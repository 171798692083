<template>
  <div class="form_info_detail form_info_serach_box">
    <!-- <div class="defult_dialog_header"
         v-if="formTitle">
      <span class="defult_dialog_header_return mint-toast-icon mintui mintui-back fl"
            @click.stop="returnEvent"></span>
      <span class="defult_dialog_header_font">{{formTitle}}</span>
    </div> -->
    <!-- 搜索选择层 -->
    <div class="defult_dialog_searchform clearfix">
      <!-- 搜索框 -->
      <div class="student_search_form">
        <input type="text"
               class="search_input"
               placeholder="请输入姓名、姓名首字母、手机号"
               v-model="searchObj.searchText">
        <span class="remove_btn"
              style="right:70px"
              v-if="searchObj.searchText.length > 0"
              @click.stop="clearSearText"></span>
      </div>
      <div @click.stop="searchTextSearch()"
           class="btn_light_blue_search fl">搜索</div>
    </div>
    <!-- 搜索结果 -->
    <div class="search_result">
      <div class="search_result_content search_result_content_for_student">
        <div class="apply_course_search_box"
             @scroll="scrollTOP">
          <!--  选择报课课单列表 -->
          <div>
            <single-course-item v-for="item in courseList"
                                :key="item.OLAPKey"
                                :courseItem="item"
                                @selectedItem="selectedItem"></single-course-item>
          </div>
        </div>
        <div v-if="!loading && courseList.length==0">
          <div class="has_arrange_course">
            <div class="has_arrange_course_content"
                 style="padding:0">
              <div class="has_arrange_course_list ">
                <div class=" has_arrange_course_nodata single_shadow ">
                  <span>亲，当前没有任何数据哦~</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import singleCourseItem from './single-course-item';
import { getSearchPageForStayClass } from '../../../../API/workbench';
export default {
  props: {
    formTitle: String, // 自定义标题名
    switchItem: [String, Number, Boolean] // 自定义跳转，不跳转路由的返回.switchItem为任意值
  },
  data () {
    return {
      courseList: [],
      searchObj: {
        pageIndex: 0,
        pageSize: 20,
        searchText: '',
        orderBy: '',
        sequence: '',
        ID: 0
      },
      // searchObj.searchText: '',
      // searchObj.pageSize: 20,     // 每次搜索人数.
      // searchObj.pageIndex: 0,      // 每次查询页数
      loading: false,
      noMore: false // 没有更多数据加载了

    };
  },
  components: {
    singleCourseItem
  },
  watch: {
    // searchObj.searchText () {
    //   if (this.searchObj.searchText == '') {
    //     this.courseList.length = 0;
    //     this.searchObj.pageSize = 20;
    //     this.searchObj.pageIndex = 0;
    //     this.getcourseList();
    //   } else {
    //     this.courseList.length = 0;
    //     this.textSeachergetcourseList();
    //   }
    // }
  },
  created () {
    this.getcourseList();
  },
  methods: {
    // 点击搜索
    searchTextSearch () {
      this.searchObj.searchText = this.searchObj.searchText.replace(/ /g, '');
      this.searchObj.pageIndex = 0;
      this.searchObj.pageSize = 0;
      this.noMore = false;
      this.getcourseList();
    },
    doAfterSelectionItem (item) {
      this.searchObj.searchText = '';
      this.$emit('selectedItem', item);
    },
    // 自定义返回或取消事件
    returnEvent () {
      if (this.switchItem) {      // 不跳转路由的返回.switchItem为任意值.
        this.$emit('return');
      } else {
        this.$router.push('/indexview');
      }
    },
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;
      if ((ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight || (ETarget.scrollTop + 1) == ETarget.scrollHeight - ETarget.clientHeight) && !this.noMore) {
        console.log('scrollTOP', this.noMore);
        this.getcourseList();
      }
    },
    textSeachergetcourseList () {                      // 根据搜索条件获取学生.
      let that = this;
      getSearchPageForStayClass(that.searchObj).then(result => {
        that.courseList.length = 0;
        result.data.PageDataList.forEach(obj => {
          obj.isActive = false;
          obj.NumCount = Number(obj.NumCount);
          obj.ToBeArranged = Number(obj.ToBeArranged);
          that.courseList.push(obj);
        });
        that.searchObj.pageIndex++;
      }).catch(error => {
        console.log(error);
      });
    },
    getcourseList () {
      this.loading = true;
      getSearchPageForStayClass(this.searchObj).then(result => {
        if (result.data) {
          // 切换学生类型时 (首页)需清空数据
          if (this.searchObj.pageIndex == 0) {
            this.courseList = [];
          }
          result.data.PageDataList.forEach(o => {
            o.isActive = false;
            o.NumCount = Number(o.NumCount);
            o.ToBeArranged = Number(o.ToBeArranged);
            let hasFind = false;
            if (this.courseList.length > 0) {
              hasFind = this.courseList.findIndex(p => p.TableID == o.TableID) >= 0;
            }
            if (!hasFind) {
              this.courseList.push(o);
            }
          });
          if (result.data.PageDataList.length < this.searchObj.pageSize) {
            this.noMore = true;
          }
          // this.totalNumForStudent = result.data.Total;
          // this.searchObj.pageIndex++;
        }
        this.loading = false;
        this.searchObj.pageIndex++;
      }).catch(error => {
        this.loading = false;
        console.log(error);
      });
    },
    // 选择学生
    selectedItem (item) {
      this.courseList.forEach(obj => {
        obj.isActive = false;
        if (obj.OLAPKey == item.OLAPKey) {
          obj.isActive = true;
        }
      });
      this.verifyStudent(item);
      this.scrollBackTop();
    },
    // 验证学生
    verifyStudent (item) {
      console.log(this.switchItem, 'item');
      if (item.IsConfirmKey == 0 && this.switchItem !== 1) { // switchItem 这里可做开关，1则关闭验证提示
        layer.alert('该学生待验证确认，请先去学生档案中进行确认操作。');
      } else {
        this.searchObj.searchText = '';
        this.$emit('selectedItem', item);
      }
    },
    scrollBackTop () {
      this.searchObj.searchText = '';
      document.getElementsByClassName('search_result_content_for_student')[0].scrollTop = 0;
      if (document.getElementsByClassName('search_result_content_for_student')[1]) {
        document.getElementsByClassName('search_result_content_for_student')[1].scrollTop = 0;
      }
    },
    clearSearText () {
      this.searchObj.searchText = '';
    }
  },
  computed: {

  }
};
</script>
<style scoped>
.search_result_content {
  margin-top: 0px !important;
}
</style>


