<template>
  <div>
    <custom-dialog title="多学生排课"
                   class="student_arrange_dialog defult_dialog no_hidden"
                   :visible.sync="showBox"
                   :before-close="close">
      <!-- 多学生排课 -->
      <student-courses-arranging ref="studentCoursesArranging"
                                 @close="close"
                                 @showCourseList="showCourseList"
                                 @showTeacherList="showTeacherList"
                                 @showClassList="showClassList"
                                 @showStundentList="showStundentList"
                                 @arrangeMutilStudentSuccess="arrangeMutilStudentSuccess"></student-courses-arranging>
    </custom-dialog>
    <!-- 搜索选择层 -->
    <custom-dialog :title="selectionTitle"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isShowCourseNameList"
                   :before-close="closeDialogForCourseNameList">
      <div>
        <single-selection-list ref="studentListDialog"
                               isShowSearchInput="false"
                               :dataSourceList="courseNameList"
                               :required="true"
                               @onSelectedItem="doAfterSelectedCourseName"></single-selection-list>
      </div>
    </custom-dialog>
    <!-- 多学生列表 -->
    <!-- <custom-dialog
      :title="'选择学生'"
      
      
      
      class="refund_dialog defult_dialog"
      
      :visible.sync="isStundentList"
      
      :before-close="closeStundentList"
    > -->
    <student-list ref="studentListBox"
                  :formTitle="'选择学生'"
                  :selectCourseID="selectCourseID"
                  @onAddStudent="addStudent"
                  @onDeleteStudent="deleteStudent"
                  @completeData="completeData"></student-list>
    <!-- </custom-dialog> -->
  </div>
</template>
<script>
import {
  SelectTheClassType,
  SelectTeach,
  SearchClass,
  GetTheClassType
} from '../../../API/workbench';
import studentCoursesArranging from './student-courses-arranging.vue';
import searchBox from '../../common/search-box';
import dropDownBox from '../../common/drop-down-box';
import studentList from '../../common-module/students-list/index';
export default {
  data () {
    return {
      showBox: false,
      courseNameList: [], // 搜索数据列表
      selectionTitle: '', // 搜索列表标头
      selectCourseID: '',
      isShowCourseNameList: false,
      isLoadSuccess: false,
      isCoursesPopup: false,
      isCoursesPopupSecond: false
    };
  },
  components: {
    studentCoursesArranging,
    searchBox,
    dropDownBox,
    studentList
  },
  methods: {
    close () {
      this.showBox = false;
    },
    addStudent (item, callBack) {
      if (callBack) {
        callBack();
      }
      this.$refs.studentCoursesArranging.studentInfoList.push(item);
      this.$refs.studentCoursesArranging.getStundentCourseInfo();
      console.log('add', item);
    },
    deleteStudent (item, index, callBack) {
      this.$refs.studentCoursesArranging.studentInfoList.splice(index, 1);
      if (callBack) {
        callBack();
      }
      console.log('delete');
    },
    selectClassName (classID) {
      GetTheClassType(classID).then(
        res => {
          if (res.code == 0) {
            this.$refs.studentCoursesArranging.selectCourseInfo = res.data;
          }
        },
        err => {
          layer.alert(err.msg);
        }
      );
    },
    completeData (data) {
      this.$refs.studentListBox.isShowAddStudent = false;
      this.$refs.studentCoursesArranging.studentInfoList = data;
    },
    closeCoursesPopup () {
      this.isCoursesPopup = false;
    },
    showStundentList () {
      this.$refs.studentListBox.isShowAddStudent = true;
      this.selectCourseID = this.$refs.studentCoursesArranging.selectCourseInfo.OLAPKey;
      this.$nextTick(() => {
        this.$refs.studentListBox.studenSearchText = '';
        this.$refs.studentsList.pushDataStudentList(this.$refs.studentCoursesArranging.studentInfoList);
        this.$refs.studentListBox.getStudentList();
      });
    },
    showCourseList () {
      this.selectionTitle = '选择课程';
      this.loadCourseNameList();
      this.$nextTick(() => {
        this.isShowCourseNameList = true;
      });
    },
    showTeacherList () {
      this.selectionTitle = '选择教师';
      this.loadTeacherNameList();
      this.$nextTick(() => {
        this.isShowCourseNameList = true;
      });
    },
    showClassList () {
      this.selectionTitle = '选择班级';
      this.loadClassNameList();
      this.$nextTick(() => {
        this.isShowCourseNameList = true;
      });
    },
    closeDialogForCourseNameList () {
      this.isShowCourseNameList = false;
    },
    doAfterSelectedCourseName (item) {
      switch (this.selectionTitle) {
        case '选择课程':
          this.$refs.studentCoursesArranging.selectCourseInfo = item;
          this.$refs.studentCoursesArranging.courseInfoList = [];
          this.$refs.studentCoursesArranging.restoreSearch();
          this.$nextTick(e => {
            this.$refs.studentCoursesArranging.getStundentCourseInfo();
          });
          break;
        case '选择教师':
          this.$refs.studentCoursesArranging.type = 1;
          this.$refs.studentCoursesArranging.serachInfo = item;
          break;
        case '选择班级':
          this.$refs.studentCoursesArranging.type = 2;
          this.$refs.studentCoursesArranging.serachInfo = item;
          break;
        default:
          break;
      }
      this.isShowCourseNameList = false;
    },
    // 获取课名列表
    loadCourseNameList () {
      SelectTheClassType().then(result => {
        this.courseNameList = [];
        result.data.forEach(obj => {
          if (obj.StateKey != 3 && obj.TypeKey == 2) {
            // StateKey:3为停用 TypeKey:0-全部；1-课类；2-课名
            this.courseNameList.push(obj);
          }
        });
        // 要根据课名列表来判断课单对应的报课计划是否为单课
        // this.getTheTicketPlanByApplyClassKey();
      });
    },
    // 获取教师列表
    loadTeacherNameList () {
      SelectTeach().then(result => {
        this.courseNameList = [];
        result.data.forEach(obj => {
          this.courseNameList.push(obj);
        });
      });
    },
    // 获取班级列表
    loadClassNameList () {
      SearchClass().then(result => {
        this.courseNameList = [];
        result.data.forEach(obj => {
          this.courseNameList.push(obj);
        });
      });
    },
    arrangeMutilStudentSuccess () {
      this.$emit('arrangeMutilStudentSuccess');
    }
  }
};
</script>