<template>
  <div class="cw_details_list"
       :class="bigCoursewareItem.ISAuthExpireKey==1?'cw_become_due':''">
    <courseware-info :bigCoursewareItem="bigCoursewareItem"
                     @doBigCwDetails="doBigCwDetails"
                     @success="success"></courseware-info>
  </div>
</template>
<script>
import coursewareInfo from './courseware-info';
export default {
  components: {
    coursewareInfo
  },
  data () {
    return {
      isShowCoursewareThis: false,
      sonDataList: []
    };
  },
  props: {
    bigCoursewareItem: Object
  },
  created () {

  },
  computed: {

  },
  methods: {
    doBigCwDetails () {
      this.$store.commit('setAcademicMdCwType', this.bigCoursewareItem.Source);
      console.log(this.bigCoursewareItem, '获取授权大课包1', this.bigCoursewareItem.Source);
      let CoursewarePackageKey = this.bigCoursewareItem.AuthKey;
      if (this.$store.getters.SaaSClientEditionKey == 6 && this.bigCoursewareItem.Source == 2) {
        CoursewarePackageKey = this.bigCoursewareItem.OLAPKey;
      }
      console.log('sddd', this.bigCoursewareItem);
      this.$dialog.open(this, {
        name: '大课包详情',
        routerName: this.$route.name,
        moduleName: 'bigCoursewareDetails',
        data: { dataInfo: { AuthKey: CoursewarePackageKey, Source: this.bigCoursewareItem.Source, MainDemoName: this.bigCoursewareItem.MainDemoName, CoursewarePackageKey: CoursewarePackageKey } },
        callBack: {
          afterSuccess: (data) => {
            console.log(data, 'data');
            this.$emit('afterSuccess');
          }
        }
      });
    },
    success () {
      this.$emit('afterSuccess');
    }
  }
};
</script>
<style scoped>
.cw_details_list {
  width: 810px;
  position: relative;
  height: 204px;
  padding: 20px 17px 26px;
  padding-right: 0px;
  margin: 0px auto;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
}
.cw_become_due {
  padding: 39px 17px 26px !important;
  height: 225px !important;
}
.package_box_ul {
  display: block;
  width: 815px;
  float: left;
  list-style: none;
  margin-top: 15px;
}
.is_block_ul {
  box-sizing: border-box;
  width: 780px;
  height: 46px;
  background: #f1f7ff;
  border-radius: 4px;
  float: left;
  align-items: center;
  text-align: center;
  line-height: 46px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  cursor: pointer;
  margin-top: 20px;
}
</style>
