<template>
  <div class="chain_mendian_center_left massive_bg">
    <div class="left_title">
      <div class="left_type">
        <div class="left_type_list "
             :class="searchData.DateType==1?'opt':''"
             @click.stop="changeSearchType(1)">周</div>
        丨
        <div class="left_type_list"
             :class="searchData.DateType==2?'opt':''"
             @click.stop="changeSearchType(2)">月</div>
        丨
        <div class="left_type_list"
             :class="searchData.DateType==3?'opt':''"
             @click.stop="changeSearchType(3)">年</div>
        <div class="left_type_text type_blue">应到</div>
        <div class="left_type_text type_red">实到</div>
      </div>
      <div class="left_select">
        <el-select v-model="searchData.TargetSaaSClientKey"
                   placeholder="请选择"
                   size="small"
                   @change="chgSelect">
          <el-option v-for="item in menDianList"
                     :key="item.OLAPKey"
                     :label="item.MainDemoName"
                     :value="item.OLAPKey">
          </el-option>
        </el-select>

      </div>
    </div>
    <div class="left_echart">
      <lineTypeEchart :echartID="'dual_histogram4'"
                      :reqList="monthList"
                      :nowDataList="nowList"
                      :preDataList="oldList"
                      :echartType="'line'"
                      :tipTitle="vTipsTitle"
                      ref="echart"></lineTypeEchart>
    </div>
  </div>
</template>
<script>
import lineTypeEchart from '../../../base-module/echart-common/line-type-echart';
import { MessageSearchAttendanceReport, GetChainSaaSClient } from '../../../../API/workbench';
export default {
  data () {
    return {
      searchData: {
        startTime: '',
        endTime: '',
        TargetSaaSClientKey: 0,        // 0为全部门店
        DateType: 1      // 日期类型1-周;2-月;3-年
      },
      attenReport: {},
      menDianList: [],
      monthList: [],
      nowList: [],
      nowDataList: [],
      oldList: []

    };
  },
  computed: {
    vTipsTitle () {
      if (this.searchData.DateType == 2) {
        return '日';
      } else {
        return '';
      }
    }
  },
  components: {
    lineTypeEchart
  },
  created () {
    this.GetChainSaaSClientList();
  },
  mounted () {
    this.setTime();
    this.getAttenReport();
    this.$bus.on(this, 'switchHeaderBarToMoreMendianReport', () => {
      this.getAttenReport();
    });
  },
  methods: {
    changeSearchType (val) {
      this.searchData.DateType = val;
      this.setTime();
      this.getAttenReport();
    },
    chgSelect (val) {
      console.log(val, 'chgSelect');
      this.searchData.TargetSaaSClientKey = val;
      this.getAttenReport();
    },
    setTime () {
      if (this.searchData.DateType == 1) {    // 选中周
        var today = Date.new();
        var weekday = today.getDay();
        var monday = Date.new(1000 * 60 * 60 * 24 * (1 - weekday) + today.getTime());
        var Sunday = Date.new(1000 * 60 * 60 * 24 * (7 - weekday) + today.getTime());
        this.searchData.startTime = this.$utils.formatDateToLine(monday);
        this.searchData.endTime = this.$utils.formatDateToLine(Sunday);
        console.log(monday, Sunday, '周');
      } else if (this.searchData.DateType == 2) { // 选中月
        this.searchData.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.searchData.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd');
      } else {      // //选中年
        this.searchData.startTime = Date.new().getFullYear() + '-01-01';
        this.searchData.endTime = Date.new().getFullYear() + '-12-31';
      }
      console.log(this.searchData.startTime, this.searchData.endTime);
    },
    setMonthList () {
      if (this.searchData.DateType == 1) {    // 选中周
        this.monthList = this.setEchartXForWeek();
      } else if (this.searchData.DateType == 2) { // 选中月
        this.monthList = this.setEchartXForMonth();
        console.log(this.monthList, '选中月');
      } else {      // //选中年
        this.monthList = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
      }
    },
    // 设置X轴
    setEchartXForWeek () {
      let newArr = [];
      this.nowDataList.forEach((o, index) => {
        newArr.push(o.SetUpTime.substring(5) + ' ' + this.$utils.getWeekByTwo(o.SetUpTime));
      });
      return newArr;
    },
    // 设置X轴
    setEchartXForMonth () {
      let newArr = [];
      this.nowDataList.forEach((o, index) => {
        let num = index < 9 ? '0' + (index + 1) : index + 1;
        newArr.push(num);
      });
      return newArr;
    },
    GetChainSaaSClientList () {
      GetChainSaaSClient().then(result => {
        this.menDianList = result.data;
        this.menDianList.unshift({
          OLAPKey: 0,
          MainDemoName: '全部门店'
        });
        console.log(result.data, 'result');
      }).catch(error => {
        console.log(error, 'errorGetChainSaaSClientList');
      });
    },
    getAttenReport (callback) {
      MessageSearchAttendanceReport(this.searchData).then(result => {
        console.log('getAttenReport', result.data);
        this.nowDataList = result.data;
        this.setMonthList();
        this.nowList = this.getNowList();
        this.oldList = this.getOldList();
        if (callback) {
          callback();
        }
        this.$nextTick(() => {
          if (this.$refs.echart) {
            this.$refs.echart.echartInit();
          }
        });
      }).catch(error => {
        console.log('ErroretAttenReport', error);
      });
    },
    // 应到
    getNowList () {
      let nowList = [];
      // let monthList = this.getDaysOfMonth(this.seletedDate);
      this.monthList.forEach(p => {
        let item = null;
        if (this.searchData.DateType == 3) {
          item = this.nowDataList.find(o => {     // 区分年
            return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
          });
        } else if (this.searchData.DateType == 2) {      // 区分月
          item = this.nowDataList.find(o => {
            return Date.new(o.SetUpTime).getDate() == parseInt(p);
          });
        } else {                    // 区分周
          item = this.nowDataList.find(o => {
            return p.substring(0, 5) == o.SetUpTime.substring(5);
          });
        }
        if (item) {
          nowList.push(Number(item.ArrangedNumCount).toFixed(0));
        } else {
          nowList.push('0');
        }
      });
      return nowList;
    },
    // 实到
    getOldList () {
      let nowList = [];
      this.monthList.forEach(p => {
        let item = null;
        if (this.searchData.DateType == 3) {
          item = this.nowDataList.find(o => {     // 区分年
            return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
          });
        } else if (this.searchData.DateType == 2) {      // 区分月
          item = this.nowDataList.find(o => {
            return Date.new(o.SetUpTime).getDate() == parseInt(p);
          });
        } else {                    // 区分周
          item = this.nowDataList.find(o => {
            return p.substring(0, 5) == o.SetUpTime.substring(5);
          });
        }
        if (item) {
          if (Date.new(Date.new().setDate((Date.new().getDate() - 1))) >= Date.new(item.SetUpTime)) {
            nowList.push(Number(item.ArrivedNumCount).toFixed(0));
          }
        }
        // else {
        //   nowList.push('0');
        // }
      });
      return nowList;
    }
  }
};
</script>
