<template>
  <div>
    <div class="course_statistics_table_search flex course_statistics_title">
      <div class="">
        <div class="">
          <seleted-single-date ref="monthPicker"
                               :fromTitle="'选择日期'"
                               :searchObj="searchObj"
                               @changeDate="changeSaveDate"></seleted-single-date>
        </div>
      </div>
      <div class="course_statistics_search"
           style="margin-left: -100px;">
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入'"
                                  :showSearchBtn="false"
                                  :clearAll="false"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>

      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list class="summarizing_list"
                  :tableData="vdataReportList"
                  :tableColumns="tableCourseColumns"
                  :queryParams="searchObj"
                  @loadTableData="sortList"></table-list>
    </div>
  </div>
</template>
<script>
// import dateOption from '../../class-spend-statistics-analyze/components/date-option';
import debtCourseHourseDetails from '../../../pop-up-menu/sign-up-payment/debt-course-hourse-details';
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import singleYearPicker from '../../../base-module/date-picker-common/single-year-picker';
import { ThreadStat } from '../../../../API/workbench';
export default {
  data () {
    return {
      seletedCourseInfo: {},
      studentDetail: {},
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 0,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate())))),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate())))),
        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '课程顾问',
          prop: 'SaleMan',
          minWidth: 108,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '销售额',
          prop: 'ActualColAmount',
          minWidth: 108,
          sortable: 'custom',
          align: 'left',
          type: 'textItem',
          decimalPlace: 2,
          extend: {
            symbol: '',
            className: 'font_blue',
            click: (rowData) => {
              rowData.eventName = '3';
              this.goRouter(rowData, 'applyRecord');
            }
          }
        },
        {
          label: '签单数',
          prop: 'saleCount',
          minWidth: 80,
          sortable: 'custom',
          align: 'left',
          className: '',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            click: (rowData) => {
              rowData.eventName = '3';
              this.goRouter(rowData, 'applyRecord');
            }
          }
        },
        {
          label: '完成跟单',
          prop: 'finishFollowUpNum',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          className: '',
          type: 'textItem',
          extend: {
            className: ''
            // click: (rowData) => {
            //   rowData.eventName = '3';
            //   this.goRouter(rowData,'applyRecord');
            // }
          }
        },
        {
          label: '未及时跟单',
          prop: 'noFollowNum',
          minWidth: 120,
          sortable: 'custom',
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_red',
            click: (rowData) => {
              rowData.eventName = '3';
              this.goRouter(rowData, 'unfollowClue');
            }
          }
        },
        {
          label: '预约到店',
          prop: 'appointmentToShopNum',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            click: (rowData) => {
              rowData.eventName = '3';
              this.goRouter(rowData, 'tryCourse', 1);
            }
          }
        },
        {
          label: '实际到店',
          prop: 'actualToShopNum',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            click: (rowData) => {
              rowData.eventName = '3';
              this.goRouter(rowData, 'tryCourse', 1);
            }
          }
        },
        {
          label: '预约试课',
          prop: 'appointmentTryClassNum',
          sortable: 'custom',
          minWidth: 100,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            click: (rowData) => {
              rowData.eventName = '3';
              this.goRouter(rowData, 'tryCourse', 2);
            }
          }
        },
        {
          label: '实际试课',
          prop: 'actualTryClassNum',
          sortable: 'custom',
          minWidth: 100,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            click: (rowData) => {
              rowData.eventName = '3';
              this.goRouter(rowData, 'tryCourse', 2);
            }
          }
        },
        {
          label: '跟进中线索',
          prop: 'FollowUpNum',
          sortable: 'custom',
          minWidth: 100,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            click: (rowData) => {
              rowData.eventName = '3';
              this.goRouter(rowData, 'followingClue', 0);
            }
          }
        },
        {
          label: 'S单',
          prop: 'WillingnessRateNumS',
          sortable: 'custom',
          minWidth: 80,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            click: (rowData) => {
              rowData.eventName = '3';
              this.goRouter(rowData, 'followingClue', 1);
            }
          }
        },
        {
          label: 'A单',
          prop: 'WillingnessRateNumA',
          sortable: 'custom',
          minWidth: 80,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            click: (rowData) => {
              rowData.eventName = '3';
              this.goRouter(rowData, 'followingClue', 2);
            }
          }
        }
      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
    debtCourseHourseDetails,
    singleYearPicker
  },
  props: {
  },
  created () {
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      if (this.searchObj.searchText) {
        this.dataReportList.forEach(obj => {
          if (obj.SaleMan.toUpperCase().indexOf(this.searchObj.searchText.toUpperCase()) >= 0 || (obj.FirstLetterName && obj.FirstLetterName.toUpperCase().indexOf(this.searchObj.searchText.toUpperCase()) >= 0)) {
            newArr.push(obj);
          }
        });
      } else {
        newArr = this.dataReportList;
      }
      if (this.searchObj.orderBy) {
        if (this.searchObj.sequence == 'DESC' || this.searchObj.sequence == 'desc') {
          newArr.sort((x, y) => {
            return y[this.searchObj.orderBy] - x[this.searchObj.orderBy];
          });
        } else {
          newArr.sort((x, y) => {
            return x[this.searchObj.orderBy] - y[this.searchObj.orderBy];
          });
        }
      }
      newArr.forEach((o, index) => {
        o.ActualColAmount = Number(Number(o.ActualColAmount).toFixed(2));
        if (index < 9) {
          o.Number = '0' + (index + 1);
        } else {
          o.Number = (index + 1);
        }
      });
      return newArr;
    }

  },
  mounted () {
    this.$bus.on(this, 'wsAttendanceOrArrange', (data) => {
      console.log('触发wsAttendanceOrArrange');
      this.getDataList();
    });
    this.getDataList();
  },
  methods: {
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.search();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.orderBy = '';
      this.searchObj.sequence = 'DESC';
      this.$refs.monthPicker.resetTime();
      this.getDataList();
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (item, date) {
      console.log('searchData', date);
      this.searchObj.TeacherKey = item.TeacherKey;
      this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(date).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      let seletedYearMonth = Date.new(date).getFullYear() + ' ' + (Date.new(date).getMonth() + 1);
      let nowYearMonth = Date.new().getFullYear() + ' ' + (Date.new().getMonth() + 1);
      if (seletedYearMonth != nowYearMonth) {
        this.searchObj.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new(date)), 'yyyy-MM-dd');
      } else {
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
      }
      this.getDataList();
    },
    search () {
      this.searchObj.TeacherKey = 0;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    sortList (queryParams) {
      console.log(queryParams.orderBy, 'sortList', queryParams.sequence);
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
    },
    getDataList (queryParams) {
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj);
      ThreadStat(this.searchObj).then(result => {
        this.dataReportList = result.data;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData) {
      this.$emit('toTableType', rowData.eventName, rowData.showSetUpTime);
    },
    // 关闭弹窗页面
    closeDetails () {
      this.isDebtCourseHoursDetailsShow = false;
    },
    goRouter (item, url, type) {
      var startTime = this.searchObj.startTime;
      var endTime = this.searchObj.endTime;
      if (url == 'followingClue') {
        startTime = '';
        endTime = '';
      }
      this.$router.push({
        name: url,
        query: {
          startTime: startTime,
          endTime: endTime,
          SaleMan: item.SaleMan || '',
          SaleManKey: item.SaleManKey || 0,
          type: type
        }
      });
    }

  }
};
</script>