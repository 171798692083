<template>
  <!-- 物品名称设置表单 -->
  <div>
    <div class="content_marginRL border_bottom">
      <!-- 内容 -->
      <div class="form_info_edit mendian_setting_content single_shadow"
           :class="{btn_marginBottom_30:true}">

        <input-text :formTitle="'物品名称'"
                    :readonly="isReadonly"
                    :formPlaceholder="'必填'"
                    :required="true"
                    :maxLength="14"
                    v-model="dataJson.MainDemoName"></input-text>

        <input-dialog :formTitle="'所属物品类型'"
                      :required="true"
                      :customPlaceholder="true"
                      :formPlaceholder="'必选'"
                      :readonly="isReadonly"
                      @showEldilog="doGoodsTypeListClick"
                      v-model="dataJson.GoodsTypeKeyValue"></input-dialog>

        <div class="pr">
          <input-switch v-model="dataJson.IsSellKey"
                        :formTitle="'是否销售'"
                        :readonly="isReadonly"
                        :helpDescription="''"
                        :class="dataJson.IsSellKey==0?'no_border_bottom':''"
                        style="padding-right: 23px;"
                        :required="true"></input-switch>
          <span style="position: absolute;right: 0;top: 17px;">{{dataJson.IsSellKey==1?'是':'否'}}</span>
        </div>

        <div v-if="dataJson.IsSellKey==1">
          <input-dialog :formTitle="'关联杂费'"
                        :required="true"
                        :customPlaceholder="true"
                        :formPlaceholder="'必选'"
                        :readonly="isReadonly"
                        @showEldilog="doIncidentalsListClick"
                        v-model="dataJson.IncidentalsKeyValue"></input-dialog>

          <input-number :formTitle="'销售单价'"
                        class="no_border_bottom"
                        :min="0"
                        :max='999999999999'
                        :readonly="isReadonly"
                        v-model="dataJson.SalesPriceAmount"
                        :decimalPlaces="2"
                        :placeholder="'必填'"
                        :required="true"></input-number>
        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <div v-if="!isReadonly"
         style="margin-top: 60px;">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
    <custom-dialog title="物品类型列表"
                   class="new_class defult_dialog"
                   :visible.sync="isGoodsSelectShow"
                   :before-close="closeGoodsSelect">
      <single-select-tree :sourceDate="vGoodsTypeList"
                          :selectionDate="vDataJsonInfo"
                          :disabledType="2"
                          @confirmClcik="doAfterSelectedItem"
                          @cancleClcik="closeGoodsSelect"></single-select-tree>

    </custom-dialog>
  </div>

</template> 

<script>
import singleSelectTree from '../../../../common/single-select-tree';
import { AddGoods, EditGoods, GetGoodsTypeList, getIncidentals } from '../../../../../API/workbench';
export default {
  data () {
    return {
      goodsNameID: 0, // 物品名称ID
      // 数据源
      dataJson: {
        MainDemoName: '', // 物品名称
        GoodsTypeKey: '2', // 所属物品类型
        GoodsTypeKeyValue: '全部类型',
        IsSellKey: 1, // 是否销售
        IsSellKeyValue: '',
        IncidentalsKey: '', // 关联杂费
        IncidentalsKeyValue: '',
        SalesPriceAmount: 0// 销售单价
      },
      isGoodsSelectShow: false,
      goodsTypeList: []
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: {}
    },
    isReadonly: Boolean
  },
  components: { singleSelectTree },
  computed: {
    vGoodsTypeList () {
      let list = [];
      list.splice(0, 0, {
        OLAPKey: '2',
        MainDemoName: '全部类型',
        FatherKey: '0',
        FatherKeyValue: ''
      });
      this.goodsTypeList.forEach(o => {
        list.push(
          {
            OLAPKey: o.OLAPKey,
            MainDemoName: o.MainDemoName,
            FatherKey: '2',
            FatherKeyValue: '全部类型'
          }
        );
      });
      return list;
    },
    vDataJsonInfo: {
      get () {
        return {
          id: this.dataJson.GoodsTypeKey,
          label: this.dataJson.GoodsTypeKeyValue
        };
      },
      set (newValue) {
        this.dataJson.GoodsTypeKeyValue = newValue.label;
        this.dataJson.GoodsTypeKey = newValue.id;
      }
    }
  },
  created () {
    if (JSON.stringify(this.dataInfo) != '{}') { // 物品信息
      this.dataJson.MainDemoName = this.dataInfo.MainDemoName || '';// 物品名称
      this.dataJson.GoodsTypeKey = this.dataInfo.GoodsTypeKey || ''; // 所属物品类型
      this.dataJson.GoodsTypeKeyValue = this.dataInfo.GoodsTypeKeyValue || '';
      this.dataJson.IsSellKey = this.dataInfo.IsSellKey || 1; // 是否销售
      this.dataJson.IsSellKeyValue = this.dataInfo.IsSellKeyValue || '';
      this.dataJson.IncidentalsKey = this.dataInfo.IncidentalsKey || '';// 关联杂费
      this.dataJson.IncidentalsKeyValue = this.dataInfo.IncidentalsKeyValue || '';
      this.dataJson.SalesPriceAmount = this.dataInfo.SalesPriceAmount || 0;// 销售单价
      this.goodsNameID = this.dataInfo.OLAPKey || '';// 物品名称ID
      console.log(this.dataJson, '修改物品信息');
    }
  },
  mounted () {
    // this.getGoodsTypeList();
    this.$dialog.register(this, ['singleSelectionList']);
  },
  methods: {
    // 确认按钮
    confirmClick (callBack) {
      let isWrong = this.checkSubmitData();
      if (isWrong) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      console.log(this.dataJson, '提交');

      if (Number(this.dataJson.IsSellKey) == 0) { // 销售为false时，杂费项清空
        this.dataJson.IncidentalsKey = ''; // 关联杂费
        this.dataJson.IncidentalsKeyValue = '';
      }
      if (Number(this.dataInfo.OLAPKey) > 0) { // 修改
        EditGoods(this.goodsNameID, 0, this.dataJson).then(result => {
          layer.alert('修改成功');
          this.$emit('afterSuccess', 'Edit', result.data);
          this.cancelClick();
        }, error => {
          if (error.code == 10002) {
            layer.confirm(error.msg + ', 是否继续?', {
              btn: [{
                name: '确认',
                callBack: () => {
                  EditGoods(this.goodsNameID, 1, this.dataJson).then(result => {
                    layer.alert('修改成功');
                    this.$emit('afterSuccess', 'Edit', result.data);
                    this.cancelClick();
                  });
                  layer.close();
                }
              }
              ]
            });
            // }
          } else {
            layer.alert(error.msg);
          }
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      } else {
        AddGoods(0, this.dataJson).then(result => { // 新增
          layer.alert('新增成功');
          this.$emit('afterSuccess', 'Add', result.data);
          this.cancelClick();
        }, error => {
          if (error.code == 10002) {
            layer.confirm(error.msg + ', 是否继续?', {
              btn: [{
                name: '确认',
                callBack: () => {
                  AddGoods(1, this.dataJson).then(result => {
                    layer.alert('新增成功');
                    this.$emit('afterSuccess', 'Edit', result.data);
                    this.cancelClick();
                  }, error => {
                    layer.alert(error.msg);
                  });
                  layer.close();
                }
              }
              ]
            });
          } else {
            layer.alert(error.msg);
          }
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      }
    },
    // 验证要提交的数据
    checkSubmitData () {
      console.log(this.dataJson, '验证');
      let flag = false;
      if (!this.dataJson.MainDemoName || !this.dataJson.MainDemoName.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('物品名称不能为空');
        flag = true;
      } else if (!this.dataJson.GoodsTypeKey) {
        layer.alert('所属物品类型不能为空');
        flag = true;
      } else if (!Number(this.dataJson.IncidentalsKey) && this.dataJson.IsSellKey == 1) {
        layer.alert('关联杂费项不能为空');
        flag = true;
      } else if (this.dataJson.SalesPriceAmount <= 0 && this.dataJson.IsSellKey == 1) {
        layer.alert('销售单价不能小于等于0');
        flag = true;
      }
      return flag;
    },
    // 取消按钮
    cancelClick () {
      this.$emit('closeDialog');
    },
    // 物品类型
    doGoodsTypeListClick () {
      GetGoodsTypeList().then(result => {
        console.log(result.data, '物品类型');
        this.goodsTypeList = result.data;
        this.isGoodsSelectShow = true;
      }, error => {
        layer.alert(error.msg);
      });
    },
    doAfterSelectedItem (item) {
      console.log(item, '物品类型');
      this.dataJson.GoodsTypeKey = item.id;
      this.dataJson.GoodsTypeKeyValue = item.label;
      this.isGoodsSelectShow = false;
    },
    closeGoodsSelect () {
      this.isGoodsSelectShow = false;
    },
    // 关联杂费
    doIncidentalsListClick () {
      getIncidentals().then(result => {
        console.log(result.data, '关联杂费');
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '杂费列表',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.dataJson.IncidentalsKey, dataSourceList: result.data, addModuleName: 'incidentals-setting', required: true },
          callBack: {
            onSelectedItem: (data) => {
              // console.log(data, '选择杂费');
              this.dataJson.IncidentalsKey = data.OLAPKey;
              this.dataJson.IncidentalsKeyValue = data.MainDemoName;
              this.dataJson.SalesPriceAmount = Number(data.UnitPrice);
            }
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    }
  }
};
</script>
