<template>
  <div class="payment_statistics_bottom ">
    <div class="course_statistics_table_search flex course_statistics_title">
       <div class="flex"
           style="margin-right: 10px; min-width: 120px;margin-left:0px">
        <el-select v-model="searchObj.TeacherKey"
                   placeholder="选择学管师"
                   style="width: 150px;margin-right:20px;height: 36px;"
                   ref="resetText"
                   @change="selectTeacher">
          <el-option v-for="item in vteacherList"
                     :key="item.OLAPKey"
                     :label="item.MainDemoName"
                     :value="item.OLAPKey">
          </el-option>
        </el-select>
        <div class="flex">
          <span style="line-height: 32px;">选择月份</span>
          <div class="title_date_select">
            <div class="el-select">
              <div class="el-input el-input--small el-input--suffix is-focus">
                <single-year-picker @chgDate="changeSaveDate"
                                    :minDate="''"
                                    :maxDate="''"
                                    ref="monthPicker"
                                    :type="'month'"></single-year-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="course_statistics_search" >
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入学生名'"
                                  :clearAll="true"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        
        <div class="mendian_backstage_search" style="margin-left:10px">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list class="summarizing_list"
                  :key="tableKey"
                  :tableData="vdataReportList"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :tableColumns="tableCourseColumns"
                  :isSearchText="false"
                  :isNeedInnerScroll="false"
                  :defaultSort="defaultSort"
                  :queryParams="searchObj"
                  :footerContent="vFooterContent"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
// import dateOption from '../../class-spend-statistics-analyze/components/date-option';

// import list from '../../class-spend-statistics-analyze/components/table';
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import singleYearPicker from '../../../base-module/date-picker-common/single-year-picker';

import { GetStudentAttenceList } from '../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 15,
        searchText: '',
        orderBy: '',
        type: '',
        TeacherKey: 0,
        TeacherKeyValue: '',
        StatusType:'',
        FollowTime:'',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0)),
        IsExportKey: 0,
      },
    
      
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 55,
          sortable: false,
          align: 'left',
          type: 'number-item',
          className: 'table_index_gray'
        },
        {
          label: '学管师',
          prop: 'AdviserStudentKeyValue',
          width: 100,
           sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '学生名',
          prop: 'MainDemoName',
           width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'student',
          extend: {
            studentKey: 'OLAPKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '上课时间',
          prop: 'ClassTime',
             minWidth: 120,
          sortable: 'custom',
          align: 'center',
          isShow: true,

          type: 'text-item'
        },
         {
          label: '课程',
          prop: 'CourseNameKeyValue',
             minWidth: 140,
          sortable: 'custom',
          align: 'center',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '班级',
          prop: 'GradeClassKeyValue',
          minWidth: 140,
          sortable: 'custom',
          align: 'center',
          isShow: true,
          type: 'text-item'
        },
       
        {
          label: '主讲',
          prop: 'MTeaName',
             width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
          {
          label: '考勤状态',
          prop: 'AttenceStatusKeyValue',
         width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
        },
        {
          label: '消耗课时',
          prop: 'DeductCount',
           width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'amount-item',
          decimalPlace: 1,
      
        },
          {
          label: '课时结转',
          prop: 'DeductAmount',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
           type: 'amount-item',
            decimalPlace: 2,
      
        },
       
      
      
      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    singleYearPicker,
    inputSearchContainBtn
  },
  props: {
    seletedItem: {
      type: Object,
      default: ()=>{
        return{
            OLAPKey:0,
            MainDemoName:'趁',
            StudentCount:50
        }
      }
    },
    teacherList:Array

  },

  created () {
  },
  computed: {
    vteacherList(){
      let newArr=[]
      newArr=this.$utils.parseJson(this.teacherList||[])
      newArr.unshift({
        OLAPKey:-1,
        MainDemoName:'未分配学管师'
      })
      newArr.unshift({
        OLAPKey:0,
        MainDemoName:'全部学管师'
      })
      
      return newArr
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // let info = o.ClassTime.substring(0, 10) + ',' + o.ClassTime.substring(11);
        // o.classTimeObj = info.split(',');
        // o.ClassTime = o.ClassTime.substring(5);
        o.ClassAwayAmountCount = Number(o.ClassAwayAmountCount || 0);
        o.ClassHourDebtCount = Number(o.ClassHourDebtCount) || 0;
        // o.AttenceTime = o.AttenceTime.substring(5, 16);
        // this.$set(o, 'OLAPKey', o.CourseNameKey);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterContent () {
      return '共' + this.totalNum + '条数据';
    }
  },
  mounted () {
      this.getDataList();
   
  },
  watch:{
    
    
  },
  methods: {
    selectTeacher(){
      this.getDataList();

    },
     // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn =  GetStudentAttenceList;
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '考勤明细统计',
          clist: [
            { title: '学管师', cName: 'AdviserStudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生名', cName: 'MainDemoName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '上课时间', cName: 'ClassTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课程', cName: 'CourseNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '班级', cName: 'GradeClassKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '主讲', cName: 'MTeaName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '考勤状态', cName: 'AttenceStatusKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '消耗课时', cName: 'DeductCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课时结转', cName: 'DeductAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
           
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(searchObj,jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
     changeSaveDate (val, startTime, endTime) {
      this.searchObj.startTime = startTime.replace(/\//g, '-');
      this.searchObj.endTime = endTime.replace(/\//g, '-');
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      // this.searchObj.orderBy = '';
      this.searchObj.type = '';
      this.getDataList();
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (item, date) {
      console.log('searchData', date);
      this.searchObj.TeacherKey = item.TeacherKey;
      this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    search () {
      // this.searchObj.TeacherKey = 0;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    
    getDataList (queryParams) {
      let fn = '';
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      GetStudentAttenceList(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      
    },

  }
};
</script>
<style scoped>
.mendian_backstage_search >>> .search_box {
  width: 240px !important;
  margin-right: 30px !important;
  min-width: 240px !important;
}
.course_statistics_search{
  width: auto !important;;
}
.change_history_data {
  position: absolute;
  right: 17px;
  top: 90px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
.payment_statistics_bottom .list_title{

        width: 100%;
        height: 87px;
        margin: auto;
        padding-left: 20px;
        line-height: 87px

}
.statistics_list{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.statistics_list .statistics_item{
    height: 30px;
    padding: 5px;
    font-size: 14px;
    color: #FF9800;
    border: 1.25px solid #FF9800;
}
.statistics_list .statistics_item_red{
    height: 30px;
    padding: 5px;
    font-size: 14px;
    color: #F75040;
    border: 1.25px solid #F75040
}
.statistics_list .border_left{
  border-top-left-radius: 3px;
  border-bottom-left-radius:3px;
  border-right: none;
}
.statistics_list .border_right{
    border-top-right-radius: 3px;
  border-bottom-right-radius:3px;
  border-left: none;
  
}
</style>