<template>
  <!-- 学分项 -->
  <div class="homework_student_list">
    <div class="student_list_img">
      <img :src="headImg"
           :onerror="defaultImg"
           alt=""
           class="img_box">
    </div>
    <div class="student_list_name">
      <div class="name_box"
           style="max-width: 48px">{{stuItem.StudentKeyValue}}</div>
    </div>
    <div class="score_details_ul student_list_score">
      <score-item v-for="item in scoreList"
                  :key="item.index"
                  :scorekey="item.index"
                  :stuItem="stuItem"></score-item>
    </div>
    <div class="score_details_number">
      + {{stuItem.Currency}}
    </div>
  </div>
</template>

<script>
import scoreItem from './score-item';
export default {
  data () {
    return {
      scoreList: [
        {
          index: 0
        },
        {
          index: 1
        },
        {
          index: 2
        }, {
          index: 3
        }, {
          index: 4
        }
      ]
    };
  },
  props: {
    stuItem: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  components: {
    scoreItem
  },
  watch: {

  },
  methods: {
    coinState (stuItem, index) {
      // console.log(stuItem, coinIndex, '学分');
      stuItem.coinList.forEach((o, index) => {
        if (index <= index) {
          o.coinState = true;
        } else {
          o.coinState = false;
        }
      });
      stuItem.Currency = index + 1;
      this.$emit('coinStateClick', stuItem);
    }
  },
  computed: {
    headImg () {
      console.log(this.stuItem, '默认显示logo---');
      if (this.stuItem.TargetReaderHeadImg) {
        if (this.stuItem.TargetReaderHeadImg.indexOf('http') > -1) {
          return this.stuItem.TargetReaderHeadImg;
        } else {
          return this.$store.getters.CDNURL + this.stuItem.TargetReaderHeadImg;
        }
      } else if (this.stuItem.HeadImgSupport) {
        if (this.stuItem.HeadImgSupport.indexOf('http') > -1) {
          return this.stuItem.HeadImgSupport;
        } else {
          return this.$store.getters.CDNURL + this.stuItem.HeadImgSupport;
        }
      } else { // 默认logo
        if (Number(this.stuItem.SexKey) == 2) {
          return require('../../../../../public/image/deflut_studentPhoto_boy.png');
        } else {
          return require('../../../../../public/image/deflut_studentPhoto_gril.png');
        }
      }
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../../public/image/face_screenage_noImg.png') + '"';
    }
  }
};
</script>

<style>
</style>