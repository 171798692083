<template>
  <div class="title_list"
       :class="item.isActive?'opt':''"
       @click.stop="changeType(item)">
    {{item.name}}
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  methods: {
    changeType () {
      this.$emit('changeType', this.item);
    }
  }
};
</script>
