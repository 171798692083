<template>
  <div>
    <!-- 班级排课 -->
    <div class>
      <!-- 通用版 -->
      <div style="border-radius:8px"
           v-if="vIsSysVersionTY">
        <generalClassGradeApplyCourseList></generalClassGradeApplyCourseList>
      </div>
      <!-- 精准版 -->
      <div style="border-radius:8px"
           v-else>
        <preciseClassGradeApplyCourseList></preciseClassGradeApplyCourseList>
      </div>

    </div>
  </div>
</template>
<script>

import preciseClassGradeApplyCourseList from './precise-class-grade-apply-course-list/index';
import generalClassGradeApplyCourseList from './general-class-grade-apply-course-list/index';
export default {
  name: 'classGradeApplycourse',
  data () {
    return {
      tableType: '精准版'
    };
  },
  components: {
    preciseClassGradeApplyCourseList,
    generalClassGradeApplyCourseList
  },
  created () {

  },
  watch: {

  },
  mounted () {

  },
  methods: {

  },

  computed: {
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    }
  }

};
</script>
