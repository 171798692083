<template>
  <div class="teaching_mode">
    <div class="teaching_mode_header">
      <div class="teaching_mode_header_left">
        <!-- <img :src="vBrandInfo.LogoUrl"
               alt /> -->
      </div>
      <div class="teaching_mode_header_right">
        <div class="teaching_mode_header_right_btn"
             @click.stop="toWorkbench">
          返回
        </div>
      </div>
    </div>
    <div class="teaching_mode_courseware_info pr">
      <div class="courseware_info_name">{{ dataInfo.MainDemoName }}</div>
      <div class="teaching_mode_date">{{ timer }}</div>
      <div class="courseware_info_beginclass"
           @click="goTeachingView(dataInfo)">
        开始上课
      </div>
    </div>
    <div class="teaching_mode_content swiper_box">
      <div class="courseware_packge_list swiper-container swiper_courseware_packge_list">
        <!-- <div class="swiper_wrapper_before" v-if="Number(swiperNewIndex)>0"></div> -->
        <div class="swiper-wrapper"
             v-show="dataList.length > 0">
          <attach-item v-for="(item, key) in dataList"
                       :key="key"
                       :item="item"
                       @seletedItem="seletedItem"></attach-item>
        </div>
        <div v-if="dataList.length == 0 && isLoadEnd"
             class="teaching_mode_nodta">
          <div class="teaching_mode_nodta_text">暂无附件</div>
        </div>
        <!-- <div class="swiper_wrapper_after" v-if="Number(swiperNewIndex)+4<=dataList.length-1"></div> -->
        <!-- <div class="teaching_mode_bg_ligth">

        </div> -->
      </div>
    </div>
    <teaching-mode-custom-dialog :title="'设备授权申请'"
                                 class="new_class defult_dialog"
                                 :visible.sync="isShowAuthForm"
                                 :before-close="closeForm">
      <auth-form @closeDialog="closeForm"
                 :dataInfo="authInfo"
                 @afterSuccess="afterSuccessForApplyAuth"
                 @afterSuccessCallback="afterSuccessCallback"
                 :successCallback="true"
                 :isTeachingMode="true"></auth-form>
    </teaching-mode-custom-dialog>
    <teaching-mode-custom-dialog title="安全验证"
                                 class="student_info_dialog defult_dialog"
                                 :visible.sync="TeachingModeforQrcode"
                                 :before-close="closeAuthCodeDialog">
      <courseAuthQrcode @closeDialog="closeAuthCodeDialog"
                        @afterSuccess="openAuthAfterSuccess"
                        :dataInfo="authQrcodeDataInfo"></courseAuthQrcode>
    </teaching-mode-custom-dialog>
    <div>
      <dialog-factory ref="teachingModeDialog"
                      :key="'teachingMode'"
                      :routerName="'teachingMode'"></dialog-factory>
      <dialog-factory-common ref="teachingModeCommonDialog"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import {
  WEBVCoursewareManageGetEditDetail,
  teachingModeGetCoursewareAttachList,
  teachingModeGetCoursewarePackageInfo,
  GetTheEmployeeByID,
  GetSaaSClientMsg
} from "../../../API/workbench";
import { md5 } from "../../../API/md5";
import attachItem from "./attach-item";
import dialogFactory from "../../common/dialog-factory";
import authForm from "../../courseware-work-bench/components/report/auth-components/apply-auth.vue";
import courseAuthQrcode from "../../course-table/attendance-arrange/teaching-components/course-auth-qrcode";

export default {
  data () {
    return {
      swiper: null,
      dataInfo: {
        CoursewareAttach: []
      },
      dataList: [],
      token: null,
      mendianInfo: null,
      isFullscreen: false,
      swiperNewIndex: 0,
      vLeftShow: false,
      vRightShow: false,
      isLoadEnd: false,
      timer: "",
      CoursewareID: 0,
      isShowAuthForm: false,
      TeachingModeforQrcode: false,
      authInfo: {},
      authQrcodeDataInfo: {}
    };
  },
  components: {
    attachItem,
    authForm,
    courseAuthQrcode,
    dialogFactory
  },
  created () {
    this.token = this.$store.getters.token;
    console.log(this.token, "token");
    console.log(
      this.$store.getters.SaaSClientInfo,
      "store.getters.SaaSClientInfo"
    );
    this.CoursewareID = this.$route.query.CoursewareID;
    console.log(
      "this.$route.query.CoursewareID",
      this.$route.query.CoursewareID
    );
    if (!this.$store.getters.SaaSClientInfo) {
      this.getMendianInfo();
    }
  },
  mounted () {
    setTimeout(o => {
      this.getDataInfo();
      this.getDataList();
    }, 500);
    // this.fullScreen();
    this.vDateTime();

    this.registerBusEvent();
  },
  computed: {
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' +
        require("../../../../public/image/deflut_teacher_photo@2x.png") +
        '"'
      );
    },
    vBrandInfo () {
      let obj = {};
      if (window.brandCustomSettingStr && window.brandCustomSettingStr != "") {
        obj = JSON.parse(window.brandCustomSettingStr);
        obj.isOpenBrand = true;
        obj.LoginBackground =
          "url(" + this.$store.getters.CDNURL + obj.LoginBackground + ")";
        obj.LogoUrl = this.$store.getters.CDNURL + obj.LogoUrl;
        obj.QRCode = this.$store.getters.CDNURL + obj.QRCode;
        obj.inputBackground = "#fff";
      } else {
        obj = {
          isOpenBrand: false,
          BrandSlogan:
            "url(" + require("../../../../public/image/login_text.png") + ")",
          // LoginBackground: 'url(' + require('../../../public/image/login_banner.png') + ')',
          LoginBackground: "",
          LogoUrl: require("../../../../public/image/logo_terminal.png"),
          MainBodyColor: "#f85a59",
          MainDemoName: "治学培训机构",
          QRCode: "",
          inputBackground: "#f7f7f7"
        };
      }
      return obj;
    },

    vlogo () {
      this.vSaaSClientInfo.LogoPath = this.vSaaSClientInfo.LogoPath || "";
      if (this.vSaaSClientInfo.LogoPath.indexOf("http") > -1) {
        return this.vSaaSClientInfo.LogoPath;
      } else {
        return this.$store.getters.CDNURL + this.vSaaSClientInfo.LogoPath;
      }
    },
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    },
    vHasAuthModulePower () {
      let ModulePowerKey =
        this.$store.getters.SaaSClientEditionKey == 5 ? 108 : 123;
      return this.$utils.CheckModulePower(this.vRolePowerList, ModulePowerKey);
    },

    vSaaSClientInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    // 判断是校长还是老师
    vRoleKey () {
      console.log(Number(this.$store.getters.token.RoleKey), "校长and老师");
      return this.$store.getters.token.RoleKey
        ? Number(this.$store.getters.token.RoleKey) == 2
        : false; // 2为校长；非则为老师
    }
  },
  watch: {},
  methods: {
    vDateTime () {
      setInterval(o => {
        this.timer =
          this.$utils.formatDatet(Date.new(), "yyyy-MM-dd") +
          " " +
          this.$utils.formatDatet(Date.new(), "mm:ss");
      }, 1000);
    },
    toWorkbench () {
      this.$router.go(-1);
    },
    seletedItem (item) {
      console.log("seletedItem", item);
      goTeachingView(item);
      //   this.$router.push({ name: 'teachingModeCousewarePackgeDetail', query: { OLAPKey: item.OLAPKey } });
    },
    closeForm () {
      this.isShowAuthForm = false;
    },
    afterSuccessCallback () {
      console.log("afterSuccessCallback", this.authType);
      this.isShowAuthForm = false;
      this.dataInfo.DeviceIsAuth = 1;

      layer.confirm("设备授权已生效，是否继续进入课件？", {
        mode: "teachingMode",
        btn: [
          {
            name: "确认",
            callBack: () => {
              this.goTeachingView(this.dataInfo);
              layer.close();
            }
          }
        ]
      });
    },
    // 申请授权成功。
    afterSuccessForApplyAuth () {
      this.isShowAuthForm = false;
      // this.dataInfo.DeviceIsAuth = 1;
    },
    applyAuth (type) {
      this.authType = type;
      console.log(
        "this.$store.getters.SaaSClientEditionKey",
        this.$store.getters.SaaSClientEditionKey,
        this.vHasAuthModulePower
      );
      if (this.vHasAuthModulePower) {
        if (this.$zxClient.GetMInfo() === "{}") {
          layer.alert("设备信息为空", {
            mode: "teachingMode"
          });
          let interfaceLogData = {
            InterfaceName:
              this.$store.getters.APIURL +
              "CWS.AuthorizedDeviceManagement.AuthorizedTheDevice",
            parameter: { BigCoursewarePackageKey: this.CoursewareID },
            PostData: JSON.stringify(this.$store.getters.token),
            LogContents: "设备信息为空",
            LogTitleName: "设备信息为空",
            RefererName: window.location.href
          };
          AddInterfaceLog(interfaceLogData).then(result => {
            console.log("记录日志");
          });
        } else {
          let DeviceInfo = JSON.parse(this.$zxClient.GetMInfo());
          layer.confirm("是否授权本设备", {
            mode: "teachingMode",
            btn: [
              {
                name: "确认",
                callBack: () => {
                  CWSAuthorizedDeviceManagementAuthorizedTheDevice(
                    DeviceInfo,
                    this.CoursewareID
                  ).then(
                    res => {
                      this.$zxClient.SetUCode(res.data.DeviceUCode);
                      // if (this.isAutoAuth) {
                      this.dataInfo.DeviceIsAuth = 1;

                      layer.confirm("设备授权已生效，是否继续进入课件？", {
                        mode: "teachingMode",
                        btn: [
                          {
                            name: "确认",
                            callBack: () => {
                              this.goTeachingView(this.dataInfo);
                              layer.close();
                            }
                          }
                        ]
                      });

                      // }else{
                      //    layer.alert('申请已提交，请联系管理员进行审核后再进入课件', { mode: 'teachingMode' });
                      // }
                    },
                    err => {
                      layer.alert(err.msg, {
                        mode: "teachingMode"
                      });
                    }
                  );
                  layer.close();
                }
              }
            ]
          });
        }

        // 无权限
      } else {
        this.authInfo = {
          CoursewareKey: this.CoursewareID,
          DeviceCode: this.$zxClient.GetMCode()
        };
        this.isShowAuthForm = true;
      }
    },
    // 快捷上课
    goTeachingView (item) {
      console.log("goTeachingView111", item, this.dataInfo);
      if (this.$zxClient && this.$zxClient.IsClient) {
        if (Number(this.dataInfo.DeviceIsAuth) == 0) {
          //设备未授权
          this.applyAuth("goTeachingView");
        } else if (
          this.dataInfo.PCIsLocationLockKey == 1 &&
          !this.dataInfo.Longitude
        ) {
          //PC开启定位验证
          this.authType = "goTeachingView";
          this.openAuthDialog(this.CoursewareID, "", 4, 12);
        } else {
          if (
            this.dataInfo.PCIsLocationLockKey == 1 &&
            this.dataInfo.HQAuthKey != 0
          ) {
            let text =
              this.dataInfo.HQAuthKey == 1
                ? "  当前门店定位，课件授权方禁止访问课件"
                : " 当前门店定位，课件授权方正在审核中，暂时无法访问课件";
            layer.alert(text, {
              mode: "teachingMode"
            });
            return false;
          } else {
            let newdataInfo = {
              ScheduleCourseKey: "",
              courserId: this.CoursewareID,
              client_id: this.$store.getters.clientId,
              SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
              DeviceTypeKey: "2", //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
              introType: 12,
              DeviceCode: this.$zxClient.GetMCode() || "" // 桌面端设备码
            };
            this.$utils.verifySystemLocation(
              newdataInfo,
              success => {
                this.$router.push({
                  name: "coursewareTeachingView",
                  query: {
                    type: 4,
                    coursewareId: this.CoursewareID,
                    isTeachingMode: true
                  }
                });
                setTimeout(() => {
                  this.$emit("getPrincipalWorkbenchGetCoursewareList");
                }, 500);
              },
              error => {
                this.openAuthDialog(this.CoursewareID, "", 4, 12);
              }
            );
          }
        }
      } else {
        layer.alert("请用桌面端打开程序", {
          mode: "teachingMode"
        });
      }
    },
    closeAuthCodeDialog () {
      this.TeachingModeforQrcode = false;
    },
    openAuthAfterSuccess () {
      this.TeachingModeforQrcode = false;
      setTimeout(() => {
        this.$router.push({
          name: "coursewareTeachingView",
          query: {
            type: 4,
            coursewareId: this.CoursewareID,
            isTeachingMode: true
          }
        });
      }, 500);
    },
    openAuthDialog (OLAPKey, type, model, introType) {
      let DeviceCode = "";
      if (this.$zxClient.IsClient) {
        DeviceCode = this.$zxClient.GetMCode();
      }
      let skey = "";
      if (model == 1) {
        // this.chooseScheduleCourseKey = OLAPKey;
        skey = OLAPKey;
      }
      console.log("this.dataInfo", this.dataInfo);
      if (this.dataInfo.HQAuthKey != 0) {
        let text =
          this.dataInfo.HQAuthKey == 1
            ? "  当前门店定位，课件授权方禁止访问课件"
            : " 当前门店定位，课件授权方正在审核中，暂时无法访问课件";
        layer.alert(text, {
          mode: "teachingMode"
        });
        return false;
      } else {
        this.authQrcodeDataInfo = {
          ScheduleCourseKey: skey,
          courserId: this.CoursewareID,
          client_id: this.$store.getters.clientId,
          SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
          DeviceTypeKey: "2", //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
          introType: introType,
          DeviceCode: DeviceCode //桌面端设备码
        };
        this.TeachingModeforQrcode = true;
      }
    },
    // 获取门店信息
    getMendianInfo () {
      GetSaaSClientMsg().then(
        result => {
          this.mendianInfo = result.data;
          console.log("getMendianInfo111");
          this.$store.commit("setSaaSClientInfo", result.data);
        },
        err => {
          layer.alert(err.msg);
        }
      );
    },
    getDataInfo () {
      var DeviceCode = "";
      if (this.$zxClient && this.$zxClient.IsClient) {
        DeviceCode = this.$zxClient.GetMCode(); // 340BC40DC769411B
      }
      teachingModeGetCoursewarePackageInfo(
        this.token.UserID,
        this.CoursewareID,
        DeviceCode
      ).then(result => {
        console.log(result.data, "getDataInfo");
        this.dataInfo = result.data;
      });
    },
    getDataList () {
      teachingModeGetCoursewareAttachList(this.CoursewareID).then(result => {
        console.log(result.data, "teachingModeGetCoursewareAttachList");
        this.dataList = result.data;
        this.$nextTick(o => {
          this.isLoadEnd = true;
          this.initSwiper();
        });
      });
    },
    initSwiper () {
      var that = this;
      this.swiper = new Swiper(".swiper_courseware_packge_list", {
        grabCursor: true,
        slidesPerView: 4,
        spaceBetween: 25,
        // loop: false, // 循环模式选项
        observer: false, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: false, // 修改swiper的父元素时，自动初始化swipe
        // navigation: {
        // nextEl: '.swiper-button-next',
        // prevEl: '.swiper-button-prev',
        // },
        on: {
          // 拖动结束后触发事件预加载. 判断最左边隐藏的课程数据是否小于十
          transitionEnd: function (callback) {
            that.swiper = this;
            that.swiperNewIndex = this.realIndex.toString();
            console.log(that.swiperNewIndex, "transitionEnd");
          }
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log("openCustomDialog", dialogInfo);
      if (this.$refs.teachingModeDialog) {
        this.$refs.teachingModeDialog.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      console.log("openCommonCustomDialogByFactoryDialog", dialogInfo);
      if (this.$refs.teachingModeCommonDialog) {
        this.$refs.teachingModeCommonDialog.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    },
    registerBusEvent () {
      this.$bus.on(this, "openCustomDialog", dialogInfo => {
        if (dialogInfo.routerName == "teachingMode") {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, "openCommonCustomDialog", dialogInfo => {
        if (dialogInfo.routerName == "teachingMode") {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    }
  }
};
</script>
<style scoped>
.teaching_mode {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: url('../../../../public/image/new_teaching_mode_bg.png') center
    no-repeat;

  background-size: 100%;
  overflow: hidden;
}
.teaching_mode_header {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 31.2rem;
  justify-content: space-between;
  z-index: 2;
}
.teaching_mode_header_left {
  width: 127rem;
  height: 37.44rem;
  display: flex;
}
.teaching_mode_header_left .teaching_mode_header_left_img {
  width: 33.5rem;
  height: 33.5rem;
  border-radius: 32.57rem;
  overflow: hidden;
  margin-left: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.teaching_mode_header_left .staff_name {
  width: 70rem;
  height: 26.52rem;
  font-size: 9.36rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-left: 3.9rem;
  color: #fff;
}
.teaching_mode_header_left .workbench_btn {
  width: 67.87rem;
  height: 26.52rem;
  background: linear-gradient(
      0deg,
      rgba(187, 198, 230, 0.2),
      rgba(187, 198, 230, 0.2)
    ),
    #777e90;
  border-radius: 39rem;
  font-size: 9.36rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: 3.9rem;
}
.teaching_mode_header_left .workbench_btn:hover {
  background: linear-gradient(
      0deg,
      rgba(187, 198, 230, 0.2),
      rgba(187, 198, 230, 0.2)
    ),
    #777e90;
  box-shadow: 0px 1.95rem 7.8rem rgba(22, 131, 249, 0.7);
  cursor: pointer;
}

.workbench_btn img {
  width: 9.36rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3.9rem;
}
.teaching_mode_header_right {
  width: 45.44rem;
  height: 33.44rem;
  overflow: hidden;
}
.teaching_mode_header_right_btn {
  margin-top: 6rem;
  width: 45rem;
  height: 20rem;
  opacity: 1;
  border-radius: 50rem;
  background: rgba(255, 96, 0, 1);
  font-size: 9rem;
  line-height: 20rem;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.teaching_mode_header_right_btn:hover {
  opacity: 0.8;
}

.teaching_mode_courseware_info {
  position: relative;
  width: 351rem;
  height: 251rem;
  left: 198.9rem;
  top: 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.courseware_info_name {
  width: 351rem;
  height: 26.52rem;
  line-height: 26.52rem;
  text-align: center;
  font-size: 14.04rem;
  color: rgba(255, 255, 255, 1);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 52.08%,
    rgba(0, 0, 0, 0) 100%
  );
}
.teaching_mode .teaching_mode_content {
  width: 100%;
  height: 148.2rem;
  position: absolute;
  /* left: 19.5rem; */
  bottom: 23.63rem;
}
.teaching_mode .courseware_packge_list {
  width: 100%;
  height: 148.98rem;
  padding: 15.6rem 28.92rem;
  position: relative;
}
.teaching_mode .courseware_packge_list .swiper_wrapper_before {
  display: block;
  content: '';
  width: 30rem;
  /* padding-top: 78px; */
  backdrop-filter: blur(7.92rem);
  position: absolute;
  left: 0;
  top: 15.5rem;
  bottom: 13.5rem;
  z-index: 3;
  /* background: linear-gradient(270deg, #000 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.5;
    border-radius: 14px 0px 0px 14px; */
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.teaching_mode .courseware_packge_list .swiper_wrapper_after {
  display: block;
  content: '';
  width: 30rem;
  /* padding-top: 78px; */
  backdrop-filter: blur(7.92rem);
  background: linear-gradient(90deg, #000 0%, rgba(255, 255, 255, 0) 100%);
  /* border-radius: 14px 0px 0px 14px; */
  opacity: 0.5;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  position: absolute;
  right: 0;
  top: 15.5rem;
  bottom: 13.5rem;
  z-index: 3;
}
.teaching_mode_nodta {
  background: url('../../../../public/image/teaching_no_courseware_packge.png')
    center no-repeat;
  margin: auto;
  line-height: 97.5rem;
  text-align: center;
  width: 100%;
  height: 100%;
}
.teaching_mode_nodta_text {
  font-size: 12.48rem;
  color: #ffffff;
  line-height: 214rem;
}
.body .layerdemo {
  border-radius: 20px;
}
body .layer-model .layer-wrap {
  background: inherit;
}
.teaching_mode_bg_ligth {
  background: #9ebceb;
  opacity: 0.3;
  filter: blur(75px);
  position: absolute;
  width: 537.42rem;
  height: 86.19rem;
  left: 105.69rem;
  top: 110rem;
  border-radius: 50%;
}

.teaching_mode_header_hover_img {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(208.99deg, #424b59 13.46%, #272f3a 86.66%);
  /* box-shadow: 0px 5px 20px rgba(41, 49, 61, 0.3); */
  border-radius: 32.57rem;
  width: 28.86rem;
  height: 28.86rem;
  overflow: hidden;
  text-align: center;
  line-height: 28rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 3.7rem;
  margin-left: 3.12rem;
  /* margin: auto; */
}
.teaching_mode_header_hover_img:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(208.99deg, #424b59 13.46%, #272f3a 86.66%);
  box-shadow: 0px 5px 20px rgba(22, 131, 249, 0.7);
}
.mendian_logo_content {
  width: 108.44rem;
  height: 40.1rem;
  border-radius: 7.8rem;
  /* overflow: hidden; */
  position: absolute;
  bottom: 180rem;
  left: 33.2rem;
  font-size: 9.36rem;
  color: white;
  display: flex;
  align-items: center;
  /* background: #383535eb; */
}
.teaching_mode_mendian_name {
  display: inline-block;
  margin-left: 6.5rem;
}
.teaching_mode_mendian_logo {
  width: 37.44rem;
  height: 37.44rem;
  border-radius: 7.8rem;
  overflow: hidden;
  margin-left: 1rem;
}
.teaching_mode_date {
  font-size: 11rem;
  width: 103rem;
  height: 20rem;
  line-height: 20rem;
  border-radius: 50rem;
  margin-top: 6.63rem;
  text-align: center;
  /* color: white; */
  background: rgba(255, 255, 255, 0.2);
}
.courseware_info_beginclass {
  width: 64rem;
  height: 64rem;
  margin-top: 23.4rem;
  justify-content: center;
  align-items: center;
  background: url('../../../../public/image/teaching_mode_beginclass.png')
    center no-repeat;
  background-size: 63rem;
  text-align: center;
  line-height: 64rem;
  color: white;
  font-size: 11.7rem;
  cursor: pointer;
}
.courseware_info_beginclass:hover {
}
</style>
