<template>
 <div class="form_info_detail micro_message">
        <div class="micro_message_edit content_marginRL single_shadow">
          <div class="message_edit_input">
            <textarea cols="30"
                      rows="10"
                      maxlength="100"
                      placeholder="请输入排课备注"
                      v-model="text"
                      @input="updateVal($event)"
                      @change="changeVal($event)"></textarea>
          </div>
        </div>
        <!-- 按钮组 -->
        <div class="footer_btn_group form_footer_btn_group">
          <el-button round
                     class="btn_light_red btn_hover_white"
                     @click.native="closeClick">取消</el-button>
          <el-button round
                     class="btn_light_blue btn_hover_bg_blue"
                     @click.native="confirmClick">确认</el-button>
        </div>
      </div>
</template>

<script>
export default {
  data () {
    return {
      text: ''
    };
  },
  props: {
    studentKeyValue: String,
    textContent: '',
    maxLength: {
      type: Number,
      default: 100
    }
  },
  created () {
    this.text = this.textContent;
  },
  methods: {
    updateVal (e) {
      this.onkeyupEvent(e);
    },
    changeVal (e) {
      this.onkeyupEvent(e);
    },
    onkeyupEvent (e) {
      let val = e.target.value;
      if (this.$utils.strLength(val) > this.maxLength * 2) {
        e.target.value = this.limitMaxLength(val, this.maxLength * 2);
        this.text = e.target.value;
      }
    },
    confirmClick () {
      this.$emit('onClickConfirm', this.text);
    },
    closeClick () {
      this.$emit('onClickClose');
    }
  }
};
</script>

<style>

</style>