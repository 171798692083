<template>
  <div class="pr">
    <div class="article_edit_input_box"
         :style="'bottom:'+bottomVal+'px'">
      <div class="input_left">
        <content-text class="ql-container"
                      ref="content"
                      v-model="ReplyContent"
                      :placeholder="'输入评论内容，最长支持1000字'"
                      @input="blurText"></content-text>
        <!-- <editor id="editor"
                ref="editor"
                class="ql-container input_box"
                @input="getText"
                showImgToolbarshowImgToolbar
                :placeholder="'输入评论内容，最长支持1000字'"
                :read-only="false"
                :focus="true"
                @focus="editorFocus"
                @ready="onEditorReady"
                @blur="blurChg">
        </editor> -->
      </div>
      <div class="input_right">
        <div class="input_btn"
             @click.stop="sendMessage">发布</div>
        <div :class="showMemeList?'input_swtich':'input_emoji'"
             @click.stop="sendPhizImg"></div>
      </div>
    </div>
    <div v-if="showMemeList"
         class="send_message_emoji_module">
      <meme-list @seletedMemeItem="seletedMemeItemAfter"
                 @deleteEmoji="deleteEmoji"></meme-list>
    </div>
  </div>
</template>

<script>
import contentText from '../../common-component/release-content/content-text';
import { getNewsDynamicReply } from '../../../../API/workbench';
import memeList from '../../common-component/release-content/meme-list/index.vue';
export default {
  data () {
    return {
      ReplyContent: '',
      firstFocus: true,
      isSend: true,
      isNum: 0,
      showMemeList: false,
      editorCtx: null,
      bottomVal: 0,
      focusNum: 0 // 兼容IOS第一次键盘弹出会默认将输入框顶上去
    };
  },
  components: {
    memeList, contentText
  },
  props: {
    // 回复框按钮
    btnName: {
      type: String,
      default: '发布'
    },
    EssayID: [String, Number], // 短文ID
    dataInfo: Object
  },
  created () {
    // this.editorCtx.insertText()
  },
  onReady: function () {
    // this.editorCtx.insertText()
  },
  methods: {
    blurText (text) {
      this.$emit('blurText', text);
    },
    // input 事件 给传递进来的值赋值.
    getText (e) {
      this.getEditorContents((value) => {
        this.ReplyContent = value;
      });
    },
    // 聚焦
    editorFocus (e) {
      this.showMemeList = false;
      if (uni.getSystemInfoSync().platform === 'ios') {
        if (this.focusNum > 0) { // IOS
          this.bottomVal = 250;
        } else {
          this.bottomVal = 0;
        }
        console.log('ios-聚焦 ', this.editorCtx);
      } else {
        this.bottomVal = 0;
        console.log('Android-聚焦 ', this.editorCtx);
      }
    },
    // 获取editor内容. 再进行转换.
    getEditorContents (callback) {
      let contentvalue = '';
      this.editorCtx.getContents({
        complete: res => {
          contentvalue = res.html;
          // 正则匹配截取alt属性.
          const pattern = new RegExp('<img\\b[^<>]*?\\balt="([A-Za-z0-9]*)?"[^<>]*/?>', 'g');
          contentvalue = contentvalue.replace(pattern, '{{$1}}');
          // 图片正择匹配
          contentvalue = contentvalue.replace(/<p>/g, '');
          contentvalue = contentvalue.replace(/<\/p>/g, '</br>');
          if (callback) {
            callback(contentvalue);
          }
        }
      });
    },
    seletedMemeItemAfter (item) {
      this.focusNum++; // IOS
      this.bottomVal = 0;
      this.showMemeList = false;
      // this.editorFocus();
      var that = this;
      this.$refs.content.dropRelease(item, 'meme');
      console.log(item, 'seletedMemeItemAfter');
    },
    setImgUrl (item) {
      return this.$store.getters.PCWebURL + '/WXBV/img/WXimg/expression/' + item.value + '.png';
    },
    // 添加表情
    sendPhizImg () {
      this.showMemeList = !this.showMemeList;
      if (this.showMemeList) {
        this.bottomVal = 275;
      } else {
        this.focusNum++; // IOS
        this.bottomVal = 0;
        this.editorCtx.insertText();
      }
      console.log(this.editorCtx, '添加表情11');
    },
    deleteEmoji () {
      this.$refs.content.deletedRelease();
    },
    // 发送成功
    sendMessage () {
      console.log('发送', this.ReplyContent);
      // 回复短文或者回复别人
      if (this.ReplyContent) {
        const essayTypeID = this.dataInfo.EssayID ? this.dataInfo.ID : 0;
        getNewsDynamicReply(this.EssayID, essayTypeID, {
          ReplyContent: this.ReplyContent
        }).then(result => {
          this.ReplyContent = '';
          this.bottomVal = 0;
          this.focusNum = 0;
          console.log(result.data, 'getNewsDynamicReply-result.data');
          this.$emit('sendSucceed', result.data);
        }, error => {
          this.$store.dispatch('popup/open', {
            content: error.msg
          });
        });
      }
    }

  },
  computed: {

  }
};
</script>

<style>
</style>
