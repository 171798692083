<template>
  <div class="form_info_list">
    <div v-show="dataInfo.ContentType!=2">
      <div class="from_btn_box flex">
        <div class="from_next_btn btn_hover_blue"
             :class="{'btn_hover_bg_blue':CouponInfo.type == 2}"
             @click.stop="changeActivity(2)">
          满减
        </div>
        <div class="from_next_btn btn_hover_blue"
             :class="{'btn_hover_bg_blue':CouponInfo.type == 4}"
             @click.stop="changeActivity(4)">
          代金
        </div>
        <div class="from_next_btn btn_hover_blue"
             :class="{'btn_hover_bg_blue':CouponInfo.type == 3}"
             @click.stop="changeActivity(3)" v-if="dataInfo.ContentType!=3">
          套餐
        </div>
      </div>
    </div>
    <div class="from_list from_list_required"
         v-show="dataInfo.ContentType==2||showTaocan">
      <div class="from_list_title">
        关联套餐
      </div>
      <div class="from_list_content">
        <!-- 套餐 -->
        <div class="WKT_information_block"
             v-if="!seletedPackage"
             @click.stop="onAddComboClick">
          <div class="WKT_slideshow_upload_box hover_border_blue">
            <div class="WKT_slideshow_upload_noImg">
              <div class="upload_icon">+</div>
              <div>选择套餐</div>
            </div>
          </div>
        </div>
        <!-- 套餐 -->
        <div v-if="seletedPackage"
             class="WKT_discount_package">
          <combo-enabled-list :showOperation="false"
                              :comboItem="seletedPackage"
                              :CouponInfo="CouponInfo"
                              @changeCombo="onAddComboClick"
                              @selectedItemDetails="doAfterSelectedItemDetails"></combo-enabled-list>
        </div>
        <div class="WKT_discount_info_from"
             v-if="seletedPackage">
          <!-- 优惠方式1满折2满减3特价4代金券', -->
          <form-input v-if="CouponInfo.type==2||CouponInfo.type==4"
                      :formTitle="'活动售价'"
                      :unintTitle="'元'"
                      v-model="CouponInfo.di_xian_jin"
                      :tipsTitle="vActivityCostTipForPackage"
                      :isNumber="true"
                      :required="false"
                      ref="packagedi_xian_jin"
                      @inputChange="changePackageSale"></form-input>
          <form-input v-else
                      :formTitle="'活动售价'"
                      :unintTitle="'元'"
                      v-model="CouponInfo.you_hu_money"
                      :isNumber="true"
                      :tipsTitle="vActivityCostTipForPackage"
                      :required="false"
                      ref="packagedi_xian_jin"
                      @inputChange="changePackageSale"></form-input>
          <form-input v-if="dataInfo.type==2||dataInfo.type==0"
                      :formTitle="'线上预付款'"
                      :unintTitle="'元'"
                      :isNumber="true"
                      :min="0"
                      :max="(CouponInfo.you_hu_money||CouponInfo.di_xian_jin)"
                      v-model="CouponInfo.buy_amount"
                      :required="false"
                      ref="package_buy_amount">
            <div class="font_gray"
                 v-if="dataInfo.type==2||dataInfo.type==0">到店支付<span class="font_black">{{vRemainingMoney}}</span>元</div>
          </form-input>

          <form-input :formTitle="'套餐原价'"
                      :unintTitle="'元'"
                      :isNumber="true"
                      v-model="CouponInfo.packgePrice"
                      :tipsTitle="''"
                      :required="false"></form-input>
          <form-input-date v-if="!allBuy"
                           v-model="CouponInfo.time_end"
                           :elementId="'classDate'"
                           :formTitle="'到店截止日期'"
                           :minDate="$utils.formatDateToLine(Date.new())"
                           :formPlaceholder="'请选择'"
                           :tipsTitle="'到期失效'"
                           @changeDate="changeEndTime">
          </form-input-date>
        </div>
      </div>
    </div>

    <!-- 满减 -->
    <div v-if="dataInfo.programmeType==0&&CouponInfo.type==2&&dataInfo.ContentType!=2&&!allBuy">
      <div class="from_list form_info_required">
        <div class="from_list_title">
          报名金额
        </div>
        <div class="from_list_content">
          <div class="from_list_content">
            <form-content-input :formTitle="'满'"
                                v-model="CouponInfo.xiao_fei_tiao_jian"
                                :tipsTitle="''"
                                :required="false"
                                :unintTitle="'元'"
                                :isNumber="true"
                                :inputClass="'from_list_input_three'"
                                :titleClass="''"></form-content-input>
            <form-content-input :formTitle="'减'"
                                v-model="CouponInfo.di_xian_jin"
                                :tipsTitle="vActivityCostTip"
                                :required="false"
                                :isNumber="true"
                                :unintTitle="'元'"
                                :inputClass="'from_list_input_three'"
                                :titleClass="''"></form-content-input>
            <div class="font_gray"
                 v-if="vActivityCostTip">{{vActivityCostTip}}</div>
          </div>
        </div>
      </div>
      <form-input v-if="!zeroBuy"
                  :formTitle="'线上预付款'"
                  :unintTitle="'元'"
                  v-model="CouponInfo.buy_amount"
                  :tipsTitle="vActivityCostTip"
                  :required="false"
                  ::isNumber="true"
                  :inputClass="'from_list_input_small'"></form-input>
      <form-input :formTitle="'适用范围'"
                  :formPlaceholder="'请输入优惠券名称，15字以内'"
                  v-model="CouponInfo.scopeExplain"
                  :required="true"></form-input>
    </div>
    <!-- 代金 -->
    <div v-if="dataInfo.programmeType==0&&CouponInfo.type==4&&dataInfo.ContentType!=2&&!allBuy">
      <form-input :formTitle="'抵扣金额'"
                  :unintTitle="'元'"
                  v-model="CouponInfo.di_xian_jin"
                  :tipsTitle="vActivityCostTip"
                  :isNumber="true"
                  :required="true"
                  :inputClass="'from_list_input_small'"></form-input>
      <form-input v-if="!zeroBuy"
                  :formTitle="'线上预付款'"
                  :unintTitle="'元'"
                  :isNumber="true"
                  v-model="CouponInfo.buy_amount"
                  :tipsTitle="vActivityCostTip"
                  :required="false"
                  :inputClass="'from_list_input_small'"></form-input>
      <form-input :formTitle="'适用范围'"
                  :formPlaceholder="'请输入优惠券名称，15字以内'"
                  v-model="CouponInfo.scopeExplain"
                  :required="true"></form-input>
    </div>
    <!-- 非参团 -->
    <form-input-date v-if="dataInfo.programmeType==0&&!showTaocan&&!allBuy&&dataInfo.ContentType!=2"
                     v-model="CouponInfo.time_end"
                     :elementId="'classDate'"
                     :formTitle="'到店截止日期'"
                     :required="true"
                     :minDate="$utils.formatDateToLine(Date.new())"
                     :formPlaceholder="'请选择'"
                     :tipsTitle="'到期失效'"
                     :inputClass="'from_list_input_small'"
                     @changeDate="changeEndTime">
    </form-input-date>
    <div class="from_list">
      <div class="from_list_title">
      </div>
      <div class="from_list_content">
        <div class="from_list_checked_box">
          <el-checkbox v-model="CouponInfo.only_newuser"
                       :true-label="1"
                       :false-label="0"
                       @change="seletedCheckBox"></el-checkbox>
          <span>限新生参加</span>
          <span class="font_gray">开启后在校学生不可领取</span>
        </div>
      </div>
    </div>
    <form-input :formTitle="'活动名称'"
                v-model="CouponInfo.active_title"
                :formPlaceholder="'微信分享转发时显示的标题，26-30个字内'"
                :required="true"></form-input>
    <form-input :formTitle="'活动摘要'"
                :formPlaceholder="'微信分享转发时显示的摘要，26-30个字内'"
                v-model="CouponInfo.coupon_discribe"
                :required="true"></form-input>
    <form-input-date v-model="CouponInfo.activity_time_end"
                     :elementId="'classDate'"
                     :formTitle="'活动截止日期'"
                     :required="true"
                     :minDate="$utils.formatDateToLine(Date.new())"
                     :formPlaceholder="'请选择'"
                     :tipsTitle="'到期不可参加'"
                     @changeDate="changeEndTime">
    </form-input-date>
    <form-input :formTitle="'最大招生数'"
                :unintTitle="'人'"
                v-model="CouponInfo.maxGetNum"
                :tipsTitle="'包含虚拟报名人数。'"
                :required="true"
                :isNumber="true"
                :decimalPlaces="0"
                :inputClass="'from_list_input_small'"></form-input>
    <step-btn :showPre="false"
              :showComfire="false"
              @preStep="preStep"
              @viewSettingShow="viewSettingShow"
              @nextStep="nextStep">
    </step-btn>
    <custom-dialog :title="'选择套餐'"
                   :visible.sync="isSelectComboListShow"
                   :before-close="doComboListCloseClick"> 
      <combo-selection-list ref="comboList"
                            :dataSourceLsit="selectComboList"
                            @addSelectedItem="doAfterAddSelectCombo"
                            @noCancelSelected="doComboListCloseClick"></combo-selection-list>
      <!-- @selectedItemDetails="doAfterSelectedItemDetails" -->
    </custom-dialog>
    <custom-dialog :title="'套餐详情'"
                   :visible.sync="isSelectComboDetailsShow"
                   :before-close="doComboDetailsCloseClick">
      <combo-select-details :detailInfo="selectComboDetailInfo"></combo-select-details>
    </custom-dialog>
  </div>
</template>
<script>
import { MessageSearchPagePackage, GetComboInfo } from '../../../../../../API/workbench';
import comboSelectionList from '../../../../../wkt-back/discount-package/combo-list/combo-selection-list';
import comboEnabledList from './common/combo-enabled-list';
import comboSelectDetails from '../../../../../wkt-back/discount-package/combo-select-details';
import formInput from './common/form-input';
import formContentInput from './common/form-content-input';
import formInputDate from './common/form-input-date';
import stepBtn from './step-btn';
export default {
  data () {
    return {
      selectComboList: [],
      seletedPackage: null,
      selectComboDetailInfo: null,
      isSelectComboListShow: false, // 历史套餐列表显示
      isSelectComboDetailsShow: false,
      searchObj: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 0, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配
        orderBy: '', // 排序方向
        sequence: '', // 排序字段
        StatusKey: 2//	套餐状态0-全部;2-启用;3-停用
      },
      showTaocan: false
    };
  },
  components: {
    stepBtn, comboSelectionList, comboEnabledList, comboSelectDetails, formInput, formInputDate, formContentInput

  },
  props: {
    dataInfo: Object,
    CouponInfo: Object,
    menuItem: Object,
    settingInfo: Object

  },
  mounted () {
    this.getPackageList();
    if(this.dataInfo.ContentType == 2){
      this.showTaocan=true
    }
  },
  computed: {
    vRemainingMoney () {
      if (this.CouponInfo.type == 2 || this.CouponInfo.type == 4) {
        return Number((Number(this.CouponInfo.di_xian_jin) - Number(this.CouponInfo.buy_amount)).toFixed(2));
      } else {
        return Number((Number(this.CouponInfo.you_hu_money) - Number(this.CouponInfo.buy_amount)).toFixed(2));
      }
    },
    freeBuy(){
       return this.dataInfo.type == 0;
    },
    // 0元引流
    zeroBuy () {
      return this.dataInfo.type == 1;
    },
    // 团购.
    groupBuy () {
      return this.dataInfo.type == 2;
    },
    noOnLine(){
      console.log("this.$store.getters.SaaSClientInfo.WktInfo.IsOpenPay",this.$store.getters.SaaSClientInfo.WktInfo.IsOpenPay)
      if (Number(this.$store.getters.SaaSClientInfo.WktInfo.IsOpenPay) == 0) {
        // layer.alert('未开通支付功能，请联系专属客服开通支付功能。');
        return true;
      }else{
        return false
      }
    },
      
    // 在线全款.
    allBuy () {
      return this.dataInfo.type == 3;
    },
    vActivityCostTip () {
      return this.zeroBuy ? '0元报名，到店支付' : '';
    },
    vActivityCostTipForPackage () {
      if (this.zeroBuy) {
        return '0元报名，到店支付';
      } else if (this.groupBuy||this.freeBuy) {
        return '';
      } else {
        return !this.zeroBuy ? '在线支付' : '';
      }
    }
  },
  methods: {
    viewSettingShow(){
      this.$emit("viewSettingShow")
    },
    // 非团模式可更换活动方式, 2满减,3套餐,4代金
    changeActivity (type) {
      if (this.CouponInfo.type == type) {
        return false;
      }
      if (type == 2) {
        // this.$refs.comboList.selectedItem = null;
        this.showTaocan = false;
        this.CouponInfo.type = 2;
        this.seletedPackage = null;
        this.CouponInfo.scopeExplain = '';
        this.CouponInfo.di_xian_jin = '';
        this.CouponInfo.you_hu_money = '';
        this.CouponInfo.taocanid = 0;
        this.CouponInfo.packgePrice = 0;
        this.CouponInfo.buy_amount = 0;
      } else if (type == 3) {     // 套餐
        this.CouponInfo.type = 3;
        this.CouponInfo.scopeExplain = '';
        this.CouponInfo.you_hu_money = '';
        this.CouponInfo.di_xian_jin = '';
        this.CouponInfo.xiao_fei_tiao_jian = '';
        this.CouponInfo.buy_amount = 0;
        this.showTaocan = true;
      } else {    // 代金
        // this.$refs.comboList.selectedItem = null;
        this.showTaocan = false;
        this.CouponInfo.type = 4;
        this.seletedPackage = null;
        this.CouponInfo.xiao_fei_tiao_jian = '';
        this.CouponInfo.scopeExplain = '';
        this.CouponInfo.you_hu_money = '';
        this.CouponInfo.di_xian_jin = '';
        this.CouponInfo.taocanid = 0;
        this.CouponInfo.packgePrice = 0;
        this.CouponInfo.buy_amount = 0;
      }
      console.log(this.CouponInfo, 'changeActivity', type);
    },
    seletedCheckBox (val) {
      this.CouponInfo.only_newuser = val ? 1 : 0;
      console.log(this.CouponInfo.only_newuser, 'seletedCheckBox', val);
    },
    changeEndTime () {
      console.log(this.CouponInfo);
    },
    preStep () {
      this.$emit('preStep', this.menuItem);
    },
    nextStep () {
      if (!this.verifyData()) {
        return false;
      }
      this.$emit('nextStep', this.menuItem);
    },
    doComboListCloseClick () {
      this.isSelectComboListShow = false;
    },
    doComboDetailsCloseClick () {
      this.isSelectComboDetailsShow = false;
    },
    // 启用选择的历史套餐
    onStartComboClick (item, index) {
      console.log(this.enableComboItem, 'this.enableComboItem');
      if (item.id == this.enableComboItem.id) {
        return false;
      }
      this.enableComboItem = item;
    },
    // 查看套餐详情
    doAfterSelectedItemDetails (item) {
      console.log(item, '查看套餐详情');
      GetComboInfo(item.TableID).then(result => {
        // this.isSelectComboListShow = false;
        this.selectComboDetailInfo = result.data;
        this.CouponInfo.type=3;
        this.isSelectComboDetailsShow = true;
      });
    },
    // 添加套餐
    doAfterAddSelectCombo (item, callBack) {
      console.log(item, '添加套餐');
      this.CouponInfo.type = 3;
      let flag = false;
      if (!item || !item.TableID) {
        layer.alert('请先选择套餐');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.seletedPackage = item;
      this.CouponInfo.scopeExplain = '适用' + this.seletedPackage.PackageName;
      this.CouponInfo.taocanid = item.TableID;
      this.CouponInfo.packgePrice = Number(item.SalePrice);
      this.CouponInfo.you_hu_money = 0;
      this.CouponInfo.di_xian_jin = 0;
      this.CouponInfo.buy_amount = 0;
      this.CouponInfo.time_end = '';
      this.isSelectComboListShow = false;
      this.$nextTick(() => {
        if (this.$refs.package_buy_amount) {
          this.$refs.package_buy_amount.inputValue = 0;
        }
        if (this.$refs.packagedi_xian_jin) {
          this.$refs.packagedi_xian_jin.inputValue = 0;
        }
      });
    },
    getPackageList () {
      MessageSearchPagePackage(this.searchObj).then(result => {
        console.log(result.data.PageDataList, '选择套餐添加');
        result.data.PageDataList.forEach(o => {
          o.comboColor = 'type_blue';
          o.isOpt = false;
        });
        // this.selectComboList = result.data.PageDataList;
        result.data.PageDataList.forEach(o => {
          // if (this.CouponInfo.taocanid == o.TableID) {
          //   if (this.dataInfo.programmeType != 1) {
          //     this.changeActivity(3);
          //   }
          //   this.seletedPackage = o;
          // }
          if (Number(o.SalePrice) > 0) {
            if (!o.ExpireTime) {
              this.selectComboList.push(o);
            } else if (Date.new(o.ExpireTime + ' 23:59:59') > Date.new()) {
              this.selectComboList.push(o);
            }
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },

    // 选择套餐添加
    onAddComboClick () {
      this.isSelectComboListShow = true;
    },
    verifyData () {
      let isComfire = true;
      let warningTitle = '';
      console.log("this.noOnLine",this.noOnLine,this.CouponInfo.buy_amount)
      if(this.noOnLine&&Number(this.CouponInfo.buy_amount)>0){
        isComfire = false;
       layer.alert('未开通支付功能，请联系专属客服开通支付功能。');
        return isComfire;
      }
      if (this.dataInfo.programmeType == 1 || this.showTaocan) {
        if (!this.seletedPackage) {
          isComfire = false;
          layer.alert('请选择套餐');
          return isComfire;
        } else {
          if (this.CouponInfo.type == 2 || this.CouponInfo.type == 4) {
            if (Number(this.CouponInfo.di_xian_jin) <= 0) {
              isComfire = false;
              layer.alert('活动售价不能低或等于0');
              return isComfire;
            }
            if(Number(this.CouponInfo.di_xian_jin) >Number(this.seletedPackage.SalePrice)){
                isComfire = false;
               layer.alert('活动售价不能大于套餐原价');
               return isComfire;
            }
          } else {
            if (Number(this.CouponInfo.you_hu_money) <= 0) {
              isComfire = false;
              layer.alert('活动售价不能低或等于0');
              return isComfire;
            }
            if(Number(this.CouponInfo.you_hu_money) >Number(this.seletedPackage.SalePrice)){
                isComfire = false;
               layer.alert('活动售价不能大于套餐原价');
               return isComfire;
            }
          }
          // if (this.dataInfo.type == 2 && Number(this.CouponInfo.buy_amount) <= 0) {
          //   isComfire = false;
          //   layer.alert('线上预付款不能低或等于0');
          //   return isComfire;
          // }
          if (this.dataInfo.type == 2 && this.vRemainingMoney < 0) {
            isComfire = false;
            layer.alert('线上预付款不能大于活动售价');
            return isComfire;
          }
          if (Number(this.CouponInfo.packgePrice) <= 0) {
            isComfire = false;
            layer.alert('活动原价不能低或等于0');
            return isComfire;
          }
          if (!this.allBuy && !this.CouponInfo.time_end) {
            isComfire = false;
            layer.alert('请选择到店截止日期');
            return isComfire;
          }
        }
      } else if (!this.allBuy) {
        // 满减
        if (this.dataInfo.programmeType == 0 && this.CouponInfo.type == 2) {
          if (!this.CouponInfo.xiao_fei_tiao_jian) {
            isComfire = false;
            layer.alert('请输入满多少元');
            return isComfire;
          }
          if (this.CouponInfo.di_xian_jin <= 0) {
            isComfire = false;
            layer.alert('请输入减多少元');
            return isComfire;
          }
        }
        // 代金
        if (this.dataInfo.programmeType == 0 && this.CouponInfo.type == 4) {
          if (this.CouponInfo.di_xian_jin <= 0) {
            isComfire = false;
            layer.alert('请输入抵扣金额');
            return isComfire;
          }
        }
        // if (!this.zeroBuy && this.CouponInfo.buy_amount <= 0) {
        //   isComfire = false;
        //   layer.alert('请输入线上预付款');
        //   return isComfire;
        // }
        if (!this.CouponInfo.scopeExplain) {
          isComfire = false;
          layer.alert('请输入适用范围');
          return isComfire;
        }
      }
      if (this.dataInfo.type == 3) {      // 玩法为线上全款.
        this.CouponInfo.buy_amount = Number(this.CouponInfo.you_hu_money) || Number(this.CouponInfo.di_xian_jin);
      }
      this.CouponInfo.time_start = this.$utils.formatDateToLine(Date.new());
      // 非选择套餐触发.
      if (!this.seletedPackage && !this.CouponInfo.time_end) {
        isComfire = false;
        layer.alert('请选择到店截止日期');
        return isComfire;
      }

      if (!this.CouponInfo.active_title) {
        isComfire = false;
        layer.alert('请输入活动名称');
        return isComfire;
      }
      if (!this.CouponInfo.coupon_discribe) {
        isComfire = false;
        layer.alert('请输入活动摘要');
        return isComfire;
      }
      if (!this.CouponInfo.activity_time_end) {
        isComfire = false;
        layer.alert('请选择活动截止日期');
        return isComfire;
      }
      if (!this.zeroBuy && this.seletedPackage) {
        this.CouponInfo.time_end = this.CouponInfo.activity_time_end;
      }
      if (!this.CouponInfo.maxGetNum) {
        isComfire = false;
        layer.alert('请输入最大招生数');
        return isComfire;
      }
      
      return isComfire;
    },
    initGetPackage (CouponInfo) {
      console.log('initGetPackage', this.selectComboList, CouponInfo);
      if (CouponInfo.taocanid > 0) {
        GetComboInfo(CouponInfo.taocanid).then(result => {
          console.log(result.data, '获取详情套餐详情.', this.dataInfo);

          if (this.dataInfo.programmeType != 1) {
            this.CouponInfo.type = 3;
            this.showTaocan = true;
          }
          this.seletedPackage = result.data;
        });
      }
    },
    changePackageSale (val) {
      console.log('changePackageSale');
      // 线上全款
      if (this.allBuy) {
        this.CouponInfo.buy_amount = val;
      }
    }
  }
};
</script>
