<template>
  <div class="">
    <!-- 音乐 -->
    <div>
      <div class="from_title">
        <span>音乐</span>
        <span class="font_gray">添加音乐增加活动氛围</span>
      </div>
      <div class="from_list">
        <div class="from_list_title">
          更换音乐
        </div>
        <div class="from_list_content">
          <div class="font_black from_list_input"
               v-if="this.PosterInfo.settingJson.musicTarget&&this.PosterInfo.settingJson.musicTarget.title">
            {{this.PosterInfo.settingJson.musicTarget.title}}
          </div>
          <div class="font_black from_list_input"
               v-else>
            无
          </div>
        </div>
        <div class="btn_hover_white"
             @click.stop="showMusicDialog">
          选择音乐
        </div>
      </div>
    </div>
    <!-- 海报 -->
    <div>
      <div class="from_title">
        <span>海报</span>
        <span class="font_gray">请上传小于5M的JPG/PNG图片，建议尺寸640px*495px。</span>
      </div>
      <div class="from_list">
        <div class="from_list_title">
        </div>
        <div class="from_list_content">
          <crop-upload-img :size="''"
                           style=""
                           :imgWidth="640"
                           :imgHeight="495"
                           :imgClass="'from_list_img pr'"
                           :remarks="''"
                           :isShowImg="true"
                           :ImgURl="PosterInfo.pic"
                           @seletedItem="seletedSetScrollTop(207)"
                           @uploadImgSuccess="doAfterUploadImgSuccess"></crop-upload-img>
        </div>
      </div>
    </div>
    <div v-for="(item,key) in setSortList"
         :key="key">

      <form-textarea v-if="contentSetting.brief&&item.specialName=='活动介绍'"
                     :formTitle="'活动介绍'"
                     v-model="contentSetting.brief.content"
                     :tipsTitle="'此处填写活动内容'"
                     :required="false"
                     :unintTitle="''"
                     :titleClass="''"
                     @seletedItem="seletedSetScrollTop('活动介绍')"
                     @change="changeBrief"></form-textarea>
      <form-textarea v-if="contentSetting.introduce&&item.type==200"
                     :formTitle="'机构简介'"
                     v-model="contentSetting.introduce.content"
                     :tipsTitle="'默认显示微官网首页机构简介内容，可直接修改内容'"
                     :required="false"
                     :unintTitle="''"
                     :titleClass="''"
                     @seletedItem="seletedSetScrollTop(200)"
                     @change="changeIntroduce"></form-textarea>
      <mien v-if="contentSetting.imgList&&item.type==206"
            :imgList="contentSetting.imgList.form.imgList"
            @changeImg="changeMien"
            @seletedMien="seletedSetScrollTop(206)"></mien>
    </div>
    <div>
      <div class="from_title">
        <span>联系方式</span>
        <span class="font_gray"></span>
      </div>

      <form-input :formTitle="'活动举办方'"
                  v-model="CouponInfo.t_company"
                  :required="false"
                  @seletedItem="seletedSetScrollTop(208)"
                  @input="changet_company"></form-input>
      <form-input :formTitle="'活动联系地址'"
                  v-model="CouponInfo.t_address"
                  :required="false"
                  @seletedItem="seletedSetScrollTop(208)"
                  @input="changet_address"></form-input>
      <form-input :formTitle="'活动联系手机'"
                  v-model="CouponInfo.t_telephone"
                  :isPhone="true"
                  :required="false"
                  @seletedItem="seletedSetScrollTop(208)"
                  @input="changet_telephone"></form-input>
    </div>
    <!-- <div>
      <div class="from_title">
        <span>海报</span>
        <span class="font_gray">请上传小于5M的JPG/PNG图片，建议尺寸640px*495px。</span>
      </div>
      <div class="from_list">
        <div class="from_list_title">
        </div>
        <div class="from_list_content">

          <crop-upload-img :size="''"
                           style=""
                           :imgWidth="640"
                           :imgHeight="495"
                           :imgClass="'from_list_img pr'"
                           :remarks="''"
                           :isShowImg="true"
                           :ImgURl="PosterInfo.pic"
                           @seletedItem="seletedSetScrollTop(207)"
                           @uploadImgSuccess="doAfterUploadImgSuccess"></crop-upload-img>
        </div>
      </div>
    </div>

    <form-textarea v-if="contentSetting.brief"
                   :formTitle="'活动介绍'"
                   v-model="contentSetting.brief.content"
                   :tipsTitle="'此处填写活动内容'"
                   :required="false"
                   :unintTitle="''"
                   :titleClass="''"
                   @seletedItem="seletedSetScrollTop('活动介绍')"
                   @change="changeBrief"></form-textarea>
    <form-textarea v-if="contentSetting.introduce"
                   :formTitle="'机构简介'"
                   v-model="contentSetting.introduce.content"
                   :tipsTitle="'默认显示微官网首页机构简介内容，可直接修改内容'"
                   :required="false"
                   :unintTitle="''"
                   :titleClass="''"
                   @seletedItem="seletedSetScrollTop(200)"
                   @change="changeIntroduce"></form-textarea>
    <mien v-if="contentSetting.imgList"
          :imgList="contentSetting.imgList.form.imgList"
          @changeImg="changeMien"
          @seletedMien="seletedSetScrollTop(206)"></mien>
    <div>
      <div class="from_title">
        <span>联系方式</span>
        <span class="font_gray"></span>
      </div>
    
      <form-input :formTitle="'活动举办方'"
                  v-model="CouponInfo.t_company"
                  :required="false"
                  @seletedItem="seletedSetScrollTop(208)"
                  @input="changet_company"></form-input>
      <form-input :formTitle="'活动联系地址'"
                  v-model="CouponInfo.t_address"
                  :required="false"
                  @seletedItem="seletedSetScrollTop(208)"
                  @input="changet_address"></form-input>
      <form-input :formTitle="'活动联系手机'"
                  v-model="CouponInfo.t_telephone"
                  :isPhone="true"
                  :required="false"
                  @seletedItem="seletedSetScrollTop(208)"
                  @input="changet_telephone"></form-input>
    </div> -->
    <step-btn :showPre="true"
              :showNext="false"
              @preStep="preStep"
              @submitData="confirm">
    </step-btn>
    <!-- 新版海报券选择音乐列表 -->
    <custom-dialog :title="'选择音乐'"
                   width="740px"
                   :moduleDescription="''"
                   :append-to-body="true"
                   :visible.sync="isShowMusic"
                   :before-close="closeIsShowMusicDialog">
      <ishow-editor-music-list :dataInfo="PosterInfo.settingJson"
                               @afterSuccess="setMusicSetting"
                               @cancelClick="closeIsShowMusicDialog"></ishow-editor-music-list>
    </custom-dialog>
  </div>
</template>
<script>
// import cropUploadImg from '../../../../../wkt-back/common/crop-upload-img';
import cropUploadImg from './common/crop-upload-img';
import formTextarea from './common/form-textarea';
import formInput from './common/form-input';
import stepBtn from './step-btn';
import mien from './content-setting-components/mien';
import ishowEditorMusicList from './ishow-editor-music-list/index';
import bus from '../../../../../../js/bus.js';
import { AddActivitiesCoupon, EditActivitiesCoupon } from '../../../../../../API/workbench';
import { convertToMd5 } from '../../../../../../utils/upLoadTextImg';
export default {
  data () {
    return {
      isShowMusic: false,
      mendianInfo: this.$store.getters.SaaSClientInfo,
      imgList: [] // 临时装载而已.
    };
  },
  components: {
    stepBtn, formTextarea, cropUploadImg, formInput, ishowEditorMusicList, mien
  },
  props: {
    dataInfo: Object,
    CouponInfo: Object,
    menuItem: Object,
    settingInfo: Object,
    coverInfo: Object,
    PosterInfo: Object,
    contentSetting: Object

  },
  computed: {
    vImg () {
      if (!this.PosterInfo.pic) {
        this.PosterInfo.pic = '';
      }
      if (this.PosterInfo.pic.indexOf('http') > -1) {
        return this.PosterInfo.pic;
      } else {
        return this.$store.getters.CDNURL + this.PosterInfo.pic;
      }
    },
    // 默认显示logo
    vDefaultImg () {
      return 'this.src="' + require('../../../../../../../public/image/no_logo@2x.png') + '"';
    },
    vSettingInfo () {
      let settingInfo = '';
      if (this.dataInfo) {
        settingInfo = JSON.parse(this.dataInfo.activitiesSetting);
      }
      return settingInfo;
    },
    groupStepNum () {
      return this.vSettingInfo.groupSetting.degreeRestriction.defaultValue;
    },
    setSortList () {
      var newArr = [];
      if (this.PosterInfo && this.PosterInfo.pageJson) {
        // let cover = this.findItem(207)    //封面
        // let contact = this.findItem(208)      //联系方式
        let carousel = this.findItem(206);   // 轮播
        let brief = this.findItem('活动介绍');   // 活动介绍
        let introduce = this.findItem(200);    // 机构介绍

        console.log(carousel, brief, introduce);
        carousel ? newArr.push(carousel) : newArr;
        brief ? newArr.push(brief) : newArr;
        introduce ? newArr.push(introduce) : newArr;
        // cover ? newArr.push(cover) : newArr
        // contact ? newArr.push(contact) : newArr
        newArr.sort((x, y) => {
          return Number(x.position.top) - Number(y.position.top);
        });
      }
      console.log(newArr, 'setSortList');
      return newArr;
    }
  },
  created () {
    console.log('content-setting this.coverInfo', this.coverInfo);
    console.log('content-setting this.PosterInfo', this.PosterInfo, this.PosterInfo.settingJson);
    // this.PosterInfo.pic = this.coverInfo.coverimg || '';

    if (this.coverInfo && this.coverInfo.coverimg) {
      this.PosterInfo.pic = this.coverInfo.coverimg
    }
  },
  methods: {
    findItem (type) {
      let seletedItem = null;
      let pageJson = null;
      console.log(this.PosterInfo, 'this.findItem');
      if (this.PosterInfo.pageJson && !Array.isArray(this.PosterInfo.pageJson)) {
        pageJson = JSON.parse(this.PosterInfo.pageJson);
      } else {
        pageJson = this.PosterInfo.pageJson;
      }
      console.log(pageJson, 'pageJson');
      if (pageJson) {
        if (Number(type)) {
          seletedItem = pageJson[0].elJson.find(o => {
            return o.type == type;
          });
        } else {
          seletedItem = pageJson[0].elJson.find(o => {
            return o.specialName == type;
          });
        }
      }
      return seletedItem;
    },
    changeBrief (val) {
      if (this.PosterInfo) {
        this.PosterInfo.pageJson[0].elJson.forEach(obj => {
          let x = val.replace(/\n/g, '<br>');
          if (obj.specialName == '活动介绍') {
            // obj.form.t_company = this.CouponInfo.t_company;
            this.$set(obj, 'content', x);
          }
        });
      }
    },
    changeIntroduce (val) {
      console.log('val', val);
      if (this.PosterInfo) {
        let x = val.replace(/\n/g, '<br>');
        this.PosterInfo.pageJson[0].elJson.forEach(obj => {
          if (obj.type == 200) {
            this.$set(obj, 'content', x);
          }
        });
      }
    },
    changet_company () {
      console.log('this.changet_company', this.PosterInfo);
      if (this.PosterInfo) {
        this.PosterInfo.pageJson[0].elJson.forEach(obj => {
          if (obj.type == 208) {
            console.log(obj, '208');
            // obj.form.t_company = this.CouponInfo.t_company;
            this.$set(obj.form, 't_company', this.CouponInfo.t_company);
          }
        });
      }
    },
    changet_address () {
      if (this.PosterInfo) {
        this.PosterInfo.pageJson[0].elJson.forEach(obj => {
          if (obj.type == 208) {
            // obj.form.t_address = this.CouponInfo.t_address;
            this.$set(obj.form, 't_address', this.CouponInfo.t_address);
          }
        });
      }
    },
    changet_telephone (val) {
      if (this.PosterInfo) {
        this.PosterInfo.pageJson[0].elJson.forEach(obj => {
          if (obj.type == 208) {
            // obj.form.t_telephone = this.CouponInfo.t_telephone;
            this.$set(obj.form, 't_telephone', this.CouponInfo.t_telephone);
          }
        });
      }
    },
    changeMien () {
      console.log('changeMien');
      if (this.PosterInfo) {
        if (this.contentSetting.imgList.form.imgList) {
          this.contentSetting.imgList.form.imgList.forEach(o => {
            o.title = o.title.toString();
          });
        }
        this.PosterInfo.pageJson[0].elJson.forEach(obj => {
          if (obj.type == 206) {
            // obj.form.t_telephone = this.CouponInfo.t_telephone;
            this.$set(obj.form, 'imgList', this.contentSetting.imgList.form.imgList);
          }
        });
      }
    },
    // 选中,设置滚动条高度定位.
    seletedSetScrollTop (type) {
      this.$emit('setscorllTop', type);
    },
    preStep () {
      this.$emit('preStep', this.menuItem);
    },
    nextStep () {
      this.$emit('nextStep', this.menuItem);
    },
    closeIsShowMusicDialog () {
      this.isShowMusic = false;
    },
    showMusicDialog () {
      this.isShowMusic = true;
    },
    setMusicSetting (item) {
      console.log(item, 'item');
      if (item) {
        this.PosterInfo.settingJson.isMusic = true;
        this.PosterInfo.settingJson.musicTarget = item;
      } else {
        this.PosterInfo.settingJson.isMusic = false;
        this.PosterInfo.settingJson.musicTarget = {};
      }
    },
    doAfterUploadImgSuccess (imgUrl) {
      console.log('doAfterUploadImgSuccess', imgUrl, this.PosterInfo);
      if (this.PosterInfo.pageJson) {
        this.PosterInfo.pageJson[0].elJson.forEach(o => {
          if (o.type == 207) {
            console.log("207777", o)
            o.isActive = true;
            o.content = imgUrl;
          }
        })
        // let locaItem = this.PosterInfo.pageJson[0].elJson.find(o => {
        //   return o.type == 207;
        // });
        //  console.log('locaItem', locaItem);
        // if (locaItem) {
        //   locaItem.isActive = true;
        //   locaItem.content = imgUrl;
        // }
      }
      // content
      this.PosterInfo.pic = imgUrl;
    },
    doAfterUploadImgSuccessForLogo (imgUrl) {
      this.coverInfo.coverimg = imgUrl;
    },
    verifyData () {
      let isComfire = true;
      // if (!this.CouponInfo.t_company) {
      //   isComfire = false;
      //   layer.alert('请输入活动举办方');
      //   return isComfire;
      // }
      // if (!this.CouponInfo.t_address) {
      //   isComfire = false;
      //   layer.alert('请输入活动联系地址');
      //   return isComfire;
      // }
      // if (!this.CouponInfo.t_telephone) {
      //   isComfire = false;
      //   layer.alert('请输入活动联系手机');
      //   return isComfire;
      // }
      return isComfire;
    },
    confirm (callback) {
      this.$emit('verifyAllData', () => {
        this.submitData(callback);
      }, callback);
    },
    submitData (callback) {
      console.log('submitDataPosterInfo', this.PosterInfo);
      console.log('submitDataCouponInfo', this.CouponInfo);
      console.log('submitDatadataInfo', this.dataInfo);
      if (!this.verifyData()) {
        if (callback) {
          callback();
        }
        return false;
      }
      if (this.vSettingInfo.groupSetting.isOpen) {
        if (this.groupStepNum == 1) {
          this.CouponInfo.groupSetting.target_2_A = null;
          this.CouponInfo.groupSetting.target_2_B = null;
          this.CouponInfo.groupSetting.target_3_A = null;
          this.CouponInfo.groupSetting.target_3_B = null;
        } else if (this.groupStepNum == 2) {
          this.CouponInfo.groupSetting.target_3_A = null;
          this.CouponInfo.groupSetting.target_3_B = null;
        } else {
        }
      } else {
        this.CouponInfo.groupSetting = null;
      }
      if (this.dataInfo.activitiesRelevance == 1) {     // 普通券
        this.CouponInfo.if_fenxiang = 2;
      } else if (this.dataInfo.activitiesRelevance == 2) {    // 转介绍
        this.CouponInfo.if_fenxiang = 1;
      } else {                                        // 裂变
        this.CouponInfo.if_fenxiang = 3;
      }
      // this.CouponInfo.if_fenxiang = this.dataInfo.activitiesRelevance;
      this.CouponInfo.coupon_name = this.CouponInfo.scopeExplain;

      let obj = {
        // CouponInfo: JSON.stringify(this.CouponInfo),
        // PosterInfo: JSON.stringify(this.PosterInfo)
        CouponInfo: this.$utils.parseJson(this.CouponInfo),
        PosterInfo: this.$utils.parseJson(this.PosterInfo)
      };
      // 虚拟人数开启   //临时注释.
      if (this.settingInfo.virtualSoftHitsSetting.isOpen) {
        let dataObj = this.CouponInfo.virtual_get_person_setting;
        if (dataObj && Number(dataObj.perDayNum) <= 0) {
          this.CouponInfo.virtual_get_person_setting = '';
        }
      } else {
        this.CouponInfo.virtual_get_person_setting = '';
      }
      if (this.CouponInfo.virtual_get_person_setting) {
        console.log('virtual_get_person_setting', this.CouponInfo.virtual_get_person_setting);
        obj.CouponInfo.virtual_get_person_setting = JSON.stringify(this.CouponInfo.virtual_get_person_setting);
      } else {
        obj.CouponInfo.virtual_get_person_setting = '';
      }
      obj.PosterInfo.pageJson = JSON.stringify(this.PosterInfo.pageJson);
      obj.PosterInfo.settingJson = JSON.stringify(this.PosterInfo.settingJson);
      if (this.PosterInfo.ID > 0) {
        this.editActivity(obj, callback);
      } else {
        this.addActivity(obj, callback);
      }
    },
    // 新增
    addActivity (obj, callback) {
      AddActivitiesCoupon(obj).then(result => {
        if (callback) {
          callback();
        }
        layer.alert('新增成功');
        this.$emit('success');
        console.log(result.data, 'submitData');
      }).catch(error => {
        if (callback) {
          callback();
        }
        layer.alert(error.msg);
        console.log(error, 'errorsubmitData');
      });
    },
    // 修改
    editActivity (obj, callback) {
      EditActivitiesCoupon(this.CouponInfo.id, obj).then(result => {
        if (callback) {
          callback();
        }
        layer.alert('修改成功');
        this.$emit('success');
        console.log(result.data, 'submitData');
      }).catch(error => {
        if (callback) {
          callback();
        }
        layer.alert(error.msg);
        console.log(error, 'errorsubmitData');
      });
    }

  }
};
</script>