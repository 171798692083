<template>
  <div class="margin_left_right_div">
    <div class="template_minOrmax_width_div">
      <div class="classSpendStatisticsAnalyze">
        <div class="teacher_performance_statistics course_statistics_box"
             style="min-height: 946px;">
          <div class="course_statistics_left box_shadow_border_div"
               style="height: 880px;">
            <div class="course_statistics_left_title">
              考勤统计
            </div>
            <year-report></year-report>
            <month-report @clickItem="clickItemAfter"></month-report>
          </div>
          <right-content ref="rightContent"
                         @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"></right-content>
        </div>
        <dialog-factory ref="classspendStatisticsAnalyze"
                        :routerName="'classspendStatisticsAnalyze'"></dialog-factory>
        <dialog-factory-common ref="classspendDialogFactoryCommon"></dialog-factory-common>
      </div>
    </div>
  </div>
</template>
<script>
import dialogFactory from '../../common/dialog-factory';
import yearReport from './year-report';
import monthReport from './month-report';
import rightContent from './right-content';
export default {
  name: 'classSpendStatisticsAnalyze',
  data () {
    return {
    };
  },
  components: {
    yearReport, monthReport, rightContent, dialogFactory
  },
  mounted () {
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      console.log('openCustomDialog');
      if (dialogInfo.routerName == 'classSpendStatisticsAnalyze') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      console.log(dialogInfo, 'openCommonCustomDialog');
      if (dialogInfo.routerName == 'classSpendStatisticsAnalyze') {
        console.log('%%222');
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });
  },
  methods: {
    clickItemAfter (item) {
      console.log('clickItemAfter', item);
      this.$refs.rightContent.openCourseReportGetDataByDate(item.SetUpTime);
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log(dialogInfo, 'dialogInfodialogInfo');
      if (this.$refs.classspendStatisticsAnalyze) {
        this.$refs.classspendStatisticsAnalyze.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCommonCustomDialog', dialogInfo, this.$refs.classspendDialogFactoryCommon);
      if (this.$refs.classspendDialogFactoryCommon) {
        this.$refs.classspendDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>
