<template>
  <div class="swiper-slide"
       @click="chgCoureDate">
    <div class="principal_swiper_list"
         :class="{'opt':item.isActive,'label':vDate == '今天'}">
      <div class="list_date">{{vDate}} {{vWeek}}</div>
      <div class="list_number">
        {{isDateType==3?Number(item.ArrangedNumCount||0):Number(item.ArrivedNumCount||0)}} <span class="font_gray">(人){{isDateType==3?'安排':'签到'}}</span>
      </div>
      <div class="list_info clearfix"
           v-if="isDateType==1">
        <div class="fl">
          <div v-if="Number(item.NoAttenceNum)>0">
            未到<span class="font_red font_bigger1">{{item.NoAttenceNum}}</span>人
          </div>
          <div v-else>
            出勤率<span class="font_black font_bigger1">{{Number(item.AttendanceRate)||0}}%</span>
          </div>
        </div>
        <div class="fr"
             v-if="Number(item.NoAttenceNum)>0">
          出勤率<span class="font_black font_bigger1">{{Number(item.AttendanceRate)||0}}%</span>
        </div>
      </div>
      <!-- 今天 -->
      <div class="list_info clearfix"
           v-if="isDateType==2">
        <!-- <div class="fl">
          <div v-if="Number(item.NoAttenceNum)>0">
            未到<span class="font_red">{{item.NoAttenceNum}}</span>人
          </div>          
        </div> -->
        <!-- 今天上完的课大于0,跟总课不相等  -->
        <div class="fl"
             v-if="Number(item.HaveClassNum)>0&&Number(item.HaveClassNum)!=Number(item.ScheduleCourseNum)">
          已上<span class="font_black font_bigger1">{{item.HaveClassNum}}</span>节课
        </div>
        <!-- 今天上完的课大于0,跟总课不相等  -->
        <div class="fr"
             v-if="Number(item.HaveClassNum)>0&&Number(item.HaveClassNum)!=Number(item.ScheduleCourseNum)">
          未到<span class="font_red font_bigger1">{{item.NoAttenceNum}}</span>人
        </div>

        <!-- 今天全部上完 -->
        <div class="fl"
             v-if="Number(item.HaveClassNum)>0&&Number(item.HaveClassNum)==Number(item.ScheduleCourseNum)">
          <div v-if="Number(item.NoAttenceNum)>0">
            未到<span class="font_red font_bigger1">{{item.NoAttenceNum}}</span>人
          </div>
          <div v-else>
            出勤率<span class="font_black font_bigger1">{{Number(item.AttendanceRate)||0}}%</span>
          </div>
        </div>
        <!-- 今天全部上完. -->
        <div class="fr"
             v-if="Number(item.HaveClassNum)>0&&Number(item.HaveClassNum)==Number(item.ScheduleCourseNum)&&Number(item.NoAttenceNum)>0">
          出勤率<span class="font_black font_bigger1">{{Number(item.AttendanceRate)||0}}%</span>
        </div>

        <!-- 今天一节课未上. -->
        <div class="fl"
             v-if="Number(item.ScheduleCourseNum)==0&&Number(item.HaveClassNum)==0||Number(item.HaveClassNum)==0&&Number(item.HaveClassNum)!=Number(item.ScheduleCourseNum)">
          <div>
            共<span class="font_black font_bigger1">{{item.ScheduleCourseNum}}</span>节课
          </div>
        </div>
        <!-- 今天一节课未上. -->
        <div class="fr"
             v-if="Number(item.ScheduleCourseNum)==0&&Number(item.HaveClassNum)==0||Number(item.HaveClassNum)==0&&Number(item.HaveClassNum)!=Number(item.ScheduleCourseNum)">
          请假<span class="font_red font_bigger1">{{Number(item.LeaveNumCount)||0}}</span>人
        </div>

      </div>
      <!-- 今天以后 -->
      <div class="list_info clearfix"
           v-if="isDateType==3">
        <div class="fl">
          <div>
            共<span class="font_black font_bigger1">{{item.ScheduleCourseNum}}</span>节课
          </div>
        </div>
        <div class="fr">
          请假<span class="font_red font_bigger1">{{Number(item.LeaveNumCount)||0}}</span>人
        </div>
      </div>
      <!-- 昨日以前  NoAttenceNum>0-->
      <div class="list_tips tips_warning"
           v-if="isDateType==1&&Number(item.FAttenceNumCount)>0">
        <div>
          未考勤：<span class="font_red">{{item.FAttenceNumCount}}</span>人/<span class="font_red">{{item.NoAttenceCount}}</span>节课
        </div>
      </div>
      <!-- 昨日以前  NoAttenceNum==0-->
      <div class="list_tips tips_normal tick_green"
           v-if="isDateType==1&&Number(item.FAttenceNumCount)==0">
      </div>
      <!-- 今天 -->
      <div class="list_tips tips_warning"
           v-if="isDateType==2&&Number(item.HaveClassNum)==Number(item.ScheduleCourseNum)&&Number(item.FAttenceNumCount)>0">
        <div>
          未考勤：<span class="font_red">{{item.FAttenceNumCount}}</span>人/<span class="font_red">{{item.NoAttenceCount}}</span>节课
        </div>

      </div>
      <div class="list_tips tips_warning"
           v-if="isDateType==2&&Number(item.HaveClassNum)!=Number(item.ScheduleCourseNum)&&Number(item.WarnLossCourseCount)>0">
        <div>
          <span class="font_red">{{item.WarnLossCourseCount}}</span>节课 学生不足
        </div>
      </div>
      <div class="list_tips tips_normal tick_green"
           v-if="isDateType==2&&Number(item.HaveClassNum)==Number(item.ScheduleCourseNum)&&Number(item.FAttenceNumCount)==0">
      </div>
      <div class="list_tips tips_normal tick_green"
           v-if="isDateType==2&&Number(item.HaveClassNum)!=Number(item.ScheduleCourseNum)&&Number(item.WarnLossCourseCount)==0">
      </div>
      <!-- 明天以后 -->
      <div class="list_tips tips_warning"
           v-if="isDateType==3&&Number(item.WarnLossCourseCount)>0">
        <div>
          <span class="font_red">{{item.WarnLossCourseCount}}</span>节课 学生不足
        </div>
      </div>
      <div class="list_tips tips_normal tick_green"
           v-if="isDateType==3&&Number(item.WarnLossCourseCount)==0">
      </div>
   </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: ['item'],
  computed: {
    vDate () {
      if (this.item) {
        let date = this.item.ClassTime.replace(/-/g, '/');
        let lastDate = Date.new(Date.new().setTime(Date.new().getTime() - 24 * 60 * 60 * 1000));
        let preDate = Date.new(Date.new().setTime(Date.new().getTime() + 24 * 60 * 60 * 1000));
        if (date == this.$utils.formatDatet(Date.new(), 'yyyy/MM/dd')) {
          return '今天';
        } else if (date == this.$utils.formatDatet(lastDate, 'yyyy/MM/dd')) {
          return '昨天';
        } else if (date == this.$utils.formatDatet(preDate, 'yyyy/MM/dd')) {
          return '明天';
        } else {
          return this.$utils.formatDatet(Date.new(date), 'MM') + '月' + this.$utils.formatDatet(Date.new(date), 'dd') + '日';
        }
      }
    },
    vWeek () {
      if (this.item) {
        return this.$utils.formatDatet(Date.new(this.item.ClassTime.replace(/-/g, '/')), 'ddddd');
      }
    },
    // 判断日期是否小于今天,大于今天.以便控制显示
    isDateType () {
      if (this.item) {
        let date = this.item.ClassTime.replace(/-/g, '/');
        if (date == this.$utils.formatDatet(Date.new(), 'yyyy/MM/dd')) {
          return 2;
        } else if (Date.new() < Date.new(date)) {     // 明天或以后
          return 3;
        } else {            // 昨天或以前
          return 1;
        }
      } else {
        return 1;
      }
    }
  },
  methods: {
    chgCoureDate () {
      console.log(this.item);
      let type = this.isDateType != 3;
      this.$emit('chgCoureDateAfter', this.item, type);
    }

  }

};
</script>

