<template>
  <div class="index_view_menu_three_content flex">
    <div class="new_other_statistics_content flex">
      <div class="index_view_menu index_view_menu_three box_withe_radius pr"
           v-for="(item, key) in vmenuList"
           :key="key">
        <div class="index_title pr">
          {{ item.name }}
          <span class="smallTitle">
            {{item.smallTitle}}
          </span>
        </div>
        <div class="chain_workbench_file_new pr menu_content">
          <div class="menu_content_list pr"
               style="padding: 0px;"
               v-for="(listItem, key) in item.list"
               :key="key"
               @click.stop="verifyAuthGoRouter(listItem)">

            <div class="menu_content_list_title"
                 style="margin-top: 20px">
              {{ listItem.name }}
            </div>
            <div class="menu_content_list_num"
                 style="margin-top: 8px"
                 :class="listItem.class">
              {{ listItem.num }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="new_other_statistics_second_content">
      <div class="index_title pr">
        <span>{{ menuObj.name }}</span>
        <!-- <div class="left_tips">
          <input-form-tip :tips="'等待绑定文字'"></input-form-tip>

        </div> -->
        <div class="right_surFlow"
             :title="menuObj.num"
             @click.stop="test">
          {{menuObj.surplusFlow}} <span>{{menuObj.num}}</span>
          <span class="second_content_list"></span>
        </div>
      </div>
      <div class="new_other_statistics_second_content_list">
        <div class="second_content_list pr flex"
             v-for="(item,key) in vmenuObjList.list"
             :key="key"
             @click.stop="verifyAuthGoRouter(item)">
          <div class="second_content_first"
               :class="item.class">{{item.firstTitle}}</div>
          <div class="second_content_second flex">
            <div style="font-size: 14px;color:#666">{{item.name}}</div>
            <div style="font-size: 24px;color:black">{{item.num}}</div>
          </div>
        </div>
      </div>
    </div>
    <custom-dialog :title="'设置'"
                   :visible.sync="isShowFlowSetUP"
                   :before-close="closeFlowSetUPFormPopup">
      <course-setting @close="closeFlowSetUPFormPopup"
                      @afterSuccess="getDataInfo"></course-setting>
    </custom-dialog>

  </div>
</template>
<script>
import { GetChainaaSClientScaleStatistic, GetPortStatistics } from '../../../API/workbench';
import courseSetting from '../../subscribe-course-setting/courseSetting';
export default {
  data () {
    return {
      isShowFlowSetUP: false,
      menuList: [
        {
          name: '连锁校区管理',
          value: '连锁门店管理',
          smallTitle: '（教务账户）',
          num: 0,
          keyvalue: 2,
          ModulePowerKey: 0,
          list: [
            {
              name: '直营店',
              value: '新增待分配',
              num: 0,
              routerName: 'retailManagement',
              routertitle: '直营管理',
              auth: true,
              roleName: '',
              ModulePowerKey: 105,
              moduleName: '',
              dataSet1: this.vFindDirectly,
              dataSet2: this.vFindParticipation,
              class: 'font_blue'
            },
            {
              name: '加盟店',
              value: '跟进中',
              num: 0,
              routerName: 'franchiseeManagement',
              routertitle: '加盟管理',
              auth: true,
              roleName: '',
              ModulePowerKey: 106,
              moduleName: '',
              dataSet1: this.vFindBrand,
              dataSet2: this.vFindCourseware,
              class: 'font_blue'
            }
          ]
        },
        {
          name: '课程合作校区管理',
          value: '跟进中',
          smallTitle: '（课件账户）',
          num: 0,
          keyvalue: 2,
          ModulePowerKey: 2,
          list: [
            {
              name: '已授权校区',
              num: 0,
              routerName: 'courseCooperationManagement',
              routertitle: '课程合作管理',
              auth: true,
              data: {
                type: 1
              },
              roleName: '',
              ModulePowerKey: 141,
              moduleName: '',
              dataKey: '',
              dataSet1: '',
              dataSet2: '',
              class: 'font_blue'
            },
            {
              name: '续端口预警',
              value: '跟进中',
              num: 0,
              routerName: 'courseCooperationManagement',
              routertitle: '课程合作管理',
              auth: true,
              roleName: '',
              ModulePowerKey: 141,
              moduleName: '',
              data: {
                type: 5
              },
              dataSet1: '',
              dataSet2: '',
              class: 'font_yellow'
            }
          ]
        },
        {
          name: '系统端口管理',
          value: '',
          smallTitle: '',
          num: 0,
          keyvalue: 2,
          ModulePowerKey: 2,
          list: [
            {
              name: '全部系统端口',
              num: 0,
              routerName: 'campusManagement',
              routertitle: '系统端口管理',
              auth: true,
              data: {
                type: 1
              },
              roleName: '',
              ModulePowerKey: 109,
              moduleName: '',
              dataKey: '',
              dataSet1: '',
              dataSet2: '',
              class: 'font_blue'
            },
            {
              name: '续端口预警',
              value: '',
              num: 0,
              routerName: 'campusManagement',
              routertitle: '系统端口管理',
              auth: true,
              roleName: '',
              ModulePowerKey: 109,
              moduleName: '',
              data: {
                type: 5
              },
              dataSet1: '',
              dataSet2: '',
              class: 'font_yellow'
            }
          ]
        }
      ],
      menuObj:
      {
        name: '课件与督学管理',
        value: '连锁门店管理',
        smallTitle: '（教务账户）',
        surplusFlow: '剩余流量',
        num: 0,
        keyvalue: 2,
        ModulePowerKey: 0,
        list: [
          {
            name: '课件加密上传',
            num: 0,
            routerName: 'hqCoursewareManagement',
            routertitle: '课件管理',
            auth: true,
            roleName: '',
            ModulePowerKey: 107,
            class: 'bg_blue',
            firstTitle: 'K',
            moduleName: ''
          },
          // {
          //   name: '课件授权统计',
          //   num: 0,
          //   routerName: 'authorizationCourseware',
          //   routertitle: '授权课件管理',
          //   auth: true,
          //   roleName: '',
          //   ModulePowerKey: 108,
          //   class:'bg_blue',
          //   firstTitle:'K',
          //   moduleName: '',
          // },
          {
            name: '校区课件管理',
            num: 0,
            routerName: 'campusCoursewareManagement',
            routertitle: '校区课件管理',
            auth: true,
            roleName: '',
            ModulePowerKey: 108,
            class: 'bg_blue',
            firstTitle: 'X',
            moduleName: ''
          },
          // {
          //   name: '课件使用查询',
          //   num: 0,
          //   routerName: 'courseAttendClassList',
          //   routertitle: '课件使用查询',
          //   auth: true,
          //   roleName: '',
          //   ModulePowerKey: 0,
          //   class: 'bg_blue',
          //   firstTitle: 'K',
          //   moduleName: ''
          // },
          {
            name: '课件授权管理',
            num: 0,
            routerName: 'coursewareAuthorizationManagement',
            routertitle: '课件授权管理',
            auth: true,
            roleName: '',
            ModulePowerKey: 0,
            class: 'bg_blue',
            firstTitle: 'K',
            moduleName: ''
          },

          {
            name: '系统端口管理',
            num: 0,
            routerName: 'campusManagement',
            routertitle: '系统端口管理',
            auth: true,
            roleName: '',
            ModulePowerKey: 109,
            class: 'bg_yellow',
            firstTitle: 'S',
            moduleName: ''
          },
          {
            name: '本月课件使用',
            num: 0,
            routerName: 'coursewareClassList',
            routertitle: '本月课件使用',
            auth: true,
            roleName: '',
            ModulePowerKey: 0,
            class: 'bg_yellow',
            firstTitle: 'B',
            moduleName: ''
          },
          {
            name: '本月流量消耗',
            num: 0,
            routerName: 'flowBillReport',
            routertitle: '流量消耗统计',
            auth: true,
            roleName: '',
            ModulePowerKey: 0,
            class: 'bg_yellow',
            firstTitle: 'L',
            moduleName: ''
          }
        ]
      },
      chainInfo: {
        RetailStoresCount: 0,
        LeagueBrandCount: 0,
        CoursewarePackageCount: 0,
        AuthorizeCoursewareCount: 0,
        PrincipalSchoolNum: 0,
        PrepareLessonsToCheckNum: 0,
        FluxPackageExpendLogMonthCount: 0,
        GeneralFluxCount: 0,
        CampusClassEvaluation: 0
      }, // 连锁门店规模统计
      portInfo: {

      }
    };
  },
  props: {
    menDianSetInfo: Array
  },
  created () {
    // this.getDataInfo();
    console.log(this.$store.getters.SaaSClientKey, 'this.$store.getters.SaaSClientKey');
  },
  components: {
    courseSetting
  },
  computed: {
    vmenuList () {
      this.menuList[0].list[0].num = Number(this.chainInfo.RetailStoresCount) || 0;
      this.menuList[0].list[1].num = Number(this.chainInfo.LeagueBrandCount) || 0;
      this.menuList[1].list[0].num = Number(this.chainInfo.CourseCooperationAuthorizaCampus) || 0;
      this.menuList[1].list[1].num = Number(this.chainInfo.portRenewEarlyWarning) || 0;
      this.menuList[2].list[0].num = Number(this.portInfo.PortCount) || 0;
      this.menuList[2].list[1].num = Number(this.portInfo.OverdueBymonth) || 0;
      let newArr = []
      // this.menuList[2].list[0].num = Number(
      //   this.chainInfo.PrepareLessonsToCheckNum
      // );
      // this.menuList[2].list[1].num = Number(this.chainInfo.PrincipalSchoolNum);
      if (this.vMendianInfo.IsOldHQKey == 1) {
        newArr.push(this.menuList[0])
        newArr.push(this.menuList[1])
      } else {
        newArr.push(this.menuList[0])
        newArr.push(this.menuList[2])
      }
      return newArr
      // return this.menuList;
    },
    vmenuObjList () {
      this.menuObj.list[0].num = Number(this.chainInfo.CoursewarePackageCount) || 0;
      this.menuObj.list[1].num = Number(this.chainInfo.ClientAuthNum) || 0;
      this.menuObj.list[2].num = Number(this.chainInfo.AuthorizeCoursewareCount) || 0;
      this.menuObj.list[3].num = Number(this.chainInfo.PortRenewNum) || 0;
      this.menuObj.list[4].num = Number(this.chainInfo.UseCoursewareSum) || 0
      this.menuObj.list[5].num = this.$utils.flowFormate(Number(this.chainInfo.FluxPackageExpendLogMonthCount)) || 0;
      // this.menuObj.num = Number(this.chainInfo.GeneralFluxCount) < 0 ? '-' + this.$utils.flowFormate(Number(this.chainInfo.GeneralFluxCount)) : Number(this.chainInfo.GeneralFluxCount) > 0
      //   ? this.$utils.flowFormate(this.chainInfo.GeneralFluxCount) : '--';
      if (Number(this.chainInfo.GeneralFluxCount) == 0) {
        this.menuObj.num = '--';
      } else {
        this.menuObj.num = this.$utils.flowFormate(Number(this.chainInfo.GeneralFluxCount));
      }
      return this.menuObj;
    },
    // 找到直属加盟设置
    vFindDirectly () {
      return this.findSetInfoOpenType(6);
    },
    // 找到参股加盟设置
    vFindParticipation () {
      return this.findSetInfoOpenType(7);
    },
    // 找到课件加盟设置
    vFindCourseware () {
      return this.findSetInfoOpenType(4);
    },
    // 找到整店加盟设置
    vFindBrand () {
      return this.findSetInfoOpenType(5);
    },
    // 整店加盟费
    vLeagueBrandAmount () {
      return this.setMoney(
        this.realTimesInfo.LeagueBrandAmount,
        this.vFindBrand,
        '',
        106
      );
    },
    // 课件加盟费
    vLeagueCoursewareAmount () {
      return this.setMoney(
        this.realTimesInfo.LeagueCoursewareAmount,
        this.vFindCourseware,
        '',
        106
      );
    },
    // 直营报名
    vRetailStoresApplyAmount () {
      return this.setMoney(
        this.realTimesInfo.RetailStoresApplyAmount,
        this.vFindDirectly,
        this.vFindParticipation,
        105
      );
    },
    // 直营课消
    vRetailStoresClassAmount () {
      return this.setMoney(
        this.realTimesInfo.RetailStoresClassAmount,
        this.vFindDirectly,
        this.vFindParticipation,
        105
      );
    },
    // 直营出勤
    setAttendRate () {
      if (this.isHasModulePower(105)) {
        if (
          (this.vFindDirectly && this.vFindDirectly.SetKey == 1) ||
          (this.vFindParticipation && this.vFindParticipation.SetKey == 1)
        ) {
          return (
            Math.round(
              Number(this.realTimesInfo.RetailStoresAttendRate) * 100
            ) + '%'
          );
        } else {
          return '**';
        }
      } else {
        return '**';
      }
    },
    // 直营店管理套数
    vRetailStoresCount () {
      return this.setCount(
        this.chainInfo.RetailStoresCount,
        this.vFindDirectly,
        this.vFindParticipation,
        105
      );
    },
    // 加盟店管理套数
    vLeagueBrandCount () {
      return this.setCount(
        this.chainInfo.LeagueBrandCount,
        this.vFindBrand,
        this.vFindCourseware,
        106
      );
    },
    // 课件加盟套数
    vCoursewarePackageCount () {
      return this.setCount(this.chainInfo.CoursewarePackageCount, '', '', 107);
    },
    // 课件授权套数
    vAuthorizeCoursewareCount () {
      return this.setCount(
        this.chainInfo.AuthorizeCoursewareCount,
        '',
        '',
        108
      );
    },
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    },
    vMendianInfo () {
      return this.$store.getters.SaaSClientInfo;
    }
  },
  methods: {
    seletedItem (item) {

    },
    isHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vRolePowerList, ModulePowerKey);
    },
    // 找到门店设置中的指定项内容
    findSetInfoOpenType (type) {
      console.log(this.menDianSetInfo, 'findSetInfoOpenType');
      let locaItem = this.menDianSetInfo.find((o) => {
        return o.SetTypeKey == type;
      });
      return locaItem;
    },
    // 设置金额
    setMoney (num, dataSet, dataSet2, ModulePowerKey) {
      num = Number(num) || 0;
      return this.$utils.setSixMoneyToWan(num);
      // if (dataSet || dataSet2) {
      //   if (
      //     this.isHasModulePower(ModulePowerKey) &&
      //     (dataSet.SetKey == 1 || dataSet2.SetKey == 1)
      //   ) {
      //     return this.$utils.setSixMoneyToWan(num);
      //   } else {
      //     if (num > 99999.99) {
      //       return '***';
      //     } else {
      //       return '***';
      //     }
      //   }
      // } else {
      //   if (this.isHasModulePower(ModulePowerKey)) {
      //     return this.$utils.setSixMoneyToWan(num);
      //   } else {
      //     if (num > 99999.99) {
      //       return '***';
      //     } else {
      //       return '***';
      //     }
      //   }
      // }
    },
    // 设置次数
    setCount (num, dataSet, dataSet2, ModulePowerKey) {
      num = Number(num) || 0;
      console.log('dataSet', dataSet, dataSet2);
      return num;
      // if (dataSet || dataSet2) {
      //   if (
      //     this.isHasModulePower(ModulePowerKey) &&
      //     (dataSet.SetKey == 1 || dataSet2.SetKey == 1)
      //   ) {
      //     return num;
      //   } else {
      //     return '***';
      //   }
      // } else {
      //   if (this.isHasModulePower(ModulePowerKey)) {
      //     return num;
      //   } else {
      //     return '***';
      //   }
      // }
    },
    // 获取连锁门店规模统计
    getDataInfo () {
      GetChainaaSClientScaleStatistic()
        .then((result) => {
          this.chainInfo = result.data;
          console.log('getChainaaStatistic', result.data);
        })
        .catch((error) => {
          console.log('errorgetChainaaStatistic', error);
        });
      GetPortStatistics().then(result => {
        this.portInfo = result.data;
        console.log('getChainaaStatistic', result.data);
      }).catch(error => {

      })
    },
    verifyAuthGoRouter (item) {
      console.log('verifyAuthGoRouter');
      console.log(item);
      if (item.routerName == 'flowBillReport') {
        this.$dialog.open(this, {
          name: '流量账单',
          routerName: this.$route.name,
          moduleName: 'HQflowBillReport',
          ModulePowerKey: '',
          data: { dataInfo: item }
        });
      } else {
        if (!this.isHasModulePower(item.ModulePowerKey)) {
          console.log('!!!isHasModulePower');
          this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
        } else {
          console.log('!!!isHasModulePower');
          if (item.routerName == '') {
            layer.alert('该模块正在开发种，敬请期待');
            return false;
          }
          this.goRouter(item, item.num);
        }
      }
    },
    goRouter (item, num) {
      console.log(item, 'goRouter');
      if (item.routerName == 'hqCoursewareManagement') {
        item.dataKey = 0;
      }
      if (this.vMendianInfo.IsOpenChainKey != 1) {
        layer.alert('未开通连锁功能，请联系您的专属客服');
        return false;
      }
      // if (num >= 0) {
      this.$bus.emit('needOpenRouter', item);
      // }
    },
    // 打开弹出层
    openCustomDialogByFactoryDialog (item) {
      this.$emit('openCustomDialogByFactoryDialog', item);
    },
    test () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '',
        dialogWidth: '880px',
        moduleName: 'flowPacketReport',
        data: {},
        callBack: {
        }
      });
    },
    testYK () {
      this.isShowFlowSetUP = true;
      console.log('aaaa');
    },
    closeFlowSetUPFormPopup () {
      this.isShowFlowSetUP = false;
    }
  }
};
</script>
<style scoped>
.right_surFlow {
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 26px;
}
.right_surFlow span {
  color: #3498db;
}
.left_tips {
  position: absolute;
  width: 14px;
  height: 14px;
  left: 143px;
  top: 23px;
  border-radius: 50%;
  background: #b2b4b8 url(../../../../public/image/header_tips_icon.png);
  background-size: 14px;
}
.smallTitle {
  font-size: 14px;
  color: #666666;
}
.new_other_statistics_second_content {
}
.new_other_statistics_second_content .index_title {
  font-size: 18px;
  line-height: 60px;
  border: none !important;
  display: flex;
  width: 415px;
  margin: 0 14px;
  justify-content: space-between;
}
.new_other_statistics_second_content_list {
  margin: 0px 14px;
}
.second_content_list:last-child {
  border: none;
}
.second_content_list {
  height: 49px;
  padding: 10px 0px;
  border-bottom: 1px solid #f5f8ff;
  width: 415px;
  cursor: pointer;
}
.second_content_list:hover {
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 16px 24px rgba(255, 108, 103, 0.1),
    0px 2px 6px rgba(255, 108, 103, 0.3), 0px 0px 1px rgba(255, 108, 103, 0.2);
  border-radius: 4px;
  cursor: pointer;
}
.second_content_list::after {
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  right: 6px;
  top: 19px;
  background: url(../../../../public/image/next_icon@2x.png) no-repeat right
    center;
  background-size: 7px;
}
.second_content_first {
  width: 33px;
  height: 33px;
  background: #f1f5fe;
  border-radius: 8px;
  font-size: 18px;
  line-height: 33px;
  text-align: center;
  margin-left: 10px;
}
.second_content_first.bg_blue {
  background: #f1f5fe;
  color: #4581f9;
}
.second_content_first.bg_yellow {
  background: #ffeedf;
  color: #ff8723;
}

.second_content_second {
  padding-left: 10px;
  width: 340px;
  line-height: 33px;
  justify-content: space-between;
  margin-left: 5px;
}
.new_other_statistics_content .menu_content_list_num.font_blue {
  color: rgba(69, 129, 249, 1) !important;
  font-size: 30px;
}
.new_other_statistics_content .menu_content_list_num.font_yellow {
  color: rgba(255, 135, 35, 1) !important;
  font-size: 30px;
}
</style>