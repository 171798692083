<template>
  <!-- 授权课件表单 -->
  <div style="height:600px">
    <div class="form_title_info">直营店</div>
    <div class="form_info_edit form_info_list">
      <div>
        <input-switch v-model="isShare"
                      :formTitle="'参股直营'"
                      :showInputValue="false"
                      :className="'no_border'"
                      :required="false"></input-switch>
        <div style="color:#999;border-bottom: 1px solid #ececec;
    padding-bottom: 15px;">弱管理模式。总部和门店是合作关系，总部不管理门店。通常用于总部参股的门店，需获取门店的财务信息。</div>
      </div>
      <div>
        <input-switch v-model="isDirectly"
                      :formTitle="'直属直营'"
                      :showInputValue="false"
                      :className="'no_border'"
                      :required="false"></input-switch>
        <div style="border-bottom: 1px solid #ececec;
    padding-bottom: 15px;color:#999">
          强管理模式。总部和门店是上下级关系，直接管理门店。管理权限包括并不限于门店财务、课程、售价、教案等。
        </div>
      </div>
    </div>
    <div class="form_title_info">加盟店</div>
    <div class="form_info_edit form_info_list">
      <div>
        <input-switch v-model="isBrand"
                      :formTitle="'整店加盟'"
                      :showInputValue="false"
                      :className="'no_border'"
                      :required="false"></input-switch>
        <div style="color:#999;border-bottom: 1px solid #ececec;
    padding-bottom: 15px;">门店以加盟的形式获得总部品牌使用权。总部再将课件授权分发给加盟店使用。</div>
      </div>
      <div>
        <input-switch v-model="isCourseware"
                      :formTitle="'课件加盟'"
                      :showInputValue="false"
                      :className="'no_border'"
                      :required="false"></input-switch>
        <div style="color:#999">门店仅获得总部课件的使用权，没有品牌使用权。</div>
      </div>

    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import {
  GetSaaSClientSet, ModifySaaSClientSetting
} from '../../../API/workbench';
export default {
  data () {
    return {
      // 直营类型
      sourceType: [
        { type: 1, name: '课次授权' },
        { type: 2, name: '按年授权( 天 )' },
        { type: 3, name: '永久授权' }
      ],
      locationInfo: [], // 地址
      menDianSetInfo: [],
      isCourseware: 0,   // 课件加盟4
      isBrand: 0,    // 品牌 5
      isDirectly: 0,   // 直属6
      isShare: 0   // 股份7
    };
  },
  components: {

  },
  props: {
    coursewareId: Number,
    isReadonly: Boolean
  },
  created () {
    this.getMenDianSet();
  },
  mounted () {

  },
  computed: {

  },
  methods: {
    // 获取门店设置
    getMenDianSet () {
      GetSaaSClientSet().then(result => {
        console.log('GetSaaSClientSet', result.data);
        this.menDianSetInfo = result.data;
        this.isShare = this.initData(7, this.isShare);
        // 直营
        this.isDirectly = this.initData(6, this.isDirectly);
        // 品牌
        this.isBrand = this.initData(5, this.isBrand);
        // 课件
        this.isCourseware = this.initData(4, this.isCourseware);
        console.log('initData', this.isShare, this.isDirectly, this.isBrand, this.isCourseware);
      });
    },
    initData (type, value) {
      let locaIndex = this.menDianSetInfo.findIndex(o => {
        return o.SetTypeKey == type;
      });
      if (locaIndex >= 0) {
        return Number(this.menDianSetInfo[locaIndex].SetKey) || 0;
      }
    },
    setData (type, value) {
      let locaIndex = this.menDianSetInfo.findIndex(o => {
        return o.SetTypeKey == type;
      });
      if (locaIndex >= 0) {
        this.menDianSetInfo[locaIndex].SetKeyValue = value == 1 ? '是' : '否';
        this.menDianSetInfo[locaIndex].SetKey = value;
      }
    },
    // 找到匹配项加到数组中.
    findItemPushArray (type, newArr) {
      let locaIndex = this.menDianSetInfo.findIndex(o => {
        return o.SetTypeKey == type;
      });
      if (locaIndex >= 0) {
        newArr.push(this.menDianSetInfo[locaIndex]);
      }
    },
    // 提交
    submit (callback) {
      console.log('submit', this.menDianSetInfo);
      // 股份
      this.setData(7, this.isShare);
      // 直营
      this.setData(6, this.isDirectly);
      // 品牌
      this.setData(5, this.isBrand);
      // 课件
      this.setData(4, this.isCourseware);
      console.log('submit', this.menDianSetInfo);
      // 续费
      let newObj = [];
      this.findItemPushArray(7, newObj);
      this.findItemPushArray(6, newObj);
      this.findItemPushArray(5, newObj);
      this.findItemPushArray(4, newObj);
      console.log(newObj, 'newObj');
      ModifySaaSClientSetting(newObj).then(result => {
        layer.alert('修改成功');
        this.$emit('afterSuccess');
        this.$bus.emit('updateMendianSet');
        this.cancel();
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callback) {
          callback();
        }
      });
    },
    cancel () {
      this.$emit('closeDialog');
    }
  }
};
</script>

