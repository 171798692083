<template>
  <div class="dialog-factory">
    <!-- 报名收款 -->
    <custom-dialog :title="dialogList.signuppayment.title"
                   :moduleDescription="'学员报名购买课程，如未登记的学生可点界面“+”新增学生信息后再进行报名。'"
                   :dialogParamList="dialogParamList"
                   class=""
                   :curModuleName="dialogList.signuppayment.moduleName"
                   @beforeClose="closeDialog">
      <sign-up-payment :ref="dialogList.signuppayment.moduleName"
                       :dataInfo="getData('dataInfo')"
                       @closesignUpPaymentDialog="closeDialog"
                       @afterSucces="execCustomEvent"></sign-up-payment>
    </custom-dialog>
    <!-- 报名收款成功后新增弹框 -->
    <custom-dialog class="defult_dialog toolTip_box"
                   width="410px"
                   :title="dialogList.signuppaymentSuccess.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.signuppaymentSuccess.moduleName"
                   @beforeClose="closeDialog">
      <zx-payment-hint-dialog :ref="dialogList.signuppaymentSuccess.moduleName"
                              :tableID="getData('tableID')"
                              :studentInfo="getData('studentInfo')"
                              :isMoneyReceipt="getData('isMoneyReceipt')"
                              @closeDialog="closeDialog"></zx-payment-hint-dialog>
    </custom-dialog>
    <!-- 新建循环课 -->
    <custom-dialog :title="dialogList.newCircleCourseTable.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.newCircleCourseTable.moduleName"
                   :moduleDescription="'按规律每周排课的建课方式，可同时建立多个上课规律！不可建立过去时间的课。'"
                   @beforeClose="closeDialog">
      <new-circle-course :ref="dialogList.newCircleCourseTable.moduleName"
                         :dataInfo="getData('dataInfo')"
                         @afterSucces="execCustomEvent"
                         @closeDialog="closeDialog"></new-circle-course>
    </custom-dialog>
    <!-- 日历建课 -->
    <custom-dialog :title="dialogList.newCourseByCalendar.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.newCourseByCalendar.moduleName"
                   :moduleDescription="'按天自由选择建立课程的时间，比较适用于假期无规则建立课表。可建立过去时间段的课！'"
                   @beforeClose="closeDialog">
      <new-course-calendar :ref="dialogList.newCourseByCalendar.moduleName"
                           :dataInfo="getData('dataInfo')"
                           @afterSucces="execCustomEvent"
                           @closeDialog="closeDialog">
      </new-course-calendar>
    </custom-dialog>
    <!-- 续建课表 -->
    <custom-dialog :title="dialogList.continueNewCircleCourseTable.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.continueNewCircleCourseTable.moduleName"
                   @beforeClose="closeDialog">
      <continue-new-circle-course-table :ref="dialogList.continueNewCircleCourseTable.moduleName"
                                        :ruleID="getData('ruleID')"
                                        @afterSucces="execCustomEvent"
                                        @closeDialog="closeDialog"></continue-new-circle-course-table>
    </custom-dialog>
    <!-- 循环课详情 -->
    <custom-dialog :title="dialogList.circleCourseDetail.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.circleCourseDetail.moduleName"
                   @beforeClose="closeDialog">
      <circle-course-detail :ref="dialogList.circleCourseDetail.moduleName"
                            :ruleID="getData('ruleID')"
                            @closeDialog="closeDialog"></circle-course-detail>
    </custom-dialog>
    <!-- 修改循环课表 -->
    <custom-dialog :title="dialogList.editCircleCourseTable.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.editCircleCourseTable.moduleName"
                   @beforeClose="closeDialog">
      <edit-circle-course-table :ref="dialogList.editCircleCourseTable.moduleName"
                                :circleCourseInfo="getData('circleCourseInfo')"
                                @closeDialog="closeDialog"></edit-circle-course-table>
    </custom-dialog>

    <custom-dialog :title="dialogList.buyClassDetailRecord.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.buyClassDetailRecord.moduleName"
                   @beforeClose="closeDialog">
      <coures-purchase-consume-details :ref="dialogList.buyClassDetailRecord.moduleName"
                                       :studentKey="getData('studentKey')"
                                       :courseBillKey="getData('courseBillKey')"></coures-purchase-consume-details>
    </custom-dialog>

    <!-- 录入老生 -->
    <custom-dialog :title="dialogList.addOldStudent.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.addOldStudent.moduleName"
                   @beforeClose="closeDialog">
      <!-- <explain-module :explainText="'可每天录入当天上课老生。'"></explain-module> -->
      <add-old-student :ref="dialogList.addOldStudent.moduleName"
                       @addSuccess="execCustomEvent"
                       @closeDialog="closeDialog"></add-old-student>
    </custom-dialog>
    <!-- 批量延期 -->
    <custom-dialog :dialogParamList="dialogParamList"
                   @beforeClose="closeDialog">
      <!-- <explain-module :explainText="'可每天录入当天上课老生。'"></explain-module> -->
      <course-batch-delay @addSuccess="execCustomEvent"
                          @closeDialog="closeDialog"></course-batch-delay>
    </custom-dialog>

    <!-- 批量录入老生 -->
    <custom-dialog :title="dialogList.batchAddOldStudent.title"
                   :width="'1233px'"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.batchAddOldStudent.moduleName"
                   @beforeClose="closeDialog">
      <batch-add-old-student :ref="dialogList.batchAddOldStudent.moduleName"
                             :isShowHistoryRecord="getData('isShowHistoryRecord')"
                             @closeDialog="closeDialog"></batch-add-old-student>
    </custom-dialog>

    <!-- 学生请假 -->
    <custom-dialog :title="dialogList.studentLeave.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.studentLeave.moduleName"
                   @beforeClose="closeDialog">
      <student-leave @closeDialog="closeDialog"></student-leave>
    </custom-dialog>

    <!-- 批量排课 -->
    <custom-dialog class="student_arrange_dialog defult_dialog no_hidden"
                   :title="dialogList.batchArrangeScheduleCourse.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.batchArrangeScheduleCourse.moduleName"
                   @beforeClose="closeDialog">
      <batch-arrange-course :ref="dialogList.batchArrangeScheduleCourse.moduleName"
                            :paramsInfo="getData('paramsInfo')"
                            @afterBatchArrangeCourse="execCustomEvent"
                            @cancelClick="closeDialog"></batch-arrange-course>
    </custom-dialog>

    <!-- 自选排课 -->
    <custom-dialog :title="dialogList.freeChoiceArrangeScheduleCourse.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.freeChoiceArrangeScheduleCourse.moduleName"
                   @beforeClose="closeDialog">
      <free-choice-arrange-course :ref="dialogList.freeChoiceArrangeScheduleCourse.moduleName"
                                  :paramsInfo="getData('paramsInfo')"
                                  @cancelClick="closeDialog"></free-choice-arrange-course>
    </custom-dialog>

    <!-- 学生报课 -->
    <custom-dialog title="学生报课"
                   :visible.sync="isShowStudentApplyCourseDialog"
                   :before-close="closeStudentApplyCourseDialog">
      <div>
        <student-apply-course :studentApplyCourseParams="studentApplyCourseParams"></student-apply-course>
      </div>
    </custom-dialog>

    <!-- 课程课类管理 -->
    <custom-dialog :title="dialogList.courseClassManageSetting.title"
                   :moduleDescription="''"
                   :width="'972px'"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.courseClassManageSetting.moduleName"
                   @beforeClose="closeDialog">
      <div v-if="dialogList.courseClassManageSetting.type=='HQ'">
        <hq-course-class-manage-setting></hq-course-class-manage-setting>
      </div>
      <div v-else>
        <course-class-manage-setting></course-class-manage-setting>
      </div>

    </custom-dialog>
    <!-- 班级管理 -->
    <custom-dialog :title="dialogList.gradeClassManageSetting.title"
                   :moduleDescription="''"
                   :width="'972px'"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.gradeClassManageSetting.moduleName"
                   @beforeClose="closeDialog">
      <grade-class-manage-setting></grade-class-manage-setting>
    </custom-dialog>

    <!-- 合并学生 -->
    <custom-dialog :title="dialogList.mergeStudent.title"
                   :moduleDescription="'不小心将一个学生录系统两次，只要新录的学生没有发生数据就可进行合并，如果都发生数据则需要删除学生！'"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.mergeStudent.moduleName"
                   @beforeClose="closeDialog">
      <merge-student-page :ref="dialogList.mergeStudent.moduleName"
                          :studentKey="getData('studentKey')"
                          @closePage="closeDialog"></merge-student-page>
    </custom-dialog>

    <!-- 登记客户（修改/查看） -->
    <div>
      <custom-dialog :title="dialogList.registerCustomerDetails.title"
                     :moduleDescription="''"
                     :dialogParamList="dialogParamList"
                     :curModuleName="dialogList.registerCustomerDetails.moduleName"
                     @beforeClose="closeDialog">
        <register-customer-details :ref="dialogList.registerCustomerDetails.moduleName"
                                   :dataInfo="getData('dataInfo')"
                                   @closeRegisterCustomer="closeDialog"
                                   @doafterRegisterCustomer="execCustomEvent"
                                   @AfterStudentDelete="deleteRegisterCustomer">
        </register-customer-details>
      </custom-dialog>
    </div>
    <!-- 登记客户 -->
    <custom-dialog :title="dialogList.registercustomer.title"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.registercustomer.moduleName"
                   @beforeClose="closeDialog">
      <register-customer :ref="dialogList.registercustomer.moduleName"
                         :dataInfo="getData('dataInfo')"
                         @closeRegisterCustomer="closeDialog"
                         @doafterRegisterCustomer="execCustomEvent"
                         @AfterStudentDelete="deleteRegisterCustomer">

      </register-customer>
    </custom-dialog>
    <!-- 学生档案 -->
    <custom-dialog :title="dialogList.studentFile.title"
                   :dialogParamList="dialogParamList"
                   class="student_arrange_dialog"
                   :curModuleName="dialogList.studentFile.moduleName"
                   @beforeClose="closeDialog">
      <student-file :ref="dialogList.studentFile.moduleName"
                    :studentID="getData('studentID')"
                    @closeDialog="closeDialog"></student-file>
    </custom-dialog>
    <!-- 学生列表 -->
    <custom-dialog :title="dialogList.studentList.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.studentList.moduleName"
                   @beforeClose="closeDialog">
      <student-list :ref="curShowDialogParam.moduleName"
                    @selectedItem="execCustomEvent"
                    @closeDialog="closeDialog"></student-list>
    </custom-dialog>
    <!-- 批量延期 -->
    <!-- <custom-dialog :title="dialogList.delayList.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.delayList.moduleName"
                   @beforeClose="closeDialog">
      <course-batch-delay :ref="curShowDialogParam.moduleName"
                    @selectedItem="execCustomEvent"
                    @closeDialog="closeDialog"></course-batch-delay>
    </custom-dialog> -->

    <!-- 欠费预警 -->
    <custom-dialog :title="dialogList.renewfeewarning.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.renewfeewarning.moduleName"
                   :moduleDescription="vRenewfeewarningTips"
                   @beforeClose="closeDialog">
      <renew-fee-warning :ref="dialogList.renewfeewarning.moduleName"
                         :dataInfo="getData('dataInfo')"
                         @closesignUpPaymentDialog="closeDialog"
                         @afterSucces="execCustomEvent"></renew-fee-warning>
    </custom-dialog>
    <!-- 欠课时预警 -->
    <custom-dialog :title="dialogList.oweclasshourwarning.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.oweclasshourwarning.moduleName"
                   :moduleDescription="'自动统计欠课时的学生，点击记录可进行操作还课时或者忽略等，点击学生名可查看学生档案。'"
                   @beforeClose="closeDialog">
      <oweClasshourWarning :ref="dialogList.oweclasshourwarning.moduleName"
                           :dataInfo="getData('dataInfo')"
                           @closesignUpPaymentDialog="closeDialog"
                           @afterSucces="execCustomEvent"></oweClasshourWarning>
    </custom-dialog>
    <!-- 客户还款 -->
    <custom-dialog :title="dialogList.paymentrepayment.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.paymentrepayment.moduleName"
                   @beforeClose="closeDialog">
      <paymentAndWithdraw :ref="dialogList.paymentrepayment.moduleName"
                          :dataInfo="getData('dataInfo')"
                          :menuType="0"
                          @afterSucces="execCustomEvent"
                          @closePaymentDialog="closeDialog"></paymentAndWithdraw>
    </custom-dialog>
    <!-- 客户提现 -->
    <custom-dialog :title="dialogList.paymentwithdrawal.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.paymentwithdrawal.moduleName"
                   @beforeClose="closeDialog">
      <paymentAndWithdraw :ref="dialogList.paymentwithdrawal.moduleName"
                          :dataInfo="getData('dataInfo')"
                          :menuType="1"
                          @afterSucces="execCustomEvent"
                          @closePaymentDialog="closeDialog"></paymentAndWithdraw>
    </custom-dialog>

    <!-- 审批记录 -->
    <custom-dialog :title="dialogList.approvalRecord.title"
                   :width="'959px'"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.approvalRecord.moduleName"
                   @beforeClose="closeDialog">
      <approvalRecord :ref="dialogList.approvalRecord.moduleName"
                      :dataInfo="getData('dataInfo')"
                      @afterSucces="execCustomEvent"
                      @closePaymentDialog="closeDialog"></approvalRecord>
    </custom-dialog>
    <!-- 修改课单 -->
    <custom-dialog :title="dialogList.changeCourseBill.title"
                   :moduleDescription="'可以修改课程，课时数，有效期。'"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.changeCourseBill.moduleName"
                   @beforeClose="closeDialog">
      <change-course-bill :ref="dialogList.changeCourseBill.moduleName"
                          :dataInfo="getData('dataInfo')"
                          :courseInfo="getData('courseInfo')"
                          @updateCourseEvent="execCustomEvent"
                          @closeViewDialog="closeDialog"></change-course-bill>
    </custom-dialog>
    <!-- 离校预警 -->
    <custom-dialog :title="dialogList.leaveschoolwarning.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.leaveschoolwarning.moduleName"
                   :moduleDescription="'统计了近30天未在系统签到的学生。点击学生名可查看学生档案，点记录查忽略记录。'"
                   @beforeClose="closeDialog">
      <leaveschoolwarning :ref="dialogList.leaveschoolwarning.moduleName"
                          :dataInfo="getData('dataInfo')"
                          @afterSucces="execCustomEvent"
                          @closePaymentDialog="closeDialog"></leaveschoolwarning>
    </custom-dialog>
    <!-- 续课预警 -->
    <custom-dialog :title="dialogList.circulationwarning.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.circulationwarning.moduleName"
                   :moduleDescription="'有结束日期的循环课，距最后一节课两周前提醒，提前14天！'"
                   @beforeClose="closeDialog">
      <circulationwarning :ref="dialogList.circulationwarning.moduleName"
                          :dataInfo="getData('dataInfo')"
                          @afterSucces="execCustomEvent"
                          @closePaymentDialog="closeDialog"></circulationwarning>
    </custom-dialog>

    <!-- 报名欠款预警 -->
    <custom-dialog :title="dialogList.applydebtwarning.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.applydebtwarning.moduleName"
                   :moduleDescription="'历史有报名时记录了欠款的客户，点击记录可进行操作还款和忽略'"
                   @beforeClose="closeDialog">
      <applydebtwarning :ref="dialogList.applydebtwarning.moduleName"
                        :dataInfo="getData('dataInfo')"
                        @afterSucces="execCustomEvent"
                        @closePaymentDialog="closeDialog"></applydebtwarning>
    </custom-dialog>
    <!-- 学生不足预警 -->
    <custom-dialog :title="dialogList.arrangelack.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.arrangelack.moduleName"
                   @beforeClose="closeDialog">
      <arrangelack :ref="dialogList.arrangelack.moduleName"
                   :dataInfo="getData('dataInfo')"
                   @afterSucces="execCustomEvent"
                   @closePaymentDialog="closeDialog"></arrangelack>
    </custom-dialog>

    <!-- 改手机号 -->
    <div>
      <custom-dialog :title="dialogList.changephonenum.title"
                     :moduleDescription="'修改学生的手机号码，同一手机号下面的其他学生也将一起被修改。'"
                     :dialogParamList="dialogParamList"
                     :curModuleName="dialogList.changephonenum.moduleName"
                     @beforeClose="closeDialog">
        <change-phone-num :ref="dialogList.changephonenum.moduleName"
                          :dataInfo="getData('dataInfo')"
                          @afterSucces="execCustomEvent"
                          @closeChangephonenumDialog="closeDialog"></change-phone-num>
      </custom-dialog>
    </div>

    <!-- 学生换班 -->
    <div>
      <custom-dialog :title="dialogList.studentchangeclass.title"
                     :moduleDescription="'可以对单个学生进行班级调整或退班。'"
                     :dialogParamList="dialogParamList"
                     :curModuleName="dialogList.studentchangeclass.moduleName"
                     @beforeClose="closeDialog">
        <student-change-class :ref="dialogList.studentchangeclass.moduleName"
                              :dataInfo="getData('dataInfo')"
                              @afterSucces="execCustomEvent"
                              @closeStudentChangeClassDialog="closeDialog"></student-change-class>
      </custom-dialog>
    </div>
    <!-- 转让课单 -->
    <div>
      <custom-dialog :title="dialogList.transfercoursebill.title"
                     :moduleDescription="'把单个学生课单转给其他学员，不影响财务数据。'"
                     :dialogParamList="dialogParamList"
                     :curModuleName="dialogList.transfercoursebill.moduleName"
                     @beforeClose="closeDialog">
        <transfer-course-bill :ref="dialogList.transfercoursebill.moduleName"
                              :dataInfo="getData('dataInfo')"
                              @updateCourseEvent="execCustomEvent"
                              @closeTransferCourseBillDialog="closeDialog"></transfer-course-bill>
      </custom-dialog>
    </div>
    <!-- 课时退款 -->
    <div>
      <custom-dialog :title="dialogList.refundmoneybackcourse.title"
                     :moduleDescription="'单个学生退款操作，可自行修改退款金额。记得要点击课单进行选中哦！'"
                     :dialogParamList="dialogParamList"
                     :curModuleName="dialogList.refundmoneybackcourse.moduleName"
                     @beforeClose="closeDialog">
        <refundMoneyForBackCourse :ref="dialogList.refundmoneybackcourse.moduleName"
                                  :dataInfo="getData('dataInfo')"
                                  @updateCourseEvent="execCustomEvent"
                                  @closeRefundMoneyBackCourseDialog="closeDialog"></refundMoneyForBackCourse>
      </custom-dialog>
    </div>
    <!-- 长假停课 -->
    <div>
      <custom-dialog :title="dialogList.holidayssuspendclasses.title"
                     :moduleDescription="''"
                     :dialogParamList="dialogParamList"
                     :curModuleName="dialogList.holidayssuspendclasses.moduleName"
                     @beforeClose="closeDialog">
        <holidays-uspend-classes :ref="dialogList.holidayssuspendclasses.moduleName"
                                 :dataInfo="getData('dataInfo')"
                                 @updateCourseEvent="execCustomEvent"
                                 @cancel="closeDialog"></holidays-uspend-classes>
      </custom-dialog>
    </div>
    <!-- 长假调课 -->
    <div>
      <custom-dialog :title="dialogList.holidaysAdjustClasses.title"
                     :moduleDescription="''"
                     :dialogParamList="dialogParamList"
                     :curModuleName="dialogList.holidaysAdjustClasses.moduleName"
                     @beforeClose="closeDialog">
        <holidays-adjust-classes :ref="dialogList.holidaysAdjustClasses.moduleName"
                                 :dataInfo="getData('dataInfo')"
                                 @updateCourseEvent="execCustomEvent"
                                 @cancel="closeDialog"></holidays-adjust-classes>
      </custom-dialog>
    </div>
    <!-- 复制课表 -->
    <div>
      <custom-dialog :title="dialogList.copyCourse.title"
                     :moduleDescription="''"
                     :dialogParamList="dialogParamList"
                     :curModuleName="dialogList.copyCourse.moduleName"
                     @beforeClose="closeDialog">
        <copy-course :ref="dialogList.copyCourse.moduleName"
                     :dataInfo="getData('dataInfo')"
                     @updateCourseEvent="execCustomEvent"
                     @cancel="closeDialog"></copy-course>
      </custom-dialog>
    </div>
    <!-- 加入班级 -->
    <!-- <custom-dialog :title="dialogList.addStudentToBJ.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.addStudentToBJ.moduleName"
                   @beforeClose="closeDialog">
      <add-student-to-bJ :ref="dialogList.addStudentToBJ.moduleName"
                         :dataInfo="getData('dataInfo')"
                         @upClassListData="execCustomEvent"
                         @closeAddStudentToBJDialog="closeDialog"></add-student-to-bJ>
    </custom-dialog> -->
    <!-- 按课程排课 -->
    <custom-dialog :title="dialogList.studentArrangeCourseByCourseName.title"
                   :dialogParamList="dialogParamList"
                   class="student_arrange_dialog"
                   :curModuleName="dialogList.studentArrangeCourseByCourseName.moduleName"
                   @beforeClose="closeDialog">
      <student-arrange-course-by-course-name :ref="dialogList.studentArrangeCourseByCourseName.moduleName"
                                             :studentID="getData('studentID')"
                                             :courseNameID="getData('courseNameID')"
                                             :courseName="getData('courseName')"
                                             @afterSucces="execCustomEvent"
                                             @afterCancelAllArrangeSuccess="execCustomEvent"></student-arrange-course-by-course-name>
    </custom-dialog>
    <!-- 跟班级排课 -->
    <custom-dialog :title="dialogList.studentArrangeCourseByClass.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.studentArrangeCourseByClass.moduleName"
                   @beforeClose="closeDialog">
      <studentArrangeCourseByClass :ref="dialogList.studentArrangeCourseByClass.moduleName"
                                   :studentID="getData('studentID')"
                                   :classNameID="getData('classNameID')"
                                   :className="getData('className')"
                                   @afterCancelAllArrangeSuccess="execCustomEvent"></studentArrangeCourseByClass>
    </custom-dialog>
    <!-- 待报课课单报课 -->
    <div>
      <custom-dialog :title="dialogList.courseIDApplyList.title"
                     :dialogParamList="dialogParamList"
                     :curModuleName="dialogList.courseIDApplyList.moduleName"
                     @beforeClose="closeDialog">
        <courseIDApply :ref="dialogList.courseIDApplyList.moduleName"
                       :dataInfo="getData('dataInfo')"
                       @afterSucces="execCustomEvent"
                       @afterSucces2="dialogList.courseIDApplyList.title='待报课单列表'"
                       @closeDialog="closeDialog"></courseIDApply>
      </custom-dialog>
    </div>
    <!-- 学生课单报课 -->
    <div>
      <custom-dialog :title="dialogList.applyCourse.title"
                     :dialogParamList="dialogParamList"
                     :curModuleName="dialogList.applyCourse.moduleName"
                     @beforeClose="closeDialog">
        <apply-course :ref="dialogList.applyCourse.moduleName"
                      :dataInfo="getData('dataInfo')"
                      @afterSucces="execCustomEvent"
                      @closeDialog="closeDialog"></apply-course>
      </custom-dialog>
    </div>
    <!-- 导入学生/课单 -->
    <div>
      <import-mould :importMoudle="importMoudle"
                    :isOpenImportMould="isOpenImportMould"></import-mould>
    </div>

    <!-- 套餐设置 -->
    <custom-dialog title="全部套餐"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.taoCanSettingList.moduleName"
                   @beforeClose="closeDialog">
      <taoCanSettingList :ref="dialogList.taoCanSettingList.moduleName"
                         :dataInfo="getData('dataInfo')"
                         @upClassListData="execCustomEvent"
                         @closeTaoCanSettingListDialog="closeDialog"></taoCanSettingList>
    </custom-dialog>
    <!-- 课程设置 -->
    <custom-dialog title="课程设置"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.keChengSettingList.moduleName"
                   @beforeClose="closeDialog">
      <keChengSettingList :ref="dialogList.keChengSettingList.moduleName"
                          :dataInfo="getData('dataInfo')"
                          @upClassListData="execCustomEvent"
                          @closeKeChengSettingListDialog="closeDialog"></keChengSettingList>
    </custom-dialog>

    <!-- 班级设置 -->
    <custom-dialog title="设置班级"
                   :dialogParamList="dialogParamList"
                   :moduleDescription="''"
                   :curModuleName="dialogList.banJiSettingList.moduleName"
                   @beforeClose="closeDialog">
      <banJiSettingList :ref="dialogList.banJiSettingList.moduleName"
                        :dataInfo="getData('dataInfo')"
                        @onClick="execCustomEvent"
                        @closeBanJiSettingListDialog="closeDialog"></banJiSettingList>
    </custom-dialog>
    <!-- 学生退班/进班 -->
    <custom-dialog :title="dialogList.quitOrEnterClassForm.title"
                   :dialogParamList="dialogParamList"
                   class="popup_dialog_height"
                   :curModuleName="dialogList.quitOrEnterClassForm.moduleName"
                   @beforeClose="closeDialog">
      <quit-or-enter-class-form :ref="dialogList.quitOrEnterClassForm.moduleName"
                                :dataInfo="getData('dataInfo')"
                                @closeStudentQuitOrenterclassDialog="closeDialog"
                                @afterSucces="execCustomEvent"></quit-or-enter-class-form>
    </custom-dialog>

    <custom-dialog title="收支科目"
                   :dialogParamList="dialogParamList"
                   :moduleDescription="''"
                   :curModuleName="dialogList.incomePaySubject.moduleName"
                   @beforeClose="closeDialog">
      <div v-if="dialogList.incomePaySubject.type=='HQ'">
        <hq-income-pay-subject-setting :ref="dialogList.incomePaySubject.moduleName"
                                       :dataInfo="getData('dataInfo')"
                                       @cancelClick="execCustomEvent"
                                       @closeDialog="closeDialog"></hq-income-pay-subject-setting>
      </div>
    </custom-dialog>

    <custom-dialog title="招商杂费设置"
                   :dialogParamList="dialogParamList"
                   :moduleDescription="''"
                   :curModuleName="dialogList.merchantsIncidentalsSet.moduleName"
                   @beforeClose="closeDialog">
      <hq-merchants-incidentals-set :ref="dialogList.merchantsIncidentalsSet.moduleName"
                                    :dataInfo="getData('dataInfo')"
                                    @cancelClick="execCustomEvent"
                                    @closeDialog="closeDialog"></hq-merchants-incidentals-set>
    </custom-dialog>

    <custom-dialog title="招商来源设置"
                   :dialogParamList="dialogParamList"
                   :moduleDescription="''"
                   :curModuleName="dialogList.merchantsSourceSet.moduleName"
                   @beforeClose="closeDialog">
      <hq-merchants-source-set :ref="dialogList.merchantsSourceSet.moduleName"
                               :dataInfo="getData('dataInfo')"
                               @cancelClick="execCustomEvent"
                               @closeDialog="closeDialog"></hq-merchants-source-set>
    </custom-dialog>
    <custom-dialog title="部门岗位"
                   :dialogParamList="dialogParamList"
                   :moduleDescription="''"
                   :curModuleName="dialogList.departmentPost.moduleName"
                   @beforeClose="closeDialog">
      <div v-if="dialogList.departmentPost.type=='HQ'">
        <hq-department-post-setting :ref="dialogList.departmentPost.moduleName"
                                    :dataInfo="getData('dataInfo')"
                                    @cancelClick="execCustomEvent"
                                    @closeDialog="closeDialog"></hq-department-post-setting>
      </div>
    </custom-dialog>

    <!-- 全部员工 -->
    <custom-dialog title="全部员工"
                   :dialogParamList="dialogParamList"
                   :moduleDescription="''"
                   :curModuleName="dialogList.staffManagementList.moduleName"
                   @beforeClose="closeDialog">
      <div v-if="dialogList.staffManagementList.type=='HQ'">
        <hq-staff-manage :ref="dialogList.staffManagementList.moduleName"
                         :dataInfo="getData('dataInfo')"
                         @cancelClick="execCustomEvent"
                         @closeStaffManagementListDialog="closeDialog"></hq-staff-manage>
      </div>
      <div v-else>
        <yuanGongSettingList :ref="dialogList.staffManagementList.moduleName"
                             :dataInfo="getData('dataInfo')"
                             @cancelClick="execCustomEvent"
                             @closeStaffManagementListDialog="closeDialog"></yuanGongSettingList>
      </div>
    </custom-dialog>

    <!-- 角色权限设置 -->
    <custom-dialog title="角色权限设置"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.permissionsettingform.moduleName"
                   @beforeClose="closeDialog">
      <div v-if="dialogList.permissionsettingform.type=='HQ'">
        <hq-permission-setting :ref="dialogList.permissionsettingform.moduleName"
                               :dataInfo="getData('dataInfo')"
                               @upClassListData="execCustomEvent"
                               @closePermissionsettingformDialog="closeDialog"></hq-permission-setting>
      </div>
      <div v-else>
        <permissionsettingform :ref="dialogList.permissionsettingform.moduleName"
                               :dataInfo="getData('dataInfo')"
                               @upClassListData="execCustomEvent"
                               @closePermissionsettingformDialog="closeDialog"></permissionsettingform>
      </div>

    </custom-dialog>

    <!-- 收支录入 -->
    <div>
      <custom-dialog :title="dialogList.incomepayentry.title"
                     :dialogParamList="dialogParamList"
                     :curModuleName="dialogList.incomepayentry.moduleName"
                     @beforeClose="closeDialog">
        <div v-if="dialogList.incomepayentry.type=='HQ'">
          <hq-income-pay-entry :ref="dialogList.incomepayentry.moduleName"
                               :dataInfo="getData('dataInfo')"
                               @afterSucces="execCustomEvent"
                               @closeIncomepayentryDialog="closeDialog"></hq-income-pay-entry>
        </div>
        <div v-else>
          <income-pay-entry :ref="dialogList.incomepayentry.moduleName"
                            :dataInfo="getData('dataInfo')"
                            @afterSucces="execCustomEvent"
                            @closeIncomepayentryDialog="closeDialog"></income-pay-entry>
        </div>

      </custom-dialog>
    </div>

    <!-- 收支管理 -->
    <div>
      <custom-dialog :title="dialogList.incomepaymanage.title"
                     :dialogParamList="dialogParamList"
                     :curModuleName="dialogList.incomepaymanage.moduleName"
                     @beforeClose="closeDialog">
        <div v-if="dialogList.incomepaymanage.type=='HQ'">
          <hq-income-pay-manage :ref="dialogList.incomepaymanage.moduleName"
                                :dataInfo="getData('dataInfo')"
                                @afterSucces="execCustomEvent"
                                @closeIncomepaymanageDialog="closeDialog"></hq-income-pay-manage>
        </div>
        <div v-else>
          <income-pay-manage :ref="dialogList.incomepaymanage.moduleName"
                             :dataInfo="getData('dataInfo')"
                             @afterSucces="execCustomEvent"
                             @closeIncomepaymanageDialog="closeDialog"></income-pay-manage>
        </div>

      </custom-dialog>
    </div>
    <!-- 收支账户 -->
    <custom-dialog :title="dialogList.incomepayAccount.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.incomepayAccount.moduleName"
                   @beforeClose="closeDialog">
      <hq-income-pay-account :ref="dialogList.incomepayAccount.moduleName"
                             :dataInfo="getData('dataInfo')"
                             @afterSucces="execCustomEvent"
                             @readSignUpPayment="openDialogPlus"
                             @closePaymentDialog="closeDialog"></hq-income-pay-account>
    </custom-dialog>
    <!-- 报名记录 -->
    <custom-dialog :title="dialogList.applyrecord.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.applyrecord.moduleName"
                   @beforeClose="closeDialog">
      <applyrecord :ref="dialogList.applyrecord.moduleName"
                   :dataInfo="getData('dataInfo')"
                   @afterSucces="execCustomEvent"
                   @readSignUpPayment="openDialogPlus"
                   @closePaymentDialog="closeDialog"></applyrecord>
    </custom-dialog>
    <!-- 异常处理记录 -->
    <custom-dialog :title="dialogList.abnormalhandling.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.abnormalhandling.moduleName"
                   @beforeClose="closeDialog">
      <abnormalhandling :ref="dialogList.abnormalhandling.moduleName"
                        :dataInfo="getData('dataInfo')"
                        @afterSucces="execCustomEvent"
                        @closePaymentDialog="closeDialog"></abnormalhandling>
    </custom-dialog>
    <!-- 上课课时消耗 -->
    <custom-dialog :title="dialogList.classstatisticsrecord.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.classstatisticsrecord.moduleName"
                   @beforeClose="closeDialog">
      <classstatisticsrecord :ref="dialogList.classstatisticsrecord.moduleName"
                             :dataInfo="getData('dataInfo')"
                             @afterSucces="execCustomEvent"
                             @closeDialog="closeDialog"></classstatisticsrecord>
    </custom-dialog>
    <!-- 异常课时消耗 -->
    <custom-dialog :title="dialogList.abnormalchconsumerecord.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.abnormalchconsumerecord.moduleName"
                   @beforeClose="closeDialog">
      <abnormalchconsumerecord :ref="dialogList.abnormalchconsumerecord.moduleName"
                               :dataInfo="getData('dataInfo')"
                               @afterSucces="execCustomEvent"
                               @closeDialog="closeDialog"></abnormalchconsumerecord>
    </custom-dialog>
    <!-- 公众号第三方模式 -->
    <custom-dialog :title="dialogList.thirdpartyTencent.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.thirdpartyTencent.moduleName"
                   @beforeClose="closeDialog">
      <thirdparty-tencent :ref="dialogList.thirdpartyTencent.moduleName"
                          :dataInfo="getData('dataInfo')"
                          @afterSucces="execCustomEvent"
                          @closeDialog="closeDialog"></thirdparty-tencent>
    </custom-dialog>
    <!-- 签到本 -->
    <!-- <div>
      <attendancebook ref="attendancebook"></attendancebook>
    </div> -->
    <custom-dialog :title="dialogList.attendancebook.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.attendancebook.moduleName"
                   @beforeClose="closeDialog">
      <attendancebook :ref="dialogList.attendancebook.moduleName"
                      @closeDialog="closeDialog"></attendancebook>
    </custom-dialog>
    <!-- 安排试课 -->
    <custom-dialog :title="dialogList.arrangetrycourse.title"
                   :moduleDescription="''"
                   width="1213px"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.arrangetrycourse.moduleName"
                   @beforeClose="closeDialog">
      <arrange-try-course :ref="dialogList.arrangetrycourse.moduleName"
                          :tableID="getData('tableID')"
                          @afterSuccess="execCustomEvent"
                          @closeDialog="closeDialog"></arrange-try-course>
    </custom-dialog>
    <!-- 老师上课绩效列表 -->
    <custom-dialog :title="dialogList.teacherCoursePerformanceStatisticsInfo.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.teacherCoursePerformanceStatisticsInfo.moduleName"
                   @beforeClose="closeDialog">
      <teacherCoursePerformanceStatisticsInfo :ref="dialogList.teacherCoursePerformanceStatisticsInfo.moduleName"
                                              :dataInfo="getData('dataInfo')"
                                              @afterSucces="execCustomEvent"
                                              @closeDialog="closeDialog"></teacherCoursePerformanceStatisticsInfo>
    </custom-dialog>
    <!-- 欠还款统计列表 -->
    <custom-dialog :title="dialogList.debtMoneyStatisticsInfo.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.debtMoneyStatisticsInfo.moduleName"
                   @beforeClose="closeDialog">
      <debtMoneyStatisticsInfo :ref="dialogList.debtMoneyStatisticsInfo.moduleName"
                               :dataInfo="getData('dataInfo')"
                               @afterSucces="execCustomEvent"
                               @closeDialog="closeDialog"></debtMoneyStatisticsInfo>
    </custom-dialog>
    <!-- 欠课时统计列表 -->
    <custom-dialog :title="dialogList.debtCourseHourseStatisticsInfo.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.debtCourseHourseStatisticsInfo.moduleName"
                   @beforeClose="closeDialog">
      <debtCourseHourseStatisticsInfo :ref="dialogList.debtCourseHourseStatisticsInfo.moduleName"
                                      :dataInfo="getData('dataInfo')"
                                      @afterSucces="execCustomEvent"
                                      @closeDialog="closeDialog"></debtCourseHourseStatisticsInfo>
    </custom-dialog>
    <!-- 安排考勤 -->
    <custom-dialog :title="dialogList.attendanceArrange.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.attendanceArrange.moduleName"
                   @beforeClose="closeArrangeAttendanceDialog">
      <attendance-arrange :ref="dialogList.attendanceArrange.moduleName"
                          :courseKey="getData('scheduleCourseKey')"
                          :ClassName="getData('ClassName')"
                          :isAlter="getData('isAlter')"
                          :isTeacher="getData('isTeacher')"
                          :isHistory="getData('isHistory')"
                          @closeDialog="closeArrangeAttendanceDialog"></attendance-arrange>
    </custom-dialog>
    <!-- 修改考勤 -->
    <custom-dialog :title="dialogList.changeAttendance.title"
                   :moduleDescription="'可以对单个学生已考勤的数据进行修改。'"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.changeAttendance.moduleName"
                   @beforeClose="closeDialog">
      <change-attendance :ref="dialogList.changeAttendance.moduleName"
                         :ShowSearchInput="getData('isShowSearchInput')"
                         :dataInfo="getData('dataInfo')"
                         :courseInfo="getData('courseInfo')"
                         @afterSucces="execCustomEvent"
                         @closeViewDialog="closeDialog"></change-attendance>
    </custom-dialog>
    <!-- 考勤详情 -->
    <custom-dialog :title="dialogList.attendanceDetail.title"
                   :dialogParamList="dialogParamList"
                   width="649px"
                   :curModuleName="dialogList.attendanceDetail.moduleName"
                   @beforeClose="closeAttendanceDetailDialog">
      <attendance-detail :ref="dialogList.attendanceDetail.moduleName"
                         :queryDate="getData('queryDate')"
                         :isTeacher="getData('isTeacher')"
                         @closeDialog="closeDialog"></attendance-detail>
    </custom-dialog>
    <!-- 新增班级    老模块需要.-->
    <custom-dialog :title="dialogList.newClass.title"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.newClass.moduleName"
                   @beforeClose="closeDialog">
      <new-class :ref="dialogList.newClass.moduleName"
                 :editItem="getData('dataInfo')"
                 @cancelClick="closeDialog"
                 @addSuccess="execCustomEvent">
      </new-class>
    </custom-dialog>

    <!-- 出入库表单 -->
    <custom-dialog :title="dialogList.goodsLeaveOrEnterForm.title"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.goodsLeaveOrEnterForm.moduleName"
                   @beforeClose="closeDialog">
      <goods-leave-or-enter-form :ref="dialogList.goodsLeaveOrEnterForm.moduleName"
                                 :TypeKey="getData('TypeKey')"
                                 @afterSuccess="execCustomEvent"
                                 @cancelClick="closeDialog"></goods-leave-or-enter-form>
    </custom-dialog>
    <!-- 旧版海报券模板编辑 -->
    <custom-dialog :title="dialogList.oldIshowEditorDialog.title"
                   width="1200px"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.oldIshowEditorDialog.moduleName"
                   @beforeClose="closeEditorDialog">
      <old-ishow-editor-dialog :ref="dialogList.oldIshowEditorDialog.moduleName"
                           :dataInfo="getData('dataInfo')"
                           :coverInfo="getData('coverInfo')"
                           :activityInfo="getData('activityInfo')"
                           @afterSuccess="execCustomEvent"
                           @cancelClick="closeDialog"></old-ishow-editor-dialog>
    </custom-dialog>
    <!-- 新版海报券模板编辑 -->
    <custom-dialog :title="dialogList.ishowEditorDialog.title"
                   width="750px"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.ishowEditorDialog.moduleName"
                   @beforeClose="closeEditorDialog">
      <ishow-editor-dialog :ref="dialogList.ishowEditorDialog.moduleName"
                           :dataInfo="getData('dataInfo')"
                           :coverInfo="getData('coverInfo')"
                           :activityInfo="getData('activityInfo')"
                           @afterSuccess="execCustomEvent"
                           @cancelClick="closeDialog"></ishow-editor-dialog>
    </custom-dialog>
     <!-- 新版海报券模板编辑 -->
    <custom-dialog :title="dialogList.viewSetting.title"
                   width="1200px"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.viewSetting.moduleName"
                   @beforeClose="closeEditorDialog">
      <view-setting :ref="dialogList.viewSetting.moduleName"
                           :dataInfo="getData('dataInfo')"
                           :coverInfo="getData('coverInfo')"
                           @afterSuccess="execCustomEvent"
                           @cancelClick="closeDialog"></view-setting>
    </custom-dialog>
    <!-- 新版海报券选择音乐列表 -->
    <custom-dialog :title="dialogList.ishowEditorMusicList.title"
                   width="740px"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.ishowEditorMusicList.moduleName"
                   @beforeClose="closeEditorDialog">
      <ishow-editor-music-list :ref="dialogList.ishowEditorMusicList.moduleName"
                               :dataInfo="getData('dataInfoObj')"
                               @afterSuccess="execCustomEvent"
                               @cancelClick="closeDialog"></ishow-editor-music-list>
    </custom-dialog>
    <!-- 考勤明细 -->
    <custom-dialog :title="dialogList.attenDetailReport.title"
                   :moduleDescription="''"
                   width="1213px"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.attenDetailReport.moduleName"
                   @beforeClose="closeDialog">
      <atten-detail-report :ref="dialogList.attenDetailReport.moduleName"
                           :dataInfo="getData('dataInfo')"
                           @afterSuccess="execCustomEvent"
                           @closeDialog="closeDialog"></atten-detail-report>
    </custom-dialog>
    <!-- 连锁类型 -->
    <custom-dialog :title="dialogList.chainTypeSetting.title"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.chainTypeSetting.moduleName"
                   @beforeClose="closeDialog">
      <chain-type-setting :ref="dialogList.chainTypeSetting.moduleName"
                          :editItem="getData('dataInfo')"
                          @closeDialog="closeDialog"
                          @afterSuccess="execCustomEvent">
      </chain-type-setting>
    </custom-dialog>
    <!-- 直属设置 -->
    <custom-dialog :title="dialogList.directlySetting.title"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.directlySetting.moduleName"
                   @beforeClose="closeDialog">
      <directly-setting :ref="dialogList.directlySetting.moduleName"
                        :editItem="getData('dataInfo')"
                        @closeDialog="closeDialog"
                        @afterSuccess="execCustomEvent">
      </directly-setting>
    </custom-dialog>
    <!-- 课件安全 -->
    <custom-dialog :title="dialogList.coursewareSecurity.title"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.coursewareSecurity.moduleName"
                   @beforeClose="closeDialog">
      <coursewareSecurity :ref="dialogList.coursewareSecurity.moduleName"
                          :editItem="getData('dataInfo')"
                          @closeDialog="closeDialog"
                          @afterSuccess="execCustomEvent">
      </coursewareSecurity>
    </custom-dialog>
    <!-- 工作详情 -->
    <custom-dialog :title="dialogList.homeworkDetail.title"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.homeworkDetail.moduleName"
                   @beforeClose="closeDialog">
      <work-details :dataInfo="getData('dataInfo')"></work-details>
    </custom-dialog>
    <!-- 课评详情 -->
    <custom-dialog :title="dialogList.commentDetail.title"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.commentDetail.moduleName"
                   @beforeClose="closeDialog">
      <lesson-details :dataInfo="getData('dataInfo')"></lesson-details>
    </custom-dialog>
    <!-- 批改作业详情 -->
    <custom-dialog :title="dialogList.workCorrectDetails.title"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.workCorrectDetails.moduleName"
                   @beforeClose="closeDialog">
      <workCorrectDetails :dataInfo="getData('dataInfo')"></workCorrectDetails>
    </custom-dialog>
    <!-- 课件授权表单 -->
    <custom-dialog :title="dialogList.authorizationCoursewareForm.title"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.authorizationCoursewareForm.moduleName"
                   @beforeClose="closeDialog">
      <authorization-courseware-form :coursewareId="getData('OLAPKey')"
                                     :isReadonly="getData('isReadonly')"
                                     :campusID="getData('campusID')"
                                     @close="closeDialog"
                                     @afterSuccess="execCustomEvent"></authorization-courseware-form>
    </custom-dialog>
    <custom-dialog :dialogParamList="dialogParamList"
                   @beforeClose="closeDialog">
      <classe-grades @addSuccess="execCustomEvent"
                     @closeDialog="closeDialog"></classe-grades>
    </custom-dialog>
    <custom-dialog :dialogParamList="dialogParamList"
                   @beforeClose="closeDialog">
      <classe-package-list @addSuccess="execCustomEvent"
                           @closeDialog="closeDialog"></classe-package-list>
    </custom-dialog>
    <!-- 我的 -->
    <div>
      <mine ref="mine"></mine>
    </div>
    <!-- 忽略预警记录 -->
    <ignorewarning ref="ignorewarning"></ignorewarning>
    <!-- 异常签到记录 -->
    <div>
      <abnormal-check-sign ref="abnormalchecksign"
                           :callBackFunction="updateSignWarningCallBack"></abnormal-check-sign>
    </div>
    <!-- cws老师管理 -->
    <custom-dialog width="1300px"
                   :title="dialogList.cwsteacherManager.title"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.cwsteacherManager.moduleName"
                   :before-close="closeDialog">
      <cwsteacherManager :searchData="getData('searchData')"
                         @close="closeDialog"
                         @afterSuccess="execCustomEvent"></cwsteacherManager>
    </custom-dialog>
    <!-- cws老师详情 -->
    <custom-dialog width="1200px"
                   :title="dialogList.cwsteacherDetail.title"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.cwsteacherDetail.moduleName"
                   :before-close="closeDialog">
      <cwsteacherDetail :teacherKey="getData('teacherKey')"
                        :className="getData('className')"
                        @close="closeDialog"
                        @afterSuccess="execCustomEvent"></cwsteacherDetail>
    </custom-dialog>
    <!-- cws班级管理 -->
    <custom-dialog width="1100px"
                   :title="dialogList.cwsclassManager.title"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.cwsclassManager.moduleName"
                   :before-close="closeDialog">
      <cwsclassManager :searchData="getData('searchData')"
                       @close="closeDialog"
                       @afterSuccess="execCustomEvent"></cwsclassManager>
    </custom-dialog>
    <!-- cws学生管理 -->
    <custom-dialog width="880px"
                   :title="dialogList.cwsstudentManager.title"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.cwsstudentManager.moduleName"
                   :before-close="closeDialog">
      <cwsstudentManager :searchData="getData('searchData')"
                         @close="closeDialog"
                         @afterSuccess="execCustomEvent"></cwsstudentManager>
    </custom-dialog>
    <!-- cws班级管理 -->
    <custom-dialog width="1200px"
                   :title="dialogList.cwscoursewareManagerTwo.title"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.cwscoursewareManagerTwo.moduleName"
                   :before-close="closeDialog">
      <cwscoursewareManagerTwo :searchData="getData('searchData')"
                               @close="closeDialog"
                               @afterSuccess="execCustomEvent"></cwscoursewareManagerTwo>
    </custom-dialog>
    <!-- 授权课件 -->
    <custom-dialog :title="dialogList.authorizationCoursewareManage.title"
                   :width="'855px'"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.authorizationCoursewareManage.moduleName"
                   @beforeClose="closeDialog">
      <authorization-courseware-manage :ref="dialogList.authorizationCoursewareManage.moduleName"
                                       @close="closeDialog"
                                       @onSelectedItem="execCustomEvent"></authorization-courseware-manage>
    </custom-dialog>

    <custom-dialog :title="dialogList.campusForm.title"
                   :width="'855px'"
                   :BigCoursewarePackageKey="getData('BigCoursewarePackageKey')"
                   :campusListTypeKey="getData('campusListTypeKey')"
                   :before-close="closeDialog">
      <campus-form :ref="dialogList.campusForm.moduleName"
                   @close="closeDialog"
                   @onSelectedItem="execCustomEvent"></campus-form>
    </custom-dialog>
    <!-- 课件使用账单 -->
    <custom-dialog :title="dialogList.coursewareUseReportTwo.title"
                   :width="'1200px'"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.coursewareUseReportTwo.moduleName"
                   :before-close="closeDialog">
      <coursewareUseReportTwo :ref="dialogList.coursewareUseReportTwo.moduleName"
                              :dataInfo="getData('dataInfo')"
                              @close="closeDialog"
                              @onSelectedItem="execCustomEvent"></coursewareUseReportTwo>
    </custom-dialog>
    <!-- 课件消息设置 -->
    <custom-dialog :title="dialogList.coursewareMsgInform.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.coursewareMsgInform.moduleName"
                   @beforeClose="closeDialog">
      <courseware-msg-inform @addSuccess="execCustomEvent"
                             :dataInfo="getData('dataInfo')"
                             @closeDialog="closeDialog"></courseware-msg-inform>
    </custom-dialog>
     <!-- 登记客户 -->
    <custom-dialog :title="dialogList.clewAddForm.title"
                   :moduleDescription="''"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.clewAddForm.moduleName"
                   @beforeClose="closeDialog">
      <clewAddForm :ref="dialogList.clewAddForm.moduleName"
                         :dataInfo="getData('dataInfo')"
                         @close="closeDialog"
                         @afterSuccess="execCustomEvent"
                        >

      </clewAddForm>
    </custom-dialog>
     <!-- 课评模板列表 -->
    <custom-dialog class=""
                   width="800px"
                   :title="dialogList.commentReportList.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.commentReportList.moduleName"
                   @beforeClose="closeDialog">
       <comment-report-list :ref="dialogList.commentReportList.moduleName"
                           :dataInfo="getData('dataInfo')"
                           @close="closeDialog"
                           @afterSuccess="execCustomEvent">
        </comment-report-list>
    </custom-dialog>
      <!-- 学管师-我的学生 -->
    <custom-dialog class="defult_dialog toolTip_box"
                   width="460px"
                   :title="dialogList.theacherAdiser.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.theacherAdiser.moduleName"
                   @beforeClose="closeDialog">
      <theacher-adiser :ref="dialogList.theacherAdiser.moduleName"
                              @closeDialog="closeDialog"></theacher-adiser>
    </custom-dialog>
      <!-- 老师-我的学生 -->
    <custom-dialog class="defult_dialog toolTip_box"
                   width="460px"
                   :title="dialogList.myStudentList.title"
                   :dialogParamList="dialogParamList"
                   :curModuleName="dialogList.myStudentList.moduleName"
                   @beforeClose="closeDialog">
      <my-student-list :ref="dialogList.myStudentList.moduleName"
                              @closeDialog="closeDialog"></my-student-list>
    </custom-dialog>
    
    <!-- 弹框的载体 所有弹出层都挂载在 custom-dialog-factory 下面 -->
    <div class="custom-dialog-factory"
         :style="{display:$route.name!=routerName}"></div>
  </div>
</template> 
<script>
import signUpPayment from '../../student/sign-up-payment/index';
import zxPaymentHintDialog from '../../common/zx-payment-hint-dialog';
import newCircleCourse from '../../course-table/new-circle-course/index';
import newCourseCalendar from '../../course-table/new-course-calendar/index';
import continueNewCircleCourseTable from '../../relation-course-component/continue-new-circle-course-table';
import circleCourseDetail from '../../relation-course-component/circle-course-detail';
import editCircleCourseTable from '../../relation-course-component/edit-circle-course-table/index';
import registerCustomer from '../../student/register-customer/index';
import registerCustomerDetails from '../../pop-up-menu/register-customer-details';
import courseIDApply from '../../base-module/course-id-apply';
import applyCourse from '../../base-module/apply-course';
import studentApplyCourse from '../../base-module/apply-course/context-index';
import importMould from '../../common/import-mould';
import classFile from '../../pop-up-menu/class-file/index';
import abnormalCheckSign from '../../pop-up-menu/abnormal-check-sign/index';
import classeGrades from '../../courseware-work-bench/components/report/classe-grades/index';
import classPackageList from '../../courseware-work-bench/components/report/class-package-list/index';
// import studentFile from '../../pop-up-menu/student-file/index';
import studentFile from '../../student/student-file/index';// App学生档案
import arrangeTryCourse from '../../pop-up-menu/arrange-try-course/index';
import incomePayEntry from '../../pop-up-menu/income-pay-entry';
import incomePayManage from '../../pop-up-menu/income-pay-manage/index';// 收支管理
import changePhoneNum from '../../pop-up-menu/exception-handle/change-phone-num/index';
import changeCourseBill from '../../pop-up-menu/exception-handle/change-course-bill/index';
import changeAttendance from '../../pop-up-menu/exception-handle/change-attendance/index';
import studentChangeClass from '../../pop-up-menu/exception-handle/student-change-class/index';
import transferCourseBill from '../../pop-up-menu/exception-handle/transfer-course-bill/index';
import refundMoneyForBackCourse from '../../pop-up-menu/exception-handle/refund-money-back-course/index';
import holidaysUspendClasses from '../../pop-up-menu/exception-handle/holidays-uspend-classes/index';// 长假停课
import holidaysAdjustClasses from '../../pop-up-menu/exception-handle/holidays-adjust-classes/index';// 长假调课
import copyCourse from '../../pop-up-menu/exception-handle/copy-course/index';// 复制课表
import newClass from '../../base-module/basis-setting-module/squad-class-Setting/setting-item'; // 班级设置列表模块
import studentArrangeCourseByCourseName from '../../student-arrange-course/by-course-name/index';// 按课程排课
import studentArrangeCourseByClass from '../../student-arrange-course/by-class/index';// 跟班级排课
import quitOrEnterClassForm from '../../base-module/basis-setting-module/squad-class-Setting/student-class-form/quit-or-enter-class-form';// 学生进班/退班
import studentList from '../../common/student-list';
import taoCanSettingList from '../../base-module/basis-setting-module/list-taocan-setting/index';
import banJiSettingList from '../../base-module/basis-setting-module/list-banji-setting/index';
import keChengSettingList from '../../base-module/basis-setting-module/list-kecheng-setting/index';
import yuanGongSettingList from '../../base-module/basis-setting-module/list-yuangong-setting/index';
import yuanGongSettingItem from '../../base-module/basis-setting-module/list-yuangong-setting/setting-item';

import permissionsettingform from '../../base-module/basis-setting-module/permission-setting-form/index';
import addOldStudent from '../../pop-up-menu/exception-handle/add-old-student/index';
import courseBatchDelay from '../../report/class-list-query-statistics/course-batch-delay';
import studentLeave from '../../pop-up-menu/student-leave/index';
import renewFeeWarning from '../../intelligences-warning/renew-fee-warning/renew-fee-warning-item';
import oweClasshourWarning from '../../intelligences-warning/owe-classhour-warning/owe-classhour-warning-item';
import leaveschoolwarning from '../../intelligences-warning/leave-school-warning/leave-school-warning-item';
import circulationwarning from '../../intelligences-warning/circulation-warning/circulation-warning-item';
import applydebtwarning from '../../intelligences-warning/apply-debt-warning/index';
import arrangelack from '../../intelligences-warning/arrange-lack/index';
import applyrecord from '../../pop-up-menu/apply-record/index';
import abnormalhandling from '../../president-kanban/abnormal-handling';
import classstatisticsrecord from '../../pop-up-menu/class-statistics-record/index';
import abnormalchconsumerecord from '../../pop-up-menu/abnormal-ch-consume-record/index';
import attendancebook from '../../pop-up-menu/attendance-book/index';
import mine from '../../pop-up-menu/mine/index';
import ignorewarning from '../../pop-up-menu/ignore-warning/index';
import popUpDropDownBox from '../../common/pop-up-drop-down-box';
import mergeStudentPage from '../../pop-up-menu/exception-handle/merge-student-page/index';
import paymentAndWithdraw from '../../pop-up-menu/payment-and-withdraw/index';
import approvalRecord from '../../pop-up-menu/approval-record/index'; // 审批记录
import teacherCoursePerformanceStatisticsInfo from '../../common/statistics-details-list/teacher-course-performance-statistics-Info';
import debtMoneyStatisticsInfo from '../../common/statistics-details-list/debt-money-statistics-Info';
import debtCourseHourseStatisticsInfo from '../../common/statistics-details-list/debt-course-hourse-statistics-Info';
import batchAddOldStudent from '../../pop-up-menu/batch-add-old-student';
import batchArrangeCourse from '../../student-arrange-course/student-relation-course-component/batch-arrange-course';
import freeChoiceArrangeCourse from '../../student-arrange-course/student-relation-course-component/free-choice-arrange-course';
import couresPurchaseConsumeDetails from '../../pop-up-menu/student-file/coures-purchase-consume-details.vue';
import courseClassManageSetting from '../../base-module/basis-setting-module/course-class-manage-setting/index';// 课程课类管理
import gradeClassManageSetting from '../../base-module/basis-setting-module/grade-class-manage-setting/index';// 班级管理
import goodsLeaveOrEnterForm from '../../report/inventory-management-file/inventory-detail-file/goods-leave-or-enter-form';// 出入库表单
import attendanceDetail from '../../course-table/attendance-detail/index';
import attenDetailReport from '../../report/atten-detail-report/index';// 出入库表单

import attendanceArrange from '../../course-table/attendance-arrange/index';
import ishowEditorDialog from '../../recruit-student-show/component/ishow-editor-dialog/index';
import oldIshowEditorDialog from '../../recruit-student-show/component/old-ishow-editor-dialog/index';
import viewSetting from '../../recruit-student-show/component/ishow-editor-dialog/view-setting';


import ishowEditorMusicList from '../../recruit-student-show/component/ishow-editor-dialog/component/setting-component/ishow-editor-music-list/index';
import thirdpartyTencent from '../../pop-up-menu/thirdparty-tencent/index';
// 以下为总部-模块
import hqIncomePaySubjectSetting from '../../head-quarters/basis-setting-module/hq-income-pay-subject-setting/index';// 收支科目
import hqMerchantsIncidentalsSet from '../../head-quarters/basis-setting-module/hq-merchants-incidentals-set/index';// 招商杂费设置
import hqMerchantsSourceSet from '../../head-quarters/basis-setting-module/hq-merchants-source-set/index';// 招商来源设置
import hqDepartmentPostSetting from '../../head-quarters/basis-setting-module/hq-department-post-setting/index';// 部门岗位
import hqStaffManage from '../../head-quarters/basis-setting-module/hq-staff-manage/index';// 员工管理
import hqPermissionSetting from '../../head-quarters/basis-setting-module/hq-permission-setting/index';// 权限设置
import hqIncomePayEntry from '../../head-quarters/basis-setting-module/hq-income-pay-entry';// 收支录入
import hqIncomePayManage from '../../head-quarters/basis-setting-module/hq-income-pay-manage/index';// 收支管理
import hqIncomePayAccount from '../../head-quarters/basis-setting-module/hq-income-pay-account/index';// 收支账户
import hqCourseClassManageSetting from '../../head-quarters/basis-setting-module/hq-course-class-manage-setting/index';// 课程课类管理
import chainTypeSetting from '../../chain-work-bench/pop-up-compents/chain-type-setting';
import directlySetting from '../../chain-work-bench/pop-up-compents/directly-setting';
import coursewareSecurity from '../../chain-work-bench/pop-up-compents/courseware-security';
import workDetails from '../../home-school-communication/details-page/work-details/index';
import lessonDetails from '../../home-school-communication/details-page/lesson-details/index';
import workCorrectDetails from '../../home-school-communication/details-page/work-correct-details/index';
import authorizationCoursewareForm from '../../head-quarters/headquarters-management/authorization-courseware/form/authorization-courseware-form';
import cwsteacherManager from '../../courseware-work-bench/components/report/teacher-manager/index';
import cwsclassManager from '../../courseware-work-bench/components/report/class-manager/index';
import cwsstudentManager from '../../courseware-work-bench/components/report/student-manager/index';
import cwscoursewareManagerTwo from '../../courseware-work-bench/components/report/courseware-manager/index';
import cwsteacherDetail from '../../courseware-work-bench/right-box-work-bench/home-page-details/index.vue';
import authorizationCoursewareManage from '../../chain-work-bench/authorization-courseware-manage/index';
import campusForm from '../../head-quarters/common-modules/courseware-authorization-details/attend-class-record/campus-form.vue';
import coursewareUseReportTwo from '../../report/courseware-use-report/index';// 出入库表单
import coursewareMsgInform from '../../head-quarters/basis-setting-module/form/courseware-msg-inform/index';// 出入库表单
import clewAddForm from '../../student/clew-add-form/index';
import commentReportList from '../../report/comment-report-list/index'
import theacherAdiser from '../../new-index-view/components/theacher-adiser/index'
import myStudentList from '../../new-index-view/components/my-student-list/index'


export default {
  data () {
    return {
      dialogParamList: [], // 对应打开弹窗参数
      curShowDialogParam: {}, // 当前显示弹窗参数
      dialogList: this.$initJson.allCustomerDialogNames,
      importMoudle: { type: 1, moudleName: '导入学生' }, // 导入模板名,
      isOpenImportMould: false, // 显示导入模块
      updateSignWarningCallBack: null,
      isShowStudentApplyCourseDialog: false,
      studentApplyCourseParams: null,
      // 合并学生
      mergestudentObj: {
        titleName: '合并学生',
        studentKey: ''
      },
      // 合并学生弹出层
      isMergeStudentPage: false,
      // 课程课类管理弹出层
      isShowCourseClassManageDialog: false,
      isShowBatchAddOldStudentDialog: false,
      isShowPartForBatchAddOldStudent: false
    };
  },
  props: {
    routerName: String
  },
  components: {
    hqIncomePaySubjectSetting,
    hqMerchantsIncidentalsSet,
    hqMerchantsSourceSet,
    hqDepartmentPostSetting,
    hqStaffManage,
    hqPermissionSetting,
    hqIncomePayEntry,
    hqIncomePayManage,
    signUpPayment,
    zxPaymentHintDialog,
    newCircleCourse,
    newCourseCalendar,
    continueNewCircleCourseTable,
    circleCourseDetail,
    editCircleCourseTable,
    registerCustomer,
    registerCustomerDetails,
    changePhoneNum,
    changeCourseBill,
    incomePayEntry,
    incomePayManage,
    studentFile,
    arrangeTryCourse,
    changeAttendance,
    studentChangeClass,
    transferCourseBill,
    refundMoneyForBackCourse,
    holidaysUspendClasses,
    holidaysAdjustClasses,
    copyCourse,
    importMould,
    classFile,
    courseIDApply,
    applyCourse,
    studentApplyCourse,
    taoCanSettingList,
    banJiSettingList,
    keChengSettingList,
    yuanGongSettingList,
    yuanGongSettingItem,
    permissionsettingform,
    addOldStudent,
    courseBatchDelay,
    studentLeave,
    studentList,
    renewFeeWarning,
    oweClasshourWarning,
    leaveschoolwarning,
    circulationwarning,
    applydebtwarning,
    arrangelack,
    applyrecord,
    abnormalhandling,
    popUpDropDownBox,
    classstatisticsrecord,
    abnormalchconsumerecord,
    mergeStudentPage,
    attendancebook,
    paymentAndWithdraw,
    approvalRecord,
    mine,
    ignorewarning,
    teacherCoursePerformanceStatisticsInfo,
    debtMoneyStatisticsInfo,
    debtCourseHourseStatisticsInfo,
    abnormalCheckSign,
    classeGrades,
    classPackageList,
    studentArrangeCourseByCourseName,
    studentArrangeCourseByClass,
    // addStudentToBJ,
    batchAddOldStudent,
    freeChoiceArrangeCourse,
    batchArrangeCourse,
    couresPurchaseConsumeDetails,
    courseClassManageSetting,
    gradeClassManageSetting,
    quitOrEnterClassForm,
    thirdpartyTencent,
    attendanceDetail,
    attendanceArrange,
    newClass,
    goodsLeaveOrEnterForm,
    ishowEditorDialog,
    oldIshowEditorDialog,
    viewSetting,
    attenDetailReport,
    ishowEditorMusicList,
    chainTypeSetting,
    directlySetting,
    coursewareSecurity,
    hqIncomePayAccount,
    hqCourseClassManageSetting,
    workDetails,
    lessonDetails,
    workCorrectDetails,
    authorizationCoursewareForm,
    cwsteacherManager,
    cwsclassManager,
    cwsstudentManager,
    cwscoursewareManagerTwo,
    cwsteacherDetail,
    authorizationCoursewareManage,
    campusForm,
    coursewareUseReportTwo,
    coursewareMsgInform,
    clewAddForm,
    commentReportList,
    theacherAdiser,
    myStudentList
  },
  created () {
    this.$bus.on(this, 'switchHeaderBar', () => {
      this.$nextTick(() => {
        this.resetEvents(this.dialogParamList);
      });
    });
  },
  activated () {
  },
  computed: {
    vSaasClientInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vRenewfeewarningTips () {
      if (this.$utils.getSaaSClientSetInfo(60) && this.$utils.getSaaSClientSetInfo(61)) {
        return '自动统计课时数小于或者等于' + Number(this.$utils.getSaaSClientSetInfo(60).SetContent) + '，有效期小于或者等于' + Number(this.$utils.getSaaSClientSetInfo(61).SetContent) + '天的学生，点击记录可进行操作报名或者忽略，点击名可查看学生档案';
      } else {
        return '';
      }
    }

  },
  methods: {
    openDialogPlus (moduleName, dataKey, dataInfo, CallbackList, title, type) {
      console.log(moduleName, dataKey, dataInfo, CallbackList, title, type, 'openDialogPlus-6666');
      let dialogName = this.dialogList[moduleName].title;
      let dialogData = this.dialogParamList.find(obj => { return obj.moduleName == moduleName; });
      var _this = this;
      console.log(moduleName, dialogData, 'openDialogPlus');
      if (dialogData) {
        layer.alert(dialogName + '已经打开，不可重复开启。');
        setTimeout(() => {
          // 节点绑定的具体事件列表
          _this.resetEvents([this.curShowDialogParam]);
        }, 500);
        return false;
      } else {
        this.dialogList[moduleName].title = title || dialogName;
        this.dialogList[moduleName].type = type || '';
        this.curShowDialogParam = { moduleName: moduleName, dataKey: dataKey, data: dataInfo, CallbackList: CallbackList };
        this.dialogParamList.push(this.curShowDialogParam);
        setTimeout(() => {
          // 节点绑定的具体事件列表
          _this.resetEvents([this.curShowDialogParam]);
        }, 500);
      }
    },
    // 赋值
    resetEvents (dialogParamList) {
      dialogParamList.forEach(obj => {
        // 节点绑定的具体事件列表
        if (this.$refs[obj.moduleName]) {
          var evs = this.$refs[obj.moduleName]._events;
          for (var evName in evs) {
            if (evs[evName].length > 0) {
              let ev = evs[evName][0];
              var isclose = (ev.fns && ev.fns.name == 'bound closeDialog');
              if (isclose) {
                // ev.hasEdit = true;
                ev.fns = () => {
                  this.closeDialog(obj.moduleName, evName, arguments);
                };
              } else {
                if (obj.CallbackList) {
                  let cEv = obj.CallbackList[evName];
                  if (cEv) {
                    // ev.hasEdit = true;
                    ev.fns = cEv;
                  }
                }
              }
            }
          }
        }
      });
    },
    // 在某个路由下打开弹窗
    // 这里的名字相对于 initJson里面的moduleName,  打开的位置取决于routerName, 如routerName是workbench,就会将这个弹出层在workbench打开
    openDialog (moduleName, dataKey, data, Callback, title, type) {
      console.log('在某个路由下打开弹窗');
      let isOpen = false;
      switch (moduleName) {
        case 'signuppayment':
        case 'signuppaymentSuccess':
        case 'newCircleCourseTable':
        case 'newCourseByCalendar':
        case 'renewfeewarning':
        case 'oweclasshourwarning':
        case 'oweclasshourstatistics':
        case 'registercustomer':
        case 'studentFile':
        case 'registerCustomerDetails':
        case 'continueNewCircleCourseTable':
        case 'studentArrangeCourseByCourseName':
        case 'studentArrangeCourseByClass':
        case 'paymentrepayment':
        case 'paymentwithdrawal':
        case 'approvalRecord':
        case 'leaveschoolwarning':
        case 'circulationwarning':
        case 'incomepayentry':
        case 'incomepaymanage':
        case 'incomepayAccount':
        case 'changephonenum':
        case 'studentchangeclass':
        case 'transfercoursebill':
        case 'refundmoneybackcourse':
        case 'holidayssuspendclasses':
        case 'holidaysAdjustClasses':
        case 'copyCourse':
        case 'applydebtwarning':
        case 'arrangelack':
        case 'applyrecord':
        case 'abnormalhandling':
        case 'classstatisticsrecord':
        case 'abnormalchconsumerecord':
        case 'taoCanSettingList':
        case 'keChengSettingList':
        case 'banJiSettingList':
        case 'incomePaySubject':
        case 'merchantsIncidentalsSet':
        case 'merchantsSourceSet':
        case 'departmentPost':
        case 'staffManagementList':
        case 'permissionsettingform':
        case 'arrangetrycourse':
        case 'changeCourseBill':
        case 'changeAttendance':
        case 'addOldStudent':
        case 'quitOrEnterClassForm':
        case 'circleCourseDetail':
        case 'editCircleCourseTable':
        case 'buyClassDetailRecord':
        case 'thirdpartyTencent':
        case 'freeChoiceArrangeScheduleCourse':
        case 'batchArrangeScheduleCourse':
        case 'courseIDApplyList':
        case 'courseBatchDelay':
        case 'batchAddOldStudent':
        case 'teacherCoursePerformanceStatisticsInfo':
        case 'debtMoneyStatisticsInfo':
        case 'debtCourseHourseStatisticsInfo':
        case 'studentLeave':
        case 'attendanceArrange':
        case 'attendanceDetail':
        case 'courseClassManageSetting':
        case 'gradeClassManageSetting':
        case 'mergeStudent':
        case 'applyCourse':
        case 'attendancebook':
        case 'newClass':
        case 'goodsLeaveOrEnterForm':
        case 'oldIshowEditorDialog':
          case 'ishowEditorDialog':
        case 'viewSetting':
        case 'attenDetailReport':
        case 'ishowEditorMusicList':
        case 'chainTypeSetting':
        case 'directlySetting':
        case 'coursewareSecurity':
        case 'homeworkDetail':
        case 'commentDetail':
        case 'workCorrectDetails':
        case 'authorizationCoursewareForm':
        case 'cwsteacherManager':
        case 'cwsclassManager':
        case 'cwsstudentManager':
        case 'cwscoursewareManagerTwo':
        case 'campusForm':
        case 'coursewareUseReportTwo':
        case 'coursewareMsgInform':
        case 'authorizationCoursewareManage':
        case 'clewAddForm':
        case 'commentReportList':
        case 'theacherAdiser':
        case 'myStudentList':
          this.openDialogPlus(moduleName, dataKey, data, Callback, title, type);
          break;
        case 'batchEditCourse':
          if (this.isShowBatchEditCourseDialog) {
            isOpen = true;
            break;
          }
          this.callBackFunction = Callback;
          this.batchEditCourseQureyData = data || {};
          this.isShowBatchEditCourseDialog = true;
          break;
        case 'studentApplyCourse':
          console.log('studentApplyCourse');
          if (this.isShowStudentApplyCourseDialog) {
            isOpen = true;
            break;
          }
          this.isShowStudentApplyCourseDialog = true;
          this.studentApplyCourseParams = data;
          break;
        case 'mine':
          this.$refs.mine.doShowPopupShowClick();
          break;
        case 'ignorewarning':
          this.$refs.ignorewarning.doShowPopupShowClick();
          break;
        case 'abnormalchecksign':
          this.updateSignWarningCallBack = Callback || null;
          this.$refs.abnormalchecksign.doShowPopupShowClick(data);
          break;
        default:
          break;
      }
    },
    closeStudentApplyCourseDialog () {
      this.isShowStudentApplyCourseDialog = false;
    },
    deleteRegisterCustomer () {
      this.$nextTick(() => {
        this.$refs.studentFile.$refs.studentFile.studentFilesDialogVisible = false;
        this.$refs.studentFile.visShowPhonePopup = false;
      });
    },
    execCustomEvent () {

    },
    // 获取数据
    getData (dataName) {
      let data = '';
      if (this.curShowDialogParam.data) {
        data = this.curShowDialogParam.data[dataName];
        return data;
      }
    },
    // 关闭弹窗
    closeDialog () {
      let needCloseModuleIndex = this.dialogParamList.findIndex(obj => { return obj.moduleName == arguments[0]; });
      console.log(needCloseModuleIndex, '关闭弹窗');
      if (needCloseModuleIndex >= 0) {
        this.dialogParamList.splice(needCloseModuleIndex, 1);
        this.$nextTick(() => {
          this.resetEvents(this.dialogParamList);
        });
      } else if (!arguments[0]) {
        this.dialogParamList.splice(0, 1);
        this.$nextTick(() => {
          this.resetEvents(this.dialogParamList);
        });
      }
    },
    // 考勤详情关闭---特殊要通知页面刷新.
    closeAttendanceDetailDialog () {
      this.closeDialog(arguments[0]);
      this.$bus.emit('closeAttendanceDetailDialog');
    },
    // 关闭安排考勤---特殊要通知页面刷新.
    closeArrangeAttendanceDialog () {
      this.closeDialog(arguments[0]);
      this.$bus.emit('closeArrangeAttendanceDialog');
    },
    // 海报券编辑器关闭
    closeEditorDialog () {
      this.$bus.emit('closeEditorDialog');
      this.closeDialog(arguments[0]);
    }

  }
};
</script>
<style>
</style>