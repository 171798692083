import { render, staticRenderFns } from "./setup-plan.vue?vue&type=template&id=107933b2&scoped=true"
import script from "./setup-plan.vue?vue&type=script&lang=js"
export * from "./setup-plan.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "107933b2",
  null
  
)

export default component.exports