<template>
  <div class="WKT_course_info_popup WKT_course_info_popup_02">
    <div class="from_textarea_box">
      <div class="from_textarea_title">
        回复内容
      </div>
      <div class="from_textarea_content">
        <textarea v-model="dataInfo.content"
                  placeholder="请输入回复内容"
                  maxlength="100"></textarea>
        <div class="from_textarea_content_btn"
             @click.stop="dataInfo.content=''">
        </div>
      </div>
    </div>
    <!-- <common-button-group @buttonClickEvent="submit"
                         @closeDialog="doAfterClickCancelBtn"></common-button-group> -->
    <save-cancel-btn-group :isSingleSubmit="true"
                           @confirmClick="submit"
                           @cancelClick="doAfterClickCancelBtn"></save-cancel-btn-group>
  </div>
</template>
<script>
import { ReplyToFansWeChatMessage } from '../../../../../../API/workbench';
export default {
  data () {
    return {
      dataInfo: {
        content: '',
        openid: '',
        id: 0
      }
    };
  },
  props: {
    seletedItem: Object
  },
  created () {
    // if (this.seletedItem) {
    //   this.dataInfo = this.$utils.parseJson(this.seletedItem);
    // }
  },
  methods: {
    submit (callback) {
      console.log(callback, 'submit');
      if (this.dataInfo.content == '') {
        layer.alert('请输入回复内容');
        if (callback) {
          callback();
        }
        return false;
      }
      this.dataInfo.id = this.seletedItem.id;
      this.dataInfo.openid = this.seletedItem.openid;
      ReplyToFansWeChatMessage(this.dataInfo).then(result => {
        layer.alert('回复成功');
        this.$emit('afterSuccess');
        if (callback) {
          callback();
        }
      }).catch(error => {
        layer.alert(error.msg);
        this.$emit('afterSuccess');
        console.log('errEditDefaultReply', error);
      });
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    }
  }
};
</script>
