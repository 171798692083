<template>
  <div class="layer-content clearfix">
    <div class="layer-main_normal">
      <img class="cut-img"
           :style="{ width:cutImgWidth+'px', height:cutImgHeight+'px', 'position': 'absolute', 'opacity': 0,'left': '-100%','pointer-events':'none'}">

      <vue-crop @afterCrop="afterCrop2"
                ref="cropper"
                :crop-url="selectPicUrl"
                :crood="crood2"
                :ratio="cutImgWHRadio"
                :isOtherPic="true"
                :height="300"
                :width="410"></vue-crop>
    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                           :btnText="'保存'"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="setPicCorp"
                           @cancelClick="clearPicData"></save-cancel-btn-group>
  </div>
</template>

<script>
import { createDomForCutImg, htmlToPCUpLoadImgSimple, htmlToUpLoadImgSimple } from '../../utils/upLoadTextImg';
export default {
  props: {
    selectPicUrl: String,
    cutImgWidth: {// 裁切的图片宽度
      type: Number,
      default: 101
    },
    cutImgHeight: {// 裁切的图片高度
      type: Number,
      default: 101
    },
    uploadTargetPath: {// 上传到pc或者wkt
      type: String,
      default: 'PC'
    },
    saaSClientNameKey: {// 上传到指定门店
      type: [Number, String],
      default: 0
    }
  },
  data () {
    return {
      cutImgElement: null,
      cutImgWHRadio: 1, // 裁切图片的宽高比
      originalImgWidth: 0,
      originalImgHeight: 0,
      crood2: {}   // 截图坐标之类
    };
  },
  mounted () {
    this.cutImgWHRadio = this.cutImgWidth / this.cutImgHeight;
    this.createDomForCutImage();
  },
  methods: {
    createDomForCutImage () {
      this.cutImgElement = this.$el.querySelector('.cut-img');
      console.log('this.cutImgElement', this.cutImgElement);
      let img = new Image();
      img.src = this.selectPicUrl;
      img.onload = () => {
        this.originalImgWidth = img.width;      // 原图片的宽高
        this.originalImgHeight = img.height;
      };
    },
    // 获取裁切数据
    afterCrop2 (json, url) {
      this.crood2 = json;
    },
    // 确认裁切
    setPicCorp () {
      let saasClientKey = this.saaSClientNameKey;
      console.log(this.selectPicUrl, 'this.selectPicUrl', this.crood2, 'this.crood2');
      let originalImgInfo = { url: this.selectPicUrl, width: this.originalImgWidth, height: this.originalImgHeight };
      createDomForCutImg(this.cutImgElement, this.crood2, originalImgInfo, (createDom) => {
        if (this.uploadTargetPath == 'PC') {
          htmlToPCUpLoadImgSimple(createDom, saasClientKey, viewContent => {
            console.log(viewContent, '上传');
            if (viewContent) {
              this.$emit('onConfirmClick', viewContent);   // 上传到pc
            } else {
              this.$emit('onCancelClick');
            }
          });
        } else {
          htmlToUpLoadImgSimple(createDom, saasClientKey, viewContent => {
            console.log(viewContent, '上传');
            if (viewContent) {
              this.$emit('onConfirmClick', viewContent);   // 上传到pc
            } else {
              this.$emit('onCancelClick');
            }
          });
        }
      });
    },
    clearPicData () {
      this.$emit('onCancelClick');
    }
  }
};
</script>

<style>
</style>