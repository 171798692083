<template>
  <div class="left_list "
       @click.stop="clickCourse"
       :class="{opt:courseItem.isActive}">
    <div class="left_list_title"
         :class="$utils.getTextWidth(courseItem.MainDemoName, 16)>168?'font_dual':''">{{courseItem.MainDemoName}}</div>
    <div class="left_list_content clearfix">
      <div class="left_list_content_data "
           style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
           v-if="courseItem.CourseName">
        <span class="font_gray">在读：{{courseItem.CourseName}}</span>
      </div>
      <div class="left_list_content_data">
        <span class="font_gray">班内学生</span> {{$utils.mAmountType(courseItem.CurrentPeoCount,0)}}
        <span class="font_gray">人 |</span>
        <span class="font_gray">当前报课</span> {{$utils.mAmountType(courseItem.Sum_CurrentCount,1)}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { courseItem: Object },
  computed: {},
  created () { },
  methods: {
    clickCourse () {
      this.$set(this.courseItem, 'isActive', true);
      this.$emit('clickCourse', this.courseItem);
    }
  }
};
</script>