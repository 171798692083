<template>
  <div>
    <div style="min-height:226px;padding: 12px 0 120px">
      <div class="new_course_time_from">
        <div class="">
          <div class="form_info_list">
            <input-date v-model="courseTableData.CircleStartTime" 
                        :elementId="'classDate'"
                        :formTitle="'开始日期'"
                        :minDate="$utils.formatDateToLine(Date.new())"
                        :formPlaceholder="'必选'"
                        @changeDate="changeCircleStartTime">
            </input-date>
          </div>

          <div class="form_info_list pr">
            <input-select class="popTop_3"
                          :formTitle="'结束方式'"
                          :id="'key'"
                          :name="'value'"
                          :selectedInfo="vSelectionEndType"
                          :readonly="false"
                          :dataList="vEndTypes"
                          :helpDescription="vHelpDescription"
                          @selectedItem="changeEndType"></input-select>
            <div class="new_course_btn_box"
                 v-if="courseTableData.EndTypeKey==2">
              <div class="new_course_btn"
                   v-for="item in fastBtnList"
                   :key="item.key"
                   :class="{opt:item.isActive}"
                   @click.stop="clickDataBtnType(item)">
                {{ item.value}}
              </div>
            </div>
            <div class="new_course_btn_box"
                 v-if="courseTableData.EndTypeKey==3">
              <input-number :min="0"
                            v-model="courseTableData.ClassCount"
                            :decimalPlaces="0"
                            :formTitle="''"
                            :placeholder="'必填'"
                            :required="false"></input-number>
              <span class="text_pa">节</span>
            </div>
          </div>
          <div class="form_info_list pr"
               v-if="courseTableData.EndTypeKey==2">
            <input-date v-model="courseTableData.CircleEndTime"
                        :elementId="'circleEndTime'"
                        :formTitle="'结束日期'"
                        :placeholder="'必选'"
                        :minDate="$utils.formatDateToLine(Date.new())"
                        @changeDate="changeCircleEndTime"></input-date>
            <div class="new_course_btn_box">
              <span>{{courseTableData.ClassCount}}节</span>
            </div>
          </div>
        </div>
        <div class="form_info_list">
          <div class="form_info_line">
            <div class="form_info_field">
              <span>周几上课</span>
            </div>
            <div class="form_week_content flex">
              <div class="form_week_list"
                   :class="{opt:item.isActive}"
                   v-for="item in weekList"
                   :key="item.key"
                   @click="clickWeek(item)">
                <div>{{item.value}}</div>
              </div>
            </div>
          </div>

          <select-class-time-rang :formInfoLine="false"
                                  :required="false"
                                  @onSelectTimeRange="selectTimeRange"
                                  @onDelTimeRange="delTimeRange"></select-class-time-rang>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      timeRangeForClassTime: '',
      endTypes: [
        { value: '不结束', key: 1, isActive: false },
        { value: '限日期', key: 2, isActive: false },
        { value: '限节数', key: 3, isActive: false }
      ],
      weekList: [
        { value: '一', key: 1, isActive: false },
        { value: '二', key: 2, isActive: false },
        { value: '三', key: 3, isActive: false },
        { value: '四', key: 4, isActive: false },
        { value: '五', key: 5, isActive: false },
        { value: '六', key: 6, isActive: false },
        { value: '日', key: 7, isActive: false }
      ],
      fastBtnList: [
        { value: '1个月', key: 1, isActive: false },
        { value: '2个月', key: 2, isActive: false },
        { value: '1年', key: 5, isActive: false }
      ]
    };
  },
  props: {
    courseTableData: {
      type: Object
    }
  },
  created () {
    this.timeRangeForClassTime = this.$utils.getSaaSClientSetInfo(64).SetContent + ':00-' + this.$utils.getSaaSClientSetInfo(65).SetContent + ':00';
    this.initSelectedWeeks();
  },
  mounted () { },
  computed: {
    vEndTypes () {
      if (this.$store.getters.isSysVersionTY) { // true：通用版
        return this.endTypes;
      } else { // false精准版
        return this.endTypes.slice(1, 3);
      }
    },
    vHelpDescription () {
      if (this.$store.getters.isSysVersionTY) { // true：通用版
        return '不结束：一直延续，不结束循环课<br/>限日期：指定日期结束循环课<br/>限节数：指定开课节数，自动计算结束日期';
      } else { // false精准版
        return '限日期：指定日期结束循环课<br/>限节数：指定开课节数，自动计算结束日期';
      }
    },
    vSaasClientInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vSelectionEndType: {
      get () {
        return {
          id: this.courseTableData.EndTypeKey,
          name: this.courseTableData.EndTypeKeyValue
        };
      }
    }
  },
  methods: {
    // 变更结束类型
    changeEndType (item) {
      switch (item.key) {
        case 1:
          this.courseTableData.CircleEndTime = this.$utils.formatDateToLine(this.$utils.getNextYear(Date.new()));
          this.calculateClassCount();
          break;
        case 2:
          this.courseTableData.CircleEndTime = '';
          this.fastBtnList.forEach(obj => {
            obj.isActive = false;
          });
          this.courseTableData.ClassCount = 0;
          break;
        case 3:
          this.courseTableData.CircleEndTime = '';
          this.courseTableData.ClassCount = 0;
          break;
        default:
          break;
      }
      console.log('changeEndType', item);
      this.courseTableData.EndTypeKey = item.key;
      this.courseTableData.EndTypeKeyValue = item.value;
    },
    // 选中时间段查询
    selectTimeRange (item) {
      this.courseTableData.TimeQuantum = item.ClassTimeName;
      this.courseTableData.DurationCount = item.DurationCount;
      this.calculateClassCount();
    },
    // 删除时间段
    delTimeRange () {
      this.courseTableData.TimeQuantum = '';
      this.courseTableData.DurationCount = 0;
    },
    initSelectedWeeks () {
      this.courseTableData.WeekList.forEach((val) => {
        val = val == 0 ? 7 : val;
        let weekItem = this.weekList.find((item) => { return item.key == val; });
        if (weekItem) {
          weekItem.isActive = true;
        }
      });
    },
    clickWeek (weekItem) {
      weekItem.isActive = !weekItem.isActive;
      if (weekItem.isActive) {
        this.courseTableData.WeekList.push(weekItem.key);
      } else {
        let findIndex = this.courseTableData.WeekList.findIndex((val) => { return val == weekItem.key; });
        if (findIndex > -1) {
          this.courseTableData.WeekList.splice(findIndex, 1);
        }
      }
      this.calculateClassCount();
    },
    //  变更开始日期
    changeCircleStartTime (date) {
      this.courseTableData.CircleStartTime = date;
      this.fastBtnList.forEach(obj => {
        obj.isActive = false;
      });
      this.calculateClassCount();
    },
    //  变更结束日期
    changeCircleEndTime (date) {
      this.courseTableData.CircleEndTime = date;
      this.fastBtnList.forEach(obj => {
        obj.isActive = false;
      });
      this.calculateClassCount();
    },
    // 计算时长
    calculateDuration () {
      if (this.courseTableData.TimeQuantum) {
        var SClassTime = this.courseTableData.TimeQuantum.split('-')[0].slice(0, 2) * 60 + Number(this.courseTableData.TimeQuantum.split('-')[0].slice(3, 5));
        var EClassTime = this.courseTableData.TimeQuantum.split('-')[1].slice(0, 2) * 60 + Number(this.courseTableData.TimeQuantum.split('-')[1].slice(3, 5));
        this.courseTableData.DurationCount = Number(EClassTime - SClassTime);
      }
    },
    // 快捷(二月 半年 一年) 按钮
    clickDataBtnType (item) {
      if (!this.courseTableData.CircleStartTime) {
        layer.alert('请先选择开始日期');
        return false;
      }
      let circleStartTime = Date.new(this.courseTableData.CircleStartTime);
      let year = circleStartTime.getFullYear();
      let month = circleStartTime.getMonth();
      let date = circleStartTime.getDate() - 1;
      this.fastBtnList.forEach(obj => {
        if (item.key == obj.key) {
          obj.isActive = true;
        } else {
          obj.isActive = false;
        }
      });

      switch (item.key) {
        case 1:// 一个月
          this.courseTableData.CircleEndTime = this.$utils.formatDateToLine(Date.new(year, month + 1, date));
          break;
        case 2:// 两个月
          this.courseTableData.CircleEndTime = this.$utils.formatDateToLine(Date.new(year, month + 2, date));
          break;
        case 5:// 一年
          this.courseTableData.CircleEndTime = this.$utils.formatDateToLine(Date.new(year + 1, month, date));
          break;
        default:
          break;
      }
      this.calculateClassCount();
    },
    // 计算上课节数
    calculateClassCount () {
      let maxClassNum = 0;
      if (this.courseTableData.CircleStartTime && this.courseTableData.CircleEndTime && this.courseTableData.WeekList.length > 0 && this.courseTableData.TimeQuantum) {
        let curMinutes = Date.new().getHours() * 60 + Date.new().getMinutes(); // 当前时段
        let classMinutes = Number(this.courseTableData.TimeQuantum.split('-')[1].split(':')[0]) * 60 + Number(this.courseTableData.TimeQuantum.split('-')[1].split(':')[1]); // 上课时段

        let curWeek = Date.new(this.courseTableData.CircleStartTime).getDay(); curWeek = curWeek == 0 ? 7 : curWeek;// 开始日期是周几
        let isTodayPass = false; // 是否选了今天的日期且为过去时段
        if (Date.new(this.courseTableData.CircleStartTime).getTime() == Date.new(this.$utils.formatDateToLine(Date.new())).getTime() && classMinutes <= curMinutes) {
          isTodayPass = true;
        }
        this.courseTableData.WeekList.forEach(week => {
          let datas = this.$utils.getDateArrForOneDate(this.courseTableData.CircleStartTime, this.courseTableData.CircleEndTime, week == 7 ? 0 : week);
          maxClassNum = maxClassNum + datas.length;
          // 开始日期为今天 且上课时段为过去时间
          if (isTodayPass && week == curWeek) {
            --maxClassNum;
            datas.shift();
          }
        });
      } else if (this.courseTableData.EndTypeKey == 3 && !this.courseTableData.CircleEndTime) {
        maxClassNum = this.courseTableData.ClassCount;
      }
      this.courseTableData.ClassCount = maxClassNum;
    }

  }
};
</script>
<style scoped>
.form_info_edit .form_info_value input.el-input__inner {
  padding-right: 34px;
  vertical-align: top;
}
.el-date-editor input.el-input__inner {
  color: #49b5f3 !important;
}
</style>