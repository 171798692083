import { render, staticRenderFns } from "./score-item.vue?vue&type=template&id=2c7f068c"
import script from "./score-item.vue?vue&type=script&lang=js"
export * from "./score-item.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports