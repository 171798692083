<template>
  <div>
    <module-description :textTips="'如何申请信息模板、选择行业'"
                        :btnTips="'查看教程'"
                        :btnUrl="'https://mp.weixin.qq.com/s/BQ4Xiqe0K8lNx1KmQNdt-g'"></module-description>
    <div class="WKT_course_info_popup"
         style="min-height:150px">
      <div class="from_input_list">
        <div class="from_input_title form_info_required">
          模板编号
        </div>
        <div class="from_input_box">
          <input type="text"
                 v-model="dataInfo.number"
                 placeholder="请输入模板编号">
        </div>
      </div>
      <div class="from_input_list">
        <div class="from_input_title form_info_required">
          模板名称
        </div>
        <div class="from_input_box">
          <input type="text"
                 v-model="dataInfo.name"
                 placeholder="请输入模板名称">
        </div>
      </div>
      <div class="from_input_keyword form_info_required">关键词</div>
      <div class="keyword_info">
        <el-tag :key="tag"
                v-for="tag in dynamicTags"
                closable
                :disable-transitions="false"
                @close="handleClose(tag)">
          {{tag}}
        </el-tag>
        <el-input class="input-new-tag"
                  v-if="inputVisible"
                  v-model="inputValue"
                  ref="saveTagInput"
                  size="small"
                  @keyup.enter.native="handleInputConfirm"
                  @blur="handleInputConfirm">
        </el-input>
        <el-button v-else
                   class="button-new-tag"
                   size="small"
                   @click="showInput">+ 增加</el-button>
      </div>

    </div>
    <common-button-group @buttonClickEvent="submit"
                         @closeDialog="doAfterClickCancelBtn"></common-button-group>
  </div>

</template>
<script>
import { AddWeChatTemplate } from '../../../../../API/workbench';
export default {
  data () {
    return {
      dataInfo: {
        number: '',
        name: ''
      },
      dynamicTags: [],
      inputVisible: false,
      inputValue: ''
    };
  },
  props: {
  },
  created () {
  },
  methods: {
    handleClose (tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput () {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm () {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    submit (callback) {
      console.log('submit', this.dataInfo, this.dynamicTags);
      if (this.dataInfo.number == '') {
        layer.alert('请输入模板编号');
        if (callback) {
          callback();
        }
        return false;
      } else if (this.dataInfo.name == '') {
        layer.alert('请输入模板名称');
        if (callback) {
          callback();
        }
        return false;
      } else if (this.dynamicTags.length == 0) {
        layer.alert('请添加关键词');
        if (callback) {
          callback();
        }
        return false;
      }
      AddWeChatTemplate(this.dataInfo, this.dynamicTags).then(result => {
        layer.alert('新增成功');
        this.$emit('afterSuccess');
        if (callback) {
          callback();
        }
      }).catch(error => {
        layer.alert(error.msg);
        console.log('errEditDefaultReply', error);
      });
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.keyword_info {
  width: 300px;
  display: flex;
  margin-left: 69px;
  flex-wrap: wrap;
}
.el-tag {
  margin-left: 10px;
}
.el-tag + .el-tag {
  margin-left: 10px;
  margin-bottom: 15px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 15px;
  margin-left: 10px !important;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px !important;
  vertical-align: bottom;
  margin-bottom: 15px !important;
  margin-left: 0px;
}
.from_input_keyword {
  position: absolute;
}
.form_info_required:nth-of-type(3)::before {
  content: '*';
  top: 4px !important;
}
</style>