<template>
  <div class="">
    <div class="course_statistics_title">
      <div class="title_date">
        <div class="title_date_select"
             style="margin-left: 0px;    width: 222px;">
          <div class="el-select">
            <div class="el-input el-input--small el-input--suffix is-focus">
              <single-year-picker @chgDate="chgDate"
                                  ref="monthPicker"
                                  :type="'month'"></single-year-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="title_number">
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn"
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_content">

      <div class="table_list_content introduce_list campusManagement_list">
        <table-list class="summarizing_list"
                    :tableData="reportList"
                    :tableColumns="tableColumns"
                    :queryParams="objP"
                    :showPagePagination="false"
                    @loadTableData="getReportList"></table-list>
      </div>

    </div>

  </div>
</template>
<script>
import singleYearPicker from '../../base-module/date-picker-common/single-year-picker';
import tableList from '../../common/table-list/index';
import { ManageSaleAchievementSummarizing } from '../../../API/workbench';
// import dayItem from './day-item';
export default {
  data () {
    return {
      objP: {
        pageIndex: 0,
        pageSize: 5,
        orderBy: '',
        sequence: '',
        searchText: ''
      },
      tableColumns: [
        {
          label: '排序',
          width: 75,
          prop: 'indexN',
          align: 'center',
          type: 'tableIndex',
          sortable: false
        },
        {
          label: '姓名',
          prop: 'SalesKeyValue',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '报名收款',
          prop: 'ActualColAmount',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '跟单记录',
          prop: 'FollowUpCount',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '新增线索',
          prop: 'NewThreadCount',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '已诺放',
          prop: 'PromiseVisitCount',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '已约课',
          prop: 'InvitedClassCount',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '已试课',
          prop: 'TrialLessonCount',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '已报名',
          prop: 'SignUpCount',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        }
      ],
      seletedDate: Date.new().toLocaleDateString(),
      searchObj: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd')
      },
      queryParams: {
        StartTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        EndTime: this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd'),
        IsExportKey: 0
      },
      reportList: [],
      monthTotal: 0
    };
  },
  props: {
  },
  components: {
    singleYearPicker,
    // dayItem,
    tableList
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    },
    vMenDianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    isShowLeave () {
      if (this.$utils.getSaaSClientSet(32) == 3) {
        return true;
      } else if (this.$utils.getSaaSClientSet(32) == 4) {
        let locaIndex = this.reportList.findIndex(o => {
          return Number(o.LeaveNumCount) > 0;
        });
        if (locaIndex >= 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isShowTruant () {
      if (this.$utils.getSaaSClientSet(33) == 3) {
        return true;
      } else if (this.$utils.getSaaSClientSet(33) == 4) {
        let locaIndex = this.reportList.findIndex(o => {
          return Number(o.TruantNumCount) > 0;
        });
        if (locaIndex >= 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    vWith () {
      let width = Math.round((80 / 377) * 100);
      let otherWitdh = Math.round((55 / 377) * 100);
      let count = 0;
      if (this.isShowLeave && this.isShowTruant) {
        return width;
      } else {
        if (!this.isShowLeave) {
          count++;
        }
        if (!this.isShowTruant) {
          count++;
        }
        return width + (otherWitdh * count) / (5 - count);
      }
    },
    vWith2 () {
      let width = Math.round((90 / 377) * 100);
      let otherWitdh = Math.round((55 / 377) * 100);
      let count = 0;
      if (this.isShowLeave && this.isShowTruant) {
        return width;
      } else {
        if (!this.isShowLeave) {
          count++;
        }
        if (!this.isShowTruant) {
          count++;
        }
        return width + (otherWitdh * count) / (5 - count);
      }
    },
    vWith3 () {
      let width = Math.round((50 / 377) * 100);
      let otherWitdh = Math.round((55 / 377) * 100);
      let count = 0;
      if (this.isShowLeave && this.isShowTruant) {
        return width;
      } else {
        if (!this.isShowLeave) {
          count++;
        }
        if (!this.isShowTruant) {
          count++;
        }
        return width + (otherWitdh * count) / (5 - count);
      }
    },
    otherWitdh () {
      let otherWitdh = Math.round((55 / 377) * 100);
      let count = 0;
      if (this.isShowLeave && this.isShowTruant) {
        return otherWitdh;
      } else {
        if (!this.isShowLeave) {
          count++;
        }
        if (!this.isShowTruant) {
          count++;
        }
        return otherWitdh + (otherWitdh * count) / (5 - count);
      }
    }

  },
  mounted () {
    this.$bus.on(this, 'wsAttendanceOrArrange', (data) => {
      this.getReportList();
    });
    this.getReportList();
  },
  methods: {
    chgDate (val, startTime, endTime) {
      this.seletedDate = val;
      this.queryParams.StartTime = startTime;
      this.queryParams.EndTime = endTime;
      this.searchObj.startTime = startTime;
      this.searchObj.endTime = endTime;
      this.$emit('chgStatisticsDate', this.queryParams);
      this.getReportList();
    },
    getReportList () {
      ManageSaleAchievementSummarizing(this.queryParams).then(result => {
        this.queryParams.IsExportKey = 0;
        result.data.forEach((o, i) => {
          o.indexN = i + 1;
          o.ActualColAmount = o.ActualColAmount ? '¥' + Number(o.ActualColAmount) : '-';
          o.FollowUpCount = o.FollowUpCount ? Number(o.FollowUpCount) : '-';
          o.NewThreadCount = o.NewThreadCount ? Number(o.NewThreadCount) : '-';
          o.PromiseVisitCount = o.PromiseVisitCount ? Number(o.PromiseVisitCount) : '-';
          o.InvitedClassCount = o.InvitedClassCount ? Number(o.InvitedClassCount) : '-';
          o.TrialLessonCount = o.TrialLessonCount ? Number(o.TrialLessonCount) : '-';
          o.SignUpCount = o.SignUpCount ? Number(o.SignUpCount) : '-';
        });
        this.reportList = result.data;
        console.log(result.data, 'ManageSaleAchievementSummarizing');
      }).catch(error => {
        console.log('error', error.msg);
      });
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.reportList.length > 0) {
        this.queryParams.IsExportKey = 1;
        let jsonFormate = {
          SheetTitle: '招生管理-成果统计',
          clist: [
            { title: '姓名', cName: 'SalesKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '报名收款', cName: 'ActualColAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '跟单记录', cName: 'FollowUpCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '新增线索', cName: 'NewThreadCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '已诺访', cName: 'PromiseVisitCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '已约课', cName: 'InvitedClassCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '已试课', cName: 'TrialLessonCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '已报名', cName: 'SignUpCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        ManageSaleAchievementSummarizing(this.queryParams, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.queryParams.IsExportKey = 0;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    }
  }
};
</script>
<style scoped>
.course_statistics_title {
  padding: 0px !important;
}
.table_thead {
  margin: 0 !important;
}
.summarizing_list >>> .el-table .el-table__body-wrapper {
  height: 223px;
  overflow-y: auto;
}
</style>