<template>
  <div class="spring_frame_portrait_back button"
       @click.stop="selectedStudent">
    <div class="spring_frame_portrait_back_item">
      <div class="spring_frame_portrait_back_item_img pr">
        <heads-img :imgUrl="headImg"
                   :dataInfo="studentItem"></heads-img>
        <div class="icon_bottom bg_green pa"
             v-if="vAttendanceStatusKey==3">
        </div>
        <div class="icon_bottom bg_red pa"
             v-else-if="vAttendanceStatusKey==5">
          旷
        </div>
        <div class="icon_bottom bg_yellow pa"
             v-else-if="vAttendanceStatusKey==4">
          假
        </div>
        <!-- <div class="icon_bottom bg_warning pa"
             v-else-if="isCourseEnd">

        </div> -->
      </div>

      <!-- <img src="" class="spring_frame_portrait_back_item_img" /> -->
    </div>
    <div class="spring_frame_portrait_back_name">{{studentItem.StudentKeyValue}}</div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: ['studentItem', 'isCourseEnd', 'isBatchAttendance'],
  computed: {
    vAttendanceStatusKey () {
      return this.studentItem.AttenceStatusKey;
    },
    vNoClassHourse () { // 无课时 未考勤且欠课时
      if (this.studentItem.AttenceStatusKey == 2 && this.studentItem.IsClassHourLackKey == 1) {
        return true;
      } else {
        return false;
      }
    },
    // 头像
    headImg () {
      if (this.studentItem && this.studentItem.HeadImgSupport && this.studentItem.HeadImgSupport !== 'null') {
        if (this.studentItem.HeadImgSupport.indexOf('http') > -1) {
          return this.studentItem.HeadImgSupport;
        } else {
          let PCAPIURL = this.$store.getters.CDNURL;
          return (
            PCAPIURL + this.studentItem.HeadImgSupport
          );
        }
      } else {
        return '';
      }
    }
  },
  methods: {
    // 选中学生事件 告诉 <student-list> 并传递选中项信息
    selectedStudent () {
      if (!this.isBatchAttendance) {
        this.$emit('selectedStudent', this.studentItem);
      } else {
        this.studentItem.isSelected = !this.studentItem.isSelected;
      }
    }
  }
};
</script>
