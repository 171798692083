<template>
  <div>
    <div class="form_info_edit">
      <div class="form_info_line single_shadow" style="padding: 0px 12px">
        <input-text
          :formTitle="'备注'"
          style="width: 100%"
          :readonly="false"
          :formPlaceholder="'选填'"
          :required="false"
          v-model="remarks"
        ></input-text>
      </div>
    </div>
    <!-- 按钮组 -->
    <div>
      <save-cancel-btn-group
        :isSingleSubmit="true"
        :singleSubmitType="'dynamic'"
        @confirmClick="confirmSave"
        @cancelClick="closeDialog"
      ></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      remarks: "",
    };
  },
  props:{
      optionItem:Object,
  },
  methods: {
      confirmSave(){
          this.$emit('changeAttendance',this.remarks);
      },
      closeDialog(){
          this.$emit('cancel');
      }
  },
};
</script>