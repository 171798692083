<template>
  <!--   -->
  <div v-show="showBox && courseData.CourseNameKeyValue">
    <div @click.stop="hideBox"
         class="new_popup">
      <div :style="{'top':vTop,'left':vLeft}"
           class="course_popup_label"
           @mouseover.stop="keepShow"
           @mouseleave="closeShow"
           @click.stop="boxShow">

        <div class="label_title">
          <span>{{courseTableData.GradeClassKeyValue || courseData.GradeClassKeyValue}}</span>
          <span @click="isShowMenu = true"
                class="font_blue">更多</span>
        </div>
        <div class="label_content">
          <div>
            <span class="font_gray">时间：</span>
            <span>{{courseTableData.week || courseData.week}}&nbsp;&nbsp;{{courseTableData.BeginClassTime || courseData.BeginClassTime}}&nbsp;&nbsp;{{formatDate(courseTableData.ClassTime ||courseData.ClassTime)}}</span>
          </div>
          <div>
            <span class="font_gray">课程：</span>
            <span>{{courseTableData.CourseNameKeyValue || courseData.CourseNameKeyValue}}</span>
          </div>
          <div>
            <span class="font_gray">老师：</span>
            <span>{{courseTableData.MTeacherKeyValue || courseData.MTeacherKeyValue}}</span>
          </div>
          <div>
            <span class="font_gray">教室：</span>
            <span>{{courseTableData.classRoomName || courseData.classRoomName}}</span>
          </div>
          <div>
            <span class="font_gray">课消：</span>
            <span>{{Number(courseTableData.PeriodCount || courseData.PeriodCount || 0)}}课时/节课</span>
          </div>
        </div>
        <div class="label_number clearfix"
             @click="toAttendance">
          <div class="fl">
            <span class="font_gray">安排：</span>{{Number(courseTableData.ArrangedNumCount || courseData.ArrangedNumCount)}}人
          </div>
          <div class="fr"
               v-show="courseData.StatusKey != 2">
            <span class="font_gray">签到：</span>{{Number(courseTableData.ArrivedNumCount || courseData.ArrivedNumCount)}}人
          </div>
        </div>
        <div @click="toAttendance"
             class="button">
          <div v-if="courseData.StatusKey == 2 && (courseData.Attence4 > 0 || courseData.ArrangedNumCount == 0)"
               class="label_type type_red">
            <span v-if="courseData.ArrangedNumCount == 0">未安排学生</span>
            <span v-else-if="courseData.Attence4 > 0">请假{{Number(courseData.Attence4)}}人</span>
          </div>
          <div v-else-if="courseData.StatusKey != 2 && (courseData.ArrangedNumCount == 0 || courseData.Attence4 >0 || courseData.Attence5 >0 || courseData.Attence2 >0)"
               class="label_type type_red">
            <span v-if="courseData.ArrangedNumCount == 0">未安排学生</span>
            <span v-else>
              <span v-show="courseData.Attence4 >0">请假{{Number(courseData.Attence4)}}人</span>
              <span v-show="courseData.Attence4 >0 && courseData.Attence5 >0">,</span>
              <span v-show="courseData.Attence5 >0">旷课{{Number(courseData.Attence5)}}人</span>
              <span v-show="courseData.Attence5 >0 && courseData.Attence2 > 0 || courseData.Attence4 > 0 && courseData.Attence2 >0">,</span>
              <span v-show="courseData.Attence2 >0">未考勤 {{Number(courseData.Attence2)}}人</span>
            </span>
          </div>
        </div>
        <div v-show="isShowMenu"
             class="label_btn_box">
          <function-btn v-if="!noBtn&&!vIsShowHistoryData"
                        :item="authBtn.attnArrange"
                        @clickItem="toAttendance"></function-btn>
          <function-btn v-if="Number(courseData.StatusKey) !== 4 && !noBtn && !btnChangeEnable"
                        :item="authBtn.addSingleCourse"
                        @clickItem="toNewCourse"></function-btn>

          <function-btn v-if="Number(courseData.StatusKey) !== 4 && !noBtn && !btnChangeEnable"
                        :item="authBtn.addAroundCourse"
                        @clickItem="toNewCourse2"></function-btn>

          <div @click.stop="toNoAttendance"
               v-if="!noBtn">查看课程</div>
          <function-btn v-if="!noBtn&&isExpireDate&&!vIsShowHistoryData"
                        :item="authBtn.editCourse"
                        @clickItem="toAlterCourse"></function-btn>
          <function-btn v-if="!noBtn&&!vIsShowHistoryData"
                        :item="authBtn.stopCourse"
                        @clickItem="courseSuspend"></function-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PauseScheduleCourse, getCourseDetailed } from '../../../API/workbench';
import { getWeekByTwo } from '../../../utils/index';
import dropDownBox from '../../common/drop-down-box';
export default {
  data () {
    return {
      isLoad: false,
      showBox: false,
      isShowMenu: false,
      // vTop: 300
      upTop: 300,
      vtop: 0,
      courseData: {},
      // 权限控制
      authBtn: {
        attnArrange: {
          name: '安排考勤',
          ModulePowerKey: 51
        },
        editCourse: {
          name: '修改单课',
          ModulePowerKey: 47
        },
        stopCourse: {
          name: '停课',
          ModulePowerKey: 47
        },
        addSingleCourse: {
          name: '日历建课',
          ModulePowerKey: 47
        },
        addAroundCourse: {
          name: '新建循环课',
          ModulePowerKey: 47
        }
      },
      newScheduleCourseObjType: [
        {
          id: '3',
          name: '日历建课',
          ModulePowerKey: 47,
          isShow: true
        },
        {
          id: '2',
          name: '新建循环课',
          ModulePowerKey: 47,
          isShow: true
        }
      ],
      isShowNewCourseType: false
    };
  },
  props: {
    courseTableData: Object,
    vTop: String,
    vLeft: String,
    noBtn: Boolean,
    showContent: Boolean
  },
  components: {
    dropDownBox
  },
  created () {
    if (this.showBox) {
      this.initPopupDialogBoxStyle();
    }
    if (this.noBtn) {
      this.showBox = true;
    }
  },
  computed: {
    vIsShowHistoryData () {
      console.log('showContent', this.showContent, this.courseTableData)
      return this.$utils.getIsLastData(this.courseTableData.ClassTime);
    },
    // 计算是否超过30天
    isExpireDate () {
      // 2592000000 为一个月的时间戳.
      console.log(this.courseData, 'isExpireDate');
      let classTime =
        this.courseData.ClassTime + ' ' + this.courseData.BeginClassTime;
      if (Date.new(classTime).getTime() + 5184000000 > Date.new().getTime()) {
        return true;
      } else {
        return false;
      }
    },
    // 已完结的课程不可点击
    btnChangeEnable () {
      let presentDate = this.$utils.formatDateToLine(Date.new());
      if (this.courseData.ClassTime > presentDate) {
        return false;
      } else if (this.courseData.ClassTime == presentDate) {
        if (this.courseData.StatusKey == 2) {
          // 2:未开始，4：已完结
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    }
  },
  watch: {
    showBox (n, o) {
      if (n) {
        this.initPopupDialogBoxStyle();
      } else {
        this.isShowMenu = false;
      }
    }
  },
  methods: {
    toNewCourse () {
      this.toNewschedulecourse('3');
    },
    toNewCourse2 () {
      this.toNewschedulecourse('2');
    },
    showGetData (OLAPKey) {
      this.showBox = true;
      this.getCourseDetailedByID(OLAPKey);
    },
    getCourseDetailedByID (OLAPKey) {

      console.log('OLAPKey', OLAPKey)
      if (this.isLoad) {
        return false;
      } else {
        this.isLoad = true;
      }
      this.isShowMenu = false;
      if (!OLAPKey) {
        return false;
      }
      setTimeout(() => {
        let ishis = this.$utils.getIsLastData(this.courseTableData.ClassTime)
        console.log('this.vIsShowHistoryData', ishis)
        getCourseDetailed(OLAPKey, ishis ? 1 : 0).then(result => {
          let e = result.data;
          if (Number(e.ArrangedNumCount) <= Number(e.FullPeopleCount)) {
            if (
              Number(e.AttenceCount) == 0 &&
              Number(e.FullPeopleCount) == 0
            ) {
              e.AttencePercentage = 0;
            } else {
              e.AttencePercentage = Math.ceil(
                (Number(e.AttenceCount) / Number(e.FullPeopleCount)) * 100
              );
            }
            if (
              Number(e.ArrangedNumCount) == 0 &&
              Number(e.FullPeopleCount) == 0
            ) {
              e.ArrangedPercentage = 0;
            } else {
              e.ArrangedPercentage = Math.ceil(
                (Number(e.ArrangedNumCount) / Number(e.FullPeopleCount)) *
                100
              );
            }
          } else {
            if (
              Number(e.AttenceCount) == 0 &&
              Number(e.ArrangedNumCount) == 0
            ) {
              e.AttencePercentage = 0;
            } else {
              e.AttencePercentage = Math.ceil(
                (Number(e.AttenceCount) / Number(e.ArrangedNumCount)) *
                100
              );
            }
            e.ArrangedPercentage = 100;
          }
          e.week = getWeekByTwo(e.ClassTime);
          console.log('e', JSON.parse(JSON.stringify(e)))
          this.courseData = e;
          this.courseData.OLAPKey = this.courseData.ScheduleCourseKey;
          this.isLoad = false;
        }).catch(err => {
          console.log(err, 'error');
        });
      }, 100)

    },
    // 是否有权限.
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    },
    // 计算滚动条高度
    initPopupDialogBoxStyle () {
      let top = 0;
      if (this.showBox) {
        if (process.env.NODE_ENV !== 'development') {
          // console.log('this.width', this.width);
          // console.log('dialogHeight', dialogHeight);
          // console.log(
          //   "clientHeight",
          //   window.parent.document.getElementById("Form").clientHeight
          // );
          // console.log(
          //   "scrollTop",
          //   window.parent.document.getElementById("Form").scrollTop
          // );
          if (window.parent.document.getElementById('Form')) {
            top =
              window.parent.document.getElementById('Form').scrollTop - 100 <= 0
                ? Number(this.upTop)
                : window.parent.document.getElementById('Form').scrollTop -
                100 +
                Number(this.upTop);
          }
        } else {
          top = 300;
        }
        this.vtop = top + 'px';
      }
    },
    hideBox () {
      this.showBox = false;
      this.$emit('hideBox');
    },
    formatDate (date) {
      if (date) {
        let arr = date.split('-');
        return arr[1] + '/' + arr[2];
      }
    },
    toAlterCourse () {
      this.$emit('chgEditCourseShow', true);
    },
    // 停课
    courseSuspend () {
      var that = this;
      layer.confirm('是否停课', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: function () {
              that.setCourseCancel(1);
              layer.close();
            }
          }
        ]
      });
    },
    // 根据课名ID 停课.
    setCourseCancel (IsNotEndKey) {
      if (!this.courseData.OLAPKey) {
        return false;
      }
      PauseScheduleCourse(this, this.courseData.OLAPKey, IsNotEndKey).then(
        result => {
          layer.alert('停课成功');
          this.hideBox();
          this.$emit('chgCourse');
        },
        error => {
          if (error.code == 70001) {
            layer.confirm(error.msg, {
              btn: [
                {
                  name: '确认',
                  callBack: () => {
                    this.setCourseCancel(0);
                    layer.close();
                  }
                }
              ]
            });
          } else {
            layer.alert(error.msg);
          }
        }
      );
    },
    toAttendance () {
      console.log('this.courseData', JSON.parse(JSON.stringify(this.courseData), this.courseTableData))
      if (this.vHasModulePower(this.authBtn.attnArrange.ModulePowerKey)) {
        this.$emit('unAttendanceItem', this.courseData, this.vIsShowHistoryData);
      } else {
        this.$utils.CheckModulePowerToTips(this.authBtn.attnArrange.ModulePowerKey);
      }

      // this.$router.push({ path: 'attendanceArrange', query: { id: this.courseData.OLAPKey, title: '安排考勤'} });
    },
    toNoAttendance () {
      // this.hideBox();
      this.$emit('chgCourseDetailShow');
      // this.$router.push({ path: 'attendanceArrange', query: { id: this.courseData.OLAPKey, title: '查看课程'} });
    },
    // 进去新建课表
    toNewschedulecourse (type) {
      if (this.vHasModulePower(47)) {
        this.$emit('toScheduleCourse', this.courseData, type);
      } else {
        this.$utils.CheckModulePowerToTips(47);
      }
    },
    keepShow () {
      console.log('keepShow');
      this.$emit('keepShow', true);
    },
    closeShow () {
      this.$emit('closeShow');
    },
    boxShow () {
      this.$emit('keepShow', true);
    },
    // 鼠标移到新建课
    mouseOverNewCourseTable () {
      clearTimeout(this.timer);
      this.isShowNewCourseType = true;
    },
    // 鼠标离开新建课
    mouseOutNewCourseTable () {
      this.timer = setTimeout(() => {
        this.isShowNewCourseType = false;
      }, 100);
    },
    // 新建课表 选中课类型（多课 单课）
    doAfterSelectedNewCourseTableType (item) {
      this.isShowNewCourseType = false;
      this.showBox = false;
      this.toNewschedulecourse(item.id);
    }
  }
};
</script>