<template>
  <div class="download_content">
    <div class="download_content_item">
      <div class="download_content_item_bg">
        <img class="download_img_box"
             src="../../../../public/image/system_pc.png"
             alt="" />
      </div>
      <div class="download_text"
           @click="mobileLogin('电脑端')">winPC端下载</div>
    </div>
    <div class="download_content_item">
      <div class="download_content_item_bg">
        <img class="download_img_box"
             src="../../../../public/image/system_tv.png"
             alt="" />
      </div>
      <div class="download_text"
           @click.stop="mobileLogin('电视端')">TV端下载</div>
    </div>
    <div class="download_content_item">
      <div class="download_content_item_bg"
           style="margin-right:0px">
        <img style="width:50%"
             class="download_img_box"
             src="../../../../public/image/system_mobile.png"
             alt="" />
      </div>
      <div class="download_text"
           @click.stop="vBrandInfo.isOpenBrand?doAppBrandDownUrl('App'):mobileLogin('手机端')">安卓APP下载</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {};
  },
  computed: {
    vBrandInfo () {
      let obj = {};
      if (window.brandCustomSettingStr && window.brandCustomSettingStr != '') {
        obj = JSON.parse(window.brandCustomSettingStr);
        obj.isOpenBrand = true;
        obj.LoginBackground = 'url(' + this.$store.getters.CDNURL + obj.LoginBackground + ')';
        obj.LogoUrl = this.$store.getters.CDNURL + obj.LogoUrl;
        obj.QRCode = this.$store.getters.CDNURL + obj.QRCode;
        obj.inputBackground = '#fff';
      } else {
        obj = {
          isOpenBrand: false,
          MainBodyColor: '#f85a59',
          MainDemoName: '治学培训机构',
          QRCode: '',
          inputBackground: '#f7f7f7'
        };
      }
      return obj;
    }

  }
};
</script>
<style scoped>
.download_content {
  width: 100%;
  padding: 20px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  margin-top: 35px;
}
.download_content_item {
  width: 290px;
  height: 300px;

  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
}

.download_content_item_bg {
  background: url('../../../../public/image/system_bg.png') center no-repeat;
  width: 290px;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(204 204 204 / 0%);
  border-radius: 10px;
  flex-direction: column;
}
.download_content_item_bg .download_img_box {
  width: 70%;
  height: auto;
}
.download_text {
  margin-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(54, 54, 54);
  cursor: pointer;
}
.download_text:hover {
  color: #3498db !important;
  border-bottom: 1px solid #3498db !important;
}
</style>
