<template>
  <div>
    <!-- 完善学生资料 -->
    <div class="single_result_detail form_info_detail">
      <div class="student_info_edit form_info_edit form_info_list content_marginTop_30 content_marginRL single_shadow">
        <input-text :formTitle="'学生名'"
                    :formPlaceholder="'必填'"
                    :readonly="isReadOnly"
                    :required="true"
                    v-model="customerInfo.MainDemoName"></input-text>

        <form-select :formTitle="'性别'"
                     :required="false"
                     :readonly="isReadOnly"
                     :dataItem="customerInfo.SexKeyValue"
                     :dataList="sexData"
                     v-model="customerInfo.SexKeyValue"
                     @choseItem="changeSexData"></form-select>

        <input-age :min="0"
                   :max="100"
                   :formTitle="'年龄'"
                   :placeholder="agePlaceHolder"
                   :className="ageclassName"
                   :readonly="isReadOnly"
                   :required="false"
                   v-model="customerInfo.AgeName"></input-age>

        <input-phone :formTitle="'手机号'"
                     :required="false"
                     :readonly="isChgMobile||isReadOnly"
                     @input="doAfterChangeMobileSupport"
                     v-model="customerInfo.MobileSupport"></input-phone>

        <form-select :formTitle="'关系'"
                     :required="false"
                     :readonly="isReadOnly"
                     :dataItem="customerInfo.CustomerRelationshipKeyValue"
                     :dataList="customerRelationship"
                     v-model="customerInfo.CustomerRelationshipKeyValue"
                     @choseItem="changeRelationship"></form-select>

        <div v-show="isAllInfoShow">
          <input-date :formTitle="'出生日期'"
                      :endDate="$utils.formatDateToLine(Date.new())"
                      :formPlaceholder="'请选择'"
                      :readonly="isReadOnly"
                      @doBindDateChange="chgBirthdayTime"
                      v-model="customerInfo.BirthdayTime"></input-date>

          <input-text-placeholder :formTitle="'客户名称'"
                                  :formPlaceholder="'必填'"
                                  :required="true"
                                  :readonly="isReadOnly"
                                  @chgInputType="chgInputType"
                                  v-model="customerInfo.CustKeyValue"></input-text-placeholder>

          <input-dialog :formTitle="'住宅小区'"
                        :formPlaceholder="'选填'"
                        :readonly="isReadOnly"
                        @showEldilog="showAddress"
                        v-model="customerInfo.VillageKeyValue"></input-dialog>

          <form-input :formTitle="'就读学校'"
                      :formPlaceholder="'选填'"
                      :readonly="isReadOnly"
                      v-model="customerInfo.SchoolName"></form-input>
          <!--  追销开关显示 -->
          <div v-if="vZX17Power">
            <input-dialog :formTitle="'感兴趣的课'"
                          :formPlaceholder="'可选'"
                          :readonly="isReadOnly"
                          @showEldilog="showInteresteCourse"
                          :title="customerInfo.FineSupport"
                          v-model="customerInfo.FineSupport"></input-dialog>

            <form-select :formTitle="'意向级别'"
                         :dataItem="customerInfo.WillingnessRateKeyValue"
                         :dataList="showIntentionSelectorList"
                         :readonly="isReadOnly"
                         v-model="customerInfo.WillingnessRateKeyValue"
                         @choseItem="receiveIntentionItem"></form-select>

            <input-dialog :formTitle="'来源方式'"
                          :formPlaceholder="'可选'"
                          :readonly="isReadOnly"
                          @showEldilog="showSales"
                          v-model="customerInfo.SourceTypeKeyValue"></input-dialog>
          </div>

          <input-dialog :formTitle="'紧急联系人'"
                        :formPlaceholder="'选填'"
                        :readonly="isReadOnly"
                        @showEldilog="showUrgent"
                        v-model="urgentConcact"></input-dialog>

          <input-text :formTitle="'备注'"
                      class="no_border_bottom"
                      :formPlaceholder="'选填'"
                      :readonly="isReadOnly"
                      v-model="customerInfo.RemarksSupport"></input-text>
        </div>
      </div>
      <!-- 完善信息 -->
      <div class="form_extend_btn border_bottom  single_shadow"
           @click.stop="showAllInfo"
           v-show="isShowBtn">
        <div>
          <span class="form_extend_more">完善信息</span>
        </div>
      </div>

      <!-- 提交按钮组 -->
      <div v-if="submitButtonShow=='submit'">
        <save-cancel-btn-group :isSingleSubmit="true"
                               :fixedBtn="true"
                               :singleSubmitType="'dynamic'"
                               @confirmClick="register"
                               @cancelClick="closeRegisterCustomerDialog"></save-cancel-btn-group>
      </div>
      <!-- 查看按钮组 -->
      <div v-else-if="submitButtonShow=='view'">
        <save-cancel-btn-group :isSingleSubmit="true"
                               :fixedBtn="true"
                               :singleSubmitType="'static'"
                               :cancelBtnText="'删除学生'"
                               :btnText="'修改档案'"
                               :ModulePowerKey='48'
                               :cancelBtnColor="'btn_light_red btn_hover_white btn_fixed_red'"
                               @confirmClick="editStudentClick"
                               @cancelClick="deleteStudentClick"></save-cancel-btn-group>
      </div>
    </div>

    <custom-dialog title="紧急联系人"
                   class="customer_dialog defult_dialog"
                   :visible.sync="isShowUrgent"
                   :before-close="closeIsShowUrgent">
      <contactInfo :RelationshipKeyValue="customerInfo.RelationshipKeyValue"
                   :ParentPhoneName="customerInfo.ParentPhoneName"
                   @closeIsShowUrgent="closeIsShowUrgent"
                   @sendItem="receiveUrgentInfo"
                   :customerInfo="customerInfo"
                   :customerRelationship="customerRelationship"></contactInfo>
    </custom-dialog>

    <custom-dialog title="选择感兴趣的课"
                   class="customer_dialog defult_dialog"
                   :visible.sync="showInterested"
                   :before-close="closeshowInterested">

      <btn-add-select :addTitleName="'新增'"
                      :addmoduleName="'course-name-setting'"
                      :addmoduleTitleName="'add'"
                      :addmoduleKey='45'
                      @doAddSuccessEvent="doAddSuccessEvent"></btn-add-select>

      <mutil-select-tree :sourceDate="interestedCourse"
                         :selectionDate="applyCourseDatas"
                         @buttonClickEvent="receiveinterestedItem"
                         @cancleClickForMutilSelected="closeshowInterested"></mutil-select-tree>
    </custom-dialog>

    <!-- 删除学生备注弹出 -->
    <div>
      <textarea-pop :title="'学生：'+customerInfo.MainDemoName"
                    :message="''"
                    :placeholderText="'请输入删除原因...'"
                    ref="textareaPop"
                    @submitStudentArrangeSupport="submitDeleteStudent "></textarea-pop>
    </div>
    <!-- 该用户下已有的学生列表弹出层 -->

    <custom-dialog :width="'300px'"
                   :top="300"
                   :visible.sync="isStudentAfterPopups"
                   :before-close="doCloseClick">
      <user-below-student-dialog ref="studentPopups"
                                 :studentList="studentList"
                                 :popupContent="popupContent"
                                 :isSingleSubmit="true"
                                 :singleSubmitType="popupContent.mark == 'abnormal'?'dynamic':'static'"
                                 @doAddStudentClick="doPopupAddStudent"
                                 @doAmendStudentClick="doPopupAmendStudent"
                                 @doCloseClick="doCloseClick"></user-below-student-dialog>
    </custom-dialog>

  </div>
</template>
<script>
import mutilSelectTree from '../common/mutil-select-tree';
import searchForList from '../common/search-for-list';
import addressItem from '../common/address-item';
import contactInfo from '../common/contact-info';
import textareaPop from '../common/textarea-pop';
import userBelowStudentDialog from '../common/popups-box/user-below-student-dialog/index';
import {
  getLivingCommunity,
  MessageSearchPageForSourceType,
  editCustomerInfo,
  getStudentRelatedMsg,
  RecycleStudent,
  GetStudentByMobile
} from '../../API/workbench';
import formInput from '../form-item/form-input';
import formDialog from '../form-item/form-dialog';
import formSelect from '../form-item/form-select';
import studentGroup from '../common/student-group';

// 登记新生
const newCustomerInfo = {
  OLAPKey: '', // 整型  学生ID
  MainDemoName: '', // 学生名字
  SexKey: '3', // 整型 性别 2:男，3:女
  SexKeyValue: '女', // 学生性别
  NickName: '', // 昵称
  AgeName: '0', // 整型 学生年龄
  BirthdayTime: '',
  CustomerRelationshipKey: '0', // 整形   ,关系2-爸爸;3-妈妈;4-爷爷;5-奶奶;6-外公;7-外婆;8-哥哥;9-姐姐;10-其他;11-本人	;
  CustomerRelationshipKeyValue: '家长', // 客户关系
  FirstLetterName: '', //	姓名首字母
  HeadImgSupport: '', // 头像
  StudentTypeKey: '', // 整型  是否正式  1:正式, 0：临时
  StudentTypeKeyValue: '', // 是否正式 可视化
  CustStatusKey: '', // 整型  是否在校 1:是, 0：否
  IsActiveKeyValue: '', //	是否在校 可视化
  CurrentMaxDayCount: '', // 整型  最大剩余天数
  CurrentClassHourCount: '', // 整形  剩余课时
  RegisterTime: '', // 登记日期
  SchoolName: '', // 学校名字
  FineIdSupport: '', // 整形  兴趣课程ID
  FineSupport: '', // 兴趣课程
  WillingnessRateKey: 3, // 意向级别
  WillingnessRateKeyValue: 'B级', //	意向级别可视化
  AdviserKey: '', // 整型 销售顾问
  AdviserKeyValue: '', // 销售顾问
  ParentName: '', // 紧急联系人
  ParentPhoneName: '', // 紧急联系人电话
  RelationshipKey: '', // 整型  紧急联系人关系.
  RelationshipKeyValue: '', // 紧急联系人关系.
  DebtAmount: '', // 客户欠款
  SumAmount: '', // 客户余额
  MobileSupport: '', // 整型  客户手机
  CustKey: '', // 客户ID
  CustKeyValue: '', // 客户名
  VillageKey: '', // 住宅ID.
  VillageKeyValue: '', // 住宅小区
  CompanyName: '', // 工作单位
  TheRestChild: [], // 其余的孩子.
  IsCompletionKey: 0, // 是否完善资料. 0否1是.
  IsCompletionKeyValue: '否',
  RemarksSupport: '' // 备注
};
export default {
  name: 'registerCustomer',
  data () {
    return {
      isStudentAfterPopups: false,
      customerRelationship: [
        { key: 0, value: '家长' },
        { key: 2, value: '爸爸' },
        { key: 3, value: '妈妈' },
        { key: 11, value: '本人' },
        { key: 10, value: '其他' }
      ],
      sexData: [
        { key: 2, value: '男' },
        { key: 3, value: '女' }
      ],
      isAllInfoShow: false,
      isShowBtn: true,
      customerInfo: {}, // 登记信息
      isChgMobile: false,
      isShowUrgent: false, // 显示紧急联系弹出层.
      showInterested: false, // 显示感兴趣的课弹出层
      interestedCourse: [],
      showIntentionSelectorList: [
        { key: 1, value: 'S 极高' },
        { key: 2, value: 'A 较高' },
        { key: 3, value: 'B 一般' },
        { key: 4, value: 'C 较差' }
      ],
      isInput: false, // 判断客户名称是否已输入.
      applyCourseDatas: { IDs: '', Names: '' },
      studentList: [], // 学生列表
      mobileByStudentList: [], // 修改号码后的用户信息
      isMobileSupport: false, // 验证手机号是否提交
      agePlaceHolder: '选填',   // 绑定年龄组件placeHolder.
      ageclassName: '',

      popupContent: {
        titleContent: '',
        textContent: '',
        studentButName: '', // 显示学生下的合并按钮名称
        submitButName: '', // 提交按钮名称
        mark: 'normal' // 标示==》 normal：正常, abnormal:异常
      }, // 学生弹出层显示内容
      newPhoneNumber: '', // 新的手机号码
      submitButtonShow: 'submit', // 默认显示-提交按钮组
      isReadOnly: false
    };
  },
  components: {
    searchForList,
    addressItem,
    contactInfo,
    formInput,
    formDialog,
    formSelect,
    mutilSelectTree,
    studentGroup,
    textareaPop,
    userBelowStudentDialog
  },
  props: {
    dataInfo: {
      type: [Object, String],
      default: function () {
        return {
          StudentKey: [String, Number],
          Type: String
        };
      }
    }
  },
  created () {
    this.customerInfo = this.$utils.parseJson(newCustomerInfo);
    if (this.dataInfo && Number(this.dataInfo.StudentKey) > 0) {
      console.log(this.dataInfo, '个人资料');
      if (this.dataInfo.Type == 'view') { // 查看
        this.isReadOnly = true;
        this.getStudentDetailInfo(this.dataInfo.StudentKey, this.dataInfo.Type);
      } else { // 修改
        this.getStudentDetailInfo(this.dataInfo.StudentKey);
      }
    }
  },
  mounted () {
    this.$dialog.register(this, [ 'singleSelectionList' ]);
  },
  computed: {
    // 追销开关显示
    vZX17Power () {
      if (this.$store.getters.SaaSClientInfo.SaaSClientSetting && this.$store.getters.SaaSClientInfo.SaaSClientSetting.length > 0) {
        let locaIndex = this.$store.getters.SaaSClientInfo.SaaSClientSetting.findIndex(o => {
          return o.SetTypeKey == 17 && o.SetKey == 1;
        });
        if (locaIndex >= 0) {
          return false;
        } else {
          return true;
        }
      }
    },
    urgentConcact () {
      return (
        (this.customerInfo.RelationshipKeyValue || '') +
        '' +
        (this.customerInfo.ParentPhoneName || '')
      );
    }
  },
  watch: {
    'customerInfo.CustomerRelationshipKeyValue': {
      // 客户名称显示逻辑.
      handler (newName, oldName) {
        if (this.isInput == false) {
          this.customerInfo.CustKeyValue = this.customerInfo.MainDemoName || '' + this.customerInfo.CustomerRelationshipKeyValue || '';
        }
      }
      // immediate: true,
    },
    'customerInfo.MainDemoName': {
      // 客户名称显示逻辑.
      handler (newName, oldName) {
        if (this.isInput == false) {
          this.customerInfo.CustKeyValue = (this.customerInfo.MainDemoName || '') + (this.customerInfo.CustomerRelationshipKeyValue || '');
        }
      }
    },
    'customerInfo.BirthdayTime': {
      handler (newName, oldName) {
        if (!this.customerInfo.BirthdayTime && this.customerInfo.AgeName == 0) {
          this.customerInfo.AgeName = '';
        } else {
          if (this.customerInfo.BirthdayTime) {
            var dd =
              Date.new().getTime() -
              Date.new(this.customerInfo.BirthdayTime).getTime();
            this.customerInfo.AgeName = Math.floor(dd / 1000 / 24 / 3600 / 365);
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    chgInputType (type) {
      this.isInput = type;
    },
    chgBirthdayTime (val) {
      this.customerInfo.AgeName = 0;
      this.customerInfo.BirthdayTime = val ? this.$utils.formatDateToBias(val) : '';
    },

    changeRelationship (val) {
      this.customerInfo.CustomerRelationshipKey = val.key;
      this.customerInfo.CustomerRelationshipKeyValue = val.value;
    },
    changeSexData (val) {
      this.customerInfo.SexKey = val.key;
      this.customerInfo.SexKeyValue = val.value;
    },
    //  打开住宅小区列表
    showAddress () {
      getLivingCommunity().then(result => {
        let addressList = result.data;
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择小区',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.customerInfo.VillageKey, dataSourceList: addressList, required: false, addModuleName: 'community-setting', ModulePowerKey: 45 },
          callBack: {
            onSelectedItem: (data) => {
              this.receiveAddress(data);
            }
          }
        });
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 选择住宅小区
    receiveAddress (item) {
      this.customerInfo.VillageKey = item.OLAPKey;
      this.customerInfo.VillageKeyValue = item.MainDemoName;
    },
    // 客户来源层显示并获取数据
    showSales () {
      // 查询客户来源
      let sourceParam = {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称
        StateKey: 0 // 字符串	可选	0	数据源：GET	状态 2 启用 3停用
      };
      MessageSearchPageForSourceType(sourceParam).then(result => {
        let salesList = result.data.PageDataList.filter(o => {
          return o.StateKey == 2;
        });
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择客户来源',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.customerInfo.SourceTypeKey, dataSourceList: salesList, required: false, addModuleName: 'sell-source-setting', addmoduleTitleName: '新增来源方式', ModulePowerKey: 45 },
          callBack: {
            onSelectedItem: (data) => {
              this.receiveSaler(data);
            }
          }
        });
      }, err => {
        layer.alert(err.msg);
      });
    },
    receiveSaler (item) {
      this.customerInfo.SourceTypeKey = item.OLAPKey;
      this.customerInfo.SourceTypeKeyValue = item.MainDemoName;
    },
    // 显示紧急联系人弹出层
    showUrgent () {
      this.isShowUrgent = true;
    },
    // 关闭紧急联系人弹出层
    closeIsShowUrgent () {
      this.isShowUrgent = false;
    },
    receiveUrgentInfo (val) {
      this.customerInfo.ParentName = val.ParentName;
      this.customerInfo.ParentPhoneName = val.ParentPhoneName;
      this.customerInfo.RelationshipKey = val.RelationshipKey;
      this.customerInfo.RelationshipKeyValue = val.RelationshipKeyValue;
      this.closeIsShowUrgent();
    },
    // 感兴趣的课.
    async showInteresteCourse () {
      let result = await this.$basicData.loadCourseNameList();
      this.interestedCourse = [];
      result.forEach(p => {
        if (p.StateKey == 2) {
          this.interestedCourse.push(p);
        }
      });
      this.interestedCourse.splice(0, 0, {
        OLAPKey: '2',
        MainDemoName: '全部课程',
        TypeKey: '1',
        TypeKeyValue: '课类',
        LevelPath: '0.2.',
        FatherKey: '0',
        FatherKeyValue: '',
        StateKey: '2',
        StateKeyValue: '启用',
        UnitPrice: '0.0'
      });

      this.interestedCourse.forEach(o => {
        this.$set(o, 'isActive', false);
      });
      this.showInterested = true;
    },
    closeshowInterested () {
      this.showInterested = false;
    },
    receiveinterestedItem (val) {
      // 接收选中值.
      this.customerInfo.FineIdSupport = val.selectedIDs.toString().replace(/\,/g, ',');
      this.customerInfo.FineSupport = val.selectedNames.toString().replace(/\,/g, ',');
      this.applyCourseDatas.IDs = this.customerInfo.FineIdSupport;
      this.applyCourseDatas.Names = this.customerInfo.FineSupport;
      this.showInterested = false;
    },
    // 新增回调
    doAddSuccessEvent (result) {
      this.customerInfo.FineIdSupport = result.OLAPKey;
      this.customerInfo.FineSupport = result.MainDemoName;
      this.applyCourseDatas.IDs = this.customerInfo.FineIdSupport;
      this.applyCourseDatas.Names = this.customerInfo.FineSupport;
      this.showInterested = false;
    },
    // 接收选中值.
    receiveIntentionItem (val) {
      this.customerInfo.WillingnessRateKey = val.key;
      this.customerInfo.WillingnessRateKeyValue = val.value;
    },
    // 要追加是否完善资料.
    showAllInfo () {
      this.isAllInfoShow = !this.isAllInfoShow;
      this.isShowBtn = false;
      this.customerInfo.IsCompletionKey = 1;
      this.customerInfo.IsCompletionKeyValue = '是';
      if (!this.customerInfo.CustKeyValue) {
        this.customerInfo.CustKeyValue = this.customerInfo.MainDemoName + '' + this.customerInfo.CustomerRelationshipKeyValue;
      }
    },
    // 关闭
    closeRegisterCustomerDialog () {
      this.customerInfo = this.$utils.parseJson(newCustomerInfo);
      this.studentList = [];
      this.isAllInfoShow = false;
      this.isInput = false;
      this.isShowBtn = true;
      this.ageclassName = '';
      this.agePlaceHolder = '选填';
      this.applyCourseDatas.IDs = '';
      this.applyCourseDatas.Names = '';
      this.isChgMobile = false;
      this.submitButtonShow = 'submit';
      this.isReadOnly = false;
      this.$emit('closeRegisterCustomer');
    },
    // 获取个人信息
    getStudentDetailInfo (OLAPKey, type) {
      if (type == 'view') { // 查看
        this.submitButtonShow = type;
      }
      getStudentRelatedMsg(OLAPKey).then(result => {
        this.showAllInfo();
        this.ageclassName = 'placeholder_color';
        this.agePlaceHolder = '无年龄';
        this.customerInfo = result.data;
        this.newPhoneNumber = this.customerInfo.MobileSupport;
        if (this.submitButtonShow == 'view') {
          this.isChgMobile = true;
        } else {
          if (this.newPhoneNumber) {
            this.isChgMobile = true;
          } else {
            this.isChgMobile = false;
          }
        }
        // 兼容微课堂旧数据value为null时不显示选中性别问题.
        if (!this.customerInfo.SexKeyValue) {
          if (this.customerInfo.SexKey == '2') {
            this.customerInfo.SexKeyValue = '男';
          } else {
            this.customerInfo.SexKeyValue = '女';
          }
        }
        this.applyCourseDatas.IDs = this.customerInfo.FineIdSupport;
        this.applyCourseDatas.Names = this.customerInfo.FineSupport;
        if (!this.customerInfo.CustKeyValue) {
          this.isInput = false;
          this.customerInfo.CustKeyValue = this.customerInfo.MainDemoName + '' +
            this.customerInfo.CustomerRelationshipKeyValue;
        } else {
          this.isInput = true; // 因为watch事件里面有根据isInput改变值.  为false会改变.
        }
        if (!this.customerInfo.FineIdSupport) {
          this.customerInfo.FineIdSupport = '';
          this.applyCourseDatas.IDs = '';
        }
        this.customerInfo.VillageKey = this.customerInfo.VillageKey || 0;
        this.customerInfo.AdviserKeyValue = this.customerInfo.AdviserKeyValue || '';
        this.customerInfo.AdviserKey = this.customerInfo.AdviserKey || 0;
        this.customerInfo.BirthdayTime = this.customerInfo.BirthdayTime || '';
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 保存
    register (callBack) {
      let flag = false;
      // 根据OLAPKey判断新增还是修改.
      if (!this.customerInfo.MainDemoName || !this.customerInfo.MainDemoName.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('请输入学生名字');
        flag = true;
      } else if (this.customerInfo.MainDemoName.length>50) {
        layer.alert('学生名字长度不能超过50个字符');
        flag = true;
      } else if (!this.customerInfo.CustomerRelationshipKeyValue) {
        layer.alert('请选择客户关系');
        flag = true;
      } else if (
        !this.customerInfo.MobileSupport &&
        this.isMobileSupport == false &&
        !this.customerInfo.OLAPKey
      ) {
        flag = true;
        var _this = this;
        layer.confirm('未填写手机号，是否继续提交?', {
          btn: [
            {
              name: '确认',
              callBack: function () {
                layer.close();
                _this.isMobileSupport = true;
                _this.register(callBack);
              }
            }
          ]
        });
      } else if (this.customerInfo.MobileSupport.length != 11 && this.customerInfo.MobileSupport) {
        layer.alert('请输入11位的手机号码');
        flag = true;
      } else if (!this.customerInfo.CustKeyValue || !this.customerInfo.CustKeyValue.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('客户名称不能为空');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      console.log(this.studentList, '保存_学生手机');
      this.customerInfo.AgeName = this.customerInfo.AgeName == '' || this.customerInfo.AgeName == '无年龄' ? 0 : this.customerInfo.AgeName;
      if (this.customerInfo.MainDemoName) {
        this.customerInfo.MainDemoName = this.customerInfo.MainDemoName.replace(/(^\s*)|(\s*$)/g, '');
      }
      if (!this.customerInfo.CustKeyValue) {
        this.customerInfo.CustKeyValue = this.customerInfo.MainDemoName + '' + this.customerInfo.CustomerRelationshipKeyValue;
      }
      // 用户下已有学生时的==》修改模式
      this.customerInfo.IsCompletionKey = 1;
      this.customerInfo.IsCompletionKeyValue = '是';
      if (this.customerInfo.MobileSupport && this.customerInfo.MobileSupport != this.newPhoneNumber && this.studentList.length > 0) {
        this.mobileByStudentList = this.studentList;
        this.popupContent.mark = 'abnormal';// normal：正常, abnormal：异常
        this.popupContent.studentButName = ''; // 显示学生下的合并按钮名称
        this.popupContent.submitButName = '确认'; // 提交按钮名称
        this.popupContent.titleContent = '';
        this.popupContent.textContent = '手机号' + ' (' + this.customerInfo.MobileSupport + ') ' + '的学生';
        this.isStudentAfterPopups = true;
        if (callBack) {
          callBack();
        }
      } else {
        this.editCustomer(callBack);
      }
    },

    // 已有学生时 确认操作
    doPopupAddStudent (callBack) {
      if (this.mobileByStudentList.length > 0 && this.customerInfo.OLAPKey) {
        if (this.popupContent.mark == 'abnormal') {
          editCustomerInfo(this.dataInfo.StudentKey, this.customerInfo).then(result => {
            this.studentList.push(result.data);
            this.popupContent.mark = 'normal';// normal：正常, abnormal：异常
            this.popupContent.studentButName = ''; // 显示学生下的合并按钮名称
            this.popupContent.submitButName = '确认'; // 提交按钮名称
            this.popupContent.titleContent = '手机号变更成功!';
            this.popupContent.textContent = '手机号' + ' (' + this.customerInfo.MobileSupport + ') ' + '的学生';
            this.$emit('doafterRegisterCustomer', this.customerInfo);
            this.$bus.emit('AfterStudentUpdate', result.data.OLAPKey);
          }, error => {
            layer.alert(error.msg);
            this.isStudentAfterPopups = false;
          }).finally(() => {
            if (callBack) {
              console.log('error.msg');
              callBack();
            }
          });
        } else {
          this.isStudentAfterPopups = false;
          this.$nextTick(() => {
            this.$bus.emit('AfterStudentUpdate', this.customerInfo.OLAPKey);
            this.closeRegisterCustomerDialog();
          });
          if (callBack) {
            callBack();
          }
        }
      } else {
        if (this.popupContent.mark == 'normal') {
          this.isStudentAfterPopups = false;
          this.$nextTick(() => {
            this.closeRegisterCustomerDialog();
          });
          if (callBack) {
            callBack();
          }
        }
      }
    },
    // 已有学生时 合并学生
    doPopupAmendStudent (item, callBack) {
      console.log(item, '已有学生时 合并学生');
      if (item.OLAPKey) {
        this.customerInfo.OLAPKey = item.OLAPKey;
        this.customerInfo.CustKey = item.CustKey;
        this.editCustomer(callBack, '合并');
      } else {
        layer.alert('学生ID异常');
      }
    },
    // 取消
    doCloseClick () {
      this.isStudentAfterPopups = false;
      if (this.popupContent.mark == 'normal' && this.popupContent.studentButName == '') {
        this.$bus.emit('AfterStudentUpdate', this.customerInfo.OLAPKey);
        this.closeRegisterCustomerDialog();
      }
    },
    // 登记新生.修改
    editCustomer (callBack, msgVal) {
      this.isStudentAfterPopups = false;
      if (Number(this.customerInfo.OLAPKey) > 0) {
        editCustomerInfo(this.dataInfo.StudentKey, this.customerInfo).then(result => {
          this.closeRegisterCustomerDialog();
          this.$bus.emit('AfterStudentUpdate', result.data.OLAPKey, result.data);
          this.$emit('doafterRegisterCustomer', result.data);
          if (msgVal) {
            layer.alert(msgVal + '成功');
          } else {
            layer.alert('修改成功');
          }
          this.isMobileSupport = false;
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      } else {
        layer.alert('缺少学生ID');
      }
    },
    // 获取手机号下的学生
    doAfterChangeMobileSupport (val) {
      if (this.customerInfo.MobileSupport && JSON.stringify(Number(val)).length == 11) {
        GetStudentByMobile(this.customerInfo.MobileSupport).then(result => {
          if (result.data.length > 0) {
            result.data.forEach(obj => { obj.isActive = false; });
          }
          this.studentList = result.data;
        }, error => {
          layer.alert(error.msg);
        }
        );
      }
    },
    // 切换修改学生模式
    editStudentClick (callBack) {
      if (!this.customerInfo.MobileSupport) {
        this.isChgMobile = false;
      } else {
        this.isChgMobile = true;
      }
      this.isReadOnly = false;
      this.submitButtonShow = 'submit';
      this.$emit('doafterRegisterCustomer');
      if (callBack) {
        callBack();
      }
    },
    // 删除学生操作弹出层
    deleteStudentClick () {
      this.$refs.textareaPop.isAlterMessagePopup = true;
    },
    // 确定删除学生操作
    submitDeleteStudent (messageText, callBack) {
      let flag = false;
      if (!messageText) {
        layer.alert(' 删除原因不能为空');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (this.customerInfo.OLAPKey) {
        RecycleStudent(this.customerInfo.OLAPKey, messageText).then(result => {
          this.$refs.textareaPop.isAlterMessagePopup = false;
          this.$refs.textareaPop.textSupport = '';
          setTimeout(() => {
            this.closeRegisterCustomerDialog();
            this.$bus.emit('AfterStudentDelete', this.customerInfo.OLAPKey);
            this.$emit('AfterStudentDelete');
            layer.alert('删除成功');
          }, 100);
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      }
    }
  }
};
</script>

