<template>
  <div class="table_theader_box">
    <div class="table_theader">
      <header-column v-for="item in vTableColumns"
                     :key="item.prop"
                     :columnInfo="item"></header-column>
    </div>
  </div>
</template>

<script>
import headerColumn from './header-column';
export default {
  data () {
    return {
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 55,
          isShow: true,
          className: 'table_index'
        },
        {
          label: '学生名',
          tips: '一学生多课可重名',
          prop: 'studentName',
          width: 108,
          isShow: true,
          className: 'table_required'
        },
        {
          label: '性别',
          prop: 'sex',
          width: 60,
          isShow: true
        },
        {
          label: '手机号',
          prop: 'phone',
          width: 126,
          isShow: true
        },
        {
          label: '在读课程',
          tips: '必须与系统课名一致',
          prop: 'courseName',
          width: 159,
          isShow: true
        },
        {
          label: '购买日期',
          prop: 'CurrentCount3', // GOTO
          width: 122,
          isShow: true
        },
        {
          label: '失效日期',
          prop: 'ExpireTime',
          width: 122,
          isShow: true
        },
        {
          label: '购买金额',
          prop: 'CurrentCount2', // GOTO
          width: 70,
          isShow: true
        },
        {
          label: '购买课时',
          tips: '别搞错了→ ',
          prop: 'CurrentCount1', // GOTO
          width: 74,
          isShow: true
        },

        {
          label: '剩余课时',
          tips: '此处填剩余',
          prop: 'CurrentCount', // GOTO
          width: 74,
          isShow: true
        },
        // {
        //   label: '价值金额',
        //   prop: 'ValueAmount',
        //   width: 75,
        //   isShow: true
        // },
        {
          label: '系统监测异常提醒',
          prop: 'awaringTip',
          isShow: true,
          warning: '提示-请立即修正'
        },
        {
          label: '操作',
          prop: 'option',
          className: 'tc',
          width: 68,
          isShow: true
        }
      ]
    };
  },
  props: {
    isShowPart: Boolean
  },
  components: {
    headerColumn
  },
  computed: {
    vTableColumns () {
      let columns = [];
      if (this.isShowPart) {
        this.tableColumns.forEach(column => {
          if (column.prop != 'ValueAmount' && column.prop != 'ExpireTime') {
            columns.push(column);
          }
        });
      } else {
        columns = this.tableColumns;
      }
      return columns;
    }
  }

};
</script>

<style>
</style>