<template>
  <div class="up_versions_popup">
    <div @click="hiddenPopup" class="popup_exit"></div>
    <div class="popup_text">客户端发现最新版本！</div>
    <div @click="clickBtn" class="popup_btn btn_hover_bg_blue">立即升级</div>
  </div>
</template>
<script>
export default {
  data(){
    return {

    }
  },
  methods:{
    hiddenPopup(){
      this.$emit('hiddenPopup');
    },
    clickBtn(){
      this.$zxClient.Upgrade();
    }
  }
}
</script>