<template>
  <div>
    <!-- 搜索学生弹框 -->
    <div v-if="searchBoxShow">
      <searchBox :searchInitJson="newScheduleCourseInitJson"
                 @searchClick="chooseClick"
                 @onSelectedStudent="selectedItem"></searchBox>
    </div>
    <div v-if="transferCourseItemShow">
      <transfer-course-item @isSelectStudent="isSelectStudent"
                            @buttonClickEvent="buttonClickEvent"
                            @changeToStudent="changeToStudent"
                            @changeStudent="changeStudent"
                            @CourseIndex="CourseIndex"
                            @showStudentFile="showStudentFile"
                            @alterStudent="selectedItem"
                            :transferStudentItem="transferStudentItem"
                            :isSelectedStudentItem="isSelectedStudentItem"
                            :isTransferStudentItem="isTransferStudentItem"
                            :allStudentCourseList="allStudentCourseList"
                            @selectedCourseItem="selectedCourseItem"
                            @istransferCourseBillHide="istransferCourseBillHide"
                            ref="changeCourse"></transfer-course-item>
    </div>
    <!-- 转出学生列表 (CustStatusKey) 只显示在校学生-->
    <div>
      <custom-dialog title="选择转出学生"
                     class="refund_dialog defult_dialog"
                     :visible.sync="studentListShow"
                     :before-close="isStudentListShowHide">
        <student-list :CustStatusKey="1"
                      :isShowAddBtn="false"
                      :isShowaddOldStudentBtn="true"
                      :singleSelectionDatasource="classList"
                      :isShowSearchInput="isShowSearchInput"
                      @selectedItem="selectedItem"></student-list>
      </custom-dialog>
    </div>
    <!-- 转入学生列表 -->
    <div>
      <custom-dialog title="选择转入学生"
                     class="refund_dialog defult_dialog"
                     :visible.sync="transferStudentListShow"
                     :before-close="closeStudentList">
        <student-list :isShowAddBtn="true"           
                      :isShowSearchInput="isShowSearchInput"
                      @selectedItem="transferSelectedItem"></student-list>
      </custom-dialog>
    </div>
  </div>
  <!-- 转让 课单信息 -->
</template>
<script>
import searchBox from '../../../../components/common/search-box';
import studentList from '../../../../components/common/student-list';
import singleShiftListItem from './single-shift-list-item';
import transferCourseItem from './transfer-course-item';
import {
  getStudentAllCourse,
  StuTransferCourseTicket
} from '../../../../API/workbench.js';
// 转让 课单 transfer
export default {
  name: 'transferCourseBill',
  data () {
    return {
      searchBoxShow: true, // 搜索框
      // 学生选择列表
      studentListShow: false,
      // 转让学生列表
      transferStudentListShow: false,
      transferStudentItem: '',
      // 转让课单信息
      transferCourseItemShow: false,
      // 转让学生目标
      transferStudentGoal: false,
      //  搜索目标
      newScheduleCourseInitJson: { name: '转出学生' },
      // 学生列表弹框
      classList: [],
      allStudentCourseList: [], // 学员 ID获取所有课单
      transferCourseDetailItem: {}, // 转让课单项
      isShowSearchInput: false,
      isSelectedStudentItem: {}, // 转出学生选择列表
      isTransferStudentItem: {}, // 转入学生选择列表
      Remarks: '', // 转让课单接口需要的参数.  ----备注.
      selectedCourseTableID: '',
      vstudentFileItem: null, // 学生课时退款
      changeCourseIndex: 0
    };
  },
  props: ['dataInfo', 'consumeDetail'],
  components: {
    searchBox, // 搜索学生
    studentList, // 学生转让列表
    singleShiftListItem, // 学生转入列表
    transferCourseItem // 转让课单信息
  },
  created () {
    console.log(this.dataInfo, '转让课单');
    if (this.dataInfo && Number(this.dataInfo.courseDetailItem.TableID) > 0 && Number(this.dataInfo.studentInfo.OLAPKey) > 0) {
      this.searchBoxShow = false;
      this.transferCourseDetailItem = this.dataInfo.courseDetailItem;
      this.selectedItem(this.dataInfo.studentInfo);
    }
  },
  watch: {

  },
  methods: {
    show () {
      this.istransferCourseBillShow = true;
    },
    selectedCourseItem (val) {
      // 选中课单时触发更改.
      this.selectedCourseTableID = val.TableID;
    },
    // 关闭弹窗
    istransferCourseBillHide () {
      this.searchBoxShow = false;
      this.transferCourseItemShow = false;
      this.transferStudentListShow = false;
      this.transferStudentItem = ''; // 转让学生
      this.changeCourseIndex = 0; // "清除学员档案所有课单第一次进入的下标"
      this.selectedCourseTableID = '';
      this.isTransferStudentItem = {};
      this.$emit('closeTransferCourseBillDialog');
    },
    // 关闭弹窗
    isStudentListShowHide () {
      this.searchBoxShow = true;
      this.studentListShow = false;
    },
    // 关闭转出学生列表弹框
    closeStudentList () {
      this.transferStudentListShow = false;
      this.studentListShow = false;
    },
    // 点击搜索 搜索框可见 并查询数据
    chooseClick () {
      // this.istransferCourseBillShow = false;
      this.studentListShow = true;
    },
    transferStudentCourse (TableID, StuKey, Reason,tsfNumber,tsfType, callBack) {
      // 转让课单接口.
       var TransferNum = tsfType.id==0?0:tsfNumber
      StuTransferCourseTicket(TableID, StuKey, '', Reason,TransferNum).then(result => {
        this.$emit('updateCourseEvent');
        this.$bus.emit('AfterEditCourseSingle');
        layer.alert('成功转让');
        this.istransferCourseBillHide();
      }).catch(error => {
        layer.alert(error.msg);
      }).finally(() => {
        console.log('transferStudentCourse:finally');
        if (callBack) {
          callBack();
        }
      });
    },
    // 点击确定/取消事件
    buttonClickEvent (Reason,tsfNumber,tsfType ,callBack) {
      let flag = false;
      console.log("buttonClickEvent",tsfNumber,tsfType)
      if (!this.isTransferStudentItem.OLAPKey) {
        layer.alert('请选择转入学生');
        flag = true;
      } else if (!this.selectedCourseTableID) {
        layer.alert('请选择一项课单');
        flag = true;
      } else if (
        this.isSelectedStudentItem.OLAPKey == this.isTransferStudentItem.OLAPKey
      ) {
        layer.alert('不可转让给自己');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.transferStudentCourse(this.selectedCourseTableID, this.isTransferStudentItem.OLAPKey, Reason,tsfNumber,tsfType, callBack);
    },
    // 转让列表(连接)
    isSelectStudent (isShow) {
      this.transferStudentListShow = isShow;
      // this.transferCourseItemShow = false
      this.transferStudentItem = isShow; // 转让学生
    },
    // 转出学生选择项
    selectedItem (item, key) {
      console.log('转出学生1');
      this.selectedCourseTableID = '';
      this.isSelectedStudentItem = item;
      this.searchBoxShow = false;
      this.studentListShow = false;
      this.transferCourseItemShow = true;
      // 课单
      if (item.OLAPKey > 0) {
        getStudentAllCourse(item.OLAPKey).then(result => {
          if (result.data.length > 0) {
          // 过滤.  只保留状态为正常的课单.
            this.allStudentCourseList = [];
            var presentDate = this.$utils.formatDateToLine(Date.new()); // 当天日期
            result.data.forEach(p => {
            // 过滤-课单为正常，课时数和天数大于0，有效期不能小于今天的课单
              if (
              p.StatusKey == 2 &&
              Number(p.PeriodCount) > 0 &&
              Number(p.CurrentCount) > 0 &&
              p.ExpireTime >= presentDate
            ) {
                this.allStudentCourseList.push(p);
              }
            });
            console.log(this.allStudentCourseList, '显示课单');
            if (this.transferCourseDetailItem) {
            // 修改课单-学生档案-监听
              this.allStudentCourseList.forEach(o => {
                this.$set(o, 'isActive', false); // 深度监听.高亮效果.
              });
              this.ShowstudentFileItem = this.transferCourseDetailItem;
              if (this.changeCourseIndex !== 0) {
                this.allStudentCourseList[0].isActive = true; // 触发高亮事件.
                this.$refs.changeCourse.selectedItem(this.allStudentCourseList[0]); // 这是为了触发子组件的渲染事件.
                this.selectedCourseTableID = this.allStudentCourseList[0].TableID;
              } else {
                let locaIndex = this.allStudentCourseList.findIndex(o => {
                  return o.TableID == this.transferCourseDetailItem.TableID;
                });
                console.log(locaIndex, '显示课单ID');
                if (locaIndex >= 0) {
                  this.allStudentCourseList[locaIndex].isActive = true; // 触发高亮事件.
                  this.$refs.changeCourse.selectedItem(this.allStudentCourseList[locaIndex]); // 这是为了触发子组件的渲染事件.
                  this.selectedCourseTableID = this.allStudentCourseList[locaIndex].TableID;
                }
              }
            } else {
              if (this.allStudentCourseList.length > 0) {
                this.allStudentCourseList.forEach(o => {
                  this.$set(o, 'isActive', false);
                });
                this.allStudentCourseList[0].isActive = true;
                this.selectedCourseTableID = this.allStudentCourseList[0].TableID; // 初始化时设定选中第一个课单的TableID
              }
            }
            if (!key) {
              this.$refs.changeCourse.searchBoxShow = true; // 处理再次点击学生时清空选中班级. 及显示正确的显示层.
              this.$refs.changeCourse.selectedStudentInfoShow = false;
              this.isTransferStudentItem = {};
              this.$nextTick(() => {
                this.$refs.changeCourse.focusInput(); // 触发焦点事件.
              });
            }
          } else {
            this.allStudentCourseList = [];
          }
        });
      }
    },
    // 选中课单下标
    CourseIndex (index) {
      this.changeCourseIndex = index;
    },
    // 转入学生选择项
    transferSelectedItem (item) {
      console.log('转入学生选择项', item);
      this.isTransferStudentItem = item;
      this.transferStudentListShow = false;
      this.transferCourseItemShow = true;
      // 学员 ID获取所有课单.
    },
    // 显示学员档案.
    showStudentFile (val) {
      this.$emit('showStudentFile', val);
    },
    // 切换转出学生
    changeStudent () {
      // console.log("555")
      this.studentListShow = true;
      // this.transferCourseItemShow = false
    },
    // 切换转入学生
    changeToStudent () {
      this.studentListShow = false;
      this.transferStudentListShow = true;
    }
  },
  computed: {}
};
</script>

