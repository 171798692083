<template>
  <div class="modulebox">
    <div class="titlebox clearfix">
      <div class="tit">排课与课消汇总</div>
      <single-year-picker :type="'month'"
                          @chgDate="chgDate"></single-year-picker>
    </div>
    <div class="conbox clearfix">
      <div class="chart">
        <div class="block">
          <div class="name">排课课次</div>
          <div class="rate rate_3"
               :style="'width:100%'"></div>
          <div class="data">{{TheLessonCount}}课次</div>
        </div>
        <div class="block">
          <div class="name">排课人次</div>
          <div class="rate rate_1"
               :style="'width:100%'"></div>
          <div class="data">{{CourseArrangeCount}}人次</div>
        </div>
        <div class="block">
          <div class="name">出勤人次</div>
          <div class="rate rate_2"
               :style="'width:'+Attence+'%'"></div>
          <div class="data">{{Attence3Count}}人次<span>　|　出勤率 {{Attence}}%</span></div>
        </div>
      </div>
      <div class="count count_1">
        <div class="name">月课消/金额</div>
        <div class="data">{{this.setMoneyformatToParseInt(employCount)}}课时</div>
        <div class="data">{{this.setMoneyformatToParseInt(employAmount)}}元</div>
      </div>
      <div class="count count_2">
        <div class="name">课均课消/金额</div>
        <div class="data">{{this.setMoneyformatToParseInt(ClassHourAverageCount)}}课时</div>
        <div class="data">{{this.setMoneyformatToParseInt(ClassHourAverageAmount)}}元</div>
      </div>
      <div class="count count_3">
        <div class="name">课均出勤人数</div>
        <div class="data">{{CourseAverageAmount}}人</div>
      </div>
      <div class="count count_4">
        <div class="name">生均月课消/金额</div>
        <div class="data">{{this.setMoneyformatToParseInt(StudentAverageCount)}}课时</div>
        <div class="data">{{this.setMoneyformatToParseInt(StudentAverageAmount)}}元</div>
      </div>
    </div>
    <div class="tipbox"><span>*</span>为避免引流课消金额过低污染数据，指标误导。以上指标均采用正课数据。</div>
  </div>
</template>

<script>
import { GetMonthlySummaryData } from '../../../API/workbench';
import singleYearPicker from '../../base-module/date-picker-common/single-year-picker';
export default {
  data () {
    return {
      dateStart: '',
      dateEnd: '',
      CourseArrangeCount: 0,
      Attence: 0,
      Attence3Count: 0,
      employAmount: 0,
      ClassHourAverageCount: 0,
      ClassHourAverageAmount: 0,
      StudentAverageCount: 0,
      CourseAverageAmount: 0,
      StudentAverageAmount: 0,
      employCount: 0,
      employAmount: 0,
      TheLessonCount: 0
    };
  },
  components: {
    singleYearPicker
  },
  created () {
  },
  mounted () {
    this.dateStart = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
    this.dateEnd = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd');
    this.getData();
  },
  methods: {
    setMoneyformatToParseInt (num) {
      // 将数字转换为字符串并按照小数点进行拆分
      var parts = num.toString().split('.');
      // 获取整数部分
      var integerPart = parts[0];
      // 获取小数部分
      var decimalPart = parts.length > 1 ? '.' + parts[1] : '';

      return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + decimalPart;
    },
    chgDate (val, startTime, endTime) {
      this.dateStart = startTime.split('/').join('-');
      this.dateEnd = endTime.split('/').join('-');
      this.getData();
    },
    getData () {
      let that = this;
      GetMonthlySummaryData(this.dateStart, this.dateEnd).then(result => {
        if (result.code == 0) {
          that.CourseArrangeCount = result.data.CourseArrangeCount;
          that.Attence = result.data.Attence;
          that.Attence3Count = result.data.Attence3Count;
          that.employAmount = result.data.employAmount;
          that.ClassHourAverageCount = result.data.ClassHourAverageCount;
          that.StudentAverageCount = result.data.StudentAverageCount;
          that.ClassHourAverageAmount = result.data.ClassHourAverageAmount;
          that.CourseAverageAmount = result.data.CourseAverageAmount;
          that.TheLessonCount = result.data.TheLessonCount;
          that.StudentAverageAmount = result.data.StudentAverageAmount;
          that.employCount = result.data.employCount;
          that.employAmount = result.data.employAmount;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
};
</script>

<style scoped>
.titlebox .tit {
  float: left;
  height: 30px;
  line-height: 26px;
  font-size: 20px;
  font-weight: bold;
}
.titlebox .title_date {
  position: relative;
  width: 100px;
  margin-left: 20px;
}
.titlebox .title_date:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #ccc;
  right: 6px;
  top: 14px;
}
.titlebox .title_date >>> .el-input__inner {
  padding: 0 10px;
  cursor: pointer;
}
.conbox {
  margin-top: 30px;
}
.conbox .chart {
  float: left;
  width: 18%;
  height: 100px;
  margin-right: 2%;
}
.conbox .chart .block {
  position: relative;
  padding-left: 60px;
  padding-top: 5px;
}
.conbox .chart .block .name {
  position: absolute;
  width: 60px;
  line-height: 20px;
  font-size: 12px;
  color: #999999;
  left: 0;
  top: 0;
}
.conbox .chart .block .rate {
  height: 10px;
  margin-bottom: 5px;
}
.conbox .chart .block .rate.rate_1 {
  background: linear-gradient(90deg, #a9d0ff 0%, #0075ff 100%);
}
.conbox .chart .block .rate.rate_2 {
  background: linear-gradient(90deg, #ffe49b 0%, #ffbb00 100%);
}
.conbox .chart .block .rate.rate_3 {
  background: linear-gradient(90deg, #c0f8e3, #12cd88 80.24%);
}
.conbox .chart .block .data {
  line-height: 20px;
  font-size: 12px;
}
.conbox .chart .block .data span {
  color: #ffbb00;
}
.conbox .count {
  float: left;
  width: 18%;
  height: 120px;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 15px 20px;
  margin-left: 2%;
}
.conbox .count.count_1 {
  background-color: #eff1f6;
}
.conbox .count.count_2 {
  background-color: #e4edff;
}
.conbox .count.count_3 {
  background-color: #e2f6ff;
}
.conbox .count.count_4 {
  background-color: #fdf3e9;
}
.conbox .count .name {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #666666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}
.conbox .count .data {
  height: 35px;
  line-height: 35px;
  font-size: 20px;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.conbox .count .data span {
  font-size: 30px;
}
.tipbox {
  line-height: 20px;
  font-size: 12px;
  color: #999999;
  margin-left: 22%;
  margin-top: 10px;
}
.tipbox span {
  color: #ff6c67;
  margin-right: 10px;
}
</style>