<template>
  <div class="index_view_menu_three_content flex">
    <div
      class="index_view_menu index_view_menu_three box_withe_radius pr"
      v-for="(item, key) in vmenuList"
      :key="key"
    >
      <div class="index_title pr">
        {{ item.name }}
      </div>
      <div class="chain_workbench_file_new pr menu_content">
        <div
          class="menu_content_list pr" style="padding: 0px;"
          v-for="(listItem, key) in item.list"
          :key="key"
          @click.stop="verifyAuthGoRouter(listItem)"
        >
          <div
            class="menu_content_list_num"
            style="margin-top: px"
            :class="listItem.num == '***' ? 'font_gray' : ''"
          >
            {{ listItem.num }}
          </div>
          <div class="menu_content_list_title" style="margin-top: 10px">
            {{ listItem.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetChainaaSClientScaleStatistic } from '../../../API/workbench';
export default {
  data () {
    return {
      menuList: [
        {
          name: '连锁门店管理',
          value: '连锁门店管理',
          num: 0,
          keyvalue: 2,
          ModulePowerKey: 0,
          list: [
            {
              name: '直营店',
              value: '新增待分配',
              num: 0,
              routerName: 'retailManagement',
              routertitle: '直营管理',
              auth: true,
              roleName: '',
              ModulePowerKey: 105,
              moduleName: '',
              dataSet1: this.vFindDirectly,
              dataSet2: this.vFindParticipation
            },
            {
              name: '加盟店',
              value: '跟进中',
              num: 0,
              routerName: 'franchiseeManagement',
              routertitle: '加盟管理',
              auth: true,
              roleName: '',
              ModulePowerKey: 106,
              moduleName: '',
              dataSet1: this.vFindBrand,
              dataSet2: this.vFindCourseware
            }
          ]
        },
        {
          name: '课件管理',
          value: '跟进中',
          num: 0,
          keyvalue: 2,
          ModulePowerKey: 2,
          list: [
            {
              name: '课件加密上传',
              value: '新增待分配',
              num: 0,
              routerName: 'hqCoursewareManagement',
              routertitle: '课件管理',
              auth: true,
              roleName: '',
              ModulePowerKey: '107',
              moduleName: '',
              dataKey: '',
              dataSet1: '',
              dataSet2: ''
            },
            {
              name: '授权课件管理',
              value: '跟进中',
              num: 0,
              routerName: 'authorizationCourseware',
              routertitle: '授权课件管理',
              auth: true,
              roleName: '',
              ModulePowerKey: 108,
              moduleName: '',
              dataSet1: '',
              dataSet2: ''
            }
          ]
        },
        {
          name: '督学与教学成果',
          value: '跟进中',
          num: 0,
          keyvalue: 2,
          ModulePowerKey: 2,
          list: [
            {
              name: '老师备课检查',
              value: '新增待分配',
              num: 0,
              routerName: 'campusPrepareLessonsExamine',
              routertitle: '备课检查',
              auth: true,
              roleName: '',
              ModulePowerKey: 0,
              moduleName: '',
              dataSet1: '',
              dataSet2: ''
            },
            {
              name: '校区督学统计',
              value: '跟进中',
              num: 0,
              routerName: 'schoolEduReport',
              routertitle: '校区督学统计',
              auth: true,
              roleName: '',
              ModulePowerKey: 0,
              moduleName: 'schoolEduReport',
              dataSet1: '',
              dataSet2: ''
            }
          ]
        }
      ],
      chainInfo: {
        RetailStoresCount: 0,
        LeagueBrandCount: 0,
        CoursewarePackageCount: 0,
        AuthorizeCoursewareCount: 0,
        PrincipalSchoolNum: 0,
        PrepareLessonsToCheckNum: 0
      } // 连锁门店规模统计
    };
  },
  props: {
    menDianSetInfo: Array
  },
  created () {
    // this.getDataInfo();
  },
  components: {},
  computed: {
    vmenuList () {
      this.menuList[0].list[0].num = this.vRetailStoresCount;
      this.menuList[0].list[1].num = this.vLeagueBrandCount;
      this.menuList[1].list[0].num = this.vCoursewarePackageCount;
      this.menuList[1].list[1].num = this.vAuthorizeCoursewareCount;
      this.menuList[2].list[0].num = Number(
        this.chainInfo.PrepareLessonsToCheckNum
      );
      this.menuList[2].list[1].num = Number(this.chainInfo.PrincipalSchoolNum);
      return this.menuList;
    },
    // 找到直属加盟设置
    vFindDirectly () {
      return this.findSetInfoOpenType(6);
    },
    // 找到参股加盟设置
    vFindParticipation () {
      return this.findSetInfoOpenType(7);
    },
    // 找到课件加盟设置
    vFindCourseware () {
      return this.findSetInfoOpenType(4);
    },
    // 找到整店加盟设置
    vFindBrand () {
      return this.findSetInfoOpenType(5);
    },
    // 整店加盟费
    vLeagueBrandAmount () {
      return this.setMoney(
        this.realTimesInfo.LeagueBrandAmount,
        this.vFindBrand,
        '',
        106
      );
    },
    // 课件加盟费
    vLeagueCoursewareAmount () {
      return this.setMoney(
        this.realTimesInfo.LeagueCoursewareAmount,
        this.vFindCourseware,
        '',
        106
      );
    },
    // 直营报名
    vRetailStoresApplyAmount () {
      return this.setMoney(
        this.realTimesInfo.RetailStoresApplyAmount,
        this.vFindDirectly,
        this.vFindParticipation,
        105
      );
    },
    // 直营课消
    vRetailStoresClassAmount () {
      return this.setMoney(
        this.realTimesInfo.RetailStoresClassAmount,
        this.vFindDirectly,
        this.vFindParticipation,
        105
      );
    },
    // 直营出勤
    setAttendRate () {
      if (this.isHasModulePower(105)) {
        if (
          (this.vFindDirectly && this.vFindDirectly.SetKey == 1) ||
          (this.vFindParticipation && this.vFindParticipation.SetKey == 1)
        ) {
          return (
            Math.round(
              Number(this.realTimesInfo.RetailStoresAttendRate) * 100
            ) + '%'
          );
        } else {
          return '**';
        }
      } else {
        return '**';
      }
    },
    // 直营店管理套数
    vRetailStoresCount () {
      return this.setCount(
        this.chainInfo.RetailStoresCount,
        this.vFindDirectly,
        this.vFindParticipation,
        105
      );
    },
    // 加盟店管理套数
    vLeagueBrandCount () {
      return this.setCount(
        this.chainInfo.LeagueBrandCount,
        this.vFindBrand,
        this.vFindCourseware,
        106
      );
    },
    // 课件加盟套数
    vCoursewarePackageCount () {
      return this.setCount(this.chainInfo.CoursewarePackageCount, '', '', 107);
    },
    // 课件授权套数
    vAuthorizeCoursewareCount () {
      return this.setCount(
        this.chainInfo.AuthorizeCoursewareCount,
        '',
        '',
        108
      );
    },
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    },
    vMendianInfo () {
      return this.$store.getters.SaaSClientInfo;
    }
  },
  methods: {
    isHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vRolePowerList, ModulePowerKey);
    },
    // 找到门店设置中的指定项内容
    findSetInfoOpenType (type) {
      console.log(this.menDianSetInfo, 'findSetInfoOpenType');
      let locaItem = this.menDianSetInfo.find((o) => {
        return o.SetTypeKey == type;
      });
      return locaItem;
    },
    // 设置金额
    setMoney (num, dataSet, dataSet2, ModulePowerKey) {
      num = Number(num) || 0;
      console.log('dataSet', dataSet);
      if (dataSet || dataSet2) {
        if (
          this.isHasModulePower(ModulePowerKey) &&
          (dataSet.SetKey == 1 || dataSet2.SetKey == 1)
        ) {
          return this.$utils.setSixMoneyToWan(num);
        } else {
          if (num > 99999.99) {
            return '***';
          } else {
            return '***';
          }
        }
      } else {
        if (this.isHasModulePower(ModulePowerKey)) {
          return this.$utils.setSixMoneyToWan(num);
        } else {
          if (num > 99999.99) {
            return '***';
          } else {
            return '***';
          }
        }
      }
    },
    // 设置次数
    setCount (num, dataSet, dataSet2, ModulePowerKey) {
      num = Number(num) || 0;
      console.log('dataSet', dataSet, dataSet2);
      if (dataSet || dataSet2) {
        if (
          this.isHasModulePower(ModulePowerKey) &&
          (dataSet.SetKey == 1 || dataSet2.SetKey == 1)
        ) {
          return num;
        } else {
          return '***';
        }
      } else {
        if (this.isHasModulePower(ModulePowerKey)) {
          return num;
        } else {
          return '***';
        }
      }
    },
    // 获取连锁门店规模统计
    getDataInfo () {
      GetChainaaSClientScaleStatistic()
        .then((result) => {
          this.chainInfo = result.data;
          console.log('getChainaaStatistic', result.data);
        })
        .catch((error) => {
          console.log('errorgetChainaaStatistic', error);
        });
    },
    verifyAuthGoRouter (item) {
      console.log('verifyAuthGoRouter', item.dataSet1, item.dataSet2);
      if (item.dataSet1 || item.dataSet2 || item.ModulePowerKey > 0) {
        if (item.dataSet1 && item.dataSet1.SetKey != 1) {
          layer.alert('请开启相应开关设置');
        } else if (item.dataSet2 && item.dataSet2.SetKey != 1) {
          layer.alert('请开启相应开关设置');
        } else if (!this.isHasModulePower(item.ModulePowerKey)) {
          console.log('!!!isHasModulePower');
          this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
        } else {
          console.log('!!!isHasModulePower');
          this.goRouter(item, item.num);
        }
      } else {
        this.goRouter(item, item.num);
      }
    },
    goRouter (item, num) {
      console.log(item, 'goRouter');
      if (item.routerName == 'hqCoursewareManagement') {
        item.dataKey = 0;
      }
      if (this.vMendianInfo.IsOpenChainKey != 1) {
        layer.alert('未开通连锁功能，请联系您的专属客服');
        return false;
      }
      // if (num >= 0) {
      this.$bus.emit('needOpenRouter', item);
      // }
    },
    // 打开弹出层
    openCustomDialogByFactoryDialog (item) {
      this.$emit('openCustomDialogByFactoryDialog', item);
    }
  }
};
</script>