<template>
  <div class="course_statistics_echarts">
    <div class="course_statistics_title">
      <div class="title_date">
        <span>选择年份</span>
        <div class="title_date_select">
          <div class="el-select">
            <div class="el-input el-input--small el-input--suffix is-focus">
              <single-year-picker :minDate="vMinDate"
                                  :maxDate="vMaxDate"
                                  :isShowHistory="isShowHistory"
                                  ref="monthPicker"
                                  @chgDate="chgDate"></single-year-picker>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="change_history_data"
           @click.stop="historyBtn(historyName)">{{ historyName }}</div> -->
      <div class="title_date_select"
           style="width: 162px;">
        <el-select v-model="searchObj.TeacherKey"
                   placeholder="请选择"
                   size="small"
                   @change="chgSelect">
          <el-option v-for="item in teacherList"
                     :key="item.OLAPKey"
                     :label="item.MainDemoName"
                     :value="item.OLAPKey">
          </el-option>
        </el-select>
        <!-- <div class="el-select">
          <div class="el-input el-input--small el-input--suffix is-focus">
            <input type="text"
                   readonly="readonly"
                   autocomplete="off"
                   placeholder="请选择"
                   class="el-input__inner">
          </div>
        </div> -->
      </div>
    </div>
    <div class="course_statistics_echarts_box">
      <new-type-echart :echartID="'dual_histogram3'"
                       :reqList="monthList"
                       :nowDataList="nowList"
                       :preDataList="oldList"
                       :monthTitle="'月'"
                       :secondTitle="'元'"
                       :echartType="'Bar'"
                       ref="echart"></new-type-echart>
    </div>
  </div>
</template>
<script>
import singleYearPicker from '../../base-module/date-picker-common/single-year-picker';
import newTypeEchart from '../../base-module/echart-common/new-type-echart';
import { getTeacherPerformanceForMonth, geTteacherList } from '../../../API/workbench';
export default {
  data () {
    return {
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      seletedDate: Date.new(Date.new().setDate(1)),
      searchObj: {
        startTime: '',
        endTime: '',
        TeacherKey: 0
      },
      nowList: [],
      nowDataList: [],
      oldList: [],
      teacherList: [],
      yearTotal: 0,
      monthList: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    };
  },
  props: {
    IsMTeaKey: {
      type: Number,
      default: 1
    }
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    }
  },
  components: {
    newTypeEchart, singleYearPicker
  },
  created () {
    this.getTeacherList();
  },
  mounted () {
    this.searchObj.IsMTeaKey = this.IsMTeaKey;
    this.searchObj.startTime = Date.new().getFullYear() + '/01/01';
    this.searchObj.endTime = Date.new().getFullYear() + '/12/31';
    this.getReportList();
  },
  methods: {
    historyBtn (name) {
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.searchObj.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.searchObj.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isShowHistory = true;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0));
        this.isShowHistory = false;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.getReportList();
      this.$refs.monthPicker.changeHistoryTime(this.searchObj.endTime);
    },
    chgDate (val, startTime, endTime) {
      this.seletedDate = val;
      this.searchObj.startTime = startTime;
      this.searchObj.endTime = endTime;
      this.getReportList();

      // this.getNowKQDataByDay(this.startTime, this.endTime, this.getLastKQDataByDay);
    },

    chgSelect (val) {
      console.log(val, 'chgSelect');
      this.searchObj.TeacherKey = val;
      this.getReportList();
    },
    getTeacherList () {
      geTteacherList().then(result => {
        this.teacherList = result.data;
        this.teacherList.unshift({
          OLAPKey: 0,
          MainDemoName: '全部老师'
        });
      }).catch(error => {
        console.log(error, 'error');
      });
    },
    getReportList (callback) {
      getTeacherPerformanceForMonth(this.searchObj).then(result => {
        console.log(result.data, 'MessageSearchClassAwayByYear');
        this.nowDataList = result.data;
        this.nowList = this.getNowList();
        // this.yearTotal = Number(result.data.totalDeductAmount);
        if (callback) {
          callback();
        }
        this.$nextTick(() => {
          this.$refs.echart.echartInit();
        });
      }).catch(error => {
        if (callback) {
          callback();
        }
        console.log(error, 'errMessageSearchClassAwayByYear');
      });
    },
    // 获取选中年数据 因为数据呈现为12个月. 所以如果返回数据不符合该月份的话直接push '0'
    getNowList () {
      let nowList = [];
      // let monthList = this.getDaysOfMonth(this.seletedDate);
      this.monthList.forEach(p => {
        let item = this.nowDataList.find(o => {
          return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
        });
        if (item) {
          nowList.push(Number(item.ClassAwayAmountCount).toFixed(0));
        } else {
          nowList.push('0');
        }
      });
      return nowList;
    }
  }
};
</script>
<style scoped>
.change_history_data {
  position: absolute;
  margin-top: -64px;
  margin-left: 258px;
  line-height: 24px;
  width: 120px;
  height: 24px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
</style>