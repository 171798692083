<template>
  <div>
    <div class="form_info_edit form_info_list">
      <input-select :formTitle="'延期方式'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :readonly="!isReadonly"
                    :dataList="vSourceTypeInfoList"
                    :selectedInfo="selectedChoose"
                    @selectedItem="changeSourceType"></input-select>
      <input-number v-if='selectedChoose.type=="1"'
                    :min="0"
                    :value="courseDay"
                    :decimalPlaces="1"
                    :formTitle="'输入天数'"
                    :placeholder="'选填'"
                    :readonly="false"
                    :required="true"
                    @inputChange="afterChangeCurrentCount"></input-number>

      <div class="form_data form_info_line"
           v-if='selectedChoose.type=="2"'>
        <div class="form_info_field">
          <span class="required">到期日期</span>
        </div>
        <div class="form_info_value form_info_select">
          <el-date-picker type="date"
                          v-model="courseTime"
                          :picker-options="expireTimeOption"
                          placeholder="可选"></el-date-picker>
        </div>
      </div>
      <input-text v-if='selectedChoose.type=="2"||selectedChoose.type=="1"'
                  :formTitle="'备注'"
                  :required="false"
                  :readonly="!isReadonly"
                  :formPlaceholder="'请输入'"
                  v-model="Remarks"></input-text>
    </div>

    <div v-if="isReadonly">
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
      <!-- <save-cancel-btn-group :cancelBtnText="'取消'"
        :isSingleSubmit="true"
        :fixedBtn="true"
        @confirmClick="submit"
        @cancelClick="cancel"></save-cancel-btn-group> -->
    </div>
  </div>
</template> 

<script>
import {
  messageSearchPageForStudent,
  EditArrangeSupport
} from '../../../API/workbench.js';
export default {
  data () {
    return {
      courseTime: '',
      courseDay: 0,
      Remarks: '',
      selectedChoose: { type: '0', name: '请选择' },
      vSourceTypeInfoList: [
        { type: '1', name: '指定天数' },
        { type: '2', name: '指定日期' }
      ],
      expireTimeOption: {
        disabledDate (date) {
          return date.getTime() + 86400000 <= Date.now();
        }
      }
    };
  },
  props: {
    isReadonly: { type: Boolean, default: false },
    dataInfo: { type: Array, default: [] }
  },
  components: {
    messageSearchPageForStudent,
    EditArrangeSupport
  },
  created () {

  },
  mounted () {
    console.log(this.dataInfo, '弹窗信息', this.isReadonly);
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
    }, false);
  },
  computed: {},

  methods: {

    afterChangeCurrentCount (val) {
      if (!val) {
        val = 0;
      }
      this.courseDay = val;
    },
    changeSourceType (obj) {
      console.log(obj, '选择延期方式');
      this.selectedChoose.type = obj.type;
      this.selectedChoose.name = obj.name;
    },

    submit (callback) {
      var data = [];
      data.DelayTypeKey = this.selectedChoose.type;
      if (this.selectedChoose.type == '1') {
        data.DayNum = this.courseDay;
      } else if (this.selectedChoose.type == '2') {
        if (this.courseTime == '') {
          layer.alert('请选择到期日期');

          return;
        } else {
          data.ExpireTime = this.$utils.formatDateToLine(this.courseTime);
        }
      }
      data.Remarks = this.Remarks;
      this.$emit('submit', data);
      this.$emit('closeDialog');
    },
    cancel () {
      this.$emit('closeDialog');
    }
  }
};
</script>
