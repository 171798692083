<template>
  <!--ShowTypeKey-3:单选 -->
  <div class="form_list_content"
       :style="vFieldsWidth" v-if="item.isOpt">
    <div class="form_list_name">
      <div class="text_box font_black" :class="{opt:item.isOpt}" @click="singleClick">
        {{item.FieldsThat}}
      </div>
    </div>
  </div>
</template> 
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    categoryItem: Object, // 类目项
    item: Object, // 字段项
    rowWidth: Number, // 总行宽
    keyIndex: Number, // 当前下标
    dataLength: Number, // 数组长度
  },
  created () {
    // console.log('单选:', this.categoryItem, this.FieldsItem);
  },
  computed: {
    vFieldsWidth () {
      let FWh = 0;
      if (Number(this.item.Length) > FWh) {
        FWh = Number(this.rowWidth) / Number(this.item.Length);
        return 'width:' + FWh + 'px';
      } else {
        return '';
      }
    }
  },
  methods: {
    singleClick () {
      // this.item.isOpt = true;
      // this.item.DefaultValue = 1;
      this.categoryItem.FormFields.forEach(o => {
        if (o.OLAPKey == this.item.OLAPKey) {
          o.isOpt = true;
          o.DefaultValue = 1;
        } else {
          o.isOpt = false;
          o.DefaultValue = 0;
        }
      });
    }
  }
};
</script>

<style>
</style>