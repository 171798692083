<template>
	<div class="homerwork_details_student_ul">
		<item v-for="(item,key) in workStudentList" :key="key" :item="item"></item>
		<div v-if="workStudentList.length == 0">
			<basics-nodata-tips></basics-nodata-tips>
		</div>
	</div>
</template>

<script>
import item from './item.vue';
export default {
  components: {
	  item
  },
  data () {
    return {

    };
  },
  props: {
    workStudentList: Array
  },
  methods: {

  }
};
</script>

<style>

</style>
