<template>
  <div class="summarizing_list">
    <table-list
      :tableData="vTableInfoList"
      :tableColumns="tableColumns"
      :totalNum="totalNum"
      :queryParams="params"
      :isShowCheckBox="false"
      @loadTableData="getDataInfo"
    ></table-list>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
import { SearchPageYKRecommendForFollowUp } from '../../../../API/workbench.js';
export default {
  data () {
    return {
      isShowMessagePopup: false,
      selectInfo: false,
      dataInfoList: [],
      totalNum: 0,
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          type: 'table-index',
          className: 'table_index_gray',
          sortable: false,
          width: 48
        },
        {
          label: '推荐日期',
          prop: 'RecommendTime',
          width: 100,
          type: 'date-item',
          sortable: 'custom'
        },
        {
          label: '投资人',
          prop: 'MainDemoName',
          type: 'text-item',
          width: 91
        },
        {
          label: '手机号',
          prop: 'MobilePhone',
          type: 'text-item',
          width: 120
        },
        {
          label: '计划城市',
          prop: 'CityKeyValue',
          type: 'text-item',
          width: 110
        },
        {
          label: '加盟方式',
          prop: 'FranchiseTypeKeyValue',
          type: 'text-item',
          width: 100
        },
        {
          label: '投资预算',
          prop: 'BudgetAmount',
          type: 'text-item',
          width: 88
        },
        {
          label: '签单进度',
          prop: 'StatusKeyValue',
          type: 'text-item',
          width: 84,
          extend: {
            setClassName: (rowData) => {
              return this.setFeedbackStyle(rowData);
            }
          }
        },
        {
          label: '跟进记录',
          prop: 'aaa',
          type: 'text-dual-item',
          width: 357,
          extend: {
            textOne: 'FollowTime',
            classNameOne: 'font_gray',
            textTwo: 'Content',
            classNameTwo: ''
          }
        },
        {
          label: '顾问',
          prop: 'ReceiveKeyValue',
          type: 'text-item',
          width: 80
        },
        {
          label: '操作人',
          prop: 'ddd',
          width: 102,
          type: 'text-dual-item',
          extend: {
            textOne: 'FollowKeyValue',
            classNameOne: 'block',
            textTwo: 'ReceiveTime',
            classNameTwo: 'font_gray block'
          }
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          className: '',
          width: 68,
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  click: (rowData) => {
                    this.openDetails(rowData);
                  }
                }
              }
            ]
          }
        }
      ]
    };
  },
  props: {
    params: Object
  },
  components: {
    tableList
  },
  computed: {
    vTableInfoList () {
      let arr = [];
      this.dataInfoList.forEach((e) => {
        e.BudgetAmount = Number(e.StratBudgetAmount) + '-' + Number(e.EndBudgetAmount) + '万';
        e.CityKeyValue = e.ProvinceKeyValue + '·' + e.CityKeyValue;
        if (e.ReceiveTime) {
          e.ReceiveTime = e.ReceiveTime.split(' ')[0];
        }
        if (e.FollowTime) {
          e.FollowTime = this.$utils.StrForTodoTime(e.FollowTime) + ':';
        }
        arr.push(e);
      });
      return arr;
    }
  },
  created () {
    this.getDataInfo();
  },
  methods: {
    setFeedbackStyle (item) {
      let text = 'feed_back';
      switch (Number(item.StatusKey)) {
        case 1:
          text = 'type_yellow feed_back';
          break;
        case 2:
          text = 'type_yellow feed_back';
          break;
        case 3:
          text = 'type_yellow feed_back';
          break;
        case 4:
          text = 'type_yellow feed_back';
          break;
        case 5:
          text = 'type_green feed_back';
          break;
        case 6:
          text = 'type_green feed_back';
          break;
        case 7:
          text = 'type_green_blod feed_back';
          break;
        case 8:
          text = 'type_red feed_back';
          break;
        default:
          text = 'type_yellow feed_back';
          break;
      }
      return text;
    },
    openDetails (rowData) {
      this.$emit('openRecommendDetails', rowData);
    },
    getDataInfo () {
      SearchPageYKRecommendForFollowUp(this.params).then(
        (res) => {
          this.totalNum = res.data.Total;
          this.dataInfoList = res.data.PageDataList;
          this.$emit('upDataInfo', res.data);
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    }
  }
};
</script>