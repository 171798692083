<template>
  <div class="WKT_information_list">
    <div class="WKT_information_block">
      <div class="WKT_information_img">
        <img :src="vImg"
             :onerror="defaultImg"
             alt="">
      </div>
      <div class="WKT_information_info">
        <div class="WKT_information_name">
          {{item.title}}
        </div>
        <div class="WKT_information_post"
             v-if="item.courses">
          主讲：{{item.courses}}
        </div>
        <div class="WKT_information_text"
             v-html="item.content"></div>
      </div>
    </div>
    <operation-btn :item="item"
                   :dataList="dataList"
                   :isShowSetTop="true"
                   @promote="promote"
                   @decline="decline"
                   @edit="edit"
                   @remove="remove"
                   @setTop="setTop"></operation-btn>
  </div>
</template>
<script>
import { DeletePopularRecommend, MovePopularRecommend } from '../../../API/workbench';
import operationBtn from '../common/operation-btn';
export default {
  data () {
    return {

    };
  },
  components: {
    operationBtn
  },
  props: {
    item: Object,
    dataList: {
      type: Array,
      default: []
    }
  },
  computed: {
    vImg () {
      if (!this.item.picurl) {
        this.item.picurl = '';
      }
      return this.$utils.ossLitimgSet(this.item.picurl);
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../public/image/no_logo@2x.png') + '"';
    }
  },
  methods: {
    setMovePopularRecommend (id, indedx) {
      MovePopularRecommend(id, indedx).then(result => {
        // this.$emit('afterMoveCombo');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 上升
    promote (item, index) {
      this.setMovePopularRecommend(item.id, index == 0 ? 1 : index);
    },
    // 下降
    decline (item, index) {
      this.setMovePopularRecommend(item.id, index + 3);
    },
    // 修改
    edit (item) {
      this.$emit('edit', item);
    },
    // 删除
    remove (item, index) {
      layer.confirm('是否需要删除该篇文章?', {
        btn: [{
          name: '确认',
          callBack: () => {
            DeletePopularRecommend(item.id).then(result => {
              this.dataList.splice(index, 1);
            }, error => {
              layer.alert(error.msg);
            });
            layer.close();
          }
        }
        ]
      });
    },
    setTop (item, index) {
      this.setMovePopularRecommend(item.id, 1);
      this.$emit('moveIndex', item, index);
    }
  }
};
</script>