<template>
  <div>
    <div class="content_box">
      <!-- 开启列表箭头类名 open -->
      <div class="student_content_box_title"
           style="padding-right:90px"
           :class="{open:vIsOpen}"
           @click.stop="openOrCloseDetail">
        <span>{{dataObj.MainDemoName}}</span>
        <div v-if="dataObj.isShow&&dataObj.hasArrangedCourse"
             class="content_box_title_type font_green">已进班</div>
        <div v-if="dataObj.isShow&&!dataObj.hasArrangedCourse"
             class="content_box_title_btn btn_hover_bg_blue"
             @click.stop="addInClass">进班</div>
      </div>
      <div v-if="vIsOpen"
           class="content_box_ul">
        <rule-course-item v-for="item in dataObj.dataList"
                          :key="item.index"
                          :courseInfo="item"
                          @clickEvent="onClickonClickEvent"></rule-course-item>
      </div>
    </div>
  </div>
  <!-- -->
</template>

<script>
import ruleCourseItem from './rule-course-item';
export default {
  data () {
    return {
      topValue: 0,
      toScrollTop: 0// 滚动后的位置
    };
  },
  props: {
    dataObj: {
      type: Object,
      required: true,
      default: () => {
        return {
          OALPKey: 0,
          MainDemoName: '', // 班级名称/课程名
          dataList: '', // 内循环列表
          isShow: false // 班级要显示加入班级按钮
        };
      }
    }

  },
  components: {
    ruleCourseItem
  },
  computed: {
    vIsOpen () {
      return this.dataObj.isOpen;
    }

  },
  mounted () {
  },
  methods: {
    openOrCloseDetail () {
      this.$emit('openOrCloseDetail', this.dataObj);
    },
    onClickonClickEvent (courseInfo, e) {
      this.$emit('doOneTopValueEvent', courseInfo, e);
    },
    addInClass () {
      this.$emit('addInClass', this.dataObj);
    }
  }
};
</script>

<style>
</style>