<template>
  <div class="warning_list"
       @click.stop="seletedItem"
       :title="item.TypeKeyValue+(item.TypeKey==13?'':'：')+item.Title">
    {{item.TypeKeyValue}}{{item.TypeKey==13?'':'：'}}{{item.Title}}
    <div class="list_lable"
         v-if="item.TypeKey==11&&item.StatusKey==1">已禁用</div>

    <div class="list_btn"></div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object,
    keyIndex: Number
  },
  computed: {

  },
  methods: {
    seletedItem () {
      this.$emit('seletedItem', this.item, this.keyIndex);
    }
  }
};
</script>

