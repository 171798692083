<template>
  <!-- 通用列表 -->
  <div class="headquarters_info_summarizing">
    <div class="headquarters_info_search pr">
      <input-search-contain-btn v-model="params.searchText"
                                :width="'280px'"
                                :placeholder="'请输入'"
                                @onSearchEvent="search"
                                @clearSearch="clear"></input-search-contain-btn>

      <div class="summarizing_number_right_btn"
           style="top: 15px;">
        <function-btn :item="{ name: '添加校区', ModulePowerKey: '' }"
                      :className="'el-button btn_light_blue btn_hover_bg_blue'"
                      @clickItem="addCampus">
        </function-btn>
      </div>
    </div>
  </div>
</template>
<script>

import inputSearchContainBtn from '../../../report/common/input-search-contain-btn';
export default {
  data () {
    return {

    };
  },
  props: {
    params: Object
  },
  components: {
    inputSearchContainBtn
  },
  watch: {

  },
  created () {

  },
  mounted () {
  },
  methods: {
    // 添加
    addCampus () {
      this.$emit('addCampus');
    },
    // 搜索
    search () {
      console.log('搜索', this.params);
      this.$emit('loadTableData', this.params);
    },
    // 清除
    clear () {
      console.log('清除', this.params);
      this.params.searchText = '';
      this.params.pageIndex = 0;
      this.$emit('loadTableData', this.params);
    }
  }
};
</script>
<style>
</style>

