<template>
  <!-- 课程设置列表 -->
  <div>
    <custom-dialog title="全部课程"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowMainForm"
                   :before-close="doShowPopupHideClick">
      <div class="single_shadow content_marginRL content_marginTop_30">
        <function-btn :item="addbtn"
                      @clickItem="dobanJiAddClick"
                      :className="'defult_dialog_header_btn fr'">
          <span class="btn_add">+</span>开设新课
        </function-btn>
        <div class="class_common_list_box">
          <div class="course_block_white">
            <span class="font_red">*</span>{{titleTips}}
          </div>
          <div class="class_common_list_search">
            <input type="text"
                   v-model="parameter.searchText" />
            <div class="search_btn "
                 @click="doSearchTextClick()">搜索</div>
          </div>

          <div class="class_common_list_content"
               @scroll="scrollTOP">
            <div class="class_common_list clearfix"
                 v-for="(item,index) in kechengList"
                 :key="index"
                 @click.stop="doComboClick(item,$event)"
                 :class="{'abnormal_icon':item.StateKey == 3}">
              <div class="list_icon icon_yellow fl">课</div>
              <div class="list_text fl">
                <div>{{item.MainDemoName}}</div>
                <div class="font_gray">
                  在读学生
                  <span class="font_black">{{parseFloat(item.Total_Student)}}人</span>
                  <span v-if="vIsSysVersionTY">
                    | 可用课时
                    <span class="font_black">{{parseFloat(item.Sum_CurrentCount)}}</span>
                    <span v-if="item.Sum_ToBePlaned&& parseFloat(item.Sum_ToBePlaned).toFixed(0)>0">
                      (含
                      <span class="font_black">{{parseFloat(item.Sum_ToBePlaned)}}</span>课时待报课)
                    </span>
                  </span>
                </div>
              </div>
              <div class="list_btn fl"></div>
            </div>
            <div class="course_student_attendance_operation module_content_menuList"
                 :style="{'top':topValue+'px','left':'316px'}"
                 v-if="isShowItem">
              <div class="top_bg"></div>
              <drop-down-box :optionJsonList="itemMenu"
                             @clickOptionForAlertDialog="onItemClick"></drop-down-box>
            </div>
          </div>
          <div v-if="kechengList.length==0">
            <div class="monitoring_content_nodata">
              <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
            </div>
          </div>
        </div>
      </div>
    </custom-dialog>
  </div>
</template> 

<script>
import dropDownBox from '../../../common/drop-down-box';
import {
  MessageSearchPageForCourseType,
  GetTheClassType,
  StartTheClassType,
  StopTheClassType
} from '../../../../API/workbench';
export default {
  name: 'banJiSettingList',
  data () {
    return {
      isShowMainForm: false, // 控制课程显示隐藏
      kechengList: [], // 课程列表
      kechengSelectItem: {}, // 课程项
      itemMenu: [], // 操作菜单项
      // 查询课程
      parameter: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'MainDemoName', //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为课程名
        sequence: 'desc', //	字符串	可选	asc	数据源：GET	排序方向 ASC正序 DESC 倒序
        searchText: '' //	字符串	可选		数据源：GET	搜索条件：模糊匹配 课程名
      },
      // newIndex: -1,
      isShowItem: false,
      topValue: 0, // 默认第一个top值
      toScrollTop: 0, // 滚动条的高度
      addbtn: {
        name: '',
        type: '',
        ModulePowerKey: 45
      }
    };
  },

  components: {
    dropDownBox
  },
  props: {
    isShowAdd: {
      type: Boolean,
      default: true
    },
    isChildComponent: {
      type: Boolean,
      default: false
    },
    titleTips: {
      type: String,
      default: '选中课程可做更多操作'
    }
  },

  created () {
    this.loadData(); // 渲染列表数据
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isShowItem = false;
    }, false);
  },
  computed: {
    vIsSysVersionTY () {
      return !this.$store.getters.isSysVersionTY;
    }
    // vlengthShowMenu () {
    //   if (this.kechengList.length > 0) {
    //     if (
    //       (this.kechengList.length <= 9 && this.newItem.isMenu == true) ||
    //       this.kechengList.length - 1 == this.newIndex
    //     ) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   } else {
    //     return false;
    //   }
    // }
  },
  methods: {
    // 主表单开关
    doShowPopupHideClick () {
      this.isShowMainForm = false;
      this.parameter.searchText = '';
      this.toScrollTop = 0;
      this.loadData();
    },
    // 新增成功后刷新/页面并向上传递
    addSuccessEvent (data) {
      this.$emit('onClick', data);
      this.loadData();
    },
    // 搜索/刷新
    doSearchTextClick () {
      this.loadData();
    },
    loadData () {
      MessageSearchPageForCourseType(this.parameter).then(
        result => {
          console.log(result.data, '课程');
          this.isShowItem = false;
          this.kechengList = result.data.PageDataList;
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 跳转开设新课
    dobanJiAddClick () {
      this.isShowItem = false;
      // this.$nextTick(() => {
      //   this.$refs.settingItem.isShowMainForm = true;
      //   this.$refs.settingItem.formTitle = 'add';
      //   this.$refs.settingItem.editItem = {};
      //   this.$refs.settingItem.isReadonly = false;
      // });
    },
    scrollTOP (e) {
      this.toScrollTop = e.target.scrollTop;
      if (e.target.scrollTop > 0) {
        this.isShowItem = false;
      }
    },
    // 选择课程项,加载最新数据
    doComboClick (item, e) {
      console.log(item, 'item, index');
      let offsetTop = e.currentTarget.offsetTop;
      if (this.isChildComponent) {
        this.$emit('onClick', item);
        this.doShowPopupHideClick();
      } else {
        GetTheClassType(item.OLAPKey).then(
          result => {
            console.log(result.data, '课程项');
            this.kechengList.forEach(o => {
              if (o.OLAPKey == result.data.OLAPKey) {
                this.isShowItem = true;
                this.doOneTopValueCount(offsetTop);
              }
            });
            this.onSelectPopupItem(result.data);
          },
          error => {
            layer.alert(error.msg);
          }
        );
      }
    },
    // 计算TOP值
    doOneTopValueCount (offsetTop) {
      let scrollTop = document.getElementsByClassName('class_common_list_content')[0].scrollTop;
      this.topValue = offsetTop - scrollTop + 42;
    },
    // doOneTopValueCount (index) {
    // this.newIndex = index;
    // this.topValue = 230;
    // let oneTopValue = 73.87;
    // if (this.toScrollTop > 0) {
    //   this.topValue = oneTopValue * this.newIndex - this.toScrollTop + this.topValue;
    // } else {
    //   this.topValue = this.topValue + (oneTopValue * this.newIndex);
    // }
    // },
    // 选择弹出项
    onSelectPopupItem (item) {
      if (item.StateKey == 2) {
        // 启用课程
        this.itemMenu = [
          {
            type: 1,
            name: '课程排课',
            eventName: 'pkhz',
            isShow: true,
            data: item,
            ModulePowerKey: 53
          },
          {
            type: 2,
            name: '修改课程',
            eventName: 'edit',
            isShow: true,
            data: item,
            ModulePowerKey: 45
          },
          {
            type: 3,
            name: '停用课程',
            eventName: 'stop',
            isShow: true,
            data: item,
            ModulePowerKey: 45
          }
        ];
      } else if (item.StateKey == 3) {
        // 停用课程
        this.itemMenu = [
          {
            type: 1,
            name: '课程排课',
            eventName: 'pkhz',
            isShow: true,
            data: item,
            ModulePowerKey: 53
          },
          {
            type: 2,
            name: '修改课程',
            eventName: 'edit',
            isShow: true,
            data: item,
            ModulePowerKey: 45
          },
          {
            type: 3,
            name: '启用课程',
            eventName: 'start',
            isShow: true,
            data: item,
            ModulePowerKey: 45
          }
        ];
      }
    },
    // 菜单操作项
    onItemClick (menu) {
      console.log(menu, 'onItemClick');
      this.isShowItem = false;
      var _this = this;
      switch (menu.eventName) {
        case 'pkhz':
          let item = {
            ModulePowerKey: 53,
            name: '课程排课',
            routerName: 'courseNameApplyCourseReport',
            routertitle: '课程排课',
            dataKey: menu.data.OLAPKey
          };
          this.toScrollTop = 0;
          this.$bus.emit('needOpenRouter', item);
          break;
        case 'edit':
          this.$nextTick(() => {
            this.$refs.settingItem.isShowMainForm = true;
            this.$refs.settingItem.formTitle = menu.eventName;
            this.$refs.settingItem.editItem = menu.data;
            this.$refs.settingItem.isReadonly = false;
          });
          break;
        case 'stop':
          // StopTheClassType(menu.data.OLAPKey).then(
          //   result => {
          //     console.log(result, '停用课程');
          //     this.loadData(); // 渲染列表数据
          //   },
          //   error => {
          //     layer.alert(error.msg);
          //   }
          // );
          layer.confirm('请确定是否停用该课程?', {
            btn: [{
              name: '确认',
              callBack: function () {
                layer.close();
                StopTheClassType(menu.data.OLAPKey).then(result => {
                  console.log(result, '停用成功');
                  _this.loadData();// 渲染列表数据
                  layer.alert('停用成功', {
                    btn: [{
                      name: '确认',
                      callBack: function () {
                        layer.close();
                      }
                    }]
                  });
                }, error => {
                  layer.alert(error.msg);
                });
              }
            }]
          });
          break;
        case 'start':
          layer.confirm('请确定是否启用该课程?', {
            btn: [{
              name: '确认',
              callBack: function () {
                layer.close();
                let Obj = {IsSameContinue: '0', OLAPKey: menu.data.OLAPKey};
                StartTheClassType(Obj).then(result => {
                  console.log(result, '启用成功');
                  _this.loadData();// 渲染列表数据
                  layer.alert('启用成功', {
                    btn: [{
                      name: '确认',
                      callBack: function () {
                        layer.close();
                      }
                    }]
                  });
                }, error => {
                  layer.alert(error.msg);
                });
              }
            }]
          });
          break;
        case 'cancel':
          // console.log('结束没操作');
          break;
        default:
          break;
      }
    },
    closeDialog () {
      if (!this.isChildComponent) {
        // this.$router.back(-1);
      } else {
        this.$emit('closeDialog');
      }
    }
  }
};
</script>

  <style>
.dialog_header_add {
  position: relative;
  left: 1rem;
  font-size: 15px;
  color: #3996d8;
}
</style>