<template>
  <!-- 点赞人详情列表 -->
  <div>
    <div class="homework_student_ul">
      <details-item v-for="(item,index) in praiseList"
                    :key="index"
                    :praiseItem="item"
                    @coinStateClick="afterCoinStateClick"></details-item>
      <div></div>
    </div>

    <div v-if="isShowBtn">
      <release-btn :btnText="'确认'"
                   :isFloatBtn="true"
                   @click="confirmClick"></release-btn>
    </div>
    <!-- 无数据 -->
    <div class="index_JXT_noData"
         v-if="praiseList&&praiseList.length==0">
      <span class="index_JXT_noData_text">暂无数据</span>
    </div>
  </div>
</template>

<script>
import detailsItem from './details-item';
import releaseBtn from '../../common-component/release-btn';
export default {
  data () {
    return {
      // studentList: []
    };
  },
  props: {
    headImgTitle: {
      type: String,
      default: '点赞'
    },
    praiseList: Array,
    isShowBtn: {
      type: Boolean,
      default: function () {
        return true;
      }
    }
  },
  components: {
    detailsItem,
    releaseBtn
  },
  created () {
    // this.studentList = this.$utils.parseJson(this.praiseList);
    console.log(this.studentList, '学分');
  },
  watch: {
  },
  computed: {
  },
  methods: {
    afterCoinStateClick (item, index) {
      // this.$emit('afterCoinStateClick', item, index);
    },
    // 确认按钮
    confirmClick () {
      console.log(this.studentList, '学分');
      // layer.alert('正在处理，请稍后...');
      this.$emit('confirmClick', this.studentList);
    },
    // 返回按钮
    returnPage () {
      this.$emit('returnPage');
    }
  }
};
</script>

<style>
</style>