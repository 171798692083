<template>
  <div class="accessory_other_item">
    <div :class="vIconClass"></div>
    <div class="accessory_other_name">{{dataInfo.MainDemoName}}</div>
    <!-- <div class="accessory_other_size">({{vSizeNum}})</div> -->

    <div class="delete_button"
         @click="deleteItem"></div>
    <div class="courseware_name_capacity">
      <img :src="require('../../../../../public/image/capacity_icon.png')"
           class="courseware_name_capacity_icon" />
      <span class="courseware_name_capacity_text">{{!dataInfo.SizeNum?'0B':this.$utils.capacityFormate(dataInfo.SizeNum)}}</span>
    </div>
  </div>
</template>
<script>
import { DeleteCoursewareManageAttach } from '../../../../API/workbench';
export default {
  data () {
    return {
      isShowUpload: false
    };
  },
  props: {
    dataInfo: Object
  },
  computed: {
    // 1-图片;2-视频;3-音频;4-PPT;5-PDF;6-ZIP;7-excel;8-word;
    vIconClass () {
      if (this.dataInfo.AttachTypeKey > 0) {
        return 'type_0' + this.dataInfo.AttachTypeKey;
      } else {
        return '';
      }
    },
    vSizeNum () {
      // if (Number(this.dataInfo.SizeNum) > (1 * 1024 * 1024)) {
      //   return this.$utils.mMoneyType((Number(this.dataInfo.SizeNum) / 1024 / 1024), 1) + 'MB';
      // } else {
      //   return this.$utils.mMoneyType((Number(this.dataInfo.SizeNum) / 1024), 1) + 'KB';
      // }
      return '(' + Number(this.$utils.mMoneyType((Number(this.dataInfo.SizeNum) / 1024 / 1024), 3)) + 'MB)';
    }
  },
  created () {
    // console.log(this.dataInfo, 'this.dataInfo');
  },
  methods: {
    deleteItem () {
      // console.log(this.dataInfo, '删除');
      layer.confirm('是否删除当前内容', {
        btn: [
          {
            name: '取消',
            callBack: () => {
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              if (this.dataInfo.ID) {
                DeleteCoursewareManageAttach(this.dataInfo.ID).then((res) => {
                  this.$emit('deleteItem', this.dataInfo);
                }, (err) => {
                  layer.alert(err.msg);
                });
              }

              layer.close();
            }
          }
        ]
      });
    }
  }
};
</script>
<style scoped>
.accessory_other_item {
  display: flex;
  position: relative;
  height: 50px;
  border-bottom: 1px solid #e8ebef;
  line-height: 50px;
}
.accessory_other_item:last-child {
  border-bottom: none;
}
.accessory_other_item .type_01 {
  background: url(../../../../../public/image/material_icon01.png) no-repeat;
  width: 20px;
  height: 25px;
  position: absolute;
  top: 15px;
  background-size: 20px;
}
.accessory_other_item .type_02 {
  background: url(../../../../../public/image/material_icon02.png) no-repeat;
  width: 20px;
  height: 25px;
  position: absolute;
  top: 15px;
  background-size: 20px;
}
.accessory_other_item .type_03 {
  background: url(../../../../../public/image/material_icon04.png) no-repeat;
  width: 20px;
  height: 25px;
  position: absolute;
  top: 15px;
  background-size: 20px;
}
.accessory_other_item .type_04 {
  background: url(../../../../../public/image/material_icon03.png) no-repeat;
  width: 20px;
  height: 25px;
  position: absolute;
  top: 15px;
  background-size: 20px;
}
.accessory_other_item .type_05 {
  background: url(../../../../../public/image/material_icon05.png) no-repeat;
  width: 20px;
  height: 25px;
  position: absolute;
  top: 15px;
  background-size: 20px;
}
.accessory_other_item .type_06 {
  background: url(../../../../../public/image/material_icon11.png) no-repeat;
  width: 20px;
  height: 25px;
  position: absolute;
  top: 15px;
  background-size: 20px;
}
.accessory_other_item .type_07 {
  background: url(../../../../../public/image/material_icon10.png) no-repeat;
  width: 20px;
  height: 25px;
  position: absolute;
  top: 15px;
  background-size: 20px;
}
.accessory_other_item .type_08 {
  background: url(../../../../../public/image/material_icon09.png) no-repeat;
  width: 20px;
  height: 25px;
  position: absolute;
  top: 15px;
  background-size: 20px;
}
.accessory_other_name {
  color: #666666;
  font-size: 14px;
  margin-left: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 270px;
}
.accessory_other_size {
  color: #666666;
  font-size: 12px;
  margin-left: 5px;
}
.delete_button {
  position: absolute;
  right: 0;
  top: 10px;
  width: 20px;
  height: 30px;
  flex-shrink: 0;
  background: url(../../../../../public/image/courseware_menu_list03.png)
    no-repeat center center;
  cursor: pointer;
}
.courseware_name_capacity {
  position: absolute;
  bottom: 0px;
  right: 19px;
}
.courseware_name_capacity_icon {
  width: 12px;
  height: 12px;
}
.courseware_name_capacity_text {
  font-size: 14px;
  color: #999999;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
</style>