<template>
  <div @click.stop="clickItem"
       :class="[vPowerCss,className]"
       v-if="isStop"
       class="">
    <slot></slot>
    {{item.value?'':item.name}}
  </div>
  <div @click.stop="clickItem"
       class=""
       :class="[vPowerCss,className]"
       v-else>
    <slot></slot>
    {{item.value?'':item.name}}
  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },
  props: {
    item: Object,
    className: {
      type: String,
      default: ''
    },
    isStop: {
      type: Boolean,
      default: true
    }
  },
  created () {
  },
  computed: {
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    },
    vHasModulePower () {
      return this.$utils.CheckModulePower(
        this.vRolePowerList,
        this.item.ModulePowerKey
      );
    },
    vPowerCss () {
      // 有权限
      if (this.vHasModulePower) {
        return '';
        // 无权限
      } else {
        return 'norole';
      }
    },
    vIsFinish () {
      if (this.item.auth || this.item.auth == undefined) {
        return '';
      } else {
        return 'gray_title';
      }
    }
  },
  methods: {
    clickItem (e) {
      // 有权限
      if (this.vHasModulePower) {
        this.$emit('clickItem', this.item, e);
        // 无权限
      } else {
        this.$utils.CheckModulePowerToTips(this.item.ModulePowerKey);
      }
    }
  }
};
</script>

<style>
</style>