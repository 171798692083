<template>
  <div class="data_content"
       style="height:307px;padding-bottom:0px"
       @click.stop="showDialog('abnormalhandling')">
    <div class="report_item_header">
      <div style="padding-top: 3px;"
           class="button">异常考勤操作</div>
      <div class="report_item_header_btn_list">
        <div :class="searchType=='month'?'opt':''"
             @click.stop="changeTime('month')">本月</div>
        <div :class="searchType=='week'?'opt':''"
             @click.stop="changeTime('week')">本周</div>
        <div :class="searchType=='day'?'opt':''"
             @click.stop="changeTime('day')">本日</div>
      </div>
    </div>
    <div v-show="vShowEchart"
         class="principal_manage_echarts pr"
         style="height: 100%;">
      <circleEchart :echartID="'echartData_report'"
                    :reqList="monthList"
                    :nowDataList="nowList"
                    :tipstitle="'考勤异常'"
                    @clickItem="doAfterClickEchart"
                    ref="circleEchart"></circleEchart>

    </div>
    <div v-if="!vShowEchart"
         style="width:100%;height:100%">
      <div class="monitoring_content_nodata"
           style="margin-top: 60px;">
        <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
      </div>
    </div>
  </div>
</template>
<script>
import circleEchart from './circle-echart'
export default {
  data () {
    return {
      echartColor: ['#5A8DF6', '#62DAAB', '#F56E53', '#657798'],
      nowList: [],
      monthList: []
    };
  },
  props: {
    dataInfo: Object,
    searchType: String
  },
  computed: {
    vShowEchart () {
      if (Number(this.dataInfo.ChangeAttendanceCount) == 0
        && Number(this.dataInfo.TimeOutAttendanceCount) == 0
        && Number(this.dataInfo.QuitRecurringLessonCount) == 0
        && Number(this.dataInfo.TrialLessonCancelCount) == 0) {
        return false
      } else {
        return true
      }
    },
  },
  components: {
    circleEchart
  },
  created () {
    console.log("dataInfo", this.dataInfo)
  },
  methods: {
    changeTime (type) {
      console.log("changeTime", type)
      this.$emit("chgSearchType", type)
    },
    showDialog (moudleName) {
      this.$emit("showDialog", moudleName)
    },
    // 点击图片示例.
    doAfterClickEchart (item) {

    },
    // 设置课消余额金额
    initData (data) {
      console.log('initData', data)
      this.nowList = [];
      let obj1 = {
        value: Number(data.ChangeAttendanceCount),
        name: '校正考勤 ' + Number(data.ChangeAttendanceCount),
        id: '',
        itemStyle: {
          color: this.echartColor[0]
        }
      };
      let obj2 = {
        value: Number(data.TimeOutAttendanceCount),
        name: '超时考勤 ' + Number(data.TimeOutAttendanceCount),
        id: '',
        itemStyle: {
          color: this.echartColor[1]
        }
      };
      let obj3 = {
        value: Number(data.QuitRecurringLessonCount),
        name: '退出循环课 ' + Number(data.QuitRecurringLessonCount),
        id: '',
        itemStyle: {
          color: this.echartColor[2]
        }
      };
      let obj4 = {
        value: Number(data.TrialLessonCancelCount),
        name: '取消排课 ' + Number(data.TrialLessonCancelCount),
        id: '',
        itemStyle: {
          color: this.echartColor[3]
        }
      };

      this.nowList.push(obj1);
      this.nowList.push(obj2);
      this.nowList.push(obj3);
      this.nowList.push(obj4);



      console.log(this.nowList, '余额list');
      this.$nextTick(() => {
        if (this.vShowEchart) {

          this.$refs.circleEchart.echartInit();
        }

      });
    },
  }
};
</script>
<style scoped>
.report_item_header {
  position: relative;
  color: #333;
  font-size: 16px;
  margin-bottom: 10px;
}
.report_item_header::after {
  position: absolute;
  display: block;
  content: '';
  width: 18px;
  height: 18px;
  left: 93px;
  top: 3px;
  background: url('../../../../public/image/next.png') no-repeat center;
  background-size: 9px;
}
.report_item_header_btn_list {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
}
.report_item_header_btn_list > div {
  width: 64px;
  height: 24px;
  border-radius: 4px;
  border: 1px;
  font-size: 12px;
  border: 1px solid #f2f2f2;
  margin-right: 10px;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
}
.report_item_header_btn_list > div:hover {
  color: #3498db;
  border: 1px solid #3498db !important;
}
.report_item_header_btn_list .opt {
  color: #3498db;
  border: 1px solid #3498db !important;
}
</style>
