<template>
  <div class>
    <div class="form_info_edit content_marginRL content_marginTop_30 single_shadow"
         style="padding:0 20px">
      <div class>
        <div class="form_info_inputnumber form_info_line form_info_required no_border_bottom">
          <div class="form_info_field">
            <span>验 证 码</span>
          </div>
          <div class="form_info_value pr"
               style="padding-right:78px">
            <input type="text"
                   class="verifyCode"
                   onclick="this.select()"
                   placeholder="必填"
                   v-model="verfyCode"
                   onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/\.{2,}/g,'.')" />
            <div class="sendVerifyCodeGrey"
                 @click="sendVerfyCode()"
                 v-if="verfyCodeShow">获取验证码</div>
            <div class="sendVerifyCodeGrey gray"
                 v-else>重新发送({{countdown}})</div>
          </div>
        </div>
      </div>
    </div>
    <save-cancel-btn-group @confirmClick="confirmVerifyCodeToLogin"
                           @cancelClick="closeEvent"></save-cancel-btn-group>
  </div>
</template>
<script>
import { doReLogin } from '../../assets/login';
import { GetCaptcha, SwitchSaaSClientUnVerify } from '@/API/workbench.js';
export default {
  data () {
    return {
      token: this.$store.getters.token,
      verfyCodeShow: true,
      countdown: 60, // 读秒
      isopenID: false,
      verfyCode: '' // 验证码
    };
  },
  props: {
    mendianID: [String, Number],
    codeTitle: String
  },
  computed: {},
  created () {
    // 外部进入 - 需要一个标题
    console.log('mendianID',this.mendianID)
    if (this.codeTitle) {
      if (this.codeTitle == '切换门店账号') {
        this.isopenID = false;
      } else {
        this.isopenID = true;
      }
    }
  },
  watch: {},
  methods: {
    // 发送验证
    sendVerfyCode () {
      GetCaptcha(this.token.Mobile).then(result => {
        if (result.code == 0) {
          this.settime();
        } else {
          layer.alert(result.msg);
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 发送验证码倒计时（读秒）
    settime () {
      console.log("settime",this.countdown)
      if (this.countdown == 0) {
        this.verfyCodeShow = true;
        this.countdown = 60;
        return;
      } else {
        if (this.countdown <= 60 && this.countdown > 0) {
          // 读秒进入置灰
            console.log("读秒进入置灰",this.countdown)
          this.verfyCodeShow = false;
          this.countdown--;
          if (this.countdown == 59) {
            this.verfyCode = '';
          } // 重新获取验证码时，清空当前验证码
        } else {
          // 读完秒后置蓝
          this.verfyCode = ''; // 一分钟后，验证码继续重置
        }
      }
      var that = this;
      setTimeout(function () {
        that.settime();
      }, 1000);
    },
    // 输入验证码(确定按钮)
    confirmVerifyCodeToLogin () {
      if (!this.verfyCode) {
        layer.alert('请输入验证码');
        return false;
      }
      SwitchSaaSClientUnVerify(this.mendianID, this.verfyCode).then(result => {
        this.closeEvent();
        doReLogin(result.data);
      }, error => {
        layer.alert(error.msg);
      });
    },
    closeEvent () {
      this.$emit('closeDialog');
    }
  }
};
</script>
<style>
.sendVerifyCodeGrey {
  position: absolute;
  width: 90px;
  height: 30px;
  line-height: 31px;
  border-radius: 6px;
  text-align: center;
  right: 0px;
  top: 0;
  color: #fff;
  background-color: #3498db;
  cursor: pointer;
}

.sendVerifyCodeGrey.gray {
  color: #aaa;
}
.verifyCode {
  color: #3498db;
}
</style>


