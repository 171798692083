<template>
  <div>
    <div v-if="isLoading"
         class="list_drop_noData"
         :class="isShowlistDropBox?'list_drop_box':''">
      <div class="">拼命加载中</div>
    </div>
    <div v-else-if="vIsShowTable">
      <div :class="isShowlistDropBox?'list_drop_box':''"
           :style="'padding-left:' + tablePaddingLeft">
        <div class="list_drop_banner"
             :style="'width:' + tableWidth">
          <el-table element-loading-text="拼命加载中"
                    class="table_list_detail"
                    ref="multipleTable"
                    :data="tableData"
                    highlight-current-row
                    stripe
                    :border="false"
                    empty-text
                    toggleRowSelection>
            <component v-for="columnInfo in tableColumns"
                       :key="columnInfo.prop"
                       v-bind:is="columnInfo.type"
                       :columnData="columnInfo"></component>

          </el-table>
          <div v-if="downBoxText"
               v-html="downBoxText"
               @click="downBoxClick"></div>
        </div>
        <div class="list_drop_box_icon"
             :style="'left:' + iconPositionLeft">
        </div>

      </div>
    </div>
    <div v-else-if="vIsNonData"
         class="list_drop_noData"
         :class="isShowlistDropBox?'list_drop_box':''">
      <div class="">亲，当前没有任何数据哦~</div>
    </div>
  </div>
</template>
<script>

import textItem from '../column/text-item';
import student from '../column/student';
import arrangeSupport from '../column/arrange-support';
import dateItem from '../column/date-item';
import readingCourses from '../column/reading-courses';
import classHour from '../column/class-hour';
import amountItem from '../column/amount-item';
import numberItem from '../column/number-item';
import mdName from '../column/md-name';
import tableBtn from '../column/table-btn';
import hiddenBtn from '../column/hidden-btn';
import wechatSubscribe from '../column/wechat-subscribe';
import faceRecognition from '../column/face-recognition';
import multiLevelHead from '../column/multi-level-head';
export default {

  props: {
    tableData: Array, // 列表数据
    tableColumns: Array, // table column
    isLoading: Boolean, // 正在加载中
    tablePaddingLeft: [String, Number], // 展开行距离左边的
    iconPositionLeft: [String, Number],
    tableWidth: [String, Number],
    downBoxText: String,
    isShowlistDropBox: {
      type: Boolean,
      default: true
    }
  },
  components: {
    textItem,
    student,
    arrangeSupport,
    dateItem,
    readingCourses,
    classHour,
    amountItem,
    numberItem,
    wechatSubscribe,
    faceRecognition,
    multiLevelHead,
    tableBtn,
    hiddenBtn,
    mdName
  },
  computed: {
    vIsShowTable () {
      console.log(this.downBoxText, 'tableColumns');
      return !this.isLoading && this.tableData.length > 0;
    },
    vIsNonData () {
      return !this.isLoading && this.tableData.length == 0;
    }
  },
  watch: {

  },
  methods: {
    downBoxClick () {
      this.$emit('downBoxClick');
    }
  }
};
</script>

<style>
</style>