<template>
    <div class="left_content">
        <div class="left_list_item font_black" style="font-size:20px;" :class="Number(seletedID)==0?'opt':''" @click.stop="seletedItem(0)">学管师管理</div>
        <div class="left_list_item" v-for="(item,key) in dataList" :key="key" :class="Number(seletedID)==item.OLAPKey?'opt':''" @click.stop="seletedItem(item.OLAPKey,item)">
            <div class="font_black" style="font-size:16px">{{item.MainDemoName}}</div>
            <div class="font_gray" style="font-size:14px;color:#999;margin-top:5px">正课生： {{Number(item.StudentCount)}}</div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    },
    props:{
        seletedID:[Number,String],
        dataList:Array
    },
    methods:{
        seletedItem(id,item){
            this.$emit("seletedItem",id,item)
        }
    }
}
</script>
<style scoped>
    .left_content{
        width: 166px;
        /* height: 100%; */
        background: #F5F7F9;
      
    }
    .left_content .left_list_item{
        width: 100%;
        height: 87px;
        margin: auto;
        /* text-align: center; */
        padding-left: 32px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        
    }
    .left_content .left_list_item.opt{
        font-size:20px;
        background: white;
    }
    .left_content .left_list_item.opt .font_black{
        font-size:20px !important;
    }
    .left_content .left_list_item.opt .font_gray{
        font-size:16px !important;
    }
</style>