<template>
  <!-- 授权课件表单 -->
  <div style="height:400px"
       class="mode_set">
    <div class="tipSpan">
      <span style="color: red;">*</span> 课件模块按大课包统一设置,设定后大课包的全部课件自动同步.
    </div>
    <div class="form_info_edit form_info_list">
      <div>
        <input-switch v-model="dataInfoed.procedures"
                      :formTitle="'课件教程'"
                      :showInputValue="false"
                      :readonly="isReadonly"></input-switch>
      </div>
    </div>
    <div class="form_info_edit form_info_list">
      <div>
        <input-switch v-model="dataInfoed.lessonCommentary"
                      :formTitle="'课评话术'"
                      :showInputValue="false"
                      :readonly="isReadonly"></input-switch>
      </div>
    </div>
    <div class="form_info_edit form_info_list">
      <div>
        <input-switch v-model="dataInfoed.homeworkAfter"
                      :formTitle="'课后作业'"
                      :showInputValue="false"
                      :readonly="isReadonly"></input-switch>
      </div>
    </div>
    <div class="form_info_edit form_info_list">
      <div>
        <input-switch v-model="dataInfoed.pptFontCheck"
                      :formTitle="'PPT字体缺少校验'"
                      :showInputValue="false"
                      :readonly="isReadonly"></input-switch>
      </div>
    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

      dataInfoed: {
        procedures: false,
        lessonCommentary: false,
        homeworkAfter: false,
        pptFontCheck:false
      }
    };
  },
  components: {

  },
  props: {
    SettingTemplate: Object,
    isReadonly: Boolean
  },
  created () {
  },
  mounted () {
    this.dataInfoed = JSON.parse(JSON.stringify(this.SettingTemplate));
  },
  computed: {

  },
  methods: {
    submit () {
      this.$emit('afterSuccess', this.dataInfoed);
      this.$emit('close');
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.tipSpan {
  margin: 20px 20px 10px;
  background: #fffbe7;
  border: 1px solid rgba(255, 230, 151, 1);
  padding: 10px;
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
</style>
