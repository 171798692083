<template>
  <!-- 基础设置项 -->
  <div>
    <div class="content_marginRL content_marginTop_30">
      <!-- 内容 -->
      <div class="mendian_setting_content single_shadow">
        <div>
          <input-text :formTitle="'维度名称'"
                      :readonly="false"
                      class="no_border_bottom"
                      :required="true"
                      v-model="dataInfo.SetName"></input-text>
        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <div class="">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'static'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
  </div>

</template> 

<script>
export default {
  props: {// 是否显示表单
    isReadonly: {// 是否只读
      type: Boolean,
      default: false
    },
    seletdInfo:Object
  },
  data () {
    return {
      dataInfo:{
        SetName:''
      },

    };
  },
  computed: {
  },
  created () {
    console.log("this.seletdInfo",this.seletdInfo)
    this.dataInfo=this.$utils.parseJson(this.seletdInfo)
  },

  methods: {
    // 确认按钮
    confirmClick (callBack) {
      let flag = false
      if (!this.dataInfo.SetName) {
        layer.alert('请输入维度名称');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.$emit('afterSuccess',this.dataInfo)
    },
    // 取消按钮
    cancelClick () {
      this.$emit('cancelClick');
    }
  }
};
</script>

  <style>
</style>