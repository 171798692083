<template>
  <div class="table_select_box_flex">
    <div class="table_select_box" v-if="Number(teacherKey)==0">
      <div class="table_select_title">{{vTeacher}}</div>
      <el-select :value="vTeacherKeyValue"
                 filterable
                 placeholder="必选"
                 @change="typeEvent"
                 value-key="OLAPKey">
        <el-option v-for="item in vTeacherList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item"></el-option>
      </el-select>
    </div>
    <div class="table_select_box">
      <div class="table_select_title">班级</div>
      <el-select :value="vGradeKeyValue"
                 filterable
                 placeholder="必选"
                 @change="accountEvent"
                 value-key="OLAPKey">
        <el-option v-for="item in vGradeClassList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item"></el-option>
      </el-select>
    </div>
    <div class="table_select_box">
      <div class="table_select_title">课程</div>
      <el-select :value="vCourseKeyValue"
                 filterable
                 placeholder="必选"
                 @change="subjectEvent"
                 value-key="OLAPKey">
        <el-option v-for="item in vCourseNameList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item"></el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { geTteacherList, getUsingClass, getCourseTypeNameListX } from '../../../../API/workbench';
export default {
  data () {
    return {
      teacherList: [], // 老师列表
      gradeClassList: [], // 班级列表
      courseNameList: []
    };
  },
  created () {
    // this.loadDataSourceTeacher();
    // this.getValidClassList();
    // this.getCourseNameList();
  },
  props: {
    searchObj: Object,
     teacherKey:{
      type: [Number,String],
      default: 0
    },
    IsMTeaKey: {
      type: Number,
      default: 1
    }
  },
  computed: {
    vCourseNameList () {
      let newArr = (this.$store.getters.courseNameList && this.$store.getters.courseNameList.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; })) || [];
      newArr.unshift({
        MainDemoName: '全部',
        OLAPKey: 0
      });
      return newArr;
    },
    vGradeClassList () {
      let newArr = this.$utils.parseJson(this.$store.getters.gradeClassList || []);
      newArr.unshift({
        MainDemoName: '全部',
        OLAPKey: 0
      });
      return newArr;
    },
    vTeacherList () {
      let newArr = this.$utils.parseJson(this.$store.getters.teacherList || []);
      newArr.unshift({
        MainDemoName: '全部',
        OLAPKey: 0
      });
      return newArr;
    },
    vCourseKeyValue () {
      return this.searchObj.CourseKey == 0
        ? '全部'
        : this.searchObj.CourseKeyValue;
    },
    vGradeKeyValue () {
      return this.searchObj.GradeKey == 0
        ? '全部'
        : this.searchObj.GradeKeyValue;
    },
    vTeacherKeyValue () {
      return this.searchObj.TeacherKey == 0
        ? '全部'
        : this.searchObj.TeacherKeyValue;
    },
    vTeacher () {
      return this.IsMTeaKey != 1 ? '助教老师' : '主讲老师';
    }
  },
  watch: {

  },
  methods: {
    // 选中类型
    typeEvent (obj) {
      console.log(obj, '选中老师');
      this.searchObj.TeacherKeyValue = obj.MainDemoName;
      this.searchObj.TeacherKey = obj.OLAPKey;
      this.$emit('changeSearchObj');
    },
    // 选中账户
    accountEvent (obj) {
      console.log(obj, '选中班级');
      this.searchObj.GradeKeyValue = obj.MainDemoName;
      this.searchObj.GradeKey = obj.OLAPKey;
      this.$emit('changeSearchObj');
    },
    // 选中课程
    subjectEvent (obj) {
      console.log(obj, '选中课程');
      this.searchObj.CourseKeyValue = obj.MainDemoName;
      this.searchObj.CourseKey = obj.OLAPKey;
      this.$emit('changeSearchObj');
    }
    // 调用在职老师接口
    // loadDataSourceTeacher () {
    //   geTteacherList().then(result => {
    //     this.teacherList = result.data;
    //     this.teacherList.unshift({
    //       MainDemoName: '全部',
    //       OLAPKey: 0
    //     });
    //   });
    // },
    // 获取有效班级列表
    // getValidClassList () {
    //   getUsingClass().then(result => {
    //     this.gradeClassList = result.data;
    //     this.gradeClassList.unshift({
    //       MainDemoName: '全部',
    //       OLAPKey: 0
    //     });
    //   });
    // }
    // 课名列表
    // getCourseNameList () {
    //   getCourseTypeNameListX('OLAPKey', 'desc').then(result => {
    //     this.courseNameList = result.data.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; });
    //     this.courseNameList.unshift({
    //       MainDemoName: '全部',
    //       OLAPKey: 0
    //     });
    //   });
    // }
  }

};
</script>