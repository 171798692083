<template>
  <div class="">
    <div class="flex"
         style="padding: 10px 20px;">
      <date-range ref="saveDate"
                  :searchObj="searchObj"
                  :fromTitle="'申请日期'"
                  :minDate="vMinDate"
                  @changeDate="changeSaveDate"></date-range>
      <div class="table_select_box">
        <div class="table_select_title">课名</div>
        <el-select :value="vCourseNameKeyValue"
                   filterable
                   :style="searchObj.isShowDialog?' width: 210px;':''"
                   placeholder="必选"
                   @change="subjectEvent"
                   value-key="OLAPKey">
          <el-option v-for="item in courseNameList"
                     :key="item.OLAPKey"
                     :label="item.MainDemoName"
                     :value="item"></el-option>
        </el-select>
      </div>
      <div class="table_select_box"
           style="margin-right: 28px;">
        <div class="table_select_title">来源</div>
        <el-select :value="vAttenStatu"
                   filterable
                   :style="searchObj.isShowDialog?' width: 150px;':''"
                   placeholder="必选"
                   @change="attenStatusEvent"
                   value-key="OLAPKey">
          <el-option v-for="item in statusList"
                     :key="item.OLAPKey"
                     :label="item.MainDemoName"
                     :value="item"></el-option>
        </el-select>
      </div>
      <input-search-contain-btn v-model="searchObj.searchText"
                                :placeholder="'请输入姓名、姓名首字母、手机号'"
                                :clearAll="true"
                                @clearSearch="clearSearchCondition"
                                @onSearchEvent="searchData"></input-search-contain-btn>
    </div>
    <!-- list -->
    <div class="course_statistics_table_box tryArrangeCourse_table table_list_content">
      <table-list class="summarizing_list"
                  :key="tableKey"
                  :tableData="vdataReportList"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :tableColumns="tableCourseColumns"
                  :isSearchText="false"
                  :isNeedInnerScroll="false"
                  :defaultSort="defaultSort"
                  :queryParams="searchObj"
                  :footerContent="vFooterContent"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import { getCourseTypeNameListX, MessageSearchPageTrialClassForArrange, RecoverTrialClass } from '../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        type: '',
        sequence: 'DESC',
        stutasKey: 7,
        CourseNameKey: 0,
        CourseNameKeyValue: '',
        sourceKey: 0,
        sourceKeyValue: '',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd'), // this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd'),
        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      onlineNum: 0,
      seletedItem: {},
      dataReportList: [],
      defaultSort: { prop: '', order: 'ascending' },
      statusList: [
        {
          MainDemoName: '全部',
          OLAPKey: '0'
        },
        {
          MainDemoName: '线上申请',
          OLAPKey: '3'
        },
        {
          MainDemoName: '员工录入',
          OLAPKey: '2'
        }
      ],
      courseNameList: [],
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          className: 'table_index_gray'
        },
        {
          label: '安排时间',
          prop: 'PlanDateTime',
          minWidth: 70,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '来源',
          prop: 'OrderRootInKeyValue',
          minWidth: 80,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            className: 'font_dual'
          }
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          minWidth: 80,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'student',
          extend: {
            className: 'font_dual',
            studentKey: 'StudentKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '试课课名',
          prop: 'OrderClassKeyValue',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '试课时间',
          prop: 'TrialTime',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            className: 'font_dual'
          }
        },
        {
          label: '转化进度',
          prop: 'ScheduleStatus',
          minWidth: 80,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            setClassName: (rowData) => {
              if (rowData.ScheduleStatusKey == 5) {
                return 'radio_type type_red';
              } else {
                return '';
              }
            }
          }

        },
        {
          label: '追销记录',
          prop: 'FollowRecordsSupport',
          minWidth: 200,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'up-sell-support',
          extend: {
            isEdit: false
          }
        },
        // {
        //   label: '下次跟进',
        //   prop: 'NextFollowTime',
        //   sortable: 'custom',
        //   minWidth: 80,
        //   align: 'left',
        //   isShow: true,
        //   type: 'text-item'

        // },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          headerAlign: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '恢复',
                extend: {
                  click: (rowData) => { this.renew(rowData); }
                }
              }

            ]
          }
        }
      ]
    };
  },
  components: {
    tableList, inputSearchContainBtn
  },
  created () {
    this.getDataList();
    this.getCourseNameList();
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // let info = o.ClassTime.substring(0, 10) + ',' + o.ClassTime.substring(11);
        // o.classTimeObj = info.split(',');
        // o.ClassTime = o.ClassTime.substring(5);
        // o.AttenceTime = o.AttenceTime ? o.AttenceTime.substring(5, 16) : '';
        // this.$set(o, 'OLAPKey', o.CourseNameKey);
        newArr.push(o);
      });
      return newArr;
    },
    vCourseNameKeyValue () {
      return this.searchObj.CourseNameKey == 0
        ? '全部'
        : this.searchObj.CourseNameKeyValue;
    },
    vAttenStatu () {
      return !this.searchObj.sourceKey
        ? '全部'
        : this.searchObj.sourceKeyValue;
    },
    vFooterContent () {
      return '共' + this.totalNum + '条数据 ';
    }
  },
  methods: {
    // 选中课程
    subjectEvent (obj) {
      console.log(obj, '选中课程');
      this.searchObj.CourseNameKeyValue = obj.MainDemoName;
      this.searchObj.CourseNameKey = obj.OLAPKey;
      this.searchData();
      this.$emit('changeSearchObj');
    },
    // 选中考勤状态
    attenStatusEvent (obj) {
      console.log(obj, '选中考勤');
      this.searchObj.sourceKeyValue = obj.MainDemoName;
      this.searchObj.sourceKey = obj.OLAPKey;
      this.searchData();
      this.$emit('changeSearchObj');
    },
    getCourseNameList () {
      getCourseTypeNameListX('OLAPKey', 'desc').then(result => {
        this.courseNameList = result.data.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; });
        this.courseNameList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
      });
    },
    // 选择日期
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      // this.searchObj.orderBy = '';
      this.searchObj.type = '';
      // this.searchObj.sequence = 'DESC';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.QueryID = 0;
      this.searchObj.IsExportKey = 0;
      this.searchObj.CourseNameKey = 0;
      this.searchObj.sourceKeyValue = '';
      this.searchObj.sourceKey = 0;
      this.searchObj.AttenceStatusKey = '';
      this.$refs.saveDate.resetTime();
      this.searchData();
    },
    // 获取数据
    getDataList (queryParams) {
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      MessageSearchPageTrialClassForArrange(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.totalDeductAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        this.onlineNum = result.data.OnlineAppliCount;
        console.log(result.data, 'getDataList');
      }).catch(error => {
        console.log(error, 'error');
      });
    },
    searchData () {
      // this.searchObj.StudentKey = '';
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 恢复
    renew (rowItem) {
      RecoverTrialClass(rowItem.TableID).then(result => {
        layer.alert('恢复成功');
        this.getDataList();
        this.$emit('updateData');
      }).catch(error => {
        console.log('error', error);
      });
    }
  }
};
</script>