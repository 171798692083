<template>
  <div class="purchase_order">
    <div class="signuppayment_course_select_ul bg_white">
        <div class="select_list" :class="{opt:dataJson.TypeKey ==2 }" @click="changeType(2)">
          加盟课程
        </div>
        <div class="select_list" :class="{opt:dataJson.TypeKey ==1 }" @click="changeType(1)">
          其他项目
        </div>
    </div>
    <div class="form_info_edit form_info_list" v-if="dataJson.TypeKey==2">
      <input-dialog :formTitle="'课程名称'"
                        class=""
                        :formPlaceholder="'请选择'"
                        :readonly="isReadonly"
                        :required="true"
                        @showEldilog="showListDialog"
                        :value="dataJson.CourseKeyValue"></input-dialog>
      <input-select
        :formTitle="'授权类型'"
        :id="'id'"
        :name="'name'"
        :required="true"
        :readonly="isReadonly"
        :dataList="typeList"
        :formPlaceholder="'请选择'"
        :selectedInfo="vFranchiseType"
        @selectedItem="chgCourseType"
      ></input-select>
      <input-number v-if="Number(dataJson.StatusKey)>1"
        :formTitle="vTypeTitle"
        :required="true"
        :readonly="isReadonly"
        :formPlaceholder="'请输入'"
        v-model="dataJson.QuantityCount"
      ></input-number>
      <input-number
        :formTitle="'单价'"
        :required="true"
        :readonly="isReadonly"
        :formPlaceholder="'请选择'"
        v-model="dataJson.UnitPriceAmount"
      ></input-number>
      <input-number
        :formTitle="'收费金额'"
        :required="true"
        :readonly="true"
        :formPlaceholder="'请输入'"
        v-model="vTotalAmount"
      ></input-number>
    </div>
    <div class="form_info_edit form_info_list" v-if="dataJson.TypeKey==1">
      <input-dialog :formTitle="'名称'"
                        class=""
                        :formPlaceholder="'请选择'"
                        :readonly="isReadonly"
                        :required="true"
                        @showEldilog="showListDialog"
                        :value="dataJson.IncidentalsKeyValue"></input-dialog>
      <input-number
        :formTitle="'数量'"
        :required="true"
        :readonly="isReadonly"
        :formPlaceholder="'请输入'"
        v-model="dataJson.QuantityCount"
      ></input-number>
      <!-- <input-text
        :formTitle="'单位'"
        :required="true"
        :readonly="isReadonly"
        :formPlaceholder="'请选择'"
        v-model="dataJson.Unit"
      ></input-text> -->
      <input-number
        :formTitle="'单价'"
        :required="true"
        :readonly="isReadonly"
        :formPlaceholder="'请选择'"
        v-model="dataJson.UnitPriceAmount"
      ></input-number>
      <input-number
        :formTitle="'收费金额'"
        :required="true"
        :readonly="true"
        :formPlaceholder="'请输入'"
        v-model="vTotalAmount"
      ></input-number>
    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group
      v-if="!isReadonly"
      :isSingleSubmit="true"
      :singleSubmitType="'dynamic'"
      @confirmClick="confirmClick"
      @cancelClick="cancelClick"
    ></save-cancel-btn-group>
  </div>
</template>
<script>
import {hqAttractInvestmentIncidentals,HQGetLeagueCourseList} from '../../../../../API/workbench'
export default {
  data() {
    return {
      dataJson: {
        TotalAmount: '',//"总价",
        UnitPriceAmount: '',//"单价",
        Unit: '',//"单位",
        "StatusKey":'',//"课程收费类型1-终身;2-按年收费;3-按次收费",
        "StatusKeyValue":'',//"课程收费类型",
        "TypeKey":2,//"1-杂费;2-课程",
        "TypeKeyValue":'',//"类别",
        QuantityCount: '',//"数量",
        CourseKey: '',//"课程",
        CourseKeyValue: '',//"课程",
        IncidentalsKey: '',//"杂费",
        IncidentalsKeyValue: '',//"杂费",
      },
      showList:false,
      typeList: [
        {
          id: 1,
          name: "终身",
        },
        {
          id: 2,
          name: "按年",
        },
        {
          id: 3,
          name: "按次",
        },
      ], // 来源1-抖音;2-百度;3-转介绍
    };
  },
  props:{
      dataInfo:Object,
      isReadonly:Boolean
  },
  created(){
      if(this.dataInfo){
          this.dataJson=this.$utils.parseJson(this.dataInfo)
      }
  },
  mounted(){
      this.$dialog.register(this, ['singleSelectionList']);
  },
  computed:{
    vTotalAmount(){
      if(Number(this.dataJson.StatusKey)==1){
        return Number(this.dataJson.UnitPriceAmount)
      }else{
        return Number(this.dataJson.UnitPriceAmount)*Number(this.dataJson.QuantityCount)
      }
    },
    vTypeTitle(){
      return this.dataJson.StatusKey==2?'有效期':'次数'
    },
    // 选择模式
    vFranchiseType() {
      let obj = {
        id: this.dataJson.StatusKey,
        name: this.dataJson.StatusKeyValue,
      };
      console.log("obj",obj)
      return obj;
    },
  },
  methods:{
    // 选择类型
    chgCourseType(item){
      console.log('chgCourseType',item)
      this.dataJson.StatusKey=item.id
      this.dataJson.StatusKeyValue=item.name
        this.dataJson.QuantityCount=''
        this.dataJson.UnitPriceAmount=''
      if(this.dataJson.StatusKey==1){
        this.dataJson.Unit='终身'
      }else if(this.dataJson.StatusKey==2){
        this.dataJson.Unit='年'
      }else{
        this.dataJson.Unit='次'
      }
    },
    // 切换列表
    changeType(index){
      this.dataJson.TypeKey=index
      this.dataJson.TypeKeyValue=index==2?'课程':'杂费'
      if(!this.dataInfo){
        this.dataJson= {
          TotalAmount: '',//"总价",
          UnitPriceAmount: '',//"单价",
          Unit: '',//"单位",
          QuantityCount: '',//"数量",
          CourseKey: '',//"课程",
          CourseKeyValue: '',//"课程",
          IncidentalsKey: '',//"杂费",
          IncidentalsKeyValue: '',//"杂费",
          "StatusKey":'',//"课程收费类型1-终身;2-按年收费;3-按次收费",
          "StatusKeyValue":'',//"课程收费类型",
          "TypeKey":index,//"1-杂费;2-课程",
          "TypeKeyValue":index==2?'课程':'杂费',//"类别",
        }
      }
    },
      cancelClick(){
          this.$emit("cancelClick")
      },
      verifyData(callback){
          if(Number(this.dataJson.StatusKey)==1){
             this.dataJson.TotalAmount= Number(this.dataJson.UnitPriceAmount)
          }else{
             this.dataJson.TotalAmount= Number(this.dataJson.UnitPriceAmount)*Number(this.dataJson.QuantityCount)
          }
          if(this.dataJson.TypeKey==2){
            if(!this.dataJson.CourseKeyValue){
                layer.alert('请选择课程名称')
                if(callback){
                    callback()
                }
                return false
            }else if(Number(this.dataJson.StatusKey)==0){
                layer.alert('请选择加盟方式')
                if(callback){
                    callback()
                }
                return false
            }else if(Number(this.dataJson.QuantityCount)==0&&this.dataJson.StatusKey!=1){
                layer.alert('数量不能为0')
                if(callback){
                    callback()
                }
                return false
            }else if(Number(this.dataJson.UnitPriceAmount)==0){
                layer.alert('单价不能为0')
                if(callback){
                    callback()
                }
                return false
            }else if(Number(this.dataJson.TotalAmount)==0){
                layer.alert('收费金额不能为0')
                if(callback){
                    callback()
                }
                return false
            }else{
                return true
            }
          }else{
            if(!this.dataJson.IncidentalsKeyValue){
                layer.alert('请选择杂费项目')
                if(callback){
                    callback()
                }
                return false
            }else if(Number(this.dataJson.QuantityCount)==0){
                layer.alert('数量不能为0')
                if(callback){
                    callback()
                }
                return false
            }else if(Number(this.dataJson.UnitPriceAmount)==0){
                layer.alert('单价不能为0')
                if(callback){
                    callback()
                }
                return false
            }else if(Number(this.dataJson.TotalAmount)==0){
                layer.alert('收费金额不能为0')
                if(callback){
                    callback()
                }
                return false
            }else{
                return true
            }
          }
        
      },
      confirmClick(callback){
          if(this.verifyData(callback)){
              this.$emit('afterSuccess',this.dataJson,this.keyindex)
              this.$emit("cancelClick")
          }else{
           
          }
      },
      showListDialog(callback){
        if(this.dataJson.TypeKey==2){
           this.getCourseForList()
        }else{
          this.getMessageForList()
        }
      },
      doAfterSelectionData(item){
        if(this.dataJson.TypeKey==2){
          this.dataJson.CourseKey= item.OLAPKey;//"杂费",
          this.dataJson.CourseKeyValue=item.MainDemoName//"杂费",
        }else{
          this.dataJson.IncidentalsKey= item.OLAPKey;//"杂费",
          this.dataJson.IncidentalsKeyValue=item.MainDemoName//"杂费",
          this.dataJson.Unit=item.UnitName
        }
      },
      // 获取所有财务科目列表
    getMessageForList () {
      hqAttractInvestmentIncidentals().then(result => {
        this.dataList = result.data;
        console.log(result.data, '获取所有启用的数据');
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择杂费',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.dataJson.IncidentalsKey, dataSourceList: this.dataList, required: true, addModuleName: '', addModuleTitleName: '添加杂费', ModulePowerKey: 0 },
          callBack: {
            onSelectedItem: (data) => { this.doAfterSelectionData(data); }
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
      // 获取所有财务科目列表
    getCourseForList () {
      // let parameter={
      //    pageIndex:0, //	整型	必须	0	数据源：GET	页码，0为第一页
      // pageSize: 0, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      // orderBy: '', //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为课程名
      // sequence: '', //	字符串	可选	asc	数据源：GET	排序方向 ASC正序 DESC 倒序
      // searchText: '' //	字符串	可选		数据源：GET	搜索条件：模糊匹配 课程名
      // }
      HQGetLeagueCourseList().then(result => {
        this.dataList = result.data//.PageDataList.filter(obj => { return obj.TypeKey == 2&&obj.StateKey==2; });;
        console.log(this.dataList, '获取所有启用的数据');
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择课程',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.dataJson.CourseKey, dataSourceList: this.dataList, required: true, addModuleName: '', addModuleTitleName: '添加课程', ModulePowerKey: 0 },
          callBack: {
            onSelectedItem: (data) => { this.doAfterSelectionData(data); }
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
  }

};
</script>