<template>
  <div :class="titleData.className"
       :style="titleData.styleName"
       @click.stop="cilckEvent">
    {{titleData.title}}
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    titleData: {
      type: Object,
      default: () => {
        return { title: '提交时间', opt: '序号', className: '', styleName: '' };
      }
    }
  },
  components: {

  },
  created () {
  },
  methods: {
    cilckEvent () {
      this.$emit('onCilckEvent', this.titleData);
    }
  }
};
</script>

<style>
</style>