<template>
  <div class="week_list"
       :class="item.isActive?'opt':''"
       @click.stop="item.isActive=!item.isActive">
    {{vValue}}
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  computed: {
    vValue () {
      return this.item.value.substring(1);
    }
  },
  methods: {

  }
};
</script>
