const popUpMenu = {
  state: {
    searchDialogVisible: false
  },

  getters: {
    searchDialogVisible: state => {
      return state.searchDialogVisible;
    }
  },
  mutations: {
    searchDialogVisible: (state, bol) => {
      console.log('searchDialogVisible:' + bol);
      state.searchDialogVisible = bol;
    }
  }
};

export default popUpMenu;
