<template>
  <!-- 作废单据模块 -->
  <div>
    <div class="content_marginRL border_bottom">
      <div class="form_info_edit mendian_setting_content single_shadow"
           :class="{btn_marginBottom_30:true}">

        <input-text :formTitle="'原因说明'"
                    class="no_border_bottom"
                    :readonly="false"
                    :formPlaceholder="'选填'"
                    :required="true"
                    v-model="dataJson.Reason"></input-text>
      </div>
    </div>
    <!-- 按钮组 -->
    <div v-if="true"
         style="margin-top: 80px;">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="doAfterClickCancelBtn"></save-cancel-btn-group>
    </div>
  </div>

</template> 

<script>
import { ApplyRevokeCollectionRecord } from '../../../API/workbench';
export default {
  data () {
    return {
      // 数据源
      dataJson: {
        KDSYKey: '', // 报名记录key
        Reason: ''// 备注
      }
    };
  },
  props: {
    searchParams: String
  },
  computed: {

  },
  watch: {

  },
  created () {
    this.dataJson.KDSYKey = Number(this.searchParams);
    console.log(this.searchParams, 'this.dataJson.KDSYKey', this.dataJson.KDSYKey);
  },
  mounted () {

  },
  methods: {
    // 确认按钮
    confirmClick (callBack) {
      let isWrong = this.checkSubmitData();
      if (isWrong) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      ApplyRevokeCollectionRecord(this.dataJson.KDSYKey, this.dataJson.Reason).then(result => {
        layer.alert('申请成功');
        this.$emit('deleteBillBoolean');
        this.$emit('close');
        this.afterSuccess();
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 验证要提交的数据
    checkSubmitData () {
      let flag = false;
      if (!this.dataJson.Reason) {
        layer.alert('原因说明不能为空');
        flag = true;
      }
      return flag;
    },
    // 取消按钮
    // afterSuccess () {
    //   this.$emit('afterSuccess');
    //   this.studentInfo = true;
    //   console.log(this.studentInfo, 'this.studentInfo');
    // },
    doAfterClickCancelBtn () {
      this.$emit('close');
    }
  }
};
</script>
