<template>
  <div class="abnormalSign_list">
    <div class="abnormalSign_list_type">
      <span class="font_date">{{vCreateTime}}</span>
      <span>{{vSignType}}</span>
    </div>
    <div class="abnormalSign_list_text">
      <span class="font_red">{{abnormalItem.WarningTypeKeyValue}}</span>
      <span class="font_name" v-if="abnormalItem.FromTypeKey==1">家长: {{abnormalItem.SignUserName}}</span>
      <span class="font_name" v-else>学生: {{abnormalItem.SignStudentKeyValue}}</span>
    </div>
    <div class="abnormalSign_list_btn btn_hover_white" @click.stop="clickItem">
      处理
    </div>
  </div>

</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: ['abnormalItem'],
  computed: {
    vCreateTime () {
      if (this.abnormalItem) {
        this.abnormalItem.CreateTime = this.abnormalItem.CreateTime || '';
        return this.abnormalItem.CreateTime.substring(10, 16);
      }
    },
    vSignType () {
      if (this.abnormalItem) {
        if (this.abnormalItem.FromTypeKey == 1) {
          return '扫码';
        } else {
          return '刷脸';
        }
      } else {
        return '';
      }
    }
  },
  methods: {
    clickItem () {
      this.$emit('clickItemAfter', this.abnormalItem);
    }
  }
};
</script>
