<template>
  <div class="plan">
    <div class="formbox">
      <div class="tit">补课学生</div>
      <div class="con"
           :style="mode==2 ? 'cursor:pointer;' : ''"
           @click="openStudent">
        <div class="text"
             v-if="studentKey">{{studentVal}}</div>
        <div class="text"
             v-if="!studentKey">未选择</div>
      </div>
    </div>
    <div class="formbox">
      <div class="tit">补课课次</div>
      <div class="con clearfix"
           :style="mode==2 ? 'cursor:pointer;' : ''"
           @click="openAbsence">
        <div class="date"
             v-if="absenceKey">
          <div class="day"><span>{{absenceCourseWeek}}</span>{{absenceCourseDay}}</div>
          <div class="time">{{absenceCourseTime}}</div>
        </div>
        <div class="name"
             v-if="absenceKey">
          <div>{{absenceCourseName}}</div>
          <span>{{absenceCourseClass}} - {{absenceCourseTeacher}}</span>
        </div>
        <div class="mark"
             :class="absenceCourseType==5 ? 'red' : ''"
             v-if="absenceKey">
          <div v-if="absenceCourseType==4">请假</div>
          <div v-if="absenceCourseType==5">旷课</div>
          <span v-if="absenceCourseHour==0">{{absenceCourseHour}} 课时</span>
          <span v-else>-{{absenceCourseHour}} 课时</span>
        </div>
        <div class="text"
             v-if="!absenceKey">未选择</div>
      </div>
    </div>
    <div class="formbox">
      <div class="tit">安排课次</div>
      <div class="con clearfix"
           :style="mode==1 ? 'cursor:pointer;' : ''"
           @click="openTable">
        <div class="date"
             v-if="targetCourseKey">
          <div class="day"><span>{{targetCourseWeek}}</span>{{targetCourseDay}}</div>
          <div class="time">{{targetCourseTime}}</div>
        </div>
        <div class="name"
             v-if="targetCourseKey">
          <div>{{targetCourseName}}</div>
          <span>{{targetCourseClass}} - {{targetCourseTeacher}}</span>
        </div>
        <div class="text"
             v-if="!targetCourseKey">未选择</div>
      </div>
    </div>
    <div class="formbox">
      <div class="tit">安排补课是否扣课时</div>
      <el-radio v-model="useHour"
                :label="1">是，扣课时</el-radio>
      <el-radio v-model="useHour"
                :label="0">否，不扣课时</el-radio>
    </div>
    <save-cancel-btn-group :cancelBtnText="'取消'"
                           :isSingleSubmit="true"
                           :fixedBtn="true"
                           @confirmClick="submit"
                           @cancelClick="cancel"></save-cancel-btn-group>
    <custom-dialog title="安排课次"
                   width="410px"
                   :visible.sync="isShowTable"
                   :before-close="closeTable">
      <arrange-course :dataInfo="dataTable"
                      @afterSuccess="successTable"
                      @closeDialog="closeTable"></arrange-course>
    </custom-dialog>
    <custom-dialog title="补课学生"
                   width="410px"
                   :visible.sync="isShowStudent"
                   :before-close="closeStudent">
      <search-student-box :selectedStudentInfo="dataStudent"
                          :switchItem="1"
                          :CustStatusKey="1"
                          :showExamine="false"
                          :showMenu="false"
                          :showSwitchBut="true"
                          @selectedStudent="successStudent"></search-student-box>
    </custom-dialog>
    <custom-dialog title="补课课次"
                   width="500px"
                   :visible.sync="isShowAbsence"
                   :before-close="closeAbsence">
      <select-absence :searchobj="dataAbsence"
                      @afterSuccess="successAbsence"
                      @cancelClick="closeAbsence"></select-absence>
    </custom-dialog>
  </div>
</template>

<script>
import { DealMakeUpForArrange } from '../../../../API/workbench';
import arrangeCourse from './arrange-course.vue';
import searchStudentBox from '../../../common/search-student-box';
import selectAbsence from './select-absence.vue';
export default {
  data () {
    return {
      mode: 1, // 1为已有学生和缺课记录，要选补课课程，2为已有补课课程，要选学生和缺课记录
      studentKey: '', // 学生id
      studentVal: '', // 学生名
      absenceKey: '', // 缺课id
      absenceCourseDay: '', // 缺课日期，只传日
      absenceCourseWeek: '', // 缺课日期，周几
      absenceCourseTime: '', // 缺课上课时段
      absenceCourseKey: '', // 缺课课程id
      absenceCourseName: '', // 缺课课程名
      absenceCourseClass: '', // 缺课班级名
      absenceCourseTeacher: '', // 缺课老师名
      absenceCourseType: '', // 缺课原因
      absenceCourseHour: '', // 消耗课时数
      targetCourseKey: '', // 安排课id
      targetCourseDay: '', // 安排课日期，只传日
      targetCourseWeek: '', // 安排课日期，周几
      targetCourseTime: '', // 安排课上课时段
      targetCourseName: '', // 安排课课程名
      targetCourseClass: '', // 安排课班级名
      targetCourseTeacher: '', // 安排课老师名
      useHour: 1,
      isShowTable: false,
      dataTable: {
        StudentKey: this.planobj.studentKey,
        OrderClassKey: this.planobj.absenceCourseKey,
        OrderClassKeyValue: this.planobj.absenceCourseName
      },
      isShowStudent: false,
      dataStudent: {},
      isShowAbsence: false,
      dataAbsence: {
        studentKey: ''
      }
    };
  },
  components: {
    arrangeCourse,
    searchStudentBox,
    selectAbsence
  },
  props: {
    planobj: Object
  },
  created () {
    this.mode = this.planobj.mode;
    console.log(this.planobj, 'this.planobj`');
    if (this.mode == 1) {
      this.studentKey = this.planobj.studentKey;
      this.studentVal = this.planobj.studentVal;
      this.absenceKey = this.planobj.absenceKey;
      this.absenceCourseDay = this.planobj.absenceCourseDay;
      this.absenceCourseWeek = this.planobj.absenceCourseWeek;
      this.absenceCourseTime = this.planobj.absenceCourseTime;
      this.absenceCourseKey = this.planobj.absenceCourseKey;
      this.absenceCourseName = this.planobj.absenceCourseName;
      this.absenceCourseClass = this.planobj.absenceCourseClass;
      this.absenceCourseTeacher = this.planobj.absenceCourseTeacher;
      this.absenceCourseType = this.planobj.absenceCourseType;
      this.absenceCourseHour = this.planobj.absenceCourseHour;
    } else if (this.mode == 2) {
      this.targetCourseKey = this.planobj.targetCourseKey;
      this.targetCourseDay = this.planobj.targetCourseDay;
      this.targetCourseWeek = this.planobj.targetCourseWeek;
      this.targetCourseTime = this.planobj.targetCourseTime;
      this.targetCourseName = this.planobj.targetCourseName;
      this.targetCourseClass = this.planobj.targetCourseClass;
      this.targetCourseTeacher = this.planobj.targetCourseTeacher;
    }
  },
  mounted () {
  },
  methods: {
    submit () {
      let that = this;
      if (!that.studentKey) {
        layer.alert('未选择补课学生');
        return;
      }
      // if (!that.absenceKey) {
      //   layer.alert('未选择补课课次');
      //   return;
      // }
      // if (!that.targetCourseKey) {
      //   layer.alert('未选择安排课次');
      //   return;
      // }
      let param = {
        StudentKey: that.studentKey,
        ScheduleCourseKey: that.absenceKey,
        MakeUpScheduleCourseKey: that.targetCourseKey,
        IsDebtClassHourse: that.useHour
      };
      DealMakeUpForArrange(param).then(result => {
        if (result.code == 0) {
          that.$emit('afterSuccess');
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
        layer.alert(error.msg);
      });
    },
    cancel () {
      this.$emit('cancelClick');
    },
    openTable () {
      if (this.mode == 1) {
        this.isShowTable = true;
      }
    },
    closeTable () {
      this.isShowTable = false;
    },
    successTable (obj) {
      this.isShowTable = false;
      this.targetCourseKey = obj.OLAPKey;
      this.targetCourseDay = obj.ClassTime.split('-')[1] + '月' + obj.ClassTime.split('-')[2] + '日';
      this.targetCourseWeek = obj.WeekKeyValue;
      this.targetCourseTime = obj.ClassTimeName;
      this.targetCourseName = obj.CourseNameKeyValue;
      this.targetCourseClass = obj.GradeClassKeyValue;
      this.targetCourseTeacher = obj.MTeacherKeyValue;
    },
    openStudent () {
      if (this.mode == 2) {
        this.isShowStudent = true;
      }
    },
    closeStudent () {
      this.isShowStudent = false;
    },
    successStudent (obj) {
      this.studentKey = obj.OLAPKey;
      this.studentVal = obj.MainDemoName;
      this.absenceKey = '';
      this.absenceCourseDay = '';
      this.absenceCourseWeek = '';
      this.absenceCourseTime = '';
      this.absenceCourseKey = '';
      this.absenceCourseName = '';
      this.absenceCourseClass = '';
      this.absenceCourseTeacher = '';
      this.absenceCourseType = '';
      this.absenceCourseHour = '';
      this.$nextTick(() => {
        this.isShowStudent = false;
      });
    },
    openAbsence () {
      if (this.mode == 2) {
        if (this.studentKey) {
          this.dataAbsence.studentKey = this.studentKey;
          this.isShowAbsence = true;
        } else {
          layer.alert('请先选择学生');
        }
      }
    },
    closeAbsence () {
      this.isShowAbsence = false;
    },
    successAbsence (obj) {
      this.absenceKey = obj.absenceKey;
      this.absenceCourseDay = obj.absenceCourseDay;
      this.absenceCourseWeek = obj.absenceCourseWeek;
      this.absenceCourseTime = obj.absenceCourseTime;
      this.absenceCourseKey = obj.absenceCourseKey;
      this.absenceCourseName = obj.absenceCourseName;
      this.absenceCourseClass = obj.absenceCourseClass;
      this.absenceCourseTeacher = obj.absenceCourseTeacher;
      this.absenceCourseType = obj.absenceCourseType;
      this.absenceCourseHour = obj.absenceCourseHour;
      this.isShowAbsence = false;
    }
  }
};
</script>

<style scoped>
.plan {
  padding-bottom: 50px;
}
.plan .formbox {
  padding: 20px 20px 0;
}
.plan .formbox .tit {
  height: 20px;
  line-height: 20px;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.plan .formbox .con {
  border: 1px solid #333333;
  padding: 10px;
}
.plan .formbox .con .text {
  height: 20px;
  line-height: 20px;
  font-size: 16px;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.plan .formbox .con .date {
  float: left;
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 2px solid #333333;
  margin-right: 10px;
}
.plan .formbox .con .date .day {
  height: 26px;
  line-height: 26px;
  font-size: 14px;
  border-bottom: 2px solid #333333;
}
.plan .formbox .con .date .day span {
  display: inline-block;
  color: #ffffff;
  background-color: #333333;
  padding: 0 5px;
  margin-right: 5px;
}
.plan .formbox .con .date .time {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  padding: 0 5px;
  text-align: center;
}
.plan .formbox .con .name {
  float: left;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}
.plan .formbox .con .name div {
  height: 26px;
  line-height: 26px;
  font-size: 16px;
  font-weight: bold;
}
.plan .formbox .con .name span {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
}
.plan .formbox .con .mark {
  float: left;
  width: 100px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #f59a23;
}
.plan .formbox .con .mark div {
  height: 26px;
  line-height: 26px;
  font-size: 14px;
  color: #ffffff;
  background-color: #f59a23;
}
.plan .formbox .con .mark span {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  color: #f59a23;
}
.plan .formbox .con .mark.red {
  border: 2px solid #d9001b;
}
.plan .formbox .con .mark.red div {
  background-color: #d9001b;
}
.plan .formbox .con .mark.red span {
  color: #d9001b;
}
</style>