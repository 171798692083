<template>
  <div class="attendance_course_date_list">
    <div class="form_header_gray">
      <div class="form_header_text">
        {{$utils.formatDateStr(dateItem.date,'yyyy')+'年'}}{{$utils.formatDateStr(dateItem.date,'M')+'月'}}
      </div>
      <div class="form_header_text">
        签到 {{vSignCount}}次
        <span v-if="vLeaveCount>0">,请假 {{vLeaveCount}}次</span>
        <span v-if="vKKCount>0">,旷课 {{vKKCount}}次</span>
      </div>
    </div>
    <div style="padding: 0 15px;">
      <atten-item v-for="(item,key) in dateItem.childList"
                  :key="key"
                  :hiddenYear="true"
                  :item="item"></atten-item>
    </div>
  </div>

</template>

<script>
import attenItem from './atten-item.vue';
export default {
  data () {
    return {

    };
  },
  props: {
    dateItem: Object
  },
  components: {
    attenItem
  },
  created () {

  },
  computed: {
    vSignCount () {
      var num = 0;
      this.dateItem.childList.forEach(o => {
        if (o.AttenceStatusKey == 3) {
          num++;
        }
      });
      return num;
    },
    vLeaveCount () {
      var num = 0;
      this.dateItem.childList.forEach(o => {
        if (o.AttenceStatusKey == 4) {
          num++;
        }
      });
      return num;
    },
    vKKCount () {
      var num = 0;
      this.dateItem.childList.forEach(o => {
        if (o.AttenceStatusKey == 5) {
          num++;
        }
      });
      return num;
    }
  },
  methods: {}
};
</script>

<style scoped>
.attendance_course_date_list {
  border-bottom: 1px solid #ececec;
}
</style>
