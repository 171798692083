<template>
  <div class="list">
    <div class="formbox">
      <a class="btn btn_1"
         @click="addChannel">添加渠道</a>
    </div>
    <el-table :data="tableData">
      <el-table-column prop="GenerateTime"
                       label="添加日期"
                       width="100"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="MainDemoName"
                       label="渠道名"
                       width="150"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="MobileNumber"
                       label="手机号"
                       width="150"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="VolumeCount"
                       label="成交客户"
                       width="80"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="TurnoverAmount"
                       label="成交金额"
                       width="80"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="TotalConcessionAmount"
                       label="应返佣金"
                       width="80"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="AlreadyAmount"
                       label="已返佣金"
                       width="80"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="ResolvedAmount"
                       label="待返佣金"
                       width="80"
                       show-overflow-tooltip></el-table-column>
      <el-table-column label="一码通"
                       width="80">
        <template slot-scope="scope">
          <div class="button"
               @click="openCode(createCode(scope.row))">
            <vue-qr :text="createCode(scope.row)"
                    :size="50"
                    :margin="0"></vue-qr>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="ActivityKeyValue"
                       label="当前方案"
                       width="150"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="TypeKeyValue"
                       label="活动类型"
                       width="100"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="RelevanceActivityKeyValue"
                       label="活动名"
                       width="150"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="Remark"
                       label="备注"
                       show-overflow-tooltip></el-table-column>
      <el-table-column label="操作"
                       width="200">
        <template slot-scope="scope">
          <a class="row_btn"
             @click="openDetail(scope.row)">详情</a>
          <a class="row_btn"
             @click="setPlan(scope.row)">关联方案</a>
          <a class="row_btn"
             @click="rebate(scope.row)">返佣</a>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChange"
                   @current-change="currentChange"
                   :current-page="search.pageIndex"
                   :page-sizes="[5,10,20,30,40,50]"
                   :page-size="search.pageSize"
                   layout="total,prev,pager,next,jumper,sizes"
                   :total="totalNum"
                   background></el-pagination>
    <custom-dialog title="添加渠道"
                   width="500px"
                   :visible.sync="isShowAdd"
                   :before-close="closeAdd">
      <channel-list-add @afterSuccess="successAdd"
                        @cancelClick="closeAdd"></channel-list-add>
    </custom-dialog>
    <custom-dialog title="一码通"
                   width="500px"
                   :visible.sync="isShowCode"
                   :before-close="closeCode">
      <code-show :qrcodeurl="urlCode"></code-show>
    </custom-dialog>
    <custom-dialog title="关联方案"
                   width="500px"
                   :visible.sync="isShowSet"
                   :before-close="closeSet">
      <channel-list-set :rowkey="dataSet"
                        @afterSuccess="successSet"
                        @cancelClick="closeSet"></channel-list-set>
    </custom-dialog>
    <custom-dialog title="渠道详情"
                   width="500px"
                   :visible.sync="isShowDetail"
                   :before-close="closeDetail">
      <channel-list-detail :rowobj="dataDetail"></channel-list-detail>
    </custom-dialog>
  </div>
</template>

<script>
import { GetCustomerChannelList } from '../../../API/workbench';
import channelListAdd from './channel-list-add.vue';
import channelListSet from './channel-list-set.vue';
import channelListDetail from './channel-list-detail.vue';
import codeShow from './code-show.vue';
import vueQr from './vue-qr.js';
export default {
  data () {
    return {
      search: {
        pageIndex: 1,
        pageSize: 10
      },
      tableData: [],
      totalNum: 0,
      PageCount: 0,
      isShowAdd: false,
      isShowCode: false,
      urlCode: '',
      isShowSet: false,
      dataSet: '',
      isShowDetail: false,
      dataDetail: {
        OLAPKey: '',
        SaaSClientKey: '',
        MobileNumber: ''
      }
    };
  },
  components: {
    channelListAdd,
    channelListSet,
    channelListDetail,
    codeShow,
    vueQr
  },
  props: {
  },
  created () {
  },
  mounted () {
    this.getList();
  },
  computed: {
  },
  methods: {
    sizeChange (val) {
      this.search.pageSize = val;
      this.search.pageIndex = 1;
      this.getList();
    },
    currentChange (val) {
      this.search.pageIndex = val;
      this.getList();
    },
    addChannel () {
      this.isShowAdd = true;
    },
    openDetail (row) {
      this.dataDetail.OLAPKey = row.OLAPKey;
      this.dataDetail.SaaSClientKey = row.SaaSClientKey;
      this.dataDetail.MobileNumber = row.MobileNumber;
      this.isShowDetail = true;
    },
    closeDetail () {
      this.isShowDetail = false;
    },
    setPlan (row) {
      this.dataSet = row.OLAPKey;
      this.isShowSet = true;
    },
    rebate (row) {
      this.$emit('rebate', row);
    },
    createCode (row) {
      let ENV = 'develop';
      let qrcodeUrl = '';
      if (process.env.NODE_ENV === 'development') {
        ENV = 'develop';
      } else {
        if (this.$store.getters.ProductENV === 'product') {
          ENV = 'release';
        } else {
          ENV = 'trial';
        }
      }
      qrcodeUrl = this.$store.getters.APIURL + '/public/Util/qrcode.php?Content=' +
        encodeURIComponent(this.$store.getters.SaaSClientInfo.MiniProgramUrl + '/release?targetRouterParms=ChannelKey=' + row.OLAPKey + '&targetSaaSClientID=' + row.SaaSClientKey) +
        '&size=10';
      // qrcodeUrl = this.$store.getters.APIURL + '/public/Util/qrcode.php?Content=' + this.$store.getters.SaaSClientInfo.MiniProgramUrl + '/' + ENV + '?targetRouterParms=' + encodeURIComponent('ChannelKey=' + row.OLAPKey + '&targetSaaSClientID=' + row.SaaSClientKey) + '&size=10';
      console.log(row, 'row', this.$store.getters, qrcodeUrl);
      return qrcodeUrl;
    },
    openCode (url) {
      console.log(url);
      this.urlCode = url;
      this.isShowCode = true;
    },
    closeCode () {
      this.isShowCode = false;
    },
    closeAdd () {
      this.isShowAdd = false;
    },
    successAdd () {
      this.isShowAdd = false;
      this.search.pageIndex = 1;
      this.getList();
    },
    closeSet () {
      this.isShowSet = false;
    },
    successSet () {
      this.isShowSet = false;
      this.search.pageIndex = 1;
      this.getList();
    },
    getList () {
      let that = this;
      let params = {
        pageIndex: that.search.pageIndex - 1,
        pageSize: that.search.pageSize
      };
      GetCustomerChannelList(params).then(result => {
        if (result.code == 0) {
          let list = result.data.PageDataList;
          for (let i = 0; i < list.length; i++) {
            list[i].VolumeCount = Number(list[i].VolumeCount);
            list[i].TurnoverAmount = '￥' + Number(list[i].TurnoverAmount);
            list[i].TotalConcessionAmount = '￥' + Number(list[i].TotalConcessionAmount);
            list[i].AlreadyAmount = '￥' + Number(list[i].AlreadyAmount);
            list[i].ResolvedAmount = '￥' + Number(list[i].ResolvedAmount);
          }
          that.totalNum = result.data.Total;
          that.PageCount = result.data.PageCount;
          that.tableData = list;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
};
</script>

<style scoped>
</style>