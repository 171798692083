<template>
  <!-- 审批记录 -->
  <div class="authorization_courseware_manage">
    <div class="course_statistics_table_search flex">
      <date-range ref="saveDate"
                  style="align-items: center;margin-right: 20px;"
                  :searchObj="searchObj"
                  :fromTitle="'选择日期'"
                  @changeDate="changeSaveDate"></date-range>
      <div class="table_select_box"
           style="margin-right: 20px; min-width: 192px">
        <div class="table_select_title">事项</div>
        <el-select :value="vTypeKeyValue"
                   placeholder="请选择"
                   style="width: 210px"
                   ref="resetText"
                   @change="selectFluxPackageType">
          <el-option v-for="item in TypeList"
                     :key="item.value"
                     :label="item.label"
                     :value="item">
          </el-option>
        </el-select>

      </div>
      <div class="option_btn">
        <div class="import_investor btn_hover_bg_blue"
             @click="exportClick">导出</div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list class="summarizing_list"
                  :tableData="vdataReportList"
                  :tableColumns="vTableCourseColumns"
                  :key="tableKey"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :queryParams="searchObj"
                  :footerContent="vFooterTotal"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import tableList from '../../common/table-list/index';
import {
  SearchPageWriteOffApplyList
} from '../../../API/workbench';
export default {
  components: {
    tableList
  },
  data () {
    return {
      TypeList: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '欠款销账',
          value: 1
        },
        {
          label: '报名作废',
          value: 2
        }

      ],
      // 查询参数
      searchObj: {
        startTime: this.$utils.getCurrentMonthFirst(), //	字符串	必须		数据源：GET	起始时间
        endTime: this.$utils.getCurrentMonthLast(), //	字符串	必须		数据源：GET	结束时间
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        sequence: 'DESC',
        orderBy: '',
        TypeKey: 0,
        TypeKeyValue: ''
      },
      dataReportList: [],
      PageCount: 0,
      totalNum: 0,
      tableKey: '0',
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '事项',
          prop: 'TypeKeyValue',
          minWidth: 90,
          align: 'left',
          type: 'textItem'
        },

        {
          label: '对象',
          width: 120,
          prop: 'MainDemoName',
          type: 'md-name',
          extend: {
            mendianID: 'SaaSClientNO',
            click: (rowData) => {
              this.viewClick(rowData);
            }
          }
        },
        {
          label: '申请原因',
          prop: 'Reason',
          minWidth: 120,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '申请人',
          prop: 'ApplyKeyValue',
          minWidth: 90,
          width: ' ',
          type: 'textItem'
        },
        {
          label: '申请时间',
          prop: 'ApplyTime',
          minWidth: 140,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '审批人',
          prop: 'AuditKeyValue',
          minWidth: 88,
          align: 'left',
          type: 'textItem'
        },

        {
          label: '审批时间',
          prop: 'AuditTime',
          sortable: 'custom',
          minWidth: 140,
          width: ' ',
          type: 'textItem'
        }
      ]
    };
  },
  created () {
    this.getDataList();
  },
  mounted () {
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        o.MainDemoName = o.MainDemoName + '     ' + Number(o.AmountCount) + '元';
        newArr.push(o);
      });
      return newArr;
    },
    vTableCourseColumns () {
      return this.tableCourseColumns;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    },
    vTypeKeyValue () {
      return this.searchObj.TypeKey == 0
        ? '全部'
        : this.searchObj.TypeKeyValue;
    }
  },
  methods: {
    viewClick (item) {
      console.log(item, 'item', item.KDSYKey);
      if (item.TypeKey == 1) {
        this.$dialog.open(this, {
          name: '学生档案',
          routerName: this.$route.name,
          moduleName: 'studentFile',
          data: { studentID: item.StudentKey }
        });
      } else if (item.TypeKey == 2) {
        this.$dialog.open(this, {
          name: '报名收款',
          routerName: this.$route.name,
          moduleName: 'signuppayment',
          moduleDescription: '学员报名购买课程，如未登记的学生可点界面“+”新增学生信息后再进行报名。',
          data: { dataInfo: { StudentKey: item.StudentKey, TableID: item.KDSYKey, isReadOnly: true } },
          callBack: { afterSucces: () => { this.reload(); } }
        });
      }
    },
    getDataList () {
      SearchPageWriteOffApplyList(this.searchObj).then(result => {
        console.log(result.data, 'SearchPageWriteOffApplyList');
        this.dataReportList = result.data.PageDataList;
        this.totalNum = result.data.Total;
      }).catch(error => {
        console.log('errorSearchPageWriteOffApplyList', error.msg);
      });
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.getDataList();
    },
    selectFluxPackageType (item) {
      this.searchObj.TypeKeyValue = item.label;
      this.searchObj.TypeKey = item.value;
      console.log(item, 'item', this.searchObj);
      this.getDataList();
    },
    // 日期
    searchDate (startDate, endDate) {
      this.searchObj.startTime = startDate;
      this.searchObj.endTime = endDate;
      this.getDataList();
    },
    // 导出
    exportClick () {
      let obj = this.$utils.parseJson(this.searchObj);
      if (this.dataReportList.length > 0) {
        this.searchObj.IsExportKey = 1;
        this.searchObj.pageIndex = 0;
        this.searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '审批记录统计',
          clist: [
            { title: '学生名', cName: 'MainDemoName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '金额', cName: 'AmountCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '申请类型', cName: 'TypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '申请原因', cName: 'Reason', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '申请人', cName: 'ApplyKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '申请时间', cName: 'ApplyTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '审批人', cName: 'AuditKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '审批时间', cName: 'AuditTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        SearchPageWriteOffApplyList(this.searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出', this.$store.getters.APIURL + result.data.url);
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.searchObj.IsExportKey = 0;
          this.searchObj.pageIndex = obj.pageIndex;
          this.searchObj.pageSize = obj.pageSize;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    }
  }
};
</script>

<style>
.authorization_courseware_manage .table_select_box .el-select {
  width: 200px;
}
.authorization_courseware_manage
  .course_statistics_title
  .title_date_select
  .el-input
  input {
  width: 190px;
}

.authorization_courseware_manage
  .cw_summarizing
  .top_content_list
  .list_content
  .list_num {
  text-overflow: clip;
}
.option_btn {
  display: flex;
  margin-left: 270px;
}
.import_investor {
  height: 35px;
  width: 56px;
  line-height: 35px;
  text-align: center;
  align-items: center;
  border-radius: 4px;
}
</style>

