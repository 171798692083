<template>
  <div>
    <div class="form_info_edit content_marginTop_30 form_info_list single_shadow content_marginRL pr">

        <input-text
          :formTitle="'处理原因'"
          style="width: 100%;border-bottom:none"
          :readonly="false"
          :formPlaceholder="'选填'"
          :required="true"
          v-model="submitData.Reason"
        ></input-text>

    </div>
    <!-- 按钮组 -->
    <div>
      <save-cancel-btn-group
        :isSingleSubmit="true"
        :singleSubmitType="'dynamic'"
        @confirmClick="confirmSave"
        @cancelClick="closeDialog"
      ></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      submitData:{
        Reason:''
      }
    };
  },
  computed:{
    
  },
  props:{
  },
  methods: {
      confirmSave(callback){
        if(!this.submitData.Reason){
          layer.alert("请输入处理原因")
          if(callback){
            callback()
          }
        }else{
           this.$emit('afterSuccess',this.submitData.Reason);
           this.$emit('close');
        }
         
      },
      closeDialog(){
          this.$emit('close');
      }
  },
};
</script>