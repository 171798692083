<template>
  <div class="JXT_dynamic_list index_dynamic"> 
    <div class="JXT_dynamic_content">
      <div class="dynamic_content_body JXT_inform_box pr">
        <!-- 作业浏览内容 -->
        <div class="dynamic_content_title">
          {{dataInfo.Title}}
        </div>
        <div>
          <browse-item-content :dataInfo="dataInfo"></browse-item-content>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import browseItemHead from '../common-component/browse-box/browse-item-head';
import browseItemContent from '../common-component/browse-box/browse-item-content';
// import taskCorrectItem from './task-correct-item';
import releaseBtn from '../common-component/release-btn';
// 作业浏览项
export default {
  components: {
    browseItemHead,
    browseItemContent,
    // taskCorrectItem,
    releaseBtn
  },
  data () {
    return {};
  },
  props: {
    dataInfo: {
      type: Object, //
      default: function () {
        return {
          // ID
          // 作业标题
          // 发布人
          // 发布时间
          // 发布文本内容
          // 发布的附件内容
          // 接收学生对象
          // 完成人数
          // 批改人数
        };
      }
    },
    headBtnType: {
      type: String,
      default: ''
    },
    contentTextButton: {
      type: String,
      default: ''
    },
    IsAllSchool: {
      type: Number,
      default: 2
    } // 是否切换全校模式，模式 1-全校;2-普通
  },
  computed: {
    vDateMonthView () {
      if (this.dataInfo.ClassTime) {
        let month = this.dataInfo.ClassTime.slice(5, 7);
        month = Number(month) >= 10 ? Number(month) : this.dataInfo.ClassTime.slice(6, 7);
        return month;
      } else {
        return '';
      }
    },
    vDateDayView () {
      if (this.dataInfo.ClassTime) {
        let day = this.dataInfo.ClassTime.slice(8, 10);
        return day;
      } else {
        return '';
      }
    },
    vCompleteNum () {
      if (this.dataInfo.CompleteNum && this.dataInfo.PublishedNum) {
        return (
          parseInt(this.dataInfo.CompleteNum) +
          '/' +
          parseInt(this.dataInfo.PublishedNum)
        );
      } else {
        return (
          parseInt(this.dataInfo.workFinishNum) +
          '/' +
          parseInt(this.dataInfo.workNum)
        );
      }
    },
    vNoCorrectNum () {
      if (this.dataInfo.NoCorrectNum) {
        return parseInt(this.dataInfo.NoCorrectNum);
      } else if (this.dataInfo.NoWorkCheckNum) {
        return parseInt(this.dataInfo.NoWorkCheckNum);
      } else {
        return 'null';
      }
    }
  },
  methods: {
    // 头部操作按钮(作业详情)
    doHeadBrowseButClick (submitBtnName) {
      this.$emit('edit', this.dataInfo, submitBtnName);
    },
    // 作业批改进度页面
    doTaskProgress () {
      this.$router.push({
        name: 'taskCorrect',
        query: { taskID: this.dataInfo.ID }
      });
    },
    // 作业操作按钮(作业批改)
    correctTaskButClick () {
      this.$router.push({
        name: 'taskCorrect',
        query: { taskID: this.dataInfo.ID }
      });
    }
  }
};
</script>

<style>
</style>