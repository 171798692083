<template>
  <div>
    <!-- 学生验证提示 -->
    <div class="student_verify_popup_box">
      <div class="popup_box_content">
        <div class="popup_box_content_text">
          微信端新手机号注册客户，请及时验证。<br /> 未验证的客户学生，不能进行排课考勤。
        </div>
        <div class="popup_box_content_bottom clearfix">
          <div @click="confirmStudentVerifyForm"
               class="popup_box_content_btn">
            确认为新学生
          </div>
          <div @click="mergeStudentVerifyForm"
               class="popup_box_content_btn">
            合并到老学生
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ConfirmedStudentsMerge } from '../../../API/workbench';
export default {
  data () {
    return {

    };
  },
  components: {
  },
  props: {
    studentInfo: Object,
    isStudentVerifyPopup: { // 是否显示
      type: Boolean,
      required: false
    }
  },
  created () {

  },
  watch: {

  },
  methods: {
    // 关闭窗口
    closeVerifyPopup () {
      this.$emit('closeVerifyPopup');
      // 多例模式通知机制
      this.$emit('closeDialog');
    },
    // 登记为新生
    confirmStudentVerifyForm () {
      ConfirmedStudentsMerge(this.studentInfo.OLAPKey).then(result => {
        this.studentInfo.IsConfirmKey = 1;
        this.$emit('closeVerifyPopup', this.studentInfo.OLAPKey);
        // 多例模式通知机制
        this.$emit('afterSuccess');
        this.$emit('closeDialog');
        this.$bus.emit('AfterStudentUpdate', this.studentInfo.OLAPKey, 'verify');
        layer.alert('验证成功');
      });
    },
    // 合并为老生
    mergeStudentVerifyForm () {
      this.$emit('closeVerifyPopup');
      this.$emit('closeDialog');
      let moduleInfo = {
        name: '选老生合并',
        routerName: this.$route.name,
        routertitle: '',
        ModulePowerKey: 48,
        moduleName: 'mergeStudent',
        data: { studentKey: this.studentInfo.OLAPKey }
      };
      this.$bus.emit('needOpenRouter', moduleInfo);
    }
  }
};
</script>
<style>
</style>