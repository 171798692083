<template>
  <div class="list_student" @click.stop="seletedStuItem" :class="vOpt"  @mouseover.stop="mouseOverNewCourseTable"
         @mousedown.stop="onCourseNameMousedown"
         @onmouseup.stop="onCourseNameMouseup"
         @mouseout.stop="mouseOutNewCourseTable">
    <div class="list_student_name">{{studentItem.StudentKeyValue}}</div>
		<div class="list_student_number" v-if="Number(studentItem.SyncArrangedCount)>0"> {{Number(studentItem.SyncArrangedCount)}}</div>
    
  </div>
</template>
<script>
export default {
  data () {
    return {
      isMousedown:false,
       disabled: false,
    };
  },
  computed: {
    vLoop () {
      return this.studentItem.IsSyncArrangedKey == 1;
    }
  },
  props: {
    studentItem: Object,
    isDrag: Boolean,
    isOverDrag: Boolean,
    index: Number
  },
  computed:{
    // 输入框匹配学生
    vOpt () {
      return this.studentItem.isActive ? 'opt' : '';
    },
  },
  methods:{
     // 鼠标移入
    mouseOverNewCourseTable () {
      if (!this.isDrag) {
        if (this.isMousedown == false) {
          // clearTimeout(this.timer);
          this.studentItem.isMenu = true;
          this.$emit('doMouseEvent', 1, this.index, this.studentItem);
        }
      }
    },
    // 鼠标离开
    mouseOutNewCourseTable () {
      if (!this.isDrag) {
        // this.timer = setTimeout(() => {
        this.isMousedown = false;
        this.studentItem.isMenu = false;
        this.$emit('doMouseEvent', 0, this.index, this.studentItem);
        // }, 100);
      }
    },
    // 鼠标按下
    onCourseNameMousedown () {
      console.log('鼠标按下');
      this.isMousedown = true;
      this.studentItem.isMenu = false;
      this.studentItem.isEmptiness = true;
    },
    // 鼠标松开
    onCourseNameMouseup () {
      console.log('鼠标松开');
      this.isMousedown = false;
      this.studentItem.isMenu = false;
    },
    seletedStuItem(){
      console.log("seletedStuItem",this.studentItem)
      this.$emit("seletedStuItem",this.studentItem)
    }
  }
};
</script>