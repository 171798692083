<template>
  <!-- 课单列表 -->
  <div class="class_bill_list "
       :class="{opt:item.isActive}"
       @click="clickItem(item)">
    <div>
      <span class="class_bill_list_w">
        购买日期 :
        <span class="font_black">{{vbuyDate}}</span>
      </span>
      <span class="class_bill_list_w">
        购买金额 :
        <span class="font_black">{{$utils.mMoneyType(Number(item.ActualPayAmount),2)}}</span>
      </span>
    </div>
    <div>
      适用课名 :
      <span class="font_black">{{item.ApplyCorName}}</span>
    </div>
    <div class="class_bill_list_restrict">
      剩余数量丨天数丨价值 :
      <span class="font_black">{{Number(item.CurrentCount)}}{{item.UnitKeyValue || '课时'}}丨{{Number(item.PeriodCount)}}天丨{{$utils.mMoneyType(Number(item.ValueAmount),2)}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['courseList', 'item'],
  data () {
    return {
    };
  },
  components: {
  },
  computed: {
    // 负责显示购买日期
    vbuyDate () {
      if (this.item.PaidTime) {
        return this.item.PaidTime.replace(/-/g, '.');
      } else if(this.item.GenerateTime){
        return this.item.GenerateTime.slice(0, 10).replace(/-/g, '.');
      } else {
        return '';
      }
    }
  },
  created () {
  },
  methods: {
    // 点击获取选择项和选择高亮
    clickItem (item) {
      this.$store.commit('showAlerDialog', false);
      item.isActive = !item.isActive;// (多选高亮)
      this.$emit('courseItemAmountAdd');
    }
  }
};
</script>

  <style>
</style>