<template>
  <div>
    <div class="form_info_edit form_info_list">
      <input-text :formTitle="'方案名称'" :readonly="false" :required="true" v-model="name"></input-text>
      <input-dialog :formTitle="'活动类型'" :readonly="false" :required="true" :value="actType.MainDemoName" @showEldilog="showType"></input-dialog>
      <input-dialog :formTitle="'关联活动'" :readonly="false" :required="true" :value="actCoupon.MainDemoName" @showEldilog="showCoupon"></input-dialog>
      <input-number :formTitle="'返佣金额'" :readonly="false" :required="true" v-model="amount"></input-number>
      <form-select :formTitle="'返佣方式'" :readonly="false" :required="true" :dataItem="way.value" :dataList="wayList" v-model="way.value" @choseItem="wayChange"></form-select>
    </div>
    <save-cancel-btn-group :isSingleSubmit="true" @confirmClick="submit" @cancelClick="cancel"></save-cancel-btn-group>
  </div>
</template>

<script>
import {GetCouponList,AddChannelActivity} from '../../../API/workbench';
import formSelect from '../../form-item/form-select';
export default {
  data () {
    return {
      name:'',
      actType:{
        OLAPKey:'',
        MainDemoName:''
      },
      typeList:[
        {
          OLAPKey:'1',
          MainDemoName:'一码通活动-优惠券招生'
        },
        {
          OLAPKey:'2',
          MainDemoName:'一码通活动-引流套餐招生'
        },
        {
          OLAPKey:'3',
          MainDemoName:'一码通活动-拼团'
        },
        {
          OLAPKey:'4',
          MainDemoName:'一码通活动-阶梯团'
        },
        {
          OLAPKey:'5',
          MainDemoName:'一码通活动-员工分销招生'
        },
        {
          OLAPKey:'6',
          MainDemoName:'一码通活动-家长分销招生'
        }
      ],
      actCoupon:{
        OLAPKey:'',
        MainDemoName:''
      },
      couponList:[],
      amount:0,
      way:{
        key: '2',
        value: '成交后手动返佣'
      },
      wayList: [
        {
          key: '2',
          value: '成交后手动返佣'
        }
      ]
    };
  },
  components: {
    formSelect
  },
  props: {
  },
  created () {
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    showType () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '活动类型',
        moduleName: 'singleSelectionList',
        data: { selectedKey: this.actType.OLAPKey, dataSourceList: this.typeList, required: true },
        callBack: {
          onSelectedItem: (data) => { this.typeChange(data); }
        }
      });
    },
    typeChange (data) {
      this.actType.OLAPKey = data.OLAPKey;
      this.actType.MainDemoName = data.MainDemoName;
      this.actCoupon.OLAPKey = '';
      this.actCoupon.MainDemoName = '';
      this.couponList = [];
      GetCouponList(this.actType.OLAPKey).then(result => {
        if (result.code == 0) {
          this.couponList = result.data;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    showCoupon () {
      if (!this.actType.OLAPKey) {
        layer.alert('请先选择活动类型');
        return;
      }
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '关联活动',
        moduleName: 'singleSelectionList',
        data: { selectedKey: this.actCoupon.OLAPKey, dataSourceList: this.couponList, required: true },
        callBack: {
          onSelectedItem: (data) => { this.couponChange(data); }
        }
      });
    },
    couponChange (data) {
      this.actCoupon.OLAPKey = data.RelevanceActivityKey;
      this.actCoupon.MainDemoName = data.MainDemoName;
    },
    wayChange (item) {
      this.way.key = item.key;
      this.way.value = item.value;
    },
    submit () {
      let that = this;
      if (!that.name) {
        layer.alert('请填写方案名称');
        return;
      }
      if (!that.actType.OLAPKey) {
        layer.alert('请选择活动类型');
        return;
      }
      if (!that.actCoupon.OLAPKey) {
        layer.alert('请选择关联活动');
        return;
      }
      if (!that.amount) {
        layer.alert('请填写返佣金额');
        return;
      }
      if (!that.way.key) {
        layer.alert('请选择返佣方式');
        return;
      }
      let params = {
        MainDemoName: that.name,
        TypeKey: that.actType.OLAPKey,
        RelevanceActivityKey: that.actCoupon.OLAPKey,
        RelevanceActivityKeyValue: that.actCoupon.MainDemoName,
        RebateAmount:that.amount,
        RebateTypeKey: that.way.key,
        RebateTimeKey: 1,
        DelayedCount: 0
      };
      AddChannelActivity(params).then(result => {
        if (result.code == 0) {
          that.$emit('afterSuccess');
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    cancel () {
      this.$emit('cancelClick');
    }
  }
};
</script>

<style scoped>
</style>