<template>
  <!-- 门店列表 -->
  <div class="table_list_content summarizing_list">
    <div class="single_result_detail">

      <div class="pre_sales_list">
        <div class="customer_table_follow">
          <div class="customer_table_box">
            <div class="table_title">跟进</div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('FollowStartTime',vtoday)">
              <span class="font_gray">今天</span>
              <span class="table_number font_blue">{{reportStatistics.FollowTodayCount||0}}</span>
            </div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('FollowStartTime',vtomorrow)">
              <span class="font_gray">明天</span>
              <span class="table_number font_blue">{{reportStatistics.FollowTomorrowCount||0}}</span>
            </div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('FollowEndTime',vyesterday)">
              <span class="font_gray">过期</span>
              <span class="table_number font_red">{{reportStatistics.FollowExpiredCount||0}}</span>
            </div>
          </div>
          <div class="customer_table_box table_title_lists">
            <div class="table_title">未演示</div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('DemoStartTime',vtoday)">
              <span class="font_gray">今天</span>
              <span class="table_number font_blue">{{reportStatistics.TodayCount||0}}</span>
            </div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('DemoStartTime',vtomorrow)">
              <span class="font_gray">明天</span>
              <span class="table_number font_blue">{{reportStatistics.TomorrowCount||0}}</span>
            </div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('DemoEndTime',vyesterday)">
              <span class="font_gray">过期</span>
              <span class="table_number font_red">{{reportStatistics.ExpiredCount||0}}</span>
            </div>
          </div>
          <div class="customer_table_box">
            <div class="table_title">意向</div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('IntentionKey',1)">
              <span class="font_gray">S</span>
              <span class="table_number font_blue">{{reportStatistics.SCount||0}}</span>
            </div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('IntentionKey',2)">
              <span class="font_gray">A</span>
              <span class="table_number font_blue">{{reportStatistics.ACount||0}}</span>
            </div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('IntentionKey',3)">
              <span class="font_gray">B</span>
              <span class="table_number font_blue">{{reportStatistics.BCount||0}}</span>
            </div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('IntentionKey',4)">
              <span class="font_gray">C</span>
              <span class="table_number font_red">{{reportStatistics.CCount||0}}</span>
            </div>
          </div>
        </div>
        <div class="flex flex_space_between">
          <div class="customer_table_record"
               style="width:700px;">
            <div class="customer_table_box table_title_lists">
              <div class="table_title">演示记录</div>
              <div class="table_name">
                <span class="font_gray">昨天</span>
                <span class="table_number">{{reportStatistics.YesterdayFinishCount||0}}</span>
              </div>
              <div class="table_name">
                <span class="font_gray">今天</span>
                <span class="table_number">{{reportStatistics.TodayFinishCount||0}}</span>
              </div>
              <div class="table_name">
                <span class="font_gray">本周</span>
                <span class="table_number">{{reportStatistics.WeekCount||0}}</span>
              </div>
              <div class="table_name">
                <span class="font_gray">本月</span>
                <span class="table_number">{{reportStatistics.MonthCount||0}}</span>
              </div>
            </div>
            <div class="customer_table_box tc"
                 style="width:162px">
              <div class="table_title cp"
                   @click="doReportStatisticsClick('StatusKey',1)">待签
                <span class="table_number font_red">{{reportStatistics.WaitSignCount||0}}</span>
              </div>
            </div>
            <div class="customer_table_box tc"
                 style="width:162px">
              <div class="table_title cp"
                   style="width:auto !important;"
                   @click="doReportStatisticsClick('IsUseKey',1)">竞品
                <span class="table_number font_red">{{reportStatistics.CompetCount||0}}</span>
              </div>
            </div>
          </div>
          <div class="pr">
            <input-search-contain-btn v-model="searchText"
                                      style="margin-top: 7px;padding-right:76px"
                                      :width="'280px'"
                                      :placeholder="'请输入'"
                                      @clearSearch="clearExtendSearchParams"
                                      @onSearchEvent="search"></input-search-contain-btn>
            <div class="summarizing_number_right_btn">
              <div class=" el-button btn_light_blue btn_hover_bg_blue el-button--default"
                   style="width: 75px;"
                   @click="doAddCustomerClick()">添加</div>
            </div>
          </div>
        </div>
      </div>
      <table-list ref="tableListRef"
                  class="customer_mendian_list"
                  :tableData="tableData"
                  :tableColumns="tableColumns"
                  :rowKey="rowKey"
                  :totalNum="pageTotal"
                  :footerContent="'共' + pageTotal + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  @loadTableData="loadTableData"></table-list>
    </div>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
export default {
  data () {
    return {
      isTemporaryStu: false,  // 是否开启临时学生
      searchText: ''
    };
  },
  props: {
    reportStatistics: Object, // 统计
    tableData: Array, // 列表数据
    rowKey: String, // 显示详情对应绑定的ID
    pageTotal: Number, // 分页总共条数
    tableColumns: Array, // table column
    queryParams: Object,
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: '', order: 'ascending' };
      }
    }
  },
  components: {
    tableList,
    inputSearchContainBtn
  },
  computed: {
    modulePowerList () {
      return this.$store.getters.RolePowerList;
    },
    // 昨天的时间
    vyesterday () {
      var day1 = Date.new();
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000);
      let month = day1.getMonth() + 1;
      let date = day1.getDate();
      var s1 = day1.getFullYear() + '-' + (Number(month) < 10 ? '0' + month : month) + '-' + (Number(date) < 10 ? '0' + date : date);
      return s1;
    },
    // 今天的时间
    vtoday () {
      var day2 = Date.new();
      day2.setTime(day2.getTime());
      let month = day2.getMonth() + 1;
      let date = day2.getDate();
      var s2 = day2.getFullYear() + '-' + (Number(month) < 10 ? '0' + month : month) + '-' + (Number(date) < 10 ? '0' + date : date);
      return s2;
    },
    // 明天的时间
    vtomorrow () {
      var day3 = Date.new();
      day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000);
      let month = day3.getMonth() + 1;
      let date = day3.getDate();
      var s3 = day3.getFullYear() + '-' + (Number(month) < 10 ? '0' + month : month) + '-' + (Number(date) < 10 ? '0' + date : date);
      return s3;
    }

  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    // 添加客户信息
    doAddCustomerClick () {
      this.$emit('doAddCustomerClick', 'Add');
    },
    // 统计 搜索
    doReportStatisticsClick (type, value) {
      this.searchText = '';
      console.log(type, value, '统计搜索');
      this.$emit('searchStatistics', type, value);
      this.$refs.tableListRef.$refs.multipleTable.clearSort();
    },
    // 排序
    loadTableData (queryParams) {
      this.$emit('loadTableData', queryParams);
    },
    // 搜索
    search () {
      this.$emit('searchValue', this.searchText);
      this.$refs.tableListRef.$refs.multipleTable.clearSort();
    },
    // 清除条件
    clearExtendSearchParams () {
      this.$emit('clearSearchParams');
      this.$refs.tableListRef.$refs.multipleTable.clearSort();
    }
  }
};
</script>
<style scoped>
.search_top .el-select .el-input .el-select__caret {
  color: #333;
  font-size: 8px;
  position: relative;
  top: -1px;
  left: 13px;
  /* border-left: 1px solid #e6e6e6; */
}
.table_title_lists .table_title {
  width: 80px !important;
}
</style>

