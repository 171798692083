<template>
  <div class="form_info_detail form_info_serach_box">
    <!-- 搜索选择层 -->
    <div class="defult_dialog_searchform">
      <div class="search_form">
        <input type="text"
               class="search_input"
               placeholder="请输入"
               v-model="searchText">
        <span class="remove_btn"
              v-show="searchText.length > 0"
              @click.stop="clearSearText"></span>
      </div>
    </div>
    <!-- 搜索结果 nonBtnForList hasBtnForList-->
    <div class="search_result_content nonBtnForList">
      <div class="search_result_list">
        <div v-for="(item,index) in vDataSourceLsit" :key="index"
             class="single_result single_class_info"
             :class="{'active':item.isActive==true}">
          <div @click.stop="doAfterSelectionItem(item)">
            <div class="single_result_info_detail">
              <div class="student_info_name">
                <span style="padding-left: 15px;width:100px">{{item.MainDemoName}}</span>
                <span>{{item.Mobile}}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="vDataSourceLsit.length==0">
          <div class="monitoring_content_nodata"
               style="margin-top:160px">
            <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
          </div>
        </div>
      </div>

    </div>
    <!-- 按钮组 -->
    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :btnText="'提交'"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="doAfterClickConfirmBtn"
                             @cancelClick="doAfterClickCancelBtn"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { GetSalesAgentForSaaSClient } from '../../../../../API/workbench';
export default {
  name: 'singleSelectionList',
  data () {
    return {
      searchText: '',
      selectedItem: {}, // 选中项
      dataSourceLsit: []
    };
  },
  props: {
    selectionKey: [String, Number] // 可根据key找到当前项
  },
  components: {
    // singleSelectionItem
  },
  watch: {
    dataSourceLsit (n, o) {
      this.selectedItem = n.find(o => { return o.OLAPKey == this.selectionKey; });
    }
  },
  created () {
    this.getSalesAgentForSaaSClient();
  },
  methods: {
    // 获取指定门店销售
    getSalesAgentForSaaSClient () {
      GetSalesAgentForSaaSClient(1, 1).then(result => { // 空-表示所有 0-否;1-是
        result.data.forEach(o => {
          if (this.selectionKey == o.OLAPKey) {
            this.$set(o, 'isActive', true);
          } else {
            this.$set(o, 'isActive', false);
          }
        });
        this.dataSourceLsit = result.data;
        console.log('获取指定门店销售', this.dataSourceLsit);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 选中项
    doAfterSelectionItem (item) {
      console.log(item, 'itemitem');
      this.selectedItem = item;
      this.dataSourceLsit.forEach(o => {
        if (this.selectedItem.OLAPKey == o.OLAPKey) {
          this.$set(o, 'isActive', true);
        } else {
          this.$set(o, 'isActive', false);
        }
      });
    },
    // 确定按钮
    doAfterClickConfirmBtn () {
      this.searchText = '';
      this.$emit('selectedItem', this.selectedItem);
    },
    // 取消按钮
    doAfterClickCancelBtn () {
      this.searchText = '';
      this.$emit('doAfterClickCancelBtn');
    },
    // 清除按钮
    clearSearText () {
      this.searchText = '';
    }
  },
  computed: {
    vDataSourceLsit () {
      let newList = [];
      let trueSearchText = this.searchText.toLowerCase();
      this.dataSourceLsit.forEach(o => {
        if (o.MainDemoName && (o.MainDemoName.toLowerCase().indexOf(trueSearchText) >= 0 ||
          (o.FirstLetterName && o.FirstLetterName.toUpperCase().indexOf(trueSearchText.toUpperCase()) >= 0))) {
          if (o.OLAPKey == this.selectionKey) {
            newList.push(o);
          } else {
            newList.push(o);
          }
        }
      });
      return newList;
    }
  }
};
</script>


