<template>
  <div>
    <div class="form_info_edit form_info_list"
         style="height: 220px">

      <input-select :formTitle="'数据库服务器类型'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :selectedInfo="vSourceTypeInfo"
                    :dataList="sourceType"
                    @selectedItem="changeSourceType"></input-select>
    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { GetDatabaseServeSaaSClientList } from '../../../../../API/workbench';
export default {
  data () {
    return {
      sourceType: [],
      dataInfo: {
        TypeKey: '', // "" ,
        name: '',
        id: ''
      }
    };
  },
  components: {

  },
  props: {
    mendianInfo: Object // 门店信息
  },
  created () {
    this.getSubscribeCourse();
    this.dataInfo.name = this.mendianInfo.name;
    this.dataInfo.TypeKey = this.mendianInfo.TypeKey;
    this.dataInfo.id = this.mendianInfo.id;
    console.log(this.mendianInfo, 'this.mendianInfo', this.dataInfo);
  },
  computed: {
    vSourceTypeInfo () {
      return {
        id: this.dataInfo.TypeKey,
        name: this.dataInfo.name
      };
    }
  },
  methods: {
    // 选择类型
    changeSourceType (obj) {
      console.log(obj, '选择来源方式');
      this.dataInfo.TypeKey = obj.TypeKey;
      this.dataInfo.name = obj.name;
      this.dataInfo.id = obj.id;
      console.log(obj, '选择来源方式', this.dataInfo);
    },
    getSubscribeCourse () {
      GetDatabaseServeSaaSClientList().then(result => {
        this.sourceType = result.data;
        console.log(result.data, 'getSubscribeCourse', this.sourceType);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 提交
    submit (callback) {
      console.log('submit', this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      this.$emit('UpdataDatabaseServer', this.dataInfo);
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
      if (!this.dataInfo.name) {
        layer.alert('请选择数据库服务类型');
        flag = false;
      }
      return flag;
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
