<template>
  <!-- 课程表 -->
  <div class="margin_left_right_div">
    <div class="template_minOrmax_width_div">
      <div style="min-width: 1260px;max-width: 1900px;width: auto;padding:20px 38px;"
           class="width_fixed clearfix"
           ref="courseTableMainBody">
        <div v-show="showBox"
             class="course_table_box appoint_course_box"
             style="width: 79%;">
          <div class="course_table_top_fixed">
            <div class="course_table_top_fixed_shadow">
              <div class="course_table_header"
                   style="position: relative; height: 66px">
                <!-- <div @click="todayTableInfoList(Date.new())"
                 class="course_header_inner1 btn_hover_white"
                 :class="{btn_hover_bg_blue:toWeek}">今天</div> -->
                <div class="course_header_center pr">
                  <!-- <div class="course_header_date_box fl">
                <div class="course_header_inner4">日历</div>
                <input-date class="course_header_date"
                            :formTitle="''"
                            :popperClass="'course_date_el'"
                            @changeDate="todayTableInfoList"></input-date>
              </div> -->

                  <div @click="searchSevenDayAgo"
                       class="course_header_inner_pre"></div>
                  <div class="course_header_inner2">
                    <input-date class="course_header_date"
                                :formTitle="''"
                                :popperClass="'course_date_el'"
                                @changeDate="todayTableInfoList"></input-date>
                    <span>{{ vSeletedDateStart }}</span>
                    <!-- <span>{{vMonthWeek}}</span> -->
                  </div>

                  <icon-btn @clickItem="searchSevenDayAfter"
                            :className="'course_header_inner_next'">
                  </icon-btn>
                </div>

                <div class="fr">
                  <div class="appoint_course_header_btn"
                       @click.stop="changeSubscribeCourseType('1')">
                    已达成:
                    <span style="color:#24DA7E">{{ ReachNum }}</span>
                  </div>
                  <div class="appoint_course_header_btn"
                       @click.stop="changeSubscribeCourseType('2')">
                    未达成:
                    <span style="color: #FF8723">{{ NotReachNum }}</span>

                  </div>
                  <div class="appoint_course_header_btn">
                    <function-btn :item="addcoursebtn"
                                  :className="'course_header_inner12'"
                                  @clickItem="showNewCourse">
                      新建
                    </function-btn>
                  </div>
                  <div class="appoint_course_header_btn">
                    <function-btn :item="addcoursebtn"
                                  :className="'course_header_inner11'"
                                  @clickItem="showSetting">
                      设置
                    </function-btn>
                  </div>
                  <div class="appoint_course_header_btn"
                       @click="todayTableInfoList(seletedDate)">
                    <div class="course_header_inner10">刷新</div>
                  </div>
                  <!-- <div @click="todayTableInfoList(seletedDate)"
                   class=" course_header_fr_btn btn_hover_white ">刷新</div> -->

                  <el-switch v-model="isAllShow"
                             :width="86"
                             active-color="#3498DB"
                             inactive-color="#3498DB">
                  </el-switch>
                </div>
              </div>
              <div class="course_table_header header_color">
                <div class="header_menu"></div>
                <div class="header_swiper">
                  <div class="swiper-container"
                       id="header">
                    <div class="swiper-wrapper">
                      <div v-for="(item, index) in vPresentDateList"
                           :key="index"
                           class="swiper-slide swiper-no-swiping">
                        <div class="appoint_swiper-slide">
                          <div class="font_date"
                               :class="{ font_blue: item.today }">
                            {{ item.today ? "今天" : disposeDate(item.date) }}
                          </div>
                          <div class="font_week"
                               :class="{ font_blue: item.today }">
                            {{ setWeek(item.date) }}
                          </div>
                        </div>
                        <!-- <div class="appoint_swiper-slide">
                      <div class="font_gray">
                        {{ item.AppointmentCount }}/{{ item.FullPeopleCount }}
                      </div>
                    </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="course_table_content clearfix"
               @scroll="scrollEvent">
            <div class="content_date">
              <div v-for="item in vTimeHeight"
                   :key="item.date"
                   :style="{ height: item.height + 'px' }"
                   v-if="item.scope && (!isAllShow || item.height > 0)">
                {{ item.date }}
              </div>
            </div>
            <div class="content_swiper">
              <div class="swiper-container"
                   id="content">
                <div class="swiper-wrapper">
                  <div v-for="(item, index) in vPresentDateList"
                       :key="index"
                       class="swiper-slide swiper-no-swiping"
                       v-if="vTimeHeight.length >= 23">
                    <!--  :class="{ course_today_color: item.today }" -->
                    <div v-for="(item1, index1) in item.dateInfo"
                         :key="index1"
                         class="date_block"
                         :class="hidePoint(index1, item) ? '' : 'hide_cursor'"
                         :style="{ height: vTimeHeight[index1].height + 'px' }"
                         v-if="
                    vTimeHeight[index1].scope &&
                    (!isAllShow || vTimeHeight[index1].height > 0)
                  ">
                      <!--  @click.stop="newScheduleCourse(index1,item,$event)" -->
                      <div v-for="(item2, index2) in item1"
                           :key="index2"
                           :id="'course_' + item2.OLAPKey"
                           :class="courseClass(item2)"
                           @mouseover.stop="showPopup(item2, item, $event)"
                           @mouseout.stop="clearTimeOut"
                           @click="courseClick(item2)"
                           class="course_table_block">
                        <div v-if="item2.isEmpty">
                          <div class="course_table_block_isEmpty"></div>
                          <div class="course_table_block_isEmpty_text">
                            暂无课程
                          </div>
                        </div>
                        <div v-else>
                          <div class="teacher_date">
                            {{ item2.BeginClassTime }}-{{ item2.EndClassTime }}
                          </div>
                          <div class="course_name">
                            {{ item2.GradeClassKeyValue }}
                          </div>
                          <div class="course_name font_blue">
                            {{ item2.MTeacherKeyValue }}
                          </div>
                          <div class="course_name font_gray">
                            开课人数: {{ Number(item2.StartCourseCount) }}
                          </div>
                          <div class="course_name">
                            <span class="font_red"
                                  v-if="
                            Number(item2.AppointmentCount) ==
                            Number(item2.FullPeopleCount)
                          ">满</span>
                            <span class="font_green"
                                  v-else-if="
                            Number(item2.AppointmentCount) >=
                            Number(item2.StartCourseCount)
                          ">到</span>
                            <span class="font_yellow"
                                  v-else>约</span>
                            <span>
                              {{ Number(item2.AppointmentCount) }}/{{
                            Number(item2.FullPeopleCount)
                          }}</span>
                            <span v-if="Number(item2.ScheduleCourseKey) > 0"
                                  class="course_name_lock"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <pop-up-drop-down-box v-show="isShowNewCourseType"
                                :style="{ zIndex: 10 }"
                                :class="{pf:isPopFixed}"
                                :isShowDialog="isShowNewCourseType"
                                :dropDownList="newScheduleCourseObjType"
                                :positionLeft="positionLeft"
                                :positionTop="positionTop"
                                @onClick="doAfterSelectedNewappointmentCourseType"
                                @mouseover.native="isShowNewCourseType = true"
                                @mouseleave.native="closeNewappointmentCourse"></pop-up-drop-down-box>

          <pop-up-drop-down-box v-show="isShowMenuList"
                                :style="{ zIndex: 10 }"
                                :class="{pf:isPopFixed}"
                                :isShowDialog="isShowMenuList"
                                :dropDownList="menuDropList"
                                :positionLeft="positionLeft"
                                :positionTop="positionTop"
                                @onClick="doShowMenuList"
                                @mouseover.native="isShowMenuList = true"
                                @mouseleave.native="closeShowMenuList"></pop-up-drop-down-box>

          <el-popover-custom v-if="isShowNewCourseType"> </el-popover-custom>
          <!-- 亲，当前没有任何数据哦~显示 -->
          <div class="course_block_nodata_02"
               style="margin-top: 120px"
               v-show="noData && isAllShow">
            <div class="course_block_nodata_txt_02">亲，当前没有任何数据哦~</div>
          </div>
          <popup-box ref="AppointCoursePopup"
                     :courseTableData="optCourseData"
                     :vTop="vTop"
                     :vLeft="vLeft"
                     @seletedItem="seletedOptionItem"
                     @chgEditCourseShow="showEditCourse"
                     @chgCourseDetailShow="showCourseDetail"
                     @chgCourse="chgCourseUpdate"
                     @unAttendanceItem="toScheduleAtten"
                     @keepShow="keepShowAfter"
                     @closeShow="closeShowAfter"
                     @toScheduleCourse="toScheduleCourseItem"></popup-box>
          <course-popup-box ref="CoursePopup"
                            :courseTableData="optCourseData"
                            :vTop="vTop"
                            :vLeft="vLeft"
                            @chgEditCourseShow="showEditCourse"
                            @chgCourseDetailShow="showCourseDetail"
                            @chgCourse="chgCourseUpdate"
                            @unAttendanceItem="toScheduleAtten"
                            @keepShow="keepShowAfter"
                            @closeShow="closeShowAfter"
                            @toScheduleCourse="toScheduleCourseItem"></course-popup-box>
          <search-popup ref="searchPopup"
                        :condition="$route.query.name"
                        :type="type"
                        @restoreSearch="restoreSearch"></search-popup>
        </div>
        <rightSideSearch @seletedItem="seletedItemAfter"
                         @reloadAll="reload"></rightSideSearch>
        <dialog-factory ref="appointCourseDialogFactory"
                        :routerName="'appointmentCourse'"></dialog-factory>
        <dialog-factory-common ref="appointCourseDialogFactoryCommon"></dialog-factory-common>
      </div>
    </div>
  </div>
</template>
<script>
import rightSideSearch from './right-side-search';
import popupBox from './popup-box-new';
import coursePopupBox from './course-popup-box-new';
import searchPopup from './search-popup';
import dialogFactory from '../../common/dialog-factory';
import functionList from '../../common/function-list';
import popUpDropDownBox from '../../common/pop-up-drop-down-box';
import elPopoverCustom from '../../common/el-popover-custom';
import { getMonthWeek } from '../../../utils/index';
import {
  GetSubscribeCourseListByDate,
  GetScheduleCourseListByStudent,
  GetScheduleCourseListByTeacher,
  GetScheduleCourseListByClassType,
  SearchCourseForClass,
  SearchCourseForClassRoom,
  geTteacherList,
  EditAuxiliarySpeakForScheduleCourse,
  DeleteSubscribeCourse
} from '../../../API/workbench';
import { setIntervalCheck } from '../../../assets/index.js';
export default {
  name: 'appointmentCourse',
  data () {
    return {
      showBox: false,
      isShowEditCourses: false, // 修改单课显示
      isShowappointmentCourseRightBtn: true, // 助教排课模式关闭
      type: 0,
      searchId: '',
      toWeek: false,
      noData: true,
      isAllShow: false,
      isPopFixed: false,
      isShowClassDetail: false,
      timeTableInfoList: [], // 指定日期段所有数据列表
      dateList: [], // 日期数据列表
      optCourseData: {},
      headerSwiper: '',
      contentSwiper: '',
      seletedDate: '', // 显示的日期.
      searchBoxShow: false, // 新建课表显示查找框
      timer: '', // 延时器装载
      isMouseout: false, // 判断是否鼠标移入到课块中. 如果是则将click事件阻止.
      vTop: '',
      vLeft: '',
      isMouseOnPopUp: false,
      isShowSetClassTime: false,
      courseAttendanceKey: '',
      teacheList: [], // 教师列表
      selectAssistantTeacher: {}, // 选中的助教
      selectAssistantTeacherName: '', // 选中的助教名
      isShowMenuList: false,
      menuDropList: [
        {
          id: '1',
          name: '助教排课',
          ModulePowerKey: 47,
          isShow: true
        },
        {
          id: '2',
          name: '长假停课',
          ModulePowerKey: 47,
          isShow: true
        },
        {
          id: '3',
          name: '长假调课',
          ModulePowerKey: 47,
          isShow: true
        }
      ],
      newScheduleCourseObjType: [
        {
          id: '3',
          name: '日历建课',
          ModulePowerKey: 47,
          isShow: true
        },
        {
          id: '2',
          name: '新建循环课',
          ModulePowerKey: 47,
          isShow: true
        }
      ],
      isShowNewCourseType: false,
      positionTop: 0,
      positionLeft: 0,
      classTimeObj: {},
      isShowDialogFactory: false, // 显示弹出层
      importMoudle: { type: 1, moudleName: '导入学生' }, // 导入模板名,
      addcoursebtn: {
        name: '',
        type: '',
        ModulePowerKey: 47
      },
      beginDateTime: '',
      endDateTime: '',
      searchObj: {
        MTeacherKey: '',
        GradeClassKey: '',
        CourseNameKey: '',
        ClassroomKey: '',
        StudentKey: '',
        SubscribeCourseType: ''
      },
      NotReachNum: 0,
      ReachNum: 0
    };
  },
  components: {
    popupBox,
    searchPopup,
    rightSideSearch,
    dialogFactory,
    functionList,
    popUpDropDownBox,
    elPopoverCustom,
    coursePopupBox
  },
  created () {
    console.log('created-课程表', this.$route.params.data);
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
      this.searchId = this.$route.query.id;
    }
    if (this.$route.params.data && this.$route.params.data.dataInfo) {
      this.searchObj.SubscribeCourseType = this.$route.params.data.dataInfo.SubscribeCourseType;
    }
    this.contentSwiper = '';
    this.headerSwiper = '';
    this.$store.commit('setIsShowHeader', { booler: true });
    this.seletedDate = Date.new();
    this.todayTableInfoList(Date.new(), this.searchObj.SubscribeCourseType);
    document.addEventListener('click', (e) => {
      const ele = document.getElementsByClassName('alert_dialog')[0];
      if (e.target && !e.target.contains(ele)) {
        this.closeNewappointmentCourse();
      }
    });
    this.registerBusEvent();
    this.getTeacherList();
    this.setIntervalCheckCourse();
  },
  mounted () {
    this.contentSwiper = new Swiper('#content', {
      slidesPerView: 7,
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, // 修改swiper的父元素时，自动初始化swiper
      controller: {
        control: this.headerSwiper
      },
      on: {}
    });
    this.headerSwiper = new Swiper('#header', {
      slidesPerView: 7,
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, // 修改swiper的父元素时，自动初始化swiper
      noSwiping: true,
      controller: {
        control: this.contentSwiper
      }
    });

    this.swiperIndex(0);
    this.$nextTick((e) => {
      this.showBox = true;
    });
    console.log('appointmentCourse-created');
    window.addEventListener('scroll', this.handleScroll);
    window.updateDKB = () => {
      this.todayTableInfoList(this.seletedDate);
    };
    this.$dialog.register(this, ['classDetail', 'editCourse', 'editAppointCourse']);
  },
  activated () {
    let moduleName = this.$route.params.moduleName;
    if (moduleName) {
      this.openCustomDialogByFactoryDialog(this.$route.params);
    }
    this.getTeacherList();
  },
  destroyed () {
    document.removeEventListener('scroll', this.handleScroll);
    // this.$emit("afterSuccess")
  },
  watch: {
    isAllShow (n, o) {
      if (this.timeTableInfoList.length == 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    },
    vappointmentCourseData (n, o) {
      this.todayTableInfoList(this.seletedDate);
    }
  },
  computed: {
    visReachCount () {
      let num = 0;
      this.timeTableInfoList.forEach((o) => {
        if (Number(o.AppointmentCount) >= Number(o.StartCourseCount)) {
          num++;
        }
      });
      return num;
    },
    visnoReachCount () {
      let num = 0;
      this.timeTableInfoList.forEach((o) => {
        if (Number(o.AppointmentCount) < Number(o.StartCourseCount)) {
          num++;
        }
      });
      return num;
    },
    vappointmentCourseData () {
      return this.$store.getters.courseTableData;
    },
    // 显示的开始日期.
    vSeletedDateStart () {
      if (this.seletedDate) {
        let date = Date.new(this.seletedDate);
        let day = date.getDay() == 0 ? 7 - 1 : date.getDay() - 1;
        let startDate = Date.new(this.$utils.getNextDate(date, -day));
        let m;
        if (startDate.getMonth() + 1 > 9) {
          m = startDate.getMonth() + 1;
        } else {
          m = '0' + (startDate.getMonth() + 1);
        }
        return startDate.getFullYear() + '年' + m + '月';
      }
    },
    // 显示的结束日期.
    vSeletedDateEnd () {
      if (this.seletedDate) {
        let date = Date.new(this.seletedDate);
        let day = date.getDay() == 0 ? 7 - 1 : date.getDay() - 1;
        let startDate = Date.new(this.$utils.getNextDate(date, -day));
        let endDate = Date.new(this.$utils.getNextDate(Date.new(startDate), 6));
        if (
          startDate.getFullYear() == endDate.getFullYear() &&
          startDate.getMonth() == endDate.getMonth()
        ) {
          return endDate.getDate();
        } else if (startDate.getFullYear() != endDate.getFullYear()) {
          return (
            endDate.getFullYear() +
            '年' +
            (endDate.getMonth() + 1) +
            '月' +
            endDate.getDate()
          );
        } else {
          return endDate.getMonth() + 1 + '月' + endDate.getDate();
        }
      }
    },

    // 课程表数据
    vPresentDateList () {
      let today = this.$utils.formatDateToLine(Date.new());
      this.toWeek = false;
      let newDateList = this.$utils.parseJson(this.dateList);
      newDateList.forEach((e) => {
        e.dateInfo = [
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          []
        ];
        e.AppointmentCount = 0;
        e.FullPeopleCount = 0;
        e.StartCourseCount = 0;
        if (e.date == today) {
          e.today = true;
          this.toWeek = true;
        } else {
          e.today = false;
        }
      });
      this.timeTableInfoList.forEach((obj) => {
        let beginTime = Number(obj.BeginClassTime.split(':')[0]);
        let weekKey = Date.new(obj.ClassTime).getDay();
        weekKey = weekKey == 0 ? 6 : weekKey - 1;
        newDateList[weekKey].dateInfo[beginTime].push(obj);
        console.log('obj', obj);
        newDateList[weekKey].AppointmentCount += Number(obj.AppointmentCount);
        newDateList[weekKey].FullPeopleCount += Number(obj.FullPeopleCount);
        newDateList[weekKey].StartCourseCount += Number(obj.StartCourseCount);
        if (obj.ClassTime == today) {
          newDateList[weekKey].today = true;
          this.toWeek = true;
        } else {
          newDateList[weekKey].today = false;
        }
      });
      newDateList.forEach((e) => {
        e.dateInfo.forEach((i) => {
          i.forEach((o) => {
            if (o.OLAPKey == this.optCourseData.OLAPKey) {
              o.opt = true;
            } else {
              o.opt = false;
            }
          });
        });
        for (let i = 0; i < 24; i++) {
          if (e.dateInfo[i].length > 1) {
            e.dateInfo[i].sort(this.sortOLAPKey);
            e.dateInfo[i].sort(this.sortDate);
          }
        }
      });
      console.log(' this.dateList', newDateList);
      if (!this.$utils.getSaaSClientSetInfo(64).SetContent) {
        return false;
      }
      let newArr = this.commonSum(newDateList);
      // console.log("newArr",newArr)
      // 自动填补空格子
      newDateList.forEach((o) => {
        o.dateInfo.forEach((i, index) => {
          let maxlength = newArr[index].maxlength == 0 ? 1 : newArr[index].maxlength;
          if (i.length < maxlength) {
            let addLength = maxlength - i.length;
            // 显示全部的时候自动补充满
            if (!this.isAllShow) {
              for (var a = 0; a < addLength; a++) {
                i.push({
                  isEmpty: true,
                  ClassTime: o.date,
                  BeginClassTime: newArr[index].date,
                  OLAPKey: '0'
                });
              }
            } else if (this.isAllShow && newArr[index].height > 0) {
              // 选择精简的时候根据计算过的高度选择是否填充。
              for (var a = 0; a < addLength; a++) {
                i.push({
                  isEmpty: true,
                  ClassTime: o.date,
                  BeginClassTime: newArr[index].date,
                  OLAPKey: '0'
                });
              }
            } else {

            }
          }
        });
      });
      console.log(' this.dateList2222', newDateList);
      return newDateList;
    },
    vTimeHeight () {
      if (!this.$utils.getSaaSClientSetInfo(64).SetContent) {
        return false;
      }

      return this.commonSum(this.vPresentDateList);
    },
    // 获取当前第几周
    vMonthWeek () {
      let week = getMonthWeek(this.vPresentDateList[0].date);
      let weekValue = '';
      switch (String(week)) {
        case '1':
          weekValue = '第一周';
          break;
        case '2':
          weekValue = '第二周';
          break;
        case '3':
          weekValue = '第三周';
          break;
        case '4':
          weekValue = '第四周';
          break;
        case '5':
          weekValue = '第五周';
          break;
        case '6':
          weekValue = '第六周';
          break;
        default:
          weekValue = '第七周';
          break;
      }
      return weekValue;
    },
    vMendianInfo () {
      console.log(this.vPresentDateList, 'this.$store.getters.SaaSClientInfo');
      return this.$store.getters.SaaSClientInfo;
    },
    vRolePowerList () {
      return this.$store.getters.RolePowerList;
    }
  },
  methods: {
    commonSum (vPresentDateList) {
      let arr = [];
      let arrObj = [];
      let flag = true;
      vPresentDateList.forEach((e) => {
        for (let i = 0; i < 24; i++) {
          if (!arr[i]) {
            arr[i] = e.dateInfo[i].length;
          } else if (arr[i] < e.dateInfo[i].length) {
            arr[i] = e.dateInfo[i].length;
          }
        }
      });
      for (let j = 0; j < 24; j++) {
        if (arr[j] > 0) {
          if (arr[j] == 1) {
            arr[j] = arr[j] * 128 + 15;   // arr[j] * 128 + 15;
          } else {
            arr[j] = arr[j] * 138 + 15;   // arr[j] * 128 + 15;
          }
          flag = false;
        } else {
          arr[j] = 0;
        }
        let d = '';
        switch (j) {
          case 0:
            d = '00:00';
            break;
          case 1:
            d = '01:00';
            break;
          case 2:
            d = '02:00';
            break;
          case 3:
            d = '03:00';
            break;
          case 4:
            d = '04:00';
            break;
          case 5:
            d = '05:00';
            break;
          case 6:
            d = '06:00';
            break;
          case 7:
            d = '07:00';
            break;
          case 8:
            d = '08:00';
            break;
          case 9:
            d = '09:00';
            break;
          case 10:
            d = '10:00';
            break;
          case 11:
            d = '11:00';
            break;
          case 12:
            d = '12:00';
            break;
          case 13:
            d = '13:00';
            break;
          case 14:
            d = '14:00';
            break;
          case 15:
            d = '15:00';
            break;
          case 16:
            d = '16:00';
            break;
          case 17:
            d = '17:00';
            break;
          case 18:
            d = '18:00';
            break;
          case 19:
            d = '19:00';
            break;
          case 20:
            d = '20:00';
            break;
          case 21:
            d = '21:00';
            break;
          case 22:
            d = '22:00';
            break;
          case 23:
            d = '23:00';
            break;
          default:
            break;
        }
        let lastTime = this.$utils
          .getSaaSClientSetInfo(65)
          .SetContent.split(':');
        if (lastTime[1] > 0) {
          lastTime = lastTime[0];
        } else {
          lastTime = lastTime[0] - 1;
        }
        if (
          Number(d.split(':')[0]) >=
          Number(
            this.$utils.getSaaSClientSetInfo(64).SetContent.split(':')[0]
          ) &&
          Number(d.split(':')[0]) <= lastTime
        ) {
          arrObj.push({
            height: arr[j],
            maxlength: arr[j] == 0 ? 0 : (arr[j] - 15) / 128,
            date: d,
            scope: true
          });
        } else {
          arrObj.push({
            height: arr[j],
            maxlength: arr[j] == 0 ? 0 : (arr[j] - 15) / 128,
            date: d,
            scope: false
          });
        }
      }
      this.noData = flag;
      return arrObj;
    },
    testClick () {
      console.log('this.dateList', this.dateList);
    },
    sortOLAPKey (a, b) {
      return a.CourseNameKey - b.CourseNameKey;
    },
    sortDate (a, b) {
      return a.BeginClassTime.split(':')[1] - b.BeginClassTime.split(':')[1];
    },
    closeAssistantTeacher () {
      this.isShowappointmentCourseRightBtn = true;
      this.selectAssistantTeacher = {};
      this.selectAssistantTeacherName = '';
    },
    courseClick (item) {
      if (item.isEmpty) {
        return false;
      }
      if (!this.isShowappointmentCourseRightBtn) {
        // 助教模式才可触发
        if (!this.selectAssistantTeacher.OLAPKey) {
          layer.alert('请先选择助教老师');
          return false;
        }
        if (this.selectAssistantTeacher.OLAPKey == item.MTeacherKey) {
          layer.alert('选择教师为此节课的主讲老师，不可设置为助教');
          return false;
        }
        let isTeacher;
        let ATeaNameKeyArr = [];
        let ATeaNameArr = [];
        if (item.ATeaKeyName && item.ATeaKeyName.length > 2) {
          ATeaNameKeyArr = item.ATeaKeyName.split(',');
          ATeaNameArr = item.ATeaName.split(',');
          isTeacher = ATeaNameKeyArr.findIndex((id) => {
            return id == this.selectAssistantTeacher.OLAPKey;
          });
        }
        if (isTeacher > -1) {
          ATeaNameKeyArr.splice(isTeacher, 1);
          ATeaNameArr.splice(isTeacher, 1);
        } else {
          ATeaNameKeyArr.push(this.selectAssistantTeacher.OLAPKey);
          ATeaNameArr.push(this.selectAssistantTeacher.MainDemoName);
        }
        let courseItme = {
          ATeaKeyName: '',
          ATeaName: ''
        };
        ATeaNameKeyArr.forEach((e) => {
          courseItme.ATeaKeyName = courseItme.ATeaKeyName + e + ',';
        });
        ATeaNameArr.forEach((e) => {
          courseItme.ATeaName = courseItme.ATeaName + e + ',';
        });
        courseItme.ATeaKeyName = courseItme.ATeaKeyName.substring(
          0,
          courseItme.ATeaKeyName.length - 1
        );
        courseItme.ATeaName = courseItme.ATeaName.substring(
          0,
          courseItme.ATeaName.length - 1
        );
        EditAuxiliarySpeakForScheduleCourse(
          item.OLAPKey,
          courseItme.ATeaKeyName,
          courseItme.ATeaName
        ).then(
          (res) => {
            item.ATeaKeyName = res.data.ATeaKeyName;
            item.ATeaName = res.data.ATeaName;
          },
          (err) => {
            layer.alert(err.msg);
          }
        );
      }
    },
    doShowMenuList (item) {
      this.closeShowMenuList();
      if (item.id == 1) {
        this.isShowappointmentCourseRightBtn = false;
      } else if (item.id == 2) {
        this.showHolidaysUspendClasses();
      } else if (item.id == 3) {
        this.openCustomDialogByFactoryDialog({
          moduleName: 'holidaysAdjustClasses'
        });
      }
    },
    closeShowMenuList () {
      this.isShowMenuList = false;
    },
    clickAssistantTeacher (item) {
      this.selectAssistantTeacherName = item.MainDemoName;
      this.selectAssistantTeacher = item;
    },
    getTeacherList () {
      geTteacherList().then(
        (res) => {
          this.teacheList = res.data;
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    // 定时检查是否有课从未开始-》可签到
    setIntervalCheckCourse () {
      setIntervalCheck(() => {
        this.timeTableInfoList.forEach((o) => {
          let starttime =
            o.ClassTime.replace(/-/g, '/') + ' ' + o.BeginClassTime; // 开始时间
          let endtime = o.ClassTime.replace(/-/g, '/') + ' ' + o.EndClassTime; // 开始时间
          let startClassTime = Date.new(starttime).getTime() / 1000 / 60;
          let endClassTime = Date.new(endtime).getTime() / 1000 / 60;
          let curTime = Date.new().getTime() / 1000 / 60;
          if (o.StatusKey == 2 && startClassTime - curTime <= 0) {
            // 未来的课    // 当前课上课时间-当前时间 < 0 变为上课中
            o.StatusKey = 3;
          } else if (o.StatusKey == 3 && endClassTime - curTime <= 0) {
            // 当前课下课时间-当前时间 < 0 变为已结束
            o.StatusKey = 4;
          }
        });
      });
    },
    courseClass (data) {
      let c = '';
      if (data.StatusKeyType == 1) {
        return 'end_class';
      } else if (data.isEmpty) {
        if (Date.new().getTime() >= Date.new(data.ClassTime + ' ' + data.BeginClassTime).getTime()) {
          return 'end_class';
        } else {
          return 'noend_class';
        }
      } else {
        return 'noend_class';
      }

      return c;
    },
    showSetting () {
      this.openCommonCustomDialogByFactoryDialog({
        moduleName: 'appointmentCourseSetting',
        data: { dataInfo: this.classTimeObj },
        callBack: {
          afterSuccess: () => {
            this.getDataList();
          }
        }
      });
    },
    showNewCourse () {
      this.openCommonCustomDialogByFactoryDialog({
        moduleName: 'newAppointmentCourse',
        data: { dataInfo: this.classTimeObj },
        callBack: {
          afterSuccess: () => {
            this.getDataList();
          }
        }
      });
    },
    textLength (text) {
      // if (document.documentElement.getElementsByClassName('course_name')[0]) {
      // let domWidth = 149;
      // let len = text.replace(/[\u0391-\uFFE5]/g, 'aa').length;
      // if (len * 11.5 > domWidth) {
      //   return true;
      // } else {
      //   return false;
      // }
      // }
      if (text.length > 7) {
        return true;
      } else {
        return false;
      }
    },
    // 选择全部重新加载
    reload (type) {
      this.type = 0;
      this.todayTableInfoList(this.seletedDate);
    },
    // 获取指定日期的数据
    todayTableInfoList (date, SubscribeCourseType) {
      this.seletedDate = Date.new(date);
      var day =
        this.seletedDate.getDay() == 0 ? 7 - 1 : this.seletedDate.getDay() - 1;
      this.beginDateTime = this.$utils.getNextDate(this.seletedDate, -day);
      this.endDateTime = this.$utils.getNextDate(
        Date.new(this.beginDateTime),
        6
      );
      this.dateList = this.$utils.getDateParagraph(
        this.beginDateTime,
        this.endDateTime
      );
      console.log(this.dateList, 'this.dateList');
      this.getTimeTableInfoList(SubscribeCourseType);
    },
    showPopup (item, date, e) {
      if (item.isEmpty) {
        return false;
      }
      this.isMouseout = true;
      this.isMouseOnPopUp = true;
      this.closeNewappointmentCourse();
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (item) {
          item.opt = true;
          let seletedEle = document.getElementById('course_' + item.OLAPKey);
          let boxDom = document.getElementsByClassName(
            'course_table_content'
          )[0];
          this.isMouseout = false;
          console.log('showPopup');

          this.optCourseData = item;
          if (this.optCourseData.ScheduleCourseKey == 0) {
            this.$refs.CoursePopup.showBox = false;
            this.$refs.AppointCoursePopup.showGetData(item.OLAPKey);
          } else {
            this.$refs.AppointCoursePopup.showBox = false;
            this.$refs.CoursePopup.showGetData(item.ScheduleCourseKey);
          }

          // this.optCourseData.ScheduleCourseKey = item.OLAPKey;
          this.optCourseData.week = date.week;
          // 计算距离左侧距离及顶端的距离.
          this.vTop = Math.abs(
            boxDom.scrollTop -
            (seletedEle.offsetTop + 90 + seletedEle.offsetHeight)
          ); // 140为固定高度,52为该元件的高度
          let d = document.documentElement;
          let b = document.body;
          let domTop = seletedEle.offsetTop - d.scrollTop + 265;
          if (b.offsetHeight - domTop < 206) {
            this.vTop -= 230;
          }
          this.vTop += 'px';
          this.vLeft =
            seletedEle.parentElement.parentElement.offsetLeft + 91 + 'px'; // 100位固定的左边宽度.
        } else {
          console.log('没有触发');
        }
        clearTimeout(this.timer);
      }, 100);
    },
    clearTimeOut () {
      this.isMouseout = false;
      this.isMouseOnPopUp = false;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.isMouseOnPopUp || this.$refs.AppointCoursePopup.isShowNewCourseType || this.$refs.CoursePopup.isShowNewCourseType) {
          if (this.optCourseData.ScheduleCourseKey == 0) {
            this.$refs.AppointCoursePopup.showBox = true;
          } else {
            this.$refs.CoursePopup.showBox = true;
          }
          this.closeNewappointmentCourse();
        } else {
          this.optCourseData = {};
          this.$refs.CoursePopup.showBox = false;
          this.$refs.AppointCoursePopup.showBox = false;
        }
      }, 100);
    },
    closeShowAfter () {
      this.isMouseOnPopUp = false;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.isMouseOnPopUp) {
          if (this.optCourseData.ScheduleCourseKey == 0) {
            this.$refs.AppointCoursePopup.showBox = true;
          } else {
            this.$refs.CoursePopup.showBox = true;
          }
          this.closeNewappointmentCourse();
        } else {
          this.$refs.AppointCoursePopup.showBox = false;
          this.$refs.CoursePopup.showBox = false;
        }
      }, 100);
    },
    keepShowAfter (type) {
      this.isMouseOnPopUp = true;
    },
    swiperIndex (val) {
      this.headerSwiper.updateSlides();
      this.headerSwiper.slideTo(val);
    },
    setWeek (date) {
      return this.$utils.getWeekByTwo(date);
    },
    disposeDate (date) {
      // let arr = date.split('-');
      // return arr[1] + '-' + arr[2];
      return (
        this.$utils.formatDateStr(date, 'MM') +
        '-' +
        this.$utils.formatDateStr(date, 'dd')
      );
    },
    timeTableInfoData () {
      this.timeTableInfoList.forEach((e) => {
        e.MTeacherKeyValue = e.MTeacherKeyValue || '';
        e.MTeacherKeyValue = e.MTeacherKeyValue.split(',')[0];
        e.isTruantCount = e.ArrangedNumCount - e.ArrivedNumCount;
        if (Number(e.ArrangedNumCount) <= Number(e.FullPeopleCount)) {
          if (Number(e.AttenceCount) == 0 && Number(e.FullPeopleCount) == 0) {
            e.AttencePercentage = 0;
          } else {
            e.AttencePercentage = Math.ceil(
              (Number(e.AttenceCount) / Number(e.FullPeopleCount)) * 100
            );
          }
          if (
            Number(e.ArrangedNumCount) == 0 &&
            Number(e.FullPeopleCount) == 0
          ) {
            e.ArrangedPercentage = 0;
          } else {
            e.ArrangedPercentage = Math.ceil(
              (Number(e.ArrangedNumCount) / Number(e.FullPeopleCount)) * 100
            );
          }
        } else {
          if (Number(e.AttenceCount) == 0 && Number(e.ArrangedNumCount) == 0) {
            e.AttencePercentage = 0;
          } else {
            e.AttencePercentage = Math.ceil(
              (Number(e.AttenceCount) / Number(e.ArrangedNumCount)) * 100
            );
          }
          e.ArrangedPercentage = 100;
        }
      });
    },
    // 变更某节课的状态
    updateCourseStatus (courseInfo) {
      courseInfo.MTeacherKeyValue = courseInfo.MTeacherKeyValue || '';
      courseInfo.MTeacherKeyValue = courseInfo.MTeacherKeyValue.split(',')[0];
      courseInfo.isTruantCount =
        courseInfo.ArrangedNumCount - courseInfo.ArrivedNumCount;
      if (
        Number(courseInfo.ArrangedNumCount) <=
        Number(courseInfo.FullPeopleCount)
      ) {
        if (
          Number(courseInfo.AttenceCount) == 0 &&
          Number(courseInfo.FullPeopleCount) == 0
        ) {
          courseInfo.AttencePercentage = 0;
        } else {
          courseInfo.AttencePercentage = Math.ceil(
            (Number(courseInfo.AttenceCount) /
              Number(courseInfo.FullPeopleCount)) *
            100
          );
        }
        if (
          Number(courseInfo.ArrangedNumCount) == 0 &&
          Number(courseInfo.FullPeopleCount) == 0
        ) {
          courseInfo.ArrangedPercentage = 0;
        } else {
          courseInfo.ArrangedPercentage = Math.ceil(
            (Number(courseInfo.ArrangedNumCount) /
              Number(courseInfo.FullPeopleCount)) *
            100
          );
        }
      } else {
        if (
          Number(courseInfo.AttenceCount) == 0 &&
          Number(courseInfo.ArrangedNumCount) == 0
        ) {
          courseInfo.AttencePercentage = 0;
        } else {
          courseInfo.AttencePercentage = Math.ceil(
            (Number(courseInfo.AttenceCount) /
              Number(courseInfo.ArrangedNumCount)) *
            100
          );
        }
        courseInfo.ArrangedPercentage = 100;
      }
    },
    changeSubscribeCourseType (type) {
      // this.SubscribeCourseType = type;
      this.searchObj.SubscribeCourseType = type;
      this.getDataList();
    },
    getDataList () {
      GetSubscribeCourseListByDate(
        this.beginDateTime,
        this.endDateTime,
        this.searchObj
      ).then((res) => {
        if (res.code == 0) {
          this.NotReachNum = Number(res.data.NotReachNum);
          this.ReachNum = Number(res.data.ReachNum);
          this.timeTableInfoList = res.data.list;
          this.timeTableInfoData();
        }
      });
    },
    // 获取指定日期的课程数据
    getTimeTableInfoList (SubscribeCourseType) {
      this.searchObj = {
        MTeacherKey: '',
        GradeClassKey: '',
        CourseNameKey: '',
        ClassroomKey: '',
        StudentKey: '',
        SubscribeCourseType: SubscribeCourseType || ''
      };
      if (this.searchId > 0) {
        let typeName = '';
        if (this.type == 1) {
          typeName = 'StudentKey';
        } else if (this.type == 2) {
          typeName = 'MTeacherKey';
        } else if (this.type == 3) {
          typeName = 'CourseNameKey';
        } else if (this.type == 4) {
          typeName = 'ClassroomKey';
        } else if (this.type == 5) {
          typeName = 'GradeClassKey';
        }
        this.searchObj[typeName] = this.searchId;
      }
      this.getDataList();
    },
    showSearch () {
      this.$refs.searchPopup.showBox = true;
    },
    restoreSearch () {
      this.type = 0;
      this.searchId = '';
      this.$router.push({ path: 'coursetable' });
      this.todayTableInfoList(Date.new());
    },
    // 查找7天前
    searchSevenDayAgo () {
      // let date = this.seletedDate.toLocaleDateString();
      this.seletedDate = Date.new(
        this.$utils.getNextDate(this.seletedDate, -7)
      );
      console.log(this.seletedDate, 'searchSevenDayAgo');
      this.todayTableInfoList(this.seletedDate);
    },
    // 查找7天后
    searchSevenDayAfter () {
      this.seletedDate = Date.new(this.$utils.getNextDate(this.seletedDate, 7));
      console.log(this.seletedDate, 'searchSevenDayAfter');
      this.todayTableInfoList(this.seletedDate);
    },
    // 课修改更新.
    chgCourseUpdate () {
      this.todayTableInfoList(this.seletedDate);
    },
    // 弹出隐藏修改单课
    showEditCourse () {
      // this.isShowEditCourses = booler;
      let target = Number(this.optCourseData.ScheduleCourseKey) > 0 ? 'editCourse' : 'editAppointCourse';
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: target,
        data: { courseInfo: this.optCourseData },
        callBack: {
          afterSuccess: () => {
            this.todayTableInfoList(this.seletedDate);
          }
        }
      });
      this.$refs.AppointCoursePopup.showBox = false;
      this.$refs.CoursePopup.showBox = false;
    },
    hiddenEditCourse () {
      this.isShowEditCourses = false;
    },
    // 显示课程明细.
    showCourseDetail () {
      // this.isShowClassDetail = true;
      // this.$nextTick(() => {
      //   this.$refs.classDetail.showCourseDetails = true;
      // });
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'classDetail',
        data: { courseInfo: this.optCourseData },
        callBack: {
          afterSucces: () => {
            this.chgCourseUpdate();
          }
        }
      });
    },
    // 安排考勤
    toScheduleAtten (item, index) {
      let moduleInfo = {
        name: '安排考勤',
        moduleName: 'attendanceArrange',
        routerName: this.$route.name,
        data: { scheduleCourseKey: item.OLAPKey }
      };
      this.openCustomDialogByFactoryDialog(moduleInfo);
      // this.$refs.newAttendanceArrangeDialog.isAttendanceArrangeShow = true;
      // this.courseAttendanceKey = item.OLAPKey;
      // this.$refs.newAttendanceArrangeDialog.getCourseInfo(item.OLAPKey);
    },
    // 时间已过的课程不可点击
    compareDate (i, item) {
      let curDate = Date.new();
      let curTime = this.$utils.formatDateToLine(curDate);
      let classDate = this.$utils.formatDateToLine(Date.new(item.date));
      if (curTime > classDate) {
        return false;
      } else if (classDate > curTime) {
        return true;
      } else {
        let classTime = this.newTime(i);
        let time = classTime.split('-')[0];
        let curHour = curDate.getHours();
        let curMinute = curDate.getMinutes();
        let classHour = Number(time.split(':')[0]);
        let classMinute = curDate.getMinutes();
        if (classHour <= curHour) {
          return false;
        } else if (classHour > curHour) {
          return true;
        } else {
          if (classMinute < curMinute) {
            return false;
          } else {
            return true;
          }
        }
      }
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vRolePowerList, ModulePowerKey);
    },
    hidePoint (i, item) {
      let classTimeRange = this.newTime(i);
      let classTime = item.date + ' ' + classTimeRange.split('-')[0];
      let b = this.$utils.CheckModulePower(this.vRolePowerList, 47);
      // console.log(b, '权限');
      if (
        Date.new(
          Date.new(classTime).toLocaleDateString() +
          ' ' +
          classTimeRange.split('-')[0]
        ) < Date.new() ||
        !b
      ) {
        return false;
      } else {
        return true;
      }
    },
    // 空白区域进入- 新建课表
    newScheduleCourse (i, item, e) {
      if (this.vHasModulePower(47)) {
        this.closeNewappointmentCourse();
        if (this.isMouseOnPopUp) {
          return false;
        }
        let result = this.compareDate(i, item);
        if (!result) {
          return false;
        }
        this.mouseOverNewappointmentCourse(i, null, e);
        // let classTimeRange = this.newTime(i);
        let weekKey = Date.new(item.date).getDay();
        weekKey = weekKey == 0 ? 7 : weekKey;
        this.classTimeObj.frequenceInfo = [];
        this.classTimeObj.frequenceInfo.push(weekKey);
        this.classTimeObj.CircleStartTime = item.date;
        this.classTimeObj.ClassTime = item.date;
        // this.classTimeObj.SClassTimeName = classTimeRange.split('-')[0];
        // this.classTimeObj.EClassTimeName = '';
      }
    },
    // 鼠标移到新建课
    mouseOverNewappointmentCourse (i, item, e) {
      clearTimeout(this.timer);
      this.isPopFixed = false;
      if (e) {
        this.positionTop = e.pageY - 90;
        this.positionLeft = e.target.parentElement.offsetLeft + e.offsetX - 12;
      }
      this.isShowNewCourseType = true;
      if (this.isShowNewCourseType && e) {
        e.currentTarget.classList.add('border_red');
      }
    },
    // 新建课 选中新建课类型
    doAfterSelectedNewappointmentCourseType (item) {
      this.closeNewappointmentCourse();
      this.classTimeObj.CircleEndTime = '';
      if (item.id == 2) {
        this.openCustomDialogByFactoryDialog({
          moduleName: 'newCircleappointmentCourse',
          data: { dataInfo: this.classTimeObj }
        });
      } else {
        this.openCustomDialogByFactoryDialog({
          moduleName: 'newCourseByCalendar',
          data: { dataInfo: this.classTimeObj }
        });
      }
    },
    // 课块进入-新建课表
    toScheduleCourseItem (data, type) {
      this.classTimeObj.frequenceInfo = [];
      this.classTimeObj.CircleStartTime = data.ClassTime;
      let weekKey = Date.new(data.ClassTime).getDay();
      weekKey = weekKey == 0 ? 7 : weekKey;
      this.classTimeObj.frequenceInfo.push(weekKey);
      // this.classTimeObj.SClassTimeName = data.BeginClassTime;
      // this.classTimeObj.EClassTimeName = data.EndClassTime;
      this.doAfterSelectedNewappointmentCourseType({ id: type });
    },
    newTime (index) {
      let time = '';
      switch (index) {
        case 0:
          time = '00:00-01:30';
          break;
        case 1:
          time = '01:00-02:30';
          break;
        case 2:
          time = '02:00-03:30';
          break;
        case 3:
          time = '03:00-04:30';
          break;
        case 4:
          time = '04:00-05:30';
          break;
        case 5:
          time = '05:00-06:30';
          break;
        case 6:
          time = '06:00-07:30';
          break;
        case 7:
          time = '07:00-08:30';
          break;
        case 8:
          time = '08:00-09:30';
          break;
        case 9:
          time = '09:00-10:30';
          break;
        case 10:
          time = '10:00-11:30';
          break;
        case 11:
          time = '11:00-12:30';
          break;
        case 12:
          time = '12:00-13:30';
          break;
        case 13:
          time = '13:00-14:30';
          break;
        case 14:
          time = '14:00-15:30';
          break;
        case 15:
          time = '15:00-16:30';
          break;
        case 16:
          time = '16:00-17:30';
          break;
        case 17:
          time = '17:00-18:30';
          break;
        case 18:
          time = '18:00-19:30';
          break;
        case 19:
          time = '19:00-20:30';
          break;
        case 20:
          time = '20:00-21:30';
          break;
        case 21:
          time = '21:00-22:30';
          break;
        case 22:
          time = '22:00-23:30';
          break;
        case 23:
          time = '23:00-00:30';
          break;
        default:
          time = '08:30-09:30';
          break;
      }
      return time;
      // }
    },
    doAfterNewSucess () {
      this.todayTableInfoList(this.seletedDate);
    },
    seletedItemAfter (item, type) {
      console.log('seletedItemAfter', item, type);
      this.searchId = item.OLAPKey;
      this.type = type;
      this.todayTableInfoList(this.seletedDate);
    },
    // 鼠标离开新建课
    closeNewappointmentCourse () {
      let eleArr = document.querySelectorAll('.date_block');
      eleArr.forEach((element) => {
        element.classList.remove('border_red');
      });
      this.isShowNewCourseType = false;
    },
    scrollEvent () {
      this.closeNewappointmentCourse();
    },
    showHolidaysUspendClasses () {
      this.openCustomDialogByFactoryDialog({
        moduleName: 'holidayssuspendclasses'
      });
    },
    // 监听页面滚动
    handleScroll () {
      this.isShowNewCourseType = false;
    },
    // 根据课ID获取单节课
    getScheduleCourseByScheduleCourseKey (scheduleCourseKey, callBack) {
      GetScheduleCourseListByDate(
        this.beginDateTime,
        this.endDateTime,
        scheduleCourseKey
      ).then((result) => {
        if (result.code == 0) {
          if (typeof callBack == 'function') {
            callBack(result.data[0]);
          }
        }
      });
    },
    registerBusEvent () {
      // 新建课 成功后
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'appointmentCourse') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'appointmentCourse') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.appointCourseDialogFactory) {
        this.$refs.appointCourseDialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.appointCourseDialogFactoryCommon) {
        this.$refs.appointCourseDialogFactoryCommon.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    },
    seletedOptionItem (item) {
      console.log('seletedOptionItem', this.optCourseData);
      if (item.type == 1) {
        this.showArrangeCourse();
      } else if (item.type == 2) {
        this.showEditCourse();
      } else {
        this.cancelAppointCourse();
      }
    },
    // 去约课详情或者上课详情页面。
    showArrangeCourse () {
      if (Number(this.optCourseData.ScheduleCourseKey) > 0) {
        let moduleInfo = {
          name: '安排考勤',
          moduleName: 'attendanceArrange',
          routerName: this.$route.name,
          data: { scheduleCourseKey: this.optCourseData.ScheduleCourseKey },
          callBack: {
            afterSuccess: () => {
              this.todayTableInfoList(this.seletedDate);
            }
          }
        };
        this.openCustomDialogByFactoryDialog(moduleInfo);
      } else {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          moduleName: 'appointArrange',
          data: { courseKey: this.optCourseData.OLAPKey },
          callBack: {
            afterSuccess: () => {
              this.todayTableInfoList(this.seletedDate);
            }
          }
        });
      }
      this.$refs.AppointCoursePopup.showBox = false;
      this.$refs.CoursePopup.showBox = false;
    },
    // 取消约课
    cancelAppointCourse () {
      this.$refs.AppointCoursePopup.showBox = false;
      this.$refs.CoursePopup.showBox = true;

      layer.confirm('请问是否取消当前约课？', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              DeleteSubscribeCourse(this.optCourseData.OLAPKey)
                .then((result) => {
                  this.todayTableInfoList(this.seletedDate);
                })
                .catch((error) => {
                  layer.alert(error.msg);
                });
              layer.close();
            }
          }
        ]
      });
    }
  }
};
</script>
<style scoped>
.appoint_course_box .course_header_center {
  /* position: relative; */
  margin: 12px;
  height: 42px;
  width: 240px;
  background: #f5f7fa;
  border-radius: 100px;
  padding-top: 0px;
}
.appoint_course_box {
  padding-top: 85px;
}

.appoint_course_box .course_header_inner_pre {
  /* position: relative; */
  position: absolute;
  left: 20px;
  top: 5px;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background: url(../../../../public/image/arrow_icon_black_left@2x.png)
    no-repeat center;
  cursor: pointer;
  background-size: 10px;
}
.appoint_course_box .course_header_inner_next {
  /* position: relative; */
  position: absolute;
  right: 20px;
  top: 5px;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background: url(../../../../public/image/arrow_right_icon_black@2x.png)
    no-repeat center;
  cursor: pointer;
  background-size: 10px;
}
.appoint_course_box .course_header_center .course_header_inner2 {
  position: relative;
  line-height: 42px;
  height: 42px;
  width: 130px;

  margin-left: 55px;
}
.appoint_course_box
  .course_header_center
  .course_header_inner2
  .course_header_date {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.appoint_course_header_btn {
  width: 90px;
  height: 30px;
  margin-right: 15px;
  background-color: #f5f7fa;
  border-radius: 100px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}
.appoint_course_header_btn .course_header_inner10 {
  background: url(../../../../public/image/refresh@2x.png) no-repeat 15px center;
  background-size: 18px;
  padding-left: 18px;
}
.appoint_course_header_btn .course_header_inner11 {
  background: url(../../../../public/image/appoint_course_type04.png) no-repeat
    15px center;
  background-size: 18px;
  padding-left: 18px;
}
.appoint_course_header_btn .course_header_inner12 {
  background: url(../../../../public/image/arrange_icon01_norole.png) no-repeat
    15px center;
  background-size: 18px;
  padding-left: 18px;
}

.appoint_course_box .course_table_content {
  background-color: #fff;
  display: flex;
  width: 100%;
  /* height: 650px;
  overflow-y: auto; */
  margin-top: 35px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* @media screen and (max-width: 1440px) {
  .appoint_course_box .course_table_content {
    height: 500px !important;
  }
} */

.appoint_course_box .course_table_content .content_date {
  width: 55px;
  float: left;
}

.appoint_course_box .course_table_content .content_date > div {
  /* border-right: 1px solid #ebebeb; */
  border: none;
}

.appoint_course_box .course_table_content .content_date > div:last-child {
  /* border-bottom: 1px solid #ebebeb; */
  /* border: none; */
}

.appoint_course_box .course_table_content .content_swiper {
  float: left;
  width: 1001px;
  /* width: 95%; */
}

.appoint_course_box .course_table_content .content_swiper .swiper-container {
  /* min-width: 1065px; */
}
.appoint_course_box .course_table_header {
  height: 50px;
}
.course_table_header .swiper-slide {
  text-align: center;
}

.course_table_header .font_date {
  font-size: 15px;
  line-height: 49px;
  font-family: Arial;
  display: inline-block;
  vertical-align: top;
  color: #959ba2;
}

.course_table_header .font_week {
  font-size: 14px;
  line-height: 49px;
  display: inline-block;
  vertical-align: top;
  color: #959ba2;
}

.course_table_header .header_date_icon {
  position: absolute;
  height: 37.6px;
  right: 0;
  top: 0;
  width: 16px;
  background: #fff url(../../../../public/image/details_black@2x.png) no-repeat
    center center;
  background-size: 12.8px;
  z-index: 2;
}

#content.swiper-container {
  overflow-x: hidden;
  /* background-color: #fff; */
  height: auto;
}

.appoint_course_box .course_table_content .swiper-slide {
  height: auto;
}

.appoint_course_box .course_table_content .swiper-slide .date_block {
  padding: 5px;
  /* padding-top: 5px; */
  border-right: none;
  border-bottom: none;
  min-height: 128px;
  cursor: pointer;
}

.appoint_course_box .course_table_content .swiper-slide:last-child .date_block {
  border-right: none;
}

.appoint_course_box .course_table_content .swiper-slide .date_block.border_red {
  border: 1px solid #f85a59;
}

.appoint_course_box .course_table_content .course_table_block {
  height: 128px;
  /* border: 1px solid #ececec; */
  border: none;
  position: relative;
  /* background-color: #fff; */
  z-index: 9999;
  border-radius: 3px;
  margin-bottom: 14px;
  cursor: default !important;
  overflow: hidden;
  padding: 10px;
}

.appoint_course_box .course_table_content .course_table_block:last-child {
  margin-bottom: 0;
}

.appoint_course_box
  .course_table_content
  .swiper-slide
  .course_table_block.warning_icon::after {
  content: '';
  position: absolute;
  right: 1px;
  top: 1px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: url(../../../../public/image/course_block_icon.png) no-repeat;
}

.appoint_course_box .course_table_content .course_table_block.block_gray {
  cursor: default !important;
}

.appoint_course_box
  .course_table_content
  .course_table_block.tick_type::before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 28px;
  height: 26px;
  background: url(../../../../public/image/course_tick_green.png) no-repeat
    right center;
  pointer-events: none;
  z-index: 2;
}

.appoint_course_box .course_table_content .course_name {
  font-size: 14px;
  line-height: 15px;
  min-height: 20px;
  color: #333;
  padding-left: 20px;
  padding-right: 0px;
  /* word-break: break-all; */
  display: flex;
  align-items: center;
  width: 105px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.appoint_course_box .course_table_content .course_name .font_red {
  border: 1px solid #ff6c67;
  color: #ff6c67;
  border-radius: 2px;
  margin-right: 5px;
}
.appoint_course_box .course_table_content .course_name .font_green {
  border: 1px solid #24da7e;
  color: #24da7e;
  border-radius: 2px;
  margin-right: 5px;
}
.appoint_course_box .course_table_content .course_name .font_yellow {
  border: 1px solid #ff8723;
  color: #ff8723;
  border-radius: 2px;
  margin-right: 5px;
}
.appoint_course_box .course_table_content .course_name.font_gray {
  color: #7d8184;
}

.appoint_course_box .course_table_content .course_name > div {
  max-height: 30px;
  overflow: hidden;
}

.appoint_course_box .course_table_content .course_name.font_dual {
  font-size: 14px;
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.appoint_course_box .course_table_content .course_name.font_dual span {
  height: 32px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.appoint_course_box .course_table_content .teacher_date {
  font-size: 12px;
  line-height: 15px;
  color: #7d8184;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 20px;
  margin-bottom: 10px;
  background: url('../../../../public/image/appoint_course_type02.png') center
    left no-repeat;
  background-size: 15px;
}
.appoint_course_box .course_table_content .teacher_date::after {
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  right: 5px;
  top: 0px;
  background: url('../../../../public/image/menuBtn_gray_01.png') center center
    no-repeat;
  background-size: 15px;
}
.appoint_course_box .course_table_content .course_name .course_name_lock {
  position: absolute;
  right: 0;
  width: 18px;
  height: 18px;
  background: url('../../../../public/image/appoint_course_type03.png') center
    right no-repeat;
  background-size: 12px;
}

.appoint_course_box .course_table_content .course_number {
  font-size: 12px;
  line-height: 17px;
  padding-left: 10px;
  color: #7d8184;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.appoint_course_box .course_table_content .course_number_gray {
  background-color: #dedede;
  font-size: 12px;
  line-height: 17px;
  height: 17px;
  padding-left: 10px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.appoint_course_box .course_table_content .course_number_gray .font_green {
  color: #52c3a1;
}

.appoint_course_box .course_table_content .content_date > div {
  /* border-bottom: 1px solid #ebebeb; */
  font-size: 14px;
  /* line-height: 130px; */
  text-align: center;
  font-family: Arial;
  color: #959ba2;
  min-height: 128px;
  background: #fafafa;
  border: 5px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 10px solid #fff;
  border-bottom: 10px solid #fff;
}

.appoint_course_box .course_table_header .header_swiper .swiper-slide {
  height: 50px;
  border-right: 1px solid #ebebeb;
}

.appoint_course_box
  .course_table_header
  .header_swiper
  .swiper-slide:last-child {
  border-right: none;
}

.appoint_course_box .course_table_header .header_swiper .swiper-slide.today {
  color: #f85a59;
}
.appoint_course_box .course_table_header .el-switch {
  float: inherit;
  margin: 0;
}
.appoint_course_box .course_table_header .el-switch__core {
  height: 100%;
  border-radius: 100px;
  font-size: 14px;
}
.course_table_content .end_class {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
    #f9f9fb;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
.course_table_content .noend_class {
  background: #fafafa !important;
  border-radius: 4px;
}
.course_table_content .course_table_block_isEmpty {
  background: url(../../../../public/image/appoint_course_type01.png) no-repeat
    center;
  width: 100%;
  height: 65px;
  margin-top: 10px;
}
.course_table_block_isEmpty_text {
  width: 100%;
  margin: auto;
  /* padding-top: 8px; */
  text-align: center;
  color: #999999;
}
.appoint_course_box .course_table_header >>> .el-switch__core {
  border-radius: 100px;
}
.appoint_course_box
  .course_table_header
  >>> .is-checked
  .el-switch__core::after {
  border-radius: 100px;
}
.appoint_course_box .course_table_header >>> .el-switch__core:after {
  border-radius: 100px;
}

#appointmentCourse .course_rightside {
  margin-left: 1110px;
}
#appointmentCourse .course_table_top_fixed {
  margin-left: -688px;
  padding-left: 0px;
}
#appointmentCourse .width_fixed {
  width: 1416px;
}
#appointmentCourse .course_table_top_fixed_shadow {
  width: 1090px;
}
#appointmentCourse .course_table_header .header_menu {
  width: 70px;
}
#appointmentCourse .course_table_content .content_date {
  width: 70px;
}
@media screen and (max-width: 1367px) {
  #appointmentCourse .course_table_top_fixed {
    margin-left: 20px !important;
  }
}
.swiper-slide {
  width: 14% !important;
}
.course_table_top_fixed {
  background-color: #fff !important;
  width: 100% !important;
  position: absolute !important;
  top: -20px !important;
  left: 0px !important;
  margin-left: 0 !important;
  z-index: 7 !important;
  padding-left: 0 !important;
}
.course_table_top_fixed_shadow,
.content_swiper {
  width: 100% !important;
}
.course_table_content {
  height: 1500px;
  overflow-y: auto;
  width: 100% !important;
}
.header_swiper {
  float: none !important;
  width: auto !important;
}
</style>
