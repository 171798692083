<template>
  <div class="course_student_attendance_operation module_content_menuList module_content_menuList_student"
       v-show="isShowDialog"
       :style="{'left':positionLeft+'px','top':positionTop+'px'}">
    <div class="top_bg"></div>
    <drop-down-box :optionJsonList="dropDownList"
                   @clickOptionForAlertDialog="doAfterClick"></drop-down-box>
  </div>
</template>

<script>
import dropDownBox from './drop-down-box';
export default {
  data () {
    return {
    };
  },
  props: {
    dropDownList: Array,
    positionLeft: Number,
    positionTop: Number,
    isShowDialog: Boolean
  },
  components: {
    dropDownBox
  },
  methods: {
    doAfterClick (item) {
      this.$emit('onClick', item);
    }
  }
};
</script>
<style>
</style>