<template>
  <div class="add_screening_condition_box fl">
    <span>{{dataModel.formTitle}} : </span>
    <el-date-picker v-for="(item,index) in dataModel.fieldList"
                    :key="index"
                    clear-icon="none"
                    v-model="item.value"
                    type="date"
                    :class="index == 0?'bordr_white':''"
                    placeholder="不限">
    </el-date-picker>
  </div>
</template>
<script>
export default {
  name: 'datePickerGroup',
  model: {
    prop: 'dataModel'
  },
  props: {
    dataModel: Object
  },
  created () {
  },
  methods: {
  }
};
</script>
