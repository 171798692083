const homeSchoolCommunication = {
  state: {
    unReadMesseageNum: 0, // 未读消息,
    currencyCount: 0, // 学生学分
    curPlayingAudio: '', // 当前播放的音频
    isCloseAudioPlay: '', // 是否关闭当前音频
    browseMessageRole: 2 // 1-查看全校;2-查看自己的消息
  },

  getters: {
    unReadMesseageNum: state => {
      return state.unReadMesseageNum;
    },
    currencyCount: state => {
      return state.currencyCount;
    },
    curPlayingAudio: state => {
      return state.curPlayingAudio;
    },
    isCloseAudioPlay: state => {
      return state.isCloseAudioPlay;
    },
    browseMessageRole: state => {
      return state.browseMessageRole;
    }
  },
  mutations: {
    setUnReadMesseageNum: (state, obj) => {
      state.unReadMesseageNum = obj;
    },
    setCurrencyCount: (state, num) => {
      state.currencyCount = num;
    },
    setCurPlayingAudio: (state, val) => {
      state.curPlayingAudio = val;
    },
    setCloseAudioPlay: (state, val) => {
      state.isCloseAudioPlay = val;
    },
    setBrowseMessageRole: (state, val) => {
      state.browseMessageRole = val;
    }
  }
};

export default homeSchoolCommunication;
