<template>
  <div>
    <div class="table_tbody_box"
         @scroll="scrollTop">
      <div class="table_tbody">
        <bodyRow v-for="(item,index) in tableData"
                 :rowNumber="(index+1)"
                 :key="item.randomNumber"
                 :rowData="item"
                 :courseNameList="vCourseNameList"
                 :isShowPart="isShowPart"
                 :tableData="tableData"
                 :scrollTopVal="scrollTopVal"
                 :SpecialFilter="SpecialFilter"
                 @onFocusEvent="afterFocusEvent"
                 @doAfterDownwardMoveEvent="doAfterDownwardMoveEvent"
                 @doAfterUpwardMoveEvent="doAfterUpwardMoveEvent"
                 @deleteRecord="afterDeleteRecord"></bodyRow>
      </div>
    </div>
    <div class="batchEnter_student_table_import">
      <div class="import_title">
        录入学生
      </div>
      <add-row ref="addRow"
               :courseNameList="vCourseNameList"
               :isShowPart="isShowPart"
               :tableData="tableData"
               :scrollTopVal="scrollTopVal"
               :SpecialFilter="SpecialFilter"
               @onFocusEvent="afterFocusEvent"
               @onImportBtnSave="onImportBtnSave"></add-row>

    </div>
  </div>

</template>

<script>
import bodyRow from './body-row';
import addRow from './add-row';
export default {
  data () {
    return {
      curSelectedRowData: null, // 当前编辑行
      scrollTopVal: 0, // 滚动高度
      selectRowDataType: '' // 用于新增或者编辑
    };
  },
  props: {
    isShowPart: Boolean,
    tableData: Array,
    SpecialFilter: Number,
    cacheParams: Object // 是否加载
  },
  components: {
    bodyRow,
    addRow
  },
  created () {
    let self = this;
    document.addEventListener('click', () => {
      if (document.activeElement == document.body) {
        self.blurForRow();
      }
    });
  },
  computed: {
    vCourseNameList () {
      let courseNameList = [];
      let courseNameData = this.$store.getters.courseNameList;
      if (courseNameData) {
        courseNameList = courseNameData.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; });
      }
      return courseNameList;
    }
  },
  watch: {
    vCourseNameList () {
      if (!this.vCourseNameList || this.vCourseNameList.length == 0) {
        this.loadCourseNameList();
      }
    }
  },
  methods: {
    // 整行失焦
    blurForRow () {
      if (this.curSelectedRowData && (!this.curSelectedRowData.optioningRow || !this.curSelectedRowData.focusField)) {
        let emptyErrorIndex = this.curSelectedRowData.ExceptionList.findIndex(obj => { return obj.TargetField == 9 && obj.TypeKey == 5; });

        if ((this.curSelectedRowData.guid || this.selectRowDataType == 'add') && this.curSelectedRowData.ApplyClassKey) {
          if (emptyErrorIndex < 0 && (this.curSelectedRowData.CurrentCount === '' || this.curSelectedRowData.CurrentCount === null)) {
            this.curSelectedRowData.ExceptionList.push({ TargetField: 9, TypeKey: 5, TypeKeyValue: '剩余课时未填写' });
          }
        }
        this.curSelectedRowData.optioningRow = false;
        this.curSelectedRowData.focusField = '';
        this.curSelectedRowData = null;
      }
    },
    scrollTop (e) {
      let ETarget = e.target;
      this.scrollTopVal = ETarget.scrollTop;
      if (ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight && !this.cacheParams.noMore) {
        this.$emit('doScrollPageLoadData');
      }
    },
    // 下拉失焦焦隐藏
    afterDropdownBlurEvent (rowData, focusField) {
      if (focusField == 'SexKeyValue') {
        rowData.newSexKeyValue = rowData.SexKeyValue;
      } else if (focusField == 'ApplyClassKeyValue') {
        rowData.newApplyClassKeyValue = rowData.ApplyClassKeyValue;
      }
    },
    // 聚焦
    afterFocusEvent (rowData, focusField, type) {
      if (this.curSelectedRowData) {
        this.$set(this.curSelectedRowData, 'optioningRow', false);
      }
      this.$set(rowData, 'optioningRow', true);
      this.curSelectedRowData = rowData;
      this.selectRowDataType = type;
    },
    // 新增
    onImportBtnSave (rowData) {
      if (rowData && rowData.StudentKeyValue) {
        this.$emit('onImportBtnSave', rowData, '');
      } else {
        layer.alert('学生名不能为空!');
      }
    },
    // 向下键盘事件
    doAfterDownwardMoveEvent (rowData, focusField) {
      let tabList = this.tableData.filter(obj => { return obj.guid; }).length;
      if (rowData.guid && rowData.Number < tabList) {
        rowData.Number = Number(rowData.Number);
        this.tableData[rowData.Number - 1].optioningRow = false;
        this.tableData[rowData.Number].focusField = focusField;
        this.tableData[rowData.Number].optioningRow = true;
      }
    },
    // 向上键盘
    doAfterUpwardMoveEvent (rowData, focusField) {
      if (rowData.guid) {
        rowData.Number = Number(rowData.Number);
        this.tableData[rowData.Number - 1].optioningRow = false;
        this.tableData[rowData.Number - 2].focusField = focusField;
        this.tableData[rowData.Number - 2].optioningRow = true;
      }
    },
    afterDeleteRecord (rowData, rowNumber) {
      this.$emit('deleteRecord', rowData, rowNumber);
    },
    // 获取课名列表
    loadCourseNameList () {
      this.$basicData.loadCourseNameList();
    }
  }

};
</script>

<style>
</style>