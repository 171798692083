<template>
  <div class="content_course_box">
    <div class="course_box_title">
      <div class="course_box_title_icon">课</div>
      <span>{{item.CourseNameKeyValue}}</span>
    </div>
    <div class="course_box_list_content">
      <courseRulesList ref="courseRulesList"
                       v-for="rulesItem in vRulesList"
                       :key="rulesItem.StructuredRuleKey"
                       :item="rulesItem"
                       :studentList="studentList"
                       :searchText="searchText"
                       :copyData="copyData"
                       :isTemporaryStu="isTemporaryStu"
                       :seletedStructuredRuleKey="seletedStructuredRuleKey"
                       @updateClassStudentList="getStudentArrangedListByGradeAndCourse"></courseRulesList>
    </div>
  </div>
</template>
<script>
import courseRulesList from '../../../course-arrangement/course-rules-list/index';
import { gradeCourseStudentArranged } from '../../../../../API/workbench';
export default {
  data () {
    return {
      studentList: []
    };
  },
  props: {
    item: Object,
    searchText: String,
    isTemporaryStu: {
      type: Boolean,
      default: false
    },
    copyData: {
      type: Object,
      default: {
        StructuredRuleKey: 0,
        copyStudentList: []
      }
    },
    seletedStructuredRuleKey: Number

  },
  components: {
    courseRulesList
  },
  created () {
    console.log('created,');
    this.getStudentArrangedListByGradeAndCourse();
  },
  watch: {
    'item.studentList': {
      handler (c, o) {
        if (c.length >= 0) {
          console.log(c, 'course-name-item', this.$refs.courseRulesList);
          this.getStudentArrangedListByGradeAndCourse();
          if (this.$refs.courseRulesList.length > 0) {
            this.$refs.courseRulesList.forEach(o => {
              o.updateRulesStuList();
            });
          }
        }
      }
    }
  },
  computed: {
    vRulesList () {
      return this.item.rulesList;
    }
  },
  methods: {
    // 获取该班级下所有排课规律学生.
    getStudentArrangedListByGradeAndCourse (callback) {
      gradeCourseStudentArranged(this.item.GradeClassKey, this.item.CourseNameKey).then(result => {
        this.studentList = this.$utils.parseJson(result.data);
        this.item.studentList = this.$utils.parseJson(result.data);
      });
    }
  }
};
</script>
<style scoped>
.course_box_list_content >>> .list_course_box {
  width: 98% !important;
}
</style>