<template>
  <div>
    <div class="head_box">
      <div class="head_box_left">
        <div class="left_box_li">
          <div class="title_teacher"
               v-if="isTeacherInto && teacherData.AuthCWPNum == 0">
            我的班级
          </div>
          <div v-else>
            <span class="box_li_left_span">
              <div class="left_span_img">
                <img v-if="Number(teacherData.OLAPKey) > 0"
                     class="teacher_img"
                     :src="headImg"
                     :onerror="defaultImg"
                     alt="../../../../../public/image/deflut_teacher_photo@2x.png" />
                <!-- <img :src="teacherData.XMLIcon" width="100%" height="100%"> -->
              </div>
            </span>
            <span class="box_li_right_span">
              <div class="right_span_div">
                <span class="right_span_txt"
                      :title="teacherData.MainDemoName">{{ teacherData.MainDemoName }}</span>
                <span class="right_span_txt2">{{ teacherData.Mobile }}</span>
              </div>
            </span>
          </div>
        </div>
        <div class="left_box_lis">
          <div v-if="!isTeacherInto">
            <div class="box_lis_top">
              已授权课包：{{ teacherData.AuthCWPNum }} &nbsp套
            </div>
            <div class="box_lis_buttom"
                 v-if="!vIsAuthor"
                 @click="addClassPackageList">
              授权课包
            </div>
          </div>
          <div v-else>
            <div class="box_lis_top">
              已授权课包：{{ teacherData.AuthCWPNum }}&nbsp套
            </div>
            <div class="box_lis_top2">
              &nbsp&nbsp&nbsp&nbsp上课班级：{{ classList.length }}&nbsp个
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div class="head_box_right">
        <ul class="box_right_ul"
            @scroll="scrollTOP">
          <class-li-item v-for="(item, key) in classList"
                         :key="key"
                         :keyIndex="key"
                         :item="item"
                         @seletedItem="seletedItemAfter"></class-li-item>
          <li @click="addClassForTeacher"
              class="box_right_lis">
            <span class="right_li_span">
              <div class="right_li_item">+</div>
              添加上课班级
            </span>
          </li>
        </ul>
        <div v-show="isShowDiv"
             class="course_student_attendance_operation module_content_menuList"
             :style="{
            top: topValue + 'px',
            left: leftValue + 'px',
            width: '95px',
            position: 'absolute',
            zIndex: '2',
          }"
             @mouseover.stop="isShowDiv = true">
          <div class="top_bg"></div>
          <drop-down-box :optionJsonList="typeList"
                         @clickOptionForAlertDialog="clickAfter"></drop-down-box>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassLiItem from "./class-li-item.vue";
import dropDownBox from "../../../common/drop-down-box";
export default {
  components: {
    ClassLiItem,
    dropDownBox,
  },
  props: ["teacherData", "classList", "isTeacherInto", "className"],
  data () {
    return {
      isShowDiv: false,
      topValue: 0, // 默认第一个top值
      toScrollTop: 0, // 滚动条的高度
      newIndex: -1,
      seletdItem: [],
      leftValue: 50,

      typeList: [
        {
          name: "班级详情",
          type: 1,
          isShow: true,
        },
        {
          name: "退出班级",
          type: 2,
          isShow: true,
        },
      ],
    };
  },
  computed: {
    vIsAuthor () {
      return this.$utils.getSaaSClientSetInfo(71).SetKey == '1'
    },
    defaultImg () {
      return (
        'this.src="' +
        require("../../../../../public/image/deflut_teacher_photo@2x.png") +
        '"'
      );
    },
    headImg () {
      // if (!this.teacherData.XMLIcon) {
      //   this.teacherData.XMLIcon = "";
      // }
      // if (this.teacherData.XMLIcon.indexOf("http") > -1) {
      //   return this.teacherData.XMLIcon;
      // } else {
      //   return this.$store.getters.CDNURL + this.teacherData.XMLIcon;
      // }


      if (this.teacherData && this.teacherData.XMLIcon) {
        if (this.teacherData.XMLIcon.indexOf('http') > -1) {
          return this.teacherData.XMLIcon;
        } else {
          return this.$store.getters.CDNURL + this.teacherData.XMLIcon;
        }
      } else {
        return '';
      }
    },
  },
  watch: {
    toScrollTop: {
      handler (c, o) {
        // if (c>o){
        //   this.topValue = this.topValue - (c-o)
        //   // if (this.topValue > 200) {
        //   //   this.isShowDiv = false
        //   // }
        // }else {
        //   this.topValue = this.topValue - (c-o)
        //   // if (this.topValue > 200) {
        //   //   this.isShowDiv = false
        //   // }
        // }

        this.isShowDiv = false;
      },
    },
  },
  created () {
    document.querySelector("body").addEventListener(
      "click",
      (e) => {
        this.isShowDiv = false;
      },
      false
    );
  },
  mounted () { },
  methods: {
    scrollTOP (e) {
      // this.isShowDiv = false
      this.toScrollTop = e.target.scrollTop;
      if (e.target.scrollTop > 0) {
        this.isShowdropDownBox = false;
      }
    },
    addClassForTeacher () {
      this.$emit("addClassForTeacher");
    },
    getClassDetail (item) {
      this.$emit("getClassDetail", item);
    },
    quitClass (item) {
      this.$emit("quitClass", item);
    },
    addClassPackageList () {
      this.$emit("addClassPackageList");
    },
    clickAfter (item) {
      this.isShowDiv = false;
      if (item.type == 1) {
        this.$emit("getClassDetail", this.seletdItem);
      } else {
        this.$emit("quitClass", this.seletdItem);
      }
    },
    seletedItemAfter (item, key, e) {
      console.log(e);
      // this.typeList.forEach(o => { o.isShow = true; });
      this.seletdItem = item;
      this.isShowDiv = true;
      this.doOneTopValueCount(key, e);
    },
    doOneTopValueCount (index, e) {
      console.log(e, "el.style.top");
      console.log(index, "index");
      // this.newIndex = index;
      this.leftValue = 0;
      // let left = (index + 1) % 4;
      // if (left == 0) {
      //   left = 4;
      // }
      // this.leftValue = 45 + left * 8;
      if (this.className != '') {
        if (e.layerX < 0) {
          this.leftValue = e.layerX + 385
        } else if (e.layerX > 0) {
          this.leftValue = e.layerX + 385
        }
      } else {
        this.leftValue = e.layerX - 55
      }
      console.log(e.path[0].offsetLeft, 'e.path[0].offsetLeft')
      console.log(e.path[0].offsetTop, 'e.path[0].offsetTop')
      console.log(this.toScrollTop, 'this.toScrollTop')
      this.topValue = 0;
      // if (this.className == "teacher_detail_dialog") {
      this.leftValue = e.path[0].offsetLeft + 15
      this.topValue = e.path[0].offsetTop + 40 - this.toScrollTop
      // } else {
      // this.leftValue = e.path[0].offsetLeft+15
      // this.topValue = e.path[0].offsetTop+50
      // }

      // console.log(this.leftValue, "leftValue");
      // this.topValue = 0;
      let oneTopValue = 140;
      // this.topValue = e.layerY + 15
      // if (this.toScrollTop < 60 && index < 4) {
      //   console.log(this.toScrollTop, "doOneTopValueCount2");

      // } else if (this.toScrollTop < 60 && index >= 4 && index < 8) {
      //   console.log(this.toScrollTop, "doOneTopValueCount1");
      //   this.topValue = e+40;
      // } else {
      //   this.topValue = e+40;
      // }
    },
  },
};
</script>
<style scoped>
.head_box {
  margin-top: 20px;
  height: 124px;
  width: 815px;
  border-radius: 8px;
  background: #f1f7ff;
}
.head_box_left {
  float: left;
  height: 124px;
  width: 144px;
  border-radius: 8px;
  background-image: url('../../../../../public/cw-img/background_teacher.png');
}
.head_box_right {
  float: right;
  width: 670px;
  height: 124px;
  /* position: relative; */
}
.left_box_li {
  width: 100%;
  height: 62px;
}
.left_box_lis {
  width: 100%;
  height: 62px;
}

.box_li_left_span {
  display: block;
  float: left;
  width: 48px;
  height: 62px;
}
.box_li_right_span {
  display: block;
  float: right;
  width: 96px;
  height: 62px;
}
.left_span_img {
  width: 30px;
  height: 30px;
  margin: 14px 8px 0 10px;
  border-radius: 50%;
}
.right_span_div {
  margin-top: 10px;
  height: 39px;
  width: 95px;
}
.right_span_txt {
  display: inherit;
  width: 85px;
  height: 19px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.right_span_txt2 {
  width: 28px;
  height: 19px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.right_span_txt1 {
  width: 93px;
  height: 20px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: block;
  align-items: center;
  color: #ffffff;
}
.box_lis_top {
  width: 144px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  padding-top: 7px;
}
.box_lis_top2 {
  width: 144px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  /* text-align: center; */
  padding-top: 7px;
}
.box_lis_top1 {
  width: 144px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  padding-top: 7px;
}
.box_lis_buttom {
  width: 76px;
  height: 24px;
  text-align: center;
  background: linear-gradient(0deg, #fea409, #fea409), #3498db;
  border-radius: 100px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin-left: 34px;
  margin-top: 10px;
  cursor: pointer;
}
.box_right_ul {
  list-style: none;
  padding-top: 4px;
  padding-left: 16px;
  width: 670px;
  height: 124px;
  overflow: auto;
  padding-bottom: 6px;
}
.box_right_li {
  width: 150.25px;
  height: 43px;
  margin: 10px 5px 4px 4px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: block;
  float: left;
}
.box_right_lis {
  width: 150.25px;
  height: 43px;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 5px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: block;
  float: left;
  cursor: pointer;
}
.right_li_span {
  display: table-cell;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #3498db;
  align-items: center;
  vertical-align: middle;
  width: 150.25px;
  height: 43px;
  padding-left: 13px;
}
.right_li_item {
  display: block;
  float: left;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #3498db;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  line-height: 15px;
  margin-right: 10px;
  margin-top: -1px;
}
.teacher_img {
  width: 30px;
  height: 30px;
  border-radius: 50px;
}
.title_teacher {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  align-items: center;
  color: #ffffff;
  text-align: center;
  line-height: 62px;
}
</style>