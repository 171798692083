<template>
  <!-- 套餐详情表单 -->
  <div class="taoCanSetting clearfix">
    <div class="package_details_name bg_white">
      <span>{{taocanInfo.PackageName}}</span>
    </div>
    <div>
      <content-item :taocanInfo="taocanInfo"
                    :taocanType="taocanType"></content-item>
    </div>
    <div class="label_radio_bg_white"
         v-if="vShowCreateTime">
      <input-amount :formTitle="vShowTitle"
                    :value="vShowCreateTime"></input-amount>
    </div>
    <div class="label_radio_bg_white">
      <input-amount :formTitle="'价格合计'"
                    :value="Number(taocanInfo.CountAmount)||0"></input-amount>

      <input-amount :formTitle="'优惠金额'"
                    :value="Number(taocanInfo.AfterDisPreAmount)||0"></input-amount>
    </div>

    <div class="label_radio_bg_white">
      <input-amount :formTitle="'套餐售价'"
                    :amountOpt="true"
                    :value="Number(taocanInfo.SalePrice)||0"></input-amount>
    </div>

    <!-- 按钮组 -->
    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             :cancelBtnColor="'btn_fixed_red'"
                             :btnClass="vbtnClass"
                             :cancelBtnText="Number(taocanInfo.StatusKey)==3?'启用套餐':'停用套餐'"
                             :btnText="'修改套餐'"
                             @confirmClick="editClick"
                             @cancelClick="changeStatusClick"></save-cancel-btn-group>
    </div>
  </div>
</template> 

<script>
import { StopPackage, StartPackage } from '../../../../../API/workbench';
import inputAmount from '../common-module/input-amount';
import contentItem from './content-item/index';
export default {
  data () {
    return {

    };
  },
  props: {
    taocanType: {// 套餐标题，必填
      type: String
      // required: ''
    },
    taocanInfo: Object // 套餐信息 （必填）

  },
  components: {
    inputAmount,
    contentItem
  },

  created () {

  },
  mounted () {
    // this.$dialog.register(this, ['taoCanSetting']);
  },
  activated () { },
  watch: {},
  computed: {
    // 有效期
    vShowCreateTime () {
      let findItem = this.taocanInfo.KDNRList.find(o => { return Number(o.UnitKey) == 5; });
      if (findItem) {
        if (this.taocanInfo.ExpireTime) {
          // let newStartTime = this.taocanInfo.StartTime.replace(/\-/g, '.');
          // let newExpireTime = this.taocanInfo.ExpireTime.replace(/\-/g, '.');
          // let days = this.$utils.newDateDiff(this.taocanInfo.StartTime, this.taocanInfo.ExpireTime) + 1;
          // return newStartTime + ' - ' + newExpireTime + ' ' + days + ' 天';
          return this.taocanInfo.ExpireTime;
        } else {
          return this.$utils.mAmountType(this.taocanInfo.PeriodCount, 0) + (this.taocanInfo.UnitKeyValue == '月' ? '个月' : this.taocanInfo.UnitKeyValue);
        }
      } else {
        return false;
      }
    },
    vShowTitle () {
      let findItem = this.taocanInfo.KDNRList.find(o => { return Number(o.UnitKey) == 5; });
      if (findItem) {
        if (this.taocanInfo.ExpireTime) {
          if (this.taocanInfo.StartTime) {
            return `<span>生效日期&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;` + this.taocanInfo.StartTime + `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;失效日期</span>`;
          } else {
            return `<span>生效日期&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;不限&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;失效日期</span>`;
          }
        } else {
          return '课时有效期';
        }
      } else {
        return false;
      }
    },
    vbtnClass () {
      if (Number(this.taocanInfo.StatusKey) == 3) {
        return 'btn_fixed_gray';
      }
    }
  },
  methods: {
    // 修改套餐项
    editClick (callBack) {
      if (callBack) {
        callBack();
      }
      console.log(this.taocanInfo.StatusKey, '修改套餐项');
      if (Number(this.taocanInfo.StatusKey) == 2) {
        this.$emit('afterSuccess', 'edit', this.taocanInfo);
      }
    },
    // 取消按钮+控制返回表单
    cancelClick () {
      this.$emit('cancelClick');
    },
    // 停用-启用套餐
    changeStatusClick () {
      console.log('停用');
      var _this = this;
      if (Number(_this.taocanInfo.StatusKey) == 2) { // 停用改套餐
        layer.confirm('请确定是否停用该套餐?', {
          btn: [{
            name: '确认',
            callBack: function () {
              layer.close();
              StopPackage(_this.taocanInfo.TableID).then(result => {
                _this.$emit('afterSuccess', 'stop', _this.taocanInfo);
                _this.taocanInfo.StatusKey = 3;
                _this.cancelClick();
              }, error => {
                layer.alert(error.msg);
              });
            }
          }]
        });
      } else if (Number(_this.taocanInfo.StatusKey) == 3) {  // 启用改套餐
        layer.confirm('请确定是否启用该套餐?', {
          btn: [{
            name: '确认',
            callBack: function () {
              layer.close();
              StartPackage(_this.taocanInfo.TableID).then(result => {
                _this.$emit('afterSuccess', 'start', _this.taocanInfo);
                _this.taocanInfo.StatusKey = 2;
                // _this.cancelClick();
              }, error => {
                layer.alert(error.msg);
              });
            }
          }]
        });
      }
    }
  }
};
</script>

  <style>
/*  */
</style>