<template>
  <div class="workbench_view_menu massive_bg">
    <div class="menu_left_list icon_01 pr"
         :class="vHasModulePower(btnInfo.newschedulecourse.ModulePowerKey)?'button':''">
      <!-- <function-btn :item="btnInfo.newschedulecourse"
                    @clickItem="mouseOverNewCourseTable"
                    :className="''"> -->
      <!-- doAfterSelectedNewCourseTableType(newScheduleCourseObjType[1]) -->
      <div :class="vHasModulePower(btnInfo.newschedulecourse.ModulePowerKey)?'':'norole'"
           @mouseover.stop="mouseOverNewCourseTable(btnInfo.newschedulecourse,true)"
           @mouseout.stop="mouseOutNewCourseTable(btnInfo.newschedulecourse,false)">
        <div class="list_title"
             :class="btnInfo.newschedulecourse.fatherActive?'button':''">新建课表</div>
        <div class="module_content_menuList"
             style="width:120%;top:110px;left:-8px"
             v-show="isShowNewCourseType"
             @mouseover.stop="mouseOverNewCourseTable">
          <div class="top_bg"
               style=""></div>
          <drop-down-box :optionJsonList="newScheduleCourseObjType"
                         @clickOptionForAlertDialog="doAfterExceptBtn"></drop-down-box>
        </div>
      </div>

      <!-- </function-btn> -->
    </div>
    <div class="menu_left_list icon_05 pr "
         :class="vHasModulePower(btnInfo.searchCourse.ModulePowerKey)?'button':''">
      <div :class="vHasModulePower(btnInfo.searchCourse.ModulePowerKey)?'':'norole'"
           @mouseover.stop="showEduSearch"
           @mouseout.stop="choseEduSearch">
        <div class="list_title"
             :class="vHasModulePower(btnInfo.searchCourse.ModulePowerKey)?'button':''">排课汇总</div>
        <!-- <div class="list_text">
          <span class="text_black">4</span> 种查询
        </div> -->
        <div class="module_content_menuList"
             style="width:120%;top:110px;left:-8px"
             v-show="isShowEduSearchdBox"
             @mouseover.stop="showEduSearch">
          <div class="top_bg"
               style=""></div>
          <drop-down-box :optionJsonList="eduSearchJson"
                         @clickOptionForAlertDialog="doAfterExceptBtn"></drop-down-box>
        </div>
      </div>
    </div>
    <div :class="btnInfo.signuppayment.className">
      <function-btn :item="btnInfo.signuppayment"
                    @clickItem="doAfterClickFunctionBtn(btnInfo.signuppayment)"
                    :className="''">
        <div @mouseover.stop="chgfatherClass(btnInfo.signuppayment,true)"
             @mouseout.stop="chgfatherClass(btnInfo.signuppayment,false)">
          <div class="list_title"
               :class="btnInfo.signuppayment.fatherActive?'button':''">报名收款</div>
        </div>
      </function-btn>
    </div>
    <div :class="btnInfo.searchStudent.className">
      <function-btn :item="btnInfo.searchStudent"
                    @clickItem="goRouter(btnInfo.searchStudent)"
                    :className="''">
        <div @mouseover="chgfatherClass(btnInfo.searchStudent,true)"
             @mouseout="chgfatherClass(btnInfo.searchStudent,false)">
          <div class="list_title"
               :class="btnInfo.searchStudent.fatherActive?'button':''">学生管理</div>

        </div>
      </function-btn>
    </div>
    <div :class="btnInfo.gradeManager.className">
      <function-btn :item="btnInfo.gradeManager"
                    @clickItem="goRouter(btnInfo.gradeManager)"
                    :className="''">
        <div @mouseover="chgfatherClass(btnInfo.gradeManager,true)"
             @mouseout="chgfatherClass(btnInfo.gradeManager,false)">
          <div class="list_title"
               :class="btnInfo.gradeManager.fatherActive?'button':''">班级排课</div>

        </div>
      </function-btn>
    </div>
    <!-- <div :class="btnInfo.incomepayentry.className">
      <function-btn :item="btnInfo.incomepayentry"
                    @clickItem="doAfterClickFunctionBtn(btnInfo.incomepayentry)"
                    :className="''">
        <div @mouseover.stop="chgfatherClass(btnInfo.incomepayentry,true)"
             @mouseout.stop="chgfatherClass(btnInfo.incomepayentry,false)">
          <div class="list_title"
               :class="btnInfo.incomepayentry.fatherActive?'button':''">收支录入</div> -->
    <!-- <div class="list_text button"
               @mouseover.stop="chgChildClass(btnInfo.incomepayentry,true)"
               @mouseout.stop="chgChildClass(btnInfo.incomepayentry,false)"
               v-if="vHasModulePower(btnInfo.incomepayentry.ModulePowerKey)"
               @click.stop="doAfterClickFunctionBtnByVal('incomepaymanage')">
            本月
            <span class="font_blue"
                  style="color:#3498db !important">{{Number(realtimeInfo.TodayOtherRevenueNum)}}</span> 笔
          </div> -->
    <!-- </div>
      </function-btn>
    </div> -->
    <div :class="btnInfo.registercustomer.className" v-if="isZXtwo">
      <function-btn :item="btnInfo.registercustomer"
                    @clickItem="doAfterClickFunctionBtn(btnInfo.registercustomer)"
                    :className="''">
        <div @mouseover.stop="chgfatherClass(btnInfo.registercustomer,true)"
             @mouseout.stop="chgfatherClass(btnInfo.registercustomer,false)">
          <div class="list_title"
               :class="btnInfo.registercustomer.fatherActive?'button':''">登记新生</div>
        </div>
      </function-btn>
    </div>
    <div :class="btnInfo.arrangetrycourse.className">
      <function-btn :item="btnInfo.arrangetrycourse"
                    @clickItem="goRouter(btnInfo.arrangetrycourse)"
                    :className="''">
        <div @mouseover="chgfatherClass(btnInfo.arrangetrycourse,true)"
             @mouseout="chgfatherClass(btnInfo.arrangetrycourse,false)">
          <div class="list_title"
               :class="btnInfo.arrangetrycourse.fatherActive?'button':''">试课追销</div>

        </div>
      </function-btn>
    </div>
    <!-- <div class="menu_left_list icon_02 pr button">
      <div @mouseover.stop="showRegisterandArrange"
           @mouseout.stop="closeRegisterandArrange">
        <div class="list_title button">登记&试课</div> -->
    <!-- <div class="list_text">
            <span class="text_black">2</span> 个功能
          </div> -->
    <!-- <div class="module_content_menuList"
             style="width:120%;top:110px;left:-8px"
             v-show="isShowRegisterandArrange"
             @mouseover.stop="showRegisterandArrange">
          <div class="top_bg"
               style=""></div>
          <drop-down-box :optionJsonList="registerandArrangeJson"
                         @clickOptionForAlertDialog="doAfterExceptBtn"></drop-down-box>
        </div>
      </div>
    </div> -->
    <div class="menu_left_list icon_12 pr button">
      <div @mouseover.stop="showStatisticsSearch"
           @mouseout.stop="closeStatisticsSearch">
        <div class="list_title button">统计查询</div>
        <!-- <div class="list_text">
            <span class="text_black">2</span> 个功能
          </div> -->
        <div class="module_content_menuList"
             style="width:120%;top:110px;left:-8px"
             v-show="isShowStatisticsSearch"
             @mouseover.stop="showStatisticsSearch">
          <div class="top_bg"></div>
          <drop-down-box :optionJsonList="statisticsSearchJson"
                         @clickOptionForAlertDialog="doAfterExceptBtn"></drop-down-box>
        </div>
      </div>
    </div>
    <div class="menu_left_list icon_10 pr button">
      <div @mouseover.stop="showExecptionHandle"
           @mouseout.stop="choseExecptionHandle">
        <div class="list_title button">学生异常</div>

        <!-- <div class="list_text">
          <span class="text_black">7</span> 个功能
        </div> -->
        <div class="module_content_menuList"
             style="width:120%;top:110px;left:-8px"
             v-show="isShowdropDownBox"
             @mouseover.stop="showExecptionHandle">
          <div class="top_bg"></div>
          <drop-down-box :optionJsonList="exceptionJson"
                         @clickOptionForAlertDialog="doAfterExceptBtn"></drop-down-box>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import dropDownBox from '../common/drop-down-box';
export default {
  data () {
    return {
      btnInfo: {},
      isShowNewCourseType: false,
      isShowdropDownBox: false,
      isShowEduSearchdBox: false,  // 排课查询显示下拉框
      isShowstuArrange: false,    // 综合排课下拉框,]
      isShowRegisterandArrange: false, // 登记&安排下拉框.
      isShowStatisticsSearch: false, // 统计查询下拉框.

      exceptionJson: {},
      eduSearchJson: {},
      arrangeJson: {},
      stuArrangeJson: {},
      registerandArrangeJson: {},
      statisticsSearchJson: {},
      newScheduleCourseObjType: [
        {
          id: '3',
          name: '日历建课',
          moduleName: 'newCourseByCalendar',
          ModulePowerKey: 47,
          // tips: '按天自由选择建立课程的时间，比较适用于假期无规则建立课表。可建立过去时间段的课！',
          isShow: true
        },
        {
          id: '2',
          name: '新建循环课',
          moduleName: 'newCircleCourseTable',
          ModulePowerKey: 47,
          // tips: '按规律每周排课的建课方式，可同时建立多个上课规律！不可建立过去时间的课。',
          isShow: true
        }

      ]
    };
  },
  props: {
    realtimeInfo: Object
  },
  created () {
    this.btnInfo = this.$initJson.massiveBtn;
    this.exceptionJson = this.$initJson.exceptionJson;
    this.eduSearchJson = this.$initJson.courseEduSearchJson;
    this.stuArrangeJson = this.$initJson.stuArrangeJson;
    this.statisticsSearchJson = this.$initJson.statisticsSearchJson;
    this.registerandArrangeJson = this.$initJson.registerandArrangeJson;
  },
  components: {
    dropDownBox
  },
  computed: {
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    },
    vMendianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    // 是否追销2.0
    isZXtwo(){
      console.log("isZXtwoJZ")
      if(this.vMendianInfo&&this.vMendianInfo.SaaSClientSetting){
        let locaItem = this.vMendianInfo.SaaSClientSetting.find(o => {
              return o.SetTypeKey == 17;
            });
            if(locaItem&&locaItem.SetContent == '2.0'){    //追销2.0
              return false
            }else{
              return true
            }
      }else{
         return true
      }
    },
    exceptionHandlePowerList () {
      let newArr = [];
      this.exceptionJson.forEach(o => {
        newArr.push(o.ModulePowerKey);
      });
      return newArr;
    }
  },
  methods: {
    goRouter (item) {
      if (item.moduleName) {
        this.openCustomDialogByFactoryDialog(item);
      } else {
        if (item.routerName == 'tryArrangeCourse') {
          console.log('OpenModule', this.$store.getters.SaaSClientInfo.OpenModule);
          if (this.$utils.CheckOpenSettingPower(this.$store.getters.SaaSClientInfo.OpenModule, 6)) {
            this.$bus.emit('needOpenRouter', item);
          } else {
            this.$utils.CheckOpenSettingToTips(6);
          }
        } else {
          this.$bus.emit('needOpenRouter', item);
        }
      }
    },
    doAfterClickFunctionBtnByVal (moduleName) {
      this.openCustomDialogByFactoryDialog({ moduleName: moduleName });
    },
    doAfterClickFunctionBtn (item) {
      if (item.eventName == 'changeAttendance') {
        let moduleInfo = {
          name: '修改考勤',
          routerName: this.$route.name,
          moduleName: 'changeAttendance',
          data: {}
        };
        this.$bus.emit('needOpenRouter', moduleInfo);
      } else if (item.eventName == 'changeCourseBill') {
        let moduleInfo = {
          name: '修改课单',
          routerName: this.$route.name,
          moduleName: 'changeCourseBill',
          data: {}
        };
        this.$bus.emit('needOpenRouter', moduleInfo);
      } else {
        this.openCustomDialogByFactoryDialog({ moduleName: item.moduleName });
      }
    },
    // 新建课表 选中课类型（多课 单课）
    // 新建课NOTE
    doAfterSelectedNewCourseTableType (item) {
      this.isShowNewCourseType = false;
      if (item.id == 2) {
        this.openCustomDialogByFactoryDialog({ moduleName: 'newCircleCourseTable' });
      } else {
        this.openCustomDialogByFactoryDialog({ moduleName: 'newCourseByCalendar' });
      }
      // this.openCustomDialogByFactoryDialog({ moduleName: 'newCircleCourseTable' });
    },
    openCustomDialogByFactoryDialog (item) {
      this.$emit('openCustomDialogByFactoryDialog', item);
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    },
    chgfatherClass (item, type) {
      if (this.vHasModulePower(item.ModulePowerKey)) {
        item.fatherActive = type;
        item.childActive = false;
      }
    },
    onlyChgfatherClass (item, type) {
      if (this.vHasModulePower(item.ModulePowerKey)) {
        item.fatherActive = type;
        item.childActive = false;
      }
    },
    chgChildClass (item, type) {
      item.fatherActive = false;
      item.childActive = type;
    },
    showExecptionHandle () {
      // clearTimeout(this.timer);
      this.isShowdropDownBox = true;
      if (this.vHasModulePower(this.exceptionHandlePowerList)) {
        this.chgfatherClass(this.btnInfo.exceptionJson, true);
      }
    },
    choseExecptionHandle () {
      this.isShowdropDownBox = false;
    },
    // 鼠标移到新建课
    mouseOverNewCourseTable () {
      // clearTimeout(this.timer);
      this.isShowNewCourseType = true;
      if (this.vHasModulePower(47)) {
        this.chgfatherClass(this.btnInfo.newschedulecourse, true);
      }
    },
    // 鼠标离开新建课
    mouseOutNewCourseTable () {
      this.isShowNewCourseType = false;
      if (this.vHasModulePower(47)) {
        this.chgfatherClass(this.btnInfo.newschedulecourse, false);
      }
    },
    // 点击异常处理列表功能时触发
    doAfterExceptBtn (item) {
      console.log('item', item);
      this.isShowEduSearchdBox = false;
      this.isShowdropDownBox = false;
      this.isShowEduSearchdBox = false;
      this.isShowstuArrange = false;
      this.isShowRegisterandArrange = false;
      this.isShowStatisticsSearch = false;
      this.isShowNewCourseType = false;
      if (item.roleName == 'router') {        // 判断是否跳路由的item
        this.goRouter(item);
      } else {
        this.doAfterClickFunctionBtn(item);
      }
    },
    showEduSearch () {
      this.isShowEduSearchdBox = true;
      if (this.vHasModulePower(this.btnInfo.searchCourse.ModulePowerKey)) {
        this.chgfatherClass(this.btnInfo.searchCourse, true);
      }
    },
    choseEduSearch () {
      this.isShowEduSearchdBox = false;
    },
    showstuArrange () {
      this.isShowstuArrange = true;
      if (this.vHasModulePower(this.btnInfo.studentCoursesArranging.ModulePowerKey)) {
        this.chgfatherClass(this.btnInfo.studentCoursesArranging, true);
      }
    },
    chosestuArrange () {
      this.isShowstuArrange = false;
    },
    showRegisterandArrange () {
      this.isShowRegisterandArrange = true;
      if (this.vHasModulePower(this.btnInfo.registercustomer.ModulePowerKey)) {
        this.chgfatherClass(this.btnInfo.registercustomer, true);
      }
    },
    closeRegisterandArrange () {
      this.isShowRegisterandArrange = false;
    },
    showStatisticsSearch () {
      this.isShowStatisticsSearch = true;
      if (this.vHasModulePower(this.btnInfo.statisticsSearch.ModulePowerKey)) {
        this.chgfatherClass(this.btnInfo.statisticsSearch, true);
      }
    },
    closeStatisticsSearch () {
      this.isShowStatisticsSearch = false;
    }
  }
};
</script>