<template>
  <div class="flex"
       :class="vExpireKey?'vExpireKey':''">
    <div class="coursetips"
         :class="$store.getters.SaaSClientEditionKey!=6?'jwTipsTop':''"
         v-if="bigCoursewareItem.ISAuthExpireKey==1">课件端口过期，无法加载课件，请联系课件授权方</div>
    <div class="coursetips"
         :class="$store.getters.SaaSClientEditionKey!=6?'jwTipsTop':''"
         v-else-if="bigCoursewareItem.IsSuspendAuthKey==1">课件授权暂停，无法加载课件，请联系课件授权方</div>
    <div class="coursetips"
         :class="$store.getters.SaaSClientEditionKey!=6?'jwTipsTop':''"
         v-else-if="vshowChannelDate>0">课件端口{{vshowChannelDate}}天后过期，过期后无法加载课件，请联系课件授权方续期</div>
    <div class="coursetips"
         :class="$store.getters.SaaSClientEditionKey!=6?'jwTipsTop':''"
         v-else-if="vshowExpireTime>0">课包授权{{vshowExpireTime}}天后过期，过期后无法加载课件，请联系课件授权方续期</div>
    
    <div class="cw_details_img pr"
         @click.stop="doBigCwDetails">
      <img :src="headImg"
           :onerror="defaultImg" />
      <span class="cw_authorization"
            v-if="vIsAcademicMd">授权方：{{bigCoursewareItem.AuthorizedParty}}</span>
    </div>
    <div class="head_div_right">
      <span class="left_box_list_span pr"
            @click.stop="doBigCwDetails">{{bigCoursewareItem.MainDemoName}}</span>
      <span class="after_span"
            @click.stop="doBigCwDetails">详情</span>
      <div class="flex">
        <div class="left_box_list font_gray9">
          <div class="list_content"
               v-if="vIsAcademicMd">授权日期：
            <span class="font_black">{{bigCoursewareItem.AuthorizeTime}}</span>
          </div>
          <div class="list_content">课件数：
            <span class="font_black">{{bigCoursewareItem.CoursewareNum}}</span>
          </div>
        </div>
        <div class="right_box_list font_gray9">
          <div class="list_content"
               v-if="vIsAcademicMd">授权方式：
            <span class="font_black">{{vAuthorizeTips}}</span>
          </div>
          <div class="list_content">
            授权老师：
            <span class="font_black teather_text">{{vIsAuthor?'全部':vTeacher}}</span>
            当前设备：
            <span v-if="bigCoursewareItem.IsPCAuthKey=='0'"
                  class="device_auth_no">
              {{vAuthorizeType}}
            </span>
            <span v-else
                  :class="Number(bigCoursewareItem.DeviceIsAuth)>0?'device_auth_ok':'device_auth_no'">
              {{vAuthorizeType}}
            </span>

          </div>

        </div>
        <div class="list_btn">
          <!-- <div class="btn_hover_bg_blue"
               v-show="Number(bigCoursewareItem.DeviceIsAuth)==0"
               @click.stop="doAuthorizeDevice">授权本设备</div> -->
          <div class="btn_hover_bg_blue"
               v-if="!vIsAuthor"
               :style="'margin-top:'+ (Number(bigCoursewareItem.DeviceIsAuth)==0?52:52)+'px'"
               @click.stop="doAuthorizeTeacher">授权老师</div>
        </div>
      </div>
      <div @click.stop="doDeviceCount">
        <div class="device_control">设备控制</div>
        <div class="device_control_list">
          <iconAuthorization v-for="(item,index) in vCourseAuthList"
                             :key="index"
                             :isShowBeforLine="true"
                             :courseAuth="item"></iconAuthorization>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import iconAuthorization from './icon-authorization.vue';
export default {
  data () {
    return {
      isAuthor: '0'
    };
  },
  props: {
    bigCoursewareItem: Object
  },
  created () {

  },
  mounted () {
    console.log('bigCoursewareItem', this.bigCoursewareItem, this.vSaaSClientEditionKey, this.$store.getters.SaaSClientEditionKey);
  },
  components: {
    iconAuthorization
  },
  computed: {
      vshowChannelDate(){
      let date=this.bigCoursewareItem.CoursewareChannelExpiredTime||''
      let today=Date.new().toLocaleDateString()
      console.log("vshowChannelDate",date,Date.new(date).getTime()-Date.new(today).getTime(),86400000*7)
       // this.bigCoursewareItem.ExpireTime=date
      if(date&&(Date.new(date).getTime()-Date.new(today).getTime()+86400000)<=86400000*7){
        return parseInt((Date.new(date).getTime()-Date.new(today).getTime()+86400000)/86400000)
      }else{
        return 0
      }
    },
    vshowExpireTime(){
      let date=this.bigCoursewareItem.ExpireTime||''
      let today=Date.new().toLocaleDateString()
      console.log("vshowExpireTime",date,Date.new(date).getTime()-Date.new(today).getTime(),86400000*7)
        // this.bigCoursewareItem.ExpireTime=date
      if(date&&(Date.new(date).getTime()-Date.new(today).getTime()+86400000)<=86400000*7){
        return parseInt((Date.new(date).getTime()-Date.new(today).getTime()+86400000)/86400000)
      }else{
        return 0
      }
    },
    vExpireKey () {
      return this.bigCoursewareItem.ISAuthExpireKey == 1 || this.bigCoursewareItem.IsSuspendAuthKey == 1;
    },
    vIsAuthor () {
      return this.$utils.getSaaSClientSetInfo(71).SetKey == '1';
    },
    vSaaSClientEditionKey () {
      if ((this.$store.getters.SaaSClientEditionKey == 2 || this.$store.getters.SaaSClientEditionKey == 3 || this.$store.getters.SaaSClientEditionKey == 6)) { // 教务门店
        return true;
      } else {
        return false;
      }
    },
    vDeviceCountClass () {
      if (this.vSaaSClientEditionKey || this.$store.getters.SaaSClientEditionKey == 6) { // 教务门店
        return true;
      } else {
        return false;
      }
    },
    vIsAcademicMd () {
      if (this.vSaaSClientEditionKey && this.$store.getters.AcademicMdCwType == 2) { // 自研
        return false;
      } else {
        return true;
      }
    },
    vDeviceCount () {
      if (this.vSaaSClientEditionKey && this.$store.getters.AcademicMdCwType == 2) { // 自研
        return Number(this.bigCoursewareItem.DeviceAuthCount) + '台';
      } else {
        return Number(this.bigCoursewareItem.DeviceAuthCount) + '/' + Number(this.bigCoursewareItem.AuthorizeDeviceNum) + '台';
      }
    },
    // 授权方式
    vAuthorizeTips () {
      let residueNum = (Number(this.bigCoursewareItem.ReleasedNum) - Number(this.bigCoursewareItem.UsageNum)) +
        '/' + Number(this.bigCoursewareItem.ReleasedNum);
      if (this.bigCoursewareItem.ClearingFormKey == 1 && Number(this.bigCoursewareItem.ReleasedNum) > 0) {
        return '按次授权' + '(剩余可用' + residueNum + '课次)';
      } else if (this.bigCoursewareItem.ClearingFormKey == 2) {
        return '按年授权' + '(剩余可用' + residueNum + '天)';
      } else if (this.bigCoursewareItem.ClearingFormKey == 4) {
        return '按天授权' + '(剩余可用' + residueNum + '天)';
      } else {
        return this.bigCoursewareItem.ClearingFormKeyValue;
      }
    },
    vTeacher () {
      if (this.bigCoursewareItem.TeacherAuthCount) {
        return Number(this.bigCoursewareItem.TeacherAuthCount) + '人';
      } else if (this.bigCoursewareItem.AuthTeacherList && this.bigCoursewareItem.AuthTeacherList.length > 0) {
        let teacherNames = '';
        this.bigCoursewareItem.AuthTeacherList.map(o => {
          teacherNames = (teacherNames ? teacherNames + '、' + o.TeacherKeyValue : o.TeacherKeyValue);
        });
        return Number(this.bigCoursewareItem.AuthTeacherList.length) + '人';
      } else {
        return '无';
      }
    },
    vAuthorizeType () {
      if (!this.$zxClient.IsClient) {
        return '非桌面端';
      } else {
        if (this.bigCoursewareItem.IsPCAuthKey == '0') {
          return '禁用';
        } else {
          if (Number(this.bigCoursewareItem.DeviceIsAuth) > 0) {
            return '已授权';
          } else {
            return '未授权';
          }
        }
      }
    },
    vCourseAuthList () {
      let data = this.bigCoursewareItem;
      let arr = [];
      if (!this.bigCoursewareItem) {
        return arr;
      }
      let itemPC = {
        IsAuthKey: data.IsPCAuthKey,
        IsRecordKey: data.IsPCRecordKey,
        AuthorizeDeviceNum: data.AuthorizeDeviceNum,
        AuthCount: data.PCDeviceAuthCount,
        LocationLockKey: data.PCIsLocationLockKey,
        type: 'PC'
      };
      let itemTV = {
        IsAuthKey: data.IsTVAuthKey,
        IsRecordKey: data.IsTVRecordKey,
        AuthorizeDeviceNum: data.TVAuthorizeDeviceNum,
        AuthCount: data.TVDeviceAuthCount,
        LocationLockKey: data.TVIsLocationLockKey,
        type: 'TV'
      };
      let itemAPP = {
        IsAuthKey: data.IsAPPAuthKey,
        IsRecordKey: data.IsAPPRecordKey,
        AuthorizeDeviceNum: data.APPAuthorizeDeviceNum,
        AuthCount: data.APPDeviceAuthCount,
        LocationLockKey: data.IsLocationLockKey,
        type: 'APP'
      };
      arr.push(itemPC);
      arr.push(itemAPP);
      arr.push(itemTV);
      return arr;
    },
    headImg () {
      if (!this.bigCoursewareItem.CoverUrl) {
        return '';
      } else if (this.bigCoursewareItem.CoverUrl.indexOf('http') > -1) {
        return this.bigCoursewareItem.CoverUrl;
      } else {
        return this.$store.getters.CDNURL + this.bigCoursewareItem.CoverUrl;
      }
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' + require('../../../../../public/image/no_logo@2x.png') + '"'
      );
    }
  },
  methods: {
    doBigCwDetails () {
      if (this.bigCoursewareItem.ISAuthExpireKey == 1) {
        layer.alert('课件端口过期，无法加载课件，请联系课件授权方!');
      } else if (this.bigCoursewareItem.IsSuspendAuthKey == 1) {
        layer.alert('课件授权暂停，无法加载课件，请联系课件授权方!');
      } else {
        this.$emit('doBigCwDetails', this.bigCoursewareItem);
      }
    },
    // 授权设备
    doAuthorizeDevice () {
      var DeviceCode = ''; // 浏览器
      if (this.$zxClient.IsClient) {
        DeviceCode = this.$zxClient.GetMCode();
      }
      this.$dialog.open(this, {
        name: '申请设备授权',
        routerName: this.$route.name,
        routertitle: '工作台',
        ModulePowerKey: '',
        moduleName: 'cwsApplyAuth',
        data: {
          dataInfo: {
            CoursewareKey: this.bigCoursewareItem.BigPackageKey,
            DeviceCode: DeviceCode
          }
        },
        callBack: {
          afterSuccess: (data) => { this.$emit('success'); }
        }
      });
    },
    // 授权老师
    doAuthorizeTeacher () {
      this.$dialog.open(this, {
        name: '授权老师',
        routerName: this.$route.name,
        routertitle: '工作台',
        ModulePowerKey: '',
        moduleName: 'cwsTeacherAuthCw',
        data: {
          dataInfo: {
            BigCoursewarePackageKey: this.bigCoursewareItem.BigPackageKey,
            BigCoursewarePackageKeyValue: this.bigCoursewareItem.BigPackageName,
            TeacherKey: this.$store.getters.token.UserID,
            TeacherKeyValue: this.$store.getters.token.UserName
          }
        }
      });
    },
    doDeviceCount () {
      if (this.vDeviceCountClass) {
        let opneModuleName = this.vSaaSClientEditionKey ? 'authManagerList' : 'cwsAuthManagerList';
        this.$dialog.open(this, {
          name: '授权设备',
          routerName: this.$route.name,
          routertitle: '工作台',
          ModulePowerKey: '',
          moduleName: opneModuleName,
          data: {
            dataInfo: {
              CoursewarePackageKey: this.bigCoursewareItem.CoursewarePackageKey,
              CoursewarePackageKeyValue: this.bigCoursewareItem.CoursewarePackageKeyValue
            }
          }
        });
      }
    }
  }
};
</script>
<style scoped>
.cw_details_img img {
  height: 168px;
  width: 300px;
  border-radius: 8px;
  cursor: pointer;
}
.cw_authorization {
  width: 300px;
  height: 29px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  line-height: 29px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0px 0px 8px 8px;
}
.head_div_right {
  width: 500px;
  height: 168px;
  margin-left: 15px;
  position: relative;
}
.after_span {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  position: absolute;
  right: 27px;
  top: 1px;
  color: #999999;
  cursor: pointer;
}

.left_box_list {
  display: flex;
}
.right_box_list {
  width: 250px;
  height: 84px;
  float: right;
}
.left_box_list_span {
  display: block;
  height: 22px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  color: #000000;
  cursor: pointer;
}
.left_box_list_span::after {
  content: '';
  background: url(../../../../../public/image/book_next@2x.png) no-repeat 50%;
  background-size: 12px;
  width: 30px;
  height: 12px;
  position: absolute;
  right: 0px;
  top: 5px;
}
.left_box_list {
  width: 40%;
  display: block;
}
.right_box_list {
  width: 70%;
  display: block;
}
.list_content {
  margin-top: 19px;
  cursor: pointer;
}
.list_btn {
  position: absolute;
  right: 18px;
  top: 80px;
}
.cw_details_list .btn_hover_bg_blue {
  width: 76px;
  height: 36px;
  line-height: 34px;
  text-align: center;
  border-radius: 4px;
}
.device_auth_ok {
  width: 60px;
  height: 20px;
  background: rgba(209, 248, 230);
  border: 1px solid #1cce75;
  box-sizing: border-box;
  border-radius: 10px 10px 10px 1px;
  color: #1cce75;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* line-height: 22px; */
  padding: 3px;
}
.device_auth_no {
  width: 60px;
  height: 20px;
  background: rgba(255, 108, 103, 0.2);
  border: 1px solid #ff6c67;
  box-sizing: border-box;
  border-radius: 10px 10px 10px 1px;
  color: #ff6c67;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* line-height: 10px; */
  padding: 3px;
}
.head_div_right .font_black.font_blue_click {
  color: #3498db !important;
  position: relative;
}
.head_div_right .font_black.font_blue_click:after {
  content: '';
  background: url(../../../../../public/image/book_next@2x.png) no-repeat 50%;
  background-size: 8px;
  width: 20px;
  height: 9px;
  position: absolute;
  top: 6px;
}
.teather_text {
  width: 100px;
  display: inline-block;
}
.device_control {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  align-items: center;

  color: #333333;
}
.device_control_list {
  box-sizing: border-box;
  width: 403px;
  height: 35px;
  background: #f9f9fb;
  border-radius: 4px;
  margin-top: 9px;
  align-items: center;
  display: flex;
  flex-direction: row;
  /* text-align: center; */
}
.device_control_list .icon_authorization_box {
  margin-right: 3px !important;
}
.coursetips {
  position: absolute;
  left: 0;
  right: 0;
  top: -26px;
  height: 35px;
  background: #fffbe6;
  border: 1px solid #ffe694;
  border-radius: 2px;
  line-height: 35px;
  padding-left: 20px;
  color: #fea409;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.vExpireKey {
  position: relative;
  padding-top: 12px;
}
.jwTipsTop {
  top: -30px;
}
</style>