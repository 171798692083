<template>
  <div class="gift_exchange_box">
    <div class="gift_exchange_title">
      <span class="gift_exchange_name">{{ studentInfo.MainDemoName }}的学分：</span>
      <span class="gift_exchange_title_icon">{{
        studentInfo.LearnCurrencyCount
      }}</span>
    </div>
    <div class="gift_exchange_content">
      <div class="gift_exchange_ul_title" v-if="giftListInfo.length != 0">可兑换礼品</div>
      <div class="gift_exchange_ul">
        <gift-exchange-list
          v-for="(item, index) in giftListInfo"
          :key="index"
          :dataInfo="item"
          :class="{opt:selectGiftInfo.OLAPKey == item.OLAPKey}"
          @selectItem="selectGift"
        ></gift-exchange-list>
        <div v-if="giftListInfo.length == 0" class="gift_exchange_nodata">
          您的积分不足，无可兑换的礼品~
        </div>
      </div>
      
    </div>
    <div v-if="selectGiftInfo.MainDemoName" class="gift_exchange_btn">
      <div class="gift_exchange_btn_left pr">
        <div class="gift_exchange_btn_ifno">
          <span class="gift_exchange_btn_text">{{selectGiftInfo.MainDemoName}}</span>
          <span class="gift_exchange_btn_ifno_icon">{{Number(selectGiftInfo.CreditCount || 0)}}</span>
        </div>
        <div  class="font_gray">剩 {{ vCreditCount }}学分</div>
      </div>
      <div @click="changeGift" class="btn_box btn_hover_bg_blue">立即兑换</div>
    </div>
  </div>
</template>
<script>
import {
  SelectExchangeGoodsForStudent,
  ExchangeGift,
} from "../../../API/workbench";
import giftExchangeList from "./gift-exchange-list";
export default {
  data() {
    return {
      giftListInfo: [],
      selectGiftInfo: {}
    };
  },
  props: {
    studentInfo: Object,
  },
  components: {
    giftExchangeList,
  },
  computed:{
    vCreditCount(){
      if(this.selectGiftInfo.CreditCount){
        return Number(this.studentInfo.LearnCurrencyCount -  this.selectGiftInfo.CreditCount);
      }else {
        return this.studentInfo.LearnCurrencyCount;
      }
    }
  },
  created() {
    this.getGiftListInfo();
  },
  methods: {
    selectGift(item){
      this.selectGiftInfo = item;
    },
    changeGift(){
      ExchangeGift(this.studentInfo.OLAPKey,this.selectGiftInfo.OLAPKey).then(
        (res) => {
          layer.alert('兑换礼品成功');
          this.$emit('cancelPopup');
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    getGiftListInfo() {
      SelectExchangeGoodsForStudent(this.studentInfo.OLAPKey).then(
        (res) => {
          this.giftListInfo = res.data;
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    }
  },
};
</script>