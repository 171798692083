<template>
  <!-- 空事件防止全局冒泡关闭事件 -->
  <div class="time_picker pr"
       @click.stop="">
    <div @mouseleave="boxMouseleave"
         @mouseover="boxMouseover"
         @click.stop="showDateBox"
         class="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--time">
      <input type="text"
             autocomplete="off"
             :placeholder="placeholder"
             :readonly="readonly"
             v-model="dateNumber"
             class="el-input__inner" />
      <span class="el-input__prefix">
        <i class="el-input__icon el-icon-time"></i>
      </span>
      <span class="el-input__suffix">
        <span class="el-input__suffix-inner">
          <i class="el-input__icon"
             @click.stop="clearDate"
             :class="{'el-icon-circle-close':isClose}"></i>
        </span>
      </span>
    </div>
    <div v-show="vIsDateBox && isDateBox"
         class="time_picker_box">
      <div class="time_picker_date clearfix">
        <div class="time_picker_hour fl">
          <div class="time_picker_title">
            小时
            <span>（h）</span>
          </div>
          <div class="time_picker_top time_picker_content clearfix">
            <div v-for="(item,index) in dateHourTop"
                 :key="index"
                 :class="{font_gray:isNoLimitTimeRange?(item.date < startHour):isNoLimitTimeRange,opt:item.opt}"
                 @click.stop="clickDateHour(item,isNoLimitTimeRange?(item.date < startHour):isNoLimitTimeRange)"
                 class="time_picker_list">{{item.date}}</div>
            <div class="time_picker_type">上</div>
          </div>
          <div class="time_picker_bottom time_picker_content clearfix">
            <div v-for="(item,index) in dateHourBottom"
                 :key="index"
                 :class="{font_gray: isNoLimitTimeRange?(item.date > endHour):isNoLimitTimeRange,opt:item.opt}"
                 @click.stop="clickDateHour(item,isNoLimitTimeRange?item.date > endHour:isNoLimitTimeRange)"
                 class="time_picker_list">{{item.date}}</div>
            <div class="time_picker_type">下</div>
          </div>
        </div>
        <div class="time_picker_minute fr">
          <div class="time_picker_title">
            分钟
            <span>（m）</span>
          </div>
          <div class="time_picker_right_content clearfix">
            <div v-for="(item,index) in dateMinute"
                 :key="index"
                 @click.stop="clickDateMinute(item)"
                 :class="{opt:item.opt,font_gray:isNoLimitTimeRange?(item.disabled):isNoLimitTimeRange}"
                 class="time_picker_list">{{item.date}}</div>
          </div>
        </div>
      </div>
      <save-cancel-btn-group class="time_picker_btn"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isClose: false,
      isDateBox: false,
      // dateNumber: "",
      optHour: '',
      optMinute: '',
      startHour: '',
      endHour: '',
      startMinute: '',
      endMinute: '',
      dateHourTop: [
        {
          date: '00',
          opt: false
        },
        {
          date: '01',
          opt: false
        },
        {
          date: '02',
          opt: false
        },
        {
          date: '03',
          opt: false
        },
        {
          date: '04',
          opt: false
        },
        {
          date: '05',
          opt: false
        },
        {
          date: '06',
          opt: false
        },
        {
          date: '07',
          opt: false
        },
        {
          date: '08',
          opt: false
        },
        {
          date: '09',
          opt: false
        },
        {
          date: '10',
          opt: false
        },
        {
          date: '11',
          opt: false
        }
      ],
      dateHourBottom: [
        {
          date: '12',
          opt: false
        },
        {
          date: '13',
          opt: false
        },
        {
          date: '14',
          opt: false
        },
        {
          date: '15',
          opt: false
        },
        {
          date: '16',
          opt: false
        },
        {
          date: '17',
          opt: false
        },
        {
          date: '18',
          opt: false
        },
        {
          date: '19',
          opt: false
        },
        {
          date: '20',
          opt: false
        },
        {
          date: '21',
          opt: false
        },
        {
          date: '22',
          opt: false
        },
        {
          date: '23',
          opt: false
        }
      ],
      dateMinute: [
        {
          date: '00',
          opt: false,
          disabled: false
        },
        {
          date: '05',
          opt: false,
          disabled: false
        },
        {
          date: '10',
          opt: false,
          disabled: false
        },
        {
          date: '15',
          opt: false,
          disabled: false
        },
        {
          date: '20',
          opt: false,
          disabled: false
        },
        {
          date: '25',
          opt: false,
          disabled: false
        },
        {
          date: '30',
          opt: false,
          disabled: false
        },
        {
          date: '35',
          opt: false,
          disabled: false
        },
        {
          date: '40',
          opt: false,
          disabled: false
        },
        {
          date: '45',
          opt: false,
          disabled: false
        },
        {
          date: '50',
          opt: false,
          disabled: false
        },
        {
          date: '55',
          opt: false,
          disabled: false
        }
      ]
    };
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    timeRange: {
      type: String,
      default: ''
    },
    dateNumber: {
      type: String,
      default: ''
    },
    isNoLimitTimeRange: {// 是否显示时间范围
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isTeachingMode:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    vIsDateBox () {
      return this.$store.getters.getShowTimePicker;
    }
  },
  watch: {
    vIsDateBox (n, o) {
      if (!n) {
        this.isDateBox = false;
      }
    },
    optHour (n, o) {
      this.dateHourTop.forEach(e => {
        if (n == e.date) {
          e.opt = true;
        } else {
          e.opt = false;
        }
      });
      this.dateHourBottom.forEach(e => {
        if (n == e.date) {
          e.opt = true;
        } else {
          e.opt = false;
        }
      });
      this.filterMinute();
    },
    optMinute (n, o) {
      this.dateMinute.forEach(e => {
        if (n == e.date) {
          e.opt = true;
        } else {
          e.opt = false;
        }
      });
    },
    isDateBox (n, o) {
      if (n) {
        document.querySelector('.popup_dialog_shade').addEventListener(
          'click',
          this.hiddenTimePickerBox
        );
        document.addEventListener(
          'click',
          this.hiddenTimePickerBox
        );

        this.filterMinute();
        this.timeRangeDispose();
      } else {
        document.querySelector('.popup_dialog_shade').removeEventListener('click', this.hiddenTimePickerBox);
        document.removeEventListener('click', this.hiddenTimePickerBox);
      }
    }
  },
  mounted () {
    this.timeRangeDispose();
  },
  destroyed () {
    document.removeEventListener('click', this.hiddenTimePickerBox);
  },
  methods: {
    boxMouseleave () {
      this.isClose = false;
    },
    boxMouseover () {
      this.isClose = true;
    },
    hiddenTimePickerBox () {
      console.log('hiddenTimePickerBox', document.querySelector('.layer-bg'));
      if (document.querySelector('.layer-bg')) {
        return false;
      }
      this.isDateBox = false;
      this.$store.commit('setShowTimePicker', false);
    },
    clearDate () {
      this.$emit('upDateNumber', '');
    },
    filterMinute () {
      this.dateMinute.forEach(e => {
        e.disabled = false;
      });
      if (this.isNoLimitTimeRange) {
        if (this.optHour == this.startHour) {
          this.dateMinute.forEach(e => {
            if (e.date < this.startMinute) {
              e.disabled = true;
            }
          });
        } else if (this.optHour == this.endHour) {
          this.dateMinute.forEach(e => {
            if (e.date > this.endMinute) {
              e.disabled = true;
            }
          });
        }
      }
    },
    timeRangeDispose () {
      if (this.timeRange) {
        let start = this.timeRange.split('-')[0];
        let end = this.timeRange.split('-')[1];
        this.startHour = start.split(':')[0];
        this.startMinute = start.split(':')[1];
        this.endHour = end.split(':')[0];
        this.endMinute = end.split(':')[1];
      }
    },
    clickDateHour (item, isClick) {
      if (isClick) {
        return false;
      }
      item.opt = true;
      this.optHour = item.date;
      if (this.optMinute != '') {
        if (
          this.optHour == this.startHour &&
          this.optMinute < this.startMinute
        ) {
          this.optMinute = this.startMinute;
        } else if (
          this.optHour == this.endHour &&
          this.optMinute > this.endMinute
        ) {
          this.optMinute = this.endMinute;
        }
      }
    },
    clickDateMinute (item) {
      console.log(item, 'clickDateMinute');
      if (!item.disabled) {
        item.opt = true;
        this.optMinute = item.date;
      }
    },
    showDateBox () {
      this.$store.commit('setShowTimePicker', false);
      this.$nextTick(() => {
        this.isDateBox = true;
        this.optHour = this.dateNumber.split(':')[0] || '';
        this.optMinute = this.dateNumber.split(':')[1] || '';
        this.$store.commit('setShowTimePicker', true);
      });
    },
    confirmClick () {
      if (this.optHour != '' && this.optMinute != '') {
        let dateNumber = this.optHour + ':' + this.optMinute;
        this.$emit('upDateNumber', dateNumber);
        this.cancelClick();
      } else {
        layer.alert('请选择一个时间', { mode: this.isTeachingMode?'teachingMode':'' }, () => {
          document.querySelector('.layer-bg').addEventListener(
            'click',
            this.hiddenTimePickerBox
          );
        });
        document.querySelector('.layer-model').removeEventListener('click', this.hiddenTimePickerBox);
        document.querySelector('.layer-wrap').removeEventListener('click', this.hiddenTimePickerBox);
        document.querySelector('.layer-btn').removeEventListener('click', this.hiddenTimePickerBox);
        document.querySelector('.layer-btn button').removeEventListener('click', this.hiddenTimePickerBox);
        document.querySelector('.layer-cont').removeEventListener('click', this.hiddenTimePickerBox);
        document.removeEventListener('click', this.hiddenTimePickerBox);
      }
    },
    cancelClick () {
      this.$store.commit('setShowTimePicker', false);
      this.isDateBox = false;
      this.optHour = '';
      this.optMinute = '';
    }
  }
};
</script>