  <!-- 学生 -->
<template>
  <el-table-column :fixed="columnData.fixed||false"
                   :label="columnData.label"
                   :prop="columnData.prop"
                   :width="columnData.width||100"
                   :min-width="columnData.minWidth"
                   :sortable="columnData.sortable"
                   :align="columnData.align||'left'"
                   :show-overflow-tooltip="true"
                   :sort-orders="['ascending', 'descending']"
                   :label-class-name="columnData.columnClass"
                   :render-header="columnData.renderHeader">
    <template slot-scope="scope">
      <div :class="columnData.extend.click?'pr cp':'pr cd'"
           @click="clickEvent(scope.row)">
        <div class="student_view_title_next"
             :class="{'font_black ':!columnData.extend.click}">
          {{scope.row[columnData.prop]}}
        </div>
        <div class="table_student_column_show_phone"
             v-if="scope.row[columnData.extend.mendianID]" v-html="scope.row[columnData.extend.mendianID]"></div>
      </div>
    </template>
  </el-table-column>
</template>

<script>
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          extend: {
            studentKey: 'StudentKey', //  由本组件完成查看学生档案  所以需要辅助信息 学生ID
            phone: 'CustomerPhoneName', // 手机号 如果学生名下面要显示手机号 则必填 否则不填
            gradeClassKey: 'IsInGradeClassKey', // 未进班显示，是否显示学生名下的未进班标识 非必填
            showWechat: false
          }
        };
      }
    }
  },
  created () {
  },
  computed: {
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    }
  },
  methods: {
    clickEvent (rowData) {
      // 表示是否支持点击
      if (this.columnData.extend && typeof this.columnData.extend.click == 'function') {
        // 普通点击事件 直接执行回调
        if (this.hasModulePower(Number(this.columnData.extend.ModulePowerKey))) {
          this.columnData.extend.click(rowData);
        } else {
          this.$utils.CheckModulePowerToTips(this.columnData.extend.ModulePowerKey);
        }
      }
    },
    // 判断权限,
    hasModulePower (ModulePowerKey) {
      ModulePowerKey = ModulePowerKey || '';
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    }
  }
};
</script>

<style>
</style>