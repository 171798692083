<template>
  <!-- 学生档案 -->
  <div class="student_file_view">
    <search-student-box :selectedStudentInfo="studentInfo"
                        :switchItem="1"
                        :showExamine="false"
                        :showMenu="false"
                        :showSwitchBut="true"
                        :IsRecycleKey="Number(studentInfo.IsRecycleKey) == 1"
                        @selectedStudent="doAfterSelectionStudent"
                        class="form_info_top "></search-student-box>

    <div v-if="Number(studentInfo.OLAPKey)>0 &&isUpData"
         class="ovh pr">
      <div class="class_details_nav_ul">
        <div class="left_btn"
             v-if="!optionList[0].isShow&&optionList.length>4"
             @click="showLeftPage"></div>
        <option-item v-for="(item,key) in voptionList"
                     :className="''"
                     :key="key"
                     :item="item"
                     @seletedOption="seletedOption"></option-item>
        <div class="right_btn"
             v-if="optionList[0].isShow&&optionList.length>4"
             @click="showRightPage"></div>
      </div>
      <!-- 基础设置 -->
      <basic-information v-if="showType==1"
                         class="form_left_type"
                         :studentInfo="studentInfo"
                         @updateStudentFile="updateStudentFile"></basic-information>
      <!-- 销售跟进 -->
      <sales-follow v-else-if="showType==2"
                    :studentInfo="studentInfo"></sales-follow>
      <!-- 考勤记录 -->
      <attention-list v-else-if="showType==3"
                      :studentInfo="studentInfo"></attention-list>
      <!-- 课时消耗 -->
      <class-hour-spend v-else-if="showType==4"
                        :studentInfo="studentInfo"></class-hour-spend>
      <!-- 成长档案 -->
      <grow-up-time v-else-if="showType==5"
                    :studentInfo="studentInfo"></grow-up-time>
      <!-- 优惠券 -->
      <coupon-list v-else-if="showType==6"
                   :studentInfo="studentInfo"></coupon-list>
    </div>
  </div>
</template>

<script>
import searchStudentBox from '../../common/search-student-box';
import optionItem from './components/option-item.vue';
import basicInformation from './components/basic-information/index.vue';
import salesFollow from './components/sales-follow/index';
import attentionList from './components/attention-list/index';
import classHourSpend from './components/class-hour-spend/index';
import { getStudentRelatedMsg } from '../../../API/workbench';
import growUpTime from './components/grow-up-time/index';
import couponList from './components/coupon-list/index.vue';
export default {
  data () {
    return {
      studentInfo: {},
      showType: 1,
      optionList: [
        { key: 1, value: '基础信息', isActive: true, isShow: true },
        { key: 4, value: '课时消耗', isActive: false, isShow: true },
        { key: 5, value: '成长档案', isActive: false, isShow: true },
        { key: 2, value: '销售跟进', isActive: false, isShow: true },
        { key: 3, value: '考勤记录', isActive: false, isShow: true },

        { key: 6, value: '优惠券', isActive: false, isShow: true }
      ],
      isUpData: true,
      isShowElectronicContractPopup: false
    };
  },
  components: {
    searchStudentBox,
    optionItem,
    basicInformation,
    couponList,
    growUpTime,
    classHourSpend,
    attentionList,
    salesFollow
  },
  props: {
    studentID: String,
    defaultShowType: {
      type: [Number, String],
      default () {
        return ''
      }
    }
  },
  created () {
    console.log("this.defaultShowType", this.defaultShowType)
    if (this.defaultShowType) {
      this.showType = this.defaultShowType
    }
    if (this.studentID > 0) {
      this.seletedStudent(this.studentID);
    }
    this.resgisterBusEvent();
  },
  computed: {
    voptionList () {
      let nerArr = [];
      this.optionList.forEach(o => {
        if (o.key == 2) {
          if (!this.$utils.CheckOpenSettingPower(this.$store.getters.SaaSClientInfo.OpenModule, 6)) {
            o.isShow = false;
          } else {
            o.isShow = true;
          }
        } else if (o.key == 6) {
          if (!this.$utils.CheckOpenSettingPower(this.$store.getters.SaaSClientInfo.OpenModule, 10)) {
            o.isShow = false;
          } else {
            o.isShow = true;
          }
        }
        if (o.isShow) {
          nerArr.push(o);
        }
      });
      return nerArr;
    }
  },
  mounted () {
    this.$dialog.register(this, [
      'registerCustomerDetails',
      'debtClassCountDetail',
      'attendanceArrange',
      'signuppayment',
      'releaseHomeTask',
      'releaseComment',
      'attenCourseSpendDetails',
      'paymentrepayment',
      'debtCourseHourseDetails',
      'writeoffarrears'
    ]);
  },
  methods: {
    // 触发更新
    updateStudentFile () {
      console.log('updateStudentFileInfo');
      this.seletedStudent(this.studentInfo.OLAPKey);
    },
    // 获取学生个人资料信息
    seletedStudent (studentID) {
      getStudentRelatedMsg(studentID).then(result => {
        this.studentInfo = result.data;
        this.studentInfo.upDataTime = Date.new();//
        const findItem = this.optionList.find(obj => {
          return obj.key == this.showType;
        });
        this.seletedOption(findItem);
      }, err => {
        layer.alert(err.msg);
      });
    },
    showLeftPage () {
      // if(this.voptionList.length>4){

      this.optionList[0].isShow = true;
      this.optionList[5].isShow = false;
      // }
    },
    showRightPage () {
      // if(this.voptionList.length>4){
      this.optionList[0].isShow = false;
      this.optionList[5].isShow = true;
      // }
    },
    // 切换各项信息
    seletedOption (item) {
      console.log(item, '切换各项信息');
      this.optionList.forEach(o => {
        if (o.key == item.key) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      this.showType = item.key;
      if (this.showType == 2) {
        this.showLeftPage();
      }
      if (this.showType == 5) {
        this.showRightPage();
      }
    },
    // 更新学生的各项信息
    doAfterSelectionStudent (item) {
      console.log(item, '更新选中学生信息');
      this.isUpData = false;
      this.$nextTick(() => {
        this.seletedStudent(item.OLAPKey);
        this.isUpData = true;
      });
    },
    resgisterBusEvent () {
      // 学生个人资料变更通知
      this.$bus.on(this, 'AfterStudentUpdate', (studentKey, type) => {
        if (studentKey == this.studentID || type == 'merge') {
          console.log('AfterStudentUpdate222222', studentKey, this.studentID);
          // this.studentInfo = { OLAPKey: studentKey };
          this.seletedStudent(studentKey);
        }
      });
      // 学有关课程考勤的变更通知
      this.$bus.on(this, 'AfterChangeAttendanceStatus', (scheduleCourseKey, studentKey) => {
        console.log('AfterChangeAttendanceStatus', studentKey);
        if (this.studentInfo.OLAPKey == studentKey) {
          this.doAfterSelectionStudent(this.studentInfo);
        }
      });
    }
  }
};
</script>

<style scoped>
.student_file_view {
  height: 100%;
}
.ovh {
  overflow: hidden;
}
.class_details_nav_ul {
  height: 45px;
  padding: 0 0px 0 20px;
  border-bottom: 1px solid #ececec;
  display: flex;
  background-color: #fff;
  min-width: 618px;
}
.left_btn {
  width: 20px;
  height: 15px;
  background: url(../../../../public/image/book_prod@2x.png) no-repeat 100%;
  background-size: 12px;
  cursor: pointer;
  position: absolute;
  display: inline-block;
  vertical-align: top;
  bottom: 0;
  right: 10px;
  top: 15px;
  left: 5px;
  background-color: #fff;
  box-shadow: 5px 0 10px 4px #fff;
  z-index: 10;
}
.right_btn {
  width: 20px;
  height: 15px;
  background: url(../../../../public/image/book_next@2x.png) no-repeat 100%;
  background-size: 12px;
  cursor: pointer;
  position: absolute;
  display: inline-block;
  vertical-align: top;
  bottom: 0;
  right: 10px;
  top: 15px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 6px #fff;
  z-index: 10;
}
.class_details_nav_ul.pf {
  left: 0;
  right: 0;
  top: 143px;
  height: 47px;
  z-index: 2;
  overflow-x: auto;
  width: 100%;
  flex-wrap: nowrap;
}
</style>
