<template>
  <div>
    <div class="studentname">{{studentName}}</div>
    <div class="box">
      <div class="item"
           v-for="item in dataInfo">
        <div v-if="item.TypeKey == 4"
             class="item_type">
          <el-radio v-model="radio"
                    label=""
                    class="ridioinput">{{ item.name }} </el-radio>
          <div class="title_name">{{ item.title }}</div>
        </div>
        <div v-if="item.TypeKey == '1'||item.TypeKey == '2'"
             class="item_type">
          <el-radio v-model="radio"
                    :label="item.TableID"
                    class="ridioinput"> {{ item.name }} </el-radio>
          <div class="zhengke">
            <div class="zhengkeitem">
              <div class="zhengkeitemTitle">
                <div class="titletype"
                     :class="item.TypeKeyValue=='引流课'?'yinliuke':''">{{ item.TypeKeyValue }}</div>
                <div class="courseno">课单号:{{`&nbsp;`+ item.NumberName }}</div>
              </div>
              <div class="titleStatus"
                   :class="getStatus(item)=='使用中'?'':'shixiao'"> {{ getStatus(item) }}</div>
            </div>
            <div class="zhengkecontent">
              <div class="fontItem">
                <span class="leftclass">报名日期:</span>
                <span class="rightclass">{{ item.GenerateTime }}</span>
              </div>
              <div class="fontItem">
                <span class="leftclass">报名课程:</span>
                <span class="rightclass">{{ item.ApplyCorName }}</span>
              </div>
              <div class="fontItem">
                <span class="leftclass">购买课时:</span>
                <span class="rightclass"> {{ Number(item.NumCount)+item.UnitKeyValue }}</span>
              </div>
              <div class="fontItem">
                <span class="leftclass">实付金额:</span>
                <span class="rightclass">{{ '￥'+ $utils.setMoneyformatToParseInt(Number(item.ActualPayAmount))}}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.TypeKey == '3'"
             class="item_type">
          <el-radio v-model="radio"
                    :label="item.TableID"
                    class="ridioinput"> {{ item.name }} </el-radio>
          <div class="zhengke">
            <div class="zhengkeitem">
              <div class="zhengkeitemTitle">
                <div class="titletype yinliuke"
                     style="width: 60px;">{{ item.TypeKeyValue }}</div>
              </div>
              <div> </div>
            </div>
            <div class="zhengkecontentdas">
              <div style="display: flex;">
                <div class="fontItem"
                     style="width: 210px;">
                  <span class="leftclass">日期:</span>
                  <span class="rightclass" >{{ item.ClassTime + `&nbsp;&nbsp;&nbsp;`+item.ClassTimeName }}</span>
                </div>
                <div class="fontItem"
                     style="width: 110px;">
                  <span class="leftclass">课程:</span>
                  <span class="rightclass" :title="item.CourseNameKeyValue">{{ item.CourseNameKeyValue }}</span>
                </div>
              </div>
              <div style="display: flex;">
                <div class="fontItem"
                     style="width: 210px;">
                  <span class="leftclass">班级:</span>
                  <span class="rightclass" :title="item.GradeClassKeyValue"> {{ item.GradeClassKeyValue }}</span>
                </div>
                <div class="fontItem"
                     style="width: 110px;">
                  <span class="leftclass">老师:</span>
                  <span class="rightclass" :title="item.MTeacherKeyValue">{{ item.MTeacherKeyValue }}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
  </div>
</template>
<script>
import { GetConversionRelevantList, ConversionRelevant } from '../../API/workbench';

export default {
  data () {
    return {
      // 提交数据对象
      dataInfo: [],
      radio: ''
    };
  },
  components: {

  },
  props: {
    IsRenewalKey: [String, Number],
    StudentKey: [String, Number],
    TableID: [String, Number],
    isReadonly: Boolean,
    studentName: String
  },
  created () {
    // if (this.dataInfo) {
    //   this.dataJson.OLAPKey = this.dataInfo.OLAPKey;
    //   this.dataJson.MainDemoName = this.dataInfo.MainDemoName;
    // }
    this.getDataInfo();
  },
  computed: {
    // vMoney () {
    //   return this.;
    // }
  },
  methods: {
    getDataInfo () {
      console.log('this.IsRenewalKey, this.StudentKey', this.IsRenewalKey, this.StudentKey);
      GetConversionRelevantList(this.IsRenewalKey, this.StudentKey).then((res) => {
        this.dataInfo = res.data;
        this.dataInfo.unshift({
          TypeKey: 4,
          title: '不关联'
        });
      });
    },
    verifyData () {
      let isFail = false;
      if (!this.dataJson.MainDemoName) {
        isFail = true;
        layer.alert('请输入班级名');
      }
      return isFail;
    },
    confirmClick () {
      let data = {

      };
      if (this.radio == '') {
        data.TableID = this.TableID;
        data.RelevantID = 0;
        data.TypeKey = 4;
      } else {
        let index = this.dataInfo.findIndex((item) => {
          return item.TableID == this.radio;
        });
        console.log('index', index, this.radio);
        let obj = this.dataInfo[index];
        data.RelevantID = obj.TableID;
        data.TableID = this.TableID;
        data.TypeKey = obj.TypeKey;
        data.NumberName = obj.NumberName;
        data.GradeClassKeyValue = obj.GradeClassKeyValue;
      }
      ConversionRelevant(data).then((res) => {
        this.$emit('afterSuccess');
        this.$emit('cancelClick');
      },(error)=>{
        layer.alert(error.msg);
      });
      console.log('cancelClick', this.radio);
    },
    cancelClick () {
      this.$emit('cancelClick');
    },
    getStatus (data) {
      let strdd = '';
      console.log('new Date(data.ExpireTime).getTime()', new Date(data.ExpireTime).getTime(), new Date().getTime());
      if (data.CurrentCount > 0 && new Date(data.ExpireTime).getTime() >= new Date().getTime()) {
        strdd = '使用中';
      } else if (new Date(data.ExpireTime).getTime() < new Date().getTime() && data.ExpireCount == 0) {
        strdd = '已过期';
      } else {
        strdd = '已耗尽';
      }
      return strdd;
    }
  }
};
</script>
<style scoped>
.box {
  width: 390px;
  height: 300px;
  overflow: auto;
  margin: 10px 10px;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}
.item {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ccc;
}
.item_type {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.title_name {
  line-height: 50px;
  font-size: 16px;
  margin-left: -30px;
}
.box >>> .el-radio__inner {
  width: 18px;
  height: 18px;
}
.ridioinput {
  line-height: 50px;
}
.zhengke {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  margin-left: -30px;
}
.zhengkeitem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f2f2f2;
}

.studentname {
  margin: 10px;
  line-height: 50px;
  font-size: 18px;
  font-weight: 600;
  padding: 0 10px;
}
.zhengkeitemTitle {
  display: flex;
  flex-direction: row;
}
.titletype {
  font-size: 12px;
  background: #9af20b;
  margin: 5px;
  padding: 5px;
  width: 50px;
  text-align: center;
  border-radius: 4px;
  color: #666;
}
.courseno {
  font-size: 12px;
  margin: 3px;
  color: #666;
  line-height: 28px;
}
.titleStatus {
  font-size: 12px;
  background: #9af20b;
  margin: 5px;
  padding: 5px;
  width: 50px;
  text-align: center;
  border-radius: 4px;
  color: #333;
}
.zhengkecontent {
  margin-top: 1px;
  background: #f2f2f2;
  padding: 5px 0;
}
.zhengkecontentdas {
  background: #f2f2f2;
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: column;
}
.leftclass {
  color: #666;
  font-size: 14px;
  line-height: 22px;
}
.rightclass {
  color: black;
  font-size: 14px;
}
.fontItem {
  margin-left: 7px;
  line-height: 20px;
  overflow: hidden;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.yinliuke {
  background: #facd91 !important;
}
.shixiao {
  background: #c0c4cc !important;
}
</style>