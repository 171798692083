<template>
  <div class="screening_condition_list no_clear fl">
    <span>{{dataModel.formTitle}}：</span>
    <span>{{dataModel.fieldList[0].value}}</span>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'extendSearchParams'
  },
  props: {
    dataModel: Object,
    extendSearchParams: Array // 整个已选条件的数据结构
  },
  methods: {
  }
};
</script>
