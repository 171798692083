<template>
  <div class="JXT_dynamic_comment_ul">
    <reply-item v-for="(item,index) in replyInfo"
                :key="index"
                :replyData="item"
                :replyList="replyInfo"
                :IsAllSchool="IsAllSchool"
                @toggleReply="replyChild"></reply-item>
  </div>
</template>

<script>
import replyItem from './reply-item';
export default {
  components: {
    replyItem
  },
  data () {
    return {
      // newreplyList: []
    };
  },
  props: {
    replyInfo: {
      type: Array,
      default: function () {
        return [
          {
            ReplyTypeKey: '', // 回复类型',
            FromTypeKey: '', // 发送人类型',
            From: '', // 发送人',
            FromID: '', // 发送人ID',
            FromHeadImg: '', // 发送人头像',
            ToTypeKey: '', // 1老师2老师3家长',
            To: '', // 接收人',
            ToID: '', // 接收人ID',
            ToHeadImg: '', // 接收人头像',
            ForReplyID: '', // 当前目标回复ID',
            MainForReplyID: '', // 主回复ID0表示为回复短文',
            Content: '', // 回复内容',
            ReplyTime: '' // 回复时间',
          }
        ];
      }
    },
    IsAllSchool: {
      type: Number,
      default: 2
    } // 是否切换全校模式，模式 1-全校;2-普通
  },
  created () {
  },
  computed: {
    vFromHeadImg (url) {
      if (this.bodyItem.FromHeadImg) {
        if (this.bodyItem.FromHeadImg.indexOf('http') > -1) {
          return this.bodyItem.FromHeadImg;
        } else {
          return this.$store.getters.CDNURL + this.bodyItem.FromHeadImg;
        }
      } else {
        return '';
      }
    },
    mainReplyList () {
      // 遍历主回复及子回复.  然后再进行重组.
      var mainReply = [];
      this.replyList.forEach(o => {
        if (o.ReplyTypeKey != 1) {
          if (o.MainForReplyID == 0) {
            if (!o.childList) {
              this.$set(o, 'childList', []); // 强制监听此属性.
            }
            mainReply.push(o);
          }
        }
      });
      mainReply.forEach(c => {
        // c.Content = Math.random()
        if (c.childList.length > 0) { c.childList.splice(0, c.childList.length); }
        this.replyList.forEach(p => {
          if (c.ID == p.MainForReplyID) {
            c.childList.push(p);
          }
        });
      });
      return mainReply;
    }
  },
  methods: {
    replyChild (item, type) {
      this.$emit('toggleReply', item, type); // 调用方法传值
    }
  }
};
</script>

<style>
</style>