<template>
  <div class="from_list"
       :class="required?'from_list_required':''">
    <div class="from_list_title"
         v-if="titleName">{{titleName}}</div>
    <div class="from_list_content">
      <div class="from_list_input">
        <input type="text"
               :placeholder="placeholder"
               :value="value"
               @input="inputEvent($event.target.value)">
      </div>
    </div>
  </div>

</template>

<script>

export default {
  props: {
    titleName: {//  标题 （选填）
      type: String,
      default: ''
    },
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    placeholder: {//  水印提示 （选填）
      type: String,
      default: ''
    },
    required: {//  是否必填 （选填）
      type: Boolean,
      default: false
    }
  },
  methods: {
    inputEvent (val) {
      this.$emit('inputEvent', val);
    }
  }

};
</script>

<style>
</style>