<template>
  <div class="teacher_list_ul"
       @click="doOptionClick"
       v-if="this.$store.getters.token.RoleKey == 2">
    <div class="courseware_teacher_list pr"
         :class="{'opt':dataInfo.isOpt,'all_courseware':Number(dataInfo.OLAPKey)==0,'no_all_courseware':Number(dataInfo.OLAPKey)!=0}"
         v-if="Number(dataInfo.OLAPKey)>=0">
      <img v-if="Number(dataInfo.OLAPKey)>0 && this.dataInfo.XMLIcon"
           class="teacher_img"
           :src="headImg"
           :onerror="defaultImg"
           alt="../../../../../public/image/user-icon.png">
      <div class="info_img"
           v-if="Number(dataInfo.OLAPKey)>0 &&!this.dataInfo.XMLIcon"
           :class="dataInfo.XMLIcon?'':vClass"> <span>{{ dataInfo.MainDemoName.slice(0,1) }}</span> </div>
      <div :class="{'teacher_name':Number(dataInfo.OLAPKey)>0}"
           :title="dataInfo.MainDemoName">
        <span class="teacher_span">{{dataInfo.MainDemoName}}</span>
      </div>
    </div>
    <div class="courseware_teacher_list pr add_teacher_list"
         v-else-if="Number(dataInfo.OLAPKey)==-1">
      <div class="add_teacher_info"></div>
      <div class="teacher_name red_blue">{{dataInfo.MainDemoName}}</div>
    </div>
    <div class="courseware_teacher_list pr more_teacher_list"
         v-else-if="Number(dataInfo.OLAPKey)==-2">
      <div class="more_teacher_info"></div>
      <div class="teacher_name font_blue">{{dataInfo.MainDemoName}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object
  },
  computed: {
    vClass () {
      const n = Math.floor(1 + Math.random() * (9 - 1));
      return 'bg_' + n;
    },
    headImg () {
      console.log(this.dataInfo, 'this.dataInfo.XMLIcon ');
      if (!this.dataInfo.XMLIcon) {
        this.dataInfo.XMLIcon = '';
      }
      if (this.dataInfo.XMLIcon.indexOf('http') > -1) {
        return this.dataInfo.XMLIcon;
      } else {
        if (Number(this.dataInfo.OLAPKey) == Number(this.$store.getters.token.UserID) && this.$store.getters.token.XMLIcon) {
          return this.$store.getters.CDNURL + this.$store.getters.token.XMLIcon;
        } else {
          return this.$store.getters.CDNURL + this.dataInfo.XMLIcon;
        }
      }
    },
    defaultImg () {
      return 'this.src="' + require('../../../../../public/image/user-icon.png') + '"';
    }
  },
  created () {
    console.log(this.$store.getters, 'this.$store.getters');
  },
  methods: {
    doOptionClick () {
      this.$emit('doOptionClick', this.dataInfo);
    }
  }
};
</script>

<style>
.courseware_teacher_list {
  width: 109px;
  height: 55px;
  line-height: 55px;
  margin-bottom: 10px;
  cursor: pointer;
}
/* .teacher_list_ul.opt {
  background: #fff;
} */

.all_courseware {
  border-bottom: 3px solid rgba(245, 247, 249, 1);
  text-align: center;
  box-shadow: -3px 0px 5px rgba(222, 225, 230, 0.6);
  border-radius: 8px 0 0 0;
}
.courseware_teacher_list .teacher_img,
.info_img {
  width: 18px;
  height: 18px;
  border-radius: 50px;
  position: absolute;
  top: 17px;
  left: 18px;
}
.info_img {
  font-size: 10px;
  line-height: 17px;
  color: #fff;
  text-align: center;
}
.teacher_name {
  width: 100%;
  position: absolute;
  top: -2px;
  height: 50px;
  left: 10px;
}
.add_teacher_info {
  position: absolute;
  right: 70px;
  top: 10px;
  width: 30px;
  height: 30px;
  background-image: url(../../../../../public/image/add-teacher.png);
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.more_teacher_info {
  position: absolute;
  right: 73px;
  top: 17px;
  width: 18px;
  height: 18px;
  background-image: url(../../../../../public/image/lable_type_await@1.png);
  background-position: 20%;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 50%;
  cursor: pointer;
  background-size: 18px;
}
.teacher_span {
  width: 68% !important;
  display: inline-block !important;
  height: 30px !important;
  line-height: 30px !important;
  border-radius: 4px !important;
}
.no_all_courseware .teacher_span {
  width: 65% !important;
  height: 46px !important;
  line-height: 57px !important;
  margin-left: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.more_teacher_list .teacher_name {
  margin-left: 30px;
}
.all_courseware .teacher_span:hover {
  background: rgba(217, 223, 228, 1) !important;
}
.no_all_courseware {
  height: 50px !important;
  line-height: 55px !important;
}
.no_all_courseware:hover {
  background: rgba(217, 223, 228, 1) !important;
}
/* .no_all_courseware.opt,
.all_courseware.opt .teacher_span {
  background: rgba(217, 223, 228, 1) !important;
} */
.no_all_courseware.opt {
  background: rgba(217, 223, 228, 1) !important;
}
.red_blue {
  color: #e43e09 !important;
  margin-left: 30px;
}
.add_teacher_list {
  height: 50px !important;
}
.more_teacher_list {
  height: 50px !important;
  line-height: 55px !important;
}

.bg_1 {
  background-color: #bad7df;
}

.bg_2 {
  background-color: #769fcd;
}

.bg_3 {
  background-color: #99ddcc;
}

.bg_4 {
  background-color: #fbafaf;
}

.bg_5 {
  background-color: #f2c6b4;
}

.bg_6 {
  background-color: #b9bbdf;
}
.bg_7 {
  background-color: #99e1e5;
}

.bg_8 {
  background-color: #c1c0b9;
}
</style>
