<template>
	<div>
		<div class="complete_the_job_text1">
			<span>{{dataInfo.StudentKeyValue}}完成的作业</span>
		</div>
		<div class="pr">
			<new-home-task-browse-item :dataInfo="dataInfo"></new-home-task-browse-item>
		</div>
		<!-- <div class="complete_the_job_text2">
			<span v-html="$utils.translationSchoolText(dataInfo.Content)">
			</span>
		</div> -->
		<!-- 提交作业的附件内容 -->
		<!-- <div class="work_attach_bottom" v-if="dataInfo.AttachList.length>0">
			<attach-content-list :AttachList="dataInfo.AttachList"></attach-content-list>
		</div> -->
		<div class="complete_the_job_date">
			<span>{{$utils.replyTime(dataInfo.CreateTime)}}</span>
		</div>
	</div>
</template>

<script>
import newHomeTaskBrowseItem from '../../../home-task-page/new-home-task-browse-item';
import attachContentList from '../../common/attach-content-list.vue';
export default {
  components: {
    attachContentList,newHomeTaskBrowseItem
  },
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object // 作业项
  },
  methods: {

  }
};
</script>

<style>

</style>
