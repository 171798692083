<template>
  <div class="couser_date_arrange_box">
    <div class="couser_date_arrange_title">
      <span class="title_year">{{yearInfo.yearName}}</span>
      <span class="title_number">每{{yearInfo.weekKeyValue}}<span class="font_gray">（已建{{yearInfo.hasCreatedNum}}节）</span></span>
      <span class="fr">已排 <span class="font_blue">{{vHasArrangedNum}}</span> 节</span>
    </div>
    <div class="couser_date_arrange_ul">
      <month-item v-for="(monthInfo,index) in yearInfo.monthArr"
                :key="index"
                :yearName="yearInfo.yearName"
                :monthInfo="monthInfo"
                :index="index"
                :courseItem="courseItem"></month-item>
    </div>
  </div>
</template>

<script>
import monthItem from './month-item';
export default {
  props: {
    yearInfo: Object,
    courseItem: Object
  },
  components: {
    monthItem
  },
  computed: {
    vHasArrangedNum () {
      let num = 0;
      this.yearInfo.monthArr.forEach(monthItem => {
        monthItem.dayArr.forEach(dayItem => {
          dayItem.courseList.forEach(course => {
            // 已安排未取消 或 新安排
            if ((course.hasArranged && !course.cancelArranged) || course.hasSelected) {
              num += 1;
            }
          });
        });
      });
      return num;
    }
  },
  created () {
  },
  methods: {
  }
};
</script>

<style>
</style>