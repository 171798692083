<template>
  <div class="workbench_view"
       style="max-width: 1920px;margin: auto;">
    <div class="workbench_view_box">
      <div class="workbench_view_left">
        <!-- 考勤日报  今日签到 -->
        <attendance-date @showDialog="showDialog"
                         @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog">

        </attendance-date>

        <!-- 直播课 -->

        <div class="workbench_view_right"
             style="bottom:0;padding-bottom:0">
          <div class="workbench_view_user massive_bg"
               style="height:100%"
               @click="doAfterClickFunctionBtn(mine)">
            <div class="user_img">
              <!-- <img :src="headImg"
                 alt
                 :onerror="defaultImg" /> -->
              <staff-heads-img-upload :ImgURl="staffInfo.XMLIcon"
                                      :staffInfo="staffInfo"
                                      :formTitle="'员工头像:'"
                                      :imgHeight="300"
                                      :imgWidth="300"
                                      @doSetPicCorp="doSetPicCorpHeadImg"></staff-heads-img-upload>
            </div>
            <div class="user_name">
              嗨！{{staffInfo.MainDemoName}}
            </div>
            <div class="user_post">
              {{staffInfo.PostKeyValue||''}}
            </div>
            <div class="user_course user_course_text"
                 v-if="latelyCourseInfo.CourseNameKey>0">
              <span class="font_gray">最近的课：</span>
              {{vToday}} {{latelyCourseInfo.SClassTimeName}} {{latelyCourseInfo.CourseNameKeyValue}}
            </div>
            <div class="user_course"
                 v-else>
              <span class="font_gray">当前没有课</span>
            </div>
            <div class="user_btn"></div>
          </div>
        </div>
      </div>

    </div>
    <div>
      <dialog-factory ref="teacherAttenClassDialogFactory"
                      :key="'teacherAttenClass'"
                      :routerName="'teacherAttenClass'"></dialog-factory>
      <dialog-factory-common ref="teacherAttenClassDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import attendanceDate from './attendance-date';
import coursesign from './course-sign';

import dialogFactory from '../common/dialog-factory';

import {
  getIndexReportRealtimeData,
  getToDoStatistics,
  GetTheEmployeeByID,
  GetTheEmployeeTeachingPercentage,
  GetSaaSClientMsg
} from '../../API/workbench';
export default {
  name: 'teacherAttenClass',
  data () {
    return {
      isShow: false,
      realtimeInfo: {},
      isInstructions: false, // 使用问答
      warmingMenuList: [], // 预警统计列表
      WarmingStatisical: {},
      token: {},
      staffTeaching: {}, // 教学业绩
      latelyCourseInfo: {}, // 最后一节课
      staffInfo: {},
      mine: {
        moduleName: 'mine'
      },
      isShowNewCourseType: false,
      isShortcutStartSettingShow: false, // 是否显示快捷启用入口
      abnormalSign: false,  // 异常签到入口
      isAllShow: true,
      isShowBat: true
    };
  },
  components: {
    attendanceDate,
    coursesign,
    dialogFactory
  },
  created () {
    this.token = this.$store.getters.token;
    this.warmingMenuList = this.$initJson.toDoWarningList;
    this.loadData();
  },
  mounted () {
    this.registerBusEvent();
  },
  activated () {
    this.getTheSaaSClientMsg();
    let moduleName = this.$route.params.moduleName;
    if (moduleName) {
      this.openCustomDialogByFactoryDialog(this.$route.params);
    }
  },
  computed: {
    isSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    },
    vWarmingMenuList () {
      // let newArr = [];
      for (var i = 0; i < this.warmingMenuList.length; i++) {
        if (this.vHasModulePower(54)) {
          switch (String(this.warmingMenuList[i].type)) {
            case '1':
              this.warmingMenuList[i].num = this.WarmingStatisical.CountRenewalNum;
              break;
            case '2':
              this.WarmingStatisical.DebtClassHourInfo = this.WarmingStatisical.DebtClassHourInfo || {};
              this.warmingMenuList[i].num = this.WarmingStatisical.DebtClassHourInfo.CountDebtClassHourNum;
              break;
            case '3':
              this.warmingMenuList[i].num = this.WarmingStatisical.CountLeaveSchoolNum;
              break;
            case '4':
              this.warmingMenuList[i].num = this.WarmingStatisical.ArrangeWarningNum;
              break;
            case '5':
              this.warmingMenuList[i].num = this.WarmingStatisical.ClassScheduleNum;
              break;
            case '6':
              this.WarmingStatisical.DebtAmountInfo =
                this.WarmingStatisical.DebtAmountInfo || {};
              this.warmingMenuList[i].num = this.WarmingStatisical.DebtAmountInfo.DebtAmountNum;
              break;
            default:
              break;
          }
        } else {
          this.warmingMenuList[i].num = 0;
        }
      }
      return this.warmingMenuList;
    },
    // 头像
    headImg () {
      if (!this.staffInfo.XMLIcon) {
        this.staffInfo.XMLIcon = '';
      }
      if (this.staffInfo.XMLIcon.indexOf('http') > -1) {
        return this.staffInfo.XMLIcon;
      } else {
        return this.$store.getters.CDNURL + this.staffInfo.XMLIcon;
      }
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' +
        require('../../../public/image/deflut_teacher_photo@2x.png') +
        '"'
      );
    },
    vMendianCreateDateTime () {
      let presentDate = this.$utils.formatDateToLine(Date.new()); // 当天日期
      let newCreateDateTime = this.$store.getters.SaaSClientInfo.CreateDateTime;
      let getDateDiffDay = this.$utils.DateDiffDay(newCreateDateTime, presentDate);
      // console.log(getDateDiffDay, '门店开启日期');
      if (getDateDiffDay >= 0 && getDateDiffDay <= 30) {
        return true;
      } else {
        return false;
      }
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    },

    vToday () {
      if (this.latelyCourseInfo.CourseNameKey) {
        return (
          this.$utils.formatDatet(Date.new(this.latelyCourseInfo.ClassTime), 'MM') +
          '月' +
          this.$utils.formatDatet(Date.new(this.latelyCourseInfo.ClassTime), 'dd') +
          '日 ' +
          ' ' +
          this.$utils.formatDatet(Date.new(this.latelyCourseInfo.ClassTime), 'ddddd')
        );
      }
    }
  },
  methods: {
    loadData () {
      // this.getRealtimeData();
      this.getWarmingStatisical();
      this.getStaffInfo(this.token.UserID);
      this.getStaffTeachingPercentage(this.token.UserID);
    },
    // 是否有开启快捷启用
    getTheSaaSClientMsg () {
      GetSaaSClientMsg().then(result => {
        console.log(result.data, '获取门店信息-是否有开启快捷启用');
        if (this.$refs.shortcutStartSetting && result.data.QuickStartKey) {
          this.$refs.shortcutStartSetting.QuickStartKey = result.data.QuickStartKey;
        }
        if (result.data.QuickStartKey == 1) {
          this.isShortcutStartSettingShow = true;
        } else {
          this.isShortcutStartSettingShow = false;
        }
      }, err => {
        console.log(err, '是否有开启快捷启用');
      });
    },
    // 进入快捷启用
    doShortcutStartSettingClick () {
      if (this.$refs.shortcutStartSetting) {
        this.$refs.shortcutStartSetting.showShortcutStartSetting = true;
        this.$refs.shortcutStartSetting.loadData();
      }
    },
    // 关闭快捷启用
    doStartViewClick () {
      this.isShortcutStartSettingShow = false;
    },
    // 更新头像.
    doSetPicCorpHeadImg (ImgURl) {
      this.staffInfo.XMLIcon = ImgURl;
    },
    getRealtimeData () {
      getIndexReportRealtimeData().then(result => {
        console.log(result, 'getRealtimeData:result');
        this.realtimeInfo = result.data;
        // 门店信息加上在校学生数 其他模块免得在调用接口
        let saasClientInfo = this.$store.getters.SaaSClientInfo;
        saasClientInfo.inSchollStudentNum = result.data.EnrolledNum;
        this.$store.commit('setSaaSClientInfo', saasClientInfo);
      });
    },
    // 获取员工信息.
    getStaffInfo (OLAPKey, callback) {
      GetTheEmployeeByID(OLAPKey)
        .then(result => {
          this.staffInfo = result.data;
          if (callback) {
            callback();
          }
          console.log('getStaffInfo', result.data);
        })
        .catch(error => {
          console.log('getStaffInfo', error);
        });
    },
    // 获取员工教学信息
    getStaffTeachingPercentage (OLAPKey) {
      GetTheEmployeeTeachingPercentage(OLAPKey).then(result => {
        console.log('获取员工教学信息', result.data);
        this.staffTeaching = result.data;
        this.latelyCourseInfo = result.data.Today.RecentClass || {};
      });
    },
    // 获取预警统计.
    getWarmingStatisical () {
      getToDoStatistics()
        .then(result => {
          this.WarmingStatisical = result.data;
          this.$store.commit('setWarmingStatisical', this.WarmingStatisical);
        })
        .catch(error => {
          console.log('getWarmingStatisical', error);
        });
    },
    // 异常预警点击
    toWarmingDetail (item) {
      if (this.vHasModulePower(item.ModulePowerKey)) {
        item.callBack = {
          afterSucces: () => {
            this.getWarmingStatisical();
          }
        };
        console.log(item, 'item');
        this.doAfterClickFunctionBtn(item);
      } else {
        this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
      }
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    },
    showDialog (val) {
      this.openCustomDialogByFactoryDialog({ moduleName: val });
    },
    doAfterClickFunctionBtn (item) {
      this.openCustomDialogByFactoryDialog({ moduleName: item.moduleName, callBack: item.callBack });
    },

    goRouter (item) {
      if (item.moduleName) {
        this.openCustomDialogByFactoryDialog(item);
      } else {
        if (item.routerName == 'studentManagementReport') {
          item.data = {
            headSummaryData: { title: '待排人数', className: 'font_blue', value: 0, type: 'Num_ToBeArranged', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false }     // 待排课学生
          };
        }
        console.log('item', item);
        this.$bus.emit('needOpenRouter', item);
      }
    },
    closeAbnormalSign () {
      this.abnormalSign = false;
    },

    registerBusEvent () {
      this.$bus.on(this, 'switchHeaderBarToTeacherAttenClass', () => {
        this.loadData();
      });
      this.$bus.on(this, 'wsTodoList', (data) => {
        console.log('getTodoStatisticsByStaff');
        this.getWarmingStatisical();
      });
      this.$bus.on(this, 'updateWarmingStatisical', (data) => {
        console.log('updateWarmingStatisical');
        this.getWarmingStatisical();
      });

      this.$bus.on(this, 'wsAfterUpdateAgentInfo', (data) => {
        this.staffInfo = data;
      });
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'teacherAttenClass') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'teacherAttenClass') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.teacherAttenClassDialogFactory) {
        this.$refs.teacherAttenClassDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.teacherAttenClassDialogFactoryCommon) {
        this.$refs.teacherAttenClassDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    },
    batchAddStudent () {
      this.openCustomDialogByFactoryDialog({ moduleName: 'batchAddOldStudent' });
    },
    // 显示使用问答.
    showInstructions () {
      this.isInstructions = !this.isInstructions;
      console.log(this.isInstruction, 'showInstructions');
    },
    // 关闭使用问答.
    closeInstructions () {
      this.isInstructions = false;
    }

  }
};
</script>

