<template>
  <div class="mendian_list_item"
       :class="isSeleted?'opt':''"
       @click.stop="switchSaasClient">
    <div class="mendian_list_item_img">
      <img :src="vMendianLogo"
           alt="" />
    </div>
    <div style="color:#333;margin-left:10px;font-size:14px">
      {{ item.SaaSClientKeyValue }}
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      disabled: false
    };
  },
  props: {
    item: Object
  },
  computed: {
    vMDInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    isSeleted () {
      return this.vMDInfo.OLAPKey == this.item.SaaSClientKey
    },
    vMendianLogo () {
      let img = this.item.LogoPath;
      if (!img || img == "/public/image/no_logo@2x.png") {
        return require("../../../public/image/no_logo@2x.png");
      }
      if (img.indexOf("http") > -1) {
        return img;
      } else {
        return (
          this.$store.getters.CDNURL +
          this.item.LogoPath
        );
      }
    },
  },
  mounted () {
    // this.disabled = this.$refs.textBox.offsetWidth < 116;
  },
  methods: {
    switchSaasClient () {
      this.$emit('switchSaasClient', this.item);
    }
  }
};
</script>
<style scoped>
.mendian_list_item {
  width: 335px;
  height: 50px;
  display: flex;
  flex-direction: row;
  padding: 6px;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  background: #f6f7f8;
  border: 1px solid white;
  position: relative;
}
.mendian_list_item.opt::after {
  position: absolute;
  content: '';
  display: block;
  right: 20px;
  top: 12px;
  width: 25px;
  height: 25px;
  background: url('../../../public/image/tick_green.png') center no-repeat;
  background-size: 24px;
}
.mendian_list_item:hover {
  border: 1px solid #2d98d9;
}
.mendian_list_item_img {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 4px;
  text-align: center;
}
.mendian_list_item_img img {
  width: 100%;
}
</style>