<template>
  <div class="apply_payment_echarts_hous massive_bg echarts_hous_mb">
    <div class="statistics_echarts_left fl">
      <div class="statistics_echarts_left_title">
        课时消耗排名-按老师
      </div>
      <multiplePicker @chgDate="chgDate" :sTime="startTime" :eTime="endTime" :isType="true"></multiplePicker>
      <div class="statistics_echarts_left_btn_content">
        <columnarEchart :echartID="'hour4'"
                        :reqList="reqList"
                        :dataList="dataList"
                        :echartColor="echartColor"
                        :echartBGColor="echartBGColor"
                        :rotate="'-45'"
                        :echartClass="'echarts_m'"
                        ref="echart"></columnarEchart>
      </div>
    </div>
    <teacherStatistics :lastInfo="statisticsLastInfo"
                     :statisticsList="statisticsList"
                     :formTitle="'老师课时消耗'"></teacherStatistics>
  </div>
</template>
<script>
import teacherStatistics from './teacher-statistics';
import columnarEchart from '../echart-common/columnar-echart';
import multiplePicker from '../date-picker-common/multiple-picker';
import { getClassCountUsedForTeacher } from '../../../API/workbench';
export default {
  name: 'classSpendForTeacher',
  data () {
    return {
      // 当前月份第一天.
      startTime: Date.new(Date.new().setDate(1)).toLocaleDateString(),
      // 当前月份最后一天.
      // endTime: Date.new().getFullYear() + '/' + (Date.new().getMonth() + 1) + '/' + Date.new(Date.new().getFullYear(), Date.new().getMonth() + 1, 0).getDate(),
      endTime: this.setChoseEndTime(Date.new()),
      searchType: 1,      // 主讲,全部
      statisticsLastInfo: {},   // 最后一个信息
      statisticsList: [],     // 接收result.data
      reqList: [],          // echart底下遍历的数据
      dataList: [],        // echart 中间遍历的数据
      echartColor: 'rgba(73,181,243,0.6)', // 报表颜色,这是红色,  'rgba(73,181,243,0.6)'这是蓝色 'rgba(255,108,103,0.6)' 这是红色
      echartBGColor: 'rgba(73,181,243,1)'// 报表颜色,这是红色, 'rgba(73,181,243,1)'这是蓝色  'rgba(255,108,103,1)' 这是红色
    };
  },
  components: {
    teacherStatistics, multiplePicker, columnarEchart
  },
  computed: {
  },
  mounted () {
    this.initGetData();
  },
  methods: {
    chgDate (startTime, endTime, type) {
      console.log('type', type);
      this.startTime = startTime;
      this.endTime = endTime;
      this.searchType = type;
      this.initGetData();
    },
    // 绑定数据中的名字
    getDataToFoce () {
      var newArr = [];
      this.statisticsList.forEach(o => {
        if (o.TeacherKey) {
          newArr.push(o.TeacherKeyValue);
        }
      });
      return newArr;
    },
    // echart的显示数据.
    getDataToCount () {
      var newArr = [];
      this.statisticsList.forEach(o => {
        if (o.TeacherKey) {
          newArr.push(Number(o.totalClassCount));
        }
      });
      return newArr;
    },
    // 初始化获取数据并视callback是否存在触发后续事件.
    initGetData (callback) {
      this.getClassCountForTeacherList(this.startTime, this.endTime, this.searchType, callback);
    },
    // 封装获取选择的月份 及最后一天.timer 为时间类型.
    setChoseEndTime (timer) {
      let Year = timer.getFullYear();
      let Month = timer.getMonth() + 1;
      let EndDate = Date.new(Year, Month, 0).getDate();
      return Year + '/' + Month + '/' + EndDate;
    },
    getClassCountForTeacherList (startTime, endTime, type, callback) {
      this.statisticsList.length = 0;
      getClassCountUsedForTeacher(startTime, endTime, type).then(result => {
        this.statisticsLastInfo = result.data.DataList.ClassCountLast;
        this.statisticsList = result.data.DataList.ClassCountTop15 || [];
        this.statisticsList.sort((x, y) => {
          return Number(y.totalClassCount) - Number(x.totalClassCount);
        });
        this.reqList = this.getDataToFoce();
        this.dataList = this.getDataToCount();
        this.$nextTick(() => {
          this.$refs.echart.echartInit();
        });
      });
    }

  }

};
</script>
