<template>
  <div class="form_information_list"
       :class="className">
    <div class="form_information_title">
      {{formTitle}}
    </div>
    <div v-if="!isSlot"
         class="form_information_content">
      <div>
        <span :class="readonlyClassName">{{value}}</span>
      </div>
    </div>
    <div v-else
         class="form_information_content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: 'formInput',
  data () {
    return {};
  },
  props: {
    formTitle: {
      type: String,
      required: true
    }, // 标题，必填
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    className: String,
    titleClassName: String,
    readonlyClassName: String,
    isSlot: Boolean

  },
  computed: {},
  created () {

  },
  methods: {

  }
};
</script>
<style scoped>
.form_information_list {
  border-bottom: none;
  line-height: 27px;
  min-height: 27px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
}
.form_information_list .sex_icon {
  display: inline-block;
  vertical-align: top;
  width: 12px;
  height: 27px;
  background-repeat: no-repeat;
  background-position: left center;
}

.form_information_list .sex_icon.boy_icon {
  background-image: url(../../../../../../public/image/boy_icon@2x.png);
  background-size: 12px;
}

.form_information_list .sex_icon.girl_icon {
  background-image: url(../../../../../../public/image/girl_icon.png);
  background-size: 12px;
}
.form_information_title {
  color: #999;
  width: 96px;
}
.form_information_content {
  padding-right: 16px;
  text-align: left;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  padding-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.font_underline_blue {
  border-bottom: 1px solid #3498db;
}
</style>
