<template>
  <div class="student_conflict_list">
    <div class="flex">
      <div class="student_photo"
           style="padding-left:0">
        <img :src="vHeadImg"
             alt="">
      </div>
      <div class="student_name">
        {{item.MainDemoName}}
      </div>
      <div class="student_sex"
           :class="Number(item.SexKey) == 2?'boy':'girl'">
      </div>
    </div>
    <div class="student_btn"
         @click="doAmendStudentClick"
         v-if="butNameShow">
      {{butNameShow}}
    </div>
  </div>
</template>
<script>
export default {
  name: 'alertDialog',
  data () {
    return {
      lastClickTime: null,
      isSubmiting: false
    };
  },
  props: {
    item: Object, // 为数组
    butNameShow: { // 按钮
      type: String,
      required: ''
    },
    isSingleSubmit: {
      type: Boolean,
      default: false
    }, // 是否启用单次点击
    singleSubmitType: {
      type: String,
      default: 'static'
    } // static（根据两次点击的时间间隔）, dynamic（提交完成后 手动更改参数）
  },
  created () {
  },
  computed: {
    vHeadImg () {
      if (this.item.HeadImgSupport) {
        if (this.item.HeadImgSupport.indexOf('http') > -1) {
          return this.item.HeadImgSupport;
        } else {
          return this.$store.getters.CDNURL + this.item.HeadImgSupport;
        }
      } else {
        if (Number(this.item.SexKey) == 2) {
          return (require('../../../../../public/image/deflut_studentPhoto_boy.png'));
        } else {
          return (require('../../../../../public/image/deflut_studentPhoto_gril.png'));
        }
      }
    }
  },
  methods: {
    doAmendStudentClick () {
      if (!this.isSingleSubmit || this.checkCanClick()) {
        this.$emit('click', this.item, () => { this.isSubmiting = false; });
      }
    },
    checkCanClick () {
      if (this.singleSubmitType == 'static') {
        let now = Date.new();
        let r = !this.lastClickTime || now - this.lastClickTime > 800;
        if (r) {
          this.lastClickTime = now;
        }
        return r;
      } else {
        let r = !this.isSubmiting;
        this.isSubmiting = true;
        return r;
      }
    }
  }

};
</script>

