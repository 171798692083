<template>
  <div class="modulebox clearfix">
    <div class="chartbox">
      <div class="tit">透视排课不足学生</div>
      <echart-pie :echartID="'echart2'"
                  ref="echart2"
                  :dataList="echartList"></echart-pie>
    </div>
    <div class="listbox">
      <div class="formbox">
        <el-select :value="search.CourseNameKey"
                   placeholder="选择课名"
                   clearable
                   @change="inputClassChange">
          <el-option v-for="i in inputClassList"
                     :key="i.CourseNameKey"
                     :label="i.CourseNameKeyValue+'（'+i.Num+'）'"
                     :value="i.CourseNameKey"></el-option>
        </el-select>
        <div class="tip"><span>*</span>信息采集自下周数据，仅供参考。</div>
      </div>
      <div class="formbox">
        <el-input v-model="search.searchText"
                  placeholder="搜索学生"
                  prefix-icon="el-icon-search"></el-input>
        <a class="btn btn_1"
           @click="searchClick">搜索</a>
        <a class="btn btn_2"
           @click="resetClick">清除条件</a>
        <a class="btn btn_3"
           @click="downClick">导出</a>
      </div>
      <div class="course_statistics_table_box table_list_content">
        <table-list class="summarizing_list"
                    :tableData="tableData"
                    :totalNum="totalNum"
                    :PageCount="PageCount"
                    :tableColumns="tableColumns"
                    :queryParams="search"
                    :footerContent="vFooterContent"
                    @loadTableData="getList"></table-list>
      </div>
    </div>
  </div>
</template>

<script>
import { GetPotentialCoursePicture, GetPotentialCoursePictureCount, GetPotentialCourseList } from '../../../API/workbench';
import echartPie from './echart-pie';
import tableList from '../../common/table-list/index';
export default {
  data () {
    return {
      echartList: [
        {
          value: 50,
          name: '排课不足学生',
          itemStyle: { color: '#FE9647' }
        },
        {
          value: 50,
          name: '排课合理学生',
          itemStyle: { color: '#519CF7' }
        }
      ],
      search: {
        CourseNameKey: '',
        searchText: '',
        pageIndex: 0,
        pageSize: 5,
        IsExportKey: 0
      },
      inputClassList: [],
      tableData: [],
      totalNum: 0,
      PageCount: 0,
      tableColumns: [
        {
          label: '学生名',
          prop: 'StudentNameKeyValue',
          width: 90,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '学管师',
          prop: 'AdviserStudentKeyValue',
          width: 90,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '周排课次',
          prop: 'StudentCourseCount',
          width: 90,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '周排课计划',
          prop: 'plan',
          width: 450,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '排课备注',
          prop: 'ArrangeSupport',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        }
      ]
    };
  },
  components: {
    echartPie,
    tableList
  },
  created () {
  },
  mounted () {
    let that = this;
    GetPotentialCoursePictureCount().then(result => {
      if (result.code == 0) {
        that.inputClassList = result.data;
        if (that.inputClassList.length > 0) {
          that.search.CourseNameKey = that.inputClassList[0].CourseNameKey;
        }
        that.getList();
      } else {
        console.log(result.msg);
      }
    }).catch(error => {
      console.log(error);
    });
    that.getChart();
  },
  computed: {
    vFooterContent () {
      return '共 ' + this.totalNum + ' 条数据';
    }
  },
  methods: {
    draw () {
      this.$refs.echart2.echartInit();
    },
    getChart () {
      let that = this;
      GetPotentialCoursePicture().then(result => {
        if (result.code == 0) {
          that.echartList[0].value = parseInt(result.data.FewCount);
          that.echartList[1].value = parseInt(result.data.ManyCount);
          that.$nextTick(() => {
            that.draw();
          });
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    inputClassChange (val) {
      this.search.CourseNameKey = val;
      this.search.pageIndex = 0;
      this.getList();
    },
    searchClick () {
      this.search.pageIndex = 0;
      this.getList();
    },
    resetClick () {
      this.search.CourseNameKey = '';
      this.search.searchText = '';
      this.search.pageIndex = 0;
      this.getList();
    },
    downClick () {
      let that = this;
      let search = {
        CourseNameKey: '',
        searchText: '',
        pageIndex: 0,
        pageSize: 0,
        IsExportKey: 1
      };
      let jsonFormate = {
        SheetTitle: '排课不足学生',
        clist: [
          { title: '学生名', cName: 'StudentNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '周排课次', cName: 'StudentCourseCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '周几', cName: 'WeekKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '上课时间段', cName: 'ClassTimeName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '课程名', cName: 'CourseNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '老师名', cName: 'MTeacherKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '班级名', cName: 'GradeClassKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '排课备注', cName: 'ArrangeSupport', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
        ]
      };
      GetPotentialCourseList(search, jsonFormate).then(result => {
        if (result.code == 0) {
          window.location.href = that.$store.getters.APIURL + result.data.url;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    getList () {
      let that = this;
      GetPotentialCourseList(that.search).then(result => {
        if (result.code == 0) {
          let list = result.data.PageDataList;
          for (let i = 0; i < list.length; i++) {
            list[i].plan = list[i].WeekKeyValue + ' | ' + list[i].ClassTimeName + ' | ' + list[i].CourseNameKeyValue + ' | ' + list[i].MTeacherKeyValue + ' | ' + list[i].GradeClassKeyValue;
            list[i].AdviserStudentKeyValue = list[i].AdviserStudentKeyValue ? list[i].AdviserStudentKeyValue : '-';
          }
          that.totalNum = result.data.Total;
          that.PageCount = result.data.PageCount;
          that.tableData = list;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
};
</script>

<style scoped>
</style>