<template>
  <div>
    <div class="applyClassKey">
      <el-select :value="vMainDemoName"
                 popper-class="popperClass"
                 style="font-size: 16px;height: 50px;z-index: 1;cursor: pointer"
                 placeholder="选择课程"
                 value-key="ApplyClassKey"
                 @change="selectedCourse">
        <el-option v-for="item in courseList"
                   :key="item.ApplyClassKey"
                   :label="item.ApplyClassKeyValue"
                   :value="item"></el-option>
      </el-select>
    </div>
    <div style="position: relative;left: 16px;bottom: 33px;">
      <span style="color:#3498DB">在读学生 {{$utils.mAmountType(courseItem.Total_Student,0)}} 人 |</span>
      <span style="color:#3498DB">当前报课 {{$utils.mAmountType(courseItem.Sum_CurrentCount,1)}}</span>
    </div>

  </div>

</template>
<script>
// import {  } from '../../../API/workbench.js';
export default {
  props: {
    courseList: Array,
    courseItem: Object
  },
  data () {
    return {
      applyCourseNameitem: {}, // 获取该课程项
      sexArr: [
        { ApplyClassKeyValue: '女', ApplyClassKey: 3 },
        { ApplyClassKeyValue: '男', ApplyClassKey: 2 }
      ]
    };
  },
  created () {

  },
  watch: {

  },
  computed: {

    vMainDemoName: {
      // getter
      get: function () {
        return this.courseItem.ApplyClassKeyValue;
      },
      // setter
      set: function (newValue) {
        this.courseItem.ApplyClassKeyValue = newValue;
      }
    }
  },
  methods: {
    // 选择课程内容
    selectedCourse (findItem) {
      // let findItem = this.courseList.find(o => { return (o.ApplyClassKey == Key); });
      console.log(findItem, 'findItem');
      this.$emit('selectedCourse', findItem);
    },
    clickEvent () {
    }
  }
};
</script>

<style>
</style>

