<template>
  <div class="recommend_manage_view table_list_content all_width box_withe_radius">
    <div class="brand_view_top pr">
      <table-type-ul :dataInfo="dataInfo"
                     :tableType="tableType"
                     @swtichTableType="swtichTableType"></table-type-ul>
      <div class="recycle-bin btn_hover_bg_blue"
           @click="clickRecycle">回收站</div>
    </div>
    <search-box :params="params"
                :tableType="tableType"
                @searchClick="searchClick"
                @clearSearchParams="clearSearchParams"
                @doExportInfoClick="doExportInfoClick"></search-box>
    <div>
      <wait-audit-table ref="tableList"
                        v-if="tableType == 1"
                        :params="params"
                        @refreshListInfo="getDataInfo"></wait-audit-table>
      <wait-payment-table ref="tableList"
                          v-if="tableType == 2"
                          :params="params"
                          @refreshListInfo="getDataInfo">
      </wait-payment-table>
      <payment-end-table ref="tableList"
                         v-if="tableType == 3"
                         :params="params"
                         @refreshListInfo="getDataInfo">
      </payment-end-table>
      <contact-payment-table ref="tableList"
                             v-if="tableType == 4"
                             :params="params"
                             @refreshListInfo="getDataInfo">
      </contact-payment-table>
      <contact-wait-audit-table ref="tableList"
                                v-if="tableType == 5"
                                :params="params"
                                @refreshListInfo="getDataInfo">
      </contact-wait-audit-table>
      <contact-refused-table ref="tableList"
                             v-if="tableType == 6"
                             :params="params"
                             @refreshListInfo="getDataInfo">
      </contact-refused-table>
    </div>
    <div>
      <dialog-factory ref="crmJoinSignManageDialogFactory"
                      :key="'crmJoinSignManage'"
                      :routerName="'crmJoinSignManage'"></dialog-factory>
      <dialog-factory-common ref="crmJoinSignManageDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import dialogFactory from '../../common/dialog-factory';
import { HQSignStat } from '../../../API/workbench.js';
import tableTypeUl from './table-type-ul';
import searchBox from './search-box';
import waitAuditTable from './table/wait-audit-table';
import paymentEndTable from './table/payment-end-table';
import waitPaymentTable from './table/wait-payment-table';
import contactPaymentTable from './table/contact-payment-table';
import contactWaitAuditTable from './table/contact-wait-audit-table';
import contactRefusedTable from './table/contact-refused-table';
export default {
  name: 'crmJoinSignManage',
  data () {
    return {
      tableType: 1,
      dataInfo: {
        PendingAuditStat: '0', //	整型	签单待审核
        PendingPaymentStat: '0', //	整型	签单待付款
        HaveFullStat: '0', //	整型	已全款
        RecycleBinStat: '0', //	整型	回收站
        PaymentRecordStat: '0', //	整型	支付记录
        PaymentPendingAuditStat: '0', //	整型	支付待审核
        PaymentRefusedAuditStat: '0' //	整型	支付拒绝审核
      },
      params: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        sourceKey: 0,     // 来源
        PaymentTypeKey: 0, // 付款类型 付款类型1-定金;2-尾款;3-全款
        paymentScheduleKey: 0,  // 付款进度 1-已签单;2-付定中;3-已付定;4-尾款中;5-全款中;6-已全款
        counselorKey: 0,    // 销售顾问
        joinTheWay: 0,    // 加盟方式  1-课程加盟;2-整店加盟
        StartTime: '',
        EndTime: ''
      }
    };
  },
  components: {
    tableTypeUl,
    searchBox,
    waitAuditTable,
    paymentEndTable,
    waitPaymentTable,
    contactPaymentTable,
    contactWaitAuditTable,
    contactRefusedTable,
    dialogFactory
  },
  created () {
    console.log('this.$route.params', this.$route.params.data);
    if (this.$route.params.data) {
      this.tableType = this.$route.params.data.type || 1;
      if (this.tableType == 4) {
        this.params.StartTime = this.$route.params.data.StartTime;// this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd')
        this.params.EndTime = this.$route.params.data.EndTime;// this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0))
      }
    }

    this.getDataInfo();
  },
  mounted () {
    this.registerBusEvent();
  },
  methods: {
    openRecommendDetails (data) { },
    upDataInfo (data) {
      // this.dataInfo = data;
      //  this.getDataInfo()
    },
    getDataInfo () {
      HQSignStat().then(result => {
        this.dataInfo = result.data;
        console.log('HQSignStat', result.data);
      }).catch(error => {

      });
    },
    searchClick () {
      const type = this.tableType;
      this.tableType = 0;
      this.params.pageIndex = 0;
      this.$nextTick(() => {
        this.swtichTableType(type);
      });
    },
    // 导出
    doExportInfoClick () {
      this.$refs.tableList.doExportInfoClick();
    },
    clearSearchParams () {
      this.params = {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        sourceKey: 0,
        counselorKey: 0,
        joinTheWay: 0,
        paymentScheduleKey: 0,
        PaymentTypeKey: 0,
        StartTime: '',
        EndTime: ''
      };
    },
    swtichTableType (type) {
      this.params.pageIndex = 0;
      this.tableType = type;
      this.getDataInfo()
    },
    // 查看回收站数据
    clickRecycle () {
      let item = {
        ModulePowerKey: 0,
        auth: true,
        name: '加盟签单回收站',
        roleName: '',
        routerName: 'crmContactRecycleBin',
        routertitle: '加盟签单回收站',
        dataKey: 0,
        data: {}
      };
      this.$bus.emit('needOpenRouter', item);
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCustomDialog', dialogInfo);
      if (this.$refs.crmJoinSignManageDialogFactory) {
        this.$refs.crmJoinSignManageDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title, 'HQ');
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.crmJoinSignManageDialogFactoryCommon) {
        this.$refs.crmJoinSignManageDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title, 'HQ');
      }
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'crmJoinSignManage') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'crmJoinSignManage') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    }
  }
};
</script>
<style scoped>
.brand_view_top {
  display: flex;
}
.recommend_manage_view {
  padding: 20px 20px 10px;
}

.all_width {
  width: 1290px;
  margin: 20px auto 40px;
}

.small_width {
  width: 1290px;
  margin: 20px auto 40px;
}

.box_withe_radius {
  background-color: #fff;
  border-radius: 8px;
}
.recycle-bin {
  position: absolute;
  right: 0px;
  top: 6px;
  height: 36px;
  width: 76px;
  line-height: 34px;
  text-align: center;
  border-radius: 4px;
  padding-left: 27px;
  padding-right: 7px;
  background: #3498db url(../../../../public/image/recycled_icon.png) no-repeat
    8px;
}
</style>