<template>
  <!-- 面容信息 -->
  <div class="face_informatio_entry_box clearfix">
    <leftContent ref="leftContent" @seletedItem ="seletedItemAfter" :seletedStuItem="seletedStuItem"></leftContent>
    <rightContent 
      ref="rightContent"
      :seletedItem="seletedStuItem"
      @seletedItem ="seletedItemAfter"
      @updateStudentList="updateStudentList"
      @parentFn="parentFn">
    </rightContent>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
  </div>
</template>
<script>
import leftContent from './left-content';
import rightContent from './right-content';

export default {
  data () {
    return {
      curStatusInfo: { statusKey: 1, statusKeyValue: '识别中...' },
      flag: false,
      trackerTask: null,
      delayedTime: 3000,
      statusList: {
        recognizing: 1, // 识别中
        scanning: 2, // 扫描中
        successed: 3, // 签到成功
        unRecogniz: 4, // 无法识别
        abnormal: 5 // 签到异常
      },
      signRecodList: [], // 签到记录流水
      seletedStuItem: {}

    };
  },
  components: {
    leftContent, rightContent
  },
  computed: {
    vCurrentStatus () {
      let status = '';
      switch (this.curStatusInfo.statusKey) {
        case this.statusList.recognizing:
          status = 'type_purple';
          break;
        case this.statusList.scanning:
          status = 'type_blue';
          break;
        case this.statusList.successed:
          status = 'type_green';
          break;
        case this.statusList.unRecogniz:
          status = 'type_yellow';
          break;
        case this.statusList.abnormal:
          status = 'type_red';
          break;
        default:
          break;
      }
      return status;
    }
  },
  mounted () {
    // this.openCamera();

  },
  methods: {
    seletedItemAfter (item) {
      this.seletedStuItem = item;
    },
    updateStudentList () {
      this.$refs.leftContent.search();
    },
    closeCanmara () {
      this.$refs.rightContent.stopMediaStreamTrack();
    },
    parentFn(v){
      console.log(v,"子向父")
      this.seletedStuItem = v;
      this.$refs.leftContent.search();
    }
  }
};
</script>
<style scoped>
.container {
  position: relative;
  width: 400px;
  height: 400px;
  float: left;
}
.message {
  float: left;
}
video,
#canvas {
  position: absolute;
    /* width: 400px; */
    height: 400px;
    left: 50%;
    transform: translateX(-50%);
}
</style>
 
