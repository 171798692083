<template>
  <div>
    <div class="student_file_course_block ">
      <bill-item :item="billInfo"
                 :showTaoCan="true"></bill-item>
    </div>
    <div class="attendance_course_content">
      <div class="class_details_nav_ul">
        <option-item v-for="(item,key) in optionList"
                     :key="key"
                     :item="item"
                     @seletedOption="seletedOption"></option-item>
        <div v-if="showType==1"
             class="class_details_nav_btn">
          <div class="class_nav_btn"
               @click.stop="showChgCourse(true)"
               v-if="!bantchChgCourse">
            批量更换课消
          </div>
          <div v-else>
            <div class="class_nav_btn class_nav_btn_left"
                 @click="showChgCourse(false)">取消</div>
          </div>
        </div>
      </div>
      <atten-spend v-if="showType==1"
                   :bantchChgCourse="bantchChgCourse"
                   :searchObj="searchObj"
                   ref="attenSpend">
      </atten-spend>
      <abnormal-spend v-if="showType==2"
                      :searchObj="searchObj"></abnormal-spend>

      <button-single-comfire-btn v-if="bantchChgCourse"
                                 :btnText="'确定'"
                                 :btnClass="'popup_confirm_btn'"
                                 @click="showCourseBillList"></button-single-comfire-btn>
    </div>
    <custom-dialog :title="'选择课消课单'"
                   :visible.sync="isShowSelectCourseBillListPopup"
                   :before-close="closeCourseBillListPopup">
      <select-course-bill-list :dataSourceList="courseBillList"
                               :selectedKey="newSeletedKey"
                               @onSelectedItem="seletedCourseItem"></select-course-bill-list>
    </custom-dialog>
  </div>
</template>

<script>
import optionItem from '../../option-item';
import attenSpend from './atten-spend.vue';
import abnormalSpend from './abnormal-spend.vue';
import selectCourseBillList from './select-course-bill-list';
import { AlterAttendanceXYKD, getStudentAllCourse, GetStudentXYKDDetails } from '../../../../../../API/workbench';
;
export default {
  data () {
    return {
      showType: 1,
      StudentKey: 0,
      optionList: [{
        key: 1,
        value: '考勤课消',
        isShow: true,
        isActive: true
      }, {
        key: 2,
        value: '异常课消',
        isShow: true,
        isActive: false
      }],
      searchObj: {
        TableID: 0,
        SourceBillKey: 0,
        RN: 0
      },
      billInfo: {},
      bantchChgCourse: false,  // 是否批量
      showList: false,		// 是否显示列表
      newSeletedKey: 0,
      courseBillList: [],
      isShowSelectCourseBillListPopup: false
    };
  },
  components: {
    billItem: () => import('../bill-item'), // 异步注册组件
    optionItem,
    attenSpend,
    abnormalSpend,
    selectCourseBillList
  },
  props: {
    dataInfo: {
      type: Object,
      default: {}
    }
  },
  created () {
    this.searchObj.TableID = this.dataInfo.TableID;
    this.searchObj.SourceBillKey = this.dataInfo.SourceBillKey;
    this.searchObj.RN = this.dataInfo.RN;
    this.StudentKey = this.dataInfo.StudentKey;
    this.getBillInfo();
  },
  computed: {

  },
  methods: {
    seletedCourseItem (item) {
      console.log(item, 'seletedCourseItem');
      this.newSeletedKey = item.TableID;
      layer.confirm('是否用选中的课单扣课时?', {
        btn: [
          {
            name: '取消',
            callBack: () => {
              this.newSeletedKey = 0;
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              this.newSeletedKey = 0;
              AlterAttendanceXYKD(this.StudentKey, JSON.stringify(this.seletedList), item.TableID, this.searchObj.TableID).then(result => {
                this.getBillInfo();
                this.showChgCourse(false);
                this.$refs.attenSpend.getDataList();
                this.$emit('afterSucces');
                this.closeCourseBillListPopup();
              }, error => {
                layer.alert(error.msg);
              });
              layer.close();
            }
          }]
      });
    },
    // 点击弹出课单列表
    showCourseBillList () {
      this.seletedList = [];
      this.$refs.attenSpend.dataList.forEach(o => {
        if (o.isActive) {
          this.seletedList.push(o.TheLessonKey);
        }
      });
      if (this.seletedList.length > 0) {
        getStudentAllCourse(this.StudentKey).then(result => {
          this.courseBillList = result.data.filter(o => {
              o.StartTime = o.StartTime == '1900-01-01' ? '-' : o.StartTime;
            return o.StatusKeyValue != 2 && o.TableID != this.searchObj.TableID;
          });
            console.log("选择课消课单",this.courseBillList)
          this.isShowSelectCourseBillListPopup = true;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('请至少选中一项课消记录');
      }
    },
    closeCourseBillListPopup () {
      this.isShowSelectCourseBillListPopup = false;
    },
    // 点击更换课单设置批量样式
    showChgCourse (type) {
      this.bantchChgCourse = type;
      this.$refs.attenSpend.initDataList();
    },
    // 切换显示
    seletedOption (item) {
      this.optionList.forEach(o => {
        if (o.key == item.key) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
        // if(o.key==2){
        this.bantchChgCourse = false;
        // }
      });
      this.showType = item.key;
    },
    getBillInfo () {
      GetStudentXYKDDetails(this.searchObj).then(result => {
        console.log('GetStudentXYKDDetails', result.data);
        this.billInfo = result.data;
      }, error => {
        layer.alert(error.msg);
      });
    }
  }
};
</script>

<style scoped>
.student_file_course_block {
  border-bottom: 1px solid #ececec;
}
.class_details_nav_ul {
  height: 45px;
  padding: 0 0px 0 20px;
  border-bottom: 1px solid #ececec;
  display: flex;
  background-color: #fff;
}
.class_details_nav_btn {
  position: relative;
  right: -100px;
  text-align: right;
  top: 7px;
  height: 30px;
  line-height: 28px;
  display: flex;
}
.class_nav_btn {
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 0 15px;
  cursor: pointer;
}
.class_nav_btn_left {
  margin-left: 56px !important;
}
.class_details_nav_btn div {
  margin: 0 5px;
  flex: 1;
  display: inline-block;
}
</style>
