<!-- 流量账单模块 -->
<template>
  <!-- 授权管理 -->
  <div class="payment_statistics_bottom flow_packet_report">
    <!-- 头部 -->
    <div class="HQflow_packet_total">
      <div class="HQflow_Bill_date">
        <!-- 选择年月 -->
        <div class="date_time pr">
          <div class="new_table_title_date left_type">
            <div @click.stop="searchLast()"
                 class="date_last fl"></div>
            <div class="cw_date_calendar">
              <single-year-picker @chgDate="chgDate"
                                  :type="'month'"
                                  style="opacity: 0;"></single-year-picker>
            </div>
            <span class="date_text">{{yearView}}&nbsp;年&nbsp;{{monthView}}&nbsp;月</span>
            <div @click.stop="searchNext()"
                 class="date_next fr"></div>
          </div>
        </div>
        <div class="FlowPacketList_div">
          <div class="FlowPacket_Count">
            <div>{{FlowPacketList.NumCount}}</div>
            <div>购买流量</div>
          </div>
          <div class="FlowPacket_Count">
            <div>{{FlowPacketList.UseNumCount}}</div>
            <div>消耗流量</div>
          </div>
          <div class="FlowPacket_Count">
            <div>{{FlowPacketList.DirectionalCount}}</div>
            <div>门店抵扣</div>
          </div>
          <div class="FlowPacket_Count">
            <div>{{FlowPacketList.CurrencyCount}}</div>
            <div>扣减流量</div>
          </div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_search flex">

      <div class="width_small course_statistics_search">
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入'"
                                  :clearAll="true"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>

      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list class="summarizing_list"
                  :tableData="vdataReportList"
                  :tableColumns="vTableCourseColumns"
                  :key="tableKey"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :queryParams="searchObj"
                  :footerContent="vFooterTotal"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import singleYearPicker from './hqsingle-year-flow-bill';
import {
  GetSaaSClientFluxPackageBill, GetFluxPackageRangeForCount, AdminGetFluxPackageRangeForCount, AdminGetSaaSClientFluxPackageBill
} from '../../../../API/workbench';
export default {
  components: {
    tableList,
    inputSearchContainBtn,
    singleYearPicker
  },
  data () {
    return {
      getDate: new Date(),
      monthView: '',
      yearView: '',
      startDate: this.$utils.getCurrentMonthFirst(),
      endDate: this.$utils.getCurrentMonthLast(),
      seletedDate: '',
      FlowPacketList: {
        Number: 123,
        ResidueCount: 0, // 剩余总流量
        NumCount: 0, // 购买流量
        CurrencyCount: 0, // 扣减流量
        UseNumCount: 0, // 消耗流量
        DirectionalCount: 0 // 门店抵扣

      },
      TypeList: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '通用',
          value: 1
        },
        {
          label: '定向',
          value: 2
        }

      ],
      // 查询参数
      searchObj: {
        startTime: '',
        endTime: '',
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        searchDate: '',
        sequence: 'DESC',
        orderBy: '',
        ExpendClientKey: ''
      },
      dataReportList: [],
      PageCount: 0,
      totalNum: 0,
      tableKey: '0',
      GeneralFluxCount: 0,
      HQtableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '年月',
          prop: 'StartTime',
          minWidth: 70,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '校区',
          prop: 'ExpendClientKeyValue',
          sortable: 'custom',
          minWidth: 100,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '消耗流量',
          prop: 'NumberCount',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '门店抵扣',
          prop: 'DirectionalCount',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '扣减流量',
          prop: 'CurrencyCount',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '门店可用流量',
          prop: 'GiveFluxCount',
          minWidth: 130,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          width: 100,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  click: (rowData) => {
                    this.doAfterTableBtnClick(rowData);
                  }
                },
                ModulePowerKey: 0
              }

            ]
          }
        }
      ]
    };
  },
  props: {
    dataInfo: Object
  },
  created () {
    console.log(this.startDate, 'dataInfo', this.endDate);
    console.log(this.dataInfo, 'dataInfo', this.$store.getters);
    this.chgDate('', this.startDate, this.endDate);
  },
  mounted () {
    this.$dialog.register(this,
      [
        'flowPacketDetailReport'
      ]);
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        o.NumCount = Number(o.NumCount) != 0 ? this.$utils.flowFormate(o.NumCount) : '-';
        o.ResidueCount = Number(o.ResidueCount) != 0 ? this.$utils.flowFormate(o.ResidueCount) : '-';
        o.CurrencyCount = Number(o.CurrencyCount) != 0 ? this.$utils.flowFormate(o.CurrencyCount) : '-';
        o.NumberCount = Number(o.NumberCount) != 0 ? this.$utils.flowFormate(o.NumberCount) : '-';
        o.UseNumCount = Number(o.UseNumCount) != 0 ? this.$utils.flowFormate(o.UseNumCount) : '-';
        o.DirectionalCount = Number(o.DirectionalCount) != 0 ? this.$utils.flowFormate(o.DirectionalCount) : '-';
        if (o.CurrentFluxObjectKey == 1) {
          o.GiveFluxCount = Number(o.GiveFluxCount) == 0 ? '总部负担' : this.$utils.flowFormate(o.GiveFluxCount);
        } else {
          if (o.GiveFluxCount > 0) {
            o.GiveFluxCount = '门店负担';
          } else {
            o.GiveFluxCount = Number(o.oweNumCount) == 0 ? '门店负担' : '欠' + this.$utils.flowFormate(o.oweNumCount);
          }
        }
        this.searchObj.ExpendClientKey = Number(o.ExpendClientKey);
        newArr.push(o);
      });
      console.log(newArr, 'newArr');
      return newArr;
    },
    vTableCourseColumns () {
      // if (this.vmdInfo.EditionKey == 5) {
      return this.HQtableCourseColumns;
      // } else {
      //   return this.tableCourseColumns;
      // }
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    },
    vmdInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vTypeKeyValue () {
      return this.searchObj.FluxPackageType == 0
        ? '全部'
        : this.searchObj.FluxPackageTypeValue;
    }
  },
  methods: {
    // 当前时间
    atTimeView (date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month < 10) { month = '0' + month; }
      this.yearView = year;
      this.monthView = month;
    },
    // 搜索上一个月
    searchLast () {
      this.monthView--;
      if (this.monthView < 10) { this.monthView = '0' + this.monthView; }
      if (this.monthView < 1) { this.monthView = 12; this.yearView = this.yearView - 1; }
      this.searchDate();
    },
    // 搜索下一个月
    searchNext () {
      this.monthView++;
      if (this.monthView < 10) { this.monthView = '0' + this.monthView; }
      if (this.monthView > 12) { this.monthView = '01'; this.yearView = this.yearView + 1; }
      this.searchDate();
    },
    // 选择某一天
    chgDate (val, startTime, endTime) {
      // this.seletedDate = val;
      console.log(val, startTime, endTime, '点击当前汇总数据');
      let date = Date.new(startTime);
      this.atTimeView(date);
      this.startDate = startTime.replace(/\//g, '-');
      this.endDate = endTime.replace(/\//g, '-');
      this.searchObj.startTime = this.startDate;
      this.searchObj.endTime = this.endDate;
      console.log(this.searchObj.startTime, this.searchObj.endTime, 'this.searchObj.endTime');
      this.getDataList();
    },
    // 搜索日期
    searchDate () {
      // 开始日期
      this.startDate = this.yearView + '-' + this.monthView + '-' + '01';
      // 结束日期
      this.endDate = this.yearView + '-' + this.monthView + '-' + this.mGetDate(this.yearView, this.monthView);
      this.chgDate('', this.startDate, this.endDate);
    },
    mGetDate (year, month) {
      var d = Date.new(year, month, 0);
      return d.getDate();
    },
    setFluxNumber (size) {
      size = Number(size);
      if (size == 0) {
        return 0 + 'KB';
      }
      let kb = 1024;
      let unit = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
      let i = Math.floor(Math.log(Math.abs(size)) / Math.log(kb));
      return (size / Math.pow(kb, i)).toPrecision(3) + ' ' + unit[i];
    },
    getDataList () {
      let fn = '';
      let fn2 = '';
      let OLAPKey = this.dataInfo ? Number(this.dataInfo.OLAPKey) : 0;
      console.log(this.vmdInfo, 'this.vmdInfo');

      console.log(this.vmdInfo.OLAPKey, 'this.vmdInfo.OLAPKey');
      if (this.vmdInfo.OLAPKey != 1) {
        fn = GetSaaSClientFluxPackageBill;
        fn2 = GetFluxPackageRangeForCount;
      } else {
        fn = AdminGetSaaSClientFluxPackageBill;
        fn2 = AdminGetFluxPackageRangeForCount;
      }
      fn(this.searchObj, OLAPKey).then(result => {
        console.log(result.data, 'GetSaaSClientFluxPackageBill');
        this.dataReportList = result.data.PageDataList;
        this.totalNum = result.data.Total;
      }).catch(error => {
        console.log('errorGetSaaSClientFluxPackageBill', error.msg);
      });

      fn2(this.searchObj.startTime, this.searchObj.endTime, OLAPKey).then((result) => {
        // this.FlowPacketList = result.data;
        console.log('GetFluxPackageRangeForCount', result.data);
        // this.FlowPacketList.FlowPackagesKeyValue = this.dataInfo.FlowPackagesKeyValue;

        this.FlowPacketList.CurrencyCount = Number(result.data.CurrencyCount) == 0 ? '-' : this.$utils.flowFormate(result.data.CurrencyCount); // 扣减流量
        this.FlowPacketList.ResidueCount = Number(result.data.ResidueCount) == 0 ? '-' : this.$utils.flowFormate(result.data.ResidueCount); // 剩余总流量
        this.FlowPacketList.NumCount = Number(result.data.NumCount) == 0 ? '-' : this.$utils.flowFormate(result.data.NumCount); // 购买流量
        this.FlowPacketList.UseNumCount = Number(result.data.UseNumCount) == 0 ? '-' : this.$utils.flowFormate(result.data.UseNumCount); // 消耗流量
        this.FlowPacketList.DirectionalCount = Number(result.data.DirectionalCount) == 0 ? '-' : this.$utils.flowFormate(result.data.DirectionalCount); // 门店扣减
        console.log(this.FlowPacketList, 'this.FlowPacketList');
      })
        .catch((error) => {
          console.log('errorGetFluxPackageRangeForCount', error);
        });
    },
    clearSearchCondition () {
      this.searchObj.FluxPackageTypeValue = '全部';
      this.searchObj.FluxPackageType = '0';
      this.searchObj.pageIndex = 0;
      this.search();
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    search () {
      console.log(this.searchObj.PageDataList, 'this.searchObj.PageDataList');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // table 按钮点击
    doAfterTableBtnClick (item) {
      console.log(this.dataInfo, 'dataInfodataInfo');
      let obj = {
        StartTime: this.searchObj.startTime,
        EndTime: this.searchObj.endTime,
        OLAPKey: item.ExpendClientKey,
        SaaSClientKey: this.dataInfo.OLAPKey || this.$store.getters.SaaSClientInfo.OLAPKey
      };
      console.log(obj, 'obj');
      console.log(item, 'item');
      this.$dialog.open(this, {
        name: item.ExpendClientKeyValue + '-流量明细',
        routerName: this.$route.name,
        moduleName: 'flowPacketDetailReport',
        ModulePowerKey: '',
        data: { dataInfo: obj }
      });
    }
    // toBuy () {

    // }
  }
};
</script>

<style scoped>
.date_time .new_table_title_date.left_type {
  font-size: 14px;
  width: 160px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 300px;
}
.date_time .new_table_title_date.left_type .date_last,
.date_time .new_table_title_date.left_type .date_next {
  width: 30px;
  height: 30px;
  background-size: 5px;
}
.date_time .new_table_title_date .date_last {
  background: url(../../../../../public/image/change_prev_defult.png) no-repeat
    center center;
}
.date_time .new_table_title_date .date_last:hover {
  background-image: url(../../../../../public/image/change_prev_light.png);
}
.date_time .new_table_title_date .date_next {
  background: url(../../../../../public/image/change_next_defult.png) no-repeat
    center center;
}
.date_time .new_table_title_date .date_next:hover {
  background-image: url(../../../../../public/image/change_next_light.png);
}
.date_time .date_text {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.date_time .cw_date_calendar {
  width: 117px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  top: -1px;
  left: 24px;
}
single-year-picker {
  padding-left: 8px;
  padding-right: 9px;
}
.date_time .cw_date_box {
  position: absolute;
  width: 100px;
  height: 28px;
  cursor: pointer;
  opacity: 0;
}
.el-icon-time {
  position: absolute;
  left: 35px;
}
.title_date_select {
  display: inline-block;
  width: 118px;
  height: 36px;
  border-radius: 4px;
  margin-left: 10px;
  vertical-align: top;
  left: 290px;
  position: absolute;
}

.title_date_select .el-input input {
  padding-left: 10px;
  height: 34px;
  width: 101px;
  vertical-align: top;
  padding-right: 20px;
  font-size: 13px;
}

.title_date_select::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  right: 6px;
  top: 15px;
  pointer-events: none;
}

.title_date_select.open::after {
  border-top-color: transparent;
  border-bottom-color: #ccc;
  top: 9px;
}

.title_date_select .el-select {
  z-index: 2;
}
.head {
  position: absolute;
  top: 70px;
  left: 20px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.HQflow_packet_total {
  top: 96px;
  line-height: 35px;
  background-size: 30px;
  width: 100%;
}
.payment_statistics_bottom {
  height: 760px;
}
.HQflow_Bill_date {
  box-sizing: border-box;
  width: 805px;
  height: 100px;
  margin: 0px auto;
  margin-top: 20px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #f1f7ff, #f1f7ff),
    linear-gradient(0deg, #f5f8ff, #f5f8ff), #f9f9fb;
  border: 1px solid #ececec;
  border-radius: 4px;
}
.FlowPacketList_div {
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
}
.FlowPacket_Count {
  flex: 1;
  text-align: center;
  border-right: 1px solid #ececec;
}
.FlowPacket_Count div {
  height: 20px;
}
.FlowPacket_Count div:nth-of-type(1) {
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
}
.FlowPacket_Count div:nth-of-type(2) {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
}
.FlowPacket_Count:nth-of-type(4) {
  border-right: none;
}
.course_statistics_search >>> .search_box {
  margin-right: 15px;
}
</style>

