<template>
  <div class="select_xu_checkbox_group_xuan list_credit_type"
       :class="{opt:dataInfo.isActive}"
       @click.stop="clickItem">
    <div class="checkbox_view">
      <span>{{dataInfo.StudentNameKeyValue}}</span>
    </div>
    <div class="flex flex_ai_c">
      <div class="reviewed_box">
        <div v-if="dataInfo.IsAssignHomework == 1"
             class="reviewed">

        </div>
      </div>
      <div class="list_type"
           :class="vType">
        {{dataInfo.AttenceStatusKeyValue}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },
  computed: {
    vType () {
      if (this.dataInfo.AttenceStatusKey == 2) {
        return 'type_gray';
      } else if (this.dataInfo.AttenceStatusKey == 3) {
        return 'type_green';
      } else if (this.dataInfo.AttenceStatusKey == 4) {
        return 'type_yellow';
      } else if (this.dataInfo.AttenceStatusKey == 5) {
        return 'type_orange';
      }
    }
  },
  props: {
    dataInfo: Object
  },
  methods: {
    clickItem () {
      if (!this.dataInfo.isActive) {
        this.dataInfo.isActive = true;
      } else {
        this.dataInfo.isActive = false;
      }
    }
  }
};
</script>

<style>
</style>
