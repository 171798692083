<template>
  <div class="course_arrange_manage_list"
       @click.stop="seletedItem"
       :class="item.isActive?'opt':''">
    <div class="list_number">
      {{vkeyIndex}}
    </div>
    <div class="list_info">
      <div class="list_name">{{item.CourseNameKeyValue}}</div>
      <div class="list_text">班级：{{Number(item.GradeClassCount)}}个</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object,
    keyIndex: Number
  },
  computed: {
    vkeyIndex () {
      let num = this.keyIndex + 1;
      if (num < 10) {
        return '0' + num;
      } else {
        return num;
      }
    }
  },
  methods: {
    seletedItem () {
      this.$emit('seletedItem', this.item);
    }
  }
};
</script>
