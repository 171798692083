<!-- 关注 -->
<template>
  <el-table-column :fixed="columnData.fixed||false"
                   :label="columnData.label"
                   :prop="columnData.prop"
                   :width="columnData.width||50"
                   :align="columnData.align||'left'"
                   :label-class-name="columnData.columnClass"
                   :key="columnData.prop">
    <template slot-scope="scope">
       <div class="subscribe">
        <!-- 未关注 -->
        <div v-if="Number(scope.row[columnData.prop])==0"
             class="subscribe_icon no_type">
        </div>
        <!-- 已关注 -->
        <div v-if="Number(scope.row[columnData.prop])>0"
             class="subscribe_icon cp" @click="clickEvent(scope.row,$event)"></div>
      </div>
    </template>
  </el-table-column>
</template>

<script>
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          type: String,
          extend: {
            studentKey: 'StudentKey',
            studentKeyValue: 'StudentKeyValue',
            click: () => { }
          }
        };
      }
    }
  },
  created () {
  },
  methods: {
    clickEvent (rowData) {
      // 表示是否支持点击
      if (this.columnData.extend && typeof this.columnData.extend.click == 'function') {
          // 普通点击事件 直接执行回调
        this.columnData.extend.click(rowData);
      }
    }
  }
};
</script>

<style>
</style>