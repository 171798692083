<template>
     <div>
        <div class="form_info_edit form_info_list">
            <input-text :formTitle="'姓名'"
                  :required="true"
                  :readonly="isReadonly"
                  v-model="dataJson.MainDemoName"></input-text>

            <form-select :formTitle="'性别'"
                           :required="true"
                           :readonly="isReadonly"
                           :dataItem="dataJson.SexKeyValue"
                           :dataList="sexData"
                           v-model="dataJson.SexKeyValue"
                           @choseItem="changeSexData"></form-select>
             <div id="screanContent"
                   style="z-index:1;position: absolute !important;right: 45px; opacity: 0"></div>
            <input-upload-img :ImgURl="dataJson.HeadImgSupport?dataJson.HeadImgSupport:''"
                                :formTitle="'头像'"
                                :imgHeight="101"
                                :imgWidth="101"
                                :readonly="isReadonly"
                                @doSetPicCorp="doSetPicCorpHeadImg"></input-upload-img>

          
           
        </div>
         <save-cancel-btn-group :isSingleSubmit="true"
                                 :singleSubmitType="'dynamic'"
                                 @confirmClick="confirmClick"
                                 @cancelClick="cancelClick"></save-cancel-btn-group>
     </div>
</template>
<script>
import { CWSAddStudent,CWSUpdateStudent} from "../../../../API/workbench";
import formSelect from '../../../form-item/form-select';
export default {
    data(){
        return{
             sexData: [
        { key: 2, value: '男' },
        { key: 3, value: '女' }
      ],
            // 提交数据对象
            dataJson: {
                MainDemoName: '', // "投资人姓名",
                SexKey: '2', // "性别",
                SexKeyValue: '男', // "2-男;3-女",
                HeadImgSupport:''
            },
      
        }
    },
    components:{
        formSelect,
    },
    props:{
        dataInfo:Object,
        isReadonly:Boolean
    },
    created(){
        if(this.dataInfo){
            this.dataJson.OLAPKey=this.dataInfo.OLAPKey
            this.dataJson.MainDemoName=this.dataInfo.MainDemoName
              this.dataJson.SexKey=Number(this.dataInfo.SexKey)||'2'
                this.dataJson.SexKeyValue=this.dataInfo.SexKeyValue||'男'
                  this.dataJson.HeadImgSupport=this.dataInfo.HeadImgSupport
        }
    },
    computed:{
    },
    methods:{
           // 员工头像地址
        doSetPicCorpHeadImg (Url) {
            this.dataJson.HeadImgSupport = Url;
        },
          // 性别显示
        changeSexData (val) {
            this.dataJson.SexKey = val.key;
            this.dataJson.SexKeyValue = val.value;
        },

        verifyData(){
            let isFail=false
            if(!this.dataJson.MainDemoName){
                isFail=true
                layer.alert("请输入名字")
            }
            return isFail
        },   
        confirmClick(callBack){
            if(this.verifyData()){
                if(callBack){
                    callBack()
                }
                return false
            }
            if(this.dataInfo){       //修改
                CWSUpdateStudent(this.dataJson).then(result=>{
                    console.log("CWSUpdateStudent",result)
                       this.$emit("afterSuccess",result.data)
                        this.$emit("cancelClick")
                }).catch(error=>{
                      layer.alert(error.msg)
                    if(callBack){
                    callBack()
                }
                    console.log("errorCWSUpdateTeacher",error.msg)
                })
            }else{                 //新增
                CWSAddStudent(this.dataJson).then(result=>{
                    console.log("CWSStudentLeave",result)
                    layer.alert("新增成功")
                     this.$emit("afterSuccess",result.data)
                        this.$emit("cancelClick")
                }).catch(error=>{
                      layer.alert(error.msg)
                    if(callBack){
                    callBack()
                }
                    console.log("errorCWSAddTeacher",error.msg)
                })
            }
        },
        cancelClick(){
            this.$emit("cancelClick")
        }
    }
}
</script>