<template>
  <div class="modulebox clearfix">
    <div class="chartbox">
      <div class="tit">透视出勤不足学生<span @click="setup">设置</span></div>
      <echart-pie :echartID="'echart3'"
                  ref="echart3"
                  :dataList="echartList"></echart-pie>
    </div>
    <div class="listbox">
      <div class="roomlist clearfix"
           style="position: relative;">
        <div v-for="(item, index) in echartList"
             :key="index"
             class="block"
             :class="search.TypeKey==index+1 ? 'cur' : ''"
             @click="roomClick(index)">
          <div>{{item.name}}</div>
          <p>{{item.value}}人</p>
        </div>
        <a class="btn btn_3"
           @click="doExportInfoClick">导出</a>
      </div>
      <div class="course_statistics_table_box table_list_content">
        <table-list class="summarizing_list"
                    :tableData="tableData"
                    :totalNum="totalNum"
                    :PageCount="PageCount"
                    :tableColumns="tableColumns"
                    :queryParams="search"
                    :footerContent="vFooterContent"
                    @loadTableData="getList"></table-list>
      </div>
    </div>
    <custom-dialog title="学生考勤记录"
                   width="1200px"
                   :visible.sync="isShowDetail"
                   :before-close="closeDetail">
      <attendance-student-detail :student="dataDetail"></attendance-student-detail>
    </custom-dialog>
  </div>
</template>

<script>
import { GetSaaSClientMsg, GetPotentialStudentData, GetPotentialStudentlist } from '../../../API/workbench';
import echartPie from './echart-pie';
import tableList from '../../common/table-list/index';
import attendanceStudentDetail from './attendance-student-detail';
export default {
  data () {
    return {
      echartList: [
        {
          value: 0,
          name: '低出勤',
          itemStyle: { color: '#FE9647' }
        },
        {
          value: 0,
          name: '中出勤',
          itemStyle: { color: '#519CF7' }
        },
        {
          value: 0,
          name: '高出勤',
          itemStyle: { color: '#7BE070' }
        }
      ],
      search: {
        TypeKey: 1,
        pageIndex: 0,
        pageSize: 5,
        IsExportKey: 0
      },
      tableData: [],
      totalNum: 0,
      PageCount: 0,
      isShowDetail: false,
      dataDetail: {
        key: '',
        name: '',
        tel: '',
        remark: '',
        grade: 30
      },
      tableColumns: [
        {
          label: '学生名',
          prop: 'StudentNameKeyValue',
          width: 90,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item',
          extend: {
            click: (rowData) => {
              this.openDetail(rowData);
            }
          }
        },
        {
          label: '学管师',
          prop: 'AdviserStudentKeyValue',
          width: 90,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '排课',
          prop: 'CourseArrangeCount',
          width: 90,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '出勤',
          prop: 'Attence3Count',
          width: 90,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '出勤率',
          prop: 'Attence',
          width: 90,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '请假',
          prop: 'Attence4Count',
          width: 90,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '旷课',
          prop: 'Attence5Count',
          width: 90,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '未考勤',
          prop: 'Attence2Count',
          width: 90,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '排课备注',
          prop: 'ArrangeSupport',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        }
      ]
    };
  },
  components: {
    echartPie,
    tableList,
    attendanceStudentDetail
  },
  created () {
  },
  mounted () {
    this.getGrade();
    this.getChart();
    this.getList();
  },
  computed: {
    vFooterContent () {
      return '共 ' + this.totalNum + ' 条数据';
    }
  },
  methods: {
    getGrade () {
      let that = this;
      GetSaaSClientMsg().then(result => {
        if (result.code == 0) {
          let list = result.data.SaaSClientSetting;
          for (let i = 0; i < list.length; i++) {
            if (list[i].SetTypeKey == 103) {
              that.dataDetail.grade = parseInt(list[i].SetContent);
            }
          }
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    draw () {
      this.$refs.echart3.echartInit();
    },
    getChart () {
      let that = this;
      GetPotentialStudentData().then(result => {
        if (result.code == 0) {
          that.echartList[0].value = parseInt(result.data.LowAttendance);
          that.echartList[1].value = parseInt(result.data.MediumAttendance);
          that.echartList[2].value = parseInt(result.data.HighAttendance);
          that.$nextTick(() => {
            that.draw();
          });
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    roomClick (index) {
      let that = this;
      that.search.TypeKey = index + 1;
      this.search.pageIndex = 0;
      this.getList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.tableData.length > 0) {
        this.search.IsExportKey = 1;
        this.search.pageIndex = 0;
        this.search.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '透视出勤不足学生',
          clist: [
            { title: '学生名', cName: 'StudentNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学管师', cName: 'AdviserStudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '排课', cName: 'CourseArrangeCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '出勤', cName: 'Attence3Count', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '出勤率', cName: 'Attence', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '请假', cName: 'Attence4Count', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '旷课', cName: 'Attence5Count', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '未考勤', cName: 'Attence2Count', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '排课备注', cName: 'ArrangeSupport', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        GetPotentialStudentlist(this.search, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.search.IsExportKey = 0;
          this.search.pageIndex = 0;
          this.search.pageSize = 10;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getList () {
      let that = this;
      GetPotentialStudentlist(that.search).then(result => {
        if (result.code == 0) {
          let list = result.data.PageDataList;
          for (let i = 0; i < list.length; i++) {
            list[i].Attence = list[i].Attence + '%';
            list[i].AdviserStudentKeyValue = list[i].AdviserStudentKeyValue ? list[i].AdviserStudentKeyValue : '-';
          }
          that.totalNum = result.data.Total;
          that.PageCount = result.data.PageCount;
          that.tableData = list;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    openDetail (row) {
      let that = this;
      that.dataDetail.key = row.StudentNameKey;
      that.dataDetail.name = row.StudentNameKeyValue;
      that.dataDetail.tel = row.CustomerPhoneName;
      that.dataDetail.remark = row.ArrangeSupport;
      that.isShowDetail = true;
    },
    closeDetail () {
      this.isShowDetail = false;
    },
    setup () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '设置学生出勤率级别',
        moduleName: 'courseSettingItem',
        data: { editItem: { attendanceNum: 1 } },
        callBack: {
          afterSuccess: () => {
            this.search.pageIndex = 0;
            this.getList();
            this.getChart();
            this.getGrade();
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.btn_3 {
  position: absolute;
  color: #fff;
  background-color: #2d98d9;
  border: 1px solid #2886bf;
  padding: 0 20px;
  margin: 0;
  right: 0;
  top: 10px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: 10px;
  cursor: pointer;
}
</style>