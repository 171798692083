<template>
  <!-- 有效期 -->
  <!-- "TypeKey" : 门店类型, "TypeKeyValue":"", "SaaSClientNO":"门店id", "ParentKey" : "所属总店", "ParentKeyValue" : "所属总店" -->
  <div>
    <div class="form_info_edit form_info_list ">
      <input-select :formTitle="'门店类型'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :readonly="false"
                    :dataList="mdType"
                    :selectedInfo="vMdType"
                    @selectedItem="changeMdTypee"></input-select>

      <input-dialog :formTitle="'所属总店'"
                    :formPlaceholder="'选填'"
                    :required="true"
                    :readonly="false"
                    v-model="formData.ParentKeyValue"
                    @showEldilog="selectParentShop"></input-dialog>

      <input-number-alphabet v-if="formData.ParentKey"
                             :formTitle="'门店ID'"
                             :required="true"
                             :readonly="false"
                             :formPlaceholder="'请输入'"
                             :formInputHint="formData.ParentKey + '-'"
                             v-model="formData.SaaSClientNO"></input-number-alphabet>

    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="submitDate"
                           @cancelClick="closeFrom"></save-cancel-btn-group>

  </div>
</template>
<script>
import {
  EditlinkSaaSClient, SelectHQSaaSClient
} from '../../../../API/workbench.js';
export default {
  data () {
    return {
      formData: {
        TypeKey: '', // 门店类型,
        TypeKeyValue: '', //
        SaaSClientNO: '', // "门店id",
        ParentKey: '', // "所属总店",
        ParentKeyValue: '' // "所属总店"
      },
      mdType: [
        { type: 7, name: '连锁加盟' },
        { type: 8, name: '连锁直营' }
      ]
    };
  },

  props: {
    mendianInfo: Object // 门店信息
  },
  computed: {
    vMdType () {
      return {
        type: this.formData.TypeKey,
        name: this.formData.TypeKeyValue
      };
    }
  },
  created () {
    console.log(this.mendianInfo, '门店信息');
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
  },
  watch: {

  },
  methods: {
    selectParentShop () {
      SelectHQSaaSClient().then(result => {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择所属总店',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.formData.ParentKey, dataSourceList: result.data, required: false },
          callBack: {
            onSelectedItem: (data) => {
              this.formData.ParentKey = data.OLAPKey;
              this.formData.ParentKeyValue = data.MainDemoName;
            }
          }
        });
      }, err => {
        layer.alert(err.msg);
      });
    },
    changeMdTypee (obj) {
      this.formData.TypeKey = obj.type;
      this.formData.TypeKeyValue = obj.name;
    },
    //  关闭
    closeFrom () {
      this.$emit('closeFrom');
    },
    // 提交
    submitDate (callBack) {
      if (callBack) {
        callBack();
      }
      if (!this.formData.TypeKey) {
        layer.alert('请选择门店类型');
      } else if (!this.formData.ParentKey) {
        layer.alert('请选择所属总店');
      } else {
        EditlinkSaaSClient(this.mendianInfo.OLAPKey, this.formData).then(result => {
          this.$emit('afterSuccess', 'Edit', result.data);
          layer.alert('关联成功！');
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      }
    }
  }
};
</script>


