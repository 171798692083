<template>
  <div>
    <div class="popup_dialog_face "
         v-dialogDrag
         :style="isShow">
      <!-- 弹出框 -->
      <div class="popup_dialog_box popup_dialog_box_of_face"
           :class="popupDialogBoxStyle?'':'popup_dialog_face_inside'"
           :style="popupDialogBoxStyle">
        <!-- 表头 -->
        <div class="popup_dialog_box_header"
             v-show="title">
          <span class="popup_dialog_box_header_title">{{title}}</span>
          <div class="popup_dialog_box_header_btn"
               @click.stop="handleClose"></div>
        </div>
        <!-- 内容 -->
        <div class="popup_dialog_box_content clearfix">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      popupDialogBoxStyle: null,
      clickEle: ''
    };
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    showPosition: {
      type: String,
      default: 'top'
    },
    visible: {
      type: Boolean,
      default: 'false'
    },
    beforeClose: Function,
    width: String,
    top: {
      type: [Number, String],
      default: 30
    },
    upTop: {
      type: [Number, String],
      default: 0
    },
    closeOnClickModal: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isShow () {
      if (this.visible) {
        return { 'visibility': 'visible' };
      } else {
        return { 'visibility': 'hidden' };
      }
    }
  },
  created () {
    if (this.visible) {
      this.initPopupDialogBoxStyle();
    }
  },
  mounted () {
    if (this.appendToBody) {
      document.body.appendChild(this.$el);
    }
  },
  watch: {
    visible (n, o) {
      if (n) {
        this.$store.commit('setFaceSign', true);
        this.initPopupDialogBoxStyle();
        // this.$nextTick(() => {
        //   this.appendDialogToElement();
        // });
      } else {
        this.$store.commit('setFaceSign', false);
        this.hide();
      }
    },

    width (n, o) {
      if (n != o) {
        this.popupDialogBoxStyle.width = n;
      }
    }
  },
  methods: {
    initPopupDialogBoxStyle () {
      let top = 0;
      this.$nextTick(() => {
        this.popupDialogBoxStyle = {};
        if (this.width) {
          this.popupDialogBoxStyle.width = this.width;
        }
        let dialogHeight = this.$el.querySelector('.popup_dialog_box').offsetHeight;
        if (this.showPosition == 'visibleMiddle') {
          top = document.documentElement.scrollTop + document.documentElement.clientHeight / 2 - dialogHeight / 2;
        } else if (this.showPosition == 'top') {
          top = document.documentElement.scrollTop + Number(this.top) + 50;
        } else if (this.showPosition == 'bottom') {
        } else if (this.showPosition == 'clickNearby') {
        }
        if (this.upTop == 0) {
          this.popupDialogBoxStyle.top = parseInt(top) + 'px';
        } else {
          this.popupDialogBoxStyle.top =
            parseInt(top) - Number(this.upTop) + 'px';
        }
        this.setMaxZIndex();
      });
    },
    setMaxZIndex () {
      let maxZIndex = this.caculateMaxZIndex();
      this.popupDialogBoxStyle.zIndex = maxZIndex + 2;
      this.$store.commit('SetPopupMaxZIndex', maxZIndex + 1);
    },
    caculateMaxZIndex () {
      let shadeDiv = document.querySelector('.popup_dialog_shade');
      if (!shadeDiv) {
        this.$store.commit('SetPopupShade', true);
      }
      let curRouterElement = document.getElementById(this.$route.name);
      let divs = curRouterElement.querySelectorAll('.popup_dialog_box');
      let max = 0;
      for (let i = 0; i < divs.length; i++) {
        let divEL = divs[i];
        max = Math.max(max, divEL.style.zIndex || 0);
      }
      if (max <= 0) {
        max = 20;
      }
      return max;
    },
    // 把弹窗添加到指定元素上
    appendDialogToElement () {
      document.body.appendChild(this.$el);
    },
    handleClose () {
      if (typeof this.beforeClose === 'function') {
        this.beforeClose();
        this.hide();
      }
    },
    hide () {
      let shadeDiv = document.querySelector('.popup_dialog_shade');
      if (shadeDiv) {
        // 关闭时 把zIndex设置为零 下次打开重计算
        if (this.popupDialogBoxStyle) {
          this.popupDialogBoxStyle.zIndex = 0;
        }
        let maxZIndex = this.caculateMaxZIndex();
        this.$store.commit('SetPopupMaxZIndex', maxZIndex - 4);
      }
    },

    destroyed () {
      if (this.appendToBody && this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
    }
  }
};
</script>
<style>
</style>