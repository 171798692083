<template>
  <!-- 班级设置项 -->
  <div>
    <div class="content_marginRL content_marginTop_30">
      <!-- 内容 -->
      <div class="form_info_edit mendian_setting_content single_shadow"
           :class="{btn_marginBottom_30:isReadonly}">
        <div>
          <input-text :formTitle="'班级名称'"
                      :readonly="isReadonly"
                      :formPlaceholder="'班级名称'"
                      :required="true"
                      v-model="dataJson.GradeClassKeyValue"></input-text>

          <input-dialog :formTitle="'所属班级分类'"
                        :formPlaceholder="'单选'"
                        :readonly="isReadonly"
                        @showEldilog="openGradeTypeList"
                        v-model="dataJson.GradeTypeKeyValue"></input-dialog>

          <input-dialog :formTitle="'上课课程'"
                        :helpDescription="'选填项，填写关联后建课时会自动填写课程，也可更换'"
                        :required="false"
                        :readonly="isReadonly"
                        v-model="dataJson.ClassSetKeyValue"
                        @showEldilog="openCourseNameList"></input-dialog>

          <input-dialog :formTitle="'主讲老师'"
                        :helpDescription="'选填项，填写关联后建课时会自动填写老师，也可更换。'"
                        :readonly="isReadonly"
                        @showEldilog="doZjlsDialogShow"
                        v-model="dataJson.LecturerKeyValue"></input-dialog>

          <div v-if="vIsSysVersionTY"
               class="pr">
            <input-switch v-model="dataJson.IsSyncGradeClassKey"
                          :formTitle="'是否全班一起上课'"
                          :readonly="isReadonly"
                          :helpDescription="'开启后建立的课表班内所有学生直接会跟排进课，不开启建立的课表没有学生安排，上课学生可以再安排。'"
                          :required="false"></input-switch>
          </div>
          <div class="full_class_box"
               style="border-bottom: 1px solid #ececec;">
            <div class="full_class_text">招生满班人数 <span>统计满班率</span></div>
            <div>
              <input type="number"
                     v-model="dataJson.WantPeopleCount"
                     class="full_class_input">
              <span class="people_class">人/班</span>
            </div>
          </div>
          <!-- <div class="full_class_box">
            <div class="full_class_text">满编人数</div>
            <div>
              <input type="number"
                     v-model="dataJson.EnrollmentCount"
                     class="full_class_input">
              <span class="people_class">人/班</span>
            </div>
          </div> -->

        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <div v-if="!isReadonly"
         class="">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
    <div v-else
         class="on_form_footer_btn_group"></div>
    <!-- <div class="search_box" style="height:100%"> -->
    <custom-dialog :title="'选择主讲老师'"
                   class="new_class defult_dialog"
                   :visible.sync="isOptionForm"
                   :before-close="doCloseChildForm">

      <btn-add-select :addTitleName="'新增'"
                      :addmoduleName="'yuangong-setting'"
                      :addmoduleTitleName="'添加老师'"
                      :addmoduleKey='44'
                      @doAddSuccessEvent="doAddTeacherSuccessEvent"></btn-add-select>

      <new-single-selection-list :formTitle="'选择老师'"
                                 :isShowHead="true"
                                 :selectionKey="dataJson.LecturerKey"
                                 :curModuleName="'teacher'"
                                 :dataSourceLsit="dataSourceTeacherList"
                                 @selectedItem="selectInfo"
                                 @onBack="returnBox"></new-single-selection-list>

    </custom-dialog>
  </div>

</template> 

<script>
import newSingleSelectionList from '../../../common-module/new-single-selection-list';
import { GradeClassAdd, editClass, geTteacherList, getCourseTypeNameList, GetTheClassByID, MessageSearchPageForGradeType } from '../../../../API/workbench';
export default {
  // 提供外部输出显示，不给就输出内部默认显示
  props: {
    isReadonly: {// 是否只读
      type: Boolean,
      default: false
    },
    editItem: {// 修改项
      type: Object,
      default: function () {
        return {};
      }
    },
    callBackFunction: Function
  },
  data () {
    return {
      newInputText: '0', // 默认值

      dataJson: {
        GradeClassKey: '', // int, --班级ID（修改）
        GradeClassKeyValue: '', // string, --班级名称（修改）
        WantPeopleCount: '', // int, --计划招生数
        EnrollmentCount: '',
        LecturerKeyValue: '', // string, --主讲老师
        LecturerKey: '', // string, --主讲老师ids
        ClassSetKey: '', // string, --开设课程id
        ClassSetKeyValue: '', // string, --开设课程
        GradeTypeKey: '', // 数据源：GET	班级分类
        GradeTypeKeyValue: '', // 数据源：GET	班级分类
        IsSyncGradeClassKey: '0'// 是否全班一起上课
      },
      // 数据对象
      dataSourceTeacherList: [], // 老师列表
      isOptionForm: false,
      params: {
        pageIndex: 0, // 每页记录数，0：表示查询所有
        pageSize: 0, // 页码，0为第一页
        searchText: '', //	搜索条件：模糊匹配姓名、昵称、学号、客户手机号、客户名 课
        orderBy: '', // 排序字段
        sequence: '' // 排序字段
      }
    };
  },

  components: {
    newSingleSelectionList
  },
  computed: {
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;// true：通用版，false精准版
    },
    vCourseNameList () {
      return (this.$store.getters.courseNameList && this.$store.getters.courseNameList.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; })) || [];
    }
  },
  watch: {
    vCourseNameList (n, o) {
      this.getCourseNameList();
    }
  },
  created () {
    if (JSON.stringify(this.editItem) != '{}') {
      console.log(this.editItem, 'created');
      if (this.editItem.OLAPKey) {
        this.getTheClassInfo(this.editItem.OLAPKey);
      } else if (this.editItem.GradeTypeKey && Number(this.editItem.GradeTypeKey) > 2) { // 所属班级分类
        this.dataJson.GradeTypeKey = this.editItem.GradeTypeKey;
        this.dataJson.GradeTypeKeyValue = this.editItem.GradeTypeKeyValue;
      }
    } else {
      this.dataJson.IsSyncGradeClassKey = '0';
    }
    this.getCourseNameList(); // 渲染列表数据
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
  },
  methods: {
    getTheClassInfo (OLAPKey) {
      GetTheClassByID(OLAPKey).then(result => {
        if (result.data.ClassSetKey) {
          let isExists = this.vCourseNameList.find(obj => { return obj.OLAPKey == result.data.ClassSetKey; });
          if (isExists) {
            this.dataJson.ClassSetKey = result.data.ClassSetKey;
            this.dataJson.ClassSetKeyValue = result.data.ClassSetKeyValue;
          }
        }
        this.dataJson.GradeClassKey = result.data.OLAPKey;
        this.dataJson.GradeClassKeyValue = result.data.MainDemoName;
        this.dataJson.WantPeopleCount = Number(result.data.WantPeopleCount);
        this.dataJson.EnrollmentCount = Number(result.data.EnrollmentCount);
        this.dataJson.LecturerKeyValue = result.data.LecturerKeyValue;
        this.dataJson.LecturerKey = result.data.LecturerKey;
        this.dataJson.GradeTypeKey = this.editItem.GradeTypeKey;
        this.dataJson.GradeTypeKeyValue = this.editItem.GradeTypeKeyValue;
        this.dataJson.IsSyncGradeClassKey = result.data.IsSyncGradeClassKey;
        console.log(result.data, 'getTheClassInfo');
      });
    },
    // 新增老师
    doAddTeacherSuccessEvent (result) {
      console.log(result, '新增回调');
      // 接收选中值.
      this.selectInfo(result);
    },
    getCourseNameList () {
      getCourseTypeNameList().then(result => {
        this.$basicData.loadCourseNameList();
      });
    },
    // 打开课程列表
    openCourseNameList () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '选择上课课程',
        moduleName: 'singleSelectionList',
        data: { selectedKey: this.dataJson.ClassSetKey, dataSourceList: this.vCourseNameList, addModuleName: 'course-name-setting' },
        callBack: {
          onSelectedItem: (data) => { this.doAfterSelectClassSet(data); }
        }
      });
    },
    // 选择课程
    doAfterSelectClassSet (item) {
      this.dataJson.ClassSetKey = item.OLAPKey;
      this.dataJson.ClassSetKeyValue = item.MainDemoName;
    },
    // 确认按钮
    confirmClick (callBack) {
      console.log(callBack, typeof callBack == 'function', '确认按钮');
      let flag = false;
      if (!this.dataJson.GradeClassKeyValue.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('请输入名称');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (this.editItem && this.editItem.OLAPKey > 0) {
        editClass(this.dataJson).then(result => {
          layer.alert('修改成功');
          this.$emit('afterSuccess', 'Edit', result.data);
          this.cancelClick();
          this.$bus.emit('AfterClassGradeAddOrEdit', 'Edit', result.data);
          console.log(this.callBackFunction, 'this.callBackFunction');
          if (typeof this.callBackFunction == 'function') {
            this.callBackFunction();
          }
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      } else {
        GradeClassAdd(
          this.dataJson.GradeClassKeyValue,
          this.dataJson.WantPeopleCount,
          this.dataJson.EnrollmentCount,
          this.dataJson.LecturerKeyValue,
          this.dataJson.LecturerKey,
          this.dataJson.ClassSetKey,
          this.dataJson.ClassSetKeyValue,
          this.dataJson.GradeTypeKey,
          this.dataJson.GradeTypeKeyValue,
          this.dataJson.IsSyncGradeClassKey
        ).then(result => {
          this.$emit('afterSuccess', 'Add', result.data);
          this.cancelClick();
          this.$bus.emit('AfterClassGradeAddOrEdit', 'Add', result.data);
          if (typeof this.callBackFunction == 'function') {
            this.callBackFunction();
          }
          if (result.data.IsSyncGradeClassKey == 1) {
            layer.confirm('新增成功,是否要继续安排学生进班', {
              btn: [{
                name: '取消',
                callBack: function () {
                  layer.close();
                }
              }, {
                name: '确认',
                callBack: () => {
                  layer.close();
                  let item = {
                    ModulePowerKey: 53,
                    auth: true,
                    name: '班级排课',
                    roleName: '',
                    routerName: 'classGradeApplycourse',
                    routertitle: '班级排课',
                    dataKey: result.data.OLAPKey,
                    data: { isAddStudent: true }
                  };
                  this.$bus.emit('needOpenRouter', item);
                }
              }]
            });
          } else {
            layer.alert('新增成功');
          }
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      }
    },
    // 取消按钮
    cancelClick () {
      this.$emit('cancelClick');
      this.$store.commit('setShowTimetableId', 0);
    },
    // 子组件弹出层取消
    doCloseChildForm () {
      this.isOptionForm = false;
    },
    // 打开班级类别列表
    openGradeTypeList () {
      MessageSearchPageForGradeType(this.params).then(result => {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择所属班级分类',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.dataJson.GradeTypeKey, dataSourceList: result.data.PageDataList },
          callBack: {
            onSelectedItem: (data) => { this.doAfterSelectClassTypeSet(data); }
          }
        });
        this.isShowGradeTypeList = true;// 选择所属班级分类
      });
    },
    // 选择班级类别
    doAfterSelectClassTypeSet (item) {
      this.dataJson.GradeTypeKey = item.OLAPKey;
      this.dataJson.GradeTypeKeyValue = item.MainDemoName;
    },
    // 选择主讲老师
    doZjlsDialogShow () {
      // 调用在职老师接口
      geTteacherList().then(result => {
        this.dataSourceTeacherList = result.data;
        // this.$parent.isShowMainForm = false;
        this.isOptionForm = true;
        this.$store.commit('setShowTimetableId', this.dataJson.LecturerKey);
      });
    },
    returnBox () {
      this.isOptionForm = false;
    },
    selectInfo (item) {
      console.log(item, '3');
      this.dataJson.LecturerKey = item.OLAPKey || '';// --主讲老师ids
      this.dataJson.LecturerKeyValue = item.MainDemoName || '';// --主讲老师
      this.$store.commit('setShowTimetableId', item.OLAPKey);
      this.returnBox();
    }
  }
};
</script>

  <style scoped>
.full_class_box {
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin-top: 15px;
}

.full_class_input {
  border: 1px solid #ccc;
  width: 60px;
  text-align: center;
  border-radius: 3px;
  color: #3498db;
}

.full_class_text {
  font-size: 14px;
  color: #666;
}
.full_class_text span {
  font-size: 10px !important;
  color: #ccc !important;
}
</style>