  <template>
  <!-- 微消息 -->
  <div>
    <custom-dialog title="微消息"
                   :visible.sync="microMessageDialogVisible"
                   :before-close="closeMicroMessage">
      <div class="form_info_detail micro_message">
        <div class="micro_message_consignee content_marginTop_30 content_marginRL single_shadow">
          <div class="micro_message_field">
            <span class>收件人：</span>
          </div>
          <div class="micro_message_value">
            <span>{{addresseeItem.CustomerKeyValue}}</span>
          </div>
        </div>
        <div class="micro_message_edit content_marginRL single_shadow">
          <div class="message_edit_input">
            <textarea name
                      id
                      cols="30"
                      rows="10"
                      placeholder="请输入消息内容..."
                      v-model="messageContent"></textarea>
          </div>
        </div>
        <!-- 按钮组 -->
        <div class="footer_btn_group form_footer_btn_group">
          <el-button round
                     class="btn_light_red btn_hover_white"
                     @click.stop="ButtonEvent(false)">取消</el-button>
          <el-button round
                     class="btn_light_blue btn_hover_bg_blue"
                     @click.stop="ButtonEvent(true)">确认</el-button>
        </div>
      </div>
    </custom-dialog>
  </div>
</template>
<script>
import { microMessagePush } from '../../API/workbench.js';
export default {
  name: 'succeedPayment',
  props: ['microMessageDialogVisible', 'addresseeItem'],
  data () {
    return {
      messageContent: '' // 推送内容
    };
  },
  created () {
    // if (this.microMessageDialogVisible ==) {
    // }
  },
  methods: {
    closeMicroMessage () {
      this.$emit('closeMicroMessage');
    },
    // 确定按钮
    ButtonEvent (Val) {
      // debugger
      if (Val == true) {
        if (!this.messageContent) {
          layer.alert('消息内容不能为空.');
          return false;
        }
        microMessagePush(this.addresseeItem.StudentKey, this.messageContent)
          .then(result => {
            console.log(result, 'result');
            if (result.data.errcode == 0) {
              this.$emit('ButtonEvent', Val);
            }
            // else {
            //   layer.alert(result.data.errtip);
            // }
          })
          .catch(error => {
            layer.alert(error.msg || error.data.errtip);
          });
      } else {
        this.$emit('ButtonEvent', Val);
      }
      this.messageContent = '';
    }
  }
};
</script>
 
