<template>
  <div class="WKT_activity_compile_popup">
    <editor-view ref="editor"
                 :dataInfo="playInfo"
                 :CouponInfo="couponInfo"
                 :PosterInfo="posterInfo"></editor-view>
    <activities-info :isShowPlay="isShowPlay" :dataInfo="couponInfo" @closePopup="closePopup" @refreshActivityInfo="getActivitiesPlay"></activities-info>
  </div>
</template>
<script>
import { GetActivitiesCouponInfo, GetActivitiesPlayByID,GetActivitiesPlayInfo } from '../../../API/workbench.js';
import editorView from '../component/ishow-editor-dialog/component/editor-view';
import activitiesInfo from './component/activities-info';
export default {
  data () {
    return {
      playInfo: {},
      couponInfo: {},
      posterInfo: {}
    };
  },
  props: {
    dataInfo: Object,
    coverInfo: Object,
    isShowPlay:{
      type:Boolean,
      default:true
    }
  },
  computed: {
    vSaasClientInfo () {
      return this.$store.getters.SaaSClientInfo;
    }
  },
  components: {
    editorView, activitiesInfo
  },
  created () {
    this.getActivitiesPlay();
  },
  methods: {
    closePopup () {
      this.$emit('closePopup');
    },
    getActivitiesCouponInfo () {
      GetActivitiesCouponInfo(this.dataInfo.id).then(res => {
        this.couponInfo = res.data.CouponInfo;
        this.initResult(res.data.PosterInfo);
        this.posterInfo = res.data.PosterInfo;
      }, err => {
        console.log(err.msg);
      });
    },
    initResult (data) {
      data.isTemplate = true;
      data.pageJson = JSON.parse(data.pageJson) || [];
      // 初始化获取.
      if (!data.settingJson) {
        data.settingJson = {
          footerNav: false,
          isMusic: false,
          musicTarget: {}
        };
      } else {
        console.log(data.settingJson, 'data.settingJson');
        data.settingJson = JSON.parse(data.settingJson);
        if (!data.settingJson.musicTarget) {
          data.settingJson.musicTarget = {};
        }
        if (!data.settingJson.isMusic) {
          data.settingJson.isMusic = false;
        }
        if (!data.settingJson.footerNav) {
          data.settingJson.footerNav = false;
        }
      }
      data.picTemp = data.picTemp || '';
      data.pageJson.forEach(o => {
        o.elJson.forEach(p => {
          p.isActive = false;
          p.animate = p.animate || [];
          p.isLock = p.isLock || false;
          p.width = Number(p.width) || 'auto';
          p.height = Number(p.height) || 'auto';
          p.form = p.form || {};
          p.specialName = p.specialName || '';
          p.fontSet = p.fontSet || {
            fontID: 0,
            fontFamily: '',
            fontFacePath: '',
            fontFaceID: ''
          };
          if (p.type == '204' || p.type == '205') {
            if (p.width == 'auto') {
              p.width = '185px';
            } else {
              p.width = p.width.toString() + 'px';
            }
          }
          if (p.type == '206' || p.type == '207') {
            if (p.width == 'auto') {
              p.width = '640px';
            } else {
              p.width = p.width.toString() + 'px';
            }
            // 轮播
            if (p.type == '206') {
              console.log('206', p.form);
              p.form = !Array.isArray(p.form) ? p.form : { imgList: [] };
            }
            // 封面
            if (p.type == '207') {
              if (this.coverInfo && !p.content) {
                data.pic = this.coverInfo.coverimg;
                p.content = this.coverInfo.coverimg;
              }
            }
          }
          if (p.type == '208') {
            p.form = {
              t_address: this.couponInfo.t_address,
              t_telephone: this.couponInfo.t_telephone,
              t_company: this.couponInfo.t_company,
              logo: p.form.logo
            };
          }
          p.content = p.content.toString();
          if (p.fontSet.fontID > 0) {
            this.loadOnlineFont(p.fontSet);
          }
        });
      });
      data.name = data.name || this.vSaasClientInfo.WktInfo.name;
      data.address = data.address || this.vSaasClientInfo.WktInfo.address;
      data.tel = data.tel || this.vSaasClientInfo.WktInfo.tel;
      return data;
    },
    // 在线字体load
    loadOnlineFont (fontSet) {
      console.log('this.$store.getters.PCWebURL', this.$store.getters.CDNURL + fontSet.fontFacePath);
      var f = new FontFace(fontSet.fontFamily, 'url(' + this.$store.getters.CDNURL + fontSet.fontFacePath + ')', {});
      f.load().then(loadedFace => {
        document.fonts.add(loadedFace);
      });
    },
    getActivitiesPlay () {
      if(this.dataInfo.playID>0){
        GetActivitiesPlayByID(this.dataInfo.playID).then(res => {
          res.data.posterTemplateID = 0;
          res.data.id = this.dataInfo.id;
          this.playInfo = res.data;
          this.getActivitiesCouponInfo();
          console.log(res, 'GetActivitiesPlayByID');
        }, err => {
          console.log(err.msg);
        });
      }else{
        this.getPlayInfo()
      }
    },
    getPlayInfo(){
      GetActivitiesPlayInfo(this.$route.query.TypeKey||1).then(result=>{
        result.data.posterTemplateID = 0;
          // result.data.id = this.dataInfo.id;
          this.playInfo = result.data;
        this.getActivitiesCouponInfo();
      }, err => {
          console.log(err.msg);
      });
    },
  }
};
</script>