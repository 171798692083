<template>
  <!-- 统计汇总信息 -->
  <div class="top_content_list"
       :class="dataInfo.listClassName"
       @click="doSelected">
    <div class="list_title">{{dataInfo.name}}</div>
    <div class="list_content"
         style="margin-top: 6px;"
         v-if="dataInfo.type=='progress'">
      <el-progress type="circle"
                   :width="60"
                   :percentage="vProgressValue"></el-progress>
    </div>
    <div class="list_content"
         :class="dataInfo.className"
         v-else>
      <span class="list_num"
            :class="dataInfo.numClass">{{dataInfo.value}}</span>
      <span v-if="dataInfo.unit"
            class="list_unit">{{dataInfo.unit}}</span>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
    };
  },
  props: {
    dataInfo: Object
  },
  created () {

  },
  computed: {
    vProgressValue () {
      if (this.dataInfo.type == 'progress') {
        return this.$utils.mMoneyType(this.dataInfo.value, 0);
      }
    }
  },
  methods: {
    doSelected () {
      if (this.dataInfo.numClass == 'font_blue') { // 蓝色默认支持点击
        this.$emit('doSelected', this.dataInfo);
      }
    }
  }
};
</script>

	<style>
.list_content .el-progress-circle {
  transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -o-transform: rotate(270deg);
}
.list_content .el-progress__text {
  color: #3498db;
}
</style>

