<template>
  <!-- <div v-if="isShowMainForm">
    <custom-dialog title="异常处理明细表"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowMainForm"
                   :before-close="doShowPopupHideClick"> -->
  <!-- 报名记录/潜在客户 -->
  <!-- <div class="course_block_gray">
          <span class="font_red">*</span>带<img class="tick" src="../../../../../public/image/open_green.png">学生是已报名学生。
      </div>-->
  <!-- <div class="course_block_gray">
          <span class="font_red">*</span>点击学生给学生排课和报课；点击可用课时等标题快捷排序。
      </div>-->
  <div class="content_marginTop_30 content_marginRL single_shadow btn_marginBottom_30 overflow_hidden">
    <div class="class_common_list_search ">
      <input type="text"
             v-model="searchData.searchText"
             placeholder="" />
      <div v-if="searchData.searchText.length > 0"
           class="search_btn_clear"
           @click="clearSearch">

      </div>
      <div class="search_btn"
           @click="search">搜索</div>
    </div>
    <div class="border_bottom">
      <div class="new_table_title_date"
           style="margin-top: -0.25rem;">
        <div @click="prodMounth"
             class="date_last fl"></div>
        <span class="date_text">{{vMonth[0]}}年{{vMonth[1]}}月</span>
        <div @click="nextMounth"
             class="date_next fr"></div>
      </div>
    </div>
    <div v-if="dataList.length>0"
         @scroll="scrollTOP"
         class="abnormal_handling_ul">
      <div class="abnormal_handling_list"
           v-for="(item,key) in dataList"
           :key="key">
        <div class="list_date">
          <span>{{item.GenerateTime}}</span>
          <span>{{item.OperationAgentKeyValue}}</span>
        </div>
        <div class="list_info">
          <span>对象：</span>
          <span class="font_gray">
            <span>{{item.DataKeyValue}}</span>
            <span>{{$utils.phoneModulePower(item.CustomerPhoneName)}}</span>
          </span>
        </div>
        <div class="list_info">
          <span>类型：</span>
          <span class="font_gray">{{item.OperationTypeKeyValue}}</span>
        </div>
        <div class="list_info">
          <span>内容：</span>
          <span class="font_gray"
                :title="item.OperationSupport">{{item.OperationSupport}}</span>
        </div>
        <div class="list_info">
          <span>原因：</span>
          <span class="font_gray">{{item.OperationReasonSupport||'--'}}</span>
        </div>
      </div>
    </div>
    <div class="course_block_nodata"
         style="padding-bottom:20px;height:506px"
         v-else>
      <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
    </div>
  </div>
  <!-- </custom-dialog>
  </div> -->
</template>
<script>
import { getAbnormalHandlingList } from '../../API/workbench';
export default {
  data () {
    return {
      searchData: {
        pageIndex: 0,
        pageSize: 20,
        searchText: '',
        orderBy: '',
        sequence: '',
        stutasKey: '',
        typeKey: '',
        startime: '',
        endtime: ''
      },
      toDate: '',
      dataList: [],
      isLoadEnd: false, // 判断滚动事件触发时是否接口已经处理完毕,处理完毕再执行滚动事件.
      isShowMainForm: false, // 显示
      noMore: false
    };
  },
  components: {},
  created () {
    // this.getPotentialCustomerData(this.searchData);
    this.getTheDate();
    this.getMonthTime();
    // this.doShowPopupShowClick();
  },
  computed: {
    vMonth () {
      let date = this.toDate;
      let arr = [];
      arr = date.split('-');
      return arr;
    }
  },
  watch: {
    'searchData.searchText': {
      deep: true,
      handler: function (c, o) {
        if (!c) {

          this.dataList = [];
          this.searchData.pageIndex = 0
          this.searchData.pageSize = 20
          this.noMore = false
          this.getPotentialCustomerData(this.searchData);
        }
      }
    }
  },
  methods: {

    getTheDate () {
      this.toDate = this.$utils.getCurrentMonthFirst(Date.new());
    },
    prodMounth () {
      this.toDate = this.$utils.getPreMonth(this.toDate);
      this.getMonthTime(this.toDate.split('-')[0], this.toDate.split('-')[1]);
    },
    nextMounth () {
      this.toDate = this.$utils.getNextMonth(this.toDate);
      this.getMonthTime(this.toDate.split('-')[0], this.toDate.split('-')[1]);
    },
    getMonthTime (year, month) {
      var date = Date.new();
      var year = year || date.getFullYear() + '';
      var month = month || (date.getMonth() + 1) + '';
      if (Number(month) < 10) {
        month = '0' + Number(month);
      }
      // 本月第一天日期
      var begin = year + '-' + month + '-01';
      // 本月最后一天日期
      var lastDateOfCurrentMonth = Date.new(year, month, 0);
      var end = year + '-' + month + '-' + lastDateOfCurrentMonth.getDate();
      this.searchData.startime = begin;
      this.searchData.endtime = end;
      this.searchData.pageIndex = 0
      this.searchData.pageSize = 20
      this.dataList = [];
      this.getPotentialCustomerData(this.searchData);
    },
    // 主表单开关
    doShowPopupHideClick () {
      // this.searchData.pageIndex = 0;
      this.searchData.searchText = '';
      this.dataList = [];
      this.isShowMainForm = false;
    },
    doShowPopupShowClick () {
      this.isShowMainForm = true;
      // document.body.scrollTop = 0;
      this.getPotentialCustomerData(this.searchData);
    },
    getPotentialCustomerData (searchData) {
      this.isLoadEnd = true;
      getAbnormalHandlingList(searchData).then(result => {
        console.log(result.data, 'getPotentialCustomerData11');

        result.data.PageDataList.forEach(o => {
          if (o.OperationTypeKeyValue.indexOf('修改课时') != -1) {
            o.OperationTypeKeyValue = o.OperationTypeKeyValue.replace(/修改课时/g, '修改课单');
          }
          this.dataList.push(o);
        });

        if (result.data.PageDataList.length < this.searchData.pageSize) {
          this.noMore = true
        } else {
          this.searchData.pageIndex++
          this.noMore = false
        }

        // this.searchData.pageIndex++;
        this.isLoadEnd = false;
      });
    },
    vDate (item) {
      if (item && item.GenerateTime) {
        return item.GenerateTime.substring(0, 10);
      }
    },
    vHour (item) {
      if (item && item.time) {
        return item.time;
      }
    },
    // 点击搜索按钮.
    search () {
      // this.searchData.pageIndex = 0;
      // if (this.searchData.searchText == '') {
      //   this.searchData.pageSize = 20;
      // } else {
      //   this.searchData.pageSize = 0;
      // }
      this.searchData.pageIndex = 0
      this.searchData.pageSize = 20
      this.noMore = false
      this.dataList = [];
      this.getPotentialCustomerData(this.searchData);
    },
    clearSearch () {
      this.searchData.searchText = '';
      this.dataList = [];
      this.searchData.pageIndex = 0
      this.searchData.pageSize = 20
      this.noMore = false
    },
    // 滚动加载更多
    scrollTOP (e) {
      let ETarget = e.target;
      if (
        (ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight ||
          ETarget.scrollTop + 1 ==
          ETarget.scrollHeight - ETarget.clientHeight) &&
        !this.searchData.searchText &&
        !this.isLoadEnd && !this.noMore
      ) {
        // console.log('scrollTOP', this.noMore);
        this.getPotentialCustomerData(this.searchData);
      } else {

      }
    }
  }
};
</script>

