<template>
  <div class=" form_info_line"
       :class="{form_info_required:required}">
    <div class="form_info_field"
         >
      <span>{{formTitle}}</span>
      <span v-if="helpDescription">
        <input-form-tip :tips='helpDescription'></input-form-tip>
      </span>
    </div>
    <div style="padding-left: 10px;line-height: 30px;"
         v-for="(item,index) in dataList"
         :key="index">
      <el-checkbox v-model="item.isSelected"
                   @change="changeChecked(item)"
                   :disabled="item.disabled">
        <span>{{item.name}}</span>
      </el-checkbox>
    </div>
    <!-- <el-radio v-for="(item,index) in dataList"
              :key="index"
              v-model="radio"
              :label="item.key"
              @change="changeChecked(item)">{{item.name}}</el-radio> -->
    <div class="form_info_value"
         style="border-bottom: 1px solid #ececec"
         v-if="dataList[dataList.length - 1].isSelected==true && this.dataList[this.dataList.length - 1].key == 0">
      <input type="text"
             v-model="textValue"
             :placeholder="'请输入'"
             @change="changeEvent($event.target.value,$event)" />
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      radio: '', // 选项Key
      selectScope: [],
      textValue: '',
      itemInfo: { key: '', name: '' }
    };
  },
  props: {
    formTitle: {
      type: String,
      required: true
    }, // 标题，必填
    value: [String, Number], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改,
    helpDescription: String, // 表单说明提示
    radioKeys: [String, Number], // 选项Key
    dataList: {
      type: Array,
      default: () => {
        return [
          { isSelected: false, name: '校宝', key: 1, disabled: false },
          { isSelected: false, name: '小麦', key: 2, disabled: false },
          { isSelected: false, name: '校管家', key: 3, disabled: false },
          { isSelected: false, name: '校盈易', key: 4, disabled: false },
          { isSelected: false, name: '其他', key: 0, disabled: false }
        ];
      }
    } // 数据源
  },

  computed: {

  },
  created () {
    if (this.radioKeys || this.value) {
      this.setCheckKey();
    }
    console.log(this.radioKeys, this.value, '多选竞品修改');
    // 获取其他的内容
    if (this.dataList[this.dataList.length - 1].isSelected && this.dataList[this.dataList.length - 1].key == 0) {
      let checkKeyValue = this.value.split(',');
      this.dataList.forEach(o => {
        checkKeyValue.forEach(val => {
          if (val == o.name) {
            this.textValue = '';
          } else {
            this.textValue = val;
          }
        });
      });
    }
    if (this.radioKeys || this.value) {
      this.setCheckKey();
      this.$emit('changeChecked', this.dataList, this.textValue);
    }
  },
  watch: {
    radioKeys () {
      if (this.radioKeys || this.value) {
        this.setCheckKey();
      }
    },
    value () {
      if (this.dataList[this.dataList.length - 1].isSelected && this.dataList[this.dataList.length - 1].key == 0) {
        let checkKeyValue = this.value.split(',');
        this.dataList.forEach(o => {
          checkKeyValue.forEach(val => {
            if (val == o.name) {
              this.textValue = '';
            } else {
              this.textValue = val;
            }
          });
        });
      }
    }
  },
  methods: {
    setCheckKey () {
      let checkKeys = this.radioKeys >= 0 ? this.radioKeys : this.radioKeys.split(',');
      if (checkKeys >= 0) {
        this.dataList.forEach(o => {
          if (o.key == checkKeys) {
            o.isSelected = true;
          }
        });
      } else {
        this.dataList.forEach(o => {
          checkKeys.forEach(key => {
            if (o.key == key) {
              o.isSelected = true;
            }
          });
        });
      }
      console.log(this.dataList, this.radioKeys, 'this.dataList');
    },
    changeChecked (item) {
      this.dataList.forEach(o => {
        if (o.key == 0 && o.isSelected == false) {
          this.textValue = '';
        }
      });
      this.$emit('changeChecked', this.dataList, this.textValue);
    },
    changeEvent () {
      this.$emit('changeChecked', this.dataList, this.textValue);
    }
  }
};
</script>

<style>
</style>

