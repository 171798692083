<template>
  <div>
    <div v-if="!vIsSelectedStudent">
      <search-box :searchInitJson="searchDialogInitJson"
                  :isShowSeachItem="isShowItem"
                  @searchClick="doChooseClick"
                  @onSelectedStudent="doAfterSelectedStudent"
                  @addClick="addClick"></search-box>
    </div>
    <div v-else>
      <student-lable class="content_marginTop_30 single_shadow content_marginRL"
                     :studentID="studentInfo.OLAPKey"
                     :readonly="readOnly"
                     :switchItem="switchItem"
                     :IsConfirmKey="IsConfirmKey"
                     :showExamine="showExamine"
                     :showMenu="showMenu"
                     :showSwitchBut="showSwitchBut"
                     :IsRecycleKey="IsRecycleKey"
                     :showPrint="showPrint"
                     @alterStudent="doAfterSelectedStudent"
                     @showPrintFrom="doShowPrintFrom"></student-lable>
    </div>
  </div>
</template> 

<script>
import searchBox from './search-box';
import studentLable from './student-lable';
export default {
  name: 'searchStudentBox',
  data () {
    return {
      searchDialogInitJson: {
        name: '目标学生',
        addBtnText: '新增',
        isShowAddBtn: this.isShowAddBtn,
        roleName: 47
      },
      studentInfo: {},
      isShowStudentListDialog: false
    };
  },
  props: {
    selectedStudentInfo: Object,
    readOnly: {
      type: Boolean,
      default: false
    },
    isShowAddBtn: {
      type: Boolean,
      default: true
    },
    switchItem: { // 选择未验证学生是否提示，1为不提示 (可选)
      type: [String, Number],
      default: 0
    },
    IsConfirmKey: {// 0:显示全部学生， 1：过滤掉待验证的学生 (可选)
      type: [String, Number],
      default: 0
    },
    CustStatusKey: {// 是否在校学生
      type: [String, Number],
      default: ''
    },
    showExamine: {
      type: Boolean,
      default: true
    },
    showMenu: { type: Boolean, default: true },
    showSwitchBut: {
      type: Boolean,
      default: false
    },
    IsRecycleKey: { // 该学生是否回收1-是;0-否
      type: Boolean,
      default: false
    },
    showPrint: {
      type: Boolean,
      default: false
    },
    isShowItem: {
      type: Number,
      default: 0
    }
  },
  computed: {
    vIsSelectedStudent () {
      return JSON.stringify(this.studentInfo) != '{}';
    }

  },
  components: {
    searchBox,
    studentLable
  },
  beforeCreate () {

  },
  created () {
    this.studentInfo = this.$utils.parseJson(this.selectedStudentInfo);
  },
  mounted () {
    this.$dialog.register(this, ['studentList', 'registercustomer']);
  },
  methods: {
    // 点击搜索
    doChooseClick (type) {
      console.log('doChooseClick', this.CustStatusKey);
      let titlename = '学生列表';
      if (type == 2) {
        titlename = '线索列表';
      }
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: titlename,
        moduleName: 'studentList',
        data: {
          switchItem: this.switchItem,
          typeApi: type,
          CustStatusKey: this.CustStatusKey
        },
        callBack: {
          selectedItem: (item) => {
            this.doAfterSelectedStudent(item);
          }
        }
      });
    },
    doShowPrintFrom () {
      this.$emit('showPrintFrom');
    },
    // 选中学生后
    doAfterSelectedStudent (item) {
      this.studentInfo = item;
      this.$emit('selectedStudent', this.studentInfo);
    },
    // 学生注册
    addClick () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'registercustomer',
        callBack: {
          doafterRegisterCustomer: (item) => { this.doAfterAddSuccess(item); }
        }
      });
    },
    // 学生注册成功
    doAfterAddSuccess (item) {
      this.studentInfo = item;
      this.$emit('selectedStudent', this.studentInfo);
    }
  },
  watch: {
    'selectedStudentInfo.OLAPKey': {
      handler (n, o) {
        if (n != o) {
          this.studentInfo = this.$utils.parseJson(this.selectedStudentInfo);
        }
      },
      deep: true
    }
  }
};
</script>
