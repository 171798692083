<template>
  <div class="add_screening_condition_box fl">
    <span class="number_title">{{dataModel.formTitle}} : </span>
    <span>
      <input-number v-model="dataModel.fieldList[0].value"
                    :decimalPlaces="0"
                    class="number-ranger input-Number">
      </input-number>

    </span>
    <span>-</span>
    <span>
      <input-number v-model="dataModel.fieldList[1].value"
                    :decimalPlaces="0"
                    class="number-ranger input-Number">
      </input-number>
    </span>
  </div>
</template>
<script>
import inputNumber from '../../form-item/input-number';
export default {
  model: {
    prop: 'dataModel'
  },
  props: {
    dataModel: Object
  },
  components: {
    inputNumber
  },
  created () {
  },
  methods: {

  }
};
</script>
<style scoped>
.el-input-number .el-input__inner {
  text-align: left !important;
}
</style>

