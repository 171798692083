<template>
  <el-container class="page">
    <el-main class="page_content">
      <div class="class_consume_money clearfix">
        <div class="apply_payment_details fl">
          <div class="massive_bg apply_payment_details_box">
            <dayStatistics :formTitle="'今天'"
                           :vClass="'class_consume_money_box_top'"
                           :dataInfo="ClassCountAmountDetail.CountUsedAmountToday"></dayStatistics>
            <dayStatistics :formTitle="'昨天'"
                           :vClass="'class_consume_money_box_bottom'"
                           :dataInfo="ClassCountAmountDetail.CountUsedAmountYesterday"></dayStatistics>
          </div>
          <div class=" massive_bg apply_payment_details_box">
            <div class="apply_payment_box_title">
              课时卡剩余
            </div>
            <div class="box_dot dot_green">
              剩余价值
            </div>
            <div class="apply_payment_box_number">
              <span>￥</span>{{Number(ClassCountAmountDetail.CountUsedAmountClassCount5.totalValueAmount)}}
            </div>
            <div class="apply_payment_box_singular box_dot dot_blue">
              <span>{{Number(ClassCountAmountDetail.CountUsedAmountClassCount5.totalCurrentCount)}}</span> 课时
            </div>
            <div class="apply_payment_box_singular box_dot dot_yellow">
              <span>{{ClassCountAmountDetail.CountUsedAmountClassCount5.totalPerson}}</span> 学生
            </div>
          </div>
        </div>
        <!-- 图表区域 -->
        <div class="apply_payment_echarts fl">
          <class-amount-day-report @showDetail="showDetail"></class-amount-day-report>
          <class-amount-month-report @showDetail="showDetail"></class-amount-month-report>

        </div>
        <!-- 底部信息区 -->
        <div class="details_bottom_box">
          <bottomStatistics :formTitle="'学生考勤-课消金额'"
                            :secondTitle="'消耗'"
                            :thirdTitle="'签到'"
                            :lastTitle="'人次'"
                            :dataInfo="thisMonthData"
                            @showDetail="showDetail('学生考勤')">
          </bottomStatistics>
          <!-- <bottomStatistics :formTitle="'还课时-课消金额'"
                        :secondTitle="'还'"
                        :thirdTitle="'小计'"
                        :lastTitle="'次'"
                        :dataInfo="ClassCountAmountDetail.CountUsedAmountThisMonth.DeductTypeKey2">
      </bottomStatistics> -->
          <bottomStatistics :formTitle="'课时过期-课消金额'"
                            :secondTitle="'过期'"
                            :thirdTitle="'小计'"
                            :lastTitle="'张卡'"
                            :dataInfo="ClassCountAmountDetail.CountUsedAmountThisMonth.DeductTypeKey4"
                            @showDetail="showDetail('课时过期')">
          </bottomStatistics>
          <bottomStatistics :formTitle="'课时延期-课消金额'"
                            :secondTitle="'延期'"
                            :thirdTitle="'共延期'"
                            :lastTitle="'次'"
                            :dataInfo="ClassCountAmountDetail.CountUsedAmountThisMonth.DeductTypeKey5"
                            @showDetail="showDetail('课时延期')">
          </bottomStatistics>
          <bottomStatistics :formTitle="'调整课时-课消金额'"
                            :secondTitle="'调整'"
                            :thirdTitle="'共调整'"
                            :lastTitle="'次'"
                            :dataInfo="ClassCountAmountDetail.CountUsedAmountThisMonth.DeductTypeKey7"
                            @showDetail="showDetail('调整课时')">
          </bottomStatistics>
        </div>
      </div>
    </el-main>
    <!-- 弹窗区域 -->
    <list ref="classCountAmount"
          class="class_count_amount table_button_arrow_small"
          :listTitle="listTitle"
          :extendSearchCondition="extendSearchCondition"
          :tableData="classCountAmountList"
          :totalNum="totalNum"
          :PageCount="PageCount"
          :tableColumns="tableColumns"
          :rowKey='rowKey'
          :detailColumns="detailColumns"
          @loadTableData="loadTableData"></list>

    <classCountList ref="classCountList"
                    :listTime="classCountListTime"
                    :listTitle="classCountListTitle"
                    :tableData="classCountListData"
                    :rowKey="classCountRowKey"
                    :detailColumns="classCountDetailColumns"
                    :tableColumns="classCountTableColumns">
    </classCountList>
  </el-container>
</template>
<script>
import dayStatistics from './day-statistics';
import bottomStatistics from './bottom-statistics';
import classAmountDayReport from './class-amount-day-report';
import classAmountMonthReport from './class-amount-month-report';
import list from '../../list/index';
import classCountList from './class-count-list';
import { getClassCountUsedAmountStatistics, MessageSearchPageForAttendance, classMessageSearchPageForClassCountUsed, getClassCountUsedAmountReportByDay, getClassCountUsedAmountReportByMonth } from '../../../API/workbench';
export default {
  data () {
    return {
      ClassCountAmountDetail: {
        CountUsedAmountToday: {

        },
        CountUsedAmountYesterday: {

        },
        CountUsedAmountClassCount5: {

        },
        CountUsedAmountThisMonth: {}
      },
      // 本月数据
      thisMonthData: {
        DeductCount: 0,
        DeductAmount: 0,
        Number: 0
      },
      value1: '',
      totalNum: 0,
      PageCount: 0,
      rowKey: 'StuArrangeKey',
      classCountRowKey: 'SetUpTime',
      listTitle: '', // 列表名
      classCountListTitle: '', // 日、月报列表名
      classCountListTime: '', // 日、月报列表时间
      classCountListData: [], // 日、月报列表数据
      classCountAmountList: [], // 列表数据
      extendSearchCondition: [], // 扩展查询条件
      tableColumns: [], // 表头
      detailColumns: [
        { label: '上课日期', prop: 'ClassTime' },
        { label: '课名', prop: 'CourseNameKeyValue' },
        { label: '学生名', prop: 'StudentKeyValue' },
        { label: '考勤课消结果', prop: 'AttenceStatusKeyValue' },
        { label: '欠课时数', prop: 'noData' },
        { label: '上课时间', prop: 'BeginClassTime' },
        { label: '主讲老师', prop: 'LecturerKeyValue' },
        { label: '关系', prop: 'CustomerRelationshipKeyValueName' },
        { label: '课单号', prop: 'xykdKeyValue' },
        { label: '打考勤人', prop: 'AttenceOpratorKeyValue' },
        { label: '班名', prop: 'GradeClassKeyValue' },
        { label: '教室', prop: 'classRoomName', isShowTitle: true },
        { label: '手机', prop: 'CustomerPhoneName' },
        { label: '剩余课时', prop: 'ClassSingleCount' },
        { label: '打考勤时间', prop: 'AttenceTime' }
      ],
      classCountDetailColumns: [
        { label: '课消日期', prop: 'dateTime' },
        { label: '消耗课时卡', prop: 'DeductCount5Detail' },
        { label: '课时卡过期', prop: 'ExpireDetail' },
        { label: '课消总金额', prop: 'totalDeductAmount' },
        { label: '改课单调整课时', prop: 'AdjustDetail' },
        { label: '课时卡延期', prop: 'DelayDetail' }

      ],
      classCountTableColumns: [ // table 列
        {
          label: 'OLAPKey',
          prop: 'OLAPKey',
          width: 0,
          sortable: false,
          align: 'left',
          isShow: false,
          type: '',
          decimalPlace: 0
        },
        {
          label: '课消日期',
          prop: 'SetUpTime',
          width: '',
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '课消总金额',
          prop: 'totalDeductAmount',
          width: '',
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '消耗课时卡',
          prop: 'DeductAmount5HaveClass',
          width: '',
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '课时卡过期',
          prop: 'DeductAmount5Expire',
          width: '',
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '课时卡延期',
          prop: 'DeductAmount5Delay',
          width: '',
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '改课单调整课时',
          prop: 'DeductAmount5Adjust',
          width: '',
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '操作',
          prop: '',
          width: '',
          sortable: false,
          align: 'center',
          tableBtnGroup: [
            {
              name: '详情',
              eventName: this.$initJson.baseFunctionBtnName.detail,
              Stutas: 'StutasKey',
              className: () => {
                return 'btn_light_white';
              }
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        }
      ],
      checkExtendSearchCondition: [// 扩展查询条件
        {
          type: this.$initJson.extendSearchConditionType.dateRange, // 日期范围
          formTitle: '考勤日期',
          fieldList: [
            {
              name: 'startTime', // 参数名
              defaultValue: '', // 默认值
              value: '', // 绑定值
              validateHandle: '', // 自定义验证函数
              sourceData: []// 数据源
            },
            {
              name: 'endTime',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: []
            }
          ]
        }
      ],
      checkTableColumns: [ // table 列
        {
          label: 'OLAPKey',
          prop: 'OLAPKey',
          width: 0,
          sortable: false,
          align: 'left',
          isShow: false,
          type: '',
          decimalPlace: 0
        },
        {
          label: '考勤日期',
          prop: 'ClassTime',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '上课时间',
          prop: 'BeginClassTime',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '课程安排',
          prop: 'CourseNameKeyValue',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '考勤课消结果',
          prop: 'AttenceStatusKeyValue',
          width: 200,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '打考勤人',
          prop: 'AttenceOpratorKeyValue',
          width: '',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '打考勤时间',
          prop: 'AttenceTime',
          width: '200',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '操作',
          prop: '',
          width: '',
          sortable: false,
          align: 'center',
          tableBtnGroup: [
            {
              name: '详情',
              eventName: this.$initJson.baseFunctionBtnName.detail,
              Stutas: 'StutasKey',
              className: () => {
                return 'btn_light_white';
              }
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        }
      ],
      pastExtendSearchCondition: [// 扩展查询条件
        {
          type: this.$initJson.extendSearchConditionType.dateRange, // 日期范围
          formTitle: '过期日期',
          fieldList: [
            {
              name: 'startTime', // 参数名
              defaultValue: '', // 默认值
              value: '', // 绑定值
              validateHandle: '', // 自定义验证函数
              sourceData: []// 数据源
            },
            {
              name: 'endTime',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: []
            }
          ]
        }
      ],
      pastTableColumns: [ // table 列
        {
          label: 'OLAPKey',
          prop: 'OLAPKey',
          width: 0,
          sortable: false,
          align: 'left',
          isShow: false,
          type: '',
          decimalPlace: 0
        },
        {
          label: '过期日期',
          prop: 'SetUpTime',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '课消金额',
          prop: 'DeductAmount',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '过期课时数',
          prop: 'DeductCount',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '关系/手机号',
          prop: 'CustomerRelationshipKeyValue',
          width: 180,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '有效期范围',
          prop: 'StartTime',
          width: '200',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '使用课单号',
          prop: 'xykdKeyValue',
          width: '',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        }
      ],
      postponeExtendSearchCondition: [// 扩展查询条件
        {
          type: this.$initJson.extendSearchConditionType.dateRange, // 日期范围
          formTitle: '延期日期',
          fieldList: [
            {
              name: 'startTime', // 参数名
              defaultValue: '', // 默认值
              value: '', // 绑定值
              validateHandle: '', // 自定义验证函数
              sourceData: []// 数据源
            },
            {
              name: 'endTime',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: []
            }
          ]
        }
      ],
      postponeTableColumns: [ // table 列
        {
          label: 'OLAPKey',
          prop: 'OLAPKey',
          width: 0,
          sortable: false,
          align: 'left',
          isShow: false,
          type: '',
          decimalPlace: 0
        },
        {
          label: '延期日期',
          prop: 'SetUpTime',
          width: '',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '课消金额',
          prop: 'DeductAmount',
          width: '',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '延期课时数',
          prop: 'DeductCount',
          width: '',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: '',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '关系/手机号',
          prop: 'CustomerRelationshipKeyValue',
          width: 150,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '过期课单号',
          prop: 'xykdKeyValue',
          width: '150',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '操作时间',
          prop: 'GenerateTime',
          width: '',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '原因备注',
          prop: 'RemarkSupport',
          width: '',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        }
      ],
      adjustExtendSearchCondition: [// 扩展查询条件
        {
          type: this.$initJson.extendSearchConditionType.dateRange, // 日期范围
          formTitle: '调整日期',
          fieldList: [
            {
              name: 'startTime', // 参数名
              defaultValue: '', // 默认值
              value: '', // 绑定值
              validateHandle: '', // 自定义验证函数
              sourceData: []// 数据源
            },
            {
              name: 'endTime',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: []
            }
          ]
        }
      ],
      adjustTableColumns: [ // table 列
        {
          label: 'OLAPKey',
          prop: 'OLAPKey',
          width: 40,
          sortable: false,
          align: 'left',
          isShow: false,
          type: '',
          decimalPlace: 0
        },
        {
          label: '调整日期',
          prop: 'SetUpTime',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '课消金额',
          prop: 'DeductAmount',
          width: 90,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '调整课时数',
          prop: 'DeductCount',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: '90',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '关系/手机',
          prop: 'CustomerRelationshipKeyValue',
          width: 145,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '修改课单号',
          prop: 'xykdKeyValue',
          width: 145,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '改前课时数',
          prop: 'agoClassSingleCount',
          width: 90,
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '改后课时数',
          prop: 'ClassSingleCount',
          width: 115,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '操作时间',
          prop: 'GenerateTime',
          width: '100',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '原因备注',
          prop: 'RemarkSupport',
          width: '',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        }
      ]

    };
  },
  components: {
    dayStatistics, bottomStatistics, classAmountDayReport, classAmountMonthReport, list, classCountList
  },
  mounted () {
    this.getClassCountAmountDetail();
  },
  methods: {
    getClassCountAmountDetail () {
      // CountUsedAmountToday
      getClassCountUsedAmountStatistics().then(result => {
        console.log(result.data, 'getClassCountAmountDetail');
        this.ClassCountAmountDetail = result.data;
        this.thisMonthData.DeductCount = Number(this.ClassCountAmountDetail.CountUsedAmountThisMonth.DeductTypeKey2.DeductCount) + Number(this.ClassCountAmountDetail.CountUsedAmountThisMonth.DeductTypeKey3.DeductCount) + Number(this.ClassCountAmountDetail.CountUsedAmountThisMonth.DeductTypeKey10.DeductCount);
        this.thisMonthData.DeductAmount = (Number(this.ClassCountAmountDetail.CountUsedAmountThisMonth.DeductTypeKey2.DeductAmount) + Number(this.ClassCountAmountDetail.CountUsedAmountThisMonth.DeductTypeKey3.DeductAmount) + Number(this.ClassCountAmountDetail.CountUsedAmountThisMonth.DeductTypeKey10.DeductAmount)).toFixed(2);
        this.thisMonthData.Number = Number(this.ClassCountAmountDetail.CountUsedAmountThisMonth.DeductTypeKey2.Number);
      }, r => {
        console.log(r, 'wrong');
      });
    },
    showDetail (type, startTime, endTime) {
      let initSelectedParams = [];
      let time = '';
      if (startTime && endTime) {
        startTime = startTime.split('/')[0] + '-' + startTime.split('/')[1] + '-' + startTime.split('/')[2];
        endTime = endTime.split('/')[0] + '-' + endTime.split('/')[1] + '-' + endTime.split('/')[2];
        time = startTime.split('-');
      }
      switch (type) {
        case '学生考勤':
          this.listTitle = '学生考勤-课消金额';
          this.tableColumns = this.checkTableColumns;
          this.extendSearchCondition = this.checkExtendSearchCondition;
          initSelectedParams.push({ name: 'startTime', value: this.$utils.getCurrentMonthFirst() }, { name: 'endTime', value: this.$utils.getCurrentMonthLast() });
          this.$refs.classCountAmount.isShowListDialog = true;
          this.$refs.classCountAmount.initSelectedParams(initSelectedParams);
          break;
        case '课时过期':
          this.listTitle = '课时过期-课消金额';
          this.tableColumns = this.pastTableColumns;
          this.extendSearchCondition = this.pastExtendSearchCondition;
          initSelectedParams.push({ name: 'startTime', value: this.$utils.getCurrentMonthFirst() }, { name: 'endTime', value: this.$utils.getCurrentMonthLast() });
          this.$refs.classCountAmount.isShowListDialog = true;
          this.$refs.classCountAmount.initSelectedParams(initSelectedParams);
          break;
        case '课时延期':
          this.listTitle = '课时延期-课消金额';
          this.tableColumns = this.postponeTableColumns;
          this.extendSearchCondition = this.postponeExtendSearchCondition;
          initSelectedParams.push({ name: 'startTime', value: this.$utils.getCurrentMonthFirst() }, { name: 'endTime', value: this.$utils.getCurrentMonthLast() });
          this.$refs.classCountAmount.isShowListDialog = true;
          this.$refs.classCountAmount.initSelectedParams(initSelectedParams);
          break;
        case '调整课时':
          this.listTitle = '课消金额明细-调整课时';
          this.tableColumns = this.adjustTableColumns;
          this.extendSearchCondition = this.adjustExtendSearchCondition;
          initSelectedParams.push({ name: 'startTime', value: this.$utils.getCurrentMonthFirst() }, { name: 'endTime', value: this.$utils.getCurrentMonthLast() });
          this.$refs.classCountAmount.isShowListDialog = true;
          this.$refs.classCountAmount.initSelectedParams(initSelectedParams);
          break;
        case '日报':
          this.classCountListTitle = '课消金额日报';
          this.$refs.classCountList.isShowListDialog = true;
          this.classCountListTime = time[0] + '年' + time[1] + '月';
          this.classCountLoadTableData(startTime, endTime);
          break;
        case '月报':
          this.classCountListTitle = '课消金额月报';
          this.$refs.classCountList.isShowListDialog = true;
          this.classCountListTime = time[0] + '年';
          this.classCountLoadTableData(startTime, endTime);
          break;
        default:
          break;
      }
    },
    loadTableData (queryParams) {
      if (queryParams.orderBy == '' && queryParams.sequence == '') {
        queryParams.orderBy = 'ClassTime';
        queryParams.sequence = 'DESC';
      }
      if (this.listTitle == '学生考勤-课消金额') {
        queryParams.typeKey = 1;
        MessageSearchPageForAttendance(queryParams).then(
          result => {
            this.classCountAmountList = [];
            if (result.data.PageDataList.length > 0) {
              result.data.PageDataList.forEach((obj, index) => {
                obj.CustomerPhoneName = this.$utils.phoneModulePower(obj.CustomerPhoneName) || '无手机号';
                obj.CustomerRelationshipKeyValueName = obj.CustomerRelationshipKeyValue + '/' + obj.CustomerPhoneName;
                obj.BeginClassTime = obj.BeginClassTime.substring(0, 5) + '-' + obj.EndClassTime.substring(0, 5);

                obj.DeductCount = Number(obj.DeductCount);
                obj.DeductAmount = Number(obj.DeductAmount);
                obj.ClassSingleCount = Number(obj.ClassSingleCount) + '课时';
                if (obj.AttenceStatusKeyValue == '出勤') {
                  obj.AttenceStatusKeyValue = '签到';
                }
                obj.AttenceStatusKeyValue = obj.AttenceStatusKeyValue + ' 扣' + obj.DeductCount + '课时 -' + obj.DeductAmount + '元';
                obj.noData = 0 + '课时';
                if (obj.ClassCount) {
                  obj.ClassCount = Number(obj.ClassCount);
                }
                if (obj.CurrentCount) {
                  obj.CurrentCount = Number(obj.CurrentCount);
                }
                if (obj.AttenceTime) {
                  let data = obj.ClassTime.split('-')[0] + '.' + obj.ClassTime.split('-')[1] + '.' + obj.ClassTime.split('-')[2];
                  obj.AttenceTime = data + ' ' + this.$utils.getWeekTime(obj.ClassTime) + ' ' + obj.AttenceTime;
                }
                this.classCountAmountList.push(obj);
              });
            }
            this.totalNum = result.data.Total;
            this.PageCount = result.data.PageCount;
            this.$refs.classCountAmount.isLoading = false;
          }
        );
      }
      if (this.listTitle == '课时过期-课消金额' || this.listTitle == '课时延期-课消金额' || this.listTitle == '课消金额明细-调整课时') {
        if (this.listTitle == '课时过期-课消金额') {
          queryParams.DeductTypeKey = 4;
        }
        if (this.listTitle == '课时延期-课消金额') {
          queryParams.DeductTypeKey = 5;
        }
        if (this.listTitle == '课消金额明细-调整课时') {
          queryParams.DeductTypeKey = 7;
        }
        classMessageSearchPageForClassCountUsed(queryParams).then(
          result => {
            this.classCountAmountList = [];
            if (result.data.PageDataList.length > 0) {
              result.data.PageDataList.forEach((obj, index) => {
                obj.CustomerPhoneName = this.$utils.phoneModulePower(obj.CustomerPhoneName) || '无手机号';
                obj.SetUpTime = obj.SetUpTime.split('-')[1] + '.' + obj.SetUpTime.split('-')[2] + ' ' + this.$utils.getWeekTime(obj.SetUpTime);
                obj.CustomerRelationshipKeyValue = obj.CustomerRelationshipKeyValue + '/' + obj.CustomerPhoneName;
                obj.agoClassSingleCount = Number(obj.ClassSingleCount) + Number(obj.DeductCount);
                obj.agoClassSingleCount = obj.agoClassSingleCount + '课时';
                obj.StartTime = obj.StartTime + '-' + obj.ExpireTime;

                obj.DeductCount = Number(obj.DeductCount) + '课时';

                obj.DeductAmount = Number(obj.DeductAmount) + '元';

                obj.ClassSingleCount = Number(obj.ClassSingleCount) + '课时';

                this.classCountAmountList.push(obj);
              });
            }
            this.totalNum = result.data.Total;
            this.PageCount = result.data.PageCount;
            this.$refs.classCountAmount.isLoading = false;
          });
      }
    },
    classCountLoadTableData (startTime, endTime) {
      if (this.classCountListTitle == '课消金额日报') {
        this.classCountListData = [];
        getClassCountUsedAmountReportByDay(startTime, endTime).then(result => {
          if (result.data.DataList.length > 0) {
            result.data.DataList.forEach((obj, index) => {
              obj.dateTime = obj.SetUpTime.split('-')[0] + '.' + obj.SetUpTime.split('-')[1] + '.' + obj.SetUpTime.split('-')[2] + ' ' + this.$utils.getWeekTime(obj.SetUpTime);
              obj.SetUpTime = obj.SetUpTime.split('-')[1] + '.' + obj.SetUpTime.split('-')[2];

              obj.DeductAmount5HaveClass = Number(obj.DeductAmount5HaveClass).toFixed(2) + '元';
              obj.totalDeductAmount = Number(obj.totalDeductAmount).toFixed(2) + '元';
              obj.DeductAmount2 = Number(obj.DeductAmount2).toFixed(2) + '元';
              obj.DeductAmount5Expire = Number(obj.DeductAmount5Expire).toFixed(2) + '元';
              obj.DeductAmount5Delay = Number(obj.DeductAmount5Delay).toFixed(2) + '元';
              obj.DeductAmount5Adjust = Number(obj.DeductAmount5Adjust).toFixed(2) + '元';

              obj.DeductCount5 = Number(obj.DeductCount5);

              obj.DeductCount5Expire = Number(obj.DeductCount5Expire);

              obj.DeductCount2 = Number(obj.DeductCount2);

              obj.DeductCount5Delay = Number(obj.DeductCount5Delay);

              obj.ClassCount5HaveClass = Number(obj.ClassCount5HaveClass);

              obj.DeductCount5Adjust = Number(obj.DeductCount5Adjust);

              obj.DeductCount5Detail = obj.DeductAmount5HaveClass + ' ' + obj.Number5HaveClass + '人 ' + obj.ClassCount5HaveClass + '课时';
              obj.ExpireDetail = obj.DeductAmount5Expire + ' 过期' + obj.Number5Expire + '张 ' + obj.DeductCount5Expire + '课时';
              obj.HaveClassDetail = obj.DeductAmount2 + ' 扣' + obj.DeductCount2 + '张卡';
              obj.DelayDetail = obj.DeductAmount5Delay + ' 延期' + obj.Number5Delay + '张 ' + obj.DeductCount5Delay + '课时';
              obj.AdjustDetail = obj.DeductAmount5Adjust + ' 改' + obj.Number5Adjust + '次 ' + obj.DeductCount5Adjust + '课时';

              this.classCountListData.push(obj);
            });
          }
        });
      }
      if (this.classCountListTitle == '课消金额月报') {
        this.classCountListData = [];
        getClassCountUsedAmountReportByMonth(startTime, endTime).then(result => {
          if (result.data.DataList.length > 0) {
            result.data.DataList.forEach((obj, index) => {
              obj.SetUpTime = obj.SetUpTime.split('-')[0] + '.' + obj.SetUpTime.split('-')[1];
              obj.dateTime = obj.SetUpTime;

              obj.DeductAmount5HaveClass = Number(obj.DeductAmount5HaveClass).toFixed(2) + '元';
              obj.totalDeductAmount = Number(obj.totalDeductAmount).toFixed(2) + '元';
              obj.DeductAmount2 = Number(obj.DeductAmount2).toFixed(2) + '元';
              obj.DeductAmount5Expire = Number(obj.DeductAmount5Expire).toFixed(2) + '元';
              obj.DeductAmount5Delay = Number(obj.DeductAmount5Delay).toFixed(2) + '元';
              obj.DeductAmount5Adjust = Number(obj.DeductAmount5Adjust).toFixed(2) + '元';

              obj.DeductCount5 = Number(obj.DeductCount5);

              obj.DeductCount5Expire = Number(obj.DeductCount5Expire);

              obj.DeductCount2 = Number(obj.DeductCount2);

              obj.DeductCount5Delay = Number(obj.DeductCount5Delay);

              obj.ClassCount5HaveClass = Number(obj.ClassCount5HaveClass);

              obj.DeductCount5Adjust = Number(obj.DeductCount5Adjust);

              obj.DeductCount5Detail = obj.DeductAmount5HaveClass + ' ' + obj.Number5HaveClass + '人 ' + obj.ClassCount5HaveClass + '课时';
              obj.ExpireDetail = obj.DeductAmount5Expire + ' 过期' + obj.Number5Expire + '张 ' + obj.DeductCount5Expire + '课时';
              obj.HaveClassDetail = obj.DeductAmount2 + ' 扣' + obj.DeductCount2 + '张卡';
              obj.DelayDetail = obj.DeductAmount5Delay + ' 延期' + obj.Number5Delay + '张 ' + obj.DeductCount5Delay + '课时';
              obj.AdjustDetail = obj.DeductAmount5Adjust + ' 改' + obj.Number5Adjust + '次 ' + obj.DeductCount5Adjust + '课时';

              this.classCountListData.push(obj);
            });
          }
        });
      }
    }
  }
};
</script>

