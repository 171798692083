<template>
  <div class="course_statistics_table_search">
    <div class="query_box_content"
         v-show="!isBatchDistribute">
      <div class="query_box_first_line">
        <div class="">
          <date-range ref="saveDate"
                      :searchObj="vSearchDataRange"
                      :fromTitle="'录入日期'"
                      @changeDate="changeDate"></date-range>
        </div>
        <div class="search_item">
          <el-select-option :selectTitle="'招商顾问'"
                            :dataSourceList="counselorList"
                            :selectionKey="paramsForQuery.CounselorKey"
                            @changeEvent="chooseCounselor"></el-select-option>
        </div>
        <div class="search_item">
          <el-select-option :selectTitle="'有效性'"
                            :dataSourceList="IsValidList"
                            :selectionKey="paramsForQuery.IsValidKey"
                            @changeEvent="chooseIsValid"></el-select-option>
        </div>
        <div class="search_item">
          <el-select-option :selectTitle="'来源方式'"
                            :dataSourceList="sourceTypeList"
                            :selectionKey="paramsForQuery.SourceTypeKey"
                            @changeEvent="chooseSourceType"></el-select-option>
        </div>
      </div>
      <div class="query_box_second_line">
        <div style="display: flex">
          <div class="search_item">
            <el-select-option :selectTitle="'分配状态'"
                              :dataSourceList="distributeStatusList"
                              :selectionKey="paramsForQuery.StatusKey"
                              @changeEvent="chooseStatus"></el-select-option>
          </div>
          <input-search-contain-btn v-model="paramsForQuery.searchText"
                                    :placeholder="'请输入'"
                                    @onSearchEvent="searchClick"
                                    @clearSearch="clearSearchParams"></input-search-contain-btn>
        </div>
        <div class="option_btn">
          <el-button class="btn_hover_bg_blue"
                     style="margin-right:10px;border-radius:4px;padding:10px 5px"
                     @click="addInvestor">添加投资人
          </el-button>
          <div class="btn_light_blue btn_hover_bg_blue import_investor">
            <el-upload accept=".xls, .xlsx"
                       action="string"
                       ref="upload"
                       :show-file-list="false"
                       :http-request="importEvent">导入线索</el-upload>
          </div>
          <div class="distribute_advisor btn_hover_bg_blue"
               @click="distributeClick">
            批量分配
          </div>
        </div>
      </div>
    </div>
    <div v-if="isBatchDistribute"
         class="batch_option_box flex">
      <div class="pitch_count pr">
        已选中 {{ batchAllotList.length }} 项
        <div class="remove_db_btn"
             @click="cancleBatchDistribute"></div>
      </div>
      <div class="btn_hover_bg_blue batch_btn"
           @click="doBatchAllot">
        确认分配
      </div>
    </div>
  </div>
</template>

<script>
import elSelectOption from "../../../../report/common/el-select-option"; // 下拉选择框
import inputSearchContainBtn from "../../../../report/common/input-search-contain-btn";
import {
  GetCounselorList,
  hqAttractInvestmentSourceSet,
  ImportInvestor,
} from "../../../../../API/workbench";
export default {
  props: {
    paramsForQuery: {
      //查询参数
      type: Object,
    },
    batchAllotList: {
      //批量选中的投资人数
      type: Array,
    },
    isBatchDistribute: Boolean, //是否显示查询条件
  },
  data () {
    return {
      investorInfo: {
        name: "",
        value: "添加投资人",
        routerName: 'merchantsCounselorWorkbench',
        routertitle: "招商管理",
        keyvalue: '01',
        ModulePowerKey: 135,
        moduleName: "addClewForm",
      },
      counselorList: [], //投资顾问列表
      sourceTypeList: [], //来源
      IsValidList: [
        //有效性  0全部;1-有效;2-待确认;3-无效
        { OLAPKey: 0, MainDemoName: "全部" },
        { OLAPKey: 1, MainDemoName: "有效" },
        { OLAPKey: 2, MainDemoName: "待验证" },
        { OLAPKey: 3, MainDemoName: "无效" },
      ],
      distributeStatusList: [
        //分配状态0-全部;1-已分配;2-未分配
        { OLAPKey: 0, MainDemoName: "全部" },
        { OLAPKey: 1, MainDemoName: "已分配" },
        { OLAPKey: 2, MainDemoName: "未分配" },
      ],
    };
  },
  components: {
    elSelectOption,
    inputSearchContainBtn,
  },
  computed: {
    vSearchDataRange () {
      return {
        startTime: this.paramsForQuery.entryTimeStart,
        endTime: this.paramsForQuery.entryTimeEnd,
      };
    },
  },
  created () {
    this.paramsForQuery.StatusKey = 0;
    this.loadAdvisorList();
    this.loadSourceType();
  },
  methods: {
    //获取投资顾问列表
    loadAdvisorList () {
      GetCounselorList().then((result) => {
        this.counselorList = result.data || [];
        this.counselorList.unshift({
          MainDemoName: "全部",
          OLAPKey: 0,
        });
      });
    },
    //查询所有招商来源设置-总部
    loadSourceType () {
      hqAttractInvestmentSourceSet().then(
        (result) => {
          this.sourceTypeList = result.data || [];
          this.sourceTypeList.unshift({
            MainDemoName: "全部",
            OLAPKey: 0,
          });
          console.log(this.sourceTypeList, "查询所有招商来源设置");
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    //改变日期
    changeDate (dataInfo) {
      this.paramsForQuery.entryTimeStart = dataInfo.startTime;
      this.paramsForQuery.entryTimeEnd = dataInfo.endTime;
    },
    //选择投资顾问
    chooseCounselor (item) {
      this.paramsForQuery.CounselorKey = item.OLAPKey;
      this.searchClick();
    },
    //选择有效性
    chooseIsValid (item) {
      this.paramsForQuery.IsValidKey = item.OLAPKey;
      this.searchClick();
    },
    //选择来源方式
    chooseSourceType (item) {
      this.paramsForQuery.SourceTypeKey = item.OLAPKey;
      this.searchClick();
    },
    //选择分配状态
    chooseStatus (item) {
      this.paramsForQuery.StatusKey = item.OLAPKey;
      this.searchClick();
    },
    //点击搜索
    searchClick () {
      this.paramsForQuery.pageIndex = 0;
      this.$emit("searchClick", this.paramsForQuery);
    },
    //清除搜索条件
    clearSearchParams () {
      this.$emit("clearSearchParams");
    },
    //分配投资人
    distributeClick () {
      this.$emit("enableBatchAllot");
    },
    //取消批量分配
    cancleBatchDistribute () {
      this.$emit("cancleBatchDistribute");
    },
    //批量分配
    doBatchAllot () {
      if (this.batchAllotList.length == 0) {
        layer.alert("请至少选择一个投资人");
        return;
      }
      this.$emit(
        "doBatchAllot",
        {
          OLAPKey: this.batchAllotList.map((item) => {
            return item.OLAPKey;
          }),
        },
        "batch"
      );
    },
    // 导入
    importEvent (param) {
      this.showProgress = true;
      this.ProgressHintText = "导入中...";
      const formData = new FormData();
      formData.append("file", param.file);
      ImportInvestor(formData)
        .then(
          (result) => {
            // this.statisticsInfo = result.data;
            console.log("导入成功", result.data);
            this.$emit("importSuccess", this.paramsForQuery);
            layer.alert("导入成功");
          },
          (error) => {
            layer.alert(error.msg);
          }
        )
        .finally(() => {
          this.showProgress = false;
          this.$refs.upload.clearFiles(); // 上传成功之后清除历史记录
        });
    },
    // 添加投资人
    addInvestor () {
      this.$emit("openCommonCustomDialogByFactoryDialog", this.investorInfo);
    }
  },
};
</script>

<style scoped>
.course_statistics_table_search {
  padding: 13px 0px;
}
.query_box_content {
  width: 100%;
}
.query_box_first_line {
  display: flex;
}
.query_box_second_line {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}
.course_statistics_table_search .search_item {
  margin-right: 20px;
}
.option_btn {
  display: flex;
  justify-content: space-between;
}
.import_investor {
  height: 35px;
  width: 75px;
  /* font-size: 12px; */
  line-height: 34px;
  text-align: center;
  align-items: center;
  border-radius: 4px;
}
.distribute_advisor {
  margin-left: 10px;
  height: 35px;
  line-height: 34px;
  /* font-size: 12px; */
  width: 75px;
  text-align: center;
  align-items: center;
  border-radius: 4px;
}
.batch_option_box {
  height: 86px;
  display: flex;
  align-items: center;
}
.remove_db_btn {
  background-image: url(../../../../../../public/image/dialog_del_icon.png);
  position: absolute;
  right: -40px;
  top: 14%;
  margin-top: -8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 8px;
}
.batch_btn {
  margin-left: 60px;
  margin-top: -10px;
  height: 35px;
  width: 100px;
  line-height: 35px;
  text-align: center;
  border-radius: 4px;
  padding-left: 27px;
  padding-right: 7px;
  background: #3498db
    url(../../../../../../public/image/batch_distribution_icon.png) no-repeat
    8px;
}
</style>