import {
  fetchToken
} from './../utils/fetch';
import qs from 'qs';
// 特殊容器名称 WKTB.CouponBill.SearchSpecialContent
export function SearchSpecialContent () {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.CouponBill.SearchSpecialContent'
    },
    method: 'get'
  });
}
// 截图坐标.
export function imageCropper (data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEB.Pic.ImageCropper',
      source_path: data.source_path,
      src_x: data.src_x,
      src_y: data.src_y,
      width: data.width,
      height: data.height
    },
    method: 'get'
    // data: qs.stringify(data)
  });
}
// 分页查询（图片）
export function getSearchPage (data) { // getSearchPage
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEB.Resource.SearchPage'
    },
    method: 'post',
    data: qs.stringify(data)
  });
}
// 通过门店ID获取门店信息
export function GetMenDianInfoByMenDianId (gl_saasclientid) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.MenDian.GetMenDianInfoByMenDianId',
      gl_saasclientid: gl_saasclientid
    },
    method: 'get'
  });
}
// 根据内容返回相应字体文件
export function DyncGenerateFonts (id, Data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEB.Font.DyncGenerateFonts',
      ID: id
      // content: content
    },
    data: qs.stringify(Data),
    method: 'post'
  });
}
// 返回所有字体数据（包括停用、启用的），按index排序，顺序  WEB.Font.GetAll
export function GetAllFont () {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEB.Font.GetAll'
    },
    method: 'get'
  });
}
// 保存海报券
export function postH5Page (data) { // 点击保存H5, 即为保存海报券.
  data = {
    Data: JSON.stringify(data)
  };
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEB.Showbill.SaveBillInfo'
    },
    method: 'post',
    data: qs.stringify(data)
  });
}
// 海报券模板ID获取模版信息.
export function getModuleInfo (id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEB.ShowbillTmp.GetTmpInfo',
      ID: id
    },
    method: 'get'
  });
}
// 海报券ID获取海报券信息
export function getPageJson (id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEB.Showbill.GetBillInfo',
      ID: id
    },
    method: 'get'
  });
}
// 获取优惠券信息
export function getCouponJson (currentSaasClientKey, couponID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'App.Coupon.GetCouponInfoById',
      mdID: currentSaasClientKey,
      CouponID: couponID
    },
    method: 'get'
  });
}
// 海报模板列表
export function getModuleList (data) { // 获取所有模版.
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEB.ShowbillTmp.SearchPage'
    },
    method: 'post',
    data: qs.stringify(data)
  });
}
