<template>
  <div>
    <div class="weixin_setting_form form_info_color clerafix">
      <div class="form_title pr">
        <!-- 模板名称 -->
        <span>{{vFormTitle}}</span>
        <!-- 模板开关 -->
        <span>
          <el-switch class="pa"
                     v-model="selectEventType.templateKey"
                     :active-value="1"
                     :inactive-value="0"></el-switch>
        </span>
      </div>
      <div class="mendian_setting_content form_info_edit"
           v-if="selectEventType.eventId==12">
        <div class="form_info_line "
             style="padding-top:15px">
          <div class="form_info_field_init ">
            <span>第一次提醒</span>
          </div>
          <div class="form_info_value form_info_select"
               @click="doOpenClick(1)">
            <input style="font-size:16px;padding-right:18px"
                   type="text"
                   :disabled="false"
                   placeholder="请输入"
                   :value="ChangeHourMinutestr(remindClassEarly1) || '无'">
          </div>
        </div>
        <div class="form_info_line no_border_bottom"
             style="padding-top:15px">
          <div class="form_info_field_init ">
            <span>第二次提醒</span>
          </div>
          <div class="form_info_value form_info_select"
               @click="doOpenClick(2)">
            <input style="font-size:16px;padding-right:18px"
                   type="text"
                   :disabled="false"
                   placeholder="请输入"
                   :value="doChangeHourMinutestr(remindClassEarly2) || '无'">
          </div>
        </div>
      </div>
      <!-- 模板内容 -->
      <div class="message_template_box border_top">
        <div class="template_select">
          <span>提醒模板：</span>
          <el-select class="template_select_input"
                     v-model="defaultValue"
                     value-key="id"
                     :disabled="isReadonly"
                     @change="doSelectedTemplateItem"
                     :placeholder="getSelectTemplateList.length>0?'请选择':''">
            <el-option v-for="item in getSelectTemplateList"
                       :key="item.id"
                       :label="item.title"
                       :value="item"></el-option>
          </el-select>

          <span class="fr"
                style="background: none;"
                v-if="vIsThirdpartyTencentShow">
            <span @click="showAddTemplate">添加</span>
            <span style="padding-left: 10px;"
                  @click="getUpdateTemplate">刷新</span>
          </span>

          <span class="fr"
                v-else-if="!isReadonly"
                @click="getUpdateTemplate()">刷新模板</span>
        </div>
        <div v-if="getSelectTemplateList.length>0 && selectedInfo.id && isMessageContentShow">
          <message-content :messageTitle="defaultValue"
                           :templateInfo="selectedInfo"
                           :isReadonly="isReadonly"
                           :selectEventID="selectEventType.eventId"
                           @doSettingItemClick="doSettingItemClick"></message-content>
        </div>
        <div v-else>
          <div class="template_content_nodata_txt">暂无数据</div>
        </div>
      </div>
      <!-- 按钮组 -->
      <div>
        <save-cancel-btn-group :isSingleSubmit="true"
                               class=""
                               :singleSubmitType="'dynamic'"
                               @confirmClick="confirmEdit"
                               @cancelClick="cancelEdit"></save-cancel-btn-group>
      </div>

    </div>
    <!-- 选择该项进行设置 -->
    <custom-dialog :title="'消息模板设置'"
                   class="form_info_color"
                   :visible.sync="isShowSettingItem"
                   :before-close="closeEditSettingClick">
      <div>
        <setting-content ref="messageContent"
                         :getEventParametList="getEventParametList"
                         :getTemplateSettingItem="templateSettingItem"
                         :getSelectData="selectData"
                         :getMendianInfo="mendianInfo"
                         @showSelectHeadsettingItem="doSettingItemClick"
                         @doConfirmEditSetting="confirmEditSettingClick"
                         @doCloseEditSetting="closeEditSettingClick"></setting-content>
      </div>
    </custom-dialog>

    <custom-dialog :title="'上课提醒'"
                   class="new_class defult_dialog"
                   :visible.sync="iskqtqShow"
                   :before-close="doCloseShowForm">

      <weChatInformItem :isReadonly="false"
                        :selectEventId="selectEventType.eventId"
                        :remindClassEarlyType="remindClassEarlyType"
                        :remindClassEarlyCount="Number(remindClassEarlyCount)"
                        :remindClassEarlyUnits="remindClassEarlyUnits"
                        @doConfirmClick="confirmClick"
                        @doCloseShowForm="doCloseShowForm"></weChatInformItem>

    </custom-dialog>
    <custom-dialog :title="'添加模板'"
                   class="new_class defult_dialog"
                   :visible.sync="isShowAddTemplate"
                   :before-close="closeAddTemplate">
      <add-templete @afterSuccess="addTempleteAfter"
                    @close="closeAddTemplate"></add-templete>
    </custom-dialog>
    <span id="repostdiv"></span>

    <!-- 弹框的载体 所有弹出层都挂载在 custom-dialog-factory 下面 -->
    <!-- <div class="custom-dialog-factory"></div> -->
  </div>

</template>

<script>

import formSelect from '../../../../form-item/form-select';
import messageContent from './message-content';
import settingContent from './setting-content';
import weChatInformItem from './course-inform-warn';
import addTemplete from './add-templete';
import { SelectTemplate, UpdateTemplate, GetTemplateMsg, getEventParamet, AddOrEditTemplateMsg } from '../../../../../API/workbench';
export default {
  data () {
    return {
      iskqtqShow: false,
      remindClassEarly1: Number(this.$utils.getSaaSClientSetInfo(62).SetContent), // 课前第一次
      remindClassEarly2: Number(this.$utils.getSaaSClientSetInfo(63).SetContent), // 课前第二次
      selectunitsAType: '分钟', // 课前第一次单位
      selectunitsBType: '分钟', // 课前第二次单位

      remindClassEarlyType: '', // 课前类型
      remindClassEarlyCount: '', // 课前时间
      remindClassEarlyUnits: '', // 课前单位

      defaultValue: '', // 默认值
      isShowSettingItem: false, // 课前设置项
      getSelectTemplateList: [], // 获取模板列表
      getGetTemplateMsgInfo: {}, // 获取模板消息设置
      getEventParametList: [], // 获取事件动态标签列表
      selectedInfo: {}, // 模板选择项
      templateSettingItem: {}, // 选择该项数据信息
      editTemplateSettingItem: {}, // 修改该项数据信息
      selectData: [],
      isMessageContentShow: false,
      // 提交
      parameterSubmit: {
        eventId: '',
        id: '',
        templateId: '',
        url: '',
        incontent: {}
      },
      isReadonly: this.mendianInfo.WktInfo.PatternKey != 1, // // 是否只读
      isShowAddTemplate: false
    };
  },
  props: {
    mendianInfo: {
      // 门店信息
      type: Object,
      default: {}
    },
    switchState: {
      // 是否显示课前设置表单
      type: Boolean,
      default: true
    },
    selectEventType: Object // 模板类型
  },
  computed: {
    vIsThirdpartyTencentShow () {
      console.log('this.mendianInfo.WktInfo', this.mendianInfo.WktInfo.is_official_component_authorizer);
      if (this.mendianInfo.WktInfo.is_official_component_authorizer == 1) {
        return true;
      } else {
        return false;
      }
    },
    // 模块开关标题
    vFormTitle () {
      switch (this.selectEventType.eventId) {
        case 12:
          return '上课提醒';
        case 13:
          return '签到通知';
        case 15:
          return '请假通知';
        case 16:
          return '旷课通知';
        case 29:
          return '作业通知';
        case 30:
          return '批改通知';
        case 31:
          return '点评通知';
        case 32:
          return '回复通知';
        case 33:
          return '家校通知';
        case 48:
          return '约课通知';
        default:
          return this.selectEventType.name;
      }
    }
  },
  components: {
    formSelect,
    messageContent,
    settingContent,
    weChatInformItem,
    addTemplete
  },

  created () {
    this.getDataInfo();
    // 删除该项设置
    window.removeTemplateFieldForNoticeSetting = function (removeBtn) {
      removeBtn.parentNode.remove();
      console.log('removeTemplateFieldForNoticeSetting');
    };
  },
  mounted () {
    // this.getDataInfo();
  },
  methods: {
    doOpenClick (type) {
      this.remindClassEarlyType = type;
      if (this.remindClassEarlyType == 1) { // 第一次
        this.remindClassEarlyCount = this.remindClassEarly1;
        this.remindClassEarlyUnits = this.selectunitsAType;
      } else { // 第二次
        this.remindClassEarlyCount = this.remindClassEarly2;
        this.remindClassEarlyUnits = this.selectunitsBType;
      }
      this.iskqtqShow = true;
    },
    // 确定按钮
    confirmClick (item) {
      let obj = {};
      if (this.remindClassEarlyType == 1) { // 62-上课1次提醒(分钟)
        this.remindClassEarly1 = item.amount;
        this.selectunitsAType = item.units;
        this.remindClassEarlyUnits = this.selectunitsAType;
        obj = this.$utils.getSaaSClientSetInfo(62);
        obj.SetContent = (this.selectunitsAType == '小时' ? this.remindClassEarly1 * 60 : this.remindClassEarly1);
        this.$emit('modifySetting', obj, 62);
      } else { // 63-上课2次提醒(分钟)
        this.remindClassEarly2 = item.amount;
        this.selectunitsBType = item.units;
        this.remindClassEarlyUnits = this.selectunitsBType;
        obj = this.$utils.getSaaSClientSetInfo(63);
        obj.SetContent = (this.selectunitsBType == '小时' ? this.remindClassEarly2 * 60 : this.remindClassEarly2);
        this.$emit('modifySetting', obj, 63);
      }
      this.doCloseShowForm();
    },
    doCloseShowForm () {
      this.iskqtqShow = false;
    },
    ChangeHourMinutestr (str) {
      if (Number(str) > 0) {
        if (str != 0 && str != '' && str !== null && this.selectunitsAType == '分钟') {
          return '上课前 ' + ((Math.floor(str / 60)).toString() > 0 ? (Math.floor(str / 60)).toString() + '小时 ' + ((str % 60).toString() > 0 ? (str % 60).toString() + '分钟' : '') : (str % 60).toString() + '分钟');
        } else {
          return '上课前 ' + str + this.selectunitsAType;
        }
      } else {
        return '未开启 ';
      }
    },
    doChangeHourMinutestr (str) {
      if (Number(str) > 0) {
        if (str != 0 && str != '' && str !== null && this.selectunitsBType == '分钟') {
          return '上课前 ' + ((Math.floor(str / 60)).toString() > 0 ? (Math.floor(str / 60)).toString() + '小时 ' + ((str % 60).toString() > 0 ? (str % 60).toString() + '分钟' : '') : (str % 60).toString() + '分钟');
        } else {
          return '上课前 ' + str + this.selectunitsBType;
        }
      } else {
        return '未开启 ';
      }
    },
    // 更新模板
    getUpdateTemplate () {
      UpdateTemplate().then(result => {
        // 所有列表
        this.getSelectTemplateList = result.data;
        GetTemplateMsg(this.selectEventType.eventId).then(res => {
          this.getGetTemplateMsgInfo = res.data;
          // 默认模板
          if (this.getSelectTemplateList.length > 0) {
            this.getSelectTemplateList.forEach(item => {
              if (this.getGetTemplateMsgInfo && this.getGetTemplateMsgInfo.module_id == item.id) {
                this.defaultValue = item.title;
                if (this.getGetTemplateMsgInfo.incontent) {
                  this.getGetTemplateMsgInfo.incontent.forEach(r => {
                    item.incontent.forEach(e => {
                      if (e.key == r.key) {
                        e.valueNameInit = r.value;
                        e.valueName = r.value;
                        e.isIMG = false;
                      }
                    });
                  });
                }
                console.log(item, '更新模板');
                this.replaceResult(item);
              }
            });
          }
        });
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 获取模板
    getDataInfo () {
      SelectTemplate().then(result => {
        console.log(result, '获取模板列表0');
        this.getSelectTemplateList = result.data;
        GetTemplateMsg(this.selectEventType.eventId).then(res => {
          this.getGetTemplateMsgInfo = res.data;
          console.log(this.getGetTemplateMsgInfo, '消息设置1');
          if (this.getSelectTemplateList.length > 0) {
            this.getSelectTemplateList.forEach(item => {
              if (this.getGetTemplateMsgInfo && this.getGetTemplateMsgInfo.module_id == item.id) {
                this.defaultValue = item.title;
                if (this.getGetTemplateMsgInfo.incontent) {
                  this.getGetTemplateMsgInfo.incontent.forEach(r => {
                    item.incontent.forEach(e => {
                      if (e.key == r.key) {
                        e.valueNameInit = r.value;
                        e.valueName = r.value;
                        e.isIMG = false;
                      }
                    });
                  });
                }
                console.log(item, '选中模板');
                this.replaceResult(item);
              }
            });
          }
        });
      }, err => {
        layer.alert(err.msg);
      });
    },
    replaceResult (item) {
      let incontentArr = item.incontent.filter(o => { return o.value != '客户备注' && o.key != 'first' && o.key != 'remark'; });
      console.log('arrr', incontentArr);
      item.incontent = incontentArr;
      console.log(item, 'item', incontentArr, 'incontentArr');
      this.selectData = item.incontent; // 获取头部下拉框列表
      getEventParamet(this.selectEventType.eventId).then(res => { // 获取事件动态标签
        console.log(res.data, '获取事件动态标签3');
        this.getEventParametList = res.data;
        if (item.incontent && this.getEventParametList.length > 0) {
          item.incontent.forEach(r => {
            this.getEventParametList.forEach(it => {
              var defaultImg = require('../../../../../../public/image/X_icon.png') + '"';
              var keyVale = it.value;// 防止门店信息标签与输入时内容显示问题
              if (it.key === '{{CompanyName}}') {
                keyVale = this.mendianInfo.WktInfo.name ? this.mendianInfo.WktInfo.name : it.value;
              } else if (it.key === '{{CompanyTel}}') {
                keyVale = this.mendianInfo.WktInfo.tel ? this.mendianInfo.WktInfo.tel : it.value;
              } else if (it.key === '{{CompanyAddress}}') {
                keyVale = this.mendianInfo.WktInfo.address ? this.mendianInfo.WktInfo.address : it.value;
              }
              if (r.valueName) {
                r.valueName = r.valueName.replace(it.key, `<span contenteditable="false" style="display: inline;" class="remover_block templeateField font_block" onclick="doSelectedLabelShow(this)">${keyVale}<img class="remover_button" src="${defaultImg}" alt="" onclick="removeTemplateFieldForNoticeSetting(this)"></span>&nbsp`);
                r.valueName = r.valueName.replace('\n', `<br>`);

                if (r.valueName.indexOf('{{') > -1) {
                  r.valueName = r.valueName.replace(it.key, `<span contenteditable="false" style="display: inline;" class="remover_block templeateField font_block" onclick="doSelectedLabelShow(this)">${keyVale}<img class="remover_button" src="${defaultImg}" alt="" onclick="removeTemplateFieldForNoticeSetting(this)"></span>&nbsp`);
                }
                if (r.valueName.indexOf('}}') > -1) {
                  r.valueName = r.valueName.replace(it.key, `<span contenteditable="false" style="display: inline;" class="remover_block templeateField font_block" onclick="doSelectedLabelShow(this)">${keyVale}<img class="remover_button" src="${defaultImg}" alt="" onclick="removeTemplateFieldForNoticeSetting(this)"></span>&nbsp`);
                }
              }
            });
          });
        }
        this.selectedInfo = item;
        this.isMessageContentShow = true;
        console.log(this.selectedInfo, '选中模板2');
      });
    },

    // 选择模板
    doSelectedTemplateItem (item) {
      console.log(item, '选择模板');
      this.replaceResult(item, 0);
      this.defaultValue = item.title;
    },
    // 选择设置该项
    doSettingItemClick (item) {
      if (item) {
        console.log(this.selectedInfo, 'item');
        this.editTemplateSettingItem = this.$utils.parseJson(item); // 修改项;
        this.templateSettingItem = item; // 选择项
      }
      this.isShowSettingItem = true;
      console.log(this.getEventParametList, '获取事件动态标签666');
    },
    // 元素转字符串
    nodeToString (node) {
      // createElement()返回一个Element对象
      var tmpNode = document.createElement('div');
      // appendChild()  参数Node对象   返回Node对象  Element方法
      // cloneNode()  参数布尔类型  返回Node对象   Element方法
      tmpNode.appendChild(node.cloneNode(true));
      var str = tmpNode.innerHTML;
      tmpNode = node = null; // prevent memory leaks in IE
      return str;
    },
    // 保存该项设置
    confirmEditSettingClick (callBack, node) {
      if (callBack) {
        callBack();
      }
      var child = node.firstElementChild;
      while (child) {
        this.getEventParametList.forEach(it => {
          if (child.innerText === this.mendianInfo.WktInfo.name) {
            child.innerText = '{{CompanyName}}';
          } else if (child.innerText === this.mendianInfo.WktInfo.tel) {
            child.innerText = '{{CompanyTel}}';
          } else if (child.innerText === this.mendianInfo.WktInfo.address) {
            child.innerText = '{{CompanyAddress}}';
          } else if (child.outerHTML === `<br>` || child.outerHTML === `<br><br>`) {
            child.innerHTML = '\n';
            console.log(node, 'console.log(child333)');
          } else if (child.innerText == it.value) {
            child.innerText = it.key;
          }
        });
        child = child.nextElementSibling;// 阻止
      }
      this.templateSettingItem.valueName = node.innerText;
      this.templateSettingItem.valueNameInit = node.innerText;
      // console.log(this.templateSettingItem, '保存该项设置');
      this.replaceResult(this.selectedInfo);
      this.isShowSettingItem = false;
      this.isMessageContentShow = false;
    },
    // 取消该项设置
    closeEditSettingClick () {
      this.replaceResult(this.selectedInfo);
      this.isShowSettingItem = false;
      this.templateSettingItem.key = this.editTemplateSettingItem.key;
      this.templateSettingItem.value = this.editTemplateSettingItem.value;
      this.templateSettingItem.valueName = this.editTemplateSettingItem.valueName;
      this.templateSettingItem.valueNameInit = this.editTemplateSettingItem.valueNameInit;
    },
    // 确认保存
    confirmEdit (callBack) {
      this.modifySetting(callBack);// 修改门店设置

      console.log(this.selectEventType.eventId, this.selectEventType.templateKey, '模块开关');
      if (this.mendianInfo.WktInfo.PatternKey == 1) {
        var param = {};
        if (this.selectedInfo.incontent) {
          this.selectedInfo.incontent.forEach(o => {
            param[o.key] = o.valueNameInit;
          });
        }
        this.parameterSubmit = {
          eventId: this.selectEventType ? this.selectEventType.eventId : null,
          id: this.getGetTemplateMsgInfo ? this.getGetTemplateMsgInfo.message_id : null,
          templateId: this.selectedInfo ? this.selectedInfo.id : null,
          url: '',
          incontent: param
        };
        if (this.parameterSubmit.templateId) {
          AddOrEditTemplateMsg(this.parameterSubmit).then(res => {
            console.log(res.data, '确认保存');
          }, err => {
            layer.alert(err.msg);
          }).finally(() => {
            if (callBack) {
              callBack();
            }
          });
        }
      }
    },
    // 更新门店设置
    modifySetting (callBack) {
      var obj = {
        SetKey: this.selectEventType.templateKey,
        SetKeyValue: (this.selectEventType.templateKey > 0 ? '是' : '否'),
        SetContent: '',
        SetTypeKey: this.selectEventType.setTypeKey
      };
      this.$emit('modifySetting', obj, '设置成功', callBack);
    },
    // 取消保存
    cancelEdit () {
      this.$emit('cancelEdit');
    },
    // 显示添加模板
    showAddTemplate () {
      this.isShowAddTemplate = true;
    },
    closeAddTemplate () {
      this.isShowAddTemplate = false;
    },
    // 添加模板后触发更新.
    addTempleteAfter () {
      this.closeAddTemplate();
      this.getUpdateTemplate();
    }

  }

};
</script>

<style>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  not supported by any browser */
}
.snow-container {
  border: 1px solid #3498db;
  color: #3498db;
  /* padding-right: 5px; */
}
.message_template_box .banner_content_text .first_text img {
  display: none;
}
</style>
