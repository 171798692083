<template>
  <!-- 已下架的优惠券 -->
  <div class="discount_coupon_manage_ul disabled_type">
    <coupon-item v-for="(item,index) in couponList"
                 :key="index"
                 :dataInfo="item"
                 @doAlreadyReceivedClick="doAlreadyReceivedClick"
                 @doHaveBeenUsedClick="doHaveBeenUsedClick"
                 class="discount_coupon_manage_list"
                 :class="vStatus(item)"></coupon-item>
  </div>

</template>
<script>
import couponItem from './coupon-item';

export default {
  components: { couponItem },
  data () {
    return {

    };
  },
  props: {
    couponList: Array // 下架券列表
  },
  created () {

  },
  watch: {

  },
  computed: {
  },
  methods: {
    doAlreadyReceivedClick (data) {
      this.$emit('doAlreadyReceivedClick', data);
    },
    doHaveBeenUsedClick (data) {
      this.$emit('doHaveBeenUsedClick', data);
    },
    // 状态
    vStatus (item) {
      console.log(item);
      if (item.isInvalid == 1) {
        return 'type_two';
      } else if (this.$utils.formatDateToLine(Date.new()) > item.time_end) {
        return 'type_one';
      }
    }
  }
};
</script>

