<template>
  <div>
    <pre contenteditable="true"
         class="input_div"
         :style="{'text-decoration':false,'text-decoration-line':false}"
         v-html="contentText"
         @blur.stop="updateVal"
         @keypress="changeVal"
         :placeholder="placeholder">
        {{contentText}}
      </pre>
  </div>
</template>
<script>
export default {
  data () {
    return {
      contentText: '',
      maxLength: 1000
    };
  },
  props: {
    value: String, // 值，双向绑定，如不想双向绑定，则自行实现input事件
    placeholder: String
  },
  created () {
    // this.contentText = this.valueToShowContent(this.value);
  },
  mounted () {

  },
  watch: {
    value: {
      handler (c, o) {
        if (c) {
          this.contentText = this.valueToShowContent(this.value);
        }
      },  
    }
    // contentText: {
    //   handler (c, o) {
    //     if (c) {
    //       console.log(c.length, this.$utils.strLength(c).length, this.maxLength * 2);
    //       if (this.$utils.strLength(c).length >= this.maxLength * 2) {
    //         this.contentText = this.limitMaxLength(c, this.maxLength * 2);
    //       }
    //     }
    //   }
    // }
  },
  methods: {
    valueToShowContent (v) {
      console.log(v, 'valueToShowContent', this.$utils.strLength(v));
      if (v) {
        return v.toString().replace(/<br>/g, '\n');
      } else {
        return '';
      }
    },
    showContentToValue (v) {
      v = v.toString().replace(/<div><br>/g, '<br>');
      v = v.toString().replace(/<div>/g, '<br>');
      v = v.toString().replace(/<\/div>/g, '');
      let value = v.toString().replace(/<br>/g, '\n');
      return value;
    },
    changeVal (e) {
      console.log(this.$utils.strLength(e.target.innerHTML), 'changeVal', this.showContentToValue(e.target.innerHTML));
      // this.onkeyupEvent(e);
      // this.$emit('input', this.showContentToValue(e.target.innerHTML));
    },
    updateVal (e) {
      this.$emit('input', this.showContentToValue(e.target.innerHTML));
    },
    limitMaxLength (str, maxLen) {
      var result = [];
      var re = /^[a-zA-Z]+$/;
      for (var i = 0; i < maxLen; i++) {
        var char = str[i];
        if (!re.test(char)) { maxLen--; }
        result.push(char);
      }
      return result.join('');
    },
    onkeyupEvent (e) {
      if (this.maxLength > 0) {
        let val = this.showContentToValue(e.target.innerHTML);
        if (this.$utils.strLength(val) > this.maxLength * 2) { e.target.innerHTML = this.limitMaxLength(val, this.maxLength * 2); }
      }
    }
  }
};
</script>
