<template>
  <div>
    <div class="form_info_edit form_info_list"
         style="height: 475px">
      <input-text :formTitle="'校区名称'"
                  :required="false"
                  :readonly="true"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.CampusKeyValue"></input-text>

      <!-- <input-date :value="dataInfo.ApplyTime"
                  :minDate="$utils.formatDateToLine(Date.new())"
                  :formTitle="'申请日期'"
                  :readonly="true"
                  :elementId="'BirthdayTime'"
                  :required="false"
                  :formPlaceholder="'选填'"></input-date>

      <input-text :formTitle="'经办人'"
                  :required="false"
                  :readonly="true"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.CreateKeyValue"></input-text> -->
      <input-text :formTitle="'续期功能'"
                  :required="false"
                  :readonly="true"
                  :formPlaceholder="'请输入'"
                  v-model="vRenewTitle"></input-text>
      <input-number :min="0"
                    :max="100"
                    :formTitle="'续期时长（年）'"
                    :formPlaceholder="'请输入'"
                    :decimalPlaces="0"
                    :required="true"
                    :readonly="isReadonly"
                    v-model="dataInfo.PortalNum"></input-number>
      <input-text   :formTitle="'有效期'"
                    :decimalPlaces="0"
                    :required="false"
                    :readonly="true"
                    :className="'font_black'"
                    v-model="vExpireTime"></input-text>
       <input-text  :min="0"
                    :formTitle="'扣除端口数（个）'"
                    :decimalPlaces="0"
                    :required="false"
                    :readonly="true"
                    :className="'font_black'"
                    v-model="vDeductCountTitle"></input-text>

    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { PortRenew } from '../../../../../API/workbench';
export default {
  data () {
    return {
      // 直营类型
      sourceType: [
        { type: 1, name: '参股直营' },
        { type: 2, name: '直属直营' },
        { type: 3, name: '课件加盟' },
        { type: 4, name: '整店加盟' }
      ],
      locationInfo: [], // 地址
      dataInfo: {
        CampusKey: '', // "校区" ,
        CampusKeyValue: '',
        PortalNum: 1, // "端口数" ,
        RenewTypeKey: '' // "续期类型 1-教务；2-课件"
      }
    };
  },
  components: {

  },
  props: {
    campusInfo: {
      type: Object,
      default: () => {    // 下面都是必传的值。
        return {
          OLAPKey: '',  // 校区ID
          MainDemoName: '',
          IsOpenChannelKey: '', // "是否开通课件 0-否;1-是",
          IsOpenAcademicKey: '', // "是否开通教务  0-否;1-是",
          ExpireTime: ''   // 到期日
        };
      }
    },
    isReadonly: Boolean
  },
  created () {
    // console.log(this.$store.getters, 'initAddData');
    this.initData();

  },
  computed: {
    vRenewTitle () {
      let text = '';
      text = this.campusInfo.IsOpenAcademicKey == 1 ? '教务' : '课件';
      return text;
    },
    vExpireTime () {
      if (this.dataInfo.PortalNum > 0) {
        if(Date.new(this.dataInfo.ExpireTime)<Date.new()){
          this.dataInfo.ExpireTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate(Date.new().getDate() -1)))
          console.log("vExpireTime",this.dataInfo.ExpireTime)
        }
        let date1 = this.$utils.formatDateToLine(Date.new(Date.new(this.dataInfo.ExpireTime).setDate(Date.new(this.dataInfo.ExpireTime).getDate() + 1)));
        let date2 = this.$utils.formatDateToLine(Date.new(Date.new(this.dataInfo.ExpireTime).setFullYear(Date.new(this.dataInfo.ExpireTime).getFullYear() + Number(this.dataInfo.PortalNum))));
        return date1 + ' - ' + date2;
      } else {
        return '';
      }
    },
    vDeductCountTitle () {
      let text = '';
      if (parseInt(this.dataInfo.PortalNum) > 0) {
        text += this.dataInfo.RenewTypeKey == 1 ? '教务*' + parseInt(this.dataInfo.PortalNum) : '课件*' + parseInt(this.dataInfo.PortalNum);
        console.log(this.dataInfo, 'vDeductCountTitle.', text);
      }
      return text;
    }
  },
  methods: {
    initData () {
      console.log(this.campusInfo, 'initData');
      this.dataInfo = {
        CampusKey: this.campusInfo.OLAPKey, // 校区
        CampusKeyValue: this.campusInfo.MainDemoName, // 校区
        ApplyTime: this.$utils.formatDateToLine(Date.new()), // 申请日期
        PortalNum: 1, // 端口数
        ExpireTime: this.campusInfo.ExpireTime || '2022-06-06',
        RenewTypeKey: this.campusInfo.IsOpenAcademicKey == 1 ? '1' : '2'
      };
    },
    // 提交
    submit (callback) {
      console.log('submit', this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      PortRenew(this.dataInfo).then(result => {
        layer.alert('续期成功');
        this.$emit('afterSuccess');
        this.$bus.emit('updateCampusCourseware', { DataKey: this.dataInfo.CampusKey });
        this.cancel();
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callback) {
          callback();
        }
      });
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
      this.dataInfo.PortalNum=parseInt(this.dataInfo.PortalNum)
      if (!this.dataInfo.PortalNum) {
        layer.alert('请输入续期时长（年）');
        flag = false;
      }
      return flag;
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
