<template>
  <!-- 转出学生详细信息 -->
  <div class="single_result_detail">
    <div id="focusInput"
         tabindex="1"
         hidefocus="true"
         style="outline:0">
      <student-lable class="content_marginRL single_shadow"
                     :CustStatusKey="1"
                     :isShowaddOldStudentBtn="true"
                     :studentID="newInitJson.OLAPKey"
                     :mobileSupport="newInitJson.MobileSupport"
                     @changeStudent="changeStudent"
                     @alterStudent="alterStudent">
      </student-lable>
    </div>
    <!-- 已 购 课 单 -->
    <div>
      <div class="single_result_courses_purchased content_marginRL">
        <div class="courses_purchased_list"
             ref="dataScroll"
             :class="{courses_purchased_list_padding : allStudentCourseList.length > 0}">
          <alreadyBuyCourse v-for="(item,index) in allStudentCourseList"
                            :key="index"
                            :allStudentCourseItem="item"
                            :allStudentCourseList="allStudentCourseList"
                            @selectedItem="selectedItem"></alreadyBuyCourse>
        </div>
      </div>
    </div>
    <div v-if="allStudentCourseList.length==0">
      <div class="course_block_nodata"
           @click.stop="asdasdfa()">
        <div class="monitoring_content_nodata_txt">该学生暂无课单</div>
      </div>
    </div>
    <div v-else>
      <!-- 转入学生搜索框 -->
      <div v-if="searchBoxShow">
        <searchBox @searchClick="isSelectStudent()"
                   class="content_marginRL content_marginBottom_30"></searchBox>
      </div>
      <!-- 转入学生个人信息 -->
      <div v-if="selectedStudentInfoShow">
        <student-lable class="content_marginRL single_shadow"
                       :studentID="IntoStudentItem.OLAPKey"
                       :mobileSupport="IntoStudentItem.MobileSupport"
                       @changeStudent="changeToStudent"
                       @alterStudent="alterToStudent">
        </student-lable>
      </div>
    </div>
    <div v-if="selectedStudentInfoShow &&allStudentCourseList.length>0" class="form_info_edit form_info_list">
      <input-select :formTitle="'转让方式'"
                    :id="'id'"
                    :name="'name'"
                    :required="false"
                    :dataList="optionList"
                    :selectedInfo="tsfType"
                    @selectedItem="changeTsfType"></input-select>
      <input-number v-if="tsfType.id!=0" :decimalPlaces="1" :formTitle="'转让课时'" :formPlaceholder="'请输入'"
					v-model="tsfNumber"></input-number>
      <div class="content_marginRL"
           style="margin-top: 5px">
        <div class="form_info_edit">
          <div class="form_info_line single_shadow"
               style="padding: 0px">
            <input-text :formTitle="'处理原因'"
                        style="width: 100%;"
                        :readonly="false"
                        :formPlaceholder="'选填'"
                        :required="false"
                        v-model="Reason"></input-text>
          </div>
        </div>
      </div>

      
    </div>
    <!-- 按钮组 -->
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="buttonClickEvent"
                             @cancelClick="closeDialog"></save-cancel-btn-group>
  </div>
</template>
<script>
// 转让课单
import alreadyBuyCourse from '../../../../components/common/already-buy-course-item';
import searchBox from './search-box-item';
import studentLable from '../../../../components/common/student-lable';
export default {
  props: [
    'transferStudentItem',
    'isSelectedStudentItem',
    'isTransferStudentItem',
    'allStudentCourseList'
  ], // 需绑定到上面
  data () {
    return {
      selectedStudentInfoShow: false,
      searchBoxShow: true,
      newInitJson: this.isSelectedStudentItem, // 选中转出学生信息
      IntoStudentItem: {}, // 选中转入学生信息
      searchInitJson: { name: '转出学生' },
      searchInitJsonA: { name: '转入学生' },
      scrollDistance: 0,
      Reason: '', // 处理原因
      optionList: [{
	    id: 0,
	    name: '全部转让'
	  },
	  {
	    id: 1,
	    name: '部分转让'
	  },
	  ],
	  tsfType:{
		  id:0,
		  name:'全部转让'
	  },
	  tsfNumber:0
    };
  },
  components: {
    searchBox, // 搜索学生
    studentLable, // 目标学生
    alreadyBuyCourse // 已购课单
  },
  created () {
    console.log(this.allStudentCourseList, 'allStudentCourseList');
    if (this.transferStudentItem == true) {
      this.searchBoxShow = false;
      this.selectedStudentInfoShow = true;
    } else {
      this.selectedStudentInfoShow = false;
      this.searchBoxShow = true;
    }
    this.IntoStudentItem = this.isTransferStudentItem;
    this.newInitJson = this.isSelectedStudentItem;
  },
  watch: {
    isTransferStudentItem () {
      console.log(this.isSelectedStudentItem, '22222222');
      this.IntoStudentItem = this.isTransferStudentItem;
      if (this.isTransferStudentItem.OLAPKey) {
        this.searchBoxShow = false;
        this.selectedStudentInfoShow = true;
      } else {
        this.searchBoxShow = true;
      }
    },
    isSelectedStudentItem () {
      this.newInitJson = this.isSelectedStudentItem;
      console.log(this.allStudentCourseList, '课单数');
    }
  },
  methods: {
     changeTsfType(item){
		  this.tsfType.id=item.id;
		  this.tsfType.name = item.name;
		  if(item.id==0){
			  this.tsfNumber=0
		  }
	  },
    // 触发焦点事件.
    focusInput () {
      document.getElementById('focusInput').focus();
    },
    // 关闭页面.
    closeDialog () {
      this.$emit('istransferCourseBillHide');
    },
    // 显示转出的学生的学员档案
    showStudentFile () {
      this.$emit('showStudentFile', this.isSelectedStudentItem);
    },
    // 显示转入的学生的学员档案
    showIntoStudentFile () {
      this.$emit('showStudentFile', this.isTransferStudentItem);
    },
    isSelectStudent () {
      var isSelectStudentShow = true;
      this.$emit('isSelectStudent', isSelectStudentShow);
    },
    buttonClickEvent (callBack) {
      this.$emit('buttonClickEvent', this.Reason,this.tsfNumber,this.tsfType, callBack);
    },
    changeToStudent () {
      this.$emit('changeToStudent');
    },
    changeStudent () {
      this.$emit('changeStudent');
    },
    alterStudent (item) {
      this.newInitJson.OLAPKey = item.OLAPKey;
      this.$emit('alterStudent', item, item.OLAPKey);
    },
    alterToStudent (item) {
      this.IntoStudentItem.OLAPKey = item.OLAPKey;
      this.IntoStudentItem.MobileSupport = item.MobileSupport;
    },
    // 选择学生课单项
    selectedItem (item, val) {
      this.scrollDistance = 200;
      this.selectCourseFormItem = item;
      this.$emit('selectedCourseItem', item); // 往上传递.转让课单接口需要此参数.
      //  学员档案项选中课单位置
      this.$nextTick(() => {
        this.allStudentCourseList.forEach((o, i) => {
          if (this.selectCourseFormItem.TableID == o.TableID) {
            this.$emit('CourseIndex', i);
            if (!val) {
              this.$refs.dataScroll.scrollLeft = i * 205 + i * 2;
            }
          }
        });
      });
    }
  }
};
</script>   
<style scoped>
</style>
      