<template>
  <div class="ZSJK_menuBox_dataList_table">
    <el-table class="table_list"
              ref="multipleTable"
              :data="tableData"
              stripe
              empty-text
              :row-class-name="tableRowClassName"
              style="width: 100%">
      <!-- 表头 -->
      <el-table-column v-for="(item,index) in vTableColumns"
                       :width="item.width"
                       :sortable="item.sortable"
                       :align="item.align"
                       :key="index"
                       :type="item.type"
                       :label="item.label"
                       :prop="item.prop"
                       :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <!-- <el-tooltip :disabled="!item.toolTip" :content="customerTemplateShow(item.toolTip,scope.row)" placement="bottom" effect="light"> -->
          <span v-if="item.type=='number'">{{rounding(scope.row,item.prop,item.decimalPlace)}}</span>
          <el-button v-else-if="item.type=='tableBtn'"
                     v-for="(info,indexs) in item.tableBtnGroup"
                     :class="info.className(scope.row[info.Stutas])"
                     class="tableBtn"
                     :key="indexs"
                     size="mini"
                     @click.stop="$emit('showDetail', scope.row,info.eventName)">{{info.eventName == 9?ifBtnName(scope.row):info.name}}</el-button>
          <span v-else-if="item.type=='html'"
                v-html="scope.row[item.prop]">{{scope.row[item.prop]}}</span>
          <!-- 手机 -->
          <!-- <span v-if="item.type=='telephone'&& teShow==true">{{scope.row[item.prop]}}</span> -->
          <span v-else
                :class="item.className"
                :title="scope.row[item.title]">{{scope.row[item.prop]}}</span>
          <!-- </el-tooltip> -->
        </template>
      </el-table-column>
      <div slot="empty"
           style="height:390px;line-height:390px;">
        <i class="el-icon-warning-outline">
          <div class="monitoring_content_nodata"></div>
          亲，当前没有任何数据哦~
        </i>
      </div>
    </el-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dom: ''
    };
  },
  props: {
    // tableHead: Array,
    // tableData: Array
    tableData: Array, // 列表数据
    tableColumns: Array // table column
  },
  computed: {
    vTableColumns () {
      let newTableColumns = [];
      let tableColumn = this.tableColumns;
      if (tableColumn) {
        tableColumn.forEach(element => {
          if (element.isShow) {
            newTableColumns.push(element);
          }
        });
      }
      return newTableColumns;
    }
  },
  mounted () {
    this.dom = this.$refs.multipleTable.bodyWrapper;
    this.dom.addEventListener('scroll', () => {
      // 滚动距离
      let scrollTop = this.dom.scrollTop;
      // 变量windowHeight是可视区的高度
      let windowHeight = this.dom.clientHeight || this.dom.clientHeight;
      // 变量scrollHeight是滚动条的总高度
      let scrollHeight = this.dom.scrollHeight || this.dom.scrollHeight;
      if (scrollTop + windowHeight === scrollHeight) {
        // 获取到的不是全部数据 当滚动到底部 继续获取新的数据
        this.$emit('scrollUpdateData');
        console.log('scrollTop', scrollTop + 'windowHeight', windowHeight + 'scrollHeight', scrollHeight);
      }
    });
  },
  methods: {
    tableRowClassName ({ row, rowIndex }) {
      console.log(row, 'row', this);
      // if (rowIndex === 0) {
      //   return 'warning-row';
      // } else if (rowIndex === 1) {
      //   return 'success-row';
      // }
      if (row.read_gift_status == 3 || row.read_gift_status == 1) {
        return 'success-row';
      }
      return '';
    },
    customerTemplateShow (tpl, data) {
      tpl = tpl || '';
      if (tpl.indexOf('{{') >= 0) {
        // 仍需要处理, 处理为通用. 因为有可能前后都有一些文字tpl有可能为'""+data["name"]+""'
        console.log('customerTemplateShow');
        console.log(data);
        return eval('""+data["telephone"]+""');
      } else {
        return tpl;
      }
    }

  }
};
</script>
<style>
.el-table .success-row:hover .cell {
  /* background: #f0f9eb; */
  color: #8a919b;
}
.el-table .success-row:hover .cell .tableBtn {
  /* background: #f0f9eb; */
  color: #8a919b !important;
}
</style>