<template>
  <!-- 上课记录列表 -->
  <div class="">
    <list v-if="isCourseInfo"
          ref="studentApplyCourseTable"
          :rowKey="'Number'"
          :tableData="classRecordListL"
          :totalNum="classRecordTotal"
          :PageCount="PageCount"
          :tableColumns="tableColumns"
          :pageSize="15"
          :isSearchText="false"
          :defaultSort="defaultSort"
          :queryParams="classRecordParams"
          @tableBtnClick="doAfterTableBtnClick"
          @loadTableData="loadAfterTableData"></list>
    <list-new v-else
              ref="studentApplyCourseTable"
              :rowKey="'Number'"
              :tableData="classRecordListL"
              :totalNum="classRecordTotal"
              :PageCount="PageCount"
              :tableColumns="tableColumnsNew"
              :tableColumnsNew="tableColumnsNew"
              :pageSize="15"
              :isSearchText="false"
              :defaultSort="defaultSort"
              :queryParams="classRecordParams"
              @tableBtnClick="doAfterTableBtnClick"
              @loadTableData="loadAfterTableData"></list-new>

  </div>
</template>
<script>

import list from './general-table';
// import tableList from '../../../common/table-list/index';
import listNew from './general-table-new';
// import attendanceArrange from '../../../pop-up-menu/exception-handle/attendance-arrange-form/attendance-arrange';
export default {
  data () {
    return {
      seletedCourseInfo: {},
      studentList: [], // 按学生 的 学生列表
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 58,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          className: '',
          decimalPlace: 0
        },
        {
          label: '上课日期',
          prop: 'ClassDate',
          width: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'date-item',
          dateType: 'h:min-MM:DD'
        },
        {
          label: '上课时间',
          prop: 'SchoolTime',
          width: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          dateType: 'h:min-MM:DD'
        },
        {
          label: '班名',
          prop: 'GradeClassKeyValue',
          width: 110,
          sortable: 'custom',
          align: 'center',
          isShow: true,
          type: 'reading-courses',
          columnClass: ''
        },
        {
          label: '课名',
          prop: 'CourseNameKeyValue',
          width: 110,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'reading-courses',
          columnClass: ''
        },
        {
          label: '老师名',
          prop: 'MTeacherKeyValue',
          width: 80,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          className: ''
        },
        {
          label: '应到学生',
          prop: 'ArrangedNumCount',
          width: 80,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'number-item',
          decimalPlace: 0
        },
        {
          label: '出勤人次',
          prop: 'ArrivedNumCount',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'number-item',
          decimalPlace: 0
        },
        {
          label: '未出勤人次',
          prop: 'TruantNumCount',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'number-item',
          decimalPlace: 0
        },
        {
          label: '出勤率',
          prop: 'AttendanceRate',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        },
        {
          label: '消耗课时',
          prop: 'DeductCount',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        },
        {
          label: '结转课消',
          prop: 'DeductAmount',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        },
        {
          label: '生均消费',
          prop: 'AvergeAmount',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        },
        {
          label: '操作',
          type: 'table-btn',
          align: 'center',
          fixed: 'right',
          extend: {
            tableBtnGroup: [
              {
                name: '查看', // 原本 - 详情   现 - 查看
                extend: {
                  click: (rowData) => { this.doAfterTableBtnClick(rowData); },
                  ModulePowerKey: 51
                }
              }
            ]
          }
        }
      ],
      tableColumnsNew: [
        {
          label: '序号',
          prop: 'Number',
          width: 58,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          className: '',
          decimalPlace: 0
        },
        {
          label: '上课日期',
          prop: 'ClassDate',
          width: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'date-item',
          dateType: 'h:min-MM:DD'
        },
        {
          label: '上课时间',
          prop: 'SchoolTime',
          width: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          dateType: 'h:min-MM:DD'
        },
        {
          label: '考勤日期',
          prop: 'AttenceDate',
          width: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'date-item',
          dateType: 'h:min-MM:DD'
        },
        {
          label: '考勤时间',
          prop: 'AttenceTime',
          width: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          dateType: 'h:min-MM:DD'
        },
        {
          label: '考勤时差',
          prop: 'TimeDifference',
          width: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          className: ''
        },
        {
          label: '操作人',
          prop: 'AttenceOpratorKeyValue',
          width: 110,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'reading-courses',
          columnClass: ''
        },
        {
          label: '班名',
          prop: 'GradeClassKeyValue',
          width: 110,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'reading-courses',
          columnClass: ''
        },
        {
          label: '课名',
          prop: 'CourseNameKeyValue',
          width: 110,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'reading-courses',
          columnClass: ''
        },
        {
          label: '老师名',
          prop: 'MTeacherKeyValue',
          width: 80,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          className: ''
        },
        {
          label: '学生名',
          prop: 'StudentNameKeyValue',
          width: 80,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          className: ''
        },
        {
          label: '考勤结果',
          prop: 'AttenceStatusKeyValue',
          width: 100,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0
        },
        {
          label: '消耗课时',
          prop: 'DeductCount',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0
        },
        {
          label: '结转课消',
          prop: 'DeductAmount',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        },
        {
          label: '消耗课单',
          prop: 'NumberName',
          width: 140,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          extend: {
            click: (rowData) => {
              this.goRouter(rowData);
            }

          }
        },
        {
          label: '课单类型',
          prop: 'ClassUnitType',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        },
        {
          label: '适用课程',
          prop: 'ApplyCorName',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 2
        },
        {
          label: '操作',
          type: 'table-btn',
          align: 'center',
          fixed: 'right',
          extend: {
            tableBtnGroup: [
              {
                name: '查看', // 原本 - 详情   现 - 查看
                extend: {
                  click: (rowData) => { this.doAfterTableBtnClick(rowData); },
                  ModulePowerKey: 51
                }
              }
            ]
          }
        }
      ],
      PageCount: 0,
      summary: {
        needSummariesColumns: [
          { prop: 'StudentKeyValue', SumType: 'string', unit: '人' },
          { prop: 'Total_NumCount', SumType: 'number', unit: '' },
          { prop: 'Sum_SpendCount', SumType: 'number', unit: '' },
          { prop: 'CurrentClassHourCount', SumType: 'number', unit: '' },
          { prop: 'Sum_CurrentCount', SumType: 'number', unit: '' },
          { prop: 'Total_ToBePlaned', SumType: 'number', unit: '' },
          { prop: 'Sum_ArrangedCount', SumType: 'number', unit: '' },
          { prop: 'Sum_ToBeArranged', SumType: 'number', unit: '' }
        ],
        isShowSummary: false,
        summaryName: '合计',
        summaryData: []
      },
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  components: {
    list,
    listNew
    // attendanceArrange
  },
  props: {
    classRecordListL: Array,
    classRecordTotal: Number,
    classKey: [Number, String], // 班级ID
    classRecordParams: Object,
    isCourseInfo: Boolean // true课次记录 false结转课消

  },
  created () {
    console.log(this.classRecordListL, 'classRecordListL');
  },
  mounted () {
    this.$refs.studentApplyCourseTable.queryParams.pageIndex = 0;
    // this.$dialog.register(this, ['attendanceArrange']);
  },

  watch: {
    'classKey': {
      handler (n, o) {
        console.log(n, '班级id-123');
        if (Number(n) > 0 && n != o) {
          this.$nextTick(() => {
            this.$refs.studentApplyCourseTable.queryParams.pageIndex = 0;
          });
        }
      },
      deep: true // 表示开启深度监听
    },
    'classRecordParams.pageIndex': {
      handler (n, o) {
        if (n == 0) {
          this.$nextTick(() => {
            this.$refs.studentApplyCourseTable.queryParams.pageIndex = 0;
          });
        }
      },
      deep: true // 表示开启深度监听
    }
  },
  computed: {
    vTable () {
      let arr = [];
      if (this.isCourseInfo) {
        arr = this.tableColumns;
      } else {
        arr = this.tableColumnsNew;
      }
      return arr;
    },
    vtableColumns () {
      let list = [];
      this.tableColumns.forEach(o => {
        if (this.$utils.getSaaSClientSet(59) > 0 && o.label == '旷课人次') {
          o.isShow = true;
        } else if (this.$utils.getSaaSClientSet(59) == 0 && o.label == '旷课人次') {
          o.isShow = false;
        }
        list.push(o);
      });
      return list;
    }
  },
  methods: {
    goRouter (item) {
      console.log('goRouter', item);
      let moduleInfo = {
        routerName: 'classListQueryStatistics',
        moduleName: '',
        ModulePowerKey: 127,
        data: { dataInfo: { StudentKey: item.StudentKey, TableID: item.TableID, searchText: item.NumberName } }

      };
      this.$bus.emit('needOpenRouter', moduleInfo);
      // layer.alert("此功能暂未开通")
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData) {
      console.log(rowData, '按钮点击');
      this.seletedCourseInfo = rowData;
      this.$dialog.open(this, {
        name: '课程详情',
        moduleName: 'attendanceArrange',
        routerName: this.$route.name,
        data: { scheduleCourseKey: this.seletedCourseInfo.OLAPKey }
      });
    },
    // 跳转页码
    loadAfterTableData (queryParams) {
      console.log(queryParams, '跳转页码');
      this.$emit('loadTableData', queryParams);
    }
  }

};
</script>
