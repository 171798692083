<template>
  <div class="record_img_content">
    <div class=""
         style="width:34px">
      <div class="font_black"
           style="font-size:14px">
        {{vYear}}
      </div>
      <div class="font_gray"
           style="font-size:12px;color:#999">
        {{vDate}}
      </div>
    </div>
    <div class="record_right_content">

      <div class="record_right_content_item font_black"
           style="line-height:20px;word-break: break-word;"
           v-html="item.Conent"></div>
      <div class="record_right_content_item"
           v-if="item.FollowImgList.length>0">
        <div class="record_img_content_imgitem"
             v-for="(imgItem, key) in item.FollowImgList"
             :key="key"
             @click="showPreview(imgItem)">
          <img :src="$store.getters.CDNURL + imgItem.Url"
               :onerror="defaultImg"
               alt="" />
        </div>
      </div>

      <div class=""
           style="color:#999">跟单人: {{item.FollowName}}</div>
    </div>
    <custom-dialog :title="'预览图片'"
                   :visible.sync="isPreviewImg"
                   :before-close="closePreviewImg">
      <div class="home_work_img">
        <preview-img :imgList="vimgList"
                     :showIndex="item.index"
                     :previewText="item.Content"
                     @onClosePreviewImg="closePreviewImg"></preview-img>
      </div>
    </custom-dialog>
  </div>
</template>
<script>
import previewImg from '../../../home-school-communication/common-component/preview-img/index';

export default {
  data () {
    return {
      isPreviewImg: false
    }
  },
  components: {
    previewImg
  },
  props: {
    item: Object
  },
  computed: {
    vimgList () {
      let list = [];
      this.item.FollowImgList.forEach(o => {
        list.push({ imgUrl: o.Url, id: o.OLAPKey });
      });
      return list;
    },
    vYear () {
      let date = ''
      date = this.item.GenerateTime ? this.item.GenerateTime.substring(0, 4) : ''
      return date
    },
    vDate () {
      let date = ''
      date = this.item.GenerateTime ? this.item.GenerateTime.substring(5, 10) : ''
      return date
    },
    defaultImg () {
      return 'this.src="' + require('../../../../../public/image/no_logo@2x.png') + '"';
    },

  },
  methods: {
    showPreview (item) {
      this.PhotoUrl = this.$store.getters.CDNURL + item.AttachUrl;
      console.log(item, 'aaa');
      this.isPreviewImg = true;
      let locaIndex = this.item.FollowImgList.findIndex(o => {
        return o.OLAPKey == item.OLAPKey;
      });
      if (locaIndex >= 0) {
        this.item.Content = item.Title;
        this.item.index = locaIndex;
      } else {
        this.item.index = 0;
      }
    },
    // 关闭预览图片
    closePreviewImg () {
      this.isPreviewImg = false;
      this.item.index = 0;
    },
  }
}
</script>
<style scoped>
.record_right_content {
  flex: 1;
  padding-left: 15px;
  display: flex;
  flex-direction: column;

  /* margin-bottom: 10px; */
}
.record_right_content_item {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}
.span_flex {
  flex: 1;
  display: flex;
}
.record_type_01 {
  background: url('../../../../../public/image/record_type_01.png') center
    no-repeat;
  background-size: 14px;
  display: inline-block;
  width: 15px;
}
.record_type_02 {
  background: url('../../../../../public/image/record_type_02.png') center
    no-repeat;
  background-size: 14px;
  display: inline-block;
  width: 15px;
}
.record_type_03 {
  background: url('../../../../../public/image/record_type_03.png') center
    no-repeat;
  background-size: 14px;
  display: inline-block;
  width: 15px;
}
.record_img_content {
  display: flex;
  flex-direction: row;
  margin: 15px 0px;
  /* height: 109px; */
  /* margin-bottom: 15px; */
}
.flex_row {
  display: flex;
  flex-direction: row;
}
.flex_row {
  display: flex;
  flex-direction: row;
}
.record_img_content_imgitem {
  width: 85px;
  height: 85px;
  margin-right: 10px;
  text-align: center;
  position: relative;
}
.record_img_content_imgitem img {
  width: 85px;
  height: 85px;
}
</style>
