<template>
  <div class="table_nav_box pprr">
    <div class="table_fixed_nav tltle_margin">
      <div v-for="item in titleList"
           :key="item.key"
           @click="chgTitleList(item)"
           class="table_fixed_nav_list"
           :class="{opt:item.isSelected}">{{item.name}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    titleList: Array
  },
  created () {
  },
  methods: {
    // 切换门店列表
    chgTitleList (item) {
      this.titleList.forEach(o => {
        if (item.key == o.key) {
          o.isSelected = true;
        } else {
          o.isSelected = false;
        }
      });
      this.$emit('chgTitleList', item);
    }
  },
  computed: {

  }
};
</script>
<style scoped>
.pprr{
  width: 1208px !important;
  /* margin: 0px auto; */
}
.prepare_lesson_module .table_nav_box {
  background-color: #FAFAFA;

}

.table_fixed_nav_list {
    line-height: 19px;
    height: 35px;
    font-size: 14px;
    padding: 0 0px;
    text-align: center;
    cursor: pointer;
    color: #666;
    margin-right: 20px;
}
.super_management .tltle_margin{
  margin-left: 0px;
}
</style>