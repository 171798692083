<template>
  <div class="teaching_mode_cw_list">
    <div class="teaching_mode_bg_ligth">

    </div>
    <div class="teaching_mode_cw_list_scroll">
      <childCoursewareItem v-for="(item,key) in vDataList"
                           :key="key"
                           :item="item"
                           :dataInfo="dataInfo"
                           :seletedOLAPKey="seletedOLAPKey"
                           @seletedItem="seletedItem"
                           @goTeachingView="goTeachingView"
                           @chooseCourseForClass="chooseCourseForClass"></childCoursewareItem>
      <div class=""
           :class="isSearch?vSearchPic:'teaching_mode_cw_list_nodata'"
           v-if="vDataList.length==0">
        <div class="nodata_text">{{isSearch?vSearchTip:'暂无课件'}}</div>
      </div>
    </div>

    <teaching-mode-custom-dialog :title="'开课'"
                                 class="new_class defult_dialog"
                                 :visible.sync="isShowClassSeletcion"
                                 :before-close="closeClassSeletcion">
      <class-selection @closeDialog="closeClassSeletcion"
                       :isTeachingMode="true"
                       :dataInfo="classInfo"
                       @afterSuccess="afterSuccessForGoClass"></class-selection>
    </teaching-mode-custom-dialog>

    <teaching-mode-custom-dialog :title="'设备授权申请'"
                                 class="new_class defult_dialog"
                                 :visible.sync="isShowAuthForm"
                                 :before-close="closeForm">
      <auth-form @closeDialog="closeForm"
                 :dataInfo="authInfo"
                 @afterSuccess="afterSuccessForApplyAuth"
                 @afterSuccessCallback="afterSuccessCallback"
                 :successCallback="true"
                 :isTeachingMode="true"></auth-form>
    </teaching-mode-custom-dialog>
    <teaching-mode-custom-dialog title="安全验证"
                                 class="student_info_dialog defult_dialog"
                                 :visible.sync="TeachingModeforQrcode"
                                 :before-close="closeAuthCodeDialog">
      <courseAuthQrcode @closeDialog="closeAuthCodeDialog"
                        @afterSuccess="openAuthAfterSuccess"
                        :dataInfo="authQrcodeDataInfo"></courseAuthQrcode>
    </teaching-mode-custom-dialog>
  </div>
</template>
<script>
import childCoursewareItem from "./child-courseware-item";
import classSelection from "../../courseware-work-bench/components/goToClass";
import authForm from "../../courseware-work-bench/components/report/auth-components/apply-auth.vue";
import { CWSAuthorizedDeviceManagementAuthorizedTheDevice, AddInterfaceLog } from "../../../API/workbench";
import courseAuthQrcode from '../../course-table/attendance-arrange/teaching-components/course-auth-qrcode';

export default {
  data () {
    return {
      isShowClassSeletcion: false,
      authInfo: {},
      classInfo: {},
      isShowAuthForm: false,
      TeachingModeforQrcode: false,
      authQrcodeDataInfo: {},
      authType: ''
    };
  },
  props: {
    dataInfo: Object,
    dataList: Array,
    seletedOLAPKey: [Number, String], //选中id
    isSearch: Boolean,   //是否搜索列表
    searchEnd: Boolean  //是否搜索完
  },
  components: {
    childCoursewareItem,
    classSelection,
    authForm,
    courseAuthQrcode
  },
  created () {
    console.log("dataList", this.dataList);
  },
  computed: {
    vDataList () {
      return this.dataList;
    },
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    },
    isAutoAuth () {
      return this.$utils.getSaaSClientSetInfo(75).SetKey == 1;
    },
    vHasAuthModulePower () {
      let ModulePowerKey =
        this.$store.getters.SaaSClientEditionKey == 5 ? 108 : 123;
      return this.$utils.CheckModulePower(this.vRolePowerList, ModulePowerKey);
    },
    vSearchTip () {
      return this.searchEnd ? '没有符合条件的结果' : ''
    },
    vSearchPic () {
      return this.searchEnd ? 'teaching_mode_cw_list_search_nodata' : ''
    }
  },
  methods: {
    // 申请授权成功。
    afterSuccessForGoClass () {
      this.isShowClassSeletcion = false;
    },
    afterSuccessCallback () {
      console.log("afterSuccessCallback", this.authType)
      this.isShowAuthForm = false;
      this.dataInfo.DeviceIsAuth = 1;
      if (this.authType != 'chooseCourseForClass') {
        layer.confirm("设备授权已生效，是否继续进入课件？", {
          mode: "teachingMode",
          btn: [
            {
              name: "确认",
              callBack: () => {
                this.goTeachingView(this.seletdcourseware)
                layer.close();
              }
            }
          ]
        });
      } else {
        this.chooseCourseForClass(this.seletdcourseware)
      }
    },
    // 申请授权成功。
    afterSuccessForApplyAuth () {
      this.isShowAuthForm = false;
      // this.dataInfo.DeviceIsAuth = 1;
    },
    seletedItem (item) {
      this.$emit("seletedItem", item);
    },
    closeForm () {
      this.isShowAuthForm = false;
    },
    chooseCourseForClass (item) {
      console.log("选班上课", item);
      this.seletdcourseware = item;

      if (Number(this.dataInfo.DeviceIsAuth) == 0) {
        //设备未授权
        this.applyAuth('chooseCourseForClass')
      } else {
        if (item.ScheduleCourseKey > 0) {
          if (this.dataInfo.PCIsLocationLockKey == 1 && this.dataInfo.HQAuthKey != 0) {
            let text = this.dataInfo.HQAuthKey == 1 ? '  当前门店定位，课件授权方禁止访问课件' : ' 当前门店定位，课件授权方正在审核中，暂时无法访问课件'
            layer.alert(text, {
              mode: 'teachingMode'
            })
            return false
          } else {
            layer.confirm("是否继续上课", {
              mode: "teachingMode",
              btn: [
                {
                  name: "确认",
                  callBack: () => {
                    if (this.dataInfo.PCIsLocationLockKey == 1 && !this.$store.getters.geoInfo.Longitude) {
                      this.authType = 'chooseCourseForClass'
                      this.openAuthDialog(item.ScheduleCourseKey, 'ZZ', 1, 11);
                    } else {

                      this.$router.push({
                        name: 'coursewareTeachingView',
                        query: {
                          type: 1,
                          scheduleCourseID: item.ScheduleCourseKey, // 正在上课的Key
                          isTeachingMode: true
                        }
                      });
                    }
                    layer.close();
                  }
                }
              ]
            });

          }
        } else {

          this.classInfo = {
            item: this.seletdcourseware,
            PCIsLocationLockKey: this.dataInfo.PCIsLocationLockKey,
            courserId: this.seletdcourseware.OLAPKey,
            Longitude: this.dataInfo.Longitude || "",
            Latitude: this.dataInfo.Latitude || "",
            PositioningOffset: this.dataInfo.PositioningOffset || ""
          };
          this.isShowClassSeletcion = true;
        }
      }
    },
    closeClassSeletcion () {
      this.isShowClassSeletcion = false;
      console.log('关闭咯弹窗')
    },
    applyAuth (type) {
      this.authType = type
      console.log("this.$store.getters.SaaSClientEditionKey", this.$store.getters.SaaSClientEditionKey, this.vHasAuthModulePower)
      if (this.vHasAuthModulePower) {
        if (this.$zxClient.GetMInfo() === '{}') {
          layer.alert('设备信息为空', {
            mode: 'teachingMode'
          })
          let interfaceLogData = {
            InterfaceName: this.$store.getters.APIURL + 'CWS.AuthorizedDeviceManagement.AuthorizedTheDevice',
            parameter: { BigCoursewarePackageKey: this.dataInfo.OLAPKey },
            PostData: JSON.stringify(this.$store.getters.token),
            LogContents: '设备信息为空',
            LogTitleName: '设备信息为空',
            RefererName: window.location.href
          };
          AddInterfaceLog(interfaceLogData).then(result => {
            console.log('记录日志')
          });
        } else {
          let DeviceInfo = JSON.parse(this.$zxClient.GetMInfo());
          layer.confirm("是否授权本设备", {
            mode: "teachingMode",
            btn: [
              {
                name: "确认",
                callBack: () => {
                  CWSAuthorizedDeviceManagementAuthorizedTheDevice(
                    DeviceInfo,
                    this.dataInfo.OLAPKey
                  ).then(
                    res => {
                      this.$zxClient.SetUCode(res.data.DeviceUCode);
                      // if (this.isAutoAuth) {
                      this.dataInfo.DeviceIsAuth = 1;
                      if (type == 'goTeachingView') {
                        layer.confirm("设备授权已生效，是否继续进入课件？", {
                          mode: "teachingMode",
                          btn: [
                            {
                              name: "确认",
                              callBack: () => {
                                this.goTeachingView(this.seletdcourseware)
                                layer.close();
                              }
                            }
                          ]
                        });
                      } else {
                        this.chooseCourseForClass(this.seletdcourseware)
                      }
                      // }else{
                      //    layer.alert('申请已提交，请联系管理员进行审核后再进入课件', { mode: 'teachingMode' });
                      // }
                    },
                    err => {
                      layer.alert(err.msg, {
                        mode: "teachingMode"
                      });
                    }
                  );
                  layer.close();
                }
              }
            ]
          });
        }




        // 无权限
      } else {
        this.authInfo = {
          CoursewareKey: this.dataInfo.OLAPKey,
          DeviceCode: this.$zxClient.GetMCode()
        };
        this.isShowAuthForm = true;
      }
    },
    // 快捷上课
    goTeachingView (item) {
      console.log("goTeachingView", item, this.dataInfo);
      this.seletdcourseware = item;
      if (Number(this.dataInfo.DeviceIsAuth) == 0) {
        //设备未授权
        this.applyAuth('goTeachingView')
      } else if (
        this.dataInfo.PCIsLocationLockKey == 1 &&
        !this.dataInfo.Longitude
      ) {
        //PC开启定位验证
        this.authType = 'goTeachingView'
        this.openAuthDialog(this.seletdcourseware.OLAPKey, "", 4, 12);
      } else {
        if (this.dataInfo.PCIsLocationLockKey == 1 && this.dataInfo.HQAuthKey != 0) {
          let text = this.dataInfo.HQAuthKey == 1 ? '  当前门店定位，课件授权方禁止访问课件' : ' 当前门店定位，课件授权方正在审核中，暂时无法访问课件'
          layer.alert(text, {
            mode: 'teachingMode'
          })
          return false
        } else {
          let newdataInfo = {
            ScheduleCourseKey: '',
            courserId: this.seletdcourseware.OLAPKey,
            client_id: this.$store.getters.clientId,
            SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
            DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
            introType: 12,
            DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
          };
          this.$utils.verifySystemLocation(
            newdataInfo,
            success => {
              this.$router.push({
                name: "coursewareTeachingView",
                query: {
                  type: 4,
                  coursewareId: this.seletdcourseware.OLAPKey,
                  isTeachingMode: true

                }
              });
              setTimeout(() => {
                this.$emit('getPrincipalWorkbenchGetCoursewareList');
              }, 500);
            },
            error => {
              this.openAuthDialog(this.item.OLAPKey, '', 4, 12);
            }
          );

        }

      }
    },
    closeAuthCodeDialog () {
      this.TeachingModeforQrcode = false
    },
    openAuthAfterSuccess () {
      this.TeachingModeforQrcode = false
      setTimeout(() => {
        if (this.authType == 'goTeachingView') {

          this.$router.push({
            name: "coursewareTeachingView",
            query: {
              type: 4,
              coursewareId: this.seletdcourseware.OLAPKey,
              isTeachingMode: true
            }
          });
        } else {
          this.$router.push({
            name: 'coursewareTeachingView',
            query: {
              type: 1,
              scheduleCourseID: this.seletdcourseware.ScheduleCourseKey, // 正在上课的Key
              isTeachingMode: true
            }
          });
        }
      }, 500)

    },
    openAuthDialog (OLAPKey, type, model, introType) {
      let DeviceCode = "";
      if (this.$zxClient.IsClient) {
        DeviceCode = this.$zxClient.GetMCode();
      }
      let skey = '';
      if (model == 1) {
        // this.chooseScheduleCourseKey = OLAPKey;
        skey = OLAPKey;
      }
      console.log("this.dataInfo", this.dataInfo)
      if (this.dataInfo.HQAuthKey != 0) {
        let text = this.dataInfo.HQAuthKey == 1 ? '  当前门店定位，课件授权方禁止访问课件' : ' 当前门店定位，课件授权方正在审核中，暂时无法访问课件'
        layer.alert(text, {
          mode: 'teachingMode'
        })
        return false
      } else {
        this.authQrcodeDataInfo = {
          ScheduleCourseKey: skey,
          courserId: this.seletdcourseware.ParentKey,
          client_id: this.$store.getters.clientId,
          SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
          DeviceTypeKey: "2", //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
          introType: introType,
          DeviceCode: DeviceCode //桌面端设备码
        }
        this.TeachingModeforQrcode = true
      }
    }
  }
};
</script>
<style scoped>
.teaching_mode_bg_ligth {
  background: #9ebceb;
  opacity: 0.3;
  filter: blur(75px);
  position: absolute;
  width: 560.42rem;
  height: 50.19rem;
  left: 83.69rem;
  top: 0rem;
  border-radius: 50%;
}
.teaching_mode_cw_list {
  width: 709.8rem;
  height: 353rem;
  /* 351rem */
  margin: auto;
  padding: 19.5rem 9rem 0rem;
  /* backdrop-filter: blur(1.95rem); */
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-top: 0.98rem solid;
  /* border-bottom: 0.98rem solid; */
  /*设置线性渐变*/
  border-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      #fff 50%,
      rgba(255, 255, 255, 0) 99%
    )
    2 2 2 2;

  /* background: red; */
}
/* 
.teaching_mode_cw_list::before {
  display: block;
  content: "";
  border-left: 0.58rem solid;
  position: absolute;
     height: 60%;
    top: 55rem;
    left: 0;
  border-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #878889 50%,
      rgba(255, 255, 255, 0) 100%
    )
    2 2 2 2;
}
.teaching_mode_cw_list::after {
  border-right: 0.58rem solid;
  display: block;
  content: "";
  position: absolute;
    height: 60%;
    top: 55rem;
  right: 0px;
  border-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #878889 50%,
      rgba(255, 255, 255, 0) 100%
    )
    2 2 2 2;
} */
.teaching_mode_cw_list_scroll {
  width: 688rem;
  height: 100%;
  overflow-y: auto;
}
.teaching_mode_cw_list_scroll::-webkit-scrollbar {
  width: 1.57rem;
  height: 2.37rem;
  background-color: #ebebeb;
}
.teaching_mode_cw_list_scroll::-webkit-scrollbar-thumb {
  background-color: #a1a3a9;
  border-radius: 3px;
}
.teaching_mode_cw_list_scroll::-webkit-scrollbar-track {
  background-color: #777e90;
}
.teaching_mode_cw_list_nodata {
  width: 100%;
  height: 100%;
  margin: auto;
  background: url('../../../../public/image/teaching_no_courseware.png') 50% 35%
    no-repeat;
  background-size: 90.48rem;

  display: flex;
  align-items: center;
  justify-content: center;
}
.teaching_mode_cw_list_search {
  width: 100%;
  height: 100%;
  margin: auto;
  background: url('../../../../public/image/teaching__courseware_search.png')
    50% 35% no-repeat;
  background-size: 90.48rem;

  display: flex;
  align-items: center;
  justify-content: center;
}
.teaching_mode_cw_list_search_nodata {
  width: 100%;
  height: 100%;
  margin: auto;
  background: url('../../../../public/image/teaching_mode_search_nodata.png')
    50% 35% no-repeat;
  background-size: 90.48rem;

  display: flex;
  align-items: center;
  justify-content: center;
}
.teaching_mode_cw_list .nodata_text {
  font-size: 12.48rem;
  padding-top: 70rem;
  color: #ffffff;
}
</style>
