import store from '../store';
import router from '../router';
import fun from './KPI';
import * as utils from '../utils/index.js';
import { loadMoudlePowerData } from '../utils/fetch';
import { GetSaaSClientMsg } from '@/API/workbench.js';
import bus from '../js/bus';
import websocket from './websocket.js';
export function doAfterLoginSuccess (token, callback) {
  console.log(token, 'doAfterLoginSuccess');
  store.commit('resetStore');
  localStorage.setItem('apiList', JSON.stringify(token.ApiList));
  delete token.ApiList;
  store.commit('selectNewAPIURL');
  fun.loginInfo.SetToken(token);
  fun.loginInfo.setCookie('token', JSON.stringify(token));
  token.SaaSClientKeyValue = token.SaaSClientKeyValue.replace(/\s/g, '');
  store.commit('SetToken', token);
  let obj = {};
  if (window.brandCustomSettingStr && window.brandCustomSettingStr != '') {
    obj = JSON.parse(window.brandCustomSettingStr);
    document.title = obj.MainDemoName;
  } else {
    document.title = '治学';
  }
  store.commit('setHeaderBarListDefault', null);
  sessionStorage.removeItem('headerBarList');
  GetSaaSClientMsg().then(
    result => {
      store.commit('setSaaSClientInfo', result.data);
      getMoudlePowerList();
      getHQMoudlePowerList();
      websocket.init();
      if (callback) {
        // callback();
        console.log(callback, 'callback');
      } else {
        router.push({
          path: utils.getIndexPath()
        });
      }
    },
    error => {
      console.log('loadSaaSClientMsg', error);
    }
  );
}

export function doReLogin (token) {
  console.log(token, 'doReLogin');
  bus.emit('reLoadPage', (callbackGoIndex) => {
    store.commit('resetStore');
    localStorage.setItem('apiList', JSON.stringify(token.ApiList));
    delete token.ApiList;
    store.commit('selectNewAPIURL');
    fun.loginInfo.SetToken(token);
    fun.loginInfo.setCookie('token', JSON.stringify(token));
    token.SaaSClientKeyValue = token.SaaSClientKeyValue.replace(/\s/g, '');
    store.commit('SetToken', token);
    let obj = {};
    if (window.brandCustomSettingStr && window.brandCustomSettingStr != '') {
      obj = JSON.parse(window.brandCustomSettingStr);
      document.title = obj.MainDemoName;
    } else {
      document.title = '治学';
    }
    store.commit('setHeaderBarListDefault', null);
    sessionStorage.removeItem('headerBarList');
    GetSaaSClientMsg().then(
      result => {
        store.commit('setSaaSClientInfo', result.data);
        getMoudlePowerList();
        getHQMoudlePowerList();
        callbackGoIndex();
      },
      error => {
        console.log('loadSaaSClientMsg', error);
      }
    );
  });
}

export function outLogin () {
  fun.loginInfo.delCookie('token');
  fun.loginInfo.SetToken(null);
  store.commit('SetToken', null);
  store.commit('setHeaderBarListDefault', null);
  sessionStorage.removeItem('headerBarList');
  localStorage.removeItem('apiList');
  localStorage.removeItem('newAPIURL');
  store.commit('selectNewAPIURL');
  router.push('/login');
}

export function clearUserInfo () {
  fun.loginInfo.delCookie('token');
  fun.loginInfo.SetToken(null);
  store.commit('SetToken', null);
  store.commit('setHeaderBarListDefault', null);
  sessionStorage.removeItem('headerBarList');
}
function getMoudlePowerList () {
  loadMoudlePowerData('', (result) => {
    console.log('loadMoudlePowerDataresult', result);
    window.ModulePowerSetting = result.data;
    localStorage.setItem('ModulePowerSetting', result.data);
    console.log('window.ModulePowerSetting', window.ModulePowerSetting);
  });
}
function getHQMoudlePowerList () {
  loadMoudlePowerData('HQ', (result) => {
    console.log('HQModulePowerSetting', result);
    window.HQModulePowerSetting = result.data;
    console.log('window.ModulePowerSetting', window.HQModulePowerSetting);
    localStorage.setItem('HQModulePowerSetting', result.data);
  });
}
