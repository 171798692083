<template>
  <div class="course_details_bottom"
       v-if="vShowBottom">
    <div class="course_details_plan_ul">

      <div class="course_details_plan_list"
           @click.stop="toComment"
           v-if="courseInfo.CommentStudentList.length>0"> 
        <div class="list_type type_yellow">
          课评
        </div>
        <div class="list_info">
          <div class="list_text">
            已发布：{{courseInfo.CommentStudentList.length}}人 {{vCommentStudentList}}
          </div>
          <div class="list_text"
               v-if="courseInfo.UnCommentStudentList.length>0">
            未发布：<span class="font_red">{{courseInfo.UnCommentStudentList.length}}</span> 人 {{vUnCommentStudentList}}
          </div>
        </div>
      </div>

      <div class="course_details_plan_list"
           @click.stop="toHomeWork"
           v-if="courseInfo.HomeWorkStudentList.length>0">
        <div class="list_type type_green">
          作业
        </div>
        <div class="list_info">
          <div class="list_text">
            已布置：{{courseInfo.HomeWorkStudentList.length}}人 {{vHomeWorkStudentList}}
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    courseInfo: Object
  },
  computed: {
    vShowBottom () {
      if (this.courseInfo.CommentStudentList.length > 0 || this.courseInfo.HomeWorkStudentList.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    vCommentStudentList () {
      var name = '';
      this.courseInfo.CommentStudentList.forEach((o, index) => {
        if (index == this.courseInfo.CommentStudentList.length - 1) {
          name += o.StudentNameKeyValue;
        } else {
          name += o.StudentNameKeyValue + '、';
        }
      });
      return name;
    },
    vUnCommentStudentList () {
      var name = '';
      this.courseInfo.UnCommentStudentList.forEach((o, index) => {
        if (index == this.courseInfo.UnCommentStudentList.length - 1) {
          name += o.StudentNameKeyValue;
        } else {
          name += o.StudentNameKeyValue + '、';
        }
      });
      return name;
    },
    vHomeWorkStudentList () {
      var name = '';
      this.courseInfo.HomeWorkStudentList.forEach((o, index) => {
        if (index == this.courseInfo.HomeWorkStudentList.length - 1) {
          name += o.StudentNameKeyValue;
        } else {
          name += o.StudentNameKeyValue + '、';
        }
      });
      return name;
    }

  },
  methods: {
    toComment () {
      console.log('toHomeWork', this.courseInfo);
      let moduleInfo = {
        name: '课评详情',
        moduleName: 'commentDetail',
        routerName: this.$route.name,
        data: { dataInfo: {
          courseId: this.courseInfo.ScheduleCourseKey,
          showWorkInfo: true
        } }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
      // this.$zxrouter.navigateTo({
      //   // /pages/home-school-communication/details-page/lesson-details/index?courseId=504723
      //   // url: '/pages/home-school-communication/release-comment/index?isTeacher=0&essayID=0&scheduleCourseID='+this.courseInfo.ScheduleCourseKey+'&StudentKey=0'
      //   url: '/pages/home-school-communication/details-page/lesson-details/index?courseId=' + this.courseInfo.ScheduleCourseKey
      // });
    },
    toHomeWork () {
      console.log('toHomeWork', this.courseInfo);
      let moduleInfo = {
        name: '作业详情',
        moduleName: 'homeworkDetail',
        routerName: this.$route.name,
        data: { dataInfo: {
          courseId: this.courseInfo.ScheduleCourseKey,
          workType: 1

        } }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
      // this.$zxrouter.navigateTo({
      //   // /pages/home-school-communication/details-page/work-details/index?courseId=504723
      //   // url: '/pages/home-school-communication/release-home-task/index?isTeacher=0&essayID=0&scheduleCourseID='+this.courseInfo.ScheduleCourseKey+'&StudentKey=0'
      //   url: '/pages/home-school-communication/details-page/work-details/index?courseId=' + this.courseInfo.ScheduleCourseKey
      // });
    }
  }
};
</script>

<style>
</style>
