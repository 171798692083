<template>
  <div>
    <div style="width: 100%;">
      <div class="apply_course_content">
        <student-lable class="student_lable"
                       ref="studentLable"
                       :studentID="studentItem.OLAPKey"
                       @alterStudent="alterStudent"
                       @chgCourseComfirm="chgCourseComfirmAfter"
                       :showMenu="true"></student-lable>
        <div class="single_shadow overflow_hidden">
          <div class="">
            <div class="swiper-container-course"
                 :class="[historyClass]"
                 :key="radamCount"
                 v-if="lessonList.length>0">
              <new-tick-item :tickItem="selectedTickItem"
                             :waitPlanCount="waitPlanCount"
                             @showTickList="showTickListafter"></new-tick-item>
            </div>
          </div>
          <div v-if="lessonList.length==0"
               class="course_block_nodata">
            <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
          </div>
          <div class="apply_course_ul ul_student_type"
               v-if="lessonList.length!=0">
            <div class="apply_course_ul_box"
                 :class="{show_open_btn:planList.length>vplanList.length &&planList.length>0}">
              <div class="apply_course_ul_title">
                <span>报课详情</span>
                <span class="font_slogan">点报课分解套餐课时到课程，退报课时返还待报课</span>
              </div>
              <div v-if="vplanList.length>0">
                <planItem v-for="planItem in vplanList"
                          :key="planItem.ApplyClassTypeKey"
                          :planItem="planItem"
                          :planList="planList"
                          :tickItem="selectedTickItem"
                          :studentItem="studentItem"
                          :waitPlanCount="waitPlanCount"
                          @courseApplyCourse="toCourseApplyCourse"
                          @applyCourse="showApplyCourse"
                          @refundCourse="showrRefundCourse"></planItem>
              </div>
              <div v-if="vplanList.length==0 &&lessonList.length>0"
                   class="course_block_nodata">
                <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
              </div>
              <div class="apply_course_list_last"
                   @click="chgShowMore"
                   v-if="planList.length>vplanList.length &&planList.length>0">
                <span class="list_last_icon"></span>
                <span>展开更多</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  课单列表 -->
    <custom-dialog title="可用课单"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="showCourseList"
                   :before-close="isShowCourseListHide">
      <tick-list-item :formTitle="'可用课单'"
                      :dataList="lessonList"
                      @chgTickItem="afterselectedTickItem">
      </tick-list-item>
    </custom-dialog>
    <!-- 报课弹窗 -->
    <apply-course-dialog :planItem="selectedPlanItem"
                         :tickItem="selectedTickItem"
                         :waitPlanCount="waitPlanCount"
                         ref="applycourse"
                         @confirm="confirmAfter"></apply-course-dialog>
    <!-- 退报课弹窗 -->
    <refund-course-dialog :planItem="selectedPlanItem"
                          :tickItem="selectedTickItem"
                          ref="refundCourse"
                          @confirm="confirmAfter"></refund-course-dialog>
  </div>
</template>
<script>
import studentLable from '../../common/student-lable';
import tickItem from './tick-item';
import newTickItem from './new-tick-item';
import applyCourseDialog from './apply-course-dialog/apply-course';
import refundCourseDialog from './apply-course-dialog/refund-course';
import tickListItem from './tick-list-item';
import planItem from './plan-item';
import {
  getStudentRelatedMsg,
  getStudentTicketPlan,
  GetTheCourseDetails,
  submitTicketPlan,
  getTheTicketPlanList
} from '../../../API/workbench';
export default {
  data () {
    return {
      visShowPhonePopup: false,
      studentID: 0,
      tableID: 0,
      studentItem: {}, // 选中学生
      lessonList: [], // 所有有效课单.
      showClassLength: 1, // Swiper显示长度
      selectedTickItem: {}, // 选中课单.
      swiper: '',
      planList: [], // 指定课单的排课计划列表
      tickPlanList: [], // 待报课记录
      noApply: [], // 没报课列表
      alreadyApply: [], // 已报课列表
      showMore: false,
      isConfirmed: true, // 防止多次点击.
      isShowComfirm: true, // 显示操作栏
      isCourseApplycourse: true, /// / 防止异步刷新时进入课单列表
      isShowCourseApplycourse: false,
      historyClass: 'apply_course',
      radamCount: parseInt(Math.random() * 1000),
      showCourseList: false,     // 显示课单列表切换.
      selectedPlanItem: {}      // 选中报课课名
    };
  },
  components: {
    studentLable,
    tickItem,
    planItem,
    newTickItem,
    tickListItem,
    applyCourseDialog,
    refundCourseDialog
  },
  props: {
    studentApplyCourseParams: {
      type: Object,
      default: () => {
        return {
          studentID: 0,
          taoCanID: 0
        };
      }
    }
  },
  created () {
    if (this.studentApplyCourseParams && this.studentApplyCourseParams.studentID > 0) {
      console.log(this.studentApplyCourseParams, 'this.studentApplyCourseParams');
      this.initGetData(this.studentApplyCourseParams.studentID, this.studentApplyCourseParams.taoCanID);
    }
  },
  mounted () {
    this.historyClass += (Math.random() * 1000).toFixed(0);
  },
  watch: {},
  computed: {
    showTotalApplyCount () {
      if (this.vplanList.length == 1 && this.vplanList[0].readOnly) {
        return false;
      } else {
        return true;
      }
    },
    vlessonList () {
      var arr = [];
      this.lessonList.forEach(o => {
        if (o.TableID != this.selectedTickItem.TableID) {
          arr.push(o);
        }
      });
      return arr;
    },
    vplanList () {
      let findItem = this.planList.find(o => {
        return o.TableID > 0 && o.IsSuitable != 0;
      });
      if (!this.showMore) {
        if (!findItem) {
          return this.alreadyApply.concat(this.noApply);
        } else {
          return this.alreadyApply;
        }
      } else {
        if (this.alreadyApply.length > 0) {
          this.alreadyApply[this.alreadyApply.length - 1].isLast = true;
        }
        return this.alreadyApply.concat(this.noApply);
      }
    },
    vtickPlanListTotol () {
      let num = 0;
      this.tickPlanList.forEach(o => {
        num += Number(o.PlanCount);
      });
      return num;
    },
    vTotalApplyCount () {       // 总报课时.
      let count = 0;
      this.planList.forEach(o => {
        count += Number(o.activeCount);
      });
      return this.$utils.saveHowMuchDecimal(count, 1);
    },
    // 超报课数量
    vSum_SuperQuote () {
      let applyCount = 0;
      this.planList.forEach(o => {       // 获取所有报课课程报课数量总值.
        if (o.Apply < 0) {
          applyCount += Math.abs(o.Apply) + Number(o.activeCount);
        } else {
          applyCount += Number(o.activeCount);
        }
      });
      if (applyCount - Number(this.selectedTickItem.NumCount) > 0) {
        return applyCount - Number(this.selectedTickItem.NumCount);  // CurrentCount
      } else {
        return 0;
      }
    },
    waitPlanCount () {        // 正确的待报课数量
      if (this.selectedTickItem) {
        let num = 0;
        this.planList.forEach(o => {
          num += Number(o.activeCount);
        });
        let waitPlanCount = this.$utils.saveDecimal(Number(this.selectedTickItem.CurrentCount) - num);
        return waitPlanCount < Number(this.selectedTickItem.CurrentCount) ? waitPlanCount : Number(this.selectedTickItem.CurrentCount);
      }
      // return Number(this.selectedTickItem.WaitForPlanCount) || 0;
    },
    showNowApplyCount () {        // 判断课当为False时 是否显示当前报课,
      let typeKey = false;
      this.vplanList.forEach(o => {
        if (o.readOnly == false) {
          typeKey = false;
          return false;
        } else {
          typeKey = true;
        }
      });
      return typeKey;
    }
  },
  methods: {
    isShowCourseListHide () {
      this.showCourseList = false;
    },
    afterselectedTickItem (item) {
      this.isShowCourseListHide();
      this.selectedTickItem = item;
      this.GetTheCourseDetailsByPlanID(this.selectedTickItem.TableID);
    },
    showTickListafter () {
      console.log('123');
      this.showCourseList = true;
    },
    doSwitch () {
      this.$refs.studentLable.switchBtn();
    },
    isShowPhonePopupHide () {
      this.visShowPhonePopup = false;
    },
    // 外部传值初次加载.
    initGetData (studentID, tableID) {
      if (tableID) {
        this.tableID = tableID;
      }
      this.stuKeyInit(studentID, () => { });
    },
    // 初始化swiper.
    swiperInit (callback) {
      let that = this;
      // if (this.swiper && this.swiper.destroyed) {
      //   this.swiper.destroy(false);
      // }
      this.swiper = new Swiper('.' + that.historyClass, {
        loop: that.lessonList.length > 1, // 循环模式选项
        // loopAdditionalSlides : 4,
        effect: 'coverflow',
        slidesPerView: 'auto',
        centeredSlides: true,
        coverflowEffect: {
          rotate: 0,
          stretch: -30,
          depth: 100,
          modifier: 1,
          slideShadows: false
        },
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swipe
        on: {
          transitionEnd: function (callback) {
            that.swiper = this;
            that.showMore = false; // 将显示更多
            if (that.lessonList.length > 0) {
              if (callback) {
                callback();
              } else {
                if (that.lessonList.length > 0) {
                  this.planList = [];
                  that.selectedTickItem = that.lessonList[this.realIndex];
                  that.GetTheCourseDetailsByPlanID(
                    that.selectedTickItem.TableID
                  );
                }
              }
            }
          }
        }
      });
      window.swiperA = this.swiper;
    },
    alterStudent (item) {
      this.studentItem = item;
      this.isShowComfirm = true;
      this.stuKeyInit(this.studentItem.OLAPKey);
    },
    chgCourseComfirmAfter (type) {
      this.isShowComfirm = type;
    },
    // 请求所有关于学生ID的接口.
    stuKeyInit (StuKey, callback) {
      // this.getStudentInfo(StuKey);
      this.studentItem.OLAPKey = StuKey;
      this.getTicketPlanForStuKey(StuKey, () => {
      }, () => {
          // 初始化时判断是否有tableID存在.
        this.$nextTick(() => {
          this.tableIDInit();
        });
          // this.tableIDInit()
      }
      );
    },
    tableIDInit () {
      let locaIndex;
      if (this.tableID > 0) {
        locaIndex = this.lessonList.findIndex(o => {
          return o.TaoCanKey == this.tableID || o.TableID == this.tableID;
        });
      } else {
        locaIndex = this.lessonList.findIndex(o => {
          return Number(o.WaitForPlanCount) > 0;
        });
      }
      if (locaIndex >= 0) {
        // this.swiper.slideTo(locaIndex);
        this.selectedTickItem = this.lessonList[locaIndex];
        this.GetTheCourseDetailsByPlanID(this.selectedTickItem.TableID);
      } else {
        this.selectedTickItem = this.lessonList[0];
        this.GetTheCourseDetailsByPlanID(this.lessonList[0].TableID);
      }
    },
    // 其他地方对接进来,
    // 获取学生个人信息
    getStudentInfo (StuKey) {
      getStudentRelatedMsg(StuKey)
        .then(result => {
          this.studentItem = result.data;
        }, err => {
          layer.alert(err.msg);
        });
    },
    // 学生ID获取所有有效课单
    getTicketPlanForStuKey (StuKey, callback, callback2) {
      getStudentTicketPlan(StuKey).then(result => {
        this.lessonList = this.$utils.parseJson(result.data);
        console.log(result.data, '学生ID获取所有有效课单');
        // 进去课单列表
        if (this.lessonList.length > 0 &&
          this.studentApplyCourseParams &&
          this.studentApplyCourseParams.studentID > 0 &&
          !this.studentApplyCourseParams.taoCanID &&
          this.isCourseApplycourse) {
          this.showTickListafter();
          this.isCourseApplycourse = false;
        }
        // this.planList = [];
        this.radamCount = parseInt(Math.random() * 1000);
        if (this.lessonList.length > 0) {
          // this.selectedTickItem = this.lessonList[0];
          if (callback) {
            callback();
          }
          if (callback2) {
            callback2();
          }
          // this.swiperInit()
          // this.GetTheCourseDetailsByPlanID(this.selectedTickItem.TableID)
        } else {
          this.planList = [];
          this.alreadyApply = [];
          this.noApply = [];
          callback();
        }
      })
        .catch(error => {
          console.log(error, 'error');
        });
    },
    getTicketPlan (planID) {
      getTheTicketPlanList(planID).then(result => {
        this.tickPlanList = result.data;
        console.log(result, 'getTicketPlangetTicketPlan');
      });
    },
    // 课单ID获取所有排课计划
    GetTheCourseDetailsByPlanID (planID) {
      this.getTicketPlan(planID);
      GetTheCourseDetails(planID).then(result => {
        if (this.selectedTickItem.TableID != planID) {
          // 获取数据获取延迟，导致绑定数据错位。
          // 当前获取内容与选中课单不一致，再放弃这次获取到的数据。
          return;
        }
        this.planList = [];
        this.alreadyApply = [];
        this.noApply = [];
        result.data.forEach(o => {
          o.PlanCount = this.$utils.saveDecimal(Number(o.PlanCount));
          o.Apply = this.$utils.saveDecimal(Number(o.Apply));
          o.activeCount = o.Apply;
          if (o.Apply < 0) {      // 加入当前报课为负数时有可能存在超报
            // o.PlanCount += Math.abs(o.Apply);
            o.activeCount = 0;
          }
          o.SpendCount = this.$utils.saveDecimal(Number(o.SpendCount));
          o.Sum_PlanCount = this.$utils.saveDecimal(Number(o.Sum_PlanCount));
          o.Sum_Apply = this.$utils.saveDecimal(Number(o.Sum_Apply));
          // Sum_Apply-Apply = 未显示的报课数量.
          o.Min = this.$utils.saveDecimal(Number(o.Min));
          o.Max = this.$utils.saveDecimal(Number(o.Max));

          o.adjustCount = 0; // 将要调整的的数.
          if (o.ApplyClassTypeKey == this.selectedTickItem.ApplyCorIDsName) {
            o.readOnly = true;
          } else {
            o.readOnly = false;
          }
          o.isLast = true;
          this.planList.push(o);
          if (o.TableID > 0 && o.IsSuitable != 0) {
            this.alreadyApply.push(o);
          } else {
            this.noApply.push(o);
          }
        });
        if (this.alreadyApply.length > 0) {
          this.alreadyApply[this.alreadyApply.length - 1].isLast = false;
        }
        // this.planList.sort((x, y) => {
        //   // TableID
        //   return Number(y.TableID) - Number(x.TableID);
        // });
      }).catch(error => {
        console.log(error, 'error');
      });
    },
    // 取消
    cancel () {
      this.isShowPhonePopupHide();
    },
    // 确定.
    confirm (content, callback) {
      content = content || '';
      if (this.isConfirmed) {
        this.isConfirmed = false;
        let dataList = [];
        let allAdjustCount = 0;
        let allApplyCount = 0;
        let isChange = 0;     // 防止一加一减刚好为0的情况, 所以特地新增的一个判断
        let dataObj = {
          PlanCount: 0, // float, --计划数量
          ApplyClassKey: 0, // int, --课名ID
          ApplyClassKeyValue: '' // string, --课名
        };

        this.vplanList.forEach(o => {
          // 计算减去所有课单排课数后的总共排课数
          if (o.PlanCount || o.adjustCount - o.Apply != 0) {   // o.PlanCount || o.adjustCount - o.Sum_Apply != 0
            allAdjustCount += o.adjustCount;
            if (o.adjustCount != 0) {
              isChange = o.adjustCount;
            }
            allApplyCount += (o.Apply < 0 ? 0 : o.Apply) + o.SpendCount + o.adjustCount;
            let dataObj = {
              PlanCount: (o.Apply < 0 ? 0 : o.Apply) + o.SpendCount + o.adjustCount, // float, --计划数量       //这里计算出问题.
              ApplyClassKey: o.ApplyClassTypeKey, // int, --课名ID
              ApplyClassKeyValue: o.ApplyClassTypeKeyValue // string, --课名
            };
            // dataObj.PlanCount = o.activeCount
            // dataObj.ApplyClassKey = o.ApplyClassTypeKey
            // dataObj.ApplyClassKeyValue = o.ApplyClassTypeKeyValue
            // console.log(o,dataObj,'dataObj22')
            dataList.push(dataObj);
          }
        });
        // allAdjustCount  为所有课名在此课单中的总报课数量.
        if (isChange == 0) {
          layer.alert('没有变更报课，不需要提交。');
          this.isConfirmed = true;
          // if (callback) {      // 防止多次点击的通用按钮里面的方法
          //   this.isConfirmed = true;
          //   callback();
          // }
        } else {
          console.log(dataList, 'dataList', this.selectedTickItem);
          let sumPlan = this.vtickPlanListTotol - allApplyCount;
          let dataObj = {
            // 计算减去所有排课计划的排课数后 剩余的数量
            // PlanCount: Number(this.selectedTickItem.WaitForPlanCount) - allAdjustCount, // float, --计划数量
            PlanCount: sumPlan < 0 ? 0 : sumPlan,         // 这里计算出问题.
            ApplyClassKey: 0, // int, --课名ID
            ApplyClassKeyValue: '复合' // string, --课名
          };
          dataList.push(dataObj);
          console.log(dataList, 'dataListdataList');
          submitTicketPlan(this.selectedTickItem.TableID, dataList)
            .then(result => {
              if (result.code == 0) {
                this.isConfirmed = true;
                layer.alert(content);
                this.confirmAfterUpdate();
                this.$emit('studentApplyCourseSuccess', result.data);
                this.$bus.emit('AfterStudentApplyCourse', result.data, this.studentApplyCourseParams.studentID);
              } else {
                layer.alert(result.msg);
              }
            })
            .finally(() => {
              this.isConfirmed = true;
              if (callback) {
                callback();
              }
            }).catch(error => {
              this.confirmAfterUpdate();
              layer.alert(error.msg);
            });
        }
      }
      // console.log(this.swiper.activeIndex)
      // dataList = JSON.stringify(dataList)
    },
    confirmAfterUpdate () {
      // let realIndex = this.$utils.parseJson(this.swiper.realIndex);
      this.getTicketPlanForStuKey(
        this.studentItem.OLAPKey,
        () => {
          // this.$nextTick(() => {
          //   this.swiperInit();
          // });
        },
        () => {
          this.$nextTick(() => {
            // this.swiper.slideTo(realIndex);
            // this.selectedTickItem = this.lessonList[realIndex];
            let sameItem = this.lessonList.find(o => {
              return o.TableID == this.selectedTickItem.TableID;
            });
            if (sameItem) {
              this.selectedTickItem = this.$utils.parseJson(sameItem);
            }
            this.GetTheCourseDetailsByPlanID(this.selectedTickItem.TableID);
            // console.log('indexslideTo', realIndex, this.swiper.realIndex);
          });
        }
      );
    },
    chgShowMore () {
      this.showMore = true;
    },
    toCourseApplyCourse (id) {
      console.log(id, '课程报课');
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'courseApplycourse',
        data: { studentKey: this.studentItem.OLAPKey, applyClassID: id }
      });
    },
    chgIsShowCourseApplycourse () {
      console.log('触发closeDialog');
      this.isShowCourseApplycourse = false;
    },
    // 报课弹窗
    showApplyCourse (item) {
      this.selectedPlanItem = item;
      this.$refs.applycourse.chgisShowMain();
    },
    // 退报课弹窗
    showrRefundCourse (item) {
      this.selectedPlanItem = item;
      this.$refs.refundCourse.chgisShowMain();
    },
    confirmAfter (item, content) {
      let locaIndex = this.planList.findIndex(o => {
        return o.ApplyClassTypeKey == item.ApplyClassTypeKey;
      });
      if (locaIndex >= 0) {
        this.planList[locaIndex] = this.$utils.parseJson(item);
      }
      this.confirm(content);
    }
  }
};
</script>
