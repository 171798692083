<template>
  <!-- 通用列表 -->
  <div class="table_list_content">
    <div class="single_result_detail">
      <!-- 筛选条件 -->
      <div class="screening_condition clearfix"
           v-if="isShowSearch">
        <div>
          <div class="screening_condition_number fl"
               v-if="isLoading">
            <img src="../../../public/image/screening_condition_number_icon02.gif"
                 alt> 正在获取数据 , 请稍等...
          </div>
          <div class="screening_condition_number fl"
               v-if="!isLoading">
            <img src="../../../public/image/screening_condition_number_icon01.png"
                 alt> 符合条件 : 共
            <span>{{totalNum}}</span> 条。
          </div>
        </div>
        <!-- 已选条件 -->
        <el-button class="btn_light_white btn_hover_white fr"
                   @click="clearExtendSearchParams">清除条件</el-button>
      </div>
      <!-- 
          选中行高亮  highlight-current-row 
          隔行换色  stripe
          数据为空展示的文字  empty-text
          选中行 复选框联动  toggleRowSelection
        -->
      <div>
        <el-table element-loading-text="拼命加载中"
                  class="table_list"
                  ref="multipleTable"
                  :data="vtableData"
                  highlight-current-row
                  stripe
                  :border="false"
                  empty-text
                  toggleRowSelection
                  @sort-change="sortChange"
                  :default-sort="defaultSort"
                  @row-click="clickRow"
                  :row-key="rowKey||'none'"
                  :expand-row-keys="expandRowKeys"
                  style="width: 100%">
          <!-- 表头 -->
          <el-table-column type="selection"
                           fixed
                           width="55"
                           v-if="vIsSearchText"></el-table-column>
          <!-- fixed:true, left, right -->
          <el-table-column v-for=" (item,ind) in vTableColumns"
                           :fixed="item.fixed||false"
                           :width="item.width"
                           :sortable="item.sortable"
                           :align="item.align"
                           :key="ind"
                           :class-name="item.className"
                           :min-width="item.minWidth"
                           :label="item.label"
                           :render-header="item.renderHeader"
                           :prop="item.prop"
                           :show-overflow-tooltip="true&&(!item.type||item.type!='popUp')&&item.type!='student'"
                           :label-class-name="item.columnClass"
                           :sort-orders="['ascending', 'descending']">
            <!-- 复合标题 -->
            <el-table-column v-for="childItem in item.childColumn"
                             :key="childItem.prop"
                             :fixed="childItem.fixed"
                             :width="childItem.width"
                             :sortable="childItem.sortable"
                             :class-name="childItem.className"
                             :min-width="childItem.minWidth"
                             :align="childItem.align"
                             :label="childItem.label"
                             :prop="childItem.prop"
                             :show-overflow-tooltip="true"
                             :label-class-name="item.columnClass"
                             :sort-orders="['ascending', 'descending']">
              <template slot-scope="scope">
                <span v-if="childItem.type=='number'">{{rounding(scope.row,childItem.prop,childItem.decimalPlace)}}</span>
                <span v-else-if="childItem.type=='date'">{{convertDateformat(scope.row,childItem.prop,childItem.dateType)}}</span>
                <!--当前报课+ 待排课时 -->
                <span v-else-if="childItem.type=='blueNumber'"
                      :class="Number(scope.row[childItem.prop])>0?'font_border_blue':''"
                      @click="tableBtnClick(scope.row,childItem,scope.row[childItem.prop])">
                  <span :class="Number(scope.row[childItem.prop])>0?'font_blue':''">{{rounding(scope.row,childItem.prop,childItem.decimalPlace)}}</span>
                </span>

                <el-button v-else-if="childItem.type=='tableBtn'"
                           v-for="(info,indexs) in childItem.tableBtnGroup"
                           :class="[info.className(scope.row[info.Stutas]),vHasModulePower(info.ModulePowerKey)?'':'norole']"
                           class="tableBtn"
                           :key="indexs"
                           size="mini"
                           @click="tableBtnClick(scope.row,info)">{{info.eventName == 9?ifBtnName(scope.row):info.name}}</el-button>
                <span v-else-if="childItem.type=='popUp'">
                  <el-popover v-if="scope.row[childItem.prop+'_Pop_Tips']"
                              trigger="hover"
                              placement="top">
                    <p v-html="scope.row[childItem.prop+'_Pop_Tips']"></p>
                    <div slot="reference"
                         class="name-wrapper-NO">
                      <div size="">{{scope.row[childItem.prop]}}</div>
                    </div>
                  </el-popover>
                </span>
                <span v-else>{{scope.row[childItem.prop]}}</span>
                <span class="new_bottom_warn"
                      :class="childItem.className"
                      v-if="childItem.isBottomWarn&&scope.row[childItem.bottomWarnProp]">
                  <!-- {{scope.row[childItem.bottomWarnProp]}} -->
                  <el-popover v-if="scope.row[childItem.prop+'_Pop_Tips']"
                              trigger="hover"
                              placement="top">
                    <p v-html="scope.row[childItem.prop+'_Pop_Tips']"></p>
                    <div slot="reference">
                      <div size="">{{scope.row[childItem.bottomWarnProp]}}</div>
                    </div>
                  </el-popover>
                  <span v-else>{{scope.row[childItem.bottomWarnProp]}}</span>
                </span>
              </template>
            </el-table-column>
            <template slot-scope="scope"
                      v-if="!item.childColumn">
              <span v-if="item.type=='serial'">{{setSerialNumber(scope.row[item.prop])}}</span>
              <span v-else-if="item.type=='number'">{{rounding(scope.row,item.prop,item.decimalPlace)}}</span>
              <span v-else-if="item.type=='date'">{{convertDateformat(scope.row,item.prop,item.dateType)}}</span>
              <span v-else-if="item.type=='text'">{{scope.row[item.prop]}}</span>
              <span v-else-if="item.type=='blueText'"
                    class="font_blue"
                    @click="tableBtnClick(scope.row,item,scope.row.Total_ToBePlaned)">{{scope.row[item.prop]}}</span>

              <!-- 待排课时 -->
              <span v-else-if="item.type=='blueNumber'"
                    :class="Number(scope.row[item.prop])>0?'font_border_blue':''"
                    @click="tableBtnClick(scope.row,item,scope.row.Sum_ToBeArranged)">
                <span :class="Number(scope.row[item.prop])>0?'font_blue':''">{{rounding(scope.row,item.prop,item.decimalPlace)}}</span>
              </span>
              <!-- 待报课时 -->
              <span v-else-if="item.type=='redNumber'"
                    :style="Number(scope.row[item.prop])>0?'color:#FF6C67':''"
                    :class="Number(scope.row[item.prop])>0?'font_border_blue':''"
                    @click="tableBtnClick(scope.row,item,scope.row.Total_ToBePlaned)">{{rounding(scope.row,item.prop,item.decimalPlace)}}</span>
              <!-- 学生+手机号 -->
              <span v-else-if="item.type=='student' &&scope.row.StudentKey">
                <div slot="reference"
                     style="cursor: pointer;"
                     @click="tableBtnClick(scope.row,item)">
                  <div :title="popStudentTitle(scope.row.studentInfo[0])"
                       class="student_view_title_next">{{scope.row.studentInfo[0]}}</div>
                  <div style="font-size: 12px"
                       class="font_gray"
                       :class="scope.row.studentInfo[1]=='未进班'?'Not_in_classbottom_warn':''">{{ scope.row.studentInfo[1]}}</div>
                </div>
              </span>
              <el-button v-else-if="item.type=='tableBtn'"
                         v-for="(info,indexs) in item.tableBtnGroup"
                         :class="[info.className(scope.row[info.Stutas]),vHasModulePower(info.ModulePowerKey)?'':'norole']"
                         :style="!scope.row.StudentKey?'opacity: 0;cursor: default':''"
                         class="tableBtn"
                         :key="indexs"
                         size="mini"
                         @click="tableBtnClick(scope.row,info)">{{info.eventName == 9?ifBtnName(scope.row):info.name}}</el-button>

              <span v-else-if="item.type=='popDialog' &&scope.row.StudentKey"
                    :class="item.className"
                    @click="editContentBtnClick(scope.row)">{{scope.row[item.prop]}}</span>
              <span v-else-if="item.type=='lineFeed'"
                    :class="item.className"
                    v-html="scope.row[item.prop]"></span>
              <!-- 多课程  trigger="hover-->
              <span v-else-if="item.type=='popUp' &&scope.row.StudentKey">
                <el-popover v-if="scope.row.sourceData.length>2"
                            placement="top">
                  <p v-for="(O,index) in scope.row.sourceData"
                     :key="index">{{ O }}</p>
                  <div slot="reference"
                       class="name-wrapper">
                    <div size="">{{ scope.row.sourceData[0] }}</div>
                    <div class="list_down">{{ scope.row.sourceData[1] }}</div>
                  </div>
                </el-popover>
                <div v-else
                     slot="reference"
                     class="name-wrapper">
                  <div :title="popTitle(scope.row.sourceData[0])">{{ scope.row.sourceData[0]}}</div>
                  <div :title="popTitle(scope.row.sourceData[1])">{{ scope.row.sourceData[1]}}</div>
                </div>
              </span>
              <span v-else-if="scope.row.StudentKey"
                    :class="item.className">{{scope.row[item.prop]}}</span>

              <span class="new_bottom_warn"
                    :class="item.className"
                    v-if="item.isBottomWarn&&scope.row[item.bottomWarnProp]">
                <el-popover v-if="scope.row[item.prop+'_Pop_Tips']"
                            trigger="hover"
                            placement="top">
                  <p v-html="scope.row[item.prop+'_Pop_Tips']"></p>
                  <div slot="reference"
                       class="name-wrapper-NO">
                    <div size="">{{scope.row[item.bottomWarnProp]}}</div>
                  </div>
                </el-popover>
                <span v-else>{{scope.row[item.bottomWarnProp]}}</span>
              </span>
            </template>
          </el-table-column>
          <!-- 收起部分内容 -->
          <el-table-column fixed="center"
                           type="expand">
            <template slot-scope="props">
              <div v-if="studentDetailList.length!=0">
                <el-form label-position="left"
                         inline
                         class="demo-table-expand">
                  <el-form-item :label="item.label"
                                v-for="(item,index) in detailColumns"
                                :key="index">
                    <span :title="props.row[item.prop]">{{ props.row[item.prop] }}</span>
                  </el-form-item>
                </el-form>
                <div class="list_drop_box">
                  <div class="list_drop_banner">
                    <div class="list_drop_data clearfix">
                      <div class="list_drop_table">
                        <div class="list_drop_thead clearfix">
                          <div class="list_drop_th tl no_border"
                               style="width: 206px;">
                            在读课程
                          </div>
                          <div class="list_drop_th"
                               style="width: 100px;">
                            上课消耗
                          </div>
                          <div class="list_drop_th"
                               style="width:  100px;">
                            当前排课
                          </div>
                          <div class="list_drop_th"
                               style="width:  100px;">
                            待排课时
                          </div>
                          <div class="list_drop_th"
                               style="width: 100px;">
                            待报课时
                          </div>
                          <div class="list_drop_th"
                               style="width: 169px;">
                            操作
                          </div>
                        </div>
                        <div class="list_drop_tbody">
                          <div class="list_drop_tr clearfix"
                               v-for="(obje,index) in studentDetailList"
                               :key="index">
                            <div class="list_drop_td tl"
                                 :title="obje.ApplyClassKeyValue"
                                 style="width: 206px;">
                              <span>{{obje.ApplyClassKeyValue}}</span>
                            </div>
                            <!-- 上课消耗 -->
                            <div class="list_drop_td"
                                 style="width: 100px;">
                              <span>{{$utils.newAmountType(obje.Sum_SpendCount,1)}}</span>
                            </div>
                            <!-- 当前排课 -->
                            <div class="list_drop_td"
                                 style="width: 100px;">
                              <span> {{$utils.newAmountType(obje.Sum_ArrangedCount,1)}}</span>
                            </div>
                            <!-- 待排课时 -->
                            <div class="list_drop_td "
                                 style="width: 100px;"
                                 @click="arrangeClick(obje,'Sum_ToBeArranged')">
                              <span :style="Number(obje.Sum_ToBeArranged)>0?'border-bottom: 1px solid #3498db;cursor: pointer':''"
                                    :class="Number(obje.Sum_ToBeArranged)>0?'font_blue':''">{{$utils.newAmountType(obje.Sum_ToBeArranged,1)}}</span>
                            </div>
                            <!-- 待报课时 -->
                            <div class="list_drop_td "
                                 style="width: 100px;"
                                 @click="applyCourseClick(obje,'Total_ToBePlaned')">
                              <span :style="Number(obje.Total_ToBePlaned)>0?'border-bottom: 1px solid #3498db;cursor: pointer':''"
                                    :class="Number(obje.Total_ToBePlaned)>0?'font_red':''">{{$utils.newAmountType(obje.Total_ToBePlaned,1)}}</span>
                            </div>

                            <div class="list_drop_td"
                                 style="width: 169px;">
                              <div class="list_drop_td_btn_box">
                                <function-btn :item="authbtn"
                                              @clickItem="applyCourseClick(obje)"
                                              :className="''">

                                  <div class="list_drop_td_btn"
                                       :class="vHasModulePower(51)?'':'bg_gray'">报课</div>
                                </function-btn>
                                <function-btn :item="authbtn"
                                              @clickItem="arrangeClick(obje)"
                                              :className="''">

                                  <div class="list_drop_td_btn"
                                       :class="vHasModulePower(51)?'':'bg_gray'">排课</div>
                                </function-btn>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list_drop_box_icon">
                  </div>
                </div>
              </div>
              <div v-if="studentDetailList.length==0"
                   class="list_drop_noData list_drop_box">
                <div class="">亲，当前没有任何数据哦~</div>
              </div>
            </template>
          </el-table-column>

          <div slot="empty"
               style="min-height:500px;line-height:500px;">
            <i class="el-icon-warning-outline">
              <div v-if="isHint"
                   class="monitoring_content_nodata"></div>
              亲，当前没有任何数据哦~
            </i>
          </div>
        </el-table>
        <!-- 悬浮滚动条 -->
        <div class="float-scroll"
             v-if="isNeedInnerScroll&&isShowInnerScroll"
             @scroll="scrollEvent">
          <div class="scroll_content"></div>
        </div>
        <!-- 分页 -->
        <div class="table_pagination table_pagination_right">
          <div class="table_left_text"
               v-show="showPagination">
            共{{totalNum}}条数据
          </div>
          <el-pagination @current-change="handleCurrentChange"
                         :page-size="queryParams.pageSize"
                         :pager-count="5"
                         :current-page.sync="vPageIndex"
                         background
                         layout="prev, pager, next, jumper"
                         :total="totalNum>0?totalNum:queryParams.pageSize"></el-pagination>
          <table-pagination-number :pageSize="queryParams.pageSize"
                                   :queryParams="queryParams"
                                   @loadTableData="loadTableData"></table-pagination-number>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tablePaginationNumber from '../common/table-pagination-number';
export default {
  data () {
    return {
      selectedRow: {}, // 选中行
      expandRowKeys: [], // 展开的行,
      authbtn: {
        name: '',
        ModulePowerKey: 51
      },
      isShowInnerScroll: true,
      isLoading: true,
      isShowSummary: false
    };
  },
  props: {
    tableData: Array,
    // {// 列表数据
    //   type: Array,
    //   default: []
    // },
    detailColumns: Array, // 显示详情内容
    studentDetailList: Array, // 学生的报课详情
    rowKey: String, // 显示详情对应绑定的ID
    totalNum: Number, // 列表总共条数
    tableColumns: Array, // table column
    summary: {
      type: Object,
      default: function () {
        return {
          isShowSummary: false,
          needSummariesColumns: [],
          summaryName: '合计',
          summaryData: []
        };
      }
    },
    isNeedInnerScroll: {
      type: Boolean,
      default: false
    },
    isShowSearch: {
      type: Boolean,
      default: false
    },
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: '', order: 'ascending' };
      }
    },
    queryParams: {
      type: Object,
      default: () => {
        return {
          pageIndex: 0,
          pageSize: 10,
          orderBy: '',
          sequence: ''
        };
      }
    },
    isHint: {
      type: Boolean,
      default: true
    },
    showPagination: {
      type: Boolean,
      default: false
    }
  },
  components: {
    tablePaginationNumber
  },
  computed: {
    // 不足10行时空行补充
    vtableData () {
      let items = {
        StudentKey: '',
        sourceData: [], // 已有用到的数组需实例化，不然渲染时会报错
        studentInfo: []
      };
      let arr = [];
      console.log(this.tableData, 'tableData');
      if (this.tableData) {
        let oldTableDataLength = this.$utils.parseJson(this.tableData.length);
        if (this.tableData.length < this.queryParams.pageSize && this.tableData.length > 0) {
          arr = this.tableData;
          for (let index = 1; index <= (this.queryParams.pageSize - oldTableDataLength); index++) {
            arr.push(items);
          }
          return arr;
        } else {
          return this.tableData;
        }
      } else {
        return arr;
      }
    },
    vIsSearchText () {
      if (this.isSearchText) {
        return true;
      } else {
        return false;
      }
    },
    vPageIndex: {
      get () {
        return this.queryParams.pageIndex + 1;
      },
      set (n, o) {
      }
    },
    vTableColumns () {
      let newTableColumns = [];
      this.tableColumns.forEach(element => {
        if (element.isShow) {
          newTableColumns.push(element);
        }
      });
      return newTableColumns;
    },
    modulePowerList () {
      return this.$store.getters.RolePowerList;
    }
  },
  watch: {
    tableColumns (n, o) {
      if (this.isNeedInnerScroll) {
        setTimeout(() => {
          this.caculInnerScroll();
        }, 800);
      }
    },
    'summary.summaryData': {
      handler (n, o) {
        if (this.summary.isShowSummary) {
          this.$nextTick(() => {
            this.isShowSummary = true;
          });
        }
      },
      deep: true
    }
  },
  created () {
    this.searchConditionType = this.$initJson.extendSearchConditionType;
    if (this.summary.isShowSummary) {
      this.isShowSummary = true;
    }
  },
  mounted () {
    if (this.isNeedInnerScroll) {
      setTimeout(() => {
        this.caculInnerScroll();
      }, 600);
    }
  },
  methods: {
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      ModulePowerKey = ModulePowerKey || '';
      return this.$utils.CheckModulePower(this.modulePowerList, ModulePowerKey);
    },
    // 跳转到第几页
    handleCurrentChange (currentRow, oldCurrentRow) {
      console.log('handleCurrentChange', currentRow);
      this.selectedRow = {};
      this.$refs.multipleTable.setCurrentRow();
      this.queryParams.pageIndex = currentRow - 1;
      this.$emit('loadTableData');
    },
    // 设置序列号
    setSerialNumber (num) {
      return num > 9 ? num : '0' + num;
    },
    // 保留小数位数
    rounding (row, filedName, decimalPlace) {
      if (row[filedName] === '') {
        return row[filedName];
      } else if (row[filedName] == 0) {
        return '-';
      } else {
        if (!row[filedName]) {
          return '';
        } else {
          return this.$utils.mAmountType(row[filedName], 1);
        }
      }
    },
    // 日期转换
    convertDateformat (row, value, type) {
      if (row[value] && type == 'YYMMDD') {
        return this.$utils.formatDateToLine(Date.new(row[value]));
      } else {
        return row[value];
      }
    },
    // 排序
    sortChange (column, prop) {
      if (column.prop == 'studentInfo' && this.queryParams.orderBy == 'StudentKeyValue' && column.order == 'ascending' && this.queryParams.sequence == 'asc') {
        return;
      }
      if (column.prop) {
        this.queryParams.orderBy = column.prop;
        this.queryParams.sequence = column.order == 'ascending' ? 'asc' : 'desc';
      }
      this.$emit('loadTableData', this.queryParams);
    },
    popTitle (data) {
      if (data && data.length > 10) {
        return data;
      } else {
        return '';
      }
    },
    popStudentTitle (data) {
      if (data && data.length >= 5) {
        return data;
      } else {
        return '';
      }
    },
    editContentBtnClick (rowData) {
      let item = {};
      item.eventName = this.$initJson.baseFunctionBtnName.popDialog;
      this.tableBtnClick(rowData, item);
    },
    clearExtendSearchParams () {
      this.queryParams.pageIndex = 0;
      this.$emit('clearSearchParams');
    },
    applyCourseClick (item, val) {
      if (val && Number(item.Total_ToBePlaned) == 0) {
        return false;
      }
      this.$emit('applyCourseClick', item);
    },
    arrangeClick (item, val) {
      if (val && Number(item.Sum_ToBeArranged) == 0) {
        return false;
      }
      this.$emit('arrangeClick', item);
    },
    // 点击表单按钮
    tableBtnClick (rowData, item, val) {
      if (!rowData.StudentKey || (val && Number(val) == 0)) {
        return false;
      }
      if (this.vHasModulePower(item.ModulePowerKey)) {
        if (item.eventName == this.$initJson.baseFunctionBtnName.detail) {
          this.$emit('tableBtnClick', rowData, item.eventName, item);
          if (rowData[this.rowKey] == this.expandRowKeys[0]) {
            this.expandRowKeys = [];
          } else {
            this.expandRowKeys = [];
            this.expandRowKeys.push(rowData[this.rowKey]);
          }
        } else {
          this.$emit('tableBtnClick', rowData, item.eventName, item);
        }
      } else {
        this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
      }
    },
    // 选中行
    clickRow (row, column, event) {
      this.selectedRow = row;
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row, true);
    },
    getSummaries (param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.summary.summaryName;
          return;
        }
        let findItem = this.summary.needSummariesColumns.find(o => {
          return o.prop == column.property;
        });
        if (findItem) {
          // 非数字类型 一般统计个数
          if (findItem.SumType == 'string') {
            sums[index] = this.summary.summaryData.length + findItem.unit;
          } else {
            const values = this.summary.summaryData.map(item => Number(item[column.property]));
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return Number(this.$utils.mAmountType((prev + curr), 1));
                  // return Number(Number(prev + curr).toFixed(0));
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += findItem.unit;
            }
          }
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
    caculInnerScroll () {
      // table的宽度
      let tableWidth = document.getElementsByClassName('el-table table_list')[0] ? document.getElementsByClassName('el-table table_list')[0].clientWidth : 0;
      // table内容的宽度
      let contentWidth = document.getElementsByClassName('el-table__body')[0] ? document.getElementsByClassName('el-table__body')[0].clientWidth : 0;
      // table 左边固定宽
      let leftFix = document.getElementsByClassName('el-table__fixed')[0] ? document.getElementsByClassName('el-table__fixed')[0].clientWidth : 0;
      // table 右边固定宽
      let rightFix = document.getElementsByClassName('el-table__fixed-right')[0] ? document.getElementsByClassName('el-table__fixed-right')[0].clientWidth : 0;
      // 合计的高度
      let countColumnHeight = document.getElementsByClassName('el-table__fixed-footer-wrapper')[0] ? document.getElementsByClassName('el-table__fixed-footer-wrapper')[0].clientHeight : 0;

      if (tableWidth >= contentWidth) {
        this.isShowInnerScroll = false;
      } else {
        this.isShowInnerScroll = true;
        if (document.getElementsByClassName('el-table__body-wrapper')[0]) {
          document.getElementsByClassName('el-table__body-wrapper')[0].style.overflowX = 'hidden';
        }
        this.$nextTick(() => {
          document.getElementsByClassName('scroll_content')[0].style.width = contentWidth + 'px';

          document.getElementsByClassName('float-scroll')[0].style.width = (tableWidth - leftFix - rightFix) + 'px';
          document.getElementsByClassName('float-scroll')[0].style.marginLeft = leftFix + 'px';
          document.getElementsByClassName('float-scroll')[0].style.marginTop = -countColumnHeight + 'px';
        });
      }
    },
    scrollEvent (e) {
      let ETarget = e.target;
      // table的宽度
      let tableWidth = document.getElementsByClassName('el-table table_list')[0].clientWidth;
      // table内容的宽度
      let contentWidth = document.getElementsByClassName('el-table__body')[0].clientWidth;
      // 左边固定宽
      let leftFix = document.getElementsByClassName('el-table__fixed')[0].clientWidth;
      // 右边固定宽
      let rightFix = document.getElementsByClassName('el-table__fixed-right')[0].clientWidth;
      // 滚动条外框的宽度
      document.getElementsByClassName('float-scroll')[0].style.width = (tableWidth - leftFix - rightFix) + 'px';

      document.getElementsByClassName('el-table__body-wrapper')[0].scrollLeft = ETarget.scrollLeft / (contentWidth / (contentWidth - leftFix - rightFix));
    },
    loadTableData (queryParams) {
      this.$emit('loadTableData', queryParams);
    }
  }
};
</script>
<style>
</style>

