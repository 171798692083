<template>
  <div style="border-radius:8px;background-color:#fff;width:100%">
    <!-- 套餐报课汇总 -->
    <div>
      <div v-show="packageList.length > 0"
           class="apply_class_summarizing_swiper">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <package-item v-for="(item,key) in packageList"
                          :key="key"
                          :packageItem="item"
                          @selectedPackageItem="doAfterSelectedPackageItem"></package-item>
          </div>
          <!-- 如果需要分页器 -->
          <!-- <div class="swiper-pagination swiper-pagination-package"
               v-show="packageList.length>4"></div> -->
        </div>
      </div>
      <div class="apply_class_summarizing_content"
           v-if="packageList.length>0">
        <div class="content_nav clearfix">
          <div class="content_nav_list"
               :class="{opt:tabActiveIndex==tabTitles.whole}"
               @click.stop="tabSwitch(tabTitles.whole)">套餐排课总览</div>
          <div class="content_nav_list"
               :class="{opt:tabActiveIndex==tabTitles.student}"
               @click.stop="tabSwitch(tabTitles.student)">学生排课</div>
          <div class="content_nav_list"
               :class="{opt:tabActiveIndex==tabTitles.course}"
               @click.stop="tabSwitch(tabTitles.course)">课程排课</div>
        </div>
        <!-- 汇总数据 -->
        <div class="content_top"
             v-if="tabActiveIndex!=tabTitles.course">
          <div class="content_top_data_box course_class_summarizing_right ">
            <div class="flex">
              <div class="right_top_box box_right fr">
                <summary-data-item v-for="(item,key,index) in summaryDataForHour"
                                   :sumData="item"
                                   :key="index"
                                   @onCilckEvent="afterCilckSummaryData"></summary-data-item>
              </div>
            </div>
          </div>
          <div class="content_search"
               style="margin-right:20px;padding:25px 0 23px">
            <input-search-contain-btn v-model="searchText"
                                      :placeholder="'请输入姓名、姓名首字母、手机号'"
                                      :clearAll="true"
                                      @onSearchEvent="searchStudent"
                                      @clearSearch="clearSearchCondition"></input-search-contain-btn>
          </div>
        </div>
        <!-- 套餐排课总览 -->
        <div v-if="tabActiveIndex==tabTitles.whole">
          <overview-table ref="overviewTable"
                          :packageInfo="curSelectedPackage"
                          :SpecialFilter="paramsForStudent.SpecialFilter"
                          @doAfterTableBtnClick="doAfterTableBtnClick"></overview-table>

        </div>
        <!-- 按学生查看 -->
        <!-- <div>
          <list ref="studentTable"
                v-if="tabActiveIndex==tabTitles.student"
                class="summarizing_list"
                :tableData="studentList"
                :totalNum="totalNum"
                :PageCount="PageCount"
                :tableColumns="tableColumnsForStudent"
                :isSearchText="false"
                :summary="summaryForStudent"
                :rowKey="'Number'"
                :studentDetailList="studentDetailList"
                :defaultSort="defaultSort"
                :queryParams="paramsForStudent"
                @applyCourseClick="doAfterApplyCourseClick"
                @arrangeClick="doAfterArrangeClick"
                @tableBtnClick="doAfterTableBtnClick"
                @loadTableData="getMessageSearchTicketPlanPageForStudent"></list>
        </div> -->

        <div class="table_list_content"
             v-if="tabActiveIndex==tabTitles.student">
          <div class="single_result_detail">
            <table-list ref="studentTable"
                        class="summarizing_list"
                        :tableData="studentList"
                        :tableColumns="tableColumnsForStudent"
                        :expandRowData="studentDetailList"
                        :rowKey="'Number'"
                        :totalNum="totalNum"
                        :defaultSort="defaultSort"
                        :queryParams="paramsForStudent"
                        @loadTableData="getMessageSearchTicketPlanPageForStudent"></table-list>

          </div>
        </div>
        <!-- 按课程查看 -->
        <div v-if="tabActiveIndex==tabTitles.course">
          <course-name-apply-course ref="courseNameApplyCourse"
                                    :taoCanKey="curSelectedPackage.TableID"
                                    @arrangeOrApplyCourseSuccess="doAfterArrangeOrApplyCourseSuccess"></course-name-apply-course>
        </div>
      </div>
      <div v-else
           style="height:600px;padding-top:150px">
        <div class="course_block_nodata"
             style="background-position:center 70px;background-size:100px">
          <div style="padding-top: 150px;"
               class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
        </div>
      </div>
    </div>

    <!-- 备注 -->
    <textarea-pop :title="'学生：'+curSelectedStudent.StudentKeyValue"
                  :message="curSelectedStudent.ArrangeSupport"
                  :placeholderText="'请输入排课备注...'"
                  ref="textareaPop"
                  @submitStudentArrangeSupport="submitStudentArrangeSupport"></textarea-pop>

    <div>
      <dialog-factory ref="packageApplyCourseReportDialogFactory"
                      :key="'packageApplyCourseReport'"
                      :routerName="'packageApplyCourseReport'"></dialog-factory>

      <dialog-factory-common ref="packageApplyCourseReportDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import {
  MessageSearchTicketPlanPageForStudent,
  EditArrangeSupport,
  SumarizeTaocanTicketPlan,
  SummariseStudentTicketPlanDetial
} from '../../../API/workbench.js';

import list from '../table';
import packageItem from './package-item';
import courseNameApplyCourse from '../course-name-apply-course/index';
import textareaPop from '../../common/textarea-pop';
import dialogFactory from '../../common/dialog-factory';
import studentCoursesArranging from '../../pop-up-menu/student-courses-arranging/index';
import overviewTable from './overview-table';
import summaryDataItem from '../summary-data/item';
import inputSearchContainBtn from '../common/input-search-contain-btn';

import tableList from '../../common/table-list/index';
export default {
  name: 'packageApplyCourseReport',
  data () {
    return {
      swiper: {},
      // 购买套餐的学生( 学生报课统计 )
      paramsForStudent: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: 'StudentKeyValue', // 排序字段
        sequence: 'asc', // 排序字段
        startTime: '', // 最近报名日期开始
        endTime: '', // 最近报名日期结束
        ApplyClassKey: 0, // 课名ID
        TaoCanKey: 0, // 套餐ID
        orderByForOpt: '', // 辅助排序字段
        QueryID: 0, //	整型	可选	0	数据源：GET	学生ID
        SpecialFilter: 0 //	整型	可选	0	数据源：GET	0-不生效;1-按待排>0;2-按待报>0;3-按当前报课<=5;
      },
      packageList: [], // 可用套餐列表
      curSelectedPackage: {}, // 当前选中的套餐

      studentList: [], // 按学生 的 学生列表
      curSelectedStudentKey: 0, // 当前选中学生Key
      curSelectedStudent: {}, // 当前选中学生
      studentDetailList: [], // 学生的报课详情
      tabTitles: {
        // 几个tab选项卡的title集
        whole: 1,
        student: 2,
        course: 3
      },
      tabActiveIndex: 1, // 选中的tab下标

      isLoadding: false,

      PageCount: 0,
      totalNum: 0,
      summaryDataForHour: {
        inSchoolStudent: { title: '在读学生', opt: '在读学生', className: 'font_blue', value: 0, type: 'inSchoolStudent', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: true },
        SumCurrentCount: { title: '当前报课', className: '', value: 0, dataType: 'number', decimalPlace: 1 },
        NumToBeArranged: { title: '待排人数', className: 'font_blue', value: 0, type: 'NumToBeArranged', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false },
        NumPlanCount: { title: '待报人数', className: 'font_blue', value: 0, type: 'NumPlanCount', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false },
        NumNotEnought: { title: '课时不足', className: 'font_blue', value: 0, type: 'NumNotEnought', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false }
      },
      tableColumnsForStudent: [
        {
          label: '学生ID',
          prop: 'StudentKey',
          width: 20,
          sortable: false,
          align: 'left',
          isShow: false,
          type: '',
          decimalPlace: 0
        },
        {
          label: '学生',
          prop: 'StudentKeyValue',
          type: 'student',
          width: 120,
          extend: {
            studentKey: 'StudentKey', // 学生ID
            phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '排课备注',
          prop: 'ArrangeSupport',
          type: 'arrange-support',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            studentKeyValue: 'StudentKeyValue', // 学生名
            click: () => { }
          }
        },
        {
          label: '最近购课',
          prop: 'LastEnlistTime',
          type: 'date-item',
          width: 150
        },
        {
          label: '在读课程',
          prop: 'ApplyClassKeyValue',
          type: 'reading-courses',
          width: 220,
          extend: {
            splitSymbol: ','
          }
        },
        {
          label: '可用课时',
          prop: 'Total_CurrentCount',
          type: 'class-hour',
          sortable: 'custom'
        },
        {
          label: '当前排课',
          prop: 'Sum_ArrangedCount',
          type: 'class-hour',
          sortable: 'custom'
        },
        {
          label: '待排课时',
          prop: 'Sum_ToBeArranged',
          type: 'class-hour',
          sortable: 'custom',
          extend: {
            click: (rowData) => { this.studentArrangCourses(rowData); }
          }
        },
        {
          label: '待报课时',
          prop: 'Total_ToBePlaned',
          type: 'class-hour',
          sortable: 'custom',
          extend: {
            click: (rowData) => { this.studentApplyCourse(rowData); },
            className: 'total_to_beplaned'
          }
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          width: 180,
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                type: 'expand',
                extend: {
                  click: (rowData) => { },
                  loadtableData: (rowData, callBack) => { // 获取详情数据
                    if (rowData.StudentKey) {
                      this.studentKey = rowData.StudentKey;
                    }
                    this.getSummariseStudentTicketPlanDetial(callBack);
                  },
                  tableColumnsDetail: [
                    {
                      label: '在读课程',
                      prop: 'ApplyClassKeyValue',
                      type: 'reading-courses'
                    },
                    {
                      label: '当前报课',
                      prop: 'Sum_CurrentCount',
                      type: 'class-hour'
                    },
                    {
                      label: '当前排课',
                      prop: 'Sum_ArrangedCount',
                      type: 'class-hour'
                    },
                    {
                      label: '待排课时',
                      prop: 'Sum_ToBeArranged',
                      type: 'class-hour',
                      extend: {
                        click: (rowData) => { this.studentArrangCourses(rowData); }
                      }
                    },
                    {
                      label: '待报课时',
                      prop: 'Total_ToBePlaned',
                      type: 'class-hour',
                      extend: {
                        click: (rowData) => { this.studentApplyCourse(rowData); },
                        className: 'total_to_beplaned'
                      }
                    },
                    {
                      label: '操作',
                      prop: 'option',
                      type: 'table-btn',
                      width: 180,
                      extend: {
                        tableBtnGroup: [
                          {
                            name: '报课',
                            extend: {
                              click: (rowData) => { this.studentApplyCourse(rowData); },
                              ModulePowerKey: 51
                            }
                          },
                          {
                            name: '排课',
                            extend: {
                              click: (rowData) => { this.studentArrangCourses(rowData); },
                              ModulePowerKey: 51
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: '报课',
                extend: {
                  click: (rowData) => {
                    this.studentApplyCourse(rowData);
                  },
                  ModulePowerKey: 51
                }
              },
              {
                name: '排课',
                extend: {
                  click: (rowData) => {
                    this.studentArrangCourses(rowData);
                  },
                  ModulePowerKey: 51
                }
              }
            ]
          }
        }
      ],
      summaryForStudent: {
        needSummariesColumns: [
          { prop: 'StudentKeyValue', SumType: 'string', unit: '人' },
          { prop: 'Total_NumCount', SumType: 'number', unit: '' },
          { prop: 'Sum_SpendCount', SumType: 'number', unit: '' },
          { prop: 'Total_CurrentCount', SumType: 'number', unit: '' },
          { prop: 'Sum_CurrentCount', SumType: 'number', unit: '' },
          { prop: 'Total_ToBePlaned ', SumType: 'number', unit: '' },
          { prop: 'Sum_ArrangedCount', SumType: 'number', unit: '' },
          { prop: 'Sum_ToBeArranged', SumType: 'number', unit: '' }
        ],
        isShowSummary: false,
        summaryName: '合计',
        summaryData: []
      },
      curSelectedSummaryData: {},
      searchText: '',
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  components: {
    list,
    packageItem,
    textareaPop,
    studentCoursesArranging,
    courseNameApplyCourse,
    dialogFactory,
    overviewTable,
    summaryDataItem,
    inputSearchContainBtn,
    tableList
  },
  watch: {
    'paramsForStudent.SpecialFilter': {
      handler (n, o) {
        if (n == 0 && n != o) {
          this.curSelectedSummaryData.isSelected = false;
          this.summaryDataForHour.inSchoolStudent.isSelected = true;
          this.curSelectedSummaryData = this.summaryDataForHour.inSchoolStudent;
        }
      },
      deep: true
    }
  },
  created () {
    this.curSelectedSummaryData = this.summaryDataForHour.inSchoolStudent;
    this.curSelectedPackage.TableID = this.$route.params.dataKey;
  },
  mounted () {
    // 获取当前所有的可用套餐
    this.getUsefulPackage();
    this.swiperInit();
    this.registerBusEvent();
  },
  activated () {
  },
  methods: {
    // 获取当前所有的可用套餐
    getUsefulPackage (QueryID) {
      this.isLoadding = true;
      SumarizeTaocanTicketPlan(QueryID).then(result => {
        console.log('getUsefulPackage', result.data);
        if (result.data.length > 0) {
          if (QueryID > 0) {
            this.curSelectedPackage = result.data[0];
            this.$set(this.curSelectedPackage, 'isActive', true);
            let findIndex = this.packageList.findIndex(item => { return item.TableID == QueryID; });
            if (findIndex > -1) {
              this.packageList.splice(findIndex, 1, this.curSelectedPackage);
            }
            // this.doAfterSelectedPackageItem(this.curSelectedPackage);
          } else {
            if (this.curSelectedPackage.TableID > 0) {
              let findIndex = result.data.findIndex(obj => {
                return obj.TableID == this.curSelectedPackage.TableID;
              });
              if (findIndex > -1) {
                this.curSelectedPackage = result.data[findIndex];
                this.$set(result.data[findIndex], 'isActive', true);
                this.packageList = result.data;
              }
              this.$nextTick(() => {
                this.swiperIndex(findIndex);
              });
              this.doAfterSelectedPackageItem(this.packageList[findIndex]);
            } else {
              this.packageList = result.data;
              let findItem = result.data[0];
              this.$set(findItem, 'isActive', true);
              this.curSelectedPackage = findItem;
              this.$nextTick(() => {
                this.swiperIndex(0);
              });
              if (this.$parent) {
                this.$parent.packageListLength = result.data.length;
              }
              this.doAfterSelectedPackageItem(this.packageList[0]);
            }
          }
          this.initSummaryData();
          if (this.tabActiveIndex == this.tabTitles.student) {
            this.getMessageSearchTicketPlanPageForStudent();
          }
        }
      }).finally(() => {
        this.isLoadding = false;
      });
    },
    // 选中套餐
    doAfterSelectedPackageItem (item) {
      console.log('选中套餐', item);
      this.paramsForStudent.SpecialFilter = 0;
      this.searchText = '';
      if (this.tabActiveIndex == this.tabTitles.student) {
        this.paramsForStudent.pageIndex = 0;
        this.paramsForStudent.searchText = this.searchText;
      }
      this.tabActiveIndex = this.tabTitles.whole;
      if (!this.$utils.isObjEqual(this.curSelectedPackage, item)) {
        this.$set(this.curSelectedPackage, 'isActive', false);
      }
      this.curSelectedPackage = item;
      this.initSummaryData();
    },
    initSummaryData () {
      this.summaryDataForHour.inSchoolStudent.value = this.curSelectedPackage.Total_Student;
      this.summaryDataForHour.SumCurrentCount.value = this.curSelectedPackage.Sum_CurrentCount;
      this.summaryDataForHour.NumToBeArranged.value = this.curSelectedPackage.Num_ToBeArranged;
      this.summaryDataForHour.NumPlanCount.value = this.curSelectedPackage.Num_PlanCount;
      this.summaryDataForHour.NumNotEnought.value = this.curSelectedPackage.Num_NotEnought;
    },
    // 获取选中套餐下的学生（按学生 学生报课统计）
    getMessageSearchTicketPlanPageForStudent () {
      console.log('学生报课统计）');
      if (this.$refs.studentTable) {
        this.$refs.studentTable.expandRowKeys = [];
      }
      this.paramsForStudent.orderBy = (this.paramsForStudent.orderBy == 'studentInfo' ? 'StudentKeyValue' : this.paramsForStudent.orderBy);
      MessageSearchTicketPlanPageForStudent(this.paramsForStudent).then(result => {
        this.$nextTick(() => {
          if (result.data.PageDataList.length == 0 && this.$refs.studentTable) {
            this.$refs.studentTable.isLoading = true;
          }
        });
        if (result.data) {
          result.data.PageDataList.forEach((o, index) => {
            o.Number = index + 1;
            o.sourceData = o.ApplyClassKeyValue.split(',');
            let info = o.StudentKeyValue + ',' + o.CustomerPhoneName;
            o.studentInfo = info.split(',');
            // o.Number = o.Number > 9 ? o.Number : '0' + o.Number;

            if (Number(o.Sum_SuperQuote) > 0) {
              o.Total_ToBePlaned_Warn = '超上';
              o.Total_ToBePlaned_Pop_Tips =
                '超上' + Math.abs(o.Sum_SuperQuote) + '课时';
            }
            if (Number(o.Sum_Overplace) > 0) {
              o.Sum_ToBeArranged_Warn = '超排';
              o.Sum_ToBeArranged_Pop_Tips =
                '超排' + Math.abs(o.Sum_Overplace) + '课时';
            }
            if (Number(o.Total_CurrentCount) <= 5 && Number(o.Total_CurrentCount) != 0) {
              o.Total_CurrentCount_Warn = '不足';
              // o.Total_CurrentCount_Pop_Tips = '不足' + Math.abs(o.Total_CurrentCount) + '课时';
            }
          });
          this.studentList = result.data.PageDataList;
          this.totalNum = result.data.Total;
        }
      }).finally(() => {
        this.$nextTick(() => {
          if (this.$refs.studentTable) {
            this.$refs.studentTable.isLoading = false;
          }
        });
      });
    },
    // 学生排课
    studentArrangCourses (rowData) {
      if (rowData.StudentKey) {
        this.studentKey = rowData.StudentKey;
      }
      this.curSelectedStudent = rowData;
      let item = {};
      if ((rowData.ApplyClassKey > 0 && !rowData.sourceData) || (rowData.sourceData && rowData.sourceData.length == 1)) { // 确定唯一课程，进去
        item = {
          routerName: this.$route.name,
          routertitle: '套餐排课',
          moduleName: 'studentArrangeCourseByCourseName',
          name: '按课程排课',
          data: {
            studentID: this.studentKey,
            courseNameID: rowData.ApplyClassKey,
            courseName: rowData.ApplyClassKeyValue
          }
        };
        this.openCommonCustomDialogByFactoryDialog(item);
      } else {
        item = {
          routerName: this.$route.name,
          routertitle: '套餐排课',
          moduleName: 'studentArrangeCourseByCourseName',
          name: '按课程排课',
          data: {
            studentID: this.studentKey
          }
        };
        this.openCustomDialogByFactoryDialog(item);
      }
    },
    // 学生报课
    studentApplyCourse (rowData) {
      this.curSelectedStudent = rowData;
      if (rowData.StudentKey) {
        this.studentKey = rowData.StudentKey;
      }
      let item = {
        routerName: this.$route.name,
        routertitle: '套餐排课',
        moduleName: 'studentApplyCourse',
        name: '学生报课',
        data: { studentApplyCourseParams: { studentID: this.studentKey, taoCanID: this.curSelectedPackage.TableID } }
      };
      this.openCommonCustomDialogByFactoryDialog(item);
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, columnInfo) {
      this.studentKey = rowData.StudentKey;
      this.curSelectedStudent = rowData;
      let item = {};
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.applyCourse:
          item = {
            routerName: this.$route.name,
            routertitle: '套餐排课',
            moduleName: 'studentApplyCourse',
            name: '学生报课',
            data: { studentApplyCourseParams: { studentID: this.studentKey, taoCanID: this.curSelectedPackage.TableID } }
          };
          this.openCommonCustomDialogByFactoryDialog(item);
          break;
        case this.$initJson.baseFunctionBtnName.arrange:
          if ((rowData.ApplyClassKey > 0 && !rowData.sourceData) || (rowData.sourceData && rowData.sourceData.length == 1)) { // 确定唯一课程，进去
            item = {
              routerName: this.$route.name,
              routertitle: '套餐排课',
              moduleName: 'studentArrangeCourseByCourseName',
              name: '按课程排课',
              data: {
                studentID: this.studentKey,
                courseNameID: rowData.ApplyClassKey,
                courseName: rowData.ApplyClassKeyValue
              }
            };
            this.openCommonCustomDialogByFactoryDialog(item);
          } else {
            item = {
              routerName: this.$route.name,
              routertitle: '套餐排课',
              moduleName: 'studentArrangeCourseByCourseName',
              name: '按课程排课',
              data: {
                studentID: this.studentKey
              }
            };
            this.openCustomDialogByFactoryDialog(item);
          }
          break;
        case this.$initJson.baseFunctionBtnName.popDialog:
          this.$refs.textareaPop.isAlterMessagePopup = true;
          break;
        case this.$initJson.baseFunctionBtnName.detail:
          this.getSummariseStudentTicketPlanDetial();
          break;
        case this.$initJson.baseFunctionBtnName.read:
          item = {
            routerName: 'packageApplyCourseReport',
            routertitle: '套餐排课',
            moduleName: 'studentFile',
            name: '学生档案',
            data: { studentID: this.studentKey }
          };
          this.openCommonCustomDialogByFactoryDialog(item);
          break;
        default:
          break;
      }
    },
    doAfterApplyCourseClick (item) {
      // let moduleInfo = {
      //   routerName: this.$route.name,
      //   moduleName: 'courseApplycourse',
      //   name: '课程报课',
      //   data: { studentKey: this.studentKey, applyClassID: item.ApplyClassKey }
      // };
      // this.openCommonCustomDialogByFactoryDialog(moduleInfo);
    },
    doAfterArrangeClick (item) {
      // this.doAfterTableBtnClick(item, this.$initJson.baseFunctionBtnName.arrange);
    },
    // 获取学生的报课详情
    getSummariseStudentTicketPlanDetial (callBack) {
      SummariseStudentTicketPlanDetial(this.studentKey).then(result => {
        if (result.data) {
          if (callBack) {
            callBack(result.data || []);
          }
          this.studentDetailList = result.data || [];
        }
      });
    },
    // 学生排课备注 提交
    submitStudentArrangeSupport (message, callBack) {
      EditArrangeSupport(this.curSelectedStudent.StudentKey, message).then(
        res => {
          this.curSelectedStudent.ArrangeSupport = message;
          layer.alert('修改成功');
          if (typeof callBack == 'function') {
            callBack();
          }
        },
        err => {
          if (typeof callBack == 'function') {
            callBack();
          }
          layer.alert(err.msg);
        }
      );
    },
    // 切换几个tab
    tabSwitch (val) {
      this.searchText = '';
      this.paramsForStudent.SpecialFilter = 0;
      this.tabActiveIndex = val;
      switch (val) {
        case this.tabTitles.student:
          this.paramsForStudent.pageIndex = 0;
          this.paramsForStudent.searchText = this.searchText;
          this.$nextTick(() => {
            this.$refs.studentTable.queryParams.pageIndex = 0;
            this.$refs.studentTable.queryParams.searchText = this.searchText;
          });
          this.paramsForStudent.TaoCanKey = this.curSelectedPackage.TableID;
          this.getMessageSearchTicketPlanPageForStudent();
          break;
        case this.tabTitles.course:
          this.$nextTick(() => {
            this.$refs.courseNameApplyCourse.isShowSearch = true;
          });
          break;
        default:
          break;
      }
    },
    // 搜索
    searchStudent () {
      this.search();
    },
    // 清除条件
    clearSearchCondition () {
      this.searchText = '';
      this.search();
    },
    search () {
      this.paramsForStudent.SpecialFilter = 0;
      switch (this.tabActiveIndex) {
        case this.tabTitles.whole:
          this.$refs.overviewTable.params.pageIndex = 0;
          this.$refs.overviewTable.params.SpecialFilter = this.paramsForStudent.SpecialFilter;
          this.$refs.overviewTable.params.searchText = this.searchText.replace(/ /g, '');
          this.$refs.overviewTable.loadMessageSearchTicketPlanPageForTaoCan();
          break;
        case this.tabTitles.student:
          this.$refs.studentTable.queryParams.pageIndex = 0;
          this.$refs.studentTable.expandRowKeys = [];
          this.paramsForStudent.pageIndex = 0;
          this.paramsForStudent.searchText = this.searchText.replace(/ /g, '');
          this.getMessageSearchTicketPlanPageForStudent();
          break;
        case this.tabTitles.course:
          this.$refs.courseNameApplyCourse.paramsForStudent.SpecialFilter = this.paramsForStudent.SpecialFilter;
          this.$refs.courseNameApplyCourse.searchText = this.searchText.replace(/ /g, '');
          this.$refs.courseNameApplyCourse.searchStudent();
          break;
        default:
          break;
      }
    },
    // 初始化swiper.
    swiperInit () {
      this.swiper = new Swiper('.swiper-container', {
        breakpoints: {
          1920: {
            slidesPerView: 4
          },
          1440: {
            slidesPerView: 4
          }
        },
        spaceBetween: 20, // 间距
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        slidesPerGroup: 3
      });
    },
    // 更新swiper坐标.
    swiperIndex (val) {
      this.swiper.updateSlides();
      this.swiper.slideTo(val);
    },
    //  安排 或 报课 成功
    doAfterArrangeOrApplyCourseSuccess () {
      switch (this.tabActiveIndex) {
        case this.tabTitles.whole:
          this.getUsefulPackage();
          break;
        case this.tabTitles.student:
          this.getUsefulPackage();
          break;
        case this.tabTitles.course:
          this.getUsefulPackage();
          break;
        default:
          break;
      }
    },
    afterCilckSummaryData (item) {
      if (item.title !== '当前报课') {
        if (!item.value || item.value == 0 || item.title == '当前报课') {
          return false;
        }
        this.curSelectedSummaryData.isSelected = false;
        item.isSelected = true;
        this.curSelectedSummaryData = item;
        this.searchText = '';
        this.paramsForStudent.searchText = '';
        switch (item.type) {
          case 'inSchoolStudent':
            this.paramsForStudent.SpecialFilter = '0';
            break;
          case 'NumToBeArranged':
            this.paramsForStudent.SpecialFilter = '1';
            break;
          case 'NumPlanCount':
            this.paramsForStudent.SpecialFilter = '2';
            break;
          case 'NumNotEnought':
            this.paramsForStudent.SpecialFilter = '3';
            break;
          default:
            break;
        }
        if (this.tabActiveIndex == this.tabTitles.whole) {
          this.$refs.overviewTable.params.pageIndex = 0;
          this.$refs.overviewTable.params.pageSize = 10;
          this.$refs.overviewTable.params.searchText = this.paramsForStudent.searchText;
          this.$refs.overviewTable.params.SpecialFilter = this.paramsForStudent.SpecialFilter;
          this.$refs.overviewTable.loadMessageSearchTicketPlanPageForTaoCan();
        } else if (this.tabActiveIndex == this.tabTitles.student) {
          this.$refs.studentTable.expandRowKeys = [];
          this.paramsForStudent.pageIndex = 0;
          // studentTable
          this.getMessageSearchTicketPlanPageForStudent();
        }
      }
    },
    registerBusEvent () {
      // 修改学生档案的学生信息成功后触发回调
      this.$bus.on(this, 'AfterStudentUpdate', studentKey => {
        if (studentKey > 0) {
          this.getUsefulPackage();
        }
      });
      // 刷新排课数据
      this.$bus.on(this, 'afterArrangeScheduleCourse', () => {
        this.getUsefulPackage(this.curSelectedPackage.TableID);
        this.getSummariseStudentTicketPlanDetial();
      });
      this.$bus.on(this, 'AfterStudentApplyCourse', applyCourseInfo => {
        this.getUsefulPackage(this.curSelectedPackage.TableID);
        this.getSummariseStudentTicketPlanDetial();
      });
      this.$bus.on(this, 'AfterArrangeForSingleStudent', (type, courseID, studentID) => {
        this.getUsefulPackage(this.curSelectedPackage.TableID);
        this.getSummariseStudentTicketPlanDetial();
      });
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'packageApplyCourseReport') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'packageApplyCourseReport') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.packageApplyCourseReportDialogFactory) {
        this.$refs.packageApplyCourseReportDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.packageApplyCourseReportDialogFactoryCommon) {
        this.$refs.packageApplyCourseReportDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>
