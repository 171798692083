<template>
  <div>
    <div class="general_summarizing_student">
      <div class="course_statistics_table_search flex">
        <date-range ref="saveDate"
                    :searchObj="searchObj"
                    :fromTitle="'选择日期'"
                    :maxDate="$utils.formatDateToLine(Date.new())"
                    @changeDate="changeSaveDate"></date-range>
        <div class="course_statistics_search">
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入学生名、备注'"
                                    :clearAll="true"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="searchData"></input-search-contain-btn>
          <div class="mendian_backstage_search">
            <div class="btn_hover_bg_blue search_btn "
                 style="margin: 0;"
                 @click.stop="doExportInfoClick">导出</div>
          </div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <list class="summarizing_list"
            :key="tableKey"
            :tableData="vdataReportList"
            :totalNum="totalNum"
            :PageCount="PageCount"
            :tableColumns="tableCourseColumns"
            :isSearchText="false"
            :isNeedInnerScroll="false"
            :defaultSort="defaultSort"
            :queryParams="searchObj"
            :footerContent="vFooterTotal"
            @loadTableData="getDataList"></list>
    </div>
    <!-- 历史记录 -->
    <custom-dialog :title="'历史记录'"
                   class=" "
                   :width="'1213px'"
                   :visible.sync="isShowDebtHistroy"
                   :before-close="changeShowDebtHistroy">
      <debt-histroy :dataInfo="seletedInfo"
                    @afterSuccess="searchData"
                    @closeDialog="changeShowDebtHistroy">
      </debt-histroy>
    </custom-dialog>
  </div>
</template>
<script>
import list from '../../../../common/table-list/index';
import applyAcountItem from './../apply-acount-item';
import debtHistroy from './debt-histroy';
import inputSearchContainBtn from '../../../common/input-search-contain-btn';
import { MessageSearchInComePayReportForCurrentDebt, getStudentRelatedMsg } from '../../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: '',
        endTime: '',
        IsExportKey: 0
      },
      seletedInfo: null,
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          decimalPlace: 0,
          className: 'table_index_gray'
        },
        {
          label: '学生',
          prop: 'StudentKeyValue',
          minWidth: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'student',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '当前欠款',
          prop: 'DebtAmount',
          minWidth: 140,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'amount-item',
          decimalPlace: 2
        },
        {
          label: '总欠款',
          prop: 'TotalDebtAmount',
          minWidth: 140,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'amount-item',
          decimalPlace: 2
        },
        {
          label: '已还款',
          prop: 'RefundAmount',
          minWidth: 140,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'amount-item',
          decimalPlace: 2
        },
        {
          label: '还款待审',
          prop: 'PendAmount',
          minWidth: 140,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'amount-item',
          decimalPlace: 2,
          eventName: '4'
        },
        {
          label: '最近欠款',
          prop: 'BillTime',
          minWidth: 140,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          eventName: '4'
        },
        {
          label: '经办人',
          prop: 'BillerKeyValue',
          minWidth: 140,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          fixed: 'right',
          width: 120,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  click: (rowData) => {
                    this.openDebyHistory(rowData);
                  }
                }
                // ModulePowerKey: 50
              },
              {
                name: '还款',
                extend: {
                  click: (rowData) => {
                    this.studentPaymentEvent(rowData);
                  }
                },
                ModulePowerKey: 50
              }

            ]
          }
        }
      ],
      dataReportList: [],
      isShowDebtHistroy: false
    };
  },
  components: {
    list,
    applyAcountItem,
    inputSearchContainBtn,
    debtHistroy
  },
  props: {

  },
  created () {
    this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
    this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.BillTime = o.BillTime.substring(0, 10);
        // o.GenerateTime = o.GenerateTime.substring(5);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      return '共' + this.totalNum + '笔，合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
    }

  },
  mounted () {
    this.getDataList();
  },
  methods: {
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime; ;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.AccountTypeKey = '';
      this.searchObj.searchText = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    searchData () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '当前欠款',
          clist: [
            { title: '学生', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '当前欠款', cName: 'DebtAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '总欠款', cName: 'TotalDebtAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '已还款', cName: 'RefundAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '还款待审', cName: 'PendAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '最近欠款', cName: 'BillTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '经办人', cName: 'BillerKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchInComePayReportForCurrentDebt(searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      console.log(this.searchObj);
      MessageSearchInComePayReportForCurrentDebt(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalDebtAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchInComePayReportForKDSY');
      }).catch(error => {
        console.log('MessageSearchInComePayReportForKDSY', error.msg);
      });
    },
    toApplyReport (item) {
      this.$emit('toApplyReport', item, this.searchObj);
    },
    toOtherIncomeReport (item) {
      this.$emit('toOtherIncomeReport', item, this.searchObj);
    },
    toExpenditureReport (item) {
      this.$emit('toExpenditureReport', item, this.searchObj);
    },
    selectAccountType (item) {
      console.log(item, 'selectAccountType');
      this.searchObj.AccountTypeKey = item.OLAPKey;
      this.searchObj.AccountTypeKeyValue = item.MainDemoName;
      this.searchData();
    },
    changeShowDebtHistroy () {
      this.isShowDebtHistroy = false;
    },
    openDebyHistory (item) {
      this.seletedInfo = {
        startTime: this.searchObj.startTime,
        endTime: this.searchObj.endTime,
        StudentKey: item.StudentKey,
        StudentKeyValue: item.StudentKeyValue,
        CustomerKey: item.CustomerKey,
        TypeKey: 1,
        TypeKeyValue: '欠款'
      };
      this.isShowDebtHistroy = true;
      // this.$dialog.open(this, {
      //   name: '历史记录',
      //   routerName: this.$route.name,
      //   moduleName: 'debyHistory',
      //   data: { dataInfo: item },
      //   callBack: { afterSucces: () => { this.searchData(); } }
      // });
    },
    // 续费按钮
    studentPaymentEvent (item) {
      this.getStudentInfo(item.StudentKey, (data) => {
        this.$dialog.open(this, {
          name: '客户还款',
          routerName: this.$route.name,
          moduleName: 'paymentrepayment',
          data: { dataInfo: data },
          callBack: { afterSucces: () => { this.searchData(); } }
        });
      });
    },
    // 获取学生个人信息
    getStudentInfo (StuKey, callback) {
      getStudentRelatedMsg(StuKey).then(result => {
        if (callback) {
          callback(result.data);
        }
      }, err => {
        layer.alert(err.msg);
      });
    }
  }
};
</script>