<template>
  <div>
    <div class>
      <div style="border-radius:8px;overflow:hidden;">
        <list ref="studentApplyCourseTable"
              class="summarizing_list"
              :tableData="studentList"
              :tableColumns="tableColumns"
              :expandRowData="expandRowData"
              :totalNum="totalNum"
              :headSummaryData="headSummaryData"
              :PageCount="PageCount"
              :isSearchText="false"
              :summary="summary"
              :isShowSearch="true"
              :rowKey="'Number'"
              :tableColumnsType="tableColumnsType"
              :defaultSort="defaultSort"
              @searchStudent="searchStudent"
              @loadTableData="loadAfterTableData"
              @clearSearchParams="clearSearchCondition"
              @doSelectCourseSearch="loadCourseNameList"
              @doAfterCilckSummaryData="afterCilckSummaryData"></list>
      </div>
    </div>
    <div>
      <dialog-factory ref="studentApplyCourseReportDialogFactory"
                      :key="'studentManagementReport'"
                      :routerName="'studentManagementReport'"></dialog-factory>

      <dialog-factory-common ref="studentManagementCommonDialogFactory"></dialog-factory-common>
    </div>
    <custom-dialog title="选择课程"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isShowCourseNameList"
                   :before-close="closeDialogForCourseNameList">
      <div>
        <single-selection-list :isShowSearchInput="false"
                               :required="true"
                               :dataSourceList="courseNameList"
                               :selectedKey="paramsForStudent.ApplyClassKey"
                               @onSelectedItem="doAfterSelectedCourseName"></single-selection-list>
      </div>
    </custom-dialog>
  </div>
</template>
<script>
import {
  SummariseStudentTicketPlanDetial,
  getStudentRelatedMsg,
  SelectTheClassType,
  MessageSearchPageForActiveStudent,
  MessageSearchPageForActiveStudentApplyClass,
  getStudentAllCourse,
  WeChatUnbind
} from '../../../API/workbench.js';

import list from './precise-table';
import dialogFactory from '../../common/dialog-factory';
import studentCoursesArranging from '../../pop-up-menu/student-courses-arranging/index';
export default {
  data () {
    return {
      tableType: '通用版',
      isShowCourseNameList: false,
      courseNameList: [], // 课程列表
      // 在校学生统计
      paramsForStudent: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母
        orderBy: 'StudentKeyValue', //	字符串	可选		数据源：GET	排序字段
        sequence: 'asc', //	字符串	可选		数据源：GET	排序顺序
        ApplyClassKey: '', // 课程ID
        QueryID: '', // 整型	可选	0	数据源：GET	学生ID
        SubscribeKey: '', // 关注状态：0-未关注;1-已关注;空-不生效
        SpecialFilter: '0' // 整型	可选	0	数据源：GET	0-不生效;2-按待报>0;3-按课时不足<=5
      },
      studentInSchoolTotalNum: 0,
      studentList: [], // 按学生 的 学生列表
      curSelectedStudentKey: 0, // 当前选中学生Key
      searchText: '',
      headSummaryData: [
        { title: '在校学生', className: 'font_blue', value: 0, type: 'Total_Student', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: true },
        { title: '课时不足', className: 'font_blue', value: 0, type: 'Num_NotEnought', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false },
        { title: '待报人数', className: 'font_blue', value: 0, type: 'Num_PlanCount', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false },
        { title: '待排人数', className: 'font_blue', value: 0, type: 'Num_ToBeArranged', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false },
        { title: '超排人数', className: 'font_blue', value: 0, type: 'Num_Overplace', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false },
        { title: '超上人数', className: 'font_blue', value: 0, type: 'Num_SuperQuote', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false },
        { title: '已关注', className: 'font_blue', value: 0, type: 'Num_Subscribe', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false }
        // { title: '未关注', className: 'font_orange', value: 0, type: 'Num_UnSubscribe', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false }

      ],
      tableColumnsType: 'StudentKey',
      tableColumns: [
        {
          label: '学生',
          prop: 'StudentKeyValue',
          type: 'student',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '排课备注',
          prop: 'ArrangeSupport',
          type: 'arrange-support',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            studentKeyValue: 'StudentKeyValue', // 学生名
            click: () => { }
          }
        },
        {
          label: '最近购课',
          prop: 'LastEnlistTime',
          type: 'date-item'
        },
        {
          label: '在读课程',
          prop: 'ApplyClassKeyValue',
          type: 'reading-courses',
          extend: {
            splitSymbol: ','
          }
        },
        {
          label: '可用课时',
          prop: 'Total_CurrentCount',
          type: 'class-hour',
          isShow: true,
          extend: {
            type: 'expand',
            click: () => { },
            isLoading: true, // 正在加载中
            tableWidth: '980px',
            left: '100px',
            loadtableData: (rowData, callBack) => { // 获取详情数据
              this.studentKey = rowData.StudentKey;
              this.getStudentAllCourseDetial(callBack);
            },
            tableColumnsDetail: [
              {
                label: '适用课程',
                prop: 'ApplyCorName',
                type: 'reading-courses'
              },
              {
                label: '有效天数',
                prop: 'PeriodCount',
                type: 'class-hour'
              },

              {
                label: '购买课时',
                prop: 'NumCount',
                type: 'class-hour'
              },
              {
                label: '消耗课时',
                prop: 'expendCount',
                type: 'class-hour'
              },
              {
                label: '调整课时',
                prop: 'AdjustCount',
                type: 'class-hour'
              },
              {
                label: '可用课时',
                prop: 'CurrentCount',
                type: 'class-hour'
              },
              {
                label: '待报课时',
                prop: 'ToBePlaned',
                type: 'class-hour',
                extend: {
                  click: (rowData) => { this.studentApplyCourse(rowData); },
                  className: 'total_to_beplaned'
                }
              },
              {
                label: '操作',
                prop: 'option',
                type: 'table-btn',
                width: 180,
                extend: {
                  tableBtnGroup: [
                    {
                      name: '报课',
                      extend: {
                        click: (rowData) => {
                          this.studentApplyCourse(rowData);
                        },
                        ModulePowerKey: 51
                      }
                    },
                    {
                      name: '排课',
                      extend: {
                        click: (rowData) => {
                          this.studentArrangCourses(rowData);
                        },
                        ModulePowerKey: 51
                      }
                    }
                  ]
                }
              }
            ]
          }
        }, {
          label: '当前报课',
          prop: 'Sum_CurrentCount',
          type: 'class-hour',
          isShow: false,
          extend: {
            click: (rowData) => { this.studentApplyCourse(rowData); }
          }
        },
        {
          label: '当前排课',
          prop: 'Sum_ArrangedCount',
          type: 'class-hour'
        },
        {
          label: '待排课时',
          prop: 'Sum_ToBeArranged',
          type: 'class-hour',
          extend: {
            click: (rowData) => { this.studentArrangCourses(rowData); }
          }
        },
        {
          label: '待报课时',
          prop: 'Total_ToBePlaned',
          type: 'class-hour',
          extend: {
            click: (rowData) => { this.studentApplyCourse(rowData); },
            className: 'total_to_beplaned'
          }
        },
        {
          label: '关注',
          prop: 'SubscribeKey',
          type: 'wechat-subscribe',
          extend: {
            click: (rowData) => { this.wechatSubscribeUnbind(rowData); }
          }
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                type: 'expand',
                extend: {
                  click: (rowData) => { },
                  loadtableData: (rowData, callBack) => { // 获取详情数据
                    if (rowData.StudentKey) {
                      this.studentKey = rowData.StudentKey;
                    }
                    this.getSummariseStudentTicketPlanDetial(callBack);
                  },
                  tableColumnsDetail: [
                    {
                      label: '在读课程',
                      prop: 'ApplyClassKeyValue',
                      type: 'reading-courses'
                    },
                    {
                      label: '当前报课',
                      prop: 'Sum_CurrentCount',
                      type: 'class-hour'
                    },
                    {
                      label: '当前排课',
                      prop: 'Sum_ArrangedCount',
                      type: 'class-hour'
                    },
                    {
                      label: '待排课时',
                      prop: 'Sum_ToBeArranged',
                      type: 'class-hour',
                      extend: {
                        click: (rowData) => { this.studentArrangCourses(rowData); }
                      }
                    },
                    {
                      label: '待报课时',
                      prop: 'Total_ToBePlaned',
                      type: 'class-hour',
                      extend: {
                        click: (rowData) => { this.studentApplyCourse(rowData); },
                        className: 'total_to_beplaned'
                      }
                    },
                    {
                      label: '操作',
                      prop: 'option',
                      type: 'table-btn',
                      width: 180,
                      extend: {
                        tableBtnGroup: [
                          {
                            name: '报课',
                            extend: {
                              click: (rowData) => { this.studentApplyCourse(rowData); },
                              ModulePowerKey: 51
                            }
                          },
                          {
                            name: '排课',
                            extend: {
                              click: (rowData) => { this.studentArrangCourses(rowData); },
                              ModulePowerKey: 51
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                name: '报课',
                extend: {
                  click: (rowData) => { this.studentApplyCourse(rowData); },
                  ModulePowerKey: 51
                }
              },
              {
                name: '排课',
                extend: {
                  click: (rowData) => { this.studentArrangCourses(rowData); },
                  ModulePowerKey: 51
                }
              }
            ]
          }
        }
      ],
      PageCount: 0,
      totalNum: 0,
      summary: {
        needSummariesColumns: [
          { prop: 'StudentKeyValue', SumType: 'string', unit: '人' },
          { prop: 'Total_NumCount', SumType: 'number', unit: '' },
          { prop: 'Sum_SpendCount', SumType: 'number', unit: '' },
          { prop: 'Total_CurrentCount', SumType: 'number', unit: '' },
          { prop: 'Sum_CurrentCount', SumType: 'number', unit: '' },
          { prop: 'Total_ToBePlaned', SumType: 'number', unit: '' },
          { prop: 'Sum_ArrangedCount', SumType: 'number', unit: '' },
          { prop: 'Sum_ToBeArranged', SumType: 'number', unit: '' }
        ],
        isShowSummary: false,
        summaryName: '合计',
        summaryData: []
      },
      isAlterMessagePopup: false,
      arrangeType: '', // 安排类型（因为有两个入口可安排学生 以区分是哪个入口）
      defaultSort: { prop: 'studentInfo', order: 'ascending' },
      expandRowData: []// 展开行的数据
    };
  },
  components: {
    list,
    studentCoursesArranging,
    dialogFactory
  },
  created () {
    if (this.$route.params.data && this.$route.params.data.headSummaryData) { // 外部进入定位
      console.log(this.$route.params.data, '外部进入定位');
      this.headSummaryData.forEach(o => {
        if (o.title == this.$route.params.data.headSummaryData.title) {
          this.afterCilckSummaryData(o);
        }
      });
      this.registerBusEvent();
    } else {
      this.getMessageSearchTicketPlanPageForStudent();
      this.registerBusEvent();
    }
  },
  watch: {

  },
  activated () {
    if (this.$route.params.data && this.$route.params.data.headSummaryData) { // 外部进入定位,防止打开时，第二次没有默认选中的问题
      console.log(this.$route.params.data, '外部进入定位-activated');
      this.headSummaryData.forEach(o => {
        if (o.title == this.$route.params.data.headSummaryData.title) {
          this.afterCilckSummaryData(o);
        }
      });
    }
  },
  mounted () {

  },
  methods: {
    // 关闭 课名列表
    closeDialogForCourseNameList () {
      this.isShowCourseNameList = false;
    },
    // 获取课名列表
    loadCourseNameList () {
      SelectTheClassType().then(result => {
        this.courseNameList = [];
        result.data.forEach(obj => {
          if (obj.StateKey != 3 && obj.TypeKey == 2) {
            // StateKey:3为停用 TypeKey:0-全部；1-课类；2-课名
            this.courseNameList.push(obj);
          }
        });
        this.isShowCourseNameList = true;
      });
    },
    // 汇总数据
    initSummaryData (data) {
      if (data) {
        this.headSummaryData[0].value = data.Total_Student;// 在读学生
        this.headSummaryData[1].value = data.Num_NotEnought;// 课时不足
        this.headSummaryData[2].value = data.Num_PlanCount;// 待报人数
        this.headSummaryData[3].value = data.Num_ToBeArranged;// 待排人数
        this.headSummaryData[4].value = data.Num_Overplace;// 超排人数
        this.headSummaryData[5].value = data.Num_SuperQuote;// 超上人数
        this.headSummaryData[6].value = data.Num_Subscribe;// 已关注学生数
        // this.headSummaryData[7].value = data.Num_UnSubscribe;// 超上人数
      }
    },
    // 汇总数据排序操作项
    afterCilckSummaryData (item) {
      console.log(item, '汇总数据排序');
      this.headSummaryData.forEach(o => { // 高亮显示
        if (o.type == item.type) {
          o.isSelected = true;
        } else {
          o.isSelected = false;
        }
      });
      this.searchText = '';
      this.paramsForStudent.searchText = '';
      this.paramsForStudent.pageIndex = 0;
      this.paramsForStudent.ApplyClassKey = 0;
      if (item.type == 'Total_Student' || item.type == 'Num_PlanCount' || item.type == 'Num_NotEnought' || item.type == 'Num_Subscribe') {
        if (this.$refs.studentApplyCourseTable) {
          this.$refs.studentApplyCourseTable.searchValue = 1;// 回到学生搜索类型
        }
        this.studentSummaryDataOpt(item);
      } else {
        this.courseSummaryDataOpt(item);
      }
    },
    // 学生级
    studentSummaryDataOpt (item) {
      this.tableColumnsType = 'StudentKey';
      this.tableColumns.forEach(o => {
        if (o.label == '可用课时') {
          o.isShow = true;
        }
        if (o.label == '当前报课') {
          o.isShow = false;
        }
        let detailsBut = {
          name: '详情',
          type: 'expand',
          extend: {
            click: (rowData) => { },
            loadtableData: (rowData, callBack) => { // 获取详情数据
              if (rowData.StudentKey) {
                this.studentKey = rowData.StudentKey;
              }
              this.getSummariseStudentTicketPlanDetial(callBack);
            },
            tableColumnsDetail: [
              {
                label: '',
                prop: 'other',
                type: 'other'
              },
              {
                label: '在读课程',
                prop: 'ApplyClassKeyValue',
                type: 'reading-courses'
              },
              {
                label: '当前报课',
                prop: 'Sum_CurrentCount',
                type: 'class-hour'
              },
              {
                label: '当前排课',
                prop: 'Sum_ArrangedCount',
                type: 'class-hour'
              },
              {
                label: '待排课时',
                prop: 'Sum_ToBeArranged',
                type: 'class-hour',
                extend: {
                  click: (rowData) => { this.studentArrangCourses(rowData); }
                }
              },
              {
                label: '待报课时',
                prop: 'Total_ToBePlaned',
                type: 'class-hour',
                extend: {
                  click: (rowData) => { this.studentApplyCourse(rowData); },
                  className: 'total_to_beplaned'
                }
              },
              {
                label: '操作',
                prop: 'option',
                type: 'table-btn',
                width: 180,
                extend: {
                  tableBtnGroup: [
                    {
                      name: '报课',
                      extend: {
                        click: (rowData) => { this.studentApplyCourse(rowData); },
                        ModulePowerKey: 51
                      }
                    },
                    {
                      name: '排课',
                      extend: {
                        click: (rowData) => { this.studentArrangCourses(rowData); },
                        ModulePowerKey: 51
                      }
                    }
                  ]
                }
              }
            ]
          }
        };
        if (o.label == '操作') {
          if (o.extend.tableBtnGroup[0].name !== '详情') {
            o.extend.tableBtnGroup.unshift(detailsBut);
          }
        }
      });
      this.paramsForStudent.SubscribeKey = '';// 默认不生效
      switch (item.type) {
        case 'Total_Student':// 在读学生
          this.paramsForStudent.SpecialFilter = '0';
          break;
        case 'Num_PlanCount':// 待报人数
          this.paramsForStudent.SpecialFilter = '2';
          break;
        case 'Num_NotEnought':// 课时不足
          this.paramsForStudent.SpecialFilter = '3';
          break;
        case 'Num_Subscribe':// 已关注学生数
          this.paramsForStudent.SubscribeKey = '1';
          this.paramsForStudent.SpecialFilter = '0';
          break;
        default:
          break;
      }
      this.getMessageSearchTicketPlanPageForStudent();
    },
    // 课程级
    courseSummaryDataOpt (item) {
      this.tableColumnsType = '';
      this.tableColumns.forEach(o => {
        if (o.label == '可用课时') {
          o.isShow = false;
        }
        if (o.label == '当前报课') {
          o.isShow = true;
        }
        if (o.label == '操作') {
          if (o.extend.tableBtnGroup[0].name == '详情') {
            o.extend.tableBtnGroup.shift();
          }
        }
      });
      switch (item.type) { // 1-按待排>0;4-按超排过滤;5-按超上过滤;
        case 'Num_ToBeArranged':// 待排人数
          this.paramsForStudent.SpecialFilter = '1';
          break;
        case 'Num_Overplace': // 超排人数
          this.paramsForStudent.SpecialFilter = '4';
          break;
        case 'Num_SuperQuote':// 超上人数
          this.paramsForStudent.SpecialFilter = '5';
          break;
        default:
          break;
      }
      this.getMessageSearchPageForActiveStudentApplyClass();
    },
    // 获取在校学生统计
    getMessageSearchTicketPlanPageForStudent (queryParams) {
      if (queryParams) {
        queryParams.orderBy = (queryParams.orderBy == 'studentInfo' ? 'StudentKeyValue' : queryParams.orderBy);
        Object.assign(this.paramsForStudent, queryParams);
      }
      MessageSearchPageForActiveStudent(this.paramsForStudent).then(result => {
        this.studentList = [];
        this.initSummaryData(result.data.sumamarize);
        if (result.data.PageDataList.length == 0) {
          this.$refs.studentApplyCourseTable.isLoading = true;
        }
        this.totalNum = result.data.Total;
        if (result.data && result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach((o, index) => {
            o.Number = index + 1;
            o.sourceData = o.ApplyClassKeyValue.split(',');
            let info = o.StudentKeyValue + ',' + this.$utils.phoneModulePower(o.CustomerPhoneName);
            o.studentInfo = info.split(',');
            // o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
          });
          this.studentList = result.data.PageDataList;
          let items = {
            StudentKeyValue: ' ',
            isEmpertyRow: true
          };
          let oldTableDataLength = this.$utils.parseJson(this.studentList.length);
          if (this.studentList.length < this.paramsForStudent.pageSize && this.studentList.length > 0) {
            for (let index = 1; index <= (this.paramsForStudent.pageSize - oldTableDataLength); index++) {
              this.studentList.push(items);
            }
          }
        }
      }).finally(() => {
        if (this.$refs.studentApplyCourseTable) {
          this.$refs.studentApplyCourseTable.isLoading = false;
        }
      });
    },
    // 在校学生课程报课统计
    getMessageSearchPageForActiveStudentApplyClass (queryParams) {
      if (queryParams) {
        queryParams.orderBy = (queryParams.orderBy == 'studentInfo' ? 'StudentKeyValue' : queryParams.orderBy);
        Object.assign(this.paramsForStudent, queryParams);
      }
      MessageSearchPageForActiveStudentApplyClass(this.paramsForStudent).then(result => {
        this.studentList = [];
        this.initSummaryData(result.data.sumamarize);
        if (result.data.PageDataList.length == 0) {
          this.$refs.studentApplyCourseTable.isLoading = true;
        }
        this.totalNum = result.data.Total;
        if (result.data && result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach((o, index) => {
            let info = o.StudentKeyValue + ',' + o.CustomerPhoneName;
            o.studentInfo = info.split(',');
            // o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
            if (Number(o.Sum_SuperQuote) > 0) {
              o.Sum_CurrentCount_Warn = '超上' + Math.abs(o.Sum_SuperQuote);
            }
            if (Number(o.Sum_Overplace) > 0) {
              o.Sum_ToBeArranged_Warn = '超排' + Math.abs(o.Sum_Overplace);
            }
          });
          let items = {
            StudentKeyValue: ' ',
            isEmpertyRow: true
          };
          this.studentList = result.data.PageDataList;
          let oldTableDataLength = this.$utils.parseJson(this.studentList.length);
          if (this.studentList.length < this.paramsForStudent.pageSize && this.studentList.length > 0) {
            for (let index = 1; index <= (this.paramsForStudent.pageSize - oldTableDataLength); index++) {
              this.studentList.push(items);
            }
          }
        }
      }).finally(() => {
        if (this.$refs.studentApplyCourseTable) {
          this.$refs.studentApplyCourseTable.isLoading = false;
        }
      });
    },
    // 跳转页码
    loadAfterTableData (queryParams) {
      this.paramsForStudent.pageIndex = queryParams.pageIndex;
      this.$refs.studentApplyCourseTable.expandRowKeys = [];
      this.$refs.studentApplyCourseTable.totalCurrentCountDetailList = [];
      switch (this.paramsForStudent.SpecialFilter) {
        case '0':
        case '2':
        case '3':
          this.getMessageSearchTicketPlanPageForStudent(queryParams);
          break;
        case '1':
        case '4':
        case '5':
          this.getMessageSearchPageForActiveStudentApplyClass(queryParams);
          break;
        default:
          break;
      }
    },
    // 选择课程项进行搜索
    doAfterSelectedCourseName (item) {
      this.paramsForStudent.searchText = '';
      this.paramsForStudent.pageIndex = 0;
      this.$refs.studentApplyCourseTable.queryParams.pageIndex = 0;
      this.paramsForStudent.ApplyClassKey = item.OLAPKey;
      this.$refs.studentApplyCourseTable.searchText = item.MainDemoName;
      this.isShowCourseNameList = false;
      this.getMessageSearchPageForActiveStudentApplyClass();
    },
    // 搜索
    searchStudent (searchText) {
      this.paramsForStudent.searchText = searchText;
      this.paramsForStudent.pageIndex = 0;
      this.paramsForStudent.ApplyClassKey = 0;
      this.$refs.studentApplyCourseTable.queryParams.pageIndex = 0;
      this.$refs.studentApplyCourseTable.expandRowKeys = [];
      if (this.tableColumnsType == 'StudentKey') {
        this.getMessageSearchTicketPlanPageForStudent();
      } else {
        this.getMessageSearchPageForActiveStudentApplyClass();
      }
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchText = '';
      this.paramsForStudent.searchText = '';
      this.paramsForStudent.pageIndex = 0;
      this.paramsForStudent.ApplyClassKey = 0;
      this.$refs.studentApplyCourseTable.queryParams.pageIndex = 0;
      if (this.tableColumnsType == 'StudentKey') {
        this.getMessageSearchTicketPlanPageForStudent();
      } else {
        this.getMessageSearchPageForActiveStudentApplyClass();
      }
    },
    // 获取当前选中学生信息
    loadStudentInfo () {
      getStudentRelatedMsg(this.curSelectedStudentKey).then(
        result => {
          this.curSelectedStudentInfo = result.data;
        }, error => {
        layer.alert(error.msg);
      });
    },
    // 学生报课显示...
    doStudentApplyCourseClick (course) {
      let item = {
        routerName: this.$route.name,
        routertitle: '学生管理',
        moduleName: 'studentApplyCourse',
        name: '学生报课',
        data: { studentApplyCourseParams: { studentID: this.studentKey, taoCanID: course.TableID } }
      };
      this.$dialog.open(this, item);
    },
    // 点击待报课时项显示...
    doAfterApplyCourseClick (item) {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        routertitle: '学生管理',
        moduleName: 'courseApplycourse',
        name: '课程报课',
        data: { studentKey: this.studentKey, applyClassID: item.ApplyClassKey }
      });
    },
    // 获取学生的报课详情
    getSummariseStudentTicketPlanDetial (callBack) {
      this.isReadExpandType = 'PlanDetial';
      SummariseStudentTicketPlanDetial(this.studentKey).then(result => {
        if (result.data) {
          this.$refs.studentApplyCourseTable.studentDetailList = result.data || [];
          if (callBack) {
            callBack(result.data || []);
          }
          this.expandRowData = result.data || [];
        }
      });
    },
    // 获取学生的课单详情(可用课时)
    getStudentAllCourseDetial (callBack) {
      this.isReadExpandType = 'CurrentCount';
      if (!this.studentKey) {
        return;
      }
      getStudentAllCourse(this.studentKey).then(result => {
        if (result.data) {
          let studentAllCourseDetialList = [];
          if (result.data && result.data.length > 0) {
            result.data.forEach((o, index) => {
              o.Number = index + 1;
              o.ApplyClassKey = o.ApplyCorIDsName;
              o.ApplyClassKeyValue = o.ApplyCorName;
              // o.sourceData = o.ApplyCorName.split(',');
              o.sourceData = o.ApplyCorName.replace(/\，/g, ',').split(',');
              if (o.StatusKey == 2 && o.CurrentCount > 0 && o.UnitKey == 5) {
                studentAllCourseDetialList.push(o);
              }
            });
          }
          studentAllCourseDetialList.sort((x, y) => { return Date.new(y.PaidTime) - Date.new(x.PaidTime); });// 购买时间排序
          if (callBack) {
            callBack(studentAllCourseDetialList || []);
          }
          this.expandRowData = studentAllCourseDetialList || [];
        }
      });
    },
    // 课程报课成功 详情
    doAfterCourseApplyCourseSuccess () {
      this.getSummariseStudentTicketPlanDetial();
      this.getMessageSearchTicketPlanPageForStudent();
      this.$refs.studentApplyCourseTable.expandRowKeys.push(this.studentKey);
    },
    // 学生安排（取消成功）详情
    cancelArrangeSuccess () {
      if (this.arrangeType == 'detail') {
        this.doAfterCourseApplyCourseSuccess();
      } else {
        this.getMessageSearchTicketPlanPageForStudent();
      }
    },
    upSelectStudentOrApplyClass () {
      if (this.tableColumnsType == 'StudentKey') {
        this.getMessageSearchTicketPlanPageForStudent();
      } else {
        this.getMessageSearchPageForActiveStudentApplyClass();
      }
    },
    // 选择需要显示的详情列表
    showDetailsTable () {
      if (this.isReadExpandType == 'CurrentCount') {
        this.getStudentAllCourseDetial();
      } else if (this.isReadExpandType == 'PlanDetial') {
        this.getSummariseStudentTicketPlanDetial();
      }
    },

    // 学生排课
    studentArrangCourses (rowData) {
      if (rowData.StudentKey) {
        this.studentKey = rowData.StudentKey;
      }
      let moduleInfo = {};
      if (((rowData.ApplyClassKey > 0 && !rowData.sourceData) || (rowData.sourceData && rowData.sourceData.length == 1)) &&
        !rowData.UnitKey// 当UnitKey为true时 为课单详情，不需要预置课程
      ) { // 确定唯一课程，进去
        moduleInfo = {
          routerName: this.$route.name,
          routertitle: this.$route.meta.title,
          moduleName: 'studentArrangeCourseByCourseName',
          name: '按课程排课',
          data: {
            studentID: this.studentKey,
            courseNameID: rowData.ApplyClassKey,
            courseName: rowData.ApplyClassKeyValue
          },
          callBack: {
            afterSucces: () => {
              this.upSelectStudentOrApplyClass();
              this.showDetailsTable();
            }
          }
        };
      } else {
        moduleInfo = {
          routerName: this.$route.name,
          routertitle: this.$route.meta.title,
          moduleName: 'studentArrangeCourseByCourseName',
          name: '按课程排课',
          data: {
            studentID: this.studentKey
          },
          callBack: {
            afterSucces: () => {
              this.upSelectStudentOrApplyClass();
              this.showDetailsTable();
            }
          }
        };
      }
      this.$dialog.open(this, moduleInfo);
    },
    // 微信关注解绑 WeChatUnbind
    wechatSubscribeUnbind (rowData) {
      console.log(rowData, '微信关注解绑');
      layer.confirm('请问是否解绑?', {
        btn: [{
          name: '确认',
          callBack: () => {
            WeChatUnbind(rowData.CustKey).then(
              result => {
                layer.alert('解绑成功');
                this.upSelectStudentOrApplyClass();
              }, error => {
              layer.alert(error.msg);
            });
            layer.close();
          }
        }
        ]
      });
    },
    // 学生报课
    studentApplyCourse (rowData) {
      if (rowData.StudentKey) {
        this.studentKey = rowData.StudentKey;
      }
      if (this.tableColumnsType) { // 学生级
        this.doStudentApplyCourseClick(rowData);
      } else { // 课程级
        this.doAfterApplyCourseClick(rowData);
      }
    },
    registerBusEvent () {
      // 修改学生档案的学生信息成功后触发回调 SelectedSummaryItem
      this.$bus.on(this, 'AfterStudentUpdate', studentKey => {
        if (studentKey > 0) {
          this.upSelectStudentOrApplyClass();
        }
      });
      this.$bus.on(this, 'AfterStudentApplyCourse', (applyCourseInfo, studentID) => {
        this.upSelectStudentOrApplyClass();
        this.showDetailsTable();
      });
      this.$bus.on(this, 'AfterArrangeForSingleStudent', (type, courseID, studentID) => {
        this.upSelectStudentOrApplyClass();
        this.showDetailsTable();
      });
      this.$bus.on(this, 'AfterCourseNameApplyCourse', (data) => {
        this.upSelectStudentOrApplyClass();
        this.showDetailsTable();
      });
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'studentManagementReport') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'studentManagementReport') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.studentApplyCourseReportDialogFactory) {
        this.$refs.studentApplyCourseReportDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.studentManagementCommonDialogFactory) {
        this.$refs.studentManagementCommonDialogFactory.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  },
  computed: {}
};
</script>
