import { render, staticRenderFns } from "./leave-school-warning-item.vue?vue&type=template&id=33c06d47&scoped=true"
import script from "./leave-school-warning-item.vue?vue&type=script&lang=js"
export * from "./leave-school-warning-item.vue?vue&type=script&lang=js"
import style0 from "./leave-school-warning-item.vue?vue&type=style&index=0&id=33c06d47&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33c06d47",
  null
  
)

export default component.exports