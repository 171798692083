<template>
  <!-- 设置补课处理规则-->
  <div class="saturationCourse_si">
    <div class="content_marginRL content_marginTop_30">
      <div class="form_info_edit form_info_list pr">
        <input-select :formTitle="'补课可追溯时长'"
                      :id="'type'"
                      :name="'name'"
                      :required="false"
                      :readonly="false"
                      :dataList="timeStatisticsList"
                      :selectedInfo="vTimeStatisticsList"
                      @selectedItem="chgSearchTimeStatisticsValue"></input-select>

        <input-switch v-model="conciseMode.SetKey"
                      :readonly="false"
                      style="padding-right: 0px;"
                      :formTitle="'学生补课简洁模式'"
                      :required="false"
                      :showInputValue="false"
                      :className="'no_border'"
                      @change="change"></input-switch>
      </div>
      <div class="absence_from_duty">设置允许不处理的缺勤课次，可更简洁</div>
      <div class="saturation_course_content"
           v-if="conciseMode.SetKey == 1">
        <!-- 列表名 -->
        <div class="tabulation_title">
          <div class="title_name">课名</div>
          <div class="title_name"
               style="    margin-left: 80px;">缺勤未处理几次，不再提示</div>
        </div>
        <!-- 列表内容 -->
        <div class="tabulation_content">
          <div class="table_row"
               v-for="(item,index) in MakeupCourseSetList"
               :key="index"
               @click="saturationClick(item)">
            <div class="row_name"
                 :title="item.CourseKeyValue">{{ item.CourseKeyValue }}</div>
            <div class="row_name"
                 :title="item.MinNotDealCount+'课次/周'">{{Number(item.MinNotDealCount)}}次/月</div>
            <div class="row_name">く</div>
          </div>
        </div>
      </div>

    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
    <custom-dialog title="修改数据"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowUpdateNum"
                   :before-close="closeUpdateNumPopup">
      <update-setting :formTitle="'修改数据'"
                      :dateInfo="dateInfo"
                      @updataCourseInfo="updataCourseInfo"
                      @afterSuccess="afterSuccess">
      </update-setting>
    </custom-dialog>
  </div>

</template> 

<script>
import updateSetting from './update-setting';
import { GetMakeUpSetting, UpdateMakeupSet } from '../../../API/workbench';
export default {
  props: {
  },
  data () {
    return {
      isShowUpdateNum: false,
      dateInfo: {},
      timeStatisticsList: [
        { type: 1, name: '近1个自然月' },
        { type: 2, name: '近2个自然月' },
        { type: 3, name: '近3个自然月' }
      ],
      MakeupCourseSetList: [], // 查询课程数据
      TraceabilityTimeType: {
        SetContent: 3,
        SetKey: 0,
        SetKeyValue: '关闭'
      }, // 补课可追溯时长
      conciseMode: {
        SetContent: '',
        SetKey: 0,
        SetKeyValue: '关闭'
      }, // 学生补课简洁模式
      mendianInfo: null
    };
  },

  components: {
    updateSetting
  },
  computed: {
    vTimeStatisticsList () {
      return {
        type: this.TraceabilityTimeType.SetContent == 1 ? 1 : this.TraceabilityTimeType.SetContent == 2 ? 2 : this.TraceabilityTimeType.SetContent == 3 ? 3 : '',
        name: this.TraceabilityTimeType.SetContent == '3' ? '近3个自然月' : this.TraceabilityTimeType.SetContent == '2' ? '近2个自然月' : this.TraceabilityTimeType.SetContent == '1' ? '近1个自然月' : ''
      };
    }
  },
  watch: {
  },
  created () {
    this.getGetMakeUpSetting();
  },
  mounted () {
  },
  methods: {
    cancelClick () {
      this.$emit('cancelClick');
    },
    confirmClick () {
      UpdateMakeupSet(this.TraceabilityTimeType.SetContent, this.conciseMode.SetKey, this.MakeupCourseSetList)
        .then(result => {
          console.log(result.data, 'UpdateSchoolRoomSetting');
          layer.alert('修改成功');
          this.$emit('afterSuccess');
        }, err => {
          layer.alert(err.msg);
        });
    },
    closeUpdateNumPopup () {
      this.isShowUpdateNum = false;
    },
    chgSearchTimeStatisticsValue (obj) {
      this.TraceabilityTimeType.SetContent = obj.type;
      console.log(obj, 'chgSearchTimeStatisticsValue', this.timeRangeInfo);
    },
    saturationClick (item) {
      console.log(item, ' saturationClick item999');
      this.dateInfo = item;
      this.isShowUpdateNum = true;
    },
    change () {
      console.log('change', this.conciseMode);
    },
    getGetMakeUpSetting () {
      GetMakeUpSetting().then(result => {
        this.MakeupCourseSetList = result.data.MakeupCourseSetList;
        this.TraceabilityTimeType = result.data.TraceabilityTimeType;
        this.conciseMode = result.data.conciseMode;
        console.log(result.data, 'GetMakeUpSetting');
      }, err => {
        layer.alert(err.msg);
      });
    },
    afterSuccess () {
      this.isShowUpdateNum = false;
    },
    updataCourseInfo (item, Count) {
      this.MakeupCourseSetList.forEach(o => {
        if (o.CourseKey == item.CourseKey) {
          o.MinNotDealCount = Count;
        }
      });
      console.log(item, Count, 'item,Count  MakeupCourseSetList', this.MakeupCourseSetList);
      this.isShowUpdateNum = false;
    }
  }
};
</script>

<style scoped>
/*  */
.saturation_course_content {
  width: 387px;
  margin: 0 auto;
  height: 320px;
  margin-bottom: 20px;
  border: 1px solid rgba(236, 236, 236, 1);
}
.tabulation_title {
  display: flex;
  background: #fafafa;
  height: 31px;
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  line-height: 31px;
}
.title_name {
  margin-left: 20px;
  color: rgba(151, 156, 160, 1);
}
.table_row {
  display: flex;
  height: 40px;
  line-height: 40px;
}
.table_row:hover {
  cursor: pointer;
  color: rgba(52, 152, 219, 1) !important;
}
.row_name {
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-weight: 400;
}
.row_name:nth-of-type(1) {
  margin-left: 20px;
  width: 105px;
}
.row_name:nth-of-type(2) {
  width: 200px;
  margin-left: 4px;
}
.row_name:nth-of-type(3) {
  margin-left: 5px;
  transform: rotate(180deg);
}
.tabulation_content {
  overflow-y: auto;
  height: 287px;
}
.absence_from_duty {
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #999999;
  margin-left: 30px;
  position: relative;
  margin-bottom: 20px;
}
.absence_from_duty::before {
  content: '*';
  color: #ff6c67;
  position: absolute;
  left: -10px;
  top: 4px;
  line-height: 18px;
  display: block;
}
</style>