<template>
  <div>
    <div class="payment_statistics_bottom clearfix">
      <div class="payment_statistics_bottom_select flex">
        <div class="course_table_fixed_nav"
             style="width:100%">
          <div class="course_table_fixed_nav_list "
               v-if="IsMTeaKey == 0 || IsMTeaKey == 3">
            <div class="course_table_tex"
                 v-if="IsMTeaKey == 0">全部</div>
            <div class="course_table_tex"
                 v-if="IsMTeaKey == 3">全部组合课</div>
            <div class="course_table_num">{{allNum.AllNum}}</div>
          </div>
          <div class="course_table_fixed_nav_list">
            <div class="course_table_tex">学生</div>
            <div class="course_table_num">{{allNum.StudentNum}}</div>
          </div>
          <div class="course_table_fixed_nav_list">
            <div class="course_table_tex">日月年卡</div>
            <div class="course_table_num">{{allNum.TermNum}}</div>
          </div>
          <div class="course_table_fixed_nav_list">
            <div class="course_table_tex">课时卡/剩余课时</div>
            <div class="course_table_num">{{Math.floor(allNum.HourNum) }}/{{Number(allNum.CurrentSum).toFixed(1) }}</div>
          </div>
          <div class="course_table_fixed_nav_list">
            <div class="course_table_tex">剩余价值</div>
            <div class="course_table_num">{{Number(allNum.ValueAmountSum).toFixed(2)}}</div>
          </div>
        </div>
      </div>
      <div v-if="!isBatchDistribute"
           class="course_statistics_table_search flex">
        <seleted-option :searchObj="searchObj"
                        :IsMTeaKey="IsMTeaKey"
                        @changeSearchObj="updateData"
                        style="margin-right:10px"></seleted-option>
        <el-select v-model="selectBuyDate"
                   style="width:350px;border: 1px solid #ececec;min-width: 105px !important;"
                   placeholder="请选择"
                   @change="typeEventBuyDate">
          <el-option v-for="item in optionsBuyDate"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
        <date-range ref="saveDate"
                    :searchObj="searchObj"
                    :fromTitle="''"
                    @changeDate="changeSaveDate"
                    style="margin-right:10px"></date-range>
        <div class="table_select_box"
             style="margin-right:10px">
          <div class="table_select_title">状态</div>
          <el-select v-model="select"
                     style="width:175px"
                     placeholder="请选择"
                     @change="typeEvent">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="width_small course_statistics_search">
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入姓名、姓名首字母、手机号、课单ID'"
                                    :clearAll="true"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="search"></input-search-contain-btn>

          <!-- <div class="mendian_backstage_search">
              <div class="btn_hover_bg_blue search_btn "
                  style="margin-right:20px;width:auto;padding: 0 10px;"
                  @click.stop="openAttenDetail"
                  v-if="IsMTeaKey==1">课消明细</div>
              <div class="btn_hover_bg_blue search_btn "
                  style="margin: 0;"
                  @click.stop="doExportInfoClick">导出</div>
            </div> -->

        </div>
        <!-- <function-btn 
                          :item="addbtn"
                          @clickItem="batchDelay"
                          :className="'class_btn_delay btn_fixed_blue btn_hover_bg_blue'">
          </function-btn> -->
        <div>
          <function-btn :item="addbtn"
                        @clickItem="showisBatchDistribute"
                        :className="'class_btn_delays search_btn btn_hover_bg_blue'">
          </function-btn>
        </div>
        <div>
          <function-btn :item="addbtn2"
                        @clickItem="setCourse"
                        :className="'class_btn_setcount search_btn btn_hover_bg_blue'">
          </function-btn>
        </div>
      </div>
      <div v-if="isBatchDistribute"
           style="margin:14px 0px">
        <div class="mendian_backstage_search">
          <div class="pitch_count pr"
               style="line-height: 36px;margin-left:10px">已选中 {{courseList.length}} 项
            <div class="remove_db_btn"
                 @click="toggleSelection"></div>
          </div>
          <div class="tsjj">
            <function-btn :item="addbtn"
                          @clickItem="batchDelay"
                          :className="'class_btn_delay search_btn btn_hover_bg_blue'">
            </function-btn>
          </div>

        </div>
      </div>
    </div>

    <div class="course_statistics_table_box table_list_content">
      <table-list ref='multipleTable'
                  class="summarizing_list"
                  :key="tableKey"
                  :tableData="vdataReportList"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :tableColumns="tableCourseColumns"
                  :isSearchText="false"
                  :isNeedInnerScroll="false"
                  :defaultSort="defaultSort"
                  :queryParams="searchObj"
                  :footerContent="vFooterContent"
                  :isShowCheckBox="true"
                  :isStudentKey='false'
                  @loadTableData="getDataList"
                  @changeCheckBoxFun="clickCheckBox"></table-list>
    </div>
    <div>
      <dialog-factory ref="studentApplyCourseReportDialogFactory"
                      :key="'studentManagementReport'"
                      :routerName="'studentManagementReport'"></dialog-factory>

      <dialog-factory-common ref="studentManagementCommonDialogFactory"></dialog-factory-common>
    </div>
    <custom-dialog title="批量延期"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowCourseBatchDelayDialog"
                   :before-close="closeCourseBatchDelayDialog">
      <course-batch-delay :dataInfo="courseList"
                          :isReadonly="true"
                          @submit="courseBatchDelaySubmit"
                          @closeDialog="closeCourseBatchDelayDialog"></course-batch-delay>
    </custom-dialog>
    <custom-dialog title="设置课时数"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowCourseCountDialog"
                   :before-close="closeCourseCountDialog">
      <course-count :isReadonly="true"
                    @submit="courseCountSubmit"
                    @closeDialog="closeCourseCountDialog"></course-count>
    </custom-dialog>
    <custom-dialog title="合并课单"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowrelavantCourseFormDialog"
                   :before-close="closerelavantCourseFormDialog">
      <relavantCourseForm :isReadonly="true"
                          :XYKDKey="relavantCourseData.XYKDKey"
                          :StudentKey="relavantCourseData.StudentKey"
                          :studentName="relavantCourseData.StudentKeyValue"
                          @afterSuccess="search"
                          @closeDialog="closerelavantCourseFormDialog"
                          @cancelClick="closerelavantCourseFormDialog"></relavantCourseForm>
    </custom-dialog>
  </div>
</template>
<script>
import dialogFactory from '../../common/dialog-factory';
import debtCourseHourseDetails from '../../pop-up-menu/sign-up-payment/debt-course-hourse-details';
// import list from '../../class-spend-statistics-analyze/components/table';
import tableList from '../../common/table-list/index';
import seletedOption from './seleted-option';
import courseBatchDelay from './course-batch-delay';
import courseCount from './course-count';
import relavantCourseForm from './relavant-course-form';
import inputSearchContainBtn from '../common/input-search-contain-btn';
import { classMessageSearchTeachingPerformanceForCourse, getStudentRelatedMsg, updateCourseBatchDelay, ModifySetting } from '../../../API/workbench';
import courseBillItem from '../../student/student-file/components/class-hour-spend/course-bill-item';
import batchDistributionBox from '../upsell-management/list-components/batch-distribution-box';
export default {
  data () {
    return {
      isShowrelavantCourseFormDialog: false,
      relavantCourseData: {},
      isShowCourseCountDialog: false,
      seletedCourseInfo: {},
      studentDetail: {},
      isBatchDistribute: false,
      isShowCourseBatchDelayDialog: false,
      courseList: [],
      addbtn: {
        name: '批量延期',
        type: '',
        ModulePowerKey: 139
      },
      addbtn1: {
        name: '延期',
        type: '',
        ModulePowerKey: 139
      },
      addbtn2: {
        name: '设置',
        type: '',
        ModulePowerKey: 139
      },
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 15,
        searchText: '',  // 搜索条件：模糊匹配单号、学生姓名、手机号
        // orderBy: '',
        Type: this.IsMTeaKey,  // 类型 0全部 1单课 2套餐  3组合 4.课时不足
        TaoCanKey: '',          // 套餐ID
        TaoCanKeyVal: '',          // 套餐
        ApplyClassTypeKey: '', // 适用课程id
        ApplyClassTypeKeyVal: '',
        // sequence: 'DESC',
        startTime: '',
        endTime: '',
        // startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        // endTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0)),
        // this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate() - 1)))),
        // IsExportKey: 0,
        StatusKey: 1, // 状态
        DataType: 1
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 55,
          sortable: false,
          align: 'left',
          type: 'number-item',
          className: 'table_index_gray'
        },
        {
          label: '学生',
          prop: 'StudentKeyValue',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'student',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '课单编号',
          prop: 'NumberName',
          minWidth: 160,
          sortable: false,
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'text-item',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: (rowData) => {
              console.log(rowData, '点击...课单编号');
              this.doAttenCourseSpendDetails(rowData);
            }
          }
        },
        {
          label: '套餐',
          prop: 'TaoCanKeyValue',
          minWidth: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'text-item'
        },
        {
          label: '适用课程',
          prop: 'ApplyCorName',
          minWidth: 90,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '购买数量',
          prop: 'NumCount',
          minWidth: 90,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          eventName: this.$initJson.baseFunctionBtnName.read
        },
        {
          label: '实付金额',
          prop: 'ActualPayAmount',
          minWidth: 90,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'class-hour'
        },
        {
          label: '消耗数量',
          prop: 'SpendCount',
          minWidth: 90,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '调整数量',
          prop: 'AdjustCount',
          sortable: false,
          minWidth: 90,
          align: 'left',
          isShow: true,
          type: 'text-item'

        },
        {
          label: '失效数量',
          prop: 'ExpireCount',
          sortable: false,
          minWidth: 100,
          align: 'left',
          isShow: true,
          type: 'text-item'

        },
        {
          label: '当前数量',
          prop: 'CurrentCount',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '价值金额',
          prop: 'ValueAmountText',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },

        {
          label: '生效日期',
          prop: 'StartTime',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '失效日期',
          prop: 'ExpireTime',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '状态',
          prop: 'StatusKeyValue',
          type: 'text-item',
          width: 80,
          align: 'center'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          isShow: this.IsMTeaKey == 4,
          extend: {
            tableBtnGroup: [
              {
                name: '合并',
                extend: {
                  click: (rowData) => {
                    this.showRelavantForm(rowData)
                  },
                  ModulePowerKey: '139'
                }
              }
            ]
          }
        }

      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false,
      allNum: {},
      options: [{
        value: '0',
        label: '全部'
      }, {
        value: '1',
        label: '正常'
      }, {
        value: '2',
        label: '用完'
      }, {
        value: '3',
        label: '过期'
      }, {
        value: '4',
        label: '转让'
      }, {
        value: '5',
        label: '退课'
      }],
      select: '正常',
      selectBuyDate: '购买日期',
      optionsBuyDate: [{
        value: '1',
        label: '购买日期'
      }, {
        value: '2',
        label: '生效日期'
      }, {
        value: '3',
        label: '失效日期'
      }]
    };
  },
  components: {
    tableList,
    debtCourseHourseDetails,
    seletedOption,
    inputSearchContainBtn,
    dialogFactory,
    courseBatchDelay,
    courseBillItem,
    batchDistributionBox,
    courseCount,
    relavantCourseForm
  },
  props: {
    searchText: String,
    seletedDate: {
      type: String,
      default: ''
    },
    IsMTeaKey: {
      type: Number,
      default: 1
    }
  },
  created () {
    console.log("触发更新", this.searchText)
    // this.searchObj.searchText=this.searchText
    // if(this.$route.params&&this.$route.params.data&&this.$route.params.data.dataInfo){
    //   let newSearchText=this.$route.params.data.dataInfo.searchText||''
    //   this.searchObj.searchText=newSearchText
    //   this.$route.params.data.dataInfo.searchText=''
    //   console.log("init searchText2222",this.searchObj.searchText)
    // }

  },
  activated () {
    if (this.$route.params && this.$route.params.data && this.$route.params.data.dataInfo) {
      let newSearchText = this.$route.params.data.dataInfo.searchText || ''
      this.searchObj.searchText = newSearchText
      this.searchObj.StatusKey = 0
      this.select = '全部';
      this.$route.params.data.dataInfo.searchText = ''
      setTimeout(o => {
        this.getDataList();
      }, 100)

      console.log("activated searchText2222", this.searchObj.searchText)
    }

  },
  watch: {
    IsMTeaKey: {
      handler (val) {
        this.searchObj.Type = this.IsMTeaKey;
        this.searchObj.pageIndex = 0;
        console.log(val, '11111111111111');
        if (val == 1) {
          this.searchObj.TaoCanKey = '';
        } else if (val == 2) {
          this.searchObj.ApplyClassTypeKey = '';
        } else if (val == 3) {

        }
        this.isBatchDistribute = false;
        this.toggleSelection();
        this.getDataList();
      }
    }
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // let info = o.ClassTime.substring(0, 10) + ',' + o.ClassTime.substring(11);
        // o.classTimeObj = info.split(',');
        if (o.UnitKey == 5) {
          o.CurrentCount = Number(o.CurrentCount) == '0' ? '--' : Number(o.CurrentCount).toFixed(1) + this.numToCount(o.UnitKey);
          o.SpendCount = parseFloat(o.SpendCount) == '0' ? '--' : parseFloat(o.SpendCount) + this.numToCount(o.UnitKey);
        } else {
          o.CurrentCount = Number(o.PeriodCount) == '0' ? '--' : Number(o.PeriodCount) + '天';
          o.SpendCount = parseFloat(o.SpendCount) == '0' ? '--' : parseFloat(o.SpendCount) + '天';
        }
        o.StartTime = o.StartTime.substring(0, 10);
        if (o.StartTime == '1900-01-01') {
          o.StartTime = '-';
        }
        o.ValueAmountText = Number(o.ValueAmount) == 0 ? '--' : Number(o.ValueAmount).toFixed(2)
        o.ExpireTime = o.ExpireTime.substring(0, 10);
        o.TaoCanKeyValue = o.TaoCanKeyValue == '不使用' ? '--' : o.TaoCanKeyValue;
        o.NumCount = parseFloat(o.NumCount) == '0' ? '--' : parseFloat(o.NumCount) + this.numToCount(o.UnitKey);

        o.AdjustCount = parseFloat(o.AdjustCount) == '0' ? '--' : parseFloat(o.AdjustCount) + this.numToCount(o.UnitKey);
        o.ExpireCount = parseFloat(o.ExpireCount) == '0' ? '--' : parseFloat(o.ExpireCount) + this.numToCount(o.UnitKey);

        // o.ClassHourDebtCount = Number(o.ClassHourDebtCount) || 0;
        // o.ClassAwayAmountCount = Number(o.ClassAwayAmountCount || 0);
        // o.AttenceTime = o.AttenceTime.substring(5, 16);
        // this.$set(o, 'OLAPKey', o.CourseNameKey);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterContent () {
      return '共' + this.totalNum + '条数据';
    },
    vIsMTeaKey () {
      return this.IsMTeaKey;
    }
  },
  mounted () {
    this.$bus.on(this, 'wsAttendanceOrArrange', (data) => {
      console.log('触发wsAttendanceOrArrange');
      this.getDataList();
    });
    // this.searchObj.Type = this.IsMTeaKey;
    // console.log( this.IsMTeaKey,"1111111111111111");
    if (this.seletedDate) {
      this.searchObj.startTime = this.seletedDate;
      this.searchObj.endTime = this.seletedDate;
    }
    this.getDataList();
  },
  methods: {
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    // 状态切换
    typeEvent (item) {
      console.log(item, 'item', this.searchObj.Type, 'this.searchObj.type', this.searchObj, 'this.searchObj');
      this.searchObj.StatusKey = item;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    typeEventBuyDate (item) {
      this.searchObj.DataType = item;
      this.getDataList();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.ApplyClassTypeKey = '';
      this.selectBuyDate = '购买日期';
      this.searchObj.DataType = 1;
      this.searchObj.TaoCanKey = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.StatusKey = 0; // 状态
      this.select = '全部';
      this.$refs.saveDate.resetTime();
      this.getDataList();
    },
    // 适用课程切换
    updateData (item) {
      console.log(this.searchObj, 'updateData', item);

      this.searchObj.pageIndex = 0;
      this.getDataList();
    },

    // 外围调用,根据指定老师课名课程
    // searchDataForKey (data, seachDataObj) {
    //   this.searchObj.TeacherKey = data.TeacherKey;
    //   this.searchObj.TeacherKeyValue = data.TeacherKeyValue;
    //   this.searchObj.GradeKey = data.GradeClassKey;
    //   this.searchObj.CourseKey = data.CourseNameKey;
    //   this.searchObj.GradeKeyValue = data.GradeClassKeyValue;
    //   this.searchObj.CourseKeyValue = data.CourseNameKeyValue;
    //   this.searchObj.startTime = seachDataObj.startTime;
    //   this.searchObj.endTime = seachDataObj.endTime;
    //   // this.searchObj = this.$utils.parseJson(this.searchObj);
    //   this.getDataList();
    // },
    // 外围调用. 更新日期查询
    searchDataForDate (item, date) {
      console.log('searchDataForDate', date);
      // this.searchObj.TeacherKey = item.TeacherKey;
      // this.searchObj.TeacherKeyValue = item.TeacherKeyValue;
      // this.searchObj.GradeKey = 0;
      // this.searchObj.GradeKeyValue = '全部';
      this.searchObj.CourseKey = 0;
      this.searchObj.CourseKeyValue = '全部';
      // this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(date).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      let seletedYearMonth = Date.new(date).getFullYear() + ' ' + (Date.new(date).getMonth() + 1);
      let nowYearMonth = Date.new().getFullYear() + ' ' + (Date.new().getMonth() + 1);
      if (seletedYearMonth != nowYearMonth) {
        this.searchObj.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new(date)), 'yyyy-MM-dd');
      } else {
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0));
      }
      this.getDataList();
    },
    search () {
      // this.searchObj.TeacherKey = 0;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },

    getDataList (queryParams) {
      // if (queryParams) {
      //   if (queryParams.orderBy == 'classTimeObj') {
      //     queryParams.orderBy = 'ClassTime';
      //   }
      //   Object.assign(this.searchObj, queryParams);
      // }
      // 课单查询统计列表
      classMessageSearchTeachingPerformanceForCourse(this.searchObj).then(result => {
        console.log(this.searchObj, 'this.searchObj');
        console.log(result, 'result');
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.totalAbnormalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        this.allNum = result.data.Stat;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData) {
      console.log(rowData, 'doAfterTableBtnClick');

      this.seletedCourseInfo = rowData;
      let moduleInfo = {
        name: '安排考勤',
        moduleName: 'attendanceArrange',
        routerName: this.$route.name,
        data: { scheduleCourseKey: this.seletedCourseInfo.ScheduleCourseKey }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    },

    // 关闭弹窗页面
    closeDetails () {
      this.isDebtCourseHoursDetailsShow = false;
    },
    // 确认欠课时详情
    confirmDebtCourseHoursDetails (error) {
      if (error.code == 0) {
        this.getDataList();
        this.closeDetails();
      }
    },
    showRelavantForm (rowData) {
      console.log('rowData', rowData)
      this.relavantCourseData = rowData
      this.isShowrelavantCourseFormDialog = true;
    },
    // 转换单位 num-> 课时 单位2-年;3-月;4-日;5-课时
    numToCount (v) {
      switch (v) {
        case '2':
          return '年';
        case '3':
          return '月';
        case '4':
          return '日';
        case '5':
          return '课时';
      }
    },

    // 考勤课消详情dialog
    doAttenCourseSpendDetails (item) {
      console.log(item, '考勤课消详情dialog');
      this.$dialog.open(this, {
        name: '考勤课消详情',
        routerName: this.$route.name,
        moduleName: 'attenCourseSpendDetails',
        ModulePowerKey: '',
        data: { dataInfo: { TableID: item.XYKDKey, SourceBillKey: item.SourceBillKey, RN: item.RN, StudentKey: item.StudentKey } },
        callBack: {
          afterSucces: () => {
            console.log('考勤课消详情callBack');
          }
        }
      });
    },
    // 批量延期 选中一行
    clickCheckBox (lists, rowData) {
      console.log('clickCheckBox', lists, rowData);
      if (!this.isBatchDistribute && lists.length > 0) {
        this.isBatchDistribute = true;
      } else if (lists.length == 0) {
        console.log('为零');
        this.isBatchDistribute = false;
      }
      console.log('isBatchDistribute', this.isBatchDistribute);
      let arr = lists.filter((item) => { return item.OLAPKey > 0; });
      this.courseList = lists;
    },
    toggleSelection (rows) {
      // console.log(rows)
      // if (rows) {
      //   rows.forEach(row => {
      //     this.$refs.multipleTable.$refs.multipleTable.toggleRowSelection(row);
      //   });
      // } else {
      //   this.$refs.multipleTable.$refs.multipleTable.clearSelection();
      //   this.$refs.multipleTable.checkBoxList=[]
      // }
      this.$refs.multipleTable.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.checkBoxList = [];
      this.courseList = [];
      this.isBatchDistribute = false;
    },
    // 批量延期按钮
    batchDelay () {
      this.isShowCourseBatchDelayDialog = true;
    },
    setCourse () {
      this.isShowCourseCountDialog = true;
    },
    closeCourseBatchDelayDialog () {
      this.isShowCourseBatchDelayDialog = false;
    },
    closeCourseCountDialog () {
      this.isShowCourseCountDialog = false;
    },
    closerelavantCourseFormDialog () {
      this.isShowrelavantCourseFormDialog = false;
    },

    relavantCourseFormSubmit () {

    },
    // 获取选中数组中的
    getStuKey () {
      var arr = '';
      for (let i in this.courseList) {
        console.log(this.courseList[i].StudentKey);
        console.log(i + 1, this.courseList.length, (i + 1) == this.courseList.length);
        if ((Number(i) + 1) == this.courseList.length) {
          console.log(111111111111111111111);
          arr += this.courseList[i].StudentKey;
        } else {
          arr += this.courseList[i].StudentKey + ',';
        }
      }
      return arr;
    },
    getXYKDKey () {
      var arr = [];
      for (let i in this.courseList) {
        console.log(this.courseList.length);
        arr.push(this.courseList[i].XYKDKey);
      }
      return arr;
    },
    showisBatchDistribute () {
      this.isBatchDistribute = true;
    },
    courseBatchDelaySubmit (data) {
      console.log('提交数据', data);
      var TableIDList = this.getXYKDKey();
      updateCourseBatchDelay(data, TableIDList).then(result => {
        this.isShowCourseBatchDelayDialog = false;
        layer.alert("延期成功");
        // this.$message({
        //   message: '延期成功',
        //   type: 'success'
        // });
        this.toggleSelection();
        this.courseList = [];
        this.isBatchDistribute = false;
        this.getDataList();
      }, error => {
        layer.alert(error.msg);
        // this.$message({
        //   message: error.msg,
        //   type: 'warning'
        // });
      }).catch(error => {
        layer.alert(error.msg);
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
        // this.$message({
        //   message: error.msg,
        //   type: 'warning'
        // });
        this.isShowCourseBatchDelayDialog = false;
        this.toggleSelection();
        this.courseList = [];
        this.isBatchDistribute = false;
        this.getDataList();
      });
    },
    courseCountSubmit (data) {
      ModifySetting(data).then(result => {
        this.isShowCourseCountDialog = false;
        layer.alert('设置成功');
      }, error => {
        layer.alert(error.msg);
      })
    }
  }
};
</script>
<style scoped>
.class_btn_setcount {
  height: 36px;
  width: 80px !important;
  align-items: center;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
}
.class_btn_delay {
  height: 36px;
  width: 100px !important;
  align-items: center;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  background: #3498db url(../../../../public/image/batch_distribution_icon.png)
    no-repeat left 10px center;
  background-size: 16px;
  padding-left: 20px;
}
.class_btn_delays {
  height: 36px;
  width: 90px;
  align-items: center;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
}
.remove_db_btn {
  background-image: url(../../../../public/image/dialog_del_icon.png);
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 8px;
  margin-top: 3px;
}
.tsjj {
  margin-left: 20px;
}
.course_statistics_table_search .mendian_backstage_date {
  margin-right: 0px;
  min-width: 250px;
}
.mendian_backstage_search >>> .search_box {
  width: 290px !important;
  margin-right: 30px !important;
  min-width: 290px !important;
}
/* 
.payment_statistics_bottom .course_statistics_search.width_small .search_box{
  width: 164px!important;
  min-width: 164px!important;
} */
</style>