<template>
  <div class="" style="margin-top：10px">
    <!-- <div class="from_title">
      <span>{{dataInfo.title}}说明</span>

    </div> -->
    <div class="from_introduce_text" style="margin-top:20px"
         v-show="groupStepNum==1">
      <div>
        · 可设置虚拟团数，虚拟团拼团成功，系统自动补齐，保持虚拟团数不变。
      </div>
      <div>
        · 用户可参团或开团，降低成团门槛，提高报名率。参团成功即领取优惠券。
      </div>
      <div>
        · 优惠券在学生微信账户“我的-我的活动”，报名时使用优惠券，系统可自动减免。
      </div>
    </div>

    <div style="margin-top：10px">
      <div class="from_list"
           v-if="vAwardCount>1">
        <div class="table_select_box"
             style="margin-right: 0; min-width: 150px">
          <div class="from_list_title"
               style="padding: 0;margin-right: 12px;">奖励类型</div>
          <el-select :value="vTypeKeyValue"
                     placeholder="请选择"
                     style="width: 110px"
                     ref="resetText"
                     @change="selectType">
            <el-option v-for="item in vTypeList"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item">
            </el-option>
          </el-select>
          <div class="from_list_content from_list_select "
               style="margin-left: 30px;"
               v-if="typeKey==1">
            <div class="table_select_title"
                 style="width:68px;">
              适用于
            </div>
            <div class="from_list_input from_list_input_three el-icon-eleme pr"
                 @click.stop="showCourseNameListDialog">
              <input type="text"
                     readonly
                     style="width: 286px;"
                     :class="!CouponInfo.groupSetting.target_1_A.fanhour_keyvalues?'font_gray':'font_blue'"
                     placeholder="请选择"
                     v-model="CouponInfo.groupSetting.target_1_A.fanhour_keyvalues">
            </div>
          </div>
        </div>

      </div>
      <div class="from_list from_list_content from_list_select "
           v-if="vAwardCount==1&&typeKey==1">
        <div class="from_list_content">
          <div class="table_select_title">
            适用于
          </div>
          <div class="from_list_input from_list_input_three el-icon-eleme pr"
               @click.stop="showCourseNameListDialog">
            <input type="text"
                   readonly
                   :class="!CouponInfo.groupSetting.target_1_A.fanhour_keyvalues?'font_gray':'font_blue'"
                   placeholder="请选择"
                   v-model="CouponInfo.groupSetting.target_1_A.fanhour_keyvalues">
          </div>

        </div>

      </div>
      <!--  vcommanderAward.returnClassHour.isOpen||vcommanderAward.returnPrize.isOpen -->
      <div v-if="groupStepNum>=1"
           class="from_list_group_ul">
        <div class="from_list"
             v-if="groupStepNum>=1">
          <div class="from_list_title">
            {{groupStepNum==1?'成团人数':'1阶团'}}
          </div>
          <!-- 返现 -->
          <div class="from_list_content"
               v-if="typeKey==0">
            <form-content-input :formTitle="'满 '"
                                :unintTitle="'人'"
                                :decimalPlaces="0"
                                v-model="CouponInfo.groupSetting.target_1"
                                :formPlaceholder="'例如: 3'"
                                :required="true"
                                :isNumber="true"
                                :supportInit="true"
                                :readonly="!settingInfo.groupSetting.targetAward.oneGroupNumberLimit.isEdit&&groupStepNum==1"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 团长 '"
                                :unintTitle="vGroupLeaderUnit"
                                v-model="CouponInfo.groupSetting.target_1_A.fanxian_amount"
                                :formPlaceholder="'例如: 20'"
                                :isNumber="true"
                                :supportInit="true"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'团员'"
                                :unintTitle="vGroupLeaderUnit"
                                :isNumber="true"
                                :supportInit="true"
                                v-model="CouponInfo.groupSetting.target_1_B.fanxian_amount"
                                :formPlaceholder="'例如: 10'"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <!-- 团长奖励关闭 -->
            <form-content-input v-if="!settingInfo.groupSetting.targetAward.commanderAward.isOpen||!settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 '"
                                :unintTitle="vGroupLeaderUnit"
                                :isNumber="true"
                                :supportInit="true"
                                v-model="CouponInfo.groupSetting.target_1_A.fanxian_amount"
                                :formPlaceholder="'例如: 10'"
                                :inputClass="'from_list_input_four'"></form-content-input>

          </div>
          <!-- 课时 -->
          <div class="from_list_content"
               v-if="typeKey==1">
            <form-content-input :formTitle="'满 '"
                                :unintTitle="'人'"
                                :decimalPlaces="0"
                                v-model="CouponInfo.groupSetting.target_1"
                                :formPlaceholder="'例如: 3'"
                                :required="true"
                                :isNumber="true"
                                :supportInit="true"
                                :readonly="!settingInfo.groupSetting.targetAward.oneGroupNumberLimit.isEdit&&groupStepNum==1"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 团长 '"
                                :unintTitle="vGroupLeaderUnit"
                                v-model="CouponInfo.groupSetting.target_1_A.fanhour_num"
                                :formPlaceholder="'例如: 2'"
                                :isNumber="true"
                                :decimalPlaces="1"
                                :supportInit="true"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'团员'"
                                :unintTitle="vGroupLeaderUnit"
                                :isNumber="true"
                                :supportInit="true"
                                :decimalPlaces="1"
                                v-model="CouponInfo.groupSetting.target_1_B.fanhour_num"
                                :formPlaceholder="'例如: 1'"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <!-- 团长奖励关闭 -->
            <form-content-input v-if="!settingInfo.groupSetting.targetAward.commanderAward.isOpen||!settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 '"
                                :unintTitle="vGroupLeaderUnit"
                                :isNumber="true"
                                :supportInit="true"
                                :decimalPlaces="1"
                                v-model="CouponInfo.groupSetting.target_1_A.fanhour_num"
                                :formPlaceholder="'例如: 2'"
                                :inputClass="'from_list_input_four'"></form-content-input>

          </div>
          <!-- 礼品 -->
          <div class="from_list_content"
               v-if="typeKey==2">
            <form-content-input :formTitle="'满 '"
                                :unintTitle="'人'"
                                :decimalPlaces="0"
                                v-model="CouponInfo.groupSetting.target_1"
                                :formPlaceholder="'例如: 3'"
                                :required="true"
                                :isNumber="true"
                                :supportInit="true"
                                :readonly="!settingInfo.groupSetting.targetAward.oneGroupNumberLimit.isEdit&&groupStepNum==1"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 团长 '"
                                :unintTitle="vGroupLeaderUnit"
                                v-model="CouponInfo.groupSetting.target_1_A.fangift_name"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'团员'"
                                :unintTitle="vGroupLeaderUnit"
                                v-model="CouponInfo.groupSetting.target_1_B.fangift_name"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <!-- 团长奖励关闭 -->
            <form-content-input v-if="!settingInfo.groupSetting.targetAward.commanderAward.isOpen||!settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 '"
                                :unintTitle="vGroupLeaderUnit"
                                v-model="CouponInfo.groupSetting.target_1_A.fangift_name"
                                :inputClass="'from_list_input_four'"></form-content-input>
          </div>
        </div>
        <div class="from_list"
             v-if="groupStepNum>=2">
          <div class="from_list_title">
            2阶团
          </div>
          <div class="from_list_content"
               v-if="typeKey==0">
            <form-content-input :formTitle="'满 '"
                                :unintTitle="'人'"
                                :decimalPlaces="0"
                                v-model="CouponInfo.groupSetting.target_2"
                                :formPlaceholder="'例如: 5'"
                                :required="true"
                                :isNumber="true"
                                :supportInit="true"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 团长 '"
                                :unintTitle="vGroupLeaderUnit"
                                :isNumber="true"
                                :supportInit="true"
                                v-model="CouponInfo.groupSetting.target_2_A.fanxian_amount"
                                :formPlaceholder="'例如: 30'"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'团员 '"
                                :unintTitle="vGroupLeaderUnit"
                                :isNumber="true"
                                :supportInit="true"
                                v-model="CouponInfo.groupSetting.target_2_B.fanxian_amount"
                                :formPlaceholder="'例如: 20'"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <!-- 团长奖励关闭 -->
            <form-content-input v-if="!settingInfo.groupSetting.targetAward.commanderAward.isOpen||!settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 '"
                                :unintTitle="vGroupLeaderUnit"
                                :isNumber="true"
                                :supportInit="true"
                                v-model="CouponInfo.groupSetting.target_2_A.fanxian_amount"
                                :formPlaceholder="'例如: 30'"
                                :inputClass="'from_list_input_four'"></form-content-input>

          </div>
          <!-- 课时 -->
          <div class="from_list_content"
               v-if="typeKey==1">
            <form-content-input :formTitle="'满 '"
                                :unintTitle="'人'"
                                :decimalPlaces="0"
                                v-model="CouponInfo.groupSetting.target_2"
                                :formPlaceholder="'例如: 5'"
                                :required="true"
                                :isNumber="true"
                                :supportInit="true"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 团长 '"
                                :unintTitle="vGroupLeaderUnit"
                                v-model="CouponInfo.groupSetting.target_2_A.fanhour_num"
                                :formPlaceholder="'例如: 5'"
                                :decimalPlaces="1"
                                :isNumber="true"
                                :supportInit="true"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'团员'"
                                :unintTitle="vGroupLeaderUnit"
                                :isNumber="true"
                                :supportInit="true"
                                :decimalPlaces="1"
                                v-model="CouponInfo.groupSetting.target_2_B.fanhour_num"
                                :formPlaceholder="'例如: 3'"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <!-- 团长奖励关闭 -->
            <form-content-input v-if="!settingInfo.groupSetting.targetAward.commanderAward.isOpen||!settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 '"
                                :unintTitle="vGroupLeaderUnit"
                                :isNumber="true"
                                :supportInit="true"
                                :decimalPlaces="1"
                                v-model="CouponInfo.groupSetting.target_2_A.fanhour_num"
                                :formPlaceholder="'例如: 5'"
                                :inputClass="'from_list_input_four'"></form-content-input>

          </div>
          <!-- 礼品 -->
          <div class="from_list_content"
               v-if="typeKey==2">
            <form-content-input :formTitle="'满 '"
                                :unintTitle="'人'"
                                :decimalPlaces="0"
                                v-model="CouponInfo.groupSetting.target_2"
                                :formPlaceholder="'例如: 5'"
                                :required="true"
                                :isNumber="true"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 团长 '"
                                :unintTitle="vGroupLeaderUnit"
                                v-model="CouponInfo.groupSetting.target_2_A.fangift_name"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'团员'"
                                :unintTitle="vGroupLeaderUnit"
                                v-model="CouponInfo.groupSetting.target_2_B.fangift_name"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <!-- 团长奖励关闭 -->
            <form-content-input v-if="!settingInfo.groupSetting.targetAward.commanderAward.isOpen||!settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 '"
                                :unintTitle="vGroupLeaderUnit"
                                v-model="CouponInfo.groupSetting.target_2_A.fangift_name"
                                :inputClass="'from_list_input_four'"></form-content-input>
          </div>
        </div>
        <div class="from_list"
             v-if="groupStepNum>=3">
          <div class="from_list_title">
            3阶团
          </div>
          <div class="from_list_content"
               v-if="typeKey==0">
            <form-content-input :formTitle="'满 '"
                                :unintTitle="'人'"
                                v-model="CouponInfo.groupSetting.target_3"
                                :formPlaceholder="'例如: 8'"
                                :isNumber="true"
                                :required="true"
                                :supportInit="true"
                                :decimalPlaces="0"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 团长 '"
                                :unintTitle="vGroupLeaderUnit"
                                v-model="CouponInfo.groupSetting.target_3_A.fanxian_amount"
                                :formPlaceholder="'例如: 40'"
                                :isNumber="true"
                                :supportInit="true"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'团员 '"
                                :unintTitle="vGroupLeaderUnit"
                                v-model="CouponInfo.groupSetting.target_3_B.fanxian_amount"
                                :isNumber="true"
                                :supportInit="true"
                                :formPlaceholder="'例如: 30'"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="!settingInfo.groupSetting.targetAward.commanderAward.isOpen||!settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 '"
                                :unintTitle="vGroupLeaderUnit"
                                :isNumber="true"
                                :supportInit="true"
                                v-model="CouponInfo.groupSetting.target_3_A.fanxian_amount"
                                :formPlaceholder="'例如: 10'"
                                :inputClass="'from_list_input_four'"></form-content-input>
          </div>
          <!-- 课时 -->
          <div class="from_list_content"
               v-if="typeKey==1">
            <form-content-input :formTitle="'满 '"
                                :unintTitle="'人'"
                                :decimalPlaces="0"
                                v-model="CouponInfo.groupSetting.target_3"
                                :formPlaceholder="'例如: 8'"
                                :required="true"
                                :supportInit="true"
                                :isNumber="true"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 团长 '"
                                :unintTitle="vGroupLeaderUnit"
                                v-model="CouponInfo.groupSetting.target_3_A.fanhour_num"
                                :formPlaceholder="'例如: 8'"
                                :decimalPlaces="1"
                                :isNumber="true"
                                :supportInit="true"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'团员'"
                                :unintTitle="vGroupLeaderUnit"
                                :isNumber="true"
                                :supportInit="true"
                                :decimalPlaces="1"
                                v-model="CouponInfo.groupSetting.target_3_B.fanhour_num"
                                :formPlaceholder="'例如: 5'"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <!-- 团长奖励关闭 -->
            <form-content-input v-if="!settingInfo.groupSetting.targetAward.commanderAward.isOpen||!settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 '"
                                :unintTitle="vGroupLeaderUnit"
                                :isNumber="true"
                                :supportInit="true"
                                :decimalPlaces="1"
                                v-model="CouponInfo.groupSetting.target_3_A.fanhour_num"
                                :formPlaceholder="'例如: 8'"
                                :inputClass="'from_list_input_four'"></form-content-input>

          </div>
          <!-- 礼品 -->
          <div class="from_list_content"
               v-if="typeKey==2">
            <form-content-input :formTitle="'满 '"
                                :unintTitle="'人'"
                                :decimalPlaces="0"
                                v-model="CouponInfo.groupSetting.target_3"
                                :formPlaceholder="'例如: 8'"
                                :required="true"
                                :isNumber="true"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 团长 '"
                                :unintTitle="vGroupLeaderUnit"
                                v-model="CouponInfo.groupSetting.target_3_A.fangift_name"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <form-content-input v-if="settingInfo.groupSetting.targetAward.commanderAward.isOpen&&settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'团员'"
                                :unintTitle="vGroupLeaderUnit"
                                v-model="CouponInfo.groupSetting.target_3_B.fangift_name"
                                :inputClass="'from_list_input_four'"></form-content-input>
            <!-- 团长奖励关闭 -->
            <form-content-input v-if="!settingInfo.groupSetting.targetAward.commanderAward.isOpen||!settingInfo.groupSetting.targetAward.memberAward.isOpen"
                                :formTitle="'奖励 '"
                                :unintTitle="vGroupLeaderUnit"
                                v-model="CouponInfo.groupSetting.target_3_A.fangift_name"
                                :inputClass="'from_list_input_four'"></form-content-input>
          </div>
        </div>
      </div>
      <div class="from_list"
           v-if="settingInfo.groupSetting.settlementType.isEdit">
        <div class="from_list_title">
          结算类型
        </div>
        <div class="from_list_content">
          <div class="from_list_content">
            <div class="from_list_radio_left">
              <el-radio v-model="CouponInfo.groupSetting.target_type"
                        :label="1"
                        @change="changeStatus">用券</el-radio>
            </div>
            <div class="from_list_radio_left">
              <el-radio v-model="CouponInfo.groupSetting.target_type"
                        :label="2"
                        @change="changeStatus">售券</el-radio>
            </div>
          </div>
        </div>
      </div>
      <div class="from_list"
           v-if="inShowOnLine &&typeKey==0">
        <div class="from_list_title">
          返现方式
        </div>
        <div class="from_list_content">
          <div class="from_list_content">
            <div class="from_list_radio_left">
              <el-radio v-model="fanxian_type"
                        :label="2"
                        @change="changeType">线上返</el-radio>
            </div>
            <div class="from_list_radio_left">
              <el-radio v-model="fanxian_type"
                        :label="1"
                        @change="changeType">线下返</el-radio>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 报组合课 -->
    <custom-dialog :title="'选择课程'"
                   :visible.sync="isShowSelectCourseDialog"
                   :before-close="docloseSelection">
      <btn-add-select :addTitleName="'新增'"
                      :addmoduleName="'course-name-setting'"
                      :addmoduleTitleName="'add'"
                      :addmoduleKey='45'
                      @doAddSuccessEvent="addSusscess"></btn-add-select>
      <singleChoiceSelectTree :sourceDate="courseTreeList"
                              :selectionDate="applyCourseDatas"
                              @buttonClickEvent="doAfterSelectionCourseName"
                              @cancleClickForMutilSelected="docloseSelection"></singleChoiceSelectTree>
    </custom-dialog>
  </div>
</template>
<script>
import singleChoiceSelectTree from '../../../../../../common/single-choice-select-tree';
import ticketAward from '../sales-setting-component/ticket-award';
import formContentInput from '../common/form-content-input';

export default {
  data () {
    return {
      fanxian_type: 1,
      typeKey: 0,
      typeKeyValue: '返现金',
      fanhour_keys: 0,
      fanhour_keyvalues: '请选择',
      typeList: [{
        MainDemoName: '返现金',
        OLAPKey: 0
      },
      {
        MainDemoName: '返课时',
        OLAPKey: 1
      },
      {
        MainDemoName: '返奖品',
        OLAPKey: 2
      }],
      isShowSelectCourseDialog: false,
      // 提交的组合课
      applyCourseDatas: {
        IDs: '',
        Names: ''
      }
    };
  },
  props: {
    CouponInfo: Object,
    settingInfo: Object,
    dataInfo: Object,
    fxType: {
      type: [Number, String],
      default: 2
    },
    courseTreeList: Array

  },
  components: {
    formContentInput, ticketAward, singleChoiceSelectTree
  },
  created () {
  },
  computed: {
    vCourseNameList () {
      return (this.$store.getters.courseNameList && this.$store.getters.courseNameList.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; })) || [];
    },
    vTypeList () {
      let newArr = [];
      if (this.vcommanderAward.isOpen) {
        if (this.vcommanderAward.returnMoney.isOpen) {
          newArr.push(this.typeList[0]);
        }
        if (this.vcommanderAward.returnClassHour.isOpen) {
          newArr.push(this.typeList[1]);
        }
        if (this.vcommanderAward.returnPrize.isOpen) {
          newArr.push(this.typeList[2]);
        }
      } else if (this.vmemberAward.isOpen) {
        if (this.vmemberAward.returnMoney.isOpen) {
          newArr.push(this.typeList[0]);
        }
        if (this.vmemberAward.returnClassHour.isOpen) {
          newArr.push(this.typeList[1]);
        }
        if (this.vmemberAward.returnPrize.isOpen) {
          newArr.push(this.typeList[2]);
        }
      }

      return newArr;
    },
    vTypeKeyValue () {
      return this.typeKeyValue;
    },
    vGroupLeaderUnit () {
      if (this.typeKey == 0) {
        return '元';
      } else if (this.typeKey == 1) {
        return '课时';
      } else if (this.typeKey == 2) {
        return '个';
      }
    },
    groupStepNum () {
      return this.settingInfo.groupSetting.degreeRestriction.defaultValue;
    },
    vMenDianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    inShowOnLine () {
      return this.vMenDianInfo.WktInfo.is_open_redpaper && !this.vMenDianInfo.IsCommonTrusteeshipKey;
      // {{mendianInfo.WktInfo && mendianInfo.WktInfo.is_open_redpaper ?'开启':'关闭'}}
    },
    // 团长奖励方式开关
    vcommanderAward () {
      return this.settingInfo.groupSetting.targetAward.commanderAward;
    },
    vmemberAward () {
      return this.settingInfo.groupSetting.targetAward.memberAward;
    },
    // 奖励方式的开关数
    vAwardCount () {
      let count = 0;
      if (this.vcommanderAward.isOpen) {
        count = this.initTypeKey(this.vcommanderAward, count);
      } else if (this.vmemberAward.isOpen) {
        count = this.initTypeKey(this.vmemberAward, count);
      }
      return count;
    }
  },
  watch: {
    fxType: {
      handler (c, o) {
        this.fanxian_type = c;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    initTypeKey (data, count) {
      if (data.returnPrize.isOpen) {
        this.typeKey = 2;
        this.typeKeyValue = '返礼品';
        count++;
      }
      if (data.returnClassHour.isOpen) {
        this.typeKey = 1;
        this.typeKeyValue = '返课时';
        count++;
      }
      if (data.returnMoney.isOpen) {
        this.typeKey = 0;
        this.typeKeyValue = '返现金';
        count++;
      }
      if (this.CouponInfo.groupSetting&&this.CouponInfo.groupSetting.target_1_A) {
        if (Number(this.CouponInfo.groupSetting.target_1_A.fanxian_amount) > 0) {
          this.typeKey = 0;
          this.typeKeyValue = '返现金';
        } else if (Number(this.CouponInfo.groupSetting.target_1_A.fanhour_num)) {
          this.typeKey = 1;
          this.typeKeyValue = '返课时';
        } else if (this.CouponInfo.groupSetting.target_1_A.fangift_name) {
          this.typeKey = 2;
          this.typeKeyValue = '返礼品';
        }
      }
      return count;
    },
    selectType (item) {
      if (this.groupStepNum >= 1) {
        this.initGroupData(this.CouponInfo.groupSetting.target_1_A);
        this.initGroupData(this.CouponInfo.groupSetting.target_1_B);
      }
      if (this.groupStepNum >= 2) {
        this.initGroupData(this.CouponInfo.groupSetting.target_2_A);
        this.initGroupData(this.CouponInfo.groupSetting.target_2_B);
      }
      if (this.groupStepNum >= 3) {
        this.initGroupData(this.CouponInfo.groupSetting.target_3_A);
        this.initGroupData(this.CouponInfo.groupSetting.target_3_B);
      }
      // 重置的时候组件内部watch失效.
      this.$nextTick(() => {
        this.typeKey = item.OLAPKey;
        this.typeKeyValue = item.MainDemoName;
        this.applyCourseDatas.IDs = '';
        this.applyCourseDatas.Names = '';
      });
      console.log('selectType', this.CouponInfo.groupSetting);
    },
    changeStatus (val) {
      console.log(val, 'changeStatus');
    },
    changeType (val) {
      console.log(val, 'changeType');
      this.$emit('changeType', val);
    },
    initGroupData (data) {
      data.fanxian_amount = '';
      data.fanhour_num = '';
      data.fanhour_keyvalues = '';
      data.fanhour_keys = '';
      data.fangift_name = '';
    },
    verifyData () {
      let isComfire = true;
      if (this.groupStepNum >= 1) {
        if (!this.settingInfo.groupSetting.targetAward.commanderAward.isOpen) {
          this.CouponInfo.groupSetting.target_1_B.fanxian_amount = this.CouponInfo.groupSetting.target_1_A.fanxian_amount;
          this.CouponInfo.groupSetting.target_1_B.fanhour_num = this.CouponInfo.groupSetting.target_1_A.fanhour_num;
          this.CouponInfo.groupSetting.target_1_B.fangift_name = this.CouponInfo.groupSetting.target_1_A.fangift_name;
        }
        if (Number(this.CouponInfo.groupSetting.target_1) <= 1) {
          isComfire = false;
          layer.alert('1阶团目标人数至少大于等于2人');
          return isComfire;
        }
        // 一阶团长奖励
        if (this.CouponInfo.groupSetting.target_1_A) {
          if (this.typeKey == 0) {
            if (Number(this.CouponInfo.groupSetting.target_1_A.fanxian_amount) <= 0) {
              isComfire = false;
              layer.alert('请输入1阶团长返现金额');
              return isComfire;
            }
          } else if (this.typeKey == 1) {
            if (Number(this.CouponInfo.groupSetting.target_1_A.fanhour_num) <= 0) {
              isComfire = false;
              layer.alert('请输入1阶团长赠送课时');
              return isComfire;
            }
            if (!this.CouponInfo.groupSetting.target_1_A.fanhour_keyvalues) {
              isComfire = false;
              layer.alert('请选择1阶团长适用课程');
              return isComfire;
            }
          } else if (this.typeKey == 2) {
            if (!this.CouponInfo.groupSetting.target_1_A.fangift_name) {
              isComfire = false;
              layer.alert('请输入1阶团长赠送奖品');
              return isComfire;
            }
          } else {
            isComfire = false;
            layer.alert('请选择1阶团奖励类型');
            return isComfire;
          }
        }
        // 一阶团员奖励
        if (this.vmemberAward.isOpen) {
          // if (this.CouponInfo.groupSetting.target_1_B) {
          //   if (this.typeKey == 0) {
          //     if (Number(this.CouponInfo.groupSetting.target_1_B.fanxian_amount) <= 0) {
          //       isComfire = false;
          //       layer.alert('请输入1阶团员返现金额');
          //       return isComfire;
          //     }
          //   } else if (this.typeKey == 1) {
          //     if (Number(this.CouponInfo.groupSetting.target_1_B.fanhour_num) <= 0) {
          //       isComfire = false;
          //       layer.alert('请输入1阶团员赠送课时');
          //       return isComfire;
          //     }
          //     if (!this.CouponInfo.groupSetting.target_1_B.fanhour_keyvalues) {
          //       isComfire = false;
          //       layer.alert('请选择1阶团员适用课程');
          //       return isComfire;
          //     }
          //   } else if (this.typeKey == 2) {
          //     if (!this.CouponInfo.groupSetting.target_1_B.fangift_name) {
          //       isComfire = false;
          //       layer.alert('请输入1阶团员赠送奖品');
          //       return isComfire;
          //     }
          //   } else {
          //     isComfire = false;
          //     layer.alert('请选择1阶团员奖励类型');
          //     return isComfire;
          //   }
          // }
        }
        this.CouponInfo.groupSetting.target_num = this.CouponInfo.groupSetting.target_1;
      }
      if (this.groupStepNum >= 2) {
        if (!this.settingInfo.groupSetting.targetAward.commanderAward.isOpen) {
          this.CouponInfo.groupSetting.target_2_B.fanxian_amount = this.CouponInfo.groupSetting.target_2_A.fanxian_amount;
          this.CouponInfo.groupSetting.target_2_B.fanhour_num = this.CouponInfo.groupSetting.target_2_A.fanhour_num;
          this.CouponInfo.groupSetting.target_2_B.fangift_name = this.CouponInfo.groupSetting.target_2_A.fangift_name;
        }
        // 2阶团长奖励
        if (Number(this.CouponInfo.groupSetting.target_2) <= 0) {
          isComfire = false;
          layer.alert('请输入2阶团人数');
          return isComfire;
        }
        if (Number(this.CouponInfo.groupSetting.target_2) <= Number(this.CouponInfo.groupSetting.target_1)) {
          isComfire = false;
          layer.alert('2阶团人数不能少于或等于1阶团人数');
          return isComfire;
        }
        if (this.CouponInfo.groupSetting.target_2_A) {
          if (this.typeKey == 0) {
            if (Number(this.CouponInfo.groupSetting.target_2_A.fanxian_amount) <= 0) {
              isComfire = false;
              layer.alert('请输入2阶团长返现金额');
              return isComfire;
            }
          } else if (this.typeKey == 1) {
            if (Number(this.CouponInfo.groupSetting.target_2_A.fanhour_num) <= 0) {
              isComfire = false;
              layer.alert('请输入2阶团长赠送课时');
              return isComfire;
            }
            if (!this.CouponInfo.groupSetting.target_2_A.fanhour_keyvalues) {
              isComfire = false;
              layer.alert('请选择2阶团长适用课程');
              return isComfire;
            }
          } else if (this.typeKey == 2) {
            if (!this.CouponInfo.groupSetting.target_2_A.fangift_name) {
              isComfire = false;
              layer.alert('请输入2阶团长赠送奖品');
              return isComfire;
            }
          } else {
            isComfire = false;
            layer.alert('请选择2阶团长奖励类型');
            return isComfire;
          }
        }
        // 2阶团员奖励
        if (this.vmemberAward.isOpen) {
          // if (this.CouponInfo.groupSetting.target_2_B) {
          //   if (this.typeKey == 0) {
          //     if (Number(this.CouponInfo.groupSetting.target_2_B.fanxian_amount) <= 0) {
          //       isComfire = false;
          //       layer.alert('请输入2阶团员返现金额');
          //       return isComfire;
          //     }
          //   } else if (this.typeKey == 1) {
          //     if (Number(this.CouponInfo.groupSetting.target_2_B.fanhour_num) <= 0) {
          //       isComfire = false;
          //       layer.alert('请输入2阶团员赠送课时');
          //       return isComfire;
          //     }
          //     if (!this.CouponInfo.groupSetting.target_2_B.fanhour_keyvalues) {
          //       isComfire = false;
          //       layer.alert('请选择2阶团员适用课程');
          //       return isComfire;
          //     }
          //   } else if (this.typeKey == 2) {
          //     if (!this.CouponInfo.groupSetting.target_2_B.fangift_name) {
          //       isComfire = false;
          //       layer.alert('请输入2阶团员赠送奖品');
          //       return isComfire;
          //     }
          //   } else {
          //     isComfire = false;
          //     layer.alert('请选择2阶团员奖励类型');
          //     return isComfire;
          //   }
          // }
        }
        this.CouponInfo.groupSetting.target_num = this.CouponInfo.groupSetting.target_2;
      }
      if (this.groupStepNum >= 3) {
        if (!this.settingInfo.groupSetting.targetAward.commanderAward.isOpen) {
          this.CouponInfo.groupSetting.target_3_B.fanxian_amount = this.CouponInfo.groupSetting.target_3_A.fanxian_amount;
          this.CouponInfo.groupSetting.target_3_B.fanhour_num = this.CouponInfo.groupSetting.target_3_A.fanhour_num;
          this.CouponInfo.groupSetting.target_3_B.fangift_name = this.CouponInfo.groupSetting.target_3_A.fangift_name;
        }
        if (Number(this.CouponInfo.groupSetting.target_3) <= 0) {
          isComfire = false;
          layer.alert('请输入3阶团人数');
          return isComfire;
        }
        if (Number(this.CouponInfo.groupSetting.target_3) <= Number(this.CouponInfo.groupSetting.target_2)) {
          isComfire = false;
          layer.alert('3阶团人数不能少于或等于2阶团人数');
          return isComfire;
        }
        // 3阶团长奖励
        if (this.CouponInfo.groupSetting.target_3_A) {
          if (this.typeKey == 0) {
            if (Number(this.CouponInfo.groupSetting.target_3_A.fanxian_amount) <= 0) {
              isComfire = false;
              layer.alert('请输入3阶团长返现金额');
              return isComfire;
            }
          } else if (this.typeKey == 1) {
            if (Number(this.CouponInfo.groupSetting.target_3_A.fanhour_num) <= 0) {
              isComfire = false;
              layer.alert('请输入3阶团长赠送课时');
              return isComfire;
            }
            if (!this.CouponInfo.groupSetting.target_3_A.fanhour_keyvalues) {
              isComfire = false;
              layer.alert('请选择3阶团长适用课程');
              return isComfire;
            }
          } else if (this.typeKey == 2) {
            if (!this.CouponInfo.groupSetting.target_3_A.fangift_name) {
              isComfire = false;
              layer.alert('请输入3阶团长赠送奖品');
              return isComfire;
            }
          } else {
            isComfire = false;
            layer.alert('请选择3阶团长奖励类型');
            return isComfire;
          }
        }
        // 3阶团员奖励
        if (this.vmemberAward.isOpen) {
          // if (this.CouponInfo.groupSetting.target_3_B) {
          //   if (this.typeKey == 0) {
          //     if (Number(this.CouponInfo.groupSetting.target_3_B.fanxian_amount) <= 0) {
          //       isComfire = false;
          //       layer.alert('请输入3阶团员返现金额');
          //       return isComfire;
          //     }
          //   } else if (this.typeKey == 1) {
          //     if (Number(this.CouponInfo.groupSetting.target_3_B.fanhour_num) <= 0) {
          //       isComfire = false;
          //       layer.alert('请输入3阶团员赠送课时');
          //       return isComfire;
          //     }
          //     if (!this.CouponInfo.groupSetting.target_3_B.fanhour_keyvalues) {
          //       isComfire = false;
          //       layer.alert('请选择3阶团员适用课程');
          //       return isComfire;
          //     }
          //   } else if (this.typeKey == 2) {
          //     if (!this.CouponInfo.groupSetting.target_3_B.fangift_name) {
          //       isComfire = false;
          //       layer.alert('请输入3阶团员赠送奖品');
          //       return isComfire;
          //     }
          //   } else {
          //     isComfire = false;
          //     layer.alert('请选择3阶团员奖励类型');
          //     return isComfire;
          //   }
          // }
        }
        this.CouponInfo.groupSetting.target_num = this.CouponInfo.groupSetting.target_3;
      }
      console.log('verifyData', isComfire);
      // 分销商报名
      return isComfire;
    },
    // 打开课程
    showCourseNameListDialog () {
      console.log('showCourseNameListDialog');
      this.isShowSelectCourseDialog = true;
    },
    addSusscess (item) {
      let obj = {
        selectedNames: [],
        selectedIDs: []
      };
      obj.selectedNames.push(item.MainDemoName);
      obj.selectedIDs.push(item.OLAPKey);
      this.doAfterSelectionCourseName(obj);
      this.$emit('updateCourseList');
      // this.loadDataList();
    },
    doAfterSelectionCourseName (obj) {
      console.log(obj, 'doAfterSelectionCourseName');
      if (obj && obj.selectedNames) {
        let item = {
          MainDemoName: obj.selectedNames[0],
          OLAPKey: obj.selectedIDs[0]
        };
        this.applyCourseDatas = {
          IDs: obj.selectedIDs[0],
          Names: obj.selectedNames[0]
        };
        this.fanhour_keyvalues = item.MainDemoName;
        this.fanhour_keys = item.OLAPKey;
        if (this.groupStepNum >= 1) {
          this.CouponInfo.groupSetting.target_1_A.fanhour_keyvalues = item.MainDemoName;
          this.CouponInfo.groupSetting.target_1_A.fanhour_keys = item.OLAPKey;
          this.CouponInfo.groupSetting.target_1_B.fanhour_keyvalues = item.MainDemoName;
          this.CouponInfo.groupSetting.target_1_B.fanhour_keys = item.OLAPKey;
        }
        if (this.groupStepNum >= 2) {
          this.CouponInfo.groupSetting.target_2_A.fanhour_keyvalues = item.MainDemoName;
          this.CouponInfo.groupSetting.target_2_A.fanhour_keys = item.OLAPKey;
          this.CouponInfo.groupSetting.target_2_B.fanhour_keyvalues = item.MainDemoName;
          this.CouponInfo.groupSetting.target_2_B.fanhour_keys = item.OLAPKey;
        }
        if (this.groupStepNum >= 3) {
          this.CouponInfo.groupSetting.target_3_A.fanhour_keyvalues = item.MainDemoName;
          this.CouponInfo.groupSetting.target_3_A.fanhour_keys = item.OLAPKey;
          this.CouponInfo.groupSetting.target_3_B.fanhour_keyvalues = item.MainDemoName;
          this.CouponInfo.groupSetting.target_3_B.fanhour_keys = item.OLAPKey;
        }
        this.docloseSelection();
        console.log(this.CouponInfo.groupSetting, '拼团数据');
      }
    },
    docloseSelection () {
      this.isShowSelectCourseDialog = false;
    }

  }
};
</script>
