<template v-slot:dialogFactoryCommon>
  <div class="dialog-factory-common">
    <div v-if="isShowDialog">
      <custom-dialog :title="curShowDialogParam.title"
                     :dialogParamList="dialogParamList"
                     :curModuleName="curShowDialogParam.moduleName"
                     :width="curShowDialogParam.dialogWidth"
                     :isOpenFactoryDialogCommon="true"
                     :class="curShowDialogParam.className"
                     :moduleDescription="curShowDialogParam.moduleDescription"
                     @beforeClose="closeDialog">
        <sign-up-payment v-if="curShowDialogParam.moduleName == dialogList.signuppayment.moduleName"
                         :ref="curShowDialogParam.moduleName"
                         :dataInfo="getData('dataInfo')"
                         @closesignUpPaymentDialog="closeDialog"
                         @afterSucces="execCustomEvent"></sign-up-payment>

        <zx-payment-hint-dialog v-else-if="curShowDialogParam.moduleName == dialogList.signuppaymentSuccess.moduleName"
                                :ref="curShowDialogParam.moduleName"
                                :tableID="getData('tableID')"
                                :studentInfo="getData('studentInfo')"
                                :isMoneyReceipt="getData('isMoneyReceipt')"
                                @closeDialog="closeDialog"></zx-payment-hint-dialog>

        <student-file v-else-if="curShowDialogParam.moduleName==dialogList.studentFile.moduleName"
                      :ref="curShowDialogParam.moduleName"
                      :studentID="getData('studentID')"
                      :defaultShowType="getData('defaultShowType')"
                      @closeDialog="closeDialog"></student-file>

        <!-- 学生列表 -->
        <student-list v-else-if="curShowDialogParam.moduleName==dialogList.studentList.moduleName"
                      :ref="curShowDialogParam.moduleName"
                      :switchItem="getData('switchItem')"
                      :studentInfo="getData('studentInfo')"
                      :CustStatusKey="getData('CustStatusKey')"
                      :IsConfirmKey="getData('IsConfirmKey')"
                      :isShowAddBtn="getData('isShowAddBtn')"
                      :searchText="getData('searchText')"
                      :SubscribeCourseKey="getData('SubscribeCourseKey')"
                      :typeApi="getData('typeApi')"
                      @selectedItem="execCustomEvent"
                      @closeDialog="closeDialog"></student-list>

        <!-- 班级列表 -->
        <grade-list v-else-if="curShowDialogParam.moduleName==dialogList.gradeList.moduleName"
                    :ref="curShowDialogParam.moduleName"
                    :isValidClassShow="getData('isValidClassShow')"
                    @selectedItem="execCustomEvent"
                    @closeDialog="closeDialog">
        </grade-list>

        <register-customer v-else-if="curShowDialogParam.moduleName==dialogList.registercustomer.moduleName"
                           :ref="curShowDialogParam.moduleName"
                           :dataInfo="getData('dataInfo')"
                           @closeRegisterCustomer="closeDialog"
                           @doafterRegisterCustomer="execCustomEvent">
        </register-customer>

        <register-customer-details v-else-if="curShowDialogParam.moduleName == dialogList.registerCustomerDetails.moduleName"
                                   :ref="curShowDialogParam.moduleName"
                                   :dataInfo="getData('dataInfo')"
                                   @closeRegisterCustomer="closeDialog"
                                   @doafterRegisterCustomer="execCustomEvent"
                                   @AfterStudentDelete="execCustomEvent">
        </register-customer-details>

        <arrange-try-course v-else-if="curShowDialogParam.moduleName == dialogList.arrangetrycourse.moduleName"
                            :ref="curShowDialogParam.moduleName"
                            :tableID="getData('tableID')"
                            @afterSuccess="execCustomEvent"
                            @closeDialog="closeDialog"></arrange-try-course>

        <student-apply-course v-else-if="curShowDialogParam.moduleName == dialogList.studentApplyCourse.moduleName"
                              :ref="curShowDialogParam.moduleName"
                              :studentApplyCourseParams="getData('studentApplyCourseParams')"
                              @afterSuccess="execCustomEvent"
                              @closeDialog="closeDialog"></student-apply-course>

        <course-applycourse v-else-if="curShowDialogParam.moduleName == dialogList.courseApplycourse.moduleName"
                            :ref="curShowDialogParam.moduleName"
                            :studentKey="getData('studentKey')"
                            :applyClassID="getData('applyClassID')"
                            @afterSuccess="execCustomEvent"
                            @closeDialog="closeDialog"></course-applycourse>

        <debt-course-hourse-details v-else-if="curShowDialogParam.moduleName == dialogList.debtCourseHourseDetails.moduleName"
                                    :ref="curShowDialogParam.moduleName"
                                    :studentItem="getData('studentItem')"
                                    @backClassHourSuccess="execCustomEvent"
                                    @closeDialog="closeDialog"></debt-course-hourse-details>
        <!-- 课类设置 -->
        <course-class-setting v-else-if="curShowDialogParam.moduleName == dialogList.courseClassSetting.moduleName"
                              :ref="curShowDialogParam.moduleName"
                              :editItem="getData('editItem')"
                              :isReadonly="getData('isReadonly')"
                              @afterSuccess="execCustomEvent"
                              @cancelClick="closeDialog"></course-class-setting>
        <!-- 课程设置 -->
        <course-name-setting v-else-if="curShowDialogParam.moduleName == dialogList.courseNameSetting.moduleName"
                             :ref="curShowDialogParam.moduleName"
                             :editItem="getData('editItem')"
                             :isReadonly="getData('isReadonly')"
                             @afterSuccess="execCustomEvent"
                             @cancelClick="closeDialog"></course-name-setting>
        <!-- 班级设置 -->
        <squad-class-Setting v-else-if="curShowDialogParam.moduleName == dialogList.squadClassSetting.moduleName"
                             :ref="curShowDialogParam.moduleName"
                             :editItem="getData('editItem')"
                             :isReadonly="getData('isReadonly')"
                             @afterSuccess="execCustomEvent"
                             @cancelClick="closeDialog"></squad-class-Setting>
        <!--  排课创收潜力挖掘 - 校区饱和排课设置 1  -->
        <saturation-course-setting v-else-if="curShowDialogParam.moduleName == dialogList.saturationCourseSetting.moduleName"
                                   :ref="curShowDialogParam.moduleName"
                                   :editItem="getData('editItem')"
                                   :isReadonly="getData('isReadonly')"
                                   @afterSuccess="execCustomEvent"
                                   @cancelClick="closeDialog"></saturation-course-setting>
        <!--  排课创收潜力挖掘 - 校区饱和排课设置 2  -->
        <appoint-course-setting v-else-if="curShowDialogParam.moduleName == dialogList.appointCourseSetting.moduleName"
                                :ref="curShowDialogParam.moduleName"
                                :OLAPKey="getData('OLAPKey')"
                                :isReadonly="getData('isReadonly')"
                                @afterSuccess="execCustomEvent"
                                @cancelClick="closeDialog"></appoint-course-setting>
        <!--  排课创收潜力挖掘 - 学生/班级/满班率 出勤级别  -->
        <course-setting-item v-else-if="curShowDialogParam.moduleName == dialogList.courseSettingItem.moduleName"
                             :ref="curShowDialogParam.moduleName"
                             :editItem="getData('editItem')"
                             :isReadonly="getData('isReadonly')"
                             @afterSuccess="execCustomEvent"
                             @cancelClick="closeDialog"></course-setting-item>
        <!-- 班类设置 -->
        <squad-class-type-setting v-else-if="curShowDialogParam.moduleName == dialogList.squadClassTypeSetting.moduleName"
                                  :ref="curShowDialogParam.moduleName"
                                  :editItem="getData('editItem')"
                                  :isReadonly="getData('isReadonly')"
                                  @afterSuccess="execCustomEvent"
                                  @cancelClick="closeDialog"></squad-class-type-setting>
        <!-- 教室设置 -->
        <classroom-setting v-else-if="curShowDialogParam.moduleName == dialogList.classroomSetting.moduleName"
                           :ref="curShowDialogParam.moduleName"
                           :editItem="getData('editItem')"
                           :isReadonly="getData('isReadonly')"
                           @afterSuccess="execCustomEvent"
                           @cancelClick="closeDialog"></classroom-setting>
        <!-- 部门设置 -->
        <department-setting v-else-if="curShowDialogParam.moduleName == dialogList.departmentSetting.moduleName"
                            :ref="curShowDialogParam.moduleName"
                            :editItem="getData('editItem')"
                            :isReadonly="getData('isReadonly')"
                            @afterSuccess="execCustomEvent"
                            @cancelClick="closeDialog"></department-setting>
        <!-- 职务设置 -->
        <post-setting v-else-if="curShowDialogParam.moduleName == dialogList.postSetting.moduleName"
                      :ref="curShowDialogParam.moduleName"
                      :editItem="getData('editItem')"
                      :isReadonly="getData('isReadonly')"
                      @afterSuccess="execCustomEvent"
                      @cancelClick="closeDialog"></post-setting>
        <!-- 财务科目设置 -->
        <incomeAndExpenses-subject-setting v-else-if="curShowDialogParam.moduleName == dialogList.incomeAndExpensesSubjectSetting.moduleName"
                                           :ref="curShowDialogParam.moduleName"
                                           :editItem="getData('editItem')"
                                           :isReadonly="getData('isReadonly')"
                                           @afterSuccess="execCustomEvent"
                                           @cancelClick="closeDialog"></incomeAndExpenses-subject-setting>
        <!-- 收款方式设置 -->
        <proceeds-account-setting v-else-if="curShowDialogParam.moduleName == dialogList.proceedsAccountSetting.moduleName"
                                  :ref="curShowDialogParam.moduleName"
                                  :editItem="getData('editItem')"
                                  :isReadonly="getData('isReadonly')"
                                  @afterSuccess="execCustomEvent"
                                  @cancelClick="closeDialog"></proceeds-account-setting>
        <!-- 学杂费设置 -->
        <incidentals-setting v-else-if="curShowDialogParam.moduleName == dialogList.incidentalsSetting.moduleName"
                             :ref="curShowDialogParam.moduleName"
                             :editItem="getData('editItem')"
                             :isReadonly="getData('isReadonly')"
                             @afterSuccess="execCustomEvent"
                             @cancelClick="closeDialog"></incidentals-setting>
        <!-- 供应商设置 -->
        <supplier-setting v-else-if="curShowDialogParam.moduleName == dialogList.supplierSetting.moduleName"
                          :ref="curShowDialogParam.moduleName"
                          :editItem="getData('editItem')"
                          :isReadonly="getData('isReadonly')"
                          @afterSuccess="execCustomEvent"
                          @cancelClick="closeDialog"></supplier-setting>
        <!-- 住宅设置 -->
        <community-setting v-else-if="curShowDialogParam.moduleName == dialogList.communitySetting.moduleName"
                           :ref="curShowDialogParam.moduleName"
                           :editItem="getData('editItem')"
                           :isReadonly="getData('isReadonly')"
                           @afterSuccess="execCustomEvent"
                           @cancelClick="closeDialog"></community-setting>
        <!-- 客户来源设置 -->
        <sell-source-setting v-else-if="curShowDialogParam.moduleName == dialogList.sellSourceSetting.moduleName"
                             :ref="curShowDialogParam.moduleName"
                             :editItem="getData('editItem')"
                             :isReadonly="getData('isReadonly')"
                             @afterSuccess="execCustomEvent"
                             @cancelClick="closeDialog"></sell-source-setting>
        <!-- 套餐设置 -->
        <list-taocan-setting v-else-if="curShowDialogParam.moduleName == dialogList.taoCanSetting.moduleName"
                             :ref="curShowDialogParam.moduleName"
                             :TableID="getData('TableID')"
                             :type="getData('type')"
                             @afterSuccess="execCustomEvent"
                             @closeDialog="closeDialog"></list-taocan-setting>
        <!-- 员工设置 -->
        <list-yuangong-setting v-else-if="curShowDialogParam.moduleName == dialogList.yuanGongSetting.moduleName"
                               :ref="curShowDialogParam.moduleName"
                               :staffID="getData('staffID')"
                               :staffType="getData('staffType')"
                               :isReadonly="getData('isReadonly')"
                               @afterSuccess="execCustomEvent"
                               @closeDialog="closeDialog"></list-yuangong-setting>
        <!-- 按课程排课 -->
        <student-arrange-course-by-course-name v-else-if="curShowDialogParam.moduleName == dialogList.studentArrangeCourseByCourseName.moduleName"
                                               :ref="curShowDialogParam.moduleName"
                                               :studentID="getData('studentID')"
                                               :courseNameID="getData('courseNameID')"
                                               :courseName="getData('courseName')"
                                               @afterSucces="execCustomEvent"
                                               @afterCancelAllArrangeSuccess="execCustomEvent"></student-arrange-course-by-course-name>
        <!-- 跟班级排课 -->
        <student-arrange-course-by-class v-else-if="curShowDialogParam.moduleName == dialogList.studentArrangeCourseByClass.moduleName"
                                         :ref="curShowDialogParam.moduleName"
                                         :studentID="getData('studentID')"
                                         :classNameID="getData('classNameID')"
                                         :className="getData('className')"
                                         @afterCancelAllArrangeSuccess="execCustomEvent"></student-arrange-course-by-class>

        <merge-student-page v-else-if="curShowDialogParam.moduleName == dialogList.mergeStudent.moduleName"
                            :ref="curShowDialogParam.moduleName"
                            :studentKey="getData('studentKey')"
                            @closePage="closeDialog"></merge-student-page>

        <courseIDApply v-else-if="curShowDialogParam.moduleName == dialogList.courseIDApplyList.moduleName"
                       :ref="curShowDialogParam.moduleName"
                       :dataInfo="getData('dataInfo')"
                       @afterSucces="execCustomEvent"
                       @afterSucces2="dialogList.courseIDApplyList.title='待报课单列表'"
                       @closeDialog="closeDialog"></courseIDApply>
        <coures-purchase-consume-details v-else-if="curShowDialogParam.moduleName == dialogList.buyClassDetailRecord.moduleName"
                                         :ref="curShowDialogParam.moduleName"
                                         :studentKey="getData('studentKey')"
                                         :courseBillKey="getData('courseBillKey')"></coures-purchase-consume-details>
        <class-detail v-else-if="curShowDialogParam.moduleName == dialogList.classDetail.moduleName"
                      :ref="curShowDialogParam.moduleName"
                      :courseDetail="getData('courseInfo')"
                      @afterSucces="execCustomEvent"></class-detail>
        <edit-course v-else-if="curShowDialogParam.moduleName == dialogList.editCourse.moduleName"
                     :ref="curShowDialogParam.moduleName"
                     :courseInfo="getData('courseInfo')"
                     @afterSucces="execCustomEvent"
                     @closeDialog="closeDialog"></edit-course>
        <!-- 客户还款 -->
        <paymentAndWithdraw v-else-if="curShowDialogParam.moduleName == dialogList.paymentrepayment.moduleName"
                            :ref="dialogList.paymentrepayment.moduleName"
                            :dataInfo="getData('dataInfo')"
                            :menuType="0"
                            @afterSucces="execCustomEvent"
                            @closePaymentDialog="closeDialog"></paymentAndWithdraw>
        <!-- 客户提现 -->
        <paymentAndWithdraw v-else-if="curShowDialogParam.moduleName == dialogList.paymentwithdrawal.moduleName"
                            :ref="curShowDialogParam.moduleName"
                            :dataInfo="getData('dataInfo')"
                            :menuType="1"
                            @afterSucces="execCustomEvent"
                            @closePaymentDialog="closeDialog"></paymentAndWithdraw>
        <!-- 欠款销账 -->
        <write-off-arrears-table v-else-if="curShowDialogParam.moduleName == dialogList.writeoffarrears.moduleName"
                                 :ref="dialogList.writeoffarrears.moduleName"
                                 :dataInfo="getData('dataInfo')"
                                 :menuType="0"
                                 @afterSucces="execCustomEvent"
                                 @closePaymentDialog="closeDialog"></write-off-arrears-table>
        <!-- 作废单据 -->
        <delete-bill-table v-else-if="curShowDialogParam.moduleName == dialogList.deletebill.moduleName"
                           :ref="dialogList.deletebill.moduleName"
                           :tableID="getData('tableID')"
                           :studentInfo="getData('studentInfo')"
                           @afterSucces="execCustomEvent"
                           @closePaymentDialog="closeDialog"></delete-bill-table>
        <!-- 循环课详情 -->
        <circle-course-detail v-else-if="curShowDialogParam.moduleName == dialogList.circleCourseDetail.moduleName"
                              :ref="curShowDialogParam.moduleName"
                              :ruleID="getData('ruleID')"
                              @closeDialog="closeDialog"></circle-course-detail>
        <!-- 修改循环课 -->
        <edit-circle-course-table v-else-if="curShowDialogParam.moduleName == dialogList.editCircleCourseTable.moduleName"
                                  :ref="curShowDialogParam.moduleName"
                                  :circleCourseInfo="getData('circleCourseInfo')"
                                  @closeDialog="closeDialog"></edit-circle-course-table>
        <!-- 续建课表 -->
        <continue-new-circle-course-table v-else-if="curShowDialogParam.moduleName == dialogList.continueNewCircleCourseTable.moduleName"
                                          :ref="curShowDialogParam.moduleName"
                                          :ruleID="getData('ruleID')"
                                          @afterSucces="execCustomEvent"
                                          @closeDialog="closeDialog"></continue-new-circle-course-table>

        <quit-or-enter-class-form v-else-if="curShowDialogParam.moduleName == dialogList.quitOrEnterClassForm.moduleName"
                                  :ref="curShowDialogParam.moduleName"
                                  :dataInfo="getData('dataInfo')"
                                  @closeStudentQuitOrenterclassDialog="closeDialog"
                                  @afterSucces="execCustomEvent"></quit-or-enter-class-form>
        <!-- 批量安排课 -->
        <batch-arrange-course v-else-if="curShowDialogParam.moduleName == dialogList.batchArrangeScheduleCourse.moduleName"
                              :ref="curShowDialogParam.moduleName"
                              :paramsInfo="getData('paramsInfo')"
                              @afterBatchArrangeCourse="execCustomEvent"
                              @cancelClick="closeDialog"></batch-arrange-course>
        <!-- 修改课单 -->
        <change-course-bill v-else-if="curShowDialogParam.moduleName == dialogList.changeCourseBill.moduleName"
                            :ref="curShowDialogParam.moduleName"
                            :dataInfo="getData('dataInfo')"
                            :courseInfo="getData('courseInfo')"
                            @updateCourseEvent="execCustomEvent"
                            @closeViewDialog="closeDialog"></change-course-bill>
        <!-- 课时退款 -->
        <refundMoneyForBackCourse v-else-if="curShowDialogParam.moduleName == dialogList.refundmoneybackcourse.moduleName"
                                  :ref="curShowDialogParam.moduleName"
                                  :dataInfo="getData('dataInfo')"
                                  @updateCourseEvent="execCustomEvent"
                                  @closeRefundMoneyBackCourseDialog="closeDialog"></refundMoneyForBackCourse>
        <!-- 转让课单 -->
        <transfer-course-bill v-else-if="curShowDialogParam.moduleName == dialogList.transfercoursebill.moduleName"
                              :ref="curShowDialogParam.moduleName"
                              :dataInfo="getData('dataInfo')"
                              @updateCourseEvent="execCustomEvent"
                              @closeTransferCourseBillDialog="closeDialog"></transfer-course-bill>

        <add-student-to-bJ v-else-if="curShowDialogParam.moduleName == dialogList.addStudentToBJ.moduleName"
                           :ref="curShowDialogParam.moduleName"
                           :dataInfo="getData('dataInfo')"
                           @selectedItem="execCustomEvent"
                           @closeAddStudentToBJDialog="closeDialog"
                           @onSelectedItem="execCustomEvent"></add-student-to-bJ>

        <arrange-support-edit v-else-if="curShowDialogParam.moduleName == dialogList.arrangeSupportEdit.moduleName"
                              :ref="curShowDialogParam.moduleName"
                              :studentKey="getData('studentKey')"
                              :content="getData('content')"
                              @updateSuccess="execCustomEvent"
                              @closeDialog="closeDialog"></arrange-support-edit>
        <attendance-detail v-else-if="curShowDialogParam.moduleName == dialogList.attendanceDetail.moduleName"
                           :ref="curShowDialogParam.moduleName"
                           :queryDate="getData('queryDate')"
                           @closeDialog="closeDialog"></attendance-detail>

        <release-home-task v-else-if="curShowDialogParam.moduleName == dialogList.releaseHomeTask.moduleName"
                           :ref="curShowDialogParam.moduleName"
                           :dataInfo="getData('taskData')"
                           @afterSuccess="execCustomEvent"
                           @closeDialog="closeDialog"></release-home-task>

        <release-comment v-else-if="curShowDialogParam.moduleName == dialogList.releaseComment.moduleName"
                         :ref="curShowDialogParam.moduleName"
                         :dataInfo="getData('taskData')"
                         @afterSuccess="execCustomEvent"
                         @closeDialog="closeDialog"></release-comment>

        <task-correct v-else-if="curShowDialogParam.moduleName == dialogList.taskCorrect.moduleName"
                      :ref="curShowDialogParam.moduleName"
                      :taskID="getData('taskID')"
                      @closeDialog="closeDialog"></task-correct>

        <correct-home-task v-else-if="curShowDialogParam.moduleName == dialogList.taskCorrectDetails.moduleName"
                           :ref="curShowDialogParam.moduleName"
                           :dataInfo="getData('taskData')"
                           @afterSuccess="execCustomEvent"
                           @closeDialog="closeDialog"></correct-home-task>

        <task-detail v-else-if="curShowDialogParam.moduleName == dialogList.taskDetail.moduleName"
                     :ref="curShowDialogParam.moduleName"
                     :dataInfo="getData('taskData')"
                     @closeDialog="closeDialog"></task-detail>

        <details-list v-else-if="curShowDialogParam.moduleName == dialogList.studentCoindetails.moduleName"
                      :ref="curShowDialogParam.moduleName"
                      :studentMoneyList="getData('studentMoneyList')"
                      @closeDialog="closeDialog"
                      @confirmClick="execCustomEvent"></details-list>
        <!-- 微信模板 -->
        <weChat-template v-else-if="curShowDialogParam.moduleName == dialogList.weChatTemplate.moduleName"
                         :ref="curShowDialogParam.moduleName"
                         :StudentKey="getData('StudentKey')"
                         :eventId="getData('eventId')"
                         @closeDialog="closeDialog"
                         @confirmClick="execCustomEvent"></weChat-template>

        <!-- 安排考勤 -->
        <attendance-arrange v-else-if="curShowDialogParam.moduleName == dialogList.attendanceArrange.moduleName"
                            :ref="curShowDialogParam.moduleName"
                            :courseKey="getData('scheduleCourseKey')"
                            :ClassName="getData('ClassName')"
                            :isAlter="getData('isAlter')"
                               :isTeacher="getData('isTeacher')"
                            :isHistory="getData('isHistory')"
                            @closeDialog="closeDialog"></attendance-arrange>

        <!-- 欠课时详情-->
        <debtClassCountDetail v-else-if="curShowDialogParam.moduleName == dialogList.debtClassCountDetail.moduleName"
                              :ref="curShowDialogParam.moduleName"
                              :dataInfo="getData('dataInfo')"
                              @afterSucces="execCustomEvent"
                              @closeDialog="closeDialog"></debtClassCountDetail>
        <!-- 考勤课消详情              -->
        <attenCourseSpendDetails v-else-if="curShowDialogParam.moduleName == dialogList.attenCourseSpendDetails.moduleName"
                                 :ref="curShowDialogParam.moduleName"
                                 :dataInfo="getData('dataInfo')"
                                 @afterSucces="execCustomEvent"
                                 @closeDialog="closeDialog"></attenCourseSpendDetails>

        <!-- 单选通用列表 -->
        <single-selection-list v-else-if="curShowDialogParam.moduleName == dialogList.singleSelectionList.moduleName"
                               :ref="curShowDialogParam.moduleName"
                               :selectedKey="getData('selectedKey')"
                               :showRightContent="getData('showRightContent')"
                               :dataSourceList="getData('dataSourceList')"
                               :required="getData('required')"
                               :placeholder="getData('placeholder')"
                               :addBtnText="getData('addBtnText')"
                               :addModuleName="getData('addModuleName')"
                               :addModuleTitleName="getData('addModuleTitleName')"
                               :ModulePowerKey="getData('ModulePowerKey')"
                               :mutilSwitchBtnList="getData('mutilSwitchBtnList')"
                               @onSelectedItem="execCustomEvent"
                               @closeDialog="closeDialog"></single-selection-list>
        <!-- 物品名称设置 -->
        <goods-name-setting-form v-else-if="curShowDialogParam.moduleName == dialogList.goodsNameSetting.moduleName"
                                 :ref="curShowDialogParam.moduleName"
                                 :dataInfo="getData('dataInfo')"
                                 :isReadonly="getData('isReadonly')"
                                 @afterSuccessCallBack="execCustomEvent"
                                 @closeDialog="closeDialog"></goods-name-setting-form>
        <!-- 安排试课弹框 -->
        <arrange-course v-else-if="curShowDialogParam.moduleName == dialogList.arrangeCourse.moduleName"
                        :ref="curShowDialogParam.moduleName"
                        :dataInfo="getData('dataInfo')"
                        @afterSuccess="execCustomEvent"
                        @closeDialog="closeDialog"></arrange-course>
        <verify-student-popup v-else-if="curShowDialogParam.moduleName == dialogList.verifyStudentPopup.moduleName"
                              :ref="curShowDialogParam.moduleName"
                              :studentInfo="getData('dataInfo')"
                              @afterSuccess="execCustomEvent"
                              @closeDialog="closeDialog"></verify-student-popup>
        <apply-auth v-else-if="curShowDialogParam.moduleName == dialogList.applyAuth.moduleName"
                    :ref="curShowDialogParam.moduleName"
                    :dataInfo="getData('dataInfo')"
                    @afterSuccess="execCustomEvent"
                    @closeDialog="closeDialog"></apply-auth>

        <authManagerList v-else-if="curShowDialogParam.moduleName == dialogList.authManagerList.moduleName"
                         :ref="curShowDialogParam.moduleName"
                         :dataInfo="getData('dataInfo')"
                         @afterSuccess="execCustomEvent"
                         @closeDialog="closeDialog">
        </authManagerList>
        <!-- 修改密码 -->
        <edit-pass-word v-else-if="curShowDialogParam.moduleName == dialogList.editPassWord.moduleName"
                        :ref="curShowDialogParam.moduleName"
                        :OLAPKey="getData('OLAPKey')"
                        @afterSuccess="execCustomEvent"
                        @cancelClick="closeDialog"></edit-pass-word>
        <!-- 招商顾问工作台-完善档案 -->
        <improve-clew-form v-else-if="curShowDialogParam.moduleName == dialogList.improveClewForm.moduleName"
                           :ref="curShowDialogParam.moduleName"
                           :dataInfo="getData('dataInfo')"
                           @afterSuccess="execCustomEvent"
                           @cancelClick="closeDialog"
                           :isReadonly="getData('isReadonly')"></improve-clew-form>

        <!-- CRM-签约表单/签约详情 -->
        <crm-sign-contract-form v-else-if="curShowDialogParam.moduleName == dialogList.crmSignContractForm.moduleName"
                                :ref="curShowDialogParam.moduleName"
                                :dataInfo="getData('dataInfo')"
                                :showBtn="getData('showBtn')"
                                :isReadonly="getData('isReadonly')"
                                @afterSuccess="execCustomEvent"
                                @closeDialog="closeDialog">
        </crm-sign-contract-form>
        <!-- 分配投资顾问 -->
        <distribute-investment-advisor v-else-if="curShowDialogParam.moduleName == dialogList.distributeInvestmentAdvisor.moduleName"
                                       :ref="curShowDialogParam.moduleName"
                                       :investorIDs="getData('investorIDs')"
                                       :isReadonly="getData('isReadonly')"
                                       @afterSuccess="execCustomEvent"
                                       @cancelClick="closeDialog"></distribute-investment-advisor>
        <!-- CRM-付款申请表单/付款审核 -->
        <crm-payment-form v-else-if="curShowDialogParam.moduleName == dialogList.crmPaymentForm.moduleName"
                          :ref="curShowDialogParam.moduleName"
                          :OLAPKey="getData('OLAPKey')"
                          :paymentType="getData('paymentType')"
                          :isReadonly="getData('isReadonly')"
                          @afterSuccess="execCustomEvent"
                          @closeDialog="closeDialog">
        </crm-payment-form>
        <!-- 投资人详情 -->
        <crm-improve-clew-form v-else-if="curShowDialogParam.moduleName == dialogList.crmImproveClewForm.moduleName"
                               :ref="curShowDialogParam.moduleName"
                               :dataInfo="getData('dataInfo')"
                               @afterSuccess="execCustomEvent"
                               @cancelClick="closeDialog"
                               :isReadonly="getData('isReadonly')"
                               :isHideEditBtn="getData('isHideEditBtn')"></crm-improve-clew-form>
        <!-- 新增投资人 -->
        <add-clew-form v-else-if="curShowDialogParam.moduleName == dialogList.addClewForm.moduleName"
                       :ref="curShowDialogParam.moduleName"
                       :dataInfo="getData('dataInfo')"
                       :initCounselorKey="getData('initCounselorKey')"
                       @afterSuccess="execCustomEvent"
                       @cancelClick="closeDialog"
                       :isReadonly="getData('isReadonly')"></add-clew-form>
        <!-- 回收站 -->
        <recycleBinList v-else-if="curShowDialogParam.moduleName == dialogList.recycleBin.moduleName || curShowDialogParam.moduleName == dialogList.hqRecycleBin.moduleName"
                        :ref="curShowDialogParam.moduleName"
                        :dataInfo="getData('dataInfo')"
                        @afterSuccess="execCustomEvent"
                        @cancelClick="closeDialog"
                        :isReadonly="getData('isReadonly')"></recycleBinList>
        <!--上课统计 -->
        <class-statistics v-else-if="curShowDialogParam.moduleName == dialogList.classStatistics.moduleName"
                          :ref="curShowDialogParam.moduleName"
                          :dataInfo="getData('dataInfo')"
                          @afterSuccess="execCustomEvent"
                          @cancelClick="closeDialog"
                          :isReadonly="getData('isReadonly')"></class-statistics>
        <!--课件使用统计 -->
        <coursewareUseStatlist v-else-if="curShowDialogParam.moduleName == dialogList.coursewareUseStatlist.moduleName"
                               :ref="curShowDialogParam.moduleName"
                               :dataInfo="getData('dataInfo')"
                               @afterSuccess="execCustomEvent"
                               @cancelClick="closeDialog"
                               :isReadonly="getData('isReadonly')"></coursewareUseStatlist>
        <!--上课记录 -->
        <attend-class-record v-else-if="curShowDialogParam.moduleName == dialogList.attendClassRecord.moduleName"
                             :ref="curShowDialogParam.moduleName"
                             :dataInfo="getData('dataInfo')"
                             @afterSuccess="execCustomEvent"
                             @cancelClick="closeDialog"
                             :isReadonly="getData('isReadonly')"></attend-class-record>
        <!--备课记录 -->
        <prepare-lessons-record v-else-if="curShowDialogParam.moduleName == dialogList.prepareLessonsRecord.moduleName"
                                :ref="curShowDialogParam.moduleName"
                                :dataInfo="getData('dataInfo')"
                                @afterSuccess="execCustomEvent"
                                @cancelClick="closeDialog"
                                :isReadonly="getData('isReadonly')"></prepare-lessons-record>

        <!--老师上课统计 -->
        <class-statistics-teacher v-else-if="curShowDialogParam.moduleName == dialogList.classStatisticsTeacher.moduleName"
                                  :ref="curShowDialogParam.moduleName"
                                  :dataInfo="getData('dataInfo')"
                                  @afterSuccess="execCustomEvent"
                                  @cancelClick="closeDialog"
                                  :isReadonly="getData('isReadonly')"></class-statistics-teacher>
        <!--老师上课统计 -->
        <webv-class-statistics-teacher v-else-if="curShowDialogParam.moduleName == dialogList.WebvClassStatisticsTeacher.moduleName"
                                       :ref="curShowDialogParam.moduleName"
                                       :dataInfo="getData('dataInfo')"
                                       @afterSuccess="execCustomEvent"
                                       @cancelClick="closeDialog"
                                       :isReadonly="getData('isReadonly')"></webv-class-statistics-teacher>
        <!--老师上课统计 -->
        <webv-class-statistics v-else-if="curShowDialogParam.moduleName == dialogList.WebvClassStatistics.moduleName"
                               :ref="curShowDialogParam.moduleName"
                               :dataInfo="getData('dataInfo')"
                               @afterSuccess="execCustomEvent"
                               @cancelClick="closeDialog"
                               :isReadonly="getData('isReadonly')"></webv-class-statistics>
        <!-- 大课包详情 -->
        <bigCoursewareDetails v-else-if="curShowDialogParam.moduleName == dialogList.bigCoursewareDetails.moduleName"
                              :ref="curShowDialogParam.moduleName"
                              :dataInfo="getData('dataInfo')"
                              @afterSuccess="execCustomEvent"
                              @closeDialog="closeDialog"
                              :isReadonly="getData('isReadonly')"></bigCoursewareDetails>
        <!-- 课包详情教务门店老师 -->
        <bigCoursewareDetailst v-else-if="curShowDialogParam.moduleName == dialogList.bigCoursewareDetailst.moduleName"
                               :ref="curShowDialogParam.moduleName"
                               :dataInfo="getData('dataInfo')"
                               @afterSuccess="execCustomEvent"
                               @closeDialog="closeDialog"
                               :isReadonly="getData('isReadonly')"></bigCoursewareDetailst>
        <!-- 课件详情 -->
        <particular v-else-if="curShowDialogParam.moduleName == dialogList.CoursewareParticular.moduleName"
                    :ref="curShowDialogParam.moduleName"
                    :CoursewarePackage="getData('CoursewarePackage')"
                    @afterSuccess="execCustomEvent"
                    @closeDialog="closeDialog"
                    :isReadonly="getData('isReadonly')"></particular>
        <!-- 教务门店老师上课课件详情 -->
        <courseware-particular v-else-if="curShowDialogParam.moduleName == dialogList.CoursewareParticularForTeacher.moduleName"
                               :ref="curShowDialogParam.moduleName"
                               :CoursewarePackage="getData('CoursewarePackage')"
                               @afterSuccess="execCustomEvent"
                               @closeDialog="closeDialog"
                               :isReadonly="getData('isReadonly')"></courseware-particular>
        <!-- cws新增班级 -->
        <cwsAddClass v-else-if="curShowDialogParam.moduleName == dialogList.cwsAddClass.moduleName"
                     :ref="curShowDialogParam.moduleName"
                     :editItem="getData('editItem')"
                     :isReadonly="getData('isReadonly')"
                     @afterSuccess="execCustomEvent"
                     @cancelClick="closeDialog"></cwsAddClass>
        <!-- cws新增班级 -->
        <cws-add-teacher v-else-if="curShowDialogParam.moduleName == dialogList.cwsAddTeacher.moduleName"
                         :ref="curShowDialogParam.moduleName"
                         :editItem="getData('editItem')"
                         :isReadonly="getData('isReadonly')"
                         @afterSuccess="execCustomEvent"
                         @cancelClick="closeDialog"></cws-add-teacher>
        <!-- cws课件选择 -->
        <cws-choose-courseware v-else-if="curShowDialogParam.moduleName == dialogList.cwsChooseCourseware.moduleName"
                               :ref="curShowDialogParam.moduleName"
                               :editItem="getData('editItem')"
                               :isReadonly="getData('isReadonly')"
                               @afterSuccess="execCustomEvent"
                               @cancelClick="closeDialog"></cws-choose-courseware>
        <!-- CWS授权设备管理列表 -->
        <cwsAuthManagerList v-else-if="curShowDialogParam.moduleName == dialogList.cwsAuthManagerList.moduleName"
                            :ref="curShowDialogParam.moduleName"
                            :dataInfo="getData('dataInfo')"
                            @afterSuccess="execCustomEvent"
                            @closeDialog="closeDialog">
        </cwsAuthManagerList>
        <!-- CWS申请设备授权 -->
        <cws-apply-auth v-else-if="curShowDialogParam.moduleName == dialogList.cwsApplyAuth.moduleName"
                        :ref="curShowDialogParam.moduleName"
                        :dataInfo="getData('dataInfo')"
                        @afterSuccess="execCustomEvent"
                        @closeDialog="closeDialog"></cws-apply-auth>
        <!-- wevb申请设备授权 -->
        <webv-apply-auth v-else-if="curShowDialogParam.moduleName == dialogList.webvApplyAuth.moduleName"
                         :ref="curShowDialogParam.moduleName"
                         :dataInfo="getData('dataInfo')"
                         @afterSuccess="execCustomEvent"
                         @closeDialog="closeDialog"></webv-apply-auth>
        <!-- CWS老师授权列表 -->
        <cwsTeacherAuthList v-else-if="curShowDialogParam.moduleName == dialogList.cwsTeacherAuthList.moduleName"
                            :ref="curShowDialogParam.moduleName"
                            :dataInfo="getData('dataInfo')"
                            @afterSuccess="execCustomEvent"
                            @closeDialog="closeDialog"></cwsTeacherAuthList>
        <!-- csw授权老师表单 -->
        <cws-teacher-auth-cw v-else-if="curShowDialogParam.moduleName == dialogList.cwsTeacherAuthCw.moduleName"
                             :ref="curShowDialogParam.moduleName"
                             :dataInfo="getData('dataInfo')"
                             :teacherReadonly="getData('teacherReadonly')"
                             :coursewareReadonly="getData('coursewareReadonly')"
                             @closeDialog="closeDialog"
                             @cancelSuccess="execCustomEvent"
                             @afterSuccess="execCustomEvent"></cws-teacher-auth-cw>
        <!-- 通用 - 上课 -->
        <goToClass v-else-if="curShowDialogParam.moduleName == dialogList.cwsGoToClass.moduleName"
                   :ref="curShowDialogParam.moduleName"
                   :dataInfo="getData('dataInfo')"
                   @closeDialog="closeDialog"
                   @afterSuccess="execCustomEvent"></goToClass>
        <!-- 课件详情 -->
        <cwsClassDetail v-else-if="curShowDialogParam.moduleName == dialogList.cwsClassDetail.moduleName"
                        :ref="curShowDialogParam.moduleName"
                        :classKey="getData('classKey')"
                        :isTeacher="getData('isTeacher')"
                        @afterSuccess="execCustomEvent"
                        @closeDialog="closeDialog"></cwsClassDetail>
        <!-- 授权课件详情 -->
        <courseware-authorization-details v-else-if="curShowDialogParam.moduleName == dialogList.coursewareAuthorizationDetails.moduleName"
                                          :ref="curShowDialogParam.moduleName"
                                          :dataInfo="getData('dataInfo')"
                                          @afterSuccess="execCustomEvent"
                                          @closeDialog="closeDialog"></courseware-authorization-details>

        <!-- 通用-选择老师 -->
        <selectTeacher v-else-if="curShowDialogParam.moduleName == dialogList.selectTeacher.moduleName"
                       :ref="curShowDialogParam.moduleName"
                       :classKey="getData('classKey')"
                       :isTeacher="getData('isTeacher')"
                       @afterSuccess="execCustomEvent"
                       @closeDialog="closeDialog"></selectTeacher>
        <!-- cws新增学生 -->
        <cws-add-student v-else-if="curShowDialogParam.moduleName == dialogList.cwsAddStudent.moduleName"
                         :ref="curShowDialogParam.moduleName"
                         :editItem="getData('editItem')"
                         :isReadonly="getData('isReadonly')"
                         @afterSuccess="execCustomEvent"
                         @cancelClick="closeDialog"></cws-add-student>
        <!-- cws老师详情 -->
        <cwsteacherDetail v-else-if="curShowDialogParam.moduleName == dialogList.cwsteacherDetail.moduleName"
                          :ref="curShowDialogParam.moduleName"
                          :teacherKey="getData('teacherKey')"
                          :className="getData('className')"
                          @close="closeDialog"
                          @afterSuccess="execCustomEvent"></cwsteacherDetail>
        <!-- 开通功能 -->
        <open-options-form v-else-if="curShowDialogParam.moduleName == dialogList.openOptionsForm.moduleName"
                           :ref="curShowDialogParam.moduleName"
                           :campusInfo="getData('dataInfo')"
                           @close="closeDialog"
                           @afterSuccess="execCustomEvent"></open-options-form>
        <!-- 续费 -->
        <renewalForm v-else-if="curShowDialogParam.moduleName == dialogList.renewalForm.moduleName"
                     :ref="curShowDialogParam.moduleName"
                     :campusInfo="getData('dataInfo')"
                     @close="closeDialog"
                     @afterSuccess="execCustomEvent"></renewalForm>
        <!-- 添加课程加盟合作校区 -->
        <add-campus v-else-if="curShowDialogParam.moduleName == dialogList.addCampus.moduleName"
                    :ref="curShowDialogParam.moduleName"
                    :campusInfo="getData('dataInfo')"
                    @close="closeDialog"
                    @afterSuccess="execCustomEvent"></add-campus>

        <campus-list v-else-if="curShowDialogParam.moduleName == dialogList.campusForList.moduleName"
                     :ref="curShowDialogParam.moduleName"
                     :campusListTypeKey="getData('campusListTypeKey')"
                     :BigCoursewarePackageKey="getData('BigCoursewarePackageKey')"
                     @close="closeDialog"
                     @onSelectedItem="execCustomEvent"></campus-list>

        <campus-list-multiple-els v-else-if="curShowDialogParam.moduleName == dialogList.campusListMultipleEls.moduleName"
                                  :ref="curShowDialogParam.moduleName"
                                  :campusListTypeKey="getData('campusListTypeKey')"
                                  :BigCoursewarePackageKey="getData('BigCoursewarePackageKey')"
                                  :dataList="getData('dataList')"
                                  @close="closeDialog"
                                  @afterSuccess="execCustomEvent"></campus-list-multiple-els>
        <locationForm v-else-if="curShowDialogParam.moduleName == dialogList.locationForm.moduleName"
                      :ref="curShowDialogParam.moduleName"
                      :isReadonly="getData('isReadonly')"
                      :dataList="getData('dataList')"
                      @close="closeDialog"
                      @afterSuccess="execCustomEvent"></locationForm>
        <chooseCoursewareMultiple v-else-if="curShowDialogParam.moduleName == dialogList.chooseCoursewareMultiple.moduleName"
                                  :ref="curShowDialogParam.moduleName"
                                  :bigCoursewareData="getData('bigCoursewareData')"
                                  :checkedList="getData('checkedList')"
                                  :isActive="getData('isActive')"
                                  @close="closeDialog"
                                  @afterSuccess="execCustomEvent"></chooseCoursewareMultiple>
        <chooseAuthDataTime v-else-if="curShowDialogParam.moduleName == dialogList.chooseAuthDataTime.moduleName"
                            :ref="curShowDialogParam.moduleName"
                            :dataInfo="getData('dataInfo')"
                            @close="closeDialog"
                            @afterSuccess="execCustomEvent"></chooseAuthDataTime>

        <campus-form v-else-if="curShowDialogParam.moduleName == dialogList.campusForm.moduleName"
                     :ref="curShowDialogParam.moduleName"
                     :campusListTypeKey="getData('campusListTypeKey')"
                     :BigCoursewarePackageKey="getData('BigCoursewarePackageKey')"
                     @close="closeDialog"
                     @onSelectedItem="execCustomEvent"></campus-form>
        <!-- cws课件选择 -->
        <webv-choose-courseware v-else-if="curShowDialogParam.moduleName == dialogList.webvChooseCourseware.moduleName"
                                :ref="curShowDialogParam.moduleName"
                                :ScheduleCourseKey="getData('ScheduleCourseKey')"
                                @afterSuccess="execCustomEvent"
                                @cancelClick="closeDialog"></webv-choose-courseware>
        <cwscourseware-manager v-else-if="curShowDialogParam.moduleName == dialogList.cwscoursewareManager.moduleName"
                               :ref="curShowDialogParam.moduleName"
                               :searchData="getData('searchData')"
                               @close="closeDialog"
                               @afterSuccess="execCustomEvent"></cwscourseware-manager>
        <!-- 流量包 -->
        <flowPacketReport v-else-if="curShowDialogParam.moduleName == dialogList.flowPacketReport.moduleName"
                          :ref="curShowDialogParam.moduleName"
                          :dataInfo="getData('dataInfo')"
                          @close="closeDialog"
                          @afterSuccess="execCustomEvent"></flowPacketReport>
        <!-- 流量明细 -->
        <flowPacketDetailReport v-else-if="curShowDialogParam.moduleName == dialogList.flowPacketDetailReport.moduleName"
                                :ref="curShowDialogParam.moduleName"
                                :dataInfo="getData('dataInfo')"
                                :searchType="getData('searchType')"
                                @close="closeDialog"
                                @afterSuccess="execCustomEvent"></flowPacketDetailReport>
        <!-- 余额明细 -->
        <balanceDetailReport v-else-if="curShowDialogParam.moduleName == dialogList.balanceDetailReport.moduleName"
                             :ref="curShowDialogParam.moduleName"
                             :dataInfo="getData('dataInfo')"
                             @close="closeDialog"
                             @afterSuccess="execCustomEvent"></balanceDetailReport>
        <!-- 流量账单 -->
        <flowPacketBill v-else-if="curShowDialogParam.moduleName == dialogList.flowPacketBill.moduleName"
                        :ref="curShowDialogParam.moduleName"
                        :dataInfo="getData('dataInfo')"
                        @close="closeDialog"
                        @afterSuccess="execCustomEvent"></flowPacketBill>
        <!-- 余额充值 -->
        <rechargeTheBalance v-else-if="curShowDialogParam.moduleName == dialogList.rechargeTheBalance.moduleName"
                            :ref="curShowDialogParam.moduleName"
                            :putData="getData('putData')"
                            @close="closeDialog"
                            @afterSuccess="execCustomEvent"></rechargeTheBalance>
        <!-- 余额提现 -->
        <verifyIdentityFlow v-else-if="curShowDialogParam.moduleName == dialogList.verifyIdentityFlow.moduleName"
                            :ref="curShowDialogParam.moduleName"
                            @close="closeDialog"
                            @afterSuccess="execCustomEvent"></verifyIdentityFlow>
        <!-- 流量包明细 -->
        <HQflowPacketReport v-else-if="curShowDialogParam.moduleName == dialogList.HQflowPacketReport.moduleName"
                            :ref="curShowDialogParam.moduleName"
                            :dataInfo="getData('dataInfo')"
                            @close="closeDialog"
                            @afterSuccess="execCustomEvent"></HQflowPacketReport>
        <!-- 流量账单 -->
        <HQflowBillReport v-else-if="curShowDialogParam.moduleName == dialogList.HQflowBillReport.moduleName"
                          :ref="curShowDialogParam.moduleName"
                          :dataInfo="getData('dataInfo')"
                          @close="closeDialog"
                          @afterSuccess="execCustomEvent"></HQflowBillReport>

        <!-- cws自研课件管理 -->
        <cwsgeneralCoursewareManagement v-else-if="curShowDialogParam.moduleName == dialogList.cwsgeneralCoursewareManagement.moduleName"
                                        :ref="curShowDialogParam.moduleName"
                                        :dataInfo="getData('dataInfo')"
                                        @close="closeDialog"
                                        @afterSuccess="execCustomEvent"></cwsgeneralCoursewareManagement>
        <!-- 课件使用账单 -->
        <coursewareUseReport v-else-if="curShowDialogParam.moduleName == dialogList.coursewareUseReport.moduleName"
                             :ref="curShowDialogParam.moduleName"
                             :dataInfo="getData('dataInfo')"
                             @close="closeDialog"
                             @afterSuccess="execCustomEvent"></coursewareUseReport>
        <!-- 校区课件使用账单 -->
        <courseAttendClassList v-else-if="curShowDialogParam.moduleName == dialogList.courseAttendClassList.moduleName"
                               :ref="curShowDialogParam.moduleName"
                               :dataInfo="getData('dataInfo')"
                               @close="closeDialog"
                               @afterSuccess="execCustomEvent"></courseAttendClassList>
        <!-- 课件安全验证 -->
        <courseAuthQrcode v-else-if="curShowDialogParam.moduleName == dialogList.courseAuthQrcode.moduleName"
                          :ref="curShowDialogParam.moduleName"
                          :dataInfo="getData('dataInfo')"
                          @close="closeDialog"
                          @afterSuccess="execCustomEvent"></courseAuthQrcode>
        <!-- 约课建课 -->
        <new-appointment-course v-else-if="curShowDialogParam.moduleName == dialogList.newAppointmentCourse.moduleName"
                                :ref="curShowDialogParam.moduleName"
                                :dataInfo="getData('dataInfo')"
                                @closeDialog="closeDialog"
                                @afterSuccess="execCustomEvent">

        </new-appointment-course>
        <!--约课时间设置 -->
        <appointmentCourseSetting v-else-if="curShowDialogParam.moduleName == dialogList.appointmentCourseSetting.moduleName"
                                  :ref="curShowDialogParam.moduleName"
                                  :dataInfo="getData('dataInfo')"
                                  @close="closeDialog"
                                  @afterSuccess="execCustomEvent"></appointmentCourseSetting>
        <!-- 安排约课 -->
        <appoint-arrange v-else-if="curShowDialogParam.moduleName == dialogList.appointArrange.moduleName"
                         :ref="curShowDialogParam.moduleName"
                         :courseKey="getData('courseKey')"
                         :ClassName="getData('ClassName')"
                         @afterSuccess="execCustomEvent"
                         @closeDialog="closeDialog"></appoint-arrange>
        <!-- 修改约课 -->
        <edit-appoint-course v-else-if="curShowDialogParam.moduleName == dialogList.editAppointCourse.moduleName"
                             :ref="curShowDialogParam.moduleName"
                             :courseInfo="getData('courseInfo')"
                             @afterSuccess="execCustomEvent"
                             @closeDialog="closeDialog"></edit-appoint-course>
        <!--推荐作品 -->
        <look-works-popup v-else-if="curShowDialogParam.moduleName == dialogList.lookWorksPopup.moduleName"
                          :ref="curShowDialogParam.moduleName"
                          :PhotoUrl="getData('PhotoUrl')"
                          @afterSuccess="execCustomEvent"
                          @closeDialog="closeDialog"></look-works-popup>
        <!-- 考勤课时校正 -->
        <change-atten-list v-else-if="curShowDialogParam.moduleName == dialogList.changeAttenList.moduleName"
                           :ref="curShowDialogParam.moduleName"
                           :ScheduleCourseKey="getData('ScheduleCourseKey')"
                           @close="closeDialog"
                           @afterSuccess="execCustomEvent"></change-atten-list>
        <!-- ios录屏监控列表 -->
        <screen-record-list v-else-if="curShowDialogParam.moduleName == dialogList.screenRecordList.moduleName"
                            :ref="curShowDialogParam.moduleName"
                            :dataInfo="getData('dataInfo')"
                            @close="closeDialog"
                            @afterSuccess="execCustomEvent"></screen-record-list>
         <!-- 教学模式主题 -->
        <courseware-teachingmode-setting v-else-if="curShowDialogParam.moduleName == dialogList.coursewareTeachingmodeSetting.moduleName"
                            :ref="curShowDialogParam.moduleName"
                            :dataInfo="getData('dataInfo')"
                            @close="closeDialog"
                            @afterSuccess="execCustomEvent"></courseware-teachingmode-setting>
        <!-- 登记客户线索 -->
        <clew-add-form v-else-if="curShowDialogParam.moduleName==dialogList.clewAddForm.moduleName"
                           :ref="curShowDialogParam.moduleName"
                           :dataInfo="getData('dataInfo')"
                           @close="closeDialog"
                           @afterSuccess="execCustomEvent"></clew-add-form>
         <!-- 严格模式备注 -->
        <strict-mode-reason v-else-if="curShowDialogParam.moduleName==dialogList.strictModeReason.moduleName"
                           :ref="curShowDialogParam.moduleName"
                           :dataInfo="getData('dataInfo')"
                           @close="closeDialog"
                           @afterSuccess="execCustomEvent">
        </strict-mode-reason>
        <!-- 课评模板列表 -->
        <comment-report-list v-else-if="curShowDialogParam.moduleName==dialogList.commentReportList.moduleName"
                           :ref="curShowDialogParam.moduleName"
                           :dataInfo="getData('dataInfo')"
                           @close="closeDialog"
                           @afterSuccess="execCustomEvent">
        </comment-report-list>
      </custom-dialog>
    </div>
  </div>
</template> 
<script>
import WebvClassStatistics from '../teacher-atten-class/class-statistics/index';
import WebvClassStatisticsTeacher from '../teacher-atten-class/class-statistics/teacher-statistics';
import classStatisticsTeacher from '../courseware-work-bench/components/report/class-statistics/teacher-statistics';
import classStatistics from '../courseware-work-bench/components/report/class-statistics/index';
import coursewareUseStatlist from '../courseware-work-bench/components/report/class-statistics/courseware-use-statlist';
import editPassWord from '../chain-work-bench/edit-pass-word';
import signUpPayment from '../student/sign-up-payment/index';
import zxPaymentHintDialog from '../common/zx-payment-hint-dialog';
import studentList from '../common/student-list'; // 学生列表
import gradeList from '../common/grade-list'; // 班级列表
import mergeStudentPage from '../pop-up-menu/exception-handle/merge-student-page/index';
import arrangeTryCourse from '../pop-up-menu/arrange-try-course/index';
// import studentFile from '../pop-up-menu/student-file/index';
import studentFile from '../student/student-file/index'; // App学生档案
import registerCustomer from '../student/register-customer/index';
import registerCustomerDetails from '../pop-up-menu/register-customer-details';
import courseApplycourse from '../pop-up-menu/course-applycourse/index';
import studentApplyCourse from '../base-module/apply-course/context-index';
import debtCourseHourseDetails from '../pop-up-menu/sign-up-payment/debt-course-hourse-details';
import courseClassSetting from '../base-module/basis-setting-module/course-class-setting/setting-item'; // 课类设置
import courseNameSetting from '../base-module/basis-setting-module/course-name-setting/setting-item'; // 课程设置
import squadClassSetting from '../base-module/basis-setting-module/squad-class-Setting/setting-item'; // 班级设置
import saturationCourseSetting from '../base-module/basis-setting-module/potential-excavate-setting/saturation-course-setting'; // 排课创收潜力挖掘 - 校区饱和排课设置 1
import appointCourseSetting from '../base-module/basis-setting-module/potential-excavate-setting/appoint-course-setting'; // 排课创收潜力挖掘 - 校区饱和排课设置 2
import courseSettingItem from '../base-module/basis-setting-module/potential-excavate-setting/setting-item'; // 排课创收潜力挖掘 - 学生/班级/满班率 出勤级别
import squadClassTypeSetting from '../base-module/basis-setting-module/squad-class-type-setting/setting-item'; // 班级类型设置
import classroomSetting from '../base-module/basis-setting-module/classroom-setting/setting-item'; // 教室设置
import departmentSetting from '../base-module/basis-setting-module/department-setting/setting-item'; // 部门设置
import postSetting from '../base-module/basis-setting-module/post-setting/setting-item'; // 职务设置
import incomeAndExpensesSubjectSetting from '../base-module/basis-setting-module/incomeAndExpenses-subject-setting/setting-item'; // 财务科目设置
import proceedsAccountSetting from '../base-module/basis-setting-module/proceeds-account-setting/setting-item'; // 收款方式设置
import incidentalsSetting from '../base-module/basis-setting-module/incidentals-setting/setting-item'; // 学杂费设置
import supplierSetting from '../base-module/basis-setting-module/supplier-setting/setting-item'; // 供应商设置
import communitySetting from '../base-module/basis-setting-module/community-setting/setting-item'; // 住宅设置
import sellSourceSetting from '../base-module/basis-setting-module/sell-source-setting/setting-item'; // 客户来源设置
import listTaocanSetting from '../base-module/basis-setting-module/list-taocan-setting/taocan-from/setting-item'; // 套餐设置
import listYuangongSetting from '../base-module/basis-setting-module/list-yuangong-setting/setting-item'; // 员工设置
import continueNewCircleCourseTable from '../relation-course-component/continue-new-circle-course-table'; // 续建课表
import circleCourseDetail from '../relation-course-component/circle-course-detail'; // 循环课详情
import editCircleCourseTable from '../relation-course-component/edit-circle-course-table/index'; // 修改循环课
import batchArrangeCourse from '../student-arrange-course/student-relation-course-component/batch-arrange-course';
import studentArrangeCourseByCourseName from '../student-arrange-course/by-course-name/index'; // 按课程排课
import studentArrangeCourseByClass from '../student-arrange-course/by-class/index'; // 跟班级排课
import couresPurchaseConsumeDetails from '../pop-up-menu/student-file/coures-purchase-consume-details.vue';
import courseIDApply from '../base-module/course-id-apply';
import classDetail from '../pop-up-menu/class-file/class-detail'; // 课程明细
import editCourse from '../common/edit-course'; // 修改单课
import crmSignContractForm from '../base-module/basis-setting-module/crm-form-components/crm-sign-contract-form'; // 签约详情、签约表单
import crmPaymentForm from '../base-module/basis-setting-module/crm-form-components/crm-payment-form'; // 付款申请、付款审核
import paymentAndWithdraw from '../pop-up-menu/payment-and-withdraw/index';
import writeOffArrearsTable from '../pop-up-menu/write-off-arrears-table/index';// 欠款销账
import deleteBillTable from '../pop-up-menu/delete-bill-table/index';// 作废单据
import quitOrEnterClassForm from '../base-module/basis-setting-module/squad-class-Setting/student-class-form/quit-or-enter-class-form'; // 学生进班/退班
import changeCourseBill from '../pop-up-menu/exception-handle/change-course-bill/index'; // 修改课单
import refundMoneyForBackCourse from '../pop-up-menu/exception-handle/refund-money-back-course/index'; // 课时退款
import transferCourseBill from '../pop-up-menu/exception-handle/transfer-course-bill/index'; // 转让课单
import addStudentToBJ from '../pop-up-menu/student-file/student-file-item/add-student-to-bj';
import arrangeSupportEdit from '../student/arrange-support-edit'; // 修改排课备注
import attendanceDetail from '../course-table/attendance-detail/index';
// import releaseHomeTask from '../home-school-communication/release-essay/release-home-task/index';// 布置作业
import releaseHomeTask from '../home-school-communication/release-essay/new-release-home-task/index'; // 布置作业
// import releaseComment from '../home-school-communication/release-essay/release-comment/index'; // 发布点评
import releaseComment from '../home-school-communication/release-essay/new-release-comment/index'; // 发布点评
import taskCorrect from '../home-school-communication/home-task-page/task-correct/index';
import correctHomeTask from '../home-school-communication/release-essay/correct-home-task/index';
import taskDetail from '../home-school-communication/home-task-page/task-detail';
import detailsList from '../home-school-communication/common-component/student-coin-details/details-list';
import weChatTemplate from '../base-module/basis-setting-module/mendian-setting-from/module-message-setting/weChat-template';
import attendanceArrange from '../course-table/attendance-arrange/index'; // 安排考勤
import debtClassCountDetail from '../student/student-file/components/class-hour-spend/debt-class-count-detail/index'; // 欠课时详情
import attenCourseSpendDetails from '../student/student-file/components/class-hour-spend/atten-course-spend-detail/index'; // 欠课考勤课消详情时详情
import goodsNameSettingForm from '../report/inventory-management-file/inventory-detail-file/goods-setting/goods-name-setting-form'; // 物品名称设置
import arrangeCourse from '../report/try-arrange-course/components/arrange-course';
import verifyStudentPopup from '../common/popups-box/verify-student-popup';
import applyAuth from '../pop-up-menu/auth-components/apply-auth'; // 申请设备授权
import authManagerList from '../pop-up-menu/auth-components/auth-manager-list'; // 申请设备授权
import improveClewForm from '../head-quarters/headquarters-management/merchants-counselor-workbench/form/improve-clew-form';
import distributeInvestmentAdvisor from '../head-quarters/attract-investment-manage/common-dialog/distribute-investment-advisor'; // 总部招商管理 分配顾问
import crmImproveClewForm from '../base-module/basis-setting-module/crm-form-components/crm-improve-clew-form'; // 总部投资人详情
import addClewForm from '../base-module/basis-setting-module/crm-form-components/add-clew-form'; // 新增投资人
import cwsAddClass from '../courseware-work-bench/components/form/add-class'; // cws新增班级
import cwsAddTeacher from '../courseware-work-bench/components/form/add-teacher'; // cws新增班级
import cwsAddStudent from '../courseware-work-bench/components/form/add-student'; // cws新增班级
import cwsChooseCourseware from '../courseware-work-bench/components/choose-courseware/index'; // cws选择课包

import cwsAuthManagerList from '../courseware-work-bench/components/report/auth-components/auth-manager-list.vue';
import cwsApplyAuth from '../courseware-work-bench/components/report/auth-components/apply-auth.vue';
import webvApplyAuth from '../teacher-atten-class/auth-components/apply-auth.vue';
import cwsTeacherAuthList from '../courseware-work-bench/components/report/teecher-auth-list/index.vue';
import cwsTeacherAuthCw from '../courseware-work-bench/components/form/teacher-auth-cw';
import goToClass from '../courseware-work-bench/components/goToClass';
import bigCoursewareDetails from '../courseware-work-bench/components/big-courseware-details/index.vue';// 大课包详情
import bigCoursewareDetailst from '../teacher-atten-class/big-courseware-details/index.vue';// 教务门店老师课包详情
import CoursewareParticular from '../teacher-atten-class/particular/index.vue';// 教务门店老师上课课件详情
import particular from '../courseware-work-bench/components/particular/index.vue';// 课件详情
import cwsClassDetail from '../courseware-work-bench/components/class-detail/index.vue';// 课件详情
import selectTeacher from '../courseware-work-bench/components/selectTeacher'; // 选择老师
import cwsteacherDetail from '../courseware-work-bench/right-box-work-bench/home-page-details/index.vue';
import openOptionsForm from '../head-quarters/basis-setting-module/form/open-options-form.vue';
import renewalForm from '../head-quarters/headquarters-management/campus-management/form/renewal-form.vue';
import addCampus from '../head-quarters/basis-setting-module/form/add-campus/index.vue';
import attendClassRecord from '../head-quarters/common-modules/courseware-authorization-details/attend-class-record/index';
import prepareLessonsRecord from '../head-quarters/common-modules/courseware-authorization-details/prepare-lessons-record/index';
import campusList from '../head-quarters/common-modules/campus-list/index';
import campusListMultipleEls from '../head-quarters/headquarters-management/authorization-courseware/form/campus-list-multiple.vue';
import locationForm from '../head-quarters/headquarters-management/authorization-courseware/form/location-form.vue';
import chooseCoursewareMultiple from '../head-quarters/headquarters-management/authorization-courseware/form/choose-curseware.vue';
import chooseAuthDataTime from '../head-quarters/headquarters-management/authorization-courseware/form/chooseDataTime.vue';
import coursewareAuthorizationDetails from '../head-quarters/common-modules/courseware-authorization-details/index';// 授权课件详情
import campusForm from '../head-quarters/common-modules/courseware-authorization-details/attend-class-record/campus-form.vue';
import webvChooseCourseware from '../course-table/attendance-arrange/choose-courseware/index'; // cws选择课包
import cwscoursewareManager from '../courseware-work-bench/components/report/courseware-manager/index';
import flowPacketReport from '../report/flow-packet-report/index';
import flowPacketDetailReport from '../report/flow-packet-report/flow-packet-detail-report/index';
import balanceDetailReport from '../report/flow-packet-report/balance-detail-report/index';
import flowPacketBill from '../report/flow-packet-report/flow-packet-bill/index';
import rechargeTheBalance from '../base-module/basis-setting-module/mendian-setting-from/recharge-the-balance/index';
import verifyIdentityFlow from '../base-module/basis-setting-module/mendian-setting-from/recharge-the-balance/verify-identity.vue';
import HQflowPacketReport from '../report/flow-packet-report/hqflow-packet-report/index';
import HQflowBillReport from '../report/flow-packet-report/hqflow-bill-report/index';
import cwsgeneralCoursewareManagement from '../courseware-management/index.vue';
import coursewareUseReport from '../report/courseware-use-report/index';
import courseAttendClassList from '../head-quarters/common-modules/courseware-authorization-details/attend-class-record/attend-class-list';
import courseAuthQrcode from '../course-table/attendance-arrange/teaching-components/course-auth-qrcode';
import appointmentCourseSetting from '../course-table/appointment-course/subscribe-course-setting/courseSetting';
import newAppointmentCourse from '../course-table/new-appointment-course/index';

import appointArrange from '../course-table/appoint-arrange/index'; // 安排考勤
import editAppointCourse from '../course-table/appoint-arrange/edit-appoint-course'; // 修改单课
import lookWorksPopup from '../works-upload-management/recommended-works/look-works-popup'; // 推荐作品
import changeAttenList from '../course-table/attendance-arrange/components/change-atten-list/index';
import screenRecordList from '../chain-work-bench/todo-list/screen-record-list';
import coursewareTeachingmodeSetting from '../head-quarters/basis-setting-module/form/courseware-teachingmode-setting/index';
import clewAddForm from '../student/clew-add-form/index';
import strictModeReason from '../course-table/attendance-arrange/components/strict-mode-reason'
import commentReportList from '../report/comment-report-list/index'
const recycleBinList = () => import('../courseware-management/recycle-bin-list.vue'); // 回收站懒加载
export default {
  data () {
    return {
      registerDialogList: [], // 注册的弹窗
      isShowDialog: false, // 是否实例化弹窗
      dialogParamList: [], // 对应打开弹窗参数
      curShowDialogParam: {}, // 当前显示弹窗参数
      dialogList: this.$initJson.allCustomerDialogNames
    };
  },
  components: {
    WebvClassStatistics,
    WebvClassStatisticsTeacher,
    classStatisticsTeacher,
    classStatistics,
    coursewareUseStatlist,
    editPassWord,
    signUpPayment,
    zxPaymentHintDialog,
    studentList,
    gradeList,
    arrangeTryCourse,
    studentFile,
    registerCustomer,
    registerCustomerDetails,
    courseApplycourse,
    studentApplyCourse,
    debtCourseHourseDetails,
    courseClassSetting,
    courseNameSetting,
    squadClassSetting,
    saturationCourseSetting,
    appointCourseSetting,
    courseSettingItem,
    squadClassTypeSetting,
    classroomSetting,
    departmentSetting,
    postSetting,
    incomeAndExpensesSubjectSetting,
    proceedsAccountSetting,
    incidentalsSetting,
    supplierSetting,
    communitySetting,
    sellSourceSetting,
    listTaocanSetting,
    listYuangongSetting,
    studentArrangeCourseByCourseName,
    studentArrangeCourseByClass,
    couresPurchaseConsumeDetails,
    mergeStudentPage,
    courseIDApply,
    classDetail,
    editCourse,
    paymentAndWithdraw,
    writeOffArrearsTable,
    deleteBillTable,
    crmSignContractForm,
    crmPaymentForm,
    changeCourseBill,
    continueNewCircleCourseTable,
    circleCourseDetail,
    editCircleCourseTable,
    quitOrEnterClassForm,
    batchArrangeCourse,
    debtClassCountDetail,
    attenCourseSpendDetails,
    refundMoneyForBackCourse,
    transferCourseBill,
    addStudentToBJ,
    arrangeSupportEdit,
    attendanceDetail,
    releaseHomeTask,
    releaseComment,
    taskCorrect,
    correctHomeTask,
    taskDetail,
    detailsList,
    weChatTemplate,
    attendanceArrange,
    goodsNameSettingForm,
    arrangeCourse,
    verifyStudentPopup,
    authManagerList,
    applyAuth,
    improveClewForm,
    distributeInvestmentAdvisor,
    crmImproveClewForm,
    addClewForm,
    cwsAddClass,
    cwsAddTeacher,
    cwsChooseCourseware,
    recycleBinList,
    particular,
    CoursewareParticular,
    cwsAuthManagerList,
    cwsApplyAuth,
    webvApplyAuth,
    cwsTeacherAuthList,
    cwsTeacherAuthCw,
    goToClass,
    cwsClassDetail,
    selectTeacher,
    coursewareAuthorizationDetails,
    cwsteacherDetail,
    cwsAddStudent,
    openOptionsForm,
    renewalForm,
    addCampus,
    bigCoursewareDetails,
    bigCoursewareDetailst,
    attendClassRecord,
    prepareLessonsRecord,
    campusList,
    campusListMultipleEls,
    locationForm,
    chooseCoursewareMultiple,
    chooseAuthDataTime,
    campusForm,
    webvChooseCourseware,
    cwscoursewareManager,
    flowPacketReport,
    flowPacketDetailReport,
    balanceDetailReport,
    flowPacketBill,
    rechargeTheBalance,
    verifyIdentityFlow,
    HQflowPacketReport,
    HQflowBillReport,
    cwsgeneralCoursewareManagement,
    coursewareUseReport,
    courseAttendClassList,
    courseAuthQrcode,
    appointmentCourseSetting,
    newAppointmentCourse,
    appointArrange,
    editAppointCourse,
    lookWorksPopup,
    changeAttenList,
    screenRecordList,
    coursewareTeachingmodeSetting,
    clewAddForm,
    strictModeReason,
    commentReportList
  },
  created () {
    this.$bus.on(this, 'switchHeaderBar', () => {
      this.$nextTick(() => {
        this.resetEvents(this.dialogParamList);
      });
    });
  },
  methods: {
    openDialogPlus (moduleName, dataKey, dataInfo, CallbackList, title) {
      console.log('打开通用弹窗:', this.isShowDialog);
      // console.log('dialog',this.$initJson.allCustomerDialogNames);
      // 之前已打开弹窗，需要关闭之前的
      if (this.isShowDialog) {
        this.curShowDialogParam = {};
      }
      this.isShowDialog = true;
      this.$nextTick(() => {
        this.dialogList[moduleName].title =
          title || this.dialogList[moduleName].title;
        this.curShowDialogParam = {
          title: this.dialogList[moduleName].title,
          moduleName: moduleName,
          dataKey: dataKey,
          data: dataInfo,
          CallbackList: CallbackList,
          dialogWidth: this.dialogList[moduleName].dialogWidth,
          moduleDescription: this.dialogList[moduleName].moduleDescription,
          className: this.dialogList[moduleName].className
        };
        this.dialogParamList.splice(0, 1, this.curShowDialogParam);
        setTimeout(() => {
          // 节点绑定的具体事件列表
          this.resetEvents();
        }, 300);
      });
    },
    // 赋值
    resetEvents () {
      if (Object.keys(this.curShowDialogParam).length > 0) {
        // 节点绑定的具体事件列表
        var evs = '';
        if (this.$refs[this.curShowDialogParam.moduleName]) {
          evs = this.$refs[this.curShowDialogParam.moduleName]._events;
        }
        for (var evName in evs) {
          if (evs[evName].length > 0) {
            let ev = evs[evName][0];
            var isclose = ev.fns && ev.fns.name == 'bound closeDialog';
            if (isclose) {
              ev.fns = () => {
                this.closeDialog(
                  this.curShowDialogParam.moduleName,
                  evName,
                  arguments
                );
              };
            } else {
              if (this.curShowDialogParam.CallbackList) {
                let cEv = this.curShowDialogParam.CallbackList[evName];
                if (cEv) {
                  ev.fns = cEv;
                }
              }
            }
          }
        }
      }
    },
    // 回调占位事件
    execCustomEvent () {
      console.log('调到了');
    },
    // 获取数据
    getData (dataName) {
      let data = '';
      if (this.curShowDialogParam.data) {
        data = this.curShowDialogParam.data[dataName];
        return data;
      }
    },
    // 关闭弹窗
    closeDialog () {
      let needCloseModuleIndex = this.dialogParamList.findIndex(obj => {
        return obj.moduleName == arguments[0];
      });
      console.log(needCloseModuleIndex, 'needCloseModuleIndex');
      if (needCloseModuleIndex >= 0) {
        this.dialogParamList.splice(needCloseModuleIndex, 1);
      } else if (!arguments[0]) {
        this.dialogParamList.splice(0, 1);
        this.$nextTick(() => {
          this.resetEvents(this.dialogParamList);
        });
      }
    }
  }
};
</script>
<style>
</style>
