<template>
  <!-- 学生补课汇总 -->
  <div class="repair_students_view course_class_summarizing clearfix">

    <!-- 全部课程 -->
    <div class="course_class_summarizing_left">
      <div class="summarizing_number_left">
        <span style="font-size: 20px;">全部课程</span>
        <span style="color: #3498db;cursor: pointer;"
              class="fr"
              @click="repairCourseClick">
          <span style="font-size: 16px;"
                class="font_number">设置</span>
        </span>
      </div>
      <div class="left_search_box"
           style="position: relative;padding: 0 10px;">
        <el-select :value="vClassTypeInfo"
                   filterable
                   placeholder="必选"
                   @change="changeClassType"
                   value-key="OLAPKey">
          <el-option class="select_class_type"
                     v-for="item in classType"
                     :key="item.type"
                     :label="item.name"
                     :value="item"></el-option>
        </el-select>
        <div class="left_search">
          <input type="text"
                 placeholder="搜索课程"
                 v-model="searchTextForCourseName" />
          <span v-show="searchTextForCourseName"
                class="home_remove_btn"
                @click="searchTextForCourseName=''"></span>
        </div>
      </div>
      <div class="left_content"
           style="margin-top: 20px;">
        <class-item v-for="(item, index) in vApplyGradeNameList"
                    :key="index"
                    dir="ltr"
                    :courseItem="item"
                    @clickCourse="doAfterClickClass"></class-item>
        <div v-if="vApplyGradeNameList.length == 0"
             class="course_block_nodata"
             style="background-size: 100px">
          <div class="monitoring_content_nodata_txt">
            亲，当前没有任何数据哦~
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="course_class_right">

      <div class="repair_students_info"
           v-for="(item,index) in repairCourseList"
           :key="index"
           :class="(repairCourseList.length - 1) == index?'no_border':''"
           :id="'course'+item[0].CourseNameKey">
        <div class="course_top">
          <div class="coures_name">{{ item[0].CourseNameKeyValue }}
            <!-- <span class="wait_info">待处理：<span style="color: red;">{{ item[0].PersonCount }}</span>人 |
              <span style="color: red;">{{ item[0].CourseCount }}</span> 课次</span>  -->
          </div>
          <div class="time_data_div"
               v-for="(chid,cindex) in item"
               :key="cindex">
            <div class="person_course_count"
                 :style="{border:(searchData[index].tIndex == cindex)?'1px solid #3498db':'none',color:(searchData[index].tIndex == cindex)?'#3498db':'#000'}"
                 @click.stop="personCourse(chid,cindex,item,index)">
              <div class="month_title"
                   :style="{color:(searchData[index].tIndex == cindex)?'#3498db':'#666'}">{{chid.ClassTime.slice(5, 8)}}月</div>
              <div class="p_c_count"
                   v-if="chid.PersonCount || chid.CourseCount">
                <span>{{ chid.PersonCount || '-'}}</span>人 |
                <span>{{ chid.CourseCount || '-'}}</span> 课次
              </div>
              <div class="p_c_count"
                   v-else>
                <span>-</span>
              </div>
            </div>
            <!--  -->

          </div>
        </div>
        <div class="course_search_div"
             style="display: flex;">
          <input-search-contain-btn v-model="searchData[index].searchText"
                                    :placeholder="'搜索学生'"
                                    @onSearchEvent="searchTextSearch(searchData[index].searchText,index,item[0])"
                                    @clearSearch="clearSearchCondition(searchData[index].searchText,index,item[0])"></input-search-contain-btn>
        </div>

        <div class="course_content">
          <div class="course_info_div"
               v-for="(child,i) in item.studentsList"
               :key="i"
               @click="openDetail(child,searchData[index].StartTime,searchData[index].EndTime)">
            <div class="student_info_div">{{ child.StudentNameKeyValue }}</div>
            <div class="phone_info">{{ child.CustomerPhoneName }}</div>
            <div class="handle_info">待处理：<span style="color: red;">{{ child.WaitingCount }}</span></div>
            <div class="handle_info">已处理：<span>{{ child.ResolvedCount }}</span></div>
            <div class="handle_info">共缺勤：<span>{{ child.AllMakeUpCount }}</span></div>
          </div>
          <div v-if="item.studentsList.length == 0"
               style="width: 100%;">
            <div class="has_arrange_course">
              <div class="has_arrange_course_content"
                   style="padding:0">
                <div class="has_arrange_course_list">
                  <div class="has_arrange_course_nodata single_shadow">
                    <span>亲，当前没有任何数据哦~</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div v-if="repairCourseList.length == 0">
        <div class="has_arrange_course">
          <div class="has_arrange_course_content"
               style="padding:0">
            <div class="has_arrange_course_list">
              <div class="has_arrange_course_nodata single_shadow">
                <span>亲，当前没有任何数据哦~</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <custom-dialog title="设置补课处理规则"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowRepairCourseSet"
                   :before-close="closeRepairCourseSetPopup">
      <repair-course-setting :formTitle="'设置补课处理规则'"
                             @afterSuccess="afterSuccess"
                             @cancelClick="closeRepairCourseSetPopup">
      </repair-course-setting>
    </custom-dialog>
    <custom-dialog title="学生补课处理详情"
                   width="1200px"
                   :visible.sync="isShowDetail"
                   :before-close="closeDetail">
      <repair-student-detail :studentobj="dataDetail"></repair-student-detail>
    </custom-dialog>
  </div>
</template>
<script>
import singleYearPicker from '../../../base-module/date-picker-common/single-year-picker';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import repairCourseSetting from '../repair-course-setting';
import { GetPendingMakeUpCoursePersonCount, MessageSearchPageMakeUpCourseStudens, GetMakeUpSetting } from '../../../../API/workbench.js';
import repairStudentDetail from '../repair-student-detail/index.vue';
import classItem from '../../course-schedule-management/general-class-grade-apply-course-list/class-item.vue';
export default {
  data () {
    return {
      curIndex: 0,
      curSelectedClassInfo: {}, // 当前选中课程
      searchTextForCourseName: '', // 搜索课程
      applyGradeNameList: [],
      // 课程类型
      classType: [
        { type: 0, name: '全部' },
        { type: 1, name: '简洁' }
      ],
      allCourseInfo: {
        courseKey: 0,
        courseKeyValue: '全部'
      }, // 是否简洁

      isShowRepairCourseSet: false,
      searchText: '',
      DefaultStartDate: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'), // 默认开始时间
      DefaultEndDate: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0)), // 默认结束时间
      courseTime: {
        StartTime: '',
        EndTime: ''
      },
      TraceabilityTimeType: {
        SetContent: 3,
        SetKey: 0,
        SetKeyValue: '关闭'
      }, // 补课可追溯时长
      setDataObj: {},
      setSearchTitle: '',
      repairCourseList: [],
      searchData: [], // 为了查询数据
      studentsList: [],
      timeIndex: -1,
      inputIndex: -1,
      courseIndex: -1,
      isShowDetail: false,
      dataDetail: {
        key: '',
        name: '',
        tel: '',
        img: '',
        course: '',
        StartTime: '',
        EndTime: ''
      }
    };
  },
  components: {
    singleYearPicker,
    inputSearchContainBtn,
    repairCourseSetting,
    repairStudentDetail,
    classItem
  },
  watch: {
    'curSelectedClassInfo.OLAPKey': {
      handler (n, o) {
        if (n > 0 && n != o) {
          this.tableKey = n;
        }
      },
      deep: true
    }
  },
  computed: {
    vApplyGradeNameList () {
      let newList = [];
      if (this.searchTextForCourseName) {
        let trueSearchText = this.searchTextForCourseName.toLowerCase();
        this.applyGradeNameList.forEach((obj) => {
          if (obj.MainDemoName.toLowerCase().indexOf(trueSearchText) > -1) {
            newList.push(obj);
          }
        });
      } else {
        newList = this.applyGradeNameList;
      }
      console.log(newList, 'newList');
      return newList;
    },
    // 来源方式
    vClassTypeInfo () {
      return this.allCourseInfo.courseKeyValue;
    },
    vDataList () {
      console.log(this.repairCourseList, 'this.repairCourseList');
      return this.repairCourseList;
    },
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear()));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      if (this.TraceabilityTimeType.SetContent == 3) {
        month = nowDate.getMonth() - 1;
      } else if (this.TraceabilityTimeType.SetContent == 2) {
        month = nowDate.getMonth();
      } else if (this.TraceabilityTimeType.SetContent == 1) {
        month = nowDate.getMonth() + 1;
      }
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr, 'this.TraceabilityTimeType.SetContent', this.TraceabilityTimeType.SetContent);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear()));
      let year = nowDate.getFullYear();
      let month = nowDate.getMonth() + 1;
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    }
  },
  created () {
    this.getGetMakeUpSetting();
    this.getGetPendingMakeUpCoursePersonCount();
  },
  mounted () {

  },
  methods: {
    doAfterClickClass (classItem) {
      console.log(classItem, '点击班级');
      if (this.$utils.isObjEqual(this.curSelectedClassInfo, classItem)) {
        // 防止重复点击
        return false;
      }
      let courseDo = document.getElementById('course' + classItem.CourseNameKey);
      console.log(courseDo, 'aa');
      courseDo.scrollIntoView({ behavior: 'smooth', block: 'center' });
      this.$set(this.curSelectedClassInfo, 'isActive', false);
      this.curSelectedClassInfo = classItem;
    },
    // 选择课程类型
    changeClassType (obj) {
      console.log(obj, '选择来源方式');
      this.allCourseInfo.courseKey = obj.type;
      this.allCourseInfo.courseKeyValue = obj.name;
      this.getGetPendingMakeUpCoursePersonCount();
    },
    // 获取补课追溯时长
    getGetMakeUpSetting () {
      GetMakeUpSetting().then(result => {
        this.TraceabilityTimeType = result.data.TraceabilityTimeType;
        console.log(result.data, 'GetMakeUpSetting');
      }, err => {
        layer.alert(err.msg);
      });
    },
    getGetPendingMakeUpCoursePersonCount (CourseNameKey) {
      GetPendingMakeUpCoursePersonCount(CourseNameKey, this.allCourseInfo.courseKey).then(result => {
        this.applyGradeNameList = [];
        let findItem = null;
        // if (this.inputIndex == -1 && this.timeIndex == -1) {
        if (result.data.length > 0) {
          result.data.forEach((o, i) => {
            console.log(o, 'o', this.TraceabilityTimeType);
            // 删除月份
            if (this.TraceabilityTimeType.SetContent == 1) {
              o.splice(1, 2);
            } else if (this.TraceabilityTimeType.SetContent == 2) {
              o.splice(2, 1);
            }

            o.searchText = '';
            o.StartTime = '';
            o.EndTime = '';
            o.tIndex = 0;
            o.studentsList = [];
            o.forEach((child, index) => {
              if (index == 0) {
                child.OLAPKey = child.CourseNameKey;
                child.MainDemoName = child.CourseNameKeyValue;
                this.applyGradeNameList.push(child);
              }
            });

            this.getMessageSearchPageMakeUpCourseStudens('', this.DefaultStartDate, this.DefaultEndDate, o[0].CourseNameKey, o);
            // console.log('o.studentsList', o.studentsList);
          });

          if (
            JSON.stringify(this.curSelectedClassInfo) == '{}') {
            findItem = this.applyGradeNameList[0];
          } else {
            findItem = this.applyGradeNameList.find((o) => {
              return o.OLAPKey == this.curSelectedClassInfo.OLAPKey;
            });
          }
          console.log('this.classGradeKey', findItem);

          if (findItem) {
            this.curSelectedClassInfo = findItem;
          } else {
            this.curSelectedClassInfo = this.applyGradeNameList[0];
          }
          this.$set(this.curSelectedClassInfo, 'isActive', true);
        }
        this.repairCourseList = result.data;
        this.searchData = this.repairCourseList; // 为了查询数据
        //   console.log(this.repairCourseList, 'this.repairCourseList5');
        // } else {
        //   this.getMessageSearchPageMakeUpCourseStudens(val, StartTime, EndTime, CourseNameKey);

        //   setTimeout(() => {
        //     this.repairCourseList = result.data;
        //   }, 500);
        // }

        console.log(result.data, 'getGetPendingMakeUpCoursePersonCount', this.repairCourseList, this.repairCourseList.length);
      });
    },
    getMessageSearchPageMakeUpCourseStudens (val, StartTime, EndTime, CourseNameKey, item) {
      console.log(CourseNameKey, 'CourseNameKey');
      MessageSearchPageMakeUpCourseStudens(StartTime, EndTime, CourseNameKey, this.allCourseInfo.courseKey).then(result => {
        result.data.forEach(o => {
          o.CustomerPhoneName = o.CustomerPhoneName ? o.CustomerPhoneName : '-';
          o.WaitingCount = o.WaitingCount ? o.WaitingCount : '-';
          o.AllMakeUpCount = o.AllMakeUpCount ? o.AllMakeUpCount : '-';
          o.ResolvedCount = o.ResolvedCount ? o.ResolvedCount : '-';
        });
        if (this.inputIndex == -1) {
          item.studentsList = result.data;
        } else {
          this.repairCourseList[this.inputIndex].studentsList = [];
          let trueSearchText = val.toLowerCase();
          result.data.forEach((obj) => {
            if (obj.StudentNameKeyValue.toLowerCase().indexOf(trueSearchText) > -1) {
              this.repairCourseList[this.inputIndex].studentsList.push(obj);
            }
          });
          console.log('val', val, result.data, this.repairCourseList[this.inputIndex]);
        }
        this.$forceUpdate();
        console.log('arr66', this.repairCourseList);
      });
    },
    personCourse (chid, cindex, item, index) {
      let startTime = chid.ClassTime + '-01';
      let endTime = chid.ClassTime + '-30';
      this.curIndex = cindex;
      this.timeIndex = index;
      this.repairCourseList[this.timeIndex].StartTime = startTime;
      this.repairCourseList[this.timeIndex].EndTime = endTime;
      this.searchData[index].tIndex = cindex;
      console.log(chid, cindex, 'chid,cindex', index, item, this.searchData);
      this.searchTextSearch(this.searchData[this.timeIndex].searchText, this.timeIndex, chid, startTime, endTime);
    },
    // 搜索
    searchTextSearch (val, index, item, timeS, timeT) {
      let timeSText = timeS || this.repairCourseList[index].StartTime || this.DefaultStartDate;// 开始
      let timeTText = timeT || this.repairCourseList[index].EndTime || this.DefaultEndDate;// 结束
      this.inputIndex = index;
      this.setSearchTitle = val;
      console.log(this.repairCourseList, 'searchTextSearch', index, this.repairCourseList[index], 'item', item, 'item.CourseNameKey', item.CourseNameKey);
      this.getMessageSearchPageMakeUpCourseStudens(val, timeSText, timeTText, item.CourseNameKey);
    },
    // 清楚条件
    clearSearchCondition (val, index, item) {
      this.searchData[index].tIndex = 0;
      this.repairCourseList[index].StartTime = this.DefaultStartDate;
      this.repairCourseList[index].EndTime = this.DefaultEndDate;
      this.inputIndex = index;
      this.setSearchTitle = val;
      console.log(this.searchData, 'clearSearchCondition', index, this.DefaultStartDate, 'item', item, 'item.CourseNameKey', item.CourseNameKey);
      this.getMessageSearchPageMakeUpCourseStudens(val, this.DefaultStartDate, this.DefaultEndDate, item.CourseNameKey);
    },
    closeRepairCourseSetPopup () {
      this.isShowRepairCourseSet = false;
    },
    afterSuccess () {
      this.timeIndex = -1;
      this.inputIndex = -1;
      this.isShowRepairCourseSet = false;
      console.log(this.setSearchTitle, 'this.setSearchTitle');
      this.getGetMakeUpSetting();
      this.getGetPendingMakeUpCoursePersonCount();
    },
    // 进入补课设置
    repairCourseClick (item, index) {
      this.setDataObj = item;
      this.inputIndex = index;
      console.log(item, index, 'item666');
      this.isShowRepairCourseSet = true;
    },
    openDetail (obj, StartTime, EndTime) {
      this.dataDetail.key = obj.StudentNameKey;
      this.dataDetail.name = obj.StudentNameKeyValue;
      this.dataDetail.tel = obj.CustomerPhoneName;
      this.dataDetail.img = obj.HeadImgSupport;
      this.dataDetail.course = obj.CourseNameKey;
      this.dataDetail.start = StartTime || this.DefaultStartDate;
      this.dataDetail.end = EndTime || this.DefaultEndDate;
      this.isShowDetail = true;
      console.log(this.dataDetail, 'this.dataDetail');
    },
    closeDetail () {
      this.timeIndex = -1;
      this.inputIndex = -1;
      this.isShowDetail = false;
      this.getGetPendingMakeUpCoursePersonCount();
    }
  }
};
</script>
<style scoped>
.repair_students_view {
  width: 100%;
  background: #fff;
  min-height: 800px;
  max-width: 1920px;
  margin: 0 auto;
}
.repair_students_info {
  width: 96%;
  margin: 0 auto;
  border-bottom: 1px solid #ccc;
}
.no_border {
  border-bottom: none !important;
}
.course_top {
  position: relative;
  padding-top: 20px;
  display: flex;
}
.course_set_up {
  color: #3498db;
  position: absolute;
  right: 25px;
  top: 20px;
  cursor: pointer;
}
.coures_name {
  font-weight: 700;
  color: #000;
  font-size: 18px;
  margin-top: 15px;
}
.wait_info {
  color: #999;
  font-size: 14px;
  font-weight: 400;
  margin-left: 20px;
}

.course_content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.course_info_div {
  width: 150px;
  height: 190px;
  border: 1px solid #000;
  border-radius: 4px;
  margin: 20px 0;
  margin-right: 20px;
  cursor: pointer;
}
.student_info_div {
  color: #000;
  font-size: 18px;
  margin: 15px;
}
.phone_info::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -25px;
  width: 19px;
  height: 19px;
  background: url(../../../../../public/image/phone_png.png) no-repeat;
  background-size: 100% 100%;
}
.phone_info {
  margin-left: 40px;
  position: relative;
}
.handle_info {
  margin-left: 15px;
  margin-top: 15px;
  width: 158px;
}
.table_select_box .el-select >>> .el-input__inner {
  border: none !important;
  width: 200px;
}
.title_date_select {
  width: 200px !important;
}
.course_statistics_title {
  padding: 0 !important;
  margin-top: 20px;
}
.course_search_div .mendian_backstage_search {
  margin-top: 20px;
}
.has_arrange_course_list {
  /* min-width: 1500px !important; */
  height: 260px !important;
}
.has_arrange_course_nodata {
  padding: 70px 0 !important;
  background-position: center 70px !important;
}
.course_class_summarizing_left {
  width: 252px !important;
  margin-left: 20px;
  position: fixed;
}
.left_search_box .el-select {
  width: 87px;
  border-radius: 4px;
  border: 1px solid #ececec;
  border-right: none;
  padding-left: 0px;
  height: 39px;
}
.left_search_box .el-select .el-input__inner {
  padding: 0 !important;
}
.left_search_box .left_search {
  width: 157px !important;
  position: absolute;
  top: 0px;
  left: 93px;
  border-radius: 0px 4px 4px 0 !important;
  height: 39px;
}
.select_class_type >>> span {
  width: 45px !important;
}
.left_content .left_list {
  padding: 10px 0 8px !important;
  padding-left: 28px !important;
}
.left_content .left_list .left_list_title {
  line-height: 32px !important;
}
.course_class_right {
  /* width: 1600px; */
  margin-left: 300px;
}
.time_data_div {
  margin-left: 20px;
  display: flex;
}
.person_course_count {
  width: 150px;
  text-align: center;
  background-color: rgba(52, 152, 219, 0.15);
  border-radius: 6px;
  height: 53px;
  margin-left: 20px;
  line-height: 26px;
  cursor: pointer;
}
.month_title {
  font-size: 12px;
}
.p_c_count {
  font-size: 18px;
  font-weight: 700;
}
</style>