<template>
  <div>
    <!-- 门店列表 -->
    <div>
      <list ref="studentApplyCourseTable"
            :tableData="mendianList"
            :totalNum="totalNum"
            :pageTotal="pageTotal"
            :PageCount="PageCount"
            :tableColumns="tableColumns"
            :isSearchText="false"
            :rowKey="'Number'"
            :defaultSort="defaultSort"
            :queryParams="queryParams"
            :listType="listType"
            :statusList="statusList"
            @searchValue="searchValue"
            @tableBtnClick="doAfterTableBtnClick"
            @loadTableData="getMessageSearchSaaSClient"
            @clearSearchParams="clearSearchCondition"
            @doApplyForListClick="doApplyForListClick"></list>
    </div>
    <div class="course_student_attendance_operation module_content_menuList"
         :style="{'top':topValue+'px','left':'auto','right': 50+'px'}"
         v-if="isShowOperateBtn">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="operateBtnList"
                     @clickOptionForAlertDialog="onOperateClick"></drop-down-box>
    </div>

    <custom-dialog :title="'门店申请列表'"
                   width="1660px"
                   :visible.sync="isShowApplyForListPopup"
                   :before-close="closeApplyForListPopup">
      <apply-for-list :applyForType="mendianApplyForListType"
                      @afterSuccess="getMessageSearchSaaSClient"></apply-for-list>
    </custom-dialog>

    <custom-dialog :title="'正式续期'"
                   :visible.sync="isShowRenewalFormPopup"
                   :before-close="closeRenewalFormPopup">
      <form-renewal-popup :mendianInfo="mendianData"
                          @closeFrom="closeRenewalFormPopup"></form-renewal-popup>
    </custom-dialog>

    <custom-dialog :title="'选择数据清理'"
                   :visible.sync="isShowClearDataFormPopup"
                   :before-close="closeClearDataFormPopup">
      <form-clear-data-popup :mendianInfo="mendianData"
                             @closeFrom="closeClearDataFormPopup"></form-clear-data-popup>
    </custom-dialog>

    <custom-dialog :title="'修改'"
                   :visible.sync="isShowEditFormPopup"
                   :before-close="closePopup">
      <edit-form :mendianInfo="mendianData"
                 @afterSuccess="editMdInfoAfter"
                 @closeFrom="closePopup"></edit-form>
    </custom-dialog>

    <custom-dialog :title="'修改管理员手机号'"
                   :visible.sync="isShowEditPhoneFormPopup"
                   :before-close="closeEditPhoneFormPopup">
      <edit-phone-form :mendianInfo="mendianData"
                       @afterSuccess="editPhoneAfter"
                       @closeFrom="closeEditPhoneFormPopup"></edit-phone-form>
    </custom-dialog>

    <custom-dialog :title="'品牌定制设置'"
                   :visible.sync="isShowOpenBrandFormPopup"
                   :before-close="closePopup">
      <open-brand-form :mendianInfo="mendianData"
                       @afterSuccess="editMdInfoAfter"
                       @closeFrom="closePopup"></open-brand-form>
    </custom-dialog>

    <custom-dialog :title="'关联总部'"
                   :visible.sync="isShowEditlinkHQFormPopup"
                   :before-close="closePopup">
      <editlink-headquarters-form :mendianInfo="mendianData"
                                  @afterSuccess="editMdInfoAfter"
                                  @closeFrom="closePopup"></editlink-headquarters-form>
    </custom-dialog>
  </div>
</template>
<script>
import {
  MessageSearchSaaSClient,
  SaaSClientRenewal,
  SaaSClientTempRenewal,
  SaaSClientCloseDown,
  SaaSClientForOverCloseDown,
  ExportStudentXYKD,
  ClearSaaSClientData,
  ResetAdminPasswords,
  EditAdminPhone,
  CloseBrandCustom,
  EditUnbindSaaSClient,
  EditChangeSaaSClient
} from '../../../../API/workbench.js';

import list from '../table';
import applyForList from './apply-for-list';
import formRenewalPopup from './form-renewal-popup';
import formClearDataPopup from './form-clear-data-popup';
import dropDownBox from '../../../common/drop-down-box';
import editForm from './edit-form';
import editPhoneForm from './edit-phone-form';
import openBrandForm from './open-brand-form';
import editlinkHeadquartersForm from './editlink-headquarters-form';
export default {
  components: {
    dropDownBox,
    list,
    applyForList,
    formRenewalPopup,
    formClearDataPopup,
    editForm,
    editPhoneForm,
    openBrandForm,
    editlinkHeadquartersForm
  },
  props: {
    listType: String
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 15, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: 'desc', // 排序字段
        DredgeStatusKey: '',
        DredgeStatusKeyValue: ''
      },
      pageTotal: 0, // 分页器总数
      mendianList: [], // 门店列表
      mendianData: {}, // 门店信息
      isShowApplyForListPopup: false, // 门店申请列表
      mendianApplyForListType: '', // 申请列表类型
      isShowRenewalFormPopup: false, // 变更正式续期
      isShowClearDataFormPopup: false, // 清理数据
      isShowEditFormPopup: false, // 修改管理门店信息
      isShowResetPasswordFormPopup: false, // 重置管理员密码
      isShowEditPhoneFormPopup: false, // 修改管理员手机号
      isShowOpenBrandFormPopup: false, // 开启品牌定制
      isShowEditlinkHQFormPopup: false, // 关联总部
      statusList: [
        { name: '全部', key: '' },
        { name: '关停', key: 0 },
        { name: '临时开通', key: 1 },
        { name: '正式开通', key: 2 }
      ],
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          fixed: 'left',
          type: 'text-item',
          className: 'font_gray',
          decimalPlace: 0
        },
        {
          label: '门店类型',
          prop: 'TypeKeyValue',
          width: 90,
          fixed: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '门店版本',
          prop: 'EditionKeyValue',
          width: 90,
          fixed: 'left',
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '门店ID',
          prop: 'SaaSClientNO',
          width: 100,
          fixed: 'left',
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '门店名称',
          prop: 'MainDemoName',
          width: 170,
          fixed: 'left',
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '公司全称',
          prop: 'FullName',
          width: 170,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '联系人',
          prop: 'ContactName',
          width: 90,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '联系人手机',
          prop: 'ContactPhone',
          width: 120,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '门店电话',
          prop: 'Telephone',
          width: 120,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '地址',
          prop: 'Address',
          width: 140,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '微信模式',
          prop: 'PatternKeyText',
          width: 200,
          align: 'left',
          type: 'text-item'
        },
        {
          label: '微官网开启',
          prop: 'IsOpenMicroClassKeyValue',
          width: 100,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '开户日期',
          prop: 'CreateDateTime',
          width: 165,
          align: 'left',
          sortable: 'custom',
          type: 'date-item',
          extend: {
            dateType: 'YYMMRR HH:DD' // 日期类型
          }
        },
        {
          label: '失效日期',
          prop: 'ExpireDateTime',
          width: 100,
          align: 'left',
          sortable: 'custom',
          type: 'date-item'
        },
        {
          label: '是否完成初始引导',
          prop: 'IsLearnCompleteKeyValue',
          width: 150,
          align: 'center',
          sortable: 'custom',
          type: 'text-item'
        },
        {
          label: '所属总店',
          prop: 'ParentKeyValue',
          width: 140,
          align: 'left',
          sortable: 'custom',
          type: 'text-item'
        },
        {
          label: '当前教务端口',
          prop: 'CurrentPortalNum',
          width: 120,
          align: 'left',
          sortable: 'custom',
          type: 'numberItem'
        },
        {
          label: '当前课件端口',
          prop: 'CoursewarePortalCount',
          width: 120,
          align: 'left',
          sortable: 'custom',
          type: 'numberItem'
        },
        {
          label: '开通状态',
          prop: 'DredgeStatusKeyValueCloseTime',
          align: 'center',
          width: 160,
          sortable: 'custom',
          type: 'text-item'
        },
        {
          label: '备注',
          prop: 'Remark',
          width: 140,
          sortable: 'custom',
          type: 'text-item'
        },
        {
          label: '流量空间',
          prop: 'FluxCount',
          width: 120,
          align: 'left',
          type: 'sell-documentary-table-row'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          fixed: 'right',
          width: 80,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '办理',
                extend: {
                  click: (rowData) => {
                    this.operateBtnEvent(rowData);
                  }
                }
              }
            ]
          }
        }
      ],
      // 门店操作按钮
      operateBtnList: [],
      isShowOperateBtn: false, // 显示操作项
      topValue: 0, // 默认第一个top值
      PageCount: 0,
      totalNum: 0,
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  created () { },
  mounted () {
    this.getMessageSearchSaaSClient();
    document.querySelector('body').addEventListener(
      'click',
      (e) => {
        this.isShowOperateBtn = false;
      },
      false
    );
  },
  methods: {
    doApplyForListClick (type) {
      this.mendianApplyForListType = type;
      this.isShowApplyForListPopup = true;
    },
    closeApplyForListPopup () {
      this.isShowApplyForListPopup = false;
    },
    // 门店列表
    getMessageSearchSaaSClient (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.queryParams.searchText = this.queryParams.searchText.trim();
      this.queryParams.orderBy =
        this.queryParams.orderBy == 'DredgeStatusKeyValueCloseTime'
          ? 'DredgeStatusKey'
          : this.queryParams.orderBy;
      this.queryParams.orderBy =
        this.queryParams.orderBy == 'IsOpenMicroClassKeyValue'
          ? 'IsOpenMicroClassKey'
          : this.queryParams.orderBy;
      this.queryParams.orderBy =
        this.queryParams.orderBy == 'IsLearnCompleteKeyValue'
          ? 'IsLearnCompleteKey'
          : this.queryParams.orderBy;
      MessageSearchSaaSClient(this.queryParams).then(
        (result) => {
          console.log('门店列表', result.data);
          this.isShowApplyForListPopup = false;
          this.isShowOperateBtn = false;
          this.$nextTick(() => {
            if (result.data.PageDataList.length == 0) {
              this.$refs.studentApplyCourseTable.isLoading = true;
            }
          });
          this.pageTotal = result.data.Total;
          if (
            this.queryParams.status == 0 &&
            this.queryParams.searchText == ''
          ) {
            this.totalNum = result.data.Total;
          }
          // 分页数
          if (result.data && result.data.PageDataList.length > 0) {
            result.data.PageDataList.forEach((o) => {
              o.IsOpenMicroClassKeyValue =
                o.IsOpenMicroClassKey == 1 ? '开启' : '关闭';
              o.IsLearnCompleteKeyValue =
                o.IsLearnCompleteKey == 1 ? '已完成' : '未完成';
              if (o.DredgeStatusKey == 0) {
                o.DredgeStatusKeyValueCloseTime = o.CloseTime
                  ? o.CloseTime.slice(0, 10) + ' | ' + '已关停'
                  : '';
              } else {
                // --0关闭;1-临时开通;2-永久开通
                o.DredgeStatusKeyValueCloseTime = o.DredgeStatusKey == 1 ? '临时开通' : '永久开通';
              }
              if (Number(o.PatternKey) == 1) { // 门店模式：1-独立;2-托管;
                o.PatternKeyText = Number(o.is_official_component_authorizer) > 0 ? '授权独立' : '独立'; // 是否公众号三方授权 0-否;1-是
              } else if (Number(o.PatternKey) == 2) {
                o.PatternKeyText = '托管 ' + (o.TrusteeshipKeyValue ? '(' + o.TrusteeshipKeyValue + ')' : '(无)'); // 	托管门店
              }
              o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
            });
            this.mendianList = result.data.PageDataList;
          } else {
            this.mendianList = [];
          }
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    // 搜索
    searchValue (searchText) {
      this.queryParams.searchText = searchText;
      this.queryParams.pageIndex = 0;
      console.log('searchValue', searchText);
      this.getMessageSearchSaaSClient();
    },
    // 按钮点击
    doAfterTableBtnClick (rowData) {
      console.log(rowData, '按钮点击');
    },
    // 操作菜单
    operateBtnEvent (rowData) {
      console.log(rowData, this.mendianList, '操作菜单');
      this.mendianData = rowData;
      let findIndex = this.mendianList.findIndex((o) => {
        // 找到该项菜单点击下标
        return Number(o.OLAPKey) == Number(rowData.OLAPKey);
      });
      let index = findIndex + 1;
      this.topValue = 250 + index * 63;
      this.operateBtnList = [
        // { type: 1, name: '正式续期', isShow: true },
        { type: 2, name: '临时续期', isShow: !(rowData.DredgeStatusKey == 2) }, // 正式开通状态
        { type: 3, name: '关停', isShow: rowData.TypeKey == 3 && !(rowData.IsCloseKey == 1) }, // 限普通门店+未关停状态
        { type: 4, name: '永久关停', isShow: (rowData.TypeKey != 7 && rowData.TypeKey != 8) },
        { type: 5, name: '导出学生课单', isShow: rowData.EditionKey != 6 },
        { type: 6, name: '清理数据', isShow: rowData.EditionKey != 6 },
        { type: 7, name: '修改', isShow: true },
        { type: 8, name: '重置管理员密码', isShow: true },
        { type: 9, name: '修改管理员手机号', isShow: true },
        // { type: 10, name: rowData.IsBrandCustomKey == 1 ? '设置品牌定制' : '开启品牌定制', isShow: true }, // 品牌定制
        // { type:11, name: '关闭品牌定制', isShow: true }
        { type: 12, name: '解绑总部', isShow: (rowData.TypeKey == 7 || rowData.TypeKey == 8) }, // 7-8/连锁加盟-连锁直营
        { type: 13, name: '关联总部', isShow: (rowData.EditionKey == 3 || rowData.EditionKey == 6) }, // 3、6/旗舰、课件
        { type: 14, name: '转课件门店', isShow: rowData.EditionKey == 3 }
      ];
      // if (rowData.DredgeStatusKey == 2) {
      //   // 正式开通状态
      //   let findIndex = this.operateBtnList.findIndex((o) => {
      //     // 隐藏临时续期-找到该下标
      //     return Number(o.type) == 2;
      //   });
      //   this.operateBtnList.splice(findIndex, 1);
      // }

      // if (rowData.IsCloseKey == 1) {
      //   // 已关停状态
      //   let findIndex = this.operateBtnList.findIndex((o) => {
      //     // 隐藏关停-找到该下标
      //     return Number(o.type) == 3;
      //   });
      //   this.operateBtnList.splice(findIndex, 1);
      // }

      // if (rowData.IsBrandCustomKey == 1) {
      //   // 品牌定制
      //   let findIndex = this.operateBtnList.findIndex((o) => {
      //     // 隐藏临时续期-找到该下标
      //     return Number(o.type) == 10;
      //   });
      //   this.operateBtnList[findIndex].name = '设置品牌定制';
      // } else {
      //   //  let findIndex = this.operateBtnList.findIndex(o => { // 隐藏临时续期-找到该下标
      //   //   return Number(o.type) ==11
      //   // });
      //   // this.operateBtnList.splice(findIndex, 1);
      // }
      this.isShowOperateBtn = true;
    },
    // 操作按钮
    onOperateClick (item) {
      console.log(item, 'this');
      this.isShowOperateBtn = false;
      switch (item.type) {
        case 1: // 正式续期
          this.isShowRenewalFormPopup = true;
          break;
        case 2: // 临时续期
          this.chgSaaSClientTempRenewal();
          break;
        case 3: // 关停
          this.chgSaaSClientCloseDown();
          break;
        case 4: // 永久关停
          this.chgSaaSClientForOverCloseDown();
          break;
        case 5: // 导出学生课单
          this.exportStudentXYKD();
          break;
        case 6: // 清理数据
          this.isShowClearDataFormPopup = true;
          break;
        case 7: // 修改
          this.isShowEditFormPopup = true;
          break;
        case 8: // 重置管理员密码
          this.resetAdministratorPassword();
          break;
        case 9: // 修改管理员手机号
          this.isShowEditPhoneFormPopup = true;
          break;
        case 10: // 开启品牌定制
          this.isShowOpenBrandFormPopup = true;
          break;
        case 11: // 关闭品牌定制
          this.closeBrandCustomize();
          break;
        case 12: // 解绑总部
          this.unbundleHeadquarters();
          break;
        case 13: // 关联总部
          this.isShowEditlinkHQFormPopup = true;
          break;
        case 14: // 转课件门店
          this.turnCoursewareMd();
          break;
        default:
          break;
      }
    },
    // 关闭品牌定制
    closeBrandCustomize () {
      layer.confirm('是否关闭品牌定制', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              CloseBrandCustom(this.mendianData.OLAPKey).then(
                (result) => {
                  this.getMessageSearchSaaSClient();
                  layer.alert('关闭品牌定制成功!');
                },
                (error) => {
                  layer.alert(error.msg);
                }
              );
              layer.close();
            }
          }
        ]
      });
    },
    // 正式续期
    chgSaaSClientRenewal (Time) {
      SaaSClientRenewal(this.mendianData.OLAPKey, Time).then(
        (result) => {
          this.getMessageSearchSaaSClient();
          layer.alert('正式续期成功!');
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    // 临时续期
    chgSaaSClientTempRenewal () {
      layer.confirm('变更为临时开通，并自动变更有效期为30天' + ',是否继续?', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              SaaSClientTempRenewal(this.mendianData.OLAPKey).then(
                (result) => {
                  this.getMessageSearchSaaSClient();
                  layer.alert('临时续期成功!');
                },
                (error) => {
                  layer.alert(error.msg);
                }
              );
              layer.close();
            }
          }
        ]
      });
    },
    // 关停
    chgSaaSClientCloseDown () {
      layer.confirm('关停后已登录的账号会自动退出且无法使用，是否继续?', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              SaaSClientCloseDown(this.mendianData.OLAPKey).then(
                (result) => {
                  this.getMessageSearchSaaSClient();
                  layer.alert('关停成功!');
                },
                (error) => {
                  layer.alert(error.msg);
                }
              );
              layer.close();
            }
          }
        ]
      });
    },
    // 永久关停
    chgSaaSClientForOverCloseDown () {
      layer.confirm('永久关停后会同时删除门店所有数据，是否继续?', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              SaaSClientForOverCloseDown(this.mendianData.OLAPKey).then(
                (result) => {
                  this.getMessageSearchSaaSClient();
                  layer.alert('永久关停成功!');
                },
                (error) => {
                  layer.alert(error.msg);
                }
              );
              layer.close();
            }
          }
        ]
      });
    },
    // 导出学生课单
    exportStudentXYKD () {
      ExportStudentXYKD(this.mendianData.OLAPKey).then(
        (result) => {
          window.location.href = this.$store.getters.APIURL + result.data.data.url;
          layer.alert('导出学生课单成功!');
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    // 清理数据
    clearSaaSClientData (DelScope) {
      ClearSaaSClientData(this.mendianData.OLAPKey, DelScope).then(
        (result) => {
          this.getMessageSearchSaaSClient();
          layer.alert('清理数据成功!');
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    //  重置管理员密码
    resetAdministratorPassword () {
      layer.confirm(
        '确认重置管理员密码，重置后初始密码为111111' + ',是否继续?',
        {
          btn: [
            {
              name: '确认',
              callBack: () => {
                ResetAdminPasswords(this.mendianData.OLAPKey).then(
                  (result) => {
                    this.getMessageSearchSaaSClient();
                    layer.alert('重置成功!');
                  },
                  (error) => {
                    layer.alert(error.msg);
                  }
                );
                layer.close();
              }
            }
          ]
        }
      );
    },
    // 修改管理员手机号
    editPhoneAfter (phoneNumber, callBack) {
      EditAdminPhone(this.mendianData.OLAPKey, phoneNumber).then(
        (result) => {
          this.getMessageSearchSaaSClient();
          this.closeEditPhoneFormPopup();
          layer.alert('手机号修改成功!');
        },
        (error) => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
    },
    // 解绑总部
    unbundleHeadquarters () {
      layer.confirm(
        '是否确认解绑总部?', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              EditUnbindSaaSClient(this.mendianData.OLAPKey).then((result) => {
                this.getMessageSearchSaaSClient();
                layer.alert('解绑成功!');
              }, (error) => {
                layer.alert(error.msg);
              }
              );
              layer.close();
            }
          }
        ]
      }
      );
    },
    // 转课件门店
    turnCoursewareMd () {
      layer.confirm(
        '是否确认转课件门店?', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              EditChangeSaaSClient(this.mendianData.OLAPKey).then((result) => {
                this.getMessageSearchSaaSClient();
                layer.alert('已转课件门店!');
              }, (error) => {
                layer.alert(error.msg);
              }
              );
              layer.close();
            }
          }
        ]
      }
      );
    },
    // 修改门店信息
    editMdInfoAfter () {
      this.getMessageSearchSaaSClient();
      this.closePopup();
    },
    // 关闭正式续期弹窗
    closeRenewalFormPopup (date) {
      console.log(date, '正式续期日期');
      if (date) {
        this.chgSaaSClientRenewal(date);
      }
      this.isShowRenewalFormPopup = false;
    },
    // 关闭清理数据弹窗
    closeClearDataFormPopup (date) {
      console.log(date, '关闭清理数据弹窗');
      if (date) {
        this.clearSaaSClientData(date);
      }
      this.isShowClearDataFormPopup = false;
    },
    closePopup () {
      this.isShowEditFormPopup = false;
      this.isShowOpenBrandFormPopup = false;
      this.isShowEditlinkHQFormPopup = false;
    },
    closeResetPasswordFormPopup () {
      this.isShowResetPasswordFormPopup = false;
    },
    closeEditPhoneFormPopup () {
      this.isShowEditPhoneFormPopup = false;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.queryParams.searchText = '';
      this.queryParams.pageIndex = 0;
      this.queryParams.status = 0;
      this.$refs.studentApplyCourseTable.queryParams.pageIndex = 0;
      this.getMessageSearchSaaSClient();
    }
  },
  computed: {}
};
</script>
