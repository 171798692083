<template>
  <div class="WKT_student_activity_ul"
       @scroll="scrollTOP">
    <activity-item v-for="(item,index) in dataList"
                   :key="index"
                   :Item="item"
                   :Index="index"
                   :isShowbtn="isShowbtn"
                   :dataList="dataList"
                   @doSelectActivity="doSelectActivity"></activity-item>
    <div class="new_table_list_bottom"
         v-if="dataList.length>0&&isLoadEnd">
      <span class="loading_btn">加载中...</span>
    </div>
    <div class="new_table_list_bottom"
         v-if="dataList.length>0&&noMore">
      <span class="font_gray">已显示全部</span>
    </div>
    <div v-if="dataList.length==0">
      <div class="monitoring_content_nodata">
        <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
      </div>
    </div>
  </div>
</template>
<script>
import activityItem from './activity-item';
import { SelectActivitiesCouponForBanner } from '../../../../API/workbench';
export default {
  data () {
    return {
      searchText: '',
      dataList: [],
      searchObj: {
        pageIndex: 0,
        pageSize: 3,
        searchText: '',
        orderBy: '',
        sequence: ''
      },
      noMore: false,
      isLoadEnd: false
    };
  },
  components: {
    activityItem

  },
  props: {
    activitiesCouponList: Array,
    isShowbtn: {
      type: Boolean,
      default: true
    }
  },
  created () {
    this.getDataList();
  },
  watch: {

  },
  computed: {

  },
  methods: {
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;
      if (
        (ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight ||
          ETarget.scrollTop + 1 ==
          ETarget.scrollHeight - ETarget.clientHeight) &&
        !this.noMore &&
        !this.isLoadEnd
      ) {
        this.getDataList();
      }
    },
    getDataList () {
      this.isLoadEnd = true;
      SelectActivitiesCouponForBanner(this.searchObj).then(result => {
        console.log(result.data, 'SelectActivitiesCouponForBanner');
        result.data.PageDataList.forEach(o => {
          o.isActive = false;
          this.dataList.push(o);
        });
        if (result.data.PageDataList.length == this.searchObj.pageSize) {
          this.searchObj.pageIndex++;
        } else {
          this.noMore = true;
        }
        this.isLoadEnd = false;
      });
    },
    // 上架
    doSelectActivity (Item) {
      this.dataList.forEach(o => {
        if (Item.Number == o.Number) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      this.$emit('doSelectActivity', Item);
    }
  }
};
</script>

