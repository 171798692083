<template>
  <div class="video_box">
    <video-item v-for="(item,index) in vAttachList"
                :key="index"
                :videoInfo="item"
                :isCanEdit="isCanEdit"
                @showPlayVideo="toPreview"
                @onClick="toPreview"
                @onDelete="toDelete"></video-item>
    <custom-dialog :title="'播放视频'"
                   :visible.sync="isShowPlayVideo"
                   :before-close="closePlayVideo">
      <play-video v-if="isShowPlayVideo"
                  :dataInfo="videoInfo"
                  @close="closePlayVideo"></play-video>
    </custom-dialog>
    <!-- <play-video v-if="isShowPlayVideo"
                  :dataInfo="videoInfo"
                  @close="closePlayVideo"></play-video> -->
    <!-- <div v-if="isPreviewImg">
		<preview-img 
					:showIndex="imgShowIndex" 
					:imgHeight="'90%'"
					:imgList="vImgList" 
					:isCanEdit="isCanEdit"
                    @changeImgRotateEvent="afterChangeImgRotate"
				    @onClosePreviewImg="toClosePreviewImg"></preview-img>
	</div> -->
  </div>
</template>

<script>
import videoItem from './video-item';
import playVideo from './play-video';
// import previewImg from '../../preview-img/index';
export default {
  data () {
    return {
      isShowPlayVideo: false,
      videoInfo: {}
    };
  },
  components: {
    videoItem,
    playVideo
    // previewImg
  },
  props: {
    attachList: Array,
    isCanEdit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    vAttachList () {
      console.log(this.attachList, 'vAttachList222');
      return this.attachList.filter(obj => { return obj.AttachTypeKey == 2; });
    }

  },
  methods: {
    toPreview (item) {
      // 播放视频，如是上传中，则不可播放
      this.isShowPlayVideo = true;
      this.videoInfo = item;
    },
    closePlayVideo () {
      console.log('closePlayVideo');
      this.isShowPlayVideo = false;
    },
    // // 关闭预览图片
    // toClosePreviewImg () {
    // 	this.isPreviewImg = false;
    // 	this.imgShowIndex = 0;
    // },
    // 删除
    toDelete (item) {
      this.$emit('onDelete', item);
    }
  }
};
</script>

<style>
</style>
