<template>
  <div class="face_informatio_entry_right fl">
    <div class="face_informatio_entry_title">
      <span>面容录入</span>
    </div>
    <div class="sundent_list no_icon clearfix"
         v-if="!seletedItem.OLAPKey"
         @click.stop="showStudentList">
      <div class="sundent_list_photo fl">
        <img src="../../../../public/image/search_gray_icon_02@2x.png"
             crossOrigin="anonymous"
             alt="">
      </div>
      <div class="sundent_list_tips fl">
        <div>请选择学生</div>
      </div>
    </div>
    <div class="sundent_list list_swtich clearfix"
         v-else
         @click.stop="showStudentList">
      <div class="sundent_list_photo fl">
        <img :src="headImg"
             crossOrigin="anonymous"
             alt="">
      </div>
      <div class="sundent_list_info fl">
        <div class="sundent_list_info_name">
          {{seletedItem.MainDemoName}}
        </div>
        <div class="sundent_list_info_phone">
          {{$utils.phoneModulePower(seletedItem.MobileSupport)}}
        </div>
      </div>
    </div>
    <div class="face_informatio_entry_screenage">
      <div class="screenage_box"
           v-show="!noVideo">
        <video id="video2"
               ref="video"
               preload
               autoplay
               loop
               muted></video>
        <canvas id="canvas2"></canvas>
        <div class="message"
             v-show="false">
          <canvas id="shortCut2"
                  width="290"
                  height="218"></canvas>
          <div id="checkinMsg"></div>
        </div>

      </div>
      <div class="screenage_box"
           v-show="noVideo">
        <div class="screenage_box_noLink">
          未连接到摄像头
        </div>
      </div>
    </div>
    <div class="face_informatio_entry_status">
      <div class="status_photo_box"
           v-if="!noVideo">
        <div class="status_photo_btn"
             @click.stop="takePhotoForApply">
          拍照登记
        </div>
        <div class="status_photo_select"
             :class="isActive?'opt':''"
             @click="isActive=!isActive">
          同时作为头像
          <div :class="del_fice">
            <div class="btn_del_fice"
                 @click.stop="btn_del_fice">清除面容</div>
          </div>
        </div>

      </div>
      <div class="status_photo_tips"
           v-else>
        使用前请确认摄像头已正确连接，如无法显示请进行以下 尝试： <br />
        1.重新连接摄像头； <br />
        2.关掉浏览器，重新登陆{{vBrandInfo.MainDemoName}}系统； <br />
        3.重启电脑或手机。
      </div>
    </div>
    <div class="face_informatio_entry_operate_tips">
      <div class="tips_list">
        <div class="tips_img"><img src="../../../../public/image/operate_tips_icon01.png"
               crossOrigin="anonymous"
               alt=""></div>
        <div>脸部居中</div>
      </div>
      <div class="tips_list">
        <div class="tips_img"><img src="../../../../public/image/operate_tips_icon02.png"
               crossOrigin="anonymous"
               alt=""></div>
        <div>光线充足</div>
      </div>
      <div class="tips_list">
        <div class="tips_img"><img src="../../../../public/image/operate_tips_icon03.png"
               crossOrigin="anonymous"
               alt=""></div>
        <div>脸无遮挡</div>
      </div>
    </div>
    <!-- 学生列表 -->
    <custom-dialog title="学生列表"
                   :visible.sync="isShowStudentList"
                   :before-close="closeStudentList">
      <div>
        <student-list :isShowSearchInput="true"
                      @selectedItem="doAfterSelectedStudent"
                      ref="studentListDialog"></student-list>
      </div>
    </custom-dialog>
  </div>
</template>
<script>
import studentList from '../../common/student-list';
import { AddStudentFace } from '../../../API/face-recognition.js';
import { UploadFiles, delFaceInfo, accordingStudentID } from '../../../API/workbench';
export default {
  data () {
    return {
      isShowStudentList: false,
      curStatusInfo: { statusKey: 1, statusKeyValue: '识别中...' },
      flag: false,
      trackerTask: null,
      noVideo: false,
      isActive: false,
      param: {
        IsHeadImgKey: 0,
        OLAPKey: ''
      },
      IsHeadImgKey: 0,
      del_fice: 'none'
    };
  },
  props: {
    seletedItem: {
      type: Object
    }
  },
  watch: {
    seletedItem (n, o) {
      console.log(n.OLAPKey, o, 'n, o');
      this.accordingStudentIDFn(n.OLAPKey);
    }

  },
  mounted () {
    // this.stopMediaStreamTrack();
    this.checkVideoCanDo();
    this.vDelFice();
  },
  components: {
    studentList
  },
  computed: {
    vBrandInfo () {
      let obj = {};
      if (window.brandCustomSettingStr && window.brandCustomSettingStr != '') {
        obj = JSON.parse(window.brandCustomSettingStr);
        obj.MainDemoName = obj.MainDemoName;
      } else {
        obj = {
          MainDemoName: '治学'
        };
      }
      return obj;
    },
    // 头像
    headImg () {
      if (this.seletedItem && this.seletedItem.OLAPKey > 0) {
        if (this.seletedItem.HeadImgSupport && this.seletedItem.HeadImgSupport != 'null') {
          if (this.seletedItem.HeadImgSupport.indexOf('http') > -1 && this.seletedItem.HeadImgSupport.indexOf('KPIupload') > -1) {
            return this.item.HeadImgSupport;
          } else {
            let pcDomainURLOld = this.$store.getters.CDNURL;
            return pcDomainURLOld + this.seletedItem.HeadImgSupport; // 服务器的图片地址有时有问题，会返回404报错
          }
          // 默认显示头像
        } else {
          if (parseInt(this.seletedItem.SexKey) === 2) {
            return require('../../../../public/image/deflut_studentPhoto_boy.png');
          } else if (parseInt(this.seletedItem.SexKey) === 3) {
            return require('../../../../public/image/deflut_studentPhoto_gril.png');
          } else {
            return require('../../../../public/image/face_screenage_noImg.png');
          }
        }
      } else {
        return require('../../../../public/image/deflut_studentPhoto_boy.png');
      }
    }

  },
  methods: {

    // 检测关闭摄像头
    stopMediaStreamTrack () {
      console.log('stopMediaStreamTrack--RightContent');
      let video = document.getElementById('video2');
      video.srcObject = null;
    },
    vDelFice () {
      if (!this.seletedItem.OLAPKey) {
        this.del_fice = 'none';
      } else {
        this.del_fice = '';
      }
      console.log(this.seletedItem, 'this.seletedItem');
    },
    checkVideoCanDo (callback) {
      let videoNum = 0,
        deviceList = [];
      navigator.mediaDevices.enumerateDevices().then(devices => {
        devices.forEach(device => {
          // console.log(device);
          deviceList.push(device.kind);
          if (device.kind === 'videoinput') { videoNum++; }
        });
        this.noVideo = !(videoNum > 0);
        if (!this.noVideo) {
          this.openCamera();
        }
        console.log('设备列表：', deviceList);
      }).catch(function (err) {
        console.log(err, 'checkVideoCanDo');
        this.noVideo = true;
        // alert(err.name + ': ' + err.message);
      });
    },
    openCamera () {
      var video = document.getElementById('video2');
      window.navigator.mediaDevices.getUserMedia({
        video: true
      }).then(function (stream) {
        video.srcObject = stream;
      }).catch(function (err) {
        throw Error('Cannot capture user camera.');
      });
      // if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      //   navigator.mediaDevices.getUserMedia({
      //     video: true,
      //     audio: true
      //   }).then(function (stream) {
      //     console.log(stream);
      //     video.src = (window.URL).createObjectURL(stream);
      //     video.play();
      //   }).catch(function (err) {
      //     console.log(err);
      //   });
      // } else if (navigator.getMedia) {
      //   navigator.getMedia({
      //     video: true
      //   }).then(function (stream) {
      //     console.log(stream);
      //     video.src = (window.webkitURL).createObjectURL(stream);
      //     video.play();
      //   }).catch(function (err) {
      //     console.log(err);
      //   });
      // }
    },
    // 上传图片       --面容录入
    upLoadImg () {
      let shortCut = document.getElementById('shortCut2');
      let scContext = shortCut.getContext('2d');
      let video = document.getElementById('video2');
      scContext.drawImage(video, 0, 0, 290, 218);
      let imgStr = shortCut.toDataURL('image/png');
      var fd = new FormData();
      var blob = this.dataURItoBlob(imgStr);

      fd.append('source_from', 'webpage_upload');// 在formdata加入需要的参数
      fd.append('file', blob, 'image.png');

      console.log('append', fd);
      UploadFiles(1, '', fd)
        .then(response => {
          this.addFace(response.data.url, this.seletedItem.OLAPKey, this.isActive ? 1 : 0);
        })
        .catch(err => {
          console.log(err);
          layer.alert(err.msg);
        });
    },
    // 图片转成Buffer
    dataURItoBlob (dataURI) {
      var byteString = atob(dataURI.split(',')[1]);
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    // 录入面容
    addFace (faceImgPath, studentID, isHeadImgKey) {
      console.log('addFace:faceImgPath, studentID, isHeadImgKey:', faceImgPath, studentID, isHeadImgKey);
      AddStudentFace(faceImgPath, studentID, isHeadImgKey).then(result => {
        // isActive 为true时代表连同头像一起更改.
        this.seletedItem.HeadImgSupport = this.isActive ? faceImgPath : this.seletedItem.HeadImgSupport;
        layer.alert(this.seletedItem.MainDemoName + '录入成功.');
        this.$emit('updateStudentList');
        this.accordingStudentIDFn(this.seletedItem.OLAPKey);
        console.log('addFace', result);
      }).catch(error => {
        layer.alert(error.msg);
        console.log(error);
      });
    },
    // 拍照登记
    takePhotoForApply () {
      if (this.seletedItem && this.seletedItem.OLAPKey > 0) {
        this.upLoadImg();
      } else {
        layer.alert('请选择学生后再操作.');
      }
    },
    showStudentList () {
      // this.stopMediaStreamTrack();
      this.isShowStudentList = true;
    },
    closeStudentList () {
      this.isShowStudentList = false;
    },
    // 切换学生
    doAfterSelectedStudent (item) {
      this.isShowStudentList = false;
      console.log(item, 'doAfterSelectedStudent');
      this.accordingStudentIDFn(item.OLAPKey);
      this.$emit('seletedItem', item);
      // this.del_fice = 'none'
    },
    // 根据学生id查询是否已录入人脸
    accordingStudentIDFn (OLAPKey) {
      accordingStudentID(OLAPKey).then(result => {
        if (result.code == 0) {
          if (result.data.IsFaceRecognitKey == 0) {
            this.del_fice = 'none';
          } else {
            this.del_fice = '';
          }
        }
        console.log(result.data.IsFaceRecognitKey, '根据学生id查询是否已录入人脸');
      }).catch(error => {
        console.log(error, 'err');
      });
    },

    btn_del_fice () {
      let that = this;
      console.log(this.seletedItem, '删除人脸记录参数');
      if (!this.seletedItem.OLAPKey) {
        return;
      }

      layer.confirm('是否一起清除头像', {
        shadeClose: false,
        btn: [{
          name: '取消',
          callBack: function () {
            // stuItem.isOpt = false;
            // that.isSaving = false;
            that.param.IsHeadImgKey = 0;
            that.param.OLAPKey = that.seletedItem.OLAPKey;

            that.delFaceInfoFn(that.param);
            layer.close();
          }
        }, {
          name: '确认',
          callBack: function () {
            that.param.IsHeadImgKey = 1;
            that.param.OLAPKey = that.seletedItem.OLAPKey;

            that.delFaceInfoFn(that.param);
            that.seletedItem.SexKey = '1';
            layer.close();
          }
        }]
      });
    },
    // 删除人脸记录
    delFaceInfoFn () {
      delFaceInfo(this.param).then(result => {
        console.log(result, '清除人脸返回参数');
        if (result.code == 0) {
          this.headImgUrl = '';
          this.seletedItem.HeadImgSupport = '';
          this.accordingStudentIDFn(this.seletedItem.OLAPKey);
          this.$emit('parentFn', this.seletedItem);

          layer.alert('清除面容成功!!');
        } else {
          console.log(result, '删除人脸返回参数失败');
          layer.alert('清除面容失败!!');
        }
      }).catch(error => {
        console.log(error, 'err');
      });
    }
  }
};
</script>
<style scoped>
.container {
  position: relative;
  width: 400px;
  height: 400px;
  float: left;
}
.message {
  float: left;
}
video,
#canvas2 {
  position: absolute;
  top: 0%;
  height: 201px;
  left: 44%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.none {
  display: none;
}
</style>

