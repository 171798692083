<template>
     <div>
        <div class="form_info_edit form_info_list">
            <input-text :formTitle="'班级名'"
                  :required="true"
                  :readonly="isReadonly"
                  v-model="dataJson.MainDemoName"></input-text>
        
        </div>
         <save-cancel-btn-group :isSingleSubmit="true"
                                 :singleSubmitType="'dynamic'"
                                 @confirmClick="confirmClick"
                                 @cancelClick="cancelClick"></save-cancel-btn-group>
     </div>
</template>
<script>
import { CWSUpdateClass,CWSAddClass,CWSGetTeacherForUpdate} from "../../../../API/workbench";

export default {
    data(){
        return{
            // 提交数据对象
            dataJson: {
                "OLAPKey":"",// --班级ID
					"MainDemoName":''// --班级名称	
            },
      
        }
    },
    components:{

    },
    props:{
        dataInfo:{
            type:[String,Number],
            default:0
        },
        isReadonly:Boolean
    },
    created(){
        if(this.dataInfo){
          this.dataJson.OLAPKey=this.dataInfo.OLAPKey
          this.dataJson.MainDemoName=this.dataInfo.MainDemoName
        }
    },
    computed:{
    },
    methods:{

        getDataInfo(){
            CWSGetTeacherForUpdate(this.dataInfo.OLAPKey).then(result=>{
                console.log("CWSGetTeacherForUpdate",result.data)
                this.dataJson=result.data
            }).catch(error=>{
                console.log("errorCWSGetTeacherForUpdate",error.msg)
            })
        },
        verifyData(){
            let isFail=false
            if(!this.dataJson.MainDemoName){
                isFail=true
                layer.alert("请输入班级名")
            }
              return isFail
        },   
        confirmClick(callBack){
            if(this.verifyData()){
                if(callBack){
                    callBack()
                }
                return false
            }
            if(this.dataJson.OLAPKey>0){       //修改
                CWSUpdateClass(this.dataJson).then(result=>{
                    console.log("CWSUpdateTeacher",result)
                       this.$emit("afterSuccess",result.data)
                        this.$emit("cancelClick")
                }).catch(error=>{
                      layer.alert(error.msg)
                    if(callBack){
                    callBack()
                }
                    console.log("errorCWSUpdateTeacher",error.msg)
                })
            }else{                 //新增
                CWSAddClass(this.dataJson).then(result=>{
                    console.log("CWSAddTeacher",result)
                    layer.alert("新增成功")
                     this.$emit("afterSuccess",result.data)
                    this.$emit("cancelClick")
                }).catch(error=>{
                      layer.alert(error.msg)
                    if(callBack){
                    callBack()
                }
                    console.log("errorCWSAddTeacher",error.msg)
                })
            }
  
        },
        cancelClick(){
            this.$emit("cancelClick")
        }
    }
}
</script>