<template>
  <div class="WKT_discount_package_popup_list"
       :class="{'opt':selectItem.isOpt}"
       @click="selectedItem">
    <div class="list_name">
      {{selectItem.PackageName}}
    </div>
    <div class="list_text">
      适用：{{selectItem.range}}
    </div>
    <div class="list_btn" @click.stop="selectedItemDetails">

    </div>
  </div>
</template>
<script>

export default {
  name: 'singleSelectionItem',
  data () {
    return {

    };
  },
  props: {
    selectItem: Object,
    selectList: Array
  },
  created () {

  },
  mounted () {
  },
  // 一进入页面就触发；可用于初始化页面数据等
  activated () {
  },
  watch: {

  },
  computed: {

  },
  methods: {
    selectedItem () {
      this.$emit('selectedItem', this.selectItem);
    },
    selectedItemDetails () {
      this.$emit('selectedItemDetails', this.selectItem);
    }
  }
};
</script>

