<template>
  <div :class="imgClass"
       style="border-radius:0px"
       @click="seletedItem">
    <!-- class="WKT_slideshow_upload_box  hover_border_blue pr" -->
    <div>
      <div style="width:100%;height:100%"
           v-if="isShowImg&&imgURlString">
        <img :src="headImg"
             alt="../../../../../../../../public/image/no_logo@2x.png">
        <div class="upload_img_progress"
             :style="{display:vIsProgress }">
          <!-- <span class="text">{{vUploadProgress}}</span> -->
          <span class="percentage"
                :style="{width:vUploadProgress}"></span>
        </div>
      </div>
      <div class="WKT_slideshow_upload_noImg"
           v-else>
        <div class="upload_icon">+</div>
        <div>{{placeholder}}</div>
      </div>
      <div id="screanContent"
           style="z-index:1;position: absolute !important;right: 45px; opacity: 0"></div>

      <img ref="imgFile"
           :style="{ width:imgWidth+'px',  height:imgHeight+'px', 'position': 'absolute', 'opacity': 0,'z-index':0}">
      <!-- 上传 -->
      <div :class="{'WKT_slideshow_upload_shade':isShowImg&&imgURlString}"
           style="width:100%;height:100%;position: absolute;top: 0;left: 0;">
        <input type="file"
               title=""
               name="file"
               accept="image/*"
               class="button my_view_chg_index"
               @change="chooseImg($event,100)"
               style="width:100%;height:100%;opacity:0;"
               data-type="userIconImg">
      </div>
    </div>
    <div class="WKT_slideshow_upload_text"
         v-if="size||remarks">
      <div>{{size}}</div>
      <div class="font_gray">{{remarks}}</div>
    </div>
  </div>
</template>

<script>
import { UploadFiles } from '../../../API/workbench';
import { createDomForCutImg, htmlToPCUpLoadImgSimple, convertToMd5, base64ToBlob } from '../../../utils/upLoadTextImg';
export default {
  data () {
    return {
      selectPicUrl: '',          // 选中图片url
      crood2: {},           // 截图坐标之类
      isChgPic: false,
      preWHRatio: 1,
      nextElWidth: 0,
      nextElHeight: 0,
      nextWHRatio: 0,
      selectedCurrentTarget: null,
      picType: '',
      // imgstyleSize: this.imgstyle, // 裁切大小
      imgURlString: this.ImgURl || '',
      index: 0
    };
  },
  props: {
    imgHeight: {// 裁切的图片高度，默认210，
      type: Number,
      default: 210
    },
    imgWidth: {// 裁切的图片宽度，默认320，
      type: Number,
      default: 320
    },
    // imgstyle: {// 默认img样式，
    //   type: String,
    //   default: 'width: 38px;height: 38px;border-radius: 50px'
    // },
    placeholder: {// 水印提示
      type: String,
      default: '上传图片'
    },
    isShowImg: { // 显示上传的图片
      type: Boolean,
      default: false
    },
    ImgURl: String, // 图片地址
    size: String, // '尺寸：600px*336px',
    remarks: String, // '备注'
    imgClass: {
      type: String,
      default: 'WKT_slideshow_upload_content'
    },
    indexKey: {
      type: Number,
      default: 0
    },
    imgInfo: Object
  },
  computed: {
    headImg () {
      let url = this.imgURlString;
      if (url.indexOf('data:image/') == -1) {
        url = this.$store.getters.CDNURL + url;
      }
      return url;
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../public/image/no_logo@2x.png') + '"';
    },
    vUploadProgress () {
      return this.imgInfo.percent + '%';
    },
    vIsProgress () {
      return (this.imgInfo.percent == 100) ? 'none' : 'block';
    }
  },
  watch: {
    ImgURl: {
      handler (c, o) {
        this.imgURlString = c;
      },
      deep: true
    }
  },
  methods: {
    seletedItem () {
      this.$emit('seletedItem');
    },
    chooseImg (e, index) {
      console.log(index, 'chooseImg', e);
      this.picType = 'img';
      this.index = index || 100;
      this.selectedCurrentTarget = this.$refs.imgFile;
      if (e.target.files.length > 0) {
        const fileObj = e.target.files[0];
        const form = new FormData();
        // 文件对象
        form.append('file', fileObj);
        const index1 = fileObj.name.lastIndexOf('.');
        const index2 = fileObj.name.length;
        const reg = /(jpg|bmp|gif|ico|pcx|jpeg|tif|png|raw|tga)$/;
        const suffix = fileObj.name.substring(index1 + 1, index2); // 后缀名
        if (reg.test(suffix)) {
          UploadFiles(1, '', form).then(result => {
            if (this.isShowImg) {
              this.imgURlString = result.data.url;
            }
            this.setInputValue();
            this.$emit('uploadImgSuccess', result.data.url, this.indexKey);   // index为了区分新增还是修改..
          }, err => {
            layer.alert(err.msg);
          });
        }
      }
    },
    // 选中图片渲染后将input里面的值清除, 防止下次选中同一个文件无效.
    setInputValue () {
      var loadInput = this.$el.querySelector('.my_view_chg_index');
      console.log('loadInput', loadInput);
      loadInput.value = '';
    },
    clearPicData () {
      // document.body.style.overflow = 'auto';
      this.isChgPic = false;
      this.selectPicUrl = '';
      this.preWHRatio = 1;
    }
  }

};
</script>

<style>
/* .my_datum_picture_img_text {
  width: 70px;
  height: 70px;
  border-radius: 4px;
} */
</style>