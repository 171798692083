<template>
  <div class="course_list">
    <div class="course_list_text"
         v-html="$utils.translationSchoolText(item.Content)">

    </div>
    <div class="course_list_btn"
         @click.stop="releaseComment">
      课评
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  methods: {
    // 发布点评
    releaseComment () {
      this.$emit('releaseComment', this.item);
    }
  }
};
</script>

