<template>
  <div >
    <div v-if="dataInfo.dataInfo.CouponKey" class="list_info_block" @click="clickItem">
      <div class="list_info_block_name">{{ dataInfo.name }}</div>
      <div class="list_info_block_btn"></div>
      <div class="list_info_block_data">
        <div class="data_list">
          <div class="data_list_name">参加(人)</div>
          <div class="data_list_text">
            {{ dataInfo.dataInfo.getNum }}
          </div>
        </div>
        <div class="data_list">
          <div class="data_list_name">报名(人)</div>
          <div class="data_list_text font_yellow">
            {{ dataInfo.dataInfo.useNum }}
          </div>
        </div>
        <div class="data_list">
          <div class="data_list_name">金额(元)</div>
          <div class="data_list_text">
            {{ dataInfo.dataInfo.payOrderMoney }}
          </div>
        </div>
        <div class="data_list">
          <div class="data_list_name">奖励(元)</div>
          <div class="data_list_text">
            {{ dataInfo.dataInfo.Awardamount }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    dataInfo: Object,
  },
  computed:{
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    },
  },
  methods: {
    clickItem() {
      if(this.$utils.CheckOpenSettingPower(this.$store.getters.SaaSClientInfo.OpenModule,7)){
              if (this.$utils.CheckModulePower(this.vRolePowerList, 55)) {
                 this.$router.push({ name: "activityMonitoringPlatform" });
              } else {
                this.$utils.CheckModulePowerToTips(55);
              }

          }else{
            this.$utils.CheckOpenSettingToTips(7);
          }
     
    },
  },
};
</script>