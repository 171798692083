<template>
  <div class="screening_condition_list fl"
       @click.stop="del">
    <span>{{dataModel.formTitle}}：</span>
    <span>{{converToText()}}</span>
    <div>x</div>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'extendSearchParams'
  },
  props: {
    dataModel: Object,
    extendSearchParams: Array // 整个已选条件的数据结构
  },
  methods: {
    del () {
      this.dataModel.fieldList.forEach(obj => {
        let findIndex = this.extendSearchParams.findIndex(o => { return o.name == obj.name; });
        if (findIndex >= 0) {
          this.extendSearchParams.splice(findIndex, 1);
        }
      });
      this.$emit('input', this.extendSearchParams);
      this.$emit('change');
    },
    converToText () {
      let findItem = this.dataModel.fieldList[0].sourceData.find(o => { return o.OLAPKey == this.dataModel.fieldList[0].value; });
      if (findItem) {
        return findItem.MainDemoName;
      } else {
        return this.dataModel.fieldList[0].defaultValue;
      }
    }
  }
};
</script>
