<template>
  <div>
    <div class="content_marginRL content_marginTop_30 customer_registration">
      <div class="customer_registration_content form_info_edit"
           id="customer_registration">
        <div class="form_info_edit mendian_setting_content single_shadow">
          <!-- 基础信息 -->
          <div class="customer_registration_info">

            <input-text :formTitle="'门店ID'"
                        :readonly="isReadonly"
                        :formPlaceholder="'必填'"
                        :required="true"
                        v-model="mdInfo.SaaSClientNO"></input-text>

            <input-text :formTitle="'门店名称'"
                        :readonly="isReadonly"
                        :formPlaceholder="'必填'"
                        :required="true"
                        v-model="mdInfo.MainDemoName"></input-text>

            <input-text :formTitle="'公司全称'"
                        :readonly="isReadonly"
                        :formPlaceholder="'请输入'"
                        :required="false"
                        v-model="mdInfo.FullName"></input-text>

            <input-select :formTitle="'门店版本'"
                          :id="'type'"
                          :name="'name'"
                          :required="false"
                          :readonly="isReadonly || vIsVersions"
                          :dataList="mdVersions"
                          :selectedInfo="vMdVersions"
                          @selectedItem="changemdVersions"></input-select>

            <input-select :formTitle="'门店类型'"
                          :id="'type'"
                          :name="'name'"
                          :required="false"
                          :readonly="isReadonly||mendianInfo.OLAPKey>0"
                          :dataList="mdType"
                          :selectedInfo="vMdType"
                          @selectedItem="changeMdTypee"></input-select>

            <input-dialog :formTitle="'所属总店'"
                          :formPlaceholder="'选填'"
                          :readonly="isReadonly||mendianInfo.OLAPKey>0"
                          @showEldilog="selectParentShop"
                          v-model="mdInfo.ParentKeyValue"></input-dialog>
          </div>
          <div class="customer_registration_info customer_registration_info_more">
            <div id="screanContent"
                 style="z-index:1;position: absolute !important;right: 45px; opacity: 0"></div>

            <input-upload-img :ImgURl="mdInfo.LogoPath?mdInfo.LogoPath:''"
                              :formTitle="'门店LOGO'"
                              :imgHeight="100"
                              :imgWidth="100"
                              :saaSClientNameKey="mendianInfo.OLAPKey"
                              :readonly="isReadonly"
                              @doSetPicCorp="doSetLogoPath"></input-upload-img>

            <input-upload-img :ImgURl="mdInfo.BrandPath?mdInfo.BrandPath:''"
                              :formTitle="'品牌图片'"
                              :imgHeight="336"
                              :imgWidth="600"
                              :saaSClientNameKey="mendianInfo.OLAPKey"
                              :readonly="isReadonly"
                              :imgstyle="'width: 40px!important;height: 30px;z-index:10;'"
                              @doSetPicCorp="doSetBrandPath"></input-upload-img>

            <input-text :formTitle="'门店电话'"
                        :readonly="isReadonly"
                        :formPlaceholder="'请输入'"
                        :required="false"
                        v-model="mdInfo.Telephone"></input-text>

            <input-text :formTitle="'地址'"
                        :readonly="true"
                        :formPlaceholder="'请输入'"
                        :required="false"
                        v-model="mdInfo.Address"></input-text>

            <input-text :formTitle="'联系人'"
                        :readonly="isReadonly"
                        :formPlaceholder="'必填'"
                        :required="true"
                        v-model="mdInfo.ContactName"></input-text>

            <input-phone :formTitle="'联系人手机'"
                         :formPlaceholder="'必填'"
                         :required="true"
                         :readonly="isReadonly"
                         v-model="mdInfo.ContactPhone"></input-phone>

            <input-text :formTitle="'备注'"
                        :readonly="isReadonly"
                        :formPlaceholder="'请输入'"
                        :required="false"
                        v-model="mdInfo.Remark"
                        :class="'no_border_bottom'"></input-text>

          </div>
        </div>
        <!-- 按钮组 -->
        <div v-if="!isReadonly">
          <save-cancel-btn-group :isSingleSubmit="true"
                                 :singleSubmitType="'dynamic'"
                                 @confirmClick="confirmClick"
                                 @cancelClick="cancelClick"></save-cancel-btn-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { EditSaaSClient, GetSaaSClientDetails, SelectHQSaaSClient } from '../../../../API/workbench.js';
export default {
  data () {
    return {
      mdInfo: {
        SaaSClientNO: '', // 门店id
        MainDemoName: '', // 门店名称
        FullName: '', // 公司全称
        EditionKey: '', // 门店版本
        EditionKeyValue: '', // 门店版本
        TypeKey: '', // 门店类型
        TypeKeyValue: '', // 门店类型
        ParentKey: '', // 所属总店
        ParentKeyValue: '', // 所属总店
        LogoPath: '', // 门店LOGO
        BrandPath: '', // 品牌图片
        Telephone: '', // 门店电话
        Address: '', // 地址
        ContactName: '', // 联系人
        ContactPhone: '', // 联系人手机
        Remark: ''// 备注
      },
      isReadonly: false,
      mdType: [
        { type: 3, name: '单店' },
        { type: 4, name: '连锁总店' },
        { type: 5, name: '连锁门店' },
        { type: 6, name: '连锁总部' },
        { type: 7, name: '连锁加盟' },
        { type: 8, name: '连锁直营' }
      ],
      mdVersions: [
        { type: 2, name: '精准版' },
        { type: 3, name: '旗舰版' },
        { type: 4, name: '高级版' },
        { type: 5, name: '连锁版' },
        { type: 6, name: '课件版' }
      ]
    };
  },
  components: {

  },
  props: {
    mendianInfo: {
      type: Object,
      default: null
    }
  },
  created () {
    if (this.mendianInfo && Number(this.mendianInfo.OLAPKey) > 0) { // 修改员工
      this.getGetSaaSClientDetails();
    };
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
  },
  computed: {
    vMdType () {
      return {
        type: this.mdInfo.TypeKey,
        name: this.mdInfo.TypeKeyValue
      };
    },
    vMdVersions () {
      return {
        type: this.mdInfo.EditionKey,
        name: this.mdInfo.EditionKeyValue
      };
    },
    vIsVersions () {
      if (this.mdInfo.TypeKey != 6) { // 单店时，不可变更有所属总部的门店的门店版本
        if (this.mdInfo.ParentKey > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  },
  methods: {
    // 获取门店信息
    getGetSaaSClientDetails () {
      GetSaaSClientDetails(this.mendianInfo.OLAPKey).then(result => {
        // this.mdInfo = result.data;
        Object.keys(this.mdInfo).forEach(key => {
          this.mdInfo[key] = result.data[key];
        });
        console.log(this.mdInfo, '获取门店信息');
      }, error => {
        layer.alert(error.msg);
      });
    },
    changemdVersions (obj) {
      this.mdInfo.EditionKey = obj.type;
      this.mdInfo.EditionKeyValue = obj.name;
    },
    changeMdTypee (obj) {
      this.mdInfo.TypeKey = obj.type;
      this.mdInfo.TypeKeyValue = obj.name;
    },
    selectParentShop () {
      SelectHQSaaSClient().then(result => {
        console.log(this.mdInfo.ParentKey, 'this.mdInfo.ParentKey');
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择所属总店',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.mdInfo.ParentKey, dataSourceList: result.data, required: false },
          callBack: {
            onSelectedItem: (data) => {
              this.mdInfo.ParentKey = data.OLAPKey;
              this.mdInfo.ParentKeyValue = data.MainDemoName;
            }
          }
        });
      }, err => {
        layer.alert(err.msg);
      });
    },
    doSetLogoPath (Url) {
      this.mdInfo.LogoPath = Url;
    },
    doSetBrandPath (Url) {
      console.log(Url, 'ZangIcon');
      this.mdInfo.BrandPath = Url;
    },
    // 保存
    confirmClick (callBack) {
      let res = this.validatestaffItemInfo();
      if (!res) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.confirmData(callBack);
    },
    confirmData (callBack) {
      console.log(this.mdInfo, 'this.mdInfo');
      EditSaaSClient(this.mendianInfo.OLAPKey, this.mdInfo).then(result => {
        this.$emit('afterSuccess', 'Edit', result.data);
        this.cancelClick();
        layer.alert('修改成功');
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 验证客户信息
    validatestaffItemInfo () {
      if (this.mdInfo.MainDemoName) {
        this.mdInfo.MainDemoName = this.mdInfo.MainDemoName.replace(/(^\s*)|(\s*$)/g, '');
      }
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;  // 校验身份证输入
      let flag = true;
      if (!this.mdInfo.SaaSClientNO) {
        layer.alert('请输入门店ID');
        flag = false;
      } if (!this.mdInfo.MainDemoName) {
        layer.alert('请输入门店名称');
        flag = false;
      } else if (!this.mdInfo.ContactName) {
        layer.alert('请输入联系人');
        flag = false;
      } else if (this.mdInfo.ContactPhone.length != 11) {
        layer.alert('请输入11位的联系人手机号');
        flag = false;
      }
      return flag;
    },
    // 取消
    cancelClick () {
      this.$emit('closeFrom');
    }
  }
};
</script>
