<template>
  <div :class="particular != 'par' ? 'class_bigin_box' : 'class_bigin_box_par'">
    <div class="cw_summarizing">
      <div class="date_time pr">
        <div class="new_table_title_date left_type">
          <div @click="searchLast()"
               class="date_last fl"></div>
          <div class="cw_date_calendar">
            <input-date class="cw_date_box"
                        :formTitle="''"
                        :isparticular="true"
                        :style="{marginLeft: '150px',marginTop: '-30px'}"
                        @changeDate="changeDay"></input-date>
          </div>
          <span class="date_text">{{yearView}}&nbsp;年&nbsp;{{monthView}}&nbsp;月 </span>
          <div @click="searchNext()"
               class="date_next fr"></div>
        </div>
      </div>
    </div>
    <!-- <div style="clear:borth"></div> -->
    <div class="data-list"
         @click.stop="registerBusEvent">
      <div class="data-list-div">
        <span :class="particular != 'par' ? 'data-list-span' : 'data-list-span_par'"><span :class="particular != 'par' ? 'data-list-span-h1' : 'data-list-span-h1_par'">{{dataInfo.CourseCount==null?0:setDataToWan(dataInfo.CourseCount)}}</span>节课</span>
        <span :class="particular != 'par' ? 'data-list-span' : 'data-list-span_par'"><span class="data-list-span-li">已上课次</span></span>
      </div>
      <div class="data-list-div">
        <span :class="particular != 'par' ? 'data-list-span' : 'data-list-span_par'"><span :class="particular != 'par' ? 'data-list-span-h1' : 'data-list-span-h1_par'">{{dataInfo.CourseTimeCount==null?0:setDataToWan(dataInfo.CourseTimeCount)}}</span>分钟</span>
        <span :class="particular != 'par' ? 'data-list-span' : 'data-list-span_par'"><span class="data-list-span-li">上课时长</span></span>
      </div>
      <div class="data-list-div">
        <span :class="particular != 'par' ? 'data-list-span' : 'data-list-span_par'"><span :class="particular != 'par' ? 'data-list-span-h1' : 'data-list-span-h1_par'">{{this.$utils.flowFormate(dataInfo.ConsumptionFlowCount,'number')=='--'?'--':Math.abs(this.$utils.flowFormate(dataInfo.ConsumptionFlowCount,'number'))}}</span>{{this.$utils.flowFormate(dataInfo.ConsumptionFlowCount,'unit')}}</span>
        <span :class="particular != 'par' ? 'data-list-span' : 'data-list-span_par'"><span class="data-list-span-li">消耗流量</span></span>
      </div>
      <div class="data-list-div">
        <span class="data-list-span1"><span :class="particular != 'par' ? 'data-list-span-h1' : 'data-list-span-h1_par'">{{dataInfo.ArrivedNumCount==null?0:setDataToWan(dataInfo.ArrivedNumCount)}}</span>人次</span>
        <span class="data-list-span1"><span class="data-list-span-li">签到人次</span></span>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {
  },
  props: {
    TeacherKey: [String, Number],
    BigCoursewarePackageKey: {
      type: [String, Number],
      default: ''
    },
    dataInfo: Object,
    par: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      monthView: '',
      yearView: '',
      particular: this.par || ''
    };
  },
  created () {
    this.atTimeView();
  },
  mounted () {
  },
  methods: {
    setDataToWan (num) {
      if (Number(num) > 9999) {
        return (Math.floor(((Number(num)) / 10000) * 100) / 100) + '万';
      } else {
        return Number(num);
      }
    },
    searchLast () {
      this.monthView--;
      if (this.monthView < 10) { this.monthView = '0' + this.monthView; }
      if (this.monthView < 1) { this.monthView = 12; this.yearView = this.yearView - 1; }
      this.searchDate();
    },
    // 搜索下一个月
    searchNext () {
      this.monthView++;
      if (this.monthView < 10) { this.monthView = '0' + this.monthView; }
      if (this.monthView > 12) { this.monthView = '01'; this.yearView = this.yearView + 1; }
      this.searchDate();
    },
    changeDay (startDate) {
      console.log(startDate, '点击当前汇总数据');
      var d = Date.new(startDate);

      this.yearView = d.getFullYear();
      var month = d.getMonth() + 1;
      if (month < 10) {
        this.monthView = '0' + month;
      } else {
        this.monthView = month;
      }
      let endDate = this.yearView + '-' + this.monthView + '-' + this.mGetDate(this.yearView, this.monthView);
      this.$emit('getDateRangeCoursewareStat', startDate, endDate, this.BigCoursewarePackageKey);
    },
    atTimeView () {
      // this.dataInfo.CourseTimeCount = this.dataInfo.CourseTimeCount.split('.')[0]
      // this.dataInfo.ArrivedNumCount = this.dataInfo.ArrivedNumCount.split('.')[0] == '' ? '0' : this.dataInfo.ArrivedNumCount.split('.')[0]
      var date = Date.new();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month < 10) { month = '0' + month; }
      this.yearView = year;
      this.monthView = month;
    },
    registerBusEvent () {
      this.$emit('registerBusEvent');
    },
    // 搜索日期
    searchDate () {
      // 开始日期
      let startDate = this.yearView + '-' + this.monthView + '-' + '01';
      // 结束日期
      let endDate = this.yearView + '-' + this.monthView + '-' + this.mGetDate(this.yearView, this.monthView);
      this.$emit('getDateRangeCoursewareStat', startDate, endDate, this.BigCoursewarePackageKey);
    },
    mGetDate (year, month) {
      var d = Date.new(year, month, 0);
      return d.getDate();
    }

  }
};
</script>
<style scoped>
.class_bigin_box {
  /* width: 100%; */
  /* height: 100%; */
  box-sizing: border-box;
  width: 491px;
  height: 80px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #f1f7ff, #f1f7ff),
    linear-gradient(0deg, #f5f8ff, #f5f8ff), #f9f9fb;
  border: 1px solid #ececec;
  border-radius: 4px;
  margin-top: 5px;
}
.class_bigin_box_par {
  box-sizing: border-box;
  width: 491px;
  height: 80px;
  background: linear-gradient(0deg, #f1f7ff, #f1f7ff),
    linear-gradient(0deg, #f5f8ff, #f5f8ff), #f9f9fb;
  border: 1px solid transparent;
  border-radius: 4px;
  margin: 23px 0 0 22px;
}
.date_time {
  padding: 0;
}
.date_time .new_table_title_date {
  font-size: 14px;
  width: 160px;
  margin-top: -5px;
}
.date_time .new_table_title_date.left_type .date_last,
.date_time .new_table_title_date.left_type .date_next {
  width: 30px;
  height: 30px;
  background-size: 5px;
}
.date_time .new_table_title_date .date_last {
  background: url(../../../../../public/image/change_prev_defult.png) no-repeat
    center center;
}
.date_time .new_table_title_date .date_last:hover {
  background-image: url(../../../../../public/image/change_prev_light.png);
}
.date_time .new_table_title_date .date_next {
  background: url(../../../../../public/image/change_next_defult.png) no-repeat
    center center;
}
.date_time .new_table_title_date .date_next:hover {
  background-image: url(../../../../../public/image/change_next_light.png);
}
.date_time .cw_date_calendar {
  width: 100px;
  height: 28px;
  cursor: default;
  position: absolute;
  top: 24px;
  left: 50px;
}
.date_time .cw_date_box {
  position: absolute;
  width: 100px;
  height: 28px;
  cursor: pointer;
  opacity: 0;
}
.cw_summarizing .top_content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 110px;
  padding: 20px 20px;
}
.cw_summarizing .top_content_list {
  width: 119px;
  height: 88px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
.cw_summarizing .top_content_list.bottom_border_blue::after {
  content: '';
  background: #90bcff;
  border: 2px solid #90bcff;
  box-sizing: border-box;
  position: absolute;
  width: 119px;
  height: 4px;
  border-radius: 0px 0px 4px 4px;
}
.cw_summarizing .top_content_list.bottom_border_green::after {
  content: '';
  background: #7cd499;
  border: 2px solid #7cd499;
  box-sizing: border-box;
  position: absolute;
  width: 119px;
  height: 4px;
  border-radius: 0px 0px 4px 4px;
}

.cw_summarizing .top_content_list .list_title {
  font-size: 14px;
  padding: 15px 0px 10px 15px;
  color: #666666;
}
.cw_summarizing .top_content_list .list_content {
  font-size: 30px;
  padding: 5px 0px 10px 15px;
}
.cw_summarizing .top_content_list .list_content .list_unit {
  font-size: 12px;
  overflow: hidden;
  display: inline-block;
}
.cw_summarizing .top_content_list .list_content .list_num {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 68%;
}
.data-list {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40px;
  cursor: pointer;
  z-index: 2;
}
.data-list-div {
  display: block;
  float: left;
  width: 122px;
  height: 40px;
  text-align: center;
  margin-top: -5px;
}
.data-list-span {
  display: block;
  float: left;
  width: 100%;
  height: 20px;
  border-right: 1px solid #ececec;
  font-size: 12px;
}
.data-list-span_par {
  display: block;
  float: left;
  width: 100%;
  height: 20px;
  border-right: 1px solid #fff;
}
.data-list-span1 {
  display: block;
  float: left;
  width: 100%;
  height: 20px;
}
.data-list-span-h1 {
  font-weight: 500;
  font-size: 20px;
  margin-right: 2px;
  color: #3498db;
}
.data-list-span-h1_par {
  font-weight: 500;
  font-size: 20px;
  color: #3498db;
  margin-right: 2px;
}
.data-list-span-li {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  align-items: center;

  color: #999999;
}
.new_table_title_date.left_type {
  margin: 0;
  margin-left: 165px;
  line-height: 30px;
  padding: 4px 0;
  float: left;
}
</style>