<template>
<div :class="styleName?'index_JXT_ul':''">
  <div class="JXT_dynamic_list" :style="styleName">
    <div class="JXT_dynamic_content">
      <div class="dynamic_content_body JXT_inform_box pr">
        <!-- 作业浏览头部 -->
        <browse-item-head :headBtnType="headBtnType"
                          :dataInfo="dataInfo"
                          :IsAllSchool="IsAllSchool"
                          @doHeadBrowseButClick="doHeadBrowseButClick"></browse-item-head>
        <!-- 作业浏览内容 -->
        <div :class="styleName?'task_browse_box_pa':''">
        <browse-item-content :dataInfo="dataInfo"></browse-item-content>
        <!-- 学生-->
        <div class="body_relevant_people" v-if="dataInfo.ReaderSetContent">
          <span class="people_icon">@</span>
          <span class="people_text">{{dataInfo.ReaderSetContent}}</span>
        </div>
        <!-- 学生作业进度 -->
        <div class="body_homework_complete"
             @click="correctTaskButClick">
          <div>
            <span>完成学生 {{vCompleteNum}}人 </span>

            <span v-if="vNoCorrectNum>0">
            <span class="font_icon">·</span>
            <span>未批改</span>
            <span class="font_red">{{vNoCorrectNum}}</span>人
              </span>
          </div>
          <div class="next_icon"></div>
        </div>
                </div>
        <!-- 按钮 -->
        <div v-if="contentTextButton">
          <release-btn :btnText="contentTextButton"
                       @click="correctTaskButClick"></release-btn>
        </div>
      </div>
    </div>
  </div>
 </div>
</template> 

<script>
import browseItemHead from '../common-component/browse-box/browse-item-head';
import browseItemContent from '../common-component/browse-box/browse-item-content';
// import taskCorrectItem from './task-correct-item';
import releaseBtn from '../common-component/release-btn';
// 作业浏览项
export default {
  components: {
    browseItemHead,
    browseItemContent,
    // taskCorrectItem,
    releaseBtn
  },
  data () {
    return {

    };
  },
  props: {
    dataInfo: {
      type: Object, //
      default: function () {
        return {
          // ID
          // 作业标题
          // 发布人
          // 发布时间
          // 发布文本内容
          // 发布的附件内容
          // 接收学生对象
          // 完成人数
          // 批改人数
        };
      }
    },
    headBtnType: {
      type: String,
      default: ''
    },
    contentTextButton: {
      type: String,
      default: ''
    },
    styleName: {
      type: String,
      default: ''
    },
    IsAllSchool: {
      type: Number,
      default: 2
    }
  },
  computed: {
    vCompleteNum () {
      if (this.dataInfo.CompleteNum && this.dataInfo.PublishedNum) {
        return parseInt(this.dataInfo.CompleteNum) + '/' + parseInt(this.dataInfo.PublishedNum);
      } else {
        return parseInt(this.dataInfo.workFinishNum) + '/' + parseInt(this.dataInfo.workNum);
      }
    },
    vNoCorrectNum () {
      if (this.dataInfo.NoCorrectNum) {
        return parseInt(this.dataInfo.NoCorrectNum);
      } else if (this.dataInfo.NoWorkCheckNum) {
        return parseInt(this.dataInfo.NoWorkCheckNum);
      } else {
        return 'null';
      }
    }

  },
  created () {

  },
  mounted () {
    this.$dialog.register(this, ['taskCorrect']);
  },
  methods: {
    // 头部操作按钮(作业详情)
    doHeadBrowseButClick (submitBtnName) {
      this.$emit('edit', this.dataInfo, submitBtnName);
    },
    // 作业操作按钮(作业批改)
    correctTaskButClick () {
      console.log(this.dataInfo.ID, '作业批改');
      if (this.dataInfo.ID) {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '批改作业',
          moduleName: 'taskCorrect',
          data: { taskID: this.dataInfo.ID }
        });
      }
    }
  }
};
</script>

<style>
</style>