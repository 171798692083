<template>
  <div class="form_info_value">
    <el-input-number :placeholder="placeholder"
                     v-model="vInputValue"
                     :readonly="readonly"
                     @focus="focusEvent"
                     @blur="blurEvent"
                     @change="changeEvent()">
    </el-input-number>
  </div>
</template>
<script>
import { numberToDecimalPlaces } from '../../utils/index.js';
import { debug } from 'util';
export default {
  name: 'inputNumber',
  data () {
    return {
      inputValue: '',
      defaultValue: '', // 默认值,
      defaultDecimalPlaces: 2, // 默认值
      isWrithing: false
    };
  },
  props: {// 单选数据项 需绑定到界面
    max: [String, Number],
    min: [String, Number],
    value: [String, Number],
    placeholder: String,
    decimalPlaces: [String, Number],
    readonly: Boolean
  },
  computed: {
    vInputValue: {
      get () {
        if (this.inputValue == '0' || this.inputValue) {
          return this.isWrithing ? numberToDecimalPlaces(this.inputValue, this.vDecimalPlaces) : this.inputValue.toFixed(this.vDecimalPlaces);
        }
      },
      set (n, o) {
        console.log('vInputValue', n);
        this.inputValue = n;
      }
    },
    vDecimalPlaces () {
      if (this.decimalPlaces == 0) {
        return this.decimalPlaces;
      } else {
        return this.decimalPlaces || this.defaultDecimalPlaces;
      }
    }
  },
  created () {
    this.setInputValue(this.value);
  },
  watch: {
    value (c, o) {
      this.setInputValue(c);
    }
  },
  methods: {
    setInputValue (v) {
      if (v == '0' || v) {
        this.inputValue = Number(v);
        this.inputValue = this.inputValue;
      } else {
        this.inputValue = this.defaultValue;
      }
    },
    changeEvent () {
      this.inputValue = (this.inputValue == '0' || this.inputValue) ? numberToDecimalPlaces(this.inputValue, this.vDecimalPlaces) : '';
      this.inputValue = this.inputValue > this.max ? this.max : this.inputValue;
      this.inputValue = this.inputValue < this.min ? this.min : this.inputValue;
      this.$emit('input', this.inputValue);
      this.$emit('inputChange', this.inputValue);
    },
    focusEvent () {
      this.isWrithing = true;
    },
    blurEvent () {
      this.isWrithing = false;
    }
  }
};
</script>


