<template>
  <div class="courseware_popup_list">
    <div class="courseware_manage_left">
      <div class="left_title">
        <!--  v-if="vMendianInfo.TypeKey != 3" -->
        <div

          class="title_list"
          :class="{ opt: infoType == 1 }"
          @click="switchInfoType(1)"
        >
          授权课件包
        </div>
        <div
          class="title_list"
          :class="{ opt: infoType == 2 }"
          @click="switchInfoType(2)"
        >
          自研课件包
        </div>
      </div>
      <div class="left_ul">
        <courseware-item
          v-for="(item, index) in dataInfoList"
          :key="index"
          :dataInfo="item"
          @clickItem="selectCourseware"
        ></courseware-item>
        <div v-if="dataInfoList.length == 0" class="monitoring_content_nodata" style="margin-top:120px">
          <div class="monitoring_content_nodata_txt">
            亲，当前没有任何数据哦~
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import coursewareItem from "./courseware-item";
import {
  GetAuthorizationCoursewarePackageList,
  GetIndependentDevelopmentCoursewarePackageList,
} from "../../../../API/workbench";
export default {
  data() {
    return {
      infoType: 1,
      dataInfoList: [],
    };
  },
  props:{
    coursewareInfo:Object
  },
  computed:{
    vMendianInfo() {
      return this.$store.getters.SaaSClientInfo;
    },
  },
  components: {
    coursewareItem,
  },
  created() {
    // if(this.vMendianInfo.TypeKey == 3){
    //   this.infoType = 2;
    // }
    this.getDataInfoList();
  },
  methods: {
    selectCourseware(data){
      this.dataInfoList.forEach(e=>{
        e.isSelect = false;
      })
      data.isSelect = true;
      this.$emit('selectCourseware',data)
    },
    switchInfoType(type) {
      this.infoType = type;
      this.getDataInfoList();
    },
    getDataInfoList() {
      
      if (this.infoType == 1) {
        GetAuthorizationCoursewarePackageList().then(
          (res) => {
            this.dataInfoList = [];
            res.data.forEach(e => {
              if(this.coursewareInfo && this.coursewareInfo.ID == e.ID){
                this.$set(e,'isSelect',true);
              }else {
                this.$set(e,'isSelect',false);
              }
              
              this.dataInfoList.push(e);
            });
          },
          (err) => {
            console.log(err.msg);
          }
        );
      } else {
        GetIndependentDevelopmentCoursewarePackageList().then(
          (res) => {
            this.dataInfoList = [];
            res.data.forEach(e => {
              if(this.coursewareInfo && this.coursewareInfo.ID == e.ID){
                this.$set(e,'isSelect',true);
              }else {
                this.$set(e,'isSelect',false);
              }
              this.dataInfoList.push(e)
            });
          },
          (err) => {
            console.log(err.msg);
          }
        );
      }
    },
  },
};
</script>