<template>
  <!-- 历史套餐-->
  <div class="WKT_discount_package_blcok"
       style="margin-bottom: 20px;"
       :class="comboItem.comboColor">
    <div class="package_blcok_top">
      <div class="package_blcok_name">
        {{comboItem.PackageName}}
      </div>
      <div class="package_blcok_text pr"
           v-if="vIdoneityItem">
        <span class="package_blcok_text_name">适用：</span>{{vIdoneityItem.ApplyCorName||vIdoneityItem.ProjectKeyValue}}
      </div>
      <div class="package_blcok_text pr"
           v-if="vIdoneityItem">
        <div v-if="vIdoneityItem.UnitKey==5||vIdoneityItem.UnitName">
          <span class="package_blcok_text_name">数量：</span>{{$utils.mAmountType(vIdoneityItem.UnitPrice,2)}}元x{{$utils.mAmountType(vIdoneityItem.NumCount,1)}}{{vIdoneityItem.UnitKeyValue||vIdoneityItem.UnitName}}
        </div>
        <div v-else>
          <span class="package_blcok_text_name">数量：</span>
          <span v-if="vIdoneityItem.UnitKey!=5">{{vIdoneityItem.UnitKeyValue}}卡 |</span>
          {{$utils.mAmountType(vIdoneityItem.NumCount,1)}}{{vUnitName}}
        </div>
      </div>
      <div v-if="visMoreShow">
        <div class="package_blcok_text">......</div>
        <div class="package_blcok_text">更多</div>
      </div>
    </div>
    <div class="package_blcok_bottom">
      <div class="package_blcok_date"
           v-if="vShowCreateTime">课时有效期
        <span class="font_bold">{{ vShowCreateTime }}</span>{{vUnitKeyValue}}
      </div>
      <div class="package_blcok_details">
        查看详情
      </div>
    </div>
    <div class="package_blcok_cost">
      <div class="package_blcok_amount">
        <span class="package_blcok_unit">￥</span><span class="package_blcok_number">{{$utils.mAmountType(comboItem.SalePrice,2)}}</span>
      </div>
      <div class="package_blcok_btn" v-if="Number(this.$store.getters.SaaSClientInfo.WktInfo.IsOpenPay) == 1&&Number(comboItem.SalePrice)>0">购买</div>
    </div>
    <!-- <div class="package_blcok_recommend">
      推荐
    </div> -->
    <div class="WKT_discount_package_blcok_shade">
      <div class="shade_btn"
           @click="doStartComboClick">启用</div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'singleSelectionItem',
  data () {
    return {

    };
  },
  props: {
    comboItem: Object,
    comboIndex: Number
  },
  created () {

  },
  watch: {

  },
  computed: {
    // 有效期
    vShowCreateTime () {
      const findItem = this.comboItem.xykdList.find(o => {
        return o.UnitKey == 5;
      });
      if (findItem) {
        if (this.comboItem.ExpireTime) {
          // let newStartTime = this.comboItem.StartTime.replace(/\-/g, '.');
          // let newExpireTime = this.comboItem.ExpireTime.replace(/\-/g, '.');
          if (this.comboItem.StartTime && this.comboItem.StartTime != '1900-01-01') {
            return this.comboItem.StartTime + ' - ' + this.comboItem.ExpireTime;
          } else {
            return this.comboItem.ExpireTime;
          }
        } else {
          return this.$utils.mAmountType(this.comboItem.PeriodCount, 0);
        }
      } else {
        return false;
      }
    },
    // 有效期-单位显示
    vUnitKeyValue () {
      if (this.comboItem.ExpireTime || !this.comboItem.UnitKeyValue) {
        return '';
      } else {
        if (this.comboItem.UnitKeyValue == '月') {
          return '个月';
        } else {
          return this.comboItem.UnitKeyValue;
        }
      }
    },
    // 课单单位
    vUnitName () {
      if (this.vIdoneityItem.UnitKeyValue == '月' || this.vIdoneityItem.UnitName == '月') {
        return '个月';
      } else {
        return this.vIdoneityItem.UnitKeyValue || this.vIdoneityItem.UnitName;
      }
    },
    vIdoneityItem () {
      if (this.comboItem.xykdList.length > 0) {
        return this.comboItem.xykdList[0]; // 适用课单 显示第一个
      } else if (this.comboItem.incidentalsList.length > 0) {
        return this.comboItem.incidentalsList[0];// 适用杂费 显示第一个
      } else {
        return '';
      }
    },
    // 是否显示更多...提示
    visMoreShow () {
      if (this.comboItem.incidentalsList.length > 0 && this.comboItem.xykdList.length > 0) {
        return true;
      } else if (this.comboItem.incidentalsList.length > 1) {
        return true;
      } else if (this.comboItem.xykdList.length > 1) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    // 启用套餐
    doStartComboClick () {
      this.$emit('onStartComboClick', this.comboItem, this.comboIndex);
    }
  }
};
</script>

