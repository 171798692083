<template>
  <div class="body_box">
      <div class="swiper_box">
          <div class="swiper-container swiper_courseware_content">
            <div class="swiper-wrapper">
                <courseware-item v-for="(item,key) in dataList"
                     :key="key"
                     :item="item"
                     @seletedItem="seletedItem"></courseware-item>
            </div>
          </div>
          <div class="swiper-button-prev swiper-button-black " @click.stop="preClick"></div>
          <div class="swiper-button-next swiper-button-black " @click.stop="afterClick"></div>
      </div>
  </div>
</template>
<script>
import coursewareItem from './courseware-item.vue'
export default {
 
  data () {
    return {
     
    };
  },
  components: {
    coursewareItem
  },
  props:{
    dataList:Array
  },
  created () {
    
  },
  mounted () {
    // console.log('mounted',this.classData)
      // this.$emit('chgCoureDateAfter', this.classData[0]);
     this.swiper = new Swiper('.swiper_courseware_content', {
        grabCursor:true,
        slidesPerView: 5,
        spaceBetween: 15,
        // loop: false, // 循环模式选项
        observer: false,   // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: false,  // 修改swiper的父元素时，自动初始化swipe
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        
      }); 
        
  },
  methods: {
     swiperIndex (val) {
      this.swiper.updateSlides();
      this.swiper.slideTo(val);
    },
   seletedItem (item, type) {       // 只提供高亮,不提供请求.
   console.log("seletedItem",item)
      this.dataList.forEach(o => {
        if (o.CoursewarePackageKey == item.CoursewarePackageKey) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      this.$emit('seletedItem', item);
    },
     preClick () {
     console.log('preClick');
      this.findItem((locaIndex) => {
        console.log(locaIndex);
        let index = locaIndex;
        if (locaIndex == 0) {
          index = 0;
        } else {
          index = locaIndex + 1;
        }
        this.seletedItem(this.dataList[index]);
      });
    },
    afterClick () {
          console.log('preClick');
      this.findItem((locaIndex) => {
        let index = locaIndex;
        this.seletedItem(this.dataList[index]);
      });
    },
    findItem (callback) {
      let locaIndex = this.dataList.findIndex(o => {
        return o.isActive == true
      });
      if (locaIndex != -1) {
        callback(locaIndex);
      }
    },
  }
 
};
</script>
<style scoped>
.title_text{
    height: 22px;
    font-family: 'Alibaba PuHuiTi';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: block;
    align-items: center;
    color: #333333;
    
}
.body_box{
    width: 100%;
    margin-top: 20px;
    /* height: 110px; */
}
.swiper_box{
  margin-top: 10px;
  width:100%;
  height: 88px;
  position: relative;
}
.swiper {
    width: 160px;
    height: 60px;
}  

.swiper-button-prev{
    top: 46px;
    left: -30px;
    position: absolute;
    width: 30px;
    height: 10px;
    opacity: 0.3;
    background-size: 15px;
}
.swiper-button-next{
    top: 46px;
    right: -30px;
    position: absolute;
    width: 30px;
    height: 10px;
    opacity: 0.3;
    background-size: 15px;
}
.swiper-container{
  overflow: hidden;
}
swiper-container::after { height: 16px; content: "";  position:absolute; bottom:9px; right:0; padding-left:30px; background:-webkit-linear-gradient(left,transparent,#fff 55%); background:-o-linear-gradient(right,transparent,#fff 55%); background:-moz-linear-gradient(right,transparent,#fff 55%); background:linear-gradient(to right,transparent,#fff 55%); }
</style>