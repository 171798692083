<template>
  <div class=" all_view courseware_add_view scroll_type"
       @click="hiddenMenu">
    <div class="courseware_add_top "
         :class="(this.$store.getters.SaaSClientEditionKey == 6 && this.$store.getters.AcademicMdCwType == 2)?'topd':''">
      <div class="flex_box pr">

        <div class="add_title_btn"
             style="width:auto">
          <!-- <div class="btn_hover_bg_blue"
               @click="saveCourseware">保存</div> -->
          <div class="btn_hover_white btn_hover_bg_blue exit"
               @click="cancel">退出</div>
          <!-- <div  @click="showTest">测试弹出层</div> -->
        </div>
        <div class="add_title_input">
          <div class="edittext">编辑课件</div>
          <div class="add_title_input_title">{{ CoursewareTitle}}</div>
        </div>

        <div class="save_state_type">
          <div class="saveText">
            <span class="save_yes_icon"
                  :class="vStateClassName"></span>{{vSaveStateText}}
          </div>
          <div class="deleteCourse"
               v-if="PublishStatusKey==0"
               @click="deleteCourse">
            <img src="../../../../public/image/delete_courseware_managent.png"
                 alt=""
                 class="set_box_img">
            <div style="margin-left:5px;line-height: 20px;">删除</div>
          </div>

        </div>
      </div>
    </div>
    <div class="courseware_add_bottom">
      <div class="left_content">
        <div class="courseware_title_box">
          <span class="courseware_title_span">课件标题</span>
          <div class="left_list type_00  ">
            <div class="list_banner_box">
              <div class="text_box">
                <input class="input_box"
                       type="text"
                       maxlength="100"
                       style="width: 100%;"
                       placeholder="请输入课件名"
                       v-model="CoursewareTitle"
                       @blur="blurTitleInput" />
              </div>
            </div>
          </div>
        </div>
        <div class="left_list courseware_cover type_06"
             style="position: relative;height: 193px">
          <div class="list_title pr leftface">
            <div class="courseware_title_span">课件封面
              <span class="form_info_field ">

                <input-form-tip :tips="'上传图片大小不超过8MB，类型格式为.jpg,.jpeg,.png。'"></input-form-tip>
              </span>
            </div>
            <!-- <div class="font_blue">设置</div> -->
            <div class="switch_box">
              <el-switch v-model="isDefualtSet"
                         :width="72"
                         :height="22"
                         active-color="#59D382"
                         inactive-color="#59D382"
                         @change="uploadCourseware">
              </el-switch>
            </div>
            <div id="screanContent"
                 style="z-index:1;position: absolute !important;right: 45px; opacity: 0"></div>
            <input-upload-cover :ImgURl="dataInfo.CoverUrl"
                                :formTitle="''"
                                :imgHeight="isDefualtSet?360:360"
                                :imgWidth="isDefualtSet?640:360"
                                :readonly="false"
                                :acceptType="'image/*'"
                                :isDefualtSet="isDefualtSet"
                                @deleteCover="deleteCover"
                                @doSetPicCorp="doSetPicCorpHeadImg"></input-upload-cover>

          </div>
          <div class=" pr coverImg "
               v-if="dataInfo.CoverUrl">

            <div class="courseware_list accessory saa"
                 @click="seletedCoverUrl">

              <div class=" coverList">

                <div class="list_img"
                     :class="isDefualtSet?'':'defaultimage'">
                  <img class="img_box"
                       :src="vCoverUrl"
                       :onerror="defaultImg" />

                </div>
              </div>
            </div>
          </div>
          <div class="default_tips">{{ isDefualtSet ?'上传图片尺寸为 640*360px(16:9)':'上传图片尺寸为 360*360px(1:1)' }}</div>
        </div>

        <div class="announce_box">
          <div style="position: relative;">
            <span class="courseware_title_span"
                  style="line-height: 30px">课程预告
              <span class="form_info_field ">

                <input-form-tip :tips="'上传图片大小不超过8MB，类型格式为.jpg,.jpeg,.png。'"></input-form-tip>
              </span>
            </span>

            <div class="switch_box">
              <div class="announceBottom">上传图片</div>
              <input-upload-img :ImgURl="announceText"
                                :formTitle="''"
                                :isStudentWork="true"
                                :acceptType="'image/*'"
                                class="imgInput"
                                @doSetStudentWorks="doSetAnnounceText"></input-upload-img>
            </div>
          </div>
          <div class="area_announ_box">

            <div class="  coverImg "
                 v-if="this.announceText">
              <div class="courseware_list accessory saa announce">

                <div class="list_box">
                  <div class="list_img">
                    <img class="img_box"
                         :src="vAnnounceText"
                         :onerror="defaultImg" />

                  </div>
                </div>
              </div>
              <img class="deleteCoverButtom"
                   v-if="vAnnounceText&&this.announceText!=''"
                   @click.stop="deleteAnnounceText"
                   :src="require('../../../../public/image/delete_courseware.png')"
                   :onerror="defaultImg" />
            </div>
            <!-- <textarea v-model="announceText"
                      class="textAreaAnnoun"
                      placeholder=""
                      @blur="uploadCourseware"></textarea> -->
          </div>
        </div>
      </div>
      <div class="right_content">
        <courseware-view ref="coursewareView"
                         :dataInfo="dataInfo"
                         :editType="editType"
                         :coursewareId="editCoursewareId"
                         :authorizedCode="authorizedCode"
                         :SettingTemplate="SettingTemplate"
                         :boxScrollTops="scrollTop"
                         @seletedCoverUrl="seletedCoverUrl"
                         @uploadCourseware="uploadCourseware"
                         @blurTitleInput="blurTitleInput"
                         @saveAccessory="upAccessoryList"
                         @openCoursewareEdit="openCoursewareEdit"
                         @deleteAccessory="clearEditInfo"
                         @updateOption="updateOption"
                         :attachType="attachType"
                         :editInfo="editInfo"
                         @deleteCoverUrl="deleteCoverUrl"
                         @selectPPTAccessory="selectPPTAccessory"
                         @upCourseEvaluationInfo="upCourseEvaluationInfo"
                         @upHomeworkInfo="upHomeworkInfo"></courseware-view>
        <!-- <courseware-edit :coursewareId="editCoursewareId"
                         :dataInfo="dataInfo"
                         :editType="editType"
                         :attachType="attachType"
                         :editInfo="editInfo"
                         :authorizedCode="authorizedCode"
                         @deleteCoverUrl="deleteCoverUrl"
                         @selectPPTAccessory="selectPPTAccessory"
                         @deleteAccessory="clearEditInfo"
                         @saveAccessory="upAccessoryList"
                         @uploadCourseware="uploadCourseware"
                         @upCourseEvaluationInfo="upCourseEvaluationInfo"
                         @upHomeworkInfo="upHomeworkInfo"></courseware-edit> -->
      </div>
    </div>
    <custom-dialog :title="'PPT上传中...'"
                   :visible.sync="isShowDialog"
                   :width="'972px'"
                   :before-close="closeDialog">
      <pptUploadingFile :dataInfo="pptAuthItem"
                        :dataList="pptAuthItem.dataList"
                        :pptAuthList="pptAuthList"
                        :coursewareInfo="dataInfo"
                        :coursewareId="editCoursewareId"
                        :authorizedCode="authorizedCode"
                        @updatePPTAuthData="updatePPTAuthData"
                        @openCoursewareEdit="openCoursewareEdit"
                        @deleteAccessory="clearEditInfo"></pptUploadingFile>
    </custom-dialog>
  </div>
</template>
<script>
import coursewareView from './courseware-view/index';
import coursewareEdit from './courseware-edit/index';
import pptUploadingFile from './ppt-uploading-file/index';
import inputUploadCover from './courseware-view/input-upload-cover';
import upload from './courseware-view/upload';
import {
  CoursewareManageSave,
  CWSCoursewareManageSave,
  WEBVCoursewareManageSave,
  CoursewareManageGetEditDetail,
  WEBVCoursewareManageGetEditDetail,
  CWSCoursewareManageGetEditDetail,
  GetCourseEvaluationList,
  WEBVGetCourseEvaluationList,
  CWSGetCourseEvaluationList,
  GetHomeWorkDetail,
  CWSGetHomeWorkDetail,
  WEBVGetHomeWorkDetail,
  EditCoursewareManageAttach,
  CWSEditCoursewareManageAttach,
  WEBVEditCoursewareManageAttach,
  WEBVPptPageEscapeVerify,
  HQPptPageEscapeVerify,
  CWSPptPageEscapeVerify,
  WEBVPptPageEscape,
  HQPptPageEscape,
  CWSPptPageEscape,
  ExitCoursewarevisit,
  AddAttachVisitLog,
  EndAttachVisit,
  UpdateCoursewarevisitTime,
  VerifyISEndVisit,
  hqCoursewareManageDelete,
  CoursewareManageDelete,
  cwsCoursewareManageDelete
} from '../../../API/workbench';
import { md5 } from '../../../API/md5';
export default {
  data () {
    return {
      dataInfo: {
        Authorized: {},
        CoursewareAttach: [],
        CoursewareTeachingStep: [],
        Evaluation: [],
        HomeWork: {},
        TeachingProcedure: '',
        Title: ''
      },
      CoursewareTitle: '未命名课件标题',
      editType: '0',
      editInfo: {},
      attachType: '',
      AttachList: [],
      authorizedCode: '',
      editCoursewareId: 0,
      isSaveUpload: false,
      saveState: {
        saveStateText: '',
        stateKey: 0, // 是否有变更
        time1: '', //
        time3: '', //
        time4: '' //
      },
      isShowDialog: false,
      pptAuthItem: {
        OLAPKey: '34782',
        dataList: []
      },
      AnnounceTextData: [],
      pptAuthList: [], // 需要独立验证PPT的数组。
      pptVerifyFail: null,
      addAttachLog: null,
      pptPageVerify: null,
      attachVisitInfo: {},  // 访问附件info
      showInfo: null,  // 当前访问的附件info
      UpdateCoursewarevisitTimer: null,
      isDefualtSet: true,
      isShowAnnounce: true,
      announceText: '',
      SettingTemplate: {
        procedures: 0, // 教学步骤
        lessonCommentary: 0, // 课评话术
        homeworkAfter: 0, // 课后作业
        pptFontCheck: 0//PPT字体缺少校验
      },
      PublishStatusKey: 0
    };
  },
  components: {
    coursewareView,
    coursewareEdit,
    pptUploadingFile,
    inputUploadCover,
    upload
  },
  props: {
    coursewareId: [String, Number],
    scrollTop: Number
  },
  created () {
    console.log('this.$store.getters.SaaSClientInfo', this.$store.getters.SaaSClientInfo);
    setTimeout(() => {
      this.getCoursewareInfo();
    }, 500);
    this.$bus.on(this, 'wsCoursewareEditor', data => {
      if (data.Data) {
        this.waCoursewareEditSet(data.Data);
      }
      this.coursewareEditorUserEntry(data);
    });
    this.$bus.on(this, 'pptVerifyFail', this.pptVerifyFail = (data) => {
      console.log('pptVerifyFail', data, this.dataInfo);
      this.dataInfo.CoursewareAttach.forEach(item => {
        if (item.ID == data.DataKey) {
          item.Remark = data.remark
        }
      })
      // if()

    });

    this.$bus.on(this, 'addAttachLog', this.addAttachLogBus = (data) => {
      this.$websocket.commonWebsocketApi({
        'dataType': 'addAttachVisitLog',
        'data': {
          'DBKey': this.vmdInfo.DataBaseKey,
          'CWVID': this.dataInfo.Authorized.OLAPKey,
          'SaaSClientKey': this.vmdInfo.OLAPKey,
          'AttachKey': data,
          'TypeKey': 2,
          "api_url": this.$store.getters.APIURL,
          'StartPosition': ''
        }
      });
    });

    this.$bus.on(this, 'pptPageVerify', this.pptPageVerify = (data) => {
      console.log('pptPageVerify', data);
      this.setDataListItem(data);
    });
  },
  destroyed () {
    this.$bus.off(this, 'pptVerifyFail', this.pptVerifyFail);
    this.$bus.off(this, 'pptPageVerify', this.pptPageVerify);
    this.$bus.off(this, 'addAttachLog', this.addAttachLog);
    this.ExitCoursewarevisit();
    console.log('destroyed');
    clearInterval(this.UpdateCoursewarevisitTimer);

  },
  watch: {
    'dataInfo.CoursewareAttach' (n, o) {
      let j = n.findIndex(obj => {
        return obj.ShowOrder == this.editInfo.ShowOrder;
      });
      console.log('watch:dataInfo.CoursewareAttach', j, this.editType);
      if (j == -1 && this.editType != 1) {
        this.clearEditInfo();
      }
    }
  },
  mounted () {

    this.$bus.on(this, 'AftersCoursewareEditorState', (type, time) => {
      // 内容是否发现改变
      this.saveState.stateKey = time;
      if (this.saveState.stateKey > 0) {
        this.saveState.saveStateText = '';
      }
      // console.log('AftersCoursewareEditorState', type, time);
    });
    // 打开特定附件
    this.$bus.on(this, 'openAttachInfo', (res) => {
      console.log('openAttachInfo', res);
      // 关键字段：OLAPKey Title AttachTypeKey AttachViewUrl DurationNum currentNum currentTime CoverUrl
      // OLAPKey用于流量计费，如果不计流量，则OLAPKey为0，不计流量的暂时特指作业附件，其它如教学步骤的附件都需统计流量
      // res.ShowOrder = 0;
      // this.selectAttach(res);
    });
    // 开始课件访问
    this.$bus.on(this, 'beginAttachVisit', (res) => {
      console.log('beginAttachVisit', res);
      console.log('beginAttachVisit111', JSON.stringify(this.attachVisitInfo));
      // if (this.attachVisitInfo&&this.attachVisitInfo.attachVisitKey > 0) {
      //   this.EndAttachVisit(this.attachVisitInfo.attachVisitKey);
      // }
      if (res.dataInfo.ID > 0) {
        this.AddAttachVisitLog(res.dataInfo.ID, res.StartPosition);
      }
    });
    // 结束课件访问
    this.$bus.on(this, 'endAttachVisit', (res) => {
      console.log('endAttachVisit', res);
      res.EndPosition = res.EndPosition || '1';
      console.log('endAttachVisit222', JSON.stringify(this.attachVisitInfo));
      console.log(this.attachVisitInfo.attachKey, res.dataInfo.ID);
      if (this.attachVisitInfo.attachKey == res.dataInfo.ID) {
        this.EndAttachVisit(this.attachVisitInfo.attachVisitKey, res.EndPosition);
        this.attachVisitInfo.attachKey = 0;
        this.attachVisitInfo.attachVisitKey = 0;
      }
    });
    // 结束课件访问
    this.$bus.on(this, 'endAttachVisit', (res) => {
      console.log('endAttachVisit', res);
      res.EndPosition = res.EndPosition || '1';
      console.log('endAttachVisit222', JSON.stringify(this.attachVisitInfo));
      console.log(this.attachVisitInfo.attachKey, res.dataInfo.ID);
      if (this.attachVisitInfo.attachKey == res.dataInfo.ID) {
        this.EndAttachVisit(this.attachVisitInfo.attachVisitKey, res.EndPosition);
        this.attachVisitInfo.attachKey = 0;
        this.attachVisitInfo.attachVisitKey = 0;
      }
    });

    this.$bus.on(this, 'wsVerifyISEndVisit', (data) => {
      this.verifyISEndVisitRequest();
    });

    this.UpdateCoursewarevisitTimer = setInterval(() => {
      if (this.dataInfo.Authorized && this.dataInfo.Authorized.OLAPKey) {
        // // 课件访问结束
        // UpdateCoursewarevisitTime(this.dataInfo.Authorized.OLAPKey).then((res) => {
        //   console.log('UpdateCoursewarevisitTime OK');
        // }).finally((res) => {
        //   console.log('UpdateCoursewarevisitTime finally');
        // });
        this.$websocket.commonWebsocketApi({
          'dataType': 'updateCoursewarevisitTime',
          'data': {
            'DBKey': this.vmdInfo.DataBaseKey,
            'CWVID': this.dataInfo.Authorized.OLAPKey,
            "api_url": this.$store.getters.APIURL
          }
        });
      }
    }, 60000);


  },
  computed: {
    vSaveStateText () {
      // console.log('this.saveState11', this.saveState);
      if (!this.saveState.saveStateText && this.saveState.stateKey > 0) {
        return '输入中';
      } else if (this.saveState.time1 && this.saveState.stateKey <= 0) {
        return '  ' + this.saveState.time1.substring(this.saveState.time1.length - 5) + '  保存成功';
      } else {
        return '保存成功';
      }
    },
    vCoverUrl () {
      if (!this.dataInfo.CoverUrl) {
        this.dataInfo.CoverUrl = '';
      }
      if (this.dataInfo.CoverUrl.indexOf('http') > -1) {
        return this.dataInfo.CoverUrl;
      } else {
        return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
      }
    },
    vAnnounceText () {
      if (!this.announceText) {
        this.announceText = '';
      }
      if (this.announceText.indexOf('http') > -1) {
        return this.announceText;
      } else {
        return this.$store.getters.CDNURL + this.announceText;
      }
    },
    vStateClassName () {
      if (this.vSaveStateText) {
        if (this.vSaveStateText == '输入中') {
          return 'input_yes_icon';
        } else if (this.vSaveStateText == '保存中') {
          return 'save_in_yes_icon';
        } else {
          return 'save_yes_icon';
        }
      } else {
        return '';
      }
    },
    vmdInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    VisitAuthKey () {
      return this.dataInfo.Authorized.OLAPKey || '';
    },
  },

  methods: {
    // scrollList (e) {
    //   this.scrollTop = e.target.scrollTop;
    //   console.log(this.scrollTop)
    // },
    verifyISEndVisitRequest () {
      if (this.dataInfo.Authorized && this.dataInfo.Authorized.OLAPKey) {
        // 课件访问结束
        VerifyISEndVisit(this.dataInfo.Authorized.OLAPKey).then((res) => {
          console.log('VerifyISEndVisit OK', res);
          if (res.code == 10000) {
            layer.alert(res.msg);
            this.$emit('cancel');
          }
        }).catch(err => {
          console.log('err', err);
          if (err.code == 10000) {
            layer.alert(err.msg);
            this.$emit('cancel');
          }
        }).finally((res) => {
          console.log('VerifyISEndVisit finally');
        });
      }
    },
    selectAttach (data) {
      console.log('data', data);
      let oldShowInfo = null;
      this.dataInfo.newCoursewareAttach.forEach((e) => {
        if (e.isSelect) {
          oldShowInfo = e;
          // e.isSelect = false;
        }
      });
      console.log('this.dataInfo.newCoursewareAttach', this.dataInfo.newCoursewareAttach);
      console.log('oldShowInfo', oldShowInfo);
      // 上一个附件取消选择，需要退出访问，视频 音频，由播放器控制记录
      if (oldShowInfo && oldShowInfo.AttachTypeKey != 2 && oldShowInfo.AttachTypeKey != 3) {
        this.$bus.emit('endAttachVisit', {
          dataInfo: oldShowInfo,
          EndPosition: oldShowInfo.currentNum
        });
      }

      data.isSelect = true;
      this.showInfo = data;
      // uni.$emit('refreshWV', {
      //   showInfo: this.showInfo,
      //   isShowAllView: this.isShowAllView
      // }); // 初始化webview
      // // 传入数据给按钮组
      // this.$bus.emit('buttonGroupDataInfo', {
      //   dataInfo: this.showInfo,
      //   dataInfoLength: this.vListLength,
      //   isShowAllView: this.isShowAllView
      // });

      // 新附件选中，需添加访问日志，视频 音频，由播放器控制记录
      if (this.showInfo && this.showInfo.ID > 0 && this.showInfo.AttachTypeKey != 2 && this.showInfo.AttachTypeKey != 3) {
        this.$nextTick(() => {
          this.$bus.emit('beginAttachVisit', {
            dataInfo: this.showInfo,
            StartPosition: oldShowInfo.currentNum
          });
        });
      }

      // const d = UTILS.parseJson(this.showInfo);
      // this.uploadAttachProgress(d);
    },
    AddAttachVisitLog (AttachKey, StartPosition) {
      // 添加访问课件附件记录

      this.$websocket.commonWebsocketApi({
        'dataType': 'addAttachVisitLog',
        'data': {
          'DBKey': this.vmdInfo.DataBaseKey,
          'CWVID': this.dataInfo.Authorized.OLAPKey,
          'SaaSClientKey': this.vmdInfo.OLAPKey,
          'AttachKey': AttachKey,
          'TypeKey': 1,
          "api_url": this.$store.getters.APIURL,
          'StartPosition': StartPosition
        }
      });
      // AddAttachVisitLog(AttachKey, this.dataInfo.Authorized.OLAPKey, StartPosition).then((res) => {
      //   this.attachVisitInfo.attachKey = AttachKey;
      //   this.attachVisitInfo.attachVisitKey = res.data.AttachVisitKey;
      //   console.log('AddAttachVisitLog', this.attachVisitInfo);
      // });
    },
    EndAttachVisit (AttachVisitKey, EndPosition) {
      // console.log('EndAttachVisitEndAttachVisit', AttachVisitKey);
      // // 结束访问课件附件记录，AttachVisitKey为添加访问课件附件记录的Key
      // if (AttachVisitKey) {
      //   EndAttachVisit(AttachVisitKey, EndPosition).then((res) => {
      //     console.log('EndAttachVisit');
      //   });
      // }
    },
    // 退出课件。
    ExitCoursewarevisit (callback) {
      let oldShowInfo = null;
      if (this.dataInfo.newCoursewareAttach) {
        console.log('this.dataInfo.newCoursewareAttach', this.dataInfo.newCoursewareAttach);
        console.log('this.dataInfo.CoursewareAttach', this.dataInfo.CoursewareAttach);
        this.dataInfo.newCoursewareAttach.forEach((e) => {
          console.log('e.isSelect', e.isSelect);
          if (e.isSelect) {
            oldShowInfo = e;
            // e.isSelect = false;
          }
        });
      }
      console.log('oldShowInfo', oldShowInfo);
      // 上一个附件取消选择，需要退出访问，视频 音频，由播放器控制记录
      if (oldShowInfo) {
        let EndPosition = oldShowInfo.AttachTypeKey == 2 || oldShowInfo.AttachTypeKey == 3 ? oldShowInfo
          .currentTime : oldShowInfo.currentNum;
        this.EndAttachVisit(this.attachVisitInfo.attachVisitKey, EndPosition);
        // this.$bus.emit("endAttachVisit", {
        //   dataInfo: oldShowInfo,
        //   EndPosition: oldShowInfo.AttachTypeKey == 2 || oldShowInfo.AttachTypeKey == 3 ? oldShowInfo
        //     .currentTime : oldShowInfo.currentNum
        // })
      }
      console.log('ExitCoursewarevisit', this.dataInfo);
      if (this.dataInfo.Authorized && this.dataInfo.Authorized.OLAPKey) {
        // 课件访问结束
        ExitCoursewarevisit(this.dataInfo.Authorized.OLAPKey).then((res) => {
          console.log('ExitCoursewarevisit OK');
          this.$emit('exitCourseware');
        }).finally((res) => {
          console.log('ExitCoursewarevisit finally');
          if (callback) {
            callback();
          }
        });
      } else {
        console.log('ExitCoursewarevisit un begin');
        if (callback) {
          callback();
        }
      }
    },
    // PPT独立验证更新文件
    updatePPTAuthData (dataItem, resultData) {
      this.getPPTAuthDataList(resultData);
    },
    getPPTAuthDataList (data) {
      console.log('getPPTAuthDataList', data, this.pptAuthItem);
      let AttachUrl = data ? data.url || data.AttachUrl : '';
      let SizeNum = data ? data.SizeNum : '';
      this.pptAuthItem.AttachUrl = AttachUrl;
      this.pptAuthItem.SizeNum = SizeNum;

      let fn = '';
      if (this.vmdInfo.EditionKey == 5) {
        fn = HQPptPageEscapeVerify;
      } else if (this.vmdInfo.EditionKey == 6) {
        fn = CWSPptPageEscapeVerify;
      } else {
        fn = WEBVPptPageEscapeVerify;
      }
      this.pptAuthItem.dataList = [];
      fn(this.pptAuthItem.OLAPKey, this.$store.getters.clientId, AttachUrl)
        .then(result => {
          result.data.forEach((o, index) => {
            this.$set(o, 'isError', false);
            this.$set(o, 'progress', 0);
            this.$set(o, 'Remark', '');
            this.$set(o, 'PageNO', index + 1);
          });
          this.pptAuthItem.dataList = result.data;
          console.log('getPPTAuthDataList', this.pptAuthItem.dataList);
        })
        .catch(error => {
          console.log('error', error);
        });
    },
    setDataListItem (data) {
      let locaItem = this.pptAuthList.find(o => {
        return o.OLAPKey == data.DataKey;
      });
      console.log('setDataListItem', locaItem);
      if (locaItem) {
        locaItem.dataList.forEach(o => {
          if (o.AsynTaskKey == data.AsynTaskID) {
            if (data.nodeType == 2 && data.status == 0) {
              console.log('data.nodeType==2&&data.status==0');
              o.isError = true;
              o.Remark = data.remark;
              o.progress = data.progress;
            } else {
              console.log('elseelse');
              o.isError = false;
              o.Remark = data.remark;
              o.progress = data.progress;
            }
          }
        });
        let isAllPass = true;
        // 判断是否全部通过
        console.log('判断是否全部通过', locaItem.dataList);
        if (locaItem.dataList.length == 0) {
          isAllPass = false;
        } else {
          locaItem.dataList.forEach(o => {
            if (o.progress != 100) {
              isAllPass = false;
              return false;
            } else if (o.isError) {
              isAllPass = false;
              return false;
            }
          });
        }
        if (isAllPass) {
          console.log('locaItem.dataList', locaItem.dataList);
          layer.alert('验证成功，继续进行转码');
          this.verifyPPT(locaItem);
          //   layer.confirm(
          //     '验证成功，继续进行转码？',
          //   {
          //     btn: [
          //       {
          //         name: '确认',
          //         callBack: ()=> {
          //           this.verifyPPT(locaItem)
          //           // this.isShowDialog=true
          //           layer.close();
          //         }
          //       }
          //     ]
          //   }
          // );
        }
      }
    },
    // PPT正常直接转码
    verifyPPT (data) {
      console.log(
        this.$websocket.ws.readyState,
        'this.$websocket.ws.readyState'
      );
      if (this.$websocket.ws.readyState != 1) {
        layer.alert('网络不稳定，请稍后再试。');
        return false;
      }
      let AttachUrl = data ? data.url || data.AttachUrl : '';
      let fn = '';
      if (this.vmdInfo.EditionKey == 5) {
        fn = HQPptPageEscape;
      } else if (this.vmdInfo.EditionKey == 6) {
        fn = CWSPptPageEscape;
      } else {
        fn = WEBVPptPageEscape;
      }
      fn(
        data.OLAPKey,
        this.$store.getters.clientId,
        AttachUrl,
        this.authorizedCode,
        data.SizeNum
      )
        .then(res => {
          this.isShowDialog = false;
          console.log(
            'this.dataInfo.CoursewareAttach',
            this.dataInfo.CoursewareAttach
          );
          let obj = this.dataInfo.CoursewareAttach.find(o => {
            return o.ID == data.OLAPKey;
          });
          if (obj) {
            this.$asyncTask.register(res.data.AsynTaskKey, sendData => {
              console.log('sendData', sendData);
              this.revokeObjectURL(obj.AttachViewUrl);
              obj.AttachViewUrl = sendData.Data.AttachViewUrl;
              obj.DurationNum = sendData.Data.DurationNum;
              obj.Remark = sendData.remark;
              obj.SizeNum = obj.SizeNum || res.data.SizeNum; // sendData.Data.SizeNum
              this.$bus.emit('wsCoursewareEditor', sendData);
              if (sendData.nodeType == 2 && sendData.status == 1) {
                // 结束节点
                console.log('sendData.nodeType == 2 && sendData.status == 1');
                this.$asyncTask.delete(sendData.AsynTaskKey);
                obj.AttachViewUrl = sendData.Data.AttachViewUrl;
                obj.CoverUrl = sendData.Data.CoverUrl;
                obj.DurationNum = sendData.Data.DurationNum;
                obj.ID = sendData.Data.OLAPKey;
                obj.previewList = sendData.Data.previewList;
                // obj.SizeNum = sendData.Data.SizeNum||;
                this.upAccessoryList();
              }
            });
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    },
    revokeObjectURL (url) {
      if (url) {
        try {
          URL.revokeObjectURL(url);
          return true;
        } catch (e) {
          // console.log('URL.revokeObjectURL error', url, e);
        }
      }
      return false;
    },
    selectPPTAccessory (data) {
      console.log('selectPPTAccessory', data, this.pptAuthItem);
      let locaItem = this.pptAuthList.find(o => {
        return o.OLAPKey == data.ID;
      });
      if (locaItem) {
        this.pptAuthItem = locaItem;
        if (this.pptAuthItem.dataList.length > 0) {
          this.isShowDialog = true;
        } else {
          layer.confirm('该PPT内部有元素不兼容转码，是否启用独立验证转码？', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  if (this.pptAuthItem.dataList.length > 0) {
                    this.isShowDialog = true;
                  } else {
                    this.getPPTAuthDataList();
                    this.isShowDialog = true;
                  }
                  layer.close();
                }
              }
            ]
          });
        }
      } else {
        console.log('data.Remark', data.Remark);
        //   data.ResultStageKey == 5 && data.UnusualStatusKey == 1 &&
        if (
          data.Remark && data.Remark.indexOf('元素时不兼容') != -1
        ) {
          this.pptAuthItem = {
            OLAPKey: data.ID,
            dataList: [],
            AttachUrl: '',
            SizeNum: ''
          };
          this.pptAuthList.push(this.pptAuthItem);
          layer.confirm('该PPT内部有元素不兼容转码，是否启用独立验证转码？', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  if (this.pptAuthItem.dataList.length > 0) {
                    this.isShowDialog = true;
                  } else {
                    this.getPPTAuthDataList();
                    this.isShowDialog = true;
                  }
                  layer.close();
                }
              }
            ]
          });
          //  this.getPPTAuthDataList()
          //  this.isShowDialog=true
        }
      }
    },
    showTest () {
      this.isShowDialog = true;
    },
    closeDialog () {
      this.isShowDialog = false;
    },
    waCoursewareEditSet (data) {
      console.log('waCoursewareEditSet', data);
      if (this.dataInfo.CoursewareAttach.length > 0) {
        this.dataInfo.CoursewareAttach.forEach(o => {
          // console.log('CoursewareAttach',o)
          if (data.OLAPKey == o.ID) {
            o.AttachViewUrl = data.AttachViewUrl;
            o.CoverUrl = data.CoverUrl;
            o.IsInsetFontKey = data.IsInsetFontKey;
            o.IsInsetFontKeyValue = data.IsInsetFontKeyValue;
          }
        });
      }
      if (this.dataInfo.CoursewareTeachingStep.length > 0) {
        this.dataInfo.CoursewareTeachingStep.forEach(o => {
          // console.log('CoursewareTeachingStep',o)
          if (data.OLAPKey == o.ID) {
            o.AttachViewUrl = data.AttachViewUrl;
            o.CoverUrl = data.CoverUrl;
            o.IsInsetFontKey = data.IsInsetFontKey;
          }
        });
      }
    },
    coursewareEditorUserEntry (data) {
      // console.log(data.newData, 'data.newData');
      if (
        data.newData &&
        data.newData.client_id != this.$store.getters.clientId &&
        data.newData.CoursewareID == this.coursewareId
      ) {
        layer.alert('有其他用户编辑此课件');
        this.$emit('cancel');
      }
    },
    blurTitleInput (text) {
      this.uploadCourseware();
    },
    seletedCoverUrl () {
      this.openEditContent('5');
    },
    hiddenMenu () {
      this.$refs.coursewareView.hiddenAttachMenu();
    },
    openCoursewareEdit (type, data, attachType) {
      this.openEditContent(type, data, attachType);
    },
    openEditContent (type, data, attachType, clearInfo) {
      console.log('openEditContent', data, typeof data == 'object');
      let oldShowInfo = null;
      this.dataInfo.CoursewareAttach.forEach((e) => {
        if (e.isSelect) {
          oldShowInfo = e;
          // e.isSelect = false;
        }
      });
      console.log('this.dataInfo.newCoursewareAttach', this.dataInfo.newCoursewareAttach);
      console.log('oldShowInfo', oldShowInfo);

      // 上一个附件取消选择，需要退出访问，视频 音频，由播放器控制记录
      if (oldShowInfo && oldShowInfo.AttachTypeKey != 2 && oldShowInfo.AttachTypeKey != 3 && oldShowInfo.AttachTypeKey != 4) {
        this.$bus.emit('endAttachVisit', {
          dataInfo: oldShowInfo,
          EndPosition: oldShowInfo.currentNum || '1'
        });
      }

      this.dataInfo.Evaluation.forEach(e => {
        e.isSelect = false;
      });
      this.dataInfo.CoursewareAttach.forEach(e => {
        e.isSelect = false;
      });
      this.dataInfo.CoursewareTeachingStep.forEach(e => {
        e.isSelect = false;
      });
      if (type == 4) {
        if (Array.isArray(this.dataInfo.HomeWork) || clearInfo) {
          this.dataInfo.HomeWork = {
            id: '',
            Title: '',
            Content: '',
            AttachList: []
          };
        }
      }
      if (type == 3 && clearInfo) {
        this.editInfo = {};
      }
      this.editType = type;
      if (attachType) {
        this.attachType = attachType;
      }
      if (typeof data == 'object') {
        data.isSelect = true;
        this.editInfo = data;
      } else {
        this.editInfo = {};
      }
      this.showInfo = data;
      console.log('showInfo1111', this.showInfo, data);
      // 新附件选中，需添加访问日志，视频 音频，由播放器控制记录
      if (this.showInfo && this.showInfo.ID > 0 && this.showInfo.AttachTypeKey != 2 && this.showInfo.AttachTypeKey != 3 && this.showInfo.AttachTypeKey != 4) {
        console.log('showInfo2222', this.showInfo);
        this.$nextTick(() => {
          this.$bus.emit('beginAttachVisit', {
            dataInfo: this.showInfo,
            StartPosition: this.showInfo.currentNum
          });
        });
      }
      this.saveState.saveStateText = '';
      // console.log(type, data, attachType, 'openEditContent');
      // 切换时清除课评话术默认项
      if (type != 3 && this.dataInfo.Evaluation.length > 0) {
        this.dataInfo.Evaluation = this.dataInfo.Evaluation.filter(obj => {
          return obj.OLAPKey != 0;
        });
      }
    },
    updateOption () {
      this.saveState.stateKey = 0
      this.saveState.saveStateText = '';
    },
    saveCourseware () {
      let upLoadType = false;
      this.dataInfo.CoursewareAttach.forEach(e => {
        if (!e.ID) {
          upLoadType = true;
        }
      });
      if (upLoadType) {
        layer.confirm('有课件素材正在上传，是否保存课件', {
          btn: [
            {
              name: '取消',
              callBack: () => {
                layer.close();
              }
            },
            {
              name: '确认',
              callBack: () => {
                this.uploadCourseware();
                layer.close();
              }
            }
          ]
        });
      } else {
        this.uploadCourseware();
      }
    },
    deleteCoverUrl () {
      this.editType = '0';
      this.uploadCourseware();
    },
    uploadCourseware () {
      // console.log('教学步骤保存中', this.editType);
      const reg = /^([\u4E00-\u9FA5A-Za-z0-9\uFF00-\uFFFF\u2026\u00D7\u00B7\u3010\u3011,\s`~!@#$%^&*()_。，——·‘’“”、。《》－；+<>?:"{},.\/;'[\]=\-|\\])+$/;
      // /[\s\x21-\x7e//\u4e00-\u9fa5A-Za-z0-9_-]*/
      //  /^[\u4e00-\u9fa5A-Za-z0-9_-]+[\)）（，；\(\.\%。\u4e00-\u9fa5A-Za-z0-9_-]?$/;
      let flag = true;
      const nonChineseCharacter = this.CoursewareTitle;
      for (let i = 0; i < nonChineseCharacter.length; i++) {
        if (!reg.test(nonChineseCharacter.charAt(i))) {
          flag = false;
          layer.alert('课件名不能有特殊字符');
          return false;
        }
      }
      if (!flag) {
        return false;
      }
      if (this.isSaveUpload) {
        return false;
      }

      localStorage.setItem('CoverRatio', this.isDefualtSet ? 2 : 1);
      this.isSaveUpload = true;

      let apiFN = '';
      let type = this.$store.getters.SaaSClientInfo.EditionKey;
      if (type == 6) {
        apiFN = CWSCoursewareManageSave;
      } else if (type == 5) {
        apiFN = CoursewareManageSave;
      } else {
        apiFN = WEBVCoursewareManageSave;
      }
      let ratio = this.isDefualtSet ? 2 : 1;
      let openadvance = this.isShowAnnounce ? 1 : 0;
      apiFN(
        this.editCoursewareId,
        this.CoursewareTitle,
        this.dataInfo.TeachingProcedure,
        this.dataInfo.CoverUrl,
        ratio,
        openadvance,
        this.announceText
      ).then(
        res => {
          this.editType == 1;
          this.beBeingSave(1);
          this.saveState.time1 = this.$utils.formateDateYMRHMS(
            Date.new(),
            'yyyymmddhhmm'
          );
          this.dataInfo.Title = this.CoursewareTitle;
          this.isSaveUpload = false;
          // this.clearEditInfo();
        },
        err => {
          this.isSaveUpload = false;
          layer.alert(err.msg);
        }
      );
    },
    upHomeworkInfo () {
      // console.log('upHomeworkInfo', this.editType);
      this.editType = '0';

      let apiFN = '';
      let type = this.$store.getters.SaaSClientInfo.EditionKey;
      if (type == 6) {
        apiFN = CWSGetHomeWorkDetail;
      } else if (type == 5) {
        apiFN = GetHomeWorkDetail;
      } else {
        apiFN = WEBVGetHomeWorkDetail;
      }
      apiFN(this.editCoursewareId).then(
        res => {
          this.beBeingSave(4);
          this.dataInfo.HomeWork = res.data;
        },
        err => {
          // console.log(err.msg);
        }
      );
    },
    upAccessoryList () {
      console.log('upAccessoryList');
      this.AttachList = [];
      this.dataInfo.CoursewareAttach.forEach(e => {
        if (e.ID) {
          this.AttachList.push({
            MainDemoName: e.MainDemoName,
            ShowOrder: e.ShowOrder,
            ID: e.ID || '',
            DurationNum: e.DurationNum || '',
            SizeNum: e.SizeNum,
            AttachTypeKey: e.AttachTypeKey,
            isSelect: e.isSelect || false
          });
        }
      });

      this.dataInfo.CoursewareTeachingStep.forEach(e => {
        // console.log('晒对啊很对啊水火的io',e)
        if (e.ID) {
          this.AttachList.push({
            MainDemoName: e.MainDemoName,
            ShowOrder: e.ShowOrder,
            ID: e.ID || '',
            DurationNum: e.DurationNum || '',
            SizeNum: e.SizeNum,
            AttachTypeKey: e.AttachTypeKey,
            isSelect: e.isSelect || false
          });
        }
      });
      console.log('看看参数', this.AttachList)
      let apiFN = '';
      let type = this.$store.getters.SaaSClientInfo.EditionKey;
      if (type == 6) {
        apiFN = CWSEditCoursewareManageAttach;
      } else if (type == 5) {
        apiFN = EditCoursewareManageAttach;
      } else {
        apiFN = WEBVEditCoursewareManageAttach;
      }

      apiFN(this.AttachList).then(
        res => {
          this.editType = 1
          this.beBeingSave(1);
          this.saveState.time1 = this.$utils.formateDateYMRHMS(
            Date.new(),
            'yyyymmddhhmm'
          );
        },
        err => {
          // console.log(err.msg);
        }
      );
    },
    upCourseEvaluationInfo (data) {
      // console.log('upCourseEvaluationInfo', data);
      if (!data) {
        this.editInfo = {};
        this.editType = '0';
      } else {
        this.editInfo.OLAPKey = data.essayID;
        this.editInfo.Content = data.Content;
      }
      let apiFN = '';
      let type = this.$store.getters.SaaSClientInfo.EditionKey;
      if (type == 6) {
        apiFN = CWSGetCourseEvaluationList;
      } else if (type == 5) {
        apiFN = GetCourseEvaluationList;
      } else {
        apiFN = WEBVGetCourseEvaluationList;
      }
      apiFN(this.editCoursewareId).then(
        res => {
          this.beBeingSave(3);
          // this.getCoursewareInfo()
          res.data.forEach(e => {
            if (e.OLAPKey == this.editInfo.OLAPKey) {
              e.isSelect = true;
            } else {
              e.isSelect = false;
            }
          });
          this.dataInfo.Evaluation = res.data;
        },
        err => {
          // console.log(err.msg);
        }
      );
    },
    getCoursewareInfo () {
      console.log(
        this.$websocket.ws.readyState,
        'this.$websocket.ws.readyState'
      );
      if (this.$websocket.ws.readyState != 1) {
        layer.alert('网络不稳定，请稍后再试。');
        return false;
      }
      // console.log(this.$zxClient, 'this.$zxClient.GetMCode()');
      let data;
      if (this.$zxClient.IsClient) {
        data = {
          DeviceTypeKey: 2,
          DeviceTypeKeyValue: '桌面端',
          DeviceCode: this.$zxClient.GetMCode(),
          Longitude: '',
          Latitude: '',
          Sign: '',
          IsSettle: 1
        };
      } else {
        data = {
          DeviceTypeKey: 1,
          DeviceTypeKeyValue: '浏览器',
          DeviceCode: '',
          Longitude: '',
          Latitude: '',
          Sign: '',
          IsSettle: 1
        };
      }
      // data = {
      //   DeviceTypeKey: 2,
      //   DeviceTypeKeyValue: '桌面端',
      //   DeviceCode: '340BC40DC769411B',
      //   Longitude: '',
      //   Latitude: '',
      //   Sign: ''
      // };
      data.Sign = md5(
        this.$utils.setStringmd5(data.DeviceTypeKey, data.DeviceCode, 'KJ')
      );
      let apiFN = '';
      let type = this.$store.getters.SaaSClientInfo.EditionKey;
      if (type == 6) {
        apiFN = CWSCoursewareManageGetEditDetail;
      } else if (type == 5) {
        apiFN = CoursewareManageGetEditDetail;
      } else {
        apiFN = WEBVCoursewareManageGetEditDetail;
      }
      apiFN(this.coursewareId, data).then(
        res => {
          this.authorizedCode = res.data.Authorized.AuthorizedCode;
          this.editCoursewareId = res.data.Authorized.CoursewareID;
          res.data.Evaluation.forEach(e => {
            e.isSelect = false;
          });
          this.saveState.time1 = this.$utils.formateDateYMRHMS(
            Date.new(),
            'yyyymmddhhmm'
          );
          res.data.CoursewareAttach.forEach(e => {
            // this.$set(e, 'ShowOrder', 0);
            this.$set(e, 'currentNum', 1);
            this.$set(e, 'isSelect', false);
          });

          res.data.CoursewareTeachingStep = (
            res.data.CoursewareAttach || []
          ).filter(obj => {
            return obj.TypeKey == 2 || obj.TypeKey == 3;
          });
          res.data.CoursewareAttach = (res.data.CoursewareAttach || []).filter(
            obj => {
              return obj.TypeKey == 1;
            }
          );
          if (Array.isArray(res.data.HomeWork)) {
            res.data.HomeWork = {
              id: '',
              Title: '',
              Content: '',
              AttachList: []
            };
          }
          this.dataInfo = res.data;
          this.dataInfo.newCoursewareAttach = [];
          this.dataInfo.newCoursewareAttach = this.dataInfo.CoursewareAttach.filter(obj => {
            return obj.TypeKey == 1;
          });

          let isNewCourse = localStorage.getItem('isAddCourse');
          console.log('-------------', isNewCourse)
          if (isNewCourse && isNewCourse == 1) {
            let a = localStorage.getItem('CoverRatio');

            this.isDefualtSet = a != 1;

            localStorage.setItem('isAddCourse', 0);
          } else {
            this.isDefualtSet = this.dataInfo.CoverRatio != 1;
          }
          this.PublishStatusKey = this.dataInfo.PublishStatusKey;
          this.isShowAnnounce = this.dataInfo.IsOpenAdvance == 1;
          this.announceText = this.dataInfo.AdvanceContent;
          this.SettingTemplate = this.dataInfo.SettingTemplate;
          console.log('this.dataInfo.SettingTemplatethis.dataInfo.SettingTemplate', this.SettingTemplate);
          if (this.dataInfo.newCoursewareAttach) {
            let i = 1;
            this.dataInfo.newCoursewareAttach.forEach((e) => {
              // e.ShowOrder = i;
              i++;
            });
          }
          // console.log(this.dataInfo, '获取指定课件的所有信息');
          this.dataInfo.CoursewareAttach.forEach(obj => {
            // console.log('初始化遍历数据',obj)
            if (
              Number(obj.UnusualStatusKey) == 0 &&
              Number(obj.ResultStageKey) > 0 &&
              obj.AsynTaskKey
            ) {
              // console.log(obj,'初始化转码中。')
              this.$asyncTask.register(obj.AsynTaskKey, sendData => {
                // console.log("sendDataIndex",sendData);
                this.revokeObjectURL(obj.AttachViewUrl);
                obj.CoverUrl = obj.CoverUrl || res.data.CoverUrl;
                obj.AttachViewUrl =
                  obj.AttachViewUrl || sendData.Data.AttachViewUrl;
                obj.DurationNum = sendData.Data.DurationNum;
                obj.Remark = sendData.remark;
                console.log('obj.SizeNum =', obj.SizeNum);
                obj.SizeNum = obj.SizeNum; // sendData.Data.SizeNum
                this.$bus.emit('wsCoursewareEditor', sendData);
                // if(obj.AttachTypeKey==2){
                //   if (sendData.nodeType == 2 && sendData.status ==1) {
                //      obj.ResultStageKey = 0;
                //      obj.UnusualStatusKey = 0;
                //   }
                //   //console.log("obj.AttachTypeKey==2",obj)
                // }else{
                if (sendData.nodeType == 2 && sendData.status == 1) {
                  // 结束节点
                  this.$set(obj, 'istest', '111111');
                  // console.log('sendData.nodeType == 2 && sendData.status == 1',obj)
                  obj.AttachViewUrl = sendData.Data.AttachViewUrl;
                  obj.CoverUrl = sendData.Data.CoverUrl;
                  obj.DurationNum = sendData.Data.DurationNum;
                  obj.ID = sendData.Data.OLAPKey;
                  obj.SizeNum = obj.SizeNum || sendData.Data.SizeNum;
                  this.$asyncTask.delete(sendData.AsynTaskKey);

                  // this.$parent.saveAccessory();
                  // this.upAccessoryList()
                } else if (sendData.status == 0) {
                }
                // }
              });
            }
          });

          if (this.dataInfo.Title) {
            this.CoursewareTitle = this.dataInfo.Title;
          }
        },
        err => {
          // console.log(err.msg);
        }
      );
    },
    revokeObjectURL (url) {
      if (url) {
        try {
          URL.revokeObjectURL(url);
          return true;
        } catch (e) {
          // console.log("URL.revokeObjectURL error", url, e);
        }
      }
      return false;
    },
    clearEditInfo () {
      // console.log('clearEditInfo');
      this.editInfo = {};
      this.editType = '0';
      this.attachType = '';
      this.saveState.saveStateText = '';
      this.saveState.stateKey = 0;
    },
    cancel () {
      console.log('this.dataInfo.newCoursewareAttach', this.dataInfo.newCoursewareAttach);
      console.log('this.dataInfo.CoursewareAttach', this.dataInfo.CoursewareAttach);
      let upLoadType = false;
      if (this.dataInfo.CoursewareAttach) {
        this.dataInfo.CoursewareAttach.forEach(e => {
          if (!e.ID) {
            upLoadType = true;
          }
        });
      }
      if (upLoadType) {
        layer.confirm('有课件素材正在上传，是否离开编辑课件', {
          btn: [
            {
              name: '取消',
              callBack: () => {
                layer.close();
              }
            },
            {
              name: '确认',
              callBack: () => {
                this.$emit('cancel');
                layer.close();
              }
            }
          ]
        });
      } else {
        layer.confirm('是否离开编辑课件', {
          btn: [
            {
              name: '取消',
              callBack: () => {
                layer.close();
              }
            },
            {
              name: '确认',
              callBack: () => {
                this.$emit('cancel');
                layer.close();
              }
            }
          ]
        });
      }
    },
    // 保存中
    beBeingSave (type) {
      this.saveState.saveStateText = '保存中';
      this.saveState.stateKey = 0;
      // 更新教学步骤时间
      this.saveState.time1 =
        type == 1
          ? this.$utils.formateDateYMRHMS(Date.new(), 'yyyymmddhhmm')
          : this.saveState.time1;
      // 更新课评话术时间
      this.saveState.time1 =
        type == 3
          ? this.$utils.formateDateYMRHMS(Date.new(), 'yyyymmddhhmm')
          : this.saveState.time1;
      // 更新课后作业时间
      this.saveState.time1 =
        type == 4
          ? this.$utils.formateDateYMRHMS(Date.new(), 'yyyymmddhhmm')
          : this.saveState.time1;
      // this.$utils.StrForTodoTime()
      // console.log(this.editType, this.saveState, '保存中');
      // setTimeout(() => {
      // }, 500);
    },
    // 课件包封面地址
    doSetPicCorpHeadImg (Url) {
      console.log('课件包封面地址', Url);
      this.dataInfo.CoverUrl = Url;
      this.uploadCourseware();
    },
    doSetAnnounceText (Url) {
      console.log('课件包封面地址', Url);
      this.announceText = Url;
      this.uploadCourseware();
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' + require('../../../../public/image/no_logo@2x.png') + '"'
      );
    },
    selectAccessory (data) {
      console.log('selectAccessory', data)
    },
    saveAccessory (data) {
      console.log('saveAnnounceText', data)
      this.announceText = data.url
    },
    deleteCover () {
      layer.confirm('确定删除此课件封面？', {
        btn: [
          {
            name: '取消',
            callBack: () => {
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              this.dataInfo.CoverUrl = ''
              this.uploadCourseware();
              layer.close();
            }
          }
        ]
      });

    },
    deleteAnnounceText () {
      layer.confirm('确定删除此课程预告？', {
        btn: [
          {
            name: '取消',
            callBack: () => {
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              this.announceText = ''
              this.uploadCourseware();
              layer.close();
            }
          }
        ]
      });

    },
    deleteCourse (callback) {
      layer.confirm('退出课件编辑并删除这个课件', {
        btn: [
          {
            name: '取消',
            callBack: () => {
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              let type = this.$store.getters.SaaSClientInfo.EditionKey;
              let fn = null;
              if (type == 6) {
                fn = cwsCoursewareManageDelete;
              } else if (type == 5) {
                fn = CoursewareManageDelete;
              } else {
                fn = hqCoursewareManageDelete;
              }
              // 课件访问结束
              fn(this.coursewareId).then((res) => {
                console.log('hqCoursewareManageDelete OK');
                this.$emit('exitCourseware');
              }).finally((res) => {
                console.log('hqCoursewareManageDelete finally');
                if (callback) {
                  callback();
                }
              });
              this.$emit('cancel');
              layer.close();
            }
          }
        ]
      });
    },

  }
};
</script>

<style scoped>
.courseware_add_view >>> * {
  letter-spacing: 1px !important;
}
.courseware_add_bottom {
  /* margin-top: 10px; */
}
.flex_box {
  width: 100% !important;
}
.courseware_add_view {
  width: 100% !important;
  padding: 0 !important;
}
.add_title_input {
  /* position: absolute;
  left: 415px; */
  border: 1px solid rgba(240, 240, 240, 1);
  width: 579px;
  height: 56px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
}
.save_state_type {
  position: relative;
  width: 180px;
  display: flex;
}
.save_state_type .save_yes_icon {
  background: url(../../../../public/image/save_success.png) no-repeat 50%;
  background-size: 18px;
  width: 30px;
  height: 20px;
  position: absolute;
  left: -30px;
  top: 0px;
}

.save_state_type .input_yes_icon {
  background: url(../../../../public/image/saviing.png) no-repeat 50%;
  background-size: 18px;
  width: 30px;
  height: 20px;
  position: absolute;
  left: -30px;
  top: 0px;
}
.save_state_type .save_in_yes_icon {
  background: url(../../../../public/image/saviing.png) no-repeat 50%;
  background-size: 18px;
  width: 30px;
  height: 20px;
  position: absolute;
  left: -30px;
  top: 0px;
}
.topd {
  top: 48px !important;
}
.right_content {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
}
.left_content {
  flex-shrink: 0;
  width: 315px;
  border-radius: 0px 0px 4px 4px;

  background-color: #fff;
  margin-right: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.courseware_title_span {
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.courseware_title_box {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
}
.left_list .list_banner_box {
  padding: 13px 0;
  /* background-color: #f8f8fa; */
  position: relative;
  overflow: hidden;
}

.left_list .list_banner_box .text_box {
  font-size: 14px;
  line-height: 20px;
  /* background: linear-gradient(0deg, #ffffff, #ffffff); */
  border: 1px solid #e1e4e7;
  border-radius: 4px;
  padding: 8px 10px;
  overflow: hidden;
  position: relative;
  width: 275px;
  height: 39px;
  border-radius: 4px;
}

.left_list .list_banner_box .courseware_add_img_box {
  height: 155px;
  border-radius: 8px;
  overflow: hidden;
}

.left_list .list_banner_box .add_progress_text {
  font-size: 14px;
}

.left_list .list_banner_box .add_progress_text.icon_type {
  padding-bottom: 22px;
  background-size: 44px;
}

.left_list .list_banner_box .courseware_list {
  margin-bottom: 10px;
}

.left_list .list_banner_box .courseware_list:last-child {
  margin-bottom: -15px;
}

.left_list .list_banner_box .courseware_list .list_img {
  /* width: 272px;
  height: 155px; */
  background: #fff;
}

.courseware_add_left
  .left_list
  .list_banner_box
  .courseware_list
  .list_img.border_type {
  border: 1px solid #e1e4e7;
}

.courseware_add_left .left_list .courseware_list .list_img .img_box {
  object-fit: contain;
}
.courseware_add_top {
  height: 92px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
  border-bottom: inherit !important;
  z-index: 13;
}
.left_list .list_banner_box .courseware_list .list_box {
  height: auto;
  cursor: pointer;
}
.left_list .list_banner_box .courseware_list .list_top {
  top: 10px;
  background-color: transparent;
}
.left_list .list_banner_box .courseware_list .list_bottom {
  bottom: 48px;
  background-color: transparent;
}
.left_list .list_banner_box .courseware_list .list_btn {
  width: 50px;
}
.left_list .list_banner_box .list_text_box {
  width: 276px;
  min-height: 60px;
  background: #fff;
  border: 1px solid #e1e4e7;
  border-radius: 8px;
  padding: 12px 14px;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.left_list .list_banner_box .list_text_box .text_box {
  /* display: block; */
}

.left_list .list_banner_box .list_text_box.opt {
  border: 4px solid #3498db;
}

.left_list .list_banner_box .list_text_box:last-child {
  margin-bottom: 0;
}
.switch_box {
  position: absolute;
  top: -3px;
  right: 0;
  cursor: pointer;
}

.courseware_cover >>> .el-switch__core:after {
  content: '16:9';
  position: absolute;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  line-height: 18px;
  width: 35px;
  height: 18px;
  font-size: 14px;
  background-color: #e8ebef;
  color: #fff;
}
.courseware_cover >>> .el-switch {
  height: 20px !important;
}
.courseware_cover >>> .el-switch:after {
  content: '1:1';
  position: absolute;
  right: 0;
  width: 42px;
  text-align: center;
  color: #fff;
}
.courseware_cover >>> .is-checked .el-switch__core:after {
  content: '1:1';
  margin-left: -35px !important;
}
.courseware_cover >>> .is-checked:after {
  content: '16:9';
  color: #fff;
  width: 35px;
  height: 18px;
  text-align: center;
  border-radius: 3px;
  margin-left: 0;
  top: 0;
  left: 0;
  z-index: 5;
  line-height: 20px;
}
.courseware_cover >>> .el-switch__core {
  margin: 0;
  position: relative;
  width: 40px;
  height: 22px;
  border: 1px solid #dcdfe6;
  outline: 0;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #e8ebef;
  -webkit-transition: border-color 0.3s, background-color 0.3s;
  transition: border-color 0.3s, background-color 0.3s;
  vertical-align: middle;
}
.default_tips {
  position: absolute;
  top: 160.5px;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}
.defaultimage {
  width: 112.5px !important;
  margin: auto;
}
.announce_box {
  width: 100%;
}
.textAreaAnnoun {
  width: 100%;
  height: 511px;
  border: 1px solid rgba(236, 236, 236, 1);
  border-radius: 4px;
}
.area_announ_box {
  margin-top: 10px;
  position: relative;
}
.noopere {
  opacity: 1;
}
.opere {
  opacity: 0;
}
.edittext {
  width: 125px;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
}
.add_title_input_title {
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  flex: 1;
  background: #f9fbfd;
  font-family: Alibaba PuHuiTi;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0 10px;
}
.saveText {
  color: #999999 !important;
  font-family: ABeeZee;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.deleteCourse {
  display: flex;
  margin-left: 10px;
  cursor: pointer;
}
.courseware_add_view >>> .courseware_pic_upload {
  border: 1px dashed #e8ebef;
  background: #ccc;
}
.coverImg {
  width: 200px;
  height: 112.5px;
  padding-top: 15px;
}
.coverImg .list_img {
  width: 200px;
  height: 112.5px;
  border: inherit;
}
.exit {
  width: 68px !important;
}
.saa {
  border-radius: 4px;
}

.leftface >>> .img-logo {
  z-index: 2 !important;
}
.area_announ_box >>> .my_datum_picture_text {
  border-bottom: inherit !important;
}
.area_announ_box >>> .my_datum_picture_text {
  position: relative !important;
}
.area_announ_box >>> .my_datum_picture_portrait {
  background: inherit !important;
}
.area_announ_box >>> .coverImg {
  position: absolute;
  top: -10px;
  z-index: 10;
  background: #fff;
  pointer-events: none;
}
.set_box_img {
  width: 19px;
  height: 20px;
}

.save_state_type .save_yes_icon {
  background-size: 22px !important;
}
.announceBottom {
  position: absolute;
  width: 80px;
  right: 0;
  height: 36px;
  border-radius: 4.6769232749938965px;
  line-height: 35px;
  text-align: left;
  color: #3498db;
  background: #f1f3f9;
  /* background: #eee; */
  text-align: center;
  pointer-events: none;
  z-index: 12;
}
.imgInput {
  width: 80px;
  height: 36px;
}
.imgInput >>> .my_datum_picture_text {
  border-bottom: inherit !important;
}
.imgInput >>> img {
  opacity: 0;
}
.imgInput >>> .my_datum_picture_portrait {
  background: inherit !important;
}
.area_announ_box .list_img {
  width: inherit !important;
  height: inherit !important;
}
.area_announ_box .list_box {
  width: inherit !important;
  height: inherit !important;
}
.deleteCoverButtom {
  position: absolute;
  top: 30px;
  right: 15px;
  pointer-events: auto;
  z-index: 10;
  cursor: pointer;
}
.deleteCoverButtom:hover {
  z-index: 32;
}
.deleteCoverView {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 10;
  cursor: pointer;
}
.saa .list_box {
  height: inherit;
}
.announce {
  z-index: 20;
  pointer-events: auto;
  cursor: pointer;
}
.announce:hover {
  z-index: 0 !important;
}
.announce .list_img {
  pointer-events: none;
}
</style>
