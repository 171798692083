<template>
	<div class="a_cycle_lesson_has_been_cycle_lesson_time_view" :class="{lock_icon:dataInfo.TeachingPlanLock}" @click.stop="clickItem">
			<span>{{vClassTime}} {{dataInfo.WeekKeyValue}}</span>
			<span v-if="dataInfo.TeachingPlanKey && dataInfo.TeachingPlanKey > 0 &&  dataInfo.CoursewarePackageKey &&  dataInfo.CoursewarePackageKey > 0" class="a_cycle_lesson_has_been_cycle_lesson_time_view_image">
				
			</span>
		</div>
	
</template>

<script>
	export default {
		data() {
			return {}
		},
		props:{
			dataInfo:Object
		},
		computed:{
			vClassTime(){
				if(this.dataInfo.DateTime){
					let date = this.dataInfo.DateTime.split(' ')[0].split('-');
					return date[1] + '-' + date[2]
				}else {
					return ''
				}
				
			}
		},
		methods:{
			clickItem(e){
				this.$emit('clickItem',e);
			}
		}
	}
</script>

<style>
</style>
