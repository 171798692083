<template>
  <div class="left_list"
       @click.stop="clickCourse"
       :class="{opt:courseItem.isActive,disable:courseItem.StateKey==3}">
    <div class="left_list_title"
         :class="{new_font_small:(courseItem.MainDemoName &&courseItem.MainDemoName.length>10)}">{{courseItem.MainDemoName}}</div>
    <div class="left_list_content clearfix">
      <div class="left_list_content_data">
        <span class="font_gray">在读学生</span>
        <span>{{$utils.mAmountType(courseItem.Total_Student,0)}}</span>
        <span class="font_gray">人</span>
        <span class=" font_gray"> | </span>
        <span class="font_gray">当前报课</span>
        <span>{{$utils.mAmountType(courseItem.Sum_CurrentCount,1)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },
  props: {
    courseItem: Object
  },
  methods: {
    clickCourse () {
      this.$set(this.courseItem, 'isActive', true);
      this.$emit('clickCourse', this.courseItem);
    }
  }
};
</script>
<style>
</style>