<template>
  <!-- 通用列表 -->
  <div>
    <span class="defult_dialog_header_btn fr"
          v-if="isShowExportBtn">
      <zx-Import-button @click="doExportInfoClick()"
                        :isSingleSubmit="true"
                        :btnClass="'btn_fixed_Import'"
                        :singleSubmitType="'static'"
                        style="margin-left: 22px;"
                        :btnText="'导出'"></zx-Import-button>
    </span>
    <span class="defult_dialog_header_btn fr"
          v-if="isShowSuportExportBtn">
      <zx-Import-button @click="doSuportExportInfoClick"
                        :isSingleSubmit="true"
                        :btnClass="'btn_fixed_Import'"
                        :singleSubmitType="'static'"
                        style="margin-left: 22px;"
                        :btnText="'高级导出'"></zx-Import-button>
    </span>

    <div class="table_list_content">
      <div class="single_result_detail">
        <!-- 搜索栏 -->
        <div class="table_searchBar detail_info_searchBar single_shadow"
             v-if="vIsSearchText">
          <!-- 搜索条件(单行、多行) -->
          <div class="table_searchBar_inputList">
            <!-- 普通搜索 -->
            <div class="detail_info_searchInput">
              <input type="text"
                     placeholder="请输入姓名、姓名首字母、手机号"
                     class
                     v-model="searchText">
            </div>
          </div>
          <!-- 展开、收起 -->
          <div class="expend_btn"
               v-if="extendSearchCondition.length>0">
            <span class="expend_down"
                  v-if="!isShowMoreSearchCondition"
                  @click.stop="showMoreSearchCondition">展开</span>
            <span class="expend_up"
                  v-if="isShowMoreSearchCondition"
                  @click.stop="hideMoreSearchCondition">收起</span>
          </div>
          <!-- 搜索按钮 -->
          <div class=" search_btn ">
            <el-button class="btn_light_blue btn_hover_bg_blue"
                       @click.stop="searchBtn">搜索</el-button>
            <el-button class="btn_light_blue btn_hover_bg_blue el-icon-delete"
                       v-if="btnDelete"
                       @click.stop="btnDeleteList(1)">回收站 : {{hiddenNumber}}</el-button>
          </div>
          <!-- 按钮组 （自定义多个 eg:新增 导入 导出 批量 ？？以对象接受 还是 多个值控制）-->
          <div class="search_btn_group  search_btn  fr"
               v-if="functionBtnGroup">
            <el-button class="btn_light_blue btn_hover_bg_blue"
                       v-for="(item,index) in functionBtnGroup"
                       :style="item.icon?'':'padding-left:0px'"
                       :key="index"
                       :class="[vHasModulePower(item.ModulePowerKey)?'':'norole',item.icon]"
                       @click.stop="actionBtnClick(item)">{{item.name}}</el-button>
          </div>
          <!-- 展开、收起内容 里面有多个查询条件 日期 下拉列表 数字范围 -->
          <div v-if="isShowMoreSearchCondition"
               class="add_screening_condition clearfix">
            <extend-search-condition-group ref="searchConditionGroup"
                                           :extendSearchCondition="extendSearchCondition"
                                           @getSelectedParamsByClickSearchBtn="getSelectedParamsByClickSearchBtn"></extend-search-condition-group>
          </div>
          <div v-if="isShowHeaderFilter">
            <el-button class="btn_light_blue btn_hover_bg_blue"
                       @click.stop="showHeaderFilter">列表设置</el-button>
          </div>
        </div>
        <!-- 表格 -->
        <div class=" single_shadow"
             :class="{table_content:vIsSearchText}">
          <table-list class="summarizing_list table_list_content"
                      :tableData="vtableData"
                      :tableColumns="tableColumns"
                      :queryParams="queryParamsObj"
                      :totalNum="totalNum"
                      :tableKey="tableKey"
                      :isShowHeaderFilter="isShowHeaderFilter"
                      :class="'pr'"
                      @loadTableData="loadTableData"></table-list>
        </div>
      </div>
    </div>

    <!-- <custom-dialog title="列表设置"
                   width="410px"
                   :visible.sync="isShowHeaderFilterDialog"
                   :before-close="closeHeaderFilter">
      <column-header-filtering :tableColumns="fieldList"
                               :defualtColumns="tableColumns"
                               @close="closeHeaderFilter"
                               @afterSuccess="headerFilterAfterSuccess"></column-header-filtering>
    </custom-dialog> -->
  </div>
</template>
<script>
import columnHeaderFiltering from './column-header-filtering/index.vue';
import zxImportButton from '../common/zx-Import-button';
import extendSearchConditionGroup from './extend-search-condition-group/index';
import selectedConditionsGroup from './selected-conditions-group/index';
import tableList from '../common/table-list/index.vue';
import { EditAgentSetting } from '../../API/workbench';
export default {
  data () {
    return {
      searchText: '',
      queryParamsObj: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: ''
      },
      isShowMoreSearchCondition: false, // 是否显示更多查询条件
      selectedRow: {}, // 选中行
      isLoading: true, // 正在加载
      selectedParams: [], // 扩展查询参数 [{name:'参数名',value:'值'}]
      searchConditionType: {}, // 扩展查询条件类型
      expandRowKeys: [], // 展开的行,
      isShowHeaderFilterDialog: false,
      isShowInnerScroll: true,
      fieldList: []
    };
  },
  props: {
    // queryParamsObjForList: Object
    queryParams: {
      type: Object,
      default: () => {
        return {
          pageIndex: 0,
          pageSize: 10,
          orderBy: '',
          sequence: '',
          searchText: ''
        };
      }
    },
    tableKey: {
      type: String,
      default: ''
    },
    extendSearchCondition: {
      type: Array,
      default: function () {
        return [
          {
            type: 0, // 控件类型（js->initJson->extendSearchConditionType{ "dateRange": 1 //日期范围,"selectOption": 2//下拉选择,"numberRange": 3//数字范围}）
            formTitle: '', // 标题名
            fieldList: [
              {
                name: '', // 参数名
                defaultValue: '', // 默认值
                value: '', // 绑定值
                validateHandle: '', // 自定义验证函数
                sourceData: [] // 数据源（下拉选择需要 默认为空数组）
              }
            ]
          }
        ];
      }
    }, // 扩展查询条件
    functionBtnGroup: Array, // 功能按钮组
    tableData: Array, // 列表数据
    detailColumns: Array, // 显示详情内容
    rowKey: String, // 显示详情对应绑定的ID
    totalNum: Number, // 列表总共条数
    PageCount: Number, // 共几页
    tableColumns: Array, // table column
    btnDelete: false, // 回收站显示隐藏
    hiddenNumber: 0, // 回收站总数据
    isSearchText: Number, // 是否显示所有查询条件，包括搜索框
    top: 0,
    isShowHeaderFilter: {
      type: Boolean,
      default: false
    },
    summary: {
      type: Object,
      default: function () {
        return {
          isShowSummary: false,
          needSummariesColumns: [],
          summaryName: '合计',
          summaryData: []
        };
      }
    },
    isNeedInnerScroll: {
      type: Boolean,
      default: false
    },
    isShowExportBtn: {
      type: Boolean,
      default: false
    },
    isShowSuportExportBtn: {
      type: Boolean,
      default: false
    }

  },
  components: {
    extendSearchConditionGroup,
    selectedConditionsGroup,
    zxImportButton,
    tableList,
    columnHeaderFiltering

  },
  computed: {
    vtableData () {
      return this.tableData;
    },
    vTableColumns () {
      let columns = this.fieldList.filter(obj => { return obj.isShow == undefined || obj.isShow; });
      return columns;
    },
    modulePowerList () {
      return this.$store.getters.RolePowerList;
    },
    vIsSearchText () {
      if (this.isSearchText && this.isSearchText == -1) {
        return false;
      } else {
        return true;
      }
    },
    vPageIndex: {
      get () {
        return this.queryParamsObj.pageIndex + 1;
      },
      set (n, o) {
      }
    }
    // vTableColumns () {
    //   let newTableColumns = [];
    //   this.tableColumns.forEach(element => {
    //     if (element.isShow) {
    //       newTableColumns.push(element);
    //     }
    //   });
    //   return newTableColumns;
    // }
  },
  created () {
    this.$nextTick(() => {
      this.searchConditionType = this.$initJson.extendSearchConditionType;
      this.initqueryParamsObj();
      // this.fieldList = this.isShowHeaderFilter ? this.fieldList = Object.assign([], this.getTableAgentSetting()) : Object.assign([], this.tableColumns); // 用于编辑显示数据
    });
  },
  mounted () {
    if (this.isNeedInnerScroll) {
      setTimeout(() => {
        this.caculInnerScroll();
      }, 600);
    }
  },
  watch: {
    tableData () {
      console.log('tableData', this.tableData);
    }
  },
  methods: {
    getTableAgentSetting () {
      let arr = JSON.parse(localStorage.getItem('AgentSettingInfor'));
      let tempArr = [];
      arr.forEach(item => {
        if (item.SetKey == this.tableKey) {
          tempArr = item.SetValue;
        }
      });
      let columnArr = this.getLocalDataFunc(JSON.parse(tempArr), this.tableColumns, 1);
      return columnArr.length > 0 ? columnArr : this.tableColumns;
    },
    getLocalDataFunc (tempArr, tableColumns, type) {
      if (typeof tempArr == 'string') {
        tempArr = JSON.parse(tempArr);
      }
      let columnArr = [];
      console.log('tempArr', tempArr, tableColumns);
      if (tempArr.length > 0) {
        let btempArr = [];
        tempArr.forEach(item => {
          btempArr.push(item.label);
        });
        console.log('tempArr', btempArr);
        tableColumns.forEach(item => {
          let aindex = tempArr.findIndex(i => {
            return type == 1 ? i.label == item.label : i.label == item.title;
          });
          if (aindex > -1 && tempArr[aindex].isShow) {
            item.width = tempArr[aindex].width;
            // item.isShow = tempArr[aindex].isShow;
            columnArr.push(item);
          }
        });

        // 更新表格展示列
        columnArr.sort((a, b) => {
          const aKey = type == 1 ? a.label : a.title;
          const bKey = type == 1 ? b.label : b.title;
          console.log('aKey', aKey, bKey, Object.keys(a));
          if (btempArr.indexOf(aKey) == -1) {
            return false;
          }
          if (btempArr.indexOf(bKey) == -1) {
            return true;
          }
          return btempArr.indexOf(aKey) - btempArr.indexOf(bKey);
        });
      }
      console.log('columnArr', columnArr);
      return columnArr;
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      ModulePowerKey = ModulePowerKey || '';
      return this.$utils.CheckModulePower(this.modulePowerList, ModulePowerKey);
    },
    // 首次打开 初始化查询参数（对外开放）
    initSelectedParams (selectedParams) {
      this.getSelectedParamsByClickSearchBtn(selectedParams);
    },
    // 构建查询参数
    initqueryParamsObj () {
      this.queryParamsObj = this.$utils.parseJson(this.queryParams);
      this.extendSearchCondition.forEach(condition => {
        condition.fieldList.forEach((param, index) => {
          this.queryParamsObj[param.name] = param.defaultValue;
        });
      });
    },
    // 点击查询按钮
    searchBtn () {
      this.searchText = this.searchText.replace(/ /g, '');
      // 若更多查询条件 显示 重新收集已选参数
      if (this.isShowMoreSearchCondition) {
        this.$refs.searchConditionGroup.getSelectedParams(this.selectedParams);
        // 反之 直接查询
      } else {
        this.queryParamsObj.pageIndex = 0;
        this.queryParamsObj.searchText = this.searchText;
        this.$emit('loadTableData', this.queryParamsObj);
      }
      // this.$refs.multipleTable.setCurrentRow();
    },
    // 点击查询后 获取已选参数条件 并且拼接查询参数 获取列表
    getSelectedParamsByClickSearchBtn (selectedParams) {
      this.initqueryParamsObj();
      this.selectedParams = selectedParams;
      for (var index in selectedParams) {
        this.queryParamsObj[selectedParams[index].name] =
          selectedParams[index].value;
      }
      this.isShowMoreSearchCondition = false;
      if (this.$refs.multipleTable) {
        // this.$refs.multipleTable.setCurrentRow();
      }
      this.queryParamsObj.searchText = this.searchText;
      this.$emit('loadTableData', this.queryParamsObj);
    },
    // 显示更多查询条件
    showMoreSearchCondition () {
      this.isShowMoreSearchCondition = true;
      this.$nextTick(() => {
        this.$refs.searchConditionGroup.showExtendSearchCondition(
          this.selectedParams
        );
      });
    },
    // 跳转到第几页
    handleCurrentChange (val) {
      this.selectedRow = {};
      // this.$refs.multipleTable.setCurrentRow();
      this.queryParamsObj.pageIndex = val - 1;
      this.$emit('loadTableData', this.queryParamsObj);
    },
    // 保留小数位数
    rounding (row, value, decimalPlace) {
      if (!row[value]) {
        return '';
      } else {
        return Number(row[value]).toFixed(decimalPlace);
      }
    },
    // 排序
    sortChange (column, prop) {
      if (column.prop) {
        this.queryParamsObj.orderBy = column.prop;
        this.queryParamsObj.sequence = column.order == 'ascending' ? 'asc' : 'desc';
      }
      this.$emit('loadTableData', this.queryParamsObj);
    },
    // 隐藏更多查询条件
    hideMoreSearchCondition () {
      this.isShowMoreSearchCondition = false;
    },
    // 删除某个已选条件 重构queryParamsObj重新刷新
    doAfterChangeExtendSearchParams () {
      this.getSelectedParamsByClickSearchBtn(this.selectedParams);
    },
    // 清除已选条件栏  需重新刷新
    clearExtendSearchParams () {
      this.selectedParams = [];

      this.getSelectedParamsByClickSearchBtn(this.selectedParams);
    },
    // 点击功能按钮
    actionBtnClick (item) {
      if (this.vHasModulePower(item.ModulePowerKey)) {
        this.$emit('actionBtnClick', item.eventName);
      } else {
        this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
      }
    },
    // 点击表单按钮
    tableBtnClick (rowData, item) {
      console.log(item, 'tableBtnClick');
      if (!rowData.StudentKey && !rowData.OLAPKey) {
        return false;
      }
      if (this.vHasModulePower(item.ModulePowerKey)) {
        if (item.eventName == this.$initJson.baseFunctionBtnName.detail) {
          if (rowData[this.rowKey] == this.expandRowKeys[0]) {
            this.expandRowKeys = [];
          } else {
            this.expandRowKeys = [];
            this.expandRowKeys.push(rowData[this.rowKey]);
          }
        } else {
          if (this.vHasModulePower(item.ModulePowerKey)) {
            this.$emit('tableBtnClick', rowData, item.eventName);
          } else {
            this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
          }
        }
      } else {
        this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
      }
    },
    // 选中行
    clickRow (row, column, event) {
      this.selectedRow = row;
      // this.$refs.multipleTable.clearSelection();
      // this.$refs.multipleTable.toggleRowSelection(row, true);
    },
    // 回收站数据切换
    btnDeleteList (IsHiddenKey) {
      let HiddenKey = true;
      this.selectedParams.forEach(e => {
        if (e.name == 'IsHiddenKey' && e.value == 1) {
          HiddenKey = false;
        }
      });
      if (HiddenKey) {
        this.selectedParams = [];
        this.selectedParams.push({ name: 'IsHiddenKey', value: 1 });

        this.getSelectedParamsByClickSearchBtn(this.selectedParams);
      }
    },
    // 判定显示按钮名
    ifBtnName (data) {
      if (data.IsHiddenKey == 1) {
        return '恢复';
      } else {
        return '隐藏';
      }
    },
    getSummaries (param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.summary.summaryName;
          return;
        }
        let findItem = this.summary.needSummariesColumns.find(o => {
          return o.prop == column.property;
        });
        if (findItem) {
          // 非数字类型 一般统计个数
          if (findItem.SumType == 'string') {
            sums[index] = this.summary.summaryData.length + findItem.unit;
          } else {
            const values = this.summary.summaryData.map(item => Number(item[column.property]));
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += findItem.unit;
            }
          }
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
    caculInnerScroll () {
      // table的宽度
      let tableWidth = document.getElementsByClassName('el-table table_list')[0] ? document.getElementsByClassName('el-table table_list')[0].clientWidth : 0;
      // table内容的宽度
      let contentWidth = document.getElementsByClassName('el-table__body')[0] ? document.getElementsByClassName('el-table__body')[0].clientWidth : 0;
      // table 左边固定宽
      let leftFix = document.getElementsByClassName('el-table__fixed')[0] ? document.getElementsByClassName('el-table__fixed')[0].clientWidth : 0;
      // table 右边固定宽
      let rightFix = document.getElementsByClassName('el-table__fixed-right')[0] ? document.getElementsByClassName('el-table__fixed-right')[0].clientWidth : 0;
      // 合计的高度
      let countColumnHeight = document.getElementsByClassName('el-table__fixed-footer-wrapper')[0] ? document.getElementsByClassName('el-table__fixed-footer-wrapper')[0].clientHeight : 0;

      if (tableWidth >= contentWidth) {
        this.isShowInnerScroll = false;
      } else {
        this.isShowInnerScroll = true;
        if (document.getElementsByClassName('el-table__body-wrapper')[0]) {
          document.getElementsByClassName('el-table__body-wrapper')[0].style.overflowX = 'hidden';
        }
        this.$nextTick(() => {
          document.getElementsByClassName('scroll_content')[0].style.width = contentWidth + 'px';

          document.getElementsByClassName('float-scroll')[0].style.width = (tableWidth - leftFix - rightFix) + 'px';
          document.getElementsByClassName('float-scroll')[0].style.marginLeft = leftFix + 'px';
          document.getElementsByClassName('float-scroll')[0].style.marginTop = -countColumnHeight + 'px';
        });
      }
    },
    scrollEvent (e) {
      let ETarget = e.target;
      // table的宽度
      let tableWidth = document.getElementsByClassName('el-table table_list')[0].clientWidth;
      // table内容的宽度
      let contentWidth = document.getElementsByClassName('el-table__body')[0].clientWidth;
      // 左边固定宽
      let leftFix = document.getElementsByClassName('el-table__fixed')[0].clientWidth;
      // 右边固定宽
      let rightFix = document.getElementsByClassName('el-table__fixed-right')[0].clientWidth;
      // 滚动条外框的宽度
      document.getElementsByClassName('float-scroll')[0].style.width = (tableWidth - leftFix - rightFix) + 'px';

      document.getElementsByClassName('el-table__body-wrapper')[0].scrollLeft = (ETarget.scrollLeft / (contentWidth / (contentWidth - leftFix - rightFix))) - 120;
    },
    // 日期转换
    convertDateformat (row, value, type) {
      if (type == 'YYMMDD') {
        return this.$utils.formatDateToLine(Date.new(row[value]));
      } else {
        return row[value];
      }
    },
    // 导出
    doExportInfoClick () {
      this.$emit('doExportInfoClick');
    },
    showHeaderFilter () {
      this.isShowHeaderFilterDialog = true;
    },
    closeHeaderFilter () {
      this.isShowHeaderFilterDialog = false;
    },
    loadTableData (data) {
      this.$emit('loadTableData', data);
    },
    headerFilterAfterSuccess (data) {
      this.fieldList = [];
      let param = {
        SetKey: this.tableKey,
        SetValue: JSON.stringify(data)
      };
      let arr = JSON.parse(localStorage.getItem('AgentSettingInfor'));
      let isHas = false;
      arr.forEach(item => {
        if (item.SetKey == this.tableKey) {
          item.SetValue = param.SetValue;
          isHas = true;
        }
      });
      if (!isHas) {
        arr.push({
          SetKey: this.tableKey,
          SetValue: param.SetValue
        });
      }
      // 更新数据到本地缓存
      localStorage.setItem('AgentSettingInfor', JSON.stringify(arr));
      // 更新数据到数据库
      EditAgentSetting(param).then(res => {
        console.log('res', res);
      });
      setTimeout(() => {
        this.fieldList = Object.assign([], this.getLocalDataFunc(data, this.tableColumns, 1));
        this.$forceUpdate();
      }, 200);
    },
    doSuportExportInfoClick () {
      let defaultExportList = Object.assign([], this.$initJson.defaultExportList);
      console.log('defaultExportList', defaultExportList);
      // let tableExportList = defaultExportList.filter(item => { return item.tableKey == this.tableKey; });
      let tableExportList = [];
      defaultExportList.forEach(item => {
        if (item.tableKey == this.tableKey) {
          tableExportList = Object.assign([], item);
        };
      });
      // // if (tableExportList.length > 0) {
      // //   tableExportList = tableExportList[0];
      // // }
      let exportDefaultContent = tableExportList.exportDefaultContent;
      let arr = JSON.parse(localStorage.getItem('AgentSettingInfor'));
      let tempArr = [];
      arr.forEach(item => {
        if (item.SetKey == this.tableKey) {
          tempArr = item.SetValue;
        }
      });
      console.log('exportDefaultContent', arr, tempArr);
      let arrd = this.getLocalDataFunc(tempArr, exportDefaultContent.clist, 2);
      console.log('tableExportList', arrd, tempArr);
      exportDefaultContent.clist = Object.assign([], arrd);
      this.$emit('doSuportExportInfoClick', this.queryParamsObj, exportDefaultContent);
    }
  }
};
</script>
<style  scoped>
.detail_info_searchBar {
  z-index: 1;
}
.detail_info_searchBar .search_btn {
  line-height: inherit;
}
</style>

