<template>
  <div @click.stop="clickItem"
       :class="[vPowerCss,className]"
       class="icon_btn">
    <slot></slot>
    {{item?item.name:''}}
  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },
  props: {
    item: Object,
    className: {
      type: String,
      default: ''
    }
  },
  created () {
  },
  computed: {
    vHasModulePower () {
      let flag = true;
      if (this.item && this.item.ModulePowerKey) {
        flag = this.$utils.CheckModulePower(this.$store.getters.RolePowerList, this.item.ModulePowerKey);
      }
      return flag;
    },
    vPowerCss () {
      // 有权限
      if (this.vHasModulePower) {
        return '';
      } else {
        return 'norole';
      }
    }
  },
  methods: {
    clickItem (e) {
      // 有权限
      if (this.vHasModulePower) {
        this.$emit('clickItem', this.item, e);
        // 无权限
      } else {
        this.$utils.CheckModulePowerToTips(this.item.ModulePowerKey);
      }
    }
  }
};
</script>

<style>
</style>