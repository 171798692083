  <template>
  <!--  开新班 -->
  <div>
    <custom-dialog title="开设新班"
                   class="new_class defult_dialog"
                   :visible.sync="vNewClass"
                   :before-close="AddgradeClassCancleBtn">
      <div class="form_info_detail form_info_canedit">
        <div class="content_marginTop_30 content_marginRL">
          <div class="form_info_edit">
            <div class="form_info_line">
              <div class="form_info_field form_info_required_kaiban">
                <span>班名</span>
              </div>
              <div class="form_info_value">
                <input type="text"
                       placeholder="必选"
                       value
                       v-model="newClassName" />
                <span class="edit_btn edit_btn_change"
                      style="display:none;">
                  <span>修改</span>
                </span>
              </div>
            </div>
          </div>
          <div class="form_info_edit">
            <div class="form_info_line">
              <div class="form_info_field">
                <span>计划招生数</span>
              </div>

              <div class="form_info_value">
                <input type="number"
                       placeholder="请填写计划招生数,可统计新班的招生率"
                       value
                       v-model="newClassPersonNumber"
                       onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace('.', '$#$').replace(/\./g, '')" />
                <span class="edit_btn edit_btn_change"
                      style="display:none;">
                  <span>修改</span>
                </span>
              </div>
            </div>
          </div>
          <!-- 提交按钮组 -->
          <save-cancel-btn-group :isSingleSubmit="true"
                                 :singleSubmitType="'dynamic'"
                                 @confirmClick="AddgradeClassConfirmBtn"
                                 @cancelClick="AddgradeClassCancleBtn"></save-cancel-btn-group>
        </div>
      </div>
    </custom-dialog>
  </div>
</template>
<script>
import inputNumber from '../common/input-number';
import { GradeClassAdd } from '../../API/workbench.js';
export default {
  name: 'newClass',
  props: {
    isNewClass: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      newClassName: '',
      newClassPersonNumber: ' ',
      vNewClass: false // 开新班
    };
  },
  components: {
    inputNumber
  },
  created () {
    // 兼容外部调用该模块
    if (this.isNewClass && this.isNewClass.newClass) {
      this.vNewClass = this.isNewClass.newClass;
    } else {
      this.vNewClass = this.isNewClass;
    }
  },
  watch: {
    isNewClass () {
      this.vNewClass = this.isNewClass;
      if (this.isNewClass.newClass) {
        this.vNewClass = this.isNewClass.newClass;
      } else {
        this.vNewClass = this.isNewClass;
      }
      if (this.vNewClass == true) {
        this.newClassName = '';
        this.newClassPersonNumber = '';
      }
    }
  },
  methods: {
    // 新增班级确定
    AddgradeClassConfirmBtn (callBack) {
      if (this.newClassPersonNumber) {
        this.newClassPersonNumber = Number(this.newClassPersonNumber).toFixed(
          0
        );
      }
      if (this.newClassName == '') {
        if (callBack) {
          callBack();
        }
        layer.alert('班名不能为空');
        return;
      }
      const that = this;
      if (this.isNewClass && this.isNewClass.newClassValue == '开新班') {
        if (callBack) {
          callBack();
        }
        this.$emit('newClass', this.newClassName, this.newClassPersonNumber);
      } else {
        GradeClassAdd(this.newClassName, this.newClassPersonNumber).then(
            result => {
              that.vNewClass = false;
              this.$parent.isNewClass = false;
              layer.alert('开班成功');
              this.$emit('gradeClassAddSuccess', result.data);
              this.newClassName = '';
              this.newClassPersonNumber = '';
            },
            error => {
              layer.alert(error.msg);
            }
          )
          .finally(() => {
            if (callBack) {
              callBack();
            }
          });
      }
    },
    // 新增班级取消
    AddgradeClassCancleBtn () {
      this.newClassName = '';
      this.newClassPersonNumber = '';
      this.vNewClass = false;
      this.$parent.isNewClass = false;
      this.$emit('newClass');
    }
  }
};
</script>
 
