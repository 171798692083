<template>
  <div class="flex_row button quick_options_item mover"
       :class="showDel?'delshow':''"
       @click.stop="clickItem"
       @mouseenter="mouseOverNewCourseTable($event)"
       @mouseleave="mouseOutNewCourseTable($event)">
    <div class="quick_options_item_img">
      <!--  :src="../../../../public/image/homepage_opitions.png" -->
      <img :src="headImg"
           :onerror="defaultImg"
           alt="" />
    </div>
    <!-- <div v-if="showDel" class="delshow"></div> -->
    {{item.name}}
    <pop-up-drop-down-box :isShowDialog="isShowAlertDialog"
                          :dropDownList="popUpList"
                          :positionLeft="positionLeft"
                          :positionTop="positionTop"
                          @onClick="doAfterSelectedNewCourseTableType"
                          @mouseenter="mouseOverNewCourseTable({ isShowDropDownBox: true })"
                          @mouseleave="mouseOutNewCourseTable({ isShowDropDownBox: true })"></pop-up-drop-down-box>
  </div>
</template>
<script>
import popUpDropDownBox from '../../common/pop-up-drop-down-box';
export default {
  data () {
    return {
      isShowAlertDialog: false,
      positionTop: 45,
      positionLeft: 0,
      popUpList: [],
      coursePopUpList: [
        {
          id: '3',
          name: '日历建课',
          ModulePowerKey: 47,
          isShow: true
        },
        {
          id: '2',
          name: '新建循环课',
          ModulePowerKey: 47,
          isShow: true
        }
      ],
      studentPopUpList: [
        {
          id: '1',
          name: '学生请假',
          moduleName: 'studentLeave',
          ModulePowerKey: 51,
          isShow: true
        },
        {
          id: '1',
          name: '改手机号',
          moduleName: 'changephonenum',
          ModulePowerKey: 48,
          isShow: true
        },
        {
          id: '1',
          name: '修改课单',
          moduleName: 'changeCourseBill',
          ModulePowerKey: 52,
          isShow: true
        },
        // {
        //   id: '1',
        //   name: '修改考勤',
        //   moduleName: 'changeAttendance',
        //   ModulePowerKey: 51,
        //   isShow: true
        // },
        {
          id: '1',
          name: '学生换班',
          moduleName: 'studentchangeclass',
          ModulePowerKey: 51,
          isShow: true
        },
        {
          id: '1',
          name: '转让课单',
          moduleName: 'transfercoursebill',
          ModulePowerKey: 52,
          isShow: true
        },
        {
          id: '1',
          name: '课时退款',
          moduleName: 'refundmoneybackcourse',
          ModulePowerKey: 52,
          isShow: true
        },
        {
          id: '1',
          name: '合并学生',
          moduleName: 'mergeStudent',
          ModulePowerKey: 48,
          isShow: true
        }
      ],
      schedulePopUpList: [
        {
          id: '4',
          name: '课程排课',
          routertitle: '课程排课',
          routerName: 'courseNameApplyCourseReport',
          ModulePowerKey: 53,
          isShow: true
        },
        {
          id: '4',
          name: '班级排课',
          routertitle: '班级排课',
          routerName: 'classGradeApplycourse',
          ModulePowerKey: 53,
          isShow: true
        },
        {
          id: '4',
          name: '套餐排课',
          routertitle: '套餐排课',
          routerName: 'packageApplyCourseReport',
          ModulePowerKey: 53,
          isShow: true
        }
      ],
    };
  },
  props: {
    item: Object,
    showDel: Boolean
  },
  components: {
    popUpDropDownBox
  },
  created () {
    console.log("quick-options-item", this.item)
  },
  computed: {
    headImg () {
      if (!this.item.img_type) {
        return ''
      } else {
        return (require('../../../../public/image/img_type' + this.item.img_type + '.png'));
        // return '../../../../public/image/img_type'+this.item.img_type+'.png'
      }
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../public/image/homepage_opitions.png') + '"';
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    },
  },
  methods: {
    // 新建课表 选中课类型（多课 单课）
    doAfterSelectedNewCourseTableType (item) {
      if (item.id == '1') {
        document.body.style.overflow = 'auto';
        let moduleInfo = {
          routerName: 'indexView',
          routertitle: '工作台',
          moduleName: item.moduleName
        };
        this.$bus.emit('needOpenRouter', moduleInfo);
      } else if (item.id == '4') {
        let dataInfo = this.$utils.parseJson(item);
        dataInfo.isShowDropDownBox = false;
        this.$emit("clickItem", dataInfo)
      } else {
        document.body.style.overflow = 'auto';
        let moduleInfo = {
          routerName: 'CourseTable',
          routertitle: '课程表',
          moduleName:
            item.id == 2
              ? this.$initJson.allCustomerDialogNames.newCircleCourseTable
                .moduleName
              : this.$initJson.allCustomerDialogNames.newCourseByCalendar
                .moduleName
        };
        this.$bus.emit('needOpenRouter', moduleInfo);
      }

      this.isShowAlertDialog = false;
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(
        this.vModulePowerList,
        ModulePowerKey
      );
    },
    // 鼠标移到新建课
    mouseOverNewCourseTable (e) {
      if (this.item.isShowDropDownBox) {
        if (this.item.moduleName == 'newCircleCourseTable') {
          if (!this.vHasModulePower(47)) {
            return false;
          }
          this.popUpList = this.coursePopUpList;
        }
        if (this.item.moduleName == 'studentAbnormalMenu') {
          this.popUpList = this.studentPopUpList;
        }
        if (this.item.moduleName == 'schedulePopupList') {
          this.popUpList = this.schedulePopUpList;
        }
        clearTimeout(this.timer);
        // if (e) {
        //   let Rect = e.target.getBoundingClientRect();
        //   let scrollTop = document.documentElement.scrollTop;
        //   this.positionTop = Rect.top + Rect.height + scrollTop;
        //   this.positionLeft = Rect.left - 5;
        // }
        this.isShowAlertDialog = true;
      }
    },
    // 鼠标离开新建课
    mouseOutNewCourseTable () {
      if (this.item.isShowDropDownBox) {
        this.timer = setTimeout(() => {
          this.isShowAlertDialog = false;
        }, 100);
      }
    },
    clickItem () {
      this.$emit("clickItem", this.item)
    }
  }
};
</script>
<style scoped>
.quick_options_item {
  width: 140px;
  height: 52px;
  border-radius: 8px;
  border: 1px solid rgb(255 255 255 / 0);
  margin-right: 23px;
  justify-content: center;
  padding-left: 50px;
  margin-bottom: 15px;
  position: relative;
  text-align: left;
  justify-content: left;
  /* width: 18%;
    height: 52px;
    border-radius: 8px;
    border: 1px solid rgb(255 255 255 / 0);
    margin-right: 2%;
    justify-content: center;
    padding-left: 6.2%;
    margin-bottom: 15px;
    position: relative;
    text-align: left;
    justify-content: left; */
}
.quick_options_item:hover {
  border-radius: 8px;
  border: 1px solid #2d98d9;
  box-shadow: 0px 1px 3px 0px #78859233;
}
.quick_options_item.delshow {
  margin-right: 20px;
}
.delshow:hover::before {
  position: absolute;
  display: block;
  content: '';
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  background: url('../../../../public/image/quick_cost.png') center no-repeat;
  background-size: 19px;
}
.quick_options_item_img {
  position: absolute;
  left: 5px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
