<template>
  <div class="WKT_course_info_popup"
       style="min-height:359px">
    <div class="from_input_list">
      <div class="from_input_title">
        关键词
      </div>
      <div class="from_input_box">
        <input type="text"
               v-model="dataInfo.messagekey"
               placeholder="请输入关键词">
      </div>
    </div>
    <div class="from_textarea_box">
      <div class="from_textarea_title">
        回复内容
      </div>
      <div class="from_textarea_content">
        <textarea v-model="dataInfo.content"
                  placeholder="请输入回复内容"
                  maxlength="100"></textarea>
        <div class="from_textarea_content_btn"
             @click.stop="dataInfo.content=''">
        </div>
      </div>
    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                           @confirmClick="submit"
                           @cancelClick="doAfterClickCancelBtn"></save-cancel-btn-group>
    <!-- <common-button-group @buttonClickEvent="submit"
                         @closeDialog="doAfterClickCancelBtn"></common-button-group> -->
  </div>
</template>
<script>
import { EditDefaultReply, AddDefaultReply } from '../../../../../../API/workbench';
export default {
  data () {
    return {
      dataInfo: {
        content: '',
        messagekey: '',
        id: 0
      }
    };
  },
  props: {
    seletedItem: Object
  },
  created () {
    if (this.seletedItem) {
      this.dataInfo = this.$utils.parseJson(this.seletedItem);
    }
  },
  methods: {
    submit (callback) {
      if (this.dataInfo.content == '') {
        layer.alert('请输入回复内容');
        if (callback) {
          callback();
        }
        return false;
      } else if (this.dataInfo.messagekey == '') {
        layer.alert('请输入关键词');
        if (callback) {
          callback();
        }
        return false;
      }

      if (this.dataInfo.id > 0) {   // 修改
        EditDefaultReply(this.dataInfo).then(result => {
          layer.alert('修改成功');
          this.$emit('afterSuccess');
          if (callback) {
            callback();
          }
        }).catch(error => {
          console.log('errEditDefaultReply', error);
        });
      } else {
        AddDefaultReply(this.dataInfo).then(result => {
          layer.alert('新增成功');
          this.$emit('afterSuccess');
          if (callback) {
            callback();
          }
        }).catch(error => {
          console.log('errEditDefaultReply', error);
        });
      }
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    }
  }
};
</script>
