<template>
  <div>
    <list ref="detailList"
          :listTitle="listTitle"
          :extendSearchCondition="vPopupTableParams.extendSearchCondition"
          :functionBtnGroup="functionBtnGroup"
          :tableData="vPopupTableParams.tableData"
          :totalNum="totalNum"
          :PageCount="PageCount"
          :tableColumns="vPopupTableParams.tableColumns"
          :awardText="awardText"
          :titleInfoList="titleInfoList"
          @actionBtnClick="doAfterFunctionBtnClick"
          @tableBtnClick="doAfterTableBtnClick"
          @loadTableData="loadTableData"></list>
  </div>
</template>
<script>
import list from '../activity-list';
import { IntroduceRewardDetail, ParentsPromoteDistributionIncentives, BrowseGiftDistribution } from '../../../API/workbench.js';
let tableFunctionType = { fanxian: 'fanxian', fanhour: 'fanhour', fangift: 'fangift' };// 几个弹出层列表tabFunction 类型的初始化
export default {
  name: 'introDetailList',
  data () {
    return {
      listTitle: '',
      awardText:'',
      itemInfo:{},
      totalNum: 0, // 返回的总记录数
      PageCount: 0, // 页数
      pageSize: 0, // 查询记录数
      titleInfoList:[],
      // 介绍奖励明细列表
      JSJLExtendSearchCondition: [], // 扩展查询参数
      JSJLTableColumn: [// 列
        {
          label: "序号",
          prop: "Number",
          width: 68,
          sortable: false,
          align: "left",
          isShow: true,
          type: "table-index",
          className: "table_index_gray",
        },
        { label: '报名时间', prop: 'add_time', width: 157, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '学生名', prop: 'StudentKeyValue', width: 120, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '报名人', prop: 'username', width: 164, sortable: false, align: 'left', isShow: true, type: '', decimalPlace: 0, icon: 'fa fa-qrcode' },
        { label: '手机号', prop: 'telephone', width: 174, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '是否到店', prop: 'statusValue', width: 116, sortable: false, align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '奖励', prop: 'reward', width: 116, sortable: false, align: 'left', isShow: true, type: 'html', decimalPlace: 0 },
        { label: '兑现状态', prop: 'Stutas', width: 116, sortable: false, align: 'left', isShow: true, type: 'html', decimalPlace: 0 },
        { label: '兑现奖励',
          prop: '',
          sortable: false,
          tableBtnGroup: [
            { name: '发放现金',
              eventName: tableFunctionType.fanxian,
              Stutas: 'fanxian_amount,is_fafang_fanxian',
              className: (value1, value2) => {
                let className = '';
                if (value1 > 0) { // 返现金额》0
                  if (value2 == 0) {
                    className = 'btn_light_blue';
                  } else {
                    className = 'btn_light_gray';
                  }
                } else {
                  className = 'btn_light_hide';
                }
                return className;
              },
              roleName: ''
            },
            { name: '发放课时',
              eventName: tableFunctionType.fanhour,
              Stutas: 'fanhour_num,is_fafang_fanhour',
              className: (value1, value2) => {
                let className = '';
                if (value1 > 0) { // 返现金额》0
                  if (value2 == 0) {
                    className = 'btn_light_blue';
                  } else {
                    className = 'btn_light_gray';
                  }
                } else {
                  className = 'btn_light_hide';
                }
                return className;
              },
              roleName: ''
            },
            { name: '发放礼品',
              eventName: tableFunctionType.fangift,
              Stutas: 'fangift_name,is_fafang_fangift',
              className: (value1, value2) => {
                let className = '';
                if (value1) { // 返现金额》0
                  if (value2 == 0) {
                    className = 'btn_light_blue';
                  } else {
                    className = 'btn_light_gray';
                  }
                } else {
                  className = 'btn_light_hide';
                }
                return className;
              },
              roleName: ''
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        }
      ],
      JSJLTableData: [], // 数据
      functionBtnGroup: [], // 功能按钮
      queryParamsRecord: []  // 查询时的参数
    };
  },
  props: ['selectedCouponInfo'],
  components: {
    list
  },
  computed: {
    vAwardText(){
      if(this.itemInfo.name){
        return this.itemInfo.BMJL;
      }else {
        return '';
      }
    },
    // 弹出层列表参数
    vPopupTableParams () {
      let tableParams = {};
      tableParams.extendSearchCondition = this.JSJLExtendSearchCondition;
      tableParams.tableColumns = this.JSJLTableColumn;
      tableParams.tableData = this.JSJLTableData;
      return tableParams;
    },
    vAward(){
      if(this.vPopupTableParams.tableData[0]){
        if(this.vPopupTableParams.tableData[0].fanxian_amount > 0){
          return this.vPopupTableParams.tableData[0].fanxian_amount + '元';
        }
        if(this.vPopupTableParams.tableData[0].fanhour_num > 0){
          return this.vPopupTableParams.tableData[0].fanhour_num + '课时';
        }
        if(this.vPopupTableParams.tableData[0].fangift_name){
          return this.vPopupTableParams.tableData[0].fangift_name;
        }
      }else  {
        return ''
      }
    }
  },
  methods: {
    // 加载介绍奖励明细
    loadIntroduceRewardDetailList (queryParams, callback) {
      let searchObj = queryParams;
      searchObj.id = this.selectedCouponInfo.id;
      searchObj.clientid = this.itemInfo.uid;
      IntroduceRewardDetail(searchObj).then(result => {
        console.log('loadIntroduceRewardDetailList: result.data', this.$utils.parseJson(result.data));
        this.JSJLTableData = [];
        if (result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach((obj, index) => {
            obj.add_time = obj.add_time.substring(0,16);
            if (obj.username) {
              obj.username = obj.username + '/' + this.$utils.phoneModulePower(obj.telephone);
            }
            if(obj.status == 2){
              obj.statusValue = '是';
            }else {
              obj.statusValue = '否';
            }
            if (obj.strategy_sub_type == 1) { // 1、用券 2、售券 订单金额:payOrderMoney  售券金额:buyCouponMony
              obj.enrollAmount = Number(obj.payOrderMoney).toFixed(2);
            } else if (obj.strategy_sub_type == 2) { // 1、用券 2、售券 订单金额:payOrderMoney  售券金额:buyCouponMony
              obj.enrollAmount = Number(obj.buyCouponMony).toFixed(2);
            }
            if (obj.fanxian_amount > 0) {
              obj.reward = '<div>' + obj.fanxian_amount + '元</div>';
              if (obj.is_fafang_fanxian == 1) {
                if (obj.fanxian_type == 1) { // 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动 ../image/reach.png
                  obj.Stutas = '<div class="reach padding_L">已兑现</div>';
                } else if (obj.fanxian_type == 2) { // 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动
                  obj.Stutas = '<div class="reach padding_L">已兑现</div>';
                } else if (obj.fanxian_type == 3) { // 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动
                  obj.Stutas = '<div class="reach padding_L">已兑现</div>';
                }
              } else {
                if (obj.fanxian_type == 1) { // 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动 ../image/reach.png
                  obj.Stutas = '<div class="padding_L">线下返现</div>';
                } else if (obj.fanxian_type == 2) { // 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动
                  obj.Stutas = '<div class="padding_L">微信红包-自动</div>';
                } else if (obj.fanxian_type == 3) { // 1、线下返线（默认）2、微信红包-自动 3、微信红包-手动
                  obj.Stutas = '<div class="padding_L">微信红包-手动</div>';
                }
              }
            }
            if (obj.fanhour_num > 0) {
              obj.reward = obj.reward ? obj.reward + '<div>' + obj.fanhour_num + '课时</div>' : '<div>' + obj.fanhour_num + '课时</div>';
              if (obj.is_fafang_fanhour == 1) {
                obj.Stutas = obj.Stutas ? obj.Stutas + '<div class="reach padding_L">已兑现</div>' : '<div class="padding_L">已兑现</div>';
              } else {
                obj.Stutas = obj.Stutas ? obj.Stutas + '<div class="padding_L">返课时</div>' : '<div class="padding_L">返课时</div>';
              }
            }
            if (obj.fangift_name) {
              obj.reward = obj.reward ? obj.reward + '<div>' + obj.fangift_name + '</div>' : '<div>' + obj.fangift_name + '</div>';
              if (obj.is_fafang_fangift == 1) {
                obj.Stutas = obj.Stutas ? obj.Stutas + '<div class="reach padding_L">已兑现</div>' : '<div class="padding_L">已兑现</div>';
              } else {
                obj.Stutas = obj.Stutas ? obj.Stutas + '<div class="padding_L">返礼品</div>' : '<div class="padding_L">返礼品</div>';
              }
            }
            this.JSJLTableData.push(obj);
          });
          this.totalNum = result.data.Total;
          this.PageCount = result.data.PageCount;
          this.currentPage = queryParams.PageIndex;
        } else {
          this.totalNum = 0;
        }
        this.$refs.detailList.isLoading = false;
        if(result.data.PageDataList[0].fanxian_amount > 0){
          this.awardText = result.data.PageDataList[0].fanxian_amount + '元';
        }
        if(result.data.PageDataList[0].fanhour_num > 0){
          this.awardText = result.data.PageDataList[0].fanhour_num + '课时';
        }
        if(result.data.PageDataList[0].fangift_name){
          this.awardText = result.data.PageDataList[0].fangift_name;
        }
        if (callback) {
          callback();
        }
      }, error => {
        layer.alert(error.data.msg);
        this.$refs.detailList.isLoading = false;
      });
    },
    // 加载弹出层列表数据
    loadTableData (queryParams, callback) {
      this.$refs.detailList.isSearch = true;
      this.$refs.detailList.isLoading = true;
      console.log('TeacherCommissionDetails', queryParams, this.$refs.detailList.isSearch);
      this.queryParamsRecord = queryParams;
      this.loadIntroduceRewardDetailList(queryParams, callback);
    },
    // 点击table 的详情
    doAfterClickDetail (item, eventName) {
      this.itemInfo = item;
      this.titleInfoList = [
        {name:'邀请人',text:this.itemInfo.username},
        {name:'已邀请',text:this.itemInfo.get_number},
        {name:'邀请名额',text:this.itemInfo.sub_number},
        {name:'奖励',text:this.itemInfo.BMJL},
      ]
      let initSelectedParams = [];
      let unClearParams = [];
      if(this.selectedCouponInfo.if_fenxiang == 3){
        this.listTitle = '裂变奖励';
      }else {
        this.listTitle = '介绍奖励';
      }
      initSelectedParams.push({ name: 'id', value: this.selectedCouponInfo.id, unClear: true }, { name: 'clientid', value: item.uid, unClear: true });// uid: 家长id
      unClearParams.push({ name: '介绍人', value: item.username });
      this.$refs.detailList.isShowListDialog = true;
      this.$refs.detailList.initSelectedParams(initSelectedParams, unClearParams);
    },
    // 点击 弹出层列表的按钮
    doAfterTableBtnClick (rowData, eventName) {
      let type = 0;
      let canDo = true;
      switch (eventName) {
        case tableFunctionType.fanxian:
          canDo = !rowData.is_fafang_fanxian == 1;
          type = 1;
          break;
        case tableFunctionType.fanhour:
          canDo = !rowData.is_fafang_fanhour == 1;
          type = 2;
          break;
        case tableFunctionType.fangift:
          canDo = !rowData.is_fafang_fangift == 1;
          type = 3;
          break;
        default:
          break;
      }
      console.log(rowData, 'rowData');
      // 问题/
      if (canDo) {
        // if (rowData.strategy_type == 1 && rowData.strategy_sub_type == 3 && type == 3) {
        //   this.browseGiftDistribution(rowData);
        // } else {
        this.parentsPromoteDistributionIncentives(rowData, type);
        // }
      }else {
        layer.alert('已发放奖励');
      }
    },
    parentsPromoteDistributionIncentives (rowData, type) {
      ParentsPromoteDistributionIncentives(rowData.id, type).then(result => {
        this.$refs.detailList.isShowListDialog = true;
        this.loadTableData(this.queryParamsRecord, () => {
          this.$nextTick(() => {
            layer.alert(result.msg);
          });
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 浏览礼品发放   用户券id id UserCouponKey
    browseGiftDistribution (rowData) {
      console.log('browseGiftDistribution', rowData);
      BrowseGiftDistribution(0, rowData.id).then(result => {
        console.log('BrowseGiftDistribution', result);
        this.$refs.detailList.isShowListDialog = true;
        this.loadTableData(this.queryParamsRecord, () => {
          this.$nextTick(() => {
            layer.alert(result.msg);
          });
        });
      }, error => {
        layer.alert(error.msg);
      });
    },

    // 点击 弹出层列表的右上角按钮
    doAfterFunctionBtnClick () {

    }
  }

};
</script>
