<template>
  <div class="WKT_course_info_popup">
    <div class="from_input_list">
      <div class="from_input_title form_info_required">
        菜单名称
      </div>
      <div class="from_input_box">
        <input type="text"
               v-model="dataInfo.name"
               @input="changeValue('name')"
               placeholder="请输入菜单名称"
               maxlength="40">
      </div>
    </div>
    <div class="from_input_list">
      <div class="from_input_title form_info_required">
        菜单名称
      </div>
      <div class="from_list_select flex"
           style="align-items: center;">
        <el-radio v-model="dataInfo.type"
                  label="click">发送消息</el-radio>
        <el-radio v-model="dataInfo.type"
                  label="view">跳转网页</el-radio>
        <el-radio v-model="dataInfo.type"
                  label="miniprogram">跳转小程序</el-radio>
      </div>
    </div>
    <div class="from_input_list">
      <!-- v-if="!(isEdit&&seletedItem.pid==0)" -->
      <div class="from_input_title"
           :class="required?'form_info_required':''">
        链接 URL
      </div>
      <div class="from_input_box">
        <input type="text"
               v-model="dataInfo.url"
               placeholder="请输入链接 URL">
      </div>
    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                           @confirmClick="submit"
                           @cancelClick="doAfterClickCancelBtn"></save-cancel-btn-group>
    <!-- <common-button-group @buttonClickEvent="submit"
                         @closeDialog="doAfterClickCancelBtn"></common-button-group> -->
  </div>
</template>
<script>
import { EditWeChatMenu, AddWeChatMenu } from '../../../../../../API/workbench';
export default {
  data () {
    return {
      dataInfo: {
        id: 0,
        pid: 0,      // 父级id
        name: '',    // 菜单名称
        url: '',      // 链接url
        pagepath: '', // 小程序链接url
        type: 'view'    // 菜单类型 click：发送消息， view：跳转网页，miniprogram：跳转小程序
      }
    };
  },
  props: {
    seletedItem: Object,
    isEdit: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  created () {
    if (this.isEdit) {
      this.dataInfo = this.$utils.parseJson(this.seletedItem);
    }
  },
  methods: {
    changeValue (attr) {
      let value = this.dataInfo[attr]; // 校验的字段
      let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/;
      if(!this.isEdit){
        this.dataInfo.pid = this.seletedItem.id || 0;
      }
      let maxLimitStr = 5;//this.dataInfo.pid>0?20:5; // 5个汉字
      let maxEnglishLette= 10;//this.dataInfo.pid>0?40:10
      let pass = true;
      let substringStr = '';
      let length = 0;
      let strArr = value.split('');
      strArr.map((str) => {
        if (pass) {
          if (cnReg.test(str)) {
            // 中文
            length++;
          } else {
            // 英文
            length += 0.5;
          }
          // 大于最大限制的时候
          if (length > maxLimitStr) {
            pass = false;
            layer.alert('文字长度已超出最大值，最大值为'+maxLimitStr+'个汉字或'+maxEnglishLette+'个英文字母');
            // 将校验的字符截取返回
            this.dataInfo[attr] = substringStr;
          } else {
            substringStr += str;
          }
        }
      });
      console.log(maxLimitStr,'maxLimitStr',maxEnglishLette,this.seletedItem)
      return pass;
    },
    submit (callback) {
      console.log('submit', this.dataInfo);
      let isPass = true;
      if (this.dataInfo.content == '') {
        layer.alert('请输入回复内容');
        isPass = false;
      } else if (this.dataInfo.type == '') {
        layer.alert('请选择菜单内容');
        isPass = false;
      } else if (this.required && this.dataInfo.url == '') {
        layer.alert('请输入链接URL');
        isPass = false;
      } else if (this.dataInfo.url && (this.dataInfo.url.indexOf('http://') == -1 && this.dataInfo.url.indexOf('https://') == -1)) {
        layer.alert('链接URL格式不符合要求');
        isPass = false;
      }
      if (this.dataInfo.url) {
        this.dataInfo.url = this.dataInfo.url.replace(/(^\s*)|(\s*$)/g, '');
      }
      if (!isPass) {
        if (callback) {
          callback();
        }
        return false;
      }
      if (this.isEdit) {   // 修改
        EditWeChatMenu(this.dataInfo).then(result => {
          layer.alert('修改成功');
          this.$emit('afterSuccess');
          if (callback) {
            callback();
          }
        }).catch(error => {
          console.log('errEditDefaultReply', error);
        });
      } else {
        this.dataInfo.pid = this.seletedItem.id || 0;
        AddWeChatMenu(this.dataInfo).then(result => {
          layer.alert('新增成功');
          this.$emit('afterSuccess');
          if (callback) {
            callback();
          }
        }).catch(error => {
          console.log('errEditDefaultReply', error);
        });
      }
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
 .WKT_course_info_popup .from_list_select>>>.el-radio{
  margin-right: 9px !important;
 }
</style>
