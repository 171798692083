<template>
  <!-- 学生块组件---作者：Lzm -->
  <div style="width: 100%">
    <div class="student_info_label bg_white"
         v-if="studentInfo.StudentKey">
      <div class="student_info_photo">
        <div class="student_info_photo_img">
          <img :src="vStudentImg"
               class="img_box"
               mode="">
        </div>
        <div class="student_info_type"
             :class="vStudentMarkClass"
             v-if="vStudentMarkName">
          {{vStudentMarkName}}
        </div>
      </div>
      <div class="student_info_text">
        <div class="student_info_name">
          {{studentInfo.StudentKeyValue}}
        </div>
        <div class="student_info_course ">
          <div class="list_type "
               :class="vAttenClass">
            {{vAttenceStatusKeyValue}}
          </div>

        </div>
      </div>
      <div v-if="studentInfo.IsConfirmKey == 0"
           class="unverify_stundent pa"
           @click="isShowVerificationPopup = true">未验证</div>

    </div>

  </div>
</template>

<script>

export default {
  components: {

  },
  data () {
    return {

    };
  },
  props: {
    studentKey: [String, Number], // 学生ID
    studentInfo: Object// 学生对象
  },
  created () {
  },
  watch: {
  },
  computed: {
    // 学生头像
    vStudentImg () {
      // console.log(this.studentInfo, 'HeadImgSupport');
      if (this.studentInfo.HeadImgSupport) {
        if (this.studentInfo.HeadImgSupport.indexOf('http') > -1) {
          return this.studentInfo.HeadImgSupport;
        } else {
          return this.$store.getters.CDNURL + this.studentInfo.HeadImgSupport;
        }
      } else {
        if (Number(this.studentInfo.SexKey) == 2) {
          return this.$store.getters.PCWebURL + '/WXBV/img/WXimg/deflut_boy.png';
        } else if (Number(this.studentInfo.SexKey) == 3) {
          return this.$store.getters.PCWebURL + '/WXBV/img/WXimg/deflut_gril.png';
        } else {
          return this.$store.getters.PCWebURL + '/WXBV/img/WXimg/staff_photo.png';
        }
      }
    },
    // 学生状态
    vStudentMarkName () {
      if (this.studentInfo.IsActiveKey > 0 && this.studentInfo.StudentTypeKey == 2) { // -- 在校
        return '在校';
      } else if (this.studentInfo.IsActiveKey == 0 && this.studentInfo.StudentTypeKey == 2) { // --离校
        return '离校';
      } else if (this.studentInfo.StudentTypeKey == 3) { // -- 2-正式;3-临时（线索）
        return '线索';
      } else {
        return '';
      }
    },
    vAttenClass () {
      if (this.studentInfo.AttenceStatusKey == 3) {
        return 'type_green';
      } else if (this.studentInfo.AttenceStatusKey == 4) {
        return 'type_yellow';
      } else if (this.studentInfo.AttenceStatusKey == 5) {
        return 'type_red';
      } else {
        return 'type_gray';
      }
    },
    vAttenceStatusKeyValue () {
      if (this.studentInfo.AttenceStatusKey == 3) {
        return '签到';
      } else if (this.studentInfo.AttenceStatusKey == 4) {
        return '请假';
      } else if (this.studentInfo.AttenceStatusKey == 5) {
        return '旷课';
      } else {
        return '未考勤';
      }
    },
    // 状态样式
    vStudentMarkClass () {
      switch (this.vStudentMarkName) {
        case '在校':
          return 'type_blue';
        case '线索':
          return 'type_yellow';
        case '离校':
          return 'type_gray';
        default:
          break;
      }
    }

  },
  methods: {

  }
};
</script>

<style>
</style>
