<template>
  <div>
    <div class="choose-courseware">
      <el-tree class="choose-courseware-tree"
               ref="tree"
               :data="dataList"
               :props="prop"
               :load="loadNode"
               lazy
               show-checkbox
               node-key="OLAPKey"
               empty-text="暂⽆数据"
               :default-checked-keys="defaultCheckedKeys"
               :default-expanded-keys="defaultExpandedKeys"
               :default-expand-all="!isActive"
               @check-change="treeNodeClick">

        <span slot-scope="{ node, data }"
              class="span__">
          <i v-if="data.icon"
             style="font-size: 16px;"
             :class="data.icon" />
          <!-- <el-checkbox :label="node.label" v-if="data.check"></el-checkbox> -->
          <!-- <el-radio :label="node"
                    v-model="chooseKey"
                    v-if="data.check"
                    class="tree-node-span">{{node.label}}</el-radio> -->
          <span class="tree-node-span">{{ node.label }}</span>

        </span>
      </el-tree>

    </div>
    <!-- <save-cancel-btn-group :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group> -->
    <div class="bottom_box">
      <div class="el-button btn_light_blue btn_hover_bg_blue bottom_box_btn"
           @click.stop="componentsAuth">完成</div>
    </div>
  </div>

</template>
<script>
import singleSelectionItem from '../../../../common/single-selection-item.vue';
import {
  HQSearchCoursewarePackage
} from '../../../../../API/workbench';
export default {
  data () {
    return {
      selectList: [],
      dataList: [],
      chooseKey: '',
      FailureFilteringKey: 0,
      prop: {
        label: 'MainDemoName',
        // children: 'zones',
        isLeaf: 'leaf'
      },
      defaultCheckedKeys: [], // 默认选中节点
      defaultExpandedKeys: ['2'], // 默认展开节点
      count: 1,
      checkList: [],
      checkLists: []
    };
  },
  components: {
    singleSelectionItem
  },
  props: {
    bigCoursewareData: Array,
    checkedList: Array,
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    vToken () {
      return this.$store.getters.token;
    },
    vRoleKey () {
      return Number(this.vToken.RoleKey) == 2; // 2为校长；非则为老师
    }
  },
  watch: {

  },
  created () {

  },
  mounted () {
    console.log('this.bigCoursewareData', this.bigCoursewareData, this.checkedList);
    this.dataList = this.bigCoursewareData;
    this.dataList[0].icon = 'folder-blue';
    // this.dataList[0].check = true;
    this.defaultCheckedKeys = JSON.parse(JSON.stringify(this.checkedList));
    // if (!this.isActive) {
    //   this.defaultExpandedKeys = [this.bigCoursewareData[0].OLAPKey];
    //   this.defaultExpandedKeys.push(JSON.parse(JSON.stringify(this.checkedList)));
    // }
    // this.defaultCheckedKeys.forEach(item => {
    //   item.OLAPKey = item.CoursewarePackageKey;
    //   item.MainDemoName = item.CoursewarePackageKeyValue;
    // });
  },
  methods: {
    handleCheckChange (data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },
    handleNodeClick (data) {
      console.log(data);
    },
    // treeNodeClick (data, checked) {
    //   console.log(data);
    //   const node = this.$refs.tree.getNode(data);
    //   const str = [];
    //   function filterName (node) {
    //     if (node) {
    //       if (node.parent) {
    //         str.push(node.data.name);
    //         filterName(node.parent);
    //       }
    //     }
    //   }
    //   // 获取当前选择的id在数组中的索引
    //   const indexs = this.selectList.indexOf(data.OLAPKey);
    //   // 如果不存在数组中，并且数组中已经有⼀个id并且checked为true的时候，代表不能再次选择。
    //   if (indexs < 0 && this.selectList.length === 1 && checked) {
    //     console.log(1);
    //     this.$message({
    //       message: '只能选择⼀个区域！',
    //       type: 'error',
    //       showClose: true
    //     });
    //     // 设置已选择的节点为false 很重要
    //     this.$refs.tree.setChecked(data, false);
    //   } else if (this.selectList.length === 0 && checked) {
    //     console.log(2);
    //     // 发现数组为空并且是已选择
    //     // 防⽌数组有值，⾸先清空，再push
    //     this.selectList = [];
    //     // this.ruleForm.branch = ''
    //     this.selectList.push(data.OLAPKey);
    //     filterName(node);
    //     // this.ruleForm.branch = str.reverse().join('/')
    //   } else if (indexs >= 0 && this.selectList.length === 1 && !checked) {
    //     console.log(checked);
    //     console.log(3);
    //     // 再次直接进⾏赋值为空操作
    //     this.selectList = [];
    //     // this.ruleForm.branch = ''
    //   }
    // },
    treeNodeClick (data, checked) {
      this.checkList = [];
      this.checkLists = [];
      // 全选时过滤掉子级，只选父级。
      let list = this.getSimpleCheckedNodes(this.$refs.tree.store);
      list.forEach(obj => {
        this.checkList.push(obj.OLAPKey);
        this.checkLists.push({ CoursewarePackageKey: obj.OLAPKey, CoursewarePackageKeyValue: obj.MainDemoName });
        let findIndex = this.defaultCheckedKeys.indexOf(obj.OLAPKey);
        if (findIndex < 0) {
          this.defaultCheckedKeys.push(obj.OLAPKey);
          // this.$refs.tree.setChecked('40646',true)
        } else {
          this.defaultCheckedKeys.splice(findIndex, 1);
        }
      });
    },
    getSimpleCheckedNodes (store) {
      const checkedNodes = [];
      const traverse = function (node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach(child => {
          if (child.checked) {
            checkedNodes.push(child.data);
          }
          if (child.indeterminate) {
            traverse(child);
          }
        });
      };
      traverse(store);
      console.log(checkedNodes, 'checkedNodes1111');
      return checkedNodes;
    },
    loadNode (node, resolve) {
      if (node.level > 3) { return resolve([]); }
      if (node.level === 0) { return resolve([]); }

      var data;
      console.log(node, 'node');
      setTimeout(() => {
        if (node.level === 1) {
          HQSearchCoursewarePackage(node.data.OLAPKey).then(result => {
            console.log('子课包', result.data);
            result.data.forEach((o, index) => {
              o.icon = 'folder-blue';
              o.check = false;
              if (o.TypeKey == '0') {
                o.leaf = true;
              }
            });
            data = result.data;
          }).catch(error => {
            console.log('获取子课包异常', error);
            return null;
          });
        } else if (node.level === 2) {
          if (node.data.TypeKey == '2') {
            HQSearchCoursewarePackage(node.data.OLAPKey).then(result => {
              console.log('子课包', result.data);
              result.data.forEach((o, index) => {
                o.icon = 'folder-blue';
                o.check = false;
                o.leaf = true;
              });
              data = result.data;
            }).catch(error => {
              console.log('获取子课包异常', error);
              return null;
            });
          } else {
            data = [];
          }
        }
      }, 100);
      setTimeout(() => {
        console.log('data', data);
        if (data) {
          resolve(data);
        } else {
          resolve([]);
        }
      }, 500);
    },
    confirmClick (callBack) {
      if (this.chooseKey == '') {
        layer.alert('请选择课包');
        if (callBack) {
          callBack();
        }
      } else {
        this.$emit('afterSuccess', this.chooseKey.data);
      }
    },
    cancelClick () {
      this.$emit('cancelClick');
    },
    componentsAuth () {
      console.log('选择后', this.checkLists, this.checkList, this.defaultCheckedKeys);
      let isAllCourseware = false;
      let list = [];
      if (this.checkLists.length == 0 || this.checkList.indexOf(this.bigCoursewareData[0].OLAPKey) != -1) {
        isAllCourseware = true;
        list = [{ CoursewarePackageKey: this.bigCoursewareData[0].OLAPKey, CoursewarePackageKeyValue: this.bigCoursewareData[0].MainDemoName }];
        // } else if () {
        //   isAllCourseware = true;
        //   list = [{ CoursewarePackageKey: this.bigCoursewareData[0].OLAPKey, CoursewarePackageKeyValue: this.bigCoursewareData[0].MainDemoName }];
        // }
      } else {
        isAllCourseware = false;
        list = this.checkLists;
      }
      // this.checkList.forEach(item=>{

      // })
      let data = {
        isAllCourseware: isAllCourseware,
        coursewarePackageList: list
      };
      this.$emit('afterSuccess', data);
      this.$emit('close');
    }
  }

};
</script>
<style scoped>
.choose-courseware {
  height: 500px;
  overflow: auto;
  margin-top: 20px;
}
.choose-courseware .form_info_list {
  padding: 0;
}

.choose-courseware .folder-blue {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url('../../../../../../public/cw-img/file_icon@1.png');
  background-size: 23px;
  position: relative;
  top: 8px;
  left: -3px;
}
.choose-courseware >>> .el-tree-node__content {
  height: 30px;
  border: none;
}
.choose-courseware .tree-node-span {
  width: 292px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 12px;
  -o-text-overflow: ellipsis;
}
/* .choose-courseware .el-tree{
  padding: 0px 0px;
} */
/* .choose-courseware .el-tree>.el-tree-node>.el-tree-node__content{
  border: none;
  height: 30px;
    line-height: 30px;
    margin-bottom: 0px;
} */
/* .choose-courseware .el-tree>.el-tree-node>.el-tree-node__children>.el-tree-node>.el-tree-node__content{
  padding-left: 26px !important;
} */
.bottom_box {
  height: 60px;
  padding: 10px 20px;
}
.bottom_box_btn {
  width: 100%;
  height: 100%;
}
</style>
