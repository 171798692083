<template>

  <div class="couser_block_list clearfix">
    <function-btn :item="showArrangeBtn"
                  @clickItem="showArrange"
                  class="list_left"
                  :class="courseClass(item)"
                  :className="''">
      <div>
        <div class="list_title"
             :title="item.GradeClassKeyValue">
          {{item.GradeClassKeyValue}}
        </div>
        <div class="list_top">
          <span class="font_gray"
                @mouseover="isShowMTeaName=true"
                @mouseout="isShowMTeaName=false">{{item.SClassTimeName}}-{{item.EClassTimeName}}&nbsp;&nbsp;{{vMTeacherKeyValue}}</span>
        </div>

        <div class="list_bottom"
             :class="item.StyleName+ '_bottom_color'">
          <!-- <span>{{Number(item.ArrivedNumCount)}} / {{Number(item.ArrangedNumCount)}}</span> -->
          <span v-if="setStatusKeyValue (item)!='未开始'">签到{{vArrivedNumCount}}人 | 未到{{noAttenCount}}人</span>
          <span v-else>安排{{Number(item.ArrangedNumCount)}} 人 </span>
          <!-- <function-btn :item="showArrangeBtn"
                        @clickItem="showArrange"
                        :className="'font_blue fr'">
          </function-btn> -->
          <span class="font_blue fr">详情</span>

        </div>
        <div class="list_type"
             :class="item.StyleName+'_bottom_color'">{{setStatusKeyValue(item)}}</div>
      </div>
    </function-btn>
    <div class="list_right"
         v-if="vAtten">
      <transition name="fade">
        <div class="couser_block_tips type_green">
          <div class="">
            <div class="font_dual type_green">全部已打考勤</div>
            <div class="font_dual">
              <span> 签到{{vAtten3}}人</span>
              <span v-if="vAtten4">, 请假<span class="font_red">{{vAtten4}}</span>人</span>
              <span v-if="vAtten5">, 旷课<span class="font_red">{{vAtten5}}</span>人</span>

            </div>
          </div>
        </div>
      </transition>
    </div>

    <div class="list_right"
         v-else-if="vArrangedNumCount==0||vArrivedNumCount == vArrangedNumCount">
      <transition name="fade"
                  v-if="vArrangedNumCount==0">
        <div class="couser_block_tips type_red">
          <div>未安排学生</div>
        </div>
      </transition>
      <transition name="fade"
                  v-else>
        <div class="couser_block_tips type_green">
          <div class="type_green">满勤</div>
        </div>
      </transition>
    </div>

    <div class="list_right"
         v-else>
      <!-- <div class="list_student_state state_red"
           :class="vArrangedNumCount==0?'state_gray':'state_red'"
           v-if="!isShowNoAtten"
           @click="isShowNoAtten=!isShowNoAtten">
        <div class="list_student_state_text">
          <div class="text_top">未签到</div>
          <div class="text_bottom">{{noAttenCount}} <span class="font_small">人</span></div>
        </div> -->
      <!-- 箭头翻转类名 mirror、红色箭头left、绿色箭头right-->
      <!-- <div class="list_student_state_icon  "
             :class="vArrangedNumCount==0?'right_gray':'mirror left'">

        </div>
      </div> -->

      <div class="list_student_box"
           :class="item.StatusKey == 4&&item.studentList.length>0?'all_couser':''">
        <!-- all_couser -->
        <!-- <div class="list_student_list"
             v-for="stuItem in vStudentList"
             :key="stuItem.StudentKey"
             @click="seletedStuAfter(stuItem)"
             :class="stuItem.isActive?'show_box':''">
          <span>{{stuItem.StudentKeyValue}}</span>
          <div :class="attenClass(stuItem)">{{attenVal(stuItem)}}</div>
        </div> -->
        <!-- AttenceStatusKey==3,出勤, 4请假, 5,旷课 -->
        <student-item v-for="stuItem in vStudentList"
                      :key="stuItem.StudentKey"
                      :stuItem="stuItem"
                      @seletedStuAfter="seletedStuAfter">
        </student-item>
        <!-- 批量考勤 -->
        <div class="all_couser_btn btn_hover_white"
             v-if="item.StatusKey == 4&&noSignCount>0"
             @click.stop="changeIsShowDialog">
          批量考勤
          <div class="module_content_menuList"
               style="width:100%;top:30px;left:0"
               v-show="isShowDialog">
            <div class="top_bg"
                 style=""></div>
            <drop-down-box :optionJsonList="attendanceStatus"
                           @clickOptionForAlertDialog="doAfterExceptBtn"></drop-down-box>
          </div>
        </div>
        <!-- <div class="all_couser_btn"
             v-if="item.StatusKey == 4&&noSignCount>0"
             @click.stop="allQuit(item)">
          全部退课
        </div> -->
      </div>
      <!--  -->
      <!-- <div class="list_student_state state_red"
           :class="vArrangedNumCount==0?'state_gray':'state_red'"
           v-if="!isShowNoAtten">
        <div class="list_student_state_text">
          <div class="pa">
            <div class="text_top">未考勤/人</div>
            <div class="text_bottom pr">
              <span>{{noSignCount}}</span>
              <div class="text_btn opt"
                   @click="isShowNoAtten=!isShowNoAtten">详情</div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="list_student_state"
           :class="vFullAttenBGClass"
           v-if="isShowNoAtten">
        <div class="list_student_state_text"
             v-if="!isFullAtten">
          <div class="pa">
            <div class="text_top">已考勤/人</div>
            <div class="text_bottom pr">
              <span>{{hasAttenCount}}</span>
              <div class="text_btn"
                   @click="isShowNoAtten=!isShowNoAtten">详情</div>
            </div>
          </div>
        </div>
        <div class="list_student_state_clear"
             v-else>
          满勤
        </div>
      </div> -->
      <!-- <div class="list_student_state "  v-if="!isShowNoAtten &&isFullAtten">
        <div class="list_student_state_clear">
          满勤
        </div>
        <div class="list_student_state_icon right mirror">

        </div>
      </div> -->
    </div>
    <!-- 显示加载的进度条 -->
    <custom-dialog class="progress_max progress"
                   width="300px"
                   :visible.sync="showProgress">
      <div class="progress_title">
        正在批量考勤 ，{{runCount}}/{{totolPeoCount}}。
      </div>
      <div class="progress_box">
        <el-progress type="circle"
                     :percentage="progressStatus"></el-progress>
      </div>
      <!-- <div class="progress_exit"></div> -->
    </custom-dialog>
  </div>

</template> 
<script>
import dropDownBox from '../common/drop-down-box';
import {
  GetMainTeaScheduleCourseStudentList, mainTeasetAttendance, mainTeakqChange, CancelArrangeForNoAttendByCourse, mainTeakqLeave, mainTeakqTruancy, BatchSyncSubmit
} from '../../API/workbench';
import studentItem from './student-item';
export default {
  data () {
    return {
      isShowNoAtten: true,
      isShowMTeaName: false,
      showArrangeBtn: {
        'name': '',
        'eventName': '',
        'ModulePowerKey': 0
      },
      isChg: false,
      isShowDialog: false,
      attendanceStatus: [
        {
          type: this.$initJson.attendanceType.view,
          name: '全部请假',
          eventName: 'allLeave',
          className: '',
          isShow: true
        },
        {
          type: this.$initJson.attendanceType.view,
          name: '全部旷课',
          eventName: 'allTruant',
          className: '',
          isShow: true
        },
        {
          type: this.$initJson.attendanceType.view,
          name: '全部退课',
          eventName: 'allQuit',
          className: '',
          isShow: true
        }
      ],
      totolPeoCount: 0,
      runCount: 0, // 进度条.
      showProgress: false,
      isBatchAttendanceSucces: false // 判断批量考勤是否成功过

    };
  },
  props: ['item', 'textSearch'],
  components: {
    studentItem, dropDownBox
  },
  created () {
    // let websocketReadyState = this.$store.getters.getWebsocketReadyState;
    // if (websocketReadyState == 1) {              //获取学生列表接口无关长连接.
    this.getStudentListInCourse(this.item.OLAPKey);
    // }

    this.$bus.on(this, 'AfterStudentUpdate', (studentKey, studentData) => {
      if (studentKey) {
        let studentInfo = this.item.studentList.find(obj => { return obj.StudentKey == studentKey; });
        if (studentInfo && studentData && studentData.MainDemoName) {
          studentInfo.StudentKeyValue = studentData.MainDemoName;
        }
      }
    });
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener(
      'click',
      e => {
        this.isShowDialog = false;
      },
      true
    );
  },
  computed: {
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    },
    vStudentList () {
      let newArr = [];
      this.item.studentList.forEach(o => {
        this.$set(o, 'isOpt', false);
        if (this.textSearch) {
          if (o.StudentKeyValue.indexOf(this.textSearch) != -1 ||
            o.FirstLetterName.toLowerCase().indexOf(this.textSearch.toLowerCase()) != -1 ||
            o.CustomerPhoneName.indexOf(this.textSearch) != -1) {
            o.isActive = true;
          } else {
            o.isActive = false;
          }
        } else {
          o.isActive = false;
        }
        if (this.isShowNoAtten) {       // 显示未考勤.
          if (o.AttenceStatusKey == 2 || !o.AttenceStatusKey) {
            newArr.push(o);
          }
        } else {              // 显示已签到.
          if (o.AttenceStatusKey == 2 || !o.AttenceStatusKey) {

          } else {
            newArr.push(o);
          }
        }
      });
      return newArr;
    },
    noAttenCount () {     // 未考勤人数
      let count = 0;
      if (this.item.studentList) {
        this.item.studentList.forEach(o => {
          if (o.AttenceStatusKey != 3) {
            count++;
          }
        });
      }
      return count;
    },
    noSignCount () {     // 未考勤人数
      let count = 0;
      if (this.item.studentList) {
        this.item.studentList.forEach(o => {
          if (!o.AttenceStatusKey || o.AttenceStatusKey == 2) {
            count++;
          }
        });
      }
      return count;
    },
    hasAttenCount () {    // 已出勤人数.包括请假旷课
      let count = 0;
      if (this.item.studentList) {
        this.item.studentList.forEach(o => {
          if (o.AttenceStatusKey == 2 || !o.AttenceStatusKey) {

          } else {
            count++;
          }
        });
      }
      return count;
    },
    isFullAtten () {
      if (this.vArrivedNumCount > 0 && this.vArrivedNumCount == this.item.studentList.length) {
        return true;
      } else {
        return false;
      }
    },
    vAtten () {       // 显示全部已打考勤
      if (this.vArrangedNumCount == this.hasAttenCount && (Number(this.vAtten4) + Number(this.vAtten5)) > 0) {
        return true;
      } else {
        return false;
      }
    },
    vAtten3 () {      // 出勤人数
      let count = 0;
      if (this.item.studentList) {
        this.item.studentList.forEach(o => {
          if (o.AttenceStatusKey == 3) {
            count++;
          }
        });
      }
      return count;
    },
    vAtten4 () {      // 请假人数
      let count = 0;
      if (this.item.studentList) {
        this.item.studentList.forEach(o => {
          if (o.AttenceStatusKey == 4) {
            count++;
          }
        });
      }
      return count;
    },
    vAtten5 () {      // 旷课人数
      let count = 0;
      if (this.item.studentList) {
        this.item.studentList.forEach(o => {
          if (o.AttenceStatusKey == 5) {
            count++;
          }
        });
      }
      return count;
    },
    //  进度条 百分比.
    progressStatus () {
      var percent = Math.floor((this.runCount / this.totolPeoCount).toFixed(2) * 100);
      return percent || 0;
    },

    // 背景图片颜色
    vFullAttenBGClass () {
      if (this.hasAttenCount == 0) {
        return 'state_gray';
      } else {
        return 'state_green';
      }
    },
    // 箭头颜色图片
    vFullAttenClass () {
      if (this.vArrangedNumCount == 0) {
        return 'mirror right_gray';
      } else if (this.vArrivedNumCount == this.item.studentList.length) {
        return 'mirror right';
      } else {
        return 'left';
      }
    },
    SaaSClientInfo () { // 门店信息   要使用的字段 最早签到时间(分钟)
      return this.$store.getters.SaaSClientInfo;
    },
    vMTeacherKeyValue () {
      if (this.item) {
        this.item.MTeacherKeyValue = this.item.MTeacherKeyValue || '';
        if (this.isShowMTeaName) {
          return this.item.MTeacherKeyValue;
        } else {
          if (this.item.MTeacherKeyValue.indexOf(',') == -1) {
            return this.item.MTeacherKeyValue;
          } else {
            return this.item.MTeacherKeyValue.substring(0, this.item.MTeacherKeyValue.indexOf(',')) + '...';
          }
        }
      } else {
        return '';
      }
    },
    vArrivedNumCount () {
      let num = 0;
      if (this.item.studentList) {
        this.item.studentList.forEach(obj => {
          if (obj.AttenceStatusKey == 3) {
            num += 1;
          }
        });
      }
      return num;
    },
    vArrangedNumCount () {
      return this.item.studentList ? this.item.studentList.length : 0;
    }
  },
  methods: {
    setColor (item) {

    },
    attenClass (item) {
      if (item.AttenceStatusKey == 3) {
        return 'list_stundent_type';
      } else if (item.AttenceStatusKey == 4) {
        return 'list_stundent_type type_yellow';
      } else if (item.AttenceStatusKey == 5) {
        return 'list_stundent_type type_red';
      } else {
        return '';
      }
    },
    attenVal (item) {
      if (item.AttenceStatusKey == 4) {
        return '假';
      } else if (item.AttenceStatusKey == 5) {
        return '旷';
      } else {
        return '';
      }
    },
    courseClass (data) {
      let c = '';
      c += data.StyleName + '_color';
      if (data.StatusKey != 2 && data.NoAttendanceCount > 0) {
        c += ' warning_icon';
      }
      return c;
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    },
    setStatusKeyValue (item) {
      let timer = item.ClassTime.replace(/-/g, '/') + ' ' + item.SClassTimeName;        // 开始时间
      let seconds = Number(Date.new(timer).getTime() / 1000) - Number(this.$utils.getSaaSClientSetInfo(31).SetContent) * 60;  // 获取当前数据的时间
      if (item.StatusKey == 4) {
        return '已上完';
      } else if (item.StatusKey == 3) {
        return '上课中';
      } else if (item.StatusKey == 2 && seconds < (Date.new().getTime()) / 1000) {
        return '可签到';
      } else {
        return '未开始';
      }
    },
    showArrange () {
      this.$emit('attendanceArrange', this.item, this.getStudentListInCourse);
    },
    getStudentListInCourse (OLAPKey, callBack) {
      GetMainTeaScheduleCourseStudentList(OLAPKey).then(result => {
        result.data.forEach(o => {
          o.isOpt = false;
          o.isActive = false;
        });
        this.item.studentList = result.data;
        if (callBack) {
          callBack(result.data);
        }
      }).catch(error => {
        console.log(error, 'error');
      });
    },
    seletedStuAfter (stuItem) {
      if (!this.isChg) {
        this.isChg = true;
        if (this.vHasModulePower(this.showArrangeBtn.ModulePowerKey)) {
          if (this.isShowNoAtten) {
            stuItem.isOpt = true;
            this.setAttendanceByStu(stuItem, 0);
          } else {
            this.changeAttendanceStatus(2, stuItem);
          }
        } else {
          this.$utils.CheckModulePowerToTips(this.showArrangeBtn.ModulePowerKey);
        }
      }
    },
    // 签到接口
    setAttendanceByStu (stuItem, IsClassHourLackKey) {
      mainTeasetAttendance(stuItem.StudentKey,
        stuItem.ScheduleCourseKey,
        IsClassHourLackKey
      ).then(result => {
        this.isChg = false;
        // stuItem.isOpt = true;
        // this.$nextTick(() => {
        stuItem.AttenceStatusKey = 3;
        stuItem.AttenceStatusKeyValue = '签到';
        if (IsClassHourLackKey == 1) {
          stuItem.ISClassHourDebtKey = 1;
          stuItem.ISClassHourDebtKeyValue = '欠课时';
        }
        stuItem.ClassHourKey = result.data.ClassHourKey;     // 消费状态. 5为消费.
        this.item.ArrivedNumCount = this.vArrivedNumCount + 1;        // 签到人数+1
        this.item.NoAttenceCount = Number(this.item.NoAttenceCount) - 1 < 0 ? 0 : Number(this.item.NoAttenceCount) - 1;      // 未签到人数-1
        // });
        // layer.alert(stuItem.StudentKeyValue + '签到成功');
      }, err => {
        let that = this;
        if (err.code == 60001) {
          that.isChg = false;
          layer.confirm(stuItem.StudentKeyValue + '课时不足，是否以欠课时的方式继续签到？', {
            shadeClose: false,
            btn: [{
              name: '取消',
              callBack: function () {
                stuItem.isOpt = false;
                that.isSaving = false;
                layer.close();
              }
            }, {
              name: '确认',
              callBack: function () {
                that.setAttendanceByStu(stuItem, 1);
                layer.close();
              }
            }]
          });
        } else if (err.code == 60008 || err.code == 60009) {
          let tips = '';
          if (err.code == 60008) {
            tips = '学生考勤状态不符，请确认考勤状态后再考勤';
          } else {
            tips = '学生安排不符，请确认安排状态后再考勤';
          }
          layer.alert(tips);
          this.getStudentListInCourse(this.item.OLAPKey);
        } else {
          this.isChg = false;
          layer.alert(err.msg);
        }
      });
    },
    // 改变考勤接口.
    // 变更考勤（已考勤的变更成另一种状态）
    changeAttendanceStatus (type, stuItem) {
      mainTeakqChange(stuItem.StudentKey,
        stuItem.ScheduleCourseKey,
        stuItem.IsClassHourLackKey,
        type, stuItem.StudentArrangeKey
      ).then(result => {
        if (result.code === 0) {
          this.isChg = false;
          if (type == 2) {        // 2为未考勤.
            stuItem.WKQRS += 1;
            stuItem.AttenceStatusKey = 2;
            stuItem.AttenceStatusKeyValue = '未考勤';
            stuItem.ISClassHourDebtKey = 0;
            stuItem.ISClassHourDebtKeyValue = '否';
            this.item.NoAttenceCount = Number(this.item.NoAttenceCount) + 1;      // 未签到人数+1
          }
        }
        layer.alert(stuItem.StudentKeyValue + '设置未考勤成功');
      }, error => {
        this.isChg = false;
        layer.alert(error.msg);
      });
    },
    allQuit (item) {
      layer.confirm('请问是否全部退课?', {
        btn: [{
          name: '确认',
          callBack: () => {
            CancelArrangeForNoAttendByCourse(this,item.OLAPKey).then(result => {
              // layer.alert('全部退课成功');
              this.getStudentListInCourse(item.OLAPKey);
              this.$bus.emit('todayQuitAll', item.OLAPKey);
              console.log(this.item.studentList, 'CancelArrangeForNoAttendByCourse');
            }).catch(error => {
              layer.alert(error.msg);
            });
            layer.close();
          }
        }
        ]
      });
    },
    // 全部旷课
    allTruant (item) {
      console.log('旷课', this.vStudentList);
      layer.confirm('请问是否全部旷课?', {
        btn: [{
          name: '确认',
          callBack: () => {
            this.changeBatchAttendance(this.vStudentList, 0, 2);
            layer.close();
          }
        }
        ]
      });
    },
    // 全部请假
    allLeave (item) {
      console.log('请假', this.vStudentList);
      layer.confirm('请问是否全部请假?', {
        btn: [{
          name: '确认',
          callBack: () => {
            this.changeBatchAttendance(this.vStudentList, 0, 1);
            layer.close();
          }
        }
        ]
      });
    },
    // 批量考勤
    changeBatchAttendance (dataList, IsClassHourLackKey, type) {
      this.totolPeoCount = dataList.length;
      this.showProgress = true;
      let APIPromiseCreator = obj => {
        if (obj.IsClassHourLackKey == 1) {
          IsClassHourLackKey = 1;
        }
        if (type == 1) {
          return mainTeakqLeave(obj.StudentKey, obj.ScheduleCourseKey, IsClassHourLackKey);
        } else {
          return mainTeakqTruancy(obj.StudentKey, obj.ScheduleCourseKey, IsClassHourLackKey);
        }
      };
      let resolve = (r, obj) => {
        this.runCount++;
        Object.assign(obj, r.data);
        // 已签到的 课时状态未已消费
        if (obj.AttenceStatusKey == 3) {
          obj.ClassHourKey = 5;
        }
        this.isBatchAttendanceSucces = true;
      };
      let reject = (r, obj) => {
        this.runCount++;
      };
      let resultCallback = resultList => {
        let isLackOfClassTime = []; // 课时不足的数组.
        let isLackOfClassTimeTS = ''; // 课时不足时提示.
        let otherJecCode = []; // 其他错误时的数组
        let otherPeopleTS = ''; // 其他错误时提示.
        let otherErrorTS = []; // 其他错误时提示.
        resultList.forEach(o => {
          if (o.r.code == 60001) {
            isLackOfClassTime.push(o.data);
            isLackOfClassTimeTS = isLackOfClassTimeTS == '' ? o.data.StudentKeyValue : isLackOfClassTimeTS + ',' + o.data.StudentKeyValue;
          } else if (o.r.code != 0) {
            otherJecCode.push(o.data);
            otherErrorTS.push(o.r);
            otherPeopleTS = otherPeopleTS == '' ? o.data.StudentKeyValue : otherPeopleTS + ',' + o.data.StudentKeyValue;
          }
        });
        if (isLackOfClassTime.length > 0) {
          let that = this;
          layer.confirm(isLackOfClassTimeTS + '课时不足，是否以欠课时的方式继续操作？', {
            shadeClose: false,
            btn: [
              {
                name: '取消',
                callBack: function () {
                  layer.close();
                  that.runCount = 0;
                  that.cancelBatchAttendance();
                }
              },
              {
                name: '确认',
                callBack: function () {
                  that.runCount = 0;
                  that.changeBatchAttendance(isLackOfClassTime, 1, type);
                  layer.close();
                }
              }
            ]
          });
        } else {
          this.runCount = 0;
          if (otherJecCode.length > 0) {
            layer.alert(otherErrorTS[0].msg);
          }
          this.cancelBatchAttendance();
        }
      };
      BatchSyncSubmit(
        dataList,
        APIPromiseCreator,
        resultCallback,
        resolve,
        reject
      );
    },
    // 关闭批量考勤
    cancelBatchAttendance () {
      this.showProgress = false;
      this.getStudentListInCourse(this.item.OLAPKey);
      this.$bus.emit('todayQuitAll', this.item.OLAPKey);
      // this.$emit('cancelBatchAttendance');
    },
    // 显示下拉框
    changeIsShowDialog () {
      this.isShowDialog = true;
    },
    // 按钮操作
    doAfterExceptBtn (item) {
      console.log(item, 'doAfterExceptBtn');
      switch (item.eventName) {
        case 'allLeave':
          this.allLeave(this.item);
          break;
        case 'allTruant':
          this.allTruant(this.item);
          break;
        case 'allQuit':
          this.allQuit(this.item);
          break;
        default:
          break;
      }
    }
  }
};
</script>
