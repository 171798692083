<template>
  <div class="WKT_index_from">
    <div class="WKT_index_from_title">
      图片轮播
    </div>
    <div class="WKT_index_from_content">
      <div class="WKT_slideshow">
        <div class="WKT_slideshow_upload">
          <div class="WKT_slideshow_title">
            机构形象图（第一张）
          </div>
          <crop-upload-img :size="'尺寸：640px*360px'"
                           :imgWidth="640"
                           :imgHeight="360"
                           :remarks="'备注：建议上传机构LOGO或门店照片，更好的展示机构形象。'"
                           :isShowImg="true"
                           :isShrink="false"
                           :ImgURl="dataInfo.BrandImgSupport"
                           @uploadImgSuccess="doAfterUploadImgSuccess"></crop-upload-img>
                        <!-- BrandPath -->
        </div>
        <div class="">
          <div class="WKT_slideshow_title">
            <span>在线活动</span>
            <span class="font_gray">最多展示3个活动</span>
          </div>
          <div class="WKT_slideshow_ul">
            <carousel-list v-for="(item,index) in dataInfo.CorporateImageList"
                           :Item="item"
                           :Index="index"
                           :key="index"
                           :dataList="dataInfo.CorporateImageList"
                           @delCorporateImg="delCorporateImg"
                           @doChangeImageItemClick="doChangeImageItemClick"></carousel-list>
            <!-- <div class="WKT_slideshow_list">
              <div class="WKT_slideshow_img hover_border_blue">
                <img src=""
                     alt="">
                <div class="WKT_slideshow_img_text">活动名称显示在这里</div>
              </div>
              <div class="WKT_slideshow_btn_box">
                <div class="WKT_btn_up WKT_action_btn">

                </div>
                <div class="WKT_btn_down WKT_action_btn">

                </div>
                <div class="WKT_btn_edit WKT_action_btn">

                </div>
                <div class="WKT_btn_remove WKT_action_btn">

                </div>
              </div>
            </div> -->

            <div class="WKT_slideshow_upload_box hover_border_blue"
                 v-if="dataInfo.CorporateImageList&&dataInfo.CorporateImageList.length<3"
                 @click.stop="addActivity">
              <div class="WKT_slideshow_upload_noImg">
                <div class="upload_icon">+</div>
                <div>选择活动</div>
              </div>
            </div>
            <div :style="'opacity: 0;pointer-events: none'">
              <crop-upload-img ref="uploadImg"
                               :imgWidth="640"
                               :imgHeight="360"
                               :imgClass="''"
                               :placeholder="'选择活动'"
                               @uploadImgSuccess="doAfterEditCarouselImgSuccess"></crop-upload-img>
            </div>
          </div>
        </div>
      </div>
    </div>
    <custom-dialog :title="'选择活动'"
                   :visible.sync="isShowActivity"
                   :before-close="choseIsShowActivity">
      <span class="defult_dialog_header_btn fr"
            @click="doAddActivityClick()">
        <span class="btn_add">+</span>创建活动
      </span>
      <activity-list :isShowbtn="false"
                     class="WKT_student_activity_popup_ul"
                     :activitiesCouponList="historyActivitiesList"
                     @doSelectActivity="doSelectActivity"></activity-list>

    </custom-dialog>
  </div>
</template>

<script>
import cropUploadImg from '../common/crop-upload-img';
import activityList from './activity-list';
import carouselList from './carousel-list';
import { AmendCorporateImage, AddCorporateImage, EditCorporateImage
} from '../../../API/workbench';
export default {
  data () {
    return {
      isShowActivity: false,
      changeImgId: 0,
      historyActivitiesList: []

    };
  },
  components: {
    cropUploadImg, activityList, carouselList
  },
  props: {
    dataInfo: {// 轮播图片地址
      type: Object,
      default: function () {
        return {
          BrandImgSupport:'',
          CorporateImageList: []
        };
      }
    }
  },
  created(){
    console.log("dataInfo",this.dataInfo)
  },
  methods: {
    doAfterUploadImgSuccess (imgUrl) {
      console.log('doAfterUploadImgSuccess', imgUrl);
      AmendCorporateImage(imgUrl).then(result => {
        this.$emit('updateCorporateImage', imgUrl);
      }).catch(error => {
        console.log('AmendCorporateImageError', error);
      });
    },
    doAfterEditCarouselImgSuccess (imgUrl) {
      console.log('doAfterEditCarouselImgSuccess', imgUrl, this.changeImgId);
      EditCorporateImage(imgUrl, this.changeImgId).then(result => {
        let locaItem = this.dataInfo.CorporateImageList.find(o => {
          return o.id == this.changeImgId;
        });
        if (locaItem) {
          locaItem.imgUrl = imgUrl;
        }
        layer.alert('修改成功');
      });
    },
    // 选择图片修改环境展示图
    doChangeImageItemClick (e, item) {
      this.$refs.uploadImg.chooseImg(e);
      this.changeImgId = item.id;
    },
    doSelectActivity (item) {
      console.log('doSelectActivity', item);
      this.addCorporateImg(item.pic, item.id);
    },
    addCorporateImg (ImageUrl, id) {
      AddCorporateImage(ImageUrl, id).then(result => {
        console.log('AddCorporateImage');
        this.$emit('updateCorporateData');
        this.choseIsShowActivity();
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    delCorporateImg () {
      this.$emit('updateCorporateData');
    },
    choseIsShowActivity () {
      this.isShowActivity = false;
    },
    addActivity () {
      this.isShowActivity = true;
    },
    // 创建活动
    doAddActivityClick () {
      this.$router.push({ name: 'recruitStudentShow' });
      this.choseIsShowActivity();
      // layer.alert('到O2O分销招生的活动管理列表...');
    }
  }
};
</script>

<style>
</style>