<template>
  <div>
    <!-- class="headquarters_info_search pr" -->
    <div class="port_apply">
      <div class="select">
        <div class="port">
          <elSelectOption :selectTitle="'端口类型'"
                          @changeEvent="choosePortType"
                          :selectionKey="params.portTypeKey"
                          :dataSourceList="PortTypeLsit">
          </elSelectOption>
        </div>
        <div class="port">
          <elSelectOption :selectTitle="'校区类型'"
                          @changeEvent="chooseType"
                          :selectionKey="params.campusTypeKey"
                          :dataSourceList="campusTypeList">
          </elSelectOption>
        </div>
      </div>
      <inputSearchContainBtn v-model="params.searchText"
                             :placeholder="'请输入'"
                             @onSearchEvent="search"
                             @clearSearch="clear"></inputSearchContainBtn>
    </div>

    <div class="table_list_content summarizing_list">
      <tableList ref="tableListRef"
                 :tableData="campusmdList"
                 :tableColumns="tableColumns"
                 :rowKey="'Number'"
                 :totalNum="totalNum"
                 :queryParams="params"
                 :footerContent="'共' + totalNum + '条数据'"
                 @loadTableData="loadTableData"></tableList>
    </div>

  </div>
</template>
<script>
import {
  SearchPagePortConsumeRecord
} from '../../../../../API/workbench';
import elSelectOption from '../../../../report/common/el-select-option.vue'; // 下拉选择框
import tableList from '../../../../common/table-list/index';
import inputSearchContainBtn from '../../../../report/common/input-search-contain-btn.vue';
export default {
  components: {
    elSelectOption,
    tableList,
    inputSearchContainBtn
  },
  data () {
    return {
      PortTypeLsit: [
        // 端口类型
        {
          OLAPKey: 0,
          MainDemoName: '全部'
        },
        {
          OLAPKey: 1,
          MainDemoName: '教务'
        },
        {
          OLAPKey: 2,
          MainDemoName: '课件'
        }
      ],
      campusTypeList: [
        // 校区类型
        {
          OLAPKey: 0,
          MainDemoName: '全部'
        },
        {
          OLAPKey: 1,
          MainDemoName: '直营店'
        },
        {
          OLAPKey: 2,
          MainDemoName: '加盟店'
        },
        {
          OLAPKey: 3,
          MainDemoName: '课程合作'
        }
      ],
      tableColumns: [{
        label: '序号',
        width: 50,
        align: 'center',
        prop: 'Number',
        type: 'tableIndex',
        sortable: false,
        className: 'table_index_gray'
      },
      {
        label: '发生日期',
        prop: 'ApplyTime',
        width: 100,
        type: 'date-item',
        sortable: false
      },
      {
        label: '端口类型',
        prop: 'TypeKeyValue',
        width: 76,
        align: 'left',
        type: 'text-item',
        // decimalPlace: 2,
        sortable: false
      },
      {
        label: '操作类型',
        prop: 'SourceTypeKeyValue',
        width: 76,
        align: 'left',
        type: 'text-item',
        // decimalPlace: 2,
        sortable: false
      },
      {
        label: '端口数量',
        prop: 'PortalChangeNum',
        width: 76,
        type: 'text-item',
        sortable: false
      },
      {
        label: '操作内容',
        prop: 'OLAPKey',
        width: 200,
        align: 'left',
        type: 'text-item',
        // decimalPlace: 2,
        sortable: false
      },
      {
        label: '校区名称',
        width: 120,
        prop: 'MainDemoName',
        type: 'text-item',
        sortable: false
      },
      {
        label: '账户ID',
        prop: 'accountID',
        width: 86,
        align: 'left',
        type: 'text-item',
        // decimalPlace: 2,
        sortable: false
      },
      {
        label: '校区类型',
        prop: 'HQTypeKeyValue',
        width: 80,
        align: 'left',
        type: 'text-item',
        // decimalPlace: 2,
        sortable: false
      },
      {
        label: '管理员',
        prop: 'AdminName',
        width: 85,
        align: 'left',
        type: 'text-item',
        // decimalPlace: 2,
        sortable: false
      },
      {
        label: '管理员手机号',
        prop: 'DefaultMobile',
        width: 124,
        align: 'left',
        type: 'text-item',
        // decimalPlace: 2,
        sortable: false
      },
      {
        label: '经办人',
        prop: 'CreateKeyValue',
        align: 'center',
        type: 'text-item',
        // decimalPlace: 2,
        sortable: false
      }
      ],
      totalNum: 0,
      // 申请记录
      params: {
        pageIndex: 0, //	页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: '', // 排序顺序
        portTypeKey: '', // 整型	可选		数据源：GET	端口类型: 1-教务端口; 2-课件端口
        campusTypeKey: '' // 整型	可选		数据源：GET	校区类型: 1-直营店; 2-加盟店;3-课程合作
        // entryTimeStart: "", //开始录入时间
        // entryTimeEnd: "", //结束录入时间
        // CounselorKey: 0, //投资顾问
        // IsValidKey: 0, //有效性 0全部;1-有效;2-待确认;3-无效
        // SourceTypeKey: 0, //来源
        // StatusKey: 0, //分配状态 0-全部;1-已分配;2-未分配
      },
      campusmdList: [] // 端口使用记录列表
    };
  },
  props: {

  },
  created () {
    this.getSearchPagePortConsumeRecord();
    // this.getCampusPortBasicStatistics();
    // this.getSearchPageForCampusPortApplyRecord();
  },
  computed: {

  },
  methods: {
    onSubmit () {
      console.log('submit!');
    },
    // 搜索
    search () {
      console.log('搜索', this.params);
      this.params.pageIndex = 0;
      // this.initParams();
      // this.$refs.tableListRef.expandRowKeys = [];
      this.loadTableData(this.params);
    },
    // 清除
    clear () {
      console.log('清除', this.params);
      this.params.pageIndex = 0;
      this.initParams();
      // this.$refs.tableListRef.expandRowKeys = [];
      this.loadTableData(this.params);
    },

    // 选择端口状态
    choosePortType (item) {
      this.params.portTypeKey = item.OLAPKey;
      this.search();
    },
    // 选择校区状态
    chooseType (item) {
      this.params.campusTypeKey = item.OLAPKey;
      this.search();
    },
    initParams () {
      this.params.searchText = '';
      this.params.pageIndex = 0;
      this.params.orderBy = '';
      this.params.campusTypeKey = '';
      this.params.portTypeKey = '';
    },
    // 分页查询系统端口使用记录
    getSearchPagePortConsumeRecord () {
      SearchPagePortConsumeRecord(this.params).then(result => {
        this.campusmdList = [];
        this.totalNum = result.data.Total;
        this.campusmdList = result.data.PageDataList;
        this.campusmdList.forEach(o => {
          if (o.SourceTypeKey == 1 || o.SourceTypeKey == 2) {
            o.OLAPKey = `${-o.PortalNum}年:${o.operationApplyTime} 至 ${o.ExpireTime}`;
          } else if (o.SourceTypeKey == 3) {
            o.OLAPKey = '12个(买10送2)';
          } else if (o.SourceTypeKey == 4) {
            o.OLAPKey = '停用';
          } else if (o.SourceTypeKey == 5) {
            o.OLAPKey = '启用';
          } else if (o.SourceTypeKey == 8) {
            o.OLAPKey = '调减' + Number(o.PortalNum) + '个端口';
          } else if (o.SourceTypeKey == 6) {
            o.OLAPKey = +Number(o.PortalNum) + '个';
          } else if (o.SourceTypeKey == 7 || o.SourceTypeKey == 9 || o.SourceTypeKey == 10 || o.SourceTypeKey == 11) {
            o.OLAPKey = o.Remark;
          } else {
            o.OLAPKey = '-';
          }
        });
        console.log('分页查询系统端口使用记录', result);
      }, error => {
        layer.alert(error.msg);
      });
    },

    loadTableData (queryParams) {
      if (queryParams) {
        Object.assign(this.params, queryParams);
      }
      this.getSearchPagePortConsumeRecord();
      // this.getSearchPageForCampusPortApplyRecord();
    }
  }
};
</script>
<style scoped>
/* .summarizing_list.table_list_content {
    overflow: inherit;
  } */

.port_apply {
  display: flex;
  margin: 20px;
}

.select {
  display: flex;
  margin-right: 20px;
}

.port {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
</style>
