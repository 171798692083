<template>
  <div class="approval_job">
    <div class="homerwork_details_banner">
      <work-course-head-box :dataInfo="dataInfo.ScheduleCourse"></work-course-head-box>
    </div>
    <div class="approval_job_open_box"
         v-if="isShowWorkInfo">
      <div class="box_title">
        {{dataInfo.HomeWork.Title}}
      </div>
      <div class="box_text"
           v-html="$utils.translationSchoolText(dataInfo.HomeWork.Content)">
        <!-- {{dataInfo.HomeWork.Content}} -->
      </div>
      <!-- 作业附件内容 -->
      <div>
        <attach-content-list :AttachList="dataInfo.HomeWork.AttachList"
                             :content="dataInfo.HomeWork.Content"></attach-content-list>
      </div>
    </div>
    <div class="approval_job_image"
         @click="isShowWorkInfo=!isShowWorkInfo"
         :class="{'open_type':isShowWorkInfo}">
      <img style="width:100%"
           src="../../../../../../public/image/arrow__bottom_icon_black@2x.png">
    </div>
  </div>
</template>

<script>
import workCourseHeadBox from '../../work-details/common/work-course-head-box.vue';
import attachContentList from '../../common/attach-content-list.vue';
export default {
  components: {
    workCourseHeadBox,
    attachContentList
  },
  data () {
    return {
      isShowWorkInfo: false
    };
  },
  props: {
    dataInfo: Object // 作业项
  },
  methods: {

  }
};
</script>

<style>
</style>
