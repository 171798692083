<template>
  <div class="new_course_box bg_white"
       style="padding-bottom: 75px;">
    <!-- 第一步：课表基本信息 -->
    <div v-show="isShowCourseDetail"
         class="class_table_form form_info_edit content_marginTop_30">
      <course-detail class="form_info_bottom"
                     :courseTableData="courseTableData"
                     @nextStep="nextStepEvent"
                     @cancelClick="cancelEditCourseClick"></course-detail>
    </div>
    <!-- 第二步：设置上课时间 -->
    <div v-show="!isShowCourseDetail">
      <!--课 基本信息 -->
      <course-Info :courseTableData="courseTableData"
                   :isClassGradeWarning="isClassGradeConflictTips"
                   @editCourse="editBasicCourseData"></course-Info>
      <!-- 日历建课 -->
      <div>
        <calendar-choose ref="calendar"
                         :courseTableData="courseTableData"
                         @selectTimeRange="selectTimeRange"></calendar-choose>
      </div>
      <!-- 按钮组 -->
      <div>
        <save-cancel-btn-group :isSingleSubmit="true"
                               :singleSubmitType="'dynamic'"
                               :btnText="'确认'"
                               @confirmClick="confirmClick"
                               @cancelClick="cancelClick"></save-cancel-btn-group>
      </div>
    </div>
    <!-- 冲突提示 -->
    <div v-if="isShowCoursesConflictTips">
      <courses-conflict-tips :generatedClassTimeData="generatedClassTimeData"
                             @changeClassGrade="toChangeClassGrade"
                             @newClassGrade="toNewClassGrade"
                             @backToEdit="tobackToEdit"></courses-conflict-tips>
    </div>
  </div>
</template>

<script>
import courseDetail from '../common/course-detail';
import courseInfo from '../common/course-info';
import operationProcess from '../../common/operation-process';
import calendarChoose from './calendar-choose';
import coursesConflictTips from '../common/courses-conflict-tips';
import {
  getUsingClass,
  addCalendarCourse
} from '../../../API/workbench.js';
export default {
  data () {
    return {
      isAdding: true, // 是否正在新增规律课块
      isShowCourseDetail: true,
      courseTableData: {
        BJPKIDKey: 0,
        BJPKIDKeyValue: '默认班级',
        ClassSetKey: 0,
        ClassSetKeyValue: '',
        FullPeopleCount: 0,
        FrequencyKey: 2, // 2.多节课  3.单节课
        CircleStartTime: '',
        CircleEndTime: '',
        WeekKey: 0,
        SClassTimeName: '',
        EClassTimeName: '',
        ClassRoomIDSName: '',
        ClassRoomName: '',
        LecturerKeyValue: '',
        LecturerKey: '',
        SynchronizeKey: 0,
        PeriodCount: 1.0,
        LeaveClassKey: 0,
        LeaveClassKeyValue: '',
        IsleaveKey: 0,
        WarnLossCount: 0,
        IsleaveKeyValue: '',
        ArrangeName: '',
        frequenceInfo: [],
        isAdding: true,
        DefaultTimeCount: '',
        TimeQuantum: '', // 建课时段
        DurationCount: 0,  // 建课时长
        DateList: []    // 选中日期列表
      },
      generatedClassTimeData: [],
      isShowProgress: false,
      structuredPlanList: [],
      isShowCoursesConflictTips: false, // 班级冲突弹出提示
      isClassGradeConflictTips: false, // 班级冲突警告
      gradeClassList: [],
      isShowEditDialog: false,
      editCourseTableData: {},
      isEditPlan: false,
      selectTimeInfo: null
    };
  },
  props: {
    dataInfo: Object
  },
  components: {
    courseDetail,
    operationProcess,
    courseInfo,
    coursesConflictTips,
    calendarChoose
  },
  created () {
    if (JSON.stringify(this.dataInfo) != '{}') {
      Object.assign(this.courseTableData, this.dataInfo);
    }
    console.log('this.created', this.dataInfo);
    if (this.dataInfo && this.dataInfo.CircleStartTime) {
      this.courseTableData.DateList.push(this.dataInfo.CircleStartTime);
    }
  },
  mounted () {
    this.$bus.on(this, 'AfterClassGradeAddOrEdit', (type, result) => {
      this.courseTableData.BJPKIDKey = result.OLAPKey;
      this.courseTableData.BJPKIDKeyValue = result.MainDemoName;
      this.isClassGradeConflictTips = false;
    });
    this.$dialog.register(this, ['squadClassSetting']);
  },
  computed: {
    vTips () {
      let str = '';
      if (this.isEditPlan) {
        str = '正在批量修改课...';
      } else {
        str = '正在批量新增课...';
      }
      return str;
    }
  },
  watch: {
    'courseTableData.BJPKIDKey': {
      handler (n, o) {
        if (n != o) {
          this.isClassGradeConflictTips = false;
        }
      }
    }
  },
  methods: {
    selectTimeRange (item) {
      this.selectTimeInfo = item;
    },
    // 检查新建节数是否为0
    checkNewCoursesConut () {
      let zeroCourses = '';
      let flag = false;
      for (let index = 0; index < this.courseTableData.frequenceInfo.length; index++) {
        let weekdays = this.$utils.getWeekNum(Date.new(this.courseTableData.CircleStartTime), Date.new(this.courseTableData.CircleEndTime));
        let week = this.courseTableData.frequenceInfo[index];
        let weekKey = week == 0 ? 7 : week;
        let num = weekdays[weekKey];
        // 开始日期为今天的且只有一节
        if (Date.new(this.courseTableData.CircleStartTime).getTime() == Date.new(this.$utils.formatDateToLine(Date.new())).getTime() && num == 1) {
          let hours = Date.new().getHours();
          let minutes = Date.new().getMinutes();
          let curMinutes = hours * 60 + minutes;
          let classMinutes = Number(this.courseTableData.SClassTimeName.split(':')[0]) * 60 + Number(this.courseTableData.SClassTimeName.split(':')[1]);
          if (classMinutes <= curMinutes) {
            zeroCourses = zeroCourses ? zeroCourses + ',周' + this.$utils.getWeekByNum(weekKey) : '周' + this.$utils.getWeekByNum(weekKey);
            flag = true;
          }
        } else if (num == 0) {
          zeroCourses = zeroCourses ? zeroCourses + ',周' + this.$utils.getWeekByNum(weekKey) : '周' + this.$utils.getWeekByNum(weekKey);
          flag = true;
        }
      }
      if (zeroCourses) {
        layer.alert(zeroCourses + '建课节数为0节，请确认上课日期或上课时间。');
      }
      return flag;
    },
    // 验证要提交的数据
    verifySubmitData () {
      let flag = false;
      if (!this.courseTableData.SClassTimeName) {
        flag = true;
        layer.alert('上课时间不能为空');
      }
      return flag;
    },
    // 新增上课时间区间 比较是否有完全相同的时间
    contrastClassTimeDiffer () {
      // 验证是否相同时间段
      let isExistsSameClassTimeIndex = this.generatedClassTimeData.findIndex(obj => {
        return obj.SClassTimeName == this.courseTableData.SClassTimeName && obj.EClassTimeName == this.courseTableData.EClassTimeName;
      });
      // 验证是否相同日期段
      let isExistsSameClassDateIndex = this.generatedClassTimeData.findIndex(obj => {
        return obj.SClassTimeName == this.courseTableData.SClassTimeName && obj.EClassTimeName == this.courseTableData.EClassTimeName;
      });
      let hasExistsClassTimeData = [];
      if (isExistsSameClassTimeIndex >= 0) {
        this.courseTableData.frequenceInfo.forEach(val => {
          let findItem = this.generatedClassTimeData[isExistsSameClassTimeIndex].weekListData.find(item => { return item.WeekKey == val; });
          if (findItem) {
            hasExistsClassTimeData.push(findItem);
          }
        });
      }
      return hasExistsClassTimeData;
    },
    // 确定
    confirmClick (callBack) {
      if (this.selectTimeInfo) {
        this.courseTableData.TimeQuantum = this.selectTimeInfo.SClassTimeName + '-' + this.selectTimeInfo.EClassTimeName;
        this.courseTableData.DurationCount = Number(this.selectTimeInfo.EClassTimeName.split(':')[0]) * 60 + Number(this.selectTimeInfo.EClassTimeName.split(':')[1]) - (Number(this.selectTimeInfo.SClassTimeName.split(':')[0]) * 60 + Number(this.selectTimeInfo.SClassTimeName.split(':')[1]));
      } else {
        layer.alert('请选择时间段');
        this.courseTableData.TimeQuantum = '';
        if (typeof callBack == 'function') {
          callBack();
        }
        return false;
      }
      if (this.courseTableData.DateList.length == 0) {
        layer.alert('请选择建课日期');
        if (typeof callBack == 'function') {
          callBack();
        }
        return false;
      } else {
        // 判断是否超过一年.
        let isOverYear = this.courseTableData.DateList.findIndex(o => {
          return Date.new(o) >= Date.new((Date.new(Date.new().toLocaleDateString()).getTime() + 31536000000));
        });
        if (isOverYear >= 0) {
          layer.alert('最长只能建立一年内的课');
          if (typeof callBack == 'function') {
            callBack();
          }
          return false;
        }
      }
      let isBeforeToday = this.courseTableData.DateList.findIndex(o => {
        return Date.new(o) < Date.new(Date.new().toLocaleDateString());
      });
      if (isBeforeToday >= 0) {
        layer.confirm('当前建课包含过去的时间，是否继续建课？',
          {
            shadeClose: false,
            btn: [
              {
                name: '取消',
                callBack: () => {
                  layer.close();
                  callBack();
                  return false;
                }
              },
              {
                name: '确认',
                callBack: () => {
                  this.comfireSubmit(callBack);
                  // layer.close();
                }
              }
            ]
          }
        );
      } else {
        console.log(this.courseTableData, 'confirmClick');
        this.comfireSubmit(callBack);
      }
    },
    comfireSubmit (callBack) {
      if (!this.courseTableData.BJPKIDKey) {
        let that = this;
        let className = this.newClassGradeName();
        layer.confirm('未选择班级，将为您建一个' + className + '，是否继续？',
          {
            shadeClose: false,
            btn: [
              {
                name: '取消',
                callBack: () => {
                  layer.close();
                  callBack();
                  return false;
                }
              },
              {
                name: '确认',
                callBack: () => {
                  layer.close();
                  that.courseTableData.BJPKIDKeyValue = className;
                  that.batchSubmit(callBack);
                }
              }
            ]
          }
        );
      } else {
        console.log(this.courseTableData, 'confirmClick');
        this.batchSubmit(callBack);
      }
    },
    // 提交
    batchSubmit (callBack) {
      this.isShowProgress = true;
      let courseBillItemData = this.$utils.parseJson(this.courseTableData);
      delete courseBillItemData.frequenceInfo;
      let courseBillArr = [];
      this.courseTableData.frequenceInfo.forEach(value => {
        courseBillItemData.WeekKey = value == 0 ? 7 : value;
        courseBillArr.push(this.$utils.parseJson(courseBillItemData));
      });
      courseBillItemData.IsleaveKeyValue = courseBillItemData.IsleaveKey == 0 ? '否' : '是';
      courseBillItemData.LeaveClassKeyValue = courseBillItemData.LeaveClassKey == 0 ? '否' : '是';
      addCalendarCourse(courseBillItemData).then(result => {
        this.isShowProgress = false;
        this.isAdding = false;
        layer.alert('建课成功');
        this.$bus.emit('AfterNewCourseTable', 'mutil', { CourseNameKey: this.courseTableData.ClassSetKey, classGradeId: this.courseTableData.BJPKIDKey });
        this.$emit('closeDialog');
        if (typeof callBack == 'function') {
          callBack();
        }
      }, error => {
        this.isShowProgress = false;
        if (error.code == 1) {
          layer.alert(error.msg);
        } else if (error.code == 10000) {
          layer.alert(error.msg);
        } else if (error.code == 60101) {
          this.isShowCoursesConflictTips = true;
          this.isClassGradeConflictTips = true;
          this.getValidClassList();
        } else {
          layer.alert(error.msg);
        }
        if (typeof callBack == 'function') {
          callBack();
        }
      });
    },
    // 取消新建课
    cancelClick (type) {
      this.$emit('closeDialog');
    },
    // 新增循环课成功后
    afterAddCircleCourseSuccess (resultData) {
      let classTimeInfo = {
        number: Math.random().toString(36).substring(3, 7),
        CircleStartTime: this.courseTableData.CircleStartTime,
        CircleEndTime: this.courseTableData.CircleEndTime,
        weekListData: this.courseTableData.frequenceInfo,
        SClassTimeName: this.courseTableData.SClassTimeName,
        EClassTimeName: this.courseTableData.EClassTimeName
      };
      this.generatedClassTimeData.push(classTimeInfo);
      this.courseTableData.frequenceInfo = [];
      this.courseTableData.SClassTimeName = '';
      this.courseTableData.EClassTimeName = '';
      this.courseTableData.CircleEndTime = '';
    },
    newClassGradeName () {
      let lastStr = this.courseTableData.ClassSetKeyValue.substring(this.courseTableData.ClassSetKeyValue.length - 1);
      let courseName = '';
      if (lastStr == '课') {
        courseName = this.courseTableData.ClassSetKeyValue.substring(0, this.courseTableData.ClassSetKeyValue.length - 1);
      } else {
        courseName = this.courseTableData.ClassSetKeyValue;
      }
      return courseName + '班';
    },
    // 更换班级
    toChangeClassGrade (id, name) {
      this.closeConflictTip();
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '选择班级',
        moduleName: 'singleSelectionList',
        data: { selectedKey: this.courseTableData.BJPKIDKey, dataSourceList: this.gradeClassList, required: true, addModuleName: '' },
        callBack: {
          onSelectedItem: (data) => { this.selectClassGradeForConflict(data); }
        }
      });
    },
    // 新建班级
    toNewClassGrade () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '新增班级',
        moduleName: 'squadClassSetting',
        data: { editItem: {}, isReadonly: false }
      });
      this.closeConflictTip();
    },
    // 返回修改
    tobackToEdit () {
      this.closeConflictTip();
    },
    // 关闭冲突提示
    closeConflictTip () {
      this.isShowCoursesConflictTips = false;
    },
    // 获取有效班级列表
    getValidClassList () {
      getUsingClass().then(result => {
        this.gradeClassList = [];
        result.data.forEach(obj => {
          if (Date.new() <= Date.new(obj.EndTime) || !obj.EndTime) {
            this.gradeClassList.push(obj);
          }
        });
      });
    },
    // 班级冲突选择班级
    selectClassGradeForConflict (item) {
      this.courseTableData.BJPKIDKey = item.OLAPKey;
      this.courseTableData.BJPKIDKeyValue = item.MainDemoName;
    },
    // 选择班级
    doAfterSelectClassGrade (item) {
      this.courseTableData.BJPKIDKey = item.OLAPKey;
      this.courseTableData.BJPKIDKeyValue = item.MainDemoName;
      this.getValidClassList();
    },
    // 修改课的基础信息
    editBasicCourseData () {
      this.isShowCourseDetail = true;
    },
    // 下一步
    nextStepEvent (courseData) {
      this.isNext = true;
      Object.assign(this.courseTableData, courseData);
      this.cancelEditCourseClick();
    },
    // 关闭修改课弹窗
    cancelEditCourseClick () {
      if (!this.isNext) {
        this.cancelClick();
      } else {
        this.isShowCourseDetail = false;
        this.$refs.calendar.getTimeRangeCourseData();
      }
    }
  }
};
</script>
<style>
</style>