<template>
  <div class="course_student_single course_student_sign_defult"
       @click.stop="selectedItem(studentItem)">
    <div class="course_student_photo">
      <!-- 学生头像 -->
      <heads-img :imgUrl="headImg"
                 :dataInfo="studentItem"></heads-img>
      <!-- 学生类型 -->
      <span class="course_student_type course_student_type_trial"
            v-if="studentItem.IsTrialKey==1">试</span>
      <span class="course_student_type course_student_type_notime"
            v-else-if="noAttendance">无</span>
      <span class="course_student_type course_student_type_owe"
            v-else-if="studentItem.ISClassHourDebtKey>0">欠</span>
    </div>
    <div class="course_student_info ">
      <div :title="studentItem.StuKeyValue||studentItem.StudentKeyValue"
           class="course_student_name">{{studentItem.StuKeyValue||studentItem.StudentKeyValue}}</div>
      <div class="course_student_sign_type sign_type_attendance"
           v-if="studentAttenceStatusKey==3">签到</div>
      <div class="course_student_sign_type sign_type_truancy"
           v-else-if="studentAttenceStatusKey==5">旷课</div>
      <div class="course_student_sign_type sign_type_ask_leave"
           v-else-if="studentAttenceStatusKey==4">请假</div>
      <div class="course_student_sign_type sign_type_noattendance"
           v-else-if="studentAttenceStatusKey==2">未考勤</div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'inClassStudents',
  data () {
    return {
      studentInfo: {}
    };
  },
  props: ['studentItem', 'isShowWKD'], // isShowWKD 是否显示无课单.
  computed: {
    noAttendance () {
      if (this.isShowWKD) {
        if (this.studentItem.CustStatusKey != 1) {
          return true;
        } else {
          return false;
        }
      } else {
        let statusKey = this.studentItem.AttenceStatusKey;
        if (statusKey != 3 && statusKey != 4 && statusKey != 5 && this.studentItem.IsClassHourLackKey == 1) {
          return true;
        } else {
          return false;
        }
      }
    },
    headImg () {
      let pcDomainURLOld = this.$store.getters.CDNURL;
      if (!this.studentItem.HeadImgSupport) {
        this.studentItem.HeadImgSupport = '';
        if (parseInt(this.studentItem.SexKey) == 2) {
          return require('../../../../../public/image/deflut_studentPhoto_boy.png');
        } else {
          return require('../../../../../public/image/deflut_studentPhoto_gril.png');
        }
      }
      if (this.studentItem.HeadImgSupport.indexOf('http') > -1) {
        return this.studentItem.HeadImgSupport;
      } else if (this.studentItem.XMLIcon && this.studentItem.HeadImgSupport.indexOf('/') == -1) {
        return pcDomainURLOld + '/' + this.studentItem.HeadImgSupport;
      } else {
        return pcDomainURLOld + this.studentItem.HeadImgSupport;
      }
    },
    studentAttenceStatusKey () {
      return this.studentItem.AttenceStatusKey;
    }
  },
  methods: {
    selectedItem (studentItem) {
      let Rect = this.$el.getBoundingClientRect();
      let parentRect = this.$parent.$el.getBoundingClientRect();
      this.$emit('setPosition', Rect.left - parentRect.left - 8, Rect.bottom - parentRect.top, studentItem);       // event.clientX  event.clientY   //显示修改考勤
    }
  }
};
</script>
