<template>
  <div class="margin_left_right_div">
    <div class="template_minOrmax_width_div">
      <el-container class="page">
        <el-main class="page_content">
          <!-- 班级统计 -->
          <div style="display: block;margin-bottom: 100px;"
               class="clearfix">
            <!-- 班级详细信息 -->
            <div class="class_statistics_details fl">
              <briefClassInfo :setColor="'dot_green'"
                              :count="gradeStatistical.ActiveGradeTotal"
                              :content="initClassGradeStatus.activeGrade"
                              @showDetail="showDetail(initClassGradeStatus.activeGrade)"></briefClassInfo>
              <briefClassInfo :setColor="'dot_blue'"
                              :title="'排课≤10节'"
                              :count="gradeStatistical.ArrangeCourseLower10"
                              :content="initClassGradeStatus.arrangeCourseLower10"
                              @showDetail="showDetail(initClassGradeStatus.arrangeCourseLower10)"></briefClassInfo>
              <briefClassInfo :setColor="'dot_cyan'"
                              :title="'招生率≤60%'"
                              :count="gradeStatistical.EnrollRateLower60"
                              :content="initClassGradeStatus.enrollRateLower60"
                              @showDetail="showDetail(initClassGradeStatus.enrollRateLower60)"></briefClassInfo>
              <briefClassInfo :setColor="'dot_yellow'"
                              :count="gradeStatistical.FinishedGradeTotal"
                              :content="initClassGradeStatus.finishedGrade"
                              @showDetail="showDetail(initClassGradeStatus.finishedGrade)"></briefClassInfo>
            </div>
            <!-- 图表区域 -->
            <div class="class_statistics_echarts fl">
              <classCountReport :className="'class_statistics_echarts_attendance'"
                                :idName="'hour'"></classCountReport>
              <atten-report></atten-report>
              <!-- <statisticalChart :type="attenType" :formTitle="'出勤率排名-按班级'" :echartID="'attendance'" @chgDate="chgDate"></statisticalChart> -->
            </div>
          </div>
        </el-main>

        <!-- 弹窗区域 -->
        <!-- 所有班级 -->
        <list ref="classGrade"
              :listTitle="'所有班级'"
              :sarchPlaceholder="'请输入班级名'"
              :extendSearchCondition="extendSearchCondition"
              :functionBtnGroup="functionBtnGroup"
              :tableData="classGradeList"
              :totalNum="totalNum"
              :PageCount="PageCount"
              :tableColumns="tableColumns"
              @actionBtnClick="doAfterFunctionBtnClick"
              @tableBtnClick="doAfterTableBtnClick"
              @loadTableData="loadTableData"></list>
        <!-- 班级档案 -->
        <class-file ref="classFile"
                    @editClass="editBtn"></class-file>
      </el-container>
      <div>
        <dialog-factory ref="classGradeDialogFactory"
                        :key="'ClassGrade'"
                        :routerName="'ClassGrade'"></dialog-factory>
        <dialog-factory-common ref="classGradeDialogFactoryCommon"></dialog-factory-common>
      </div>
    </div>
  </div>
</template>
<script>
import list from '../../list/index';
import classFile from '../../pop-up-menu/class-file/index';
import briefClassInfo from '../../common/brief-class-info';
import statisticalChart from '../../common/statistical-chart';
import attenReport from './atten-report';
import classCountReport from './class-count-report';
import { getSmmarRizeForGrade, MessageSearchPageForGrade, FinishedGrade, UploadFilesShowProgress } from '../../../API/workbench';
import dialogFactory from '../../common/dialog-factory';
export default {
  name: 'ClassGrade',
  data () {
    return {
      initClassGradeStatus: { activeGrade: '活跃班级', arrangeCourseLower10: '排课不足班级', enrollRateLower60: '招生率不足班级', finishedGrade: '已结束班级' },
      gradeStatistical: {},
      attenType: 'attenType',
      classCountType: 'classCountType',
      // 所有班级参数
      // 操作按钮名
      functionBtnGroup: [{ name: '新增', eventName: this.$initJson.baseFunctionBtnName.add, icon: '', roleName: '', ModulePowerKey: 45 }], // 功能按钮组
      extendSearchCondition: [// 扩展查询条件
        {
          type: this.$initJson.extendSearchConditionType.dateRange, // 日期范围
          formTitle: '建班日期',
          fieldList: [
            {
              name: 'startTime', // 参数名
              defaultValue: '', // 默认值
              value: '', // 绑定值
              validateHandle: '', // 自定义验证函数
              sourceData: []// 数据源
            },
            {
              name: 'endTime',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: []
            }
          ]
        },
        {
          type: this.$initJson.extendSearchConditionType.selectOption,
          formTitle: '班级状态',
          fieldList: [
            {
              name: 'stutasKey',
              defaultValue: 0,
              value: 0,
              validateHandle: '',
              sourceData: [{ key: 0, value: '不限' }, { key: 4, value: '活跃' }, { key: 5, value: '已结束' }]
            }
          ]
        }, {
          type: this.$initJson.extendSearchConditionType.numberRangePercent,
          formTitle: '招生率',
          fieldList: [
            {
              name: 'sEnrollRate',
              defaultValue: '',
              value: '',
              validateHandle: (value) => {
                if (value < 0) {
                  layer.alert('招生率不能小于0');
                  return false;
                } else if (value > 100) {
                  layer.alert('招生率不能大于0');
                  return false;
                } else {
                  return true;
                }
              },
              sourceData: []
            },
            {
              name: 'eEnrollRate',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: []
            }
          ]
        }, {
          type: this.$initJson.extendSearchConditionType.numberRange,
          formTitle: '排课节数',
          fieldList: [
            {
              name: 'sAvailableCourse',
              defaultValue: '',
              value: '',
              validateHandle: (value) => {
                if (value < 0) {
                  layer.alert('排课节数不能小于0');
                  return false;
                } else {
                  return true;
                }
              },
              sourceData: []
            },
            {
              name: 'eAvailableCourse',
              defaultValue: '',
              value: '',
              validateHandle: (value) => {
                if (value < 0) {
                  layer.alert('排课节数不能小于0');
                  return false;
                } else {
                  return true;
                }
              },
              sourceData: []
            }
          ]
        }
      ],
      tableColumns: [ // table 列
        {
          label: '班名',
          prop: 'MainDemoName',
          width: 150,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '建班日期',
          prop: 'StartTime',
          width: 120,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '计划招生',
          prop: 'WantPeopleCount',
          width: 100,
          sortable: 'custom',
          align: 'left',
          type: 'number-item'
        },
        {
          label: '在读学生',
          prop: 'CurrentPeoCount',
          width: 100,
          sortable: 'custom',
          align: 'left',
          type: 'number-item'
        },
        {
          label: '招生率',
          prop: 'EnrollRate',
          width: 100,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '排课节数',
          prop: 'AvailableCourse',
          width: 100,
          sortable: 'custom',
          align: 'left',
          type: 'number-item'
        },
        {
          label: '班级状态',
          prop: 'StutasKeyValue',
          width: 100,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          extend: {
            tableBtnGroup: [
              {
                name: '查看',
                extend: {
                  isHide: false,
                  click: (rowData) => {
                    this.readBtn(rowData);
                  }
                }
              },
              {
                name: '修改',
                extend: {
                  isHide: false,
                  click: (rowData) => {
                    this.editBtn(rowData);
                  }
                }
              },
              {
                name: '结束',
                extend: {
                  isHide: false,
                  IsInquiryHint: 'inquiryBtn',
                  setClassName: (rowData) => {
                    if (rowData.inquiryBtn) { // 开启询问提示，显示询问弹窗时，界面按钮置灰，不可操作
                      return rowData.inquiryBtn;
                    } else if (rowData.StutasKey == 5) {
                      return 'btn_light_gray';
                    } else {
                      return 'btn_light_red';
                    }
                  },
                  click: (rowData, callBack) => {
                    if (callBack) {
                      callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: 'table_btn_gray' });
                    }
                    this.finishBtn(rowData, callBack);
                  }

                }
              }
            ]
          }
        }

      ],
      classGradeList: [],
      totalNum: 0,
      PageCount: 0
    };
  },
  created () {
    this.getBriefStatistical();
  },
  mounted () {
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'ClassGrade') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'ClassGrade') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });
  },
  computed: {
    vCurrentPage: {
      get () {
        return this.$refs.classGrade.queryParams.pageIndex;
      },
      set (n, o) {

      }
    }
  },
  components: {
    list,
    classFile,
    briefClassInfo,
    statisticalChart,
    attenReport,
    classCountReport,
    dialogFactory
  },
  methods: {
    chgDate (startTime, endTime) {
      console.log(startTime, endTime, '223532');
    },
    // 显示班级详情 new初始化数据对象
    showDetail (type) {
      let initSelectedParams = [];
      switch (type) {
        case this.initClassGradeStatus.activeGrade:
          initSelectedParams.push({ name: 'stutasKey', value: 4 });
          break;
        case this.initClassGradeStatus.arrangeCourseLower10:
          initSelectedParams.push({ name: 'stutasKey', value: 4 }, { name: 'sAvailableCourse', value: 0 }, { name: 'eAvailableCourse', value: 10 });
          break;
        case this.initClassGradeStatus.enrollRateLower60:
          initSelectedParams.push({ name: 'stutasKey', value: 4 }, { name: 'sEnrollRate', value: 0 }, { name: 'eEnrollRate', value: 60 });
          break;
        case this.initClassGradeStatus.finishedGrade:
          initSelectedParams.push({ name: 'stutasKey', value: 5 });
          break;
        default:
          break;
      }
      this.$refs.classGrade.isShowListDialog = true;
      this.$refs.classGrade.initSelectedParams(initSelectedParams);
    },
    // 显示所有班级
    showAllClassGrade () {
      this.loadTableData(this.queryParamsForList);
      this.$refs.classGrade.isShowListDialog = true;
      this.$refs.classGrade.selectedParams = [];
    },
    // table 功能按钮点击
    doAfterFunctionBtnClick (eventName) {
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.add:
          this.addBtn();
          break;
        default:
          break;
      }
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName) {
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.edit:
          this.editBtn(rowData);
          break;
        case this.$initJson.baseFunctionBtnName.read:
          this.readBtn(rowData);
          break;
        case this.$initJson.baseFunctionBtnName.finish:
          this.finishBtn(rowData);
          break;
        default:
          break;
      }
    },
    // 新增
    addBtn () {
      let item = {
        routertitle: '班级信息',
        routerName: 'ClassGrade',
        moduleName: 'newClass',
        ModulePowerKey: 50,
        callBack: { addSuccess: () => { this.doAfterGradeClassAddSuccess(); } }
      };
      // item.callBack = () => { this.doAfterGradeClassAddSuccess(); };
      this.openCustomDialogByFactoryDialog(item);
    },
    // 新增班级成功 全局刷新
    doAfterGradeClassAddSuccess () {
      let queryParams = this.$refs.classGrade.queryParams;
      queryParams.pageIndex = 0;
      this.loadTableData(queryParams);
      // 重新获取统计数据
      this.getBriefStatistical();
    },
    // 修改
    editBtn (rowData, callBack) {
      let item = {
        name: '修改班级',
        routertitle: '班级信息',
        routerName: 'ClassGrade',
        moduleName: 'newClass',
        ModulePowerKey: 50,
        data: { dataInfo: rowData },
        callBack: {
          addSuccess: () => {
            this.doAfterEditClassGrade();
            if (typeof callBack == 'function') {
              callBack();
            }
          }
        }
      };
      console.log('ddddd');
      this.openCustomDialogByFactoryDialog(item);
    },
    // 修改班级成功后 刷新当页
    doAfterEditClassGrade () {
      this.loadTableData(this.$refs.classGrade.queryParams);
    },
    // 查看 班级档案
    readBtn (rowData) {
      this.$refs.classFile.showClassFile = true;
      this.$refs.classFile.selectedClass(rowData);
    },
    // 结束
    finishBtn (rowData, callBack) {
      if (rowData.StutasKey != 4) {
        return false;
      }
      let that = this;
      layer.confirm('是否结束当前选中班级', {
        btn: [{
          name: '取消',
          callBack: function () {
            layer.close();
            if (callBack) {
              callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
            }
          }
        }, {
          name: '确认',
          callBack: function () {
            FinishedGrade(rowData.OLAPKey).then(result => {
              that.loadTableData(that.$refs.classGrade.queryParams);
              // 重新获取统计数据
              that.getBriefStatistical();
              if (callBack) {
                callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
              }
            }, error => {
              layer.alert(error.msg);
            });
            layer.close();
          }
        }]
      });
    },
    // 加载列表数据
    loadTableData (queryParams) {
      this.$refs.classGrade.isLoading = true;
      MessageSearchPageForGrade(queryParams).then(
        result => {
          console.log('this.queryParams', queryParams);
          console.log(result.data);
          this.classGradeList = [];
          if (result.data.PageDataList.length > 0) {
            this.classGradeList = result.data.PageDataList;
            // result.data.PageDataList.forEach((obj, index) => {
            //   if (obj.StutasKey == 4) {
            //     obj.StutasKeyValue = '活跃';
            //   } else {
            //     obj.StutasKeyValue = '已结束';
            //   }
            //   this.classGradeList.push(obj);
            // });
            this.totalNum = result.data.Total;
            this.PageCount = result.data.PageCount;
          } else {
            this.totalNum = 0;
          }
          this.$refs.classGrade.isLoading = false;
        },
        error => {
          this.$refs.classGrade.isLoading = false;
          layer.alert(error.msg);
        }
      );
    },
    // 获取班级基本统计.
    getBriefStatistical () {
      getSmmarRizeForGrade().then(
        result => {
          this.gradeStatistical = result.data;
        },
        r => {
          console.log(r);
        }
      );
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.classGradeDialogFactory) {
        this.$refs.classGradeDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.classGradeDialogFactoryCommon) {
        this.$refs.classGradeDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title, 'HQ');
      }
    }
  }
};
</script>

