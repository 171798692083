<template>
  <!-- 待检查列表 -->
  <div class=" table_list_content summarizing_list">
    <div class="single_result_detail">
      <!-- 搜索 -->
      <div class="table_select_box">
        <input-search-contain-btn v-model="queryParams.searchText"
                                  style="margin: 10px;"
                                  :width="'280px'"
                                  :placeholder="'请输入'"
                                  @clearSearch="clearSearch"
                                  @onSearchEvent="search"></input-search-contain-btn>
      </div>
      <!-- 通用列表 -->
      <table-list ref="tableListRef"
                  :tableData="tableDateList"
                  :tableColumns="tableColumns"
                  :rowKey="'Number'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  @loadTableData="loadTableData"></table-list>
    </div>
  </div>
</template>
<script>
import { hqSearchPageTeacherAssessmentStat, SearchPageTeacherAssessmentStat } from '../../../../../API/workbench.js';
import inputSearchContainBtn from '../../../../report/common/input-search-contain-btn';
import tableList from '../../../../common/table-list/index';
export default {
  data () {
    return {
      // 查询参数
      queryParams: {
        pageIndex: 0, //	整型	可选		数据源：GET	页码
        pageSize: 10, //	整型	可选		数据源：GET	分页大小
        searchText: '', //	字符串	可选		数据源：GET	关键字
        date: '', //	字符串	可选		数据源：GET	备课时间
        TeacherKey: '', //	老师
        SchoolKey: '', //	整型	可选		数据源：GET	校区id
        SchoolKeyValue: ''
      },
      tableDateList: [], // 门店列表
      tableColumns: [
        {
          label: '上课时间',
          prop: 'newClassDateText',
          width: 180,
          align: 'left',
          sortable: false,
          type: 'date-item',
          extend: {
            dateType: 'YYMMRR HH:DD' // 日期类型
          }
        },
        {
          label: '课名',
          prop: 'CourseNameKeyValue',
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '班级',
          prop: 'GradeClassKeyValue',
          width: 180,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '课评率',
          prop: 'newAssessmentRateText',
          width: 90,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '签到学生',
          prop: 'SignInNum',
          width: 90,
          align: 'left',
          type: 'number-item',
          sortable: false
        },
        {
          label: '课评人数',
          prop: 'AssessmentNum',
          width: 90,
          align: 'left',
          type: 'number-item',
          sortable: false
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          width: 90,
          extend: {
            tableBtnGroup: [
              {
                name: '查看信息',
                extend: {
                  click: (rowData) => {
                    this.examineBtnEvent(rowData);
                  }
                }
              }

            ]
          }
        }
      ],
      pageTotal: 0,
      totalNum: 0,
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  props: {
    paramsObj: Object
  },
  components: {
    inputSearchContainBtn,
    tableList
  },
  computed: {
    vTypeKey () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) { // 总部key为6
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    paramsObj: {
      // 深度监听该对象
      handler: function (c, o) {
        console.log(' paramsObj', c);
        this.queryParams.pageIndex = 0;
        this.queryParams.pageSize = 10;
        this.queryParams.StartDate = c.StartDate;
        this.queryParams.EndDate = c.EndDate;
        this.queryParams.TeacherKey = c.TeacherKey;
        this.queryParams.SchoolKey = c.SchoolKey;
        this.gethqSearchPageTeacherAssessmentStat(this.queryParams);
      },
      deep: true
    }
  },
  created () {
    this.queryParams.StartDate = this.paramsObj.StartDate;
    this.queryParams.EndDate = this.paramsObj.EndDate;
    this.queryParams.TeacherKey = this.paramsObj.TeacherKey;
    this.queryParams.SchoolKey = this.paramsObj.SchoolKey;
    this.gethqSearchPageTeacherAssessmentStat(this.queryParams);
  },
  methods: {
    //  分页查询老师课评统计
    gethqSearchPageTeacherAssessmentStat (queryParams) {
      queryParams.searchText = queryParams.searchText.trim();
      let apiFN = (this.vTypeKey ? hqSearchPageTeacherAssessmentStat : SearchPageTeacherAssessmentStat);
      apiFN(queryParams).then(result => {
        console.log(' 分页查询老师课评统计', result.data);
        this.totalNum = result.data.Total; // 分页数
        this.tableDateList = result.data.PageDataList || [];
        if (result.data && result.data.PageDataList.length > 0) {
          this.tableDateList.forEach(o => {
            o.newClassDateText = o.ClassTime.slice(5, 10) + ' ' + o.ClassTimeName;
            o.newAssessmentRateText = Number(o.AssessmentRate) > 0 ? this.$utils.mMoneyType(Number(o.AssessmentRate), 0) + '%' : '-';
          });
        }
        // 获取动态老师排名列表滚动高度
        this.$nextTick(() => {
          if (this.$refs.tableListRef && this.$refs.tableListRef.$el.clientHeight > 0) {
            this.$emit('teacherListScrollHeight', this.$refs.tableListRef.$el.clientHeight);
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 操作菜单
    examineBtnEvent (rowData) {
      let moduleInfo = {
        name: '课评详情',
        moduleName: 'commentDetail',
        routerName: this.$route.name,
        data: { dataInfo: { courseId: rowData.OLAPKey, TeacherKey: this.queryParams.TeacherKey, SchoolKey: this.queryParams.SchoolKey } } // 416930 rowData.OLAPKey
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    },
    // 搜索
    search (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.queryParams.pageIndex = 0;
      this.gethqSearchPageTeacherAssessmentStat(this.queryParams);
    },
    // 清除条件
    clearSearch () {
      this.queryParams.pageIndex = 0;
      this.queryParams.searchText = '';
      this.gethqSearchPageTeacherAssessmentStat(this.queryParams);
    },
    // 排序
    loadTableData (queryParams) {
      console.log(queryParams, '排序');
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.gethqSearchPageTeacherAssessmentStat(this.queryParams);
    }
  }
};
</script>
<style>
</style>

