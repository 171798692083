<template>
	<div class="homerwork_details_student_ul">
		<item v-for="(item,key) in workStudentList" :key="key" :item="item" @doWorkCorrectClick="doWorkCorrectClick"></item>
		<div v-if="workStudentList.length == 0">
			<basics-nodata-tips></basics-nodata-tips>
		</div>
	</div>
</template>

<script>
import item from './item.vue';
export default {
  components: {
    item
  },
  data () {
    return {

    };
  },
  props: {
    workStudentList: Array,
    dataInfo:Object
  },
  methods: {
    doWorkCorrectClick (item) {
      console.log(item, 'dataInfo');
      let moduleInfo = {
        name: '批改作业',
        moduleName: 'workCorrectDetails',
        routerName: this.$route.name,
        data: { dataInfo:{
            ID: item.StudentSubHomeWork.ID,
            notShowAll:this.dataInfo.notShowAll

        } }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
      // this.$zxrouter.navigateTo({
      //   url: '/pages/home-school-communication/details-page/work-correct-details/index' + '?ID=' + dataInfo.StudentSubHomeWork.ID
      // });
    }
  }
};
</script>

<style>

</style>
