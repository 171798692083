<template>
  <div class="course_statistics_table_search">
    <div class="query_box_content"
         v-show="!isBatchDistribute">
      <div class="query_box_first_line">
        <div class="search_item">
          <date-range ref="saveDate"
                      :searchObj="vSearchDataRange"
                      :fromTitle="'跟进日期'"
                      @changeDate="changeDate"></date-range>
        </div>
        <div class="search_item">
          <el-select-option :selectTitle="'来源方式'"
                            :dataSourceList="sourceTypeList"
                            :selectionKey="paramsForQuery.SourceTypeKey"
                            @changeEvent="chooseSourceType"></el-select-option>
        </div>
        <div class="search_item">
          <el-select-option :selectTitle="'跟进类型'"
                            :dataSourceList="statusList"
                            :selectionKey="paramsForQuery.StatusKey"
                            @changeEvent="chooseStatus"></el-select-option>
        </div>
        <div class="search_item">
          <el-select-option :selectTitle="'招商顾问'"
                            :dataSourceList="counselorList"
                            :selectionKey="paramsForQuery.CounselorKey"
                            @changeEvent="chooseCounselor"></el-select-option>
        </div>
      </div>
      <div class="query_box_second_line">
        <div class="search_item">
          <input-search-contain-btn v-model="paramsForQuery.searchText"
                                    :placeholder="'请输入'"
                                    @onSearchEvent="searchClick"
                                    @clearSearch="clearSearchParams"></input-search-contain-btn>
        </div>
        <div class="option_btn"
             @click="exportClick">
          <div class="import_investor btn_hover_bg_blue">导出</div>
        </div>
      </div>
    </div>
    <div v-if="isBatchDistribute"
         class="batch_option_box flex">
      <div class="pitch_count pr">
        已选中 {{ batchAllotList.length }} 项
        <div class="remove_db_btn"
             @click="cancleBatchDistribute"></div>
      </div>
      <div class="btn_hover_bg_blue batch_btn"
           @click="doBatchAllot">
        批量分配
      </div>
    </div>
  </div>
</template>

<script>
import elSelectOption from '../../../../report/common/el-select-option'; // 下拉选择框
import inputSearchContainBtn from '../../../../report/common/input-search-contain-btn';
import {
  GetCounselorList,
  hqAttractInvestmentSourceSet,
  SearchPageFollowOrderRecord
} from '../../../../../API/workbench';
export default {
  props: {
    paramsForQuery: {
      // 查询参数
      type: Object
    },
    batchAllotList: {
      // 批量选中的投资人数
      type: Array
    },
    tableData: Array
  },
  data () {
    return {
      counselorList: [], // 投资顾问列表
      sourceTypeList: [], // 来源
      statusList: [
        // 签单进度1-首次跟进;2-日常跟进;3-预约到店;4-到店考察;5-档案完善;6-签约;7-支付定金;8-支付全款;9-放弃;10-无效线索
        { OLAPKey: 0, MainDemoName: '全部' },
        { OLAPKey: 1, MainDemoName: '首次跟进' },
        { OLAPKey: 2, MainDemoName: '日常跟进' },
        { OLAPKey: 3, MainDemoName: '预约到店' },
        { OLAPKey: 4, MainDemoName: '到店考察' },
        // { OLAPKey: 5, MainDemoName: "档案完善" },
        { OLAPKey: 6, MainDemoName: '签约' },
        { OLAPKey: 7, MainDemoName: '支付定金' },
        { OLAPKey: 8, MainDemoName: '支付全款' },
        { OLAPKey: 7, MainDemoName: '放弃' },
        { OLAPKey: 8, MainDemoName: '无效线索' }
      ],
      isBatchDistribute: false // 是否显示查询条件
    };
  },
  components: {
    elSelectOption,
    inputSearchContainBtn
  },
  computed: {
    vSearchDataRange () {
      return {
        startTime: this.paramsForQuery.entryTimeStart,
        endTime: this.paramsForQuery.entryTimeEnd
      };
    }
  },
  created () {
    this.loadAdvisorList();
    this.loadSourceType();
  },
  methods: {
    // 获取投资顾问列表
    loadAdvisorList () {
      GetCounselorList().then((result) => {
        this.counselorList = result.data;
        this.counselorList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
      });
    },
    // 查询所有招商来源设置-总部
    loadSourceType () {
      hqAttractInvestmentSourceSet().then(
        (result) => {
          this.sourceTypeList = result.data || [];
          this.sourceTypeList.unshift({
            MainDemoName: '全部',
            OLAPKey: 0
          });
          console.log(this.sourceTypeList, '查询所有招商来源设置');
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    // 改变日期
    changeDate (dataInfo) {
      this.paramsForQuery.entryTimeStart = dataInfo.startTime;
      this.paramsForQuery.entryTimeEnd = dataInfo.endTime;
    },
    // 选择投资顾问
    chooseCounselor (item) {
      this.paramsForQuery.CounselorKey = item.OLAPKey;
      this.searchClick();
    },
    // 选来源
    chooseSourceType (item) {
      this.paramsForQuery.SourceTypeKey = item.OLAPKey;
      this.searchClick();
    },
    // 选择签单进度
    chooseStatus (item) {
      this.paramsForQuery.StatusKey = item.OLAPKey;
      this.searchClick();
    },
    // 点击搜索
    searchClick () {
      this.paramsForQuery.pageIndex = 0;
      this.$emit('searchClick', this.paramsForQuery);
    },
    // 清除搜索条件
    clearSearchParams () {
      this.$emit('clearSearchParams');
    },
    // 导出
    exportClick () {
      if (this.tableData.length > 0) {
        let obj = this.$utils.parseJson(this.paramsForQuery);
        obj.IsExportKey = 1;
        obj.pageIndex = 0;
        obj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '招商管理跟进记录表',
          clist: [
            {
              title: '跟进日期',
              cName: 'FollowTime',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '跟单人',
              cName: 'FollowKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '意向级别',
              cName: 'IntentionKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '姓名',
              cName: 'MainDemoName',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '手机号',
              cName: 'MobilePhone',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '城市省份',
              cName: 'ProvinceKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '计划城市',
              cName: 'CityKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '加盟方式',
              cName: 'FranchiseTypeKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '招商预算起',
              cName: 'StratBudgetAmount',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '招商预算止',
              cName: 'EndBudgetAmount',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '来源',
              cName: 'SourceTypeKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '签单进度',
              cName: 'StatusKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '招商顾问',
              cName: 'CounselorKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '最近跟进时间',
              cName: 'RecentlyFollowTime',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '跟进记录',
              cName: 'Content',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '下次跟进',
              cName: 'NextFollowTime',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            }
          ]
        };
        SearchPageFollowOrderRecord(obj, jsonFormate).then(
          (result) => {
            window.location.href = this.$store.getters.APIURL + result.data.url;
          },
          (error) => {
            layer.alert(error.msg);
          }
        );
      } else {
        layer.alert('暂无数据导出');
      }
    }
  }
};
</script>

<style scoped>
.course_statistics_table_search {
  padding: 13px 0px;
}

.query_box_content {
  width: 100%;
}
.query_box_first_line {
  display: flex;
}
.query_box_second_line {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}
.course_statistics_table_search .search_item {
  margin-right: 20px;
}
.option_btn {
  display: flex;
}
.import_investor {
  height: 35px;
  width: 56px;
  line-height: 34px;
  text-align: center;
  border-radius: 4px;
}
.batch_option_box {
  height: 86px;
  display: flex;
  align-items: center;
}
.remove_db_btn {
  background-image: url(../../../../../../public/image/dialog_del_icon.png);
  position: absolute;
  right: -40px;
  top: 14%;
  margin-top: -8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 8px;
}
.batch_btn {
  position: absolute;
  left: 180px;
  top: 170px;
  height: 40px;
  width: 100px;
  line-height: 38px;
  text-align: center;
  border-radius: 4px;
  padding-left: 27px;
  padding-right: 7px;
  background: #3498db
    url(../../../../../../public/image/batch_distribution_icon.png) no-repeat
    8px;
}
</style>