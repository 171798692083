<template>
  <div>
    <div class="form_item form-input form_info_line"
         :class="{ form_info_required: required }">
      <div class="input_img_set pr">
        <span>{{formTitle}}</span>
        <span v-if="helpDescription"
              style="position: absolute;">
          <input-form-tip :tips='helpDescription'></input-form-tip>
        </span>
      </div>
      <div class="fr"
           :class="!readonly?'my_datum_picture_portrait':'on_my_datum_picture_portrait'"
           style="z-index:11;line-height: 26px;">
        <!-- 显示 -->
        <!-- <img :style="{ width:imgWidth+'px', height:imgHeight+'px', 'position': 'absolute', 'opacity': 0}"> -->
        <span style="position: absolute;right: 55px;"
              v-if="!this.imgURlString&&!readonly"
              :class="!readonly?'font_blue':'font_gray'">{{placeholderText}}</span>

        <img :src="headImg"
             :onerror="defaultImg"
             alt="../../../public/image/upload_default_pic.png"
             class=""
             :style="imgstyleSize">
        <!-- 上传 -->
        <choose-img-mutil class="upload_box"
                          :maxNum="9"
                          :imgMaxWidth="imgMaxWidth"
                          :inputStyle="'width: 100px;left: -68px;'"
                          @upLoadImgReject="doAfterUpLoadImgSuccess"></choose-img-mutil>
      </div>
    </div>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: 'inputSelect',
  data () {
    return {
      selectPicUrl: '',          // 选中图片url
      isChgPic: false,
      imgURlString: this.ImgURl || '',
      imgstyleSize: this.imgstyle  // 裁切大小
    };
  },
  props: {
    ImgURl: String, //
    formTitle: String,
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    imgHeight: {// 裁切的图片高度，默认210，
      type: Number,
      default: 101
    },
    imgWidth: {// 裁切的图片宽度，默认320，
      type: Number,
      default: 101
    },
    imgstyle: {// 默认img样式，
      type: String,
      default: 'width: 38px;height: 38px;border-radius: 50px'
    },
    readonly: {// 是否只读，
      type: Boolean,
      default: false
    },
    imgMaxWidth: {
      type: Number,
      default: function () {
        return 1000;
      }
    },
    placeholderText: {
      type: String,
      default: () => {
        return '上传图片'
      }
    },
    helpDescription: String, // 表单说明提示
    saaSClientNameKey: [Number, String]// 上传到指定门店

  },
  computed: {
    headImg () {
      if (!this.imgURlString) {
        this.imgURlString = '';
      }
      if (this.imgURlString.indexOf('http') > -1) {
        return this.imgURlString;
      } else {
        return this.$store.getters.CDNURL + this.imgURlString;
      }
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../public/image/upload_default_pic.png') + '"';
    }
  },
  created () {
  },
  watch: {
    ImgURl (n, c) {
      this.imgURlString = n;
    }
  },
  methods: {
    closeChgPic () {
      this.isChgPic = false;
    },
    doAfterUpLoadImgSuccess (imgUrl, callback) {
      console.log("doAfterUpLoadImgSuccess", imgUrl)
      this.imgURlString = imgUrl;
      this.$emit('doAfterUpLoadImgSuccess', this.imgURlString);
    },
  }
};
</script>

 <style>
.my_datum_picture_img_text {
  width: 70px;
  height: 70px;
  border-radius: 4px;
}
.my_datum_picture_portrait {
  margin-left: 220px;
}
/* .my_datum_picture_text {
  width: 100%;
  background-color: #fff;
 padding: 4px 76px;
  border-bottom: 1px solid #f2f2f2;
  overflow: hidden;
} */
</style>