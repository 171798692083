<template>
  <div class="table_select_box_flex"
       :class="{'table_select_box_small':showTeacher}">
    <div class="table_select_box">
      <div class="table_select_title">班级</div>
      <el-select :value="vGradeKeyValue"
                 filterable
                 :style="searchObj.isShowDialog?' width: 150px;':''"
                 placeholder="必选"
                 @change="accountEvent"
                 value-key="OLAPKey">
        <el-option v-for="item in gradeClassList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item"></el-option>
      </el-select>
    </div>
    <div class="table_select_box">
      <div class="table_select_title">课程</div>
      <el-select :value="vCourseKeyValue"
                 filterable
                 :style="searchObj.isShowDialog?' width: 150px;':''"
                 placeholder="必选"
                 @change="subjectEvent"
                 value-key="OLAPKey">
        <el-option v-for="item in courseNameList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item"></el-option>
      </el-select>
    </div>
    <div class="table_select_box"
         v-if="showTeacher">
      <div class="table_select_title">主讲</div>
      <el-select :value="vTeacherKeyValue"
                 filterable
                 :style="searchObj.isShowDialog?' width: 150px;':''"
                 placeholder="必选"
                 @change="typeEvent"
                 value-key="OLAPKey">
        <el-option v-for="item in teacherList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item"></el-option>
      </el-select>
    </div>
    <div class="table_select_box jianrong"
         v-if="showTeacher"
         style="margin-left: 25px;">
      <div class="table_select_title">考勤状态</div>
      <el-select :value="vAttenStatu"
                 filterable
                 :style="searchObj.isShowDialog?' width: 150px;':''"
                 placeholder="必选"
                 @change="attenStatusEvent"
                 value-key="OLAPKey">
        <el-option v-for="item in vattenStatusList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item"></el-option>
      </el-select>
    </div>
    <div class="table_select_box jianrong"
         v-if="showTeacher&&!isTeacher"
         style="margin-left: 25px;">
      <div class="table_select_title">考勤课时类型</div>
      <el-select :value="vCourseTimeType"
                 filterable
                 :style="searchObj.isShowDialog?' width: 150px;':''"
                 placeholder="必选"
                 @change="courseTimeTypeEvent"
                 value-key="OLAPKey">
        <el-option v-for="item in courseTimeTypeList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item"></el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
// import { geTteacherList, getUsingClass, getCourseTypeNameListX } from '../../../../API/workbench';
export default {
  data () {
    return {
      // teacherList: [], // 老师列表
      // gradeClassList: [], // 班级列表
      // courseNameList: [],
      attenStatusList: [
        {
          MainDemoName: '全部',
          OLAPKey: ''
        },
        {
          MainDemoName: '签到',
          OLAPKey: '3'
        },
        {
          MainDemoName: '请假',
          OLAPKey: '4'
        }
        // {
        //   MainDemoName: '旷课',
        //   OLAPKey: '5'
        // }
      ],
      courseTimeTypeList: [
        {
          MainDemoName: '全部',
          OLAPKey: '0'
        },
        {
          MainDemoName: '欠课时',
          OLAPKey: '1'
        },
        {
          MainDemoName: '试课',
          OLAPKey: '2'
        },
        {
          MainDemoName: '补课',
          OLAPKey: '3'
        }
      ]
    };
  },
  created () {
    // this.loadDataSourceTeacher();
    // this.getValidClassList();
    // this.getCourseNameList();
    console.log(this.searchObj, '选中。。。');
  },
  props: {
    searchObj: Object,
    showTeacher: {
      type: Boolean,
      default: false
    },
    teacherList: Array,
    gradeClassList: Array,
    courseNameList: Array,
    isTeacher: {
      type: Boolean,
      default: false
    }

  },
  computed: {
    vattenStatusList () {
      let list = this.$utils.parseJson(this.attenStatusList);
      let item = { MainDemoName: '旷课', OLAPKey: '5' };
      if (this.$utils.getSaaSClientSet(59) > 0) {
        list.push(item);
      }
      return list;
    },
    vCourseKeyValue () {
      return this.searchObj.CourseKey == 0
        ? '全部'
        : this.searchObj.CourseKeyValue;
    },
    vGradeKeyValue () {
      return this.searchObj.GradeKey == 0
        ? '全部'
        : this.searchObj.GradeKeyValue;
    },
    vTeacherKeyValue () {
      return this.searchObj.TeacherKey == 0
        ? '全部'
        : this.searchObj.TeacherKeyValue;
    },
    vAttenStatu () {
      return !this.searchObj.AttenceStatusKey
        ? '全部'
        : this.searchObj.AttenceStatusKeyValue;
    },
    vCourseTimeType () {
      return !this.searchObj.CourseTimeType
        ? '全部'
        : this.searchObj.CourseTimeTypeValue;
    }
  },
  watch: {

  },
  methods: {
    // 选中类型
    typeEvent (obj) {
      console.log(obj, '选中老师');
      this.searchObj.TeacherKeyValue = obj.MainDemoName;
      this.searchObj.TeacherKey = obj.OLAPKey;
      this.$emit('changeSearchObj');
    },
    // 选中账户
    accountEvent (obj) {
      console.log(obj, '选中班级');
      this.searchObj.GradeKeyValue = obj.MainDemoName;
      this.searchObj.GradeKey = obj.OLAPKey;
      this.$emit('changeSearchObj');
    },
    // 选中课程
    subjectEvent (obj) {
      console.log(obj, '选中课程');
      this.searchObj.CourseKeyValue = obj.MainDemoName;
      this.searchObj.CourseKey = obj.OLAPKey;
      this.$emit('changeSearchObj');
    },
    // 选中考勤状态
    attenStatusEvent (obj) {
      console.log(obj, '选中考勤');
      this.searchObj.AttenceStatusKeyValue = obj.MainDemoName;
      this.searchObj.AttenceStatusKey = obj.OLAPKey;
      this.$emit('changeSearchObj');
    },
    // 选中考勤状态
    courseTimeTypeEvent (obj) {
      console.log(obj, '选中考勤');
      this.searchObj.CourseTimeTypeValue = obj.MainDemoName;
      this.searchObj.CourseTimeType = obj.OLAPKey;
      this.$emit('changeSearchObj');
    }
    // 调用在职老师接口
    // loadDataSourceTeacher () {
    //   geTteacherList().then(result => {
    //     this.teacherList = result.data;
    //     this.teacherList.unshift({
    //       MainDemoName: '全部',
    //       OLAPKey: 0
    //     });
    //   });
    // },
    // 获取有效班级列表
    // getValidClassList () {
    //   getUsingClass().then(result => {
    //     this.gradeClassList = result.data;
    //     this.gradeClassList.unshift({
    //       MainDemoName: '全部',
    //       OLAPKey: 0
    //     });
    //   });
    // },
    // 获取有效课程
    // getCourseNameList () {
    //   getCourseTypeNameListX('OLAPKey', 'desc').then(result => {
    //     this.courseNameList = result.data.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; });
    //     this.courseNameList.unshift({
    //       MainDemoName: '全部',
    //       OLAPKey: 0
    //     });
    //   });
    // }
  }

};
</script>
<style scoped>
.table_select_box_flex {
  flex-wrap: wrap;
}
@media screen and (max-width: 1366px) {
  .jianrong {
    margin-top: 10px;
  }
}
</style>