<template>
  <div class="general_summarizing_student">
    <div class="general_summarizing_student_navUl"
         style="margin: 0px;">
      <div class="title_list"
           @click="tableSwitch(1)"
           :class="{ opt: tableType == 1 }">
        学生结转统计
      </div>
      <div class="title_list"
           @click="tableSwitch(2)"
           :class="{ opt: tableType == 2 }">
        结转课消明细
      </div>
      <div class="title_list"
           @click="tableSwitch(3)"
           :class="{ opt: tableType == 3 }">
        结转天数明细
      </div>
      <div class="title_list"
           @click="tableSwitch(4)"
           :class="{ opt: tableType == 4 }">
        异常结转明细
      </div>
    </div>
    <div class="payment_statistics_bottom clearfix">
      <div v-if="tableType == 1">
        <student-transfer-report @toTableType="tableSwitch"
                                 @changeType="changeType"
                                 :searchTime="searchObj"
                                 :schoolItem="schoolItem"></student-transfer-report>
      </div>
      <div v-if="tableType == 2">
        <course-transfer-report @toTableType="tableSwitch"
                                :searchText="searchText"
                                :searchTime="searchObj"
                                :schoolItem="schoolItem"></course-transfer-report>
      </div>
      <div v-if="tableType == 3">
        <day-transfer-report @toTableType="tableSwitch"
                             :searchText="searchText"
                             :searchTime="searchObj"
                             :schoolItem="schoolItem"></day-transfer-report>
      </div>
      <div v-if="tableType == 4">
        <exception-transfer-report @toTableType="tableSwitch"
                                   :searchText="searchText"
                                   :searchTime="searchObj"
                                   :schoolItem="schoolItem"></exception-transfer-report>
      </div>

    </div>
  </div>
</template>
<script>

import studentTransferReport from './components/student-transfer-report';
import courseTransferReport from './components/course-transfer-report';
import exceptionTransferReport from './components/exception-transfer-report';
import dayTransferReport from './components/day-transfer-report';
export default {
  data () {
    return {

      searchText: '',
      searchObj: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0))
      }
    };
  },
  components: {
    studentTransferReport, courseTransferReport, exceptionTransferReport, dayTransferReport
  },
  props: {
    tableType: [Number, String],
    schoolItem: Object
  },
  created () {
    console.log(this.$route.query, 'this.$route.query');
  },
  activated () {
    console.log('activated');
  },
  methods: {
    initData (type, searchData) {
      this.searchText = '';
      this.searchObj = searchData;
      // this.$nextTick(o=>{
      //   this.tableType = type||this.tableType;
      // })
    },
    changeType (item, type, searchData) {
      console.log('searchData', searchData);
      this.searchText = item.MainDemoName;
      this.searchObj = searchData;
      this.$nextTick(o => {
        this.$parent.indexType = type;
      });
    },
    tableSwitch (type, optDataTime) {
      this.$parent.indexType = type;
      this.searchText = '';
    }
  }
};
</script>
<style scoped>
.payment_statistics_bottom >>> .course_statistics_table_search {
  padding: 20px 0px !important;
}
</style>