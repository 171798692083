<!-- 流量套餐管理模块 -->
<template>
  <div class="general_summarizing_student payment_statistics_view height_type all_view">
    <search-flow-box-bar :params="paramsForQuery"
                         @addCampus="addClick"
                         @loadTableData="loadTableData"></search-flow-box-bar>
    <custom-dialog :title="titleName"
                   :visible.sync="isShowAddFlowAdminFormPopup"
                   :before-close="closeAddFlowAdminCampusFormPopup">
      <flow-Add-form :CampusKey="Number(campusEditIndo.OLAPKey)"
                     :isReadonly="titleName=='查看'?true:false"
                     @close="closeAddFlowAdminCampusFormPopup"
                     @afterSuccess="loadTableData"></flow-Add-form>
    </custom-dialog>
    <custom-dialog :title="titleName"
                   :visible.sync="isShowFlowAdminFormPopup"
                   :before-close="closeFlowAdminCampusFormPopup">
      <flow-edit-form :CampusKey="Number(campusEditIndo.OLAPKey)"
                      :isReadonly="titleName=='查看'?true:false"
                      @close="closeFlowAdminCampusFormPopup"
                      @afterSuccess="loadTableData"></flow-edit-form>
    </custom-dialog>
    <div class="course_statistics_table_box table_list_content follow_clue_list prepare_publish_template">
      <table-list class="summarizing_list"
                  ref="tableListRef"
                  :tableData="tableData"
                  :tableColumns="tableColumns"
                  :totalNum="totalNum"
                  :queryParams="paramsForQuery"
                  :footerContent="vFooterTotal"
                  @loadTableData="loadTableData"></table-list>
    </div>
    <!-- <dialog-factory ref="FlowSetMealTemplateVersionList"
                    :key="'FlowSetMealTemplateVersionList'"
                    :routerName="'FlowSetMealTemplateVersionList'"></dialog-factory> -->
  </div>
</template>
<script>
import SearchFlowBoxBar from './search-flow-box-bar';
import tableList from '../common/table-list/index';
import flowEditForm from './form/flow-edit-form';
import flowAddForm from './form/flow-add-form';
import { GetFluxComboList, ExitFluxConboStatus } from '../../API/workbench';
export default {
  data () {
    return {
      dataInfoList: {},
      titleName: '添加',
      isShowFlowAdminFormPopup: false,
      isShowAddFlowAdminFormPopup: false,
      campusEditIndo: {},
      paramsForQuery: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: ''
      },
      tableData: [],
      totalNum: 0,
      tableColumns: [
        {
          label: '名称',
          prop: 'MainDemoName',
          width: 150,
          type: 'text-item'
        },
        {
          label: '数量',
          prop: 'SizeNum',
          width: 70,
          type: 'text-item'
        },
        {
          label: '单位',
          prop: 'UnitKey',
          width: 70,
          type: 'text-item'
        },
        {
          label: '适用门店',
          prop: 'IsUseClientKey',
          width: 85,
          type: 'text-item'
        },
        {
          label: '适用总部',
          prop: 'IsUseHQKey',
          width: 85,
          type: 'text-item'
        },
        {
          label: '是否课件功能赠送',
          prop: 'IsGiveKey',
          width: 140,
          type: 'text-item'
        },
        {
          label: '原价',
          prop: 'PriceAmount',
          width: 100,
          type: 'text-item'
        },
        {
          label: '售价',
          prop: 'PrimeAmount',
          width: 100,
          type: 'text-item'
        },
        {
          label: '备注',
          prop: 'Remark',
          width: 130,
          type: 'text-item'
        },
        {
          label: '系统状态',
          prop: 'StatusKeyValue',
          width: 100,
          align: 'center',
          type: 'text-item',
          sortable: 'custom',
          extend: {
            setClassName: (rowData) => { // 状态 0-已停用;1-已过期;2-使用中
              if (rowData.StatusKey == 0) {
                rowData.StatusKeyValue = '已停用';
                return 'status_spot_red';
              } else if (rowData.StatusKey == 1) {
                rowData.StatusKeyValue = '正常';
                return 'status_spot_gray';
              } else {
                return '';
              }
            }
          }
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          extend: {
            tableBtnGroup: [{
              name: '修改',
              extend: {
                click: (rowData) => {
                  this.editClick(rowData);
                },
                ModulePowerKey: ''
              }
            },
            {
              name: (rowData) => {
                return rowData.StatusKey == 0 ? '启用' : '停用';
              },
              extend: {
                IsInquiryHint: 'inquiryBtn',
                setClassName: (rowData) => {
                  if (rowData.inquiryBtn) { // 开启询问提示，显示询问弹窗时，界面按钮置灰，不可操作
                    return rowData.inquiryBtn;
                  }
                },
                click: (rowData, callBack) => {
                  if (callBack) {
                    callBack({
                      IsInquiryHint: 'inquiryBtn',
                      InquiryClassName: 'table_btn_gray'
                    });
                  }
                  this.switchClick(rowData, callBack);
                },
                ModulePowerKey: ''
              }
            }
            ]
          }
        }
      ]
    };
  },
  components: {
    SearchFlowBoxBar,
    tableList,
    flowEditForm,
    flowAddForm
  },
  created () {
    this.loadTableData();
  },
  mounted () {

  },
  computed: {
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    }
  },
  methods: {
    // 新增
    addClick (type) {
      this.titleName = '添加';
      this.dataInfoList = {};
      this.isShowAddFlowAdminFormPopup = true;
    },
    // 加载表单数据
    loadTableData () {
      GetFluxComboList(this.paramsForQuery).then(result => {
        this.tableData = result.data.PageDataList;
        this.totalNum = result.data.Total;
        console.log(result.data, 'result.data');
        result.data.PageDataList.forEach((o) => {
          o.UnitKey = o.UnitKey == 1 ? 'G' : 'T';
          o.IsGiveKey = o.IsGiveKey == 1 ? '是' : '否';
          o.IsUseClientKey = o.IsUseClientKey == 1 ? '是' : '否';
          o.IsUseHQKey = o.IsUseHQKey == 1 ? '是' : '否';
          o.PriceAmount = Number(o.PriceAmount); // 售价
          o.PrimeAmount = Number(o.PrimeAmount); // 原价
          // this.applyGradeList.push(o);
        });
      });
    },
    // 修改
    editClick (rowData) {
      this.titleName = '修改';
      this.campusEditIndo = rowData;
      console.log(rowData, '流量套餐管理-修改');
      this.isShowFlowAdminFormPopup = true;
    },
    // 查询
    searchClick () {
      this.loadTableData();
    },
    // 清除查询条件
    clearSearchCondition () {
      this.paramsForQuery.pageIndex = 0;
      this.paramsForQuery.searchText = '';
      this.searchClick();
    },
    // 停用/启用
    switchClick (rowData, callBack) {
      let name = rowData.StatusKey == 0 ? '启用' : '停用';
      layer.confirm('请确认是否' + name + '?', {
        btn: [{
          name: '取消',
          callBack: function () {
            layer.close();
            if (callBack) {
              callBack({
                IsInquiryHint: 'inquiryBtn',
                InquiryClassName: ''
              });
            }
          }
        },
        {
          name: '确认',
          callBack: () => {
            console.log(rowData.StatusKey, 'rowData.111111StatusKey');

            ExitFluxConboStatus(rowData.OLAPKey, rowData.StatusKey).then(result => {
              console.log(result, 'resuttt');
              if (callBack) {
                callBack({
                  IsInquiryHint: 'inquiryBtn',
                  InquiryClassName: '',
                  StatusKey: rowData.StatusKey
                });
              }
              this.loadTableData();
              console.log(rowData.StatusKey, 'rowData.S2222222222tatusKey');
            }, error => {
              layer.alert(error.msg);
            });

            layer.close();
          }
        }
        ]
      });
    },
    // 关闭
    closeFlowAdminCampusFormPopup () {
      this.isShowFlowAdminFormPopup = false;
    },
    // 关闭
    closeAddFlowAdminCampusFormPopup () {
      this.isShowAddFlowAdminFormPopup = false;
    }
  }
};
</script>
<style>
/* 设置滚动条的样式 */
#FlowSetMealTemplateVersionList {
  padding: 26px 38px 54px;
  width: 1366px;
  margin: 0 auto;
}
</style>
