<template>
  <div :class="imgClass"
       @click="seletedItem">
    <!-- class="WKT_slideshow_upload_box  hover_border_blue pr" -->
    <div>
      <div style="width:100%;height:100%"
           v-if="isShowImg&&imgURlString">
        <img :src="headImg"
             :onerror="defaultImg"
             alt="../../../../../../../../public/image/no_logo@2x.png">
      </div>
      <div class="WKT_slideshow_upload_noImg"
           v-else>
        <div class="upload_icon">+</div>
        <div>{{placeholder}}</div>
      </div>
      <div id="screanContent"
           style="z-index:1;position: absolute !important;right: 45px; opacity: 0"></div>

      <img ref="imgFile"
           :style="{ width:imgWidth+'px',  height:imgHeight+'px', 'position': 'absolute', 'opacity': 0,'z-index':0}">
      <!-- 上传 -->
      <div :class="{'WKT_slideshow_upload_shade':isShowImg&&imgURlString}"
           style="width:100%;height:100%;position: absolute;top: 0;left: 0;">
        <input type="file"
               title=""
               name="file"
               accept="image/*"
               class="button my_view_chg_index"
               @change="chooseImg($event,100)"
               style="width:100%;height:100%;opacity:0;"
               data-type="userIconImg">
      </div>
    </div>
    <div class="WKT_slideshow_upload_text"
         v-if="size||remarks">
      <div>{{size}}</div>
      <div class="font_gray">{{remarks}}</div>
    </div>
    <custom-dialog :title="'裁切图片'"
                   class=""
                   :visible.sync="isChgPic"
                   :before-close="closeChgPic">
      <!-- <div class="layer-content clearfix">
        <div class="layer-main_normal">

          <vue-crop @afterCrop="afterCrop2"
                    ref="cropper"
                    :crop-url="selectPicUrl"
                    :crood="crood2"
                    :ratio="preWHRatio"
                    :isOtherPic="true"
                    :width="410"></vue-crop>
        </div>
        <save-cancel-btn-group :isSingleSubmit="true"
                               :btnText="'保存'"
                               :singleSubmitType="'dynamic'"
                               @confirmClick="setPicCorp"
                               @cancelClick="clearPicData"></save-cancel-btn-group>
      </div> -->
      <div class="layer-content clearfix">
        <div class="layer-main_normal">
          <vue-crop @afterCrop="afterCrop2"
                    ref="cropper"
                    :crop-url="selectPicUrl"
                    :crood="crood2"
                    :ratio="preWHRatio"
                    :isOtherPic="true"
                    :height="300"
                    :width="410"></vue-crop>
        </div>
        <save-cancel-btn-group :isSingleSubmit="true"
                               :btnText="'保存'"
                               :singleSubmitType="'dynamic'"
                               @confirmClick="setPicCorp"
                               @cancelClick="clearPicData"></save-cancel-btn-group>
      </div>
    </custom-dialog>
  </div>
</template>

<script>
import { createDomForCutImg, htmlToPCUpLoadImgSimple, convertToMd5 } from '../../../../../../../utils/upLoadTextImg';
export default {
  data () {
    return {
      selectPicUrl: '',          // 选中图片url
      crood2: {},           // 截图坐标之类
      isChgPic: false,
      preWHRatio: 1,
      nextElWidth: 0,
      nextElHeight: 0,
      nextWHRatio: 0,
      selectedCurrentTarget: null,
      picType: '',
      // imgstyleSize: this.imgstyle, // 裁切大小
      imgURlString: this.ImgURl || '',
      index: 0
    };
  },
  props: {
    imgHeight: {// 裁切的图片高度，默认210，
      type: Number,
      default: 210
    },
    imgWidth: {// 裁切的图片宽度，默认320，
      type: Number,
      default: 320
    },
    // imgstyle: {// 默认img样式，
    //   type: String,
    //   default: 'width: 38px;height: 38px;border-radius: 50px'
    // },
    placeholder: {// 水印提示
      type: String,
      default: '上传图片'
    },
    isShowImg: { // 显示上传的图片
      type: Boolean,
      default: false
    },
    ImgURl: String, // 图片地址
    size: String, // '尺寸：600px*336px',
    remarks: String, // '备注'
    imgClass: {
      type: String,
      default: 'WKT_slideshow_upload_content'
    },
    indexKey: {
      type: Number,
      default: 0
    }
  },
  computed: {
    headImg () {
      if (this.imgURlString) {
        if (this.imgURlString.indexOf('http') > -1) {
          return this.imgURlString;
        } else {
          return this.$store.getters.CDNURL + this.imgURlString;
        }
      } else {
        return '';
      }
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../../../../../public/image/no_logo@2x.png') + '"';
    }
  },
  watch: {
    ImgURl: {
      handler (c, o) {
        this.imgURlString = c;
      },
      deep: true
    }
  },
  methods: {
    seletedItem () {
      this.$emit('seletedItem');
    },
    closeChgPic () {
      this.isChgPic = false;
    },
    chooseImg (e, index) {
      let fileName = e.target.files[0].name
      let typeText = '.jpg,.jpeg,.png'
      if (typeText.indexOf(fileName.substring(fileName.toLowerCase().lastIndexOf('.'), fileName.length)) == -1) {
        this.clearPicData();
        layer.alert('上传文件类型不符合，只能上传.jpg,.jpeg,.png类型文件');
        return false
      } else {
        console.log(index, 'chooseImg');
        this.picType = 'img';
        this.index = index || 100;
        this.selectedCurrentTarget = this.$refs.imgFile;
        if (e.target.files.length > 0) {
          // 上传头像
          var reader = new FileReader();	// 实例化一个FileReader对象，用于读取文件
          // 读取File对象的数据
          var that = this;
          reader.onload = function (evt) {
            that.chgPicAfter(that.selectedCurrentTarget, evt.target.result);
          };
          reader.readAsDataURL(e.target.files[0]);
        }
      }
    },
    chgPicAfter (item, url) {
      this.selectPicUrl = url;
      let img = new Image();
      img.src = this.selectPicUrl;
      img.onload = () => {
        this.nextElWidth = img.width;      // 新图片的宽高
        this.nextElHeight = img.height;
        this.nextWHRatio = (this.nextElWidth / this.nextElHeight);   // 新图片的宽高比
        let preElWidth = this.imgWidth;     // 旧图片的宽高
        let preElHeight = this.imgHeight;     // 旧图片的宽高
        this.preWHRatio = (preElWidth / preElHeight); // 旧图片的宽高比
        this.$nextTick(() => {
          this.isChgPic = true;
          // 调用子组件内部的将input值清除, 防止再选中同一个文件没触发事件.
          this.setInputValue();
        });
      };
    },
    // 选中图片渲染后将input里面的值清除, 防止下次选中同一个文件无效.
    setInputValue () {
      var loadInput = this.$el.querySelector('.my_view_chg_index');
      console.log('loadInput', loadInput);
      loadInput.value = '';
    },
    // 获取裁切数据
    afterCrop2 (json, url) {
      this.crood2 = json;
      this.selectPicUrl = url;
      console.log(this.crood2, '获取裁切数据');
    },
    // 确认裁切
    setPicCorp () {
      let saasClientKey = this.$store.getters.token.SaaSClientKey;
      let newImgInfo = { url: this.selectPicUrl, width: this.nextElWidth, height: this.nextElHeight };
      console.log(this.selectedCurrentTarget, this.selectedCurrentTarget.clientWidth, '确认裁切');
      createDomForCutImg(this.selectedCurrentTarget, this.crood2, newImgInfo, (createDom) => {
        htmlToPCUpLoadImgSimple(createDom, saasClientKey, viewContent => {
          console.log(viewContent, '上传');
          if (this.isShowImg) {
            this.imgURlString = viewContent;
          }
          this.setInputValue();
          this.$emit('uploadImgSuccess', viewContent, this.indexKey);   // index为了区分新增还是修改..
          this.clearPicData();
        });
      });
    },
    clearPicData () {
      // document.body.style.overflow = 'auto';
      this.isChgPic = false;
      this.selectPicUrl = '';
      this.preWHRatio = 1;
    }
  }

};
</script>

<style>
/* .my_datum_picture_img_text {
  width: 70px;
  height: 70px;
  border-radius: 4px;
} */
</style>