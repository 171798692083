<template>
  <!-- 基础设置项 -->
  <div>
    <div class="content_marginRL content_marginTop_30">
      <!-- 内容 -->
      <div class="form_info_edit mendian_setting_content  single_shadow"
           :class="{btn_marginBottom_30:isReadonly}">
        <div>
          <input-text :formTitle="'名称'"
                      :readonly="isReadonly"
                      :formPlaceholder="'名称'"
                      :required="true"
                      v-model="dataJson.MainDemoName"></input-text>
          <!-- <div v-if="Number(dataJson.OLAPKey)>0">
            <input-dialog :formTitle="'上级部门'"
                          :formPlaceholder="'上级部门'"
                          class="no_border_bottom"
                          :readonly="isReadonly"
                          :required="true"
                          @showEldilog="showSingleSelectionDialog()"
                          v-model="dataJson.FatherKeyValue"></input-dialog>
          </div> -->

        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <div v-if="!isReadonly"
         class="">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
    <div v-else
         class="on_form_footer_btn_group"></div>
    <custom-dialog :title="'选择上级部门'"
                   class="new_class defult_dialog"
                   :visible.sync="isChildForm"
                   :before-close="doCloseChildForm">

      <single-select-tree :sourceDate="departmentList"
                          :selectionDate="vDataJsonInfo"
                          :disabledType="2"
                          @confirmClcik="doAfterSelectedItem"
                          @cancleClcik="closeSingleSelectionList"></single-select-tree>
    </custom-dialog>
  </div>

</template> 

<script>
import { AddDepartment, EditDepartment, MessageSearchPageForDepartment } from '../../../../API/workbench';
import singleSelectTree from '../../../common/single-select-tree';
export default {
  // 提供外部输出显示，不给就输出内部默认显示
  props: {// 是否显示表单
    isReadonly: {// 是否只读
      type: Boolean,
      default: false
    },
    editItem: {// 修改项
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data () {
    return {
      newInputText: '', // 默认值
      // 提交数据对象
      dataJson: {
        MainDemoName: '', // -- 部门名称
        FatherKey: 0, // --所属部门
        FatherKeyValue: '全部', // -- 所属部门
        RemarksSupport: '' // --备注
      },
      departmentList: '', // 部门列表
      singleSelectionKey: 0,
      // 查询数据对象
      parameter: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配部门名称
        StateKey: 0 // 字符串	可选	0	数据源：GET	小区状态 2 启用 3停用
      },
      isChildForm: false
    };
  },

  components: {
    singleSelectTree
  },

  created () {
    if (JSON.stringify(this.editItem) != '{}') {
      this.dataJson.OLAPKey = this.editItem.OLAPKey;
      this.dataJson.MainDemoName = this.editItem.MainDemoName;
      this.dataJson.FatherKey = this.editItem.FatherKey;
      this.dataJson.FatherKeyValue = this.editItem.FatherKeyValue;
      this.dataJson.RemarksSupport = this.editItem.RemarksSupport;
    }
    MessageSearchPageForDepartment(this.parameter).then(result => {
      console.log(result, '部门查询');
      this.departmentList = result.data.PageDataList;
      this.departmentList.splice(0, 0, {
        OLAPKey: '2',
        MainDemoName: '全部',
        FatherKey: '0',
        FatherKeyValue: '',
        Number: '0',
        StatusKey: '2',
        TypeKey: 1,
        StatusKeyValue: '启用',
        RemarksSupport: ''
      });
    });
  },
  computed: {
    vDataJsonInfo: {
      get () {
        return {
          id: this.dataJson.FatherKey,
          label: this.dataJson.FatherKeyValue
        };
      },
      set (newValue) {
        this.dataJson.FatherKeyValue = newValue.label;
        this.dataJson.FatherKey = newValue.id;
      }
    }
  },
  methods: {
    // 确认按钮
    confirmClick (callBack) {
      let flag = false;
      if (!this.dataJson.MainDemoName.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('请输入名称');
        flag = true;
      } else if (!this.dataJson.FatherKey && !this.dataJson.FatherKeyValue) {
        layer.alert('选择上级部门');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (this.dataJson.FatherKey == 2) {
        this.dataJson.FatherKey = 0;
      }
      if (JSON.stringify(this.editItem) != '{}') {
        EditDepartment(this.dataJson).then(result => {
          console.log(result, '部门修改');
          this.$emit('afterSuccess', 'Edit', result.data);
          this.cancelClick();
          layer.alert('修改成功');
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      } else {
        AddDepartment(this.dataJson).then(result => {
          console.log(result, '部门新增');
          this.$emit('afterSuccess', 'Add', result.data);
          this.cancelClick();
          layer.alert('新增成功');
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      }
    },
    // 取消按钮
    cancelClick () {
      this.$emit('cancelClick');
    },
    // 子组件弹出层显示
    showSingleSelectionDialog () {
      this.isChildForm = true;
    },
    // 子组件弹出层取消
    doCloseChildForm () {
      this.isChildForm = false;
    },
    // 取消
    closeSingleSelectionList () {
      this.isChildForm = false;
    },
    // 确定
    doAfterSelectedItem (item) {
      this.dataJson.FatherKey = item.id;
      this.dataJson.FatherKeyValue = item.label;
      this.isChildForm = false;
      console.log(item, 'doAfterSelectedItem');
    }

  }
};
</script>

  <style>
</style>