<template>
  <div class="all_workbench_center_record_list">
    <div style="display: flex;">
      <div class="list_date">{{vTime}}</div>
      <div class="list_name"
           :class="{'font_red':item.TypeKey==9}">
        <span> {{item.TypeKeyValue}}</span>
        <span v-if="vIsIntention"> {{IntentionValue(item.BeforeIntentionKeyValue)}}→{{IntentionValue(item.IntentionKeyValue)}}</span>
      </div>
    </div>
    <div class="list_text"
         :title="item.Content">
      {{item.Content}}
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  computed: {
    vTime () {
      return this.item.FollowTime.substring(0, 16);
    },
    vIsIntention () {
      if (this.item.BeforeIntentionKeyValue && this.item.IntentionKeyValue && this.item.BeforeIntentionKey != this.item.IntentionKey) {
        return true;
      } else {
        return false;
      }
    }

  },
  methods: {
    IntentionValue (type) {
      if (type == 'S') {
        return 'S 高意向';
      } else if (type == 'A') {
        return 'A 中意向';
      } else if (type == 'B') {
        return 'B 有意向';
      } else if (type == 'C') {
        return 'C 无意向';
      } else if (type == 'N') {
        return 'N 新线索';
      }
    }
  }
};
</script>