<template>
  <div class="upsell_index_content_list"
       :class="item.Number&&item.Number>0?'no_opt':''"
       @click="openStudentFileSalesPage">
    <div class="list_text"
         :class="{no_top:!item.StudentKeyValue}">
      {{item.Content}}
    </div>
    <div class="list_item"
         v-if="item.FineSupport">
      <div class="">
        <span class="font_gray">感兴趣的课：</span>{{item.FineSupport}}
      </div>
    </div>
    <div class="list_item"
         v-if="item.AppointTime">
      <div class="">
        <span class="font_gray">预约到店：</span>{{item.AppointTime?item.AppointTime.slice(0, 10):''}}
      </div>
      <div class="list_type"
           v-if="item.ToStoreResultKey>0"
           :class="item.ToStoreResultKey == 1?'type_green':'type_red'">
        {{vToStoreResultKey}}
      </div>
    </div>
    <div class="list_item"
         v-if="item.ClassTime">
      <div class="">
        <span class="font_gray">{{item.TrialClassResultKey == 1?'试课结果':'预约试课'}}：</span>{{item.ClassTime}} {{item.GradeClassKeyValue}}-{{item.MTeacherKeyValue}}
      </div>
      <div class="list_type"
           v-if="item.TrialClassResultKey>-1"
           :class="item.TrialClassResultKey == 1?'type_green':'type_red'">
        {{vTrialClassResultKey}}
      </div>
    </div>

    <div class="list_item"
         v-if="item.NextFollowUpTime">
      <div class="">
        <span class="font_gray">下次跟进：</span>{{item.NextFollowUpTime}}
      </div>
    </div>

    <div class="list_date"
         v-if="showFollower">
      {{item.FollowUpTime?item.FollowUpTime.slice(5,16):item.FollowUpTime}}丨操作人: {{item.FollowUpKeyValue}}
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object,
    showFollower: {   // 是否显示操作人
      type: Boolean,
      default: true
    }
  },
  computed: {
    // 0-预约;1-已到店;2-未到店;3-已取消
    vToStoreResultKey () {
      if (this.item.ToStoreResultKey == 1) {
        return '已到店';
      } else if (this.item.ToStoreResultKey == 2) {
        return '未到店';
      } else if (this.item.ToStoreResultKey == 3) {
        return '已取消';
      } else {
        return '';
      }
    },
    // 0-预约;1-已试课;2-未试课;3-已取消
    vTrialClassResultKey () {
      if (this.item.TrialClassResultKey == 1) {
        return '已试课';
      } else if (this.item.TrialClassResultKey == 2) {
        return '未试课';
      } else if (this.item.TrialClassResultKey == 3) {
        return '已取消';
      } else {
        return '';
      }
    }
  },
  methods: {
    openStudentFileSalesPage () {
      this.$emit('openStudentFileSalesPage', this.item);
    }
  }
};
</script>

<style scoped>
.upsell_index_content_list {
  padding-left: 38px;
  padding-top: 16px;
  padding-bottom: 13px;
  padding-right: 16px;
  position: relative;
}
.upsell_index_content_list .list_text.no_top {
  margin-top: 0;
  font-size: 14px;
  line-height: 16px;
}
.upsell_index_content_list .list_text {
  margin-bottom: 4px;
}
.upsell_index_content_list:last-child::before {
  bottom: 18px;
}

.upsell_index_content_list:first-child::before {
  top: 19px;
}

.upsell_index_content_list::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 19px;
  width: 1px;
  background-color: #dadde2;
}
.upsell_index_content_list .list_item {
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.upsell_index_content_list .list_date {
  font-size: 12px;
  line-height: 19px;
  margin-top: 12px;
  color: #999999;
}
.upsell_index_content_list .list_item div:first-child {
  max-width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.upsell_index_content_list::after {
  content: '';
  position: absolute;
  left: 15px;
  top: 19px;
  width: 9px;
  height: 9px;
  background-color: #ff6c67;
  border-radius: 50%;
}
.upsell_index_content_list.no_opt::after {
  display: none;
}
.upsell_index_content_list.no_opt::after {
  content: '';
  position: absolute;
  left: 15px;
  top: 19px;
  width: 9px;
  height: 9px;
  background-color: #E7EAEF;
  border-radius: 50%;
}
</style>
