<template>
  <div class="label_bottom_content_material">
    <evaluation-item v-for="(item,key) in dataList"
                     :key="key"
                     :keyIndex="key"
                     :item="item"></evaluation-item>
  </div>
</template>
<script>
import evaluationItem from './evaluation-item';
export default {
  data () {
    return {

    };
  },
  props: {
    dataList: Array
  },
  components: {
    evaluationItem
  }
};
</script>

