<template>
  <div class=" form_info_line el_switch_form_info_field"
       :class="{form_info_required:required}">
    <div class=" form_info_field">
      <span>{{formTitle}}</span>
      <span v-if="helpDescription">
        <input-form-tip :tips='helpDescription'></input-form-tip>
      </span>
    </div>
    <div class=" form_info_value ">
      <el-checkbox v-model="checked"
                   style=""
                   @change="changeChecked(item)"
                   :disabled="checked">
        <span>{{checkedValue}}</span>
      </el-checkbox>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      checked: false
    };
  },
  props: {
    formTitle: {
      type: String,
      required: true
    }, // 标题，必填
    value: [Boolean, String, Number], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改,
    helpDescription: String // 表单说明提示
  },

  computed: {

  },
  created () {
  },
  watch: {

  },
  methods: {
    changeChecked (item) {
      this.$emit('changeChecked', item);
    }
  }
};
</script>

<style>

</style>

