<template>
  <div class="student_list"
       :class="[abnormalStatus,visTemporary,visTemporaryStu,vOpt]"
       @mouseover="showStuDetail($event)"
       @mouseout.stop="closeStuDetail($event)"
       @click.stop="selectedStudent($event)">
    <!-- type_warning  有异常  
         border_dashed  临时学生
         opt  搜索选中效果 
         tl  勾选临时学生开关改变样式-->
    <span>{{stuItem.StudentKeyValue}}</span>
    <!-- typen_loop 循环课学生 -->
    <div class="student_list_number"
         :class="vLoop?'typen_loop':''"
         v-if="isTemporaryType">
      {{!vLoop?Number(stuItem.ClassCount):''}}
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  components: {

  },
  props: {
    stuItem: Object,
    isTemporaryStu: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    abnormalStatus () {
      if (this.stuItem.IsInGradeClassKey == 0 || Number(this.stuItem.DebtClassCount) > 0 || (this.stuItem.CurrentCount <= 0 && this.stuItem.YearCardMaxDayCount <= 0)) {
        return 'type_warning';
      } else {
        return '';
      }
    },
    // 输入框匹配学生
    vOpt () {
      return this.stuItem.isActive ? 'opt' : '';
    },
    vLoop () {
      return (this.isTemporaryType && this.stuItem.IsSyncArrangedKey == 1);
    },
    // 是否临时学生样式
    visTemporary () {
      return (this.isTemporaryType && this.stuItem.IsSyncArrangedKey == 0) ? 'border_dashed' : '';
    },
    // 是否开启临时学生状态样式
    visTemporaryStu () {
      return this.isTemporaryType ? 'tl' : '';
    },
    // 是否开启临时学生
    isTemporaryType () {
      return this.isTemporaryStu;
    }
  },
  created () {

  },
  methods: {
    showStuDetail (e) {
      let Rect = this.$el.getBoundingClientRect();
      let parentRect = this.$parent.$el.getBoundingClientRect();
      // console.log(this.$el, 'showStuDetail', this.$parent);
      this.$emit('setPopUpPosition', e, this.stuItem, Rect.left - parentRect.left, Rect.bottom - parentRect.top, parentRect.top);
    },
    closeStuDetail (e) {
      this.$emit('mouseOverEl', e, this.stuItem);
    },
    selectedStudent (e) {
      let Rect = this.$el.getBoundingClientRect();
      let parentRect = this.$parent.$el.getBoundingClientRect();
      // console.log(Rect, parentRect, 'this.$parent', this.$parent);
      this.$emit('selectedStudent', e, this.stuItem, Rect.left - parentRect.left, Rect.bottom - parentRect.top, parentRect.top);
    }
  }
};
</script>
