<template>
  <div>
    <div class="form_info_edit form_info_list">
      <input-number :min="0"
                    :value="courseCount"
                    :decimalPlaces="1"
                    :formTitle="'最小课时数'"
                    :placeholder="'选填'"
                    :readonly="false"
                    :required="true"
                    @inputChange="afterChangeCurrentCount"></input-number>

    </div>

    <div v-if="isReadonly">
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template> 

<script>
import {
} from '../../../API/workbench.js';
export default {
  data () {
    return {
      courseCount: 0,
      courseCountInfo: {}
    };
  },
  props: {
    isReadonly: { type: Boolean, default: false },
  },
  components: {
  },
  created () {

  },
  mounted () {
    let SaaSClientSetting = this.$store.getters.SaaSClientInfo.SaaSClientSetting;
    console.log('')
    let indexs = SaaSClientSetting.findIndex((item) => {
      return item.SetTypeKey == 129
    })
    this.courseCountInfo = SaaSClientSetting[indexs]
    if (this.courseCountInfo) {
      console.log('courseCountInfo', this.courseCountInfo)
      this.courseCount = Number(this.courseCountInfo.SetContent)
    }
  },
  computed: {},

  methods: {

    afterChangeCurrentCount (val) {
      if (!val) {
        val = 0;
      }
      this.courseCount = val;
    },
    submit (callback) {
      this.courseCountInfo.SetContent = this.courseCount
      this.$emit('submit', this.courseCountInfo);
      this.$emit('closeDialog');
    },
    cancel () {
      this.$emit('closeDialog');
    }
  }
};
</script>
