<template>
  <!-- 公司信息 -->
  <div>
    <div class="YK_added_content_name">
      <span>公司信息</span>
    </div>
    <div class="YK_added_content_form">
      <div class="form_list">
        <div class="form_list_name">
          <span class="text_box">公司名称</span>
        </div>
        <div class="form_list_content">
          {{dataInfo.CompanyName}}
        </div>
      </div>
      <div class="form_list">
        <div class="form_list_name">
          <span class="text_box">公司地址</span>
        </div>
        <div class="form_list_content">
          {{dataInfo.CompanyAddres}}
        </div>
      </div>
      <div class="form_list">
        <div class="form_list_name">
          <span class="text_box">营业执照</span>
        </div>
        <div class="form_list_content">
          <div class="form_list_img"
               @click="showImg">
            <img class="img_box"
                 :src="vBusinessLicense"
                 alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 
<script>
export default {
  props: {
    dataInfo: Object,
  },
  computed: {
    vBusinessLicense() {
      return this.$store.getters.CDNURL + this.dataInfo.BusinessLicense
    },
  },
  methods: {
    showImg() {
      console.log('this.vBusinessLicense', this.vBusinessLicense)
      if (this.vBusinessLicense) {
        window.open(this.vBusinessLicense)
      }
    },
  },
}
</script>

<style scoped>
.form_list_content .form_list_img {
  cursor: pointer;
}
</style>