<template>
  <div class="popup_list_01 popup_list_box">
    <div class="list_date">{{dataInfo.PublishTime}}</div>
    <div class="list_title">{{dataInfo.StageKeyValue}}</div>
    <div class="list_text"> 说明：{{dataInfo.Remark}} </div>
  </div>
</template>
<script>
export default {
  props:{
    dataInfo:Object
  }
};
</script>
<style scoped>
.popup_list_box {
  position: relative;
}

.popup_list_box::after {
  content: "";
  position: absolute;
  left: 24px;
  top: 23px;
  width: 14px;
  height: 14px;
  background: #ffffff;
  border: 2px solid #3498db;
  box-sizing: border-box;
  border-radius: 50%;
  box-shadow: 0 10px 10px 5px #fff;
  z-index: 2;
}

.popup_list_box:last-child::before {
  content: "";
  position: absolute;
  left: 30px;
  top: 37px;
  bottom: 0;
  width: 2px;
  background-color: #fff;
  z-index: 2;
}

.popup_list_01 {
  position: relative;
  padding-left: 54px;
  padding-top: 20px;
  padding-bottom: 36px;
  padding-right: 20px;
}

.popup_list_01 .list_date {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  color: #666;
}

.popup_list_01 .list_title {
  font-size: 14px;
  line-height: 20px;
  padding-left: 14px;
  position: relative;
}

.popup_list_01 .list_title::after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  top: 8px;
  left: 0;
  border-radius: 50%;
  background-color: #333;
}

.popup_list_01 .list_text {
  font-size: 14px;
  line-height: 20px;
  color: #666;
}
</style>