import { fetchToken } from '../utils/fetch'

import basicData from '../assets/basic-data'
import { getSaaSClientSetInfo } from '../utils'
import { OSSUploadFilesPublic } from '../utils/ossupLoadTextImg'
import store from './../store'
// import * as dialog from '../utils/openFactoryDialog.js';
import qs from 'qs'
// 返回当规律下的课(未结束,未取消,未停课),当前学生有排入(未取消即可,请假旷课等都算已安排)  WEBV.StructuredRule.GetCourseAndStudentCourseArrangByRule
export function getCourseAndStudentCourseArrangByRule(StudentID, RuleID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.GetCourseAndStudentCourseArrangByRule',
      StudentID: StudentID,
      RuleID: RuleID
    },
    method: 'GET'
  })
}
// 规律内所有学生安排查询
export function CountStructuredRuleSyncArranged(StructuredRuleKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.CountStructuredRuleSyncArranged',
      StructuredRuleKey
    },
    method: 'GET'
  })
}
// 规律内所有学生安排查询 新
export function GradeCountStructuredRuleSyncArranged(StructuredRuleKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.CountStructuredRuleSyncArranged',
      StructuredRuleKey
    },
    method: 'GET'
  })
}

// 班级课表->规律内学生安排查询 新
export function CoursCountStructuredRuleSyncArranged(StructuredRuleKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.CountStructuredRuleSyncArranged',
      StructuredRuleKey
    },
    method: 'GET'
  })
}

// 排课规律->班级课程学生安排查询  WEBV.StructuredRule.SearchGradeCourseStudentArranged
export function gradeCourseStudentArranged(GradeClassKey, CourseNameKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.SearchGradeCourseStudentArranged',
      GradeClassKey: GradeClassKey,
      CourseNameKey: CourseNameKey
    },
    method: 'GET'
  })
}
// 全部学生列表 WEBV.Student.MessageSearchPageForAllStudent
export function getAllStudentList(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.MessageSearchPageForAllStudent',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      status: data.status,
      QueryID: data.QueryID,
      SubscribeKey: data.SubscribeKey,
      BirthdayMonth: data.BirthdayMonth,
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学生回收站分页查询 WEBV.StudentRecycleBin.MessageSearchPageForRecycleStudent
export function MessageSearchPageForRecycleStudent(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentRecycleBin.MessageSearchPageForRecycleStudent',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText
    },
    method: 'GET'
  })
}

// 学生回收站-修改删除原因 WEBV.StudentRecycleBin.EditRecycleReason
export function EditRecycleReason(StudentKey, RecycleReasonScript) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentRecycleBin.EditRecycleReason',
      StudentKey,
      RecycleReasonScript
    },
    method: 'GET',
    checkResubmit: true
  })
}

// 学生回收站-学生恢复 WEBV.StudentRecycleBin.RecoverStudent
export function RecoverStudent(StudentKey, Reason) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentRecycleBin.RecoverStudent',
      StudentKey,
      Reason
    },
    method: 'GET',
    checkResubmit: true
  })
}

// 根据课信息获取排课规律id WEBV.StructuredRule.GetStructuredRuleByCourseInfo
export function getRuleByCourseInfo(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.GetStructuredRuleByCourseInfo',
      CourseNameKey: data.CourseNameKey || data.CourseKeyName,
      GradeClassKey: data.GradeClassKey,
      WeekKey: data.WeekKey,
      ClassTimeName: data.ClassTimeName
    },
    method: 'GET'
  })
}
// 签到关联学生  WEBV.SignRelate.RelateStudent
export function doRelateStudent(CustKey, OpenID, StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SignRelate.RelateStudent',
      CustKey: CustKey,
      OpenID: OpenID,
      StudentKey: StudentKey
    },
    method: 'GET'
  })
}
// 签到异常->忽略   WEBV.SignWarning.IgnoreSignWarning
export function IgnoreSignWarning(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SignWarning.IgnoreSignWarning',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 签到异常->通用签到 WEBV.SignWarning.DoSignInMutiScheduleCourse
export function DoCommonWarning(OLAPKey, SignList) {
  SignList = {
    SignList: JSON.stringify(SignList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SignWarning.DoCommonWarning',
      OLAPKey: OLAPKey
      // SignList: JSON.stringify(SignList)
    },
    method: 'post',
    data: qs.stringify(SignList),
    checkResubmit: true
  })
}
// 签到异常->多节课签到课 WEBV.SignWarning.DoSignInMutiScheduleCourse
export function DoSignInMutiScheduleCourse(OLAPKey, SignList) {
  SignList = {
    SignList: JSON.stringify(SignList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SignWarning.DoSignInMutiScheduleCourse',
      OLAPKey: OLAPKey
      // SignList: JSON.stringify(SignList)
    },
    method: 'post',
    data: qs.stringify(SignList),
    checkResubmit: true
  })
}
// 签到异常->可安排签到课查询 WEBV.SignWarning.GetExceptionTimeSignInCourse
export function GetExceptionTimeSignInCourse(StudentKey, WarningKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SignWarning.GetExceptionTimeSignInCourse',
      StudentKey: StudentKey,
      WarningKey: WarningKey
    },
    method: 'get'
  })
}
// 签到异常->异常签到获取 WEBV.SignWarning.GetTheSignWarningDetail
export function GetTheSignWarningDetail(WarningKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SignWarning.GetTheSignWarningDetail',
      WarningKey: WarningKey
    },
    method: 'GET'
  })
}
// 签到异常->处理无可签到课  WEBV.SignWarning.DoNoSignInScheduleCourse
export function DoNoSignInScheduleCourse(OLAPKey, SignList) {
  SignList = {
    SignList: JSON.stringify(SignList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SignWarning.DoNoSignInScheduleCourse',
      OLAPKey: OLAPKey
    },
    method: 'post',
    data: qs.stringify(SignList),
    checkResubmit: true
  })
}
// 签到异常->处理无关联学生 WEBV.SignWarning.DoUnrelatedStudent
export function DoUnrelatedStudent(OLAPKey, SignList) {
  SignList = {
    SignList: JSON.stringify(SignList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SignWarning.DoUnrelatedStudent',
      OLAPKey: OLAPKey
    },
    method: 'post',
    data: qs.stringify(SignList),
    checkResubmit: true
  })
}
// 异常签到学生列表  WEBV.SignWarning.MessageSearchPageForSignWarning
export function getSignWarning(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SignWarning.MessageSearchPageForSignWarning',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      startTime: data.startTime,
      endTime: data.endTime,
      QueryID: data.QueryID
    },
    method: 'GET'
  })
}
// 学生排课不足
export function stuArrangeWarn(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ToDo.ToDo_ArrangeWarning',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      searchText: data.searchText,
      orderBy: data.orderBy,
      sequence: data.sequence
    },
    method: 'GET'
  })
}
// 续费 延期
export function setNoRerew(StudentKey, DelayDays) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.NoticeRenewals',
      StudentKey: StudentKey,
      DelayDays: DelayDays
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 学生- 离校 WEBV.Student.LeaveSchool
export function setLeaveSchool(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.LeaveSchool',
      StudentKey: StudentKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 修改门店所有配置
export function updateTheSaaSClientMsg(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.UpdateSaaSClient'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 根据 门店ID获取指定可用优惠券
export function getMendianCoupon(SaaSClientKey, TypeKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'App.Coupon.GetMendianCoupon',
      mdID: SaaSClientKey,
      TypeKey: TypeKey
    },
    method: 'GET'
  })
}
// 修改个人推广活动. WEBV.Employee.EditEmployeePromotionActive
export function editEmployeePromotionActive(OLAPKey, ActiveId, ActiveName) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Employee.EditEmployeePromotionActive',
      OLAPKey: OLAPKey,
      ActiveId: ActiveId,
      ActiveName: ActiveName
    },
    method: 'get',
    checkResubmit: true
  })
}
// 获取员工二维码. WEBV.Employee.GetEmployeeQRCode
export function getEmployeeQRCode(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Employee.GetEmployeeQRCode',
      OLAPKey: OLAPKey
    },
    method: 'get'
  })
}
// 签到本, WEBV.KQ.KQ_AttendanceBook
export function getAttendanceBook(date) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.KQ_AttendanceBook',
      Date: date
    },
    method: 'get'
  })
}
// 课时消耗日统计  WEBV.ReportForm.MessageSummarizeClassCountUsedForDay
export function searchPageClassCountDayStatistical(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassHourAwayReport.MessageSummarizeClassCountUsedForDay',
      startTime: startTime,
      endTime: endTime
    },
    method: 'GET'
  })
}
// 课时消耗明细接口 WEBV.ReportForm.MessageSearchPageForSummarizeClassCount
export function searchPageClassCountDetail(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.ClassHourAwayReport.MessageSearchPageForSummarizeClassCount',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      searchText: data.searchText,
      orderBy: data.orderBy,
      sequence: data.sequence
    },
    method: 'GET'
  })
}
// 新增潜客统计报表 WEBV.ReportForm.MessageSearchPagePotentialCustomer
export function searchPagePotentialCustomer(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerCluesReport.MessageSearchPagePotentialCustomer',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      searchText: data.searchText,
      orderBy: data.orderBy,
      sequence: data.sequence
    },
    method: 'GET'
  })
}
// 报名缴费记录统计报表  WEBV.ReportForm.MessageSearchPageSummarizeKDSY
export function searchPageApplyRecord(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyPaymentReport.MessageSearchPageSummarizeKDSY',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      searchText: data.searchText,
      orderBy: data.orderBy,
      sequence: data.sequence,
      startTime: data.startTime,
      endTime: data.endTime
    },
    method: 'GET'
  })
}
// 开单收银报名业绩按月查询报表.
export function getKDSYForMonth(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyPaymentReport.MessageSummarizeKDSYForMonth',
      startTime: data.startTime,
      endTime: data.endTime
    },
    method: 'get'
  })
}
// 课消金额按周查询报表.
export function getClassCountUsedAmountForWeek(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.ClassHourAwayAmountReport.MessageSummarizeClassCountUsedAmountForWeek',
      startTime: data.startTime,
      endTime: data.endTime
    },
    method: 'get'
  })
}
// 课消金额按月查询报表.
export function getClassCountUsedAmountForMonth(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.ClassHourAwayAmountReport.MessageSummarizeClassCountUsedAmountForMonth',
      startTime: data.startTime,
      endTime: data.endTime
    },
    method: 'get'
  })
}
// 获取考勤报表按月
export function getKQStatisticsForMonth(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AttenceReport.MessageSummarizeAttendanceForMonth',
      startTime: data.startTime,
      endTime: data.endTime,
      CourseKey: data.CourseKey, // 课ID
      GradeKey: data.GradeKey // 班级id
    },
    method: 'get'
  })
}
// 获取考勤报表按月
export function getKQStatisticsForMonthH(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AttenceReport.MessageSummarizeAttendanceForMonthH',
      startTime: data.startTime,
      endTime: data.endTime,
      CourseKey: data.CourseKey, // 课ID
      GradeKey: data.GradeKey // 班级id
    },
    method: 'get'
  })
}
// 获取考勤报表按周
export function getKQStatisticsForWeek(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AttenceReport.MessageSummarizeAttendanceForWeek',
      startTime: data.startTime,
      endTime: data.endTime,
      CourseKey: data.CourseKey, // 课ID
      GradeKey: data.GradeKey // 班级id
    },
    method: 'get'
  })
}
// 课消金额统计-异常课消按日统计
export function getAbnormalClassConsumeReportForDay(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.ClassHourAwayAmountReport.MessageAbnormalClassCountAmountForDay',
      startTime: startTime,
      endTime: endTime
    },
    method: 'GET'
  })
}
// 课消金额统计-返回所有符合条件的异常课时消费记录
export function getAbnormalClassConsumeDetail(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.ClassHourAwayReport.MessageSearchPageForAbnormalClassCount',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      typeKey: data.typeKey,
      DeductTypeKey: data.DeductTypeKey,
      startime: data.startime,
      endtime: data.startime
    },
    method: 'GET'
  })
}
// 校长看板页面
// 异常处理查询 abnormal-handling
export function getAbnormalHandlingList(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Exception.MessageSearchPageForException',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      stutasKey: data.stutasKey,
      typeKey: data.typeKey,
      startTime: data.startime, // 日期开始
      endTime: data.endtime // 日期结束
    },
    method: 'GET'
  })
}
// 预警忽略查询  WEBV.ToDo.MessageSearchPageForIgnoreWarning
export function getIgnoreWarningList(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ToDo.MessageSearchPageForIgnoreWarning',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      startime: data.startime,
      endtime: data.startime
    },
    method: 'GET'
  })
}

// 校长看板统计 WEBV.ReportForm.BasicStatistics
export function getPresidentStatistics() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PrincipalKanBanReport.BasicStatistics'
    },
    method: 'GET'
  })
}
// 该员工待办事项明细.WEBV.ReportForm.TodoForTeacherDetail
export function getTodoDetail(TypeKey, pageIndex, pageSize) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.IndexReport.TodoForTeacherDetail',
      TypeKey: TypeKey, // 分类key: 1-上课;2-未考勤;3-通知;4-工作流;5-待验证;6-待报课
      pageIndex: pageIndex, // 每种最大条数.
      pageSize: pageSize
    },
    method: 'get'
  })
}
// 该员工待办事项统计.WEBV.ReportForm.BacklogStatistics
export function getTodoStatisticsByStaff() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.IndexReport.BacklogStatistics'
    },
    method: 'get'
  })
}
// 根据OLAPKey获取指定员工
export function GetTheEmployeeByID(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Employee.GetTheEmployeeByID',
      OLAPKey: OLAPKey // 员工ID
    },
    method: 'GET'
  })
}
// 预警接口处理：预警统计  WEBV.ToDo.ToDo_WarningStatistics
export function getToDoStatistics() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ToDo.ToDo_WarningStatistics'
    },
    method: 'get'
  })
}
// 首页 -即时业务统计.
export function getIndexReportRealtimeData() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.IndexReport.SaasClientSalePerformanceRealtimeData'
    },
    method: 'GET'
  })
}
// 校长看板-考勤统计 KQ_Summary
export function getKQsummaryStatistics(startTime, endTime, IsCourseReview) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PrincipalKanBanReport.KQ_Summary',
      StartTime: startTime,
      EndTime: endTime,
      IsCourseReview: IsCourseReview
    },
    method: 'GET'
  })
}
// 校长看板-考勤详情按天
export function getKQsummaryForDay(date, IsCourseReview) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PrincipalKanBanReport.KQ_Details',
      Date: date,
      IsCourseReview: IsCourseReview
    },
    method: 'GET'
  })
}
// 万能大课表 按日期查询
export function GetScheduleCourseListByDate(obj, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.GetScheduleCourseListByDate',
      startTime: obj.startTime,
      endTime: obj.endTime,
      ScheduleCourseKey: obj.ScheduleCourseKey,
      IsExportKey: obj.IsExportKey
    },
    method: 'post',
    data: qs.stringify(jsonFormate)
  })
}
// 万能大课表-接口流-----

// 万能大课表 学生查询翻页
export function DKBSearchPageForStudent(PageIndex, PageSize, SearchText) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.MessageSearchPageForStudent',
      PageIndex: PageIndex, // 每页记录数，0：表示查询所有
      PageSize: PageSize, // 页码，0为第一页
      SearchText: SearchText //	搜索条件：模糊匹配姓名、昵称、学号、客户手机号、客户名 课
    },
    method: 'GET'
  })
}
// 万能大课表 老师查询
export function SelectTeach() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.SelectTeach'
    },
    method: 'GET'
  })
}
// 万能大课表 教室查询
export function SearchClassRoom() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.SearchClassRoom'
    },
    method: 'GET'
  })
}
// 万能大课表 班级查询
export function SearchClass() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.SearchClass'
    },
    method: 'GET'
  })
}
// 万能大课表 课名课类查询
export function SelectTheClassType() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.SelectTheClassType'
    },
    method: 'GET'
  })
}

// 万能大课表 按学生查询
export function GetScheduleCourseListByStudent(obj, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.GetScheduleCourseListByStudent',
      startTime: obj.startTime,
      endTime: obj.endTime,
      StudentKey: obj.StudentKey,
      IsExportKey: obj.IsExportKey
    },
    method: 'post',
    data: qs.stringify(jsonFormate)
  })
}

// 万能大课表 按老师查询
export function GetScheduleCourseListByTeacher(obj, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.GetScheduleCourseListByTeacher',
      startTime: obj.startTime,
      endTime: obj.endTime,
      tescherKey: obj.tescherKey,
      IsExportKey: obj.IsExportKey
    },
    method: 'post',
    data: qs.stringify(jsonFormate)
  })
}

// 万能大课表 按课名课类查询
export function GetScheduleCourseListByClassType(obj, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.GetScheduleCourseListByClassType',
      startTime: obj.startTime,
      endTime: obj.endTime,
      classTypeKey: obj.classTypeKey,
      IsExportKey: obj.IsExportKey
    },
    method: 'post',
    data: qs.stringify(jsonFormate)
  })
}

// 万能大课表 按班级查询
export function SearchCourseForClass(obj, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.SearchCourseForClass',
      startTime: obj.startTime,
      endTime: obj.endTime,
      ClassId: obj.ClassId,
      IsExportKey: obj.IsExportKey
    },
    method: 'post',
    data: qs.stringify(jsonFormate)
  })
}

// 万能大课表 按教室查询
export function SearchCourseForClassRoom(obj, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.SearchCourseForClassRoom',
      startTime: obj.startTime,
      endTime: obj.endTime,
      ClassRoomId: obj.ClassRoomId,
      IsExportKey: obj.IsExportKey
    },
    method: 'post',
    data: qs.stringify(jsonFormate)
  })
}

// 校长看板数据
// 机构报名业绩排名统计 WEBV.Employee.RankSaasClientSalePercentage
export function getSalePercentage(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PrincipalKanBanReport.RankSaasClientSalePercentage',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
// 机构教学业绩排名统计 RankSaasClientTeachingPercentage
export function getTeachingPercentage(startTime, endTime, TypeKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PrincipalKanBanReport.RankSaasClientTeachingPercentage',
      startTime: startTime,
      endTime: endTime,
      TypeKey: TypeKey
    },
    method: 'get'
  })
}
// 优惠券营销统计 SummariseCouponMsg
export function getSummariseCouponMsg() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PrincipalKanBanReport.SummariseCouponMsg'
    },
    method: 'get'
  })
}
// 客户线索统计 SummariseStudentRegisterMsg
export function getSummariseStudentRegisterMsg() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PrincipalKanBanReport.SummariseStudentRegisterMsg'
    },
    method: 'get'
  })
}
// 今日运营统计 SummariseTodayCourseMsg
export function getSummariseTodayCourseMsg() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PrincipalKanBanReport.SummariseTodayCourseMsg'
    },
    method: 'get'
  })
}

// 新增线索基本统计 WEBV.ReportForm.MessageSummarizeCustomer
export function getClewStatistics() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerCluesReport.MessageSummarizeCustomer'
    },
    method: 'get'
  })
}
// 系统新增线索统计报表 WEBV.ReportForm.MessageSummarizeSystemRegisterForCustomer
export function getSystemClewReportByMonth(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.CustomerCluesReport.MessageSummarizeSystemRegisterForCustomer',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
// WKT新增线索统计报表 WEBV.ReportForm.MessageSummarizeCustomer
export function getWTKClewReportByMonth(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerCluesReport.MessageSummarizeWTKmobileForCustomer',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
// 老师基本统计 WEBV.ReportForm.MessageSummarizeClassCountUsedForTeacher
export function getTeacherStatistics(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherReport.MessageSummarizeForTeacher',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
// 老师 课消统计 WEBV.ReportForm.MessageSummarizeClassCountUsedForTeacher
export function getClassCountUsedForTeacher(startTime, endTime, type) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherReport.MessageSummarizeClassCountUsedForTeacher',
      startTime: startTime,
      endTime: endTime,
      type: type
    },
    method: 'get'
  })
}
// 老师 考勤 WEBV.ReportForm.MessageSummarizeAttence2ForTeacher
export function getAttenceForTeacher(startTime, endTime, type) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherReport.MessageSummarizeAttence2ForTeacher',
      startTime: startTime,
      endTime: endTime,
      type: type
    },
    method: 'get'
  })
}
// 课消金额统计汇总 MessageSummarizeClassCountUsedAmount
export function getClassCountUsedAmountStatistics() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.ClassHourAwayAmountReport.MessageSummarizeClassCountUsedAmount'
    },
    method: 'get'
  })
}
// WEBV.PrincipalKanBanReport.GetClassAmountAnalysis
export function GetClassAmountAnalysis() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PrincipalKanBanReport.GetClassAmountAnalysis'
    },
    method: 'get'
  })
}
// 课消金额按天统计报表
export function getClassCountUsedAmountReportByDay(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.ClassHourAwayAmountReport.MessageSummarizeClassCountUsedAmountForDay',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
// 课消金额按月共计报表.WEBV.ReportForm.MessageSummarizeClassCountUsedAmountForMonth
export function getClassCountUsedAmountReportByMonth(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.ClassHourAwayAmountReport.MessageSummarizeClassCountUsedAmountForMonth',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
// 课消课时统计汇总 WEBV.ReportForm.MessageSummarizeClassCountUsed
export function getClassCountSpendStatistics() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassHourAwayReport.MessageSummarizeClassCountUsed'
    },
    method: 'get'
  })
}
// 课消课时按天共计报表.WEBV.ReportForm.MessageSummarizeClassCountUsedForDay
export function getClassCountSpendReportByDay(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassHourAwayReport.MessageSummarizeClassCountUsedForDay',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
// 课消课时按月共计报表.WEBV.ReportForm.MessageSummarizeClassCountUsedForMonth
export function getClassCountSpendReportByMonth(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.ClassHourAwayReport.MessageSummarizeClassCountUsedForMonth',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
// 异常处理报表汇总  WEBV.ReportForm.MessageSummarizeException
export function getExceptionStatistics() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ExceptionRecordReport.MessageSummarizeException'
    },
    method: 'get'
  })
}
// 异常处理报表数据 WEBV.ReportForm.MessageSummarizeExceptionForDay
export function getExceptionReport(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ExceptionRecordReport.MessageSummarizeExceptionForDay',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
// 学生统计报表汇总 WEBV.ReportForm.MessageSummarizeStudent
export function getStudentStatistics() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentManageReport.MessageSummarizeStudent'
    },
    method: 'get'
  })
}
// 学生增加报表统计 WEBV.ReportForm.MessageSummarizeIncreaseStudent
export function getAddStudentStatistics(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentManageReport.MessageSummarizeIncreaseStudent',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
// 学生流失报表统计 MessageSummarizeLossStudent
export function getLostStudentStatistics(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentManageReport.MessageSummarizeLossStudent',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
// 获取考勤报表汇总 WEBV.ReportForm.MessageSummarizeKDSY
export function getKQStatistics() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AttenceReport.MessageSummarizeAttendance'
    },
    method: 'get'
  })
}
// 获取考勤报表数据按天 WEBV.ReportForm.MessageSummarizeKDSY
export function getKQStatisticsByDay(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AttenceReport.MessageSummarizeAttendanceForDay',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
// 获取考勤报表数据按天 WEBV.ReportForm.MessageSummarizeKDSY 历史数据
export function getKQStatisticsByDayH(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AttenceReport.MessageSummarizeAttendanceForDayH',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
// 获取考勤报表数据按年
export function getKQStatisticsByMonth(startTime, endTime, ISHistory) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AttenceReport.MessageSummarizeAttendanceForMonth',
      startTime: startTime,
      endTime: endTime,
      ISHistory: ISHistory
    },
    method: 'get'
  })
}
// 获取考勤报表按月 历史数据
export function getKQStatisticsByMonthH(startTime, endTime, ISHistory) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AttenceReport.MessageSummarizeAttendanceForMonthH',
      startTime: startTime,
      endTime: endTime,
      ISHistory: ISHistory
    },
    method: 'get'
  })
}
// 获取报名缴费报表汇总 WEBV.ReportForm.MessageSummarizeKDSY
export function getKDSYStatistics(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyPaymentReport.MessageSummarizeKDSY',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
// 获取指定日期的报名缴费报表数据  WEBV.ReportForm.MessageSummarizeKDSYForDay
export function getKDSYStatisticsDataByDay(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyPaymentReport.MessageSummarizeKDSYForDay',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
// 获取指定月份的报名缴费报表数据 MessageSummarizeKDSYForMonth
export function getKDSYStatisticsDataByMonth(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyPaymentReport.MessageSummarizeKDSYForMonth',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
export function getSummarizeClassCountForGrade(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.GradeReport.MessageSummarizeClassCountUsedForGrade',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
// 获取签到统计数据 MessageSummarizeAttendanceForGrade
export function getSummarizeAttendanceForGrade(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.GradeReport.MessageSummarizeAttendanceForGrade',
      startTime: startTime,
      endTime: endTime
    },
    method: 'get'
  })
}
// 获取班级统计基本数据 MessageSummarizeForGrade
export function getSmmarRizeForGrade() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.GradeReport.MessageSummarizeForGrade'
    },
    method: 'get'
  })
}
// post方式获取
export function ReadSetSave(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.Essay.ReadSetSave',
      SaasClientID: data.SaasClientID
    },
    method: 'POST',
    data: qs.stringify(data)
  })
}

// 获取课列表
export function getScheduleCourseList(ClassTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.ScheduleCourse_List',
      ClassTime
    },
    method: 'GET'
  })
}
// 新增班级
export function GradeClassAdd(
  GradeClassKeyValue,
  WantPeopleCount,
  EnrollmentCount,
  LecturerKeyValue,
  LecturerKey,
  ClassSetKey,
  ClassSetKeyValue,
  GradeTypeKey,
  GradeTypeKeyValue,
  IsSyncGradeClassKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.AddClass',
      GradeClassKeyValue: GradeClassKeyValue,
      WantPeopleCount: WantPeopleCount || '',
      EnrollmentCount: EnrollmentCount || '',
      LecturerKeyValue: LecturerKeyValue || '', //	字符串	必须		数据源：GET	主讲老师
      LecturerKey: LecturerKey || '', //	字符串	必须		数据源：GET	主讲老师ids
      ClassSetKey: ClassSetKey || '', //	字符串	必须		数据源：GET	开设课程id
      ClassSetKeyValue: ClassSetKeyValue || '', //	字符串	必须		数据源：GET	开设课程
      GradeTypeKey: GradeTypeKey,
      GradeTypeKeyValue: GradeTypeKeyValue,
      IsSyncGradeClassKey: IsSyncGradeClassKey || '' //	是否全班一起上课
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 根据班级ID获取班级详细信息.GetTheClassByID
export function GetTheClassByID(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.GetTheClassByID',
      OLAPKey: OLAPKey
    },
    method: 'GET'
  })
}
// 根据班级ID获取所有班内学生  GetTheStudentListInClass
export function GetClassStudentsByID(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.GetTheStudentListInClass',
      OLAPKey: OLAPKey
    },
    method: 'GET'
  })
}
// 班级-获取月统计 GetTheClassStatisticMsgByDate
export function getClassStatisticMsgByDate(OLAPKey, Year, Month) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.GetTheClassStatisticMsgByDate',
      OLAPKey: OLAPKey,
      Year: Year,
      Month: Month
    },
    method: 'GET'
  })
}

// TASK:976 接口：班级-获取班内考勤按课统计 GetTheAttendanceListInCourse
export function GetClassAttendanceListByDate(OLAPKey, year, month) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.GetTheAttendanceListInCourse',
      OLAPKey: OLAPKey,
      Year: year,
      Month: month
    },
    method: 'GET'
  })
}
// 班级-获取班内考勤按月统计
export function GetClassAttendanceList(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.GetTheAttendanceListInClass',
      OLAPKey: OLAPKey
    },
    method: 'GET'
  })
}
// 修改班级计划人数.WEBV.Grade.EditClass
export function editClass(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.EditClass',
      client_id: store.getters.clientId
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 获取 有效 班级 列表
export function getValidClassList() {
  // var signdata = "&signdata={%22APIKey%22:%228a08a1febd44426b8353a54b746d037d%22,%22SignType%22:2,%22UserID%22:82,%22SaaSClientKey%22:10017,%22UserName%22:%2213714926475%22,%22SignTime%22:1524364714,%22ExpirationTime%22:1524365314,%22Sign%22:%221b9aead45d71b1addbdd142f2d757b77a3de26c9%22,%22OtherData%22:{%22HeadImg%22:3333}}"
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.GradeClass.GradeClassList'
    },
    method: 'GET'
  })
}
// 获取 有效教室列表
export function getValidClassroomList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassRoom.GetUsingClassRoom'
    },
    method: 'GET'
  })
}
// 获取教室设置列表
export function GetSchoolRoomSettingList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetSchoolRoomSettingList'
    },
    method: 'GET'
  })
}
// 获取教室设置信息
export function GetSchoolRoomSettingInfo(SchoolRoomKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetSchoolRoomSettingInfo',
      SchoolRoomKey: SchoolRoomKey // 教室key
    },
    method: 'GET'
  })
}
// 提交新建课表-排课计划
export function UpdateSchoolRoomSetting(SchoolRoomKey, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.UpdateSchoolRoomSetting',
      SchoolRoomKey: SchoolRoomKey
    },
    method: 'POST',
    data: qs.stringify(Data)
  })
}
// 获取 客户优惠券列表
export function getMyCouponList(id, mdID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'App.Coupon.GetCouponbyUser',
      jkuserID: id,
      mdID: mdID
    },
    method: 'GET'
  })
}
// 获取在职老师接口
export function geTteacherList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Employee.GetIncumbentTeachEmployee'
    },
    method: 'GET'
  })
}
// 提交新建课表-排课计划
export function geTNewCourseListData(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredPlan.StructuredPlan_Add'
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 获取学员排课表
export function getScheduleCourseOnStudentList(ScheduleCourseKey, ISHistory) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.KQ_ScheduleCourseOnStudent_List',
      ScheduleCourseKey: ScheduleCourseKey,
      ISHistory: ISHistory
    },
    method: 'GET'
  })
}
// 获取下一个风格
export function GetNextStyle() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.GetNextStyle'
    },
    method: 'GET'
  })
}
// //窗口虚拟接口测试
// export function getaddPotentialCustomerList() {
//   return fetchToken({
//     url: process.env.VUE_APP_WKT_API_Url,
//     params: {
//       service: 'WEBV.addPotentialCustomerItem_List',
//     },
//     method: 'GET'
//   })
// }

// 新增 潜客 接口
export function getAddedPotential(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ToDo.ToDo_NewPotentialCustomers',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      searchText: data.searchText,
      orderBy: data.orderBy,
      sequence: data.sequence
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 欠款预警 WEBV.ToDo.ToDo_DebtAmountWarning
export function getDebtAmountWarning(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ToDo.ToDo_DebtAmountWarning',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      searchText: data.searchText,
      orderBy: data.orderBy,
      sequence: data.sequence
    },
    method: 'GET'
  })
}
// 排课 不足 接口
export function getCourseLack(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ToDo.ToDo_StudentArrangeCourseWarn',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      searchText: data.searchText,
      orderBy: data.orderBy,
      sequence: data.sequence
    },
    method: 'GET'
  })
}
// 循环到课 预警
export function toDoClassWarning(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ToDo.ToDo_ClassScheduleBuildingLessonWarning',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      searchText: data.searchText,
      orderBy: data.orderBy,
      sequence: data.sequence
    },
    method: 'GET'
  })
}
// 离校 预警 接口
export function getLeaveWarning(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ToDo.ToDo_LeaveSchoolWarning',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      searchText: data.searchText,
      orderBy: data.orderBy,
      sequence: data.sequence
    },
    method: 'GET'
  })
}
// 流失（未续费）预警 接口
export function getLossWarning(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ToDo.ToDo_LossWarning',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      searchText: data.searchText,
      orderBy: data.orderBy,
      sequence: data.sequence
    },
    method: 'GET'
  })
}
// 欠课时 预警 接口
export function getOweClasshourWarning(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ToDo.ToDo_LackClassHoureWarn',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      searchText: data.searchText,
      orderBy: data.orderBy,
      sequence: data.sequence
    },
    method: 'GET'
  })
}
// 续费 预警 接口
export function getRenewFeeWarning(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ToDo.ToDo_RenewalWarning',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      searchText: data.searchText,
      orderBy: data.orderBy,
      sequence: data.sequence
    },
    method: 'GET'
  })
}
// 预警 忽略接口
export function getWarningIgnore(WarningType, DataKey, reason) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ToDo.ToDo_IgnoreWarning',
      WarningType, // 预警类型(1)
      DataKey: DataKey, // 预警数据ID
      reason: reason
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 单日 课考勤汇总.
export function getScheduleCourseSummary(date) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.ScheduleCourseSummary_List',
      ClassTime: date
    },
    method: 'GET'
  })
}
// 查询上一日课程.
export function getScheduleCourseBefore(ScheduleCourseKey, RowCount) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.ScheduleCourseBefore_List',
      ScheduleCourseKey: ScheduleCourseKey,
      RowCount: RowCount
    },
    method: 'GET'
  })
}
// 查询下一日课程.
export function getScheduleCourseAfter(ScheduleCourseKey, RowCount) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.ScheduleCourseAfter_List',
      ScheduleCourseKey: ScheduleCourseKey,
      RowCount: RowCount
    },
    method: 'GET'
  })
}

// 设置考勤.
export function setAttendance(StudentKey, ScheduleCourseKey, IsClassHourLack) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.KQ_Attendance',
      StudentKey: StudentKey,
      ScheduleCourseKey: ScheduleCourseKey,
      IsClassHourLack: IsClassHourLack
    },
    method: 'GET',
    checkResubmit: true
  })
}

// 考勤变更
export function kqChange(
  StudentKey,
  ScheduleCourseKey,
  IsClassHourLack,
  TargetAttenceStatusKey,
  StudentArrangeKey,
  Reason
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.KQ_Change',
      StudentKey: StudentKey,
      ScheduleCourseKey: ScheduleCourseKey,
      IsClassHourLack: IsClassHourLack,
      TargetAttenceStatusKey: TargetAttenceStatusKey,
      OLAPKey: StudentArrangeKey,
      Reason: Reason
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 考勤删除 KQ_Delete
export function kqDelete(vueObject, StudentKey, ScheduleCourseKey, Reason) {
  if (getSaaSClientSetInfo(122).SetKey == 1) {
    return new Promise((resolve, reject) => {
      // 严格模式
      console.log('PauseScheduleCourse')
      vueObject.$dialog.open(vueObject, {
        routerName: vueObject.$route.name,
        ModulePowerKey: '',
        name: '备注',
        moduleName: 'strictModeReason',
        data: {},
        callBack: {
          afterSuccess: reason => {
            kqDeleteShowDialog(StudentKey, ScheduleCourseKey, reason)
              .then(reuslt => {
                resolve(reuslt)
              })
              .catch(error => {
                reject(error)
              })
          }
        }
      })
      console.log('PauseScheduleCourse2')
    })
  } else {
    // 严格模式
    return kqDeleteShowDialog(StudentKey, ScheduleCourseKey, Reason)
  }
}

// 考勤删除 KQ_Delete
export function kqDeleteShowDialog(StudentKey, ScheduleCourseKey, Reason) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.KQ_Delete',
      StudentKey: StudentKey,
      ScheduleCourseKey: ScheduleCourseKey,
      Reason: Reason
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 考勤请假  KQ_Leave
export function kqLeave(StudentKey, ScheduleCourseKey, IsClassHourLack) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.KQ_Leave',
      StudentKey: StudentKey,
      ScheduleCourseKey: ScheduleCourseKey,
      IsClassHourLack: IsClassHourLack
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 考勤旷课 KQ_Truancy
export function kqTruancy(StudentKey, ScheduleCourseKey, IsClassHourLack) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.KQ_Truancy',
      StudentKey: StudentKey,
      ScheduleCourseKey: ScheduleCourseKey,
      IsClassHourLack: IsClassHourLack
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 考勤销假 KQ_LeaveDestroy
export function kqLeaveDestroy(StudentKey, ScheduleCourseKey, IsClassHourLack) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.KQ_LeaveDestroy',
      StudentKey: StudentKey,
      ScheduleCourseKey: ScheduleCourseKey,
      IsClassHourLack: IsClassHourLack
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 考勤未签到 KQ_NoAttendance
export function kqNoAttendance(StudentKey, ScheduleCourseKey, IsClassHourLack) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.KQ_NoAttendance',
      StudentKey: StudentKey,
      ScheduleCourseKey: ScheduleCourseKey,
      IsClassHourLack: IsClassHourLack
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 获取小区接口.
export function getLivingCommunity() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.getLivingCommunity'
    },
    method: 'GET'
  })
}

// 获取在职老师接口
export function getInJobTeacherList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Employee.GetIncumbentTeachEmployee'
    },
    method: 'GET'
  })
}
// 获取在职员工接口.
export function getInJobSalesList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Employee.GetIncumbentSaleEmployee'
    },
    method: 'GET'
  })
}
// 翻页查询班级分类
export function MessageSearchPageForGradeType(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.MessageSearchPageForGradeType',
      pageIndex: params.pageIndex, // 每页记录数，0：表示查询所有
      pageSize: params.pageSize, // 页码，0为第一页
      searchText: params.searchText, //	搜索条件：模糊匹配姓名、昵称、学号、客户手机号、客户名 课
      orderBy: params.orderBy, // 排序字段
      sequence: params.sequence // 排序字段
    },
    method: 'GET'
  })
}
// 获取班级列表
export function getUsingClass(StatusKey, orderBy, sequence) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.GetUsingClass',
      StatusKey: StatusKey, //	班级状态 4-进行中 5-已关闭
      orderBy: orderBy, // 字符串	可选		数据源：GET	排序字段
      sequence: sequence //	字符串	可选		数据源：GET	排序方向
    },
    method: 'GET'
  })
}
// 新增班级分类
export function AddGradeType(GradeTypeName) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.AddGradeType',
      GradeTypeName: GradeTypeName // 班级分类名称
    },
    method: 'GET'
  })
}
// 修改班级分类
export function EditGradeType(OLAPKey, GradeTypeName) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.EditGradeType',
      OLAPKey: OLAPKey, // 数据id
      GradeTypeName: GradeTypeName // 班级分类名称
    },
    method: 'GET'
  })
}
// 停用班级分类
export function StopClassType(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.StopClassType',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 返回指定课单的所有课消历史
export function getCourseConsumeHistroy(classID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.CourseConsumeHistroyByCursTicket',
      ID: classID
    },
    method: 'GET'
  })
}
// 按时间返回指定课单的所有课消历史 WEBV.Student.CourseConsumeHistroyByMonth
export function timeGetCourseConsumeHistroy(classID, year, month) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.CourseConsumeHistroyByMonth',
      ID: classID,
      year: year,
      month: month
    },
    method: 'GET'
  })
}
// 返回指定学生所有1.未删除 2.未取消 3."是否欠课时"为是 的排课记录 WEBV.Student.MessageSearchCoursePlanForArrearage
export function getPlanForArrearageList(studentID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.MessageSearchCoursePlanForArrearage',
      ID: studentID
    },
    method: 'GET'
  })
}
// 返回指定学生指定年、指定月考勤历史   WEBV.Student.StudentAttendanceHistroyByMonth
export function timeGetStudentAttendanceHistroy(studentID, year, month) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.StudentAttendanceHistroyByMonth',
      ID: studentID,
      year: year,
      month: month
    },
    method: 'GET'
  })
}
// 返回指定学生所有课单
export function getStudentAllCourse(studentID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetStudentAllCourseTicket',
      ID: studentID
    },
    method: 'GET'
  })
}
// 学生课单详情
export function GetStudentXYKDDetails(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetStudentXYKDDetails',
      TableID: parameter.TableID,
      SourceBillKey: parameter.SourceBillKey,
      RN: parameter.RN
    },
    method: 'GET'
  })
}
// 课单考勤课消详情
export function GetXYKDClassHourseForAttendance(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetXYKDClassHourseForAttendance',
      TableID: TableID
    },
    method: 'GET'
  })
}
// 异常课单课消详情
export function GetXYKDClassHourseForUnusual(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetXYKDClassHourseForUnusual',
      TableID: TableID
    },
    method: 'GET'
  })
}
// 返回指定学生所有杂费单
export function GetStudentAllMisChargesTicket(studentID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetStudentAllMisChargesTicket',
      ID: studentID
    },
    method: 'GET'
  })
}
// 学生-课时退款一多项
export function dropMultiCourseApply(DATA) {
  DATA = {
    DATA: JSON.stringify(DATA)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.DropMultiCourseApply',
      client_id: store.getters.clientId
    },
    method: 'POST',
    data: qs.stringify(DATA),
    checkResubmit: true
  })
}
// 返回指定学生信息 WEBV.Student.GetStudentRelatedMsg
export function getStudentRelatedMsg(studentID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetStudentRelatedMsg',
      ID: studentID
    },
    method: 'GET'
  })
}
// 返回指定学生的缴费记录(开单收银 & 商城订单)	WEBV.Student.GetStudentAllTypeTicket
export function getStudentAllTypeTicket(studentID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetStudentAllTypeTicket',
      ID: studentID
    },
    method: 'GET'
  })
}
// 返回该门店所有学生信息 WEBV.Student.MessageSearchPageForStudent(收支录入-客户)
export function messageSearchPageForStudent(
  pageIndex,
  pageSize,
  searchText,
  GradeClassKey,
  CourseKey,
  CustStatusKey,
  SubscribeCourseKey,
  MobliePhone
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.MessageSearchPageForStudent',
      pageIndex: pageIndex, // 每页记录数，0：表示查询所有
      pageSize: pageSize, // 页码，0为第一页
      searchText: searchText, //	搜索条件：模糊匹配姓名、昵称、学号、客户手机号、客户名 课
      GradeClassKey: GradeClassKey || '', // 班级ID
      CourseKey: CourseKey || '', // 课ID
      CustStatusKey: CustStatusKey, // 是否在校 0-否;1-是; 空-全部
      MobliePhone: MobliePhone // 客户手机号
    },
    method: 'GET'
  })
}
// 返回指定学生被安排进的班级 WEBV.Student.ClassStudentBelongTo
export function getStudentHavingClass(studentID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.ClassStudentBelongTo',
      ID: studentID
    },
    method: 'GET'
  })
}
// 返回指定学生有”未上“的课的排课计划 WEBV.Student.GetStudentToBeStartedPlan
export function getStudentToBeStartedPlan(studentID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetStudentToBeStartedPlan',
      ID: studentID
    },
    method: 'GET'
  })
}
// 收支录入-收支账户
export function getIncomeAccount() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.getNormalAccount'
    },
    method: 'GET'
  })
}
// 收支录入-收支账户（总部）
export function hqgetIncomeAccount() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePayAccountManage.GetIncomePayAccount'
    },
    method: 'GET'
  })
}
// 收支录入-收支科目
export function getIncomeSubject() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.GetNormalStatements'
    },
    method: 'GET'
  })
}
// 收支录入-收支科目（总部）
export function hqgetIncomeSubject() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePaySubjectManage.GetIncomePaySubject'
    },
    method: 'GET'
  })
}
// 收支 录入-在职 员工
export function getEnteringStaff() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Employee.GetIncumbentEmployee'
    },
    method: 'GET'
  })
}
// 收支录入-供应商
export function getEnteringSupplier() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.GetCooperativeSupplier'
    },
    method: 'GET'
  })
}
// 收支录入-新增 其它 收支(提交)
export function newlyIncomeAndExpenses(Data) {
  // Data = JSON.stringify(Data)
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SZGL.SZOtherAdd'
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 安排学员上课. WEBV.KQ.StudentCourse_Arrangement
export function courseAddStudent(
  ClassTime,
  ScheduleCourseKey,
  StudentKey,
  AgentKey,
  IsAllowClassHourLackKey,
  IsTrialKey,
  IsMakeupKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.StudentCourse_Arrangement',
      ClassTime: ClassTime,
      ScheduleCourseKey: ScheduleCourseKey,
      StudentKey: StudentKey,
      AgentKey: AgentKey,
      IsAllowClassHourLackKey: IsAllowClassHourLackKey,
      IsTrialKey: IsTrialKey,
      IsMakeupKey: IsMakeupKey
    },
    method: 'GET'
    // checkResubmit: true
  })
}

// 套餐 -有效套餐
export function getCombo() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Package.GetNormalPackageList'
    },
    method: 'GET'
  })
}
// 新增接口：套餐-》查询翻页
export function MessageSearchPageForPackage(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Package.MessageSearchPageForPackage',
      pageIndex: parameter.pageIndex, // 整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式,
      searchText: parameter.searchText, // 字符串	可选		数据源：GET	搜索条件：模糊匹配->套餐名,套餐名首字母
      StatusKey: parameter.StatusKey // 字符串	可选	0	数据源：GET	套餐状态0-全部;2-启用;3-停用
    },
    method: 'GET'
  })
}

// 套餐-》获取
export function GetPackageDetailInfoById(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Package.GetPackageDetailInfoById',
      TableID
    },
    method: 'GET'
  })
}
export function GetSalesList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Employee.GetSalesList'
    },
    method: 'GET'
  })
}

// 新增-套餐
export function AddPackage(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Package.AddPackage'
    },
    method: 'post',
    data: qs.stringify(Data), // json(OLAPKey -- id MainDemoName -- 收款账户名称 AccountTypeKey--账户类型key AccountTypeKeyValue--账户类型value CommentSupport-- 备注)
    checkResubmit: true
  })
}
// 修改-套餐
export function EditPackage(Data, TableID) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Package.EditPackage',
      TableID: TableID
    },
    method: 'post',
    data: qs.stringify(Data), // json(OLAPKey -- id MainDemoName -- 收款账户名称 AccountTypeKey--账户类型key AccountTypeKeyValue--账户类型value CommentSupport-- 备注)
    checkResubmit: true
  })
}
// 启用-套餐
export function StartPackage(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Package.StartPackage',
      TableID
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 停用-套餐
export function StopPackage(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Package.StopPackage',
      TableID
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 杂费-获取所有
export function getIncidentals() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.GetNotDelIncidental'
    },
    method: 'GET'
  })
}

export function getGoingCoursePlanByClass(classID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredPlan.GetOnGoingCoursePlanByClass',
      ID: classID
    },
    method: 'GET'
  })
}
// 将指定学生安排进入指定计划   WEBV.Student.ArrangeStudentToPlan
export function ArrangeStudentToPlan(StudentID, planID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.ArrangeStudentToPlan',
      StudentID: StudentID,
      planID: planID // 排课计划id
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 将指定学生安排从指定计划移除   WEBV.Student.ArrangeStudentToPlan
export function CancelStudentToPlan(StudentID, planID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.CancelStudentToPlan',
      StudentID: StudentID,
      planID: planID // 排课计划id
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 将指定学生从指定班级中移除
export function QuitStudentFromClass(StudentID, ClassID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.QuitStudentFromClass',
      StudentID: StudentID,
      ClassID: ClassID // 班级ID.
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 将指定学生安排进入指定班级（换班）
export function studentAddClass(StudentID, ClassID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.ArrangeStudentToClass',
      StudentID,
      ClassID,
      client_id: store.getters.clientId
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 修改客户信息
export function editCustomerInfo(StudentKey, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.EditStudent',
      StudentKey: StudentKey
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 登记客户
export function registerCustomer(Data, IsContinue) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.AddStudent',
      IsContinue: IsContinue // 是否继续0-否;1-是
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
//  学生-获取所有欠款还款
export function getCustomerBillRecord(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetCustomerBillRecord',
      ID: ID // 客户ID
    },
    method: 'GET'
  })
}
// 返回当前客户待还款金额（待还款 = 总欠款 - 已还款 - 还款中） WEBV.Student.GetCustomerBillDetail
export function getCustomerBillDetail(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetCustomerBillDetail',
      ID: ID // 客户ID
    },
    method: 'GET'
  })
}
// 返回客户可提现金额.
export function cashMoneyOfBeWithdrawaled(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.CashMoneyOfBeWithdrawaled',
      ID: ID // 客户ID
    },
    method: 'GET'
  })
}
// 提现申请. WithdrawalCashApplication
export function withdrawaApplication(ID, StuKey, Money, AccountID, Reason) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.WithdrawalCashApplication',
      CustomerID: ID, // 客户ID
      StuKey: StuKey, // 学生ID
      Money: Money, // 钱.
      AccountID: AccountID, // 收款账户ID.
      Reason: Reason // 提现理由.
    },
    method: 'GET',
    checkResubmit: true
  })
}

// WEBV.Student.RepaymentApplication   还款申请
export function repaymentApplication(ID, Money, AccountID, StuKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.RepaymentApplication',
      CustomerID: ID, // 客户ID
      Money: Money, // 钱.
      AccountID: AccountID, // 收款账户ID.
      StuKey: StuKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 开单收银 （保存 提交)
export function saveBillingAccept(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  console.log(Data)
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KDSY.KDSY',
      client_id: store.getters.clientId
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// ArrangeStudentToClass     将学生加入指定班级.
export function ArrangeStudentToClass(StudentID, ClassID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.ArrangeStudentToClass',
      StudentID: StudentID, // 客户ID
      ClassID: ClassID
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 学生课单转让.  TransferOfCourseTicket
export function StuTransferCourseTicket(
  TableID,
  StuKey,
  Remarks,
  Reason,
  TransferNum
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.TransferOfCourseTicket',
      TableID: TableID, // 课单ID
      StuKey: StuKey, // 转入学生ID
      Remarks: Remarks, // 备注. 暂为空.
      Reason: Reason,
      client_id: store.getters.clientId,
      TransferNum: TransferNum
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 抵扣欠课时. DeductionOwedClassHour
export function deductionOwedClassHour(TableID, CourseKey, Remarks) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.DeductionOwedClassHour',
      TableID: TableID, // 课单ID
      CourseKey: CourseKey, // 课名ID
      Remarks: Remarks // 备注. 暂为空.
    },
    method: 'GET',
    checkResubmit: true
  })
}

export function PauseScheduleCourseShowDialog(
  ScheduleCourseKey,
  IsNotEndKey,
  Reason,
  IsSyncArrangedKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.PauseScheduleCourse',
      ScheduleCourseKey: ScheduleCourseKey, // 课名ID
      IsNotEndKey,
      Reason,
      IsSyncArrangedKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 停课接口
export function PauseScheduleCourse(
  vueObject,
  ScheduleCourseKey,
  IsNotEndKey,
  Reason,
  IsSyncArrangedKey
) {
  if (getSaaSClientSetInfo(125).SetKey == 1) {
    return new Promise((resolve, reject) => {
      // 严格模式
      console.log('PauseScheduleCourse')
      vueObject.$dialog.open(vueObject, {
        routerName: vueObject.$route.name,
        ModulePowerKey: '',
        name: '备注',
        moduleName: 'strictModeReason',
        data: {},
        callBack: {
          afterSuccess: reason => {
            PauseScheduleCourseShowDialog(
              ScheduleCourseKey,
              IsNotEndKey,
              reason,
              IsSyncArrangedKey
            )
              .then(reuslt => {
                resolve(reuslt)
              })
              .catch(error => {
                reject(error)
              })
          }
        }
      })

      console.log('PauseScheduleCourse2')
    })
  } else {
    // 严格模式
    return PauseScheduleCourseShowDialog(
      ScheduleCourseKey,
      IsNotEndKey,
      Reason,
      IsSyncArrangedKey
    )
  }
}
// 最新的获取课详细信息   WEBV.ScheduleCourse.ScheduleCourseDetailed
export function getCourseDetailed(ID, ISHistory) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.getScheduleCourseDetail',
      ID: ID, // 课名ID
      ISHistory: ISHistory
    },
    method: 'GET'
  })
}
// 获取课详细信息   WEBV.ScheduleCourse.ScheduleCourseDetailed
export function ScheduleCourseDetailed(ScheduleCourseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.ScheduleCourseDetailed',
      ScheduleCourseKey: ScheduleCourseKey // 课名ID
    },
    method: 'GET'
  })
}
// 恢复上课.  WEBV.ScheduleCourse.ScheduleCourseRecovery
export function ScheduleCourseRecovery(ScheduleCourseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.RecoverScheduleCourse',
      ScheduleCourseKey: ScheduleCourseKey // 课名ID
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 判断是否可以修改课时. //WEBV.ScheduleCourse.IsAllowedUpdateClassCount
export function AllowedUpdateClassCount(ScheduleCourseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.IsAllowedUpdateClassCount',
      ID: ScheduleCourseKey // 课名ID
    },
    method: 'GET'
  })
}
// 最新 课更新.  课修改//WEBV.ScheduleCourse.UpdateScheduleCourse
export function UpdateScheduleCourse(ID, Data, IsNotEndKey) {
  Data = {
    Data: JSON.stringify(Data),
    ID: ID
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.UpdateScheduleCourse',
      ID: ID,
      IsNotEndKey // 是否不结束 0-否;1-是
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 变更PC端/WKT指定客户的手机号  WEBV.Student.UpdateCustomerTel
export function UpdateCustomerTel(ID, Tel, OLAPKey, Remarks) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.UpdateCustomerTel',
      ID: ID, // 课名ID
      Tel: Tel,
      StuKey: OLAPKey,
      Remarks: Remarks
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 变更指定课单的过期 时间(修改日期)   WEBV.Student.ChangeCourseTicketExpireTime
export function UpdateClassDate(ID, ExpireTime, Remarks, OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.ChangeCourseTicketExpireTime',
      ID: ID, // 课单ID
      ExpireTime: ExpireTime, // 过期日期 eg:YYYY-MM-DD
      Remarks: Remarks, // 备注
      StuKey: OLAPKey,
      client_id: store.getters.clientId
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 变更指定课单的当前 数量(修改数量)
export function UpdateClassCount(ID, NowCount, Remarks, OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.ChangeTicketTheRestCount',
      ID: ID, // 课单ID
      NowCount: NowCount, //	当前数量
      Remarks: Remarks, // 备注
      StuKey: OLAPKey
      // client_id: store.getters.clientId
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 变更指定课单的课程适用 范围 (修改范围)
export function UpdateClassScope(
  ID,
  RangeKey,
  RangeKeyValue,
  Remarks,
  OLAPKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.ChangeCourseTicketUseRange',
      ID: ID, // 课单ID
      RangeKey: RangeKey, // 适用范围IDs eg:123, 124, 125, 126
      RangeKeyValue: RangeKeyValue, // 适用范围 eg:苹果, 香蕉, 橘子, 葡萄
      Remarks: Remarks, // 备注
      StuKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 退课申请
export function quitCourseApply(ID, OLAPKey, Money, Remarks) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.DropCourseApply',
      ID: ID, // 课单ID
      StuKey: OLAPKey,
      Money: Money, // 退课金额
      Remarks: Remarks // 备注
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 微信通用事件消息推送模版
export function microMessagePush(ID, Content) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.WX.SendTmpMsg2WX',
      ID: ID, // 学生ID
      Content: Content // 消息内容：
    },
    method: 'GET'
  })
}
// 获取试课申请列表
export function getTryCourseList(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrialClass.MessageSearchPageForTrialClass',
      PageIndex: parameter.PageIndex, // 页码
      PageSize: parameter.PageSize, // 每页记录数
      StutasKey: parameter.StutasKey, // 2-申请中;3-已通过;4-已安排;5-已取消;6-已拒绝;
      SearchText: parameter.SearchText, // 搜索条件
      CourseNameKey: parameter.CourseNameKey, // 课名
      TableId: parameter.TableId,
      StartTime: parameter.StartTime, // 搜索条件
      EndTime: parameter.EndTime, // 申请日期结束
      OrderBy: parameter.OrderBy, // 排序字段
      Sequence: parameter.Sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 取消试课申请
export function CancelTrialClass(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrialClass.CancelTrialClass',
      ID
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 添加试课申请
export function ApplyTrialClass(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrialClass.ApplyTrialClass',
      ID: parameter.ID, // 学生ID
      ClassTypeKey: parameter.ClassTypeKey, // 课名ID
      SourceKey: parameter.SourceKey, //	预约来源 2:代申请 3:微课堂
      Remarks: parameter.Remarks // 预约要求
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 试课追销课
export function ArrangeTrialClass(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrialClass.ArrangeTrialClass',
      ID: parameter.ID, // 试课预约ID
      CourseKey: parameter.CourseKey, // 课名ID
      IsClassHourLackKey: parameter.IsClassHourLackKey //	是否无课时安排0:否, 1:是
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 获取课 为试课追销准备
export function MessageSearchPageForCourse(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.MessageSearchPageForCourse',
      SearchText: parameter.SearchText, // ,
      CourseNameKey: parameter.CourseNameKey, // 课名ID
      IsClassStopKey: parameter.IsClassStopKey, // 是否停课0: 否; 1: 是; 3: 全部
      StartTime: parameter.StartTime, // 上课日期开始
      EndTime: parameter.EndTime, // 上课日期结束
      OrderBy: parameter.OrderBy, // 排序字段
      Sequence: parameter.Sequence, // 排序字段
      PageIndex: parameter.PageIndex, // 页码，0为第一页
      PageSize: parameter.PageSize //
    },
    method: 'GET'
  })
}
// 学生导入
export function AddCustomerByExcel(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.EXCEL.AddCustomerByExcel'
    },
    method: 'post',
    data: data,
    checkResubmit: true
  })
}
// 课单导入
export function AddTicketByExcel(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.EXCEL.AddTicketByExcel'
    },
    method: 'post',
    data: data,
    checkResubmit: true
  })
}
// 班级翻页查询
export function MessageSearchPageForGrade(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.MessageSearchPageForGrade',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配班级名
      orderBy: queryParams.orderBy, // 排序字段
      sequence: queryParams.sequence, // 排序字段
      stutasKey: queryParams.stutasKey, // 班级状态
      startTime: queryParams.startTime, // 开班开始日期
      endTime: queryParams.endTime, // 开班开始日期
      sEnrollRate: queryParams.sEnrollRate, // 招生率开始
      eEnrollRate: queryParams.eEnrollRate, // 招生率开始
      sAvailableCourse: queryParams.sAvailableCourse, // 招生率开始
      eAvailableCourse: queryParams.eAvailableCourse // 招生率开始
    },
    method: 'GET'
  })
}
// 班级-结班
export function FinishedGrade(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.FinishedGrade',
      OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}

// 报名收款列表查询
export function MessageSearchPageForKDSY(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KDSY.MessageSearchPageForKDSY',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序字段
      sequence: queryParams.sequence, // 排序字段
      IsHiddenKey: queryParams.IsHiddenKey, // 是否隐藏
      BillTypeKey: queryParams.BillTypeKey, // 收款类型
      startTime: queryParams.startTime, // 报名日期开始
      endTime: queryParams.endTime // 报名日期结束
    },
    method: 'GET'
  })
}

// 数据隐藏
export function HideCommonModule(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Common.HideCommonModule',
      dataKey: data.dataKey, // 表ID
      dataName: data.dataName // 事实表名
    },
    method: 'GET',
    checkResubmit: true
  })
}

// 数据显示
export function ShowCommonModule(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Common.ShowCommonModule',
      dataKey: data.dataKey, // 表ID
      dataName: data.dataName // 事实表名
    },
    method: 'GET',
    checkResubmit: true
  })
}

// 客户还款列表查询
export function MessageSearchPageForCustomerRepay(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerRepayment.MessageSearchPageForCustomerRepay',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序字段
      sequence: queryParams.sequence, // 排序字段
      stutasKey: queryParams.stutasKey, // 还款状态
      startTime: queryParams.startTime, // 日期开始
      endTime: queryParams.endTime // 日期结束
    },
    method: 'GET'
  })
}

// 储值管理查询
export function MessageSearchPageForStoreValue(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StoreValue.MessageSearchPageForStoreValue',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序字段
      sequence: queryParams.sequence, // 排序字段
      stutasKey: queryParams.stutasKey, // 还款状态
      typeKey: queryParams.typeKey, // 储值类型
      startTime: queryParams.startTime, // 日期开始
      endTime: queryParams.endTime // 日期结束
    },
    method: 'GET'
  })
}

// 储值管理查询
export function MessageSearchPageForWithdrawCash(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.WithdrawCash.MessageSearchPageForWithdrawCash',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序字段
      sequence: queryParams.sequence, // 排序字段
      stutasKey: queryParams.stutasKey, // 还款状态
      startTime: queryParams.startTime, // 日期开始
      endTime: queryParams.endTime // 日期结束
    },
    method: 'GET'
  })
}

// 异常记录查询
export function MessageSearchPageForException(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Exception.MessageSearchPageForException',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序字段
      sequence: queryParams.sequence, // 排序字段
      stutasKey: queryParams.stutasKey, // 检查状态
      typeKey: queryParams.typeKey, // 处理类型
      startTime: queryParams.startTime, // 日期开始
      endTime: queryParams.endTime, // 日期结束
      IsAttendanceException: queryParams.IsAttendanceException
    },
    method: 'GET'
  })
}

// 学生翻页查询
export function MessageSearchPageForStudent(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.MessageSearchPageForStudent',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序字段
      sequence: queryParams.sequence, // 排序字段
      typeKey: queryParams.typeKey, // 学生类型
      CustStatusKey: queryParams.CustStatusKey, // 客户状态
      SubscribeKey: queryParams.SubscribeKey, // 关注状态
      startTime: queryParams.startTime, // 日期开始
      endTime: queryParams.endTime, // 日期结束
      sRegisterTime: queryParams.sRegisterTime, // 登记日期开始
      eRegisterTime: queryParams.eRegisterTime, // 登记日期结束
      registerTypeKey: queryParams.registerTypeKey // 登记类型
    },
    method: 'GET'
  })
}

// 学生排课-考勤统计查询
export function MessageSearchPageForStudentArrange(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentArrange.MessageSearchPageForStudentArrange',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序字段
      sequence: queryParams.sequence, // 排序字段
      stutasKey: queryParams.stutasKey, // 学生类型
      ISClassHourDebtKey: queryParams.ISClassHourDebtKey, // 是否欠课时
      startTime: queryParams.startTime, // 日期开始
      endTime: queryParams.endTime, // 日期结束
      CourseStatusKey: queryParams.CourseStatusKey // 课状态：0-全部;2-未开始;3-进行中;4-已完结
    },
    method: 'GET'
  })
}

// 课时退款查询
export function MessageSearchPageForBackCourse(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BackCourse.MessageSearchPageForBackCourse',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序字段
      sequence: queryParams.sequence, // 排序字段
      stutasKey: queryParams.stutasKey, // 退课状态
      typeKey: queryParams.typeKey, // 退课方式
      startTime: queryParams.startTime, // 日期开始
      endTime: queryParams.endTime // 日期结束
    },
    method: 'GET'
  })
}

//  课时消费-课消金额考勤查询
export function MessageSearchPageForAttendance(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassCountConsumption.MessageSearchPageForAttendance',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序字段
      sequence: queryParams.sequence, // 排序字段
      stutasKey: queryParams.stutasKey, // 考勤状态
      typeKey: queryParams.typeKey, // 考勤状态
      DeductTypeKey: queryParams.DeductTypeKey, // 课消方式
      startTime: queryParams.startTime, // 日期开始
      endTime: queryParams.endTime // 日期结束
    },
    method: 'GET'
  })
}

//  学生排课-课时消耗查询
export function MessageSearchPageForClassCountUsed(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentArrange.MessageSearchPageForClassCountUsed',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序字段
      sequence: queryParams.sequence, // 排序字段
      stutasKey: queryParams.stutasKey, // 考勤状态
      ISClassHourDebtKey: queryParams.ISClassHourDebtKey, // 是否欠课时
      startTime: queryParams.startTime, // 日期开始
      endTime: queryParams.endTime // 日期结束
    },
    method: 'GET'
  })
}

//  课时消费-课消金额查询
export function classMessageSearchPageForClassCountUsed(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassCountConsumption.MessageSearchPageForClassCountUsed',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序字段
      sequence: queryParams.sequence, // 排序字段
      typeKey: queryParams.typeKey, // 考勤状态
      DeductTypeKey: queryParams.DeductTypeKey, // 是否欠课时
      startTime: queryParams.startTime, // 日期开始
      endTime: queryParams.endTime // 日期结束
    },
    method: 'GET'
  })
}
// 长假停课
export function StopScheduleCourseForVacation(
  isSendNotice,
  startTime,
  endTime,
  content,
  reason,
  IsNotEndKey,
  GradeClassKey,
  IsNotify
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.StopScheduleCourseForVacation',
      isSendNotice: isSendNotice, // 是否发送通知0: 否， 1: 是
      startTime: startTime, // 字符串 必须 数据源： GET 上课日期开始
      endTime: endTime, // 字符串 必须 数据源： GET 上课日期结束
      content: content, // 字符串 可选 数据源： GET 通知内容
      reason: reason, // 字符串 可选 数据源： GET 停课原因
      IsNotEndKey: IsNotEndKey,
      GradeClassKey: GradeClassKey, // 0全校，其他传班级id
      IsNotify: IsNotify // 		0 不通知 1通知
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 长假调课
export function ExchangeScheduleCourseForVacation(
  ExchangeDateA,
  ExchangeDateB,
  reason,
  GradeClassKey,
  IsNotify
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.ExchangeScheduleCourseForVacation',
      ExchangeDateA: ExchangeDateA, //	字符串	必须		数据源：GET	调课日期A
      ExchangeDateB: ExchangeDateB, //	字符串	必须		数据源：GET	调课日期B
      reason: reason, //	字符串	必须		数据源：GET	原因
      GradeClassKey: GradeClassKey, //	整型	必须		数据源：GET	班级id,0为全校
      IsNotify: IsNotify // 		0 不通知 1通知
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 根据手机号查询返回符合条件的学生
export function getStudentByMobileSupport(MobileSupport) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.MessageSearchMobileForStudent',
      MobileSupport
    },
    method: 'GET'
  })
}

// 个人报名业绩
export function GetTheEmployeeSalePercentage(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.IndexReport.GetTheEmployeeSalePercentage',
      OLAPKey: OLAPKey // 员工ID
    },
    method: 'GET'
  })
}

// 个人教学业绩
export function GetTheEmployeeTeachingPercentage(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.IndexReport.GetTheEmployeeTeachingPercentage',
      OLAPKey: OLAPKey // 员工ID
    },
    method: 'GET'
  })
}
// 机构报名业绩
export function GetTheSaaSClientSalePercentage() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.IndexReport.GetTheSaaSClientSalePercentage'
    },
    method: 'GET'
  })
}
// 机构教学业绩
export function GetTheSaaSClientTeachingPercentage() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.IndexReport.GetTheSaaSClientTeachingPercentage'
    },
    method: 'GET'
  })
}
// 异常统计
export function SummaryException() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.IndexReport.SummaryException'
    },
    method: 'GET'
  })
}
// 校长今日统计
export function GetTheSummaryDataByToday() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.IndexReport.GetTheSummaryDataByToday'
    },
    method: 'GET'
  })
}

// -万能大课表 课综合查询
export function IntegratedQueryCourse(
  startTime,
  endTime,
  CourseId,
  TeacherKey,
  ClassId
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.IntegratedQueryCourse',
      startTime: startTime, // 上课日期开始
      endTime: endTime, //	上课日期结束
      CourseId: CourseId, //	课名id,这里是课名id,不支持课类
      TeacherKey: TeacherKey, //	老师id
      ClassId: ClassId // 班级ID
    },
    method: 'GET'
  })
}

// 使用回答查询 SearchUseQuestionsAndAnswers
export function SearchUseQuestionsAndAnswers(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WebTK.WebTkArticle.SearchUseQuestionsAndAnswers',
      pageIndex: data.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: data.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: data.orderBy, //	字符串	可选	id	数据源：GET	排序字段 为空默认为id
      sequence: data.sequence, //	字符串	可选	DESC	数据源：GET	排序方向
      searchText: data.searchText //	字符串	可选		数据源：GET	搜索条件：文章标
    },
    method: 'GET'
  })
}
// -使用说明查询
export function SearchInstructions(
  pageIndex,
  pageSize,
  orderBy,
  sequence,
  searchText
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WebTK.WebTkArticle.SearchInstructions',
      pageIndex: pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: orderBy, //	字符串	可选	id	数据源：GET	排序字段 为空默认为id
      sequence: sequence, //	字符串	可选	DESC	数据源：GET	排序方向
      searchText: searchText //	字符串	可选		数据源：GET	搜索条件：文章标
    },
    method: 'GET'
  })
}
// -使用说明查询
export function SearchInstructionsForData(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WebTK.WebTkArticle.SearchInstructions',
      pageIndex: data.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: data.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: data.orderBy, //	字符串	可选	id	数据源：GET	排序字段 为空默认为id
      sequence: data.sequence, //	字符串	可选	DESC	数据源：GET	排序方向
      searchText: data.searchText //	字符串	可选		数据源：GET	搜索条件：文章标
    },
    method: 'GET'
  })
}
// -最新资讯查询
export function SearchLatestNewsForData(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WebTK.WebTkArticle.SearchLatestNews',
      pageIndex: data.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: data.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: data.orderBy, //	字符串	可选	id	数据源：GET	排序字段 为空默认为id
      sequence: data.sequence, //	字符串	可选	DESC	数据源：GET	排序方向
      searchText: data.searchText //	字符串	可选		数据源：GET	搜索条件：文章标
    },
    method: 'GET'
  })
}
// -最新资讯查询
export function SearchLatestNews(
  pageIndex,
  pageSize,
  orderBy,
  sequence,
  searchText
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WebTK.WebTkArticle.SearchLatestNews',
      pageIndex: pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: orderBy, //	字符串	可选	id	数据源：GET	排序字段 为空默认为id
      sequence: sequence, //	字符串	可选	DESC	数据源：GET	排序方向
      searchText: searchText //	字符串	可选		数据源：GET	搜索条件：文章标
    },
    method: 'GET'
  })
}
// 首页统计-该员工[老师]]待办事项
export function Todo2ForTeacher() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.IndexReport.TodoForTeacher'
    },
    method: 'GET'
  })
}
// 收支管理-审核
export function AuditSZGL(TableID, AuditType) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SZGL.AuditSZGL',
      TableID, // 收支表ID
      AuditType // 审核类型: 0-拒绝; 1-通过
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 收支管理-处理提交
export function DoSZGL(
  TableID,
  SubKey,
  SubKeyValue,
  AccountKey,
  AccountKeyValue,
  Remarks
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SZGL.DoSZGL',
      TableID, // 收支表ID
      SubKey, // 收支科目Key
      SubKeyValue, // 收支科目
      AccountKey, // 收款账户Key
      AccountKeyValue, // 收款账户
      Remarks //	备注
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 收支管理-作废
export function InvalidateSZGL(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SZGL.InvalidateSZGL',
      TableID // 收支表ID
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 收支管理-待审核查询
export function MessageSearchAuditForSZGL() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SZGL.MessageSearchAuditForSZGL'
    },
    method: 'GET'
  })
}
// 收支管理 - 已审核查询
export function MessageSearchAuditedForSZGL(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SZGL.MessageSearchAuditedForSZGL',
      startTime,
      endTime
    },
    method: 'GET'
  })
}
// 收支管理-待处理查询
export function MessageSearchDoForSZGL() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SZGL.MessageSearchDoForSZGL'
    },
    method: 'GET'
  })
}
// 学生验证确认
export function ConfirmedStudentsMerge(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.ConfirmedStudentsMerge',
      ID: ID // 学生ID
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 合并学生档案
export function StudentsMerge(FromStuKey, ToStuKey, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.StudentsMerge',
      FromStuKey: FromStuKey, // 将这个学生合并到To学生
      ToStuKey: ToStuKey // 合并到的学生ID
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}

// 返回该学生指定课名的所有可用课单，及其下的报课计划，课单状态正常，课单未用完
export function GetTheTicketPlanByApplyClassKey(StuKey, ApplyClassKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.GetTheTicketPlanByApplyClassKey',
      StuKey, // 学生ID
      ApplyClassKey // 课名ID
    },
    method: 'get'
  })
}
// 获取指定课单所有排课计划  WEBV.ApplyCourse.GetTheCourseDetails
export function GetTheCourseDetails(LessonKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.GetTheCourseDetails',
      LessonKey: LessonKey
    },
    method: 'get'
  })
}
// 获取重置密码 发送短信
export function LoginForgetPassword(SaaSClientNo, Telphone, SmsCode) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Login.LoginForgetPassword',
      SaaSClientNo: SaaSClientNo, // Telphone
      Telphone: Telphone, // 手机号
      SmsCode: SmsCode // 验证码
    },
    method: 'get'
  })
}
// 获取报课计划详情. WEBV.ApplyCourse.GetTheTicketPlanList
export function getTheTicketPlanList(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.GetTheTicketPlanList',
      ID: ID
    },
    method: 'get'
  })
}
// 提交课单报课计划 WEBV.ApplyCourse.SubmitTicketPlan
export function submitTicketPlan(ID, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.SubmitTicketPlan',
      ID: ID
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 返回所有符合条件的待转课单，未过期，未使用，有待转数量的课单 WEBV.ApplyCourse.MessageSearchPackagePageForStayClass
export function getSearchPageForStayClass(dataObj) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.MessageSearchPackagePageForStayClass',
      pageIndex: dataObj.pageIndex,
      pageSize: dataObj.pageSize,
      searchText: dataObj.searchText,
      orderBy: dataObj.orderBy,
      sequence: dataObj.sequence,
      ID: dataObj.ID
    },
    method: 'get'
  })
}
// 学生指定的有效课单 WEBV.ApplyCourse.GetTheTicketPlanByStudent
export function getStudentTicketPlan(StuKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.GetTheTicketPlanByStudent',
      StuKey: StuKey
    },
    method: 'get'
  })
}
//  课类课名-获取所有
export function GetTheClassType(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.GetTheClassType',
      OLAPKey: OLAPKey // 套餐ID
    },
    method: 'GET'
  })
}
// 校长工作台 -老师详情
export function GetAppointTeacherDetails(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.GetAppointTeacherDetails',
      OLAPKey: OLAPKey // 老师ID
    },
    method: 'GET'
  })
}
// 获取授权大课包listFor老师
export function PrincipalGetAuthBigCoursewarePackageForTeacher(
  OLAPKey,
  DeviceCode
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.GetBigCoursewarePackageForTeacher',
      TeacherKey: OLAPKey, // 老师ID
      DeviceCode: DeviceCode
    },
    method: 'GET'
  })
}
// 获取授权大课包listFor老师
export function GetAuthBigCoursewarePackageForTeacher(OLAPKey, DeviceCode) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.GetBigCoursewarePackageForTeacher',
      TeacherKey: OLAPKey, // 老师ID
      DeviceCode: DeviceCode
    },
    method: 'GET'
  })
}

// 教务门店 获取授权大课包listFor排序列表
export function WEBVGetBigCoursewarePackageForTeacherSort() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_CoursewareManage.GetBigCoursewarePackageForTeacherSort'
    },
    method: 'GET'
  })
}
// 课件门店 获取授权大课包listFor排序列表
export function CWSGetBigCoursewarePackageForTeacherSort() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareManage.GetBigCoursewarePackageForTeacherSort'
    },
    method: 'GET'
  })
}
// 通用组件课件选择For老师 获取大课包
export function getAuthorizationBigCoursewarePackageListForTeacher() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'CWS.TeacherWorkbench.getAuthorizationBigCoursewarePackageListForTeacher'
    },
    method: 'GET'
  })
}

// 通用组件课件选择For老师 获取子课包
export function getAuthorizationCoursewarePackageListForTeacher(
  BigCoursewarePackageKey,
  FailureFilteringKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'CWS.TeacherWorkbench.getAuthorizationCoursewarePackageListForTeacher',
      ParentKey: BigCoursewarePackageKey, // 大课件包id
      FailureFilteringKey: FailureFilteringKey
    },
    method: 'GET'
  })
}

// 通用组件课件选择For老师 获取课件
export function getAuthorizationCoursewareListForTeacher(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.getAuthorizationCoursewareListForTeacher',
      CoursewarePackageKey: CoursewarePackageKey // 子课件包id
    },
    method: 'GET'
  })
}

// 课件门店总部 课件授权管理获取大课件包list
export function GetInUseBigCoursewarePackageList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.GetInUseBigCoursewarePackageList'
    },
    method: 'GET'
  })
}

// 课件门店总部 课件授权管理获取历史大课件包
export function GetHistoryBigCoursewarePackageList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.GetHistoryBigCoursewarePackageList'
    },
    method: 'GET'
  })
}

// 课件门店总部 课件授权管理分页查询 按授权方式
export function SearchPageAuthLogList(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.SearchPageAuthLogList',
      pageIndex: parameter.pageIndex, // 整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式,
      searchText: parameter.searchText, // 字符串	可选		数据源：GET	搜索条件：模糊匹配->套餐名,套餐名首字母
      SchoolTypeKey: parameter.SchoolTypeKey, // 1-直营2-加盟3-课件
      BigCoursewarePackageKey: parameter.BigCoursewarePackageKey, // 大课包key
      StatusKey: parameter.StatusKey, // 状态 0-已授权校区(全部) 1-在用 2-失效校区
      SchoolKey: parameter.SchoolKey, // 校区id
      UseStatusKey: parameter.UseStatusKey // 授权
    },
    method: 'GET'
  })
}

// 获取班级listFor老师
export function GetClassGradeForTeacher(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.GetClassGradeForTeacher',
      TeacherKey: OLAPKey // 老师ID
    },
    method: 'GET'
  })
}
// 老师获取班级listFor老师
export function GetTeacherWhereClass(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.GetTeacherWhereClass',
      TeacherKey: OLAPKey // 老师ID
    },
    method: 'GET'
  })
}
// 校长模式退出班级for老师
export function QuitClassGradeForTeacher(OLAPKey, GradeKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.QuitClassGradeForTeacher',
      TeacherKey: OLAPKey, // 老师ID
      GradeKey: GradeKey // 班级ID
    },
    method: 'GET'
  })
}
// 校长模式添加班级for老师
export function AddClassGradeForTeacher(OLAPKey, GradeKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.AddClassGradeForTeacher',
      TeacherKey: OLAPKey, // 老师ID
      GradeKey: GradeKey // 班级ID
    },
    method: 'GET'
  })
}
// 老师模式添加班级for老师
export function cwsAddClassGradeForTeacher(OLAPKey, GradeKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.AddClassGradeForTeacher',
      TeacherKey: OLAPKey, // 老师ID
      GradeKey: GradeKey // 班级ID
    },
    method: 'GET'
  })
}
// 学生-》变更排课备注
export function EditArrangeSupport(StudentId, ArrangeSupport) {
  let Data = {
    Data: JSON.stringify({
      ArrangeSupport: ArrangeSupport
    })
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentArrange.EditArrangeSupport',
      StudentId: StudentId // 学生ID
    },
    data: qs.stringify(Data),
    method: 'POST',
    checkResubmit: true
  })
}

// 学生报课统计列表
export function MessageSearchTicketPlanPageForStudent(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.MessageSearchTicketPlanPageForStudent',
      pageIndex: parameter.pageIndex, // 页码，0为第一页
      pageSize: parameter.pageSize, // 每页记录数，0：表示查询所有
      searchText: parameter.searchText, // 搜索条件：模糊匹配姓名，姓名首字母
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序字段
      startTime: parameter.startTime, // 最近报名日期开始
      endTime: parameter.endTime, // 最近报名日期结束
      ApplyClassKey: parameter.ApplyClassKey, // 课名ID
      TaoCanKey: parameter.TaoCanKey, // 套餐ID
      QueryID: parameter.QueryID, //	整型	可选	0	数据源：GET	学生ID
      SpecialFilter: parameter.SpecialFilter //	整型	可选	0	数据源：GET	0-不生效;1-按待排>0;2-按待报>0;3-按当前报课<=5;
    },
    method: 'GET'
  })
}
// 课名报课统计详情
export function MessageSearchTicketPlanDetialPageForApplyClass(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.ApplyCourse.MessageSearchTicketPlanDetialPageForApplyClass',
      pageIndex: parameter.pageIndex, // 页码，0为第一页
      pageSize: parameter.pageSize, // 每页记录数，0：表示查询所有
      searchText: parameter.searchText, // 搜索条件：模糊匹配姓名，姓名首字母
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序字段
      ApplyClassKey: parameter.ApplyClassKey, // 课名ID
      TaoCanKey: parameter.TaoCanKey // 套餐ID
    },
    method: 'GET'
  })
}
// 课名报课统计详情(预警)
export function SummariseTheApplyClass(StudentKey, ApplyClassKey, TaoCanKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.SummariseTheApplyClass',
      StudentKey,
      ApplyClassKey, // 课名ID
      TaoCanKey // 套餐ID
    },
    method: 'GET'
  })
}
// 学生报课统计详情;
export function SummariseStudentTicketPlanDetial(
  StudentKey,
  type,
  ApplyClassKeyValue,
  TaoCanKey,
  TaoCanKeyValue
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.GetTheStudentTicketPlanDetial',
      StudentKey: StudentKey, // 学生ID (必填)
      type: type, // 课名ID
      ApplyClassKeyValue: ApplyClassKeyValue, // 课名
      TaoCanKey: TaoCanKey, // 套餐ID
      TaoCanKeyValue: TaoCanKeyValue // 套餐
    },
    method: 'GET'
  })
}
// 指定门店所有套餐list汇总统计
export function SummarisePackageTicketPlan() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.SummarisePackageTicketPlan'
    },
    method: 'GET'
  })
}
// 套餐报课统计
export function MessageSearchPackagePageForApplyClass(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.MessageSearchPackagePageForApplyClass',
      pageIndex: parameter.pageIndex, // 页码，0为第一页
      pageSize: parameter.pageSize, // 每页记录数，0：表示查询所有
      searchText: parameter.searchText, // 搜索条件：模糊匹配姓名，姓名首字母
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序字段
      startTime: parameter.startTime, // 最近报名日期开始
      endTime: parameter.endTime, // 最近报名日期结束
      ApplyClassKey: parameter.ApplyClassKey, // 课程ID
      TaoCanKey: parameter.TaoCanKey // 套餐ID
    },
    method: 'GET'
  })
}
// 套餐报课统计
export function SummarisePackageTicketPlanDetial(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.SummarisePackageTicketPlanDetial',
      TaoCanKey: id // 套餐ID
    },
    method: 'GET'
  })
}
// 获取指定课程[套餐] 待报课时/超报课时/待报人数
export function GetTheApplyClassToBePlaned(TaoCanKey, ApplyClassKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.GetTheApplyClassToBePlaned',
      TaoCanKey, // 套餐ID
      ApplyClassKey // 课程ID
    },
    method: 'GET'
  })
}
// 获取指定班级 待报课时/超报课时/待报人数
export function GetTheGradeClassToBePlaned(GradeClassKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.GetTheGradeClassToBePlaned',
      GradeClassKey // 套餐ID
    },
    method: 'GET'
  })
}
// 获取 课类课名 列表
export function getCourseTypeNameListX(orderBy, sequence, StateKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.GetNotDelCourse',
      orderBy: orderBy, // 字符串	可选		数据源：GET	排序字段
      sequence: sequence, //	字符串	可选		数据源：GET	排序方向.
      StateKey: StateKey // 2-进行中 3-停用
    },
    method: 'GET'
  })
}
// 获取 课类课名 列表
export async function getCourseTypeNameList(id) {
  let APIPromiseCreator = obj => {
    return basicData.loadCourseNameList(true)
  }
  let res = await PromiseCreator(APIPromiseCreator)
  return res
}

// 构造一个Promise
export async function PromiseCreator(APIPromiseCreator) {
  let resultData = await APIPromiseCreator()
  return new Promise((resolve, reject) => {
    try {
      let re = {
        code: 0,
        data: resultData
      }
      resolve(re)
    } catch (exData) {
      console.log('catch', exData)
      reject(exData)
    }
  })
}

/*
  批量同步队列提交
  dataList：需提交的数据源，必有
  APIPromiseCreator：提交API Promise 构建器，参数为 data，List中的一项，必有
  resultCallback：全部提交并返回后，执行回调，参数为所有提交返回结果+提交的数据，例[{result,data},{result,data}...]，必有
  resolve：单项提交返回成功时触发回调，参数为提交返回结果+提交的数据例{result,data},非必有
  reject：单项提交失败时触发回调，参数为提交返回结果+提交的数据例{result,data},非必有
*/
export function BatchSyncSubmit(
  dataList,
  APIPromiseCreator,
  resultCallback,
  resolve,
  reject,
  callBack
) {
  let resultList = []
  if (dataList.length > 0) {
    let index = 0
    let handleResult = (data, r) => {
      resultList.push({
        data,
        r
      })
      index++
      if (index >= dataList.length) {
        if (callBack) {
          callBack()
        }
        resultCallback(resultList)
      } else {
        submit(dataList[index])
      }
    }

    // 提交一条
    let submit = data => {
      APIPromiseCreator(data).then(
        r => {
          if (resolve) {
            resolve(r, data)
          }
          handleResult(data, r)
        },
        r => {
          if (reject) {
            reject(r, data)
          }
          handleResult(data, r)
        }
      )
    }
    submit(dataList[index])
  } else {
    resultCallback(resultList)
  }
}

//  获取此学生指定课名所有报课计划
export function GetTheStudentTicketPlanListByClassType(
  StudentId,
  ClassTypeKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.GetTheStudentTicketPlanListByClassType',
      ID: StudentId, // 学生ID
      ClassTypeKey: ClassTypeKey
    },
    method: 'GET'
  })
}

// 学生已排课查询
export function SearchStudentHasArrangeSupport(
  StudentId,
  CourseId,
  startTime,
  endTime,
  GradeId
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.SearchStudentHasArrangeSupport',
      StudentId: StudentId, // 学生ID
      CourseId: CourseId,
      startTime: startTime,
      endTime: endTime,
      GradeId: GradeId
    },
    method: 'GET'
  })
}

// 多学生排课
export function ArrangeStudents2MutiCourse(StudentList, ScheduleCourseList) {
  var Data = {
    StudentList: JSON.stringify(StudentList),
    ScheduleCourseList: JSON.stringify(ScheduleCourseList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.ArrangeStudents2MutiSameApplyClassCourse'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// ------------------------
// 权限设置-模块查询
export function SearchModulePower() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Authority.SearchModulePower'
    },
    method: 'GET'
  })
}
// 权限设置-角色查询
export function SearchRole() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Authority.SearchRole'
    },
    method: 'GET'
  })
}
// 权限设置-获取角色权限模块
export function GetRole(RoleKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Authority.GetRole',
      RoleKey: RoleKey // 角色key
    },
    method: 'GET'
  })
}
// 权限设置-新增角色
export function AddRole(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Authority.AddRole'
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 角色名 RolePermissionList((ModulePowerKey--模块权限id))--角色权限list),
    checkResubmit: true
  })
}
// 权限设置-修改角色
export function EditRole(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Authority.EditRole'
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 角色名 RolePermissionList((ModulePowerKey--模块权限id))--角色权限list),
    checkResubmit: true
  })
}
// 权限设置-删除角色
export function DeleteRole(RoleKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Authority.DeleteRole',
      RoleKey: RoleKey // 角色Key
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 获取门店所有信息(旧)
export function GetTheSaaSClientMsg() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.GetTheSaaSClientMsg'
    },
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  })
}
// 获取门店所有信息(9.90-新)
export function GetSaaSClientMsg() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.GetSaaSClientMsg'
    },
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  })
}
// 更新员工头像
export function editEmployeeHeadImg(OLAPKey, HeadImgUrl) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Employee.EditEmployeeHeadImg',
      OLAPKey: OLAPKey,
      HeadImgUrl: HeadImgUrl
    },
    method: 'get'
  })
}

// 门店基础设置接口--GOTO
// 添加小区
export function AddArea(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.AddArea'
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 小区名称 remarkSupport-- 备注),
    checkResubmit: true
  })
}
// 添加部门;
export function AddDepartment(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.AddDepartment'
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 部门名称 FatherKey--所属部门key ，0为全部 FatherKeyValue -- 所属部门keyValue RemarksSupport-- 备注)
    checkResubmit: true
  })
}
// 添加杂费
export function AddIncidentals(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.AddIncidentals'
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 杂费名称 UnitName--单位 UnitPrice--单价 RemarksSupport-- 备注)
    checkResubmit: true
  })
}
// 添加收支账户
export function AddIncomePayAccount(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.AddIncomePayAccount'
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 收款账户名称 AccountTypeKey--账户类型key AccountTypeKeyValue--账户类型value CommentSupport-- 备注)
    checkResubmit: true
  })
}
// 添加收支科目
export function AddIncomePaySubject(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.AddIncomePaySubject'
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 科目名称 FatherKey--上级科目 0为全部 CommentSupport-- 备注 IncomePayTypeKey--收支类型Key 1-收;2-支 IncomePayTypeKeyValue收支类型KeyValue 1-收;2-支)
    checkResubmit: true
  })
}
// 添加教室;
export function AddSchoolRoom(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.AddSchoolRoom'
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 教室名称 PeopleCount--建议容纳人数 ClassSupport--适用课程eg:太极拳,少年太极拳,跆拳道中级 ClassTypeSupport--适用课程IDS eg:1074,1075,11094 RemarkSupport-- 备注)
    checkResubmit: true
  })
}
// 添加销售来源
export function AddSourceType(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.AddSourceType'
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 名称 remarkSupport--备注)
    checkResubmit: true
  })
}
// 添加岗位
export function AddStation(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.AddStation'
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 岗位名称 FatherKey--所属部门key ，0为全部 FatherKeyValue -- 所属部门keyValue RemarksSupport-- 备注)
    checkResubmit: true
  })
}
// 添加供应商
export function AddSupplier(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.AddSupplier'
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 全名 SimpleName--简称 PhoneName--手机 SupplyRangeSupport-- 供应范围 RemarksScript--备注)
    checkResubmit: true
  })
}
// 修改小区
export function EditArea(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.EditArea'
    },
    method: 'post',
    data: qs.stringify(Data), // json(OLAPKey -- id MainDemoName--名称 remarkSupport-- 备注)
    checkResubmit: true
  })
}
// 修改部门
export function EditDepartment(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.EditDepartment'
    },
    method: 'post',
    data: qs.stringify(Data), // json(OLAPKey -- id MainDemoName--名称 remarkSupport-- 备注)
    checkResubmit: true
  })
}
// 修改杂费
export function EditIncidentals(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.EditIncidentals'
    },
    method: 'post',
    data: qs.stringify(Data), // json(OLAPKey -- id MainDemoName -- 杂费名称 UnitName--单位 UnitPrice--单价 RemarksSupport-- 备注)
    checkResubmit: true
  })
}
// 修改收支账户
export function EditIncomePayAccount(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.EditIncomePayAccount'
    },
    method: 'post',
    data: qs.stringify(Data), // json(OLAPKey -- id MainDemoName -- 收款账户名称 AccountTypeKey--账户类型key AccountTypeKeyValue--账户类型value CommentSupport-- 备注)
    checkResubmit: true
  })
}

// 修改收支科目
export function EditIncomePaySubject(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.EditIncomePaySubject'
    },
    method: 'post',
    data: qs.stringify(Data), // json(OLAPKey -- id MainDemoName -- 科目名称 FatherKey--上级科目 CommentSupport-- 备注 IncomePayTypeKey--收支类型Key 1-收;2-支 IncomePayTypeKeyValue收支类型KeyValue 1-收;2-支)
    checkResubmit: true
  })
}
// 修改教室
export function EditSchoolRoom(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.EditSchoolRoom'
    },
    method: 'post',
    data: qs.stringify(Data), // json(OLAPKey -- id MainDemoName -- 教室名称 PeopleCount--建议容纳人数 ClassSupport--适用课程eg:太极拳,少年太极拳,跆拳道中级 ClassTypeSupport--适用课程IDS eg:1074,1075,11094 RemarkSupport-- 备注)
    checkResubmit: true
  })
}

// 修改销售来源
export function EditSourceType(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.EditSourceType'
    },
    method: 'post',
    data: qs.stringify(Data), //	json(OLAPKey -- id MainDemoName -- 名称 remarkSupport--备注)
    checkResubmit: true
  })
}
// 修改岗位
export function EditStation(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.EditStation'
    },
    method: 'post',
    data: qs.stringify(Data), // json(OLAPKey -- id MainDemoName--名称 FatherKey--所属部门key ，0为全部 FatherKeyValue -- 所属部门keyValue RemarksSupport-- 备注)
    checkResubmit: true
  })
}
// 修改供应商
export function EditSupplier(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.EditSupplier'
    },
    method: 'post',
    data: qs.stringify(Data), // json(OLAPKey -- id MainDemoName -- 全名 SimpleName--简称 PhoneName--手机 SupplyRangeSupport-- 供应范围 RemarksScript--备注)
    checkResubmit: true
  })
}
// 返回该门店所有小区
export function MessageSearchPageForArea(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.MessageSearchPageForArea',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选	OLAPKey	数据源：GET	排序字段
      sequence: parameter.sequence, // 字符串	可选	desc	数据源：GET	排序字段
      searchText: parameter.searchText, //		字符串	可选		数据源：GET	搜索条件：模糊匹配小区名称
      StateKey: parameter.StateKey // 字符串	可选	0	数据源：GET	小区状态 2 启用 3停用
    },
    method: 'GET'
  })
}

// 返回该门店所有部门
export function MessageSearchPageForDepartment(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.MessageSearchPageForDepartment',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选	OLAPKey	数据源：GET	排序字段
      sequence: parameter.sequence, // 字符串	可选	desc	数据源：GET	排序字段
      searchText: parameter.searchText, //		字符串	可选		数据源：GET	搜索条件：模糊匹配部门名称
      StateKey: parameter.StateKey // 字符串	可选	0	数据源：GET	小区状态 2 启用 3停用
    },
    method: 'GET'
  })
}
// 返回该门店所有杂费
export function MessageSearchPageForIncidentals(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.MessageSearchPageForIncidentals',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选	OLAPKey	数据源：GET	排序字段
      sequence: parameter.sequence, // 字符串	可选	desc	数据源：GET	排序字段
      searchText: parameter.searchText, //		字符串	可选		数据源：GET	搜索条件：模糊匹配
      StateKey: parameter.StateKey // 字符串	可选	0	数据源：GET	小区状态 2 启用 3停用
    },
    method: 'GET'
  })
}
// 返回该门店所有收支账户
export function MessageSearchPageForIncomePayAccount(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.MessageSearchPageForIncomePayAccount',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选	OLAPKey	数据源：GET	排序字段
      sequence: parameter.sequence, // 字符串	可选	desc	数据源：GET	排序字段
      searchText: parameter.searchText, //		字符串	可选		数据源：GET	搜索条件：模糊匹配
      StateKey: parameter.StateKey, // 字符串	可选	0	数据源：GET	小区状态 2 启用 3停用
      AccountTypeKey: parameter.AccountTypeKey // 字符串	可选	0	数据源：GET	账户类型 1-储蓄卡; 2-信用卡;3-支付宝;4-微信;5-现金;6-其它
    },
    method: 'GET'
  })
}
// 返回该门店所有收支科目
export function MessageSearchPageForIncomePaySubject(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.MessageSearchPageForIncomePaySubject',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选	OLAPKey	数据源：GET	排序字段
      sequence: parameter.sequence, // 字符串	可选	desc	数据源：GET	排序字段
      searchText: parameter.searchText, //		字符串	可选		数据源：GET	搜索条件：模糊匹配
      StateKey: parameter.StateKey, // 字符串	可选	0	数据源：GET	小区状态 2 启用 3停用
      IncomePayTypeKey: parameter.IncomePayTypeKey // 	收支类型 1-收;2-支
    },
    method: 'GET'
  })
}
// 返回该门店所有教室
export function MessageSearchPageForSchoolRoom(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.MessageSearchPageForSchoolRoom',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选	OLAPKey	数据源：GET	排序字段
      sequence: parameter.sequence, // 字符串	可选	desc	数据源：GET	排序字段
      searchText: parameter.searchText, //		字符串	可选		数据源：GET	搜索条件：模糊匹配
      StateKey: parameter.StateKey // 字符串	可选	0	数据源：GET	小区状态 2 启用 3停用
    },
    method: 'GET'
  })
}
// 返回该门店所有销售来源
export function MessageSearchPageForSourceType(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.MessageSearchPageForSourceType',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选	OLAPKey	数据源：GET	排序字段
      sequence: parameter.sequence, // 字符串	可选	desc	数据源：GET	排序字段
      searchText: parameter.searchText, //		字符串	可选		数据源：GET	搜索条件：模糊匹配
      StateKey: parameter.StateKey // 字符串	可选	0	数据源：GET	小区状态 2 启用 3停用
    },
    method: 'GET'
  })
}
// 返回该门店所有岗位
export function MessageSearchPageForStation(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.MessageSearchPageForStation',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选	OLAPKey	数据源：GET	排序字段
      sequence: parameter.sequence, // 字符串	可选	desc	数据源：GET	排序字段
      searchText: parameter.searchText, //		字符串	可选		数据源：GET	搜索条件：模糊匹配
      StateKey: parameter.StateKey // 字符串	可选	0	数据源：GET	小区状态 2 启用 3停用
    },
    method: 'GET'
  })
}
// 返回该门店所有供应商
export function MessageSearchPageForSupplier(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.MessageSearchPageForSupplier',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选	OLAPKey	数据源：GET	排序字段
      sequence: parameter.sequence, // 字符串	可选	desc	数据源：GET	排序字段
      searchText: parameter.searchText, //		字符串	可选		数据源：GET	搜索条件：模糊匹配
      StateKey: parameter.StateKey // 字符串	可选	0	数据源：GET	小区状态 2 启用 3停用
    },
    method: 'GET'
  })
}
// 启用小区
export function StartArea(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StartArea',
      OLAPKey: OLAPKey
    },
    method: 'GET'
  })
}
// 启用部门
export function StartDepartment(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StartDepartment',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 启用杂费
export function StartIncidentals(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StartIncidentals',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 启用收支账户
export function StartIncomePayAccount(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StartIncomePayAccount',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 启用收支科目
export function StartIncomePaySubject(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StartIncomePaySubject',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 启用教室
export function StartSchoolRoom(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StartSchoolRoom',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 启用销售来源
export function StartSourceType(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StartSourceType',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 启用岗位
export function StartStation(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StartStation',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}

// 启用供应商
export function StartSupplier(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StartSupplier',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}

// 停用小区
export function StopArea(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StopArea',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}

// 停用部门
export function StopDepartment(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StopDepartment',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 停用杂费
export function StopIncidentals(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StopIncidentals',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 停用收支账户
export function StopIncomePayAccount(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StopIncomePayAccount',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 停用收支科目
export function StopIncomePaySubject(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StopIncomePaySubject',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 停用教室
export function StopSchoolRoom(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StopSchoolRoom',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 停用销售来源
export function StopSourceType(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StopSourceType',
      OLAPKey: OLAPKey
    },
    method: 'GET'
  })
}
// 停用岗位
export function StopStation(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StopStation',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 停用供应商
export function StopSupplier(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.StopSupplier',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 招生管理--在职销售列表
export function GetSaleList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.GetSaleList'
    },
    method: 'GET'
  })
}
// 招生工作台--获取学生档案关联状态
export function GetBindingStatus(StudentThreadKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.GetBindingStatus',
      StudentThreadKey: StudentThreadKey // 学生线索ID
    },
    method: 'GET'
  })
}
// 招生工作台--添加关联
export function RelevancyBillCollectMoney(
  TableID,
  StudentSignUpKey,
  StudentThreadKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.RelevancyBillCollectMoney',
      TableID: TableID,
      StudentSignUpKey: StudentSignUpKey, // 报名记录ID
      StudentThreadKey: StudentThreadKey // 招生线索ID
    },
    method: 'GET'
  })
}
// 招生工作台--取消关联
export function CancelRelevancy(StudentSignUpKey, StudentSignUpSublistKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.CancelRelevancy',
      StudentSignUpKey: StudentSignUpKey, // 报名记录ID
      StudentSignUpSublistKey: StudentSignUpSublistKey // 报名关联ID
    },
    method: 'GET'
  })
}
// 招生工作台--招生线索分配销售
export function StudentThreadAllotSale(
  StudentThreadKey,
  SalesKey,
  SalesKeyValue
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.StudentThreadAllotSale',
      StudentThreadKey: StudentThreadKey, // 线索ID
      SalesKey: SalesKey, // 销售ID
      SalesKeyValue: SalesKeyValue // 销售名称
    },
    method: 'GET'
  })
}
// 招生管理--潜客学生列表
export function GetPotentialCustomerList(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetPotentialCustomerList',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为课程名
      sequence: parameter.sequence, //	字符串	可选	asc	数据源：GET	排序方向 ASC正序 DESC 倒序
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配 课程名
      StudentTypeKey: parameter.StudentTypeKey //	状态 3-潜客2-离校
    },
    method: 'GET'
  })
}
// 招生管理--批量学生转线索
export function StudentTransformThread(params) {
  let Data = JSON.stringify(params)
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.StudentTransformThread',
      Data: Data
    },
    method: 'GET'
  })
}
// 招生管理--添加销售
export function AddRecruitStudentSale(SalesKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.AddRecruitStudentSale',
      SalesKey: SalesKey
    },
    method: 'GET'
  })
}
// 招生管理--删除销售
export function DelRecruitStudentSale(SalesKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.DelRecruitStudentSale',
      SalesKey: SalesKey
    },
    method: 'GET'
  })
}
// 招生管理--标签组和所有下属标签列表
export function GetLabelGroupList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.GetLabelGroupList'
    },
    method: 'GET'
  })
}
// 招生管理--添加标签组
export function AddLabelGroup(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.AddLabelGroup'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 招生管理--修改标签组名称
export function EditLabelGroup(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.EditLabelGroup'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 招生管理--删除标签组
export function DelLabelGroup(LabelGroupKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.DelLabelGroup',
      LabelGroupKey: LabelGroupKey
    },
    method: 'GET'
  })
}
// 招生管理--添加标签
export function AddLabel(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.AddLabel'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 招生管理--修改标签
export function EditLabel(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.EditLabel'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 招生管理--删除标签
export function DelLabel(LabelKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.DelLabel',
      LabelKey: LabelKey
    },
    method: 'GET'
  })
}
// 录入老生
export function MakeUpForOldStudents(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.MakeUpForOldStudents'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 停用供应商
export function CheckStudentExitByNameAndPhone(StudentName, Phone) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.CheckStudentExitByNameAndPhone',
      StudentName,
      Phone
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 新增课类课名
export function AddTheClassType(IsSameContinue, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.AddTheClassType',
      IsSameContinue: IsSameContinue || '0' // 是否同名后继续：0-否;1-是;
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 名称 FatherKey--上级课类Key 0为全部 TypeKey -- 类型key)
    checkResubmit: true
  })
}
// 修改课类课名
export function EditTheClassType(IsSameContinue, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.EditTheClassType',
      IsSameContinue: IsSameContinue || '0' // 是否同名后继续：0-否;1-是;
    },
    method: 'post',
    data: qs.stringify(Data), // json(OLAPKey -- id MainDemoName--名称 FatherKey--上级课类Key 0为全部 UnitPrice--单价)
    checkResubmit: true
  })
}
// 启用课名课类接口
export function StartTheClassType(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.StartTheClassType',
      IsSameContinue: data.IsSameContinue || 0, //	整型	必须		数据源：GET	是否同名后继续：0-否;1-是;
      OLAPKey: data.OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 停用课名课类接口
export function StopTheClassType(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.StopTheClassType',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 返回所有符合条件的课程(不包括课类),指定页指定条数
export function MessageSearchPageForCourseType(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.MessageSearchPageForCourseType',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为课程名
      sequence: parameter.sequence, //	字符串	可选	asc	数据源：GET	排序方向 ASC正序 DESC 倒序
      searchText: parameter.searchText //	字符串	可选		数据源：GET	搜索条件：模糊匹配 课程名
    },
    method: 'GET'
  })
}
// 员工-》查询翻页
export function MessageSearchPageForEmployee(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Employee.MessageSearchPageForEmployee',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号、首字母
      orderBy: parameter.orderBy, //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为姓名
      sequence: parameter.sequence, //	字符串	可选	ASC	数据源：GET	为空则默认顺序; ASC正序;DESC倒序
      IsLeaveKey: parameter.IsLeaveKey //	字符串	可选		数据源：GET	是否在职;空表示该条件不生效;0-离职;1-在职
    },
    method: 'GET'
  })
}
// 返回指定员工信息;
export function GetEmployeeInfo(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Employee.GetEmployeeInfo',
      OLAPKey: OLAPKey //	员工ID
    },
    method: 'GET'
  })
}
// 员工-》新增
export function AddEmployee(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Employee.AddEmployee'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true // json(RoleKey--角色id MainDemoName -- 角色名 RolePermissionList((ModulePowerKey--模块权限id))--角色权限list)
  })
}
// 员工-》修改
export function EditEmployeeInfo(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Employee.EditEmployeeInfo'
    },
    method: 'post',
    data: qs.stringify(Data), // json(RoleKey--角色id MainDemoName -- 角色名 RolePermissionList((ModulePowerKey--模块权限id))--角色权限list),
    checkResubmit: true
  })
}
// 员工-》离职
export function EmployeeDimission(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Employee.EmployeeDimission',
      OLAPKey: OLAPKey //	员工ID
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 员工-》恢复
export function EmployeeRecover(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Employee.EmployeeRecover',
      OLAPKey: OLAPKey //	员工ID
    },
    method: 'GET',
    checkResubmit: true
  })
}

// -------------------------

// 返回所有符合条件的课规律
export function CourseRuleQuery(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.CourseRuleQuery',
      CourseID: parameter.CourseID, //	必须		数据源：GET	课程
      TecherID: parameter.TecherID, //	可选		数据源：GET	老师
      StartTime: parameter.StartTime, //	可选		数据源：GET	开始时间
      EndTime: parameter.EndTime, //	可选		数据源：GET	结束时间
      ClassId: parameter.ClassId
    },
    method: 'GET'
  })
}
export function SuspendClassesByCourseRule(parameter) {
  let Data = {
    DataList: JSON.stringify(parameter.DataList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.SuspendClassesByCourseRule',
      CourseID: parameter.CourseID, //	必须		数据源：GET	课程
      TecherID: parameter.TecherID, //	可选		数据源：GET	老师
      StartTime: parameter.StartTime, //	可选		数据源：GET	开始时间
      EndTime: parameter.EndTime, //	可选		数据源：GET	结束时间
      ClassId: parameter.ClassId
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 通过id进行停课
export function SuspendClassesByID(scheduleCourseList) {
  let Data = {
    ScheduleCourseList: JSON.stringify(scheduleCourseList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.SuspendClassesByID'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 返回所有符合条件的课详情
export function ClassBatchQuery(parameter) {
  let Data = {
    DataList: JSON.stringify(parameter.DataList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.ClassBatchQuery',
      CourseID: parameter.CourseID, //	必须		数据源：GET	课程
      TecherID: parameter.TecherID, //	可选		数据源：GET	老师
      StartTime: parameter.StartTime, //	可选		数据源：GET	开始时间
      EndTime: parameter.EndTime, //	可选		数据源：GET	结束时间
      ClassId: parameter.ClassId
    },
    method: 'post',
    data: qs.stringify(Data)
  })
}
// 根据课规律变更老师
export function ChangeMTeaKeyNameByCourseRule(parameter) {
  let Data = {
    DataList: JSON.stringify(parameter.DataList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.ChangeMTeaKeyNameByCourseRule',
      CourseID: parameter.CourseID, //	必须		数据源：GET	课程
      TecherID: parameter.TecherID, //	可选		数据源：GET	老师
      StartTime: parameter.StartTime, //	可选		数据源：GET	开始时间
      EndTime: parameter.EndTime, //	可选		数据源：GET	结束时间
      ClassId: parameter.ClassId,
      TecherKey: parameter.TecherKey,
      TecherKeyValue: parameter.TecherKeyValue
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 通过id变更老师
export function ChangeMTeaKeyNameByID(parameter) {
  let Data = {
    ScheduleCourseList: JSON.stringify(parameter.ScheduleCourseList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.ChangeMTeaKeyNameByID',
      TecherKey: parameter.TecherKey,
      TecherKeyValue: parameter.TecherKeyValue
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 根据课规律变更上课时间
export function ChangeClassTimeByCourseRule(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.ChangeClassTimeByCourseRule',
      CourseID: parameter.CourseID, //	必须		数据源：GET	课程
      TecherID: parameter.TecherID, //	可选		数据源：GET	老师
      StartTime: parameter.StartTime, //	可选		数据源：GET	开始时间
      EndTime: parameter.EndTime, //	可选		数据源：GET	结束时间
      ClassId: parameter.ClassId,
      WeekKey: parameter.WeekKey, // 原周几 1-周一;2-周二;3-周三;4-周四;5-周五;6-周六;7-周日
      SClassTime: parameter.SClassTime, // 原上课时间 eg:'09:02'
      EClassTime: parameter.EClassTime, // 原下课时间 eg:'10:02'
      NewWeekKey: parameter.NewWeekKey, // 新周几 1-周一;2-周二;3-周三;4-周四;5-周五;6-周六;7-周日
      NewSClassTime: parameter.NewSClassTime, // 新上课时间 eg:'09:02'
      NewEClassTime: parameter.NewEClassTime // 新下课时间 eg:'10:02'
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 通过id变更上课时间
export function ChangeClassTimeByID(parameter) {
  let Data = {
    ScheduleCourseList: JSON.stringify(parameter.ScheduleCourseList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.ChangeClassTimeByID',
      NewWeekKey: parameter.NewWeekKey, // 新周几 1-周一;2-周二;3-周三;4-周四;5-周五;6-周六;7-周日
      NewSClassTime: parameter.NewSClassTime, // 新上课时间 eg:'09:02'
      NewEClassTime: parameter.NewEClassTime // 新下课时间 eg:'10:02'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 新建循环课
export function AddCirculatedScheduleCourse(params) {
  let Data = {
    Data: JSON.stringify(params)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.AddCirculatedScheduleCourse',
      client_id: store.getters.clientId
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 新建单课
export function AddSingleScheduleCourse(params) {
  let Data = {
    Data: JSON.stringify(params)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredPlan.AddSingleScheduleCourse'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 终止排课计划
export function TerminatedPlan(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.TerminatedPlan',
      ID
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 修改排课计划
export function EditPlan(params) {
  let Data = {
    Data: JSON.stringify(params)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.EditCirculatedScheduleCourse'
    },
    method: 'post',
    data: qs.stringify(Data)
    // checkResubmit: true
  })
}
// 单学生多排课计划[同课名]加入排课
export function ArrangeStudent2MutiSameApplyClassStructuredPlan(
  StudentKey,
  IsClassHourLackKey,
  StructuredPlanList,
  IsSyncArrangedKey
) {
  let Data = {
    StructuredPlanList: JSON.stringify(StructuredPlanList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.ArrangeStudent2MutiSameApplyClassStructuredPlan',
      StudentKey,
      IsClassHourLackKey, //		数据源：GET	是否无课时0-否;1-是;
      IsSyncArrangedKey,
      client_id: store.getters.clientId
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 多学生多排课计划[同课名]移除排课
export function CancelStudents2MutiSameApplyClassStructuredPlan(
  StudentList,
  StructuredPlanList
) {
  let Data = {
    StudentList: JSON.stringify(StudentList),
    StructuredPlanList: JSON.stringify(StructuredPlanList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.CancelStudents2MutiSameApplyClassStructuredPlan',
      client_id: store.getters.clientId
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}

//  单学生单课 排课信息
export function MessageSearchPageForAvailableScheduleCourse(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.MessageSearchPageForAvailableScheduleCourse',
      pageIndex: params.pageIndex, //	数据源：GET	页码，0为第一页
      pageSize: params.pageSize, //	数据源：GET	每页记录数，0：表示查询所有
      searchText: params.searchText, //		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号、客户名
      orderBy: params.orderBy, //		数据源：GET	排序字段
      sequence: params.sequence, //		数据源：GET	排序字段
      startTime: params.startTime, //		数据源：GET	上课日期开始 eg:2020-01-01
      endTime: params.endTime, //		数据源：GET	上课日期结束 eg:2020-01-01
      studentkey: params.studentkey, //	数据源：GET	学生id
      courseid: params.courseid
    },
    method: 'GET'
  })
}
//  单学生单课加入排课
export function ArrangeStudent2SingleCourse(
  StudentKey,
  IsClassHourLackKey,
  ScheduleCourseKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.ArrangeStudent2SingleCourse',
      StudentKey, // 学生Id
      IsClassHourLackKey, // 是否欠课时0-否;1-是;
      ScheduleCourseKey // 课Key
    },
    method: 'GET',
    checkResubmit: true
  })
}

// 批量请假
export function LeaveForBatch(
  StudentKey,
  ISClassHourDebtKey,
  ScheduleCourseKeyList
) {
  let Data = {
    ScheduleCourseKeyList: JSON.stringify(ScheduleCourseKeyList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.KQ_LeaveForBatch',
      StudentKey, // 学生Id
      ISClassHourDebtKey // 是否允许欠课时
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}

// 单学生多课加入排课[同课名]
export function ArrangeStudent2MutiSameApplyClassCourse(parameter) {
  //   array[ obj{"OLAPKey"=>111, "CourseNameKey"=>112} ]
  let Data = {
    ScheduleCourseList: JSON.stringify(parameter.ScheduleCourseList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.ArrangeStudent2MutiSameApplyClassCourse',
      StudentKey: parameter.StudentKey, //		数据源：GET	学生Key
      IsClassHourLackKey: parameter.IsClassHourLackKey, //		数据源：GET	是否无课时0-否;1-是;
      client_id: store.getters.clientId
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 单学生多课移除排课
export function CancelStudent2MutiCourse(parameter) {
  let Data = {
    ScheduleCourseList: JSON.stringify(parameter.ScheduleCourseList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.CancelStudent2MutiCourse',
      StudentKey: parameter.StudentKey, //		数据源：GET	学生Key,
      client_id: store.getters.clientId
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 合并学生列表
export function SearchForWaitVerificationStudent(
  pageIndex,
  pageSize,
  searchText,
  GradeClassKey,
  CourseKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.SearchForWaitVerificationStudent',
      pageIndex: pageIndex, // 每页记录数，0：表示查询所有
      pageSize: pageSize, // 页码，0为第一页
      searchText: searchText, //	搜索条件：模糊匹配姓名、昵称、学号、客户手机号、客户名 课
      orderBy: '', // 班级ID
      sequence: '' // 课ID
    },
    method: 'GET'
  })
}

// 获取当前班级的排课规律课表  WEBV.StructuredRule.GetStructuredRuleByGrade
export function getStructuredRuleByGrade(GradeID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.GetStructuredRuleByGrade',
      GradeID: GradeID
    },
    method: 'get'
  })
}

// 获取学生所有排课规律的已排课
export function getStudentArrangRuleByGrade(StudentID, GradeID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.GetStudentArrangRuleByGrade',
      StudentID: StudentID,
      GradeID: GradeID
    },
    method: 'get'
  })
}

// 返回当规律下的课(未结束,未取消,未停课),当前学生有排入(未取消即可,请假旷课等都算已安排)
export function GetCourseAndStudentCourseArrangByRule(StudentID, RuleID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.GetCourseAndStudentCourseArrangByRule',
      StudentID, // 学生Id
      RuleID // 规律ID
    },
    method: 'GET'
  })
}
// 单学生多课规律排课
export function ArrangeStudent2MutiSameApplyClassCourseByRule(parameter) {
  let Data = {
    ScheduleCourseKeyList: JSON.stringify(parameter.ScheduleCourseKeyList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.ArrangeStudent2MutiSameApplyClassCourseByRule',
      StudentKey: parameter.StudentKey, //		数据源：GET	学生Key
      StructuredRuleKey: parameter.StructuredRuleKey, //		数据源：GET	排课规律Key
      StartTime: parameter.StartTime, // GET	开始日期 format:yyyy-mm-dd
      EndTime: parameter.EndTime, // 字符串	必须		数据源：GET	结束日期 format:yyyy-mm-dd
      ClassCount: parameter.ClassCount, //		数据源：GET	排课节数
      IsSyncArrangedKey: parameter.IsSyncArrangedKey,
      IsClassHourLackKey: parameter.IsClassHourLackKey, //		数据源：GET	是否无课时0-否;1-是;
      client_id: store.getters.clientId
    },
    method: 'post',
    data: qs.stringify(Data)
  })
}

//  返回指定学生的综合信息
export function GetStudentGeneralMessage(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetStudentGeneralMessage',
      StudentKey
    },
    method: 'GET'
  })
}
//  学生课程考勤排课获取
export function GetClassAttendanceOfStudents(
  StudentKey,
  CourseKey,
  startTime,
  endTime
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetClassAttendanceOfStudents',
      StudentKey,
      CourseKey,
      startTime,
      endTime
    },
    method: 'GET'
  })
}
// 学生课程考勤综合获取
export function GetCompositeStudentCourseAttendance(StudentKey, CourseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetCompositeStudentCourseAttendance',
      StudentKey,
      CourseKey
    },
    method: 'GET'
  })
}
// 返回指定学生的报课课消信息
export function GetStudentApplyCourseExpend(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetStudentApplyCourseExpend',
      StudentKey: StudentKey
    },
    method: 'GET'
  })
}
// 学生-》学生课单消耗明细查询
export function GetTicketExpenseDetail(StudentKey, TicketKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetTicketExpenseDetail',
      StudentKey: StudentKey,
      TicketKey: TicketKey
    },
    method: 'GET'
  })
}
// 学生-》学生课程课消查询
export function GetStudentCoursesHourExpense(StudentKey, CourseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetStudentCoursesHourExpense',
      StudentKey: StudentKey,
      CourseKey: CourseKey
    },
    method: 'GET'
  })
}

// 课名报课统计详情(预警)
export function GetTheTicketMsg(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KDSY.GetTheTicketMsg',
      TableID
    },
    method: 'GET'
  })
}

// 获取重叠的课 同一班级GradeClassKey ，同一课程CourseNameKey ，同一日期上课 StartTime EedTime  ClassTimeName  WeekKey
export function GetExpectedOverlap(params) {
  let Data = {
    Data: JSON.stringify(params)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.GetExpectedOverlap'
    },
    method: 'post',
    data: qs.stringify(Data)
  })
}
// 返回该规律下,所有正常的(未删除,未取消,未停课,未结束),重叠的课
export function AccessToOverlap(RuleID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.AccessToOverlap',
      RuleID
    },
    method: 'GET'
  })
}

// 学生-》上课安排学生查询
export function MessageSearchPageForClassArrangementStudent(
  pageIndex,
  pageSize,
  searchText,
  orderBy,
  sequence,
  gradeID,
  courseid,
  isALlStudent,
  ClassTime,
  QueryID
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.MessageSearchPageForClassArrangementStudent',
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchText: searchText,
      orderBy: orderBy,
      sequence: sequence,
      gradeID: gradeID,
      courseid: courseid,
      isALlStudent: isALlStudent,
      ClassTime: ClassTime,
      QueryID: QueryID
    },
    method: 'GET'
  })
}

// 学生-》更新头像
export function UpdateHeadImgSupport(id, ImgUrl) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.UpdateHeadImgSupport',
      ID: id, // 学生ID
      ImgUrl: ImgUrl
    },
    method: 'GET'
  })
}
// 登录页面 获取 电脑 电视 手机下载地址
export function GetBrandDownUrl(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BrandCustomManage.GetBrandDownUrl',
      OLAPKey: OLAPKey // 门店ID
    },
    method: 'GET'
  })
}
// 根据手机验证码登录
export function LoginInByMobile(Telphone, SmsCode, fromType) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Login.LoginInByMobile',
      Telphone,
      SmsCode,
      fromType
    },
    method: 'GET',
    checkResubmit: true
  })
}

// 登录识别码申请
export function ApplyLoginDistinguishCode(clientID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Login.ApplyLoginDistinguishCode',
      client_id: clientID
    },
    method: 'GET'
  })
}
// 上传文件
export function UploadFiles(type, md5, file, fileData) {
  return OSSUploadFilesPublic(type, md5, file, fileData)
  // if (isTest() && !file.get('file')) {
  //   let re = {
  //     code: 400,
  //     data: {
  //       url: ''
  //     },
  //     msg: '非法请求：缺少上传文件：file'
  //   };
  //   return new Promise((resolve, reject) => {
  //     try {
  //       reject(re);
  //     } catch (exData) {
  //       reject(re);
  //     }
  //   });
  // }
  // if (file.size > 1024 * 1024 * 10) {
  //   return new Promise((resolve, reject) => {
  //     uploadMultipart(file, type, null).then(res => {
  //       resolve(res);
  //     }, err => {
  //       reject(err);
  //     });
  //   });
  // } else {
  //   return fetchToken({
  //     url: process.env.VUE_APP_WKT_API_Url,
  //     params: {
  //       service: 'WEB.Files.Upload',
  //       type: type, //	整型	必须		数据源：GET	1-图;2-视频; 3-音频; 4-文本图; 5-字体文件;
  //       s: 'WEBVAPIUploadFiles', //	来源;
  //       md5: md5 //	字符串	可选		数据源：GET	文件识别码
  //     },
  //     method: 'post',
  //     data: file,
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     },
  //     checkResubmit: true
  //   });
  // }
}
export function AdminUploadFiles(type, md5, file, SaaSClientNameKey, fileData) {
  console.log(type, md5, file, 'UploadFiles')
  return OSSUploadFilesPublic(type, md5, file, fileData)
  // if (isTest() && !file.get('file')) {
  //   let re = {
  //     code: 400,
  //     data: {
  //       url: ''
  //     },
  //     msg: '非法请求：缺少上传文件：file'
  //   };
  //   return new Promise((resolve, reject) => {
  //     try {
  //       reject(re);
  //     } catch (exData) {
  //       reject(re);
  //     }
  //   });
  // }
  // return fetchToken({
  //   url: process.env.VUE_APP_WKT_API_Url,
  //   params: {
  //     service: 'Admin.Files.Upload',
  //     type: type, //	整型	必须		数据源：GET	1-图;2-视频; 3-音频; 4-文本图; 5-字体文件;
  //     SaaSClientNameKey: SaaSClientNameKey, //	来源;
  //     md5: md5 //	字符串	可选		数据源：GET	文件识别码
  //   },
  //   method: 'post',
  //   data: file,
  //   headers: {
  //     'Content-Type': 'multipart/form-data'
  //   },
  //   checkResubmit: true
  // });
}
// 上传文件 需要显示文件进度
export function UploadFilesShowProgress(type, md5, file, fileData) {
  return OSSUploadFilesPublic(type, md5, file, fileData)
  // if (file.size > 1024 * 1024 * 10) {
  //   return new Promise((resolve, reject) => {
  //     uploadMultipart(file, type, fileData).then(res => {
  //       resolve(res);
  //     }, err => {
  //       reject(err);
  //     });
  //   });
  // } else {
  //   return fetchToken({
  //     url: process.env.VUE_APP_WKT_API_Url,
  //     params: {
  //       service: 'WEB.Files.Upload',
  //       type: type, //	整型	必须		数据源：GET	1-图;2-视频; 3-音频; 4-文本图; 5-字体文件;
  //       s: 'WEBVAPIUploadFilesShowProgress', //	来源;
  //       md5: md5 //	字符串	可选		数据源：GET	文件识别码
  //     },
  //     method: 'post',
  //     data: file,
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     },
  //     onUploadProgress (progressEvent) {
  //       if (fileData && progressEvent.lengthComputable) {
  //         fileData.percent = (progressEvent.loaded / progressEvent.total * 100).toFixed(0);
  //         console.log('onUploadProgress', progressEvent, fileData.percent);
  //       }
  //     }
  //   });
  // }
}

// 通过门店id获取WKT门店信息
export function GetMenDianInfoByMenDianId(saasclientid) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.MenDian.GetMenDianInfoByMenDianId',
      gl_saasclientid: saasclientid
    },
    method: 'GET'
  })
}

// 更新WKT门店信息
export function EditMenDian(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.MenDian.EditMenDian'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}

// 微信--获取模板
export function SelectTemplate() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.WeChatOperation.SelectTemplate'
    },
    method: 'GET'
  })
}
// 更新模板信息
export function UpdateTemplate() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.WeChatOperation.UpdateTemplate'
    },
    method: 'GET'
  })
}
// 微信--获取模板消息设置
export function GetTemplateMsg(eventId) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.WeChatOperation.GetTemplateMsg',
      eventId: eventId
    },
    method: 'GET'
  })
}
// 新增或修改模板消息设置
export function AddOrEditTemplateMsg(parameter) {
  let Data = {
    incontent: JSON.stringify(parameter.incontent)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.WeChatOperation.AddOrEditTemplateMsg',
      eventId: parameter.eventId,
      id: parameter.id,
      templateId: parameter.templateId,
      url: parameter.url
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 发送测试模板消息
export function SendTestTemplateMsg(parameter) {
  let Data = {
    param: JSON.stringify(parameter.param)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.WeChatOperation.SendTestTemplateMsg',
      tempID: parameter.tempID,
      eventId: parameter.eventId,
      url: parameter.url,
      phone: parameter.phone
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 获取事件动态标签
export function getEventParamet(eventId) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.WeChatOperation.getEventParamet',
      eventId: eventId
    },
    method: 'GET'
  })
}
// 单学生多课[同课名]移除排课
export function CancelStudent2MutiSameApplyClassCourseByRule(
  vueObject,
  StudentKey,
  StructuredRuleKey,
  IsSyncArrangedKey,
  Reason
) {
  if (getSaaSClientSetInfo(123).SetKey == 1) {
    return new Promise((resolve, reject) => {
      // 严格模式
      console.log('PauseScheduleCourse')
      vueObject.$dialog.open(vueObject, {
        routerName: vueObject.$route.name,
        ModulePowerKey: '',
        name: '备注',
        moduleName: 'strictModeReason',
        data: {},
        callBack: {
          afterSuccess: reason => {
            CancelStudent2MutiSameApplyClassCourseByRuleShowDialog(
              StudentKey,
              StructuredRuleKey,
              IsSyncArrangedKey,
              reason
            )
              .then(reuslt => {
                resolve(reuslt)
              })
              .catch(error => {
                reject(error)
              })
          }
        }
      })
      console.log('PauseScheduleCourse2')
    })
  } else {
    // 严格模式
    return CancelStudent2MutiSameApplyClassCourseByRuleShowDialog(
      StudentKey,
      StructuredRuleKey,
      IsSyncArrangedKey,
      Reason
    )
  }
}

// 单学生多课[同课名]移除排课
export function CancelStudent2MutiSameApplyClassCourseByRuleShowDialog(
  StudentKey,
  StructuredRuleKey,
  IsSyncArrangedKey,
  Reason
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.CancelStudent2MutiSameApplyClassCourseByRule',
      StudentKey,
      StructuredRuleKey,
      IsSyncArrangedKey,
      Reason,
      client_id: store.getters.clientId
    },
    method: 'GET',
    checkResubmit: true
  })
}

// 获取事件动态标签
export function GetQRcode(CustKey, sceneType) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.WeChatOperation.GetQRcode',
      CustKey: CustKey,
      scene_type: sceneType
    },
    method: 'GET'
  })
}

// 增加门店端日志记录接口
export function AddInterfaceLog(data) {
  let Data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Common.AddInterfaceLog'
    },
    method: 'post',
    data: qs.stringify(Data)
  })
}

// 同步更新请假旷课是否扣课时
export function SynchronousUpdateIsLeaveOrIsCharges(type) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.SynchronousUpdateIsLeaveOrIsCharges',
      type: type
    },
    method: 'GET'
  })
}

// 老师统计-老师教学业绩按时间区间统计
export function MessageSearchTeachingPerformance(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherReport.MessageSearchTeachingPerformance',
      startTime: parameter.startTime,
      endTime: parameter.endTime,
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 老师统计-老师教学业绩详情
export function GetTeachingPerformanceDetails(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherReport.GetTeachingPerformanceDetails',
      startTime: parameter.startTime,
      endTime: parameter.endTime,
      TecherId: parameter.TecherId,
      CourseId: parameter.CourseId
    },
    method: 'GET'
  })
}
// -欠还款统计
export function MessageSearchDebtRepayment(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentManageReport.MessageSearchDebtRepayment',
      pageIndex: parameter.pageIndex, // 整型	可选	0	数据源：GET	 页码，0为第一页
      pageSize: parameter.pageSize, // 整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
      searchText: parameter.searchText, // 字符串	可选		数据源：GET	   搜索条件：模糊匹配姓名，姓名首字母,手机号
      orderBy: parameter.orderBy, // 字符串	可选		数据源：GET	 排序字段
      sequence: parameter.sequence // 字符串	可选		数据源：GET	  排序顺序
    },
    method: 'GET'
  })
}
// -欠课时统计
export function MessageSearchDebtClassHour(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentManageReport.MessageSearchDebtClassHour',
      pageIndex: parameter.pageIndex, // 整型	可选	0	数据源：GET	 页码，0为第一页
      pageSize: parameter.pageSize, // 整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
      searchText: parameter.searchText, // 字符串	可选		数据源：GET	   搜索条件：模糊匹配姓名，姓名首字母,手机号
      orderBy: parameter.orderBy, // 字符串	可选		数据源：GET	 排序字段
      sequence: parameter.sequence, // 字符串	可选		数据源：GET	  排序顺序
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// 返回指定学生综合信息 WEBV.Student.GetStudentCommonMsg
export function GetStudentCommonMsg(studentID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetStudentCommonMsg',
      StudentKey: studentID
    },
    method: 'GET'
  })
}
// 返回指定学生综合信息 WEBV.Student.GetStudentCommonMsg
export function GetStudentCommonMsgTwo(StudentKeyValue) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetStudentCommonMsg2',
      StudentKeyValue: StudentKeyValue
    },
    method: 'GET'
  })
}
// 获取循环课表
export function GetStructuredRuleByCourseName(CourseNameKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.GetStructuredRuleByCourseName',
      CourseNameKey
    },
    method: 'get'
  })
}
// 获取学生所有排课规律的已排课
export function GetStructuredRuleByCourseNameStudent(
  StudentKey,
  CourseNameKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.GetStructuredRuleByCourseNameStudent',
      StudentKey,
      CourseNameKey
    },
    method: 'get'
  })
}
// 班级报课统计
export function SumarizehGradeTicketPlan(QueryID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.SumarizehGradeTicketPlan',
      QueryID: QueryID
    },
    method: 'GET'
  })
}
// 课程报课统计
export function SumarizehApplyClassTicketPlan(QueryID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.SumarizehApplyClassTicketPlan',
      QueryID: QueryID
    },
    method: 'GET'
  })
}

// 套餐报课统计
export function SumarizeTaocanTicketPlan(QueryID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.SumarizeTaocanTicketPlan',
      QueryID: QueryID
    },
    method: 'GET'
  })
}
// 套餐课程报课统计
export function SumarizehTaocanApplyClassTicketPlan(TaoCanKey, QueryID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.SumarizehTaocanApplyClassTicketPlan',
      TaoCanKey, // 套餐ID
      QueryID // 课程ID
    },
    method: 'GET'
  })
}

// 套餐课程报课翻页详情
export function MessageSearchTicketPlanPageForTaoCanApplyClass(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.ApplyCourse.MessageSearchTicketPlanPageForTaoCanApplyClass',
      pageIndex: parameter.pageIndex, // 整型	可选	0	数据源：GET	 页码，0为第一页
      pageSize: parameter.pageSize, // 整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
      searchText: parameter.searchText, // 字符串	可选		数据源：GET	   搜索条件：模糊匹配姓名，姓名首字母,手机号
      orderBy: parameter.orderBy, // 字符串	可选		数据源：GET	 排序字段
      sequence: parameter.sequence, // 字符串	可选		数据源：GET	  排序顺序
      SpecialFilter: parameter.SpecialFilter, // 必须 0-不生效;1-按待排>0;2-按待报>0;3-按当前报课<=5;
      TaoCanKey: parameter.TaoCanKey,
      ApplyClassKey: parameter.ApplyClassKey, // 必须
      QueryID: parameter.QueryID // 学生ID
    },
    method: 'GET'
  })
}
// 课程报课翻页详情
export function MessageSearchTicketPlanPageForApplyClass(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.MessageSearchTicketPlanPageForApplyClass',
      pageIndex: parameter.pageIndex, // 整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, // 字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, // 字符串	可选		数据源：GET	排序顺序
      ApplyClassKey: parameter.ApplyClassKey, // 整型	必须	0	数据源：GET	课程ID
      SpecialFilter: parameter.SpecialFilter, // 整型	必须	0	数据源：GET	0-不生效;1-按待排>0;2-按待报>0;3-按当前报课<=5;
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配 单号 学生名 学生首字母 手机号
      QueryID: parameter.QueryID // 整型	可选	0	数据源：GET	学生ID
    },
    method: 'GET'
  })
}
// 班级课程报课翻页详情
export function MessageSearchTicketPlanPageForGradeApplyClass(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.MessageSearchTicketPlanPageForGradeApplyClass',
      pageIndex: parameter.pageIndex, // 整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, // 整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, // 字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, // 字符串	可选		数据源：GET	排序顺序
      ApplyClassKey: parameter.ApplyClassKey, // 整型	必须	0	数据源：GET	课程ID
      GradeClassKey: parameter.GradeClassKey, // 整型	必须	0	数据源：GET	班级ID
      SpecialFilter: parameter.SpecialFilter, // 整型	必须	0	数据源：GET	0-不生效;1-按待排>0;2-按待报>0;3-按当前报课<=5;
      QueryID: parameter.QueryID, // 整型	可选	0	数据源：GET	学生ID
      searchText: parameter.searchText, // 字符串	可选		数据源：GET	搜索条件：模糊匹配 单号 学生名 学生首字母 手机号
      IsInGradeClassKey: parameter.IsInGradeClassKey // 是否班内：0-否;1-是;空-不生效
    },
    method: 'GET'
  })
}
// 套餐在读课程查询
export function GetTheTaocanApplyClassStudent(TaoCanKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.GetTheTaocanApplyClassStudent',
      TaoCanKey
    },
    method: 'GET'
  })
}

// 套餐报课翻页详情
export function MessageSearchTicketPlanPageForTaoCan(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.MessageSearchTicketPlanPageForTaoCan',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, // 字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序顺序
      TaoCanKey: parameter.TaoCanKey, // 整型	可选	0	数据源：GET	套餐ID
      ApplyClassKey: parameter.ApplyClassKey, // 整型	必须	0	数据源：GET	课程ID
      SpecialFilter: parameter.SpecialFilter, // 整型	必须	0	数据源：GET	0-不生效;1-按待排>0;2-按待报>0;3-课时不足[0<按当前报课<=5];
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配 单号 学生名 学生首字母 手机号
      QueryID: parameter.QueryID // 整型	可选	0	数据源：GET	学生ID
    },
    method: 'GET'
  })
}
// 获取此学生指定班级所有报课计划
export function GetTicketPlanForGradeApplyClass(StudentKey, GradeClassKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.GetTicketPlanForGradeApplyClass',
      StudentKey: StudentKey, // 学生ID
      GradeClassKey: GradeClassKey
    },
    method: 'GET'
  })
}

//  批量老生录入  手动录入页翻页加载
export function MessageSearchPageCacheForMakeUp(pageSize, guid, clientId) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.MessageSearchPageCacheForMakeUp',
      pageSize: pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      guid: guid, //	字符串	可选		数据源：GET	起始guid,无则为空
      client_id: clientId // 当前登录终端的唯一识别
    },
    method: 'GET'
  })
}
// 批量老生录入 输入手机号后验证：根据录入学生名 + 手机号验证系统是否已存在
export function VerifyOldStudent(StudentKeyValue, CustomerPhoneName) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.VerifyOldStudent',
      StudentKeyValue, // 学生ID
      CustomerPhoneName // 手机号
    },
    method: 'GET'
  })
}
// 批量老生录入 输入手机号后验证：根据录入学生名 + 手机号验证系统是否已存在
export function VerifySingleStudent(StudentKeyValue, CustomerPhoneName) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.VerifySingleStudent',
      StudentKeyValue, // 学生ID
      CustomerPhoneName // 手机号
    },
    method: 'GET'
  })
}
// 批量老生录入 暂存
export function OldStudentEnterTemp(insertData, deleteIDs) {
  let postData = {
    Data: JSON.stringify(insertData),
    deleteIDs: JSON.stringify(deleteIDs)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.CacheMakeUp'
    },
    method: 'POST',
    data: qs.stringify(postData),
    checkResubmit: true
  })
}
// 批量老生历史记录
export function MessageSearchPageHistoryForMakeUp(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.MessageSearchPageHistoryForMakeUp',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	 页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须	0	数据源：GET	  每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	 排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	 排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	 搜索条件：模糊匹配学生姓名，首字母，手机号
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 在校学生的在读课程报课统计
export function MessageSearchPageForActiveStudent(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.MessageSearchPageForActiveStudent',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序顺序
      QueryID: parameter.QueryID, // 整型	可选	0	数据源：GET	学生ID
      SubscribeKey: parameter.SubscribeKey, //	字符串	可选		数据源：GET	关注状态：0-未关注;1-已关注;空-不生效
      SpecialFilter: parameter.SpecialFilter // 整型	可选	0	数据源：GET	0-不生效;2-按待报>0;3-按课时不足<=5
    },
    method: 'GET'
  })
}
export function MessageSearchPageForActiveStudentApplyClass(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.MessageSearchPageForActiveStudentApplyClass',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序顺序
      ApplyClassKey: parameter.ApplyClassKey, //	整型	可选	0	数据源：GET	课名ID
      QueryID: parameter.QueryID, // 整型	可选	0	数据源：GET	学生ID
      SpecialFilter: parameter.SpecialFilter // 整型	可选	0	数据源：GET	0-不生效;2-按待报>0;3-按课时不足<=5
    },
    method: 'GET'
  })
}
// 老生导入提交
export function SubmitMakeUp(insertData, deleteIDs) {
  let postData = {
    Data: JSON.stringify(insertData),
    deleteIDs: JSON.stringify(deleteIDs)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.SubmitMakeUp'
    },
    timeout: 600000,
    method: 'POST',
    data: qs.stringify(postData)
  })
}
// // 老生批量录入-导入
export function InputOldStudentByExcel(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.InputOldStudentByExcel'
    },
    method: 'post',
    data: data,
    timeout: 60000,
    checkResubmit: true
  })
}
// 老生录入导入
export function ImportMakeUp(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.ImportMakeUp'
    },
    method: 'post',
    data: data,
    timeout: 60000,
    checkResubmit: true
  })
}
// 异常录入/学生同名异常 翻页查询
export function MessageSearchPageExceptionForMakeUp(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.MessageSearchPageExceptionForMakeUp',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	 页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须	0	数据源：GET	  每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	 排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	 排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	 搜索条件：模糊匹配学生姓名，首字母，手机号
      QueryID: parameter.QueryID, // 整型	可选	0	数据源：GET
      SpecialFilter: parameter.SpecialFilter // 1-异常录入;2-同名异常
    },
    method: 'GET'
  })
}
// 手动录入页翻页加载
export function MessageSearchPageForCacheMakeUp(pageSize, guid, clientId) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.MessageSearchPageForCacheMakeUp',
      pageSize: pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      guid: guid, //	字符串	可选		数据源：GET	起始guid,无则为空
      client_id: clientId // 当前登录终端的唯一识别
    },
    method: 'GET'
  })
}
// 老生录入-》清空
export function ClearInputStudent() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.DeleteMakeUp'
    },
    method: 'GET'
  })
}

// 建课按课程统计查询
export function CourseStatisticsforScheduleCourse() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.CourseStatisticsforScheduleCourse'
    },
    method: 'GET'
  })
}
export function GetCaptcha(Telphone) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Login.GetCaptcha',
      Telphone
    },
    method: 'GET'
  })
}
// 获取wkt门店微信公众号配置
export function GetWeChatOfficialAccountsConfig() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.GetWeChatOfficialAccountsConfig'
    },
    method: 'GET'
  })
}
// 微信公众号配置提交
export function WeChatOfficialAccountsConfigSub(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.WeChatOfficialAccountsConfigSub'
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 班级班内学生查询
export function MessageSearchPageForGradeStudent(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.MessageSearchPageForGradeStudent',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, // 字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序顺序
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配 单号 学生名 学生首字母 手机号
      QueryID: parameter.QueryID, // 整型	可选	0	数据源：GET	学生ID
      GradeClassKey: parameter.GradeClassKey // 整型	必须	0	数据源：GET	班级ID
    },
    method: 'GET'
  })
}
// 报课统计-> 班级课程报课统计
export function SummariseGradeApplyClassTicketPlan(GradeClassKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ApplyCourse.SummariseGradeApplyClassTicketPlan',
      GradeClassKey: GradeClassKey // 整型	必须	0	数据源：GET	班级ID
    },
    method: 'GET'
  })
}
export function MessageSearchArrangedPageForActiveStudent(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.MessageSearchArrangedPageForActiveStudent',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, // 字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序顺序
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配 单号 学生名 学生首字母 手机号
      QueryID: parameter.QueryID, // 整型	可选	0	数据源：GET	学生ID
      SpecialFilter: parameter.SpecialFilter, // 0-不生效;2-课时耗尽>0;3-课时不足<=5
      SubscribeKey: parameter.SubscribeKey // 关注状态：0-未关注;1-已关注;空-不生效
    },
    method: 'GET'
  })
}
// 班内学生排课分页查询
export function MessageSearchArrangedPageForGradeStudent(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.MessageSearchArrangedPageForGradeStudent',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, // 字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序顺序
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配 单号 学生名 学生首字母 手机号
      SubscribeKey: parameter.SubscribeKey, //	字符串	可选		数据源：GET	关注状态：0-未关注;1-已关注;空-不生效
      FaceRecognition: parameter.FaceRecognition, //	字符串	可选		数据源：GET	录入状态：0-未录入;1-已录入;空-不生效
      QueryID: parameter.QueryID, // 整型	可选	0	数据源：GET	学生ID
      GradeClassKey: parameter.GradeClassKey // 	班级ID
    },
    method: 'GET'
  })
}
// 班内学生排课分页查询 新
export function GradeMessageSearchArrangedPageForGradeStudent(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.MessageSearchArrangedPageForGradeStudent',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, // 字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序顺序
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配 单号 学生名 学生首字母 手机号
      SubscribeKey: parameter.SubscribeKey, //	字符串	可选		数据源：GET	关注状态：0-未关注;1-已关注;空-不生效
      FaceRecognition: parameter.FaceRecognition, //	字符串	可选		数据源：GET	录入状态：0-未录入;1-已录入;空-不生效
      QueryID: parameter.QueryID, // 整型	可选	0	数据源：GET	学生ID
      GradeClassKey: parameter.GradeClassKey, // 	班级ID
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 排课规律->排课规律查询
export function SearchStructedRule(CourseNameKey, GradeClassKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.SearchStructedRule',
      CourseNameKey,
      GradeClassKey
    },
    method: 'GET'
  })
}

// 排课规律->排课规律查询
export function GradeSearchStructedRule(CourseNameKey, GradeClassKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.SearchStructedRule',
      CourseNameKey,
      GradeClassKey
    },
    method: 'GET'
  })
}
// 课程管理--获取课程所报班级
export function GetGradesByScheduleCourseKey(ClassTypeID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.GetGradesByScheduleCourseKey',
      ClassTypeID
    },
    method: 'GET'
  })
}
// 班级课表->排课规律查询 新 课程排课
export function CoursSearchStructedRule(CourseNameKey, GradeClassKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.SearchStructedRule',
      CourseNameKey,
      GradeClassKey
    },
    method: 'GET'
  })
}
// 排课规律 - 》终止排课规律
export function AbortStructuredRule(
  RuleId,
  IsProgressCourse,
  StartTime,
  EndTime,
  IsNotEndKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.AbortStructuredRule',
      RuleId,
      IsProgressCourse,
      StartTime,
      EndTime,
      IsNotEndKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
//  排课规律->排课规律详情
export function GetStructedRuleDetail(StructuredRuleKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.GetStructedRuleDetail',
      StructuredRuleKey
    },
    method: 'GET'
  })
}
//  排课规律->排课规律详情
export function EditClassTeacherByRuleId(
  RuleId,
  StartTime,
  EndTime,
  TeacherKey,
  TeacherKeyValue
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.EditClassTeacherByRuleId',
      RuleId,
      StartTime,
      EndTime,
      TeacherKey,
      TeacherKeyValue
    },
    method: 'GET'
  })
}
//  排课规律->批量修改课的教室
export function EditClassRoomByRuleId(
  RuleId,
  StartTime,
  EndTime,
  ClassRoomKey,
  ClassRoomKeyValue
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.EditClassRoomByRuleId',
      RuleId,
      StartTime,
      EndTime,
      ClassRoomKey,
      ClassRoomKeyValue
    },
    method: 'GET',
    checkResubmit: true
  })
}
//  排课规律->批量修改课的课消
export function EditPeriodCountByRuleId(
  RuleId,
  StartTime,
  EndTime,
  PeriodCount
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.EditPeriodCountByRuleId',
      RuleId,
      StartTime,
      EndTime,
      PeriodCount
    },
    method: 'GET',
    checkResubmit: true
  })
}
//  排课规律->批量修改课的计划满员数
export function EditFullPeopleCountByRuleId(
  RuleId,
  StartTime,
  EndTime,
  FullPeopleCount
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.EditFullPeopleCountByRuleId',
      RuleId,
      StartTime,
      EndTime,
      FullPeopleCount
    },
    method: 'GET',
    checkResubmit: true
  })
}
//  排课规律->批量修改课的旷课扣课时
export function EditIsChargesByRuleId(
  RuleId,
  StartTime,
  EndTime,
  IsChargesKey,
  IsChargesKeyValue
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.EditIsChargesByRuleId',
      RuleId,
      StartTime,
      EndTime,
      IsChargesKey,
      IsChargesKeyValue
    },
    method: 'GET',
    checkResubmit: true
  })
}
//  排课规律->批量修改课的请假扣课时
export function EditIsLeaveByRuleId(
  RuleId,
  StartTime,
  EndTime,
  IsleaveKey,
  IsleaveKeyValue
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.EditIsLeaveByRuleId',
      RuleId,
      StartTime,
      EndTime,
      IsleaveKey,
      IsleaveKeyValue
    },
    method: 'GET',
    checkResubmit: true
  })
}
//  排课规律->批量修改课的上课时间
export function EditClassTimeByRuleId(
  RuleId,
  StartTime,
  EndTime,
  WeekKey,
  SClassTime,
  EClassTime,
  IsNotEndKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.EditClassTimeByRuleId',
      RuleId,
      StartTime,
      EndTime,
      WeekKey,
      SClassTime,
      EClassTime,
      IsNotEndKey,
      client_id: store.getters.clientId
    },
    method: 'GET',
    checkResubmit: true
  })
}
//  排课规律->规律课块下的所有课
export function GetScheduleCourseByRule(RuleID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.GetScheduleCourseByRule',
      RuleID
    },
    method: 'GET'
  })
}
// 修改课的亏损预警人数
export function EditWarnLossCountByRuleId(
  RuleId,
  StartTime,
  EndTime,
  WarnLossCount
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.EditWarnLossCountByRuleId',
      RuleId: RuleId, //	整型	必须		数据源：GET	规律id
      StartTime: StartTime, //	字符串	必须		数据源：GET	起始日期
      EndTime: EndTime, //	字符串	必须		数据源：GET	终止日期
      WarnLossCount: WarnLossCount //	整型	必须		数据源：GET	亏损预警人数
    },
    method: 'GET'
  })
}
export function GetNewToken(signdata) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Login.GetNewToken',
      signdata
    },
    method: 'GET'
  })
}

// 获取当前登录用户的所有可登录门店
export function GetSwitchableSaaSClient() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.GetSwitchableSaaSClient'
    },
    method: 'GET'
  })
}
// 切换门店
export function SwitchSaaSClient(targetSaasClientID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.SwitchSaaSClient',
      targetSaasClientID // 目标门店ID
    },
    method: 'GET'
  })
}
// 根据密码登录
export function LoginInByPwd(SaaSClientNo, Telphone, Password, fromType) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Login.LoginInByPwd',
      SaaSClientNo, // 门店编码
      Telphone, // 手机号
      Password, // 密码
      fromType // 类型:PC/WXB
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 待验证账号切换门店
export function SwitchSaaSClientUnVerify(targetSaasClientID, smsCode) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.SwitchSaaSClientUnVerify',
      targetSaasClientID, // 目标门店ID
      smsCode // 验证码
    },
    method: 'GET'
  })
}

// 修改密码
export function EditPassword(oldPWD, newPWD) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Employee.EditPassword',
      oldPWD, // 旧密码
      newPWD // 新密码
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 设置门店使用状态
export function UpdateUsingStatus(SaaSClientKey, UsingStatusKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.UpdateUsingStatus',
      SaaSClientKey: SaaSClientKey, // 门店id
      UsingStatusKey: UsingStatusKey // 门店使用状态0-待辅导;1-试用中;2-已留存;3-已流失;
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 设置门店问题备注 WEBV.SaaSClient.RemarkQuestion
export function RemarkQuestion(SaaSClientKey, question) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.RemarkQuestion',
      SaaSClientKey: SaaSClientKey, // 门店id
      question: question // 门店使用状态0-待辅导;1-试用中;2-已留存;3-已流失;
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 设置门店辅导数据
export function SetTutorialData(SaaSClientKey, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.SetTutorialData',
      SaaSClientKey: SaaSClientKey // 门店id
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 门店运营管理列表接口WEBV.SaaSClient.MessageSearchPageForSaaSClientMonitorData
export function getSaaSClientMonitorData(searchData, exportList) {
  var Data = {
    jsonFormate: JSON.stringify(exportList)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.MessageSearchPageForSaaSClientMonitorData',
      pageIndex: searchData.pageIndex, // 门店id
      pageSize: searchData.pageSize,
      searchText: searchData.searchText,
      orderBy: searchData.orderBy,
      sequence: searchData.sequence,
      QueryID: searchData.QueryID,
      SCreateDateTime: searchData.SCreateDateTime,
      ECreateDateTime: searchData.ECreateDateTime,
      STutorialTime: searchData.STutorialTime,
      ETutorialTime: searchData.ETutorialTime,
      SKeepAliveTime: searchData.SKeepAliveTime,
      EKeepAliveTime: searchData.EKeepAliveTime,
      UsingStatusKey: searchData.UsingStatusKey,
      SignWarning: searchData.SignWarning,
      IsExportKey: searchData.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 后台监控数据左侧已"留存日期"为主
export function SummarizeKeepAliveData(param) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.SummarizeKeepAliveData',
      StartTime: param.StartTime, // 门店id
      EndTime: param.EndTime // 门店使用状态0-待辅导;1-试用中;2-已留存;3-已流失;
    },
    method: 'GET'
  })
}
// 后台监控数据右侧已"辅导日期"为主
export function SummarizeTutorialData() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.SummarizeTutorialData'
    },
    method: 'GET'
  })
}
// 学生回收
export function RecycleStudent(StudentKey, RecycleReasonScript) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentRecycleBin.RecycleStudent',
      StudentKey: StudentKey,
      RecycleReasonScript: RecycleReasonScript //	 素材id
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 课消统计按年查询
export function MessageSearchClassAwayByYear(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassAwayReport.MessageSearchClassAwayByYear',
      startTime: searchData.startTime, // 旧密码
      endTime: searchData.endTime // 新密码
    },
    method: 'GET'
  })
}
// 课消统计按月查询
export function MessageSearchClassAwayByMonth(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassAwayReport.MessageSearchClassAwayByMonth',
      startTime: searchData.startTime, // 旧密码
      endTime: searchData.endTime // 新密码
    },
    method: 'GET'
  })
}
// 课消统计按月查询 历史数据
export function MessageSearchClassAwayByMonthH(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassAwayReport.MessageSearchClassAwayByMonthH',
      startTime: searchData.startTime, // 旧密码
      endTime: searchData.endTime // 新密码
    },
    method: 'GET'
  })
}
// 分页查询课程课消统计
export function MessageSearchClassAwayForCourse(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassAwayReport.MessageSearchClassAwayForCourse',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      type: parameter.type,
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      CourseKey: parameter.CourseKey,
      GradeKey: parameter.GradeKey,
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 分页查询课程课消统计 历史数据
export function MessageSearchClassAwayForCourseH(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassAwayReport.MessageSearchClassAwayForCourseH',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      type: parameter.type,
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      CourseKey: parameter.CourseKey,
      GradeKey: parameter.GradeKey,
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 考勤课消明细分页统计 WEBV.ClassAwayReport.MessageSearchClassAwayForAttence
export function MessageSearchClassAwayForAttence(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassAwayReport.MessageSearchClassAwayForAttence',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      type: parameter.type,
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      CourseKey: parameter.CourseKey,
      CourseTimeType: parameter.CourseTimeType,
      GradeKey: parameter.GradeKey,
      TeacherKey: parameter.TeacherKey,
      StudentKey: parameter.StudentKey,
      AttenceStatusKey: parameter.AttenceStatusKey,
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 考勤课消明细分页统计 历史数据
export function MessageSearchClassAwayForAttenceH(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassAwayReport.MessageSearchClassAwayForAttenceH',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      type: parameter.type,
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      CourseKey: parameter.CourseKey,
      CourseTimeType: parameter.CourseTimeType,
      GradeKey: parameter.GradeKey,
      TeacherKey: parameter.TeacherKey,
      StudentKey: parameter.StudentKey,
      AttenceStatusKey: parameter.AttenceStatusKey,
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 异常课消金额明细 WEBV.ClassAwayReport.MessageSearchClassAwayForAbnormal
export function MessageSearchClassAwayForAbnormal(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassAwayReport.MessageSearchClassAwayForAbnormal',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      type: parameter.type,
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 欠课时明细分页统计
export function MessageSearchClassAwayForDeduct(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassAwayReport.MessageSearchClassAwayForDeduct',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      type: parameter.type,
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      CourseKey: parameter.CourseKey,
      GradeKey: parameter.GradeKey,
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 欠课时明细分页统计 历史数据
export function MessageSearchClassAwayForDeductH(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassAwayReport.MessageSearchClassAwayForDeductH',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      type: parameter.type,
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      CourseKey: parameter.CourseKey,
      GradeKey: parameter.GradeKey,
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
export function GetStudentByMobile(Mobile) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetStudentByMobile',
      Mobile: Mobile // 手机号
    },
    method: 'GET'
  })
}
// 将指定课的所有未考勤的学生取消排课
export function CancelArrangeForNoAttendByCourse(vueObject, CourseKey, Reason) {
  if (getSaaSClientSetInfo(126).SetKey == 1) {
    return new Promise((resolve, reject) => {
      // 严格模式
      console.log('PauseScheduleCourse')
      vueObject.$dialog.open(vueObject, {
        routerName: vueObject.$route.name,
        ModulePowerKey: '',
        name: '备注',
        moduleName: 'strictModeReason',
        data: {},
        callBack: {
          afterSuccess: reason => {
            CancelArrangeForNoAttendByCourseShowDialog(CourseKey, reason)
              .then(reuslt => {
                resolve(reuslt)
              })
              .catch(error => {
                console.log('error', error)
                reject(error)
              })
          }
        }
      })
      console.log('PauseScheduleCourse2')
    })
  } else {
    // 严格模式
    return CancelArrangeForNoAttendByCourseShowDialog(CourseKey, Reason)
  }
}

// 将指定课的所有未考勤的学生取消排课
export function CancelArrangeForNoAttendByCourseShowDialog(CourseKey, Reason) {
  console.log('CancelArrangeForNoAttendByCourseShowDialog', CourseKey, Reason)
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.CancelArrangeForNoAttendByCourse',
      CourseKey: CourseKey, // 手机号
      Reason: Reason,
      client_id: store.getters.clientId
    },
    method: 'GET',
    checkResubmit: true
  })
}
// // 将所有已完结的未考勤的学生取消排课
export function CancelArrangeForAllNoAttendByCourse(vueObject, Reason) {
  if (getSaaSClientSetInfo(123).SetKey == 1) {
    return new Promise((resolve, reject) => {
      // 严格模式
      console.log('PauseScheduleCourse')
      vueObject.$dialog.open(vueObject, {
        routerName: vueObject.$route.name,
        ModulePowerKey: '',
        name: '备注',
        moduleName: 'strictModeReason',
        data: {},
        callBack: {
          afterSuccess: reason => {
            CancelArrangeForAllNoAttendByCourseShowDialog(reason)
              .then(reuslt => {
                resolve(reuslt)
              })
              .catch(error => {
                reject(error)
              })
          }
        }
      })
      console.log('PauseScheduleCourse2')
    })
  } else {
    // 严格模式
    console.log('Reason', Reason)
    return CancelArrangeForAllNoAttendByCourseShowDialog(Reason)
  }
}
// 将所有已完结的未考勤的学生取消排课
export function CancelArrangeForAllNoAttendByCourseShowDialog(Reason) {
  // 判断设置是否严格模式。 决定弹出表单或者相应提示。
  // 严格模式
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.CancelArrangeForAllNoAttendByCourse',
      Reason: Reason,
      client_id: store.getters.clientId
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 多门店基本统计  WEBV.SaaSClientReport.SaaSClientBaseReport
export function SaaSClientBaseReport() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClientReport.SaaSClientBaseReport'
    },
    method: 'GET'
  })
}
// 获取指定时间的课消金额  WEBV.SaaSClientReport.GetClassAwayAmountForMonth
export function GetClassAwayAmountForMonth(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClientReport.GetClassAwayAmountForMonth',
      startTime: searchData.startTime, // 旧密码
      endTime: searchData.endTime // 新密码
    },
    method: 'GET'
  })
}
// 获取周 月 年 应到实到统计  WEBV.SaaSClientReport.MessageSearchAttendanceReport
export function MessageSearchAttendanceReport(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClientReport.MessageSearchAttendanceReport',
      startTime: searchData.startTime, // 旧密码
      endTime: searchData.endTime, // 新密码
      TargetSaaSClientKey: searchData.TargetSaaSClientKey,
      DateType: searchData.DateType
    },
    method: 'GET'
  })
}
// 指定门店下的连锁店  WEBV.SaaSClientReport.GetChainSaaSClient
export function GetChainSaaSClient() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClientReport.GetChainSaaSClient'
    },
    method: 'GET'
  })
}
// 获取指定时间的报名金额 WEBV.SaaSClientReport.GetApplyAmountForMonth
export function GetApplyAmountForMonth(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClientReport.GetApplyAmountForMonth',
      startTime: searchData.startTime, // 旧密码
      endTime: searchData.endTime // 新密码
    },
    method: 'GET'
  })
}
export function MessageSearchChainSaaSClientReport(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClientReport.MessageSearchChainSaaSClientReport',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence // 排序方式
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// 老师统计- 生成数据时间 WEBV.TeacherPerformanceReport.GetDataGenerationTime
export function GetTeacherDataGenerationTime() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherPerformanceReport.GetDataGenerationTime'
    },
    method: 'GET'
  })
}
// 老师统计- 重新生成数据
export function RegenTeacherPerformance() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherPerformanceReport.RegenTeacherPerformance'
    },
    method: 'GET'
  })
}
// 老师业绩-课消金额按月统计 WEBV.TeacherPerformanceReport.MessageSearchTeachingPerformanceForMonth
export function getTeacherPerformanceForMonth(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.TeacherPerformanceReport.MessageSearchTeachingPerformanceForMonth',
      startTime: searchData.startTime, // 旧密码
      endTime: searchData.endTime, // 新密码
      TeacherKey: searchData.TeacherKey,
      IsMTeaKey: searchData.IsMTeaKey
    },
    method: 'GET'
  })
}
// 老师统计-按月统计WEBV.TeacherPerformanceReport.GetTeachingPerformanceForMonth
export function GetTeachingPerformanceForMonth(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherPerformanceReport.GetTeachingPerformanceForMonth',
      startTime: searchData.startTime, // 旧密码
      endTime: searchData.endTime,
      IsMTeaKey: searchData.IsMTeaKey
    },
    method: 'GET'
  })
}
// 老师业绩-课次明细 2022后
export function MessageSearchTeachingPerformanceForCourse(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.TeacherPerformanceReport.MessageSearchTeachingPerformanceForCourse',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      TeacherKey: parameter.TeacherKey,
      GradeKey: parameter.GradeKey,
      CourseKey: parameter.CourseKey,
      IsExportKey: parameter.IsExportKey,
      IsMTeaKey: parameter.IsMTeaKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// 老师业绩-课次明细 2022前
export function MessageSearchHistoryTeachingPerformanceForCourse(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.TeacherPerformanceReport.MessageSearchHistoryTeachingPerformanceForCourse',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      TeacherKey: parameter.TeacherKey,
      GradeKey: parameter.GradeKey,
      CourseKey: parameter.CourseKey,
      IsExportKey: parameter.IsExportKey,
      IsMTeaKey: parameter.IsMTeaKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// 查询上课时间段
export function GetClassTimeRange() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.GetClassTimeRange'
    },
    method: 'GET'
  })
}
// 删除上课时间段
export function DeleteClassTimeRange(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.DeleteClassTimeRange',
      id: id
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 添加上课时间段
export function AddClassTimeRange(SClassTimeName, EClassTimeName) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BasicSetting.AddClassTimeRange',
      SClassTimeName: SClassTimeName,
      EClassTimeName: EClassTimeName
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 日历建课-获取指定课程，班级，上下课时间的课 WEBV.ScheduleCourse.GetCourseListForAddCourse
export function GetCourseListForAddCourse(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.GetCourseListForAddCourse',
      CourseKey: data.CourseKey,
      GradeKey: data.GradeKey,
      ClassTimeName: data.ClassTimeName,
      StartTime: data.StartTime,
      EndTime: data.EndTime
    },
    method: 'GET'
  })
}
export function addCalendarCourse(params) {
  let Data = {
    Data: JSON.stringify(params)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.CalendarCourse'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 是否开启快捷启用
export function SyncIfOpenQuickStart(IsOpenQuickStart) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.SyncIfOpenQuickStart',
      IsOpenQuickStart: IsOpenQuickStart // 是否开启快捷启用
    },
    method: 'GET',
    checkResubmit: true
  })
}

// 录入老生
export function SingleMakeUp(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.SingleMakeUp'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 开启刷脸签到-长连接
export function SpecialLongLink(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Common.SpecialLongLink',
      notifyType: data.notifyType,
      eventName: data.eventName,
      eventContent: data.eventContent,
      targetAgentKey: data.targetAgentKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 班级课统计
export function GetClassStatisticsForGrade(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.GetClassStatisticsForGrade',
      startTime: data.startTime, //	字符串	必须		数据源：GET	开始时间
      endTime: data.endTime, //	字符串	必须		数据源：GET	结束时间
      GradeID: data.GradeID // 整型	必须		数据源：GET	班级id
    },
    method: 'GET'
  })
}
// 班级课统计 历史数据
export function GetClassHistoryStatisticsForGrade(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.GetClassHistoryStatisticsForGrade',
      startTime: data.startTime, //	字符串	必须		数据源：GET	开始时间
      endTime: data.endTime, //	字符串	必须		数据源：GET	结束时间
      GradeID: data.GradeID // 整型	必须		数据源：GET	班级id
    },
    method: 'GET'
  })
}
// 班级应到实到统计（周，月，年）
export function MessageSearchAttendanceReportForGrade(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.MessageSearchAttendanceReportForGrade',
      startTime: data.startTime, //		字符串	必须		数据源：GET	 开始时间
      endTime: data.endTime, //		字符串	必须		数据源：GET	 结束时间
      DateType: data.DateType, //		整型	必须		数据源：GET	 日期类型1-周;2-月;3-年
      GradeID: data.GradeID //		整型	必须		数据源：GET	 班级id
    },
    method: 'GET'
  })
}
// 班级应到实到统计（周，月，年） 历史
export function MessageSearchAttendanceHistoryReportForGrade(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.MessageSearchAttendanceHistoryReportForGrade',
      startTime: data.startTime, //		字符串	必须		数据源：GET	 开始时间
      endTime: data.endTime, //		字符串	必须		数据源：GET	 结束时间
      DateType: data.DateType, //		整型	必须		数据源：GET	 日期类型1-周;2-月;3-年
      GradeID: data.GradeID //		整型	必须		数据源：GET	 班级id
    },
    method: 'GET'
  })
}
// 班级学生报名分析
export function GetApplyAmountForGradeStudent(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.GetApplyAmountForGradeStudent',
      startTime: data.startTime, //		字符串	必须		数据源：GET	 开始时间
      endTime: data.endTime, //		字符串	必须		数据源：GET	 结束时间
      GradeID: data.GradeID //		整型	必须		数据源：GET	 班级id
    },
    method: 'GET'
  })
}
// 班级课学生课消统计
export function MessageSearchClassAwayForGrade(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.MessageSearchClassAwayForGrade',
      startTime: data.startTime, //		字符串	必须		数据源：GET	 开始时间
      endTime: data.endTime, //		字符串	必须		数据源：GET	 结束时间
      GradeID: data.GradeID //		整型	必须		数据源：GET	 班级id
    },
    method: 'GET'
  })
}
// 班级课学生课消统计 历史数据
export function MessageSearchHistoryClassAwayForGrade(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.MessageSearchHistoryClassAwayForGrade',
      startTime: data.startTime, //		字符串	必须		数据源：GET	 开始时间
      endTime: data.endTime, //		字符串	必须		数据源：GET	 结束时间
      GradeID: data.GradeID //		整型	必须		数据源：GET	 班级id
    },
    method: 'GET'
  })
}
// TASK:973 班级-获取月统计(上课记录)
export function MessageSearchPageForGradeClassRecord(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.MessageSearchPageForGradeClassRecord',
      pageIndex: data.pageIndex, //	整型	必须	0	数据源：GET	 页码，0为第一页
      pageSize: data.pageSize, //	整型	必须	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: data.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: data.sequence, //	字符串	可选	DESC	数据源：GET	 排序方向
      searchText: data.searchText, //	字符串	可选		数据源：GET	 搜索条件：模糊匹配班级名，老师名
      StartTime: data.StartTime, //	字符串	可选		数据源：GET	 开始时间
      EndTime: data.EndTime, //	字符串	可选		数据源：GET	 结束时间
      GradeID: data.GradeID, //	整型	必须		数据源：GET	班级id
      IsExportKey: data.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// TASK:973 班级-获取月统计(上课记录) 历史
export function MessageSearchPageForGradeClassHistoryRecord(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.MessageSearchPageForGradeClassHistoryRecord',
      pageIndex: data.pageIndex, //	整型	必须	0	数据源：GET	 页码，0为第一页
      pageSize: data.pageSize, //	整型	必须	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: data.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: data.sequence, //	字符串	可选	DESC	数据源：GET	 排序方向
      searchText: data.searchText, //	字符串	可选		数据源：GET	 搜索条件：模糊匹配班级名，老师名
      StartTime: data.StartTime, //	字符串	可选		数据源：GET	 开始时间
      EndTime: data.EndTime, //	字符串	可选		数据源：GET	 结束时间
      GradeID: data.GradeID, //	整型	必须		数据源：GET	班级id
      IsExportKey: data.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// TASK:973 班级-获取月统计(上课记录) 课次记录
export function MessageSearchPageForGradeClassRecordNew(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.MessageSearchPageForGradeClassRecordNew',
      pageIndex: data.pageIndex, //	整型	必须	0	数据源：GET	 页码，0为第一页
      pageSize: data.pageSize, //	整型	必须	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: data.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: data.sequence, //	字符串	可选	DESC	数据源：GET	 排序方向
      searchText: data.searchText, //	字符串	可选		数据源：GET	 搜索条件：模糊匹配班级名，老师名
      StartTime: data.StartTime, //	字符串	可选		数据源：GET	 开始时间
      EndTime: data.EndTime, //	字符串	可选		数据源：GET	 结束时间
      GradeID: data.GradeID, //	整型	必须		数据源：GET	班级id
      IsExportKey: data.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// TASK:973 班级-获取月统计(上课记录) 历史 课次记录
export function MessageSearchPageForGradeClassRecordNewHistory(
  data,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.MessageSearchPageForGradeClassRecordNewHistory',
      pageIndex: data.pageIndex, //	整型	必须	0	数据源：GET	 页码，0为第一页
      pageSize: data.pageSize, //	整型	必须	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: data.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: data.sequence, //	字符串	可选	DESC	数据源：GET	 排序方向
      searchText: data.searchText, //	字符串	可选		数据源：GET	 搜索条件：模糊匹配班级名，老师名
      StartTime: data.StartTime, //	字符串	可选		数据源：GET	 开始时间
      EndTime: data.EndTime, //	字符串	可选		数据源：GET	 结束时间
      GradeID: data.GradeID, //	整型	必须		数据源：GET	班级id
      IsExportKey: data.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// TASK:973 班级-获取月统计(上课记录) 结转课消
export function MessageSearchPageForGradeClassDeductRecord(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.MessageSearchPageForGradeClassDeductRecord',
      pageIndex: data.pageIndex, //	整型	必须	0	数据源：GET	 页码，0为第一页
      pageSize: data.pageSize, //	整型	必须	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: data.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: data.sequence, //	字符串	可选	DESC	数据源：GET	 排序方向
      searchText: data.searchText, //	字符串	可选		数据源：GET	 搜索条件：模糊匹配班级名，老师名
      StartTime: data.StartTime, //	字符串	可选		数据源：GET	 开始时间
      EndTime: data.EndTime, //	字符串	可选		数据源：GET	 结束时间
      GradeID: data.GradeID, //	整型	必须		数据源：GET	班级id
      IsExportKey: data.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// TASK:973 班级-获取月统计(上课记录) 历史 结转课消
export function MessageSearchPageForGradeClassDeductRecordHistory(
  data,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.MessageSearchPageForGradeClassDeductRecordHistory',
      pageIndex: data.pageIndex, //	整型	必须	0	数据源：GET	 页码，0为第一页
      pageSize: data.pageSize, //	整型	必须	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: data.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: data.sequence, //	字符串	可选	DESC	数据源：GET	 排序方向
      searchText: data.searchText, //	字符串	可选		数据源：GET	 搜索条件：模糊匹配班级名，老师名
      StartTime: data.StartTime, //	字符串	可选		数据源：GET	 开始时间
      EndTime: data.EndTime, //	字符串	可选		数据源：GET	 结束时间
      GradeID: data.GradeID, //	整型	必须		数据源：GET	班级id
      IsExportKey: data.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 23
// TASK:973 课程管理--上课记录
export function MessageSearchStudentAttendClassRecord(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.MessageSearchStudentAttendClassRecord',
      pageIndex: data.pageIndex, //	整型	必须	0	数据源：GET	 页码，0为第一页
      pageSize: data.pageSize, //	整型	必须	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: data.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: data.sequence, //	字符串	可选	DESC	数据源：GET	 排序方向
      searchText: data.searchText, //	字符串	可选		数据源：GET	 搜索条件：模糊匹配班级名，老师名
      StartTime: data.StartTime, //	字符串	可选		数据源：GET	 开始时间
      EndTime: data.EndTime, //	字符串	可选		数据源：GET	 结束时间
      CourseNameKey: data.CourseNameKey, //	整型	必须		数据源：GET	班级id
      IsExportKey: data.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// TASK:973 课程管理--上课记录 历史 课次记录
export function MessageSearchStudentAttendClassRecordHistory(
  data,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.MessageSearchStudentAttendClassRecordHistory',
      pageIndex: data.pageIndex, //	整型	必须	0	数据源：GET	 页码，0为第一页
      pageSize: data.pageSize, //	整型	必须	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: data.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: data.sequence, //	字符串	可选	DESC	数据源：GET	 排序方向
      searchText: data.searchText, //	字符串	可选		数据源：GET	 搜索条件：模糊匹配班级名，老师名
      StartTime: data.StartTime, //	字符串	可选		数据源：GET	 开始时间
      EndTime: data.EndTime, //	字符串	可选		数据源：GET	 结束时间
      CourseNameKey: data.CourseNameKey, //	整型	必须		数据源：GET	班级id
      IsExportKey: data.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// TASK:973 班级-获取月统计(上课记录) 结转课消
export function CoursMessageSearchPageForGradeClassDeductRecord(
  data,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.MessageSearchPageForGradeClassDeductRecord',
      pageIndex: data.pageIndex, //	整型	必须	0	数据源：GET	 页码，0为第一页
      pageSize: data.pageSize, //	整型	必须	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: data.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: data.sequence, //	字符串	可选	DESC	数据源：GET	 排序方向
      searchText: data.searchText, //	字符串	可选		数据源：GET	 搜索条件：模糊匹配班级名，老师名
      StartTime: data.StartTime, //	字符串	可选		数据源：GET	 开始时间
      EndTime: data.EndTime, //	字符串	可选		数据源：GET	 结束时间
      CourseNameKey: data.CourseNameKey, //	整型	必须		数据源：GET	班级id
      IsExportKey: data.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// TASK:973 班级-获取月统计(上课记录) 历史 结转课消
export function CoursMessageSearchPageForGradeClassDeductRecordHistory(
  data,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.MessageSearchPageForGradeClassDeductRecordHistory',
      pageIndex: data.pageIndex, //	整型	必须	0	数据源：GET	 页码，0为第一页
      pageSize: data.pageSize, //	整型	必须	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: data.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: data.sequence, //	字符串	可选	DESC	数据源：GET	 排序方向
      searchText: data.searchText, //	字符串	可选		数据源：GET	 搜索条件：模糊匹配班级名，老师名
      StartTime: data.StartTime, //	字符串	可选		数据源：GET	 开始时间
      EndTime: data.EndTime, //	字符串	可选		数据源：GET	 结束时间
      CourseNameKey: data.CourseNameKey, //	整型	必须		数据源：GET	班级id
      IsExportKey: data.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 45
// TASK:973 课程管理--课时结余
export function MessageSearchClassHourCashSurplus(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.MessageSearchClassHourCashSurplus',
      pageIndex: data.pageIndex, //	整型	必须	0	数据源：GET	 页码，0为第一页
      pageSize: data.pageSize, //	整型	必须	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: data.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: data.sequence, //	字符串	可选	DESC	数据源：GET	 排序方向
      searchText: data.searchText, //	字符串	可选		数据源：GET	 搜索条件：模糊匹配班级名，学生
      StartTime: data.StartTime, //	字符串	可选		数据源：GET	 开始时间
      EndTime: data.EndTime, //	字符串	可选		数据源：GET	 结束时间
      CourseNameKey: data.CourseNameKey, //	整型	必须		数据源：GET	班级id
      IsExportKey: data.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 统计结余-天数结余
export function MessageSearchDayNumberCashSurplus(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.MessageSearchDayNumberCashSurplus',
      pageIndex: data.pageIndex, //	整型	必须	0	数据源：GET	 页码，0为第一页
      pageSize: data.pageSize, //	整型	必须	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: data.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: data.sequence, //	字符串	可选	DESC	数据源：GET	 排序方向
      searchText: data.searchText, //	字符串	可选		数据源：GET	 搜索条件：模糊匹配班级名，老师名
      StartTime: data.StartTime, //	字符串	可选		数据源：GET	 开始时间
      EndTime: data.EndTime, //	字符串	可选		数据源：GET	 结束时间
      CourseNameKey: data.CourseNameKey, //	整型	必须		数据源：GET	班级id
      IsExportKey: data.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 收付款统计
export function GetInComePayReportForDate() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.InComePayReport.GetInComePayReportForDate'
    },
    method: 'GET'
  })
}
// 收付款统计-付款分析
export function GetInComePayReportForExpend(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.InComePayReport.GetInComePayReportForExpend',
      startTime: data.startTime,
      endTime: data.endTime
    },
    method: 'GET'
  })
}
// 收付款统计-收款分析
export function GetInComePayReportForProceeds(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.InComePayReport.GetInComePayReportForProceeds',
      startTime: data.startTime,
      endTime: data.endTime
    },
    method: 'GET'
  })
}
export function MessageSearchInComePayReport(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.InComePayReport.MessageSearchInComePayReport',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IncomePayAccountKey: parameter.IncomePayAccountKey,
      IncomePayTypeKey: parameter.IncomePayTypeKey,
      IncomePaySubKey: parameter.IncomePaySubKey,
      IsExportKey: parameter.IsExportKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 老师业绩-老师统计
export function MessageSearchTeachingPerformanceByGroup(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.TeacherPerformanceReport.MessageSearchTeachingPerformanceByGroup',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      TeacherKey: parameter.TeacherKey,
      GradeKey: parameter.GradeKey,
      CourseKey: parameter.CourseKey,
      IsExportKey: parameter.IsExportKey,
      IsMTeaKey: parameter.IsMTeaKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 老师业绩-老师统计 2022之前
export function MessageSearchTeachingHistoryPerformanceByGroup(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.TeacherPerformanceReport.MessageSearchTeachingHistoryPerformanceByGroup',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      TeacherKey: parameter.TeacherKey,
      GradeKey: parameter.GradeKey,
      CourseKey: parameter.CourseKey,
      IsExportKey: parameter.IsExportKey,
      IsMTeaKey: parameter.IsMTeaKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 校长看板统计-》报名收款月报
export function GetApplyPaymentReportForMonth(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PrincipalKanBanReport.GetApplyPaymentReportForMonth',
      startTime: data.startTime,
      endTime: data.endTime
    },
    method: 'GET'
  })
}
// 校长看板统计-》课时课消月报
export function GetClassAmountReportForMonth(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PrincipalKanBanReport.GetClassAmountReportForMonth',
      startTime: data.startTime,
      endTime: data.endTime
    },
    method: 'GET'
  })
}
// 校长看板-课程课消
export function GetClassAmountReportForCourse(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PrincipalKanBanReport.GetClassAmountReportForCourse',
      startTime: data.startTime,
      endTime: data.endTime
    },
    method: 'GET'
  })
}
// 校长看板-班级课消
export function GetClassAmountReportForGrade(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PrincipalKanBanReport.GetClassAmountReportForGrade',
      startTime: data.startTime,
      endTime: data.endTime
    },
    method: 'GET'
  })
}
// 校长看板-课时余额
export function GetClassBalanceForCourse() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PrincipalKanBanReport.GetClassBalanceForCourse'
    },
    method: 'GET'
  })
}

// 营业收入统计-基本统计
export function GetOperatingReceiptReportForDate() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.OperatingReceiptReport.GetOperatingReceiptReportForDate'
    },
    method: 'GET'
  })
}

// 退课退款一详情
export function GetDropMultiCourseApplyInfo(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetDropMultiCourseApplyInfo',
      TableID: TableID
    },
    method: 'GET'
  })
}

// 营业收入统计-营收分析
export function GetOperatingReceiptReportByGroup(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.OperatingReceiptReport.GetOperatingReceiptReportByGroup',
      startTime: data.startTime,
      endTime: data.endTime,
      DateType: data.DateType
    },
    method: 'GET'
  })
}

// 营业收入统计-营收日报
export function MessageSearchOperatingReceiptReportForDay(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.OperatingReceiptReport.MessageSearchOperatingReceiptReportForDay',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 营业收入统计-营收日报 历史
export function MessageSearchOperatingReceiptHistoryReportForDay(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.OperatingReceiptReport.MessageSearchOperatingReceiptHistoryReportForDay',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 营业收入统计-营收月报
export function MessageSearchOperatingReceiptReportForMonth(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.OperatingReceiptReport.MessageSearchOperatingReceiptReportForMonth',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 营业收入统计-营收月报
export function MessageSearchOperatingReceiptHistoryReportForMonth(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.OperatingReceiptReport.MessageSearchOperatingReceiptHistoryReportForMonth',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 营业收入统计-课时课消
export function MessageSearchClassAmountForDate(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.OperatingReceiptReport.MessageSearchClassAmountForDate',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      CourseID: parameter.CourseKey,
      GradeID: parameter.GradeKey,
      TeacherID: parameter.TeacherKey,
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 营业收入统计-课时课消 历史
export function MessageSearchClassHistoryAmountForDate(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.OperatingReceiptReport.MessageSearchClassHistoryAmountForDate',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      CourseID: parameter.CourseKey,
      GradeID: parameter.GradeKey,
      TeacherID: parameter.TeacherKey,
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// 营业收入统计-课时过期
export function MessageSearchPastDueClassAmount(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.OperatingReceiptReport.MessageSearchPastDueClassAmount',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      PastDueType: parameter.PastDueType,
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 营业收入统计-课时过期 历史
export function MessageSearchHistoryPastDueClassAmount(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.OperatingReceiptReport.MessageSearchHistoryPastDueClassAmount',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      PastDueType: parameter.PastDueType,
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// 营业收入统计-杂费收入
export function MessageSearchIncidentals(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.OperatingReceiptReport.MessageSearchIncidentals',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IncidentalsKey: parameter.IncidentalsKey,
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// 营业收入统计-其他收入
export function MessageSearchOtherRevenue(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.OperatingReceiptReport.MessageSearchOtherRevenue',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      RevenueType: parameter.RevenueType,
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// 营业收入统计-异常课消
export function operatingReceiptReportMessageSearchClassAwayForAbnormal(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.OperatingReceiptReport.MessageSearchClassAwayForAbnormal',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      PastDueType: parameter.PastDueType,
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 营业收入统计-异常课消 历史
export function operatingReceiptReportMessageSearchHistoryClassAwayForAbnormal(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.OperatingReceiptReport.MessageSearchHistoryClassAwayForAbnormal',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      PastDueType: parameter.PastDueType,
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// 营业收入统计-学期卡消耗
export function MessageSearchClassAwayForTermCard(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.OperatingReceiptReport.MessageSearchClassAwayForTermCard',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 营业收入统计-学期卡消耗 历史
export function MessageSearchClassAwayForHistoryTermCard(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.OperatingReceiptReport.MessageSearchClassAwayForHistoryTermCard',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// 营业收入统计-学期卡消耗详情
export function MessageSearchTermCardClassAwayDetails(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.OperatingReceiptReport.MessageSearchTermCardClassAwayDetails',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// 营业收入统计-学期卡消耗详情 历史
export function MessageSearchTermCardClassAwayDetailsHistory(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.OperatingReceiptReport.MessageSearchTermCardClassAwayDetailsHistory',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// 获取轮播图
export function GetCorporateImage() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ImageCarousel.GetCorporateImage'
    },
    method: 'GET'
  })
}
// 获取wkt门店信息
export function GetOrganizationInfo() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.OrganizationControl.GetOrganizationInfo'
    },
    method: 'GET'
  })
}
// 修改机构名称
export function AmendOrganizationName(Name) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.OrganizationControl.AmendOrganizationName',
      Name: Name
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 修改机构简介
export function AmendOrganizationIntro(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.OrganizationControl.AmendOrganizationIntro'
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}

// 修改机构Logo
export function AmendOrganizationLogo(Logo) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.OrganizationControl.AmendOrganizationLogo',
      Logo: Logo
    },
    method: 'GET'
  })
}
// 修改机构地址
export function AmendOrganizationAddress(Address) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.OrganizationControl.AmendOrganizationAddress',
      Address: Address // 地址
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 修改机构电话
export function AmendOrganizationPhone(Phone) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.OrganizationControl.AmendOrganizationPhone',
      Phone: Phone // 电话
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 新增机构环境图
export function AddOrganizationContextDiagram(url, sort) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.OrganizationControl.AddOrganizationContextDiagram',
      url: url, //	字符串	必须		数据源：GET	图片路劲
      sort: sort //	整型	必须		数据源：GET	排序
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 修改机构环境图
export function AmendOrganizationContextDiagram(id, url) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.OrganizationControl.AmendOrganizationContextDiagram',
      id: id, //	整型	必须		数据源：GET	 数据id
      url: url //	字符串	必须		数据源：GET	 图片路劲
    },
    method: 'GET'
  })
}
// 删除机构环境图
export function DelContextDiagram(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.OrganizationControl.DelContextDiagram',
      id: id //	整型	必须		数据源：GET	 数据id
    },
    method: 'GET'
  })
}
// 移动机构环境图;
export function MoveContextDiagram(id, sort) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.OrganizationControl.MoveContextDiagram',
      id: id, //		整型	必须		数据源：GET	 数据id
      sort: sort //		整型	必须		数据源：GET	排序
    },
    method: 'GET'
  })
}

// 查询已启用的套餐
export function SelectEnableCombo(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ComboManagement.SelectEnableCombo',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	 排序方向
      searchText: parameter.searchText //	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
    },
    method: 'GET'
  })
}
// 查询已停用的套餐
export function SelectDisEnableCombo(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ComboManagement.SelectDisEnableCombo',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	 排序方向
      searchText: parameter.searchText //	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
    },
    method: 'GET'
  })
}
// 查询未上架的套餐
export function SelectCombo(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ComboManagement.SelectCombo',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	 排序方向
      searchText: parameter.searchText //	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
    },
    method: 'GET'
  })
}
// 建券套餐
export function SelectPackageForCoupon(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.SelectPackageForCoupon',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	 排序方向
      searchText: parameter.searchText //	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
    },
    method: 'GET'
  })
}
// 查看套餐详情
export function GetComboInfo(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ComboManagement.GetComboInfo',
      id: id // 套餐ID
    },
    method: 'GET'
  })
}
// 新增优惠套餐
export function AddCombo(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ComboManagement.AddCombo'
    },
    method: 'POST',
    data: qs.stringify(Data), // "ComboID":"pc套餐id", "combo":"套餐", "ComboColor":"套餐颜色", "Sort":"顺序",
    checkResubmit: true
  })
}
// 修改套餐
export function EditCombo(id, comboColor) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ComboManagement.EditCombo',
      id: id, //			数据源：GET	数据id
      comboColor: comboColor //	字符串	必须		数据源：GET	套餐颜色
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 启用套餐
export function EnableCombo(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ComboManagement.EnableCombo',
      id: id //			数据源：GET	数据id
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 停用套餐
export function StopCombo(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ComboManagement.StopCombo',
      id: id //			数据源：GET	数据id
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 上移/下移/置顶 套餐
export function MoveCombo(id, save) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ComboManagement.MoveCombo',
      id: id, //			数据源：GET	数据id
      save: save // 数据源：GET	  排序
    },
    method: 'GET'
  })
}
// 获取套餐默认颜色
export function GetComboDefaultColor() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ComboManagement.GetComboDefaultColor'
    },
    method: 'GET'
  })
}

// 修改机构形象图
export function AmendCorporateImage(ImageUrl) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ImageCarousel.AmendCorporateImage',
      ImageUrl: ImageUrl
    },
    method: 'GET'
  })
}
// 获取wkt门店基本统计
export function GetOrganizationReport() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.OrganizationReport.GetOrganizationReport'
    },
    method: 'GET'
  })
}
// wkt-查询已启用的明星学员
export function SelectEnableStarStudents(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.StarStudentsManagement.SelectEnableStarStudents',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	 排序方向
      searchText: parameter.searchText //	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
    },
    method: 'GET'
  })
}
// wkt-查询已停用的明星学员
export function SelectDisEnableStarStudents(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.StarStudentsManagement.SelectDisEnableStarStudents',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	 排序方向
      searchText: parameter.searchText //	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
    },
    method: 'GET'
  })
}
// wkt-新增明星学员
export function AddStarStudents(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.StarStudentsManagement.AddStarStudents'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// wkt-修改明星学员
export function EditStarStudents(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.StarStudentsManagement.EditStarStudents'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// wkt-獲取指定明星學員詳情
export function GetStarStudentsInfo(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.StarStudentsManagement.GetStarStudentsInfo',
      id: id
    },
    method: 'GET'
  })
}
// wkt-启用明星学员
export function EnableStarStudents(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.StarStudentsManagement.EnableStarStudents',
      id: id
    },
    method: 'GET',
    checkResubmit: true
  })
}
// wkt-停用明星学员
export function StopStarStudents(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.StarStudentsManagement.StopStarStudents',
      id: id
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 上移/下移/置顶 明星学员
export function MoveStarStudents(id, save) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.StarStudentsManagement.MoveStarStudents',
      id: id,
      save: save // 序号
    },
    method: 'GET'
  })
}
// wkt-明星老师
// wkt-查询已启用的明星教师
export function SelectEnableTeacher(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.TeacherManagement.SelectEnableTeacher',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	 排序方向
      searchText: parameter.searchText //	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
    },
    method: 'GET'
  })
}
// wkt-查询已停用的明星教师
export function SelectDisEnableTeacher(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.TeacherManagement.SelectDisEnableTeacher',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	 排序方向
      searchText: parameter.searchText //	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
    },
    method: 'GET'
  })
}
// wkt-新增明星教师
export function AddTeacher(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.TeacherManagement.AddTeacher'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// wkt-修改明星教师
export function EditTeacher(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.TeacherManagement.EditTeacher'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// wkt-獲取指定优秀教师詳情
export function GetTeacherInfo(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.TeacherManagement.GetTeacherInfo',
      id: id
    },
    method: 'GET'
  })
}
// wkt-启用明星教师
export function EnableTeacher(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.TeacherManagement.EnableTeacher',
      id: id
    },
    method: 'GET',
    checkResubmit: true
  })
} // wkt-停用明星教师
export function StopTeacher(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.TeacherManagement.StopTeacher',
      id: id
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 上移/下移/置顶 明星教师
export function MoveTeacher(id, save) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.TeacherManagement.MoveTeacher',
      id: id,
      save: save // 序号
    },
    method: 'GET'
  })
}
// 新增热门推荐
export function AddPopularRecommend(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.PopularRecommendManagement.AddPopularRecommend'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 删除热门推荐
export function DeletePopularRecommend(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.PopularRecommendManagement.DeletePopularRecommend',
      id: id
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 移动热门推荐
export function MovePopularRecommend(id, save) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.PopularRecommendManagement.MovePopularRecommend',
      id: id,
      save: save // 序号
    },
    method: 'GET'
  })
}
// 查询热门推荐
export function SearchPopularRecommend(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.PopularRecommendManagement.SearchPopularRecommend',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	 排序方向
      searchText: parameter.searchText //	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
    },
    method: 'GET'
  })
}
// wkt-管理课程
// wkt-查询已启用的课程
export function SelectEnableCourse(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CourseManagement.SelectEnableCourse',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	 排序方向
      searchText: parameter.searchText //	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
    },
    method: 'GET'
  })
}
// wkt-查询已停用的课程
export function SelectDisEnableCourse(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CourseManagement.SelectDisEnableCourse',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	 排序方向
      searchText: parameter.searchText //	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
    },
    method: 'GET'
  })
}
// wkt-新增课程
export function AddCourse(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CourseManagement.AddCourse'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// wkt-修改课程
export function EditCourse(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CourseManagement.EditCourse'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// wkt-启用课程
export function EnableCourse(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CourseManagement.EnableCourse',
      id: id
    },
    method: 'GET',
    checkResubmit: true
  })
} // wkt-停用课程
export function StopCourse(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CourseManagement.StopCourse',
      id: id
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 上移/下移/置顶 课程
export function MoveCourse(id, save) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CourseManagement.MoveCourse',
      id: id,
      save: save // 序号
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 变更门店模式
export function OpenIndependentModule(SaaSClientKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTF.Mendian.OpenIndependentModule',
      SaaSClientKey: SaaSClientKey
    },
    method: 'GET'
  })
}
//  返回指定课的作业
export function GetDynamicByCourse(courseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.HomeWork.GetDynamicByCourse',
      courseKey
    },
    method: 'GET'
  })
}
// 获取课学分
export function GetCourseCurrency(CourseID, Type) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.HomeWork.GetCourseCurrency',
      CourseID,
      Type
    },
    method: 'GET'
  })
}
// 修改课学分
export function EditCourseCurrency(CourseID, Type, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.HomeWork.EditCourseCurrency',
      CourseID: CourseID, //	课id
      Type: Type //		类型1-作业;2-点评
    },
    method: 'POST',
    data: qs.stringify(Data), //	字符串	必须		数据源：POST	 回复内容,
    checkResubmit: true
  })
}
// 获取布置作业详情;
export function GetHomeWorkDetails(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.HomeWork.GetHomeWorkDetails',
      id: id
    },
    method: 'GET'
  })
}

//  布置作业
export function PublishedHomeWork(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.HomeWork.PublishedHomeWork'
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 修改作业
export function EditHomeWork(id, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.HomeWork.EditHomeWork',
      id: id
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}

// 发布点评 接口
export function PublishedReview(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.CommentsManagement.PublishedReview'
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 修改点评
export function EditReview(id, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.CommentsManagement.EditReview',
      id: id
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 布置作业获取课内信息及学生信息
export function getCourseInfoForFSC(courseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.HomeWork.GetCourseInfo',
      courseKey: courseKey
    },
    method: 'GET'
  })
}

// 修改发布点评获取信息及学生信息
export function editCourseInfoForReview(essayID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.CommentsManagement.GetReviewDetailsByID',
      essayID: essayID
    },
    method: 'GET'
  })
}
// 新建发布点评获取课内信息及学生信息
export function GetCourseInfoForReview(CourseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.CommentsManagement.GetCourseInfoForReview',
      CourseKey: CourseKey
    },
    method: 'GET'
  })
}
// 获取批改作业的详情
export function GetCorrectingHomeWorkDetails(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.HomeWork.GetCorrectingHomeWorkDetails',
      id: id
    },
    method: 'GET'
  })
}
// 提交批改作业
export function CorrectingHomeWork(id, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.HomeWork.CorrectingHomeWork',
      id: id
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 提交修改后的批改作业详情
export function EditCorrectingHomeWork(id, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.HomeWork.EditCorrectingHomeWork',
      id: id
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
export function getNewsDynamicReply(EssayID, ForReplyID, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.NewsDynamic.Reply',
      EssayID: EssayID, //	整型	必须	0	数据源：GET	 短文ID
      ForReplyID: ForReplyID //	整型	必须		数据源：GET	回复对应回复ID，为0表示对短文进行直接回复
    },
    method: 'POST',
    data: qs.stringify(Data), //	字符串	必须		数据源：POST	 回复内容,
    checkResubmit: true
  })
}
// 删除回复短文
export function DeleteReply(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.NewsDynamic.DeleteReply',
      id: id //	整型	必须	0	数据源：GET	 短文ID
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 获取门店申请开通独立模式状态
export function GetIndependentModule() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTF.Mendian.GetIndependentModule'
    },
    method: 'GET'
  })
}

// 申请开通独立模式
export function ApplyIndependentModule() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTF.Mendian.ApplyIndependentModule'
    },
    method: 'GET'
  })
}

// 获取三方平台预授权码
export function GetApiCreatePreauthCode() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTF.Component.GetApiCreatePreauthCode'
    },
    method: 'GET'
  })
}

// 根据授权码缓存授权小程序刷新令牌
export function CacheAuthorizeRefreshToken(SaaSClientKey, authCode) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTF.Component.CacheAuthorizeRefreshToken',
      SaaSClientKey,
      authCode
    },
    method: 'GET'
  })
}

// 学生考勤报表-学生签到人次统计表
export function StudentAttendenceReportForDate(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentAttendenceReport.StudentAttendenceReportForDate',
      startTime: startTime, // 日期	必须		数据源：GET 开始时间
      endTime: endTime // 日期	必须		数据源：GET	 结束时间
    },
    method: 'GET'
  })
}

// 返回符合条件的, 当前页的指定条数学生数据
export function MessageSearchPageForStudentCourseAttendenceReport(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.StudentAttendenceReport.MessageSearchPageForStudentCourseAttendenceReport',
      pageIndex: parameter.pageIndex, // 整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母,手机号
      orderBy: parameter.orderBy, // 字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序顺序
      CourseKey: parameter.CourseKey, // 课程id
      leaveNum1: parameter.leaveNum1, // 整型	可选		数据源：GET	请假次数1
      leaveNum2: parameter.leaveNum2, // 整型	可选		数据源：GET	请假次数2
      startTime: parameter.startTime, //	日期	可选		数据源：GET	开始时间
      endTime: parameter.endTime, // 日期	可选		数据源：GET	结束时间
      IsExportKey: parameter.IsExportKey //	整型	可选		数据源：GET	是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 返回符合条件的, 当前页的指定条数学生数据 历史
export function MessageSearchPageForStudentCourseAttendenceReportH(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.StudentAttendenceReport.MessageSearchPageForStudentCourseAttendenceReportH',
      pageIndex: parameter.pageIndex, // 整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母,手机号
      orderBy: parameter.orderBy, // 字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序顺序
      CourseKey: parameter.CourseKey, // 课程id
      leaveNum1: parameter.leaveNum1, // 整型	可选		数据源：GET	请假次数1
      leaveNum2: parameter.leaveNum2, // 整型	可选		数据源：GET	请假次数2
      startTime: parameter.startTime, //	日期	可选		数据源：GET	开始时间
      endTime: parameter.endTime, // 日期	可选		数据源：GET	结束时间
      IsExportKey: parameter.IsExportKey //	整型	可选		数据源：GET	是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 微信管理->设置关注回复语
export function AddDefaultReply(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.WeChartManagement.AddDefaultReply'
    },
    method: 'post',
    data: qs.stringify(Data)
  })
}
// 微信管理->删除默认回复语 WKTBack.WeChartManagement.DelDefaultReply
export function DelDefaultReply(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.WeChartManagement.DelDefaultReply',
      id: id // id
    },
    method: 'get'
  })
}
// 微信管理->修改默认回复语 WKTBack.WeChartManagement.EditDefaultReply
export function EditDefaultReply(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.WeChartManagement.EditDefaultReply'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 微信管理->微信消息 WKTBack.WeChartManagement.MessageSearchPageForWeChatMessage
export function MessageSearchPageForWeChatMessage(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.WeChartManagement.MessageSearchPageForWeChatMessage',
      pageIndex: parameter.pageIndex, // 整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母,手机号
      orderBy: parameter.orderBy, // 字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序顺序
      startTime: parameter.startTime, //	日期	可选		数据源：GET	开始时间
      endTime: parameter.endTime // 日期	可选		数据源：GET	结束时间
    },
    method: 'POST'
  })
}
// 微信管理==回复微信信息WKTBack.WeChartManagement.ReplyToFansWeChatMessage
export function ReplyToFansWeChatMessage(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.WeChartManagement.ReplyToFansWeChatMessage'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 微信管理->群发消息 WKTBack.WeChartManagement.MassMessage
export function MassMessage(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.WeChartManagement.MassMessage'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 微信管理->关注回复语 WKTBack.WeChartManagement.SetPayAttentionReply
export function SetPayAttentionReply(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.WeChartManagement.SetPayAttentionReply'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 获取关注回复语
export function GetPayAttentionReply() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.WeChartManagement.GetPayAttentionReply'
    },
    method: 'get'
  })
}
// WKTBack.WeChartManagement.SelectDefaultReply
export function SelectDefaultReply(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.WeChartManagement.SelectDefaultReply',
      pageIndex: parameter.pageIndex, // 整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母,手机号
      orderBy: parameter.orderBy, // 字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence //	字符串	可选		数据源：GET	排序顺序
    },
    method: 'POST'
  })
}
// 学生考勤报表-学生班级考勤统计
export function MessageSearchPageForStudentGradeAttendenceReport(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.StudentAttendenceReport.MessageSearchPageForStudentGradeAttendenceReport',
      pageIndex: parameter.pageIndex, // 整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母,手机号
      orderBy: parameter.orderBy, // 字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序顺序
      GradeKey: parameter.GradeKey, // 课程id
      leaveNum1: parameter.leaveNum1, // 整型	可选		数据源：GET	请假次数1
      leaveNum2: parameter.leaveNum2, // 整型	可选		数据源：GET	请假次数2
      startTime: parameter.startTime, //	日期	可选		数据源：GET	开始时间
      endTime: parameter.endTime, // 日期	可选		数据源：GET	结束时间
      IsExportKey: parameter.IsExportKey //	整型	可选		数据源：GET	是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学生考勤报表-学生班级考勤统计 历史
export function MessageSearchPageForStudentGradeAttendenceReportH(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.StudentAttendenceReport.MessageSearchPageForStudentGradeAttendenceReportH',
      pageIndex: parameter.pageIndex, // 整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母,手机号
      orderBy: parameter.orderBy, // 字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序顺序
      GradeKey: parameter.GradeKey, // 课程id
      leaveNum1: parameter.leaveNum1, // 整型	可选		数据源：GET	请假次数1
      leaveNum2: parameter.leaveNum2, // 整型	可选		数据源：GET	请假次数2
      startTime: parameter.startTime, //	日期	可选		数据源：GET	开始时间
      endTime: parameter.endTime, // 日期	可选		数据源：GET	结束时间
      IsExportKey: parameter.IsExportKey //	整型	可选		数据源：GET	是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 微信管理->获取微信公众号菜单 WKTBack.WeChartManagement.SelectWeChatMenu
export function SelectWeChatMenu() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.WeChartManagement.SelectWeChatMenu'
    },
    method: 'get'
  })
}
// 微信管理->添加微信公众号菜单
export function AddWeChatMenu(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.WeChartManagement.AddWeChatMenu',
      pid: data.pid, // 父级id
      name: data.name, // 菜单名称
      url: data.url, // 链接url
      pagepath: data.pagepath, // 小程序链接url
      type: data.type // 菜单类型 click：发送消息， view：跳转网页，miniprogram：跳转小程序
    },
    method: 'get'
  })
}
// 微信管理->修改微信公众号菜单WKTBack.WeChartManagement.EditWeChatMenu
export function EditWeChatMenu(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.WeChartManagement.EditWeChatMenu',
      id: data.id,
      pid: data.pid, // 父级id
      name: data.name, // 菜单名称
      url: data.url, // 链接url
      pagepath: data.pagepath, // 小程序链接url
      type: data.type // 菜单类型 click：发送消息， view：跳转网页，miniprogram：跳转小程序
    },
    method: 'GET'
  })
}
// 微信管理-删除微信公众号菜单. WKTBack.WeChartManagement.DelWeChatMenu
export function DelWeChatMenu(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.WeChartManagement.DelWeChatMenu',
      id: id
    },
    method: 'GET'
  })
}
// 微信管理-生成微信公众号菜单. WKTBack.WeChartManagement.GenerateWeChatMenu
export function GenerateWeChatMenu() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.WeChartManagement.GenerateWeChatMenu'
    },
    method: 'GET'
  })
}

// 新版工作台-接口：课-修改辅讲老师
export function EditAuxiliarySpeakForScheduleCourse(
  ScheduleCourseKey,
  ATeaKeyName,
  ATeaName
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.EditAuxiliarySpeakForScheduleCourse',
      ScheduleCourseKey,
      ATeaKeyName,
      ATeaName
    },
    method: 'get'
  })
}
// 预览学生欠课时通知
export function PreviewDebtClassHourNotification(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.WeChatOperation.PreviewDebtClassHourNotification',
      StudentKey: StudentKey // 学生id
    },
    method: 'GET'
  })
}
// 发送学生欠课时通知
export function DebtClassHourNotification(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.WeChatOperation.DebtClassHourNotification',
      StudentKey: StudentKey // 学生id
    },
    method: 'GET'
  })
}
// 预览欠费通知
export function PreviewArrearageNotification(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.WeChatOperation.PreviewArrearageNotification',
      StudentKey: StudentKey // 学生id
    },
    method: 'GET'
  })
}
// 发送欠费通知
export function ArrearageNotification(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.WeChatOperation.ArrearageNotification',
      StudentKey: StudentKey // 学生id
    },
    method: 'GET'
  })
}
// 预览课时不足通知
export function PreviewLackClassHourNotification(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.WeChatOperation.PreviewLackClassHourNotification',
      StudentKey: StudentKey // 学生id
    },
    method: 'GET'
  })
}
// 发送课时不足通知
export function LackClassHourNotification(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.WeChatOperation.LackClassHourNotification',
      StudentKey: StudentKey // 学生id
    },
    method: 'GET'
  })
}
// 微信管理->添加微信公众号模板 WKTBack.WeChartManagement.AddWeChatTemplate
export function AddWeChatTemplate(data, keyword_name) {
  keyword_name = {
    keyword_name: JSON.stringify(keyword_name)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.WeChartManagement.AddWeChatTemplate',
      number: data.number,
      name: data.name
    },
    method: 'post',
    data: qs.stringify(keyword_name)
  })
}
// 排课规律-》获取规律最近一节课课信息
export function GetStructuredRuleRecentlyCourseInfo(RuleId) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.GetStructuredRuleRecentlyCourseInfo',
      RuleId
    },
    method: 'GET'
  })
}
// 新增物品
export function AddGoods(IsContinue, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PurchaseSaleStock.AddGoods',
      IsContinue: IsContinue // 整型	可选		数据源：GET	是否继续
    },
    method: 'POST',
    data: qs.stringify(Data), //    { "MainDemoName":"物品名称" ,"GoodsTypeKey":"所属物品类型"  ,"GoodsTypeKeyValue":"" ,"IsSellKey":"是否销售" ,"IsSellKeyValue":"" ,"IncidentalsKey":"关联杂费" ,"IncidentalsKeyValue":""  ,"SalesPriceAmount":"销售单价"}

    checkResubmit: true
  })
}
// 新增物品类型
export function AddGoodsType(MainDemoName, IsContinue) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PurchaseSaleStock.AddGoodsType',
      MainDemoName: MainDemoName, //	字符串	必须		数据源：GET	物品类型名称
      IsContinue: IsContinue // 整型	可选		数据源：GET	是否继续
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 修改物品
export function EditGoods(OLAPKey, IsContinue, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PurchaseSaleStock.EditGoods',
      OLAPKey: OLAPKey, //	整型	必须		数据源：GET	物品id
      IsContinue: IsContinue // 整型	可选		数据源：GET	是否继续
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 修改物品类型
export function EditGoodsType(OLAPKey, MainDemoName, IsContinue) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PurchaseSaleStock.EditGoodsType',
      OLAPKey: OLAPKey, // 整型	必须		数据源：GET	物品类型id
      MainDemoName: MainDemoName, //	字符串	必须		数据源：GET	物品类型名称
      IsContinue: IsContinue // 整型	可选		数据源：GET	是否继续
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 停用物品
export function StopGoods(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PurchaseSaleStock.StopGoods',
      OLAPKey: OLAPKey // 整型	必须		数据源：GET	物品类型id
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 停用物品类型
export function StopGoodsType(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PurchaseSaleStock.StopGoodsType',
      OLAPKey: OLAPKey // 整型	必须		数据源：GET	物品类型id
    },
    method: 'GET',
    checkResubmit: true
  })
}

// 获取物品详情
export function GetGoodsDetails(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PurchaseSaleStock.GetGoodsDetails',
      OLAPKey: OLAPKey // 整型	必须		数据源：GET	物品id
    },
    method: 'GET'
  })
}
// 获取物品类型详情
export function GetGoodsTypeDetails(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PurchaseSaleStock.GetGoodsTypeDetails',
      OLAPKey: OLAPKey // 整型	必须		数据源：GET	物品类型id
    },
    method: 'GET'
  })
}
// 获取物品列表
export function GetGoodsList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PurchaseSaleStock.GetGoodsList'
    },
    method: 'GET'
  })
}
// 获取物品类型列表
export function GetGoodsTypeList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PurchaseSaleStock.GetGoodsTypeList'
    },
    method: 'GET'
  })
}
// 库存调整
export function GoodsInventoryAdjustment(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PurchaseSaleStock.GoodsInventoryAdjustment'
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 进货入库 / 物品出库;
export function StockShipmentInventory(TypeKey, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PurchaseSaleStock.StockShipmentInventory',
      TypeKey: TypeKey //	整型	必须		数据源：GET	出入库类型1-入库;2-出库
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 库存明细
export function MessageSearchInventory(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PurchaseSaleStock.MessageSearchInventory',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序字段
      GoodsTypeKey: parameter.GoodsTypeKey, // 物品类型key
      InStock: parameter.InStock, // 库存是否大于0 1-是;0-否
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 出入库记录
export function MessageSearchStockInOutRecord(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.PurchaseSaleStock.MessageSearchStockInOutRecord',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 	字符串	可选		数据源：GET	开始时间
      endTime: parameter.endTime, // 	字符串	可选		数据源：GET	结束时间
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序字段
      GoodsKey: parameter.GoodsKey, //	整型	可选		数据源：GET	物品key
      GoodsTypeKey: parameter.GoodsTypeKey, // 物品类型key
      TypeKey: parameter.TypeKey, // 0-全部;1-入库;2-出库;3-调增;4-调减;5-售出;6-退货
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 新安排试课 WEBV.TrialClass.MessageSearchPageTrialClassForArrange
export function MessageSearchPageTrialClassForArrange(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrialClass.MessageSearchPageTrialClassForArrange',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序字段
      stutasKey: parameter.stutasKey, // -全部;2-待安排;4-已安排;5-已取消;6-已拒绝;7-已放弃
      CourseNameKey: parameter.CourseNameKey,
      startTime: parameter.startTime,
      endTime: parameter.endTime,
      sourceKey: parameter.sourceKey, // 来源:0-全部;2-员工录入;3-线上申请
      QueryID: parameter.QueryID, // 数据ID
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 线上申请列表  WEBV.TrialClass.MessageSearchPageTrialClassForOnlineApplication
export function MessageSearchPageTrialClassForOnlineApplication(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.TrialClass.MessageSearchPageTrialClassForOnlineApplication',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序字段
      stutasKey: parameter.stutasKey, // -全部;2-待安排;4-已安排;5-已取消;6-已拒绝;7-已放弃
      CourseNameKey: parameter.CourseNameKey,
      startTime: parameter.startTime,
      endTime: parameter.endTime,
      sourceKey: parameter.sourceKey, // 来源:0-全部;2-员工录入;3-线上申请
      QueryID: parameter.QueryID, // 数据ID
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 报名缴费->获取指定学生的客户信息及客户底下学生信息
export function GetCustomerStudentByStudent(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetCustomerStudentByStudent',
      StudentKey // 学生id
    },
    method: 'GET'
  })
}
// 添加跟进记录
export function AddFollowUpRecords(TableID, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrialClass.AddFollowUpRecords',
      TableID: TableID
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 指定安排试课的跟进记录 WEBV.TrialClass.SelectFollowUpRecords
export function SelectFollowUpRecords(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrialClass.SelectFollowUpRecords',
      TableID: TableID
    },
    method: 'get'
  })
}
// 放弃试课
export function AbandonFollowUp(TableID, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrialClass.AbandonFollowUp',
      TableID: TableID
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 关联报名 WEBV.TrialClass.TrialRelevanceApply
export function TrialRelevanceApply(TrialKey, ApplyKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrialClass.TrialRelevanceApply',
      TrialKey: TrialKey,
      ApplyKey: ApplyKey
    },
    method: 'get',
    checkResubmit: true
  })
}
// 预约安排试课
export function ApplyTrialClassToArrange(ID, CourseKey, Remarks) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrialClass.ApplyTrialClassToArrange',
      ID: ID,
      CourseKey: CourseKey,
      Remarks: Remarks
    },
    method: 'get',
    checkResubmit: true
  })
}
// 恢复试课记录
export function RecoverTrialClass(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrialClass.RecoverTrialClass',
      ID: ID
    },
    method: 'get',
    checkResubmit: true
  })
}
// 重排试课
export function ResetArrangeTrialClass(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrialClass.ResetArrangeTrialClass',
      TableID: data.TableID,
      StudentKey: data.StudentKey,
      SchelduleCourseKey: data.SchelduleCourseKey
    },
    method: 'get',
    checkResubmit: true
  })
}
// 招生工作台--安排试课
export function ArrangeTrialLesson(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.ArrangeTrialLesson',
      StudentThreadKey: data.StudentThreadKey,
      ScheduleCourseKey: data.ScheduleCourseKey,
      StudentKey: data.StudentKey,
      TrialLessonKey: data.TrialLessonKey
    },
    method: 'get',
    checkResubmit: true
  })
}
// 指定学生的报名缴费记录 WEBV.TrialClass.GetApplyPaymentForStudent
export function GetApplyPaymentForStudent(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrialClass.GetApplyPaymentForStudent',
      StudentKey: StudentKey
    },
    method: 'get'
  })
}
// 获取门店优惠券列表
export function FiltrateCoupon(
  pageIndex,
  pageSize,
  sequence,
  orderBy,
  searchText,
  stutasKey,
  typeKey,
  IsEnableKey,
  IsActivity
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.Coupon.MessageSearchPageForCoupon',
      pageIndex, // 必须  页码 从0开始，不可空
      pageSize, // 必须 每页条数 不可空，为0表示差所有
      sequence, // 排序方向 为空则默认倒序 ASC正序 DESC倒序
      orderBy, // 排序字段 为空默认为建立时间
      searchText, // 模糊查询文本 模糊匹配：券名，活动标题
      stutasKey, // 0所有 1进行中2已过期3停用
      typeKey, // 0所有 1极客券 2优惠券 3裂变券
      IsEnableKey, // 是否过滤已停用的券 0否 1是
      IsActivity // 0-全部;1-活动券
    },
    method: 'GET'
  })
}
export function FiltrateCouponList(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.Coupon.MessageSearchPageForCoupon',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选	DESC	数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配：券名，活动标题
      stutasKey: parameter.stutasKey, // 字符串	可选	0	数据源：GET	券状态1-进行中;2-已过期;3-停用;
      typeKey: parameter.typeKey //	字符串	可选	0	数据源：GET	券类 1-转介绍券;2-普通券;3-裂变券;
    },
    method: 'GET'
  })
}

// 获取指定优惠券信息
export function GetCouponInfoById(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.ActivityMonitoringPlatform.GetCouponInfoById',
      row: id // 优惠券id
    },
    method: 'GET'
  })
}

// 获取 历史战况
export function HistoricalSituation(id, farthestid, querynum) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.ActivityMonitoringPlatform.HistoricalSituation',
      id, // 优惠券id
      farthestid, // 最远数据id(往上滑动时 获取最后一条数据ID)
      querynum // 往前查询条数(每次查询条数)
    },
    method: 'GET'
  })
}
// 获取 最新战况
export function LatestActionQuery(id, time, farthestid) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.ActivityMonitoringPlatform.LatestActionQuery',
      id, // 优惠券id
      time,
      farthestid // 最远数据id(往上滑动时 获取最后一条数据ID)
    },
    method: 'GET'
  })
}
// 获取 老师提成
export function TeacherCommission(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.ActivityMonitoringPlatform.TeacherCommission',
      id // 优惠券ID
    },
    method: 'GET'
  })
}
// 获取 分销奖励
export function DistributionRewards(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.ActivityMonitoringPlatform.DistributionRewards',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序方向
      sequence: queryParams.sequence, // 排序字段
      id: queryParams.id // 优惠券id
    },
    method: 'GET'
  })
}
// 获取 介绍奖励
export function IntroduceRewardQuery(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.ActivityMonitoringPlatform.IntroduceRewardQuery',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序方向
      sequence: queryParams.sequence, // 排序字段
      id: queryParams.id // 优惠券id
    },
    method: 'GET'
  })
}
// 获取 浏览奖励
export function BrowseRrewards(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.ActivityMonitoringPlatform.BrowseRrewards',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序方向
      sequence: queryParams.sequence, // 排序字段
      id: queryParams.id // 优惠券id
    },
    method: 'GET'
  })
}

// 获取 老师提成明细
export function TeacherCommissionDetails(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.ActivityMonitoringPlatform.TeacherCommissionDetails',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序方向
      sequence: queryParams.sequence, // 排序字段
      id: queryParams.id, // 优惠券id
      agentid: queryParams.agentid // 老师id
    },
    method: 'GET'
  })
}
// 获取 分销奖励明细
export function DistributionRewardsDetails(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.ActivityMonitoringPlatform.DistributionRewardsDetails',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序方向
      sequence: queryParams.sequence, // 排序字段
      id: queryParams.id, // 优惠券id
      clientid: queryParams.clientid // 老师id
    },
    method: 'GET'
  })
}
// 获取 介绍奖励明细
export function IntroduceRewardDetail(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.ActivityMonitoringPlatform.IntroduceRewardDetail',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序方向
      sequence: queryParams.sequence, // 排序字段
      id: queryParams.id, // 优惠券id
      clientid: queryParams.clientid // 老师id
    },
    method: 'GET'
  })
}
// 获取 浏览奖励明细
export function BrowseRrewardsDetails(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.ActivityMonitoringPlatform.BrowseRrewardsDetails',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序方向
      sequence: queryParams.sequence, // 排序字段
      id: queryParams.id, // 优惠券id
      clientid: queryParams.clientid // 转发人id
    },
    method: 'GET'
  })
}
// 获取 领券明细
export function CouponRedemptionDetails(queryParams, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.ActivityMonitoringPlatform.CouponRedemptionDetails',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序方向
      sequence: queryParams.sequence, // 排序字段
      id: queryParams.id, // 优惠券id
      isshare: queryParams.isshare, // 是否分享
      IsExportKey: queryParams.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 浏览礼品发放
export function BrowseGiftDistribution(policytype, id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.ActivityMonitoringPlatform.BrowseGiftDistribution',
      policytype: policytype,
      id: id // 用户券id
    },
    method: 'GET'
  })
}
// 家长推广分销奖励发放
export function ParentsPromoteDistributionIncentives(id, type) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WKTB.ActivityMonitoringPlatform.ParentsPromoteDistributionIncentives',
      id, // 用户券id
      type // 奖励类型 必有 0全部 1返现 2课时 3礼品
    },
    method: 'GET'
  })
}

// 获取 拼团奖励
export function MessageSearchPageForGroup(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.Group.MessageSearchPageForGroup',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序方向
      sequence: queryParams.sequence, // 排序字段
      couponKey: queryParams.id // 优惠券id
    },
    method: 'GET'
  })
}

// 获取 拼团奖励明细
export function MessageSearchPageForReword(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.Group.MessageSearchPageForReword',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序方向
      sequence: queryParams.sequence, // 排序字段
      groupKey: queryParams.id // 优惠券id
    },
    method: 'GET'
  })
}

// 获取 拼团详情
export function GetGroupDetail(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.Group.GetGroupDetail',
      ID: ID // 优惠券id
    },
    method: 'GET'
  })
}

// 结算拼团奖励
export function FinishedGroup(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.Group.ManualSettleGroup',
      ID: ID // 优惠券id
    },
    method: 'GET'
  })
}

// 查询所有启用的适用机构 WKTBack.AgenciesManagement.SelectEnableApplicableAgencies
export function SelectEnableApplicableAgencies() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.AgenciesManagement.SelectEnableApplicableAgencies'
    },
    method: 'GET'
  })
}
// 查询所有启用的场景 WKTBack.SceneManagement.SelectEnableScene
export function SelectEnableScene() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.SceneManagement.SelectEnableScene'
    },
    method: 'GET'
  })
}
// 查询模板封面
export function SelectCoverTemplate(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CoverTemplateControl.SelectCoverTemplate',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序方向
      sequence: queryParams.sequence, // 排序字段
      SceneKey: queryParams.SceneKey,
      AgenciesKey: queryParams.AgenciesKey
    },
    method: 'GET'
  })
}
// 查询所有启用的活动玩法
export function SelectEnbleActivitiesPlay() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ActivitiesPlayManagement.SelectEnbleActivitiesPlay'
    },
    method: 'GET'
  })
} // 批量签到
export function KQBatchAttendance(StudentKeyList, ScheduleCourseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.KQ_BatchAttendance',
      ScheduleCourseKey, // 课ID
      client_id: store.getters.clientId // 用于异步任务通知
    },
    method: 'post',
    data: qs.stringify(StudentKeyList)
  })
}
// 批量旷课
export function KQBatchTruancy(StudentKeyList, ScheduleCourseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.KQ_BatchTruancy',
      ScheduleCourseKey, // 课ID
      client_id: store.getters.clientId // 用于异步任务通知
    },
    method: 'post',
    data: qs.stringify(StudentKeyList),
    checkResubmit: true
  })
}
// 批量请假
export function KQBatchLeave(StudentKeyList, ScheduleCourseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.KQ_BatchLeave',
      ScheduleCourseKey, // 课ID
      client_id: store.getters.clientId // 用于异步任务通知
    },
    method: 'post',
    data: qs.stringify(StudentKeyList),
    checkResubmit: true
  })
}
// 批量退课 WEBV.KQ.KQ_BatchDelete
export function KQBatchDelete(StudentKeyList, ScheduleCourseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.KQ_BatchDelete',
      ScheduleCourseKey, // 课ID
      StudentKeyList: StudentKeyList
      // client_id: store.getters.clientId// 用于异步任务通知
    },
    method: 'get'
    // data: qs.stringify(StudentKeyList)
    // checkResubmit: true
  })
}
// 考勤课消明细分页统计 WEBV.ClassAwayReport.MessageSearchClassAwayForAttence2
export function MessageSearchClassAwayForAttence2(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassAwayReport.MessageSearchClassAwayForAttence2',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      type: parameter.type,
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      CourseKey: parameter.CourseKey,
      GradeKey: parameter.GradeKey,
      TeacherKey: parameter.TeacherKey,
      ATeaKeyName: parameter.ATeaKeyName,
      ScheduleCourseKey: parameter.ScheduleCourseKey,
      StudentKey: parameter.StudentKey,
      AttenceStatusKey: parameter.AttenceStatusKey,
      CourseTimeType: parameter.CourseTimeType,
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 考勤课消明细分页统计  历史
export function MessageSearchClassAwayForAttence2History(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ClassAwayReport.MessageSearchClassAwayForAttence2History',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      type: parameter.type,
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      CourseKey: parameter.CourseKey,
      GradeKey: parameter.GradeKey,
      TeacherKey: parameter.TeacherKey,
      ATeaKeyName: parameter.ATeaKeyName,
      ScheduleCourseKey: parameter.ScheduleCourseKey,
      StudentKey: parameter.StudentKey,
      AttenceStatusKey: parameter.AttenceStatusKey,
      CourseTimeType: parameter.CourseTimeType,
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 分页查询活动券;
export function SelectActivitiesCoupon(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.PopularActivitiesManagement.SelectActivitiesCoupon',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序方向
      sequence: queryParams.sequence, // 排序字段
      online: queryParams.online //	字符串	可选		数据源：GET	 是否上架0-否;1-是
    },
    method: 'GET'
  })
}
// 移动活动
export function MoveActivitiesCoupon(CouponKey, sort) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.PopularActivitiesManagement.MoveActivitiesCoupon',
      CouponKey: CouponKey, // 优惠券id
      sort: sort //		数据源：GET	排序
    },
    method: 'GET'
  })
}
// 上架热门活动
export function PutAwayActivitiesCoupon(CouponKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.PopularActivitiesManagement.PutAwayActivitiesCoupon',
      CouponKey: CouponKey // 优惠券id
    },
    method: 'GET'
  })
}
// 下架热门活动
export function SoldOutActivitiesCoupon(CouponKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.PopularActivitiesManagement.SoldOutActivitiesCoupon',
      CouponKey: CouponKey // 优惠券id
    },
    method: 'GET'
  })
}
// 新增优惠券
export function AddSimpleCoupon(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.AddSimpleCoupon'
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 修改优惠券
export function EditSimpleCoupon(CouponKey, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.EditSimpleCoupon',
      CouponKey: CouponKey // 优惠券id
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 分页查询优惠券
export function SelectSimpleCoupon(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.SelectSimpleCoupon',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序方向
      sequence: queryParams.sequence, // 排序字段
      online: queryParams.online, //	字符串	可选		数据源：GET	 是否上架0-否;1-是
      isInvalid: queryParams.isInvalid
    },
    method: 'GET'
  })
}
// 分页查询所有普通券
export function MessageSearchSimpleCoupon(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.MessageSearchSimpleCoupon',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序方向
      sequence: queryParams.sequence // 排序字段
    },
    method: 'GET'
  })
}
// 翻页查询领券记录
export function MessageSearchCouponReceiveRecord(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.MessageSearchCouponReceiveRecord',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序方向
      sequence: queryParams.sequence, // 排序字段
      startTime: queryParams.startTime, //	字符串	可选		数据源：GET	 领券开始时间
      endTime: queryParams.endTime, //	字符串	可选		数据源：GET	领券结束时间
      source: queryParams.source, //	整型	可选		数据源：GET	领取来源：1.微课堂，2.客户档案送券，3.分享领取 4:手机掌柜赠券,5扫码关注领取,6微课堂(会员列表)赠送,7家长分销
      status: queryParams.status, //	整型	可选		数据源：GET	状态0-全部 1：未使用2已使用3.已过期
      QueryID: queryParams.QueryID
    },
    method: 'GET'
  })
}

// 翻页查询用券记录
export function MessageSearchCouponUseRecord(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.MessageSearchCouponUseRecord',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序方向
      sequence: queryParams.sequence, // 排序字段
      startTime: queryParams.startTime, //	字符串	可选		数据源：GET	 领券开始时间
      endTime: queryParams.endTime, //	字符串	可选		数据源：GET	领券结束时间
      source: queryParams.source, //	整型	可选		数据源：GET	领取来源：1.微课堂，2.客户档案送券，3.分享领取 4:手机掌柜赠券,5扫码关注领取,6微课堂(会员列表)赠送,7家长分销
      status: queryParams.status, //	整型	可选		数据源：GET	状态0-全部 1：未使用2已使用3.已过期
      CouponID: queryParams.CouponID
    },
    method: 'GET'
  })
}
// 获取优惠券详情
export function GetSimpleCouponInfo(CouponKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.GetSimpleCouponInfo',
      CouponKey: CouponKey // 优惠券id
    },
    method: 'GET'
  })
}

// 移动优惠券
export function MoveSimpleCoupon(CouponKey, sort) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.MoveSimpleCoupon',
      CouponKey: CouponKey, // 优惠券id
      sort: sort // 排序
    },
    method: 'GET'
  })
}

// 上架优惠券
export function PutAwaySimpleCoupon(CouponKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.PutAwaySimpleCoupon',
      CouponKey: CouponKey // 优惠券id
    },
    method: 'GET'
  })
}
// 下架优惠券
export function SoldOutSimpleCoupon(CouponKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.SoldOutSimpleCoupon',
      CouponKey: CouponKey // 优惠券id
    },
    method: 'GET'
  })
}
// 转为失效
export function ChangeToIsInvalid(CouponKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.ChangeToIsInvalid',
      CouponKey: CouponKey // 优惠券id
    },
    method: 'GET'
  })
}
// 删除优惠券
export function DeleteSimpleCoupon(CouponKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.DeleteSimpleCoupon',
      CouponKey: CouponKey // 优惠券id
    },
    method: 'GET'
  })
}
// 送券
export function SendCouponToCustomer(CouponKey, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.SendCouponToCustomer',
      CouponKey: CouponKey // 优惠券id
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 用户券过期提醒
export function ExpiredCouponNotification(UserCouponKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.ExpiredCouponNotification',
      UserCouponKey: UserCouponKey // 用户券id
    },
    method: 'GET'
  })
}

// 查询关联套餐
export function MessageSearchPagePackage(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Package.MessageSearchPagePackage',
      pageIndex: queryParams.pageIndex, // 页码，0为第一页
      pageSize: queryParams.pageSize, // 每页记录数，0：表示查询所有
      searchText: queryParams.searchText, // 搜索条件：模糊匹配
      orderBy: queryParams.orderBy, // 排序方向
      sequence: queryParams.sequence, // 排序字段
      StatusKey: queryParams.StatusKey //		套餐状态0-全部;2-启用;3-停用
    },
    method: 'GET'
  })
}

// 我的活动
export function MessageSearchActivitiesCoupon(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.MessageSearchActivitiesCoupon',
      pageIndex: queryParams.pageIndex,
      pageSize: queryParams.pageSize,
      searchText: queryParams.searchText,
      orderBy: queryParams.orderBy,
      sequence: queryParams.sequence,
      StartTime: queryParams.StartTime,
      EndTime: queryParams.EndTime
    },
    method: 'GET'
  })
}

// 获取活动券详情
export function GetActivitiesCouponInfo(CouponKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.GetActivitiesCouponInfo',
      CouponKey: CouponKey
    },
    method: 'GET'
  })
}
// WEB.Resource.SearchPage
export function ResourceSearchPage(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEB.Resource.SearchPage'
      // searchText: data.searchText,
      // resourceType: data.resourceType,  // 1图 2视频 3音频
      // category: data.category,
      // pageSize: data.pageSize,
      // pageIndex:data.pageIndex
    },
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 新增活动券
export function AddActivitiesCoupon(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.AddActivitiesCoupon'
    },
    method: 'POST',
    data: qs.stringify(Data)
  })
}
// 修改活动券
export function EditActivitiesCoupon(CouponKey, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.EditActivitiesCoupon',
      CouponKey: CouponKey
    },
    method: 'POST',
    data: qs.stringify(Data)
  })
}

export function getCouponPackage(CustomerKey, status) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.CouponPackage',
      CustomerKey,
      status
    },
    method: 'GET'
  })
}
// 获取玩法详情
export function GetActivitiesPlayByID(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ActivitiesPlayManagement.GetActivitiesPlayByID',
      id: id
    },
    method: 'GET'
  })
}
// 获取未加入轮播的活动券
export function SelectActivitiesCouponForBanner(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ImageCarousel.SelectActivitiesCouponForBanner',
      pageIndex: queryParams.pageIndex,
      pageSize: queryParams.pageSize,
      searchText: queryParams.searchText,
      orderBy: queryParams.orderBy,
      sequence: queryParams.sequence
    },
    method: 'GET'
  })
}
// 添加图片轮播
export function AddCorporateImage(ImageUrl, DataID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ImageCarousel.AddCorporateImage',
      ImageUrl: ImageUrl,
      DataID: DataID
    },
    method: 'GET'
  })
}
// 删除图片轮播
export function DeleteCorporateImage(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ImageCarousel.DeleteCorporateImage',
      id: id
    },
    method: 'GET'
  })
}
// 修改图片轮播
export function EditCorporateImage(ImageUrl, id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ImageCarousel.EditCorporateImage',
      ImageUrl: ImageUrl,
      id: id
    },
    method: 'GET'
  })
}
// 上移/下移/置顶 轮播图 WKTBack.ImageCarousel.MoveCorporateImage
export function MoveCorporateImage(id, save) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ImageCarousel.MoveCorporateImage',
      id: id,
      save: save
    },
    method: 'GET'
  })
}

// 停用活动玩法
export function StopActivitiesPlay(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ActivitiesPlayManagement.StopActivitiesPlay',
      id: id
    },
    method: 'GET'
  })
}

// 收付款日报
export function MessageSearchInComePayReportForDay(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.InComePayReport.MessageSearchInComePayReportForDay',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 收付款月报
export function MessageSearchInComePayReportForMonth(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.InComePayReport.MessageSearchInComePayReportForMonth',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 收付款-报名统计 WEBV.InComePayReport.MessageSearchInComePayReportForKDSY
export function MessageSearchInComePayReportForKDSY(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.InComePayReport.MessageSearchInComePayReportForKDSY',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      AccountTypeKey: parameter.AccountTypeKey, // 账户类型1储蓄卡;2信用卡;3支付宝;4微信;5现金;6其他
      IsExportKey: parameter.IsExportKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 首付款统计-其他收入统计
export function MessageSearchInComePayReportForOther(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.InComePayReport.MessageSearchInComePayReportForOther',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IncomePayAccountKey: parameter.IncomePayAccountKey,
      IncomePaySubKey: parameter.IncomePaySubKey,
      IsExportKey: parameter.IsExportKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 首付款统计-支出统计
export function MessageSearchInComePayReportForExpend(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.InComePayReport.MessageSearchInComePayReportForExpend',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IncomePayAccountKey: parameter.IncomePayAccountKey,
      IncomePaySubKey: parameter.IncomePaySubKey,
      IsExportKey: parameter.IsExportKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 首付款-课程销售明细
export function MessageSearchInComePayReportForCourse(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.InComePayReport.MessageSearchInComePayReportForCourse',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      Status: parameter.Status, // 状态0-全部;1-已用完;2-已过期
      ApplyTypeKey: parameter.ApplyTypeKey, // 报名类型1新生2续费3扩课
      CourseKey: parameter.CourseKey,
      IsExportKey: parameter.IsExportKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 首付款-杂费销售明细
export function MessageSearchInComePayReportForIncidentals(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.InComePayReport.MessageSearchInComePayReportForIncidentals',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 首付款-当前欠款
export function MessageSearchInComePayReportForCurrentDebt(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.InComePayReport.MessageSearchInComePayReportForCurrentDebt',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// 终止活动
export function TerminationActivity(CouponKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.CouponManagement.TerminationActivity',
      CouponKey: CouponKey
    },
    method: 'GET'
  })
}
// 收付款-历史记录
export function MessageSearchOwePaymentsReport(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.InComePayReport.MessageSearchOwePaymentsReport',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      TypeKey: parameter.TypeKey,
      CustKey: parameter.CustKey,
      IsExportKey: parameter.IsExportKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 收付款-还款记录 WEBV.InComePayReport.MessageSearchRepaymentReport
export function MessageSearchRepaymentReport(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.InComePayReport.MessageSearchRepaymentReport',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 收付款- 按月/年统计
export function SelectInComePayReportForGroup(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.InComePayReport.SelectInComePayReportForGroup',
      startTime: parameter.startTime,
      endTime: parameter.endTime,
      type: parameter.DateType
    },
    method: 'GET'
  })
}
// 收付款-活动报名统计
export function MessageSearchInComePayReportForCoupon(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.InComePayReport.MessageSearchInComePayReportForCoupon',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IncomePayAccountKey: parameter.IncomePayAccountKey,
      IncomePaySubKey: parameter.IncomePaySubKey,
      IsExportKey: parameter.IsExportKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// 获取活动券详情
export function StopNotEndStructuredRule(StructuredRuleKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.StopNotEndStructuredRule',
      StructuredRuleKey
    },
    method: 'GET'
  })
}
// 添加图片（权限）
export function AddBillResource(ID, data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEB.Resource.AddBillResource',
      ID: ID // 海报资源id
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 门店列表
export function MessageSearchSaaSClient(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.MessageSearchSaaSClient',
      pageIndex: queryParams.pageIndex,
      pageSize: queryParams.pageSize,
      searchText: queryParams.searchText,
      orderBy: queryParams.orderBy,
      sequence: queryParams.sequence,
      DredgeStatusKey: queryParams.DredgeStatusKey
    },
    method: 'GET'
  })
}
// 门店申请列表
export function MessageSearchApplySaaSClient(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.MessageSearchApplySaaSClient',
      pageIndex: queryParams.pageIndex,
      pageSize: queryParams.pageSize,
      searchText: queryParams.searchText,
      orderBy: queryParams.orderBy,
      sequence: queryParams.sequence
    },
    method: 'GET'
  })
}
// 清除数据
export function ClearSaaSClientData(OLAPKey, DelScope) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.ClearSaaSClientData',
      OLAPKey: OLAPKey, //	整型	必须		数据源：GET	门店key
      DelScope: DelScope //	字符串	必须		数据源：GET	[1,2,3] json数据 1-业务;2-客户;3-课次;4-基础资料
    },
    method: 'GET'
  })
}
// 导出学生课单
export function ExportStudentXYKD(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.ExportStudentXYKD',
      OLAPKey: OLAPKey //	整型	必须		数据源：GET	门店key
    },
    method: 'GET'
  })
}
// 关停
export function SaaSClientCloseDown(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.SaaSClientCloseDown',
      OLAPKey: OLAPKey //	整型	必须		数据源：GET	门店key
    },
    method: 'GET'
  })
}
// 永久关停
export function SaaSClientForOverCloseDown(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.SaaSClientForOverCloseDown',
      OLAPKey: OLAPKey //	整型	必须		数据源：GET	门店key
    },
    method: 'GET'
  })
}
// 正式续期
export function SaaSClientRenewal(OLAPKey, Time) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.SaaSClientRenewal',
      OLAPKey: OLAPKey, //	整型	必须		数据源：GET	门店key
      Time: Time //	字符串	必须		数据源：GET	续期时间
    },
    method: 'GET'
  })
}
// 临时续期
export function SaaSClientTempRenewal(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.SaaSClientTempRenewal',
      OLAPKey: OLAPKey //	整型	必须		数据源：GET	门店key
    },
    method: 'GET'
  })
}
// 重置管理员密码
export function ResetAdminPasswords(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.ResetAdminPasswords',
      OLAPKey: OLAPKey //	整型	必须		数据源：GET	门店key
    },
    method: 'GET'
  })
}
// 修改管理员手机号
export function EditAdminPhone(OLAPKey, MobilePhone) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.EditAdminPhone',
      OLAPKey: OLAPKey, //	整型	必须		数据源：GET	门店key
      MobilePhone: MobilePhone
    },
    method: 'GET'
  })
}
// 修改门店信息
export function EditSaaSClient(OLAPKey, data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.EditSaaSClient',
      OLAPKey: OLAPKey // 海报资源id
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取门店详情
export function GetSaaSClientDetails(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.GetSaaSClientDetails',
      OLAPKey: OLAPKey //	整型	必须		数据源：GET	门店key
    },
    method: 'GET'
  })
}
// 获取总部门店
export function SelectHQSaaSClient() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.SelectHQSaaSClient'
    },
    method: 'GET'
  })
}
// 补课管理--课程补课处理记录
export function MessageSearchPageMakeUpCourseStudensProcessingRecord(
  queryParams
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.MakeupManage.MessageSearchPageMakeUpCourseStudensProcessingRecord',
      pageIndex: queryParams.pageIndex,
      pageSize: queryParams.pageSize,
      searchText: queryParams.searchText,
      orderBy: queryParams.orderBy,
      sequence: queryParams.sequence,
      StartTime: queryParams.StartTime,
      EndTime: queryParams.EndTime,
      ClassTypeKey: queryParams.ClassTypeKey,
      MakeupResultKey: queryParams.MakeupResultKey, // 补课处理结果 0-待处理 1-已处理 不传是全部
      StudentKey: queryParams.StudentKey
    },
    method: 'GET'
  })
}
// 补课管理--未关联栏目
export function MessageSearchPageNoRelevanceList(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.MakeupManage.MessageSearchPageNoRelevanceList',
      pageIndex: queryParams.pageIndex,
      pageSize: queryParams.pageSize,
      searchText: queryParams.searchText,
      orderBy: queryParams.orderBy,
      sequence: queryParams.sequence,
      StudentKey: queryParams.StudentKey,
      StartTime: queryParams.StartTime,
      EndTime: queryParams.EndTime
    },
    method: 'GET'
  })
}
// 补课管理-关联补课
export function RelevanceMakeUp(
  StudentKey,
  ScheduleCourseKey,
  MakeUpScheduleCourseKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.MakeupManage.RelevanceMakeUp',
      StudentKey: StudentKey, // 学生key
      ScheduleCourseKey: ScheduleCourseKey, // 缺课ID
      MakeUpScheduleCourseKey: MakeUpScheduleCourseKey // 补课ID
    },
    method: 'GET'
  })
}
// 补课管理-不关联
export function MakeUpNoRelevance(StudentArrangeKey, NoRelevancyContent) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.MakeupManage.MakeUpNoRelevance',
      StudentArrangeKey: StudentArrangeKey, // 学生排课ID
      NoRelevancyContent: NoRelevancyContent // 不关联内容
    },
    method: 'GET'
  })
}
// 补课管理 补课处理记录 查看
export function GetMakeUpHistoryRecord(StudentKey, ScheduleCourseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.MakeupManage.GetMakeUpHistoryRecord',
      StudentKey: StudentKey, // 学生key
      ScheduleCourseKey: ScheduleCourseKey // 缺课ID
    },
    method: 'GET'
  })
}
// 门店管理历史记录
export function MessageSearchSaaSClientOperationLog(queryParams) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.MessageSearchSaaSClientOperationLog',
      pageIndex: queryParams.pageIndex,
      pageSize: queryParams.pageSize,
      searchText: queryParams.searchText,
      orderBy: queryParams.orderBy,
      sequence: queryParams.sequence,
      OperationTypeKey: queryParams.OperationTypeKey
    },
    method: 'GET'
  })
}
// 永久开通门店
export function DredgeForeverSaaSClient(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.DredgeForeverSaaSClient',
      OLAPKey: OLAPKey // 申请记录id
    },
    method: 'GET'
  })
}

// 临时开通门店
export function DredgeTempSaaSClient(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.DredgeTempSaaSClient',
      OLAPKey: OLAPKey // 申请记录id
    },
    method: 'GET'
  })
}

export function MessageSearchActivityForApply(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.ActivityMonitoringPlatform.MessageSearchActivityForApply',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选	DESC	数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配：券名，活动标题
      id: parameter.id, // 字符串	可选	0	数据源：GET	券状态1-进行中;2-已过期;3-停用;
      IsExportKey: parameter.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 分销统计
export function DistributionReport(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.ActivityMonitoringPlatform.DistributionReport',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选	DESC	数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配：券名，活动标题
      id: parameter.id // 字符串	可选	0	数据源：GET	券状态1-进行中;2-已过期;3-停用;
    },
    method: 'GET'
  })
}
// 分销统计列表
export function MessageSearchDistributionForAgent(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WKTB.ActivityMonitoringPlatform.MessageSearchDistributionForAgent',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选	DESC	数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配：券名，活动标题
      id: parameter.id,
      agentid: parameter.agentid
    },
    method: 'GET'
  })
}
// 获取省
export function SelectProvince() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Area.SelectProvince'
    },
    method: 'GET'
  })
}
// 获取指定省下面的市信息
export function SelectCityForProvince(ProvinceKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Area.SelectCityForProvince',
      ProvinceKey: ProvinceKey // 省id
    },
    method: 'GET'
  })
}
// 获取指定市下面的区信息
export function SelectAreaForCity(CityKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Area.SelectAreaForCity',
      CityKey: CityKey // 市id
    },
    method: 'GET'
  })
}
// 自用追销查询-售前跟单
export function MessageSearchPageForThreadMarket(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.MessageSearchPageForThreadMarket',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
      IntentionKey: parameter.IntentionKey, //	整型	必须		数据源：GET	意向1-S;2-A;3-B;4-C;
      StatusKey: parameter.StatusKey, //	整型	必须		数据源：GET	开户状态0-未开通;1-临时开通;2-正式开通
      FollowStartTime: parameter.FollowStartTime, //	字符串	可选		数据源：GET	跟进日期开始
      FollowEndTime: parameter.FollowEndTime, //	字符串	可选		数据源：GET	跟进日期结束
      DemoStartTime: parameter.DemoStartTime, //	字符串	可选		数据源：GET	演示日期开始
      DemoEndTime: parameter.DemoEndTime, //	字符串	可选		数据源：GET	演示日期结束
      IsUseKey: parameter.IsUseKey, //	字符串	可选		数据源：GET	是否在用 空-不生效;0-否;1-是
      StartDredgeTime: parameter.StartDredgeTime, // 字符串	可选		数据源：GET	开户时间开始
      EndDredgeTime: parameter.EndDredgeTime, // 字符串	可选		数据源：GET	开户时间结束
      DocumentaryKey: parameter.DocumentaryKey, //	字符串	可选		数据源：GET	销售人id
      IsRestrictKey: parameter.IsRestrictKey, // 数据源：GET	是否全部 0-否;1-是
      IsAbandon: parameter.IsAbandon,
      ToAuditCount: parameter.ToAuditCount
    },
    method: 'GET'
  })
}
// 自用追销查询-售前跟单(最新)
export function MessageThreadPreSaleDateList(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.MessageThreadPreSaleDateList',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
      PreSaleProgressKey: parameter.PreSaleProgressKey, //	售前进度:0-待联系;1-跟进中;2-已成交
      IsPreSaleHQLabelKey: parameter.IsPreSaleHQLabelKey, //	1-是;0-否
      IsPreSaleCoursewareLabelKey: parameter.IsPreSaleCoursewareLabelKey, //	0-否;1-是
      IsPreSaleAcademicLabelKey: parameter.IsPreSaleAcademicLabelKey, //	0-否;1-是
      MoreLabelKey: parameter.MoreLabelKey, //	1-重点单;2-普通单;3-同行
      CruxActionKey: parameter.CruxActionKey, //	1-试用;2-定金;3-测试;4-合同;5-演示
      startTime: parameter.startTime, //	跟进开始时间
      endTime: parameter.endTime // 跟进结束时间
    },
    method: 'GET'
  })
}
// 售后跟单
export function MessageSearchPageForAfterSale(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.MessageSearchPageForAfterSale',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
      IntentionKey: parameter.IntentionKey, //	整型	必须		数据源：GET	意向1-S;2-A;3-B;4-C;
      FollowStartTime: parameter.FollowStartTime, //	字符串	可选		数据源：GET	跟进日期开始
      FollowEndTime: parameter.FollowEndTime, //	字符串	可选		数据源：GET	跟进日期结束
      DemoStartTime: parameter.DemoStartTime, //	字符串	可选		数据源：GET	演示日期开始
      DemoEndTime: parameter.DemoEndTime, //	字符串	可选		数据源：GET	演示日期结束
      IsUseKey: parameter.IsUseKey, //	字符串	可选		数据源：GET	是否在用 空-不生效;0-否;1-是
      IsGradeStarKey: parameter.IsGradeStarKey, //	字符串	可选		数据源：GET	是否为星 空-不生效;0-否;1-是
      IsGradeVKey: parameter.IsGradeVKey, //	字符串	可选		数据源：GET	是否为V 空-不生效;0-否;1-是
      StartCount: parameter.StartCount, //	整型	可选		数据源：GET	介绍起始数
      EndCount: parameter.EndCount, //	整型	可选		数据源：GET	介绍终止数
      StartBargain: parameter.StartBargain, //	整型	可选		数据源：GET	成交起始数
      EndBargain: parameter.EndBargain, //	整型	可选		数据源：GET	成交起始数
      StartStudentCount: parameter.StartStudentCount, //	整型	可选		数据源：GET	学生起始数
      EndStudentCount: parameter.EndStudentCount, //	整型	可选		数据源：GET	学生起始数
      StayBack: parameter.StayBack, //	整型	可选		数据源：GET	待返是否大于0 0-否;1-是
      Renew: parameter.Renew, //	整型	可选		数据源：GET	续费是否小于30天 0-否;1-是
      AttenRate: parameter.AttenRate, //	整型	可选		数据源：GET	考勤率是否小于50% 0-否;1-是
      StartDredgeTime: parameter.StartDredgeTime, // 字符串	可选		数据源：GET	开户时间开始
      EndDredgeTime: parameter.EndDredgeTime, // 字符串	可选		数据源：GET	开户时间结束
      DocumentaryKey: parameter.DocumentaryKey, //	字符串	可选		数据源：GET	销售人id
      IsBalance: parameter.IsBalance, // 整型	可选		数据源：GET	是否有尾款 0-否 1-是
      IsRestrictKey: parameter.IsRestrictKey, // 数据源：GET	是否全部 0-否;1-是
      IsAbandon: parameter.IsAbandon, // 整型	可选		数据源：GET	是否放弃 0-否；1-否
      IsParent: parameter.IsParent //	字符串	可选		数据源：GET	不显示总部
    },
    method: 'GET'
  })
}
// 管理与统计-》售前跟单1
export function MessageSearchPageThreadMarketForManage(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ManageReport.MessageSearchPageThreadMarketForManage',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
      IntentionKey: parameter.IntentionKey, //	整型	必须		数据源：GET	意向1-S;2-A;3-B;4-C;
      StatusKey: parameter.StatusKey, //	整型	必须		数据源：GET	开户状态0-未开通;1-临时开通;2-正式开通
      FollowStartTime: parameter.FollowStartTime, //	字符串	可选		数据源：GET	跟进日期开始
      FollowEndTime: parameter.FollowEndTime, //	字符串	可选		数据源：GET	跟进日期结束
      DemoStartTime: parameter.DemoStartTime, //	字符串	可选		数据源：GET	演示日期开始
      DemoEndTime: parameter.DemoEndTime, //	字符串	可选		数据源：GET	演示日期结束
      IsUseKey: parameter.IsUseKey, //	字符串	可选		数据源：GET	是否在用 空-不生效;0-否;1-是
      StartDredgeTime: parameter.StartDredgeTime, // 字符串	可选		数据源：GET	开户时间开始
      EndDredgeTime: parameter.EndDredgeTime, // 字符串	可选		数据源：GET	开户时间结束
      DocumentaryKey: parameter.DocumentaryKey, //	字符串	可选		数据源：GET	销售人id
      IsAbandon: parameter.IsAbandon, // 数据源：GET	是否放弃 0-否；1-是
      IsNewTheradKey: parameter.IsNewTheradKey, // 数据源：GET	 是否新单 0-否；1-是
      IsExportKey: parameter.IsExportKey // 整型	可选		数据源：GET	是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 管理与统计-》售后跟单
export function MessageSearchPageAfterSaleForManage(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ManageReport.MessageSearchPageAfterSaleForManage',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
      IntentionKey: parameter.IntentionKey, //	整型	必须		数据源：GET	意向1-S;2-A;3-B;4-C;
      FollowStartTime: parameter.FollowStartTime, //	字符串	可选		数据源：GET	跟进日期开始
      FollowEndTime: parameter.FollowEndTime, //	字符串	可选		数据源：GET	跟进日期结束
      DemoStartTime: parameter.DemoStartTime, //	字符串	可选		数据源：GET	演示日期开始
      DemoEndTime: parameter.DemoEndTime, //	字符串	可选		数据源：GET	演示日期结束
      IsUseKey: parameter.IsUseKey, //	字符串	可选		数据源：GET	是否在用 空-不生效;0-否;1-是
      IsGradeStarKey: parameter.IsGradeStarKey, //	字符串	可选		数据源：GET	是否为星 空-不生效;0-否;1-是
      IsGradeVKey: parameter.IsGradeVKey, //	字符串	可选		数据源：GET	是否为V 空-不生效;0-否;1-是
      StartCount: parameter.StartCount, //	整型	可选		数据源：GET	介绍起始数
      EndCount: parameter.EndCount, //	整型	可选		数据源：GET	介绍终止数
      StartBargain: parameter.StartBargain, //	整型	可选		数据源：GET	成交起始数
      EndBargain: parameter.EndBargain, //	整型	可选		数据源：GET	成交起始数
      StartStudentCount: parameter.StartStudentCount, //	整型	可选		数据源：GET	学生起始数
      EndStudentCount: parameter.EndStudentCount, //	整型	可选		数据源：GET	学生起始数
      StayBack: parameter.StayBack, //	整型	可选		数据源：GET	待返是否大于0 0-否;1-是
      Renew: parameter.Renew, //	整型	可选		数据源：GET	续费是否小于30天 0-否;1-是
      AttenRate: parameter.AttenRate, //	整型	可选		数据源：GET	考勤率是否小于50% 0-否;1-是
      StartDredgeTime: parameter.StartDredgeTime, // 字符串	可选		数据源：GET	开户时间开始
      EndDredgeTime: parameter.EndDredgeTime, // 字符串	可选		数据源：GET	开户时间结束
      StartExpireTime: parameter.StartExpireTime, // 字符串	可选		数据源：GET	续费日期起
      EndExpireTime: parameter.EndExpireTime, // 字符串 可选		数据源：GET	续费日期止
      DocumentaryKey: parameter.DocumentaryKey, //	字符串	可选		数据源：GET	销售人id
      IsBalance: parameter.IsBalance, // 整型	可选		数据源：GET	是否有尾款 0-否 1-是
      IsAbandon: parameter.IsAbandon, // 整型	可选		数据源：GET	是否放弃 0-否；1-否
      IsExportKey: parameter.IsExportKey, // 整型	可选		数据源：GET	是否导出：0-否;1-是;如果为是pageIndex传0
      IsParent: parameter.IsParent //	字符串	可选		数据源：GET	不显示总部
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 管理与统计-》 获取售前跟单统计
export function GetPreSalesReportForManage() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ManageReport.GetPreSalesReportForManage'
    },
    method: 'GET'
  })
}
// 管理与统计-》售后跟单统计
export function GetAfterSalesReportForManage() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ManageReport.GetAfterSalesReportForManage'
    },
    method: 'GET'
  })
}

// 添加线索
export function AddThreadMarket(data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.AddThreadMarket'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 修改线索
export function EditThreadMarket(ThreadKey, data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.EditThreadMarket',
      ThreadKey: ThreadKey //	整型	必须		数据源：GET	线索key
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取线索详情
export function GetThreadMarketDetails(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.GetThreadMarketDetails',
      OLAPKey: OLAPKey
    },
    method: 'GET'
  })
}
// 添加跟进记录
export function AddFollowRecord(data, ThreadKey, IsPushMsg) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.AddFollowRecord',
      ThreadKey: ThreadKey,
      IsPushMsg: IsPushMsg
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取售前跟单统计
export function GetPreSalesReport(IsRestrictKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.GetPreSalesReport',
      IsRestrictKey: IsRestrictKey // 数据源：GET	是否全部 0-否;1-是
    },
    method: 'GET'
  })
}
// 售后跟单统计
export function GetAfterSalesReport(IsRestrictKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.GetAfterSalesReport',
      IsRestrictKey: IsRestrictKey // 数据源：GET	是否全部 0-否;1-是
    },
    method: 'GET'
  })
}
// 获取申请记录详情
export function GetApplyDetails(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.GetApplyDetails',
      TableID: TableID
    },
    method: 'get'
  })
}
// 添加申请记录
export function AddApply(data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.AddApply'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 取消申请
export function CancelApply(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.CancelApply',
      TableID: TableID
    },
    method: 'get'
  })
}
// 授权码审核
export function AuthorizationCodeAuditApply(TableID, Code) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.AuthorizationCodeAuditApply',
      TableID: TableID,
      Code: Code
    },
    method: 'get'
  })
}
// 查询线索跟进记录 Admin.ThreadMarket.SelectFollowRecode
export function SelectFollowRecode(ThreadKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.SelectFollowRecode',
      ThreadKey: ThreadKey
    },
    method: 'get'
  })
}
// 跟进详情 Admin.ThreadMarket.GetFollowDetails
export function GetFollowDetails(FollowKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.GetFollowDetails',
      FollowKey: FollowKey
    },
    method: 'get'
  })
}
// 演示详情 Admin.ThreadMarket.GetDemoDetails
export function GetDemoDetails(DemoKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.GetDemoDetails',
      DemoKey: DemoKey
    },
    method: 'get'
  })
}
// 获取门店转介绍
export function MessageSearchPageForSaaSClientReferral(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.MessageSearchPageForSaaSClientReferral',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
      SaaSClientNameKey: parameter.SaaSClientNameKey //	数据源：GET	 门店id
    },
    method: 'GET'
  })
}

// 获取学分管理列表
export function MessageSearchPageForStudentCredit(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CreditManagement.MessageSearchPageForStudentCredit',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
    },
    method: 'GET'
  })
}

// 获取学分管理-兑换记录
export function MessageSearchPageForExchangeRecord(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CreditManagement.MessageSearchPageForExchangeRecord',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
    },
    method: 'GET'
  })
}

// 获取学分管理-学分明细
export function MessageSearchPageForCreditDetails(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CreditManagement.MessageSearchPageForCreditDetails',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
      StudentKey: parameter.StudentKey
    },
    method: 'GET'
  })
}

// 学分管理-获取门店学分设置
export function GetSaaSClientCreditRules() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CreditManagement.GetSaaSClientCreditRules'
    },
    method: 'GET'
  })
}

// 学分管理-修改门店学分设置
export function EditSaaSClientCreditRules(OLAPKey, data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CreditManagement.EditSaaSClientCreditRules',
      OLAPKey: OLAPKey
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 学分管理-翻页查询兑换礼品
export function MessageSearchPageForExchangeGoods(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CreditManagement.MessageSearchPageForExchangeGoods',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
    },
    method: 'GET'
  })
}

// 学分管理-添加兑换物品
export function AddExchangeGoods(data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CreditManagement.AddExchangeGoods'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 学分管理-删除兑换物品
export function DeleteExchangeGoods(GoodsKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CreditManagement.DeleteExchangeGoods',
      GoodsKey
    },
    method: 'GET'
  })
}

// 学分管理-修改兑换物品
export function EditExchangeGoods(OLAPKey, data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CreditManagement.EditExchangeGoods',
      OLAPKey: OLAPKey
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 学分管理-移动物品
export function MoveExchangeGoods(OLAPKey, SortCount) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CreditManagement.MoveExchangeGoods',
      OLAPKey,
      SortCount
    },
    method: 'GET'
  })
}

// 学分管理-获取指定学生可兑换的礼品
export function SelectExchangeGoodsForStudent(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CreditManagement.SelectExchangeGoodsForStudent',
      StudentKey
    },
    method: 'GET'
  })
}

// 学分管理-兑换礼品
export function ExchangeGift(StudentKey, GoodsKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CreditManagement.ExchangeGift',
      StudentKey,
      GoodsKey
    },
    method: 'GET'
  })
}

// 获取指定门店员工
export function GetAgentForSaaSClient(SaaSClientNameKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.GetAgentForSaaSClient',
      SaaSClientNameKey: SaaSClientNameKey //	数据源：GET	 门店id
    },
    method: 'GET'
  })
}
// 获取指定门店销售
export function GetSalesAgentForSaaSClient(SaaSClientNameKey, IsLeavekey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.GetSalesAgentForSaaSClient',
      SaaSClientNameKey: SaaSClientNameKey, //	数据源：GET	 门店id
      IsLeavekey: IsLeavekey // 是否离职 空-表示所有 0-否;1-是
    },
    method: 'GET'
  })
}

// 更新线索是否为星
export function UpdateGradeStar(OLAPKey, IsGradeStarKey, IsGradeStarKeyValue) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.UpdateGradeStar',
      OLAPKey: OLAPKey, //	整型	必须		数据源：GET	线索id
      IsGradeStarKey: IsGradeStarKey, //	整型	必须		数据源：GET	是否为星0-否;1-是
      IsGradeStarKeyValue: IsGradeStarKeyValue // 字符串	必须		数据源：GET	是否为星0-否;1-是
    },
    method: 'GET'
  })
}

// 更新线索是否为V
export function UpdateGradeV(OLAPKey, IsGradeVKey, IsGradeVKeyValue) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.UpdateGradeV',
      OLAPKey: OLAPKey, //	整型	必须		数据源：GET	线索id
      IsGradeVKey: IsGradeVKey, //	整型	必须		数据源：GET	是否为V 0-否;1-是
      IsGradeVKeyValue: IsGradeVKeyValue // 字符串	必须		数据源：GET	是否为V 0-否;1-是
    },
    method: 'GET'
  })
}
// 审批-开户
export function MessageSearchPageForAuditTempSaaSclient(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.MessageSearchPageForAuditTempSaaSclient',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
      StatusKey: parameter.StatusKey //	整型	必须		数据源：GET	审批状态1-待审;2-已审核
    },
    method: 'GET'
  })
}
// 审批-清数据
export function MessageSearchPageForClearSaaSClient(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.MessageSearchPageForClearSaaSClient',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
      StatusKey: parameter.StatusKey //	整型	必须		数据源：GET	审批状态1-待审;2-已审核
    },
    method: 'GET'
  })
}
// 审批-介绍费
export function MessageSearchPageForAuditReferral(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.MessageSearchPageForAuditReferral',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
      StatusKey: parameter.StatusKey //	整型	必须		数据源：GET	审批状态1-待审;2-已审核
    },
    method: 'GET'
  })
}
// 审批-付款
export function MessageSearchPageForAuditPayment(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.MessageSearchPageForAuditPayment',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
      StatusKey: parameter.StatusKey //	整型	必须		数据源：GET	审批状态1-待审;2-已审核
    },
    method: 'GET'
  })
}
// 审批-学员课单导出
export function MessageSearchPageForAuditExportXYKD(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.MessageSearchPageForAuditExportXYKD',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
      StatusKey: parameter.StatusKey //	整型	必须		数据源：GET	审批状态1-待审;2-已审核
    },
    method: 'GET'
  })
}
// 审批-续期
export function MessageSearchPageForRenewal(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.MessageSearchPageForRenewal',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
      StatusKey: parameter.StatusKey, //	整型	必须		数据源：GET	审批状态1-待审;2-已审核
      TypeKey: parameter.TypeKey //	整型	可选		数据源：GET	申请类型 0-全部（6/7）;6-临时延期;7-续费赠送
    },
    method: 'GET'
  })
}
// 审批-流量赠送
export function MessageSearchPageForFluxCombo(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.MessageSearchPageForFluxCombo',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
      StatusKey: parameter.StatusKey, //	整型	必须		数据源：GET	审批状态1-待审;2-已审核
      TypeKey: parameter.TypeKey //	整型	可选		数据源：GET	申请类型 0-全部（6/7）;6-临时延期;7-续费赠送8-赠送流量
    },
    method: 'GET'
  })
}
// 审批-提现
export function MessageSearchPageForExtractBalance(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.MessageSearchPageForExtractBalance',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
      StatusKey: parameter.StatusKey //	整型	必须		数据源：GET	审批状态1-待审;2-已审核
    },
    method: 'GET'
  })
}
// 同意-提现申请
export function AuditWithdrawalApplyForAdopt(ApplyID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.AuditWithdrawalApplyForAdopt',
      ApplyID: ApplyID //	整型	必须		数据源：GET	线索id
    },
    method: 'GET'
  })
}
// 拒绝-提现申请
export function AuditWithdrawalApplyForRefuse(ApplyID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.AuditWithdrawalApplyForRefuse',
      ApplyID: ApplyID //	整型	必须		数据源：GET	线索id
    },
    method: 'GET'
  })
}
// 同意-审核申请
export function AuditApply(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.AuditApply',
      TableID: TableID //	整型	必须		数据源：GET	线索id
    },
    method: 'GET'
  })
}
// 拒接-审核申请
export function RefuseApply(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.RefuseApply',
      TableID: TableID //	整型	必须		数据源：GET	线索id
    },
    method: 'GET'
  })
}
// 管理与统记--基本统计
export function GetElementaryStatistics() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ManageReport.GetElementaryStatistics'
    },
    method: 'GET'
  })
}
// 管理与统记--签约校区 Admin.ManageReport.SelectSignedCampus
export function SelectSignedCampus(dataObj) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ManageReport.SelectSignedCampus',
      StartTime: dataObj.StartTime,
      EndTime: dataObj.EndTime,
      Type: dataObj.DateType
    },
    method: 'GET'
  })
}
// 管理与统记--S榜单 Admin.ManageReport.MessageSearchPageForThreadReportForS
export function MessageSearchPageForThreadReportForS(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ManageReport.MessageSearchPageForThreadReportForS',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
    },
    method: 'GET'
  })
}
// 管理与统记--演示新榜单
export function MessageSearchPageForThreadReportForDemo(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ManageReport.MessageSearchPageForThreadReportForDemo',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
    },
    method: 'GET'
  })
}
// 管理与统记--签新校区榜
export function MessageSearchPageForSignedCampus(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ManageReport.MessageSearchPageForSignedCampus',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
    },
    method: 'GET'
  })
}
// 管理与统记--销售金额榜
export function MessageSearchPageForSaleAmount(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ManageReport.MessageSearchPageForSaleAmount',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
    },
    method: 'GET'
  })
}
// 管理与统记--转介绍
export function MessageSearchPageForReferral(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ManageReport.MessageSearchPageForReferral',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
    },
    method: 'GET'
  })
}
// 变更跟单人
export function UpdateDocumentary(
  OLAPKey,
  DocumentaryKey,
  DocumentaryKeyValue
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.UpdateDocumentary',
      OLAPKey: OLAPKey, //	整型	必须		数据源：GET	线索id
      DocumentaryKey: DocumentaryKey, //	整型	必须		数据源：GET	新的跟单人Key
      DocumentaryKeyValue: DocumentaryKeyValue //	字符串	必须		数据源：GET	新的跟单人KeyValue
    },
    method: 'GET'
  })
}
// 成长时光-在校学生翻页接口  WEBV.StudentGrowthTime.MessageSearchStudent
export function growthTimeMessageSearchStudent(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentGrowthTime.MessageSearchStudent',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
      StartTime: parameter.startTime,
      EndTime: parameter.endTime,
      CourseKey: parameter.CourseKey,
      MaxHour: parameter.MaxHour
    },
    method: 'GET'
  })
}
// 成长时光- 查询学生成长时光
export function MessageSearchStudentGrowthTime(
  parameter,
  CourseKey,
  StudentKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentGrowthTime.MessageSearchStudentGrowthTime',
      OrderByTime: parameter.OrderByTime,
      CourseKey: CourseKey,
      StudentKey: StudentKey
    },
    method: 'GET'
  })
}
// 成长时光-获取学生课程 WEBV.StudentGrowthTime.GetStudentCourse
export function GetStudentGrowthTimeCourse(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentGrowthTime.GetStudentCourse',
      StudentKey: StudentKey
    },
    method: 'GET'
  })
}
// 成长时光-添加报告
export function AddStudentGrowthTime(StudentKey, CourseNameKey, IsPush, data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentGrowthTime.AddStudentGrowthTime',
      StudentKey: StudentKey,
      CourseNameKey: CourseNameKey,
      IsPush: IsPush
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除成长报告 WEBV.StudentGrowthTime.DeleteStudentGrowthTime
export function DeleteStudentGrowthTime(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentGrowthTime.DeleteStudentGrowthTime',
      id: id
    },
    method: 'GET'
  })
}
// 成长时光-设置图片第一 WEBV.StudentGrowthTime.SetDefalutFirst
export function SetDefalutFirst(id, EssayID, StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StudentGrowthTime.SetDefalutFirst',
      id: id,
      EssayID: EssayID,
      StudentKey: StudentKey
    },
    method: 'GET'
  })
}
// 学生-微信解绑
export function WeChatUnbind(CustomerKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.WeChatUnbind',
      CustomerKey: CustomerKey // 客户key
    },
    method: 'GET'
  })
}
// 导出学员课单
export function ExportXYKD(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.ExportXYKD',
      OLAPKey: OLAPKey // 客户key
    },
    method: 'GET'
  })
}

// 更新用户状态
export function UpdateUserState(userID, loginTime, data) {
  data = {
    subscribeEventList: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Subscription.UpdateUserState',
      userID: userID,
      loginTime: loginTime,
      client_id: store.getters.clientId // 用于异步任务通知
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 取消订阅
export function CancelSubscription() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Subscription.CancelSubscription',
      client_id: store.getters.clientId // 用于异步任务通知
    },
    method: 'GET'
  })
}
// 导出在校学生课单
export function ExportStudnetXYKD() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.ExportStudnetXYKD'
    },
    method: 'GET'
  })
}
// 导出班级学生
export function ExportGradeStudent(GradeKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.ExportGradeStudent',
      GradeKey: GradeKey
    },
    method: 'GET'
  })
}

// 系统端口管理-分页查询(原校区管理)
export function SearchPageForCampus(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.systemPortManage.SearchPageForCampus',
      pageIndex: parameter.pageIndex,
      pageSize: parameter.pageSize,
      searchText: parameter.searchText,
      orderBy: parameter.orderBy,
      sequence: parameter.sequence,
      IsActiveKey: parameter.IsActiveKey, //	整型	可选		数据源：GET	是否活跃校区
      IsStopKey: parameter.IsStopKey, //	整型	可选		数据源：GET	是否停用
      IsExpiredKey: parameter.IsExpiredKey, //	整型	可选		数据源：GET	是否已过期
      IsExpired30Key: parameter.IsExpired30Key, //	整型	可选		数据源：GET	是否30天内过期
      portTypeKey: parameter.portTypeKey, // 整型	可选		数据源：GET	端口类型: 1-教务端口; 2-课件端口
      campusTypeKey: parameter.campusTypeKey // 数据源：GET	 校区类型: 1-直营店; 2-加盟店;3-课程合作
    },
    method: 'GET'
  })
}
// 分页查询端口使用记录
export function SearchPagePortConsumeRecord(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.systemPortManage.SearchPagePortConsumeRecord',
      pageIndex: parameter.pageIndex,
      pageSize: parameter.pageSize,
      searchText: parameter.searchText,
      orderBy: parameter.orderBy,
      sequence: parameter.sequence,
      portTypeKey: parameter.portTypeKey, // 整型	可选		数据源：GET	端口类型: 1-教务端口; 2-课件端口
      campusTypeKey: parameter.campusTypeKey // 整型	可选		数据源：GET	校区类型: 1-直营店; 2-加盟店;3-课程合作
    },
    method: 'GET'
  })
}
// 获取校区详情信息
export function GetCampusDetails(CampusKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusManagement.GetDetails',
      CampusKey: CampusKey
    },
    method: 'GET'
  })
}
// 获取校区端口基本统计信息
export function GetCampusPortBasicStatistics() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusManagement.GetCampusPortBasicStatistics'
    },
    method: 'GET'
  })
}
// 添加直营店
export function AddRetailStores(data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusManagement.AddRetailStores'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 添加加盟店
export function AddFranchisee(data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusManagement.AddFranchisee'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 修改加盟店
export function ModifyFranchisee(CampusKey, data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusManagement.ModifyFranchisee',
      CampusKey: CampusKey //	校区id
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 修改直营店
export function ModifyRetailStores(CampusKey, data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusManagement.ModifyRetailStores',
      CampusKey: CampusKey //	校区id
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 续费
export function CampusManagementRenew(data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusManagement.Renew'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 停用
export function CampusManagementDisable(CampusKey, sourceKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.systemPortManage.Disable',
      CampusKey: CampusKey, //	校区id
      sourceKey: sourceKey // 来源Key 1-教务端口，2-课件端口
    },
    method: 'GET'
  })
}
// 启用
export function CampusManagementEnable(CampusKey, sourceKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.systemPortManage.Enable',
      CampusKey: CampusKey, //	校区id
      sourceKey: sourceKey // 来源Key 1-教务端口，2-课件端口
    },
    method: 'GET'
  })
}
// 申请校区端口
export function ApplyCampusPort(data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusManagement.ApplyCampusPort'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 翻页查询加盟店
export function SearchPageForFranchisee(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.FranchiseeManagement.SearchPageForFranchisee',
      pageIndex: parameter.pageIndex,
      pageSize: parameter.pageSize,
      searchText: parameter.searchText,
      orderBy: parameter.orderBy,
      sequence: parameter.sequence,
      StartTime: parameter.StartTime, //	字符串	可选		数据源：GET	起始日期
      EndTime: parameter.EndTime, // 字符串	可选		数据源：GET	终止日期
      TypeKey: parameter.TypeKey, // 整型	可选		数据源：GET	加盟类型3-课件加盟;4-整店加盟
      IsExpire: parameter.IsExpire, // 整型	可选		数据源：GET	空表示条件不生效;1-过期,0-正常
      IsRenew: parameter.IsRenew, //	整型	可选		数据源：GET	空表示条件不生效;1-查找要续费的课件
      IsWarning: parameter.IsWarning, // 整型	可选		数据源：GET	空表示条件不生效;1-课件排课警戒
      IsAbort: parameter.IsAbort,
      HeadTypeKey: parameter.HeadTypeKey
    },
    method: 'GET'
  })
}
// 添加加盟店
export function FranchiseeManagementAdd(data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.FranchiseeManagement.Add'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 修改加盟店
export function FranchiseeManagementModify(CampusKey, data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.FranchiseeManagement.Modify',
      CampusKey: CampusKey //	校区id
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 收款
export function FranchiseeManagementCollect(data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.FranchiseeManagement.Collect'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取加盟店详情
export function FranchiseeManagementGetDetails(CampusKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.FranchiseeManagement.GetDetails',
      CampusKey: CampusKey
    },
    method: 'GET'
  })
}
// 查询指定门店授权课件列表（加盟店）
export function FranchiseeSearchAuthorizeCourseware(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.FranchiseeManagement.SearchAuthorizeCourseware',
      OLAPKey: OLAPKey
    },
    method: 'GET'
  })
}
// 查询直营店列表
export function SearchPageForRetailStores(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.RetailStoresManagement.SearchPageForRetailStores',
      pageIndex: parameter.pageIndex,
      pageSize: parameter.pageSize,
      searchText: parameter.searchText,
      orderBy: parameter.orderBy,
      sequence: parameter.sequence
    },
    method: 'GET'
  })
}
// 获取直营店详情
export function RetailStoresManagementGetDetails(CampusKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.RetailStoresManagement.GetDetails',
      CampusKey: CampusKey
    },
    method: 'GET'
  })
}
// 获取直营店规模统计
export function GetRetailStoresScaleStatistical() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.RetailStoresManagement.GetRetailStoresScaleStatistical'
    },
    method: 'GET'
  })
}
// 查询指定门店授权课件列表（直营店）
export function RetailSearchAuthorizeCourseware(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.RetailStoresManagement.SearchAuthorizeCourseware',
      OLAPKey: OLAPKey
    },
    method: 'GET'
  })
}
// 添加直营店
export function RetailStoresManagementAdd(data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.RetailStoresManagement.Add'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 修改直营店
export function RetailStoresManagementModify(CampusKey, data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.RetailStoresManagement.Modify',
      CampusKey: CampusKey //	校区id
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取直营店规模统计
export function GetOnJobEmployees() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.Common.GetOnJobEmployees'
    },
    method: 'GET'
  })
}
// 授权课件列表
export function SearchPageList(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.SearchPageList',
      pageIndex: parameter.pageIndex,
      pageSize: parameter.pageSize,
      searchText: parameter.searchText,
      orderBy: parameter.orderBy,
      sequence: parameter.sequence,
      type: parameter.type // 0：全部 1：警戒 2：预警 3:用完
    },
    method: 'GET'
  })
}
// 授权课件流水列表
export function SearchPageLogList(AuthorizationCoursewareKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.SearchPageLogList',
      AuthorizationCoursewareKey: AuthorizationCoursewareKey
    },
    method: 'GET'
  })
}
// // 授权
// export function Authorization (parameter) {
//   return fetchToken({
//     url: process.env.VUE_APP_WKT_API_Url,
//     params: {
//       service: 'HQ.AuthorizationCourseware.Authorization',
//       AuthorizationSaaSClientKey: parameter.AuthorizationSaaSClientKey, //	整型	必须		数据源：GET	授权校区id
//       AuthorizationSaaSClientKeyValue: parameter.AuthorizationSaaSClientKeyValue, //	字符串	必须		数据源：GET	授权校区名称
//       CoursewarePackageKey: parameter.CoursewarePackageKey, //	整型	必须		数据源：GET	课件包id
//       CoursewarePackageKeyValue: parameter.CoursewarePackageKeyValue, // 字符串	必须		数据源：GET	课件包名称
//       ClearingFormKey: parameter.ClearingFormKey, //	整型	必须		数据源：GET	结算方式
//       FeeAmount: parameter.FeeAmount, //	整型	必须		数据源：GET	费用
//       CounselorKey: parameter.CounselorKey, //	整型	必须		数据源：GET	销售顾问id
//       CounselorKeyValue: parameter.CounselorKeyValue, //	字符串	必须		数据源：GET	销售顾问
//       AuthorizeDeviceNum: parameter.AuthorizeDeviceNum, //	整型	必须		数据源：GET	授权硬件设备数量
//       ReleasedNum: parameter.ReleasedNum, //	整型	可选		数据源：GET	课次数量
//       Duration: parameter.Duration, //	整型	可选		数据源：GET	时长
//       IncomePayAccountKey: parameter.IncomePayAccountKey, //	整型	可选		数据源：GET	收款账户
//       IncomePayAccountKeyValue: parameter.IncomePayAccountKeyValue, //	字符串	可选		数据源：GET	收款账户value
//       IsLocationLockKey: parameter.IsLocationLockKey, //	字符串	可选		数据源：GET	是否开启定位锁定
//       IsAPPAuthKey: parameter.IsAPPAuthKey //	字符串	可选		数据源：GET	是否开启APP授权
//     },
//     method: 'GET'
//   });
// }
// 授权
export function Authorization(parameter) {
  // var data = {
  //   CoursewarePackageList: JSON.stringify(parameter.CoursewarePackageList),
  //   AuthorizationSaaSClientList: JSON.stringify(parameter.AuthorizationSaaSClientList)
  // };
  var data = {
    data: JSON.stringify(parameter)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.BatchAuthorization'
    },
    method: 'post',
    data: qs.stringify(data),
    checkResubmit: true
  })
}
// 续费详情
export function AuthorizationCoursewareRenewDetail(AuthorizationCoursewareKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.RenewDetail',
      AuthorizationCoursewareKey: AuthorizationCoursewareKey
    },
    method: 'GET'
  })
}
// 查询校区
export function SearchSaaClientList(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.SearchPageSaaClientList',
      pageIndex: parameter.pageIndex,
      pageSize: parameter.pageSize,
      searchText: parameter.searchText,
      orderBy: parameter.orderBy,
      sequence: parameter.sequence,
      HQClientKey: parameter.HQClientKey,
      IsOpenChannelKey: parameter.IsOpenChannelKey
    },
    method: 'GET'
  })
}
// 查询课件包
export function SearchCoursewarePackageForNormal() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.SearchCoursewarePackageForNormal'
    },
    method: 'GET'
  })
}
// 查询大课包
export function SearchCoursewarePackageForBigPackage() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.SearchCoursewarePackageForBigPackage'
    },
    method: 'GET'
  })
}
// 停用
export function AuthorizationCoursewareDisable(AuthorizationCoursewareKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.Disable',
      AuthorizationCoursewareKey: AuthorizationCoursewareKey // 授权课件id
    },
    method: 'GET'
  })
}
// 启用
export function AuthorizationCoursewareEnable(AuthorizationCoursewareKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.Enable',
      AuthorizationCoursewareKey: AuthorizationCoursewareKey // 授权课件id
    },
    method: 'GET'
  })
}
// 续费
export function AuthorizationCoursewareRenew(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.Renew',
      AuthorizationCoursewareKey: parameter.AuthorizationCoursewareKey, //		整型	必须		数据源：GET	授权课件id
      ReleasedNum: parameter.ReleasedNum, //	整型	必须		数据源：GET	授权课次
      FeeAmount: parameter.FeeAmount, //		整型	必须		数据源：GET	收费金额
      AuthorizeDeviceNum: parameter.AuthorizeDeviceNum, //		整型	必须		数据源：GET	授权硬件设备
      Duration: parameter.Duration, //		整型	可选		数据源：GET	时长
      IncomePayAccountKey: parameter.IncomePayAccountKey, //		整型	可选		数据源：GET	 收款账户key
      IncomePayAccountKeyValue: parameter.IncomePayAccountKeyValue, //		字符串	可选		数据源：GET	 收款账户value
      CounselorKey: parameter.CounselorKey, //	整型	必须		数据源：GET	加盟顾问
      CounselorKeyValue: parameter.CounselorKeyValue, //	字符串	必须		数据源：GET	加盟顾问
      IsLocationLockKey: parameter.IsLocationLockKey, //	字符串	必须		数据源：GET	是否开启定位锁定
      IsAPPAuthKey: parameter.IsAPPAuthKey, //	字符串	可选		数据源：GET	是否开启APP授权
      startTime: parameter.startTime,
      endTime: parameter.endTime
    },
    method: 'GET'
  })
}
// 修改授权设备数
export function UpdateAuthorizeDeviceNum(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.UpdateAuthorizeDeviceNum',
      AuthorizationCoursewareKey: parameter.AuthorizationCoursewareKey, // 授权课件id
      AuthorizeDeviceType: parameter.AuthorizeDeviceType, // 授权设备数 PC - 1 APP-2 TV -3
      IsLocationLockKey: parameter.IsLocationLockKey, // 是否开启定位锁定
      IsAuthKey: parameter.IsAuthKey, // 是否开启授权
      AuthorizeDeviceNum: parameter.AuthorizeDeviceNum, //	授权设备数
      IsRecordKey: parameter.IsRecordKey,
      PositionalAccuracyKey: parameter.PositionalAccuracyKey,
      IsTVGatewayLogin: parameter.IsTVGatewayLogin || 0,
      IsPCGatewayLogin: parameter.IsPCGatewayLogin || 0
    },
    method: 'GET'
  })
}
// 修改授权设备数
export function UpdateRecordKeyStatus(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.RecordKeyStatus',
      OLAPKey: parameter.OLAPKey, // 授权课件id
      Status: parameter.Status // 状态
    },
    method: 'GET'
  })
}
// 修改门店设置 - 总部
export function ModifySaaSClientSetting(data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.SaaSClientSet.ModifySaaSClientSetting'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 修改门店设置 - 教务
export function WEBVModifySaaSClientSetting(data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.BatchModifySetting'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 修改门店设置 - 课件
export function CWSModifySaaSClientSetting(data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.SaaSClientManagement.BatchModifySetting'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取指定年月连锁门店实时统计业绩
export function GetRealTimeStatisticByDate(date) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.WorkBench.GetRealTimeStatisticByDate',
      Date: date
    },
    method: 'GET'
  })
}
// 获取连锁门店规模统计
export function GetChainaaSClientScaleStatistic() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.WorkBench.GetChainaaSClientScaleStatistic'
    },
    method: 'GET'
  })
}
// 获取连锁门店近12月增长趋势
export function GetGrowthTrendForLately12Month() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.WorkBench.GetGrowthTrendForLately12Month'
    },
    method: 'GET'
  })
}
// 获取门店设置HQ.Person.GetSaaSClientSet
export function GetSaaSClientSet() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.Person.GetSaaSClientSet'
    },
    method: 'GET'
  })
}
// 预警忽略 HQ.WorkBench.IgnoreEarlyWarning
export function IgnoreEarlyWarning(DataKey, TypeKey, Data, SchoolKey) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.WorkBench.IgnoreEarlyWarning',
      DataKey: DataKey,
      TypeKey: TypeKey,
      SchoolKey: SchoolKey
    },
    data: qs.stringify(Data),
    method: 'POST'
  })
}
// 查询分页智能预警
export function SearchPageForSmartEarlyWarning(dataObj) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.WorkBench.SearchPageForSmartEarlyWarning',
      pageSize: dataObj.PageSize,
      pageIndex: dataObj.PageIndex
    },
    method: 'GET'
  })
}
// 查询分页待办事项
export function SearchPageForToDo(dataObj) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.WorkBench.SearchPageForToDo',
      pageSize: dataObj.PageSize,
      pageIndex: dataObj.PageIndex
    },
    method: 'GET'
  })
}
// 课件包审核通过
export function CoursewareAuditPass(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewarePackageManage.AuditPass',
      CoursewarePackageKey: CoursewarePackageKey
    },
    method: 'GET'
  })
}
// 课件包拒绝
export function CoursewareRefuse(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewarePackageManage.Refuse',
      CoursewarePackageKey: CoursewarePackageKey
    },
    method: 'GET'
  })
}
// 课件包撤回
export function CoursewareWithdraw(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewarePackageManage.Withdraw',
      CoursewarePackageKey: CoursewarePackageKey
    },
    method: 'GET'
  })
}
// 获取标准作业题库详情
export function StandardHomeworkWarehouse(HomeworkWarehouseID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.StandardHomeworkWarehouse.Get',
      HomeworkWarehouseID: HomeworkWarehouseID // 作业题库id
    },
    method: 'GET'
  })
}
// 获取视频封面截图
export function getVideoCoverImages(videoPath) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEB.Pic.GetCoverImages',
      filePath: videoPath,
      client_id: store.getters.clientId
    },
    method: 'GET'
  })
}

// 课件编辑-获取课件详情
export function CoursewareManageGetEditDetail(CoursewareID, Data) {
  var data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.GetEditDetail',
      CoursewareID: CoursewareID,
      client_id: store.getters.clientId
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 课件编辑-获取课件详情
export function WEBVCoursewareManageGetEditDetail(CoursewareID, Data) {
  var data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.GetEditDetail',
      CoursewareID: CoursewareID,
      client_id: store.getters.clientId
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// CWS课件编辑-获取课件详情
export function CWSCoursewareManageGetEditDetail(CoursewareID, Data) {
  var data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareManage.GetEditDetail',
      CoursewareID: CoursewareID,
      client_id: store.getters.clientId
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 课件编辑-新增
export function hqCoursewareManageAdd(CoursewarePackageID, Data) {
  var data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.Add',
      CoursewarePackageID: CoursewarePackageID
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 课件编辑-新增（普通）
export function CoursewareManageAdd(CoursewarePackageID, Data) {
  var data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.Add',
      CoursewarePackageID: CoursewarePackageID
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除
export function CoursewareManageDelete(CoursewareID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.Delete',
      CoursewareID: CoursewareID // 课件ID
    },
    method: 'GET'
  })
}
// 删除
export function hqCoursewareManageDelete(CoursewareID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.Delete',
      CoursewareID: CoursewareID // 课件ID
    },
    method: 'GET'
  })
}
// 删除
export function cwsCoursewareManageDelete(CoursewareID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareManage.Delete',
      CoursewareID: CoursewareID // 课件ID
    },
    method: 'GET'
  })
}

// 课件编辑-保存
export function CoursewareManageSave(
  CoursewareID,
  CoursewareTitle,
  TeachingProcedure,
  CoverUrl,
  CoverRatio,
  IsOpenAdvance,
  AdvanceContent
) {
  var data = {
    TeachingProcedure: TeachingProcedure
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.Save',
      CoursewareID: CoursewareID,
      CoursewareTitle: CoursewareTitle,
      CoverUrl: CoverUrl,
      CoverRatio: CoverRatio,
      IsOpenAdvance: IsOpenAdvance,
      AdvanceContent: AdvanceContent
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 课件编辑-保存
export function WEBVCoursewareManageSave(
  CoursewareID,
  CoursewareTitle,
  TeachingProcedure,
  CoverUrl,
  CoverRatio,
  IsOpenAdvance,
  AdvanceContent
) {
  var data = {
    TeachingProcedure: TeachingProcedure
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.Save',
      CoursewareID: CoursewareID,
      CoursewareTitle: CoursewareTitle,
      CoverUrl: CoverUrl,
      CoverRatio: CoverRatio,
      IsOpenAdvance: IsOpenAdvance,
      AdvanceContent: AdvanceContent
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 课件编辑-保存
export function CWSCoursewareManageSave(
  CoursewareID,
  CoursewareTitle,
  TeachingProcedure,
  CoverUrl,
  CoverRatio,
  IsOpenAdvance,
  AdvanceContent
) {
  var data = {
    TeachingProcedure: TeachingProcedure
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareManage.Save',
      CoursewareID: CoursewareID,
      CoursewareTitle: CoursewareTitle,
      CoverUrl: CoverUrl,
      CoverRatio: CoverRatio,
      IsOpenAdvance: IsOpenAdvance,
      AdvanceContent: AdvanceContent
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 课件编辑-编辑附件
export function EditCoursewareManageAttach(AttachList) {
  var data = {
    AttachList: JSON.stringify(AttachList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.EditAttach'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 课件编辑-编辑附件
export function CWSEditCoursewareManageAttach(AttachList) {
  var data = {
    AttachList: JSON.stringify(AttachList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareManage.EditAttach'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 课件编辑-编辑附件
export function WEBVEditCoursewareManageAttach(AttachList) {
  var data = {
    AttachList: JSON.stringify(AttachList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.EditAttach'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 课件编辑-删除附件
export function DeleteCoursewareManageAttach(CoursewareAttachID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.DeleteCoursewareAttach',
      CoursewareAttachID
    },
    method: 'GET'
  })
}

// 课件编辑-版本还原
export function VersionRestoreCourseware(CoursewareKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.VersionRestore',
      CoursewareKey
    },
    method: 'GET'
  })
}

// 课件分发-获取授权课件包List
export function GetAuthorizationCoursewarePackageList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_CoursewareDistribute.GetAuthorizationCoursewarePackageList'
    },
    method: 'GET'
  })
}

// 课件分发-获取自研课件包List
export function GetIndependentDevelopmentCoursewarePackageList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_CoursewareDistribute.GetIndependentDevelopmentCoursewarePackageList'
    },
    method: 'GET'
  })
}

// 课件分发-获取循环课List
export function GetScheduleCourseListByStructureRuleID(StructureRuleID) {
  var data = {
    StructureRuleID: JSON.stringify(StructureRuleID)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_CoursewareDistribute.GetScheduleCourseListByStructureRuleID'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 课件分发-获取课件List
export function GetCoursewareListByCoursewarePackageID(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_CoursewareDistribute.GetCoursewareListByCoursewarePackageID',
      CoursewarePackageID
    },
    method: 'GET'
  })
}

// 课件分发-绑定分发课件
export function BindCoursewareDistribute(
  CoursewarePackageID,
  CourseNameKey,
  GradeClassKey,
  CoursewarePackageKeyValue,
  CourseNameKeyValue,
  GradeClassKeyValue,
  Data
) {
  Data = {
    data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareDistribute.Bind',
      CoursewarePackageID,
      CourseNameKey,
      GradeClassKey,
      CoursewarePackageKeyValue,
      CourseNameKeyValue,
      GradeClassKeyValue
    },
    method: 'post',
    data: qs.stringify(Data)
  })
}

export function GetScheduleCourseStudentListForHomeWork(ScheduleCourseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.HomeWork.GetScheduleCourseStudentListForHomeWork',
      ScheduleCourseKey
    },
    method: 'GET'
  })
}
// 获取课内学生->发布点评时调用
export function GetScheduleCourseStudentListForComments(ScheduleCourseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.CommentsManagement.GetScheduleCourseStudentListForComments',
      ScheduleCourseKey
    },
    method: 'GET'
  })
}
// 老师上课
// 获取去当前主讲老师指定日期的课列表
export function GetMainTeaScheduleCourseListByDate(date, IsCourseReview) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherGiveLecture.GetScheduleCourseListByDate',
      Date: date,
      IsCourseReview: IsCourseReview
    },
    method: 'GET'
  })
}
// 获取去当前主讲老师指定课的学生列表
export function GetMainTeaScheduleCourseStudentList(ScheduleCourseKey) {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'TeacherGiveLecture.GetScheduleCourseStudentList',
      ScheduleCourseKey: ScheduleCourseKey
    },
    method: 'GET'
  })
}
// //获取去当前主讲老师指定日期时间段的考勤日报
export function GetMainTeaAttendanceDaily(startTime, endTime, IsCourseReview) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherGiveLecture.AttendanceDaily',
      StartTime: startTime,
      EndTime: endTime,
      IsCourseReview: IsCourseReview
    },
    method: 'GET'
  })
}
// 当前主讲老师当前主讲老师设置考勤.
export function mainTeasetAttendance(
  StudentKey,
  ScheduleCourseKey,
  IsClassHourLack
) {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'TeacherGiveLecture.KQ_Attendance',
      StudentKey: StudentKey,
      ScheduleCourseKey: ScheduleCourseKey,
      IsClassHourLack: IsClassHourLack
    },
    method: 'GET',
    checkResubmit: true
  })
}

// 当前主讲老师考勤变更
export function mainTeakqChange(
  StudentKey,
  ScheduleCourseKey,
  IsClassHourLack,
  TargetAttenceStatusKey,
  StudentArrangeKey,
  Reason
) {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'TeacherGiveLecture.KQ_Change',
      StudentKey: StudentKey,
      ScheduleCourseKey: ScheduleCourseKey,
      IsClassHourLack: IsClassHourLack,
      TargetAttenceStatusKey: TargetAttenceStatusKey,
      OLAPKey: StudentArrangeKey,
      Reason: Reason
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 当前主讲老师考勤删除 KQ_Delete
export function mainTeakqDelete(StudentKey, ScheduleCourseKey) {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'TeacherGiveLecture.KQ_Delete',
      StudentKey: StudentKey,
      ScheduleCourseKey: ScheduleCourseKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 当前主讲老师考勤请假  KQ_Leave
export function mainTeakqLeave(StudentKey, ScheduleCourseKey, IsClassHourLack) {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'TeacherGiveLecture.KQ_Leave',
      StudentKey: StudentKey,
      ScheduleCourseKey: ScheduleCourseKey,
      IsClassHourLack: IsClassHourLack
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 当前主讲老师考勤旷课 KQ_Truancy
export function mainTeakqTruancy(
  StudentKey,
  ScheduleCourseKey,
  IsClassHourLack
) {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'TeacherGiveLecture.KQ_Truancy',
      StudentKey: StudentKey,
      ScheduleCourseKey: ScheduleCourseKey,
      IsClassHourLack: IsClassHourLack
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 当前主讲老师考勤销假 KQ_LeaveDestroy
export function mainTeakqLeaveDestroy(
  StudentKey,
  ScheduleCourseKey,
  IsClassHourLack
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherGiveLecture.KQ_LeaveDestroy',
      StudentKey: StudentKey,
      ScheduleCourseKey: ScheduleCourseKey,
      IsClassHourLack: IsClassHourLack
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 当前主讲老师考勤未签到 KQ_NoAttendance
export function mainTeakqNoAttendance(
  StudentKey,
  ScheduleCourseKey,
  IsClassHourLack
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherGiveLecture.KQ_NoAttendance',
      StudentKey: StudentKey,
      ScheduleCourseKey: ScheduleCourseKey,
      IsClassHourLack: IsClassHourLack
    },
    method: 'GET',
    checkResubmit: true
  })
}
// <-- 总部基础资料 -->

// 收支管理-审核
export function hqAuditSZGL(TableID, AuditType) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePayManage.AuditSZGL',
      TableID, // 收支表ID
      AuditType // 审核类型: 0-拒绝; 1-通过
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 收支管理-处理提交
export function hqDoSZGL(
  TableID,
  SubKey,
  SubKeyValue,
  AccountKey,
  AccountKeyValue,
  Remarks
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePayManage.DoSZGL',
      TableID, // 收支表ID
      SubKey, // 收支科目Key
      SubKeyValue, // 收支科目
      AccountKey, // 收款账户Key
      AccountKeyValue, // 收款账户
      Remarks //	备注
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 收支管理-作废
export function hqInvalidateSZGL(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePayManage.InvalidateSZGL',
      TableID // 收支表ID
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 收支管理-待审核查询
export function hqMessageSearchAuditForSZGL() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePayManage.MessageSearchAuditForSZGL'
    },
    method: 'GET'
  })
}
// 收支管理 - 已审核查询
export function hqMessageSearchAuditedForSZGL(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePayManage.MessageSearchAuditedForSZGL',
      startTime,
      endTime
    },
    method: 'GET'
  })
}
// 收支管理-待处理查询
export function hqMessageSearchDoForSZGL() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePayManage.MessageSearchDoForSZGL'
    },
    method: 'GET'
  })
}
// 收支管理-新增其它收支
export function hqSZOtherAdd(Data) {
  // Data = JSON.stringify(Data)
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePayManage.SZOtherAdd'
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 添加收支账户
export function hqAddIncomePayAccount(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePayAccountManage.AddIncomePayAccount'
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 收款账户名称 AccountTypeKey--账户类型key AccountTypeKeyValue--账户类型value CommentSupport-- 备注)
    checkResubmit: true
  })
}
// 修改收支账户
export function hqEditIncomePayAccount(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePayAccountManage.EditIncomePayAccount'
    },
    method: 'post',
    data: qs.stringify(Data), // json(OLAPKey -- id MainDemoName -- 收款账户名称 AccountTypeKey--账户类型key AccountTypeKeyValue--账户类型value CommentSupport-- 备注)
    checkResubmit: true
  })
}
// 返回该门店所有收支账户
export function hqMessageSearchPageForIncomePayAccount(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePayAccountManage.MessageSearchPageForIncomePayAccount',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选	OLAPKey	数据源：GET	排序字段
      sequence: parameter.sequence, // 字符串	可选	desc	数据源：GET	排序字段
      searchText: parameter.searchText, //		字符串	可选		数据源：GET	搜索条件：模糊匹配
      StateKey: parameter.StateKey, // 字符串	可选	0	数据源：GET	小区状态 2 启用 3停用
      AccountTypeKey: parameter.AccountTypeKey // 字符串	可选	0	数据源：GET	账户类型 1-储蓄卡; 2-信用卡;3-支付宝;4-微信;5-现金;6-其它
    },
    method: 'GET'
  })
}
// 启用收支账户
export function hqStartIncomePayAccount(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePayAccountManage.StartIncomePayAccount',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 停用收支账户
export function hqStopIncomePayAccount(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePayAccountManage.StopIncomePayAccount',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 添加收支科目
export function hqAddIncomePaySubject(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePaySubjectManage.AddIncomePaySubject'
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 科目名称 FatherKey--上级科目 0为全部 CommentSupport-- 备注 IncomePayTypeKey--收支类型Key 1-收;2-支 IncomePayTypeKeyValue收支类型KeyValue 1-收;2-支)
    checkResubmit: true
  })
}
// 修改收支科目
export function hqEditIncomePaySubject(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePaySubjectManage.EditIncomePaySubject'
    },
    method: 'post',
    data: qs.stringify(Data), // json(OLAPKey -- id MainDemoName -- 科目名称 FatherKey--上级科目 CommentSupport-- 备注 IncomePayTypeKey--收支类型Key 1-收;2-支 IncomePayTypeKeyValue收支类型KeyValue 1-收;2-支)
    checkResubmit: true
  })
}
// 返回该门店所有收支科目
export function hqMessageSearchPageForIncomePaySubject(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePaySubjectManage.MessageSearchPageForIncomePaySubject',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选	OLAPKey	数据源：GET	排序字段
      sequence: parameter.sequence, // 字符串	可选	desc	数据源：GET	排序字段
      searchText: parameter.searchText, //		字符串	可选		数据源：GET	搜索条件：模糊匹配
      StateKey: parameter.StateKey, // 字符串	可选	0	数据源：GET	小区状态 2 启用 3停用
      IncomePayTypeKey: parameter.IncomePayTypeKey // 	收支类型 1-收;2-支
    },
    method: 'GET'
  })
}
// 启用收支科目
export function hqStartIncomePaySubject(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePaySubjectManage.StartIncomePaySubject',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 停用收支科目
export function hqStopIncomePaySubject(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePaySubjectManage.StopIncomePaySubject',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 添加部门;
export function hqAddDepartment(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.DepartmentManage.AddDepartment'
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 部门名称 FatherKey--所属部门key ，0为全部 FatherKeyValue -- 所属部门keyValue RemarksSupport-- 备注)
    checkResubmit: true
  })
}
// 修改部门;
export function hqEditDepartment(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.DepartmentManage.EditDepartment'
    },
    method: 'post',
    data: qs.stringify(Data), // json(OLAPKey -- id MainDemoName--名称 remarkSupport-- 备注)
    checkResubmit: true
  })
}
// 返回该门店所有部门
export function hqMessageSearchPageForDepartment(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.DepartmentManage.MessageSearchPageForDepartment',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选	OLAPKey	数据源：GET	排序字段
      sequence: parameter.sequence, // 字符串	可选	desc	数据源：GET	排序字段
      searchText: parameter.searchText, //		字符串	可选		数据源：GET	搜索条件：模糊匹配部门名称
      StateKey: parameter.StateKey // 字符串	可选	0	数据源：GET	小区状态 2 启用 3停用
    },
    method: 'GET'
  })
}
// 启用部门
export function hqStartDepartment(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.DepartmentManage.StartDepartment',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 停用部门
export function hqStopDepartment(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.DepartmentManage.StopDepartment',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 员工-》新增
export function hqAddEmployee(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.EmployeeManage.AddEmployee'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true // json(RoleKey--角色id MainDemoName -- 角色名 RolePermissionList((ModulePowerKey--模块权限id))--角色权限list)
  })
}
// 员工-》修改
export function hqEditEmployeeInfo(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.EmployeeManage.EditEmployee'
    },
    method: 'post',
    data: qs.stringify(Data), // json(RoleKey--角色id MainDemoName -- 角色名 RolePermissionList((ModulePowerKey--模块权限id))--角色权限list),
    checkResubmit: true
  })
}
// 员工-》离职
export function hqEmployeeDimission(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.EmployeeManage.EmployeeDimission',
      OLAPKey: OLAPKey //	员工ID
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 员工-》恢复
export function hqEmployeeRecover(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.EmployeeManage.EmployeeRecover',
      OLAPKey: OLAPKey //	员工ID
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 更新员工头像
export function hqeditEmployeeHeadImg(OLAPKey, HeadImgUrl) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.EmployeeManage.EditEmployeeHeadImg',
      OLAPKey: OLAPKey,
      HeadImgUrl: HeadImgUrl
    },
    method: 'get'
  })
}
// 获取员工二维码
export function hqgetEmployeeQRCode(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.EmployeeManage.GetEmployeeQRCode',
      OLAPKey: OLAPKey
    },
    method: 'get'
  })
}
// 员工-》查询翻页
export function hqMessageSearchPageForEmployee(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.EmployeeManage.MessageSearchPageForEmployee',
      pageIndex: parameter.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号、首字母
      orderBy: parameter.orderBy, //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为姓名
      sequence: parameter.sequence, //	字符串	可选	ASC	数据源：GET	为空则默认顺序; ASC正序;DESC倒序
      IsLeaveKey: parameter.IsLeaveKey //	字符串	可选		数据源：GET	是否在职;空表示该条件不生效;0-离职;1-在职
    },
    method: 'GET'
  })
}
// 返回指定员工信息;
export function hqGetEmployeeInfo(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.EmployeeManage.GetEmployeeInfo',
      OLAPKey: OLAPKey //	员工ID
    },
    method: 'GET'
  })
}
// 添加岗位
export function hqAddStation(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.StationManage.AddStation'
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 岗位名称 FatherKey--所属部门key ，0为全部 FatherKeyValue -- 所属部门keyValue RemarksSupport-- 备注)
    checkResubmit: true
  })
}
// 修改岗位
export function hqEditStation(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.StationManage.EditStation'
    },
    method: 'post',
    data: qs.stringify(Data), // json(OLAPKey -- id MainDemoName--名称 FatherKey--所属部门key ，0为全部 FatherKeyValue -- 所属部门keyValue RemarksSupport-- 备注)
    checkResubmit: true
  })
}
// 返回该门店所有岗位
export function hqMessageSearchPageForStation(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.StationManage.MessageSearchPageForStation',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选	OLAPKey	数据源：GET	排序字段
      sequence: parameter.sequence, // 字符串	可选	desc	数据源：GET	排序字段
      searchText: parameter.searchText, //		字符串	可选		数据源：GET	搜索条件：模糊匹配
      StateKey: parameter.StateKey // 字符串	可选	0	数据源：GET	小区状态 2 启用 3停用
    },
    method: 'GET'
  })
}
// 启用岗位
export function hqStartStation(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.StationManage.StartStation',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 停用岗位
export function hqStopStation(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.StationManage.StopStation',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}

// 新增课评话术
export function GetCourseEvaluationList(CoursewareID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.GetCourseEvaluationList',
      CoursewareID
    },
    method: 'GET'
  })
}
// 新增课评话术
export function WEBVGetCourseEvaluationList(CoursewareID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.GetCourseEvaluationList',
      CoursewareID
    },
    method: 'GET'
  })
}

// 新增课评话术
export function CWSGetCourseEvaluationList(CoursewareID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareManage.GetCourseEvaluationList',
      CoursewareID
    },
    method: 'GET'
  })
}

// 新增课评话术
export function AddCourseEvaluation(CoursewareID, EvaluationContent) {
  var data = {
    EvaluationContent: EvaluationContent
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.AddCourseEvaluation',
      CoursewareID
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 新增课评话术
export function CWSAddCourseEvaluation(CoursewareID, EvaluationContent) {
  var data = {
    EvaluationContent: EvaluationContent
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareManage.AddCourseEvaluation',
      CoursewareID
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 新增课评话术
export function WEBVAddCourseEvaluation(CoursewareID, EvaluationContent) {
  var data = {
    EvaluationContent: EvaluationContent
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.AddCourseEvaluation',
      CoursewareID
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 修改课评话术
export function EditCourseEvaluation(EvaluationID, EvaluationContent) {
  var data = {
    EvaluationContent: EvaluationContent
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.EditCourseEvaluation',
      EvaluationID
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 修改课评话术
export function CWSEditCourseEvaluation(EvaluationID, EvaluationContent) {
  var data = {
    EvaluationContent: EvaluationContent
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareManage.EditCourseEvaluation',
      EvaluationID
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 修改课评话术
export function WEVBEditCourseEvaluation(EvaluationID, EvaluationContent) {
  var data = {
    EvaluationContent: EvaluationContent
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.EditCourseEvaluation',
      EvaluationID
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 删除课评话术
export function DeleteCourseEvaluation(EvaluationID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.DeleteCourseEvaluation',
      EvaluationID
    },
    method: 'GET'
  })
}

// 删除课评话术
export function WEBVDeleteCourseEvaluation(EvaluationID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.DeleteCourseEvaluation',
      EvaluationID
    },
    method: 'GET'
  })
}

// 删除课评话术
export function CWSDeleteCourseEvaluation(EvaluationID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareManage.DeleteCourseEvaluation',
      EvaluationID
    },
    method: 'GET'
  })
}
// 新增作业
export function coursewareAddHomeWork(CoursewareID, HomeWorkData) {
  var data = {
    HomeWorkData: JSON.stringify(HomeWorkData)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.AddHomeWork',
      CoursewareID
    },
    method: 'post',
    data: qs.stringify(data),
    checkResubmit: true
  })
}
// 新增作业
export function CWScoursewareAddHomeWork(CoursewareID, HomeWorkData) {
  var data = {
    HomeWorkData: JSON.stringify(HomeWorkData)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareManage.AddHomeWork',
      CoursewareID
    },
    method: 'post',
    data: qs.stringify(data),
    checkResubmit: true
  })
}
// 新增作业
export function WEBVcoursewareAddHomeWork(CoursewareID, HomeWorkData) {
  var data = {
    HomeWorkData: JSON.stringify(HomeWorkData)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.AddHomeWork',
      CoursewareID
    },
    method: 'post',
    data: qs.stringify(data),
    checkResubmit: true
  })
}

// 修改作业
export function coursewareEditHomeWork(CoursewareID, HomeWorkData) {
  var data = {
    HomeWorkData: JSON.stringify(HomeWorkData)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.EditHomeWork',
      CoursewareID
    },
    method: 'post',
    data: qs.stringify(data),
    checkResubmit: true
  })
}

// 修改作业
export function CWScoursewareEditHomeWork(CoursewareID, HomeWorkData) {
  var data = {
    HomeWorkData: JSON.stringify(HomeWorkData)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareManage.EditHomeWork',
      CoursewareID
    },
    method: 'post',
    data: qs.stringify(data),
    checkResubmit: true
  })
}

// 修改作业
export function WEBVcoursewareEditHomeWork(CoursewareID, HomeWorkData) {
  var data = {
    HomeWorkData: JSON.stringify(HomeWorkData)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.EditHomeWork',
      CoursewareID
    },
    method: 'post',
    data: qs.stringify(data),
    checkResubmit: true
  })
}
// 删除作业
export function coursewareDeleteHomeWork(CoursewareID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.DeleteHomeWork',
      CoursewareID
    },
    method: 'GET'
  })
}
// 删除作业
export function CWScoursewareDeleteHomeWork(CoursewareID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareManage.DeleteHomeWork',
      CoursewareID
    },
    method: 'GET'
  })
}
// 删除作业
export function WEBVcoursewareDeleteHomeWork(CoursewareID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.DeleteHomeWork',
      CoursewareID
    },
    method: 'GET'
  })
}
// 获取标准作业题库详情
export function GetHomeWorkDetail(CoursewareID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.GetHomeWorkDetail',
      CoursewareID
    },
    method: 'GET'
  })
}

// 获取标准作业题库详情
export function WEBVGetHomeWorkDetail(CoursewareID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.GetHomeWorkDetail',
      CoursewareID
    },
    method: 'GET'
  })
}

// 获取标准作业题库详情
export function CWSGetHomeWorkDetail(CoursewareID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareManage.GetHomeWorkDetail',
      CoursewareID
    },
    method: 'GET'
  })
}

// 课件管理-删除附件
export function HQDeleteCoursewareAttach(CoursewareAttachID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.DeleteCoursewareAttach',
      CoursewareAttachID
    },
    method: 'GET'
  })
}

// 课件管理-删除附件
export function DeleteCoursewareAttach(CoursewareAttachID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.DeleteCoursewareAttach',
      CoursewareAttachID
    },
    method: 'GET'
  })
}

// 课件管理-上传音频
export function UploadMaterialForAudio(
  file,
  CoursewareID,
  fileData,
  AuthorizeCode
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.UploadMaterialForAudio',
      CoursewareID: CoursewareID,
      AuthorizeCode
    },
    method: 'post',
    data: file,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 600000,
    onUploadProgress(progressEvent) {
      if (fileData && progressEvent.lengthComputable) {
        fileData.percent = (
          (progressEvent.loaded / progressEvent.total) *
          100
        ).toFixed(0)
        console.log('onUploadProgress', progressEvent, fileData.percent)
      }
    }
  })
}

// 课件管理-上传图片
export function UploadMaterialForImg(
  file,
  CoursewareID,
  fileData,
  AuthorizeCode
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.UploadMaterialForImg',
      CoursewareID: CoursewareID,
      AuthorizeCode
    },
    method: 'post',
    data: file,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 600000,
    onUploadProgress(progressEvent) {
      if (fileData && progressEvent.lengthComputable) {
        fileData.percent = (
          (progressEvent.loaded / progressEvent.total) *
          100
        ).toFixed(0)
        console.log('onUploadProgress', progressEvent, fileData.percent)
      }
    }
  })
}

// 课件管理-上传视频
export function UploadMaterialForVideo(
  file,
  CoursewareID,
  AuthorizeCode,
  TypeKey,
  fileData
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.UploadMaterialForVideo',
      CoursewareID: CoursewareID,
      AuthorizeCode: AuthorizeCode,
      client_id: store.getters.clientId,
      TypeKey // 附件所属类型 1-课件2-教学步骤;
    },
    method: 'post',
    data: file,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 600000,
    onUploadProgress(progressEvent) {
      if (fileData && progressEvent.lengthComputable) {
        fileData.percent = (
          (progressEvent.loaded / progressEvent.total) *
          100
        ).toFixed(0)
        console.log('onUploadProgress', progressEvent, fileData.percent)
      }
    }
  })
}

// 课件管理-上传PPT
export function UploadMaterialForPPT(
  file,
  CoursewareID,
  AuthorizeCode,
  fileData
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.UploadMaterialForPPT',
      CoursewareID: CoursewareID,
      AuthorizeCode: AuthorizeCode,
      client_id: store.getters.clientId
    },
    method: 'post',
    data: file,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 600000,
    onUploadProgress(progressEvent) {
      if (fileData && progressEvent.lengthComputable) {
        fileData.percent = (
          (progressEvent.loaded / progressEvent.total) *
          100
        ).toFixed(0)
        console.log('onUploadProgress', progressEvent, fileData.percent)
      }
    }
  })
}
// 课件管理-上传PDF
export function UploadMaterialForPDF(
  file,
  CoursewareID,
  AuthorizeCode,
  TypeKey,
  fileData
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.UploadMaterialForPDF',
      CoursewareID: CoursewareID,
      AuthorizeCode: AuthorizeCode,
      TypeKey: TypeKey, // 附件所属类型 1-课件2-教学步骤;
      client_id: store.getters.clientId
    },
    method: 'post',
    data: file,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 600000,
    onUploadProgress(progressEvent) {
      if (fileData && progressEvent.lengthComputable) {
        fileData.percent = (
          (progressEvent.loaded / progressEvent.total) *
          100
        ).toFixed(0)
        console.log('onUploadProgress', progressEvent, fileData.percent)
      }
    }
  })
}

// 老师上课获取课件详情
export function TeacherGetCoursewareDetail(ScheduleCourseID, Data) {
  let data = {
    Data: JSON.stringify(Data)
  }
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'TeacherGiveLecture.GetCoursewareDetail',
      ScheduleCourseID: ScheduleCourseID
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// TV-老师上课获取课件详情
export function GetCoursewareDetailForTVGiveLecture(
  ScheduleCourseID,
  AuthorizedCode
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_TVGiveLecture.GetCoursewareDetailForTVGiveLecture',
      ScheduleCourseID: ScheduleCourseID,
      AuthorizedCode: AuthorizedCode
    },
    method: 'GET'
  })
}
// 自研课件预览
export function SelfCourseware(CoursewareID, Data) {
  let data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePreview.SelfCourseware',
      CoursewareID: CoursewareID
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 自研课件预览
export function CWSelfCourseware(CoursewareID, Data) {
  let data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewarePreview.SelfCourseware',
      CoursewareID: CoursewareID
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 授权课件预览
export function AuthorizationCourseware(CoursewareID, AuthCoursewareID, Data) {
  let data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePreview.AuthorizationCourseware',
      CoursewareID: CoursewareID,
      AuthCoursewareID: AuthCoursewareID
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 权限设置-模块查询
export function hqSearchModulePower() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorityManage.SearchModulePower'
    },
    method: 'GET'
  })
}
// 权限设置-角色查询
export function hqSearchRole() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorityManage.SearchRole'
    },
    method: 'GET'
  })
}
// 权限设置-获取角色权限模块
export function hqGetRole(RoleKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorityManage.GetRole',
      RoleKey: RoleKey // 角色key
    },
    method: 'GET'
  })
}
// 权限设置-新增角色
export function hqAddRole(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorityManage.AddRole'
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 角色名 RolePermissionList((ModulePowerKey--模块权限id))--角色权限list),
    checkResubmit: true
  })
}
// 权限设置-修改角色
export function hqEditRole(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorityManage.EditRole'
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 角色名 RolePermissionList((ModulePowerKey--模块权限id))--角色权限list),
    checkResubmit: true
  })
}
// 权限设置-删除角色
export function hqDeleteRole(RoleKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorityManage.DeleteRole',
      RoleKey: RoleKey // 角色Key
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 最新的获取老师课详细信息
export function getTeacheCourseDetail(OLAPKey) {
  console.log(
    'getters.SaaSClientEditionKey',
    store.getters.SaaSClientEditionKey
  )
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'TeacherGiveLecture.GetScheduleCourseDetail',
      OLAPKey: OLAPKey // 课名ID
    },
    method: 'GET'
  })
}
// 获取课件详情For备课
export function GetCoursewareDetail(ScheduleCourseID, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'TeacherGiveLecture.GetCoursewareDetail',
      ScheduleCourseID: ScheduleCourseID // 课名ID
    },
    data: qs.stringify(Data),
    method: 'post'
  })
}

// 课件分发-规律课列表
export function CoursewareDistributeSearchStructureRule(
  GradeClassKey,
  CourseNameKey,
  isDistribute,
  EndDate
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareDistribute.SearchStructureRule',
      GradeClassKey: GradeClassKey || '', //	整型	可选		数据源：GET	班级ID
      CourseNameKey: CourseNameKey || '', //	整型	可选		数据源：GET	课ID
      isDistribute: isDistribute || 0, //	整型	可选		数据源：GET	是否分发 1：已分发 2：未分发 0：全部
      EndDate: EndDate || '' //	字符串	可选		数据源：GET	截止日期
    },
    method: 'GET'
  })
}
// 验证是否授权设备
export function VerifyAuthorizedDevice(DeviceCode, CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizedDeviceManage.VerifyAuthorizedDevice',
      DeviceCode: DeviceCode, // 课名ID
      CoursewarePackageKey: CoursewarePackageKey
    },
    method: 'GET'
  })
}
// 验证是否授权设备
export function ApplyAuthorizedDevice(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizedDeviceManage.ApplyAuthorizedDevice'
    },
    data: qs.stringify(Data),
    method: 'post',
    checkResubmit: true
  })
}
// 获取授权设备列表
export function SearchAuthorizedDeviceList(CoursewarePackageKey) {
  let IPAN =
    store.getters.SaaSClientEditionKey == 5
      ? 'HQ.AuthorizedDeviceManage.SearchAuthorizedDeviceList'
      : 'WEBV.AuthorizedDeviceManagement.SearchAuthorizedDeviceList'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN,
      CoursewarePackageKey: CoursewarePackageKey
    },
    method: 'get'
  })
}
// 授权设备取消
export function CancelAuthorized(ApplyKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizedDeviceManage.CancelAuthorized',
      ApplyKey: ApplyKey
    },
    method: 'get'
  })
}
// 授权设备拒绝
export function AuditReject(ApplyKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizedDeviceManage.AuditReject',
      ApplyKey: ApplyKey
    },
    method: 'get',
    checkResubmit: true
  })
}
// 授权设备通过
export function AuditAdopt(ApplyKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizedDeviceManage.AuditAdopt',
      ApplyKey: ApplyKey
    },
    method: 'get',
    checkResubmit: true
  })
}
// 查询大课包列表-总部(自研)
export function hqGetParentCourseWarePackageList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ParentCourseWarePackage.GetParentCourseWarePackageList'
    },
    method: 'get'
  })
}
// 新增大课包-总部
export function hqAddParentCourseWarePackage(parameter) {
  let Data = {
    CoursewarePackageAttach: JSON.stringify(parameter.CoursewarePackageAttach),
    CoursewareRelevanceList: JSON.stringify(parameter.CoursewareRelevanceList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ParentCourseWarePackage.Add',
      CoursewarePackageName: parameter.CoursewarePackageName, //	字符串	必须		数据源：GET	课件包名称
      CoursewarePackageCoverUrl: parameter.CoursewarePackageCoverUrl, //	字符串	必须		数据源：GET	课件包封面
      TermDurationNum: parameter.TermDurationNum, //	整型	必须		数据源：GET	学期时长（月）
      ArrangedAlertNum: parameter.ArrangedAlertNum, //	整型	必须		数据源：GET	排课警戒课次
      AlertWayKey: parameter.AlertWayKey, //	整型	必须		数据源：GET	排课警戒方式 1：警告提示 2：自动禁
      LevelCount: parameter.LevelCount, //	整型	可选	3	数据源：GET	层级数
      CoverUrl: parameter.CoverUrl,

      CoverIsDownload: parameter.CoverIsDownload
    },
    data: qs.stringify(Data),
    method: 'post'
  })
}
// 修改大课包-总部
export function hqEditParentCourseWarePackage(CoursewarePackageID, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ParentCourseWarePackage.Edit',
      CoursewarePackageID: CoursewarePackageID // 大课包ID
    },
    data: qs.stringify(Data),
    method: 'post'
  })
}
// 删除大课包-总部
export function hqDeleteParentCourseWarePackage(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ParentCourseWarePackage.Delete',
      CoursewarePackageID: CoursewarePackageID //	// 大课包ID
    },
    method: 'get'
  })
}
// 自研大课包-停用-总部
export function hqParentCourseWarePackageDisable(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ParentCourseWarePackage.Disable',
      CoursewarePackageID: CoursewarePackageID //	// 大课包ID
    },
    method: 'get'
  })
}
// 自研大课包-启用-总部
export function hqParentCourseWarePackageEnable(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ParentCourseWarePackage.Enable',
      CoursewarePackageID: CoursewarePackageID //	// 大课包ID
    },
    method: 'get'
  })
}
// 大课包-排序-总部
export function hqParentCourseWarePackageSort(
  CoursewarePackageID,
  ChangeToSort
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ParentCourseWarePackage.Sort',
      CoursewarePackageID: CoursewarePackageID, //	// 大课包ID
      ChangeToSort: ChangeToSort
    },
    method: 'get'
  })
}
// 大课包-获取详情-总部
export function hqParentCourseWarePackageGetDetail(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ParentCourseWarePackage.GetDetail',
      CoursewarePackageID: CoursewarePackageID //	// 大课包ID
    },
    method: 'get'
  })
}

// 查询大课包列表-门店(自研)
export function GetParentCourseWarePackageList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_ParentCourseWarePackage.GetParentCourseWarePackageList'
    },
    method: 'get'
  })
}

// 课件门店查询大课包列表-门店(自研)
export function CWSGetParentCourseWarePackageList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.ParentCourseWarePackage.GetParentCourseWarePackageList'
    },
    method: 'get'
  })
}

// 查询大课包列表-门店(授权)
export function ClientAuthParentCoursewarePackageList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_ParentCourseWarePackage.ClientAuthParentCoursewarePackageList'
    },
    method: 'get'
  })
}
// 新增大课包-门店
export function AddParentCourseWarePackage(parameter) {
  let Data = {
    CoursewarePackageAttach: JSON.stringify(parameter.CoursewarePackageAttach),
    CoursewareRelevanceList: JSON.stringify(parameter.CoursewareRelevanceList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_ParentCourseWarePackage.Add',
      CoursewarePackageName: parameter.CoursewarePackageName, //	字符串	必须		数据源：GET	课件包名称
      CoursewarePackageCoverUrl: parameter.CoursewarePackageCoverUrl, //	字符串	必须		数据源：GET	课件包封面
      TermDurationNum: parameter.TermDurationNum, //	整型	必须		数据源：GET	学期时长（月）
      ArrangedAlertNum: parameter.ArrangedAlertNum, //	整型	必须		数据源：GET	排课警戒课次
      AlertWayKey: parameter.AlertWayKey, //	整型	必须		数据源：GET	排课警戒方式 1：警告提示 2：自动禁
      LevelCount: parameter.LevelCount, //	整型	可选	3	数据源：GET	层级数
      CoverUrl: parameter.CoverUrl,

      CoverIsDownload: parameter.CoverIsDownload
    },
    data: qs.stringify(Data),
    method: 'post'
  })
}

// 课件门店新增大课包-门店
export function CWSAddParentCourseWarePackage(parameter) {
  let Data = {
    CoursewarePackageAttach: JSON.stringify(parameter.CoursewarePackageAttach),
    CoursewareRelevanceList: JSON.stringify(parameter.CoursewareRelevanceList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.ParentCourseWarePackage.Add',
      CoursewarePackageName: parameter.CoursewarePackageName, //	字符串	必须		数据源：GET	课件包名称
      CoursewarePackageCoverUrl: parameter.CoursewarePackageCoverUrl, //	字符串	必须		数据源：GET	课件包封面
      TermDurationNum: parameter.TermDurationNum, //	整型	必须		数据源：GET	学期时长（月）
      ArrangedAlertNum: parameter.ArrangedAlertNum, //	整型	必须		数据源：GET	排课警戒课次
      AlertWayKey: parameter.AlertWayKey, //	整型	必须		数据源：GET	排课警戒方式 1：警告提示 2：自动禁
      LevelCount: parameter.LevelCount, //	整型	可选	3	数据源：GET	层级数
      CoverUrl: parameter.CoverUrl,

      CoverIsDownload: parameter.CoverIsDownload
    },
    data: qs.stringify(Data),
    method: 'post'
  })
}

// 修改大课包-门店
export function EditParentCourseWarePackage(CoursewarePackageID, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_ParentCourseWarePackage.Edit',
      CoursewarePackageID: CoursewarePackageID // 大课包ID
    },
    data: qs.stringify(Data),
    method: 'post'
  })
}

// 修改大课包-门店
export function CWSEditParentCourseWarePackage(CoursewarePackageID, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.ParentCourseWarePackage.Edit',
      CoursewarePackageID: CoursewarePackageID // 大课包ID
    },
    data: qs.stringify(Data),
    method: 'post'
  })
}
// 删除大课包-门店
export function DeleteParentCourseWarePackage(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_ParentCourseWarePackage.Delete',
      CoursewarePackageID: CoursewarePackageID //	// 大课包ID
    },
    method: 'get'
  })
}
// 自研大课包-停用-门店
export function ParentCourseWarePackageDisable(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_ParentCourseWarePackage.Disable',
      CoursewarePackageID: CoursewarePackageID //	// 大课包ID
    },
    method: 'get'
  })
}
// 自研大课包-启用-门店
export function ParentCourseWarePackageEnable(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_ParentCourseWarePackage.Enable',
      CoursewarePackageID: CoursewarePackageID //	// 大课包ID
    },
    method: 'get'
  })
}

// CWS自研大课包-启用-门店
export function CWSParentCourseWarePackageEnable(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.ParentCourseWarePackage.Enable',
      CoursewarePackageID: CoursewarePackageID //	// 大课包ID
    },
    method: 'get'
  })
}
// 课件门店 修改-大课包排序
export function CWSGetCoursewarePackageSort(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.GiveLecturePattern.GetCoursewarePackageSort'
    },
    data: qs.stringify(Data),
    method: 'post'
  })
}
// 教务门店 修改-大课包排序
export function WEBVGetgetCoursewarePackageSort(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.GiveLecturePattern.getCoursewarePackageSort'
    },
    data: qs.stringify(Data),
    method: 'post'
  })
}
// 大课包-排序-门店
export function ParentCourseWarePackageSort(CoursewarePackageID, ChangeToSort) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_ParentCourseWarePackage.Sort',
      CoursewarePackageID: CoursewarePackageID, //	// 大课包ID
      ChangeToSort: ChangeToSort
    },
    method: 'get'
  })
}
// 大课包-获取详情-门店
export function ParentCourseWarePackageGetDetail(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_ParentCourseWarePackage.GetDetail',
      CoursewarePackageID: CoursewarePackageID //	// 大课包ID
    },
    method: 'get'
  })
}

// 门店-授权课件包列表-普通
export function AuthCoursewarePackageList(ParentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_ParentCourseWarePackage.ClientAuthCoursewarePackageListForParent',
      ParentKey: ParentKey
    },
    method: 'GET'
  })
}
// 门店-授权课件包列表（兼容大课包授权）
export function GetAuthCoursewarePackageList(ParentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_ParentCourseWarePackage.GetAuthCoursewarePackageList',
      ParentKey: ParentKey
    },
    method: 'GET'
  })
}
// 查询课件包-正常-普通
export function SearchCourseWarePackageListForNormal(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_ParentCourseWarePackage.GetSubCourseWarePackageList',
      CoursewarePackageKey: CoursewarePackageKey
    },
    method: 'GET'
  })
}

// CWS查询课件包-正常-普通
export function CWSSearchCourseWarePackageListForNormal(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.ParentCourseWarePackage.GetSubCourseWarePackageList',
      CoursewarePackageKey: CoursewarePackageKey
    },
    method: 'GET'
  })
}
// 查询课件包-回收站-普通
export function SearchCourseWarePackageListForRecycle(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_CoursewarePackageManage.SearchCourseWarePackageListForRecycle',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: params.sequence, //	字符串	可选		数据源：GET	排序字段
      LargeCoursewarePackageId: params.ID
    },
    method: 'GET'
  })
}

// CWS查询课件包-回收站-普通
export function CWSSearchCourseWarePackageListForRecycle(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'CWS.CoursewarePackageManage.SearchCourseWarePackageListForRecycle',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: params.sequence, //	字符串	可选		数据源：GET	排序字段
      LargeCoursewarePackageId: params.ID
    },
    method: 'GET'
  })
}
// 查询大课包-回收站-普通
export function SearchLargeCourseWarePackageListForRecycle(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_CoursewarePackageManage.SearchLargeCourseWarePackageListForRecycle',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: params.sequence //	字符串	可选		数据源：GET	排序字段
    },
    method: 'GET'
  })
}

// CWS查询大课包-回收站-普通
export function CWSSearchLargeCourseWarePackageListForRecycle(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'CWS.CoursewarePackageManage.SearchLargeCourseWarePackageListForRecycle',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: params.sequence //	字符串	可选		数据源：GET	排序字段
    },
    method: 'GET'
  })
}
// 排序-普通
export function CoursewarePackageManageSort(CoursewarePackageID, ChangeToSort) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePackageManage.Sort',
      CoursewarePackageID: CoursewarePackageID, // 课件包ID
      ChangeToSort: ChangeToSort // 需要变更的序号
    },
    method: 'GET'
  })
}
// 获取授权课件ListBy指定课件包ID
export function SearchAuthCoursewareListByCoursewarePackageID(
  CoursewarePackageID
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_CoursewareManage.SearchAuthCoursewareListByCoursewarePackageID',
      CoursewarePackageID: CoursewarePackageID // 课件包ID
    },
    method: 'GET'
  })
}
// 获取课件ListBy指定课件包ID-普通
export function SearchCoursewareListByCoursewarePackageID(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_CoursewareManage.SearchCoursewareListByCoursewarePackageID',
      CoursewarePackageID: CoursewarePackageID // 课件包ID
    },
    method: 'GET'
  })
}

// CWS获取课件ListBy指定课件包ID-普通
export function CWSSearchCoursewareListByCoursewarePackageID(
  CoursewarePackageID
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareManage.SearchCoursewareListByCoursewarePackageID',
      CoursewarePackageID: CoursewarePackageID // 课件包ID
    },
    method: 'GET'
  })
}
// 查询课件包-正常-总部 ok-（新增大课包ID）
export function hqSearchCourseWarePackageListForNormal(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ParentCourseWarePackage.GetSubCourseWarePackageList',
      CoursewarePackageKey: CoursewarePackageKey
    },
    method: 'GET'
  })
}
// 查询课件包-回收站-总部
export function hqSearchCourseWarePackageListForRecycle(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'HQ.CoursewarePackageManage.SearchCourseWarePackageListForRecycle',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: params.sequence, //	字符串	可选		数据源：GET	排序字段
      LargeCoursewarePackageId: params.ID
    },
    method: 'GET'
  })
}
// 查询大课包-回收站-总部
export function hqSearchLargeCourseWarePackageListForRecycle(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'HQ.CoursewarePackageManage.SearchLargeCourseWarePackageListForRecycle',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: params.sequence //	字符串	可选		数据源：GET	排序字段
    },
    method: 'GET'
  })
}
// 排序-总部
export function hqCoursewarePackageManageSort(
  CoursewarePackageID,
  ChangeToSort
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewarePackageManage.Sort',
      CoursewarePackageID: CoursewarePackageID, // 课件包ID
      ChangeToSort: ChangeToSort // 需要变更的序号
    },
    method: 'GET'
  })
}
// 获取课件ListBy指定课件包ID-总部
export function hqSearchCoursewareListByCoursewarePackageID(
  CoursewarePackageID
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.SearchCoursewareListByCoursewarePackageID',
      CoursewarePackageID: CoursewarePackageID // 课件包ID
    },
    method: 'GET'
  })
}

// 附件排序
export function CoursewareManageAttachSort(
  CoursewareAttachID,
  CoursewareID,
  ChangeToSort
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.AttachSort',
      CoursewareAttachID: CoursewareAttachID,
      CoursewareID: CoursewareID,
      ChangeToSort: ChangeToSort
    },
    method: 'GET'
  })
}
// 新增-普通 ok-
export function CoursewarePackageManageAdd(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePackageManage.Add',
      CoursewarePackageName: parameter.CoursewarePackageName, //	课件包名称
      ParentKey: parameter.ParentKey, //	字符串	可选		数据源：GET	 父类id
      ParentKeyValue: parameter.ParentKeyValue, //	字符串	必须		数据源：GET	 父类
      TermDurationNum: parameter.TermDurationNum, // 学期时长（月）
      TypeKey: parameter.TypeKey, // 	整型	必须		数据源：GET	课包类型 1-目录2-子课包
      CoverUrl: parameter.CoverUrl
    },
    method: 'GET'
  })
}

// 新增-普通 ok-
export function CWSCoursewarePackageManageAdd(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewarePackageManage.Add',
      CoursewarePackageName: parameter.CoursewarePackageName, //	课件包名称
      ParentKey: parameter.ParentKey, //	字符串	可选		数据源：GET	 父类id
      ParentKeyValue: parameter.ParentKeyValue, //	字符串	必须		数据源：GET	 父类
      TermDurationNum: parameter.TermDurationNum, // 学期时长（月）
      TypeKey: parameter.TypeKey, // 	整型	必须		数据源：GET	课包类型 1-目录2-子课包
      CoverUrl: parameter.CoverUrl
    },
    method: 'GET'
  })
}
// 新增-总部 ok
export function hqCoursewarePackageManageAdd(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewarePackageManage.Add',
      CoursewarePackageName: parameter.CoursewarePackageName, //	课件包名称
      CoursewarePackageCoverUrl: parameter.CoursewarePackageCoverUrl, // 课件包封面
      TermDurationNum: parameter.TermDurationNum, // 学期时长（月）
      ParentKey: parameter.ParentKey, // 大课包ID
      ParentKeyValue: parameter.ParentKeyValue, // 大课包
      TypeKey: parameter.TypeKey, // 	整型	必须		数据源：GET	课包类型 1-目录2-子课包
      CoverUrl: parameter.CoverUrl
    },
    method: 'GET'
  })
}
// 修改-总部
export function hqCoursewarePackageManageEdit(CoursewarePackageID, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewarePackageManage.Edit',
      CoursewarePackageID: CoursewarePackageID // 课件包ID
    },
    data: qs.stringify(Data),
    method: 'post'
  })
}
// 修改-普通
export function CoursewarePackageManageEdit(CoursewarePackageID, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePackageManage.Edit',
      CoursewarePackageID: CoursewarePackageID // 课件包ID
    },
    data: qs.stringify(Data),
    method: 'post'
  })
}

// 修改-普通
export function CWSCoursewarePackageManageEdit(CoursewarePackageID, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewarePackageManage.Edit',
      CoursewarePackageID: CoursewarePackageID // 课件包ID
    },
    data: qs.stringify(Data),
    method: 'post'
  })
}
// 详情-总部
export function hqCoursewarePackageManageGetDetail(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewarePackageManage.GetDetail',
      CoursewarePackageID: CoursewarePackageID //	课件包名称
    },
    method: 'GET'
  })
}
// 详情-普通
export function CoursewarePackageManageGetDetail(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePackageManage.GetDetail',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}

// CWS详情-普通
export function CWSCoursewarePackageManageGetDetail(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewarePackageManage.GetDetail',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}
// 门店-授权课件移入回收站
export function AuthCoursewarePackageDisable(AuthCoursewareID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_CoursewarePackageManage.AuthCoursewarePackageDisable',
      AuthCoursewareID: AuthCoursewareID //	课件包ID
    },
    method: 'GET'
  })
}
// 门店-授权课件移出回收站
export function AuthCoursewarePackageEnable(AuthCoursewareID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_CoursewarePackageManage.AuthCoursewarePackageEnable',
      AuthCoursewareID: AuthCoursewareID //	课件包ID
    },
    method: 'GET'
  })
}
// 复制-普通
export function CoursewarePackageManageCopy(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePackageManage.Copy',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}
// 复制-普通
export function CWSCoursewarePackageManageCopy(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewarePackageManage.Copy',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}
// 复制-总部
export function hqCoursewarePackageManageCopy(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewarePackageManage.Copy',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}
// 删除-普通
export function CoursewarePackageManageDelete(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePackageManage.Delete',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}
// 删除-cws
export function CWSCoursewarePackageManageDelete(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewarePackageManage.Delete',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}
// 删除-总部
export function hqCoursewarePackageManageDelete(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewarePackageManage.Delete',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}
// 停用-普通
export function CoursewarePackageManageDisable(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePackageManage.Disable',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}

// 停用-普通
export function CWSCoursewarePackageManageDisable(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewarePackageManage.Disable',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}
// 停用-总部
export function hqCoursewarePackageManageDisable(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewarePackageManage.Disable',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}
// 启用-普通
export function CoursewarePackageManageEnable(
  CoursewarePackageID,
  CatalogueKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePackageManage.Enable',
      CoursewarePackageID: CoursewarePackageID, //	课件包ID
      CatalogueKey: CatalogueKey
    },
    method: 'GET'
  })
}
// 启用-普通
export function CWSCoursewarePackageManageEnable(
  CoursewarePackageID,
  CatalogueKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewarePackageManage.Enable',
      CoursewarePackageID: CoursewarePackageID, //	课件包ID
      CatalogueKey: CatalogueKey
    },
    method: 'GET'
  })
}
// 启用-总部
export function hqCoursewarePackageManageEnable(
  CoursewarePackageID,
  CatalogueKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewarePackageManage.Enable',
      CoursewarePackageID: CoursewarePackageID, //	课件包ID
      CatalogueKey: CatalogueKey
    },
    method: 'GET'
  })
}
//  首次发布-普通
export function CoursewarePackageManageFirstPublish(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePackageManage.FirstPublish',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}
// 首次发布-总部
export function hqCoursewarePackageManageFirstPublish(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewarePackageManage.FirstPublish',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}

// 首次发布-总部
export function CWSCoursewarePackageManageFirstPublish(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewarePackageManage.FirstPublish',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}
// 发布记录-普通
export function CoursewarePackageManageGetPublishLog(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePackageManage.GetPublishLog',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}

// 发布记录-普通
export function CWSCoursewarePackageManageGetPublishLog(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewarePackageManage.GetPublishLog',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}
// 发布记录-总部
export function hqCoursewarePackageManageGetPublishLog(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewarePackageManage.GetPublishLog',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}
// 发布更新-普通
export function CoursewarePackageManageUpdatePublish(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePackageManage.UpdatePublish',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}

// 发布更新-总部
export function CWSCoursewarePackageManageUpdatePublish(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewarePackageManage.UpdatePublish',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}
// 发布更新-总部
export function hqCoursewarePackageManageUpdatePublish(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewarePackageManage.UpdatePublish',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}
// 撤回- 普通;
export function CoursewarePackageManageUpdateWithdraw(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePackageManage.Withdraw',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}
// 撤回-总部
export function hqCoursewarePackageManageUpdateWithdraw(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewarePackageManage.Withdraw',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}

// 撤回-总部
export function CWSCoursewarePackageManageUpdateWithdraw(CoursewarePackageID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewarePackageManage.Withdraw',
      CoursewarePackageID: CoursewarePackageID //	课件包ID
    },
    method: 'GET'
  })
}
// 排序
export function hqCoursewareManageSort(CoursewareID, SortNum) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.Sort',
      CoursewareID: CoursewareID, //	整型	必须		数据源：GET	课件ID
      SortNum: SortNum //	整型	必须		数据源：GET	排序序号
    },
    method: 'GET'
  })
}
// 排序
export function CoursewareManageSort(CoursewareID, SortNum) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.Sort',
      CoursewareID: CoursewareID, //	整型	必须		数据源：GET	课件ID
      SortNum: SortNum //	整型	必须		数据源：GET	排序序号
    },
    method: 'GET'
  })
}

// 恢复课件 HQ.AuthorizationCourseware.RecoveryAuthorize
export function RecoveryAuthorize(SchoolKey, CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.RecoveryAuthorize',
      SchoolKey: SchoolKey,
      CoursewarePackageKey: CoursewarePackageKey //	课件包ID
    },
    method: 'GET'
  })
}
// 锁定课件 HQ.AuthorizationCourseware.LockAuthorize
export function LockAuthorize(SchoolKey, CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.LockAuthorize',
      SchoolKey: SchoolKey,
      CoursewarePackageKey: CoursewarePackageKey //	课件包ID
    },
    method: 'GET'
  })
}

// 新增课类课名
export function hqAddTheClassType(IsSameContinue, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ClassTypeManage.AddTheClassType',
      IsSameContinue: IsSameContinue || '0' // 是否同名后继续：0-否;1-是;
    },
    method: 'post',
    data: qs.stringify(Data), // json(MainDemoName -- 名称 FatherKey--上级课类Key 0为全部 TypeKey -- 类型key)
    checkResubmit: true
  })
}
// 修改课类课名
export function hqEditTheClassType(IsSameContinue, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ClassTypeManage.EditTheClassType',
      IsSameContinue: IsSameContinue || '0' // 是否同名后继续：0-否;1-是;
    },
    method: 'post',
    data: qs.stringify(Data), // json(OLAPKey -- id MainDemoName--名称 FatherKey--上级课类Key 0为全部 UnitPrice--单价)
    checkResubmit: true
  })
}
// 启用课名课类接口
export function hqStartTheClassType(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ClassTypeManage.StartTheClassType',
      IsSameContinue: data.IsSameContinue || 0, //	整型	必须		数据源：GET	是否同名后继续：0-否;1-是;
      OLAPKey: data.OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 停用课名课类接口
export function hqStopTheClassType(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ClassTypeManage.StopTheClassType',
      OLAPKey: OLAPKey
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 获取 课类课名 列表
export function hqGetNotDelCourse(orderBy, sequence) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ClassTypeManage.GetNotDelCourse',
      orderBy: orderBy, // 字符串	可选		数据源：GET	排序字段
      sequence: sequence //	字符串	可选		数据源：GET	排序方向
    },
    method: 'GET'
  })
}
// 返回所有符合条件的课程(不包括课类),指定页指定条数
export function hqMessageSearchPageForCourseType(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ClassTypeManage.MessageSearchPageForCourseType',
      pageIndex: parameter.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为课程名
      sequence: parameter.sequence, //	字符串	可选	asc	数据源：GET	排序方向 ASC正序 DESC 倒序
      searchText: parameter.searchText //	字符串	可选		数据源：GET	搜索条件：模糊匹配 课程名
    },
    method: 'GET'
  })
}
// 根据ID获取指定课名课类
export function hqGetTheClassType(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ClassTypeManage.GetTheClassType',
      OLAPKey: OLAPKey // 套餐ID
    },
    method: 'GET'
  })
}
// 获取下一个风格
export function hqGetNextStyle() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ClassTypeManage.GetNextStyle'
    },
    method: 'GET'
  })
}
// 获取课件详情For备课
export function GetCoursewareDetailForPrepareLessons(ScheduleCourseID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.GetCoursewareDetailForPrepareLessons',
      ScheduleCourseID: ScheduleCourseID
    },
    method: 'GET'
  })
}
// 课件包审核通过
export function webvCoursewareAuditPass(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePackageManage.AuditPass',
      CoursewarePackageKey: CoursewarePackageKey
    },
    method: 'GET'
  })
}
// 课件包拒绝
export function webvCoursewareRefuse(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePackageManage.Refuse',
      CoursewarePackageKey: CoursewarePackageKey
    },
    method: 'GET'
  })
}

// 课件包审核通过
export function CWSvCoursewareAuditPass(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewarePackageManage.AuditPass',
      CoursewarePackageKey: CoursewarePackageKey
    },
    method: 'GET'
  })
}
// 课件包拒绝
export function CWSvCoursewareRefuse(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewarePackageManage.Refuse',
      CoursewarePackageKey: CoursewarePackageKey
    },
    method: 'GET'
  })
}

// 上课-获取防盗水印设置
export function GetSecurityWatermark() {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'TeacherGiveLecture.GetSecurityWatermark'
    },
    method: 'GET'
  })
}

// 备课-获取防盗水印设置-备课
export function TeacherPrepareLessonsGetSecurityWatermark() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherPrepareLessons.GetSecurityWatermark'
    },
    method: 'GET'
  })
}
// 修改门店设置
export function ModifySetting(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.ModifySetting',
      SetKey: parameter.SetKey, //	整型	必须		数据源：GET	是否开启 0-否,1-是
      SetKeyValue: parameter.SetKeyValue, //	字符串	必须		数据源：GET	0-否,1-是
      SetContent: parameter.SetContent, //	字符串	必须		数据源：GET
      SetTypeKey: parameter.SetTypeKey //	整型	必须		数据源：GET
    },
    method: 'GET'
  })
}
// 修改门店设置
export function CWSModifySetting(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.SaaSClientManagement.ModifySetting',
      SetKey: parameter.SetKey, //	整型	必须		数据源：GET	是否开启 0-否,1-是
      SetKeyValue: parameter.SetKeyValue, //	字符串	必须		数据源：GET	0-否,1-是
      SetContent: parameter.SetContent, //	字符串	必须		数据源：GET
      SetTypeKey: parameter.SetTypeKey //	整型	必须		数据源：GET
    },
    method: 'GET'
  })
}

// HQ.IncomePayManage.GetSZGLDetails
export function GetSZGLDetails(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePayManage.GetSZGLDetails',
      TableID: TableID
    },
    method: 'GET'
  })
}
// 追销管理统计SaleStatistics
export function SaleStatistics() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_SaleStat.SaleStatistics'
    },
    method: 'GET'
  })
}
// 追销管理 线索统计 WEBV.Current_SaleStat.ThreadStat
export function ThreadStat(searchObj) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_SaleStat.ThreadStat',
      startTime: searchObj.startTime,
      endTime: searchObj.endTime
    },
    method: 'GET'
  })
}
// 追销 - 报名记录
export function SaleStatReportRecord(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_SaleStat.ReportRecord',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      saleMan: parameter.saleMan,
      sequence: parameter.sequence // 排序方式
    },
    method: 'GET'
  })
}
//
// 追销 - 待分配线索
export function GetWaitAllocationThread(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_SaleStat.GetWaitAllocationThread',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      type: parameter.type,
      StudentStatusKey: parameter.StudentStatusKey // 状态1-在校；2-潜客；3-离校
    },
    method: 'GET'
  })
}
// 获取全部销售
export function GetAllSales() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_Common.GetAllSales'
    },
    method: 'GET'
  })
}
/// /追销 - 分配
export function DoAllocationThread(
  StudentKeys,
  seletedSale,
  IsAllocation,
  IsRecycle
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_SaleStat.DoAllocationThread',
      StudentKeys: StudentKeys, // [学生id1，学生id2]
      salesmanKey: seletedSale.SalesmanKey, // 要分配的销售
      salesmanKeyValue: seletedSale.SalesmanKeyValue, // 要分配的销售
      IsAllocation: IsAllocation, //	 是否强制分配
      IsRecycle: IsRecycle // 是否在回收站中分配 1:是 0：否
    },
    method: 'GET'
  })
}
// 待分配线索-删除
export function SaleStatRecycleStudent(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_SaleStat.RecycleStudent',
      OLAPKey: OLAPKey
    },
    method: 'GET'
  })
}
// 线索回收站
export function WaitAllocationThreadRecycleBin(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_SaleStat.WaitAllocationThreadRecycleBin',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence // 排序方式
      // type: parameter.type
    },
    method: 'GET'
  })
}
// 跟进中线索
export function SearchPageForFollowUpThread(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_SaleStat.SearchPageForFollowUpThread',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      type: parameter.type,
      dateType: parameter.dateType,
      saleMan: parameter.saleMan
    },
    method: 'GET'
  })
}
// 逾期补录列表
export function SearchPageExpectedSupplement(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_SaleStat.SearchPageExpectedSupplement',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      type: parameter.type,
      saleMan: parameter.saleMan
    },
    method: 'GET'
  })
}
// 未及时跟进
export function SearchPageNotTimeLyFollowUp(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_SaleStat.SearchPageNotTimeLyFollowUp',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      type: parameter.type,
      saleMan: parameter.saleMan
    },
    method: 'GET'
  })
}
// 到店试课
export function SearchPageToShopTryClassList(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_SaleStat.SearchPageToShopTryClassList',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      type: parameter.type,
      saleMan: parameter.saleMan
    },
    method: 'GET'
  })
}
// 获取在线人数
export function GetOnlineNumber() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.GetOnlineNumber'
    },
    method: 'GET'
  })
}
// 销售追销--获取指定学生的追销跟进记录
export function SearchFollowUpRecordByStudentKey(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_StudentProfile.SearchFollowUpRecordByStudentKey',
      StudentKey
    },
    method: 'GET'
  })
}
// 销售追销--获取当前追销线索信息For销售跟进
export function GetCRMThreadForStudentProfile(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_StudentProfile.GetCRMThreadForStudentProfile',
      StudentKey
    },
    method: 'GET'
  })
}

// 学生考勤信息
export function getAttendanceListByStu(StudentNameKey, OrderByTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.AttendanceList',
      StudentNameKey: StudentNameKey,
      OrderByTime: OrderByTime
    },
    method: 'GET'
  })
}
// 学生档案-学生课单
export function SelectStudentXYKD(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.SelectStudentXYKD',
      StudentKey: StudentKey
    },
    method: 'GET'
  })
}
// 指定学生所有课的欠课时总数
export function DebtClassHourAllCourseListByStu(StudentNameKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.DebtClassHourAllCourseList',
      StudentNameKey: StudentNameKey
    },
    method: 'GET'
  })
}
// 欠课时排课列表 详情
export function DebtClassHourArrangeListByStu(StudentNameKey, CourseNameKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.DebtClassHourArrangeList',
      StudentNameKey: StudentNameKey,
      CourseNameKey: CourseNameKey
    },
    method: 'GET'
  })
}
// 获取规律循环课程
export function GetStructuredRuleClassType() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.GetStructuredRuleClassType'
    },
    method: 'GET'
  })
}
// 根据课ID获取规律循环课程
export function GetStructuredGradeClassByClassType(ClassTypeKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.StructuredRule.GetStructuredGradeClassByClassType',
      ClassTypeKey: ClassTypeKey
    },
    method: 'GET'
  })
}
// 恢复离校学生
export function RecoveryStudent(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.RecoveryStudent',
      StudentKey: StudentKey //		学生id
    },
    method: 'GET'
  })
}
// 获取学生合同
export function GetContractList(StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.GetContractList',
      StudentKey: StudentKey //		学生id
    },
    method: 'GET'
  })
}
// 合同新增
export function ContractAdd(StudentKey, AttachUrl, Title) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.ContractAdd',
      StudentKey: StudentKey,
      AttachUrl: AttachUrl, //		学生id
      Title: Title
    },
    method: 'GET'
  })
}
// 合同修改
export function ContractEdit(ContractKey, AttachUrl, Title) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.ContractEdit',
      ContractKey: ContractKey,
      AttachUrl: AttachUrl, //		学生id
      Title: Title
    },
    method: 'GET'
  })
}
// 合同删除
export function ContractDel(ContractKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.ContractDel',
      ContractKey: ContractKey
    },
    method: 'GET'
  })
}
// 变更考勤课单
export function AlterAttendanceXYKD(
  StudentKey,
  ScheduleCourseKeys,
  XYKDKey,
  OldXYKDKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.AlterAttendanceXYKD',
      StudentKey: StudentKey,
      ScheduleCourseKeys: ScheduleCourseKeys, //		学生id
      XYKDKey: XYKDKey,
      OldXYKDKey: OldXYKDKey
    },
    method: 'GET'
  })
}
// 撤销点评
export function DeleteStudentForComment(ScheduleCourseKey, StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.CommentsManagement.DeleteStudentForComment',
      ScheduleCourseKey: ScheduleCourseKey,
      StudentKey: StudentKey
    },
    method: 'GET'
  })
}
// 撤销作业
export function DeleteStudentForHomeWork(ScheduleCourseKey, StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.HomeWork.DeleteStudentForHomeWork',
      ScheduleCourseKey: ScheduleCourseKey,
      StudentKey: StudentKey
    },
    method: 'GET'
  })
}

// 课单查询统计
export function classMessageSearchTeachingPerformanceForCourse(
  parameter,
  jsonFormate
) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_XYKD.SearchPageXYKDList',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      StartTime: parameter.startTime, // 搜索条件
      EndTime: parameter.endTime, // 申请日期结束
      StatusKey: parameter.StatusKey, // 状态 0全部 1正常、2用完、3过期、4转让、5退课
      ApplyClassTypeKey: parameter.ApplyClassTypeKey, // 适用课程id
      TaoCanKey: parameter.TaoCanKey, // 套餐id
      Type: parameter.Type, // 类型 0全部 1单课 2套餐  3组合
      DataType: parameter.DataType // 日期类型 1购买日期 2生效日期 3失效日期
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 批量延期课时
export function updateCourseBatchDelay(parameter, jsonFormate) {
  jsonFormate = {
    TableIDList: JSON.stringify(jsonFormate)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.BatchChangeCourseTicketExpireTime',
      StuKey: parameter.StuKey, // 批量操作的学生ID
      ExpireTime: parameter.ExpireTime, // 过期日期 eg:YYYY-MM-DD
      Remarks: parameter.Remarks, // 备注
      DayNum: parameter.DayNum, //
      DelayTypeKey: parameter.DelayTypeKey // 延期状态，0-延期指定天数； 1-延期至指定日期
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 获取有效校区列表For督学专员
export function hqSelectSchoolForInspector() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.Common.SelectSchoolForInspector'
    },
    method: 'GET'
  })
}
// 备课检查-待检查
export function hqSearchPageNotCheckPrepareLessons(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectorEducation.SearchPageNotCheckPrepareLessons',
      pageIndex: parameter.pageIndex, //	整型	可选		数据源：GET	页码
      pageSize: parameter.pageSize, //	整型	可选		数据源：GET	分页大小
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	关键字
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey //	整型	可选		数据源：GET	校区id
    },
    method: 'GET'
  })
}
// 备课检查-已检查
export function hqSearchPageCheckedPrepareLessons(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectorEducation.SearchPageCheckedPrepareLessons',
      pageIndex: parameter.pageIndex, //	整型	可选		数据源：GET	页码
      pageSize: parameter.pageSize, //	整型	可选		数据源：GET	分页大小
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	关键字
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey //	整型	可选		数据源：GET	校区id
    },
    method: 'GET'
  })
}
// 督察专员-逾期未备课
export function hqSearchPageOverdueNotPrepareLessons(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectorEducation.SearchPageOverdueNotPrepareLessons',
      pageIndex: parameter.pageIndex, //	整型	可选		数据源：GET	页码
      pageSize: parameter.pageSize, //	整型	可选		数据源：GET	分页大小
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	关键字
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey //	整型	可选		数据源：GET	校区id
    },
    method: 'GET'
  })
}

// 督导专员-获取备课检查
export function hqGetPrepareLessonsExamine(PrepareLessonsKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectorEducation.GetPrepareLessonsExamine',
      PrepareLessonsKey: PrepareLessonsKey //	整型	可选		数据源：GET	备课id
    },
    method: 'GET'
  })
}
// 督导专员-获取备课详情
export function hqGetPrepareLessonsDetail(PrepareLessonsKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectorEducation.GetPrepareLessonsDetail',
      PrepareLessonsKey: PrepareLessonsKey //	整型	可选		数据源：GET	备课id
    },
    method: 'GET'
  })
}
// 督查专员-提交备课检查
export function hqSubmitPrepareLessonsExamine(Feedback, parameter) {
  Feedback = {
    Feedback: JSON.stringify(Feedback) // 反馈
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectorEducation.SubmitPrepareLessonsExamine',
      Score: parameter.Score, //	整型	可选		数据源：GET	得分
      PrepareLessonsKey: parameter.PrepareLessonsKey //	整型	可选		数据源：GET	备课id
    },
    data: qs.stringify(Feedback),
    method: 'post'
  })
}

// 校区备课统计（总部）
export function hqSchoolPrepareLessonsStat(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectStat.SchoolPrepareLessonsStat',
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey //	门店id
    },
    method: 'GET'
  })
}
// 督学统计-备课统计-待检查（总部）
export function hqInspectStatSearchPageNotCheckPrepareLessons(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectStat.SearchPageNotCheckPrepareLessons',
      pageIndex: parameter.pageIndex, //	整型	可选		数据源：GET	页码
      pageSize: parameter.pageSize, //	整型	可选		数据源：GET	分页大小
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	关键字
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey, //	整型	可选		数据源：GET	校区id
      TeacherKey: parameter.TeacherKey //	整型	可选		数据源：GET	老师id
    },
    method: 'GET'
  })
}
// 督学统计-备课统计-已检查（总部）
export function hqInspectStatSearchPageCheckedPrepareLessons(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectStat.SearchPageCheckedPrepareLessons',
      pageIndex: parameter.pageIndex, //	整型	可选		数据源：GET	页码
      pageSize: parameter.pageSize, //	整型	可选		数据源：GET	分页大小
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	关键字
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey, //	整型	可选		数据源：GET	校区id
      TeacherKey: parameter.TeacherKey //	整型	可选		数据源：GET	老师id
    },
    method: 'GET'
  })
}
// 督学统计-备课统计-逾期未备课（总部）
export function hqInspectStatSearchPageOverdueNotPrepareLessons(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectStat.SearchPageOverdueNotPrepareLessons',
      pageIndex: parameter.pageIndex, //	整型	可选		数据源：GET	页码
      pageSize: parameter.pageSize, //	整型	可选		数据源：GET	分页大小
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	关键字
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey, //	整型	可选		数据源：GET	校区id
      TeacherKey: parameter.TeacherKey //	整型	可选		数据源：GET	老师id
    },
    method: 'GET'
  })
}

// 校区作业督学统计（总部）
export function hqInspectHomeWorkStat(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectStat.InspectHomeWorkStat',
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey //	门店id
    },
    method: 'GET'
  })
}
// 校区课评督学统计（总部）
export function hqInspectAssessmentStat(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectStat.InspectAssessmentStat',
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey //	门店id
    },
    method: 'GET'
  })
}
// 分页查询老师课评统计（总部）
export function hqSearchPageTeacherAssessmentStat(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectStat.SearchPageTeacherAssessmentStat',
      pageIndex: parameter.pageIndex, //	页码
      pageSize: parameter.pageSize, //	分页大小
      searchText: parameter.searchText, //	关键字
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey, //	校区id
      TeacherKey: parameter.TeacherKey //	老师id
    },
    method: 'GET'
  })
}
// 分页查询老师作业批改 （总部）
export function hqSearchPageTeacherHomeWork(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectStat.SearchPageTeacherHomeWork',
      pageIndex: parameter.pageIndex, //	页码
      pageSize: parameter.pageSize, //	分页大小
      searchText: parameter.searchText, //	关键字
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey, //	校区id
      TeacherKey: parameter.TeacherKey //	老师id
    },
    method: 'GET'
  })
}

// 校区课评督学统计（门店）
export function InspectAssessmentStat(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_InspectStat.InspectAssessmentStat',
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey //	门店id
    },
    method: 'GET'
  })
}
// 校区作业督学统计（门店）
export function InspectHomeWorkStat(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_InspectStat.InspectHomeWorkStat',
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey //	门店id
    },
    method: 'GET'
  })
}
// 校区备课统计（门店）
export function SchoolPrepareLessonsStat(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_InspectStat.SchoolPrepareLessonsStat',
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey //	门店id
    },
    method: 'GET'
  })
}
// 督学统计-备课统计-待检查（门店）
export function SearchPageNotCheckPrepareLessons(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_InspectStat.SearchPageNotCheckPrepareLessons',
      pageIndex: parameter.pageIndex, //	整型	可选		数据源：GET	页码
      pageSize: parameter.pageSize, //	整型	可选		数据源：GET	分页大小
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	关键字
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey, //	整型	可选		数据源：GET	校区id
      TeacherKey: parameter.TeacherKey //	整型	可选		数据源：GET	老师id
    },
    method: 'GET'
  })
}
// 督学统计-备课统计-已检查（门店）
export function SearchPageCheckedPrepareLessons(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_InspectStat.SearchPageCheckedPrepareLessons',
      pageIndex: parameter.pageIndex, //	整型	可选		数据源：GET	页码
      pageSize: parameter.pageSize, //	整型	可选		数据源：GET	分页大小
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	关键字
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey, //	整型	可选		数据源：GET	校区id
      TeacherKey: parameter.TeacherKey //	整型	可选		数据源：GET	老师id
    },
    method: 'GET'
  })
}
// 督学统计-备课统计-逾期未备课（门店）
export function SearchPageOverdueNotPrepareLessons(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_InspectStat.SearchPageOverdueNotPrepareLessons',
      pageIndex: parameter.pageIndex, //	整型	可选		数据源：GET	页码
      pageSize: parameter.pageSize, //	整型	可选		数据源：GET	分页大小
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	关键字
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey, //	整型	可选		数据源：GET	校区id
      TeacherKey: parameter.TeacherKey //	整型	可选		数据源：GET	老师id
    },
    method: 'GET'
  })
}
// 分页查询老师课评统计（门店）
export function SearchPageTeacherAssessmentStat(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_InspectStat.SearchPageTeacherAssessmentStat',
      pageIndex: parameter.pageIndex, //	页码
      pageSize: parameter.pageSize, //	分页大小
      searchText: parameter.searchText, //	关键字
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey, //	校区id
      TeacherKey: parameter.TeacherKey //	老师id
    },
    method: 'GET'
  })
}
// 分页查询老师作业批改 （门店）
export function SearchPageTeacherHomeWork(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_InspectStat.SearchPageTeacherHomeWork',
      pageIndex: parameter.pageIndex, //	页码
      pageSize: parameter.pageSize, //	分页大小
      searchText: parameter.searchText, //	关键字
      StartDate: parameter.StartDate, //	字符串	可选		数据源：GET	开始日期
      EndDate: parameter.EndDate, //	字符串	可选		数据源：GET	结束日期
      SchoolKey: parameter.SchoolKey, //	校区id
      TeacherKey: parameter.TeacherKey //	老师id
    },
    method: 'GET'
  })
}
// 补课管理--获取课程待处理人数和课次
export function GetPendingMakeUpCoursePersonCount(CourseNameKey, IsCompact) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.MakeupManage.GetPendingMakeUpCoursePersonCount',
      CourseNameKey: CourseNameKey, // 课程ID
      IsCompact: IsCompact // 是否简洁 0-否1-是
    },
    method: 'GET'
  })
}
// 补课管理-撤销操作
export function RevokeDispose(
  StudentKey,
  ScheduleCourseKey,
  MakeupCourseKey,
  ReasonContent
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.MakeupManage.RevokeDispose',
      StudentKey: StudentKey, // 学生key
      ScheduleCourseKey: ScheduleCourseKey, // 缺课ID
      MakeupCourseKey: MakeupCourseKey, // 补课ID
      ReasonContent: ReasonContent // 撤销原因
    },
    method: 'GET'
  })
}
// 补课管理--获取指定课程待补课学生信息
export function MessageSearchPageMakeUpCourseStudens(
  StartTime,
  EndTime,
  ClassTypeKey,
  IsCompact
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.MakeupManage.MessageSearchPageMakeUpCourseStudens',
      StartTime: StartTime, //	日期
      EndTime: EndTime, //	日期
      ClassTypeKey: ClassTypeKey, //	课程ID
      IsCompact: IsCompact //	是否简洁 0-否1-是
    },
    method: 'GET'
  })
}
// 校区督学统计
export function schoolDistrictSuperintendentStatistics(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectStat.SchoolInspectStat',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      // searchText: parameter.searchText, // 搜索条件
      StartDate: parameter.startTime, // 搜索条件
      EndDate: parameter.endTime, // 申请日期结束
      SchoolKey: parameter.SchoolKey // 门店id
      // orderBy: parameter.orderBy, // 排序字段
      // sequence: parameter.sequence, // 排序方式
      // TeacherKey: parameter.TeacherKey,
      // GradeKey: parameter.GradeKey,
      // CourseKey: parameter.CourseKey,
      // IsExportKey: parameter.IsExportKey,
      // IsMTeaKey: parameter.IsMTeaKey,
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// 获取课内学生作业列表
export function GetScheduleCourseStudentHomeWorkList(
  ScheduleCourseKey,
  IsAllSchool
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.HomeWork.GetScheduleCourseStudentHomeWorkList',
      ScheduleCourseKey: ScheduleCourseKey, // 课id
      IsAllSchool: IsAllSchool // 整型	可选		数据源：GET	模式 1-全校;2-普通
    },
    method: 'GET'
  })
}
// 总店获取课内学生作业列表
export function HQGetScheduleCourseStudentHomeWorkList(
  ScheduleCourseKey,
  IsAllSchool,
  TeacherKey,
  SchoolKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectStat.GetScheduleCourseStudentHomeWorkList',
      ScheduleCourseKey: ScheduleCourseKey, // 课id
      IsAllSchool: IsAllSchool, // 整型	可选		数据源：GET	模式 1-全校;2-普通
      TeacherKey: TeacherKey,
      SchoolKey: SchoolKey
    },
    method: 'GET'
  })
}
// 分店获取课内学生作业列表
export function WEBVGetScheduleCourseStudentHomeWorkList(
  ScheduleCourseKey,
  IsAllSchool,
  TeacherKey,
  SchoolKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_InspectStat.GetScheduleCourseStudentHomeWorkList',
      ScheduleCourseKey: ScheduleCourseKey, // 课id
      IsAllSchool: IsAllSchool, // 整型	可选		数据源：GET	模式 1-全校;2-普通
      TeacherKey: TeacherKey,
      SchoolKey: SchoolKey
    },
    method: 'GET'
  })
}

// 获取课点评详情
export function GetScheduleCourseCommentDetails(ScheduleCourseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.TeacherDynamicClass.GetScheduleCourseCommentDetails',
      ScheduleCourseKey: ScheduleCourseKey // 课id
    },
    method: 'GET'
  })
}
// 督学设置-分页查询
export function superintendentSet(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectorsSetting.SearchPageInspectorsSetting',
      pageIndex: parameter.pageIndex,
      pageSize: parameter.pageSize,
      searchText: parameter.searchText,
      orderBy: parameter.orderBy,
      sequence: parameter.sequence,
      IsActiveKey: parameter.IsActiveKey, //	整型	可选		数据源：GET	是否活跃校区
      IsStopKey: parameter.IsStopKey, //	整型	可选		数据源：GET	是否停用
      IsExpiredKey: parameter.IsExpiredKey, //	整型	可选		数据源：GET	是否已过期
      IsExpired30Key: parameter.IsExpired30Key //	整型	可选		数据源：GET	是否30天内过期
    },
    method: 'GET'
  })
}

// 督学设置-关闭
export function closeSuperintendentSet(parameter, jsonFormate) {
  jsonFormate = {
    Data: JSON.stringify(jsonFormate)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectorsSetting.AlterInspectorsSetting',
      SchoolKey: parameter.SchoolKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// shcoolkey获取督查设置信息
export function monitorSetupInformation(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectorsSetting.GetInspectorsSettingBySchoolKey',
      SchoolKey: parameter.SchoolKey //	校区id
    },
    method: 'get'
  })
}
// 获取学生提交作业详情
export function GetStudentSubmitHomeWorkDetails(SubHomeWorkKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.HomeWork.GetStudentSubmitHomeWorkDetails',
      SubHomeWorkKey: SubHomeWorkKey // 作业id
    },
    method: 'GET'
  })
}
// 获取指定点评点赞列表
export function GetPraiseListForComment(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.CommentsManagement.GetPraiseListForComment',
      id: id // 点评id
    },
    method: 'GET'
  })
}
// 获取指定点评转发列表
export function GetShareListForComment(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.CommentsManagement.GetShareListForComment',
      id: id // 点评id
    },
    method: 'GET'
  })
}

// 获取备课设置
export function getsLessonPreparationSettings() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectorsSetting.GetPrepareLessonsSetting'
    },
    method: 'GET'
  })
}

// 修改备课设置
export function modifyLessonPreparationSettings(jsonFormate) {
  jsonFormate = {
    Data: JSON.stringify(jsonFormate)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InspectorsSetting.AlterPrepareLessonsSetting'
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// 分片上传文件预处理
export function MultipartUploadPre(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEB.Files.MultipartUploadPre',
      identifier: parameter.identifier,
      type: parameter.type,
      totalSize: parameter.totalSize,
      ext: parameter.ext
    },
    method: 'GET'
  })
}

// 分片上传文件
export function MultipartUpload(parameter, file, updataPercentage) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEB.Files.MultipartUpload',
      identifier: parameter.identifier,
      taskid: parameter.taskid,
      currentPartNumber: parameter.currentPartNumber,
      md5: parameter.md5
    },
    method: 'post',
    data: file,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress(progressEvent) {
      if (parameter && progressEvent.lengthComputable) {
        parameter.percent = (
          (progressEvent.loaded / progressEvent.total) *
          100
        ).toFixed(0)
        updataPercentage(parameter.percent)
      }
    },
    timeout: 600000
  })
}

// 分片上传文件合并
export function MultipartUploadEnd(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEB.Files.MultipartUploadEnd',
      identifier: parameter.identifier,
      taskid: parameter.taskid
    },
    method: 'GET'
  })
}

// 课件附件分片上传文件预处理
export function HQMultipartUploadPre(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.UploadMaterialPre',
      identifier: parameter.identifier,
      type: parameter.type,
      totalSize: parameter.totalSize,
      ext: parameter.ext,
      CoursewareID: parameter.CoursewareID,
      AuthorizeCode: parameter.AuthorizeCode,
      TypeKey: parameter.TypeKey,
      client_id: store.getters.clientId
    },
    method: 'GET'
  })
}

// 课件附件分片上传文件
export function HQMultipartUpload(parameter, file, updataPercentage) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.MultipartUpload',
      identifier: parameter.identifier,
      taskid: parameter.taskid,
      currentPartNumber: parameter.currentPartNumber,
      md5: parameter.md5
    },
    method: 'post',
    data: file,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress(progressEvent) {
      if (parameter && progressEvent.lengthComputable) {
        parameter.percent = (
          (progressEvent.loaded / progressEvent.total) *
          100
        ).toFixed(0)
        updataPercentage(parameter.percent)
      }
    },
    timeout: 600000
  })
}

// 课件附件分片上传文件合并音频
export function MultipartUploadEndForAudio(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.MultipartUploadEndForAudio',
      identifier: parameter.identifier,
      taskid: parameter.taskid,
      CoursewareID: parameter.CoursewareID,
      AuthorizeCode: parameter.AuthorizeCode,
      client_id: store.getters.clientId
    },
    method: 'GET'
  })
}

// 课件附件分片上传文件合并图片
export function MultipartUploadEndForImg(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.MultipartUploadEndForImg',
      identifier: parameter.identifier,
      taskid: parameter.taskid,
      CoursewareID: parameter.CoursewareID,
      AuthorizeCode: parameter.AuthorizeCode,
      client_id: store.getters.clientId
    },
    method: 'GET'
  })
}

// 课件附件分片上传文件合并PPT
export function MultipartUploadEndForPPT(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.MultipartUploadEndForPPT',
      identifier: parameter.identifier,
      taskid: parameter.taskid,
      CoursewareID: parameter.CoursewareID,
      AuthorizeCode: parameter.AuthorizeCode,
      client_id: store.getters.clientId
    },
    method: 'GET'
  })
}
// 课件附件分片上传文件合并PDF
export function MultipartUploadEndForPDF(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.MultipartUploadEndForPDF',
      identifier: parameter.identifier,
      taskid: parameter.taskid,
      CoursewareID: parameter.CoursewareID,
      AuthorizeCode: parameter.AuthorizeCode,
      client_id: store.getters.clientId,
      TypeKey: parameter.TypeKey
    },
    method: 'GET'
  })
}

// 课件附件分片上传文件合并视频
export function MultipartUploadEndForVideo(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.MultipartUploadEndForVideo',
      identifier: parameter.identifier,
      taskid: parameter.taskid,
      CoursewareID: parameter.CoursewareID,
      AuthorizeCode: parameter.AuthorizeCode,
      client_id: store.getters.clientId,
      TypeKey: parameter.TypeKey
    },
    method: 'GET'
  })
}

// 删除人脸信息
export function delFaceInfo(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.FaceRecognition.ClearStudentFace',
      OLAPKey: data.OLAPKey,
      IsHeadImgKey: data.IsHeadImgKey
    },
    method: 'get'
  })
}

// 根据学生id查询是否已录入人脸
export function accordingStudentID(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.FaceRecognition.VerifyIsFaceRecognition',
      OLAPKey: OLAPKey
    },
    method: 'get'
  })
}

// 关闭品牌定制
export function CloseBrandCustom(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.CloseBrandCustom',
      OLAPKey: OLAPKey
    },
    method: 'get',
    checkResubmit: true
  })
}
// 开启品牌定制
export function OpenBrandCustom(OLAPKey, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.OpenBrandCustom',
      OLAPKey: OLAPKey
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 修改品牌定制
export function UpdateBrandCustom(OLAPKey, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.UpdateBrandCustom',
      OLAPKey: OLAPKey
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 获取门店品牌定制信息
export function GetBrandCustom(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.GetBrandCustom',
      OLAPKey: OLAPKey
    },
    method: 'get',
    checkResubmit: true
  })
}
// 员工改密码
export function HQEditPassword(oldPWD, newPWD) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.EmployeeManage.EditPassword',
      oldPWD: oldPWD,
      newPWD: newPWD
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 强制修改密码
export function LoginForceEditPassword(NewPassword, ConfirmPassword) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Login.LoginForceEditPassword',
      NewPassword: NewPassword, //	新密码
      ConfirmPassword: ConfirmPassword // 确认密码
    },
    method: 'get'
  })
}
// 门店-授权课件包列表
export function GetAllAuthCoursewarePackageList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_ParentCourseWarePackage.GetAllAuthCoursewarePackageList'
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 门店-授权课件包列表
export function SearchPageAuthCoursewarePackage(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_ParentCourseWarePackage.SearchPageAuthCoursewarePackage',
      pageIndex: parameter.pageIndex,
      pageSize: parameter.pageSize
    },
    method: 'GET',
    checkResubmit: true
  })
}
// 小程序三方发布平台 分页查询模版列表
export function SearchPageTemplate(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgram.SearchPageTemplate',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText
    },
    method: 'get'
  })
}
// 获取流量套餐管理
export function GetFluxComboList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.FluxCombo.GetFluxComboList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      search: params.searchText
    },
    method: 'get'
  })
}
// 获取流量套餐管理列表
export function GetFluxComboInfo(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.FluxCombo.GetFluxComboInfo',
      OLAPKey: OLAPKey
    },
    method: 'GET'
  })
}
// 修改流量套餐列表
export function EditFluxComboInfo(OLAPKey, data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.FluxCombo.EditFluxComboInfo',
      OLAPKey: OLAPKey //	流量套餐id
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 添加流量套餐
export function AddFluxComboInfo(data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.FluxCombo.AddFluxComboInfo'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}

// 停用
export function ExitFluxConboStatus(OLAPKey, StatusKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.FluxCombo.ExitFluxConboStatus',
      OLAPKey: OLAPKey, //	流量套餐id
      StatusKey: StatusKey // 状态
    },
    method: 'GET'
  })
}
// 小程序三方发布平台 获取指定版本预发布信息
export function GetPreparePublishMsgByTemplateID(TemplateID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'Admin.MiniProgramPreparePublish.GetPreparePublishMsgByTemplateID',
      TemplateID // 模板id
    },
    method: 'get'
  })
}
// 小程序三方发布平台 预发布记录
export function GetPreparePublishMsgByMiniProgramID(TemplateID, MiniProgramID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'Admin.MiniProgramPreparePublish.GetPreparePublishMsgByMiniProgramID',
      TemplateID, // 模板id
      MiniProgramID // 小程序id
    },
    method: 'get'
  })
}
// 小程序三方发布平台 预发布 设置为现行版本
export function SetForVersion(TemplateID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPreparePublish.SetForVersion',
      TemplateID // 模板id
    },
    method: 'get'
  })
}
// 小程序三方发布平台 预发布 上传代码
export function UploadCode(template_id, user_version, user_desc, AppID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPreparePublish.UploadCode',
      template_id,
      user_version,
      user_desc,
      AppID
    },
    method: 'get'
  })
}
// 小程序三方发布平台 预发布 获取体验版二维码
export function GetQRCodeForExperience(path, AppID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPreparePublish.GetQRCodeForExperience',
      path,
      AppID
    },
    method: 'get'
  })
}
// 小程序三方发布平台 设置小程序服务器域名
export function ModifyDomain(AppID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgram.ModifyDomain',
      AppID
    },
    method: 'get'
  })
}
// 小程序三方发布平台 设置业务域名
export function SetWebViewDomain(AppID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgram.SetWebViewDomain',
      AppID
    },
    method: 'get'
  })
}
// 小程序三方发布平台 预发布 提交审核
export function SubmitAudit(AppID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPreparePublish.SubmitAudit',
      AppID
    },
    method: 'get'
  })
}
// 小程序三方发布平台 预发布 审核撤回
export function UndoCodeAudit(AppID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPreparePublish.UndoCodeAudit',
      AppID
    },
    method: 'get'
  })
}
// 小程序三方发布平台 预发布 修改预发布小程序APPID
export function UpdatePreMiniProgramPublishAppID(AppID, OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'Admin.MiniProgramPreparePublish.UpdatePreMiniProgramPublishAppID',
      AppID,
      OLAPKey
    },
    method: 'get'
  })
}

// 小程序三方发布平台 预发布 发布已通过审核的小程序
export function ReleasePrepareMiniProgram(AppID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPreparePublish.Release',
      AppID
    },
    method: 'get'
  })
}

// 小程序三方发布平台 发布
export function ReleaseMiniProgram(AppIDList) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPublish.Release',
      AppIDList // {"appid","appid","appid"} 空为全部
    },
    method: 'get'
  })
}

// 获取最新版本发布审核设置
export function GetNewAuditVersion() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPreparePublish.GetNewAuditVersion'
    },
    method: 'get'
  })
}

// 提交最新版本发布审核设置
export function SubmitNewAuditVersionn(Feedback, Explain, AttachUrlList) {
  var Data = {
    AttachUrlList: JSON.stringify(AttachUrlList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPreparePublish.SubmitNewAuditVersion',
      Feedback,
      Explain
    },
    method: 'post',
    data: qs.stringify(Data)
  })
}

// 获取最新版本隐私设置
export function GetNewPrivacyVersion() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPreparePublish.GetNewPrivacyVersion'
    },
    method: 'get'
  })
}

// 提交最新版本隐私设置
export function SubmitNewPrivacyVersion(NoticeType, EMail, PrivacyList) {
  var Data = {
    PrivacyList: JSON.stringify(PrivacyList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPreparePublish.SubmitNewPrivacyVersion',
      NoticeType,
      EMail
    },
    method: 'post',
    data: qs.stringify(Data)
  })
}

// 小程序三方发布平台 分页查询授权小程序
export function SearchPageMiniProgram(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPublish.SearchPageMiniProgram',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText
    },
    method: 'get'
  })
}

// 小程序发布记录
export function PublishRecord(AppID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPublish.PublishRecord',
      AppID
    },
    method: 'get'
  })
}

// 获取小程序基本信息
export function GetAccountBasicInfo(AppID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPublish.GetAccountBasicInfo',
      AppID
    },
    method: 'get'
  })
}

// 批量发布已通过审核的小程序
export function MiniProgramBatchRelease(AppIDList) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPublish.BatchRelease',
      AppIDList
    },
    method: 'get'
  })
}

// 批量提交审核
export function MiniProgramBatchSubmitAudit(AppIDList) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPublish.BatchSubmitAudit',
      AppIDList
    },
    method: 'get'
  })
}

// 批量审核撤回
export function MiniProgramBatchUndoCodeAudit(AppIDList) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPublish.BatchUndoCodeAudit',
      AppIDList
    },
    method: 'get'
  })
}

// 批量上传代码
export function MiniProgramBatchUploadCode(AppIDList) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.MiniProgramPublish.BatchUploadCode',
      AppIDList
    },
    method: 'get'
  })
}

// 查询所有招商杂费设置-总部
export function hqAttractInvestmentIncidentals() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AttractInvestmentIncidentals.SearchList'
    },
    method: 'GET'
  })
}
// 新增招商杂费设置-总部
export function hqAddIncidentals(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AttractInvestmentIncidentals.Add'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}

// 修改招商杂费-总部
export function hqEditIncidentals(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AttractInvestmentIncidentals.Edit'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 停用招商杂费-总部
export function hqIncidentalsBlockUp(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AttractInvestmentIncidentals.BlockUp',
      ID: ID
    },
    method: 'GET'
  })
}
// 启用招商杂费-总部
export function hqIncidentalsStartUsing(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AttractInvestmentIncidentals.StartUsing',
      ID: ID
    },
    method: 'GET'
  })
}
// 删除招商杂费-总部
export function hqIncidentalsDelete(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AttractInvestmentIncidentals.Delete',
      ID: ID
    },
    method: 'GET'
  })
}

// 查询所有招商来源设置-总部
export function hqAttractInvestmentSourceSet() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AttractInvestmentSourceSet.SearchList'
    },
    method: 'GET'
  })
}
// 新增招商来源设置-总部
export function hqAddSourceSet(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AttractInvestmentSourceSet.Add'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 修改招商来源-总部
export function hqEditSourceType(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AttractInvestmentSourceSet.Edit'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 停用招商来源-总部
export function hqBlockUpSourceType(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AttractInvestmentSourceSet.BlockUp',
      ID: ID
    },
    method: 'GET'
  })
}
// 启用招商来源-总部
export function hqStartUsingSourceType(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AttractInvestmentSourceSet.StartUsing',
      ID: ID
    },
    method: 'GET'
  })
}
// 删除招商来源-总部
export function hqDeleteSourceType(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AttractInvestmentSourceSet.Delete',
      ID: ID
    },
    method: 'GET'
  })
}

// 分页查询待接收
export function SearchPageYKRecommendToBeReceive(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.YKRecommendManage.SearchPageYKRecommendToBeReceive',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      StartTime: params.StartTime,
      EndTime: params.EndTime
    },
    method: 'get'
  })
}

// 分页查询跟进中
export function SearchPageYKRecommendForFollowUp(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.YKRecommendManage.SearchPageYKRecommendForFollowUp',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      StartTime: params.StartTime,
      EndTime: params.EndTime,
      StatusKey: params.StatusKey
    },
    method: 'get'
  })
}

// 分页查询已拒绝
export function SearchPageYKRecommendForRefuse(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.YKRecommendManage.SearchPageYKRecommendForRefuse',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      StartTime: params.StartTime,
      EndTime: params.EndTime
    },
    method: 'get'
  })
}

// 分页查询超时接收
export function SearchPageYKRecommendForReceiveOvertime(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.YKRecommendManage.SearchPageYKRecommendForReceiveOvertime',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      StartTime: params.StartTime,
      EndTime: params.EndTime
    },
    method: 'get'
  })
}

// 分页查询已签单
export function SearchPageYKRecommendForSignBill(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.YKRecommendManage.SearchPageYKRecommendForSignBill',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      StartTime: params.StartTime,
      EndTime: params.EndTime,
      PaymentScheduleKey: params.PaymentScheduleKey
    },
    method: 'get'
  })
}
// 签约申请
export function HQSignUpToApply(data) {
  data = {
    data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ToSignTheBill.SignUpToApply'
    },
    method: 'post',
    data: qs.stringify(data),
    checkResubmit: true
  })
}
export function HQGetContractReviewDetail(attractInvestSignContractKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ToSignTheBill.GetContractReviewDetail',
      attractInvestSignContractKey: attractInvestSignContractKey
    },
    method: 'GET'
  })
}
// 签约审核通过
export function HQContractReview(attractInvestSignContractKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ToSignTheBill.ContractReview',
      attractInvestSignContractKey: attractInvestSignContractKey
    },
    method: 'GET'
  })
}
// 签约审核拒绝
export function HQRefuseContractReview(
  attractInvestSignContractKey,
  RefuseReson
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ToSignTheBill.RefuseContractReview',
      attractInvestSignContractKey: attractInvestSignContractKey
    },
    method: 'POST',
    data: qs.stringify({ RefuseReson: JSON.stringify(RefuseReson) })
  })
}
// 付款审核拒绝
export function HQRefusePaymentReview(
  attractInvestSignContractKey,
  RefuseReson
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ToSignTheBill.RefusePaymentReview',
      attractInvestSignContractKey: attractInvestSignContractKey
    },
    method: 'POST',
    data: qs.stringify({ RefuseReson: JSON.stringify(RefuseReson) })
  })
}

// 总部CRM-》招商管理-》顾问列表
export function GetCounselorList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.Common.GetCounselorList'
    },
    method: 'get'
  })
}
// 总部CRM-》招商管理-》投资人统计
export function InvestorManageStat() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentManagement.InvestorManageStat'
    },
    method: 'get'
  })
}

// 总部CRM-》招商管理-》新投资人列表
export function SearchPageNewInvestment(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentManagement.SearchPageNewInvestment',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      entryTimeStart: params.entryTimeStart,
      entryTimeEnd: params.entryTimeEnd,
      CounselorKey: params.CounselorKey,
      IsValidKey: params.IsValidKey,
      SourceTypeKey: params.SourceTypeKey,
      StatusKey: params.StatusKey
    },
    method: 'get'
  })
}
// 总部CRM-》招商管理-跟进中投资人列表
export function SearchPageBeFollowingUpInvestment(params, postData) {
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentManagement.SearchPageBeFollowingUpInvestment',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      entryTimeStart: params.entryTimeStart,
      entryTimeEnd: params.entryTimeEnd,
      CounselorKey: params.CounselorKey,
      IntentionKey: params.IntentionKey,
      SourceTypeKey: params.SourceTypeKey,
      StatusKey: params.StatusKey,
      IsExportKey: params.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    data: qs.stringify({ jsonFormate: JSON.stringify(postData) }),
    method: 'POST'
  })
}

// 总部CRM-》招商管理-已放弃投资人列表
export function SearchPageHaveToGiveUpInvestment(params, postData) {
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentManagement.SearchPageHaveToGiveUpInvestment',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      entryTimeStart: params.entryTimeStart,
      entryTimeEnd: params.entryTimeEnd,
      AbandonKey: params.AbandonKey,
      IntentionKey: params.IntentionKey,
      SourceTypeKey: params.SourceTypeKey,
      StatusKey: params.StatusKey,
      IsExportKey: params.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    data: qs.stringify({ jsonFormate: JSON.stringify(postData) }),
    method: 'POST'
  })
}
// 总部CRM-》招商管理-已签单投资人列表
export function SearchPageHasSignTheBillInvestment(params, postData) {
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentManagement.SearchPageHasSignTheBillInvestment',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      CounselorKey: params.CounselorKey,
      PaymentScheduleKey: params.PaymentScheduleKey,
      SourceTypeKey: params.SourceTypeKey,
      FranchiseTypeKey: params.FranchiseTypeKey,
      IsExportKey: params.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    data: qs.stringify({ jsonFormate: JSON.stringify(postData) }),
    method: 'POST'
  })
}
// 总部CRM-》招商管理-跟单记录列表
export function SearchPageFollowOrderRecord(params, postData) {
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentManagement.SearchPageFollowOrderRecord',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      entryTimeStart: params.entryTimeStart,
      entryTimeEnd: params.entryTimeEnd,
      CounselorKey: params.CounselorKey,
      SourceTypeKey: params.SourceTypeKey,
      StatusKey: params.StatusKey,
      IsExportKey: params.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    data: qs.stringify({ jsonFormate: JSON.stringify(postData) }),
    method: 'POST'
  })
}
// 总部CRM-》招商管理-回收站投资人列表
export function SearchPageRecycleInvestor(params, postData) {
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentManagement.SearchPageRecycleInvestor',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      CounselorKey: params.CounselorKey,
      IsValidKey: params.IsValidKey,
      SourceTypeKey: params.SourceTypeKey,
      StatusKey: params.StatusKey,
      IsExportKey: params.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    data: qs.stringify({ jsonFormate: JSON.stringify(postData) }),
    method: 'POST'
  })
}

// 总部CRM-》招商管理->分配顾问
export function AllotInvestor(EmployeeKey, EmployeeKeyValue, Investor) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentManagement.AllotInvestor',
      EmployeeKey,
      EmployeeKeyValue,
      Investor
    },
    method: 'get'
  })
}
// 总部CRM-》招商管理->删除投资人
export function DeleteInvestor(InvestorKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentManagement.DeleteInvestor',
      InvestorKey
    },
    method: 'get'
  })
}
// 投资线索导入
export function ImportInvestor(file) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentManagement.ImportInvestor'
    },
    method: 'POST',
    data: file
  })
}

// 招商顾问工作台-分页查询招商线索
export function hqSearchPageAttractThread(params, postData) {
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentAdvisersWorkBench.SearchPageAttractThread',
      pageIndex: params.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: params.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      searchText: params.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配投资人姓名手机号
      TypeKey: params.TypeKey, //	整型	可选	0	数据源：GET	0-全部;1-待跟进
      IntentionKey: params.IntentionKey, //	整型	可选	0	数据源：GET	意向级别 1-S;2-A;3-B;4-C
      StatusKey: params.StatusKey, //	整型	可选	0	数据源：GET	签单进度 2-跟进中;3-已预约;4-已考察;5-已签单;6-已付订;7-已全款
      QueryID: params.QueryID, //	整型	可选	0	数据源：GET	数据key
      AddToDateStart: params.AddToDateStart, //	整型	可选	0	数据源：GET	添加日期-开始
      AddToDateEnd: params.AddToDateEnd, //	整型	可选	0	数据源：GET	添加日期-结束
      IsExportKey: params.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    data: qs.stringify({ jsonFormate: JSON.stringify(postData) }),
    method: 'POST'
  })
}
// 招商顾问工作台-无效线索
export function hqInvalid(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentAdvisersWorkBench.Invalid',
      AttractThreadKey: params.AttractThreadKey, //	整型	投资线索Key
      InvalidReasonKey: params.InvalidReasonKey, //	整型	无效原因
      InvalidReasonKeyValue: params.InvalidReasonKeyValue //	字符串	1-无人接听;2-无意向
    },
    method: 'GET'
  })
}
// 招商顾问工作台-获取招商线索详情
export function hqGetAttractThreadMsg(AttractThreadKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentAdvisersWorkBench.GetAttractThreadMsg',
      AttractThreadKey: AttractThreadKey //	整型	投资线索Key
    },
    method: 'GET'
  })
}
// 招商顾问工作台-获取招商线索跟进记录
export function hqGetAttractThreadFollowUpRecord(AttractThreadKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentAdvisersWorkBench.GetAttractThreadFollowUpRecord',
      AttractThreadKey: AttractThreadKey //	整型	投资线索Key
    },
    method: 'GET'
  })
}

// 招商顾问工作台-完善档案
export function hqCompleteAttractMsg(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentAdvisersWorkBench.CompleteAttractMsg'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 招商顾问工作台-添加招商线索跟进记录
export function hqAddAttractFollowRecord(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentAdvisersWorkBench.AddAttractFollowRecord'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 推荐签单
export function YKRecommendManageReceive(
  AttractInvestKey,
  CounselorKey,
  CounselorKeyValue
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.YKRecommendManage.Receive',
      AttractInvestKey,
      CounselorKey,
      CounselorKeyValue
    },
    method: 'get'
  })
}
// 推荐拒绝
export function YKRecommendManageRefuse(AttractInvestKey, RefuseReason) {
  var Data = {
    RefuseReason: JSON.stringify(RefuseReason)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.YKRecommendManage.Refuse',
      AttractInvestKey,
      RefuseReason
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 合同申请详情
export function HQGetSignUpToApplyDetail(attractInvestKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ToSignTheBill.GetSignUpToApplyDetail',
      attractInvestKey: attractInvestKey
    },
    method: 'GET'
  })
}
// 付款申请详情.
export function HQGetPaymentRequestDetail(AttractInvestKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ToSignTheBill.GetPaymentRequestDetail',
      AttractInvestKey: AttractInvestKey
    },
    method: 'GET'
  })
}
// 付款审核详情.
export function HQGetPaymentReviewDetail(attractInvestSignContractKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ToSignTheBill.GetPaymentReviewDetail',
      attractInvestSignContractKey: attractInvestSignContractKey
    },
    method: 'GET'
  })
}
// 付款申请
export function HQPaymentRequest(printScreen, AttractInvestKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ToSignTheBill.PaymentRequest',
      printScreen: printScreen,
      AttractInvestKey: AttractInvestKey
    },
    method: 'GET'
  })
}
// 付款审核
export function HQPaymentReview(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ToSignTheBill.PaymentReview',
      attractInvestSignContractKey: data.attractInvestSignContractKey,
      incomePayAccountKey: data.incomePayAccountKey,
      incomePayAccountKeyValue: data.incomePayAccountKeyValue,
      incomePaySubjectKey: data.incomePaySubjectKey,
      incomePaySubjectKeyValue: data.incomePaySubjectKeyValue
    },
    method: 'GET'
  })
}
// 总部CRM-》签单待审核
export function HQSearchPageSignedTheBillForPendingReview(params, postData) {
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'HQ.SignTheBillManagement.SearchPageSignedTheBillForPendingReview',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      sourceKey: params.sourceKey,
      counselorKey: params.counselorKey,
      joinTheWay: params.joinTheWay,
      paymentScheduleKey: params.paymentScheduleKey,
      IsExportKey: params.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    data: qs.stringify({ jsonFormate: JSON.stringify(postData) }),
    method: 'POST'
  })
}
// 总部CRM-》签单待付款列表
export function HQSearchPageSignTheBillForPendingPayment(params, postData) {
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'HQ.SignTheBillManagement.SearchPageSignTheBillForPendingPayment',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      sourceKey: params.sourceKey,
      counselorKey: params.counselorKey,
      joinTheWay: params.joinTheWay,
      paymentScheduleKey: params.paymentScheduleKey,
      IsExportKey: params.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    data: qs.stringify({ jsonFormate: JSON.stringify(postData) }),
    method: 'POST'
  })
}
// 总部CRM-》签单已全款列表
export function HQSearchPageTheBillHasBeenSignedInFull(params, postData) {
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.SignTheBillManagement.SearchPageTheBillHasBeenSignedInFull',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      sourceKey: params.sourceKey,
      counselorKey: params.counselorKey,
      joinTheWay: params.joinTheWay,
      paymentScheduleKey: params.paymentScheduleKey,
      IsExportKey: params.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    data: qs.stringify({ jsonFormate: JSON.stringify(postData) }),
    method: 'POST'
  })
}
// 总部CRM-》合同付款列表
export function HQSearchPagePaymentRecord(params, postData) {
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.SignTheBillManagement.SearchPagePaymentRecord',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      sourceKey: params.sourceKey,
      counselorKey: params.counselorKey,
      PaymentTypeKey: params.PaymentTypeKey,
      paymentStartTime: params.StartTime,
      paymentEndTime: params.EndTime,
      IsExportKey: params.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    data: qs.stringify({ jsonFormate: JSON.stringify(postData) }),
    method: 'POST'
  })
}
// 总部CRM-》	合同付款已拒绝
export function HQSearchPageContractPaymentHasBeenRefused(params, postData) {
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'HQ.SignTheBillManagement.SearchPageContractPaymentHasBeenRefused',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      sourceKey: params.sourceKey,
      counselorKey: params.counselorKey,
      PaymentTypeKey: params.PaymentTypeKey,
      paymentStartTime: params.StartTime,
      paymentEndTime: params.EndTime,
      IsExportKey: params.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    data: qs.stringify({ jsonFormate: JSON.stringify(postData) }),
    method: 'POST'
  })
}
// 总部CRM-》	合同付款待审核
export function HQSearchPageContractPaymentPendingReview(params, postData) {
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'HQ.SignTheBillManagement.SearchPageContractPaymentPendingReview',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      sourceKey: params.sourceKey,
      counselorKey: params.counselorKey,
      PaymentTypeKey: params.PaymentTypeKey,
      paymentStartTime: params.StartTime,
      paymentEndTime: params.EndTime,
      IsExportKey: params.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    data: qs.stringify({ jsonFormate: JSON.stringify(postData) }),
    method: 'POST'
  })
}
// 总部CRM-签约回收站 HQ.SignTheBillManagement.
export function HQSearchPageContractRecycleBin(params, postData) {
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.SignTheBillManagement.SearchPageContractRecycleBin',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      sourceKey: params.sourceKey,
      counselorKey: params.counselorKey,
      joinTheWay: params.joinTheWay,
      paymentStartTime: params.StartTime,
      paymentEndTime: params.EndTime,
      IsExportKey: params.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    data: qs.stringify({ jsonFormate: JSON.stringify(postData) }),
    method: 'POST'
  })
}
// 总部CRM-  签单统计
export function HQSignStat() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.SignTheBillManagement.SignStat'
    },
    method: 'GET'
  })
}
// 总部CRM-  合同作废
export function HQCancellation(
  attractInvestSignContractKey,
  CancellationReson
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.SignTheBillManagement.Cancellation',
      attractInvestSignContractKey: attractInvestSignContractKey
    },
    method: 'POST',
    data: qs.stringify({ CancellationReson: JSON.stringify(CancellationReson) })
  })
}
// 投资人详情 HQ.InvestmentManagement.GetInvestorDetail
export function HQGetInvestorDetail(InvestorKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentManagement.GetInvestorDetail',
      InvestorKey: InvestorKey
    },
    method: 'GET'
  })
}

// 云课上架 获取云课上架信息
export function GetYKPutAwayMsg(InvestorKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.YKPutAway.GetYKPutAwayMsg'
    },
    method: 'GET'
  })
}
// 云课上架 获取公司信息
export function GetCompanyMsg() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.YKPutAway.GetCompanyMsg'
    },
    method: 'GET'
  })
}
// 云课上架 修改公司信息
export function AmendCompanyMsg(postData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.YKPutAway.AmendCompanyMsg'
    },
    data: qs.stringify({ Data: JSON.stringify(postData) }),
    method: 'POST'
  })
}
// 云课上架 修改品牌信息
export function AmendBrandMsg(postData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.YKPutAway.AmendBrandMsg'
    },
    data: qs.stringify({ Data: JSON.stringify(postData) }),
    method: 'POST'
  })
}
// 云课上架 修改加盟信息
export function AmendFranchiseMsg(postData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.YKPutAway.AmendFranchiseMsg'
    },
    data: qs.stringify({ Data: JSON.stringify(postData) }),
    method: 'POST'
  })
}
// 云课上架 上架撤回
export function YKPutAwayWithdraw(postData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.YKPutAway.Withdraw'
    },
    method: 'GET'
  })
}
// 云课上架 获取行业
export function GetYKCourse(postData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.YKPutAway.GetYKCourse'
    },
    method: 'GET'
  })
}

// 获取表单模板
export function GetFormTemplate() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.YKPutAway.GetFormTemplate'
    },
    method: 'GET'
  })
}
// 首页-客户追销管理
export function HQGetCRMStat() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.Index.GetCRMStat'
    },
    method: 'GET'
  })
}
// 首页-我的线索
export function HQGetMyCRMStat() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.Index.GetMyCRMStat'
    },
    method: 'GET'
  })
}
// 修改调研信息-
export function AmendFieldValue(OLAPKey, Data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.YKPutAway.AmendFieldValue',
      OLAPKey: OLAPKey // 上架申请key
    },
    method: 'post',
    data: qs.stringify({ Data: JSON.stringify(Data) }) // [{ "FormFieldName":"字段名","FieldValue":"字段值"}]
  })
}

// 首页-招生工作台--个人成果统计
export function PersonalAchievement(StartTime, EndTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.PersonalAchievement',
      StartTime: StartTime, // 开始时间
      EndTime: EndTime // 结束时间
    },
    method: 'GET'
  })
}
// 首页-招生管理--成果汇总
export function AchievementSummarizing(StartTime, EndTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.AchievementSummarizing',
      StartTime: StartTime, // 开始时间
      EndTime: EndTime // 结束时间
    },
    method: 'GET'
  })
}
// 招生工作台--线索记录过期未处理数量统计
export function ThreadAllExpireRecordCount(StartTime, EndTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.ThreadAllExpireRecordCount',
      StartTime: StartTime, // 开始时间
      EndTime: EndTime // 结束时间
    },
    method: 'GET'
  })
}
// 招生管理--线索记录过期未处理数量统计
export function ManageThreadAllExpireRecordCount(StartTime, EndTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.ThreadAllExpireRecordCount',
      StartTime: StartTime, // 开始时间
      EndTime: EndTime // 结束时间
    },
    method: 'GET'
  })
}
// 招生管理--销售成果统计
export function ManageSaleAchievementSummarizing(params, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.SaleAchievementSummarizing',
      StartTime: params.StartTime, // 开始时间
      EndTime: params.EndTime, // 结束时间
      IsExportKey: params.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 招生管理--销售报名收款统计
export function getManageStatistics(params, type) {
  let AIPN
  if (type == 0) {
    // 报名收款
    AIPN = '.SaleSignUpStatistics'
  } else if (type == 1) {
    // 跟单记录
    AIPN = '.SaleFollowUpStatistics'
  } else if (type == 2) {
    // 新增线索
    AIPN = '.SaleNewAddThreadStatistics'
  } else if (type == 3) {
    // 已诺访
    AIPN = '.SalePromiseVisitStatistics'
  } else if (type == 4) {
    // 已约课
    AIPN = '.SaleInvitedClassStatistics'
  } else if (type == 5) {
    // 已试课
    AIPN = '.SaleFinishTrialLessonStatistics'
  } else if (type == 6) {
    // 已报名
    AIPN = '.SaleFinishSignUpStatistics'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage' + AIPN,
      StartTime: params.StartTime, // 开始时间
      EndTime: params.EndTime // 结束时间
    },
    method: 'GET'
  })
}
// 招生工作台--更新诺访记录状态
export function UpdatePromiseVisitStatus(Data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.UpdatePromiseVisitStatus'
    },
    method: 'post',
    data: qs.stringify({ Data: JSON.stringify(Data) }) //
  })
}
// 招生工作台--更新试课记录状态
export function UpdateTrialLessonStatus(Data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.UpdateTrialLessonStatus'
    },
    method: 'post',
    data: qs.stringify({ Data: JSON.stringify(Data) }) //
  })
}
// 招生工作台--添加诺访记录
export function AddPromiseVisit(Data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.AddPromiseVisit'
    },
    method: 'post',
    data: qs.stringify({ Data: JSON.stringify(Data) }) //
  })
}
// 招生工作台--添加试课记录
export function AddTrialLesson(Data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.AddTrialLesson'
    },
    method: 'post',
    data: qs.stringify({ Data: JSON.stringify(Data) }) //
  })
}
// 招生工作台--添加报名记录
export function AddSignUp(Data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.AddSignUp'
    },
    method: 'post',
    data: qs.stringify({ Data: JSON.stringify(Data) }) //
  })
}
// 招生工作台--放弃线索
export function StudentThreadAbandon(StudentThreadKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.StudentThreadAbandon',
      StudentThreadKey: StudentThreadKey
    },
    method: 'GET'
  })
}
// 招生管理--添加回收站
export function SaleRecycle(StudentThreadKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.SaleRecycle',
      StudentThreadKey: StudentThreadKey
    },
    method: 'GET'
  })
}
// 招生工作台--标签列表
export function GetLabelList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.GetLabelList'
    },
    method: 'GET'
  })
}
// 招生工作台--招生线索列表
export function MessageSearchPageStudentThreadList(params) {
  let Data = JSON.stringify(params.LabelKeyList)
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.RecruitStudentWorkbench.MessageSearchPageStudentThreadList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      StartTime: params.StartTime,
      EndTime: params.EndTime,
      LabelKeyList: Data,
      CurrentNodeKey: params.CurrentNodeKey // 1-未联系;2-已联系;3-已诺访;4-已约课;5-已试课;6-已报名
    },
    method: 'GET'
  })
}
// 招生管理--招生线索列表
export function ManageMessageSearchPageStudentThreadList(params, jsonFormate) {
  let Data = JSON.stringify(params.LabelKeyList)
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  console.log(params, 'params1')
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.MessageSearchPageStudentThreadList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      StartTime: params.StartTime,
      EndTime: params.EndTime,
      LabelKeyList: Data,
      SalesKey: params.SalesKey, // 销售ID
      CurrentNodeKey: params.CurrentNodeKey, // 1-未联系;2-已联系;3-已诺访;4-已约课;5-已试课;6-已报名
      IsExportKey: params.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 招生工作台--线索回收站列表
export function MessageSearchPageStudentThreadRecycleList(params, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.RecruitStudentManage.MessageSearchPageStudentThreadRecycleList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText, // 销售ID
      IsExportKey: params.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 招生工作台--招生线索跟进列表
export function MessageSearchPageThreadFollowUpList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.RecruitStudentWorkbench.MessageSearchPageThreadFollowUpList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      StartTime: params.StartTime,
      EndTime: params.EndTime
    },
    method: 'GET'
  })
}
// 招生管理--招生线索跟进列表
export function ManageMessageSearchPageThreadFollowUpList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.MessageSearchPageThreadFollowUpList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      StartTime: params.StartTime,
      EndTime: params.EndTime,
      SalesKey: params.SalesKey // 销售ID
    },
    method: 'GET'
  })
}

// 招生工作台--招生线索诺访记录列表
export function MessageSearchPagePromiseVisitList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.MessageSearchPagePromiseVisitList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      StartTime: params.StartTime,
      EndTime: params.EndTime,
      StatusKey: params.StatusKey, // 诺访状态:1-待处理;2-未到;3-改约;4-已到
      NearlyThreeDays: params.NearlyThreeDays, // 近三天诺访
      IsExpire: params.IsExpire // 是否过期
    },
    method: 'GET'
  })
}
// 招生管理--招生线索诺访记录列表
export function ManageMessageSearchPagePromiseVisitList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.MessageSearchPagePromiseVisitList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      StartTime: params.StartTime,
      EndTime: params.EndTime,
      StatusKey: params.StatusKey, // 诺访状态:1-待处理;2-未到;3-改约;4-已到
      SalesKey: params.SalesKey, // 销售ID
      NearlyThreeDays: params.NearlyThreeDays, // 近三天诺访
      IsExpire: params.IsExpire // 是否过期
    },
    method: 'GET'
  })
}
// 招生工作台--招生线索试课记录列表
export function MessageSearchPageTrialLessonList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.MessageSearchPageTrialLessonList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      StartTime: params.StartTime,
      EndTime: params.EndTime,
      StatusKey: params.StatusKey, // 约试课状态:1-待处理;2-已排课;3-未到;4-改约;5-已试课
      NearlyThreeDays: params.NearlyThreeDays, // 近三天诺访
      IsExpire: params.IsExpire // 是否过期
    },
    method: 'GET'
  })
}
// 招生管理--招生线索试课记录列表
export function ManageMessageSearchPageTrialLessonList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.MessageSearchPageTrialLessonList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      StartTime: params.StartTime,
      EndTime: params.EndTime,
      StatusKey: params.StatusKey, // 约试课状态:1-待处理;2-已排课;3-未到;4-改约;5-已试课
      SalesKey: params.SalesKey, // 销售ID
      NearlyThreeDays: params.NearlyThreeDays, // 近三天诺访
      IsExpire: params.IsExpire // 是否过期
    },
    method: 'GET'
  })
}
// 招生管理--招生线索试课记录列表
export function TrialClassMessageSearchPageTrialLessonList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrialClass.MessageSearchPageTrialLessonList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      StartTime: params.StartTime,
      EndTime: params.EndTime,
      StatusKey: params.StatusKey, // 约试课状态:1-待处理;2-已排课;3-未到;4-改约;5-已试课
      SalesKey: params.SalesKey, // 销售ID
      NearlyThreeDays: params.NearlyThreeDays, // 近三天诺访
      IsExpire: params.IsExpire // 是否过期
    },
    method: 'GET'
  })
}
// 招生工作台--招生线索报名记录列表
export function MessageSearchPageStudentSignUpList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.RecruitStudentWorkbench.MessageSearchPageStudentSignUpList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      StartTime: params.StartTime,
      EndTime: params.EndTime,
      IsRelevancy: params.IsRelevancy // 是否关联 -1-否1-是
    },
    method: 'GET'
  })
}
// 招生管理--招生线索报名记录列表
export function ManageMessageSearchPageStudentSignUpList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentManage.MessageSearchPageStudentSignUpList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      orderBy: params.orderBy,
      sequence: params.sequence,
      searchText: params.searchText,
      StartTime: params.StartTime,
      EndTime: params.EndTime,
      IsRelevancy: params.IsRelevancy, // 是否关联 -1-否1-是
      SalesKey: params.SalesKey // 销售ID
    },
    method: 'GET'
  })
}
// 新增投资人-
export function HQInputInvestor(Data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.InvestmentManagement.InputInvestor'
    },
    method: 'post',
    data: qs.stringify({ Data: JSON.stringify(Data) }) // [{ "FormFieldName":"字段名","FieldValue":"字段值"}]
  })
}
// 云课上架信息
export function GetBrandSGSMsg(SGSKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.YKPutAway.GetBrandSGSMsg',
      SGSKey: SGSKey //	整型	必须		数据源：GET	认证key
    },
    method: 'GET'
  })
}

// 云课获取课程。
export function HQGetLeagueCourseList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.Common.GetLeagueCourseList'
    },
    method: 'GET'
  })
}
// 返回该门店信息用于修改
export function CWSGetSaaSClientMsgForUpdate() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.SaaSClientManagement.GetSaaSClientMsgForUpdate'
    },
    method: 'GET'
  })
}
// 校长待办事项
export function CWSTodoForPrincipal(dataObj) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.Personal.TodoForPrincipal',
      pageSize: dataObj.PageSize,
      pageIndex: dataObj.PageIndex
    },
    method: 'GET'
  })
}
// 校长获取门店统计
export function CWSGetSaaSClientStatistics() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.GetSaaSClientStatistics'
    },
    method: 'GET'
  })
}
// CWS分页查询老师列表
export function CWSSearchPageTeacherList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherManagement.SearchPageTeacherList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      TeacherType: params.TeacherType
    },
    method: 'get'
  })
}

// CWS分页查询老师列表
export function GetTeacherDataList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherManagement.GetTeacherDataList'
    },
    method: 'get'
  })
}
// CWS 老师修改密码
export function CWSTeacherEditPassword(oldPWD, newPWD) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherManagement.TeacherEditPassword',
      oldPWD: oldPWD,
      newPWD: newPWD
    },
    method: 'get'
  })
}

// CWS 启用老师
export function CWSTeacherEnable(TeacherId) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherManagement.TeacherEnable',
      TeacherId: TeacherId
    },
    method: 'get'
  })
}
// CWS 停用老师
export function CWSTeacherLeave(TeacherId) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherManagement.TeacherLeave',
      TeacherId: TeacherId
    },
    method: 'get'
  })
}
// CWS 修改老师
export function CWSUpdateTeacher(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherManagement.UpdateTeacher'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// CWS 新增老师
export function CWSAddTeacher(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherManagement.AddTeacher'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// CWS 老师详情
export function CWSGetTeacherForUpdate(TeacherId) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherManagement.GetTeacherForUpdate',
      TeacherId: TeacherId
    },
    method: 'get'
  })
}
// CWS 获取指定日期范围的课件上课统计
export function CWSGetDateRangeCoursewareStat(params) {
  let AIPN
  if (
    store.getters.SaaSClientEditionKey == 2 ||
    store.getters.SaaSClientEditionKey == 3
  ) {
    AIPN = 'WEBV.Current_CoursewarePackageManage.GetDateRangeCoursewareStat'
  } else {
    AIPN = 'CWS.PrincipalWorkbench.GetDateRangeCoursewareStat'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN,
      StartTime: params.StartTime, //	字符串	必须		数据源：GET	起始时间
      EndTime: params.EndTime, //	字符串	必须		数据源：GET	结束时间
      TeacherKey: params.TeacherKey, //	整型	可选		数据源：GET	老师key
      GradeKey: params.GradeKey, //	整型	可选		数据源：GET	班级key
      BigCoursewarePackageKey: params.BigCoursewarePackageKey, //	整型	可选		数据源：GET	大课包key
      CoursewarePackageKey: params.CoursewarePackageKey,
      Source: params.Source
    },
    method: 'get'
  })
}
// CWS 获取指定日期范围的课件上课统计
export function CWSGetSelfDateRangeCoursewareStat(params) {
  let AIPN
  if (
    store.getters.SaaSClientEditionKey == 2 ||
    store.getters.SaaSClientEditionKey == 3
  ) {
    AIPN = 'WEBV.Current_CoursewarePackageManage.GetDateRangeCoursewareStat'
  } else {
    AIPN = 'CWS.PrincipalWorkbench.getDateRangeSinceCoursewareStat'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN,
      StartTime: params.StartTime, //	字符串	必须		数据源：GET	起始时间
      EndTime: params.EndTime, //	字符串	必须		数据源：GET	结束时间
      TeacherKey: params.TeacherKey, //	整型	可选		数据源：GET	老师key
      GradeKey: params.GradeKey, //	整型	可选		数据源：GET	班级key
      BigCoursewarePackageKey: params.BigCoursewarePackageKey, //	整型	可选		数据源：GET	大课包key
      CoursewarePackageKey: params.CoursewarePackageKey
    },
    method: 'get'
  })
}
// CWS 获取指定日期范围的课件上课统计
export function GetDateCourseCountForTeacher(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.GetDateRangeCoursewareStatForTeacher',
      StartTime: params.StartTime, //	字符串	必须		数据源：GET	起始时间
      EndTime: params.EndTime, //	字符串	必须		数据源：GET	结束时间
      TeacherKey: params.TeacherKey, //	整型	可选		数据源：GET	老师key
      GradeKey: params.GradeKey, //	整型	可选		数据源：GET	班级key
      BigCoursewarePackageKey: params.BigCoursewarePackageKey //	整型	可选		数据源：GET	大课包key
    },
    method: 'get'
  })
}

// 获取授权大课包List
export function CWSGetAuthorizationBigCoursewarePackageList(DeviceCode) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'CWS.PrincipalWorkbench.GetAuthorizationBigCoursewarePackageList',
      DeviceCode: DeviceCode
    },
    method: 'get'
  })
}

export function UpdateCoursewarevisitTime(AuthKey) {
  var serviceName = ''
  //
  if (store.getters.SaaSClientEditionKey == 5) {
    serviceName = 'HQ.CoursewareManage.UpdateCoursewarevisitTime'
  } else if (store.getters.SaaSClientEditionKey == 6) {
    serviceName = 'CWS.CoursewareManage.UpdateCoursewarevisitTime' // 课件门店
  } else {
    serviceName = 'WEBV.Current_CoursewareManage.UpdateCoursewarevisitTime' // 其它，特指教务门店
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: serviceName,
      AuthKey: AuthKey
    },
    method: 'get'
  })
}

export function VerifyISEndVisit(AuthKey) {
  var serviceName = ''
  if (store.getters.SaaSClientEditionKey == 5) {
    serviceName = 'HQ.CoursewareManage.VerifyISEndVisit'
  } else if (store.getters.SaaSClientEditionKey == 6) {
    serviceName = 'CWS.CoursewareManage.VerifyISEndVisit' // 课件门店
  } else {
    serviceName = 'WEBV.Current_CoursewareManage.VerifyISEndVisit' // 其它，特指教务门店
  }

  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: serviceName,
      AuthKey: AuthKey
    },
    method: 'get'
  })
}

// 获取授权大课包List
export function CWSGetSinceBigCoursewarePackageList(DeviceCode) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewarePackageManage.GetSinceBigCoursewarePackageList',
      DeviceCode: DeviceCode
    },
    method: 'get'
  })
}
// 获取授权大课包下的子课包List
export function CWSGetCoursewarePackageList(CoursewarePackageKey, AuthTypeKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.GetCoursewarePackageList',
      CoursewarePackageKey: CoursewarePackageKey, //	整型	必须		数据源：GET	授权课包key
      AuthTypeKey: AuthTypeKey //	整型	必须		数据源：GET	授权类型0-小课包1-大课包
    },
    method: 'get'
  })
}
// 通用获取授权子课包下的课件List
export function CommonGetAuthorizationCoursewareList(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.Common.GetAuthorizationCoursewareList',
      CoursewarePackageKey: CoursewarePackageKey //	整型	必须		数据源：GET	授权课包key
    },
    method: 'get'
  })
}
// 通用获取课件ListFor选择(不过滤未发布课件)
export function CommonGetCoursewareListForSelectNoFiltering(
  CoursewarePackageKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.Common.GetCoursewareListForSelectNoFiltering',
      CoursewarePackageKey: CoursewarePackageKey //	整型	必须		数据源：GET	授权课包key
    },
    method: 'get'
  })
}
// 通用获取授权大课包下的子课包List
export function CommonGetAuthorizationCoursewarePackageList(
  CoursewarePackageKey,
  FailureFilteringKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.Common.GetAuthorizationCoursewarePackageList',
      ParentKey: CoursewarePackageKey, //	整型	必须		数据源：GET	授权课包key
      FailureFilteringKey: FailureFilteringKey // 过滤授权失效课包 0过滤 1不过滤
    },
    method: 'get'
  })
}
// 通用获取课包ListFor选择(不过滤未发布课包)
export function CommonGetCoursewarePackageListForSelectNoFiltering(
  CoursewarePackageKey,
  FailureFilteringKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.Common.GetCoursewarePackageListForSelectNoFiltering',
      ParentKey: CoursewarePackageKey, //	整型	必须		数据源：GET	授权课包key
      FailureFilteringKey: FailureFilteringKey // 过滤授权失效课包 0过滤 1不过滤
    },
    method: 'get'
  })
}
// 通用获取授权大课包List
export function CommonGetAuthorizationBigCoursewarePackageList(
  CoursewarePackageKey,
  AuthTypeKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.Common.GetAuthorizationBigCoursewarePackageList'
    },
    method: 'get'
  })
}

// 获取上课统计list
export function GetGiveLectureStat(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.GiveLectureStatistics.GetGiveLectureStat',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      orderBy: params.orderBy,
      sequence: params.sequence,
      ClassKey: params.ClassKey,
      TeacherKey: params.TeacherKey,
      BigCoursewarePackageKey: params.BigCoursewarePackageKey,
      CoursewarePackageKey: params.CoursewarePackageKey,
      CoursewareKey: params.CoursewareKey,
      StartTime: params.startTime,
      EndTime: params.endTime,
      Catalogue: params.Catalogue,
      Source: params.Source
    },
    method: 'get'
  })
}
// 分页查询课件使用统计List
export function SearchCoursewareUseStatList(params) {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'GiveLectureStatistics.SearchCoursewareUseStatList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      orderBy: params.orderBy,
      sequence: params.sequence,
      ClassKey: params.ClassKey,
      TeacherKey: params.TeacherKey,
      BigCoursewarePackageKey: params.BigCoursewarePackageKey,
      CoursewarePackageKey: params.CoursewarePackageKey,
      CoursewareKey: params.CoursewareKey,
      StartTime: params.startTime,
      EndTime: params.endTime,
      Catalogue: params.Catalogue,
      Source: params.Source,
      AttendClassTypeKey: params.AttendClassTypeKey
    },
    method: 'get'
  })
}
// 获取老师上课统计list
export function getTeacherGiveLectureStat(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.GiveLectureStatisticsForTeacher.getGiveLectureStat',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      orderBy: params.orderBy,
      sequence: params.sequence,
      ClassKey: params.ClassKey,
      TeacherKey: params.TeacherKey,
      BigCoursewarePackageKey: params.BigCoursewarePackageKey,
      CoursewarePackageKey: params.CoursewarePackageKey,
      CoursewareKey: params.CoursewareKey,
      StartTime: params.startTime,
      EndTime: params.endTime,
      Catalogue: params.Catalogue
    },
    method: 'get'
  })
}
// CWS分页查询班级列表
export function CWSSearchPageClassList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.ClassManagement.SearchPageClassList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      ClassType: params.ClassType
    },
    method: 'get'
  })
}

// CWS 启用班级
export function CWSClassEnable(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.ClassManagement.ClassEnable',
      OLAPKey: OLAPKey
    },
    method: 'get'
  })
}
// CWS 停用班级
export function CWSClassLeave(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.ClassManagement.ClassLeave',
      OLAPKey: OLAPKey
    },
    method: 'get'
  })
}
// CWS 修改班级
export function CWSUpdateClass(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.ClassManagement.UpdateClass'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// CWS 新增班级
export function CWSAddClass(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.ClassManagement.AddClass'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// CWS 修改指定老师密码
export function CWSModifyTeacherPassword(TeacherKey, newPWD) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.ModifyTeacherPassword',
      TeacherKey: TeacherKey,
      newPWD: newPWD
    },
    method: 'get'
  })
}

// CWS 添加学生
export function CWSAddStudent(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.StudentManagement.AddStudent'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
//
// CWS分页查询学生列表
export function CWSSearchPageStudentList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.StudentManagement.SearchPageStudentList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      StudentType: params.StudentType
    },
    method: 'get'
  })
}

// CWS 学生在校（离校学生恢复）
export function CWSStudentEnable(StudentId) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.StudentManagement.StudentEnable',
      StudentId: StudentId
    },
    method: 'get'
  })
}
// CWS 学生在校（离校学生）
export function CWSStudentLeave(StudentId) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.StudentManagement.StudentLeave',
      StudentId: StudentId
    },
    method: 'get'
  })
}
// CWS 修改学生
export function CWSUpdateStudent(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.StudentManagement.UpdateStudent'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 修改课件门店信息
export function CWSEditSaaSClient(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.SaaSClientManagement.editSaaSClient'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// CWS查询授权设备列表
export function CWSSearchAuthorizedDeviceList(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.AuthorizedDeviceManagement.SearchAuthorizedDeviceList',
      CoursewarePackageKey: CoursewarePackageKey
    },
    method: 'get'
  })
}
// 待办事项-课件过期-忽略
export function CWSIgnoreEarlyWarning(SaaSClientKey, DataKey, TypeKey, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.Personal.IgnoreEarlyWarning',
      SaaSClientKey: SaaSClientKey, //	整型	可选		数据源：GET	校区key
      DataKey: DataKey, //	整型	必须		数据源：GET	DataKey
      TypeKey: TypeKey //	整型	必须		数据源：GET	预警类型  12-课件续费
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// CWS审核通过设备授权
export function CWSAuditAdopt(ApplyKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.AuthorizedDeviceManagement.AuditAdopt',
      ApplyKey: ApplyKey
    },
    method: 'get',
    checkResubmit: true
  })
}
// CWS拒绝设备授权
export function CWSAuditReject(ApplyKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.AuthorizedDeviceManagement.AuditReject',
      ApplyKey: ApplyKey
    },
    method: 'get',
    checkResubmit: true
  })
}
// CWS取消设备授权
export function CWSCancelAuthorized(ApplyKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.AuthorizedDeviceManagement.CancelAuthorized',
      ApplyKey: ApplyKey
    },
    method: 'get',
    checkResubmit: true
  })
}
// 申请设备授权
export function CWSApplyAuthorizedDevice(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.ApplyAuthorizedDevice'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 获取子课件包详情
export function CWSGetCoursewarePackageDetails(
  CoursewarePackageKey,
  DeviceCode,
  TeacherKey
) {
  let AIPN
  if (
    store.getters.SaaSClientEditionKey == 2 ||
    store.getters.SaaSClientEditionKey == 3
  ) {
    AIPN =
      'WEBV.Current_CoursewarePackageManage.GetCoursewarePackageListForSelect'
  } else {
    AIPN = 'CWS.PrincipalWorkbench.GetCoursewarePackageListForSelect'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN,
      CoursewarePackageKey: CoursewarePackageKey, //	整型	必须		数据源：GET	子课包key
      DeviceCode: DeviceCode, //	字符串	必须		数据源：GET	设备码
      TeacherKey: TeacherKey //	整型	可选		数据源：GET	老师key
    },
    method: 'get'
  })
}

// 获取课件详情-老师列表
export function CWSPrincipalWorkbenchGetCWPTeacherList(CoursewarePackageKey) {
  let AIPN
  if (
    store.getters.SaaSClientEditionKey == 2 ||
    store.getters.SaaSClientEditionKey == 3
  ) {
    AIPN =
      'WEBV.Current_CoursewarePackageManage.getAuthCoursewarePackageTeacherList'
  } else {
    AIPN = 'CWS.PrincipalWorkbench.GetCWPTeacherList'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN,
      CoursewarePackageKey: CoursewarePackageKey //	整型	必须		数据源：GET	子课包key
    },
    method: 'get'
  })
}
// 获取子课包课件list
export function CWSGetTeacherAuthCoursewarePackageList(
  BigCoursewarePackageKey,
  TeacherKey
) {
  let AIPN
  if (
    store.getters.SaaSClientEditionKey == 2 ||
    store.getters.SaaSClientEditionKey == 3
  ) {
    AIPN =
      'WEBV.Current_CoursewarePackageManage.GetTeacherAuthCoursewarePackageList'
  } else {
    AIPN = 'CWS.PrincipalWorkbench.GetTeacherAuthCoursewarePackageList'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN,
      BigCoursewarePackageKey: BigCoursewarePackageKey, //	整型	必须		数据源：GET	大课包key
      TeacherKey: TeacherKey
    },
    method: 'get'
  })
}
// 获取子课包listFor授权老师
export function CWSGetCoursewarePackageForAuthTercher(BigCoursewarePackageKey) {
  let AIPN
  if (
    store.getters.SaaSClientEditionKey == 2 ||
    store.getters.SaaSClientEditionKey == 3
  ) {
    AIPN =
      'WEBV.Current_CoursewarePackageManage.GetAuthCoursewarePackageForAuthTercher'
  } else {
    AIPN = 'CWS.PrincipalWorkbench.GetCoursewarePackageForAuthTercher'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN,
      BigCoursewarePackageKey: BigCoursewarePackageKey //	整型	必须		数据源：GET	子课包key
    },
    method: 'get'
  })
}
// 获取子课包listBy父级For授权老师
export function CWSGetCWPByParentKeyForAuthTercher(ParentKey) {
  let AIPN
  if (
    store.getters.SaaSClientEditionKey == 2 ||
    store.getters.SaaSClientEditionKey == 3
  ) {
    AIPN =
      'WEBV.Current_CoursewarePackageManage.GetCWPByParentKeyForAuthTercher'
  } else {
    AIPN = 'CWS.PrincipalWorkbench.GetCWPByParentKeyForAuthTercher'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN,
      ParentKey: ParentKey //	整型	必须		数据源：GET	子课包key
    },
    method: 'get'
  })
}
// 老师授权获取大课包列表
export function CWSGetBigCoursewarePackageForAuthTercher() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.GetBigCoursewarePackageForAuthTercher'
    },
    method: 'get'
  })
}
// 提交课包老师授权
export function CWSSubmitCWPTeacherAuth(
  TeacherKey,
  BigCoursewarePackageKey,
  CoursewarePackageKeyList
) {
  CoursewarePackageKeyList = {
    CoursewarePackageKeyList: JSON.stringify(CoursewarePackageKeyList)
  }
  let AIPN
  if (
    store.getters.SaaSClientEditionKey == 2 ||
    store.getters.SaaSClientEditionKey == 3
  ) {
    AIPN = 'WEBV.Current_CoursewarePackageManage.SubmitTeacherAuth'
  } else {
    AIPN = 'CWS.PrincipalWorkbench.SubmitCWPTeacherAuth'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN,
      TeacherKey: TeacherKey,
      BigCoursewarePackageKey: BigCoursewarePackageKey //	整型	必须		数据源：GET	子课包key
    },
    method: 'post',
    data: qs.stringify(CoursewarePackageKeyList),
    checkResubmit: true
  })
}
// 课包老师授权列表
export function CWSGetCWPTeacherAuthList(BigCoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.GetCWPTeacherAuthList',
      BigCoursewarePackageKey: BigCoursewarePackageKey
    },
    method: 'get'
  })
}

// 课件详情-获取子课包list
export function CWSPrincipalWorkbenchGetCoursewareList(
  CoursewarePackageKey,
  TeacherKey
) {
  let AIPN
  if (
    store.getters.SaaSClientEditionKey == 2 ||
    store.getters.SaaSClientEditionKey == 3
  ) {
    AIPN = 'WEBV.Current_CoursewarePackageManage.getCoursewareList'
  } else {
    AIPN = 'CWS.PrincipalWorkbench.GetCoursewareList'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN,
      CoursewarePackageKey: CoursewarePackageKey, //	整型	必须		数据源：GET	子课包key
      TeacherKey: TeacherKey
    },
    method: 'get'
  })
}
// 课件详情-开课
export function CWSTeacherGiveLectureSetUpScheduleCourse(
  Data,
  IsContinueKey,
  IsAuthKey
) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherGiveLecture.SetUpScheduleCourse',
      IsContinueKey: IsContinueKey, // 整型	可选	0	数据源：GET	是否继续
      IsAuthKey: IsAuthKey // 整型	可选	0	数据源：GET	是否自动授权 0-否1-是
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// CWS分页查询课件统计
export function CWSSearchPageCoursewareStat(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareStat.SearchPageCoursewareStat',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      sequence: params.sequence,
      orderBy: params.orderBy,
      typeKey: params.typeKey
    },
    method: 'get'
  })
}
// 通用-班级选择
export function CWSCommongetClassList(MainDemoName) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.Common.getClassList',
      MainDemoName: MainDemoName // 模糊匹配
    },
    method: 'get'
  })
}

// 通用-老师选择
export function CWSCommongetTeacherList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.Common.getTeacherList'
    },
    method: 'get'
  })
}

// 课件详情-授权本设备
export function CWSAuthorizedDeviceManagementAuthorizedTheDevice(
  DeviceInfo,
  BigCoursewarePackageKey
) {
  let AIPN
  if (store.getters.SaaSClientEditionKey == 6) {
    AIPN = 'CWS.AuthorizedDeviceManagement.AuthorizedTheDevice'
  } else {
    AIPN = 'WEBV.AuthorizedDeviceManagement.AuthorizedTheDevice'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN,
      BigCoursewarePackageKey: BigCoursewarePackageKey, //	整型	必须		数据源：GET	大课包key
      DeviceName: DeviceInfo.MName, // 必须	设备名称
      DeviceTypeKey: DeviceInfo.MType, //	必须	设备类型1-浏览器;2-桌面端;3-APP;4-电视
      DeviceXCode: DeviceInfo.XCode, //	必须	实际硬件码
      DeviceUCode: DeviceInfo.UCode //	必须	DeviceUCode
    },
    method: 'get'
  })
}
// 总部 App修改App授权
export function AlterIsAPPAuth(AuthKey, StatusKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.AlterIsAPPAuth',
      AuthKey: AuthKey, //	需要授权的ID
      StatusKey: StatusKey //	整型	必须		数据源：GET	大课包key
    },
    method: 'get'
  })
}
// 取消课包老师授权
export function CWSCancelCWPTeacherAuth(TeacherKey, BigCoursewarePackageKey) {
  let AIPN
  if (
    store.getters.SaaSClientEditionKey == 2 ||
    store.getters.SaaSClientEditionKey == 3
  ) {
    AIPN = 'WEBV.Current_CoursewarePackageManage.cancelWEBVTeacherAuth'
  } else {
    AIPN = 'CWS.PrincipalWorkbench.CancelCWPTeacherAuth'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN,
      BigCoursewarePackageKey: BigCoursewarePackageKey, //	整型	必须		数据源：GET	子课包key
      TeacherKey: TeacherKey
    },
    method: 'get'
  })
}
// 课件详情-停止上课
export function CWSTeacherGiveLectureFinishScheduleCourse(Data) {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'TeacherGiveLecture.FinishScheduleCourse',
      Data: Data //	字符串	必须		数据源：GET	课key
    },
    method: 'get'
  })
}
// 老师工作台- 班级详情
export function CWSGetClassGradeDetails(GradeKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.GetClassGradeDetails',
      GradeKey: GradeKey //	整型	必须		数据源：GET	子课包key
    },
    method: 'get'
  })
}
// 老师工作台- 老师退出班级
export function CWSquitClassGradeForTeacher(GradeKey, TeacherKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.quitClassGradeForTeacher',
      GradeKey: GradeKey, //	整型	必须		数据源：GET	子课包key
      TeacherKey: TeacherKey
    },
    method: 'get'
  })
}
// 老师工作台-加学生进班
export function CWSAddClassGradeForStudent(GradeKey, StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.AddClassGradeForStudent',
      GradeKey: GradeKey, //	整型	必须		数据源：GET	子课包key
      StudentKey: StudentKey
    },
    method: 'get'
  })
}
// 老师工作台 -学生退班
export function CWSQuitClassGradeForStudent(GradeKey, StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.QuitClassGradeForStudent',
      GradeKey: GradeKey, //	整型	必须		数据源：GET	子课包key
      StudentKey: StudentKey
    },
    method: 'get'
  })
}
// 老师工作台 -获取班级关联子课包list
export function CWSgetCoursewarePackageForGrade(GradeKey, DeviceCode) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.getCoursewarePackageForGrade',
      GradeKey: GradeKey, //	整型	必须		数据源：GET	子课包key
      DeviceCode: DeviceCode
    },
    method: 'get'
  })
}

// 获取手动释放的大课包List
export function HQGetManualReleaseBigCoursewarePackage() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareReleasePool.GetManualReleaseBigCoursewarePackage'
    },
    method: 'get'
  })
}
// 添加大课包到手动释放（由默认的自动释放转换）
export function HQAddBigCoursewarePackageManualRelease(
  BigCoursewarePackageKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareReleasePool.AddBigCoursewarePackageManualRelease',
      BigCoursewarePackageKey: BigCoursewarePackageKey //	整型	必须		数据源：GET	子课包key
    },
    method: 'get'
  })
}
// 添加子课包到释放池(添加小课包到手动释放)
export function HQAddCoursewarePackageForManualRelease(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareReleasePool.AddCoursewarePackageForManualRelease',
      BigCoursewarePackageKey: params.BigCoursewarePackageKey, //	整型	必须		数据源：GET	 大课包key
      CoursewarePackageKey: params.CoursewarePackageKey, //	整型	必须		数据源：GET	小课包key
      TypeKey: params.TypeKey, //	整型	必须		数据源：GET	释放类型  1-全部校区;2-部分校区
      ClientKeylist: params.ClientKeylist //	字符串	可选		数据源：GET	Json数据，授权校区
    },
    method: 'get'
  })
}
// 修改释放池子课包
export function HQEditMRCoursewarePackage(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareReleasePool.EditMRCoursewarePackage',
      OLAPKey: params.OLAPKey, //	整型	必须		数据源：GET	子课包释放数据Key
      BigCoursewarePackageKey: params.BigCoursewarePackageKey, //	整型	必须		数据源：GET	 大课包key
      CoursewarePackageKey: params.CoursewarePackageKey, //	整型	必须		数据源：GET	小课包key
      TypeKey: params.TypeKey, //	整型	必须		数据源：GET	 释放类型  1-全部校区;2-部分校区
      ClientKeylist: params.ClientKeylist //	字符串	可选		数据源：GET	 Json数据，授权校区
    },
    method: 'get'
  })
}
// 删除释放池子课包
export function HQDeleteMRCoursewarePackage(CoursewarePackageKey, OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareReleasePool.DeleteMRCoursewarePackage',
      CoursewarePackageKey: CoursewarePackageKey, //	整型	必须		数据源：GET	子课包Key
      OLAPKey: OLAPKey //	整型	必须		数据源：GET	 子课包释放数据Key
    },
    method: 'get'
  })
}
// 获取大课包详情
export function HQGetBigCoursewarePackageDetails(ParentKey, sourceKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareReleasePool.GetBigCoursewarePackageDetails',
      ParentKey: ParentKey, //	整型	必须		数据源：GET	 子课包释放数据Key
      sourceKey: sourceKey // 整型	必须		数据源：GET	来源，1-大课包; 2-目录;
    },
    method: 'get'
  })
}
// 获取子课包列表
export function HQGetCoursewarePackageList(ParentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareReleasePool.GetCoursewarePackageList',
      ParentKey: ParentKey //	整型	必须		数据源：GET	 大课包ID
    },
    method: 'get'
  })
}
// 获取大课包授权校区(获取适用校区)
export function HQSearchPageForBigCoursewarePackageAuthorizaSaaSClient(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'HQ.CoursewareReleasePool.SearchPageForBigCoursewarePackageAuthorizaSaaSClient',
      pageIndex: params.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: params.pageSize, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: params.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: params.sequence, //	字符串	可选		数据源：GET	排序顺序
      searchText: params.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号
      BigCoursewarePackageKey: params.BigCoursewarePackageKey //	整型	必须		数据源：GET	 大课包ID
    },
    method: 'get'
  })
}
// 老师工作台 -获取班级关联子课包课件list
export function CWSGetCoursewareForGrade(CoursewarePackageKey, TeacherKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.GetCoursewareList',
      CoursewarePackageKey: CoursewarePackageKey, // 子课包key
      TeacherKey: TeacherKey //	整型	必须		数据源：GET
    },
    method: 'get'
  })
}
// 老师工作台 -取消关联子课件包
export function CWScancelRelevanceCoursewareForGrade(
  GradeKey,
  CoursewarePackageKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.cancelRelevanceCoursewareForGrade',
      GradeKey: GradeKey, // 子课包key
      CoursewarePackageKey: CoursewarePackageKey //	整型	必须		数据源：GET
    },
    method: 'get'
  })
}
//  老师工作台 -班级关联子课件包
export function CWSrelevanceCoursewareForGrade(GradeKey, CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.relevanceCoursewareForGrade',
      GradeKey: GradeKey, // 子课包key
      CoursewarePackageKey: CoursewarePackageKey //	整型	必须		数据源：GET
    },
    method: 'get'
  })
}
// 校长工作台 -获取班级关联子课包课件list
export function PrincipalGetCoursewareForGrade(
  CoursewarePackageKey,
  TeacherKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.GetCoursewareList',
      CoursewarePackageKey: CoursewarePackageKey, // 子课包key
      TeacherKey: TeacherKey //	整型	必须		数据源：GET
    },
    method: 'get'
  })
}
// 校长工作台 -取消关联子课件包
export function PrincipalcancelRelevanceCoursewareForGrade(
  GradeKey,
  CoursewarePackageKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.cancelRelevanceCoursewareForGrade',
      GradeKey: GradeKey, // 子课包key
      CoursewarePackageKey: CoursewarePackageKey //	整型	必须		数据源：GET
    },
    method: 'get'
  })
}
//  校长工作台 -班级关联子课件包
export function PrincipalrelevanceCoursewareForGrade(
  GradeKey,
  CoursewarePackageKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.relevanceCoursewareForGrade',
      GradeKey: GradeKey, // 子课包key
      CoursewarePackageKey: CoursewarePackageKey //	整型	必须		数据源：GET
    },
    method: 'get'
  })
}
// 校长工作台- 班级详情
export function PrincipalGetClassGradeDetails(GradeKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.GetClassGradeDetails',
      GradeKey: GradeKey //	整型	必须		数据源：GET	子课包key
    },
    method: 'get'
  })
}
// 校长工作台-加老师进班
export function PrincipalAddClassGradeForTeacher(GradeKey, TeacherKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.AddClassGradeForTeacher',
      GradeKey: GradeKey, //	整型	必须		数据源：GET	子课包key
      TeacherKey: TeacherKey
    },
    method: 'get'
  })
}
// 校长工作台- 老师退出班级
export function PrincipalquitClassGradeForTeacher(GradeKey, TeacherKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.quitClassGradeForTeacher',
      GradeKey: GradeKey, //	整型	必须		数据源：GET	子课包key
      TeacherKey: TeacherKey
    },
    method: 'get'
  })
}
// 校长工作台-加学生进班
export function PrincipalAddClassGradeForStudent(GradeKey, StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.AddClassGradeForStudent',
      GradeKey: GradeKey, //	整型	必须		数据源：GET	子课包key
      StudentKey: StudentKey
    },
    method: 'get'
  })
}
// 校长工作台 -学生退班
export function PrincipalQuitClassGradeForStudent(GradeKey, StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.QuitClassGradeForStudent',
      GradeKey: GradeKey, //	整型	必须		数据源：GET	子课包key
      StudentKey: StudentKey
    },
    method: 'get'
  })
}
// 校长工作台 -获取班级关联子课包list
export function PrincipalgetCoursewarePackageForGrade(GradeKey, DeviceCode) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.getCoursewarePackageForGrade',
      GradeKey: GradeKey, //	整型	必须		数据源：GET	子课包key
      DeviceCode: DeviceCode
    },
    method: 'get'
  })
}

// 获取子课件包详情
export function CWSTeacherWorkbenchGetCoursewarePackageDetails(
  CoursewarePackageKey,
  DeviceCode,
  TeacherKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.GetCoursewarePackageDetails',
      CoursewarePackageKey: CoursewarePackageKey, //	整型	必须		数据源：GET	子课包key
      DeviceCode: DeviceCode, //	字符串	必须		数据源：GET	设备码
      TeacherKey: TeacherKey //	整型	可选		数据源：GET	老师key
    },
    method: 'get'
  })
}
// CWS 获取日期范围课件上课统计For老师课件
export function CWSTeacherWorkbenchgetDateCourseCountForTeacherCourse(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.GetDateRangeCoursewareStatForTeacher',
      StartTime: params.StartTime, //	字符串	必须		数据源：GET	起始时间
      EndTime: params.EndTime, //	字符串	必须		数据源：GET	结束时间
      TeacherKey: params.TeacherKey, //	整型	可选		数据源：GET	老师key
      GradeKey: params.GradeKey, //	整型	可选		数据源：GET	班级key
      CoursewarePackageKey: params.CoursewarePackageKey //	整型	可选		数据源：GET	子课包key
    },
    method: 'get'
  })
}
// 获取释放池子课包详情
export function HQGetMRCoursewarePackageDetails(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareReleasePool.GetMRCoursewarePackageDetails',
      OLAPKey: OLAPKey //	整型	必须		数据源：GET	 子课包释放数据Key
    },
    method: 'get'
  })
}
// 修改大课包转自动释放（由手动释放转换成自动释放）
export function HQModifyBigCoursewarePackageAutomaticRelease(
  BigCoursewarePackageKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'HQ.CoursewareReleasePool.ModifyBigCoursewarePackageAutomaticRelease',
      BigCoursewarePackageKey: BigCoursewarePackageKey //	整型	必须		数据源：GET	 大课包key
    },
    method: 'get'
  })
}
// 获取大课包列表
export function HQGetBigCoursewarePackageList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareReleasePool.GetBigCoursewarePackageList'
    },
    method: 'get'
  })
}

// 获取端口统计
export function GetPortStatistics() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.systemPortManage.GetPortStatistics'
    },
    method: 'get'
  })
}
// 获取授权详情
export function HQGetAuthDetails(SchoolKey, BigCoursewarePackageKey, AuthKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.GetAuthDetails',
      SchoolKey: SchoolKey, //	整型	必须	0	数据源：GET	校区KEY
      BigCoursewarePackageKey: BigCoursewarePackageKey, //	整型	必须	0	数据源：GET	大课包KEY
      AuthKey: AuthKey
    },
    method: 'get'
  })
}
// 获取授权课包月度账单 AuthKey	整型	必须	0	数据源：GET	授权KEY
export function HQGetAuthCoursewarePackageMonthStat(AuthKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.GetAuthCoursewarePackageMonthStat',
      AuthKey: AuthKey //	整型	必须	0	数据源：GET	授权KEY
    },
    method: 'get'
  })
}
// 分页查询课件备课记录
export function HQSearchPagePrepareLessonsLogList(params, postData) {
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.SearchPagePrepareLessonsLogList',
      pageIndex: params.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: params.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: params.orderBy, //	字符串	可选		数据源：GET	排序字段 为空则默认为课程名
      sequence: params.sequence, //	字符串	可选	asc	数据源：GET	排序方向 ASC正序 DESC 倒序
      searchText: params.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配 课程名
      StartTime: params.startTime, //	字符串	可选		数据源：GET	起始日期
      EndTime: params.endTime, //	字符串	可选	0	数据源：GET	截止日期
      SchoolKey: params.SchoolKey, //	整型	可选	0	数据源：GET	校区
      TeacherKey: params.TeacherKey, // 字符串 老师Key
      IsExportKey: params.IsExportKey,
      BigCoursewarePackageKey: params.BigCoursewarePackageKey, //	整型	可选	0	数据源：GET	大课包
      CoursewarePackageKey: params.CoursewarePackageKey, //	整型	可选	0	数据源：GET	子课包
      CoursewareKey: params.CoursewareKey //	整型	可选	0	数据源：GET	课件key
    },
    data: qs.stringify({ jsonFormate: JSON.stringify(postData) }),
    method: 'POST'
  })
}
// 分页查询课件上课记录
export function HQSearchPageGiveLectureLogList(params, postData) {
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.SearchPageGiveLectureLogList',
      pageIndex: params.pageIndex, //	整型	必须	0	数据源：GET	页码，0为第一页
      pageSize: params.pageSize, //	整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
      orderBy: params.orderBy, //	字符串	可选		数据源：GET	排序字段 为空则默认为课程名
      sequence: params.sequence, //	字符串	可选	asc	数据源：GET	排序方向 ASC正序 DESC 倒序
      searchText: params.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配 课程名
      StartTime: params.startTime, //	字符串	可选		数据源：GET	起始日期
      EndTime: params.endTime, //	字符串	可选	0	数据源：GET	截止日期
      SchoolKey: params.SchoolKey, //	整型	可选	0	数据源：GET	校区
      IsExportKey: params.IsExportKey,
      ClassTypeKey: params.ClassTypeKey,
      BigCoursewarePackageKey: params.BigCoursewarePackageKey, //	整型	可选	0	数据源：GET	大课包
      CoursewarePackageKey: params.CoursewarePackageKey, //	整型	可选	0	数据源：GET	子课包
      CoursewareKey: params.CoursewareKey //	整型	可选	0	数据源：GET	课件key
    },
    data: qs.stringify({ jsonFormate: JSON.stringify(postData) }),
    method: 'POST'
  })
}

// 校长工作台 -获取班级关联子课包课件list
export function PrincipalGetCoursewarelist(CoursewarePackageKey, GradeKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.GetCoursewareForGrade',
      CoursewarePackageKey: CoursewarePackageKey, // 子课包key
      GradeKey: GradeKey //	整型	必须		数据源：GET
    },
    method: 'get'
  })
}
// 老师工作台 -获取班级关联子课包课件list
export function cwsGetCoursewarelist(CoursewarePackageKey, GradeKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.GetCoursewareForGrade',
      CoursewarePackageKey: CoursewarePackageKey, // 子课包key
      GradeKey: GradeKey //	整型	必须		数据源：GET
    },
    method: 'get'
  })
}
// 退出上课
export function cwsExitGiveLecture(ScheduleCourseKey, AuthKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherGiveLecture.ExitGiveLecture',
      ScheduleCourseKey: ScheduleCourseKey, // 课id
      AuthKey: AuthKey //	整型	必须		数据源：GET	上课授权key
    },
    method: 'get'
  })
}
// 退出备课
export function webvExitPrepareLessons(AuthKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePackageManage.ExitPrepareLessons',
      AuthKey: AuthKey // 预览授权key
    },
    method: 'get'
  })
}
// 添加课件合作校区 HQ.CourseCooperationManage.AddCourseCooperationCampus
export function AddCourseCooperationCampus(Data, IsContinueKey) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CourseCooperationManage.AddCourseCooperationCampus',
      IsContinueKey: IsContinueKey // 整型	可选	0	数据源：GET	是否继续
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 续期
export function PortRenew(Data, IsContinueKey) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CourseCooperationManage.PortRenew'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 开通功能获取详情
export function QueryCampuInfo(MobilePhone, CampuId) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CourseCooperationManage.QueryCampuInfo',
      MobilePhone: MobilePhone, // 子课包key
      CampuId: CampuId //	整型	必须		数据源：GET
    },
    method: 'get'
  })
}
// 流量设置详情
export function AdjustmentCurrentFluxObject(CampusKey, CurrentFluxObject) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.systemPortManage.AdjustmentCurrentFluxObject',
      CampusKey: CampusKey, // 校区id
      CurrentFluxObject: CurrentFluxObject //	下个月扣费对象 1-总部;2-门店
    },
    method: 'get'
  })
}
// 课程加盟校区开通功能
export function CourseCooperationOpenFeature(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CourseCooperationManage.OpenFeature'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 开通功能
export function RetailStoresOpenFeature(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.RetailStoresManagement.OpenFeature'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 加盟店管理开通功能
export function FranchiseeOpenFeature(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.FranchiseeManagement.OpenFeature'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 分页查询课程合作List
export function SearchPageCourseCooperationList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CourseCooperationManage.SearchPageCourseCooperationList',
      pageIndex: params.pageIndex, //	整型	可选	0	数据源：GET	页码，0为第一页
      pageSize: params.pageSize, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
      searchText: params.searchText, //	字符串	可选		数据源：GET	搜索条件：校区名，账户ID，校区负责人手机号
      orderBy: params.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: params.sequence, //	字符串	可选		数据源：GET	排序字段
      TypeKey: params.TypeKey //	整型	必须		数据源：GET	查询状态: 1-使用中; 2-续费预警; 3-已停用; 4-端口到期; 5-端口到期预警
    },
    method: 'get'
  })
}
// 获取授权大课包详情
export function CWSGetAuthBigCoursewarePackageDetails(DeviceCode, AuthKey) {
  let paramsData
  if (
    (store.getters.SaaSClientEditionKey == 2 ||
      store.getters.SaaSClientEditionKey == 3) &&
    store.getters.AcademicMdCwType
  ) {
    if (store.getters.AcademicMdCwType == 1) {
      paramsData = {
        service:
          'WEBV.Current_CoursewarePackageManage.getAuthBigCoursewarePackageDetails',
        DeviceCode: DeviceCode, //	整型	可选	0	数据源：GET	设备码
        AuthKey: AuthKey //	整型	可选	0	数据源：GET	授权key
      }
    } else if (store.getters.AcademicMdCwType == 2) {
      paramsData = {
        service:
          'WEBV.Current_CoursewarePackageManage.getSinceBigCoursewarePackageDetails',
        DeviceCode: DeviceCode, //	整型	可选	0	数据源：GET	设备码
        CoursewarePackageKey: AuthKey //	授权大课包key
      }
    }
  } else if (
    store.getters.SaaSClientEditionKey == 6 &&
    store.getters.AcademicMdCwType == 2
  ) {
    console.log(AuthKey, 'AuthKey')
    paramsData = {
      service:
        'CWS.CoursewarePackageManage.GetSinceBigCoursewarePackageDetails',
      DeviceCode: DeviceCode, //	整型	可选	0	数据源：GET	设备码
      CoursewarePackageKey: AuthKey //	整型	可选	0	数据源：GET	授权key
    }
  } else {
    paramsData = {
      service: 'CWS.PrincipalWorkbench.GetAuthBigCoursewarePackageDetails',
      DeviceCode: DeviceCode, //	整型	可选	0	数据源：GET	设备码
      AuthKey: AuthKey //	整型	可选	0	数据源：GET	授权key
    }
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: paramsData,
    method: 'get'
  })
}
// 获取子课包for自研
export function GetSubCoursewarePackageForSelf(ParentKey) {
  var IPAN = ''
  if (store.getters.SaaSClientEditionKey == 6) {
    IPAN = 'CWS.PrincipalWorkbench.GetSubCoursewarePackageForSelf'
  } else {
    IPAN = 'WEBV.Current_CoursewarePackageManage.GetSubCoursewarePackageForSelf'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN,
      ParentKey: ParentKey //	上级key
    },
    method: 'get'
  })
}
// 获取子课包for三方
export function GetSubCoursewarePackageForAuth(ParentKey) {
  var IPAN = ''
  if (store.getters.SaaSClientEditionKey == 6) {
    IPAN = 'CWS.PrincipalWorkbench.GetSubCoursewarePackageForAuth'
  } else {
    IPAN = 'WEBV.Current_CoursewarePackageManage.GetSubCoursewarePackageForAuth'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN,
      ParentKey: ParentKey //	上级key
    },
    method: 'get'
  })
}
// 根据父级获取子课包
export function GetSubCoursewarePackage(ParentKey) {
  var IPAN = ''
  if (store.getters.SaaSClientEditionKey == 6) {
    IPAN = 'CWS.TeacherWorkbench.GetSubCoursewarePackage'
  } else {
    IPAN = 'WEBV.TeacherGiveLecture.GetSubCoursewarePackage'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN,
      ParentKey: ParentKey //	上级key
    },
    method: 'get'
  })
}
// 根据父级获取子课包
export function GetSubCoursewarePackageForTeacher(ParentKey, TeacherKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.PrincipalWorkbench.GetSubCoursewarePackageForTeacher',
      ParentKey: ParentKey, //	上级key
      TeacherKey: TeacherKey // 老师key
    },
    method: 'get'
  })
}
// 获取大课包详情for老师(校长工作台-老师)
export function CWSGetBigCoursewarePackageDetailsForPrincipaTeacher(
  TeacherKey,
  BigCoursewarePackageKey,
  DeviceCode,
  AuthKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'CWS.PrincipalWorkbench.GetBigCoursewarePackageDetailsForTeacher',
      TeacherKey: TeacherKey, //	整型	必须		数据源：GET	老师key
      BigCoursewarePackageKey: BigCoursewarePackageKey, //	整型	必须		数据源：GET	大课包ID
      DeviceCode: DeviceCode, //	字符串	必须		数据源：GET	 设备码
      AuthKey: AuthKey
    },
    method: 'get'
  })
}
// 获取大课包详情for老师(老师工作台)
export function CWSGetBigCoursewarePackageDetailsForTeacher(
  BigCoursewarePackageKey,
  DeviceCode,
  AuthKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherWorkbench.GetBigCoursewarePackageDetailsForTeacher',
      BigCoursewarePackageKey: BigCoursewarePackageKey, //	整型	必须		数据源：GET	大课包ID
      DeviceCode: DeviceCode, //	字符串	必须		数据源：GET	 设备码
      AuthKey: AuthKey
    },
    method: 'get'
  })
}
// 退出备课
export function cwsExitPrepareLessons(AuthKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherPrepareLessons.ExitPrepareLessons',
      AuthKey: AuthKey // 课id
    },
    method: 'get'
  })
}

// 教务-退出上课
export function ExitGiveLecture(ScheduleCourseKey, AuthKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherGiveLecture.ExitGiveLecture',
      ScheduleCourseKey: ScheduleCourseKey, // 课id
      AuthKey: AuthKey // 上课授权key
    },
    method: 'get'
  })
}

// 查询课件包
export function HQSearchCoursewarePackage(ParentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.SearchCoursewarePackage',
      ParentKey: ParentKey
    },
    method: 'GET'
  })
}
// 获取课包LevelPathName
export function GetLevelPathName(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.GetLevelPathName',
      CoursewarePackageKey: CoursewarePackageKey //	整型	必须	0	数据源：GET	课包Key（大课包Key/目录Key/子课包Key）
    },
    method: 'GET'
  })
}
// CWS备课
export function GetCoursewareDetails(CoursewareID, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherPrepareLessons.GetCoursewareDetails',
      CoursewareID: CoursewareID // 课名ID
    },
    data: qs.stringify(Data),
    method: 'post'
  })
}
// 上课获取上课时长
export function GetScheduleDurationCount() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherGiveLecture.GetScheduleDurationCount'
    },
    method: 'GET'
  })
}

// 解绑总部
export function EditUnbindSaaSClient(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.EditUnbindSaaSClient',
      OLAPKey: OLAPKey // 门店key
    },
    method: 'GET'
  })
}
// 关联总部
export function EditlinkSaaSClient(OLAPKey, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.EditlinkSaaSClient',
      OLAPKey: OLAPKey // 门店key
    },
    data: qs.stringify(Data),
    method: 'post'
  })
}
// 转课件门店
export function EditChangeSaaSClient(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.SaaSClientManage.EditChangeSaaSClient',
      OLAPKey: OLAPKey // 门店key
    },
    method: 'GET'
  })
}
// 售后总部详情
export function MessageSearchPageForAfterDetailsSale(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.MessageSearchPageForAfterDetailsSale',
      pageIndex: parameter.pageIndex, //	整型	必须		数据源：GET	页码，0为第一页
      pageSize: parameter.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
      orderBy: parameter.orderBy, //	字符串	可选		数据源：GET	排序字段
      sequence: parameter.sequence, //	字符串	可选		数据源：GET	排序字段
      searchText: parameter.searchText, //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
      FollowStartTime: parameter.FollowStartTime, //	字符串	可选		数据源：GET	跟进日期开始
      FollowEndTime: parameter.FollowEndTime, //	字符串	可选		数据源：GET	跟进日期结束
      IsGradeStarKey: parameter.IsGradeStarKey, //	字符串	可选		数据源：GET	是否为星 空-不生效;0-否;1-是
      IsGradeVKey: parameter.IsGradeVKey, //	字符串	可选		数据源：GET	是否为V 空-不生效;0-否;1-是
      StartCount: parameter.StartCount, //	整型	可选		数据源：GET	介绍起始数
      EndCount: parameter.EndCount, //	整型	可选		数据源：GET	介绍终止数
      StartBargain: parameter.StartBargain, //	整型	可选		数据源：GET	成交起始数
      EndBargain: parameter.EndBargain, //	整型	可选		数据源：GET	成交起始数
      StartStudentCount: parameter.StartStudentCount, //	整型	可选		数据源：GET	学生起始数
      EndStudentCount: parameter.EndStudentCount, //	整型	可选		数据源：GET	学生起始数
      StayBack: parameter.StayBack, //	整型	可选		数据源：GET	待返是否大于0 0-否;1-是
      Renew: parameter.Renew, //	整型	可选		数据源：GET	续费是否小于30天 0-否;1-是
      AttenRate: parameter.AttenRate, //	整型	可选		数据源：GET	考勤率是否小于50% 0-否;1-是
      StartDredgeTime: parameter.StartDredgeTime, // 字符串	可选		数据源：GET	开户时间开始
      EndDredgeTime: parameter.EndDredgeTime, // 字符串	可选		数据源：GET	开户时间结束
      StartExpireTime: parameter.StartExpireTime, // 字符串	可选		数据源：GET	续费日期起
      EndExpireTime: parameter.EndExpireTime, // 字符串 可选		数据源：GET	续费日期止
      DocumentaryKey: parameter.DocumentaryKey, //	字符串	可选		数据源：GET	销售人id
      IsBalance: parameter.IsBalance, // 整型	可选		数据源：GET	是否有尾款 0-否 1-是
      IsAbandon: parameter.IsAbandon, // 整型	可选		数据源：GET	是否放弃 0-否；1-否
      IsExportKey: parameter.IsExportKey, // 整型	可选		数据源：GET	是否导出：0-否;1-是;如果为是pageIndex传0
      OLAPKey: parameter.OLAPKey // 整型	可选		数据源：GET	门店id
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 售后跟单详情统计
export function GetAfterSalesDetailsReport(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.GetAfterSalesDetailsReport',
      OLAPKey: OLAPKey // 门店key
    },
    method: 'GET'
  })
}
// 分页查询端口消耗记录
export function GetPostSaaSClientLog(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.ThreadMarket.GetPostSaaSClientLog',
      pageIndex: parameter.pageIndex,
      pageSize: parameter.pageSize,
      searchText: parameter.searchText,
      orderBy: parameter.orderBy,
      sequence: parameter.sequence,
      portTypeKey: parameter.portTypeKey, // 整型	可选		数据源：GET	端口类型: 1-教务端口; 2-课件端口
      campusTypeKey: parameter.campusTypeKey, // 整型	可选		数据源：GET	校区类型: 1-直营店; 2-加盟店;3-课程合作
      OLAPKey: parameter.OLAPKey // 门店key
    },
    method: 'GET'
  })
}

// 教务门店老师上课获取大课包Listfor老师
export function GetBigCoursewarePackageForTeacher() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherGiveLecture.GetAuthBigCoursewarePackageForTeacher'
    },
    method: 'GET'
  })
}
// 教务门店老师上课获取指定大课包详情
export function GetCoursewarePackageDetailsForTeacher(
  DeviceCode,
  BigCoursewarePackageKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherGiveLecture.GetCoursewarePackageDetailsForTeacher',
      DeviceCode: DeviceCode, //	设备码
      BigCoursewarePackageKey: BigCoursewarePackageKey // 整型	必须	0	数据源：GET	课包Key（大课包Key/目录Key/子课包Key）
    },
    method: 'GET'
  })
}
// 教务门店老师上课获取指定课件详情
export function GetCoursewarePackageListForSelect(
  CoursewarePackageKey,
  DeviceCode,
  TeacherKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherGiveLecture.GetCoursewarePackageListForSelect',
      CoursewarePackageKey: CoursewarePackageKey, //	整型	必须		数据源：GET	子课包key
      DeviceCode: DeviceCode, //	字符串	必须		数据源：GET	设备码
      TeacherKey: TeacherKey //	整型	可选		数据源：GET	老师key
    },
    method: 'GET'
  })
}

// WEBV 获取指定日期范围的课件上课统计
export function WEBVGetDateRangeCoursewareStat(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherGiveLecture.GetDateRangeCoursewareStat',
      StartTime: params.StartTime, //	字符串	必须		数据源：GET	起始时间
      EndTime: params.EndTime, //	字符串	必须		数据源：GET	结束时间
      TeacherKey: params.TeacherKey, //	整型	可选		数据源：GET	老师key
      GradeKey: params.GradeKey, //	整型	可选		数据源：GET	班级key
      BigCoursewarePackageKey: params.BigCoursewarePackageKey, //	整型	可选		数据源：GET	大课包key
      CoursewarePackageKey: params.CoursewarePackageKey
    },
    method: 'get'
  })
}

// WEBV 课件详情-获取子课包list
export function WEBVGetCoursewareListForTeacher(
  CoursewarePackageKey,
  TeacherKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherGiveLecture.GetCoursewareListForTeacher',
      CoursewarePackageKey: CoursewarePackageKey, //	整型	必须		数据源：GET	子课包key
      TeacherKey: TeacherKey
    },
    method: 'get'
  })
}

// 申请设备授权
export function WEBVApplyAuthorizedDevice(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherGiveLecture.ApplyAuthorizedDevice'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 验证是否授权设备
export function WEBVVerifyAuthorizedDevice(DeviceCode, CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AuthorizedDeviceManagement.verifyAuthorizedDevice',
      DeviceCode: DeviceCode, // 课名ID
      CoursewarePackageKey: CoursewarePackageKey
    },
    method: 'GET'
  })
}

// WEBV教务门店老师课件统计 获取大课包
export function getTeacherAuthCoursewarePackageForGiveLecture(ParentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.TeacherGiveLecture.GetTeacherAuthCoursewarePackageForGiveLecture',
      ParentKey: ParentKey
    },
    method: 'GET'
  })
}
// WEBV教务门店老师课件统计 获取课包ListFor选择(不过滤未发布课包)
export function getTeacherCoursewarePackageListForSelectNoFiltering(ParentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Common.GetCoursewarePackageListForSelectNoFiltering',
      ParentKey: ParentKey
    },
    method: 'GET'
  })
}

// WEBV教务门店老师上课统计目录级
export function WEBVGetAuthorizationCoursewareList(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherGiveLecture.GetAuthorizationCoursewareList',
      CoursewarePackageKey: CoursewarePackageKey // 大课件包id
    },
    method: 'GET'
  })
}
// WEBV教务门店老师课件统计 获取大课包
export function getTeacherCoursewarePackageForGiveLecture(ParentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_CoursewarePackageManage.GetCoursewarePackageForGiveLecture',
      ParentKey: ParentKey
    },
    method: 'GET'
  })
}
// WEBV教务门店老师上课统计目录级
export function WEBVGetCoursewareListForSelect(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_CoursewarePackageManage.GetCoursewareListForSelect',
      CoursewarePackageKey: CoursewarePackageKey // 大课件包id
    },
    method: 'GET'
  })
}
// WEBV教务门店老师上课统计list
export function getTeacherGetGiveLectureStat(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.GiveLectureStatisticsForTeacher.GetGiveLectureStat',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      orderBy: params.orderBy,
      sequence: params.sequence,
      ClassKey: params.ClassKey,
      BigCoursewarePackageKey: params.BigCoursewarePackageKey,
      CoursewarePackageKey: params.CoursewarePackageKey,
      CoursewareKey: params.CoursewareKey,
      StartTime: params.startTime,
      EndTime: params.endTime,
      Catalogue: params.Catalogue
    },
    method: 'get'
  })
}

// WEBV CWS 教务门店老师上课统计list
export function getSearchTeacherCoursewareUseStatList(params) {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        IPAN + 'GiveLectureStatisticsForTeacher.SearchCoursewareUseStatList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      orderBy: params.orderBy,
      sequence: params.sequence,
      ClassKey: params.ClassKey,
      BigCoursewarePackageKey: params.BigCoursewarePackageKey,
      CoursewarePackageKey: params.CoursewarePackageKey,
      CoursewareKey: params.CoursewareKey,
      StartTime: params.startTime,
      EndTime: params.endTime,
      Catalogue: params.Catalogue,
      Source: params.Source,
      AttendClassTypeKey: params.AttendClassTypeKey
    },
    method: 'get'
  })
}

// WEBV教务门店老师课件统计 获取大课包
export function getGiveLectureStatisticsTeacherAuthCoursewarePackageForGiveLecture(
  ParentKey,
  FailureFilteringKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.GiveLectureStatistics.GetTeacherAuthCoursewarePackageForGiveLecture',
      ParentKey: ParentKey,
      FailureFilteringKey: FailureFilteringKey
    },
    method: 'GET'
  })
}
// WEBV教务门店老师课件统计 获取课包ListFor选择(不过滤未发布课包)
export function getGiveLectureStatisticsCoursewarePackageListForSelectNoFiltering(
  ParentKey,
  FailureFilteringKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Common.GetCoursewarePackageListForSelectNoFiltering',
      ParentKey: ParentKey,
      FailureFilteringKey: FailureFilteringKey
    },
    method: 'GET'
  })
}

// WEBV教务门店老师上课统计目录级
export function getGiveLectureStatisticsAuthorizationCoursewareList(
  CoursewarePackageKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.GiveLectureStatistics.GetAuthorizationCoursewareList',
      CoursewarePackageKey: CoursewarePackageKey // 大课件包id
    },
    method: 'GET'
  })
}
// WEBV教务门店老师上课统计目录级 获取课件ListFor选择(不过滤未发布课件)
export function getGiveLectureStatisticsCoursewareListForSelectNoFiltering(
  CoursewarePackageKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Common.GetCoursewareListForSelectNoFiltering',
      CoursewarePackageKey: CoursewarePackageKey // 大课件包id
    },
    method: 'GET'
  })
}

// WEBV教务门店老师上课统计list
export function getGiveLectureStatisticsGiveLectureStat(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.GiveLectureStatistics.GetGiveLectureStat',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      orderBy: params.orderBy,
      sequence: params.sequence,
      ClassKey: params.ClassKey,
      BigCoursewarePackageKey: params.BigCoursewarePackageKey,
      CoursewarePackageKey: params.CoursewarePackageKey,
      CoursewareKey: params.CoursewareKey,
      StartTime: params.startTime,
      EndTime: params.endTime,
      Catalogue: params.Catalogue,
      Source: params.Source,
      TeacherKey: params.TeacherKey
    },
    method: 'get'
  })
}

// WEBV总部获取上课统计
export function getGiveLectureRecordGiveLectureStat(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.GiveLectureStatistics.GetGiveLectureStat',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      orderBy: params.orderBy,
      sequence: params.sequence,
      BigCoursewarePackageKey: params.BigCoursewarePackageKey,
      CoursewarePackageKey: params.CoursewarePackageKey,
      CoursewareKey: params.CoursewareKey,
      StartTime: params.startTime,
      EndTime: params.endTime,
      Catalogue: params.Catalogue,
      ClientKey: params.CampusKey,
      ClassTypeKey: params.ClassTypeKey
    },
    method: 'get'
  })
}

// WEBV总部课件统计 获取大课包
export function getCoursewarePackageListForHQCascadeSelector(ParentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'HQ.GiveLectureStatistics.GetCoursewarePackageListForHQCascadeSelector',
      ParentKey: ParentKey
    },
    method: 'GET'
  })
}

// WEBV总部上课统计目录级
export function getCoursewareListForHQCascadeSelector(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.GiveLectureStatistics.GetCoursewareListForHQCascadeSelector',
      CoursewarePackageKey: CoursewarePackageKey // 大课件包id
    },
    method: 'GET'
  })
}
// WEBV获取校区ListFor总部
export function GetCampusListForHQ() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.GiveLectureStatistics.GetCampusListForHQ'
    },
    method: 'GET'
  })
}
// 获取指定时间范围的课件统计For授权
export function GetDateRangeGrantCoursewareStat(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_CoursewarePackageManage.GetDateRangeGrantCoursewareStat',
      StartTime: params.StartTime, //	字符串	必须		数据源：GET	起始时间
      EndTime: params.EndTime, //	字符串	必须		数据源：GET	结束时间
      TeacherKey: params.TeacherKey, //	整型	可选		数据源：GET	 老师key
      GradeKey: params.GradeKey, //	整型	可选		数据源：GET	 班级key
      BigCoursewarePackageKey: params.BigCoursewarePackageKey, //	整型	可选		数据源：GET	 大课包key
      CoursewarePackageKey: params.CoursewarePackageKey //	整型	可选		数据源：GET	子课包key
    },
    method: 'GET'
  })
}
// 获取指定时间范围的课件统计For自研
export function GetDateRangeSinceCoursewareStat(params) {
  let AIPN
  if (
    store.getters.SaaSClientEditionKey == 2 ||
    store.getters.SaaSClientEditionKey == 3
  ) {
    AIPN =
      'WEBV.Current_CoursewarePackageManage.getDateRangeSinceCoursewareStat'
  } else {
    AIPN = 'CWS.CoursewarePackageManage.GetDateRangeSinceCoursewareStat'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN,
      StartTime: params.StartTime, //	字符串	必须		数据源：GET	起始时间
      EndTime: params.EndTime, //	字符串	必须		数据源：GET	结束时间
      TeacherKey: params.TeacherKey, //	整型	可选		数据源：GET	 老师key
      GradeKey: params.GradeKey, //	整型	可选		数据源：GET	 班级key
      BigCoursewarePackageKey: params.BigCoursewarePackageKey, //	整型	可选		数据源：GET	 大课包key
      CoursewarePackageKey: params.CoursewarePackageKey //	整型	可选		数据源：GET	子课包key
    },
    method: 'GET'
  })
}
// 获取授权大课包List
export function GetAuthorizationGrantBigCoursewarePackageList(DeviceCode) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_CoursewarePackageManage.GetAuthorizationGrantBigCoursewarePackageList',
      DeviceCode: DeviceCode
    },
    method: 'GET'
  })
}

// 获取门店动态数据
export function GetSaaSClientDynamicData() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SaaSClient.GetSaaSClientDynamicData'
    },
    method: 'GET'
  })
}

// 获取授权大课包List
export function GetSinceBigCoursewarePackageList(DeviceCode) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Current_CoursewarePackageManage.GetSinceBigCoursewarePackageList',
      DeviceCode: DeviceCode
    },
    method: 'GET'
  })
}
// 公共分片上传验证文件是否存在
export function publicCheckFileExit(md5) {
  if (md5) {
    return fetchToken({
      url: process.env.VUE_APP_WKT_API_Url,
      params: {
        service: 'WEB.Files.CheckFileExit',
        md5: md5 // 课id
      },
      method: 'get'
    })
  } else {
    return new Promise((resolve, reject) => {
      try {
        resolve()
      } catch (ex) {
        reject(ex)
      }
    })
  }
}
// 私用分片上传验证文件是否存在
export function privateCheckFileExit(
  md5,
  CoursewareID,
  AttachTypeKey,
  TypeKey,
  client_id,
  size,
  AuthorizeCode,
  MainDemoName,
  VisitAuthKey
) {
  if (size && size < 1048576) {
    return new Promise((resolve, reject) => {
      try {
        resolve()
      } catch (ex) {
        reject(ex)
      }
    })
  } else {
    return fetchToken({
      url: process.env.VUE_APP_WKT_API_Url,
      params: {
        service: 'WEB.Files.CheckFileExitForCourseware',
        md5: md5, // 课id
        CoursewareID: CoursewareID,
        AttachTypeKey: AttachTypeKey,
        TypeKey: TypeKey, // 附件类型1课件2教学步骤
        client_id: client_id,
        size: size,
        AuthorizeCode: AuthorizeCode,
        MainDemoName: MainDemoName,
        VisitAuthKey: VisitAuthKey
      },
      method: 'get'
    })
  }
}
// 获取加盟店头部统计数据
export function GetFranchiseeStatisticalData() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.FranchiseeManagement.GetFranchiseeStatisticalData'
    },
    method: 'get'
  })
}
// WEBV审核通过设备授权
export function WEBVAuditAdopt(ApplyKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AuthorizedDeviceManagement.AuditAdopt',
      ApplyKey: ApplyKey
    },
    method: 'get',
    checkResubmit: true
  })
}
// WEBV拒绝设备授权
export function WEBVAuditReject(ApplyKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AuthorizedDeviceManagement.AuditReject',
      ApplyKey: ApplyKey
    },
    method: 'get',
    checkResubmit: true
  })
}
// WEBV审核通过欠款销账
export function agreeApply(ApplyKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ArrearsWriteOffManage.agreeApply',
      ApplyKey: ApplyKey
    },
    method: 'get'
  })
}
// WEBV拒绝欠款销账
export function refuseApply(ApplyKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ArrearsWriteOffManage.refuseApply',
      ApplyKey: ApplyKey
    },
    method: 'get'
  })
}
// WEBV审核通过作废单据
export function AgreeRevokeCollectionRecordApply(ApplyKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ArrearsWriteOffManage.AgreeRevokeCollectionRecordApply',
      ApplyKey: ApplyKey
    },
    method: 'get'
  })
}
// WEBV拒绝作废单据
export function RefuseRevokeCollectionRecordApply(ApplyKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ArrearsWriteOffManage.RefuseRevokeCollectionRecordApply',
      ApplyKey: ApplyKey
    },
    method: 'get'
  })
}
// WEBV取消设备授权
export function WEBVCancelAuthorized(ApplyKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AuthorizedDeviceManagement.CancelAuthorized',
      ApplyKey: ApplyKey
    },
    method: 'get',
    checkResubmit: true
  })
}
// CWS查询授权设备列表
export function WEBVSearchAuthorizedDeviceList(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AuthorizedDeviceManagement.SearchAuthorizedDeviceList',
      CoursewarePackageKey: CoursewarePackageKey
    },
    method: 'get'
  })
}
// WEBV分页查询课件统计
export function WEBVSearchPageCoursewareStat(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AuthorizedCoursewareStat.SearchPageCoursewareStat',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      sequence: params.sequence,
      orderBy: params.orderBy,
      typeKey: params.typeKey
    },
    method: 'get'
  })
}
// 课包老师授权列表
export function WEBVGetCWPTeacherAuthList(BigCoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePackageManage.getCWPTeacherAuthList',
      BigCoursewarePackageKey: BigCoursewarePackageKey
    },
    method: 'get'
  })
}
// 老师上课关联课件
export function TeacherGiveLectureRelationCourseware(
  CoursewareKey,
  ScheduleCourseKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherGiveLecture.TeacherGiveLectureRelationCourseware',
      CoursewareKey: CoursewareKey, // 子课件包id
      ScheduleCourseKey: ScheduleCourseKey
    },
    method: 'GET'
  })
}
// 老师上课关联课件
export function getCoursewarePackageIs() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Common.getCoursewarePackageIs'
    },
    method: 'GET'
  })
}

// 获取门店流量包信息 WEBV.FluxPackage.GetFluxPackageForList
export function WEBVGetFluxPackageForList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.FluxPackage.GetFluxPackageForList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      search: params.searchText,
      sequence: params.sequence,
      orderBy: params.orderBy,
      startTime: params.startTime,
      endTime: params.endTime,
      IsFluxPackage: params.IsFluxPackage, // 1 有效流量包 0 无效流量包
      FluxPackageType: params.FluxPackageType // 1通用 2定向
    },
    method: 'get'
  })
}
// 课件门店获取门店流量包信息
export function CWSGetFluxPackageForList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.FluxPackage.GetFluxPackageForList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      search: params.searchText,
      sequence: params.sequence,
      orderBy: params.orderBy,
      startTime: params.startTime,
      endTime: params.endTime,
      IsFluxPackage: params.IsFluxPackage, // 1 有效流量包 0 无效流量包
      FluxPackageType: params.FluxPackageType // 1通用 2定向
    },
    method: 'get'
  })
}
// // 总部获取门店流量包信息
export function HQGetFluxPackageForList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.FluxPackage.GetFluxPackageForList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      search: params.searchText,
      sequence: params.sequence,
      orderBy: params.orderBy,
      startTime: params.startTime,
      endTime: params.endTime,
      IsFluxPackage: params.IsFluxPackage, // 1 有效流量包 0 无效流量包
      FluxPackageType: params.FluxPackageType // 1通用 2定向
    },
    method: 'get'
  })
}
// // 总部流量包明细信息
export function GetFluxPackageRecordForList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.FluxPackage.GetFluxPackageRecordForList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      search: params.searchText, // 搜索校区
      sequence: params.sequence,
      orderBy: params.orderBy,
      startTime: params.startTime,
      endTime: params.endTime,
      FluxPackageKey: params.FluxPackageKey, // 流量包Key
      FlowPackagesKeyValue: params.FlowPackagesKeyValue
    },
    method: 'get'
  })
}
// 获取指定总部流量包明细
export function GetFluxPackageDetails(FluxPackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.FluxPackage.GetFluxPackageDetails',
      FluxPackageKey: FluxPackageKey
    },
    method: 'GET'
  })
}
// // 总部流量账单信息
export function GetSaaSClientFluxPackageBill(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.FluxPackage.GetSaaSClientFluxPackageBill',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      search: params.searchText, // 搜索校区
      sequence: params.sequence,
      orderBy: params.orderBy,
      startTime: params.startTime,
      endTime: params.endTime,
      FluxPackageKey: params.FluxPackageKey // 流量包Key
    },
    method: 'get'
  })
}
// 获取指定总部流量账单
export function GetFluxPackageRangeForCount(startTime, endTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.FluxPackage.GetFluxPackageRangeForCount',
      startTime: startTime,
      endTime: endTime
    },
    method: 'GET'
  })
}
// webv分页查询流量明细
export function WEBVSearchPageFlowDetailedList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BillManage.SearchPageFlowDetailedList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      sequence: params.sequence,
      orderBy: params.orderBy,
      StartTime: params.startTime,
      EndTime: params.endTime,
      FlowPackagesTypeKey: params.FlowPackagesTypeKey, // 流量包类型:1-通用流量;2-定向流量
      FlowPackagesKey: params.FlowPackagesKey, // 流量包Key
      HappenKey: params.HappenKey,
      ExpendClientKey: params.ExpendClientKey, // 消费门店Key
      SaaSClientKey: params.SaaSClientKey // 总部门店Key
    },
    method: 'get'
  })
}
// 课件门店分页查询流量明细
export function CWSSearchPageFlowDetailedList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.BillManage.SearchPageFlowDetailedList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      sequence: params.sequence,
      orderBy: params.orderBy,
      StartTime: params.startTime,
      EndTime: params.endTime,
      FlowPackagesTypeKey: params.FlowPackagesTypeKey, // 流量包类型:1-通用流量;2-定向流量
      FlowPackagesKey: params.FlowPackagesKey, // 流量包Key
      HappenKey: params.HappenKey,
      ExpendClientKey: params.ExpendClientKey, // 消费门店Key
      SaaSClientKey: params.SaaSClientKey // 总部门店Key
    },
    method: 'get'
  })
}
// 总部分页查询流量明细
export function HQSearchPageFlowDetailedList(params, OLAPKey, searchType) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.FluxPackage.SearchPageFlowDetailedList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      sequence: params.sequence,
      orderBy: params.orderBy,
      StartTime: params.startTime,
      EndTime: params.endTime,
      FlowPackagesTypeKey: params.FlowPackagesTypeKey, // 流量包类型:1-通用流量;2-定向流量
      FlowPackagesKey: params.FlowPackagesKey, // 流量包Key
      HappenKey: params.HappenKey, // 发生类型:1-购买充值;2-课件消耗
      ExpendClientKey: params.ExpendClientKey, // 消费门店Key
      SaaSClientKey: params.SaaSClientKey, // 总部门店Key
      searchType: searchType
    },
    method: 'get'
  })
}
// 获取本门店流量包
export function WEBVGetFluxPackageForSaaSClient() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BillManage.GetFluxPackageForSaaSClient'
    },
    method: 'GET'
  })
}
// 总部获取本门店流量包
export function HQGetFluxPackageForSaaSClient(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.FluxPackage.GetFluxPackageForSaaSClient',
      OLAPKey: OLAPKey
    },
    method: 'GET'
  })
}
// 获取本门店流量包
export function CWSGetFluxPackageForSaaSClient() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.BillManage.GetFluxPackageForSaaSClient'
    },
    method: 'GET'
  })
}
// webv查询余额明细
export function WEBVSearchPageBalanceDetailsList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BillManage.SearchPageBalanceDetailsList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      search: params.searchText,
      sequence: params.sequence,
      orderBy: params.orderBy,
      StartTime: params.startTime,
      EndTime: params.endTime,
      TypeKey: params.TypeKey // 发生类型:0-所有;1-现金充值;2-购买流量;3-功能续费;4-余额提现
    },
    method: 'get'
  })
}
// cws查询余额明细
export function CWSSearchPageBalanceDetailsList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.BillManage.SearchPageBalanceDetailsList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      search: params.searchText,
      sequence: params.sequence,
      orderBy: params.orderBy,
      StartTime: params.startTime,
      EndTime: params.endTime,
      TypeKey: params.TypeKey // 发生类型:0-所有;1-现金充值;2-购买流量;3-功能续费;4-余额提现
    },
    method: 'get'
  })
}

// WEBV 月度汇总流量账单
export function WEBVSummaryForMonth(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BillManage.SummaryForMonth',
      StartTime: searchData.StartTime,
      EndTime: searchData.EndTime
    },
    method: 'GET'
  })
}
// CWS 月度汇总流量账单
export function CWSSummaryForMonth(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.BillManage.SummaryForMonth',
      StartTime: searchData.StartTime,
      EndTime: searchData.EndTime
    },
    method: 'GET'
  })
}

// WebV 余额充值
export function WebvcurrentAmountRecharge(PaymentMethod, RechargeAmount) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BillManage.CurrentAmountRecharge',
      // eslint-disable-next-line camelcase
      client_id: store.getters.clientId,
      PaymentMethod: PaymentMethod, // 支付方式 1.微信
      RechargeAmount: RechargeAmount // 充值金额
    },
    method: 'GET'
  })
}

// CWS 余额充值
export function CwsvcurrentAmountRecharge(PaymentMethod, RechargeAmount) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.BillManage.CurrentAmountRecharge',
      // eslint-disable-next-line camelcase
      client_id: store.getters.clientId,
      PaymentMethod: PaymentMethod, // 支付方式 1.微信
      RechargeAmount: RechargeAmount // 充值金额
    },
    method: 'GET'
  })
}

// 总部获取本门店流量包
export function CWSgetFluxPackagelist() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.FluxPackage.getFluxPackagelist'
    },
    method: 'GET'
  })
}

// 总部获取本门店流量包
export function WEBVgetFluxPackagelist() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.FluxPackage.getFluxPackagelist'
    },
    method: 'GET'
  })
}

// 总部获取本门店流量包
export function HQgetFluxPackagelist() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.FluxPackage.getFluxPackagelist'
    },
    method: 'GET'
  })
}

// 总部购买流量包
export function HQGetFluxPackagePayCode(parmgs) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.FluxPackage.getFluxPackagePayCode',
      ClientId: store.getters.clientId,
      FluxComboKey: parmgs.FluxComboKey
    },
    method: 'GET'
  })
}

// CWS购买流量包
export function CWSGetFluxPackagePayCode(parmgs) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.FluxPackage.getFluxPackagePayCode',
      ClientId: store.getters.clientId,
      FluxComboKey: parmgs.FluxComboKey,
      PayWx: parmgs.PayWx,
      IsPayBalance: parmgs.IsPayBalance
    },
    method: 'GET'
  })
}
// WEBV购买流量包
export function WEBVGetFluxPackagePayCode(parmgs) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.FluxPackage.getFluxPackagePayCode',
      ClientId: store.getters.clientId,
      FluxComboKey: parmgs.FluxComboKey,
      PayWx: parmgs.PayWx,
      IsPayBalance: parmgs.IsPayBalance
    },
    method: 'GET'
  })
}
// WEBV购买流量包密码验证
export function WEBVcheckSaaSClientPwd(Password) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.FluxPackage.checkSaaSClientPwd',
      Password: Password
    },
    method: 'GET'
  })
}
// CWS购买流量包密码验证
export function CWScheckSaaSClientPwd(Password) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.FluxPackage.checkSaaSClientPwd',
      Password: Password
    },
    method: 'GET'
  })
}

// CWS余额购买流量包
export function CWSGetFluxPackageBalancePay(FluxComboKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.FluxPackage.GetFluxPackageBalancePay',
      FluxComboKey: FluxComboKey
    },
    method: 'GET'
  })
}

// WEBV余额购买流量包
export function WEBVGetFluxPackageBalancePay(FluxComboKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.FluxPackage.GetFluxPackageBalancePay',
      FluxComboKey: FluxComboKey
    },
    method: 'GET'
  })
}

// WEBV 课件统计
export function WebVgetCoursewareStat() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_ParentCourseWarePackage.GetCoursewareStat'
    },
    method: 'GET'
  })
}

// CWS 课件统计
export function cwsGetCoursewareStat() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.ParentCourseWarePackage.GetCoursewareStat'
    },
    method: 'GET'
  })
}

// HQ 课件统计
export function HQgetCoursewareStat() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ParentCourseWarePackage.GetCoursewareStat'
    },
    method: 'GET'
  })
}

// WEBV 余额提现
export function WEBVwithdrawalOfBalance(
  WithdrawalAmount,
  WithdrawalContent,
  WithdrawalWayKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.BillManage.WithdrawalOfBalance',
      WithdrawalAmount: WithdrawalAmount, // 提现金额
      WithdrawalContent: WithdrawalContent, // 提现原因
      WithdrawalWayKey: WithdrawalWayKey // 提现方式 1-微信
    },
    method: 'GET'
  })
}

// WEBV 余额提现
export function CWSVwithdrawalOfBalance(
  WithdrawalAmount,
  WithdrawalContent,
  WithdrawalWayKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.BillManage.WithdrawalOfBalance',
      WithdrawalAmount: WithdrawalAmount, // 提现金额
      WithdrawalContent: WithdrawalContent, // 提现原因
      WithdrawalWayKey: WithdrawalWayKey // 提现方式 1-微信
    },
    method: 'GET'
  })
}
//

// WEBV 月度课件使用统计
export function WEBVGetCoursewareUseBillSummaryForMonth(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CoursewareUseBill.GetCoursewareUseBillSummaryForMonth',
      StartTime: searchData.startTime,
      EndTime: searchData.endTime
    },
    method: 'GET'
  })
}
// WEBV 月度课件使用列表
export function WEBVSearchPageCoursewareUseBillList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CoursewareUseBill.SearchPageCoursewareUseBillList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      sequence: params.sequence,
      orderBy: params.orderBy,
      StartTime: params.startTime,
      EndTime: params.endTime
    },
    method: 'get'
  })
}
// CWS 月度课件使用统计
export function CWSGetCoursewareUseBillSummaryForMonth(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareUseBill.GetCoursewareUseBillSummaryForMonth',
      StartTime: searchData.startTime,
      EndTime: searchData.endTime
    },
    method: 'GET'
  })
}
// CWS 月度课件使用列表
export function CWSSearchPageCoursewareUseBillList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareUseBill.SearchPageCoursewareUseBillList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      sequence: params.sequence,
      orderBy: params.orderBy,
      StartTime: params.startTime,
      EndTime: params.endTime
    },
    method: 'get'
  })
}
// 超级管理总店流量账单列表 Admin.FluxPackage.GetSaaSClientFluxPackageBill
export function AdminGetSaaSClientFluxPackageBill(params, OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.FluxPackage.GetSaaSClientFluxPackageBill',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      search: params.searchText, // 搜索校区
      sequence: params.sequence,
      orderBy: params.orderBy,
      startTime: params.startTime,
      endTime: params.endTime,
      OLAPKey: OLAPKey // 流量包Key
    },
    method: 'get'
  })
}
// 获取指定超级管理总店流量账单
export function AdminGetFluxPackageRangeForCount(startTime, endTime, OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.FluxPackage.GetFluxPackageRangeForCount',
      startTime: startTime,
      endTime: endTime,
      OLAPKey: OLAPKey
    },
    method: 'GET'
  })
}
// 总部获取本月课件使用账单
export function SearchPageCoursewareUseBillList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareUseBill.SearchPageCoursewareUseBillList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      orderBy: params.orderBy,
      sequence: params.sequence,
      StartTime: params.startTime,
      EndTime: params.endTime,
      CampusKey: params.CampusKey,
      CampusTypeKey: params.CampusTypeKey
    },
    method: 'get'
  })
}
// 总部获取本月课件使用账单
export function SearchPageCoursewareUseBillListForCampus(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareUseBill.SearchPageCoursewareUseBillListForCampus',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      orderBy: params.orderBy,
      sequence: params.sequence,
      StartTime: params.startTime,
      EndTime: params.endTime,
      CampusKey: params.CampusKey
    },
    method: 'get'
  })
}
export function CoursewareUseBillSummaryForMonth(StartTime, EndTime) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareUseBill.CoursewareUseBillSummaryForMonth',
      StartTime: StartTime,
      EndTime: EndTime
    },
    method: 'GET'
  })
}
// 超级管理 月度汇总流量账单
export function AdminSummaryForMonth(searchData, OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.FluxPackage.SummaryForMonth',
      startTime: searchData.StartTime,
      endTime: searchData.EndTime,
      OLAPKey: OLAPKey
    },
    method: 'GET'
  })
}
// 超级管理查询余额明细
export function AdminSearchPageBalanceDetailsList(params, OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.BillManage.SearchPageBalanceDetailsList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      search: params.searchText,
      sequence: params.sequence,
      orderBy: params.orderBy,
      StartTime: params.startTime,
      EndTime: params.endTime,
      TypeKey: params.TypeKey, // 发生类型:0-所有;1-现金充值;2-购买流量;3-功能续费;4-余额提现
      OLAPKey: OLAPKey
    },
    method: 'get'
  })
}
// 超级管理分页查询流量明细
export function AdminSearchPageFlowDetailedList(params, ExpendClientKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.FluxPackage.SearchPageFlowDetailedList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      sequence: params.sequence,
      orderBy: params.orderBy,
      StartTime: params.startTime,
      EndTime: params.endTime,
      FlowPackagesTypeKey: params.FlowPackagesTypeKey, // 流量包类型:1-通用流量;2-定向流量
      FlowPackagesKey: params.FlowPackagesKey, // 流量包Key
      HappenKey: params.HappenKey,
      ExpendClientKey: ExpendClientKey, // 消费门店Key
      SaaSClientKey: params.SaaSClientKey // 总部门店Key
    },
    method: 'get'
  })
}
// 超级管理获取本门店流量包
export function AdminGetFluxPackageForSaaSClient(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.FluxPackage.GetFluxPackageForSaaSClient',
      OLAPKey: OLAPKey
    },
    method: 'GET'
  })
}
// 总部课件使用账单月度汇总For校区
export function HQCoursewareUseBillSummaryForCampus(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareUseBill.CoursewareUseBillSummaryForCampus',
      StartTime: searchData.startTime,
      EndTime: searchData.endTime,
      CampusKey: searchData.CampusKey
    },
    method: 'GET'
  })
}
// HQ.CampusManagement.SearchPageSaaSClientList
export function HQSearchPageSaaSClientList(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusManagement.SearchPageSaaSClientList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      sequence: params.sequence,
      orderBy: params.orderBy,
      HQClientKey: params.HQClientKey
    },
    method: 'get'
  })
}

// 结束访问课件记录，AuthKey为添加访问课件授权Key
export function UpgradeDeviceCode(DeviceInfo) {
  var serviceName = ''
  //
  if (store.getters.SaaSClientInfo.TypeKey == 6) {
    // 连锁总部
    serviceName = 'HQ.AuthorizedDeviceManage.UpgradeDeviceCode'
  } else if (store.getters.SaaSClientEditionKey == 6) {
    serviceName = 'CWS.AuthorizedDeviceManagement.UpgradeDeviceCode' // 课件门店
  } else {
    serviceName = 'WEBV.AuthorizedDeviceManagement.UpgradeDeviceCode' // 其它，特指教务门店
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: serviceName,
      DeviceName: DeviceInfo.MName,
      DeviceTypeKey: DeviceInfo.MType,
      DeviceXCode: DeviceInfo.XCode
    },
    method: 'get'
  })
}

// 调整学分
export function UpdateCredit(StudentKey, CreditNum, Remake) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CreditManagement.UpdateCredit',
      StudentKey: StudentKey, //	整型	必须		数据源：GET	学生ID
      CreditNum: CreditNum, //	整型	必须		数据源：GET	调整数量(最终数量)
      Remake: Remake // 字符串	必须			数据源：GET	 原因说明
    },
    method: 'GET'
  })
}
// 欠款销账
export function ApplyWriteOff(StudentKey, Amount, Reason) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ArrearsWriteOffManage.ApplyWriteOff',
      StudentKey: StudentKey, //	整型	必须		数据源：GET	学生ID
      Amount: Amount, //	整型	必须		数据源：GET
      Reason: Reason // 字符串	必须			数据源：GET	 原因说明
    },
    method: 'GET'
  })
}

// 总部批量取消授权
export function BatchDisable(Data) {
  Data = {
    AuthorizationCoursewareKeyList: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.BatchDisable'
    },
    method: 'post',
    data: qs.stringify(Data), // json(OLAPKey -- id MainDemoName -- 收款账户名称 AccountTypeKey--账户类型key AccountTypeKeyValue--账户类型value CommentSupport-- 备注)
    checkResubmit: true
  })
}
// 审批记录
export function SearchPageWriteOffApplyList(params, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ArrearsWriteOffManage.SearchPageWriteOffApplyList',
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      searchText: params.searchText,
      sequence: params.sequence,
      orderBy: params.orderBy,
      startTime: params.startTime,
      endTime: params.endTime,
      matterTypeKey: params.TypeKey,
      IsExportKey: params.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 作废单据
export function ApplyRevokeCollectionRecord(KDSYKey, Reason) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ArrearsWriteOffManage.ApplyRevokeCollectionRecord',
      KDSYKey: KDSYKey, //	整型	必须		数据源：GET	报名记录key
      Reason: Reason // 字符串	必须			数据源：GET	 原因说明
    },
    method: 'GET'
  })
}
// 通知校区list
export function HQGetInfomCampusList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareMessageNotice.GetInfomCampusList'
    },
    method: 'GET'
  })
}
// 未通知校区list
export function HQGetNotInfomCampusList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareMessageNotice.GetNotInfomCampusList'
    },
    method: 'GET'
  })
}
// 移除通知校区
export function HQRemoveInformCampus(CampusKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareMessageNotice.RemoveInformCampus',
      CampusKey: CampusKey
    },
    method: 'GET'
  })
}
// 设置通知校区
export function HQSetUpInformCampus(CampusKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareMessageNotice.SetUpInformCampus',
      CampusKey: CampusKey
    },
    method: 'GET'
  })
}
// 设置通知文字
export function HQSetUpInformWords(Words) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareMessageNotice.SetUpInformWords',
      Words: Words
    },
    method: 'GET'
  })
}
// 约课建课-获取指定课程，班级，上下课时间的课 WEBV.ScheduleCourse.GetCourseListForAddCourse
export function AppointGetCourseListForAddCourse(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SubscribeCourse.GetCourseListForAddCourse',
      CourseNameKey: data.CourseKey,
      GradeClassKey: data.GradeKey,
      ClassTimeName: data.ClassTimeName,
      StartTime: data.StartTime,
      EndTime: data.EndTime
    },
    method: 'GET'
  })
}
// 申请约课 检测是否有课程
export function AddSubscribeCourse(params) {
  let Data = {
    Data: JSON.stringify(params)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SubscribeCourse.AddSubscribeCourse'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 获取约课 课表详情
export function GetSubscribeCourseDetails(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SubscribeCourse.GetSubscribeCourseDetails',
      OLAPKey: OLAPKey // 课名ID
    },
    method: 'GET'
  })
}
// 获取约课设置信息
export function GetSaaSClientSettingSubscribeCourse(SaaSClientKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SubscribeCourse.GetSaaSClientSettingSubscribeCourse',
      SaaSClientKey: SaaSClientKey // 门店key
    },
    method: 'GET'
  })
}

// 修改约课设置信息
export function UpdataSaaSClientSettingSubscribeCourse(OLAPKey, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SubscribeCourse.UpdataSaaSClientSettingSubscribeCourse',
      OLAPKey: OLAPKey // 门店key
    },
    data: qs.stringify(Data),
    method: 'post'
  })
}
// 取消约课
export function DeleteSubscribeCourse(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SubscribeCourse.DeleteSubscribeCourse',
      OLAPKey: OLAPKey // 约课ID
    },
    method: 'GET'
  })
}
// 关联销售
export function RelevantSales(TableID, SalesID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KDSY.RelevantSales',
      TableID, // 开单收银id
      SalesID // 销售Id
    },
    method: 'GET'
  })
}

// 关联销售
export function GetXYKDMsgByTicketID(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KDSY.GetXYKDMsgByTicketID',
      TableID // 开单收银id
    },
    method: 'GET'
  })
}

// 关联报班
export function RelevantGrade(TableID, GradeKey, GradeKeyValue) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KDSY.RelevantGrade',
      TableID, // 开单收银id
      GradeKey, // 班级Key
      GradeKeyValue // 班级KeyValue 不关联时key为0 value为无
    },
    method: 'GET'
  })
}

// 取消学生约课
export function DeleteSubscribeCourseStudent(
  OLAPKey,
  SubscribeCourseKey,
  Reason
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SubscribeCourse.DeleteSubscribeCourseStudent',
      OLAPKey: OLAPKey, // 学生ID
      SubscribeCourseKey: SubscribeCourseKey,
      Reason: Reason
    },
    method: 'GET'
  })
}
// 约课大课表 按日期查询
export function GetSubscribeCourseListByDate(startTime, endTime, searchObj) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SubscribeCourse.GetSubscribeCourseListByDate',
      startTime: startTime,
      endTime: endTime,
      MTeacherKey: searchObj.MTeacherKey,
      GradeClassKey: searchObj.GradeClassKey,
      CourseNameKey: searchObj.CourseNameKey,
      ClassroomKey: searchObj.ClassroomKey,
      StudentKey: searchObj.StudentKey,
      SubscribeCourseType: searchObj.SubscribeCourseType
    },
    method: 'GET'
  })
}
// 约课加学生
export function addSubscribeCourseStudent(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SubscribeCourse.addSubscribeCourseStudent'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 最新的获取约课详细信息   WEBV.ScheduleCourse.ScheduleCourseDetailed
export function getAppointCourseDetailed(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SubscribeCourse.GetSubscribeCourseDetails',
      OLAPKey: OLAPKey // 课名ID
    },
    method: 'GET'
  })
}
// 最新 约课更新.  课修改//WEBV.ScheduleCourse.UpdateScheduleCourse
export function UpdateAppointCourse(OLAPKey, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SubscribeCourse.UpdataSubscribeCourse',
      OLAPKey: OLAPKey
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 开通门店选择服务器
export function GetDatabaseServeSaaSClientList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'Admin.DatabaseServe.GetDatabaseServeSaaSClientList'
    },
    method: 'GET'
  })
}
// 老师业绩-学生统计 2022后
export function SearchPageStudentStat(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherPerformanceReport.SearchPageStudentStat',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey,
      IsMTeaKey: parameter.IsMTeaKey,
      TeacherKey: parameter.TeacherKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

// 老师业绩-学生统计 2022前
export function SearchPageHistoryStudentStat(parameter, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherPerformanceReport.SearchPageHistoryStudentStat',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      startTime: parameter.startTime, // 搜索条件
      endTime: parameter.endTime, // 申请日期结束
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      IsExportKey: parameter.IsExportKey,
      IsMTeaKey: parameter.IsMTeaKey,
      TeacherKey: parameter.TeacherKey
    },
    // method: 'GET'
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 添加推荐作品
export function AddRecommendWorks(data) {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'StudentWorkManage.AddRecommendWorks',
      Works: data.UploadWorksUrl, // 作品
      StudentName: data.StudentName,
      GuidanceTeacherName: data.GuidanceTeacherName,
      CreationTime: data.CreationTime,
      RecommendObject: data.RecommendObject // 推荐对象
    },
    method: 'GET'
  })
}
// 获取推荐作品列表（本校作品）
export function GetRecommendWorksListForPC() {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'StudentWorkManage.GetRecommendWorksListForPC'
    },
    method: 'GET'
  })
}
// 获取精选作品列表（总部推荐至本校作品）
export function GetSelectedWorksListPC() {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'StudentWorkManage.GetSelectedWorksListPC'
    },
    method: 'GET'
  })
}
// 删除作品CWSDeleteWorks
export function DeleteWorks(OLAPKey) {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'StudentWorkManage.DeleteWorks',
      WorksID: OLAPKey // 作品ID
    },
    method: 'GET'
  })
}
// 补课处理-不处理
export function DealMakeUpForDoNot(StudentKey, ScheduleCourseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.MakeupManage.DealMakeUpForDoNot',
      StudentKey: StudentKey, // 学生key
      ScheduleCourseKey: ScheduleCourseKey // 课id
    },
    method: 'GET'
  })
}
// 补课处理-补扣课时
export function DealMakeUpForDeductClassHour(StudentKey, ScheduleCourseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.MakeupManage.DealMakeUpForDeductClassHour',
      StudentKey: StudentKey, // 学生key
      ScheduleCourseKey: ScheduleCourseKey // 课id
    },
    method: 'GET'
  })
}
// 补课处理-退还课时
export function DealMakeUpForRefundClassHour(StudentKey, ScheduleCourseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.MakeupManage.DealMakeUpForRefundClassHour',
      StudentKey: StudentKey, // 学生key
      ScheduleCourseKey: ScheduleCourseKey // 课id
    },
    method: 'GET'
  })
}
// 补课处理-获取补课设置信息
export function GetMakeUpSetting() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.MakeupManage.GetMakeUpSetting'
    },
    method: 'GET'
  })
}
// 补课管理--设置课程缺勤未处理忽略次数
export function UpdateMakeupSet(SetContent, ConciseModeStatuskey, DATA) {
  var DATA = {
    DATA: JSON.stringify(DATA)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.MakeupManage.UpdateMakeupSet',
      SetContent: SetContent, // 设置可追溯时长 1 2 3 个月
      ConciseModeStatuskey: ConciseModeStatuskey // 是否开启补课简洁模式 0-否1-是
    },
    method: 'post',
    data: qs.stringify(DATA)
  })
}
// 获取推荐门店列表
export function GetRecommendClientList() {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'StudentWorkManage.GetRecommendClientList'
    },
    method: 'GET'
  })
}
// 返回最新推荐学生作品
export function HQGetNewestStudentPhotoPageList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.StudentPhoto.GetNewestStudentPhotoPageList'
    },
    method: 'GET'
  })
}
// 返回回收学生作品
export function HQGetCollectStudentPhotoPageList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.StudentPhoto.GetCollectStudentPhotoPageList'
    },
    method: 'GET'
  })
}
// 推荐学生作品
export function HQPushStudentPhoto(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.StudentPhoto.PushStudentPhoto',
      OLAPKey: OLAPKey // 作品ID
    },
    method: 'GET'
  })
}
// 回收学生作品
export function HQCollectStudentPhoto(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.StudentPhoto.CollectStudentPhoto',
      OLAPKey: OLAPKey // 作品ID
    },
    method: 'GET'
  })
}
// 返回校区精选的数量和总数量
export function HQGetSaaSClientStudentPhotoCount() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.StudentPhoto.GetSaaSClientStudentPhotoCount'
    },
    method: 'GET'
  })
}
// 返回精选推荐学生作品
export function HQGetPushStudentPhotoPageList(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.StudentPhoto.GetPushStudentPhotoPageList',
      ClientKey: OLAPKey // 推荐校区Key
    },
    method: 'GET'
  })
}
// 一建回收
export function HQBatchCollectStudentPhoto() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.StudentPhoto.BatchCollectStudentPhoto'
    },
    method: 'GET'
  })
}

// 一建回收
export function HQGetTecherListForHQ(ClientKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.getTecherListForHQ',
      ClientKey // 门店ID
    },
    method: 'GET'
  })
}

// 获取指定PPT的路径
export function CheckAuthorizedCode(CheckAuthorizedCode, ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.CheckAuthorizedCode',
      AuthorizedCode: CheckAuthorizedCode, // 授权码
      ID: ID // 附件ID
    },
    method: 'GET'
  })
}
// WEBV ppt每页转码验证
export function WEBVPptPageEscapeVerify(AttachKey, client_id, AttachUrl) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.PptPageEscapeVerify',
      AttachKey: AttachKey, // 附件id
      client_id: client_id, // // 当前登录终端的唯一识别
      AttachUrl: AttachUrl
    },
    method: 'GET'
  })
}
// HQ ppt每页转码验证
export function HQPptPageEscapeVerify(AttachKey, client_id, AttachUrl) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.PptPageEscapeVerify',
      AttachKey: AttachKey, // 附件id
      client_id: client_id, // // 当前登录终端的唯一识别
      AttachUrl: AttachUrl
    },
    method: 'GET'
  })
}
// CWS ppt每页转码验证
export function CWSPptPageEscapeVerify(AttachKey, client_id, AttachUrl) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareManage.PptPageEscapeVerify',
      AttachKey: AttachKey, // 附件id
      client_id: client_id, // // 当前登录终端的唯一识别
      AttachUrl: AttachUrl
    },
    method: 'GET'
  })
}

// WEBV ppt转码验证
export function WEBVPptPageEscape(
  AttachKey,
  client_id,
  AttachUrl,
  AuthorizeCode,
  Size
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewareManage.PptPageEscape',
      AttachKey: AttachKey, // 附件id
      client_id: client_id, // // 当前登录终端的唯一识别
      AttachUrl: AttachUrl,
      AuthorizeCode: AuthorizeCode,
      Size: Size
    },
    method: 'GET'
  })
}
// HQ ppt转码验证
export function HQPptPageEscape(
  AttachKey,
  client_id,
  AttachUrl,
  AuthorizeCode,
  Size
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CoursewareManage.PptPageEscape',
      AttachKey: AttachKey, // 附件id
      client_id: client_id, // // 当前登录终端的唯一识别
      AttachUrl: AttachUrl,
      AuthorizeCode: AuthorizeCode,
      Size: Size
    },
    method: 'GET'
  })
}
// CWS ppt转码验证
export function CWSPptPageEscape(
  AttachKey,
  client_id,
  AttachUrl,
  AuthorizeCode,
  Size
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareManage.PptPageEscape',
      AttachKey: AttachKey, // 附件id
      client_id: client_id, // // 当前登录终端的唯一识别
      AttachUrl: AttachUrl,
      AuthorizeCode: AuthorizeCode,
      Size: Size
    },
    method: 'GET'
  })
}

// 优惠券消息推送
export function PushYHQManage(YHQKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.ActivityMonitoringPlatform.PushYHQManage',
      YHQKey: YHQKey // 优惠券ID
    },
    method: 'GET'
  })
}
// 推送消息模块列表
export function SearchPageMessagePushList(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.MessagePushManage.SearchPageMessagePushList',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      MsgTypeKey: parameter.MsgTypeKey
    },
    method: 'GET'
  })
}

// 总部 查看门店信息详情并通知已查看
export function AuditPassConfirm(CampusKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ModifyAddress.AuditPassConfirm',
      ID: CampusKey
    },
    method: 'GET'
  })
}

// 总部 门店修改地址记录
export function GetSaaSClientAddressLogList(ClientKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ModifyAddress.GetSaaSClientAddressLogList',
      ClientKey: ClientKey
    },
    method: 'GET'
  })
}
// CWS 教学模式或缺大可包
export function TeachingModeGetBigCoursewarePackageList() {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'GiveLecturePattern.GetBigCoursewarePackageList'
    },
    method: 'GET'
  })
}
// CWS 教学模式或缺大可包
export function TeachingModeGetCoursewarePackageDetails(
  BigCoursewarePackageKey,
  DeviceCode
) {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'GiveLecturePattern.GetCoursewarePackageDetails',
      BigCoursewarePackageKey,
      DeviceCode: DeviceCode //	字符串	必须		数据源：GET	 设备码
    },
    method: 'GET'
  })
}
// CWS 教学模式或缺大可包
export function TeachingModeGetCoursewareList(
  BigCoursewarePackageKey,
  CoursewarePackageKey,
  TeacherKey,
  IsAuthKey
) {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'GiveLecturePattern.GetCoursewareList',
      CoursewarePackageKey: CoursewarePackageKey,
      BigCoursewarePackageKey: BigCoursewarePackageKey, //	字符串	必须		数据源：GET	 设备码
      TeacherKey: TeacherKey,
      IsAuthKey: IsAuthKey
    },
    method: 'GET'
  })
}
// CWS 教学模式或缺大可包
export function TeachingModegetSearchCourseware(
  CoursewarePackageKey,
  searchText,
  TeacherKey
) {
  var IPAN = store.getters.SaaSClientEditionKey == 6 ? 'CWS.' : 'WEBV.'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'GiveLecturePattern.getSearchCourseware',
      searchText: searchText,
      CoursewarePackageKey: CoursewarePackageKey, //	字符串	必须		数据源：GET	 设备码
      TeacherKey: TeacherKey
    },
    method: 'GET'
  })
}
//	添加附件访问记录
export function AddAttachVisitLog(
  AttachKey,
  CoursewareVisitKey,
  StartPosition,
  AttachVisitKey,
  EndPosition
) {
  var IPAN = ''
  if (store.getters.SaaSClientEditionKey == 5) {
    IPAN = 'HQ.'
  } else if (store.getters.SaaSClientEditionKey == 6) {
    IPAN = 'CWS.'
  } else {
    IPAN = 'WEBV.'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'AttachVisit.AddAttachVisitLog',
      AttachKey: AttachKey,
      CoursewareVisitKey: CoursewareVisitKey, //	字符串	必须		数据源：GET	 设备码
      StartPosition: StartPosition,
      AttachVisitKey: AttachVisitKey,
      EndPosition: EndPosition
    },
    method: 'GET'
  })
}
// 附件访问结束
export function EndAttachVisit(AttachVisitKey, EndPosition) {
  var IPAN = ''
  if (store.getters.SaaSClientEditionKey == 5) {
    IPAN = 'HQ.'
  } else if (store.getters.SaaSClientEditionKey == 6) {
    IPAN = 'CWS.'
  } else {
    IPAN = 'WEBV.'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'AttachVisit.EndAttachVisit',
      AttachVisitKey: AttachVisitKey,
      EndPosition: EndPosition
    },
    method: 'GET'
  })
}
// 退出课件访问
export function ExitCoursewarevisit(AuthKey) {
  var IPAN = ''
  if (store.getters.SaaSClientEditionKey == 5) {
    IPAN = 'HQ.'
  } else if (store.getters.SaaSClientEditionKey == 6) {
    IPAN = 'CWS.'
  } else {
    IPAN = 'WEBV.Current_'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + 'CoursewareManage.ExitCoursewarevisit',
      AuthKey: AuthKey
    },
    method: 'GET'
  })
}
// 操作日志
export function MessageSearchPageClientActionLog(searchData) {
  var serviceName = ''
  //
  if (store.getters.SaaSClientInfo.TypeKey == 6) {
    // 连锁总部
    serviceName = 'HQ'
  } else if (store.getters.SaaSClientEditionKey == 6) {
    serviceName = 'CWS' // 课件门店
  } else {
    serviceName = 'WEBV' // 其它，特指教务门店
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        serviceName + '.ClientActionLogManage.MessageSearchPageClientActionLog',
      pageIndex: searchData.pageIndex,
      pageSize: searchData.pageSize,
      orderBy: searchData.orderBy,
      sequence: searchData.sequence,
      searchText: searchData.searchText,
      OperatorPersonnelKey: searchData.OperatorPersonnelKey,
      OperatorTypekey: searchData.OperatorTypekey,
      OperatorEquipmentKey: searchData.OperatorEquipmentKey,
      StartTime: searchData.startTime,
      EndTime: searchData.endTime
    },
    method: 'get'
  })
}
// WEBV.TeacherGiveLecture.VerifyLocation
export function VerifyLocation(
  ScheduleCourseKey,
  Longitude,
  Latitude,
  SaaSClientKey,
  client_id,
  DeviceTypeKey,
  DeviceCode,
  PositioningOffset
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherGiveLecture.VerifyLocation',
      ScheduleCourseKey: ScheduleCourseKey, // 课名ID
      Longitude: Longitude, // 经度
      Latitude: Latitude, // 纬度
      SaaSClientKey,
      client_id,
      DeviceTypeKey,
      DeviceCode,
      PositioningOffset
    },
    method: 'GET'
  })
}

// 教务门店预览验证
export function WEBVPreviewVerifyLocation(
  CourseWarePackageKey,
  Longitude,
  Latitude,
  SaaSClientKey,
  client_id,
  DeviceTypeKey,
  DeviceCode,
  PositioningOffset
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_CoursewarePreview.VerifyLocation',
      CourseWarePackageKey: CourseWarePackageKey, // 课名ID
      Longitude: Longitude, // 经度
      Latitude: Latitude, // 纬度
      SaaSClientKey,
      client_id,
      DeviceTypeKey,
      DeviceCode,
      PositioningOffset
    },
    method: 'GET'
  })
}

// 教务门店预览验证
export function CWSPreviewVerifyLocation(
  CourseWarePackageKey,
  Longitude,
  Latitude,
  SaaSClientKey,
  client_id,
  DeviceTypeKey,
  DeviceCode,
  PositioningOffset
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherPrepareLessons.VerifyLocation',
      CourseWarePackageKey: CourseWarePackageKey, // 课名ID
      Longitude: Longitude, // 经度
      Latitude: Latitude, // 纬度
      SaaSClientKey,
      client_id,
      DeviceTypeKey,
      DeviceCode,
      PositioningOffset
    },
    method: 'GET'
  })
}

export function CWSVerifyLocation(
  ScheduleCourseKey,
  Longitude,
  Latitude,
  SaaSClientKey,
  client_id,
  DeviceTypeKey,
  CourseWarePackageKey,
  DeviceCode,
  PositioningOffset
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.TeacherGiveLecture.VerifyLocation',
      ScheduleCourseKey: ScheduleCourseKey, // 课名ID
      Longitude: Longitude, // 经度
      Latitude: Latitude, // 纬度
      SaaSClientKey,
      client_id,
      DeviceTypeKey,
      CourseWarePackageKey,
      DeviceCode,
      PositioningOffset
    },
    method: 'GET'
  })
}
// 课件- 验证位置
export function coursewareVerifyLocation(
  CourseWarePackageKey,
  Longitude,
  Latitude,
  SaaSClientKey,
  client_id,
  DeviceTypeKey,
  DeviceCode,
  PositioningOffset
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_TeacherPrepareLessons.VerifyLocation',
      CourseWarePackageKey: CourseWarePackageKey,
      Longitude: Longitude, // 经度
      Latitude: Latitude, // 纬度
      SaaSClientKey,
      client_id,
      DeviceTypeKey,
      DeviceCode,
      PositioningOffset
    },
    method: 'GET',
    checkResubmit: true
  })
}
// WEBV.TeacherGiveLecture.VerifyLocation
export function KQ_AdjustHour(
  ScheduleCourseKey,
  IsClassHourLack,
  IsTrialKey,
  dataInfo
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KQ.KQ_AdjustHour',
      OLAPKey: ScheduleCourseKey, // 课名ID
      IsClassHourLack: IsClassHourLack, // 经度
      IsTrialKey: IsTrialKey,
      TargetAttenceStatusKey: dataInfo.TargetAttenceStatusKey, // 纬度
      ClassHourNum: dataInfo.ClassHourNum,
      IsMakeupKey: dataInfo.IsMakeupKey,
      Reason: dataInfo.Reason
    },
    method: 'GET'
  })
}

// WEBV.TeacherGiveLecture.VerifyLocation
export function getAddressLogList(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ModifyAddress.getAddressLogList',
      pageIndex: searchData.pageIndex,
      TypeKey: searchData.TypeKey,
      pageSize: searchData.pageSize,
      orderBy: searchData.orderBy,
      sequence: searchData.sequence,
      searchText: searchData.searchText,
      IsConfirmKey: searchData.IsConfirmKey
    },
    method: 'GET'
  })
}

// MessageSearchPageActiveCampus
export function MessageSearchPageActiveCampus(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusCoursewareManage.MessageSearchPageActiveCampus',
      pageIndex: searchData.pageIndex,
      TypeKey: searchData.TypeKey,
      pageSize: searchData.pageSize,
      orderBy: searchData.orderBy,
      sequence: searchData.sequence,
      searchText: searchData.searchText
    },
    method: 'GET'
  })
}

// 通过定位授权
export function AuditSaaSClientAddressLog(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ModifyAddress.AuditSaaSClientAddressLog',
      OLAPKey: OLAPKey
    },
    method: 'GET'
  })
}

// 拒绝定位授权
export function RefuseSaaSClientAddressLog(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ModifyAddress.RefuseSaaSClientAddressLog',
      OLAPKey: OLAPKey
    },
    method: 'GET'
  })
}

// 查看定位授权
export function CheckSaaSClientAddressLog(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.ModifyAddress.CheckSaaSClientAddressLog',
      OLAPKey: OLAPKey
    },
    method: 'GET'
  })
}
// 老生录入导入
export function ImportStudentThread(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_SaleStat.ImportStudentThread'
    },
    method: 'post',
    data: data,
    timeout: 60000,
    checkResubmit: true
  })
}
// 线索导入
export function RecruitImportStudentThread(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.ImportStudentThread'
    },
    method: 'post',
    data: data,
    timeout: 60000,
    checkResubmit: true
  })
}
export function batchAuthorization(parameter, AuthKeyList) {
  var data = {
    AuthKeyList: JSON.stringify(AuthKeyList)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.BatchAuthorizationSetting',
      ClearingFormKey: parameter.ClearingFormKey, //	整型	必须		数据源：GET	结算方式
      AuthorizeDeviceNum: parameter.AuthorizeDeviceNum, //	整型	必须		数据源：GET	授权硬件设备数量
      ReleasedNum: parameter.ReleasedNum, //	整型	可选		数据源：GET	课次数量
      Duration: parameter.Duration, //	整型	可选		数据源：GET	时长
      IncomePayAccountKey: parameter.IncomePayAccountKey, //	整型	可选		数据源：GET	收款账户
      IncomePayAccountKeyValue: parameter.IncomePayAccountKeyValue, //	字符串	可选		数据源：GET	收款账户value
      IsLocationLockKey: parameter.IsLocationLockKey, //	字符串	可选		数据源：GET	是否开启定位锁定
      IsAPPAuthKey: parameter.IsAPPAuthKey, //	字符串	可选		数据源：GET	是否开启APP授权
      IsTVAuthKey: parameter.IsTVAuthKey,
      IsPCAuthKey: parameter.IsPCAuthKey,
      APPAuthorizeDeviceNum: parameter.APPAuthorizeDeviceNum,
      TVAuthorizeDeviceNum: parameter.TVAuthorizeDeviceNum,
      TVIsLocationLockKey: parameter.TVIsLocationLockKey,
      PCIsLocationLockKey: parameter.PCIsLocationLockKey,
      TVPositionalAccuracyKey: parameter.TVPositionalAccuracyKey,
      PCPositionalAccuracyKey: parameter.PCPositionalAccuracyKey,
      APPPositionalAccuracyKey: parameter.APPPositionalAccuracyKey,
      startTime: parameter.startTime,
      endTime: parameter.endTime,
      IsPCRecordKey: parameter.IsPCRecordKey,
      IsAPPRecordKey: parameter.IsAPPRecordKey,
      IsTVRecordKey: parameter.IsTVRecordKey
    },
    method: 'post',
    data: qs.stringify(data),
    checkResubmit: true
  })
}
//
// 防录屏截屏列表 HQ.Monitor.GetMonitorList
export function GetMonitorList(IsHQClientKey, searchData) {
  let AIPN
  if (store.getters.SaaSClientEditionKey == 5) {
    AIPN = 'HQ'
  } else if (store.getters.SaaSClientEditionKey == 6) {
    AIPN = 'CWS'
  } else {
    AIPN = 'WEBV'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN + '.Monitor.GetMonitorList',
      IsHQClientKey: IsHQClientKey,
      pageIndex: searchData.pageIndex,
      pageSize: searchData.pageSize,
      orderBy: searchData.orderBy,
      sequence: searchData.sequence,
      searchText: searchData.searchText
    },
    method: 'GET'
  })
}

// 防录屏截屏忽略
export function MonitorOverlook(OLAPKey, IsHQClientKey) {
  let AIPN
  if (store.getters.SaaSClientEditionKey == 5) {
    AIPN = 'HQ'
  } else if (store.getters.SaaSClientEditionKey == 6) {
    AIPN = 'CWS'
  } else {
    AIPN = 'WEBV'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN + '.Monitor.MonitorOverlook',
      OLAPKey: OLAPKey,
      IsHQClientKey: IsHQClientKey
    },
    method: 'GET'
  })
}
// 防录屏截屏查看
export function GetMonitorInfo(OLAPKey, IsHQClientKey) {
  let AIPN
  if (store.getters.SaaSClientEditionKey == 5) {
    AIPN = 'HQ'
  } else if (store.getters.SaaSClientEditionKey == 6) {
    AIPN = 'CWS'
  } else {
    AIPN = 'WEBV'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN + '.Monitor.GetMonitorInfo',
      OLAPKey: OLAPKey,
      IsHQClientKey: IsHQClientKey
    },
    method: 'GET'
  })
}
// 教学模式-课件列表

export function teachingModeGetSearchCoursewarePackage(
  searchText,
  TeacherKey,
  ParentKey
) {
  let AIPN
  if (store.getters.SaaSClientEditionKey == 6) {
    AIPN = 'CWS'
  } else {
    AIPN = 'WEBV'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN + '.GiveLecturePattern.GetSearchCoursewarePackage',
      searchText: searchText,
      TeacherKey: TeacherKey,
      ParentKey: ParentKey
    },
    method: 'GET'
  })
}
// 教学模式-课件附件列表
export function teachingModeGetCoursewareAttachList(ParentKey) {
  let AIPN
  if (store.getters.SaaSClientEditionKey == 6) {
    AIPN = 'CWS'
  } else {
    AIPN = 'WEBV'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN + '.GiveLecturePattern.GetCoursewareAttachList',
      ParentKey: ParentKey
    },
    method: 'GET'
  })
}
// 教学模式-子课包信息
export function teachingModeGetCoursewarePackageInfo(
  TeacherKey,
  CoursewareKey,
  DeviceCode
) {
  let AIPN
  if (store.getters.SaaSClientEditionKey == 6) {
    AIPN = 'CWS'
  } else {
    AIPN = 'WEBV'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN + '.GiveLecturePattern.GetCoursewarePackageInfo',
      TeacherKey: TeacherKey,
      CoursewareKey: CoursewareKey,
      DeviceCode: DeviceCode
    },
    method: 'GET'
  })
}
// 课件管理 设置模块
export function SetSettingTemplate(SettingTemplate, OLAPKey) {
  var data = {
    SettingTemplate: JSON.stringify(SettingTemplate)
  }
  let AIPN
  if (store.getters.SaaSClientEditionKey == 5) {
    AIPN = 'HQ.'
  } else if (store.getters.SaaSClientEditionKey == 6) {
    AIPN = 'CWS.'
  } else {
    AIPN = 'WEBV.Current_'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN + 'ParentCourseWarePackage.SetSettingTemplate',
      OLAPKey: OLAPKey
    },
    method: 'post',
    data: qs.stringify(data),
    checkResubmit: true
  })
}

// webv通用获取大课包下的子课包List
export function commonGetCoursewarePackageParentList(CoursewarePackageKey) {
  let serviceName = ''
  if (store.getters.SaaSClientEditionKey == 5) {
    serviceName = 'HQ.ParentCourseWarePackage.GetCoursewarePackageParentList'
  } else if (store.getters.SaaSClientEditionKey == 6) {
    serviceName = 'CWS.ParentCourseWarePackage.GetCoursewarePackageParentList' // 课件门店
  } else {
    serviceName =
      'WEBV.Current_ParentCourseWarePackage.GetCoursewarePackageParentList' // 其它，特指教务门店
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: serviceName,
      ParentKey: CoursewarePackageKey //	整型	必须		数据源：GET	授权课包key
    },
    method: 'get'
  })
}
// 获取自研课包的课件
export function commonGetCoursewareParentList(CoursewarePackageKey) {
  let serviceName = ''
  if (store.getters.SaaSClientEditionKey == 5) {
    serviceName = 'HQ.ParentCourseWarePackage.GetCoursewareParentList'
  } else if (store.getters.SaaSClientEditionKey == 6) {
    serviceName = 'CWS.ParentCourseWarePackage.GetCoursewareParentList' // 课件门店
  } else {
    serviceName = 'WEBV.Current_ParentCourseWarePackage.GetCoursewareParentList' // 其它，特指教务门店
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: serviceName,
      CoursewarePackageKey: CoursewarePackageKey //	整型	必须		数据源：GET	授权课包key
    },
    method: 'get'
  })
}
// 获取附件信息
export function getGetCoursewarePackageAttach(CoursewarePackageKey, TypeKey) {
  let AIPN
  if (store.getters.SaaSClientEditionKey == 6) {
    AIPN = 'CWS.'
  } else {
    AIPN = 'WEBV.Current_'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: AIPN + 'CoursewarePackageManage.GetCoursewarePackageAttach',
      CoursewarePackageKey: CoursewarePackageKey, // 课包key
      TypeKey: TypeKey // 附件类型 不传返回全部 1-课包介绍2-附件下载
    },
    method: 'GET'
  })
}
// 获取封面下载列表
export function getGetCoursewarePackageDownloadCoverUrlList(
  CoursewarePackageKey,
  CoursewarePackageTypeKey,
  TeacherKey
) {
  let AIPN
  if (store.getters.SaaSClientEditionKey == 6) {
    AIPN = 'CWS.'
  } else {
    AIPN = 'WEBV.Current_'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        AIPN +
        'CoursewarePackageManage.GetCoursewarePackageDownloadCoverUrlList',
      CoursewarePackageKey: CoursewarePackageKey, // 课包key
      CoursewarePackageTypeKey: CoursewarePackageTypeKey, // 0-子课包 1-大课包 2-目录
      TeacherKey: TeacherKey // 老师key
    },
    method: 'GET'
  })
}
// 获取大课包关联课件列表
export function getCoursewarePackageParentList(BigCoursewarePackageKey) {
  let AIPN
  if (store.getters.SaaSClientEditionKey == 6) {
    AIPN = 'CWS.'
  } else {
    AIPN = 'WEBV.'
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        AIPN + 'CoursewarePackageAttachManage.CoursewarePackageParentList',
      BigCoursewarePackageKey: BigCoursewarePackageKey // 大课包Key
    },
    method: 'GET'
  })
}
// 重置授权模板按钮
export function resettingTemplate() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTB.WeChatOperation.resettingTemplate'
    },
    method: 'GET'
  })
}

// 门店 收支作废
export function DeleteSearchPageForSZGL(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.SZGL.DeleteSearchPageForSZGL',
      TableID: TableID
    },
    method: 'GET'
  })
}
// 总部 收支作废
export function HqDeleteSearchPageForSZGL(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.IncomePayManage.DeleteSearchPageForSZGL',
      TableID: TableID
    },
    method: 'GET'
  })
}
// 班级管理--排课统计
export function MessageSearchGradeCourseArrangeRepost(GradeID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.MessageSearchGradeCourseArrangeRepost',
      GradeID: GradeID // 班级id
    },
    method: 'GET'
  })
}

// 班级管理--周排课详情
export function MessageSearchGradeCourseArrangeDetail(
  GradeID,
  CourseArrangeNumberType
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.MessageSearchGradeCourseArrangeDetail',
      GradeID: GradeID, // 班级id
      CourseArrangeNumberType: CourseArrangeNumberType // 周排课分类类型
    },
    method: 'GET'
  })
}
// 班级管理--本月应到实到统计
export function MessageSearchThisMonthAttendanceReportForClass(GradeID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.MessageSearchThisMonthAttendanceReportForClass',
      GradeID: GradeID // 班级id
    },
    method: 'GET'
  })
}
// 班级管理--结转课消统计
export function MessageSearchClassDeductAmountAndAttendanceReportForClass(
  GradeID
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'WEBV.Grade.MessageSearchClassDeductAmountAndAttendanceReportForClass',
      GradeID: GradeID // 班级id
    },
    method: 'GET'
  })
}
// 班级管理--结转课消统计
export function MessageSearchGradeClassInfoReport(GradeID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Grade.MessageSearchGradeClassInfoReport',
      GradeID: GradeID // 班级id
    },
    method: 'GET'
  })
}

// 学费结余 -  学生结余
export function GetTuitionBalanceStudentList(data, ClientKey, jsonFormate) {
  let IPAN = store.getters.SaaSClientEditionKey == 5 ? 'HQ' : 'WEBV'
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + '.TuitionCount.GetTuitionBalanceStudentList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      StudentKey: data.StudentKey,
      ClientKey: ClientKey,
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学费结余 -  课程结余
export function GetTuitionBalanceCourseList(data, ClientKey, jsonFormate) {
  let IPAN = store.getters.SaaSClientEditionKey == 5 ? 'HQ' : 'WEBV'
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + '.TuitionCount.GetTuitionBalanceCourseList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      StudentKey: data.StudentKey,
      ClientKey: ClientKey,
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学费结余 -  课时结余
export function GetTuitionBalanceClassHourList(data, ClientKey, jsonFormate) {
  let IPAN = store.getters.SaaSClientEditionKey == 5 ? 'HQ' : 'WEBV'
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + '.TuitionCount.GetTuitionBalanceClassHourList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      StudentKey: data.StudentKey,
      ClientKey: ClientKey,
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学费结余 -  天数结余
export function GetTuitionBalanceDayList(data, ClientKey, jsonFormate) {
  let IPAN = store.getters.SaaSClientEditionKey == 5 ? 'HQ' : 'WEBV'
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + '.TuitionCount.GetTuitionBalanceDayList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      StudentKey: data.StudentKey,
      ClientKey: ClientKey,
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学费结余统计
export function GetTuitionBalanceCountDate(ClientKey) {
  let IPAN = store.getters.SaaSClientEditionKey == 5 ? 'HQ' : 'WEBV'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + '.TuitionCount.GetTuitionBalanceCountDate',
      ClientKey: ClientKey
    },
    method: 'GET'
  })
}

// 学费结余 - 报名记录
export function GetIncomeApplicationList(data, ClientKey, jsonFormate) {
  let IPAN = store.getters.SaaSClientEditionKey == 5 ? 'HQ' : 'WEBV'
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + '.TuitionCount.GetIncomeApplicationList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      StudentKey: data.StudentKey,
      StartTime: data.startTime,
      EndTime: data.endTime,
      ClientKey: ClientKey,
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学费收入 - 课时列表
export function GetIncomeClassHourList(data, ClientKey, jsonFormate) {
  let IPAN = store.getters.SaaSClientEditionKey == 5 ? 'HQ' : 'WEBV'
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + '.TuitionCount.GetIncomeClassHourList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      StudentKey: data.StudentKey,
      StartTime: data.startTime,
      EndTime: data.endTime,
      ClientKey: ClientKey,
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学费收入 - 学杂费收入
export function GetMiscellAneousTuitionList(data, ClientKey, jsonFormate) {
  let IPAN = store.getters.SaaSClientEditionKey == 5 ? 'HQ' : 'WEBV'
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + '.TuitionCount.GetMiscellAneousTuitionList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      StudentKey: data.StudentKey,
      StartTime: data.startTime,
      EndTime: data.endTime,
      ClientKey: ClientKey,
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学费收入 - 退课收入
export function GetRefundCourseList(data, ClientKey, jsonFormate) {
  let IPAN = store.getters.SaaSClientEditionKey == 5 ? 'HQ' : 'WEBV'
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + '.TuitionCount.GetRefundCourseList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      StudentKey: data.StudentKey,
      StartTime: data.startTime,
      EndTime: data.endTime,
      ClientKey: ClientKey,
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学费收入 - 天数收入
export function GetIncomeDayList(data, ClientKey, jsonFormate) {
  let IPAN = store.getters.SaaSClientEditionKey == 5 ? 'HQ' : 'WEBV'
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + '.TuitionCount.GetIncomeDayList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      StudentKey: data.StudentKey,
      StartTime: data.startTime,
      EndTime: data.endTime,
      ClientKey: ClientKey,
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学费收入 - 学杂费统计
export function GetIncomeCountDate(searchObj, ClientKey) {
  let IPAN = store.getters.SaaSClientEditionKey == 5 ? 'HQ' : 'WEBV'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + '.TuitionCount.GetIncomeCountDate',
      StartTime: searchObj.startTime,
      EndTime: searchObj.endTime,
      ClientKey: ClientKey
    },
    method: 'GET'
  })
}

// 学费结转 -  学生结转
export function GetSettlementsStudentList(data, ClientKey, jsonFormate) {
  let IPAN = store.getters.SaaSClientEditionKey == 5 ? 'HQ' : 'WEBV'
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + '.TuitionCount.GetSettlementsStudentList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      StudentKey: data.StudentKey,
      StartTime: data.startTime,
      EndTime: data.endTime,
      ClientKey: ClientKey,
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学费结转 -  课程结转
export function GetSettlementsClassDeductList(data, ClientKey, jsonFormate) {
  let IPAN = store.getters.SaaSClientEditionKey == 5 ? 'HQ' : 'WEBV'
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + '.TuitionCount.GetSettlementsClassDeductList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      StudentKey: data.StudentKey,
      StartTime: data.startTime,
      EndTime: data.endTime,
      ClientKey: ClientKey,
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学费结转 -  异常结转
export function GetSettlementsExceptionalList(data, ClientKey, jsonFormate) {
  let IPAN = store.getters.SaaSClientEditionKey == 5 ? 'HQ' : 'WEBV'
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + '.TuitionCount.GetSettlementsExceptionalList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      StudentKey: data.StudentKey,
      StartTime: data.startTime,
      EndTime: data.endTime,
      DeductTypeKey: data.DeductTypeKey,
      ClientKey: ClientKey,
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学费结转 -  天数结转
export function GetSettlementsDayList(data, ClientKey, jsonFormate) {
  let IPAN = store.getters.SaaSClientEditionKey == 5 ? 'HQ' : 'WEBV'
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + '.TuitionCount.GetSettlementsDayList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      StudentKey: data.StudentKey,
      StartTime: data.startTime,
      EndTime: data.endTime,
      ClientKey: ClientKey,
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学费结转统计
export function GetSettlementsCountDate(parameter, ClientKey) {
  let IPAN = store.getters.SaaSClientEditionKey == 5 ? 'HQ' : 'WEBV'
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: IPAN + '.TuitionCount.GetSettlementsCountDate',
      StartTime: parameter.startTime,
      EndTime: parameter.endTime,
      ClientKey: ClientKey
    },
    method: 'GET'
  })
}

// 获取关联课单list
export function GetConversionRelevantList(IsRenewalKey, StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KDSY.GetConversionRelevantList',
      IsRenewalKey,
      StudentKey
    },
    method: 'GET'
  })
}

// 获取关联课单列表
export function GetRelevantRenewXykd(StudentKey, TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.GetRelevantRenewXykd',
      StudentKey,
      TableID
    },
    method: 'GET'
  })
}
// 更新关联课单
export function UpdateRelevantRenewXykd(TableID, RenewTableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.UpdateRelevantRenewXykd',
      TableID: TableID, // 被续费课单key
      RenewTableID: RenewTableID // 续费课单key
    },
    method: 'GET'
  })
}

// 关联转化
export function ConversionRelevant(params) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KDSY.ConversionRelevant',
      TableID: params.TableID,
      RelevantID: params.RelevantID,
      TypeKey: params.TypeKey,
      NumberName: params.NumberName,
      GradeClassKeyValue: params.GradeClassKeyValue
    },
    method: 'GET'
  })
}
// 获取总部直营和加盟 开通教务的门店
export function GetHQClinetAcademicList(IsRenewalKey, StudentKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.TuitionCount.GetHQClinetAcademicList',
      IsRenewalKey,
      StudentKey
    },
    method: 'GET'
  })
}

// WEBV.KDSY.GetConversionUntreated 待处理列表
export function GetConversionUntreated(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KDSY.GetConversionUntreated',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      StartTime: data.StartTime,
      EndTime: data.EndTime,
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}

//  已处理列表
export function GetConversionProcessed(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KDSY.GetConversionProcessed',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      StartTime: data.startTime,
      EndTime: data.endTime,
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 关联转化列表
export function getConversionCourseScheduleList(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KDSY.getConversionCourseScheduleList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      StartTime: data.startTime,
      EndTime: data.endTime,
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 课程管理--报课学生
export function MessageSearchStudentCourseApply(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.MessageSearchStudentCourseApply',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      ClassTypeID: data.ClassTypeID, // 课名课类ID
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 课程管理--周排课报告
export function MessageSearchWeekCourseArrangeReport(ClassTypeID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.MessageSearchWeekCourseArrangeReport',
      ClassTypeID: ClassTypeID
    },
    method: 'GET'
  })
}
// 课程管理--本月结转课消和正课课消单价统计
export function MessageSearchThisMonthClassHourDeductCostReport(ClassTypeID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.MessageSearchThisMonthClassHourDeductCostReport',
      ClassTypeID: ClassTypeID
    },
    method: 'GET'
  })
}

// 课程管理--本月应到实到统计
export function MessageSearchThisMonthAttendanceReportForCourse(ClassTypeID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.MessageSearchThisMonthAttendanceReportForCourse',
      ClassTypeID: ClassTypeID
    },
    method: 'GET'
  })
}
// 课程管理--本月应到实到统计
export function MessageSearchClassHourBalanceReportThisMonth(ClassTypeID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Cours.MessageSearchClassHourBalanceReportThisMonth',
      ClassTypeID: ClassTypeID
    },
    method: 'GET'
  })
}
// 修改课单类型
export function AlterKDApplyTypeKey(param) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.KDSY.AlterKDApplyTypeKey',
      TableID: param.TableID,
      ApplyTypeKey: param.ApplyTypeKey,
      ApplyTypeKeyValue: param.ApplyTypeKeyValue
    },
    method: 'GET'
  })
}

// 系统端口管理-分页查询(原校区管理)
export function GetSystemPortConsumeOptionList(parameter, StatusTypeKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.systemPortManage.GetSystemPortConsumeOptionList',
      pageIndex: parameter.pageIndex,
      pageSize: parameter.pageSize,
      searchText: parameter.searchText,
      orderBy: parameter.orderBy,
      sequence: parameter.sequence,
      IsActiveKey: parameter.IsActiveKey, //	整型	可选		数据源：GET	是否活跃校区
      IsStopKey: parameter.IsStopKey, //	整型	可选		数据源：GET	是否停用
      IsExpiredKey: parameter.IsExpiredKey, //	整型	可选		数据源：GET	是否已过期
      IsExpired30Key: parameter.IsExpired30Key, //	整型	可选		数据源：GET	是否30天内过期
      portTypeKey: parameter.portTypeKey, // 整型	可选		数据源：GET	端口类型: 1-教务端口; 2-课件端口
      campusTypeKey: parameter.campusTypeKey, // 数据源：GET	 校区类型: 1-直营店; 2-加盟店;3-课程合作
      StatusTypeKey: StatusTypeKey //
    },
    method: 'GET'
  })
}
// 批量续端口
export function BatchRenew(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.systemPortManage.BatchRenew'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 批量续端口校区列表
export function GetClientSystemPortConsumeOptionList(MAXNum, MINNum, TypeKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.systemPortManage.GetClientSystemPortConsumeOptionList',
      MAXNum: MAXNum,
      MINNum: MINNum,
      TypeKey: TypeKey
    },
    method: 'GET'
  })
}

// 课件校区管理--获取校区资源
export function MessageSearchPageCampusResource(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusResourceManage.MessageSearchPageCampusResource',
      pageIndex: parameter.pageIndex,
      pageSize: parameter.pageSize,
      searchText: parameter.searchText,
      orderBy: parameter.orderBy,
      sequence: parameter.sequence
    },
    method: 'GET'
  })
}

// 校区课件管理--获取授权课件大课包详情
export function GetAuthorizeBigCoursePackageDetails(
  ClientKey,
  BigCoursePackageKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusCoursewareManage.GetAuthorizeBigCoursePackageDetails',
      ClientKey: ClientKey,
      BigCoursePackageKey: BigCoursePackageKey
    },
    method: 'GET'
  })
}

// 校区课件管理--获取校区详情
export function GethqCampusDetails(ClientKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusCoursewareManage.GetCampusDetails',
      ClientKey: ClientKey
    },
    method: 'GET'
  })
}

// 校区课件管理--获取授权课件大课包
export function GetAuthorizeBigCoursePackage(ClientKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusCoursewareManage.GetAuthorizeBigCoursePackage',
      ClientKey: ClientKey
    },
    method: 'GET'
  })
}
// 课包授权管理--获取在用校区
export function AuthorMessageSearchPageActiveCampus(
  parameter,
  BigCoursewarePackageKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.MessageSearchPageActiveCampus',
      pageIndex: parameter.pageIndex,
      pageSize: parameter.pageSize,
      searchText: parameter.searchText,
      orderBy: parameter.orderBy,
      sequence: parameter.sequence,
      BigCoursewarePackageKey
    },
    method: 'GET'
  })
}
// 课包授权管理--获取停用校区
export function MessageSearchPageDisuseCampus(
  parameter,
  BigCoursewarePackageKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.MessageSearchPageDisuseCampus',
      pageIndex: parameter.pageIndex,
      pageSize: parameter.pageSize,
      searchText: parameter.searchText,
      orderBy: parameter.orderBy,
      sequence: parameter.sequence,
      BigCoursewarePackageKey
    },
    method: 'GET'
  })
}
// 课包授权管理--获取授权记录
export function MessageSearchPageAuthorizeRecord(
  parameter,
  CoursewarePackageKey,
  SchoolKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.MessageSearchPageAuthorizeRecord',
      pageIndex: parameter.pageIndex,
      pageSize: parameter.pageSize,
      searchText: parameter.searchText,
      orderBy: parameter.orderBy,
      sequence: parameter.sequence,
      StatusKey: parameter.StatusKey,
      CoursewarePackageKey: CoursewarePackageKey || 0,
      SchoolKey: SchoolKey || 0
    },
    method: 'GET'
  })
}
// 排课创收潜力挖掘-排课与课消汇总
export function GetMonthlySummaryData(start, end) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetMonthlySummaryData',
      StartTime: start,
      EndTime: end
    },
    method: 'GET'
  })
}
// 排课创收潜力挖掘-排课饱和率-统计和教室列表
export function GetSchoolRoomCourseData() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetSchoolRoomCourseData'
    },
    method: 'GET'
  })
}
// 排课创收潜力挖掘-排课饱和率-教室周排课列表
export function GetSchoolRoomCourseWeekDate(key, IsSchoolRoomkey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetSchoolRoomCourseWeekDate',
      SchoolRoomKey: key,
      IsSchoolRoomkey: IsSchoolRoomkey
    },
    method: 'GET'
  })
}
// 排课创收潜力挖掘-排课饱和率-教室天排课列表
export function GetSchoolRoomWeekScheduleCourseList(
  roomKey,
  dayKey,
  pageIndex,
  pageSize,
  IsSchoolRoomkey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetSchoolRoomWeekScheduleCourseList',
      SchoolRoomKey: roomKey,
      WeekKey: dayKey,
      pageIndex: pageIndex,
      pageSize: pageSize,
      IsSchoolRoomkey: IsSchoolRoomkey
    },
    method: 'GET'
  })
}
// 排课创收潜力挖掘-透视排课不足学生-统计图
export function GetPotentialCoursePicture() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetPotentialCoursePicture'
    },
    method: 'GET'
  })
}
// 排课创收潜力挖掘-透视排课不足学生-下拉列表选项
export function GetPotentialCoursePictureCount() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetPotentialCoursePictureCount'
    },
    method: 'GET'
  })
}
// 排课创收潜力挖掘-透视排课不足学生-列表和导出
export function GetPotentialCourseList(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetPotentialCourseList',
      CourseNameKey: data.CourseNameKey,
      searchText: data.searchText,
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      IsExportKey: data.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 排课创收潜力挖掘-透视出勤不足学生-统计图
export function GetPotentialStudentData() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetPotentialStudentData'
    },
    method: 'GET'
  })
}
// 排课创收潜力挖掘-透视出勤不足学生-列表
export function GetPotentialStudentlist(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetPotentialStudentlist',
      TypeKey: data.TypeKey,
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      IsExportKey: data.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 排课创收潜力挖掘-透视出勤不足学生-详情列表
export function GetPotentialStudentdetailsList(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetPotentialStudentdetailsList',
      StudentKey: data.StudentKey,
      TimeTypeNum: data.TimeTypeNum,
      GradeKey: data.GradeKey,
      AttenceStatusKey: data.AttenceStatusKey,
      pageIndex: data.pageIndex,
      pageSize: data.pageSize
    },
    method: 'GET'
  })
}
// 排课创收潜力挖掘-透视出勤不足班级-统计图
export function GetGradeAttenceDataCount() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetGradeAttenceDataCount'
    },
    method: 'GET'
  })
}
// 排课创收潜力挖掘-透视出勤不足班级-列表
export function GetGradeAttenceDataList(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetGradeAttenceDataList',
      TypeKey: data.TypeKey,
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      IsExportKey: data.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 排课创收潜力挖掘-透视出勤不足班级-详情班级信息
export function GetPotentialGradeInfo(key, num) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetPotentialGradeInfo',
      GradeClassKey: key,
      TimeTypeNum: num
    },
    method: 'GET'
  })
}
// 排课创收潜力挖掘-透视出勤不足班级-详情列表
export function GetGradeAttenceStudentDataList(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetGradeAttenceStudentDataList',
      GradeClassKey: data.GradeClassKey,
      TimeTypeNum: data.TimeTypeNum,
      WeekKey: data.WeekKey,
      MTeacherKey: data.MTeacherKey,
      CourseNameKey: data.CourseNameKey,
      ClassTimeName: data.ClassTimeName,
      pageIndex: data.pageIndex,
      pageSize: data.pageSize
    },
    method: 'GET'
  })
}
// 排课创收潜力挖掘-合班可行性分析-统计图
export function GetGradeFullDataCount() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetGradeFullDataCount'
    },
    method: 'GET'
  })
}
// 排课创收潜力挖掘-合班可行性分析-列表
export function GetGradeFullList(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Potential.GetGradeFullList',
      TypeKey: data.TypeKey,
      StartTime: data.StartTime,
      EndTime: data.EndTime,
      pageIndex: data.pageIndex,
      pageSize: data.pageSize
    },
    method: 'GET'
  })
}
// 一码通管理-渠道列表-列表
export function GetCustomerChannelList(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerChannel.GetCustomerChannelList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize
    },
    method: 'GET'
  })
}
// 一码通管理-渠道列表-添加
export function AddCustomerChannel(data) {
  data = {
    data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerChannel.AddCustomerChannel'
    },
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 一码通管理-渠道列表-关联方案列表
export function GetBelowCustomerChannelActivitylist(IsStatusKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerChannel.GetBelowCustomerChannelActivitylist',
      IsStatusKey: IsStatusKey
    },
    method: 'GET'
  })
}
// 一码通管理-成交与返佣记录-渠道商下拉列表
export function GetBelowCustomerChannellist() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerChannel.GetBelowCustomerChannellist'
    },
    method: 'GET'
  })
}
// 一码通管理-设置方案佣金-列表
export function GetChannelActivityList(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerChannel.GetChannelActivityList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize
    },
    method: 'GET'
  })
}
// 一码通管理-设置方案佣金-关联活动下拉列表
export function GetCouponList(key) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerChannel.GetCouponList',
      CouponType: key
    },
    method: 'GET'
  })
}
// 一码通管理-设置方案佣金-添加
export function AddChannelActivity(data) {
  data = {
    data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerChannel.AddChannelActivity'
    },
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 一码通管理-设置方案佣金-停用
export function UpdateStopChannelActivity(key) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerChannel.UpdateStopChannelActivity',
      ActivityKey: key
    },
    method: 'GET'
  })
}
// 一码通管理-渠道列表-关联方案
export function ContactCustomerChannelActivity(rowkey, plankey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerChannel.ContactCustomerChannelActivity',
      CustomerKey: rowkey,
      ActivityKey: plankey
    },
    method: 'GET'
  })
}
// 一码通管理-成交与返佣记录-列表
export function GetRebateLogList(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerChannel.GetRebateLogList',
      CustomerKey: data.channel,
      ActivityKey: data.activity,
      RebateStatusKey: data.state,
      searchText: data.text,
      pageIndex: data.pageIndex,
      pageSize: data.pageSize
    },
    method: 'GET'
  })
}
// 一码通管理-成交与返佣记录-返佣
export function Rebate(key) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerChannel.Rebate',
      RebateLogKey: key
    },
    method: 'GET'
  })
}
// 一码通管理-渠道列表-详情-获取渠道商信息
export function GetCustomerChannelInfo(key) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerChannel.GetCustomerChannelInfo',
      OLAPKey: key
    },
    method: 'GET'
  })
}
// 一码通管理-渠道列表-详情-当前活动列表信息
export function GetCurrentActivityList(key) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerChannel.GetCurrentActivityList',
      OLAPKey: key
    },
    method: 'GET'
  })
}
// 一码通管理-渠道列表-详情-历史活动列表信息
export function GetHistoryActivityList(key) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerChannel.GetHistoryActivityList',
      OLAPKey: key
    },
    method: 'GET'
  })
}
// 一码通管理-渠道列表-详情-获取历史活动列表详情列表
export function GetHistoryActivityRebateList(OLAPKey, ActivityKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.CustomerChannel.GetHistoryActivityRebateList',
      OLAPKey: OLAPKey,
      ActivityKey: ActivityKey
    },
    method: 'GET'
  })
}
// 课效督学统计-老师课效汇总统计-老师客评统计图表数据
export function GetParentCountData(month, lastMonth) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ParentReview.GetParentCountData',
      Month: month,
      LastMonth: lastMonth
    },
    method: 'GET'
  })
}
// 课效督学统计-老师课效汇总统计-老师客评统计数据
export function GetParentMTeacherCountList(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ParentReview.GetParentMTeacherCountList',
      Month: data.Month,
      pageIndex: data.pageIndex,
      pageSize: data.pageSize
    },
    method: 'GET'
  })
}
// 课效督学统计-透视已读家长-已读家长统计数据
export function GetReadParentData(month) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ParentReview.GetReadParentData',
      Month: month
    },
    method: 'GET'
  })
}
// 课效督学统计-透视已读家长-列表和导出
export function GetReadParentList(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ParentReview.GetReadParentList',
      Month: data.Month,
      TypeKey: data.TypeKey,
      searchText: data.searchText,
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      IsExportKey: data.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 课效督学统计-透视转发家长-转发家长统计数据
export function GetChangeParentData(month) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ParentReview.GetChangeParentData',
      Month: month
    },
    method: 'GET'
  })
}
// 课效督学统计-透视转发家长-列表和导出
export function GetChangeParentList(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ParentReview.GetChangeParentList',
      Month: data.Month,
      TypeKey: data.TypeKey,
      searchText: data.searchText,
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      IsExportKey: data.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 课程表-复制课表
export function CopyScheduleCourse(key, start, end, remark) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScheduleCourse.CopyScheduleCourse',
      GradeClassKey: key,
      DateTime: start,
      ToDateTime: end,
      ReasonContent: remark
    },
    method: 'GET'
  })
}
// 补课管理-详情-课程列表
export function GetMakeUpCourseByStudent(key) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.MakeupManage.GetMakeUpCourseByStudent',
      StudentKey: key
    },
    method: 'GET'
  })
}
// 补课管理-安排补课
export function DealMakeUpForArrange(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.MakeupManage.DealMakeUpForArrange',
      StudentKey: data.StudentKey,
      ScheduleCourseKey: data.ScheduleCourseKey,
      MakeUpScheduleCourseKey: data.MakeUpScheduleCourseKey,
      IsDebtClassHourse: data.IsDebtClassHourse
    },
    method: 'GET'
  })
}
// 补课管理-获取指定学生的缺勤记录
export function GetAbsenceListByStudent(studentKey, courseKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.MakeupManage.GetAbsenceListByStudent',
      StudentKey: studentKey,
      ScheduleCourseKey: courseKey
    },
    method: 'GET'
  })
}
// 课包管理-终止授权    AuthKey
export function AuthorizationCoursewareCloseAuth(AuthKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.CloseAuth',
      AuthKey
    },
    method: 'GET'
  })
}
// 获取续授权记录
export function GetAuthorizationLoglist(CoursewarePackageKey, ClearingFormKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.GetAuthorizationLoglist',
      CoursewarePackageKey,
      ClearingFormKey
    },
    method: 'GET'
  })
}
export function AuthorizationCoursewareBatchRenew(parameter) {
  var data = {
    data: JSON.stringify(parameter)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.BatchRenew'
    },
    method: 'post',
    data: qs.stringify(data),
    checkResubmit: true
  })
}

// 获取续授权校区
export function GetClientAuthList(CoursewarePackageKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.AuthorizationCourseware.GetClientAuthList',
      CoursewarePackageKey
    },
    method: 'GET'
  })
}
// 获取续授权课包
export function GetClientAuthBigCoursewarePackageList(HQClientKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service:
        'HQ.AuthorizationCourseware.GetClientAuthBigCoursewarePackageList',
      HQClientKey
    },
    method: 'GET'
  })
}

// 校区课件管理--授权记录
export function CampusMessageSearchPageAuthorizeRecord(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusCoursewareManage.MessageSearchPageAuthorizeRecord',
      pageIndex: parameter.pageIndex,
      pageSize: parameter.pageSize,
      searchText: parameter.searchText,
      orderBy: parameter.orderBy,
      sequence: parameter.sequence,
      StatusKey: parameter.StatusKey,
      ClientKey: parameter.ClientKey,
      ClearingFormKey: parameter.ClearingFormKey,
      CoursewarePackageKey: parameter.CoursewarePackageKey
    },
    method: 'GET'
  })
}

// 老生录入导入
export function ImportCampusResource(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusResourceManage.ImportCampusResource'
    },
    method: 'post',
    data: data,
    timeout: 60000,
    checkResubmit: true
  })
}

// MessageSearchPageDisuseCampus
export function CampusMessageSearchPageDisuseCampus(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusCoursewareManage.MessageSearchPageDisuseCampus',
      pageIndex: searchData.pageIndex,
      TypeKey: searchData.TypeKey,
      pageSize: searchData.pageSize,
      orderBy: searchData.orderBy,
      sequence: searchData.sequence,
      searchText: searchData.searchText
    },
    method: 'GET'
  })
}

// 校区课件管理--暂停授权
export function StopAuthorization(key) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusCoursewareManage.StopAuthorization',
      SchoolKey: key
    },
    method: 'GET'
  })
}
// 恢复授权
export function RecoverAuthorization(key) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusCoursewareManage.RecoverAuthorization',
      SchoolKey: key
    },
    method: 'GET'
  })
}

// 校区课件管理--终止授权
export function TerminateAuthorize(key) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusCoursewareManage.TerminateAuthorize',
      guid: key
    },
    method: 'GET'
  })
}

// 课件校区管理--添加校区资源
export function AddCampusResource(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusResourceManage.AddCampusResource'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}

// 课件校区管理--修改校区资源
export function EditCampusResource(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusResourceManage.EditCampusResource'
    },
    method: 'post',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}

// 课件校区管理--删除校区资源
export function DeleteCampusResource(key) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusResourceManage.DeleteCampusResource',
      ResourceKey: key
    },
    method: 'GET'
  })
}

// 校区课件管理--获取全部大课包
export function GetAllCoursewarePackage(key, status) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusCoursewareManage.GetAllCoursewarePackage',
      ClientKey: key,
      StatusKey: status
    },
    method: 'GET'
  })
}

// 校区课件管理--获取授权课件大课包详情
export function GetAuthorizeDetails(AuthLotKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.CampusCoursewareManage.GetAuthorizeDetails',
      AuthLotKey
    },
    method: 'GET'
  })
}

// 翻页查询活动列表
export function MessageSearchActivity(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ActivityManagement.MessageSearchActivity',
      pageIndex: searchData.pageIndex,
      TypeKey: searchData.TypeKey,
      pageSize: searchData.pageSize,
      orderBy: searchData.orderBy,
      sequence: searchData.sequence,
      searchText: searchData.searchText,
      startTime: searchData.startTime,
      endTime: searchData.endTime,
      PublishStatus: searchData.PublishStatus, // -1 全部， 0未发布，1已发布
      TypeKey: searchData.TypeKey // 活动类型 5-优惠券招生;6-引流套餐招生;7-拼团招生;8-阶梯团招生9-员工分销招生10-家长分销招生
    },
    method: 'GET'
  })
}
// 翻页查询活动报名记录
export function MessageSearchActivityForApplyNew(searchData, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ActivityManagement.MessageSearchActivityForApply',
      pageIndex: searchData.pageIndex,
      TypeKey: searchData.TypeKey,
      pageSize: searchData.pageSize,
      orderBy: searchData.orderBy,
      sequence: searchData.sequence,
      searchText: searchData.searchText,
      startTime: searchData.startTime,
      endTime: searchData.endTime,
      id: searchData.id,
      TypeKey: searchData.TypeKey, // 活动类型 5-优惠券招生;6-引流套餐招生;7-拼团招生;8-阶梯团招生9-员工分销招生10-家长分销招生
      IsExportKey: searchData.IsExportKey
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 翻页查询活动模板
export function MessageSearchActivitytemplate(searchData) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ActivityManagement.MessageSearchActivitytemplate',
      pageIndex: searchData.pageIndex,
      TypeKey: searchData.TypeKey,
      pageSize: searchData.pageSize,
      orderBy: searchData.orderBy,
      sequence: searchData.sequence,
      searchText: searchData.searchText,
      startTime: searchData.startTime,
      endTime: searchData.endTime,
      TypeKey: searchData.TypeKey // 活动类型 5-优惠券招生;6-引流套餐招生;7-拼团招生;8-阶梯团招生9-员工分销招生10-家长分销招生
    },
    method: 'GET'
  })
}
// 终止活动
export function AbortActivity(CouponKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ActivityManagement.AbortActivity',
      CouponKey
    },
    method: 'GET'
  })
}
// 删除模板
export function DeleteActivitytemplate(TemplateID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ActivityManagement.DeleteActivitytemplate',
      TemplateID: TemplateID
    },
    method: 'GET'
  })
}

// 获取海报模板信息 WEB.ShowbillTmp.GetTmpInfo
export function GetTmpInfo(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEB.ShowbillTmp.GetTmpInfo',
      ID
    },
    method: 'GET'
  })
}
// 保存海报模板信息
export function SaveBillInfo(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEB.Showbill.SaveBillInfo',
      ID
    },
    method: 'POST',
    data: qs.stringify(Data),
    checkResubmit: true
  })
}
// 获取玩法信息 GetActivitiesPlayInfo
export function GetActivitiesPlayInfo(TypeKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ActivityManagement.GetActivitiesPlayInfo',
      TypeKey
    },
    method: 'GET'
  })
}
// 新增活动
export function AddActivity(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ActivityManagement.AddActivity'
    },
    method: 'POST',
    data: qs.stringify(Data)
  })
}
// 修改活动
export function EditActivity(CouponKey, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ActivityManagement.EditActivity',
      CouponKey
    },
    method: 'POST',
    data: qs.stringify(Data)
  })
}
// 获取活动信息
export function GetActivityInfo(CouponKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ActivityManagement.GetActivityInfo',
      CouponKey
    },
    method: 'GET'
  })
}
// 获取海报信息
export function GetShowBillMsg(id) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ActivityManagement.GetShowBillMsg',
      id
    },
    method: 'GET'
  })
}
// 添加海报
export function AddShowBill(CouponKey, Data) {
  Data = {
    PosterInfo: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ActivityManagement.AddShowBill',
      CouponKey: CouponKey
    },
    method: 'POST',
    data: qs.stringify(Data)
  })
}
// 修改海报
export function EditShowBill(ShowBillKey, Data) {
  Data = {
    PosterInfo: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ActivityManagement.EditShowBill',
      ShowBillKey: ShowBillKey
    },
    method: 'POST',
    data: qs.stringify(Data)
  })
}
// 发布活动
export function PublishActivity(CouponKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WKTBack.ActivityManagement.PublishActivity',
      CouponKey: CouponKey
    },
    method: 'get'
  })
}
// 总部设置教学模式下背景图 和 Logo
export function CoursewareSaaSClientSetting(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'HQ.SaaSClientSet.CoursewareSaaSClientSetting'
    },
    method: 'post',
    data: qs.stringify(Data)
  })
}

// 添加学生线索
export function AddStudentThread(data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.AddStudentThread'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 招生工作台--学生登记
export function AddStudent(data) {
  data = {
    Data: JSON.stringify(data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.AddStudent'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
// 招生工作台--关联学生档案
export function GetStudentDetailList(MobileSupport) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.GetStudentDetailList',
      MobileSupport: MobileSupport // 顾客手机号
    },
    method: 'get'
  })
}
// 招生工作台--线索详情
export function StudentThreadDetail(StudentThreadKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.StudentThreadDetail',
      StudentThreadKey: StudentThreadKey // 线索ID
    },
    method: 'get'
  })
}

// 招生工作台--更新试课记录状态
export function UpdateStudentThread(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.UpdateStudentThread'
    },
    method: 'POST',
    data: qs.stringify(Data)
  })
}
// 招生工作台--关联学生档案
export function BindingStudentThread(
  StudentThreadKey,
  StudentKeyValue,
  StudentKey
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.BindingStudentThread',
      StudentThreadKey: StudentThreadKey, // 学生线索ID
      StudentKeyValue: StudentKeyValue, // 学生名
      StudentKey: StudentKey // 学生ID
    },
    method: 'get'
  })
}
// 学管师列表
export function GetTeacherAdviserList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.GetTeacherAdviserList'
    },
    method: 'get'
  })
}
// 获取正课生预警统计数据
export function GetRenewalWarningCount(TeacherKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.GetRenewalWarningCount',
      TeacherKey
    },
    method: 'get'
  })
}
// 获取正课生统计数据
export function GetActiveStudentCount(TeacherKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.GetActiveStudentCount',
      TeacherKey
    },
    method: 'get'
  })
}
// 学管师获取正课生列表
export function GetCourseStudentList(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.GetCourseStudentList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      TeacherKey: data.TeacherKey,
      StartTime: data.startTime,
      EndTime: data.endTime,
      Isformal: data.Isformal,
      StatusType: data.StatusType, // 1-续费预警 2-失效未续 3-出勤率预警 4-排课不足 5-长期未到校预警
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学管师获取续费跟进列表
export function GetRenewalXykdList(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.GetRenewalXykdList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      TeacherKey: data.TeacherKey,
      StartTime: data.startTime,
      EndTime: data.endTime,
      StatusType: data.StatusType, // 1-续费预警 2-失效未续 3-出勤率预警 4-排课不足 5-长期未到校预警
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学管师-不续课单
export function NotRenewXykd(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.NotRenewXykd',
      TableID
    },
    method: 'get'
  })
}
// 学管师获取续费记录列表
export function GetHaveRenewList(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.GetHaveRenewList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      TeacherKey: data.TeacherKey,
      StartTime: data.startTime,
      EndTime: data.endTime,
      StatusType: data.StatusType, // 1-续费预警 2-失效未续 3-出勤率预警 4-排课不足 5-长期未到校预警
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学管师获取不续列表
export function GetNotHaveRenewList(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.GetNotHaveRenewList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      TeacherKey: data.TeacherKey,
      StartTime: data.startTime,
      EndTime: data.endTime,
      StatusType: data.StatusType, // 1-续费预警 2-失效未续 3-出勤率预警 4-排课不足 5-长期未到校预警
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学管师获取排课考勤列表
export function GetArrangeStudentlist(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.GetArrangeStudentlist',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      TeacherKey: data.TeacherKey,
      StartTime: data.startTime,
      EndTime: data.endTime,
      StatusType: data.StatusType, // 1-续费预警 2-失效未续 3-出勤率预警 4-排课不足 5-长期未到校预警
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学管师获取考勤明细
export function GetStudentAttenceList(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.GetStudentAttenceList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText,
      TeacherKey: data.TeacherKey,
      StartTime: data.startTime,
      EndTime: data.endTime,
      StatusType: data.StatusType, // 1-续费预警 2-失效未续 3-出勤率预警 4-排课不足 5-长期未到校预警
      IsExportKey: data.IsExportKey // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 学管师获取排课出勤统计
export function GetScheduleAttendanceCount(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.GetScheduleAttendanceCount',
      StartTime: data.startTime,
      EndTime: data.endTime
    },
    method: 'get'
  })
}
// 获取课消结转统计
export function GetCourseXykdCount(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.GetCourseXykdCount',
      StartTime: data.startTime,
      EndTime: data.endTime
    },
    method: 'get'
  })
}
// 获取课单续费统计
export function getCourseRenewCount(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.getCourseRenewCount',
      StartTime: data.startTime,
      EndTime: data.endTime
    },
    method: 'get'
  })
}
// 学管师 数据汇总统计
export function getMonthActiveStudentCount(data, jsonFormate) {
  if (jsonFormate) {
    jsonFormate.client_id = store.getters.clientId
  }
  jsonFormate = {
    jsonFormate: JSON.stringify(jsonFormate)
  }
  return commonExportData({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.getMonthActiveStudentCount',
      StartTime: data.startTime,
      EndTime: data.endTime,
      IsExportKey: data.IsExportKey || 0 // 是否导出：0-否;1-是;如果为是pageIndex传0
    },
    method: 'POST',
    data: qs.stringify(jsonFormate)
  })
}
// 新增学管师
export function AddTheacherAdiser(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.AddTheacherAdiser',
      TeacherKey: data.OLAPKey
    },
    method: 'POST'
  })
}

// 学管师 分配正课生
export function AddAdiserStudent(TeacherKey, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.AddAdiserStudent',
      TeacherKey: TeacherKey
    },
    method: 'POST',
    data: qs.stringify(Data)
  })
}
// 学管师新增线索跟进记录
export function AddAdiserStudentFollow(TeacherKey, StudentKey, Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.AddAdiserStudentFollow',
      TeacherKey: TeacherKey,
      StudentKey: StudentKey
    },
    method: 'POST',
    data: qs.stringify(Data)
  })
}

// 招生工作台--获取学生线索跟单记录
export function GetFollowUpDetail(FollowUpKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.GetFollowUpDetail',
      FollowUpKey: FollowUpKey
    },
    method: 'get'
  })
}

// 招生工作台--添加学生线索跟单记录
export function AddStudentThreadFollowUp(Data) {
  Data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.AddStudentThreadFollowUp'
    },
    method: 'POST',
    data: qs.stringify(Data)
  })
}
// 招生工作台--获取学生线索跟单流水记录
export function GetFollowUpList(StudentThreadKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RecruitStudentWorkbench.GetFollowUpList',
      StudentThreadKey: StudentThreadKey
    },
    method: 'get'
  })
}
// 学管师- -获取学生线索跟单流水记录
export function GetStudentFollowUpList(StudentKey, TeacherKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.GetStudentFollowUpList',
      StudentKey: StudentKey,
      TeacherKey: TeacherKey
    },
    method: 'get'
  })
}

// 新增学管师
export function TransformationAnalysis(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrackingData.TransformationAnalysis',
      StartTime: data.StartTime,
      EndTime: data.EndTime
    },
    method: 'POST'
  })
}

export function SignUpCollectMoneyStatistics(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrackingData.SignUpCollectMoneyStatistics',
      StartTime: data.StartTime,
      EndTime: data.EndTime
    },
    method: 'POST'
  })
}

export function SaleNewAddThreadStatistics(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrackingData.SaleNewAddThreadStatistics',
      StartTime: data.StartTime,
      EndTime: data.EndTime
    },
    method: 'POST'
  })
}

export function SalePromiseVisitStatistics(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrackingData.SalePromiseVisitStatistics',
      StartTime: data.StartTime,
      EndTime: data.EndTime
    },
    method: 'POST'
  })
}

export function SaleTrialLessonStatistics(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrackingData.SaleTrialLessonStatistics',
      StartTime: data.StartTime,
      EndTime: data.EndTime
    },
    method: 'POST'
  })
}

export function SaleSignUpTransformationStatistics(data, SalesKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrackingData.SaleSignUpTransformationStatistics',
      StartTime: data.StartTime,
      EndTime: data.EndTime,
      SalesKey: SalesKey
    },
    method: 'POST'
  })
}

export function SaleSourceRateContrast(data, SalesKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrackingData.SaleSourceRateContrast',
      StartTime: data.StartTime,
      EndTime: data.EndTime,
      SalesKey: SalesKey
    },
    method: 'POST'
  })
}

export function SaleTransformationRateContrast(data, SalesKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrackingData.SaleTransformationRateContrast',
      StartTime: data.StartTime,
      EndTime: data.EndTime,
      SalesKey: SalesKey
    },
    method: 'POST'
  })
}

export function SalePromiseVisitRateContrast(data, SalesKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrackingData.SalePromiseVisitRateContrast',
      StartTime: data.StartTime,
      EndTime: data.EndTime,
      SalesKey: SalesKey
    },
    method: 'POST'
  })
}

export function SaleInviteTrialLessonRateContrast(data, SalesKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrackingData.SaleInviteTrialLessonRateContrast',
      StartTime: data.StartTime,
      EndTime: data.EndTime,
      SalesKey: SalesKey
    },
    method: 'POST'
  })
}
export function SaleTrialLessonRateContrast(data, SalesKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrackingData.SaleTrialLessonRateContrast',
      StartTime: data.StartTime,
      EndTime: data.EndTime,
      SalesKey: SalesKey
    },
    method: 'POST'
  })
}

export function SaleMakeABargainRateContrast(data, SalesKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TrackingData.SaleMakeABargainRateContrast',
      StartTime: data.StartTime,
      EndTime: data.EndTime,
      SalesKey: SalesKey
    },
    method: 'POST'
  })
}
// 获取学管师学生列表
export function GetAdiserStudentList(TeacherKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.GetAdiserStudentList',
      TeacherKey: TeacherKey
    },
    method: 'POST'
  })
}
// 学管师管理-删除学管师
export function DelTheacherAdiser(TeacherKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.DelTheacherAdiser',
      TeacherKey: TeacherKey
    },
    method: 'POST'
  })
}

export function CommonMessageSearchPageStudentThreadList(
  PageIndex,
  PageSize,
  SearchText
) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Common.MessageSearchPageStudentThreadList',
      pageIndex: PageIndex, // 每页记录数，0：表示查询所有
      pageSize: PageSize, // 页码，0为第一页
      searchText: SearchText //	搜索条件：模糊匹配姓名、昵称、学号、客户手机号、客户名 课
    },
    method: 'GET'
  })
}

export function RelevanceStudentByStudentThread(StudentThreadKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Common.RelevanceStudentByStudentThread',
      StudentThreadKey
    },
    method: 'GET'
  })
}

// 解锁ppt
export function UnlockPPT(params) {
  let serviceName = ''
  if (store.getters.SaaSClientEditionKey == 5) {
    serviceName = 'HQ.CoursewareManage.UnlockPPT'
  } else if (store.getters.SaaSClientEditionKey == 6) {
    serviceName = 'CWS.CoursewareManage.UnlockPPT' // 课件门店
  } else {
    serviceName = 'WEBV.Current_CoursewareManage.UnlockPPT' // 其它，特指教务门店
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: serviceName,
      password: params.password, // ppt只读密码
      client_id: params.client_id, // 长连接id
      AuthorizeCode: params.AuthorizeCode, // 授权码
      AttachKey: params.AttachKey // 附件key
    },
    method: 'get'
  })
}

// 通用大数据导出
export function commonExportData(options) {
  return new Promise((resolve, reject) => {
    // 严格模式
    fetchToken(options)
      .then(reuslt => {
        resolve(reuslt)
      })
      .catch(error => {
        if (error.code == '10010') {
          window.layer.alert(error.msg)
        } else {
          console.log('errorcommonExportData', error)
          reject(error)
        }
      })
  })
}
// 异步导出execl表列表
export function GetRemindWorkList(parameter) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RemindWork.GetRemindWorkList',
      pageIndex: parameter.pageIndex, // 页码
      pageSize: parameter.pageSize, // 每页记录数
      searchText: parameter.searchText, // 搜索条件
      orderBy: parameter.orderBy, // 排序字段
      sequence: parameter.sequence, // 排序方式
      DataTypeKey: parameter.DataTypeKey
    },
    method: 'GET'
  })
}
//
export function CancelRemindWork(OLAPKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RemindWork.CancelRemindWork',
      OLAPKey: OLAPKey
    },
    method: 'POST'
  })
}
// 修改表格个人设置
export function EditAgentSetting(parameter) {
  var data = {
    Data: JSON.stringify(parameter)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_Common.EditAgentSetting'
    },
    method: 'post',
    data: qs.stringify(data),
    checkResubmit: true
  })
}

// 获取员工设置
export function GetAgentSetting() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_Common.GetAgentSetting'
    },
    method: 'GET'
  })
}
// 是否有新的提醒事务
export function GetIndexIsRemindWorkInfo() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.RemindWork.GetIndexIsRemindWorkInfo'
    },
    method: 'GET'
  })
}

// 获取课评模板列表
export function getScAssessTemplate() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.ScAssessTemplate.getScAssessTemplate'
    },
    method: 'GET'
  })
}

// 获取课评模板列表
export function GetScAssessTemplatePageList(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Common.GetScAssessTemplatePageList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText
    },
    method: 'GET'
  })
}
// 获取课评模板维度详情
export function GetScAssessTemplateDetail(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Common.GetScAssessTemplateDetail',
      ID
    },
    method: 'GET'
  })
}

// 获取自研课包的课件
export function GetFontTemplateList(
  pageIndex,
  pageSize,
  searchText,
  orderBy,
  sequence,
  StatusKey,
  isSpec
) {
  let serviceName = ''
  if (store.getters.SaaSClientEditionKey == 5) {
    serviceName = 'HQ.CoursewareManage.GetFontTemplateList'
  } else if (store.getters.SaaSClientEditionKey == 6) {
    serviceName = 'CWS.CoursewareManage.GetFontTemplateList' // 课件门店
  } else {
    serviceName = 'WEBV.Current_CoursewareManage.GetFontTemplateList' // 其它，特指教务门店
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: serviceName,
      pageIndex: pageIndex,
      pageSize: pageSize,
      orderBy: orderBy,
      sequence: sequence,
      searchText: searchText,
      StatusKey: StatusKey,
      isSpec: isSpec
    },
    method: 'get'
  })
}

// 获取课评模板管理列表
export function GetTemplatePageList(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.CommentsManagement.GetTemplatePageList',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      orderBy: data.orderBy,
      sequence: data.sequence,
      searchText: data.searchText
    },
    method: 'GET'
  })
}
//获取课评模板维度详情
export function getTemplateDetail(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.CommentsManagement.getTemplateDetail',
      ID
    },
    method: 'GET'
  })
}
// 停用课评模板
export function DisableTemplate(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.CommentsManagement.DisableTemplate',
      ID
    },
    method: 'GET'
  })
}
// 启用课评模板
export function StartUsingTemplate(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.CommentsManagement.StartUsingTemplate',
      ID
    },
    method: 'GET'
  })
}
// 删除课评模板
export function DelTemplateDimension(ID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.CommentsManagement.DelTemplateDimension',
      ID
    },
    method: 'GET'
  })
}
//添加课评模板维度
export function AddTemplate(Data) {
  let data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.CommentsManagement.AddTemplate'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
//修改课评模板维度
export function EditTemplate(Data) {
  let data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'FSC.CommentsManagement.EditTemplate'
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
//考勤异常
export function GetAttendanceException(data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AcademicWorkbench.GetAttendanceException',
      StartTime: data.startTime,
      EndTime: data.endTime
    },
    method: 'GET'
  })
}
//报名记录统计
export function GetRegistrastionRecord() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AcademicWorkbench.GetRegistrastionRecord'
    },
    method: 'GET'
  })
}

//试课追销统计
export function GetTrialLessonTracking() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AcademicWorkbench.GetTrialLessonTracking'
    },
    method: 'GET'
  })
}
//补课管理统计
export function GetMakeUpMissedLessonsManageStatistics() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AcademicWorkbench.GetMakeUpMissedLessonsManageStatistics'
    },
    method: 'GET'
  })
}
// 约课表达成未达成统计
export function GetConcludeStatistics() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.AcademicWorkbench.GetConcludeStatistics'
    },
    method: 'GET'
  })
}
// 老师上课->获取老师本月月绩
export function GetTeachingPerformanceForThisMonth() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherReport.GetTeachingPerformanceForThisMonth'
    },
    method: 'GET'
  })
}

// APP->获取我的学生
export function GetTeacherStudentList() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherGiveLecture.GetTeacherStudentList'
    },
    method: 'GET'
  })
}
// 取消关联续费客单
export function CancelRelevantRenewXykd(TableID) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.TeacherAdviser.CancelRelevantRenewXykd',
      TableID: TableID
    },
    method: 'GET'
  })
}

// 获取客户端IP地址
export function GetClientIP() {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'CWS.CoursewareManage.GetClientIP'
    },
    method: 'GET'
  })
}

// 课时不足合并课单--课时课单列表
export function GetClassHourXYKDListByStudentKey(StudentKey,XYKDKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Current_XYKD.GetClassHourXYKDListByStudentKey',
      StudentKey,
      XYKDKey
    },
    method: 'GET'
  })
}


// 合并课时不足课单
export function MergeClassHourXYKD(Data) {
  let data = {
    Data: JSON.stringify(Data)
  }
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.Student.MergeClassHourXYKD',
    },
    method: 'post',
    data: qs.stringify(data)
  })
}
