
  <template>
  <!-- 收支表单 -->
  <div>
    <!-- 审核表单 -->
    <div class="single_result_detail"
         v-if="item.StatusKey==3">
      <div class="class_table_form form_info_edit form_info_list single_shadow">
        <form-input :formTitle="'收支流水'"
                    :formPlaceholder="' '"
                    :readonly="isReadonly"
                    v-model="item.SourceKeyName"></form-input>

        <div v-if="item.IncomePayTypeKey ==1">
          <form-input :formTitle="'收入金额'"
                      :formPlaceholder="' '"
                      :readonly="isReadonly"
                      :value="Number(item.InAmount).toFixed(2)"></form-input>
        </div>

        <div v-else-if="item.IncomePayTypeKey ==2">
          <form-input :formTitle="'支出金额'"
                      :formPlaceholder="' '"
                      :readonly="isReadonly"
                      :value="Number(item.PayAmount).toFixed(2)"></form-input>
        </div>
        <form-input-select :formTitle="'收支账户'"
                           :formPlaceholder="' '"
                           :readonly="isReadonly"
                           @showEldilog="formOptionEvent('GLMD')"
                           v-model="item.IncomePayAccountKeyValue"></form-input-select>
        <form-input-select :formTitle="'收支科目'"
                           :formPlaceholder="' '"
                           :readonly="isReadonly"
                           @showEldilog="formOptionEvent('GLMD')"
                           v-model="item.IncomePaySubKeyValue"></form-input-select>
        <form-input :formTitle="'发生人类型'"
                    :formPlaceholder="' '"
                    :readonly="isReadonly"
                    v-model="item.HapObjTypeKeyValue"></form-input>
        <div>
          <form-input :formTitle="'发生人'"
                      :formPlaceholder="' '"
                      :readonly="isReadonly"
                      v-model="item.HapPeolKeyValue"></form-input>
        </div>
        <form-input :formTitle="'发生来源'"
                    :formPlaceholder="' '"
                    :readonly="isReadonly"
                    :value="item.HapSourceKeyKeyValue"></form-input>
        <form-input :formTitle="'发生时间'"
                    :formPlaceholder="' '"
                    :readonly="isReadonly"
                    :value="item.StartTimeFrom"></form-input>
        <form-input :formTitle="'备注'"
                    :formPlaceholder="' '"
                    :readonly="isReadonly"
                    v-model="item.CommentSupport"></form-input>
      </div>
      <!-- 按钮组 -->
      <div class="footer_btn_group form_footer_btn_group">
        <el-button round
                   class="btn_light_red btn_hover_white"
                   :class="vHasModulePower(43)?'':'norole'"
                   @click="cancelAudit()">审核拒绝</el-button>
        <el-button round
                   class="btn_light_blue btn_hover_bg_blue"
                   :class="vHasModulePower(43)?'':'norole'"
                   @click="submitAudit()">审核通过</el-button>
      </div>

    </div>
    <!-- 处理表单 -->
    <div class="single_result_detail"
         v-else>
      <div class="class_table_form form_info_edit form_info_list single_shadow">
        <form-input :formTitle="'收支流水'"
                    :formPlaceholder="' '"
                    :readonly="isReadonly"
                    v-model="item.SourceKeyName"></form-input>

        <div v-if="item.IncomePayTypeKey ==1">
          <form-input :formTitle="'收入金额'"
                      :formPlaceholder="' '"
                      :readonly="isReadonly"
                      :value="Number(item.InAmount).toFixed(2)"></form-input>
        </div>

        <div v-else-if="item.IncomePayTypeKey ==2">
          <form-input :formTitle="'支出金额'"
                      :formPlaceholder="' '"
                      :readonly="isReadonly"
                      :value="Number(item.PayAmount).toFixed(2)"></form-input>
        </div>

        <div class="form_info_line form_info_required">
          <div class="form_info_field">收支账户</div>
          <div class="form_info_value form_info_select">
            <el-select :value="vIncomePayAccountKeyValue"
                       placeholder="必选"
                       @change="accountEvent"
                       value-key="OLAPKey">
              <el-option v-for="item in incomePayAccountList"
                         :key="item.OLAPKey"
                         :label="item.MainDemoName"
                         :value="item"></el-option>
            </el-select>
          </div>
        </div>
        <div v-if="item.IncomePayTypeKey ==1"
             c>
          <div class="form_info_line form_info_required">
            <div class="form_info_field">收支科目</div>
            <div class="form_info_value form_info_select">
              <el-select :value="vIncomePaySubKeyValue"
                         placeholder="必选"
                         @change="subjectEvent"
                         value-key="OLAPKey">
                <el-option v-for="item in subjectListS"
                           :key="item.OLAPKey"
                           :label="item.MainDemoName"
                           :value="item"></el-option>
              </el-select>
            </div>
          </div>
        </div>

        <div v-else-if="item.IncomePayTypeKey ==2">
          <div class="form_info_line form_info_required">
            <div class="form_info_field">收支科目</div>
            <div class="form_info_value form_info_select">
              <el-select :value="vIncomePaySubKeyValue"
                         placeholder="必选"
                         @change="subjectEvent"
                         value-key="OLAPKey">
                <el-option v-for="item in subjectListZ"
                           :key="item.OLAPKey"
                           :label="item.MainDemoName"
                           :value="item"></el-option>
              </el-select>
            </div>
          </div>
        </div>

        <form-input :formTitle="'发生人类型'"
                    :formPlaceholder="' '"
                    :readonly="isReadonly"
                    v-model="item.HapObjTypeKeyValue"></form-input>

        <div>
          <form-input :formTitle="'发生人'"
                      :formPlaceholder="' '"
                      :readonly="isReadonly"
                      :value="item.HapPeolKeyValue"></form-input>
        </div>

        <form-input :formTitle="'发生来源'"
                    :formPlaceholder="' '"
                    :readonly="isReadonly"
                    :value="item.HapSourceKeyKeyValue"></form-input>

        <form-input :formTitle="'发生时间'"
                    :formPlaceholder="' '"
                    :readonly="isReadonly"
                    :value="item.StartTimeFrom"></form-input>
        <form-input :formTitle="'备注'"
                    :formPlaceholder="' '"
                    v-model="item.CommentSupport"></form-input>
      </div>
      <!-- 按钮组 -->
      <div class="footer_btn_group form_footer_btn_group">
        <el-button round
                   class="btn_light_red btn_hover_white"
                   @click="cancelDispose()">作废</el-button>
        <el-button round
                   class="btn_light_blue btn_hover_bg_blue"
                   @click="submitDispose()">提交</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import formInputSelect from '../../form-item/form-input-select';
import formInput from '../../form-item/form-input';
import {
  getIncomeAccount,
  getIncomeSubject,
  AuditSZGL,
  DoSZGL,
  InvalidateSZGL
} from '../../../API/workbench.js';
export default {
  data () {
    return {
      isReadonly: true,
      incomePayAccountList: [], // 收支账户列表
      IncomePaySubjectList: [] // 收支科目列表
      // incomeAndexpensesItem: {
      //   InPayTypeKey: 1, // int--收支类型：1-收，2-支,
      //   InPayAccountKey: '', // int--收支账户key
      //   IncomePayAccount: '', // 绑定收支账户值
      //   InPaySubKey: '', // int--收支科目key
      //   IncomePaySubject: '' // 绑定收支科目值
      // }
    };
  },
  props: {
    item: Object // 收支对象.
  },
  components: {
    formInputSelect,
    formInput
  },
  created () {
    this.getConnectorData();
  },
  watch: {},
  computed: {
    // 过滤收科目(收)
    subjectListS () {
      var SubjectResult = [];
      this.IncomePaySubjectList.map(item => {
        if (item.IncomePayTypeKey == 1) {
          SubjectResult.push(item);
        }
      });
      return SubjectResult;
    },
    // 过滤支科目(支)
    subjectListZ () {
      var SubjectResult = [];
      this.IncomePaySubjectList.map(item => {
        if (item.IncomePayTypeKey == 2) {
          SubjectResult.push(item);
        }
      });
      return SubjectResult;
    },
    vIncomePaySubKeyValue () {
      return this.item.IncomePaySubKey == 0
        ? ''
        : this.item.IncomePaySubKeyValue;
    },
    vIncomePayAccountKeyValue () {
      return this.item.IncomePayAccountKey == 0
        ? ''
        : this.item.IncomePayAccountKeyValue;
    }
  },
  methods: {
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      console.log(this.$store.getters.RolePowerList, '判断权限');
      return this.$utils.CheckModulePower(this.$store.getters.RolePowerList, ModulePowerKey);
    },
    formOptionEvent (q) {
      console.log('选择');
    },
    // 选中账户
    accountEvent (obj) {
      console.log(obj, '收支账户');
      this.item.IncomePayAccountKeyValue = obj.MainDemoName;
      this.item.IncomePayAccountKey = obj.OLAPKey;
    },
    // 选中科目
    subjectEvent (obj) {
      console.log(obj, '收支科目');
      this.item.IncomePaySubKeyValue = obj.MainDemoName;
      this.item.IncomePaySubKey = obj.OLAPKey;
    },
    // 收支录入-数据源
    getConnectorData () {
      // 获取收支账户接口
      getIncomeAccount().then(result => {
        if (result.code == 0) {
          console.log('getIncomeAccount', result.data);
          this.incomePayAccountList = result.data;
        } else {
          layer.alert('接口异常，注意检查');
        }
      });
      // 获取收支科目接口
      getIncomeSubject().then(result => {
        if (result.code == 0) {
          this.IncomePaySubjectList = result.data;
          this.IncomePaySubjectList.forEach(o => { });
        } else {
          layer.alert('接口异常，注意检查');
        }
      });
    },
    // 审核通过
    submitAudit () {
      if (this.vHasModulePower(43)) {
        var _this = this;
        layer.confirm('请确定是否审核通过?', {
          btn: [
            {
              name: '确认',
              callBack: function () {
                layer.close();
                // _this.$parent.isIncomeAndexpenses = false;
                AuditSZGL(_this.item.TableID, 1).then(
                  result => {
                    _this.$emit('passHide');
                  },
                  error => {
                    layer.alert(error.msg);
                  }
                );
              }
            }
          ]
        });
      } else {
        this.$utils.CheckModulePowerToTips(43);
      }
    },
    // 审核拒绝
    cancelAudit () {
      if (this.vHasModulePower(43)) {
        var _this = this;
        layer.confirm('请确定是否审核拒绝?', {
          btn: [
            {
              name: '确认',
              callBack: function () {
                layer.close();
                AuditSZGL(_this.item.TableID, 0).then(
                  result => {
                    _this.$emit('passHide');
                  },
                  error => {
                    layer.alert(error.msg);
                  });
              }
            }
          ]
        });
      } else {
        this.$utils.CheckModulePowerToTips(43);
      }
    },
    // 处理通过
    submitDispose () {
      if (this.item.IncomePayAccountKey <= 1) {
        layer.alert('请选择您的收支账户');
      } else if (this.item.IncomePaySubKey <= 1) {
        layer.alert('请选择您的收支科目');
        // 验证成功
      } else {
        var _this = this;
        // layer.confirm('请确认是否提交?', {
        //   btn: [
        //     {
        //       name: '确认',
        //       callBack: function () {
        //         layer.close();
        DoSZGL(
          _this.item.TableID,
          _this.item.IncomePaySubKey,
          _this.item.IncomePaySubKeyValue,
          _this.item.IncomePayAccountKey,
          _this.item.IncomePayAccountKeyValue,
          _this.item.CommentSupport
        ).then(
          result => {
            layer.alert('提交成功');
            _this.$emit('passHide');
          },
          error => {
            layer.alert(error.msg);
          }
        );
        //       }
        //     }
        //   ]
        // });
      }
    },
    // 处理作废
    cancelDispose () {
      var _this = this;
      layer.confirm('请确认是否作废?', {
        btn: [
          {
            name: '确认',
            callBack: function () {
              layer.close();
              if (_this.item.HapSourceKeyKey != 5) {
                // 不是 人工收支，不可作废
                layer.alert(
                  '仅限人工收支单据,' +
                  _this.item.HapSourceKeyKeyValue +
                  '单据不支持作废。'
                );
              } else {
                InvalidateSZGL(_this.item.TableID).then(
                  result => {
                    _this.$emit('passHide');
                  },
                  error => {
                    layer.alert(error.msg);
                  }
                );
              }
            }
          }
        ]
      });
    }
  }
};
</script>

<style>
.string {
  border-bottom: 1px solid #f2f2f2;
}
</style>

