<template>
  <!-- 单位文本 -->
  <div class="form_list_input_box">
    <show-input :inputStyle="'width: 178px;'"
                :inputClass="'form_list_input'"
                :item="item"></show-input>
    <div class="form_list_input_unit">{{item.Unit}}</div>
  </div>
</template> 
<script>
import showInput from './show-input';
export default {
  components: {
    showInput
  },
  data () {
    return {

    };
  },
  props: {
    categoryItem: Object, // 类目项
    item: Object // 字段项
  },
  created () {
    // console.log('单行文本:', this.categoryItem, this.item);
  },
  computed: {

  },
  methods: {

  }
};
</script>

<style>
</style>