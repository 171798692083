<template>
  <div class="retailManagement chainstore_manage_view all_view">
    <head-summarizing ref="refHeadSummarizing"></head-summarizing>
    <search-box-bar :params="params"
                    @addCampus="addClick"
                    @loadTableData="loadAfterTableData"></search-box-bar>

    <retail-content-item ref="retailContent"
                         v-for="(item,index) in retailmdList"
                         :dataInfo="item"
                         :ranking="index+1"
                         :key="index"
                         @doViewBtn="viewClick"
                         @doEditBtn="editClick"
                         @afterSuccess="getSearchPageForRetailStores"></retail-content-item>

    <div v-if="retailmdList.length == 0">
      <div class="monitoring_content_nodata"
           style="height: 440px;border-radius: 8px;padding-top: 200px;background: #fff;">
        <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
      </div>
    </div>
    <custom-dialog :title="titleName+'直营校区'"
                   :visible.sync="isShowParticipateCampusFormPopup"
                   :before-close="closeParticipateCampusFormPopup">
      <retail-campus-edit-form :CampusKey="Number(dataInfo.OLAPKey)"
                               :isReadonly="titleName=='查看'?true:false"
                               :titleName="titleName"
                               @close="closeParticipateCampusFormPopup"
                               @afterSuccess="afterSuccess"></retail-campus-edit-form>
    </custom-dialog>
    <div>
      <dialog-factory ref="retailManagementDialogFactory"
                      :routerName="'retailManagement'"></dialog-factory>
      <dialog-factory-common ref="dialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>

<script>
import { SearchPageForRetailStores, RetailSearchAuthorizeCourseware } from '../../../../API/workbench.js';
import headSummarizing from './head-summarizing';
import searchBoxBar from './search-box-bar';
import retailContentItem from './retail-content-item';
import retailCampusEditForm from './form/retail-campus-edit-form';
import dialogFactory from '../../../common/dialog-factory';
export default {
  name: 'retailManagement',
  components: {
    headSummarizing,
    searchBoxBar,
    retailContentItem,
    retailCampusEditForm,
    dialogFactory
  },
  data () {
    return {
      isShowParticipateCampusFormPopup: false,
      isShowPaymentForm: false,
      // 直营管理
      params: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: '' //	字符串	可选		数据源：GET	排序顺序
      },
      retailmdList: [], // 直营列表
      PageCount: 0,
      totalNum: 0,
      expandRowData: [],
      dataInfo: {},
      titleName: '添加'
    };
  },
  created () {
    this.registerBusEvent();
  },
  activated () {
    this.getSearchPageForRetailStores();
  },
  mounted () {

  },
  methods: {
    afterSuccess () {
      this.$refs.refHeadSummarizing.initSummaryData();
      this.loadAfterTableData();
    },
    // 直营管理-分页查询
    getSearchPageForRetailStores () {
      SearchPageForRetailStores(this.params).then(result => {
        this.retailmdList = [];
        this.totalNum = result.data.Total;
        this.retailmdList = result.data.PageDataList;
        console.log(result.data.PageDataList, '直营管理-分页查询');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 条件查询
    loadAfterTableData (queryParams) {
      if (queryParams) {
        Object.assign(this.params, queryParams);
      }
      if (this.$refs.retailContent) {
        this.$refs.retailContent.forEach(o => {
          o.isShowCourseware = false;
        });
      }
      console.log(this.$refs.retailContent, '条件查询');
      this.getSearchPageForRetailStores();
    },
    // 新增
    addClick (type) {
      this.titleName = '添加';
      this.dataInfo = {};
      this.isShowParticipateCampusFormPopup = true;
    },
    // 修改
    editClick (rowData) {
      this.titleName = '修改';
      this.dataInfo = rowData;
      console.log(rowData, '直营管理-修改');
      this.isShowParticipateCampusFormPopup = true;
    },
    // 查看
    viewClick (rowData) {
      this.titleName = '查看';
      this.dataInfo = rowData;
      console.log(rowData, '直营管理-修改');
      this.isShowParticipateCampusFormPopup = true;
    },
    // 授权课包数
    getAuthorizeCoursewareCountDetial (rowData, callBack) {
      RetailSearchAuthorizeCourseware(rowData.OLAPKey).then(result => {
        if (result.data) {
          this.expandRowData = result.data.PageDataList;
          if (callBack) {
            callBack(this.expandRowData || []);
          }
          console.log(this.expandRowData, 'result.data.PageDataList-可用课时');
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 关闭
    closeParticipateCampusFormPopup () {
      this.isShowParticipateCampusFormPopup = false;
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'retailManagement') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        console.log(dialogInfo, 'openCommonCustomDialog');
        if (dialogInfo.routerName == 'retailManagement') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.retailManagementDialogFactory) {
        this.$refs.retailManagementDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.dialogFactoryCommon) {
        this.$refs.dialogFactoryCommon.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    }
  }
};
</script>
