<template>
  <div>
    <div class="content_marginRL content_marginTop_30 single_shadow overflow_hidden " :class="{btn_marginBottom_30:isReadonly}">
      <div class="mendian_setting_content">
        <div class="block_switch clearfix">
          <div class="fl"
               style="font-size: 20px;">第{{remindClassEarlyType==2?'二':'一'}}次提醒 </div>
          <el-switch :value="isAllShow"
                     :width="86"
                     class="fr"
                     active-color="#3498DB"
                     inactive-color="#3498DB"
                     @change="doElSwitch(isAllShow)">
          </el-switch>
          <!-- </div> -->
        </div>

        <div v-if="isAllShow">
          <div class="form_info_edit">
            <form-input :min="0"
                        class="no_content"
                        :readonly="isReadonly"
                        v-model="classEarlyACountSelect.amount"
                        :decimalPlaces="0"
                        :formTitle="'发送时间: 上课前'"
                        :formPlaceholder="' '"
                        :required="false"
                        :numberVerify="true"></form-input>
          </div>

          <div class="form_info_edit">
            <form-select :formTitle="'单位'"
            class="form_info_color"
                         :readonly="isReadonly"
                         :formPlaceholder="' '"
                         style="border-bottom:0px"
                         :dataItem="unitsValue"
                         :dataList="unitsSelect"
                         :value="unitsValue"
                         @choseItem="doSelectSwitchUnits"></form-select>
          </div>
        </div>

      </div>
    </div>
    <!-- 按钮组 -->
    <div v-if="!isReadonly"
         class="">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="doCloseShowForm"></save-cancel-btn-group>
    </div>
    <div v-else class="on_form_footer_btn_group"></div>
  </div>
</template> 

<script>
import formSelect from '../../../../form-item/form-select';
export default {
  data () {
    return {
      isAllShow: this.remindClassEarlyCount > 0,
      unitsValue: this.remindClassEarlyUnits || '分钟',
      classEarlyACountSelect: {
        amount: this.remindClassEarlyCount || 0,
        units: '分钟'
      },
      switchSelect: [
        { key: 1, value: '开启' },
        { key: 0, value: '关闭' }
      ],
      unitsSelect: [
        { key: 1, value: '小时' },
        { key: 0, value: '分钟' }
      ]
    };
  },
  props: {
    isReadonly: {
      // 是否只读
      type: Boolean,
      default: false
    },
    selectEventId: {
      // 事件ID
      type: Number,
      default: 0
    },
    remindClassEarlyType: Number, // 类型
    remindClassEarlyCount: { // 时间
      type: Number,
      default: 0
    },
    remindClassEarlyUnits: String // 单位
  },
  components: {
    formSelect
  },

  created () {
    if (this.classEarlyACountSelect.amount % 60 == 0) {
      this.classEarlyACountSelect.amount = this.classEarlyACountSelect.amount / 60;
      this.classEarlyACountSelect.units = '小时';
      this.unitsValue = '小时';
    }
    console.log(this.remindClassEarlyUnits, '单位');
  },
  mounted () {

  },
  activated () {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    doElSwitch (e) {
      this.isAllShow = !this.isAllShow;
      console.log(this.isAllShow, 'doElSwitch');

      if (this.isAllShow) {
        this.classEarlyACountSelect.amount = this.remindClassEarlyCount;
      } else { // 关闭默认显示
        this.classEarlyACountSelect.amount = 0;
        // this.classEarlyACountSelect.units = '分钟';
      }
    },
    doSelectSwitchUnits (item) {
      this.unitsValue = item.value;
      console.log(item, '单位');
    },
    // 确定按钮
    confirmClick (callBack) {
      this.classEarlyACountSelect.units = this.unitsValue;
      this.$emit('doConfirmClick', this.classEarlyACountSelect);
      if (callBack) {
        callBack();
      }
    },
    doCloseShowForm () {
      this.$emit('doCloseShowForm');
    }
  }

};
</script>

  <style>
.block_switch {
  line-height: 48px;
  border-bottom: 1px solid #ececec;
}
</style>