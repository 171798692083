<template>
  <!-- 复制课表 -->
  <div>
    <module-description :textTips="'将指定日期的课复制到另一日期'"
                        :btnTips="'查看教程'"
                        :btnUrl="'https://mp.weixin.qq.com/s/nv94va2TOFasLisMh7uQTw'"></module-description>
    <div class="form_info_edit form_info_list">
      <input-dialog :formTitle="'复制范围'"
                    :formPlaceholder="'请选择'"
                    :required="true"
                    v-model="classInfo.MainDemoName"
                    @showEldilog="chgAdjustScope()"></input-dialog>
      <div :class="{'required_position':startWeek.length > 0}">
        <input-date v-model="startDate"
                    :required="true"
                    :elementId="'copyADate'"
                    :formTitle="'复制来源日期'"
                    :helpDescription="''"
                    :minDate="minDate"
                    :formPlaceholder="'请选择'"
                    @changeDate="startDateEvent">
        </input-date>
        <span class="week_select">{{startWeek}}</span>
      </div>
      <div :class="{'required_position':endWeek.length > 0}">
        <input-date v-model="endDate"
                    :required="true"
                    :elementId="'copyBDate'"
                    :formTitle="'复制目的日期'"
                    :helpDescription="''"
                    :minDate="minDate"
                    :formPlaceholder="'请选择'"
                    @changeDate="endDateEvent">
        </input-date>
        <span class="week_select">{{endWeek}}</span>
      </div>
      <!-- <div class="pr">
        <input-switch v-model="IsNotify"
                      :formTitle="'是否发送家校通知'"
                      style="padding-right: 23px;"
                      :readonly="false"
                      :required="false"></input-switch>
      </div> -->
      <input-text :formTitle="'处理原因'"
                  :readonly="false"
                  :formPlaceholder="'请输入'"
                  :required="false"
                  v-model="Remarks"></input-text>
    </div>
    <!-- 按钮组 -->
    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="affirmSave"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>

<script>
import { CopyScheduleCourse, SearchClass } from '../../../../API/workbench.js';
export default {
  data () {
    return {
      startDate: '', // 复制来源日期
      endDate: '', // 复制目的日期
      startWeek: '', // 复制来源周
      endWeek: '', // 复制目的周
      Remarks: '', // 处理原因--备注
      classInfo: { MainDemoName: '全校', OLAPKey: '0' },
      IsNotify: 0,	// 		0 不通知 1通知
      minDate: Date.new().getFullYear() + '-' + (Date.new().getMonth() + 1) + '-' + (Date.new().getDate() + 1)
    };
  },
  created () {

  },
  methods: {
    // 复制范围
    chgAdjustScope () {
      SearchClass().then(result => {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择班级',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.classInfo.OLAPKey, dataSourceList: result.data, required: false },
          callBack: {
            onSelectedItem: (data) => {
              console.log(data);
              if (data.OLAPKey > 0) {
                this.classInfo.OLAPKey = data.OLAPKey;
                this.classInfo.MainDemoName = data.MainDemoName;
              } else {
                this.classInfo.OLAPKey = 0;
                this.classInfo.MainDemoName = '全校';
              }
            }
          }
        });
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 选择复制来源日期
    startDateEvent (date) {
      this.$nextTick(() => {
        if (date) {
          date = date.replace(/\./g, '/');
          this.startDate = date;
          this.startWeek = this.getWeekVal(date);
        } else {
          this.startWeek = '';
        }
      });
    },
    // 选择复制目的日期
    endDateEvent (date) {
      this.$nextTick(() => {
        if (date) {
          date = date.replace(/\./g, '/');
          this.endDate = date;
          this.endWeek = this.getWeekVal(date);
        } else {
          this.endWeek = '';
        }
      });
    },
    // 获取周
    getWeekVal (date) {
      let weekVal = '周' + '日一二三四五六'.charAt(Date.new(
        date.slice(0, 4),
        date.slice(5, 7) - 1,
        date.slice(8, 10)
      ).getDay());
      return weekVal;
    },
    // 确定按钮事件触发
    affirmSave (callBack) {
      let that = this;
      let flag = false;
      if (!this.startDate || !this.endDate) {
        layer.alert('请检查是否完善日期');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      layer.confirm('注意，复制目的日期的指定范围内原有的课会全部停课，是否继续？', {
        btn: [{
          name: '取消',
          callBack: function () {
            layer.close();
            if (callBack) {
              callBack();
            }
          }
        }, {
          name: '确认',
          callBack: function () {
            layer.close();
            CopyScheduleCourse(that.classInfo.OLAPKey, that.startDate, that.endDate, that.Remarks).then(result => {
              layer.alert('复制成功');
              that.cancel();
              that.$bus.emit('AfterHolidaysStopCourse', that.startDate, that.endDate); // 成功后通知到考勤管理模块刷新数据
            }, error => {
              if (error.code == 70003) {
                layer.confirm(error.msg, {
                  btn: [
                    {
                      name: '确认',
                      callBack: () => {
                        that.affirmSave(callBack, 0);
                        layer.close();
                      }
                    }
                  ]
                });
              } else {
                layer.alert(error.msg);
              }
            }).finally(() => {
              if (callBack) {
                callBack();
              }
            });
          }
        }]
      });
    },
    // 取消按钮事件触发
    cancel () {
      this.$emit('cancel');
    }
  }
};
</script>

<style scoped>
.required_position {
  height: 47px;
}
.week_select {
  position: relative;
  left: 320px;
  top: -31px;
  color: #3498db;
}
</style>

