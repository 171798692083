<template>
  <div class="apply_class_summarizing">
    <!-- 几个报表头 -->
    <div class="pr single_shadow">
      <div class="apply_class_summarizing_nav font_black">
        <el-menu
          :default-active="menuActiveIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="menuSwitch">
          <el-menu-item index="1">在校学生</el-menu-item>
          <el-menu-item index="2">全部课程</el-menu-item>
          <el-menu-item index="3">全部套餐</el-menu-item>
        </el-menu>
      </div>
      <div class="apply_class_summarizing_number pa" v-if="menuActiveIndex==menuTitles.package">
        可用套餐：
        <span class="font_black">{{packageListLength}}</span>
      </div>
    </div>
    <!-- 学生 -->
    <div v-if="menuTitles.student==menuActiveIndex">
      <student-management-report></student-management-report>
    </div>
    <!-- 课程 -->
    <div v-else-if="menuTitles.course==menuActiveIndex">
      <course-name-apply-course></course-name-apply-course>
    </div>
    <!-- 套餐 -->
    <div v-else-if="menuTitles.package==menuActiveIndex">
      <package-apply-course ref="packageApplyCourse"></package-apply-course>
    </div>
  </div>
</template>
<script>
import studentManagementReport from '../student-management/index';
import courseNameApplyCourse from '../course-name-apply-course/index';
import packageApplyCourse from '../package-apply-course/index';
export default {
  data () {
    return {
      menuActiveIndex: '1',
      menuTitles: {
        // 几个菜单选项卡的title集
        student: '1',
        course: '2',
        package: '3'
      },
      packageListLength: 0
    };
  },
  components: {
    studentManagementReport,
    courseNameApplyCourse,
    packageApplyCourse
  },
  created () {},
  methods: {
    // 切换几个tab
    menuSwitch (val) {
      this.menuActiveIndex = val;
      // if (this.menuActiveIndex == this.menuTitles.package) {
      //   setTimeout(() => {
      //     this.packageListLength = this.$refs.packageApplyCourse.packageList.length;
      //   }, 200);
      // }
    }
  }
};
</script>
<style>
</style>