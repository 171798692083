<template>
  <div class="port_apply_summarizing"
         style="padding-top:15px">
    <div class="table_list_content summarizing_list"
         style="padding-bottom: 40px">
      <table-list ref="tableListRef"
                  :tableData="vDataList"
                  :tableColumns="tableColumns"
                  :rowKey="'Number'"
                  :totalNum="totalNum"
                  :queryParams="params"
                  :footerContent="'共' + totalNum + '条数据'"
                  @loadTableData="getDataList"></table-list>
    </div>

  </div>
</template>
<script>
import { GetMonitorList,GetMonitorInfo} from '../../../API/workbench';
import tableList from '../../common/table-list/index';
export default {
  data () {
    return {
      tableColumns: [
        {
          label: '序号',
          width: 50,
          prop: 'Number',
          type: 'number-item',
          sortable: false
        },
         {
          label: '时间',
          prop: 'GenerateTime',
          width: 150,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        // {
        //   label: '总部门店名称',
        //   prop: 'HQClientKeyValue',
        //   align: 'left',
        //   width: 140,
        //   type: 'text-item',
        //   sortable: false
        // },
        {
          label: '校区门店名称',
          prop: 'SaaSClientKeyValue',
          width: 140,
          type: 'text-item',
          sortable: false
        },
        {
          label: '员工名',
          prop: 'AgentKeyValue',
          width: 100,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '类型',
          prop: 'TypeKeyValue',
          width: 90,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '使用课件包名-课件',
          prop: 'CoursewarePackageKeyValue',
          width: 250,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '触发结果',
          prop: 'result',
          minWidth: 155,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
       
       
      ],
      totalNum: 0,
      dataList: [],
      params: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: 'DESC' //	字符串	可选		数据源：GET	排序顺序
      }
    };
  },
  components: {
    tableList
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          OLAPKey: ''
        };
      }
    }
  },
  created () {
    console.log('createddataInfo', this.dataInfo);
    if(this.dataInfo&&this.dataInfo.ID){
      this.params.pageSize=0
    }else{
      this.params.pageSize=10
    }
    this.getDataList();
  },
  computed: {
    vSaaSClientEditionKey () {      // 6为课件门店,5为总店
      return this.$store.getters.SaaSClientEditionKey;
    },
    vDataList(){
      let newArr=[]
      this.dataList.forEach(o=>{
        o.Number=o.Number||1
        o.GenerateTime=o.GenerateTime||''
        o.GenerateTime=o.GenerateTime.substring(0,16)
        newArr.push(o)
      })
      return newArr
    }
  },
  methods: {
    getDataInfo(){
      GetMonitorInfo(this.dataInfo.ID,1).then(result => {
        this.totalNum = 1
        this.dataList=[]
        console.log("GetMonitorInfo",result.data)
        this.$emit('afterSuccess')
        this.dataList.push(result.data)
      
      }, error => {
        layer.alert(error.msg);
       });
    },
    // 分页查询校区端口申请记录
    getDataList (queryParams) {
      if (queryParams) {
        Object.assign(this.params, queryParams);
      }
      let IsHQClientKey=1
      if(this.dataInfo&&this.dataInfo.ID>0){
        this.getDataInfo()
      }else{
        GetMonitorList(IsHQClientKey,this.params).then(result => {
          this.totalNum = result.data.Total;
          this.dataList = result.data.PageDataList;
  
          console.log('SearchAuthorizedDeviceList', result.data);
        }, error => {
          layer.alert(error.msg);
        });
      }
    },
  }
};
</script>
<style scoped>
.summarizing_list.table_list_content >>> .el-table thead tr th .cell.font_dual {
  display: flex;
  text-align: center;
  align-items: center;
}
.summarizing_list
  >>> .el-table
  .el-table__body-wrapper
  tr
  td:nth-of-type(3)
  .cell {
  width: 155px !important;
}
.summarizing_list
  >>> .el-table
  .el-table__body-wrapper
  tr
  td:nth-of-type(5)
  .cell {
  width: 160px !important;
}
</style>