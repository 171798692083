<template>
  <!-- 条件搜索 -->
  <div class="flex">
    <div class="course_statistics_title">
      <div class="table_select_box">
        <div class="table_select_title">选择时间</div>
        <div class="title_date_select">
          <div class="el-select">
            <single-year-picker @chgDate="changeSaveDate"
                                ref="monthPicker"
                                :type="'month'"></single-year-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="table_select_box">
      <div class="table_select_title">选择校区</div>
      <el-select :value="vSchoolKeyValue"
                 filterable
                 placeholder="必选"
                 value-key="SchoolKey"
                 @change="doRecordSelectedStatus">
        <el-option v-for="item in campusList"
                   :key="item.SchoolKey"
                   :label="item.SchoolKeyValue"
                   :value="item"></el-option>
      </el-select>
    </div>
    <!-- 已选条件 -->
    <div class="table_select_box">
      <input-search-contain-btn v-model="searchText"
                                style="margin: 10px;"
                                :width="'280px'"
                                :placeholder="'请输入'"
                                @clearSearch="clearSearch"
                                @onSearchEvent="search"></input-search-contain-btn>
    </div>
  </div>
</template>
<script>
import inputSearchContainBtn from '../../../report/common/input-search-contain-btn';
import singleYearPicker from '../../../base-module/date-picker-common/single-year-picker';
import { hqSelectSchoolForInspector } from '../../../../API/workbench.js';
export default {
  data () {
    return {
      searchText: '',
      campusList: []
    };
  },
  props: {
    queryParams: Object
  },
  components: {
    singleYearPicker,
    inputSearchContainBtn
  },
  computed: {
    vSchoolKeyValue () {
      return this.queryParams.SchoolKey ? this.queryParams.SchoolKeyValue : '全部';
    }
  },
  watch: {

  },
  created () {
    this.getSelectSchoolForInspector();
  },
  methods: {
    // 获取有效校区列表
    getSelectSchoolForInspector () {
      hqSelectSchoolForInspector().then(result => {
        console.log('获取有效校区列表', result.data);
        this.campusList = result.data || [];
        this.campusList.unshift({ SchoolKeyValue: '全部', SchoolKey: '' });
        if (this.queryParams.SchoolKey > 0) {
          let findItem = this.campusList.find(o => {
            return o.SchoolKey == this.queryParams.SchoolKey;
          });
          this.queryParams.SchoolKeyValue = findItem.SchoolKeyValue;
          this.queryParams.SchoolKey = findItem.SchoolKey;
        } else {
          this.queryParams.SchoolKeyValue = this.campusList[0].SchoolKeyValue;
          this.queryParams.SchoolKey = this.campusList[0].SchoolKey;
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 选择时间
    changeSaveDate (dataInfo, startTime, endTime) {
      console.log(dataInfo, startTime, endTime, '选中状态');
      this.queryParams.StartDate = startTime.replace(/\//g, '-');
      this.queryParams.EndDate = endTime.replace(/\//g, '-');
      this.$emit('searchValue', this.queryParams);
    },
    // 选择校区
    doRecordSelectedStatus (obj) {
      console.log(obj, '选中状态');
      this.queryParams.SchoolKeyValue = obj.SchoolKeyValue;
      this.queryParams.SchoolKey = obj.SchoolKey;
      this.$emit('searchValue', this.queryParams);
    },
    // 搜索
    search () {
      this.queryParams.searchText = this.searchText;
      this.$emit('searchValue', this.queryParams);
    },
    // 清除条件
    clearSearch () {
      this.searchText = '';
      this.$refs.monthPicker.cleanTime();
      this.$emit('clearSearchParams');
    }
  }
};
</script>
<style>
</style>

