<template>
  <div class="modulebox clearfix">
    <div class="chartbox">
      <div class="tit">透视出勤不足班级<span @click="setup">设置</span></div>
      <echart-pie :echartID="'echart4'"
                  ref="echart4"
                  :dataList="echartList"></echart-pie>
    </div>
    <div class="listbox">
      <div class="roomlist clearfix">
        <div v-for="(item, index) in echartList"
             :key="index"
             class="block"
             :class="search.TypeKey==index+1 ? 'cur' : ''"
             @click="roomClick(index)">
          <div>{{item.name}}</div>
          <p>{{item.value}}班</p>
        </div>
        <a class="btn btn_3"
           @click="doExportInfoClick">导出</a>
      </div>
      <el-table :data="tableData">
        <el-table-column prop="MainDemoName"
                         label="班名"
                         width="110"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="ScheduleCourseLvValue"
                         label="综合出勤率"
                         width="110"
                         show-overflow-tooltip></el-table-column>
        <el-table-column label="循环课"
                         width="290"
                         class-name="custombox">
          <template slot-scope="scope">
            <div class="block"
                 v-for="(i,index) in scope.row.newCourseArr"
                 :key="index">{{i.WeekKeyValue}} | {{i.ClassTimeName}} | {{i.CourseNameKeyValue}} | {{i.MTeacherKeyValue}}</div>
          </template>
        </el-table-column>
        <el-table-column label="排课课次"
                         width="80"
                         class-name="custombox">
          <template slot-scope="scope">
            <div class="block"
                 v-for="(i,index) in scope.row.newCourseArr"
                 :key="index">{{i.ArrangeCourseCount}}</div>
          </template>
        </el-table-column>
        <el-table-column label="排课人次"
                         width="80"
                         class-name="custombox">
          <template slot-scope="scope">
            <div class="block"
                 v-for="(i,index) in scope.row.newCourseArr"
                 :key="index">{{i.ArrangedNumCount}}</div>
          </template>
        </el-table-column>
        <el-table-column label="平均出勤"
                         width="80"
                         class-name="custombox">
          <template slot-scope="scope">
            <div class="block"
                 v-for="(i,index) in scope.row.newCourseArr"
                 :key="index">{{Number(i.AverageCount)}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="Attendance"
                         label="出勤率"
                         width="80"
                         class-name="custombox">
          <template slot-scope="scope">
            <div class="block"
                 v-for="(i,index) in scope.row.newCourseArr"
                 :key="index">{{i.Attendance}}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         class-name="custombox">
          <template slot-scope="scope">
            <div class="block"
                 v-for="(i,index) in scope.row.newCourseArr"
                 :key="index">
              <a class="font_blue button"
                 @click="openDetail(i)">详情</a>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChange"
                     @current-change="currentChange"
                     :current-page="search.pageIndex"
                     :page-sizes="[5,10,20,30,40,50]"
                     :page-size="search.pageSize"
                     layout="total,prev,pager,next,jumper,sizes"
                     :total="totalNum"
                     background></el-pagination>
    </div>
    <custom-dialog title="班内学生出勤汇总统计"
                   width="1200px"
                   :visible.sync="isShowDetail"
                   :before-close="closeDetail">
      <attendance-class-detail :classkeys="dataDetail"></attendance-class-detail>
    </custom-dialog>
  </div>
</template>

<script>
import { GetSaaSClientMsg, GetGradeAttenceDataCount, GetGradeAttenceDataList } from '../../../API/workbench';
import echartPie from './echart-pie';
import attendanceClassDetail from './attendance-class-detail';
export default {
  data () {
    return {
      echartList: [
        {
          value: 0,
          name: '低出勤',
          itemStyle: { color: '#FE9647' }
        },
        {
          value: 0,
          name: '中出勤',
          itemStyle: { color: '#519CF7' }
        },
        {
          value: 0,
          name: '高出勤',
          itemStyle: { color: '#7BE070' }
        }
      ],
      search: {
        TypeKey: 1,
        pageIndex: 1,
        pageSize: 5
      },
      tableData: [],
      totalNum: 0,
      PageCount: 0,
      isShowDetail: false,
      dataDetail: {
        key: '',
        childKeys: '',
        grade: 30
      }
    };
  },
  components: {
    echartPie,
    attendanceClassDetail
  },
  created () {
  },
  mounted () {
    this.getGrade();
    this.getChart();
    this.getList();
  },
  methods: {
    getGrade () {
      let that = this;
      GetSaaSClientMsg().then(result => {
        if (result.code == 0) {
          let list = result.data.SaaSClientSetting;
          for (let i = 0; i < list.length; i++) {
            if (list[i].SetTypeKey == 108) {
              that.dataDetail.grade = parseInt(list[i].SetContent);
            }
          }
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    sizeChange (val) {
      this.search.pageSize = val;
      this.search.pageIndex = 1;
      this.getList();
    },
    currentChange (val) {
      this.search.pageIndex = val;
      this.getList();
    },
    draw () {
      this.$refs.echart4.echartInit();
    },
    getChart () {
      let that = this;
      GetGradeAttenceDataCount().then(result => {
        if (result.code == 0) {
          that.echartList[0].value = parseInt(result.data.LowAttendanceGrade);
          that.echartList[1].value = parseInt(result.data.MediumAttendanceGrade);
          that.echartList[2].value = parseInt(result.data.HighAttendanceGrade);
          that.$nextTick(() => {
            that.draw();
          });
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    roomClick (index) {
      let that = this;
      that.search.TypeKey = index + 1;
      this.search.pageIndex = 1;
      this.getList();
    },
    getList () {
      let that = this;
      let param = {
        TypeKey: that.search.TypeKey,
        pageIndex: that.search.pageIndex - 1,
        pageSize: that.search.pageSize
      };
      GetGradeAttenceDataList(param).then(result => {
        if (result.code == 0) {
          let list = result.data.PageDataList;
          for (let i = 0; i < list.length; i++) {
            list[i].newCourseArr = list[i].ScheduleCourseLvValue = list[i].newCourseArr = list[i].ScheduleCourseLv + '%';
            list[i].newCourseArr = list[i].ScheduleCourseList.filter(item => item.ArrangedNumCount != 0);
            for (let j = 0; j < list[i].newCourseArr.length; j++) {
              list[i].newCourseArr[j].Attendance = list[i].newCourseArr[j].Attendance + '%';
            }
          }
          that.totalNum = result.data.Total;
          that.PageCount = result.data.PageCount;
          that.tableData = list;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    openDetail (row) {
      let that = this;
      if (row.MainDemoName) {
        that.dataDetail.key = row.OLAPKey;
        that.dataDetail.childKeys = '';
      } else {
        that.dataDetail.key = row.GradeClassKey;
        that.dataDetail.childKeys = row.WeekKey + ',' + row.MTeacherKey + ',' + row.CourseNameKey + ',' + row.ClassTimeName;
      }
      that.isShowDetail = true;
    },
    closeDetail () {
      this.isShowDetail = false;
    },
    setup () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '设置班级出勤率级别',
        moduleName: 'courseSettingItem',
        data: { editItem: { attendanceNum: 2 } },
        callBack: {
          afterSuccess: () => {
            this.search.pageIndex = 1;
            this.getList();
            this.getChart();
            this.getGrade();
          }
        }
      });
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.tableData.length > 0) {
        this.search.IsExportKey = 1;
        this.search.pageIndex = 0;
        this.search.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '透视出勤不足班级',
          clist: [
            { title: '班名', cName: 'MainDemoName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '综合出勤率', cName: 'ScheduleCourseLv', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{condition::[ScheduleCourseLv] > 0 ? [ScheduleCourseLv] : '-'::2::0 }}{{condition::[ScheduleCourseLv] > 0 ? '%' : '' }}" },
            { title: '循环课', cName: 'WeekKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{string::WeekKeyValue}}|{{string::ClassTimeName}}|{{string::CourseNameKeyValue}}|{{string::MTeacherKeyValue}}" },
            { title: '排课课次', cName: 'ArrangeCourseCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{condition::[ArrangeCourseCount] > 0 ? [ArrangeCourseCount] : '-'::2::0 }}" },
            { title: '排课人次', cName: 'ArrangedNumCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{condition::[ArrangedNumCount] > 0 ? [ArrangedNumCount] : '-'::2::0 }}" },
            { title: '平均出勤', cName: 'AverageCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{condition::[AverageCount] > 0 ? [AverageCount] : '-'::2::0 }}" },
            { title: '出勤率', cName: 'Attendance', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{condition::[Attendance] > 0 ? [Attendance] : '-'::2::0 }}{{condition::[Attendance] > 0 ? '%' : '' }}" }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        GetGradeAttenceDataList(this.search, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.search.IsExportKey = 0;
          this.search.pageIndex = 0;
          this.search.pageSize = 10;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
  }
};
</script>

<style scoped>
.modulebox .listbox >>> .el-table__header-wrapper .el-table__cell {
  color: #909399;
  background-color: #f0f2f6;
}
.modulebox .listbox >>> .el-table__row--level-0 .el-table__cell {
  border-bottom: 1px solid #ebeef5;
}
.modulebox .listbox >>> .el-pagination {
  text-align: right;
  background-color: transparent;
}
.modulebox .listbox >>> td.custombox {
  padding: 0;
}
.modulebox .listbox >>> td.custombox .cell {
  padding: 0;
}
.modulebox .listbox >>> td.custombox .block {
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #ebeef5;
  padding: 0 10px;
}
.modulebox .listbox >>> td.custombox .block:last-child {
  border: none;
}
.roomlist {
  position: relative;
}
.btn_3 {
  position: absolute;
  color: #fff;
  background-color: #2d98d9;
  border: 1px solid #2886bf;
  padding: 0 20px;
  margin: 0;
  right: 0;
  top: 10px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: 10px;
  cursor: pointer;
}
</style>