<template>
  <div>
    <div class="course_statistics_table_search flex course_statistics_title">
      <div class="">
        <div class="">
          <date-range ref="monthPicker"
                      :fromTitle="'发生日期'"
                      :searchObj="searchObj"
                      @changeDate="changeSaveDate"></date-range>
        </div>
      </div>
      <div class="table_select_box"
           style="margin-right: 20px; min-width: 304px">
        <div class="table_select_title">账户类型</div>
        <el-select :value="vTypeKeyValue"
                   filterable
                   placeholder="请选择"
                   style="width: 239px"
                   ref="resetText"
                   @change="selectAccountType">
          <el-option v-for="item in typeList"
                     :key="item.OLAPKey"
                     :label="item.MainDemoName"
                     :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="course_statistics_search">
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入经办人、发生人、备注'"
                                  :clearAll="true"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="searchData"></input-search-contain-btn>
        <div class="mendian_backstage_search">

          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <list class="summarizing_list"
            :key="tableKey"
            :tableData="vdataReportList"
            :totalNum="totalNum"
            :PageCount="PageCount"
            :tableColumns="tableCourseColumns"
            :isSearchText="false"
            :isNeedInnerScroll="false"
            :defaultSort="defaultSort"
            :queryParams="searchObj"
            :footerContent="vFooterTotal"
            @doAfterExceptBtn="doAfterExceptBtn"
            @loadTableData="getDataList"></list>
    </div>
    <div v-if="vPayTypeList.length > 0"
         class="payment_statistics_bottom_ul">
      <apply-acount-item v-for="(item,key) in vPayTypeList"
                         :key="key"
                         :item="item">
      </apply-acount-item>
    </div>
  </div>
</template>
<script>
import list from '../../../../common/table-list/index';
import applyAcountItem from './../apply-acount-item';
import inputSearchContainBtn from '../../../common/input-search-contain-btn';
import { MessageSearchInComePayReportForKDSY, getStudentRelatedMsg } from '../../../../../API/workbench';
import { convertToMd5 } from '../../../../../utils/upLoadTextImg';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        AccountTypeKey: 0,    // /账户类型1储蓄卡;2信用卡;3支付宝;4微信;5现金;6其他
        AccountTypeKeyValue: '',
        startTime: '',
        endTime: '',
        IsExportKey: 0
      },
      typeList: [
        {
          MainDemoName: '全部',
          OLAPKey: 0
        },
        {
          MainDemoName: '储蓄卡',
          OLAPKey: 1
        },
        {
          MainDemoName: '信用卡',
          OLAPKey: 2
        },
        {
          MainDemoName: '支付宝',
          OLAPKey: 3
        },
        {
          MainDemoName: '微信',
          OLAPKey: 4
        },
        {
          MainDemoName: '现金',
          OLAPKey: 5
        },
        {
          MainDemoName: '其他',
          OLAPKey: 6
        }

      ],
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          decimalPlace: 0,
          className: 'table_index_gray'
        },
        {
          label: '发生日期',
          prop: 'GenerateTime',
          minWidth: 132,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0

        },
        {
          label: '经办人',
          prop: 'BillerKeyValue',
          minWidth: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item',
          decimalPlace: 0
        },
        {
          label: '销售归属',
          prop: 'SaleMansName',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item',
          decimalPlace: 0
        },
        {
          label: '学生',
          prop: 'StudentKeyValue',
          minWidth: 110,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'student',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '购买套餐',
          prop: 'BoughtItem',
          minWidth: 160,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '应收',
          prop: 'RecableAmount',
          minWidth: 126,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'amount-item',
          decimalPlace: 2,
          eventName: '4'
        },
        {
          label: '实收',
          prop: 'ActualColAmount',
          minWidth: 126,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'amount-item',
          decimalPlace: 2
        },
        {
          label: '欠款',
          prop: 'DebtAmount',
          minWidth: 126,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'amount-item',
          decimalPlace: 2
        },
        {
          label: '预付款',
          prop: 'DepositAmount',
          minWidth: 126,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'amount-item',
          decimalPlace: 2
        },
        {
          label: '余额',
          prop: 'StoPayAmount',
          minWidth: 126,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'amount-item',
          decimalPlace: 2
        },
        {
          label: '收款方式',
          prop: 'AccountTypeKeyValue',
          minWidth: 126,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          eventName: '4'
        },
        {
          label: '备注',
          prop: 'CommentScript',
          minWidth: 126,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          eventName: '4'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          fixed: 'right',
          width: 120,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  click: (rowData) => {
                    this.toApplyRecord(rowData);
                  }
                },
                ModulePowerKey: 50
              },
              {
                name: '更多',
                showMore: true,
                extend: {
                  click: (rowData) => {
                    // this.studentDetail = rowData;
                    // this.doDebtDetailsClick(this.studentDetail, rowData);
                  }
                },
                btnList: [
                  {
                    name: '打印',
                    isShow: true,
                    type: 'print',
                    ModulePowerKey: 0
                  },
                  {
                    name: '还款',
                    isShow: true,
                    type: 'repayment',
                    ModulePowerKey: 0
                  }
                ]
                // ModulePowerKey: 50
              }

            ]
          }
        }

      ],
      dataReportList: [],
      PayTypeReport: []
    };
  },
  components: {
    list,
    applyAcountItem,
    inputSearchContainBtn
  },
  props: {
    searchParams: Object
  },
  created () {
    this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
    this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
  },
  mounted () {
    if (this.searchParams) {
      if (this.searchParams.SetUpTime) {      // 日报表
        this.parentSearchData(this.searchParams.SetUpTime);
      } else {        // 月报表
        this.parentSearchData(this.searchParams.startTime, this.searchParams.endTime);
      }
    } else {
      this.getDataList();
    }
    // this.getDataList();
  },
  computed: {
    vTypeKeyValue () {
      return this.searchObj.AccountTypeKey == 0
        ? '全部'
        : this.searchObj.AccountTypeKeyValue;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      return '共' + this.totalNum + '条，合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
    },
    vPayTypeList () {
      let dataInfo = [];
      this.PayTypeReport.forEach((item, index) => {
        let { CollectAccontKey } = item;
        let { CollectAccontKeyValue } = item;
        let { AccountTypeKey } = item;
        if (!dataInfo[CollectAccontKey]) {
          dataInfo[CollectAccontKey] = {
            CollectAccontKey,
            CollectAccontKeyValue,
            AccountTypeKey,
            child: []
          };
        }
        dataInfo[CollectAccontKey].child.push(item);
        // dataInfo.push(item);
      });
      let list = Object.values(dataInfo); // list 转换成功的数据
      console.log(list);
      return list;
    }
  },
  methods: {
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.AccountTypeKey = '';
      this.searchObj.searchText = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    parentSearchData (date, endDate) {
      console.log('searchData', date);
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = date;
      this.searchObj.endTime = endDate || date;
      this.getDataList();
    },
    searchData () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '报名记录',
          clist: [
            { title: '发生日期', cName: 'GenerateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '经办人', cName: 'BillerKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '销售归属', cName: 'SaleMansName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '购买套餐', cName: 'BoughtItem', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '应收', cName: 'RecableAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '实收', cName: 'ActualColAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '欠款', cName: 'DebtAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '预付款', cName: 'DepositAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '余额', cName: 'StoPayAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '收款方式', cName: 'AccountTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '备注', cName: 'CommentScript', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchInComePayReportForKDSY(searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      console.log(this.searchObj);
      MessageSearchInComePayReportForKDSY(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        result.data.PageDataList.forEach(o => {
          o.SaleMansName = o.SaleMansName ? o.SaleMansName : '-';// 销售归属
        });
        this.dataReportList = result.data.PageDataList;
        this.PayTypeReport = result.data.PayTypeReport;
        console.log(result.data, 'MessageSearchInComePayReportForKDSY');
      }).catch(error => {
        console.log('MessageSearchInComePayReportForKDSY', error.msg);
      });
    },
    toApplyReport (item) {
      this.$emit('toApplyReport', item, this.searchObj);
    },
    toOtherIncomeReport (item) {
      this.$emit('toOtherIncomeReport', item, this.searchObj);
    },
    toExpenditureReport (item) {
      this.$emit('toExpenditureReport', item, this.searchObj);
    },
    selectAccountType (item) {
      console.log(item, 'selectAccountType');
      this.searchObj.AccountTypeKey = item.OLAPKey;
      this.searchObj.AccountTypeKeyValue = item.MainDemoName;
      this.searchData();
    },
    // 到报名收款
    toApplyRecord (item) {
      console.log('toApplyRecord', item);
      this.$dialog.open(this, {
        name: '报名收款',
        routerName: this.$route.name,
        moduleName: 'signuppayment',
        moduleDescription: '学员报名购买课程，如未登记的学生可点界面“+”新增学生信息后再进行报名。',
        data: { dataInfo: { StudentKey: item.StudentKey, TableID: item.TableID, isReadOnly: true } },
        callBack: { afterSucces: () => { this.getDataList(); } }
      });
    },
    doShowPrintFrom (rowData) {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '打印收据',
        moduleName: 'signuppaymentSuccess',
        data: { tableID: Number(rowData.TableID), isMoneyReceipt: true }
      });
    },
    studentPaymentEvent (rowData) {
      this.getStudentInfo(rowData.StudentKey, (data) => {
        this.$dialog.open(this, {
          name: '客户还款',
          routerName: this.$route.name,
          moduleName: 'paymentrepayment',
          data: { dataInfo: data },
          callBack: { afterSucces: () => { this.searchData(); } }
        });
      });
    },
    // 获取学生个人信息
    getStudentInfo (StuKey, callback) {
      getStudentRelatedMsg(StuKey).then(result => {
        if (callback) {
          callback(result.data);
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    doAfterExceptBtn (item, rowData) {
      console.log(item, 'doAfterExceptBtn', rowData);
      if (item.type == 'print') {
        this.doShowPrintFrom(rowData);
      } else if (item.type == 'repayment') {
        this.studentPaymentEvent(rowData);
      }
    }

  }
};
</script>