<template>
  <!-- 公司信息 -->
  <div class="league_info_form border_bottom">
    <div class="YK_added_content_name">
      <span>加盟信息</span>
    </div>
    <div class="YK_added_content_form">
      <div class="form_list">
        <div class="form_list_name">
          <span class="text_box">整店加盟信息</span>
        </div>
        <div class="form_list_content">
          {{vWholeStoreFranchiseType}}
        </div>
      </div>
      <div v-if="vIsShowWholeStoreFranchise">
        <div class="form_list">
          <div class="form_list_name">
            <span class="text_box">整店加盟费</span>
          </div>
          <div class="form_list_content">
            {{Number(dataInfo.StartBrandFranchiseAmount)}}-{{Number(dataInfo.EndBrandFranchiseAmount)}}万
          </div>
        </div>
        <!-- <div class="form_list">
            <div class="form_list_name">
              <span class="text_box">全部投资预算</span>
            </div>
            <div class="form_list_content">
              {{Number(dataInfo.StartBrandFranchiseAmount)}}-{{Number(dataInfo.EndBrandFranchiseAmount)}}
            </div>
          </div> -->
        <div class="form_list">
          <div class="form_list_name">
            <span class="text_box">加盟区域</span>
          </div>
          <div class="form_list_content">
            {{dataInfo.FranchiseArea}}
          </div>
        </div>
        <div class="form_list">
          <div class="form_list_name">
            <span class="text_box">加盟扶持</span>
          </div>
          <div class="form_list_content">
            <div class="flex">
              <div class="form_list_name">
                <span class="text_box font_black">选址扶持</span>
              </div>
              <div class="form_list_content">
                <span class="font_gray">{{vChooseAddressSupport}}</span>
              </div>
            </div>
            <div class="flex">
              <div class="form_list_name">
                <span class="text_box font_black">装修扶持</span>
              </div>
              <div class="form_list_content">
                <span class="font_gray">{{vDecorationSupport}}</span>
              </div>
            </div>
            <div class="flex">
              <div class="form_list_name">
                <span class="text_box font_black">新员工招聘</span>
              </div>
              <div class="form_list_content">
                <span class="font_gray">{{vRecruitmentSupport}}</span>
              </div>
            </div>
            <div class="flex">
              <div class="form_list_name">
                <span class="text_box font_black">新员工培训</span>
              </div>
              <div class="form_list_content">
                <span class="font_gray">{{vTrainingSupport}}</span>
              </div>
            </div>
            <div class="flex">
              <div class="form_list_name">
                <span class="text_box font_black">开业招生</span>
              </div>
              <div class="form_list_content">
                <span class="font_gray">{{vEnrollmentSupport}}</span>
              </div>
            </div>
            <div class="flex">
              <div class="form_list_name">
                <span class="text_box font_black">运营扶持</span>
              </div>
              <div class="form_list_content">
                <span class="font_gray">{{vOperateSupport}}</span>
              </div>
            </div>
            <div class="flex">
              <div class="form_list_name">
                <span class="text_box font_black">门店管理系统</span>
              </div>
              <div class="form_list_content">
                <span class="font_gray">{{vSystemSupport}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form_list">
          <div class="form_list_name">
            <span class="text_box">对手品牌</span>
          </div>
          <div class="form_list_content">
            {{dataInfo.RivalBrand}}
          </div>
        </div>
        <div class="form_list">
          <div class="form_list_name">
            <span class="text_box">您的优势</span>
          </div>
          <div class="form_list_content">
            {{dataInfo.Advantage}}
          </div>
        </div>
        <div class="form_list">
          <div class="form_list_name">
            <span class="text_box">主营行业</span>
          </div>
          <div class="form_list_content">
            {{dataInfo.TheMainCourseName}}
          </div>
        </div>
        <div class="form_list"
             style="border-top: 1px solid #ececec;padding-top: 20px;">
          <div class="form_list_name">
            <span class="text_box">课程加盟信息</span>
          </div>
          <div class="form_list_content">
            {{vCourseStoreFranchiseType}}
          </div>
        </div>
      </div>
      <div class="form_list"
           v-if="vIsShowCourseStoreFranchise">
        <div class="form_list_name">
          <span class="text_box">课程信息</span>
        </div>
        <div class="form_list_content">
          <div class="form_course_block">
            <div class="course_content border_bottom"
                 v-for="(courseInfo,index) in dataInfo.CourseList"
                 :key="index">
              <div class="course_content_img">
                <img class="img_box"
                     :src="setCourseCover(courseInfo)"
                     alt="">
              </div>
              <div class="">
                <div class="course_content_name">
                  {{courseInfo.MainDemoName}}
                </div>
                <div class="course_content_info">
                  {{courseInfo.SchoolAgeRange}}丨课时单价{{Number(courseInfo.UnitPriceAmount)}}元丨上课次数{{Number(courseInfo.ClassTimeCount)}}
                </div>
                <div class="course_content_text" :title="courseInfo.CourseIntro">
                  {{courseInfo.CourseIntro}}
                </div>
              </div>
              <div class="course_content_amount">
                {{setPriceAmount(courseInfo)}}<span class="font_unit">{{setUnitName(courseInfo)}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 
<script>
export default {
  props: {
    dataInfo: Object,
  },
  computed: {
    vWholeStoreFranchiseType() {
      return this.dataInfo.FranchiseTypeKey == 1 ||
        this.dataInfo.FranchiseTypeKey == 2
        ? '开启'
        : '关闭'
    },
    vCourseStoreFranchiseType() {
      return this.dataInfo.FranchiseTypeKey == 1 ||
        this.dataInfo.FranchiseTypeKey == 3
        ? '开启'
        : '关闭'
    },
    vIsShowWholeStoreFranchise() {
      return (
        this.dataInfo.FranchiseTypeKey == 1 ||
        this.dataInfo.FranchiseTypeKey == 2
      )
    },
    vIsShowCourseStoreFranchise() {
      return (
        this.dataInfo.FranchiseTypeKey == 1 ||
        this.dataInfo.FranchiseTypeKey == 3
      )
    },
    vChooseAddressSupport() {
      let list = JSON.parse(
        this.dataInfo.FranchiseSupport
      )[0].childrenList.filter((obj) => {
        return obj.isSelected
      })
      return list
        .map((obj) => {
          return obj.name
        })
        .join(',')
    },
    vDecorationSupport() {
      let list = JSON.parse(
        this.dataInfo.FranchiseSupport
      )[1].childrenList.filter((obj) => {
        return obj.isSelected
      })
      return list
        .map((obj) => {
          return obj.name
        })
        .join(',')
    },
    vRecruitmentSupport() {
      let list = JSON.parse(
        this.dataInfo.FranchiseSupport
      )[2].childrenList.filter((obj) => {
        return obj.isSelected
      })
      return list
        .map((obj) => {
          return obj.name
        })
        .join(',')
    },
    vTrainingSupport() {
      let list = JSON.parse(
        this.dataInfo.FranchiseSupport
      )[3].childrenList.filter((obj) => {
        return obj.isSelected
      })
      return list
        .map((obj) => {
          return obj.name
        })
        .join(',')
    },
    vEnrollmentSupport() {
      let list = JSON.parse(
        this.dataInfo.FranchiseSupport
      )[4].childrenList.filter((obj) => {
        return obj.isSelected
      })
      return list
        .map((obj) => {
          return obj.name
        })
        .join(',')
    },
    vOperateSupport() {
      let list = JSON.parse(
        this.dataInfo.FranchiseSupport
      )[5].childrenList.filter((obj) => {
        return obj.isSelected
      })
      return list
        .map((obj) => {
          return obj.name
        })
        .join(',')
    },
    vSystemSupport() {
      let list = JSON.parse(
        this.dataInfo.FranchiseSupport
      )[6].childrenList.filter((obj) => {
        return obj.isSelected
      })
      return list
        .map((obj) => {
          return obj.name
        })
        .join(',')
    },
  },
  methods: {
    setCourseCover(courseInfo) {
      return this.$store.getters.CDNURL + courseInfo.CourseCover
    },
    setPriceAmount(courseInfo) {
      if (Number(courseInfo.PriceAmount) > 10000) {
        return Number(courseInfo.PriceAmount) / 10000
      } else {
        return Number(courseInfo.PriceAmount)
      }
    },
    setUnitName(courseInfo) {
      // 收费方式1终身;2按 年收费;3按次收费
      let str = ''
      if (courseInfo.ChargeTypeKey == 1) {
        if (Number(courseInfo.PriceAmount) > 10000) {
          str = '万/永久'
        } else {
          str = '/永久'
        }
      } else if (courseInfo.ChargeTypeKey == 2) {
        if (Number(courseInfo.PriceAmount) > 10000) {
          str = '万/年'
        } else {
          str = '/年'
        }
      }
      if (courseInfo.ChargeTypeKey == 3) {
        if (Number(courseInfo.PriceAmount) > 10000) {
          str = '万/课次'
        } else {
          str = '/课次'
        }
      }
      return str
    },
  },
}
</script>

<style scoped>
.franchise_support {
  margin-right: 60px;
}
.league_info_form {
  padding-left: 166px;
  padding-right: 60px;
}
</style>