<template>
  <div class="swipeAction">
    <div class="clue_item">
      <div class="clue_content">
        <div class="top_box"
             @click.stop="goStudentFile">
          <div class="ad_student">{{item.MainDemoName}}</div>
          <div class="box_item">
            <div class="box_item_list">
              <div class="flexr">
                <div class="box_item_title"> 可用课单：</div>
                <div class="box_item_value">x{{item.XykdCount}}</div>
                <!-- <div class="typestyle02">预警x2</div>
                <div class="typestyle01">失效x1</div> -->
              </div>
              <div class="flexr">
                <div class="box_item_title"> 30天出勤率：</div>
                <div class="box_item_value">{{Number(item.AttenceLv).toFixed(2)}}%</div>
                <!-- <div class="typestyle02">预警</div> -->
              </div>
            </div>
            <div class="box_item_list">
              <div class="flexr">
                <div class="box_item_title"> 周排课次：</div>
                <div class="box_item_value">x{{item.StudentArrangeCount}}</div>
                <!-- <div class="typestyle02">预警</div> -->
              </div>
              <div class="flexr">
                <div class="box_item_title"> 最近出勤：</div>
                <div class="box_item_value">{{item.LastSignTime?$utils.replyTimeNotHasHour(item.LastSignTime):'--' }}
                </div>
                <!-- <div class="typestyle02">预警</div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="body_box">
          <div class="left_box"
               v-if="item.FollowTime&&item.FollowTime!=''">
            <div class="year_text">{{item.FollowTime?item.FollowTime.substring(0, 4):''}}</div>
            <div class="month_text">{{item.FollowTime?item.FollowTime.substring(5, 10):''}}</div>
          </div>
          <div class="right_box"
               v-if="item.FollowTime&&item.FollowTime!=''">
            <div class="top_content">
              <div class="top_content_item_box">
                <span class="top_content_item_text color3 textEllipsis">{{item.FollowName}}
                </span>
                <div class="top_content_item_text color9">录入</div>
              </div>
              <!-- <div class="top_content_item_box label_item">
                <span class="top_content_item_text color9">节点:</span>
                <span class="top_content_item_text color3">{{item.FormerNodeKeyValue}}</span>
                <span class="record_type_01" v-if="item.FormerNodeKey"></span>
                <span class="top_content_item_text color3">{{item.CurrentNodeKeyValue}}</span>
              </div> -->
              <!--  <div class="top_content_item_box label_item" style="flex:1">
                <div class="top_content_item_text color9 maginleft10">标签:</div>
                <span :class="agoType"></span>
                <span class="top_content_item_text color3"> {{vLabelChangeAgo}}</span>
                <span :class="nowType"></span>
                <span class="top_content_item_text color3"> {{vLabelChangeNow}}</span> -->
              <!-- </div> -->
            </div>
            <div class="bottom_content"
                 @click.stop="goDetail">
              <div class="bottom_content_text">{{item.FollowConent}}</div>
            </div>
          </div>
          <div class="details_box"
               @click.stop="goDetail">跟单</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {
      options2: [{
        text: '电话联系',
        style: {
          backgroundColor: '#007aff',
          fontSize: '12px'
        }
      },
      {
        text: '跟单记录',
        style: {
          backgroundColor: '#1384EC',
          fontSize: '12px'
        }
      },
      {
        text: '登记诺访',
        style: {
          backgroundColor: '#8400FF',
          fontSize: '12px'
        }
      },
      {
        text: '登记约试课',
        style: {
          backgroundColor: '#06C061',
          fontSize: '12px'
        }
      },
      {
        text: '报名收款',
        style: {
          backgroundColor: '#FA9E3B',
          fontSize: '12px'
        }
      },
      {
        text: '放弃',
        style: {
          backgroundColor: '#F95151',
          fontSize: '12px'
        }
      }
      ],
    };
  },
  props: {
    item: Object,
  },
  computed: {
    vOptions2 () {
      let arr = JSON.parse(JSON.stringify(this.options2))

      if (this.item.MobilePhone && this.item.MobilePhone.length == 0) {
        arr[0].style.backgroundColor = '#666666'
      }

      return arr
    },
    // agoType() {
    //   let newArr = this.item.LabelNames.split('、')
    //   let text = ''
    //   if (newArr.length > 0) {
    //     text = newArr[0]
    //     text = text.substring(0, 1)
    //     return text == '-' ? 'record_type_02' : 'record_type_03'
    //   }
    //   return text
    // },
    // nowType() {
    //   let newArr = this.item.LabelNames.split('、')
    //   let text = ''
    //   if (newArr.length > 1) {
    //     text = newArr[1]
    //     text = text.substring(0, 1)
    //     return text == '-' ? 'record_type_02' : 'record_type_03'
    //   }
    //   return text
    // },
    // vLabelChangeAgo() {
    //   let newArr = this.item.LabelNames.split('、')
    //   let text = ''
    //   if (newArr.length > 0) {
    //     text = newArr[0]
    //     return text
    //   }
    //   return text
    // },
    // vLabelChangeNow() {
    //   let newArr = this.item.LabelNames.split('、')
    //   let text = ''
    //   if (newArr.length > 1) {
    //     text = newArr[1]
    //     return text
    //   }
    //   return text
    // }
  },
  created () {
    console.log('item', this.item)
  },
  mounted () { },
  methods: {
    bindClick (e) {

    },
    change (e) {
      // this.isOpened = e;
      console.log('返回：', e);
    },
    goDetail () {
      console.log('去详情', this.item)
      this.$emit('clickBottom', this.item)
    },
    goStudentFile () {
      this.$emit('goStudentFile', this.item)
    }
  }
};
</script>

<style scoped>
.swipeAction {
  margin-bottom: 10px;
}

.clue_item {
  /* height: 340rpx; */
  display: flex;
  flex-direction: row;

  /* background: #333333; */
}

.clue_content {
  flex: 1;
  /* height: 340rpx; */
  background: #fff;
  border-radius: 8px;
  border: Mixed solid #cfcfcf;
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  padding: 12px;
  position: relative;
}

.top_box {
  height: 95px;
  display: flex;
  flex-direction: column;
  /* padding */
  padding-bottom: 2px;
  border-bottom: 1px solid #dadfe8;
}

.name_text {
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  height: 28px;
  letter-spacing: 0em;
  width: 50px;
  overflow: hidden;
  word-break: break-all;
  /* break-all(允许在单词内换行。) */
  text-overflow: ellipsis;
  /* 超出部分省略号 */
  display: -webkit-box;
  /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical;
  /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 1;
  /* line: 1; */
  /** 显示的行数 **/
  color: #333333;
}

.textEllipsis {
  width: 60px;
  overflow: hidden;
  word-break: break-all;
  /* break-all(允许在单词内换行。) */
  text-overflow: ellipsis;
  /* 超出部分省略号 */
  display: -webkit-box;
  /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical;
  /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 1;
}

.otherText {
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  color: #999999;
  margin-left: 5px;
  margin-right: 5px;
}

.phone_img {
  width: 12px;
  height: 12px;
  margin-top: 10px;
  margin-left: 5px;
}

.body_box {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  min-height: 15px;
  position: relative;
}

.left_box {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 70px;
}

.year_text {
  font-family: Arial;
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #333333;
}

.month_text {
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #888888;
}

.right_box {
  flex: 7;
  display: flex;
  flex-direction: column;
  height: 70px;
}

.top_content {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.top_content_item_box {
  width: 80px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #cfcfcf;
  background: linear-gradient(0deg, #f8f8fa, #f8f8fa);
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  margin-left: 5px;
}

.top_content_item_text {
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.color3 {
  color: #333333;
}

.color9 {
  color: #999999;
}

.bottom_content {
  flex: 3;
  position: relative;
}

.maginleft10 {
  margin-left: 5px;
}

.bottom_content_text {
  position: absolute;
  top: 0px;
  left: 5px;
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  /* width: 150rpx; */
  flex: 1;
  overflow: hidden;
  word-break: break-all;
  /* break-all(允许在单词内换行。) */
  text-overflow: ellipsis;
  /* 超出部分省略号 */
  display: -webkit-box;
  /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical;
  /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 2;
  /* line: 1; */
  /** 显示的行数 **/
  color: #333333;
}

.label_item {
  justify-content: left;
}

.record_type_01 {
  background: url('../../../../../public/image//record_type_01.png') center
    no-repeat;
  background-size: 10px;
  display: inline-block;
  width: 12px;
  height: 12px;
}

.record_type_02 {
  background: url('../../../../../public/image//record_type_02.png') center
    no-repeat;
  background-size: 10px;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: 4px;
}

.record_type_03 {
  background: url('../../../../../public/image/record_type_03.png') center
    no-repeat;
  background-size: 10px;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: 2px;
}

.ad_student {
  font-family: PingFang SC;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  color: #333333;
  height: 23px;
}

.box_item {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* padding-bottom: 10rpx; */
}

.textEllipsis {
  max-width: 45px;
  overflow: hidden;
  word-break: break-all;
  /* break-all(允许在单词内换行。) */
  text-overflow: ellipsis;
  /* 超出部分省略号 */
  display: -webkit-box;
  /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical;
  /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 1;
}

.box_item_list {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.flexr {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.typestyle02 {
  width: 34px;
  height: 18px;
  border-radius: 4px;
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  background: #ff980033;
  color: #ff9800;
  margin-left: 2px;
}

.typestyle01 {
  width: 34px;
  height: 18px;
  border-radius: 4px;
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  background: #f7504033;
  color: #f75040;
  margin-left: 2px;
}

.box_item_title {
  font-family: DIN;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #999999;
}

.box_item_value {
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #333333;
}

.details_box {
  position: absolute;
  right: 15px;
  bottom: -10px;
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
}

.details_box::after {
  content: '';
  /* background: linear-gradient(270deg, #FF5400 0%, rgba(255, 84, 0, 0) 92.5%); */
  width: 10px;
  height: 10px;
  position: absolute;
  top: 4px;
  right: -17px;
  opacity: 1;
  background-image: url('../../../../../public/image/list_next@2x.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px;
}
</style>