<template>
  <!-- 品牌录入 -->
  <div>
    <div class="form_info_edit form_info_list">
      <input-date
        v-model="dataJson.ApplicantTime"
        :formTitle="'签单日期'"
        :readonly="isReadonly"
        :elementId="'BirthdayTime'"
        :required="true"
        :formPlaceholder="'必填'"
        @changeDate="changeDate"
      ></input-date>
      <input-text
        :formTitle="'申请人'"
        :required="true"
        :readonly="true"
        :formPlaceholder="'请输入'"
        v-model="dataJson.ApplicantKeyValue"
      ></input-text>
      <input-text
        :formTitle="'机构名称'"
        :required="false"
        :readonly="isReadonly"
        :formPlaceholder="'请选择'"
        v-model="dataJson.InstitutionName"
      ></input-text>
      <input-text
        :formTitle="'联系人'"
        :required="true"
        :readonly="isReadonly"
        :formPlaceholder="'请输入'"
        v-model="dataJson.Contacts"
      ></input-text>
      <input-phone
        :formTitle="'联系电话'"
        :required="true"
        :readonly="isReadonly"
        :formPlaceholder="'请输入'"
        v-model="dataJson.ContactPhone"
      ></input-phone>
      <input-select
        :formTitle="'合作方式'"
        :id="'id'"
        :name="'name'"
        :required="true"
        :readonly="isReadonly"
        :dataList="typeList"
        :formPlaceholder="'请选择'"
        :selectedInfo="vFranchiseType"
        @selectedItem="chgType"
      ></input-select>
      <input-text
        :formTitle="'合作内容'"
        :required="true"
        :readonly="true"
        :formPlaceholder="''"
        :value="''"
      ></input-text>
    </div>
    <div class="form_extra_box" v-if="dataJson.CooperateTypeKey > 0">
      <div class="bg_white" style="margin-bottom: 8px;">
        <input-text-inline
          v-if="dataJson.CooperateTypeKey == 2"
          :formTitle="'基础加盟费'"
          :required="true"
          :readonly="isReadonly"
          :formPlaceholder="'请输入'"
          @input="updateBasicFranchiseFeeAmount"
          v-model="dataJson.BasicFranchiseFeeAmount"
        ></input-text-inline>
        <input-text-inline
          :formTitle="'服务续费'"
          :required="true"
          :readonly="true"
          :showSelect="true"
          :formPlaceholder="'请输入'"
          :class="isReadonly?'':'inline_font_blue'"
          @clickAmend="showServiceChargeForm"
          :value="vServiceChargeContent"
        ></input-text-inline>
      </div>
      <!-- <div class="bg_white"> -->
        <bill-item v-for="(item, key) in dataJson.joinedContent"
          :key="key"
          :item="item"
          :keyIndex="key"
          :isReadonly="isReadonly"
          @seletedItem="seletedItemAfter"
          @deletedItem="deletedItemAfter">
        </bill-item>
      <!-- </div> -->
      <div class="bg_white" v-if="!isReadonly" :class="isReadonly?'form_extra_btn_gray':'form_extra_btn'" @click.stop="addItem" >添加项目</div>
      
    </div>
    <div class="form_info_edit form_info_list">
      <input-number
        :formTitle="'优惠金额'"
        :required="false"
        :readonly="true"
        v-model="vDiscountsAmount"
      ></input-number>
      <input-number
        :formTitle="'合同金额'"
        :required="true"
        :readonly="isReadonly"
        v-model="dataJson.ContractAmount"
      ></input-number>
      <input-number
        :formTitle="'定金'"
        :required="false"
        :readonly="isReadonly"
        :formPlaceholder="'请输入'"
        v-model="dataJson.EarnestAmount"
      ></input-number>
      <input-upload-file v-if="!isReadonly"
              :formTitle="'合同附件'"
              :readonly="isReadonly"
              :isDownLoad="true"
              :required="true"
              :uploadTitle="'上传合同'"
              :value="vFileName"
              @UploadFilesAfter="UploadFilesAfter"
      ></input-upload-file>
      <input-download-file v-else
              :formTitle="'合同附件'"
              :readonly="isReadonly"
              :isDownLoad="true"
              :required="true"
              :uploadTitle="'上传合同'"
              :AttachUrl="vAttachUrl"
              :value="vFileName"
              @UploadFilesAfter="UploadFilesAfter"
      ></input-download-file>
      <input-text-area
        :formTitle="'修改条款'"
        :required="false"
        :readonly="isReadonly"
        v-model="dataJson.AmendingClause"
      ></input-text-area>
    </div>
    <!-- 新增按钮组 -->
    <save-cancel-btn-group
      v-if="!isReadonly"
      :isSingleSubmit="true"
      :singleSubmitType="'dynamic'"
      @confirmClick="confirmClick"
      @cancelClick="cancelClick"
    ></save-cancel-btn-group>
    <!-- 审核按钮组 -->
    <save-cancel-btn-group
      v-if="isReadonly&&showBtn"
      :isSingleSubmit="true"
      :singleSubmitType="'dynamic'"
      :cancelBtnText="'审核拒绝'"
      :btnText="'审核通过'"
      @confirmClick="auditPass"
      @cancelClick="auditReject"
    ></save-cancel-btn-group>
    <custom-dialog
      title="整店加盟"
      class="customer_dialog defult_dialog"
      :visible.sync="isShowBrandForm"
      :before-close="closeDialog"
    >
      <add-brand-form
        :dataInfo="seletedItem"
        :isReadonly="isReadonly"
        @afterSuccess="updateItem"
        @cancelClick="closeDialog"
      ></add-brand-form>
    </custom-dialog>
    <custom-dialog
      title="课程加盟"
      class="customer_dialog defult_dialog"
      :visible.sync="isShowCourseForm"
      :before-close="closeDialog"
    >
      <add-course-form
        :dataInfo="seletedItem"
        :isReadonly="isReadonly"
        @afterSuccess="updateItem"
        @cancelClick="closeDialog"
      ></add-course-form>
    </custom-dialog>
    <custom-dialog
      title="服务费用设置"
      class="customer_dialog defult_dialog"
      :visible.sync="isShowServiceChargeForm"
      :before-close="closeDialog"
    >
      <ServiceChargeForm
        :dataInfo="dataJson"
        :isReadonly="isReadonly"
        @afterSuccess="updateServiceCharge"
        @cancelClick="closeDialog"
      ></ServiceChargeForm>
    </custom-dialog>
  </div>
</template>

<script>
import billItem from './crm-sign-contract-form-components/bill-item'
import ServiceChargeForm from './crm-sign-contract-form-components/service-charge-form'
import addBrandForm from "./crm-sign-contract-form-components/add-brand-form";
import addCourseForm from "./crm-sign-contract-form-components/add-course-form";
import {
  HQSignUpToApply,
  HQGetSignUpToApplyDetail,
  HQGetContractReviewDetail,
  HQContractReview,
  HQRefuseContractReview
} from '../../../../API/workbench.js';
export default {
  components: {
    addBrandForm,
    addCourseForm,
    billItem,
    ServiceChargeForm
  },
  data() {
    return {
      typeList: [
        {
          id: 2,
          name: "整店加盟",
        },
        {
          id: 1,
          name: "课程加盟",
        },
      ], // 来源1-抖音;2-百度;3-转介绍
      // 提交数据对象
      dataJson: {
        ApplicantTime:this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd'), //"签约日期",
        AttractInvestKey: "", //"线索id",
        AttractInvestKeyValue:'',
        ApplicantKey: "", //"申请人",
        ApplicantKeyValue: "", // "申请人",
        InstitutionName: "", // "组织机构",
        Contacts: "", //"联系人",
        ContactPhone: "", //"联系手机号",
        CooperateTypeKey: 0, //"加盟方式",
        CooperateTypeKeyValue: "", //"加盟方式1-课程加盟;2-整店加盟",
        BasicFranchiseFeeAmount: "", //"基础加盟费",
        ServiceChargeAmount: '0', // "服务费",
        ServiceChargeNum: "", // "服务收费间隔",
        ServiceChargeStartTime: "", // "服务收费开始时间",
        ContractAmount: "", //"合同金额",
        EarnestAmount: "", //"定金",
        AmendingClause: "", //"修改条款",
        DiscountsAmount: "", //"优惠金额",
        joinedContent: [
          // {
          //   TotalAmount: "总价",
          //   UnitPriceAmount: "单价",
          //   Unit: "单位",
          //   QuantityCount: "数量",
          //   CourseKey: "课程",
          //   CourseKeyValue: "课程",
          //   IncidentalsKey: "杂费",
          //   IncidentalsKeyValue: "杂费",
          // },
        ],
        attach: [
          // {
          //   AttachUrl: "附件",
          //   MainDemoName: "名称",
          // },
        ],
      },
      isShowServiceChargeForm:false,   //显示服务费用表单
      isShowBrandForm: false,   //显示整店加盟表单
      isShowCourseForm: false,    //显示课程加盟表单
      token: {},
      joinedContentIndex:0,  //修改时的下标
      seletedItem: null,
    };
  },
  props: {
    dataInfo: Object, // 职务信息
    isReadonly: Boolean, // 是否只读
    showBtn:{
      type:Boolean,
      default:true
    }
  },
  created() {
    this.token = this.$store.getters.token;
    console.log(this.$store.getters.token, "token", this.dataInfo);
    this.getDataInfo(this.dataInfo.OLAPKey);
    
    
  },
  computed: {
    vFileName(){
      if(this.dataJson.attach.length>0){
        return this.dataJson.attach[0].MainDemoName
      }else{
        return ''
      }
    },
    vAttachUrl(){
      if(this.dataJson.attach.length>0){
        return this.dataJson.attach[0].AttachUrl
      }else{
        return null
      }
    },
    // 选择模式
    vFranchiseType() {
      let obj = {
        id: this.dataJson.CooperateTypeKey,
        name: this.dataJson.CooperateTypeKeyValue,
      };
      return obj;
    },
    vBrandAmount() {
      if (
        this.dataJson.StartBrandFranchiseAmount &&
        this.dataJson.EndBrandFranchiseAmount
      ) {
        return (
          Number(this.dataJson.StartBrandFranchiseAmount) +
          "~" +
          Number(this.dataJson.EndBrandFranchiseAmount) +
          "万"
        );
      } else {
        return "";
      }
    },
    vServiceChargeContent(){
      let text=''
    
      if(this.dataJson.ServiceChargeStartTime){
        text='从'+this.$utils.formatDateToYYYYMMDD(this.dataJson.ServiceChargeStartTime)+'开始,每'+Number(this.dataJson.ServiceChargeNum)+'年'+Number(this.dataJson.ServiceChargeAmount)+'元'
      }else{
        this.dataJson.ServiceChargeAmount='0'
        text=this.dataJson.ServiceChargeAmount
        
      }
      
      return text
    },
    // 优惠金额
    vDiscountsAmount(){
        let amount =this.sumContractAmount()
        if(amount==0){
          return 0
        }else{
          return amount-Number(this.dataJson.ContractAmount)
        }
    }
  },
  methods: {
    // 计算合同金额
    sumContractAmount(){
      let amount =0
        amount+=Number(this.dataJson.BasicFranchiseFeeAmount)//+Number(this.dataJson.ServiceChargeAmount)
        this.dataJson.joinedContent.forEach(o=>{
          amount+=Number(o.TotalAmount)
        })
        return amount
    },
    updateBasicFranchiseFeeAmount(val){
      console.log('updateBasicFranchiseFeeAmount',val)
      this.dataJson.ContractAmount=this.sumContractAmount()
    },
    getDataInfo(OLAPKey) {
      if(!this.isReadonly){
        HQGetSignUpToApplyDetail(OLAPKey)
          .then(
            (result) => {
              // this.dataJson = result.data;
              console.log(this.dataJson, "HQGetContractReviewDetail111");
              this.dataJson.ApplicantKey = this.token.UserID;
              this.dataJson.ApplicantKeyValue = this.token.UserName;
              // this.dataJson.InstitutionName = this.token.SaaSClientKeyValue;
              this.dataJson.AttractInvestKey=this.dataInfo.OLAPKey
              this.dataJson.AttractInvestKeyValue=this.dataInfo.MainDemoName
              this.dataJson.Contacts=this.dataInfo.MainDemoName
              this.dataJson.ContactPhone=this.dataInfo.MobilePhone
              this.dataJson.CooperateTypeKey=this.dataInfo.FranchiseTypeKey//"加盟方式",
              this.dataJson.CooperateTypeKeyValue=this.dataInfo.FranchiseTypeKeyValue//"加盟方式1-课程加盟;2-整店加盟",
            
            },
            (error) => {
              layer.alert(error.msg);
            }
          )
          .finally((error) => {
            console.log("error", error);
          });
      }else{

        HQGetContractReviewDetail(OLAPKey)
          .then(
            (result) => {
              this.dataJson = result.data;
              this.dataJson.ApplicantTime=this.dataJson.ApplicantTime.substring(0,10)
              console.log(this.dataJson, "HQGetContractReviewDetail");
              this.dataJson.BasicFranchiseFeeAmount= Number(this.dataJson.BasicFranchiseFeeAmount)
            },
            (error) => {
              layer.alert(error.msg);
            }
          )
          .finally((error) => {
            console.log("error", error);
          });
      }
    },
    // 上传文件
    UploadFilesAfter(Url,fileName) {
      this.dataJson.attach = [];
      let obj={
            AttachUrl: Url,
            MainDemoName: fileName,
          };
         this.dataJson.attach.push(obj)
           console.log(Url, "UploadFilesAfter",fileName,this.dataJson.attach); 
        
    },
    // 选中单个修改
    seletedItemAfter(item,keyIndex){
      this.seletedItem = item;
      this.joinedContentIndex=keyIndex
      if (this.dataJson.CooperateTypeKey == 1) {
        this.isShowCourseForm = true;
      } else {
        this.isShowBrandForm = true;
      }
    },
    deletedItemAfter(item,keyIndex){
      this.dataJson.joinedContent.splice(keyIndex,1);
      this.dataJson.ContractAmount=this.sumContractAmount()
    },
    // 新增
    addItem() {
      console.log("addItem", this.dataJson.CooperateTypeKey);
      this.seletedItem = null;
      if(!this.isReadonly){
        if (this.dataJson.CooperateTypeKey == 1) {
          this.isShowCourseForm = true;
        } else {
          this.isShowBrandForm = true;
        }
      }
       
    },
    // 弹出层点击确定修改或新增
    updateItem(item,index) {
      console.log("updateItem", item);
      if (!this.seletedItem) {
        this.dataJson.joinedContent.push(item);
      }else{
        this.seletedItem=this.$utils.parseJson(item)
        this.dataJson.joinedContent[this.joinedContentIndex]=this.$utils.parseJson(item)
      }
        console.log("updateItem", this.seletedItem,this.dataJson.joinedContent);
      this.dataJson.ContractAmount=this.sumContractAmount()
      this.closeDialog()
    },
    // 更改课程加盟费
    changeCourseFranchise(item) {
      // 找到列表中对应的项更改值.
      let locaIndex = this.dataJson.IntentionIndustryList.findIndex((o) => {
        return o.IndustryKey == item.IndustryKey;
      });

      if (locaIndex >= 0) {
        this.dataJson.IntentionIndustryList.splice(locaIndex, 1, item);
        console.log(
          item,
          "更改课程加盟费",
          this.dataJson.IntentionIndustryList
        );
        // locaItem=this.$utils.parseJson(item)
      }
      this.closeDialog();
    },
    showServiceChargeForm(){
      this.isShowServiceChargeForm=true
    },
    updateServiceCharge(dataObj){
      console.log("updateServiceCharge",dataObj)
      this.dataJson.ServiceChargeAmount= dataObj.ServiceChargeAmount;// "服务费",
      this.dataJson.ServiceChargeNum=dataObj.ServiceChargeNum;// "服务收费间隔",
      this.dataJson.ServiceChargeStartTime= dataObj.ServiceChargeStartTime; // "服务收费开始时间",
      // this.dataJson.ContractAmount=this.sumContractAmount()
    },
    closeDialog() {
      this.isShowBrandForm = false;
      this.isShowCourseForm = false;
      this.isShowServiceChargeForm=false
    },
    changeDate(dateTime) {
      console.log(dateTime, "changeDate");
      this.dataJson.ApplicantTime = dateTime;
    },

    // 选择所属部门
    chgType(item) {
      console.log(item, "选择所属部门");
      this.dataJson.CooperateTypeKey = item.id;
      this.dataJson.CooperateTypeKeyValue = item.name;
      this.dataJson.joinedContent = [];
      this.dataJson.BasicFranchiseFeeAmount=''
       this.dataJson.ServiceChargeAmount= '';// "服务费",
      this.dataJson.ServiceChargeNum='';// "服务收费间隔",
      this.dataJson.ServiceChargeStartTime=''; // "服务收费开始时间",
       this.dataJson.ContractAmount=this.sumContractAmount()
       

    },
    verifyData() {
      let flag = false;
      this.dataJson.DiscountsAmount=this.vDiscountsAmount
      if (Number(this.dataJson.DiscountsAmount)<0) {
        layer.alert("合同金额不能大于合作内容总和");
        flag = true;
        return flag;
      }
      if (!this.dataJson.ApplicantTime) {
        layer.alert("请选择签约日期");
        flag = true;
        return flag;
      }
      if (!this.dataJson.ApplicantKeyValue) {
        layer.alert("请输入申请人");
        flag = true;
        return flag;
      }
      // if (!this.dataJson.InstitutionName) {
      //   layer.alert("请输入机构名称");
      //   flag = true;
      //   return flag;
      // }
      if (!this.dataJson.Contacts) {
        layer.alert("请输入对接人");
        flag = true;
        return flag;
      }
      if (!this.dataJson.ContactPhone) {
        layer.alert("请输入联系电话");
        flag = true;
        return flag;
      }
       if (this.dataJson.ContactPhone.length != 11) {
        layer.alert("手机号码必须为11位数");
        flag = true;
      }
      if (!this.dataJson.CooperateTypeKeyValue) {
        layer.alert("请选择合作方式");
        flag = true;
        return flag;
      }
      if(this.dataJson.CooperateTypeKey==2){
         if (Number(this.dataJson.BasicFranchiseFeeAmount)==0) {
          layer.alert("基础加盟费不能为0");
          flag = true;
          return flag;
        }
      }
      if (!this.dataJson.ServiceChargeAmount) {
        layer.alert("服务续费不能为空,至少为0");
        flag = true;
        return flag;
      }
      //  if (Number(this.dataJson.DiscountsAmount)==0) {
      //   layer.alert("优惠金额不能为0");
      //   flag = true;
      //   return flag;
      // }
       if (Number(this.dataJson.ContractAmount)==0) {
        layer.alert("合同金额不能为0");
        flag = true;
        return flag;
      }
      //  if (Number(this.dataJson.EarnestAmount)==0) {
      //   layer.alert("定金不能为0");
      //   flag = true;
      //   return flag;
      // }
       if (this.dataJson.attach.length==0) {
        layer.alert("请上传合同附件");
        flag = true;
        return flag;
      }
      //  if (!this.dataJson.AmendingClause) {
      //   layer.alert("请输入修改条款");
      //   flag = true;
      //   return flag;
      // }

     
      return flag;
    },
    // 确认按钮
    confirmClick(callBack) {
      console.log("verifyData",this.dataJson)
  
      if (this.verifyData()) {
        if (callBack) {
          callBack();
        }
        return false;
      }

      this.addOperation(callBack);
    },
    cancelClick() {
      this.$emit("closeDialog");
    },
    // 新增
    addOperation(callBack) {
      HQSignUpToApply(this.dataJson).then(
          (result) => {
            console.log(result, "签约申请");
            this.$emit("afterSuccess", result.data);
            this.$emit("closeDialog");
            layer.alert("签单申请成功");
          },
          (error) => {
            layer.alert(error.msg);
          }
        )
        .finally(() => {
          if (callBack) {
            callBack();
          }
        });
    },
    //通过
    auditPass(){
       HQContractReview(this.dataJson.AttractInvestKey).then(
          (result) => {
            console.log(result, "签约申请");
            this.$emit("afterSuccess", result.data);
            this.$emit("closeDialog");
            layer.alert("签约通过成功");
          },
          (error) => {
            layer.alert(error.msg);
          }
        )
        .finally(() => {
          if (callBack) {
            callBack();
          }
        });
    },
    //拒绝
    auditReject(){
       HQRefuseContractReview(this.dataJson.AttractInvestKey).then(
          (result) => {
            console.log(result, "签约申请");
            this.$emit("afterSuccess", result.data);
            this.$emit("closeDialog");
            layer.alert("签约拒绝成功");
          },
          (error) => {
            layer.alert(error.msg);
          }
        )
        .finally(() => {
          if (callBack) {
            callBack();
          }
        });
    }
  },
};
</script>

<style>
.inline_font_blue .input_readonly{
  color: #3498db !important
}
</style>
