<template>
  <!-- 全部功能弹出层  v-show="isShowAllFunction" -->
  <div class="quick_setting">
    <!-- <div class="shade"
           @mouseenter.stop="cancelSetting"
           style=""></div> -->
    <div class="flex_row space_between">
      <div style="font-size: 16px;font-weight: 700;color: #333333;">快捷功能区</div>
      <div>
        <span class="el-button fl canel_btn el-button--default btn_light_red btn_hover_white"
              style="width: 62px;height: 32px;padding: 8px 0px;"
              @click.stop="cancelSetting">取消</span>
        <span class="el-button el-button--default  btn_hover_bg_blue sumbit_btn"
              style="width: 62px;height: 32px;padding: 8px 0px;"
              @click.stop="comfirmSetting">确认</span>
      </div>
    </div>
    <div class="quick_content_tips">
      已选功能（拖拽可以排序）
    </div>

    <draggable v-model="dataList"
               class="quick_content flex_row"
               :options="{group:{name: 'site'},sort: true}"
               animation="100"
               dragClass="dragClass"
               ghostClass="ghostClass"
               chosenClass="chosenClass"
               filter=".forbid"
               handle=".mover"
               v-bind="{sort: true,store: false,}"
               @start="onStart"
               @end="onEnd"
               :move="onMove"
               @add="onAdd">
      <transition-group class="draggable">
        <quick-options-item v-for="(item, key) in dataList"
                            :key="item.name"
                            :item="item"
                            :showDel="true"
                            @clickItem="clickItem">

        </quick-options-item>
      </transition-group>
    </draggable>

    <div class="quick_content_tips"
         style="margin-top:0px">
      更多功能
    </div>
    <div class="quick_setting_menu_box">
      <div class="quick_setting_menu_ul">
        <div class="pr">
          <div class=""
               v-for="(btnInfo, index) in vallFunctionBtnTypeChange"
               :key="index">
            <div class="menu_list">
              <div class="menu_list_title">
                <div class="title_box">
                  <div class="title_name">{{ btnInfo.title }}</div>
                  <!-- <div class="title_en">{{ btnInfo.english }}</div> -->
                </div>
              </div>
              <div class="menu_list_content">
                <div v-for="(childItem, index) in btnInfo.childList"
                     :key="index"
                     :item="childItem"
                     class="menu_list_btn"
                     :title="childItem.name"
                     @click.stop="clickItem(childItem)"
                     :className="childItem.className"
                     :class="setClass(childItem)">
                  <span style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                    {{childItem.name}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  EditAgentSetting
} from '../../../API/workbench';
import draggable from 'vuedraggable';
import quickOptionsItem from "./quick-options-item";
export default {
  data () {
    return {
      dataList: []
    };
  },
  props: {
    quickList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  created () {
    console.log("this.quickList", this.quickList)
    this.dataList = this.$utils.parseJson(this.quickList)
  },
  components: {
    quickOptionsItem, draggable
  },
  computed: {
    vMendianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vallFunctionBtnList () {
      let allFunctionBtn = {};
      allFunctionBtn = this.$utils.parseJson(this.$initJson.newallFunctionBtn)
      // 根据是否开启了门店设置里的追销决定是否加入.
      if (
        this.vMendianInfo.SaaSClientSetting &&
        this.vMendianInfo.SaaSClientSetting.length > 0
      ) {
        let openAppointCourseSetting = this.vMendianInfo.SaaSClientSetting.find(
          o => {
            return o.SetTypeKey == 73;
          }
        );
        console.log('openAppointCourseSetting', openAppointCourseSetting);
        if (!(openAppointCourseSetting && openAppointCourseSetting.SetKey == 1)) {
          allFunctionBtn.forEach(e => {
            if (e.title == '教务中心') {
              e.childList = e.childList.filter(obj => {
                return obj.routerName != 'appointmentCourse'; // 约课表
              });
              // e.childList.push({
              //   name: '约课表',
              //   routertitle: '约课表',
              //   routerName: 'appointmentCourse',
              //   auth: true,
              //   roleName: '',
              //   ModulePowerKey: [149, 47]
              // });
            }
          });
        }
        let locaItem = this.vMendianInfo.SaaSClientSetting.find(o => {
          return o.SetTypeKey == 17;
        });
        if (
          !this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 6)
        ) {
          console.log('upSellupSell', allFunctionBtn);
          //  allFunctionBtn.splice(4, 0);
        } else if (locaItem && locaItem.SetKey == 1) {
          let upSell = this.$utils.parseJson(this.$initJson.upSell);

          if (locaItem && locaItem.SetContent == '2.0') {    // 追销2.0
            if (Number(this.$store.getters.token.IsAdviserKey) == 0) {
              upSell.childList.splice(0, 2);
            } else {
              upSell.childList.splice(0, 1);
            }
          } else {
            upSell.childList.splice(1, upSell.childList.length - 1);
          }
          console.log('upSell 2.0', upSell, locaItem, this.$store.getters.token.IsAdviserKey);
          allFunctionBtn.splice(6, 0, upSell);
        }
      }
      if (this.vMendianInfo.SaaSClientSetting) {
        if (this.$store.getters.token.SaaSClientKey == 1) {
          // 1号门店
          allFunctionBtn.forEach(e => {
            if (e.title == '基础') {

            }
          });
        } else if (!(this.vMendianInfo.TypeKey == 4 && this.vHasModulePower(87))) {
          allFunctionBtn.forEach(e => {
            if (e.title == '财务') {
              e.childList = e.childList.filter(obj => {
                return obj.routerName != 'moreMendianReport'; // 连锁门店统计
              });

            }
          });
        } else if (
          this.vMendianInfo.IsOpenMicroClassKey &&
          this.vMendianInfo.IsOpenMicroClassKey != 1
        ) {
          // 1.全部功能-》营销：需开通微课堂 才显示
          allFunctionBtn = allFunctionBtn.filter(obj => {
            return obj.title != '营销'; // 微网校设置
          });
        }
      }
      if (!this.vMendianInfo.IsOpenSelfCoursewareKey == 0) {
        allFunctionBtn.forEach(e => {
          if (e.title == '课件加密系统') {
            console.log('课件管理权限', e.childList);
            e.childList = e.childList.filter(obj => {
              return obj.routerName != 'generalCoursewareManagement'; // 微网校设置
            });
            console.log('e.childList', e.childList);
          }
          if (e.title == '课件加密系统') {
            console.log('课件管理权限', e.childList);
            e.childList = e.childList.filter(obj => {
              return obj.routerName != 'generalCoursewareManagement'; // 微网校设置
            });
            console.log('e.childList', e.childList);
          }
        });
      }
      // 开通了销售中心
      if (
        this.vMendianInfo.OpenModule &&
        this.vMendianInfo.OpenModule.length > 0
      ) {
        if (
          !this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 2)
        ) {
          allFunctionBtn.forEach(e => {
            if (e.title == '家校中心') {
              e.childList = e.childList.filter(obj => {
                return obj.routerName != 'creditManagement'; // 微网校设置
              });

            }
          });
        }
        if (
          !this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 4)
        ) {
          allFunctionBtn.forEach(e => {
            if (e.title == '教务中心') {
              e.childList = e.childList.filter(obj => {
                return obj.routerName != 'inventoryManagementFile'; // 库存管理
              });

            }
          });
        }
        if (
          !this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 15)
        ) {
          allFunctionBtn.forEach(e => {
            if (e.title == '师教中心') {
              e.childList = e.childList.filter(obj => {
                return obj.routerName != 'studentManagementEngineer'; // 学管师管理
              });

            }
          });
        }
        if (
          !this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 6)
        ) {
          allFunctionBtn.forEach(e => {
            if (e.title == '教务中心') {
              e.childList = e.childList.filter(obj => {
                return obj.routerName != 'inventoryManagementFile'; // 库存管理
              });

            }
          });
        }
        if (
          !this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 8)
        ) {
          allFunctionBtn.forEach(e => {
            if (e.title == '家校中心') {
              e.childList = e.childList.filter(obj => {
                return obj.routerName != 'mendainSuperviseSchoolStatistics'; // 校区督学综合
              });
              e.childList = e.childList.filter(obj => {
                return obj.routerName != 'readShareReport'; // 课效督学统计
              });

            }
          });
        }
        if (
          !this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 10)
        ) {
          allFunctionBtn.forEach(e => {
            if (e.title == '家校中心') {
              e.childList = e.childList.filter(obj => {
                return obj.routerName != 'recruitStudentShow'; // O2O分销招生
              });

            }
          });
          if (
            this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 1)
          ) {
            allFunctionBtn.forEach(e => {
              if (e.title == '营销') {

              }
            });
          }
        }
        allFunctionBtn.forEach(e => {
          if (e.title == '课件加密系统') {
            // 隐藏自研课件
            if (this.vMendianInfo.IsAuthorizeCoursewareKey && Number(this.vMendianInfo.IsOpenSelfCoursewareKey == 0)
            ) {
              let locaIndex = e.childList.findIndex(item => {
                return item.routerName == 'generalCoursewareManagement'
              })
              console.log("locaIndexlocaIndex", locaIndex)
              if (locaIndex != -1) {
                e.childList.splice(locaIndex, 1);
              }
            }
            // 隐藏授权课件
            if (Number(this.vMendianInfo.IsAuthorizeCoursewareKey == 0) && Number(this.vMendianInfo.IsOpenSelfCoursewareKey == 0)
            ) {
              let locaIndex = e.childList.findIndex(item => {
                return item.moduleName == 'authorizationCoursewareManage'
              })
              console.log("locaIndexlocaIndex", locaIndex)
              if (locaIndex != -1) {
                e.childList.splice(locaIndex, 1);
              }
            }
            console.log("e.childList", e.childList)
            if (this.$store.getters.SaaSClientEditionKey != 5 && this.$store.getters.SaaSClientEditionKey != 6) {

            } else {
              let locaIndex = e.childList.findIndex(item => {
                return item.routerName == 'teachingModeView'
              })
              console.log("locaIndexlocaIndex", locaIndex)
              if (locaIndex != -1) {
                e.childList.splice(locaIndex, 1);
              }
            }

          }
          if (e.title == "更多") {
            if (this.vMendianInfo.EditionKey != 2) {
              let locaIndex = e.childList.findIndex(item => {
                return item.moduleName == 'applyCourse'
              })
              if (locaIndex != -1) {
                e.childList.splice(locaIndex, 1);
              }
              let towlocaIndex = e.childList.findIndex(item => {
                return item.moduleName == 'courseIDApplyList'
              })
              if (towlocaIndex != -1) {
                e.childList.splice(towlocaIndex, 1);
              }
            }
          }
        });


      }

      return allFunctionBtn;
      return allFunctionBtn;
    },
    vallFunctionBtnTypeChange () {
      this.vallFunctionBtnList.forEach(o => {
        if (o.english == "More...") {
          o.hiddenType = false;
        }
      });

      return this.vallFunctionBtnList;
    }
  },
  methods: {
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.$store.getters.RolePowerList, ModulePowerKey);
    },
    onMove (e) {
      console.log('onMove')

    },
    //开始拖拽事件
    onStart (e) {
      // this.draggableItem = this.newMaxCoursewareList[e.oldIndex];

    },
    onAdd (e) {

    },
    // 拖拽结束事件
    onEnd (e) {
      console.log("onEnd to", e)

    },
    // 点击 功能按钮
    clickItem (item) {
      let locaIndex = this.dataList.findIndex(o => {
        return o.name == item.name
      })
      if (locaIndex >= 0) {
        this.dataList.splice(locaIndex, 1)
      } else {
        this.dataList.push(item)
      }
    },
    cancelSetting () {
      this.$emit('cancelSetting')
    },
    comfirmSetting () {
      let newObj = {
        "SetKey": "99",
        "SetValue": JSON.stringify(this.dataList),
      }
      console.log("newObj")
      EditAgentSetting(newObj).then(result => {
        layer.alert("设置成功")
        this.$emit('updateSetting')
      }).catch(error => {
        console.log("EditAgentSetting error", error)
      })
    },
    setClass (item) {
      let locaIndex = this.dataList.findIndex(o => {
        return o.name == item.name
      })
      if (locaIndex >= 0) {
        return 'cost_type'
      } else {
        return 'add_type'
      }
    },

  }
};
</script>
<style scoped>
.quick_setting {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  /* z-index: 4000; */
}
.flex_row {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.space_between {
  justify-content: space-between;
}
.quick_content_tips {
  font-size: 14px;
  color: #999;
  margin-top: 20px;
}
.quick_setting_menu_box {
  position: relative;
  width: 100%;
  height: 100%;
}

.quick_setting_menu_ul {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: 1px solid #ececec;
  position: relative;
  padding: 20px 0px;
}

.quick_setting_menu_ul .pr {
  /* padding-bottom: 90px; */
}

.quick_setting_menu_box .menu_list {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  position: relative;
}
.quick_setting_menu_box .menu_list_title {
  /* background-color: #EDEDED; */
  line-height: 1;
  /* height: 70px; */
  overflow: hidden;
  text-align: left;
  position: relative;
  width: 110px;

  /* justify-content: left; */
}

.quick_setting_menu_box .menu_list_title .title_box {
  /* width: 180px; */
  margin: auto;
  background-color: #fff;
  display: flex;

  align-items: center;
  margin-top: 20px;
  /* padding-left: 20px; */
}

.quick_setting_menu_box .menu_list_title .title_name {
  /* padding-top: 20px; */
  font-size: 16px;
  margin-bottom: 4px;
}

.quick_setting_menu_box .menu_list_title .title_en {
  font-size: 14px;
  color: #999;
  font-family: Arial;
  margin-left: 5px;
}

.quick_setting_menu_box .menu_list_content {
  flex: 1;
  /* padding-left: 10px; */
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.quick_setting_menu_box .menu_list::after {
  display: block;
  content: '';
  height: 1px;
  background-color: #f2f4f8;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -12px;
}
.quick_setting_menu_box .menu_list_content .menu_list_btn {
  width: 84px;
  height: 32px;
  line-height: 32px;
  /* width: 125px;
  height: 42px;
  line-height: 42px; */
  text-align: center;

  border-radius: 4px;
  float: left;
  font-size: 12px;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  /* background-color: #f7f7f7; */
  color: #666;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  border: 1px solid white;
  position: relative;
}

.quick_setting_menu_box .menu_list_content .menu_list_btn:hover {
  border: 1px solid #82cdff;
  /* background-color: #82cdff; */
  color: #82cdff;
}
.quick_setting_menu_box .menu_list_content .menu_list_btn.cost_type {
  background: #f2f2f2;
  border-radius: 8px;
}
.quick_setting_menu_box .menu_list_content .menu_list_btn.cost_type:hover {
  background: none;
  border: 1px solid #2d98d9;
}
.quick_setting_menu_box .menu_list_content .menu_list_btn.add_type {
  border: 1px solid #f2f2f2;
  border-radius: 8px;
}
.quick_setting_menu_box .menu_list_content .menu_list_btn.add_type:hover {
  border: 1px solid #2d98d9;
  border-radius: 8px;
}
.quick_setting_menu_box
  .menu_list_content
  .menu_list_btn.cost_type:hover::before {
  position: absolute;
  display: block;
  content: '';
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  background: url('../../../../public/image/quick_cost.png') center no-repeat;
  background-size: 19px;
}
.quick_setting_menu_box
  .menu_list_content
  .menu_list_btn.add_type:hover::before {
  position: absolute;
  display: block;
  content: '';
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  background: url('../../../../public/image/quick_add.png') center no-repeat;
  background-size: 19px;
}

.quick_setting_menu_box .menu_list_content .menu_list_btn:active {
  border: 1px solid #2e86c1;
  background-color: #2e86c1;
  color: #fff;
}

.header_fixed_mendian_ul ::-webkit-scrollbar,
.quick_setting_menu_box ::-webkit-scrollbar,
.workbench_view ::-webkit-scrollbar,
.home_page_right_contont ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f5f5f5;
}

.header_fixed_mendian_ul ::-webkit-scrollbar-track,
.quick_setting_menu_box ::-webkit-scrollbar-track,
.workbench_view ::-webkit-scrollbar-track {
  background-color: linear-gradient(
    90deg,
    rgba(205, 206, 208, 1),
    rgba(238, 238, 238, 1)
  );
}

.header_fixed_mendian_ul ::-webkit-scrollbar-thumb,
.quick_setting_menu_box ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #cdd1d8;
}

.workbench_view ::-webkit-scrollbar-thumb,
.home_page_right_contont ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #cdd1d8;
}

.header_fixed_mendian_ul ::-webkit-scrollbar-thumb:hover,
.quick_setting_menu_box ::-webkit-scrollbar-thumb:hover,
.workbench_view :-webkit-scrollbar-thumb:hover,
.home_page_right_contont :-webkit-scrollbar-thumb:hover {
  width: 5px;
}

.quick_content {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.quick_content >>> .draggable {
  display: flex;
  flex-wrap: wrap;
}
</style>
