<template>
  <!-- 设置详情按钮弹窗 -->

  <div>
    <!-- 操作菜单 -->
    <div class="course_student_attendance_operation module_content_menuList"
         style="top:30px;left:270px">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="menuItemList"
                     @clickOptionForAlertDialog="doMenuClick"></drop-down-box>
    </div>
  </div>
  <!-- <div class="search_popup">
    <div class="shade"
         @click="$parent.isShowOpreateMenu=false"></div>
    <div class="course_popup"
         style="padding-top:0px">
      <div class="course_popup_ul">

        <div v-for="item in menuItemList" :key="item.opreateType" @click="doMenuClick(item.opreateType)"
             class="course_popup_list border_botom">
          {{item.menuName}}
        </div>

      </div>
    </div>
  </div> -->
</template> 

<script>
import dropDownBox from '../../../common/drop-down-box';
export default {
  props: [
    'menuItemList', // 菜单项
    'menuData' // 当前菜单操作的数据对象
  ],
  // model: {
  //   prop: 'isShowOpreateMenu',
  //   event: 'change'
  // },
  data () {
    return {
    };
  },

  created () {
  },
  components: {
    dropDownBox
  },
  methods: {
    // 选择详情项
    doMenuClick (opreateType) {
      console.log(opreateType, +'-选择详情项-' + this.menuData);
      this.$emit('onItemClick', opreateType, this.menuData);
    }

  }
};
</script>

  <style>
</style>