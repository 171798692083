<template>
  <div class="apply_course_list"
       :class="{'is_solid':planItem.isLast}">
    <div class="apply_course_list_two">
      <div class="clearfix">
        <div class="apply_course_list_left fl">
          <div class="apply_course_list_left_course">
            <span class=""
                  style="background:none">{{planItem.ApplyClassTypeKeyValue}}</span>
          </div>
          <div class="apply_course_list_left_number"
               v-if="Number(planItem.SpendCount)>0||Number(planItem.Apply)>0">
            <span>消耗 <span class="font_black">{{Number(planItem.SpendCount)}}</span> 课时丨当前报课 <span class="font_blue">{{Number(planItem.Apply)>0?Number(planItem.Apply):0}}</span> 课时 </span>
            <div class="box_red"
                 v-if="Number(planItem.SpendCount)-Number(planItem.PlanCount)>0">超上 {{overUse}}</div>
          </div>
        </div>
        <div class="apply_course_list_right fr">
          <div class="apply_course_list_right_btn btn_hover_white button"
               v-if="Number(planItem.activeCount)>0 &&!isSuitable"
               @click.stop="refundCourse">退报</div>
          <div class="apply_course_list_right_btn btn_hover_blue  button"
               :class="(isSuitable||waitPlanCount<=0)?'btn_gray':'button'"
               v-if="!isSuitable"
               @click.stop="applyCourse">报课</div>
        </div>
      </div>
      <div class="apply_course_list_bottom"
           v-if="votherCount>0">
        <span>当前共报课：<span class="font_black">{{Number(planItem.Apply)+votherCount}}</span> 课时</span>
      </div>
    </div>
  </div>
</template>
<script>
import tickListItem from '../apply-course/tick-list-item';
// import { saveDecimal } from '../../../utils';
import { GetTheTicketPlanByApplyClassKey } from '../../../API/workbench';
export default {
  data () {
    return {
      value: 0,
      isShowMore: false,
      dataList: [],
      showtool: false
    };
  },
  props: ['planItem', 'studentItem', 'planList', 'tickItem', 'waitPlanCount'],
  components: {
    tickListItem
  },
  created () {

  },
  watch: {
    planList () {
      this.isShowMore = false;
      this.dataList = [];
    }
  },
  computed: {
    votherCount () {
      return Number(this.planItem.Sum_Apply) - (Number(this.planItem.Apply) > 0 ? Number(this.planItem.Apply) : 0);
      // let count = 0;
      // this.planList.forEach(o => {
      //   if (this.planItem.ApplyClassTypeKey != o.ApplyClassTypeKey) {
      //     count += Number(o.Sum_Apply) - Number(o.Apply);
      //   }
      // });
      // return parseInt(count);
    },
    isSuitable () {
      return (this.planItem.readOnly || this.planItem.IsSuitable == 0 || this.planItem.StateKey == 3);
    },
    vleft () {
      let left = Number(this.planItem.activeCount) / Number(this.tickItem.CurrentCount) * 100;
      if (left < 0) {
        left = 0;
      }
      console.log(left);
      return { 'left': left + '%' };
    },
    // 当前调整数量
    vAdjustCount () {
      this.planItem.adjustCount = this.$utils.saveDecimal(Number(this.planItem.activeCount - this.planItem.Apply));
      console.log('vAdjustCount', this.planItem.adjustCount);
      return this.planItem.adjustCount;
    },
    // 调整后总共.
    vAdjustAfter () {
      // this.planItem.adjustCount = this.planItem.Sum_Apply + this.vAdjustCount;
      // console.log(this.planItem.Sum_Apply, 'vAdjustAfter', this.vAdjustCount);
      return this.planItem.Apply + this.vAdjustCount + this.votherCount;      // 之前的
      // return this.planItem.activeCount + this.votherCount;
    },
    vWidth () {
      let count = 0;
      this.planList.forEach(o => {
        if (this.planItem.ApplyClassTypeKey != o.ApplyClassTypeKey) {
          count += Number(o.activeCount);
        }
      });
      return ((Number(this.tickItem.CurrentCount) - Number(count)) / Number(this.tickItem.CurrentCount)) * 100;
      // if (Number(this.votherCount) == 0) {
      //   return 100;
      // } else {
      // return ((Number(this.tickItem.CurrentCount) - Number(this.votherCount)) / Number(this.tickItem.CurrentCount)) * 100;
      // }
    },
    overUse () {
      return Number((Number(this.planItem.SpendCount) - Number(this.planItem.PlanCount)).toFixed(1));
    }
    // 显示最大值
    // vMax(){
    //   return this.planItem.Sum_Apply + this.planItem.vMax
    // }
  },
  methods: {
    addActiveCount () {
      if (this.planItem.readOnly || this.planItem.IsSuitable == 0 || this.planItem.StateKey == 3) {
        return false;
      }
      this.planItem.activeCount++;
      // if (this.planItem.activeCount > this.planItem.Max) {
      //   this.planItem.activeCount = this.planItem.Max;
      // }.
      console.log((this.vWidth));
      if (this.planItem.activeCount > Number(this.tickItem.CurrentCount) * (Number(this.vWidth) / 100)) {
        this.planItem.activeCount = Number(this.tickItem.CurrentCount) * (Number(this.vWidth) / 100);
      }
    },
    deductdActiveCount () {
      if (this.planItem.readOnly || this.planItem.IsSuitable == 0 || this.planItem.StateKey == 3) {
        return false;
      }
      this.planItem.activeCount--;
      if (this.planItem.activeCount < 0) {
        this.planItem.activeCount = 0;
      }
    },
    chgSlider (value) {
      this.showtool = true;
    },
    chgSliderAfter (value) {
      console.log(value, 'chgSliderAfter');
      this.showtool = false;
      if (this.planItem.activeCount > Number(this.tickItem.CurrentCount) * (Number(this.vWidth) / 100)) {
        this.planItem.activeCount = (Number(this.tickItem.CurrentCount) * (Number(this.vWidth) / 100));
        // this.planItem.activeCount--;
      }
    },
    showMore () {
      this.isShowMore = !this.isShowMore;
      if (this.isShowMore) {
        this.getTheTicketPlanByApplyClassKey();
      }
    },
    // 返回该学生指定课名的所有可用课单，及其下的报课计划
    getTheTicketPlanByApplyClassKey () {
      this.dataList = [];
      GetTheTicketPlanByApplyClassKey(this.studentItem.OLAPKey, this.planItem.ApplyClassTypeKey)
        .then(result => {
          result.data.dataList.forEach(obj => {
            console.log(this.tickItem.TableID, 'obj', obj.TableID);
            if (this.tickItem.TableID != obj.TableID && Number(obj.HasBingCount) != 0) {
              this.dataList.push(obj);
            }
          });
          console.log(this.dataList, 'getTheTicketPlanByApplyClassKey');
        })
        .catch(error => {
          layer.alert(error.msg);
        });
    },
    refundCourse () {
      console.log(this.planItem, 'refundCourse');
      this.$emit('refundCourse', this.planItem);
    },
    applyCourse () {
      if (this.isSuitable) {
        // layer.alert('该课名不能安排报课');
        return false;
      } else if (this.waitPlanCount <= 0) {
        // layer.alert('该课单已经没有足够课时可报课');
        return false;
      }
      this.$emit('applyCourse', this.planItem);
    }

  }
};
</script>
<style scoped>
.is_gray {
  color: #c333c3;
}
</style>


