<template>
  <div class="from_btn_box flex pa">
    <div>
      <zx-button :btnText="btnLeftName"
                 :btnClass="'from_next_btn'+' '+btnLeftClass"
                 :isSingleSubmit="true"
                 :singleSubmitType="'dynamic'"
                 @click="doBtnLeftClick"></zx-button>
    </div>
    <div>
      <zx-button :btnText="btnRightName"
                 :btnClass="'from_next_btn btn_hover_bg_blue'"
                 :isSingleSubmit="true"
                 :singleSubmitType="'dynamic'"
                 @click="doBtnRightClick"></zx-button>

    </div>
  </div>
</template>

<script>
import zxButton from '../../common/zx-button';
export default {
  components: { zxButton },
  props: {
    btnLeftName: {//  按钮 （选填）
      type: String,
      default: '取消'
    },
    btnLeftClass: {//  左侧默认样式 （选填）
      type: String,
      default: 'btn_hover_bg_white'
    },
    btnRightName: {//  按钮 （选填）
      type: String,
      default: '确定'
    }
  },
  methods: {
    doBtnLeftClick (callBack) {
      this.$emit('doBtnLeftClick', callBack);
    },
    doBtnRightClick (callBack) {
      this.$emit('doBtnRightClick', callBack);
    }
  }

};
</script>

<style>
</style>