<template>
  <div>
    <div class="all_course_table_content"
         @scroll="doAfterEventScrollTOP"
         style="padding: 0 14px 10px;">
      <rule-course-list v-for="item in allCourseTableList"
                        style="width: 100%;"
                        :key="item.index"
                        :dataObj="item"
                        :contentBoxTitle="'BJPK'"
                        @openOrCloseDetail="downOrUpDetail"
                        @doOneTopValueEvent="doOneTopValueEvent"></rule-course-list>
      <div v-if="allCourseTableList.length==0"
           class="course_block_nodata"
           :style="nodataPadding>0?('margin-top:'+nodataPadding+'px'):''">
        <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
      </div>
    </div>
    <!-- 点击循环课 展开更多操作 -->
    <div class="course_student_attendance_operation module_content_menuList"
         :style="{'top':topValue+'px','left':'auto','position': 'absolute','right':'-30px'}"
         v-show="isShowPopUpMenu">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="popupsMenu"
                     @clickOptionForAlertDialog="doClickPopMenuItem"></drop-down-box>
    </div>
    <!-- 调整上课时间 -->
    <custom-dialog title="调整上课时间"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowEditClassTimeDialog"
                   :before-close="closeEditClassTimeDialog">
      <edit-class-time :circleCourseInfo="circleCourseInfo"
                       @closeDialog="closeEditClassTimeDialog">

      </edit-class-time>
    </custom-dialog>
    <!-- 调整上课时间 -->
    <custom-dialog title="调整老师"
                   class="popup_dialog_height_from defult_dialog"
                   :visible.sync="isShowEditTeacherDialog"
                   :before-close="closeEditTeacherDialog">
      <edit-teacher :circleCourseInfo="circleCourseInfo"
                    @closeDilog="closeEditTeacherDialog">

      </edit-teacher>
    </custom-dialog>
  </div>

</template>

<script>
import { SearchStructedRule, AbortStructuredRule, StopNotEndStructuredRule } from '../../../API/workbench.js';
import ruleCourseList from './rule-course-list';
import dropDownBox from '../../common/drop-down-box';
import editClassTime from '../edit-circle-course-table/edit-class-time';
import editTeacher from '../edit-circle-course-table/edit-teacher';
import studentList from '../../common/student-list';

export default {
  data () {
    return {
      circleCourseInfo: {},
      courseTableList: [],
      allCourseTableList: [],
      isShowPopUpMenu: false,
      topValue: 0,
      popupsMenu: [
        { name: '查看详情', eventName: 'detail', isShow: true, ModulePowerKey: 47 },
        { name: '修改课表', eventName: 'endCourseTable', isShow: true, ModulePowerKey: 47 },
        { name: '续建课表', eventName: 'continueNewCircleCourse', isShow: true, ModulePowerKey: 47 },
        { name: '结束循环课', eventName: 'endCircleCourse', isShow: true, ModulePowerKey: 47 },
        { name: '安排学生', eventName: 'arrangeStudent', isShow: true }
      ],
      isShowEditClassTimeDialog: false, // 调整上课时间
      isShowEditTeacherDialog: false// 调整老师
    };
  },
  props: {
    courseNameKey: String,
    classNameKey: String,
    taoCanKey: String,
    nodataPadding: {
      type: Number,
      default: 0
    }
  },
  components: {
    ruleCourseList,
    dropDownBox,
    studentList,
    editClassTime,
    editTeacher
  },
  computed: {
    vClassList () {
      return this.groupByGlass(this.courseTableList);
    }
  },
  watch: {
    courseNameKey (n, o) {
      if (n && n != o) {
        this.isShowPopUpMenu = false;
      }
      this.loadStructedRule();
    },
    classNameKey (n, o) {
      this.loadStructedRule();
    },
    vClassList (newList, o) {
      let allCourseTableArr = [];
      newList.forEach((obj, index) => {
        let findItem = this.allCourseTableList.find(item => {
          return item.OLAPKey == obj.OLAPKey;
        });
        if (findItem) {
          findItem.dataList = obj.dataList;
          allCourseTableArr.push(findItem);
        } else {
          obj.isOpen = false;
          allCourseTableArr.splice(index, 0, obj);
        }
      });
      if (allCourseTableArr.length == 1) {
        allCourseTableArr[0].isOpen = true;
      }
      this.allCourseTableList = allCourseTableArr;
    }
  },
  created () {
    this.loadStructedRule();
    this.registerBusEvent();
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isShowPopUpMenu = false;
    }, false);
  },
  methods: {
    // 获取排课规律课表
    loadStructedRule () {
      if (this.classNameKey) {
        this.popupsMenu[4].isShow = false;// 精准版班级排课=》隐藏安排学生按钮
      }
      SearchStructedRule(this.courseNameKey, this.classNameKey).then(result => {
        this.courseTableList = result.data;
        this.$emit('structedRuleData', this.courseTableList);
      });
    },
    // 按班级分组
    groupByGlass (dataSource) {
      let newList = [];
      dataSource.forEach(obj => {
        if (this.courseNameKey > 0) {
          let course = newList.find(item => {
            return item.OLAPKey == obj.GradeClassKey;
          });
          // 班级名称不存在 添加一项
          if (!course) {
            newList.push({ OLAPKey: obj.GradeClassKey, MainDemoName: obj.GradeClassKeyValue, dataList: [obj] });
          } else {
            course.dataList.push(obj);
          }
        } else {
          let course = newList.find(item => {
            return item.OLAPKey == obj.CourseNameKey;
          });
          // 班级名称不存在 添加一项
          if (!course) {
            newList.push({ OLAPKey: obj.CourseNameKey, MainDemoName: obj.CourseNameKeyValue, dataList: [obj] });
          } else {
            course.dataList.push(obj);
          }
        }
      });
      return newList;
    },
    // 展开收起
    downOrUpDetail (item) {
      this.isShowPopUpMenu = false;
    },
    // 点击一节课 弹出层位置计算/计算TOP值
    doOneTopValueEvent (courseInfo, e) {
      this.circleCourseInfo = courseInfo;
      let offsetTop = e.currentTarget.offsetTop;
      let scrollTop = document.getElementsByClassName('all_course_table_content')[0].scrollTop;
      this.topValue = offsetTop - scrollTop + 42;
      let stopItem = this.popupsMenu.find(obj => { return obj.eventName == 'stopContinueNewCircleCourse'; });
      let continueItem = this.popupsMenu.find(obj => { return obj.eventName == 'continueNewCircleCourse'; });
      if (courseInfo.IsNotEndKey == 1) { // 不结束
        if (continueItem) {
          continueItem.name = '停止续课';
          continueItem.eventName = 'stopContinueNewCircleCourse';
        }
      } else {
        if (stopItem) {
          stopItem.name = '续建课表';
          stopItem.eventName = 'continueNewCircleCourse';
        }
      }
      this.isShowPopUpMenu = true;
    },
    // 滚动后触发获取弹窗位置
    doAfterEventScrollTOP (e) {
      this.isShowPopUpMenu = false;
    },
    doClickPopMenuItem (menu) {
      this.isShowPopUpMenu = false;
      switch (menu.eventName) {
        case 'detail': // 循环课详情
          let item = {
            routerName: this.$route.name,
            routertitle: this.$route.meta.title,
            moduleName: 'circleCourseDetail',
            data: { ruleID: this.circleCourseInfo.StructuredRuleKey }
          };
          this.$dialog.open(this, item);
          break;
        case 'stopContinueNewCircleCourse': // 停止续课
          layer.confirm('请确认是否停止续课?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.stopNotEndStructuredRule();
                  layer.close();
                }
              }
            ]
          });
          break;
        case 'continueNewCircleCourse': // 续建课表
          let params = {
            routerName: this.$route.name,
            routertitle: this.$route.meta.title,
            moduleName: 'continueNewCircleCourseTable',
            data: { ruleID: this.circleCourseInfo.StructuredRuleKey }
          };
          this.$dialog.open(this, params);
          break;
        case 'endCourseTable': // 修改课表
          let courseParams = this.$utils.parseJson(this.circleCourseInfo);
          this.$dialog.open(this, { routerName: this.$route.name, routertitle: this.$route.meta.title, moduleName: 'editCircleCourseTable', data: { circleCourseInfo: courseParams } });
          break;
        case 'changeClassTime': // 调整上课时间
          this.isShowEditClassTimeDialog = true;
          break;
        case 'changeTeacher': // 调整老师
          this.isShowEditTeacherDialog = true;
          break;
        case 'endCircleCourse':// 结束循环课
          layer.confirm('请确认是否结束循环课?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  let IsProgressCourse = 0;// 是否终止进行中的课 0-否;1-是
                  this.abortStructuredRule(IsProgressCourse, 1);
                  layer.close();
                }
              }
            ]
          });
          break;
        case 'arrangeStudent':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            moduleName: 'studentList',
            name: '安排学生',
            data: { CustStatusKey: 1, switchItem: 0, isShowaddOldStudentBtn: true, isShowAddBtn: true },
            callBack: {selectedItem: (studentInfo) => { this.doSelectedChooseStudent(studentInfo); }}
          });
          break;
        default:
          break;
      }
    },
    // 停止续课
    stopNotEndStructuredRule () {
      StopNotEndStructuredRule(this.circleCourseInfo.StructuredRuleKey).then(result => {
        layer.alert('停止续课成功');
        this.circleCourseInfo.IsNotEndKey = 0;
        this.circleCourseInfo.IsNotEndKeyValue = '否';
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 结束循环课
    abortStructuredRule (IsProgressCourse, IsNotEndKey) {
      if(this.circleCourseDetail.StructuredRuleKey>0){
        AbortStructuredRule(this.circleCourseInfo.StructuredRuleKey, IsProgressCourse, this.circleCourseInfo.CurrentRuleTime, this.circleCourseInfo.LastClassTime, IsNotEndKey).then(result => {
        this.$bus.emit('AfterAbortCircleCorse', this.circleCourseInfo.StructuredRuleKey);
      }, error => {
        if (error.code == 10000) {
          layer.alert(error.msg);
        } else if (error.code == 60104) {
          layer.confirm('有进行中的课，是否继续结束循环课?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  let IsProgressCourse = 1;// 是否终止进行中的课 0-否;1-是
                  this.abortStructuredRule(IsProgressCourse, IsNotEndKey);
                  layer.close();
                }
              }
            ]
          });
        } else if (error.code == 70000) {
          layer.confirm(error.msg, {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.abortStructuredRule(IsProgressCourse, 0);
                  layer.close();
                }
              }
            ]
          });
        } else {
          layer.alert(error.msg);
        }
      });
    
      }
     
    },
    // 新建课表
    newCourseTable () {
      let item = {
        routerName: this.$route.name,
        routertitle: this.$route.meta.title,
        moduleName: 'newCircleCourseTable',
        data: { dataInfo: { ClassSetKey: this.courseNameKey, ClassSetKeyValue: this.courseName } }
      };
      this.$bus.emit('needOpenRouter', item);
    },
    // 安排学生
    doSelectedChooseStudent (studentInfo) {
      let item = {
        routerName: this.$route.name,
        moduleName: 'batchArrangeScheduleCourse',
        data: { paramsInfo: { studentKey: studentInfo.OLAPKey, ruleID: this.circleCourseInfo.StructuredRuleKey, courseNameKey: this.courseNameKey, classGradeKey: this.circleCourseInfo.GradeClassKey } }
      };
      this.$dialog.open(this, item);
    },
    // 关闭修改课表
    closeBatchEditCourseDialog () {
      this.isShowBatchEditCourseDialog = false;
    },
    // 关闭 调整上课时间
    closeEditClassTimeDialog (ruleID) {
      this.reLoadData(ruleID);
      this.isShowEditClassTimeDialog = false;
    },
    // 关闭 调整老师
    closeEditTeacherDialog (ruleID) {
      this.reLoadData(ruleID);
      this.isShowEditTeacherDialog = false;
    },
    registerBusEvent () {
      // 新建课 成功后
      this.$bus.on(this, 'AfterNewCourseTable', (type, data) => {
        this.loadStructedRule();
      });
      // 新建循环课成功后 批量修改排课计划
      this.$bus.on(this, 'AfterBatchEditStructuredPlanList', (courseNameID, classGradeId, planList) => {
        this.loadStructedRule();
      });
      // 批量删除排课计划
      this.$bus.on(this, 'AfterBatchDeleteStructuredPlanList', (courseNameID, classGradeId, planList) => {
        this.loadStructedRule();
      });
      // 批量停课 courseIDList 1、存在时 已停课ID列表 2、可不传（按规律停课）
      this.$bus.on(this, 'AfterBatchStopCourses', (courseNameID, classGradeId, courseIDList) => {
        this.reLoadData(classGradeId);
      });
      // 批量修改课
      this.$bus.on(this, 'AfterBatchEditCourses', (courseNameID, classGradeId) => {
        if (classGradeId) {
          this.reLoadData(classGradeId);
        }
      });
      this.$bus.on(this, 'AfterEditCircleCourse', ruleID => {
        this.loadStructedRule();
      });
      this.$bus.on(this, 'AfterAbortCircleCorse', (ruleID) => {
        this.reLoadData(ruleID); // 终止循环节
      });
      this.$bus.on(this, 'AfterNewCourseTable', (type, params) => {
        if (params.CourseNameKey == this.courseNameKey) { this.loadStructedRule(); }
      });
    },
    // 重新刷新数据
    reLoadData (ruleID) {
      if (ruleID) {
        this.loadStructedRule();
      }
    }
  }
};
</script>

<style>
</style>