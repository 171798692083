<template>
  <!-- 回收站-->
  <div class="recycle_bin_list">
    <div class="search_clue_div">
      <!-- 已选条件 -->
      <input-search-contain-btn v-model="queryParams.searchText"
                                :placeholder="'搜索学生'"
                                @clearSearch="clearExtendSearchParams"
                                @onSearchEvent="search"></input-search-contain-btn>

      <div class="mendian_backstage_search">
        <div class="btn_hover_bg_blue search_btn "
             style="margin-left: 20px;"
             @click.stop="doExportInfoClick">导出</div>
      </div>

    </div>

    <div class="table_list_content introduce_list campusManagement_list">
      <table-list ref="tableListRef"
                  class="summarizing_list schedule_table_class"
                  :tableData="recruitStudentsList"
                  :queryParams="queryParams"
                  :tableColumns="tableColumns"
                  :rowKey="'OLAPKey'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  @loadTableData="getMessageSearchPageStudentThreadRecycleList"></table-list>
    </div>
    <custom-dialog :title="'分配员工'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowSalesList"
                   :before-close="closeForm">
      <get-sale-list :selectedKey="selectedKey"
                     :OLAPKey="OLAPKey"
                     @closeForm="closeForm"
                     @onSelectedItem="onSelectedItem">
      </get-sale-list>
    </custom-dialog>
    <custom-dialog title="跟单记录流水"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowRecordList"
                   :before-close="doShowFollowUpHide">
      <clew-follow-record-list :studentItem="studentItem"
                               :StudentThreadKey="StudentThreadKey"
                               :isAllFollowRecord="true"
                               @close="doShowFollowUpHide"></clew-follow-record-list>
    </custom-dialog>
  </div>
</template>
<script>
import { MessageSearchPageStudentThreadRecycleList } from '../../../API/workbench.js';
import inputSearchContainBtn from '../../report/common/input-search-contain-btn';
import tableList from '../../common/table-list/index';
import getSaleList from '../form/getSale-list.vue';// 销售
import clewFollowRecordList from '../../recruit-students-workbenches/clue-list/clew-follow-record-list';
export default {
  data () {
    return {
      studentItem: null,
      StudentThreadKey: 0,
      isShowRecordList: false,
      isShowSalesList: false, // 销售
      selectedKey: '', // 销售ID
      OLAPKey: '', // 线索ID
      queryParams: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        IsExportKey: 0
      },
      recruitStudentsList: [],
      tableColumns: [
        {
          label: '序号',
          width: 65,
          prop: 'Number',
          align: 'center',
          type: 'tableIndex',
          sortable: false
        },
        {
          label: '回收日期',
          width: 100,
          sortable: 'custom',
          prop: 'RecycleTime',
          type: 'text-item'
        },
        {
          label: '归属销售',
          prop: 'SalesKeyValue',
          width: 90,
          align: 'center',
          type: 'text-item'
          // extend: {
          //   isClick: true,
          //   click: (rowData) => {
          //     this.doFollowClick(rowData);
          //   }
          // }
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 110,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '手机号',
          prop: 'MobilePhone',
          width: 110,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '节点',
          prop: 'CurrentNodeKeyValue',
          align: 'left',
          width: 120,
          type: 'text-item'
        },
        {
          label: '标签',
          prop: 'LabelNames',
          width: 130,
          align: 'left',
          type: 'text-item'
        },
        {
          label: '最近跟单',
          prop: 'FollowUpTime',
          width: 100,
          align: 'left',
          type: 'text-item'
        },
        {
          label: '最近跟单',
          prop: 'FollowContentValue',
          align: 'left',
          type: 'text-item',
          extend: {
            type: 'expand',
            click: (rowData) => { this.doFollowContentClick(rowData); }
          }
        }
      ],
      totalNum: 0
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
    getSaleList,
    clewFollowRecordList
  },
  computed: {
  },
  props: {
  },
  created () {
    this.getMessageSearchPageStudentThreadRecycleList();
  },
  watch: {
  },
  activated () {
  },
  mounted () {
  },
  methods: {
    closeForm () {
      this.isShowSalesList = false;// 销售
    },
    // 打开员工列表
    doFollowClick (rowData) {
      this.selectedKey = rowData.SalesKey;
      this.OLAPKey = rowData.StudentThreadKey;
      this.isShowSalesList = true;
    },
    onSelectedItem (item) {
      console.log('item0,', item);
      this.isShowSalesList = false;// 销售
      this.getMessageSearchPageStudentThreadRecycleList();
    },
    doFollowContentClick (item) {
      this.StudentThreadKey = item.StudentThreadKey;
      this.studentItem = item;
      this.isShowRecordList = true;
    },
    doShowFollowUpHide () {
      this.isShowRecordList = false;
    },
    // 回收站 数据查询
    getMessageSearchPageStudentThreadRecycleList () {
      MessageSearchPageStudentThreadRecycleList(this.queryParams).then(result => {
        this.queryParams.IsExportKey = 0;
        this.totalNum = result.data.Total;
        this.recruitStudentsList = result.data.PageDataList;
        this.recruitStudentsList.forEach(o => {
          o.StudentKeyValue = o.StudentKeyValue ? o.StudentKeyValue : '-';
          o.MobilePhone = o.MobilePhone ? o.MobilePhone : '-';
          o.CurrentNodeKeyValue = o.CurrentNodeKeyValue ? o.CurrentNodeKeyValue : '-';
          o.LabelNames = o.LabelNames ? o.LabelNames : '-';
          o.FollowUpTime = o.FollowUpTime ? o.FollowUpTime : '-';
          o.FollowContentValue = o.FollowContent ? '<div class="recently_documentary_class">' + o.FollowContent + '</div>' : '';
          o.SalesKeyValue = !o.SalesKeyValue ? '未分配' : o.SalesKeyValue;
        });
        console.log(result, '回收站 数据查询');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.recruitStudentsList.length > 0) {
        var newData = this.$utils.parseJson(this.queryParams);
        this.queryParams.IsExportKey = 1;
        this.queryParams.pageIndex = 0;
        this.queryParams.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '招生管理-回收站',
          clist: [
            { title: '回收日期', cName: 'RecycleTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '归属销售', cName: 'SalesKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生名', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '手机号', cName: 'MobilePhone', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '节点', cName: 'CurrentNodeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '标签', cName: 'LabelNames', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '最近跟单', cName: 'FollowUpTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '最近跟单', cName: 'FollowContent', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        MessageSearchPageStudentThreadRecycleList(this.queryParams, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.queryParams.IsExportKey = 0;
          this.queryParams.pageIndex = newData.pageIndex;
          this.queryParams.pageSize = newData.pageSize;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    search () {
      this.queryParams.pageIndex = 0;
      this.getMessageSearchPageStudentThreadRecycleList();
    },
    clearExtendSearchParams () {
      this.queryParams = {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        IsExportKey: 0
      };
      this.getMessageSearchPageStudentThreadRecycleList();
    }
  }
};
</script>

<style scoped>
.search_clue_div {
  display: flex;
  margin-bottom: 20px;
}
.schedule_table_class >>> .el-table .recently_documentary_class {
  width: 99%;
  max-height: 84px;
  line-height: 54px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  right: 1px;
  z-index: 13;
  border: 1px solid transparent !important;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 40px;
  color: #333 !important;
}
.schedule_table_class >>> .el-table .recently_documentary_class::after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -11.5px;
  right: 20px;
  width: 21px;
  height: 21px;
  padding-right: 17px;
  z-index: 2;
  background: url(../../../../public/image/course_header.png) no-repeat 50%;
  background-size: 18px 12px;
}
</style>

