<template>
  <div class="edit_class_time edit_class_from">
    <div class="content_marginRL content_marginTop_30 single_shadow">
      <course-info :circleCourseInfo="circleCourseInfo"
                   v-if="vIsShowCircleCourseItem"></course-info>
      <choose-class-time-range :circleCourseInfo="circleCourseInfo" @confirmClassRangeTime="confirmClassRangeTime"></choose-class-time-range>
      <div class="new_course_title border_top_line"
             style="margin-bottom:12px">
          修改内容
        </div>
      <div class="mendian_setting_content form_info_edit">
        <select-class-time-rang :classTimeData="{SClassTimeName:sClassTimeName,EClassTimeName:eClassTimeName}"
                                :formInfoLine="false"
                                @onSelectTimeRange="selectTimeRange"
                                @onDelTimeRange="delTimeRange"></select-class-time-rang>

        <input-select :dataList="weekList"
                      :formTitle="'星期'"
                      :id="'id'"
                      class="no_border_bottom form_info_border_type"
                      :name="'name'"
                      :required="true"
                      :readonly="false"
                      :selectedInfo="selectedWeekInfo"
                      @selectedItem="changeWeekKey"></input-select>
      </div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="closeDilog"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import courseInfo from '../course-info';
import chooseClassTimeRange from './common/choose-class-time-range';
import timePicker from '../../common/time-picker';
import { EditClassTimeByRuleId } from '../../../API/workbench';
export default {
  data () {
    return {
      sClassTimeName: '', // 开始上课时间
      eClassTimeName: '', // 结束上课时间
      sClassDate: '', // 开始上课时间
      eClassDate: '', // 结束上课时间
      selectedWeekInfo: { name: '', id: 0 },
      timeRangeForClassTime: this.$utils.getSaaSClientSetInfo(64).SetContent + ':00-' + this.$utils.getSaaSClientSetInfo(65).SetContent + ':00',
      weekList: [
        { name: '周一', id: 1 },
        { name: '周二', id: 2 },
        { name: '周三', id: 3 },
        { name: '周四', id: 4 },
        { name: '周五', id: 5 },
        { name: '周六', id: 6 },
        { name: '周日', id: 7 }
      ]
    };
  },
  components: {
    courseInfo,
    timePicker,
    chooseClassTimeRange
  },
  props: {
    circleCourseInfo: {
      type: Object
    }
  },
  computed: {
    vTimeRangeLength () {
      let timeRangeStr = '';
      if (this.sClassTimeName && this.eClassTimeName) {
        let stArr = this.sClassTimeName.split(':');
        let etArr = this.eClassTimeName.split(':');
        var SClassTime = Number(stArr[0]) * 60 + Number(stArr[1]);
        var EClassTime = Number(etArr[0]) * 60 + Number(etArr[1]);
        timeRangeStr = Number(EClassTime - SClassTime) + '分钟';
      }
      return timeRangeStr;
    },
    vClassTimeRangeLength () {
      let classTimeName = this.circleCourseInfo.ClassTimeName.split('-');
      if (classTimeName[0] && classTimeName[1]) {
        let stArr = classTimeName[0].split(':');
        let etArr = classTimeName[1].split(':');
        var SClassTime = Number(stArr[0]) * 60 + Number(stArr[1]);
        var EClassTime = Number(etArr[0]) * 60 + Number(etArr[1]);
        return Number(EClassTime - SClassTime);
      }
    },
    vIsShowCircleCourseItem () {
      let bool = false;
      if (!this.circleCourseInfo.SubRule) { // SubRule为NULL 就显示循环课块
        bool = true;
      }
      return bool;
    },
    vSaasClientInfo () {
      return this.$store.getters.SaaSClientInfo;
    }
  },
  watch: {
    sClassTimeName (n, o) {
      let mistiming = 0;
      if (!this.vTimeRangeLength) {
        mistiming = this.vClassTimeRangeLength || 0;
      }
      if (mistiming > 0 && !this.eClassTimeName) {
        let earliesHour = Number(this.$utils.getSaaSClientSetInfo(64).SetContent.split(':')[0]);
        let latestHour = Number(this.$utils.getSaaSClientSetInfo(65).SetContent.split(':')[0]);
        let latestMin = Number(this.$utils.getSaaSClientSetInfo(65).SetContent.split(':')[1]);
        var mins = Number(n.slice(0, 2)) * 60 + Number(n.slice(3, 5)) + mistiming;
        var hour = 0;
        var min = 0;
        min = mins % 60;
        hour = (mins - min) / 60;
        hour = hour < earliesHour ? earliesHour : hour;
        if (hour >= latestHour) {
          hour = latestHour;
          min = latestMin;
        }
        this.eClassTimeName = (hour < 10 ? '0' + hour : hour) + ':' + (min < 10 ? '0' + min : min); // 计算结束时间值
      }
    },
    eClassTimeName (n, o) {
      let mistiming = 0;
      if (!this.vTimeRangeLength) {
        mistiming = this.vClassTimeRangeLength || 0;
      }
      if (n) {
        if (mistiming > 0 && !this.sClassTimeName) {
          let earliesHour = Number(this.$utils.getSaaSClientSetInfo(64).SetContent.split(':')[0]);
          var mins = Number(n.slice(0, 2)) * 60 + Number(n.slice(3, 5)) - mistiming;
          var hour = 0;
          var min = 0;
          min = mins % 60;
          hour = (mins - min) / 60;
          hour = hour < earliesHour ? earliesHour : hour;
          this.sClassTimeName = (hour < 10 ? '0' + hour : hour) + ':' + (min < 10 ? '0' + min : min); // 计算开始时间值
        }
      }
    }
  },
  created () {
    this.sClassDate = this.circleCourseInfo.SubRule ? this.circleCourseInfo.SubRule.StartTime : this.circleCourseInfo.CurrentRuleTime;
    this.eClassDate = this.circleCourseInfo.SubRule ? this.circleCourseInfo.SubRule.EndTime : this.circleCourseInfo.LastClassTime;

    this.selectedWeekInfo.id = this.circleCourseInfo.WeekKey;
    this.selectedWeekInfo.name = this.circleCourseInfo.WeekKeyValue;
    this.sClassTimeName = this.circleCourseInfo.ClassTimeName.split('-')[0];
    this.eClassTimeName = this.circleCourseInfo.ClassTimeName.split('-')[1];
  },
  methods: {
    // 确认 开始上课时间
    confirmSClassTime (data) {
      this.sClassTimeName = data;
    },
    // 确认 结束上课时间
    confirmEClassTime (data) {
      this.eClassTimeName = data;
    },
    changeWeekKey (data) {
      this.selectedWeekInfo.id = data.id;
      this.selectedWeekInfo.name = data.name;
    },
    confirmClick (callback, IsNotEndKey) {
      IsNotEndKey = IsNotEndKey == undefined ? 1 : IsNotEndKey;
      let sClassTime = this.sClassTimeName.replace(/:/g, '');
      let eClassTime = this.eClassTimeName.replace(/:/g, '');
      let flag = false;
      if (!sClassTime) {
        flag = true;
        layer.alert('上课开始时间不可为空');
      } else if (!eClassTime) {
        flag = true;
        layer.alert('上课结束时间不可为空');
      } else if (sClassTime > eClassTime) {
        flag = true;
        layer.alert('上课开始时间不能晚于结束时间');
      } else if (sClassTime == eClassTime) {
        flag = true;
        layer.alert('上课开始时间不能等于结束时间');
      } else if (!this.selectedWeekInfo.id) {
        flag = true;
        layer.alert('请先选择一个星期');
      }
      if (flag) {
        if (typeof callback == 'function') { callback(); }
        return;
      }
      EditClassTimeByRuleId(this.circleCourseInfo.StructuredRuleKey,
        this.sClassDate, this.eClassDate, this.selectedWeekInfo.id, this.sClassTimeName, this.eClassTimeName, IsNotEndKey).then(resule => {
          layer.alert('修改循环课上课时间成功');
          this.closeDilog(resule.data.ruleID);
        }, error => {
          if (error.code == 10001) {
            layer.alert('课程表冲突，请检查后再修改');
          } else if (error.code == 70000) {
            layer.confirm(error.msg, {
              btn: [
                {
                  name: '确认',
                  callBack: () => {
                    this.confirmClick(callback, 0);
                    layer.close();
                  }
                }
              ]
            });
          } else {
            layer.alert(error.msg);
          }
        }).finally(() => {
          if (typeof callback == 'function') { callback(); }
        });
    },
    closeDilog (ruleID) {
      this.$emit('closeDialog', ruleID);
    },
    // 确认 修改课表范围
    confirmClassRangeTime (startDate, endDate, classNum) {
      this.sClassDate = startDate;
      this.eClassDate = endDate;
    },
    // 选中时间段查询
    selectTimeRange (item) {
      this.sClassTimeName = item.SClassTimeName;
      this.eClassTimeName = item.EClassTimeName;
    },
    // 删除时间段
    delTimeRange () {
      this.sClassTimeName = '';
      this.eClassTimeName = '';
    }
  }
};
</script>
<style >
</style>