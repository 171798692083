<template>
  <div class="table_tr">
    <!-- 序号 -->
    <div class="table_td table_index"
         style="width:50px">
      {{vNumber}}
    </div>
    <!-- 提交时间 -->
    <div class="table_td"
         style="width:150px">
      <div>{{ vSubmitTime}}</div>
    </div>
    <!-- 学生名 -->
    <div class="table_td table_cell_select"
         style="width:112px"
         @click="cilckStudentNameEvent(contentItem)">
      <div ref="studentValWidth"
           :title="$refs.studentValWidth&&$refs.studentValWidth.scrollWidth>120?contentItem.StudentKeyValue:''">{{contentItem.StudentKeyValue}}</div>
    </div>
    <!-- 性别 -->
    <div class="table_td"
         style="width:60px">
      <div style="padding-left:8px">{{contentItem.SexKeyValue}}</div>
    </div>
    <!-- 手机号 -->
    <div class="table_td table_cell_select_phone table_cell_select"
         style="width:124px"
         @click="cilckPhoneNameEvent(contentItem)">
      <div style="">{{$utils.phoneModulePower(contentItem.CustomerPhoneName)}}</div>
    </div>
    <!-- 课程 -->
    <div class="table_td"
         style="width:155px">
      <div ref="applyClassValWidth"
           :title="$refs.applyClassValWidth&&$refs.applyClassValWidth.scrollWidth>170?contentItem.ApplyClassKeyValue:''">{{contentItem.ApplyClassKeyValue}}</div>
    </div>
    <!-- 购买日期 -->
    <div class="table_td"
         style="width:122px">
      <div>{{contentItem.PayTime}}</div>
    </div>
    <!-- 失效日期 -->
    <div class="table_td"
         style="width:122px">
      <div>{{contentItem.ExpireTime}}</div>
    </div>
    <!-- 购买金额 -->
    <div class="table_td"
         style="width:70px">
      <div>{{ contentItem.ActualPayAmount&&contentItem.ApplyClassKeyValue?$utils.mAmountType(contentItem.ActualPayAmount,2):''}}</div>
    </div>
    <!-- 购买课时 -->
    <div class="table_td"
         style="width:74px">
      <div>{{ contentItem.NumCount&&contentItem.ApplyClassKeyValue?$utils.mAmountType(contentItem.NumCount,1):''}}</div>
    </div>
    <!-- 剩余课时 -->
    <div class="table_td"
         style="width:74px">
      <div>{{ contentItem.CurrentCount&&contentItem.ApplyClassKeyValue?$utils.mAmountType(contentItem.CurrentCount,1):''}}</div>
    </div>
    <div class="table_td tc"
         style="width:78px"
         v-if="contentItem.ApplyClassKeyValue"
         @click="doAlterCourseHourClick">
      <div class="table_btn table_btn_white btn_hover_blue">修改课单</div>
    </div>
    <div class="table_td tc"
         style="width:78px"
         v-if="!contentItem.ApplyClassKeyValue"
         @click="doAdditionalRecordingCourseHourClick">
      <div class="table_btn table_btn_white btn_hover_blue">补录课时</div>
    </div>
  </div>
</template>

<script>
import { getStudentRelatedMsg } from '../../../../API/workbench';
export default {
  data () {
    return {
      isScrollWidthShow: false
    };
  },
  props: {
    contentItem: {
      type: Object,
      default: {}
    }
  },
  components: {
  },
  created () {
    // if (this.$refs.scrollWidthElement && this.$refs.scrollWidthValue) {
    //   if (this.$refs.scrollWidthValue.scrollWidth > this.$refs.scrollWidthElement.scrollWidth) {
    //   } else {
    //   }
    // }
  },
  mounted () {

  },
  computed: {
    vNumber () {
      if (this.contentItem.Number && Number(this.contentItem.Number) < 10) {
        return '0' + this.contentItem.Number;
      } else {
        return this.contentItem.Number;
      }
    },
    vSubmitTime () {
      if (this.contentItem.SubmitTime) {
        // let dateTime = this.contentItem.SubmitTime.slice(0, 16);
        return this.contentItem.SubmitTime.slice(0, 16);
      } else {
        return '';
      }
    }
  },
  methods: {
    // 触发学生点击事件
    cilckStudentNameEvent (item) {
      if (item.IsRecycleKey == 0 && item.IfDel == 0) { // IsRecycleKey：是否回收1-是;0-否；IfDel：否删除 0-未删除;1-已删除
        let obj = {
          StudentKey: item.StudentKey,
          Type: 'submit'
        };
        let moduleInfo = {
          name: '修改学生档案',
          routerName: this.$route.name,
          routertitle: '',
          ModulePowerKey: 48,
          moduleName: 'registerCustomerDetails',
          data: { dataInfo: obj }
        };
        this.$bus.emit('needOpenRouter', moduleInfo);
      } else {
        if (item.IsRecycleKey == 1) {
          layer.alert('该学生已被删除，不可操作');
        } else if (item.IfDel == 1) {
          layer.alert('该学生已被合并，不可操作');
        }
      }
    },
    // 触发手机号点击事件
    cilckPhoneNameEvent (item) {
      if (item.IsRecycleKey == 0 && item.IfDel == 0) { // IsRecycleKey：是否回收1-是;0-否；IfDel：否删除 0-未删除;1-已删除
        let obj = {
          StudentKey: this.contentItem.StudentKey
        };
        let moduleInfo = {
          name: '改手机号',
          routerName: this.$route.name,
          moduleName: 'changephonenum',
          data: { dataInfo: obj }
        };
        this.$bus.emit('needOpenRouter', moduleInfo);
      } else {
        if (item.IsRecycleKey == 1) {
          layer.alert('该学生已被删除，不可操作');
        } else if (item.IfDel == 1) {
          layer.alert('该学生已被合并，不可操作');
        }
      }
    },
    // 修改课单
    doAlterCourseHourClick () {
      if (this.contentItem.IsRecycleKey == 0 && this.contentItem.IfDel == 0) { // IsRecycleKey：是否回收1-是;0-否；IfDel：否删除 0-未删除;1-已删除
        this.getStudentInfo(this.contentItem.StudentKey, (data) => {
          let moduleInfo = {
            name: '修改课单',
            routerName: this.$route.name,
            moduleName: 'changeCourseBill',
            data: { dataInfo: data },
            callBack: { updateCourseEvent: () => { this.reload(); } }
          };
          this.$bus.emit('needOpenRouter', moduleInfo);
        });
      } else {
        if (this.contentItem.IsRecycleKey == 1) {
          layer.alert('该学生已被删除，不可操作');
        } else if (this.contentItem.IfDel == 1) {
          layer.alert('该学生已被合并，不可操作');
        }
      }
    },
    getStudentInfo (id, callback) {
      getStudentRelatedMsg(id).then(result => {
        if (callback) {
          callback(result.data);
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 补录课时
    doAdditionalRecordingCourseHourClick () {
      if (this.contentItem.IsRecycleKey == 0 && this.contentItem.IfDel == 0) { // IsRecycleKey：是否回收1-是;0-否；IfDel：否删除 0-未删除;1-已删除
        let obj = {
          StudentKey: this.contentItem.StudentKey,
          isReadOnly: false,
          guid: this.contentItem.guid,
          TableID: ''
        };
        let moduleInfo = {
          name: '报名收款',
          routerName: this.$route.name,
          moduleName: 'signuppayment',
          data: { dataInfo: obj },
          callBack: { afterSucces: () => { this.reload(); } }
        };
        this.$bus.emit('needOpenRouter', moduleInfo);
      } else {
        if (this.contentItem.IsRecycleKey == 1) {
          layer.alert('该学生已被删除，不可操作');
        } else if (this.contentItem.IfDel == 1) {
          layer.alert('该学生已被合并，不可操作');
        }
      }
    },
    // 回调
    reload () {
      console.log('回调-补录课时/修改课单');
    }
  }

};
</script>

<style>
</style>