<template>
  <div class="table_tr">
    <div class="table_td table_index"
         style="width:55px">{{vRowNumber}}</div>
    <student-name :rowData="rowData"
                  :isShowPart="isShowPart"
                  :tableData="tableData"
                  @onFocusEvent="afterFocusEvent"
                  @doDownwardMove="doAfterDownwardMoveEvent"
                  @doUpwardMove="doAfterUpwardMoveEvent"></student-name>

    <sex-cell :rowData="rowData"
              :tableData="tableData"
              :scrollTopVal="scrollTopVal"
              @onFocusEvent="afterFocusEvent"
              @doDownwardMove="doAfterDownwardMoveEvent"
              @doUpwardMove="doAfterUpwardMoveEvent"></sex-cell>

    <customer-phone-name :rowData="rowData"
                         :tableData="tableData"
                         @onFocusEvent="afterFocusEvent"
                         @doDownwardMove="doAfterDownwardMoveEvent"
                         @doUpwardMove="doAfterUpwardMoveEvent"></customer-phone-name>

    <course-name :rowData="rowData"
                 :scrollTopVal="scrollTopVal"
                 @onFocusEvent="afterFocusEvent"
                 @doDownwardMove="doAfterDownwardMoveEvent"
                 @doUpwardMove="doAfterUpwardMoveEvent"></course-name>

    <!-- 购买日期    -->
    <buy-date :rowData="rowData"
              :isShowPart="isShowPart"
              @onFocusEvent="afterFocusEvent"
              @doDownwardMove="doAfterDownwardMoveEvent"
              @doUpwardMove="doAfterUpwardMoveEvent"></buy-date>

    <expire-time v-if="!isShowPart"
                 :rowData="rowData"
                 @onFocusEvent="afterFocusEvent"
                 @doDownwardMove="doAfterDownwardMoveEvent"
                 @doUpwardMove="doAfterUpwardMoveEvent"></expire-time>
    <!-- 购买金额    -->
    <buy-amount v-if="!isShowPart"
                :rowData="rowData"
                @onFocusEvent="afterFocusEvent"
                @doDownwardMove="doAfterDownwardMoveEvent"
                @doUpwardMove="doAfterUpwardMoveEvent"></buy-amount>
    <!-- 购买课时   -->
    <buy-classHour v-if="!isShowPart"
                   :rowData="rowData"
                   @onFocusEvent="afterFocusEvent"
                   @doDownwardMove="doAfterDownwardMoveEvent"
                   @doUpwardMove="doAfterUpwardMoveEvent"></buy-classHour>

    <current-count :rowData="rowData"
                   :isShowPart="isShowPart"
                   :tableData="tableData"
                   @onFocusEvent="afterFocusEvent"
                   @doDownwardMove="doAfterDownwardMoveEvent"
                   @doUpwardMove="doAfterUpwardMoveEvent"></current-count>

    <warning-list :rowData="rowData"
                  :tableData="tableData"
                  :courseNameList="courseNameList"
                  :isShowPart="isShowPart"
                  :key="rowData.guid||rowData.Number"
                  @onFocusEvent="afterFocusEvent"
                  @doDownwardMove="doAfterDownwardMoveEvent"
                  @doUpwardMove="doAfterUpwardMoveEvent"></warning-list>

    <div class="table_td tc"
         style="width:68px">
      <div class="table_btn table_btn_white btn_hover_blue"
           v-if="vIsShowDeleteBtn"
           @click.stop="deleteRecord">删除</div>
    </div>
  </div>
</template>

<script>
import studentName from './row-cell/student-name';
import sexCell from './row-cell/sex-cell';
import customerPhoneName from './row-cell/customer-phone-name';
import courseName from './row-cell/course-name';
import buyDate from './row-cell/buy-date';
import expireTime from './row-cell/expire-time';
import buyAmount from './row-cell/buy-amount';
import buyClassHour from './row-cell/buy-classHour';
import currentCount from './row-cell/current-count';
// import valueAmount from './row-cell/value-amount';
import warningList from './row-cell/warning-list';
export default {
  data () {
    return {
      sexArr: [
        { name: '女', id: 3 },
        { name: '男', id: 2 }
      ],
      columnNums: [
        { prop: 'StudentName', num: 1 },
        { prop: 'SexKeyValue', num: 2 },
        { prop: 'CustomerPhoneName', num: 3 },
        { prop: 'ApplyClassKeyValue', num: 4 },
        { prop: 'CurrentCount', num: 5 },
        { prop: 'ValueAmount', num: 6 },
        { prop: 'ExpireTime', num: 7 }
      ],
      lastClickTime: null,
      isSubmiting: false
    };
  },
  props: {
    isShowPart: Boolean,
    rowData: Object,
    rowNumber: Number,
    courseNameList: Array,
    tableData: Array,
    scrollTopVal: Number,
    SpecialFilter: Number
  },
  components: {
    studentName,
    sexCell,
    customerPhoneName,
    courseName,
    buyDate,
    expireTime,
    buyAmount,
    buyClassHour,
    currentCount,
    warningList
  },
  computed: {
    vRowNumber () {
      let number = '';
      if (this.rowData.guid) {
        // number = this.rowData.Number < 10 ? '0' + this.rowData.Number : this.rowData.Number;
        number = this.rowNumber < 10 ? '0' + this.rowNumber : this.rowNumber;
      }
      return number;
    },
    vIsShowDeleteBtn () {
      let flag = false;
      if (this.rowData.guid) { // SpecialFilter==0 为当前录入
        flag = true;
      }
      return flag;
    }
  },
  methods: {
    // 聚焦事件 tableData
    afterFocusEvent (focusField) {
      this.$emit('onFocusEvent', this.rowData, focusField);
    },
    deleteRecord () {
      if (this.checkCanClick()) {
        this.$emit('deleteRecord', this.rowData, this.rowNumber - 1);
      }
    },
    // 向下触发事件
    doAfterDownwardMoveEvent (rowData, focusField) {
      this.$emit('doAfterDownwardMoveEvent', rowData, focusField);
    },
    // 向上触发事件
    doAfterUpwardMoveEvent (rowData, focusField) {
      this.$emit('doAfterUpwardMoveEvent', rowData, focusField);
    },
    checkCanClick () {
      let now = Date.new();
      let r = !this.lastClickTime || now - this.lastClickTime > 100;
      if (r) {
        this.lastClickTime = now;
      }
      return r;
    }

  }
};
</script>

<style>
</style>