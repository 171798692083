<template>
  <div class="courseware_manage_list pr">
    <div class="list_number">{{dataItem.ShowOrder}}</div>
    <div class="list_name"
         @click="showCourseware">{{dataItem.MainDemoName}}</div>
    <img class="cover_img"
         :src="vCoverImg"
         :onerror="defaultImg"
         alt="">
    <div style="padding-left: 8px;">
      <div class="courseware_name_capacity">
        <img :src="require('../../../../public/image/capacity_icon.png')"
             class="courseware_name_capacity_icon" />
        <span class="courseware_name_capacity_text">{{!dataItem.OccupiedCapacity?'0B':this.$utils.capacityFormate(dataItem.OccupiedCapacity)}}</span>
      </div>
      <div class="list_text">{{dataItem.briefDesc}}</div>
    </div>

    <!-- PublishStatus 发布状态（2：发布中 1：可以发布 0：不能发布（按钮灰色）） -->
    <!-- 发布中的时候只能进行预览 -->
    <div v-if="type=='ZZ' &&publishLogInfo.PublishStatus!=2">
      <div class="list_menu button"
           :class="vClassName"></div>
      <div class="list_btn button"
           @click="showOperateMenu">
        <div class="list_btn_ul"
             v-if="dataItem.isOperate">
          <div class="list_btn_menu type_02"
               @click="coursewareEdit">编辑</div>
          <div v-if="coursewareInfo.PublishStatusKey == 1 && this.dataItem.PublishStatusKey == 0"
               class="list_btn_menu type_04"
               @click="coursewareRestore">还原</div>
          <div class="list_btn_menu type_03"
               v-if="coursewareInfo.PublishStatusKey ==0"
               @click="coursewareDelete">删除</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      publishLogInfo: { PublishStatus: '-1' },
      isShowBtn: false
    };
  },
  props: {
    type: String,
    coursewareInfo: Object,
    dataItem: Object,
    logInfo: Object,
    coursewareInfoType: [String, Number]
  },
  created () {
    // console.log(this.logInfo, '发布记录-content-item');
    if (this.logInfo && this.logInfo.PublishStatus) {
      this.publishLogInfo = this.logInfo;
    }
  },
  watch: {
    // 发布状态（2：发布中 1：可以发布 0：不能发布（按钮灰色））
    'logInfo': {
      handler (n, o) {
        console.log(n, '发布按钮22');
        this.publishLogInfo = n;
      },
      immediate: true
    }
  },
  computed: {
    vClassName () {
      // 课包级发布状态(0-未发布;1-已发布)
      console.log(this.coursewareInfo, '课包信息');
      if (this.coursewareInfo.PublishStatusKey == 0) {
        return 'button mover';
      } else if (this.coursewareInfo.PublishStatusKey == 1 && this.dataItem.PublishStatusKey == 0) { //	课件级发布状态0：草稿状态 1：发布状态
        return 'type_blue';
      } else if (this.coursewareInfo.PublishStatusKey == 1 && this.dataItem.PublishStatusKey == 1) {
        return 'type_tick';
      }
    },
    // 非自研课件时不显示添加按钮
    vCoverImg () {
      if (this.dataItem.CoverUrl) {
        return this.$store.getters.CDNURL + this.dataItem.CoverUrl;
      } else {
        return '';
      }
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../public/cw-img/file_icon@2x.png') + '"';
    }
  },
  methods: {
    // 显示操作项
    showOperateMenu () {
      this.$emit('showOperateMenu', this.dataItem);
    },
    // 编辑
    coursewareEdit () {
      this.$emit('coursewareOperate', 'Edit', this.dataItem);
    },
    coursewareRestore () {
      this.$emit('coursewareOperate', 'Restore', this.dataItem);
    },
    // 删除
    coursewareDelete () {
      this.$emit('coursewareOperate', 'Delete', this.dataItem);
    },
    // 进入课件预览模式
    showCourseware () {
      // authCoursewareID 授权课件ID
      this.$router.push({ name: 'coursewareTeachingView', query: { type: 2, coursewareId: this.dataItem.OLAPKey, coursewareInfoType: this.coursewareInfoType, IsPCRecordKey: 1 } });
    }
  }
};
</script>
<style scoped>
.courseware_name_capacity {
}
.courseware_name_capacity_icon {
  width: 10px;
  height: 10px;
}
.courseware_name_capacity_text {
  font-size: 12px;
  color: #999999;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
.courseware_manage_list .list_name {
  margin-bottom: 0px;
}
.courseware_manage_list .cover_img {
  width: 26px;
  height: 22px;
  position: absolute;
  top: 57px;
  left: 8px;
  /* margin-right: 10px; */
}
.list_text {
  margin-top: 10px;
}
</style>