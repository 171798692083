<template>
  <div>
    <div class="form_info_edit form_info_list single_shadow content_marginRL content_marginTop_30">
      <div class="form_info_edit">
        <input-number :min="0"
                      :max='3653'
                      :formTitle="'剩余天数'"
                      v-model="remainingDays"
                      :placeholder="'选填'"
                      :required="true"
                      :decimalPlaces="0"></input-number>
      </div>
      <div class="form_info_edit">
        <input-text :formTitle="'处理原因'"
                    :readonly="false"
                    :formPlaceholder="'选填'"
                    :required="false"
                    v-model="Remarks"></input-text>
      </div>

    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="buttonClickEvent"
                           @cancelClick="closeDialog"></save-cancel-btn-group>

  </div>
</template>

<script>
// 选择框 作为公共组件
import { UpdateClassDate } from '../../../../API/workbench.js';
export default {
  props: ['residueDay', 'formItem', 'StudentKey'],
  data () {
    return {
      remainingDays: 0,
      Remarks: '' // 处理原因
    };
  },
  components: {},
  created () {
    this.remainingDays = this.residueDay;
  },
  computed: {},
  methods: {
    // 确定按钮
    buttonClickEvent (callBack) {
      // 更改日期
      var timestamp = Date.new().getTime(); // 当前时间戳
      var startTime = Date.new(this.formItem.StartTime).getTime();// 有效期开始时间戳
      var date = '';// 日期
      if (timestamp > startTime) {
        date = this.$utils.msToDate((this.remainingDays - 1) * 86400000 + timestamp);// 当前时间戳加上更改天数的时间戳(-1需要加上当天)
      } else {
        date = this.$utils.msToDate((this.remainingDays - 1) * 86400000 + startTime);// 当前时间戳加上更改天数的时间戳(-1需要加上当天)
      }
      if (date.hasTime.split(' ')[0]) {
        var dateTime = date.hasTime.split(' ')[0]; // 有效期
      }
      // console.log(dateTime, 'Y1 + M2 + D3');

      if (this.formItem.TableID == undefined) {
        this.formItem.TableID = this.formItem.DataKey;
      }
      UpdateClassDate(
        this.formItem.TableID,
        dateTime,
        this.Remarks,
        this.StudentKey
      ).then(result => {
        this.$emit('buttonClickEvent', this.formItem.TableID);
        this.$bus.emit('AfterEditCourseSingle', this.formItem.TableID);
        layer.alert('修改成功');
      }).catch(error => {
        console.log(error.msg);
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 取消按钮
    closeDialog () {
      this.$emit('closeDialog', this.formItem.TableID);
    }
  }
};
</script>

  <style>
</style> 