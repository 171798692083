<template>
  <div class="principal_summarizing">
    <div class="principal_summarizing_title">
      安排考勤
    </div>
    <div class="principal_summarizing_content clearfix">
      <div class="content_list fl">
        <div class="fl">
          <div class="content_list_title">本月签到</div>
          <div><span class="font_black">{{setNumber(attenReport.Attendance_ThisMonth.Attence3)}}</span><span class="">人</span></div>
        </div>
        <div class="fl">
          <div class="content_list_title">安排人数</div>
          <div>
            <span class="font_black ">{{setNumber(attenReport.Attendance_ThisMonth.TotalPerson)}}</span><span class="">人</span>
          </div>
        </div>
      </div>
      <div class="content_list fl">
        <div class="fl">
          <div class="content_list_title">出勤率</div>
          <div><span class="font_black">{{attenRatePercent(attenReport.Attendance_ThisMonth.Attence3,attenReport.Attendance_ThisMonth.TotalPerson)}}</span></div>
        </div>
        <div class="fl">
          <div class="content_list_title">上课节数</div>
          <div><span class="font_black ">{{setNumber(attenReport.Attendance_ThisMonth.HavedClass)}}</span><span class="">节</span></div>
        </div>
      </div>
      <div class="content_list fl">
        <div class="fl">
          <div class="content_list_title">上月签到</div>
          <div><span class="font_black">{{setNumber(attenReport.Attendance_LastMonth.Attence3)}}</span><span class="">人</span></div>
        </div>
        <div class="fl">
          <div class="content_list_title">安排人数</div>
          <div>
            <span class="font_black ">{{setNumber(attenReport.Attendance_LastMonth.TotalPerson)}}</span><span class="">人</span>
          </div>
        </div>
      </div>
      <div class="content_list fl">
        <div class="fl">
          <div class="content_list_title">出勤率</div>
          <div><span class="font_black">{{attenRatePercent(attenReport.Attendance_LastMonth.Attence3,attenReport.Attendance_LastMonth.TotalPerson)}}</span></div>
        </div>
        <div class="fl">
          <div class="content_list_title">上课节数</div>
          <div><span class="font_black ">{{setNumber(attenReport.Attendance_LastMonth.HavedClass)}}</span><span class="">节</span></div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {getKQStatistics} from '../../API/workbench';
export default {
  data () {
    return {
      attenReport: {
        Attendance_ThisMonth: {},
        Attendance_LastMonth: {}
      }
    };
  },
  created () {
    this.getAttenReport();
  },
  methods: {
    getAttenReport () {
      getKQStatistics().then(result => {
        this.attenReport = result.data;
        console.log(result.data, 'getAttenReport');
      });
    },
    setNumber (num) {
      num = num || 0;
      return Number(num);
    },
    attenRatePercent (num, lastnum) {
      num = this.setNumber(num);
      lastnum = this.setNumber(lastnum);
      return parseInt((Math.round(num / lastnum * 10000) / 100.00) ||0 ) + '%';
    },
    // 计算比率
    toPercent (num, lastnum) {
      num = this.setNumber(num);
      lastnum = this.setNumber(lastnum);
      let rate = 0;
      if (num < lastnum) {
        rate = 100 - (Math.round(num / lastnum * 10000) / 100.00);
        console.log(rate, 'rete', (Math.round(num / lastnum * 10000) / 100.00));
      } else if (num == lastnum) {
        rate = 100;
      } else {        // 大于
        rate = (Math.round(num / lastnum * 10000) / 100.00) - 100;
      }
      return rate + '%';// 小数点后两位百分比
    },
    compareNum (num, lastnum) {
      return this.setNumber(num) - this.setNumber(lastnum); ;
    }
  }
};
</script>

