<template>
  <div>
    <div class="form_info_list">
      <form-input :formTitle="'开发者邮箱'"
                  :formPlaceholder="' '"
                  :required="true"
                  v-model="dataInfo.EMail"></form-input>
      <form-input :formTitle="'通知方式'"
                  :formPlaceholder="' '"
                  :required="true"
                  v-model="dataInfo.NoticeType"></form-input>
    </div>
    <div class="form_info_list">
      <form-input v-for="(item, index) in dataInfo.PrivacyList"
                  :key="index"
                  :formTitle="nameSwitch(item.PrivacyName)"
                  :formPlaceholder="' '"
                  :required="true"
                  v-model="item.PrivacyText"></form-input>
    </div>
    <button-info @closeDialog="closePopup"
                 @buttonClickEvent="submitForm"></button-info>
  </div>
</template>
<script>
import {
  GetNewPrivacyVersion,
  SubmitNewPrivacyVersion
} from '../../API/workbench';
export default {
  data () {
    return {
      privacyList: [
        {
          name: '用户信息',
          key: 'UserInfo'
        },
        {
          name: '位置信息',
          key: 'Location'
        },
        {
          name: '地址',
          key: 'Address'
        },
        {
          name: '发票信息',
          key: 'Invoice'
        },
        {
          name: '微信运动数据',
          key: 'RunData'
        },
        {
          name: '麦克风',
          key: 'Record'
        },
        {
          name: '选中的照片或视频信息',
          key: 'Album'
        },
        {
          name: '摄像头',
          key: 'Camera'
        },
        {
          name: '手机号码',
          key: 'PhoneNumber'
        },
        {
          name: '通讯录（仅写入）权限',
          key: 'Contact'
        },
        {
          name: '设备信息',
          key: 'DeviceInfo'
        },
        {
          name: '身份证号码',
          key: 'EXIDNumber'
        },
        {
          name: '订单信息',
          key: 'EXOrderInfo'
        },
        {
          name: '发布内容',
          key: 'EXUserPublishContent'
        },
        {
          name: '所关注账号',
          key: 'EXUserFollowAcct'
        },
        {
          name: '操作日志',
          key: 'EXUserOpLog'
        },
        {
          name: '相册（仅写入）权限',
          key: 'AlbumWriteOnly'
        },
        {
          name: '车牌号',
          key: 'LicensePlate'
        },
        {
          name: '蓝牙',
          key: 'BlueTooth'
        },
        {
          name: '日历（仅写入）权限',
          key: 'CalendarWriteOnly'
        },
        {
          name: '邮箱',
          key: 'Email'
        },
        {
          name: '选中的文件',
          key: 'MessageFile'
        }
      ],
      dataInfo: {
        EMail: '',
        NoticeType: ''
      }
    };
  },
  created () {
    this.isShowPopup = true;
    this.getDataInfo();
  },
  methods: {
    closePopup () {
      this.$emit('closePopup');
    },
    doAfterUpLoadImgSuccess (imgInfo, callback) {
      this.dataInfo.AttachUrlList.push(imgInfo);
    },
    nameSwitch (key) {
      let obj = this.privacyList.find((e) => {
        return key == e.key;
      });
      return obj.name;
    },
    submitForm () {
      SubmitNewPrivacyVersion(
        this.dataInfo.NoticeType,
        this.dataInfo.EMail,
        this.dataInfo.PrivacyList
      ).then(
        (res) => {
          layer.alert('修改成功');
          this.$emit('succeed');
          this.closePopup();
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    getDataInfo () {
      GetNewPrivacyVersion().then(
        (res) => {
          this.dataInfo = res.data;
          let i = this.dataInfo.PrivacyList.findIndex((e) => {
            return e.PrivacyName == 'Album';
          });
          let j = this.dataInfo.PrivacyList.findIndex((e) => {
            return e.PrivacyName == 'PhoneNumber';
          });
          if (i < 0 && j < 0) {
            this.dataInfo.PrivacyList = [
              {
                PrivacyName: 'Album',
                PrivacyText: ''
              },
              {
                PrivacyName: 'PhoneNumber',
                PrivacyText: ''
              }
            ];
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
};
</script>
<style scoped>
.bulk_assessment_students_image_view_image {
  margin-right: 15px;
  margin-bottom: 15px;
}
</style>
