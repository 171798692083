<template>
  <div :class="vSassIngo.EditionKey!=6?'':'reMaxCoursewareList'" @scroll="scrollList($event)">
    <!-- 普通版本 scroll_type-->
    <div v-if="isShowMaxCoursewareList">
      <!-- <div class="customer_management"
           style="margin: 40px 38px 54px;">
        <div class="table_fixed_nav_box">
          <div class="table_fixed_nav tltle_margin"
               style="width: 1370px;">
            <div @click="showAuthorizationList"
                 class="table_fixed_nav_list"
                 :class="{opt:coursewareTypeKey=='SQ'}">授权课件</div>
            <div @click="showScienceList"
                 class="table_fixed_nav_list"
                 :class="{opt:coursewareTypeKey=='ZZ'}">自研课件</div>
          </div>
        </div>
      </div> -->
      <!-- 大课包 -->
      <max-courseware-list :type="coursewareTypeKey"
                           :maxCoursewareList="maxCoursewareList"
                           :coursewareInfo="vMaxCoursewareInfo"
                           :statInfo="statInfo"
                           @setSortList="setSortList"
                           @afterSuccess="getCoursewareStat"
                           @selectEvent="selectEvent"
                           @doListSort="doListSort"
                           @doOperateItemClick="doOperateItemClick"
                           @selectCoursewareType="selectCoursewareType"
                           @editCoursewarePackageAfterSuccess="getParentCourseWarePackageList"></max-courseware-list>

      <div v-if="coursewareTypeKey=='SQ'"
           class="max_courseware_authorization_table">
        <authorization-table-list class="summarizing_list"></authorization-table-list>
      </div>
    </div>

    <div v-else>
      <!-- 小课包 -->
      <single-courseware-list :type="coursewareTypeKey"
                              :scrollTop="scrollTop"
                              :maxCoursewareInfo="vMaxCoursewareInfo"
                              @getParentCourseWarePackageList="getParentCourseWarePackageList"></single-courseware-list>
    </div>
    <div>
      <dialog-factory ref="hqCoursewareManagementDialogFactory"
                      :key="'hqCoursewareManagement'"
                      :routerName="'hqCoursewareManagement'"></dialog-factory>
      <dialog-factory-common ref="hqCoursewareManagementCommonDialogFactory"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import {
  GetParentCourseWarePackageList,
  ClientAuthParentCoursewarePackageList,
  ParentCourseWarePackageSort,
  // DeleteParentCourseWarePackage,
  CoursewarePackageManageDisable,
  CWSCoursewarePackageManageDisable,
  hqCoursewarePackageManageDisable,
  CWSGetParentCourseWarePackageList,
  cwsGetCoursewareStat,
  HQgetCoursewareStat,
  WebVgetCoursewareStat
} from '../../API/workbench';
import maxCoursewareList from './max-courseware-list/index';
import authorizationTableList from './authorization-table-list';
import singleCoursewareList from './single-courseware-list';
import dialogFactory from '../common/dialog-factory';
export default {
  name: 'generalCoursewareManagement',
  provide () {
    return {
      generalOrHq: this.generalOrHq,
      coursewarePackage: this.coursewarePackage,
      getParentCourseWarePackageList: this.getParentCourseWarePackageList,
      getCoursewareStat: this.getCoursewareStat
    };
  },
  data () {
    return {
      isShowMaxCoursewareList: true,
      coursewareTypeKey: 'ZZ', // 课件类型
      maxCoursewareList: [], // 查询大课包列表-正常
      maxCoursewareInfo: {}, // 大课包信息
      coursewarePackageID: '', // 支持外部进入选中查看(课件包ID)
      coursewareEditInfo: {},
      coursewareId: '',
      isLoadData: true,
      generalOrHq: 'general', // 门店点击 general  总部点击 hq
      coursewarePackage: 'bigCourseware', // 传递给回收站 是从大课包传入的信息
      statInfo: {},
      chooseKey: '',
      scrollTop:0
    };
  },
  components: {
    maxCoursewareList,
    singleCoursewareList,
    authorizationTableList,
    dialogFactory
  },
  created () {
      console.log('外部进入定位 created', );
    if(!this.$route.params.data||!this.$route.params.data.levelPath){
      this.showScienceList();// 默认查询大课包列表-普通(自研)
    }else if(this.$route.params.data.isChild||this.$route.params.data.isNew){
      if(this.$route.params.data&&this.$route.params.data.levelPath){
        this.coursewareTypeKey = 'ZZ';
        this.isShowMaxCoursewareList=true
        this.getParentCourseWarePackageList(()=>{
          this.enterRouterInit()
        });
        this.getCoursewareStat()
      }else{
         this.showScienceList();// 默认查询大课包列表-普通(自研)
      }
    }
    
  },
  activated () {
    // this.isShowMaxCoursewareList = true;
    // if ((this.$route.params.dataKey && this.$route.params.dataKey > 0) || this.coursewarePackageID) { // 外部进入定位
    //   this.coursewarePackageID = this.$route.params.dataKey || this.coursewarePackageID;
    //   if (this.coursewareTypeKey == 'ZZ') {
    //     this.getParentCourseWarePackageList();
    //   }
    // }
    console.log('外部进入定位', this.$route.params);
    if(this.$route.params.data&&this.$route.params.data.levelPath&&!this.$route.params.data.isNew){
       layer.confirm(
              "是否跳转到子课包"+this.$route.params.data.Title+'?',
              {
                btn: [
                  {
                    name: '确认',
                    callBack: ()=> {
                       this.coursewareTypeKey = 'ZZ';
                        this.isShowMaxCoursewareList=true
                        this.getParentCourseWarePackageList(()=>{
                          this.enterRouterInit()
                        });
                        this.getCoursewareStat()
                      layer.close();
                    }
                  }
                ]
              }
            );
     
    }
  },
  destroyed(){
    this.$route.params.data={}
    this.$route.params.isChild=false
  },
  computed: {
    vSassIngo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vMaxCoursewareInfo () {
      let obj = {};
      if (this.maxCoursewareList.length > 0 && this.chooseKey != '') {
        this.maxCoursewareList.forEach((item, index) => {
          if (item.ID == this.chooseKey) {
            obj = item;
          }
        });
      }
      return obj;
    }
  },
  methods: {
    enterRouterInit(){
     
       if(this.$route.params.data&&this.$route.params.data.levelPath){
         
            let dataArr= this.$route.params.data.levelPath.split('.')
            if(dataArr[0]==''){
              dataArr.splice(0,1)
            }
            console.log("dataArr",dataArr)
            if(this.maxCoursewareList.length>0){
              let item = this.maxCoursewareList.find(p=>{
                return p.ID==dataArr[0]
              })
              console.log("item",item)
              this.selectEvent(item)
            }
          }
    },
    // 授权课件
    showAuthorizationList () {
      this.coursewareTypeKey = 'SQ';
      this.getClientAuthParentCoursewarePackageList();
    },
    // 自研课件
    showScienceList () {
      this.coursewareTypeKey = 'ZZ';
      this.getCoursewareStat();
      this.getParentCourseWarePackageList();
    },
    // 查询大课包列表-普通(自研)
    getParentCourseWarePackageList (callback) {
      console.log(1111111111111);
      let fn = null;
      if (this.vSassIngo.EditionKey == 6) {
        fn = CWSGetParentCourseWarePackageList;
      } else if (this.vSassIngo.EditionKey == 5) {
        layer.alert('同事正在连夜搬砖中！');
        return;
      } else {
        fn = GetParentCourseWarePackageList;
      }
      fn().then(result => {
        this.maxCoursewareList = result.data;
        if(callback){
          callback()
        }
        console.log('查询大课包列表-普通', result.data);
      }, error => {
        layer.alert(error.msg);
      });
      this.$emit('afterSuccess');
    },
    //  查询大课包列表-普通(授权)
    getClientAuthParentCoursewarePackageList () {
      ClientAuthParentCoursewarePackageList().then(result => {
        this.maxCoursewareList = result.data;
        console.log('查询大课包列表-普通', result.data);
      }, error => {
        layer.alert(error.msg);
      });
    },
    scrollList (e) {
      this.scrollTop = e.target.scrollTop;
      console.log(this.scrollTop)
    },
    // 查询课件包列表-回收站
    getRecycleCoursewarePackage () {
      // hqSearchCourseWarePackageListForRecycle().then(result => {
      //   this.coursewareTypeKey = 'HSZ';
      //   this.maxCoursewareList = result.data;
      //   if (this.maxCoursewareList.length > 0) {
      //     this.selectEvent(result.data[0]);// 默认第一项
      //   } else {
      //     this.coursewareContentList = [];
      //     this.maxCoursewareInfo = {};
      //   }
      //   console.log('查询课件包列表-回收站(普通)', result.data);
      // }, error => {
      //   layer.alert(error.msg);
      // });
    },
    // 课件包排序
    setSortList(data,index){
       ParentCourseWarePackageSort(data.ID, index).then(result => {
        this.getParentCourseWarePackageList();
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 课件包排序
    doListSort (sortType, data) {
      let changeToSort = (sortType == 'Top' ? Number(data.ShowOrder) - 1 : Number(data.ShowOrder) + 1);
      ParentCourseWarePackageSort(data.ID, changeToSort).then(result => {
        this.getParentCourseWarePackageList();
      }, error => {
        layer.alert(error.msg);
      });
    },

    // 根据指定课件包ID获取课件列表
    selectEvent (dataInfo) {
      console.log(dataInfo, 'dataInfo111');
      this.chooseKey = dataInfo.ID;
      this.maxCoursewareInfo = dataInfo;
      this.isShowMaxCoursewareList = false;
    },
    // 重新加载数据
    selectCoursewareType (item, data) { // data,用于选中当前项
      console.log('重新加载数据', item);
      this.coursewarePackageID = (data && data.ID ? data.ID : '');
      if (item.key == 'ZZ') {
        this.getParentCourseWarePackageList();
      } else if (item.key == 'HSZ') {
        this.getRecycleCoursewarePackage();
      }
    },
    getCoursewareStat () {
      // const sassInfo = this.$store.getters.SaaSClientInfo
      let apiUrlIn = null;
      console.log(this.vSassIngo.EditionKey, 'this.vMendianInfo.EditionKey');
      if (this.vSassIngo.EditionKey == 6) {
        console.log('课件门店');
        apiUrlIn = cwsGetCoursewareStat();
      } else if (this.vSassIngo.EditionKey == 5) {
        console.log('总部');
        apiUrlIn = HQgetCoursewareStat();
      } else {
        console.log('教务门店');
        apiUrlIn = WebVgetCoursewareStat();
      }
      apiUrlIn.then(result => {
        console.log('统计数据', result.data);
        this.statInfo = result.data;
      });
    },
    // 大课包操作
    doOperateItemClick (item, data) {
      console.log(item, data, '操作项');
      switch (item.key) {
        case 'return':
          this.isShowMaxCoursewareList = true;
          break;
        // case 'copy':
        //   hqCoursewarePackageManageCopy(data.ID).then(result => {
        //     layer.alert('复制成功');
        //     this.coursewarePackageID = result.data.CoursewarePackageID;
        //     this.getParentCourseWarePackageList();
        //   }, error => {
        //     layer.alert(error.msg);
        //   });
        //   break;
        // case 'delete':
        //   layer.confirm('确定删除该课件包?', {
        //     btn: [{
        //       name: '确认',
        //       callBack: () => {
        //         DeleteParentCourseWarePackage(data.ID).then(result => {
        //           this.getParentCourseWarePackageList();
        //         }, error => {
        //           layer.alert(error.msg);
        //         });
        //         layer.close();
        //       }
        //     }
        //     ]
        //   });
        //   break;
        case 'disable':
          layer.confirm('停用该课件包,所有该课件包的分发将会取消,是否继续停用?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  let apiFN = '';
                  let type = this.$store.getters.SaaSClientInfo.EditionKey;
                  if (type == 6) {
                    apiFN = CWSCoursewarePackageManageDisable;
                  } else if (type == 5) {
                    apiFN = hqCoursewarePackageManageDisable;
                  } else {
                    apiFN = CoursewarePackageManageDisable;
                  }

                  apiFN(data.ID).then(
                    result => {
                      this.getParentCourseWarePackageList();
                      this.getCoursewareStat();
                    },
                    error => {
                      layer.alert(error.msg);
                    }
                  );
                  layer.close();
                }
              }
            ]
          });
          break;
        // case 'enable':
        //   ParentCourseWarePackageEnable(data.ID).then(result => {
        //     layer.alert('启用成功');
        //     this.getRecycleCoursewarePackage();
        //   }, error => {
        //     layer.alert(error.msg);
        //   });
        //   break;
        default:
          break;
      }
    }
  }
};
</script>
<style>
.max_courseware_authorization_table {
  background: #fff;
  width: 72%;
  max-height: 560px;
  border-radius: 8px;
  margin: 30px 260px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.reMaxCoursewareList {
  background: #f5f5f5;
  height: 810px;
  overflow: auto;
}
</style>
