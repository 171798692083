<template>
  <!-- 通用列表 -->
  <div>
    <el-table element-loading-text="拼命加载中" 
              class="table_list"
              ref="multipleTable"
              :data="vTableData"
              :border="false"
              :default-sort="defaultSort"
              :row-key="getRowKeys"
              stripe
              style="width: 100%;"
              :show-summary="isShowSummary"
              @sort-change="sortChange"
              @select-all="changeCheckBoxFun"
              @select="changeCheckBoxFun"
              :cell-class-name="cellcb">

      <el-table-column type="selection"
                       width="55"
                       prop="id"
                       :selectable="checkSelectable"
                       :reserve-selection="true"></el-table-column>

      <component v-for="columnInfo in vTableColumns"
                 :key="columnInfo.prop"
                 v-bind:is="columnInfo.type"
                 :columnData="columnInfo"></component>
      <el-table-column fixed="center"
                       type="expand">
        <template slot-scope="props">
          <div v-if="curExpandRowInfo.expandType=='form'">
            <expand-row-form :rowData="props.row"
                             :tableColumns="curExpandRowInfo.tableColumns"></expand-row-form>
          </div>
          <div v-else>
            <expand-row-table :tableColumns="curExpandRowInfo.tableColumns"
                              :isLoading="curExpandRowInfo.isLoading"
                              :tablePaddingLeft="curExpandRowInfo.left"
                              :iconPositionLeft="curExpandRowInfo.positionLeft"
                              :tableWidth="curExpandRowInfo.width"></expand-row-table>
          </div>
        </template>
      </el-table-column>
      <!-- </div> -->
      <div slot="empty"
           style="height:440px;line-height:440px;">
        <i class="el-icon-warning-outline">
          <div class="monitoring_content_nodata"></div>
          亲，当前没有任何数据哦~
        </i>
      </div>
    </el-table>
    <!-- 分页 -->
    <div v-if="queryParams.pageSize > 0"
         class="table_pagination table_pagination_right">
      <div class="table_left_text">
        {{footerContent}}
      </div>
      <el-pagination @current-change="handleCurrentChange"
                     :page-size="queryParams.pageSize"
                     :current-page.sync="vPageIndex"
                     background
                     :pager-count="5"
                     layout="prev, pager, next, jumper"
                     :total="totalNum>0?totalNum:queryParams.pageSize"></el-pagination>
      <table-pagination-number :pageSize="queryParams.pageSize"
                               :queryParams="queryParams"
                               @loadTableData="loadTableData"></table-pagination-number>
    </div>
  </div>
</template>
<script>

import tableIndex from '../../../../common/table-list/column/table-index';// 序号
import textItem from '../../../../common/table-list/column/text-item';// 文本
import student from '../../../../common/table-list/column/student';// 学生
import arrangeSupport from '../../../../common/table-list/column/arrange-support';// 排课备注
import remarksItem from '../../../../common/table-list/column/remarks-item';// 备注
import dateItem from '../../../../common/table-list/column/date-item'; // 日期
import classHour from '../../../../common/table-list/column/class-hour';// 课时
import amountItem from '../../../../common/table-list/column/amount-item';// 金额
import numberItem from '../../../../common/table-list/column/number-item';// 数字
import readingCourses from '../../../../common/table-list/column/reading-courses';// 通用在读课程
import wechatSubscribe from '../../../../common/table-list/column/wechat-subscribe';// 微信关注icon
import faceRecognition from '../../../../common/table-list/column/face-recognition';// 面容识别icon
import multiLevelHead from '../../../../common/table-list/column/multi-level-head';// 多级表头
import tableBtn from '../../../../common/table-list/column/table-btn';// 操作
import expandRowTable from '../../../../common/table-list/expand-row-table/index';// 展开行 table
import expandRowForm from '../../../../common/table-list/expand-row-form/index';// 展开行 表单
import tablePaginationNumber from '../../../../common/table-pagination-number'; // table 分页
import lineNumber from '../../../../common/table-list/column/line-number';
export default {
  data () {
    return {
      curExpandRowInfo: { tableData: [], tableColumns: [], expandType: 'table', prop: '', isLoading: false, width: 0, left: 0, positionLeft: 0 }, // 当前查看详情的列信息
      isShowInnerScroll: true,
      isShowSummary: false
    };
  },
  props: {
    tableData: Array, // 列表数据
    totalNum: Number, // 列表总共条数
    queryParams: {
      type: Object,
      default: () => {
        return {
          pageIndex: 0,
          pageSize: 10,
          orderBy: '',
          sequence: '',
          searchText: ''
        };
      }
    },
    tableColumns: {
      type: Array,
      default: () => {
        return [
          {
            label: {// 列名
              type: String,
              required: true
            },
            prop: {// 绑定的字段名
              type: String,
              required: true
            },
            type: {// 类型
              type: String,
              required: true
            },
            extend: {

            },
            width: 120, // 默认宽
            sortable: true, // 是否可排序
            align: 'left', // 对戏方式
            isShow: false// 是否显示
          }
        ];
      }
    }, // table column
    summary: {
      type: Object,
      default: function () {
        return {
          isShowSummary: false,
          needSummariesColumns: [],
          summaryName: '合计',
          summaryData: []
        };
      }
    },
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: '', order: 'ascending' };
      }
    },
    isNeedInnerScroll: {
      type: Boolean,
      default: false
    },
    footerContent: {    // 显示底部左下角统计文字
      type: String,
      default: ''
    },
    checkBoxList: Array
  },
  components: {
    textItem,
    tableIndex,
    student,
    arrangeSupport,
    dateItem,
    readingCourses,
    classHour,
    amountItem,
    numberItem,
    wechatSubscribe,
    faceRecognition,
    multiLevelHead,
    tableBtn,
    lineNumber,
    expandRowTable,
    expandRowForm,
    tablePaginationNumber,
    remarksItem
  },
  computed: {
    vPageIndex: {
      get () {
        return this.queryParams.pageIndex + 1;
      },
      set (n, o) {
      }
    },
    vTableColumns () {
      let columns = this.tableColumns.filter(obj => { return obj.isShow == undefined || obj.isShow; });
      return columns;
    },
    vTableData () {
      let items = {
        isEmpertyRow: true
      };
      let arr = [];
      let oldTableDataLength = this.$utils.parseJson(this.tableData.length);
      if (this.tableData.length < this.queryParams.pageSize && this.tableData.length > 0) {
        arr = this.tableData;
        for (let index = 1; index <= (this.queryParams.pageSize - oldTableDataLength); index++) {
          arr.push(items);
        }
      } else if (this.tableData.length >= this.queryParams.pageSize) {
        arr = this.tableData;
      }
      return arr;
    }
  },
  created () {
  },
  mounted () {

  },
  watch: {

  },
  methods: {
    // 显示选中项
    getRowKeys (row) {
      return row.OLAPKey;
    },
    // 没有数据时，去掉多余复选框
    cellcb (row) {
      if (!row.row.OLAPKey) {
        return 'myCell';
      }
    },
    // 是否禁止选中
    checkSelectable (row, index) {
      return Number(row.SubscribeKey) == 1;
    },
    // 分页
    handleCurrentChange (val) {
      this.$refs.multipleTable.setCurrentRow();
      this.queryParams.pageIndex = val - 1;
      this.loadTableData(this.queryParams);
    },
    // 排序
    sortChange (column, prop) {
      if (column.prop) {
        this.queryParams.orderBy = column.prop;
        this.queryParams.sequence = column.order == 'ascending' ? 'asc' : 'desc';
      }
      this.loadTableData(this.queryParams);
    },
    // 选中框
    changeCheckBoxFun (list, row) {
      console.log(list, row, '选中项');
      this.$emit('changeCheckBoxFun', list, row);
    },
    // 跳转到第几页
    loadTableData (queryParams) {
      this.$emit('loadTableData', queryParams);
    },
    setCurrentRow () {
      this.$refs.multipleTable.setCurrentRow();
    }
  }
};
</script>
<style>
/* .myCell .el-checkbox__input {
  display: none;
} */
</style>

