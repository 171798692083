<template>
  <div class="autorelease_pool_tips_box">
    <div class="courseware_bag_img pr">
      <img :src="headImg"
           alt="" />
    </div>
    <div class="top_box_left">{{dataInfo.MainDemoName}}</div>
    <div class="top_box_centre">
      当前释放:
      <span>{{Number(dataInfo.MRCoursewarePackageSumForRelease)}}个</span>
    </div>
    <div class="top_box_centre">
      全部校区:
      <span>{{Number(dataInfo.MRCoursewarePackageSumForWholeClient)}}个</span>
      <span class="or_box">|</span>
      部分校区:
      <span>{{Number(dataInfo.MRCoursewarePackageSumForPartClient)}}个</span>
    </div>
    <div class="top_box_btn  pr"
         @click="doAutoAutorelease">转自动释放</div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object
  },
  created () {

  },
  computed: {
    headImg () {
      if (this.dataInfo && this.dataInfo.CoverUrl) {
        if (this.dataInfo.CoverUrl.indexOf('http') > -1) {
          return this.dataInfo.CoverUrl;
        } else {
          return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
        }
      } else {
        return '';
      }
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' + require('../../../../public/image/no_logo@2x.png') + '"'
      );
    }
  },
  methods: {
    doAutoAutorelease () {
      this.$emit('doAutoAutorelease', this.dataInfo);
      console.log('转自动释放');
    }
  }
};
</script>
<style scoped>
.autorelease_pool_tips_box {
  width: 239px;
  height: auto;
  border-radius: 8px 0px 0px 8px;
  background: #f1f7ff;
  padding-bottom: 1px;
}
.courseware_bag_img img {
  height: 112px;
  width: 200px;
  border-radius: 8px;
  margin: 20px;
  margin-bottom: 8px;
}
.top_box_left {
  line-height: 30px;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  padding-left: 20px;
}
.top_box_centre {
  line-height: 24px;
  max-width: 236px;
  font-size: 14px;
  padding-left: 20px;
  color: #999;
}
.top_box_centre span {
  color: #000;
}
.top_box_centre .or_box {
  margin: 10px;
  color: #999;
}
.top_box_btn {
  line-height: 40px;
  width: 200px;
  font-size: 14px;
  font-weight: 400;
  padding-right: 50px;
  text-align: right;
  cursor: pointer;
  border: 1px solid #2e86c1;
  background-color: #3498db;
  color: #fff;
  border-radius: 4px;
  height: 40px;
  margin: 25px 20px;
}
.top_box_btn:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  left: 50px;
  top: 10px;
  background: url(../../../../public/cw-img/autorelease_pool@2.png) no-repeat
    50%;
  background-size: 20px;
}
</style>
