<template>
  <div class="WKT_information_list">
    <div class="WKT_information_block">
      <div class="WKT_information_img">
        <img :src="vImg"
             :onerror="defaultImg"
             alt="">
      </div>
      <div class="WKT_information_info">
        <div class="WKT_information_name">
          {{item.StarStudentsName}}
        </div>
        <div class="WKT_information_text"
             v-html="item.content">
          <!-- {{item.content}} -->
        </div>
      </div>
    </div>
    <operation-btn :item="item"
                   :dataList="dataList"
                   :isShowSetTop="true"
                   @promote="promote"
                   @decline="decline"
                   @edit="edit"
                   @remove="remove"
                   @setTop="setTop"></operation-btn>
  </div>
</template>
<script>
import operationBtn from '../common/operation-btn';
export default {
  data () {
    return {

    };
  },
  components: {
    operationBtn
  },
  props: {
    item: Object,
    dataList: {
      type: Array,
      default: []
    }
  },
  computed: {
    vImg () {
      if (!this.item.picurl) {
        this.item.picurl = '';
      }
      return this.$utils.ossLitimgSet(this.item.picurl);
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../public/image/no_logo@2x.png') + '"';
    }
  },
  methods: {
    // 上升
    promote (item, index) {
      this.$emit('moveIndex', item, index);
    },
    // 下降
    decline (item, index) {
      this.$emit('moveIndex', item, index + 3);
    },
    // 修改
    edit (item) {
      this.$emit('edit', item);
    },
    // 删除
    remove (item) {
      this.$emit('remove', item);
    },
    setTop (item, index) {
      this.$emit('moveIndex', item, index);
    }
  }
};
</script>