<template>
  <div>
    <div class="form_info_detail form_info_serach_box">
      <!-- 搜索选择层 -->
      <input-search-contain-btn v-model="params.searchText"
                                :placeholder="'请输入校区名称'"
                                @onSearchEvent="searchTextSearch"></input-search-contain-btn>
      <!-- 搜索结果 -->
      <div class="search_result">
        <div class="search_result_content search_result_content_for_student">
          <div class="search_result_list"
               @scroll="scrollTOP">
            <Item v-for="item in campusList"
                  :key="item.OLAPKey"
                  :Item="item"
                  @selectedItem="selectedItem"></Item>
            <div v-if="loading"
                 class="students_loading all_list_bottom">
              <span class="el-icon-loading"></span>
              <span class="loading_style">加载中...</span>
            </div>
            <div v-else-if="campusList.length==0">
              <div class="monitoring_content_nodata">
                <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
              </div>
            </div>
            <div v-else
                 class="all_list_bottom">
              <span class="complete">已显示全部</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>
<script>
import Item from './item';
import { HQSearchPageForBigCoursewarePackageAuthorizaSaaSClient, SearchSaaClientList } from '../../../../API/workbench';
export default {
  name: 'campusList',
  data () {
    return {
      campusList: [],
      params: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: '', //	字符串	可选		数据源：GET	排序顺序
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号
        BigCoursewarePackageKey: '' //	整型	必须		数据源：GET	 大课包ID
      },
      loading: false,
      noMore: false // 没有更多数据加载了
    };
  },
  props: {
    BigCoursewarePackageKey: { // 是否在校 0-否; 1-是; 空- 全部
      type: [String, Number],
      default: ''
    },
    campusListTypeKey: [String, Number]
  },
  components: {
    Item
  },
  watch: {
    // 'params.searchText' () {
    //   if (this.params.searchText == '') {
    //     this.campusList.length = 0;
    //     this.pageSize = 20;
    //     this.params.pageIndex = 0;
    //     this.noMore = false;
    //     this.getCampusForList();
    //   }
    // }
  },
  created () {
    this.params.BigCoursewarePackageKey = this.BigCoursewarePackageKey;
    this.getCampusForList();
  },
  methods: {
    // 点击搜索
    searchTextSearch () {
      this.params.searchText = this.params.searchText.replace(/ /g, '');
      this.params.pageIndex = 0;
      this.noMore = false;
      this.getCampusForList();
    },
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;
      let HeightView = ETarget.scrollHeight - ETarget.clientHeight;
      // console.log(this.noMore, 'this.noMore');
      if (parseInt(ETarget.scrollTop) == parseInt(HeightView) && !this.noMore) {
        this.getCampusForList();
      }
    },
    getCampusForList () {
      this.loading = true;
      let ApiName = this.campusListTypeKey == 1 ? HQSearchPageForBigCoursewarePackageAuthorizaSaaSClient : SearchSaaClientList;
      ApiName(this.params).then(result => {
        if (result.data) {
          // 切换校区类型时 (首页)需清空数据
          if (this.params.pageIndex == 0) {
            this.campusList = [];
          }
          let resultData = result.data.PageDataList || result.data.data.PageDataList;
          resultData.forEach(o => {
            o.isActive = false;
            let hasFind = false;
            if (this.campusList.length > 0) {
              hasFind = this.campusList.findIndex(p => p.OLAPKey == o.OLAPKey) >= 0;
            }
            if (!hasFind) {
              this.campusList.push(o);
            }
          });
          if (resultData.length < this.pageSize) {
            this.noMore = true;
          }
        }
        this.loading = false;
        this.params.pageIndex++;
      }).catch(error => {
        this.loading = false;
        console.log(error);
      });
    },
    // 选择校区
    selectedItem (item) {
      this.campusList.forEach(obj => {
        obj.isActive = false;
        if (obj.OLAPKey == item.OLAPKey) {
          obj.isActive = true;
        }
      });
      this.$emit('onSelectedItem', item);
      this.scrollBackTop();
      this.$emit('close');
    },
    scrollBackTop () {
      this.params.searchText = '';
      document.getElementsByClassName('search_result_content_for_student')[0].scrollTop = 0;
      if (document.getElementsByClassName('search_result_content_for_student')[1]) {
        document.getElementsByClassName('search_result_content_for_student')[1].scrollTop = 0;
      }
    },
    clearSearText () {
      this.params.searchText = '';
    }
  }
};
</script>

