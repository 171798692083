<template>
  <!-- 已批改-->
  <div class="JXT_dynamic_list"
       v-if="studenttaskItem.TasksStatus==1">
    <div class="JXT_dynamic_content">
      <div class="dynamic_content_photo">
        <img class="img_box"
             :src="vStudentImg"
             :onerror="defaultImg"
             alt="">
      </div>
      <div class="dynamic_content_body">
        <div class="body_title_box flex">
          <div class="body_name">
            {{studenttaskItem.Author}}的作业
          </div>
        </div>
        <div class="body_arrange_date">
          <span class="body_arrange_name">{{studenttaskItem.CustKeyValue}}</span>
          <span>提交于 {{vPublishTime}}</span>
        </div>
        <!-- 作业浏览内容 -->
        <browse-item-content :dataInfo="studenttaskItem"
                             :isUnfoldShow="false"></browse-item-content>
      </div>
    </div>
    <!-- 接口异常,以下数据源非真实数据，待处理 -->
    <div class="JXT_homework_check JXT_dynamic_comment"
         v-if="studenttaskItem.CorrectList && (studenttaskItem.CorrectList.ID || isStudentTask)">
      <div class="dynamic_content_body JXT_inform_box pr">
        <div class="inform_alter_btn"
             v-if="!isStudentTask"
             @click="editTaskButClick"></div>
        <div class="homework_check_date body_arrange_date">
          <div class="homework_check_img">
            <img class="img_box"
                 :src="vCorrectImg"
                 :onerror="defaultImg"
                 alt="">
          </div>
          <span class="body_arrange_name">{{vAuthor}}</span>
          <span>批改于 {{vCorrectPublishTime}}</span>
        </div>
        <div class="body_number_box"
             v-if="studenttaskItem.CorrectList.Currency > 0">
          <div class="body_score_ul flex">
            <div v-for="(item,key) in studenttaskItem.CorrectList.Currency"
                 :key="key">
              <div class="body_score_list"></div>
            </div>
          </div>
        </div>
        <!-- 作业浏览内容 -->
        <browse-item-content :dataInfo="studenttaskItem.CorrectList"
                             :isUnfoldShow="false"></browse-item-content>
      </div>

    </div>
  </div>
</template>

<script>
// import browseItemHead from '../common-component/browse-box/browse-item-head';
import browseItemContent from '../../common-component/browse-box/browse-item-content';
import releaseBtn from '../../common-component/release-btn';
export default {
  components: {
    // browseItemHead,
    browseItemContent,
    releaseBtn
  },
  data () {
    return {
      isNOTimeShow: Date.new().getTime()
    };
  },
  props: {
    isStudentTask: {
      type: Boolean,
      default: false
    },
    dataInfo: {
      type: Object,
      default: function () {
        return {};
      }
    },
    studenttaskItem: {
      type: Object,
      default: function () {
        return {};
      }
    },
    taskCorrectInfo: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  computed: {
    vAuthor () {
      if (this.studenttaskItem.CorrectList && this.studenttaskItem.CorrectList.Author != this.$store.getters.token.UserName) {
        return this.studenttaskItem.CorrectList.Author;
      } else {
        return '我';
      }
    },
    // 头像
    vStudentImg () {
      if (this.studenttaskItem.HeadImg) {
        if (this.studenttaskItem.HeadImg.indexOf('http') > -1) {
          return this.studenttaskItem.HeadImg;
        } else {
          return this.$store.getters.CDNURL + this.studenttaskItem.HeadImg;
        }
      } else {
        return '';
      }
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../../public/image/face_screenage_noImg.png') + '"';
    },
    // 时间
    vPublishTime () {
      if (this.studenttaskItem.PublishTime) {
        const month = this.studenttaskItem.PublishTime.slice(5, 7);
        const day = this.studenttaskItem.PublishTime.slice(8, 10);
        const time = this.studenttaskItem.PublishTime.slice(11, 16);
        return month + '-' + day + ' ' + time;
      } else {
        return '';
      }
    },
    vCorrectPublishTime () {
      if (this.studenttaskItem.CorrectList.PublishTime) {
        const month = this.studenttaskItem.CorrectList.PublishTime.slice(5, 7);
        const day = this.studenttaskItem.CorrectList.PublishTime.slice(8, 10);
        const time = this.studenttaskItem.CorrectList.PublishTime.slice(11, 16);
        return month + '-' + day + ' ' + time;
      } else {
        return '';
      }
    },
    vCorrectImg () {
      if (this.studenttaskItem.CorrectList.HeadImg) {
        if (this.studenttaskItem.CorrectList.HeadImg.indexOf('http') > -1) {
          return this.studenttaskItem.CorrectList.HeadImg;
        } else {
          return this.$store.getters.CDNURL + this.studenttaskItem.CorrectList.HeadImg;
        }
      } else {
        return '';
      }
    }
  },
  created () {
    console.log(this.taskCorrectInfo, 'taskCorrectInfo');
  },
  mounted () {
    this.$dialog.register(this, ['taskCorrectDetails']);
  },
  methods: {
    // 重新批改作业
    editTaskButClick () {
      console.log(this.studenttaskItem, '重新批改作业');
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '批改作业',
        moduleName: 'taskCorrectDetails',
        data: { taskData: { courseName: this.taskCorrectInfo.Title, essayID: this.studenttaskItem.CorrectList.ID, isEdit: true } },
        callBack: {
          afterSuccess: () => { this.$emit('doEditTaskClick'); }
        }
      });
    }
  }
};
</script>

<style>
</style>