<template>
  <!-- 提现 -->
  <div class="">
    <div class="form_info_bottom single_shadow content_marginRL">
      <!-- 提现输入-->
      <div class="cash_repayment">
        <div class="input_amount form_info_required">
          <div>{{ amount }}</div>
          <div class="can_input_amount">
            <input
              type="number"
              autocomplete="off"
              onclick="this.select()"
              onkeypress="return (/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              onblur="this.value=Math.floor(this.value * 100)/100"
              @keyup="getValue"
              style="ime-mode: Disabled"
              min="0"
              v-model="cashAmount"
            />
            <!--/^(0|[1-9]\d*)(.\d{1,2})?$/  -->
            <!-- this.value=this.value.replace(/[^\d.]/g,'').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); -->
          </div>
        </div>
        <div class="input_amount_info">
          <span>
            {{ canAmount }}
            <span>{{ ToBeCashed }}</span
            >元
          </span>

          <div class="edit_btn fr" @click.stop="allPaymentOrWithdraw">
            <span>{{ allClean }}</span>
          </div>
        </div>
      </div>
      <!-- 付款方式-->
      <div class="class_table_courseChoose">
        <!-- 选择课程下拉菜单 -->
        <!-- 可编辑界面 form_info_canedit -->
        <div style="padding-left: 15px; padding-bottom: 10px">
          <div class="input_amount form_info_required">
            <div>{{ payOrWithdrawMethods }}</div>
          </div>
        </div>
        <div class="form_info_canedit">
          <div class="form_info_edit">
            <div class="form_info_line">
              <div class="form_info_value">
                <el-select
                  v-model="selectedIncomePayAccount"
                  placeholder="必选"
                  @change="chooseAcountID"
                >
                  <el-option
                    v-for="item in incomePayAccountList"
                    :key="item.OLAPKey"
                    :label="item.MainDemoName"
                    :value="item.OLAPKey"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group
      :isSingleSubmit="true"
      :singleSubmitType="'dynamic'"
      @confirmClick="comfiredebtBackAmount"
      @cancelClick="closeshowWithDraw"
    ></save-cancel-btn-group>
  </div>
</template>
<script>
import {
  cashMoneyOfBeWithdrawaled,
  repaymentApplication,
  getIncomeAccount,
  withdrawaApplication,
  MessageSearchAuditForSZGL,
} from "../../API/workbench";
export default {
  name: "paymentAndWithdraw",
  props: [
    "payOrWithdrawMethods",
    "amount",
    "canAmount",
    "allClean",
    "studentItem",
    "isShowWithdraw",
  ],
  // payOrWithdrawMethods 提供字符串 付款方式 或者 收款方式.
  // amount  字符串,还款金额 或者 提现金额
  // canAmount  字符串 欠款金额 或者 可提现金额,
  // allClean   字符串.  全部还清 或者 全部提现
  data() {
    return {
      paymentOrWithdraw: {},
      ToBeCashed: 0,
      studentInfo: {},
      type: false,
      cashAmount: 0,
      Reason: "", // 提现申请需要的提现理由.
      accountID: 0,
      incomePayAccountList: [], // 客户收支账户
      selectedIncomePayAccount: [], // 选中的账户
      ToBeCheckSZGLList: [], // 待审核收支列表
      ToBeCheckWithdrawalList: [], // 待审核提现列表
    };
  },
  watch: {
    isShowWithdraw() {
      if (this.isShowWithdraw == true) {
        // console.log("收支目录")
        this.validClassList();
      }
    },
    cashAmount() {
      if (this.cashAmount < 0) {
        this.cashAmount = 0;
      }
    },
    studentItem() {
      this.studentInfo = this.studentItem;
      this.loadToBeCheckSZGL();
    },
    studentInfo(n, o) {
      if (this.amount.indexOf("提现") == -1) {
        console.log("还款1", n);
        this.ToBeCashed = Number(n.DebtAmount).toFixed(2);
      } else {
        console.log("提现1");
        this.cashMoneyWithdraw(this.studentInfo.CustKey);
      }
    },
  },
  created() {
    this.studentInfo = this.studentItem;
    this.validClassList();
    this.loadToBeCheckSZGL();
  },
  methods: {
    // 过滤 小数，取两位小数且不需四舍五入
    changeDecimals(cashAmount) {
      var x = String(cashAmount).indexOf(".") + 1; // 小数点的位置
      var y = String(cashAmount).length - x; // 小数的位数
      if (y > 2) {
        this.cashAmount = (parseInt(cashAmount * 100) / 100).toFixed(2);
      } else {
        this.cashAmount = Number(cashAmount);
      }
      // console.log('有' + y + '位小数');
    },
    chooseAcountID(item) {
      // 改变选中账户
      this.accountID = item;
      this.changeDecimals(this.cashAmount);
    },
    allPaymentOrWithdraw() {
      // 全部还款或者全部提现.
      this.cashAmount = Number(this.ToBeCashed);
    },
    closeshowWithDraw() {
      // 取消操作./
      this.$emit("showWithDraw", false);
      this.$emit("closeshowWithDraw", false);
    },
    comfiredebtBackAmount(callBack) {
      this.changeDecimals(this.cashAmount);
      if (this.amount.indexOf("提现") == -1) {
        // 调用还款申请接口.
        this.replyCash(
          this.studentInfo.CustKey,
          this.cashAmount,
          this.accountID,
          callBack
        );
      } else {
        this.applyWithdraw(
          this.studentInfo.CustKey,
          this.studentInfo.OLAPKey,
          this.cashAmount,
          this.accountID,
          this.Reason,
          callBack
        );
      }
    },
    // 还款申请接口.
    replyCash(CustKey, money, accountID, callBack) {
      let flag = false;
      if (money == 0) {
        layer.alert("还款金额需大于0");
        flag = true;
      } else if (money >= 100000000) {
        layer.alert("还款金额必须小于一亿");
        flag = true;
      } else if (!this.accountID) {
        if (this.payOrWithdrawMethods.indexOf("付款") != -1) {
          layer.alert("请选择付款方式");
        } else {
          layer.alert("请选择收款方式");
        }
        flag = true;
      } else if (this.ToBeCheckSZGLList.length > 0) {
        layer.alert("该学生有其它还款待审批，请先处理。");
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      repaymentApplication(CustKey, money, accountID, this.studentInfo.OLAPKey)
        .then(
          (result) => {
            this.cashAmount = 0;
            this.closeshowWithDraw();
            this.$emit("showsucceedPayment", true);
          },
          (error) => {
            layer.alert(error.msg);
          }
        )
        .finally(() => {
          if (callBack) {
            callBack();
          }
        });
    },
    // 提现金额
    cashMoneyWithdraw(OLAPkey) {
      // 提现信息接口.
      cashMoneyOfBeWithdrawaled(OLAPkey).then(
        (result) => {
          this.paymentOrWithdraw = result.data;
          this.ToBeCashed = Number(result.data.SumAmount);
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    // 提现申请接口
    applyWithdraw(CustKey, OLAPKey, money, accountID, Reason, callBack) {
      let flag = false;
      if (money == 0) {
        layer.alert("提现金额需大于0");
        flag = true;
      } else if (money >= 100000000) {
        layer.alert("提现必须小于一亿");
        flag = true;
      } else if (!this.accountID) {
        if (this.payOrWithdrawMethods.indexOf("付款") != -1) {
          layer.alert("请选择付款方式");
        } else {
          layer.alert("请选择收款方式");
        }
        flag = true;
      } else if (this.ToBeCheckWithdrawalList.length > 0) {
        layer.alert("该学生有其它提现待审批，请先处理。");
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      withdrawaApplication(CustKey, OLAPKey, money, accountID, Reason)
        .then(
          (result) => {
            this.cashAmount = 0;
            this.$emit("showsucceedPayment", true);
          },
          (error) => {
            layer.alert(error.msg);
          }
        )
        .finally(() => {
          if (callBack) {
            callBack();
          }
        });
    },
    // 获取收支账户接口
    validClassList() {
      getIncomeAccount().then((result) => {
        if (result.code == 0) {
          this.incomePayAccountList = result.data;
        } else {
          layer.alert("接口异常，注意检查");
        }
      });
    },
    // 待审核查询接口
    loadToBeCheckSZGL() {
      MessageSearchAuditForSZGL().then(
        (result) => {
          this.ToBeCheckSZGLList = (result.data || []).filter((obj) => {
            return (
              obj.HapObjTypeKey == 1 &&
              obj.IncomePayTypeKey == 1 &&
              obj.HapPeolKey == this.studentInfo.CustKey
            );
          }); // 发生人为客户且收支类型为收
          this.ToBeCheckWithdrawalList = (result.data || []).filter((obj) => {
            return (
              obj.HapObjTypeKey == 1 &&
              obj.IncomePayTypeKey == 2 &&
              obj.HapPeolKey == this.studentInfo.CustKey
            );
          }); // 发生人为客户且收支类型为支
          console.log(this.ToBeCheckWithdrawalList, "待审核 ");
        },
        (error) => {
          console.log("loadToBeCheckSZGL", error.msg);
        }
      );
    },

    //返回输入值
    getValue(val) {
      let arr = []
      // count为零时不取值
      if (this.cashAmount != 0) {
        arr = this.cashAmount.toString().split("."); //取小数点后面的值
      }
      // 判断是否取到值
      if (arr.length > 1) {
        // 当小数点后面的长度大于需要的长度时 返回
        if (arr[1].length > 2) {
          this.cashAmount = Math.floor(this.cashAmount * 100)/100
        }
      }
    },
  },
};
</script>
<style>
</style>



