<template>
  <div>
    <div class="SystemPort">
      <div class="SystemPort_all">
        <div class="SystemPort_all_title">全部系统端口</div>
        <div class="SystemPort_all_text">
          <span style="margin-left:50px;">{{PortData.PortCount}}</span>
        </div>
      </div>
      <div class="SystemPort_timeLimit">
        <div class="SystemPort_timeLimit_title">30天内到期</div>
        <div class="SystemPort_all_text"
             @click="clickData">
          <span style="margin-left:50px;color:#ff8723;">{{PortData.OverdueBymonth}}</span>
        </div>
      </div>
      <div class="SystemPort_details">
        <div class="SystemPort_details_left">
          <div class="SystemPort_details_left_left">
            <div class="SystemPort_details_left_left_title">总部</div>
            <div style="display: flex;margin-top: 10px;">
              <div class="SystemPort_details_left_left_open">
                <div>开通日期</div>
                <div style="color:#000000;margin-top: 5px;">{{PortData.CreateDateTime}}</div>
              </div>
              <div class="SystemPort_details_left_right_dateDue">
                <div style="color: #999999;margin-bottom: 5px;">到期日期</div>
                <span>{{PortData.ExpireDateTime}}</span>
                <span>{{PortData.endDateTime}}天</span>
              </div>
            </div>

            <!-- <div class="SystemPort_details_left_right_Renew">续费</div> -->
          </div>
          <div style="width:1px;height:90px;background-color:#eeeeee;margin: 0 15px"></div>
          <div class="SystemPort_details_bottom">
            <div class="SystemPort_details_left_left_title">剩余可用端口</div>
            <div style="display:flex;margin-top:10px">
              <div class="SystemPort_details_bottom_courseware">
                <img class="imgIcon"
                     src="../../../../../public/image/kejian.png"
                     alt="">
                <div style="margin: 0 16px 0 10px;">
                  <div class="bottomText">课件端口</div>
                  <div class="bottomNumber">{{Number(PortData.CoursewarePortalCount)}}</div>
                </div>
                <!-- <div class="topUp">
                    充值
                    <i style="color:#999999" class="el-icon-arrow-right"></i>
                  </div> -->
              </div>
              <div class="SystemPort_details_bottom_educational">
                <img class="imgIcon"
                     src="../../../../../public/image/jiaowu.png"
                     alt="">
                <div style="margin: 0 16px 0 10px;">
                  <div class="bottomText">教务端口</div>
                  <div class="bottomNumber">{{PortData.CurrentPortalNum}}</div>
                </div>
                <!-- <div class="topUp">
                    充值
                    <i style="color:#999999" class="el-icon-arrow-right"></i>
                  </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GetPortStatistics
} from '../../../../API/workbench';
export default {

  data () {
    return {
      PortData: {} // 端口数据
    };
  },
  props: {
    params: Object
  },
  created () {
    this.getGetPortStatistics();
  },

  mounted () {

  },

  methods: {
    getGetPortStatistics () {
      GetPortStatistics().then(res => {
        console.log(res, '获取系统端口统计');
        if (res.data.CoursewarePortalCount == '.0000') {
          res.data.CoursewarePortalCount = 0;
        }
        this.PortData = res.data;
      });
    },
    clickData () {
      this.params.IsExpired30Key = 1;
      this.params.pageIndex = 0;
      this.params.orderBy = '';
      this.params.searchText = '';
      this.$emit('loadTableData', this.params);
    }
  }
};
</script>

<style scoped>
.SystemPort {
  display: flex;
  margin-bottom: 20px;
}

/* 第一个模块 */
.SystemPort_all {
  width: 181px;
  height: 120px;
  background: linear-gradient(0deg, #ffffff, #ffffff), #edfcf2;
  border-radius: 8px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SystemPort_all_title {
  /* display: flex; */
  font-family: 'Alibaba PuHuiTi';
  /* justify-content: center; */
  margin: 22px 0 30px;
  color: #666;
}

.SystemPort_all_text {
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  width: 100%;
  color: #333333;
  cursor: pointer;
  /* display: flex; */
  /* justify-content: center; */
}

/* 第二个模块 */
.SystemPort_timeLimit {
  background: linear-gradient(0deg, #ffffff, #ffffff), #f1f7ff;
  border-radius: 8px;
  width: 181px;
  height: 120px;
  margin-right: 20px;
}

.SystemPort_timeLimit_title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  color: #666666;
  display: flex;
  justify-content: center;
  margin: 22px 0 30px;
}

/* 第三个模块 */
/* 上 */
.SystemPort_details {
  background: #ffffff;
  border-radius: 8px;
  width: 888px;
  /* height: 109px; */
}

.SystemPort_details_left {
  display: flex;
  margin: 15px 20px 15px 20px;
  flex: 1;
  justify-content: space-between;
}

.SystemPort_details_left_left {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.SystemPort_details_left_left_title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 500;
  color: #666666;
}

.SystemPort_details_left_left_open {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  color: #999999;
  margin-right: 10px;
  flex: 1;
  padding: 15px;
  border-radius: 5px;
  background-color: #f7f8fa;
}

.SystemPort_details_left_right_dateDue {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  flex: 1;
  padding: 15px;
  border-radius: 5px;
  background-color: #f7f8fa;
}

.SystemPort_details_left_right_Renew {
  /* background: linear-gradient(0deg, #3498DB, #3498DB), linear-gradient(0deg, #F8F8F8, #F8F8F8), #F1F7FF;
    border-radius: 100px;
    width: 48px;
    height: 24px;
    color: #FFFFFF;
    font-family: 'Alibaba PuHuiTi';
    font-style: normal;
    line-height: 24px;
    display: flex;
    justify-content: center;
    cursor: pointer */
}

/* 下 */
.SystemPort_details_bottom {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.SystemPort_details_bottom_surplus {
  display: flex;
  /* margin-right: 20px; */
  align-items: center;
  flex: 1;
}

.SystemPort_details_bottom_courseware {
  display: flex;
  flex: 1;
  background-color: #f7f8fa;
  border-radius: 5px;
  padding: 15px;
  margin-right: 10px;
}

.SystemPort_details_bottom_educational {
  display: flex;
  flex: 1;
  background-color: #f7f8fa;
  border-radius: 5px;
  padding: 15px;
}

.imgIcon {
  width: 26px;
  height: 26px;
}

.bottomText {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  color: #999999;
  margin-bottom: 5px;
}

.bottomNumber {
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  color: #333333;
}

.topUp {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  color: #3498db;
  margin-left: 10px;
  cursor: pointer;
}
</style>
