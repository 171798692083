<template>
  <!-- 大课包 -->
  <div class="max_courseware_manage"
       :class="vMendianInfo.EditionKey!=6?'max_courseware_manage':'max_courseware_manage_fiexd'">
    <div class="courseware_manage_title "
         :class="vMendianInfo.EditionKey!=6?'courseware_manage_titles':'courseware_manage_title_fiexd'">
      <!-- <div class="header_img">
        <img :src="headImg"
             width="48"
             height="48"
             style="border-radius: 100%;"
             :onerror="defaultImg"
             alt="../../../../public/image/no_logo@2x.png" />
      </div> -->
      <div class="header_option">
        <span class="header_option_name"
              :title="vMendianInfo.MainDemoName">{{vMendianInfo.MainDemoName}}</span>
        <div class="header_option_box">
          <span class="header_option_box_item">
            系列课包
            <span class="font_black">{{statInfo.BigCoursewarePackageCount}}</span>
          </span>
          <span class="header_option_box_item">
            发布课件
            <span class="font_black">{{statInfo.ReleaseCoursewareCount}}</span>
          </span>
          <span class="header_option_box_item">
            占用容量
            <span class="font_black">{{this.$utils.capacityFormate(Number(statInfo.OccupiedCapacity))}}</span>
          </span>

        </div>
      </div>
      <div class="header_bottom"
           v-if="vShowListAdd">
        <function-btn :item="{ name: '新建大课包', ModulePowerKey: '' }"
                      :className="'el-button buttom_sq font_blue courseware_icon_03'"
                      @clickItem="doOperateItemClick({key:'add'})">
        </function-btn>
        <function-btn v-if="type != 'SQ'"
                      :item="{ name: '回收站', ModulePowerKey: '' }"
                      :className="'el-button  buttom_sq courseware_icon_02'"
                      @clickItem="OpenRecycleBin">
        </function-btn>
      </div>
    </div>
    <div :class="vMendianInfo.EditionKey!=6?'courseware_item':'courseware_item_fiexd'">
      <draggable v-model="newMaxCoursewareList"
                 class="big_courseware_list"
                 :options="{group:{name: 'site'},sort: false}"
                 animation="100"
                 dragClass="dragClass"
                 ghostClass="ghostClass"
                 chosenClass="chosenClass"
                 filter=".forbid"
                 handle=".mover"
                 v-bind="{sort: false,store: false,}"
                 @start="onStart"
                 @end="onEnd"
                 :move="onMove"
                 @add="onAdd">
        <transition-group class="draggable">
          <list-item v-for="item in newMaxCoursewareList"
                     :key="item.ID"
                     :type="vType"
                     :listLength="newMaxCoursewareList.length"
                     :dataInfo="item"
                     :isDrag="isDrag"
                     @selectEvent="selectEvent"
                     @doOperateItemClick="doOperateItemClick"
                     @moveStart="moveStart"
                     @stopMove="stopMove"
                     @doListSort="doListSort"></list-item>
          <div v-for="i in 20"
               :key="i"
               class="makeweight"></div>
        </transition-group>
      </draggable>
      <!-- <div class="max_list_add_box"
           
           @click="doOperateItemClick({key:'add'})">
        <span class="max_list_add">+</span>
      </div> -->
      <div v-if="newMaxCoursewareList.length==0"
           style="position:relative">
        <div class="courseware_manage_right"
             :class="vMendianInfo.EditionKey!=6?'courseware_manage_titles nullTip':'nullTips'"
             style="position: absolute;top: -147px; left: 10px;">
          <div class="right_nodata"
               style="top: 140px;">还没有课件哦~</div>
          <div class="box_notNull_bottom">
            <div class="notNull_bottom"
                 v-if="vShowListAdd">
              <function-btn :item="{ name: '＋ 新建课包', ModulePowerKey: '' }"
                            :className="'el-button btn_light_blue btn_hover_bg_blue buttom_sq'"
                            @clickItem="doOperateItemClick({key:'add'})">
              </function-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <custom-dialog :title="titleName"
                   :visible.sync="isShowCoursewareFormPopup"
                   :before-close="closeCoursewareFormPopup">
      <max-courseware-form :coursewareId="coursewareId"
                           :isReadonly="false"
                           @close="closeCoursewareFormPopup"
                           @afterSuccess="editCoursewarePackageAfterSuccess"></max-courseware-form>
    </custom-dialog>

    <div>
      <dialog-factory ref="hqCoursewareManagementCommonDialogRecycleBin2"
                      :key="'hqCoursewareManagement'"
                      :routerName="'hqCoursewareManagement'"></dialog-factory>
      <dialog-factory-common class="recycle_bin_list"
                             ref="hqCoursewareManagementCommonDialogRecycleBin"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
// import {hqCoursewarePackageManageDisable} from '../../../API/workbench'
import dialogFactory from '../../common/dialog-factory';
import listItem from './list-item';
import maxCoursewareForm from './max-courseware-form';
import draggable from 'vuedraggable';
import {
  HQgetCoursewareStat,
  WebVgetCoursewareStat,
  cwsGetCoursewareStat
} from '../../../API/workbench';
export default {
  components: {
    listItem,
    maxCoursewareForm,
    dialogFactory,
    draggable
  },
  data () {
    return {
      // 打开回收站参数
      investorInfo: {
        name: '',
        moduleName: 'hqRecycleBin'
      },
      titleName: '添加课件包',
      coursewareId: 0, // 课件包ID
      newMaxCoursewareList: [], // 大课包数据列表
      isShowCoursewareFormPopup: false, // 对话框
      isDrag: false,
      moveItem: null
    };
  },
  props: {
    maxCoursewareList: Array,
    coursewareInfo: Object,
    type: String,
    statInfo: Object
  },
  computed: {
    // 非自研课件时不显示添加按钮
    vShowListAdd () {
      console.log(this.type, '非自研课件时不显示添加按钮');
      if (this.type == 'SQ') {
        return false;
      } else {
        return true;
      }
    },
    defaultImg () {
      return (
        'this.src="' +
        require('../../../../public/image/deflut_teacher_photo@2x.png') +
        '"'
      );
    },
    vType () {
      return this.type;
    },
    vMendianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },

    headImg () {
      if (this.vMendianInfo && this.vMendianInfo.LogoPath) {
        if (this.vMendianInfo.LogoPath.indexOf('http') > -1) {
          return this.vMendianInfo.LogoPath;
        } else {
          return this.$store.getters.CDNURL + this.vMendianInfo.LogoPath;
        }
      } else {
        return '';
      }
    }
  },
  watch: {
    maxCoursewareList (n, c) {
      console.log(n, 'this.newMaxCoursewareList-watch');
      this.newMaxCoursewareList = n;
      this.selectedDefaultCourseware(this.newMaxCoursewareList);
    }
    // vMendianInfo: {
    //   handler (c, o) {
    //     if (c) {
    //       this.getCoursewareStat();
    //     }
    //   },
    //   deep: true
    // }
  },
  created () {
    this.newMaxCoursewareList = this.maxCoursewareList;
  },
  mounted () {
  },
  methods: {
    stopMove () {
      this.isDrag = false;
      console.log('stopMove');
    },
    moveStart (item) {
      this.isDrag = true;
      this.moveItem = item;
      console.log('moveStart', this.moveItem);
    },
    onMove (e) {
      console.log('onMove');
      // 移入时判断是否可移入
      // return this.$parent.draggRuleStudentMove(e)
      return this.isDrag == true;
    },
    // 开始拖拽事件
    onStart (e) {
      // this.draggableItem = this.newMaxCoursewareList[e.oldIndex];

    },
    onAdd (e) {
      this.isDrag = false;
    },
    // 拖拽结束事件
    onEnd (e) {
      console.log('onEnd to', e);
      if (this.isDrag) {
        this.isDrag = false;
        this.$emit('setSortList', this.moveItem, e.newIndex + 1);
      }
    },
    // 默认初始化,列表第一项选中高亮
    selectedDefaultCourseware (dataList) {
      if (dataList.length > 0) {
        dataList.forEach(o => {
          this.$set(o, 'isSelected', false);
          this.$set(o, 'isShowBtn', false);
        });
        // let findIndex = -1;
        // findIndex = dataList.findIndex(o => {
        //   return this.coursewareInfo.ID == o.ID;
        // });
        // if (findIndex >= 0) {
        //   this.$set(dataList[findIndex], 'isSelected', true);
        // } else {
        //   this.$set(dataList[0], 'isSelected', true);
        // }
      }
    },
    // 选择课件包
    selectEvent (dataInfo) {
      console.log(dataInfo, '点击事件');
      this.newMaxCoursewareList.forEach(o => {
        this.$set(o, 'isSelected', false);
        if (Number(dataInfo.ID) === Number(o.ID)) {
          this.$set(o, 'isSelected', true);
        }
      });
      this.$parent.isLoadData = true;
      this.$emit('selectEvent', dataInfo);
    },
    // 操作项
    doOperateItemClick (item, data) {
      console.log(item, data, '操作项');
      // 添加
      if (item.key == 'add') {
        this.coursewareId = 0; // 课件包ID
        this.titleName = '添加大课包';
        this.isShowCoursewareFormPopup = true;
      }
      // 编辑
      else if (item.key == 'edit') {
        this.coursewareId = Number(data.ID); // 课件包ID
        this.titleName = '编辑大课包';
        this.isShowCoursewareFormPopup = true;
      } else {
        this.$emit('doOperateItemClick', item, data);
      }
      // if (item.key == 'disable')
      // 停用
      // if(item.key == 'disable') {
      // this.coursewareId = Number(data.ID); // 课件包ID
      // await hqCoursewarePackageManageDisable(data.ID)
      // 停用刷新页面
      // this.$emit('editCoursewarePackageAfterSuccess')
      // layer.alert('已停用');
      // 对话框显示隐藏
      // this.isShowCoursewareFormPopup = true;
      //   return;
      // }
    },
    // 添加/修改 课件包成功之后
    editCoursewarePackageAfterSuccess (type, data) {
      if (type == 'add') {
        this.newMaxCoursewareList.push(data);
        // setTimeout(() => {
        //   this.selectEvent(data);
        // }, 500);
      } else if (type == 'edit') {
        this.newMaxCoursewareList.forEach(o => {
          if (o.ID == data.ID) {
            Object.assign(o, data);
          }
        });
      }
      this.isShowCoursewareFormPopup = false;
      console.log(data, this.newMaxCoursewareList, '添加/修改 课件包成功之后');
      this.$emit('afterSuccess');
    },
    closeCoursewareFormPopup () {
      this.coursewareId = 0;
      this.isShowCoursewareFormPopup = false;
    },
    // 排序
    doListSort (sortType, data) {
      this.$emit('doListSort', sortType, data);
    },
    // 打开回收站
    OpenRecycleBin () {
      // let type = this.$store.getters.SaaSClientInfo.EditionKey;
      this.openCommonCustomDialogByFactoryDialog(this.investorInfo);
    },

    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.hqCoursewareManagementCommonDialogRecycleBin) {
        this.$refs.hqCoursewareManagementCommonDialogRecycleBin.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callback,
          dialogInfo.name || dialogInfo.title
        );
      }
    }

  }
};
</script>

<style scoped>
.max_courseware_manage {
  max-width: 1920px;
  min-width: 1366px;
  /* background: #fff; */
  width: 100%;
  height: 100%;
  border-radius: 8px;
  margin: 0px auto;
  /* padding-top: 25px; */
  /* overflow-y: hidden; */
  min-height: 800px;
  padding-left: 0px;
}
.max_courseware_manage_fiexd {
  width: 100% !important;
}
.max_courseware_manage .max_list_add_box {
  height: 230px;
  background: #fff;
  border-radius: 8px;
  padding: 6px;
  float: left;
  margin: 0 20px 40px 19px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.max_courseware_manage .max_list_add {
  line-height: 166px;
  font-size: 32px;
  color: #3498db;
  text-align: center;
  background: #f8f8fa;
  border: 1px solid #e1e4e7;
  border-radius: 8px;
  cursor: pointer;
  float: left;
  width: 300px;
}
.max_courseware_manage .max_list_add:hover {
  border-color: #3498db;
  float: left;
}
.hq_recycle_bin {
  width: 64px;
  height: 61px;
  border-radius: 50%;
  position: fixed;
  cursor: pointer;
  /* background: #3498db;
  line-height: 50px; */
  text-align: center;
  /* color: #fff; */
  right: 300px;
  bottom: 70px;
  background: url(../../../../public/image/recycle_bin_btn_icon.png) no-repeat;
  background-size: 64px;
  z-index: 1;
}
.courseware_manage_title {
  position: relative;
  height: 92px;
  width: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.02), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 8px 8px;

  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}
@media screen and (min-width: 1367px) {
  .courseware_manage_title {
    height: 92px;
    width: 100%;
  }
  .courseware_manage_titles {
    width: 100%;
    position: fixed;
    top: 68px;

    /* left: 14.3% !important; */
  }
  .nullTips {
    padding-top: 140px;
    left: 20px;
    z-index: 0;
    width: 997px !important;
  }
  .nullTip {
    padding-top: 140px;
    left: 20px;
    margin-top: 150px;
    margin-left: 20px;
    z-index: 0;
  }
  .courseware_manage_title_fiexd {
    /* position: fixed;
    top: 0px;
    width: 1325px;
    left: 50px !important; */
  }
  .courseware_manage_right {
    width: 93%;
  }
}
.courseware_manage_title_fiexd {
  position: absolute;
  /* width: 990px !important; */
  left: 50%;
  top: 100px;
  z-index: 1;
  transform: translate(-50%, -50%);
}
.courseware_item_fiexd {
  padding-top: 125px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
/* 媒体查询 解决兼容分辨率放大1366的问题 */
@media screen and (max-width: 1366px) {
  .courseware_item_fiexd {
    margin-left: -3px !important;
  }
}
/* 媒体查询 解决兼容分辨率放大125%的问题 */
@media (-webkit-min-device-pixel-ratio: 1.25) {
  .courseware_item_fiexd {
    width: 100%;
  }
  .max_courseware_manage_fiexd .courseware_item_fiexd {
    width: 100%;
  }
}
/* 媒体查询 解决兼容分辨率放大150%的问题 */
@media (-webkit-min-device-pixel-ratio: 1.5) {
  .courseware_manage_title_fiexd {
    margin-left: 2px !important;
    /* width: 960px !important; */
  }
  .courseware_item_fiexd {
    margin-left: -28px !important;
  }
  .max_courseware_manage_fiexd {
    width: 960px !important;
  }
}
.courseware_manage_titles {
  width: 100%;
  /* left: 12.3%; */
  z-index: 2;
  position: relative;
  top: 0;
}

.header_img {
  width: 75px;
  padding-top: 25px;
  padding-left: 27px;
}
.header_option {
  width: 600px;
  display: flex;
  flex-direction: column;
}
.header_option_name {
  height: 45px;
  padding-top: 11px;
  padding-left: 37px;
  color: #333333;
  font-family: Alibaba PuHuiTi;
  font-style: Regular;
  font-size: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.header_option_box {
  /* height: 50px; */
  padding-left: 37px;
  display: flex;
  flex-direction: row;
}
.header_option_box_item {
  display: inline-block;
  border-radius: 3px;
  background: rgba(217, 223, 228, 0.4);
  color: #999;
  font-size: 14px;
  height: 24px;
  text-align: center;
  margin-right: 16px;
  padding: 0 10px;
  line-height: 24px;
}
.header_option_id {
  color: #999999;
  font-size: 14px;
  font-family: Mixed;
  font-style: Regular;
}
.header_option_idvalue {
  color: #333333;
  font-size: 14px;
  font-family: Mixed;
  font-style: Regular;
}
.header_item {
  width: 150px;
  display: flex;
  flex-direction: row;
}
.header_item_icon {
  width: 31px;
  height: 31px;
  margin-top: 35px;
}
.header_item_span {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  padding-top: 25px;
}
.header_item_span_course {
  color: #999999;
  font-family: Alibaba PuHuiTi;
  font-style: Regular;
  font-size: 12px;
  line-height: 22px;
}
.header_item_span_value {
  color: #333333;
  font-family: DIN;
  font-style: Medium;
  font-size: 20px;
}
.header_bottom {
  position: absolute;
  right: 50px;
  top: 30px;
}
.courseware_icon_02,
.courseware_icon_03 {
  padding-left: 45px;
  border: none !important;
}
.courseware_icon_02:hover,
.courseware_icon_03:hover {
  /* color: #b9ddf5 !important; */
  background: none !important;
}
.courseware_icon_02::before {
  content: '';
  position: absolute;
  width: 28px;
  height: 28px;
  left: 10px;
  top: 3px;
  background: url(../../../../public/image/courseware_icon_02.png) center
    no-repeat;
  background-size: 23px;
}
.courseware_icon_03::before {
  content: '';
  position: absolute;
  width: 28px;
  height: 28px;
  left: 10px;
  top: 3px;
  background: url(../../../../public/image/courseware_icon_03.png) center
    no-repeat;
  background-size: 28px;
}
.courseware_manage_right {
  width: 97%;
}
.courseware_manage_rights {
  width: 98%;
}
.courseware_manage_rights {
  position: relative;
  min-height: 680px;
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 20px;
}
.courseware_manage_rights .right_nodata {
  height: 200px;
  font-size: 14px;
  line-height: 18px;
  color: #a9aaaa;
  text-align: center;
  padding-top: 175px;
  position: absolute;
  top: 310px;
  left: 0;
  right: 0;
  background: #fff url(../../../../public/image/courseware_no_data.png)
    no-repeat center center;
}
.notNull_bottom {
  /* position: absolute;
    top: 350px;
    left: 447px; */
}
.box_notNull_bottom {
  width: 100%;
  text-align: center;
  /* margin-top: 210px; */
  position: absolute;
  top: 355px;
}
.courseware_item {
  max-width: 1920px;
  min-width: 1366px;
  margin: auto;
  padding-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* margin: 0 70px; */
}
.big_courseware_list .draggable {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
}
.nullTip {
  z-index: 1;
}
.nullTips {
  top: -4px !important;
  left: 14px !important;
}
.makeweight {
  width: 416px;
  height: 0;
  margin: 0 14px;
}
</style>
