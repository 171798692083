<template>
  <div class="course_statistics_right general_summarizing_student box_shadow_border_div">
    <div class="general_summarizing_student_title attendance_statistics ">
      <div class="general_summarizing_student_nav"
           style="width:500px">
        <menu-item v-for="item in menuList"
                   :key="item.type"
                   :item="item"
                   @seletedItem="seletedMenuAfter"></menu-item>
      </div>
    </div>
    <div class=" attendance_statistics">
      <div v-if="searchType==0">
        <div class="apply_payment_echarts  margintop  fl">
          <day-statistics-report @showDetail="showDetail">
          </day-statistics-report>
          <monthStatisticsReport @showDetail="showDetail"></monthStatisticsReport>
        </div>
      </div>
      <div v-else-if="searchType==1">
        <course-report ref="courseReport"
                       :seletedDate="seletedDate"
                       :teacherList="teacherList"
                       :gradeClassList="gradeClassList"
                       :courseNameList="courseNameList"
                       @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"></course-report>
      </div>
      <div v-else-if="searchType==2">
        <atten-report ref="attenReport"
                      :teacherList="teacherList"
                      :gradeClassList="gradeClassList"
                      :courseNameList="courseNameList"
                      :searchInfo="attenReportSeachObj"
                      :dateReadonly="dateReadonly"
                      @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"></atten-report>
      </div>

      <div v-else-if="searchType==3">
        <deduct-detail ref="deductDetail"
                       :teacherList="teacherList"
                       :gradeClassList="gradeClassList"
                       :courseNameList="courseNameList"
                       @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"></deduct-detail>
      </div>
      <div v-else-if="searchType==4">
        <student-management-report ref="studentManagementReport"
                                   :teacherList="teacherList"
                                   :gradeClassList="gradeClassList"
                                   :courseNameList="courseNameList"
                                   @doAfterTableBtnClicks="doAfterTableBtnClicks"
                                   @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"></student-management-report>
      </div>
      <div v-else-if="searchType==5">
        <atten-exception-report ref="attenExceptionReport"
                                :teacherList="teacherList"
                                :gradeClassList="gradeClassList"
                                :courseNameList="courseNameList"
                                @doAfterTableBtnClicks="doAfterTableBtnClicks"
                                @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"></atten-exception-report>
      </div>
    </div>

  </div>
</template>
<script>
import menuItem from './components/menu-item';
import courseReport from './components/course-report';
import attenReport from './components/atten-report';
import classCountDetail from './components/class-count-detail';
import deductDetail from './components/deduct-detail';
import dayStatisticsReport from '../../base-module/attention-grade/day-statistics-report';
import monthStatisticsReport from '../../base-module/attention-grade/month-statistics-report';
import studentManagementReport from '../../report/student-management/student-attendance-list/index';
import attenExceptionReport from './components/atten-exception-report';
import { geTteacherList, getUsingClass, getCourseTypeNameListX } from '../../../API/workbench';
export default {
  data () {
    return {
      searchType: 0,
      menuList: [
        {
          type: 0,
          name: '考勤签到柱图',
          isActive: true
        },
        {
          type: 1,
          name: '课次明细',
          isActive: false
        },
        {
          type: 2,
          name: '学生考勤明细',
          isActive: false
        },
        // {
        //   type: 2,
        //   name: '异常明细',
        //   isActive: false
        // },
        {
          type: 3,
          name: '欠课时明细',
          isActive: false
        },
        {
          type: 4,
          name: '学生考勤',
          isActive: false
        },
        {
          type: 5,
          name: '异常考勤明细',
          isActive: false
        }
      ],
      teacherList: [],
      gradeClassList: [],
      courseNameList: [],
      seletedDate: null,
      attenReportSeachObj: null,
      dateReadonly: false

    };
  },
  components: {
    menuItem, courseReport, attenReport, classCountDetail, deductDetail, dayStatisticsReport, monthStatisticsReport, studentManagementReport, attenExceptionReport
  },
  created () {
    console.log(this.$route.query, 'this.$route.query');
    if (this.$route.query.type == 2) {      // type 为1 默认打开考勤明细.
      this.seletedMenuAfter(2);
    }
    this.loadDataSourceTeacher();
    this.getValidClassList();
    this.getCourseNameList();
  },
  activated () {
    console.log('activated');
    if (this.$route.query.type == 1) {      // type 为1 默认打开考勤明细.
      this.seletedMenuAfter(1);
    } else if (this.$route.query.type == 2) {
      this.seletedMenuAfter(2);
    } else {
      this.seletedMenuAfter(this.searchType);
    }
  },
  methods: {
    seletedMenuAfter (type, callback) {     // 防止seletedDate未清空.
      this.attenReportSeachObj = null;
      this.dateReadonly = false;
      console.log(callback, 'callback');
      this.seletedDate = null;
      this.menuList.forEach(o => {
        if (o.type == type) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      if (callback) {
        callback();
      }
      this.searchType = type;
    },
    // 点击月统计单项的日期指定打开课程列表的指定日期
    openCourseReportGetDataByDate (date) {
      this.seletedDate = date;
      console.log(date, 'openCourseReportGetDataByDate');
      if (this.searchType == 1) {
        this.$nextTick(() => {
          this.$refs.courseReport.searchData(this.seletedDate);
        });
      } else {
        this.seletedMenuAfter(1, () => {
          this.seletedDate = date;
        });
      }
    },
    openCustomDialogByFactoryDialog (item) {
      this.$emit('openCustomDialogByFactoryDialog', item);
    },
    // 调用在职老师接口
    loadDataSourceTeacher () {
      geTteacherList().then(result => {
        this.teacherList = result.data;
        this.teacherList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
      });
    },
    // 获取有效班级列表
    getValidClassList () {
      getUsingClass().then(result => {
        this.gradeClassList = result.data;
        this.gradeClassList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
      });
    },
    getCourseNameList () {
      getCourseTypeNameListX('OLAPKey', 'desc').then(result => {
        this.courseNameList = result.data.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; });
        this.courseNameList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
      });
    },
    showDetail (title, startTime, endTime) {
      console.log(title, startTime, endTime);
      this.attenReportSeachObj = {};
      this.attenReportSeachObj.startTime = startTime;
      this.attenReportSeachObj.endTime = endTime;
      this.seletedDate = null;
      this.menuList.forEach(o => {
        if (o.type == 2) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.searchType = 2;
    },
    doAfterTableBtnClicks (data) {
      console.log('data', data);
      this.attenReportSeachObj = {};
      this.attenReportSeachObj = data;
      this.seletedDate = null;
      this.menuList.forEach(o => {
        if (o.type == 2) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.searchType = 2;
    }

  }

};
</script>
<style scoped>
.apply_payment_echarts {
  width: 100%;
}
.attendance_statistics >>> .nav_list {
  width: 140px;
}
.attendance_statistics
  >>> .general_summarizing_student_nav
  .nav_list:nth-child(3) {
  padding: 0px;
}
.attendance_statistics >>> .echarts_m div canvas {
  width: 100%;
}
.margintop {
  margin-top: 20px;
}
</style>
