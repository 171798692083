<template>
  <!-- 补课管理 -->
  <div class="repair_course_view">
    <!-- 头部 -->
    <div class="repair_course_title">
      <statistics-tltle-head :titleList="statisticsTltleList"></statistics-tltle-head>
    </div>

    <div style="min-width: 1366px;max-width: 1920px;margin:0 auto;margin-top: 90px;">
      <!-- 学生补课汇总 -->
      <div v-if="statisticsTltleList[0].isSelected">
        <repair-students-statistics></repair-students-statistics>
      </div>

      <!-- 请假补课待处理 -->
      <div v-else-if="statisticsTltleList[1].isSelected">
        <wait-statistics :searchObj='searchObj'></wait-statistics>
      </div>
      <!-- 已处理 -->
      <div v-else-if="statisticsTltleList[2].isSelected">
        <finish-statistics></finish-statistics>
      </div>
      <!-- 补课未关联处理 -->
      <div v-else-if="statisticsTltleList[3].isSelected">
        <repair-unassociated-handle :searchObj='searchObj'></repair-unassociated-handle>
      </div>
    </div>
    <div>
      <dialog-factory ref="repairCourseManagementFactory"
                      :key="'repairCourseManagement'"
                      :routerName="'repairCourseManagement'"></dialog-factory>
      <dialog-factory-common ref="operatingDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import repairStudentsStatistics from './repair-students-statistics/index.vue';
import waitStatistics from './wait-statistics/index.vue';
import finishStatistics from './finish-statistics/index.vue';
import repairUnassociatedHandle from './repair-unassociated-handle/index.vue';
import statisticsTltleHead from '../../../components/head-quarters/campus-supervise-school-statistics/common/statistics-tltle-head.vue';
import dialogFactory from '../../common/dialog-factory';
export default {
  name: 'repairCourseManagement',
  data () {
    return {
      statisticsTltleList: [
        { name: '学生补课汇总', key: 1, isSelected: false },
        { name: '请假补课待处理', key: 2, isSelected: false },
        { name: '已处理', key: 3, isSelected: false },
        { name: '补课未关联', key: 4, isSelected: false }
      ],
      searchObj: {
        startTime: '',
        endTime: '',
      }
    };
  },
  components: {
    repairStudentsStatistics,
    waitStatistics,
    finishStatistics,
    statisticsTltleHead,
    dialogFactory,
    repairUnassociatedHandle
  },
  created () {
    console.log("router.params", this.$route.params.data)
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'repairCourseManagement') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'repairCourseManagement') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });
  },
  activated () {
    console.log('activatedactivated', this.$route.params)
    this.statisticsTltleList.forEach(o => {
      o.isSelected = false;
    })
    if (this.$route.params.data && this.$route.params.data.dataInfo) {
      this.searchObj.startTime = this.$route.params.data.dataInfo.startTime;
      this.searchObj.endTime = this.$route.params.data.dataInfo.endTime;
      this.statisticsTltleList[this.$route.params.data.dataInfo.showTypeKey - 1].isSelected = true;
    } else {
      this.statisticsTltleList[0].isSelected = true;
    }
  },
  methods: {
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log(dialogInfo, 'repairCourseManagement');
      if (this.$refs.repairCourseManagementFactory) {
        this.$refs.repairCourseManagementFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.operatingDialogFactoryCommon) {
        this.$refs.operatingDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>
<style scoped>
.repair_course_view {
  width: 100%;
  margin: 0 auto;
}
.repair_course_title >>> .table_fixed_nav_box .table_fixed_nav {
  width: 99% !important;
  max-width: 1920px;
  margin: 0 auto;
}
</style>