<template>
  <div class="WKT_discount_package_popup_details">
    <div class="package_popup_details_name">
      {{detailInfo.PackageName}}
    </div>
    <div class="package_popup_ul">
      <div class="package_popup_list"
           v-for="item in detailInfo.xykdList"
           :key="item.TableID">
        <div v-if="detailInfo.xykdList.length>0"
             class="">
          <div class="package_popup_list_text">
            <span class="font_gray">适用：</span>
            <span>{{item.ApplyCorName}}</span>
          </div>
          <div class="package_popup_list_text">
            <span class="font_gray">数量：</span>
            <span v-if="item.UnitKey!=5">{{item.UnitKeyValue}}卡 |</span>
            <span v-if="item.UnitKey==5">{{$utils.mAmountType(item.UnitPrice,2)}}元x{{$utils.mAmountType(item.NumCount,1)}}{{item.UnitKeyValue}}</span>
            <span v-else>{{$utils.mAmountType(item.NumCount,1)}}{{item.UnitKeyValue=='月'?'个月':item.UnitKeyValue}}</span>
          </div>
        </div>
      </div>
      <div class="package_popup_list"
           v-for="item in detailInfo.incidentalsList"
           :key="item.TableID">
        <div class=""
             v-if="detailInfo.incidentalsList.length>0">
          <div class="package_popup_list_text">
            <span class="font_gray">适用：</span>
            <span>{{item.ProjectKeyValue}}</span>
          </div>
          <div class="package_popup_list_text">
            <span class="font_gray">数量：</span>
             <!-- <span v-if="item.UnitKey!=5">{{item.UnitName}}卡 |</span> -->
            <span>{{$utils.mAmountType(item.UnitPrice,2)}}元x{{$utils.mAmountType(item.NumCount,1)}}{{item.UnitName}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="package_popup_bottom">
      <div class="package_popup_bottom_date">
        <div v-if="vShowCreateTime">
          <span class="font_gray">课时有效期</span>
          <span class="font_green">
            <span class="font_bold">{{vShowCreateTime}}</span>{{vUnitKeyValue}}</span>
        </div>
      </div>
      <div class="package_popup_bottom_amount">
        <span class="font_unit">￥</span>{{$utils.mAmountType(detailInfo.SalePrice,2)}}
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'singleSelectionItem',
  data () {
    return {

    };
  },
  props: {
    detailInfo: Object
  },
  created () {

  },
  mounted () {
  },
  // 一进入页面就触发；可用于初始化页面数据等
  activated () {
  },
  watch: {

  },
  computed: {
    // 有效期
    vShowCreateTime () {
      console.log(this.detailInfo, 'this.detailInfo');
      const findItem = this.detailInfo.xykdList.find(o => {
        return o.UnitKey == 5;
      });
      if (findItem) {
        if (this.detailInfo.ExpireTime) {
          // let newStartTime = this.detailInfo.StartTime.replace(/\-/g, '.');
          // let newExpireTime = this.detailInfo.ExpireTime.replace(/\-/g, '.');
          if (this.detailInfo.StartTime && this.detailInfo.StartTime != '1900-01-01') {
            return this.detailInfo.StartTime + ' - ' + this.detailInfo.ExpireTime;
          } else {
            return this.detailInfo.ExpireTime;
          }
        } else {
          return this.$utils.mAmountType(this.detailInfo.PeriodCount, 0);
        }
      } else {
        return false;
      }
    },
      // 单位显示
    vUnitKeyValue () {
      if (this.detailInfo.ExpireTime || !this.detailInfo.UnitKeyValue) {
        return '';
      } else {
        if (this.detailInfo.UnitKeyValue == '月') {
          return '个月';
        } else {
          return this.detailInfo.UnitKeyValue;
        }
      }
    }
  },
  methods: {
  }
};
</script>

