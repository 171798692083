<template>
  <div class="manage_statistics_view">
    <div class="manage_statistics_top massive_bg">
      <header-content></header-content>
      <content-echart></content-echart>
    </div>
    <footer-content></footer-content>
    <div>
      <dialog-factory ref="managementAndStatisticsDialogFactory"
                      :routerName="'managementAndStatistics'"></dialog-factory>
      <dialog-factory-common ref="managementAndStatistics"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import headerContent from './header-content';
import contentEchart from './content-echart';
import footerContent from './footer-content';
import dialogFactory from '../../common/dialog-factory';
export default {
  name: 'managementAndStatistics',
  data () {
    return {

    };
  },
  mounted () {
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'managementAndStatistics') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      console.log('openCommonCustomDialog', dialogInfo);
      if (dialogInfo.routerName == 'managementAndStatistics') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });
  },
  components: {
    headerContent,
    contentEchart,
    footerContent,
    dialogFactory
  },
  methods: {
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.managementAndStatisticsDialogFactory) {
        this.$refs.managementAndStatisticsDialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.managementAndStatistics) {
        this.$refs.managementAndStatistics.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>
