<template>
  <div class="apply_payment_echarts_hous massive_bg echarts_hous_mb">
    <div class="statistics_echarts_left fl">
      <div class="apply_payment_echarts_left_title clearfix">
        <div class="title_font fl">报名缴费日报</div>
        <single-year-picker @chgDate="chgDate"
                            :type="'month'"></single-year-picker>
        <div class="title_month fr">
          <span class="this_month">本月</span>
          <span class="past_month">上个月</span>
        </div>
      </div>
      <div>
        <doubleTypeEchart :echartID="'graph'"
                          :reqList="monthList"
                          :nowDataList="nowList"
                          :preDataList="oldList"
                          :echartType="'line'"
                          ref="echart"></doubleTypeEchart>
      </div>
      <div>
        <a @click.stop="showDetail"
           class="class_statistics_details_btn">
          查看详情
        </a>
      </div>
    </div>
    <div class=" statistics_echarts_right fl ">
      <div class="apply_payment_box_title ">
        {{vFormTitle}}报名统计
        <span>{{vYear}}</span>
      </div>
      <div class="box_dot dot_green ">
        报名金额
      </div>
      <div class="apply_payment_box_number ">
        <span>￥</span>{{nowTotalRecableAmount}}
      </div>
      <div class="apply_payment_box_singular box_dot dot_blue ">
        单数
        <span>{{totalCount}}</span> 单
      </div>
      <div class="box_contrast"
           v-if="nowTotalRecableAmount-lastYearTotalRecableAmount<0">
        <img class="fl "
             src="../../../../public/image/contrast_down.png"
             alt=" ">
        <div class="box_contrast_data fl ">
          <div class="data_number"><span v-if="increaseRate=='--'?false:true">-</span>{{increaseRate}}</div>
          <div>环比下降</div>
        </div>
      </div>
      <div class="box_contrast "
           v-else>
        <img class="fl "
             src="../../../../public/image/contrast_up.png"
             alt=" ">
        <div class="box_contrast_data fl ">
          <div class="data_number">{{increaseRate}}</div>
          <div>环比增长</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import singleYearPicker from '../date-picker-common/single-year-picker';
import doubleTypeEchart from '../echart-common/double-type-echart';
import { getKDSYStatisticsDataByDay } from '../../../API/workbench';
export default {
  name: 'applyDayStatisticsReport',
  data () {
    return {
      seletedDate: Date.new(),    // 绑定日期插件
      startTime: '',     // 开始时间
      endTime: '',        // 结束时间
      monthList: [],
      nowDataList: [],      // 选中月数据源
      oldDataList: [],       // 上个月数据源
      nowList: [],
      oldList: []
    };
  },
  components: {
    singleYearPicker, doubleTypeEchart
  },
  computed: {
    // 选中月的报表总数
    nowTotalRecableAmount () {
      let RecableAmount = 0;
      this.nowDataList.forEach(o => {
        RecableAmount += Number(o.RecableAmount);
      });
      return (Math.round(RecableAmount * 100) / 100).toFixed(2) || 0;
    },
    // 选中月的单数
    totalCount () {
      let count = 0;
      this.nowDataList.forEach(o => {
        count += Number(o.Number);
      });
      return count;
    },
    vYear () {
      return this.seletedDate.getFullYear() + '年' + (this.seletedDate.getMonth() + 1) + '月';
    },
    // 上一个月的报表总数
    lastYearTotalRecableAmount () {
      let RecableAmount = 0;
      this.oldDataList.forEach(o => {
        RecableAmount += Number(o.RecableAmount);
      });
      return (Math.round(RecableAmount * 100) / 100).toFixed(2) || 0;
    },
    // 环比增长率=（本期的某个指标的值-上一期这个指标的值）/上一期这个指标的值*100%。
    increaseRate () {
      if (this.lastYearTotalRecableAmount == 0 && this.nowTotalRecableAmount > 0) {
        return '100%';
      } else if (this.lastYearTotalRecableAmount >= 0 && this.nowTotalRecableAmount == 0) {
        return '---';
      } else {
        return parseInt(Math.abs((this.nowTotalRecableAmount - this.lastYearTotalRecableAmount) / this.lastYearTotalRecableAmount) * 100) + '%';
      }
    },
    // 判断显示本年本月.
    vFormTitle () {
      if (this.seletedDate.getFullYear() == Date.new().getFullYear() && this.seletedDate.getMonth() == Date.new().getMonth()) { return '本月'; }
    }
  },
  mounted () {
    // 初始化时获取当前月份的开始时间及最后一天
    this.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
    this.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd');
    this.monthList = this.getLongDaysOfMonth();
    this.initGetData(this.getLastKDSYDataByDay);
  },
  methods: {
    showDetail () {
      this.$emit('showDetail', '报名缴费日报', this.startTime, this.endTime);
    },
    // 监听时间插件改变值
    chgDate (val, startTime, endTime) {
      this.seletedDate = val;
      this.startTime = startTime;
      this.endTime = endTime;
      this.monthList = this.getLongDaysOfMonth();
      // 选择该月份最后一天.
      console.log(this.startTime, this.endTime);
      this.initGetData(this.getLastKDSYDataByDay);
    },
    // 获取最长的月份.
    getLongDaysOfMonth () {
      let testTime = this.seletedDate || Date.new();
      let nowDayOFMonth = this.getDaysOfMonth(this.seletedDate);
      let preMonthDate = Date.new(testTime.getFullYear(), testTime.getMonth() - 1, 1);
      let preDayOfMonth = this.getDaysOfMonth(preMonthDate);
      let xAxisData;
      if (nowDayOFMonth.length > preDayOfMonth.length) {
        xAxisData = nowDayOFMonth;
      } else {
        xAxisData = preDayOfMonth;
      }
      return xAxisData;
    },
    // 初始化获取数据
    initGetData (callback) {
      this.getNowKDSYDataByDay(this.startTime, this.endTime, callback);
    },
    // 获取选中月数据.
    getNowKDSYDataByDay (startTime, endTime, callback) {
      getKDSYStatisticsDataByDay(startTime, endTime).then(result => {
        this.nowDataList = result.data.DataList || [];
        this.nowList = this.getNowList();
        if (callback) {
          callback();
        }
      }, r => {
        console.log(r);
      });
    },
    // 获取选中月的上个月数据.
    getLastKDSYDataByDay () {
      let year = Date.new(this.startTime).getFullYear();    // 纯年
      let month = Date.new(this.startTime).getMonth() + 1;   // 纯月
      let testTime = Date.new(year, month - 1); // 上个月
      let startTime = this.$utils.formatDateStr(Date.new(testTime.setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      let endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(testTime), 'yyyy-MM-dd');
      getKDSYStatisticsDataByDay(startTime, endTime).then(result => {
        this.oldDataList = result.data.DataList || [];
        this.oldList = this.getOldList();
        this.$nextTick(() => {
          this.$refs.echart.echartInit();
        });
      }, r => {
        console.log(r);
      });
    },
    // 获取一个月多少天.
    getDaysOfMonth (monthDate) {
      monthDate = monthDate || Date.new(Date.new().setDate(1));
      let dayList = [];
      let selectedMonth = Date.new(monthDate.getFullYear(), monthDate.getMonth() + 1, 0);
      let dayMany = selectedMonth.getDate();
      for (let i = 1; i <= dayMany; i++) {
        dayList.push(i);
      }
      return dayList;
    },
    // 获取选中年数据 因为数据呈现为12个月. 所以如果返回数据不符合该月份的话直接push '0'
    getNowList () {
      let nowList = [];
      let monthList = this.getDaysOfMonth(this.seletedDate);
      monthList.forEach(p => {
        let item = this.nowDataList.find(o => {
          return Date.new(o.SetUpTime).getDate() == parseInt(p);
        });
        if (item) {
          nowList.push(Number(item.RecableAmount).toFixed(2));
        } else {
          nowList.push('0.00');
        }
      });
      return nowList;
    },
    // 获取上一年数据   因为数据呈现为12个月. 所以如果返回数据不符合该月份的话直接push '0'
    getOldList (timeDate) {
      let oldList = [];
      timeDate = timeDate || Date.new();
      let preMoth = Date.new(timeDate.getFullYear(), timeDate.getMonth() - 1, 1);
      let monthList = this.getDaysOfMonth(preMoth);
      monthList.forEach(p => {
        let item = this.oldDataList.find(o => {
          return Date.new(o.SetUpTime).getDate() == parseInt(p);
        });
        if (item) {
          oldList.push(Number(item.RecableAmount).toFixed(2));
        } else {
          oldList.push('0.00');
        }
      });
      return oldList;
    }
  }

};
</script>
