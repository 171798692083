<template>
  <div class="bottom_content massive_bg ">
    <div class="premium_recommendation_title"
         style="margin-top:20px">
      <div class="premium_recommendation_title_text">
        <span class="title_text_left">精品</span>
        <span class="title_text_right">推荐</span>
      </div>

    </div>
    <div class="flex_row premium_recommendation_content"
         v-if="vshowDefault">
      <div class="premium_recommendation_item"
           :class="vBrandInfo.isOpenBrand?'':'button'">
        <img src="../../../../public/image/homepage_jp_01.png"
             alt=""
             @click.stop="playVideo">
      </div>
      <div class="premium_recommendation_item ">
        <img src="../../../../public/image/homepage_jp_02.png"
             alt="">
      </div>
      <div class="premium_recommendation_item ">
        <!-- <img src="../../../../public/image/homepage_jp_01.png" alt=""> -->
      </div>
    </div>
    <div class="flex_row premium_recommendation_content"
         v-else>
      <div class="premium_recommendation_item"
           v-for="(item,key) in vAdvertisingConfig"
           :key="key"
           :class="!item.videoUrl?'':'button'">
        <img v-if="item.imgUrl"
             :src="$store.getters.CDNURL+item.imgUrl"
             alt=""
             @click.stop="playBrandVideo(item)">
      </div>

    </div>

    <custom-dialog :title="'课件加密'"
                   width="1000px"
                   :visible.sync="isShowPlayView"
                   :before-close="closePalyViewPopup">
      <playView :dataInfo="vshowItem"
                :isReadonly="false"
                @close="closePalyViewPopup"></playView>
    </custom-dialog>
  </div>
</template>
<script>
import playView from '../../courseware-management/courseware-editor/courseware-view/play-view'
export default {
  data () {
    return {
      dataList: [],
      isShowPlayView: false,
      showItem: {
        AttachViewUrl: '/publicImg/video/courseware_encryption.mp4',
        AttachTypeKey: 2,
        CoverUrl: this.$store.getters.PCWebURL + '/publicImg/image/courseware_encryption.png'
      },
      videoItem: null
    }
  },
  components: {
    playView
  },
  computed: {
    vshowItem () {
      return this.videoItem
    },
    vAdvertisingConfig () {
      console.log("vAdvertisingConfig", this.vBrandInfo.AdvertisingConfig)
      return this.vBrandInfo.AdvertisingConfig
    },
    vAdvertisingConfigHasTrue () {
      return this.vBrandInfo.AdvertisingConfig.findIndex(o => {
        return o.videoUrl || o.imgUrl
      })
    },
    vBrandInfo () {
      let obj = {};
      if (window.brandCustomSettingStr && window.brandCustomSettingStr != '') {
        obj = JSON.parse(window.brandCustomSettingStr);
        obj.isOpenBrand = true;
        obj.LoginBackground = 'url(' + this.$store.getters.CDNURL + obj.LoginBackground + ')';
        obj.LogoUrl = this.$store.getters.CDNURL + obj.LogoUrl;
        obj.QRCode = this.$store.getters.CDNURL + obj.QRCode;
        obj.inputBackground = '#fff';
      } else {
        obj = {
          isOpenBrand: false,
          LoginBackground: '',
          MainBodyColor: '#f85a59',
          MainDemoName: '治学培训机构',
          QRCode: '',
          inputBackground: '#f7f7f7',
          AdvertisingConfig: [],
          HomePageVideo: ''
        };
      }
      return obj;
    },
    vshowDefault () {
      if (!this.vBrandInfo.isOpenBrand) {
        return true
      } else if (this.vBrandInfo.isOpenBrand && this.vAdvertisingConfigHasTrue) {
        return false
      } else {
        return true
      }
    },
  },
  methods: {
    playBrandVideo (item) {
      if (item.videoUrl) {
        this.videoItem = {
          AttachViewUrl: this.$store.getters.CDNURL + item.videoUrl,
          AttachTypeKey: 2,
          CoverUrl: ''
        }
        this.isShowPlayView = true
      }
    },
    playVideo () {
      this.videoItem = this.showItem
      if (!this.vBrandInfo.isOpenBrand) {
        this.isShowPlayView = true
      }
    },
    closePalyViewPopup () {
      this.isShowPlayView = false
    }

  }
}
</script>
<style scoped>
.bottom_content {
  /* height: 74px; */
  padding: 0 20px 10px;
  width: 100%;
  border: 1px solid #f2f2f2;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 40px; */
  /* flex: 1; */

  /* margin-bottom: 40px; */
}
.premium_recommendation_title {
  width: 100px;
  height: 27px;
  border: 1px solid #d80000;
  border-radius: 2px;
  background: #d80000;
  /* text-align: center; */
  display: flex;
}
.premium_recommendation_title_text {
  flex: 1;
  display: flex;
}
.premium_recommendation_content {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.title_text_left {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  font-size: 20;
}
.title_text_right {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 20;
  background: white;
  /* color: white; */
}
.premium_recommendation_item {
  margin-right: 15px;
  /* width: 248px; */
  /* height: 287px; */
  flex: 1;
}
.premium_recommendation_item:hover {
  /* border-radius: 8px;
    border: 1px solid #2D98D9 */
}
.premium_recommendation_item img {
  width: 100%;
  height: 100%;
}
.flex_row {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
</style>