<template>
  <!-- 通用列表 -->
  <div class
       v-if="showStudentList=='atSchool'">
    <!-- <div class> -->
    <div class="table_list_content summarizing_list">
      <div class="single_result_detail">

        <div class="general_summarizing_student student_management">
          <div style="padding:20px 20px 20px 0;">
            <div class="general_course_summarizing_title">
              <div class="summarizing_student_top_box content_top_data_box course_class_summarizing_right">
                <div class="flex">
                  <div class="right_top_box box_right fr"
                       style="margin-bottom:0;width: 81%;">
                    <summary-data-item v-for="(item,key,index) in titleContent"
                                       :sumData="item"
                                       :key="index"
                                       @onCilckEvent="doTitleBoxClick"></summary-data-item>
                  </div>
                  <!-- <div class="table_select_box"
                       style="margin-right:20px;line-height: 0px;">
                    <div class="table_select_title">关注</div>
                    <el-select v-model="defaultAttentionText"
                               placeholder="请选择"
                               ref="resetText"
                               @change="selectAttention">
                      <el-option v-for="item in attention"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value">
                      </el-option>
                    </el-select>
                  </div> -->
                </div>
              </div>
              <input-search-contain-btn v-model="searchText"
                                        style="margin-top:7px;margin-right: 80px;"
                                        :width="'280px'"
                                        :placeholder="'请输入姓名、姓名首字母、手机号'"
                                        @clearSearch="clearExtendSearchParams"
                                        @onSearchEvent="searchStudent"></input-search-contain-btn>
            </div>
            <function-btn :item="{name:'学生课单导出',ModulePowerKey:120}"
                          :className="'right_top_btn btn_hover_bg_blue fr student_course_export_btn'"
                          @clickItem="exportStudentCourse">
            </function-btn>
          </div>
          <table-list ref="tableListRef"
                      :tableData="tableData"
                      :tableColumns="tableColumns"
                      :expandRowData="expandRowData"
                      :rowKey="rowKey"
                      :totalNum="totalNum"
                      :defaultSort="defaultSort"
                      :queryParams="paramsForStudent"
                      @loadTableData="loadTableData"></table-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ExportStudnetXYKD
} from '../../../API/workbench.js';
import summaryDataItem from '../summary-data/item';
import inputSearchContainBtn from '../common/input-search-contain-btn';
import tableList from '../../common/table-list/index';
export default {
  data () {
    return {
      defaultAttentionText: '全部',
      attention: [
        { value: '全部', id: '' },
        { value: '已关注', id: 1 },
        { value: '未关注', id: 0 }
      ],
      searchText: '',
      isShowSummary: false
    };
  },
  props: {
    tableData: Array, // 列表数据
    expandRowData: Array, // 展开行列表数据
    rowKey: [String, Number], // 显示详情对应绑定的ID
    totalNum: Number, // 分页总共条数
    tableColumns: Array, // table column
    titleContent: Array, // 在校学生类型
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: '', order: 'ascending' };
      }
    },
    paramsForStudent: Object, // 查询字段
    showStudentList: String// 默认显示
  },
  components: {
    summaryDataItem,
    inputSearchContainBtn,
    tableList
  },
  computed: {
    modulePowerList () {
      return this.$store.getters.RolePowerList;
    }
  },
  watch: {

  },
  created () {
    this.searchConditionType = this.$initJson.extendSearchConditionType;
  },
  mounted () {

  },
  methods: {
    // 查询 关注
    selectAttention (val) {
      let findItem = this.attention.find(o => {
        return o.value == val;
      });
      this.paramsForStudent.SubscribeKey = findItem ? findItem.id : '';
      this.$emit('searchStudent');
    },
    // 回车搜索
    keydownEvent (e) {
      switch (e.keyCode) {
        case 13: // 回车
          this.searchStudent();
          break;
        default:
          break;
      }
    },
    // 搜索
    searchStudent () {
      this.paramsForStudent.searchText = this.searchText;
      this.$refs.tableListRef.expandRowKeys = [];
      this.$emit('searchStudent', this.paramsForStudent.searchText);
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey, data, name) {
      ModulePowerKey = ModulePowerKey || '';
      if (data && name && name == '离校' && Number(data.CurrentClassHourCount) > 0) { // 不可离校
        return false;
      } else {
        return this.$utils.CheckModulePower(this.modulePowerList, ModulePowerKey);
      }
    },
    // 清除
    clearExtendSearchParams () {
      this.defaultAttentionText = '全部';
      this.searchText = '';
      this.paramsForStudent.searchText = this.searchText;
      this.paramsForStudent.pageIndex = 0;
      this.$refs.tableListRef.expandRowKeys = [];
      this.$emit('clearSearchParams');
    },
    // 翻页
    loadTableData (queryParams) {
      this.$emit('loadTableData', queryParams);
    },
    // 选择在校学生类型
    doTitleBoxClick (item) {
      this.searchText = '';
      this.$emit('doTitleBoxClick', item);
      document.getElementsByClassName('el-table__body-wrapper')[0].scrollTop = 0;
    },
    // 学生课单导出
    exportStudentCourse () {
      ExportStudnetXYKD().then(result => {
        console.log(result.data, '学生课单导出');
        window.location.href = this.$store.getters.APIURL + result.data.url;
      }, error => {
        layer.alert(error.msg);
      });
    }
  }
};
</script>
<style scoped>
.table_select_box .el-select {
  width: 200px !important;
}
</style>

