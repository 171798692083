<template>
  <div class="payment_statistics_bottom ">
    <div class="course_statistics_table_search flex course_statistics_title">
      <div class="flex">
        <span>选择月份</span>
        <div class="title_date_select">
          <div class="el-select">
            <div class="el-input el-input--small el-input--suffix is-focus">
              <single-year-picker @chgDate="changeSaveDate"
                                  :minDate="''"
                                  :maxDate="''"
                                  ref="monthPicker"
                                  :type="'month'"></single-year-picker>
            </div>
          </div>
        </div>
      </div>
   
    </div>
    <div class="course_statistics_table_search flex course_statistics_title" style="flex-direction: column;padding-top:0px;padding-bottom:0px;">
      <div class="statistics_list_right">
        <span class="statistics_item noborder font_gray" >排课出勤: </span>
        <span class="statistics_item font_black no_border_right">人均排课:{{Number(attenInfo.AverAgeCount)}} </span>
        <span class="statistics_item font_black no_border_right">排课人次:{{Number(attenInfo.ArrangeCount)}} </span>
        <span class="statistics_item font_black no_border_right">出勤人次:{{Number(attenInfo.Attence3Count)}} </span>
        <span class="statistics_item font_black">出勤率:{{Number(attenInfo.Attence3Lv)+'%'}} </span>
      </div>
      <div class="statistics_list_right">
        <span class="statistics_item noborder font_gray" >课消结转: </span>
        <span class="statistics_item font_black no_border_right">消耗课时:{{Number(xykdInfo.DeductCount)}} </span>
        <span class="statistics_item font_black no_border_right">课消结转:{{Number(xykdInfo.DeductAmount)}} </span>
        <span class="statistics_item font_black">人均结转:{{Number(xykdInfo.AverAgeCount)}} </span>
      </div>
      <div class="statistics_list_right">
        <span class="statistics_item noborder font_gray" >课单续费: </span>
        <span class="statistics_item font_black no_border_right">已续课单:{{Number(renewInfo.HaveRenewCount)}} </span>
        <span class="statistics_item font_black no_border_right">续费金额:{{Number(renewInfo.HaveRenewAmount)}} </span>
        <span class="statistics_item statistics_item_red ">不续课单:{{Number(renewInfo.NotRenewCount)}} </span>
        <span class="statistics_item font_black no_border_left">续费率:{{Number(renewInfo.RenewLv)+'%'}} </span>
      </div>
   
    </div>
    <div class="course_statistics_table_search flex course_statistics_title" style="padding-top:0px;padding-bottom: 0px;">
       <div class="statistics_list_right">
        <span class="statistics_item noborder font_black" style="font-size:18px" >学管师数据统计表（月）</span>
      </div>
      <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list class="summarizing_list"
                  :key="tableKey"
                  :tableData="vdataReportList"
                  :tableColumns="tableCourseColumns"
                  :isSearchText="false"
                  :isNeedInnerScroll="false"
                  :defaultSort="defaultSort"
                  :queryParams="searchObj"
                  @loadTableData="setsearchObj"></table-list>
    </div>
    <div class="course_statistics_table_search flex course_statistics_title" style="flex-direction: column;padding-top:0px;padding-bottom:0px;">
      <div class="statistics_list_right">
        <span class="statistics_item font_black no_border_right" :class="echartType=='ArrageStudentCount'?'opt':''" style="width:191px" @click="changeEchartType('ArrageStudentCount')">人均排课</span>
        <span class="statistics_item font_black no_border_right"  :class="echartType=='AttenceLv'?'opt':''" style="width:191px" @click="changeEchartType('AttenceLv')">出勤率</span>
        <span class="statistics_item font_black no_border_right"  :class="echartType=='DeductXykdAmount'?'opt':''" style="width:191px" @click="changeEchartType('DeductXykdAmount')">课消结转</span>
        <span class="statistics_item font_black no_border_right"  :class="echartType=='AverAgeXykdCount'?'opt':''" style="width:191px" @click="changeEchartType('AverAgeXykdCount')">人均结转</span>
        <span class="statistics_item font_black no_border_right"  :class="echartType=='HaveRenewAmount'?'opt':''" style="width:191px" @click="changeEchartType('HaveRenewAmount')">续费金额</span>
        <span class="statistics_item font_black " :class="echartType=='RenewLv'?'opt':''" style="width:191px" @click="changeEchartType('RenewLv')">续费率</span>
      </div>
    </div>
    <div class="course_statistics_echarts_box">
      <new-type-echart :echartID="'dual_histogram3'"
                       :reqList="reqList"
                       :nowDataList="nowList"
                       :preDataList="oldList"
                       :monthTitle="''"
                       :secondTitle="''"
                       :echartType="'Bar'"
                       :barWidth="28.33"
                       ref="echart"></new-type-echart>
    </div>
  </div>
</template>
<script>
// import dateOption from '../../class-spend-statistics-analyze/components/date-option';

// import list from '../../class-spend-statistics-analyze/components/table';
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import singleYearPicker from '../../../base-module/date-picker-common/single-year-picker';
import newTypeEchart from '../../../base-module/echart-common/new-type-echart';
import { getMonthActiveStudentCount,GetScheduleAttendanceCount,GetCourseXykdCount,getCourseRenewCount} from '../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 0,
        searchText: '',
        orderBy: 'MainDemoName',
        sequence: 'desc',
        startTime:this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0)),
      },
      attenInfo:{
        ArrangeCount:0,//	整型	排课人数
        Attence3Count:0,//	整型	出勤人数
        Attence3Lv:0,//	整型	出勤率
        AverAgeCount:0,//	整型	人均出勤
      },
      xykdInfo:{
        AverAgeCount:0,//	整型	人均结转
        DeductAmount:0,//	整型	课消结转
        DeductCount:0,//	整型	课时数
        
      },
      renewInfo:{
        HaveRenewCount:0,//	整型	已续客单
        HaveRenewAmount:0,//	整型	客单金额
        NotRenewCount:0,//	整型	不续客单
        RenewLv:0,//	整型	续费率
      },
      
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        // {
        //   label: '序号',
        //   prop: 'Number',
        //   width: 55,
        //   sortable: false,
        //   align: 'left',
        //   type: 'tableIndex',
        //   className: 'table_index_gray'
        // },
        {
          label: '学管师',
          prop: 'MainDemoName',
          width: 110,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '排课人次',
          prop: 'ArrageStudentCount',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,

          type: 'number-item'
        },
        {
          label: '出勤人次',
          prop: 'Attence3Count',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'number-item'
        },
      
        {
          label: '出勤率',
          prop: 'AttenceLvText',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
      
        },
        {
          label: '消耗课时',
          prop: 'DeductXykdCount',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'amount-item',
           decimalPlace: 1
        },
        {
          label: '课消结转',
          prop: 'DeductXykdAmount',
          sortable: 'custom',
          type: 'amount-item',
          minWidth: 100,
          decimalPlace: 2
        },
        {
          label: '人均结转',
          prop: 'AverAgeXykdCount',
          sortable: 'custom',
          type: 'amount-item',
          minWidth: 100,
             decimalPlace: 2
        },
        {
          label: '已续课单',
          prop: 'HaveRenewCount',
          sortable: 'custom',
          type: 'number-item',
          minWidth: 100,
        },
        {
          label: '续费金额',
          prop: 'HaveRenewAmount',
          sortable: 'custom',
          type: 'amount-item',
          minWidth: 100,
          decimalPlace: 2
        },
        {
          label: '不续课单',
          prop: 'NotRenewCount',
          sortable: 'custom',
          type: 'number-item',
          minWidth: 100,
        },
        {
          label: '续费率',
          prop: 'RenewLvText',
          sortable: 'custom',
          type: 'text-item',
          minWidth: 100,
        },      
      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false,
      echartType:'ArrageStudentCount',
      nowList: [],
      oldList: [],
      reqList:[]
    };
  },
  components: {
    tableList,
    singleYearPicker,
    inputSearchContainBtn,
    newTypeEchart
  },
  props: {
    

  },

  created () {
  },
  computed: {
  
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o,index) => {
        // let info = o.ClassTime.substring(0, 10) + ',' + o.ClassTime.substring(11);
        // o.classTimeObj = info.split(',');
        // o.ClassTime = o.ClassTime.substring(5);
        o.AttenceLvText=o.AttenceLv?Number(o.AttenceLv)+'%':'-';
        o.RenewLvText=o.RenewLv?Number(o.RenewLv)+'%':'-';
        o.DeductXykdAmount=Number(o.DeductXykdAmount || 0);
        o.AverAgeXykdCount=Number(o.AverAgeXykdCount || 0);
        o.HaveRenewAmount=Number(o.HaveRenewAmount || 0);
        o.ClassAwayAmountCount = Number(o.ClassAwayAmountCount || 0);
        o.ClassHourDebtCount = Number(o.ClassHourDebtCount) || 0;
        // o.AttenceTime = o.AttenceTime.substring(5, 16);
        // this.$set(o, 'OLAPKey', o.CourseNameKey);
        newArr.push(o);
      });
      let orderBy=this.searchObj.orderBy
      if(orderBy&&orderBy!='MainDemoName'){
        if (this.searchObj.sequence == 'DESC' || this.searchObj.sequence == 'desc') {
          newArr.sort((x, y) => {
            return y[orderBy] - x[orderBy];
          });
        } else {
          newArr.sort((x, y) => {
            return x[orderBy] - y[orderBy];
          });
        }
 
      }
      newArr.forEach((o,index)=>{
         o.Number=Number(index+1)
      })
      return newArr;
    },
  },
  mounted () {
      this.getDataList();
      this.getDataInfo()
   
  },
  watch:{
    
    
  },
  methods: {
     // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn =  getMonthActiveStudentCount;
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '数据汇总统计',
          clist: [
            { title: '学管师', cName: 'MainDemoName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '排课人次', cName: 'ArrageStudentCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '出勤人次', cName: 'Attence3Count', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '出勤率', cName: 'AttenceLv', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '消耗课时', cName: 'DeductXykdCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课消结转', cName: 'DeductXykdAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '人均结转', cName: 'AverAgeXykdCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '已续课单', cName: 'HaveRenewCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '续费金额', cName: 'HaveRenewAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '不续课单', cName: 'NotRenewCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '续费率', cName: 'RenewLv', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            
         
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(searchObj,jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    changeEchartType(type){
      this.echartType=type
      this.setChartList()
      this.$nextTick(() => {
        this.$refs.echart.echartInit();
      });
    },
    setChartList(){
      this.reqList=[];
      this.nowList=[];
      this.vdataReportList.forEach(o=>{
        this.reqList.push(o.MainDemoName)
        this.nowList.push(Number(o[this.echartType]))
      })
      console.log(this.reqList,'setChartList reqList')
      console.log(this.nowList,'setChartList nowList')

    },
    getDataInfo(){
      GetScheduleAttendanceCount(this.searchObj).then(result => {
        this.attenInfo = result.data;
        console.log(result.data, 'GetScheduleAttendanceCount');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
      GetCourseXykdCount(this.searchObj).then(result => {
        this.xykdInfo = result.data;
        console.log(result.data, 'GetCourseXykdCount');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
      getCourseRenewCount(this.searchObj).then(result => {
        this.renewInfo = result.data;
        console.log(result.data, 'getCourseRenewCount');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
     changeSaveDate (val, startTime, endTime) {
      this.searchObj.startTime = startTime.replace(/\//g, '-');
      this.searchObj.endTime = endTime.replace(/\//g, '-');
      this.search()
    },
    search () {
      // this.searchObj.TeacherKey = 0;
      this.searchObj.pageIndex = 0;
      this.getDataList();
      this.getDataInfo()
    },
    setsearchObj(queryParams){
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj,'setsearchObj')
    },
    getDataList (queryParams) {
      let fn = '';
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      getMonthActiveStudentCount(this.searchObj).then(result => {
        this.dataReportList = result.data;
        this.searchObj.pageSize=result.data.length
        this.changeEchartType(this.echartType)
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      
    },

  }
};
</script>
<style scoped>
.mendian_backstage_search >>> .search_box {
  width: 240px !important;
  margin-right: 30px !important;
  min-width: 240px !important;
}
.course_statistics_search{
  width: auto !important;;
}
.change_history_data {
  position: absolute;
  right: 17px;
  top: 90px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
.payment_statistics_bottom .list_title{

        width: 100%;
        height: 87px;
        margin: auto;
        padding-left: 20px;
        line-height: 87px

}
.statistics_list_right .no_border_right{
  border-right: none !important;
}
.statistics_list_right .no_border_left{
  border-left: none !important;
}
.statistics_list_right .statistics_item:first-child{
  padding:0px !important;
}
.statistics_list_right .statistics_item{
  padding:0px 5px;
}
.statistics_list{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.statistics_list .statistics_item_yellow{
    height: 30px;
    padding: 5px;
    font-size: 14px;
    color: #FF9800;
    border: 1.25px solid #FF9800;
}

.statistics_list .statistics_item_red{
    height: 30px;
    /* padding: 5px; */
    font-size: 14px;
    color: #F75040 ;
    border: 1.25px solid #F75040
}
.statistics_list .border_left{
  border-top-left-radius: 3px;
  border-bottom-left-radius:3px;
  border-right: none;
}
.statistics_list .border_right{
    border-top-right-radius: 3px;
  border-bottom-right-radius:3px;
  border-left: none;
  
}

.statistics_list_right{
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 55px;
    align-items: center;
}
.statistics_list_right .statistics_item_yellow{
    height: 30px;
    padding: 5px;
    font-size: 14px;
    color: #FF9800;
    border: 1.25px solid #FF9800;
    border-radius: 4px;
    margin-right: 10px;
}
.statistics_list_right .statistics_item{
    min-width: 103px;
    height: 30px;
    line-height: 30px;
    /* border-radius: 4px; */
    border: 1.25px solid #ECECEC;
    /* color: #333; */
    font-size: 14px;
    text-align: center;
     min-width: 103px;
}
.statistics_list_right .statistics_item.opt{
  color: #3498DB !important;
  background-color: rgba(52,152,219,0.2);

}
.statistics_item.noborder{
 border: none;
 text-align: left;
 margin-right:0px;
}
.statistics_item.bg_blue{
    background-color: rgba(52,152,219,0.2);
    color: #3498DB;
}
.statistics_list_right .statistics_item_red{
    height: 30px;
    /* padding: 5px; */
    font-size: 14px;
    color: #F75040;
    border: 1.25px solid #F75040;
     /* border-radius: 4px; */
     /* margin-right: 10px; */
     min-width: 103px;
}
.statistics_list_right .border_left{
  border-top-left-radius: 3px;
  border-bottom-left-radius:3px;
  border-right: none;
}
.statistics_list_right .border_right{
    border-top-right-radius: 3px;
  border-bottom-right-radius:3px;
  border-left: none;
  
}
</style>