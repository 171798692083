<template>
  <div class="modulebox clearfix">
    <div class="chartbox">
      <div class="tit">排课饱和率<span @click="setupAll">设置</span></div>
      <echart-circle :echartID="'echart1'"
                     ref="echart1"
                     :dataList="echartList"></echart-circle>
      <div class="score">
        <div>排课饱和率</div>
        <p>{{echartList[0].value}}%</p>
      </div>
    </div>
    <div class="listbox">
      <div class="roomlist clearfix">
        <div v-for="i in roomList"
             :key="i.OLAPKey"
             class="block"
             :class="i.OLAPKey==roomNow.OLAPKey ? 'cur' : ''"
             @click="roomClick(i)">
          <div>{{i.MainDemoName}}</div>
          <p v-if="i.OLAPKey">{{i.Saturation}}%</p>
          <p v-else>--%</p>
        </div>
      </div>
      <div class="wod">周排课次　<span>{{roomNow.WeekCourseCount||0}}/{{roomNow.ArrangeCourseNum||0}}</span>　|　排课人次　<span>{{roomNow.WeekCourseStudentCount||0}}/{{roomNow.ArrangeCourseStudentNum||0}}</span><a @click="setupRoom(roomNow.OLAPKey)">设置</a></div>
      <div class="table clearfix">
        <div class="daylist">
          <div v-for="i in dayList"
               :key="i.WeekKey"
               class="block"
               :class="i.WeekKey==dayNow.WeekKey ? 'cur' : ''"
               @click="dayClick(i)">{{i.WeekKeyValue}} ( {{i.ScheduleCourseCount}} | {{i.CourseStudentCount}} )</div>
        </div>
        <div class="classlist">
          <div v-for="i in classList"
               :key="i.OLAPKey"
               class="block clearfix">
            <div class="td1">{{i.ClassTimeName}}</div>
            <div class="td2">{{i.GradeClassKeyValue}}</div>
            <div class="td3">{{i.MTeacherKeyValue}}</div>
            <div class="td4">{{i.ArrangeStudentCount}}人</div>
          </div>
        </div>
      </div>
      <div class="tip"><span>*</span>信息采集自下周数据，仅供参考。</div>
    </div>
  </div>
</template>

<script>
import { GetSchoolRoomCourseData, GetSchoolRoomCourseWeekDate, GetSchoolRoomWeekScheduleCourseList } from '../../../API/workbench';
import echartCircle from './echart-circle';
export default {
  data () {
    return {
      echartList: [
        {
          value: 0,
          itemStyle: { color: '#519CF7' }
        },
        {
          value: 100,
          itemStyle: { color: '#B4D5FF' }
        }
      ],
      roomList: [],
      IsSchoolRoomkey: 0, // 是否未分配教室 1-是 0-否
      roomNow: {
        ArrangeCourseNum: '', // 设置安排课
        ArrangeCourseStudentNum: '', // 设置总安排学生数
        CourseStudentNum: '',
        MainDemoName: '', // 班级名称
        OLAPKey: '', // 班级key
        Saturation: 0, // 班级饱和率
        WeekCourseCount: 0, // 下周安排课次
        WeekCourseStudentCount: 0 // 下周安排学生数
      },
      dayList: [],
      dayNow: {
        WeekKey: 0, // 周几Key
        WeekKeyValue: '', // 周几名称
        ScheduleCourseCount: 0, // 安排课次
        CourseStudentCount: 0 // 安排课学生数
      },
      classList: []
    };
  },
  components: {
    echartCircle
  },
  created () {
  },
  mounted () {
    this.getData();
  },
  methods: {
    draw () {
      this.$refs.echart1.echartInit();
    },
    roomClick (room) {
      let that = this;
      that.roomNow.ArrangeCourseNum = room.OLAPKey ? room.ArrangeCourseNum : '-';
      that.roomNow.ArrangeCourseStudentNum = room.OLAPKey ? room.ArrangeCourseStudentNum : '-';
      that.roomNow.CourseStudentNum = room.CourseStudentNum;
      that.roomNow.MainDemoName = room.MainDemoName;
      that.roomNow.OLAPKey = room.OLAPKey;
      that.roomNow.Saturation = room.Saturation;
      that.roomNow.WeekCourseCount = room.OLAPKey ? room.WeekCourseCount : '-';
      that.roomNow.WeekCourseStudentCount = room.OLAPKey ? room.WeekCourseStudentCount : '-';
      that.getDayList(that.roomNow.OLAPKey);
    },
    dayClick (day) {
      let that = this;
      that.dayNow.WeekKey = day.WeekKey;
      that.dayNow.WeekKeyValue = day.WeekKeyValue;
      that.dayNow.ScheduleCourseCount = day.ScheduleCourseCount;
      that.dayNow.CourseStudentCount = day.CourseStudentCount;
      that.getClassList(that.roomNow.OLAPKey, that.dayNow.WeekKey);
    },
    getData () {
      let that = this;
      GetSchoolRoomCourseData().then(result => {
        if (result.code == 0) {
          that.echartList[0].value = parseInt(result.data.Saturation);
          that.echartList[1].value = 100 - parseInt(result.data.Saturation);
          that.$nextTick(() => {
            that.draw();
          });
          that.roomList = [];
          that.roomNow.ArrangeCourseNum = '';
          that.roomNow.ArrangeCourseStudentNum = '';
          that.roomNow.CourseStudentNum = '';
          that.roomNow.MainDemoName = '';
          that.roomNow.OLAPKey = '';
          that.roomNow.Saturation = '';
          that.roomNow.WeekCourseCount = '';
          that.roomNow.WeekCourseStudentCount = '';
          that.dayList = [];
          that.dayNow.WeekKey = 0;
          that.dayNow.WeekKeyValue = '';
          that.dayNow.ScheduleCourseCount = 0;
          that.dayNow.CourseStudentCount = 0;
          that.classList = [];
          that.roomList = result.data.SchoolRoomList;
          if (that.roomList.length) {
            that.roomList.push({
              MainDemoName: '未分配教室',
              OLAPKey: 0
            });
            that.roomNow.ArrangeCourseNum = that.roomList[0].ArrangeCourseNum;
            that.roomNow.ArrangeCourseStudentNum = that.roomList[0].ArrangeCourseStudentNum;
            that.roomNow.CourseStudentNum = that.roomList[0].CourseStudentNum;
            that.roomNow.MainDemoName = that.roomList[0].MainDemoName;
            that.roomNow.OLAPKey = that.roomList[0].OLAPKey;
            that.roomNow.Saturation = that.roomList[0].Saturation;
            that.roomNow.WeekCourseCount = that.roomList[0].WeekCourseCount;
            that.roomNow.WeekCourseStudentCount = that.roomList[0].WeekCourseStudentCount;
            that.getDayList(that.roomNow.OLAPKey);
          }
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    getDayList (key) {
      let that = this;
      that.IsSchoolRoomkey = key ? 0 : 1;
      GetSchoolRoomCourseWeekDate(key, that.IsSchoolRoomkey).then(result => {
        if (result.code == 0) {
          that.dayList = [];
          that.dayNow.WeekKey = 0;
          that.dayNow.WeekKeyValue = '';
          that.dayNow.ScheduleCourseCount = 0;
          that.dayNow.CourseStudentCount = 0;
          that.classList = [];
          that.dayList = result.data;
          if (that.dayList.length) {
            that.dayNow.WeekKey = that.dayList[0].WeekKey;
            that.dayNow.WeekKeyValue = that.dayList[0].WeekKeyValue;
            that.dayNow.ScheduleCourseCount = that.dayList[0].ScheduleCourseCount;
            that.dayNow.CourseStudentCount = that.dayList[0].CourseStudentCount;
            that.getClassList(that.roomNow.OLAPKey, that.dayNow.WeekKey);
          }
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    getClassList (roomKey, dayKey) {
      let that = this;
      that.IsSchoolRoomkey = that.roomNow.OLAPKey ? 0 : 1;
      GetSchoolRoomWeekScheduleCourseList(roomKey, dayKey, 0, 0, that.IsSchoolRoomkey).then(result => {
        if (result.code == 0) {
          that.classList = [];
          that.classList = result.data.PageDataList;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    setupAll () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '设置校区排课饱和课次',
        moduleName: 'saturationCourseSetting',
        data: {},
        callBack: {
          afterSuccess: () => {
            this.getData();
          }
        }
      });
    },
    setupRoom (key) {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '设置校区排课饱和课次',
        moduleName: 'appointCourseSetting',
        data: { OLAPKey: key },
        callBack: {
          afterSuccess: () => {
            this.getData();
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.modulebox .chartbox .score {
  position: absolute;
  width: 100px;
  line-height: 30px;
  text-align: center;
  white-space: nowrap;
  left: 50%;
  margin-left: -50px;
  top: 170px;
}
.modulebox .chartbox .score div {
  font-size: 14px;
  color: #666666;
  margin-bottom: 10px;
}
.modulebox .chartbox .score p {
  font-size: 34px;
  font-weight: bold;
  color: #333333;
}
.modulebox .listbox .wod {
  position: relative;
  width: 800px;
  line-height: 20px;
  font-size: 14px;
  color: #999999;
}
.modulebox .listbox .wod span {
  color: #333333;
}
.modulebox .listbox .wod a {
  position: absolute;
  color: #3498db;
  cursor: pointer;
  right: 0;
  top: 0;
}
.modulebox .listbox .table {
  width: 800px;
  border: 1px solid #ececec;
  border-radius: 8px;
  overflow: hidden;
  margin: 10px 0;
}
.modulebox .listbox .table .daylist {
  float: left;
  width: 158px;
  background-color: #fafafa;
  box-sizing: border-box;
  padding: 10px;
}
.modulebox .listbox .table .daylist .block {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #666666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 10px;
  margin-bottom: 5px;
}
.modulebox .listbox .table .daylist .block.cur {
  border: 1px solid #3498db;
}
.modulebox .listbox .table .classlist {
  float: left;
  width: 640px;
  max-height: 265px;
  box-sizing: border-box;
  padding: 10px 0px 10px 20px;
  overflow: auto;
}
.modulebox .listbox .table .classlist .block {
  margin-bottom: 5px;
}
.modulebox .listbox .table .classlist .block div {
  float: left;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modulebox .listbox .table .classlist .block div.td1 {
  width: 150px;
}
.modulebox .listbox .table .classlist .block div.td2 {
  width: 250px;
}
.modulebox .listbox .table .classlist .block div.td3 {
  width: 100px;
}
.modulebox .listbox .table .classlist .block div.td4 {
  width: 100px;
}
.modulebox .listbox .tip {
  width: 800px;
  line-height: 20px;
  font-size: 12px;
  color: #999999;
}
.modulebox .listbox .tip span {
  color: #ff6c67;
  margin-right: 10px;
}
</style>