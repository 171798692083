<template>
  <div :id="echartID"
       :class="[vClass]"></div>
</template>
<script>
export default {
  name: 'circleEchart',
  data () {
    return {
      chartItem: {
        resize: ''
      },
      echart: null
    };
  },
  props: {
    echartID: String,
    nowDataList: Array,      // 当前
    echartClass: String,
    tipstitle: String

  },
  computed: {
    vClass () {
      if (this.echartClass) {
        return this.echartClass;
      } else {
        return 'echarts_m';
      }
    }
  },
  mounted () {
  },
  methods: {
    echartInit () {
      this.initEchartCircle();
    },
    lineHide (opt) {
      opt.data.forEach(item => {
        console.log(item.value, 'lineHide');
        if (item.value == 0) {
          item.itemStyle.normal.labelLine.show = false;
          item.itemStyle.normal.label.show = false;
        }
      });
    },

    // 柱形图初始.
    initEchartCircle () {
      this.echart = echarts.init(document.getElementById(this.echartID));
      window.chartList.push(this.echart);
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b}<br/>' + this.tipstitle + ' {c}元',
          backgroundColor: '#fff',
          padding: [5, 5, 5, 12],
          textStyle: {
            color: '#333'
          },
          extraCssText: 'width:200px;box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06),-8px 0px 26px 2px rgba(0, 0, 0, 0.06),-6px 0px 16px 0px rgba(0, 0, 0, 0.06);'

          // '{b}<br/>' + this.tipstitle + ' {c} ({d}%)'
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          selectedMode: false,
          itemHeight: 8,  // legend图形大小
          icon: 'circle',   //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
          left: 260,
          top: 'middle',
          textStyle: {
            lineHeight: 28
          }
          // data: this.reqList
        },
        series: [
          {
            name: '',     // 设置
            type: 'pie',
            radius: ['62%', '100%'],
            center: ['117px', '50%'],
            hoverAnimation: false,
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: 'center',
              normal: {
                formatter: '{d}%',
                position: 'inside'
              }
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: '22',
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: false
            },
            data: this.nowDataList
          }
        ]
      };
      // var opt = option.series[0];
      // this.lineHide(opt);
      // 使用刚指定的配置项和数据显示图表。
      this.echart.setOption(option);
    }

  }
};
</script>

