<template>
  <!-- 授权课件 -->
  <div class="authorization_courseware">
    <headSummarizing :summarizingBoxList="summarizingBoxList"
                     :summarizingSingleList="summarizingSingleList"
                     @doAfterCilckSummaryData="doAfterCilckSummaryData"></headSummarizing>
    <list ref="campusManagement"
          class="summarizing_list"
          :tableData="authorizationCoursewareList"
          :tableColumns="tableColumns"
          :expandRowData="expandRowData"
          :totalNum="totalNum"
          :rowKey="'Number'"
          :params="params"
          :defaultSort="defaultSort"
          @addAuthorizationCourseware="addAuthorizationCourseware"
          @loadTableData="loadAfterTableData"></list>

    <custom-dialog :title="titleName"
                   :visible.sync="isShowCoursewareFormPopup"
                   :before-close="closeCoursewareFormPopup">
      <authorization-courseware-form :coursewareId="Number(coursewareIndo.OLAPKey)"
                                     :isReadonly="false"
                                     @close="closeCoursewareFormPopup"
                                     @afterSuccess="getSearchPageList"></authorization-courseware-form>
    </custom-dialog>

    <custom-dialog :title="'修改授权设备数量'"
                   :visible.sync="isShowAuthorizationAeviceFormPopup"
                   :before-close="closeCoursewareFormPopup">
      <edit-authorization-device-form :coursewareId="Number(coursewareIndo.OLAPKey)"
                                      :deviceNum="Number(coursewareIndo.AuthorizeDeviceNum)"
                                      @close="closeCoursewareFormPopup"
                                      @afterSuccess="getSearchPageList"></edit-authorization-device-form>
    </custom-dialog>

    <div>
      <dialog-factory ref="distributeCoursewareTableDialogFactory"
                      :routerName="'distributeCoursewareTable'"></dialog-factory>
    </div>

  </div>
</template>

<script>
import { SearchPageList, AuthorizationCoursewareDisable, AuthorizationCoursewareEnable, SearchPageLogList } from '../../../../API/workbench.js';
import headSummarizing from './head-summarizing';
import authorizationCoursewareForm from './form/authorization-courseware-form';
import editAuthorizationDeviceForm from './form/edit-authorization-device-form';
import list from './table';
import dialogFactory from '../../../common/dialog-factory';
export default {
  name: 'authorizationCourseware',
  components: {
    headSummarizing,
    list,
    authorizationCoursewareForm,
    editAuthorizationDeviceForm,
    dialogFactory
  },
  data () {
    return {
      isShowCoursewareFormPopup: false,
      isShowAuthorizationAeviceFormPopup: false,
      // 授权课件管理
      params: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: '', //	字符串	可选		数据源：GET	排序顺序
        type: '' // 0：全部 1：警戒 2：预警  3:用完
      },
      authorizationCoursewareList: [], // 授权课件列表
      summarizingBoxList: [
        { title: '在用校区', value: 0, iconClass: 'banner_01', decimalPlace: 0, isFilter: false },
        { title: '在用课包', value: 0, iconClass: 'banner_02', decimalPlace: 0, isFilter: false }
      ],
      summarizingSingleList: [
        { title: '排课警戒', type: 1, value: '-', colorClass: 'font_yellow', decimalPlace: 0, isFilter: true },
        { title: '续费预警', type: 2, value: '-', colorClass: 'font_yellow', decimalPlace: 0, isFilter: true },
        { title: '已用完', type: 3, value: '-', colorClass: 'font_gray', decimalPlace: 0, isFilter: true }
      ],
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '授权日期',
          width: 100,
          prop: 'AuthorizeTime',
          type: 'date-item',
          sortable: 'custom'
        },
        {
          label: '校区名称',
          width: 150,
          prop: 'SchoolKeyValue',
          type: 'md-name',
          extend: {
            mendianID: 'Support20'
          }
        },
        {
          label: '经办人',
          prop: 'OperatorKeyValue',
          width: 80,
          align: 'left',
          type: 'text-item'
        },
        {
          label: '课件包名称',
          width: 140,
          prop: 'ParentKeyValue',
          type: 'md-name',
          extend: {
            mendianID: 'CoursewarePackageKeyValue'
          }
        },
        {
          label: '结算方式',
          prop: 'ClearingFormKeyValue',
          type: 'text-item',
          width: 115,
          sortable: 'custom'
        },
        {
          label: '授权设备',
          prop: 'AuthorizeDeviceNum',
          width: 90,
          type: 'number-item',
          sortable: 'custom',
          extend: {
            className: 'font_blue font_border_blue',
            type: 'expand',
            click: (rowData) => { this.editAuthorizationEquipment(rowData); }
          }
        },
        {
          label: '发放',
          width: 80,
          prop: 'ReleasedNum',
          type: 'class-hour',
          columnClass: 'font_dual',
          sortable: 'custom',
          extend: {
            type: 'expand',
            click: () => { },
            isLoading: true, // 正在加载中
            tableWidth: '1050px',
            left: '100px',
            loadtableData: (rowData, callBack) => { // 获取详情数据
              this.getAuthorizeCoursewareCountDetial(rowData, callBack);
            },
            tableColumnsDetail: [
              {
                label: '课件授权日期',
                prop: 'AuthorizeTime',
                type: 'date-item',
                width: 110,
                extend: {
                  dateType: 'YYMMRR HH:DD' // 日期类型
                }
              },
              {
                label: '经办人',
                prop: 'OperatorKeyValue',
                type: 'text-item'
              },
              {
                label: '课件包名称',
                width: 200,
                prop: 'CoursewarePackageKeyValue',
                type: 'text-item'
                // extend: { //md-name
                //   mendianID: 'CoursewarePackageKeyValue'
                // }
              },
              {
                label: '结算方式',
                prop: 'ClearingFormKeyValue',
                type: 'text-item'
              },
              {
                label: '发放',
                prop: 'ReleasedNum',
                type: 'number-item'
              },
              {
                label: '加盟顾问',
                prop: 'CounselorKeyValue',
                type: 'text-item'
              },
              {
                label: '收费金额',
                prop: 'FeeAmount',
                type: 'number-item'
              },
              {
                label: '状态',
                prop: 'StatusKeyValue',
                align: 'left',
                type: 'text-item',
                extend: {
                  setClassName: (rowData) => { // 状态 1:已使用完 0：未使用完
                    if (rowData.StatusKey == 0) {
                      return 'status_spot_green';
                    } else if (rowData.StatusKey == 1) {
                      return 'status_spot_gray';
                    } else {
                      return '';
                    }
                  }
                }
              }
            ]
          }
        },
        {
          label: '使用',
          prop: 'UsageNum',
          width: 60,
          type: 'text-item',
          sortable: 'custom',
          extend: {
            setClassName: (rowData) => {
              return rowData.UsageNum == 0 ? 'font_red' : '';
            }
          }
        },
        {
          label: '剩余',
          prop: 'residue',
          width: 60,
          type: 'text-item',
          sortable: 'custom',
          extend: {
            setClassName: (rowData) => {
              return rowData.residue == 0 ? 'font_red' : '';
            }
          }
        },
        {
          label: '排课班次',
          prop: 'ArrangeGradeClassNum',
          width: 90,
          type: 'class-hour',
          sortable: false
        },
        {
          label: '排课课次',
          prop: 'ArrangeNum',
          width: 90,
          type: 'class-hour',
          sortable: false
        },
        {
          label: '排课警戒',
          prop: 'ArrangedWarningValue',
          width: 90,
          type: 'text-item',
          sortable: false
        },
        {
          label: '状态',
          prop: 'StatusKeyValue',
          width: 80,
          align: 'left',
          type: 'text-item',
          sortable: false,
          extend: {
            setClassName: (rowData) => { // 1：启用 0：停用 2：已用完 3：已过期
              if (rowData.StatusKey == 0) {
                return 'status_spot_red';
              } else if (rowData.StatusKey == 1) {
                return 'status_spot_green';
              } else if (rowData.StatusKey > 1) {
                return 'status_spot_gray';
              }
            }
          }
        },
        {
          label: '定位授权',
          prop: 'IsLocationLockKeyValue',
          width: 90,
          type: 'text-item',
          sortable: false
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          width: 142,
          extend: {
            tableBtnGroup: [
              {
                name: (rowData) => {
                  return rowData.StatusKey == 0 ? '恢复授权' : '暂停授权';
                },
                extend: {
                  isHide: (rowData) => {
                    return rowData.SysStatusKey == 1;
                  },
                  IsInquiryHint: 'inquiryBtn',
                  setClassName: (rowData) => {
                    if (rowData.inquiryBtn) { // 开启询问提示，显示询问弹窗时，界面按钮置灰，不可操作
                      return rowData.inquiryBtn;
                    }
                  },
                  click: (rowData, callBack) => {
                    if (callBack) {
                      callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: 'table_btn_gray' });
                    }
                    this.switchClick(rowData, callBack);
                  },
                  ModulePowerKey: ''
                }
              },
              {
                name: '续费',
                extend: {
                  isHide: (rowData) => {
                    return rowData.StatusKey == 0 || rowData.ClearingFormKey == 3;
                  },
                  click: (rowData) => { this.renewClick(rowData); },
                  ModulePowerKey: ''
                }
              }
            ]
          }
        }
      ],
      PageCount: 0,
      totalNum: 0,
      expandRowData: [],
      defaultSort: { prop: 'studentInfo', order: 'ascending' },
      coursewareIndo: {},
      titleName: '授权课件'
    };
  },
  created () {
    this.registerBusEvent();
  },
  activated () {
    this.getSearchPageList();
  },
  methods: {
    // 汇总数据
    initSummaryData (data) {
      console.log(data, '汇总数据');
      this.summarizingBoxList[0].value = data.SchoolCount || 0;// 在用校区
      this.summarizingBoxList[1].value = data.CoursewarePackageCount || 0;// 在用课包

      this.summarizingSingleList[0].value = data.ArrangWarningCount;// 排课警戒
      this.summarizingSingleList[1].value = data.ArrangeAlertCount;// 续费预警
      this.summarizingSingleList[2].value = data.ExpireCount;// 已过期
    },
    //  授权课件列表-分页查询
    getSearchPageList () {
      SearchPageList(this.params).then(result => {
        this.authorizationCoursewareList = [];
        this.initSummaryData(result.data);
        this.totalNum = result.data.Total;
        result.data.PageDataList.forEach(o => {
          o.ArrangedWarningValue = '';
          if (Number(o.ArrangedAlert) == 1) {
            o.ArrangedWarningValue = `<div class="arranged_alert">警戒</div>`;
          } else if (Number(o.ArrangedWarning) == 1) {
            o.ArrangedWarningValue = `<div class="arranged_warning">预警</div>`;
          }
          if (Number(o.StatusKey) == 1) {
            o.StatusKeyValue = '使用中';
          } else if (Number(o.StatusKey) == 0) {
            o.StatusKeyValue = '已暂停';
          } else if (Number(o.StatusKey) == 2) {
            o.StatusKeyValue = '已用完';
          } else if (Number(o.StatusKey) == 3) {
            o.StatusKeyValue = '已过期';
          }
          if (Number(o.ParentKey) == 0) {
            o.ParentKeyValue = o.CoursewarePackageKeyValue;
            o.CoursewarePackageKeyValue = '全部';
          }
          o.CoursewarePackageKeyValue = `<span style="color:#999">${o.CoursewarePackageKeyValue}<span>`;
          o.IsLocationLockKeyValue = o.IsLocationLockKey > 0 ? '已开启' : '未开启';
        });
        this.authorizationCoursewareList = result.data.PageDataList;
        console.log(result, '授权课件列表-分页查询');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 汇总查询
    doAfterCilckSummaryData (item) {
      console.log('点击汇总数据排序', item);
      this.params.searchText = '';
      this.params.pageIndex = 0;
      this.params.type = item.type;
      this.getSearchPageList();
    },
    // 条件查询
    loadAfterTableData (queryParams) {
      if (queryParams) {
        Object.assign(this.params, queryParams);
      }
      this.getSearchPageList();
    },
    // 新增
    addAuthorizationCourseware (type) {
      this.titleName = '授权课件';
      this.coursewareIndo = {};
      this.isShowCoursewareFormPopup = true;
    },
    // 续费
    renewClick (rowData) {
      this.titleName = '课件续费';
      this.coursewareIndo = rowData;
      this.isShowCoursewareFormPopup = true;
    },
    editAuthorizationEquipment (rowData) {
      this.coursewareIndo = rowData;
      this.isShowAuthorizationAeviceFormPopup = true;
    },
    // 停用/启用
    switchClick (rowData, callBack) {
      let name = rowData.StatusKey == 0 ? '恢复授权' : '暂停授权';
      layer.confirm('请确定是否' + name + '?', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              layer.close();
              if (callBack) {
                callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
              }
            }
          },
          {
            name: '确认',
            callBack: () => {
              if (rowData.StatusKey == 0) {
                AuthorizationCoursewareEnable(rowData.OLAPKey).then(result => {
                  this.getSearchPageList();
                  if (callBack) {
                    callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
                  }
                }, error => {
                  layer.alert(error.msg);
                });
              } else {
                AuthorizationCoursewareDisable(rowData.OLAPKey).then(result => {
                  this.getSearchPageList();
                  if (callBack) {
                    callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });;
                  }
                }, error => {
                  layer.alert(error.msg);
                });
              }
              layer.close();
            }
          }
        ]
      });
    },
    // 授权课件详情
    getAuthorizeCoursewareCountDetial (rowData, callBack) {
      SearchPageLogList(rowData.OLAPKey).then(result => {
        if (result.data) {
          this.expandRowData = result.data;
          this.expandRowData.forEach(o => {
            // 状态 1:已使用完 0：未使用完
            if (Number(o.StatusKey) == 0) {
              o.StatusKeyValue = '使用中';
            } else if (Number(o.StatusKey) == 1) {
              o.StatusKeyValue = '已用完';
            }
            o.AuthorizeTime = o.AuthorizeTime ? o.AuthorizeTime.slice(0, 10) : '';
          });
          // this.expandRowData.sort((x, y) => {  return Date.new(y.AuthorizeTime) - Date.new(x.AuthorizeTime);});// 授权时间排序
          if (callBack) {
            callBack(this.expandRowData || []);
          }
          console.log(this.expandRowData, '授权课件详情');
        }
      });
    },
    // 关闭
    closeCoursewareFormPopup () {
      this.isShowCoursewareFormPopup = false;
      this.isShowAuthorizationAeviceFormPopup = false;
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'distributeCoursewareTable') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.distributeCoursewareTableDialogFactory) {
        this.$refs.distributeCoursewareTableDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    }
  },
  computed: {

  }
};
</script>
<style>
.authorization_courseware .el-table__body-wrapper {
  width: 103.9%;
}
</style>

