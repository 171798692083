<template>
  <div>
    <div class="form_info_line"
         :class="{ form_info_required: required }">
      <div class="form_info_field">
        <span>{{formTitle}}</span>
        <span v-if="helpDescription">
          <input-form-tip :tips='helpDescription'></input-form-tip>
        </span>
      </div>
      <div class="form_info_value form_info_select"
           @click.stop="showEditDialog">
        <input type="text"
               :placeholder="required?'必填':'选填'"
               readonly
               v-model="vValue"
               :class="{input_readonly:readonly}" />
      </div>
      <custom-dialog :title="dialogTitleName"
                     class="refund_dialog defult_dialog"
                     :visible.sync="isShowDialog"
                     :before-close="cancel">
        <input-number-form :min="min"
                           :max="max"
                           :value="value.number"
                           :decimalPlaces="decimalPlaces"
                           :isShowButtonInfo="isShowButtonInfo"
                           :dialogTitleName="dialogTitleName"
                           :isRemarksShow="isRemarksShow"
                           :formTitle="formTitle"
                           :placeholder="inputNumberFormPlaceholder"
                           :maxLength="maxLength"
                           @cancelEvent="cancel"
                           @inputChange="doAfterChangeCount"
                           :required="required"></input-number-form>
      </custom-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isShowDialog: false,
      vValue: '',
      isVerificationSHow: ''
    };
  },
  props: {
    formTitle: {
      // 标题，必填
      type: String,
      required: true
    },
    isNeedVerification: {
      type: Boolean,
      default: false
    },
    verificationData: String,
    verificationTip: String,
    readonly: Boolean,
    dialogTitleName: String, // 弹出层标题名
    value: Object,
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    formPlaceholder: String, // 默认文本，可选，如不提供，则默认显示title
    isShowButtonInfo: Boolean,
    isVerification: [Number, String], // 需要验证的弹窗层是否显示
    isRemarksShow: {
      type: Boolean,
      default: false
    },
    decimalPlaces: {
      type: Number,
      default: 2
    },
    inputNumberFormPlaceholder: {
      type: String,
      default: ''
    },
    max: [Number, String], // 最大值
    min: [Number, String], // 最小值
    maxLength: {
      type: Number,
      default: 12
    },
    helpDescription: String // 表单说明提示

  },
  computed: {
    vPlaceholder () {
      // 默认文本，如不提供，则默认显示title
      return this.formPlaceholder || this.formTitle;
    },
    vDialogTitleName () {
      return '选择' + this.formTitle;
    }
  },
  watch: {
    'value.number': {
      handler (newValue, oldValue) {
        this.vValue = newValue + this.value.UnitName;
      },
      deep: true
    },
    isVerification () {
      this.isVerificationSHow = this.isVerification;
      if (this.isVerificationSHow) {
        this.isShowDialog = true;
      } else {
        this.isShowDialog = false;
      }
    }
  },
  created () {
    this.vValue = this.value.number + this.value.UnitName;
    this.isVerificationSHow = this.isVerification;
  },
  methods: {
    // 点击时，显示选择层
    showEditDialog () {
      if (this.isNeedVerification && !this.verificationData) {
        layer.alert(this.verificationTip);
        return;
      }
      if (this.readonly) {
        this.$emit('showDialog');
      } else {
        this.isShowDialog = true;
      }
    },
    doAfterChangeCount (val, Remarks) {
      this.value.number = val;
      this.cancel();
      this.$emit('input', this.value); // 触发普通change，模拟双向绑定
      this.$emit('changeNumber', this.value, Remarks); // 触发普通change，模拟双向绑定
    },
    cancel () {
      this.isShowDialog = false;
    }
  }
};
</script>

