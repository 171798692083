<template>
  <div class="couser_info_lable">
    <div class="couser_info_lable_title"
         style="background: white;font-size: 16px;">
      <div class="lable_title_type type_green">班</div>
      <span>{{courseInfo.GradeClassKeyValue}}</span>
    </div>
    <div class="all_course_table_content"
         style="padding-bottom:5px">
      <div class="content_box_list no_pointer clearfix">
        <div class="content_box_list_date fl">
          <div>{{courseInfo.WeekKeyValue}}</div>
          <div class="font_gray">{{vBeginClassTime}}</div>
        </div>
        <div class="content_box_list_info fr"
             style="width: 305px;">
          <div class="list_info_left fl"
               style="max-width:210px">
            <div :class="$utils.getTextWidth(courseInfo.CourseNameKeyValue, 16)>210?'font_dual':''">
              <span>{{courseInfo.CourseNameKeyValue}} <span class="text_teacher">{{courseInfo.MTeacherKeyValue}}</span></span>

            </div>
            <div class="font_gray"> 截止
              <span class="">{{vLastClassTime}}</span>
              <span class="font_black">{{$utils.mAmountType(courseInfo.ClassCount,0)}}</span>节丨
              <span class="font_black"
                    v-if="vIsSysVersionTY">{{$utils.mAmountType(courseInfo.Num_SyncArranged,0)}}人</span>
              <span class="font_black"
                    v-else>{{$utils.mAmountType(courseInfo.Num_InGrade,0)}}人</span>
              <!-- <span class="font_black">{{$utils.mAmountType(courseInfo.RecentNumCount,0)}}</span>人 -->
            </div>
          </div>
          <div class="list_info_number fr">
            <div>
              <span class="font_gray">已排节数</span>
              <span>{{courseInfo.alreadyAttenCount}}</span>
            </div>
            <div>
              <span class="font_gray">待排课时</span>
              <span class="font_blue">{{$utils.mAmountType(sumToBeArranged,1)}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    courseInfo: Object,
    sumToBeArranged: [Number, String]
  },
  computed: {
    vBeginClassTime () {
      return this.courseInfo.ClassTimeName.split('-')[0];
    },
    vLastClassTime () {
      return this.courseInfo.LastClassTime.slice(0, 10);
    },
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    }
  }
};
</script>

<style>
</style>