<template>
  <div>
    <div class="workbench_view_attendance massive_bg button"
         @click.stop="goRouter('studentManagementEngineerWorkbench')"
         v-if="vtoken.IsTeacherAdviserKey==1">
      <div class="workbench_view_title form_info_field"
           style="border: none">
        <span>我的学生</span>

      </div>
      <div class="teacher_student_content">
        <div class="teacher_student_content_one">
          <div class="teacher_student_content_onetitle">
            分配正课生(人)
          </div>
          <div class="teacher_student_content_onedata"
               style="font-size:36px;margin-top:5px">
            {{teacherStudentList.length}}
          </div>
        </div>
        <div class="teacher_student_content_two">
          <div class="teacher_student_content_title">续费</div>
          <div class="two_content">
            <div class="two_content_item ">
              <div style="color:#FF9800;font-size:30px;margin-top:-10px;margin-bottom:5px">{{Number(dataInfo.RenewalWarningCount)}}</div>
              <div style="font-size:12px;color:#666">续费预警</div>
            </div>
            <div class="two_content_item ">
              <div style="color:#F75040;font-size:30px;margin-top:-10px;margin-bottom:5px">{{Number(dataInfo.NotRenewalCount)}}</div>
              <div style="font-size:12px;color:#666">未续失效</div>
            </div>
          </div>
        </div>
        <div class="teacher_student_content_two">
          <div class="teacher_student_content_title">出勤</div>
          <div class="two_content"
               style="width:364px">
            <div class="two_content_item">
              <div style="color:#F75040;font-size:30px;margin-top:-10px;margin-bottom:5px">{{Number(dataInfo.AttendanceWarningCount)}}<span style="color:#666;font-size:14px">人</span></div>
              <div style="font-size:12px;color:#666">出勤率预警</div>
            </div>
            <div class="two_content_item"
                 :title="'周排课次≤1的学生'">
              <div style="color:#F75040;font-size:30px;margin-top:-10px;margin-bottom:5px">{{Number(dataInfo.StuentNotOneCount)}}<span style="color:#666;font-size:14px">人</span></div>
              <div style="font-size:12px;color:#666;width: 100%;text-align: center;"
                   class="pr">
                <div>排课不足</div>
                <!-- <div style="color:#999;position: absolute;">周排课次≤1的学生</div> -->

              </div>
            </div>
            <div class="two_content_item">
              <div style="color:#F75040;font-size:30px;margin-top:-10px;margin-bottom:5px">{{Number(dataInfo.CampusWarningCount)}}<span style="color:#666;font-size:14px">人</span></div>
              <div style="font-size:12px;color:#666">离校预警</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot></slot>
    <div class="workbench_view_attendance massive_bg"
         v-if="isCoursewareListNull">
      <div class="workbench_view_title clearfix form_info_field"
           style="border-bottom:inherit">
        <span class="fl">我的课包</span>
        <!-- <input-form-tip :tips="'统计每天上课节数，考勤数据，方便查看每天的上课考勤情况，也可以对每节课的考勤数据进行操作。'"></input-form-tip> -->
        <span class="font_gray"
              style="margin-left: 20px;">向左拖动查看更多课包</span>
      </div>
      <div ref="swiper1"
           class="swiper-container swiper_attendance_contents">
        <div class="swiper-wrapper">
          <courseware-swiper v-for="(item, key) in coursewareList"
                             :key="key"
                             :item="item"
                             @chgCoureDateAfter="chgCouseware"></courseware-swiper>
        </div>
      </div>
    </div>
    <slot></slot>
    <div class="workbench_view_todaySign massive_bg">
      <div class="workbench_view_title clearfix form_info_field">
        <span class="fl">今日签到
          <input-form-tip :tips="'展示当天需要打考勤的课程和学生，非常贴合教务日常操作考勤方式。点一下即可考勤成功。'"></input-form-tip>
        </span>
        <span class="font_middle font_black fl">{{ vDate }} 共{{ courseList.length }}节</span>
        <div class="todaySign_fr fl">
          <span class="font_gray">提前签到：</span>
          <span class="font_blue">{{ Number($utils.getSaaSClientSetInfo(31).SetContent) }}分钟</span>
        </div>

        <div class="search_blook_box"
             style="margin-left: 10px">
          <div class="workbench_view_title_search fl">
            <input type="text"
                   placeholder="请输入姓名、姓名首字母、手机号"
                   v-model="textSearch" />
            <span v-show="textSearch"
                  class="home_remove_btn"
                  style=""
                  @click="textSearch = ''"></span>
          </div>
          <!-- <div class="workbench_view_title_notebook fl"
               @click="showDialog('attendancebook')"></div> -->
        </div>
      </div>
      <!-- show_sign_box 显示异常签到需要的类名 -->
      <div :class="abnormalSign ? 'show_sign_box' : ''">
        <div class="workbench_view_todaySign_content">
          <div class="couser_used_block clearfix"
               v-if="vEndInfo.count != 0"
               @click.stop="upOrDownEndCourseList">
            <div class="fl pr"
                 style="overflow: visible"
                 v-if="!isShowEndList">
              <!-- <el-tooltip :content="vEndInfo.val"
                          :disabled="disabled1"
                          popper-class="mendian_introduce_tooltip"
                          placement="bottom"
                          effect="light"> -->
              <div class="fl"
                   style="max-width: 100%">
                <span ref="tooltipBox1">已上完 <span class="font_black">{{ vEndInfo.count }}</span>节课
                  <!-- <span class="font_black">{{vEndInfo.val}}</span> -->
                </span>
              </div>
              <!-- </el-tooltip> -->
            </div>

            <div class="fl"
                 v-if="isShowEndList">
              已展开<span class="font_black">{{ vEndInfo.count }}</span>节已完结的课
            </div>
            <div class="fr"
                 :class="isShowEndList ? 'donw' : 'up'">
              {{ !isShowEndList ? "查看全部" : "恢复隐藏" }}
            </div>
          </div>
          <div v-if="vcourseList.length > 0"
               class="couser_block_ul"
               :class="{ height_top: vEndInfo.count == 0 }"
               :style="
              vcourseList.length == 0 && abnormalSign
                ? 'border-right: 1px solid #ececec;'
                : ''
            ">
            <coursesign ref="coursesign"
                        v-for="item in vcourseList"
                        :key="item.OLAPKey"
                        :textSearch="textSearch"
                        :item="item"
                        @attendanceArrange="showArrange"></coursesign>
          </div>
          <div v-if="
              vcourseList.length == 0 &&
              (vStartInfo.count != 0 || vEndInfo.count != 0) &&
              !isShowStartList &&
              !isShowEndList
            "
               class="workbench_view_todaySign_nodata_01"
               :class="{ height_top: vEndInfo.count == 0 }"
               style="cursor: default">
            暂无签到中的课。
          </div>
          <div class="couser_untapped_block clearfix"
               @click="isShowStartList = !isShowStartList"
               v-if="vStartInfo.count != 0">
            <div class="fl pr"
                 style="overflow: visible"
                 v-if="!isShowStartList">
              <div class="fl"
                   style="max-width: 100%">
                <span ref="tooltipBox2">未开始 <span class="font_black">{{ vStartInfo.count }}</span>节课
                  <!-- <span class="font_black"> {{vStartInfo.val}}</span> -->
                </span>
              </div>
              <!-- </el-tooltip> -->
            </div>
            <div class="fl"
                 v-if="isShowStartList">
              已展开<span class="font_black">{{ vStartInfo.count }}</span>节未开始的课
            </div>
            <div class="fr"
                 :class="isShowStartList ? 'up' : 'donw'">
              {{ !isShowStartList ? "查看全部" : "恢复隐藏" }}
            </div>
          </div>
          <div class="workbench_view_todaySign_nodata_02"
               :class="{ height_top: vEndInfo.count == 0 }"
               v-if="courseList.length == 0">
            今天还没有安排课程哦!!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import kqItem from './kq-item';
import coursewareSwiper from './courseware-swiper/index.vue';
// import abnormalSignPool from './abnormal-sign-pool';
import {
  getKQsummaryStatistics,
  getKQsummaryForDay,
  GetMainTeaAttendanceDaily,
  GetMainTeaScheduleCourseListByDate,
  getScheduleCourseOnStudentList,
  getSignWarning,
  GetBigCoursewarePackageForTeacher,
  GetRenewalWarningCount,
  GetAdiserStudentList
} from '../../API/workbench';
import coursesign from './course-sign';
import { setIntervalCheck } from '../../assets/index.js';
export default {
  data () {
    return {
      isCoursewareListNull: true, // 我的课包是否有值
      coursewareList: [], // 我的课包list
      curSelectedDate: Date.new().toLocaleDateString(),
      presidentReport: {}, // 校长统计
      startTime: '',
      endTime: '',
      swiper: {},
      swiper1: {},
      selectedDateInfo: '', // 选中天的详细数据
      kqList: [],
      disabled1: false,
      disabled2: false,
      showAttenInfo: false, // 显示考勤详情
      isToday: false, // 是否今天显示操作栏
      isTodayType: 1, // 1为已上课程, 2位未上课程.
      courseList: [],
      isOverShowMore: false, // 点击触发显示更多.
      isNotBeginShowMore: false, // 点击触发未开始显示更多.
      isShowAll: false, // 是否已经显示所有
      isNotBeginShowAll: false, // 是否未开始已经显示所有
      perviewLength: 6,
      screenWidth: '',
      isShowStartList: true, // 是否显示未开始,
      isShowEndList: true, // 是否显示进行中.
      seletedCourseInfo: {},
      parseDataList: '',
      textSearch: '',
      setTimeoutObj: null,
      abnormalSign: false, // 显示异常签到池
      searchObj: {
        pageIndex: 0,
        pageSize: 0,
        orderBy: '',
        sequence: '',
        searchText: '',
        startTime: '', // this.$utils.formatDateToLine(Date.new()),
        endTime: '', // this.$utils.formatDateToLine(Date.new()),
        QueryID: 0
      },
      abnormalSignData: {
        Total: 0,
        PageDataList: []
      },
      seletedAbnormalInfo: {},
      isShowAttendanceArrangeDialog: false,
      dataInfo: {
        RenewalWarningCount: 0,//续费预警
        NotRenewalCount: 0, //失效未续
        AttendanceWarningCount: 0, //预警出勤率数
        CampusWarningCount: 0, //长期未到校预警数
        StuentNotOneCount: 0, //排课不足预警
      },
      teacherStudentList: []
    };
  },
  components: {
    kqItem,
    coursesign,
    coursewareSwiper
  },
  created () {
    this.registerBusEvent();
    this.setIntervalCheckCourse();


  },
  mounted () {
    if (this.vtoken.IsTeacherAdviserKey == 1) {
      this.getDataInfo()
    }
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    this.$nextTick(() => {
      this.swiperInit();
      this.loadData();
      this.getCoursewareList();
    });

    // this.$refs.swiper1.swiper.slideTo(0);
    if (this.$refs.tooltipBox1) {
      this.disabled1 =
        this.$refs.tooltipBox1.offsetWidth <
        this.$refs.tooltipBox1.parentElement.offsetWidth;
    }
    if (this.$refs.tooltipBox2) {
      this.disabled2 =
        this.$refs.tooltipBox2.offsetWidth <
        this.$refs.tooltipBox2.parentElement.offsetWidth;
    }
  },
  watch: {
    screenWidth (c, o) {
      if (c >= 1901) {
        this.perviewLength = 6;
      } else if (c < 1901 && c > 1440) {
        this.perviewLength = 5;
      } else {
        this.perviewLength = 3;
      }
      this.$nextTick(() => {
        // this.swiperInit();
      });
    }
  },
  computed: {
    vtoken () {
      return this.$store.getters.token
    },
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    // 判断权限,
    vHasModulePower () {
      return this.$utils.CheckModulePower(
        this.$store.getters.RolePowerList,
        51
      ); // 上课安排权限
    },
    SaaSClientInfo () {
      // 门店信息   要使用的字段 最早签到时间(分钟)
      return this.$store.getters.SaaSClientInfo;
    },
    vcourseList () {
      let newArr = [];
      if (!this.isShowStartList && !this.isShowEndList) {
        // 初始化时只显示进行中的课.
        newArr = [].concat(this.vProcessCourseList);
      } else {
        if (this.isShowStartList && this.isShowEndList) {
          newArr = []
            .concat(this.vEndCourseList)
            .concat(this.vProcessCourseList)
            .concat(this.vUnStartCourseList);
        } else if (this.isShowEndList) {
          newArr = []
            .concat(this.vEndCourseList)
            .concat(this.vProcessCourseList);
        } else {
          newArr = []
            .concat(this.vProcessCourseList)
            .concat(this.vUnStartCourseList);
        }
      }
      return newArr;
    },
    vDate () {
      if (this.selectedDateInfo) {
        return (
          this.$utils.formatDateStr(this.selectedDateInfo.ClassTime, 'ddddd') +
          ' ' +
          this.$utils.formatDateStr(this.selectedDateInfo.ClassTime, 'MM') +
          '月' +
          this.$utils.formatDateStr(this.selectedDateInfo.ClassTime, 'dd') +
          '日'
        );
      }
    },
    // 已结束的课
    vEndInfo () {
      let endInfo = {
        count: 0, //
        atten: 0,
        noAtten: 0,
        val: ''
      };
      this.vEndCourseList.forEach((o, index) => {
        endInfo.count++;
        endInfo.atten += Number(o.ArrivedNumCount);
        endInfo.noAtten += Number(o.NoAttenceCount);
        if (index + 1 == this.vEndCourseList.length) {
          endInfo.val +=
            o.CourseNameKeyValue +
            ' ( 签到' +
            Number(o.ArrivedNumCount) +
            '人 ) ';
        } else {
          endInfo.val +=
            o.CourseNameKeyValue +
            ' ( 签到' +
            Number(o.ArrivedNumCount) +
            '人 ) , ';
        }
      });
      return endInfo;
    },
    // 未开始的课列表
    vUnStartCourseList () {
      let unStartCourseList = [];
      // let curTime = Date.new().getTime() / 1000 / 60;
      this.courseList.forEach((o) => {
        if (o.StatusKey == 2 && !o.canSign) {
          // 未来的课
          unStartCourseList.push(o);
        }
      });
      return unStartCourseList;
    },
    // 进行中的课列表
    vProcessCourseList () {
      let processCourseList = [];
      this.courseList.forEach((o) => {
        if (o.StatusKey == 2 && o.canSign) {
          // 未来的课
          processCourseList.push(o);
        } else if (o.StatusKey == 3 && o.canSign) {
          processCourseList.push(o);
        }
      });
      console.log('vProcessCourseList', processCourseList);
      return processCourseList;
    },
    // 已结束的课列表
    vEndCourseList () {
      let endCourseList = [];
      this.courseList.forEach((o) => {
        if (o.StatusKey == 4) {
          // 已结束的课
          endCourseList.push(o);
        }
      });
      return endCourseList;
    },
    // 未开始的课
    vStartInfo () {
      let startInfo = {
        count: 0, //
        ArrangedNumCount: 0,
        val: ''
      };
      this.vUnStartCourseList.forEach((o, index) => {
        startInfo.count++;
        startInfo.ArrangedNumCount += Number(o.ArrangedNumCount);
        if (index + 1 == this.vUnStartCourseList.length) {
          startInfo.val +=
            o.CourseNameKeyValue +
            ' ( 安排' +
            Number(o.ArrangedNumCount) +
            '人 )';
        } else {
          startInfo.val +=
            o.CourseNameKeyValue +
            ' ( 安排' +
            Number(o.ArrangedNumCount) +
            '人 ) , ';
        }
      });
      return startInfo;
    },
    vPageDataList () {
      return this.abnormalSignData.PageDataList;
    },
    vAbnormalSignList () {
      let newArr = [];
      this.vPageDataList.forEach((o) => {
        let locaIndex = newArr.findIndex((p) => {
          return o.CreateTime.substring(0, 10) == p.CreateTime.substring(0, 10);
        });
        if (locaIndex >= 0) {
          newArr[locaIndex].PageDataList.push(o);
        } else {
          newArr.push({
            CreateTime: o.CreateTime,
            PageDataList: [o]
          });
        }
      });
      console.log(newArr, 'vAbnormalSignList');
      return newArr;
    }
  },
  methods: {
    getDataInfo () {
      GetAdiserStudentList(this.$store.getters.token.UserID).then(result => {
        this.teacherStudentList = result.data
        console.log(result.data, 'GetAdiserStudentList');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
      GetRenewalWarningCount(this.$store.getters.token.UserID).then(result => {
        this.dataInfo = result.data
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // 关闭考勤详情时更新当天的数据
    courseAttenCloseUpdate () {
      console.log('courseAttenClose', this.curSelectedDate);
      // getKQsummaryStatistics
      GetMainTeaAttendanceDaily(
        this.curSelectedDate,
        this.curSelectedDate
      ).then((result) => {
        console.log('获取指定日期数据', result.data);
        let locaIndex = this.kqList.findIndex((o) => {
          return o.ClassTime == this.curSelectedDate;
        });
        if (locaIndex >= 0) {
          result.data[0].isActive = false;
          result.data[0].isOpt = false;
          this.kqList.splice(locaIndex, 1, result.data[0]);
          // this.kqList[locaIndex] = this.$utils.parseJson(result.data[0]);
          console.log(this.kqList[locaIndex], '处理后', locaIndex);
        }
        // this.kqList.forEach(o => {
        //   if (o.ClassTime == this.curSelectedDate) {
        //     console.log(o, '处理前');
        //     o = this.$utils.parseJson(result.data[0]);
        //     console.log(o, '处理后');
        //   }
        // });
      });
    },
    // 定时检查是否有课从未开始-》可签到
    setIntervalCheckCourse () {
      setIntervalCheck(() => {
        this.setScheduleCourseStatus();
      });
    },
    setScheduleCourseStatus () {
      this.courseList.forEach((o) => {
        let starttime = o.ClassTime.replace(/-/g, '/') + ' ' + o.SClassTimeName; // 开始时间
        let endtime = o.ClassTime.replace(/-/g, '/') + ' ' + o.EClassTimeName; // 开始时间
        let startClassTime = Date.new(starttime).getTime() / 1000 / 60;
        let endClassTime = Date.new(endtime).getTime() / 1000 / 60;
        let curTime = Date.new().getTime() / 1000 / 60;
        if (o.StatusKey == 2) {
          // 未来的课
          if (
            startClassTime - curTime >=
            Number(this.$utils.getSaaSClientSetInfo(31).SetContent)
          ) {
            // 当前课上课时间大于当前时间 超过最早签到时间
            o.canSign = false;
          } else {
            o.canSign = true; // 可以签到
            if (startClassTime - curTime <= 0) {
              // 当前课上课时间-当前时间 < 0 变为上课中
              o.StatusKey = 3;
            }
          }
        } else if (o.StatusKey == 4) {
          // 已结束的课
          o.canSign = true; // 可以签到.0+-
        } else if (o.StatusKey == 3) {
          o.canSign = true; // 可以签到
          if (endClassTime - curTime <= 0) {
            // 当前课下课时间-当前时间 < 0 变为已结束
            o.StatusKey = 4;
          }
        }
      });
    },

    setCreateTime (item) {
      if (item) {
        let date = item.CreateTime.replace(/-/g, '/').substring(0, 10);
        let lastDate = Date.new(
          Date.new().setTime(Date.new().getTime() - 24 * 60 * 60 * 1000)
        );
        let last2Date = Date.new(
          Date.new().setTime(Date.new().getTime() - 24 * 60 * 60 * 1000 * 2)
        );
        let preDate = Date.new(
          Date.new().setTime(Date.new().getTime() + 24 * 60 * 60 * 1000)
        );
        if (date == this.$utils.formatDatet(Date.new(), 'yyyy/MM/dd')) {
          return '今天';
        } else if (date == this.$utils.formatDatet(lastDate, 'yyyy/MM/dd')) {
          return '昨天';
        } else if (date == this.$utils.formatDatet(preDate, 'yyyy/MM/dd')) {
          return '明天';
        } else if (date == this.$utils.formatDatet(last2Date, 'yyyy/MM/dd')) {
          return '前天';
        } else {
          return (
            this.$utils.formatDatet(Date.new(date), 'yyyy') +
            '-' +
            this.$utils.formatDatet(Date.new(date), 'MM') +
            '-' +
            this.$utils.formatDatet(Date.new(date), 'dd')
          );
        }
      }
    },
    // 选中异常签到池的单项,弹出处理层.
    seletedAbnormalItem (item) {
      console.log(this.vAbnormalSignList, 'seletedAbnormalItem', item);
      this.seletedAbnormalInfo = item;
      let moduleInfo = {
        name: '异常签到池',
        routerName: this.$route.name,
        moduleName: 'abnormalchecksign',
        data: item
      };
      this.$bus.emit('needOpenRouter', moduleInfo);
    },
    // 显示异常签到池.
    showAbnormalSign () {
      this.abnormalSign = !this.abnormalSign;
    },
    spliceWarningList (OLAPKey) {
      console.log(OLAPKey, 'sliceWarningList');
      if (OLAPKey > 0) {
        let index = this.abnormalSignData.PageDataList.findIndex((o) => {
          return o.OLAPKey == OLAPKey;
        });
        console.log(index, this.abnormalSignData.PageDataList);
        if (index >= 0) {
          this.abnormalSignData.PageDataList.splice(index, 1);
        }
        console.log(this.abnormalSignData.PageDataList);
      }
    },
    getCoursewareList () {
      // console.log('刷新1')
      GetBigCoursewarePackageForTeacher().then((result) => {
        result.data.forEach((o, index) => {
          o.isActive = false;
          if (index == 0) {
            o.isActive = true;
          }
        });
        this.coursewareList = result.data;

        console.log(this.coursewareList, '老师大课包list');
        if (this.coursewareList.length == 0) {
          this.isCoursewareListNull = false;
        }
        setTimeout(() => {
          console.log('刷新');
          this.swiper1.updateSlides();
          this.swiper1.slideTo(0);
        }, 500);
      });
      // console.log('延时')
      // this.$refs.swiper1..slideTo(0)
      console.log(this.swiper1, 'this.$refs.swiper1');
    },
    loadData (type) {
      this.todayTableInfoList(Date.new(), () => {
        this.$nextTick(() => {
          let locaIndex = this.kqList.findIndex((o) => {
            return o.ClassTime == this.curSelectedDate;
          });
          if (locaIndex) {
            this.swiperIndex(locaIndex - 1); // 定位到当前日期.
          }
          if (!type) {
            this.chgCoureDate(this.kqList[locaIndex]);
          } else {
            this.kqList[locaIndex].isActive = true;
          }
        });
      });
    },
    // 弹出层
    showDialog (val) {
      this.$emit('showDialog', val);
    },
    // 打开安排考勤
    showArrange (item, updateFunc) {
      console.log('showArrange', item);
      this.seletedCourseInfo = item;
      let moduleInfo = {
        name: '安排考勤',
        moduleName: 'attendanceArrange',
        routerName: this.$route.name,
        data: { scheduleCourseKey: this.seletedCourseInfo.OLAPKey, isTeacher: true }
      };
      this.$emit('openCustomDialogByFactoryDialog', moduleInfo);
    },
    overShowMore (newArr) {
      if (this.isOverShowMore) {
        this.courseList.forEach((o) => {
          if (o.StatusKey == 3 || o.StatusKey == 4) {
            newArr.push(o);
          }
        });
      } else {
        this.courseList.forEach((o) => {
          if (o.StatusKey == 3 || o.StatusKey == 4) {
            if (newArr.length < 5) {
              newArr.push(o);
            }
          }
        });
      }
    },
    notBeginShowMore (newArr) {
      if (this.isNotBeginShowMore) {
        this.courseList.forEach((o) => {
          if (o.StatusKey == 2) {
            newArr.push(o);
          }
        });
      } else {
        this.courseList.forEach((o) => {
          if (o.StatusKey == 2) {
            if (newArr.length < 5) {
              newArr.push(o);
            }
          }
        });
      }
    },
    chgisNotBeginShowMore () {
      this.isNotBeginShowAll = true;
      this.isNotBeginShowMore = true;
    },
    chgisOverShowMore () {
      this.isShowAll = true;
      this.isOverShowMore = true;
    },
    // 显示日期插件
    showDatePicker () {
      this.$refs.eventCalendarBox.showBox = true;
    },
    // 选中日期
    changeDay (date) {
      // 复制一下date.
      this.curSelectedDate = date;
      this.todayTableInfoList(Date.new(date), () => {
        console.log('changeDay', this.curSelectedDate);
        let locaIndex = this.kqList.findIndex((o) => {
          return o.ClassTime == this.curSelectedDate;
        });
        if (locaIndex) {
          this.swiperIndex(locaIndex); // 定位到当前日期.
          this.chgCoure(this.kqList[locaIndex], true);
        }
      });
    },
    chgCoure (item, type) {
      // 只提供高亮,不提供请求.
      // this.$el.querySelector('.el-input__inner').blur();
      this.$refs.inputDate.pickerblur();
      this.curSelectedDate = item.ClassTime;
      this.isTodayType = 1;
      this.isOverShowMore = false;
      this.isNotBeginShowMore = false;
      this.isShowAll = false;
      this.isNotBeginShowAll = false;
      this.kqList.forEach((o) => {
        if (o.ClassTime == item.ClassTime) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      if (!type) {
        let moduleInfo = {
          name: '考勤详情', // + ' ' + this.curSelectedDate,
          routerName: this.$route.name,
          moduleName:
            this.$initJson.allCustomerDialogNames.attendanceDetail.moduleName,
          data: {
            queryDate: this.curSelectedDate,
            isTeacher: true,
            ClassName: 'noscroll'
          }
        };
        this.$bus.emit('needOpenRouter', moduleInfo);
      }
    },
    // 点击中考勤日期列表块触发
    chgCoureDate (item, type) {
      // 提供高亮,提供请求.
      this.selectedDateInfo = item;
      this.isTodayType = 1;
      this.isOverShowMore = false;
      this.isNotBeginShowMore = false;
      this.isShowAll = false;
      this.isNotBeginShowAll = false;

      let date = item.ClassTime.replace(/-/g, '/');
      this.getCourseListForDate(item.ClassTime, '', () => {
        if (date == this.$utils.formatDatet(Date.new(), 'yyyy/MM/dd')) {
          this.isToday = true;
        } else {
          this.isToday = false;
        }
        if (
          Date.new(date) <=
          Date.new(this.$utils.formatDatet(Date.new(), 'yyyy/MM/dd')) &&
          Number(item.HaveClassNum) + Number(item.UnderwayClassNum) ==
          Number(item.ScheduleCourseNum) &&
          this.courseList.length > 0
        ) {
          this.showAttenInfo = true;
        } else {
          this.showAttenInfo = false;
        }
        this.kqList.forEach((o) => {
          if (o.ClassTime == item.ClassTime) {
            o.isActive = true;
          } else {
            o.isActive = false;
          }
        });
      });
    },
    // 获取指定日期的数据
    todayTableInfoList (date, callback) {
      date = date || Date.new();
      this.curSelectedDate = this.$utils.formatDatet(date, 'yyyy-MM-dd');
      this.startTime = this.$utils.getNextDate(date, -20);
      this.endTime = this.$utils.getNextDate(date, 40);
      this.getKQStatisticsDateList(this.startTime, this.endTime, callback);
    },
    // 初次及选中日期时触发请求的考勤统计
    getKQStatisticsDateList (startTime, endTime, callback) {
      // getKQsummaryStatistics
      GetMainTeaAttendanceDaily(startTime, endTime).then((result) => {
        this.kqList = result.data;
        this.kqList.forEach((o) => {
          this.$set(o, 'isActive', false);
          this.$set(o, 'isOpt', false);
        });
        if (callback) {
          callback();
        }
      });
    },
    // swiper往前拖动触发的考勤统计.
    getKQStatisticsBeforeList (startTime, endTime, callback) {
      // getKQsummaryStatistics
      GetMainTeaAttendanceDaily(startTime, endTime).then((result) => {
        result.data.sort((x, y) => {
          return Date.new(y.ClassTime) - Date.new(x.ClassTime);
        });
        result.data.forEach((o) => {
          o.isActive = false;
          let locationIndex = this.kqList.findIndex((p) => {
            return p.ClassTime == o.ClassTime;
          });
          if (locationIndex < 0) {
            this.kqList.unshift(o);
          }
        });
        if (callback) {
          callback();
        }
      });
    },
    // swiper往后拖动触发的考勤统计.
    getKQStatisticsAfterList (startTime, endTime, callback) {
      // getKQsummaryStatistics
      GetMainTeaAttendanceDaily(startTime, endTime).then((result) => {
        result.data.forEach((o) => {
          o.isActive = false;
          let locationIndex = this.kqList.findIndex((p) => {
            return p.ClassTime == o.ClassTime;
          });
          if (locationIndex < 0) {
            this.kqList.push(o);
          }
        });
        if (callback) {
          callback();
        }
      });
    },
    // 按天获取所有考勤课列表的信息
    getCourseListForDate (date, queryID, callback) {
      // getKQsummaryForDay
      GetMainTeaScheduleCourseListByDate(date).then((result) => {
        result.data.forEach((o) => {
          o.canSign = false;
          o.studentList = [];
        });
        if (queryID > 0) {
          let findIndex = this.courseList.findIndex((obj) => {
            return obj.OLAPKey == queryID;
          });
          if (findIndex > -1) {
            this.courseList.splice(findIndex, 1, result.data[0]);
          } else {
            this.courseList.push(result.data[0]);
          }
        } else {
          this.courseList = result.data;
          this.setScheduleCourseStatus();
        }
        if (callback) {
          callback();
        }
      });
    },
    //
    swiperInit () {
      var that = this;
      // this.swiper = new Swiper('.swiper_attendance_content', {
      //   slidesPerView: 4,
      //   breakpoints: {
      //     1020: {
      //       slidesPerView: 3
      //     },
      //     1440: {
      //       slidesPerView: 4
      //     },
      //     1680: {
      //       slidesPerView: 5
      //     },
      //     1920: {
      //       slidesPerView: 6
      //     },
      //     2560: {
      //       slidesPerView: 8
      //     },
      //     3840: {
      //       slidesPerView: 12
      //     }
      //   },
      //   spaceBetween: 5,
      //   // loop: false, // 循环模式选项
      //   observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      //   observeParents: true, // 修改swiper的父元素时，自动初始化swipe
      //   on: {
      //     // 拖动结束后触发事件预加载. 判断最左边隐藏的课程数据是否小于十
      //     slidePrevTransitionEnd: function () {
      //       let index = this.activeIndex; // 保存当前下标问题.
      //       let oldLength = Number(that.kqList.length);
      //       console.log(this.activeIndex);
      //       if (this.activeIndex < 10) {
      //         var thos = this;
      //         that.preCourseKey(that.kqList[0], function () {
      //           that.$nextTick(() => {
      //             let newLength = that.kqList.length;
      //             thos.activeIndex = newLength - oldLength + index; // 设定当前下标数.
      //           });
      //         });
      //       }
      //     },
      //     // 拖动结束后触发事件预加载. 判断最右边隐藏的课程数据是否小于十,activeIndex为内部的下标属性.
      //     slideNextTransitionEnd: function () {
      //       console.log(this.activeIndex);
      //       if (that.kqList.length > 0) {
      //         if (that.kqList.length - this.activeIndex < 20) {
      //           this.curCourseIndex = this.activeIndex;
      //           that.nextCourseKey(that.kqList[that.kqList.length - 1], () => {
      //             that.$nextTick(() => {
      //               that.swiper.updateSlides();
      //             });
      //           });
      //         }
      //       }
      //     }
      //   }
      // });

      this.swiper1 = new Swiper('.swiper_attendance_contents', {
        slidesPerView: 3.2,
        // grabCursor:true,
        // slidesPerView: 1,
        // spaceBetween: 15,
        initialSlide: 1,
        grabCursor: true,
        // breakpoints: {
        //   1440: {
        //     slidesPerView: 2,
        //   },
        //   1680: {
        //     slidesPerView: 3,
        //   },
        //   1920: {
        //     slidesPerView: 4,
        //   },
        //   2560: {
        //     slidesPerView: 6,
        //   },
        //   3840: {
        //     slidesPerView: 8,
        //   },
        // },
        // spaceBetween: 0,
        // loop: true, // 循环模式选项
        observer: true,   // 修改swiper自己或子元素时，自动初始化swiper
        // observeParents: true,  // 修改swiper的父元素时，自动初始化swipe

        on: {
          // 拖动结束后触发事件预加载. 判断最右边隐藏的课程数据是否小于十,activeIndex为内部的下标属性.
          slideNextTransitionEnd: function () {
            console.log(this.activeIndex);
          },
          slidePrevTransitionEnd: function () {
            console.log(this.activeIndex);
          }
        }
      });

      // this.swiper1.slideTo(0);
      console.log(this.swiper1, 'this.swiper1');
    },
    // 向前预加载
    preCourseKey (item, callback) {
      let date = Date.new(item.ClassTime.replace(/-/g, '/'));
      this.endTime = this.$utils.getNextDate(date, -1);
      this.startTime = this.$utils.getNextDate(date, -6);
      this.getKQStatisticsBeforeList(this.startTime, this.endTime, callback);
    },
    // 向后预加载
    nextCourseKey (item, callback) {
      let date = Date.new(item.ClassTime.replace(/-/g, '/'));
      this.startTime = this.$utils.getNextDate(date, 1);
      this.endTime = this.$utils.getNextDate(date, 6);
      this.getKQStatisticsAfterList(this.startTime, this.endTime, callback);
    },
    swiperIndex (val) {
      // this.swiper.updateSlides();
      // this.swiper.slideTo(val);
    },
    // 转为整数
    integerData (val) {
      val = val || 0;
      return Math.round(val);
    },
    goRouter (url) {
      this.$router.push({ name: url });
    },
    // 长连接通知
    websocketNoticeAttendanceOrArrange (data) {
      switch (data.dataType) {
        case 'StudentAttendance':
          this.websocketNoticeStudentAttendance(data.newData);
          break;
        case 'StudentArrang':
          this.websocketNoticeStudentArrang(data.newData);
          break;
        case 'ScheduleCourse':
          this.websocketNoticeScheduleCourse(data.newData);
          break;
        default:
          break;
      }
    },
    // 长连接通知 学生考勤
    websocketNoticeStudentAttendance (dataArr) {
      console.log('长连接通知 学生考勤', dataArr);
      dataArr.forEach((item) => {
        let findCourseItem = this.courseList.find((obj) => {
          return obj.OLAPKey == item.ScheduleCourseKey;
        }); // 找到对应的课
        let findStudentIndex = -1;
        if (findCourseItem) {
          findStudentIndex = findCourseItem.studentList.findIndex((obj) => {
            return obj.StudentKey == item.StudentKey;
          }); // 找到对应学生
        }
        // 已结束且可签到
        if (
          (findCourseItem && findCourseItem.StatusKey == 4 &&
            findCourseItem.canSign &&
            findStudentIndex == -1) ||
          (findCourseItem.StatusKey == 4 &&
            findCourseItem.canSign &&
            !this.isShowEndList)
        ) {
          if (item.AttenceStatusKey == 3) {
            // 已考勤
            findCourseItem.ArrivedNumCount =
              Number(findCourseItem.ArrivedNumCount) + 1;
            findCourseItem.studentList.push(item);
          } else {
            if (
              findStudentIndex > -1 &&
              findCourseItem.studentList[findStudentIndex].AttenceStatusKey == 3
            ) {
              findCourseItem.ArrivedNumCount =
                Number(findCourseItem.ArrivedNumCount) - 1;
              findCourseItem.studentList.splice(findStudentIndex, 1);
            }
          }
        } else if (findCourseItem && findStudentIndex > -1) {
          this.$set(item, 'isActive', false); // 这里是为了高亮效果.
          this.$set(item, 'isOpt', false);
          if (item.AttenceStatusKey == 3) {
            findCourseItem.ArrivedNumCount =
              Number(findCourseItem.ArrivedNumCount) + 1;
          } else if (
            item.AttenceStatusKey != 3 &&
            findCourseItem.studentList[findStudentIndex].AttenceStatusKey == 3
          ) {
            findCourseItem.ArrivedNumCount =
              Number(findCourseItem.ArrivedNumCount) - 1;
          }
          findCourseItem.studentList.splice(findStudentIndex, 1, item);
        }
      });
    },
    // 长连接通知 安排学生
    websocketNoticeStudentArrang (dataArr) {
      console.log('长连接通知 安排学生', dataArr);
      dataArr.forEach((item) => {
        this.$set(item, 'isActive', false); // 这里是为了高亮效果.
        this.$set(item, 'isOpt', false);
        let findCourseItem = this.courseList.find((obj) => {
          return obj.OLAPKey == item.ScheduleCourseKey;
        }); // 找到对应的课

        let findStudentIndex = -1;
        if (findCourseItem) {
          findStudentIndex = findCourseItem.studentList.findIndex((obj) => {
            return obj.StudentKey == item.StudentKey;
          }); // 找到对应的学生
        }
        // 未开始的课且不可签到
        if (findCourseItem.StatusKey == 2 && !findCourseItem.canSign) {
          if (!this.isShowStartList) {
            // 未展开
            if (item.IfDel == 0) {
              // 添加
              findCourseItem.ArrangedNumCount =
                Number(findCourseItem.ArrangedNumCount) + 1;
            } else if (item.IfDel == 1) {
              // 删除
              findCourseItem.ArrangedNumCount =
                Number(findCourseItem.ArrangedNumCount) - 1;
            }
          } else {
            // 已展开
            if (item.IfDel == 0 && findStudentIndex == -1) {
              // 添加
              findCourseItem.ArrangedNumCount =
                Number(findCourseItem.ArrangedNumCount) + 1;
              findCourseItem.studentList.push(item);
            } else if (item.IfDel == 1 && findStudentIndex > -1) {
              // 删除
              findCourseItem.ArrangedNumCount =
                Number(findCourseItem.ArrangedNumCount) - 1;
              findCourseItem.studentList.splice(findStudentIndex, 1);
            }
          }
        } else if (
          findCourseItem &&
          item.IfDel == 0 &&
          findStudentIndex == -1
        ) {
          // 存在的课 不存在的学生
          findCourseItem.studentList.push(item);
          findCourseItem.ArrangedNumCount =
            Number(findCourseItem.ArrangedNumCount) + 1;
        } else if (findCourseItem && findStudentIndex > -1 && item.IfDel == 1) {
          // 删除学生
          findCourseItem.studentList.splice(findStudentIndex, 1);
          findCourseItem.ArrangedNumCount =
            Number(findCourseItem.ArrangedNumCount) - 1;
        }
      });
    },
    // 长连接通知 修改课（停课 恢复 修改 新增）
    websocketNoticeScheduleCourse (dataArr) {
      console.log('websocketNoticeScheduleCourse', dataArr);
      dataArr.forEach((item) => {
        let findIndexForCourse = this.courseList.findIndex((obj) => {
          return item.OLAPKey == obj.OLAPKey;
        }); // 找到对应的课
        let findItem = this.courseList[findIndexForCourse];
        // 停课 或 把今天的课 改为其他天的课
        if (
          findIndexForCourse > -1 &&
          (item.IsClassStopKey == 1 ||
            this.$utils.formatDateToLine(Date.new(item.ClassTime)) !=
            this.$utils.formatDateToLine(Date.new()))
        ) {
          this.courseList.splice(findIndexForCourse, 1);
          return;
        } else if (findIndexForCourse > -1 && item.IfDel == 1) {
          this.courseList.splice(findIndexForCourse, 1);
          return;
        } else if (findIndexForCourse == -1 && item.IsClassStopKey != 1) {
          // 恢复 或 新增
          this.setCanSignStatus(item);
          if (item.ArrangedNumCount > 0) {
            this.getStudentListInCourse(item, () => {
              this.courseList.push(item);
              this.courseList.sort((a, b) => {
                return (
                  Date.new(a.ClassTime + ' ' + a.SClassTimeName) -
                  Date.new(b.ClassTime + ' ' + b.SClassTimeName)
                );
              });
            });
          } else {
            this.$set(item, 'studentList', []);
            this.courseList.push(item);
          }
          let locaIndex = this.kqList.findIndex((o) => {
            return o.ClassTime == this.curSelectedDate;
          });
          if (locaIndex >= 0) {
            this.kqList[locaIndex].ScheduleCourseNum++;
            console.log(locaIndex, '新增课', this.kqList[locaIndex]);
          }
        } else if (findIndexForCourse > -1 && item.IsClassStopKey != 1) {
          // 修改
          this.$set(item, 'studentList', findItem.studentList || []);
          this.setCanSignStatus(item);
          this.courseList.splice(findIndexForCourse, 1, item);
        }
        this.courseList.sort((a, b) => {
          return (
            Date.new(a.ClassTime + ' ' + a.SClassTimeName) -
            Date.new(b.ClassTime + ' ' + b.SClassTimeName)
          );
        });
      });
    },
    setCanSignStatus (item) {
      console.log(
        this.$utils.getSaaSClientSetInfo(31),
        'this.$utils.getSaaSClientSetInfo(31)'
      );
      let starttime =
        item.ClassTime.replace(/-/g, '/') + ' ' + item.SClassTimeName; // 开始时间
      let startClassTime = Date.new(starttime).getTime() / 1000 / 60;
      let curTime = Date.new().getTime() / 1000 / 60;
      if (
        startClassTime - curTime >=
        Number(this.$utils.getSaaSClientSetInfo(31).SetContent)
      ) {
        // 当前课上课时间大于当前时间 超过最早签到时间
        this.$set(item, 'canSign', false);
      } else {
        this.$set(item, 'canSign', true);
      }
    },
    getStudentListInCourse (item, callback) {
      getScheduleCourseOnStudentList(item.OLAPKey)
        .then((result) => {
          item.studentList = result.data;
          if (typeof callback == 'function') {
            callback();
          }
        })
        .catch((error) => {
          console.log(error, 'error');
        });
    },
    upOrDownEndCourseList () {
      this.isShowEndList = !this.isShowEndList;
      if (!this.isShowEndList) {
        this.courseList.forEach((obj) => {
          if (obj.StatusKey == 4) {
            let asignNum = 0;
            obj.studentList.forEach((item) => {
              if (item.AttenceStatusKey == 3) {
                asignNum += 1;
              }
            });
            obj.ArrivedNumCount = asignNum;
          }
        });
      }
    },
    websocketNoticeSignWarning (data) {
      // 已解决
      if (data.StatusKey == 2 || data.StatusKey == 3) {
        let findIndex = this.abnormalSignData.PageDataList.findIndex((obj) => {
          return obj.OLAPKey == data.OLAPKey;
        });
        if (findIndex > -1) {
          this.abnormalSignData.PageDataList.splice(findIndex, 1);
        }
      } else if (data.StatusKey == 1) {
        this.abnormalSignData.PageDataList.unshift(data);
      }
    },
    registerBusEvent () {
      // 长连接通知 考勤相关
      this.$bus.on(this, 'wsAttendanceOrArrange', (data) => {
        if (data) {
          this.websocketNoticeAttendanceOrArrange(data);
        } else {
          this.courseAttenCloseUpdate();
        }
      });
      this.$bus.on(this, 'switchHeaderBarToTeacherAttenClass', () => {
        this.loadData('nonUpdateCourseList');
      });
      // 长连接通知 异常签到
      this.$bus.on(this, 'wsAfterSignWarning', (data) => {
        this.websocketNoticeSignWarning(data);
      });
      // 提前处理.
      this.$bus.on(this, 'updateSignWrongling', (arg1, arg2, ...arr) => {
        this.spliceWarningList(arg1);
      });
      this.$bus.on(this, 'allCourseQuit', (arg1, arg2, ...arr) => {
        this.getKQStatisticsDateList(this.startTime, this.endTime, () => {
          let findItem = this.kqList.find((o) => {
            return o.ClassTime == this.curSelectedDate;
          });
          if (findItem) {
            findItem.isActive = true;
          }
        });
      });
      this.$bus.on(this, 'closeAttendanceDetailDialog', (data) => {
        console.log('closeAttendanceDetailDialog');
        this.courseAttenCloseUpdate();
      });
    },
    closeAttendanceArrangeDialog () {
      this.isShowAttendanceArrangeDialog = false;
    },
    chgCouseware (item) {
      this.coursewareList.forEach((o, index) => {
        o.isActive = false;
        if (o.OLAPKey == item.OLAPKey) {
          o.isActive = true;
        }
      });
      console.log(this.$store.getters.token, 'this.$store.getters.token;', item);
      let token = this.$store.getters.token;
      // let moduleInfo = {
      //     name: "课包详情", // + ' ' + this.curSelectedDate,
      //     routerName: this.$route.name,
      //     moduleName:'bigCoursewareDetails',
      //     data: {
      //         dataInfo : {
      //         TeacherKey : token.UserID,
      //         BigCoursewarePackageKey : item.BigCoursewarePackageKey,
      //         AuthKey : 0,
      //         TeacherName: token.UserName
      //       }
      //     },
      //   };
      //   this.$bus.emit("needOpenRouter", moduleInfo);
      this.$dialog.open(this, {
        name: '课包详情',
        routerName: this.$route.name,
        moduleName: 'bigCoursewareDetailst',
        data: {
          dataInfo: {
            TeacherKey: token.UserID,
            BigCoursewarePackageKey: item.OLAPKey,
            AuthKey: 0,
            TeacherName: token.UserName,
            Source: item.Source,
            MainDemoName: item.MainDemoName,
            CoursewarePackageKey: item.OLAPKey,
          }
        },
        callBack: {
          afterSuccess: () => {
            this.$emit('flashData');
          },
          closeDialog: () => {
            this.$emit('flashData');
          }
        }
      });
    }
  },
  beforeDestroy () {
    clearTimeout(this.setTimeoutObj);
  }
};
</script>
<style scoped>
.swiper_attendance_contents {
  width: 100%;
  overflow: hidden;
  display: block;
}
.teacher_student_content {
  height: 130px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
}
.teacher_student_content_one {
  width: 198px;
  height: 106px;
  border-radius: 8px;
  background: linear-gradient(90deg, #7b94fb 0%, #3969fe 103.28%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  color: white;
}
.teacher_student_content_onetitle {
  font-size: 14px;
  color: white;
}
.teacher_student_content_onedata {
  position: relative;
  padding-left: 32px;
}
.teacher_student_content_onedata::before {
  display: block;
  content: '';
  position: absolute;
  left: 0px;
  top: 4px;
  width: 30px;
  height: 30px;
  background: url('../../../public/image/Vector_5.png') center no-repeat;
}
.teacher_student_content_title {
  font-size: 16px;
  color: #333;
  position: relative;
  padding-left: 10px;
  margin-bottom: 4px;
}
.teacher_student_content_title::before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  width: 4px;
  height: 15px;
  border-radius: 8px;
  background: #3969fe;
}
.teacher_student_content_two {
  margin-left: 35px;
}
.two_content {
  width: 236px;
  height: 81px;
  border-radius: 8px;
  border: 1.25px solid #ececec;
  /* background: linear-gradient(0deg, #ECECEC, #ECECEC),
  linear-gradient(0deg, #FFFFFF, #FFFFFF); */
  display: flex;
  justify-content: row;
  flex-direction: row;
  align-content: center;
}
.two_content_item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>