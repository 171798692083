<template>
  <div class="chain_mendian_center_left"
       style="width:100% !important;">
    <div class="left_title">
      <div class="left_type">
        <div class="left_type_list"
             :class="searchData.DateType==1?'opt':''"
             @click.stop="changeSearchType(1)">月</div>
        丨
        <div class="left_type_list"
             :class="searchData.DateType==2?'opt':''"
             @click.stop="changeSearchType(2)">年</div>
        <!-- <div class="left_type_text type_blue">收入</div>
        <div class="left_type_text type_red">支出</div> -->

      </div>
      <div class="centent_type">签约校区</div>
      <div class="right_title_date">
        <div class="el-select"
             v-if="searchData.DateType==1">
          <div class="el-input el-input--small el-input--suffix">
            <single-year-picker @chgDate="chgDate"
                                :isDisableNow="isDisableNow"
                                :defaultDate="defaultDate"
                                :type="'month'"></single-year-picker>
          </div>
        </div>
        <div class="el-select"
             v-if="searchData.DateType==2">
          <div class="el-input el-input--small el-input--suffix">
            <single-year-picker @chgDate="chgDate"
                                :isDisableNow="isDisableNow"
                                :defaultDate="defaultDate"
                                :type="'year'"></single-year-picker>
          </div>
        </div>
        <!-- <el-select v-model="searchData.TargetSaaSClientKey"
                   placeholder="请选择"
                   size="small"
                   @change="chgSelect">
          <el-option v-for="item in menDianList"
                     :key="item.OLAPKey"
                     :label="item.MainDemoName"
                     :value="item.OLAPKey">
          </el-option>
        </el-select> -->

      </div>
    </div>
    <div class="left_echart">
      <lineTypeEchart :echartID="'dual_histogram4'"
                      :reqList="monthList"
                      :nowDataList="nowList"
                      :echartType="'line'"
                      :tipTitle="vTipsTitle"
                      ref="echart"></lineTypeEchart>
    </div>
  </div>
</template>
<script>
import singleYearPicker from '../../base-module/date-picker-common/single-year-picker';
import lineTypeEchart from '../../base-module/echart-common/line-type-echart';
import { SelectInComePayReportForGroup, SelectSignedCampus } from '../../../API/workbench';
export default {
  data () {
    return {
      searchData: {
        StartTime: '',
        EndTime: '',
        DateType: 1      // 日期类型1-月;2-年
      },
      attenReport: {},
      menDianList: [],
      monthList: [],
      nowList: [],
      nowDataList: [],
      oldList: [],
      defaultDate: Date.new(Date.new().setDate((Date.new().getDate() - -1))),
      isDisableNow: true          // 是否禁用今天以后的日期

    };
  },
  computed: {
    vTipsTitle () {
      if (this.searchData.DateType == 1) {
        return '日';
      } else {
        return '';
      }
    }
  },
  components: {
    lineTypeEchart, singleYearPicker
  },
  created () {
  },
  mounted () {
    this.setTime();
    this.getAttenReport();
    this.$bus.on(this, 'switchHeaderBarToManagementAndStatistics', () => {
      this.getAttenReport();
    });
  },
  methods: {
    chgDate (val, StartTime, EndTime) {
      this.searchData.StartTime = StartTime.replace(/\//g, '-');
      this.searchData.EndTime = EndTime.replace(/\//g, '-'); ;
      this.getAttenReport();
    },
    changeSearchType (val) {
      this.searchData.DateType = val;
      this.setTime();
      this.getAttenReport();
    },
    chgSelect (val) {
      console.log(val, 'chgSelect');
      this.searchData.TargetSaaSClientKey = val;
      this.getAttenReport();
    },
    setTime () {
      if (this.searchData.DateType == 1) {    //  选中月
        this.searchData.StartTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.searchData.EndTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd');
      } else {      // //选中年
        this.searchData.StartTime = Date.new().getFullYear() + '-01-01';
        this.searchData.EndTime = Date.new().getFullYear() + '-12-31';
      }
      console.log(this.searchData.StartTime, this.searchData.EndTime);
    },
    setMonthList () {
      if (this.searchData.DateType == 1) { // 选中月
        this.monthList = this.setEchartXForMonth();
        console.log(this.monthList, '选中月');
      } else {      // //选中年
        this.monthList = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
      }
    },
    // 设置X轴
    setEchartXForWeek () {
      let newArr = [];
      this.nowDataList.forEach((o, index) => {
        newArr.push(o.SeptTime.substring(5) + ' ' + this.$utils.getWeekByTwo(o.SeptTime));
      });
      return newArr;
    },
    // 设置X轴
    setEchartXForMonth () {
      let newArr = [];
      this.nowDataList.forEach((o, index) => {
        let num = index < 9 ? '0' + (index + 1) : index + 1;
        newArr.push(num);
      });
      return newArr;
    },
    getAttenReport (callback) {
      SelectSignedCampus(this.searchData).then(result => {
        console.log('getAttenReport', result.data);
        this.nowDataList = result.data;
        this.setMonthList();
        this.nowList = this.getNowList();
        // this.oldList = this.getOldList();
        if (callback) {
          callback();
        }
        this.$nextTick(() => {
          if (this.$refs.echart) {
            this.$refs.echart.echartInit();
          }
        });
      }).catch(error => {
        console.log('ErroretAttenReport', error);
      });
    },
    // 应到
    getNowList () {
      let nowList = [];
      // let monthList = this.getDaysOfMonth(this.seletedDate);
      this.monthList.forEach(p => {
        let item = null;
        if (this.searchData.DateType == 2) {
          item = this.nowDataList.find(o => {     // 区分年
            return Date.new(o.SeptTime).getMonth() + 1 == parseInt(p);
          });
        } else if (this.searchData.DateType == 1) {      // 区分月
          item = this.nowDataList.find(o => {
            return Date.new(o.SeptTime).getDate() == parseInt(p);
          });
        }
        if (item) {
          if (Date.new(Date.new().setDate((Date.new().getDate()))) >= Date.new(item.SeptTime)) {
            nowList.push(Number(item.ThreadCount).toFixed(0));
          }
          // nowList.push(Number(item.ThreadCount).toFixed(0));
        } else {
          // nowList.push('0');
        }
      });
      return nowList;
    },
    // 实到
    getOldList () {
      let nowList = [];
      this.monthList.forEach(p => {
        let item = null;
        if (this.searchData.DateType == 2) {
          item = this.nowDataList.find(o => {     // 区分年
            return Date.new(o.SeptTime).getMonth() + 1 == parseInt(p);
          });
        } else if (this.searchData.DateType == 1) {      // 区分月
          item = this.nowDataList.find(o => {
            return Date.new(o.SeptTime).getDate() == parseInt(p);
          });
        }
        if (item) {
          if (Date.new(Date.new().setDate((Date.new().getDate()))) >= Date.new(item.SeptTime)) {
            nowList.push(Number(item.PayAmount).toFixed(0));
          }
        }
        // else {
        //   nowList.push('0');
        // }
      });
      return nowList;
    }
  }
};
</script>
