<template>
  <!-- <div v-if="isShowMainForm">
    <custom-dialog title="学生排课不足"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowMainForm"
                   :before-close="doShowPopupHideClick"> -->
  <!-- 续费预警数据列表 -->
  <div class="renew_warning content_marginTop_30 content_marginRL single_shadow btn_marginBottom_30">
    <!-- <div class="course_block_yellow pr">
          <span class="font_red">*</span>2周后没有排课的在校学号是呢过，注意排课，尽快消耗课时。
          <div class="course_block_close"></div>
        </div> -->
    <!-- <div class="course_block_black" v-if="totalObj.total>0">
          排课不足学生 {{Number(totalObj.total)}}人
        </div> -->
    <div class="population">
      <div class="population_left">
        <span class="">排课不足学生</span>
        <span class="">{{Number(VstatisticsCount||0)}}</span>人
      </div>
    </div>
    <input-search-contain-btn v-model="searchText"
                              :placeholder="'请输入姓名、姓名首字母、手机号'"
                              @onSearchEvent="inputSearch"></input-search-contain-btn>

    <div class="recording_data_table"
         v-if="totalObj.dataList.length>0">
      <div class="new_table_list_thead">
        <div style="width: 89px;"
             class="new_table_list_th style_gray tl">学生</div>
        <div style="width: 111px;padding-left:15px"
             class="new_table_list_th style_yellow tl">手机号</div>
        <div style="width: 91px;padding-left:15px"
             class="new_table_list_th style_red tl">剩余课时</div>
        <div style="width: 99px;padding-left:15px"
             class="new_table_list_th style_green tl">最后安排</div>
      </div>
      <div class="recording_data_tbody"
           @scroll="scrollTOP">
        <div class="new_table_list_tr"
             v-for="(item,index) in totalObj.dataList "
             :key="index"
             @click.stop="goRouter(item)">
          <div class="new_table_list_td tl font_blue"
               style="width: 89px;"
               @click.stop="goStudentFile(item)">{{item.StudentKeyValue}}</div>
          <div class="new_table_list_td tl"
               style="width: 111px;padding-left:15px">
            <div class="school_warning_content_center">{{$utils.phoneModulePower(item.CustomerPhoneName)||'--'}}</div>
          </div>
          <div class="new_table_list_td tl"
               style="width: 91px;padding-left:15px">
            <div>{{Number(item.CurrentClassHourCount||0)}}课时</div>
          </div>
          <div class="new_table_list_td tl"
               style="width: 99px;padding-left:15px">
            <div class="font_red">{{vLastClassTime(item)}}
              <span class="font_gray"
                    v-if="(vLastClassTime(item)!='今天'&&vLastClassTime(item)!='无安排')">天后</span>
            </div>
          </div>
          <div class="new_table_list_arrow"></div>
        </div>
        <div class="new_table_list_bottom"
             v-if="isLoadEnd">
          <span class="loading_btn">加载中...</span>
        </div>
        <div class="new_table_list_bottom"
             v-else-if="noMore">
          <span class="font_gray">已显示全部</span>
        </div>
      </div>
    </div>
    <div class="course_block_nodata"
         style="padding-bottom:20px"
         v-else>
      <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
    </div>
  </div>
</template>
<script>
import { stuArrangeWarn } from '../../../API/workbench';
export default {
  data () {
    return {
      searchData: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 20, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: '', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '' // 字符串	可选		数据源：GET	搜索条件：模糊匹配->套餐名,套餐名首字母
      },
      searchText: '',
      totalObj: {
        Total: 0,
        dataList: []
      },
      isLoadEnd: false, // 判断滚动事件触发时是否接口已经处理完毕,处理完毕再执行滚动事件.
      isShowMainForm: false, // 显示
      noMore: false
    };
  },
  components: {},
  created () {
    // this.getApplyRecordData(this.searchData);
    this.doShowPopupShowClick();
  },
  mounted () {
    this.$dialog.register(this, ['studentFile', 'studentArrangeCourseByCourseName']);
  },
  computed: {
    VstatisticsCount () {
      if (this.vWarmingStatisical) {
        return this.vWarmingStatisical;
      } else {
        return 0;
      }
    },
    vWarmingStatisical () {
      return this.$store.getters.getWarmingStatisical.ArrangeWarningNum;
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    },
  },
  watch: {
    // 'searchData.searchText': {
    //   deep: true,
    //   handler: function (c, o) {
    //     if (!c) {
    //       this.totalObj.dataList = [];
    //       this.searchData.pageIndex = 0;
    //       this.searchData.pageSize = 20;
    //       this.getApplyRecordData(this.searchData);
    //     }
    //   }
    // }
  },
  methods: {
    // 打开学生档案.
    goStudentFile (item) {
      this.$dialog.open(this, {
        name: '学生档案',
        routerName: this.$route.name,
        moduleName: 'studentFile',
        data: { studentID: item.StudentKey }
      });
    },
    // 主表单开关
    doShowPopupHideClick () {
      this.searchData.pageIndex = 0;
      this.searchData.searchText = '';
      this.searchText = '';
      this.totalObj.dataList = [];
      // this.oweClasshourWarning.Total=0
      this.noMore = false;
      this.isShowMainForm = false;
    },
    doShowPopupShowClick () {
      this.isShowMainForm = true;
      // document.body.scrollTop = 0;
      this.getStuArrangeWarn(this.searchData);
    },
    getStuArrangeWarn (searchData) {
      this.isLoadEnd = true;
      stuArrangeWarn(searchData).then(result => {
        console.log(result.data, 'getPotentialCustomerData111');
        this.totalObj.Total = result.data.Total;
        result.data.PageDataList.forEach(o => {
          this.totalObj.dataList.push(o);
        });
        if (result.data.PageDataList.length == this.searchData.pageSize) {
          this.searchData.pageIndex++;
        } else {
          this.noMore = true;
        }
        this.isLoadEnd = false;
      });
    },
    vLastClassTime (item) {
      if (item) {
        if (item.LastClassTime) {
          let timesecond = Date.new(item.LastClassTime.replace(/-/g, '/')).getTime() / 1000;
          let result = timesecond / 3600 / 24;
          let todayTime = Date.new(Date.new().toLocaleDateString()).getTime() / 1000 / 3600 / 24;
          if (result - todayTime < 1) {
            return '-';
          } else {
            console.log(result);
            return Math.round(result - todayTime);
          }
        } else {
          return '无安排';
        }
      }
    },
    // 点击搜索按钮.
    search () {
      this.searchData.pageIndex = 0;
      if (this.searchData.searchText == '') {
        this.searchData.pageSize = 20;
      } else {
        this.searchData.pageSize = 0;
      }
      this.totalObj.dataList = [];
      this.getStuArrangeWarn(this.searchData);
    },
    // 滚动加载更多
    scrollTOP (e) {
      let ETarget = e.target;
      if (
        (ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight ||
          ETarget.scrollTop + 1 ==
          ETarget.scrollHeight - ETarget.clientHeight) &&
        !this.searchData.searchText &&
        !this.isLoadEnd && !this.noMore
      ) {
        // console.log('scrollTOP', this.noMore);
        this.getStuArrangeWarn(this.searchData);
      }
    },
    // 点击搜索
    inputSearch () {
      this.noMore = false;
      this.searchData.pageIndex = 0;
      this.searchData.searchText = this.searchText;
      this.totalObj.dataList = [];
      this.totalObj.Total = 0;
      this.getStuArrangeWarn(this.searchData);
    },
    reload () {
      this.noMore = false;
      this.searchData.pageIndex = 0;
      // this.searchData.searchText = '';
      this.totalObj.dataList = [];
      this.totalObj.Total = 0;
      this.getStuArrangeWarn(this.searchData);
      console.log('reload');
      this.$bus.emit('updateWarmingStatisical');
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    },
    goRouter (item) {
      // 排课 按钮
      if (this.vHasModulePower(53)) {
       this.$dialog.open(this, {
        name: '按课程排课',
        routerName: this.$route.name,
        moduleName: 'studentArrangeCourseByCourseName',
        data: { studentID: item.StudentKey },
        callBack: { afterSucces: () => { this.reload(); } }
      });
      } else {
        this.$utils.CheckModulePowerToTips(53);
      }
      
    }
  }
};
</script>

