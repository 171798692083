<template>
  <!-- 教师 弹窗宽度要设置436-->
  <div>
    <div class="WKT_information_nav">
      <div class="nav_list"
           :class="isOptType=='teachers'?'opt':''"
           @click="doTeacherClick">
        优秀教师
      </div>
      <div class="nav_list"
           :class="isOptType=='students'?'opt':''"
           @click="doStudentsClick">
        明星学员
      </div>
    </div>
    <!-- 搜索选择层 -->
    <input-search-immediately v-model="searchText"
                       :placeholder="'请输入'"
                       :styleText="'right: 35px;'"
                       :inputStyle="'width: 396px;'"
                       @clearSearch="clearSearText"></input-search-immediately>

    <div class="WKT_information_teacher_ul"
         v-if="isOptType=='teachers'">
      <enable-teacher-list v-for="(item,index) in vEnableTeacherList"
                           :key="index"
                           :dataItem="item"
                           :enableList="enableList"
                           @doAddRecommendClick="doAfterAddRecommendClick"></enable-teacher-list>
      <div v-show="vEnableTeacherList.length == 0" class="has_arrange_course">
        <div class="has_arrange_course_content" style="padding: 0px;">
          <div class="has_arrange_course_list ">
              <div class="has_arrange_course_nodata single_shadow ">
                <span>亲，当前没有任何数据哦~</span>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="WKT_information_teacher_ul"
         v-else-if="isOptType=='students'">
      <enableStar-students-list v-for="(item,index) in vEnableStarStudentsList"
                                :key="index"
                                :dataItem="item"
                                  :enableList="enableList"
                                 @doAddRecommendClick="doAfterAddRecommendClick"></enableStar-students-list>
      <div v-show="vEnableStarStudentsList.length == 0" class="has_arrange_course">
        <div class="has_arrange_course_content" style="padding: 0px;">
          <div class="has_arrange_course_list ">
              <div class="has_arrange_course_nodata single_shadow ">
                <span>亲，当前没有任何数据哦~</span>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import enableTeacherList from './enable-teacher-list';
import enableStarStudentsList from './enableStar-students-list';
import { SelectEnableTeacher, SelectEnableStarStudents, AddPopularRecommend } from '../../../../API/workbench';
export default {
  name: 'singleSelectionItem',
  data () {
    return {
      searchText: '',
      isOptType: 'teachers', // 默认选中优秀老师栏
      // 查询参数
      searchObj: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
        sequence: '', //	字符串	可选		数据源：GET	 排序方向
        searchText: ''//	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
      },
      enableTeacherList: [], // 已启用优秀老师查询列表
      enableStarStudentsList: [] // 已启用明星学员查询列表
    };
  },
  props: {
    enableList: Array
  },
  components: {
    enableTeacherList,
    enableStarStudentsList
  },
  created () {
    this.getEnableTeacher();
  },

  watch: {

  },
  computed: {
    vEnableTeacherList () {
      let newList = [];
      let trueSearchText = this.searchText.toLowerCase();
      this.enableTeacherList.forEach(o => {
        if ((o.TeacherName && (o.TeacherName.toLowerCase().indexOf(trueSearchText) >= 0)) ||
         (o.FirstLetterName && o.FirstLetterName.toUpperCase().indexOf(trueSearchText.toUpperCase()) >= 0)
        ) {
          newList.push(o);
        }
      });
      return newList;
    },
    vEnableStarStudentsList () {
      let newList = [];
      let trueSearchText = this.searchText.toLowerCase();
      this.enableStarStudentsList.forEach(o => {
        if ((o.StarStudentsName && (o.StarStudentsName.toLowerCase().indexOf(trueSearchText) >= 0)) ||
        (o.FirstLetterName && o.FirstLetterName.toUpperCase().indexOf(trueSearchText.toUpperCase()) >= 0)
        ) {
          newList.push(o);
        }
      });
      return newList;
    }
  },
  methods: {
    clearSearText () {
      this.searchText = '';
    },
    getEnableTeacher () {
      SelectEnableTeacher(this.searchObj).then(result => {
        console.log(result.data.PageDataList, '优秀老师列表');
         // 对列表去重
        this.enableList.forEach(o => {
          result.data.PageDataList.forEach((i, index) => {
            if (i.id == o.id) {
              result.data.PageDataList.splice(index, 1);
            }
          });
        });
        this.enableTeacherList = result.data.PageDataList;
      });
    },
    getEnableStarStudents () {
      SelectEnableStarStudents(this.searchObj).then(result => {
        console.log(result.data.PageDataList, '明星学员列表');
        // 对列表去重
        this.enableList.forEach(p1 => {
          result.data.PageDataList.forEach((p, index) => {
            if (p.id == p1.id) {
              result.data.PageDataList.splice(index, 1);
            }
          });
        });
        this.enableStarStudentsList = result.data.PageDataList;
      });
    },
    // 显示 优秀老师列表
    doTeacherClick () {
      this.isOptType = 'teachers';
      this.getEnableTeacher();
    },
    // 显示 明星学员列表
    doStudentsClick () {
      this.isOptType = 'students';
      this.getEnableStarStudents();
    },
    // 添加推荐
    doAfterAddRecommendClick (item) {
      let Obj = { id: item.id, save: this.enableList.length + 1 };
      AddPopularRecommend(Obj).then(result => {
        console.log(result.data, '推荐成功');
        layer.alert('推荐成功');
        this.$emit('doAfterAddRecommendSucceed', this.dataItem);
      });
    }
  }
};
</script>

