<template>
  <div>
    <list ref="detailList"
          :listTitle="listTitle"
          :extendSearchCondition="vPopupTableParams.extendSearchCondition"
          :functionBtnGroup="functionBtnGroup"
          :tableData="vPopupTableParams.tableData"
          :totalNum="totalNum"
          :PageCount="PageCount"
          :tableColumns="vPopupTableParams.tableColumns"
          :awardText="vAwardText"
          :titleInfoList="titleInfoList"
          @actionBtnClick="doAfterFunctionBtnClick"
          @tableBtnClick="doAfterTableBtnClick"
          @loadTableData="loadTableData"></list>
  </div>
</template>
<script>
import list from '../activity-list';
import { TeacherCommissionDetails, ParentsPromoteDistributionIncentives } from '../../../API/workbench.js';
let tableFunctionType = { fanxian: 'fanxian', fanhour: 'fanhour', fangift: 'fangift' };// 几个弹出层列表tabFunction 类型的初始化
export default {
  name: 'teacherDetailList',
  data () {
    return {
      listTitle: '',
      itemInfo:{},
      totalNum: 0, // 返回的总记录数
      PageCount: 0, // 页数
      pageSize: 0, // 查询记录数
      // 老师提成列表
      LSTCExtendSearchCondition: [], // 老师提成扩展查询参数
      LSTCTableColumn: [// 列
        {
          label: "序号",
          prop: "Number",
          width: 68,
          sortable: false,
          align: "left",
          isShow: true,
          type: "table-index",
          className: "table_index_gray",
        },
        { label: '报名时间', prop: 'add_time', width: 157, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '学生名', prop: 'StudentKeyValue', width: 184, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '报名人', prop: 'username', width: 184, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0, icon: 'fa fa-qrcode' },
        { label: '手机号', prop: 'telephone', width: 184, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '是否到店', prop: 'statusValue', width: 184, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0 },
        // { label: '报名金额', prop: 'source_order_amount', width: 184, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '奖励', prop: 'commission_amount', width: 'auto', sortable: 'custom', align: 'left', isShow: true, type: 'date', decimalPlace: 0 }
      ],
      titleInfoList:[],
      LSTCTableData: [], // 数据
      functionBtnGroup: [] // 功能按钮
    };
  },
  props: ['selectedCouponInfo'],
  components: {
    list
  },
  computed: {
    vAwardText(){
      if(this.vPopupTableParams.tableData[0]){
        return this.vPopupTableParams.tableData[0].commission_amount;
      }else  {
        return ''
      }
      
    },
    // 弹出层列表参数
    vPopupTableParams () {
      let tableParams = {};
      tableParams.extendSearchCondition = this.LSTCExtendSearchCondition;
      tableParams.tableColumns = this.LSTCTableColumn;
      tableParams.tableData = this.LSTCTableData;
      return tableParams;
    }
  },
  methods: {
    // 加载老师提成明细
    loadTeacherPerformanceList (queryParams) {
      let searchObj = queryParams;
      searchObj.id = this.selectedCouponInfo.id;
      searchObj.agentid = this.itemInfo.id;
      TeacherCommissionDetails(searchObj).then(result => {
        console.log('loadTeacherPerformanceList: result.data', result.data);
        this.LSTCTableData = [];
        if (result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach(obj => {
            obj.add_time = obj.add_time.substring(0,16);
            obj.source_order_amount = Number(obj.source_order_amount).toFixed(2) + '元';
            obj.commission_amount = Number(obj.commission_amount).toFixed(2) + '元';
            if(obj.status == 2){
              obj.statusValue = '是';
            }else {
              obj.statusValue = '否';
            }
            console.log(obj.commission_amount, 'commission_amount', obj.source_order_amount);
            this.LSTCTableData.push(obj);
          });
          this.totalNum = result.data.Total;
          this.PageCount = result.data.PageCount;
          this.currentPage = queryParams.PageIndex;
        } else {
          this.totalNum = 0;
        }
        this.$refs.detailList.isLoading = false;
      }, error => {
        layer.alert(error.data.msg);
        this.$refs.detailList.isLoading = false;
      });
    },
    // 加载弹出层列表数据
    loadTableData (queryParams) {
      this.$refs.detailList.isSearch = true;
      this.$refs.detailList.isLoading = true;
      console.log('TeacherCommissionDetails', queryParams);
      this.loadTeacherPerformanceList(queryParams);
    },
    // 点击table 的详情
    doAfterClickDetail (item, eventName) {
      // if (eventName == tableFunctionType.fangift) {
      //   console.log(item, 'tableFunctionType.fangift', eventName);
      //   if (item.read_gift_status == 2) {
      //     this.browseGiftDistribution(item);
      //   }
      //   return;
      // }
      this.itemInfo = item;
      this.titleInfoList = [
        {name:'员工',text:this.itemInfo.name},
        {name:'浏览',text:this.itemInfo.readPeopleNum},
        {name:'招生',text:this.itemInfo.BMNum},
        {name:'奖励',text:this.itemInfo.commission_amount},
      ];
      console.log(item, 'doAfterClickDetail', eventName);
      let initSelectedParams = [];
      let unClearParams = [];
      this.listTitle = '员工直销';
      initSelectedParams.push({ name: 'id', value: this.selectedCouponInfo.id, unClear: true }, { name: 'agentid', value: item.id, unClear: true });
      unClearParams.push({ name: '老师', value: item.name });
      this.$refs.detailList.isShowListDialog = true;
      this.$refs.detailList.initSelectedParams(initSelectedParams, unClearParams);
    },
    // 点击 弹出层列表的按钮
    doAfterTableBtnClick (rowData, eventName) {
      let type = 0;
      switch (eventName) {
        case tableFunctionType.fanxian:
          if (rowData.is_fafang_fanxian == 1) {
            return;
          }
          type = 1;
          break;
        case tableFunctionType.fanhour:
          if (rowData.is_fafang_fanhour == 1) {
            return;
          }
          type = 2;
          break;
        case tableFunctionType.fangift:
          if (rowData.is_fafang_fangift == 1) {
            return;
          }
          type = 3;
          break;
        default:
          break;
      }
      console.log(rowData, 'rowData');
      // 问题/
      this.parentsPromoteDistributionIncentives(rowData, type);
    },
    showTeacherList () {
      this.$refs.detailList.isShowListDialog = true;
    },
    parentsPromoteDistributionIncentives (rowData, type) {
      ParentsPromoteDistributionIncentives(rowData.id, type).then(result => {
        console.log(this.LSTCTableData, 'LSTCTableData1');
        console.log(this.FXJLTableData, 'FXJLTableData111');
        this.FXJLTableData = this.utils.parseJson(this.chgArrayInfo(this.FXJLTableData, rowData, type));
        this.LSTCTableData = this.utils.parseJson(this.chgArrayInfo(this.LSTCTableData, rowData, type));
        console.log(this.LSTCTableData, 'LSTCTableData222');
        console.log(this.FXJLTableData, 'FXJLTableData2222');
        layer.alert(result.msg);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 点击 弹出层列表的右上角按钮
    doAfterFunctionBtnClick () {

    }
  }

};
</script>
