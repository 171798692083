<template>
  <div class="course_dispense_right_list">
    <div>
      <span class="list_date">{{dataInfo.WeekKeyValue}}{{dataInfo.StartTime}}</span>
      <span v-if="Number(dataInfo.IsNotEndKey)==1">不结束</span>
      <span v-else>{{dataInfo.ClassNum}}课次</span>
    </div>
    <div class="list_icon"
         v-if="dataInfo.LastClassTime"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object
  },
  methods: {

  }
};
</script>

<style>
</style>