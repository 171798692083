<template>
  <div class="recruit_student_activity_content ">
    <div class="course_statistics_table_search flex course_statistics_title" style="padding: 13px 0px">

      <div class="course_statistics_search">

        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入活动名'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;width:76px"
               @click.stop="addActivity">新建活动</div>
        </div>
      </div>
    </div>
    <div class="content_ul" style="min-height:500px"
         v-if="vTemplateList.length>0">
      <template-item v-for="(item,key) in vTemplateList"
                     :key="key"
                     :item="item"
                     @seletedItem="seletedTemplateAfter"></template-item>

    </div>
    <div v-if="vTemplateList.length==0"
         style="padding-top:150px;height:500px">
      <div class="course_block_nodata">
        <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
      </div>
    </div>

    <!-- 分页 -->
    <div v-if="searchObj.pageSize > 0"
         class="table_pagination table_pagination_right" style="margin: 20px;">
      <div class="table_left_text">
        共{{total}}条数据
      </div>
      <el-pagination @current-change="handleCurrentChange"
                     :page-size="searchObj.pageSize"
                     :current-page.sync="vPageIndex"
                     background
                     :pager-count="5"
                     layout="prev, pager, next, jumper"
                     :total="total>0?total:searchObj.pageSize"></el-pagination>
      <table-pagination-number :pageSize="searchObj.pageSize"
                               :queryParams="searchObj"
                               @loadTableData="getDataList"></table-pagination-number>
    </div>
    <custom-dialog :title="'活动预览'"
                   width="1200px"
                   :visible.sync="isShowActivityDetailsPopup"
                   :before-close="hiddenActivityDetailsPopup">
      <activities-preview :dataInfo="selectActivityInfo"
                          :isShowPlay="false"
                          @closePopup="hiddenActivityDetailsPopup"></activities-preview>
    </custom-dialog>
  </div>
</template>
<script>
import templateItem from './template-item';
import activitiesPreview from '../../../recruit-student-show/user-activities/activities-preview';
import tablePaginationNumber from '../../../common/table-pagination-number'; // table 分页
import inputSearchContainBtn from '../../../report/common/input-search-contain-btn';
import { MessageSearchActivity,AbortActivity } from '../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        PublishStatus:'-1', //-1 全部， 0未发布，1已发布
        startTime: '',
        endTime: '',
        TypeKey:'5' //活动类型 5-优惠券招生;6-引流套餐招生;7-拼团招生;8-阶梯团招生9-员工分销招生10-家长分销招生
      },
      total: 0,
      templateList:[
      ],
      defaultSort: { prop: '', order: 'ascending' },
      coverInfo:null,
      selectActivityInfo:null,
      oneClick:true,
         isShowActivityDetailsPopup: false,
      
    };
  },
  components: {
    inputSearchContainBtn,templateItem,tablePaginationNumber,activitiesPreview
  },
  props: {
    seletedPlayMethodInfo:Object
  },
  created () {
    console.log(this.toSearchObj, 'this.toSearchObj');
     this.searchObj.TypeKey = this.$route.query.TypeKey || 1;
    this.getDataList()
    this.$bus.on(this, 'updateTemplatelist', data => {
      this.getDataList()
    });
  },
  watch:{

  },
  computed: {
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    },
    vTemplateList () {
      let newArr = [];
      this.templateList.forEach((o, index) => {
        this.$set(o, 'isActive', false);
        if (!this.searchObj.searchText && this.searchObj.pageIndex == 0 && this.searchObj.SceneKey == 0 && this.searchObj.AgenciesKey == 0) {
          if (index < 5) {
            this.$set(o, 'isHot', true);
          }
        } else {
          this.$set(o, 'isHot', false);
        }
        newArr.push(o);
      });
      return newArr;
    },
    vPageIndex: {
      get () {
        return this.searchObj.pageIndex + 1;
      },
      set (n, o) {
      }
    },
  },
  mounted () {
    // this.getDataList();
  },
  methods: {
    // 打开活动
    showActivityDetailsPopup (item) {
      this.selectActivityInfo = item;
      this.isShowActivityDetailsPopup = true;
    },
     hiddenActivityDetailsPopup () {
      this.$bus.emit('closeEditorDialog');
      this.isShowActivityDetailsPopup = false;
    },
    // 招生状况
    openActivitiesStatisticsList (item) {
       if(this.$utils.CheckOpenSettingPower(this.$store.getters.SaaSClientInfo.OpenModule,7)){
              if (this.$utils.CheckModulePower(this.vRolePowerList, 55)) {
                this.$router.push({
                  name: 'activityMonitoringPlatform',
                  query: {
                    couponID: item.id
                  }
                });
              } else {
                this.$utils.CheckModulePowerToTips(55);
              }

          }else{
            this.$utils.CheckOpenSettingToTips(7);
          }
     
      // this.$emit('openActivitiesStatisticsList',this.dataInfo)
    },
     // 打开编辑器
    openIshowEditor (item) {
      // table 按钮点击
      if (this.oneClick) {
        this.oneClick = false;
        // this.seletedPlayMethodInfo = item;
        // this.seletedPlayMethodInfo.id=this.coverInfo.id
        console.log("this.seletedPlayMethodInfo.id",this.seletedPlayMethodInfo.id)
        let moduleInfo = {
          name: '活动制作-方案设置',
          moduleName: 'ishowEditorDialog',
          routerName: this.$route.name,
          data: { dataInfo: this.seletedPlayMethodInfo, coverInfo: this.coverInfo,activityInfo:item },
          callBack: { afterSuccess: () => {
            this.getDataList()
          }
          }
        };
        this.$emit('openFactoryDialog', moduleInfo);
        // bus.emit('openCustomDialog', moduleInfo);
        setTimeout(() => {
          this.oneClick = true;
        }, 1000);
        // this.$nextTick(o => {

        // });
      }
    },
    // 选中模板
    seletedTemplateAfter (item,type) {
      this.templateList.forEach(o => {
        if (item.id == o.id) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      console.log(type,'seletedTemplateAfter')
      this.coverInfo = item;
      switch (type) {
        case 'view':    //查看
          console.log(item,'查看')
          this.showActivityDetailsPopup(item)
          // this.newAddmoduleName = 'classroom-setting';
          break;
        case 'edit':  //修改
            console.log(item,'修改')
            this.openIshowEditor(item)
          // this.newAddmoduleName = 'squad-class-Setting';
          break;
        case 'stop':    //终止.
          console.log(item,'终止')
          layer.confirm('请问是否终止该活动', {
              btn: [
                {
                  name: '确认',
                  callBack:  ()=> {
                    this.stopItem(item);
                    layer.close();
                  }
                }
              ]
            });
          // this.newAddmoduleName = 'squad-class-Setting';
          break;
        case 'go':    //招生战况
        console.log(item,'招生战况')
          this.openActivitiesStatisticsList(item)
          // this.newAddmoduleName = 'squad-class-Setting';
          break;
        case 'make':   //继续制作
         console.log(item,'继续制作')
         this.openIshowEditor(item)
          // this.newAddmoduleName = 'squad-class-Setting';
          break;
        default:
          break;
      }
    },
    // 终止活动
    stopItem(item){
      AbortActivity(item.id).then(result=>{
        console.log("AbortActivity",result.data)
        this.getDataList()
      }).catch(error=>{
        console.log('AbortActivityError',error)
      })
    },
     // 跳转到第几页
    handleCurrentChange (val) {
      this.searchObj.pageIndex = val - 1;
      this.getDataList(this.searchObj);
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 新增活动
    addActivity (callBack) {
      this.openIshowEditor()
      console.log('新增活动');
    },
    getDataList (queryParams) {
      let fn = MessageSearchActivity;
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj, '22222');
      fn(this.searchObj).then(result => {
        this.total = result.data.Total;
      
        this.templateList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },

  }
};
</script>
<style>
.hover {
  cursor: pointer;
}
</style>