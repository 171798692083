<template>
  <div>
    <div class="index_JXT_homework_title"
         @click.stop="doNewHeadBrowseButClick">
      <div class="dynamic_content_date"
           v-if="contentLeft">
        <div class="date_day">{{vDateDayView}}</div>
        <div class="date_month">
          <span class="month_number"
                :style="{'padding-left: 0.1rem':vDateMonthView>10}">{{vDateMonthView}}</span><span>月</span>
        </div>
      </div>
      <div>
        <div class="body_title_box flex"
             v-if="contentTop">
          <div class="body_title_text">
            {{dataInfo.Title}}
          </div>
        </div>
        <div class="body_arrange_date"
             v-if="contentBottom">
          <span class="body_arrange_name">{{vAuthor}}</span>
          <span>{{dispatchType}} {{vPublishTime}}</span>
        </div>
      </div>
    </div>

    <div v-if="(vAuthor == '我' || IsAllSchool != 2 ) && headBtnType"
         @click.stop="doHeadBrowseButClick">
      <div class="inform_alter_btn"
           v-if="headBtnType=='btn'"></div>
      <div class="inform_next_icon"
           v-else-if="headBtnType=='icon'"> </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    dataInfo: {
      type: Object,
      default: function () {
        return {};
      }
    },
    IsAllSchool: {
      type: Number,
      default: 2
    }, // 是否切换全校模式，模式 1-全校;2-普通
    headBtnType: {
      type: String,
      default: ''
    }, // 显示按钮类型
    dispatchType: {
      type: String,
      default: '布置于'
    }, // 显示发文类型
    contentLeft: {
      type: Boolean,
      default: true
    },
    contentTop: {
      type: Boolean,
      default: true
    },
    contentBottom: {
      type: Boolean,
      default: true
    }

  },
  computed: {
    vDateMonthView () {
      console.log(this.dataInfo, 'this.dataInfo');
      if (this.dataInfo.ClassTime) {
        let month = this.dataInfo.ClassTime.slice(5, 7);
        month = Number(month) >= 10 ? Number(month) : this.dataInfo.ClassTime.slice(6, 7);
        return month;
      } else if (this.dataInfo.PublishTime) {
        let month = this.dataInfo.PublishTime.slice(5, 7);
        month = Number(month) >= 10 ? Number(month) : this.dataInfo.PublishTime.slice(6, 7);
        return month;
      } else {
        return '';
      }
    },
    vDateDayView () {
      if (this.dataInfo.ClassTime) {
        let day = this.dataInfo.ClassTime.slice(8, 10);
        return day;
      } else if (this.dataInfo.PublishTime) {
        let day = this.dataInfo.PublishTime.slice(8, 10);
        // day = Number(day) > 10 ? Number(day) : this.dataInfo.PublishTime.slice(9, 10);
        return day;
      } else {
        return '';
      }
    },
    vPublishTime () {
      if (this.dataInfo.PublishTime) {
        const month = this.dataInfo.PublishTime.slice(5, 7);
        const day = this.dataInfo.PublishTime.slice(8, 10);
        const time = this.dataInfo.PublishTime.slice(11, 16);
        return month + '-' + day + ' ' + time;
      } else {
        return '';
      }
    },
    vAuthor () {
      console.log(this.$store.getters, this.IsAllSchool, '普通模式');
      if (this.IsAllSchool == 2) { // -普通模式
        if (this.$store.getters.token && this.dataInfo.Author == this.$store.getters.token.UserName) {
          return '我';
        } else {
          return this.dataInfo.Author;
        }
      } else {
        return this.dataInfo.Author;
      }
    }
  },
  created () {
  },
  methods: {
    // 头部按钮
    doHeadBrowseButClick () {
      if (this.headBtnType) {
        this.$emit('doHeadBrowseButClick');
      }
    },
    doNewHeadBrowseButClick () {
      if (this.headBtnType && this.headBtnType == 'icon') {
        this.$emit('doHeadBrowseButClick');
      }
    }
  }
};
</script>

<style>
</style>