<template>
  <div class="detail">
    <div class="name"><span>{{student.name}}</span>{{student.tel}}</div>
    <div class="remark">排课备注：{{student.remark}}</div>
    <div class="formbox">
      <div class="text">选择日期</div>
      <el-select :value="search.TimeTypeNum" @change="selectDateChange">
        <el-option :label="'近30天内'" :value="30"></el-option>
        <el-option :label="'近60天内'" :value="60"></el-option>
        <el-option :label="'近90天内'" :value="90"></el-option>
      </el-select>
      <div class="text">考勤状态</div>
      <el-select :value="search.AttenceStatusKey" @change="selectStateChange">
        <el-option :label="'全部'" :value="''"></el-option>
        <el-option :label="'未考勤'" :value="2"></el-option>
        <el-option :label="'出勤'" :value="3"></el-option>
        <el-option :label="'请假'" :value="4"></el-option>
        <el-option :label="'旷课'" :value="5"></el-option>
      </el-select>
      <div class="text">班级</div>
      <el-select :value="search.GradeKey" @change="selectClassChange">
        <el-option :label="'全部'" :value="''"></el-option>
        <el-option v-for="i in classList" :key="i.OLAPKey" :label="i.MainDemoName" :value="i.OLAPKey"></el-option>
      </el-select>
      <a class="btn btn_1" @click="searchClick">搜索</a>
      <a class="btn btn_2" @click="resetClick">清除条件</a>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list class="summarizing_list" :tableData="tableData" :totalNum="totalNum" :PageCount="PageCount" :tableColumns="tableColumns" :queryParams="search" :footerContent="vFooterContent" @loadTableData="getList"></table-list>
    </div>
  </div>
</template>

<script>
import {SearchClass,GetPotentialStudentdetailsList} from '../../../API/workbench';
import tableList from '../../common/table-list/index';
export default {
  data () {
    return {
      classList:[],
      search:{
        StudentKey:this.student.key,
        TimeTypeNum:30,
        GradeKey:'',
        AttenceStatusKey:'',
        pageIndex:0,
        pageSize:10
      },
      tableData:[],
      totalNum:0,
      PageCount:0,
      tableColumns:[
        {
          label: '日期',
          prop: 'ClassTime',
          width: 150,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '周几',
          prop: 'WeekKeyValue',
          width: 80,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '时间',
          prop: 'ClassTimeName',
          width: 150,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '班级',
          prop: 'GradeClassKeyValue',
          width: 250,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '老师',
          prop: 'MTeacherKeyValue',
          width: 150,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '课程',
          prop: 'CourseNameKeyValue',
          width: 250,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '考勤结果',
          prop: 'AttenceStatusKeyValue',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item',
          extend: {
            setClassName: (rowData) => {
              let key = Number(rowData.AttenceStatusKey);
              if (key==2 || key==5) {
                return 'font_red';
              } else if (key==4) {
                return 'font_yellow';
              } else if (key==3) {
                return 'font_green';
              } else {
                return '';
              }
            }
          }
        }
      ]
    };
  },
  components: {
    tableList
  },
  props: {
    student: Object
  },
  created () {
    this.search.TimeTypeNum = this.student.grade;
    SearchClass().then(result => {
      if (result.code == 0) {
        this.classList = result.data;
      } else {
        console.log(result.msg);
      }
    }).catch(error => {
      console.log(error);
    });
  },
  mounted () {
    this.getList();
  },
  computed: {
    vFooterContent () {
      return '共 ' + this.totalNum + ' 条数据';
    }
  },
  methods: {
    selectDateChange (val) {
      this.search.TimeTypeNum = val;
      this.search.pageIndex = 0;
      this.getList();
    },
    selectStateChange (val) {
      this.search.AttenceStatusKey = val;
      this.search.pageIndex = 0;
      this.getList();
    },
    selectClassChange (val) {
      this.search.GradeKey = val;
      this.search.pageIndex = 0;
      this.getList();
    },
    searchClick () {
      this.search.pageIndex = 0;
      this.getList();
    },
    resetClick () {
      this.search.TimeTypeNum = this.student.grade;
      this.search.AttenceStatusKey = '';
      this.search.GradeKey = '';
      this.search.pageIndex = 0;
      this.getList();
    },
    getList () {
      let that = this;
      GetPotentialStudentdetailsList(that.search).then(result => {
        if (result.code == 0) {
          that.totalNum = result.data.Total;
          that.PageCount = result.data.PageCount;
          that.tableData = result.data.PageDataList;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
};
</script>

<style scoped>
.detail{padding:20px;}
.detail .name{line-height:30px;font-size:12px;}
.detail .name span{font-size:18px;font-weight:bold;margin-right:10px;}
.detail .remark{line-height:30px;font-size:14px;color:#999999;}
.detail .formbox{padding:10px 0;}
.detail .formbox .text{display:inline-block;height:36px;line-height:36px;font-size:14px;color:#666666;margin-right:10px;}
.detail .formbox .el-select{display:inline-block;width:150px;height:36px;margin-right:20px;}
.detail .formbox .el-select >>> input.el-input__inner{border:1px solid #ececec;}
.detail .formbox .btn{display:inline-block;height:36px;line-height:36px;font-size:14px;border-radius:4px;box-sizing:border-box;margin-right:10px;cursor:pointer;}
.detail .formbox .btn.btn_1{color:#FFFFFF;background-color:#2D98D9;border:1px solid #2886BF;padding:0 20px;}
.detail .formbox .btn.btn_2{color:#333333;background-color:#FFFFFF;border:1px solid #ECECEC;padding:0 10px;}
.detail .course_statistics_table_box{padding:0;}
</style>