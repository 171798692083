<template>
  <div class="payment_statistics_bottom_list">
    <span class="list_name"
          :class="vTypeClass">{{item.CollectAccontKeyValue}}
      <span>:</span>
    </span>

    <span class="list_number">{{vTotalAmount}}</span>
    <span class="list_text"
          style="min-width:170px;padding-right:20px">
      <span>收款</span>
      <span v-if="vInCome&&Number(vInCome.TotalNum)>0">
        ({{vInCome.TotalNum}}笔 | {{$utils.setMoneyformatToParseInt(vInCome.TotalAmount)}}元)
      </span>
      <span v-else>无</span>
    </span>
    <span>
      <span>支出</span>
      <span v-if="vPayment&&Number(vPayment.TotalNum)>0">
        ({{vPayment.TotalNum}}笔 | {{$utils.setMoneyformatToParseInt(vPayment.TotalAmount)}}元)
      </span>
      <span v-else>无</span>
    </span>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  computed: {
    vTypeClass () {
      if (this.item.CollectAccontKeyValue == '微信') {
        return 'type_WeChat';
      } else if (this.item.CollectAccontKeyValue == '支付宝') {
        return 'type_Alipay';
      } else if (this.item.CollectAccontKeyValue == '现金') {
        return 'type_money';
      } else if (this.item.AccountTypeKey == 6) {
        return 'type_card';
      } else {
        return '';
      }
    },
    vTotalAmount () {
      let num = 0;
      this.item.child.forEach(o => {
        if (o.IncomePayTypeKey == 1) {
          num += Number(o.TotalAmount);
        } else {
          num += -Number(o.TotalAmount);
        }
      });
      return (num > 0 ? '+' : '') + this.$utils.setMoneyformatToParseInt(num) + '元';
    },
    // 付款
    vPayment () {
      let locaItem = this.item.child.find(o => {
        return o.IncomePayTypeKey == 2;
      });
      console.log(locaItem, 'vPayment');
      return locaItem;
    },
    // 收款
    vInCome () {
      let locaItem = this.item.child.find(o => {
        return o.IncomePayTypeKey == 1;
      });
      console.log(locaItem, 'vInCome');
      return locaItem;
    }
  }
};
</script>
