<template>
  <div class="publish_score_ul">
    <div class="score_list"
         v-for="(item,index) in vCurrency"
         :key="index"></div>
    <!-- <div class="score_list"></div>
    <div class="score_list"></div>
    <div class="score_list"></div>
    <div class="score_list"></div> -->
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object,
    vTeacher: String
  },
  computed: {
    vCurrency () {
      console.log(this.dataInfo, 'vScoreList');
      if (this.dataInfo.Currency > 5) {
        return 5;
      } else {
        return Number(this.dataInfo.Currency);
      }
    }
  }
};
</script>
<style scoped>
.publish_score_ul {
  padding-top: 4px;
  padding-left: 0px !important;
}
.score_list {
  margin-right: 2px !important;
}
</style>