<template>
  <div class="absence">
    <div v-if="tableObj.NewDate.length>0">
      <div class="year_name"> {{ yearName }}年</div>
      <div class="block clearfix"
           v-for="i in tableObj.NewDate"
           :key="i.ScheduleCourseKey"
           @click="blockClick(i)">
        <div class="date">
          <div class="day"><span>{{i.WeekKeyValue}}</span>{{i.absenteeismTimeDay}}</div>
          <div class="time">{{i.ClassTimeName}}</div>
        </div>
        <div class="name">
          <div>{{i.CourseNameKeyValue}}</div>
          <span>{{i.GradeClassKeyValue}} - {{i.MTeacherKeyValue}}</span>
        </div>
        <div class="mark"
             :class="i.AttenceStatusKey==5 ? 'red' : ''">
          <div v-if="i.AttenceStatusKey==4">请假</div>
          <div v-if="i.AttenceStatusKey==5">旷课</div>
          <span v-if="i.ClassCount==0">{{i.ClassCount}} 课时</span>
          <span v-else>-{{i.ClassCount}} 课时</span>
        </div>
        <div class="check"
             :class="i.ScheduleCourseKey==checked.absenceKey ? 'cur' : ''"></div>
      </div>
    </div>

    <div v-if="tableObj.OldDate.length>0">
      <div class="year_name"> {{ yearName-1 }}年</div>
      <div class="block clearfix"
           v-for="i in tableObj.OldDate"
           :key="i.ScheduleCourseKey"
           @click="blockClick(i)">
        <div class="date">
          <div class="day"><span>{{i.WeekKeyValue}}</span>{{i.absenteeismTimeDay}}</div>
          <div class="time">{{i.ClassTimeName}}</div>
        </div>
        <div class="name">
          <div>{{i.CourseNameKeyValue}}</div>
          <span>{{i.GradeClassKeyValue}} - {{i.MTeacherKeyValue}}</span>
        </div>
        <div class="mark"
             :class="i.AttenceStatusKey==5 ? 'red' : ''">
          <div v-if="i.AttenceStatusKey==4">请假</div>
          <div v-if="i.AttenceStatusKey==5">旷课</div>
          <span v-if="i.ClassCount==0">{{i.ClassCount}} 课时</span>
          <span v-else>-{{i.ClassCount}} 课时</span>
        </div>
        <div class="check"
             :class="i.ScheduleCourseKey==checked.absenceKey ? 'cur' : ''"></div>
      </div>
    </div>
    <div class="nodata"
         v-if="!tableObj.NewDate.length && !tableObj.OldDate.length">没有缺课记录</div>
    <save-cancel-btn-group :cancelBtnText="'取消'"
                           :isSingleSubmit="true"
                           :fixedBtn="true"
                           @confirmClick="submit"
                           @cancelClick="cancel"></save-cancel-btn-group>
  </div>
</template>

<script>
import { GetAbsenceListByStudent } from '../../../../API/workbench';
export default {
  data () {
    return {
      tableObj: { NewDate: [], OldDate: [] },
      yearName: new Date().getFullYear(),
      checked: {
        absenceKey: '', // 缺课id
        absenceCourseDay: '', // 缺课日期，只传日
        absenceCourseWeek: '', // 缺课日期，周几
        absenceCourseTime: '', // 缺课上课时段
        absenceCourseKey: '', // 缺课课程id
        absenceCourseName: '', // 缺课课程名
        absenceCourseClass: '', // 缺课班级名
        absenceCourseTeacher: '', // 缺课老师名
        absenceCourseType: '', // 缺课原因
        absenceCourseHour: '' // 消耗课时数
      }
    };
  },
  components: {
  },
  props: {
    searchobj: Object
  },
  created () {
  },
  mounted () {
    new Date();
    this.getList();
  },
  methods: {
    blockClick (row) {
      this.checked.absenceKey = row.ScheduleCourseKey;
      this.checked.absenceCourseDay = row.absenteeismTimeDay;
      this.checked.absenceCourseWeek = row.WeekKeyValue;
      this.checked.absenceCourseTime = row.ClassTimeName;
      this.checked.absenceCourseKey = row.CourseNameKey;
      this.checked.absenceCourseName = row.CourseNameKeyValue;
      this.checked.absenceCourseClass = row.GradeClassKeyValue;
      this.checked.absenceCourseTeacher = row.MTeacherKeyValue;
      this.checked.absenceCourseType = row.AttenceStatusKey;
      this.checked.absenceCourseHour = row.ClassCount;
    },
    getList () {
      let that = this;
      console.log(this.yearName, 'yearName');
      GetAbsenceListByStudent(that.searchobj.studentKey, '').then(result => {
        if (result.code == 0) {
          let list = result.data;
          for (let i = 0; i < list.length; i++) {
            list[i].absenteeismTimeDay = list[i].ClassTime.split(' ')[0].split('-')[1] + '月' + list[i].ClassTime.split(' ')[0].split('-')[2] + '日';
            list[i].ClassCount = Number(list[i].ClassCount);
            if (this.yearName == list[i].ClassTime.split(' ')[0].split('-')[0]) {
              that.tableObj.NewDate.push(list[i]);
            }
            if ((this.yearName - 1) == list[i].ClassTime.split(' ')[0].split('-')[0]) {
              that.tableObj.OldDate.push(list[i]);
            }
          }

          // that.tableObj = list;
        } else {
          console.log(result.msg);
        }
        this.$forceUpdate();
      }).catch(error => {
        console.log(error);
      });
    },
    submit () {
      this.$emit('afterSuccess', this.checked);
    },
    cancel () {
      this.$emit('cancelClick');
    }
  }
};
</script>

<style scoped>
.absence {
  padding: 20px 0;
  max-height: 600px;
  overflow-y: auto;
}
.absence .block {
  position: relative;
  margin: 0 20px 20px;
  cursor: pointer;
}
.absence .block .date {
  float: left;
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 2px solid #333333;
  margin-right: 10px;
}
.absence .block .date .day {
  height: 26px;
  line-height: 26px;
  font-size: 14px;
  border-bottom: 2px solid #333333;
}
.absence .block .date .day span {
  display: inline-block;
  color: #ffffff;
  background-color: #333333;
  padding: 0 5px;
  margin-right: 5px;
}
.absence .block .date .time {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  padding: 0 5px;
  text-align: center;
}
.absence .block .name {
  float: left;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}
.absence .block .name div {
  height: 26px;
  line-height: 26px;
  font-size: 16px;
  font-weight: bold;
}
.absence .block .name span {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
}
.absence .block .mark {
  float: left;
  width: 100px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #f59a23;
}
.absence .block .mark div {
  height: 26px;
  line-height: 26px;
  font-size: 14px;
  color: #ffffff;
  background-color: #f59a23;
}
.absence .block .mark span {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  color: #f59a23;
}
.absence .block .mark.red {
  border: 2px solid #d9001b;
}
.absence .block .mark.red div {
  background-color: #d9001b;
}
.absence .block .mark.red span {
  color: #d9001b;
}
.absence .block .check {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #2e86c1;
  right: 0;
  top: 50%;
  margin-top: -10px;
}
.absence .block .check.cur:after {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  background-color: #2e86c1;
  left: 50%;
  margin-left: -5px;
  top: 50%;
  margin-top: -5px;
}
.absence .nodata {
  font-size: 16px;
  text-align: center;
  padding-top: 40px;
}
.year_name {
  font-size: 24px;
  margin-left: 20px;
  margin-bottom: 10px;
}
</style>