<template>
  <div class="select_xu_checkbox_group_xuan list_credit_type"
       :class="{opt:dataInfo.ID==seletedTemplateInfo.ID}"
       @click="clickItem">
    <div class="checkbox_view">
      <span>{{dataInfo.TemplateName}}</span>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  computed: {

  },
  props: {
    dataInfo: Object,
    seletedTemplateInfo: Object
  },
  methods: {
    clickItem (val) {
      this.$emit('clickItem', this.dataInfo)
    },

  }
}
</script>

<style>
.select_xu_checkbox_group .select_xu_checkbox_group_xuan {
  border-bottom: 1px solid #ececec;
}

.select_xu_checkbox_group_xuan {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding-left: 35px;
  position: relative;
}

.select_xu_checkbox_group_xuan::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -10px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #c2c2c2;
}

.select_xu_checkbox_group_xuan.opt::after {
  border-color: #ff6c67;
  background: #ff6c67 url('../../../../../../public/image/tick_white@2x.png')
    no-repeat center center;
  background-size: 10px;
}

.select_xu_checkbox_group_xuan .checkbox_view {
  width: 88px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkbox_view {
  width: 70px;
  display: inline-block;
  vertical-align: middle;
}
</style>
