import { render, staticRenderFns } from "./pending-processing.vue?vue&type=template&id=623a04fa&scoped=true"
import script from "./pending-processing.vue?vue&type=script&lang=js"
export * from "./pending-processing.vue?vue&type=script&lang=js"
import style0 from "./pending-processing.vue?vue&type=style&index=0&id=623a04fa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "623a04fa",
  null
  
)

export default component.exports