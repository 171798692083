<template>
  <div>
    <div v-if="isShowAddBtn">
      <btn-add-select :addTitleName="addTitleName"
                      :addmoduleName="'register-customer'"
                      :addmoduleTitleName="'add'"
                      :addmoduleKey='48'
                      @doAddSuccessEvent="doAddSuccessEvent"></btn-add-select>
    </div>

    <div class="form_info_detail form_info_serach_box">
      <!-- 搜索选择层 -->
      <input-search-contain-btn v-model="studenSearchText"
                                :placeholder="'请输入姓名、姓名首字母、手机号'"
                                @onSearchEvent="searchTextSearch"></input-search-contain-btn>
      <!-- 搜索结果 -->
      <div class="search_result">
        <div class="search_result_content search_result_content_for_student"
             @scroll="scrollTOP">
          <div class="search_result_list"
               @scroll="scrollTOP">
            <!-- active 选中当前 -->
            <single-selection-item v-for="item in vStudentList"
                                   :key="item.OLAPKey"
                                   :isTestStudentItem="item"
                                   @selectedItem="selectedItem"></single-selection-item>
            <div v-if="loading"
                 class="students_loading all_list_bottom">
              <span class="el-icon-loading"></span>
              <span class="loading_style">加载中...</span>

            </div>
            <div v-else-if="vStudentList.length == 0">
              <div class="has_arrange_course">
                <div class="has_arrange_course_content"
                     style="padding:0">
                  <div class="has_arrange_course_list ">
                    <div class=" has_arrange_course_nodata single_shadow ">
                      <span>亲，当前没有任何数据哦~</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="noMore"
                 class="all_list_bottom">
              <span class="complete">已显示全部</span>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>

</template>
<script>

import singleSelectionItem from './single-selection-item';
import { messageSearchPageForStudent } from '../../API/workbench';
export default {
  name: 'singleSelectionList',
  // switchItem--判断进入验证学生的开关，1为不验证
  // IsConfirmKey--0:显示全部学生， 1：过滤掉待验证的学生,默认为显示全部
  // props: ['isShowSearchInput', 'existsStudentList', 'switchItem', 'IsConfirmKey', 'studentInfo', 'type'],
  props: {
    switchItem: {
      type: [String, Number],
      default: 0
    },
    IsConfirmKey: {
      type: [String, Number],
      default: 0
    },
    studentInfo: {
      type: Object,
      default: () => { }
    },
    addTitleName: {
      type: String,
      default: '新增'
    },
    CustStatusKey: { // 是否在校 0-否; 1-是; 空- 全部
      type: [String, Number],
      default: ''
    },
    isShowAddBtn: {
      type: Boolean,
      default: true
    }

  },
  data () {
    return {
      // 测试学生列表 数据
      studentList: [],
      studenSearchText: '',
      pageSize: 20,     // 每次搜索人数.
      pageIndex: 0,     // 每次查询页数
      noMore: false,
      loading: false
    };
  },
  components: {
    singleSelectionItem
  },
  computed: {
    vStudentList () {
      let newArr = [];
      this.studentList.forEach(o => {
        if (this.studentInfo && this.studentInfo.OLAPKey == o.OLAPKey) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
        newArr.push(o);
      });
      return newArr;
    }
  },
  watch: {
    studenSearchText () {
      // console.log(this.studenSearchText, 'studenSearchText');
      if (this.studenSearchText == '') {
        this.studentList.length = 0;
        this.pageSize = 20;
        this.pageIndex = 0;
        this.noMore = false;
        this.getStudentList();
      }
    }
  },
  created () {
    this.getStudentList();
  },
  methods: {
    // 新增回调
    doAddSuccessEvent (result) {
      console.log(result, '新增回调');
      this.$emit('selectedItem', result);
    },
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;
      let HeightView = ETarget.scrollHeight - ETarget.clientHeight;
      if (parseInt(ETarget.scrollTop) == parseInt(HeightView) && !this.noMore) {
        this.noMore = false;
        this.getStudentList();
      }
    },
    // // 根据搜索条件获取客户.
    // textSeachergetStudentList () {
    //   messageSearchPageForStudent(0, 0, this.studenSearchText, '', '', this.CustStatusKey).then(result => {
    //     this.studentList = [];
    //     if (this.IsConfirmKey == 1) { // 只显示已验证的学生
    //       result.data.PageDataList.forEach(obj => {
    //         if (obj.IsConfirmKey == 1) {
    //           this.studentList.push(obj);
    //         }
    //       });
    //     } else {
    //       result.data.PageDataList.forEach(obj => {
    //         obj.isActive = false;
    //         obj.SexKey = obj.SexKey || '2';
    //         this.studentList.push(obj);
    //       });
    //     }
    //     this.pageIndex++;
    //   }).catch(error => {
    //     console.log(error);
    //   });
    // },
    searchTextSearch () {
      this.studenSearchText = this.studenSearchText.replace(/ /g, '');
      this.pageIndex = 0;
      this.getStudentList();
    },
    getStudentList () {
      if (!this.noMore) {
        this.loading = true;
      }
      console.log(this.IsConfirmKey, 'this.IsConfirmKey');
      messageSearchPageForStudent(this.pageIndex, this.pageSize, this.studenSearchText, '', '', this.CustStatusKey).then(result => {
        if (this.pageIndex == 0) {
          this.studentList = [];
        }
        result.data.PageDataList.forEach(o => {
          o.isActive = false;
          let hasFind = false;
          if (this.studentList.length > 0) {
            hasFind = this.studentList.findIndex(p => p.OLAPKey == o.OLAPKey) >= 0;
          }
          if (!hasFind) {
            if (this.IsConfirmKey == 1) { // 只显示已验证的学生
              if (o.IsConfirmKey == 1) {
                this.studentList.push(o);
              }
            } else {
              this.studentList.push(o);
            }
          }
        });
        if (result.data.PageDataList.length < this.pageSize) {
          this.noMore = true;
          this.loading = false;
        }
        this.pageIndex++;
      }).catch(error => {
        this.loading = false;
        console.log(error);
      });
    },
    selectedItem (item) {
      this.studentList.forEach(obj => {
        obj.isActive = false;
        if (obj.OLAPKey == item.OLAPKey) {
          obj.isActive = true;
        }
      });
      this.verifyStudent(item);
      this.scrollBackTop();
    },
    // 验证学生
    verifyStudent (item) {
      console.log(item, 'item');
      if (item.IsConfirmKey == 0 && this.switchItem !== 1) { // switchItem 这里可做开关，1则关闭验证提示
        layer.alert('该学生待验证确认，请在学生档案中进行确认操作。');
      } else {
        this.studenSearchText = '';
        this.$emit('selectedItem', item);
      }
    },
    scrollBackTop () {
      // document.getElementsByClassName('search_result_content_for_student')[0].scrollTop = 0;
      // if (document.getElementsByClassName('search_result_content_for_student')[1]) {
      //   document.getElementsByClassName('search_result_content_for_student')[1].scrollTop = 0;
      // }
      document.getElementsByClassName('search_result_list')[0].scrollTop = 0;
      if (document.getElementsByClassName('search_result_list')[1]) {
        document.getElementsByClassName('search_result_list')[1].scrollTop = 0;
      }
    },
    clearSearText () {
      this.studenSearchText = '';
    }
  }

};
</script>

