<template>
  <div>
    <div class="payment_statistics_bottom_select flex">
      <seleted-option :searchObj="searchObj"
                      @changeSearchObj="updateData"></seleted-option>
    </div>
    <div class="course_statistics_table_search flex course_statistics_title">
      <div class="">
        <div class="">
          <date-range ref="monthPicker"
                      :fromTitle="'选择日期'"
                      :searchObj="searchObj"
                      :minDate="vMinDate"
                      :maxDate="vMaxDate"
                      :isShowHistory="isShowHistory"
                      @changeDate="changeSaveDate"></date-range>
        </div>
        <div class="change_history_data"
             @click.stop="historyBtn(historyName)">{{ historyName }}</div>
      </div>
      <div class="course_statistics_search">
        <!-- <div class="mendian_backstage_search">
          <div class="search_box">
            <div class="search_box_content">
                <div class="el-input el-input--small el-input--suffix is-focus">
                  <input type="text"
                         v-model="searchObj.searchText"
                         autocomplete="off"
                         placeholder="请输入姓名、姓名首字母、手机号"
                         class="el-input__inner">
                </div>
            </div>                                           
          </div>
          <div class="btn_hover_bg_blue search_btn "
               @click.stop="search">搜索</div>
          <div class="btn_hover_white search_clear"
               @click.stop="clearSearchCondition">清除条件</div>
        </div> -->

        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入姓名、姓名首字母、手机号'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin-right:20px;width:auto;padding: 0 10px;"
               @click.stop="openAttenDetail">课消明细</div>
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class="course_class_table course_statistics_table_box table_list_content">
      <list class="summarizing_list"
            :key="tableKey"
            :tableData="vdataReportList"
            :totalNum="totalNum"
            :PageCount="PageCount"
            :tableColumns="tableCourseColumns"
            :isSearchText="false"
            :isNeedInnerScroll="false"
            :defaultSort="defaultSort"
            :queryParams="searchObj"
            :vFooterTotal="vFooterTotal"
            @tableBtnClick="doAfterTableBtnClick"
            @loadTableData="getDataList"></list>
    </div>
    <!-- 安排考勤 -->
    <!-- <div>
      <attendance-arrange ref="arrangeDialog"
                          :formTitle="'安排考勤'"
                          @updateCourse="search"></attendance-arrange>
    </div> -->
  </div>
</template>
<script>
import list from '../../class-spend-statistics-analyze/components/table';
import attendanceArrange from '../../../pop-up-menu/exception-handle/attendance-arrange-form/attendance-arrange';
import seletedOption from './seleted-option';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import { MessageSearchClassAmountForDate, MessageSearchClassHistoryAmountForDate } from '../../../../API/workbench';
export default {
  data () {
    return {
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      seletedCourseInfo: {},
      studentDetail: {},
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        GradeKey: '',
        GradeKeyValue: '',
        CourseKey: '',
        CourseKeyValue: '',
        TeacherKey: '',
        TeacherKeyValue: '',
        startTime: '',
        endTime: '',
        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'serial',
          decimalPlace: 0,
          className: 'table_index_gray'
        },
        {
          label: '上课时间',
          prop: 'ClassTime',
          minWidth: 132,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text',
          decimalPlace: 0
        },
        {
          label: '课程',
          prop: 'CourseNameKeyValue',
          minWidth: 182,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text',
          decimalPlace: 0
        },
        {
          label: '班级',
          prop: 'GradeClassKeyValue',
          minWidth: 170,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text',
          decimalPlace: 0,
          eventName: '3'
        },
        {
          label: '老师',
          prop: 'MTeacherKeyValue',
          minWidth: 126,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text',
          decimalPlace: 0,
          eventName: '4'
        },
        {
          label: '考勤人数',
          prop: '',
          minWidth: 240,
          sortable: false,
          align: 'center',
          isShow: true,
          headerCount: true,
          className: '',
          decimalPlace: 0,
          childColumn: [
            {
              label: '签到',
              prop: 'ArrivedNumCount',
              width: 80,
              sortable: 'custom',
              align: 'center',
              isShow: true,
              type: 'lineNumber',
              className: 'table_header_dual',
              noPlusminus: true,
              decimalPlace: 0,
              eventName: '5'
            },
            {
              label: '请假',
              prop: 'LeaveNum',
              width: 80,
              sortable: 'custom',
              align: 'center',
              isShow: true,
              noPlusminus: true,
              type: 'lineNumber',
              className: 'table_header_dual',
              decimalPlace: 0,
              eventName: '6'
            },
            {
              label: '旷课',
              prop: 'TruantNum',
              sortable: 'custom',
              width: 80,
              align: 'center',
              isShow: this.$utils.getSaaSClientSet(59) > 0,
              noPlusminus: true,
              type: 'lineNumber',
              className: 'table_header_dual',
              decimalPlace: 0,
              eventName: '7'
            }
          ]
        },
        {
          label: '学生课时',
          prop: 'ClassHourCount',
          sortable: 'custom',
          minWidth: 80,
          align: 'left',
          isShow: true,
          type: 'lineNumber',
          noPlusminus: true,
          decimalPlace: 0,
          eventName: '7'
        },
        {
          label: '课消金额',
          prop: 'DeductAmount',
          sortable: 'custom',
          minWidth: 90,
          align: 'left',
          isShow: true,
          type: 'lineNumber',
          noPlusminus: true,
          decimalPlace: 0,
          eventName: '7'
        },
        {
          label: '操作',
          prop: '',
          width: '120',
          sortable: false,
          align: 'center',
          tableBtnGroup: [
            {
              name: '详情',
              eventName: this.$initJson.baseFunctionBtnName.red,
              Stutas: 'StutasKey',
              className: () => {
                return 'btn_light_blue';
              },
              ModulePowerKey: 51
            },
            {
              name: '明细',
              eventName: this.$initJson.baseFunctionBtnName.detail,
              extend: {
                click: (rowData) => {
                  this.seletedItemToAttenDetail(rowData);
                }
              },
              className: () => {
                return 'btn_light_blue';
              }
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        }
      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    list,
    inputSearchContainBtn,
    seletedOption,
    attendanceArrange
  },
  props: {
    toSearchObj: {
      type: Object
    }
  },
  created () {
    console.log(this.toSearchObj, 'this.toSearchObj', new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear(), 'toSearchObj', new Date(this.toSearchObj.startTime).getFullYear());
    if (this.toSearchObj.startTime) {
      this.searchObj.startTime = this.toSearchObj.startTime;
      this.searchObj.endTime = this.toSearchObj.endTime;
    } else {
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
    }
    if (new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31' >= this.toSearchObj.startTime) {
      this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      this.isShowHistory = true;
    } else {
      this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      this.isShowHistory = false;
    }
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    },
    vtableCourseColumns () {

    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.showClassTime = this.$utils.parseJson(o.ClassTime);
        o.ClassTime = o.ClassTime.split('-')[1] + '-' + o.ClassTime.split('-')[2];
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text;
      if (this.totalAmount > 0) {
        text = '+' + this.$utils.setMoneyformatToParseInt(this.totalAmount);
        text = ',合计' + text + '元';
      } else {
        text = ',合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      }
      return text;
    }
  },
  mounted () {
    this.$bus.on(this, 'wsAttendanceOrArrange', (data) => {
      console.log('触发wsAttendanceOrArrange');
      this.getDataList();
    });
    this.getDataList();
  },
  methods: {
    historyBtn (name) {
      console.log(this.isShowHistory, 'isShowHistory');
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.searchObj.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.searchObj.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isShowHistory = true;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
        this.isShowHistory = false;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.getDataList();
      this.$refs.monthPicker.changeHistoryTime(this.searchObj.startTime, this.searchObj.endTime);
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.GradeKey = '';
      this.searchObj.CourseKey = '';
      this.searchObj.TeacherKey = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (item, date) {
      console.log('searchData', date);
      this.searchObj.TeacherKey = item.TeacherKey;
      this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(date).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      let seletedYearMonth = Date.new(date).getFullYear() + ' ' + (Date.new(date).getMonth() + 1);
      let nowYearMonth = Date.new().getFullYear() + ' ' + (Date.new().getMonth() + 1);
      if (seletedYearMonth != nowYearMonth) {
        this.searchObj.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new(date)), 'yyyy-MM-dd');
      } else {
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
      }
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = '';
        if (this.isShowHistory) {
          fn = MessageSearchClassHistoryAmountForDate;
        } else {
          fn = MessageSearchClassAmountForDate;
        }
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '课时课消',
          clist: [
            { title: '上课时间', cName: 'ClassTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课程', cName: 'CourseNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '班级', cName: 'GradeClassKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '老师', cName: 'MTeacherKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '签到人数', cName: 'ArrivedNumCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '请假人数', cName: 'LeaveNum', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '旷课人数', cName: 'TruantNum', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生课时', cName: 'ClassHourCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课消金额', cName: 'DeductAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      let fn = '';
      if (this.isShowHistory) {
        fn = MessageSearchClassHistoryAmountForDate;
      } else {
        fn = MessageSearchClassAmountForDate;
      }
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj);
      fn(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.red:
          this.doAttendanceDetailsClick(rowData);
          break;
        case this.$initJson.baseFunctionBtnName.detail:
          this.seletedItemToAttenDetail(rowData);
          break;
        // seletedItemToAttenDetail
        default:
          break;
      }
    },
    seletedItemToAttenDetail (item) {
      console.log(item, 'seletedItemToAttenDetail');
      let dataInfo = {
        CourseKey: item.CourseNameKey,
        CourseKeyValue: item.CourseNameKeyValue,
        GradeKey: item.GradeClassKey,
        GradeKeyValue: item.GradeClassKeyValue,
        TeacherKey: item.MTeacherKey,
        TeacherKeyValue: item.MTeacherKeyValue,
        ScheduleCourseKey: item.OLAPKey || 0,
        startTime: item.showClassTime.substring(0, 10),
        endTime: item.showClassTime.substring(0, 10),
        isShowHistory: this.isShowHistory
      };
      let moduleInfo = {
        name: '课消明细',
        moduleName: 'attenDetailReport',
        routerName: this.$route.name,
        data: { dataInfo: dataInfo }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    },
    // 打开课消明细
    openAttenDetail () {
      let dataInfo = {
        startTime: this.searchObj.startTime,
        endTime: this.searchObj.endTime
      };
      let moduleInfo = {
        name: '课消明细',
        moduleName: 'attenDetailReport',
        routerName: this.$route.name,
        data: { dataInfo: dataInfo }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    },
    doAttendanceDetailsClick (item) {
      // if (this.mIsShowArrangeDialog(item)) {
      // this.$refs.arrangeDialog.isAttendanceArrangeShow = true;
      // this.$refs.arrangeDialog.getCourseInfo(item.OLAPKey);
      // }
      let moduleInfo = {
        name: '课程详情',
        moduleName: 'attendanceArrange',
        routerName: this.$route.name,
        data: { scheduleCourseKey: item.OLAPKey, isAlter: false }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    }

  }
};
</script>
<style scoped>
.change_history_data {
  position: absolute;
  right: 230px;
  margin-top: -36px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
</style>