<template>
  <div class="from_list"
       :class="{ form_info_required: required }">
    <div class="from_list_title"
         v-if="formTitle">
      {{formTitle}}
    </div>
    <div class="from_list_content">
      <div class="from_list_input pr"
           :class="inputClass">
        <input v-if="isNumber"
               type="number"
               :value="vInputValue"
               :placeholder="formPlaceholder?formPlaceholder:'请输入'"
               :readonly="readonly"
               :class="readonly?'font_gray':''"
               @click="clickEvent($event)"
               @input="inputEvent($event.target.value,$event)"
               @change="changeEvent($event.target.value,$event)"
                />
               <!-- onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');" -->
        <input v-else-if="isPhone"
               type="text"
               :value="value"
               :placeholder="formPlaceholder?formPlaceholder:'请输入'"
               :readonly="readonly"
               :class="readonly?'font_gray':''"
               @click="clickEvent($event)"
               @input="updateVal($event.target.value)"
               @change="changeVal($event.target.value)"
               @blur="testTelNo"
               onkeyup="this.value=this.value.replace(/[^0-9-.]/g,'');" />
        <input v-else
               type="text"
               :value="value"
               :placeholder="formPlaceholder?formPlaceholder:'请输入'"
               :readonly="readonly"
               :class="readonly?'font_gray':''"
               @click="clickEvent($event)"
               @input="updateVal($event.target.value)"
               @change="changeVal($event.target.value)" />
        <div class="from_list_unit"
             v-if="unintTitle">{{unintTitle}}</div>
      </div>
      <slot></slot>
      <div class="font_gray"
           v-if="tipsTitle">{{tipsTitle}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      inputValue: '',
      defaultValue: 0, // 默认值,
      defaultDecimalPlaces: 2 // 默认值
    };
  },
  props: {
    max: [Number, String], // 最大值
    min: [Number, String], // 最小值
    decimalPlaces: Number, // 保留的小数位数
    maxLength: {
      type: Number,
      default: 12
    },
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    formTitle: String,
    unintTitle: String,       // 单位
    tipsTitle: String,        // 提示.
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    formPlaceholder: String, // 默认文本，可选，如不提供，则默认显示title,
    inputClass: String,
    isPhone: {
      type: Boolean,
      default: false
    },
    isNumber: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    vInputValue: {
      get () {
        if (this.inputValue == '0' || this.inputValue) {
          return this.inputValue;
        } else {
          return '';
        }
      },
      set (n, o) {
        this.inputValue = n;
      }
    },
    vDecimalPlaces () {
      if (this.decimalPlaces == 0) {
        return 0;
      } else {
        return this.decimalPlaces || this.defaultDecimalPlaces;
      }
    }
  },
  watch: {
    // value (c, o) {
    //   if (this.isNumber) {
    //     this.setInputValue(c);
    //   }
    // },
    value: {
      handler (c, o) {
        if (c) {
          let locaIndex = c.toString().indexOf('.');
          if (this.vDecimalPlaces == 0) {
            this.setInputValue(c);
          } else if (locaIndex) {
            let str = c.toString().substring(locaIndex);

            if (this.isNumber && c && str != '.0') {
              this.setInputValue(c);
            }
          }
        }

        // if (this.isNumber && c && str != '0') {
        //   this.setInputValue(c);
        // }
      },
      deep: true,
      immediate: true
    }

  },
  methods: {
    updateVal (val) {
      this.$emit('input', val);
    },
    changeVal (val) {
      this.$emit('change', val);
    },
    showEldilog () {
      this.$emit('showEldilog');
    },
    testTelNo () {
      if (this.vPlaceholder == '请输入客户名称') {
        this.$emit('chgInputType', true); // 有发生改变就触发.
      }
    },
    setInputValue (v) {
      if (v > 0) {
        this.inputValue = this.$utils.mMoneyType(v, this.vDecimalPlaces);

        this.inputValue = this.inputValue;
        this.$emit('input', this.inputValue);
      }
      // this.$emit('change', this.inputValue);
    },
    inputEvent (val, e) {
      if (val.length > this.maxLength) {
        e.target.value = this.inputValue;
        return false;
      }
      val = val > Number(this.max) ? Number(this.max) : val;
      val = val < Number(this.min) ? Number(this.min) : val;
      this.inputValue = val;
      // e.target.value = this.inputValue;

      this.$emit('input', this.inputValue);
      this.$emit('change', this.inputValue);
      if (!this.$utils.isIntermediateValue(this.inputValue)) {
        this.$emit('inputChange', Number(this.inputValue));
      }
    },
    changeEvent (val, e) {
      if (val.length > this.maxLength) {
        e.target.value = this.inputValue;
        return false;
      }
      if (val != '') {
        this.inputValue = this.$utils.mMoneyType(val, this.vDecimalPlaces);
        this.inputValue = this.inputValue > Number(this.max) ? Number(this.max) : this.inputValue;
        this.inputValue = this.inputValue < Number(this.min) ? Number(this.min) : this.inputValue;
      }
      e.target.value = this.inputValue;
      if (this.inputValue == '') {
        this.$emit('change', this.inputValue);
        this.$emit('input', this.inputValue);
        this.$emit('inputChange', this.inputValue);
        this.$emit('changeEvent', this.inputValue);
      } else {
        this.$emit('change', Number(this.inputValue));
        this.$emit('input', Number(this.inputValue));
        this.$emit('inputChange', Number(this.inputValue));
        this.$emit('changeEvent', Number(this.inputValue));
      }
    },
    clickEvent (event) {
      if (this.isNumber) {
        event.currentTarget.select();
      }
      this.$emit('seletedItem');
    }
  }
};
</script>

