<template>
  <div class="general_summarizing_student payment_statistics_view height_type all_view">
    <div class="course_statistics_table_search course_statistics_title pr course_statistics_search">
      <input-search-contain-btn v-model="paramsForQuery.searchText"
                                :placeholder="'请输入版本号'"
                                @clearSearch="clearSearchCondition"
                                @onSearchEvent="searchClick"></input-search-contain-btn>
    </div>
    <div class="course_statistics_table_box table_list_content follow_clue_list prepare_publish_template">
      <table-list class="summarizing_list"
                  ref="tableListRef"
                  :tableData="tableData"
                  :tableColumns="tableColumns"
                  :totalNum="totalNum"
                  :queryParams="paramsForQuery"
                  :footerContent="vFooterTotal"
                  @loadTableData="loadTableData"></table-list>
    </div>
    <custom-dialog :title="'预发布版本信息'"
                   :visible.sync="isShowPreparePublishTemplatePopup"
                   :before-close="closePreparePublishTemplateDialog">
      <prepare-publish-template-detail :miniProgramData="curSelectedTemplateData"
                                       @closeClick="closePreparePublishTemplateDialog"></prepare-publish-template-detail>
    </custom-dialog>

    <dialog-factory ref="miniProgramTemplateVersionList"
                    :key="'miniProgramTemplateVersionList'"
                    :routerName="'miniProgramTemplateVersionList'"></dialog-factory>
                    
    <dialog-factory-common ref="commonMiniProgramTemplateVersionList"></dialog-factory-common>
  </div>
</template>

<script>
import tableList from '../../common/table-list/index';
import inputSearchContainBtn from '../../report/common/input-search-contain-btn';
import preparePublishTemplateDetail from '../prepare-publish-template-detail/index';
import dialogFactory from '../../common/dialog-factory';

import { SearchPageTemplate, ReleasePrepareMiniProgram, ReleaseMiniProgram } from '../../../API/workbench';
export default {
  components: {
    tableList,
    inputSearchContainBtn,
    preparePublishTemplateDetail,
    dialogFactory
  },
  data () {
    return {
      paramsForQuery: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: ''
      },
      tableColumns: [
        {
          label: '版本号',
          prop: 'VersionNO',
          width: 150,
          type: 'text-item'
        },
        {
          label: '状态',
          prop: 'StatusKeyValue',
          width: 100,
          type: 'text-item'
        },
        {
          label: '描述',
          prop: 'Describe',
          width: 200,
          type: 'text-item'
        },
        {
          label: '添加时间',
          prop: 'CreationTime',
          width: 100,
          type: 'date-item'
        },
        {
          label: '未上传数量',
          prop: 'UnUploadCount',
          width: 100,
          type: 'number-item'
        },
        {
          label: '已上传数量',
          prop: 'HasUploadCount',
          width: 100,
          type: 'number-item'
        },
        {
          label: '审核中数量',
          prop: 'InAuditCount',
          width: 100,
          type: 'number-item'
        },
        {
          label: '审核拒绝数量',
          prop: 'AuditRefusedCount',
          width: 120,
          type: 'number-item'
        },
        {
          label: '待发布数量',
          prop: 'ToPublishCount',
          width: 100,
          type: 'number-item'
        },
        {
          label: '已发布数量',
          prop: 'HasPublishCount',
          width: 100,
          type: 'number-item'
        },
        {
          label: '应发布数量',
          prop: 'PublishCount',
          width: 100,
          type: 'number-item'
        },
        {
          label: '来源小程序',
          prop: 'MiniProgramName',
          width: 100,
          type: 'text-item'
        },
        {
          label: '小程序AppID',
          prop: 'MiniProgramAppID',
          width: 180,
          type: 'text-item'
        }, ,
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          width: 150,
          extend: {
            tableBtnGroup: [// 1-未验证;2-预发行;3-现行;4-历史
              {
                name: '预发布',
                extend: {
                  isHide: (rowData) => {
                    return rowData.StatusKey == 3;
                  },
                  click: (rowData) => { this.preparePublish(rowData); }
                }
              },
              {
                name: '现行发布', // 仅状态为现行的可操作
                extend: {
                  isHide: (rowData) => {
                    return rowData.StatusKey != 3;
                  },
                  click: (rowData) => {
                    this.xxPublish(rowData);
                  }

                }
              }

            ]
          }
        }
      ],
      tableData: [],
      totalNum: 0,
      curSelectedTemplateData: {}, // 当前选中的模板信息
      isShowPreparePublishTemplatePopup: false // 是否显示预发布版本信息
    };
  },
  computed: {
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    }
  },
  created () {
    this.loadTableData();
  },
  mounted () {
    this.resgisterBusEvent();
  },
  methods: {
    // 加载表单数据
    loadTableData () {
      SearchPageTemplate(this.paramsForQuery).then(result => {
        this.tableData = result.data.PageDataList;
        this.totalNum = result.data.Total;
      });
    },
    // 查询
    searchClick () {
      this.loadTableData();
    },
    // 清除查询条件
    clearSearchCondition () {
      this.paramsForQuery.pageIndex = 0;
      this.paramsForQuery.searchText = '';
      this.searchClick();
    },
    // 预发布
    preparePublish (data) {
      this.curSelectedTemplateData = data;
      this.isShowPreparePublishTemplatePopup = true;
      console.log('beforehandPublish', data);
    },
    // 现行发布
    xxPublish (data) {
      this.$router.push({ name: 'miniProgramPublish', query: { MiniProgramAppID: data.MiniProgramAppID } });
    },
    // 关闭预发布模板信息
    closePreparePublishTemplateDialog () {
      this.isShowPreparePublishTemplatePopup = false;
    },
    resgisterBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'miniProgramTemplateVersionList') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'miniProgramTemplateVersionList') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.miniProgramTemplateVersionList) {
        this.$refs.miniProgramTemplateVersionList.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.commonMiniProgramTemplateVersionList) {
        this.$refs.commonMiniProgramTemplateVersionList.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    }
  }
};
</script>

<style>
</style>