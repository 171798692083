<template>
  <div class="growth_time_right"
       style="max-width: max-content;">
    <div v-show="courseList.length > 0"
         class="growth_time_right_title_box swiper-container">
      <div class="growth_time_right_title swiper-wrapper">
        <courseItem v-for="(item,key) in courseList"
                    :key="key"
                    :item="item"
                    @seletedItem="seletedCourseItem"></courseItem>
      </div>
    </div>
    <div class="growth_time_right_content"
         v-if="courseList.length>0">
      <div class="growth_time_add_btn btn_hover_bg_blue"
           @click.stop="addReport">添加报告</div>
      <div class="growth_time_ul_box"
           @scroll="scrollTOP">
        <div class="growth_time_ul">

          <growth-time-item v-for="(item,key) in growthTimeList"
                            :key="key"
                            :dataInfo="item"
                            :courseInfo="courseInfo"
                            :studentInfo="studentInfo"
                            @delItem="delItem">
          </growth-time-item>
          <!-- <div class="growth_time_list">
            <div class="growth_time_list_date">
              <div class="date_month">3月</div>
              <div class="date_day">26</div>
            </div>
            <div class="growth_time_list_content">
              <div class="growth_time_list_title">
                可乐老师给小飞侠签到了
              </div>
            </div>
          </div> -->

        </div>
        <div v-if="growthTimeList.length==0">
          <div class="monitoring_content_nodata"
               style="margin-top: 200px;">
            <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
          </div>
        </div>
        <div class="no_list_text"
             v-else-if="noMore&&growthTimeList.length>0">
          没有更多的内容啦
        </div>
      </div>

    </div>
    <div v-if="courseList.length==0">
      <div class="monitoring_content_nodata"
           style="margin-top: 300px;">
        <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
      </div>
    </div>

    <custom-dialog title="添加成长报告"
                   width="768px"
                   :visible.sync="showAddReport"
                   :before-close="closeAddReport">
      <add-report :studentInfo="studentInfo"
                  :courseInfo="courseInfo"
                  @afterSuccess="afterSuccess"
                  @close="closeAddReport"></add-report>
    </custom-dialog>
  </div>
</template>
<script>
import courseItem from './components/course-item';
import addReport from './components/add-report';
import growthTimeItem from './components/growth-time-item';
import { MessageSearchStudentGrowthTime, GetStudentGrowthTimeCourse, DeleteStudentGrowthTime } from '../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        startTime: '',
        endTime: ''
      },
      courseInfo: null,
      courseList: [],
      growthTimeList: [],
      showAddReport: false, // 显示添加报告.
      seletedItem: null,
      noMore: false,
      isLoadEnd: false,
      swiper: null
    };
  },
  created () {

  },
  mounted () {
    this.swiper = new Swiper('.growth_time_right_title_box', {
      slidesPerView: 'auto',
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true // 修改swiper的父元素时，自动初始化swiper
    });
    if (this.studentInfo) {
      this.getAllCourse();
    }
  },
  watch: {
    studentInfo (c, o) {
      this.searchObj.OrderByTime = '';
      this.getAllCourse();
    }
  },
  components: {
    courseItem, growthTimeItem, addReport
  },
  props: {
    studentInfo: Object
  },
  methods: {
    // 获取学生所有成长时光数据
    getStudentGrowthTime (courseKey, callBack) {
      this.isLoadEnd = true;
      console.log(this.growthTimeList, 'this.getStudentGrowthTime');
      MessageSearchStudentGrowthTime(this.searchObj, courseKey, this.studentInfo.OLAPKey).then(result => {
        // this.growthTimeList = result.data;
        result.data.forEach(o => {
          this.growthTimeList.push(o);
        });
        if (result.data.length < 10) {
          this.noMore = true;
        } else {
          this.searchObj.OrderByTime = result.data[result.data.length - 1].OrderByTime;
          this.noMore = false;
        }
        this.isLoadEnd = false;
        if (callBack) {
          callBack();
        }
        console.log('getStudentGrowthTime', result.data);
      }).catch(error => {
        console.log('errorgetStudentGrowthTime', error);
      });
    },
    // 获取学生所有课程
    getAllCourse () {
      GetStudentGrowthTimeCourse(this.studentInfo.OLAPKey).then(result => {
        this.courseList = result.data;
        this.courseList.forEach(o => {
          this.$set(o, 'isActive', false);
        });

        if (this.courseList.length > 0) {
          this.courseList[0].isActive = true;
          this.courseInfo = this.courseList[0];
          this.growthTimeList = [];
          this.searchObj.OrderByTime = '';
          this.getStudentGrowthTime(this.courseInfo.CourseNameKey, () => {
            if (this.swiper) {
              console.log(this.swiper, 'swiper');
              this.swiper.updateSlides();
              this.swiper.slideTo(0);
            }
          });
        }
        console.log('GetStudentGrowthTimeCourse', result.data);
      }).catch(error => {
        console.log('errorGetStudentGrowthTimeCourse', error);
      });
    },
    seletedCourseItem (item) {
      console.log(item, 'seletedCourseItem');
      this.courseList.forEach(o => {
        if (o.CourseNameKey == item.CourseNameKey) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      this.courseInfo = item;
      this.growthTimeList = [];
      this.isLoadEnd = false;
      this.noMore = false;
      this.searchObj.OrderByTime = '';
      this.getStudentGrowthTime(this.courseInfo.CourseNameKey);
    },
    addReport () {
      console.log('addReport');
      // this.seletedItem = item;
      this.showAddReport = true;
    },
    // 删除单个,
    delItem (item) {
      console.log('delItem', item);
      layer.confirm('请问是否删除此学习成长报告?', {
        btn: [{
          name: '确认',
          callBack: () => {
            DeleteStudentGrowthTime(item.ID).then(result => {
              let locaIndex = this.growthTimeList.findIndex(o => {
                return o.ID == item.ID;
              });
              if (locaIndex != -1) {
                this.studentInfo.GrowCount = Number(this.studentInfo.GrowCount) - 1;
                this.growthTimeList.splice(locaIndex, 1);
              }
            });
            layer.close();
          }
        }
        ]
      });
    },
    closeAddReport () {
      this.showAddReport = false;
    },
    afterSuccess () {
      this.growthTimeList = [];
      this.searchObj.OrderByTime = '';
      this.isLoadEnd = false;
      this.noMore = false;
      this.getStudentGrowthTime(this.courseInfo.CourseNameKey);
    },
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;
      if (
        (ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight ||
          ETarget.scrollTop + 1 ==
          ETarget.scrollHeight - ETarget.clientHeight) &&
        !this.noMore &&
        !this.isLoadEnd
      ) {
        this.getStudentGrowthTime(this.courseInfo.CourseNameKey);
      }
    }
    // deleteStudentGrowthTime(item){

    // }

  }
};
</script>