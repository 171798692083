<template>
  <!-- 线索录入 -->
  <div>
    <div class="form_info_edit form_info_list">
      <input-number :formTitle="'投资预算最低 (万)'"
                    :required="true"
                    :readonly="isReadonly"
                    v-model="dataJson.StratBudgetAmount"></input-number>
      <input-number :formTitle="'投资预算最高 (万)'"
                    :required="true"
                    :readonly="isReadonly"
                    v-model="dataJson.EndBudgetAmount"></input-number>

    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group v-if="!isReadonly"
                           :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dataJson: {
        StratBudgetAmount: '',
        EndBudgetAmount: '',
        InvestBudgetRemark: ''
      }

    };
  },
  props: {
    dataInfo: Object, // 职务信息
    isReadonly: Boolean// 是否只读
  },
  created () {
    this.dataJson = this.$utils.parseJson(this.dataInfo);
  },
  computed: {

  },
  methods: {
    // 确认按钮
    confirmClick (callBack) {
      let flag = false;
      if (!this.dataJson.StratBudgetAmount) {
        layer.alert('清输入投资预算最低数');
        flag = true;
      }
      if (!this.dataJson.EndBudgetAmount) {
        layer.alert('清输入投资预算最高数');
        flag = true;
      }
      if (this.dataJson.StratBudgetAmount > this.dataJson.EndBudgetAmount) {
        layer.alert('投资预算最低不能高于投资预算最高');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.$emit('afterSuccess', this.dataJson);
    },
    cancelClick () {
      this.$emit('cancelClick');
    }

  }
};
</script>

<style>
</style>
