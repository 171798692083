<template>
  <div>
    <!-- 课程选择 -->
    <div class="form_item form_info_line"
         :class="{form_info_required:required}">
      <div class="form_info_field">
        <span>{{formTitle}}</span>
        <span v-if="helpDescription">
          <input-form-tip :tips='helpDescription'></input-form-tip>
        </span>
      </div>
      <div class="form_info_value form_info_select"
           v-if="!isReadOnly"
           @click.stop="showEditDialog">
        <input type="text"
               readonly
               :value="value.label"
               :placeholder="vPlaceholder" />
        <!-- <div class="class_table_courseChoose_icon el-icon-arrow-right"></div> -->
      </div>
      <div v-else
           class="input_readonly form_info_value"
           style="padding-right:26px">{{value.label}}</div>
    </div>

    <custom-dialog :title="vDialogTitleName"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowMianBody"
                   :before-close="doShowPopupHideClick">
      <btn-add-select :addTitleName="'新增'"
                      :addmoduleName="'course-name-setting'"
                      :addmoduleTitleName="'add'"
                      :addmoduleKey='45'
                      @doAddSuccessEvent="doAddCoursSuccessEvent"></btn-add-select>
      <single-selection-list ref="studentListDialog"
                             isShowSearchInput="false"
                             :dataSourceList="dataSource"
                             :selectedKey="value.id"
                             :required="required"
                             @onSelectedItem="confirmEdit"></single-selection-list>
    </custom-dialog>
  </div>
</template>
<script>
export default {
  name: 'singleSelectTreeDialog',
  data () {
    return {
      isShowMianBody: false
    };
  },
  props: {
    formTitle: {
      // 标题，必填
      type: String,
      required: true
    },
    dataSource: Array, // 数据源
    value: {
      // 值，双向绑定，如不想双向绑定，则自行实现input事件
      type: Object,
      required: true,
      // 对象或数组默认值必须从一个工厂函数获取
      default: function () {
        return {
          label: '',
          id: '',
          typeKey: '',
          UnitPrice: 0
        };
      }
    },
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    formPlaceholder: String, // 默认文本，可选，如不提供，则默认显示title
    isReadOnly: {
      type: Boolean,
      default: false
    },
    helpDescription: String // 表单说明提示
  },
  computed: {
    vPlaceholder () {
      // 默认文本，如不提供，则默认显示title
      if (this.formPlaceholder) {
        return this.formPlaceholder;
      } else {
        if (this.required) {
          return '必选';
        } else {
          return '可选';
        }
      }
    },
    vDialogTitleName () {
      return '选择' + this.formTitle;
    }
  },
  methods: {
    doAddCoursSuccessEvent (result) {     // 新增的时候
      console.log(result, '新增回调');
      // 接收选中值.
      result.id = result.OLAPKey;
      result.label = result.MainDemoName;
      this.confirmEdit(result);
      this.$emit('doAddCoursSuccessEvent', result);
    },
    // 关闭弹窗
    doShowPopupHideClick () {
      this.isShowMianBody = false;
    },
    // 点击时，显示选择层
    showEditDialog () {
      this.isShowMianBody = true;
    },
    confirmEdit (val) {
      console.log(val, 'confirmEdit');
      this.value.id = val.OLAPKey;
      this.value.label = val.MainDemoName;
      this.value.UnitPrice = val.UnitPrice;
      this.$emit('input', this.value);
      this.cancelEdit();
    },
    cancelEdit () {
      this.isShowMianBody = false;
    },
    loadSourceData () {
      this.$emit('loadSourceData');
    }
  }
};
</script>

