<template>
	<div class="homerwork_details_list" @click.stop="doWorkCorrectDetailsClick">
		<not-correct-edit-head :dataInfo="item"></not-correct-edit-head>
		<!-- 音频块 -->
		<audio-view v-if="vAudioShow" v-for="(item,key) in vAudioShow" :key="key" :item="item" :controls="false"></audio-view>
		<!-- 最多显示3张 -->
		<div class="article_details_img_ul">
			<!-- 视频块 -->
			<video-view v-if="vVideoList" :videoClass="'img_list'" v-for="(item,key) in vVideoList" :key="key" :videoInfo="item" :controls="false"></video-view>
			<!-- 图片块 -->
			<image-view v-if="vImgList" v-for="(item,key) in vImgList" :key="key" :imageUrl="item.AttachUrl"  :isViewImage="false"></image-view>
		</div>
		<div class="homerwork_details_list_bottom">
			{{$utils.replyTime(item.StudentSubHomeWork.CreateTime)}}
		</div>
	</div>
</template>

<script>
import notCorrectEditHead from '../common/not-correct-edit-head.vue';
import audioView from '../../../components/attach-box/audio-view.vue';
import videoView from '../../../components/attach-box/video-view.vue';
import imageView from '../../../components/attach-box/image-view.vue';
export default {
  components: {
    notCorrectEditHead,
    audioView,
    videoView,
    imageView
  },
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  computed: {
    // 视频列表（最多三个，优先级高于图片）
    vVideoList () {
      if (!this.item.StudentSubHomeWork) {
        return [];
      }
      let list = [];
      list = this.item.StudentSubHomeWork.AttachList.filter((obj) => {
        return obj.AttachTypeKey == 2 
      });
     return list;
    },
    // 图片数量，最多三个）
    vImgList () {
      if (!this.item.StudentSubHomeWork) {
        return '';
      }
      let list = [];
      list = this.item.StudentSubHomeWork.AttachList.filter((obj, index) => {
        return obj.AttachTypeKey == 1 && index < (3 - this.vVideoList.length);
      });
      if (list.length > 0) {
        return list;
      } else {
        return '';
      }
    },
    // 音频数量，有就取第一个
    vAudioShow () {
      if (!this.item.StudentSubHomeWork) {
        return '';
      }
      let list = [];
      list = this.item.StudentSubHomeWork.AttachList.filter(obj => {
        return obj.AttachTypeKey == 3;
      });
      if (list.length > 0) {
        return list;
      } else {
        return '';
      }
    }
  },
  methods: {
    doWorkCorrectDetailsClick () {
      this.$emit('doWorkCorrectDetailsClick', this.item);
    }
  }
};
</script>

<style>

</style>
