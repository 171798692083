<template>
  <div class="customer_management_from popup_height_from">
    <div class="form_info_list">
      <input-text :formTitle="'申请日期'"
                  :readonly="true"
                  :formPlaceholder="'请输入申请日期'"
                  class=""
                  v-model="dataInfo.ApplyTime"></input-text>
      <input-text :formTitle="'申请人'"
                  :readonly="true"
                  :formPlaceholder="'请输入申请人'"
                  class=""
                  v-model="dataInfo.ApplicantKeyValue"></input-text>
      <input-text :formTitle="'机构名称'"
                  :readonly="true"
                  :formPlaceholder="'请输入机构名称'"
                  class=""
                  v-model="dataInfo.SaaSClientNameKeyValue"></input-text>
      <input-text :formTitle="'联系人'"
                  :readonly="true"
                  :formPlaceholder="'请输入联系人'"
                  class=""
                  v-model="dataInfo.ContactsName"></input-text>

      <input-text :formTitle="'手机号'"
                  :readonly="true"
                  :formPlaceholder="'请输入手机号'"
                  class=""
                  v-model="dataInfo.MobileSupport"></input-text>

      <input-number v-if="Number(seletedItem.SaaSClientCount)>1"
                    :min="0"
                    :formTitle="'教务端口'"
                    :decimalPlaces="0"
                    :required="false"
                    v-model="dataInfo.AcademicPortCount"></input-number>

      <input-number v-if="Number(seletedItem.SaaSClientCount)>1"
                    :min="0"
                    :formTitle="'课件端口'"
                    :decimalPlaces="0"
                    :required="false"
                    v-model="dataInfo.CoursewarePortCount"></input-number>

      <input-text :formTitle="'申请原因'"
                  :readonly="vIsEdit"
                  :required="true"
                  :formPlaceholder="'请输入'"
                  class=""
                  v-model="dataInfo.ReasonScript"></input-text>
      <div class="form_info_edit">
        <input-dialog :formTitle="'数据库服务器'"
                      :required="false"
                      :readonly="vIsEdit"
                      :formPlaceholder="'请选择'"
                      v-model="DataBaseType.name"
                      @showEldilog="changeDatabaseType"></input-dialog>
      </div>

    </div>

    <!-- <div class="from_input_list">
      <div class="from_input_title form_info_required">
        菜单名称
      </div>
      <div class="from_list_select flex"
           style="align-items: center;">
        <el-radio v-model="dataInfo.type"
                  label="click">发送消息</el-radio>
        <el-radio v-model="dataInfo.type"
                  label="view">跳转网页</el-radio>
        <el-radio v-model="dataInfo.type"
                  label="miniprogram">跳转小程序</el-radio>
      </div>
    </div> -->
    <div v-if="!vIsEdit">
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submitAdd"
                             @cancelClick="doAfterClickCancelBtn"></save-cancel-btn-group>
    </div>
    <div v-else>
      <save-cancel-btn-group :cancelBtnText="'撤销'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             :btnText="'提交审核'"
                             @confirmClick="submitAudit"
                             @cancelClick="revocation"></save-cancel-btn-group>
    </div>
    <custom-dialog :title="'临时开户'"
                   class=" "
                   :visible.sync="showAuthCode"
                   :before-close="closeShowAuthCode">
      <auth-code :dataInfo="dataInfo"
                 @close="closeShowAuthCode"
                 @afterSuccess="afterSuccess"></auth-code>
    </custom-dialog>
    <custom-dialog :title="'选择数据库服务器'"
                   :visible.sync="isShowDatabaseServer"
                   :before-close="closeDatabaseServerPopuP">
      <database-type @close="closeDatabaseServerPopuP"
                     @UpdataDatabaseServer="UpdataDatabaseServer"
                     :mendianInfo='DataBaseType'></database-type>
    </custom-dialog>
  </div>
</template>
<script>
import authCode from './auth-code';
import databaseType from './database-type';
import { AddApply, GetApplyDetails, CancelApply, AuthorizationCodeAuditApply, GetDatabaseServeSaaSClientList } from '../../../../../API/workbench';
export default {
  data () {
    return {
      dataInfo: {
        'ApplyTime': this.$utils.formatDateToLine(Date.new()), // '申请时间',
        'ApplicantKey': '', // '申请人',
        'ApplicantKeyValue': '', // '申请人',
        'SaaSClientNameKey': '', // '机构Key',
        'SaaSClientNameKeyValue': '', // '机构',
        'SaaSClientStatusKey': '', // '机构状态0-未开通;1-临时开通;2-正式开通;3-关停',
        'SaaSClientStatusKeyValue': '', // '机构状态',
        'MobileSupport': '', // '手机号',
        'ContactsName': '', // '联系人',
        'ThreadKey': '', // '线索key',
        'TypeKey': '1', // '1-开户;2-付款;3-返介绍费;4-清数据',
        'TypeKeyValue': '开户', // '1-开户;2-付款;3-返介绍费;4-清数据',
        'CoursewarePortCount': '', // 课件端口
        'AcademicPortCount': 0, // 教务端口
        'ReasonScript': 0, // '申请原因',
        'DataBaseKey': 1, // 1默认服务器 3一般服务器
        'PayMent': null,
        //  {// 付款申请
        //   'PaymentTime': '', // '付款时间',
        //   'IsOpenAcademicKey': '', // '是否开通教务',
        //   'IsOpenWKTKey': '', // '是否开通WKT',
        //   'TypeKey': '', // '1-首付;2-续费;3-加功能;4-还尾款',
        //   'TypeKeyValue': '', // '1-首付;2-续费;3-加功能;4-还尾款',
        //   'ContractAmount': '', // '合同金额',
        //   'ActuallyAmount': '', // '实付金额',
        //   'BalanceAmount': '', // '尾款',
        //   'AccessorySupport': '', // '合同附件',
        //   'AmendScript': '', // '修改条款',
        //   'PayCutSupport': '', // '付款截图',
        //   'ExpireDateTime': '', // '门店有效期',
        //   'RenewCount': '' // '续费年数'
        // },
        'ClearSaaSClien': null,
        // {// 清数据申请
        //   'ClearScopeSupport': '', // '清理范围',
        //   'ClearSaaSClientKey': '', // '清除数据门店',
        //   'ClearSaaSClientKeyValue': '' // '清除数据门店'
        // },
        'CashBack': null
        // {// 返介绍费
        //   'CashSaaSClientKey': '', // '返现人门店',
        //   'CashSaaSClientKeyValue': '', // '返现人门店',
        //   'CashBackAmount': '', // '返现金额',
        //   'MobileSupport': '', // '返现人手机号',
        //   'CashBackKey': '', // '返现人',
        //   'CashBackKeyValue': '' // '返现人'
        // }
      },
      showAuthCode: false,
      isShowDatabaseServer: false,
      DataBaseType: {
        TypeKey: '', // 1默认服务器 3一般服务器
        name: '',
        id: ''
      }
    };
  },
  components: {
    authCode,
    databaseType
  },
  props: {
    seletedItem: Object
  },
  created () {
    if (this.seletedItem && this.seletedItem.TempAuditKey > 0) {
      console.log(this.seletedItem.TempAuditKey, 'this.seletedItem.TempAuditKey');
      this.getDetailInfo(this.seletedItem.TempAuditKey);
    } else {
      this.getGetDatabaseServeSaaSClientList(1);
      this.initAddData();
    }
    console.log(this.dataInfo, 'dataInfo');
  },
  computed: {
    vIsEdit () {
      if (this.seletedItem && this.seletedItem.TempAuditKey > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    getGetDatabaseServeSaaSClientList (id) {
      GetDatabaseServeSaaSClientList().then(result => {
        result.data.forEach(o => {
          if (o.id == Number(id)) {
            this.DataBaseType.name = o.name;
            this.DataBaseType.TypeKey = o.TypeKey;
            this.DataBaseType.id = o.id;
            console.log('GetDatabaseServeSaaSClientList', o, this.DataBaseType, this.dataInfo.DataBaseKey);
          }
        });
      });
    },
    UpdataDatabaseServer (type) {
      this.DataBaseType.name = type.name;
      this.DataBaseType.TypeKey = type.TypeKey;
      this.DataBaseType.id = type.id;
      this.dataInfo.DataBaseKey = type.id;
      this.isShowDatabaseServer = false;
      console.log(type, 'type', this.dataInfo.DataBaseKey);
    },
    // 打开选择服务器
    changeDatabaseType () {
      this.isShowDatabaseServer = true;
    },
    // 关闭数据库服务器
    closeDatabaseServerPopuP () {
      this.isShowDatabaseServer = false;
    },
    initAddData () {
      console.log(this.$store.getters.token, 'initAddData123');
      this.dataInfo.ApplicantKey = this.$store.getters.token.UserID;
      this.dataInfo.ApplicantKeyValue = this.$store.getters.token.UserName;
      this.dataInfo.SaaSClientNameKey = this.seletedItem.SaaSClientNameKey; // '机构Key',
      this.dataInfo.SaaSClientNameKeyValue = this.seletedItem.SaaSClientNameKeyValue; // '机构',
      this.dataInfo.MobileSupport = this.seletedItem.MobileSupport; // '手机号',
      this.dataInfo.ContactsName = this.seletedItem.ContactsName; // '联系人',
      this.dataInfo.ThreadKey = this.seletedItem.OLAPKey; // '线索key',
    },
    getDetailInfo (TableID) {
      GetApplyDetails(TableID).then(result => {
        console.log('GetApplyDetails', result.data);
        this.dataInfo = result.data;
        this.getGetDatabaseServeSaaSClientList(result.data.DataBaseKey);
        this.dataInfo.DataBaseKey = Number(result.data.DataBaseKey);
        console.log('GetApplyDetails', result.data, this.dataInfo);
      });
    },
    // 提交审核
    submitAudit (callback) {
      console.log('submit', this.dataInfo);
      this.showAuthCode = true;
      callback();
    },
    // 提交新增
    submitAdd (callback) {
      console.log('submit', this.dataInfo);
      let isPass = true;
      if (this.dataInfo.ReasonScript == '') {
        layer.alert('请输入原因');
        isPass = false;
      }
      if (!isPass) {
        if (callback) {
          callback();
        }
        return false;
      }
      AddApply(this.dataInfo).then(result => { // 任务1846
        layer.alert('新增成功');
        this.$emit('afterSuccess');
        this.doAfterClickCancelBtn();
        if (callback) {
          callback();
        }
      }).catch(error => {
        layer.alert(error.msg);
        if (callback) {
          callback();
        }
        console.log('errEditDefaultReply', error);
      });
    },
    // 撤销
    revocation () {
      layer.confirm('请问是否要撤销该申请', {
        btn: [{
          name: '确认',
          callBack: () => {
            CancelApply(this.dataInfo.TableID).then(result => {
              this.$emit('afterSuccess');
              this.doAfterClickCancelBtn();
            }).catch(error => {
              layer.alert(error.msg);
            });
            layer.close();
          }
        }
        ]
      });
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    },
    closeShowAuthCode () {
      this.showAuthCode = false;
    },
    afterSuccess () {
      this.showAuthCode = false;
      this.$nextTick(() => {
        this.$emit('afterSuccess');
        this.$emit('close');
      });
    }
  }
};
</script>
<style scoped>
.form_info_edit >>> .form_info_line .form_info_value input {
  color: #3498db;
}
</style>