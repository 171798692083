<template>
  <div>
    <div class="compile_activity_info">
      <div class="compile_activity_content">
        <div class="compile_activity_title">{{dataInfo.active_title}}</div>
        <div class="compile_activity_text">
          {{dataInfo.coupon_discribe}}
        </div>
        <div class="compile_activity_type">
          <div class="type_list"
               v-if="isShowPlay">
            <span class="font_gray">玩法类型：</span>
            <span>{{ vActivityType }} | {{dataInfo.title}}</span>
          </div>
          <div class="type_list">
            <span class="font_gray">使用模板：</span>
            <span>{{dataInfo.SceneKeyValue}} {{dataInfo.SceneKeyValue&&dataInfo.activityTitle?'|':''}} {{ dataInfo.activityTitle }}</span>
          </div>
        </div>
        <div class="type_time compile_activity_font">
          <span class="font_gray">活动上架时间：</span>
          <span>{{vputTime}}</span>
        </div>
        <div class="type_date compile_activity_font">
          <span class="font_gray">活动截止日期：</span>
          <span>{{dataInfo.activity_time_end}}</span>
        </div>
        <div class="type_number compile_activity_font">
          <span class="font_gray">参与人数：</span>
          <span>{{vActivityNmuber}}</span>
        </div>
      </div>
    </div>
    <div v-if="isActivityTimeEnd"
         class="compile_activity_btn btn_hover_bg_blue"
         @click="clickItem">编辑活动</div>
    <div class="compile_activity_QRcode">
      <div class="QRcode_box">
        <!-- <div id="activityQRcode"></div> -->
        <qrcode-vue :value="codeValue"
                    :size="codeValue?168:0"
                    level="L"
                    id="activityQRcode" />
        <a :href="vActivityURL"></a>
      </div>
      <div class="QRcode_text">微信扫码查看活动</div>
    </div>
  </div>
</template>
<script>
import { GetActivitiesPlayByID } from '../../../../API/workbench.js';
import QrcodeVue from 'qrcode.vue';
export default {
  data () {
    return {
      codeValue: ''
    };
  },
  computed: {
    CDNUrl () {
      return this.$store.getters.CDNURL;
    },
    isActivityTimeEnd () {
      let dateTime = Date.new(this.dataInfo.activity_time_end);
      dateTime = dateTime.setDate(dateTime.getDate());
      dateTime = dateTime + (3600000 * 24 - 1); // 加上有效期的时分秒
      if (Date.new().getTime() > dateTime || this.dataInfo.status == 0) {
        return true;
      } else {
        return true;
      }
    },
    vputTime () {
      if (this.dataInfo.put_time) {
        return this.dataInfo.put_time.substring(0, 10);
      } else {
        return '------';
      }
    },
    vAddTime () {
      if (this.dataInfo.add_time) {
        return this.$utils.formatDateToLine(this.dataInfo.add_time * 1000);
      } else {
        return '';
      }
    },
    vActivityDate () {
      if (this.dataInfo.time_start) {
        return this.dataInfo.activity_time_end;
      } else {
        return '';
      }
    },
    vActivityNmuber () {
      if (this.dataInfo.maxGetNum) {
        return this.dataInfo.total_get_num + '/' + this.dataInfo.maxGetNum + '（含虚拟' + this.dataInfo.VirtualNumber + '人）';
      } else {
        return '';
      }
    },
    vActivityURL () {
      let ENV = 'release'
      // if (process.env.NODE_ENV === 'development') {
      //   ENV = 'develop'
      // } else {
      //   if(window.zxConfig.Product_ENV==='product'){
      //     ENV = 'release'
      //   }else{
      //     ENV = 'trial'
      //   }
      // }
      let parms = 'targetSaaSClientID=' + this.$store.getters.SaaSClientInfo.OLAPKey + '&coupon_id=' + this.dataInfo.id + '&source_type=4&share_user_id=' + this.$store.getters.token.UserID + '&SaaSClientKey=' + this.$store.getters.SaaSClientInfo.OLAPKey + '&isMPopen=1'
      // let url='https://zxbeta.zhixuerj.com/mp/10419/develop?targetRouterParms=targetSaaSClientID%3D10017%26coupon_id%3D3061%26source_type%3D4%26share_user_id%3D32704%26SaaSClientKey%3D10017'
      // let url =this.$store.getters.SaaSClientInfo.MiniProgramUrl+'/'+ENV+'?targetRouterParms='+encodeURIComponent(parms);
      // let url = this.$store.getters.SaaSClientInfo.WktInfo.sec_website + '/do/shareCouponToGet.php?coupon_id=' + this.dataInfo.id + '&source_type=4&share_user_id=' + this.$store.getters.token.UserID + '&SaaSClientKey=' + this.$store.getters.SaaSClientInfo.OLAPKey;
      let url = this.$store.getters.SaaSClientInfo.WktInfo.sec_website + '/do/shareCouponToGet.php?coupon_id=' + this.dataInfo.id + '&source_type=4&share_user_id=' + this.$store.getters.token.UserID + '&SaaSClientKey=' + this.$store.getters.SaaSClientInfo.OLAPKey;
      console.log(url);
      this.createQRcode(url);
      return url;
    },
    vActivityType () {
      if (this.dataInfo.activityType == 1) {
        return '0元报名';
      } else if (this.dataInfo.activityType == 2) {
        return '线上付定金';
      } else if (this.dataInfo.activityType == 3) {
        return '线上全款';
      } else {
        return '';
      }
    }
  },
  props: {
    dataInfo: Object,
    isShowPlay: Boolean
  },
  components: {
    QrcodeVue
  },
  mounted () {
  },
  methods: {
    getActivitiesPlay (id, callback) {
      GetActivitiesPlayByID(id).then(res => {
        res.data.posterTemplateID = 0;
        if (callback) {
          callback(res.data);
        }
        console.log(res, 'GetActivitiesPlayByID');
      });
    },
    createQRcode (url) {
      if (document.getElementById('activityQRcode')) {
        // document.getElementById('activityQRcode').innerHTML = '';
        // let qrcode = new QRCode(document.getElementById('activityQRcode'), {
        //   correctLevel: QRCode.CorrectLevel.L // 容错级别，
        // });
        // qrcode.clear(); // 清除二维码
        // qrcode.makeCode(url);
        this.codeValue = url;
        document.getElementById('activityQRcode').title = '';
      }
    },
    clickItem () {
      this.$emit('closePopup');
      if (this.isShowPlay) {
        this.getActivitiesPlay(this.dataInfo.playID, (data) => {
          data.id = this.dataInfo.id;
          let moduleInfo = {
            name: this.dataInfo.title,
            moduleName: 'oldIshowEditorDialog',
            routerName: this.$route.name,
            data: { dataInfo: data, coverInfo: {} }
          };
          this.$bus.emit('openCustomDialog', moduleInfo);
        });
      } else {
        this.getActivitiesPlay(this.dataInfo.playID, (data) => {
          data.id = this.dataInfo.id;
          let moduleInfo = {
            name: this.dataInfo.title,
            moduleName: 'ishowEditorDialog',
            routerName: this.$route.name,
            data: { dataInfo: data, coverInfo: {}, activityInfo: this.dataInfo }
          };
          this.$bus.emit('openCustomDialog', moduleInfo);
        });
      }

    }
  }
};
</script>