<template>
  <div class="mendian_backstage_content general_summarizing_student">
    <div class="mendian_backstage_searchBox">
      <div class="mendian_backstage_searchBox_top flex">
        <date-option ref="openDate"
                     :fromTitle="'开户日期'"
                     @changeDate="changeOptenAcountDate"></date-option>
        <date-option ref="tutorshipDate"
                     :fromTitle="'辅导日期'"
                     @changeDate="changeTutorshipDate"></date-option>
        <date-option ref="saveDate"
                     :fromTitle="'留存日期'"
                     @changeDate="changeSaveDate"></date-option>
        <div class="mendian_backstage_date">
          <div class="date_title">使用状态</div>
          <div class="type_select">
            <div class="el-select">
              <div class="el-input el-input--small el-input--suffix is-focus">
                <!-- <input type="text"
                       readonly="readonly"
                       autocomplete="off"
                       placeholder="请选择"
                       class="el-input__inner"> -->
                <el-select v-model="searchObj.UsingStatusKey"
                           placeholder="请选择"
                           size="small"
                           @change="chgSearchValue">
                  <el-option v-for="item in options"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <div class="mendian_backstage_check">
          <el-checkbox v-model="checked"
                       @change="changeSignWarning">考勤率异常</el-checkbox>
        </div>
      </div>
      <div class="mendian_backstage_searchBox_bottom flex pr">
        <div class="mendian_backstage_search">
          <div class="search_title">模糊搜索</div>
          <div class="search_input">
            <input type="text"
                   v-model="searchObj.searchText"
                   placeholder="请输入搜索内容">
          </div>
          <div class="btn_hover_bg_blue search_btn "
               @click.stop="searchData">搜索</div>
          <div class="btn_hover_white search_clear"
               @click.stop="clearSearchCondition">清除条件</div>
          <span class="export_btn">
            <zx-Import-button @click="doExportInfoClick()"
                              :isSingleSubmit="true"
                              :btnClass="''"
                              :singleSubmitType="'static'"
                              :btnText="'导出'"></zx-Import-button>
          </span>
        </div>

      </div>

    </div>
    <div class="mendian_backstage_table">
      <div>
        <!-- 在读课程总览 -->
        <list class="summarizing_list"
              :key="tableKey"
              :tableData="vMenDianList"
              :totalNum="totalNum"
              :PageCount="PageCount"
              :tableColumns="tableCourseColumns"
              :isSearchText="false"
              :isNeedInnerScroll="false"
              :defaultSort="defaultSort"
              :queryParams="searchObj"
              @tableBtnClick="doAfterTableBtnClick"
              @loadTableData="getSaaSClientMonitorList"></list>
      </div>
      <!-- <div class="screening_condition_number fl"
           style="margin-left: 436px;margin-top: 30px"
           v-else>
        <img src="../../../../public/image/screening_condition_number_icon02.gif"
             alt> 正在获取数据 , 请稍等...
      </div> -->
    </div>
    <custom-dialog title="修改"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowChangeStatuDialog"
                   :before-close="closeShowChangeDialog">
      <change-status :dataInfo="seletedMDInfo"
                     @closeDialog="closeShowChangeDialog"
                     @updateMenDianStatus="updateMenDianStatus"></change-status>
    </custom-dialog>
    <custom-dialog title="设置门店问题"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowChangeRemarkQuestionDialog"
                   :before-close="closeShowChangeDialog">

    </custom-dialog>
    <custom-dialog title="辅导日期"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowChangeTutorialDialog"
                   :before-close="closeShowChangeDialog">
      <change-tutorial :seletedInfo="seletedMDInfo"
                       @saveComfire="saveTutorial"
                       @closeDialog="closeShowChangeDialog"></change-tutorial>
    </custom-dialog>
    <!-- 备注 -->
    <textarea-pop :title="seletedMDInfo.SaaSClientKeyValue+'问题'"
                  :message="seletedMDInfo.RemarkSupport"
                  :placeholderText="'请输入问题备注...'"
                  :maxLength="10000"
                  ref="textareaPop"
                  @submitStudentArrangeSupport="submitQuestion"></textarea-pop>
  </div>
</template>
<script>
import { RemarkQuestion, getSaaSClientMonitorData, OpenIndependentModule } from '../../../API/workbench';
import dateOption from './component/date-option';
import changeTutorial from './component/change-tutorial';
import changeRemarkQuestion from './component/change-remark-question';
import changeStatus from './component/change-status';
import textareaPop from '../../common/textarea-pop';
import zxImportButton from '../../common/zx-Import-button';
import list from './table';
export default {
  data () {
    return {
      checked: false,
      value: '',
      isShowChangeStatuDialog: false, // 显示修改门店状态
      isShowChangeRemarkQuestionDialog: false, // 显示修改门店问题备注
      isShowChangeTutorialDialog: false, // 显示修改门店辅导信息
      isLoadingEnd: false,
      options: [
        // {label: '全部', value: 0},
        { label: '全部', value: '' },
        { label: '待辅导', value: 0 },
        { label: '试用中', value: 1 },
        { label: '留存', value: 2 }
        // { label: '流失', value: 3 }
      ],
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: 'CreateDateTime',
        sequence: 'DESC',
        QueryID: 0,
        SCreateDateTime: '',      // 开户时间
        ECreateDateTime: '',
        STutorialTime: '',        // 辅导日期
        ETutorialTime: '',
        SKeepAliveTime: '',       // 留存日期
        EKeepAliveTime: '',
        UsingStatusKey: '',
        SignWarning: 0,
        IsExportKey: 0
      },
      menDianList: [],
      seletedMDInfo: {},
      totalNum: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '门店ID',
          prop: 'SaaSClientKey',
          width: 20,
          sortable: false,
          align: 'left',
          isShow: false,
          type: '',
          decimalPlace: 0
        },
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'serial',
          decimalPlace: 0,
          className: '',
          eventName: this.$initJson.baseFunctionBtnName.read
        },
        {
          label: '开户日期',
          prop: 'CreateDateTime',
          width: 111,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'date',
          decimalPlace: 0
        },
        {
          label: '门店ID',
          prop: 'Support20',
          width: 81,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text',
          decimalPlace: 0
        },
        {
          label: '门店名称',
          prop: 'SaaSClientKeyValue',
          width: 157,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text',
          decimalPlace: 0,
          eventName: this.$initJson.baseFunctionBtnName.read
        },
        {
          label: '辅导日期/项目经理/电话销售',
          prop: 'TutorialTime',
          width: 350,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'popUp',
          decimalPlace: 0,
          ModulePowerKey: 0,
          className: 'NNN_type edit_icon',
          eventName: this.$initJson.baseFunctionBtnName.read
        },
        {
          label: '在校学生',
          prop: 'ActiveStudentCount',
          prop2: 'ActiveContrast',
          width: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'contrast',
          decimalPlace: 0,
          dynamicClassName: data => {
            return '';
          },
          eventName: this.$initJson.baseFunctionBtnName.read
        },
        {
          label: '关注学生',
          prop: 'SubscribeCount',
          prop2: 'RegisterContrast',
          width: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'contrast',
          decimalPlace: 0,
          dynamicClassName: data => {
            return '';
          },
          eventName: this.$initJson.baseFunctionBtnName.read
        },
        {
          label: '关注率',
          prop: 'SubscribeRateCount',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'date',
          decimalPlace: 0

        },
        {
          label: '近3天考勤/安排',
          prop: 'SignInArranged3', // 'AttenCount',
          width: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text',
          decimalPlace: 0,
          eventName: this.$initJson.baseFunctionBtnName.read
        },
        {
          label: '考勤率',
          prop: 'SignInRateCount',
          prop2: 'RateContrast',
          width: 91,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'contrast',
          decimalPlace: 0,
          dynamicClassName: data => {
            return data.RateStyle;
          },
          eventName: this.$initJson.baseFunctionBtnName.read
        },
        {
          label: '使用状态',
          prop: 'UsingStatusKeyValue',
          width: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'popDialog2',
          decimalPlace: 0,
          dynamicClassName: data => {
            if (data.UsingStatusKey == 1) {
              return 'font_blue';
            } else if (data.UsingStatusKey == 2) {
              return 'font_green';
            } else {
              return 'font_red';
            }
          },
          eventName: this.$initJson.baseFunctionBtnName.edit
        },
        {
          label: '留存日期',
          prop: 'KeepAliveTime',
          width: 136,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'date',
          decimalPlace: 0,
          eventName: this.$initJson.baseFunctionBtnName.read
        },
        {
          label: '公众号状态',
          prop: 'OfficialStatusKeyValue',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'popDialog2',
          decimalPlace: 0,
          dynamicClassName: data => {
            if (data.OfficialStatusKey == 1 || data.OfficialStatusKey == 4) {
              return 'font_blue';
            } else {
              return '';
            }
          },
          eventName: this.$initJson.baseFunctionBtnName.arrange
        },
        {
          label: '问题',
          prop: 'RemarkSupport',
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'popDialog',
          decimalPlace: 0,
          ModulePowerKey: 0,
          className: 'edit_icon',
          eventName: this.$initJson.baseFunctionBtnName.popDialog
        }
      ]

    };
  },
  components: {
    list, dateOption, changeTutorial, changeRemarkQuestion, changeStatus, textareaPop, zxImportButton
  },
  created () {
    this.getSaaSClientMonitorList();
  },
  computed: {
    vMenDianList () {
      let newArr = [];
      this.menDianList.forEach(o => {
        o.sourceData = [];
        this.contrastData(o);
        o.RegisterStudentCount = Number(o.RegisterStudentCount) || '-';
        o.LastRegisterCount = Number(o.LastRegisterCount) || '-';
        o.ActiveStudentCount = Number(o.ActiveStudentCount) || '-';
        o.LastActiveCount = Number(o.LastActiveCount) || '-';
        o.RegisterRateCount = Number(o.RegisterRateCount) > 0 ? Math.round(Number(o.RegisterRateCount)) + '%' : '-';
        o.SubscribeCount = Number(o.SubscribeCount) || '-';
        o.SubscribeRateCount = Number(o.SubscribeRateCount) > 0 ? Math.round(Number(o.SubscribeRateCount)) + '%' : '-';
        o.LastRegisterRateCount = Number(o.LastRegisterRateCount) || '-';
        // o.AttenCount = Number(o.SignIn3Count) + '\/' + Number(o.Arranged3Count);
        o.SignIn3Count = Number(o.SignIn3Count) || '-';
        o.LastSignIn3Count = Number(o.LastSignIn3Count) || '-';
        o.Arranged3Count = Number(o.Arranged3Count) || '-';
        o.LastArranged3Count = Number(o.LastArranged3Count) || '-';
        o.SignInRateCount = Number(o.SignInRateCount) > 0 ? Math.round(Number(o.SignInRateCount)) + '%' : '-';
        o.LastSignInRateCount = Number(o.LastSignInRateCount) > 0 ? Math.round(Number(o.LastSignInRateCount)) + '%' : '-';
        o.TutorialTime = o.TutorialTime || '-';
        o.ProjectManagerKeyValue = o.ProjectManagerKeyValue || '-';
        o.SalesPersonKeyValue = o.SalesPersonKeyValue || '-';
        o.sourceData.push(o.TutorialTime);
        o.sourceData.push(o.ProjectManagerKeyValue);
        o.sourceData.push(o.SalesPersonKeyValue);

        newArr.push(o);
      });
      return newArr;
    },
    vtableCourseColumns () {

    }
  },
  methods: {
    // 比较数据
    contrastData (o) {
      if (Number(o.SubscribeCount) > Number(o.LastSubscribeCount)) {
        o.RegisterContrast = '+';
      } else if (Number(o.SubscribeCount) < Number(o.LastSubscribeCount)) {
        o.RegisterContrast = '-';
      } else {
        o.RegisterContrast = '';
      }
      if (Number(o.ActiveStudentCount) > Number(o.LastActiveCount)) {
        o.ActiveContrast = '+';
      } else if (Number(o.ActiveStudentCount) < Number(o.LastActiveCount)) {
        o.ActiveContrast = '-';
      } else {
        o.ActiveContrast = '';
      }
      // o.SignInRateCount = 20;
      if (Number(o.SignInRateCount) > Number(o.LastSignInRateCount)) {
        o.RateContrast = '+';
      } else if (Number(o.SignInRateCount) < Number(o.LastSignInRateCount)) {
        o.RateContrast = '-';
      } else {
        o.RateContrast = '';
      }
      o.RateStyle = Number(o.SignInRateCount) < 30 && Number(o.SignInRateCount) > 0 ? 'bg_red' : '';
    },
    // 修改开户日期触发
    changeOptenAcountDate (dataInfo) {
      this.searchObj.SCreateDateTime = dataInfo.startTime;
      this.searchObj.ECreateDateTime = dataInfo.endTime;
      // console.log(dataInfo, 'changeOptenAcountDate', this.searchData);
      // this.searchData();
    },
    // 修改辅导日期触发
    changeTutorshipDate (dataInfo) {
      // console.log(dataInfo, 'changeTutorshipDate');
      this.searchObj.STutorialTime = dataInfo.startTime;
      this.searchObj.ETutorialTime = dataInfo.endTime;
      // this.searchData();
      // this.$refs.changeTutorial.isShowChangeTutorialDialog = true;
    }, // 修改留存日期触发
    // 修改留存日期触发
    changeSaveDate (dataInfo) {
      // console.log(dataInfo, 'changeSaveDate');
      this.searchObj.SKeepAliveTime = dataInfo.startTime;
      this.searchObj.EKeepAliveTime = dataInfo.endTime;
      // this.searchData();
    },
    showStatus () {
      this.isShowChangeStatuDialog = true;
    },
    // 关闭修改弹出层.
    closeShowChangeDialog () {
      this.isShowChangeStatuDialog = false;
      this.isShowChangeRemarkQuestionDialog = false;
      this.isShowChangeTutorialDialog = false;
    },
    // 保存辅导信息
    saveTutorial () {
      this.$bus.emit('updateMendianOperationManagement');
      this.closeShowChangeDialog();
      this.getSaaSClientMonitorList();
    },
    // 更新门店状态.
    updateMenDianStatus () {
      this.$bus.emit('updateMendianOperationManagement');
      this.closeShowChangeDialog();
      this.getSaaSClientMonitorList();
    },
    searchData () {
      this.searchObj.pageIndex = 0;
      this.getSaaSClientMonitorList();
    },
    // 获取门店运营管理列表
    getSaaSClientMonitorList () {
      getSaaSClientMonitorData(this.searchObj).then(result => {
        this.menDianList = result.data.PageDataList;
        this.totalNum = result.data.Total;
        console.log(result.data, 'getSaaSClientMonitorError');
      }).catch(error => {
        console.log(error, 'getSaaSClientMonitorError');
        // this.isLoadingEnd = true;
      });
    },
    submitQuestion (val, callBack) {
      this.saveMenDianQuestion(this.seletedMDInfo.SaaSClientKey, val, callBack);
    },
    // 设置门店问题备注
    saveMenDianQuestion (SaaSClientKey, question, callBack) {
      RemarkQuestion(SaaSClientKey, question).then(result => {
        this.seletedMDInfo.RemarkSupport = question;
        console.log('RemarkQuestion', result.data);
        this.$bus.emit('updateMendianOperationManagement');
        layer.alert('修改成功');
        if (callBack) {
          callBack();
        }
      }).catch(error => {
        if (callBack) {
          callBack();
        }
        console.log(error, 'RemarkQuestionError');
      });
    },
    chgSearchValue (val) {
      // this.searchObj.UsingStatusKey = val || '';
      console.log(val, 'chgSearchValue');
    },
    changeSignWarning (val) {
      if (val) {
        this.searchObj.SignWarning = 1;
      } else {
        this.searchObj.SignWarning = 0;
      }
      // this.searchData();
      console.log(val, 'changeSignWarning');
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj = {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: 'Support20',
        sequence: 'DESC',
        QueryID: 0,
        SCreateDateTime: '',      // 开户时间
        ECreateDateTime: '',
        STutorialTime: '',        // 辅导日期
        ETutorialTime: '',
        SKeepAliveTime: '',       // 留存日期
        EKeepAliveTime: '',
        UsingStatusKey: '',
        SignWarning: 0,
        IsExportKey: 0
      };
      this.$refs.openDate.resetTime();
      this.$refs.tutorshipDate.resetTime();
      this.$refs.saveDate.resetTime();
      this.getSaaSClientMonitorList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.menDianList.length > 0) {
        this.searchObj.IsExportKey = 1;
        this.searchObj.pageIndex = 0;
        this.searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '客户跟单管理',
          clist: [
            { title: '开户日期', cName: 'CreateDateTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '门店ID', cName: 'SaaSClientKey', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '门店名称', cName: 'SaaSClientKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '辅导日期', cName: 'TutorialTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '项目经理', cName: 'ProjectManagerKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '电话销售', cName: 'SalesPersonKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '在校学生', cName: 'ActiveStudent', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '关注学生', cName: 'SubscribeCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '关注率', cName: 'SubscribeRate', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '近3天安排考勤', cName: 'SignInArranged3', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '考勤率', cName: 'SignInRate', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '使用状态', cName: 'UsingStatusKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '留存日期', cName: 'KeepAliveTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '公众号状态', cName: 'OfficialStatusKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '问题', cName: 'RemarkSupport', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }

          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        getSaaSClientMonitorData(this.searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.searchObj.IsExportKey = 0;
          this.searchObj.pageIndex = 0;
          this.searchObj.pageSize = 10;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, rowAtrribute);
      this.seletedMDInfo = rowData;
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.popDialog:
          this.$refs.textareaPop.isAlterMessagePopup = true;
          break;
        case this.$initJson.baseFunctionBtnName.read:
          this.isShowChangeTutorialDialog = true;
          break;
        case this.$initJson.baseFunctionBtnName.edit:
          if (rowData.UsingStatusKey == 0) {
            return false;
          }
          this.isShowChangeStatuDialog = true;
          break;
        case this.$initJson.baseFunctionBtnName.arrange:
          if (rowData.OfficialStatusKey == 2 || rowData.OfficialStatusKey == 3 || rowData.OfficialStatusKey == 5) {
            return false;
          }
          layer.confirm('是否切换到待授权？', {
            btn: [{
              name: '确认',
              callBack: () => {
                OpenIndependentModule(rowData.SaaSClientKey).then(result => {
                  this.getSaaSClientMonitorList();
                }).catch(error => {
                  layer.alert(error.msg);
                });
                layer.close();
              }
            }
            ]
          });
          break;
        default:
          break;
      }
    }
  }
};
</script>

