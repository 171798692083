<template>
  <div class="courseware_details pr">
    <!-- 汇总信息 -->
    <prepare-lessons-head :titleList="vauthorizationHeadList"
                          @chgTitleList="chgTitleList"></prepare-lessons-head>
    <!-- <div class="switchBox">
      <span @click="setUpFun">授权老师所有课件</span>
      <el-switch v-model="isSetModify"
                 @change="changeSwitch"></el-switch>
    </div>
    <div class="switchBox1">
      <span>设备申请自动审批</span>
      <el-switch v-model="isAutoAuth"
                 @change="changeAutoAuthSwitch"></el-switch>

    </div>
    <div class="switchBox2"
         v-if="isAutoAuth">
      <span>自动释放设备</span>
      <el-switch v-model="isAutoReplace"
                 @change="changeAutoReplace"></el-switch>

    </div> -->
    <div class="setUpClass"
         @click="setUpFun">
      <img class="setImg"
           src="../../../../../public/image/setup_icon.png" />
    </div>
    <div v-if="bigCoursewarePackageList.length>0">
      <!-- <cw-summarizing :cwsSummList="cwsSummList"
                      @searchDate="searchDate"
                      @doSummarizingClick="doSummarizingClick"></cw-summarizing> -->

      <courseware-list v-for="(item,index) in bigCoursewarePackageList"
                       :key="index"
                       :bigCoursewareItem="item"
                       :particularData="particularData"
                       @afterSuccess="getAuthorizationBigCoursewarePackageList"></courseware-list>
    </div>
    <div v-else
         style="height: 100%;">
      <div class="cw_content_nodata_txt_zys pr"
           v-if="$store.getters.SaaSClientEditionKey == 6 && $store.getters.AcademicMdCwType == 2&&$store.getters.SaaSClientInfo.IsOpenSelfCoursewareKey==0">
        <span>功能未开通</span>
        此功能为付费功能，请联系专属客服！

      </div>
      <div v-else-if="$store.getters.SaaSClientEditionKey == 6 && $store.getters.AcademicMdCwType == 1"
           class="cw_content_nodata_txt">还没有课包哦，请与课件方联系~</div>
      <div v-else-if="$store.getters.SaaSClientEditionKey == 6 && $store.getters.AcademicMdCwType == 2"
           class="cw_content_nodata_txt cw_content_nodata_txt_blue"
           @click="goSelfCourseware">还没有课包哦，点击上传课包</div>
    </div>
    <custom-dialog :title="'设置'"
                   :visible.sync="isDeviceDialog"
                   :before-close="closeisDeviceDialog">
      <switch-Auto-form @submitAuto="submitAuto"
                        @close="closeisDeviceDialog"></switch-Auto-form>
    </custom-dialog>
  </div>
</template>  
<script>
import cwSummarizing from './cw-summarizing';
import coursewareList from './courseware-list';
import switchAutoForm from './switch-Auto-form';

import prepareLessonsHead from '../../../head-quarters/campus-prepare-lessons-examine/common/prepare-lessons-head';
import { CWSGetDateRangeCoursewareStat, CWSGetAuthorizationBigCoursewarePackageList, GetDateRangeSinceCoursewareStat, CWSModifySetting, CWSGetSinceBigCoursewarePackageList } from '../../../../API/workbench';
export default {
  data () {
    return {
      authorizationHead: [
        { name: '三方课件', key: 1, isSelected: true, isShow: true },
        { name: '自研课件', key: 2, isSelected: false, isShow: true }
      ],
      cwsSummList: [
        { name: '已上课次', key: 1, type: 'count', value: 0, listClassName: 'cp', unit: '节课', numClass: 'font_blue' },
        { name: '上课时长', key: 2, type: 'count', value: 0, listClassName: 'cp', unit: '分钟', numClass: 'font_blue' },
        { name: '消耗流量', key: 3, type: 'count', value: 0, listClassName: 'cp', unit: 'G', numClass: 'font_blue' },
        { name: '签到人次', key: 4, type: 'count', value: 0, listClassName: 'cp', unit: '人次', numClass: 'font_blue' },
        { name: '今天已上课次', key: 5, type: 'count', value: 0, listClassName: 'bottom_border_blue cp', unit: '节课', numClass: 'font_blue' },
        { name: '今天上课时长', key: 6, type: 'count', value: 0, listClassName: 'bottom_border_green cp', unit: '分钟', numClass: 'font_blue' }
      ],
      isDeviceDialog: false,
      isSetModify: false,
      dataInfo: [],
      autoAuthDataInfo: [],
      autoReplaceDataInfo: [],
      isAutoAuth: false,
      particularData: {}, // 传入课件详情
      // 查询参数
      params: {
        StartTime: this.$utils.getCurrentMonthFirst(), //	字符串	必须		数据源：GET	起始时间
        EndTime: this.$utils.getCurrentMonthLast(), //	字符串	必须		数据源：GET	结束时间
        TeacherKey: '', //	整型	可选		数据源：GET	老师key
        GradeKey: '', //	整型	可选		数据源：GET	班级key
        BigCoursewarePackageKey: '', //	整型	可选		数据源：GET	大课包key
        Source: ''
      },
      bigCoursewarePackageList: [],
      coursePackageSortObj: {
        name: '课包排序',
        routerName: 'coursePackageSort',
        routertitle: '课包排序',
        auth: true,
        roleName: '',
        ModulePowerKey: 0,
        class: 'bg_yellow',
        firstTitle: 'L',
        moduleName: [118, 123]
      },
      isAutoReplace: false
    };
  },
  computed: {
    vMendianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vauthorizationHeadList () {
      let newArr = []
      let list = this.authorizationHead
      if (this.vMendianInfo && this.vMendianInfo.OpenModule) {
        if (this.vMendianInfo.IsAuthorizeCoursewareKey != 1) {
          list[0].isSelected = false;
          list[0].isShow = false;
          list[1].isSelected = true;
          list[1].isShow = true;
        }
        if (this.vMendianInfo.IsOpenSelfCoursewareKey != 1) {
          // 没开通自研
          // this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 12)
          list[1].isSelected = false;
          list[1].isShow = false;
        }
      }
      list.forEach((o, index) => {
        if (o.isShow) {
          newArr.push(o)
        }
      })

      return newArr
    },
    vDateRangeApiName () {
      if (this.authorizationHead[0].isSelected) { // 授权
        // this.params.Source = 1
        return CWSGetDateRangeCoursewareStat;
      } else if (this.authorizationHead[1].isSelected) { // 自研
        this.params.Source = '';
        return GetDateRangeSinceCoursewareStat;
      }
    },
    vBigCoursewarePackageListApiName () {
      if (this.authorizationHead[0].isSelected) { // 授权
        return CWSGetAuthorizationBigCoursewarePackageList;
      } else if (this.authorizationHead[1].isSelected) { // 自研
        return CWSGetSinceBigCoursewarePackageList;
      }
    },
    vSource () {
      if (this.authorizationHead[0].isSelected) { // 授权
        return 1;
      } else if (this.authorizationHead[1].isSelected) { // 自研
        return 2;
      }
    }
  },
  components: {
    cwSummarizing,
    coursewareList,
    prepareLessonsHead,
    switchAutoForm
  },
  created () {
    console.log(this.$store.getters, 'this.$store.getters111', this.vMendianInfo);
    // 没三方授权


    // this.getDateRangeCoursewareStat();// 按月

    setTimeout(() => {
      this.$store.commit('setAcademicMdCwType', 1);
      console.log('this.$store.vMendianInfo', this.vMendianInfo);
      // if (this.vMendianInfo.IsAuthorizeCoursewareKey!=1) {
      //   this.authorizationHead[0].isSelected=false;
      //   this.authorizationHead[0].isShow=false;
      //   this.authorizationHead[1].isSelected=true; 
      //   this.authorizationHead[1].isShow=true; 
      // }
      // if (this.vMendianInfo.IsOpenSelfCoursewareKey!=1) {
      //       // 没开通自研
      //       // this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 12)

      //   this.authorizationHead[1].isSelected=false; 
      //   this.authorizationHead[1].isShow=false; 
      // }
      // 加入两个都不开通。
      if (Number(this.vMendianInfo.IsAuthorizeCoursewareKey == 0) && Number(this.vMendianInfo.IsOpenSelfCoursewareKey == 0)) {

      } else {
        this.getStartDateRangeCoursewareStat();// 按天
        this.getAuthorizationBigCoursewarePackageList();
      }
      console.log("this.authorizationHead", this.authorizationHead)
    }, 300);
  },
  mounted () {
    setTimeout(() => {
      this.dataInfo = this.$utils.getSaaSClientSetInfo(71);
      this.autoAuthDataInfo = this.$utils.getSaaSClientSetInfo(75);
      this.autoReplaceDataInfo = this.$utils.getSaaSClientSetInfo(94);
      console.log(this.dataInfo, 'this.dataInfo');
      if (this.dataInfo.SetKey == '1') {
        this.isSetModify = true;
      } else {
        this.isSetModify = false;
      }
      if (this.autoAuthDataInfo.SetKey == '1') {
        this.isAutoAuth = true;
      } else {
        this.isAutoAuth = false;
      }
      if (this.autoReplaceDataInfo.SetKey == '1') {
        this.isAutoReplace = true;
      } else {
        this.isAutoReplace = false;
      }
    }, 500);
  },
  methods: {
    submitAuto (a, b, c) {
      console.log(a, b, c, 'abc');
      this.isDeviceDialog = false;
    },
    closeisDeviceDialog () {
      this.isDeviceDialog = false;
    },
    setUpFun () {
      this.isDeviceDialog = true;
    },
    getDateRangeCoursewareStat () {
      console.log('this.params', this.params);
      if (this.authorizationHead[0].isSelected) {
        this.params.Source = 1;
      }
      console.log(1111111);
      this.vDateRangeApiName(this.params).then(result => {
        //  Number(result.data.CourseCount)>9999?this.$utils.mAmountType(Number(result.data.CourseCount)>9999/1000)
        console.log(result.data, '获取指定日期范围的课件上课统计');
        this.particularData = result.data;
        this.cwsSummList[0].value = this.setCount(result.data.CourseCount) || 0;// 已上课次
        this.cwsSummList[1].value = this.setCount(result.data.CourseTimeCount) || 0;// 上课时长
        this.cwsSummList[2].value = this.$utils.flowFormate(result.data.ConsumptionFlowCount, 'number') == '--' ? '--' : Math.abs(this.$utils.flowFormate(result.data.ConsumptionFlowCount, 'number')).toFixed(0);// 消耗流量
        this.cwsSummList[2].unit = this.$utils.flowFormate(result.data.ConsumptionFlowCount, 'unit');// 消耗流量
        this.cwsSummList[3].value = this.setCount(result.data.ArrivedNumCount);// 签到人次
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 导航栏切换
    chgTitleList (item) {
      console.log(item, this.authorizationHead, '导航栏切换');
      this.$store.commit('setAcademicMdCwType', item.key);
      this.bigCoursewarePackageList = [];
      this.params.StartTime = this.$utils.getCurrentMonthFirst();
      this.params.EndTime = this.$utils.getCurrentMonthLast();
      // this.getDateRangeCoursewareStat();// 按月
      this.getStartDateRangeCoursewareStat();// 按天
      this.getAuthorizationBigCoursewarePackageList();
    },
    getStartDateRangeCoursewareStat () {
      let date = {
        StartTime: this.$utils.formatDateToLine(Date.new()), //	字符串	必须		数据源：GET	起始时间
        EndTime: this.$utils.formatDateToLine(Date.new()), //	字符串	必须		数据源：GET	结束时间
        TeacherKey: '', //	整型	可选		数据源：GET	老师key
        GradeKey: '', //	整型	可选		数据源：GET	班级key
        BigCoursewarePackageKey: ''//	整型	可选		数据源：GET	大课包key
      };
      if (this.authorizationHead[0].isSelected) {
        date.Source = 1;
      }
      this.vDateRangeApiName(date).then(result => {
        console.log(result.data, '获取指定日期范围的课件上课统计');
        this.cwsSummList[4].value = this.setCount(result.data.CourseCount);// 今日已上课次
        this.cwsSummList[5].value = this.setCount(result.data.CourseTimeCount);// 今日上课时长
      }, error => {
        layer.alert(error.msg);
      });
    },
    setCount (num) {
      if (Number(num) > 9999) {
        return this.$utils.mAmountType((Number(num) / 10000), 1) + '万';
      } else {
        return Number(num);
      }
    },
    getAuthorizationBigCoursewarePackageList () {
      var DeviceCode = '';
      if (this.$zxClient.IsClient) {
        DeviceCode = this.$zxClient.GetMCode();// 340BC40DC769411B
      }
      this.vBigCoursewarePackageListApiName(DeviceCode).then(result => {
        console.log(result.data, '获取授权大课包List');
        this.bigCoursewarePackageList = result.data;
        this.bigCoursewarePackageList.forEach((item) => {
          item.Source = this.authorizationHead[0].isSelected ? '1' : '2';
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    searchDate (startDate, endDate) {
      this.params.StartTime = startDate;
      this.params.EndTime = endDate;
      this.getDateRangeCoursewareStat();// 按月
      this.getStartDateRangeCoursewareStat();// 按天
    },
    doSummarizingClick (item, startDate, endDate) {
      console.log(item, startDate, endDate, '点击当前汇总数据');
      let StartTime = item.key >= 5 ? this.$utils.formatDateToLine(Date.new()) : startDate; //	字符串	必须		数据源：GET	起始时间
      let EndTime = item.key >= 5 ? this.$utils.formatDateToLine(Date.new()) : endDate; //	字符串	必须		数据源：GET	结束时间
      this.$dialog.open(this, {
        name: '课件使用账单',
        routerName: this.$route.name,
        moduleName: 'coursewareUseReport',
        // name: '上课统计',
        // routerName: this.$route.name,
        // moduleName: 'classStatistics',
        data: {
          dataInfo: {
            TeacherKey: this.TeacherKey,
            StartTime: StartTime,
            EndTime: EndTime,
            BigCoursewarePackageKey: '',
            Source: this.authorizationHead[0].isSelected ? 1 : 2
          }
        }
      });
    },
    changeSwitch () {
      console.log(this.isSetModify, 'this.isSetModify');
      const that = this;
      layer.confirm('确定' + (this.isSetModify ? '开启' : '关闭') + '授权老师所有课件开关吗？', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              that.isSetModify = !that.isSetModify;
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              if (that.isSetModify) {
                that.dataInfo.SetKey = '1';
              } else {
                that.dataInfo.SetKey = '0';
              }
              CWSModifySetting(
                that.dataInfo
              )
                .then((res) => {
                  console.log(res, '修改成功');
                })
                .catch((error) => {
                  layer.alert(error.msg);
                });
              layer.close();
            }
          }
        ]
      });
    },
    changeAutoAuthSwitch () {
      console.log(this.isAutoAuth, 'this.isAutoAuth');
      const that = this;
      layer.confirm('确定' + (this.isAutoAuth ? '开启' : '关闭') + '设备申请自动审批开关吗？', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              that.isAutoAuth = !that.isAutoAuth;
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              if (that.isAutoAuth) {
                that.autoAuthDataInfo.SetKey = '1';
              } else {
                that.autoAuthDataInfo.SetKey = '0';
              }
              CWSModifySetting(
                that.autoAuthDataInfo
              )
                .then((res) => {
                  console.log(res, '修改成功');
                })
                .catch((error) => {
                  layer.alert(error.msg);
                });
              layer.close();
            }
          }
        ]
      });
    },
    changeAutoReplace () {
      console.log(this.isAutoReplace, 'this.isAutoAuth');
      const that = this;
      layer.confirm('确定' + (this.isAutoReplace ? '开启' : '关闭') + '自动释放设备？', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              that.isAutoReplace = !that.isAutoReplace;
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              if (that.isAutoReplace) {
                that.autoReplaceDataInfo.SetKey = '1';
              } else {
                that.autoReplaceDataInfo.SetKey = '0';
              }
              CWSModifySetting(
                that.autoReplaceDataInfo
              )
                .then((res) => {
                  console.log(res, '修改成功');
                })
                .catch((error) => {
                  layer.alert(error.msg);
                });
              layer.close();
            }
          }
        ]
      });
    },
    goSelfCourseware () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'cwsgeneralCoursewareManagement',
        data: {},
        callBack: {

          afterSuccess: () => { this.$emit('afterSuccess'); }
        }
      });
    }
  }
};
</script>

<style scoped>
.headerGost {
  position: relative;
  width: 100%;
  height: 50px;
}
.switchBox {
  position: absolute;
  top: 17px;
  right: 20px;
}
.switchBox1 {
  position: absolute;
  top: 17px;
  right: 210px;
}
.switchBox2 {
  position: absolute;
  top: 17px;
  right: 395px;
}
.cw_content_nodata_txt_zys {
  min-height: 308px !important;
  margin-top: -78px !important;
  padding-top: 270px !important;
  text-align: center;
  padding-bottom: 80px;
  background-image: url(../../../../../public/cw-img/Group.png);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  color: #999;
  font-size: 16px;
  height: 100%;
}
.cw_content_nodata_txt_zys span {
  display: block;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
  /* identical to box height, or 157% */
  align-items: center;
  color: #000000;
}
.cw_content_nodata_txt_blue {
  color: #3498db;
  cursor: pointer;
}
.courseware_details {
  background: rgba(245, 247, 249, 1);
  height: 100%;
}
.courseware_details >>> .table_fixed_nav {
  width: 856px !important;
  background: #fff;
  border-radius: 0 8px 0px 0;
  box-shadow: 8px 4px 15px rgba(222, 225, 230, 0.6);
}

.courseware_details >>> .table_nav_box {
  margin-bottom: 26px;
  position: relative;
  z-index: 1;
}
.courseware_details >>> .table_nav_box .table_fixed_nav .opt {
  color: rgba(228, 62, 9, 1) !important;
  font-weight: 700;
  border-bottom: 3px solid rgba(228, 62, 9, 1);
}
.courseware_details >>> .table_nav_box .table_fixed_nav .table_fixed_nav_list {
  padding: 0 !important;
  margin: 0 20px;
  line-height: 52px !important;
  height: 52px !important;
  font-size: 14px !important;
}
.cw_content_nodata_txt {
  min-height: 310px !important;
  margin-top: -78px !important;
  padding-top: 270px !important;
  background-position: center !important;
  border-radius: 0px 0px 8px 0px;
  height: 100%;
}
.setUpClass {
  position: absolute;
  top: 12px;
  right: 15px;
  width: 30px;
  height: 30px;
  line-height: 29px;
  cursor: pointer;
  text-align: center;
  z-index: 2;
}
.setUpClass:hover {
  border-radius: 4px;
  background: rgba(217, 223, 228, 1);
}
.setImg {
  width: 25px;
  height: 25px;
}
</style>
