<template>
  <div class="">
    <div class="titleText">报名转化来源统计</div>
    <div class=" "
         style="justify-content: inherit;flex-direction: row;flex-wrap: wrap;">
      <div class="search_item"
           style="margin-top: 10px;margin-bottom: 10px;">
        <el-select-option :selectTitle="'选择销售'"
                          :dataSourceList="saleList"
                          :selectionKey="SaleKey"
                          @changeEvent="chooseSale"></el-select-option>
      </div>
    </div>
    <div class="credit_management_table">
      <table-list class=" table_list_content"
                  :tableData="vdataReportList"
                  :tableColumns="tableCourseColumns"
                  :queryParams="queryParams"
                  :showPagePagination="false"
                  @loadTableData="getDataList"></table-list>
    </div>
    <div style="display: flex; flex-direction: row;">
      <div class="chartbox">
        <div class="report_title_text pa50">线索来源占比</div>
        <echactPie :echartID="'registrationechart'"
                   ref="registrationechart"
                   :dataList="vEchartList1"></echactPie>
      </div>
      <div class="chartbox">
        <div class="report_title_text pa50">线索转化占比</div>
        <echactPie :echartID="'registrationechart1'"
                   ref="registrationechart1"
                   :dataList="vEchartList2"></echactPie>
      </div>
    </div>
    <div class="report_title_text">节点转化来源对比分析</div>
    <div style="display: flex; flex-direction: row;flex-wrap: wrap;margin-top: 15px;">
      <comparativeAnalysis :DataList="vdataReportList"
                           :modeType="1"></comparativeAnalysis>
      <comparativeAnalysis :DataList="vdataReportList"
                           :modeType="2"></comparativeAnalysis>
      <comparativeAnalysis :DataList="vdataReportList"
                           :modeType="3"></comparativeAnalysis>
      <comparativeAnalysis :DataList="vdataReportList"
                           :modeType="4"></comparativeAnalysis>
    </div>

  </div>
</template>
<script>
import elSelectOption from '../../../report/common/el-select-option';
import echactPie from './echact-pie';
import comparativeAnalysis from './comparative-analysis';
import {
  SaleSignUpTransformationStatistics, GetSaleList, SaleSourceRateContrast, SaleTransformationRateContrast, SalePromiseVisitRateContrast, SaleInviteTrialLessonRateContrast, SaleTrialLessonRateContrast, SaleMakeABargainRateContrast
} from '../../../../API/workbench';
import tableList from '../../../common/table-list/index';
export default {
  data () {
    return {
      echartList1: [

      ],
      echartList2: [],
      analysis1: [],
      analysis2: [],
      analysis3: [],
      analysis4: [],
      saleList: [
        {
          MainDemoName: '全部',
          OLAPKey: 0
        },
        {
          MainDemoName: '授权课件',
          OLAPKey: 1
        },
        {
          MainDemoName: '自研课件',
          OLAPKey: 2
        }

      ],
      dataReportList: [],
      queryParams: {
        pageIndex: 0,
        pageSize: 5,
        orderBy: '',
        sequence: '',
        searchText: ''
      },
      SaleKey: 0,
      tableData: {},
      tableCourseColumns: [
        // 明细总览 table 列
        // {
        //   label: '序号',
        //   prop: 'Number',
        //   width: 68,
        //   sortable: false,
        //   align: 'left',
        //   type: 'tableIndex',
        //   className: 'table_index_gray'
        // },
        {
          label: '来源方式',
          prop: 'SourceTypeKeyValue',
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '新增线索',
          prop: 'NewAddThreadCount',
          width: 110,
          sortable: 'custom',
          align: 'left',
          type: 'numberItem'
        },
        {
          label: '已诺访',
          prop: 'PromiseVisitGetToSchoolCount',
          width: 110,
          sortable: 'custom',
          align: 'left',
          type: 'numberItem'
        },
        {
          label: '诺访率',
          prop: 'PromiseVisitRateText',
          width: 110,
          sortable: 'custom',
          type: 'textItem'
        },
        {
          label: '约试课',
          prop: 'TrialLessonCount',
          width: 110,
          sortable: 'custom',
          type: 'numberItem'
        },
        {
          label: '约课率',
          prop: 'InvitedLessonRateText',
          Width: 110,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '已试课',
          prop: 'FinishTrialLessonCount',
          width: 110,
          sortable: 'custom',
          align: 'left',
          type: 'numberItem'
        },
        {
          label: '试课率',
          prop: 'FinishTrialLessonRateText',
          width: 110,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '转化报名',
          prop: 'SignUpCount',
          width: 110,
          sortable: 'custom',
          align: 'left',
          type: 'numberItem'
        },
        {
          label: '转化率',
          prop: 'SignUpTransformationRateText',
          width: 110,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        }
      ]
    };
  },
  components: {
    tableList,
    elSelectOption,
    echactPie,
    comparativeAnalysis
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        newArr.push(o);
      });
      return newArr;
    },
    vEchartList1 () {
      return this.echartList1;
    },
    vEchartList2 () {
      return this.echartList2;
    }
  },
  watch: {
    'searchObj': {
      handler (newval, oldval) {
        this.getDataList();
        this.getSaleSourceRateContrast();
        this.getSaleTransformationRateContrast();
        // this.getSalePromiseVisitRateContrast();
      },
      deep: true
    }
  },
  props: {
    searchObj: Object
  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      this.getSaleList();
      this.getDataList();
      this.getSaleSourceRateContrast();
      this.getSaleTransformationRateContrast();
    });

    // this.getSalePromiseVisitRateContrast();
  },
  methods: {
    chooseSale (item) {
      this.SaleKey = item.OLAPKey;
      // this.searchObj.pageIndex = 0;
      this.getDataList();
      this.getSaleSourceRateContrast();
      this.getSaleTransformationRateContrast();
      // this.getSalePromiseVisitRateContrast();
    },
    // 获取老师列表
    getSaleList () {
      GetSaleList().then((result) => {
        this.saleList = result.data;
        this.saleList.unshift({ MainDemoName: '全部', OLAPKey: 0 });
        console.log(this.saleList, '销售列表');
      }, (error) => {
        layer.alert(error.msg);
      }
      );
    },
    getDataList () {
      SaleSignUpTransformationStatistics(this.searchObj, this.SaleKey).then(
        (res) => {
          this.dataReportList = res.data;
          this.dataReportList.forEach((o) => {
            o.SignUpTransformationRateText = Number(o.SignUpTransformationRate) == 0 ? '-' : Number(o.SignUpTransformationRate) + '%';
            o.FinishTrialLessonRateText = Number(o.FinishTrialLessonRate) == 0 ? '-' : Number(o.FinishTrialLessonRate) + '%';
            o.InvitedLessonRateText = Number(o.InvitedLessonRate) == 0 ? '-' : Number(o.InvitedLessonRate) + '%';
            o.PromiseVisitRateText = Number(o.PromiseVisitRate) == 0 ? '-' : Number(o.PromiseVisitRate) + '%';
            o.SourceTypeKeyValue = o.SourceTypeKeyValue == '' ? '未分配' : o.SourceTypeKeyValue
          });
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    draw () {
      this.$refs.registrationechart.echartInit();
    },
    draw1 () {
      this.$refs.registrationechart1.echartInit();
    },

    getSaleSourceRateContrast () {
      let that = this;
      SaleSourceRateContrast(this.searchObj, this.SaleKey).then((result) => {
        that.echartList1=[]
        result.data.forEach(item => {

          if(item.SourceRate>0){
            item.SourceTypeKeyValue = item.SourceTypeKeyValue == '' ? '未分配' : item.SourceTypeKeyValue
            item.value = item.SourceRate;
            item.name = Number(item.SourceRate).toFixed(2) + '%    ' + item.SourceTypeKeyValue;
            // item.itemStyle={ color: this.getStyleColor() }
            that.echartList1.push(item)
          }
        
        });
        if (that.echartList1.length == 0){
          that.echartList1.push({value:0,name:'',itemStyle:{
            color:'#999'
          }})
        }
        //  = result.data;

        setTimeout(() => {
          that.$forceUpdate();
          that.draw1();
        }, 100);
      }, (error) => {
        layer.alert(error.msg);
      }
      );
    },
    getStyleColor(){
      let arr = ['#FE9647','#519CF7', '#7BE070','#e2f6ff','#ef2a57','#fbe812','#1608e3','#ccb05d','#f4b833','#e43927','#c6c6bc','#e3ddbd','#e6a0c4']
      var randomNum = Math.floor(Math.random() * 13)
      return arr[randomNum]
    },
    getSaleTransformationRateContrast () {
      let that = this;
      SaleTransformationRateContrast(this.searchObj, this.SaleKey).then((result) => {
        that.echartList2=[]
        result.data.forEach(item => {
          if(item.TransformationRate>0){
            item.SourceTypeKeyValue = item.SourceTypeKeyValue == '' ? '未分配' : item.SourceTypeKeyValue
            item.value = item.TransformationRate;
            item.name = Number(item.TransformationRate).toFixed(2) + '%    ' + item.SourceTypeKeyValue;
            // item.itemStyle={ color: this.getStyleColor() }
            that.echartList2.push(item)
          }
         
        });
        if (that.echartList2.length == 0){
          that.echartList2.push({value:0,name:'',itemStyle:{
            color:'#999'
          }})
        }
        setTimeout(() => {
          that.$forceUpdate();
          that.draw();
        }, 100);
      }, (error) => {
        layer.alert(error.msg);
      }
      );
    }
    // getSalePromiseVisitRateContrast () {
    //   SalePromiseVisitRateContrast(this.searchObj, this.SaleKey).then((result) => {
    //     this.analysis1 = result.data;
    //   }, (error) => {
    //     layer.alert(error.msg);
    //   }
    //   );
    // },
    // getSaleInviteTrialLessonRateContrast () {
    //   SaleInviteTrialLessonRateContrast(this.searchObj, this.SaleKey).then((result) => {
    //     this.analysis2 = result.data;
    //   }, (error) => {
    //     layer.alert(error.msg);
    //   }
    //   );
    // },
    // getSaleTrialLessonRateContrast () {
    //   SaleTrialLessonRateContrast(this.searchObj, this.SaleKey).then((result) => {
    //     this.analysis3 = result.data;
    //   }, (error) => {
    //     layer.alert(error.msg);
    //   }
    //   );
    // },
    // getSaleMakeABargainRateContrast () {
    //   SaleMakeABargainRateContrast(this.searchObj, this.SaleKey).then((result) => {
    //     this.analysis4 = result.data;
    //   }, (error) => {
    //     layer.alert(error.msg);
    //   }
    //   );
    // }

  }
};
</script>
<style scoped>
.titleText {
  font-family: Alibaba PuHuiTi;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  color: #333333;
}
.pr {
  position: relative;
}
.chartbox {
  position: relative;
  flex: 1;
  /* display: flex;
  flex-direction: row; */
}
.chartbox .tit .text {
  /* float: left; */
  height: 30px;
  line-height: 30px;
  font-size: 20px;
  font-weight: bold;
}
.chartbox .tit {
  position: absolute;
  display: flex !important;
  flex-direction: column;
  width: 50px;
  /* flex: 4; */
}
.chartbox .tit .title_date {
  position: relative;
  width: 100px;
  margin-left: 20px;
}
.chartbox .tit .title_date:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #ccc;
  right: 6px;
  top: 14px;
}
.chartbox .tit .title_date .el-input__inner {
  padding: 0 10px;
  cursor: pointer;
}
.chartbox .echartbox {
  /* flex: 6; */
  height: 360px;
}
.report_title_text {
  font-family: Alibaba PuHuiTi;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  color: #333333;
}
.pa50 {
  position: absolute;
  top: 50px;
}
</style>