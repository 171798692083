<template>
  <div class="WKT_index_phone">
    <div class="WKT_index_phone_banner">
      <div class="WKT_home_view">
        <!-- 轮播图片 -->
        <div class="WKT_home_view_swiper hover_border_blue"
             :id="moduleNames.corporateImage"
             @click.stop="clickModuleEvent(moduleNames.corporateImage)"
             :class="{opt_view_border:vIsShowCorporateImage}">
          <div class="swiper-container"
               v-if="vcorporateImageList.length<=1">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img-show :imgUrl="vcorporateImagesUrl"
                          :isShrink="false"></img-show>
              </div>
            </div>
            <!--分页器。如果放置在swiper-container外面，需要自定义样式。-->
          </div>
          <div class="swiper-container view_swiper"
               v-show="vcorporateImageList.length>1">
            <div class="swiper-wrapper">

              <!-- <div class="swiper-slide">
                <img-show :imgUrl="corporateImageData.BrandImgSupport">
                </img-show>
              </div> -->
              <div v-for="(item,index) in vcorporateImageList"
                   :key="index"
                   class="swiper-slide">
                <img-show :imgUrl="item.imgUrl"
                          v-if="item.imgUrl"
                          :isShrink="false"></img-show>
              </div>
            </div>
            <div class="swiper-pagination"
                 v-show="vcorporateImageList.length>1"></div>
            <!--分页器。如果放置在swiper-container外面，需要自定义样式。-->
          </div>
          <div v-if="vIsShowCorporateImage"
               class="opt_view_btn"
               @click.stop="clickModuleEvent(moduleNames.corporateImage)">
          </div>
        </div>
        <!-- 机构简介 -->
        <div class="WKT_home_view_mendian hover_border_blue"
             :id="moduleNames.organizationIntroduction"
             @click.stop="clickModuleEvent(moduleNames.organizationIntroduction)"
             :class="{opt_view_border:vIsShowOrganizationIntroduction}">
          <div class="mendian_title">
            <div class="mendian_logo">
              <img-show :imgUrl="mendianInfo.logo"></img-show>
            </div>
            <div class="mendian_name">{{mendianInfo.name}}</div>
          </div>
          <div class="mendian_content pr">
            <div class="content_text">{{vIntroduce}}</div>
            <div class="WKT_course_info_text_btn"
                 v-if="isShowOpenBtn">展开</div>
          </div>
          <div v-if="vIsShowOrganizationIntroduction"
               class="opt_view_btn"
               @click.stop="clickModuleEvent(moduleNames.organizationIntroduction)">
          </div>
        </div>
        <!-- 九宫格菜单 -->
        <div class="WKT_home_view_menuBox">

          <div class="menu_list bg_org hover_border_blue dfr"
               :id="moduleNames.hotActivity"
               :class="{opt_view_border:vIsShowHotActivity}"
               @click.stop="clickModuleEvent(moduleNames.hotActivity)">
            <div class="menu_icon long_icon">
              <img src="../../../../public/image/wktImg/hot.png"
                   alt="">
            </div>
            <div class=" long_text">
              热门活动
              <div v-if="vIsShowHotActivity"
                   class="opt_view_btn"
                   @click.stop="clickModuleEvent(moduleNames.hotActivity)">
              </div>
            </div>
            <!-- <div v-if="vIsShowHotActivity"
                 class="opt_view_btn"
                 @click.stop="clickModuleEvent(moduleNames.hotActivity)">
            </div> -->
          </div>

          <div class="menu_list bg_lblue hover_border_blue dfr"
               :class="{opt_view_border:vCourseName}"
               :id="moduleNames.courseName"
               @click.stop="clickModuleEvent(moduleNames.courseName)">
            <div class="menu_icon long_icon">
              <img src="../../../../public/image/wktImg/great_courseware.png"
                   alt="">
            </div>
            <div class=" long_text"
                 style="line-height: 45px;padding-left: 8px;">
              精品课程
              <div v-if="vCourseName"
                   class="opt_view_btn"
                   @click.stop="clickModuleEvent(moduleNames.courseName)">
              </div>
            </div>

          </div>

          <div class="menu_list_min menu_list hover_border_blue"
               :id="moduleNames.discountPackage"
               :class="{opt_view_border:vIsShowDiscountPackage}"
               @click.stop="clickModuleEvent(moduleNames.discountPackage)">
            <div class="menu_icon_min menu_icon">
              <img src="../../../../public/image/wktImg/Combo.png"
                   alt="">
            </div>
            <div class="menu_text">
              优惠套餐
            </div>
            <div v-if="vIsShowDiscountPackage"
                 class="opt_view_btn"
                 @click.stop="clickModuleEvent(moduleNames.discountPackage)">
            </div>
          </div>
          <div class="menu_list_min menu_list hover_border_blue"
               :class="{opt_view_border:vIsShowExcellentTeacher}"
               :id="moduleNames.excellentTeacher"
               @click.stop="clickModuleEvent(moduleNames.excellentTeacher)">
            <div class="menu_icon_min menu_icon">
              <img src="../../../../public/image/wktImg/Teacher.png"
                   alt="">
            </div>
            <div class="menu_text">
              优秀教师
            </div>
            <div v-if="vIsShowExcellentTeacher"
                 class="opt_view_btn"
                 @click.stop="clickModuleEvent(moduleNames.excellentTeacher)">
            </div>
          </div>
          <div class="menu_list_min menu_list hover_border_blue"
               :class="{opt_view_border:vIsShowStarStudents}"
               :id="moduleNames.starStudents"
               @click.stop="clickModuleEvent(moduleNames.starStudents)">
            <div class="menu_icon_min menu_icon">
              <img src="../../../../public/image/wktImg/Student.png"
                   alt="">
            </div>
            <div class="menu_text">
              明星学员
            </div>
            <div v-if="vIsShowStarStudents"
                 class="opt_view_btn"
                 @click.stop="clickModuleEvent(moduleNames.starStudents)">
            </div>
          </div>
          <div class="menu_list_min menu_list hover_border_blue"
               :id="moduleNames.coupon"
               :class="{opt_view_border:vIsShowCoupon}"
               @click.stop="clickModuleEvent(moduleNames.coupon)">
            <div class="menu_icon_min menu_icon">
              <img src="../../../../public/image/wktImg/Coupon.png"
                   alt="">
            </div>
            <div class="menu_text">
              优惠券
            </div>
            <div v-if="vIsShowCoupon"
                 class="opt_view_btn"
                 @click.stop="clickModuleEvent(moduleNames.coupon)">
            </div>
          </div>
        </div>
        <!-- 热门推荐 -->
        <div class="WKT_home_view_information hover_border_blue"
             :class="{opt_view_border:vIsShowHotRecommendation}"
             :id="moduleNames.hotRecommendation"
             @click.stop="clickModuleEvent(moduleNames.hotRecommendation)">
          <div class="title_green"></div>
          <div class="WKT_home_view_title">
            热门推荐
          </div>
          <div class="WKT_home_view_information_ul">
            <div class="WKT_home_view_information_list"
                 v-for="(item,index) in hotRecommendationList"
                 :key="index">
              <div class="WKT_information_block">
                <div class="WKT_information_img">
                  <img-show :imgUrl="item.picurl"></img-show>
                </div>
                <div class="WKT_information_info">
                  <div class="WKT_information_name">
                    {{item.type==4?'优秀教师':'明星学员'}} | {{item.title}}
                  </div>
                  <div class="WKT_information_post"
                       v-if="item.courses">
                    主讲：{{item.courses}}
                  </div>
                  <div class="WKT_information_text"
                       v-html="item.content"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="vIsShowHotRecommendation"
               class="opt_view_btn"
               @click.stop="clickModuleEvent(moduleNames.hotRecommendation)">
          </div>
        </div>
        <!-- 联系我们 -->
        <div class="WKT_home_view_contact hover_border_blue"
             :id="moduleNames.contactUs"
             :class="{opt_view_border:vIsShowContactUs}"
             @click.stop="clickModuleEvent(moduleNames.contactUs)">
          <div class="title_green"></div>
          <div class="WKT_home_view_title">
            联系我们
          </div>
          <div class="WKT_home_view_contact_content">
            <div class="WKT_home_view_contact_info">
              <div class="WKT_home_view_contact_text">
                <div class="contact_name">
                  {{mendianInfo.name}}
                </div>
                <div class="contact_site">
                  {{mendianInfo.address}}
                </div>
              </div>
              <div class="WKT_home_view_contact_phone"> </div>
            </div>
            <div class="WKT_home_view_contact_ul">
              <div v-for="(imgItem,index) in mendianInfo.imgList"
                   :key="index"
                   class="WKT_home_view_contact_list">
                <img-show :imgUrl="imgItem.imgUrl"></img-show>
              </div>
            </div>
          </div>
          <div v-if="vIsShowContactUs"
               class="opt_view_btn"
               @click.stop="clickModuleEvent(moduleNames.contactUs)">
          </div>
        </div>
        <div class="WKT_footer_text">
          微网校由
          <span class="font_black">治学系统</span> 提供技术支持
        </div>
        <div class="footer_fixed_nav">
          <div class="nav_home nav_list hover_border_blue"
               :class="!vCourseName?'opt':''"
               @click.stop="clickModuleEvent(moduleNames.corporateImage)">
            <span>首页</span>
          </div>
          <div class="nav_JXT nav_list">
            <span>成长时光</span>
          </div>
          <!-- <div class="nav_award nav_list">
            <span>奖励</span>
          </div> -->
          <div class="nav_course nav_list">
            <span>课程表</span>
          </div>
          <div class="nav_my nav_list">
            <span>我的</span>
          </div>
        </div>
      </div>
    </div>
    <div class="WKT_index_phone_btn">
      <div class="btn_top"
           @click="preScrollEvent">

      </div>
      <div class="btn_bottom"
           @click="nextScrollEvent">

      </div>
    </div>
  </div>
</template>

<script>
import imgShow from '../common/img-show';
export default {
  data: function () {
    return {
      swiper: null,
      moduleNames: {
        corporateImage: 'corporate-image',
        organizationIntroduction: 'organization-introduction',
        hotActivity: 'hot-activity',
        coupon: 'coupon',
        discountPackage: 'discount-package',
        excellentTeacher: 'excellent-teacher',
        starStudents: 'star-students',
        hotRecommendation: 'hot-recommendation',
        contactUs: 'contact-us',
        courseName: 'course-name'
      },

      isShowOpenBtn: false
    };
  },
  props: {
    curSelectionMenuItem: Object,
    corporateImageData: Object,
    mendianInfo: Object,
    hotRecommendationList: Array
  },
  components: {
    imgShow
  },
  mounted () {

  },
  computed: {

    vMendianLogo () {
      return this.$store.getters.CDNURL + this.mendianInfo.logo;
    },
    vIsShowCorporateImage () {
      return this.curSelectionMenuItem.type == this.moduleNames.corporateImage;
    },
    vIsShowOrganizationIntroduction () {
      return this.curSelectionMenuItem.type == this.moduleNames.organizationIntroduction;
    },
    vIsShowHotActivity () {
      return this.curSelectionMenuItem.type == this.moduleNames.hotActivity;
    },
    vIsShowCoupon () {
      return this.curSelectionMenuItem.type == this.moduleNames.coupon;
    },
    vIsShowDiscountPackage () {
      return this.curSelectionMenuItem.type == this.moduleNames.discountPackage;
    },
    vIsShowExcellentTeacher () {
      return this.curSelectionMenuItem.type == this.moduleNames.excellentTeacher;
    },
    vIsShowStarStudents () {
      return this.curSelectionMenuItem.type == this.moduleNames.starStudents;
    },
    vIsShowHotRecommendation () {
      return this.curSelectionMenuItem.type == this.moduleNames.hotRecommendation;
    },
    vIsShowContactUs () {
      return this.curSelectionMenuItem.type == this.moduleNames.contactUs;
    },
    vIntroduce () {
      let content = this.mendianInfo.introduce;
      if (content) {
        content = this.$utils.rtrim(content);
      }
      return content;
    },
    vCourseName () {
      return this.curSelectionMenuItem.type == this.moduleNames.courseName;
    },
    vcorporateImageList () {
      let newArr = [];
      newArr = this.$utils.parseJson(this.corporateImageData.CorporateImageList || []);
      if (this.corporateImageData.BrandImgSupport) {
        let obj = {
          imgUrl: this.corporateImageData.BrandImgSupport
        };
        newArr.unshift(obj);
      }
      console.log(newArr, 'vcorporateImageList');
      return newArr;
    },
    vcorporateImagesUrl () {
      if (this.vcorporateImageList.length == 0) {
        return '';
      } else {
        return this.vcorporateImageList[0].imgUrl;
      }
    }
  },
  watch: {
    curSelectionMenuItem (newVal, oldVal) {
      if (newVal.type != oldVal.type) {
        this.scrollEvent();
      }
    },
    vcorporateImageList (c, o) {
      // if (c.length > 1 && this.swiper) {
      //   this.swiper.init();
      // }
    },
    'mendianInfo.introduce': {
      handler (newValue, oldValue) {
        console.log(newValue, 'mendianInfo.introduce');
        this.caculationContentHeight();
      },
      // deep: true,
      immediate: true
    }
  },
  methods: {
    swiperInit () {
      this.swiper = new Swiper('.view_swiper', {
        // direction: 'horizontal', // 垂直切换选项
        // loop: true // 循环模式选项
        pagination: {
          el: '.swiper-pagination'
        },
        paginationClickable: true,
        speed: 1000,
        loop: true,
        // loopAdditionalSlides: 3,
        // slidesPerView: 1,
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        }
      });
    },
    // 点击模块
    clickModuleEvent (moduleName) {
      this.$emit('onClickModuleEvent', moduleName);
    },
    preScrollEvent (e) {
      let scrollTop = this.$el.querySelector('.WKT_home_view').scrollTop;
      let scrollHeight = this.$el.querySelector('.WKT_home_view').scrollHeight;
      let clientHeight = this.$el.querySelector('.WKT_home_view').clientHeight;
      if (scrollTop > 0 && (scrollTop <= (scrollHeight - clientHeight) / 2)) {
        this.$el.querySelector('.WKT_home_view').scrollTop = 0;
      } else if (scrollTop > 0 && (scrollTop > (scrollHeight - clientHeight) / 2)) {
        this.$el.querySelector('.WKT_home_view').scrollTop = (scrollHeight - clientHeight) / 2;
      }
    },
    nextScrollEvent (e) {
      let scrollTop = this.$el.querySelector('.WKT_home_view').scrollTop;
      let scrollHeight = this.$el.querySelector('.WKT_home_view').scrollHeight;
      let clientHeight = this.$el.querySelector('.WKT_home_view').clientHeight;
      // if (scrollTop == 0 || (scrollTop < (scrollHeight - clientHeight) / 2)) {
      //   this.$el.querySelector('.WKT_home_view').scrollTop = (scrollHeight - clientHeight) / 2;
      // } else if (scrollTop == 0 || (scrollTop >= (scrollHeight - clientHeight) / 2)) {
      //   this.$el.querySelector('.WKT_home_view').scrollTop = scrollHeight;
      // }
      this.$el.querySelector('.WKT_home_view').scrollTop = (clientHeight / 2) + scrollTop;
    },
    // 滚动条
    scrollEvent (e) {
      if (this.curSelectionMenuItem.type == 'course-name') {
        return false;
      }
      let scrollHeight = this.$el.querySelector('.WKT_home_view').scrollHeight;
      let clientHeight = this.$el.querySelector('.WKT_home_view').clientHeight;
      let scrollTop = this.$el.querySelector('.WKT_home_view').scrollTop;
      let elHeight = this.$el.querySelector('#' + this.curSelectionMenuItem.type).getBoundingClientRect().height;
      let offsetTop = this.$el.querySelector('#' + this.curSelectionMenuItem.type).offsetTop;
      console.log(offsetTop, scrollHeight, elHeight, 'scrollHeight');
      console.log('scrollEvent', offsetTop, clientHeight);

      if (this.curSelectionMenuItem.type == this.moduleNames.corporateImage && scrollTop > 0) {
        this.$el.querySelector('.WKT_home_view').scrollTop = 0;
      } else {
        this.$el.querySelector('.WKT_home_view').scrollTop = offsetTop - (clientHeight / 2);
      }
      //  if ((offsetTop + elHeight) - scrollTop > clientHeight)
      // } else if (offsetTop > (scrollHeight - clientHeight - scrollTop)) {
      //   this.$el.querySelector('.WKT_home_view').scrollTop = offsetTop - elHeight;
      // }
    },
    caculationContentHeight () {
      setTimeout(() => {
        let contentHeight = document.getElementsByClassName('mendian_content')[0].scrollHeight - 12; // 内容告诉-padding
        let viewHeight = document.getElementsByClassName('mendian_content')[0].offsetHeight; // 可视高度
        let innerText = document.getElementsByClassName('content_text')[0].innerText; // 渲染内容
        console.log(contentHeight, viewHeight, 'contentHeight,viewHeight123');
        if (this.vIntroduce != innerText) { // 拿到的innerText与实际绑定的内容不等，说明数据渲染还没有完成，则在等200毫秒
          this.caculationContentHeight();
          return;
        }
        if (contentHeight > viewHeight) {
          this.isShowOpenBtn = true;
        } else {
          this.isShowOpenBtn = false;
        }
      }, 200);
    }
  }
};
</script>

<style scoped>
.long_icon {
  margin: 10px 10px !important;
}
.dfr {
  display: flex;
  flex-direction: row;
  width: 127px !important;
  height: 49px !important;
}
.bg_org {
  background: linear-gradient(90deg, #fea727 0%, #ffb442 100%);
  border-radius: 6px;
}
.long_text {
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 48px;
  color: #fff;
  padding-left: 12px;
}
.bg_lblue {
  background: linear-gradient(90deg, #4576ff 0%, #5e88fe 100%);
  border-radius: 6px;
}

.menu_list_min {
  width: 62px !important;
  height: 81px;
  border-radius: 4px;
  text-align: center;
}
.menu_icon_min {
  width: 40px;
  height: 40px;
  margin: 17px auto 8px;
}
.WKT_home_view_menuBox {
  border-top: inherit;
}
.mendian_title {
  border-bottom: inherit;
}
.WKT_home_view_information {
  margin-top: 0px;
}
.WKT_home_view_title {
  border-bottom: inherit;
}
.nav_JXT {
  background-image: url(../../../../public/image/wktImg/step_time.png) !important;
}
.WKT_home_view_contact:before {
  height: 0px;
}
.WKT_home_view_information:before {
  height: 0px;
}
.title_green {
  background: #49cd89;
  height: 20px;
  width: 5px;
  position: absolute;
  top: 9px;
  left: 10px;
}
.WKT_home_view_title {
  padding-left: 22px;
}
</style>