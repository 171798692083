<template>
  <div class="form_item form-input form_info_line"  :style="vWrapStyle"
       :class="{ form_info_required: required }">
    <div class="form_info_field" :class="isWrap?'form_info_field_wrap':''">
      <span>{{formTitle}} </span>
      <span v-if="helpDescription">
        <input-form-tip :tips='helpDescription'></input-form-tip>
      </span>
    </div>
    <div class="form_info_value" :class="[isWrap?'form_info_value_wrap':'',readonly?'input_readonly':'']">
      <textarea name="" id="" cols="30" rows="3"  :maxlength="4000"
               :value="value"
               :readonly="readonly"
               :placeholder="required?'必填':'选填'"
               @input="updateVal($event)"
               @change="changeVal($event)"></textarea>
      <!-- <input type="text"
             ref="groupName"
             :value="value"
             :placeholder="customPlaceholder?formPlaceholder:(required?'必填':'选填')"
             @input="updateVal($event)"
             @change="changeVal($event)" /> -->
    </div>
    <!-- <div v-else
         class="input_readonly form_info_value"
         @click.stop="amendVal()">
      {{value?value:''}}
      <p v-if="amendButton"
         class="input_edit_btn_CQ">修改</p>
    </div> -->
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: 'formInput',
  data () {
    return {};
  },
  props: {
    formTitle: {
      type: String,
      required: true
    }, // 标题，必填
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    amendButton: false, // 是否显示修改按钮，默认不显示
    formPlaceholder: String, // 默认文本，可选，如不提供，则默认显示title
    customPlaceholder: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: 100
    },
    isWrap:{        //是否换行显示。
      type:Boolean,
      default:false
    },
    helpDescription: String // 表单说明提示
  },
  computed: {
    vPlaceholder () {
      // 默认文本，如不提供，则默认显示title
      return this.formPlaceholder || this.formTitle;
    },
    vWrapStyle(){
      if(this.isWrap){
        return 'flex-wrap:wrap'
      }else{
        return ''
      }
    }
  },
  created () { },
  watch: {},
  methods: {
    updateVal (e) {
      // this.onkeyupEvent(e);
      this.$emit('input', e.target.value);
    },
    changeVal (e) {
      // this.onkeyupEvent(e);
      this.$emit('change', e.target.value);
    },
    // 修改按钮触发事件
    amendVal () {
      this.$emit('clickAmend');
    },
    limitMaxLength (str, maxLen) {
      var result = [];
      var re = /^[a-zA-Z]+$/;
      for (var i = 0; i < maxLen; i++) {
        var char = str[i];
        if (!re.test(char)) { maxLen--; }
        result.push(char);
      }
      return result.join('');
    },
    onkeyupEvent (e) {
      let val = e.target.value;
      if (this.$utils.strLength(val) > this.maxLength * 2) { e.target.value = this.limitMaxLength(val, this.maxLength * 2); }
    }
  }
};
</script>
<style scoped>
/* .form_info_value > span {
  display: inline !important;
} */
.input_edit_btn_CQ {
  color: #49b5f3;
  font-size: 0.25rem;
  float: right;
  padding-top: 0.1rem;
  border-bottom: 1px solid #49b5f3;
}
.form_info_field_wrap{
  width: 100%;
  min-width: 336px !important;
}
.form_info_value_wrap{
  width: 100%;
  padding-left: 0px !important;
  min-width: 336px !important;
}
.form_info_value_wrap textarea{
  width: 100%;
  border: 1px solid #ececec;
  color: #3498db;
}
.input_readonly textarea{
  color: #999!important;
}
.form_item .input_readonly{
  padding-right: 0px !important;
}

</style>
