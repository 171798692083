<!-- 弹框流量设置 -->
<template>
  <div>
    <div class="form_info_edit form_info_list"
         style="height: 130px">
      <input-select :formTitle="'流量扣费对象'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :dataList="sourceType"
                    @selectedItem="changeSourceType"></input-select>
    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { AdjustmentCurrentFluxObject } from '../../../../../API/workbench';
export default {
  name: 'flowsetForm',
  data () {
    return {
      // 直营类型
      sourceType: [
        { type: 1, name: '总部' },
        { type: 2, name: '门店' }
      ],
      dataInfo: {
        CampusKey: '', // "校区" ,
        CurrentFluxObject: '' // 下个月扣费对象 1-总部;2-门店
      }
    };
  },
  components: {

  },
  props: {
    campusInfo: {
      type: Object,
      default: {}
    }
  },
  created () {
    // console.log(this.$store.getters, 'initAddData');
    this.initData();
    this.dataInfo.CampusKey = this.campusInfo.OLAPKey;
    console.log(this.campusInfo, 'campusInfocampusInfo');
  },
  computed: {
  },
  methods: {
    initData () {
      console.log(this.campusInfo, 'initData');
    },
    // 选择类型
    changeSourceType (obj) {
      console.log(obj, '选择来源方式');
      if (obj.type == 1) {
        this.dataInfo.CurrentFluxObject = 1;
      } else {
        this.dataInfo.CurrentFluxObject = 2;
      }
    },
    // 提交
    submit (callback) {
      console.log('submit', this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      AdjustmentCurrentFluxObject(this.dataInfo.CampusKey, this.dataInfo.CurrentFluxObject).then(result => {
        layer.alert('修改成功');
        this.$emit('afterSuccess');
        this.cancel();
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callback) {
          callback();
        }
      });
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
      if (!this.dataInfo.CurrentFluxObject) {
        layer.alert('请选择流量设置');
        flag = false;
      }
      return flag;
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
