<template>
  <!-- 修改APPID -->
  <div>
    <div class="content_marginRL content_marginTop_30">
      <!-- 内容 -->
      <div class="form_info_edit mendian_setting_content single_shadow" >
        <!-- 内容 -->
        <div>
          <input-text :formTitle="'二维码路径'"
                      class="no_border_bottom" 
                      :required="false"
                      v-model="path"></input-text>
        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group :isSingleSubmit="true"
                            :singleSubmitType="'dynamic'"
                            @confirmClick="confirmClick"
                            @cancelClick="cancelClick"></save-cancel-btn-group>
   
  </div>

</template> 

<script>
import { GetQRCodeForExperience } from '../../../API/workbench';
export default {
  props: {
    appID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      path: ''
    };
  },

  components: {

  },

  created () {
  },
  methods: {
    // 确认按钮
    confirmClick (callBack) {
      console.log('confirmClick', this.path);
      GetQRCodeForExperience(this.path, this.appID).then(
        (res) => {
          this.$emit('getQRCodeSuccess', res.data);
          if (typeof callBack == 'function') {
            callBack();
          }
        },
        (err) => {
          layer.alert(err.msg);
          console.log(err);
          if (typeof callBack == 'function') {
            callBack();
          }
        }
      );
    },
    // 取消按钮
    cancelClick () {
      this.$emit('cancelClick');
    }
  }
};
</script>

  <style>
</style>