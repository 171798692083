<template>

  <div class="">
    <release-title :tips="'作业标题'"
                   :readonly="true"
                   v-model="releaseData.Title"></release-title>
    <!-- 学生 -->
    <div class="homework_publish_title_box">
      <div class="homework_publish_title"
           style="padding-left: 12px;">
        批改学生
      </div>
      <div class="homework_student_list"
           style="padding-left: 12px;">
        <div class="student_list_img">
          <div class="img_box">
            <heads-img :imgUrl="essayInfo.HeadImg"
                       :showUp="false"
                       :dataInfo="essayInfo"></heads-img>
          </div>
        </div>
        <div class="student_list_name">
          <div class="name_box">{{essayInfo.Author}}</div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="homework_publish_score no_border_bottom">
        <div>
          奖励学分
        </div>
        <div class="publish_score_ul">
          <score-item v-for="item in scoreList"
                      :key="item.index"
                      :scorekey="item.index"
                      :stuItem="releaseData"></score-item>
        </div>
      </div>
      <!-- 发布内容 标题 附件（视频 语音 图片） -->
      <release-content ref="releaseContent"
                       :dataInfo="releaseData"
                       :settingInfo="settingInfo"
                       :contentTitle="'批改详情'"
                       :placeholder="'请输入批改详情内容，最长支持1000字。'"
                       :className="'homework_publish_textBox'"></release-content>
    </div>
    <!-- 发布内容 标题 附件（视频 语音 图片） -->

    <!-- 发布按钮 -->
    <release-btn :btnText="'发送'"
                 :btnClass="vReleaseBtnClass"
                 :isFloatBtn="true"
                 :singleSubmitType="'dynamic'"
                 @click="submitClick"></release-btn>
  </div>
</template>

<script>
import { GetCorrectingHomeWorkDetails, CorrectingHomeWork, EditCorrectingHomeWork } from '../../../../API/workbench';
import releaseContent from '../../common-component/release-content/index';
import releaseTitle from '../../common-component/release-title';
import releaseBtn from '../../common-component/release-btn';
import scoreItem from '../../common-component/student-coin-details/score-item';
// 布置作业
export default {
  components: {
    releaseContent,
    releaseTitle,
    releaseBtn,
    scoreItem
  },

  data () {
    return {
      isEdit: false,
      releaseData: {
        essayID: 0,
        Title: '', // 标题,
        Content: '', // 内容,
        Currency: 0,
        RelateCourseID: 0, // 课ID,
        AttachList: []  // 附件列表 { AttachTypeKey: 1, // 1-图片; 2-视频;3-音频  AttachUrl: '../../../../public/image/01.jpg'}
      },
      essayInfo: {},
      settingInfo: {
        addVideoType: '3',
        isTeacher: 0,     // 是否老师
        showAddAudio: true,  // 是否显示录音
        formTitle: '',   // 主标题
        formTitleplaceholder: '',  // 主标题栏水印
        contentTitle: '',   // 内容标题
        placeholder: '请输入内容',  // 内容
        showAddPic: true,   // 显示添加图片
        showRelevance: true,    // 显示添加关联
        showUploadFile: false   // 显示上传文件

      },
      scoreList: [
        {
          index: 0
        },
        {
          index: 1
        },
        {
          index: 2
        }, {
          index: 3
        }, {
          index: 4
        }
      ]
    };
  },
  props: {
    dataInfo: { // 修改的作业信息
      type: Object,
      default: {}
    }
  },
  created () {
    console.log(this.dataInfo, '修改的作业信息');
    this.releaseData.essayID = Number(this.dataInfo.essayID) || 0;// 作业ID
    this.isEdit = this.dataInfo.isEdit || false;// 是否修改
    // 作业ID大于0表示修改状态 反之 新增
    if (this.releaseData.essayID) {
      this.getEditClassStudentsList();
    }

    this.releaseData.Title = this.dataInfo.courseName; // 课名
  },
  computed: {
    vReleaseBtnClass () {
      let className = 'no_confirm';
      if (this.releaseData.Content || this.releaseData.AttachList.length > 0) {
        className = 'btn_hover_bg_blue';
      }
      return className;
    },
    headImg () {
      if (this.essayInfo.HeadImg) {
        if (this.essayInfo.HeadImg.indexOf('http') > -1) {
          return this.essayInfo.HeadImg;
        } else {
          return this.$store.getters.CDNURL + this.essayInfo.HeadImg;
        }
      } else {
        return '';
      }
    },
    // 默认显示logo
    defaultImg () {
      if (this.essayInfo.sexKey == 2) {
        return 'this.src="' + require('../../../../../public/image/deflut_studentPhoto_boy.png') + '"';
      } else {
        return 'this.src="' + require('../../../../../public/image/deflut_studentPhoto_gril.png') + '"';
      }
    }
  },
  methods: {
    getEditClassStudentsList () {
      GetCorrectingHomeWorkDetails(this.releaseData.essayID).then(res => {
        console.log('getCourseInfoForFSC', res.data);
        if (this.isEdit) {
          this.releaseData.Content = res.data.Content;
          this.releaseData.AttachList = res.data.AttachList;
          this.releaseData.RelateCourseID = res.data.RelateCourseID || 0;
          this.releaseData.Currency = Number(res.data.Currency) || 0;

          this.essayInfo = res.data;
          this.essayInfo.Author = this.essayInfo.Student.StudentKeyValue;
          this.essayInfo.HeadImg = this.essayInfo.Student.HeadImg;
          this.essayInfo.AuthorID = this.essayInfo.Student.StudentKey;
          console.log(' this.releaseData', this.releaseData);
        } else {
          this.essayInfo = res.data;
          this.releaseData.RelateCourseID = res.data.RelateCourseID || 0;
          this.releaseData.Currency = Number(res.data.Currency) || 0;
        }
      }, err => {
        console.log(err);
      });
    },
    submitClick (callBack) {
      console.log('submitClick:this.releaseData', this.releaseData);
      if (this.vReleaseBtnClass == 'no_confirm') {
        if (typeof callBack == 'function') {
          callBack();
        }
        return;
      }
      if (this.releaseData.Content.length > 1000) {
        layer.alert('内容不能超过1000字');
        if (typeof callBack === 'function') {
          callBack();
        }
        return false;
      }
      let uploadingImgList = this.releaseData.AttachList.filter(obj => { return obj.AttachTypeKey == 1 && obj.AttachUrl.indexOf('data:image/') >= 0; });
      if (uploadingImgList.length > 0) {
        layer.alert('图片正在上传中，请稍后提交');
        if (typeof callBack == 'function') {
          callBack();
        }
        return false;
      }
      let imgList = this.releaseData.AttachList.filter(obj => { return obj.AttachTypeKey == 1; });
      if (imgList.length > 12) {
        layer.alert('图片不可超过12张');
        if (typeof callBack == 'function') {
          callBack();
        }
        return false;
      }
      const uploadingVideoList = this.releaseData.AttachList.filter(obj => {
        return obj.AttachTypeKey == 2 && obj.VideoCoverUrl == '';
      });
      if (uploadingVideoList.length > 0) {
        layer.alert('视频正在上传中，请稍后提交');

        if (typeof callBack === 'function') {
          callBack();
        }
        return false;
      }
      if (this.isEdit) {
        EditCorrectingHomeWork(this.releaseData.essayID, this.releaseData).then(result => {
          console.log('EditCorrectingHomeWork', result.data);
          this.$emit('afterSuccess');
          this.closeDialog();
        }, error => {
          if (typeof callBack == 'function') {
            callBack();
          }
          layer.alert(error.msg);
        });
      } else {
        CorrectingHomeWork(this.releaseData.essayID, this.releaseData).then(result => {
          console.log('CorrectingHomeWork', result.data);
          this.$emit('afterSuccess');
          this.closeDialog();
        }, error => {
          if (typeof callBack == 'function') {
            callBack();
          }
          layer.alert(error.msg);
        });
      }
    },
    closeDialog () {
      this.$emit('closeDialog');
    }
  }
};
</script>

<style>
</style>