<template>
  <div>
    <div>
      <btn-add-select :addTitleName="'新增'" :addmoduleName="'cws-add-teacher'" :addmoduleKey="0"
        :addmoduleTitleName="'新增老师'" @doAddSuccessEvent="doSelectedItem"></btn-add-select>
    </div>
    <div class="form_info_detail form_info_serach_box">
      <mutil-switch-btn v-if="mutilSwitchBtnList&&mutilSwitchBtnList.length>1" :btnList="mutilSwitchBtnList"
        @selectedItem="selectedSwitchBtnType"></mutil-switch-btn>
      <div class="student_info_supplement form_info">
        <div class="defult_dialog_searchform">
          <!-- 搜索框 -->
          <div class="search_form">
            <input type="text" v-model="searchText" class="search_input" :placeholder="placeholder" />
            <span v-if="searchText.length > 0" class="remove_btn" @click.stop="clearSearText"></span>
          </div>
        </div>
      </div>
      <!-- 搜索结果 -->
      <div class="search_result">
        <div class="search_result_content search_result_content_for_student">
          <div class="search_result_list">
            <!-- active 选中当前 -->
            <single-selection-item v-for="item in vDataSourceLsit" :key="item.OLAPKey" :item="item"
              @onSelectedItem="doSelectedItem"></single-selection-item>
            <div v-if="!required&&newDataSourceList.length>0" class="btn_fixed_bottom_placeholder"></div>
            <div v-if="vDataSourceLsit.length == 0">
              <div class="has_arrange_course">
                <div class="has_arrange_course_content" style="padding:0">
                  <div class="has_arrange_course_list">
                    <div class="has_arrange_course_nodata single_shadow">
                      <span>亲，当前没有任何数据哦~</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-if="!required&&newDataSourceList.length>0&&!footerButtonTitle" @click="cancelData"
            class="confirm_btn btn_fixed_bottom">
            <div class="btn_fixed_blue btn_hover_bg_blue" style="magin:0 auto">清空选择</div>
          </div>
          <div v-if="!required&&newDataSourceList.length>0&&footerButtonTitle" @click="seletedFooterBtn"
            class="confirm_btn btn_fixed_bottom">
            <div class="btn_fixed_blue btn_hover_bg_blue" style="magin:0 auto">{{footerButtonTitle}}</div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    CWSCommongetTeacherList
  } from '../../../../API/workbench'
  // import mutilSwitchBtn from '../head-quarters/basis-setting-module/hq-income-pay-subject-setting/mutil-switch-btn/list';
  import mutilSwitchBtn from '../../../head-quarters/basis-setting-module/hq-income-pay-subject-setting/mutil-switch-btn/list.vue';
  export default {
    components: {
      mutilSwitchBtn
    },
    data() {
      return {
        searchText: '',
        newDataSourceList: []
      };
    },
    props: {
      // dataSourceList: Array, // 数据源
      selectedKey: [String, Number], // 选中项key
      required: { // 是否必选项
        type: Boolean,
        default: false
      },
      placeholder: { // 搜索框水印
        type: String,
        default: ''
      },
     
      footerButtonTitle: {
        type: String,
        default: ''
      },
      mutilSwitchBtnList: Array // 是否开启选项卡 （例如 [{key: '', value: '', isSelection: '选中'},{}]）
    },
    created() {
      // this.newDataSourceList = this.dataSourceList;
      this.loadDataSourceTeacher()
      this.initSelectedItem();
    },
    watch: {
      selectedKey(n, o) {
        this.initSelectedItem();
      },
      dataSourceList(n, o) {
        console.log(n, 'single-selection-list');
        this.newDataSourceList = n;
      }
    },
    methods: {
      initSelectedItem() {
        this.newDataSourceList.forEach(obj => {
          if (obj.OLAPKey == this.selectedKey) {
            this.$set(obj, 'isSelected', true);
          } else {
            this.$set(obj, 'isSelected', false);
          }
        });
      },
      // 选中一项
      doSelectedItem(item) {
        console.log("doSelectedItem",item)
        this.searchText = '';
        this.$emit('afterSuccess', item);
        this.$emit('closeDialog');
        // this.newDataSourceList.forEach(obj => {
        //   if (obj.OLAPKey == item.OLAPKey) {
        //     this.$set(obj, 'isSelected', true);
        //   } else {
        //     this.$set(obj, 'isSelected', false);
        //   }
        // });
      },
      clearSearText() {
        this.searchText = '';
      },
      cancelData() {
        this.doSelectedItem({
          OLAPKey: 0,
          MainDemoName: ''
        });
      },
      seletedFooterBtn() {
        this.doSelectedItem({
          OLAPKey: 0,
          MainDemoName: this.footerButtonTitle
        });
      },
      // 切换选项卡
      selectedSwitchBtnType(obj) {
        console.log(obj, '123');
        this.newDataSourceList = obj.dataList;
        this.initSelectedItem();
      },
      // 调用在职老师接口
      loadDataSourceTeacher() {
        CWSCommongetTeacherList().then(result => {
          this.newDataSourceList = result.data;
        });
      }
    },
    computed: {
      vDataSourceLsit() {
        let newArr = [];
        // const PinyinMatch = require('pinyin-match');// 根据字母搜索
        if (this.searchText) {
          this.newDataSourceList.forEach(obj => {
            // var m = PinyinMatch.match(obj.MainDemoName, this.searchText);
            if (obj.MainDemoName.toUpperCase().indexOf(this.searchText.toUpperCase()) >= 0 || (obj
                .FirstLetterName && obj.FirstLetterName.toUpperCase().indexOf(this.searchText.toUpperCase()) >= 0
                )) {
              newArr.push(obj);
            }
          });
        } else {
          newArr = this.newDataSourceList;
        }
        return newArr;
      }
    }
  };

</script>
<style>
  .form_info_serach_box .bgGray_nav_box .bgGray_nav_list.opt {
    color: #fff;
    border-color: #3498db;
    background: #3498db;
  }

  .form_info_serach_box .bgGray_nav_box {
    padding-bottom: 0;
  }

</style>
