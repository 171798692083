<template>
  <div>
    <div class="form_info_edit form_info_list" style="margin-bottom:80px">
       <input-text
        :formTitle="'签约日期'"
        :required="false"
        :readonly="true"
        :formPlaceholder="'请输入'"
        v-model="dataJson.ApplicantTime"
      ></input-text>
      <input-number
        :formTitle="'合同金额'"
        :required="false"
        :readonly="true"
        :formPlaceholder="'请输入'"
        v-model="dataJson.ContractAmount"
      ></input-number>
      <input-text
        :formTitle="'付款类型'"
        :required="false"
        :readonly="true"
        :formPlaceholder="'请选择'"
        v-model="dataJson.PayTypeKeyValue"
      ></input-text>
      <input-number
        :formTitle="'付款金额'"
        :required="false"
        :readonly="true"
        :formPlaceholder="'请输入'"
        v-model="dataJson.PaymentAmount"
      ></input-number>
      <input-upload-img-file
              :ImgURl="dataJson.PrintScreen ? dataJson.PrintScreen : ''"
              :formTitle="'付款截图'"
              :readonly="isReadonly"
              :required="true"
              :imgstyle="'width: 20px!important;height:20px;z-index:10;'"
              @doAfterUpLoadImgSuccess="doSetBrandPath"
      ></input-upload-img-file>
      <input-dialog :formTitle="'收支账户'"
                    v-if="paymentType!='add'"
                        class=""
                        :formPlaceholder="'请选择'"
                        :readonly="isReadonly"
                        :required="true"
                        @showEldilog="showListDialog(1)"
                        :value="dataJson.incomePayAccountKeyValue"></input-dialog>
      <input-dialog :formTitle="'收支科目'"
                    v-if="paymentType!='add'"
                        class=""
                        :formPlaceholder="'请选择'"
                        :readonly="isReadonly"
                        :required="true"
                        @showEldilog="showListDialog(2)"
                        :value="dataJson.incomePaySubjectKeyValue"></input-dialog>
    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group
      v-if="!isReadonly"
      :isSingleSubmit="true"
      :singleSubmitType="'dynamic'"
      @confirmClick="confirmClick"
      @cancelClick="cancelClick"
    ></save-cancel-btn-group>
  </div>
</template>
<script>
import {HQGetPaymentRequestDetail, HQGetPaymentReviewDetail,HQPaymentRequest, HQPaymentReview, hqgetIncomeAccount, hqgetIncomeSubject} from '../../../../API/workbench';
export default {
  data () {
    return {
      dataJson: {
        ApplicantTime: '', // 字符串	签约日期
        ContractAmount: '', //	字符串	合同金额
        PayTypeKeyValue: '', //	字符串	付款类型
        PaymentAmount: '', //	字符串	付款金额
        PrintScreen: '', //	字符串	截图
        attractInvestSignContractKey: '', //	整型	合同id
        incomePayAccountKey: '', // 收支账户
        incomePayAccountKeyValue: '',
        incomePaySubjectKey: '',   // 收支科目
        incomePaySubjectKeyValue: ''
      },
      showList: false
    };
  },
  props: {
    OLAPKey: [Number,String],
    isReadonly: Boolean,
    paymentType: String
  },
  created () {
    this.getDataInfo();
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
  },
  methods: {
    getDataInfo () {
      if(this.paymentType=='add'){

        HQGetPaymentRequestDetail(this.OLAPKey).then(result => {
            result.data.incomePayAccountKey = '';// "杂费",
            result.data.incomePayAccountKeyValue = '';// "杂费",
            result.data.incomePaySubjectKey = '';// "杂费",
            result.data.incomePaySubjectKeyValue = '';// "杂费",
          
          this.dataJson = result.data;
          this.dataJson.ApplicantTime = this.dataJson.ApplicantTime.substring(0, 10);
          this.dataJson.PrintScreen=''
        }).catch(error => {
          console.log(error, 'error');
        });
      }else{
        HQGetPaymentReviewDetail(this.OLAPKey).then(result => {
          if (this.paymentType != 'add') {
            result.data.incomePayAccountKey = '';// "杂费",
            result.data.incomePayAccountKeyValue = '';// "杂费",
            result.data.incomePaySubjectKey = '';// "杂费",
            result.data.incomePaySubjectKeyValue = '';// "杂费",
          }
          this.dataJson = result.data;
          this.dataJson.ApplicantTime = this.dataJson.ApplicantTime.substring(0, 10);
        }).catch(error => {
          console.log(error, 'error');
        });
      }
    },
    doSetBrandPath (url) {
      this.dataJson.PrintScreen = url;
    },
    cancelClick () {
        this.$emit('closeDialog');
      },
    verifyData (callback) {
        if (this.paymentType == 'add') {
          if (!this.dataJson.PrintScreen) {
            layer.alert('请上传付款截图');
            if (callback) {
                callback();
              }
            return false;
          } else{
            return true;
          }
        } else{
          if (Number(this.dataJson.incomePayAccountKey) == 0) {
             layer.alert('请选择收支账户');
             if (callback) {
                callback();
              }
             return false;
           } else if (Number(this.dataJson.incomePaySubjectKey) == 0) {
            layer.alert('请选择收支科目');
            if (callback) {
                callback();
              }
            return false;
          }else {
            return true;
          }
        }
      },
    confirmClick (callback) {
        if (this.verifyData(callback)) {
            if (this.paymentType == 'add') {
              HQPaymentRequest(this.dataJson.PrintScreen, this.OLAPKey).then(result => {
                this.$emit('afterSuccess', this.dataJson);
                this.cancelClick()
              }).catch(error => {
                console.log(error, 'error');
              });
            } else{
              HQPaymentReview(this.dataJson).then(result => {
                this.$emit('afterSuccess', this.dataJson);
               this.cancelClick()
              }).catch(error => {
                console.log(error, 'error');
              });
            }
          }else {

          }
      },
    showListDialog (type) {
        if (type == 1) {
          this.getAcountList();
        } else{
          this.getSubjectList();
        }
      },
    doAfterSelectionAcount (item) {
        console.log('doAfterSelectionAcount', item);
        this.dataJson.incomePayAccountKey = item.OLAPKey;// "杂费",
        this.dataJson.incomePayAccountKeyValue = item.MainDemoName;// "杂费",
      },
    doAfterSelectionSubject (item) {
        console.log('doAfterSelectionSubject', item);
        this.dataJson.incomePaySubjectKey = item.OLAPKey;// "杂费",
        this.dataJson.incomePaySubjectKeyValue = item.MainDemoName;// "杂费",
      },
      // 获取所有财务科目列表
    getAcountList () {
      hqgetIncomeAccount().then(result => {
        this.dataList = result.data;
        console.log(result.data, '获取所有启用的数据');
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '收支帐户',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.dataJson.incomePayAccountKey, dataSourceList: this.dataList, required: true, addModuleName: '', addModuleTitleName: '添加杂费', ModulePowerKey: 0 },
          callBack: {
            onSelectedItem: (data) => { this.doAfterSelectionAcount(data); }
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    getSubjectList () {
      hqgetIncomeSubject().then(result => {
        this.dataList = result.data;
        console.log(result.data, '获取所有启用的数据');
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '收支科目',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.dataJson.incomePaySubjectKey, dataSourceList: this.dataList, required: true, addModuleName: '', addModuleTitleName: '添加杂费', ModulePowerKey: 0 },
          callBack: {
            onSelectedItem: (data) => { this.doAfterSelectionSubject(data); }
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    }
  }

};
</script>