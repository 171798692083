<template>
  <div class="margin_left_right_div">
    <div class="template_minOrmax_width_div">
      <div class="payment_statistics_view all_view">
        <content-chart></content-chart>
        <!-- <top-report @seletedDate="seletedDateAfter"
                @seletedEchartItem="seletedEchartItemAfter"></top-report> -->
        <!-- <content-report ref="contentReport"></content-report> -->
        <newContent></newContent>
        <dialog-factory ref="receiptAndPaymentStatistics"
                        :routerName="'receiptAndPaymentStatistics'"></dialog-factory>
        <dialog-factory-common ref="receiptAndPaymentFactoryCommon"></dialog-factory-common>
      </div>
    </div>
  </div>
</template>
<script>
import topReport from './top-report';
import contentReport from './content-report';
import contentChart from './content-chart';
import newContent from './new-content';
import dialogFactory from '../../common/dialog-factory';
export default {
  name: 'receiptAndPaymentStatistics',
  data () {
    return {

    };
  },
  components: {
    topReport,
    contentReport,
    newContent,
    dialogFactory,
    contentChart
  },
  mounted () {
    this.getCourseNameList();
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      console.log('openCustomDialog');
      if (dialogInfo.routerName == 'receiptAndPaymentStatistics') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      console.log(dialogInfo, 'openCommonCustomDialog');
      if (dialogInfo.routerName == 'receiptAndPaymentStatistics') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });
  },
  methods: {
    // 课名列表
    getCourseNameList () {
      this.$basicData.loadCourseNameList();
    },
    // 选中日期触发
    seletedDateAfter (timeObj) {        // 注释, 已经废弃,以防将来有用
      // this.$refs.contentReport.searchDataForParentByDate(timeObj);
    },
    // 选中饼图某项触发.
    seletedEchartItemAfter (item, type) {   // 注释,已经废弃, 以防将来有用
      // this.$refs.contentReport.searchDataForParentByIncomePaySubKey(item, type);
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log(dialogInfo, 'dialogInfodialogInfo');
      if (this.$refs.receiptAndPaymentStatistics) {
        this.$refs.receiptAndPaymentStatistics.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCommonCustomDialog', dialogInfo, this.$refs.classspendDialogFactoryCommon);
      if (this.$refs.receiptAndPaymentFactoryCommon) {
        this.$refs.receiptAndPaymentFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>