<template>
  <div v-if="isShowMainForm"
       id="Form">
    <custom-dialog title="个人详情"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowMainForm"
                   :before-close="doShowPopupHideClick">
      <div class="my_user_menu">
        <!-- <div class="defult_dialog_header">
          <span class="defult_dialog_header_return mint-toast-icon mintui mintui-back fl"
                @click="goback"></span>
          <span class="defult_dialog_header_font">我的</span>
        </div> -->
        <div id="screanContent"
             style="z-index:1;position: absolute !important;"></div>
        <div class="my_view"
             style="z-index:2">
          <div class="single_shadow content_marginRL content_marginTop_30 overflow_hidden">
            <div class="my_view_logo ">
              <img style="width:100%;position: absolute;top:0;z-index: 2;"
                   :src="vBrandImgSupport"
                   :onerror="vDefaultBrandImgSupport"
                   alt="">
              <div class="my_view_chg_pic"
                   v-show="vBrandImgSupport"
                   @click="checkPower">更换图片</div>
              <div class="no_logo"
                   style="position:absolute;top:0;z-index:3"
                   v-show="!vBrandImgSupport"
                   @click="checkPower">
                <div>
                  点击上传门店Logo或展示形象的最佳图片<br /> 尺寸：600*336
                </div>
              </div>
              <input v-show="vHasModulePower(38)"
                     type="file"
                     name="file"
                     title=""
                     ref="uploading"
                     accept="image/*"
                     v-on:change="selectImg"
                     class="mine_logo_upload"
                     :style="vBindStyleInputLoad"
                     data-type="userIconImg">
            </div>
            <div class="my_view_name_box">
              <div class="my_view_name_logo fl">
                <img :src="vlogo"
                     :onerror="vDefaultBrandImgSupport"
                     alt="">
              </div>
              <div class="my_view_name fl"
                   :title="menDianInfo.MainDemoName">{{menDianInfo.MainDemoName}}</div>
              <div class="my_view_name_setting fr"
                   @click="gomendiansettingform">门店设置</div>
            </div>
          </div>

          <div class="my_view_info single_shadow content_marginRL">
            <staff-item :staffInfo="staffInfo"
                        @selectImg="selectImg"></staff-item>
          </div>
          <div class="my_view_menu single_shadow content_marginRL">
            <div class="my_view_menu_list"
                 :class="vHasModulePower(57)?'':'none_bgu'"
                 @click="showCouponList">
              <div class="list_dual">个人推广招生活动</div>
              <div class="font_gray">{{staffInfo.SpreadConponKeyValue||''}}</div>
            </div>
            <div class="my_view_menu_list no_border_bottom"
                 @click.stop="myWDEWM">
              <div>我的二维码</div>
            </div>
          </div>
          <div class="my_view_help single_shadow content_marginRL clearfix">
            <div class="my_view_help_icon fl">
              系统帮助
            </div>
            <div class="my_view_help_content fl">
              <div class="content_list"
                   v-for="(item,index) in instructionsList"
                   :key="index"
                   :class="index==1?'no_border_bottom':''"
                   @click="openLink(item)">
                <div>【{{item.sname}}】{{item.title}}</div>
                <div style="padding-left:5px">{{item.title}}</div>
              </div>
              <!-- <div class="content_list no_border_bottom">
              【新手入门】如何新建课表?<br />
              如何新建课表?
            </div> -->
              <div v-if="instructionsList.length==0"
                   class="course_block_nodata"
                   style="min-height: 80px;background-position-y: 0px;padding-bottom:0">
                <div class="monitoring_content_nodata_txt"
                     style="padding-top:82px">亲，当前没有任何数据哦~</div>
              </div>
              <div v-if="instructionsList.length>0"
                   class="content_btn pa"
                   @click="toList">更多</div>
            </div>
          </div>
          <div class="my_view_versions single_shadow content_marginRL btn_marginBottom_30 ">
            <span>版本号</span>
            <span class="font_gray">{{vVersion}}</span>
            <div class="versions_btn"
                 @click="updateVersion">更新</div>
          </div>
          <!-- <div class="my_view_btn_list"
              @click="goRouter('myqhmd')">
            切换门店
          </div>
          <div class="my_view_btn_list font_red"
              @click="myTCDL">
            退出登录
          </div> -->
        </div>
        <!-- 二维码显示 -->
        <div v-if="isPopupVisible">
          <div class="my_user_menu">
            <!-- <div class="shade"></div> -->
            <div class="user_QR box_shadow">
              <div class="user_QR_title">个人推广码</div>
              <!-- 二维码模块-->
              <div class="self_code clearfix"
                   v-if="noneShowtowCodeErrortip">
                <img id="twocodesave"
                     :src="twoCode"
                     alt="请确认是否已开通微网校并且微信参数配置正确" />
                <div @click="downloadCode"
                     class="btn_fixed_blue btn_hover_bg_blue saveCode">下载二维码</div>
              </div>
              <!-- 没有时 二维码显示 -->
              <div class="no_self_code"
                   v-else>请确认是否已开通微网校<br />并且微信参数配置正确</div>
              <div class="turnoff"
                   @click="isPopupVisible = false"></div>
            </div>
          </div>
        </div>

      </div>
    </custom-dialog>
    <custom-dialog :title="'裁切图片'"
                   class=""
                   :visible.sync="isChgPic"
                   :before-close="chgisChgPic">
      <vue-crop-img :selectPicUrl="selectPicUrl"
                    :cutImgWidth="picType == 'mendian'?600:101"
                    :cutImgHeight="picType == 'mendian'?336:101"
                    :uploadTargetPath="picType == 'mendian'?'WKT':'PC'"
                    @onConfirmClick="setPicCorp"
                    @onCancelClick="clearPicData"></vue-crop-img>
    </custom-dialog>
    <coupon-list-for ref="customPopup"
                     :formTitle="'个人推广招生活动列表'"
                     :switchItem="1"
                     :SpreadConponKey="staffInfo.SpreadConponKey"
                     @return="backToMain"
                     @selectedItem="doAfterSelectedCoupon">
    </coupon-list-for>
  </div>
</template>
<script>
import { editEmployeeHeadImg, updateTheSaaSClientMsg, GetSaaSClientMsg, GetTheEmployeeByID, SearchInstructionsForData, editEmployeePromotionActive, getEmployeeQRCode } from '../../../API/workbench.js';
import staffItem from './staff-item';
import couponListFor from '../../common-module/search-list/coupon-list-for';
export default {
  data () {
    return {
      menDianInfo: {},
      isPopupVisible: false,      // 二维码显示层
      noneShowtowCodeErrortip: true,
      token: {},
      twoCode: '', // 二维码的URL地址
      staffInfo: {},  // 员工信息
      instructionsSearch: {     // 最新资讯查找条件
        pageIndex: 0,
        pageSize: 2,
        searchText: '',
        orderBy: '',
        sequence: ''
      },
      instructionsList: [],  // 最新资讯列表
      selectPicUrl: '',          // 选中图片url
      crood2: {},           // 截图坐标之类
      isChgPic: false,
      preWHRatio: 1,
      nextElWidth: 0,
      nextElHeight: 0,
      nextWHRatio: 0,
      selectedCurrentTarget: null,
      picType: '',
      modulePowerList: [],
      isShowMainForm: false
    };
  },
  components: {
    staffItem, couponListFor
  },
  created () {
  },
  mounted () {
  },
  computed: {
    vBrandImgSupport () {
      if (this.menDianInfo && this.menDianInfo.BrandPath) {
        if (this.menDianInfo.BrandPath.indexOf('http') == -1) {
          return this.$store.getters.CDNURL + this.menDianInfo.BrandPath;
        } else {
          return this.menDianInfo.BrandPath;
        }
      } else {
        return '';
      }
    },
    // 控制input的样式.
    vBindStyleInputLoad () {
      if (this.vBrandImgSupport) {
        return 'width:70px;right:15px;top:185px;height:30px;opacity: 0;z-index: 10;position: absolute';
      } else {
        return 'position: absolute;width:100%;right:0px;top:0px;height:100%;opacity: 0;z-index: 10;';
      }
    },
    vlogo () {
      if (this.menDianInfo && this.menDianInfo.LogoPath) {
        if (this.menDianInfo.LogoPath.indexOf('http') == -1) {
          return this.$store.getters.CDNURL + this.menDianInfo.LogoPath;
        } else {
          return this.menDianInfo.LogoPath;
        }
      } else {
        return '';
      }
    },
    vDefaultBrandImgSupport () {
      return 'this.src="' + require('../../../../public/image/no_logo@2x.png') + '"';
    },
    vDefaultImg () {       // 默认头像.
      return 'this.src="' + require('../../../../public/image/deflut_studentPhoto_boy.png') + '"';
    },
    vVersion () {
      return document.title;
    }
  },
  watch: {

  },
  methods: {
    checkPower () {
      if (!this.vHasModulePower(38)) {
        this.$utils.CheckModulePowerToTips(38);
      }
    },
    qrcodeDownload () {
      if (this.twoCode) {
        let src = this.twoCode;
        // src = src.replace('cdn', '');
        src = src.replace(':8999', '');
        console.log(src);
        var canvas = document.createElement('canvas');
        var img = document.createElement('img');
        img.setAttribute('crossOrigin', 'Anonymous');
        img.onload = function (e) {
          canvas.width = img.width;
          canvas.height = img.height;
          var context = canvas.getContext('2d');
          context.drawImage(img, 0, 0, img.width, img.height);
          canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
          canvas.toBlob((blob) => {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = '我的二维码';
            link.click();
          }, 'image/jpeg');
        };
        img.src = src;
        // console.log(img);
      }
    },

    downloadWKTFile (fileUrl) {
      var a = document.createElement('a');
      var event = new MouseEvent('click');
      a.download = '';
      a.href = fileUrl;
      a.dispatchEvent(event);
      // if (document.getElementById('#downloadWKTFile')) {
      //   document.getElementById('#downloadWKTFile').remove();
      // }
      // var iframe = document.createElement('iframe');
      // iframe.setAttribute('id', 'downloadWKTFile');
      // iframe.src = this.$store.getters.PCWebURL + '/downfile.html?fp=' + fileUrl;
      // document.getElementById('Form').appendChild(iframe);
      // document.getElementById('downloadWKTFile').style = { 'width': '0px', 'height': '0px' };
      // var event = new MouseEvent('click');
      // iframe.dispatchEvent(event);
    },
    // 下载二维码
    downloadCode () {
      this.qrcodeDownload()
      // var imgs = document.getElementById('twocodesave');
      // this.downloadWKTFile(imgs.src);
    },
    // 主表单开关
    doShowPopupHideClick () {
      this.isPopupVisible = false;
      this.isShowMainForm = false;
    },
    doShowPopupShowClick () {
      this.isShowMainForm = true;
      this.token = this.$store.getters.token;
      this.modulePowerList = this.$store.getters.RolePowerList;
      this.getMenDianInfo(this.token.SaaSClientKey);
      this.getStaffInfo(this.token.UserID, () => {
      });
      this.getSearchInstructions();
    },
    // 获取员工信息.
    getStaffInfo (OLAPKey, callback) {
      GetTheEmployeeByID(OLAPKey).then(result => {
        this.staffInfo = result.data;
        if (callback) {
          callback();
        }
        console.log('getStaffInfo', result.data);
      }).catch(error => {
        console.log('getStaffInfo', error);
      });
    },
    // 获取门店信息.
    getMenDianInfo () {
      GetSaaSClientMsg().then(result => {
        this.menDianInfo = result.data;
        this.menDianInfo.BrandPath = this.menDianInfo.BrandPath || '';
        console.log('token&门店', this.menDianInfo);
      }).catch(error => {
        console.info(error);
      });
    },
    // 我的-菜单(我的二维码)
    myWDEWM () {
      this.isPopupVisible = true;
      getEmployeeQRCode(this.token.UserID).then(result => {
        if (result.data) {
          this.twoCode = this.$store.getters.CDNURL + result.data;

        } else {
          this.noneShowtowCodeErrortip = false;
        }
      }, errer => {
        this.noneShowtowCodeErrortip = false;
        layer.alert(errer.msg);
      });
    },
    gomendiansettingform () {
      let item = {
        routerName: 'mendiansettingform',
        routertitle: '门店设置',
        moduleName: ''
      };
      this.$bus.emit('needOpenRouter', item);
    },
    goback () {
      document.body.style.overflow = 'auto';
      if (location.href.indexOf('timestamp') != -1) {
        this.$router.go(-2);
      } else {
        this.$router.go(-1);
      }
    },
    // 我的-菜单(退出登录)
    myTCDL () {
      this.$fun.loginInfo.delCookie('token');
      this.$router.push('/login');
    },
    // 更新版本.
    updateVersion () {
      window.location.reload();
    },
    // -使用说明查询
    getSearchInstructions () {
      SearchInstructionsForData(this.instructionsSearch).then(result => {
        this.instructionsList = result.data.PageDataList;
      }).catch(error => {
        console.log(error, 'getSearchInstructionsError');
      });
    },
    // 跳转到详情页.
    openLink (item) {
      if (window.location.host.indexOf('zx.zhixuerj.com') !== -1) { // 产品环境
        window.open('http://www.sztuoke.com/content/' + item.id + '.html ');
        // window.open(Link);
      } else { // 非产品环境
        window.open('http://webtest.wktdevtest.zhixuerj.com/content/' + item.id + '.html');
        // window.open(Link);
      }
    },
    // 跳转到列表
    toList () {
      // let Link = '';
      if (window.location.host.indexOf('zx.zhixuerj.com') !== -1) { // 产品环境
        window.open('http://www.sztuoke.com/news');
        // window.open(Link);
      } else { // 非产品环境
        window.open('http://webtest.wktdevtest.zhixuerj.com/news');
        // window.open(Link);
      }
    },
    // 判断权限
    vHasModulePower (key) {
      return this.$utils.CheckModulePower(this.$store.getters.RolePowerList, key);
    },
    showCouponList () {
      if (this.vHasModulePower(57)) {
        this.$refs.customPopup.doShowPopupShowClick();
      }
      // else {
      //   this.$utils.CheckModulePowerToTips(57);
      // }
    },
    backToMain () {
      this.$refs.customPopup.isShowMainForm = false;
    },
    // 选中券
    doAfterSelectedCoupon (item) {
      console.log(item);
      this.chgPromotionActive(item.id, item.coupon_name);
    },
    // 更改个人推广活动
    chgPromotionActive (ActiveId, ActiveName) {
      editEmployeePromotionActive(this.staffInfo.OLAPKey, ActiveId, ActiveName).then(result => {
        layer.alert('更新个人推广活动成功');
        this.staffInfo.SpreadConponKeyValue = ActiveName;
        this.staffInfo.SpreadConponKey = ActiveId;
        this.backToMain();
        console.log(result, 'editEmployeePromotionActive');
      });
    },
    selectImg (e, type, callback) {
      let fileName = e.target.files[0].name
      let typeText = '.jpg,.jpeg,.png'
      if (typeText.indexOf(fileName.substring(fileName.toLowerCase().lastIndexOf('.'), fileName.length)) == -1) {
        this.clearPicData();
        layer.alert('上传文件类型不符合，只能上传.jpg,.jpeg,.png类型文件');
        return false
      } else {
        this.picType = type || 'mendian';
        if (e.target.files.length > 0) {
          var reader = new FileReader();	// 实例化一个FileReader对象，用于读取文件
          var that = this;
          reader.onload = function (evt) {
            that.selectPicUrl = evt.target.result;
            that.$nextTick(() => {
              that.isChgPic = true;
              if (callback) {
                callback();
              }
            });
          };
          reader.readAsDataURL(e.target.files[0]);
        }
      }
    },
    chgisChgPic () {
      this.isChgPic = false;
    },
    // 选中图片渲染后将input里面的值清除, 防止下次选中同一个文件无效.
    setInputValue () {
      var loadInput = document.getElementsByClassName('mine_logo_upload')[0];
      var loadInput2 = document.getElementsByClassName('staff_upload')[0];
      loadInput.value = '';
      loadInput2.value = '';
    },
    // 确认裁切
    setPicCorp (imgUrl) {
      if (this.picType == 'mendian') {
        this.menDianInfo.BrandPath = imgUrl;
        this.updateMendianInfo(this.menDianInfo);
      } else {
        this.staffInfo.XMLIcon = imgUrl;
        this.updateEmployeeHeadImg(this.staffInfo.OLAPKey, this.staffInfo.XMLIcon);
      }
      this.clearPicData();
    },
    clearPicData () {
      document.body.style.overflow = 'auto';
      this.isChgPic = false;
      this.selectPicUrl = '';
      if (this.$refs.uploading) {
        this.$refs.uploading.value = '';
      }
    },
    // 获取裁切数据
    afterCrop2 (json, url) {                           // 获取裁切的坐标XY及大小
      // console.log('afterCrop2', this.crood2);
      this.crood2 = json;
      this.selectPicUrl = url;
    },
    updateMendianInfo (data) {
      let newData = {
        MainDemoName: data.MainDemoName, // 门店名称
        Telephone: data.Telephone, // 门店Tel
        Address: data.Address, // 门店地址
        LogoPath: data.LogoPath, // 门店Logo
        BrandPath: data.BrandPath, // 门店形象图
        WktInfo: data.WktInfo
      };
      updateTheSaaSClientMsg(newData).then(result => {
        console.log('updateTheSaaSClientMsg', result);
      });
    },
    updateEmployeeHeadImg (OLAPKey, url) {
      editEmployeeHeadImg(OLAPKey, url).then(result => {
        console.log('updateEmployeeHeadImg', result);
      });
    }
  }
};
</script>
<style scoped>
.layer-content {
  position: absolute;
  outline: 0;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  color: #2b2e38;
  background: #fff;
  display: inline-block;
  vertical-align: middle;
  z-index: 1500;
  /* transform: translateY(-50%); */
}
</style>

