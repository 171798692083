<template>
  <div class="form_item form-input  form_info_line"
       :class="{ form_info_required: required }">
    <div class="form_info_field ">
      {{formTitle}}
    </div>
    <div class="form_info_value pr button"
         @click.stop="downLoadFile">
      <div style="position: absolute;top: 1px;right: 48px;"
           v-if="!value"
           class="font_blue">{{uploadTitle}}</div>

      <input type="text"
             v-if="value"
             :value="value"
             readonly
             placeholder="请上传">
      <img src="../../../public/image/upload_default_pic.png"
           v-if="value"
           :onerror="defaultImg"
           alt="../../../public/image/upload_default_pic.png"
           class=""
           style="width: 20px !important;height: 20px;z-index: 10;margin-right: 20px;">
    </div>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
import { UploadFiles } from '../../API/workbench';
export default {
  name: 'inputSelect',
  data () {
    return {
      selectPicUrl: '',          // 选中图片url
      isChgPic: false,
      imgURlString: this.ImgURl || '',
      imgstyleSize: this.imgstyle  // 裁切大小
    };
  },
  props: {
    value: [String, Number, Boolean],
    required: Boolean,
    formTitle: String,
    readonly: {// 是否只读，
      type: Boolean,
      default: false
    },
    AttachUrl: String,
    uploadTitle: {
      type: String,
      default: '请上传'
    },
    isDownLoad: {  //只读模式下是否允许下载
      type: Boolean,
      default: false
    },
    helpDescription: String, // 表单说明提示

  },
  computed: {
    headImg () {
      if (!this.imgURlString) {
        this.imgURlString = '';
      }
      if (this.imgURlString.indexOf('http') > -1) {
        return this.imgURlString;
      } else {
        return this.$store.getters.CDNURL + this.imgURlString;
      }
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../public/image/no_logo@2x.png') + '"';
    }
  },
  created () {
  },
  watch: {
    ImgURl (n, c) {
      this.imgURlString = n;
    }
  },
  methods: {
    downLoadFile () {
      console.log("isDownLoad", this.value, this.AttachUrl)
      if (this.AttachUrl) {
        let url = this.$store.getters.CDNURL + this.AttachUrl;
        let link = document.createElement('a');
        // 这里是将url转成blob地址，
        fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
          link.href = URL.createObjectURL(blob);
          console.log(link.href);
          link.download = '';
          link.download = '下载合同';
          document.body.appendChild(link);
          link.click();
        });
      }

    }
  }
};
</script>

 <style scoped>
.form_info_value_readonly input {
  color: #999 !important;
}
.form_info_value input {
  width: 80%;
  padding-right: 0px;
}
</style>