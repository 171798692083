<template>
  <div class="clearfix login_bj">
    <header-bar v-if="vIsShowHeadBar && isRouterShow"
                :screenWidth="screenWidth"></header-bar>
    <popup-dialog-shade></popup-dialog-shade>
    <div :id="$route.name"
         v-if="isRouterShow">
      <keep-alive :include="vIncludeRouterList">
        <router-view :key="$route.name"></router-view>
      </keep-alive>
    </div>
    <up-versions-popup v-if="isShowUpVersions"
                       @hiddenPopup="hiddenUpVersions"></up-versions-popup>
  </div>
</template>

<script>
import { GetSaaSClientMsg, GetNewToken, UploadFiles, AddInterfaceLog, UpgradeDeviceCode, GetAgentSetting } from './API/workbench.js';
import headerBar from './components/new-header-bar/index.vue';
import { outLogin } from './assets/login';
import { loadMoudlePowerData } from './utils/fetch';
export default {
  name: 'App',
  provide () {
    return {
      reload: this.reload

    };
  },
  data () {
    return {
      isRouterAlive: true,
      isRouterShow: true,
      freshInterval: null,
      isShowUpVersions: false,
      screenWidth: 1920
    };
  },
  components: {
    headerBar
  },
  computed: {
    vIsShowHeadBar () {
      return this.$route.name != 'login' &&
        this.$route.name != 'cloudClassAuthenticationDetails' &&
        this.$route.name != 'coursewareTvTeaching' &&
        this.$route.name != 'prestrainPage' &&
        this.$route.name.indexOf('teachingMode') == -1;
    },
    vIncludeRouterList () {
      if (this.isRouterShow) {
        let includeList = [];
        this.$store.getters.headerBarList.forEach((obj) => {
          includeList.push(obj.routerName);
        });
        console.log('vIncludeRouterList'.includeList);
        return includeList.join(',');
      } else {
        return '';
      }
    }
  },
  created () {
    // window.brandCustomSettingStr='{"OLAPKey":"10063","Ifleaf":null,"SourceKey":"","IfDel":"0.0000","GenerateTime":"2024-04-23 18:19:59","EditTime":null,"ShowOrder":"0","SaaSClientKeyValue":null,"DesktopDownUrl":"","AppDownUrl":"","PCEditionKey":"0","APPEditionKey":"0","StatusKey":"0","DesktopDown64Url":"","DesktopDown32Url":"","AppIsPublishKey":"0","AppIsPublishKeyValue":"","DesktopIsPublishKey":"0","DesktopIsPublishKeyValue":"","TeachingBackground":"","SaaSClientKey":"1277","BrandSlogan":"好好学习，天天向上","QRCode":"/Upload/Resource/1/Image/20240423871203.png","LoginBackground":"/Upload/Resource/1/Image/20240423386343.png","MainBodyColor":"red","LogoUrl":"/Upload/Resource/1/Image/20240423520499.png","MainDemoName":"扶摇","Icon":"/Upload/Resource/1/Image/20240423158019.png","TVStartImg":"/Upload/Resource/1/Image/2024042387271.png","APPStartImg":"/Upload/Resource/1/Image/20240423721303.png","DesktopStartImg":"/Upload/Resource/1/Image/20240423319836.png","IOSIsPublish":null,"AppPackName":"扶摇","AppID":"111","IOSSignPrivateKey":null,"IOSSignDescribe":null,"IOSSignPassword":null,"IOSMapKey":null,"IOSAppDownUrl":null,"AndroidMapKey":"111","Type":"4","NewTVAPPID":"111","NewTVPackName":"111","HomePageUrl":"/Upload/Resource/1/Image/20240430512460.png","HomePageVideo":"/Upload/Resource/1/Video/[ CF ] KT Oresigi网络上加上充满活力（面试）_爱给网_aigei_com.mp4","AdvertisingConfig":[{"MainDemoName":"广告位1","imgUrl":"/Upload/Resource/1/Image/20240429294151.png","videoUrl":"/Upload/Resource/1/Video/2.5M.mp4"},{"MainDemoName":"广告位2","imgUrl":"/Upload/Resource/1/Image/20240429213107.png","videoUrl":"/Upload/Resource/1/Video/2.5M.mp4"},{"MainDemoName":"广告位3","imgUrl":"/Upload/Resource/1/Image/20240429953387.png","videoUrl":"/Upload/Resource/1/Video/2.5M.mp4"}],"WebUrl":"zxbeta.zhixuerj.com"}'
    this.GetRequest();
    this.init();
    window.UploadFiles = UploadFiles;
    this.freshInterval = setInterval(() => {
      this.checkLoginChange();
    }, 500);
    this.$bus.on(this, 'wsAfterUpdateAgentInfo', (data) => {
      this.getNewToken(this.$store.getters.token);
    });
    let ModulePowerSetting = window.localStorage.ModulePowerSetting;
    console.log('window.localStorage', ModulePowerSetting);
    // 获取权限
    // if (!ModulePowerSetting) {
    // 先从本地存储加载配置，有可能不存在，本地有，则可以同步加载
    // this.getMoudlePowerList();
    // this.getHQMoudlePowerList();
    // }else{
    // this.$store.commit('setModulePowerSetting', ModulePowerSetting);
    // }
  },
  mounted () {
    this.$bus.on(this, 'needOpenRouter', (moduleInfo) => {
      this.openRouter(moduleInfo);
    });
    this.$bus.on(this, 'reLoadPage', (callbackInitData) => {
      this.reLoadPage(callbackInitData);
    });


    if (this.$zxClient.IsClient) {
      this.upVersions();
    }
    window.onresize = () => {
      return (() => {
        console.log("document.body.clientWidth", document.body.clientWidth)
        if (document.body.clientWidth >= 1920) {
          this.screenWidth = 1920
        } else if (document.body.clientWidth > 1366) {
          this.screenWidth = document.body.clientWidth
        } else {
          this.screenWidth = 1366
        }
        this.$bus.emit("screenOnResize",this.screenWidth)
        this.initSetRem();
      })();
    };
  },
  methods: {
    // 动态计算rem的值
    initSetRem () {
      var docEl = document.documentElement;
      var width = docEl.getBoundingClientRect().width;
      var rem = 0;
      var rem = width / 750;
      docEl.style.fontSize = rem + 'px';
      // 比例是0.390625
    },
    reLoadPage (callbackInitData) {
      console.log('reLoadPage');
      this.isRouterShow = false;
      this.$nextTick(() => {
        callbackInitData(() => {
          this.$websocket.init();
          this.isRouterShow = true;
          this.$router.push({
            path: this.$utils.getIndexPath()
          });
        });
      });
    },
    getMoudlePowerList () {
      loadMoudlePowerData('', (result) => {
        console.log('loadMoudlePowerDataresult', result);
        window.ModulePowerSetting = result.data;
        localStorage.setItem('ModulePowerSetting', result.data);
        console.log('window.ModulePowerSetting', window.ModulePowerSetting);
      });
    },
    getHQMoudlePowerList () {
      loadMoudlePowerData('HQ', (result) => {
        console.log('HQModulePowerSetting', result);
        window.HQModulePowerSetting = result.data;
        console.log('window.ModulePowerSetting', window.HQModulePowerSetting);
        localStorage.setItem('HQModulePowerSetting', result.data);
      });
    },
    init () {
      let wktDomainURL = '';
      let PCWebURL = '';
      let PCAPIURL = '';
      let CDNURL = '';
      let APIURL = '';
      let WSURL = '';
      let ProductENV = '';

      clearInterval(this.freshInterval);
      if (process.env.NODE_ENV === 'development') {
        wktDomainURL = process.env.VUE_APP_WKT_DomainURL;
        PCAPIURL = process.env.VUE_APP_PC_API_URL;
        CDNURL = process.env.VUE_APP_CDN_URL;
        APIURL = process.env.VUE_APP_APIURL;
        WSURL = process.env.VUE_APP_WS_URL;
        PCWebURL = process.env.VUE_APP_PC_WEB_URL;
        ProductENV = 'dev';
      } else {
        let config =
          window.location.protocol == 'http:'
            ? window.zxConfig
            : window.zxConfigSSL;
        wktDomainURL = config.WKT_DomainURL;
        PCAPIURL = config.PC_API_URL;
        CDNURL = config.CDN_URL;
        APIURL = config.APIURL;
        WSURL = config.WS_URL;
        PCWebURL = config.PC_WEB_URL;
        ProductENV = config.Product_ENV;
      }
      this.$store.commit('SetDomainURL', {
        wktDomainURL,
        PCAPIURL,
        APIURL,
        CDNURL,
        WSURL,
        PCWebURL,
        ProductENV
      });
      window.CDNURL = CDNURL;  // ueditor 使用
      window.APIURL = APIURL; // ueditor 使用
      this.$store.commit('selectNewAPIURL');
      let tokenStr = this.$fun.loginInfo.getCookie('token');
      if (tokenStr && tokenStr != 'null') {
        this.initToken(JSON.parse(tokenStr));
      } else {
        console.log('APP退出记录1');
        if (this.vIsShowHeadBar) {
          this.$router.push('/login');
        }
      }

      this.$store.commit('saveStore');

      this.$bus.on(this, 'upgradedevicecode', () => {
        console.log('upgradedevicecode on');
        let minfo = JSON.parse(this.$zxClient.GetMInfo());
        console.log('minfo', minfo);
        UpgradeDeviceCode(minfo).then(result => {
          this.$zxClient.SetUCode(result.data.DeviceUCode);
          layer.confirm('设备授权验证机制升级成功，需要刷新', {
            btn: [
              {
                name: '确认',
                callBack: function () {
                  location.reload();
                  layer.close();
                }
              }
            ]
          });
        }).catch(error => {
          if (error.code == 80003) {
            this.$zxClient.SetUCode('');
          } else {
            console.error('UpgradeDeviceCode', error);
          }
        });
      });

      this.$bus.on(this, 'zxsendclientlog', (logtext) => {
        console.log('zxsendclientlog on');
        let interfaceLogData = {
          InterfaceName: APIURL + 'client',
          parameter: this.$zxClient.GetVersion(),
          PostData: JSON.stringify(this.$store.getters.token),
          LogContents: logtext,
          LogTitleName: '桌面端提交本地日志',
          RefererName: window.location.href
        };

        // 判断是否为门店端日志记录接口，如果是则不再记录（跳过）。
        // 如果不是，则提交异常到门店端日志记录接口。
        AddInterfaceLog(interfaceLogData).then(result => {
          let customerMsg = '提交成功，感谢您的反馈,日志代码为L-{code}';
          customerMsg = customerMsg.replace('{code}', result.data);
          // eslint-disable-next-line no-alert
          window.alert(customerMsg);
        });
      });
    },
    initToken (token) {
      this.$store.commit('SetToken', token);
      this.$fun.loginInfo.SetToken(token);
      this.getNewToken(token);
      GetSaaSClientMsg().then((result) => {
        this.$store.commit('setSaaSClientInfo', result.data);
        this.getMoudlePowerList();
        this.getHQMoudlePowerList();
      });
      GetAgentSetting().then(res => {
        console.log('setAgentSettingInfores.data', res);
        localStorage.setItem('AgentSettingInfor', JSON.stringify(res.data));
        // this.$store.commit('setAgentSettingInfo', res.data);
      });
      this.$nextTick(() => {
        this.$websocket.init();
      });
    },
    getNewToken (token) {
      GetNewToken(JSON.stringify(token.SignData)).then((result) => {
        if (result.code == 0) {
          localStorage.setItem('apiList', JSON.stringify(result.data.ApiList));
          delete result.data.ApiList;
          this.$store.commit('selectNewAPIURL');
          this.$store.commit('SetToken', result.data);
          this.$fun.loginInfo.SetToken(result.data);
          this.$fun.loginInfo.setCookie('token', JSON.stringify(result.data));
          console.log(
            result.data,
            this.$utils.formatDateToBias(Date.new()),
            'tokentokentoken'
          );
        }
      }, (error) => {
        if (this.vIsShowHeadBar) {
          outLogin();
        }
        layer.alert(error.msg);
      });
    },
    upVersions () {
      if (this.$zxClient.Version) {
        console.log(this.$zxClient.Version, process.env.VUE_APP_VERSION_CODE, 'this.$zxClient.Versionthis.$zxClient.Versionthis.$zxClient.Version');
        if (process.env.VUE_APP_VERSION_CODE) {
          this.isShowUpVersions = this.$utils.checkVersion(this.$zxClient.Version, process.env.VUE_APP_VERSION_CODE) == -1;
        }
      } else {
        setTimeout(() => {
          this.upVersions();
        }, 100);
      }
    },
    hiddenUpVersions () {
      this.isShowUpVersions = false;
    },
    checkLoginChange () {
      // 登录成功后 会存cookie和vuex
      // 退出登录 清空 cookie和vuex
      // 切换门店 会存cookie和vuex
      var token = this.$fun.loginInfo.getCookie('token');
      var vuexToken = this.$store.getters.token;
      // LOGIN
      // 有cookies
      if ((this.$route.name == 'cloudClassAuthenticationDetails' ||
        this.$route.name == 'coursewareTvTeaching' ||
        this.$route.name == 'prestrainPage'
      ) && !this.vIsShowHeadBar) { // 该路由无需登录
        return false;
      } else if (this.vIsShowHeadBar) {
        if (token && token != 'null') {
          token = JSON.parse(token);
          if (!vuexToken || (vuexToken && token.UserID != vuexToken.UserID)) {
            this.initToken(token);
            location.href = location.origin + location.pathname;
          }
        } else {
          console.log('APP退出记录2');
          this.$fun.loginInfo.SetToken(null);
          this.$router.push('/login');
        }
      }
    },
    // 增加外部入口URL参数设置初始路由访问功能
    GetRequest () {
      let url = decodeURI(location.search); // 获取url中"?"符后的字串
      if (url.indexOf('?') != -1) {
        let urlParams = url.substr(1).split('&targetRouterParms=');
        let routerUrl = '';
        let routerPathParam = urlParams[0].split('=');
        if (routerPathParam[0] == 'targetRoutePath') {
          routerUrl = '/#/' + routerPathParam[1]; // ?a=x&b=y
          if (urlParams[1]) {
            let routerParms = '';
            routerParms = urlParams[1].split('&');
            for (let i = 0; i < routerParms.length; i++) {
              // ?a=x&b=y
              let paramsArr = routerParms[i].split('=');
              if (i == 0) {
                routerUrl =
                  routerUrl +
                  '?' +
                  paramsArr[0] +
                  '=' +
                  decodeURI(paramsArr[1]);
              } else {
                routerUrl =
                  routerUrl +
                  '&' +
                  paramsArr[0] +
                  '=' +
                  decodeURI(paramsArr[1]);
              }
            }
          }
          console.log(location.origin + location.pathname + routerUrl, 'location.origin + location.pathname + routerUrl');
          location.href = location.origin + location.pathname + routerUrl;
        }
      }
    },
    // 打开路由
    openRouter (moduleInfo) {
      if (moduleInfo.routerName) {
        this.$dialog.openRouter(moduleInfo, this.$route.name);
      }
    }
  }
};
</script> 
<style scoped>
.login_bj {
  height: 100%;
}
</style>
