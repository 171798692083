<template>
  <div>
    <div v-if="!isMoneyReceipt"
         class="toolTip_box_content">
      <div class="toolTip_box_content_img">
        <div class="toolTip_box_content_fontBlue">报名成功!</div>
        <!-- <div v-if="studentInfo&&!studentInfo.isAddInClass"
             class="toolTip_box_content_fontBlack">{{studentInfo&&studentInfo.MainDemoName}}还未加入班级，是否加入班级去排课？</div> -->
      </div>
      <!-- <div class="toolTip_box_content_btnBox"
           v-if="studentInfo&&studentInfo.isAddInClass">
        <div class="btnBox_withe btn_hover_white"
             @click="hiddenView">返回工作台</div>
      </div> -->
      <!-- <div class="toolTip_box_content_btnBox"
           v-else>
        <div class="btnBox_withe btn_hover_white"
             @click="hiddenView">返回工作台</div> -->
      <!-- <div class="btnBox_blue btn_hover_bg_blue"
           :class="vCheckModulePower?'':'norole'"
           @click="showSingleSelectionDialog">进班排课</div> -->
      <!-- </div> -->
    </div>
    <div class="flex_box">
      <div class="flex_box_list"
           v-if="data1.SetKey=='1'">
        <div>销售业绩归属</div>
        <div @click="openCounselorList"
             v-if="CounselorKey==''&&CounselorKeyValue==''"
             class="point colorblue">设置</div>
        <div @click="openCounselorList"
             v-else
             class="point colorblue">{{CounselorKeyValue}}</div>
      </div>
      <div class="flex_box_list"
           v-if="data2.SetKey=='1'">
        <div>进班排课</div>
        <div @click="showSingleSelectionDialog"
             v-if="classKey==''&&className==''"
             class="point colorblue">设置</div>
        <div @click="showSingleSelectionDialog"
             v-else
             class="point colorblue">{{className}}</div>
      </div>
      <div class="flex_box_list"
           v-if="data3.SetKey=='1'">
        <div>关联转化单</div>
        <div @click="openRelevantTableDialig"
             class="point colorblue">{{text}}</div>
      </div>
    </div>
    <div class="print">打印</div>
    <div class="flex_box_a">
      <div class="flex_box_list">
        <div @click="openProp('1')"
             class="point">预览A4纸</div>
        <div @click="openProp('2')"
             class="point">打印A4纸</div>
      </div>
      <div class="flex_box_list">
        <div @click="openProp('3')"
             class="point">预览小票</div>
        <div @click="openProp('4')"
             class="point printtext">打印小票</div>
      </div>
    </div>
    <div class="signuppayment_print_box"
         :class="{no_top_type:isMoneyReceipt}"
         v-show="type=='5'">
      <div class="signuppayment_print_menu">
        <div class="print_btn btn_hover_bg_blue"
             @click="printing">打印</div>
      </div>
      <div class="signuppayment_print_from"
           ref="iframeDom">
        <iframe id="frame"
                ref="iframe"
                :style="{height:iframeHeight + 'px',width:vIframeWidth + 'px',paddingLeft:radio == '2'?'14px':''}"
                :src="vIframeSrc"
                frameborder="0"
                scrolling="no"></iframe>
      </div>
    </div>
    <custom-dialog :title="showTitle"
                   width="800px"
                   :visible.sync="isShowPrintProp"
                   :before-close="closeDialog">
      <print-payment-hint :tableID="tableID"
                          :studentInfo="studentInfo"
                          :isMoneyReceipt="isMoneyReceipt"
                          :radio="radio"
                          :type="type"
                          @afterSuccess="printPropSuccess"
                          @cancelClick="closeDialog"></print-payment-hint>
    </custom-dialog>
    <custom-dialog title="关联转化课单"
                   width="800px"
                   :visible.sync="isShowRelevantTable"
                   :before-close="closeRelevantTableDialog">
      <relevant-table :tableID="tableID"
                      @setText="setText"
                      @afterSuccess="relevantTableSuccess"
                      @cancelClick="closeRelevantTableDialog"></relevant-table>
    </custom-dialog>

    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
  </div>
</template> 

<script>
import printPaymentHint from './print-payment-hint.vue';
import relevantTable from './relevant-table.vue';
import { GetTheTicketMsg, getUsingClass, GetSalesList, RelevantSales, RelevantGrade } from '../../API/workbench.js';
export default {
  data () {
    return {
      isShowPrintFrom: false,
      radio: '1',
      state: '',
      iframeHeight: 380,
      iframeWin: {},
      formJson: {},
      gradeClassList: [],
      CounselorKey: '',
      CounselorKeyValue: '',
      classKey: '',
      className: '',
      isShowPrintProp: false,
      showTitle: '',
      type: '',
      isShowRelevantTable: false,
      text: '设置',
      data1: {},
      data2: {},
      data3: {}

    };
  },
  components: {
    printPaymentHint,
    relevantTable
  },
  props: {
    tableID: {
      type: Number
    },
    studentInfo: Object,
    isMoneyReceipt: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    vSaasClientInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vSaaSClientSetting () {
      return this.$store.getters.SaaSClientInfo.SaaSClientSetting;
    },
    vIframeSrc () {
      let src;
      if (this.radio == '1') {
        src = 'printview.html?v=2';
      } else {
        src = 'printview-moblie.html?v=2';
      }
      if (this.$refs.iframeDom) {
        this.$refs.iframeDom.scrollTop = 0;
      }
      this.setFromJson();
      if (process.env.NODE_ENV === 'development') {
        return '/public/' + src;
      } else {
        return window.location.origin + '/WebV/' + src;
      }
    },
    vIframeWidth () {
      if (this.radio == '2') {
        localStorage.setItem('print-type', '2');
        return '260';
      } else {
        localStorage.setItem('print-type', '1');
        return '700';
      }
    },
    vCheckModulePower () {
      if (this.$utils.CheckModulePower(this.$store.getters.RolePowerList, 51)) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    isShowPrintFrom (n, o) {
      if (n) {
        this.$nextTick(() => {
          window.addEventListener('message', this.handleMessage); // mounted钩子函数中
          this.iframeWin = this.$refs.iframe.contentWindow; // 获取iframe
          this.setFromJson();
        });
      }
    }
  },
  created () {
    this.getValidClassList();
  },
  activated () {
    console.log('isMoneyReceipt', this.tableID, this.studentInfo, this.isMoneyReceipt);
    window.addEventListener('message', this.handleMessage); // mounted钩子函数中
    this.iframeWin = this.$refs.iframe.contentWindow; // 获取iframe
    this.$nextTick(() => {
      this.setFromJson();
    });
  },
  mounted () {
    this.isShowPrintFrom = true;
    console.log('vSaaSClientSetting', this.vSaaSClientSetting);
    let index1 = this.vSaaSClientSetting.findIndex((item) => {
      return item.SetTypeKey == '100';
    });
    console.log();
    this.data1 = JSON.parse(JSON.stringify(this.vSaaSClientSetting[index1]));
    console.log('data1', this.data1, index1);
    let index2 = this.vSaaSClientSetting.findIndex((item) => {
      return item.SetTypeKey == '101';
    });
    console.log();
    this.data2 = JSON.parse(JSON.stringify(this.vSaaSClientSetting[index2]));
    console.log('data2', this.data2, index2);
    let index3 = this.vSaaSClientSetting.findIndex((item) => {
      return item.SetTypeKey == '102';
    });
    console.log();
    this.data3 = JSON.parse(JSON.stringify(this.vSaaSClientSetting[index3]));
    console.log('data3', this.data3, index3);
    this.$dialog.register(this, ['singleSelectionList', 'addStudentToBJ']);
    if (this.tableID > 0) {
      this.getfromInfo();
    }
  },
  methods: {
    hiddenView () {
      this.$emit('closeDialog');
      this.$router.push({ name: 'indexView' });
    },
    showPrintFrom () {
      this.isShowPrintFrom = true;
    },
    // 获取有效班级列表
    getValidClassList () {
      getUsingClass().then(result => {
        this.gradeClassList = [];
        result.data.forEach(obj => {
          if (Date.new() <= Date.new(obj.EndTime) || !obj.EndTime) {
            this.gradeClassList.push(obj);
          }
        });
      });
    },
    // 打开班级列表
    showSingleSelectionDialog () {
      // 判断权限,
      if (this.vCheckModulePower) {
        this.$dialog.open(this, {
          name: '加入班级',
          routerName: this.$route.name,
          routertitle: '',
          ModulePowerKey: 51,
          moduleName: 'addStudentToBJ',
          data: { dataInfo: { studentInfo: this.studentInfo, type: '进班', isPayment: true, selectedKey: this.classKey } },
          callBack: {
            onSelectedItem: (data) => {
              console.log('data', data);
              if (data.OLAPKey == 0) {
                data.MainDemoName = '不关联';
              }
              this.classKey = data.OLAPKey;
              this.className = data.MainDemoName;
              RelevantGrade(this.tableID, this.classKey, this.className).then((res) => {
                console.log('关联班级成功', res.data);
              });
            }
          }
        });
        // this.$emit('closeDialog');
      } else {
        this.$utils.CheckModulePowerToTips(51);
      }
    },
    // 获取来源方式
    openCounselorList () {
      GetSalesList().then(
        (result) => {
          this.CounselorList = result.data;
          this.CounselorList.unshift({
            OLAPKey: 0,
            MainDemoName: '不关联'
          });
          this.$dialog.open(this, {
            routerName: this.$route.name,
            name: '选择销售顾问',
            moduleName: 'singleSelectionList',
            data: {
              selectedKey: this.CounselorKey,
              dataSourceList: this.CounselorList,
              required: false,
              ModulePowerKey: 0
            },
            callBack: {
              onSelectedItem: (data) => {
                if (data.OLAPKey == 0) {
                  data.MainDemoName = '不关联';
                }
                this.CounselorKey = data.OLAPKey;
                this.CounselorKeyValue = data.MainDemoName;
                RelevantSales(this.tableID, this.CounselorKey).then((res) => {
                  console.log('关联销售成功', res.data);
                });
              }
            }
          });
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    getfromInfo () {
      GetTheTicketMsg(this.tableID).then(res => {
        this.formJson = res.data;
        console.log(this.formJson, 'GetTheTicketMsg');
        this.formJson.saasClientInfo = this.vSaasClientInfo;
        if (this.formJson.saasClientInfo.WktInfo && this.formJson.saasClientInfo.WktInfo.QRcode && this.formJson.saasClientInfo.WktInfo.QRcode.indexOf('http') == -1) {
          this.formJson.saasClientInfo.WktInfo.QRcode = this.$store.getters.CDNURL + this.formJson.saasClientInfo.WktInfo.QRcode;
        }
        if (this.formJson.CouponKey == 0) {
          this.formJson.CouponKeyValue = '未使用';
        }
        if (this.formJson.BillerKeyValue.length == 0) {
          this.formJson.BillerKeyValue = this.$store.getters.token.UserName;
        }
        if (this.formJson.MobileSupport.length > 0) {
          this.formJson.MobileSupport = this.$utils.phoneModulePower(this.formJson.MobileSupport);
        }
        this.courseListSort();
        window.addEventListener('message', this.handleMessage); // mounted钩子函数中
        this.iframeWin = this.$refs.iframe.contentWindow; // 获取iframe
        this.$nextTick(() => {
          this.setFromJson();
        });
      }, err => {
        console.log(err);
      });
    },
    setText (text) {
      this.text = text;
    },
    courseListSort () {
      let arr = [];
      let packageList = [];
      this.formJson.KDNR.forEach(e => {
        let arr2 = [];
        if (e.PackageKey > 0) {
          packageList.push(e);
        } else {
          arr2.push(e.ApplyCorName);
          arr2.push(Number(e.UnitPrice).toFixed(2));
          arr2.push(Number(e.NumCount) + e.UnitKeyValue);
          arr2.push(Number(e.ValueAmount).toFixed(2));
          if (e.DiscountCount >= 10) {
            arr2.push('原价');
          } else if (e.DiscountCount == 0) {
            arr2.push('赠送');
          } else {
            arr2.push(Number(e.DiscountCount) + '折');
          }
          arr2.push(Number(e.AfterDisAmount).toFixed(2));
          arr.push(arr2);
        }
      });
      this.formJson.ZFNR.forEach(e => {
        let arr2 = [];
        if (e.PackageKey > 0) {
          packageList.push(e);
        } else {
          arr2.push(e.ProjectKeyValue);
          arr2.push(Number(e.UnitPrice).toFixed(2));
          arr2.push(Number(e.NumCount) + e.UnitName);
          arr2.push(Number(e.ValueAmount).toFixed(2));
          if (e.DiscountCount >= 10) {
            arr2.push('原价');
          } else if (e.DiscountCount == 0) {
            arr2.push('赠送');
          } else {
            arr2.push(Number(e.DiscountCount) + '折');
          }
          arr2.push(Number(e.AfterDisAmount).toFixed(2));
          arr.push(arr2);
        }
      });
      if (packageList.length > 0) {
        let packageData = {
          SalePrice: 0,
          amount: 0,
          afterDisAmount: 0
        };
        packageList.forEach(e => {
          packageData.SalePrice += Number(e.ValueAmount || e.ValueAmount);
          packageData.amount += Number(e.ValueAmount || e.ValueAmount);
          packageData.afterDisAmount += Number(e.AfterDisAmount || e.AfterDisAmount);
        });
        packageData.afterDisAmount = packageData.afterDisAmount - this.formJson.ComboDiscountAmount;
        let arr2 = [];
        arr2.push(this.formJson.PackageKeyValue);
        arr2.push(packageData.SalePrice.toFixed(2));
        arr2.push('1套');// 数量
        arr2.push(packageData.amount.toFixed(2));
        arr2.push('原价');// 折扣
        arr2.push(packageData.afterDisAmount.toFixed(2));
        arr.unshift(arr2);
      }
      this.formJson.courseList = arr;
    },
    setFromJson () {
      setTimeout((res) => {
        if (!this.iframeWin) {
          return false;
        }
        if (this.iframeWin.postMessage) {
          this.iframeWin.postMessage(
            {
              cmd: 'getFormJson',
              info: this.formJson
            },
            '*'
          );
        }
      }, 500);
    },
    handleMessage (event) {
      // 根据上面制定的结构来解析iframe内部发回来的数据
      const data = event.data;
      // console.log("vue文件接收的数据", data);
      if (data.cmd == 'success') {
        // 自己定义一个判断是否正常
        this.state = 'ok';
        if (this.iframeWin && this.iframeWin.document) {
          this.iframeHeight = this.iframeWin.document.getElementById('printFrom').clientHeight + 20;
        }
      }
    },
    printing () {
      // 点击打印
      let iframe = document.getElementById('frame'); // 获取iframe标签相当于获取了打印的区域了
      this.iframeWin.postMessage(
        {
          cmd: 'getFormJson',
          info: this.formJson
        },
        '*'
      );
      setTimeout((res) => {
        if (this.state == 'ok') {
          iframe.contentWindow.print(); // 打印iframe的html页面
          // if (this.radio == '1' && this.$zxClient) {
          //   iframe.contentWindow.document.body.style = 'transform: scale(0.95);transform-origin: 0 0;';
          //   iframe.contentWindow.print(); // 打印iframe的html页面
          //   setTimeout(() => { iframe.contentWindow.document.body.style = ''; }, 500);
          // } else {
          // }
        }
      }, 500);
    },
    closeDialog () {
      this.isShowPrintProp = false;
    },
    closeRelevantTableDialog () {
      this.isShowRelevantTable = false;
    },
    openRelevantTableDialig () {
      this.isShowRelevantTable = true;
    },
    printPropSuccess () {

    },
    relevantTableSuccess (data) {
      console.log('relevantTableSuccess', data);
    },
    openProp (type) {
      if (type == '1') {
        this.radio = '1';
        this.showTitle = '预览A4纸';
        this.isShowPrintProp = true;
      } else if (type == '2') {
        this.radio = '1';
        this.showTitle = '打印A4纸';
        this.type = '2';
        setTimeout(() => {
          this.printing();
        }, 100);
        // this.isShowPrintProp = true;
      } else if (type == '3') {
        this.radio = '2';
        this.showTitle = '预览小票';
        this.isShowPrintProp = true;
      } else if (type == '4') {
        this.radio = '2';
        this.type = '4';
        this.showTitle = '打印小票';
        setTimeout(() => {
          this.printing();
        }, 100);
        // this.isShowPrintProp = true;
      }
    },
    cancelClick () {
      this.$emit('closeDialog');
    },
    confirmClick () {
      this.$emit('closeDialog');
    }
  }
};
</script>

<style scoped>
.flex_box {
  display: flex;
  flex-direction: column;
  width: 370px;
  margin: auto;
}
.flex_box_list {
  display: flex;
  flex-direction: row;
  height: 46px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.flex_box_a {
  display: flex;
  flex-direction: column;
  height: 120px;
  border-top: 1px solid #ececec;
  margin-top: 10px;
  padding: 20px 100px;
  margin-left: 20px;
  margin-right: 20px;
}
.print {
  width: 370px;
  margin: auto;
  font-family: Alibaba PuHuiTi;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
}
.printment {
  opacity: 0;
}
.point {
  cursor: pointer;
}
.colorblue {
  color: #3498db;
}
.printtext {
  letter-spacing: 1px;
}
</style>
