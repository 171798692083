<template>
  <div class="big_child_course">
    <div v-for="(item,i) in particulars"
         :key="i"
         style="position: relative;">
      <div class="big_courseware_img"
           :class="item.SettingCoverUrl?'':'big_courseware_default_img'">
        <img :src="item.img"
             :class="item.SettingCoverUrl?'swimg':'default_img'" />
        <span class="big_courseware_text">{{item.MainDemoName}}</span>
      </div>
      <div class="attend_class_bj"
           @click.stop="prepare(item)">开始上课</div>
    </div>
    <div v-if="particulars.length==0"
         class="course_block_nodata"
         style="margin-top: 150px;margin-left: 568px;">
      <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
    </div>
  </div>
</template>

<script>
import {
  CWSGetCoursewarePackageDetails,
  CWSTeacherWorkbenchGetCoursewarePackageDetails,
  CWSGetCoursewareForGrade,
  CWSPrincipalWorkbenchGetCoursewareList,
  CWSTeacherGiveLectureSetUpScheduleCourse
} from '../../../API/workbench';
export default {
  components: {

  },
  props: {
    CoursewarePackage: {
      type: Object,
      default: () => {
        return {
          CourseCount: [Number, String],
          CourseTimeCount: [Number, String],
          CoursewareNum: [Number, String],
          OLAPKey: [Number, String],
          MainDemoName: String,
          particularData: Object,
          TeacherKey: [Number, String],
          ParentKeyValue: String,
          Source: [String, Number]
        };
      }
    }
  },
  data () {
    return {
      chooseCoursewareInfoType: '',
      chooseType: '',
      chooseScheduleCourseKey: '',
      maxCoursewarePackage: {}, // 子课包详情
      Source: '',
      role: this.vRoleKey(),
      particulars: [],
      openClassData: [],
      IsContinueKey: 0,
      IsAuthKey: 0,
      TeacherID: this.CoursewarePackage.TeacherKey || '', // this.$store.getters.token.UserID || '' ,// 老师id
      busClassLocationEvent: null
    };
  },
  computed: {

  },
  created () {
    if (this.$zxClient && this.$zxClient.IsClient) {
      this.deviceCode = this.$zxClient.GetMCode();
    } else {
      // this.tipContont = '非桌面端';
      this.deviceCode = '';
    }
    if (this.CoursewarePackage.OLAPKey && Number(this.CoursewarePackage.OLAPKey) > 0) {
      this.getCoursewarePackageDetails(this.CoursewarePackage.OLAPKey, this.deviceCode, this.TeacherID);
    }
    this.Source = this.CoursewarePackage.Source;
    this.vRoleKey();
    console.log(this.CoursewarePackage, 'CoursewarePackage this.role', this.role);
    this.getPrincipalWorkbenchGetCoursewareList();

    this.busClassLocationEvent = res => {
      if (this.chooseType == 1) {
        this.$router.push({
          name: 'coursewareTeachingView',
          query: {
            type: this.chooseType,
            scheduleCourseID: this.chooseScheduleCourseKey,
            CoursewareInfoType: this.chooseCoursewareInfoType,
            Accuracy: this.maxCoursewarePackage.Accuracy,
            IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

          }
        });
      } else if (this.chooseType == 10) {
        this.openClassData.Longitude = res.Longitude;
        this.openClassData.Latitude = res.Latitude;
        console.log('this.openClassData', this.openClassData);
        CWSTeacherGiveLectureSetUpScheduleCourse(
          this.openClassData,
          this.IsContinueKey,
          this.IsAuthKey
        )
          .then(result => {
            console.log(result, '上课返回数据');
            // this.$emit("afterSuccess");
            // this.$emit("closeDialog");
            if (result.data.ScheduleCourseKey) {
              setTimeout(() => {
                this.$router.push({
                  name: 'coursewareTeachingView',
                  query: {
                    type: 1,
                    scheduleCourseID: result.data.ScheduleCourseKey,
                    Accuracy: this.maxCoursewarePackage.Accuracy,
                    IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

                  }
                });
              }, 500);
            } else {
              console.log(123456456);
              layer.alert(error.msg);
            }
          })
          .catch(error => {
            console.log(123456456);
            if (error.code == 80001) {
              this.isAuthCourseware();
            } else if (error.code == 80002) {
              layer.confirm(error.msg, {
                btn: [
                  {
                    name: '取消',
                    callBack: function () {
                      this.IsContinueKey = 0;
                      layer.close();
                      if (callBack) {
                        callBack();
                      }
                    }
                  },
                  {
                    name: '确认',
                    callBack: () => {
                      this.IsContinueKey = 1;
                      CWSTeacherGiveLectureSetUpScheduleCourse(
                        this.openClassData,
                        this.IsContinueKey,
                        this.IsAuthKey
                      )
                        .then(res => {
                          if (res.data.ScheduleCourseKey) {
                            setTimeout(() => {
                              this.$router.push({
                                name: 'coursewareTeachingView',
                                query: {
                                  type: 1,
                                  scheduleCourseID: res.data.ScheduleCourseKey,
                                  Accuracy: this.maxCoursewarePackage.Accuracy,
                                  IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

                                }
                              });
                            }, 500);
                          } else {
                            layer.alert(error.msg);
                          }
                        })
                        .catch(error => {
                          if (error.code == 80001) {
                            this.isAuthCourseware();
                          } else {
                            layer.alert(error.msg);
                          }
                        });
                      layer.close();
                    }
                  }
                ]
              });
            } else {
              layer.alert(error.msg);
            }
          });
      } else if (this.chooseType == 2) {
        setTimeout(() => {
          this.$router.push({
            name: 'coursewareTeachingView',
            query: {
              type: this.chooseType,
              coursewareId: this.coursewareIdforOpen,
              // authCoursewareID:this.chooseSaaSClientKey,
              coursewareInfoType: this.chooseCoursewareInfoType,
              Accuracy: this.maxCoursewarePackage.Accuracy,
              IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

            }
          });
        }, 500);
      } else if (this.chooseType == 4) {
        setTimeout(() => {
          this.$router.push({
            name: 'coursewareTeachingView',
            query: {
              type: this.chooseType,
              coursewareId: this.coursewareIdforOpen,
              // authCoursewareID:this.chooseSaaSClientKey,
              coursewareInfoType: this.chooseCoursewareInfoType,
              Accuracy: this.maxCoursewarePackage.Accuracy,
              IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

            }
          });
        }, 500);
      }
      setTimeout(() => {
        this.getPrincipalWorkbenchGetCoursewareList();
      }, 500);
    };
  },
  mounted () {
  },
  methods: {
    isAuthCourseware () {
      layer.confirm(
        '您还没有该课包的授权，如果继续，将会自动将该课包授权给您，是否继续?',
        {
          btn: [
            {
              name: '取消',
              callBack: function () {
                this.IsAuthKey = 0;
                layer.close();
                if (callBack) {
                  callBack();
                }
              }
            },
            {
              name: '确认',
              callBack: () => {
                this.IsAuthKey = 1; // 是否自动授权
                CWSTeacherGiveLectureSetUpScheduleCourse(
                  this.openClassData,
                  this.IsContinueKey,
                  this.IsAuthKey
                ).then(result => {
                  if (result.data.ScheduleCourseKey) {
                    this.$router.push({
                      name: 'coursewareTeachingView',
                      query: {
                        type: 1,
                        coursewareId: result.data.ScheduleCourseKey,
                        Accuracy: this.maxCoursewarePackage.Accuracy,
                        IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

                      }
                    });
                  } else {
                    layer.alert(error.msg);
                  }
                });
                layer.close();
              }
            }
          ]
        }
      );
    },
    // 获取子课件包详情
    getCoursewarePackageDetails (OLAPKey, deviceCode, TeacherID) {
      console.log(this.TeacherID, '老师ID');
      // if (!this.$zxClient.IsClient) {
      //   layer.alert('请用桌面端打开程序')
      // } else {
      if (this.role) {
        CWSGetCoursewarePackageDetails(OLAPKey, deviceCode, TeacherID).then(
          result => {
            this.maxCoursewarePackage = result.data;
            let geoInfo = {
              Longitude: result.data.Longitude || '',
              Latitude: result.data.Latitude || '',
              Accuracy: result.data.Accuracy || '',
              PositioningOffset: result.data.PositioningOffset || ''
            };
            console.log('geoInfo', geoInfo);
            console.log('result.data', result.data);
            this.$store.commit('setGeoInfo', geoInfo);

            console.log('校长-获取子课件包详情index页面', result.data);
          });
      } else {
        CWSTeacherWorkbenchGetCoursewarePackageDetails(OLAPKey, deviceCode, TeacherID).then(
          result => {
            this.maxCoursewarePackage = result.data;
            let geoInfo = {
              Longitude: result.data.Longitude || '',
              Latitude: result.data.Latitude || '',
              Accuracy: result.data.Accuracy || '',
              PositioningOffset: result.data.PositioningOffset || ''
            };
            this.$store.commit('setGeoInfo', geoInfo);
            console.log('老师-获取子课件包详情index页面', result.data);
          });
      }
      console.log(6545665165);
      this.$emit('afterSuccess');
      // }
    },
    //   获取子课包课件list
    getPrincipalWorkbenchGetCoursewareList () {
      // if (!this.$zxClient.IsClient) {
      //   layer.alert('请用桌面端打开程序')
      // } else {
      if (this.role) {
        CWSPrincipalWorkbenchGetCoursewareList(this.CoursewarePackage.OLAPKey, this.TeacherID).then(
          result => {
            this.particulars = result.data;
            this.particulars.forEach(o => {
              if (o.SettingCoverUrl) {
                o.img = this.$store.getters.CDNURL + o.CoverUrl;
              } else {
                o.img = require('../../../../public/image/default_courseware_bj.png');
              }
              console.log(o.img, '上课时间');
            });
            console.log('获取子课包课件list', this.particulars);
          });
      } else {
        CWSGetCoursewareForGrade(this.CoursewarePackage.OLAPKey, this.TeacherID).then(result => {
          this.particulars = result.data;
          this.particulars.forEach(o => {
            if (o.SettingCoverUrl) {
              o.img = this.$store.getters.CDNURL + o.CoverUrl;
            } else {
              o.img = require('../../../../public/image/default_courseware_bj.png');
            }
          });
          console.log('老师-获取子课包课件list', this.particulars);
        });
      }
      // }
    },
    // 进入备课模式
    prepare (item) {
      console.log(
        '去备课',
        item,
        this.CoursewarePackage,
        this.Source
      );
      if (!this.$zxClient.IsClient) {
        layer.alert('请用桌面端打开程序');
      } else {
        if (this.maxCoursewarePackage.DeviceIsAuth != 1) {
          layer.alert('设备未授权');
        } else {
          if (this.$store.getters.SaaSClientEditionKey == 6) {
            if (this.role) {
              if (this.Source == 1 || this.Source == 2) {
                // 授权
                let viewType = this.Source == 1 ? 'SQ' : 'ZZ';
                if (
                  this.maxCoursewarePackage.PCIsLocationLockKey == 1 &&
                  !this.$store.getters.geoInfo.Longitude
                ) {
                  this.openAuthDialog(item.OLAPKey, viewType, 2, 12);
                } else {
                  let newdataInfo = {
                    ScheduleCourseKey: item.ScheduleCourseKey || '',
                    courserId: this.CoursewarePackage.OLAPKey,
                    client_id: this.$store.getters.clientId,
                    SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
                    DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
                    introType: 12,
                    DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
                  };
                  this.$utils.verifySystemLocation(
                    newdataInfo,
                    success => {
                      this.$router.push({
                        name: 'coursewareTeachingView',
                        query: {
                          type: 2,
                          coursewareId: item.OLAPKey,
                          coursewareInfoType: viewType,
                          Accuracy: this.maxCoursewarePackage.Accuracy,
                          IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

                        }
                      });
                      setTimeout(() => {
                        this.getPrincipalWorkbenchGetCoursewareList();
                      }, 500);
                    },
                    error => {
                      this.openAuthDialog(item.OLAPKey, viewType, 2, 12);
                    }
                  );

                  console.log('12ssss34544444');
                }
              }
            } else {
              if (
                this.maxCoursewarePackage.PCIsLocationLockKey == 1 &&
                !this.$store.getters.geoInfo.Longitude
              ) {
                this.openAuthDialog(item.OLAPKey, '', 4, 12);
              } else {
                console.log('12ssss345611112222');
                let newdataInfo = {
                  ScheduleCourseKey: item.ScheduleCourseKey || '',
                  courserId: this.CoursewarePackage.OLAPKey,
                  client_id: this.$store.getters.clientId,
                  SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
                  DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
                  introType: 12,
                  DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
                };
                this.$utils.verifySystemLocation(
                  newdataInfo,
                  success => {
                    this.$router.push({
                      name: 'coursewareTeachingView',
                      query: {
                        type: 4,
                        coursewareId: item.OLAPKey,
                        Accuracy: this.maxCoursewarePackage.Accuracy,
                        IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

                      }
                    });
                    setTimeout(() => {
                      this.getPrincipalWorkbenchGetCoursewareList();
                    }, 500);
                  },
                  error => {
                    this.openAuthDialog(item.OLAPKey, '', 4, 12);
                  }
                );
              }
            }
          } else {
            if (this.Source == 1 || this.Source == 2) {
              let viewType = this.Source == 1 ? 'SQ' : 'ZZ';
              // 授权
              if (
                this.maxCoursewarePackage.PCIsLocationLockKey == 1 &&
                !this.$store.getters.geoInfo.Longitude
              ) {
                this.openAuthDialog(item.OLAPKey, viewType, 2, 22);
              } else {
                console.log('12ssss3456');
                let newdataInfo = {
                  ScheduleCourseKey: item.ScheduleCourseKey || '',
                  courserId: this.CoursewarePackage.OLAPKey,
                  client_id: this.$store.getters.clientId,
                  SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
                  DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
                  introType: 22,
                  DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
                };
                this.$utils.verifySystemLocation(
                  newdataInfo,
                  success => {
                    this.$router.push({
                      name: 'coursewareTeachingView',
                      query: {
                        type: 2,
                        coursewareId: item.OLAPKey,
                        coursewareInfoType: viewType,
                        Accuracy: this.maxCoursewarePackage.Accuracy,
                        IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

                      }
                    });
                    setTimeout(() => {
                      this.getPrincipalWorkbenchGetCoursewareList();
                    }, 500);
                  },
                  error => {
                    this.openAuthDialog(item.OLAPKey, viewType, 2, 22);
                  }
                );
              }
            }
          }
        }
      }
    },
    openAuthDialog (OLAPKey, type, model, introType) {
      console.log(
        'openAuthDialog maxCoursewarePackage',
        this.maxCoursewarePackage
      );
      if (this.maxCoursewarePackage.HQAuthKey != 0) {
        let text = this.maxCoursewarePackage.HQAuthKey == 1 ? '  当前门店定位，课件授权方禁止访问课件' : ' 当前门店定位，课件授权方正在审核中，暂时无法访问课件';
        layer.alert(text);
        return false;
      }
      this.chooseScheduleCourseKey = '';
      this.coursewareIdforOpen = OLAPKey;
      this.chooseType = model;
      this.chooseCoursewareInfoType = type;
      let skey = '';
      if (model == 1) {
        this.chooseScheduleCourseKey = OLAPKey;
        skey = OLAPKey;
      }
      if (this.maxCoursewarePackage.PCIsLocationLockKey == 1) {
        console.log('courseAuthQrcode');
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '安全验证',
          moduleName: 'courseAuthQrcode',
          data: {
            dataInfo: {
              ScheduleCourseKey: skey,
              courserId: this.CoursewarePackage.OLAPKey,
              client_id: this.$store.getters.clientId,
              SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
              DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
              introType: introType,
              DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
            }
          },
          callBack: {
            afterSuccess: data => {
              this.busClassLocationEvent(data);
            }
          }
        });
      }
    },
    vRoleKey () {
      if (this.$store.getters.SaaSClientEditionKey == 6) {
        return this.$store.getters.token ? Number(this.$store.getters.token.RoleKey) == 2 : false; // 2为校长；非则为老师
      } else if (this.$store.getters.SaaSClientEditionKey <= 3) {
        return true;
      }
    }
  }
};
</script>

<style scoped>
.big_child_course {
  display: flex;
  flex-wrap: wrap;
}
.big_courseware_img {
  position: relative;
  display: flex;
  margin-right: 7rem;
  width: 113rem;
  height: 66rem;
  margin-bottom: 19rem;
  border-radius: 4rem;
  cursor: pointer;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
}
.big_courseware_default_img {
  background-color: #ccc;
}
.swimg {
  width: 100%;
  height: 100%;
  border-radius: 4rem;
}
.default_img {
  width: 51rem;
  height: 38rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 12rem;
  border-radius: 4rem;
}
.big_courseware_text {
  position: absolute;
  font-family: Alibaba PuHuiTi;
  color: #333;
  font-size: 6rem;
  bottom: -17rem;
  width: 100%;
  height: 17rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 18rem;
}
.attend_class_bj {
  width: 113rem;
  height: 66rem;
  border-radius: 4rem;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
  background-image: url(../../../../public/image/attend_class_icon.png);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: center;
  text-align: center;
  line-height: 64rem;
  font-size: 6rem;
  color: #fff;
  cursor: pointer;
  opacity: 0;
}
.attend_class_bj:hover {
  opacity: 1;
}
</style>
