<template>
  <div class="pr package_list label_radio_bg_white"
       @click="clickEvent">
    <div class="package_list_name">
      <span>{{item.ApplyCorName}}
        <div class="package_list_name_label"
             v-if="item.ApplyTypeKeyValue">{{item.ApplyTypeKeyValue}}</div>
      </span>
    </div>
    <div class="package_list_text">
      {{vType}}
    </div>
    <div class="package_list_amount">
      <div>
        <span v-if="Number(item.AfterDisAmount)==0">赠送</span>
        <span v-else>￥{{$utils.mMoneyType(item.AfterDisAmount,2)}}</span>
      </div>
      <div class="font_gray"
           v-if="Number(item.AfterDisAmount)<Number(item.ValueAmount)">
        <span class="text_center_line">￥{{$utils.mMoneyType(item.ValueAmount,2)}}</span>
        <span>{{vDiscount}}</span>
      </div>
    </div>

    <!-- 下拉弹窗层 选择项目类型 课时 套餐 杂费 -->
    <!-- <div class="module_content_menuList" style="width:120px; top: 65px; left: 72%;"
      v-show="isShowAlerDialog">
    <div class="top_bg"></div>
    <drop-down-box :optionJsonList="optionBtns"
                    @clickOptionForAlertDialog="chooseOptionBtn"></drop-down-box>
  </div> -->
    <span class="package_type">{{vDrainageKeyValue}}</span>
  </div>
</template>

<script>
import dropDownBox from '../../../../common/drop-down-box';
export default {
  props: {
    item: Object,
    isReadonly: Boolean
  },
  data () {
    return {
      isShowAlerDialog: false,
      optionBtns: [// 操作按钮
        {
          type: 'edit',
          name: '修改',
          isShow: true
        },
        {
          type: 'delete',
          name: '删除',
          isShow: true
        }
      ]
    };
  },
  computed: {
    vDrainageKeyValue () {
      if (this.item.IsDrainageKey == '1' || this.item.ApplyTypeKey == '4'
      ) {
        return '引流';
      } else if (Number(this.item.AfterDisAmount) == 0) {
        return '';
      } else {
        return '正课';
      }
    },
    vDiscount () {
      let str = '';
      if (Number(this.item.AfterDisAmount) == 0) {
        str = '';
      } else if (Number(this.item.AfterDisAmount) < Number(this.item.ValueAmount)) {
        str = this.$utils.mMoneyType(this.item.DiscountCount, 1) + '折';
      } else {
        str = '';
      }
      return str;
    },
    vType () {
      let str = '';
      // 课时卡
      if (this.item.UnitKey == 5) {
        str = Number(this.item.UnitPrice) + '元×' + Number(this.item.NumCount) + '课时';
      } else {
        str = '学期卡 | 有效期：' + Number(this.item.NumCount) + this.item.UnitKeyValue;
      }
      return str;
    }
  },
  components: {
    dropDownBox
  },
  created () {
    // document.addEventListener('click', e => {
    //   const ele = document.getElementsByClassName('alert_dialog')[0];
    //   if (e.target && !e.target.contains(ele)) {
    //     this.isShowAlerDialog = false;
    //   }
    // });
  },
  methods: {
    clickEvent (e) {
      if (!this.isReadonly) {
        // setTimeout(() => {
        //   this.isShowAlerDialog = true;
        // }, 50);
        let scrollTop = document.getElementsByClassName('package_revise_from')[0].scrollTop;
        let top = e.currentTarget.offsetTop + (e.currentTarget.clientHeight / 2) + 10 - scrollTop;

        this.$emit('showAlertDialog', this.item, 'classHour', top);
      }
    },
    chooseOptionBtn (item) {
      if (item.type == 'edit') {
        this.$emit('onEditClick', this.item);
      } else {
        this.$emit('onDeleteClick', this.item);
      }
      this.isShowAlerDialog = false;
    }
  }
};
</script>

<style scoped>
.package_type {
  position: absolute;
  top: 6px;
  right: 28px;
  font-size: 12px;
  color: #999;
}
</style>