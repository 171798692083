<template>
  <div :class="level!=2?'teaching_mode_cw_list':'teaching_mode_content'">
    <div class="teaching_mode_cw_list_search">
      <span class="span_hover"
            @click="toSeletedPage(0)">首页&nbsp;&nbsp;></span>
      <span class="span_hover"
            @click="toSeletedPage(1,dataInfo)">&nbsp;&nbsp;{{dataInfo.MainDemoName}}&nbsp;&nbsp;</span>
      <span class="span_hover"
            v-if="vSeletedCourseware"
            @click="toSeletedPage(2,vSeletedCourseware)">>&nbsp;&nbsp;{{vSeletedCourseware.CoursewarePackageKeyValue}}</span>
    </div>
    <div class="teaching_mode_bg_ligth">

    </div>

    <div :class="level!=2?'teaching_mode_cw_list_scroll':'courseware_packge_list swiper-container swiper_courseware_packge_list'">
      <div :class="level!=2?'':'swiper-wrapper'"
           v-show="vDataList.length>0">
        <coursewareItem v-for="(item,key) in vDataList"
                        :key="key"
                        :item="item"
                        @seletedItem="seletedItem"></coursewareItem>
      </div>
      <div v-if="vDataList.length==0&&level==2"
           class="teaching_mode_nodta">
        <div class="teaching_mode_nodta_text">暂无课件</div>
      </div>
      <div class="teaching_mode_cw_list_nodata"
           v-if="level!=2&&vDataList.length==0">
        <div class="nodata_text">暂无课件</div>
      </div>
    </div>
  </div>
</template>
<script>
import coursewareItem from './courseware-item'
export default {
  data () {
    return {
      swiper: null,
      swiperNewIndex: 0,
    }
  },
  props: {
    dataInfo: Object,
    dataList: Array,
    level: {
      type: [Number, String],
      default () {
        return 2
      }
    },
    seletedOLAPKey: [Number, String]  //选中id
  },
  components: {
    coursewareItem
  },
  created () {
    console.log("teaching_mode_cw_list", this.dataInfo)
  },
  computed: {
    vDataList () {
      return this.dataList
      let arr = [];
      let treeData = this.dataInfo.CoursewarePackageList.filter(item => {
        // 找到该目录
        if ((this.dataInfo && Number(this.dataInfo.OLAPKey) == Number(item.ParentKey) && item.TypeKey == 2)) {
          this.$set(item, 'isOpt', true);
        } else {
          this.$set(item, 'isOpt', false);
        }
        if (this.dataInfo && Number(this.dataInfo.OLAPKey) == Number(item.ParentKey)) {
          this.$set(item, 'isSelected', true);
        } else {
          this.$set(item, 'isSelected', false);
        }
        this.$set(item, 'childTree', []);
        return item.ParentKey;
      });
      // 父节点数据
      const ParentList = treeData.filter(item => Number(item.ParentKey) == Number(this.dataInfo.OLAPKey));
      // 子节点数据
      const childList = treeData.filter(item => Number(item.ParentKey) != Number(this.dataInfo.OLAPKey));
      arr = this.$utils.loopData(ParentList, childList, treeData, 'CoursewarePackageKey', 'ParentKey');
      console.log("this.seletedOLAPKey", this.seletedOLAPKey)
      if (this.seletedOLAPKey == this.dataInfo.OLAPKey) {
        return arr
      } else {
        let findItem = arr.find(o => {
          return o.CoursewarePackageKey == this.seletedOLAPKey
        })
        if (findItem) {
          return findItem.childTree
        } else {
          return []
        }
      }
    },
    vSeletedCourseware () {
      let findItem = this.dataInfo.CoursewarePackageList.find(o => {
        return this.seletedOLAPKey == o.CoursewarePackageKey;
      });
      if (findItem) {
        return findItem;
      } else {
        return ''
      }
    }
  },
  methods: {
    toSeletedPage (type, dataInfo) {
      this.$emit("toPage", type, dataInfo)
    },
    initSwiper () {
      var that = this
      if (this.level != 2) {
        return false
      }
      this.swiper = new Swiper('.swiper_courseware_packge_list', {
        grabCursor: true,
        slidesPerView: 4,
        spaceBetween: 25,
        // loop: false, // 循环模式选项
        observer: false, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: false, // 修改swiper的父元素时，自动初始化swipe
        // navigation: {
        // nextEl: '.swiper-button-next',
        // prevEl: '.swiper-button-prev',
        // },
        on: {
          // 拖动结束后触发事件预加载. 判断最左边隐藏的课程数据是否小于十
          transitionEnd: function (callback) {
            that.swiper = this;
            that.swiperNewIndex = this.realIndex.toString()
            console.log(that.swiperNewIndex, 'transitionEnd')

          }
        },
      });
    },
    seletedItem (item, authType) {
      this.$emit("seletedItem", item, authType);
    }
  }
}
</script>
<style scoped>
.teaching_mode_bg_ligth {
  background: #9ebceb;
  opacity: 0.3;
  filter: blur(75px);
  position: absolute;
  width: 560.42rem;
  height: 50.19rem;
  left: 83.69rem;
  top: 0rem;
  border-radius: 50%;
}
.teaching_mode_cw_list {
  width: 709.8rem;
  height: 353rem;
  /* 351rem */
  margin: auto;
  padding: 19.5rem 9rem 0rem;
  /* backdrop-filter: blur(7.95rem); */
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-top: 0.98rem solid;
  /* border-bottom:  0.98rem solid; */
  /*设置线性渐变*/
  border-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      #fff 50%,
      rgba(255, 255, 255, 0) 100%
    )
    2 2 2 2;

  /* background: red; */
}
.teaching_mode_cw_list::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #f5f5f5;
  overflow-y: auto;
}
.teaching_mode_cw_list::before {
  display: block;
  content: '';
  border-left: 0.98rem solid;
  position: absolute;
  height: 60%;
  top: 55rem;
  left: 0px;
  border-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #878889 50%,
      rgba(255, 255, 255, 0) 100%
    )
    2 2 2 2;
}
.teaching_mode_cw_list::after {
  border-right: 0.98rem solid;
  display: block;
  content: '';
  position: absolute;
  height: 60%;
  top: 55rem;
  right: 0;
  border-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #878889 50%,
      rgba(255, 255, 255, 0) 100%
    )
    2 2 2 2;
}
.teaching_mode_cw_list_scroll {
  width: 688rem;
  height: 100%;
  overflow-y: auto;
}
.teaching_mode_cw_list_scroll::-webkit-scrollbar {
  width: 1.57rem;
  height: 2.37rem;
  background-color: #ebebeb;
}
.teaching_mode_cw_list_scroll::-webkit-scrollbar-thumb {
  background-color: #a1a3a9;
  border-radius: 3px;
}
.teaching_mode_cw_list_scroll::-webkit-scrollbar-track {
  background-color: #777e90;
}
.teaching_mode_cw_list_nodata {
  width: 100%;
  height: 100%;
  margin: auto;
  background: url('../../../../public/image/teaching_no_courseware.png') 50% 35%
    no-repeat;
  background-size: 90.48rem;

  display: flex;
  align-items: center;
  justify-content: center;
}
.teaching_mode_cw_list_nodata .nodata_text {
  font-size: 12.48rem;
  padding-top: 120rem;
  color: #ffffff;
}
.teaching_mode_cw_list_search {
  width: 659.1rem;
  height: 21.84rem;
  border-radius: 4.68rem;
  background: rgba(0, 0, 0, 0.2);
  color: white;
  margin: 0rem 39.42rem;
  position: absolute;
  top: -22rem;
  font-size: 7.02rem;
  line-height: 21.84rem;
  padding-left: 21.45rem;
}
.teaching_mode_cw_list_search::before {
  content: '';
  position: absolute;
  left: 10rem;
  top: 5rem;
  width: 9.36rem;
  height: 9.36rem;
  background: url('../../../../public/image/teaching_mode_search_home.png') 50%
    no-repeat;
  background-size: 9.36rem;
}

.teaching_mode_content {
  width: 100%;
  height: 148.2rem;
  position: absolute;
  /* left: 19.5rem; */
  bottom: 23.63rem;
}
.courseware_packge_list {
  width: 100%;
  height: 148.98rem;
  padding: 15.6rem 28.92rem;
  position: relative;
}
.courseware_packge_list .swiper_wrapper_before {
  display: block;
  content: '';
  width: 30rem;
  /* padding-top: 78px; */
  backdrop-filter: blur(7.92rem);
  position: absolute;
  left: 0;
  top: 15.5rem;
  bottom: 13.5rem;
  z-index: 3;
  /* background: linear-gradient(270deg, #000 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.5;
    border-radius: 14px 0px 0px 14px; */
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.courseware_packge_list .swiper_wrapper_after {
  display: block;
  content: '';
  width: 30rem;
  /* padding-top: 78px; */
  backdrop-filter: blur(7.92rem);
  background: linear-gradient(90deg, #000 0%, rgba(255, 255, 255, 0) 100%);
  /* border-radius: 14px 0px 0px 14px; */
  opacity: 0.5;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  position: absolute;
  right: 0;
  top: 15.5rem;
  bottom: 13.5rem;
  z-index: 3;
}
.teaching_mode_nodta {
  background: url('../../../../public/image/teaching_no_courseware_packge.png')
    center no-repeat;
  margin: auto;
  line-height: 97.5rem;
  text-align: center;
  width: 100%;
  height: 100%;
}
.teaching_mode_nodta_text {
  font-size: 12.48rem;
  color: #ffffff;
  line-height: 214rem;
}
.span_hover {
  cursor: pointer;
}
</style>