<template>

  <li @click.stop="toggle(model)"
      class="single_result_tree single_class_info ">
    <div :class="{opt:model.isOpt||model.childTree.isOpt}"
         class="single_result_info_detail2">
      <div>
        <div :class="liClass">{{model.menuName}}
          <div v-if="model.childTree.length>0"
               class="leave-iocn-down"
               :class="iconShow?'leave-iocn-up':''"></div>
        </div>
      </div>

    </div>
    <!-- name="slide" -->
    <transition>
      <ul v-show="open">
        <items v-for="(item,index) in model.childTree"
               :model="item"
               :key="index"></items>
      </ul>
    </transition>
  </li>

</template>
 
<script>
export default {
  name: 'items',
  props: ['model', 'multie'],
  data () {
    return {
      open: false,
      iconShow: false,
      treeData: []
    };
  },
  created () {
    if (this.model.menuName == '全部') {
      this.toggle(true);
    } else {
    }
  },
  methods: {
    toggle (item) {
      if (this.model.id == 0) {
        return false;
      }
      this.iconShow = !this.iconShow;
      this.open = !this.open;
      this.model.isOpt = !this.model.isOpt;
      // console.log(this.model.isOpt, 'item');
      const parma = {
        multie: this.multie,
        model: this.model
      };
      this.$bus.emit('courseTableSelected', parma);
    }
  },
  computed: {
    liClass () {
      // let _this = this;
      let option = `level_${this.model.level}`;
      let res = {};
      res[option] = true;
      if (this.model.menuName.length > 17) {
        res.boxText = 'boxText';
      }
      return res;
    }
  }
};
</script>
 
<style>
.single_result_tree {
  background-color: #fff;
  display: -moz-box;
  display: -ms-flexbox;
  /* border-bottom: 1px solid #e6e9ec; */
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.1s;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-20px);
}
.slide-enter-to,
.slide-leave {
  transform: translateY(0px);
}
</style>
