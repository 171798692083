<template>
  <!-- 学生选择退款金额页面 -->
  <div class="">
    <!-- 按钮 组 -->
    <div class="footer_btn_group form_footer_btn_group" :class="className">
      <el-button round
                 class=" btn_light_red btn_hover_white"
                 @click.stop="cancelBtn">取消</el-button>
      <el-button round
                 class=" btn_light_blue btn_hover_bg_blue"
                 @click.stop="confirmSave">确认</el-button>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      button: '' // 触发按钮
    };
  },
  props:{
    className:String
  },
  components: {

  },
  created () {
  },
  methods: {
    cancelBtn () {
      this.$emit('closeDialog');   // 点击取消触发考勤eldiago隐藏.
    },
    confirmSave () {
      this.button = false;
      this.$emit('buttonClickEvent', this.button);
      this.$emit('sendItem');
    }

  }
};
</script>

  <style>
</style>