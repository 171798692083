<template>
  <!-- 已检查列表 -->
  <div class=" table_list_content summarizing_list">
    <div class="single_result_detail">
      <!-- 搜索 -->
      <condition-filtration-box :queryParams="queryParams"
                                @clearSearchParams="clearExtendSearchParams"
                                @searchValue="search"></condition-filtration-box>
      <!-- 通用列表 -->
      <table-list ref="tableListRef"
                  :tableData="mendianList"
                  :tableColumns="tableColumns"
                  :rowKey="'Number'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  @loadTableData="getSearchPageNotCheckPrepareLessons"></table-list>
    </div>
    <!-- 备课检查 -->
    <custom-dialog :title="'备课检查'"
                   class="incomepay_dialog defult_dialog"
                   :visible.sync="isShowPopup"
                   :before-close="closeDialog">
      <prepare-lessons-examine :prepareLessonsKey="prepareLessonsInfo.OLAPKey"
                               :isReadonly="true"
                               @afterSuccess="submitAfter"
                               @closeDialog="closeDialog"></prepare-lessons-examine>
    </custom-dialog>
  </div>
</template>
<script>
import { hqSearchPageCheckedPrepareLessons } from '../../../../API/workbench.js';
import conditionFiltrationBox from '../common/condition-filtration-box';
import tableList from '../../../common/table-list/index';
import prepareLessonsExamine from '../form/prepare-lessons-examine';
export default {
  data () {
    return {
      // 查询参数
      queryParams: {
        pageIndex: 0, //	整型	可选		数据源：GET	页码
        pageSize: 10, //	整型	可选		数据源：GET	分页大小
        searchText: '', //	字符串	可选		数据源：GET	关键字
        StartDate: this.$utils.getCurrentMonthFirst(), //	字符串	可选		数据源：GET	开始日期
        EndDate: this.$utils.getCurrentMonthLast(), //	字符串	可选		数据源：GET	结束日期
        SchoolKey: '', //	整型	可选		数据源：GET	校区id
        SchoolKeyValue: ''
      },
      mendianList: [], // 门店列表
      isShowPopup: false, // 备课检查
      prepareLessonsInfo: {}, // 备课信息
      tableColumns: [
        {
          label: '校区',
          prop: 'SaaSClientName',
          width: 200,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '老师',
          prop: 'TeacherName',
          width: 80,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '课件名',
          prop: 'newCourseWareNameText',
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '备课日期',
          prop: 'PrepareLessonsTimeText',
          width: 155,
          align: 'left',
          sortable: false,
          type: 'date-item',
          extend: {
            dateType: 'YYMMRR HH:DD' // 日期类型
          }
        },
        {
          label: '提前量',
          prop: 'newAdvanceDayCount',
          width: 75,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '评分',
          prop: 'RummageScoreCount',
          classIconName: 'star_dynamic_list opt',
          width: 160,
          align: 'left',
          type: 'icon-item',
          sortable: false
        },
        {
          label: '检查反馈',
          prop: 'RummageFeedBack',
          width: 235,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          width: 80,
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  click: (rowData) => {
                    this.examineBtnEvent(rowData);
                  }
                }
              }

            ]
          }
        }
      ],
      pageTotal: 0,
      totalNum: 0,
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  components: {
    conditionFiltrationBox,
    tableList,
    prepareLessonsExamine
  },
  computed: {

  },
  created () {
    this.getSearchPageNotCheckPrepareLessons(this.queryParams);
  },
  methods: {
    // 已检查列表
    getSearchPageNotCheckPrepareLessons (queryParams) {
      queryParams.searchText = queryParams.searchText.trim();
      hqSearchPageCheckedPrepareLessons(queryParams).then(result => {
        console.log('已检查列表', result.data);
        this.totalNum = result.data.Total; // 分页数
        this.mendianList = result.data.PageDataList || [];
        if (this.mendianList.length > 0) {
          this.mendianList.forEach(o => {
            o.PrepareLessonsTimeText = o.PrepareLessonsTime.slice(0, 16);
            // o.newCourseWareNameText = o.CourseWareName + `<br/><span class="form_dual_text">${o.ParentKeyValue?o.ParentKeyValue+' - ':''}${o.CourseWarePackageKeyValue}</span>`;
             o.newCourseWareNameText = o.CourseWareName + `<br/><span class="form_dual_text">${o.ParentKeyValue?o.ParentKeyValue+' - ':''}${o.AuthContent}</span>`;
            o.newAdvanceDayCount = (Number(o.AdvanceDayCount) == 1 ? `<span class="font_red">${Number(o.AdvanceDayCount)}</span>` : Number(o.AdvanceDayCount)) + '天';
            o.RummageFeedBack = this.$utils.insertStr(o.RummageFeedBack, 15, `<br/>`);
          });
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 操作按钮
    examineBtnEvent (rowData) {
      this.prepareLessonsInfo = rowData;
      this.isShowPopup = true;
      // let moduleInfo = {
      //   name: '批改作业',
      //   moduleName: 'workCorrectDetails',
      //   routerName: this.$route.name,
      //   data: { dataInfo: { ID: 2360 } }
      // };
      // this.$bus.emit('openCustomDialog', moduleInfo);
      console.log(this.prepareLessonsInfo, '操作按钮');
    },
    // 搜索
    search (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.queryParams.pageIndex = 0;
      this.getSearchPageNotCheckPrepareLessons(this.queryParams);
    },
    // 清除条件
    clearExtendSearchParams () {
      this.queryParams.pageIndex = 0;
      this.queryParams.searchText = '';
      this.queryParams.SchoolKey = '';
      this.queryParams.StartDate = '';
      this.queryParams.EndDate = '';
      this.getSearchPageNotCheckPrepareLessons(this.queryParams);
    },
    // 排序
    loadTableData (queryParams) {
      console.log(queryParams, '排序');
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.getSearchPageNotCheckPrepareLessons(this.queryParams);
    },
    submitAfter () {
      this.getSearchPageNotCheckPrepareLessons(this.queryParams);
      this.closeDialog();
    },
    closeDialog () {
      this.isShowPopup = false;
    }
  }
};
</script>
<style>
</style>

