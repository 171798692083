<template>
  <!-- 线索 -->
  <div class="clue_list">

    <div class="search_clue_div">
      <!-- 1 -->
      <div class="clue_content_class_one">
        <div class="option_title">
          <div class="general_summarizing_student_navUl">
            <div class="title_list"
                 @click="tableSwitch(0)"
                 :class="{ opt: tableType == 0 }">
              全部
            </div>
            <div class="title_list"
                 @click="tableSwitch(1)"
                 :class="{ opt: tableType == 1 }">
              未联系 {{ contactCountList.NoContactCount }}
            </div>
            <div class="title_list"
                 @click="tableSwitch(2)"
                 :class="{ opt: tableType == 2 }">
              已联系 {{ contactCountList.ContactedCount }}
            </div>
            <div class="title_list"
                 @click="tableSwitch(3)"
                 :class="{ opt: tableType == 3 }">
              已诺访 {{ contactCountList.PromiseVisitCount }}
            </div>
            <div class="title_list"
                 @click="tableSwitch(4)"
                 :class="{ opt: tableType == 4 }">
              约试课 {{ contactCountList.InvitedClassCount }}
            </div>
            <div class="title_list"
                 @click="tableSwitch(5)"
                 :class="{ opt: tableType == 5 }">
              已试课 {{ contactCountList.TrialLessonCount }}
            </div>
          </div>
        </div>
      </div>

      <!-- 2 -->
      <div class="clue_content_class_two">

        <div class="header_bottom">
          <function-btn :item="{ name: '添加线索', ModulePowerKey: '' }"
                        :className="'el-button btn_light_blue btn_hover_bg_blue buttom_sq'"
                        @clickItem="doAddClueClick">
          </function-btn>
        </div>
        <!--  -->

        <!-- 标签 -->
        <div class="table_select_box"
             style="width: 280px;margin-left: 20px;"
             :title="labelText">
          <input v-model="labelText"
                 class="label_input"
                 @click="labelClick"
                 placeholder="全部标签" />
        </div>

        <div class="table_select_box"
             style="margin-right: 20px;">
          <div class="table_select_title">最近跟单</div>
          <el-select v-model="selectedRecentlyDocumentary"
                     filterable
                     style="width: 120px;"
                     placeholder="必选"
                     @change="chgSearchRecentlyDocumentaryValue"
                     value-key="OLAPKey">
            <el-option v-for="item in recentlyDocumentaryList"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item"></el-option>
          </el-select>
        </div>
        <!-- 已选条件 -->
        <input-search-contain-btn v-model="queryParams.searchText"
                                  :placeholder="'搜索学生'"
                                  @clearSearch="clearExtendSearchParams"
                                  @onSearchEvent="search"></input-search-contain-btn>

      </div>

    </div>

    <div class="table_list_content introduce_list campusManagement_list">
      <table-list ref="tableListRef"
                  class="summarizing_list schedule_table_class"
                  :tableData="recruitStudentsList"
                  :queryParams="queryParams"
                  :tableColumns="tableColumns"
                  :rowKey="'OLAPKey'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  @loadTableData="getMessageSearchPageStudentThreadList"></table-list>
    </div>

    <!--  跟进表单  登记诺访 登记约课 报名 放弃 列表 :formTitle="'可用课单'"-->
    <custom-dialog :title="scheduleTitle"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowFollowUp"
                   :before-close="doShowFollowUpHide">
      <follow-up-form :dataInfo="dataInfo"
                      :scheduleTitle="scheduleTitle"
                      @afterSuccess="afterSuccess">
      </follow-up-form>
    </custom-dialog>
    <custom-dialog title="线索跟进记录"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowClewForm"
                   :before-close="doShowFollowUpHide">
      <clew-follow-form :studentItem="studentItem"
                        @close="doShowFollowUpHide"
                        @afterSuccess="getMessageSearchPageStudentThreadList"></clew-follow-form>
    </custom-dialog>
    <!-- 图1 -->
    <custom-dialog :title="'修改线索'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowAssociationStudentOne"
                   :before-close="doShowApplicationHide">
      <clew-add-form :threadInfo="threadInfo"
                     :isUpStudentsData="true"
                     @afterSuccess="afterSuccess">
      </clew-add-form>
    </custom-dialog>
    <custom-dialog title="跟单记录流水"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowRecordList"
                   :before-close="doShowFollowUpHide">
      <clew-follow-record-list :studentItem="studentItem"
                               :StudentThreadKey="StudentThreadKey"
                               :isAllFollowRecord="true"
                               @close="doShowFollowUpHide"></clew-follow-record-list>
    </custom-dialog>
    <custom-dialog title="筛选标签"
                   :visible.sync="isShowLabelPopup"
                   :before-close="closeLabelPopup">
      <input-label :labelInfo="queryParams"
                   :allLabelList="allLabelList"
                   @close="closeLabelPopup"
                   @checkedLabelSubmit="checkedLabelSubmit"
                   @afterSuccess="closeLabelPopup"></input-label>
    </custom-dialog>
  </div>
</template>
<script>
import { MessageSearchPageStudentThreadList, GetLabelList, StudentThreadAbandon } from '../../../API/workbench.js';
import inputSearchContainBtn from '../../report/common/input-search-contain-btn';
import tableList from '../../common/table-list/index';
import followUpForm from '../follow-up-form.vue';
import clewFollowForm from './clew-follow-form';
import clewAddForm from '../../student/clew-add-form';
import clewFollowRecordList from './clew-follow-record-list';
import inputLabel from '../../recruit-students-management/form/input-label';
export default {
  data () {
    return {
      isShowLabelPopup: false, // 标签
      labelText: '', // 标签
      allLabelList: [],
      StudentThreadKey: 0,
      isShowRecordList: false,
      threadInfo: {},
      isShowAssociationStudentOne: false, // 关联学生档案 1
      tableType: 0,
      isShowFollowUp: false,
      studentItem: null,
      selectedRecentlyDocumentary: '全部', // 最近跟单
      recentlyDocumentaryList: [
        {
          OLAPKey: 0,
          MainDemoName: '全部'
        },
        {
          OLAPKey: 1,
          MainDemoName: '0-30天'
        },
        {
          OLAPKey: 2,
          MainDemoName: '31-60天'
        },
        {
          OLAPKey: 3,
          MainDemoName: '61-90天'
        },
        {
          OLAPKey: 4,
          MainDemoName: '超过90天'
        }
      ],
      // 销售
      labelList: [],
      recruitStudentsList: [],
      queryParams: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        StartTime: '',
        EndTime: '',
        LabelKeyList: [], // 标签
        CurrentNodeKey: '' // 1-未联系;2-已联系;3-已诺访;4-已约课;5-已试课;6-已报名
      },
      dataInfo: {},
      scheduleTitle: '登记诺访',
      LabelKeyValue: '',
      contactCountList: {
        NoContactCount: '0', // 未联系
        ContactedCount: '0', // 已联系
        PromiseVisitCount: '0', // 已诺访
        InvitedClassCount: '0', // 约试课
        TrialLessonCount: '0' // 已试课
      },
      tableColumns: [
        {
          label: '序号',
          width: 65,
          prop: 'Number',
          align: 'center',
          type: 'tableIndex',
          sortable: false
        },
        {
          label: '添加日期',
          width: 100,
          sortable: 'custom',
          prop: 'GenerateTime',
          type: 'text-item'
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 110,
          align: 'left',
          type: 'text-item',
          sortable: false,
          extend: {
            type: 'expand',
            click: (rowData) => { this.doStudentsClick(rowData); }
          }
        },
        {
          label: '手机号',
          prop: 'MobilePhone',
          width: 110,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '节点',
          prop: 'CurrentNodeKeyValue',
          align: 'center',
          width: 90,
          type: 'text-item'
        },
        {
          label: '标签',
          prop: 'LabelNames',
          width: 120,
          align: 'left',
          type: 'text-item'
        },
        {
          label: '最近跟单',
          prop: 'FollowTime',
          width: 100,
          align: 'left',
          type: 'text-item'
        },
        {
          label: '最近跟单',
          prop: 'FollowContentValue',
          align: 'left',
          type: 'text-item',
          extend: {
            type: 'expand',
            click: (rowData) => { this.doFollowContentClick(rowData); }
          }
        },
        {
          label: '操作',
          prop: 'option',
          width: 310,
          type: 'table-btn',
          extend: {
            tableBtnGroup: [
              {
                name: '跟单',
                extend: {
                  isHide: false,
                  click: (rowData) => {
                    this.documentaryBtn(rowData, '跟单');
                  }
                }
              },
              {
                name: '登记诺访',
                extend: {
                  isHide: false,
                  click: (rowData) => {
                    this.editBtn(rowData, '登记诺访');
                  }
                }
              },
              {
                name: '登记约课',
                extend: {
                  isHide: false,
                  click: (rowData) => {
                    this.editBtn(rowData, '登记约课');
                  }
                }
              },
              {
                name: '报名',
                extend: {
                  isHide: false,
                  click: (rowData) => {
                    this.editBtn(rowData, '报名');
                  }
                }
              },
              {
                name: '放弃',
                extend: {
                  className: 'btn_red',
                  click: (rowData) => {
                    this.giveUpBtn(rowData, '放弃');
                  }
                }
              }
            ]
          }
        }
      ],
      totalNum: 0,
      isShowClewForm: false
    };
  },
  components: {
    inputSearchContainBtn,
    tableList,
    followUpForm,
    clewFollowForm,
    clewAddForm,
    clewFollowRecordList,
    inputLabel
  },
  computed: {
    vLabelValue () {
      return this.LabelKeyValue == '' ? '全部标签' : this.LabelKeyValue;
    }
  },
  created () {
    this.getMessageSearchPageStudentThreadList();
    this.getGetLabelList();
  },
  watch: {
  },
  activated () {
  },
  mounted () {
  },
  methods: {
    labelClick () {
      this.queryParams.LabelKeyList = [];
      this.isShowLabelPopup = true;
    },
    // 关闭 标签 弹框
    closeLabelPopup () {
      this.isShowLabelPopup = false;
    },
    checkedLabelSubmit (a, list) {
      console.log(a, 'a');
      let text = '';
      a.forEach((item, index) => {
        if (index == 0) {
          text = item;
        } else {
          text = text + '|' + item;
        }
        console.log('item', item, index);
      });
      this.labelText = text;
      this.allLabelList = a;
      list.forEach(item => {
        item.forEach(child => {
          a.forEach(p => {
            if (child.LabelKeyValue == p) {
              this.queryParams.LabelKeyList.push(child.LabelKey);
              console.log(child, 'child.LabelKeyValue', p);
            }
          });
        });
      });
      this.closeLabelPopup();
      console.log(this.labelText, this.queryParams, 'this.allLabelList', this.allLabelList);
      this.getMessageSearchPageStudentThreadList();
    },
    doStudentsClick (rowData) {
      console.log(rowData, 'rowData');
      this.threadInfo = rowData;
      this.isShowAssociationStudentOne = true;
    },
    doShowApplicationHide () {
      this.isShowAssociationStudentOne = false;
    },
    doFollowContentClick (item) {
      this.StudentThreadKey = item.OLAPKey;
      this.studentItem = item;
      this.isShowClewForm = true;
    },
    doShowFollowUpHide () {
      this.isShowFollowUp = false;
      this.isShowRecordList = false;
      this.isShowClewForm = false;
    },
    tableSwitch (type) {
      this.tableType = type;
      this.queryParams.CurrentNodeKey = type;
      this.queryParams.pageIndex = 0;
      this.getMessageSearchPageStudentThreadList();
    },
    // 线索 数据查询
    getMessageSearchPageStudentThreadList () {
      MessageSearchPageStudentThreadList(this.queryParams).then(result => {
        this.totalNum = result.data.Total;
        this.recruitStudentsList = result.data.PageDataList;
        this.contactCountList.NoContactCount = Number(result.data.NoContactCount) ? Number(result.data.NoContactCount) : '-';// 未联系
        this.contactCountList.ContactedCount = Number(result.data.ContactedCount) ? Number(result.data.ContactedCount) : '-';// 已联系
        this.contactCountList.PromiseVisitCount = Number(result.data.PromiseVisitCount) ? Number(result.data.PromiseVisitCount) : '-';// 已诺访
        this.contactCountList.InvitedClassCount = Number(result.data.InvitedClassCount) ? Number(result.data.InvitedClassCount) : '-';// 约试课
        this.contactCountList.TrialLessonCount = Number(result.data.TrialLessonCount) ? Number(result.data.TrialLessonCount) : '-';// 已试课
        this.recruitStudentsList.forEach(o => {
          o.StudentKeyValue = o.StudentKeyValue ? o.StudentKeyValue : '-';
          o.MobilePhone = o.MobilePhone ? o.MobilePhone : '-';
          o.CurrentNodeKeyValue = o.CurrentNodeKeyValue ? o.CurrentNodeKeyValue : '-';
          o.LabelNames = o.LabelNames ? o.LabelNames : '-';
          o.FollowTime = o.FollowTime ? o.FollowTime : '-';
          o.FollowContentValue = o.FollowContent ? '<div class="recently_documentary_class">' + o.FollowContent + '</div>' : '<div class="recently_documentary_class">-</div>';
        });
        console.log(result, '线索 数据查询');
      }, error => {
        layer.alert(error.msg);
      });
    },
    doAddClueClick () {
      let obj = {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '添加学生线索',
        moduleName: 'clewAddForm',
        callBack: {
          afterSuccess: () => {
            this.getMessageSearchPageStudentThreadList();
          }
        }
      };
      this.$emit('openDialog', obj);
    },
    // 标签列表
    getGetLabelList () {
      GetLabelList().then(result => {
        console.log('标签列表', result.data);
        // 分页数
        if (result.data && result.data.length > 0) {
          this.labelList = result.data;
        } else {
          this.labelList = [];
        }
        this.labelList.splice(0, 0, {
          OLAPKey: '',
          MainDemoName: '全部标签'
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    formatDate (date) {
      date = Date.new(date.replace(/-/g, '/'));
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
    },
    goTimerFun (num) {
      let timer = new Date();
      var date2 = new Date(timer);
      date2.setDate(timer.getDate() - num);
      var time2 = date2.getFullYear() + '-' + (date2.getMonth() + 1) + '-' + date2.getDate();
      return time2;
    },
    // 最近跟单
    chgSearchRecentlyDocumentaryValue (item) {
      console.log(item, 'item');
      let timer = new Date();
      let gotimer = new Date(timer.getFullYear() + '-' + (Number(timer.getMonth())));
      let goStartTimer = '';
      let goEndTimer = '';
      this.selectedRecentlyDocumentary = item.MainDemoName;
      switch (item.OLAPKey) {
        case 0:   // 全部
          goStartTimer = '';
          goEndTimer = '';
          break;
        case 1:  // 0-30天
          goStartTimer = this.formatDate(this.goTimerFun(30));
          goEndTimer = this.formatDate(timer.getFullYear() + '-' + (Number(timer.getMonth()) + 1) + '-' + (Number(timer.getDate())));
          break;
        case 2:    // 31-60天
          goStartTimer = this.formatDate(this.goTimerFun(60));
          goEndTimer = this.formatDate(this.goTimerFun(30));
          break;
        case 3:    // 61-90天
          goStartTimer = this.formatDate(this.goTimerFun(90));
          goEndTimer = this.formatDate(this.goTimerFun(60));
          break;
        case 4:   // 超过90天
          goStartTimer = '';
          goEndTimer = this.formatDate(this.goTimerFun(90));
          break;
        default:
          break;
      }
      this.queryParams.StartTime = goStartTimer;
      this.queryParams.EndTime = goEndTimer;
      this.queryParams.pageIndex = 0;
      console.log(goStartTimer, 'goStartTimer goEndTimer', goEndTimer, gotimer, 'this.queryParams', this.queryParams);
      this.getMessageSearchPageStudentThreadList();
    },
    // 跟单
    documentaryBtn (item) {
      this.studentItem = item;
      this.isShowClewForm = true;
      console.log('documentaryBtn', item);
    },
    // 放弃
    giveUpBtn (rowData) {
      layer.confirm('确定放弃该数据吗？', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              StudentThreadAbandon(rowData.OLAPKey).then(result => {
                layer.close();
                layer.alert('放弃成功');
                this.getMessageSearchPageStudentThreadList();
              }, error => {
                layer.alert(error.msg);
              });
            }
          }
        ]
      });
    },
    editBtn (rowData, title) {
      this.dataInfo = rowData;
      this.scheduleTitle = title;
      console.log(this.scheduleTitle, 'this.scheduleTitle this.dataInfo', this.dataInfo);
      this.isShowFollowUp = true;
    },
    afterSuccess () {
      this.isShowFollowUp = false;
      this.isShowAssociationStudentOne = false;
      this.$emit('afterSuccess');
      this.getMessageSearchPageStudentThreadList();
    },
    search () {
      this.queryParams.pageIndex = 0;
      this.getMessageSearchPageStudentThreadList();
    },
    clearExtendSearchParams () {
      this.tableType = 0;
      this.selectedRecentlyDocumentary = '全部';
      this.LabelKeyValue = '全部标签';
      this.labelText = '';
      this.allLabelList = [];
      this.queryParams = {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        StartTime: '',
        EndTime: '',
        LabelKeyList: [], // 标签
        CurrentNodeKey: '' // 1-未联系;2-已联系;3-已诺访;4-已约课;5-已试课;6-已报名
      };
      this.getMessageSearchPageStudentThreadList();
    }
  }
};
</script>

<style scoped>
.search_clue_div {
  margin-bottom: 10px;
  line-height: 70px;
  align-items: center;
  margin-top: -10px;
  height: 100px;
}
.clue_content_class_two {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.general_summarizing_student_navUl {
  height: 37px !important;
  border-bottom: none !important;
  margin: 0px !important;
}
.general_summarizing_student_navUl .title_list {
  padding: 0 !important;
  line-height: 37px !important;
  height: 37px !important;
  width: 120px;
  font-size: 14px !important;
  cursor: pointer;
  border: 1px solid #ececec !important;
}
.general_summarizing_student_navUl .opt {
  color: #3498db !important;
  border: 1px solid #3498db !important;
  background-color: rgba(52, 152, 219, 0.15) !important;
  z-index: 2;
}
.schedule_table_class >>> .el-table .recently_documentary_class {
  width: 99%;
  max-height: 84px;
  line-height: 54px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  right: 1px;
  z-index: 13;
  border: 1px solid transparent !important;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 40px;
  color: #333 !important;
}
.schedule_table_class >>> .el-table .recently_documentary_class::after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -11.5px;
  right: 20px;
  width: 21px;
  height: 21px;
  padding-right: 17px;
  z-index: 2;
  background: url(../../../../public/image/add_dis_icon@2x.png) no-repeat 50%;
  background-size: 18px 18px;
}
.schedule_table_class >>> .el-table .btn_red {
  color: red !important;
}
.label_input {
  border: 1px solid #ececec;
  width: 260px;
  height: 36px;
  border-radius: 3px;
  cursor: pointer;
  padding-left: 10px;
  background: url(../../../../public/image/more.png) no-repeat;
  background-size: 24px;
  background-position-x: 225px;
  background-position-y: 5px;
  z-index: 2;
  padding-right: 25px;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.label_input::after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -7.5px;
  right: 5px;
  width: 15px;
  height: 15px;
  padding-right: 17px;
  background: url(../../../../public/image/more.png) no-repeat center center;
  background-size: 15px;
  z-index: 2;
}
</style>

