<template>
  <div class="from_list"
       :class="required?'from_list_required':''">
    <div class="from_list_title"
         v-if="titleName">{{titleName}}</div>
    <div class="from_list_content">
      <div class="from_list_textarea"
           :class="textareaSize">
        <textarea placeholder="请输入"
                  :value="value"
                  @input="inputEvent($event.target.value)"></textarea>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    titleName: {//  标题 （选填）
      type: String,
      default: ''
    },
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: {//  是否必填 （选填）
      type: Boolean,
      default: false
    },
    textareaSize: {//  输入框尺寸 （选填）
      type: String,
      default: 'size_small'
    }
  },
  methods: {
    inputEvent (val) {
      this.$emit('inputEvent', val);
    }
  }

};
</script>

<style>
</style>