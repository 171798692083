<template>
  <div style="min-height:615px">
    <div class="new_course_time_from">
      <upSellLabel :dataInfo="dataInfo"
                   :recordListLength="recordDataListLength"
                   @showRecord="showRecord"></upSellLabel>
      <div class="up_sell_record_from bg_white">
        <!-- 选项卡 -->
        <div class="up_sell_record_from_title">
          客户意向
        </div>
        <option-list :optionList="optionList"
                     @seletedItem="seletedOption"></option-list>
        <div class="up_sell_record_from_date pr"
             style="margin-top:0px"
             v-if="dataObj.FollowRecord.IntentionKey==4">
          <div class="btn_hover_red pa"
               style="width:85px;right:1px"
               @click.stop="abandon">
            <span>放弃跟进</span>
          </div>
        </div>
        <div class="from_textarea_box">
          <div class="up_sell_record_from_title from_textarea_title">
            跟进记录/结果
          </div>
          <div class="from_textarea_content">
            <textarea v-model="dataObj.FollowRecord.ContentScript"
                      placeholder="请输入跟进记录"
                      maxlength="600"></textarea>
          </div>
        </div>
        <div class="up_sell_record_from_date pr">
          <input-date v-model="dataObj.FollowRecord.NextFollowTime"
                      :elementId="'classDate'"
                      :formTitle="'下次跟进'"
                      :minDate="$utils.formatDateToLine(Date.new())"
                      :formPlaceholder="'请选择'"
                      @changeDate="changeDate">
          </input-date>
          <el-checkbox v-if="seletedItem.SourceTypeKey == 3"
                       v-model="IsPushMsg"
                       style="margin-top:8px"
                       :true-label="1"
                       :false-label="0">消息</el-checkbox>

        </div>
        <div v-if="seletedItem.StatusKey!=2">

          <div class="table_select_box"
               v-if="seletedItem.DemoKey==0||(demoInfo&&demoInfo.StatusKey!=0)"
               style="margin-right:20px;min-width:306px">
            <div class="table_select_title"
                 style="margin-right:15px">预约演示</div>
            <el-select v-model="appointType"
                       placeholder="请选择"
                       ref="resetText"
                       @change="selectAppointtype">
              <el-option v-for="item in appointtypeList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div v-if="appointType==1">
            <div class="up_sell_record_from_date pr"
                 style="margin-top: 10px;"
                 v-if="appointType==1">
              <input-date v-model="dataObj.DemoRecord.DemoTime"
                          :elementId="'classDate'"
                          :formTitle="'演示日期'"
                          :minDate="$utils.formatDateToLine(Date.new())"
                          :formPlaceholder="'请选择'"
                          @changeDate="changeDateTime">
              </input-date>
              <div class="from_input_box">
                <el-time-select v-model="StartTimeName"
                                :picker-options="{
                                  start: '07:30',
                                  step: '00:15',
                                  end: '23:00'
                                }"
                                @change="setClassTimeRange"
                                placeholder="选择时间">
                </el-time-select>
              </div>
            </div>
            <div class="table_select_box"
                 style="margin-bottom:20px;min-width:306px;margin-right:0"
                 v-if="appointType==1">
              <div class="table_select_title"
                   style="margin-right:15px">演示对象</div>
              <el-select v-model="demoType"
                         placeholder="请选择"
                         ref="resetText"
                         @change="selectdemotype">
                <el-option v-for="item in demoList"
                           :key="item.value"
                           :label="item.label"
                           :value="item">
                </el-option>
              </el-select>
              <div style="margin-left:20px"
                   class="from_input_box">
                <input type="text"
                       style="font-size:12px"
                       v-model="DemoSupport"
                       placeholder="请输入">
              </div>
            </div>
          </div>
          <div class="flex"
               v-if="demoInfo&&demoInfo.StatusKey==0"
               style="margin-bottom:20px;align-items: center;">
            <div class="form_info_field">演示时间</div>
            <div style="width:130px;margin-right:20px;line-height:18px">
              <div>{{this.$utils.formatDateStr(demoInfo.DemoTime,'MM-dd')+' '+demoInfo.StartTimeName}}</div>
              <div>{{demoInfo.DemoSupport}}</div>
            </div>
            <el-checkbox v-model="dataObj.PreDemoRecord.StatusKey"
                         :true-label="1"
                         :false-label="0"
                         @change="changeFinishStatusKey">完成</el-checkbox>
            <el-checkbox v-model="dataObj.PreDemoRecord.StatusKey"
                         :true-label="2"
                         :false-label="0">取消</el-checkbox>
          </div>
        </div>

      </div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             :btnText="'确认'"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="doAfterClickCancelBtn"></save-cancel-btn-group>
    </div>
    <custom-dialog title="全部记录"
                   :visible.sync="isShowRecord"
                   :before-close="closeisShowRecord">
      <recordList :recordList="recordDataList"></recordList>
    </custom-dialog>
  </div>
</template>
<script>
import upSellLabel from './up-sell-record-component/up-sell-label';
import recordList from './up-sell-record-component/record-list';
import optionList from './up-sell-record-component/select-option';
import { AddFollowRecord, SelectFollowRecode, GetDemoDetails } from '../../../../../API/workbench';
export default {
  data () {
    return {
      seletedItem: {},
      optionList: [// 选项卡菜单
        { level: 'S', value: '极高', cardKey: 1, isOpt: true },
        { level: 'A', value: '较高', cardKey: 2, isOpt: false },
        { level: 'B', value: '一般', cardKey: 3, isOpt: false },
        { level: 'C', value: '较低', cardKey: 4, isOpt: false }
      ],
      appointtypeList: [
        {
          label: '无',
          value: 0
        },
        {
          label: '预约',
          value: 1
        }
      ],
      appointType: 0,
      demoList: [
        {
          label: '校长',
          value: 1
        },
        {
          label: '教务',
          value: 2
        },
        {
          label: '老师',
          value: 3
        },
        {
          label: '其他',
          value: 4
        }
      ],
      demoType: '',
      dataObj: {
        FollowRecord: {
          IntentionKey: '1', // '客户意向:S-极高;A-较高;B-一般;C-较低',
          IntentionKeyValue: 'S', // '客户意向Key:S A B C',
          ContentScript: '', // '跟进记录',
          NextFollowTime: '', // '下次跟进时间'
          StatusKey: 0
        },
        DemoRecord: null,    // 可为null
        // {
        //   DemoSupport: '', // 演示对象
        //   DemoTime: '', // 演示时间'
        //   StatusKey: '' // 0-预约;1-完成;2-取消'
        // },
        PreDemoRecord: null
        // {// 可为null
        //   OLAPKey: '', //  上一次演示key'
        //   StatusKey: '' //  1-完成;2-取消'
        // }
      },
      DemoSupport: '',   // 绑定
      recordDataList: [],  // 全部记录
      demoInfo: null, // 预约列表
      isShowRecord: false,
      IsPushMsg: 0, // 是否发送消息
      isShowTime: false,
      StartTimeName: '',
      EendTimeName: ''
    };
  },
  props: {
    dataInfo: Object
  },
  components: {
    upSellLabel, optionList, recordList
  },
  computed: {
    recordDataListLength () {
      return this.recordDataList.length;
    }
  },
  created () {
    console.log(this.dataInfo, 'this.dataInfo');
    this.seletedItem = this.dataInfo;
    this.getAllFollowRecord();
    if (this.seletedItem.DemoKey > 0) {
      this.getDemoDetail();
      // this.initOptionHighLinght();
    } else {
      this.dataObj.PreDemoRecord = null;
    }
    // if (this.seletedItem.SourceTypeKey == 3) {
    //   this.IsPushMsg = 1;
    // }
    // this.initOptionHighLinght();
  },
  methods: {
    initOptionHighLinght () {
      if (this.seletedItem.IntentionKeyValue) {
        this.optionList.forEach(o => {
          if (o.level === this.seletedItem.IntentionKeyValue) {
            o.isOpt = true;
            this.dataObj.FollowRecord.IntentionKey = o.cardKey;
            this.dataObj.FollowRecord.IntentionKeyValue = o.level;
          } else {
            o.isOpt = false;
          }
        });
      }
    },
    // 选中意向
    seletedOption (item) {
      this.dataObj.FollowRecord.IntentionKey = item.cardKey;
      this.dataObj.FollowRecord.IntentionKeyValue = item.level;
      console.log(item, 'seletedOption', this.dataObj);
    },
    // 放弃试课
    abandon () {
      if (this.seletedItem.StatusKey != 3 && this.seletedItem.StatusKey != 0) {
        layer.alert('非关停门店不可放弃');
        return false;
      }
      layer.confirm('请问确认放弃?', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              this.dataObj.FollowRecord.StatusKey = 1;
              AddFollowRecord(this.dataObj, this.seletedItem.OLAPKey, this.IsPushMsg).then(result => {
                this.$emit('afterSuccess');
                this.$emit('close');
              }).catch(error => {
                layer.alert(error.msg);
              });
              layer.close();
            }
          }
        ]
      });
    },
    getAllFollowRecord () {
      console.log(this.seletedItem, 'getAllFollowRecord');
      SelectFollowRecode(this.seletedItem.OLAPKey).then(result => {
        this.recordDataList = result.data;
        console.log('SelectFollowRecode', result.data);
      }).catch(error => {
        console.log('errorSelectFollowRecode', error);
      });
    },
    getDemoDetail () {
      GetDemoDetails(this.seletedItem.DemoKey).then(result => {
        this.demoInfo = result.data;
        console.log(this.demoInfo, 'this.demoInfo');
        this.dataObj.PreDemoRecord = {
          OLAPKey: this.demoInfo.OLAPKey, //  上一次演示key'
          StatusKey: Number(this.demoInfo.StatusKey) //  1-完成;2-取消'
        };
        console.log('GetDemoDetails', result.data);
      }).catch(error => {
        console.log('errorGetDemoDetails', error);
      });
    },
    submit (callback) {
      let isPass = false;
      if (this.dataObj.FollowRecord.ContentScript == '') {
        isPass = true;
        layer.alert('请输入跟进记录');
      } else if (this.dataObj.FollowRecord.NextFollowTime == '') {
        isPass = true;
        layer.alert('请选择下次跟进日期');
      }
      if (this.dataObj.DemoRecord) {
        if (Number(this.demoType) <= 0) {
          isPass = true;
          layer.alert('请选择演示对象');
        }
        if (!this.DemoSupport) {
          isPass = true;
          layer.alert('请选择演示人');
        }
        this.dataObj.DemoRecord.DemoSupport = this.demoTypeValue + '-' + this.DemoSupport;
      }
      if (isPass) {
        if (callback) {
          callback();
        }
        return false;
      } else {
        console.log(this.seletedItem, 'AddFollowUpRecords');
        AddFollowRecord(this.dataObj, this.seletedItem.OLAPKey, this.IsPushMsg).then(result => {
          layer.alert('添加跟进记录成功');
          this.$emit('afterSuccess');
          this.$emit('close');
        }).catch(error => {
          layer.alert(error.msg);
        }); ;
      }
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    },
    changeDate (date) {
      console.log('changeDate', date);
    },
    changeDateTime (date) {
      console.log('changeDate', date);
    },
    showRecord () {
      this.isShowRecord = true;
    },
    closeisShowRecord () {
      this.isShowRecord = false;
    },
    selectAppointtype (value) {
      if (value == 0) {
        this.dataObj.DemoRecord = null;
        this.demoTypeValue = '';
        console.log(this.dataObj.DemoRecord, 'this.dataObj.DemoRecord');
        // this.appointType
      } else {
        this.dataObj.DemoRecord = {
          DemoSupport: '', // 演示对象
          DemoTime: '', // 演示时间'
          StartTimeName: '', // 演示开始时间',
          EendTimeName: '', // 演示结束时间',
          StatusKey: '' // 0-预约;1-完成;2-取消'}
        };
      }
      console.log('selectAppointtype', value, this.appointType);
    },
    selectdemotype (item) {
      this.demoType = item.label;
      this.demoTypeValue = item.label;
      console.log('selectdemotype', item, this.demoType);
    },
    // 选中完成预约状态
    changeFinishStatusKey (val) {
      console.log('changeFinishStatusKey', val);
    },
    showTimeDialog () {
      this.isShowTime = true;
    },
    closeShowTime () {
      this.isShowTime = false;
    },
    setClassTimeRange (startTime, endTime) {
      this.dataObj.DemoRecord.StartTimeName = startTime;
      // this.dataObj.DemoRecord.EendTimeName = endTime;
      this.StartTimeName = startTime;
      // this.EendTimeName = endTime;
      this.isShowTime = false;
    }
  }
};
</script>
