<template>
  <div class="view_photo_img body_img_list"><img :src="headImg"
         alt=""
         class="img_box"></div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object,
    imgList: Array
  },
  computed: {
    headImg () {
      if (!this.item.AttachUrl) {
        this.item.AttachUrl = '';
      }
      if (this.imgList.length < 3) {
        if (this.item.AttachUrl.indexOf('http') > -1) {
          return this.item.AttachUrl;
        } else {
          return this.$store.getters.CDNURL + this.item.AttachUrl;
        }
      } else {
        return this.$utils.ossLitimgSet(this.item.AttachUrl);
      }
    }
  }
};
</script>
