<template>
<div>
  <div class="form_info_border_type form_info_edit mendian_setting_content single_shadow">
      <div :class="{input_readonly:!vIsShowCircleCourseItem,arrange_num:vIsShowAllRange=='全部'}">
        <input-dialog :formTitle="'选择范围'"
                      :dialogTitleName="'选择范围'" 
                      v-model="vIsShowAllRange"
                      :required="true"
                      :readonly="!vIsShowCircleCourseItem"
                      :class="'arrange_hour no_border_bottom'"
                      @showEldilog="openClassTimeRange"></input-dialog>
        <!-- <span class="bottom_text"
              @showEldilog="openClassTimeRange"
              v-if="vIsShowAllRange=='全部'&&circleCourseInfo.IsNotEndKey!=1">{{vClassRangeTime}}</span> -->
      </div>
    </div>
    
    <custom-dialog title="修改课表范围"
                   :visible.sync="isShowClassTimeRangeDialog"
                   :before-close="closeClassTimeRangeDilog">
      <edit-class-time-range :circleCourseInfo="circleCourseInfo"
                             :sClassDate="sClassDate"
                             :eClassDate="eClassDate"
                             :minDate="minDate"
                             :maxDate="maxDate"
                             :weekkey="circleCourseInfo.WeekKey"
                             @afterChangeClassTime="confirmClassRangeTime" 
                             @closeDilog="closeClassTimeRangeDilog"></edit-class-time-range>
    </custom-dialog>
    </div>
</template>

<script>
import editClassTimeRange from '../edit-class-time-range';
export default {
  data () {
    return {
      sClassDate: '', // 开始上课时间
      eClassDate: '', // 结束上课时间
      minDate: '', // 最小日期
      maxDate: '', // 最大日期
      isShowClassTimeRangeDialog: false // 修改课表范围
    };
  },
  props: {
    circleCourseInfo: {
      type: Object
    }
  },
  computed: {
    vIsShowCircleCourseItem () {
      let bool = false;
      if (!this.circleCourseInfo.SubRule) { // SubRule为NULL 就显示循环课块
        bool = true;
      }
      return bool;
    },
    vIsShowAllRange () {
      let str = this.vClassRangeTime;
      if (this.sClassDate == this.minDate && Date.new(this.eClassDate).getTime() >= Date.new(this.maxDate).getTime()) {
        str = '全部';
      } else if (Date.new(this.sClassDate).getTime() > Date.new(this.minDate).getTime() && this.eClassDate == this.maxDate && this.circleCourseInfo.IsNotEndKey == 1) {
        str = this.sClassDate + ' ~ 不结束';
      }
      return str;
    },
    vClassRangeTime () {
      return this.sClassDate + '~' + this.eClassDate + ' ' + this.classCount + '节';
    }
  },
  components: {
    editClassTimeRange
  },
  created () {
    this.minDate = this.circleCourseInfo.SubRule ? this.circleCourseInfo.SubRule.StartTime : this.circleCourseInfo.CurrentRuleTime;
    this.maxDate = this.circleCourseInfo.SubRule ? this.circleCourseInfo.SubRule.EndTime : this.circleCourseInfo.LastClassTime;
    this.classCount = Number(this.circleCourseInfo.SubRule ? this.circleCourseInfo.SubRule.ClassCount : this.circleCourseInfo.ClassCount);
    this.sClassDate = this.minDate;
    this.eClassDate = this.maxDate;
  },
  methods: {
    // 打开 修改课表范围
    openClassTimeRange () {
      if (this.vIsShowCircleCourseItem) {
        this.isShowClassTimeRangeDialog = true;
      }
    },
    // 确认 修改课表范围
    confirmClassRangeTime (startDate, endDate, classNum) {
      this.sClassDate = startDate;
      this.eClassDate = endDate;
      this.classCount = classNum;
      this.$emit('confirmClassRangeTime', startDate, endDate, classNum);
      this.closeClassTimeRangeDilog();
    },
    // 关闭 修改课表范围
    closeClassTimeRangeDilog () {
      this.isShowClassTimeRangeDialog = false;
    }
  }
};
</script>

<style>

</style>