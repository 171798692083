<template>
  <!-- 班级管理 -->
  <div class="course_class_manage">
    <div class="course_class_manage_title">
      <div class="manage_title_left">
        <span>班级分类</span>
        <span class="font_blue"
              style="cursor: pointer;"
              @click="onAddClassClassItemClick">新增班类</span>
      </div>
      <div class="manage_title_right">
        <span>所有班级</span>
        <span class="font_blue"
              style="cursor: pointer;"
              @click="onAddClassNameItemClick">新增班级</span>
      </div>
    </div>
    <div class="course_class_manage_content"
         ref="manageContent"
         style="position: relative;" 
         @scroll="scrollTOP">
      <setting-content v-for="(item,index) in vcourseClassCourseNameList"
                       :key="index"
                       :courseClasBox="item"
                       :courseClasIndex="index"
                       :courseClassList="vcourseClassCourseNameList"
                       @onCourseClassOperateClick="doCourseClassOperateClick"
                       @oncourseNameItemClick="doOperate"
                       @doMouseEvent="doMouseEvent"></setting-content>
    </div>
    <!-- 班类操作菜单 -->
    <div v-if="isShowCourseTypeMenu">
      <div class="course_student_attendance_operation module_content_menuList"
           :style="'top:'+newOffsetHeight+'px'+';'+'left:120px'">
        <div class="top_bg"></div>
        <drop-down-box :optionJsonList="menuItemList"
                       @clickOptionForAlertDialog="doCourseClassMenuClick"></drop-down-box>
      </div>
    </div>
    <!-- 课名操作菜单 -->
    <div v-if="isShowCourseNameMenu"
         @mouseenter.stop="mouseOverNewCourseTable"
         @mouseleave.stop="mouseOutNewCourseTable">
      <div class="course_student_attendance_operation module_content_menuList"
           :style="'top:'+newOffsetHeight+'px'+';'+'left:' +newOffsetLeft+'px'">
        <div class="top_bg"></div>
        <drop-down-box :optionJsonList="menuItemList"
                       @clickOptionForAlertDialog="doCourseNameItemClick"></drop-down-box>
      </div>
    </div>

  </div>
</template> 

<script>

import {
  MessageSearchPageForGradeType,
  getUsingClass,
  FinishedGrade, // 结束班级
  StopClassType // 结束班类

} from '../../../../API/workbench';
import settingContent from './setting-content';
import dropDownBox from '../../../common/drop-down-box';
export default {
  data () {
    return {
      gradeList: {
        typeList: [],
        nameList: []
      }, // 班类列表
      options: {
        type: 'add',
        moduleName: '',
        data: {}
      }, // 操作类型
      menuItemList: [
        { type: 1, name: '查看', eventName: 'view', isShow: true, ModulePowerKey: '' },
        { type: 2, name: '修改', eventName: 'edit', isShow: true, ModulePowerKey: '' },
        { type: 3, name: '结束', eventName: 'unActive', isShow: true, ModulePowerKey: '' }
      ],
      params: {
        pageIndex: 0, // 每页记录数，0：表示查询所有
        pageSize: 0, // 页码，0为第一页
        searchText: '', //	搜索条件：模糊匹配姓名、昵称、学号、客户手机号、客户名 课
        orderBy: '', // 排序字段
        sequence: '' // 排序字段
      },
      newOffsetHeight: 0, // 元素纵向位置
      newOffsetLeft: 0, // 元素横向位置
      scrollETarget: { scrollTop: 0 }, // 滚动元素
      courseClassItem: {}, // 选择操作的班类
      isShowCourseTypeMenu: false,
      isShowCourseNameMenu: false, // 班级名称操作菜单
      courseInfo: {} // 班级信息

    };
  },
  props: [

  ],
  components: {
    settingContent,
    dropDownBox
  },

  created () {
    this.getMessageSearchPageForGradeType();
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isShowCourseTypeMenu = false;
    }, false);
    this.$dialog.register(this, ['communitySetting', 'sellSourceSetting']);
  },
  activated () {

  },
  watch: {

  },
  computed: {
    vcourseClassCourseNameList () {
      let courseClassList = [];// 课类列表
      let courseNameList = [];// 课名列表
      let notClassifiedList = {// 未分类课程列表
        OLAPKey: 0,
        MainDemoName: '默认班级',
        buildCourseList: []
      };
      this.gradeList.typeList.forEach(o => {
        this.$set(o, 'isMenu', false);
        courseClassList.push(o);
      });
      this.gradeList.nameList.forEach(o => {
        this.$set(o, 'isMenu', false);
        courseNameList.push(o);
      });
      // 未分类课程 构建
      courseNameList.forEach(p => {
        if (p.GradeTypeKey == 2 || p.GradeTypeKey == 0) {
          notClassifiedList.buildCourseList.push(p);
        }
      });
      // 课类下的课名构建
      courseClassList.forEach(o => {
        this.$set(o, 'buildCourseList', []);
        courseNameList.forEach(i => {
          if (o.OLAPKey == i.GradeTypeKey) {
            o.buildCourseList.push(i);
          }
        });
      });
      courseClassList.unshift(notClassifiedList);
      console.log(courseClassList, '班级');
      return courseClassList;
    }
  },
  methods: {
    // 弹出框异常
    doCourseClassOperateClick (item, index, locationHeight) {
      let dialogHeight = 76;// 弹出层头部的高度
      this.newOffsetHeight = this.getElementHeight(index) + dialogHeight - locationHeight - this.scrollETarget.scrollTop;
      this.courseClassItem = item;
      this.isShowCourseNameMenu = false;
      this.isShowCourseTypeMenu = true;
    },
    //  班类=>显示操作类型
    doCourseClassMenuClick (options) {
      // if (this.courseClassItem.buildCourseList.length > 0 && options.eventName == 'unActive') {
      //   layer.alert('该班类下有未结束的班级时不能结束!');
      // } else {
      this.courseClassItem.isMenu = false;
      let obj = {
        type: options.eventName,
        moduleName: 'squadClassTypeSetting',
        data: this.courseClassItem
      };
      this.doOperate(obj);
      // }
    },
    // 物品名称=>操作
    doCourseNameItemClick (options) {
      let obj = {
        type: options.eventName,
        moduleName: 'squadClassSetting',
        data: this.courseInfo
      };
      this.doOperate(obj);
    },
    // 班级移出移入-弹出框
    doMouseEvent (type, index, locationHeight, courseIndex, courseItem) {
      this.courseInfo = courseItem;
      let dialogHeight = 118; // 弹窗头部到第一个班级的高度
      let courseHeight = parseInt(courseIndex / 5) * 36;// 计算班级纵向所在的高度 每列高为38px
      this.newOffsetHeight = this.getElementHeight(index) + dialogHeight - locationHeight - this.scrollETarget.scrollTop + courseHeight;// 纵向位置
      this.newOffsetLeft = (courseIndex % 5) * 144 + 228;// 计算班级横向所在的偏移量 第一个默认是228，每个班级相距为144px 横向位置
      this.isShowCourseTypeMenu = false;
      // console.log(this.getElementHeight(index), '偏移量');
      if (type == 1) { // 移入
        this.mouseOverNewCourseTable();
      } else { // 离开
        // this.mouseOutNewCourseTable();
      }
    },
    // 移入时班级继续高亮
    mouseOverNewCourseTable () {
      this.isShowCourseNameMenu = true;
    },
    // 移出时班级继续高亮
    mouseOutNewCourseTable () {
      this.isShowCourseNameMenu = false;
    },
    //  获取班类所在的高度
    getElementHeight (index) {
      let elementHeight = 0; // 元素的高度
      this.vcourseClassCourseNameList.forEach((o, i) => {
        if (i == 0) { // 第一条不包括新增操作的高度计算
          if (o.buildCourseList.length > 10) {
            elementHeight = Math.ceil((o.buildCourseList.length - 10) / 5) * 36 + 88;
          } else {
            elementHeight = 88;
          }
        }
        if (index >= i && i != 0) { // 其余的+新增操作项的高度计算
          if (o.buildCourseList.length + 1 > 10) {
            elementHeight += Math.ceil((o.buildCourseList.length + 1 - 10) / 5) * 36 + 86;
          } else {
            elementHeight += 86;
          }
        }
      });
      return elementHeight;
    },
    // 获取所有班级类型列表
    getMessageSearchPageForGradeType () {
      this.getClassNameList();
      MessageSearchPageForGradeType(this.params).then(result => {
        this.gradeList.typeList = result.data.PageDataList;
        console.log(this.gradeList.typeList, '班级类型列表');
      }, error => {
        layer.alert(error.msg);
      });
    },
     // 获取所有班级名称列表
    getClassNameList () {
      let StatusKey=4 //	班级状态 4-进行中 5-已关闭
      getUsingClass(StatusKey).then(result => {
        this.gradeList.nameList = result.data;
        console.log(this.gradeList.nameList, '班级名称列表');
      });
    },
    // 刷新班类/班名
    upCourseAndClassListData (type, item) {
      console.log(item, '刷新班类/班名');
      this.getMessageSearchPageForGradeType();
      console.log(this.courseClassCourseNameList, '获取班类课名列表');
      this.$bus.emit('AfterClassGradeAddOrEdit', type, item);
    },
    // 新增班类项
    onAddClassClassItemClick () {
      this.options.type = 'add';
      this.options.data = {};
      this.options.moduleName = 'squadClassTypeSetting';
      this.doOperate(this.options);
    },
    // 新增班级项
    onAddClassNameItemClick () {
      this.options.type = 'add';
      this.options.data = {};
      this.options.moduleName = 'squadClassSetting';
      this.doOperate(this.options);
    },
    doOperate (options) {
      this.options.moduleName = options.moduleName;
      console.log(options, '更新');
      switch (options.type) {
        case 'add':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: this.showModulesName(this.options.moduleName, '新增'),
            moduleName: this.options.moduleName,
            data: { editItem: options.data || {}, isReadonly: false },
            callBack: {
              afterSuccess: (type, data) => { this.upCourseAndClassListData(type, data); }
            }
          });
          console.log(options, 'add');
          break;
        case 'edit':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: this.showModulesName(this.options.moduleName, '新增'),
            moduleName: this.options.moduleName,
            data: { editItem: options.data, isReadonly: false },
            callBack: {
              afterSuccess: (type, data) => { this.upCourseAndClassListData(type, data); }
            }
          });
          console.log(options, 'edit');
          break;
        case 'view':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: this.showModulesName(this.options.moduleName, '查看'),
            moduleName: this.options.moduleName,
            data: { editItem: options.data, isReadonly: true }
          });
          console.log(options, 'view');
          break;
        case 'unActive':
          console.log(options, 'unActive');
          layer.confirm('确定是否结束?', {
            btn: [{
              name: '确认',
              callBack: () => {
                if (options.moduleName == 'squadClassTypeSetting') {
                  StopClassType(options.data.OLAPKey).then(result => {
                    options.data.StateKey = 3;
                    this.getMessageSearchPageForGradeType();
                  }, error => {
                    layer.alert(error.msg);
                  });
                } else if (options.moduleName == 'squadClassSetting') {
                  FinishedGrade(options.data.OLAPKey).then(result => {
                    options.data.StateKey = 3;
                    this.getMessageSearchPageForGradeType();
                  }, error => {
                    layer.alert(error.msg);
                  });
                }
                layer.close();
              }
            }
            ]
          });
          break;
        default:// 超出标准操作，自行处理

          break;
      }
      this.isShowCourseTypeMenu = false;
    },
    showModulesName (value, type) {
      switch (value) {
        case 'squadClassSetting':
          return type + '班级';
        case 'squadClassTypeSetting':
          return type + '班类';
        default:
          return '';
      }
    },
    // 滚动加载事件.
    scrollTOP (e) {
      this.scrollETarget = e.target;
      if (this.scrollETarget.scrollTop) {
        this.vcourseClassCourseNameList.forEach(o => {
          o.isMenu = false;
        });
      }
      this.isShowCourseTypeMenu = false;
      this.isShowCourseNameMenu = false;
    }
  }
};
</script>
