<template>
  <div class="margin_left_right_div">
    <div class="course_arrange_manage_view all_view template_minOrmax_width_div"
         style="width: auto!important;">
      <div class="course_arrange_manage_box scroll_type">
        <div class="course_arrange_manage_left">
          <div class="course_arrange_manage_title pr">
            <div class="title_name">
              排课管理
            </div>
            <div class="right_tips">
              <input-form-tip :tips="'现有课程可按课程表建立。<a class=tips_a href=https://mp.weixin.qq.com/s/6XihH6O6nVqyVPZko3FupQ target=_Blank>详情<a>'"></input-form-tip>

            </div>
            <function-btn :item="addNewCourseBtn"
                          :className="'title_btn'"
                          @clickItem="doNewCrouse">
            </function-btn>
            <!-- <div class="title_btn">
            新建课表
          </div> -->
          </div>
          <div class="course_arrange_manage_search">
            <input type="text"
                   v-model="searchText"
                   placeholder="请输入">
          </div>
          <div class="course_arrange_manage_ul">
            <course-list v-for="(item,key) in vCourseRuleList"
                         :key="key"
                         :item="item"
                         @seletedItem="seletedItem"></course-list>
          </div>
        </div>
        <div class="course_arrange_manage_right">

          <div class="course_ul_box">
            <div class="course_ul">
              <grade-class-item v-for="(item,key) in vgradeList"
                                :key="key"
                                :item="item"
                                :courseInfo="seletedCourseInfo"
                                :copyData="copyData"
                                ref="gradeClassList"
                                @clearTimeOutAfter="clearTimeOutAfter"
                                @setPopUpPositionAfter="setPopUpPositionAfter"
                                @updateData="updateCourseData"></grade-class-item>
              <div v-if="vgradeList.length == 0"
                   class="monitoring_content_nodata"
                   style="margin-top:120px">
                <div class="monitoring_content_nodata_txt">
                  亲，当前没有任何数据哦~
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="general_course_summarizing">
          <div v-if="vTypeCourseNameList.length> 0"
               class="general_course_summarizing_content">
            <courseNameItem v-for="item in vTypeCourseNameList"
                            :key="item.CourseNameKey"
                            :item="item"
                            :searchText="searchText"
                            :isTemporaryStu="isTemporaryStu"
                            :copyData="copyData"
                            :seletedStructuredRuleKey="seletedStructuredRuleKey"></courseNameItem>
          </div>
          <div v-else
               class="course_block_nodata"
               style="border-radius: 8px;height: 500px;background-position:center 200px">
            <div class="monitoring_content_nodata_txt"
                 style="padding-top: 290px;">亲，当前没有任何数据哦~</div>
          </div>
        </div> -->
        </div>
      </div>
      <studentInfoPopUp ref="studentInfoPopUp"
                        v-show="showPopUp"
                        :vleft="positionLeft"
                        :vBottom="positionBottom"
                        :showPopUp="showPopUp"
                        @mouseOutEl="keepShow"
                        @mouseOverEl="closeShow"
                        :studentInfo="movestudentInfo"></studentInfoPopUp>
      <div>
        <dialog-factory ref="courseArrangementDialogFactory"
                        :key="'courseArrangement'"
                        :routerName="'courseArrangement'"></dialog-factory>

        <dialog-factory-common ref="courseArrangementDialogFactoryCommon"></dialog-factory-common>
      </div>
    </div>
  </div>

</template>
<script>
import dialogFactory from '../../common/dialog-factory';
import courseList from './course-list';
import gradeClassItem from './components/grade-class-item';
import studentInfoPopUp from './course-rules-list/student-info-popup';
import { SearchStructedRule, GetStructuredRuleClassType, GetStructuredGradeClassByClassType } from '../../../API/workbench';
export default {
  name: 'courseArrangement',
  data () {
    return {
      isTemporaryStu: false,
      searchText: '',
      addNewCourseBtn: {
        id: '2',
        name: '新建课表',
        ModulePowerKey: 47,
        isShow: true
      },
      GradeClassKey: 0,
      CourseNameKey: 0,
      structedRuleData: [],      // 规律课
      courseRuleList: [],
      copyData: {
        StructuredRuleKey: 0,
        copyStudentList: []
      },
      seletedStructuredRuleKey: 0, // 更新的循环规律id
      gradeList: [],
      seletedCourseInfo: {},  // 选中规律课信息
      showPopUp: false,
      isMouseOnPopUp: false, // 判断是否移入了弹出层.
      studentInfo: {},    // 点击选中学生
      movestudentInfo: {},   // 移入选中学生
      isSecedeLoop: false,   // 是否退出循环课弹出层
      positionLeft: 0,
      positionBottom: 0,
      pupupPositionLeft: 0,
      pupupPositionTop: 0,
      timer: null
    };
  },
  components: {
    dialogFactory, courseList, gradeClassItem, studentInfoPopUp
  },
  watch: {
    searchText: {
      handler (c, o) {
        console.log(c, 'watch');
      }
    }
  },
  computed: {
    // 循环课列表
    vCourseRuleList () {
      let newArr = [];
      this.courseRuleList.forEach(o => {
        if (this.searchText != '') {
          if (o.CourseNameKeyValue.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1) {
            let locaIndex = newArr.findIndex(p => {
              return p.FatherKey == o.FatherKey;
            });
            if (locaIndex >= 0) {
              newArr[locaIndex].childList.push(o);
            } else {
              let obj = this.$utils.parseJson(o);
              obj.childList = [];
              obj.childList.push(o);
              newArr.push(obj);
            }
          }
        } else {
          let locaIndex = newArr.findIndex(p => {
            return p.FatherKey == o.FatherKey;
          });
          if (locaIndex >= 0) {
            newArr[locaIndex].childList.push(o);
          } else {
            let obj = this.$utils.parseJson(o);
            obj.childList = [];
            obj.childList.push(o);
            newArr.push(obj);
          }
        }
      });
      console.log('vCourseRuleList', newArr);
      return newArr;
    },
    // 课循环班级列表
    vgradeList () {
      return this.gradeList;
    }
  },
  created () {

  },
  mounted () {
    console.log('this.$route.name', this.$route.name);
    this.getCourseRuleList(() => {
      if (this.courseRuleList.length > 0) {
        this.seletedItem(this.courseRuleList[0], (data) => {
          this.gradeList = data;
          if (this.gradeList.length > 0) {
            this.gradeList.forEach(o => {
              this.$set(o, 'isshowDetail', false);
              this.$set(o, 'rulesList', []);
            });
            this.$nextTick(o => {
              this.$refs.gradeClassList.forEach(o => {
                o.initshowDetail(this.gradeList[0]);
              });
            });
          }
        });
      }
    });

    this.resgisterBusEvent();
  },
  methods: {
    keepShow () {
      clearTimeout(this.timer);
      this.isMouseOnPopUp = true;
      this.showPopUp = true;
    },
    closeShow () {
      this.isMouseOnPopUp = false;
      this.showPopUp = false;
      this.movestudentInfo = {};
    },
    clearTimeOutAfter () {
      //   console.log("clearTimeoutAfter")
      this.isMouseOnPopUp = false;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (!this.isMouseOnPopUp) {
          this.showPopUp = false;
        } else {
          this.showPopUp = true;
        }
      }, 300);
    },
    // 移入学生设置位置偏移量
    setPopUpPositionAfter (el, item, left, top, parentElOffsetTop, parentparentElTop) {
      // if (this.isShowDialog && this.studentInfo.StudentKey != item.StudentKey) {
      //   return;
      // }
      this.$emit('setPopUpPositionAfter', el, item, left, top, parentElOffsetTop);
      //   console.log('setPopUpPositionAfter2222', item,parentElOffsetTop);
      //   console.log('setPopUpPositionAfter3333', left, top,parentElOffsetTop, el.offsetX, this.$el.querySelector('.course_list_right').offsetLeft);
      if (this.isSecedeLoop) {
        return false;
      }
      this.showPopUp = false;
      this.movestudentInfo = item;
      this.isMouseOnPopUp = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.showPopUp = true;
        this.$nextTick(o => {
          if (this.$refs.studentInfoPopUp) {
            let scrollTop = this.$el.querySelector('.course_ul').scrollTop;
            let studentInfoPopUpHeight = this.$refs.studentInfoPopUp.$el.offsetHeight;      // 获取弹出层可视高度.
            let left = document.getElementsByClassName('course_arrange_manage_right')[0].offsetLeft;
            this.positionLeft = el.clientX - el.offsetX;// - el.offsetX - this.$el.querySelector('.course_list_right').offsetLeft - left;  // 30是边距.
            this.positionBottom = parentparentElTop + parentElOffsetTop + top - scrollTop - studentInfoPopUpHeight + 65;   // 195为弹出层加自身选择块的高度,.
          }
        });
        // console.log('student_list_popup', this.$refs.studentInfoPopUp.$el.getBoundingClientRect());
        clearTimeout(this.timer);
      }, 300);
    },
    seletedItem (item, callback) {
      this.courseRuleList.forEach(o => {
        if (o.CourseNameKey == item.CourseNameKey) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      this.seletedCourseInfo = item;
      this.getGradeClassList(this.seletedCourseInfo.CourseNameKey, callback);
      if (this.$refs.gradeClassList && this.$refs.gradeClassList.length > 0) {
        this.$refs.gradeClassList.forEach(o => {
          o.seletedStudentInfo = null;
        });
      }
      console.log(this.$refs.gradeClassList, 'this.$refs.gradeClassList');
      console.log(item, 'seletedItem');
    },
    updateCourseData () {
      this.getGradeClassList(this.seletedCourseInfo.CourseNameKey);
    },

    // 获取课规律信息列表
    getCourseRuleList (callBack) {
      GetStructuredRuleClassType().then(result => {
        console.log('getCourseRuleList', result.data);
        this.courseRuleList = result.data;
        this.courseRuleList.forEach(o => {
          if (this.seletedCourseInfo && this.seletedCourseInfo.CourseNameKey > 0 && o.CourseNameKey == this.seletedCourseInfo.CourseNameKey) {
            this.$set(o, 'isActive', true);
          } else {
            this.$set(o, 'isActive', false);
          }
        });
        if (callBack) {
          callBack();
        } else {

        }
      }).catch(error => {
        console.log('errorGetCourseRuleList', error);
      });
    },
    // 根据课I的获取班级规律课块列表
    getGradeClassList (ClassTypeKey, callback) {
      GetStructuredGradeClassByClassType(ClassTypeKey).then(result => {
        console.log('getGradeClassList', result.data);
        console.log('callback', callback);
        if (callback) {
          callback(result.data);
        } else {
          this.gradeList = result.data;
          this.gradeList.forEach(o => {
            this.$set(o, 'isshowDetail', false);
            this.$set(o, 'rulesList', []);
          });
        }
      }).catch(error => {

      });
    },

    // 更改是否离校学生状态.
    changeStatus (val) {
      this.$store.commit('setIsTemporaryStu', this.isTemporaryStu);
      console.log(this.isTemporaryStu, 'changeStatus', val);
    },
    // 查找
    search () {
      console.log(this.searchText, 'search');
    },
    // 清空搜索条件并查找
    clearSearch () {
      this.searchText = '';
    },
    // charCodeAt排序
    charCodeAtSort (val) {
      let num = 0;
      for (var i = 0; i < val.length; i++) {
        num += Number(val[i].charCodeAt());
      }
      return num;
    },
    // 新建课表
    doNewCrouse (item) {
      this.openCustomDialogByFactoryDialog({ moduleName: 'newCircleCourseTable' });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.courseArrangementDialogFactory) {
        this.$refs.courseArrangementDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.courseArrangementDialogFactoryCommon) {
        console.log('courseArrangementDialogFactoryCommon');
        this.$refs.courseArrangementDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    busUpdateStructedRuleData (arg2) {
      console.log(arg2, 'busUpdateStructedRuleData1111');
      if (this.$route.name != 'newCourseArrangement') {
        return false;
      }
      arg2.GradeClassKey = arg2.GradeClassKey || arg2.classGradeId;
      //   // 更新指定的学生列表.
      //   this.seletedStructuredRuleKey = Number(arg2.StructuredRuleKey) || 0;
      if (this.seletedCourseInfo && this.seletedCourseInfo.CourseNameKey > 0) {
        this.getCourseRuleList();
        this.getGradeClassList(this.seletedCourseInfo.CourseNameKey, (dataList) => {
          let locaIndex = dataList.findIndex(o => {
            return o.GradeClassKey == arg2.GradeClassKey;
          });
          let oldlocaIndex = this.gradeList.findIndex(o => {
            return o.GradeClassKey == arg2.GradeClassKey;
          });
          console.log(locaIndex, 'oldlocaIndex', oldlocaIndex);
          if (locaIndex >= 0 && oldlocaIndex >= 0) {
            // 修改课之类
            this.$set(dataList[locaIndex], 'isshowDetail', this.gradeList[locaIndex].isshowDetail);
            this.$set(dataList[locaIndex], 'rulesList', this.gradeList[locaIndex].rulesList);
            this.gradeList.splice(locaIndex, 1, dataList[locaIndex]);

            this.$refs.gradeClassList.forEach(o => {
              o.updateDataForParent(arg2);
            });
          } else if (locaIndex >= 0) {
            // 新建课
            this.$set(dataList[locaIndex], 'isshowDetail', false);
            this.$set(dataList[locaIndex], 'rulesList', []);
            this.gradeList.push(dataList[locaIndex]);
          }
        });
      } else {
        this.getCourseRuleList(() => {
          if (this.courseRuleList.length > 0) {
            this.seletedItem(this.courseRuleList[0]);
          }
        });
      }
    },
    resgisterBusEvent () {
      if (this.$route.name == 'newCourseArrangement') {
        // 新建课表
        this.$bus.on(this, 'AfterNewCourseTable', (type, obj) => {
          console.log('bus-AfterNewCourseTable', obj);

          this.busUpdateStructedRuleData(obj);
        });
        // 修改课表
        this.$bus.on(this, 'AfterEditCircleCourse', (ruleID, obj) => {
          console.log('bus-AfterEditCircleCourse');
          this.busUpdateStructedRuleData(obj);
        });

        // 批量删除排课计划
        this.$bus.on(this, 'AfterBatchDeleteStructuredPlanList', (courseID, classGradeID, planList) => {
          this.busUpdateStructedRuleData({ CourseNameKey: courseID, GradeClassKey: classGradeID });
        });
        // 退出循环课 AfterAbortCircleCorse
        this.$bus.on(this, 'AfterAbortCircleCorse', (ruleID, obj) => {
          console.log('bus-AfterAbortCircleCorse');
          this.busUpdateStructedRuleData(obj);
        });
        // 学生退出加入循环课操作
        this.$bus.on(this, 'AfterCancelApplyClassCourseByRule', (ruleID, obj) => {
          console.log('bus-AfterCancelApplyClassCourseByRule');
          this.busUpdateStructedRuleData(obj);
        });
        // 新建循环课成功后 批量修改排课计划
        this.$bus.on(this, 'AfterBatchEditStructuredPlanList', (courseID, classGradeID, planList) => {
          let obj = {
            GradeClassKey: classGradeID,
            CourseNameKey: courseID
          };
          console.log(obj, 'obj');
          this.busUpdateStructedRuleData(obj);
        });
        this.$bus.on(this, 'afterQuitOrAddClass', (classID, studentID) => {
          console.log('afterQuitOrAddClass');
          let obj = {
            GradeClassKey: classID,
            CourseNameKey: 0
          };
          this.busUpdateStructedRuleData(obj);
        });
      }
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'newCourseArrangement') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'newCourseArrangement') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      // 切换路由为本路由时触发.
      this.$bus.on(this, 'switchHeaderBarToNewCourseArrangement', () => {
        this.getCourseRuleList();
        this.gradeList = [];
      });
    }
  }
};
</script>

<style>
.upsell_management_view {
  /* width: 1366px; */
  margin: 0 auto;
}

.upsell_management_top {
  display: flex;
  height: 181px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
}

.upsell_management_top_list {
  flex: 1;
  position: relative;
  cursor: pointer;
}

.upsell_management_top_list::after {
  content: '';
  position: absolute;
  right: -1px;
  top: 61px;
  height: 95px;
  width: 1px;
  background: #ececec;
}

.upsell_management_top_list .list_date {
  margin-left: 19px;
  margin-top: 13px;
  font-size: 14px;
  line-height: 19px;
  color: #333;
  padding-left: 17px;
  position: relative;
  margin-bottom: 21px;
}

.upsell_management_top_list .list_date::after {
  content: '';
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  left: 0;
  top: 6px;
  background-color: #feaa35;
}

.upsell_management_top_list .list_content {
  text-align: center;
  position: relative;
}

.upsell_management_top_list .list_content::after {
  content: '';
  position: absolute;
  right: 82px;
  top: 22px;
  width: 14px;
  height: 14px;
  background: url(../../../../public/image/next_icon@2x.png) no-repeat center
    center;
  background-size: 8px;
}

.upsell_management_top_list .list_content .font_number {
  font-size: 30px;
  line-height: 34px;
  margin-bottom: 6px;
}

.upsell_management_top_list .list_content .font_gray {
  font-size: 14px;
  line-height: 19px;
  color: #999;
}

.upsell_management_top_list .list_bottom {
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
}

.upsell_management_top_list .list_bottom_number {
  font-size: 20px;
  line-height: 24px;
}

.upsell_management_top_list .list_bottom_number .font_gray {
  font-size: 14px;
  color: #999;
  margin-right: 5px;
}

.upsell_management_center {
  background-color: #fff;
  height: 223px;
  border-radius: 8px;
  margin-top: 20px;
}

.upsell_management_title {
  font-size: 18px;
  line-height: 60px;
  border-bottom: 1px solid #ececec;
  padding-left: 20px;
}

.upsell_management_center_content {
  display: flex;
  padding: 0 10px;
}

.upsell_management_center_list {
  flex: 1;
  padding: 0 10px;
}

.upsell_management_center_list .list_top {
  display: flex;
  padding-top: 20px;
}

.upsell_management_center_list .list_top_info {
  margin-left: 20px;
}

.upsell_management_center_list .list_top_name {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 8px;
  position: relative;
}

.upsell_management_center_list .list_top_name::after {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  right: -40px;
  background: url(../../../../public/image/arrow_right_icon_black@2x.png)
    no-repeat;
  background-size: 8px;
}

.upsell_management_center_list .list_top_number {
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
}

.upsell_management_center_list .list_top_number.font_yellow {
  color: #ff8723;
}

.upsell_management_center_list .list_top_number.font_red {
  color: #ff6c67;
}

.upsell_management_center_list .list_bottom {
  height: 71px;
  margin-top: 16px;
  display: flex;
  background: rgba(231, 234, 239, 0.35);
  border-radius: 4px;
  padding-top: 12px;
  justify-content: space-around;
}

.upsell_management_center_list .list_bottom_list {
  text-align: center;
}

.upsell_management_center_list .list_bottom_number {
  font-size: 18px;
  line-height: 23px;
  height: 28px;
}

.upsell_management_center_list .list_bottom_text {
  font-size: 14px;
  line-height: 19px;
  color: #999;
}

.course_arrange_manage_view {
  width: 1366px;
  margin: 0 auto;
}

.course_arrange_manage_box {
  display: flex;
  height: 821px;
  border-radius: 8px;
  background: #ffffff;
}

.course_arrange_manage_left {
  width: 356px;
  min-width: 356px;
  border-radius: 8px 0px 0px 8px;
  box-shadow: 4px 0px 10px 2px rgb(0, 0, 0, 0.06);
}

.course_arrange_manage_title {
  display: flex;
  justify-content: space-between;
  line-height: 68px;
  padding: 0 16px;
}

.course_arrange_manage_title .title_name {
  font-size: 18px;
}

.course_arrange_manage_title .title_btn {
  color: #3498dc;
  position: relative;
  padding-left: 15px;
  cursor: pointer;
}

.course_arrange_manage_title .title_btn::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 2px;
  left: 0;
  top: 33px;
  background-color: #3498dc;
}

.course_arrange_manage_title .title_btn::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 10px;
  left: 4px;
  top: 29px;
  background-color: #3498dc;
}

.course_arrange_manage_search {
  width: 324px;
  height: 32px;
  margin: 0 auto 20px;
}

.course_arrange_manage_search input {
  width: 100%;
  height: 100%;
  border: 1px solid #ececec;
  border-radius: 4px;
  padding-left: 30px;
  background: url(../../../../public/image/search_icon01.png) no-repeat 11px
    center;
  background-size: 14px;
}

.course_arrange_manage_ul {
  height: 646px;
  overflow-y: auto;
}

.course_arrange_manage_ul .list_block .box_title {
  line-height: 36px;
  padding-left: 16px;
  color: #666;
  background-color: #fafafa;
}

.course_arrange_manage_list {
  height: 80px;
  display: flex;
  padding-top: 18px;
  position: relative;
}

.course_arrange_manage_list.opt,
.course_arrange_manage_list.opt .list_number,
.course_arrange_manage_list.opt .list_text {
  color: #3498dc;
  background-color: #eaf4fb;
}

.course_arrange_manage_list::before {
  content: '';
  position: absolute;
  left: 46px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #ececec;
}

.course_arrange_manage_list::after {
  content: '';
  position: absolute;
  left: 42px;
  top: 24px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #666666;
  box-sizing: border-box;
}

.course_arrange_manage_list.opt::after {
  border-color: #3498db;
  background-color: #3498db;
}

.course_arrange_manage_list .list_number {
  width: 46px;
  font-size: 14px;
  line-height: 20px;
  color: #666;
  text-align: center;
}

.course_arrange_manage_list .list_info {
  padding-left: 23px;
}

.course_arrange_manage_list .list_name {
  font-size: 16px;
  line-height: 20px;
}

.course_arrange_manage_list .list_text {
  font-size: 14px;
  line-height: 19px;
  color: #999;
  margin-top: 2px;
}

.course_arrange_manage_right {
  position: relative;
  flex: 1;
}

.course_arrange_manage_right .course_ul {
  overflow-y: auto;
  height: 761px;
}
.course_ul_box {
  overflow: hidden;
  height: 761px;
  /* padding: 30px 20px; */
}
.course_arrange_manage_right .course_ul {
  padding: 30px 20px;
}
.course_arrange_manage_title .right_tips {
  position: absolute;
  width: 14px;
  height: 14px;
  left: 94px;
  top: 27px;
  border-radius: 50%;
  background: #b2b4b8 url(../../../../public/image/header_tips_icon.png);
  background-size: 14px;
}

.course_arrange_manage_right .course_list {
  margin-bottom: 37px;
}

.course_arrange_manage_right .course_list_content {
  display: flex;
  min-height: 133px;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  z-index: 1;
}

.course_arrange_manage_right .course_list_left {
  width: 244px;
  min-width: 244px;
  padding-left: 20px;
  padding-top: 12px;
}

.course_arrange_manage_right .course_list_name {
  font-size: 18px;
  line-height: 30px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 23px;
}

.course_arrange_manage_right .course_list_left_btn {
  font-size: 14px;
  line-height: 18px;
  /* width: 108px; */
  width: 80%;
  color: #666;
  display: flex;
  justify-content: space-between;
}

.course_arrange_manage_right .course_list_left_btn .font_blue {
  cursor: pointer;
}

.course_arrange_manage_right .course_list_btn {
  position: absolute;
  width: 52px;
  height: 24px;
  border: 1px solid #ececec;
  border-radius: 12px;
  left: 50%;
  margin-left: -26px;
  bottom: 13px;
  background: url(../../../../public/image/arrow__bottom_icon_black@2x.png)
    no-repeat center center;
  background-size: 10px;
  cursor: pointer;
}

.course_arrange_manage_right .course_list_btn.open_type {
  transform: rotate(180deg);
}

.course_arrange_manage_right .course_list_right {
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px;
  padding-bottom: 40px;
}

.course_arrange_manage_right .course_list_right .list_student {
  width: 96px;
  height: 30px;
  line-height: 28px;
  background: #f9fbfd;
  border: 1px solid #e8ebef;
  border-radius: 4px;
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}
.course_arrange_manage_right .course_list_right .list_student.opt {
  background-color: #3498db;
  color: #fff;
  border-color: #3498db;
}
.course_arrange_manage_right
  .course_list_right
  .list_student.type_warning::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  right: 0;
  top: 0;
  border: 5px solid #e7534e;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-top-right-radius: 3px;
}

/* .course_arrange_manage_right .course_list_right .list_student:nth-child(6n) {
			margin-right: 0;
		} */

.course_arrange_manage_right .course_list_right .list_student_name {
  padding-left: 10px;
  width: 72px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.course_arrange_manage_right .course_list_right .list_student_number {
  line-height: 19px;
  height: 19px;
  margin-top: 4px;
  flex: 1;
  text-align: center;
  border-left: 1px solid #e8ebef;
}

.course_arrange_manage_right .course_list_right .list_add {
  width: 98px;
  height: 30px;
  line-height: 28px;
  background: #f9fbfd;
  border: 1px solid #e8ebef;
  border-radius: 4px;
  padding-left: 43px;
  color: #3498db;
  position: relative;
}

.course_arrange_manage_right .course_list_right .list_add::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 2px;
  border-radius: 2px;
  background-color: #3498db;
  left: 28px;
  top: 13px;
}

.course_arrange_manage_right .course_list_right .list_add::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 10px;
  border-radius: 2px;
  background-color: #3498db;
  left: 32px;
  top: 9px;
}

.course_arrange_manage_right .course_list_right .list_add.norole::before {
  background-color: #ccc;
}

.course_arrange_manage_right .course_list_right .list_add.norole::after {
  background-color: #ccc;
}

.course_arrange_manage_right .course_list_right .list_add.no_bg {
  padding-left: 22px;
  height: 30px;
  margin-left: 10px;
}

.course_arrange_manage_right .course_list_right .list_add.no_bg::before {
  display: none;
}

.course_arrange_manage_right .course_list_right .list_add.no_bg::after {
  display: none;
}
.course_arrange_manage_right .course_list_box {
  background-color: #fafafa;
  border: 1px solid #ececec;
  margin-top: -8px;
  padding-top: 8px;
}

.course_arrange_manage_right .course_list_item {
  border-bottom: 1px solid #ececec;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  padding-left: 244px;
}

.course_arrange_manage_right .course_list_item:last-child {
  border-bottom: none;
}

.course_arrange_manage_right .course_list_info {
  width: 244px;
  min-width: 244px;
  padding-left: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  left: 0;
  align-content: center;
  align-items: center;
}

.course_arrange_manage_right .course_list_item .course_list_right {
  padding-bottom: 14px;
  padding-top: 14px;
}
.course_list_left_btn .list_btn {
  width: 42px;
  height: 32px;
  position: relative;
  top: -6px;
  right: -18px;
  background: url(../../../../public/image/courseware_menu_btn_white.png);
}
.course_arrange_manage_right .course_list_info_top::after {
  content: '';
  width: 30px;
  height: 30px;
  position: absolute;
  top: -5px;
  display: block;
  right: -12px;
  cursor: pointer;
  background: url(../../../../public/image/menuBtn_gray.png) no-repeat center
    center;
  background-size: 9px;
}
.course_arrange_manage_right .course_list_info_top {
  font-size: 16px;
  line-height: 20px;
  width: 85%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.course_arrange_manage_right .course_list_info_bottom {
  font-size: 14px;
  line-height: 16px;
  color: #999;
}
.right_tips .header_tips_icon {
  background: none;
}
.course_list_right .draggable {
  display: flex;
  /* width: 645px; */
  flex-wrap: wrap;
}
</style>
