<template>
  <div class="student_file_course">
    <div style="padding-bottom: 10px;">
      <debt-course-item v-for="debtItem in debyDataList"
                        :key="debtItem.CourseNameKey"
                        :item="debtItem"
                        @seletedItem="seletedDebtItemAfter"></debt-course-item>
    </div>

    <course-bill-item v-for="(item,key) in dataList"
                      :key="key"
                      :item="item"
                      @seletedItem="seletedItemAfter" @doAttenCourseSpendDetails="doAttenCourseSpendDetails"></course-bill-item>
    <basics-nodata-tips v-if="dataList.length==0"></basics-nodata-tips>
    <div v-else
         class="no_list_text">已显示全部</div>
  </div>
</template>

<script>
import courseBillItem from './course-bill-item.vue';
import debtCourseItem from './debt-course-item.vue';
import { SelectStudentXYKD, DebtClassHourAllCourseListByStu } from '../../../../../API/workbench';
export default {
  data () {
    return {
      dataList: [],
      scrollTop: 0,
      debyDataList: []
    };
  },
  props: {
    studentInfo: Object
  },
  components: {
    courseBillItem, debtCourseItem
  },
  created () {
    console.log(this.studentInfo, 'createdclass-hour-spend');
    this.initGetData()
  },
  watch:{
    'studentInfo.OLAPKey':{
      handler(c,o){
        if(c>0){
          console.log('watchstudentInfo.OLAPKey')
            this.initGetData()
        }
      },
      deep:true
    }
  },
  methods: {
    initGetData(){
      console.log("initGetData")
      this.getDataList();
    this.getDebtDataList();
    },
    getDataList () {
      SelectStudentXYKD(this.studentInfo.OLAPKey).then(result => {
        // console.log('getDataList', result.data);
        this.dataList = result.data;
      }, error => {
        layer.alert(error.msg);
      });
    },
    getDebtDataList () {
      DebtClassHourAllCourseListByStu(this.studentInfo.OLAPKey).then(result => {
        console.log('getDebtDataList', result.data);
        this.debyDataList = result.data;
      }, error => {
        layer.alert(error.msg);
      });
    },
    seletedDebtItemAfter (item) {
      this.$dialog.open(this, {
        name: '欠课时详情',
        routerName: this.$route.name,
        moduleName: 'debtClassCountDetail',
        ModulePowerKey: '',
        data: { dataInfo: { studentKey: this.studentInfo.OLAPKey, CourseNameKey: item.CourseNameKey, CourseNameKeyValue: item.MainDemoName, DebtCount: item.DebtCount } },
        callBack: { afterSucces: () => { 
          this.initGetData();
         } }
      });
    },
    seletedItemAfter (item) {
      this.$dialog.open(this, {
        name: '报名收款',
        routerName: this.$route.name,
        moduleName: 'signuppayment',
        moduleDescription: '学员报名购买课程，如未登记的学生可点界面“+”新增学生信息后再进行报名。',
        data: { dataInfo: { StudentKey: this.studentInfo.OLAPKey, TableID: item.TableID } },
        callBack: { afterSucces: () => { this.initGetData(); } }
      });
    },
    doAttenCourseSpendDetails (item) {
      this.$dialog.open(this, {
        name: '考勤课消详情',
        routerName: this.$route.name,
        moduleName: 'attenCourseSpendDetails',
        ModulePowerKey: '',
        data: { dataInfo: { TableID: item.TableID, SourceBillKey: item.SourceBillKey, RN: item.RN, StudentKey: this.studentInfo.OLAPKey} },
        callBack: { afterSucces: () => { this.initGetData() } }
      });
    }
  }
};
</script>

<style scoped>
.student_file_course {
  padding-top: 5px;
  max-height: 600px;
  overflow-y: auto;
  background-color: #f8f8f8;
}
</style>
