                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             <template>
  <div class="franchiseeManagement">
    <headSummarizing :summaryData="summaryData"
                     :params="params"
                     @loadTableData="loadAfterTableData"></headSummarizing>
    <list ref="campusManagement"
          class="summarizing_list"
          :tableData="campusmdList"
          :expandRowData="expandRowData"
          :tableColumns="tableColumns"
          :totalNum="totalNum"
          :rowKey="'Number'"
          :params="params"
          :defaultSort="defaultSort"
          @addCampus="addCampus"
          @loadTableData="loadAfterTableData"
          @flashData='getSearchPageForFranchisee'
          @downBoxClick="doAuthorizeCoursewareBtn"></list>

    <custom-dialog :title="titleName+'加盟校区'"
                   :visible.sync="isShowParticipateCampusFormPopup"
                   :before-close="closeFormPopup">
      <franchisee-campus-edit-form :CampusKey="Number(dataInfo.OLAPKey)"
                                   :isShowInput="true"
                                   :titleName="titleName"
                                   :isReadonly="titleName=='查看'?true:false"
                                   @close="closeFormPopup"
                                   @afterSuccess="getSearchPageForFranchisee(1)"></franchisee-campus-edit-form>
    </custom-dialog>

    <custom-dialog :title="'收款'"
                   :visible.sync="isShowPaymentForm"
                   :before-close="closeFormPopup">
      <payment-form :campusInfo="dataInfo"
                    @close="closeFormPopup"
                    @afterSuccess="getSearchPageForFranchisee"></payment-form>
    </custom-dialog>

    <custom-dialog :title="titleName"
                   :visible.sync="isShowCoursewareFormPopup"
                   :before-close="closeFormPopup">
      <authorization-courseware-form :coursewareId="Number(coursewareInfo.OLAPKey)"
                                     :campusID="Number(dataInfo.SaaSClientNO)"
                                     :isReadonly="false"
                                     @close="closeFormPopup"
                                     @afterSuccess="afterSuccessUpExpandRowData"></authorization-courseware-form>
    </custom-dialog>
    <div>
      <dialog-factory ref="franchiseeManagementDialogFactory"
                      :routerName="'franchiseeManagement'"></dialog-factory>
      <dialog-factory-common ref="dialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>

<script>
import {
  SearchPageForFranchisee,
  FranchiseeSearchAuthorizeCourseware,
  AuthorizationCoursewareEnable,
  AuthorizationCoursewareDisable,
  GetFranchiseeStatisticalData
} from '../../../../API/workbench.js';
import headSummarizing from './head-summarizing';
import franchiseeCampusEditForm from './form/franchisee-campus-edit-form';
import paymentForm from './form/payment-form';
import authorizationCoursewareForm from '../authorization-courseware/form/authorization-courseware-form';
import list from './table';
import dialogFactory from '../../../common/dialog-factory';
export default {
  name: 'franchiseeManagement',
  components: {
    headSummarizing,
    list,
    franchiseeCampusEditForm,
    paymentForm,
    authorizationCoursewareForm,
    dialogFactory
  },
  data () {
    return {
      isShowParticipateCampusFormPopup: false,
      isShowPaymentForm: false,
      // 加盟管理
      params: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: '', //	字符串	可选		数据源：GET	排序顺序
        StartTime: '', //	字符串	可选		数据源：GET	起始日期
        EndTime: '', // 字符串	可选		数据源：GET	终止日期
        TypeKey: '', // 整型	可选		数据源：GET	加盟类型3-课件加盟;4-整店加盟
        IsExpire: '', // 整型	可选		数据源：GET	空表示条件不生效;1-过期,0-正常
        IsRenew: '', //	整型	可选		数据源：GET	空表示条件不生效;1-查找要续费的课件
        IsWarning: '', // 整型	可选		数据源：GET	空表示条件不生效;1-课件排课警戒
        IsAbort: '', // 终止 1-0,
        HeadTypeKey: 1 // 1-使用中;2-校区续费预警;3-校区已停用;4-教务端口到期;5-教务端口到期预警;6-课件端口到期;7-课件端口到期预警
      },
      summaryData: null, // 汇总数据
      campusmdList: [], // 加盟列表
      isShowCoursewareFormPopup: false,
      coursewareInfo: {},
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '系统\n开户日期',
          width: 100,
          prop: 'OpenTime',
          type: 'date-item',
          sortable: 'custom',
          columnClass: 'font_dual'
        },
        {
          label: '校区名称',
          width: 140,
          prop: 'MainDemoName',
          type: 'md-name',
          sortable: false,
          extend: {
            mendianID: 'SaaSClientNO',
            click: (rowData) => {
              this.viewClick(rowData);
            }
          }
        },
        {
          label: '管理员',
          prop: 'AdminName',
          width: 80,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '管理员手机号',
          prop: 'DefaultMobile',
          width: 110,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '授权\n课包数',
          width: 80,
          prop: 'AuthorizeCoursewareCount',
          type: 'class-hour',
          columnClass: 'font_dual',
          extend: {
            type: 'expand',
            click: (rowData) => {
            },
            isLoading: true, // 正在加载中
            tableWidth: '1220px',
            left: '100px',
            isShowOweMark: true,
            isStringClick: true,
            // downBoxText: `<div class="authorization_courseware_btn">授权课件</div>`,
            loadtableData: (rowData, callBack) => {
              // 获取详情数据
              this.getAuthorizeCoursewareCountDetial(rowData, callBack);
            },
            tableColumnsDetail: [
              {
                label: '课件授权日期',
                prop: 'AuthorizeTime',
                type: 'date-item',
                width: 110,
                extend: {
                  dateType: 'YYMMRR HH:DD' // 日期类型
                }
              },
              {
                label: '经办人',
                prop: 'OperatorKeyValue',
                width: 100,
                type: 'text-item'
              },
              {
                label: '课件包名称',
                prop: 'parentCoursewarePackageKeyValue',
                type: 'text-item'
              },
              {
                label: '结算方式',
                prop: 'ClearingFormKeyValue',
                width: 90,
                type: 'text-item'
              },
              {
                label: '发放',
                prop: 'ReleasedNum',
                width: 70,
                type: 'number-item'
              },
              {
                label: '使用',
                prop: 'UsageNum',
                width: 70,
                type: 'number-item'
              },
              {
                label: '剩余',
                prop: 'residue',
                width: 70,
                type: 'number-item'
              },
              {
                label: '排课班次',
                prop: 'ArrangeGradeClassNum',
                width: 80,
                type: 'class-hour'
              },
              {
                label: '排课课次',
                prop: 'ArrangeNum',
                width: 80,
                type: 'class-hour'
              },
              {
                label: '排课警戒',
                prop: 'ArrangedWarningValue',
                width: 80,
                type: 'text-item'
              },
              {
                label: '状态',
                prop: 'StatusKeyValue',
                width: 80,
                align: 'center',
                type: 'text-item',
                extend: {
                  setClassName: (rowData) => {
                    // 1：启用 0：停用 2：已用完 3：已过期
                    if (rowData.StatusKey == 0) {
                      return 'status_spot_red';
                    } else if (rowData.StatusKey == 1) {
                      return 'status_spot_green';
                    } else {
                      return 'status_spot_gray';
                    }
                  }
                }
              },
              {
                label: '定位授权',
                prop: 'IsLocationLockKeyValue',
                width: 90,
                type: 'text-item',
                sortable: false
              },
              {
                label: '操作',
                prop: 'option',
                type: 'table-btn',
                width: 140,
                extend: {
                  tableBtnGroup: [
                    {
                      name: (rowData) => {
                        return rowData.StatusKey == 0 ? '恢复授权' : '暂停授权';
                      },
                      extend: {
                        isHide: (rowData) => {
                          return rowData.SysStatusKey == 1;
                        },
                        IsInquiryHint: 'inquiryBtn',
                        setClassName: (rowData) => {
                          if (rowData.inquiryBtn) {
                            // 开启询问提示，显示询问弹窗时，界面按钮置灰，不可操作
                            return rowData.inquiryBtn;
                          }
                        },
                        click: (rowData, callBack) => {
                          if (callBack) {
                            callBack({
                              IsInquiryHint: 'inquiryBtn',
                              InquiryClassName: 'table_btn_gray'
                            });
                          }
                          this.switchClick(rowData, callBack);
                        },
                        ModulePowerKey: ''
                      }
                    },
                    {
                      name: '续费',
                      extend: {
                        isHide: (rowData) => {
                          return rowData.StatusKey == 0;
                        },
                        click: (rowData) => {
                          this.renewClick(rowData);
                        },
                        ModulePowerKey: ''
                      }
                    }
                  ]
                }
              }
            ]
          }
        },
        {
          label: '近30天\n上课课次',
          width: 90,
          prop: 'CourseArrang30Count',
          type: 'text-item',
          columnClass: 'font_dual'
        },
        // {
        //   label: '系统\n续费日期',
        //   width: 100,
        //   prop: 'ExpiredTimeText',
        //   type: 'text-item',
        //   sortable: 'custom',
        //   columnClass: 'font_dual'
        // },
        {
          label: '加盟日期',
          width: 100,
          prop: 'LeagueTime',
          type: 'date-item',
          sortable: 'custom'
        },
        {
          label: '招商顾问',
          prop: 'CounselorKeyValue',
          width: 90,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },

        {
          label: '经办人',
          prop: 'OperatorKeyValue',
          width: 90,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '本月报名金额',
          prop: 'ThisMonthApplyAmount',
          width: 120,
          type: 'text-item'
        },
        {
          label: '本月课消金额',
          prop: 'ThisMonthClassAmount',
          width: 120,
          type: 'text-item'
        },
        {
          label: '收款余额',
          prop: 'ResidueAmount',
          width: 100,
          type: 'text-item'
        },
        {
          label: '剩余课时数',
          prop: 'ClassHourCount',
          width: 120,
          type: 'text-item'
        },
        {
          label: '本月出勤率',
          prop: 'ThisMonthSignRate',
          width: 120,
          type: 'text-item'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'hidden-btn',
          width: 280,
          align: 'left',
          extend: {
            tableBtnGroup: [
              {
                name: '修改',
                extend: {
                  isHide: (rowData) => {
                    return false;
                  },
                  click: (rowData) => {
                    this.editClick(rowData);
                  },
                  ModulePowerKey: ''
                }
              },
              {
                name: '收款',
                extend: {
                  isHide: (rowData) => {
                    return false;
                  },
                  click: (rowData) => {
                    this.paymentClick(rowData);
                  },
                  ModulePowerKey: ''
                }
              },
              {
                name: (rowData) => {
                  let atDate = Date.new().getTime();
                  if (rowData.IsOpenAcademicKey == 0) {
                    return `开通教务`;
                  } else if ((Number(rowData.StatusKey) == 0 ||
                    (rowData.ExpiredTime && atDate > Date.new(rowData.ExpiredTime).getTime())) &&
                    rowData.IsOpenAcademicKey == 1) {
                    return `教务续期`;
                  }
                },

                extend: {
                  isHide: (rowData) => {
                    let atDate = Date.new().getTime();
                    if (rowData.IsOpenAcademicKey == 0) {
                      return false;
                    } else if ((Number(rowData.StatusKey) == 0 ||
                      (rowData.ExpiredTime && atDate > Date.new(rowData.ExpiredTime).getTime())) &&
                      rowData.IsOpenAcademicKey == 1) {
                      return false;
                    } else {
                      return true;
                    }
                  },
                  click: (rowData) => {
                    this.academicClick(rowData);
                  },
                  ModulePowerKey: ''
                }
              },

              {
                name: (rowData) => {
                  let atDate = Date.new().getTime();
                  if (rowData.IsOpenChannelKey == 0) {
                    return '开通课件';
                  } else if ((Number(rowData.ChannelStatusKey) == 0 ||
                    (rowData.CoursewareChannelExpiredTime && atDate > Date.new(rowData.CoursewareChannelExpiredTime).getTime())) &&
                    rowData.IsOpenChannelKey == 1) {
                    return '课件续期';
                  }
                },
                extend: {
                  isHide: (rowData) => {
                    let atDate = Date.new().getTime();
                    if (rowData.IsOpenChannelKey == 0) {
                      return false;
                    } else if ((Number(rowData.ChannelStatusKey) == 0 ||
                      (rowData.CoursewareChannelExpiredTime && atDate > Date.new(rowData.CoursewareChannelExpiredTime).getTime())) &&
                      rowData.IsOpenChannelKey == 1) {
                      return false;
                    } else {
                      return true;
                    }
                  },
                  click: (rowData) => {
                    this.coursewareClick(rowData);
                  },
                  ModulePowerKey: ''
                }
              }
            ]
          }
        }
      ],
      PageCount: 0,
      totalNum: 0,
      expandRowData: [],
      defaultSort: { prop: 'studentInfo', order: 'ascending' },
      dataInfo: {},
      titleName: '添加'
    };
  },
  created () {
    this.registerBusEvent();
  },
  activated () {
    this.getSearchPageForFranchisee();
  },
  methods: {
    // 加盟管理-分页查询
    getSearchPageForFranchisee (val) {
      if (val == 1) {
        this.$refs.campusManagement.$refs.tableListRef.expandRowKeys = [];
      }
      SearchPageForFranchisee(this.params).then((result) => {
        this.campusmdList = [];
        this.totalNum = result.data.Total;
        result.data.PageDataList.forEach((o) => {
          if (o.StatusKey == 0) {
            o.ExpiredTimeText = '已暂停';
          } else {
            if (o.ExpiredTime) {
              // 处理系统续费日期
              let days = this.$utils.DateDiffDay(this.$utils.formatDateToLine(Date.new()), o.ExpiredTime.slice(0, 10)) + 1;
              if (days <= 30 && days > 0) {
                o.ExpiredTimeText = o.ExpiredTime.slice(0, 10) + `<div class="font_red">${days}天后过期</div>`;
              } else {
                o.ExpiredTimeText = o.ExpiredTime.slice(0, 10);
              }
            }
          }
          o.AuthorizeCoursewareCount = o.IsOpenChannelKey == 0 ? '-' : (Number(o.AuthorizeCoursewareCount) == 0 ? '--' : Number(o.AuthorizeCoursewareCount));// 是否开通课件门店
          o.CourseArrang30Count = o.IsOpenAcademicKey == 0 ? '-' : Number(o.CourseArrang30Count);// 是否开通教务功能
          o.ThisMonthApplyAmount = o.IsOpenAcademicKey == 0 ? '-' : Number(o.ThisMonthApplyAmount);// 是否开通教务功能
          o.ThisMonthClassAmount = o.IsOpenAcademicKey == 0 ? '-' : Number(o.ThisMonthClassAmount);// 是否开通教务功能
          o.ResidueAmount = o.IsOpenAcademicKey == 0 ? '-' : Number(o.ResidueAmount).toFixed(2);// 是否开通教务功能
          o.ClassHourCount = o.IsOpenAcademicKey == 0 ? '-' : Number(o.ClassHourCount);//
          o.ThisMonthSignRate = o.IsOpenAcademicKey == 0 ? '-' : Number(o.ThisMonthSignRate) <= 0 ? '--' : (Math.round(o.ThisMonthSignRate * 100) + '%');// 本月出勤率
        });
        this.campusmdList = result.data.PageDataList;
        this.getSummaryData();
        console.log(result, '加盟管理-分页查询');
      }, (error) => {
        layer.alert(error.msg);
      }
      );
    },
    getSummaryData () {
      GetFranchiseeStatisticalData().then((result) => {
        console.log(result.data, '加盟管理-汇总信息');
        this.summaryData = result.data;
      }, (error) => {
        layer.alert(error.msg);
      });
    },
    // 条件查询
    loadAfterTableData (queryParams) {
      if (queryParams) {
        Object.assign(this.params, queryParams);
      }
      this.getSearchPageForFranchisee();
    },
    // 新增
    addCampus (type) {
      this.titleName = '添加';
      this.dataInfo = {};
      this.isShowParticipateCampusFormPopup = true;
    },
    // 修改
    editClick (rowData) {
      this.titleName = '修改';
      this.dataInfo = rowData;
      console.log(rowData, '校区管理-修改');
      // if (rowData.TypeKey > 2) {
      // 1-直营参股;2-直营直属;3-课件加盟;4-整店加盟
      this.isShowParticipateCampusFormPopup = true;
      // } else {
      //   this.isShowChainCampusFormPopup = true;
      // }
    },
    // 查看
    viewClick (rowData) {
      this.titleName = '查看';
      this.dataInfo = rowData;
      console.log(rowData, '校区管理-修改');
      // if (rowData.TypeKey > 2) {
      // 1-直营参股;2-直营直属;3-课件加盟;4-整店加盟
      this.isShowParticipateCampusFormPopup = true;
      // } else {
      // this.isShowChainCampusFormPopup = true;
      // }
    },
    // 收款
    paymentClick (rowData) {
      this.dataInfo = rowData;
      this.isShowPaymentForm = true;
    },
    // 教务功能
    academicClick (rowData) {
      if (rowData.IsOpenAcademicKey == 0) {
        this.openForm(rowData, 'jw');
      } else {
        this.renewalForm(rowData, 'jw');
      }
    },
    // 课件功能
    coursewareClick (rowData) {
      if (rowData.IsOpenChannelKey == 0) {
        this.openForm(rowData, 'kj');
      } else {
        this.renewalForm(rowData, 'kj');
      }
    },
    // 续费
    renewalForm (rowData, type) {
      console.log(type, rowData, '点击');
      let NowTime = new Date();
      let ExpiredTime = new Date(rowData.ExpiredTime);
      console.log(ExpiredTime, 'NowTime', NowTime, ExpiredTime > NowTime);
      if (type == 'jw' && ExpiredTime > NowTime) {
        layer.confirm('该门店教务功能自费，不可进行该操作，如有问题请与专属客服联系', {
          btn: [
            {
              name: '确认',
              callBack: () => {
                layer.close();
              }
            }
          ]
        });
      } else {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '续期',
          moduleName: 'renewalForm',
          data: {
            dataInfo: {
              OLAPKey: rowData.OLAPKey,  // 校区ID
              MainDemoName: rowData.MainDemoName,
              IsOpenChannelKey: type == 'kj' ? 1 : 0, // "是否开通课件 0-否;1-是",
              IsOpenAcademicKey: type == 'jw' ? 1 : 0, // "是否开通教务  0-否;1-是",
              ExpireTime: type == 'jw' ? rowData.ExpiredTime : rowData.CoursewareChannelExpiredTime // "是否开通教务  0-否;1-是", // 到期日
            }
          },
          callBack: {
            afterSuccess: (data) => { this.getSearchPageForFranchisee(); }
          }
        });
      }
    },
    // 开通
    openForm (rowData, type) {
      console.log(type, rowData, '点击');
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '开通功能',
        moduleName: 'openOptionsForm',
        data: {
          dataInfo: {
            CampusKey: rowData.OLAPKey,  // 校区ID
            CampusName: rowData.MainDemoName,
            MobilePhone: rowData.MobilePhone,
            IsOpenChannelKey: type == 'kj' ? 1 : 0, // "是否开通课件 0-否;1-是",
            IsOpenAcademicKey: type == 'jw' ? 1 : 0, // "是否开通教务  0-否;1-是",
            ExpireTime: type == 'jw' ? rowData.ExpiredTime : rowData.CoursewareChannelExpiredTime,  // 到期日
            type: rowData.TypeKey // 1-直营店;2-加盟店;3-课程合作
          }
        },
        callBack: {
          afterSuccess: (data) => { this.getSearchPageForFranchisee(); }
        }
      });
    },
    // 更新子列表信息
    afterSuccessUpExpandRowData () {
      this.getSearchPageForFranchisee();
      this.getAuthorizeCoursewareCountDetial(this.dataInfo);
    },
    // 授权课包数
    getAuthorizeCoursewareCountDetial (rowData, callBack) {
      this.dataInfo = rowData;
      FranchiseeSearchAuthorizeCourseware(this.dataInfo.OLAPKey).then((result) => {
        this.expandRowData = [];
        if (result.data) {
          this.expandRowData = result.data.PageDataList;
          this.expandRowData.forEach((o) => {
            o.AuthorizeTime = o.AuthorizeTime
              ? o.AuthorizeTime.slice(0, 10)
              : '';
            if (Number(o.StatusKey) == 1) {
              o.StatusKeyValue = '使用中';
            } else if (Number(o.StatusKey) == 0) {
              o.StatusKeyValue = '已暂停';
            } else if (Number(o.StatusKey) == 2) {
              o.StatusKeyValue = '已用完';
            } else if (Number(o.StatusKey) == 3) {
              o.StatusKeyValue = '已过期';
            }
            o.parentCoursewarePackageKeyValue =
              (o.ParentKeyValue ? o.ParentKeyValue + '-' : '') +
              o.CoursewarePackageKeyValue;
            if (Number(o.ArrangedAlert) == 1) {
              o.ArrangedWarningValue = `<div class="arranged_alert">警戒</div>`;
            } else if (Number(o.ArrangedWarning) == 1) {
              o.ArrangedWarningValue = `<div class="arranged_warning">预警</div>`;
            }
            // o.IsLocationLockKeyValue = o.IsLocationLockKey > 0 ? '已开启' : '未开启';
            // o.ThisMonthApplyAmount=number(o.ThisMonthApplyAmount);
            // o.ThisMonthClassAmount=number(o.ThisMonthClassAmount);
            // o.ResidueAmount=number(o.ResidueAmount);
            // o.ClassHourCount=number(o.ClassHourCount);
            //  o.ThisMonthSignRate=number(o.ThisMonthSignRate)+'%'
          });
          if (callBack) {
            callBack(this.expandRowData || []);
          }
          console.log(this.expandRowData, '授权课包数');
        }
      },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    // 展开详情-授权课件按钮
    doAuthorizeCoursewareBtn () {
      console.log('授权课件按钮');
      this.titleName = '授权课件';
      this.coursewareInfo = {};
      this.isShowCoursewareFormPopup = true;
    },
    // 停用/启用
    switchClick (rowData, callBack) {
      this.coursewareInfo = rowData;
      let name = rowData.StatusKey == 0 ? '恢复授权' : '暂停授权';
      layer.confirm('请确定是否' + name + '?', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              layer.close();
              if (callBack) {
                callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
              }
            }
          },
          {
            name: '确认',
            callBack: () => {
              if (rowData.StatusKey == 0) {
                AuthorizationCoursewareEnable(rowData.OLAPKey).then(
                  (result) => {
                    this.afterSuccessUpExpandRowData();
                    if (callBack) {
                      callBack({
                        IsInquiryHint: 'inquiryBtn',
                        InquiryClassName: ''
                      });
                    }
                  },
                  (error) => {
                    layer.alert(error.msg);
                  }
                );
              } else {
                AuthorizationCoursewareDisable(rowData.OLAPKey).then(
                  (result) => {
                    this.afterSuccessUpExpandRowData();
                    if (callBack) {
                      callBack({
                        IsInquiryHint: 'inquiryBtn',
                        InquiryClassName: ''
                      });
                    }
                  },
                  (error) => {
                    layer.alert(error.msg);
                  }
                );
              }
              layer.close();
            }
          }
        ]
      });
    },
    // 展开详情-续费按钮
    renewClick (rowData) {
      this.titleName = '课件续费';
      this.coursewareInfo = rowData;
      this.isShowCoursewareFormPopup = true;
    },
    // 关闭
    closeFormPopup () {
      this.isShowCoursewareFormPopup = false;
      this.isShowParticipateCampusFormPopup = false;
      this.isShowPaymentForm = false;
    },
    registerBusEvent () {
      this.$bus.on(this, 'updateCampusCourseware', (data) => {
        if (this.dataInfo.OLAPKey && this.expandRowData.length > 0) {
          this.dataInfo.OLAPKey = data.DataKey;
          this.getAuthorizeCoursewareCountDetial(this.dataInfo);
        }
      });
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'franchiseeManagement') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'franchiseeManagement') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.franchiseeManagementDialogFactory) {
        this.$refs.franchiseeManagementDialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.dialogFactoryCommon) {
        this.$refs.dialogFactoryCommon.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    }
  },
  computed: {}
};
</script>

<style >
.franchiseeManagement
  .summarizing_list
  .table_list_content
  .el-table
  thead
  tr
  th
  .cell.font_dual {
  display: flex;
  width: 87px;
  text-align: center;
  align-items: center;
  overflow: visible;
  line-height: 16px;
  white-space: pre-line;
  text-align: center;
}
.franchiseeManagement >>> .ansyio .el-table__body-wrapper {
  width: 103.9%;
}
</style>


