<template>
  <!-- 课程表 -->
  <div class="margin_left_right_div">
    <div class="template_minOrmax_width_div">
      <div style="min-width: 1260px;max-width: 1900px;width: auto;padding:20px 38px;"
           class="width_fixed clearfix paike"
           ref="courseTableMainBody">
        <div v-show="showBox"
             class="course_table_box"
             style="width: 79%;"
             :class="{paddingShow:isAllShow==3}">
          <div class="course_table_top_fixed">
            <div class="course_table_top_fixed_shadow">
              <div class="course_table_header"
                   style="position:relative;height:66px">
                <div class="listBox">
                  <!-- <el-switch v-model="isAllShow"
                         :width="86"
                         active-color="#3498DB"
                         inactive-color="#3498DB">
              </el-switch> -->
                  <span @click="()=>{this.isAllShow = 2}"
                        class="listBoxText"
                        :class="{listBoxTextOpt:isAllShow == 2}">精简</span>
                  <span @click="()=>{this.isAllShow = 1}"
                        class="listBoxText"
                        :class="{listBoxTextOpt:isAllShow == 1}">全部</span>
                  <span @click="()=>{this.isAllShow = 3}"
                        class="listBoxText"
                        :class="{listBoxTextOpt:isAllShow == 3}">列表</span>
                </div>
                <!-- <div
                 class="course_header_inner1 btn_hover_white"
                 :class="{btn_hover_bg_blue:isShowScheduleTable}"
                 style="width:80px">排课列表</div> -->
                <div @click="todayTableInfoList(Date.new())"
                     class="course_header_inner1 btn_hover_white"
                     :class="{btn_hover_bg_blue:toWeek}">今天</div>
                <div class="course_header_center">
                  <div class="course_header_date_box fl">
                    <div class="course_header_inner4">日历</div>
                    <input-date class="course_header_date"
                                :formTitle="''"
                                :popperClass="'course_date_el'"
                                @changeDate="todayTableInfoList"></input-date>
                  </div>

                  <div @click="searchSevenDayAgo"
                       class="course_header_inner5"></div>
                  <div class="course_header_inner2">
                    <span>{{vSeletedDateStart}}</span>
                    <span>{{vMonthWeek}}</span>
                  </div>

                  <icon-btn @clickItem="searchSevenDayAfter"
                            :className="'course_header_inner6'">
                  </icon-btn>
                </div>

                <div v-if="isShowCourseTableRightBtn"
                     class="fr">
                  <div class="course_header_fr_btn btn_hover_white "
                       @mouseleave="isShowNewCourseType=false">
                    <function-btn :item="addcoursebtn"
                                  @clickItem="showNewCourse">
                      新建课表
                    </function-btn>
                  </div>
                  <div class="course_header_fr_btn btn_hover_white "
                       @mouseleave="isShowMenuList=false">
                    <function-btn :item="addcoursebtn"
                                  @clickItem="showMenuList">
                      更多操作
                    </function-btn>
                  </div>
                  <div @click="todayTableInfoList(seletedDate)"
                       class="course_header_inner3 course_header_fr_btn btn_hover_white ">刷新</div>
                </div>
                <div v-else
                     class="fr">
                  <div class="course_header_select_box">
                    <div class="course_header_select_name">助教</div>
                    <el-select v-model="selectAssistantTeacherName"
                               class="course_header_select_input"
                               @change="clickAssistantTeacher"
                               :placeholder="'请选择助教老师'">
                      <el-option v-for="item in teacheList"
                                 :key="item.OLAPKey"
                                 :label="item.MainDemoName"
                                 :value="item"></el-option>
                    </el-select>
                  </div>
                  <div @click="closeAssistantTeacher"
                       class="course_header_fr_btn btn_hover_white ">
                    返回课表
                  </div>
                </div>
              </div>
              <div class="course_table_header header_color"
                   v-if="isAllShow!=3">
                <div class="header_menu"
                     style="width: 53px;"></div>
                <div class="header_swiper">
                  <div class="swiper-container"
                       id="header">
                    <div class="swiper-wrapper">
                      <div v-for="(item,index) in dateList"
                           :key="index"
                           class="swiper-slide swiper-no-swiping">
                        <div class="font_date"
                             :class="{font_blue:item.today}">{{item.today?'今天':disposeDate(item.date)}}</div>
                        <div class="font_week"
                             :class="{font_blue:item.today}">{{item.week}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isAllShow==3">
            <schedule-table :dataList="timeTableInfoList"
                            :startDate="beginDateTime"
                            :endDate="endDateTime"
                            @doExportInfoClick="doExportInfoClick"
                            @stopCourse="courseSuspend"
                            @showEditData="showEditData"
                            @toScheduleAtten="toScheduleAtten"
                            @showTableCourseDetail="showTableCourseDetail">

            </schedule-table>
          </div>

          <div class="course_table_content clearfix"
               @scroll="scrollEvent"
               v-if="isAllShow!=3">
            <div class="content_date">
              <div v-for="item in vTimeHeight"
                   :key="item.date"
                   :style="{height:item.height+ 'px'}"
                   v-if="item.scope && (isAllShow==1 || item.height > 0)">{{item.date}}</div>
            </div>
            <div class="content_swiper">
              <div class="swiper-container"
                   id="content">
                <div class="swiper-wrapper">
                  <div v-for="(item,index) in vPresentDateList"
                       :key="index"
                       :class="{course_today_color:item.today}"
                       class="swiper-slide swiper-no-swiping"
                       v-if="vTimeHeight.length >= 23">
                    <div v-for="(item1,index1) in item.dateInfo"
                         :key="index1"
                         class="date_block"
                         :class="hidePoint(index1,item)?'':'hide_cursor'"
                         @click.stop="newScheduleCourse(index1,item,$event)"
                         :style="{height:vTimeHeight[index1].height+ 'px'}"
                         v-if="vTimeHeight[index1].scope && (isAllShow==1 || vTimeHeight[index1].height > 0)">
                      <div v-for="(item2,index2) in item1"
                           :key="index2"
                           :id="'course_'+item2.OLAPKey"
                           :class="courseClass(item2)"
                           @mouseover.stop="showPopup(item2,item,$event)"
                           @mouseout.stop="clearTimeOut"
                           @click="courseClick(item2)"
                           class="course_table_block">
                        <div class="course_name"
                             :class="{font_dual:item2.GradeClassKeyValue.length > 13}">
                          <div><span>{{item2.GradeClassKeyValue}}</span></div>
                        </div>
                        <div class="teacher_date">{{item2.BeginClassTime}}-{{item2.EndClassTime}} {{item2.MTeacherKeyValue}}</div>
                        <div v-if="item2.StatusKey == 2"
                             class="course_number">
                          安排 <span class="font_black">{{Number(item2.ArrangedNumCount)}}</span>人
                          <span v-if="Number(item2.WarnLossCount)>0&&(Number(item2.ArrangedNumCount)-Number(item2.LeaveCount)) <= Number(item2.WarnLossCount)">| <span class="font_red">人数不足</span></span>
                        </div>
                        <div v-else
                             class="course_number_gray"
                             :class="item2.StyleName+ '_bottom_color'">
                          签到 {{Number(item2.ArrivedNumCount)}}人丨
                          <span>
                            <span v-if="item2.isTruantCount > 0">
                              未到
                              <span class="font_red">{{Number(item2.isTruantCount)}}</span>人
                            </span>
                            <span v-else-if="item2.ArrangedNumCount == 0"
                                  class="font_red">未排学生</span>
                            <span v-else
                                  class="">全勤</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <pop-up-drop-down-box v-show="isShowNewCourseType"
                                :style="{ zIndex: 10 }"
                                :class="{pf:isPopFixed}"
                                :isShowDialog="isShowNewCourseType"
                                :dropDownList="newScheduleCourseObjType"
                                :positionLeft="positionLeft"
                                :positionTop="positionTop"
                                @onClick="doAfterSelectedNewCourseTableType"
                                @mouseover.native="isShowNewCourseType = true"
                                @mouseleave.native="closeNewCourseTable"></pop-up-drop-down-box>

          <pop-up-drop-down-box v-show="isShowMenuList"
                                :style="{ zIndex: 10 }"
                                :class="{pf:isPopFixed}"
                                :isShowDialog="isShowMenuList"
                                :dropDownList="menuDropList"
                                :positionLeft="positionLeft"
                                :positionTop="positionTop"
                                @onClick="doShowMenuList"
                                @mouseover.native="isShowMenuList = true"
                                @mouseleave.native="closeShowMenuList"></pop-up-drop-down-box>

          <el-popover-custom v-if="isShowNewCourseType"> </el-popover-custom>
          <!-- 亲，当前没有任何数据哦~显示 -->
          <div class="course_block_nodata_02"
               style="margin-top: 120px;"
               v-show="noData && isAllShow==2">
            <div class="course_block_nodata_txt_02">亲，当前没有任何数据哦~</div>
          </div>
          <popup-box ref="coursePopup"
                     :courseTableData="optCourseData"
                     :vTop="vTop"
                     :vLeft="vLeft"
                     @chgEditCourseShow="showEditCourse"
                     @chgCourseDetailShow="showCourseDetail"
                     @chgCourse="chgCourseUpdate"
                     @unAttendanceItem="toScheduleAtten"
                     @keepShow="keepShowAfter"
                     @closeShow="closeShowAfter"
                     @toScheduleCourse="toScheduleCourseItem"></popup-box>
          <search-popup ref="searchPopup"
                        :condition="$route.query.name"
                        :type="type"
                        @restoreSearch="restoreSearch"></search-popup>
        </div>
        <rightSideSearch @seletedItem="seletedItemAfter"
                         @reloadAll="reload"></rightSideSearch>
        <dialog-factory ref="courseTableDialogFactory"
                        :routerName="'CourseTable'"></dialog-factory>
        <dialog-factory-common ref="courseTableDialogFactoryCommon"></dialog-factory-common>
      </div>
    </div>
  </div>
</template>
<script>
import rightSideSearch from './right-side-search';
import popupBox from './popup-box-new';
import searchPopup from './search-popup';
import scheduleTable from './schedule-table';
import dialogFactory from '../../common/dialog-factory';
import functionList from '../../common/function-list';
import popUpDropDownBox from '../../common/pop-up-drop-down-box';
import elPopoverCustom from '../../common/el-popover-custom';
import { getMonthWeek } from '../../../utils/index';
import {
  GetScheduleCourseListByDate,
  GetScheduleCourseListByStudent,
  GetScheduleCourseListByTeacher,
  GetScheduleCourseListByClassType,
  SearchCourseForClass,
  SearchCourseForClassRoom,
  geTteacherList,
  EditAuxiliarySpeakForScheduleCourse,
  PauseScheduleCourse
} from '../../../API/workbench';
import { setIntervalCheck } from '../../../assets/index.js';
export default {
  name: 'CourseTable',
  data () {
    return {
      isShowScheduleTable: false,
      showBox: false,
      isShowEditCourses: false, // 修改单课显示
      isShowCourseTableRightBtn: true, // 助教排课模式关闭
      type: 0,
      searchId: '',
      toWeek: false,
      noData: true,
      isAllShow: 2,
      isPopFixed: false,
      isShowClassDetail: false,
      timeTableInfoList: [], // 指定日期段所有数据列表
      dateList: [], // 日期数据列表
      optCourseData: {},
      headerSwiper: '',
      contentSwiper: '',
      seletedDate: '', // 显示的日期.
      searchBoxShow: false, // 新建课表显示查找框
      timer: '', // 延时器装载
      isMouseout: false, // 判断是否鼠标移入到课块中. 如果是则将click事件阻止.
      vTop: '',
      vLeft: '',
      isMouseOnPopUp: false,
      isShowSetClassTime: false,
      courseAttendanceKey: '',
      teacheList: [], // 教师列表
      selectAssistantTeacher: {}, // 选中的助教
      selectAssistantTeacherName: '', // 选中的助教名
      isShowMenuList: false,
      menuDropList: [
        {
          id: '1',
          name: '助教排课',
          ModulePowerKey: 47,
          isShow: true
        },
        {
          id: '2',
          name: '长假停课',
          ModulePowerKey: 47,
          isShow: true
        },
        {
          id: '3',
          name: '长假调课',
          ModulePowerKey: 47,
          isShow: true
        },
        {
          id: '4',
          name: '复制课表',
          ModulePowerKey: 47,
          isShow: true
        }
      ],
      newScheduleCourseObjType: [
        {
          id: '3',
          name: '日历建课',
          ModulePowerKey: 47,
          isShow: true
        },
        {
          id: '2',
          name: '新建循环课',
          ModulePowerKey: 47,
          isShow: true
        }

      ],
      isShowNewCourseType: false,
      positionTop: 0,
      positionLeft: 0,
      classTimeObj: {},
      isShowDialogFactory: false, // 显示弹出层
      importMoudle: { type: 1, moudleName: '导入学生' }, // 导入模板名,
      addcoursebtn: {
        name: '',
        type: '',
        ModulePowerKey: 47
      },
      beginDateTime: '',
      endDateTime: ''
    };
  },
  components: {
    popupBox,
    searchPopup,
    rightSideSearch,
    dialogFactory,
    functionList,
    popUpDropDownBox,
    elPopoverCustom,
    scheduleTable
  },
  created () {
    console.log('created-课程表');
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
      this.searchId = this.$route.query.id;
    }
    this.contentSwiper = '';
    this.headerSwiper = '';
    this.$store.commit('setIsShowHeader', { booler: true });
    this.seletedDate = Date.new();

    this.$bus.on(this, 'switchHeaderBarToCourseTable', () => {
      this.todayTableInfoList(this.seletedDate);
    });
    this.todayTableInfoList(Date.new());
    document.addEventListener('click', e => {
      const ele = document.getElementsByClassName('alert_dialog')[0];
      if (e.target && !e.target.contains(ele)) {
        this.closeNewCourseTable();
      }
    });
    this.registerBusEvent();
    this.getTeacherList();
    this.setIntervalCheckCourse();
  },
  mounted () {
    this.contentSwiper = new Swiper('#content', {
      slidesPerView: 7,
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, // 修改swiper的父元素时，自动初始化swiper
      controller: {
        control: this.headerSwiper
      },
      on: {}
    });
    this.headerSwiper = new Swiper('#header', {
      slidesPerView: 7,
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, // 修改swiper的父元素时，自动初始化swiper
      noSwiping: true,
      controller: {
        control: this.contentSwiper
      }
    });

    this.swiperIndex(0);
    this.$nextTick(e => {
      this.showBox = true;
    });
    console.log('CourseTable-created');
    window.addEventListener('scroll', this.handleScroll);
    window.updateDKB = () => {
      this.todayTableInfoList(this.seletedDate);
    };
    this.$dialog.register(this, ['classDetail', 'editCourse']);
  },
  activated () {
    let moduleName = this.$route.params.moduleName;
    if (moduleName) {
      this.openCustomDialogByFactoryDialog(this.$route.params);
    }
    this.getTeacherList();
  },
  destroyed () {
    document.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    isAllShow (n, o) {
      if (this.timeTableInfoList.length == 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    },
    vCourseTableData (n, o) {
      this.todayTableInfoList(this.seletedDate);
    }
  },
  computed: {
    vCourseTableData () {
      return this.$store.getters.courseTableData;
    },
    // 显示的开始日期.
    vSeletedDateStart () {
      if (this.seletedDate) {
        let date = Date.new(this.seletedDate);
        let day = date.getDay() == 0 ? 7 - 1 : date.getDay() - 1;
        let startDate = Date.new(this.$utils.getNextDate(date, -day));
        let m;
        if (startDate.getMonth() + 1 > 9) {
          m = startDate.getMonth() + 1;
        } else {
          m = '0' + (startDate.getMonth() + 1);
        }
        return (
          startDate.getFullYear() +
          '年' +
          m +
          '月'
        );
      }
    },
    // 显示的结束日期.
    vSeletedDateEnd () {
      if (this.seletedDate) {
        let date = Date.new(this.seletedDate);
        let day = date.getDay() == 0 ? 7 - 1 : date.getDay() - 1;
        let startDate = Date.new(this.$utils.getNextDate(date, -day));
        let endDate = Date.new(this.$utils.getNextDate(Date.new(startDate), 6));
        if (
          startDate.getFullYear() == endDate.getFullYear() &&
          startDate.getMonth() == endDate.getMonth()
        ) {
          return endDate.getDate();
        } else if (startDate.getFullYear() != endDate.getFullYear()) {
          return (
            endDate.getFullYear() +
            '年' +
            (endDate.getMonth() + 1) +
            '月' +
            endDate.getDate()
          );
        } else {
          return endDate.getMonth() + 1 + '月' + endDate.getDate();
        }
      }
    },
    // 课程表数据
    vPresentDateListback () {
      let arr = [];
      this.toWeek = false;
      this.dateList.forEach(e => {
        e.dateInfo = [
          [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []
        ];
        let today = this.$utils.formatDateToLine(Date.new());
        if (e.date == today) {
          e.today = true;
          this.toWeek = true;
        }
        e.info.forEach(o => {
          let time = o.BeginClassTime.split(':');
          switch (String(time[0])) {
            case '00':
              e.dateInfo[0].push(o);
              break;
            case '01':
              e.dateInfo[1].push(o);
              break;
            case '02':
              e.dateInfo[2].push(o);
              break;
            case '03':
              e.dateInfo[3].push(o);
              break;
            case '04':
              e.dateInfo[4].push(o);
              break;
            case '05':
              e.dateInfo[5].push(o);
              break;
            case '06':
              e.dateInfo[6].push(o);
              break;
            case '07':
              e.dateInfo[7].push(o);
              break;
            case '08':
              e.dateInfo[8].push(o);
              break;
            case '09':
              e.dateInfo[9].push(o);
              break;
            case '10':
              e.dateInfo[10].push(o);
              break;
            case '11':
              e.dateInfo[11].push(o);
              break;
            case '12':
              e.dateInfo[12].push(o);
              break;
            case '13':
              e.dateInfo[13].push(o);
              break;
            case '14':
              e.dateInfo[14].push(o);
              break;
            case '15':
              e.dateInfo[15].push(o);
              break;
            case '16':
              e.dateInfo[16].push(o);
              break;
            case '17':
              e.dateInfo[17].push(o);
              break;
            case '18':
              e.dateInfo[18].push(o);
              break;
            case '19':
              e.dateInfo[19].push(o);
              break;
            case '20':
              e.dateInfo[20].push(o);
              break;
            case '21':
              e.dateInfo[21].push(o);
              break;
            case '22':
              e.dateInfo[22].push(o);
              break;
            case '23':
              e.dateInfo[23].push(o);
              break;
            default:
              break;
          }
        });
        arr.push(e);
      });

      return arr;
    },
    // 课程表数据
    vPresentDateList () {
      let today = this.$utils.formatDateToLine(Date.new());
      this.toWeek = false;
      this.dateList.forEach(e => {
        e.dateInfo = [
          [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []
        ];
        if (e.date == today) {
          e.today = true;
          this.toWeek = true;
        } else {
          e.today = false;
        }
      });
      this.timeTableInfoList.forEach(obj => {
        // console.log('timeTableInfoList-item', obj);
        let beginTime = Number(obj.BeginClassTime.split(':')[0]);
        let weekKey = Date.new(obj.ClassTime).getDay();
        weekKey = weekKey == 0 ? 6 : weekKey - 1;
        this.dateList[weekKey].dateInfo[beginTime].push(obj);
        if (obj.ClassTime == today) {
          this.dateList[weekKey].today = true;
          this.toWeek = true;
        } else {
          this.dateList[weekKey].today = false;
        }
      });
      this.dateList.forEach(e => {
        e.dateInfo.forEach(i => {
          i.forEach(o => {
            if (o.OLAPKey == this.optCourseData.OLAPKey) {
              o.opt = true;
            } else {
              o.opt = false;
            }
          });
        });
        for (let i = 0; i < 24; i++) {
          if (e.dateInfo[i].length > 1) {
            e.dateInfo[i].sort(this.sortOLAPKey);
            e.dateInfo[i].sort(this.sortDate);
          }
        }
      });
      return this.dateList;
    },
    vTimeHeight () {
      if (!this.$utils.getSaaSClientSetInfo(64).SetContent) {
        return false;
      }
      let arr = [];
      let arrObj = [];
      let flag = true;
      this.vPresentDateList.forEach(e => {
        for (let i = 0; i < 24; i++) {
          if (!arr[i]) {
            arr[i] = e.dateInfo[i].length;
          } else if (arr[i] < e.dateInfo[i].length) {
            arr[i] = e.dateInfo[i].length;
          }
        }
      });
      for (let j = 0; j < 24; j++) {
        if (arr[j] > 0) {
          arr[j] = arr[j] * 67 + 4;
          flag = false;
        } else {
          arr[j] = 0;
        }
        let d = '';
        switch (j) {
          case 0:
            d = '00:00';
            break;
          case 1:
            d = '01:00';
            break;
          case 2:
            d = '02:00';
            break;
          case 3:
            d = '03:00';
            break;
          case 4:
            d = '04:00';
            break;
          case 5:
            d = '05:00';
            break;
          case 6:
            d = '06:00';
            break;
          case 7:
            d = '07:00';
            break;
          case 8:
            d = '08:00';
            break;
          case 9:
            d = '09:00';
            break;
          case 10:
            d = '10:00';
            break;
          case 11:
            d = '11:00';
            break;
          case 12:
            d = '12:00';
            break;
          case 13:
            d = '13:00';
            break;
          case 14:
            d = '14:00';
            break;
          case 15:
            d = '15:00';
            break;
          case 16:
            d = '16:00';
            break;
          case 17:
            d = '17:00';
            break;
          case 18:
            d = '18:00';
            break;
          case 19:
            d = '19:00';
            break;
          case 20:
            d = '20:00';
            break;
          case 21:
            d = '21:00';
            break;
          case 22:
            d = '22:00';
            break;
          case 23:
            d = '23:00';
            break;
          default:
            break;
        }
        let lastTime = this.$utils.getSaaSClientSetInfo(65).SetContent.split(':');
        if (lastTime[1] > 0) {
          lastTime = lastTime[0];
        } else {
          lastTime = lastTime[0] - 1;
        }
        if (Number(d.split(':')[0]) >= Number(this.$utils.getSaaSClientSetInfo(64).SetContent.split(':')[0]) && Number(d.split(':')[0]) <= lastTime) {
          arrObj.push({
            height: arr[j],
            date: d,
            scope: true
          });
        } else {
          arrObj.push({
            height: arr[j],
            date: d,
            scope: false
          });
        }
      }
      this.noData = flag;
      return arrObj;
    },
    // 获取当前第几周
    vMonthWeek () {
      let week = getMonthWeek(this.vPresentDateList[0].date);
      let weekValue = '';
      switch (String(week)) {
        case '1':
          weekValue = '第一周';
          break;
        case '2':
          weekValue = '第二周';
          break;
        case '3':
          weekValue = '第三周';
          break;
        case '4':
          weekValue = '第四周';
          break;
        case '5':
          weekValue = '第五周';
          break;
        case '6':
          weekValue = '第六周';
          break;
        default:
          weekValue = '第七周';
          break;
      }
      return weekValue;
    },
    vMendianInfo () {
      console.log(this.vPresentDateList, 'this.$store.getters.SaaSClientInfo');
      return this.$store.getters.SaaSClientInfo;
    },
    vRolePowerList () {
      return this.$store.getters.RolePowerList;
    }

  },
  methods: {
    sortOLAPKey (a, b) {
      return a.CourseNameKey - b.CourseNameKey;
    },
    sortDate (a, b) {
      return a.BeginClassTime.split(':')[1] - b.BeginClassTime.split(':')[1];
    },
    closeAssistantTeacher () {
      this.isShowCourseTableRightBtn = true;
      this.selectAssistantTeacher = {};
      this.selectAssistantTeacherName = '';
    },
    courseClick (item) {
      if (!this.isShowCourseTableRightBtn) { // 助教模式才可触发
        if (!this.selectAssistantTeacher.OLAPKey) {
          layer.alert('请先选择助教老师');
          return false;
        }
        if (this.selectAssistantTeacher.OLAPKey == item.MTeacherKey) {
          layer.alert('选择教师为此节课的主讲老师，不可设置为助教');
          return false;
        }
        let isTeacher;
        let ATeaNameKeyArr = [];
        let ATeaNameArr = [];
        if (item.ATeaKeyName && item.ATeaKeyName.length > 2) {
          ATeaNameKeyArr = item.ATeaKeyName.split(',');
          ATeaNameArr = item.ATeaName.split(',');
          isTeacher = ATeaNameKeyArr.findIndex(id => { return id == this.selectAssistantTeacher.OLAPKey; });
        }
        if (isTeacher > -1) {
          ATeaNameKeyArr.splice(isTeacher, 1);
          ATeaNameArr.splice(isTeacher, 1);
        } else {
          ATeaNameKeyArr.push(this.selectAssistantTeacher.OLAPKey);
          ATeaNameArr.push(this.selectAssistantTeacher.MainDemoName);
        }
        let courseItme = {
          ATeaKeyName: '',
          ATeaName: ''
        };
        ATeaNameKeyArr.forEach(e => {
          courseItme.ATeaKeyName = courseItme.ATeaKeyName + e + ',';
        });
        ATeaNameArr.forEach(e => {
          courseItme.ATeaName = courseItme.ATeaName + e + ',';
        });
        courseItme.ATeaKeyName = courseItme.ATeaKeyName.substring(0, courseItme.ATeaKeyName.length - 1);
        courseItme.ATeaName = courseItme.ATeaName.substring(0, courseItme.ATeaName.length - 1);
        EditAuxiliarySpeakForScheduleCourse(item.OLAPKey, courseItme.ATeaKeyName, courseItme.ATeaName).then(res => {
          item.ATeaKeyName = res.data.ATeaKeyName;
          item.ATeaName = res.data.ATeaName;
        }, err => {
          layer.alert(err.msg);
        });
      }
    },
    doShowMenuList (item) {
      this.closeShowMenuList();
      if (item.id == 1) {
        this.isShowCourseTableRightBtn = false;
      } else if (item.id == 2) {
        this.showHolidaysUspendClasses();
      } else if (item.id == 3) {
        this.openCustomDialogByFactoryDialog({ moduleName: 'holidaysAdjustClasses' });
      } else if (item.id == 4) {
        this.openCustomDialogByFactoryDialog({ moduleName: 'copyCourse' });
      }
    },
    closeShowMenuList () {
      this.isShowMenuList = false;
    },
    clickAssistantTeacher (item) {
      this.selectAssistantTeacherName = item.MainDemoName;
      this.selectAssistantTeacher = item;
    },
    getTeacherList () {
      geTteacherList().then(res => {
        this.teacheList = res.data;
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 定时检查是否有课从未开始-》可签到
    setIntervalCheckCourse () {
      setIntervalCheck(() => {
        this.timeTableInfoList.forEach(o => {
          let starttime = o.ClassTime.replace(/-/g, '/') + ' ' + o.BeginClassTime;        // 开始时间
          let endtime = o.ClassTime.replace(/-/g, '/') + ' ' + o.EndClassTime;        // 开始时间
          let startClassTime = Date.new(starttime).getTime() / 1000 / 60;
          let endClassTime = Date.new(endtime).getTime() / 1000 / 60;
          let curTime = Date.new().getTime() / 1000 / 60;
          if (o.StatusKey == 2 && startClassTime - curTime <= 0) { // 未来的课    // 当前课上课时间-当前时间 < 0 变为上课中
            o.StatusKey = 3;
          } else if (o.StatusKey == 3 && endClassTime - curTime <= 0) { // 当前课下课时间-当前时间 < 0 变为已结束
            o.StatusKey = 4;
          }
        });
      });
    },
    courseClass (data) {
      let c = '';
      c += data.StyleName + '_color ';
      if (data.opt) {
        c += data.StyleName + '_shadow_color';
      }
      if (data.StatusKey != 2 && data.NoAttendanceCount > 0) {
        c += ' warning_icon';
      }
      if (!this.isShowCourseTableRightBtn && this.selectAssistantTeacher.OLAPKey && data.ATeaKeyName) {
        let aTeacher = data.ATeaKeyName.split(',');
        let isTeacher = aTeacher.findIndex(id => { return id == this.selectAssistantTeacher.OLAPKey; });
        if (isTeacher > -1) {
          c += ' tick_type';
        }
      }

      return c;
    },
    showNewCourse () {
      this.isPopFixed = true;
      this.positionTop = 132;
      this.positionLeft = document.documentElement.getElementsByClassName('course_table_top_fixed')[0].offsetLeft + 39 + document.documentElement.getElementsByClassName('course_header_fr_btn btn_hover_white ')[0].offsetLeft - 30;
      this.classTimeObj = {};
      this.isShowNewCourseType = true;
    },
    showMenuList () {
      this.isPopFixed = true;
      this.positionTop = 132;
      this.positionLeft = document.documentElement.getElementsByClassName('course_table_top_fixed')[0].offsetLeft + 39 + document.documentElement.getElementsByClassName('course_header_fr_btn btn_hover_white ')[1].offsetLeft - 30;
      this.isShowMenuList = true;
    },
    textLength (text) {
      // if (document.documentElement.getElementsByClassName('course_name')[0]) {
      // let domWidth = 149;
      // let len = text.replace(/[\u0391-\uFFE5]/g, 'aa').length;
      // if (len * 11.5 > domWidth) {
      //   return true;
      // } else {
      //   return false;
      // }
      // }
      if (text.length > 7) {
        return true;
      } else {
        return false;
      }
    },
    // 选择全部重新加载
    reload (type) {
      this.type = 0;
      this.todayTableInfoList(this.seletedDate);
    },
    // 获取指定日期的数据
    todayTableInfoList (date) {
      this.seletedDate = Date.new(date);
      var day = this.seletedDate.getDay() == 0 ? 7 - 1 : this.seletedDate.getDay() - 1;
      this.beginDateTime = this.$utils.getNextDate(this.seletedDate, -day);
      this.endDateTime = this.$utils.getNextDate(Date.new(this.beginDateTime), 6);
      this.dateList = this.$utils.getDateParagraph(this.beginDateTime, this.endDateTime);
      // console.log(this.dateList, 'this.dateList');
      this.getTimeTableInfoList();
    },
    showPopup (item, date, e) {
      this.isMouseout = true;
      this.isMouseOnPopUp = true;
      this.closeNewCourseTable();
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (item) {
          item.opt = true;
          let seletedEle = document.getElementById('course_' + item.OLAPKey);
          let boxDom = document.getElementsByClassName('course_table_content')[0];
          this.isMouseout = false;
          console.log('showPopup');
          this.$refs.coursePopup.showGetData(item.OLAPKey);
          this.optCourseData = item;
          this.optCourseData.ScheduleCourseKey = item.OLAPKey;
          this.optCourseData.week = date.week;
          // 计算距离左侧距离及顶端的距离.
          this.vTop = Math.abs(boxDom.scrollTop - (seletedEle.offsetTop + 140 + seletedEle.offsetHeight - 23)); // 140为固定高度,52为该元件的高度
          let d = document.documentElement;
          let b = document.body;
          let domTop = seletedEle.offsetTop - d.scrollTop + 265;
          if (b.offsetHeight - domTop < 206) {
            this.vTop -= 262;
            if (
              ((item.ArrangedNumCount > 0 && item.isTruantCount == 0) || (item.ArrangedNumCount > 0 && item.StatusKey == 2)) &&
              (item.NoAttendanceCount == 0 || item.StatusKey == 2) &&
              item.LeaveCount == 0 &&
              (item.StatusKey != 2 || item.TruantCount == 0)) {
              this.vTop += 27;
            }
          }
          this.vTop += 'px';
          this.vLeft = seletedEle.parentElement.parentElement.offsetLeft + 91 + 'px'; // 100位固定的左边宽度.
        } else {
          console.log('没有触发');
        }
        clearTimeout(this.timer);
      }, 100);
    },
    clearTimeOut () {
      this.isMouseout = false;
      this.isMouseOnPopUp = false;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.isMouseOnPopUp || this.$refs.coursePopup.isShowNewCourseType) {
          this.$refs.coursePopup.showBox = true;
          this.closeNewCourseTable();
        } else {
          this.optCourseData = {};
          this.$refs.coursePopup.showBox = false;
        }
      }, 100);
    },
    closeShowAfter () {
      this.isMouseOnPopUp = false;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.isMouseOnPopUp) {
          this.$refs.coursePopup.showBox = true;
          this.closeNewCourseTable();
        } else {
          this.$refs.coursePopup.showBox = false;
        }
      }, 100);
    },
    keepShowAfter (type) {
      this.isMouseOnPopUp = true;
    },
    swiperIndex (val) {
      this.headerSwiper.updateSlides();
      this.headerSwiper.slideTo(val);
    },
    disposeDate (date) {
      let arr = date.split('-');
      return arr[1] + '-' + arr[2];
    },
    timeTableInfoData () {
      this.timeTableInfoList.forEach(e => {
        e.MTeacherKeyValue = e.MTeacherKeyValue || '';
        e.MTeacherKeyValue = e.MTeacherKeyValue.split(',')[0];
        e.isTruantCount = e.ArrangedNumCount - e.ArrivedNumCount;
        if (Number(e.ArrangedNumCount) <= Number(e.FullPeopleCount)) {
          if (Number(e.AttenceCount) == 0 && Number(e.FullPeopleCount) == 0) {
            e.AttencePercentage = 0;
          } else {
            e.AttencePercentage = Math.ceil((Number(e.AttenceCount) / Number(e.FullPeopleCount)) * 100);
          }
          if (Number(e.ArrangedNumCount) == 0 && Number(e.FullPeopleCount) == 0) {
            e.ArrangedPercentage = 0;
          } else {
            e.ArrangedPercentage = Math.ceil((Number(e.ArrangedNumCount) / Number(e.FullPeopleCount)) * 100);
          }
        } else {
          if (Number(e.AttenceCount) == 0 && Number(e.ArrangedNumCount) == 0) {
            e.AttencePercentage = 0;
          } else {
            e.AttencePercentage = Math.ceil((Number(e.AttenceCount) / Number(e.ArrangedNumCount)) * 100);
          }
          e.ArrangedPercentage = 100;
        }

        e.ClassTimeText = this.$utils.getWeekByTwo(e.ClassTime);
        e.ClassStartToEnd = e.BeginClassTime + '~' + e.EndClassTime;
        e.PreSaleAmountCountText = Number(e.PreSaleAmountCount) == 0 ? '-' : '￥' + this.$utils.setMoneyformatToParseInt(Number(e.PreSaleAmountCount));
      });
    },
    // 变更某节课的状态
    updateCourseStatus (courseInfo) {
      courseInfo.MTeacherKeyValue = courseInfo.MTeacherKeyValue || '';
      courseInfo.MTeacherKeyValue = courseInfo.MTeacherKeyValue.split(',')[0];
      courseInfo.isTruantCount = courseInfo.ArrangedNumCount - courseInfo.ArrivedNumCount;
      if (Number(courseInfo.ArrangedNumCount) <= Number(courseInfo.FullPeopleCount)) {
        if (Number(courseInfo.AttenceCount) == 0 && Number(courseInfo.FullPeopleCount) == 0) {
          courseInfo.AttencePercentage = 0;
        } else {
          courseInfo.AttencePercentage = Math.ceil((Number(courseInfo.AttenceCount) / Number(courseInfo.FullPeopleCount)) * 100);
        }
        if (Number(courseInfo.ArrangedNumCount) == 0 && Number(courseInfo.FullPeopleCount) == 0) {
          courseInfo.ArrangedPercentage = 0;
        } else {
          courseInfo.ArrangedPercentage = Math.ceil((Number(courseInfo.ArrangedNumCount) / Number(courseInfo.FullPeopleCount)) * 100);
        }
      } else {
        if (Number(courseInfo.AttenceCount) == 0 && Number(courseInfo.ArrangedNumCount) == 0) {
          courseInfo.AttencePercentage = 0;
        } else {
          courseInfo.AttencePercentage = Math.ceil((Number(courseInfo.AttenceCount) / Number(courseInfo.ArrangedNumCount)) * 100);
        }
        courseInfo.ArrangedPercentage = 100;
      }
    },
    doExportInfoClick () {
      let IsExportKey = 1;
      let jsonFormate = {
        SheetTitle: '排课列表',
        clist: [
          { title: '周几', cName: 'WeekKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '时间', cName: 'ClassTimeName', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
          { title: '班级', cName: 'GradeClassKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '老师', cName: 'MTeacherKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '课程', cName: 'CourseNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '学生', cName: 'StudentNameStr', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '预消课时', cName: 'PreClassCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '预消金额', cName: 'PreSaleAmountCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
        ]
      };
      let obj = {
        startTime: this.beginDateTime,
        endTime: this.endDateTime,
        IsExportKey: IsExportKey
      };
      if (this.type == 1) {
        obj.StudentKey = this.searchId;
        GetScheduleCourseListByStudent(obj, jsonFormate).then(
          result => {
            window.location.href = this.$store.getters.APIURL + result.data.url;
          }, error => {
            layer.alert(error.msg);
          }
        );
      } else if (this.type == 2) {
        obj.tescherKey = this.searchId;
        GetScheduleCourseListByTeacher(obj, jsonFormate).then(
          result => {
            window.location.href = this.$store.getters.APIURL + result.data.url;
          }, error => {
            layer.alert(error.msg);
          }
        );
      } else if (this.type == 3) {
        obj.classTypeKey = this.searchId;
        GetScheduleCourseListByClassType(obj, jsonFormate).then(
          result => {
            window.location.href = this.$store.getters.APIURL + result.data.url;
          }, error => {
            layer.alert(error.msg);
          }
        );
      } else if (this.type == 4) {
        obj.ClassRoomId = this.searchId;
        SearchCourseForClassRoom(obj, jsonFormate).then(
          result => {
            window.location.href = this.$store.getters.APIURL + result.data.url;
          }, error => {
            layer.alert(error.msg);
          }
        );
      } else if (this.type == 5) {
        obj.ClassId = this.searchId;
        SearchCourseForClass(obj, jsonFormate).then(result => {
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        // obj.ScheduleCourseKey = this.searchId;
        GetScheduleCourseListByDate(obj, jsonFormate).then(result => {
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      }
    },
    // 获取指定日期的课程数据
    getTimeTableInfoList () {
      let initDataFunction = (data) => {
        // console.log(data, 'getTimeTableInfoListgetTimeTableInfoList');
        this.timeTableInfoList = data;
        this.timeTableInfoData();
      };
      let obj = {
        startTime: this.beginDateTime,
        endTime: this.endDateTime,
        IsExportKey: 0
      };
      if (this.type == 1) {
        obj.StudentKey = this.searchId;
        GetScheduleCourseListByStudent(obj).then(
          res => {
            if (res.code == 0) {
              initDataFunction(res.data);
            }
          }
        );
      } else if (this.type == 2) {
        obj.tescherKey = this.searchId;
        GetScheduleCourseListByTeacher(obj).then(
          res => {
            if (res.code == 0) {
              initDataFunction(res.data);
            }
          }
        );
      } else if (this.type == 3) {
        obj.classTypeKey = this.searchId;
        GetScheduleCourseListByClassType(obj).then(res => {
          if (res.code == 0) {
            initDataFunction(res.data);
          }
        });
      } else if (this.type == 4) {
        obj.ClassRoomId = this.searchId;
        SearchCourseForClassRoom(obj).then(
          res => {
            if (res.code == 0) {
              initDataFunction(res.data);
            }
          }
        );
      } else if (this.type == 5) {
        obj.ClassId = this.searchId;
        SearchCourseForClass(obj).then(res => {
          if (res.code == 0) {
            initDataFunction(res.data);
          }
        });
      } else {
        // obj.ScheduleCourseKey = this.searchId;
        GetScheduleCourseListByDate(obj).then(res => {
          if (res.code == 0) {
            initDataFunction(res.data);
          }
        });
      }
    },
    showSearch () {
      this.$refs.searchPopup.showBox = true;
    },
    restoreSearch () {
      this.type = 0;
      this.searchId = '';
      this.$router.push({ path: 'coursetable' });
      this.todayTableInfoList(Date.new());
    },
    // 查找7天前
    searchSevenDayAgo () {
      // let date = this.seletedDate.toLocaleDateString();
      this.seletedDate = Date.new(this.$utils.getNextDate(this.seletedDate, -7));
      console.log(this.seletedDate, 'searchSevenDayAgo');
      this.todayTableInfoList(this.seletedDate);
    },
    // 查找7天后
    searchSevenDayAfter () {
      this.seletedDate = Date.new(this.$utils.getNextDate(this.seletedDate, 7));
      console.log(this.seletedDate, 'searchSevenDayAfter');
      this.todayTableInfoList(this.seletedDate);
    },
    // 课修改更新.
    chgCourseUpdate () {
      this.todayTableInfoList(this.seletedDate);
    },
    // 弹出隐藏修改单课
    showEditCourse () {
      // this.isShowEditCourses = booler;
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'editCourse',
        data: { courseInfo: this.optCourseData }
      });
    },
    // 列表弹出弹出隐藏修改单课
    showEditData (data) {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'editCourse',
        data: { courseInfo: data }
      });
    },
    hiddenEditCourse () {
      this.isShowEditCourses = false;
    },
    // 显示课程明细.
    showCourseDetail () {
      // this.isShowClassDetail = true;
      // this.$nextTick(() => {
      //   this.$refs.classDetail.showCourseDetails = true;
      // });
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'classDetail',
        data: { courseInfo: this.optCourseData },
        callBack: { afterSucces: () => { this.chgCourseUpdate(); } }
      });
    },
    // 列表显示课程明细.
    showTableCourseDetail (data) {
      // this.isShowClassDetail = true;
      // this.$nextTick(() => {
      //   this.$refs.classDetail.showCourseDetails = true;
      // });
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'classDetail',
        data: { courseInfo: data },
        callBack: { afterSucces: () => { this.chgCourseUpdate(); } }
      });
    },
    // 安排考勤
    toScheduleAtten (item, isLastData) {
      console.log('``````````````````````````isLastData', isLastData);
      let moduleInfo = {
        name: '安排考勤',
        moduleName: 'attendanceArrange',
        routerName: this.$route.name,
        data: { scheduleCourseKey: item.OLAPKey, isHistory: isLastData, isAlter: !isLastData }
      };
      this.openCustomDialogByFactoryDialog(moduleInfo);
      // this.$refs.newAttendanceArrangeDialog.isAttendanceArrangeShow = true;
      // this.courseAttendanceKey = item.OLAPKey;
      // this.$refs.newAttendanceArrangeDialog.getCourseInfo(item.OLAPKey);
    },
    // 时间已过的课程不可点击
    compareDate (i, item) {
      let curDate = Date.new();
      let curTime = this.$utils.formatDateToLine(curDate);
      let classDate = this.$utils.formatDateToLine(Date.new(item.date));
      if (curTime > classDate) {
        return false;
      } else if (classDate > curTime) {
        return true;
      } else {
        let classTime = this.newTime(i);
        let time = classTime.split('-')[0];
        let curHour = curDate.getHours();
        let curMinute = curDate.getMinutes();
        let classHour = Number(time.split(':')[0]);
        let classMinute = curDate.getMinutes();
        if (classHour <= curHour) {
          return false;
        } else if (classHour > curHour) {
          return true;
        } else {
          if (classMinute < curMinute) {
            return false;
          } else {
            return true;
          }
        }
      }
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vRolePowerList, ModulePowerKey);
    },
    hidePoint (i, item) {
      let classTimeRange = this.newTime(i);
      let classTime = item.date + ' ' + classTimeRange.split('-')[0];
      let b = this.$utils.CheckModulePower(this.vRolePowerList, 47);
      // console.log(b, '权限');
      if (Date.new(Date.new(classTime).toLocaleDateString() + ' ' + classTimeRange.split('-')[0]) < Date.new() || !b) {
        return false;
      } else {
        return true;
      }
    },
    // 空白区域进入- 新建课表
    newScheduleCourse (i, item, e) {
      if (this.vHasModulePower(47)) {
        this.closeNewCourseTable();
        if (this.isMouseOnPopUp) {
          return false;
        }
        let result = this.compareDate(i, item);
        if (!result) {
          return false;
        }
        this.mouseOverNewCourseTable(i, null, e);
        // let classTimeRange = this.newTime(i);
        let weekKey = Date.new(item.date).getDay();
        weekKey = weekKey == 0 ? 7 : weekKey;
        this.classTimeObj.frequenceInfo = [];
        this.classTimeObj.frequenceInfo.push(weekKey);
        this.classTimeObj.CircleStartTime = item.date;
        this.classTimeObj.ClassTime = item.date;
        // this.classTimeObj.SClassTimeName = classTimeRange.split('-')[0];
        // this.classTimeObj.EClassTimeName = '';
      }
    },
    // 鼠标移到新建课
    mouseOverNewCourseTable (i, item, e) {
      clearTimeout(this.timer);
      this.isPopFixed = false;
      if (e) {
        this.positionTop = e.pageY - 90;
        this.positionLeft = e.target.parentElement.offsetLeft + e.offsetX - 12;
      }
      this.isShowNewCourseType = true;
      if (this.isShowNewCourseType && e) {
        e.currentTarget.classList.add('border_red');
      }
    },
    // 新建课 选中新建课类型
    doAfterSelectedNewCourseTableType (item) {
      this.closeNewCourseTable();
      this.classTimeObj.CircleEndTime = '';
      if (item.id == 2) {
        this.openCustomDialogByFactoryDialog({ moduleName: 'newCircleCourseTable', data: { dataInfo: this.classTimeObj } });
      } else {
        this.openCustomDialogByFactoryDialog({ moduleName: 'newCourseByCalendar', data: { dataInfo: this.classTimeObj } });
      }
    },
    // 课块进入-新建课表
    toScheduleCourseItem (data, type) {
      this.classTimeObj.frequenceInfo = [];
      this.classTimeObj.CircleStartTime = data.ClassTime;
      let weekKey = Date.new(data.ClassTime).getDay();
      weekKey = weekKey == 0 ? 7 : weekKey;
      this.classTimeObj.frequenceInfo.push(weekKey);
      // this.classTimeObj.SClassTimeName = data.BeginClassTime;
      // this.classTimeObj.EClassTimeName = data.EndClassTime;
      this.doAfterSelectedNewCourseTableType({ id: type });
    },
    newTime (index) {
      let time = '';
      switch (index) {
        case 0:
          time = '00:00-01:30';
          break;
        case 1:
          time = '01:00-02:30';
          break;
        case 2:
          time = '02:00-03:30';
          break;
        case 3:
          time = '03:00-04:30';
          break;
        case 4:
          time = '04:00-05:30';
          break;
        case 5:
          time = '05:00-06:30';
          break;
        case 6:
          time = '06:00-07:30';
          break;
        case 7:
          time = '07:00-08:30';
          break;
        case 8:
          time = '08:00-09:30';
          break;
        case 9:
          time = '09:00-10:30';
          break;
        case 10:
          time = '10:00-11:30';
          break;
        case 11:
          time = '11:00-12:30';
          break;
        case 12:
          time = '12:00-13:30';
          break;
        case 13:
          time = '13:00-14:30';
          break;
        case 14:
          time = '14:00-15:30';
          break;
        case 15:
          time = '15:00-16:30';
          break;
        case 16:
          time = '16:00-17:30';
          break;
        case 17:
          time = '17:00-18:30';
          break;
        case 18:
          time = '18:00-19:30';
          break;
        case 19:
          time = '19:00-20:30';
          break;
        case 20:
          time = '20:00-21:30';
          break;
        case 21:
          time = '21:00-22:30';
          break;
        case 22:
          time = '22:00-23:30';
          break;
        case 23:
          time = '23:00-00:30';
          break;
        default:
          time = '08:30-09:30';
          break;
      }
      return time;
      // }
    },
    doAfterNewSucess () {
      this.todayTableInfoList(this.seletedDate);
    },
    seletedItemAfter (item, type) {
      console.log('seletedItemAfter', item, type);
      this.searchId = item.OLAPKey;
      this.type = type;
      this.todayTableInfoList(this.seletedDate);
    },
    // 鼠标离开新建课
    closeNewCourseTable () {
      let eleArr = document.querySelectorAll('.date_block');
      eleArr.forEach(element => {
        element.classList.remove('border_red');
      });
      this.isShowNewCourseType = false;
    },
    scrollEvent () {
      this.closeNewCourseTable();
    },
    showHolidaysUspendClasses () {
      this.openCustomDialogByFactoryDialog({ moduleName: 'holidayssuspendclasses' });
    },
    // 监听页面滚动
    handleScroll () {
      this.isShowNewCourseType = false;
    },
    // 根据课ID获取单节课
    getScheduleCourseByScheduleCourseKey (scheduleCourseKey, callBack) {
      let obj = {
        startTime: this.beginDateTime,
        endTime: this.endDateTime,
        IsExportKey: 0
      };
      obj.ScheduleCourseKey = scheduleCourseKey;

      GetScheduleCourseListByDate(obj).then(result => {
        if (result.code == 0) {
          if (typeof callBack == 'function') {
            callBack(result.data[0]);
          }
        }
      });
    },
    registerBusEvent () {
      // 新建课 成功后
      this.$bus.on(this, 'AfterNewCourseTable', (type, data) => {
        if (type == 'mutil') {
          this.todayTableInfoList(this.seletedDate);
        } else {
          if (data.ClassTime <= this.endDateTime && data.ClassTime >= this.beginDateTime) {
            this.getScheduleCourseByScheduleCourseKey(data.OLAPKey, (data) => {
              this.timeTableInfoList.push(data);
            });
          }
        }
      });
      // 新建循环课成功后 批量修改排课计划
      this.$bus.on(this, 'AfterBatchEditStructuredPlanList', (courseID, classGradeID, planList) => {
        planList.forEach(obj => {
          let needUpdateCourseList = this.timeTableInfoList.filter(item => item.StructuredPlanKey == obj.OLAPKey);
          needUpdateCourseList.forEach(item => {
            item.MTeacherKeyValue = obj.MTeacherKeyValue;
            item.MTeacherKey = obj.MTeacherKey;
          });
        });
      });
      // 批量删除排课计划
      this.$bus.on(this, 'AfterBatchDeleteStructuredPlanList', (courseID, classGradeID, planList) => {
        // planList.forEach(val => {
        //   let existsIndex = this.timeTableInfoList.findIndex(obj => { return obj.StructuredPlanKey == val; });
        //   if (existsIndex >= 0) {
        //     this.timeTableInfoList.splice(existsIndex, 1);
        //   }
        // });
        this.todayTableInfoList(this.seletedDate);
      });

      // 批量停课 courseIDList 1、存在时 已停课ID列表 2、可不传（按规律停课）
      this.$bus.on(this, 'AfterBatchStopCourses', (courseNameID, classGradeID, courseIDList) => {
        if (courseIDList && courseIDList.length <= 10) { // 停的课小于10条 就本地变更 否则直接reload数据
          courseIDList.forEach(obj => {
            let findIndex = this.timeTableInfoList.findIndex(item => { return item.OLAPKey == obj.OLAPKey; });
            if (findIndex > -1) {
              this.timeTableInfoList.splice(findIndex, 1);
            }
          });
        } else {
          this.todayTableInfoList(this.seletedDate);
        }
      });
      // 批量修改课
      this.$bus.on(this, 'AfterBatchEditCourses', (courseNameID, classGradeID) => {
        this.todayTableInfoList(this.seletedDate);
      });
      // 长假停课 courseIDList 1、存在时 已停课ID列表 2、可不传（按规律停课）
      this.$bus.on(this, 'AfterHolidaysStopCourse', (startDate, endDate) => {
        console.log('长假停课:', startDate, endDate);
        if ((Date.new(startDate).getTime() <= Date.new(this.beginDateTime).getTime() && Date.new(endDate).getTime() >= Date.new(this.beginDateTime).getTime()) ||
          (Date.new(startDate).getTime() >= Date.new(this.beginDateTime).getTime() && Date.new(endDate).getTime() <= Date.new(this.endDateTime).getTime()) ||
          (Date.new(startDate).getTime() >= Date.new(this.beginDateTime).getTime() && Date.new(startDate).getTime() <= Date.new(this.endDateTime).getTime())) {
          this.todayTableInfoList(this.seletedDate);
          console.log('AfterHolidaysStopCourse:startDate, endDate:', this.seletedDate);
        }
      });
      // 修改单节课
      this.$bus.on(this, 'AfterEditCourse', (courseInfo) => {
        let findIndex = this.timeTableInfoList.findIndex(obj => { return obj.OLAPKey == courseInfo.ScheduleCourseKey; });
        if (findIndex > -1) {
          this.getScheduleCourseByScheduleCourseKey(courseInfo.ScheduleCourseKey, (data) => {
            // 不在当前显示日期区间
            if (!data) {
              this.timeTableInfoList.splice(findIndex, 1);
            } else {
              this.updateCourseStatus(data);
              this.timeTableInfoList.splice(findIndex, 1, data);
            }
          });
        }
      });
      // 删除单节课
      this.$bus.on(this, 'AfterStopCourse', (scheduleCourseKey) => {
        let findIndex = this.timeTableInfoList.findIndex(obj => { return obj.OLAPKey == scheduleCourseKey; });
        if (findIndex > -1) {
          this.timeTableInfoList.splice(findIndex, 1);
        }
      });

      // 变更考勤
      this.$bus.on(this, 'AfterChangeAttendanceStatus', (scheduleCourseKey, studenID) => {
        let findIndex = this.timeTableInfoList.findIndex(obj => { return obj.OLAPKey == scheduleCourseKey; });
        if (findIndex > -1) {
          this.getScheduleCourseByScheduleCourseKey(scheduleCourseKey, (data) => {
            this.updateCourseStatus(data);
            this.timeTableInfoList.splice(findIndex, 1, data);
          });
        }
      });

      // 批量考勤
      this.$bus.on(this, 'AfterBatchAttendance', (scheduleCourseKey) => {
        console.log('AfterBatchAttendance:scheduleCourseKey', scheduleCourseKey);
        let findIndex = this.timeTableInfoList.findIndex(obj => { return obj.OLAPKey == scheduleCourseKey; });
        if (findIndex > -1) {
          this.getScheduleCourseByScheduleCourseKey(scheduleCourseKey, (data) => {
            this.updateCourseStatus(data);
            this.timeTableInfoList.splice(findIndex, 1, data);
          });
        }
      });

      // 添加学生进课
      this.$bus.on(this, 'AfterStudentAddToCourse', (scheduleCourseKey, studentKey) => {
        let findIndex = this.timeTableInfoList.findIndex(obj => { return obj.OLAPKey == scheduleCourseKey; });
        this.getScheduleCourseByScheduleCourseKey(scheduleCourseKey, (data) => {
          // 不在当前显示日期区间
          if (data) {
            this.updateCourseStatus(data);
            this.timeTableInfoList.splice(findIndex, 1, data);
          }
        });
      });
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'CourseTable') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'CourseTable') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    // 停课
    courseSuspend (data) {
      console.log('课程表停课1111111');
      let that = this;
      layer.confirm('是否停课', {
        btn: [{
          name: '取消',
          callBack: function () {
            layer.close();
          }
        }, {
          name: '确认',
          callBack: function () {
            that.setCourseCancel(1, data.CourseNameKey);
            layer.close();
          }
        }]
      });
    },
    setCourseCancel (IsNotEndKey, key) {
      PauseScheduleCourse(this, key, IsNotEndKey).then(result => {
        layer.alert('停课成功');
        this.$emit('refresh');
      }, error => {
        if (error.code == 70001) {
          layer.confirm(error.msg, {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.setCourseCancel(0, 0);
                  layer.close();
                }
              }
            ]
          });
        } else {
          layer.alert(error.msg);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.courseTableDialogFactory) {
        this.$refs.courseTableDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.courseTableDialogFactoryCommon) {
        this.$refs.courseTableDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>
<style scoped>
.swiper-slide {
  width: 14% !important;
}
.paddingShow {
  padding-top: 66px !important;
}
.paike >>> .el-table .el-table__cell {
  padding: 0 !important;
}
.listBox {
  width: 162px;
  /* display: inline-block; */
  float: left;
  display: flex;
  /* height: 40px; */
  height: 30px;
  border-radius: 3px;
  border: 1px solid #3498db;
  text-align: center;
  line-height: 26px;
  margin: 18px 10px 0;
  align-items: center;
  justify-content: space-around;
  flex-wrap: nowrap;
}
.listBoxText {
  padding: 0 10px;
  border-radius: 4px;
  cursor: pointer;
}
.listBoxTextOpt {
  background: #3498db;
  color: #fff;
}
.course_table_top_fixed {
  background-color: #fff !important;
  width: 100% !important;
  position: absolute !important;
  top: -20px !important;
  left: 0px !important;
  margin-left: 0 !important;
  z-index: 7 !important;
  padding-left: 0 !important;
}
.course_table_top_fixed_shadow,
.content_swiper {
  width: 100% !important;
}
.course_table_content {
  height: 1500px;
  overflow-y: auto;
  width: 100% !important;
}
.header_swiper {
  float: none !important;
  width: auto !important;
}
</style>

