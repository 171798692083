<template>
  <div class="general_summarizing_student">
    <div class="general_summarizing_student_navUl">
      <div
        class="title_list"
        @click="tableSwitch(1)"
        :class="{ opt: tableType == 1 }"
      >
        营收日报
      </div>
      <div
        class="title_list"
        @click="tableSwitch(2)"
        :class="{ opt: tableType == 2 }"
      >
        营收月报
      </div>
      <div
        class="title_list"
        @click="tableSwitch(3)"
        :class="{ opt: tableType == 3 }"
      >
        课时课消
      </div>
      <div
        class="title_list"
        @click="tableSwitch(4)"
        :class="{ opt: tableType == 4 }"
      >
        年月日卡扣费
      </div>
      <div
        class="title_list"
        @click="tableSwitch(5)"
        :class="{ opt: tableType == 5 }"
      >
        课时过期
      </div>
      <div
        class="title_list"
        @click="tableSwitch(6)"
        :class="{ opt: tableType == 6 }"
      >
        异常课消
      </div>
      <div
        class="title_list"
        @click="tableSwitch(7)"
        :class="{ opt: tableType == 7 }"
      >
        杂费收入
      </div>
      <div
        class="title_list"
        @click="tableSwitch(8)"
        :class="{ opt: tableType == 8 }"
      >
        退课差额
      </div>
    </div>
    <div class="payment_statistics_bottom clearfix">
      <!-- <content-report></content-report> -->
      <div v-if="tableType == 1">
        <revenue-day-report @toTableType="tableSwitch"></revenue-day-report>
      </div>
      <div v-if="tableType == 2">
        <revenue-month-report @toTableType="tableSwitch"></revenue-month-report>
      </div>
      <div v-if="tableType == 3">
        <course-class-report :toSearchObj="doSearchObj"></course-class-report>
      </div>
      <div v-if="tableType == 4">
        <semester-card-report :toSearchObj="doSearchObj"></semester-card-report>
      </div>
      <div v-if="tableType == 5">
        <course-overdue-report
          :toSearchObj="doSearchObj"
        ></course-overdue-report>
      </div>
      <div v-if="tableType == 6">
        <course-abnormal-report
          :toSearchObj="doSearchObj"
        ></course-abnormal-report>
      </div>
      <div v-if="tableType == 7">
        <incidentals-income-report
          :toSearchObj="doSearchObj"
        ></incidentals-income-report>
      </div>
      <div v-if="tableType == 8">
        <other-income-report :toSearchObj="doSearchObj"></other-income-report>
      </div>
    </div>
  </div>
</template>
<script>
import revenueDayReport from './table-list/revenue-day-report';
import revenueMonthReport from './table-list/revenue-month-report';
import courseClassReport from './table-list/course-class-report';
import courseOverdueReport from './table-list/course-overdue-report';
import courseAbnormalReport from './table-list/course-abnormal-report';
import incidentalsIncomeReport from './table-list/incidentals-income-report';
import otherIncomeReport from './table-list/other-income-report';
import semesterCardReport from './table-list/semester-card-report';
export default {
  data () {
    return {
      tableType: 1,
      doSearchObj: {}
    };
  },
  components: {
    revenueDayReport,
    revenueMonthReport,
    courseClassReport,
    courseOverdueReport,
    courseAbnormalReport,
    incidentalsIncomeReport,
    otherIncomeReport,
    semesterCardReport
  },
  created () {
    console.log(this.$route.query, 'this.$route.query');
    if (Number(this.$route.query.type)>0) {      // type 为1 默认打开考勤明细.
      this.tableType=this.$route.query.type
    }
  },
  activated () {
    console.log('activated');
   if (Number(this.$route.query.type)>0) {      // type 为1 默认打开考勤明细.
      this.tableType=this.$route.query.type
    }
  },
  methods: {
    tableSwitch (type, optDataTime) {
      if (optDataTime) {
        let arr = optDataTime.split('-');
        console.log(arr.length, 'arrs');
        if (arr.length == 3) {
          this.doSearchObj.startTime = optDataTime;
          this.doSearchObj.endTime = optDataTime;
        } else if (arr.length == 2) {
          this.doSearchObj.startTime = arr[0] + '-' + arr[1] + '-01';
          console.log(
            this.$utils.getDataMonthLast(Date.new(arr[0], arr[1], 0)),
            'this.$utils.getDataMonthLast(Date.new(optDataTime)'
          );
          this.doSearchObj.endTime = this.$utils.getDataMonthLast(
            Date.new(arr[0], arr[1], 0)
          );
        }
      } else {
        this.doSearchObj = {};
      }
      this.tableType = type;
    }
  }
};
</script>