<template>
  <!-- active 选中当前 -->
  <div class="single_result single_class_info"
       @click.stop="selectedItem"
       :class="{active:item.isSelected}">
    <div class="single_result_info_detail  ">
      <div class="single_class_name">
        <span>{{item.StartTime}} {{item.ApplyCorName}}</span>
        <span class="list_name_type"
              v-if="Number(item.ActualPayAmount)==0">{{vApplyTypeKeyValue}}</span>
      </div>
      <div class="custom_font_gray">
        剩余{{item.UnitKey==5?'课时':'天数'}}: {{item.UnitKey==5?Number(item.CurrentCount):Number(item.PeriodCount)}}{{item.UnitKey==5?'课时':'天'}}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'addressItem',
  data () {
    return {
    };
  },
  props: {
    item: Object
  }, // 单选数据项 需绑定到界面
  created () {
  },
  computed: {
    vApplyTypeKeyValue () {
      if (Number(this.item.ActualPayAmount) == 0) {
        return '赠送';
      } else {
        return '';
      }
    }
  },
  methods: {
    selectedItem () {
      this.$emit('onSelectedItem', this.item);
    }
  }
};
</script>
<style scoped>
.single_class_info {
  position: relative;
}
.custom_font_gray {
  color: #666;
  font-size: 12px;
  padding-top: 5px;
}
.list_name_type {
  display: inline-block;
  vertical-align: top;
  width: 35px;
  text-align: center;
  line-height: 16px;
  font-size: 14px;
  color: #ff8c39;
  background-color: #fffbe6;
  margin-left: 10px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid #ffe58f;
  font-weight: normal;
}
</style>