<template>
  <div class="course_week_list no_pointer">
    <div @click.stop="clickItem"
         class="button">
      <div class="list_icon  fl"
           :class="{opt:courseItem.isSelected}"></div>
      <div class="fl">
        <span>{{vDay}}</span>
        <span class="list_date">{{vWeekValue}} {{courseItem.courseInfo.BeginClassTime}}</span>
        <span class="text_overflow_hidden"
              style="max-width: 180px;
    display: inline-block;
    vertical-align: top;">{{courseItem.courseInfo.CourseNameKeyValue}}</span>
      </div>
    </div>
    <div class="list_btn btn_hover_blue fr"
         @click.stop="changeCourse">
      调课
    </div>
  </div>
</template>

<script>
export default {
  props: {
    courseItem: Object,
    monthItem: Object
  },
  computed: {
    vDay () {
      let day = Date.new(this.courseItem.courseInfo.ClassTime).getDate();
      return (day < 10 ? '0' + day : day) + '日';
    },
    vWeekValue () {
      return this.$utils.getWeekByTwo(this.courseItem.courseInfo.ClassTime);
    }
  },
  methods: {
    clickItem () {
      this.courseItem.isSelected = !this.courseItem.isSelected;
      this.$emit('onClickItem', this.monthItem, this.courseItem);
    },
    changeCourse () {
      this.$emit('changeCourse', this.courseItem);
    }
  }
};
</script>
<style>
</style>