<template>
  <div class="summarizing_list">
    <table-list :tableData="vTableInfoList"
                :tableColumns="tableColumns"
                :totalNum="totalNum"
                :queryParams="params"
                :isShowCheckBox="false"
                @loadTableData="getDataInfo"></table-list>
    <custom-dialog :title="'拒绝原因'"
                   :visible.sync="isShowMessagePopup"
                   :before-close="hideMessagePopup">
      <remerk-popup :placeholder="'请输入拒绝原因'"
                    @onClickClose="hideMessagePopup"
                    @onClickConfirm="rejectRecommend"></remerk-popup>
    </custom-dialog>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
import remerkPopup from '../remerk-popup';
import { SearchPageYKRecommendToBeReceive, YKRecommendManageReceive, YKRecommendManageRefuse, GetOnJobEmployees } from '../../../../API/workbench.js';
export default {
  data () {
    return {
      isShowMessagePopup: false,
      isShowCounselorList: false,
      selectInfo: {},
      dataInfoList: [],
      totalNum: 0,
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          type: 'table-index',
          className: 'table_index_gray',
          sortable: false,
          width: 48
        },
        {
          label: '推荐日期',
          prop: 'RecommendTime',
          width: 100,
          type: 'date-item',
          sortable: 'custom'
        },
        {
          label: '投资人',
          prop: 'MainDemoName',
          type: 'text-item',
          width: 95
        },
        {
          label: '手机号',
          prop: 'MobilePhone',
          type: 'text-item',
          width: 120
        },
        {
          label: '计划城市',
          prop: 'CityKeyValue',
          type: 'text-item',
          width: 130
        },
        {
          label: '加盟方式',
          prop: 'FranchiseTypeKeyValue',
          type: 'text-item',
          width: 96
        },
        {
          label: '投资预算',
          prop: 'BudgetAmount',
          type: 'text-item',
          width: 96
        },
        {
          label: '其他',
          prop: 'Other',
          type: 'text-item',
          width: 330
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          className: 'btn_dual',
          extend: {
            tableBtnGroup: [
              {
                name: '接收',
                extend: {
                  click: (rowData) => {
                    this.openCounselorList(rowData);
                  }
                }
              },
              {
                name: '拒绝',
                extend: {
                  click: (rowData) => {
                    this.openMessagePopup(rowData);
                  }
                }
              }
            ]
          }
        }
      ]
    };
  },
  props: {
    params: Object
  },
  components: {
    tableList, remerkPopup
  },
  computed: {
    vTableInfoList () {
      let arr = [];
      this.dataInfoList.forEach((e) => {
        e.BudgetAmount = Number(e.StratBudgetAmount) + '-' + Number(e.EndBudgetAmount) + '万';
        e.Other = e.SexKeyValue + ' ' + e.Age + '岁';
        e.CityKeyValue = e.ProvinceKeyValue + '·' + e.CityKeyValue;
        e.MobilePhone = String(e.MobilePhone).replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2');
        arr.push(e);
      });
      return arr;
    }
  },
  created () {
    this.getDataInfo();
  },
  methods: {
    openCounselorList (item) {
      this.selectInfo = item;
      GetOnJobEmployees().then(result => {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择招商顾问',
          moduleName: 'singleSelectionList',
          data: { dataSourceList: result.data, required: false, ModulePowerKey: 0 },
          callBack: {
            onSelectedItem: (data) => {
              this.resultRecommend(data);
            }
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    openMessagePopup (item) {
      this.selectInfo = item;
      this.isShowMessagePopup = true;
    },
    hideCounselorList () {
      this.isShowMessagePopup = false;
    },
    resultRecommend (item) {
      YKRecommendManageReceive(this.selectInfo.OLAPKey, item.OLAPKey, item.MainDemoName).then(res => {
        this.$emit('refreshListInfo');
      }, err => {
        layer.alert(err.msg);
      });
    },
    rejectRecommend (text) {
      this.hideMessagePopup();
      YKRecommendManageRefuse(this.selectInfo.OLAPKey, text).then(res => {
        this.$emit('refreshListInfo');
      }, err => {
        layer.alert(err.msg);
      });
    },
    hideMessagePopup () {
      this.isShowMessagePopup = false;
    },
    getDataInfo () {
      SearchPageYKRecommendToBeReceive(this.params).then(
        (res) => {
          this.totalNum = res.data.Total;
          this.dataInfoList = res.data.PageDataList;
          this.$emit('upDataInfo', res.data);
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    }
  }
};
</script>