<template>
  <div>
    <div class="content_marginRL content_marginTop_30 customer_registration">
      <div class="customer_registration_content form_info_edit"
           id="customer_registration">
        <div class="form_info_edit mendian_setting_content single_shadow">
          <!-- 基础信息 -->
          <div class="customer_registration_info customer_registration_info_more">
            <div id="screanContent"
                 style="
                z-index: 1;
                position: absolute !important;
                right: 45px;
                opacity: 0;
              "></div>
            
            <input-upload-img-file :ImgURl="dataInfo.LogoUrl ? dataInfo.LogoUrl : ''"
                                   :formTitle="'教学模式Logo'"
                                   :saaSClientNameKey="vMenDianInfo.OLAPKey"
                                   :readonly="readonly"
                                   :required="true"
                                    :imgMaxWidth="0"
                                    :helpDescription="'图片尺寸216*216'"
                                   :imgstyle="'width: 20px!important;height: 20px;z-index:10;'"
                                   :placeholderText="'216*216'"
                                   @doAfterUpLoadImgSuccess="doSetLogoPath"></input-upload-img-file>
             <input-upload-img-file :ImgURl="dataInfo.TeachingBackground ? dataInfo.TeachingBackground : ''"
                                   :formTitle="'教学模式背景图'"
                                   :saaSClientNameKey="vMenDianInfo.OLAPKey"
                                   :readonly="readonly"
                                   :required="true"
                                   :imgMaxWidth="0"
                                      :helpDescription="'图片尺寸1920*1080'"
                                   :placeholderText="'1920*1080'"
                                   :imgstyle="'width: 20px!important;height: 20px;z-index:10;'"
                                   @doAfterUpLoadImgSuccess="doSetTeachingBackground"></input-upload-img-file>
          </div>
        </div>
        <!-- 按钮组 -->
        <div v-if="!readonly">
          <save-cancel-btn-group :isSingleSubmit="true"
                                 :singleSubmitType="'dynamic'"
                                 @confirmClick="confirmClick"
                                 @cancelClick="cancelClick"></save-cancel-btn-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  GetSaaSClientSet,
  CoursewareSaaSClientSetting,
} from "../../../../../API/workbench.js";
export default {
  data () {
    return {
      
      dataInfo: {
        LogoUrl: "", //"教学模式Logo",
        TeachingBackground: '',  //教学模式背景图
      },
      setDataInfo:[]

    };
  },
  components: {},
  props: {
    isEdit: Boolean,
    readonly: Boolean,
  },
  created () {
    this.getMenDianSet();

  },
  mounted () { },
  computed: {
     vMenDianInfo() {
      // TimeScopeCount
      return this.$store.getters.SaaSClientInfo;
    },
  },
  methods: {
 
    // 获取门店信息
    getMenDianSet () {
      this.setDataInfo=[]
      GetSaaSClientSet().then(
        (result) => {
        //   this.dataInfo = result.data;
          result.data.forEach(o=>{
           
            if(o.SetTypeKey==117){
                this.dataInfo.TeachingBackground=o.SetContent
                this.setDataInfo.push(o)
            }
            if(o.SetTypeKey==118){
                
                  this.dataInfo.LogoUrl=o.SetContent
                this.setDataInfo.push(o)
            }
          })
          console.log(this.setDataInfo, "GetSaaSClientSet");
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },

    doSetLogoPath (Url) {
      this.dataInfo.LogoUrl = Url;
    },
 
    doSetTeachingBackground (Url) {
      this.dataInfo.TeachingBackground = Url;
    },
    // 保存
    confirmClick (callBack) {
      let res = this.validatestaffItemInfo();
      
      if (!res) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.setDataInfo.forEach(o=>{
        if(o.SetTypeKey==117){
            o.SetContent=this.dataInfo.TeachingBackground
         
        }
        if(o.SetTypeKey==118){
           o.SetContent= this.dataInfo.LogoUrl
        }
      })
      this.confirmData(callBack);
    },
    confirmData (callBack) {
      console.log(this.setDataInfo, "this.confirmData");

        CoursewareSaaSClientSetting( this.setDataInfo)
          .then(
            (result) => {
              this.$emit("afterSuccess");
              this.cancelClick();
              layer.alert("设置成功");
            },
            (error) => {
              layer.alert(error.msg);
            }
          )
          .finally(() => {
            if (callBack) {
              callBack();
            }
          });
      
    },
    // 验证客户信息
    validatestaffItemInfo () {
      let flag = true;
      if (!this.dataInfo.LogoUrl) {
        layer.alert("请上传教学模式Logo");
        flag = false;
      } else if (!this.dataInfo.TeachingBackground) {
        layer.alert("请上传教学模式背景图");
        flag = false;
      }
      return flag;
    },
    // 取消
    cancelClick () {
      this.$emit("close");
    },
  },
};
</script>
