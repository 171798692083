<template>
  <div class="content_course_box">
    <div class="course_box_title">
      <div class="course_box_title_icon">课</div>
      <span>{{item.CourseNameKeyValue}}</span>
    </div>
    <div class="course_box_ul">
      <gradeItemInCourse v-for="gradeItem in vClassList"
                         :key="gradeItem.GradeClassKey"
                         :item="gradeItem"
                         :searchText="searchText"
                         :isTemporaryStu="isTemporaryStu"
                         :copyData="copyData"
                         :seletedStructuredRuleKey="seletedStructuredRuleKey"></gradeItemInCourse>
    </div>
  </div>
</template>
<script>
import gradeItemInCourse from './grade-item-in-course';
export default {
  data () {
    return {

    };
  },
  components: {
    gradeItemInCourse
  },
  props: {
    item: Object,
    searchText: String,
    isTemporaryStu: {
      type: Boolean,
      default: false
    },
    seletedStructuredRuleKey: Number,
    copyData: {
      type: Object,
      default: {
        StructuredRuleKey: 0,
        copyStudentList: []
      }
    }
  },
  computed: {
    vClassList () {
      let newArr = [];
      this.item.classList.forEach(o => {
        let obj = {
          GradeClassKey: o.GradeClassKey,
          GradeClassKeyValue: o.GradeClassKeyValue,
          CourseNameKey: o.CourseNameKey,
          CourseNameKeyValue: o.CourseNameKeyValue,
          rulesList: [],        // 班级列表
          studentList: o.studentList
        };
        obj.rulesList.push(o);
        let locaIndex = newArr.findIndex(p => {       // 分类课程.
          return p.GradeClassKey == o.GradeClassKey;
        });
        if (locaIndex == -1) {
          newArr.push(obj);
        } else {
          newArr[locaIndex].rulesList.push(o);
        }
      });
      return newArr;
    }
  }
};
</script>
