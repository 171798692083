<template>
  <div class="teaching_mode_search"
       style="z-index:8">
    <div class="teaching_mode_cousewarepackge">
      <div class="teaching_mode_header">
        <div class="teaching_mode_header_left teaching_mode_header_hover_img"
             @click="goBack">
          <img src="../../../../public/image/teaching_mode_back.png"
               alt=""
               style="width:100%">

        </div>
        <div class="teaching_mode_header_middle_search"
             style="margin-left:-30rem">
          <div class="student_info_supplement form_info">
            <div class="defult_dialog_searchform">
              <!-- 搜索框 -->
              <div class="search_form">
                <input type="text"
                       ref="inputSearch"
                       v-model="searchText"
                       class="search_input"
                       :placeholder="'请输入课件名搜索'" />
                <!-- <span v-if="searchText.length > 0"
                                class="remove_btn"
                                @click.stop="clearSearText"></span> -->
                <span class="search_btn"
                      @click.stop="search"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="teaching_mode_header_right">

        </div>
      </div>

      <div>
        <child-courseware-list :dataList="coursewareList"
                               :searchEnd="searchEnd"
                               :isSearch="true"
                               :dataInfo="dataInfo"
                               @seletedItem="seletedCWItem">

        </child-courseware-list>
      </div>

    </div>

  </div>
</template>
<script>
import {
  TeachingModegetSearchCourseware
} from '../../../API/workbench';
import childCoursewareList from './child-courseware-list'
export default {
  data () {
    return {
      searchText: '',
      coursewareList: [],
      searchEnd: false
    }
  },
  components: {
    childCoursewareList
  },
  props: {
    dataInfo: Object,
    seletedOLAPKey: [Number, String]
  },
  computed: {
    // 判断是校长还是老师
    vRoleKey () {
      console.log(Number(this.$store.getters.token.RoleKey), "校长and老师");
      return this.$store.getters.token.RoleKey ? Number(this.$store.getters.token.RoleKey) == 2 : false; // 2为校长；非则为老师
    },
  },
  created () {
    // this.getCoursewareList()

  },
  mounted () {
    this.$refs.inputSearch.focus()
  },
  methods: {
    search () {
      this.getCoursewareList()
    },
    // 根据大课包及子课包ID获取课件list
    seletedCWItem () {

    },
    clearSearText () {
      this.searchText = ''
      this.getCoursewareList()
    },
    getCoursewareList () {
      let teacherKey = this.vRoleKey ? '' : this.$store.getters.token.UserID
      this.dataInfo.OLAPKey = this.dataInfo.OLAPKey || 0
      if (this.dataInfo.OLAPKey) {
        TeachingModegetSearchCourseware(this.dataInfo.OLAPKey, this.searchText, teacherKey).then(result => {
          console.log("getCoursewareList", result.data)
          this.coursewareList = result.data
          this.searchEnd = true
        })
      }
    },
    goBack () {
      this.$emit("closeDialog")
    },


  }
}
</script>
<style scoped>
.teaching_mode_search {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: url('../../../../public/image/teaching_mode_bg.png') center
    no-repeat;
  background-size: 100%;
}
.teaching_mode_cousewarepackge {
  /* backdrop-filter: blur(1.95rem); */
}
.teaching_mode_header {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 19.5rem;
  justify-content: space-between;
  z-index: 2;
}
.teaching_mode_header_left {
  display: flex;
  align-items: center;
  margin-left: 3.12rem;
}
.teaching_mode_header_hover_img:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(208.99deg, #424b59 13.46%, #272f3a 86.66%);
  box-shadow: 0px 5px 20px rgba(22, 131, 249, 0.7);
}
.teaching_mode_header_hover_img {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(208.99deg, #424b59 13.46%, #272f3a 86.66%);
  /* box-shadow: 0px 5px 20px rgba(41, 49, 61, 0.3); */
  border-radius: 32.57rem;
  width: 28.86rem;
  height: 28.86rem;
  overflow: hidden;
  text-align: center;
  line-height: 28rem;
  cursor: pointer;
  /* margin: auto; */
}
/* .teaching_mode_header_middle{
  line-height: 28rem;
  font-size: 14.04rem;
  color: #FFFFFF;
} */
.teaching_mode_search .teaching_mode_header_right {
  width: 0rem;
  height: 37.44rem;
  display: flex;
  flex-direction: row;
  /* border-radius: 7.8rem; */
  /* overflow: hidden; */
}
.teaching_mode_header_right .teaching_mode_location {
  line-height: 26rem;
}
.teaching_mode_header_right .teaching_mode_location img {
  width: 11.29rem;
  height: 13.65rem;
}
.teaching_mode_header_right div {
  margin-right: 12rem;
}
.teaching_mode_header_right img {
  width: 14.56rem;
  height: 12.13rem;
}
/* 搜索框 */

.teaching_mode_header_middle_search .defult_dialog_searchform {
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  /* z-index: 10; */
  padding: 6.24rem 7.8rem;
  /* box-shadow: 0 2px 4px 0px #e7e7e7; */
  /* background-color: #fff; */
  /* border-radius: 8px; */
  overflow: hidden;
  position: relative;
  width: 195rem;
  height: 31.2rem;
  background: #00000073;
  /* opacity: 0.3; */
  box-shadow: inset 0px 1.17rem 2.34rem rgba(0, 0, 0, 0.5);
  border-radius: 6.24rem;
}

.teaching_mode_header_middle_search .search_form {
  height: 100%;
  /* padding: 3.9rem 5.85rem; */
  border-radius: 1.56rem;
  padding: 0px;
  /* border: .39rem solid #ececec; */
  border: none !important;
  position: relative;
}

.teaching_mode_header_middle_search .search_form .search_input {
  width: 100%;
  /* padding-left: 7.8rem; */
  height: 100% !important;
  font-size: 13.04rem;
  padding-left: 3.9rem;

  /* background-color: transparent; */
  /* background-image: url(../../../../public/image/search_icon01.png); */
  /* background-size: 5.07rem;
  background-position: left center;
  background-repeat: no-repeat; */
  background: none !important;
  color: white;
}
.teaching_mode_header_middle_search .defult_dialog_searchform .remove_btn {
  position: absolute;
  right: 15.6rem;
  margin-top: -3.12rempx;
  display: inline-block;
  width: 8rem;
  height: 8rem;
  background-image: url(../../../../public/image/dialog_del_icon.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 8rem;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.teaching_mode_header_middle_search .defult_dialog_searchform .search_btn {
  position: absolute;
  right: -3rem;
  margin-top: -1.5rem;
  display: inline-block;
  width: 22rem;
  height: 22rem;
  background-image: url(../../../../public/image/teaching_mode_search2.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 15rem;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.teaching_mode_header_middle_search
  .defult_dialog_searchform
  .search_btn:hover {
  background-image: url(../../../../public/image/teaching_mode_search_hover.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 15rem;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
</style>