<template>
  <!-- 待处理列表 -->
  <div class=" table_list_content pr summarizing_list pending_processed">
    <div class="single_result_detail">
      <!-- 搜索 -->
      <div class="table_select_box"
           style="padding: 20px 0px;">
        <inputSearchContainBtn v-model="queryParams.searchText"
                               :width="'280px'"
                               :placeholder="'请输入校区名'"
                               @onSearchEvent="search"
                               @clearSearch="clearExtendSearchParams"></inputSearchContainBtn>
        <div class="heardItem">
          <a class="el-button btn_light_blue btn_hover_bg_blue"
             :href="encodeURI($store.getters.PCWebURL + '/ImportTemplate/校区资源导入模板.xlsx')" style="margin-right:10px">下载模板</a>
          <function-btn :item="{ name: '添加校区资源', ModulePowerKey: '' }"
                        :className="'el-button btn_light_blue btn_hover_bg_blue iconPlanC'"
                        style="width: 106px;margin-right:10px"
                        
                        @clickItem="addCampusResource">
          </function-btn>
          <div class="el-button btn_light_blue btn_hover_bg_blue"
               style="padding:0;width: 100px;">
            <el-upload accept=".xls, .xlsx"
                       action="string"
                       ref="upload"
                       :show-file-list="false"
                       :http-request="importEvent">导入校区资源</el-upload>
          </div>

          <!-- <function-btn :item="{ name: '导入校区资源', ModulePowerKey: '' }"
                        :className="'el-button btn_light_blue btn_hover_bg_blue iconPlanC'"
                        style="width: 106px;"
                        @clickItem="">
          </function-btn> -->
        </div>
      </div>
      <!-- 通用列表 -->
      <table-list ref="tableListRef"
                  :tableData="mendianList"
                  :tableColumns="tableColumns"
                  :rowKey="'Number'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  @loadTableData="getAddressLogListData"></table-list>
    </div>
    <div class="course_student_attendance_operation module_content_menuList"
         :style="{'top':topValue+'px','left':'auto','right': 4+'px'}"
         v-if="isShowOperateBtn">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="operateBtnList"
                     @clickOptionForAlertDialog="onOperateClick"></drop-down-box>
    </div>
    <!-- 显示加载的进度条 -->
    <custom-dialog class="progress"
                   width="300px"
                   :visible.sync="showProgress">
      <submit-progress-tip :tips="'正在批量'+ProgressHintText"></submit-progress-tip>
    </custom-dialog>

    <custom-dialog :title="titleName"
                   width="400px"
                   :before-close="closeUpdateCampusResourcePopup"
                   :visible.sync="showUpdateCampusResource">
      <update-campus-resource :isReadonly="false"
                              :dataInfo="updateData"
                              @close="closeUpdateCampusResourcePopup"
                              @afterSuccess="search"></update-campus-resource>
    </custom-dialog>
    <custom-dialog :title="'添加直营校区'"
                   :visible.sync="isShowParticipateCampusFormPopup"
                   :before-close="closeParticipateCampusFormPopup">
      <retail-campus-edit-form :isReadonly="false"
                               :titleName="'添加'"
                               :newData="updateData"
                               @close="closeParticipateCampusFormPopup"
                               @afterSuccess="search"></retail-campus-edit-form>
    </custom-dialog>
    <custom-dialog :title="'添加加盟校区'"
                   :visible.sync="isShowFranchiseeParticipateCampusFormPopup"
                   :before-close="closeFranchiseeParticipateCampusFormPopup">
      <franchisee-campus-edit-form :newData="updateData"
                                   :isShowInput="true"
                                   :titleName="'添加'"
                                   :isReadonly="false"
                                   @close="closeFranchiseeParticipateCampusFormPopup"
                                   @afterSuccess="search"></franchisee-campus-edit-form>
    </custom-dialog>
    <custom-dialog :title="'添加课程合作'"
                   :visible.sync="isShowCampusFormPopup"
                   :before-close="closeCampusFormPopup">
      <campusEditForm :newData="updateData"
                      :titleName="'添加'"
                      :isReadonly="false"
                      @close="closeCampusFormPopup"
                      @afterSuccess="search"></campusEditForm>
    </custom-dialog>
  </div>
</template>
  <script>
import { MessageSearchPageCampusResource, ImportCampusResource, DeleteCampusResource, GetAllCoursewarePackage } from '../../../../API/workbench.js';
import tableList from '../../../common/table-list/index';
import dropDownBox from '../../../common/drop-down-box';
import inputSearchContainBtn from '../../../report/common/input-search-contain-btn.vue';
import updateCampusResource from './update-campus-resource.vue';
import retailCampusEditForm from '../../headquarters-management/retail-management/form/retail-campus-edit-form';
import franchiseeCampusEditForm from '../../headquarters-management/franchisee-management/form/franchisee-campus-edit-form';
import campusEditForm from '../../basis-setting-module/form/add-campus/index';
export default {
  data () {
    return {
      isShowCampusFormPopup: false,
      isShowFranchiseeParticipateCampusFormPopup: false,
      isShowParticipateCampusFormPopup: false,
      showUpdateCampusResource: false,
      updateData: null,
      titleName: '添加校区',
      showProgress: false, // 显示进度条状态
      ProgressHintText: '',
      selectedStateScreenAccount: '全部',
      // 查询参数
      queryParams: {
        pageIndex: 0, //	整型	可选		数据源：GET	页码
        pageSize: 10, //	整型	可选		数据源：GET	分页大小
        searchText: '', //	字符串	可选		数据源：GET	关键字
        orderBy: '',
        sequence: '',
        TypeKey: ''
      },
      PortWarningCount: 0,
      locationInfo: [
        { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '14' }, // 定位地址信息
        { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '15' }, // 定位经纬度信息
        { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '16' }// 定位截图信息
      ],
      nowRowData: {},
      mendianList: [], // 门店列表
      operateBtnList: [],
      topValue: 0,
      tableColumnsData: [],
      tableColumns: [
        {
          label: '校区名',
          prop: 'MainDemoName',
          width: 100,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '校区类型',
          width: 100,
          align: 'left',
          prop: 'TypeKeyValue',
          type: 'text-item',
          sortable: false
        },
        {
          label: '管理员',
          prop: 'PrincipalName',
          align: 'left',
          width: 100,
          type: 'text-item',
          sortable: false
          // extend: {
          //   className: 'sta_saa'
          // }
        },
        {
          label: '管理员手机',
          prop: 'MobilePhone',
          align: 'left',
          width: 120,
          type: 'text-item',
          sortable: false
          // extend: {
          //   className: 'sta'
          // }
        },
        {
          label: '详细地址',
          prop: 'Address',
          align: 'left',
          type: 'text-item',
          width: 280,
          sortable: false
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: () => {
                  return '删除';
                },
                extend: {
                  click: (rowData) => {
                    this.deleteCampusResource(rowData);
                  },
                  ModulePowerKey: ''
                }
              },
              {
                name: () => {
                  return '修改';
                },
                extend: {
                  click: (rowData) => {
                    this.updateCampusResource(rowData);
                  },
                  ModulePowerKey: ''
                }
              },
              {
                name: () => {
                  return '开端口';
                },
                extend: {
                  click: (rowData) => {
                    this.openCampusResource(rowData);
                  },
                  ModulePowerKey: ''
                }
              }
            ]
          }
        }
      ],
      pageTotal: 0,
      totalNum: 0,
      defaultSort: { prop: 'studentInfo', order: 'ascending' },
      isShowOperateBtn: false,
      isShowLocationForm: false
    };
  },
  components: {
    tableList,
    dropDownBox,
    inputSearchContainBtn,
    updateCampusResource,
    retailCampusEditForm,
    franchiseeCampusEditForm,
    campusEditForm
  },
  props: {
  },
  computed: {
    vAutoProcessed () {
      return this.$utils.getSaaSClientSetInfo(87).SetKey == 1;
      // return true;
    }
  },
  watch: {

  },
  mounted () {
    this.getAddressLogListData(this.queryParams);
    document.querySelector('body').addEventListener(
      'click',
      (e) => {
        this.isShowOperateBtn = false;
      },
      false
    );
  },
  created () {
  },
  methods: {
    // 状态筛选
    chgSearchValue (item) {
      console.log(item, 'item');
      this.queryParams.TypeKey = item.OLAPKey;
      this.selectedStateScreenAccount = item.MainDemoName;
      this.getAddressLogListData(this.queryParams);
    },
    // 处理列表
    getAddressLogListData (queryParams) {
      queryParams.searchText = queryParams.searchText.trim();
      MessageSearchPageCampusResource(queryParams).then(result => {
        this.isShowOperateBtn = false;
        this.totalNum = result.data.Total; // 分页数
        this.mendianList = result.data.PageDataList || [];
        this.PortWarningCount = result.data.PortWarningCount;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 搜索
    search (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.queryParams.pageIndex = 0;
      this.getAddressLogListData(this.queryParams);
    },
    // 清除条件
    clearExtendSearchParams () {
      this.queryParams.TypeKey = 0;
      this.selectedStateScreenAccount = '全部';
      this.queryParams.pageIndex = 0;
      this.queryParams.searchText = '';
      this.queryParams.pageSize = 10;
      this.getAddressLogListData(this.queryParams);
    },
    // 排序
    loadTableData (queryParams) {
      console.log(queryParams, '排序');
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.getAddressLogListData(this.queryParams);
    },

    // 操作菜单
    onOperateClick (item) {
      // this.threadInfo = this.$utils.parseJson(rowData);
      // this.threadInfo.SaaSClientTypeKey = 2;
      this.isShowOperateBtn = false;
      console.log(item, this.nowRowData, '操作菜单');
      switch (item.type) {
        case 'pass':// 审核通过
          console.log('审核通过');
          this.pass();
          break;
        case 'refuse':// 审核拒绝
          console.log('审核拒绝');
          this.refuse();
          break;
        case 'check':// 审核拒绝
          console.log('查看');
          this.check();
          // this.$emit('goCheck');
          break;
        default:
          break;
      }
    },
    closeLocationFormPopup () {
      this.isShowLocationForm = false;
    },
    showLocationFormPopup (rowData, type) {
      if (type == 'new') {
        if (rowData.Location == null || rowData.Location == '') {
          return;
        }
        this.locationInfo[1].SetContent = [rowData.Longitude, rowData.Latitude];
      } else {
        if (rowData.PrimaryLocation == null || rowData.PrimaryLocation == '') {
          return;
        }
        this.locationInfo[1].SetContent = [rowData.PrimaryLongitude, rowData.PrimaryLatitude];
      }
      this.isShowLocationForm = true;
    },
    downloadMould () {
      window.location.href = this.$store.getters.CDNURL + '/ImportTemplate/校区资源导入模板.xlsx';
    },
    // 导入
    importEvent (param) {
      this.showProgress = true;
      this.ProgressHintText = '导入中...';
      const formData = new FormData();
      formData.append('file', param.file);
      // this.saveOldStudentEnterTemp(() => {
      ImportCampusResource(formData).then(result => {
        console.log('导入成功', result.data);
        this.search();
        layer.alert('导入成功');
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        this.showProgress = false;
        this.$refs.upload.clearFiles(); // 上传成功之后清除历史记录
      });
      // });
    },
    getAllBigCourse () {
      GetAllCoursewarePackage().then(result => {
        this.bigCoursewareList = result.data;

        this.bigCoursewareList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
      });
    },
    closeUpdateCampusResourcePopup () {
      this.showUpdateCampusResource = false;
    },
    addCampusResource () {
      this.titleName = '添加校区资源';
      this.updateData = null;
      this.showUpdateCampusResource = true;
    },
    updateCampusResource (data) {
      this.titleName = '修改校区资源';
      this.updateData = data;
      console.log('data', data);
      this.showUpdateCampusResource = true;
    },
    deleteCampusResource (data) {
      layer.confirm('是否删除该校区', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              DeleteCampusResource(data.OLAPKey).then(result => {
                this.search();
              });
              layer.close();
            }
          }
        ]
      });
    },
    // 关闭
    closeParticipateCampusFormPopup () {
      this.isShowParticipateCampusFormPopup = false;
    },
    // 关闭
    closeFranchiseeParticipateCampusFormPopup () {
      this.isShowFranchiseeParticipateCampusFormPopup = false;
    },

    openCampusResource (data) {
      this.updateData = data;
      if (data.TypeKey == '1') {
        this.isShowParticipateCampusFormPopup = true;
      } else if (data.TypeKey == '2') {
        this.isShowFranchiseeParticipateCampusFormPopup = true;
      } else if (data.TypeKey == '3') {
        this.isShowCampusFormPopup = true;
      }
    },
    closeCampusFormPopup () {
      this.isShowCampusFormPopup = false;
    }

  }
};
  </script>
  <style scoped>
.super_management {
  margin-top: 90px;
}
.pending_processed .location_icon {
  width: 13px;
  height: 18px;
  margin-right: 10px;
  margin-top: -1px;
}

.pending_processed .location_icon1 {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  /* top: 10px; */
  margin-top: -1px;
}
.pending_processed >>> .sta {
  white-space: break-spaces !important;
  display: flex;
  align-items: center;
}

.pending_processed >>> .sta_time {
  white-space: break-spaces !important;
  margin-right: 46px;
  display: block;
  line-height: 18px;
}
.pending_processed >>> .sta_saa {
  white-space: break-spaces !important;
}
.PortWarning {
  position: absolute;
  left: 460px;
}
.table_select_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.PortWarningText {
  padding: 4px 25px;
  margin-left: 8px;
  border: 1px solid #ececec;
  color: #ef8604;
  border-radius: 4px;
}
.el-button >>> .el-upload {
  width: inherit;
}
</style>
  
  