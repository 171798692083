<template>
  <!-- 推荐作品 或 最新推荐 recommended_works_div recommended_works_list-->
  <div class="max_courseware old_max_courseware_manage">
    <div class="courseware_list"
         @mouseleave.stop="mouseOutEvent">
      <div class="max_list_box"
           @click.stop="selectEvent">
        <div class="list_img"
             @mouseenter="mouseOverEvent">
          <div class="list_number">{{ShowOrder+1}}</div>
          <!-- 作品按钮 显示... -->
          <div class="list_img_btn"
               v-if="isShowBtn&&newOperateMenu.length>0 "
               @mouseenter="showOperateMenu">
            <div class="list_img_ul"
                 v-if="isShowOperateMenu">
              <!-- 作品按钮按钮显示 -->
              <div v-for="item in newOperateMenu"
                   :key="item.key">
                <div class="list_img_menu"
                     :class="item.className"
                     v-if="item.isShow"
                     @click.stop="doOperateItemClick(item)">
                  {{item.name}}
                </div>
              </div>

            </div>
          </div>
          <img class="img_box"
               :src="vCoverImg"
               alt="">
        </div>
        <div class="max_list_info">
          <div class="list_name">
            {{dataInfo.StudentName}}
          </div>
          <div class="list_text">
            <span>指导老师：{{dataInfo.GuideTeacherName}}</span>
          </div>
          <div class="list_text">
            <span>创作日期：{{$utils.formatDateToLine(dataInfo.CreateDateTime)}}</span>
          </div>
          <div class="list_text">
            <span v-if="this.showManagement == 'recommendedWorks'">推荐至：{{dataInfo.ReceiveClientKeyValue}}</span>
            <span v-else>所属校区：{{dataInfo.SaaSClientKeyValue}}</span>
          </div>
        </div>
      </div>
    </div>
    <custom-dialog :title="dataInfo.StudentName"
                   :visible.sync="isShowPhoneFormPopup"
                   :before-close="closePhoneFormPopup">
      <look-works-popup :PhotoUrl="dataInfo.PhotoUrl"
                        @closeFrom="closePhoneFormPopup"></look-works-popup>
    </custom-dialog>
  </div>
</template>
<script>
import LookWorksPopup from './look-works-popup';
import { DeleteWorks, HQPushStudentPhoto, HQCollectStudentPhoto } from '../../../API/workbench';
export default {
  data () {
    return {
      isShowPhoneFormPopup: false,
      WorksTitle: '查看作品',
      isShowBtn: false, // 显示按钮
      isShowOperateMenu: false, // 显示菜单
      isShowMenuBeing: true, // 是否已是移出状态
      isShowLookWorksPopup: false,
      newOperateMenu: [],
      operateMenu: [
      ],
      lastClickTime: null
    };
  },
  components: {
    LookWorksPopup
  },
  props: {
    dataInfo: Object,
    ShowOrder: Number,
    showManagement: String
  },
  mounted () {
    this.$dialog.register(this,
      [
        'lookWorksPopup'
      ]);
  },
  created () {
    console.log(this.dataInfo, 'this.dataInfo');
    if (this.showManagement == 'recommendedWorks') {
      this.operateMenu = [
        { name: '查看', className: 'see_icon', key: 'see', isShow: true },
        { name: '删除', className: 'type_03', key: 'disable', isShow: true }
      ];
    } else {
      this.operateMenu = [
        { name: '查看', className: 'see_icon', key: 'see', isShow: true },
        { name: '推荐', className: 'rmd_icon', key: 'rmd', isShow: true },
        { name: '回收', className: 'recover_icon', key: 'recover', isShow: true }
      ];
    }
  },
  computed: {
    vCoverImg () {
      if (this.dataInfo.PhotoUrl) {
        return this.$store.getters.CDNURL + this.dataInfo.PhotoUrl;
      } else {
        return '';
      }
    }
  },
  methods: {
    // 鼠标移入,显示按钮
    mouseOverEvent () {
      console.log('鼠标移入');
      this.isShowBtn = true;
      this.newOperateMenu = this.$utils.parseJson(this.operateMenu);
    },
    // 鼠标移入,显示操作菜单
    showOperateMenu () {
      console.log('鼠标移入2');
      this.isShowOperateMenu = true;
    },
    // 鼠标离开
    mouseOutEvent () {
      this.isShowBtn = false;
      this.isShowOperateMenu = false;
    },
    // 点击事件
    selectEvent () {
      this.$emit('selectEvent', this.dataInfo);
    },
    closePhoneFormPopup (type) {
      if (!type) {
        this.isShowPhoneFormPopup = false;
      }
    },
    // 操作项
    doOperateItemClick (item) {
      switch (item.name) {
        case '查看':
          this.isShowPhoneFormPopup = true;
          break;
        case '删除':
          layer.confirm(
            '删除该作品,该作品将永久删除不会被恢复,是否继续删除?',
            {
              btn: [
                {
                  name: '确认',
                  callBack: () => {
                    DeleteWorks(Number(this.dataInfo.OLAPKey)).then(result => {
                      layer.close('删除作品成功');
                      this.$emit('afterSuccess');
                    }, error => {
                      console.log(error.msg);
                    });
                    layer.close();
                  }
                }
              ]
            }
          );
          break;
        case '推荐':
          layer.confirm(
            '确定继续推荐?',
            {
              btn: [
                {
                  name: '确认',
                  callBack: () => {
                    HQPushStudentPhoto(Number(this.dataInfo.OLAPKey)).then(result => {
                      layer.close('推荐作品成功');
                      this.$emit('afterSuccess');
                    }, error => {
                      console.log(error.msg);
                    });
                    layer.close();
                  }
                }
              ]
            }
          );
          break;
        case '回收':
          layer.confirm(
            '确定将此作品回收?',
            {
              btn: [
                {
                  name: '确认',
                  callBack: () => {
                    HQCollectStudentPhoto(Number(this.dataInfo.OLAPKey)).then(result => {
                      layer.close('回收作品成功');
                      this.$emit('afterSuccess');
                    }, error => {
                      console.log(error.msg);
                    });
                    layer.close();
                  }
                }
              ]
            }
          );
          break;
        default:
          break;
      }
      console.log(item, 'item');
      if (this.checkCanClick()) {
        this.mouseOutEvent();
      }
    },
    checkCanClick () {
      let now = Date.new();
      let r = !this.lastClickTime || now - this.lastClickTime > 800;
      if (r) {
        this.lastClickTime = now;
      }
      return r;
    }
  }
};
</script>
<style scoped>
.showManagement > div {
  z-index: 22;
}
.courseware_list .list_img_btn {
  z-index: 10;
  position: absolute;
  top: 20px;
  right: 50px;
  width: 42px;
  height: 32px;
  cursor: pointer;
  background: url(../../../../public/image/courseware_menu_btn_gray.png)
    no-repeat;
  background-size: 30px;
}
.courseware_list .list_img_btn:hover {
  background: url(../../../../public/image/courseware_menu_btn_blue.png)
    no-repeat;
  background-size: 30px;
}
/* 查看 */
.courseware_list .list_img_menu.see_icon {
  background-image: url(../../../../public/image/see_icon01.png);
}

.courseware_list .list_img_menu.see_icon:hover {
  background-image: url(../../../../public/image/see_icon02.png);
}
/* 推荐 */
.courseware_list .list_img_menu.rmd_icon {
  background-image: url(../../../../public/image/rmd_icon01.png);
}

.courseware_list .list_img_menu.rmd_icon:hover {
  background-image: url(../../../../public/image/rmd_icon02.png);
}
/* 回收 */
.courseware_list .list_img_menu.recover_icon {
  background-image: url(../../../../public/image/recover_icon01.png);
}

.courseware_list .list_img_menu.recover_icon:hover {
  background-image: url(../../../../public/image/recover_icon02.png);
}
.courseware_list .list_img_btn {
  top: 8px;
  right: 0px;
  background-size: 40px;
}
.courseware_list {
  display: flex;
  width: 310px;
  float: left;
  margin: 20px 20px 20px 0;
}
.courseware_list .max_list_box {
  height: 295px;
  background: #fff;
  border-radius: 8px;
  padding: 6px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.courseware_list .list_btn {
  position: relative;
  width: 0px;
}
.courseware_list .max_list_info {
  width: 280px;
  height: 50px;
  cursor: pointer;
  margin: 10px 10px 10px 15px;
}
.capacity {
  padding-left: 2px;
}
.list_name {
  margin-bottom: 8px;
}
.list_text {
  margin-top: 3px;
}
.courseware_list .list_number {
  z-index: 10;
  left: 0px !important;
}
.img_box {
  width: auto !important;
  height: auto !important;
  display: block;
  margin: 0 auto;
  max-width: 300px;
  max-height: 168px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>