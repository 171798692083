<template>
  <div class="pr">
    <div contenteditable="true"
         id="textarea_form"
         class="text_box"
         ref="focusTextarea"
         :placeholder="placeholder"
         @blur="updateVal"
         @focus="focusFun"
         @keydown="doKeyboardPress($event)"
         @input="inputDiv"
         @change="changeVal"
         v-html="contentText">

    </div>
    <div v-if="!hasContent"
         class="textarea_form_placeholder">
      {{placeholder}}
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      contentText: '',
      hasContent: false,
      seletedSelection: null,
      isUpdate: false,
      innerHTMLVal: ''
    };
  },
  props: {
    placeholder: {
      type: String,
      default: '请输入作业内容'
    },
    value: {    // 值，双向绑定，如不想双向绑定，则自行实现input事件
      type: String,
      default: ''
    },
    maxLength: {
      type: Number
    }
  },
  created () {
    this.autoFocussing();
    this.innerHTMLVal = this.$utils.parseJson(this.valueToShowContent(this.value));
    window.removeTemplateFieldForNoticeSetting = function (removeBtn) {
      removeBtn.parentNode.remove();
    };
    // 点击事件
    window.doSelectedLabelShow = function (event) {

    };
  },
  mounted () {
    // 监听复制问题。
    let event = document.getElementById('textarea_form');

    this.$utils.addPasteEvent(event, (content) => {
      // var memepattern = new RegExp('\{\{([0-9]*)?\}\}', 'g');
      // var namepattern = new RegExp('\{\{([A-Za-z]*)?\}\}', 'g');
      //   // 表情匹配
      // content = content.replace(memepattern,
      //     '<img src=' + this.$store.getters.PCWebURL + '/WXBV/img/WXimg/expression/$1.png alt="{{$1}}" data="{{$1}}" class="editor_meme_img">'
      //   );
      //   // @关联匹配
      // content = content.replace(namepattern,
      //     '<img src=' + this.$store.getters.PCWebURL + '/WXBV/img/WXimg/expression/$1.png alt="{{$1}}" data="{{$1}}" class="editor_stu_img">'
      //   );
      // return content.toString().replace(/\r\n/g, '<br>');
    });
    this.$bus.on(this, 'copyContentAfterUpdate', (data) => {
      let textContet = this.$el.querySelector('#textarea_form').innerHTML;
      if (textContet == '') {
        this.hasContent = false;
      } else {
        this.hasContent = true;
      }
    });
    setTimeout(() => {
      console.log('content-text mounted', this.value);
      this.contentText = this.valueToShowContent(this.value);
      // window.getSelection().collapseToEnd();// 光标移至最后
    }, 500);
  },
  watch: {
    value: {
      handler (c, o) {
        if (c) {
          // this.contentText = this.valueToShowContent(this.value);
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 添加到指定位置并释放
    dropRelease (item, type) {
      console.log(item, 'this.添加到指定位置并释放1');
      this.hasContent = true;
      let textNode = document.createElement('span');
      var defaultImg = '';
      // nodeValueName = this.transitionText(nodeValueName);
      if (type == 'meme') {
        defaultImg = this.setImgUrl(item);
        textNode.innerHTML = `<img class="editor_meme_img" src="${defaultImg}" alt="${item.value}" data="${item.key}">`;
      } else {
        let url = item == '@学生名' ? 'student' : 'teacher';
        defaultImg = this.setImgUrl(url);
        textNode.innerHTML = `<img class="editor_stu_img" src="${defaultImg}" alt="${url}" data="${url}">`;
      }
      this.insertHtmlAtCaretForTempleateField(textNode.children[0]);
    },
    // 记录光标位置插入文本
    insertHtmlAtCaretForTempleateField (html) {
      let range = null;
      this.seletedSelection = window.getSelection();
      console.log('insertHtmlAtCaretForTempleateField html', html);
      console.log('insertHtml getSelection', window.getSelection);
      if (window.getSelection) {
        console.log('this.seletedSelection.getRangeAt', this.seletedSelection.getRangeAt && this.seletedSelection.rangeCount);
        console.log('insertHtmlAtCaretForTempleateField this.seletedSelection', this.seletedSelection);
        if ((this.seletedSelection.anchorNode.id && this.seletedSelection.anchorNode.id == 'textarea_form') || (this.seletedSelection.anchorNode.parentNode && this.seletedSelection.anchorNode.parentNode.id == 'textarea_form')) {
          if (this.seletedSelection.getRangeAt && this.seletedSelection.rangeCount) {
            // 失去焦点时获取光标的位置
            range = this.seletedSelection.getRangeAt(0); range.deleteContents();
            // img标签挂载在div下
            let el = document.createElement('div'); el.appendChild(html);
            // 是用来创建一个虚拟的节点对象
            let frag = document.createDocumentFragment(), node, lastNode;
            while ((node = el.firstChild)) {
              lastNode = frag.appendChild(node);
            }
            range.insertNode(frag);
            if (lastNode && range) {
              range = range.cloneRange();
              range.setStartAfter(lastNode);
              range.collapse(false);
              this.seletedSelection.removeAllRanges();
              this.seletedSelection.addRange(range);
              // console.log(range.commonAncestorContainer.innerHTML, 'range');
              if (html.innerHTML) { // 换行不进入条件
                let htmlText = range.commonAncestorContainer;
                // htmlText.innerHTML = htmlText.innerHTML.replace(/&nbsp;/gi, '');// 全局清空，防止多出空格
                htmlText.innerHTML = htmlText.innerHTML.replace(new RegExp('</span><', 'g'), '</span><');// 全局替换，添加一个空格
                htmlText.innerHTML = htmlText.innerHTML.replace(new RegExp('<br><br>', 'g'), '<br>');// 全局替换，还原一个<br>
                // htmlText.innerHTML += '&nbsp';// 全局替换，最后一个添加一个空格
                // console.log(htmlText);
              }
            }
          }
        }
      }
    },
    setImgUrl (item) {
      if (!item.value) {
        return this.$store.getters.PCWebURL + '/WXBV/img/WXimg/expression/' + item + '.png';
      } else {
        return this.$store.getters.PCWebURL + '/WXBV/img/WXimg/expression/' + item.value + '.png';
      }
    },
    // 自动聚焦
    autoFocussing () {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.focusTextarea) {
            // 定位光标追加到最后
            var obj = this.$refs.focusTextarea;
            obj.focus();
            console.log('autoFocussing', window.getSelection);
            if (window.getSelection) { // ie11 10 9 ff safari
              obj.focus(); // 解决ff不获取焦点无法定位问题
              // var range = window.getSelection();// 创建range
              this.seletedSelection = window.getSelection();
              this.seletedSelection.selectAllChildren(obj);// range 选择obj下所有子内容
              this.seletedSelection.collapseToEnd();// 光标移至最后
            } else if (document.selection) { // ie10 9 8 7 6 5
              var range = document.selection.createRange();// 创建选择对象
              // var range = document.body.createTextRange();
              range.moveToElementText(obj);// range定位到obj
              range.collapse(false);// 光标移至最后
              range.select();
            }
          }
        }, 500);
      });
    },
    // 键盘按下
    doKeyboardPress (e) {
      var ev = (typeof event != 'undefined') ? window.event : e;

      if (e.target.innerHTML == '') {
        this.hasContent = false;
      } else {
        this.hasContent = true;
      }
    },
    inputDiv (e) {
      // if (e.target.innerHTML.length > this.maxLength) {
      //   e.target.innerHTML = e.target.innerHTML.substring(0, this.maxLength);
      // }
      if (e.target.innerHTML == '') {
        this.hasContent = false;
      } else {
        this.hasContent = true;
      }
      let copyText = e.target.innerHTML.toString();
      // console.log(copyText, this.innerHTMLVal, '教学步骤2-onchange');
      //  console.log(e.target.innerHTML, this.innerHTMLVal, '教学步骤2-onchange');
      if (this.innerHTMLVal != e.target.innerHTML) {
        this.$bus.emit('AftersCoursewareEditorState', 'onchange', Date.new().getTime());
      } else {
        this.$bus.emit('AftersCoursewareEditorState', 'onchange', -Date.new().getTime());
      }
    },
    updateVal (e) {
      if (window.getSelection) {
        this.seletedSelection = window.getSelection();
      }
      this.isUpdate = true;
      // console.log('blur');
      // console.log("e.target.innerHTML",e.target.innerHTML)
      // console.log("e.target.innerHTML.length",e.target.innerHTML.length)
      // console.log("e.target.innerTEXT",e.target.innerText)
      // console.log("e.target.innerTEXT22",e.target.innerText.length)
      if (e.target.innerText.length > this.maxLength) {
        e.target.innerText = e.target.innerText.substring(0, this.maxLength);
        console.log('e.target.innerHTML', e.target.innerHTML);
        layer.alert('文字内容不能超过' + this.maxLength);
      }
      this.$emit('input', this.showContentToValue(e.target.innerHTML));
    },
    focusFun (e) {
      setTimeout(() => {
        console.log(e.target, this.innerHTMLVal, '聚焦-content-text');
        if (this.innerHTMLVal && this.innerHTMLVal != e.target.innerHTML) {
          this.$bus.emit('AftersCoursewareEditorState', 'onchange', Date.new().getTime());
        } else {
          this.$bus.emit('AftersCoursewareEditorState', 'onchange', -Date.new().getTime());
        }
      }, 500);
      this.$emit('focusFun');
    },
    changeVal (e) {

    },
    showContentToValue (v) {
      let pattern = new RegExp('<img\\b[^<>]*?\\balt="([A-Za-z0-9]*)?"[^<>]*/?>', 'g');
      v = v.toString().replace(pattern, '{{$1}}');

      // 图片正择匹配
      v = v.toString().replace(/<span>/g, '');
      v = v.toString().replace(/<\/span>/g, '');
      v = v.toString().replace(/^<div>/, '');
      v = v.toString().replace(/<br><\/div>/g, '');
      v = v.toString().replace(/<br><div>/g, '<br>');
      v = v.toString().replace(/<div>/g, '<br>');
      v = v.toString().replace(/<\/div>/g, '');
      v = v.toString().replace(/&nbsp;/g, ' ');
      let value = v.toString().replace(/<br>/g, '\r\n');
      if (value == '') {
        this.hasContent = false;
      } else {
        this.hasContent = true;
      }
      return value;
    },
    valueToShowContent (v) {
      if (v) {
        console.log('valueTo', v);
        this.hasContent = true;
        var memepattern = new RegExp('\{\{([0-9]*)?\}\}', 'g');
        var namepattern = new RegExp('\{\{([A-Za-z]*)?\}\}', 'g');
        // 表情匹配
        v = v.replace(memepattern,
          '<img src=' + this.$store.getters.PCWebURL + '/WXBV/img/WXimg/expression/$1.png alt="$1" class="editor_meme_img">'
        );
        // @关联匹配
        v = v.replace(namepattern,
          '<img src=' + this.$store.getters.PCWebURL + '/WXBV/img/WXimg/expression/$1.png alt="$1" class="editor_stu_img">'
        );
        return v.toString().replace(/\r\n/g, '<br>');
      } else {
        return '';
      }
    }
  }
};
</script>
