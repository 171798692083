<template>
  <div class="payment_statistics_top height_type"
       style="margin-bottom: 0;height: 406px;">
    
    <div class="payment_echarts_box"
         style="display:block;padding-top: 90px;">
      <div class="chain_mendian_center_left" style="left: -20px;top:6px">
        <div class="left_type_text type_blue">
          本月
        </div>
        <div class="left_type_text type_red">
          上个月
        </div>
        <div class="centre_type_text">
          学生签到人次统计表
        </div>
      </div>

      <div class="chain_mendian_center_left" style="top: 4px;right: 0;">
        <div class="right_title_date">
          <single-year-picker @chgDate="chgDate"
                              :isDisableNow="isDisableNow"
                              :defaultDate="defaultDate"
                              :type="'month'"></single-year-picker>
        </div>
      </div>
      <div class="chain_mendian_center_left" style="left: -20px;top:60px">
        <div class="left_type_text">
          (签到人次)
        </div>
      </div>
      <doubleTypeEchart :echartID="'dual_histogram5'"
                        :reqList="monthList"
                        :nowDataList="nowList"
                        :preDataList="oldList"
                        :tipTitle="showTipTitle"
                        :echartType="'line'"
                        class="student_echart"
                        ref="echart"></doubleTypeEchart>
    </div>
  </div>
</template>
<script>
import doubleTypeEchart from './line-type-echart';
import {
  StudentAttendenceReportForDate
} from '../../../../API/workbench';
import singleYearPicker from '../../../base-module/date-picker-common/single-year-picker';
export default {
  data () {
    return {
      searchTime: {
        // 收款查询日期
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.getCurrentMonthLast(),
        DateType: 2
      },
      paymentDetail: [],
      monthList: [ ],
      nowList: [], // 选中月数据显示的内容
      oldList: [], // 上个月数据显示的内容
      nowDataList: [], // 选中当月数据源
      oldDataList: [], // 选中的上个月数据源
      defaultDate: Date.new(Date.new().setDate((Date.new().getDate() - -1))),
      isDisableNow: true,          // 是否禁用今天以后的日期
      showTipTitle: Date.new().getFullYear() + '年' + (Date.new().getMonth() + 1) + '月'
    };
  },
  components: {
    doubleTypeEchart,
    singleYearPicker
  },
  created () {
    this.getPaymentListReport();
  },
  mounted () {

  },
  computed: {
  },
  methods: {
    // 选择日期
    chgDate (val, startTime, endTime) {
      console.log(val, startTime, endTime, 'chgDate');
      let year = Date.new(val).getFullYear();
      let month = Date.new(val).getMonth() + 1;
      this.showTipTitle = year + '年' + month + '月';

      this.searchTime.startTime = startTime.replace(/\//g, '-');
      this.searchTime.endTime = endTime.replace(/\//g, '-');
      this.getPaymentListReport();
    },
    // 学生考勤报表-学生签到人次统计表
    getPaymentListReport () {
      StudentAttendenceReportForDate(this.searchTime.startTime, this.searchTime.endTime).then((result) => {
        this.paymentDetail = result.data;
        console.log(this.paymentDetail, '学生考勤报表');
        this.monthList = this.setEchartXForMonth();// 选中月
        this.nowList = this.getNowList();
        this.oldList = this.getOldList();
        this.$nextTick(() => {
          this.$refs.echart.echartInit();
        });
      }).catch((error) => {
        console.log(error, 'errorGetInComePayReportForDate');
      });
    },
    // 设置X轴
    setEchartXForMonth () {
      let newArr = [];
      this.paymentDetail.ThisMonthReport.forEach((o, index) => {
        let num = index < 9 ? '0' + (index + 1) : index + 1;
        newArr.push(num);
      });
      let n = Number(this.searchTime.endTime.split('-')[2]);
      if (newArr.length < n) {
        for (let i = newArr.length; i < n; i++) {
          newArr.push(i + 1);
        }
      }
      // console.log(newArr, '设置X轴');
      // console.log(this.paymentDetail.ThisMonthReport, '设置X轴22');
      return newArr;
    },
    // 本月考勤统计 // 区分月
    getNowList () {
      let nowList = [];
      this.monthList.forEach(p => {
        let item = null;
        item = this.paymentDetail.ThisMonthReport.find(o => {
          return Date.new(o.SetUpTime).getDate() == parseInt(p);
        });
        if (item && Date.new(item.SetUpTime) <= Date.new()) {
          nowList.push(Number(item.AttendenceCount).toFixed(0));
        } else {
          nowList.push('');
        }
      });
      return nowList;
    },
    // 上月考勤统计 // 区分月
    getOldList () {
      let nowList = [];
      this.monthList.forEach(p => {
        let item = null;
        item = this.paymentDetail.LastMonthReport.find(o => {
          return Date.new(o.SetUpTime).getDate() == parseInt(p);
        });
        if (item) {
          nowList.push(Number(item.AttendenceCount).toFixed(0));
        } else {
          nowList.push('');
        }
      });
      return nowList;
    }
  }
};
</script>
