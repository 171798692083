import { render, staticRenderFns } from "./ppt-editor.vue?vue&type=template&id=31306022&scoped=true"
import script from "./ppt-editor.vue?vue&type=script&lang=js"
export * from "./ppt-editor.vue?vue&type=script&lang=js"
import style0 from "./ppt-editor.vue?vue&type=style&index=0&id=31306022&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31306022",
  null
  
)

export default component.exports