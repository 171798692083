<template>
  <div class="courseware_list accessory"
       style="width: 416px;margin-bottom: 20px;">
    <div v-if="!vIsShowUpload"
         class="flex">
      <div class="list_boxs">
        <div class="ppttipbox"
             :title="dataInfo.IsInsetFontKeyValue"
             v-if="dataInfo.AttachTypeKey == '4'&&Number(dataInfo.IsInsetFontKey)==0&&Number(settingTemplateData.pptFontCheck)==1"> <img :src="require('../../../../../public/image/ppt_font_d.png')"
               class="ppt-font-d"> <span style="margin-right: 5px;">字体</span> </div>
        <div class="list_number">{{ dataInfo.ShowOrder }}</div>
        <div class="hoverShadow"
             @click.stop="showPlay">
          <img :src="require('../../../../../public/image/delete_courseware.png')"
               class="deleteCourse"
               @click.stop="deleteCourse" />
          <div class="passwordCourse"
               @click.stop="passwordCourse"
               v-if="dataInfo.Remark&&dataInfo.Remark.indexOf('修改文件密码')>-1">
            <img :src="require('../../../../../public/image/update_password.png')"
                 width="20"
                 height="20" />
          </div>

        </div>
        <div v-if="!((!dataInfo.ID || !dataInfo.AttachViewUrl) ||vErrorkey)&&vErrorMessage!=''&&dataInfo.Remark&&dataInfo.Remark!='完成'&&dataInfo.Remark!=''"
             class="courseware_add_img_box">
          <!-- <div class="add_img">
            <img class="img_box" :src="vUrl" alt="" />
          </div> -->
          <div class="add_progress_text icon_type">{{dataInfo.Remark || ''}}</div>
        </div>
        <upload v-if="(!dataInfo.ID || !dataInfo.AttachViewUrl) ||vErrorkey"
                :dataInfo="dataInfo"
                @seletedPPTItem="selectPPTAccessory"></upload>
        <div v-else
             class="list_img">
          <img v-if="!(!((!dataInfo.ID || !dataInfo.AttachViewUrl) ||vErrorkey)&&vErrorMessage!=''&&dataInfo.Remark&&dataInfo.Remark!='完成'&&dataInfo.Remark!='')"
               class="img_box"
               :src="vUrl" />
          <img :src="require('../../../../../public/image/video_plays.png')"
               class="videoIcon"
               v-if="dataInfo.AttachTypeKey == '2'">
          <div v-if="vErrorMessage"
               class="error_message">
            {{vErrorMessage}}
          </div>
        </div>
        <div class="courseware_list list_accessory_name"
             :title="dataInfo.MainDemoName">
          <div v-if="!isEdit"
               class="course_text"><img :src="vIconUrl"
                 class="imgIcon" /> {{ dataInfo.MainDemoName || "未命名" }}</div>
          <div v-else>
            <input type="text"
                   class="editTitleInput"
                   v-model="inputmDadisa"
                   @blur="verifyInput">
          </div>
          <div class="editTitle"
               v-if="!isEdit"
               @click="isEdit=!isEdit">{{isEdit?'保存':'修改'}}</div>
        </div>
        <!-- <div class="courseware_name_capacity">
          <img :src="require('../../../../../public/image/capacity_icon.png')"
               class="courseware_name_capacity_icon" />
          <span class="courseware_name_capacity_text">{{!dataInfo.SizeNum?'0M':this.$utils.capacityFormate(dataInfo.SizeNum)}}</span>
        </div> -->
      </div>
      <div class="list_btn">
        <div class="list_top"
             v-if="dataInfo.ShowOrder > 1"
             @click="upItem"></div>
        <div class="list_top notop"
             v-else></div>
        <div class="list_bottom"
             v-if="maxHeight != dataInfo.ShowOrder"
             @click="downItem"></div>
        <div class="list_bottom notbottom"
             v-else></div>
      </div>
    </div>
    <div v-else
         @click="selectItem"
         class="list_boxs"
         style="width:100%">
      <upload-item :dataInfo="dataInfo"></upload-item>
    </div>
    <custom-dialog :title="'文件修改密码'"
                   :visible.sync="isShowDialog"
                   :width="'400px'"
                   :before-close="closeDialog">
      <updatePassword @afterSuccess="upfatePasswordSuccess"
                      @cancelClick="closeDialog"> </updatePassword>

    </custom-dialog>
  </div>
</template>
<script>
import uploadItem from './upload-item';
import upload from '../courseware-edit/accessory-editor/upload';
import updatePassword from './update-password.vue'
import {
  DeleteCoursewareManageAttach,
  UnlockPPT
} from '../../../../API/workbench';
export default {
  data () {
    return {
      isShowUpload: false,
      isEdit: false,
      inputmDadisa: '',
      isShowDialog: false
    };
  },
  components: {
    uploadItem,
    upload,
    updatePassword
  },
  props: {
    dataInfo: Object,
    maxHeight: Number,
    editType: String,
    attachType: String,
    editInfo: Object,
    coursewareId: [String, Number],
    authorizedCode: String,
    settingTemplateData: Object
  },
  computed: {
    vUrl () {
      if (this.dataInfo.AttachTypeKey == '1') {
        if (this.dataInfo.AttachViewUrl.indexOf('data:image') != -1) {
          return this.dataInfo.AttachViewUrl;
        } else {
          if (this.dataInfo.CoverUrl && this.dataInfo.CoverUrl.length > 1) {
            return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
          } else {
            return (
              this.$store.getters.PCWebURL +
              this.dataInfo.AttachViewUrl +
              '?sign=' +
              this.$store.getters.signData.Sign
            );
          }
        }
      } else if (this.dataInfo.AttachTypeKey == '2') {
        if (this.dataInfo.CoverUrl) {
          return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
        } else {
          return require('../../../../../public/image/courseware_list_type01.png');
        }
      } else if (this.dataInfo.AttachTypeKey == '3') {
        return require('../../../../../public/image/courseware_list_type02.png');
      } else if (this.dataInfo.AttachTypeKey == '4') {
        if (this.dataInfo.CoverUrl) {
          return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
        } else {
          return require('../../../../../public/image/courseware_list_type03.png');
        }
      } else if (this.dataInfo.AttachTypeKey == '5') {
        return require('../../../../../public/image/courseware_pdf_cover.png');
      }
    },
    vIconUrl () {
      if (this.dataInfo.AttachTypeKey == '1') {
        return require('../../../../../public/image/material_icon01.png');
      } else if (this.dataInfo.AttachTypeKey == '2') {
        return require('../../../../../public/image/material_icon02.png');
      } else if (this.dataInfo.AttachTypeKey == '3') {
        return require('../../../../../public/image/material_icon04.png');
      } else if (this.dataInfo.AttachTypeKey == '4') {
        return require('../../../../../public/image/material_icon03.png');
      } else if (this.dataInfo.AttachTypeKey == '5') {
        return require('../../../../../public/image/material_icon05.png');
      }
    },
    vClass () {
      if (this.dataInfo.AttachTypeKey == '1') {
        return 'type_01';
      } else if (this.dataInfo.AttachTypeKey == '2') {
        return 'type_02';
      } else if (this.dataInfo.AttachTypeKey == '3') {
        return 'type_04';
      } else if (this.dataInfo.AttachTypeKey == '4') {
        return 'type_03';
      } else if (this.dataInfo.AttachTypeKey == '5') {
        return 'type_05';
      }
    },
    vIsShowUpload () {
      // console.log(this.dataInfo, 'vIsShowUpload');
      if ((this.dataInfo.percent && this.dataInfo.percent < 100) || (!this.dataInfo.ID && this.dataInfo.AttachTypeKey != '3')) {
        return true;
      } else {
        return false;
      }
    },
    vErrorMessage () {
      if (this.dataInfo.UnusualStatusKey == 1) {
        if (this.dataInfo.ResultStageKey == 3) {
          return '封面生成失败，请确认文件是否正常';
        } else if (this.dataInfo.ResultStageKey == 4 || this.dataInfo.ResultStageKey == 5) {
          return '转码失败，请重试，如多次失败，请联系专属客服';
        }
      } else if (this.dataInfo.UnusualStatusKey == 0) {
        if (this.dataInfo.ResultStageKey > 0) {
          let isTimeOut = Date.new().getTime() - Date.new(this.dataInfo.DealTime).getTime() > 86400000;
          if (isTimeOut) {
            return '附件处理超时，请删除重新上传';
          }
        }
      } else {
        return '';
      }
    },
    vErrorkey () {
      if (this.dataInfo.ResultStageKey && this.dataInfo.UnusualStatusKey && (this.attachType == 'video' || this.attachType == 'ppt')) {
        return (Number(this.dataInfo.ResultStageKey) != 0 || Number(this.dataInfo.UnusualStatusKey) != 0);
      } else {
        return false;
      }
    }
  },
  created () {
    this.$bus.on(this, 'wsCoursewareEditor', (data) => {
      console.log('监听到');
      // console.log(data, this.dataInfo, this.attachType, 'wsCoursewareEditor');
      if (data.DataKey == this.dataInfo.ID && data.status == 1) {
        this.dataInfo.ResultStageKey = 0;
        this.dataInfo.UnusualStatusKey = 0;
        // this.dataInfo.percent = data.progress
        this.dataInfo.Remark = data.remark
        if (data.Data.AttachTypeKey == '2') {
          this.dataInfo.AttachViewUrl = data.Data.AttachViewUrl;
        }
      }
    });

    if (this.dataInfo.percent < 100) {
      this.isShowUpLoad = true;
    }
    this.errorMessage();
    // this.$bus.on(this, 'wsCoursewareEditor', (data) => {
    //   console.log('监听到');
    //   // console.log(data, this.dataInfo, this.attachType, '封面显示');
    //   if (data.DataKey == this.dataInfo.ID && data.status == 1) { // 成功
    //     this.dataInfo.ResultStageKey = 0;
    //     this.dataInfo.UnusualStatusKey = 0;

    //   }

    //   // if()
    // });
  },
  mounted () {
    this.inputmDadisa = this.dataInfo.MainDemoName;
    // @click="selectItem"
    // this.selectItem();
  },
  methods: {
    verifyInput (text) {
      const reg = /^([\u4E00-\u9FA5A-Za-z0-9\uFF00-\uFFFF\u2026\u00D7\u00B7\u3010\u3011,\s`~!@#$%^&*()_。，——·‘’“”、。《》－；+<>?:"{},.\/;'[\]=\-|\\])+$/;
      // /[\s\x21-\x7e//\u4e00-\u9fa5A-Za-z0-9_-]*/
      //  /^[\u4e00-\u9fa5A-Za-z0-9_-]+[\)）（，；\(\.\%。\u4e00-\u9fa5A-Za-z0-9_-]?$/;
      let flag = true;
      const nonChineseCharacter = this.inputmDadisa;
      for (let i = 0; i < nonChineseCharacter.length; i++) {
        if (!reg.test(nonChineseCharacter.charAt(i))) {
          flag = false;
          layer.alert('课件名不能有特殊字符');
          return false;
        }
      }

      if (flag) {
        this.dataInfo.MainDemoName = this.inputmDadisa;
      }

      this.isEdit = false;
      this.saveAccessory()
    },
    upItem () {
      this.$emit('upItem', this.dataInfo);
    },
    downItem () {
      this.$emit('downItem', this.dataInfo);
    },
    selectItem () {
      this.$emit('selectItem', this.dataInfo);
    },
    saveAccessory () {
      this.$emit('saveAccessory');
    },
    errorMessage () {
      // console.log(this.dataInfo, this.attachType, 'courseware-edit-errorMessage(dataInfo)');
      if (this.dataInfo.ResultStageKey > 0 && this.dataInfo.UnusualStatusKey == 0) {
        this.isShowUpLoad = true;
        this.dataInfo.Remark = this.dataInfo.ResultStageKeyValue;
        let isTimeOut = Date.new().getTime() - Date.new(this.dataInfo.DealTime).getTime() > 86400000;
        if (isTimeOut) {
          // layer.alert('附件处理超时，请删除重新上传，如多次失败，请联系专属客服');
        }
      } else {
        if (this.dataInfo.ResultStageKey == 3) {
          // layer.alert('封面生成失败，请确认文件是否正常');
        } else if (this.dataInfo.ResultStageKey == 4 || this.dataInfo.ResultStageKey == 5) {
          // layer.alert('转码失败，请重试，如多次失败，请联系专属客服');
        } else if (this.dataInfo.Remark) {
          // layer.alert(this.dataInfo.Remark);
        }
      }
    },
    deleteCourse () {
      console.log('deleteItem', this.dataInfo);
      if (this.dataInfo.ID > 0) {
        layer.confirm('是否删除当前内容', {
          btn: [
            {
              name: '取消',
              callBack: () => {
                layer.close();
              }
            },
            {
              name: '确认',
              callBack: () => {
                if (this.dataInfo.ID) {
                  // console.log(this.dataInfo, '删除');
                  DeleteCoursewareManageAttach(this.dataInfo.ID).then((res) => {

                  }, (err) => {
                    layer.alert(err.msg);
                  }
                  );
                }

                // if(this.vTypeKey) {
                //   HQDeleteCoursewareAttach(this.dataInfo.ID).then(res=>{
                //   },err=>{
                //     layer.alert(err.msg)
                //   })
                // }else {
                //   DeleteCoursewareAttach(this.dataInfo.ID).then(res=>{
                //   },err=>{
                //     layer.alert(err.msg)
                //   })
                // }
                this.$emit('deleteCourseWare', this.dataInfo);
                layer.close();
              }
            }
          ]
        });
      } else {
        layer.alert('文件正在上传中，请上传成功后再删除');
      }
    },
    passwordCourse () {
      this.isShowDialog = true
    },
    showPlay () {
      console.log('this.dataInfo', this.dataInfo);
      if (this.dataInfo.AttachTypeKey == '2' || this.dataInfo.AttachTypeKey == '3') {
        this.$emit('showPlayViewPopup', this.dataInfo);
      } else if (this.dataInfo.AttachTypeKey == '4') {

        this.$emit('selectPPTAccessory', this.dataInfo);
      }
    },
    deleteCoverUrl () {
      this.$emit('deleteCoverUrl');
    },
    selectPPTAccessory (data) {
      this.$emit('selectPPTAccessory', data);
    },
    clearEditInfo () {
      this.$emit('clearEditInfo');
    },
    upAccessoryList () {
      this.$emit('upAccessoryList');
    },
    uploadCourseware () {
      this.$emit('uploadCourseware');
    },
    upCourseEvaluationInfo (data) {
      this.$emit('upCourseEvaluationInfo', data);
    },
    upHomeworkInfo () {
      this.$emit('upHomeworkInfo');
    },
    closeDialog () {
      this.isShowDialog = false
    },
    upfatePasswordSuccess (password) {
      console.log('password', this.dataInfo)
      let param = {}
      param.password = password;
      param.client_id = this.$store.getters.clientId;
      param.AuthorizeCode = this.authorizedCode;
      param.AttachKey = this.dataInfo.ID;
      UnlockPPT(param).then(res => {
        this.dataInfo.Remark = '转码队列中'
        layer.alert('提交成功');
      })

    }
  }
};
</script>
<style scoped>
.error_message {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
}
.courseware_name_capacity {
  position: absolute;
  bottom: 15px;
  right: 0px;
}
.courseware_name_capacity_icon {
  width: 10px;
  height: 10px;
}
.courseware_name_capacity_text {
  font-size: 12px;
  color: #999999;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
.courseware_list.opt >>> .courseware_add_img_box {
  border: 4px solid #3498db;
}

.list_boxs {
  flex: 1;
  width: 416px;
  height: 297px;
  padding-top: 10px;
  padding-left: 7px;
  border: 1px solid rgba(240, 240, 240, 1);
  border-radius: 12px;
}
.list_number {
  top: 10px;
  z-index: 3;
  width: 44px !important;
  height: 28px !important;
  border-radius: 0px 14px 14px 0px;
  line-height: 28px !important;
}
.hoverShadow {
  position: absolute;
  /* cursor: pointer; */
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
  width: 396px;
  height: 225px;
  left: 10px;
  border-radius: 16px;
}
.hoverShadow:hover {
  z-index: 2;
}
.list_img:hover {
  z-index: 0;
  border: inherit;
}
.list_img {
  z-index: 1;
  border-radius: 12px !important;
  pointer-events: none;
  text-align: center;
  width: 400px !important;
  height: 225px !important;
  border: inherit !important;
}
.deleteCourse {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  border-radius: 4px;
  cursor: pointer;
}

.passwordCourse {
  position: absolute;
  right: 50px;
  top: 10px;
  z-index: 1;
  border-radius: 4px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background: #c4c3c3;
  display: flex;
  text-align: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
}
.editTitle {
  position: absolute;
  width: 60px;
  height: 36px;
  right: 8px;
  color: #409eff;
  top: 13px;
  text-align: center;
  background-color: #eee;
  font-size: 13px;
  line-height: 36px;
  border-radius: 4.6769232749938965px;
  cursor: pointer;
}
.list_accessory_name {
  position: relative;
  max-width: 100% !important;
  padding-left: 0px !important;
  height: 55px !important;
  line-height: 27px !important;
  font-family: Alibaba PuHuiTi;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
}
.editTitleInput {
  border: 1px solid #eee;
  height: 35px;
  margin-top: 12px;
  padding-left: 5px;
  width: 350px;
}

.courseware_list .list_btn {
  width: 50px !important;
}
.courseware_list .list_bottom {
  position: absolute;
  height: 30px;
  bottom: 60px;
  width: 30px;
  left: 20px;
  right: 0px;
  cursor: pointer;
  background-image: url(../../../../../public/image/course_bottom.png);
}
.courseware_list .list_bottom:hover {
  background-image: url(../../../../../public/image/course_bottom.png) !important;
}
.courseware_list.accessory .list_top {
  height: 30px;
  width: 30px;
  left: 20px;
  top: 10px;
  right: 0px;
  background-image: url(../../../../../public/image/course_top.png);
}
.notop {
  background-image: url(../../../../../public/image/course_no_top.png) !important;
}
.notbottom {
  background-image: url(../../../../../public/image/course_no_bottom.png) !important;
}
.img_box {
  width: auto;
  border-radius: 12px;
}
.course_text {
  width: 330px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 18px;
  font-family: Alibaba PuHuiTi;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  height: 100%;
  /* padding-left: 40px; */
}
.ppt-font-d {
  width: 22px;
  height: 22px;
  margin-left: 3px;
  margin-bottom: 2px;
}
.imgIcon {
  width: 20px;
  height: 23px;
  margin-bottom: 4px;
}
.videoIcon {
  position: absolute;
  width: 40px;
  height: 40px;
  margin: auto;
  /* align-items: center; */
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.ppttipbox {
  position: absolute;
  top: 10px;
  left: 70px;
  /* background: pink; */
  color: #d92d2d;
  z-index: 10;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  border-radius: 4px;
  background: #ffdada;
  border: 1px solid #f0f0f0;
  cursor: pointer;
}
.courseware_add_img_box {
  position: absolute !important;
  z-index: 10 !important;
  top: 11px !important;
  bottom: 61px !important;
  border-radius: 12px !important;
  width: 400px !important;
  height: 226px !important;
  pointer-events: none;
  /* background: rgba(0,0,0,.3); */
}
.add_progress_banner {
  border-radius: 12px;
}
.add_progress {
  border-radius: 12px;
}
</style>