<template>
  <div v-if="vIsShow"
       class="popup_list"
       :class="[vOpt,item.className]"
       @click="clickItem">
    <!-- item.isActive?'opt':'' -->
    <basics-power-button ref="btn"
                         :item="item"
                         @clickItem="seletedItem">
      <div>
        <div>
          <span class="popup_list_text">{{item.value}}</span>
          <span :class="item.hintClassName"
                @click.stop="seletedHint">{{item.formHint||''}}</span>
        </div>
        <div class="select_btn"
             @click.stop="cancelClick"
             v-if="item.key==seletedKey&&showType=='opt'">
          撤销
        </div>
      </div>
    </basics-power-button>
    <!-- list_icon02 -->
  </div>

</template>

<script>
import basicsPowerButton from './basics-power-button';
export default {
  components: {
    basicsPowerButton
  },
  data () {
    return {

    };
  },
  props: {
    item: {
      type: Object,
      default: {
        value: '',				// 页面显示文字
        formHint: '',			// 文字提示语
        hintClassName: 'font_gray', // 提示语样式，默认灰色
        key: '',					// 键值对
        className: '',			// className
        isActive: false,			// 选中切换效果
        isShow: true         // 是否显示.
      }
    },
    noHintClick: {
      type: Boolean,
      default: false
    },
    showType: {
      type: String,
      default: 'other'
    }, // opt 为选中类型,提供撤销    其他other则为即时操作. 没有选中效果.
    seletedKey: [Number, String]			// 选中的项目
  },
  computed: {
    vIsShow () {
      if (this.item.isShow == undefined) {
        return true;
      } else {
        return this.item.isShow;
      }
    },
    vOpt () {
      if (this.seletedKey > 0) {
        console.log('this.seletedKey', this.seletedKey, this.item.key);
        return this.item.key == this.seletedKey ? 'opt' : '';
      } else {
        return this.item.isActive ? 'opt' : '';
      }
    }
  },
  methods: {
    cancelClick () {
      this.$emit('cancelClick', this.item);
    },
    clickItem () {
      this.$refs.btn.clickItem();
    },
    seletedItem () {
      this.$emit('seletedItem', this.item);
    },
    seletedHint () {
      if (!this.noHintClick) {
        this.$emit('seletedHint', this.item);
      } else {
        this.$emit('seletedItem', this.item);
      }
    }
  }
};
</script>

<style>
.popup_list {
  height: 51px;
  line-height: 51px;
  font-size: 14px;
  border-bottom: 1px solid rgba(236, 236, 236, 0.5);
  background-repeat: no-repeat;
  background-position: left center;
  position: relative;
  margin: 0 20px;
}
.popup_list.list_icon08 {
  padding-left: 31px;
  background-image: url(../../../../../public/image/popup_list_icon08.png);
  background-size: 17px;
}
.popup_list.list_icon09 {
  padding-left: 31px;
  background-image: url(../../../../../public/image/popup_list_icon09.png);
  background-size: 17px;
}

.popup_list.list_icon10 {
  padding-left: 31px;
  background-image: url(../../../../../public/image/popup_list_icon10.png);
  background-size: 17px;
}
</style>
