<template>
  <!--客户线索信息 -->
  <div class="customer_management_font">
    <div class="single_result_detail form_info_detail customer_form">
      <div class="student_info_edit form_info_edit form_info_list">
        <div class="customer_thread_form">
          <div class="form_left">
            <input-date v-model="queryParams.AddTime"
                        :formTitle="'添加日期'"
                        :readonly="true"
                        :elementId="'startPeriod'"
                        :required="false"
                        :minDate="$utils.formatDateToLine(Date.new())"
                        :formPlaceholder="'必填'"></input-date>

            <input-select :formTitle="'来源方式'"
                          :id="'type'"
                          :name="'name'"
                          :required="true"
                          :readonly="isReadOnly"
                          :dataList="sourceType"
                          :selectedInfo="vSourceTypeInfo"
                          @selectedItem="changeSourceType"></input-select>

            <input-text v-if="source.type==3"
                        :formTitle="'介绍机构'"
                        :formPlaceholder="'必填'"
                        :readonly="isReadOnly"
                        :required="true"
                        v-model="queryParams.IntroduceSaaSClientKeyValue"></input-text>

            <input-text :formTitle="'机构名称'"
                        :formPlaceholder="'必填'"
                        :readonly="false"
                        :required="true"
                        v-model="queryParams.SaaSClientNameKeyValue"></input-text>

            <input-text :formTitle="'联系人'"
                        :formPlaceholder="'必填'"
                        :readonly="false"
                        :required="true"
                        v-model="queryParams.ContactsName"></input-text>
          </div>
          <div style=" flex: 1;"></div>
          <div class="form_right">
            <input-text :formTitle="'录入人'"
                        :formPlaceholder="'必填'"
                        :readonly="true"
                        :required="false"
                        v-model="$store.getters.token.UserName"></input-text>

            <input-text v-if="queryParams.SourceTypeKey==1||queryParams.SourceTypeKey==3"
                        :formTitle="'占位'"
                        :readonly="true"
                        style="opacity: 0;"></input-text>

            <input-select v-if="queryParams.SourceTypeKey==2"
                          :formTitle="'导流来源'"
                          :id="'type'"
                          :name="'name'"
                          :required="false"
                          :readonly="false"
                          :dataList="shuntType"
                          :selectedInfo="vShuntTypeInfo"
                          @selectedItem="changeShuntType"></input-select>

            <input-select v-if="queryParams.SourceTypeKey==4"
                          :formTitle="'约单人'"
                          :id="'OLAPKey'"
                          :name="'MainDemoName'"
                          :required="false"
                          :readonly="false"
                          :filterable="true"
                          :dataList="staffList"
                          :selectedInfo="vStaffInfo"
                          @selectedItem="changeStaff"></input-select>

            <input-dialog v-if="source.type==3"
                          :formTitle="'介绍人'"
                          :required="true"
                          :readonly="false"
                          v-model="queryParams.IntroduceKeyValue"
                          @showEldilog="changeIntroduce"></input-dialog>

            <input-number class="no_padding"
                          :min="0"
                          :formTitle="'门店数'"
                          :decimalPlaces="0"
                          :required="false"
                          :readonly="vIsSaaSClientCount"
                          v-model="queryParams.SaaSClientCount"></input-number>

            <input-phone :formTitle="'手机号'"
                         :formPlaceholder="'必填'"
                         :required="true"
                         :readonly="false"
                         v-model="queryParams.MobileSupport"></input-phone>
          </div>
        </div>
        <input-search-cities :formTitle="'所在地区'"
                             :required="false"
                             :readonly="false"
                             :className="' '"
                             :selectedInfo="locationInfo"
                             @selectedItem="changeRegion"></input-search-cities>

        <input-text :formTitle="'详细地址'"
                    :formPlaceholder="'必填'"
                    :readonly="false"
                    :required="false"
                    v-model="queryParams.addressScript"></input-text>

        <div class="customer_thread_form">
          <div class="form_left">
            <input-select :formTitle="'学员数'"
                          :id="'type'"
                          :name="'name'"
                          :required="true"
                          :readonly="false"
                          :dataList="studentCountType"
                          :selectedInfo="vStudentCountInfo"
                          @selectedItem="changestudentCountType"></input-select>
          </div>
          <div style=" flex: 1;"></div>
          <div class="form_right">
            <input-dialog :formTitle="'课类'"
                          :required="false"
                          :readonly="false"
                          v-model="queryParams.ClassTypeName"
                          @showEldilog="changeCoursework"></input-dialog>
          </div>
        </div>

        <div class="thread_option">
          <input-option :formTitle="'是否用过'"
                        :readonly="false"
                        :required="true"
                        :class="queryParams.IsExperiKey==0?'':'no_border_bottom'"
                        :radioKey="queryParams.IsExperiKey"
                        @change="chgIsExperiKeySetting">
          </input-option>
        </div>
        <div v-if="queryParams.IsExperiKey>0">
          <single-for-option :formTitle="'用过'"
                             :readonly="false"
                             :required="false"
                             :value="queryParams.CompetName"
                             :radioKeys="queryParams.CompetIDSName"
                             @changeChecked="changeChecked">
          </single-for-option>

          <div class="customer_thread_form">
            <div class="form_left thread_option">
              <input-option :formTitle="'是否在用'"
                            :readonly="false"
                            :required="true"
                            :radioKey="queryParams.IsUseKey"
                            @change="chgIsUseKeySetting">
              </input-option>
            </div>
            <div style=" flex: 1;"></div>
            <div class="form_right"
                 v-if="queryParams.IsUseKey>0">
              <input-date v-model="queryParams.CompetExpireTime"
                          :formTitle="'到期日期'"
                          :readonly="false"
                          :elementId="'CompetExpireTime'"
                          :required="true"
                          :minDate="$utils.formatDateToLine(Date.new())"
                          :formPlaceholder="'必填'"></input-date>
            </div>
          </div>
        </div>

        <input-text :formTitle="'关注功能'"
                    :formPlaceholder="'必填'"
                    :readonly="false"
                    :required="false"
                    v-model="queryParams.AttentionScript"></input-text>

        <input-text :formTitle="'备注事项'"
                    :formPlaceholder="'必填'"
                    :readonly="false"
                    :required="false"
                    v-model="queryParams.RemarkScript"></input-text>
      </div>
      <div>
        <save-cancel-btn-group :isSingleSubmit="true"
                               :singleSubmitType="'dynamic'"
                               :btnText="'提交'"
                               @confirmClick="submitDate"
                               @cancelClick="closeFrom"></save-cancel-btn-group>

      </div>
    </div>
    <custom-dialog title="选择课类"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isShowMutilSelectCourseDialog"
                   :before-close="doclosemultiSelection">
      <mutil-select-tree :sourceDate="courseTreeList"
                         :selectionDate="applyCourseDatas"
                         @buttonClickEvent="doAfterSelectedMultiItem"
                         @cancleClickForMutilSelected="doclosemultiSelection"
                         @firstSelectTreeEvent="doFirstSelectTreeEvent"></mutil-select-tree>
    </custom-dialog>

    <custom-dialog title="员工列表"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isShowIntroduceListDialog"
                   :before-close="docloIntroduceList">
      <staff-list :threadInfo="{ SaaSClientNameKey:queryParams.IntroduceSaaSClientKey,SaaSClientNameKeyValue:queryParams.IntroduceSaaSClientKeyValue }"
                  @selectStaff="selectIntroducer"></staff-list>
    </custom-dialog>
  </div>
</template>
<script>
import singleForOption from '../single-for-option';
import staffList from './staff-list';
import mutilSelectTree from '../../../../common/mutil-select-tree';
import { AddThreadMarket, getCourseTypeNameList, GetThreadMarketDetails, EditThreadMarket, GetAgentForSaaSClient } from '../../../../../API/workbench.js';
export default {
  components: { singleForOption, mutilSelectTree, staffList },
  data () {
    return {
      isReadOnly: false,
      // 来源方式
      sourceType: [
        { type: 1, name: '自约' },
        { type: 2, name: '导流' },
        // { type: 3, name: '转介绍' }
        { type: 4, name: '约单' }
      ],
      // 导流来源
      shuntType: [
        { type: 1, name: '官网' },
        { type: 2, name: '抖音' },
        { type: 3, name: 'APP' }
      ],
      // 学员数量
      studentCountType: [
        { type: 1, name: '0-50' },
        { type: 2, name: '50-100' },
        { type: 3, name: '100-150' },
        { type: 4, name: '150-500' },
        { type: 5, name: '500+' }
      ],
      isShowMutilSelectCourseDialog: false, // 课类
      isShowIntroduceListDialog: false, // 员工列表
      courseTreeList: [], // 课类列表
      staffList: [],
      applyCourseDatas: { IDs: '', Names: '' },
      // 地址信息
      locationInfo: [],
      // 提交参数
      queryParams: {
        AddTime: this.$utils.formatDateToLine(Date.new()), // 添加日期
        SourceTypeKey: 1, // 1-自约;2-引流;3-转介绍;4-约单'
        SourceTypeKeyValue: '自约', // 1-自约;2-公司;3-转介绍;4-约单'
        ShuntTypeKey: '', // -- 引流方式 1-公司;2-抖音;3-app'
        ShuntTypeKeyValue: '', // 引流方式 1-公司;2-抖音;3-app'
        EngageBillKey: '', // -- 约单人'
        EngageBillKeyValue: '', // 约单人'
        SaaSClientNameKeyValue: '', // 机构名
        ContactsName: '', // 联系人
        MobileSupport: '', // 手机号
        SaaSClientCount: '', // 门店数
        ProvinceKey: '', // 省
        ProvinceKeyValue: '', // 省
        CityKey: '', // 市
        CityKeyValue: '', // 市
        AreaKey: '', // 区/县
        AreaKeyValue: '', // 区/县
        addressScript: '', // 详细地址
        StudentCountKey: '', // 学生数
        StudentCountKeyValue: '', // 学生数
        ClassTypeIDSName: '', // 课类IDS
        ClassTypeName: '', // 课类
        IsExperiKey: '', // 是否有使用经历
        IsExperiKeyValue: '', // 0-否;1-是
        CompetIDSName: null, // 1,2
        CompetName: null, // 校宝,小麦
        IsUseKey: 0, // 是否在用
        IsUseKeyValue: '', // 0-否;1-是
        InUseCompetName: '', // 在用竞品名称
        CompetExpireTime: this.$utils.formatDateToLine(this.$utils.getNextYear(Date.new())), // 竞品到期时间
        AttentionScript: '', // 关注功能
        RemarkScript: '', // 备注事项
        IntroduceKey: '', // 介绍人
        IntroduceKeyValue: '', // 介绍人
        IntroduceSaaSClientKey: '', // 介绍人门店
        IntroduceSaaSClientKeyValue: '', // 介绍人门店
        IntroduceMobileSupport: ''// 介绍人手机号
      },
      restsCompetName: '' // 其他
    };
  },
  props: {
    OLAPKey: [String, Number], // 线索信息ID
    source: {// 1-自约;2-公司;3-转介绍
      type: Object,
      default: () => {
        return { type: '', name: '' };
      }
    }
  },
  computed: {
    // 来源方式
    vSourceTypeInfo () {
      return {
        type: this.queryParams.SourceTypeKey,
        name: this.queryParams.SourceTypeKeyValue
      };
    },
    // 导流来源
    vShuntTypeInfo () {
      return {
        type: this.queryParams.ShuntTypeKey,
        name: this.queryParams.ShuntTypeKeyValue
      };
    },
    // 约单人
    vStaffInfo () {
      return {
        type: this.queryParams.EngageBillKey,
        name: this.queryParams.EngageBillKeyValue
      };
    },
    // 学员数
    vStudentCountInfo () {
      return {
        type: this.queryParams.StudentCountKey,
        name: this.queryParams.StudentCountKeyValue
      };
    },
    // 已开户的单店禁止修改，总部不可将门店数改为0和1
    vIsSaaSClientCount () {
      if (this.OLAPKey && this.queryParams.StatusKey > 0 && this.queryParams.TypeKey != 6) {
        return true;
      } else {
        return false;
      }
    }
  },
  created () {
    if (this.OLAPKey && Number(this.OLAPKey) > 0) {
      this.getThreadMarketDetails(this.OLAPKey);
    }
    if (this.source && Number(this.source.type) > 0) {
      this.queryParams.SourceTypeKey = this.source.type;// 来源方式
      this.queryParams.SourceTypeKeyValue = this.source.name;
      this.queryParams.IntroduceSaaSClientKey = this.source.organizationId;// 介绍人门店
      this.queryParams.IntroduceSaaSClientKeyValue = this.source.organizationName;// 介绍人门店
      this.isReadOnly = true;// 转介绍时只读
    }
    this.getAgentForSaaSClient();
    console.log(this.$utils.getNextYear(Date.new()), '默认有效期');
  },
  watch: {

  },
  methods: {
    // 获取线索信息
    getThreadMarketDetails (OLAPKey) {
      GetThreadMarketDetails(OLAPKey).then(result => {
        console.log(result.data, '获取线索信息');
        this.queryParams = result.data;
        this.queryParams.SaaSClientCount = result.data.SaaSClientCount || 0;
        this.locationInfo = [this.queryParams.ProvinceKey, this.queryParams.CityKey, this.queryParams.AreaKey];
        this.applyCourseDatas.IDs = this.queryParams.ClassTypeIDSName;
        this.applyCourseDatas.Names = this.queryParams.ClassTypeName;
      });
    },
    // 获取指定门店员工
    getAgentForSaaSClient () {
      console.log('threadInfo', this.threadInfo);
      GetAgentForSaaSClient(1).then(result => {
        console.log('获取指定门店员工', result.data);
        this.staffList = result.data;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 省市区
    changeRegion (keys, values) {
      this.queryParams.ProvinceKey = keys[0] || '';// 省
      this.queryParams.ProvinceKeyValue = values ? values[0] : ''; // 省
      this.queryParams.CityKey = keys[1] || '';// 市
      this.queryParams.CityKeyValue = values ? values[1] : ''; // 市
      this.queryParams.AreaKey = keys[2] || ''; // 区/县
      this.queryParams.AreaKeyValue = values ? values[2] : ''; // 区/县
      console.log(keys, values, '省市区');
    },
    // 介绍人列表
    changeIntroduce () {
      this.isShowIntroduceListDialog = true;
    },
    docloIntroduceList () {
      this.isShowIntroduceListDialog = false;
    },
    // 介绍人信息
    selectIntroducer (data) {
      this.queryParams.IntroduceKey = data.OLAPKey;
      this.queryParams.IntroduceKeyValue = data.MainDemoName;
      this.queryParams.IntroduceMobileSupport = data.Mobile;
      this.docloIntroduceList();
    },
    // 课类
    changeCoursework () {
      getCourseTypeNameList().then(result => {
        this.courseTreeList = [];
        result.data.forEach(obj => {
          // StateKey:3为停用 TypeKey:0-全部；1-课类；2-课名
          if (Number(obj.StateKey) == 2) {
            obj.UnitPrice = this.$utils.mAmountType(Number(obj.UnitPrice), 1);
            this.courseTreeList.push(obj);
          }
        });
        this.courseTreeList.splice(0, 0, {
          OLAPKey: '2',
          MainDemoName: '全部课程',
          TypeKey: '2',
          TypeKeyValue: '课类',
          LevelPath: '0.2.',
          FatherKey: '0',
          FatherKeyValue: '',
          StateKey: 2,
          StateKeyValue: '启用',
          UnitPrice: '0'
        });
        console.log(this.courseTreeList, 'this. this.courseTreeList');
        this.isShowMutilSelectCourseDialog = true;
      });
    },
    // 组合课-选择
    doFirstSelectTreeEvent (keys, vals) {
      console.log(keys, vals, 'this.组合课-选择');
    },
    // 组合课-确定
    doAfterSelectedMultiItem (selectionDate, callBack) {
      console.log(selectionDate, 'this.CourseTypeNameList');
      this.applyCourseDatas.IDs = '';
      this.applyCourseDatas.Names = '';
      if (selectionDate.selectedIDs.length == 0) {
        layer.alert('适用课程不能为空');
      } else {
        selectionDate.selectedIDs.forEach(o => {
          this.applyCourseDatas.IDs += o + ','; // 返课时类型课程IDs
        });
        selectionDate.selectedNames.forEach(o => {
          this.applyCourseDatas.Names += o + ','; // 返课时类型课程Values
        });
        this.queryParams.ClassTypeIDSName = this.applyCourseDatas.IDs.substring(0, this.applyCourseDatas.IDs.lastIndexOf(','));
        this.queryParams.ClassTypeName = this.applyCourseDatas.Names.substring(0, this.applyCourseDatas.Names.lastIndexOf(','));
        if (callBack) {
          callBack();
        }
      }
      this.isShowMutilSelectCourseDialog = false;
    },
    // 组合课-取消
    doclosemultiSelection () {
      this.isShowMutilSelectCourseDialog = false;
    },
    // 是否用过
    chgIsExperiKeySetting (name, key) {
      console.log(name, key, '是否用过');
      if (key == 0) {
        this.queryParams.CompetIDSName = null;
        this.queryParams.CompetName = null;
        this.queryParams.IsUseKey = null;
        this.queryParams.IsUseKeyValue = null;
      }
      this.queryParams.IsExperiKey = key;
      this.queryParams.IsExperiKeyValue = key > 0 ? '是' : '否';
    },
    // 用过
    changeChecked (dataList, textValue) {
      this.restsCompetName = textValue;
      this.queryParams.CompetIDSName = '';
      this.queryParams.CompetName = '';
      dataList.forEach(o => {
        if (o.isSelected) {
          this.queryParams.CompetIDSName = (this.queryParams.CompetIDSName == '' ? o.key : this.queryParams.CompetIDSName + ',' + o.key);// 1,2
          this.queryParams.CompetName = (this.queryParams.CompetName == '' ? o.name : this.queryParams.CompetName + ',' + o.name);// 校宝,小麦
        }
      });
      this.queryParams.CompetName = this.queryParams.CompetName.replace(/\其他/g, this.restsCompetName);
      console.log(this.queryParams.CompetIDSName, this.queryParams.CompetName, '用过');
    },
    // 是否在用
    chgIsUseKeySetting (name, key) {
      console.log(name, key, '是否用过');
      this.queryParams.IsUseKey = key;
      this.queryParams.IsUseKeyValue = key > 0 ? '是' : '否';
    },
    // 选择来源方式
    changeSourceType (obj) {
      console.log(obj, '选择来源方式');
      this.queryParams.SourceTypeKey = obj.type;
      this.queryParams.SourceTypeKeyValue = obj.name;
    },
    // 选择导流来源
    changeShuntType (obj) {
      console.log(obj, '选择来源方式');
      this.queryParams.ShuntTypeKey = obj.type;
      this.queryParams.ShuntTypeKeyValue = obj.name;
    },
    // 约单人
    changeStaff (obj) {
      console.log(obj, '选择来源方式');
      this.queryParams.EngageBillKey = obj.OLAPKey;
      this.queryParams.EngageBillKeyValue = obj.MainDemoName;
    },
    // 学员数
    changestudentCountType (obj) {
      this.queryParams.StudentCountKey = obj.type;
      this.queryParams.StudentCountKeyValue = obj.name;
    },
    //  关闭
    closeFrom () {
      this.$emit('closeFrom');
    },
    // 提交 afterSuccess
    submitDate (callBack) {
      console.log(this.queryParams, '提交');
      let isWrong = this.checkSubmitData();
      if (isWrong) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (this.OLAPKey && Number(this.OLAPKey) > 0) {
        EditThreadMarket(this.OLAPKey, this.queryParams).then(result => {
          layer.alert('修改成功!');
          this.closeFrom();
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        AddThreadMarket(this.queryParams).then(result => {
          layer.alert('添加成功!');
          this.closeFrom();
        }, error => {
          layer.alert(error.msg);
        });
      }
    },
    // 验证要提交的数据
    checkSubmitData () {
      let flag = false;
      if (!this.queryParams.SaaSClientNameKeyValue || !this.queryParams.SaaSClientNameKeyValue.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('机构名称不能为空');
        flag = true;
      } else if (!this.queryParams.ContactsName || !this.queryParams.ContactsName.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('联系人不能为空');
        flag = true;
      } else if (this.source.type == 3 && (!this.queryParams.IntroduceKeyValue || !this.queryParams.IntroduceKeyValue.replace(/(^\s*)|(\s*$)/g, ''))) {
        layer.alert('介绍人不能为空');
        flag = true;
      } else if (!this.queryParams.MobileSupport) {
        layer.alert('手机号不能为空');
        flag = true;
      } else if (this.queryParams.MobileSupport.length != 11 && this.queryParams.MobileSupport) {
        layer.alert('请输入11位的手机号码');
        flag = true;
      } else if (!this.queryParams.StudentCountKey) {
        layer.alert('学员数不能为空');
        flag = true;
      } else if (this.queryParams.IsExperiKey == 1 && !this.queryParams.CompetName && !this.queryParams.CompetIDSName && this.queryParams.CompetIDSName !== 0) { // 默认竞品
        layer.alert('请至少选择一项使用经历');
        flag = true;
      } else if (this.queryParams.IsExperiKey == 1 && !this.restsCompetName && (this.queryParams.CompetIDSName >= 0 ? this.queryParams.CompetIDSName === 0 : this.queryParams.CompetIDSName.indexOf('0') > -1)) { // 其他竞品
        layer.alert('请输入其他竞品名称');
        flag = true;
      }
      return flag;
    }
  }
};
</script>


