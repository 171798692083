<template>
  <div class="WX_setting_manage">
    <!-- 现有组件 -->
    <div class="flex">
      <date-range ref="saveDate"
                  :searchObj="searchObj"
                  :fromTitle="'消息时间'"
                  @changeDate="changeSaveDate"></date-range>
      <input-search-contain-btn v-model="searchObj.searchText"
                                :placeholder="'请输入'"
                                :clearAll="true"
                                @clearSearch="clearSearchCondition"
                                @onSearchEvent="searchData"></input-search-contain-btn>
    </div>
    <div class="WX_setting_manage_ul">
      <message-item v-for="(item,key) in dataReportList"
                    :key="key"
                    :item="item"
                    @reply="replyShow"></message-item>
      <div v-if="dataReportList.length==0"
           class="course_block_nodata"
           style="margin-top: 100px;">
        <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="table_pagination table_pagination_right">
      <div class="table_left_text">
        共{{totalNum}}条数据
      </div>
      <el-pagination @current-change="handleCurrentChange"
                     :page-size="searchObj.pageSize"
                     :current-page.sync="vPageIndex"
                     background
                     :pager-count="5"
                     :layout="true?'prev, pager, next, jumper':'prev, pager, next'"
                     :total="totalNum>0?totalNum:searchObj.pageSize"></el-pagination>
      <div class="table_number_select">
        <div class="select_box"
             v-show="pageSizeShow">
          <div class="select_list"
               v-for="(item,key) in pageSizeList"
               :key="key"
               :class="item.isActive?'opt':''"
               @click.stop="changePageSize(item)">
            <div>{{item.value}}</div>
          </div>
        </div>
        <div class="select_text"
             @click.stop="pageSizeShow=true">
          {{searchObj.pageSize}}条/页
        </div>
      </div>
    </div>
    <custom-dialog :title="'消息回复'"
                   class=" "
                   :visible.sync="isShowReply"
                   :before-close="closeShowReply">
      <reply-message :seletedItem="seletedItem"
                     @close="closeShowReply"
                     @afterSuccess="getDataList"></reply-message>
    </custom-dialog>
  </div>
</template>
<script>
import messageItem from './common/message-item';
import replyMessage from './common/reply-message';
import { MessageSearchPageForWeChatMessage } from '../../../../../API/workbench';
import inputSearchContainBtn from '../../../../report/common/input-search-contain-btn';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        type: '',
        sequence: 'DESC',
        QueryID: 0,
        GradeKey: 0,
        GradeKeyValue: '',
        TeacherKey: 0,
        TeacherKeyValue: '',
        CourseKey: 0,
        CourseKeyValue: '',
        AttenceStatusKey: '',
        AttenceStatusKeyValue: '',
        StudentKey: 0,
        StudentKeyVal: '',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd'), // this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd'),
        IsExportKey: 0
      },
      totalNum: 0,
      PageCount: 0,
      dataReportList: [],
      pageSizeShow: false,
      pageSizeList: [
        {
          value: 5,
          isActive: false
        },
        {
          value: 10,
          isActive: true
        },
        {
          value: 15,
          isActive: false
        },
        {
          value: 20,
          isActive: false
        },
        {
          value: 25,
          isActive: false
        },
        {
          value: 30,
          isActive: false
        }, {
          value: 35,
          isActive: false
        },
        {
          value: 40,
          isActive: false
        },
        {
          value: 45,
          isActive: false
        }, {
          value: 50,
          isActive: false
        },
        {
          value: 100,
          isActive: false
        },
        {
          value: 200,
          isActive: false
        }, {
          value: 500,
          isActive: false
        },
        {
          value: 1000,
          isActive: false
        },
        {
          value: 2000,
          isActive: false
        }
      ],
      seletedItem: null,
      isShowReply: false

    };
  },
  components: {
    inputSearchContainBtn, messageItem, replyMessage
  },
  created () {
    this.searchData();
  },
  computed: {
    vPageIndex: {
      get () {
        return this.searchObj.pageIndex + 1;
      },
      set (n, o) {
      }
    }
  },
  methods: {
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      // this.searchObj.orderBy = '';
      // this.searchObj.sequence = 'DESC';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';

      this.$refs.saveDate.resetTime();
      this.searchData();
    },
    searchData () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    getDataList () {
      this.isShowReply = false;
      MessageSearchPageForWeChatMessage(this.searchObj).then(result => {
        console.log(result.data, 'MessageSearchPageForWeChatMessage');
        this.totalNum = result.data.Total;
        this.dataReportList = result.data.PageDataList;
      }).catch(error => {
        console.log(error, 'errorMessageSearchPageForWeChatMessage');
      });
    },
    // 跳转到第几页
    handleCurrentChange (currentRow, oldCurrentRow) {
      console.log('handleCurrentChange', currentRow);
      // this.selectedRow = {};
      // this.$refs.multipleTable.setCurrentRow();
      this.searchObj.pageIndex = currentRow - 1;
      // this.$emit('loadTableData');
      this.getDataList();
    },
    changePageSize (item) {
      this.searchObj.pageSize = item.value;
      this.pageSizeList.forEach(o => {
        if (o.value == item.value) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      this.pageSizeShow = false;
      console.log('changePageSize');
      // this.$emit('loadTableData', this.searchObj);
      this.getDataList();
    },
    replyShow (item) {
      this.seletedItem = item;
      this.isShowReply = true;
    },
    closeShowReply () {
      this.isShowReply = false;
    }
  }
};
</script>

