<template>
  <div :class="[vClass]">
    <div class="apply_payment_box_title">
      {{formTitle}}
      <span>{{dataInfo.SetUpTime}}</span>
    </div>
    <div class="box_dot dot_green">
      课消金额
    </div>
    <div class="apply_payment_box_number">
      <span>￥</span>{{totalDeductAmount}}
    </div>
  </div>
</template>
<script>
export default {
  name: 'dayStatistics',
  data () {
    return {

    };
  },
  props: {
    vClass: String,
    formTitle: String,
    dataInfo: Object
  },
  computed: {
    totalDeductAmount () {
      if (this.dataInfo) {
        return (Math.round(Number(this.dataInfo.totalDeductAmount) * 100) / 100).toFixed(1);
      }
    }
  }
};
</script>

