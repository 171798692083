<template>
  <div class="statistics_list">
    <div class="statistics_list_title">{{vTitle}}</div>
    <div class="statistics_content">
      <div class="month_list">
        <div class="font_gray">辅导</div>
        <div class="font_number">{{Number(dataInfo.TutorialDateCount)||0}}</div>
      </div>
      <div class="month_list">
        <div class="font_gray">留存</div>
        <div class="font_number">{{Number(dataInfo.KeepAliveCount)||0}}</div>
      </div>
      <div class="month_list">
        <div class="font_gray">留存率</div>
        <div class="font_number">{{Math.round(Number(dataInfo.KeepAliveRate)||0)}}<span class="font_unit">%</span></div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    dataInfo: {
      type: Object
    },
    type: String
  },
  computed: {
    vTitle () {
      let title = '本月';
      switch (this.type) {
        case '0':
          title = '本月';
          break;
        case '1':
          title = (Date.new(Date.new().setMonth(Date.new().getMonth() - 1)).getMonth() + 1) + '月';
          break;
        case '2':
          title = (Date.new(Date.new().setMonth(Date.new().getMonth() - 2)).getMonth() + 1) + '月';
          break;
        case '3':
          title = (Date.new(Date.new().setMonth(Date.new().getMonth() - 3)).getMonth() + 1) + '月前';
          break;
        default:
          break;
      }
      return title;
    }
  },
  created () {

  }
};
</script>
