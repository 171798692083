<template>
    <div>
        <div class="register_student form_info_edit">
            <div class=" minHeight">
            <input-date v-model="date"
                          :formTitle="'选择日期'"
                          :readonly="false"
                          :required="false"
                          :formPlaceholder="'必填'"
                          @changeDate="changeDate"></input-date>
            
            <!-- 提交 -->
         
            
          
            </div>
              
        </div>
          <save-cancel-btn-group :isSingleSubmit="true"
                                    :singleSubmitType="'dynamic'"
                                    @confirmClick="confirmClick"
                                    @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>

 
</template>
<script>
import { AddStudentThreadFollowUp,GetFollowUpDetail,GetLabelGroupList } from "../../../API/workbench";
// 登记客户
export default {
  components: {},
  data() {
    return {
      date:''
    };
  },
  props: {
    seletedDate: String
  },
  computed: {
   
    
  },
  created() {
    
    
  },
  mounted() {},
  watch: {},
  methods: {
    changeDate(){
    
    },
    // 确定执行
    confirmClick(callBack) {
      const res = this.validata(callBack);
      if (!res) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      console.log('confirmClick',this.date)
        // 防止接口请求太慢导致重复提交
      this.$emit("afterSuccess",this.date)
 
    },
    // 验证客户信息
    validata(callBack) {
      let flag = true;
      if (!this.date) {
        layer.alert("请选择日期");
        flag = false;
      }
      return flag;
    },
    

    // 取消
    cancelClick(data) {
      this.$emit("close");
    }
  }
};
</script>
<style scoped>


</style>