<template>
  <div class="swiper-slide courseware_packge_list_item"
       @click.stop="seletedItem">
    <div class="courseware_packge_list_item_bg"
         :class="vdrakColor"></div>
    <img :src="vImg"
         alt="">
    <!-- https://cdnzxbeta.zhixuerj.com/Upload/Resource/10017/Image/20230129204308.png -->
    <div class="courseware_packge_list_item_bottom">
      {{item.CoursewarePackageKeyValue}}
    </div>
    <div class="teaching_mode_isLock"
         v-if="Number(item.IsLockKey)==0">

    </div>
    <div class="teaching_mode_no_auth"
         v-else-if="Number(item.ISAuthTeacherKey)==0&&!this.vRoleKey">

    </div>

  </div>
</template>
<script>
export default {
  data () {
    return {};
  },
  props: {
    item: Object
    //  {
    //     'CoursewarePackageKey':'课包key',
    //     'CoursewarePackageKeyValue':'课包keyValue',
    //     'ParentKey':'父级',
    //     'TypeKey':'类型0子类;1父类;2目录',
    //     'ISAuthTeacher':'是否课包老师授权:1-是;0-否;',
    //     'IsLockKey':'是否释放:1-已释放;0-未释放',
    //     'BigCoursewarePackageCoverUrl':'大课包封面路径',
    //     'ShowOrder':'子课包序号',
    // },
  },

  computed: {
    vImg () {
      if (this.item.CoverUrl) {
        if (this.item.CoverUrl.indexOf('http') > -1) {
          return this.item.CoverUrl;
        } else {
          return (
            this.$store.getters.CDNURL + this.item.CoverUrl
          );
        }
      } else {
        return '';
      }
    },
    vdrakColor () {
      if (Number(this.item.IsLockKey) == 0 || (Number(this.item.ISAuthTeacherKey) == 0 && !this.vRoleKey)) {
        return 'is_drak';
      } else {
        return '';
      }
    },
    // 判断是校长还是老师
    vRoleKey () {
      console.log(Number(this.$store.getters.token.RoleKey), '校长and老师');
      return this.$store.getters.token.RoleKey ? Number(this.$store.getters.token.RoleKey) == 2 : false; // 2为校长；非则为老师
    },
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    },
    vHasAuthModulePower () {
      let ModulePowerKey = this.store.getters.SaaSClientEditionKey == 5 ? 108 : 123;
      return this.$utils.CheckModulePower(
        this.vRolePowerList,
        ModulePowerKey
      );
    }
  },
  methods: {
    seletedItem () {
      console.log('seletedItem', this.item);
      // if(this.item.TypeKey==2){
      //   this.$emit("seletedItem", this.item);
      // }else{
      if (this.vRoleKey) {
        if (Number(this.item.IsLockKey) == 0) {
          layer.alert('总部尚未开放此课件', {
            mode: 'teachingMode'
          });
        } else if (Number(this.item.ISAuthTeacherKey) == 0 && this.item.TypeKey != 2) {
          //  this.$emit("seletedItem", this.item);
          layer.confirm(
            '您还没有该课包的授权，如果继续会自动将该课包授权给您，是否继续？',
            {
              mode: 'teachingMode',
              title: '消息',
              btn: [
                {
                  name: '确认',
                  callBack: () => {
                    this.$emit('seletedItem', this.item, 1);
                    this.item.ISAuthTeacherKey = 1;
                    layer.close();
                  }
                }
              ]
            }
          );
        } else {
          this.$emit('seletedItem', this.item);
        }
      } else {
        if (Number(this.item.IsLockKey) == 0) {
          layer.alert('总部尚未开放此课件', {
            mode: 'teachingMode'
          });
        } else if (Number(this.item.ISAuthTeacherKey) == 0) {
          layer.alert('课件未授权', {
            mode: 'teachingMode'
          });
        } else {
          this.$emit('seletedItem', this.item);
        }
      }
      // }
    }
  }
};
</script>
<style scoped>
.courseware_packge_list_item {
  width: 156.25rem !important;
  height: 117.19rem;
  margin-left: 10.5rem;
  position: relative;
  /* border: 0.78rem solid rgba(255, 255, 255, 0.4); */
  border-radius: 7.03rem;
  overflow: hidden;
  display: inline-block;
  background: #a9a9a9;
  margin-bottom: 10.92rem;
  margin-right: 4.8rem;
}
.courseware_packge_list_item:hover {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #2afea9 0.01%, #1c4ef4 102.48%);
  border: 1.17rem solid #ffffff;
  box-shadow: 0px 1.95rem 7.8rem rgba(22, 131, 249, 0.7);
  border-radius: 7.03rem;
  cursor: pointer;
}
.courseware_packge_list_item .courseware_packge_list_item_bg {
  /* background: linear-gradient(
    225.87deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 90.8%
  ); */
  /* opacity: 0.35; */
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('../../../../public/image/coureware_bg_highlight.png') center
    no-repeat;
  background-size: 100%;
}
.courseware_packge_list_item_bg.is_drak::after {
  display: block;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #000000, #000000),
    linear-gradient(180deg, #b94aff 0.01%, #6a20fe 102.48%);
  opacity: 0.5;
}
.courseware_packge_list_item img {
  width: 100%;
  /* height: 87%; */
  /* background: linear-gradient(225.87deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 90.8%);
        opacity: 0.35; */
}
.courseware_packge_list_item_bottom {
  width: 100%;
  height: 31.6rem;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1.17rem 2.34rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(7.5px);
  border-radius: 4.68rem;
  position: absolute;
  bottom: 0px;
  font-weight: 400;
  font-size: 9.36rem;
  line-height: 31.6rem;
  color: #ffffff;
  padding-left: 8.85rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* text-align: center; */
}
.teaching_mode_isLock {
  background: url('../../../../public/image/teaching_mode_lock.png') center
    no-repeat;
  background-size: 31.2rem;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.teaching_mode_no_auth {
  background: url('../../../../public/image/teaching_mode_courseware_noauth.png')
    center no-repeat;
  background-size: 31.2rem;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
</style>
