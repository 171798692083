import { render, staticRenderFns } from "./item.vue?vue&type=template&id=18f0e6ff&scoped=true"
import script from "./item.vue?vue&type=script&lang=js"
export * from "./item.vue?vue&type=script&lang=js"
import style0 from "./item.vue?vue&type=style&index=0&id=18f0e6ff&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18f0e6ff",
  null
  
)

export default component.exports