<template>
  <div class="face_informatio_entry_left fl">
    <div class="face_informatio_entry_title">
      <span>已录入学生</span>
      <span class="font_number">({{faceData.Total}}人)</span>
    </div>
    <div class="class_common_list_search" >
      <input type="text"
             style="margin-top:0"
             v-model="searchText"
             placeholder="搜索学生">
      <div class="search_btn "
           style="line-height:40px;top:0"
           @click.stop="search">搜索</div>
    </div>
    <div class="sundent_ul"
         @scroll="scrollTOP" v-if="vPageDataList.length>0">
      <student-item v-for="item in vPageDataList"
                    :key="item.OLAPKey"
                    :item="item"
                    @seletedItem="seletedItemAfter">
      </student-item>
      <div class="new_table_list_bottom no_border"
           v-if="isLoadEnd">
        <span class="loading_btn">加载中...</span>
      </div>
      <div class="new_table_list_bottom no_border"
           v-if="noMore">
        <span class="font_gray">已显示全部</span>
      </div>
    </div>
    <div class="course_block_nodata"
             style="padding-bottom:20px;"
             v-else>
          <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
    </div>
  </div>
</template>
<script>
import { getFaceRecognitionStuList } from '../../../API/face-recognition.js';
import studentItem from './student-item';
export default {
  data () {
    return {
      faceData: {
        Total: 0,
        PageDataList: []
      },    // 已登记的学生列表.
      searchData: {
        pageIndex: 0,
        pageSize: 20,
        searchText: ''
      },
      searchText: '', // 搜索字段.
      isLoadEnd: false,
      noMore: false
    };
  },
  components: {
    studentItem
  },
  props: {
    seletedStuItem: Object
  },
  computed: {
    vPageDataList () {
      let newArr = [];
      this.faceData.PageDataList.forEach(o => {
        if (o.OLAPKey == this.seletedStuItem.OLAPKey) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
        newArr.push(o);
      });
      return newArr;
    }
  },
  mounted () {
    this.getInFaceStudentList();
  },
  methods: {
    search () {
      this.noMore = false;
      this.searchData.pageIndex = 0;
      this.searchData.searchText = this.searchText;
      this.faceData.PageDataList = [];
      this.getInFaceStudentList();
    },
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;
      if ((ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight || (ETarget.scrollTop + 1) == ETarget.scrollHeight - ETarget.clientHeight) && !this.isLoadEnd && !this.noMore) {
        console.log('scrollTOP', this.noMore);
        this.getInFaceStudentList();
      }
    },
    getInFaceStudentList () {
      this.isLoadEnd = true;
      getFaceRecognitionStuList(this.searchData).then(result => {
        if (this.searchData.searchText == '') {
          this.faceData.Total = result.data.Total;
        }
        result.data.PageDataList.forEach(o => {
          if (this.seletedStuItem.OLAPKey > 0 && this.seletedStuItem.OLAPKey == o.OLAPKey) {
            o.isActive = true;
          } else {
            o.isActive = false;
          }
          this.faceData.PageDataList.push(o);
        });
        this.searchData.pageIndex++;
        if (result.data.PageDataList.length < this.searchData.pageSize) {
          this.noMore = true;
        }
        this.isLoadEnd = false;
        console.log('getFaceRecognitionStuList', result.data, this.faceData);
      }).catch(error => {
        this.isLoadEnd = false;
        console.log(error, 'err');
      }); 
    },
    seletedItemAfter (item) {
      console.log(item, 'seletedItemAfter');
      // 高亮效果.
      item.isActive = true;
      // this.faceData.PageDataList.forEach(o => {
      //   if (o.OLAPKey == item.OLAPKey) {
      //     o.isActive = true;
      //   } else {
      //     o.isActive = false;
      //   }
      // });
      this.$emit('seletedItem', item);
    }
  }
};
</script>
