<template>
  <div class="growth_time_list pr"
       v-if="vTitleImg">
    <div class="growth_time_list_date">
      <div class="date_month">20{{$utils.formatDateStr(dataInfo.SetpTime,'yy')}}</div>
      <div class="date_day">{{$utils.formatDateStr(dataInfo.SetpTime,'MM')}}/{{$utils.formatDateStr(dataInfo.SetpTime,'dd')}}</div>
    </div>
    <!-- TypeKey 类型 1-签到;2-请假;3-成长报告;4-完成作业 -->
    <div class="growth_time_list_content">
      <div style="display: flex;padding-bottom: 10px;">
        <div class="title_img"
             :class="vTitleImg"></div>
        <div class="title_text">{{titleText}}</div>
        <div type="primary"
             v-if="dataInfo.TypeKey == 3"
             @click.stop="deleteStudentGrowthTime"
             class="button_right_box btn_hover_bg_blue delete_student">撤销</div>
      </div>
      <!-- 签到 -->
      <div class="growth_time_list_title text_content_color"
           v-if="dataInfo.TypeKey==1">
        {{dataInfo.StudentKeyValue}}签到了
      </div>
      <!-- 请假 -->
      <div class="growth_time_list_title text_content_color"
           v-if="dataInfo.TypeKey==2">
        {{dataInfo.StudentKeyValue}}请假了
      </div>
      <!-- 旷课 -->
      <div class="growth_time_list_title text_content_color"
           v-if="dataInfo.TypeKey==5">
        {{dataInfo.StudentKeyValue}}旷课了
      </div>
      <!-- 发布成长报告 -->
      <!-- <div class="growth_time_list_title"
           v-if="dataInfo.TypeKey==3">
        {{vTeacher}}发布了学习成长报告
        <span class="WKT_btn_remove WKT_action_btn fr"
              @click.stop="delItem"></span>
      </div> -->
      <!-- <div class="growth_time_list_type type_01"
           v-if="dataInfo.TypeKey==3">
        <span>学习成长报告</span>
      </div> -->
      <!--  -->

      <!-- 交作业 -->
      <!-- <div class="growth_time_list_title"
           v-if="dataInfo.TypeKey==4">
        {{dataInfo.CustKeyValue}}提交了作业
      </div> -->
      <div class="growth_time_list_title text_content_color"
           v-if="dataInfo.TypeKey==4 ">
        作业：{{dataInfo.Title}}
      </div>
      <div class="growth_time_list_title text_content_color"
           v-if="dataInfo.TypeKey==4||dataInfo.TypeKey==7">
        课程：{{courseInfo.CourseNameKeyValue}}
      </div>
      <!-- <div class="growth_time_list_title"
           v-if="dataInfo.Comment">
        日期：{{dataInfo.OrderByTime}}
      </div> -->
      <div class="growth_time_list_title text_content_color"
           v-if="dataInfo.TypeKey==4||dataInfo.TypeKey==7">
        老师：{{dataInfo.AttenceOpratorKeyValue||dataInfo.MTeacherKeyValue||dataInfo.Comment.Author}}
      </div>
      <!-- <div class="growth_time_list_title"
           v-if="dataInfo.TypeKey==4 &&dataInfo.attachList.length>0">要求：{{dataInfo.Comment.Author}}
      </div> -->
      <div class="growth_time_list_title text_content_color list_credit_type"
           style="display:flex"
           v-if="dataInfo.TypeKey==7">
        <star-list :stuInfo="dataInfo"
                   :readonly="true"></star-list>
        <!-- <span>评分：</span>
        <comment :dataInfo="dataInfo"></comment> -->
      </div>
      <div class="growth_time_list_title text_content_color"
           v-if="dataInfo.TypeKey==4 &&dataInfo.attachList.length>0"
           v-html="'要求：'+$utils.translationSchoolText(dataInfo.ParentContent,studentInfo.MainDemoName)">
      </div>

      <div class="growth_time_list_title dpf text_content_color"
           v-if="dataInfo.TypeKey==4 &&dataInfo.attachList.length>0">
        <div class="compentsWork"></div> 完成作业
      </div>
      <!-- <div class="growth_time_list_type type_02"
           v-if="dataInfo.TypeKey==4">
        <span>完成了作业</span>
      </div> -->

      <div v-if="dataInfo.Content"
           class="growth_time_list_text text_content_color"
           v-html="'内容：'+$utils.translationSchoolText(dataInfo.Content,studentInfo.MainDemoName)">
      </div>
      <div v-if="dataInfo.Comment&&dataInfo.Comment.Content"
           class="growth_time_list_text text_content_color"
           v-html="'内容：'+$utils.translationSchoolText(dataInfo.Comment.Content,studentInfo.MainDemoName)">
      </div>
      <!-- 签到 -->
      <div v-if="dataInfo.Comment&&dataInfo.Comment.attachList.length>0">
        <!-- <swiper-img :dataInfo="dataInfo"
                    :studentInfo="studentInfo"></swiper-img> @doImgViewClick="doImgViewClick"-->
        <img-list :imgList="dataInfo.Comment.attachList"
                  :isMore="false"></img-list>
      </div>
      <!-- 做作业-->
      <div v-if="dataInfo.TypeKey==4||dataInfo.TypeKey==7">
        <img-list v-if="dataInfo&&dataInfo.attachList.length>0"
                  :imgList="dataInfo.attachList"
                  :isMore="false"></img-list>
        <!-- <work-swiper-img v-if="dataInfo&&dataInfo.attachList.length>0"
                         :dataInfo="dataInfo"
                         :studentInfo="studentInfo"></work-swiper-img> -->
      </div>
      <!-- 发布成长报告 -->
      <div v-if="dataInfo.TypeKey==3">
        <img-list v-if="dataInfo.attachList&&dataInfo.attachList.length>0"
                  :imgList="dataInfo.attachList"
                  :isMore="false"></img-list>
        <!-- <report-img v-if="dataInfo.attachList&&dataInfo.attachList.length>0"
                    :dataInfo="dataInfo"></report-img> -->
      </div>
    </div>
  </div>
</template>
<script>
import swiperImg from './growth-time-components/swiper-img';
import reportImg from './growth-time-components/report-img';
import workSwiperImg from './growth-time-components/work-swiper-img';
import comment from './growth-time-components/comment';
import imgList from '../../student/student-file/components/grow-up-time/img-module/img-list';
import starList from '../../home-school-communication/release-essay/new-release-comment/release-components/star-list'
export default {
  data () {
    return {
      titleText: ''
    };
  },
  props: {
    dataInfo: Object,
    studentInfo: Object,
    courseInfo: Object
  },
  components: {
    swiperImg, reportImg, comment, workSwiperImg, imgList, starList
  },
  created () {
    console.log('courseInfo', this.courseInfo);
  },
  computed: {
    vToken () {
      return this.$store.getters.token;
    },
    vTitleImg () {
      if (this.dataInfo.TypeKey == 1 || this.dataInfo.TypeKey == 2 || this.dataInfo.TypeKey == 5) {
        this.titleText = '其他通知';
        return 'title_img_type02';
      } else if (this.dataInfo.TypeKey == 7) {
        this.titleText = '点评记录';
        return 'title_img_type01';
      } else if (this.dataInfo.TypeKey == 3) {
        this.titleText = '学习成长报告';
        return 'title_img_type03';
      } else if (this.dataInfo.TypeKey == 4) {
        this.titleText = '作业通知';
        return 'title_img_type04';
      } else {
        return false;
      }
    },
    vTeacher () {
      // if (this.vToken.UserName.indexOf('老师') >= 0) {
      //   return this.vToken.UserName;
      // } else {
      //   return this.vToken.UserName + '老师';
      // }
      if (this.dataInfo.TypeKey == 3) {       // 成长报告
        if (this.dataInfo.Author.indexOf('老师') >= 0) {
          return this.dataInfo.Author;
        } else {
          return this.dataInfo.Author + '老师';
        }
      } else {
        if (this.dataInfo.AttenceOpratorKeyValue.indexOf('老师') >= 0) {
          return this.dataInfo.AttenceOpratorKeyValue;
        } else {
          return this.dataInfo.AttenceOpratorKeyValue + '老师';
        }
      }
    },
    vCommentTeacher () {
      if (this.dataInfo.Comment) {
        if (this.dataInfo.Comment.Author.indexOf('老师') >= 0) {
          return this.dataInfo.Comment.Author;
        } else {
          return this.dataInfo.Comment.Author + '老师';
        }
      }
    }
  },
  methods: {
    addReport () {
      this.$emit('addReport', this.dataInfo);
    },
    delItem () {
      this.$emit('delItem', this.dataInfo);
    },
    deleteStudentGrowthTime () {
      this.$emit('delItem', this.dataInfo);
    }
  }
};
</script>
<style scoped>
.date_month {
  font-size: 22px !important;
  line-height: 24px;
  /* color: #666; */
  font-family: 'DIN';
  font-style: normal;
  color: #313131 !important;
}
.growth_time_list_date {
  position: absolute;
  left: 0;
  top: 10px;
  width: 90px;
  height: 48px;
  background: initial;
  border: initial;
  border-radius: 12px;
  text-align: center;
}
.date_day {
  font-size: 18px;
  line-height: 2 !important;
  /* color: #666; */
  font-family: 'DIN';
  font-style: normal;
  color: #313131 !important;
}
.growth_time_list:before {
  width: 0px;
}
.title_img {
  width: 32px;
  height: 32px;
  background-size: 100%;
}
.title_img_type01 {
  background-image: url(../../../../public/image/wktImg/dp.png);
}
.title_img_type02 {
  background-image: url(../../../../public/image/wktImg/other.png);
}
.title_img_type03 {
  background-image: url(../../../../public/image/wktImg/report.png);
}
.title_img_type04 {
  background-image: url(../../../../public/image/wktImg/work.png);
}
.growth_time_list_content {
  padding: 10px 20px 30px 20px;
  width: 95% !important;
}
.title_text {
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #313131;
  padding-left: 10px;
}
.text_content_color {
  color: #666666;
}
.growth_time_list_title {
  line-height: 30px;
  font-size: 16px !important;
}
.dpf {
  display: flex;
}
.compentsWork {
  background-image: url(../../../../public/image/wktImg/compent.png);
  width: 15px;
  height: 11px;
  background-size: contain;
  margin-top: 11px;
  margin-right: 10px;
}
.delete_student {
  width: 50px;
  position: absolute;
  text-align: center;
  align-items: center;
  line-height: 28px;
  border-radius: 4px;
  right: 50px;
}
</style>