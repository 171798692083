<template>
  <div class="statistics_echarts_right fl">
    <div class="apply_payment_box_title">
      {{vFormTitle}} <span>{{vYear}}</span>
    </div>
    <div class="box_month">
      <div class="box_dot"
           :class="[dotColor]">
        新增客户线索
        <span></span>
      </div>
      <div class="apply_payment_box_number">
        {{yearTotalPeople}}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'rightSide',
  data () {
    return {

    };
  },
  props: {
    reportList: Array,
    selectedDate: Date,
    dotColor: String
  },
  computed: {
    vYear () {
      return this.$utils.formatDateStr(this.selectedDate.toLocaleDateString(), 'yyyy') + '年';
    },
    // 年统计人数
    yearTotalPeople () {
      let count = 0;
      this.reportList.forEach(o => {
        count += Number(o.TotalPerson);
      });
      return count;
    },
    // 判断显示本年
    vFormTitle () {
      if (this.selectedDate.getFullYear() == Date.new().getFullYear()) { return '本年'; }
    }
  }

};
</script>

