<template>
  <div class="credit_management_popup">
    <div class="credit_management_table">
      <div class="heard">
        <!-- <input-search-contain-btn v-model="searchObj.searchText"
                                  style="margin-top:7px;margin-right: 80px;width:400px"
                                  :placeholder="'请输入请输入学生名、订单号'"
                                  @clearSearch="clearSearchParams"
                                  @onSearchEvent="searchData"></input-search-contain-btn> -->

        <inputSearchContainBtn v-model="searchObj.searchText"
                               style="margin-left:20px;width:420px"
                               :placeholder="'请输入学生名、订单号'"
                               @onSearchEvent="searchData"
                               @clearSearch="clearSearchParams"></inputSearchContainBtn>
        <div class="heardItem">
          <function-btn :item="{ name: '设置', ModulePowerKey: '' }"
                        :className="'el-button btn_light_blue btn_hover_bg_blue iconPlanC'"
                        style="width: 76px;margin-right:10px;background-color: #3498db;border: 1px solid #2e86c1;"
                        @clickItem="openSetting">
          </function-btn>
          <function-btn :item="{ name: '导出', ModulePowerKey: '' }"
                        :className="'el-button btn_light_blue btn_hover_bg_blue iconPlanC'"
                        style="width: 76px;background-color: #3498db;border: 1px solid #2e86c1;"
                        @clickItem="doExportInfoClick">
          </function-btn>
        </div>
      </div>
      <table-list v-if="vSettingShow"
                  class="summarizing_list table_list_content"
                  :tableData="vdataReportList"
                  :tableColumns="tableColumsList"
                  :queryParams="searchObj"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  @loadTableData="getDataList"></table-list>
    </div>
    <custom-dialog title="关联转化课单"
                   width="800px"
                   :visible.sync="isShowRelevantTable"
                   :before-close="closeRelevantTableDialog">
      <relevant-table :tableID="tableID"
                      @afterSuccess="relevantTableSuccess"
                      @cancelClick="closeRelevantTableDialog"></relevant-table>
    </custom-dialog>
    <custom-dialog title="设置"
                   width="400px"
                   :visible.sync="isOpenSetting"
                   :before-close="closeSupplementtarySetting">
      <supplementtarySetting @afterSuccess="supplementtarySettingSuccess"
                             @cancelClick="closeSupplementtarySetting"></supplementtarySetting>
    </custom-dialog>
  </div>
</template>
<script>
import { GetConversionUntreated, RelevantGrade, GetSalesList, RelevantSales } from '../../../API/workbench';
import tableList from '../../common/table-list/index.vue';
import relevantTable from '../../common/relevant-table.vue';
import supplementtarySetting from './supplementtary-setting.vue';
import inputSearchContainBtn from '../../report/common/input-search-contain-btn.vue';
export default {
  data () {
    return {
      data1: {
        SetKey: '0'
      },
      data2: {
        SetKey: '0'
      },
      data3: {
        SetKey: '0'
      },
      isOpenSetting: false,
      tableID: '',
      seletedItem: null, // 选中单个
      showAddForm: false,
      studentName: '',
      CounselorKey: '',
      CounselorKeyValue: '',
      dataReportList: [],
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        ClassType: '4'
      },
      isShowRelevantTable: false,
      StudentKey: '',
      IsRenewalKey: '',
      ids: '',
      tableData: {},
      totalNum: 0,
      tableColumsList: [],
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '购课日期',
          prop: 'GenerateTime',
          width: 120,
          sortable: false,
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '订单号',
          prop: 'NumberName',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            type: 'expand',
            click: (rowData) => {
              this.openbaoming(rowData);
            }
          }
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '手机号',
          prop: 'ParPhoneName',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '实收款',
          prop: 'ActualColAmountText',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '生效日期',
          prop: 'StartTimeText',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '失效日期',
          prop: 'EndTime',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        }

        // ，{
        //   label: '关联转化单',
        //   prop: 'option',
        //   type: 'hidden-btn',
        //   minWidth: 200,
        //   align: 'center',
        //   extend: {
        //     tableBtnGroup: [
        //       {
        //         name: `RelevanceContent`,
        //         extend: {
        //           click: (rowData) => {
        //             console.log('rowData', rowData);
        //           }
        //         }
        //       }
        //     ]
        //   }
        // }
      ]
    };
  },
  props: {
  },
  components: {
    relevantTable,
    tableList,
    supplementtarySetting,
    inputSearchContainBtn
  },
  watch: {
    // 优惠金额
    'vSettingShow': {
      handler (newValue, oldValue) {
        if (this.vSettingShow) {
          // this.getDataList();
          console.log(1111);
        }
      },
      deep: true
    }
  },
  computed: {
    vSaaSClientSetting () {
      return this.$store.getters.SaaSClientInfo.SaaSClientSetting;
    },
    vCheckModulePower () {
      if (this.$utils.CheckModulePower(this.$store.getters.RolePowerList, 51)) {
        return true;
      } else {
        return false;
      }
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.StudentCount = Number(o.StudentCount) || 0;
        o.ThisMonthFlow = this.$utils.flowFormate(o.ThisMonthFlow);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    },
    vAddFormTitle () {
      return !this.seletedItem ? '新增班级' : '修改班级';
    },
    vSeletedItemKey () {
      return this.seletedItem ? this.seletedItem.OLAPKey : 0;
    },
    vSettingShow () {
      if (this.data1.SetKey != '1' && this.data2.SetKey != '1' && this.data3.SetKey != '1') {
        return false;
      }
      return true;
    }
  },
  created () {

  },
  mounted () {
    setTimeout(() => {
      this.tableColumsList = JSON.parse(JSON.stringify(this.tableCourseColumns));
      let index1 = this.vSaaSClientSetting.findIndex((item) => {
        return item.SetTypeKey == '100';
      });
      // console.log();
      this.data1 = JSON.parse(JSON.stringify(this.vSaaSClientSetting[index1]));
      if (this.data1.SetKey == '1') {
        this.tableColumsList.push({
          label: '销售业绩归属',
          prop: 'SaleMansKeyText',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            type: 'expand',
            click: (rowData) => {
              this.openCounselorList(rowData);
            }
          }
        });
      }
      console.log('data1', this.data1, index1);
      let index2 = this.vSaaSClientSetting.findIndex((item) => {
        return item.SetTypeKey == '101';
      });
      console.log();
      this.data2 = JSON.parse(JSON.stringify(this.vSaaSClientSetting[index2]));
      if (this.data2.SetKey == '1') {
        this.tableColumsList.push({
          label: '进班排课',
          prop: 'AttendClassKeyText',
          sortable: false,
          width: 120,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            type: 'expand',
            click: (rowData) => {
              this.showSingleSelectionDialog(rowData);
            }
          }
        });
      }
      console.log('data2', this.data2, index2);
      let index3 = this.vSaaSClientSetting.findIndex((item) => {
        return item.SetTypeKey == '102';
      });
      console.log();
      this.data3 = JSON.parse(JSON.stringify(this.vSaaSClientSetting[index3]));
      if (this.data3.SetKey == '1') {
        this.tableColumsList.push({
          label: '关联转化',
          prop: 'RelationScheduleKeyText',
          sortable: false,
          width: 120,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            type: 'expand',
            click: (rowData) => {
              this.openRelevantFormDialog(rowData);
            }
          }
        });
      }
      console.log('data3', this.data3, index3);
      if (this.vSettingShow) {
        this.getDataList();
      } else {
        this.openSetting();
      }
    }, 500);

    this.$dialog.register(this, ['cwsClassDetail']);
  },
  methods: {
    openSetting () {
      this.isOpenSetting = true;
    },
    closeSupplementtarySetting () {
      this.isOpenSetting = false;
    },
    clearSearchParams () {
      this.searchObj = {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        ClassType: '4',
        IsExportKey: 0
      };
      this.getDataList();
    },
    searchData () {
      if (this.vSettingShow) {
        this.getDataList();
      }
    },
    unitKeyValue (type) {
      let a = '';
      switch (type) {
        case '2':
          a = '年';
          break;
        case '3':
          a = '月';
          break;
        case '4':
          a = '日';
          break;
        case '5':
          a = '课时';
          break;

        default:
          break;
      }
      return a;
    },
    // 查看详情
    goDetail (item) {
      this.seletedItem = item;
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '班级详情',
        moduleName: 'cwsClassDetail',
        data: {
          classKey: this.seletedItem.OLAPKey,   // 班级详情适用， 班级Key
          isTeacher: false   // 班级详情适用， 是否老师， false则为校长
        },
        callBack: {
          afterSuccess: () => { this.getDataList(); }
        }
      });
    },
    // 修改单个
    editItem (item) {
      this.seletedItem = item;
      this.showAddForm = true;
    },
    // 新增按钮
    addItem () {
      this.seletedItem = null;
      this.showAddForm = true;
    },
    closeDialog () {
      this.showAddForm = false;
    },
    updateData () {
      if (this.vSettingShow) {
        this.getDataList();
      }
    },
    // 重置查询条件
    initParamsForQuery () {
      this.dataReportList = [];
      this.searchObj.searchText = '';
      this.searchObj.pageIndex = 0;
    },
    // 点击分类
    clcikStatictisItem (item) {
      this.initParamsForQuery();
      this.searchObj.ClassType = item.type;
      this.getDataList();
    },
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      if (this.vSettingShow) {
        this.getDataList();
      }
    },
    search () {
      this.searchObj.pageIndex = 0;
      if (this.searchObj.searchText == '') {
        this.searchObj.StudentKey = '';
      }
      if (this.vSettingShow) {
        this.getDataList();
      }
    },
    // 获取来源方式
    openCounselorList (data) {
      this.CounselorKey = data.SaleManIDsName;
      this.CounselorKeyValue = data.SaleMansName;
      this.tableID = data.TableID;
      GetSalesList().then(
        (result) => {
          this.CounselorList = result.data;
          this.CounselorList.unshift({
            OLAPKey: 0,
            MainDemoName: '不关联'
          });
          this.$dialog.open(this, {
            routerName: this.$route.name,
            name: '选择销售顾问',
            moduleName: 'singleSelectionList',
            data: {
              selectedKey: this.CounselorKey,
              dataSourceList: this.CounselorList,
              required: false,
              ModulePowerKey: 0
            },
            callBack: {
              onSelectedItem: (data) => {
                this.CounselorKey = data.OLAPKey;
                this.CounselorKeyValue = data.MainDemoName;
                RelevantSales(this.tableID, this.CounselorKey).then((res) => {
                  console.log('关联销售成功', res.data);
                  this.getDataList();
                });
              }
            }
          });
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    showSingleSelectionDialog (data) {
      let studentInfo = {
        OLAPKey: data.StudentKey,
        MainDemoName: data.StudentKeyValue
      };
      this.tableID = data.TableID;
      // 判断权限,
      if (this.vCheckModulePower) {
        this.$dialog.open(this, {
          name: '加入班级',
          routerName: this.$route.name,
          routertitle: '',
          ModulePowerKey: 51,
          moduleName: 'addStudentToBJ',
          data: { dataInfo: { studentInfo: studentInfo, type: '进班', isPayment: true, selectedKey: this.classKey } },
          callBack: {
            onSelectedItem: (data) => {
              console.log('data', data);
              this.classKey = data.OLAPKey;
              this.className = data.MainDemoName;
              RelevantGrade(this.tableID, this.classKey, this.className).then((res) => {
                console.log('关联班级成功', res.data);
                this.getDataList();
              });
            }
          }
        });
        // this.$emit('closeDialog');
      } else {
        this.$utils.CheckModulePowerToTips(51);
      }
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        var newData = this.$utils.parseJson(this.searchObj);
        this.searchObj.IsExportKey = 1;
        this.searchObj.pageIndex = 0;
        this.searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '待处理',
          clist: [
            { title: '购课日期', cName: 'GenerateTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '订单号', cName: 'NumberName', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '学生名', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '手机号', cName: 'ParPhoneName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '实收款', cName: 'ActualColAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '生效日期', cName: 'StartTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '失效日期', cName: 'EndTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '排班进课', cName: 'AttendClassKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '销售业绩归属', cName: 'SaleMansName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '关联转化', cName: 'RelationScheduleKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        GetConversionUntreated(this.searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.searchObj.IsExportKey = 0;
          this.searchObj.pageIndex = newData.pageIndex;
          this.searchObj.pageSize = newData.pageSize;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList () {
      this.dataReportList = [];
      GetConversionUntreated(this.searchObj).then(
        (res) => {
          console.log('CWSCWSSearchPageClassList', res.data);
          this.totalNum = res.data.Total;
          this.dataReportList = res.data.PageDataList;
          this.dataReportList.forEach(item => {
            // item.NumCountText = Number(item.NumCount).toFixed(2) + this.unitKeyValue(item.UnitKey);
            item.ActualColAmountText = Number(item.ActualColAmount) == 0 ? '-' : '￥' + this.$utils.setMoneyformatToParseInt(Number(item.ActualColAmount));
            item.AttendClassKeyText = item.AttendClassKey == '-1' ? '未设置' : item.AttendClassKey == '0' ? '无' : item.AttendClassKeyValue;
            item.SaleMansKeyText = item.SaleManIDsName == '-1' ? '未设置' : item.SaleManIDsName == '0' ? '无' : item.SaleMansName;
            item.RelationScheduleKeyText = item.RelationScheduleKey == '-1' ? '未设置' : item.RelationScheduleKey == '0' ? '无' : item.RelationScheduleKey == '1' ? '部分设置' : '已设置';
            item.StartTimeText = item.StartTime == '1900-01-01' ? '-' : item.StartTime;
            item.ParPhoneName = item.ParPhoneName ? item.ParPhoneName : '-';
          });
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    openbaoming (data) {
      this.$dialog.open(this, {
        name: '报名收款',
        routerName: this.$route.name,
        moduleName: 'signuppayment',
        moduleDescription: '学员报名购买课程，如未登记的学生可点界面“+”新增学生信息后再进行报名。',
        data: { dataInfo: { StudentKey: data.StudentKey, TableID: data.TableID, isReadOnly: true } },
        callBack: { afterSucces: () => { this.reload(); } }
      });
    },
    closeRelevantTableDialog () {
      this.isShowRelevantTable = false;
    },
    openRelevantFormDialog (data) {
      this.tableID = data.TableID;
      console.log('TableID', this.tableID);
      this.isShowRelevantTable = true;
    },
    relevantTableSuccess () {
      if (this.vSettingShow) {
        this.getDataList();
      }
    },
    relevantFormSuccess () {
      console.log('成功ok');
      if (this.vSettingShow) {
        this.getDataList();
      }
    },
    supplementtarySettingSuccess (data) {
      this.tableColumsList = JSON.parse(JSON.stringify(this.tableCourseColumns));
      this.data1 = data[0];
      if (this.data1.SetKey == '1') {
        this.tableColumsList.push({
          label: '销售业绩归属',
          prop: 'SaleMansKeyText',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            type: 'expand',
            click: (rowData) => {
              this.openCounselorList(rowData);
            }
          }
        });
      }
      this.data2 = data[1];
      if (this.data2.SetKey == '1') {
        this.tableColumsList.push({
          label: '进班排课',
          prop: 'AttendClassKeyText',
          sortable: false,
          width: 120,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            type: 'expand',
            click: (rowData) => {
              this.showSingleSelectionDialog(rowData);
            }
          }
        });
      }
      this.data3 = data[2];
      if (this.data3.SetKey == '1') {
        this.tableColumsList.push({
          label: '关联转化',
          prop: 'RelationScheduleKeyText',
          sortable: false,
          width: 120,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue ',
            type: 'expand',
            click: (rowData) => {
              this.openRelevantFormDialog(rowData);
            }
          }
        });
      }
      if (this.vSettingShow) {
        setTimeout(() => {
          this.getDataList();
        }, 100);
      }
    }
  }
};
</script>
<style scoped>
.heard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}
.heardItem {
  display: flex;
  margin-right: 20px;
  height: 60px;
  align-items: center;
}
</style>