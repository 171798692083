<template>
  <!-- 招生工作台 -->
  <div class="recruit_students_list">
    <div class="recruit_content_div">
      <div class="students_schedule">
        <div class="personal_achievements">
          <!-- <div class="achievements_text">个人成果</div> -->

          <div class="table_select_box">
            <div class="table_select_title">个人成果</div>
            <el-select v-model="personalAchievements"
                       placeholder="请选择"
                       style="width:239px"
                       ref="resetText"
                       @change="selectTimeType">
              <el-option v-for="item in timeTypeList"
                         :key="item.OLAPKey"
                         :label="item.MainDemoName"
                         :value="item">
              </el-option>
            </el-select>
          </div>

        </div>
        <!--  -->
        <div class="application_Collection_div">
          <div class="schedule_both_div"
               style="border-right: 2px solid;">
            <div class="schedule_text">报名收款(元)</div>
            <div class="schedule_num">{{$utils.setMoneyformatToParseInt(personalAmObj.ActualColAmount)}}</div>
          </div>
          <div class="schedule_both_div s_div">
            <div class="schedule_text">新增线索</div>
            <div class="schedule_num">{{personalAmObj.NewThreadCount}}</div>
          </div>
          <img src="../../../public/image/schedule_icon.png"
               class="schedule_img"
               alt="">
          <div class="schedule_both_div s_div">
            <div class="schedule_text">已诺访</div>
            <div class="schedule_num">{{personalAmObj.PromiseVisitCount}}</div>
          </div>
          <img src="../../../public/image/schedule_icon.png"
               class="schedule_img"
               alt="">
          <div class="schedule_both_div s_div">
            <div class="schedule_text">约试课</div>
            <div class="schedule_num">{{personalAmObj.InvitedClassCount}}</div>
          </div>
          <img src="../../../public/image/schedule_icon.png"
               class="schedule_img"
               alt="">
          <div class="schedule_both_div s_div">
            <div class="schedule_text">已试课</div>
            <div class="schedule_num">{{personalAmObj.TrialLessonCount}}</div>
          </div>
          <img src="../../../public/image/schedule_icon.png"
               class="schedule_img"
               alt="">
          <div class="schedule_both_div s_div">
            <div class="schedule_text">已报名</div>
            <div class="schedule_num">{{personalAmObj.SignUpCount}}</div>
          </div>
        </div>

      </div>
      <!--  -->
      <div class="option_title">
        <div class="general_summarizing_student_navUl">
          <div class="title_list"
               @click="tableSwitch(1)"
               :class="{ opt: tableType == 1 }">
            线索
          </div>
          <div class="title_list"
               @click="tableSwitch(2)"
               :class="{ opt: tableType == 2 }">
            跟单记录
          </div>
          <div class="title_list"
               @click="tableSwitch(3)"
               :class="{ opt: tableType == 3 }">
            诺访记录 <span style="color: red;">{{ recordUntreatedDataObj.PromiseVisitCount }}</span>
          </div>
          <div class="title_list"
               @click="tableSwitch(4)"
               :class="{ opt: tableType == 4 }">
            试课记录 <span style="color: red;">{{ recordUntreatedDataObj.TrialLessonCount }}</span>
          </div>
          <div class="title_list"
               @click="tableSwitch(5)"
               :class="{ opt: tableType == 5 }">
            报名记录 <span style="color: red;">{{ recordUntreatedDataObj.SignUpCount }}</span>
          </div>
        </div>
      </div>
      <!-- 线索 -->
      <div class=""
           v-if="tableType == 1">
        <clue-list @openDialog="openCustomDialogByFactoryDialog"
                   @afterSuccess="afterSuccess"></clue-list>
      </div>
      <!-- 跟单记录 -->
      <div class=""
           v-if="tableType == 2">
        <documentary-records-list></documentary-records-list>
      </div>
      <!-- 诺访记录 -->
      <div class=""
           v-if="tableType == 3">
        <nuofang-records-list></nuofang-records-list>
      </div>
      <!-- 试课记录 -->
      <div class=""
           v-if="tableType == 4">
        <trial-class-records-list></trial-class-records-list>
      </div>
      <!-- 报名记录 -->
      <div class=""
           v-if="tableType == 5">
        <application-records-list></application-records-list>
      </div>

    </div>

    <div>
      <dialog-factory ref="recruitStudentsWorkbenches"
                      :routerName="'recruitStudentsWorkbenches'"></dialog-factory>
    </div>
    <dialog-factory-common ref="recruitStudentsWorkbenchescommon"></dialog-factory-common>
  </div>
</template>
<script>
import { PersonalAchievement, ThreadAllExpireRecordCount } from '../../API/workbench.js';
import dialogFactory from '../common/dialog-factory';
import clueList from './clue-list/index.vue';// 线索
import documentaryRecordsList from './documentary-records-list/index.vue';// 跟单记录
import nuofangRecordsList from './nuofang-records-list/index.vue';// 诺访记录
import trialClassRecordsList from './trial-class-records-list/index.vue';// 试课记录
import applicationRecordsList from './application-records-list/index.vue';// 报名记录
export default {
  data () {
    return {
      searchObj: {
        StartTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        EndTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0))
      },
      personalAchievements: '本月',
      personalAmObj: {
        ActualColAmount: 0, // 报名收款
        NewThreadCount: 0, // 新增线索
        PromiseVisitCount: 0, // 已诺访
        InvitedClassCount: 0, // 约试课
        TrialLessonCount: 0, // 已试课
        SignUpCount: 0 // 已报名
      },
      recordUntreatedDataObj: {
        PromiseVisitCount: '', // 诺访记录过期未处理数量
        TrialLessonCount: '', // 试课记录过期未处理数量
        SignUpCount: '' // 报名记录过期未处理数量
      },
      timeTypeList: [
        {
          MainDemoName: '今日',
          OLAPKey: 1
        },
        {
          MainDemoName: '昨日',
          OLAPKey: 2
        },
        {
          MainDemoName: '本周',
          OLAPKey: 3
        },
        {
          MainDemoName: '上周',
          OLAPKey: 4
        },
        {
          MainDemoName: '本月',
          OLAPKey: 5
        },
        {
          MainDemoName: '上月',
          OLAPKey: 6
        }
      ],
      tableType: 1
    };
  },
  components: {
    dialogFactory,
    clueList,
    documentaryRecordsList,
    nuofangRecordsList,
    trialClassRecordsList,
    applicationRecordsList
  },
  computed: {
    vSaaSClientSetting () {
      return this.$store.getters.SaaSClientInfo.SaaSClientSetting;
    }
  },
  created () {
    this.getPersonalAchievement();
    this.registerBusEvent();
    this.getThreadAllExpireRecordCount();
  },
  watch: {
  },
  activated () {
  },
  mounted () {
    this.$dialog.register(this, ['clewAddForm']);
  },
  methods: {
    // 最近跟单
    tableSwitch (type) {
      this.tableType = type;
    },
    // 招生工作台--线索记录过期未处理数量统计
    getThreadAllExpireRecordCount () {
      ThreadAllExpireRecordCount(this.searchObj.StartTime, this.searchObj.EndTime).then(result => {
        console.log(result, '线索 数据查询');
        this.recordUntreatedDataObj.PromiseVisitCount = Number(result.data.PromiseVisitCount) || '';
        this.recordUntreatedDataObj.TrialLessonCount = Number(result.data.TrialLessonCount) || '';
        this.recordUntreatedDataObj.SignUpCount = Number(result.data.SignUpCount) || '';
      }, error => {
        layer.alert(error.msg);
      });
    },
    afterSuccess () {
      this.getThreadAllExpireRecordCount();
    },
    // 个人成果
    getPersonalAchievement () {
      PersonalAchievement(this.searchObj.StartTime, this.searchObj.EndTime).then(result => {
        console.log(result, '线索 数据查询');
        this.personalAmObj.ActualColAmount = Number(result.data.ActualColAmount) || 0;
        this.personalAmObj.NewThreadCount = Number(result.data.NewThreadCount) || 0;
        this.personalAmObj.PromiseVisitCount = Number(result.data.PromiseVisitCount) || 0;
        this.personalAmObj.InvitedClassCount = Number(result.data.InvitedClassCount) || 0;
        this.personalAmObj.TrialLessonCount = Number(result.data.TrialLessonCount) || 0;
        this.personalAmObj.SignUpCount = Number(result.data.SignUpCount) || 0;
      }, error => {
        layer.alert(error.msg);
      });
    },
    formatDate (date) {
      date = Date.new(date.replace(/-/g, '/'));
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
    },
    setChoseEndTime (timer) {
      let Year = timer.getFullYear();
      let Month = timer.getMonth() + 1;
      let EndDate = Date.new(Year, Month, 0).getDate();
      return Year + '-' + Month + '-' + EndDate;
    },
    goTimerFun (num) {
      let timer = new Date();
      var date2 = new Date(timer);
      date2.setDate(timer.getDate() - num);
      var time2 = date2.getFullYear() + '-' + (date2.getMonth() + 1) + '-' + date2.getDate();
      return time2;
    },
    selectTimeType (item) {
      this.personalAchievements = item.MainDemoName;
      let monday = new Date();
      let sunday = new Date();
      let timer = new Date();
      let goStartTimer = '';
      let goEndTimer = '';
      if (item.OLAPKey == 1) {
        // 今天
        goStartTimer = this.formatDate(timer.getFullYear() + '-' + (Number(timer.getMonth()) + 1) + '-' + (Number(timer.getDate())));
        goEndTimer = this.formatDate(timer.getFullYear() + '-' + (Number(timer.getMonth()) + 1) + '-' + (Number(timer.getDate())));
      } else if (item.OLAPKey == 2) {
        // 昨天
        goStartTimer = this.formatDate(timer.getFullYear() + '-' + (Number(timer.getMonth()) + 1) + '-' + (Number(timer.getDate()) - 1));
        goEndTimer = this.formatDate(timer.getFullYear() + '-' + (Number(timer.getMonth()) + 1) + '-' + (Number(timer.getDate()) - 1));
      } else if (item.OLAPKey == 3) {
        // 本周
        const nowDay = (new Date()).getDay() || 7;
        monday.setDate(monday.getDate() - nowDay + 1);
        sunday.setDate(sunday.getDate() - nowDay + 7);
        goStartTimer = monday.toLocaleDateString();
        goEndTimer = sunday.toLocaleDateString();
      } else if (item.OLAPKey == 4) {
        // 上周
        const nowDay = (new Date()).getDay() || 7;
        monday.setDate(monday.getDate() - nowDay - 6);
        sunday.setDate(sunday.getDate() - nowDay);
        goStartTimer = monday.toLocaleDateString();
        goEndTimer = sunday.toLocaleDateString();
      } else if (item.OLAPKey == 5) {
        // 本月
        goStartTimer = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        goEndTimer = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0));
      } else if (item.OLAPKey == 6) {
        // 上月
        timer.setMonth(timer.getMonth() - 1);
        goStartTimer = this.$utils.formatDateStr(Date.new(timer.setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        goEndTimer = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth()), 0));
      }
      this.searchObj.StartTime = goStartTimer;
      this.searchObj.EndTime = goEndTimer;
      console.log(goStartTimer, 'goStartTimer goEndTimer', goEndTimer, 'this.searchObj', this.searchObj, 'item', item);
      this.getPersonalAchievement();
      this.getThreadAllExpireRecordCount();
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'recruitStudentsWorkbenches') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'recruitStudentsWorkbenches') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },

    // 单例
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCustomDialogByFactoryDialog', dialogInfo);
      // this.$emit('openCustomDialog', dialogInfo);
      if (dialogInfo.routerName == 'recruitStudentsWorkbenches') {
        if (this.$refs.recruitStudentsWorkbenches) {
          this.$refs.recruitStudentsWorkbenches.openDialog(
            dialogInfo.moduleName,
            dialogInfo.dataKey,
            dialogInfo.data,
            dialogInfo.callBack,
            dialogInfo.name
          );
        }
      }
    },
    // 多例
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      // this.$emit('openCommonCustomDialog', dialogInfo);
      if (this.$refs.recruitStudentsWorkbenchescommon) {
        this.$refs.recruitStudentsWorkbenchescommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>

<style scoped>
#recruitStudentsWorkbenches {
  height: 100%;
}
.recruit_students_list {
  max-width: 1920px;
  margin: auto;
  height: 100%;
  background: #fff;
}
.recruit_content_div {
  margin: 0 40px;
}
.table_select_box {
  padding: 20px 0;
}
.table_select_title {
  font-size: 24px;
  color: #000;
  margin-right: 20px;
}
.application_Collection_div {
  display: flex;
  text-align: center;
}
.schedule_both_div {
  line-height: 15px;
  padding-right: 30px;
  height: 50px;
}
.schedule_text {
  color: #666;
}
.schedule_num {
  font-size: 25px;
  color: #000;
  font-weight: 600;
  margin-top: 17px;
}
.s_div {
  margin-left: 25px;
}
.schedule_img {
  height: 23px;
  margin-top: 12px;
}
.general_summarizing_student_navUl {
  height: 50px !important;
  margin: 35px 0 !important;
}
.general_summarizing_student_navUl .title_list {
  padding: 0 !important;
  line-height: 35px !important;
  height: 50px !important;
  width: 150px;
}
.general_summarizing_student_navUl .opt {
  border-bottom: 3px solid #3498db !important;
  color: #333 !important;
  font-weight: 700;
}
</style>

