<template>
  <div class="nav_list"
       :class="[item.isActive?'opt':'',className]"
       v-if="item.isShow"
       @click.stop="seletedOption">
    <span class="text_box">{{item.value}}</span>
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object,
    className: String
  },
  methods: {
    seletedOption () {
      this.$emit('seletedOption', this.item);
    }
  }
};
</script>

<style>

</style>

<style scoped>
.nav_list {
  line-height: 46px;
  font-size: 14px;
  color: #666;
  margin-right: 25px;
  position: relative;
  cursor: pointer;
}

.nav_list.opt {
  font-weight: bold;
  color: #333;
}

.nav_list.opt .text_box {
  position: relative;
  display: inline-block;
}

.nav_list.opt .text_box::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1px;
  height: 3px;
  background: linear-gradient(90deg, #f04a43 0%, #f98b70 108.7%);
  border-radius: 2px;
}
</style>
