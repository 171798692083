
<template>
  <div :class="{ form_info_required: required,form_info_line:formTitle }">
    <div class="form_info_field"
         v-if="formTitle">
      <span>{{formTitle}}</span>
      <span v-if="helpDescription">
        <input-form-tip :tips='helpDescription'></input-form-tip>
      </span>
    </div>
    <div class="form_info_select"
         :class="{ form_info_value: formTitle }"
         v-if="!readonly">
      <div class="form_info_value_input">
        <el-date-picker :popper-class="popperClass"
                        v-model="dateTime"
                        :type="isparticular ? 'month' : 'date'"
                        :placeholder="formPlaceholder||'必选'"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        ref="datePicker"
                        :picker-options="pickerOptions"
                        @change="changeEvent($event)"
                        @focus="focusEvent($event)"
                        @blur="closedPicker"
                        :class="isShowWeek?'input_week':''"></el-date-picker>
      </div>
      <span class="new_input_date_week"
            v-if="isShowWeek"
            @change="changeEvent($event)">{{vWeekKeyValue}}</span>
      <span class="font_gray"
            v-if="tipsTitle">{{tipsTitle}}</span>
    </div>
    <div v-else
         class="input_readonly form_info_value"
         style="padding-right: 23px;line-height: 30px;">{{dateTime}}</div>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: 'inputDate',
  data () {
    let that = this;
    return {
      dateTime: this.value,
      pickerOptions: {
        disabledDate (time) {
          // console.log(that.minDate, 'that.minDatethat.minDate', this.isShowHistory);
          if (that.isShowHistory) {
            return Date.new(that.maxDate).getTime() < time.getTime();
          } else {
            return Date.new(that.minDate).getTime() > time.getTime();
          }
        }
      }
    };
  },
  props: {
    formTitle: {
      type: String,
      required: true
    }, // 标题，必填
    value: String, // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    formPlaceholder: String,
    isparticular: {
      type: Boolean,
      default: false
    },
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    minDate: String, // 最小日期
    maxDate: String, // 最大日期
    isShowHistory: {       // true 2022前/false 2022后
      type: Boolean,
      default: false
    },
    popperClass: String,
    tipsTitle: String,        // 提示.
    isShowWeek: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    },
    isShowClickTips: {//是否点击提示内容
      type: Boolean,
      default: false
    },
    cliclTipsContent: String,//点击提示内容
    helpDescription: String // 表单说明提示
  },
  created () {
    console.log(this.minDate, 'minDate maxDate', this.maxDate, this.isShowHistory);
  },
  computed: {
    vWeekKeyValue () {
      if (this.dateTime) {
        return this.$utils.getWeekByTwo(this.dateTime);
      } else {
        return '';
      }
    }
  },
  methods: {
    changeEvent (value) {
      this.$emit('changeDate', this.dateTime);
      this.$emit('input', this.dateTime);
    },
    focusEvent () {
      if (this.isShowClickTips) {
        layer.alert(this.cliclTipsContent, {}, 0, () => {
          console.log('123456')
          this.$refs.datePicker.pickerVisible = true;
        });
      }
      this.$emit('focusEvent');
    },
    pickerblur () {
      this.closedPicker();
      this.$refs.datePicker.pickerVisible = false;
    },
    closedPicker () {
      this.$emit('blurEvent');
    },
    resetDate () {
      this.dateTime = '';
    }
  },
  watch: {
    value (n, o) {
      this.dateTime = n;
    }
  }
};
</script>

<style>
.new_input_date_week {
  top: 13px;
  right: 26px;
  line-height: 20px;
  position: absolute;
  color: #3498db;
}
</style>

