<template>
  <div class="couser_info_lable"
       @click.stop="clickCircleCourse($event)">
    <div class="couser_info_lable_title"
         style="background: white; font-size: 18px;font-weight:inherit">
      <div class="lable_title_type type_green"
           style="color: #fff;background-color: #32D77E;">班</div> <span>{{circleCourseInfo.GradeClassKeyValue}}</span>
    </div>
    <div class="all_course_table_content"
         style="padding-bottom:15px">
      <div class="content_box_list clearfix">
        <div class="content_box_list_date fl">
          <div>{{circleCourseInfo.WeekKeyValue}}</div>
          <div class="font_gray">{{vBeginTime}}</div>
        </div>
        <div class="content_box_list_info fr"
             style="width: 305px;">
          <div class="list_info_left fl"
               style="max-width: 100%;">
            <div :class="String(circleCourseInfo.CourseNameKeyValue).length > 12?'font_dual':''">
              <span>{{circleCourseInfo.CourseNameKeyValue}}<span class="text_teacher"> {{circleCourseInfo.MTeacherKeyValue}}</span></span>

            </div>
            <div class="font_gray">
              <span v-if="circleCourseInfo.IsNotEndKey==1">不结束</span>
              <span v-else>
                <span>截止</span>
                <span :class="{font_red:vLastClassTimeDay}">{{circleCourseInfo.LastClassTime}}</span> <span class="font_black">{{$utils.mAmountType(circleCourseInfo.ClassCount)}}</span>节
              </span>
              <span>丨</span>
              <span class="font_black"
                    v-if="vIsSysVersionTY">{{$utils.mAmountType(circleCourseInfo.Num_SyncArranged,0)}}人</span>
              <span class="font_black"
                    v-else>{{$utils.mAmountType(circleCourseInfo.Num_InGrade,0)}}人</span>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="couser_info_lable_btn"
         v-if="isShowCouserInfoLableBtn"></div>
  </div>
</template>

<script>
export default {
  props: {
    circleCourseInfo: Object,
    isShowCouserInfoLableBtn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    vBeginTime () {
      let beginTime = '';
      if (this.circleCourseInfo.ClassTimeName) {
        beginTime = this.circleCourseInfo.ClassTimeName.split('-')[0];
      }
      return beginTime;
    },
    vWeekKeyValue () {
      return '周' + this.$utils.getWeekByNum(this.circleCourseInfo.WeekKey);
    },
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    },
    vLastClassTimeDay () {
      return this.$utils.DateDiffDay(Date.new(), this.circleCourseInfo.LastClassTime) <= 14;
    }
  },
  created () {
  },
  methods: {
    clickCircleCourse (e) {
      this.$emit('clickCircleCourse', e);
    }
  }
};
</script>

<style>
</style>