<template>
  <!-- 欠课时预警数据列表 -->
  <div class="defect_warning content_marginTop_30 content_marginRL single_shadow btn_marginBottom_30">
    <div class="population">
      <div class="population_left">
        <span class="">欠课时学生</span>
        <span>
          <span class="">{{VstatisticsCount}}</span>人 |
          <span class="">{{vTotalClassHourDebts}}</span>课时</span>
      </div>
    </div>
    <input-search-contain-btn v-model="searchText"
                              :placeholder="'请输入姓名、姓名首字母、手机号'"
                              @onSearchEvent="inputSearch"></input-search-contain-btn>

    <div class="new_table_list_box"
         v-if="oweClasshourWarning.PageDataList.length>0">
      <div class="new_table_list_thead">
        <div style="width: 89px;"
             class="new_table_list_th style_gray tl">学生</div>
        <div style="width: 140px;padding-left:23px"
             class="new_table_list_th style_yellow tl">手机号</div>
        <div style="width: 100px;padding-left:20px"
             class="new_table_list_th style_red tl">欠课时数</div>
        <div style="width: 75px;padding-left:20px"
             class="new_table_list_th style_green tl">微信</div>
      </div>
      <div class="owe_classhour_warning new_table_list_tbody"
           @scroll="scrollTOP">
        <div class="new_table_list_tr"
             v-for="(item,index) in oweClasshourWarning.PageDataList "
             :key="index"
             @click.stop="selectAmendEvent(item,$event)">
          <div class="new_table_list_td tl font_blue"
               style="width: 89px;"
               @click.stop="goStudentFile(item)">{{item.StudentKeyValue}}</div>
          <div class="new_table_list_td tl"
               style="width: 140px;padding-left:23px">
            <div class="school_warning_content_center">{{$utils.phoneModulePower(item.CustomerPhoneName)||'--'}}</div>
          </div>
          <div class="new_table_list_td tl"
               style="width: 100px;padding-left:20px">
            <span class="font_red">{{CurrentClassHourCount(item.ISClassHourDebts)}}</span>课时
          </div>
          <!-- 微信 -->
          <div class="new_table_list_td tl"
               style="width: 75px;padding-left:20px"
               @click.stop="doWeChatTemplateShow(item)">
            <!-- td_WX_close -->
            <div class=""
                 :class="Number(item.SubscribeKey)>0?'td_WX_open':'td_WX_close'"></div>
          </div>
          <div class="new_table_list_arrow"></div>
        </div>
        <div class="new_table_list_bottom"
             v-if="isLoadEnd">
          <!-- <span class="font_gray">已显示全部</span> -->
          <span class="loading_btn">加载中...</span>
        </div>
        <div class="new_table_list_bottom"
             v-else-if="noMore">
          <span class="font_gray">已显示全部</span>
        </div>
      </div>
    </div>
    <div class="course_block_nodata content_marginTop_30 content_marginRL single_shadow btn_marginBottom_30"
         v-else>
      <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
    </div>
    <pop-up-drop-down-box :isShowDialog="isShowAlertDialog"
                          :dropDownList="popUpList"
                          :positionLeft="positionLeft"
                          :positionTop="positionTop"
                          @onClick="doAfterClick"></pop-up-drop-down-box>
    <custom-dialog title="还课时"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isDebtCourseHoursDetailsShow"
                   :before-close="closeDetails">
      <div>
        <debt-course-hourse-details @confirmDebtCourseHoursDetails="confirmDebtCourseHoursDetails"
                                    @closeDialog="closeDetails"
                                    :studentItem="studentDetail"></debt-course-hourse-details>
      </div>
    </custom-dialog>

  </div>

</template>
<script>
import { getOweClasshourWarning, getWarningIgnore, getStudentRelatedMsg } from '../../../API/workbench.js';
import popUpDropDownBox from '../../common/pop-up-drop-down-box';
import debtCourseHourseDetails from '../../pop-up-menu/sign-up-payment/debt-course-hourse-details';
export default {
  props: ['GOwarningDetailsList', 'callBackFunction'],
  data () {
    return {
      oweClasshourWarning: {
        PageDataList: [],
        Total: 0,
        TotalClassHourDebts: 0
      }, // 欠课时预警
      PageMax: 0,
      PopUpShow: false, // 弹出层选择菜单
      studentItem: {}, // 操作按钮选择对象
      studentDetail: {},
      searchObj: {
        pageIndex: 0,
        pageSize: 20,
        searchText: '',
        orderBy: '',
        sequence: ''
      },
      isLoadEnd: false, // 是否加载完
      noMore: false, // 是否没有数据
      isDebtCourseHoursDetailsShow: false,
      moreJiaowuBaseMenuData: {
        // 权限控制.
        payment: {
          name: '报名收款',
          eventName: 'payment',
          ModulePowerKey: 50
        },
        repay: {
          name: '还课时',
          eventName: 'repay',
          ModulePowerKey: 50
        }
        // arrange: {
        //   name: '排课',
        //   eventName: 'arrange',
        //   ModulePowerKey: 51
        // }
      },

      popUpList: [
        { name: '还课时', type: 'repay', ModulePowerKey: 50, isShow: true },
        { name: '报名收款', type: 'payment', isShow: true, ModulePowerKey: 50 },
        { name: '忽略', type: 'ignore', isShow: true }
      ],
      positionLeft: 0,
      positionTop: 0,
      isShowAlertDialog: false,
      isShowMainForm: false, // 显示。
      searchText: ''
    };
  },
  components: { popUpDropDownBox, debtCourseHourseDetails },
  created () {
    document.addEventListener('click', e => {
      const ele = document.getElementsByClassName('alert_dialog')[0];
      if (e.target && !e.target.contains(ele)) {
        this.isShowAlertDialog = false;
      }
    });
    this.doShowPopupShowClick();
    // 还课时成功后更新数据
    this.$bus.on(this, 'AfterRepayClassHour', studentKey => {
      this.rewardData(studentKey);
    });
  },
  mounted () {
    this.$dialog.register(this, ['signuppayment', 'studentFile', 'changeCourseBill', 'weChatTemplate']);
  },
  watch: {},
  methods: {
    // 微信模板通知
    doWeChatTemplateShow (item) {
      if (this.vWeChatPattern) { // 托管模式
        layer.alert('非自有公众号, 不能发送通知');
      } else if (Number(this.$utils.getSaaSClientSet(40)) == 0) {
        layer.alert('该通知开关未开启');
      } else if (item.SubscribeKey == 0) {
        // layer.alert('非自有公众号, 不能发送通知');
      } else {
        this.$dialog.open(this, {
          name: '欠课提醒',
          routerName: this.$route.name,
          moduleName: 'weChatTemplate',
          data: { StudentKey: item.StudentKey, eventId: 36 }
        });
      }
      // console.log(item, '微信模板显示');
    },
    // 关闭弹窗页面
    closeDetails () {
      this.isDebtCourseHoursDetailsShow = false;
    },
    // 确认欠课时详情
    confirmDebtCourseHoursDetails (error) {
      if (error.code == 0) {
        this.rewardData();
        this.closeDetails();
      }
    },
    // 去还款
    doDebtDetailsClick (item) {
      getStudentRelatedMsg(item.StudentKey).then(res => {
        if (res.code == 0) {
          this.studentDetail = res.data;
          this.isDebtCourseHoursDetailsShow = true;
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 打开学生档案.
    goStudentFile (item) {
      this.$dialog.open(this, {
        name: '学生档案',
        routerName: this.$route.name,
        moduleName: 'studentFile',
        data: { studentID: item.StudentKey }
      });
    },
    // 主表单开关
    doShowPopupHideClick () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchText = '';
      this.oweClasshourWarning.PageDataList = [];
      this.oweClasshourWarning.Total = 0;
      this.oweClasshourWarning.TotalClassHourDebts = 0;
      this.$emit('chgCallBackFunction');
      this.noMore = false;
      this.isShowMainForm = false;
      this.isShowAlertDialog = false;
    },
    doShowPopupShowClick () {
      this.isShowMainForm = true;
      document.body.scrollTop = 0;
      this.updatewarningList();
    },
    // 滚动加载事件.
    scrollTOP (e) {
      this.isShowAlertDialog = false;
      let ETarget = e.target;
      if (
        (ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight ||
          ETarget.scrollTop + 1 ==
          ETarget.scrollHeight - ETarget.clientHeight) &&
        !this.noMore &&
        !this.isLoadEnd
      ) {
        console.log('scrollTOP', this.noMore);
        this.updatewarningList();
      }
    },
    // 刷新
    rewardData () {
      this.oweClasshourWarning.PageDataList = [];
      this.searchObj.pageIndex = 0;
      // this.searchObj.searchText = '';
      this.noMore = false;
      this.updatewarningList();
      setTimeout(() => {
        this.$emit('afterSucces');
      }, 500);
    },
    CurrentClassHourCount (item) {
      // 排课时间精确到1位小数点.
      return Number(Number(item).toFixed(1));
    },
    // 返回页面
    returnEvent () {
      this.$router.back(-1);
      // this.$router.push('/jiaowudesk');
    },
    //  显示弹出层
    selectAmendEvent (item, e) {
      let scrollTop = document.getElementsByClassName('owe_classhour_warning')[0].scrollTop;
      this.positionLeft = 322;
      this.positionTop = e.currentTarget.offsetTop - scrollTop + 32;
      this.isShowAlertDialog = true;
      this.studentItem = item; // 按钮选择的学生对象
      this.PopUpShow = true;
      document.body.scrollTop = 0;
    },
    // 取消弹出层
    cancelAmendEvent () {
      this.PopUpShow = false;
    },
    // 刷新 欠课时预警
    updatewarningList () {
      this.isLoadEnd = true;
      getOweClasshourWarning(this.searchObj).then(result => {
        result.data.PageDataList.forEach(o => {
          this.oweClasshourWarning.PageDataList.push(o);
        });
        if (result.data.PageDataList.length == this.searchObj.pageSize) {
          this.searchObj.pageIndex++;
        } else {
          this.noMore = true;
        }
        this.oweClasshourWarning.Total = result.data.Total;
        this.oweClasshourWarning.TotalClassHourDebts = result.data.TotalClassHourDebts;
        this.isLoadEnd = false;
      });
    },
    // 忽略按钮
    overlookEvent () {
      let title =
        this.studentItem.StudentKeyValue +
        '欠' +
        this.CurrentClassHourCount(this.studentItem.ISClassHourDebts) +
        '课时';
      getWarningIgnore(3, parseInt(this.studentItem.StudentKey), title).then(
        result => {
          var length = this.oweClasshourWarning.PageDataList.length;
          for (var i = 0; i < length; i++) {
            if (this.oweClasshourWarning.PageDataList[i] == this.studentItem) {
              if (i == 0) {
                this.oweClasshourWarning.PageDataList.shift(); // 删除并返回数组的第一个元素
              } else if (i == length - 1) {
                this.oweClasshourWarning.PageDataList.pop(); // 删除并返回数组的最后一个元素
              } else if (i > 0) {
                this.oweClasshourWarning.PageDataList.splice(i, 1); // 删除下标为i的元素
              }
            }
          }
          this.cancelAmendEvent();
          this.rewardData();
        }
      );
    },
    // 点击搜索
    inputSearch () {
      this.noMore = false;
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = this.searchText;
      this.oweClasshourWarning.PageDataList = [];
      this.oweClasshourWarning.Total = 0;
      this.oweClasshourWarning.TotalClassHourDebts = 0;
      this.updatewarningList();
    },
    reload () {
      this.searchObj.pageIndex = 0;
      // this.searchObj.searchText = '';
      this.noMore = false;
      this.oweClasshourWarning.PageDataList = [];
      this.oweClasshourWarning.Total = 0;
      this.oweClasshourWarning.TotalClassHourDebts = 0;
      this.isLoadEnd = false;
      this.updatewarningList();
      setTimeout(() => {
        this.$emit('afterSucces');
      }, 500);
    },
    // 续费按钮
    studentPaymentEvent () {
      this.PopUpShow = false;
      let obj = {
        StudentKey: this.studentItem.StudentKey,
        isReadOnly: false,
        TableID: ''
      };
      this.$dialog.open(this, {
        name: '报名收款',
        routerName: this.$route.name,
        moduleName: 'signuppayment',
        moduleDescription: '学员报名购买课程，如未登记的学生可点界面“+”新增学生信息后再进行报名。',
        data: { dataInfo: obj },
        callBack: { afterSucces: () => { this.reload(); } }
      });
    },
    // 排课 按钮
    studentCourseEvent () {
      this.PopUpShow = false;
      this.$dialog.open(this, {
        name: '跟班级排课',
        routerName: this.$route.name,
        moduleName: 'studentArrangeCourseByClass',
        data: {
          studentID: this.studentItem.StudentKey
        },
        callBack: () => { this.reload(); }
      });
    },
    doAfterClick (item) {
      switch (item.type) {
        case 'payment':
          this.studentPaymentEvent();
          break;
        case 'arrange':
          this.studentCourseEvent();
          break;
        case 'repay':
          this.doDebtDetailsClick(this.studentItem);
          break;
        case 'ignore':
          this.overlookEvent();
          break;
        case 'cancel':
          break;
        default:
          break;
      }
      this.isShowAlertDialog = false;
    }
  },
  computed: {
    // 门店是否托管
    vWeChatPattern () {
      if (this.$store.getters.SaaSClientInfo.WktInfo && this.$store.getters.SaaSClientInfo.WktInfo.PatternKey == 2 && this.$store.getters.SaaSClientInfo.IsCommonTrusteeshipKey == 1) { // 托管模式
        return true;
      } else {
        return false;
      }
    },
    // 欠课时 (人数)
    VstatisticsCount () {
      if (this.vWarmingStatisical) {
        return this.vWarmingStatisical.CountDebtClassHourNum;
      } else {
        return 0;
      }
    },
    vTotalClassHourDebts () {
      console.log(this.vWarmingStatisical, 'vTotalClassHourDebts');
      if (this.vWarmingStatisical) {
        return Number(
          Number(this.vWarmingStatisical.CountDebtClassHour).toFixed(2)
        );
      } else {
        return 0;
      }
    },
    vWarmingStatisical () {
      return this.$store.getters.getWarmingStatisical.DebtClassHourInfo;
    },
    vBMSKNorole () {
      return !this.$fun.CheckPower(this.$store.getters.getRolestr, 'BMSK');
    },
    vXSPKNorole () {
      return !this.$fun.CheckPower(this.$store.getters.getRolestr, 'XSPK');
    }
  }
};
</script>
<style scoped>
/* 刷新按钮样式 */
.update_img {
  opacity: 0.5;
  position: relative;
  left: 4.64rem;
  width: 0.36rem;
  height: 0.36rem;
}
</style>