<template>
  <!-- 待处理列表 -->
  <div class=" table_list_content pr summarizing_list pending_processed">
    <div class="single_result_detail">
      <!-- 搜索 -->
      <div class="table_select_box"
           style="padding: 20px 0px;">
        <inputSearchContainBtn v-model="queryParams.searchText"
                               :width="'280px'"
                               :placeholder="'请输入校区名'"
                               @onSearchEvent="search"
                               @clearSearch="clearExtendSearchParams"></inputSearchContainBtn>

        <div class="PortWarning">续端口预警 <span class="PortWarningText">{{PortWarningCount}}</span></div>
        <div class="heardItem">
          <function-btn :item="authBantchBtn"
                        :className="'el-button btn_light_blue btn_hover_bg_blue iconPlanC'"
                        style="width: 86px;margin-right:15px"
                        @clickItem="authBantch(authBantchBtn)">
          </function-btn>
          <function-btn :item="{ name: '批量续端口', ModulePowerKey: '' }"
                        :className="'el-button btn_light_blue btn_hover_bg_blue iconPlanC'"
                        style="width: 96px;"
                        @clickItem="renewClick(null,'bantch')">
          </function-btn>
        </div>
      </div>
      <!-- 通用列表 -->
      <table-list ref="tableListRef"
                  :tableData="mendianList"
                  :tableColumns="tableColumns"
                  :rowKey="'Number'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  @loadTableData="getAddressLogListData"></table-list>
    </div>
    <div class="course_student_attendance_operation module_content_menuList"
         :style="{'top':topValue+'px','left':'auto','right': 130+'px'}"
         v-if="isShowOperateBtn">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="operateBtnList"
                     @clickOptionForAlertDialog="onOperateClick"></drop-down-box>
    </div>

    <custom-dialog :title="'校区课件详情'"
                   :width="'1366px'"
                   :visible.sync="isShowCoursewareDetails"
                   :before-close="closeCoursewareDetails">
      <courseware-details :ClientKey="ids"
                          ref="coursewareDetails"
                          :rowData="rowData"
                          @renewClick="renewClick"
                          @close="closeCoursewareDetails"></courseware-details>
    </custom-dialog>
    <custom-dialog :title="'续端口'"
                   :visible.sync="isShowCampusRenewalForm"
                   :before-close="closeCampusRenewalFormPopup">
      <renewal-form :campusInfo="campusEditIndo"
                    @close="closeCampusRenewalFormPopup"
                    @afterSuccess="getSearchPageForCampus"></renewal-form>
    </custom-dialog>
    <custom-dialog :title="'授权课件'"
                   :visible.sync="isShowCoursewareFormPopup"
                   :before-close="closeFormPopup">
      <bantch-auth-courserware-form :campusID="campusID"
                                    @close="closeFormPopup"
                                    @afterSuccess="getSearchPageForCampus"></bantch-auth-courserware-form>
    </custom-dialog>
    <custom-dialog :title="'授权课件'"
                   :visible.sync="isShowChildCoursewareFormPopup"
                   :before-close="closeFormPopup">
      <authorization-courseware-form :coursewareBagInfo="null"
                                     :campusID="campusID"
                                     :schoolReadonly="true"
                                     :isReadonly="false"
                                     @close="closeFormPopup"
                                     @afterSuccess="getSearchPageForCampus"></authorization-courseware-form>
    </custom-dialog>
    <custom-dialog :title="'流量设置'"
                   :visible.sync="isShowFlowSetUP"
                   :before-close="closeFormPopup">
      <flowset-form :campusInfo="rowData"
                    @close="closeFormPopup"
                    @afterSuccess="getSearchPageForCampus"></flowset-form>
    </custom-dialog>
  </div>
</template>
  <script>
import { MessageSearchPageActiveCampus, AuditSaaSClientAddressLog, RefuseSaaSClientAddressLog, CheckSaaSClientAddressLog, StopAuthorization, RecoverAuthorization } from '../../../../API/workbench.js';
import tableList from '../../../common/table-list/index';
import dropDownBox from '../../../common/drop-down-box';
import inputSearchContainBtn from '../../../report/common/input-search-contain-btn.vue';
import coursewareDetails from './courseware-details.vue';
import renewalForm from '../../headquarters-management/campus-management/form/new-renewal-form';
import bantchAuthCourserwareForm from '../../headquarters-management/authorization-courseware/form/bantch-auth-courserware-form/index.vue';
import authorizationCoursewareForm from '../../headquarters-management/authorization-courseware/form/authorization-courseware-form';
import flowsetForm from '../../headquarters-management/campus-management/form/flowset-form';
export default {
  data () {
    return {
      getDate: new Date(),
      isShowFlowSetUP: false,
      rowData: {},
      ids: '',
      isShowCoursewareDetails: false,
      isShowCoursewareFormPopup: false,
      isShowChildCoursewareFormPopup: false,
      selectedStateScreenAccount: '全部',
      campusID: 0,
      campusEditIndo: {},
      authBantchBtn: {
        name: '授权校区',
        routerName: this.$route.name,
        routertitle: '',
        value: '',
        auth: true,
        roleName: '',
        keyvalue: '',
        ModulePowerKey: 108,
        moduleName: 'authorizationCoursewareForm'
      },
      // 查询参数
      queryParams: {
        pageIndex: 0, //	整型	可选		数据源：GET	页码
        pageSize: 10, //	整型	可选		数据源：GET	分页大小
        searchText: '', //	字符串	可选		数据源：GET	关键字
        orderBy: '',
        sequence: '',
        TypeKey: ''
      },
      PortWarningCount: 0,
      locationInfo: [
        { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '14' }, // 定位地址信息
        { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '15' }, // 定位经纬度信息
        { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '16' }// 定位截图信息
      ],
      nowRowData: {},
      mendianList: [], // 门店列表
      operateBtnList: [],
      topValue: 0,
      tableColumnsData: [],
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          className: 'table_index_gray'
        },
        {
          label: '开户日期',
          prop: 'OpenTime',
          width: 100,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '校区名',
          width: 80,
          align: 'left',
          type: 'popover-item',
          sortable: false,
          extend: {
            className: 'font_blue ',
            type: 'expand',
            click: (rowData) => {
              this.openCoursewareDetails(rowData);
            }
          }
        },
        {
          label: '大课包',
          prop: 'CoursewarePackageCount',
          align: 'left',
          width: 70,
          type: 'number-item',
          sortable: false
          // extend: {
          //   className: 'sta_saa'
          // }
        },
        {
          label: '授权次数',
          prop: 'AuthorizeCount',
          align: 'left',
          width: 80,
          type: 'number-item',
          sortable: false
          // extend: {
          //   className: 'sta'
          // }
        },
        {
          label: '在用授权',
          prop: 'InUseAuthorizeCount',
          align: 'left',
          type: 'number-item',
          width: 80,
          sortable: false
          // extend: {
          //   className: 'font_blue sta',
          //   type: 'expand',
          //   // mendianID: 'SaaSClientNO',
          //   click: (rowData) => {
          //     this.showLocationFormPopup(rowData, 'new');
          //   }
          // }
        },
        {
          label: '过期预警',
          prop: 'ExpireWarningCount',
          align: 'left',
          width: 80,
          type: 'text-item',
          sortable: false
        },
        {
          label: '终止授权',
          prop: 'UnUseAuthorizeCount',
          align: 'left',
          width: 80,
          type: 'number-item',
          sortable: false
        },
        {
          label: '地址定位',
          prop: 'IsLocation',
          align: 'left',
          width: 80,
          type: 'text-item',
          sortable: false
        },
        {
          label: '流量设置',
          prop: 'CurrentFluxObjectKeyValue',
          align: 'left',
          width: 100,
          type: 'md-name',
          sortable: false,
          extend: {
            className: 'font_blue ',
            type: 'expand',
            click: (rowData) => {
              this.setMendain(rowData);
            }
          }
        },

        {
          label: '状态',
          prop: 'IsUse',
          align: 'left',
          width: 60,
          type: 'text-item',
          sortable: false
        },
        {
          label: '端口天数',
          prop: 'PortDayNumber',
          align: 'left',
          width: 80,
          type: 'number-item',
          sortable: false
        },
        {
          label: '最后定位时间',
          prop: 'AddressAlterTime',
          align: 'left',
          width: 110,
          type: 'text-item',
          sortable: false
        },
        {
          label: '操作',
          prop: 'option',
          width: 300,
          type: 'table-btn',
          align: 'center',
          fixed: 'right',
          extend: {
            tableBtnGroup: [
              {
                name: () => {
                  return '详情';
                },
                extend: {
                  click: (rowData) => {
                    this.openCoursewareDetails(rowData);
                  },
                  ModulePowerKey: ''
                }
              },
              {
                name: () => {
                  return '授权';
                },
                extend: {
                  click: (rowData) => {
                    this.operateBtnEvent(rowData);
                    // this.AuthorizeCourseware(rowData);
                  },
                  ModulePowerKey: ''
                }
              },
              // {
              //   name: () => {
              //     return '续端口';
              //   },
              //   extend: {
              //     click: (rowData) => {
              //       this.renewClick(rowData);
              //     },
              //     ModulePowerKey: ''
              //   }
              // },
              {
                name: (rowData) => {
                  console.log('rowData', rowData);
                  return rowData.IsUse == '在用' ? '暂停授权' : '恢复授权';
                },
                extend: {
                  click: (rowData) => {
                    this.auth(rowData);
                  },
                  ModulePowerKey: ''
                }
              }
            ]
          }
        }
      ],
      pageTotal: 0,
      totalNum: 0,
      defaultSort: { prop: 'studentInfo', order: 'ascending' },
      isShowOperateBtn: false,
      isShowLocationForm: false,
      isShowCampusRenewalForm: false
    };
  },
  components: {
    tableList,
    dropDownBox,
    inputSearchContainBtn,
    coursewareDetails,
    renewalForm,
    bantchAuthCourserwareForm,
    authorizationCoursewareForm,
    flowsetForm
  },
  props: {
  },
  computed: {
    vAutoProcessed () {
      return this.$utils.getSaaSClientSetInfo(87).SetKey == 1;
      // return true;
    }
  },
  watch: {

  },
  mounted () {
    this.getAddressLogListData(this.queryParams);
    document.querySelector('body').addEventListener(
      'click',
      (e) => {
        this.isShowOperateBtn = false;
      },
      false
    );
  },
  created () {
  },
  methods: {
    // 授权子课包
    childAuthorizeCourseware (rowData) {
      this.campusID = rowData.OLAPKey;
      this.isShowChildCoursewareFormPopup = true;
    },
    // 授权课件
    AuthorizeCourseware (rowData) {
      this.campusID = rowData.OLAPKey;
      this.isShowCoursewareFormPopup = true;
    },
    closeFormPopup () {
      this.isShowCoursewareFormPopup = false;
      this.isShowChildCoursewareFormPopup = false;
      this.isShowFlowSetUP = false;
    },
    authBantch (item) {
      console.log('authBantch', item);
      this.$bus.emit('openCustomDialog', item);
    },
    getSearchPageForCampus () {
      if (this.isShowCoursewareDetails) {
        this.$refs.coursewareDetails.getDataDetails();
      }
      this.getAddressLogListData(this.queryParams);
    },
    // 关闭
    closeCampusRenewalFormPopup () {
      this.isShowCampusRenewalForm = false;
    },
    renewClick (rowData, type) {
      console.log(rowData, '续费');
      if (type) {
        this.campusEditIndo = {
          OLAPKey: 0,  // 校区ID
          MainDemoName: '',
          IsOpenChannelKey: 0, // "是否开通课件 0-否;1-是",
          IsOpenAcademicKey: 1, // "是否开通教务  0-否;1-是",
          ExpireTime: ''   // 到期日
        };
        this.isShowCampusRenewalForm = true;
      } else {
        let NowTime = new Date();
        let PortExpiredTime = new Date(rowData.PortExpiredTime);
        // 续费教务时，如门店教务还在有效期内
        if ((rowData.portTypeKey == 1 && rowData.RenewOwnKey != 1) && (PortExpiredTime > NowTime && rowData.SysStatusKey != 1)) {
          layer.confirm('该门店教务功能自费，不可进行该操作，如有问题请与专属客服联系', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  layer.close();
                }
              }
            ]
          });
        } else {
          console.log('campusEditIndo');
          this.campusEditIndo = {
            OLAPKey: rowData.OLAPKey,  // 校区ID
            MainDemoName: rowData.MainDemoName,
            IsOpenChannelKey: rowData.portTypeKey == 2 ? 1 : 0, // "是否开通课件 0-否;1-是",
            IsOpenAcademicKey: rowData.portTypeKey == 1 ? 1 : 0, // "是否开通教务  0-否;1-是",
            ExpireTime: rowData.ExpiredTime   // 到期日
          };
          this.isShowCampusRenewalForm = true;
        }
      }
    },
    // 状态筛选
    chgSearchValue (item) {
      console.log(item, 'item');
      this.queryParams.TypeKey = item.OLAPKey;
      this.selectedStateScreenAccount = item.MainDemoName;
      this.getAddressLogListData(this.queryParams);
    },
    closeCoursewareDetails () {
      this.isShowCoursewareDetails = false;
    },
    openCoursewareDetails (rowData) {
      this.ids = rowData.ClientKey;
      this.rowData = rowData;
      this.isShowCoursewareDetails = true;
    },
    // 处理列表
    getAddressLogListData (queryParams) {
      queryParams.searchText = queryParams.searchText.trim();
      MessageSearchPageActiveCampus(queryParams).then(result => {
        this.isShowOperateBtn = false;
        this.totalNum = result.data.Total; // 分页数
        this.mendianList = result.data.PageDataList || [];
        this.PortWarningCount = result.data.PortWarningCount;
        if (this.mendianList.length > 0) {
          this.mendianList.forEach(o => {
            o.ExpireWarningCount = o.ExpireWarningCount == 0 ? '-' : `<span style="color:#F7B763">` + o.ExpireWarningCount + `</span>`;
            o.IsLocation = o.IsLocation == '未定位' ? `<span style="color:#F7B763">` + o.IsLocation + `</span>` : `<span style="color:#3DBEBE">` + o.IsLocation + `</span>`;
            if (o.CurrentFluxObjectKey == 1 && o.NextFluxObjectKey != 2) {
              o.CurrentFluxObjectKeyValue = '总部负担';
            } else if (o.CurrentFluxObjectKey == 1 && o.NextFluxObjectKey == 2) {
              if ((this.getDate.getMonth() + 2) == 13) {
                o.CurrentFluxObjectKeyValue = '总部负担,1月1号起门店负担';
              } else {
                o.CurrentFluxObjectKeyValue = '总部负担,' + (this.getDate.getMonth() + 2) + '月1号起门店负担';
              }
            } else if (o.CurrentFluxObjectKey == 2 && o.NextFluxObjectKey != 1) {
              o.CurrentFluxObjectKeyValue = '门店负担';
            } else if (o.CurrentFluxObjectKey == 2 && o.NextFluxObjectKey == 1) {
              if ((this.getDate.getMonth() + 2) == 13) {
                o.CurrentFluxObjectKeyValue = '门店负担,1月1号起总部负担';
              } else {
                o.CurrentFluxObjectKeyValue = '门店负担,' + (this.getDate.getMonth() + 2) + '月1号起总部负担';
              }
            } else if (o.CurrentFluxObjectKey == 0 && o.NextFluxObjectKey == 1) {
              if ((this.getDate.getMonth() + 2) == 13) {
                o.CurrentFluxObjectKeyValue = '暂时未设置，1月1号起总部负担';
              } else {
                o.CurrentFluxObjectKeyValue = '暂时未设置，' + (this.getDate.getMonth() + 2) + '月1号起总部负担';
              }
            } else if (o.CurrentFluxObjectKey == 0 && o.NextFluxObjectKey == 2) {
              if ((this.getDate.getMonth() + 2) == 13) {
                o.CurrentFluxObjectKeyValue = '暂时未设置，1月1号起门店负担';
              } else {
                o.CurrentFluxObjectKeyValue = '暂时未设置，' + (this.getDate.getMonth() + 2) + '月1号起门店负担';
              }
            } else {
              o.CurrentFluxObjectKeyValue = '未设置';
            }
          });
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 搜索
    search (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.queryParams.pageIndex = 0;
      this.getAddressLogListData(this.queryParams);
    },
    // 清除条件
    clearExtendSearchParams () {
      this.queryParams.TypeKey = 0;
      this.selectedStateScreenAccount = '全部';
      this.queryParams.pageIndex = 0;
      this.queryParams.searchText = '';
      this.queryParams.pageSize = 10;
      this.getAddressLogListData(this.queryParams);
    },
    // 排序
    loadTableData (queryParams) {
      console.log(queryParams, '排序');
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.getAddressLogListData(this.queryParams);
    },
    // 操作菜单
    operateBtnEvent (rowData) {
      console.log(rowData, this.mendianList, '操作菜单');
      this.studentsData = rowData;
      let findIndex = this.mendianList.findIndex((o) => {
        // 找到该项菜单点击下标
        console.log(o.CourseNameKey, rowData.CourseNameKey, '0');
        return Number(o.Number) == Number(rowData.Number);
      });
      let index = findIndex + 1;
      this.topValue = 105 + index * 63;
      console.log(this.topValue, 'this.topValue', findIndex);
      this.operateBtnList = [
        { type: 1, name: '授权大课包', isShow: true },
        { type: 2, name: '授权子课包', isShow: true }
      ];
      this.isShowOperateBtn = true;
    },
    // 操作菜单
    onOperateClick (item) {
      // this.threadInfo = this.$utils.parseJson(rowData);
      // this.threadInfo.SaaSClientTypeKey = 2;
      this.isShowOperateBtn = false;
      console.log(item, this.nowRowData, '操作菜单');
      switch (item.type) {
        case 1:// 授权大课包
          console.log('授权大课包');
          this.AuthorizeCourseware(this.studentsData);
          break;
        case 2:// 授权子课包
          console.log('授权子课包');
          this.childAuthorizeCourseware(this.studentsData);
          break;
        default:
          break;
      }
    },
    pass () {
      AuditSaaSClientAddressLog(
        this.nowRowData.OLAPKey
      ).then(
        (res) => {
          layer.alert('已通过');
          this.getAddressLogListData(this.queryParams);
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    refuse () {
      RefuseSaaSClientAddressLog(
        this.nowRowData.OLAPKey
      ).then(
        (res) => {
          layer.alert('已拒绝');
          this.getAddressLogListData(this.queryParams);
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    check () {
      CheckSaaSClientAddressLog(
        this.nowRowData.OLAPKey
      ).then(
        (res) => {
          layer.alert('已查看');
          this.getAddressLogListData(this.queryParams);
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    closeLocationFormPopup () {
      this.isShowLocationForm = false;
    },
    showLocationFormPopup (rowData, type) {
      if (type == 'new') {
        if (rowData.Location == null || rowData.Location == '') {
          return;
        }
        this.locationInfo[1].SetContent = [rowData.Longitude, rowData.Latitude];
      } else {
        if (rowData.PrimaryLocation == null || rowData.PrimaryLocation == '') {
          return;
        }
        this.locationInfo[1].SetContent = [rowData.PrimaryLongitude, rowData.PrimaryLatitude];
      }
      this.isShowLocationForm = true;
    },
    auth (data) {
      let fn = data.IsUse == '在用' ? StopAuthorization : RecoverAuthorization;
      layer.confirm('是否' + (data.IsUse == '在用' ? '暂停' : '恢复') + '授权', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              fn(data.OLAPKey).then(result => {
                this.getAddressLogListData(this.queryParams);
              });
              layer.close();
            }
          }
        ]
      });
    },
    setMendain (rowData) {
      this.rowData = rowData;
      this.isShowFlowSetUP = true;
    }
  }
};
  </script>
  <style scoped>
.super_management {
  margin-top: 90px;
}
.pending_processed .location_icon {
  width: 13px;
  height: 18px;
  margin-right: 10px;
  margin-top: -1px;
}

.pending_processed .location_icon1 {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  /* top: 10px; */
  margin-top: -1px;
}
.pending_processed >>> .sta {
  white-space: break-spaces !important;
  display: flex;
  align-items: center;
}

.pending_processed >>> .sta_time {
  white-space: break-spaces !important;
  margin-right: 46px;
  display: block;
  line-height: 18px;
}
.pending_processed >>> .sta_saa {
  white-space: break-spaces !important;
}
.PortWarning {
  position: absolute;
  left: 460px;
}
.table_select_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.PortWarningText {
  padding: 4px 25px;
  margin-left: 8px;
  border: 1px solid #ececec;
  color: #ef8604;
  border-radius: 4px;
}
.summarizing_list.table_list_content {
  padding: 0;
}
</style>
  
  