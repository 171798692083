<template>
  <div class="course_atten">
    <div
      class="atten_scroll index_tabs_two"
    >
      <!-- 学生块 -->
      <div>
        <div class="pr">
          <div
            class="select_course_student_info"
            ref="selectCourseStudentList"
            
          >
            <div
              class="course_student_info_list"
             
            >
              <student-item
                v-for="item in studentArrangeList"
                :key="item.StudentArrangeKey"
                :studentItem="item"
               
                @selectedStudent="selectedStudent"
                @setPosition="getPosition"
              ></student-item>

              <!-- 添加学生 -->
              <add-student-btn
               @addStudentBtnClick="showAddStudentDialog"
              ></add-student-btn>
            </div>
            <!-- 添加学生弹框. -->
            <!-- <div>
              <student-for
                ref="studentsList"
                :formTitle="studentTitle"
                :classGradeID="selectedCourseInfo.GradeClassKey"
                :selectCourseID="selectedCourseInfo.CourseNameKey"
                :selectCourseTime="selectedCourseInfo.ClassTime"
                :classHoureCount="selectedCourseInfo.PeriodCount"
                @onAddStudent="doAfterSelectedStudent"
             
                @completeData="closeStundetList"
              ></student-for>
            </div> -->
           
          </div>
          <div>
            <!-- 学生考勤操作-->
            <div
              class="course_student_attendance_option_list
                course_student_attendance_operation
                module_content_menuList
                active
              "
              :style="{ left: positionLeft + 'px', top: positionTop + 'px' }"
              v-show="isShowDialog && vIsShowAlerDialog"
            >
              <div class="top_bg"></div>
              <drop-down-box
                :optionJsonList="attendanceStatus"
                @clickOptionForAlertDialog="clickAttendanceOption"
              ></drop-down-box>
            </div>
          </div>
        </div>
      </div>
      <!-- 无数据展示 -->
      <!-- <div v-else class="course_block_nodata" style="background-color: #fff">
        <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
      </div> -->
      <custom-dialog
        :title="'考勤备注'"
        class="student_info_dialog defult_dialog"
        :visible.sync="isShowReason"
        :before-close="closeReason"
      >
        <reason
          :optionItem="optionItem"
          @changeAttendance="cancelAppoint"
          @cancel="closeReason"
        ></reason>
      </custom-dialog>
      <custom-dialog
        :title="'学生列表'"
        class="student_info_dialog defult_dialog"
        :visible.sync="isShowStudentListDialog"
        :before-close="closeStudentListDialog"
      >
        <student-list
          :isShowSearchInput="true"
          :addTitleName="''"
          :CustStatusKey="1"
          :isShowAddBtn="false"
          :SubscribeCourseKey="selectedCourseInfo.OLAPKey"
          @selectedItem="toAddStudent"
        ></student-list>
      </custom-dialog>
    </div>
    
  </div>
</template>

<script>
import studentFor from '../../common-module/students-list/index';
import studentItem from './student-item';
import studentList from '../../common/student-list';
import addStudentBtn from './add-student-btn';
import dropDownBox from '../../common/drop-down-box';
import reason from './reason';
import {
  
  DeleteSubscribeCourseStudent,
  addSubscribeCourseStudent
 
} from '../../../API/workbench';
export default {
  data () {
    return {
      deleteCount:1,//手动防抖工具
      isShowDialog: false,
      isScrollHidden: true,
      isAdd: false,
      isShowReason: false,
      optionItem: {},
      attendanceStatus: [
        {
          type: this.$initJson.attendanceType.view,
          name: '查看档案',
          eventName: 'examine',
          className: '',
          isShow: true
        },
        {
          type: this.$initJson.attendanceType.cancel,
          name: '取消预约',
          eventName: 'examine',
          className: '',
          isShow: true
        },
       
      ],
      curSelectedStudentInfo: {}, // 选中学生.
      vStudentArrangeList: [],
      positionLeft: '',
      positionTop: '',
      isShowChangeAttendance: false,
      isShowStudentListDialog: false,
      
      isShowAddStudentDialog: '',
      addStudentMode: 0, // 添加学生列表类型
      isShowdropDownBox: false,
      addLoopStudentInfo:null,
         studentTitle: '', // 添加学生列表弹窗名
    };
  },
  props: {
    studentArrangeList: Array,
    selectedCourseInfo: Object,
  },
  components: {
    studentItem,
    addStudentBtn,
    studentFor,
    dropDownBox,
    studentList,
    reason
  },
  created () {
    console.log(this.selectedCourseInfo, 'selectedCourseInfo1');
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener(
      'click',
      (e) => {
        this.isShowDialog = false;
        this.isShowdropDownBox = false;
        this.$store.commit('showAlerDialog', false);
      },
      true
    );
  },
  watch: {
  },
  computed: {
    
    vIsShowAlerDialog () {
      return this.$store.getters.showAlerDialog;
    },
   
  },
  methods: {
    cancelAppoint(text){
      console.log(text,'cancelAppoint')
      DeleteSubscribeCourseStudent(this.curSelectedStudentInfo.OLAPKey,this.selectedCourseInfo.OLAPKey,text).then(result=>{
        console.log("DeleteSubscribeCourseStudent",result.data)
        this.closeReason()
          this.$emit("updateCourseInfo",this.selectedCourseInfo.OLAPKey)
        layer.alert("删除成功")
      }).catch(error=>{
        layer.alert(error.msg)
      })
    },
    // 加入循环课
    toAddStudent (stuItem) {
      console.log("toAddStudent",stuItem)
      this.isShowStudentListDialog = false;
      this.addLoopStudentInfo = stuItem;
      let studentObj={
        "IsClassHourLackKey":0,// 是否欠课时0-否;1-是,
        "SubscribeCourseKey":this.selectedCourseInfo.OLAPKey, //"约课Key",
        "StudentKey": stuItem.OLAPKey,//"学生Key",
        "StudentKeyValue":stuItem.MainDemoName,//"学生名称"
      }
      addSubscribeCourseStudent(studentObj).then(result=>{
        console.log("addSubscribeCourseStudent")
        this.$emit("updateCourseInfo",this.selectedCourseInfo.OLAPKey)
      }).catch(error=>{
        layer.alert(error.msg)
        console.log("erroraddSubscribeCourseStudent",error)
      })
     
    },
    closeReason(){
      this.isShowReason=false
    },
    // 添加学生弹出层 isStudentAttendance
    showAddStudentDialog () {
      this.isShowStudentListDialog = true;
      // this.$refs.studentsList.isShowAddStudent = true;
      // this.isShowAddStudentDialog = '安排'; // 选择学生签到
      // this.studentTitle = '安排学生约课';
      // this.addStudentMode = 0;
      // this.$nextTick(() => {
      //   this.$refs.studentsList.studenSearchText = '';
      //   this.$refs.studentsList.pushDataStudentList(this.studentArrangeList);
      //   this.$refs.studentsList.getStudentList();
      // });
    },
    
    // 选中学员信息 根据选中学生状态 弹出学生考勤框（变更考勤，未考勤，签到，请假，旷课，删除）根据状态显示哪几个
    selectedStudent (studentData) {
      this.isAdd = false;
      if (this.curSelectedStudentInfo) {
        this.curSelectedStudentInfo.isSelected = false;
      }
      studentData.isSelected = true;
      this.curSelectedStudentInfo = studentData;
      // let StatusKey = this.selectedCourseInfo.StatusKey;

      // let scrollTop = document.getElementsByClassName('course_student_info_list')[0].scrollTop;
      // this.vTop = Math.abs(scrollTop - (seletedEle.offsetTop + 140 + seletedEle.offsetHeight - 18)) + 'px';        // 140为固定高度,52为该元件的高度
    },
    // 点击考情项
    clickAttendanceOption (optionItem) {
      this.optionItem = optionItem;
      switch (optionItem.type) {
        case this.$initJson.attendanceType.view:
          this.examine();
          this.studentArrangeList.forEach(function (obj) {
            obj.isSelected = false;
          });
          break;
       
        case this.$initJson.attendanceType.cancel:
          console.log("canmcel")
          this.isShowReason = true;
          break;
        default:
          break;
      }
      this.isShowDialog = false;
      this.$store.commit('showAlerDialog', false);
    },

 
    
    // 查看学员的学员档案
    examine () {
      let item = {
        routerName: this.$route.name,
        name: '学生档案',
        moduleName: 'studentFile',
        data: { studentID: this.curSelectedStudentInfo.StudentKey }
      };
      this.$dialog.open(this, item);
    },
    getPosition (left, top, clientTop) {
      console.log(left, 'positionLeft', clientTop);
      if (top > 280) {
        this.isScrollHidden = false;
        let num = this.$refs.selectCourseStudentList.scrollTop;
        this.$refs.selectCourseStudentList.scrollTop =
          this.$refs.selectCourseStudentList.scrollTop + 80;
        num = this.$refs.selectCourseStudentList.scrollTop - num;
        top -= num;
      }
      this.positionLeft = left + 8;
      this.positionTop = top;
    },
  
    closeStudentListDialog () {
      this.isShowStudentListDialog = false;
    },
  
  }
};
</script>

