import { render, staticRenderFns } from "./html-item.vue?vue&type=template&id=8e8e5c9c"
import script from "./html-item.vue?vue&type=script&lang=js"
export * from "./html-item.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports