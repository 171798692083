<template>

  <div class="general_summarizing_student">
    <!-- <div class="course_statistics_left_title"><span>课次明细</span></div> -->
    <div class="general_summarizing_student_navUl">
      <div class="title_list"
           @click="tableSwitch(1)"
           :class="{ opt: tableType == 1 }">
        收付款日报
      </div>
      <div class="title_list"
           @click="tableSwitch(2)"
           :class="{ opt: tableType == 2 }">
        收付款月报
      </div>
      <div class="title_list"
           @click="tableSwitch(3)"
           :class="{ opt: tableType == 3 }">
        报名收款统计
      </div>
      <div class="title_list"
           @click="tableSwitch(4)"
           :class="{ opt: tableType == 4 }">
        其他收入统计
      </div>
      <div class="title_list"
           @click="tableSwitch(5)"
           :class="{ opt: tableType == 5 }">
        支出统计
      </div>
    </div>
    <div class="payment_statistics_bottom clearfix">
      <div v-if="tableType==1">
        <day-report @toApplyReport="toApplyReport"
                    @toOtherIncomeReport="toOtherIncomeReport"
                    @toExpenditureReport="toExpenditureReport"></day-report>
      </div>
      <div v-if="tableType==2">
        <month-report @toApplyReport="monthReporttoApplyReport"
                      @toOtherIncomeReport="monthReporttoOtherIncomeReport"
                      @toExpenditureReport="monthReporttoExpenditureReport"></month-report>
      </div>
      <div v-if="tableType==3">
        <apply-payment-report :searchParams="searchObj"></apply-payment-report>
      </div>
      <div v-if="tableType==4">
        <other-income-report :searchParams="searchObj"></other-income-report>
      </div>
      <div v-if="tableType==5">
        <expenditure-report :searchParams="searchObj"></expenditure-report>
      </div>
    </div>
  </div>
</template>
<script>
import dayReport from './components/day-report';
import monthReport from './components/month-report';
import applyPaymentReport from './components/apply-payment-report';
import otherIncomeReport from './components/other-income-report';
import expenditureReport from './components/expenditure-report';
export default {
  data () {
    return {
      tableType: 1,
      searchObj: null
    };
  },
  components: {
    dayReport, monthReport, applyPaymentReport, otherIncomeReport, expenditureReport
  },
  computed: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    tableSwitch (type) {
      this.searchObj = null;
      this.tableType = type;
    },
    // 到报名收款统计
    toApplyReport (item, searchObj) {
      console.log(item, searchObj, 'toApplyReport');
      this.searchObj = item;
      this.tableType = 3;
    },
    monthReporttoApplyReport (item, searchObj) {
      // this.searchObj = searchObj;
      this.searchObj = {};
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(item.SetUpTime).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new(item.SetUpTime).getFullYear(), (Date.new(item.SetUpTime).getMonth() + 1), 0));
      // console.log('monthReporttoApplyReport', this.searchObj);
      // searchObj.startTime
      this.tableType = 3;
    },
    // 到其他收入统计
    toOtherIncomeReport (item, searchObj) {
      console.log(item, searchObj, 'toOtherIncomeReport');
      this.searchObj = item;
      this.tableType = 4;
    },
    // 到其他收入统计
    monthReporttoOtherIncomeReport (item, searchObj) {
      this.searchObj = {};
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(item.SetUpTime).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new(item.SetUpTime).getFullYear(), (Date.new(item.SetUpTime).getMonth() + 1), 0));
      this.tableType = 4;
    },
    // 到支出统计
    toExpenditureReport (item, searchObj) {
      console.log(item, searchObj, 'toExpenditureReport');
      this.searchObj = item;
      this.tableType = 5;
    },
    monthReporttoExpenditureReport (item, searchObj) {
      this.searchObj = {};
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(item.SetUpTime).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new(item.SetUpTime).getFullYear(), (Date.new(item.SetUpTime).getMonth() + 1), 0));
      this.tableType = 5;
    }

  }
};
</script>

