<template>
  <!-- 成果统计 - 右边柱状图统计 -->
  <div class="course_statistics_echarts">
    <div class="course_statistics_title"
         style="padding: 0 0 0 20px;">
      <div class="table_select_box">
        <el-select v-model="selectedAchievement"
                   filterable
                   style="width: 160px;"
                   placeholder="必选"
                   @change="chgSearchAchievementValue"
                   value-key="OLAPKey">
          <el-option v-for="item in AchievementList"
                     :key="item.OLAPKey"
                     :label="item.MainDemoName"
                     :value="item"></el-option>
        </el-select>
      </div>
    </div>
    <div class="course_statistics_echarts_box"
         style="border-bottom: none;height: 279px;border:1px solid #ececec;margin: 10px 0 0 32px;">
      <new-type-echart :echartID="'dual_histogram3'"
                       :reqList="monthList"
                       :nowDataList="nowList"
                       :preDataList="oldList"
                       :monthTitle="''"
                       :secondTitle="''"
                       :echartType="'Bar'"
                       ref="echart"></new-type-echart>
    </div>
  </div>
</template>
<script>
import newTypeEchart from './new-type-echart';
import { getManageStatistics } from '../../../API/workbench';
export default {
  data () {
    return {
      selectedAchievement: '报名收款', // 报名收款
      searchObj: {
        StartTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        EndTime: this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd')
      },
      achievementIndex: 0,
      AchievementList: [
        {
          OLAPKey: 0,
          MainDemoName: '报名收款'
        },
        {
          OLAPKey: 1,
          MainDemoName: '跟单记录'
        },
        {
          OLAPKey: 2,
          MainDemoName: '新增线索'
        },
        {
          OLAPKey: 3,
          MainDemoName: '已诺访'
        },
        {
          OLAPKey: 4,
          MainDemoName: '已约课'
        },
        {
          OLAPKey: 5,
          MainDemoName: '已试课'
        },
        {
          OLAPKey: 6,
          MainDemoName: '已报名'
        }
      ],
      nowList: [],
      nowDataList: [],
      oldList: [],
      monthList: []
    };
  },
  props: {
  },
  computed: {
  },
  components: {
    newTypeEchart
  },
  created () {
  },
  mounted () {
    this.getManageStatistics(this.achievementIndex);
  },
  methods: {
    chgStatisticsRDate (item) {
      console.log(item, 'chgStatisticsRDate ite, achievementIndex', this.achievementIndex);
      this.searchObj.StartTime = item.StartTime;
      this.searchObj.EndTime = item.EndTime;
      this.getManageStatistics(this.achievementIndex);
    },
    getManageStatistics (type) {
      let screeLength = 8;
      this.monthList = [];
      getManageStatistics(this.searchObj, type).then(result => {
        result.data.forEach((o, i) => {
          o.statAmountCount = o.ActualColAmount || o.FollowUpCount || o.NewThreadCount || o.PromiseVisitCount || o.InvitedClassCount ||
            o.FinishTrialLessonCount || o.FinishSignUpCount;
          console.log(o.statAmountCount, 'o.statAmountCount', Number(o.FollowUpCount));
          this.monthList.push(o.SalesKeyValue);
        });

        if (document.body.clientWidth >= 1340 && document.body.clientWidth <= 1370) {
          // 分辨率 1366
          screeLength = 5;
          console.log('a1');
        } else if (document.body.clientWidth >= 1890 && document.body.clientWidth <= 1930) {
          // 分辨率1920
          screeLength = 8;
          console.log('a2');
        }
        console.log(screeLength, 'screeLength', this.screenWidth, document.body.clientWidth);
        if (this.monthList.length < screeLength) {
          let j = screeLength - this.monthList.length;// 显示8个 或者 6
          for (var i = 0; i < j; i++) {
            this.monthList.push('');
          }
        }
        this.nowDataList = result.data;
        this.nowList = this.getNowList();
        console.log(this.nowDataList, 'this.nowDataList this.nowList this.oldList', this.nowList, this.oldList);
        this.$nextTick(() => {
          this.$refs.echart.echartInit();
        });
        this.$forceUpdate();
        console.log(result.data, '成果统计图表 this.monthList', this.monthList);
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    chgSearchAchievementValue (item) {
      // OLAPKey 0 报名收款 1 跟单记录 2新增线索 3 已诺访 4已约课 5已试课 6已报名
      console.log(item, 'item');
      this.achievementIndex = item.OLAPKey;
      this.selectedAchievement = item.MainDemoName;
      this.getManageStatistics(item.OLAPKey);
    },
    getNowList () {
      let nowList = [];
      this.monthList.forEach(p => {
        let item = this.nowDataList.find(o => {
          return o.SalesKeyValue == p;
        });
        if (item) {
          nowList.push(Number(item.statAmountCount).toFixed(0));
        } else {
          nowList.push('0');
        }
      });
      return nowList;
    }
  }
};
</script>
<style scoped>
</style>