<template>
  <div class="mendian_backstage_date">
    <div class="date_title"
         v-if="fromTitle">{{fromTitle}}</div>
    <div class="date_box"
         :class="{border_blue:vIsBorder}">
      <div class="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date">
        <input type="text"
               autocomplete="off"
               name=""
               placeholder="开始日期"
               v-model="vStartTime"
               readonly
               class="el-input__inner">
        <input-date class="mendian_backstage_date_input_date"
                    :formTitle="''"
                    ref="inputDate"
                    :minDate="minDate"
                    :maxDate="maxDate"
                    :isShowHistory="isShowHistory"
                    @blurEvent="blurEvent('1')"
                    @focusEvent="focusEvent('1')"
                    @changeDate="changeStartDay"></input-date>
      </div>
      <div class="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date">
        <input type="text"
               autocomplete="off"
               name=""
               placeholder="结束日期"
               v-model="vEndTime"
               readonly
               class="el-input__inner">
        <input-date class="mendian_backstage_date_input_date"
                    :formTitle="''"
                    ref="inputDate2"
                    :minDate="minDate"
                    :maxDate="maxDate"
                    :isShowHistory="isShowHistory"
                    @blurEvent="blurEvent('2')"
                    @focusEvent="focusEvent('2')"
                    @changeDate="changeEndDay"></input-date>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isDate01: false,
      isDate02: false,
      dataInfo: {
        startTime: '',
        endTime: ''
      }
    };
  },
  props: {
    fromTitle: String,
    searchObj: {
      type: Object,
      default: () => {
        return {
          startTime: '',
          endTime: ''
        };
      }
    },
    minDate: {
      type: String,
      default: ''
    },
    maxDate: {
      type: String,
      default: ''
    },
    isShowHistory: {       // true 2022前/false 2022后
      type: Boolean,
      default: false
    }
  },
  computed: {
    vStartTime () {
      if (this.dataInfo.startTime) {
        return this.$utils.formatDateToLine(Date.new(this.dataInfo.startTime));
      } else {
        return '';
      }
    },
    vEndTime () {
      if (this.dataInfo.endTime) {
        return this.$utils.formatDateToLine(Date.new(this.dataInfo.endTime));
      } else {
        return '';
      }
    },
    vIsBorder () {
      if (this.isDate01 || this.isDate02) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    // 结束时间
    'searchObj.endTime': {
      handler (newValue, oldValue) {
        if (!newValue && !this.searchObj.startTime) {
          this.resetTime();
        }
      },
      deep: true
    },
    // 开始时间
    'searchObj.startTime': {
      handler (newValue, oldValue) {
        if (!newValue && !this.searchObj.endTime) {
          this.resetTime();
        }
      },
      deep: true
    }
  },
  mounted () {
    console.log(this.isShowHistory, 'isShowHistory');
    this.dataInfo = this.searchObj;
  },
  methods: {
    changeHistoryTime (startTime, endTime) {
      this.dataInfo.startTime = startTime;
      this.dataInfo.endTime = endTime;
    },
    resetTime () {
      this.dataInfo.startTime = '';
      this.dataInfo.endTime = '';
      this.$refs.inputDate.resetDate();
      this.$refs.inputDate2.resetDate();
    },
    changeStartDay (date) {
      if (this.dataInfo.endTime && Date.new(this.dataInfo.endTime) < Date.new(date)) {
        this.$refs.inputDate.resetDate();
        layer.alert('开始时间不能大于结束时间');
      } else {
        this.dataInfo.startTime = date;
        this.$emit('changeDate', this.dataInfo);
      }
    },
    changeEndDay (date) {
      if (this.dataInfo.startTime && Date.new(this.dataInfo.startTime) > Date.new(date)) {
        layer.alert('结束时间不能早于开始时间');
      } else {
        this.dataInfo.endTime = date;
        this.$emit('changeDate', this.dataInfo);
      }
    },
    focusEvent (type) {
      if (type == '1') {
        this.isDate01 = true;
      } else {
        this.isDate02 = true;
      }
    },
    blurEvent (type) {
      if (type == '1') {
        this.isDate01 = false;
      } else {
        this.isDate02 = false;
      }
    }

  }
};
</script>