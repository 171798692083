<template>
  <div class="new_course_info_box"
       @click.stop="clickCourse">
    <div class="new_course_info_title">
      <div class="info_content_half"
           :class="{pr:isClassGradeWarning&&generatedClassTimeData.length==0}">
        <div class="info_warning_box">
          <span class="info_class">{{courseTableData.GradeClassKeyValue}}</span>
          <span class="info_course">({{courseTableData.CourseNameKeyValue}})</span>
          <div v-show="isClassGradeWarning&&generatedClassTimeData.length==0"
               class="info_warning">班级冲突</div>
        </div>
      </div>
    </div>
    <div class="new_course_info_content clearfix">
      <div class="info_content_half">
        <span class="font_text">上课老师：{{courseTableData.MTeacherKeyValue}}</span>
        <span class="font_text">消耗课时：{{courseTableData.PeriodCount}}课时/节课</span>
      </div>
      <div class="info_content_half">
        <span class="font_text">上课教室：{{courseTableData.ClassroomKeyValue}}</span>
        <span class="font_text">开课人数：{{courseTableData.StartCourseCount}}</span>
      </div>
      <div class="info_content_half">
        <span class="font_text">满员人数：{{courseTableData.FullPeopleCount}}</span>
        <span class="font_text"
              v-if="$utils.getSaaSClientSet(32) == 4 ">请假{{courseTableData.IsleaveKey==1?'':'不'}}扣课时</span>
        <span class="font_text"
              v-if="$utils.getSaaSClientSet(33) == 4 && this.$utils.getSaaSClientSet(59) > 0">旷课{{courseTableData.IsChargesKey==1?'':'不'}}扣课时</span>
       
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
    };
  },
  props: {
    courseTableData: Object,
    isClassGradeWarning: Boolean,
    generatedClassTimeData: Array
  },
  computed: {
    vSaaSClientInfo () {
      return this.$store.getters.SaaSClientInfo;
    }
  },
  methods: {
    clickCourse () {
      this.$emit('editCourse');
    }
  }
};
</script>