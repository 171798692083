import { render, staticRenderFns } from "./form-text.vue?vue&type=template&id=7a58f78a&scoped=true"
import script from "./form-text.vue?vue&type=script&lang=js"
export * from "./form-text.vue?vue&type=script&lang=js"
import style0 from "./form-text.vue?vue&type=style&index=0&id=7a58f78a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a58f78a",
  null
  
)

export default component.exports