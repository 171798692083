<template>
  <div>
    <div class="course_statistics_table_search flex course_statistics_title">
      <div class="course_statistics_search">
        <div class="flex"
             style="align-items: center;">
          <span class="">选择年份</span>
          <div class="title_date_select">
            <div class="el-select">
              <div class="el-input el-input--small el-input--suffix is-focus">
                <single-year-picker @chgDate="changeSaveDate"
                                    ref="monthPicker"
                                    :type="'year'"></single-year-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <list class="summarizing_list"
            :key="tableKey"
            :tableData="vdataReportList"
            :totalNum="Total"
            :PageCount="PageCount"
            :tableColumns="tableCourseColumns"
            :isSearchText="false"
            :isNeedInnerScroll="false"
            :defaultSort="defaultSort"
            :queryParams="searchObj"
            :footerContent="vFooterTotal"
            @loadTableData="getDataList"></list>
    </div>
    <div v-if="vPayTypeList.length > 0"
         class="payment_statistics_bottom_ul">
      <acount-item v-for="(item,key) in vPayTypeList"
                   :key="key"
                   :item="item">
      </acount-item>
    </div>
  </div>
</template>
<script>
import singleYearPicker from '../../../base-module/date-picker-common/single-year-picker';
import list from '../../../common/table-list/index';
import acountItem from './acount-item';
import { MessageSearchInComePayReportForMonth } from '../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: Date.new().getFullYear() + '-01-01',
        endTime: Date.new().getFullYear() + '-12-31',
        IsExportKey: 0
      },
      Total: 0,
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          decimalPlace: 0,
          className: 'table_index_gray'
        },
        {
          label: '发生日期',
          prop: 'SetUpTime',
          minWidth: 132,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0

        },
        {
          label: '报名收款',
          prop: 'ApplyAmount',
          minWidth: 182,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item',
          decimalPlace: 0,
          extend: {
            setClassName: (item) => {
              return item.ApplyAmount == '-' ? 'font_black' : 'font_blue';
            },
            click: (rowData) => {
              // this.doAfterClickToAtten(rowData);
              if (rowData.ApplyAmount != '-') {
                this.toApplyReport(rowData);
              }
            },
            ModulePowerKey: ''
          }
        },
        {
          label: '其他收入',
          prop: 'OtherInAmount',
          minWidth: 170,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item',
          decimalPlace: 0,
          eventName: '3',
          extend: {
            setClassName: (item) => {
              return item.OtherInAmount == '-' ? 'font_black' : 'font_blue';
            },
            click: (rowData) => {
              // this.doAfterClickToAtten(rowData);
              if (rowData.OtherInAmount != '-') {
                this.toOtherIncomeReport(rowData);
              }
            },
            ModulePowerKey: ''
          }
        },
        {
          label: '支出',
          prop: 'PayAmount',
          minWidth: 126,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          eventName: '4',
          extend: {
            setClassName: (item) => {
              return item.PayAmount == '-' ? 'font_black' : 'font_blue';
            },
            click: (rowData) => {
              // this.doAfterClickToAtten(rowData);
              if (rowData.PayAmount != '-') {
                this.toExpenditureReport(rowData);
              }
            },
            ModulePowerKey: ''
          }
        },
        {
          label: '收支净额',
          prop: 'TotalAmount',
          minWidth: 126,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          eventName: '4'
        }

      ],
      dataReportList: [],
      PayTypeReport: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    list,
    singleYearPicker,
    acountItem
  },
  props: {

  },
  created () {

  },
  computed: {

    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      return '共' + this.totalNum + '笔，合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
    },
    vPayTypeList () {
      let dataInfo = {};
      this.PayTypeReport.forEach((item, index) => {
        let { IncomePayAccountKey } = item;
        let { IncomePayAccountKeyValue } = item;
        let { AccountTypeKey } = item;
        if (!dataInfo[IncomePayAccountKey]) {
          dataInfo[IncomePayAccountKey] = {
            IncomePayAccountKey,
            IncomePayAccountKeyValue,
            AccountTypeKey,
            child: []
          };
        }
        dataInfo[IncomePayAccountKey].child.push(item);
      });
      let list = Object.values(dataInfo); // list 转换成功的数据
      console.log(list);
      return list;
    }
  },
  mounted () {
    this.getDataList();
  },
  methods: {
    changeSaveDate (dataInfo, startTime, endTime) {
      this.searchObj.startTime = startTime.replace(/\//g, '-');
      this.searchObj.endTime = endTime.replace(/\//g, '-'); ;
      this.searchData();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.GradeKey = '';
      this.searchObj.CourseKey = '';
      this.searchObj.TeacherKey = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },

    searchData () {
      this.searchObj.IsExportKey = 0;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '收付款月报',
          clist: [
            { title: '发生日期', cName: 'SetUpTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '报名收款', cName: 'ApplyAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '其他收入', cName: 'OtherInAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '支出', cName: 'PayAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '收支净额', cName: 'TotalAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchInComePayReportForMonth(searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      if (queryParams) {
        if (queryParams.orderBy == 'time') {
          queryParams.orderBy = 'SetUpTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj);
      MessageSearchInComePayReportForMonth(this.searchObj).then(result => {
        this.Total = result.data.Total;
        this.totalNum = result.data.TotalNum;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        this.PayTypeReport = result.data.PayTypeReport;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    toApplyReport (item) {
      this.$emit('toApplyReport', item, this.searchObj);
    },
    toOtherIncomeReport (item) {
      this.$emit('toOtherIncomeReport', item, this.searchObj);
    },
    toExpenditureReport (item) {
      this.$emit('toExpenditureReport', item, this.searchObj);
    }

  }
};
</script>