<template>
  <!-- 优惠券操作 -->
  <div class="list_btn_box">
    <div class="list_btn">
      <div class="list_btn_text" @click="sendClick">发送优惠券</div>
    </div>
    <div class="list_btn">
      <div class="list_btn_text" @click="editClick">修改</div> 
    </div>
    <div class="list_btn btn_icon">
      <div class="list_btn_text" @click.stop="moreClick">更多</div>
    </div>
  </div>
</template>
<script>

export default {
  components: {},
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object // 券信息
  },
  created () {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    // 点击发送
    sendClick () {
      this.$emit('sendClick', this.dataInfo);
    },
    // 点击修改
    editClick () {
      this.$emit('editClick', this.dataInfo);
    },
    // 点击更多
    moreClick () {
      this.$emit('moreClick', this.dataInfo);
    }
  }
};
</script>

