<template>
  <div class="chain_mendian_center">
    <mendian-atten-report></mendian-atten-report>
    <mendian-amount-report></mendian-amount-report>
    
  </div>
</template>
<script>
import mendianAttenReport from './components/mendian-atten-report';
import mendianAmountReport from './components/mendian-amount-report';
export default {
  data () {
    return {

    };
  },
  components: {
    mendianAmountReport, mendianAttenReport
  }
};
</script>