import { render, staticRenderFns } from "./courseware-packge-list-item.vue?vue&type=template&id=3ec76cca&scoped=true"
import script from "./courseware-packge-list-item.vue?vue&type=script&lang=js"
export * from "./courseware-packge-list-item.vue?vue&type=script&lang=js"
import style0 from "./courseware-packge-list-item.vue?vue&type=style&index=0&id=3ec76cca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ec76cca",
  null
  
)

export default component.exports