<template>
  <!-- 模板设置内容 -->
  <div class="weixin_setting_form">
    <div class="message_template_box form_info_edit">
      <!-- 头部 -->
      <form-select :formTitle="'设置区域'"
                   :readonly="isReadonly"
                   :formPlaceholder="' '"
                   class="no_border_bottom"
                   :dataItem="getTemplateSettingItem.value"
                   :dataList="getSelectData"
                   :value="getTemplateSettingItem.value"
                   @choseItem="showSelectHeadsetting"></form-select>

      <div class="weixin_setting_form_textarea">
        <!-- 选择区域 -->
        <div contenteditable="true"
             id="textarea_form"
             ref="focusTextarea"
             @keydown="doKeyboardPress($event)"
             v-html="this.getTemplateSettingItem.valueName">
        </div>

      </div>
      <!-- 可选标签 -->
      <div class="weixin_setting_form_label_box noselect">
        <div class="label_title">可选动态标签</div>
        <div class="label_box">
          <div v-for="(item,index) in getEventParametList"
               :key="index">
            <div class="label_block noselect"
                 @click="dropRelease($event,item.value,item.key)">
              <span class="noselect">{{item.value}}</span>
            </div>
          </div>
          <div class="label_block label_icon noselect"
               @click="dropRelease($event,'<br>','\n')">换行 </div>
        </div>
      </div>
    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'static'"
                           @confirmClick="doConfirmEditSetting"
                           @cancelClick="doCloseEditSetting"></save-cancel-btn-group>
  </div>
</template> 

<script>
import formSelect from '../../../../form-item/form-select';
export default {
  data () {
    return {
      templateStr: 'thing', // 获取标签类型
      newInputText: '', // 默认值
      showbanJiForm: '', // 控制显示表单
      isBrShow: true
      // newMessage: this.getTemplateSettingItem.valueName
    };
  },
  props: {
    isReadonly: {
      // 是否只读
      type: Boolean,
      default: false
    },
    // 门店信息
    getMendianInfo: Object,
    // 标签列表
    getEventParametList: Array,
    // 选择设置项
    getTemplateSettingItem: Object,
    // 设置区域可选内容
    getSelectData: Array
  },
  components: {
    formSelect
  },

  created () {
    this.templateStr = this.getTemplateSettingItem.key.replace(/[0-9]+/g, '');
    console.log(this.getMendianInfo, 'getMendianInfo getTemplateSettingItem', this.getTemplateSettingItem, this.templateStr);
    this.autoFocussing();
    // 删除该项设置
    window.removeTemplateFieldForNoticeSetting = function (removeBtn) {
      console.log(removeBtn, '删除该项设置1');
      removeBtn.parentNode.remove();
      // this.autoFocussing();//删除该项时需重置光标位置
      // 光标定位
      // var el = removeBtn.parentNode;
      // var range = document.createRange();
      // var sel = window.getSelection();
      // range.setStart(el.childNodes[0], el.childNodes[0].length);
      // range.collapse(true);
      // sel.removeAllRanges();
      // sel.addRange(range);
      // el.focus();
    };
    // window.removeTemplateFieldForNoticeSetting(removeBtn => {
    //   removeBtn.parentNode.remove();
    //   this.autoFocussing();
    // });
    // 点击事件
    window.doSelectedLabelShow = function (event) {

    };
  },
  watch: {

  },
  computed: {

  },
  methods: {
    mHtmlText (e) {
      console.log(e, 'doKeyboardPress');
      return e;
    },
    // 键盘按下
    doKeyboardPress (e) {
      var ev = (typeof event != 'undefined') ? window.event : e;
      console.log(ev, 'doKeyboardPress');
      if (this.templateStr == 'time') {//时间 禁止键盘输入
        e.preventDefault();
        return false;
      }
      if (ev.keyCode == 13) { // 禁止点击回车
        e.preventDefault();
        return false;
      }
      if (ev.keyCode == 8) { // 禁止点击回车
        // console.log(e.target.outerHTML, 'outerHTML');
        // e.target.outerHTML = e.target.outerHTML.replace('<div><br></div>', '');
        // this.autoFocussing();
      }
    },
    // 自动聚焦
    autoFocussing () {
      this.$nextTick(() => {
        setTimeout(() => {
          console.log(this.$refs.focusTextarea, '123');
          if (this.$refs.focusTextarea) {
            // 定位光标追加到最后
            var obj = this.$refs.focusTextarea;
            obj.focus();
            if (window.getSelection) { // ie11 10 9 ff safari
              obj.focus(); // 解决ff不获取焦点无法定位问题
              var range = window.getSelection();// 创建range
              range.selectAllChildren(obj);// range 选择obj下所有子内容
              range.collapseToEnd();// 光标移至最后
            } else if (document.selection) { // ie10 9 8 7 6 5
              var range = document.selection.createRange();// 创建选择对象
              // var range = document.body.createTextRange();
              range.moveToElementText(obj);// range定位到obj
              range.collapse(false);// 光标移至最后
              range.select();
            }
          }
        }, 10);
      });
    },
    // 头部标题设置显示
    showSelectHeadsetting (item) {
      this.$emit('showSelectHeadsettingItem', item);
      // 自动聚焦
      this.autoFocussing();
    },
    // 添加到指定位置并释放
    dropRelease (event, nodeValueName, nodeValueKey) {
      console.log(nodeValueName, 'this.添加到指定位置并释放');
      event = event || window.event;
      event.preventDefault();
      if (nodeValueKey === '\n') {
        this.insertHtmlAtCaretForTempleateField(document.createElement('br'));
        this.insertHtmlAtCaretForTempleateField(document.createElement('br'));
      } else {
        // tfName="${nodeValueKey}"
        let textNode = document.createElement('span');
        nodeValueName = this.transitionText(nodeValueName);
        var defaultImg = require('../../../../../../public/image/X_icon.png') + '"';
        textNode.innerHTML = `<span contenteditable="false" style="display: inline;padding-right: 5px" class="remover_block templeateField font_block" onclick="doSelectedLabelShow(this)">${nodeValueName}<img class="remover_button" src="${defaultImg}" alt="" onclick="removeTemplateFieldForNoticeSetting(this)"></span>&nbsp;`;
        this.insertHtmlAtCaretForTempleateField(textNode.children[0]);
        console.log(textNode.children[0], 'this.textNode');
      }
    },
    transitionText (insertTxt) {
      console.log(insertTxt, 'this.insertTxt2312312', this.getMendianInfo.WktInfo);
      if (insertTxt == '门店名称') {
        return this.getMendianInfo.WktInfo.name ? this.getMendianInfo.WktInfo.name : '门店名称';
      } else if (insertTxt == '门店电话') {
        return this.getMendianInfo.WktInfo.tel ? this.getMendianInfo.WktInfo.tel : '门店电话';
      } else if (insertTxt == '门店地址') {
        return this.getMendianInfo.WktInfo.address ? this.getMendianInfo.WktInfo.address : '门店地址';
      } else {
        return insertTxt;
      }
    },
    // 元素转字符串
    nodeToString (node) {
      // createElement()返回一个Element对象
      var tmpNode = document.createElement('div');
      // appendChild()  参数Node对象   返回Node对象  Element方法
      // cloneNode()  参数布尔类型  返回Node对象   Element方法
      tmpNode.appendChild(node.cloneNode(true));
      var str = tmpNode.innerHTML;
      tmpNode = node = null; // prevent memory leaks in IE
      return str;
    },
    // 记录光标位置插入文本
    insertHtmlAtCaretForTempleateField (html) {
      let sel, range;
      if (window.getSelection) {
        // IE9 and non-IE
        sel = window.getSelection();
        // console.log(sel);
        if ((sel.anchorNode.id && sel.anchorNode.id == 'textarea_form') || (sel.anchorNode.parentNode && sel.anchorNode.parentNode.id == 'textarea_form')) {
          if (sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();
            let el = document.createElement('div');
            el.appendChild(html);
            var frag = document.createDocumentFragment(), node, lastNode;
            while ((node = el.firstChild)) {
              lastNode = frag.appendChild(node);
            }
            range.insertNode(frag);
            if (lastNode && range) {
              range = range.cloneRange();
              range.setStartAfter(lastNode);
              range.collapse(false);
              sel.removeAllRanges();
              sel.addRange(range);
              // console.log(range.commonAncestorContainer.innerHTML, 'range');
              if (html.innerHTML) { // 换行不进入条件
                let htmlText = range.commonAncestorContainer;
                // htmlText.innerHTML = htmlText.innerHTML.replace(/&nbsp;/gi, '');// 全局清空，防止多出空格
                htmlText.innerHTML = htmlText.innerHTML.replace(new RegExp('</span><', 'g'), '</span>&nbsp;<');// 全局替换，添加一个空格
                htmlText.innerHTML = htmlText.innerHTML.replace(new RegExp('<br><br>', 'g'), '<br>');// 全局替换，还原一个<br>
                htmlText.innerHTML += '&nbsp';// 全局替换，最后一个添加一个空格
                // console.log(htmlText);
              }
            }
          }
        }
      }
      this.autoFocussing();
      // else if (document.selection && document.selection.type != 'Control') {
      //   console.log(document.selection, '2');
      //   // IE < 9
      //   document.selection.createRange().pasteHTML(html);
      // }
    },
    doConfirmEditSetting (callBack) {
      let node = this.$refs.focusTextarea;
      let str1 = '';
      let str2 = '';
      node.innerHTML = node.innerHTML.replace(new RegExp('<br>&nbsp;', 'g'), '<br>');// 全局替换，添加一个空格
      node.innerHTML = node.innerHTML.replace(new RegExp('<br><br>', 'g'), '<br>');// 全局替换，还原一个<br>
      str1 = node.innerHTML.replace(/<.*?>/ig, '');// 去掉标签
      str2 = str1.replace(/&nbsp;/ig, '');// 去掉空格
      // let str3 = str2.match(/^(\d{4})(年)(\d{1,2})(月)(\d{1,2})$/);
      console.log(node, 'node666 callBack', this.templateStr, str1, str2);
      switch (this.templateStr) {
        case 'thing':
          if (str2.length <= 20) {
            this.$emit('doConfirmEditSetting', callBack, node);
          } else {
            layer.alert('请输入20以内的字符');
          }
          break;
        case 'number':
          if (!isNaN(str2.length)) {
            if (str2.length <= 32) {
              this.$emit('doConfirmEditSetting', callBack, node);
            } else {
              layer.alert('请输入32以内的纯数字');
            }
          } else {
            layer.alert('请输入纯数字');
          }
          break;
        case 'letter':
          if (str2.length <= 32) {
            this.$emit('doConfirmEditSetting', callBack, node);
          } else {
            layer.alert('请输入32以内的字符');
          }
          break;
        case 'symbol':
          if (str2.length <= 5) {
            this.$emit('doConfirmEditSetting', callBack, node);
          } else {
            layer.alert('请输入5以内的字符');
          }
          break;
        case 'time':
          if ((isNaN(str2) && !isNaN(Date.parse(str2))) || str2.match(/^(\d{4})(年)(\d{1,2})(月)(\d{1,2})$/) || ((str2.indexOf('时间') != -1 || str2.indexOf('日期') != -1) && str2.length < 6)) {
            this.$emit('doConfirmEditSetting', callBack, node);
          } else {
            layer.alert('请输入日期格式');
          }
          break;
        case 'phrase':
          if (str2.length <= 5) {
            this.$emit('doConfirmEditSetting', callBack, node);
          } else {
            layer.alert('请输入5以内的字符');
          }
          break;
        default:
          this.$emit('doConfirmEditSetting', callBack, node);
          break;
      }
      // this.$emit('doConfirmEditSetting', callBack, node);
    },
    doCloseEditSetting () {
      this.$emit('doCloseEditSetting');
    }
    // 阻止默认事件
    // allowDrop (event) {
    //   let e = event || window.event;
    //   if (e && e.preventDefault) {
    //     e.preventDefault();
    //   } else {
    //     window.event.returnValue = false;
    //   }
    // },
  }

};
</script>

  <style>
</style>