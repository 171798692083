<template>
  <!-- 返介绍费 -->
  <div class="single_result_detail form_info_detail">
    <div class="student_info_edit form_info_edit form_info_list"
         style="height: 500px;">
      <input-text :formTitle="'申请日期'"
                  :formPlaceholder="'必填'"
                  :readonly="true"
                  :required="false"
                  v-model="dataInfo.ApplyTime"></input-text>

      <input-text :formTitle="'申请人'"
                  :formPlaceholder="'必填'"
                  :readonly="true"
                  :required="false"
                  v-model="dataInfo.ApplicantKeyValue"></input-text>

      <input-text :formTitle="'签约机构'"
                  :formPlaceholder="'必填'"
                  :readonly="true"
                  :required="false"
                  :value="dataInfo.SaaSClientNameKeyValue+'( ID:'+dataInfo.SaaSClientNameKey+')'"></input-text>

      <input-text :formTitle="'联系人'"
                  :formPlaceholder="'必填'"
                  :readonly="true"
                  :required="false"
                  :value="dataInfo.ContactsName +'-'+dataInfo.MobileSupport"></input-text>

      <div class="form_item form-input form_info_line">
        <div class="form_info_field">
          <span>介绍人</span>
        </div>
        <div class="input_readonly form_info_value">
          {{dataInfo.CashBack.CashSaaSClientKeyValue+'( ID:'+dataInfo.CashBack.CashSaaSClientKey+') '}}<br />{{dataInfo.CashBack.CashBackKeyValue +'-'+dataInfo.CashBack.MobileSupport}}
        </div>
      </div>

      <input-number :formTitle="'介绍费'"
                    :formPlaceholder="'必填'"
                    :decimalPlaces="2"
                    :readonly="isReadOnly"
                    :required="true"
                    v-model="dataInfo.CashBack.CashBackAmount"></input-number>

      <input-text :formTitle="'申请原因'"
                  :formPlaceholder="'必填'"
                  :readonly="isReadOnly"
                  :required="true"
                  v-model="dataInfo.ReasonScript"></input-text>

    </div>
    <div v-if="!isReadOnly">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :btnText="'提交'"
                             @confirmClick="submitAdd"
                             @cancelClick="doAfterClickCancelBtn"></save-cancel-btn-group>
    </div>
    <div v-else
         class="confirm_btn btn_fixed_bottom">
      <div class="btn_fixed_white"
           style="width: 100%;"
           @click.stop="revocation">撤销</div>
    </div>
  </div>
</template>
<script>
import { AddApply, GetThreadMarketDetails, GetApplyDetails, CancelApply } from '../../../../../API/workbench';
export default {
  data () {
    return {
      isReadOnly: false,
      dataInfo: {
        ApplyTime: this.$utils.formatDateToLine(Date.new()), // 申请时间'
        ApplicantKey: '', // 申请人'
        ApplicantKeyValue: '', // 申请人'
        SaaSClientNameKey: '', // 机构Key'
        SaaSClientNameKeyValue: '', // 机构'
        SaaSClientStatusKey: '', // 机构状态0-未开通;1-临时开通;2-正式开通;3-关停'
        SaaSClientStatusKeyValue: '', // 机构状态'
        MobileSupport: '', // 手机号'
        ContactsName: '', // 联系人'
        ThreadKey: '', // 线索key'
        TypeKey: '', // 1-开户;2-付款;3-返介绍费;4-清数据'
        TypeKeyValue: '', // 1-开户;2-付款;3-返介绍费;4-清数据'
        ReasonScript: '', // 申请原因'
        PayMent: null, // 付款申请
        ClearSaaSClien: null, // 清数据申请
        CashBack: {// 返介绍费
          CashSaaSClientKey: '', // 返现人门店'
          CashSaaSClientKeyValue: '', // 返现人门店'
          CashBackAmount: '', // 返现金额'
          MobileSupport: '', // 返现人手机号'
          CashBackKey: '', // 返现人'
          CashBackKeyValue: '' // 返现人'
        }
      },
      showAuthCode: false
    };
  },
  components: {

  },
  props: {
    threadId: [Number, String],
    CashAuditKey: [Number, String]
  },
  created () {
    console.log(this.introduceInfo, 'introduceInfo');
    if (this.threadId && this.threadId > 0) {
      if (this.CashAuditKey && this.CashAuditKey > 0) {
        this.isReadOnly = true;
        this.getApplyDetails(this.CashAuditKey);// 查询申请记录信息
      } else {
        this.getDetailInfo(this.threadId);// 查询线索信息
      }
    }
  },
  computed: {
    vIsEdit () {
      if (this.seletedItem && this.seletedItem.TempAuditKey > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    // 查询线索信息
    getDetailInfo (OLAPKey) {
      GetThreadMarketDetails(OLAPKey).then(result => {
        console.log('GetApplyDetails', result.data);
        console.log(this.$store.getters.token, 'initAddData');
        this.dataInfo.id = OLAPKey;
        this.dataInfo.ApplicantKey = this.$store.getters.token.UserID;// 申请时间
        this.dataInfo.ApplicantKeyValue = this.$store.getters.token.UserName;// 申请人
        this.dataInfo.SaaSClientNameKey = result.data.SaaSClientNameKey; // '机构Key',
        this.dataInfo.SaaSClientNameKeyValue = result.data.SaaSClientNameKeyValue; // '机构',
        this.dataInfo.SaaSClientStatusKey = result.data.StatusKey; // 机构状态0-未开通;1-临时开通;2-正式开通;3-关停'
        this.dataInfo.SaaSClientStatusKeyValue = result.data.StatusKeyValue; // 机构状态'
        this.dataInfo.MobileSupport = result.data.MobileSupport; // '手机号',
        this.dataInfo.ContactsName = result.data.ContactsName; // '联系人',
        this.dataInfo.ThreadKey = result.data.OLAPKey; // '线索key',
        this.dataInfo.ReasonScript = ''; // 申请原因'
        this.dataInfo.TypeKey = 3; // 1-开户;2-付款;3-返介绍费;4-清数据'
        this.dataInfo.TypeKeyValue = '返介绍费'; // 1-开户;2-付款;3-返介绍费;4-清数据'
        this.dataInfo.CashBack = {// 返介绍费
          CashSaaSClientKey: result.data.IntroduceSaaSClientKey, // 返现人门店'
          CashSaaSClientKeyValue: result.data.IntroduceSaaSClientKeyValue, // 返现人门店'
          CashBackAmount: '', // 返现金额'
          MobileSupport: result.data.IntroduceMobileSupport, // 返现人手机号'
          CashBackKey: result.data.IntroduceKey, // 返现人'
          CashBackKeyValue: result.data.IntroduceKeyValue  // 返现人'
        };
      });
    },
    // 查询申请记录信息
    getApplyDetails (OLAPKey) {
      GetApplyDetails(OLAPKey).then(result => {
        console.log('GetApplyDetails', result.data);
        this.dataInfo.id = OLAPKey;
        this.dataInfo.ApplicantKey = result.data.ApplyTime;// 申请时间
        this.dataInfo.ApplicantKeyValue = result.data.ApplicantKeyValue;// 申请人
        this.dataInfo.SaaSClientNameKey = result.data.SaaSClientNameKey; // '机构Key',
        this.dataInfo.SaaSClientNameKeyValue = result.data.SaaSClientNameKeyValue; // '机构',
        this.dataInfo.SaaSClientStatusKey = result.data.SaaSClientStatusKey; // 机构状态0-未开通;1-临时开通;2-正式开通;3-关停'
        this.dataInfo.SaaSClientStatusKeyValue = ''; // 机构状态'
        this.dataInfo.MobileSupport = result.data.MobileSupport; // '手机号',
        this.dataInfo.ContactsName = result.data.ContactsName; // '联系人',
        this.dataInfo.ThreadKey = result.data.OLAPKey; // '线索key',
        this.dataInfo.ReasonScript = result.data.ReasonScript; // 申请原因'
        this.dataInfo.TypeKey = 3; // 1-开户;2-付款;3-返介绍费;4-清数据'
        this.dataInfo.TypeKeyValue = '返介绍费'; // 1-开户;2-付款;3-返介绍费;4-清数据'
        this.dataInfo.CashBack = {// 返介绍费
          CashSaaSClientKey: result.data.CashBack.CashSaaSClientKey, // 返现人门店'
          CashSaaSClientKeyValue: result.data.CashBack.CashSaaSClientKeyValue, // 返现人门店'
          CashBackAmount: result.data.CashBack.CashBackAmount, // 返现金额'
          MobileSupport: result.data.CashBack.MobileSupport, // 返现人手机号'
          CashBackKey: result.data.CashBack.CashBackKey, // 返现人'
          CashBackKeyValue: result.data.CashBack.CashBackKeyValue  // 返现人'
        };
      });
    },
    // 提交审核
    submitAudit (callback) {
      console.log('submit', this.dataInfo);
      this.showAuthCode = true;
      callback();
    },
    // 提交新增
    submitAdd (callback) {
      console.log('submit', this.dataInfo);
      let isPass = true;
      if (!this.dataInfo.CashBack.CashBackAmount) {
        layer.alert('介绍费需大于0');
        isPass = false;
      } else if (this.dataInfo.ReasonScript == '') {
        layer.alert('请输入申请原因');
        isPass = false;
      }
      if (!isPass) {
        if (callback) {
          callback();
        }
        return false;
      }
      AddApply(this.dataInfo).then(result => {
        layer.alert('新增成功');
        this.$emit('afterSuccess', result.data);
        this.doAfterClickCancelBtn();
        if (callback) {
          callback();
        }
      }).catch(error => {
        layer.alert(error.msg);
        if (callback) {
          callback();
        }
        console.log('errEditDefaultReply', error);
      });
    },
    // 撤销
    revocation () {
      layer.confirm('请问是否要撤销该申请', {
        btn: [{
          name: '确认',
          callBack: () => {
            CancelApply(this.dataInfo.id).then(result => {
              this.$emit('afterSuccess', result.data);
              this.doAfterClickCancelBtn();
            }, err => {
              layer.alert(err.msg);
            });
            layer.close();
          }
        }
        ]
      });
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    }
  }
};
</script>
