<template>
  <!-- 改手机号 -->
  <div>
    <module-description :textTips="'修改的手机号下有多个学生会全部被修改。'"
                        :btnTips="'查看教程'"
                        :btnUrl="'https://mp.weixin.qq.com/s/O6-IXQ3aAkTkxPB146qCOw'"></module-description>
    <!-- 搜索学生弹框 -->
    <div v-if="searchBoxShow">
      <searchBox :searchInitJson="newScheduleCourseInitJson"
                 @searchClick="chooseClick"
                 @onSelectedStudent="selectedItem"></searchBox>
    </div>
    <!-- 学生列表弹框 -->
    <div v-else-if="singleSelectionListShow">
      <student-list :isShowSearchInput="isShowSearchInput"
                    :switchItem="1"
                    @selectedItem="selectedItem"></student-list>
    </div>
    <!-- 选中后的单项学生 -->
    <div v-else-if="singleSelectedItemShow">
      <single-selected-item :searchInitJson="newScheduleCourseInitJson"
                            :studentItem="isSelectedStudentItem"
                            @showStudentFile="showStudentFile"
                            @buttonClickEvent="buttonClickEvent"
                            @cancelChgMobile="cancelChgMobile"
                            @studentPopups="studentPopups"
                            @changeStudent="changeStudent"></single-selected-item>
    </div>
    <!-- 该用户下已有的学生列表弹出层 isStudentPopups-->
    <div>
      <user-below-student-dialog ref="studentPopups"
                                 :studentList="mobileByStudentList"
                                 :popupContent="popupContent"
                                 :isSingleSubmit="true"
                                 :singleSubmitType="popupContent.mark == 'abnormal'?'dynamic':'static'"
                                 @doAddStudentClick="doPopupAddStudent"
                                 @doCloseClick="doCloseClick"></user-below-student-dialog>
    </div>
  </div>
</template>
<script>
// 改手机号
import searchBox from '../../../../components/common/search-box';
import studentList from '../../../../components/common/student-list';
import singleSelectedItem from './single-selected-item';
import userBelowStudentDialog from '../../../common/popups-box/user-below-student-dialog/phone-index';
import { UpdateCustomerTel, getStudentRelatedMsg } from '../../../../API/workbench';
export default {
  name: 'changePhoneNum',
  props: ['dataInfo'],
  data () {
    return {
      searchBoxShow: true, // 搜索框
      singleSelectionListShow: false,
      singleSelectedItemShow: false,
      //  搜索目标
      newScheduleCourseInitJson: {
        name: '目标学生'
      },
      isSelectedStudentItem: {}, // 学生选中项
      isShowSearchInput: false,
      mobileByStudentList: [], // 修改号码后的用户信息
      // 学生弹出层显示内容
      popupContent: {
        titleContent: '',
        textContent: '',
        studentButName: '', // 显示学生下的合并按钮名称
        submitButName: '', // 提交按钮名称
        mark: 'normal', // 标示==》 normal：正常, abnormal:异常
        mobile: '', // 电话
        Remarks: ''// 处理原因
      }
    };
  },
  components: {
    searchBox, // 搜索学生
    studentList, // 学生列表
    singleSelectedItem, // 选中项
    userBelowStudentDialog
  },
  created () {
    if (this.dataInfo && Number(this.dataInfo.StudentKey) > 0) {
      this.searchBoxShow = false;
      this.loadStudentDetail(this.dataInfo.StudentKey);
    }
  },
  watch: {

  },
  computed: {},
  methods: {
    //  外部选中加载学生信息
    loadStudentDetail (studentKey) {
      getStudentRelatedMsg(studentKey).then(result => {
        console.log(result.data, '外部选中加载学生信息');
        if (result.data) {
          this.selectedItem(result.data);
        }
      }, error => {
        layer.alert(error.msg);
      }
      );
    },
    // 关闭弹窗
    isShowPhonePopupHide () {
      console.log('关闭弹窗');
      this.$emit('closeChangephonenumDialog');
      this.singleSelectionListShow = false;
      this.singleSelectedItemShow = false;
    },
    // 点击搜索 搜索框可见 并查询数据
    chooseClick () {
      this.searchBoxShow = false;
      this.singleSelectionListShow = true;
    },
    // 取消修改手机.
    cancelChgMobile () {
      this.isShowPhonePopupHide();
    },
    // 点击确定修改手机
    buttonClickEvent (MobileSupport, callBack) {
      this.isSelectedStudentItem.MobileSupport = MobileSupport;
      if (callBack) {
        callBack();
      } else {
        this.cancelChgMobile();
      }
    },
    selectedItem (item) {
      this.isSelectedStudentItem = item;
      this.$set(
        this.isSelectedStudentItem,
        'MobileSupport',
        this.isSelectedStudentItem.MobileSupport
      );
      this.singleSelectionListShow = false;
      this.singleSelectedItemShow = true;
    },
    // 显示学员档案
    showStudentFile () {
      this.$emit('showStudentFile', this.isSelectedStudentItem);
    },
    // 切换学生
    changeStudent () {
      this.singleSelectionListShow = true;
      this.singleSelectedItemShow = false;
    },
    // 手机号下的学生弹窗
    studentPopups (mobile, list, Remarks) {
      this.mobileByStudentList = list;
      this.popupContent.mobile = mobile;
      this.popupContent.Remarks = Remarks;
      this.popupContent.mark = 'abnormal';// normal：正常, abnormal：异常
      this.popupContent.submitButName = '确认'; // 提交按钮名称
      this.popupContent.titleContent = '';
      this.popupContent.textContent = '手机号' + ' (' + this.popupContent.mobile + ') ' + '的学生';
      this.$refs.studentPopups.isStudentPopups = true;
    },
    // 已有学生时 确认操作
    doPopupAddStudent (callBack) {
      if (this.popupContent.mark == 'abnormal') {
        UpdateCustomerTel(this.isSelectedStudentItem.CustKey, this.popupContent.mobile, this.isSelectedStudentItem.OLAPKey, this.popupContent.Remarks).then(result => {
          this.mobileByStudentList = result.data.data;
          this.popupContent.mark = 'normal';// normal：正常, abnormal：异常
          this.popupContent.submitButName = '确认'; // 提交按钮名称
          this.popupContent.titleContent = '手机号变更成功!';
          this.popupContent.textContent = '手机号' + ' (' + this.popupContent.mobile + ') ' + '的学生:';
          // this.isShowPhonePopupHide();
        }, errer => {
          layer.alert(errer.msg);
          this.$refs.studentPopups.isStudentPopups = false;
        });
      } else {
        layer.alert('修改成功');
        this.isShowPhonePopupHide();
        this.$refs.studentPopups.isStudentPopups = false;
      }
      if (callBack) {
        callBack();
      }
    },
    // 取消
    doCloseClick () {
      if (this.popupContent.mark == 'normal' && this.popupContent.studentButName == '') {
        this.isShowPhonePopupHide();
      }
    }
  }
};
</script>