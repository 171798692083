<template>
  <div class="course_list pr">
   
      <div class="course_list_content">
        <div class="course_list_left">
          <div class="course_list_name">{{ item.GradeClassKeyValue }}</div>
          <div class="course_list_left_btn pr">
            <div>循环课 {{ Number(item.RuleCount) }}</div>
            <div class="list_btn" @click.stop="showSeletedPupUp($event)">

            </div>
          </div>
        </div>
        <div class="course_list_right">
           <draggable v-model="item.StudentList"
                :options="{group:{name: 'site',pull:'clone'},sort: false}"
                 animation="100"
                 dragClass="dragClass"
                 ghostClass="ghostClass"
                 chosenClass="chosenClass"
                 filter=".forbid"
                 handle=".mover"
                 v-bind="{sort: false,store: false,}"
                 @start="onStart"
                 @end="onEnd"
                 :move="onMove"
                 @add="onAdd">
          <transition-group class="draggable"
                          :id="item.GradeClassKey">
            <student-item
              v-for="(studentItem, index) in item.StudentList"
              :key="studentItem.StudentKey"
              :index="index"
              :studentItem="studentItem"
              :isDrag="isDrag"
               :class="'mover'"
              @doMouseEvent="doMouseEvent"
              @seletedStuItem="seletedStuItemAfter"
            ></student-item>
             <function-btn
              :key="'新增'"
              :item="addbtn"
              @clickItem="showAddStudentDialog"
              :className="'list_add forbid'"
            >
            </function-btn>
          </transition-group>
          
          </draggable>
          
         
        </div>
        <div
          class="course_list_btn"
          :class="item.isshowDetail ? 'open_type' : ''"
          @click.stop="showDetail"
        ></div>
      </div>
      <div class="course_list_box" v-if="item.isshowDetail" @click.stop="keep">
        <courseRulesList
          ref="courseRulesList"
          v-for="rulesItem in vRulesList"
          :key="rulesItem.StructuredRuleKey"
          :item="rulesItem"
          :studentList="studentList"
          :searchText="searchText"
          :isTemporaryStu="isTemporaryStu"
          :copyData="copyData"
          :seletedStructuredRuleKey="seletedStructuredRuleKey"
          :seletedStudentInfo="seletedStudentInfo"
          :RulesList="vRulesList"
          @draggRuleStudentMove="draggRuleStudentMove"
          @draggRuleStudentStart="draggRuleStudentStart"
          @draggRuleStudentEnd="draggRuleStudentEnd"
          @clearTimeOutAfter="clearTimeOutAfter"
          @setPopUpPositionAfter="setPopUpPositionAfter"
          @updateClassStudentList="getStudentArrangedListByGradeAndCourse"
        ></courseRulesList>
      </div>
  
    <!-- 课块操作-->
    <div
      class="
        course_student_attendance_operation
        module_content_menuList
        active
        courseOptionPupUp
      "
      :style="{ left: pupupPositionLeft + 'px', top: pupupPositionTop + 'px' }"
      v-show="isShowCourseOptionDialog"
    >
      <div class="top_bg"></div>
      <drop-down-box
        :optionJsonList="rolePopupMenu"
        @clickOptionForAlertDialog="doAfterClickRolePopup"
      ></drop-down-box>
    </div>
    <!-- 添加学生弹框. -->
    <div>
      <students-list
        ref="studentsList"
        :formTitle="'安排学生进班'"
        :classGradeID="item.GradeClassKey"
        :selectCourseID="courseInfo.CourseNameKey"
        @onAddStudent="addInClass"
        @onDeleteStudent="quitStudentClass"
      ></students-list>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import courseRulesList from '../course-rules-list';
import dropDownBox from '../../../common/drop-down-box';
import studentsList from '../../class-grade-applycourse/general-class-grade-apply-course-list/students-list';
import {
  QuitStudentFromClass,
  SearchStructedRule,
  studentAddClass,
  gradeCourseStudentArranged
} from '../../../../API/workbench';
import studentItem from './student-item';
export default {
  data () {
    return {
      addbtn: {
        name: '学生',
        type: '',
        ModulePowerKey: 51
      },
      searchText: '',
      studentList: [],
      isshowDetail: false,
      seletedStructuredRuleKey: 0,
      isTemporaryStu: false,
      isShowCourseOptionDialog: false,
      pupupPositionLeft: 0,
      pupupPositionTop: 0,
      rolePopupMenu: [
        {
          name: '新建课表',
          eventName: 'newCircleCourseTable',
          isShow: true,
          ModulePowerKey: 47
        },
        {
          name: '班级详情',
          eventName: 'classGradeApplycourse',
          isShow: true,
          ModulePowerKey: 53
        }
      ],
      seletedStudentInfo: null,
       isDrag: false, // 正在拖拽
       draggStudentItem:null
    };
  },
  components: {
    studentItem,
    studentsList,
    courseRulesList,
    dropDownBox,
    draggable
  },
  created () {},
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener(
      'click',
      (e) => {
        this.isShowCourseOptionDialog = false;
        this.$store.commit('showAlerDialog', false);
      },
      true
    );
  },
  props: {
    item: Object,
    courseInfo: Object,
    copyData: {
      type: Object,
      default: {
        StructuredRuleKey: 0,
        copyStudentList: []
      }
    }
  },
  computed: {
    vRulesList () {
      this.item.rulesList.sort((x, y) => {
        return Number(x.WeekKey) - Number(y.WeekKey);
      });
      return this.item.rulesList;
    }
  },
  methods: {
    //拖拽循环规律的学生
    draggRuleStudentStart(studentitem,draggRuleClassItem){
      this.draggStudentItem = studentitem;
      this.draggRuleClassItem=draggRuleClassItem
    },
    draggRuleStudentMove(e){
      console.log("draggRuleStudentMove",e)
      // // 规律课块的学生不能拖进班级学生块。
      // if(e.to.id.toString().indexOf("_StructuredRuleKey")>=0){
      //   return false
      // }
      // 是否属于班级的规律课块
      let locaIndex = this.vRulesList.findIndex(o=>{
        return e.to.id.replace('_StructuredRuleKey','')==o.StructuredRuleKey
      })
      if(locaIndex==-1){
        return false
      }else{
        // 匹配移入的规律学生列表中有没有相同的学生。 
        if(e.to.id==e.from.id){
        
        }else{

          let studentIndex= this.$refs.courseRulesList[locaIndex].rulesStudentList.findIndex(o=>{
            return o.StudentKey==this.draggStudentItem.StudentKey
          })
          if(studentIndex>=0){
            return false
          }
        }
      }
    
      // this.$emit('draggRuleStudentMove')
    },
    //结束
    draggRuleStudentEnd(e){
      console.log("draggRuleStudentEnd",e)
      // // 规律课块的学生不能拖进班级学生块。
      // if(e.to.id.toString().indexOf("_StructuredRuleKey")>=0){
      //   return false
      // }
       if (this.draggStudentItem && e.to.id !== e.from.id) {
            let findIndex = this.vRulesList.findIndex(o => {
              return o.StructuredRuleKey == e.to.id.replace('_StructuredRuleKey','');
            });
            if (findIndex>=0) {
              console.log(this.$refs.courseRulesList[findIndex].vCombineStudentList,'this.$refs.courseRulesList[findIndex].vCombineStudentList')
                this.$refs.courseRulesList[findIndex].addToCircleCourseForStudent(this.draggStudentItem)
                let findOldIndex = this.vRulesList.findIndex(o => {
                  return o.StructuredRuleKey == e.from.id.replace('_StructuredRuleKey','');
                });
                if (findOldIndex>=0) {
                  this.$refs.courseRulesList[findOldIndex].cancelArrangeByParent(this.draggStudentItem)
                }
              
            } 
      }
    },
    doMouseEvent (type, index, item) {
    },
    //开始拖拽事件
    onStart (e) {
      console.log(e,'onStart')
      this.isDrag = true;
      this.draggStudentItem = this.item.StudentList[e.oldIndex];
    },
    onAdd (e) {
      
    },
    onMove(e){
      if(e.to.id==e.from.id){
        
      }else if(e.to.id.toString().indexOf('_StructuredRuleKey')==-1){
        return false
      }
    },
    // 拖拽结束事件
    onEnd (e) {
      console.log(e,'onEnd班级GradeClassKey',this.item.GradeClassKey,this.draggStudentItem)
       if (this.draggStudentItem && e.to.id !== e.from.id) {
         let findIndex = this.vRulesList.findIndex(o => {
           return o.StructuredRuleKey == e.to.id.replace('_StructuredRuleKey','');
        });
          console.log(findIndex,'findIndex',this.vRulesList)
        if (findIndex>=0) {
          let studentIndex= this.$refs.courseRulesList[findIndex].vCombineStudentList.findIndex(o=>{
            return o.StudentKey==this.draggStudentItem.StudentKey
          })
          console.log("studentIndex,",studentIndex)
          if(studentIndex==-1){
            this.$refs.courseRulesList[findIndex].addToCircleCourseForStudent(this.draggStudentItem)
          }
        }
      }
      this.isDrag = false;
    },
    initshowDetail(item){ 
      console.log("initshowDetail111",item)
      if(item.GradeClassKey==this.item.GradeClassKey){
        console.log("initshowDetail",item)
        this.showDetail()
      }
    },
    // 选中班内学生高亮规律课内的指定学生
    seletedStuItemAfter (item) {
      this.seletedStudentInfo = item;
      this.item.StudentList.forEach(o => {
         if (o.StudentKey == item.StudentKey) {
            this.$set(o, 'isActive', !o.isActive);
          }else {
            this.$set(o, 'isActive', false);
          }
       });
        // if(this.$refs.courseRulesList&&this.$refs.courseRulesList.length>0){
        //     this.$refs.courseRulesList.forEach(o=>{
        //       o.setRulesStudentListHighLight(item)
        //     })
        // }
      console.log('seletedStuItemAfter', this.$refs.courseRulesList, this.seletedStudentInfo);
    },
    updateDataForParent (dataObj) {
      console.log('updateDataForParent', this.item);
      if (
        this.item.GradeClassKey == dataObj.GradeClassKey &&
        this.item.isshowDetail
      ) {
        this.getStructedRuleDataByKey(
          this.courseInfo.CourseNameKey,
          this.item.GradeClassKey,
          (result) => {
            this.item.rulesList = result.data;
            this.getStudentArrangedListByGradeAndCourse();
            console.log('this.$$refs.courseRulesList');
            this.$refs.courseRulesList.forEach((o) => {
              o.updateDataForParent();
            });
          }
        );
      }
    },
    keep () {},
    showSeletedPupUp (e) {
      // let Rect = e.getBoundingClientRect();
      console.log(e, 'showSeletedPupUp', e.target);
      this.pupupPositionTop = 80;// e.layerY - e.offsetY + 20;
      this.pupupPositionLeft = 126;// e.layerX - e.offsetX + 20;
      this.isShowCourseOptionDialog = true;
    },
    // 循环课操作框选中
    doAfterClickRolePopup (menuItem) {
      console.log('doAfterSelectItemClick11', menuItem, this.seletedRuleItem);
      var val = menuItem.eventName;
      switch (val) {
        case 'newCircleCourseTable':
          this.toAddLoopCourse();
          break;
        case 'classGradeApplycourse':
          this.toClassGrade();
          break;
        default:
          break;
      }
      this.isShowCourseOptionDialog = false;
    },
    toClassGrade () {
      let item = {
        ModulePowerKey: 53,
        auth: true,
        name: '班级排课',
        roleName: '',
        routerName: 'classGradeApplycourse',
        routertitle: '班级排课',
        moduleName: '',
        dataKey: this.item.GradeClassKey
      };
      this.$bus.emit('needOpenRouter', item);
    },
    toAddLoopCourse () {
      // 需要传的参数
      // let WeekKey = item.WeekKey == 7 ? '0' : item.WeekKey;
      let paramsData = {
        SClassTimeName: '', // 上课开始时间
        EClassTimeName: '', // 上课结束时间
        CircleStartTime: '', // 上课开始日期
        CircleEndTime: '', // 上课结束日期
        FrequencyKey: 2, // 多课
        frequenceInfo: [],
        BJPKIDKey: this.item.GradeClassKey, // 班级ID
        BJPKIDKeyValue: this.item.GradeClassKeyValue, // 班级名
        ClassSetKey: this.courseInfo.CourseNameKey, // 课名ID
        ClassSetKeyValue: this.courseInfo.CourseNameKeyValue, // 课名
        ClassRoomIDSName: '', // 教室ID
        ClassRoomNames: '', // 教室名
        LecturerKeyValue: '', // 主讲ID
        LecturerKey: '', // 主讲名
        PeriodCount: 1, // 课消
        LeaveClassKey: 0, // 请假是否扣课时
        IsleaveKey: 0, // 旷课是否扣课时
        WeekKey: 0 // 周几
      };
      let moduleInfo = {
        name: '新建循环课',
        moduleName: 'newCircleCourseTable',
        routerName: this.$route.name,
        data: { dataInfo: paramsData },
        callBack: {
          afterSuccess: () => {
            this.$emit('updateData');
          }
        }
      };
      this.$bus.emit('needOpenRouter', moduleInfo);
    },
    clearTimeOutAfter () {
      this.$emit('clearTimeOutAfter');
    },
    setPopUpPositionAfter (el, item, left, top, parentElOffsetTop) {
      var elTop = this.$el.offsetTop;
      // console.log('course_list_item', this.$el.offsetTop, parentElOffsetTop);
      this.$emit(
        'setPopUpPositionAfter',
        el,
        item,
        left,
        top,
        parentElOffsetTop,
        elTop
      );
    },
    // 显示更多按钮.
    showDetail () {
      if (!this.item.isshowDetail) {
        this.getStructedRuleDataByKey(
          this.courseInfo.CourseNameKey,
          this.item.GradeClassKey,
          (result) => {
            this.item.rulesList = result.data;
            this.item.isshowDetail = !this.item.isshowDetail;
            if (this.item.isshowDetail) {
              this.getStudentArrangedListByGradeAndCourse();
            }
          }
        );
      } else {
        this.item.isshowDetail = !this.item.isshowDetail;
      }
    },
    // 获取所有循环课
    getStructedRuleDataByKey (CourseNameKey, GradeClassKey, callBack) {
      SearchStructedRule(CourseNameKey, GradeClassKey)
        .then((result) => {
          console.log(result.data, 'getStructedRuleData');
          if (callBack) {
            callBack(result);
          }
          // this.structedRuleData = result.data;
        })
        .catch((error) => {
          console.log(error, 'error');
        });
    },

    // 获取该班级下所有排课规律学生.
    getStudentArrangedListByGradeAndCourse () {
      gradeCourseStudentArranged(
        this.item.GradeClassKey,
        this.courseInfo.CourseNameKey
      ).then((result) => {
        console.log('getStudentArrangedListByGradeAndCourse', result.data);
        result.data.forEach(o => {
          o.isActive = false;
        });
        this.studentList = this.$utils.parseJson(result.data);
        this.item.studentList = this.$utils.parseJson(result.data);
      });
    },
    // 添加学生弹出层 isStudentAttendance
    showAddStudentDialog () {
      this.item.StudentList.forEach(o=>{
        this.$set(o,'OLAPKey',o.StudentKey)
      })
      this.$refs.studentsList.isShowAddStudent = true;
      this.studentTitle = '安排学生上课';
      this.$nextTick(() => {
        this.$refs.studentsList.studenSearchText = '';
        this.$refs.studentsList.pushDataStudentList(this.item.StudentList);
        this.$refs.studentsList.getStudentList();
      });
    },
    // 加入班级
    addInClass (item) {
      console.log(item, 'item');
      studentAddClass(item.StudentKey, this.item.GradeClassKey).then(
        (result) => {
          this.$bus.emit(
            'afterQuitOrAddClass',
            this.item.GradeClassKey,
            item.StudentKey
          );
          // this.$emit("updateData");
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    // 退班
    quitStudentClass (item, index, completeCallback) {

      let studentItem=this.$utils.parseJson(item)
      QuitStudentFromClass(studentItem.StudentKey, this.item.GradeClassKey).then(
        (res) => {
          console.log(item,'quitStudentClass',index)
          this.$bus.emit(
            'afterQuitOrAddClass',
            this.item.GradeClassKey,
            item.StudentKey
          );
          completeCallback();
         
          // this.getStudentArrangedListByGradeAndCourse();
          // this.$emit("updateData");
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    }
  }
};
</script>