<!-- 文本 -->
<template>
  <el-table-column :label="columnData.label"
                   :prop="columnData.prop"
                   :width="columnData.width"
                   :min-width="columnData.minWidth">
    <template slot-scope="scope">
      <!-- 特例，兼容多行文本鼠标移除显示效果 -->
      <!-- <el-tooltip placement="top"
                  :disabled="$utils.getTextWidth(scope.row[columnData.prop], 14)<columnData.width"
                  :content="mShowTooltip(scope)">
        <div class="follow-record"
             v-if="!scope.row.isEmpertyRow">
          <span class="follow_time_tips">{{scope.row[columnData.extend.FollowTime]?$utils.StrToRelativeTime(scope.row[columnData.extend.FollowTime])+'：':''}}</span>
          <span class="follow_content">{{scope.row[columnData.prop]}}</span>
        </div>
      </el-tooltip> -->

      <el-popover trigger="hover"
                  v-if="!scope.row.isEmpertyRow"
                  placement="top">
        <p class="titlep">
          <span class="namesd">{{ scope.row.MainDemoName }}</span>
          <span :class="[columnData.extend && typeof columnData.extend.click == 'function'?'font_blue button':'',columnData.extend&&typeof(columnData.extend.setClassName)=='function'&&columnData.extend.setClassName(scope.row)]"
                @click="clickEvent(scope.row)">详情</span>
        </p>

        <p class="linetext">校区ID: {{ scope.row.SaaSClientNO }}</p>
        <p class="linetext">管理员手机: {{ scope.row.ContactPhone }}</p>
        <div slot="reference"
             class="name-wrapper follow-record">
          <span :class="[columnData.extend && typeof columnData.extend.click == 'function'?'font_blue button':'',vClassName,columnData.extend&&typeof(columnData.extend.setClassName)=='function'&&columnData.extend.setClassName(scope.row)]">{{ scope.row.MainDemoName }}</span>
        </div>
      </el-popover>

    </template>
  </el-table-column>
</template>

<script>
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String
        };
      }
    }
  },
  data () {
    return {
    };
  },
  computed: {
    vClassName () {
      let className = '';
      if (this.columnData.extend && this.columnData.extend.className) {
        className = this.columnData.extend.className;
      }
      return className;
    },
    vShowOverflowTooltip () {
      if (typeof this.columnData.showOverflowTooltip == 'boolean') {
        return this.columnData.showOverflowTooltip;
      } else {
        return true;
      }
    }
  },
  created () {
  },
  methods: {
    clickEvent (rowData) {
      // 表示是否支持点击
      if (this.columnData.extend && typeof this.columnData.extend.click == 'function') {
        // 普通点击事件 直接执行回调
        if (this.hasModulePower(Number(this.columnData.extend.ModulePowerKey))) {
          this.columnData.extend.click(rowData);
        } else {
          this.$utils.CheckModulePowerToTips(this.columnData.extend.ModulePowerKey);
        }
      }
    },
    // 判断权限,
    hasModulePower (ModulePowerKey) {
      ModulePowerKey = ModulePowerKey || '';
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    }
  }
};
</script>

<style scoped>
.follow-record {
  max-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: normal;
}
.follow_time_tips {
  color: #999999;
  max-width: 105px;
}
.namesd {
  font-weight: 600;
  font-size: 16px;
}
.titlep {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}
.linetext {
  /* line-height: 20px; */
  margin-top: 5px;
}
</style>