<template>
  <div>
    <module-description :textTips="'退课的款项系统会自动计算，也可手动修改。'"
                        :btnTips="'查看教程'"
                        :btnUrl="'https://mp.weixin.qq.com/s/nQH5hSACXXUfDRiJCOavJg'"></module-description>
    <!-- 搜索学生弹框 -->
    <div v-if="searchBoxShow">
      <searchBox :searchInitJson="newScheduleCourseInitJson"
                 @searchClick="chooseClick"
                 @onSelectedStudent="selectedItem"></searchBox>
    </div>
    <!-- 学生列表弹框 (CustStatusKey) 只显示在校学生-->
    <div v-else-if="studentListShow">
      <student-list :CustStatusKey="1"
                    :isShowAddBtn="false"
                    :isShowaddOldStudentBtn="true"
                    :singleSelectionDatasource="classList"
                    :isShowSearchInput="isShowSearchInput"
                    @selectedItem="selectedItem"></student-list>
    </div>
    <!-- 学生退课信息 -->
    <div v-else-if="isStudentChangeClassShow">
      <quitCourseList @changeStudent="changeStudent"
                      ref="changeCourse"
                      @showStudentFile="showStudentFile"
                      :studentItem="selectedStudentIdItem"
                      :isReadonly="readonly"
                      :courseIndex="changeCourseIndex"
                      :allStudentCourseList="allStudentCourseList"
                      :allIncidentalsList="allStudentIncidentalsList"
                      :tableId="externalCourseKey"
                      @close="isrefundMoneyForBackCourseHide"
                      @clickRefundMethod="clickRefundMethod"
                      @updateCourseEvent="doUpdateCourseEvent"
                      @alterStudent="selectedItem"></quitCourseList>
    </div>
  </div>
</template>
<script>
import searchBox from '../../../../components/common/search-box';
import studentList from '../../../../components/common/student-list';
import quitCourseList from './quit-course-list';
import chagneRefundItem from './chagne-refund-item';
import {
  getStudentAllCourse,
  GetStudentAllMisChargesTicket
} from '../../../../API/workbench.js';
// 退 课 退 款
export default {
  name: 'studentChangeClass',
  data () {
    return {
      searchBoxShow: true, // 搜索框
      // 学生选择列表
      studentListShow: false,
      // 选择学生信息
      isStudentChangeClassShow: false,
      //  搜索目标
      newScheduleCourseInitJson: {
        name: '目标学生'
      },
      // 学生列表弹框
      classList: [],
      isShowSearchInput: false,
      selectedStudentIdItem: {},
      allStudentCourseList: [], // 学员 ID获取所有课单
      allStudentIncidentalsList: [], // 学员 ID获取所有杂费
      refunDeventShow: '',
      changeCourseIndex: 0,
      studentKey: 0, // 学生Key
      externalCourseKey: 0, // 外部传入的课单 key
      // incomePayAccountList: [],
      selectMode: false,
      readonly: false
    };
  },
  props: {
    dataInfo: Object,
    isrefundMoneyForBackCourse: {
      type: Boolean,
      default: false
    },
    callBackFunction: Function
  },
  components: {
    searchBox, // 搜索学生
    studentList, // 学生列表
    quitCourseList, // 学生个人信息
    chagneRefundItem
  },
  created () {
    console.log(this.dataInfo, '课时退款');
    if (this.dataInfo && this.dataInfo.readonly) {
      this.readonly = this.dataInfo.readonly;
    }
    if (this.dataInfo && Number(this.dataInfo.courseDetailItem.TableID) > 0 && Number(this.dataInfo.studentInfo.OLAPKey) > 0) {
      this.searchBoxShow = false;
      this.show(this.dataInfo.studentInfo, this.dataInfo.courseDetailItem);
    }
  },
  watch: {

  },
  methods: {
    doUpdateCourseEvent () {
      if (typeof this.callBackFunction == 'function') {
        this.callBackFunction();
      }
      this.$emit('updateCourseEvent');
    },
    clickRefundMethod (boll) {
      console.log('boll', boll);
      this.selectMode = boll;
    },
    // 点击搜索 搜索框可见 并查询数据
    chooseClick () {
      this.searchBoxShow = false;
      this.studentListShow = true;
      this.$emit('closeSearchBoxShow');
    },
    // 显示学员档案.
    showStudentFile () {
      this.$emit('showStudentFile', this.selectedStudentIdItem);
    },
    // 学生选择项
    selectedItem (item) {
      console.log(item, '学生选择项123');
      this.selectedStudentIdItem = item;
      this.studentListShow = false;
      this.isStudentChangeClassShow = true;
      this.searchBoxShow = false;
      this.allStudentIncidentalsList = [];
      this.allStudentCourseList = [];
      this.getStudentAllCourse();
    },
    // 关闭弹窗
    isrefundMoneyForBackCourseHide () {
      this.$parent.isrefundMoneyForBackCourse = false;
      this.searchBoxShow = false;
      this.studentListShow = false;
      this.isStudentChangeClassShow = false;
      this.$emit('closeRefundMoneyBackCourseDialog');
      this.allStudentIncidentalsList = [];
      this.allStudentCourseList = [];
      this.$emit('finishFunction');
    },
    // 打开
    show (targetStudentItem, targetCourseItem) {
      this.externalCourseKey = targetCourseItem.TableID; // 课单Key
      this.selectedStudentIdItem.OLAPKey = targetStudentItem.OLAPKey; // 学员key
      this.selectedItem(targetStudentItem);
    },
    getStudentAllCourse () {
      if (this.selectedStudentIdItem.OLAPKey) {
      // 学员 ID获取所有课单.
        getStudentAllCourse(this.selectedStudentIdItem.OLAPKey).then(result => {
          this.$refs.changeCourse.DATA.RefundMoney = 0;
          this.$refs.changeCourse.courseAmount = 0;
          this.$refs.changeCourse.incidentalsAmount = 0;
          this.$refs.changeCourse.DATA.CourseTicketList = [];
          this.$refs.changeCourse.DATA.MiscellaneousFeeList = [];
          if (result.data.length > 0) {
            var presentDate = this.$utils.formatDateToLine(Date.new()); // 当天日期 p.CurrentCount > 0
          // 赋值-倒序
            result.data.sort(function (a, b) {
              return (
              b.StartTime.replace(/-/g, '') - a.StartTime.replace(/-/g, '')
              );
            });
            result.data.forEach(p => {
            // 排出掉已失效的课单
              if (
              p.StatusKey == 2 &&
              p.ExpireTime >= presentDate &&
              Number(p.CurrentCount) > 0
            ) {
                this.allStudentCourseList.push(p);
              }
            // 显示高亮和位置
              if (this.externalCourseKey == p.TableID) {
                this.$set(p, 'isActive', true);
                this.allStudentCourseList.forEach((i, index) => {
                  this.changeCourseIndex = index + '-' + Date.new().getTime();
                });
                this.$nextTick(() => {
                  if (this.$refs.changeCourse) {
                    this.$refs.changeCourse.externalOptCourseItem(p);
                  }
                });
              } else {
                console.log('显示高亮和位置-123');
                this.$set(p, 'isActive', false);
              }
            });
          }
        // 学员ID获取所有 杂费 接口异常
          GetStudentAllMisChargesTicket(this.selectedStudentIdItem.OLAPKey).then(result => {
          // this.allStudentIncidentalsList = [];
            if (result.data.length > 0) {
              console.log(result.data, '学员杂费');
              if (this.allStudentCourseList.length == 0 && result.data.length == 1) { // 选中杂费
                this.$set(result.data[0], 'isActive', true);
                this.$nextTick(() => {
                  if (this.$refs.changeCourse) {
                    this.$refs.changeCourse.externalOptCourseItem(result.data[0], 'Zf');
                  }
                });
              } else {
                result.data.forEach(o => {
                  this.$set(o, 'isActive', false);
                });
              }
              this.allStudentIncidentalsList = result.data;
            // 赋值-倒序
              this.allStudentIncidentalsList.sort(function (a, b) {
                return (
                b.StartTime.replace(/-/g, '') - a.StartTime.replace(/-/g, '')
                );
              });
            }
            if (this.allStudentIncidentalsList.length == 0 && this.allStudentCourseList.length == 1) { // 选中课单
              this.$set(this.allStudentCourseList[0], 'isActive', true);
              this.$nextTick(() => {
                if (this.$refs.changeCourse) {
                  this.$refs.changeCourse.externalOptCourseItem(this.allStudentCourseList[0]);
                }
              });
              console.log(this.allStudentCourseList, '学员课单3');
            }
          });
        });
      }
    },
    // 切换学生
    changeStudent () {
      console.log('切换学生');
      this.studentListShow = true;
      this.isStudentChangeClassShow = false;
    }
  }
};
</script>

