<template>
  <div class="growth_time_left">
    <!-- 营业收入统计现有模块 -->
    <div class="payment_statistics_bottom_select ">
      <seleted-option :searchObj="searchObj"
                      @changeSearchObj="search"></seleted-option>
    </div>
    <!-- 营业收入统计现有模块 -->
    <div class="course_statistics_table_search flex course_statistics_title">
      <div class="">
        <div class="">
          <date-range ref="monthPicker"
                      :fromTitle="'发布时间'"
                      :searchObj="searchObj"
                      @changeDate="changeSaveDate"></date-range>
        </div>
      </div>
      <div class="course_statistics_search">
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入姓名、姓名首字母、手机号'"
                                  :clearAll="false"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
      </div>
    </div>
    <table-list class="growth_time_left_table"
                ref="table"
                :tableData="vdataReportList"
                :tableColumns="tableCourseColumns"
                :totalNum="totalNum"
                :PageCount="PageCount"
                :queryParams="searchObj"
                :footerContent="vFooterTotal"
                :showPagePagination="false"
                @clickRow="seletedItem"
                @loadTableData="initDataList"></table-list>

  </div>
</template>
<script>
import tableList from '../common/table-list/index';
import seletedOption from './components/seleted-option';
import inputSearchContainBtn from '../report/common/input-search-contain-btn';
import { growthTimeMessageSearchStudent } from '../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0, //	整型	必须		数据源：GET	页码，0为第一页
        pageSize: 20, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: '', //	字符串	可选		数据源：GET	排序字段
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
        startTime: '',
        endTime: '',
        CourseKey: 0,
        CourseKeyValue: '',
        MaxHour: '',
        MaxHourValue: '不限'
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      dataReportList: [],
      noMore: false, // 判断是否没有数据了.
      isLoadEnd: false, // 判断是否加载完.
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 48,
          sortable: false,
          align: 'left',
          type: 'text-item'
        },
        {
          label: '姓名',
          prop: 'MainDemoName',
          sortable: false,
          align: 'left',
          className: 'font_blue',
          type: 'student',
          extend: {
            studentKey: 'OLAPKey', // 学生ID
            showWechat: true,
            setClassName: (rowData) => {
              return Number(rowData.SubscribeKey) == 1 ? 'subscribe_icon' : '';
            },
            // phone: 'CustomerPhoneName',
            click: () => { }
          }

        },
        {
          label: '入学日期',
          prop: 'RegisterTime',
          minWidth: 120,
          sortable: 'custom',
          align: 'left',
          type: 'dateItem',
          extend: {
            showTimeAgo: true
          }

        },
        {
          label: '签到',
          prop: 'SignCount',
          minWidth: 48,
          sortable: false,
          align: 'center',
          type: 'textItem'

        },
        {
          label: '点评',
          prop: 'CommentCount',
          minWidth: 48,
          sortable: false,
          align: 'center',
          type: 'textItem'

        },
        {
          label: '作业',
          prop: 'WorkCount',
          minWidth: 48,
          sortable: false,
          align: 'center',
          type: 'textItem'

        },
        {
          label: '成长报告',
          prop: 'GrowCount',
          minWidth: 78,
          sortable: false,
          align: 'center',
          type: 'textItem'

        },
        {
          label: '上次发布时间',
          prop: 'LastPushTime',
          minWidth: 115,
          sortable: 'custom',
          align: 'left',
          type: 'textItem',
          extend: {
            setClassName: (rowData) => {
              return rowData.isActive ? 'tick_type_01' : '';
            }
          }

        }

      ],
      studentInfo: null
    };
  },
  components: {
    seletedOption, inputSearchContainBtn, tableList
  },
  props: {

  },
  created () {

  },
  mounted () {
    // 监听table 滚动条
    let tableBodyDom = this.$refs.table.$refs.multipleTable.bodyWrapper;
    console.log(this.$refs.table.multipleTable, tableBodyDom);
    tableBodyDom.addEventListener('scroll', () => {
      // 滚动条总高度 减去 窗口高度 减去 已卷入的高度 小于 50px 就加载新数据
      console.log('滚动', tableBodyDom.scrollHeight, tableBodyDom.clientHeight, tableBodyDom.scrollTop);
      if ((tableBodyDom.scrollHeight - tableBodyDom.clientHeight - tableBodyDom.scrollTop) < 50) {
        // 一定要给事件一个节流限制。不然会执行很多次
        if (!this.noMore && !this.isLoadEnd) {
          // this.pageLimit.page++;
          this.getDataList();
        }
      }
    });
    this.getDataList();
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    }
  },
  methods: {
    getDataList () {
      this.isLoadEnd = true;
      growthTimeMessageSearchStudent(this.searchObj).then(result => {
        console.log(result.data, 'growthTimeMessageSearchStudent');
        // this.dataReportList = result.data.PageDataList;;
        result.data.PageDataList.forEach(o => {
          o.isActive = false;
          this.dataReportList.push(o);
        });
        this.totalNum = result.data.Total;
        if (result.data.PageDataList.length == this.searchObj.pageSize) {
          this.searchObj.pageIndex++;
        } else {
          this.noMore = true;
        }
        this.isLoadEnd = false;
      }).catch(error => {
        console.log(error, 'errorGrowthTimeMessageSearchStudent');
      });
    },
    initDataList () {
      this.isLoadEnd = true;
      this.dataReportList = [];
      this.searchObj.pageIndex = 0;
      this.noMore = false;
      this.$emit('searchData');
      growthTimeMessageSearchStudent(this.searchObj).then(result => {
        console.log(result.data, 'growthTimeMessageSearchStudent');
        result.data.PageDataList.forEach(o => {
          o.isActive = false;
          // this.dataReportList.push(o);
        });
        this.dataReportList = result.data.PageDataList;
        this.totalNum = result.data.Total;
        if (result.data.PageDataList.length == this.searchObj.pageSize) {
          this.searchObj.pageIndex++;
        } else {
          this.noMore = true;
        }
        this.isLoadEnd = false;
      }).catch(error => {
        console.log(error, 'errorGrowthTimeMessageSearchStudent');
      });
    },
    search () {
      this.dataReportList = [];
      this.searchObj.pageIndex = 0;
      this.noMore = false;
      this.$emit('searchData');
      this.getDataList();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.CourseKey = '';
      this.searchObj.CourseKeyValue = '全部';
      this.searchObj.MaxHour = '';
      this.searchObj.MaxHour = '不限';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.dataReportList = [];
      this.getDataList();
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.search();
    },
    // 选中行
    seletedItem (item) {
      if (!item.OLAPKey) {
        return false;
      }
      this.dataReportList.forEach(o => {
        if (o.OLAPKey == item.OLAPKey) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      this.studentInfo = item;
      this.$emit('seletedItem', item);
      console.log('seletedItem', item);
    }
  }

};
</script>
