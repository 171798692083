<template>
  <div>
    <div class="form_info_edit form_info_list"
         style="height: 475px">
      <input-text :formTitle="'校区名称'"
                  :required="false"
                  :readonly="true"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.CampusKeyValue"></input-text>

      <input-date :value="dataInfo.ApplyTime"
                  :minDate="$utils.formatDateToLine(Date.new())"
                  :formTitle="'申请日期'"
                  :readonly="true"
                  :elementId="'BirthdayTime'"
                  :required="false"
                  :formPlaceholder="'选填'"></input-date>

      <input-text :formTitle="'经办人'"
                  :required="false"
                  :readonly="true"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.CreateKeyValue"></input-text>

      <input-number :min="0"
                  :max="100"
                    :formTitle="'续期时长（年）'"
                    :formPlaceholder="'请输入'"
                    :decimalPlaces="0"
                    :required="true"
                    :readonly="isReadonly"
                    v-model="dataInfo.PortalNum"></input-number>

      <input-number :min="0"
                    :formTitle="'扣除端口数（个）'"
                    :decimalPlaces="0"
                    :required="false"
                    :readonly="true"
                    :className="'font_black'"
                    v-model="dataInfo.PortalNum"></input-number>

    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { CampusManagementRenew } from '../../../../../API/workbench';
export default {
  data () {
    return {
      // 直营类型
      sourceType: [
        { type: 1, name: '参股直营' },
        { type: 2, name: '直属直营' },
        { type: 3, name: '课件加盟' },
        { type: 4, name: '整店加盟' }
      ],
      locationInfo: [], // 地址
      dataInfo: {}
    };
  },
  components: {

  },
  props: {
    campusInfo: Object,
    isReadonly: Boolean
  },
  created () {
    // console.log(this.$store.getters, 'initAddData');
    this.initData();
    console.log(this.CampusKey, 'CampusKey555555');
  },
  computed: {

  },
  methods: {
    initData () {
      this.dataInfo = {
        CampusKey: this.campusInfo.OLAPKey, // 校区
        CampusKeyValue: this.campusInfo.MainDemoName, // 校区
        ApplyTime: this.$utils.formatDateToLine(Date.new()), // 申请日期
        CreateKey: this.campusInfo.OperatorKey, // 经办人
        CreateKeyValue: this.campusInfo.OperatorKeyValue, // 经办人
        PortalNum: 1 // 端口数
      };
    },
    // 提交
    submit (callback) {
      console.log('submit', this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      CampusManagementRenew(this.dataInfo).then(result => {
        layer.alert('续期成功');
        this.$emit('afterSuccess');
        this.$bus.emit('updateCampusCourseware', { DataKey: this.dataInfo.CampusKey });
        this.cancel();
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callback) {
          callback();
        }
      });
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
       this.dataInfo.PortalNum=parseInt(this.dataInfo.PortalNum)
      if (!this.dataInfo.PortalNum) {
        layer.alert('请输入开通时长（年）');
        flag = false;
      }
      return flag;
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
