<template>
  <div class="article_details_text" v-html="$utils.translationSchoolText(dataInfo.Content)" >
    <!-- {{dataInfo.Content}} -->
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object,
  },
  computed: {

  },
  methods: {
    // copy (value) {
    //   //提示模板
    //   uni.showModal({
    //     content: value,//模板中提示的内容
    //     confirmText: '复制内容',
    //     success: () => {//点击复制内容的后调函数
    //       //uni.setClipboardData方法就是讲内容复制到粘贴板
    //       uni.setClipboardData({
    //         data: value,//要被复制的内容
    //         success: () => {//复制成功的回调函数
    //           uni.showToast({//提示
    //             title: '复制成功'
    //           })
    //         }
    //       });
    //     }
    //   });
    // }
  }
};
</script>

<style>
</style>
