<template>
     <div>
        <div class="form_info_edit form_info_list">
           <input-dialog :formTitle="'选择员工'"
                    :required="true"
                    :readonly="false"
                    :formPlaceholder="'请选择'"
                    :value="dataJson.MainDemoName"
                    @showEldilog="showSaleDialog"></input-dialog>
        </div>
         <save-cancel-btn-group :isSingleSubmit="true"
                                 :singleSubmitType="'dynamic'"
                                 @confirmClick="confirmClick"
                                 @cancelClick="cancelClick"></save-cancel-btn-group>
     </div>
</template>
<script>
import { getEnteringStaff,AddTheacherAdiser} from "../../../../API/workbench";
import formSelect from '../../../form-item/form-select';
export default {
    data(){
        return{
            // 提交数据对象
            dataJson: {
                MainDemoName: '', // "投资人姓名",
                OLAPKey:''
            },
            dataList:[]
      
        }
    },
    components:{
        formSelect,
    },
    props:{

        isReadonly:Boolean,
      
    },
    created(){
       this.getSaleList()
    },
    computed:{
    },
    methods:{
        showSaleDialog () {
            this.$dialog.open(this, {
                routerName: this.$route.name,
                name: '选择员工',
                moduleName: 'singleSelectionList',
                data: { selectedKey: this.dataJson.OLAPKey, dataSourceList: this.dataList, required: true, addModuleName: '' },
                // addModuleTitleName: '添加老师', ModulePowerKey: 44
                callBack: {
                    onSelectedItem: (data) => { this.doAfterSeletedItem(data); }
                }
            });
        },
        getSaleList () {
            getEnteringStaff().then(result => {
                this.dataList = result.data.filter(o=>{
                    return o.IsTeacherAdviserKey!=1
                });
            });
        },
        doAfterSeletedItem (item) {
            this.dataJson.OLAPKey = item.OLAPKey;
            this.dataJson.MainDemoName = item.MainDemoName;
        },
        verifyData(){
            let isFail=false
               console.log("verifyData",this.dataJson)
            if(!this.dataJson.MainDemoName){
                isFail=true
                layer.alert("请选择员工")
            }
            return isFail
        },   
        confirmClick(callBack){
            if(this.verifyData()){
                if(callBack){
                    callBack()
                }
                return false
            }
                        //新增
                AddTheacherAdiser(this.dataJson).then(result=>{
                    layer.alert("新增成功")
                    this.$emit("afterSuccess",result.data)
                    this.$emit("cancelClick")
                }).catch(error=>{
                      layer.alert(error.msg)
                    if(callBack){
                    callBack()
                }
                    console.log("errorCWSAddTeacher",error.msg)
                })
            

        },
        cancelClick(){
            this.$emit("cancelClick")
        }
    }
}
</script>