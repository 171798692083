<template>
  <div class="detail">
    <div class="name"><span>{{dataDetail.name}}</span></div>
    <div class="remark">班内学生 <span>{{dataDetail.studentNum}}</span>　|　周排课次 <span>{{dataDetail.courseNum}}</span></div>
    <div class="formbox">
      <div class="text">选择日期</div>
      <el-select :value="search.TimeTypeNum" @change="selectDateChange" style="width:150px;">
        <el-option :label="'近30天内'" :value="30"></el-option>
        <el-option :label="'近60天内'" :value="60"></el-option>
        <el-option :label="'近90天内'" :value="90"></el-option>
      </el-select>
      <div class="text">循环课次</div>
      <el-select :value="dataDetail.courseNow" @change="selectClassChange">
        <el-option :label="'全部'" :value="''"></el-option>
        <el-option v-for="(item,index) in dataDetail.courseList" :key="index" :label="item.WeekKeyValue+' | '+item.ClassTimeName+' | '+item.CourseNameKeyValue+' | '+item.MTeacherKeyValue" :value="item.WeekKey + ',' + item.MTeacherKey + ',' + item.CourseNameKey + ',' + item.ClassTimeName"></el-option>
      </el-select>
      <a class="btn btn_1" @click="searchClick">搜索</a>
      <a class="btn btn_2" @click="resetClick">清除条件</a>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list class="summarizing_list" :tableData="tableData" :totalNum="totalNum" :PageCount="PageCount" :tableColumns="tableColumns" :queryParams="search" :footerContent="vFooterContent" @loadTableData="getList"></table-list>
    </div>
  </div>
</template>

<script>
import {GetPotentialGradeInfo,GetGradeAttenceStudentDataList} from '../../../API/workbench';
import tableList from '../../common/table-list/index';
export default {
  data () {
    return {
      dataDetail:{
        name:'',
        studentNum:'',
        courseNum:'',
        courseList:[],
        courseNow:''
      },
      search:{
        GradeClassKey:this.classkeys.key,
        TimeTypeNum:30,
        WeekKey:'',
        MTeacherKey:'',
        CourseNameKey:'',
        ClassTimeName:'',
        pageIndex:0,
        pageSize:10
      },
      tableData:[],
      totalNum:0,
      PageCount:0,
      tableColumns:[
        {
          label: '序号',
          prop: 'Number',
          width: 80,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '学生',
          prop: 'StudentNameKeyValue',
          width: 160,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '排课',
          prop: 'ArrangeCourseCount',
          width: 160,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '出勤',
          prop: 'Attence3Count',
          width: 160,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '出勤率',
          prop: 'Attence',
          width: 160,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '排课备注',
          prop: 'ArrangeSupport',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        }
      ]
    };
  },
  components: {
    tableList
  },
  props: {
    classkeys: Object
  },
  created () {
    this.search.TimeTypeNum = this.classkeys.grade;
    if (this.classkeys.childKeys) {
      let keys = this.classkeys.childKeys.split(',');
      this.dataDetail.courseNow = this.classkeys.childKeys;
      this.search.WeekKey = parseInt(keys[0]);
      this.search.MTeacherKey = parseInt(keys[1]);
      this.search.CourseNameKey = parseInt(keys[2]);
      this.search.ClassTimeName = keys[3];
    }
    this.getDetail();
  },
  mounted () {
    this.getList();
  },
  computed: {
    vFooterContent () {
      return '共 ' + this.totalNum + ' 条数据';
    }
  },
  methods: {
    getDetail () {
      GetPotentialGradeInfo(this.classkeys.key,this.search.TimeTypeNum).then(result => {
        if (result.code == 0) {
          this.dataDetail.name = result.data.MainDemoName;
          this.dataDetail.studentNum = result.data.CurrentPeoCount;
          this.dataDetail.courseNum = result.data.ArrangeCourseCount;
          this.dataDetail.courseList = result.data.ScheduleCourse;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    selectDateChange (val) {
      this.search.TimeTypeNum = val;
      this.search.WeekKey = '';
      this.search.MTeacherKey = '';
      this.search.CourseNameKey = '';
      this.search.ClassTimeName = '';
      this.dataDetail.courseNow = '';
      this.getDetail();
      this.search.pageIndex = 0;
      this.getList();
    },
    selectClassChange (val) {
      if (val) {
        let keys = val.split(',');
        this.search.WeekKey = parseInt(keys[0]);
        this.search.MTeacherKey = parseInt(keys[1]);
        this.search.CourseNameKey = parseInt(keys[2]);
        this.search.ClassTimeName = keys[3];
      } else {
        this.search.WeekKey = '';
        this.search.MTeacherKey = '';
        this.search.CourseNameKey = '';
        this.search.ClassTimeName = '';
      }
      this.dataDetail.courseNow = val;
      this.search.pageIndex = 0;
      this.getList();
    },
    searchClick () {
      this.search.pageIndex = 0;
      this.getList();
    },
    resetClick () {
      this.search.TimeTypeNum = this.classkeys.grade;
      this.search.WeekKey = '';
      this.search.MTeacherKey = '';
      this.search.CourseNameKey = '';
      this.search.ClassTimeName = '';
      this.dataDetail.courseNow = '';
      this.search.pageIndex = 0;
      this.getList();
    },
    getList () {
      let that = this;
      GetGradeAttenceStudentDataList(that.search).then(result => {
        if (result.code == 0) {
          let list = result.data.PageDataList;
          for (let i = 0; i < list.length; i++) {
            list[i].Attence = list[i].Attence + '%';
          }
          that.totalNum = result.data.Total;
          that.PageCount = result.data.PageCount;
          that.tableData = list;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
};
</script>

<style scoped>
.detail{padding:20px;}
.detail .name{line-height:30px;font-size:12px;}
.detail .name span{font-size:18px;font-weight:bold;margin-right:10px;}
.detail .remark{line-height:30px;font-size:14px;color:#999999;}
.detail .remark span{color:#333333;}
.detail .formbox{padding:10px 0;}
.detail .formbox .text{display:inline-block;height:36px;line-height:36px;font-size:14px;color:#666666;margin-right:10px;}
.detail .formbox .el-select{display:inline-block;width:400px;height:36px;margin-right:20px;}
.detail .formbox .el-select >>> input.el-input__inner{border:1px solid #ececec;}
.el-select-dropdown__item{max-width:400px;}
.el-select-dropdown__item >>> span{width:360px;}
.detail .formbox .btn{display:inline-block;height:36px;line-height:36px;font-size:14px;border-radius:4px;box-sizing:border-box;margin-right:10px;cursor:pointer;}
.detail .formbox .btn.btn_1{color:#FFFFFF;background-color:#2D98D9;border:1px solid #2886BF;padding:0 20px;}
.detail .formbox .btn.btn_2{color:#333333;background-color:#FFFFFF;border:1px solid #ECECEC;padding:0 10px;}
.detail .course_statistics_table_box{padding:0;}
</style>