<template>
  <div class="course_statistics_table_search">
    <div class="query_box_content"
         v-show="!isBatchDistribute">
      <div class="query_box_first_line">
        <div class="search_item">
          <el-select-option :selectTitle="'来源方式'"
                            :dataSourceList="sourceTypeList"
                            :selectionKey="paramsForQuery.SourceTypeKey"
                            @changeEvent="chooseSourceType"></el-select-option>
        </div>
        <div class="search_item">
          <el-select-option :selectTitle="'签单进度'"
                            :dataSourceList="statusList"
                            :selectionKey="paramsForQuery.StatusKey"
                            @changeEvent="chooseStatus"></el-select-option>
        </div>
        <div class="search_item">
          <el-select-option :selectTitle="'放弃人'"
                            :dataSourceList="counselorList"
                            :selectionKey="paramsForQuery.AbandonKey"
                            @changeEvent="chooseCounselor"></el-select-option>
        </div>
        <div class="search_item">
          <el-select-option :selectTitle="'意向级别'"
                            :dataSourceList="intentionList"
                            :selectionKey="paramsForQuery.IntentionKey"
                            @changeEvent="chooseIntention"></el-select-option>
        </div>
      </div>
      <div class="query_box_second_line">
        <div class="search_item">
          <input-search-contain-btn v-model="paramsForQuery.searchText"
                                    :placeholder="'请输入'"
                                    @onSearchEvent="searchClick"
                                    @clearSearch="clearSearchParams"></input-search-contain-btn>
        </div>
        <div class="option_btn">
          <div class="import_investor btn_hover_bg_blue"
               @click="exportClick">导出</div>
          <div class="distribute_advisor btn_hover_bg_blue"
               @click="distributeClick">分配</div>
        </div>
      </div>
    </div>
    <div v-if="isBatchDistribute"
         class="batch_option_box flex">
      <div class="pitch_count pr">已选中 {{batchAllotList.length}} 项
        <div class="remove_db_btn"
             @click="cancleBatchDistribute"></div>
      </div>
      <div class="btn_hover_bg_blue batch_btn"
           @click="doBatchAllot">批量分配</div>
    </div>
  </div>
</template>

<script>
import elSelectOption from '../../../../report/common/el-select-option'; // 下拉选择框
import inputSearchContainBtn from '../../../../report/common/input-search-contain-btn';
import {
  GetCounselorList,
  hqAttractInvestmentSourceSet,
  SearchPageHaveToGiveUpInvestment
} from '../../../../../API/workbench';
export default {
  props: {
    paramsForQuery: {
      // 查询参数
      type: Object
    },
    batchAllotList: {
      // 批量选中的投资人数
      type: Array
    },
    isBatchDistribute: Boolean, // 是否显示查询条件
    tableData: Array
  },
  data () {
    return {
      counselorList: [], // 投资顾问列表
      intentionList: [
        // 意向级别1-S;2-A;3-B;4-C
        { OLAPKey: 0, MainDemoName: '全部' },
        { OLAPKey: 1, MainDemoName: 'S' },
        { OLAPKey: 2, MainDemoName: 'A' },
        { OLAPKey: 3, MainDemoName: 'B' },
        { OLAPKey: 4, MainDemoName: 'C' }
      ],
      sourceTypeList: [], // 来源列表,
      statusList: [
        // 签单进度0-待接收;1-新线索;2-跟进中;3-已预约;4-已考察;5-已签单;6-已付订;7-已全款;-1-已拒绝
        { OLAPKey: '', MainDemoName: '全部' },
        { OLAPKey: 0, MainDemoName: '待接收' },
        { OLAPKey: 1, MainDemoName: '新线索' },
        { OLAPKey: 2, MainDemoName: '跟进中' },
        { OLAPKey: 3, MainDemoName: '已预约' },
        { OLAPKey: 4, MainDemoName: '已考察' },
        { OLAPKey: 5, MainDemoName: '已签单' },
        { OLAPKey: 6, MainDemoName: '已付订' },
        { OLAPKey: 7, MainDemoName: '已全款' },
        { OLAPKey: -1, MainDemoName: '已拒绝' }
      ]
    };
  },
  components: {
    elSelectOption,
    inputSearchContainBtn
  },
  computed: {
    vSearchDataRange () {
      return {
        startTime: this.paramsForQuery.entryTimeStart,
        endTime: this.paramsForQuery.entryTimeEnd
      };
    }
  },
  created () {
    this.loadAdvisorList();
    this.loadSourceType();
  },
  methods: {
    // 获取投资顾问列表
    loadAdvisorList () {
      GetCounselorList().then((result) => {
        this.counselorList = result.data;
        this.counselorList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
      });
    },
    // 查询所有招商来源设置-总部
    loadSourceType () {
      hqAttractInvestmentSourceSet().then(
        (result) => {
          this.sourceTypeList = result.data || [];
          this.sourceTypeList.unshift({
            MainDemoName: '全部',
            OLAPKey: 0
          });
          console.log(this.sourceTypeList, '查询所有招商来源设置');
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    // 选择放弃人
    chooseCounselor (item) {
      this.paramsForQuery.AbandonKey = item.OLAPKey;
      this.searchClick();
    },
    // 选择意向级别
    chooseIntention (item) {
      this.paramsForQuery.IntentionKey = item.OLAPKey;
      this.searchClick();
    },
    // 选择签单进度
    chooseStatus (item) {
      this.paramsForQuery.StatusKey = item.OLAPKey;
      this.searchClick();
    },
    // 选择来源方式
    chooseSourceType (item) {
      this.paramsForQuery.SourceTypeKey = item.OLAPKey;
      this.searchClick();
    },
    // 点击搜索
    searchClick () {
      this.paramsForQuery.pageIndex = 0;
      this.$emit('searchClick', this.paramsForQuery);
    },
    // 清除搜索条件
    clearSearchParams () {
      this.$emit('clearSearchParams');
    },
    // 分配投资人
    distributeClick () {
      this.$emit('enableBatchAllot');
    },
    // 取消批量分配
    cancleBatchDistribute () {
      this.$emit('cancleBatchDistribute');
    },
    // 批量分配
    doBatchAllot () {
      if (this.batchAllotList.length == 0) {
        layer.alert('请至少选择一个投资人');
        return;
      }
      this.$emit(
        'doBatchAllot',
        {
          OLAPKey: this.batchAllotList.map((item) => {
            return item.OLAPKey;
          })
        },
        'batch'
      );
    },
    // 导出
    exportClick () {
      console.log('导出');
      if (this.tableData.length > 0) {
        let obj = this.$utils.parseJson(this.paramsForQuery);
        obj.IsExportKey = 1;
        obj.pageIndex = 0;
        obj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '招商管理已放弃记录表',
          clist: [
            {
              title: '意向级别',
              cName: 'IntentionKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '姓名',
              cName: 'MainDemoName',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '手机号',
              cName: 'MobilePhone',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '城市省份',
              cName: 'ProvinceKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '计划城市',
              cName: 'CityKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '加盟方式',
              cName: 'FranchiseTypeKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '投资预算起',
              cName: 'StratBudgetAmount',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '投资预算止',
              cName: 'EndBudgetAmount',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '来源',
              cName: 'SourceTypeKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '签单进度',
              cName: 'StatusKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '放弃人',
              cName: 'AbandonKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '最近跟进时间',
              cName: 'FollowTime',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '跟进记录',
              cName: 'Content',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            }
          ]
        };
        SearchPageHaveToGiveUpInvestment(obj, jsonFormate).then(
          (result) => {
            console.log(result.data, '导出');
            window.location.href = this.$store.getters.APIURL + result.data.url;
          },
          (error) => {
            layer.alert(error.msg);
          }
        );
      } else {
        layer.alert('暂无数据导出');
      }
    }
  }
};
</script>

<style scoped>
.course_statistics_table_search {
  padding: 13px 0px;
}

.query_box_content {
  width: 100%;
}
.query_box_first_line {
  display: flex;
}
.query_box_second_line {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}
.course_statistics_table_search .search_item {
  margin-right: 20px;
}
.option_btn {
  display: flex;
}
.import_investor {
  height: 35px;
  width: 56px;
  line-height: 35px;
  text-align: center;
  align-items: center;
  border-radius: 4px;
}
.distribute_advisor {
  margin-left: 10px;
  height: 35px;
  line-height: 35px;
  width: 56px;
  text-align: center;
  align-items: center;
  border-radius: 4px;
}
.batch_option_box {
  height: 86px;
  display: flex;
  align-items: center;
}
.remove_db_btn {
  background-image: url(../../../../../../public/image/dialog_del_icon.png);
  position: absolute;
  right: -40px;
  top: 14%;
  margin-top: -8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 8px;
}
.batch_btn {
  margin-left: 60px;
  margin-top: -10px;
  height: 40px;
  width: 100px;
  line-height: 38px;
  text-align: center;
  border-radius: 4px;
  padding-left: 27px;
  padding-right: 7px;
  background: #3498db
    url(../../../../../../public/image/batch_distribution_icon.png) no-repeat
    8px;
}
</style>