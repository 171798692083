<template>
  <!-- 调研头部标题 -->
  <div class="form_list_subtitle">
    {{columnData.MainDemoName}}
  </div>
</template> 
<script>

export default {
  data () {
    return {

    };
  },
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          MainDemoName: String
        };
      }
    }
  },
  created () {
    // console.log('调研头部标题:', this.columnData);
  },
  computed: {

  },
  methods: {

  }
};
</script>

<style>
</style>