<template>
  <div class="manage_statistics_bottom_center massive_bg"
       style="    overflow: hidden;">
    <div class="general_summarizing_student">
      <div class="general_summarizing_student_navUl">
        <div class="title_list flex_1 "
             :class="type==1?'opt':''"
             @click.stop="changeType(1)">
          演示新单榜
        </div>
        <div class="title_list flex_1 "
             :class="type==2?'opt':''"
             @click.stop="changeType(2)">
          签新校区榜
        </div>
        <div class="title_list flex_1 "
             :class="type==3?'opt':''"
             @click.stop="changeType(3)">
          销售金额榜
        </div>
      </div>

    </div>
    <div class="payment_statistics_bottom clearfix">
      <div v-if="type==1">
        <demo-list></demo-list>
      </div>
      <div v-if="type==2">
        <sign-new-list></sign-new-list>
      </div>
      <div v-if="type==3">
        <sales-amount-list></sales-amount-list>
      </div>
    </div>
  </div>
</template>
<script>
import demoList from './demo-list';
import signNewList from './sign-new-list';
import salesAmountList from './sales-amount-list';
export default {
  data () {
    return {
      type: 1
    };
  },
  components: {
    demoList, signNewList, salesAmountList
  },
  created () {

  },
  methods: {
    changeType (type) {
      this.type = type;
    }
  }
};
</script>
