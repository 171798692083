var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand_info border_bottom pr"},[_vm._m(0),_c('div',{staticClass:"YK_added_content_form"},[_c('div',{staticClass:"form_list"},[_vm._m(1),_c('div',{staticClass:"form_list_content"},[_vm._v(" "+_vm._s(_vm.dataInfo.BrandName)+" ")])]),_c('div',{staticClass:"form_list"},[_vm._m(2),_c('div',{staticClass:"form_list_content"},[_vm._v(" "+_vm._s(_vm.vFranchiseType)+" ")])]),_c('div',{staticClass:"form_list"},[_vm._m(3),_c('div',{staticClass:"form_list_content"},[_vm._v(" 自营店："+_vm._s(Number(_vm.dataInfo.DirectSaleStoreCount)||0)+" "),_c('span',{staticStyle:{"padding":"0 8px"}},[_vm._v("|")]),_vm._v(" 整店加盟："+_vm._s(Number(_vm.dataInfo.WholeStoreCount)||0)+" "),_c('span',{staticStyle:{"padding":"0 8px"}},[_vm._v("|")]),_vm._v(" 课程加盟："+_vm._s(Number(_vm.dataInfo.CourseStoreCount)||0)+" "),_c('span',{staticStyle:{"padding":"0 8px"}},[_vm._v("|")]),_vm._v(" 门店总数："+_vm._s(Number(_vm.dataInfo.StoreCount)||0)+" ")])]),_c('div',{staticClass:"form_list"},[_vm._m(4),_c('div',{staticClass:"form_list_content"},[_vm._v(" 整店加盟："+_vm._s(Number(_vm.dataInfo.OneYearWholeStoreCount))+" ")])]),_c('div',{staticClass:"form_list"},[_vm._m(5),_c('div',{staticClass:"form_list_content"},[_vm._v(" 课程加盟："+_vm._s(Number(_vm.dataInfo.OneYearCourseStoreCount))+" ")])]),_c('div',{staticClass:"form_list"},[_vm._m(6),_c('div',{staticClass:"form_list_content"},[_c('div',{staticClass:"form_list_textarea"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataInfo.BrandIntroduction),expression:"dataInfo.BrandIntroduction"}],staticStyle:{"padding":"0"},attrs:{"readonly":""},domProps:{"value":(_vm.dataInfo.BrandIntroduction)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.dataInfo, "BrandIntroduction", $event.target.value)}}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"YK_added_content_name"},[_c('span',[_vm._v("品牌信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form_list_name"},[_c('span',{staticClass:"text_box"},[_vm._v("品牌名称")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form_list_name"},[_c('span',{staticClass:"text_box"},[_vm._v("加盟方式")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form_list_name"},[_c('span',{staticClass:"text_box"},[_vm._v("门店数量")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form_list_name"},[_c('span',{staticClass:"text_box"},[_vm._v("1年内加盟数")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form_list_name"},[_c('span',{staticClass:"text_box"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form_list_name"},[_c('span',{staticClass:"text_box"},[_vm._v("品牌简介")])])
}]

export { render, staticRenderFns }