<template>
  <!-- 推荐作品列表 -->
  <div style="height: 680px;">
    <div class="form_info_edit form_info_list border_bottom">
      <input-text :formTitle="'上传作品'"
                  :required="true"
                  :readonly="true"
                  :formPlaceholder="'请输入'"
                  class="no_border_bottom"></input-text>
      <div class="courseware_form_upload_box"
           :class="WorksUrl?'show_Img':''">
        <div class="courseware_form_upload_noImg"
             :class="WorksUrl?'isShowUpload':''"
             style="z-index: 1;">
          <div class="upload_icon">+</div>
          <div>上传图片</div>
        </div>
        <div id="screanContent"
             style="z-index:1;position: absolute !important;right: 45px; opacity: 0"></div>
        <input-upload-img :ImgURl="WorksUrl"
                          :formTitle="''"
                          :isStudentWork="isStudentWork"
                          :acceptType="'image/*'"
                          @doSetStudentWorks="doSetPicCorpHeadImg"></input-upload-img>
      </div>
      <input-text :formTitle="'学生姓名'"
                  :required="true"
                  :customPlaceholder="true"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.StudentName"></input-text>
      <input-text :formTitle="'指导老师'"
                  :required="false"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.GuidanceTeacherName"></input-text>

      <input-date v-model="dataInfo.CreationTime"
                  :formTitle="'创作日期'"
                  :readonly="false"
                  :required="true"
                  :formPlaceholder="'必填'"
                  @changeDate="changeDate"
                  class="create_date"></input-date>
      <input-select :formTitle="'推荐至'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    class="no_border_bottom"
                    :readonly="false"
                    :dataList="ObjectList"
                    :selectedInfo="vRecommendObject"
                    @selectedItem="changeRecommendObjectType"></input-select>
    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { AddRecommendWorks, GetRecommendClientList } from '../../API/workbench';
export default {
  data () {
    return {
      ObjectList: [
        { type: 1, name: '本校' }
      ],
      RecommendList: {
        type: 1,
        name: '本校'
      },
      isStudentWork: true,
      WorksUrl: '',
      dataInfo: {
        UploadWorksUrl: '', // 作品上传图片
        StudentName: '', //	学生名
        GuidanceTeacherName: '', // 指导老师
        CreationTime: this.$utils.formatDateToLine(new Date()), // 创作日期
        RecommendObject: this.$store.getters.token.SaaSClientKey //	推荐对象
      }
    };
  },
  components: {

  },
  props: {
  },
  created () {
    this.getGetRecommendClientList();
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
  },
  computed: {
    // 模式
    vTypeKey () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) { // 总部key为6
        return true;
      } else {
        return false;
      }
    },
    // 推荐对象
    vRecommendObject () {
      return {
        type: this.RecommendList.type,
        name: this.RecommendList.name
      };
    },
    vMenDianInfo () {
      return this.$store.getters.SaaSClientInfo.EditionKey;
    }
  },
  methods: {
    getGetRecommendClientList () {
      GetRecommendClientList().then(result => {
        result.data.forEach(o => {
          this.ObjectList.push({
            type: Number(o.OLAPKey),
            name: o.MainDemoName
          });
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    changeDate (dateTime) {
      console.log(dateTime, 'changeDate');
      this.dataInfo.CreationTime = dateTime;
    },
    // 选择推荐对象
    changeRecommendObjectType (obj) {
      console.log(obj, '选择结算方式');
      this.RecommendList.type = obj.type;
      this.RecommendList.name = obj.name;
      if (obj.type == 1) {
        this.dataInfo.RecommendObject = this.$store.getters.token.SaaSClientKey;
      } else {
        this.dataInfo.RecommendObject = obj.type;
      }
      console.log(this.dataInfo.RecommendObject, 'this.dataInfo.RecommendObject ');
    },
    // 上传作品地址
    doSetPicCorpHeadImg (Url) {
      console.log('上传作品地址', Url);
      this.WorksUrl = this.$store.getters.CDNURL + Url;
      this.dataInfo.UploadWorksUrl = Url;
    },
    // 提交
    submit (callback) {
      console.log('submit', this.dataInfo);
      console.log(this.vMenDianInfo, 'vMenDianInfo');
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      AddRecommendWorks(this.dataInfo).then(result => {
        layer.alert('新增成功');
        this.$emit('afterSuccess', result.data);
        this.cancel();
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callback) {
          callback();
        }
      });
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
      if (!this.dataInfo.StudentName) {
        layer.alert('请输入学生名');
        flag = false;
      } else if (!this.WorksUrl) {
        layer.alert('请选择作品上传');
        flag = false;
      } else if (!this.dataInfo.CreationTime) {
        layer.alert('请选择创建日期');
        flag = false;
      } else if (!this.dataInfo.RecommendObject) {
        layer.alert('请选择推荐对象');
        flag = false;
      }
      return flag;
    },
    cancel () {
      this.$emit('afterSuccess');
    }
  }
};
</script>
<style scoped>
.create_date {
  cursor: pointer !important;
}
.create_date >>> .form_info_select .el-input__inner {
  cursor: pointer !important;
}
.isShowUpload {
  opacity: 0;
}
</style>
