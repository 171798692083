<template>
  <div class="recording_data_tr">
    <div class="recording_data_td"
         style="width: 150px">
      <div>{{vDate}}</div>
      <!-- <div>{{vHour}}</div> -->
    </div>
    <div class="recording_data_td"
         style="width:90px">
      <div v-if="Number(item.TotalNum)>0">{{Number(item.TotalNum)}}次</div>
      <div v-else>-</div>
    </div>
    <div class="recording_data_td"
         style="width: 120px">
      <div v-if="Number(item.AbnormalIncrease)>0">加{{Number(item.AbnormalIncrease)}}课时</div>
      <div v-else>-</div>

    </div>
    <div class="recording_data_td"
         style="width: 120px">
      <div v-if="Number(item.AbnormalReduction)>0" class="pr">减{{Number(item.AbnormalReduction)}}课时</div>
      <div v-else>-</div>
    </div>
    <div class="recording_data_td"
             style="width: 100px">
          <div v-if="Number(item.ExceptionClassAway)>0">{{Number(item.ExceptionClassAway)}}课时</div>
          <div v-else>-</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: ['item'],
  computed: {
    vDate () {
      if (this.item && this.item.SetUpTime) {
        return this.item.SetUpTime.substring(0, 10);
      }
    }

  }
};
</script>

