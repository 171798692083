<template>
  <div class="workbench_view_right home_page_right_contont">
    <div class="massive_bg home_page_right_contont_flxed">
      <div class="home_page_user button"
           @click="showStaffInfo">
        <div class="user_img">
          <img :src="headImg"
               alt
               :onerror="defaultImg" />
          <!-- <staff-heads-img-upload
          :ImgURl="staffInfo.XMLIcon"
          :staffInfo="staffInfo"
          :formTitle="'员工头像:'"
          :imgHeight="300"
          :imgWidth="300"
          @doSetPicCorp="doSetPicCorpHeadImg"
        ></staff-heads-img-upload> -->
        </div>
        <div class="user_content">
          <div class="user_content_name"
               :title="staffInfo.MainDemoName">
            Hi, {{ staffInfo.MainDemoName }}
          </div>
          <div class="user_content_auth">
            <span class="user_content_auth_text">
              <!-- {{ staffInfo.PostKeyValue || "" }} -->
              角色: {{ staffInfo.Chose2KeyValue }}
            </span>
          </div>
        </div>

        <div class="user_btn"></div>
      </div>
      <todo-list ref="todoList"
                 :todoShowWarning="todoShowWarning"
                 :isAutoWarning="isAutoWarning"
                 @changeType="changeType"
                 @renewWarmingDetail="renewWarmingDetail"></todo-list>
      <!-- <instructions></instructions> -->
      <div class="workbench_view_news home_page_todo">
        <div class="workbench_view_title clearfix form_info_field"
             v-if="!todoShowWarning">
          <span>智能预警
            <input-form-tip :tips="'根据考勤，自动统计课时不足，欠课时等预警情况，点击可查看详情。'"></input-form-tip>
            <span class="renew_btn"
                  style="top: 16px"
                  @click.stop="renewWarmingDetail"></span>
          </span>
        </div>
        <div class="workbench_view_news_content"
             style="overflow-y: hidden"
             v-if="loadEnd && visAutoWarning">
          <div class="news_list form_info_field"
               v-for="(item, key) in vWarmingMenuList"
               :key="key"
               @click="toWarmingDetail(item)">
            <div class="help_list_inquiry clearfix">
              <span>{{ item.name }}{{item.type ==1?'':item.type != 5 ? "(人)" : "(个)" }}
              </span>
              <span class="news_list_btn">{{ item.num }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <custom-dialog title="修改员工"
                   :visible.sync="isshowStaffInfo"
                   :before-close="closeStaffInfo">
      <edit-staff :staffInfo="staffInfo"
                  @closeDilog="closeStaffInfo"></edit-staff>
    </custom-dialog>
    <!-- <lastNew></lastNew> -->
  </div>
</template>
<script>
import editStaff from "./components/edit-staff";
import todoList from "./todo-list";
import { getToDoStatistics, GetTheEmployeeByID } from "../../API/workbench";
export default {
  name: "indexView",
  data () {
    return {
      warmingMenuList: [], // 预警统计列表
      WarmingStatisical: {},
      token: {},
      loadEnd: true,
      staffInfo: {},
      mine: {
        moduleName: "mine",
      },
      isshowStaffInfo: false,
      todoShowWarning: false, //待办事项是否显示智能预警按钮
      isAutoWarning: false, //是否选中待办事项
    };
  },
  components: {
    todoList,
    editStaff,
  },
  created () {
    this.token = this.$store.getters.token;
    this.warmingMenuList = this.$initJson.toDoWarningList;
    console.log(
      "$utils.formatDateToLine",
      this.$utils.formatDateToLine(Date.new())
    );
    setInterval((o) => {
      this.vScrollHeight();
    }, 1000);
    this.loadData();
  },
  mounted () {
    this.registerBusEvent();
  },
  activated () {
    // let moduleName = this.$route.params.moduleName;
    // if (moduleName) {
    //   this.openCustomDialogByFactoryDialog(this.$route.params);
    // }
  },
  computed: {
    visAutoWarning () {
      if (!this.todoShowWarning) {
        return true;
      } else {
        return this.isAutoWarning;
      }
    },
    isSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    },
    vWarmingMenuList () {
      // let newArr = [];
      for (var i = 0; i < this.warmingMenuList.length; i++) {
        if (this.vHasModulePower(54)) {
          switch (String(this.warmingMenuList[i].type)) {
            case "1":
              if (Number(this.$utils.getSaaSClientSetInfo(128).SetKey) == 0) {
                this.warmingMenuList[i].name = '可用课时不足(人)'
                this.warmingMenuList[i].num =
                  this.WarmingStatisical.CountStudentRenewalNum;
              } else {
                this.warmingMenuList[i].name = '可用课时不足(课单)'
                this.warmingMenuList[i].num =
                  this.WarmingStatisical.CountXykdRenewalNum;
              }
              // this.warmingMenuList[i].num =
              //   this.WarmingStatisical.CountRenewalNum;
              break;
            case "2":
              this.WarmingStatisical.DebtClassHourInfo =
                this.WarmingStatisical.DebtClassHourInfo || {};
              this.warmingMenuList[i].num =
                this.WarmingStatisical.DebtClassHourInfo.CountDebtClassHourNum;
              break;
            case "3":
              this.warmingMenuList[i].num =
                this.WarmingStatisical.CountLeaveSchoolNum;
              break;
            case "4":
              this.warmingMenuList[i].num =
                this.WarmingStatisical.ArrangeWarningNum;
              break;
            case "5":
              this.warmingMenuList[i].num =
                this.WarmingStatisical.ClassScheduleNum;
              break;
            case "6":
              this.WarmingStatisical.DebtAmountInfo =
                this.WarmingStatisical.DebtAmountInfo || {};
              this.warmingMenuList[i].num =
                this.WarmingStatisical.DebtAmountInfo.DebtAmountNum;
              break;
            default:
              break;
          }
        } else {
          this.warmingMenuList[i].num = 0;
        }
      }
      return this.warmingMenuList;
    },
    // 头像
    headImg () {
      if (!this.staffInfo.XMLIcon) {
        this.staffInfo.XMLIcon = "";
      }
      if (this.staffInfo.XMLIcon.indexOf("http") > -1) {
        return this.staffInfo.XMLIcon;
      } else {
        return this.$store.getters.CDNURL + this.staffInfo.XMLIcon;
      }
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' +
        require("../../../public/image/deflut_teacher_photo@2x.png") +
        '"'
      );
    },

    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    },

    vToday () {
      if (this.latelyCourseInfo.CourseNameKey) {
        return (
          this.$utils.formatDatet(
            Date.new(this.latelyCourseInfo.ClassTime),
            "MM"
          ) +
          "月" +
          this.$utils.formatDatet(
            Date.new(this.latelyCourseInfo.ClassTime),
            "dd"
          ) +
          "日 " +
          " " +
          this.$utils.formatDatet(
            Date.new(this.latelyCourseInfo.ClassTime),
            "ddddd"
          )
        );
      }
    },
  },
  methods: {
    changeType (boolean) {
      console.log("changeType", boolean);
      this.isAutoWarning = boolean;
    },
    vScrollHeight () {
      // console.log("offsetHeight2222",document.getElementsByClassName('home_page_left_contont'))

      if (document.body.clientHeight < 900) {
        this.todoShowWarning = true;
      } else {
        this.todoShowWarning = false;
        this.isAutoWarning = false;
      }
      // if(this.scrollHeight<333){}
    },
    showStaffInfo () {
      this.isshowStaffInfo = true;
    },
    closeStaffInfo () {
      this.isshowStaffInfo = false;
    },
    loadData () {
      // this.getRealtimeData();
      if (!this.$store.getters.isLoadWarmingStatisical) {
        this.getWarmingStatisical();
      } else {
        this.WarmingStatisical = this.$store.getters.getWarmingStatisical;
      }
      this.getStaffInfo(this.token.UserID);
    },

    // 更新头像.
    doSetPicCorpHeadImg (ImgURl) {
      this.staffInfo.XMLIcon = ImgURl;
    },

    // 获取员工信息.
    getStaffInfo (OLAPKey, callback) {
      GetTheEmployeeByID(OLAPKey)
        .then((result) => {
          this.staffInfo = result.data;
          if (callback) {
            callback();
          }
          console.log("getStaffInfo", result.data);
        })
        .catch((error) => {
          console.log("getStaffInfo", error);
        });
    },
    // 获取预警统计.
    getWarmingStatisical () {
      this.loadEnd = false;
      getToDoStatistics()
        .then((result) => {
          this.WarmingStatisical = result.data;
          this.loadEnd = true;
          this.$store.commit("setisLoadWarmingStatisical", true);
          this.$store.commit("setWarmingStatisical", this.WarmingStatisical);
        })
        .catch((error) => {
          console.log("getWarmingStatisical", error);
        });
    },
    renewWarmingDetail () {
      this.getWarmingStatisical();
    },
    // 异常预警点击
    toWarmingDetail (item) {
      if (this.vHasModulePower(item.ModulePowerKey)) {
        item.callBack = {
          afterSucces: () => {
            this.getWarmingStatisical();
          },
        };
        console.log(item, "item");
        this.doAfterClickFunctionBtn(item);
      } else {
        this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
      }
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(
        this.vModulePowerList,
        ModulePowerKey
      );
    },
    doAfterClickFunctionBtn (item) {
      this.$bus.emit("needOpenRouter", {
        routerName: this.$route.name,
        moduleName: item.moduleName,
        callBack: item.callBack,
      });
      //   this.openCustomDialogByFactoryDialog({
      //     moduleName: item.moduleName,
      //     callBack: item.callBack
      //   });
    },
    registerBusEvent () {
      // this.$bus.on(this, "switchHeaderBarToIndexView", () => {
      //   this.loadData();
      //   this.$refs.todoList.loadData();
      // });
      this.$bus.on(this, "wsTodoList", (data) => {
        console.log("getTodoStatisticsByStaff");
        this.getWarmingStatisical();
      });
      this.$bus.on(this, "updateWarmingStatisical", (data) => {
        console.log("updateWarmingStatisical");
        this.getWarmingStatisical();
      });

      this.$bus.on(this, "wsAfterUpdateAgentInfo", (data) => {
        this.staffInfo = data;
      });
    },
  },
};
</script>
<style scoped>
.home_page_right_contont {
  width: 372px;
  /* min-height: 588px; */
  min-height: 100%;
  /* background: greenyellow; */
  position: relative !important;
  display: flex;
  flex-direction: column;
  /* margin-left: 20px; */
}
.home_page_right_contont_flxed {
  position: fixed;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 372px;
  padding-bottom: 5px;
}
.home_page_todo .workbench_view_title {
  border-bottom: none !important;
  border-top: 1px solid #ececec !important;
}
.workbench_view_news .workbench_view_title {
  line-height: 60px;
  height: 60px;
}
.home_page_todo .backlog_nav {
  border: none !important;
  padding: 0px;
}
.home_page_todo .backlog_nav_list.opt {
  background: #f2f2f2;
  /* border-bottom: none !important; */
  border-radius: 8px;
}
.home_page_user {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-left: 10px;
}
.user_img {
  width: 64px;
  height: 64px;
}
.user_img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 1px 5px 0px rgba(222, 225, 230, 0.6);
}
.user_content {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  margin-left: 20px;
}
.user_content_name {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
}
.user_content_auth_text {
  min-width: 100px;
  height: 20px;
  border-radius: 4px;
  padding: 0 5px;
  background: #f276014d;
  color: #f27601;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home_page_todo .renew_btn {
  width: 25px;
  height: 25px;
  border: none !important;
  background: url('../../../public/image/home_page_renew.png') center no-repeat;
  background-size: 18px;
}
.home_page_todo .renew_btn:hover {
  opacity: 0.8;
}
.user_btn {
  width: 18px;
  height: 18px;
  background: url('../../../public/image/home_page_next.png') no-repeat right
    center;
  background-size: 14px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 30px;
}
</style>
