<template>
  <div class="chain_mendian_top massive_bg">
    <div class="chain_mendian_top_list">
      <div class="list_title">实到/应到(人)</div>
      <div class="list_box">
        <div class="list_left">
          <div class="list_top">
            <span class="font_big">{{Number(reportInfo.YesterdayArrivedNum||0)}}</span>
            <span>应到 {{Number(reportInfo.YesterdayArrangedNum||0)}}</span>
          </div>
          <div class="list_bottom">
            昨天
          </div>
        </div>
        <div class="list_right">
          <div class="list_top">
            <span class="font_big">{{Number(reportInfo.MonthArrivedNum||0)}}</span>
            <span>应到 {{Number(reportInfo.MonthArrangedNum||0)}}</span>
          </div>
          <div class="list_bottom">
            {{vmonth}}
          </div>
        </div>
      </div>
    </div>
    <div class="chain_mendian_top_list">
      <div class="list_title">课消金额(元)</div>
      <div class="list_box">
        <div class="list_left">
          <div class="list_top">
            <span class="font_big">{{setMoney(reportInfo.YesterdayAmount||0)}}</span>
          </div>
          <div class="list_bottom">
            昨天
          </div>
        </div>
        <div class="list_right">
          <div class="list_top">
            <span class="font_big">{{setMoney(reportInfo.MonthAmount||0)}}</span>
          </div>
          <div class="list_bottom">
             {{vmonth}}
          </div>
        </div>
      </div>
    </div>
    <div class="chain_mendian_top_list">
      <div class="list_title">报名人次(人)</div>
      <div class="list_box">
        <div class="list_left">
          <div class="list_top">
            <span class="font_big">{{Number(reportInfo.YesterdayApplyNum||0)}}</span>
          </div>
          <div class="list_bottom">
            昨天
          </div>
        </div>
        <div class="list_right">
          <div class="list_top">
            <span class="font_big">{{Number(reportInfo.MonthApplyNum||0)}}</span>
          </div>
          <div class="list_bottom">
            {{vmonth}}
          </div>
        </div>
      </div>
    </div>
    <div class="chain_mendian_top_list">
      <div class="list_title">报名金额(元)</div>
      <div class="list_box">
        <div class="list_left">
          <div class="list_top">
            <span class="font_big">{{setMoney(reportInfo.YesterdayApplyAmount||0)}}</span>
          </div>
          <div class="list_bottom">
            昨天
          </div>
        </div>
        <div class="list_right">
          <div class="list_top">
            <span class="font_big">{{setMoney(reportInfo.MonthApplyAmount||0)}}</span>
          </div>
          <div class="list_bottom">
           {{vmonth}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    reportInfo: Object
  },
  computed: {
    vmonth () {
      if (Date.new().getDate() == 1) {
        return '上月';
      } else {
        return '本月';
      }
    }
  },
  methods: {
    setMoney (val) {
      val = val || 0;
      return Math.round(Number(val));
    }
  }
};
</script>



