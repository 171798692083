<template>
  <div class="courseware_add_lefts courseware_add_left">
    <div v-if="isShowAttachMenu"
         class="list_multimedia_menu"
         :class="{cws_munu:vISCws&&bodyScrollTop>10}">
      <div class="menu_list_content">
        <div class="menu_list type_01">

        </div>
        <div class="menu_list_text">
          <div class="font_black">
            图片
          </div>
          <div class="font_gray">
            限制大小8MB, 文件格式jpg、jpeg、png。
          </div>
        </div>
        <upload :attachType="'1'"
                :VisitAuthKey="VisitAuthKey"
                :dataInfo="dataInfo.CoursewareAttach"
                :coursewareId="coursewareId"
                :authorizedCode="authorizedCode"
                @deleteAccessory="deleteAccessory"
                @saveAccessory="saveAccessory"></upload>
      </div>
      <div class="menu_list_content">
        <div class="menu_list type_04">

        </div>
        <div class="menu_list_text">
          <div class="font_black">
            音频
          </div>
          <div class="font_gray">
            限制大小50MB, 文件格式wav、mp3、aac。
          </div>
        </div>
        <upload :attachType="'3'"
                :coursewareId="coursewareId"
                :VisitAuthKey="VisitAuthKey"
                :dataInfo="dataInfo.CoursewareAttach"
                :authorizedCode="authorizedCode"
                @deleteAccessory="deleteAccessory"
                @saveAccessory="saveAccessory"></upload>
      </div>
      <div class="menu_list_content">
        <div class="menu_list type_02">

        </div>
        <div class="menu_list_text">
          <div class="font_black">
            视频
          </div>
          <div class="font_gray">
            限制大小500MB, 文件格式mp4。
          </div>
        </div>
        <upload :attachType="'2'"
                :coursewareId="coursewareId"
                :VisitAuthKey="VisitAuthKey"
                :dataInfo="dataInfo.CoursewareAttach"
                :authorizedCode="authorizedCode"
                @deleteAccessory="deleteAccessory"
                @saveAccessory="saveAccessory"></upload>
      </div>
      <div class="menu_list_content">
        <div class="menu_list type_03">

        </div>
        <div class="menu_list_text">
          <div class="font_black">
            PPT
          </div>
          <div class="font_gray">
            限制大小200MB, 文件格式ppt、pptx。
          </div>
        </div>
        <upload :attachType="'4'"
                :coursewareId="coursewareId"
                :VisitAuthKey="VisitAuthKey"
                :dataInfo="dataInfo.CoursewareAttach"
                :authorizedCode="authorizedCode"
                @deleteAccessory="deleteAccessory"
                @saveAccessory="saveAccessory"></upload>
      </div>
      <div class="menu_list_contents">
        <div class="menu_list type_05">

        </div>
        <div class="menu_list_text">
          <div class="font_black">
            PDF
          </div>
          <div class="font_gray">
            限制大小20MB, 文件格式pdf。
          </div>
        </div>
        <upload :attachType="'5'"
                :coursewareId="coursewareId"
                :VisitAuthKey="VisitAuthKey"
                :dataInfo="dataInfo.CoursewareAttach"
                :authorizedCode="authorizedCode"
                @deleteAccessory="deleteAccessory"
                @saveAccessory="saveAccessory"></upload>
      </div>

      <div class="icon_view"
           :style="{ top: '-8px' }"></div>
      <!-- { form_info_required: required } -->
    </div>
    <div :class="{ fexid_box : showType== 1, marginTop:bodyScrollTop>10&&showType== 1}">
      <div class="left_ul_title">
        <div class="left_lists type_02"
             :class="showType==1?'option':''"
             @click.stop="showTypeOption(1)">

          <div ref="attachBtn">
            <div>课件素材</div>

          </div>

        </div>
        <div class="left_lists type_01"
             v-if="vSettingTemplateData&&vSettingTemplateData.procedures==1"
             :class="showType==2?'option':''"
             @click.stop="showTypeOption(2)">
          <div @click="openTeachingStep">
            <div>课件教程</div>
          </div>
        </div>
        <div class="left_lists type_03"
             v-if="vSettingTemplateData&&vSettingTemplateData.lessonCommentary==1"
             :class="showType==3?'option':''"
             @click.stop="showTypeOption(3)">
          <div @click="openLanguage()">
            <div>课评话术</div>

          </div>
        </div>
        <div class="left_lists type_04 "
             v-if="vSettingTemplateData&&vSettingTemplateData.homeworkAfter==1"
             :class="showType==4?'option':''"
             @click.stop="showTypeOption(4)">

          <div>
            <div>课后作业</div>

          </div>
        </div>
        <div class="set_box"
             @click="setMode">
          <img src="../../../../../public/image/courseware_set.png"
               alt=""
               class="set_box_img">
          <span class="set_box_text">模块设置</span>
        </div>
      </div>
    </div>

    <div class="left_content"
         @scroll="scrollList($event)">

      <div v-if=" showType == 1 "
           style="margin-top: 150px;"
           class="list_banner_box">
        <div class="view_fexid"
             :class="{marginTop:bodyScrollTop>10,cws_fexid:vISCws}  ">
          <div class="up_courseware"
               @click.stop="showAttachMenu()">
            <img src="../../../../../public/image/upload_courseware.png"
                 alt=""
                 class="set_box_imgda">
            <span class="upload_text">上传课件</span>
          </div>

          <div class="up_courseware"
               @click.stop="showFreeFontsList()"
               style="margin-left: 20px;">
            <img src="../../../../../public/image/font-famliy.png"
                 alt=""
                 class="set_box_imgda">
            <span class="upload_text">免费字体</span>
          </div>
        </div>

        <accessory-item v-for="(item, index) in dataInfo.CoursewareAttach"
                        :key="index"
                        :dataInfo="item"
                        :maxHeight="dataInfo.CoursewareAttach.length"
                        :attachType="attachType"
                        :editInfo="editInfo"
                        :coursewareId="coursewareId"
                        :authorizedCode="authorizedCode"
                        :settingTemplateData="vSettingTemplateData"
                        @clearEditInfo="clearEditInfo"
                        @deleteCoverUrl="deleteCoverUrl"
                        @selectPPTAccessory="selectPPTAccessory"
                        @selectItem="selectAccessory"
                        @upItem="upAccessory"
                        @downItem="downAccessory"
                        @showPlayViewPopup="showPlayViewPopup"
                        @deleteCourseWare="deleteCourseWare"
                        @saveAccessory="saveAccessory"></accessory-item>
      </div>
    </div>

    <div v-if="showType == 2"
         class="list_banner_box courseware_add_right accessoryContent"
         style="margin-left: 20px;">

      <span style="position: absolute;left:40px;line-height: 30px;font-size: 16px;">课件教程</span>
      <teaching-step-editor :dataInfo="dataInfo"
                            :coursewareId="coursewareId"
                            :authorizedCode="authorizedCode"
                            @editText="editTeachingStep"
                            @saveText="saveTeachingStep"></teaching-step-editor>
      <div class="accessoryRight">
        <accessory-item v-for="(item, index) in vDesignateAttachType"
                        :key="index"
                        :dataInfo="item"
                        :attachType="attachType"
                        :editInfo="editInfo"
                        :coursewareId="coursewareId"
                        @clearEditInfo="clearEditInfo"
                        @deleteCoverUrl="deleteCoverUrl"
                        @selectPPTAccessory="selectPPTAccessory"
                        @showPlayViewPopup="showPlayViewPopup"
                        @selectItem="selectAccessory"
                        @upItem="upTeachingStepAccessory"
                        @downItem="downTeachingStepAccessory"
                        @deleteCourseWare="deleteCourseWare"
                        @saveAccessory="saveAccessory"></accessory-item>

        <div v-if="vOtherAttachType.length>0"
             class="accessory_other_box">
          <span style="font-size: 16px;">可下载附件</span>
          <accessory-other-item v-for="(item, index) in vOtherAttachType"
                                :key="index"
                                :dataInfo="item"
                                @deleteItem="deleteItem"></accessory-other-item>
        </div>
      </div>

    </div>

    <div v-if="showType == 3"
         style="margin-left: 20px;"
         class="list_banner_box">

      <language-editor :coursewareId="coursewareId"
                       :dataInfo="dataInfo"
                       @upCourseEvaluationInfo="upCourseEvaluationInfo"></language-editor>
    </div>

    <div v-if="showType == 4"
         style="margin-left: 20px;"
         class="list_banner_box homework">

      <home-work :coursewareId="coursewareId"
                 :dataInfo="dataInfo.HomeWork"
                 @upHomeworkInfo="upHomeworkInfo"></home-work>
    </div>

    <custom-dialog :title="'设置课件模块'"
                   width="400px"
                   :visible.sync="isShowCourseModeFormPopup"
                   :before-close="closeCoursewareFormPopup">
      <course-mode-set :SettingTemplate="vSettingTemplateData"
                       :isReadonly="false"
                       @close="closeCoursewareFormPopup"
                       @afterSuccess="commitSettingTemplate"></course-mode-set>
    </custom-dialog>
    <custom-dialog :title="showItem.MainDemoName?showItem.MainDemoName:'未命名'"
                   width="1000px"
                   :visible.sync="isShowPlayView"
                   :before-close="closePalyViewPopup">
      <playView :dataInfo="showItem"
                :isReadonly="false"
                @close="closePalyViewPopup"
                @afterSuccess="commitSettingTemplate"></playView>
    </custom-dialog>

    <custom-dialog :title="'免费字体'"
                   width="400px"
                   :visible.sync="isShowFreeFontsListPopup"
                   :before-close="closeFreeFontsListPopup">
      <free-fonts-list @close="closeFreeFontsListPopup"></free-fonts-list>
    </custom-dialog>
  </div>
</template>
<script>
import accessoryItem from './accessory-item';
import accessoryOtherItem from './accessory-other-item';
import languageItem from './language-item';
import freeFontsList from './fonts-list.vue';
import upload from './upload';
import inputUploadCover from './input-upload-cover';
import teachingStepEditor from '../courseware-edit/teaching-step-editor.vue';
import languageEditor from '../courseware-edit/language-editor';
import homeWork from '../courseware-edit/home-work';
import dialogFactory from '../../../common/dialog-factory';
import courseModeSet from './course-mode-set';
import playView from './play-view';
import { CoursewareManageAttachSort, SetSettingTemplate } from '../../../../API/workbench';
export default {
  data () {
    return {
      bodyScrollTop: 0,
      scrollTop: 0,
      isShowAttachMenu: false,
      CoursewareTitle: '未命名课件标题',
      coverSelect: false,
      showType: 1,
      isShowCourseModeFormPopup: false,
      optionList: [],
      isShowPlayView: false,
      showItem: {},
      isShowFreeFontsListPopup: false,
      SettingTemplateData: {
        procedures: 0, // 教学步骤
        lessonCommentary: 0, // 课评话术
        homeworkAfter: 0, // 课后作业
        pptFontCheck: 0 // PPT字体缺少校验
      },
      isUpdate: false
    };
  },
  props: {
    dataInfo: Object,
    coursewareId: [String, Number],
    authorizedCode: String,
    editType: [String, Number],
    attachType: String,
    editInfo: Object,
    SettingTemplate: Object,
    boxScrollTops: Number

  },
  components: {
    accessoryItem,
    languageItem,
    upload,
    accessoryOtherItem,
    inputUploadCover,
    teachingStepEditor,
    languageEditor,
    homeWork,
    dialogFactory,
    courseModeSet,
    playView,
    freeFontsList
  },
  created () {

  },
  destroyed () {
    window.removeEventListener('scroll', this.updateScrollTop);
  },
  mounted () {
    window.addEventListener('scroll', this.updateScrollTop);
    console.log('this.dataInfo.Title', this.dataInfo.Title, this.dataInfo);
    if (this.dataInfo.Title) {
      this.CoursewareTitle = this.dataInfo.Title;
    }
    this.SettingTemplateData = this.SettingTemplate;

    console.log('this.SettingTemplateData', this.SettingTemplateData, this.SettingTemplate);
  },
  computed: {
    vSettingTemplateData () {
      if (this.isUpdate) {
        return this.SettingTemplateData;
      } else {
        return this.dataInfo.SettingTemplate;
      }
    },
    VisitAuthKey () {
      return this.dataInfo.Authorized.OLAPKey || '';
    },
    // 指定附件
    vDesignateAttachType () {
      let list = this.dataInfo.CoursewareTeachingStep.filter((obj) => {
        return obj.TypeKey == 2;
      });
      list.sort((x, y) => {
        return x.ShowOrder - y.ShowOrder;
      });
      return list;
    },
    // 其他附件
    vOtherAttachType () {
      let list = this.dataInfo.CoursewareTeachingStep.filter((obj) => {
        return obj.TypeKey == 3;
      });
      // let item = {
      //   AttachTypeKey: "1",
      //   AttachViewUrl: "/CoursewareView/06885918cbc73f5746a1feec53b0f921/63919",
      //   CoverUrl: "/Upload/Resource/10545/Courseware/Cover/202206281717555737.jpg_226.jpg",
      //   DurationNum: "",
      //   ID: 63919,
      //   MainDemoName: "大美中国.mp4",
      //   ShowOrder: 2,
      //   SizeNum: "3.6",
      //   TypeKey: 3,
      //   isSelect: false,
      //   percent: "100",
      //   percentText: ""
      // };
      // for (let index = 0; index < 5; index++) {
      //   list.push(item);
      // }
      console.log(list, this.dataInfo, '其他附件');
      return list;
    },
    vISCws () {
      return this.$store.getters.SaaSClientEditionKey == 6;
    },
    vScrollTop () {
      let n = Math.abs(this.$refs.attachBtn.offsetTop - 330) + this.scrollTop;
      if (n > 0) {
        console.log('this.vISCws', this.vISCws);
        if (this.vISCws) {
          if (n > 142) {
            return '-' + (142 - 60) + 'px';
          } else {
            return '-' + (n - 60) + 'px';
          }
        } else {
          if (n > 142) {
            return '-' + 142 + 'px';
          } else {
            return '-' + n + 'px';
          }
        }
      } else {
        console.log('this.vISCws', this.vISCws);
        if (this.vISCws) {
          return (n - 60) + 'px';
        } else {
          return n + 'px';
        }
      }
    },
    vIconBottom () {
      let n = Math.abs(this.$refs.attachBtn.offsetTop - 330) + this.scrollTop;
      if (n > 142) {
        return n - 142 + 42 + 'px';
      } else {
        return '42px';
      }
    },
    vCoverUrl () {
      if (!this.dataInfo.CoverUrl) {
        this.dataInfo.CoverUrl = '';
      }

      console.log('CoverUrl', this.dataInfo.CoverUrl);
      if (this.dataInfo.CoverUrl && this.dataInfo.CoverUrl.indexOf('http') > -1) {
        return this.dataInfo.CoverUrl;
      } else {
        return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
      }
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' + require('../../../../../public/image/no_logo@2x.png') + '"'
      );
    }
  },
  watch: {
    'dataInfo.Title' (n, o) {
      if (n) {
        this.CoursewareTitle = this.dataInfo.Title;
      }
    },
    // 教学
    'dataInfo.CoursewareTeachingStep.length' (n, o) {
      console.log(this.dataInfo, 'watch-教学步骤');
      if (n != o) {
        var data1 = this.dataInfo.CoursewareTeachingStep.filter((item) => { return item.TypeKey == '2'; });
        var data2 = this.dataInfo.CoursewareTeachingStep.filter((item) => { return item.TypeKey == '3'; });
        // for (let i = 1; i <= data1.length; i++) {
        //   data1[i - 1].ShowOrder = i;
        // }
        // if (data1.length > 1) {
        data1.sort((x, y) => {
          return x.ShowOrder < y.ShowOrder;
        });
        data1.forEach((item, index) => {
          item.ShowOrder = index + 1;
        });
        // }
        // else if (data1.length == 1) {
        //   data1[0].ShowOrder = 1;
        // }

        this.dataInfo.CoursewareTeachingStep = data1.concat(data2);

        console.log(this.dataInfo.CoursewareTeachingStep, 'watch-教学步骤排序后');
      }
    },
    'dataInfo.CoursewareAttach.length' (n, o) {
      console.log(this.dataInfo, 'watch-课评话术');
      if (n != o) {
        for (let i = 1; i <= this.dataInfo.CoursewareAttach.length; i++) {
          this.dataInfo.CoursewareAttach[i - 1].ShowOrder = i;
        }
        this.dataInfo.CoursewareAttach.sort((x, y) => {
          return x.ShowOrder - y.ShowOrder;
        });

        console.log('watch-课评话术', this.dataInfo.CoursewareAttach);
      }
    },
    'boxScrollTops' (n, o) {
      if (n) {
        console.log('bodyScrollTop', n);
        this.bodyScrollTop = n;
      }
    }
  },
  methods: {
    showTypeOption (type) {
      this.showType = type;
      if (type == 1) {
        this.$emit('updateOption');
      }
    },
    // 课件包封面地址
    doSetPicCorpHeadImg (Url) {
      console.log('课件包封面地址', Url);
      this.dataInfo.CoverUrl = Url;
      this.$emit('uploadCourseware');
    },
    blurTitleInput () {
      if (!this.CoursewareTitle) {
        this.CoursewareTitle = this.dataInfo.Title;
        layer.alert('请输入课件名字');
        return false;
      }
      this.$emit('blurTitleInput', this.CoursewareTitle);
    },
    seletedCoverUrl () {
      this.coverSelect = true;
      this.$emit('seletedCoverUrl');
    },
    saveAccessory () {
      this.$emit('saveAccessory');
    },
    upAccessory (data) {
      const n = Number(data.ShowOrder) - 1;
      var data1 = this.dataInfo.CoursewareAttach;

      data1.sort((x, y) => {
        return x.ShowOrder - y.ShowOrder;
      });
      data1[data.ShowOrder - 2].ShowOrder = Number(data1[data.ShowOrder - 2].ShowOrder) + 1;
      data.ShowOrder = n;
      data1.sort((x, y) => {
        return x.ShowOrder - y.ShowOrder;
      });
      this.dataInfo.CoursewareAttach = data1;

      CoursewareManageAttachSort(
        data.ID,
        this.coursewareId,
        data.ShowOrder
      ).then(
        (res) => { },
        (err) => {
          console.log(err.msg);
        }
      );
    },
    downAccessory (data) {
      const n = Number(data.ShowOrder) + 1;
      var data1 = this.dataInfo.CoursewareAttach;
      data1.sort((x, y) => {
        return x.ShowOrder - y.ShowOrder;
      });
      data1[data.ShowOrder].ShowOrder =
        Number(data1[data.ShowOrder].ShowOrder) - 1;
      data.ShowOrder = n;
      data1.sort((x, y) => {
        return x.ShowOrder - y.ShowOrder;
      });
      this.dataInfo.CoursewareAttach = data1;

      CoursewareManageAttachSort(
        data.ID,
        this.coursewareId,
        data.ShowOrder
      ).then(
        (res) => { },
        (err) => {
          console.log(err.msg);
        }
      );
    },
    upTeachingStepAccessory (data) {
      const n = Number(data.ShowOrder) - 1;
      var data1 = this.dataInfo.CoursewareTeachingStep.filter((item) => { return item.TypeKey == '2'; });
      var data2 = this.dataInfo.CoursewareTeachingStep.filter((item) => { return item.TypeKey == '3'; });
      data1.sort((x, y) => {
        return x.ShowOrder - y.ShowOrder;
      });
      data1[data.ShowOrder - 2].ShowOrder = Number(data1[data.ShowOrder - 2].ShowOrder) + 1;
      data.ShowOrder = n;
      data1.sort((x, y) => {
        return x.ShowOrder - y.ShowOrder;
      });
      this.dataInfo.CoursewareTeachingStep = data1.concat(data2);
      CoursewareManageAttachSort(
        data.ID,
        this.coursewareId,
        data.ShowOrder
      ).then(
        (res) => { },
        (err) => {
          console.log(err.msg);
        }
      );
    },
    downTeachingStepAccessory (data) {
      const n = Number(data.ShowOrder) + 1;
      var data1 = this.dataInfo.CoursewareTeachingStep.filter((item) => { return item.TypeKey == '2'; });
      var data2 = this.dataInfo.CoursewareTeachingStep.filter((item) => { return item.TypeKey == '3'; });
      data1.sort((x, y) => {
        return x.ShowOrder - y.ShowOrder;
      });
      data1[data.ShowOrder].ShowOrder =
        Number(data1[data.ShowOrder].ShowOrder) - 1;
      data.ShowOrder = n;
      data1.sort((x, y) => {
        return x.ShowOrder - y.ShowOrder;
      });
      this.dataInfo.CoursewareTeachingStep = data1.concat(data2);
      CoursewareManageAttachSort(
        data.ID,
        this.coursewareId,
        data.ShowOrder
      ).then(
        (res) => { },
        (err) => {
          console.log(err.msg);
        }
      );
    },
    deleteAccessory () {
      this.$emit('deleteAccessory');
    },
    deleteCourseWare (data) {
      this.$emit('deleteAccessory');
      if (data.TypeKey == 1) {
        let n = this.dataInfo.CoursewareAttach.findIndex((item) => { return item.ShowOrder == data.ShowOrder; });
        this.dataInfo.CoursewareAttach.splice(n, 1);
        for (let i = 0; i < this.dataInfo.CoursewareAttach.length; i++) {
          this.dataInfo.CoursewareAttach[i].ShowOrder = i + 1;
        }
        this.dataInfo.CoursewareAttach.sort((x, y) => {
          return x.ShowOrder - y.ShowOrder;
        });
      } else if (data.TypeKey == 2) {
        let t = this.dataInfo.CoursewareTeachingStep.findIndex((item) => { return item.ShowOrder == data.ShowOrder; });
        this.dataInfo.CoursewareTeachingStep.splice(t, 1);
        for (let i = 0; i < this.dataInfo.CoursewareTeachingStep.length; i++) {
          this.dataInfo.CoursewareTeachingStep[i].ShowOrder = i + 1;
        }
        this.dataInfo.CoursewareTeachingStep.sort((x, y) => {
          return x.ShowOrder - y.ShowOrder;
        });
      }
    },
    // 删除其他附件
    deleteItem (item) {
      this.dataInfo.CoursewareTeachingStep = this.dataInfo.CoursewareTeachingStep.filter((obj) => {
        return obj.ID != item.ID;
      });
      console.log(this.dataInfo.CoursewareTeachingStep, '删除其他附件');
      this.deleteAccessory();
    },

    selectAccessory (data) {
      this.coverSelect = false;
      if (data.AttachTypeKey == 1) {
        this.showAttachEdit('img', data);
      } else if (data.AttachTypeKey == 2) {
        this.showAttachEdit('video', data);
      } else if (data.AttachTypeKey == 3) {
        this.showAttachEdit('audio', data);
      } else if (data.AttachTypeKey == 4) {
        this.$emit('selectPPTAccessory', data);
        this.showAttachEdit('ppt', data);
      } else if (data.AttachTypeKey == 5) {
        this.showAttachEdit('pdf', data);
      }
      let dataInfo = {
        OLAPKey: data.EssayID, // 文件ID
        Title: '课后作业音频', // 文件名称
        AttachTypeKey: data.AttachTypeKey, // 文件类型
        AttachViewUrl: this.$store.getters.CDNURL + data.AttachUrl, // 文件URL
        DurationNum: '', // 页码
        currentNum: '', // 历史翻页
        currentTime: '', // 历史播放时间
        CoverUrl: this.$store.getters.CDNURL + data.VideoCoverUrl
      };
      this.$bus.emit('openAttachInfo', dataInfo);
    },
    openTeachingStep () {
      this.$emit('openCoursewareEdit', '1');
    },
    showAttachEdit (type, data) {
      this.isShowAttachMenu = false;
      this.$emit('openCoursewareEdit', '2', data, type);
    },
    openLanguage (data) {
      console.log(data, '添加课评话术');
      if (data) {
        this.dataInfo.Evaluation = this.dataInfo.Evaluation.filter((obj) => { return obj.OLAPKey != 0; });
        this.$emit('openCoursewareEdit', '3', data);
      } else {
        setTimeout(() => {
          let nData = { Content: `请在右侧编辑处添加内容...`, OLAPKey: 0, isSelect: false, className: 'font_gray9' };
          let list = this.$utils.parseJson(this.dataInfo.Evaluation);
          let findIndex = list.findIndex(o => { return o.OLAPKey == 0; });
          if (findIndex < 0) {
            this.dataInfo.Evaluation = list;
          }
          this.$emit('openCoursewareEdit', '3', nData);
        }, 300);
      }
    },
    openHomeWork () {
      this.$emit('openCoursewareEdit', '4');
    },
    showAttachMenu () {
      // if (this.editType == 3) {
      //   layer.confirm("当前编辑的课评话术未保存，是否取消编辑", {
      //     btn: [
      //       {
      //         name: "取消",
      //         callBack: () => {
      //           layer.close();
      //         },
      //       },
      //       {
      //         name: "确认",
      //         callBack: () => {
      //           this.$emit("deleteAccessory");
      //           this.isShowAttachMenu = true;

      //           layer.close();
      //         },
      //       },
      //     ],
      //   });
      // } else
      if (this.editType == 4) {
        layer.confirm('当前编辑的课后作业未保存，是否取消编辑', {
          btn: [
            {
              name: '取消',
              callBack: () => {
                layer.close();
              }
            },
            {
              name: '确认',
              callBack: () => {
                this.$emit('deleteAccessory');
                this.isShowAttachMenu = true;

                layer.close();
              }
            }
          ]
        });
      } else {
        this.isShowAttachMenu = true;
      }
    },
    hiddenAttachMenu () {
      if (this.isShowAttachMenu) {
        this.isShowAttachMenu = false;
      }
    },
    scrollList (e) {
      this.isShowAttachMenu = false;
      this.scrollTop = e.target.scrollTop;
      console.log('111', this.scrollTop);
    },
    deleteCoverUrl () {
      this.$emit('deleteCoverUrl');
    },
    clearEditInfo () {
      this.$emit('clearEditInfo');
    },
    selectPPTAccessory (data) {
      this.$emit('selectPPTAccessory', data);
    },
    upAccessoryList () {
      this.$emit('upAccessoryList');
    },
    uploadCourseware () {
      this.$emit('uploadCourseware');
    },
    upCourseEvaluationInfo (data) {
      if (data) {
        let i = this.dataInfo.Evaluation.findIndex((obj) => { return obj.OLAPKey == data.essayID; });
        if (i == -1) {
          this.dataInfo.Evaluation.push({
            OLAPKey: data.essayID,
            Content: data.Content
          });
        } else {
          this.dataInfo.Evaluation[i].Content = data.Content;
        }
      }
      this.$emit('upCourseEvaluationInfo', data);
    },
    upHomeworkInfo () {
      this.$emit('upHomeworkInfo');
    },
    editTeachingStep (text) {
      this.dataInfo.TeachingProcedure = text;
    },
    saveTeachingStep () {
      this.$emit('uploadCourseware');
    },
    setMode () {
      this.isShowCourseModeFormPopup = true;
    },
    closeCoursewareFormPopup () {
      this.isShowCourseModeFormPopup = false;
    },
    closePalyViewPopup () {
      this.isShowPlayView = false;
    },
    closeFreeFontsListPopup () {
      this.isShowFreeFontsListPopup = false;
    },
    showPlayViewPopup (data) {
      console.log('data', data);
      this.showItem = data;
      this.isShowPlayView = true;
    },
    commitSettingTemplate (data) {
      this.showTypeOption(1);
      this.SettingTemplateData = {
        procedures: data.procedures ? 1 : 0, // 教学步骤
        lessonCommentary: data.lessonCommentary ? 1 : 0, // 课评话术
        homeworkAfter: data.homeworkAfter ? 1 : 0, // 课后作业
        pptFontCheck: data.pptFontCheck ? 1 : 0// PPT字体缺少校验
      };
      this.isUpdate = true;
      console.log('dataSettingTemplateData', this.SettingTemplateData);
      SetSettingTemplate(
        this.SettingTemplateData,
        this.coursewareId
      ).then(
        (res) => { },
        (err) => {
          console.log(err.msg);
        }
      );
    },
    updateScrollTop () {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop; // 兼容多个浏览器
      // if (!this.keyWord) {

      //   document.documentElement.scrollTop = 0
      //   document.body.scrollTop = 0
      // }

      this.bodyScrollTop = scrollTop;
      console.log(scrollTop);
    },
    showFreeFontsList () {
      this.isShowFreeFontsListPopup = true;
    }
  }
};
</script>
<style scoped>
.fexid_box {
  position: fixed;
  /* width: 76%; */
  display: flex;
  /* flex: 1; */
  width: 76%;
  background: #ffffff;
  z-index: 11;
}
.left_ul_title {
  display: flex;
  background: #ff60001a;
  height: 39px;
  flex: 1;
  margin: 40px 40px 20px 40px;
  position: relative;
  border-radius: 4px;
}

.left_content {
  margin: 0 40px;
}
.view_fexid {
  position: fixed;
  top: 279px;
  z-index: 11;
  width: 500px;
  background: #ffffff;
  display: flex;
}

.cws_fexid {
  top: 259px !important;
}
.up_courseware {
  width: 102px;
  height: 36px;
  top: 211px;
  left: 375px;
  border-radius: 4.6769232749938965px;
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(52, 152, 219, 1);
  background: rgba(241, 243, 249, 1);
  cursor: pointer;
  text-align: center;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
}

.left_ul_title .left_lists {
  margin-left: 1px;
  width: 79.09px;
  height: 34.55px;
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  margin: 2px 2px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

.left_ul_title .option {
  color: #ffffff;
  background: linear-gradient(257.72deg, #ff6000 1.93%, #ff8900 95.76%);
}

.courseware_add_lefts {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 0px 0px 4px 4px;
  margin-right: 20px;
  position: relative;
  width: 100%;
  height: inherit;
  margin-top: 20px;
  min-height: 948px;
}
.courseware_add_lefts .list_multimedia_menu {
  position: fixed;
  width: 540px;
  height: 412px;
  background: #fff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  top: 313px;
  left: 374px;
  z-index: 3002;
}

.cws_munu {
  top: 278px !important;
}
.accessory_other_box {
  width: 416px;
  background: #ffffff;
  border: 1px solid #e1e4e7;
  border-radius: 8px;
  padding: 15px 10px;
  min-height: 100px;
  padding-bottom: 0;
}
.menu_list_content {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 15px;
  cursor: pointer;
}
.menu_list_contents {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 15px;
  cursor: pointer;
}
.courseware_add_lefts .list_multimedia_menu .menu_list {
  padding-top: 0px;
  background-position: center 5px;
  width: 50px;
  height: 50px;
}
.menu_list_text {
  display: flex;
  flex-direction: column;
  flex: 1;
  line-height: 26px;
  padding-left: 10px;
  padding-right: 12px;
}
.marginTop {
  margin-top: -20px;
}
.menu_list_text .font_black {
  background: url(../../../../../public/image/next_icon@2x.png) no-repeat right
    center;
  background-size: 8px;
  font-size: 16px;
}
.menu_list_content >>> .upload_input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  cursor: pointer;
}

.menu_list_contents >>> .upload_input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  cursor: pointer;
}
.menu_list_content::after {
  content: '';
  display: block;
  border: 1px solid #ececec;
  position: absolute;
  bottom: 0px;
  width: 508px;
}
.list_multimedia_menu:last-child {
  border: 1px solid red;
}
.menu_list_text .font_gray {
  color: #999 !important;
}
.list_banner_box .courseware_form_upload_box {
  width: 272px;
  height: 161.5px;
}
.icon_view {
  left: 40px !important;
  bottom: inherit;
}
.set_box {
  position: absolute;
  right: 60px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  top: 8px;
}
.set_box_text {
  padding-left: 5px;
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.set_box_imgda {
  width: 16.57px;
  height: 16px;
}
.set_box_img {
  width: 22px;
  height: 22px;
}
.upload_text {
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  margin-left: 4px;
}
.accessoryRight {
  display: flex;
  flex-direction: column;
  padding: 50px 20px;
  flex: 1;
}
.accessoryContent {
  display: flex;
  flex-direction: row;
  /* flex: 1; */
}
.courseware_add_right >>> .add_text_box {
  width: 45%;
  min-width: 500px;
  margin: 50px 20px !important;
  height: 670px;
}
.courseware_add_right >>> .add_text_box .text_box {
  height: 570px;
}

.homework >>> .courseware_textarea_box {
  width: 45%;
  min-width: 500px;
}
.free_fonts {
  display: flex;
  color: #3498db;
  background: #f1f3f9;
  width: 85px;
  height: 36px;
  border-radius: 4px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}
</style>


