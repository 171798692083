<template>
  <div class="statistics_echarts_right fr">
    <div class="statistics_echarts_right_title">{{formTitle}}</div>
    <div class="statistics_echarts_right_content">
      <div class="statistics_echarts_right_list">
        <div class="list_left dot_green fl">
          <div class="font_top">最高</div>
          <div class="font_bottom">{{topInfoCount||'0'}}</div>
        </div>
        <div class="list_right fl">
          <div class="font_top">{{topMainDemoName}}</div>
          <div class="font_bottom">已上 <span>{{topInfoOnClass||0}}</span> 节课
          </div>
        </div>
      </div>
      <div class="statistics_echarts_right_list">
        <div class="list_left dot_blue fl">
          <div class="font_top">次高</div>
          <div class="font_bottom">{{secondInfoCount||'0'}}</div>
        </div>
        <div class="list_right fl">
          <div class="font_top">{{secondMainDemoName}}</div>
          <div class="font_bottom">已上 <span>{{secondInfoOnClass||0}}</span> 节课
          </div>
        </div>
      </div>
      <div class="statistics_echarts_right_list">
        <div class="list_left dot_yellow fl">
          <div class="font_top">最低</div>
          <div class="font_bottom">{{lastInfoCount||'0'}}</div>
        </div>
        <div class="list_right fl">
          <div class="font_top">{{lastInfoMainDemoName}}</div>
          <div class="font_bottom">已上 <span>{{lastInfoAlreadyCount||0}}</span> 节课
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'teacherStatistics',
  data () {
    return {

    };
  },
  props: ['lastInfo', 'statisticsList', 'formTitle', 'type'],
  computed: {
    // 返回门店信息
    topMainDemoName () {
      if (this.statisticsList.length > 0) {
        return this.statisticsList[0].TeacherKeyValue;
      } else {
        return '';
      }
    },
    // 最高信息
    topInfoCount () {
      if (this.statisticsList.length > 0) {
        if (this.type == 'attenType') {
          return this.statisticsList[0].attence2Rate + '%';
        } else {
          return Number(this.statisticsList[0].totalClassCount);
        }
      } else {
        return '';
      }
    },
    topInfoOnClass () {
      if (this.statisticsList.length > 0) {
        return Number(this.statisticsList[0].HavedClass);
      } else {
        return '';
      }
    },
    // 第二信息
    secondMainDemoName () {
      if (this.statisticsList.length > 1) {
        return this.statisticsList[1].TeacherKeyValue;
      } else {
        return '';
      }
    },
    secondInfoCount () {
      if (this.statisticsList.length > 1) {
        if (this.type == 'attenType') {
          return this.statisticsList[1].attence2Rate + '%';
        } else {
          return Number(this.statisticsList[1].totalClassCount);
        }
      } else {
        return '';
      }
    },
    secondInfoOnClass () {
      if (this.statisticsList.length > 1) {
        return Number(this.statisticsList[1].HavedClass);
      } else {
        return '';
      }
    },
    // 最低信息.
    lastInfoCount () {
      if (this.lastInfo) {
        if (this.type == 'attenType') {
          return this.lastInfo.attence2Rate + '%';
        } else {
          return Number(this.lastInfo.totalClassCount);
        }
      } else {
        return '';
      }
    },
    // 最低已上节课
    lastInfoAlreadyCount () {
      if (this.lastInfo) {
        return Number(this.lastInfo.HavedClass);
      } else {
        return '';
      }
    },
    lastInfoMainDemoName () {
      if (this.lastInfo) {
        return this.lastInfo.TeacherKeyValue;
      } else {
        return '';
      }
    }

  }
};
</script>

