<template>
  <div class="particular_list">
    <div class="particular_list_front">
      <div class="particular_list_front_title">
        <!-- 列表第一个图标 -->
        <div class="particular_list_front_title_img particular_list_front_title_img_text">
          <!-- <div class=""> -->
          {{ item.ShowOrder >= 10 ? item.ShowOrder : "0" + item.ShowOrder }}
          <!-- </div> -->
        </div>
        <!-- 列表第一个文本 -->
        <el-tooltip effect="light"
                    :content="item.MainDemoName"
                    placement="top-end">
          <div class="particular_list_front_title_text"
               :style="vIsAcademicMd ? '' : ''">
            {{ item.MainDemoName }}
          </div>
        </el-tooltip>
      </div>
      <!-- 最近备课部分 -->
      <div class="particular_list_front_content"
           :style="vIsAcademicMd ? 'left: 315px;' : ''">
        <img v-if="!(vRoleKey || vEditionKey)"
             class="particular_list_img"
             src="../../../../../public/cw-img/prepare_lessons.png"
             alt="" />
        <div v-if="item.PrepareLessonsTime != ''"
             class="particular_list_front_content_text">
          <span v-if="vRoleKey || vEditionKey">
            <!-- 最近预览 ： -->
          </span>
          <span v-else>最近上课 ：</span>
          <div v-if="vRoleKey || vEditionKey"></div>
          <div v-else
               style="color: #000; margin-top: 1px">
            {{ item.PrepareLessonsTime }}
          </div>
        </div>
        <div v-else
             :class="!(vRoleKey || vEditionKey) ? 'prepareType' : ''">
          <div style="color: #ff6c67">
            <span v-if="vRoleKey || vEditionKey">
              <!-- 未预览 -->
            </span>
            <span v-else>未上课</span>
          </div>
        </div>
      </div>
      <div class="particularBtn"
           style="margin-right:10px;width:60px"
           :class="(vRoleKey || vEditionKey)?'marginlft':''"
           @click="prepare"
           v-if="maxCoursewarePackage.IsPCAuthKey == 1">
        <span v-if="vRoleKey || vEditionKey">预览</span>
        <span v-else>上课</span>
      </div>
    </div>
    <!-- 最近上课部分 -->
    <!-- <div class="particular_list_back"
         v-if="!vIsAcademicMd">
      <img class="particular_list_img"
           src="../../../../../public/cw-img/attend_class.png"
           alt="" />
      <div style="display: flex; flex: 1">
        <div v-if="item.CourseCount > 0"
             class="particular_list_back_text">
          <div style="display: flex; color: #999;height: 50px;line-height: 70px;">
            最近选班上课 ：
            <div style="color: #000; margin-top: 1px;">
              {{ item.RecentlyClassTime }}
            </div>
          </div>
          <div style="margin-left: 100px">已上{{ item.CourseCount }}课次</div>
        </div>
        <div v-else
             class="prepareType">
          <div style="color: #ff6c67">未选班上课</div>
        </div>
      </div>
      <div v-if="
          item.ScheduleCourseKey == 0 && maxCoursewarePackage.IsPCAuthKey == 1
        "
           class="particularBtn"
           style="margin-right:10px;width:60px"
           @click="attendClass">
        选班上课
      </div>
      <div v-else-if="
          item.ScheduleCourseKey != 0 && maxCoursewarePackage.IsPCAuthKey == 1
        "
           class="beClassType pr"
           @click.stop="isShowdropDownBox = !isShowdropDownBox">
        <div style="color: #ed9908">
          正在上课
        </div>
        <div class="module_content_menuList"
             :style="{ top: '30px', left: '-20px' }"
             v-show="isShowdropDownBox"
             @mouseover.stop="isShowdropDownBox = true">
          <div class="top_bg"></div>
          <drop-down-box :optionJsonList="menuList"
                         @clickOptionForAlertDialog="clickAttendanceOption"></drop-down-box>
        </div>
      </div>
    </div> -->
    <custom-dialog :title="'申请设备授权'"
                   :visible.sync="showAuth"
                   :before-close="closeAuthDialog">
      <apply-auth :dataInfo="authDataInfo"
                  @closeDialog="closeAuthDialog"
                  @afterSuccess="updateData"></apply-auth>
    </custom-dialog>
  </div>
</template>

<script>
import {
  CWSTeacherGiveLectureFinishScheduleCourse,
  CWSTeacherGiveLectureSetUpScheduleCourse
} from '../../../../API/workbench';
import dropDownBox from '../../../common/drop-down-box';
import applyAuth from '../report/auth-components/apply-auth';
export default {
  props: {
    role: Boolean, // true为校长 ，false为老师
    item: Object, // 传入的子课包数据
    maxCoursewarePackage: Object, // 全部课包详情
    CoursewarePackage: Object, // 子课包
    TeacherID: [Number, String], // 老师ID
    Source: [String, Number]
  },
  components: {
    dropDownBox,
    applyAuth
  },
  data () {
    return {
      atPresentTeacher: '', // 当前老师ID
      isEditPlan: false,
      menuList: [
        {
          name: '继续上课',
          type: 1,
          isShow: true
        },
        {
          name: '停止上课',
          type: 2,
          isShow: true
        }
      ],
      isShowdropDownBox: false,
      showAuth: false, // 显示授权弹出层
      authDataInfo: null,
      chooseScheduleCourseKey: '',
      chooseSaaSClientKey: '',
      chooseType: '',
      chooseCoursewareInfoType: '',
      openClassData: [],
      IsContinueKey: 0,
      IsAuthKey: 0,
      busClassLocationEvent: null
    };
  },
  computed: {
    vIsAcademicMd () {
      if (
        this.$store.getters.SaaSClientEditionKey == 2 ||
        this.$store.getters.SaaSClientEditionKey == 3
      ) {
        return true;
      } else {
        return false;
      }
    },
    vRoleKey () {
      // 校长                                                自研
      if (this.$store.getters.token &&
        Number(this.$store.getters.token.RoleKey) == 2 &&
        this.$store.getters.AcademicMdCwType == 2
      ) {
        if (this.Source == 2) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    vEditionKey () {
      if (this.$store.getters.token && this.Source == 2 && Number(this.$store.getters.token.RoleKey) == 2) {
        return true;
      } else {
        return false;
      }
    }
  },
  created () {
    console.log(
      'CoursewarePackage',
      this.CoursewarePackage,
      this.maxCoursewarePackage
    );
    console.log(
      'this.$store.getters.SaaSClientInfo.EditionKey',
      this.$store.getters.token.RoleKey,
      this.$store.getters.AcademicMdCwType,
      this.$store.getters.SaaSClientEditionKey
    );
    this.getAtPresent();

    document.querySelector('body').addEventListener(
      'click',
      e => {
        this.isShowdropDownBox = false;
      },
      false
    );
    this.busClassLocationEvent = res => {
      if (this.chooseType == 1) {
        this.$router.push({
          name: 'coursewareTeachingView',
          query: {
            type: this.chooseType,
            scheduleCourseID: this.chooseScheduleCourseKey,
            CoursewareInfoType: this.chooseCoursewareInfoType,
            Accuracy: this.maxCoursewarePackage.Accuracy,
            IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

          }
        });
      } else if (this.chooseType == 10) {
        this.openClassData.Longitude = res.Longitude;
        this.openClassData.Latitude = res.Latitude;
        console.log('this.openClassData', this.openClassData);
        CWSTeacherGiveLectureSetUpScheduleCourse(
          this.openClassData,
          this.IsContinueKey,
          this.IsAuthKey
        )
          .then(result => {
            console.log(result, '上课返回数据');
            // this.$emit("afterSuccess");
            // this.$emit("closeDialog");
            if (result.data.ScheduleCourseKey) {
              setTimeout(() => {
                this.$router.push({
                  name: 'coursewareTeachingView',
                  query: {
                    type: 1,
                    scheduleCourseID: result.data.ScheduleCourseKey,
                    Accuracy: this.maxCoursewarePackage.Accuracy,
                    IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

                  }
                });
              }, 500);
            } else {
              console.log(123456456);
              layer.alert(error.msg);
            }
          })
          .catch(error => {
            console.log(123456456);
            if (error.code == 80001) {
              this.isAuthCourseware();
            } else if (error.code == 80002) {
              layer.confirm(error.msg, {
                btn: [
                  {
                    name: '取消',
                    callBack: function () {
                      this.IsContinueKey = 0;
                      layer.close();
                      if (callBack) {
                        callBack();
                      }
                    }
                  },
                  {
                    name: '确认',
                    callBack: () => {
                      this.IsContinueKey = 1;
                      CWSTeacherGiveLectureSetUpScheduleCourse(
                        this.openClassData,
                        this.IsContinueKey,
                        this.IsAuthKey
                      )
                        .then(res => {
                          if (res.data.ScheduleCourseKey) {
                            setTimeout(() => {
                              this.$router.push({
                                name: 'coursewareTeachingView',
                                query: {
                                  type: 1,
                                  scheduleCourseID: res.data.ScheduleCourseKey,
                                  Accuracy: this.maxCoursewarePackage.Accuracy,
                                  IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

                                }
                              });
                            }, 500);
                          } else {
                            layer.alert(error.msg);
                          }
                        })
                        .catch(error => {
                          if (error.code == 80001) {
                            this.isAuthCourseware();
                          } else {
                            layer.alert(error.msg);
                          }
                        });
                      layer.close();
                    }
                  }
                ]
              });
            } else {
              layer.alert(error.msg);
            }
          });
      } else if (this.chooseType == 2) {
        setTimeout(() => {
          this.$router.push({
            name: 'coursewareTeachingView',
            query: {
              type: this.chooseType,
              coursewareId: this.coursewareIdforOpen,
              // authCoursewareID:this.chooseSaaSClientKey,
              coursewareInfoType: this.chooseCoursewareInfoType,
              Accuracy: this.maxCoursewarePackage.Accuracy,
              IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

            }
          });
        }, 500);
      } else if (this.chooseType == 4) {
        setTimeout(() => {
          this.$router.push({
            name: 'coursewareTeachingView',
            query: {
              type: this.chooseType,
              coursewareId: this.coursewareIdforOpen,
              // authCoursewareID:this.chooseSaaSClientKey,
              coursewareInfoType: this.chooseCoursewareInfoType,
              Accuracy: this.maxCoursewarePackage.Accuracy,
              IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

            }
          });
        }, 500);
      }
      setTimeout(() => {
        this.$emit('getPrincipalWorkbenchGetCoursewareList');
      }, 500);
    };
  },

  methods: {
    // 进入备课模式
    prepare () {
      console.log(
        '去备课',
        this.item.OLAPKey,
        this.CoursewarePackage,
        this.Source
      );
      if (!this.$zxClient.IsClient) {
        layer.alert('请用桌面端打开程序');
      } else {
        if (this.maxCoursewarePackage.DeviceIsAuth != 1) {
          layer.alert('设备未授权');
        } else {
          if (this.$store.getters.SaaSClientEditionKey == 6) {
            if (this.role) {
              if (this.Source == 1 || this.Source == 2) {
                // 授权
                let viewType = this.Source == 1 ? 'SQ' : 'ZZ';
                if (
                  this.maxCoursewarePackage.PCIsLocationLockKey == 1 &&
                  !this.$store.getters.geoInfo.Longitude
                ) {
                  this.openAuthDialog(this.item.OLAPKey, viewType, 2, 12);
                } else {
                  let newdataInfo = {
                    ScheduleCourseKey: this.item.ScheduleCourseKey || '',
                    courserId: this.CoursewarePackage.OLAPKey,
                    client_id: this.$store.getters.clientId,
                    SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
                    DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
                    introType: 12,
                    DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
                  };
                  this.$utils.verifySystemLocation(
                    newdataInfo,
                    success => {
                      this.$router.push({
                        name: 'coursewareTeachingView',
                        query: {
                          type: 2,
                          coursewareId: this.item.OLAPKey,
                          coursewareInfoType: viewType,
                          Accuracy: this.maxCoursewarePackage.Accuracy,
                          IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

                        }
                      });
                      setTimeout(() => {
                        this.$emit('getPrincipalWorkbenchGetCoursewareList');
                      }, 500);
                    },
                    error => {
                      this.openAuthDialog(this.item.OLAPKey, viewType, 2, 12);
                    }
                  );

                  console.log('12ssss34544444');
                }
              }
            } else {
              if (
                this.maxCoursewarePackage.PCIsLocationLockKey == 1 &&
                !this.$store.getters.geoInfo.Longitude
              ) {
                this.openAuthDialog(this.item.OLAPKey, '', 4, 12);
              } else {
                console.log('12ssss345611112222');
                let newdataInfo = {
                  ScheduleCourseKey: this.item.ScheduleCourseKey || '',
                  courserId: this.CoursewarePackage.OLAPKey,
                  client_id: this.$store.getters.clientId,
                  SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
                  DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
                  introType: 12,
                  DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
                };
                this.$utils.verifySystemLocation(
                  newdataInfo,
                  success => {
                    this.$router.push({
                      name: 'coursewareTeachingView',
                      query: {
                        type: 4,
                        coursewareId: this.item.OLAPKey,
                        Accuracy: this.maxCoursewarePackage.Accuracy,
                        IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

                      }
                    });
                    setTimeout(() => {
                      this.$emit('getPrincipalWorkbenchGetCoursewareList');
                    }, 500);
                  },
                  error => {
                    this.openAuthDialog(this.item.OLAPKey, '', 4, 12);
                  }
                );
              }
            }
          } else {
            if (this.Source == 1 || this.Source == 2) {
              let viewType = this.Source == 1 ? 'SQ' : 'ZZ';
              // 授权
              if (
                this.maxCoursewarePackage.PCIsLocationLockKey == 1 &&
                !this.$store.getters.geoInfo.Longitude
              ) {
                this.openAuthDialog(this.item.OLAPKey, viewType, 2, 22);
              } else {
                console.log('12ssss3456');
                let newdataInfo = {
                  ScheduleCourseKey: this.item.ScheduleCourseKey || '',
                  courserId: this.CoursewarePackage.OLAPKey,
                  client_id: this.$store.getters.clientId,
                  SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
                  DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
                  introType: 22,
                  DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
                };
                this.$utils.verifySystemLocation(
                  newdataInfo,
                  success => {
                    this.$router.push({
                      name: 'coursewareTeachingView',
                      query: {
                        type: 2,
                        coursewareId: this.item.OLAPKey,
                        coursewareInfoType: viewType,
                        Accuracy: this.maxCoursewarePackage.Accuracy,
                        IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

                      }
                    });
                    setTimeout(() => {
                      this.$emit('getPrincipalWorkbenchGetCoursewareList');
                    }, 500);
                  },
                  error => {
                    this.openAuthDialog(this.item.OLAPKey, viewType, 2, 22);
                  }
                );
              }
            }
          }
        }
      }
    },
    // 开始上课
    attendClass () {
      console.log('去上课');
      if (!this.$zxClient.IsClient) {
        layer.alert('请用桌面端打开程序');
      } else if (this.maxCoursewarePackage.DeviceIsAuth != 1) {
        layer.alert('设备未授权');
      } else {
        // if(this.maxCoursewarePackage.HQAuthKey!=0){
        //   let text=this.maxCoursewarePackage.HQAuthKey==1?'  当前门店定位，课件授权方禁止访问课件':' 当前门店定位，课件授权方正在审核中，暂时无法访问课件'
        //   layer.alert(text)
        //   return false
        // }
        this.doViewCoursewareDetails();
      }
    },
    // 打开上课弹窗
    doViewCoursewareDetails () {
      this.item.ParentKey = this.CoursewarePackage.OLAPKey;
      this.$dialog.open(this, {
        name: '开课',
        routerName: this.$route.name,
        ModulePowerKey: '',
        moduleName: 'cwsGoToClass',
        data: {
          dataInfo: {
            item: this.item,
            PCIsLocationLockKey: this.maxCoursewarePackage.PCIsLocationLockKey,
            courserId: this.CoursewarePackage.OLAPKey,
            Longitude: this.$store.getters.geoInfo.Longitude,
            Latitude: this.$store.getters.geoInfo.Latitude,
            HQAuthKey: this.maxCoursewarePackage.HQAuthKey
          }
        },
        callBack: {
          afterSuccess: data => {
            this.$emit('getPrincipalWorkbenchGetCoursewareList');
          }
        }
      });
    },
    addClassGradeForTeacher (data) {
      this.chooseType = 10; // bus事件判断 开课时
      this.openClassData = data;
      this.openAuthDialogforOpenClass(data.CoursewareKey);
    },
    // 继续上课
    goOnAttendClass () {
      let isAuthContiune = false;
      console.log('继续上课', this.item);
      const that = this;
      if (this.item.MTeacherKey != this.$store.getters.token.UserID) {
        layer.alert('您不是当前的主讲老师，无法上课');
        return false;
      }
      if (!this.$zxClient.IsClient) {
        layer.alert('请使用桌面端');
      } else if (that.maxCoursewarePackage.DeviceIsAuth != 1) {
        layer.alert('设备未授权');
      } else {
        if (this.maxCoursewarePackage.PCIsLocationLockKey == 1 && this.maxCoursewarePackage.HQAuthKey != 0) {
          let text = this.maxCoursewarePackage.HQAuthKey == 1 ? '  当前门店定位，课件授权方禁止访问课件' : ' 当前门店定位，课件授权方正在审核中，暂时无法访问课件';
          layer.alert(text);
          return false;
        } else {
          layer.confirm('是否继续上课', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  if (
                    this.maxCoursewarePackage.PCIsLocationLockKey == 1 &&
                    !this.$store.getters.geoInfo.Longitude
                  ) {
                    this.openAuthDialog(that.item.ScheduleCourseKey, 'ZZ', 1, 11);
                  } else {
                    this.$router.push({
                      name: 'coursewareTeachingView',
                      query: {
                        type: 1,
                        scheduleCourseID: that.item.ScheduleCourseKey, // 正在上课的Key
                        Accuracy: this.maxCoursewarePackage.Accuracy,
                        IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

                      }
                    });
                  }

                  // }
                  // 取消事件
                  layer.close();
                }
              }
            ]
          });
        }
      }
    },
    // 停止上课
    stopGoOnAttendClass () {
      console.log('停止上课');
      layer.confirm('是否停止上课', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              // 点击确定调用接口
              CWSTeacherGiveLectureFinishScheduleCourse(
                this.item.ScheduleCourseKey
              ).then(result => {
                this.$emit('getPrincipalWorkbenchGetCoursewareList');
              });
              // 取消事件
              layer.close();
            }
          }
        ]
      });
    },
    clickAttendanceOption (item) {
      if (item.type == 1) {
        this.isShowdropDownBox = false;
        if (this.maxCoursewarePackage.HQAuthKey != 0) {
          let text = this.maxCoursewarePackage.HQAuthKey == 1 ? '  当前门店定位，课件授权方禁止访问课件' : ' 当前门店定位，课件授权方正在审核中，暂时无法访问课件';
          layer.alert(text);
          return false;
        }
        this.goOnAttendClass();
      } else {
        this.stopGoOnAttendClass();
        this.isShowdropDownBox = false;
      }
    },
    // 获取当前老师ID
    getAtPresent () {
      this.atPresentTeacher = this.$store.getters.token.UserID;
      console.log(this.atPresentTeacher, '当前老师');
      console.log(this.item, '传入的子课包数据');
      console.log(this.maxCoursewarePackage, '全部课包xinxi');
      console.log(this.$store.getters, '当前用户信息');
    },
    closeAuthDialog () {
      this.showAuth = false;
    },
    updateData () {
      this.closeAuthDialog();
      // this.verifyAuthorize();
    },
    openAuthDialog (OLAPKey, type, model, introType) {
      console.log(
        'openAuthDialog maxCoursewarePackage',
        this.maxCoursewarePackage
      );
      if (this.maxCoursewarePackage.HQAuthKey != 0) {
        let text = this.maxCoursewarePackage.HQAuthKey == 1 ? '  当前门店定位，课件授权方禁止访问课件' : ' 当前门店定位，课件授权方正在审核中，暂时无法访问课件';
        layer.alert(text);
        return false;
      }
      this.chooseScheduleCourseKey = '';
      this.coursewareIdforOpen = OLAPKey;
      this.chooseType = model;
      this.chooseCoursewareInfoType = type;
      let skey = '';
      if (model == 1) {
        this.chooseScheduleCourseKey = OLAPKey;
        skey = OLAPKey;
      }
      if (this.maxCoursewarePackage.PCIsLocationLockKey == 1) {
        console.log('courseAuthQrcode');
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '安全验证',
          moduleName: 'courseAuthQrcode',
          data: {
            dataInfo: {
              ScheduleCourseKey: skey,
              courserId: this.CoursewarePackage.OLAPKey,
              client_id: this.$store.getters.clientId,
              SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
              DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
              introType: introType,
              DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
            }
          },
          callBack: {
            afterSuccess: data => {
              this.busClassLocationEvent(data);
            }
          }
        });
      }
    },
    // 开课调用
    openAuthDialogforOpenClass () {
      console.log('openAuthDialog111', this.CoursewarePackage.OLAPKey);
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '安全验证',
        moduleName: 'courseAuthQrcode',
        data: {
          dataInfo: {
            ScheduleCourseKey: 0,
            client_id: this.$store.getters.clientId,
            SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
            DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
            introType: 11,
            CourseWarePackageKey: this.CoursewarePackage.OLAPKey,
            DeviceCode: this.$zxClient.GetMCode() || '' // 桌面端设备码
          }
        },
        callBack: {
          afterSuccess: data => {
            this.busClassLocationEvent(data);
          }
        }
      });
      setTimeout(() => {
        this.$emit('getPrincipalWorkbenchGetCoursewareList');
      }, 500);
    },
    isAuthCourseware () {
      layer.confirm(
        '您还没有该课包的授权，如果继续，将会自动将该课包授权给您，是否继续?',
        {
          btn: [
            {
              name: '取消',
              callBack: function () {
                this.IsAuthKey = 0;
                layer.close();
                if (callBack) {
                  callBack();
                }
              }
            },
            {
              name: '确认',
              callBack: () => {
                this.IsAuthKey = 1; // 是否自动授权
                CWSTeacherGiveLectureSetUpScheduleCourse(
                  this.openClassData,
                  this.IsContinueKey,
                  this.IsAuthKey
                ).then(result => {
                  if (result.data.ScheduleCourseKey) {
                    this.$router.push({
                      name: 'coursewareTeachingView',
                      query: {
                        type: 1,
                        coursewareId: result.data.ScheduleCourseKey,
                        Accuracy: this.maxCoursewarePackage.Accuracy,
                        IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

                      }
                    });
                  } else {
                    layer.alert(error.msg);
                  }
                });
                layer.close();
              }
            }
          ]
        }
      );
    },
    openPop (msg) {
      layer.alert(msg);
    }
  }
};
</script>

<style scoped>
.particular_list {
  display: flex;
  align-items: center;
  border: 1px solid #ececec;
  border-radius: 5px;
  width: 884px;
  height: 70px;
  margin-bottom: 10px;
}

.particular_list_front {
  display: flex;
  flex: 1;
  align-items: center;
  /* justify-content: space-between; */
  position: relative;
}
.marginlft {
  margin-left: 170px;
}

.particular_list_img {
  width: 15px;
  height: 15px;
}

.particular_list_front_title {
  display: flex;
  margin-left: 25px;
  align-items: center;
}

.particular_list_front_title_img {
  background: url('../../../../../public/cw-img/Subtract.png') no-repeat;
  background-size: 18px;
  width: 18px;
  height: 18px;
  line-height: 18px;
}

.particular_list_front_title_img_text {
  font-family: 'Arial';
  font-style: normal;
  font-size: 12px;
  color: #6dc4bf;
  padding-left: 3px;
}

.particular_list_front_title_text {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-size: 16px;
  margin: 0 20px 0 10px;
  font-weight: 700;
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
  width: 450px;
  /* flex: 1; */
}

.particular_list_front_content {
  display: flex;
  align-items: center;
  color: #999999;
  padding-right: 100px;
  /* position: absolute; */
  /* left: 255px; */
}

.particularBtn {
  color: #3498db;
  cursor: pointer;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  width: 30px;
}

.particular_list_front_content_text {
  display: flex;
  font-family: 'Arial';
  font-style: normal;
  margin-left: 10px;
}

.particular_list_back {
  display: flex;
  width: 340px;
  align-items: center;
  justify-content: space-between;
}

.particular_list_back_text {
  display: flex;
  flex: 1;
  margin-left: 10px;
  height: 70px;
  flex-direction: column;
}

.prepareType {
  border: 1px solid #ffe2e1;
  height: 20px;
  width: 77px;
  line-height: 20px;
  text-align: center;
  margin: 0 70px 0 10px;
  background-color: #ffe2e1;
  border-radius: 4px;
  font-family: 'Arial';
  font-style: normal;
  font-size: 12px;
}

.beClassType {
  border: 1px solid #ffedce;
  height: 20px;
  width: 80px;
  line-height: 20px;
  text-align: center;
  margin: 0 5px 0 0px;
  background-color: #ffedce;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Arial';
  font-style: normal;
  font-size: 12px;
}
</style>
