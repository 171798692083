<template>
  <div>
    <!-- 学生验证提示 -->
    <custom-dialog title="验证学生说的"
                   class="bg_white defult_dialog"
                   :visible.sync="isStudentVerifyPopup"
                   :before-close="isChangeFromHide">

      <div style="background: white">
        <div class="set_class_time_title"
             style="padding: 15px 15px; margin: 0;"
             v-if="studentData.RegisterTypeKeyValue">
          注册用户
        </div>
        <div class="verify_student_lable"
             :style="selectedStudentInfo?'':'margin-bottom: 100px'">
          <div class="verify_student_img fl">
            <heads-img :imgUrl="newStudentData.CusHeadImg"
                       :dataInfo="newStudentData"></heads-img>
          </div>
          <div class="verify_student_info fl">
            <div class="verify_student_name">{{studentData.CustKeyValue}}</div>
            <div class="verify_student_phone">{{$utils.phoneModulePower(studentData.MobileSupport)}}</div>
          </div>
        </div>
        <!-- 验证学生 -->
        <div v-if="!selectedStudentInfo">
          <div class="btn_fixed_bottom"
               style="margin-bottom: 2px">
            <function-btn :item="addbtn"
                          @clickItem="confirmStudentVerifyForm"
                          :className="'btn_fixed_blue btn_hover_bg_blue'">确认为新生
            </function-btn>
            <function-btn :item="addbtn"
                          @clickItem="studentChange"
                          :className="'btn_fixed_blue btn_hover_bg_blue'">合并为老生
            </function-btn>
          </div>
        </div>
        <!-- 合并学生 -->
        <div v-if="selectedStudentInfo">
          <div class="set_class_time_title"
               style="padding: 15px 15px;margin: 0;">
            合并老生
          </div>
          <div class="verify_student_lable">
            <student-lable ref="studentLable"
                           :styleInfo="'background-color: #f6f8f9;border-radius: 8px'"
                           :studentID="selectedStudentInfo.OLAPKey"
                           :dropDownBoxLeft="290"
                           :showExamine="false"
                           @alterStudent="selectedItem"
                           :showMenu="true"></student-lable>
          </div>
          <div class="set_class_time_title"
               style="padding: 15px 15px;margin: 0;">
            新手机号
          </div>
          <div class="verify_student_from pr">
            <div class="verify_student_from_list">
              <div class="list_content tl"
                   style="padding-left: 55px;">{{$utils.phoneModulePower(studentData.MobileSupport)}}</div>
            </div>
          </div>
          <div class="btn_fixed_bottom"
               style="margin-bottom: 2px">
            <div class="btn_fixed_white btn_hover_white"
                 @click="cancel()">取消</div>
            <function-btn :item="addbtn"
                          @clickItem="merge"
                          :className="'btn_fixed_blue btn_hover_bg_blue'">
              确认合并
            </function-btn>
          </div>
        </div>
      </div>

    </custom-dialog>
    <!-- 学生列表 -->
    <custom-dialog title="选择学生"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isStudentListShow"
                   :before-close="studentFromHide">
      <student-list :switchItem="0"
                    :IsConfirmKey="1"
                    :type="'merge'"
                    @selectedItem="selectedItem"></student-list>
    </custom-dialog>
  </div>
</template>
<script>
import formSelect from '../form-item/form-select';
import studentList from '../../components/common/student-list';
import {
  getStudentRelatedMsg,
  StudentsMerge,
  ConfirmedStudentsMerge
} from '../../API/workbench.js';
export default {
  name: 'selectedClassInfo',
  props: ['studentKey', 'Isverify', 'isEmit'],
  data () {
    return {
      isStudentVerifyPopup: false,
      studentData: {}, // 表单需要的学生信息
      newStudentData: {}, // 拷贝一份学生信息，用于判断学生是否有过修改
      // isChangeFrom: this.Isverify != 0, // 切换学生列表和合并表单
      isStudentListShow: false,
      ISsubmit: true, // 防止多次点击
      isreadOnly: false,
      newStudentKey: '', // 学生ID
      mergeJsonData: {},
      customerRelationship: [
        { key: 0, value: '家长' },
        { key: 2, value: '爸爸' },
        { key: 3, value: '妈妈' },
        { key: 11, value: '本人' },
        { key: 10, value: '其他' }
      ],
      sexData: [
        { key: 2, value: '男' },
        { key: 3, value: '女' }
      ],
      selectedStudentInfo: '', // 选择被合并的学生信息
      addbtn: {
        name: '',
        type: 'tjxs',
        ModulePowerKey: 51
      },
      pickerOptions: {
        disabledDate: (time) => {
          let nowData = Date.new();
          // nowData = Date.new(nowData.setDate(nowData.getDate() - 1));
          return time > nowData;
        }
      }
    };
  },
  components: {
    studentList,
    formSelect,
    // studentLable
    studentLable: () => import('./student-lable') // 异步注册组件
  },

  created () {
    this.newStudentKey = this.studentKey;
  },
  mounted () {
  },
  watch: {
    // 学生ID
    studentKey: {
      handler (newName, oldName) {
        this.newStudentKey = newName;
      },
      immediate: true
    }
    // 'studentData.BirthdayTime': {
    //   handler (newName, oldName) {
    //     if (!this.studentData.BirthdayTime && this.studentData.AgeName == 0) {
    //       this.studentData.AgeName = '无年龄';
    //     } else {
    //       // if (!this.studentData.BirthdayTime) {
    //       //   this.studentData.AgeName = '无年龄';
    //       // } else {
    //       var dd = Date.new().getTime() - Date.new(this.studentData.BirthdayTime).getTime();
    //       this.studentData.AgeName = Math.floor(dd / 1000 / 24 / 3600 / 365);
    //       // }
    //     }
    //   },
    //   immediate: true
    // }
  },
  methods: {
    // selectStundent () {
    //   this.$emit('selectStundent');
    // },
    // 验证学生
    studentVerifyForm (studentKey) {
      if (studentKey) {
        this.newStudentKey = studentKey;
      }
      if (Number(this.newStudentKey) > 0) {
        this.isStudentVerifyPopup = true;
        this.getstudentData(this.newStudentKey); // 默认没有
      }
      console.log(this.newStudentKey, '开始验证');
    },
    // 获取学生信息，需要一个学生ID
    getstudentData (studentKey) {
      getStudentRelatedMsg(studentKey).then(result => {
        this.newStudentData = this.$utils.parseJson(result.data);
        this.studentData = result.data;
        this.isStudentVerifyPopup = true;
        console.log(this.studentData, 'result.data');
      }, error => {
        layer.alert(error.msg);
      }
      );
    },
    // 关闭合并表单
    isChangeFromHide () {
      this.isStudentVerifyPopup = false;
      this.ISsubmit = true;
      this.studentData = {};
      this.selectedStudentInfo = '';
    },
    // 关闭-选择的合并学生列表
    studentFromHide () {
      this.isStudentListShow = false;
    },
    // 确认学生
    confirmStudentVerifyForm () {
      ConfirmedStudentsMerge(this.studentData.OLAPKey).then(result => {
        this.isStudentVerifyPopup = false;
        this.$emit('update');
        if (!this.isEmit) {
        }
        layer.alert('学生验证成功');
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    // 去列表选择被合并的学生
    studentChange () {
      if (
        !this.studentData.MainDemoName ||
        !this.studentData.MainDemoName.replace(/(^\s*)|(\s*$)/g, '')
      ) {
        layer.alert('学生姓名不能为空');
      } else {
        this.isStudentListShow = true;
      }
    },
    // 选中学生项合并
    selectedItem (item) {
      console.log(item, '学生');
      this.selectedStudentInfo = item;
      this.isStudentListShow = false;
      this.ISsubmit = true;
    },
    alterStudent (data) { // GOTO
      console.log(data, 'alterStudent');
      this.$emit('alterStudent', data);
    },
    // 选择关系
    changeRelationship (val) {
      this.studentData.CustomerRelationshipKey = val.key;
      this.studentData.CustomerRelationshipKeyValue = val.value;
    },
    changeSex (val) {
      this.studentData.SexKey = val.key;
      this.studentData.SexKeyValue = val.value;
    },
    getAgeName (val) {
      console.log(val, 'getAgeName');
      this.studentData.AgeName = val;
    },
    // 选择生日
    chgBirthdayTime (val) {
      this.studentData.BirthdayTime = val ? val.toLocaleDateString() : '';
    },
    // 合并（验证）
    merge () {
      console.log(this.ISsubmit, 'result');
      if (this.selectedStudentInfo) {
        if (this.studentData.AgeName == '无年龄') {
          this.studentData.AgeName = 0;
        }
        if (this.studentData.OLAPKey == this.selectedStudentInfo.OLAPKey) {
          layer.alert('选择已验证的学生合并时， 不能为同一个学生');
        } else if (this.selectedStudentInfo.IsConfirmKey == 0) {
          layer.alert('请选择已验证的学生，进行合并');
        } else {
          // 以老生优先;
          this.mergeJsonData.StuKey = this.selectedStudentInfo.OLAPKey;
          this.mergeJsonData.MainDemoName = this.selectedStudentInfo.MainDemoName;
          this.mergeJsonData.CustKey = this.selectedStudentInfo.CustKey;
          this.mergeJsonData.CustKeyValue = this.selectedStudentInfo.CustKeyValue;
          this.mergeJsonData.SexKey = this.selectedStudentInfo.SexKey;
          this.mergeJsonData.SexKeyValue = this.selectedStudentInfo.SexKeyValue;
          this.mergeJsonData.CustomerRelationshipKey = this.selectedStudentInfo.RelationKey;
          this.mergeJsonData.CustomerRelationshipKeyValue = this.selectedStudentInfo.RelationKeyValue;
          // 以新生优先;
          this.mergeJsonData.AgeName = Number(this.studentData.AgeName) > 0 ? this.studentData.AgeName : (Number(this.selectedStudentInfo.AgeName) > 0 ? this.selectedStudentInfo.AgeName : '无年龄');
          this.mergeJsonData.BirthdayTime = this.studentData.BirthdayTime ? this.studentData.BirthdayTime : this.selectedStudentInfo.BirthdayTime;
          this.mergeJsonData.MobileSupport = this.studentData.MobileSupport;
          if (this.ISsubmit == true) {
            this.ISsubmit = false;
            StudentsMerge(
              this.studentData.OLAPKey,
              this.selectedStudentInfo.OLAPKey,
              this.mergeJsonData
            ).then(response => {
              console.log(response, 'result');
              this.isChangeFrom = false;
              this.$emit('update');
              this.$bus.emit('AfterStudentUpdate', this.mergeJsonData.StuKey);
              this.cancel();
              layer.alert('学生合并成功');
            }).catch(err => {
              this.ISsubmit = true;
              layer.alert(err.msg);
            });
          }
        }
      } else {
        layer.alert('请先选择需要合并的学生');
      }
    },
    // 取消
    cancel () {
      this.isChangeFromHide();
    }
  },
  computed: {

  }
};
</script>
<style>
.new_no_border_bottom {
  border-bottom: none !important;
  padding: 0px 10px 0px 30px !important;
  min-height: 30px !important;
}
</style>


