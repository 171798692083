<template>
  <!-- 已 购 课 单 -->
  <div class="courses_purchased_single single_shadow courses1"
       id="Scroll"
       :class="{ checked_right:allStudentCourseItem.isActive}"
       @click="selectedItem(allStudentCourseItem)">
    <div class="courses_purchased_name">
      <span class="courses_name_field point_light_blue">适用课程</span>
      <span class="courses_name_value"
            :title="(allStudentCourseItem.ApplyCorName)?(allStudentCourseItem.ApplyCorName):''">{{(allStudentCourseItem.ApplyCorName)?(allStudentCourseItem.ApplyCorName):""}}</span>
    </div>
    <div class="courses_purchased_time">
      <span class="courses_name_field point_light_green">剩余数量
        <span class="parting_line"> | </span>天数</span>
      <span class="courses_name_value">
        <span v-if="allStudentCourseItem.UnitKey=='5'">{{$utils.mAmountType(CurrentCount,1)}}{{allStudentCourseItem.UnitKeyValue}}</span>
        <span v-else>{{'--'}}</span>
        <span class="parting_line"> | </span>
        <span>{{Number(allStudentCourseItem.PeriodCount)}}</span>天</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'addStudentBtn',
  props: ['allStudentCourseItem', 'allStudentCourseList'], // 需绑定到上面
  data () {
    return {
      isActivestype: false,
      // 测试课单 数据
      // formTypeShow: false,
      courseItemInfo: {}
    };
  },
  created () {
    // var sLeft = document.getElementById('vid').scrollLeft;
    // console.log(sLeft, "滚动")
  },

  mounted () {
    // this.$refs.mybox.addEventListener('scroll', this.handleScroll, true)
    // window.addEventListener('scroll', this.handleScroll, true)
    // document.getElementById('vid').addEventListener('scroll', this.handleScroll)
  },
  watch: {
  },
  methods: {
    // handleScroll () {
    //   var scrollLeft = window.pageYOffset || document.documentElement.scrollLeft || document.getElementById('vid').scrollLeft
    //   console.log(scrollLeft)
    //   // console.log(document.getElementById('vid').scrollLeft)
    // },
    selectedItem (item) {
      this.allStudentCourseList.forEach(o => {
        if (item.TableID == o.TableID) {
          item.isActive = true;
        } else {
          o.isActive = false;
        }
        // var formTypeShow = true;
      });
      this.$emit('selectedItem', item, 'click');
    }
  },
  computed: {
    CurrentCount () {
      return Number(this.allStudentCourseItem.CurrentCount).toFixed(1);
    }
    // isActivestype () {
    //   this.allStudentCourseList.forEach(o => {
    //     if (this.Activestyp.ApplyCorName == o.ApplyCorName) {
    //       o.isActive = true
    //       // this.isActivestype == o.isActive
    //       console.log(o.isActive, "高亮+1")
    //     } else {
    //       o.isActive = false
    //     }
    //   })
    //   return this.Activestyp
    // }
  }
};
</script>
<style>
/* .courses1 {
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
} */
</style>
