<template>
  <div class="form_info_detail">
    <!-- 搜索选择层 -->
    <div class="new_list_type single_shadow content_marginRL content_marginTop_30">
      <div class="search_form_box">
        <div class="search_form">
          <input type="text"
                 class="search_input"
                 :rows="1"
                 placeholder=""
                 v-model="searchText" />
          <span class="remove_btn"
                v-if="searchText != ''"
                @click.stop="ClearSearchText"></span>
        </div>
      </div>
      <div class="equip_btn">
        <div class="search_result_list"
             :class="{multi:!isSingleMode}">
          <!-- <div @click="cancelData" class="single_result_cancel single_result single_class_info">
            <div class="single_result_info_detail">
              <div class="student_info_name">
                <span>不选择</span>
              </div>
            </div>
          </div>-->
          <multi-select-item v-for="(item,index) in vMutilSelectSourceDate"
                             :key="index"
                             :multipleItem="item"
                             @clickItem="doAfterClickItem"></multi-select-item>
          <div v-if="vMutilSelectSourceDate.length==0"
               class="course_block_nodata">

            <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 按钮组 -->
    <div v-if="!isSingleMode">
      <common-button-group @buttonClickEvent="doAfterClickConfirmBtn"
                           @closeDialog="doAfterClickCancelBtn"></common-button-group>
    </div>
    <div>
    </div>
  </div>
</template>
<script>
import multiSelectItem from './multi-select-item';

export default {
  name: 'mutiSelectList',
  data () {
    return {
      searchText: '',
      selectedItems: { selectedIDs: [], selectedNames: [] },
      baseInfoNames: null // 基础资料的名字集
    };
  },
  props: {
    mutilSelectSourceData: Array,
    selectionData: Object,
    dialogTitleName: String,
    idFieldName: String,
    nameFieldName: String,
    parentIDFieldName: String,
    curModuleName: String,
    selectTypeItem: Object,
    isSingleMode: {
      // true 为单选
      type: Boolean,
      required: false
    }
  },
  components: {
    multiSelectItem
  },
  computed: {
    // 多选数据源 根据选中数据 给数据源添加选中属性
    vMutilSelectSourceDate () {
      let newList = [];
      let trueSearchText = this.searchText.toLowerCase();
      // const PinyinMatch = require('pinyin-match');// 根据字母搜索
      this.mutilSelectSourceData.forEach(o => {
        // var m = PinyinMatch.match(o.MainDemoName, trueSearchText);
        if (
          o.MainDemoName &&
          (o.MainDemoName.toLowerCase().indexOf(trueSearchText) >= 0 ||
            (o.FirstLetterName && o.FirstLetterName.toUpperCase().indexOf(trueSearchText.toUpperCase()) >= 0))
        ) {
          // 查找当前项是否被选中
          let selectedIndex = this.selectedItems.selectedIDs.findIndex(id => {
            return id == o.OLAPKey;
          });
          // 避免污染数据源
          newList.push({ dataItem: o, isActive: selectedIndex >= 0 });
        }
      });
      return newList;
    }
  },
  watch: {
    selectionData (val, c) {
      this.refreshSelectedItmes(val);
    }
  },
  created () {
    this.baseInfoNames = this.$initJson.baseInfoNames;
    this.refreshSelectedItmes(this.selectionData);
  },
  mounted () {
    window.newUserGuideFunClick = (type, callback) => {
      this.newUserGuideFunClick(type, callback);
    };
  },
  methods: {
    // redFont (str, start, end) {
    //   return str.substring(0, start) + '<b style="color: red">' + str.substring(start, end + 1) + '</b>' + str.substring(end + 1);
    // },
    cancelData () {
      this.$emit('buttonClickEvent', { selectedIDs: [], selectedNames: [] });
    },
    // 复制一份选中数据源 并把其转换为数据 以便后面正选反选的添加和移除
    refreshSelectedItmes (newSelectionData) {
      this.selectedItems.selectedIDs = newSelectionData.idText
        ? newSelectionData.idText.split(',')
        : [];
      // this.selectedItems.selectedNames = newSelectionData.nameText ? newSelectionData.nameText.split(',') : [];
    },
    // 点击后 判断当前项是否在选中数据源中 若是移除该项 反之则添加该项
    doAfterClickItem (item) {
      let selectedIndex = this.selectedItems.selectedIDs.findIndex(id => {
        return id == item.OLAPKey;
      });
      if (selectedIndex >= 0) {
        this.selectedItems.selectedIDs.splice(selectedIndex, 1);
      } else {
        this.selectedItems.selectedIDs.push(item.OLAPKey);
      }
      if (this.isSingleMode == true) { // 单选
        this.selectedItems.selectedIDs = [];
        this.selectedItems.selectedNames = [];
        this.selectedItems.selectedIDs.push(item.OLAPKey);
        this.selectedItems.selectedNames.push(item.MainDemoName);
        this.$emit('buttonClickEvent', this.selectedItems);
        this.ClearSearchText();
      }
    },
    // 点击确认保存后
    doAfterClickConfirmBtn () {
      this.selectedItems.selectedNames = [];
      this.selectedItems.selectedIDs.forEach(val => {
        let findItem = this.mutilSelectSourceData.find(o => {
          return o.OLAPKey == val;
        });
        if (findItem) {
          this.selectedItems.selectedNames.push(findItem.MainDemoName);
        }
      });
      this.$emit('buttonClickEvent', this.selectedItems);
      this.ClearSearchText();
    },
    // 点击取消后
    doAfterClickCancelBtn () {
      this.selectedItems.selectedIDs = this.selectionData.idText
        ? this.selectionData.idText.split(',')
        : [];
      this.selectedItems.selectedNames = this.selectionData.nameText
        ? this.selectionData.nameText.split(',')
        : [];
      this.$emit('cancleClickForMutilSelected');
      this.ClearSearchText();
    },
    ClearSearchText () {
      this.searchText = '';
    },
    // 新增
    newClick (callback) {
      this.$nextTick(() => {
        this.$refs.customPopUp.PopUpShow = true;
        if (typeof callback == 'function') {
          setTimeout(() => {
            callback();
          }, 50);
        }
      });
    },
    // 新增成功后
    doAfterAddSuccess (item) {
      console.log('doAfterAddSuccess', item);
      this.mutilSelectSourceData.push(item);
      this.doAfterClickItem(item);
      this.doCancelAddDialog();
    },
    // 新增取消
    doCancelAddDialog () {
      this.$refs.customPopUp.PopUpShow = false;
    },
    newUserGuideFunClick (type, callback) {
      switch (type) {
        case this.$initJson.newUserGuideFunType.beginNewLoopSchedulecourse:
          this.newClick(callback);
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style>
.search_result_list.multi .single_result.single_class_info:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #3498db;
  border-radius: 4px;
  left: 10px;
  margin-top: -11px;
  top: 50%;
  pointer-events: none;
}
</style>


