<template>
  <div class="form_item form-input form_info_line"
       :class="{ form_info_required: required }"> 
    <div class="form_info_field">
      <span>{{formTitle}}</span>
      <span v-if="helpDescription">
        <input-form-tip :tips='helpDescription'></input-form-tip>
      </span>
    </div>
    <div class="form_info_value">
      <span class="form_input_hint"
            v-if="formInputHint">{{formInputHint}}</span>
      <!-- 修改模式 -->
      <input type="number"
             :placeholder="placeholder?placeholder:(required?'必填':'选填')"
             :value="vInputValue"
             v-if="!readonly"
             @click="clickEvent($event)"
             @input="inputEvent($event.target.value,$event)"
             @change="changeEvent($event.target.value,$event)" />

      <!-- 只读模式 -->
      <input v-if="readonly"
             readonly="readonly"
             :value="vInputValue"
             :class="className?className:'input_readonly'" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'inputNumber',
  data () {
    return {
      inputValue: 0,
      defaultValue: 0, // 默认值,
      defaultDecimalPlaces: 2 // 默认值
    };
  },
  props: {
    max: [Number, String], // 最大值
    min: [Number, String], // 最小值
    value: [Number, String], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    placeholder: String, // 提示 文本，可选，如不提供，则默认显示title
    decimalPlaces: Number, // 保留的小数位数
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    formTitle: {
      // 标题，必填
      type: String,
      required: true
    },
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证,
    maxLength: {
      type: [Number, String],
      default: 12
    },
    formInputHint: {
      // 输入框左侧文本说明
      type: String,
      default: ''
    },
    helpDescription: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    }
  },
  computed: {
    vInputValue: {
      get () {
        if (this.inputValue == '0' || this.inputValue) {
          return this.$utils.mMoneyType(this.inputValue, this.vDecimalPlaces);
        } else {
          return '';
        }
      },
      set (n, o) {
        this.inputValue = n;
      }
    },
    vDecimalPlaces () {
      if (this.decimalPlaces == 0) {
        return 0;
      } else {
        return this.decimalPlaces || this.defaultDecimalPlaces;
      }
    }
  },
  created () {
    this.setInputValue(this.value);
  },
  watch: {
    value (c, o) {
      this.setInputValue(c);
    }
  },
  methods: {
    setInputValue (v) {
      this.inputValue = this.$utils.mMoneyType(v, this.vDecimalPlaces);
      this.inputValue = this.inputValue || this.defaultValue;
    },
    inputEvent (val, e) {
      if (val.length > this.maxLength) {
        e.target.value = this.inputValue;
        return false;
      }
      val = val > Number(this.max) ? Number(this.max) : val;
      val = val < Number(this.min) ? Number(this.min) : val;
      this.inputValue = val;
      this.$emit('inputChange', val);
      // e.target.value = this.inputValue;
      // this.$emit('input', this.inputValue);
      // if (!this.$utils.isIntermediateValue(this.inputValue)) {
        
      // }
    },
    changeEvent (val, e) {
      if (val.length > this.maxLength) {
        e.target.value = this.inputValue;
        return false;
      }
      this.inputValue = this.$utils.mMoneyType(val, this.vDecimalPlaces);
      this.inputValue =
        this.inputValue > Number(this.max) ? Number(this.max) : this.inputValue;
      this.inputValue =
        this.inputValue < Number(this.min) ? Number(this.min) : this.inputValue;
      e.target.value = this.inputValue;

      this.$emit('change', Number(this.inputValue));
      this.$emit('input', Number(this.inputValue));
      this.$emit('inputChange', Number(this.inputValue));
      this.$emit('changeEvent', Number(this.inputValue));
    },
    clickEvent (event) {
      event.currentTarget.select();
    }
  }
};
</script>


