<template>
<div class="bg_gray form_info_detail clearfix">
  <div  class="purchase_order">
    <!-- 表单内容 -->
    <div class="purchase_order_form form_info_edit label_radio_bg_white">
      <input-dialog :formTitle="'杂费名称'"
                    :required="true"
                    @showEldilog="openZFList"
                    v-model="formData.ProjectKeyValue"></input-dialog>
      <!-- <input-dialog :formTitle="'数量'"
                    :required="true"
                    @showEldilog="openSettingNumCount"
                    v-model="vCountAndUnitName"></input-dialog> -->
      <input-number :min="0"
                      :max='9999'
                      v-model="formData.NumCount"
                      :decimalPlaces="0"
                      :formTitle="'数量'"
                      :required="true"
                      @inputChange="RecalculationAmount"></input-number>
      <input-text :formTitle="'单位'"
                    :required="true"
                    v-model="formData.UnitName"></input-text>
      <input-number :min="0"
                    :value="formData.UnitPrice"
                    :decimalPlaces="2"
                    :formTitle="'单价'"
                    :required="true"
                    @inputChange="changeUnitPrice"></input-number>
      <input-dialog :formTitle="'优惠折扣'"
                      :formPlaceholder="'必填'"
                      :readonly="false"
                      :required="true"
                      v-model="vDiscountCount"
                      @showEldilog="openDisCountDialog"></input-dialog>
  
      <input-number :min="0"
                      :max="formData.ValueAmount"
                      :readonly="false"
                      :value="formData.AfterDisAmount"
                      :decimalPlaces="2"
                      :required="true"
                      :formTitle="'收费价格'"
                      class="no_border_bottom"
                      @inputChange="doAfterChangeAfterDisAmount"></input-number>
    </div>
    <!-- 按钮组 -->
    <div>
      <save-cancel-btn-group :btnText="'下一步'"
                              @confirmClick="confirmClick"
                              @cancelClick="backClick"></save-cancel-btn-group>
    </div>
  </div>
  <custom-dialog :title="'数量'"
        width="410px"
        show-position="top"
        :visible.sync="isShowSettingNumCountDialog"
        :append-to-body="false"
        :close-on-click-modal="false"
        :before-close="closeNumCountDialog">
      <setting-numcount @confirmCount="doAfterConfirmNumCount"
                        @cancleCountDialog="closeNumCountDialog"
                        :counts="formData.NumCount"
                        :unitName="formData.UnitName"></setting-numcount>
    </custom-dialog> 
    
  <custom-dialog :title="'折扣'"
        width="410px"
        show-position="top"
        :visible.sync="isShowDiscountCountDialog"
        :append-to-body="false"
        :close-on-click-modal="false"
        :before-close="closeDiscountDialog">
    <form-discount v-model="formData.DiscountCount"
                    @onConfirm="doAfterChangeDiscountCount"
                    @onClose="closeDiscountDialog"></form-discount>
  </custom-dialog>
</div>
</template>

<script>
import { getIncidentals } from '../../../../../API/workbench.js';
import singleSelectionList from '../../../../common/single-selection-list';
import settingNumcount from '../../common/setting-numcount';

export default {
  data () {
    return {
      formData: {}, // 表单数据
      zfList: [], // 杂费列表
      isShowDiscountCountDialog: false,
      isShowSettingNumCountDialog: false
    };
  },
  props: {
    ZFNR: Object
  },
  components: {
    singleSelectionList,
    settingNumcount
  },
  computed: {
    vFormTitle () {
      let formTitle = '';
      if (!this.formData.ProjectKey) {
        formTitle = '购买杂费';
      } else {
        formTitle = '编辑杂费';
      }
      return formTitle;
    },
    vDiscountCount: {
      get () {
        let val = '';
        if (this.formData.DiscountCount == 10) {
          val = '原价';
        } else if (this.formData.DiscountCount == 0) {
          val = '赠送';
        } else {
          val = this.$utils.mMoneyType(this.formData.DiscountCount, 1) + '折';
        }
        return val;
      },
      set (newValue) {
        this.formData.DiscountCount = Number(newValue.number);
      }
    },
    vCountAndUnitName () {
      let str = '';
      if (this.formData.NumCount) {
        str = this.formData.NumCount + this.formData.UnitName;
      }
      return str;
    }
  },
  created () {
    this.formData = this.$utils.parseJson(this.ZFNR);
    this.$dialog.register(this, ['singleSelectionList']);
  },
  methods: {
    // 打开杂费列表
    openZFList () {
      this.loadZFList();
    },
    // 杂费变更后
    doAfterChooseZF (item) {
      this.formData.ProjectKey = item.OLAPKey;
      this.formData.ProjectKeyValue = item.MainDemoName;
      this.formData.UnitPrice = Number(item.UnitPrice);
      if (item.UnitName) {
        this.formData.NumCount = 1;
        this.formData.UnitName = item.UnitName;
      }
      this.RecalculationAmount();
    },
    // 收费价格变更后
    doAfterChangeAfterDisAmount (value) {
      this.formData.AfterDisAmount = value;
      this.formData.DiscountAmount = this.$utils.mMoneyType(this.formData.ValueAmount - this.formData.AfterDisAmount, 2);
      if (value == 0) {
        this.formData.DiscountCount = 0;
      } else {
        let disCount = (this.formData.AfterDisAmount / this.formData.ValueAmount) * 10;
        let DateArr = disCount.toString().split('.');
        let firstDecimal = DateArr[1] ? DateArr[1].toString().substring(0, 1) : 0;
        if (firstDecimal == 9) {
          this.formData.DiscountCount = Number(disCount.toString().substring(0, 3));
        } else if (disCount < 0.1) {
          this.formData.DiscountCount = 0.1;
        } else {
          this.formData.DiscountCount = Number(disCount.toFixed(1));
        }
      }
    },
    // 折扣变更后
    doAfterChangeDiscountCount (val) {
      this.formData.DiscountCount = Number(val);
      this.RecalculationAmount();
      this.closeDiscountDialog();
    },
    // 变更单价
    changeUnitPrice (value) {
      this.formData.UnitPrice = value;
      this.RecalculationAmount();
    },
    // 重算价值跟金额
    RecalculationAmount () {
      this.formData.ValueAmount = this.$utils.mMoneyType(this.formData.UnitPrice * this.formData.NumCount, 2);
      this.formData.AfterDisAmount = this.$utils.mMoneyType((this.formData.ValueAmount * this.formData.DiscountCount) / 10, 2);
      this.formData.DiscountAmount = Number(this.$utils.mMoneyType(this.formData.ValueAmount - this.formData.AfterDisAmount, 2));
    },
    // 打开折扣界面
    openDisCountDialog () {
      this.isShowDiscountCountDialog = true;
    },
    // 折扣 取消
    closeDiscountDialog () {
      this.isShowDiscountCountDialog = false;
    },
    // 打开设置数量界面
    openSettingNumCount () {
      this.isShowSettingNumCountDialog = true;
    },
    // 确定 数量
    doAfterConfirmNumCount (count, unitName) {
      this.formData.NumCount = count;
      this.formData.UnitName = unitName;
      this.RecalculationAmount();
      this.closeNumCountDialog();
    },
    closeNumCountDialog () {
      this.isShowSettingNumCountDialog = false;
    },
    // 确定
    confirmClick () {
      const result = this.checkFomDataBeforeSumbit();
      if (result) {
        return false;
      }
      this.$emit('onConfirmClick', this.formData);
    },
    // 验证表单数据
    checkFomDataBeforeSumbit () {
      let flag = false;
      if (!this.formData.ProjectKey) {
        layer.alert('杂费名称不能为空!');
        flag = true;
      } else if (this.formData.NumCount <= 0) {
        layer.alert('数量不能小于等于0!');
        flag = true;
      } else if (this.formData.UnitPrice <= 0 || !this.formData.UnitPrice) {
        layer.alert('单价不能小于等于0!');
        flag = true;
      }
      return flag;
    },
    // 杂费列表
    loadZFList () {
      getIncidentals().then(result => {
        this.zfList = result.data.filter(obj => { return obj.StatusKey == 2; });

        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择杂费',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.formData.ProjectKey, dataSourceList: this.zfList, required: true, addModuleName: 'incidentals-setting', ModulePowerKey: 45 },
          callBack: {
            onSelectedItem: (data) => { this.doAfterChooseZF(data); }
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 退出
    backClick () {
      this.$emit('onCancelClick');
    }
  }
};
</script>

<style>

</style>