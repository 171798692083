<template>
  <div>
    <div class="course_statistics_table_search flex course_statistics_title">
      <date-range ref="saveDate"
                  :searchObj="searchObj"
                  :fromTitle="'选择日期'"
                  @changeDate="changeSaveDate"></date-range>
      <!--  :minDate="vMinDate"
                    :maxDate="vMaxDate"
                    :isShowHistory="isShowHistory" -->
      <div class="course_statistics_search">

        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入学生名、课单号'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class=" table_list_content">
      <tableList class="summarizing_list"
                 :key="tableKey"
                 :tableData="vdataReportList"
                 :totalNum="totalNum"
                 :PageCount="PageCount"
                 :tableColumns="tableCourseColumns"
                 :isSearchText="false"
                 :isNeedInnerScroll="false"
                 :defaultSort="defaultSort"
                 :queryParams="searchObj"
                 :vFooterTotal="vFooterTotal"
                 @loadTableData="getDataList"></tableList>
    </div>
  </div>
</template>
<script>
import tableList from '../../../../common/table-list/index';
import inputSearchContainBtn from '../../../common/input-search-contain-btn';
import { GetIncomeClassHourList } from '../../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0)),

        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          className: 'table_index_gray'
        },
        {
          label: '报名日期',
          prop: 'GenerateTime',
          width: ' ',
          minWidth: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },

        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: ' ',
          minWidth: 90,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '手机号',
          prop: 'ParPhoneName',
          minWidth: 110,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
        },
        {
          label: '课单号',
          prop: 'NumberName',
          width: ' ',
          minWidth: 110,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            setClassName: (rowData) => {
              return this.$store.getters.SaaSClientEditionKey != 5 ? 'font_blue' : 'font_black';
            },
            ModulePowerKey: 0, //127
            click: (rowData) => {
              // if (rowData.TrialTime) {
              this.seletedItem = rowData;
              if (this.$store.getters.SaaSClientEditionKey == 5) {
                return false
              }
              this.goRouter(rowData)
              // this.changeType(rowData,3);
              console.log('触发订单号');
              // }
            }

          }
        },
        {
          label: '适用课程',
          prop: 'ApplyCorIDsNameValueContent',
          minWidth: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },
        {
          label: '售出课时',
          prop: 'ClassHourAmountValue',
          minWidth: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },
        {
          label: '课单类型',
          prop: 'SourceTypeKeyValue',
          minWidth: 92,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },

        {
          label: '生效日期',
          prop: 'StartTime',
          minWidth: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },
        {
          label: '失效日期',
          prop: 'ExpireTime',
          minWidth: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },


      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
  },
  props: {
    toSearchObj: {
      type: Object
    },
    searchTime: Object,
    searchText: String,
    schoolItem: Object
  },
  created () {
    console.log(this.toSearchObj, 'this.toSearchObj');
    this.schoolKey = this.$route.query.schoolKey || 0
    this.searchObj.searchText = this.searchText
  },
  watch: {
    searchTime: {
      handler (c, o) {
        if (c) {
          //  this.getDateRangeCoursewareStat( this.$utils.getCurrentMonthFirst(),this.$utils.getCurrentMonthLast())
          console.log("searchTime")
          setTimeout(o => {
            console.log("searchTime", this.searchObj.searchText)
            this.searchObj.startTime = c.startTime
            this.searchObj.endTime = c.endTime
            this.getDataList();
          }, 100)
        }
      },
      immediate: true,
      deep: true
    },
    'schoolItem.OLAPKey': {
      handler (c, o) {
        if (c) {
          console.log('schoolItem.OLAPKey', this.schoolItem.OLAPKey)
          //  this.getDateRangeCoursewareStat( this.$utils.getCurrentMonthFirst(),this.$utils.getCurrentMonthLast())
          setTimeout(o => {
            this.getDataList();
          }, 100)
        }
      },
    }

  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.StartTime = o.StartTime == '1900-01-01' ? '-' : o.StartTime
        o.ClassHourAmountValue = Number(o.ClassHourAmount) == 0 ? `-` : `<span class="">` + Number(Number(o.ClassHourNum).toFixed(2)) + `课时/￥` + Number(Number(o.ClassHourAmount).toFixed(2)) + `</span>`
        o.ApplyCorIDsNameValueContent = (o.IsSingleKey == 1 ? '单课|' : '组合课|') + o.ApplyCorIDsNameValue
        // o.DayAmountValue=`<span class="font_blue hover">`+Number(Number(o.DayNum).toFixed(2))+`天/￥`+Number(Number(o.DayAmount).toFixed(2))+`</span>`
        // o.ActualColAmount='￥'+Number(Number(o.ActualColAmount).toFixed(2))
        // o.ZFTotAmount='￥'+Number(Number(o.ZFTotAmount).toFixed(2))
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text;
      if (this.totalAmount > 0) {
        text = '+' + this.$utils.setMoneyformatToParseInt(this.totalAmount);
        text = ',合计' + text + '元';
      } else {
        text = ',合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      }
      return text;
    }
  },
  mounted () {
    // this.getDataList();
  },
  methods: {
    goRouter (item) {
      console.log('goRouter', item);
      let moduleInfo = {
        routerName: 'classListQueryStatistics',
        moduleName: '',
        ModulePowerKey: 127,
        data: { dataInfo: { StudentKey: item.StudentKey, TableID: item.TableID, searchText: item.NumberName } },

      };
      this.$bus.emit('needOpenRouter', moduleInfo);
      // layer.alert("此功能暂未开通")
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    changeType (item, type) {
      console.log("changeType", item, type)
      this.$emit("changeType", item, type)
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = GetIncomeClassHourList;
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '课时收入明细',
          clist: [
            { title: '报名日期', cName: 'GenerateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生名', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '手机号', cName: 'ParPhoneName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课单号', cName: 'NumberName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            {
              title: '售出课时', cName: 'ClassHourNum', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::ClassHourNum::2::0}}"
            },
            {
              title: '售出课时总额', cName: 'ClassHourAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::ClassHourAmount::2::0}}"
            },
            { title: '是否单课', cName: 'IsSingleKey', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '适用课程', cName: 'ApplyCorIDsNameValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课单类型', cName: 'SourceTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '生效日期', cName: 'StartTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '失效日期', cName: 'ExpireTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },


          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(searchObj, this.schoolItem.OLAPKey, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      let fn = GetIncomeClassHourList;
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj, '22222');
      fn(this.searchObj, this.schoolItem.OLAPKey).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },


  }
};
</script>
<style>
.hover {
  cursor: pointer;
}
</style>