<template>
  <div>
    <!-- <custom-dialog title="上课课时消耗"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowMainForm"
                   :before-close="doShowPopupHideClick"> -->
    <!-- <div class="defult_dialog_header">
        <span class="defult_dialog_header_return mint-toast-icon mintui mintui-back fl"
              @click.stop="$router.go(-1)"></span>
        <span class="defult_dialog_header_font">上课课时消耗</span>
        <div class="defult_dialog_header_btn fr" @click="goRouter()"><span>异常课时消耗</span></div>
      </div> -->
    <!-- <function-btn :item="abnormalchconsumebtn"
                  :className="''"
                  @clickItem="goOther">
      <div class="defult_dialog_header_btn"><span>异常课时消耗</span></div>
    </function-btn> -->

    <div class="content_marginTop_30 content_marginRL single_shadow btn_marginBottom_30 overflow_hidden">
      <div class="course_consumption_header clearfix">
        <div class="fl"
             :class="{'opt':activeType==1}"
             @click="activeType=1">
          <span>明细表</span>
        </div>
        <div class="fl"
             :class="{'opt':activeType==2}"
             @click="activeType=2">
          <span>日统计表</span>
        </div>

      </div>
      <input-search-contain-btn v-if="activeType==1"
                                v-model="searchData.searchText"
                                :placeholder="'请输入姓名、姓名首字母、手机号'"
                                :width="'360px'"
                                @onSearchEvent="search"></input-search-contain-btn>
      <!-- <div class="class_common_list_search"
           v-if="activeType==1">
        <input type="text"
               v-model="searchData.searchText"
               placeholder="请输入姓名、姓名首字母、手机号">
        <div class="btn_hover_bg_blue search_btn "
             @click="search">
          搜索
        </div>

      </div> -->
      <div v-if="dataList.length > 0 &&activeType==1"
           class="recording_data_table">
        <div class="recording_data_thead clearfix"
             v-if="activeType==1">
          <div class="recording_data_th"
               style="width: 125px">
            <div>上课时间</div>
          </div>
          <div class="recording_data_th"
               style="width: 130px;">
            <div>课程/老师</div>
          </div>
          <div class="recording_data_th"
               style="width: 150px">
            <div>学生</div>
          </div>
          <div class="recording_data_th"
               style="width: 110px;">
            <div>考勤/课消</div>
          </div>
        </div>
        <!-- 课时消耗明细 -->
        <div class="recording_data_tbody"
             @scroll="scrollTOP"
             v-if="activeType==1">
          <detail-item class="no_button"
                       v-for="(item,key) in dataList"
                       :key="key"
                       :item="item">
          </detail-item>
          <div class="all_list_bottom">
            <span v-if="isLoadEnd"
                  class="loading">加载中...</span>
            <span v-if="noMore"
                  class="complete">已显示全部</span>
          </div>
        </div>
      </div>
      <div v-if="dataList.length == 0 &&activeType==1">
        <div class="course_block_nodata ">
          <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
        </div>
      </div>
      <div v-if="dayReportList.length > 0 &&activeType==2"
           class="recording_data_table">
        <div class="recording_data_thead clearfix"
             v-if="activeType==2">
          <div class="recording_data_th"
               style="width: 100px;">
            <div>上课时间</div>
          </div>
          <div class="recording_data_th"
               style="width: 130px;">
            <div>上课</div>
          </div>
          <div class="recording_data_th"
               style="width: 150px;">
            <div>应到/实到</div>
          </div>
          <div class="recording_data_th"
               style="width: 110px;">
            <div>上课消耗</div>
          </div>
        </div>
        <!-- 课时消耗日统计 -->
        <div class="recording_data_tbody"
             @scroll="scrollTOPDataForDay"
             v-if="activeType==2">
          <day-statisctics-item class="no_button"
                                v-for="(item,key) in dayReportList"
                                :key="key"
                                :item="item">
          </day-statisctics-item>
          <div class="all_list_bottom">
            <span v-if="isLoadEnd"
                  class="loading">加载中...</span>
            <span v-if="forDaynoMore"
                  class="complete">已显示全部</span>
          </div>
        </div>
      </div>
      <div v-if="dayReportList.length == 0 &&activeType==2">
        <div class="course_block_nodata ">
          <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
        </div>
      </div>
    </div>
    <!-- </custom-dialog> -->
  </div>
</template>
<script>
import { searchPageClassCountDayStatistical, searchPageClassCountDetail } from '../../../API/workbench';
import detailItem from './detail-item';
import dayStatiscticsItem from './day-statisctics-item';
export default {
  data () {
    return {
      searchData: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 20, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: '', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '' // 字符串	可选		数据源：GET	搜索条件：模糊匹配->套餐名,套餐名首字母
      },
      dataList: [],     // 详细列表
      dayReportList: [], // 日 统计数据
      activeType: 1,
      startTime: Date.new().toLocaleDateString().replace(/\//g, '-'),
      endTime: Date.new().toLocaleDateString().replace(/\//g, '-'),
      isLoadEnd: false,   // 判断滚动事件触发时是否接口已经处理完毕,处理完毕再执行滚动事件.
      isShowMainForm: false, // 显示
      noMore: false,     // 详情已显示全部
      forDaynoMore: false,    // 日统计已显示全部
      menDianInfo: {},
      abnormalchconsumebtn: {
        name: '',
        type: 'tjxs',
        ModulePowerKey: 61
      }
    };
  },
  components: {
    detailItem, dayStatiscticsItem
  },
  created () {
    // this.getClassCountDetail(this.searchData);
    // this.startTime = this.getStartTime(this.endTime);
    // this.getClassCountDataForDay(this.startTime, this.endTime);
    this.doShowPopupShowClick();
  },
  mounted () {
    window.newUserGuideFunClick = (type, callback) => {
      this.newUserGuideFunClick(type, callback);
    };
  },
  computed: {
    vMinDate(){
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); //获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month ="01";
      let day = "01";
      let hour = "00";
      let minute = '00';
      let second = '01';
      let dateStr = year + "-" + month + "-" + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate',dateStr)
      return dateStr
    },
    vLastMinDate(){
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); //获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month ="02";
      let day = "01";
      let hour = "00";
      let minute = '00';
      let second = '01';
      let dateStr = year + "-" + month + "-" + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate',dateStr)
      return dateStr
    },
  },
  watch: {
    activeType (n, o) {
      document.querySelector('.recording_data_tbody').scrollTop = 0;
    }
  },
  // watch: {
  //   'searchData.searchText': {
  //     deep: true,
  //     handler: function (c, o) {
  //       if (!c) {
  //         this.dataList = [];
  //         this.searchData.pageIndex = 0;
  //         this.searchData.pageSize = 20;
  //         this.getClassCountDetail(this.searchData);
  //       }
  //     }
  //   }
  // },
  methods: {
    // 主表单开关
    doShowPopupHideClick () {
      this.activeType = 1;
      this.searchData.pageIndex = 0;
      this.searchData.searchText = '';
      this.dataList = [];
      this.dayReportList = [];
      this.isLoadEnd = false;
      this.noMore = false;
      this.forDaynoMore = false;
      // this.oweClasshourWarning.Total=0
      this.isShowMainForm = false;
    },
    doShowPopupShowClick () {
      this.isShowMainForm = true;
      this.isLoadEnd = false;
      this.noMore = false;
      this.forDaynoMore = false;
      // document.body.scrollTop = 0;
      this.startTime = Date.new().toLocaleDateString().replace(/\//g, '-');
      this.endTime = Date.new().toLocaleDateString().replace(/\//g, '-');

      this.getClassCountDetail(this.searchData);
      this.startTime = this.getStartTime(this.endTime);
      this.getClassCountDataForDay(this.startTime, this.endTime);
    },
    goOther () {
      // this.doShowPopupHideClick();
      this.$emit('closeDialog');
      let moduleInfo = {
        name: '异常课时消耗',
        routerName: this.$route.name,
        moduleName: 'abnormalchconsumerecord'
      };
      this.$bus.emit('needOpenRouter', moduleInfo);
    },
    // 消耗课时详细查询
    getClassCountDetail (searchData) {
      this.isLoadEnd = true;
      searchPageClassCountDetail(searchData).then(result => {
        result.data.PageDataList.forEach(o => {
          this.dataList.push(o);
        });
        this.isLoadEnd = false;
        if (result.data.PageDataList.length < searchData.pageSize) {
          this.noMore = true;
        }
        this.searchData.pageIndex++;
      });
    },
    // 消耗课时按日查询
    getClassCountDataForDay (startTime, endTime) {
      this.isLoadEnd = true;
      searchPageClassCountDayStatistical(startTime, endTime).then(result => {
        result.data.DataList.sort((x, y) => {
          return Date.new(y.SetUpTime) - Date.new(x.SetUpTime);
        });
        this.menDianInfo = this.$utils.parseJson(this.$store.getters.SaaSClientInfo);
        result.data.DataList.forEach(o => {
          if (
            Date.new(o.SetUpTime) >=
            Date.new(this.menDianInfo.CreateDateTime.substring(0, 10))
          ) {
            this.dayReportList.push(o);
          } else {
            this.forDaynoMore = true;
          }
        });
        this.isLoadEnd = false;
      });
    },
    // 滚动加载更多
    scrollTOP (e) {
      let ETarget = e.target;
      if ((ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight || (ETarget.scrollTop + 1) == ETarget.scrollHeight - ETarget.clientHeight) && !this.isLoadEnd && !this.noMore) {
        // console.log('scrollTOP', this.noMore);
        this.getClassCountDetail(this.searchData);
      }
    },
    // 滚动加载事件.
    scrollTOPDataForDay (e) {
      let ETarget = e.target;
      if ((ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight || (ETarget.scrollTop + 1) == ETarget.scrollHeight - ETarget.clientHeight) && !this.isLoadEnd && !this.forDaynoMore) {
        let currTime = new Date(this.$utils.getPreDay(this.dayReportList[this.dayReportList.length - 1].SetUpTime)).getTime()/1000
        let minDate = new Date(this.vMinDate).getTime()/ 1000
        let lastDate =  new Date(this.vLastMinDate).getTime()/ 1000
          console.log('currTime',currTime,lastDate)
        if (currTime>minDate&&currTime>lastDate){
          this.endTime = this.getEndTime(this.dayReportList[this.dayReportList.length - 1].SetUpTime);
          console.log('asdas',this.endTime)
          this.startTime = this.getStartTime(this.endTime);
          this.getClassCountDataForDay(this.startTime, this.endTime);
        }else if(currTime>minDate&&currTime<lastDate){
         
          this.endTime = this.getEndTime(this.dayReportList[this.dayReportList.length - 1].SetUpTime);
          this.startTime =this.vMinDate;
          console.log('进来',)
          this.getClassCountDataForDay(this.startTime, this.endTime);
        }
        
      }
    },
    // 点击搜索按钮.
    search () {
      this.searchData.pageIndex = 0;
      this.searchData.pageSize = 20;
      this.noMore = false;
      this.dataList = [];
      this.getClassCountDetail(this.searchData);
    },
    getEndTime (endTime) {
      let dateTime = Date.new(endTime).getTime() - 86400000;
      return Date.new(dateTime).getFullYear() + '/' + (Date.new(dateTime).getMonth() + 1) + '/' + Date.new(dateTime).getDate();
    },
    getStartTime (endTime) {
      let month = Date.new(endTime).getMonth();   // 上个月.
      let year = Date.new(endTime).getFullYear();
      if (month < 10) {
        month = '0' + month;
      }
      if (Number(month) == 0) {
        month = 12;
        year--;
      }
      return year + '/' + month + '/01';
    },
    goRouter () {
      this.$router.push({ name: 'abnormalCHConsumeRecord' });
    },
    newUserGuideFunClick (type, callback) {
      switch (type) {
        case this.$initJson.newUserGuideFunType.returnHome:
          this.$router.go(-1);
          setTimeout(() => {
            callback();
          }, 300);
          break;
        default:
          break;
      }
    }
  }
};
</script>

