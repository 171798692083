<template>
  <div>
    <!-- 音频块 -->
    <audio-view v-if="vAudioList.length>0"
                v-for="(item,index) in vAudioList"
                :key="index"
                :item="item"></audio-view>
    <!-- 视频块 -->
    
    <!-- 图片块 -->
    <div class="article_details_img_ul">
		<video-view v-if="vVideoList.length>0"
		            v-for="item in vVideoList"
		            :key="item.ID"
		            :videoInfo="item"></video-view>
      <image-view v-if="vImgList.length>0"
                  v-for="(item,key) in vImgList"
                  :key="key"
                  :imageUrl="item.AttachUrl"
                  :ImgList="vImgList"
                  :content="content">
				  
		</image-view>
    </div>
  </div>
</template>

<script>
import audioView from '../../components/attach-box/audio-view.vue';
import videoView from '../../components/attach-box/video-view.vue';
import imageView from '../../components/attach-box/image-view.vue';
export default {
  components: {
    audioView,
    videoView,
    imageView
  },
  props: {
    AttachList: Array, // 附件列表
    content: String // 内容
  },
  data () {
    return {

    };
  },
  methods: {

  },
  computed: {
    // 视频
    vVideoList () {
      let list = [];
      list = this.AttachList.filter(obj => {
        return obj.AttachTypeKey == 2;
      });
      console.log(list, '视频');
      return list;
    },
    // 音频列表
    vAudioList () {
      let list = [];
      list = this.AttachList.filter(obj => {
        return obj.AttachTypeKey == 3;
      });
      return list;
    },
    // 图片
    vImgList () {
      console.log(this.AttachList, '图片');
      let list = [];
      list = this.AttachList.filter(obj => {
        return obj.AttachTypeKey == 1;
      });
      return list;
    }
  }
};
</script>

<style>
</style>
