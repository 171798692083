import Vue from 'vue';

const asyncTask = new Vue({
  data () {
    return {
      asyncTaskList: {}
    };
  },
  created () {
  },
  methods: {
    // 注册异步任务
    register (asyncTaskID, callBack) {
        this.asyncTaskList[asyncTaskID] = {};
        if (typeof callBack == 'function') {
          this.asyncTaskList[asyncTaskID].callback = callBack;
        }
    },
    // 执行异步任务
    execute (asyncTaskID, data) { 
      let task = this.asyncTaskList[asyncTaskID];
      if (task && task.callback) {
        task.callback(data);
      }
    },
    // 删除 （异步任务执行后）
    delete (asyncTaskID) {
      let task = this.asyncTaskList[asyncTaskID];
      if (task) {
        delete this.asyncTaskList[asyncTaskID];
      }
    }
  }
});

export default asyncTask;
