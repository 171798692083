<template>
  <div class="general_summarizing_student">
    <!-- <div class="course_statistics_left_title"><span>课次明细</span></div> -->
    <div class="payment_statistics_bottom clearfix">
      <div class="payment_statistics_bottom_select flex">
        <div class="table_select_box">
          <div class="table_select_title">收支类型</div>
          <el-select :value="vIncomePayTypeKeyValue"
                     filterable
                     style="width:240px"
                     placeholder="必选"
                     @change="typeEvent"
                     value-key="OLAPKey">
            <el-option v-for="item in typeList"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item"></el-option>
          </el-select>
        </div>
        <div class="table_select_box">
          <div class="table_select_title">收支账户</div>
          <el-select :value="vIncomePayAccountKeyValue"
                     filterable
                     style="width:240px"
                     placeholder="必选"
                     @change="accountEvent"
                     value-key="OLAPKey">
            <el-option v-for="item in incomePayAccountList"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item"></el-option>
          </el-select>
        </div>
        <div class="table_select_box">
          <div class="table_select_title">收支科目</div>
          <el-select :value="vIncomePaySubKeyValue"
                     filterable
                     style="width:240px"
                     placeholder="必选"
                     @change="subjectEvent"
                     value-key="OLAPKey">
            <el-option v-for="item in subjectListS"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item"></el-option>
          </el-select>
        </div>
      </div>
      <div class="course_statistics_table_search flex">

        <date-range ref="saveDate"
                    :searchObj="searchObj"
                    :fromTitle="'选择日期'"
                    :maxDate="$utils.formatDateToLine(Date.new())"
                    @changeDate="changeSaveDate"></date-range>
        <div class="course_statistics_search">
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入经办人、发生人、备注'"
                                    :clearAll="true"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="searchData"></input-search-contain-btn>
          <div class="mendian_backstage_search">
            <div class="btn_hover_bg_blue search_btn "
                 style="margin: 0;"
                 @click.stop="doExportInfoClick">导出</div>
          </div>
        </div>
      </div>
      <div class="course_statistics_table_box table_list_content">
        <table-list class="summarizing_list"
                    :key="tableKey"
                    :tableData="vdataReportList"
                    :totalNum="totalNum"
                    :PageCount="PageCount"
                    :tableColumns="tableCourseColumns"
                    :isSearchText="false"
                    :isNeedInnerScroll="false"
                    :defaultSort="defaultSort"
                    :showPagination="true"
                    :footerContent="vFooterContent"
                    :isShowPageTo="true"
                    :footerTotalMsg="'笔, '"
                    @loadTableData="getDataList"></table-list>
        <!-- <list class="summarizing_list"
              :key="tableKey"
              :tableData="vdataReportList"
              :totalNum="totalNum"
              :PageCount="PageCount"
              :tableColumns="tableCourseColumns"
              :isSearchText="false"
              :isNeedInnerScroll="false"
              :defaultSort="defaultSort"
              :queryParams="searchObj"
              :showPagination="true"
              :vFooterTotal="vFooterTotal"
              :isShowPageTo="true"
              :footerTotalMsg="'笔, '"
              @tableBtnClick="doAfterTableBtnClick"
              @loadTableData="getDataList"></list> -->
        <!-- :vFooterTotal="vFooterTotal" -->
      </div>
      <div v-if="vPayTypeList.length > 0"
           class="payment_statistics_bottom_ul">
        <acount-item v-for="(item,key) in vPayTypeList"
                     :key="key"
                     :item="item">
        </acount-item>
      </div>
    </div>
  </div>
</template>
<script>
// import list from '../class-spend-statistics-analyze/components/table';
import tableList from '../../common/table-list/index';
import dialogFactory from '../../common/dialog-factory';
import acountItem from './components/acount-item';
import inputSearchContainBtn from '../common/input-search-contain-btn';
import { MessageSearchInComePayReport, getIncomeAccount, getIncomeSubject } from '../../../API/workbench';
export default {
  data () {
    return {
      seletedCourseInfo: {},
      incomePayAccountList: [],    // 收支账户列表
      IncomePaySubjectList: [],    // 收支科目列表
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: '',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new()), // this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd'),
        IncomePayTypeKey: 0,   // 收支类型1-收;2-支
        IncomePayTypeKeyValue: '',
        IncomePaySubKey: 0,    // 收支科目
        IncomePaySubKeyValue: '',
        IncomePayAccountKey: 0,  // 收支账户
        IncomePayAccountKeyValue: '',
        IsExportKey: 0       // 是否导出
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'number-item',
          className: 'table_index_gray'
        },
        {
          label: '收支流水',
          prop: 'SourceNumName',
          minWidth: 80,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '发生日期',
          prop: 'GenerateTime',
          minWidth: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item'
        },
        {
          label: '经办人',
          prop: 'HandlerAgentKeyValue',
          minWidth: 80,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '收支科目',
          prop: 'IncomePaySubKeyValue',
          minWidth: 80,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '收支账户',
          prop: 'IncomePayAccountKeyValue',
          minWidth: 80,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '收支金额',
          prop: 'Amount',
          minWidth: 80,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'amount-item'
        },
        {
          label: '发生人',
          prop: 'HapPeolKeyValue',
          minWidth: 80,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '备注',
          prop: 'CommentSupport',
          sortable: 'custom',
          minWidth: 250,
          align: 'left',
          isShow: true,
          type: 'text-item'
        }

      ],
      dataReportList: [],     // 列表数据
      PayTypeReport: [],
      typeList: [
        {
          MainDemoName: '全部',
          OLAPKey: 0
        },
        {
          MainDemoName: '收',
          OLAPKey: 1
        }, {
          MainDemoName: '支',
          OLAPKey: 2
        }
      ]
    };
  },
  components: {
    tableList, dialogFactory, acountItem, inputSearchContainBtn
  },
  computed: {
    vPayTypeList () {
      let dataInfo = {};
      this.PayTypeReport.forEach((item, index) => {
        let { IncomePayAccountKey } = item;
        let { IncomePayAccountKeyValue } = item;
        let { AccountTypeKey } = item;
        if (!dataInfo[IncomePayAccountKey]) {
          dataInfo[IncomePayAccountKey] = {
            IncomePayAccountKey,
            IncomePayAccountKeyValue,
            AccountTypeKey,
            child: []
          };
        }
        dataInfo[IncomePayAccountKey].child.push(item);
      });
      let list = Object.values(dataInfo); // list 转换成功的数据
      console.log(list);
      return list;
    },
    // 过滤收科目(收)
    subjectListS () {
      var SubjectResult = [];
      this.IncomePaySubjectList.map(item => {
        // if (item.IncomePayTypeKey == 1) {
        SubjectResult.push(item);
        // }
      });
      return SubjectResult;
    },
    // 过滤支科目(支)
    subjectListZ () {
      var SubjectResult = [];
      this.IncomePaySubjectList.map(item => {
        if (item.IncomePayTypeKey == 2) {
          SubjectResult.push(item);
        }
      });
      return SubjectResult;
    },
    vIncomePaySubKeyValue () {
      return this.searchObj.IncomePaySubKey == 0
        ? '全部'
        : this.searchObj.IncomePaySubKeyValue;
    },
    vIncomePayAccountKeyValue () {
      return this.searchObj.IncomePayAccountKey == 0
        ? '全部'
        : this.searchObj.IncomePayAccountKeyValue;
    },
    vIncomePayTypeKeyValue () {
      return this.searchObj.IncomePayTypeKey == 0
        ? '全部'
        : this.searchObj.IncomePayTypeKeyValue;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.DeductAmount = Math.round(Number(o.DeductAmount || 0));
        o.ApplyAmount = Math.round(Number(o.ApplyAmount || 0));
        o.ApplyNum = Number(o.ApplyNum || 0);
        o.ArrivedNum = Number(o.ArrivedNum || 0);
        o.ArrangedNum = Number(o.ArrangedNum || 0);
        o.GenerateTime = o.GenerateTime || '';
        o.GenerateTime = o.GenerateTime.substring(5);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterContent () {
      return '共' + this.totalNum + '笔 合计' + (this.totalAmount > 0 ? '+' : '') + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
    }
  },
  created () {

  },
  mounted () {
    this.getConnectorData();
    this.getDataList();
  },
  methods: {
    testClick () {
      console.log(this.vPayTypeList);
    },
    // 父组件触发, 日期.
    searchDataForParentByDate (timeObj) {
      this.searchObj.startTime = timeObj.startTime;
      this.searchObj.endTime = timeObj.endTime;
      this.searchData();
    },
    searchDataForParentByIncomePaySubKey (item, type) {
      this.searchObj.IncomePayTypeKeyValue = type == 1 ? '收' : '支';
      this.searchObj.IncomePayTypeKey = type;
      let indexItem = this.IncomePaySubjectList.find(o => {
        return item.IncomePaySubKey == o.OLAPKey;
      });
      console.log(item, 'indexItem', indexItem);
      if (indexItem) {
        this.searchObj.IncomePaySubKeyValue = indexItem.MainDemoName;
        this.searchObj.IncomePaySubKey = indexItem.OLAPKey;
        // this.searchData();
      }
      this.searchData();
    },
    // 选中类型
    typeEvent (obj) {
      console.log(obj, '选中类型');
      this.searchObj.IncomePayTypeKeyValue = obj.MainDemoName;
      this.searchObj.IncomePayTypeKey = obj.OLAPKey;
      this.searchData();
    },
    // 选中账户
    accountEvent (obj) {
      console.log(obj, '收支账户');
      this.searchObj.IncomePayAccountKeyValue = obj.MainDemoName;
      this.searchObj.IncomePayAccountKey = obj.OLAPKey;
      this.searchData();
    },
    // 选中科目
    subjectEvent (obj) {
      console.log(obj, '收支科目');
      this.searchObj.IncomePaySubKeyValue = obj.MainDemoName;
      this.searchObj.IncomePaySubKey = obj.OLAPKey;
      this.searchData();
    },
    // 收支录入-数据源
    getConnectorData () {
      // 获取收支账户接口
      getIncomeAccount().then(result => {
        if (result.code == 0) {
          console.log('getIncomeAccount', result.data);
          this.incomePayAccountList = result.data;
          this.incomePayAccountList.unshift({
            MainDemoName: '全部',
            OLAPKey: 0
          });
        } else {
          layer.alert('接口异常，注意检查');
        }
      });
      // 获取收支科目接口
      getIncomeSubject().then(result => {
        if (result.code == 0) {
          this.IncomePaySubjectList = result.data;
          // this.IncomePaySubjectList.forEach(o => { });
          this.IncomePaySubjectList.unshift({
            MainDemoName: '全部',
            OLAPKey: 0
          });
        } else {
          layer.alert('接口异常，注意检查');
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.classspendStatisticsAnalyze) {
        this.$refs.classspendStatisticsAnalyze.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    changeSaveDate (dataInfo) {
      console.log('changeSaveDate', dataInfo);
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    // 清除搜索条件
    clearSearchCondition () {
      console.log('clearSearchCondition');
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      // this.searchObj.orderBy = '';
      this.searchObj.type = '';
      // this.searchObj.sequence = 'DESC';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.QueryID = 0;
      this.searchObj.IsExportKey = 0;
      this.searchObj.IncomePayTypeKey = 0;   // 收支类型1-收;2-支
      this.searchObj.IncomePayTypeKeyValue = '';
      this.searchObj.IncomePaySubKey = 0;    // 收支科目
      this.searchObj.IncomePaySubKeyValue = '';
      this.searchObj.IncomePayAccountKey = 0;  // 收支账户
      this.searchObj.IncomePayAccountKeyValue = '';
      this.searchObj = this.$utils.parseJson(this.searchObj);
      this.$refs.saveDate.resetTime();
      this.searchData();
    },
    searchData () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    getDataList (queryParams) {
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      MessageSearchInComePayReport(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        this.PayTypeReport = result.data.PayTypeReport;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        this.searchObj.IsExportKey = 1;
        this.searchObj.pageIndex = 0;
        this.searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '收付款统计',
          clist: [
            { title: '收支流水', cName: 'SourceNumName', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '发生日期', cName: 'GenerateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '经办人', cName: 'HandlerAgentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '收支科目', cName: 'IncomePaySubKeyValue', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '收支账户', cName: 'IncomePayAccountKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '收支金额', cName: 'Amount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '发生人', cName: 'HapPeolKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '备注', cName: 'CommentSupport', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchInComePayReport(this.searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.searchObj.IsExportKey = 0;
          this.searchObj.pageIndex = 0;
          this.searchObj.pageSize = 10;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      this.seletedMDInfo = rowData;
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.popDialog:
          this.$refs.textareaPop.isAlterMessagePopup = true;
          break;
        case this.$initJson.baseFunctionBtnName.read:
          this.seletedCourseInfo = rowData;
          this.$refs.newAttendanceArrangeDialog.isAttendanceArrangeShow = true;
          this.$refs.newAttendanceArrangeDialog.getCourseInfo(this.seletedCourseInfo.OLAPKey);
          break;
        case this.$initJson.baseFunctionBtnName.edit:
          this.isShowChangeStatuDialog = true;
          break;
        default:
          break;
      }
    }
  }
};
</script>

