<template>
  <div>
    <!-- 选择班级 -->
    <div class="aim_class single_shadow fl">
      <div class="aim_class_left fl">
        目标班级
      </div>
      <div class="aim_class_center fl">
        <div class="aim_class_center_name"
             :title="classItem.MainDemoName">
          <span>{{classItem.MainDemoName}}</span>
          <span class="aim_class_center_name_icon"
                v-if="false"
                @click.stop="showCourseDetail"></span>
        </div>
        <div class="aim_class_center_number">
          <span class="font_gray">报班学生 ：</span> <span>{{CurrentPeoCount}}人</span>
        </div>
      </div>
      <div class="aim_class_right fr">
        <div class="aim_class_right_icon_change"
             @click.stop="changeClass()"></div>
        <!-- <div class="aim_class_right_icon_check" @click.stop="showCourseDetail"></div> -->
      </div>
    </div>

  </div>
</template>
<script>

export default {
  name: 'selectedClassIntro',
  props: ['classItem'], // 单选数据项 需绑定到界面 选 中   centre 当前
  data () {
    return {
      // 测试班级数据
    };
  },
  components: {
  },
  created () {
  },
  methods: {
    changeClass (item) {
      this.$emit('changeClass', item);
    },
    showCourseDetail () {
      this.$emit('showCourseDetail', this.classItem);
    }
  },
  computed: {
    CurrentPeoCount () {
      return Number(this.classItem.CurrentPeoCount);
    }
  }
};
</script>

