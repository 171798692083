<template>
  <div>
    <list ref="detailList"
          :listTitle="listTitle"
          :extendSearchCondition="vPopupTableParams.extendSearchCondition"
          :functionBtnGroup="functionBtnGroup"
          :tableData="vPopupTableParams.tableData"
          :totalNum="totalNum"
          :PageCount="PageCount"
          :tableColumns="vPopupTableParams.tableColumns"
          @actionBtnClick="doAfterFunctionBtnClick"
          @tableBtnClick="doAfterTableBtnClick"
          @loadTableData="loadTableData"></list>
  </div>
</template>
<script>
import list from '../list';
import { CouponRedemptionDetails, ParentsPromoteDistributionIncentives } from '../../../API/workbench.js';
import initJson from '../../../js/initJson.json';
let tableFunctionType = { fanxian: 'fanxian', fanhour: 'fanhour', fangift: 'fangift' };// 几个弹出层列表tabFunction 类型的初始化
export default {
  name: 'teacherDetailList',
  data () {
    return {
      listTitle: '',
      totalNum: 0, // 返回的总记录数
      PageCount: 0, // 页数
      pageSize: 0, // 查询记录数
      // 领券明细 未转发
      LQMXExtendSearchCondition: [
        {
          type: initJson.extendSearchConditionType.selectOption,
          formTitle: '状态',
          fieldList: [
            {
              name: 'isshare',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: [
                { key: 0, value: '不限' },
                { key: 1, value: '未转发' },
                { key: 2, value: '已转发' }
              ]
            }
          ]
          // fieldList: [
          //   {
          //     name: 'StutasKey',
          //     defaultValue: 0,
          //     value: 0,
          //     validateHandle: '',
          //     sourceData: [
          //       { key: 0, value: '不限' },
          //       { key: 1, value: '进行中' },
          //       { key: 2, value: '已过期' },
          //       { key: 3, value: '已停用' }
          //     ]
          //   }
          // ]
        }
      ], // 扩展查询参数
      LQMXTableColumn: [ //  列
        { label: '客户姓名', prop: 'username', width: 245, sortable: 'custom', align: 'left', isShow: true, type: 'date', decimalPlace: 0 },
        { label: '转发次数', prop: 'share_number', width: 150, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '领取次数', prop: 'get_number', width: 150, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0, icon: 'fa fa-qrcode' },
        { label: '来源人姓名', prop: 'sourceusername', width: 250, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '领取来源', prop: 'SourceKeyValue', minWidth: 250, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0 }
      ],
      LQMXTableData: [], //  数据
      functionBtnGroup: [] // 功能按钮
    };
  },
  props: ['selectedCouponInfo'],
  components: {
    list
  },
  computed: {
    // 弹出层列表参数
    vPopupTableParams () {
      let tableParams = {};
      tableParams.extendSearchCondition = this.LQMXExtendSearchCondition;
      tableParams.tableColumns = this.LQMXTableColumn;
      tableParams.tableData = this.LQMXTableData;
      return tableParams;
    }
  },
  methods: {
    // 加载 领券明细 (未转发)
    loadCouponRedemptionDetails (queryParams) {
      queryParams.IsExportKey = 0;
      CouponRedemptionDetails(queryParams, {}).then(result => {
        console.log(result, ' this.LQMXTableData');
        console.log('loadCouponRedemptionDetails: result.data', result.data);
        this.LQMXTableData = [];
        if (result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach((obj, index) => {
            if (obj.username) {
              obj.username = obj.username + '/' + this.$utils.phoneModulePower(obj.telephone);
            } else {
              obj.username = this.$utils.phoneModulePower(obj.telephone);
            }
            obj.share_number = obj.share_number + '次';
            obj.get_number = obj.get_number + '次';
            if (obj.source == 1) { // 1.微课堂，2.客户档案送券，3.分享领取 4:手机掌柜赠券,5扫码关注领取,6微课堂(会员列表)赠送,7家长分销
              obj.sourceName = '微课堂';
            } else if (obj.source == 2) {
              obj.sourceName = '客户档案送券';
            } else if (obj.source == 3) {
              obj.sourceName = '分享领取';
            } else if (obj.source == 4) {
              obj.sourceName = '手机掌柜赠券';
            } else if (obj.source == 5) {
              obj.sourceName = '扫码关注领取';
            } else if (obj.source == 6) {
              obj.sourceName = '微课堂(会员列表)赠送';
            } else if (obj.source == 7) {
              obj.sourceName = '家长分销';
            }
            this.LQMXTableData.push(obj);
          });
          this.totalNum = result.data.Total;
          this.PageCount = result.data.PageCount;
          this.currentPage = queryParams.PageIndex;
        } else {
          this.totalNum = 0;
        }
        this.$refs.detailList.isLoading = false;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 加载弹出层列表数据
    loadTableData (queryParams) {
      // this.$refs.detailList.isSearch = true;
      // this.$refs.detailList.isLoading = true;
      // getPageCouponList;
      let newqueryParams=this.$utils.parseJson(queryParams)
      if (newqueryParams.isshare == 2) {
        newqueryParams.isshare = 1;
      } else if (newqueryParams.isshare == 1) {
        newqueryParams.isshare = 0;
      } else {
        newqueryParams.isshare = '';
      }

      console.log(newqueryParams, 'queryParams');
      if (newqueryParams.orderBy == 'sourceName') {
        newqueryParams.orderBy = 'source';
      }
      this.loadCouponRedemptionDetails(newqueryParams);
    },
    // 未转发
    notForwardingClick () {
      console.log('进来');
      // this.unShareActive = true;
      this.listTitle = '领券明细';
      let initSelectedParams = [];
      let unClearParams = [];
      if (this.selectedCouponInfo.id) {
        initSelectedParams.push({ name: 'id', value: this.selectedCouponInfo.id, unClear: true }, { name: 'isshare', value: 1, unClear: false });
        // unClearParams.push({name: '转发次数', value: '0次'});
        this.$refs.detailList.isShowListDialog = true;
        this.$refs.detailList.initSelectedParams(initSelectedParams, unClearParams);
      } else {
        layer.alert('暂无数据');
      }
    },
    // 点击table 的详情
    doAfterClickDetail (item, eventName) {
      // if (eventName == tableFunctionType.fangift) {
      //   console.log(item, 'tableFunctionType.fangift', eventName);
      //   if (item.read_gift_status == 2) {
      //     this.browseGiftDistribution(item);
      //   }
      //   return;
      // }
      console.log(item, 'doAfterClickDetail', eventName);
      let initSelectedParams = [];
      let unClearParams = [];
      this.listTitle = '老师提成';
      initSelectedParams.push({ name: 'id', value: this.selectedCouponInfo.id, unClear: true }, { name: 'agentid', value: item.id, unClear: true });
      unClearParams.push({ name: '老师', value: item.name });
      this.$refs.detailList.isShowListDialog = true;
      this.$refs.detailList.initSelectedParams(initSelectedParams, unClearParams);
    },
    // 点击 弹出层列表的按钮
    doAfterTableBtnClick (rowData, eventName) {
      let type = 0;
      switch (eventName) {
        case tableFunctionType.fanxian:
          if (rowData.is_fafang_fanxian == 1) {
            return;
          }
          type = 1;
          break;
        case tableFunctionType.fanhour:
          if (rowData.is_fafang_fanhour == 1) {
            return;
          }
          type = 2;
          break;
        case tableFunctionType.fangift:
          if (rowData.is_fafang_fangift == 1) {
            return;
          }
          type = 3;
          break;
        default:
          break;
      }
      console.log(rowData, 'rowData');
      // 问题/
      this.parentsPromoteDistributionIncentives(rowData, type);
    },
    showDetailList () {
      this.$refs.detailList.isShowListDialog = true;
    },
    parentsPromoteDistributionIncentives (rowData, type) {
      ParentsPromoteDistributionIncentives(rowData.id, type).then(result => {
        console.log(this.LSTCTableData, 'LSTCTableData1');
        console.log(this.FXJLTableData, 'FXJLTableData111');
        this.FXJLTableData = this.utils.parseJson(this.chgArrayInfo(this.FXJLTableData, rowData, type));
        this.LSTCTableData = this.utils.parseJson(this.chgArrayInfo(this.LSTCTableData, rowData, type));
        console.log(this.LSTCTableData, 'LSTCTableData222');
        console.log(this.FXJLTableData, 'FXJLTableData2222');
        layer.alert(result.msg);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 点击 弹出层列表的右上角按钮
    doAfterFunctionBtnClick () {

    }
  }

};
</script>
