<template>
  <div class="course_week_list list_bottom"
       style="line-height: 26px;">
    <div @click.stop="clickItem"
         class="single_result single_class_info"
         :class="{active:courseInfo.isActive}"
         style="padding:12px">
      <div class="fl"
           style="padding:0 12px"></div>
      <div class="list_text fl text_overflow_hidden">
        <span>{{vClassTime}}</span>
        <span class="list_date">{{vWeekValue}}</span>
        <span>{{courseInfo.SClassTimeName}}</span>

        <span>{{courseInfo.CourseNameKeyValue}}</span>
        <span>{{vMTeaName}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    courseInfo: Object
  },
  computed: {
    vClassTime () {
      let month = Date.new(this.courseInfo.ClassTime).getMonth() + 1;
      let day = Date.new(this.courseInfo.ClassTime).getDate();
      return (
        (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day)
      );
    },
    vWeekValue () {
      return this.$utils.getWeekByTwo(this.courseInfo.ClassTime);
    },
    vMTeaName () {
      return this.courseInfo.MTeacherKeyValue.split('老师')[0] + '老师';
    }
  },
  methods: {
    clickItem () {
      this.$set(this.courseInfo, 'isActive', true);
      this.$emit('onClickItem', this.courseInfo);
    }
  }
};
</script>
<style>
</style>