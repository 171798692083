<template>
  <div class="img_list"
       :style="{'margin-left':vmarginLeft}">
    <div class="img_list_close"
         @click.stop="deleteImg"></div>
    <img class="img_box"
         :src="vImgUrl"
         alt=""
         @click.stop="clickImg">
    <div class="upload_img_progress"
         :style="{display:vIsProgress }">
      <!-- <span class="text">{{vUploadProgress}}</span> -->
      <span class="percentage"
            :style="{width:vUploadProgress}"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgInfo: {
      type: Object
    },
    essayID: Number, // 短文ID
    keyIndex: Number,
    isVideo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    vImgUrl () {
      let url = this.imgInfo.AttachUrl;
      if (url.indexOf('data:image/') == -1) {
        url = this.$store.getters.CDNURL + url;
      }
      return url;
    },
    vUploadProgress () {
      return this.imgInfo.percent + '%';
    },
    vIsProgress () {
      return (this.imgInfo.percent == 100 || this.imgInfo.EssayID) ? 'none' : 'block';
    },
    vmarginLeft () {
      if (this.isVideo && this.keyIndex == 0) {
        return '114px';
      } else {
        return '0px';
      }
    }
  },
  created () {
    console.log('this.imgInfo', this.imgInfo);
  },
  methods: {
    clickImg () {
      this.$emit('onClickImg', this.imgInfo.AttachUrl);
    },
    deleteImg () {
      this.$emit('onDeleteImg', this.imgInfo.AttachUrl);
    }
  }
};
</script>

<style>
</style>