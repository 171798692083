<template>
  <div>
    <!-- 搜索选择层 -->
    <input-search-immediately v-model="searchText"
                       :placeholder="'请输入套餐名'"
                       :styleText="'right: 40px;'"
                       @clearSearch="clearSearText"></input-search-immediately>

    <!-- 选择套餐 -->
    <div class="WKT_discount_package_ul">
      <combo-selection-item v-for="(item,index) in vDataSourceLsit"
                            :key="index"
                            :selectItem="item"
                            :selectList="vDataSourceLsit"
                            @selectedItem="doAfterSelectionItem"
                            @selectedItemDetails="doAfterSelectedItemDetails"></combo-selection-item>

      <div v-if="vDataSourceLsit.length==0">
        <div class="monitoring_content_nodata"
             style="margin-top:160px">
          <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <div>
      <common-button-group @buttonClickEvent="doAfterClickConfirmBtn"
                           @closeDialog="doAfterClickCancelBtn"></common-button-group>
    </div>
  </div>
</template>
<script>
import comboSelectionItem from './combo-selection-item';
export default {
  name: 'singleSelectionList',
  data () {
    return {
      searchText: '',
      selectedItem: {} // 选中项
    };
  },
  props: {
    dataSourceLsit: Array, // 数据源（必填）
    selectionKey: [String, Number] // 可根据key找到当前项
  },
  components: {
    comboSelectionItem
  },
  watch: {

  },
  created () {
  },
  methods: {
    // 选中项详情
    doAfterSelectedItemDetails (item) {
      if (item) {
        this.selectedItem = item;
        this.$emit('selectedItemDetails', this.selectedItem);
      }
    },
    // 选中添加项
    doAfterSelectionItem (item) {
      if (item) {
        this.vDataSourceLsit.forEach(o => {
          if (o.TableID == item.TableID) {
            o.isOpt = true;
          } else {
            o.isOpt = false;
          }
        });
        this.selectedItem = item;
      }
    },
    // 确认添加套餐
    doAfterClickConfirmBtn (callBack) {
      this.searchText = '';
      this.$emit('addSelectedItem', this.selectedItem, callBack);
    },
    // 取消按钮
    doAfterClickCancelBtn () {
      this.searchText = '';
      this.$emit('noCancelSelected');
    },
    // 清除按钮
    clearSearText () {
      this.searchText = '';
    }
  },
  computed: {
    vDataSourceLsit () {
      let newList = [];
      let trueSearchText = this.searchText.toLowerCase();
      this.dataSourceLsit.forEach(o => {
        if (o.PackageName && (o.PackageName.toLowerCase().indexOf(trueSearchText) >= 0 || (o.FirstLetterName && o.FirstLetterName.toUpperCase().indexOf(trueSearchText.toUpperCase()) >= 0))) {
          if (o.OLAPKey == this.selectionKey) {
            newList.push(o);
          } else {
            newList.push(o);
          }
        }
      });
      return newList;
    }
  }
};
</script>


