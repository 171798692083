<template>
  <div class="list_info_block" @click="clickItem">
    <div class="list_info_block_data">
      <div class="data_list">
        <div class="data_list_name">浏览(次)</div>
        <div class="data_list_text">{{dataInfo.total_read_num}}</div>
      </div>
      <div class="data_list">
        <div class="data_list_name">转发(次)</div>
        <div class="data_list_text">{{dataInfo.total_share_num}}</div>
      </div>
      <div class="data_list no_line">
        <div class="data_list_name">线上报名(人)</div>
        <div class="data_list_text">
          {{dataInfo.total_get_num}}
          <div class="pa">
            <span class="font_gray">显示</span>
            <span class="">{{(dataInfo.total_get_num + dataInfo.vgetNum)}}</span>
            <span class="font_gray">|</span>
            <span class="font_gray">虚拟</span>
            <span class="">{{dataInfo.vgetNum || 0}}</span>
          </div>
        </div>
      </div>
      <div class="data_list">
        <div class="data_list_name">报名金额(元)</div>
        <div class="data_list_text font_yellow">{{dataInfo.TotalBuyAmount || 0.00}}</div>
      </div>
      <div v-if="dataInfo.activityType != 3" class="data_list no_line">
        <div class="data_list_name">到店付款(人)</div>
        <div class="data_list_text">{{dataInfo.total_use_num}}</div>
      </div>
      <div v-if="dataInfo.activityType != 3" class="data_list no_line">
        <div class="data_list_name">报名金额(元)</div>
        <div class="data_list_text font_yellow">{{dataInfo.total_pay_amount}}</div>
      </div>
    </div>
    <div class="list_info_btn">
      查看详情
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props:{
    dataInfo:Object
  },
  methods:{
    clickItem(){
      this.$emit('clickItem')
    }
  }
};
</script>