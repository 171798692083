<template>
  <!-- 学生作品模块 - 总部 -->
  <div class="works_management">
    <div class="table_fixed_nav_box">
      <div class="table_fixed_nav tltle_margin">
        <div @click="showHQRecommendedWorksClick"
             class="table_fixed_nav_list"
             :class="{opt:showManagement=='hqrecommendedWorks'}">最新推荐</div>
        <div @click="showHQhqFineRecommendWorks"
             class="table_fixed_nav_list"
             :class="{opt:showManagement=='hqFineRecommendWorks'}">精选推荐</div>
        <div @click="showHQExquisiteWorksClick"
             class="table_fixed_nav_list"
             :class="{opt:showManagement=='hqexquisiteWorks'}">回收站</div>
        <div class="header_bottom"
             v-if="showManagement=='hqrecommendedWorks'">
          <function-btn :item="{ name: '一键回收', ModulePowerKey: '' }"
                        :className="'el-button btn_light_blue btn_hover_bg_blue buttom_sq'"
                        @clickItem="doOperateItemClick">
          </function-btn>
        </div>
      </div>
    </div>
    <div>
      <!-- 最新推荐 -->
      <div class="showManagement"
           v-if="showManagement=='hqrecommendedWorks'">
        <div v-for="item,index in maxWorksUploadList"
             :key="index"
             class="hqrecommended_works">
          <recommended-works :dataInfo="item"
                             :ShowOrder="index"
                             :showManagement="showManagement"
                             @afterSuccess="afterSuccess"></recommended-works>
        </div>
      </div>
      <!-- 精选推荐 -->
      <div class="showManagement"
           v-if="showManagement=='hqFineRecommendWorks'">
        <div>
          <hq-fine-recommend-works :campusWorksLeftList="campusWorksLeftList"
                                   :campusWorksRightList="campusWorksRightList"
                                   @afterSuccess="afterSuccess"
                                   @campusLeftClick="campusLeftClick"></hq-fine-recommend-works>
        </div>
      </div>
      <!-- 回收站 -->
      <div class="showManagement"
           v-if="showManagement=='hqexquisiteWorks'">
        <!-- 提示 -->
        <div class="head_tips_div"
             v-if="showManagement=='hqexquisiteWorks'&& maxWorksUploadList.length != 0"> <span style="color:red">*</span> 回收站作品保存60天，超过60天自动清除。</div>
        <div class="hqexquisite_works"
             v-for="item,index in maxWorksUploadList"
             :key="index">
          <exquisite-works :dataInfo="item"
                           :showManagement="showManagement"
                           :ShowOrder="index"
                           @afterSuccess="afterSuccess"></exquisite-works>
        </div>
      </div>

    </div>
    <div v-if="showManagement!='hqFineRecommendWorks' && maxWorksUploadList.length == 0"
         style="margin-top: 90px;">
      <div class="right_nodata">亲，当前还没有任何数据哦~</div>
    </div>
    <div>
      <dialog-factory ref="hqworksUploadManagementDialog"
                      :key="'hqworksUploadManagement'"
                      :routerName="'hqworksUploadManagement'"></dialog-factory>
      <dialog-factory-common ref="hqworksUploadManagementCommonDialog"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import { HQGetNewestStudentPhotoPageList, HQGetSaaSClientStudentPhotoCount, HQGetPushStudentPhotoPageList, HQGetCollectStudentPhotoPageList, HQBatchCollectStudentPhoto } from '../../API/workbench';

import recommendedWorks from './recommended-works/index.vue';
import hqFineRecommendWorks from './hq-fine-recommend-works/index.vue';
import exquisiteWorks from './exquisite-works/index.vue';
import recommendWorksPopup from './recommend-works-popup';
import dialogFactory from '../common/dialog-factory';
export default {
  name: 'hqworksUploadManagement',
  data () {
    return {
      showManagement: 'hqrecommendedWorks',
      maxWorksUploadList: [],
      campusWorksRightList: [],
      campusWorksLeftList: []
    };
  },
  components: {
    recommendedWorks,
    exquisiteWorks,
    recommendWorksPopup,
    dialogFactory,
    hqFineRecommendWorks
  },
  created () {
    this.registerBusEvent();
    this.getWorksList();
  },
  mounted () {

  },
  methods: {
    doOperateItemClick () {
      layer.confirm(
        '一键回收作品,会将最新推荐的所有作品回收,是否继续?',
        {
          btn: [
            {
              name: '确认',
              callBack: () => {
                HQBatchCollectStudentPhoto().then(result => {
                  this.getWorksList();
                  layer.close('一键回收成功');
                  console.log(HQBatchCollectStudentPhoto, result.data);
                }, error => {
                  layer.alert(error.msg);
                });
                layer.close();
              }
            }
          ]
        }
      );
    },
    getWorksList (OLAPKey) {
      let fn = '';
      if (this.showManagement == 'hqrecommendedWorks') {
        fn = HQGetNewestStudentPhotoPageList;
      } else if (this.showManagement == 'hqFineRecommendWorks') {
        fn = HQGetSaaSClientStudentPhotoCount;
      } else {
        fn = HQGetCollectStudentPhotoPageList;
      }
      fn().then(result => {
        if (this.showManagement == 'hqFineRecommendWorks') {
          this.campusWorksLeftList = result.data;
          if (this.campusWorksLeftList.length == 0) {
            this.campusWorksLeftList.List = [];
          }
          console.log(this.campusWorksLeftList, 'this.campusWorksLeftList');
          if (this.campusWorksLeftList.List.length > 0) {
            this.campusWorksLeftList.List.forEach(o => { this.$set(o, 'isOpt', false); });
            this.campusWorksLeftList.List[0].isOpt = true;
            this.getStudentWorksList(this.campusWorksLeftList.List[0].SaaSClientKey);
          } else {
            this.getStudentWorksList(OLAPKey);
          }
        } else {
          this.maxWorksUploadList = result.data;
        }

        console.log(fn, result.data);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 返回精选推荐学生作品
    getStudentWorksList (SaaSClientKey) {
      HQGetPushStudentPhotoPageList(Number(SaaSClientKey)).then(result => {
        this.campusWorksRightList = result.data;
        this.campusWorksRightList.forEach(o => { this.$set(o, 'isOpt', false); });
        console.log('HQGetPushStudentPhotoPageList', result.data);
      }, error => {
        layer.alert(error.msg);
      });
    },
    afterSuccess (OLAPKey) {
      this.getWorksList(OLAPKey);
    },
    // 精选推荐的子传父
    campusLeftClick (SaaSClientKey) {
      console.log(SaaSClientKey, 'SaaSClientKey');
      this.getStudentWorksList(SaaSClientKey);
    },
    // 切换最新推荐
    showHQRecommendedWorksClick () {
      this.showManagement = 'hqrecommendedWorks';
      this.getWorksList();
    },
    // 切换精选推荐
    showHQhqFineRecommendWorks () {
      this.showManagement = 'hqFineRecommendWorks';
      this.getWorksList();
    },
    // 切换回收站
    showHQExquisiteWorksClick () {
      this.showManagement = 'hqexquisiteWorks';
      this.getWorksList();
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        console.log('openCustomDialog', dialogInfo);
        if (dialogInfo.routerName == 'hqworksUploadManagement') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'hqworksUploadManagement') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.hqworksUploadManagementDialog) {
        this.$refs.hqworksUploadManagementDialog.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.hqworksUploadManagementCommonDialog) {
        this.$refs.hqworksUploadManagementCommonDialog.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }

  },
  computed: {}
};
</script>
<style scoped>
/* 查看作品的背景颜色 */
.works_management
  >>> .custom-dialog-factory
  .popup_dialog_bg
  .popup_dialog_box {
  background: #000 !important;
}
.works_management .tltle_margin {
  margin: 0 auto;
}
.showManagement {
  margin: 0 auto;
  width: 1340px;
}
.head_tips_div {
  width: 1340px;
  height: 35px;
  background: #fffbe6;
  border: 1px solid #ffe694;
  border-radius: 2px;
  line-height: 35px;
  margin-top: 90px;
  padding-left: 40px;
}
.head_tips_div span {
  position: absolute;
  margin-top: 3px;
  margin-left: -17px;
}
.works_management {
  margin-bottom: 0px;
  margin-top: 68px;
}
.table_fixed_nav {
  width: 1340px !important;
}
.table_fixed_nav_list {
  padding: 0;
  width: 155px;
}
.header_bottom {
  position: absolute;
  margin-left: 1255px;
}
.right_nodata {
  height: 745px;
  font-size: 14px;
  line-height: 18px;
  color: #a9aaaa;
  text-align: center;
  padding-top: 430px;
  /* position: absolute;
  top: 160px;
  left: 0;
  right: 0; */
  background: #fff url(../../../public/image/courseware_no_data.png) no-repeat
    center center;
  width: 1340px;
  margin: 0 auto;
  border-radius: 10px;
  z-index: -1;
}
.showManagement
  > .hqrecommended_works:nth-of-type(4n-2)
  >>> .max_courseware
  .courseware_list,
.showManagement
  > .hqexquisite_works:nth-of-type(4n-1)
  >>> .max_courseware
  .courseware_list {
  margin: 20px 15px !important;
}
.showManagement
  > .hqrecommended_works:nth-of-type(4n-1)
  >>> .max_courseware
  .courseware_list,
.showManagement
  > .hqexquisite_works:nth-of-type(4n)
  >>> .max_courseware
  .courseware_list {
  margin: 20px 15px !important;
}
.showManagement
  > .hqrecommended_works:nth-of-type(4n)
  >>> .max_courseware
  .courseware_list,
.showManagement
  > .hqexquisite_works:nth-of-type(4n + 1)
  >>> .max_courseware
  .courseware_list {
  float: right !important;
  margin: 20px 3px !important;
}
</style>