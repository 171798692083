<template>
  <div class="summarizing_list">
    <table-list
      :tableData="vTableInfoList"
      :tableColumns="tableColumns"
      :totalNum="totalNum"
      :queryParams="params"
      :isShowCheckBox="false"
      @loadTableData="getDataInfo"
    ></table-list>
  </div>
</template>
<script>
import tableList from "../../../common/table-list/index";
import { SearchPageYKRecommendForSignBill } from "../../../../API/workbench.js";
export default {
  data() {
    return {
      isShowMessagePopup: false,
      selectInfo: false,
      dataInfoList: [],
      totalNum: 0,
      tableColumns: [
        {
          label: "序号",
          prop: "Number",
          type: "table-index",
          className: "table_index_gray",
          sortable: false,
          width: 48,
        },
        {
          label: "签单日期",
          prop: "SignTime",
          width: 100,
          type: "date-item",
          sortable: "custom",
        },
        {
          label: "推荐日期",
          prop: "RecommendTime",
          width: 100,
          type: "date-item",
          sortable: "custom",
        },
        {
          label: "投资人",
          prop: "MainDemoName",
          type: "text-item",
          width: 95,
        },
        {
          label: "手机号",
          prop: "MobilePhone",
          type: "text-item",
          width: 114,
        },
        {
          label: "计划城市",
          prop: "CityKeyValue",
          type: "text-item",
          width: 110,
        },
        {
          label: "加盟方式",
          prop: "FranchiseTypeKeyValue",
          type: "text-item",
          width: 106,
        },
        {
          label: "投资预算",
          prop: "BudgetAmount",
          type: "text-item",
          width: 86,
        },
        {
          label: "跟进记录",
          prop: "aaa",
          type: "text-dual-item",
          width: 361,
          extend: {
            textOne:'FollowTime',
            classNameOne:'font_gray',
            textTwo:'Content',
            classNameTwo:''
          }
        },
        {
          label: "顾问",
          prop: "CounselorKeyValue",
          type: "text-item",
          width: 84,
        },
        {
          label: "合同金额",
          prop: "ContractAmount",
          type: "text-item",
          width: 94,
        },
        {
          label: "定金",
          prop: "EarnestAmount",
          type: "text-item",
          width: 86,
        },
        {
          label: "付款进度",
          prop: "PaymentScheduleKeyValue",
          type: "text-item",
          width: 86,
        },
        {
          label: "操作",
          prop: "option",
          type: "table-btn",
          align: "center",
          className: "",
          width: 68,
          extend: {
            tableBtnGroup: [
              {
                name: "详情",
                extend: {
                  click: (rowData) => {
                    this.openDetails(rowData);
                  },
                },
              },
            ],
          },
        },
      ],
    };
  },
  props: {
    params: Object,
  },
  components: {
    tableList,
  },
  computed: {
    vTableInfoList() {
      let arr = [];
      this.dataInfoList.forEach((e) => {
        e.BudgetAmount = Number(e.StratBudgetAmount) + "-" + Number(e.EndBudgetAmount) + "万";
        e.CityKeyValue = e.ProvinceKeyValue + '·' + e.CityKeyValue;
        e.ContractAmount = Number(e.ContractAmount);
        e.EarnestAmount = Number(e.EarnestAmount);
        e.FollowTime = this.$utils.StrForTodoTime(e.FollowTime) + ':';
        arr.push(e);
      });
      return arr;
    },
  },
  created() {
    this.getDataInfo();
  },
  methods: {
    openDetails(rowData) {
      this.$emit('openRecommendDetails',rowData);
    },
    getDataInfo() {
      SearchPageYKRecommendForSignBill(this.params).then(
        (res) => {
          this.totalNum = res.data.Total;
          this.dataInfoList = res.data.PageDataList;
          this.$emit("upDataInfo", res.data);
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
  },
};
</script>