<template>
  <div :id="echartID"
       :class="[vClass]"></div>
</template>
<script>
export default {
  name: 'columnarEchart',
  data () {
    return {
      // chartItem: {
      //   resize: ''
      // },
      echart: null
    };
  },
  props: {
    echartID: String,
    dataList: Array,
    dataList2: Array,
    totalDataList: Array,
    reqList: Array,
    echartColor: String,
    echartBGColor: String,
    rotate: String,
    echartClass: String,
    maxType: String,
    rateType: String,
    barOneTitle: {
      type: String,
      default: 'bar'
    },
    barTwoTitle: {
      type: String,
      default: 'bar2'
    }
  },
  computed: {
    vClass () {
      if (this.echartClass) {
        return this.echartClass;
      } else {
        return 'echarts_m';
      }
    },
    // 这是为了预防 当没有数据时, Y轴刻度 连数字都不显示的问题.  20这个数字到时候直接从外面传值进来处理
    maxSize () {
      let count = 0;
      for (var i = 0; i < this.dataList.length; i++) {
        count += Number(this.dataList[i]);
      }
      if (this.maxType == 'rate') {
        return 100;
      } else if (count == 0 || count < 10) {
        return 10;
      }
    }
  },
  mounted () {

  },
  methods: {
    echartInit () {
      this.echart = echarts.init(document.getElementById(this.echartID));
      window.chartList.push(this.echart);
      var that = this;
      // 指定图表的配置项和数据
      var emphasisStyle = {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0,0,0,0.3)'
        }
      };
      var option = {
        legend: {
          show: false,
          data: ['bar', 'bar2'],
          left: '10%'
        },
        brush: {
          toolbox: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
          xAxisIndex: 0
        },
        toolbox: {
          feature: {
            magicType: {
              type: ['stack', 'tiled']
            },
            dataView: {}
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            show: true,
            type: 'none' // 默认为直线，可选为：'line' | 'shadow'
          },
          backgroundColor: '#FFD572',
          formatter: function (params, ticket, callback) {
            let tips = '';                // 根据传进来的rateType是否存在确认是否显示tips.
            if (!that.rateType) {
              tips = 0;
            } else {
              tips = that.rateType;
            }
            var htmlStr = '';
            console.log('params', params);
            for (var i = 0; i < params.length; i++) {
              var param = params[i];
              var xName = param.seriesName;// x轴的名称
              // var seriesName = param.seriesName;// 图例名称
              var value = param.value;// y轴值
              var color = param.color;// 图例颜色

              // if (i === 0) {
              //   htmlStr += xName;// x轴的名称
              // }
              //  style="background-color:#FFD572;"
              if (param.name) {
                htmlStr += '<div>';

                if (xName) {
                  // 为了保证和原来的效果一样，这里自己实现了一个点的效果
                  htmlStr += '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;border:1px solid white;background-color:' + color + ';"></span>';

                  // 圆点后面显示的文本
                  htmlStr += xName + ' ' + value;
                }
                htmlStr += '</div>';
              }
            }
            return htmlStr;
          }
        },
        xAxis: {
          boundaryGap: false,
          data: this.reqList,
          axisTick: {
            show: false
          },
          name: '',
          axisLine: { show: false, onZero: false },
          splitLine: { show: false },
          splitArea: { show: false },
          minorSplitLine: {
            show: false
          }
        },
        yAxis: {
          show: false
        },
        grid: {
          // bottom: 100
        },
        series: [
          {
            name: this.barOneTitle,
            type: 'bar',
            stack: 'one',
            emphasis: emphasisStyle,
            data: this.dataList,
            barMaxWidth: 22,
            itemStyle: {
              normal: {
                barBorderWidth: 1,
                barBorderRadius: [25, 25, 25, 25],
                color: '#FFD572',
                barBorderColor: this.echartBGColor,
                abel: {
                  show: true,
                  textStyle: {
                    color: '#FFD572'
                  }
                }
                // barWidth: 22
              }
            }
          },
          {
            name: this.barTwoTitle,
            type: 'bar',
            stack: 'one',
            emphasis: emphasisStyle,
            data: this.dataList2,
            barMaxWidth: 22,
            itemStyle: {
              normal: {
                barBorderWidth: 1,
                barBorderRadius: [25, 25, 25, 25],
                color: '#B09FFF',
                barBorderColor: this.echartBGColor,
                abel: {
                  show: true,
                  textStyle: {
                    color: '#B09FFF'
                  }
                }
                // barWidth: 22
              }
            }
          },
          {
            name: '',
            type: 'bar',
            stack: 'two',
            emphasis: emphasisStyle,
            data: this.totalDataList,
            barMaxWidth: 22,
            barGap: '-100%',
            z: -1,
            itemStyle: {
              normal: {
                barBorderWidth: 1,
                barBorderRadius: [25, 25, 25, 25],
                color: '#B09FFF',
                barBorderColor: this.echartBGColor,
                abel: {
                  show: true,
                  textStyle: {
                    color: '#B09FFF'
                  }
                }

                // barWidth: 22
              }
            }
          }

        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      this.echart.setOption(option);
    },
    sumBorderWidth () {
      let borderList = [];
      this.dataList.forEach(o => {
        if (o == 0) {
          borderList.push(0);
        } else {
          borderList.push(1);
        }
      });
      return borderList;
    }
  }
};
</script>

