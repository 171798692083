<template>
  <div class="course_auth_qrcode"
       style="height:576px">
    <div class="course_auth_qrcode_title">课件安全认证</div>
    <!-- <div class="course_auth_qrcode_img"
         id="auth_qrcode">

    </div> -->
    <qrcode-vue class="course_auth_qrcode_img"
                :value="codeValue"
                :size="codeValue?256:0"
                level="L"
                id="auth_qrcode" />
    <div class="course_auth_qrcode_footer">请登录{{vBrandInfo.MainDemoName}}APP，点击右上角
      <span class="course_auth_qrcode_footer_img"></span>
      扫码验证
    </div>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue';
import {
  GetClientIP
} from "../../../../API/workbench";
export default {
  data () {
    return {
      codeValue: '',
      busResult: null
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          ScheduleCourseKey: 0,           // 课id
          SaaSClientKey: 0,           // 门店id
          client_id: '',              // 长连接ID
          DeviceTypeKey: '2',  //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
          coursewareID: ''         // 课件ID
        };
      }
    }
  },
  components: {
    QrcodeVue
  },
  computed: {
    vBrandInfo () {
      let obj = {};
      if (window.brandCustomSettingStr && window.brandCustomSettingStr != '') {
        obj = JSON.parse(window.brandCustomSettingStr);
        obj.MainDemoName = obj.MainDemoName;
      } else {
        obj = {
          MainDemoName: '治学'
        };
      }
      return obj;
    }
  },
  created () {
    console.log('dataInfo', this.dataInfo);
  },
  mounted () {
    if (this.dataInfo) {
      // isPCQrCode
      // this.dataInfo.isPCQrCode = 1;
      // if (!this.dataInfo.ScheduleCourseKey || this.dataInfo.ScheduleCourseKey == 0) {
      //   delete this.dataInfo.ScheduleCourseKey;
      // }
      // this.dataInfo.client_id = this.$store.getters.clientId;
      // console.log('this.dataInfo', this.dataInfo);
      // let url = JSON.stringify(this.dataInfo);
      // this.codeValue = url; 
      this.getIPdata()

    }
    this.regisBus();
  },
  destroyed () {
    this.$bus.off(this, 'scanToAuthCourseware', this.busResult);
  },
  methods: {
    getIPdata () {
      GetClientIP().then(result => {
        console.log("GetClientIP", result.data)
        if (this.dataInfo) {
          // isPCQrCode
          this.dataInfo.IP = result.data.IP
          this.dataInfo.isPCQrCode = 1;
          if (!this.dataInfo.ScheduleCourseKey || this.dataInfo.ScheduleCourseKey == 0) {
            delete this.dataInfo.ScheduleCourseKey;
          }
          this.dataInfo.client_id = this.$store.getters.clientId;
          console.log('this.dataInfo', this.dataInfo);
          let url = JSON.stringify(this.dataInfo);
          this.codeValue = url;

        }

      }).catch(error => {

      })
    },
    regisBus () {
      this.busResult = (res) => {
        console.log('this.openClassData', res.data);
        let geoInfo = {
          Longitude: res.data.Longitude || '',
          Latitude: res.data.Latitude || '',
          Accuracy: res.data.Accuracy || '',
          PositioningOffset: res.data.PositioningOffset || ''

        };
        this.$store.commit('setGeoInfo', geoInfo);
        this.$emit('afterSuccess', res.data);
        this.$emit('close');
      };
      this.$bus.on(this, 'scanToAuthCourseware', this.busResult);
    }
  }

};
</script>
<style scoped>
.course_auth_qrcode {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.course_auth_qrcode_title {
  font-size: 22px;
  height: 80px;
  padding-top: 30px;
}
.course_auth_qrcode_img {
  width: 100%;
  height: 190.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.course_auth_qrcode_img >>> canvas {
  height: 70.295rem !important;
  width: 70.295rem !important;
  border: 3.39rem solid #ffffff;
}
.course_auth_qrcode_img >>> img {
  width: 190.5px;
  height: 190.5px;
  align-items: center;
  justify-content: center;
}
.course_auth_qrcode_footer {
  font-size: 16px;
  margin-top: 30px;
}
.course_auth_qrcode_footer_img {
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url('../../../../../public/image/scan_login.png') center no-repeat;
  background-size: 18px;
  background-color: #e64024;
  border-radius: 50%;
  position: relative;
  top: 12px;
}
</style>