<template>
  <div v-if="item.stepName!='end'"
       class="procedure_index"
       :class="item.calssName"
       :style="{left: item.left}"
       @click.stop="clickEvent">
    <div :style="{display:vIsDone}">{{item.stepName}}</div>
    <span class="procedure_text">{{item.title}}</span>
  </div>
  <div v-else
       class="procedure_end"
       :class="item.calssName">
    <span class="procedure_text">{{item.title}}</span>
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {
        return {
          title: '步骤一',
          stepName: '1',
          status: 'undo', // undo:未做, doing:正在做，done：已做,
          calssName: '',
          left: '50%'// 左边距离（适应多个步骤）
        };
      }
    }
  },
  computed: {
    vIsDone () {
      if (this.item.status == 'done') {
        return 'none';
      } else {
        return 'block';
      }
    }
  },
  methods: {
    clickEvent () {
      if (this.item.status == 'done') {
        this.$emit('onClickEvent', this.item);
      }
    }
  }

};
</script>

<style>
</style>