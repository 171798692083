<template>
	<!-- <scroll-view scroll-x="true" :scroll-left="scrollLeft" class="" > -->
		<div class="bgGray_nav_box bg_white">
			<btn-item class="bgGray_nav_list" v-for="item in btnList" :key="item.key" :itemData="item" @click="doClick">
		</btn-item>
		</div>
	<!-- </scroll-view> -->
</template>

<script>
import BtnItem from './item.vue';
export default {
  data () {
    return {
      curSelectionItem: null// 当前选中项
    };
  },
  components: {
    BtnItem
  },
  props: {
    btnList: {
      type: Array,
      default: function () {
        return [{
          key: 0,
          value: '',
          isSelection: false // 是否选中
        }];
      }
    },
    scrollLeft: {    // 传递进来的左滚动值
      type: Number,
      default: 0
    }
  },
  methods: {
    doClick (item) {
      console.log(this.scrollLeft, 'doClick');
      for (let i = 0; i < this.btnList.length; i++) {
        let btnInfo = this.btnList[i];
        if (item.key !== btnInfo.key) {
          this.$set(btnInfo, 'isSelection', false);
        }
      }
      this.$emit('selectedItem', item);
    }
  }
};
</script>

  <style>
</style>
