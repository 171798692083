<template>
  <div class="WKT_discount_coupon_list" 
           @click="doSelectCouponAdded" :class="{disabled:Item.unSelection,opt:vSelection,type_one:Item.status == 2,type_two:Item.status == 3,only_browse:isBrowse}">
    <div class="pr" >
      <div class="WKT_discount_coupon_box">

        <div class="WKT_discount_coupon_left">
          <div class="coupon_amount_scope"
               v-if="Item.type==3">特价券</div>
          <div class="coupon_amount">
            <span class="font_unit">￥<span :style="vMoneySize">{{vParseInt}}</span><span class="font_number_small" v-if="vDecimals">.{{vDecimals}}</span></span>
            <!-- <span class="font_unit">￥</span>{{vCouponMoney}} -->
          </div>
          <div class="coupon_amount_scope"
               :style="(Item.type==2||Item.type==1)?'border: 1px solid #FFFFFF':'border:none'">
            <span v-if="Item.type==2 ||Item.type==1">满{{$utils.mMoneyType(Item.xiao_fei_tiao_jian,2) ||(isView?'XX':'')}}元使用</span>
          </div>
          <div class="coupon_scope">
            <span v-if="Item.only_newuser==1">限新生领取</span>
          </div>
        </div>

        <div class="WKT_discount_coupon_right">
          <div class="coupon_name">
            <span>{{vScopeExplain}}</span>
          </div>
          <div class="coupon_date">
            有效期：{{Item.time_start ||(isView?'XXXX-XX-XX':'')}} 至 {{Item.time_end ||(isView?'XXXX-XX-XX':'')}}
          </div>
          <div class="coupon_unfold_btn"
               :class="isShowExplain?'coupon_close_btn':''"
               @click.stop="showExplain">使用说明
          </div>
        </div>
      </div>
      <div class="WKT_discount_coupon_explain"
           v-if="isShowExplain">
        <pre>{{Item.coupon_discribe}}</pre>
      </div>
    </div>
    <div class="list_btn_box"
         v-if="isShowbtn">
      <operation-btn :isShowSetTop="true"
                     :item="Item"
                     :dataList="dataList"
                     @promote="promote"
                     @decline="decline"
                     @edit="edit"
                     @remove="remove"
                     @setTop="setTop"></operation-btn>
    </div>
  </div>

</template>
<script>
import operationBtn from '../../common/operation-btn';
import { MoveSimpleCoupon, SoldOutSimpleCoupon } from '../../../../API/workbench';
export default {
  components: {
    operationBtn
  },
  data () {
    return {
      searchText: '',
      isShowExplain: false
    };
  },
  props: {
    Item: Object,
    isView: Boolean,
    Index: Number,
    dataList: Array,
    isShowbtn: Boolean,
    selectionKey: Number,
    isBrowse: Boolean
  },
  created () {
    if (this.isView) {
      this.isShowExplain = true;
    }
    // this.isShowExplain = !!this.Item.coupon_discribe;
  },
  watch: {

  },
  computed: {
    vScopeExplain () {
      if (this.Item.scopeExplain) {
        return this.Item.scopeExplain;
      } else {
        return this.isView ? '适用XX' : '--';
      }
    },
    vCouponMoney () {
      switch (Number(this.Item.type)) { // 优惠方式 2满减, 3.特价 4.代金券
        case 1:
        case 2:
        case 4:
          return Number(this.Item.di_xian_jin) || (this.isView ? 'XX' : 0);
        case 3:
          return Number(this.Item.you_hu_money) || (this.isView ? 'XX' : 0);
        default:
          return '';
      }
    },
    // 整数显示
    vParseInt () {
      if (Number(this.vCouponMoney) > 0) {
        return parseInt(this.vCouponMoney);
      } else {
        return this.vCouponMoney;
      }
    },
    // 小数显示
    vDecimals () {
      if (this.vCouponMoney && JSON.stringify(this.vCouponMoney).indexOf('.') > -1) {
        return JSON.stringify(this.vCouponMoney).split('.')[1];
      } else {
        return '';
      }
    },
    //  金额显示尺寸
    vMoneySize () {
      let val = this.isShowbtn ? 0 : 10;
      let lengthSize = JSON.stringify(this.vParseInt).length;
      if (lengthSize >= 1 && lengthSize < 4) {
        return 'font-size:' + (36 - val) + 'px;';
      } else {
        return 'font-size:' + (28 - val) + 'px;';
      }
    },
    vSelection () { // 选中状态
      return this.selectionKey == this.Item.you_hui_id;
    }
  },
  methods: {
    showExplain () {
      if (this.isView) {
        return false;
      }
      this.isShowExplain = !this.isShowExplain;
    },
    // 移动
    setMoveSimpleCoupon (id, indedx) {
      MoveSimpleCoupon(id, indedx).then(result => {
        // this.$emit('afterMoveActivity');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 上升
    promote () {
      console.log('上升');
      let Indedx = this.Index;
      Indedx = (Indedx == 0 ? 1 : Indedx);
      this.setMoveSimpleCoupon(this.Item.id, Indedx);
      // document.getElementsByClassName('WKT_discount_coupon_ul')[0].scrollTop = this.Index * 221 - 221;
    },
    // 下降
    decline () {
      console.log('下降');
      let Indedx = this.Index + 3;
      this.setMoveSimpleCoupon(this.Item.id, Indedx);
      // document.getElementsByClassName('WKT_discount_coupon_ul')[0].scrollTop = this.Index * 221 + 221;
    },
    // 置顶
    setTop () {
      console.log('置顶');
      this.setMoveSimpleCoupon(this.Item.id, 1);
      document.getElementsByClassName('WKT_discount_coupon_ul')[0].scrollTop = 0;
    },
    // 修改
    edit () {
      this.$emit('edit', this.Item);
    },
    // 下架
    remove () {
      layer.confirm('是否需要下架该优惠券?', {
        btn: [{
          name: '确认',
          callBack: () => {
            SoldOutSimpleCoupon(this.Item.id).then(result => {
              this.dataList.splice(this.Index, 1);
            }, error => {
              layer.alert(error.msg);
            });
            layer.close();
          }
        }
        ]
      });
      console.log('删除');
    },
    // 上架
    doSelectCouponAdded () {
      if (!this.isShowbtn && !this.Item.unSelection) {
        this.$emit('doSelectCouponAdded', this.Item);
      }
    }
  }
};
</script>

