<template>
  <!-- 加盟店表单 -->
  <div id="mendain_set_form"
       style="height: 650px;overflow-y: auto;">
    <div class="form_info_edit form_info_list">
      <input-text :formTitle="'校区名'"
                  :required="true"
                  :readonly="titleName != '添加'"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.CampusName"></input-text>

      <input-number-alphabet :formTitle="'门店ID'"
                             :required="true"
                             :readonly="titleName != '添加'"
                             :formPlaceholder="'请输入'"
                             :formInputHint="vCurrentMendianId"
                             v-model="dataInfo.SaaSClientID"></input-number-alphabet>

      <input-text :formTitle="'管理员'"
                  :required="true"
                  :readonly="titleName != '添加'"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.AdminName"></input-text>

      <input-phone :formTitle="'管理员手机号'"
                   :formPlaceholder="'请输入'"
                   :required="true"
                   :readonly="titleName != '添加'"
                   v-model="dataInfo.DefaultMobile"></input-phone>
       <div v-if="isFlowSet">
        <input-select :formTitle="'流量设置'"
                      :id="'type'"
                      :name="'name'"
                      :required="true"
                      :readonly="isReadonly"
                      :selectedInfo="vflowSetInfo"
                      :dataList="flowSetType"
                      @selectedItem="changeFlowSet"></input-select>
      </div>
      <div class="from_input_list"
           v-if="vshowOption">
        <div class="from_input_title form_info_required">
          开通功能
        </div>
        <div class="from_list_select flex"
             style="align-items: center;">
          <el-checkbox v-model="dataInfo.IsOpenAcademicKey"
                       true-label="1"
                       false-label="0"
                       :disabled="titleName != '添加'"
                       @change="seletedEdu">教务</el-checkbox>
          <el-checkbox v-model="dataInfo.IsOpenChannelKey"
                       true-label="1"
                       false-label="0"
                       :disabled="titleName != '添加'"
                       @change="seletedCW">课件</el-checkbox>
        </div>
      </div>
      <!-- <input-select :formTitle="'加盟类型'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :readonly="isReadonly ||vIsEdit"
                    :dataList="sourceType"
                    :selectedInfo="vSourceTypeInfo"
                    @selectedItem="changeSourceType"></input-select> -->

      <input-search-cities :formTitle="'所在地区'"
                           :required="false"
                           :readonly="titleName != '添加'"
                           :selectedInfo="locationInfo"
                           @selectedItem="changeRegion"></input-search-cities>

      <div class="form_info_edit form_info_list pr location_text"
           style="padding:0px">
        <input-text :formTitle="'详细地址'"
                    :formPlaceholder="'请输入'"
                    :readonly="titleName != '添加'"
                    :required="false"
                    v-model="dataInfo.Address"></input-text>

      </div>
      <input-dialog :formTitle="'地图定位'"
                    class="site_icon_type"
                    :customPlaceholder="true"
                    :formPlaceholder="'设置'"
                    :formInputHint="dataInfo.SaaSClientSetting[0].SetContent?'':'未设置定位'"
                    :required="false"
                    :hiddenInput="true"
                    :readonly="isReadonly||vIsEdit"
                    :class="dataInfo.SaaSClientSetting[0].SetContent?'content':''"
                    :value="dataInfo.SaaSClientSetting[0].SetContent ||'设置'"
                    @showEldilog="isShowLocationForm=true">
        <span class="student_view_title_next"
              v-if="titleName!='添加'"
              style="left: 82px;top: 15px;position: absolute;"
              @click.stop="openHistoryLocationPopup">历史定位</span>
      </input-dialog>

      <div class="mendian_location pr"
           v-if="vLocationImg != ''">
        <img :src="vLocationImg"
             crossOrigin="anonymous"
             alt="">
      </div>
      <input-number v-if="!CampusKey"
                    :min="0"
                    :formTitle="'开通时长（年）'"
                    :formPlaceholder="'请输入'"
                    :decimalPlaces="0"
                    :required="true"
                    :readonly="isReadonly ||vIsEdit"
                    v-model="dataInfo.DeductCount"></input-number>

      <input-text v-if="!CampusKey"
                  :min="0"
                  :formTitle="'扣除端口数（个）'"
                  :decimalPlaces="0"
                  :required="false"
                  :readonly="true"
                  :className="'font_black'"
                  v-model="vDeductCountTitle"></input-text>

      <!-- v-model="dataInfo.DeductCount" -->

      <input-dialog v-if="isShowInput"
                    :formTitle="'招商顾问'"
                    :formPlaceholder="'请输入'"
                    :required="false"
                    :readonly="isReadonly ||vIsEdit"
                    v-model="dataInfo.CounselorKeyValue"
                    @showEldilog="selectCounselor"></input-dialog>

      <input-date v-if="isShowInput"
                  v-model="dataInfo.LeagueTime"
                  :formTitle="'加盟日期'"
                  :readonly="isReadonly "
                  :elementId="'BirthdayTime'"
                  :required="false"
                  :formPlaceholder="'选填'"></input-date>
      <div v-if="isReadonly">
        <input-text :formTitle="'教务功能'"
                    class=""
                    :formPlaceholder="'请输入'"
                    :readonly="isReadonly"
                    :required="false"
                    :value="vExpiredTime"></input-text>
      </div>
      <div v-if="isReadonly">
        <input-text :formTitle="'课件功能'"
                    class=""
                    :formPlaceholder="'请输入'"
                    :readonly="isReadonly"
                    :required="false"
                    :value="vCoursewareChannelExpiredTime"></input-text>
      </div>
     

    </div>
    <div v-if="!isReadonly">
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
    <custom-dialog :title="'历史定位'"
                   width="540px"
                   :visible.sync="isShowHistoryLocation"
                   :before-close="closeHistoryLocationPopup">
      <history-location :CampusKey="Number(CampusKeyValue)"
                        @close="closeHistoryLocationPopup"></history-location>
    </custom-dialog>
    <custom-dialog :title="'位置'"
                   :visible.sync="isShowLocationForm"
                   :before-close="closeLocationFormPopup">
      <form-location :locationInfo="dataInfo.SaaSClientSetting"
                     @close="closeLocationFormPopup"
                     @setLocationInfo="setLocationInfo"></form-location>
    </custom-dialog>
  </div>
</template>
<script>
import { FranchiseeManagementModify, FranchiseeManagementAdd, GetCampusDetails, GetOnJobEmployees, GetSaaSClientSet, AdjustmentCurrentFluxObject } from '../../../../../API/workbench';
import historyLocation from '../../campus-management/form/history-location.vue';
export default {
  data () {
    return {
      EduNum: 0,
      CWNum: 0,
      isFlowSet: false,
      // 流量设置
      flowSetType: [
        { type: 1, name: '总部' },
        { type: 2, name: '门店' }
      ],
      getDate: new Date(),
      // 直营类型
      sourceType: [],
      locationInfo: [], // 地址
      dataInfo: {
        CampusName: '', // 校区名
        SaaSClientID: '', // 门店ID
        Principal: '', // 负责人
        MobilePhone: '', // 手机号
        AdminName: '', // 管理员
        DefaultMobile: '', // 管理员手机号
        TypeKey: '', // 加盟类型
        TypeKeyValue: '', // 1-直营参股;2-直营直属;3-课件加盟;4-整店加盟
        ProvinceKey: '', // 省
        ProvinceKeyValue: '',
        CityKey: '', // 市
        CityKeyValue: '',
        AreaKey: '', // 区
        AreaKeyValue: '',
        Address: '', // 详细地址
        CounselorKey: '', // 招商顾问
        CounselorKeyValue: '',
        DeductCount: 1, // 扣除端口数
        LeagueTime: this.$utils.formatDateToLine(Date.new()), // 加盟日期
        IsOpenAcademicKey: '',
        IsOpenChannelKey: '',
        ExpiredTime: '',
        CoursewareChannelExpiredTime: '',
        CurrentFluxObjectKey: 1, // 1-总部;2-门店
        CurrentFluxObjectKeyValue: '总部', // 总部、门店
        NextFluxObjectKey: 0, // 下个月扣费对象	 1-总部;2-门店;0-未设置
        NextFluxObjectKeyValue: '', // 下个月扣费对象	 1-总部;2-门店;0-未设置
        SaaSClientSetting: [
          { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '14' }, // 定位地址信息
          { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '15' }, // 定位经纬度信息
          { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '16' }// 定位截图信息
        ]
      },
      isShowHistoryLocation: false,
      CampusKeyValue: '',
      isShowLocationForm: false // 位置
    };
  },
  components: {
    historyLocation
  },
  props: {
    newData: Object,
    CampusKey: Number,
    isReadonly: Boolean,
    isShowInput: {
      type: Boolean,
      default: false
    },
    titleName: String //
  },
  created () {
    if (this.CampusKey > 0) {
      this.getDetailInfo();
    } else {
      this.getMenDianSet();
    }
    if (this.newData) {
      this.dataInfo.CampusName = this.newData.MainDemoName;
      this.dataInfo.AdminName = this.newData.PrincipalName;
      this.dataInfo.DefaultMobile = this.newData.MobilePhone;
      this.dataInfo.Address = this.newData.Address;
    }
    console.log(this.CampusKey, 'CampusKey222222', this.titleName);
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
  },
  computed: {
    // 流量设置
    vflowSetInfo () {
      if (this.CampusKey > 0) {
        return {
          type: this.dataInfo.NextFluxObjectKey,
          name: this.dataInfo.CurrentFluxObjectKeyValue
        };
      } else {
        return {
          type: this.dataInfo.CurrentFluxObjectKey,
          name: this.dataInfo.CurrentFluxObjectKeyValue
        };
      }
    },
    vshowOption () {
      if (this.isReadonly) {
        return false;
      } else if (this.vIsEdit) {
        return false;
      } else {
        return true;
      }
    },
    vIsEdit () {
      if (this.titleName == '修改') {
        return false;
      } else {
        if (this.CampusKey > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    // 当前门店id
    vCurrentMendianId () {
      return this.$store.getters.SaaSClientInfo.SaaSClientNO + '-';
    },
    // 直营类型
    vSourceTypeInfo () {
      return {
        type: this.dataInfo.TypeKey,
        name: this.dataInfo.TypeKeyValue
      };
    },
    vDeductCountTitle () {
      let text = '';
      if (this.dataInfo.DeductCount > 0) {
        text += this.dataInfo.IsOpenAcademicKey == 1 ? '教务*' + this.dataInfo.DeductCount : '';
        if (this.dataInfo.IsOpenAcademicKey == 1) {
          text += this.dataInfo.IsOpenChannelKey == 1 ? ';课件*' + this.dataInfo.DeductCount : '';
        } else {
          text += this.dataInfo.IsOpenChannelKey == 1 ? '课件*' + this.dataInfo.DeductCount : '';
        }
        console.log(this.dataInfo, '', text);
      }
      return text;
    },
    // 是否开通教务-日期
    vExpiredTime () {
      if (this.dataInfo.IsOpenAcademicKey == 1) {
        return this.dataInfo.ExpiredTime;
      } else {
        return '未开通';
      }
    },
    // 是否开通课件-日期
    vCoursewareChannelExpiredTime () {
      if (this.dataInfo.IsOpenChannelKey == 1) {
        return this.dataInfo.CoursewareChannelExpiredTime;
      } else {
        return '未开通';
      }
    },
    // 定位截图
    vLocationImg () {
      if (this.dataInfo && this.dataInfo.SaaSClientSetting[2].SetContent) {
        return this.$store.getters.CDNURL + this.dataInfo.SaaSClientSetting[2].SetContent;
      } else {
        return '';
      }
    }
  },
  methods: {

    closeLocationFormPopup () {
      this.isShowLocationForm = false;
    },
    setLocationInfo (data) {
      this.dataInfo.SaaSClientSetting = data;
      console.log(data, 'locationInfo');
      this.closeLocationFormPopup();
    },
    // 选择流量设置
    changeFlowSet (obj) {
      console.log(obj, '选择来源方式');
      if (this.CampusKey > 0) {
        this.dataInfo.NextFluxObjectKey = obj.type;
      } else {
        this.dataInfo.CurrentFluxObjectKey = obj.type;
      }
      this.dataInfo.CurrentFluxObjectKeyValue = obj.name;
      console.log(this.dataInfo.CurrentFluxObjectKey, 'this.dataInfo.CurrentFluxObjectKey');
    },
    seletedCW (val) {
      this.CWNum = val;
      if (val == 1) {
        this.isFlowSet = true;
        this.dataInfo.CurrentFluxObjectKey = 1;
        this.dataInfo.CurrentFluxObjectKeyValue = '总部';
      } else {
        this.dataInfo.CurrentFluxObjectKeyValue = '未设置';
        this.isFlowSet = false;
      }
      console.log(val, 'seletedCW');
    },
    seletedEdu (val) {
      console.log(val, 'seletedEdu');

      this.EduNum = val;
      if (this.CWNum == 1) {
        this.dataInfo.CurrentFluxObjectKeyValue = '';
      } else {
        this.dataInfo.CurrentFluxObjectKeyValue = '未设置';
      }
    },
    // 获取门店设置
    getMenDianSet () {
      GetSaaSClientSet().then(result => {
        result.data.forEach(o => {
          if (o.SetTypeKey == 4 && o.SetKey == 1) { // 是否显示课件加盟
            this.sourceType.push({ type: 3, name: '课件加盟' });
          }
          if (o.SetTypeKey == 5 && o.SetKey == 1) { // 是否显示整店加盟
            this.sourceType.push({ type: 4, name: '整店加盟' });
          }
        });
        if (this.sourceType.length > 0) {
          this.dataInfo.TypeKey = (this.sourceType[0].type == 3 ? 3 : 4);
          this.dataInfo.TypeKeyValue = (this.sourceType[0].type == 3 ? '课件加盟' : '整店加盟');
        }
        console.log('GetSaaSClientSet', result.data);
      });
    },
    // 选择直营类型
    changeSourceType (obj) {
      console.log(obj, '选择来源方式');
      this.dataInfo.TypeKey = obj.type;
      this.dataInfo.TypeKeyValue = obj.name;
    },
    // 省市区
    changeRegion (keys, values) {
      this.dataInfo.ProvinceKey = keys[0] || '';// 省
      this.dataInfo.ProvinceKeyValue = values[0] || ''; // 省
      this.dataInfo.CityKey = keys[1] || '';// 市
      this.dataInfo.CityKeyValue = values[1] || ''; // 市
      this.dataInfo.AreaKey = keys[2] || ''; // 区/县
      this.dataInfo.AreaKeyValue = values[2] || ''; // 区/县
      console.log(keys, values, '省市区');
    },
    // 选择招商顾问
    selectCounselor () {
      GetOnJobEmployees().then(result => {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择招商顾问',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.dataInfo.CounselorKey, dataSourceList: result.data, required: false, ModulePowerKey: 0 },
          callBack: {
            onSelectedItem: (data) => {
              console.log(data, '选择招商顾问');
              this.dataInfo.CounselorKey = data.OLAPKey;
              this.dataInfo.CounselorKeyValue = data.MainDemoName;
            }
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取校区详情信息
    getDetailInfo () {
      GetCampusDetails(this.CampusKey).then(result => {
        console.log('获取校区详情信息', result.data);
        this.isFlowSet = true;
        this.locationInfo = [result.data.ProvinceKey, result.data.CityKey, result.data.AreaKey];
        Object.assign(this.dataInfo, result.data);
        this.dataInfo.CampusName = result.data.MainDemoName;
        this.dataInfo.SaaSClientID = result.data.SaaSClientNO;
        this.dataInfo.AdminName = result.data.AdminName;
        this.dataInfo.Principal = result.data.PrincipalName;
        this.dataInfo.LeagueTime = this.dataInfo.LeagueTime.slice(0, 10);
        if (result.data.CurrentFluxObjectKey == 1 && result.data.NextFluxObjectKey != 2) {
          this.dataInfo.CurrentFluxObjectKeyValue = '总部负担';
        } else if (result.data.CurrentFluxObjectKey == 1 && result.data.NextFluxObjectKey == 2) {
          if ((this.getDate.getMonth() + 2) == 13) {
            this.dataInfo.CurrentFluxObjectKeyValue = '总部负担,1月1号起门店负担';
          } else {
            this.dataInfo.CurrentFluxObjectKeyValue = '总部负担,' + (this.getDate.getMonth() + 2) + '月1号起门店负担';
          }
        } else if (result.data.CurrentFluxObjectKey == 2 && result.data.NextFluxObjectKey != 1) {
          this.dataInfo.CurrentFluxObjectKeyValue = '门店负担';
        } else if (result.data.CurrentFluxObjectKey == 2 && result.data.NextFluxObjectKey == 1) {
          if ((this.getDate.getMonth() + 2) == 13) {
            this.dataInfo.CurrentFluxObjectKeyValue = '门店负担,1月1号起总部负担';
          } else {
            this.dataInfo.CurrentFluxObjectKeyValue = '门店负担,' + (this.getDate.getMonth() + 2) + '月1号起总部负担';
          }
        } else if (result.data.CurrentFluxObjectKey == 0 && result.data.NextFluxObjectKey == 1) {
          if ((this.getDate.getMonth() + 2) == 13) {
            this.dataInfo.CurrentFluxObjectKeyValue = '暂时未设置，1月1号起总部负担';
          } else {
            this.dataInfo.CurrentFluxObjectKeyValue = '暂时未设置，' + (this.getDate.getMonth() + 2) + '月1号起总部负担';
          }
        } else if (result.data.CurrentFluxObjectKey == 0 && result.data.NextFluxObjectKey == 2) {
          if ((this.getDate.getMonth() + 2) == 13) {
            this.dataInfo.CurrentFluxObjectKeyValue = '暂时未设置，1月1号起门店负担';
          } else {
            this.dataInfo.CurrentFluxObjectKeyValue = '暂时未设置，' + (this.getDate.getMonth() + 2) + '月1号起门店负担';
          }
        } else {
          this.dataInfo.CurrentFluxObjectKeyValue = '未设置';
        }
        // this.getLocation()
        console.log('地址', this.locationInfo);
      });
    },
    // 提交
    submit (callback) {
      console.log('submit', this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      this.dataInfo.Principal = this.dataInfo.AdminName;
      this.dataInfo.MobilePhone = this.dataInfo.DefaultMobile;
      if (this.CampusKey > 0) {
        FranchiseeManagementModify(this.CampusKey, this.dataInfo).then(result => {
          layer.alert('修改成功');
          this.$emit('afterSuccess');
          this.cancel();
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callback) {
            callback();
          }
        });
        // 流量设置接口
        AdjustmentCurrentFluxObject(this.CampusKey, this.dataInfo.NextFluxObjectKey).then(result => {
          layer.alert('修改成功');
          this.$emit('afterSuccess');
          this.cancel();
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callback) {
            callback();
          }
        });
      } else {
        var obj = this.$utils.parseJson(this.dataInfo);
        obj.SaaSClientID = this.$store.getters.SaaSClientInfo.SaaSClientNO + '-' + this.dataInfo.SaaSClientID;
        FranchiseeManagementAdd(obj).then(result => {
          layer.alert('新增成功');
          this.$emit('afterSuccess');
          this.cancel();
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callback) {
            callback();
          }
        });
      }
    },
    // 验证客户信息
    verificationInfo () {
      this.dataInfo.DeductCount = parseInt(this.dataInfo.DeductCount);
      if (this.dataInfo.CampusName) {
        this.dataInfo.CampusName = this.dataInfo.CampusName.replace(/(^\s*)|(\s*$)/g, '');
      }
      let flag = true;
      if (!this.dataInfo.CampusName) {
        layer.alert('请输入校区名');
        flag = false;
      } else if (!this.dataInfo.SaaSClientID) {
        layer.alert('请输入大于0以上门店ID');
        flag = false;
      } else if (!this.dataInfo.AdminName) {
        layer.alert('请输入管理员');
        flag = false;
      } else if (this.dataInfo.DefaultMobile.length != 11) {
        layer.alert('请输入手机号');
        flag = false;
      } else if (!this.dataInfo.DeductCount) {
        layer.alert('请输入开通时长（年）');
        flag = false;
      } else if (!this.dataInfo.CurrentFluxObjectKeyValue) {
        layer.alert('请选择流量设置');
        flag = false;
      } else if (this.dataInfo.IsOpenAcademicKey == 0 && this.dataInfo.IsOpenChannelKey == 0 && !this.CampusKey) {
        layer.alert('请选择开通功能');
        flag = false;
      }

      return flag;
    },
    closeHistoryLocationPopup () {
      this.isShowHistoryLocation = false;
    },
    openHistoryLocationPopup () {
      console.log('okokok', this.dataInfo.ClientKey);
      this.CampusKeyValue = this.dataInfo.ClientKey;
      this.isShowHistoryLocation = true;
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.location_text >>> .form_info_field {
  margin-right: 62px;
}
</style>