<template>
  <div>
    <span class="defult_dialog_header_btn fr"
          :class="vHasPower?'':'norole'"
          @click="doAddItmeClick"
          v-if="addTitleName">
      <span class="btn_add">+</span>{{addTitleName}}
    </span>
  </div>
</template>
<script>
export default {
  data () {
    return {
      newAddmoduleName: this.addmoduleName
    };
  },
  props: {
    // 标题
    addTitleName: {
      // 必填,没有则不显示
      type: String,
      required: ''
    },
    // 选择组件
    addmoduleName: {
      // 标题，必填,没有则不显示
      type: String,
      required: ''
    },
    // 组件key
    addmoduleKey: {
      // 标题，必填,没有则不显示
      type: [Number, String],
      required: '',
      default: 0
    },
    // 组件名
    addmoduleTitleName: {
      // 标题，必填,没有则不显示
      type: String,
      required: ''
    }
  },
  components: {

  },
  computed: {
    vHasPower () {
      return this.$utils.CheckModulePower(this.$store.getters.RolePowerList, this.addmoduleKey);
    }
  },
  created () {
    this.showModulesName();
  },
  mounted () {
    this.$dialog.register(this,
      [
        'courseNameSetting',
        'departmentSetting',
        'postSetting',
        'incomeAndExpensesSubjectSetting',
        'squadClassSetting',
        'proceedsAccountSetting',
        'incidentalsSetting',
        'supplierSetting',
        'classroomSetting',
        'communitySetting',
        'sellSourceSetting',
        'yuanGongSetting',
        'registercustomer',
        'goodsNameSetting',
        'taoCanSetting',
        'cwsAddClass',
        'cwsAddTeacher',
        'cwsAddStudent'
      ]);
  },
  methods: {
    // 处理一些特例
    showModulesName () {
      if (this.addmoduleName && this.addmoduleName.indexOf('老师') > -1) { // 防止标题不统一
        this.newAddmoduleName = 'yuangong-setting';
      }
      switch (this.addmoduleName) {
        case '教室':
          this.newAddmoduleName = 'classroom-setting';
          break;
        case '班级':
          this.newAddmoduleName = 'squad-class-Setting';
          break;
        default:
          break;
      }
    },
    // 新增操作事件
    doAddItmeClick () {
      if (this.vHasPower) {
        switch (this.newAddmoduleName) {
          case 'course-name-setting':
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: '',
              name: '新增课程',
              moduleName: 'courseNameSetting',
              data: { editItem: {}, isReadonly: false },
              callBack: {
                afterSuccess: (type, data) => { this.addSuccessEvent(data); },
                closeDialog: (item) => {
                  this.$emit('cancelClick');
                }
              }
            });
            break;
          case 'department-setting':
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: '',
              name: '新增部门',
              moduleName: 'departmentSetting',
              data: { editItem: {}, isReadonly: false },
              callBack: {
                afterSuccess: (type, data) => { this.addSuccessEvent(data); }
              }
            });
            break;
          case 'post-setting':
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: '',
              name: '新增职务',
              moduleName: 'postSetting',
              data: { editItem: {}, isReadonly: false },
              callBack: {
                afterSuccess: (type, data) => { this.addSuccessEvent(data); }
              }
            });
            break;
          case 'incidentals-setting':
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: '',
              name: '新增学杂费',
              moduleName: 'incidentalsSetting',
              data: { editItem: {}, isReadonly: false },
              callBack: {
                afterSuccess: (type, data) => { this.addSuccessEvent(data); }
              }
            });
            break;
          case 'yuangong-setting':
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: 44,
              name: this.addmoduleTitleName,
              moduleName: 'yuanGongSetting',
              data: { staffID: 0, staffType: this.addmoduleTitleName, isReadonly: false },
              callBack: {
                afterSuccess: (type, data) => { this.addSuccessEvent(data); }
              }
            });
            break;
          case 'register-customer':
            this.$dialog.open(this, {
              name: '登记新生',
              routerName: this.$route.name,
              ModulePowerKey: 48,
              moduleName: 'registercustomer',
              callBack: { doafterRegisterCustomer: (data) => { this.addSuccessEvent(data); } }
            });
            break;
          case 'add-old-student':
            let addOldStudentInfo = {
              name: '录入老生',
              routerName: this.$route.name,
              routertitle: '',
              ModulePowerKey: 49,
              moduleName: 'addOldStudent',
              callBack: { addSuccess: (data) => { this.addSuccessEvent(data); } }
            };
            this.$bus.emit('needOpenRouter', addOldStudentInfo);
            break;
          case 'squad-class-Setting':
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: '',
              name: '新增班级',
              moduleName: 'squadClassSetting',
              data: { editItem: {}, isReadonly: false },
              callBack: {
                afterSuccess: (type, data) => { this.addSuccessEvent(data); }
              }
            });
            break;
          case 'classroom-setting':
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: '',
              name: '新增教室',
              moduleName: 'classroomSetting',
              data: { editItem: {}, isReadonly: false },
              callBack: {
                afterSuccess: (type, data) => { this.addSuccessEvent(data); }
              }
            });
            break;
          case 'community-setting':
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: '',
              name: '新增住宅',
              moduleName: 'communitySetting',
              data: { editItem: {}, isReadonly: false },
              callBack: {
                afterSuccess: (type, data) => { this.addSuccessEvent(data); }
              }
            });
            break;
          case 'sell-source-setting':
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: '',
              name: '新增来源方式',
              moduleName: 'sellSourceSetting',
              data: { editItem: {}, isReadonly: false },
              callBack: {
                afterSuccess: (type, data) => { this.addSuccessEvent(data); }
              }
            });
            break;
          case 'supplier-setting':
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: '',
              name: '新增供应商',
              moduleName: 'supplierSetting',
              data: { editItem: {}, isReadonly: false },
              callBack: {
                afterSuccess: (type, data) => { this.addSuccessEvent(data); }
              }
            });
            break;
          case 'goods-name-setting':
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: '',
              name: '新增物品名称',
              moduleName: 'goodsNameSetting',
              data: { dataInfo: {}, isReadonly: false },
              callBack: {
                afterSuccess: (type, data) => { this.addSuccessEvent(data); }
              }
            });
            break;
          case 'tao-can-setting':
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: '46',
              name: '新建套餐',
              moduleName: 'taoCanSetting',
              data: { TableID: 0, type: 'add' },
              callBack: {
                afterSuccess: (type, data) => { this.addSuccessEvent(data); }
              }
            });
            break;
          case 'cws-add-class':
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: '',
              name: '新增班级',
              moduleName: 'cwsAddClass',
              data: { TableID: 0, type: 'add' },
              callBack: {
                afterSuccess: (data) => { this.addSuccessEvent(data); }
              }
            });
            break;
          case 'cws-add-teacher':
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: '',
              name: '新增老师',
              moduleName: 'cwsAddTeacher',
              data: { TableID: 0, type: 'add' },
              callBack: {
                afterSuccess: (data) => { this.addSuccessEvent(data); }
              }
            });
            break;
          case 'cws-add-student':
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: '',
              name: '新增学生',
              moduleName: 'cwsAddStudent',
              data: { TableID: 0, type: 'add' },
              callBack: {
                afterSuccess: (data) => { this.addSuccessEvent(data); }
              }
            });
            break;
            case 'clewAddForm':
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: '',
              name: '新增线索',
              moduleName: 'clewAddForm',
              data: { },
              callBack: {
                afterSuccess: (data) => { this.addSuccessEvent(data); }
              }
            });
            break;
          default:
            this.$emit('doAddSuccessEvent');
            break;
        }
      } else {
        this.$utils.CheckModulePowerToTips(this.addmoduleKey);
      }
    },
    // 新增成功事件
    addSuccessEvent (result) {
      console.log(result, 'addSuccessEvent');
      setTimeout(() => { // 防止当前新增表单未关闭则就触发emit事件
        this.$emit('doAddSuccessEvent', result);
      }, 100);
    }
  }
};
</script>


