<template>
  <!-- 分析统计 -->
  <div class="chain_mendian_center_left"
       v-if="classStatisticsReportList.length>0">
    <div class="left_title">
      <div class="left_type">
        <div class="left_type_list "
             :class="statisticsDate.DateType==1?'opt':''"
             @click.stop="changeSearchType(1)">周</div>
        丨
        <div class="left_type_list"
             :class="statisticsDate.DateType==2?'opt':''"
             @click.stop="changeSearchType(2)">月</div>
        丨
        <div class="left_type_list"
             :class="statisticsDate.DateType==3?'opt':''"
             @click.stop="changeSearchType(3)">年</div>
        <div class="left_type_text type_blue">应到</div>
        <div class="left_type_text type_red">实到</div>
      </div>
      <div class="right_title_date">
        <div class="el-select">
          <div class="el-input el-input--small el-input--suffix"
               v-if="isShowDateType">
            <single-year-picker @chgDate="changeSaveDate"
                                :isDisableNow="isDisableNow"
                                :type="vDateType"
                                ref="monthPicker"
                                :maxDate="vMaxDate"
                                :isShowHistory="isShowHistory"
                                :minDate="vMinDate"></single-year-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="change_history_data"
         @click.stop="historyBtn(historyName)">{{ historyName }}</div>
    <div class="left_echart">
      <lineTypeEchart :echartID="'dual_histogram4'"
                      :reqList="monthList"
                      :nowDataList="nowList"
                      :preDataList="oldList"
                      :echartType="'line'"
                      :tipTitle="vTipsTitle"
                      ref="echart"></lineTypeEchart>
    </div>
  </div>
</template>
<script>
import singleYearPicker from '../../../../base-module/date-picker-common/single-year-picker';
import lineTypeEchart from '../../../../base-module/echart-common/line-type-echart';
import { MessageSearchAttendanceReportForGrade, MessageSearchAttendanceHistoryReportForGrade } from '../../../../../API/workbench';
export default {
  data () {
    return {
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      // 分析统计日期查询
      statisticsDate: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd'),
        DateType: '1', //		整型	必须		数据源：GET	 日期类型1-周;2-月;3-年
        GradeID: '100521'//		整型	必须		数据源：GET	 班级id
      },
      // yearDate: {
      //   startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
      //   endTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd')
      // },
      // monthDate: {
      //   startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
      //   endTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd')
      // },
      // weekDate: {
      //   startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
      //   endTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd')
      // },
      isDisableNow: true,          // 是否禁用今天以后的日期
      isShowDateType: true,
      // defaultDate: '',
      defaultDateType: 'month',
      classStatisticsReportList: [],
      attenReport: {},
      monthList: [],
      nowList: [],
      oldList: []

    };
  },
  props: {
    classKey: [Number, String]
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    },
    vTipsTitle () {
      if (this.statisticsDate.DateType == 2) {
        return '日';
      } else {
        return '';
      }
    },
    vDateType () {
      if (Number(this.statisticsDate.DateType) == 1) {
        return 'date';
      } else if (Number(this.statisticsDate.DateType) == 2) {
        return 'month';
      } else if (Number(this.statisticsDate.DateType) == 3) {
        return 'year';
      }
    }
  },
  watch: {
    'classKey': {
      handler (n, o) {
        console.log(n, '班级id-123');
        if (Number(n) > 0) {
          this.statisticsDate.GradeID = n;
          this.$nextTick(() => {
            this.setTime();
          });
        }
      },
      deep: true // 表示开启深度监听
    }
  },
  components: {
    lineTypeEchart,
    singleYearPicker
  },
  created () {

  },
  mounted () {
    this.setTime();
  },
  methods: {
    historyBtn (name) {
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.statisticsDate.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.statisticsDate.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isShowHistory = true;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.statisticsDate.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.statisticsDate.endTime = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0));
        this.isShowHistory = false;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.getAttenReport();
      this.$refs.monthPicker.changeHistoryTime(this.statisticsDate.endTime);
    },
    changeSearchType (val) {
      this.statisticsDate.DateType = val;
      this.isShowDateType = false;
      this.setTime();
    },
    // 切换日期后获取分析统计数据
    changeSaveDate (val, startTime, endTime) {
      console.log('开始日期：', startTime, '结束日期：', endTime);
      //  日期类型1-周;2-月;3-年

      this.statisticsDate.startTime = startTime.replace(/\//g, '-');
      this.statisticsDate.endTime = endTime.replace(/\//g, '-');
      this.getAttenReport();
    },
    setTime () {
      console.log(this.statisticsDate.startTime, 'setTime-setTime');
      if (this.statisticsDate.DateType == 1) {    // 选中周
        var today = Date.new();
        var weekday = today.getDay();
        var monday = Date.new(1000 * 60 * 60 * 24 * (1 - weekday) + today.getTime());
        var Sunday = Date.new(1000 * 60 * 60 * 24 * (7 - weekday) + today.getTime());
        this.statisticsDate.startTime = this.$utils.formatDateToLine(monday);
        this.statisticsDate.endTime = this.$utils.formatDateToLine(Sunday);
      } else if (this.statisticsDate.DateType == 2) { // 选中月
        this.statisticsDate.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.statisticsDate.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd');
      } else {      // //选中年
        this.statisticsDate.startTime = Date.new().getFullYear() + '-01-01';
        this.statisticsDate.endTime = Date.new().getFullYear() + '-12-31';
      }
      this.getAttenReport();
    },
    setMonthList () {
      if (this.statisticsDate.DateType == 1) {    // 选中周
        this.monthList = this.setEchartXForWeek();
      } else if (this.statisticsDate.DateType == 2) { // 选中月
        this.monthList = this.setEchartXForMonth();
        console.log(this.monthList, '选中月');
      } else {      // //选中年
        this.monthList = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
      }
    },
    // 设置X轴
    setEchartXForWeek () {
      let newArr = [];
      this.classStatisticsReportList.forEach((o, index) => {
        newArr.push(o.SetUpTime.substring(5) + ' ' + this.$utils.getWeekByTwo(o.SetUpTime));
      });
      return newArr;
    },
    // 设置X轴
    setEchartXForMonth () {
      let newArr = [];
      this.classStatisticsReportList.forEach((o, index) => {
        let num = index < 9 ? '0' + (index + 1) : index + 1;
        newArr.push(num);
      });
      return newArr;
    },
    // 分析统计
    getAttenReport (startTime, endTime) {
      let fn = '';
      if (this.isShowHistory) {
        fn = MessageSearchAttendanceHistoryReportForGrade;
      } else {
        fn = MessageSearchAttendanceReportForGrade;
      }
      console.log(this.classKey, this.statisticsDate, '班级ID');
      if (Number(this.classKey) > 0) {
        this.statisticsDate.GradeID = this.classKey;
        if (Number(this.statisticsDate.DateType) == 1) {
          var weekday = Date.new(this.statisticsDate.startTime).getDay();
          weekday = weekday == 0 ? 7 : weekday;
          var monday = Date.new(1000 * 60 * 60 * 24 * (1 - weekday) + Date.new(this.statisticsDate.startTime).getTime());
          var Sunday = Date.new(1000 * 60 * 60 * 24 * (7 - weekday) + Date.new(this.statisticsDate.startTime).getTime());
          this.statisticsDate.startTime = this.$utils.formatDateToLine(monday);
          this.statisticsDate.endTime = this.$utils.formatDateToLine(Sunday);
        }
        fn(this.statisticsDate).then(result => {
          this.isShowDateType = true;
          console.log(result.data, '分析统计');
          this.classStatisticsReportList = result.data;
          this.setMonthList();
          this.nowList = this.getNowList();
          this.oldList = this.getOldList();
          this.$nextTick(() => {
            this.$refs.echart.echartInit();
          });
        }).catch(error => {
          console.log('ErroretAttenReport', error);
        });
      }
    },
    // 应到
    getNowList () {
      let nowList = [];
      // let monthList = this.getDaysOfMonth(this.seletedDate);
      this.monthList.forEach(p => {
        let item = null;
        if (this.statisticsDate.DateType == 3) {
          item = this.classStatisticsReportList.find(o => {     // 区分年
            return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
          });
        } else if (this.statisticsDate.DateType == 2) {      // 区分月
          item = this.classStatisticsReportList.find(o => {
            return Date.new(o.SetUpTime).getDate() == parseInt(p);
          });
        } else {                    // 区分周
          item = this.classStatisticsReportList.find(o => {
            return p.substring(0, 5) == o.SetUpTime.substring(5);
          });
        }
        if (item) {
          nowList.push(Number(item.ArrangedNumCount).toFixed(0));
        } else {
          nowList.push('0');
        }
      });
      console.log(this.monthList, nowList, '应到');
      return nowList;
    },
    // 实到
    getOldList () {
      let nowList = [];
      this.monthList.forEach(p => {
        let item = null;
        if (this.statisticsDate.DateType == 3) {
          item = this.classStatisticsReportList.find(o => {     // 区分年
            return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
          });
        } else if (this.statisticsDate.DateType == 2) {      // 区分月
          item = this.classStatisticsReportList.find(o => {
            return Date.new(o.SetUpTime).getDate() == parseInt(p);
          });
        } else {                    // 区分周
          item = this.classStatisticsReportList.find(o => {
            return p.substring(0, 5) == o.SetUpTime.substring(5);
          });
        }
        if (item) {
          if (Date.new(Date.new().setDate((Date.new().getDate() - 1))) >= Date.new(item.SetUpTime)) {
            nowList.push(Number(item.ArrivedNumCount).toFixed(0));
          } else {
            if (Number(item.ArrivedNumCount) > 0) {
              nowList.push(Number(item.ArrivedNumCount).toFixed(0));
            }
          }
        }
      });
      console.log(nowList, 'nowLis123-t');
      return nowList;
    }
  }
};
</script>
<style scoped>
.change_history_data {
  position: absolute;
  margin-left: 700px;
  margin-top: -50px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  right: auto !important;
}
</style>
</script>
