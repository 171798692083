<template>
  <div class="credit_management_popup">
    <div class="credit_management_table">
      <div class="heard">
        <!-- :maxDate="vMaxDate" -->
        <div class="heardItem">
          <function-btn :item="{ name: '导出排课表', ModulePowerKey: '' }"
                        :className="'el-button btn_light_blue btn_hover_bg_blue iconPlanC'"
                        style="width: 96px;"
                        @clickItem="doExportInfoClick">
          </function-btn>
        </div>
        <div class="tableCourselistbox">
          <!-- <span>周排课汇总</span> -->
          <!-- <div class="tableCourselist">
            <span class="tableCourselistItem">
              {{ vCourseLength+'课次' }}
            </span>
            <div class="tableCourselistItemline"></div>
            <span class="tableCourselistItem">{{vStudentCount+'人次'}}</span>
            <div class="tableCourselistItemline"></div>
            <span class="tableCourselistItem">{{vPreClassCount+'课时'}}</span>
            <div class="tableCourselistItemline"></div>
            <span class="tableCourselistItem">{{vPreSaleAmountCount+'元'}}</span>
          </div> -->
          <div class="customer_table_box" style="margin-left: 40px;">
            <div class="table_title">周排课汇总：</div>
            <div class="table_name ">
              <!-- <span class="font_gray">剩</span> -->
              <span class="table_number font_blue">{{vCourseLength}}</span>
              <span class="unit_text">课次</span>
            </div>
            <div class="table_name ">
              <!-- <span class="font_gray">剩</span> -->
              <span class="table_number font_blue">{{vStudentCount}}</span>
              <span class="unit_text">人次</span>
            </div>
            <div class="table_name ">
              <!-- <span class="font_gray">临</span> -->
              <span class="table_number font_blue">{{vPreClassCount}}</span>
              <span class="unit_text">课时</span>
            </div>
            <div class="table_name ">
              <!-- <span class="font_gray">存</span> -->
              <span class="table_number font_blue">{{vPreSaleAmountCount}}</span>
              <span class="unit_text">元</span>
            </div>
          </div>

          <div style="margin-left: 20px;font-size: 16px;">日期：{{ startDate+'~'+endDate }}</div>
        </div>
      </div>
      <table-list class="summarizing_list table_list_content"
                  :tableData="vDataList"
                  :tableColumns="tableCourseColumns"
                  :totalNum="totalNum"
                  :showPagePagination="false"
                  :footerContent="'共' + vDataList.length + '条数据'"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import tableList from '../../common/table-list/index.vue';
export default {
  data () {
    return {
      tableData: {},
      totalNum: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '周几',
          prop: 'WeekKeyValue',
          width: 50,
          sortable: false,
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '时间',
          width: 110,
          prop: 'ClassTimeName',
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '班级',
          prop: 'GradeClassKeyValue',
          width: 160,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '老师',
          prop: 'MTeacherKeyValue',
          width: 80,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '课程',
          prop: 'CourseNameKeyValue',
          width: 160,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '学生数',
          prop: 'ArrangedNumCount',
          width: 70,
          sortable: false,
          align: 'left',
          type: 'numberItem'
        },
        {
          label: '预消课时',
          prop: 'PreClassCount',
          width: 80,
          sortable: false,
          align: 'left',
          type: 'numberItem'
        },
        {
          label: '预消金额',
          prop: 'PreSaleAmountCountText',
          width: 80,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          extend: {
            tableBtnGroup: [
              {
                name: '查看',
                extend: {
                  click: (rowData) => {
                    let data = JSON.parse(JSON.stringify(rowData));
                    data.ScheduleCourseKey = data.OLAPKey;

                    this.$emit('showTableCourseDetail', data);
                  },
                  roleName: '',
                  ModulePowerKey: ''
                }
              },
              {
                name: '考勤',
                extend: {
                  click: (rowData, callBack) => {
                    let data = JSON.parse(JSON.stringify(rowData));
                    data.ScheduleCourseKey = data.OLAPKey;
                    let lastTime = this.$utils.getIsLastData(data.ClassTime);
                    this.$emit('toScheduleAtten', data, lastTime);
                  },
                  roleName: '安排考勤',
                  ModulePowerKey: '51'
                }
              },
              {
                name: '修改',
                extend: {
                  click: (rowData) => {
                    let data = JSON.parse(JSON.stringify(rowData));
                    data.ScheduleCourseKey = data.OLAPKey;
                    this.$emit('showEditData', data);
                  },
                  roleName: '修改单课',
                  ModulePowerKey: '47'
                }
              },
              {
                name: '停课',
                extend: {
                  click: (rowData, callBack) => {
                    let data = JSON.parse(JSON.stringify(rowData));
                    data.CourseNameKey = data.OLAPKey;
                    this.$emit('stopCourse', data);
                  },
                  roleName: '停课',
                  ModulePowerKey: '47'
                }

              }
            ]
          }
        }
        // ，{
        //   label: '关联转化单',
        //   prop: 'option',
        //   type: 'hidden-btn',
        //   minWidth: 200,
        //   align: 'center',
        //   extend: {
        //     tableBtnGroup: [
        //       {
        //         name: `RelevanceContent`,
        //         extend: {
        //           click: (rowData) => {
        //             console.log('rowData', rowData);
        //           }
        //         }
        //       }
        //     ]
        //   }
        // }
      ]
    };
  },
  props: {
    dataList: {
      type: Array,
      default: []
    },
    startDate:String,
    endDate:String
  },
  components: {
    tableList
  },
  watch: {
    // 优惠金额
    // 'dataReportList': {
    //   handler (newValue, oldValue) {

    //   }
    //   // deep: true
    // }
  },
  computed: {
    vDataList () {
      return JSON.parse(JSON.stringify(this.dataList));
    },
    vCourseLength () {
      return this.dataList.length;
    },
    vStudentCount () {
      let count = 0;
      this.dataList.forEach(item => {
        count = count + Number(item.ArrangedNumCount);
      });
      return count;
    },
    vPreClassCount () {
      let count = 0;
      this.dataList.forEach(item => {
        count = count + Number(item.PreClassCount);
      });
      return Number(Number(count).toFixed(2));
    },
    vPreSaleAmountCount () {
      let count = 0;
      this.dataList.forEach(item => {
        count = count + Number(item.PreSaleAmountCount);
      });
      return this.$utils.setMoneyformatToParseInt(Number(count));
    }

  },
  created () {

  },
  mounted () {

  },
  methods: {
    unitKeyValue (type) {
      let a = '';
      switch (type) {
        case '2':
          a = '年';
          break;
        case '3':
          a = '月';
          break;
        case '4':
          a = '日';
          break;
        case '5':
          a = '课时';
          break;

        default:
          break;
      }
      return a;
    },
    doExportInfoClick () {
      this.$emit('doExportInfoClick');
    },
    getDataList () {
      this.$emit('getDataList');
    },
    stopCourse (rowdata) {
      this.$emit('stopCourse', rowdata);
    }
    // // 导出
    // doExportInfoClick (callBack) {
    //   if (this.dataReportList.length > 0) {
    //     var newData = this.$utils.parseJson(this.searchObj);
    //     this.searchObj.IsExportKey = 1;
    //     this.searchObj.pageIndex = 0;
    //     this.searchObj.pageSize = 0;
    //     let jsonFormate = {
    //       SheetTitle: '待处理',
    //       clist: [
    //         { title: '购课日期', cName: 'GenerateTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
    //         { title: '订单号', cName: 'NumberName', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
    //         { title: '学生名', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '手机号', cName: 'ParPhoneName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '实收款', cName: 'ActualColAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '生效日期', cName: 'StartTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '失效日期', cName: 'EndTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '排班进课', cName: 'AttendClassKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '销售业绩归属', cName: 'SaleMansName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '关联转化', cName: 'RelationScheduleKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
    //       ]
    //     };
    //     console.log(jsonFormate, 'jsonFormate');
    //     getConversionCourseScheduleList(this.searchObj, jsonFormate).then(result => {
    //       console.log(result.data, '导出');
    //       window.location.href = this.$store.getters.APIURL + result.data.url;
    //       this.searchObj.IsExportKey = 0;
    //       this.searchObj.pageIndex = newData.pageIndex;
    //       this.searchObj.pageSize = newData.pageSize;
    //     }, error => {
    //       layer.alert(error.msg);
    //     });
    //   } else {
    //     layer.alert('暂无数据导出');
    //   }
    //   console.log('导出');
    // },
  }
};
</script>
<style scoped>
.heard {
  display: flex;
  flex-direction: row;
  flex-direction: row-reverse;
}
.heardItem {
  margin: 20px;
}
.tableCourselistbox {
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row-reverse;
}
.tableCourselist {
  border: 1px solid;
  height: 45px;
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.tableCourselistItem {
  margin: 10px;
  min-width: 50px;
}
.tableCourselistItemline {
  height: 100%;
  width: 1px;
  background: #333;
}
.credit_management_popup {
  padding-bottom: 20px;
}
.table_number {
  font-size: 22px;
}
.table_name {
  min-width: 100px !important;
}
.unit_text {
  color: #666;
}
.table_title {
  width: 115px !important;
}
</style>