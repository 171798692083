<template>
  <!-- 欠课时统计列表 -->
  <div>
    <!-- <custom-dialog title="欠课时统计"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowModule"
                   :before-close="closeModule"> -->
    <span class="defult_dialog_header_btn fr">
      <zx-Import-button @click="doExportInfoClick()"
                        :isSingleSubmit="true"
                        :btnClass="'btn_fixed_Import'"
                        :singleSubmitType="'static'"
                        style="margin-left: 22px;"
                        :btnText="'导出'"></zx-Import-button>
    </span>
    <div style="background-color: #fff">
      <!-- 搜索选择层 -->
      <input-search-contain-btn v-model="parameter.searchText"
                                :placeholder="'请输入学生名、手机号、课程名'"
                                @onSearchEvent="doTextSearchClick"></input-search-contain-btn>
      <div class="new_table_list_box">
        <div class="new_table_list_thead">
          <div style="width: 120px;"
               class="new_table_list_th style_gray tl">姓名</div>
          <div style="width:130px;padding-right: 80px"
               class="new_table_list_th style_yellow tc">课程</div>
          <div style="width: 60px;"
               class="new_table_list_th style_red tc">欠课时</div>
          <div style="width: 100px;"
               class="new_table_list_th style_blue tl">最近欠课时</div>
        </div>
        <div class="new_table_list_tbody"
             style="max-height: 600px;overflow-y: auto;cursor: pointer"
             @scroll="scrollTOP">
          <div class="new_table_list_tr"
               v-for="(item,index) in debtCourseHourseList"
               :key="index"
               @click="doDebtDetailsClick(item)">
            <div style="width:120px;"
                 class="new_table_list_td tl">
              <div class="font_name">
                <span>{{item.StuKeyValue}}</span>
              </div>
              <div class="font_phone">
                <span>{{$utils.phoneModulePower(item.CustomerPhoneName)}}</span>
              </div>
            </div>

            <div style="width: 120px;text-align: left;padding-left: 10px"
                 class="new_table_list_td tc">
              <span class="">{{item.CourseNameKeyValue}}</span>
            </div>

            <div style="width: 60px;"
                 class="new_table_list_td tc">
              <span style="color: #AB0B19">{{$utils.newAmountType(item.ClassCount||0,1)}}</span>
            </div>

            <div style="width: 100px;padding-left: 10px"
                 class="new_table_list_td tl">
              <span>{{mdate(item.BeginClassTime)}}</span>
            </div>
            <div class="new_table_list_arrow"
                 v-if="Number(item.ClassCount)>0"></div>
          </div>
          <div class="new_table_list_bottom"
               v-if="loading">
            <span class="loading_btn">加载中...</span>
          </div>
          <div v-else-if="debtCourseHourseList.length==0">
            <div class="monitoring_content_nodata">
              <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
            </div>
          </div>
          <div class="new_table_list_bottom"
               v-else-if="noMore">
            <span class="font_gray">已显示全部</span>
          </div>
        </div>
      </div>
    </div>
    <!-- </custom-dialog> -->
    <!-- 欠课课时详情页面 -->
    <!-- <custom-dialog title="还课时"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isDebtCourseHoursDetailsShow"
                   :before-close="closeDetails">
      <div>
        <debt-course-hourse-details @confirmDebtCourseHoursDetails="confirmDebtCourseHoursDetails"
                                    @closeDialog="closeDetails"
                                    :studentItem="studentInfo"></debt-course-hourse-details>
      </div>
    </custom-dialog> -->
  </div>
</template> 

<script>
import zxImportButton from '../../common/zx-Import-button';
import debtCourseHourseDetails from '../../pop-up-menu/sign-up-payment/debt-course-hourse-details';
import { MessageSearchDebtClassHour, getStudentRelatedMsg } from '../../../API/workbench';

export default {
  data () {
    return {
      parameter: {
        pageIndex: 0,	// 整型	可选	0	数据源：GET	 页码，0为第一页
        pageSize: 20,	// 整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
        searchText: '',	// 字符串	可选		数据源：GET	   搜索条件：模糊匹配姓名，姓名首字母,手机号
        orderBy: 'GenerateTime', // 字符串	可选		数据源：GET	 排序字段
        sequence: 'desc'	// 字符串	可选		数据源：GET	  排序顺序
      },
      debtCourseHourseList: [], // 欠未款统计列表
      isShowModule: false, // 控制显示表单
      isDebtCourseHoursDetailsShow: false, // 欠课时页面
      loading: false,
      noMore: false, // 没有更多数据加载了
      studentInfo: {},
      isShowAdd: true // 导出权限是否开启
    };
  },
  props: [

  ],
  components: {
    debtCourseHourseDetails,
    zxImportButton
  },
  created () {
    // 还课时
    this.loadData();
    this.$bus.on(this, 'AfterRepayClassHour', (result, type) => {
      if (!type) {
        this.parameter.pageIndex = 0;
        this.loadData(true);
      }
    });
  },
  mounted () {
    this.$dialog.register(this, ['debtCourseHourseDetails']);
  },
  computed: {
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    }
  },
  methods: {
    closeModule () {
      this.isShowModule = false;
      this.parameter.pageIndex = 0;
      this.parameter.searchText = '';
      this.$emit('closeDialog');
    },
    // 导出
    doExportInfoClick () {
      if (this.debtCourseHourseList.length > 0) {
        this.parameter.IsExportKey = 1;
        this.parameter.pageIndex = 0;
        this.parameter.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '欠课时统计',
          clist: [
            { title: '姓名', cName: 'StuKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '手机号', cName: 'CustomerPhoneName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课程', cName: 'CourseNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '欠课时', cName: 'ClassCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '最近欠课时', cName: 'BeginClassTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchDebtClassHour(this.parameter, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.parameter.IsExportKey = 0;
          this.parameter.pageIndex = 0;
          this.parameter.pageSize = 20;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    // 关闭弹窗页面
    closeDetails () {
      // this.isDebtCourseHoursDetailsShow = false;
    },
    // 确认欠课时详情
    confirmDebtCourseHoursDetails (error) {
      if (error.code == 0) {
        this.closeDetails();
      }
    },
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;
      if ((ETarget.scrollTop >= ETarget.scrollHeight - ETarget.clientHeight) && !this.noMore) {
        this.loadData();
      }
    },
    mdate (date) {
      let newDate = date.slice(0, 10);
      return newDate;
    },
    doTextSearchClick () {
      this.parameter.pageIndex = 0;
      this.noMore = false;
      this.loadData();
    },
    clearSearchCondition () {
      this.parameter.searchText = '';
      this.doTextSearchClick();
    },
    // 去还款
    doDebtDetailsClick (item) {
      if (this.$utils.CheckModulePower(this.vRolePowerList, 50)) {
        if (Number(item.ClassCount) > 0) {
          getStudentRelatedMsg(item.StuKey).then(res => {
            if (res.code == 0) {
              this.studentInfo = res.data;
              this.studentInfo.CourseNameKey = item.CourseNameKey;
              this.studentInfo.CourseNameKeyValue = item.CourseNameKeyValue;
              this.$dialog.open(this, {
                routerName: this.$route.name,
                moduleName: 'debtCourseHourseDetails',
                data: { studentItem: this.studentInfo }
              });
            // this.isDebtCourseHoursDetailsShow = true;
            }
          }, err => {
            layer.alert(err.msg);
          });
        }
      } else {
        this.$utils.CheckModulePowerToTips(50);
      }
    },
    loadData (type) {
      console.log(this.parameter, '日期');
      if (!type) {
        this.isShowModule = true;
      }
      this.loading = true;
      MessageSearchDebtClassHour(this.parameter).then(result => {
        // this.debtCourseHourseList = result.data.PageDataList;
        if (result.data) {
          if (this.parameter.pageIndex == 0) {
            this.debtCourseHourseList = [];
          }
          result.data.PageDataList.forEach(o => {
            o.isActive = false;
            let hasFind = false;
            if (this.debtCourseHourseList.length > 0 && !this.parameter.searchText) {
              hasFind = this.debtCourseHourseList.findIndex(p => p.Number == o.Number) >= 0;
            }
            if (!hasFind) {
              this.debtCourseHourseList.push(o);
            }
          });
          if (result.data.PageDataList.length < this.parameter.pageSize) {
            this.noMore = true;
          }
        }
        this.loading = false;
        this.parameter.pageIndex++;
        console.log(this.debtCourseHourseList, '欠未款统计列表');
      }, error => {
        this.loading = false;
        layer.alert(error.msg);
      });
    }
  }

};
</script>

  <style>
/*  */
</style>