<template>
  <div class="gift_management_box">
    <div class="gift_management_tips">
      <span class="font_red">*</span> 图片上传尺寸建议640px*640px
    </div>
    <div class="commodity_ul">
      <gift-list
        v-for="(item, index) in giftListInfo"
        :key="index"
        :dataInfo="item"
        :isShowTop="index != 0"
        :isShowUp="index != 0"
        :isShowDown="(index + 1) != giftListInfo.length"
        @upGiftInfo="upGiftInfo"
        @downGiftInfo="downGiftInfo"
        @editGiftInfo="addGiftInfo"
        @refreshDataList="getGiftListInfo"
      ></gift-list>
      <div @click="addGiftInfo()" class="gift_list">
        <div class="WKT_slideshow_upload_box hover_border_blue">
          <div class="WKT_slideshow_upload_noImg">
            <div class="upload_icon">+</div>
            <div>添加物品</div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import { MessageSearchPageForExchangeGoods, MoveExchangeGoods } from "../../../../API/workbench";
import giftList from "./gift-list";

export default {
  data() {
    return {
      giftListInfo: [],
      searchObj: {
        pageIndex: 0,
        pageSize: 0,
        searchText: "",
        orderBy: "",
        sequence: "ASC",
        startTime: "",
        endTime: "",
      },
    };
  },
  components: {
    giftList,
  },
  computed: {},
  created() {
    this.getGiftListInfo();
  },
  methods: {
    upGiftInfo(item){
      let index = this.giftListInfo.findIndex(o => {
        return o == item;
      });
      MoveExchangeGoods(item.OLAPKey,index).then(
        (res) => {
          this.getGiftListInfo();
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
      
    },
    downGiftInfo(item){
      let index = this.giftListInfo.findIndex(o => {
        return o == item;
      });
      MoveExchangeGoods(item.OLAPKey, index + 3).then(
        (res) => {
          this.getGiftListInfo();
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    addGiftInfo(item){
      console.log(item,'0000000000000')
      if(item){
        this.$emit('showAddGiftInfoDialog',item);
      }else {
        this.$emit('showAddGiftInfoDialog');
      }
      
    },
    getGiftListInfo() {
      MessageSearchPageForExchangeGoods(this.searchObj).then(
        (res) => {
          this.giftListInfo = res.data.PageDataList;
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    }
  },
};
</script>