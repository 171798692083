<template>

  <li class="single_result_tree single_class_info">
    <!-- :class="liClass" -->
    <div :class="(model.isOpt||model.childTree.isOpt)?'opt':''"
         class="single_result_info_detail">

      <div @click="toggle(model)">
        <div :class="liClass">{{model.menuName}}
          <div v-if="model.childTree.length>0"
               class="leave-iocn-down"
               :class="iconShow?'leave-iocn-up':''"></div>
        </div>
      </div>

    </div>
    <!-- <div>︿</div> -->

    <transition name="slide">
      <ul v-if="open==true">
        <items v-for="(item,index) in model.childTree"
               :model="item"
               :key="index"></items>
      </ul>
    </transition>
  </li>

</template>
 
<script>
export default {
  name: 'items',
  props: ['model', 'multie'],
  data () {
    return {
      open: false,
      iconShow: false,
      treeData: []
    };
  },
  created () {
    if (this.model.childTree.length > 0) {
      this.model.childTree.forEach(e => {
        if (e.id == this.$store.getters.getShowTimetableId) {
          console.log('model222');
          this.open = true;
          this.iconShow = true;
        } else if (this.$store.getters.getShowTimetableId == 0) {
          console.log('model333');
          this.open = false;
          this.iconShow = false;
          this.model.isOpt = false;
        }
      });
    }
  },
  watch: {

  },
  methods: {
    toggle (item) {
      this.iconShow = !this.iconShow;
      this.open = !this.open;
      this.model.isOpt = !this.model.isOpt;
      console.log(this.model.isOpt, 'item');
      const parma = {
        multie: this.multie,
        model: this.model
      };
      this.$store.commit('setShowTimetableId', item.id);
      this.$bus.emit('selected', parma);
    }
  },
  computed: {
    liClass () {
      let option = `level_${this.model.level}`;
      let res = {};
      res[option] = true;
      return res;
    }
  }
};
</script>
 
<style>
.level_1 {
  padding: 0.15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* border: 1px #ff0000 solid; */
}
.level_2 {
  padding: 0.15rem;
  /* border: 1px #00ff00 solid; */
  text-indent: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.level_3 {
  padding: 0.15rem;
  /* border: 1px #0000ff solid; */
  text-indent: 2em;
}
.level_4 {
  padding: 0.15rem;
  /* border: 1px #ffff00 solid; */
  text-indent: 3em;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.1s;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-20px);
}
.slide-enter-to,
.slide-leave {
  transform: translateY(0px);
}
.leave-iocn-down {
  float: right;
  width: 0.3rem;
  height: 0.3rem;
  background: url(../../../../../public/image/course_header_down@2x.png)
    no-repeat;
  background-size: 100%;
  margin-top: 0.1rem;
}

.leave-iocn-up {
  background-image: url(../../../../../public/image/course_header_up@2x.png);
  margin-top: -0.1rem;
}
</style>
