<template>
  <div>
    <!-- 完善学生资料 -->
    <div class="single_result_detail form_info_detail">
      <div class="student_info_edit form_info_edit form_info_list content_marginTop_30 content_marginRL single_shadow ApplyAuth">
        <input-text :formTitle="'申请原因'"
                    :formPlaceholder="'请输入'"
                    v-model="sumitData.Remark"></input-text>

      </div>

      <!-- 提交按钮组 -->
      <div>
        <save-cancel-btn-group :isSingleSubmit="true"
                               :fixedBtn="true"
                               :singleSubmitType="'dynamic'"
                               @confirmClick="doAfterConfirm"
                               @cancelClick="doAfterCancel"></save-cancel-btn-group>
      </div>

    </div>

  </div>
</template>
<script>
import { CWSApplyAuthorizedDevice, WEBVApplyAuthorizedDevice } from '../../../../../API/workbench';
export default {
  data () {
    return {
      sumitData: {
        'CoursewarePackageKey': '',
        'Remark': '',
        'DeviceCode': ''
      }

    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          CoursewareKey: '',    // 教案课件ID
          DeviceCode: ''   // 设备码

        };
      }
    },
    isTeachingMode: {
      type: Boolean,
      default: false
    },
    successCallback:{
      type: Boolean,
      default: false
    }

  },
  computed: {
    isCWS () {      // 是否为课件门店
      return this.$store.getters.SaaSClientEditionKey == 6;
    },
    isAutoAuth () {
      return this.$utils.getSaaSClientSetInfo(75).SetKey == 1;
    },
    vTeachingModeTag () {
      return this.isTeachingMode ? 'teachingMode' : '';
    }
  },
  created () {
    console.log(this.dataInfo, 'created');
    this.sumitData.CoursewarePackageKey = this.dataInfo.CoursewareKey;
    let mInfo = JSON.parse(this.$zxClient.GetMInfo());
    this.sumitData.DeviceName = mInfo.MName;
    this.sumitData.DeviceTypeKey = mInfo.MType;
    this.sumitData.DeviceXCode = mInfo.XCode;
    this.sumitData.DeviceUCode = mInfo.UCode;
  },
  methods: {
    doAfterConfirm () {
      let fn = this.isCWS ? CWSApplyAuthorizedDevice : WEBVApplyAuthorizedDevice;
      fn(this.sumitData).then(result => {
        console.log(result, 'CWSApplyAuthorizedDevice',this.isAutoAut);
        console.log("this.$utils.getSaaSClientSetInfo(75)",this.$utils.getSaaSClientSetInfo(75))
        this.$zxClient.SetUCode(result.data.DeviceUCode);
        if (this.isAutoAuth) {
          if(this.successCallback){
               this.$emit('afterSuccessCallback',this.isAutoAuth);
            }else{

              layer.alert('申请已通过，可以继续进入课件', { mode: this.vTeachingModeTag });
            }
        } else {
          layer.alert('申请已提交，请联系管理员进行审核后再进入课件', { mode: this.vTeachingModeTag });
        }

        this.$emit('afterSuccess',this.isAutoAuth);
        this.$emit('closeDialog');
      }, error => {
        console.log('error', error);
        if (error.code == 8004 && this.isAutoAuth) {
          layer.alert('设备数已满，请联系管理员进行处理', { mode: this.vTeachingModeTag });
          this.$zxClient.SetUCode(error.data.DeviceUCode);
        } else if (error.code == 10000) {
          layer.alert('申请已提交，请联系管理员进行审核后再进入课件', { mode: this.vTeachingModeTag });
        } else {
          layer.alert(error.msg, { mode: this.vTeachingModeTag });
        }
        this.$emit('closeDialog');
      });
    },
    doAfterCancel () {
      this.$emit('closeDialog');
    }
  }
};
</script>


