<template>
  <div class="homerwork_details_student_list student_info_list select_icon"
        @click="doWorkCorrectClick">
    <div class="list_img"
          :class="vClass">
      <span v-if="!vStudentPhoto"
            class="text_box">{{vStudentInitial}}</span>
      <img class="img_box"
             :src="vStudentPhoto"
             mode="">
    </div>
    <div class="list_name">
      {{item.StudentKeyValue}}
    </div>
    <div class="list_credit">
      <div class="list_credit_img"
            v-for="(obj,key) in 5"
            :key="key"
            :class="{'opt':key<Number(item.Currency)}"></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  computed: {
    vClass () {
      let n = Math.floor(1 + Math.random() * (9 - 1))
      return 'bg_' + n;
    },
    vStudentInitial () {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(this.item.StudentKeyValue)) {
        return this.item.StudentKeyValue.substr(this.item.StudentKeyValue.length - 1, 1)
      } else {
        return this.item.StudentKeyValue.substr(0, 1)
      }
    },
    vStudentPhoto () { 
      let isDefaultImg= (this.item.HeadImg!='/PUBLIC/images/deflut_studentPhoto_boy.png'&&this.item.HeadImg!='/PUBLIC/images/deflut_studentPhoto_gril.png')
      if (this.item.HeadImg&&isDefaultImg) {
        if (this.item.HeadImg.indexOf('http') > -1) {
          return this.item.HeadImg;
        } else {
          return this.$store.getters.CDNURL + this.item.HeadImg;
        }
      } else {
        return ''
      }
    }
  },
  methods: {
    doWorkCorrectClick () {
      this.$emit('doWorkCorrectClick', this.item)
    }
  }
};
</script>

<style>
</style>
