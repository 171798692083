<template>
  <div class="course_details defult_dialog">
      <div class="class_file_detail">
        <!-- 课程综合数据 -->
        <div class="class_file_data">
          <div class="aim_class single_shadow fl">
            <div class="aim_class_left fl">
              目标课程
            </div>
            <div class="aim_class_center fl">
              <div class="aim_class_center_name"
                   :title="courseInfo.CourseNameKeyValue">
                <span>{{courseInfo.CourseNameKeyValue}}</span>
              </div>
              <div class="aim_class_center_number">
                <span class="font_gray">{{ClassTime}} {{week}} {{courseInfo.BeginClassTime}}~{{courseInfo.EndClassTime}}</span>

              </div>
              <div class="aim_class_center_number">
                <span class="font_gray"
                      v-if="courseInfo.GradeClassKeyValue">{{courseInfo.GradeClassKeyValue}}</span>
                <span class="font_gray"
                      v-if="courseInfo.MTeacherKeyValue">主讲老师: </span>
                <span class="font_gray">{{courseInfo.MTeacherKeyValue}}</span>
              </div>
            </div>
            <div class="aim_class_right fr">
              <div class="aim_class_right_icon_change"
                   v-show="false"></div>
              <function-btn v-if="isExpireDate"
                            :item="editbtn"
                            @clickItem="restoreCourse"
                            :className="'font_blue'">
              </function-btn>
            </div>
          </div>
          <div class="project_class single_shadow fl">

            <!-- <div class="project_class_checkd">
              <span>全班学生一起上课</span>
              <el-checkbox class="fr"
                           v-model="IsAutoLearnKey"
                           disabled></el-checkbox>
            </div> -->
            <div class="project_class_checkd">
              <span class="font_gray">扣课时:</span>
              <span>{{PeriodCount}}课时</span>
            </div>
            <div class="project_class_checkd">
              <span>旷课是否扣课时</span>
              <el-checkbox class="fr"
                           v-model="IsChargesKey"
                           disabled></el-checkbox>
            </div>
            <div class="project_class_checkd">
              <span>请假是否扣课时</span>
              <el-checkbox class="fr"
                           v-model="IsleaveKey"
                           disabled></el-checkbox>
            </div>
          </div>
          <div class="time_class single_shadow fr">
            <div class="project_class_checkd">
              <span class="font_top font_gray">教</span>
              <span class="font_gray">室 : </span>
              <span>{{courseInfo.classRoomName}}</span>
            </div>
            <div class="project_class_checkd">
              <span class="font_gray">助教老师 : </span>
              <span>{{courseInfo.ATeaName}}</span>
            </div>
            <div class="project_class_checkd">
              <!-- <span class="font_bottom font_gray">满编人数</span>-->
              <span class="font_gray">满编人数 : </span>
              <span>{{Number(courseInfo.FullPeopleCount).toFixed(0) ||0}}人</span>
            </div>
          </div>
        </div>
        <!-- 课程学员数据 -->
        <div class="course_details_student single_shadow">
          <div class="course_details_student_statistics">
            <div class="course_details_student_icon"></div>
            <div class="time_class_all fl"
                 @click.stop="chgType(0)">全部
              <span class="font_blue"
                    :class="type==0?'red':''">{{totalStudentsCount}}</span>人
            </div>
            <div class="time_class_list fl">
              <span class="time_class_list_title">签到 : </span>
              <span class="time_class_list_number"
                    @click.stop="chgType(3)">
                <span :class="type==3?'red':''">{{totalCQCount}}</span>人
              </span>
            </div>
            <div class="time_class_list fl">
              <span class="time_class_list_title">请假 : </span>
              <span class="time_class_list_number"
                    @click.stop="chgType(4)">
                <span :class="type==4?'red':''">{{totalLeaveCount}}</span>人
              </span>
            </div>
            <div class="time_class_list fl">
              <span class="time_class_list_title">旷课 : </span>
              <span class="time_class_list_number"
                    @click.stop="chgType(5)">
                <span :class="type==5?'red':''">{{totalKKCount}}</span>人
              </span>
            </div>
            <div class="time_class_list fl">
              <span class="time_class_list_title">未考勤 : </span>
              <span class="time_class_list_number"
                    @click.stop="chgType(2)">
                <span :class="type==2?'red':''">{{totalWKQCount}}</span>人
              </span>
            </div>
          </div>
          <div class="select_course_student_info"
               v-if="studentList.length>0">
            <inClassStudent v-for="item in studentList"
                            :key="item.StudentArrangeKey"
                            :studentItem="item"
                            @selectedItem="selectedItem"></inClassStudent>
          </div>
          <div class="select_course_student_info"
               v-else>
            <div class="student_payment_nodata form_info_list ">
              <span>亲，当前没有任何数据哦~</span>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import editCourse from '../../../common/edit-course';
import { getScheduleCourseOnStudentList, getCourseDetailed } from '../../../../API/workbench';
import inClassStudent from '../tabs/in-class-students';
export default {
  name: 'classDetail',
  data () {
    return {
      showCourseDetails: false,
      isChecked: true,
      studentArrangeList: [],
      type: 0,
      isShowEditCourse: false,  // 修改课程弹出
      courseInfo: {},
      editbtn: {
        name: '修改',
        type: 'xgkc',
        ModulePowerKey: 47
      }
    };
  },
  props: ['courseDetail'],
  components: {
    inClassStudent, editCourse
  },
  computed: {
    // 扣课时
    PeriodCount () {
      return Number(this.courseInfo.PeriodCount).toFixed(1);
    },
    ClassTime () {
      if (this.courseInfo.ClassTime) {
        return this.courseInfo.ClassTime.replace(/-/g, '.');
      }
    },
    // 计算是否超过30天
    isExpireDate () {   // 2592000000 为一个月的时间戳.
      console.log(this.courseInfo, 'isExpireDate');
      let classTime = this.courseInfo.ClassTime + ' ' + this.courseInfo.BeginClassTime;
      if (Date.new(classTime).getTime() + 5184000000 > Date.new().getTime()) {
        return true;
      } else {
        return false;
      }
    },
    // 显示周几
    week () {
      if (this.courseInfo.ClassTime) {
        return this.$utils.formatDateStr(this.courseInfo.ClassTime, 'ddddd');
      }
    },
    // 所有学生列表.
    studentList () {
      let newArr = [];
      this.studentArrangeList.forEach(o => {
        if (this.type == 0) {
          newArr.push(o);
        } else {
          if (this.type == 3) {
            if (o.AttenceStatusKey == 3) {
              newArr.push(o);
            }
          } else if (this.type == 4) {
            if (o.AttenceStatusKey == 4) {
              newArr.push(o);
            }
          } else if (this.type == 5) {
            if (o.AttenceStatusKey == 5) {
              newArr.push(o);
            }
          } else if (this.type == 2) {
            if (o.AttenceStatusKey == 2) {
              newArr.push(o);
            }
          }
        }
      });
      return newArr;
    },
    // 签到总人数
    totalCQCount () {
      let count = 0;
      this.studentArrangeList.forEach(o => {
        if (o.AttenceStatusKey == 3) {
          count++;
        }
      });
      return count;
    },
    // 未考勤总人数
    totalWKQCount () {
      let count = 0;
      this.studentArrangeList.forEach(o => {
        if (o.AttenceStatusKey == 2) {
          count++;
        }
      });
      return count;
    },
    // 请假总人数
    totalLeaveCount () {
      let count = 0;
      this.studentArrangeList.forEach(o => {
        if (o.AttenceStatusKey == 4) {
          count++;
        }
      });
      return count;
    },
    // 旷课总人数
    totalKKCount () {
      let count = 0;
      this.studentArrangeList.forEach(o => {
        if (o.AttenceStatusKey == 5) {
          count++;
        }
      });
      return count;
    },
    // 总学生人数.
    totalStudentsCount () {
      return this.studentArrangeList.length;
    },
    vIsShowChargeTruant () {
      return this.$utils.getSaaSClientSet(33) == 4 && this.$utils.getSaaSClientSet(59) > 0;
    },
    //  2不扣课时 3扣课时 4建课设置
    vIsShowChargeLeave () {
      return this.$utils.getSaaSClientSet(32) == 4;
    },
    // 是否全班一起上课
    IsAutoLearnKey () {
      if (this.courseInfo.IsAutoLearnKey == 1) {
        return true;
      } else {
        return false;
      }
    },
    // 是否旷课扣课时
    IsChargesKey () {
      if (this.courseInfo.IsChargesKey == 1) {
        return true;
      } else {
        return false;
      }
    },
    // 是否请假扣课时
    IsleaveKey () {
      if (this.courseInfo.IsleaveKey == 1) {
        return true;
      } else {
        return false;
      }
    }
  },
  created () {
    console.log(this.courseDetail);
    this.getCourseDetail(this.courseDetail.ScheduleCourseKey, () => {
      this.getStudentArrangeList(this.courseInfo.ScheduleCourseKey);
    });
  },
  mounted () {
    this.$dialog.register(this, ['editCourse']);
  },
  watch: {
    courseDetail (n, o) {
      if (this.showCourseDetails && n.ScheduleCourseKey) {
        this.getCourseDetail(this.courseDetail.ScheduleCourseKey, () => {
          this.getStudentArrangeList(this.courseInfo.ScheduleCourseKey);
        });
      }
    },
    showCourseDetails (n, o) {
      if (n && this.courseDetail.ScheduleCourseKey) {
        this.getCourseDetail(this.courseDetail.ScheduleCourseKey, () => {
          this.getStudentArrangeList(this.courseInfo.ScheduleCourseKey);
        });
      }
    }
  },
  methods: {
    // 获取课详细信息.
    getCourseDetail (id, callback) {
      let ishis = this.$utils.getIsLastData(this.courseDetail.ClassTime)
      console.log('courseDetail',this.courseDetail)
      getCourseDetailed(id,ishis?1:0).then(result => {
        this.courseInfo = result.data;
        if (callback) {
          callback();
        }
      });
    },
    // 当前课的学生
    getStudentArrangeList (ScheduleCourseKey) {
      console.info(ScheduleCourseKey, '4');
      let ishis = this.$utils.getIsLastData(this.courseDetail.ClassTime)
      getScheduleCourseOnStudentList(ScheduleCourseKey,ishis?1:0).then(result => {
        this.studentArrangeList = result.data || [];
        console.log(this.studentArrangeList, 'this.getStudentArrangeList');
      }).catch(err => {
        console.info(err);
      });
    },
    closeCourseDetails () {
      this.type = 0;
      this.showCourseDetails = false;
      this.$emit('afterSucces');
    },
    selectedItem (val) {
      console.log(val);
    },
    chgType (val) {
      this.type = val;
    },
    // 回复或者停课
    openOrStopCourse () {
      this.getStudentArrangeList(this.courseInfo.ScheduleCourseKey);
    },
    // 弹出修改课程.
    editCourse (booler, val, type) {
      // this.showEditCourse(booler);
      this.courseInfo = val;
      this.$bus.emit('updateCourse', this.courseInfo);    // 临时注释
      this.getStudentArrangeList(this.courseInfo.ScheduleCourseKey);
    },
    showEditCourse (booler) {
      if (booler) {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          moduleName: 'editCourse',
          data: { courseInfo: this.courseInfo },
          callBack: {afterSucces: () => { this.editCourse(); }}
        });
      }
    },
    restoreCourse () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'editCourse',
        data: { courseInfo: this.courseInfo },
        callBack: {afterSucces: (booler, val, type) => { this.editCourse(booler, val, type); }}
      });
      // this.isShowEditCourse = true;
      // console.log('this.courseInfo', this.courseInfo);
      // this.$refs.editCourse.CourseDetailed(this.courseInfo.ScheduleCourseKey);  // 直接调用获取课信息接口.  以防止数据
      // this.$refs.editCourse.isAllowedUpdateClassCount(this.courseInfo.ScheduleCourseKey);
    }
  }
};
</script>
<style>
/* .red {
  color: red;
} */
.class_file_detail {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
</style>

