<template>
  <div class="sign_face_box clearfix">
    <audio id="face_audio"
           controls></audio>
    <div class="sign_face_left fl"
         v-show="!noVideo">
      <div class="sign_face_screenage">
        <video id="video"
               ref="video"
               preload
               autoplay
               loop
               muted></video>
        <canvas id="canvas"
                width=""
                height="400"></canvas>

        <!-- <img v-show="imgUrl" :src="imgUrl" alt="" style="height: 100%;position: relative;width: 100%;"> -->
      </div>
      <div class="message"
           v-show="false">
        <canvas id="shortCut"
                width=""
                height="400"></canvas>
        <div id="checkinMsg"></div>
      </div>
      <!-- 紫色：type_purple 蓝色：type_blue 绿色：type_green 黄色：type_yellow 红色：type_red-->
      <div class="sign_face_type type_purple"
           :class="vCurrentStatus">
        {{curStatusInfo.statusKeyValue}}
      </div>
    </div>
    <div class="sign_face_left fl face_informatio_entry_screenage"
         style="width:400px"
         v-if="noVideo">
      <div class="screenage_box">
        <div class="screenage_box_noLink">
          未连接到摄像头
        </div>
      </div>
      <div class="face_informatio_entry_status">
        <div class="status_photo_tips">
          使用前请确认摄像头已正确连接，如无法显示请进行以下 尝试： <br />
          1.重新连接摄像头； <br />
          2.关掉浏览器，重新登陆{{vBrandInfo.MainDemoName}}系统； <br />
          3.重启电脑或手机。
        </div>
      </div>
    </div>
    <div class="sign_face_right fl">
      <div class="sign_face_title_date">
        <span class="font_big font_bold">签到记录</span>
        <span>{{vWeek}}</span>
        <span>{{vDate}}</span>
        <span><span class="font_bold">{{signRecodList.length||0}}</span>人次</span>
      </div>
      <div class="sign_face_ul"
           v-if="signRecodList.length>0">
        <sign-recod-item v-for="(item,index) in signRecodList"
                         :key="index"
                         :recodInfo="item"></sign-recod-item>

      </div>
      <div class="sign_face_ul"
           v-else>
        <div class="course_block_nodata"
             style="padding-bottom:20px">
          <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { AttendanceStudentFace, AddStudentFace } from '../../API/face-recognition.js';
import { UploadFiles } from '../../API/workbench.js';
import signRecodItem from './sign-recod-item';

export default {
  data () {
    return {
      curStatusInfo: { statusKey: 1, statusKeyValue: '识别中...' },
      flag: false,
      trackerTask: null,
      delayedTime: 3000,
      statusList: {
        recognizing: 1, // 识别中
        scanning: 2, // 扫描中
        successed: 3, // 签到成功
        unRecogniz: 4, // 无法识别
        abnormal: 5 // 签到异常

      },
      signRecodList: [], // 签到记录流水
      noVideo: false,   // 是否没有摄像头
      imgUrl: '',
      timer: null
    };
  },
  components: {
    signRecodItem
  },
  computed: {
    vBrandInfo () {
      let obj = {};
      if (window.brandCustomSettingStr && window.brandCustomSettingStr != '') {
        obj = JSON.parse(window.brandCustomSettingStr);
        obj.MainDemoName = obj.MainDemoName;
      } else {
        obj = {
          MainDemoName: '治学'
        };
      }
      return obj;
    },
    vCurrentStatus () {
      let status = '';
      switch (this.curStatusInfo.statusKey) {
        case this.statusList.recognizing:
          status = 'type_purple';
          break;
        case this.statusList.scanning:
          status = 'type_blue';
          break;
        case this.statusList.successed:
          status = 'type_green';
          break;
        case this.statusList.unRecogniz:
          status = 'type_yellow';
          break;
        case this.statusList.abnormal:
          status = 'type_red';
          break;
        default:
          break;
      }
      return status;
    },
    // 是否开启面容录入管理.
    vIsOpenFaceSave () {
      return this.$store.getters.isOpenFaceSave;
    },
    // 是否开启面容录入管理.
    vIsOpenFaceRecognition () {
      return this.$store.getters.isOpenFaceRecognition;
    },
    vWeek () {
      return this.$utils.formatDatet(Date.new(), 'ddddd');
    },
    vDate () {
      return this.$utils.formatDatet(Date.new(), 'MM') + '月' + this.$utils.formatDatet(Date.new(), 'dd') + '日';
    }
  },
  mounted () {

  },
  watch: {
    // vIsOpenFaceSave (c, o) {
    //   console.log('进入判断vIsOpenFaceRecognition');
    //   if (!this.vIsOpenFaceSave) {
    //     this.trackerTask.stop();
    //   }
    // },
    // isOpenFaceStatus (c, o) {
    //   if (!c) {
    //     this.stopMediaStreamTrack();
    //     this.checkVideoCanDo();
    //   }
    // },
    vIsOpenFaceRecognition (c, o) {     // 是否开启面容识别.
      if (!c) {
        this.stopMediaStreamTrack();
      } else {
        this.checkVideoCanDo();
      }
    },
    // 监控此扫描状态变更.
    curStatusInfo (c, o) {
      this.$store.commit('setFaceSignTips', c);
    }

  },
  methods: {
    // 检测关闭摄像头
    stopMediaStreamTrack () {
      if (this.trackerTask) {
        this.trackerTask.stop();
        let video = this.$el.querySelector('#video');
        console.log(this.trackerTask, 'stopMediaStreamTrack', video.srcObject);
        video.srcObject = null;
        this.trackerTask = null;
      }
      console.log(this.trackerTask, 'stopMediaStreamTrackafter');
    },
    // 判断是否有连接摄像头.
    checkVideoCanDo (callback) {
      let videoNum = 0;
      let deviceList = [];
      navigator.mediaDevices.enumerateDevices().then(devices => {
        devices.forEach(device => {
          deviceList.push(device.kind);
          if (device.kind === 'videoinput') { videoNum++; }
        });
        this.noVideo = !(videoNum > 0);
        if (!this.noVideo) {
          this.openCamera();
        } else {
          this.curStatusInfo = { statusKeyValue: '没有摄像头', statusKey: 5 };
        }
        console.log('设备列表：', deviceList);
      }).catch(function (err) {
        console.log(err, 'checkVideoCanDo');
        this.noVideo = true;
        this.curStatusInfo = { statusKeyValue: '没有摄像头', statusKey: 5 };
        // alert(err.name + ': ' + err.message);
      });
    },
    openCamera () {
      let canvas = document.getElementById('canvas');
      let context = canvas.getContext('2d');// 返回一个用于在画布上绘图的环境。
      var tracker = new window.tracking.ObjectTracker('face');
      // let time = 3000;// 向后台发照片的冷却时间
      tracker.setInitialScale(4);
      tracker.setStepSize(2);
      tracker.setEdgesDensity(0.1);//  转头角度影响识别率
      // 启动摄像头
      this.trackerTask = tracking.track('#video', tracker, { camera: true });
      // this.$store.commit('setIsOpenFaceSave', true);
      this.curStatusInfo = { statusKeyValue: '识别中...', statusKey: 1 };
      this.flag = true;
      tracker.on('track', (event) => {
        context.clearRect(0, 0, canvas.width, canvas.height);// 清空给定矩形内的指定像素。
        // 识别到有头像
        if (this.vIsOpenFaceSave) {      // 判断是否开启了面容录入管理
          // console.log(this.vIsOpenFaceSave, 'this.vIsOpenFaceSave', event.data.length, 'event.data.length');
          if (event.data.length > 0) {
            context.clearRect(0, 0, canvas.width, canvas.height);
            let max = 0;
            let maxImg = 0;
            for (let i = 0; i < event.data.length; i++) {
              let rect = event.data[i];
              if (rect.width * rect.height > max) { max = rect.width * rect.height; maxImg = rect; }
            }
            // event.data.forEach(function (rect) {
            context.strokeStyle = '#fff';// 笔触的颜色、渐变或模式
            context.strokeRect(maxImg.x - 100, maxImg.y, maxImg.width, maxImg.height);
            // });
            console.log(context, 'context', this.flag, 'this.flag');
            if (this.flag) {
              this.curStatusInfo = { statusKeyValue: '扫描中...', statusKey: 2 };
              this.flag = false;
              this.cutPhoto(context);
            }
          }
        }
      });
    },
    // 截取图片     --面容识别.
    cutPhoto () {
      let shortCut = document.getElementById('shortCut');
      let scContext = shortCut.getContext('2d');
      let video = document.getElementById('video');
      scContext.drawImage(video, -50, 0, 400, 400);       // 50为样式的左偏移量
      let imgStr = shortCut.toDataURL('image/png');
      this.imgUrl = shortCut.toDataURL('image/png');
      imgStr = imgStr.substring(imgStr.indexOf(',') + 1);
      // console.log(imgStr, 'cutPhotocutPhoto');
      this.submitAttendanceStudentFace(imgStr);
    },
    // 提交面容识别
    submitAttendanceStudentFace (imgStr) {
      let faceAudio = document.getElementById('face_audio');
      console.log(this.$store.getters.CDNURL, 'this.$store.getters.CDNURL');
      // 将拍照的图片数据发送到后端识别
      this.flag = false;
      AttendanceStudentFace(imgStr).then(result => {
        if (result.code == 0) {
          this.curStatusInfo = { statusKeyValue: result.data.StudentKeyValue + '签到成功！', statusKey: 3 };
          this.signRecodList.unshift(result.data);
        }
        faceAudio.src = this.$store.getters.CDNURL + '/PUBLIC/audio/sign_sussces.mp3';
        this.imgUrl = '';
        faceAudio.play();
      }, error => {
        if (error.code == 1) {
          faceAudio.src = this.$store.getters.CDNURL + '/PUBLIC/audio/sign_reject.mp3';
          this.curStatusInfo = { statusKeyValue: error.data.StudentKeyValue + '已签到！', statusKey: 5 };
          // if(error.data.SignWarningKey == 0) {
          //   faceAudio.src = this.$store.getters.CDNURL + '/PUBLIC/audio/sign_reject_mins.mp3';
          // }
        } else if (error.code == 2) {
          faceAudio.src = this.$store.getters.CDNURL + '/PUBLIC/audio/more_course_sign.mp3';
          this.curStatusInfo = { statusKeyValue: error.data.StudentKeyValue + '有多门课！', statusKey: 5 };
          if (error.data.SignWarningKey > 0) {
            this.signRecodList.unshift(error.data);
          } else {
            faceAudio.src = this.$store.getters.CDNURL + '/PUBLIC/audio/sign_reject_mins.mp3';
          }
        } else if (error.code == 3) {
          this.curStatusInfo = { statusKeyValue: error.data.StudentKeyValue + '没有排课！', statusKey: 5 };
          faceAudio.src = this.$store.getters.CDNURL + '/PUBLIC/audio/no_course_sign.mp3';
          if (error.data.SignWarningKey > 0) {
            this.signRecodList.unshift(error.data);
          }
        } else if (error.code == 61002 || error.code == 61001) {
          faceAudio.src = this.$store.getters.CDNURL + '/PUBLIC/audio/sign_unrecognizable.m4a';
          this.curStatusInfo = { statusKeyValue: '无法识别！', statusKey: 4 };
        } else if (error.code == 61003) {
          faceAudio.src = this.$store.getters.CDNURL + '/PUBLIC/audio/repetition_sign.mp3';
          this.curStatusInfo = { statusKeyValue: '重复刷脸！', statusKey: 5 };
          if (error.data.SignWarningKey == 0) {
            faceAudio.src = this.$store.getters.CDNURL + '/PUBLIC/audio/sign_reject_mins.mp3';
          }
        }
        this.imgUrl = '';
        faceAudio.play();
        console.log('duration', faceAudio.duration, faceAudio);
        console.log('error', this.curStatusInfo.statusKeyValue, error, Date.new());
      }).finally(() => {
        // 签到成功、失败、无法识别摄像头停3秒，3秒后开启下一个面容捕捉
        console.log('触发finally');
        setTimeout(() => {
          this.flag = true;
          this.curStatusInfo = { statusKeyValue: '识别中...', statusKey: 1 };
          console.log('setTimeout:this.flag', this.flag);
        }, 3000);
        this.imgUrl = '';
      });
    },
    // 上传图片       --面容录入
    upLoadImg () {
      let shortCut = document.getElementById('shortCut');
      let scContext = shortCut.getContext('2d');
      let video = document.getElementById('video');
      scContext.drawImage(video, 0, 0, 290, 218);
      let imgStr = shortCut.toDataURL('image/png');
      var fd = new FormData();
      var blob = this.dataURItoBlob(imgStr);

      fd.append('source_from', 'webpage_upload');// 在formdata加入需要的参数
      fd.append('file', blob, 'image.png');

      UploadFiles(1, '', fd)
        .then(response => {
          this.addFace(response.data.url, 160720, 1);
        })
        .catch(err => {
          console.info(err);
        });
    },
    // 图片转成Buffer
    dataURItoBlob (dataURI) {
      var byteString = atob(dataURI.split(',')[1]);
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    // 录入面容
    addFace (faceImgPath, studentID, isHeadImgKey) {
      console.log('addFace:faceImgPath, studentID, isHeadImgKey:', faceImgPath, studentID, isHeadImgKey);
      AddStudentFace(faceImgPath, studentID, isHeadImgKey).then(result => {
        console.log('addFace', result);
      });
    }
  }
  // destroyed () {
  //   if (!this.mediaStreamTrack) { return; }
  //   // 关闭摄像头和侦测
  //   this.mediaStreamTrack.srcObject.getTracks()[0].stop();
  //   this.trackerTask.stop();
  // }
};
</script>
<style scoped>
.container {
  position: relative;
  width: 400px;
  height: 400px;
  float: left;
}
.message {
  float: left;
}
video,
canvas {
  position: absolute;
  /* width: 400px; */
  height: 400px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
 
