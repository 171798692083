<template>
  <div class="bg_white">
    <div class="signuppayment_stundent_lable_banner lable_big"
         style="height:71px;margin:0;over-flow:hidden;">
      <div class="banner_info flex height_auto">
        <div class="banner_info_left">
          <div class="banner_info_img">
            <img :src="vDefaultImg"
                 :onerror="vOnerrorImg"
                 class="img_box"
                 alt="">
          </div>
          <div class="banner_info_name">
            {{studentInfo.MainDemoName}}
            <!-- {{studentInfo.MobileSupport}} -->
          </div>
          <div v-if="dataInfo.PurposeKeyName > 0"
               class="client_support_rank">
            <div class="sell_support_info_rank"
                 :class="vPurposeClass">
              <!-- 客户意愿 -->
              <span>
                {{dataInfo.PurposeKeyName}}
              </span>
            </div>
            <div class="client_support_rank_text"
                 :class="vPurposeClass">
              {{dataInfo.PurposeName}}
            </div>
          </div>
        </div>
        <div class="banner_info_right flex">
          <!-- <div class="banner_info_coupon">
            <span>券包</span>
            <span>10</span>
          </div> -->
          <div class="banner_info_swtich"
               style="width:26px;background-size:24px;height: 71px;"
               v-if="!isShowEdit"
               @click.stop="showStudentList">

          </div>
          <div class="banner_info_edit"
               v-else
               @click.stop="showStudentFile">

          </div>
        </div>
      </div>
    </div>
    <!-- 学生列表 -->
    <custom-dialog title="学生列表"
                   :visible.sync="isShowStudentList"
                   :before-close="closeStudentList">
      <div>
        <student-list :switchItem="switchItem"
                      :studentInfo="studentInfo"
                      :CustStatusKey="CustStatusKey"
                      :IsConfirmKey="IsConfirmKey"
                      :isShowAddBtn="CustStatusKey!=1"
                      :isShowaddOldStudentBtn="isShowaddOldStudentBtn"
                      @selectedItem="doAfterSelectedStudent"
                      ref="studentListDialog"></student-list>
      </div>
    </custom-dialog>
  </div>
</template>
<script>
import {
  EditArrangeSupport,
  GetStudentCommonMsg
} from '../../../../API/workbench.js';
import studentList from '../../../common/student-list';
export default {
  data () {
    return {
      showHeadsImg: true,         // 是否显示头像
      isShowStudentList: false,   // 切换学生显示
      studentInfo: {}
    };
  },
  components: {
    studentList
  },
  props: {
    studentID: [Number, String],
    showMenu: { type: Boolean, default: true },
    dataInfo: Object,
    isShowEdit: {
      type: Boolean,
      default: false
    },
    isShowBox: {
      type: Boolean,
      default: false
    },
    unshowChgStudent: Boolean,
    readonly: {
      type: Boolean,
      default: false
    },
    isExternalAlter: {
      type: Boolean,
      default: false
    },
    showExamine: {
      type: Boolean,
      default: true
    },
    switchItem: {
      type: [String, Number],
      default: 0
    },
    IsConfirmKey: {
      type: Number,
      default: 0 // 0:显示全部学生， 1：过滤掉待验证的学生
    },
    styleInfo: {
      type: String,
      default: ''
    },
    CustStatusKey: { // 学生块的学生列表是否只显示在校学生1为是，0为否（可选）
      type: [Number, String],
      default: ''
    },
    dropDownBoxLeft: {
      type: [Number, String],
      default: ''
    },
    showSwitchBut: {
      type: Boolean,
      default: false
    },
    mobileSupport: {
      type: [Number, String],
      default: ''
    },
    IsConfirmBut: {
      type: Boolean,
      default: true
    },
    // 该学生是否回收1-是;0-否
    IsRecycleKey: {
      type: Boolean,
      default: false
    },
    showPrint: {
      type: Boolean,
      default: false
    },
    isShowaddOldStudentBtn: {// 学生块的学生列表是否需要显示补录老生按钮（可选）
      type: Boolean,
      default: false
    }

  },
  computed: {
    vDefaultImg () {
      let imgUrl = this.studentInfo.HeadImgSupport || '';
      if (imgUrl && imgUrl.indexOf('deflut_studentPhoto') == -1 && imgUrl.indexOf('data:image/png;base64') == -1) {
        if (imgUrl.indexOf('http') == -1) {
          return this.$store.getters.CDNURL + imgUrl;
        } else {
          return imgUrl;
        }
      } else {
        if (this.studentInfo.SexKeyValue == '男' || Number(this.studentInfo.SexKey) == 2) {
          return (
            require('../../../../../public/image/deflut_studentPhoto_boy.png')
          );
        } else {
          return (
            require('../../../../../public/image/deflut_studentPhoto_gril.png')
          );
        }
      }
    },
    vOnerrorImg () {
      if (this.studentInfo.SexKeyValue == '男' || Number(this.studentInfo.SexKey) == 2) {
        return 'this.src="' + require('../../../../../public/image/deflut_studentPhoto_boy.png') + '"';
      } else {
        return 'this.src="' + require('../../../../../public/image/deflut_studentPhoto_gril.png') + '"';
      }
    },
    isShowAlerDialog () {
      return this.showAlerDialogForStu;
    },
    vIsShowAlerDialog () {
      return this.$store.getters.showAlerDialog;
    },
    vLeaveDate () {
      if (this.studentInfo.LeaveDateTime) {
        let today = Date.new();
        let d = today.getDate();
        if (d < 10) {
          d = '0' + d;
        }
        today.setTime(today.getTime());
        let todayNumber = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + d;
        let date = this.$utils.newDateDiff(this.studentInfo.LeaveDateTime, todayNumber) + 1;
        return date;
      } else {
        return '';
      }
    },
    vPurposeClass () {
      let className = '';
      // 客户意愿 S-极高 A-较高 B-一般 C-较低
      if (this.dataInfo.PurposeKeyName) {
        if (this.dataInfo.PurposeKeyName == 'S') {
          className = 'purpose_black';
        } else if (this.dataInfo.PurposeKeyName == 'A') {
          className = 'purpose_brown';
        } else if (this.dataInfo.PurposeKeyName == 'B') {
          className = 'purpose_yellow';
        } else if (this.dataInfo.PurposeKeyName == 'C') {
          className = 'purpose_gray';
        }
      }
      return className;
    }
  },
  watch: {
    //     'isSelectedStudentItem.OLAPKey': {
    //   immediate: true,
    //   handler (nv, ov) {
    //     console.log(this.isSelectedStudentItem, 'dataInfodataInfodataInfo');

    //     this.newInitJson = this.isSelectedStudentItem;
    //   }
    // }
    studentID (n, o) {
      console.log(n, '学生IDDIDID');
      this.showHeadsImg = !this.showHeadsImg;
      this.isPatriarchCourse = false;
      this.isShowArrangeText = false;
      this.$nextTick(e => {
        this.showHeadsImg = true;
      });
      // if (n != o) {
      this.loadStudentDetailInfo();
      // }
    },
    'mobileSupport': {
      handler (newName, oldName) {
        if (newName) {
          this.studentInfo.MobileSupport = newName;
        }
        console.log(this.studentInfo.MobileSupport, '手机号码123');
      },
      deep: true
    }
  },
  created () {
    if (this.studentID > 0) {
      this.loadStudentDetailInfo(this.studentID, true);
    }
    document.addEventListener('click', e => {
      const ele = document.getElementsByClassName('alert_dialog')[0];
      if (e.target && !e.target.contains(ele)) {
        this.showAlerDialogForStu = false;
      }
    });
  },
  mounted () {
    this.$bus.on(this, 'AfterStudentUpdate', studentKey => {
      if (studentKey == this.studentID) {
        this.loadStudentDetailInfo(studentKey, true);
      }
    });
    this.$dialog.register(this, ['studentList', 'studentFile', 'registerCustomerDetails']);
  },
  methods: {

    showPatriarchCourse () {
      this.isPatriarchCourse = true;
    },
    arrangeShowText () {
      this.isShowArrangeText = true;
      this.isArrangeTextPa = false;
    },
    showAlterMessagePopup () {
      // if (!this.IsRecycleKey) {
      this.$emit('showAlterMessagePopup');
      this.isAlterMessagePopup = true;
      this.alterMessageText = this.studentInfo.ArrangeSupport;
      // }
    },
    hideAlterMessagePopup () {
      console.log('hideAlterMessagePopup');
      this.isAlterMessagePopup = false;
      this.$emit('closed');
    },
    submitStudentArrangeSupport () {
      EditArrangeSupport(this.studentInfo.OLAPKey, this.alterMessageText).then(
        res => {
          this.studentInfo.ArrangeSupport = this.alterMessageText;
          this.$nextTick(() => {
            if (this.$refs.patriarchCourseUl && this.$refs.patriarchCourseUl.offsetWidth >= (this.$refs.studentLabelPatriarch.offsetWidth * 0.5)) {
              this.isPatriarchCourseText = true;
            } else {
              this.isPatriarchCourseText = false;
            }
          });
          this.hideAlterMessagePopup();
        },
        err => {
          this.hideAlterMessagePopup();
          layer.alert(err.msg);
        }
      );
    },
    closeStudentList () {
      this.isShowStudentList = false;
    },
    // 切换学生
    doAfterSelectedStudent (item) {
      console.log(item, '切换学生');
      // this.refreshArrangeText();
      this.loadStudentDetailInfo(item.OLAPKey);
      this.$emit('alterStudent', item);
      this.isShowStudentList = false;
    },

    showStudentList () {
      this.isShowStudentList = true;
    },
    // 验证学生
    verifyStudentClick () {
      if (this.IsConfirmBut) {
        this.verifyPopup = true;
      }
    },
    // 显示验证学生弹出层
    closeVerifyPopup (OLAPKey) {
      this.verifyPopup = false;
      if (OLAPKey) { // 当学生ID为true时,刷新
        this.loadStudentDetailInfo(OLAPKey, 1);
      }
    },
    showStudentFile (switchCourse, courseInfo) {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'studentFile',
        data: { studentID: this.studentInfo.OLAPKey }
      });
    },
    loadStudentDetailInfo (studentID, alterStudent) {
      let OLAPKey = studentID || this.studentID;
      GetStudentCommonMsg(OLAPKey).then(result => {
        this.studentInfo = result.data;
        console.log(this.studentInfo, '学生块');
        if (alterStudent) {
          this.$emit('alterStudent', this.studentInfo);
        }
        this.$nextTick(() => {
          if (this.$refs.patriarchCourseUl && this.$refs.patriarchCourseUl.offsetWidth >= (this.$refs.studentLabelPatriarch.offsetWidth * 0.5)) {
            this.isPatriarchCourseText = true;
          } else {
            this.isPatriarchCourseText = false;
          }
        });
        // this.refreshArrangeText();
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    showAlertDialog (e) {
      this.$store.commit('showAlerDialog', false);
      let Rect = e.target.getBoundingClientRect();
      let parentRect = this.$parent.$el.getBoundingClientRect();

      this.$nextTick(() => {
        this.$store.commit('showAlerDialog', true);
        this.showAlerDialogForStu = true;
      });
      this.positionLeft = Rect.left - parentRect.left - 8;
      this.positionTop = Rect.bottom - parentRect.top;

      this.positionLeft = Rect.left / 2 - 196;
      this.positionTop = Rect.top - 35;
    },
    switchStudent (e) {
      let Rect = e.target.getBoundingClientRect();
      let parentRect = this.$parent.$el.getBoundingClientRect();
      this.doAfterClick(this.optionStatus[1]);
      this.positionLeft = Rect.left - parentRect.left - 8;
      this.positionTop = Rect.bottom - parentRect.top;
      this.positionLeft = Rect.left / 2 - 196;
      this.positionTop = Rect.top - 35;
    },
    updateVal (e) {
      this.onkeyupEvent(e);
    },
    changeVal (e) {
      this.onkeyupEvent(e);
    },
    limitMaxLength (str, maxLen) {
      var result = [];
      var re = /^[a-zA-Z]+$/;
      for (var i = 0; i < maxLen; i++) {
        var char = str[i];
        if (!re.test(char)) { maxLen--; }
        result.push(char);
      }
      return result.join('');
    },
    onkeyupEvent (e) {
      let val = e.target.value;
      if (this.$utils.strLength(val) > this.maxLength * 2) {
        e.target.value = this.limitMaxLength(val, this.maxLength * 2);
        this.alterMessageText = e.target.value;
      }
    }
  }
};
</script>