<template>
  <div class="html_view_outside">
    <!-- 是否显示slot由外部决定 -->
    <slot></slot>
    <div class="html_view_icons pr">
      <div class="menuBox_list list_img pr"
           v-if="settingInfo.showAddPic">
        <img src="../../../../../public/image/image_icon.png">
        <div>
          <choose-img-mutil class="upload_box"
                            :maxNum=12
                            @onUpLoadImgSuccess="doAfterUpLoadImgSuccess"></choose-img-mutil>
        </div>
      </div>
      <div class="menuBox_list list_img pr"
           v-else>
        <img src="../../../../../public/image/image_icon_dis.png">
      </div>
      <div class="pr">
        <img src="../../../../../public/image/voice_icon.png"
             class="menuBox_list list_audio"
             v-if="settingInfo.showAddAudio"
             @click.stop="clickRecordVoice">
        <img src="../../../../../public/image/voice_icon_dis.png"
             class="menuBox_list list_audio"
             style="width:24px;margin-top: 16px;"
             v-else>
        <record-voice v-if="settingInfo.showAddAudio"
                      @onCancelVoice="toCancelVoice"
                      @onUploadFiles="doAfterUploadAudio"
                      @onDeleteAudio="deleteAudio">
        </record-voice>
      </div>
      <div class="pr">
        <img src="../../../../../public/image/video_icon.png"
             @click.stop="clickVideo"
             v-if="settingInfo.addVideoType>0">
        <img src="../../../../../public/image/video_icon_dis.png"
             v-else>
        <upload-video v-if="settingInfo.addVideoType>0"
                      @onUploadFiles="doAfterUploadvideo">
        </upload-video>
      </div>
      <div class="meme_area">
        <img src="../../../../../public/image/expression_icon.png"
             @mouseup.stop="clickMeme"
             class="button meme_button ">
      </div>
      <div v-if="showRelevanceStudent">
        <img src="../../../../../public/image/students_name_icon.png"
             @click="clickRelevanceStudent"
             class="button">
      </div>
      <div v-if="showRelevanceTeacher">
        <img src="../../../../../public/image/students_name_icon.png"
             @click="clickRelevanceTeacher"
             class="button">
      </div>
      <div v-if="settingInfo.showUploadFile">
        <img src="../../../../../public/image/annex.png"
             @click.stop="clickUploadFile"
             class="button">
      </div>
      <div v-if="showMemeList"
           class="meme_list_release meme_area">
        <meme-list @seletedMemeItem="seletedMemeItemAfter"
                   @deleteEmoji="deleteEmoji"></meme-list>
      </div>
    </div>

  </div>
</template>

<script>
import recordVoice from './record-voice';
import uploadVideo from './upload-video';
// import videoAPI from '../../../../common/video/index.js';
import memeList from './meme-list/index.vue';
export default {
  data () {
    return {
      isShowRecordVoice: false,
      showMemeList: false, // 显示表情框
      isShowFastSearchStudentBottomPop: false, // 显示学生框
      isSave: false
    };
  },
  components: {
    recordVoice,
    memeList,
    uploadVideo
  },
  props: {
    value: Object, // 发文的内容
    settingInfo: {
      type: Object,
      default: () => {
        return {
          contentTitle: {       // 根据传入类型判断显示的类型.
            type: String,
            default: '作业内容'
          },
          formTitle: {
            type: String,
            default: ''
          },
          formTitleplaceholder: {
            type: String,
            default: ''
          },
          placeholder: {
            type: String,
            default: ''
          },
          isTeacher: {
            type: [String, Number],
            default: 0
          },
          showAddPic: {   // 显示添加图片
            type: Boolean,
            default: true
          },
          showAddAudio: {   // 显示添加图片
            type: Boolean,
            default: true
          },
          // 0关闭 1拍选压 2拍压 3选压 4选不压
          addVideoType: {
            type: Number,
            default: 0
          },
          showRelevance: {    // 显示添加关联
            type: Boolean,
            default: true
          },
          showUploadFile: {   // 显示上传文件
            type: Boolean,
            default: false
          }
        };
      }
    },
    showRelevanceStudent: {
      type: Boolean,
      default: true
    },
    showRelevanceTeacher: {
      type: Boolean,
      default: false
    }

  },
  mounted () {
    document.querySelector('body').addEventListener('click', e => {
      if (e.target.parentNode.className.indexOf('meme_area') < 0) {
        this.showMemeList = false;
      }
    }, false);
    document.querySelector('.meme_button').addEventListener('mousedown', e => {
      e.preventDefault();
    }, false);
  },
  methods: {
    saveClick () {
      this.isSave = !this.isSave;
      this.$emit('saveClick', this.isSave);
    },
    doAfterUpLoadImgSuccess (imgInfo, callback) {
      this.$emit('doAfterUpLoadImgSuccess', imgInfo, callback);
      console.log(this.value, 'doAfterUpLoadImgSuccess1111', imgInfo);
    },
    // 上传音频后
    doAfterUploadAudio (url, recorderDuration) {
      console.log('上传录音，录音时长：', recorderDuration);
      this.$emit('onUploadFiles', url, recorderDuration);
    },
    // 上传视频后
    doAfterUploadvideo (formData) {
      console.log('上传视频');
      this.$emit('onUploadVideoFiles', formData);
    },
    // 点击语音
    clickRecordVoice () {
      this.isShowRecordVoice = true;
    },
    // 删除音频
    deleteAudio () {
      this.$emit('onDeleteAudio');
    },
    // 取消录音
    toCancelVoice () {
      this.isShowRecordVoice = false;
    },
    seletedMemeItemAfter (item) {
      this.showMemeList = false;
      this.$emit('seletedMemeItem', item);
    },
    deleteEmoji () {
      this.$emit('deleteEmoji');
    },
    clickVideo () {
      let callback = (resVideo) => {
        this.$emit('onSeletedVideo', resVideo.tempFilePath, resVideo.tempFile, resVideo.size, resVideo.duration);
      };
      switch (this.addVideoType) {
        case 1:
          videoAPI.chooseVideo(callback);
          break;
        case 2:
          videoAPI.shootVideo(callback);
          break;
        case 3:
          videoAPI.takeVideo(callback, true);
          break;
        case 4:
          videoAPI.takeVideo(callback, false);
          break;
        default:
          videoAPI.chooseVideo(callback);
          break;
      }
    },
    clickMeme (e) {
      this.showMemeList = !this.showMemeList;
      console.log('clickMeme', this.showMemeList);
    },
    mouseupMeme () {
      console.log('mouseupMeme');
    },
    clickRelevanceStudent () {
      let name = '@学生名';
      console.log('clickRelevanceStudent');
      this.$emit('clickRelevance', name);
    },
    clickRelevanceTeacher () {
      let name = '@老师名';
      this.$emit('clickRelevance', name);
    },
    clickUploadFile () {
      this.$store.dispatch('popup/open', {
        content: '暂未开通此通能'
      });
    }

  }
};
</script>

<style>
.meme_list_release {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 60px;
  text-align: left;
  border: 1px solid #ececec;
}
</style>
