<template>
  <!-- 基础设置项 -->
  <div>
    <div class="content_marginRL content_marginTop_30">
      <!-- 内容 -->
      <div class="mendian_setting_content single_shadow">
        <div>
          <input-text :formTitle="'名称'"
                      :readonly="false"
                      :formPlaceholder="'名称'"
                      class="no_border_bottom"
                      :required="true"
                      v-model="labelGroupText"></input-text>
        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <div class="">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'static'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
  </div>

</template> 

<script>
import { AddLabelGroup, EditLabelGroup, AddLabel, EditLabel } from '../../../API/workbench';
export default {
  props: {// 是否显示表单
    isReadonly: {// 是否只读
      type: Boolean,
      default: false
    },
    operateType: String,
    threadInfo: {//
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data () {
    return {
      labelGroupText: '', // 默认值
      // 数据对象 组
      dataGroupJson: {
        LabelGroupKey: '', // -- 标签组ID
        LabelGroupKeyValue: '' // --标签组名称
      },
      // 数据对象 标签
      dataLabelJson: {
        LabelKey: '', // 标签ID
        LabelKeyValue: '', // -- 标签名称
        LabelGroupKey: '', // --标签组ID
        LabelGroupKeyValue: '' // --标签组名称
      }
    };
  },
  computed: {
  },
  created () {
    console.log('threadInfo operateType', this.threadInfo, this.operateType);
    if (JSON.stringify(this.threadInfo) != '{}') {
      if (this.operateType == 'add2') {
        this.labelGroupText = '';
      } else {
        this.labelGroupText = this.operateType == 'edit1' ? this.threadInfo.MainDemoName : this.threadInfo.LabelKeyValue;
      }
    }
  },

  methods: {
    // 确认按钮
    confirmClick (callBack) {
      let flag = false;
      let fn = '';
      let obj = {};
      if (this.operateType == 'add1') {
        // 添加 标签组
        fn = AddLabelGroup;
        obj = {
          LabelGroupKeyValue: this.labelGroupText // 标签组名称
        };
      } else if (this.operateType == 'add2') {
        // 添加标签
        fn = AddLabel;
        obj = {
          LabelKeyValue: this.labelGroupText, // 标签名称
          LabelGroupKey: this.threadInfo.OLAPKey, // 标签组ID
          LabelGroupKeyValue: this.threadInfo.MainDemoName // 标签组名称
        };
      } else if (this.operateType == 'edit1') {
        // 修改标签组
        fn = EditLabelGroup;
        obj = {
          LabelGroupKey: this.threadInfo.OLAPKey, // 标签组ID
          LabelGroupKeyValue: this.labelGroupText // 标签组名称
        };
      } else if (this.operateType == 'edit2') {
        // 修改标签组
        fn = EditLabel;
        obj = {
          LabelKey: this.threadInfo.LabelKey, // 标签ID
          LabelKeyValue: this.labelGroupText // 标签名称
        };
      }
      console.log(obj, 'obj');
      if (!this.labelGroupText) {
        layer.alert('请输入名称');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }

      fn(obj).then(result => {
        this.$emit('afterSuccess', result.data);
        this.cancelClick();
        layer.alert('更新成功');
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 取消按钮
    cancelClick () {
      this.$emit('cancelClick');
    }
  }
};
</script>

  <style>
</style>