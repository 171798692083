<template>
  <div class="WKT_course_info_img">
    <img :src="vImg"
         :onerror="defaultImg">
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    imgItem: Object
  },
  computed: {
    vImg () {
      if (!this.imgItem.imgUrl) {
        this.imgItem.imgUrl = '';
      }
      return this.$utils.ossLitimgSet(this.imgItem.imgUrl);
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../public/image/no_logo@2x.png') + '"';
    }
  }
};
</script>
