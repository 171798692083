<template>
  <div class="img_box">
    <div class="content_bannber">
      <div class="content_banner_box">
        <img :src="vUrl" class="content_banner_box_img"  :style="{maxWidth: vMaxWidth,maxHeight:vMaxHeight}"/> 
        <div class="watermark">
          {{watermark}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataInfo: Object,
    watermark: String
  },
  computed: {
    vUrl () {
      if (this.dataInfo.AttachViewUrl) {
        return this.dataInfo.AttachViewUrl;
      } else {
        return this.dataInfo.CoverUrl;
      }
    },
    vMaxWidth () {
      return document.documentElement.clientWidth + 'px';
    },
    vMaxHeight () {
      return document.documentElement.clientHeight + 'px';
    }
  }
};
</script>