<template>
  <div class="swiper-slide"
       @click="getItem">
    <div class="head_div_left">
      <img :src="headImg"
           width="100%"
           height="100%"
           :onerror="defaultImg"
           :class="{ 'swiper-list-text-choose': item.isActive }"
           style="border-radius: 8px"
           alt="../../../../public/image/no_logo@2x.png" />
      <span class="head_div_left_span">{{ item.MainDemoName }}</span>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      items: {}
    };
  },
  // watch:{
  //   item:{
  //     handler(c,o){
  //       if(c){
  //        this.items = this.item
  //        console.log('item',this.items)
  //       }
  //     },
  //   },
  // },
  computed: {
    headImg () {
      if (this.item && this.item.CoverUrl) {
        if (this.item.CoverUrl.indexOf('http') > -1) {
          return this.item.CoverUrl;
        } else {
          return this.$store.getters.CDNURL + this.item.CoverUrl;
        }
      } else {
        return '';
      }
    },
    defaultImg () {
      return (
        'this.src="' +
        require('../../../../public/image/no_logo@2x.png') +
        '"'
      );
    }
  },
  created () { },
  mounted () {
    // this.items = this.item
  },
  methods: {
    getItem () {
      if (this.item.ISAuthExpireKey == 1) {
        layer.alert('课件端口过期，无法加载课件，请联系课件授权方!');
      } else if (this.item.IsSuspendAuthKey == 1) {
        layer.alert('课件授权暂停，无法加载课件，请联系课件授权方!');
      } else {
        this.$emit('chgCoureDateAfter', this.item);
      }
    }
  }
};
</script>
<style scoped>
.swiper-slide {
  width: 250px !important;
  height: 140px;
  border-radius: 8px;
  margin-left: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  overflow: hidden;
}

.head_div_left {
  width: 100%;
  height: 100%;
  /* border-radius: 6px; */
  position: relative;
  float: left;
}
.head_div_left_span {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  padding-left: 15px;
  line-height: 29px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* left: 15px; */
}
.swiper-list-text-choose {
  border: 3px solid #3498db;

  border-image-source: linear-gradient(0deg, #3498db, #3498db),
    linear-gradient(0deg, #356fff, #356fff);

  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 10px 30px rgba(52, 152, 219, 0.1),
    0px 4px 6px rgba(50, 95, 209, 0.1), 0px 4px 5px rgba(28, 84, 224, 0.1);
}
</style>
