<template>
  <div class="">
    <div v-if="vLoading"
         class="index_loading">
      加载中...
    </div>
    <!-- <div v-else
         class="index_JXT_noData">
      <div class="index_JXT_noData_text">{{fontText}}</div>
      <div class="index_JXT_noData_tips">{{fontTips}}</div>
    </div> -->
    <div v-else
         class="monitoring_content_nodata">
      <div class="monitoring_content_nodata_txt2">{{fontTips}}</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: true
    };
  },
  props: {
    fontText: {
      type: String,
      default: '空空如也'
    },
    fontTips: {
      type: String,
      default: '亲，当前没有任何数据哦~'
    },
    isLoading: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    vLoading () {
      if (this.isLoading != null && typeof this.isLoading == 'boolean') {
        this.$emit('loading', this.isLoading);
        return this.isLoading;
      } else {
        this.$emit('loading', this.loading);
        return this.loading;
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.loading = false;
    }, 1200);
  }
};
</script>

<style>
/* scoped */
.index_loading {
  width: 100%;
  min-height: 300px;
  padding-top: 200px;
  font-size: 16px;
  line-height: 1;
  color: #999;
  text-align: center;
  background: transparent url(../../../public/image/loading_banner.gif)
    no-repeat center 150px;
  background-size: 78px;
}
.index_JXT_noData_tips {
  font-size: 14px;
  line-height: 1;
  color: #999;
  text-align: center;
}
</style>
