<template>
  <div class="form_info_edit form_info_list" style="padding-bottom: 200px;">
    <input-number :min="0"
                  :formTitle="'备课提前天数'"
                  :required="true"
                  :readonly="false"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo[1].SetContent"></input-number>

    <input-number :min="0"
                  :formTitle="'备课确认间隔'"
                  :required="true"
                  :readonly="false"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo[0].SetContent"></input-number>
    <div style="margin: 85px; margin-bottom: 30px;color: #999;"></div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { modifyLessonPreparationSettings } from '../../../../../API/workbench';
export default {
  data () {
    return {
      dataInfo: [{
            "SetContent":"60",
            "SetKey":"0",
            "SetKeyValue":"0",
            "SetTypeKey": '66' //备课确认间隔
      },
      {
            "SetContent":"",
            "SetKey":"0",
            "SetKeyValue":"0",
            "SetTypeKey": '67' //备课提前天数
      }]
    };
  },
  components: {

  },
  props: {
    preparingSetList:Array
  },
  created () {
    console.log(this.preparingSetList,"preparingSetList",'-----------',this.dataInfo, 'dataInfo');
    this.dataInfo[0].SetContent = this.preparingSetList[0].SetContent;
     this.dataInfo[1].SetContent = this.preparingSetList[1].SetContent;
  },
  computed: {
  },
  methods: {
    // 提交
    submit (callback) {
      console.log('提交', this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      modifyLessonPreparationSettings(this.dataInfo).then(result => {
        layer.alert('提交成功');
        this.$emit('afterSuccess');
        this.cancel();
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callback) {
          callback();
        }
      });
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
      if (!this.dataInfo[1].SetContent ) {
        layer.alert('备课提前天数不可以设置为0');
        flag = false;
      }
      return flag;
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
