<template>
  <div class="teaching_mode">
    <div class="teaching_mode_header">
      <div class="teaching_mode_header_left">
        <img :src="vBrandInfo.LogoUrl"
             alt />
      </div>
      <div class="teaching_mode_header_right">
        <div class="teaching_mode_header_right_btn"
             @click.stop="toWorkbench">
          退出
        </div>
      </div>

    </div>
    <div class="teaching_mode_content swiper_box">

      <div class="courseware_packge_list swiper-container swiper_courseware_packge_list">
        <!-- <div class="swiper_wrapper_before" v-if="Number(swiperNewIndex)>0"></div> -->
        <div class="swiper-wrapper"
             v-show="dataList.length>0">
          <courseware-packge-list-item v-for="(item,key) in dataList"
                                       :key="key"
                                       :item="item"
                                       @seletedItem="seletedItem"></courseware-packge-list-item>

        </div>
        <div v-if="dataList.length==0&&isLoadEnd"
             class="teaching_mode_nodta">
          <div class="teaching_mode_nodta_text">暂无课包</div>
        </div>
        <!-- <div class="swiper_wrapper_after" v-if="Number(swiperNewIndex)+4<=dataList.length-1"></div> -->
        <!-- <div class="teaching_mode_bg_ligth">

        </div> -->
      </div>
      <div class="teaching_mode_date">{{timer}}</div>
    </div>
    <div>
      <dialog-factory ref="teachingModeDialog"
                      :key="'teachingMode'"
                      :routerName="'teachingMode'"></dialog-factory>
      <dialog-factory-common ref="teachingModeCommonDialog"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import {
  TeachingModeGetBigCoursewarePackageList,
  GetTheEmployeeByID,
  GetSaaSClientMsg
} from '../../API/workbench';
import coursewarePackgeListItem from './components/courseware-packge-list-item';
import dialogFactory from '../common/dialog-factory';
export default {
  data () {
    return {
      swiper: null,
      dataList: [],
      token: null,
      mendianInfo: null,
      staffInfo: {},
      isFullscreen: false,
      swiperNewIndex: 0,
      vLeftShow: false,
      vRightShow: false,
      isLoadEnd: false,
      timer: ''
    };
  },
  components: {
    coursewarePackgeListItem,
    dialogFactory
  },
  created () {
    this.token = this.$store.getters.token;
    console.log(this.token, 'token');
    console.log(
      this.$store.getters.SaaSClientInfo,
      'store.getters.SaaSClientInfo'
    );

    this.getStaffInfo(this.token.UserID);
    if (!this.$store.getters.SaaSClientInfo) {
      this.getMendianInfo();
    }
    ['fullscreenerror', 'webkitfullscreenerror', 'mozrequestscreenerror', 'msrequestfullscreen'].forEach(item => {
      document.addEventListener(item, () => {
        this.isExceptionForFullScreen()
      })
    });

  },
  mounted () {
    this.getDataList();
    // this.fullScreen();
    let isFullscreen = this.getFullScreenStatus();
    if (this.$zxClient.IsClient) {
      try {
        this.$zxClient.FullScreen();
      } catch (ex) {
        let isFullscreenS = document.body.scrollHeight == window.screen.height && document.body.scrollWidth == window.screen.width;
        console.log('isFullscreenS', isFullscreenS)
        if (!isFullscreenS) {
          this.isExceptionForFullScreen()
        }
      }

    } else {
      if (!isFullscreen) {
        this.fullScreen();
      }

    }

    console.log('isFullscreen', isFullscreen)
    this.vDateTime()

    this.registerBusEvent();


  },
  computed: {
    vstaffImg () {
      if (!this.staffInfo.XMLIcon) {
        this.staffInfo.XMLIcon = '';
        return this.staffInfo.XMLIcon
      }
      if (this.staffInfo.XMLIcon.indexOf('http') > -1) {
        return this.staffInfo.XMLIcon;
      } else {
        return this.$store.getters.CDNURL + this.staffInfo.XMLIcon;
      }
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' +
        require('../../../public/image/deflut_teacher_photo@2x.png') +
        '"'
      );
    },
    vBrandInfo () {
      let obj = {};
      if (window.brandCustomSettingStr && window.brandCustomSettingStr != '') {
        obj = JSON.parse(window.brandCustomSettingStr);
        obj.isOpenBrand = true;
        obj.LoginBackground = 'url(' + this.$store.getters.CDNURL + obj.LoginBackground + ')';
        obj.LogoUrl = this.$store.getters.CDNURL + obj.LogoUrl;
        obj.QRCode = this.$store.getters.CDNURL + obj.QRCode;
        obj.inputBackground = '#fff';
      } else {
        obj = {
          isOpenBrand: false,
          BrandSlogan: 'url(' + require('../../../public/image/login_text.png') + ')',
          // LoginBackground: 'url(' + require('../../../public/image/login_banner.png') + ')',
          LoginBackground: '',
          LogoUrl: require('../../../public/image/logo_terminal.png'),
          MainBodyColor: '#f85a59',
          MainDemoName: '治学培训机构',
          QRCode: '',
          inputBackground: '#f7f7f7'
        };
      }
      return obj;
    },

    vlogo () {
      this.vSaaSClientInfo.LogoPath = this.vSaaSClientInfo.LogoPath || ''
      if (this.vSaaSClientInfo.LogoPath.indexOf('http') > -1) {
        return this.vSaaSClientInfo.LogoPath;
      } else {
        return this.$store.getters.CDNURL + this.vSaaSClientInfo.LogoPath;
      }
    },
    vSaaSClientInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    // 判断是校长还是老师
    vRoleKey () {
      console.log(Number(this.$store.getters.token.RoleKey), "校长and老师");
      return this.$store.getters.token.RoleKey ? Number(this.$store.getters.token.RoleKey) == 2 : false; // 2为校长；非则为老师
    },

  },
  watch: {
    $route (to, from) {
      // // 所有的路由都为keepAlive
      // if (!to.meta.keepAlive) {
      //   to.meta.keepAlive = true;
      // }
      // let headerBarList = this.$store.getters.headerBarList;
      // // 路由不存在headerBarList 则关闭了 所以把keepAlive设置false
      // if (
      //   headerBarList.findIndex(obj => {
      //     return obj.routerName == from.name;
      //   }) == -1
      // ) {
      //   from.meta.keepAlive = false;
      // }

      // 激活下个路由（选中效果）
      // let toRouterIndex = headerBarList.findIndex(obj => {
      //   return obj.routerName == to.name;
      // });
      // if (toRouterIndex > -1) {
      //   headerBarList[toRouterIndex].isSelected = true;
      //   this.$store.commit('setHeaderBarList', headerBarList);
      // }
      console.log('路由弹窗弹窗')
      this.$nextTick(() => {
        let ele = document.getElementById(to.name);
        if (ele) {
          // 检查目标路由是否有弹出框
          // 有 重新计算遮罩层的zIndex
          // 没有 把遮罩层隐藏
          if (ele.querySelectorAll('.popup_dialog').length > 0) {
            let divs = ele.querySelectorAll('.popup_dialog_box');
            let max = 0;
            for (let i = 0; i < divs.length; i++) {
              let divEL = divs[i];
              max = Math.max(max, divEL.style.zIndex || 0);
            }
            if (max <= 0) {
              max = 10;
            }
            this.$store.commit('SetPopupMaxZIndex', max - 1);
            this.$store.commit('SetPopupShade', true);
          } else {
            if (document.querySelectorAll('.popup_dialog_face')[0].style.visibility == 'visible') {
              console.log('触发visibility');
            } else if (this.$route.name == 'recruitStudentShow' && document.querySelectorAll('.open').length > 0) {
              console.log('触发recruitStudentShow');
              this.$store.commit('SetPopupShade', true);
            } else {
              this.$store.commit('SetPopupShade', false);
            }
          }
        }
      });
    }
  },
  methods: {
    vDateTime () {
      setInterval(o => {
        this.timer = this.$utils.formatDatet(Date.new(), 'yyyy-MM-dd') + ' ' + this.$utils.formatDatet(Date.new(), 'mm:ss')
      }, 1000)
    },
    seletedItem (item) {
      console.log('seletedItem', item);
      this.$router.push({ name: 'teachingModeCousewarePackgeDetail', query: { OLAPKey: item.OLAPKey } });
    },
    // 判断当前页面是否全屏
    getFullScreenStatus () {
      return !!(
        document.fullScreen ||
        document.mozFullScreen ||
        document.webkitIsFullScreen ||
        document.webkitFullScreen ||
        document.msFullScreen
      );
    },
    isExceptionForFullScreen () {
      layer.alert('点击确定进入全屏模式', {
        mode: 'teachingMode',
        btn: [
          {
            name: '确认',
            callBack: () => {
              layer.close();
              if (this.$zxClient.IsClient) {
                this.$zxClient.FullScreen();
              } else {

                this.fullScreen();

              }
            }
          }
        ]
      })
    },
    fullScreen () {
      let isFullscreen = this.getFullScreenStatus();
      if (!isFullscreen) {
        if (document.documentElement.RequestFullScreen) {
          document.documentElement.RequestFullScreen();
        }
        //兼容火狐
        console.log(document.documentElement.mozRequestFullScreen)
        if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        }
        //兼容谷歌等可以webkitRequestFullScreen也可以webkitRequestFullscreen
        if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen();
        }
        //兼容IE,只能写msRequestFullscreen
        if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        }
      } else {
        console.log('已在全屏状态');
      }
    },
    toWorkbench () {
      let moduleInfo = {
        routerName:
          this.$store.getters.SaaSClientEditionKey == 6
            ? 'coursewareWorkBench'
            : 'indexView',
        routertitle: '工作台',
        ModulePowerKey: 0
      };



      if (this.$zxClient.IsClient) {
        this.$zxClient.UnFullScreen();
      } else {
        this.exitFullscreen();
      }




      // this.exitFullscreen();
      this.$bus.emit('needOpenRouter', moduleInfo);
      setTimeout(() => {
        let ele = document.getElementById(this.$store.getters.SaaSClientEditionKey == 6
          ? 'coursewareWorkBench'
          : 'indexView'
        );
        if (ele) {
          // 检查目标路由是否有弹出框
          // 有 重新计算遮罩层的zIndex
          // 没有 把遮罩层隐藏
          if (ele.querySelectorAll('.popup_dialog').length > 0) {
            this.$store.commit('SetPopupShade', true);
          }
        }

      }, 100)
    },
    isFullscreenForNoScroll () {
      var explorer = window.navigator.userAgent.toLowerCase();
      if (explorer.indexOf('chrome') > 0) { // webkit
        if (document.body.scrollHeight === window.screen.height && document.body.scrollWidth === window.screen.width) {
          return true;
        } else {
          return false;
        }
      } else { // IE 9+  fireFox
        if (window.outerHeight === window.screen.height && window.outerWidth === window.screen.width) {
          return true;
        } else {
          return false;
        }
      }
    },
    exitFullscreen () {
      let isFullscreen = this.getFullScreenStatus();
      console.log('isFullscreen', isFullscreen);
      if (isFullscreen) {
        if (document.exitFullScreen) {
          document.exitFullscreen()
        }
        //兼容火狐
        console.log(document.mozExitFullScreen)
        if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        }
        //兼容谷歌等
        if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        }
        //兼容IE
        if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        console.log('不在全屏状态');
      }
    },
    // 获取门店信息
    getMendianInfo () {
      GetSaaSClientMsg().then(
        result => {
          this.mendianInfo = result.data;
          this.$store.commit('setSaaSClientInfo', result.data);
        },
        err => {
          layer.alert(err.msg);
        }
      );
    },
    // 获取员工信息.
    getStaffInfo (OLAPKey, callback) {
      GetTheEmployeeByID(OLAPKey)
        .then(result => {
          this.staffInfo = result.data;
          console.log('getStaffInfo', result.data);
        })
        .catch(error => {
          console.log('getStaffInfo', error);
        });
    },
    getDataList () {
      TeachingModeGetBigCoursewarePackageList().then(result => {
        console.log(result.data, 'TeachingModeGetBigCoursewarePackageList');
        this.dataList = result.data.filter((obj) => {
          return Number(obj.IsPCAuthKey) == 1;
        });
        this.$nextTick(o => {
          this.isLoadEnd = true
          this.initSwiper();
        })
      });
    },
    initSwiper () {
      var that = this
      this.swiper = new Swiper('.swiper_courseware_packge_list', {
        grabCursor: true,
        slidesPerView: 4,
        spaceBetween: 25,
        // loop: false, // 循环模式选项
        observer: false, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: false, // 修改swiper的父元素时，自动初始化swipe
        // navigation: {
        // nextEl: '.swiper-button-next',
        // prevEl: '.swiper-button-prev',
        // },
        on: {
          // 拖动结束后触发事件预加载. 判断最左边隐藏的课程数据是否小于十
          transitionEnd: function (callback) {
            that.swiper = this;
            that.swiperNewIndex = this.realIndex.toString()
            console.log(that.swiperNewIndex, 'transitionEnd')

          }
        },
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCustomDialog', dialogInfo);
      if (this.$refs.teachingModeDialog) {
        this.$refs.teachingModeDialog.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCommonCustomDialogByFactoryDialog', dialogInfo);
      if (this.$refs.teachingModeCommonDialog) {
        this.$refs.teachingModeCommonDialog.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', dialogInfo => {
        if (dialogInfo.routerName == 'teachingMode') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', dialogInfo => {
        if (dialogInfo.routerName == 'teachingMode') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
  }
};
</script>
<style scoped>
.teaching_mode {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: url('../../../public/image/new_teaching_mode_bg.png') center
    no-repeat;

  background-size: 100%;
  overflow: hidden;
}
.teaching_mode_header {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 31.2rem;
  justify-content: space-between;
  z-index: 2;
}
.teaching_mode_header_left {
  width: 127rem;
  height: 37.44rem;
  display: flex;
}
.teaching_mode_header_left .teaching_mode_header_left_img {
  width: 33.5rem;
  height: 33.5rem;
  border-radius: 32.57rem;
  overflow: hidden;
  margin-left: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.teaching_mode_header_left .staff_name {
  width: 70rem;
  height: 26.52rem;
  font-size: 9.36rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-left: 3.9rem;
  color: #fff;
}
.teaching_mode_header_left .workbench_btn {
  width: 67.87rem;
  height: 26.52rem;
  background: linear-gradient(
      0deg,
      rgba(187, 198, 230, 0.2),
      rgba(187, 198, 230, 0.2)
    ),
    #777e90;
  border-radius: 39rem;
  font-size: 9.36rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: 3.9rem;
}
.teaching_mode_header_left .workbench_btn:hover {
  background: linear-gradient(
      0deg,
      rgba(187, 198, 230, 0.2),
      rgba(187, 198, 230, 0.2)
    ),
    #777e90;
  box-shadow: 0px 1.95rem 7.8rem rgba(22, 131, 249, 0.7);
  cursor: pointer;
}

.workbench_btn img {
  width: 9.36rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3.9rem;
}
.teaching_mode_header_right {
  width: 45.44rem;
  height: 33.44rem;
  overflow: hidden;
}
.teaching_mode_header_right_btn {
  margin-top: 6rem;
  width: 45rem;
  height: 20rem;
  opacity: 1;
  border-radius: 50rem;
  background: rgba(255, 96, 0, 1);
  font-size: 9rem;
  line-height: 20rem;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.teaching_mode_header_right_btn:hover {
  opacity: 0.8;
}

.teaching_mode .teaching_mode_content {
  width: 100%;
  height: 148.2rem;
  position: absolute;
  /* left: 19.5rem; */
  bottom: 23.63rem;
}
.teaching_mode .courseware_packge_list {
  width: 100%;
  height: 148.98rem;
  padding: 15.6rem 28.92rem;
  position: relative;
}
.teaching_mode .courseware_packge_list .swiper_wrapper_before {
  display: block;
  content: '';
  width: 30rem;
  /* padding-top: 78px; */
  backdrop-filter: blur(7.92rem);
  position: absolute;
  left: 0;
  top: 15.5rem;
  bottom: 13.5rem;
  z-index: 3;
  /* background: linear-gradient(270deg, #000 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.5;
    border-radius: 14px 0px 0px 14px; */
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.teaching_mode .courseware_packge_list .swiper_wrapper_after {
  display: block;
  content: '';
  width: 30rem;
  /* padding-top: 78px; */
  backdrop-filter: blur(7.92rem);
  background: linear-gradient(90deg, #000 0%, rgba(255, 255, 255, 0) 100%);
  /* border-radius: 14px 0px 0px 14px; */
  opacity: 0.5;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  position: absolute;
  right: 0;
  top: 15.5rem;
  bottom: 13.5rem;
  z-index: 3;
}
.teaching_mode_nodta {
  background: url('../../../public/image/teaching_no_courseware_packge.png')
    center no-repeat;
  margin: auto;
  line-height: 97.5rem;
  text-align: center;
  width: 100%;
  height: 100%;
}
.teaching_mode_nodta_text {
  font-size: 12.48rem;
  color: #ffffff;
  line-height: 214rem;
}
.body .layerdemo {
  border-radius: 20px;
}
body .layer-model .layer-wrap {
  background: inherit;
}
.teaching_mode_bg_ligth {
  background: #9ebceb;
  opacity: 0.3;
  filter: blur(75px);
  position: absolute;
  width: 537.42rem;
  height: 86.19rem;
  left: 105.69rem;
  top: 110rem;
  border-radius: 50%;
}

.teaching_mode_header_hover_img {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(208.99deg, #424b59 13.46%, #272f3a 86.66%);
  /* box-shadow: 0px 5px 20px rgba(41, 49, 61, 0.3); */
  border-radius: 32.57rem;
  width: 28.86rem;
  height: 28.86rem;
  overflow: hidden;
  text-align: center;
  line-height: 28rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 3.7rem;
  margin-left: 3.12rem;
  /* margin: auto; */
}
.teaching_mode_header_hover_img:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(208.99deg, #424b59 13.46%, #272f3a 86.66%);
  box-shadow: 0px 5px 20px rgba(22, 131, 249, 0.7);
}
.mendian_logo_content {
  width: 108.44rem;
  height: 40.1rem;
  border-radius: 7.8rem;
  /* overflow: hidden; */
  position: absolute;
  bottom: 180rem;
  left: 33.2rem;
  font-size: 9.36rem;
  color: white;
  display: flex;
  align-items: center;
  /* background: #383535eb; */
}
.teaching_mode_mendian_name {
  display: inline-block;
  margin-left: 6.5rem;
}
.teaching_mode_mendian_logo {
  width: 37.44rem;
  height: 37.44rem;
  border-radius: 7.8rem;
  overflow: hidden;
  margin-left: 1rem;
}
.teaching_mode_date {
  position: absolute;
  font-size: 11rem;
  right: 20rem;
  bottom: -14rem;
  width: 103rem;
  height: 20rem;
  line-height: 20rem;
  opacity: 1;
  border-radius: 50rem;
  background: rgba(247, 246, 250, 1);
  text-align: center;
}
</style>
