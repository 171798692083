<template>
  <!-- 课件门店工作台 -->
  <div class="chain_workbench_view all_view">
    <!-- <div style="position: absolute;z-index: 1;top: 75px;">
      <span @click="showDialog('cwsclassManager','dialogFactory')">班级管理</span>
      <span @click="showDialog('cwsteacherManager','dialogFactory')">老师管理</span>
      <span @click="showDialog('cwsstudentManager','dialogFactory')">学生管理</span>
      <span @click="showDialog('cwscoursewareManager','dialogFactory')">课件统计</span>
      <span @click="showDialog('cwsTeacherAuthList','o')">课包授权老师列表</span>
       <span @click="showDialog('cwsClassDetail','o')">班级详情</span>
    </div> -->
    <div>
      <left-box-work-bench ref="leftBox"></left-box-work-bench>

    </div>

    <div>
      <!-- <right-box-work-bench @afterSuccess="reflashData"></right-box-work-bench> -->
      <new-left-course-box></new-left-course-box>
    </div>

    <div>
      <dialog-factory ref="coursewareWorkBenchDialog"
                      :key="'coursewareWorkBench'"
                      :routerName="'coursewareWorkBench'"></dialog-factory>
      <dialog-factory-common ref="coursewareWorkBenchCommonDialog"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import leftBoxWorkBench from './left-box-work-bench/index';
import newLeftCourseBox from './new-left-course-box/index';
// import rightBoxWorkBench from './right-box-work-bench/index';
import dialogFactory from '../common/dialog-factory';
export default {
  name: 'coursewareWorkBench',
  components: {
    leftBoxWorkBench,
    newLeftCourseBox,
    // rightBoxWorkBench,
    dialogFactory
  },
  data () {
    return {};
  },

  created () {

  },
  mounted () {
    this.registerBusEvent();
  },
  methods: {
    showDialog (moduleName, type) {
      let obj = {
        name: '测试弹出层',
        routerName: this.$route.name,
        routertitle: '工作台',
        ModulePowerKey: '',
        moduleName: moduleName,
        data: {
          dataInfo: {
            BigCoursewarePackageKey: moduleName == 'cwsTeacherAuthList' ? '40519' : 0,
            BigCoursewarePackageKeyValue: moduleName == 'cwsTeacherAuthList' ? '总部大课件——和平精英' : ''
          },
          classKey: '121531',   // 班级详情适用， 班级Key
          isTeacher: false   // 班级详情适用， 是否老师， false则为校长
        }
      };
      if (type == 'dialogFactory') {
        this.openCustomDialogByFactoryDialog(obj);
      } else {
        this.openCommonCustomDialogByFactoryDialog(obj);
      }
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCustomDialog', dialogInfo);
      if (this.$refs.coursewareWorkBenchDialog) {
        this.$refs.coursewareWorkBenchDialog.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCommonCustomDialogByFactoryDialog', dialogInfo);
      if (this.$refs.coursewareWorkBenchCommonDialog) {
        this.$refs.coursewareWorkBenchCommonDialog.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'coursewareWorkBench') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'coursewareWorkBench') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    reflashData () {
      this.$refs.leftBox.reflashData();
    }
  }
};
</script>
