<template>
  <div style="height:627px;padding-bottom: 10px;">
    <div class="form_info_student_box">
      <span style="font-size:20px;"
            class="font_black">{{studentItem.StudentKeyValue}}</span>
      <span style="font-size:12px;"
            class="font_gray">{{studentItem.MobilePhone}}</span>
    </div>
    <div class="register_student form_info_edit bg_white"
         style="overflow-y:scroll"
         v-if="dataList.length>0">
      <record-item v-for="(item,key) in dataList"
                   :key="key"
                   :item="item"></record-item>
    </div>
    <div v-else
         class="monitoring_content_nodata">
      <div class="monitoring_content_nodata_txt">暂无数据</div>
    </div>
  </div>
</template>
<script>

import { GetFollowUpList, GetFollowUpDetail } from '../../../API/workbench';
import recordItem from './record-item';
// 登记客户
export default {
  data () {
    return {
      dataList: []
    };
  },
  props: {
    studentItem: Object,
    StudentThreadKey: [String, Number],
    isAllFollowRecord: {
      type: Boolean,
      default: true
    }
  },
  components: {
    recordItem
  },
  computed: {
    // 默认显示logo

  },
  created () {
    console.log(this.isAllFollowRecord, 'isAllFollowRecord StudentThreadKey');
    if (this.isAllFollowRecord) {
      this.getList();
    } else {
      this.getDataInfo();
    }
  },
  mounted () { },
  watch: {},
  methods: {
    getList () {
      GetFollowUpList(this.StudentThreadKey).then(result => {
        this.dataList = result.data;
        console.log('GetFollowUpList', result.data);
      });
    },
    getDataInfo () {
      GetFollowUpDetail(this.StudentThreadKey).then(result => {
        if (result.data) {
          this.dataList.push(result.data);
        } else {
          this.dataList = [];
        }

        console.log('GetFollowUpDetail', result.data, this.dataList);
      });
    },

    // 取消
    cancelClick (data) {
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.form_info_student_box {
  height: 60px;
  line-height: 50px;
  border-bottom: 1px solid #ececec;
  padding: 5px 20px;
}

.upload_btn {
  display: inline-block;
  line-height: 36px;
  width: 76px;
  height: 36px;
  border-radius: 4.6769232749938965px;
  text-align: center;
  padding-left: 20px;
  font-size: 14px;
  background: #f1f3f9;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.upload_btn::before {
  content: '';
  display: inline-block;
  position: absolute;
  right: 44px;
  top: 8px;
  width: 20px;
  height: 20px;
  background: url('../../../../public/image/Vector_upload.png') center no-repeat;
  background-size: 16px;
}
.upload_input {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.upload_img_content {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  height: 109px;
  margin-bottom: 15px;
}
.flex_row {
  display: flex;
  flex-direction: row;
}
.upload_img_content_imgitem {
  width: 85px;
  height: 109px;
  margin-right: 10px;
  text-align: center;
  position: relative;
}
.upload_img_content_imgitem img {
  width: 85px;
  height: 85px;
}
.delete_the_photo img {
  position: absolute;
  width: 30px;
  height: 30px;
  float: right;
  top: 0;
  right: 0;
}
.flex_row_item {
  width: 66px;
  height: 30px;
  border-radius: 4px;
  text-align: center;
  line-height: 30px;
  border: 1.25px solid #ececec;
  position: relative;
  cursor: pointer;
}
.flex_row_item.isActive {
  background: #3498db33;
}
.right_lll {
  margin: 0 2px;
}
.flex_row_item_date {
  position: absolute;
  min-width: 54px;
  height: 20px;
  line-height: 20px;
  border-radius: 4px;
  font-size: 12px;
  border: 1.25px solid #ececec;
  margin-top: 3px;
  cursor: pointer;
}
</style>