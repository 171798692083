<template>
  <div class="add_screening_condition_box fl">
    <span class="number_title">{{dataModel.formTitle}} : </span>
    <span>
      <input-number v-model="dataModel.fieldList[0].value"
                    class="number-ranger input-Number">
      </input-number>
      <span style="    height: 36px;
    line-height: 36px;
    vertical-align: top;
    display: inline-block;">%</span>
    </span>
    <span>-</span>
    <span>
      <input-number v-model="dataModel.fieldList[1].value"
                    class="number-ranger input-Number">
      </input-number><span style="    height: 36px;
    line-height: 36px;
    vertical-align: top;
    display: inline-block;">%</span>
    </span>
  </div>
</template>
<script>
import inputNumber from '../../form-item/input-number';
export default {
  model: {
    prop: 'dataModel'
  },
  props: {
    dataModel: Object
  },
  components: {
    inputNumber
  },
  created () {
  },
  methods: {

  }
};
</script>
<style>
</style>

