<template>
  <div class="index_view_right">
    <menu-list @addClewAfter="addClewAfter"
      @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"
      @openCommonCustomDialogByFactoryDialog="openCommonCustomDialogByFactoryDialog"
    ></menu-list>
    <crm-cs-manager v-if="isHasModulePower(135)" ref="csManager"  @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"></crm-cs-manager>
    <my-clew v-if="isHasModulePower(136)" ref="myClew"  @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"></my-clew>
    <div class="new_other_statistics">
      <new-other-statistics :menDianSetInfo="menDianSetInfo" ref="otherStatistics"  @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"></new-other-statistics>
    </div>
    <!-- <other-statistics :menDianSetInfo="menDianSetInfo" ref="otherStatistics"  @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"></other-statistics> -->
    <div class="chain_workbench_bottom" style="width: 914px">
      <div class="bottom_title">数据统计与分析</div>
      <div class="echart_box">
        <doubleColumnarEchart
          v-if="dataList.length > 0"
          :echartID="'mendianCharts'"
          :reqList="reqList"
          :dataList="RetailStoresList"
          :dataList2="FranchiseStoresList"
          :totalDataList="totalList"
          :rotate="'-45'"
          :echartClass="'echarts_l'"
          :maxType="''"
          :rateType="''"
          :barOneTitle="'直营店'"
          :barTwoTitle="'加盟店'"
          ref="echart"
        ></doubleColumnarEchart>
        <div v-if="dataList.length == 0" class="course_block_nodata">
          <div class="monitoring_content_nodata_txt">
            亲，当前没有任何数据哦~
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetGrowthTrendForLately12Month } from "../../API/workbench";
import menuList from "./menu-list";
import crmCsManager from "./crm-cs-manager";
import myClew from "./my-clew";
import otherStatistics from "./other-statistics";
import newOtherStatistics from "./new-other-statistics";
import doubleColumnarEchart from "../base-module/echart-common/double-columnar-echart";
export default {
  data() {
    return {
      reqList: [],
      ehartDataList: [],
      dataList: [], // 未污染额数据
      FranchiseStoresList: [], // 加盟店数
      RetailStoresList: [], // 直营店数
      totalList: [], // 加盟店数+直营店数 的总数
    };
  },
  props: {
    menDianSetInfo: Array,
  },
  components: {
    menuList,
    crmCsManager,
    myClew,
    otherStatistics,
    doubleColumnarEchart,
    newOtherStatistics
  },
  computed:{
      vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    }
  },
  created(){
    //   this.getEchartData()
  },
  methods: {
    addClewAfter(){
      console.log("addClewAfter")
      this.loadData()
    },
    loadData(){
         this.getEchartData()
         this.$refs.otherStatistics.getDataInfo()
         if( this.$refs.csManager){
             this.$refs.csManager.getDataInfo()
         }
         if( this.$refs.myClew){
             this.$refs.myClew.getDataInfo()
         }
    },
    isHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(
        this.vRolePowerList,
        ModulePowerKey
      );
    },
    openCustomDialogByFactoryDialog(item) {
      this.$emit("openCustomDialogByFactoryDialog", item);
    },
    openCommonCustomDialogByFactoryDialog(item){
        this.$emit("openCommonCustomDialogByFactoryDialog", item);
    },
    getEchartData() {
      GetGrowthTrendForLately12Month().then((result) => {
        console.log("getEchartData", result.data);
        this.ehartDataList = result.data;
        this.dataList = this.$utils.parseJson(result.data);
        this.initEchartDataList();
        this.$nextTick(() => {
          if (this.dataList.length > 0) {
            this.$refs.echart.echartInit();
          }
        });
      });
    },
    initEchartDataList() {
      this.reqList = [];
      this.RetailStoresList = [];
      this.FranchiseStoresList = [];
      this.totalList = [];
      var length = 12 - this.ehartDataList.length;
      if (length > 0) {
        for (var i = 0; i < length - 1; i++) {
          let obj = {
            GenerateTime: "",
            RetailStoresNum: "",
            FranchiseFeesNum: "",
          };
          this.ehartDataList.push(obj);
        }
      }
      this.ehartDataList.forEach((o) => {
        if (o.GenerateTime) {
          this.reqList.push(Number(o.GenerateTime.substring(5)) + "月");
        } else {
          this.reqList.push(o.GenerateTime);
        }
        this.RetailStoresList.push(o.RetailStoresNum);
        this.FranchiseStoresList.push(o.FranchiseFeesNum);
        this.totalList.push(
          Number(o.FranchiseFeesNum) + Number(o.RetailStoresNum)
        );
      });
    },
  },
};
</script>
<style >
.new_other_statistics .new_other_statistics_content{
  display: flex;
    flex-direction: column;
}
.new_other_statistics .new_other_statistics_content .index_view_menu_three{
 width: 447px !important;
 height: 172px !important;
}
.new_other_statistics_second_content{
  width: 447px;
  height: 364px;
  background: white;
}
/* .new_other_statistics .new_other_statistics_content .menu_content .menu_content_list .menu_content_list_num{
  text-align: left;
  padding-left: 35px;
}
.new_other_statistics .new_other_statistics_content .menu_content .menu_content_list .menu_content_list_title{
  text-align: left;
  padding-left: 35px;
} */
</style>