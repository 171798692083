<template>
  <div>
    <custom-dialog :title="formTitle"
                   :visible.sync="isAttendanceArrangeShow"
                   :before-close="cancelAttendanceArrangeClick">
      <div>
        <div class="attendance_arrange ">
          <!-- <form action
                method="post"
                id="goclass"> -->
          <!--班级信息-->
          <ul class="class-list">
            <div class="course_popup_label attendance_label">
              <div class="label_title">
                <div class="label_name">{{courseInfo.CourseNameKeyValue}}</div>
                <div v-if="courseInfo.Attence2 > 0"
                     class="label_attendance">未考勤{{courseInfo.Attence2 || 0}}人</div>
              </div>
              <div class="label_info clearfix">
                <div class="label_info_list">
                  <span class="font_gray">时间</span><span>: {{courseInfo.week}}&nbsp;&nbsp;{{courseInfo.BeginClassTime}}&nbsp;&nbsp;{{formatDate(courseInfo.ClassTime)}}</span>
                </div>
                <div class="label_info_list">
                  <span class="font_gray">班级</span><span>: {{courseInfo.GradeClassKeyValue}}</span>
                </div>
                <div class="label_info_list">
                  <span class="font_gray">老师</span><span>: {{courseInfo.MTeacherKeyValue}}</span>
                </div>
                <div class="label_info_list">
                  <span class="font_gray">教室</span><span>: {{courseInfo.classRoomName}}</span>
                </div>
              </div>
            </div>
            <div class="form_info_bottom">
              <!-- v-if="!isSuspend" -->
              <div class="form_attendance_title">
                <div class="fl">
                  学员签到表
                </div>
                <div class="fr">签到:
                  <span class="font_black">{{Number(courseInfo.ArrivedNumCount)}}人</span>丨未到:
                  <span class="font_black"><span class="font_red">{{Number(courseInfo.ArrangedNumCount - courseInfo.ArrivedNumCount)}}</span>人</span>
                  <!-- <span>
                      丨扣课时:<span class="font_black">{{courseInfo.PeriodCount}}课时</span>
                    </span> -->
                </div>
              </div>
              <li :class="{'student-sign-in':!isSuspend}">
                <student-info v-show="courseInfo.ScheduleCourseKey"
                              :selectedCourseInfo="courseInfo"
                              :isAlter="isAlter"
                              :isSuspend="isSuspend"
                              :studentArrangeList="studentArrangeList"
                              :isTeacher="isTeacher"
                              @refresh="updateCourseInfo"
                              @closeCourse="cancelAttendanceArrange"
                              @updateStudentFileEvent="updateStudentFileEvent"></student-info>
              </li>
              <!-- v-if="!isSuspend" -->
              <li class="students-check">
                <div class="gray_bg"></div>
                <div class="student_check_type">
                  <div class="cut-class">
                    <span class="students_check_title">
                      签到
                      <span class="font_black">{{Appendzero(vSignInStudents.length)}}</span> 人
                    </span>
                    <div class="check_list">
                      <span class
                            v-for="(it,index) in vSignInStudents"
                            :key="index">
                        {{it.StudentKeyValue}}
                        <span v-if="index+1 != vSignInStudents.length">,</span>
                      </span>
                    </div>
                  </div>
                  <div class="sign_in">
                    <span class="students_check_title">
                      请假
                      <span class="font_black">{{Appendzero(vLeaveStudents.length)}}</span> 人
                    </span>
                    <div class="check_list">
                      <span class
                            v-for="(it,index) in vLeaveStudents"
                            :key="index">
                        {{it.StudentKeyValue}}
                        <span v-if="index+1 != vLeaveStudents.length">,</span>
                      </span>
                    </div>
                  </div>
                  <div class="leave-early">
                    <span class="students_check_title">
                      旷课
                      <span class="font_black">{{Appendzero(vAbsenteeismStudents.length)}}</span> 人
                    </span>
                    <div class="check_list">
                      <span class
                            v-for="(it,index) in vAbsenteeismStudents"
                            :key="index">
                        {{it.StudentKeyValue}}
                        <span v-if="index+1 != vAbsenteeismStudents.length">,</span>
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </ul>
          <!-- </form> -->
        </div>
        <div @click="cancelAttendanceArrangeClick"
             style="background-color:#fff;border-top:1px solid #ececec"
             class="confirm_btn btn_fixed_bottom">
          <div class="btn_fixed_blue btn_hover_bg_blue"
               style="width:100%">完成并返回</div>
        </div>
      </div>
    </custom-dialog>
  </div>
</template>

<script>
import studentInfo from './student-info';
import {
  getCourseDetailed,
  getScheduleCourseOnStudentList
} from '../../../../API/workbench';
export default {
  data () {
    return {
      isAttendanceArrangeShow: false, // 安排考勤
      isShow: true,
      // formTitle: '',
      isAlter: true,
      isSuspend: false,
      isBatchAttendance: false, // 是否批量考勤
      courseInfo: {},
      studentArrangeList: [],
      signInStudentList: [], // 签到人员
      leaveStudentList: [], // 请假人员
      absenteeismStudentList: [] // 旷课人员
    };
  },
  components: {
    studentInfo
  },
  // 提供外部输出显示，不给就输出内部默认显示
  props: {
    courseKey: {
      type: [String, Number],
      default: 0
    },
    formTitle: {
      // 标题，
      type: String,
      default: '默认'
    }
  },
  created () {
    var token = this.$store.getters.token;
    console.log(token, 'token');
    this.AgentKey = token.UserID;
    this.AgentKeyValue = token.UserName;
    this.SaaSClientKey = token.SaaSClientKey;

    if (this.formTitle == '查看课程') {
      this.isAlter = false;
    }
    // if (this.courseKey > 0) {
    //   this.getCourseInfo(this.courseKey);
    // }
    this.resgisterBusEvent();
  },
  watch: {
    // courseKey (n, c) {
    //   if (this.isAttendanceArrangeShow && this.courseKey > 0) {
    //     this.getCourseInfo(this.courseKey);
    //   }
    // },
    isAttendanceArrangeShow (n, o) {
      if (n && this.courseKey > 0) {
        this.getCourseInfo(this.courseKey);
      }
    }
  },
  computed: {
    isTeacher () {
      if (this.courseInfo && this.courseInfo.ScheduleCourseKey) {
        if (this.courseInfo.MTeacherKey.indexOf(this.$store.getters.token.UserID) != -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    vSignInStudents () {
      let arr = [];
      this.studentArrangeList.forEach(e => {
        if (e.AttenceStatusKey == 3) {
          arr.push(e);
        }
      });
      if (arr.length > 0) {
        if (arr.length > this.signInStudentList.length) {
          arr.forEach(e => {
            if (this.signInStudentList.length > 0) {
              let falg = true;
              this.signInStudentList.map(o => {
                if (e.StudentKey == o.StudentKey) {
                  falg = false;
                }
              });
              if (falg) {
                this.signInStudentList.push(e);
              }
            } else {
              this.signInStudentList.push(e);
            }
          });
        } else {
          this.signInStudentList = arr;
        }
      } else {
        this.signInStudentList = arr;
      }
      return this.signInStudentList;
    },
    vLeaveStudents () {
      let arr = [];
      this.studentArrangeList.forEach(e => {
        if (e.AttenceStatusKey == 4) {
          arr.push(e);
        }
      });
      if (arr.length > 0) {
        if (arr.length > this.leaveStudentList.length) {
          arr.forEach(e => {
            if (this.leaveStudentList.length > 0) {
              let falg = true;
              this.leaveStudentList.map(o => {
                if (e.StudentKey == o.StudentKey) {
                  falg = false;
                }
              });
              if (falg) {
                this.leaveStudentList.push(e);
              }
            } else {
              this.leaveStudentList.push(e);
            }
          });
        } else {
          this.leaveStudentList = arr;
        }
      } else {
        this.leaveStudentList = arr;
      }
      return this.leaveStudentList;
    },
    vAbsenteeismStudents () {
      let arr = [];
      this.studentArrangeList.forEach(e => {
        if (e.AttenceStatusKey == 5) {
          arr.push(e);
        }
      });
      if (arr.length > 0) {
        if (arr.length > this.absenteeismStudentList.length) {
          arr.forEach(e => {
            if (this.absenteeismStudentList.length > 0) {
              let falg = true;
              this.absenteeismStudentList.map(o => {
                if (e.StudentKey == o.StudentKey) {
                  falg = false;
                }
              });
              if (falg) {
                this.absenteeismStudentList.push(e);
              }
            } else {
              this.absenteeismStudentList.push(e);
            }
          });
        } else {
          this.absenteeismStudentList = arr;
        }
      } else {
        this.absenteeismStudentList = arr;
      }
      return this.absenteeismStudentList;
    }
  },
  mounted () {
    // 查看或者已上完的课
    if (this.optionType == 1) {
      this.flag = false;
    }
    if (this.courseInfo.StatusKey == 4) {
      this.addStudnetBtn = false;
      this.addArrangeStuBtnHidden = true;
    }
    this.$dialog.register(this, ['studentFile']);
  },
  methods: {
    // 更新外部学生信息
    updateStudentFileEvent (StudentKey, ScheduleCourseKey) {
      this.$emit('updateStudentFileEvent', StudentKey, ScheduleCourseKey);
    },
    // 外部调用这组件时触发.
    showGetData (OLAPkey) {
      this.getCourseInfo(OLAPkey);
      this.$nextTick(() => {
        this.isAttendanceArrangeShow = true;
      });
    },
    cancelAttendanceArrangeClick () {
      this.studentArrangeList = [];
      this.courseInfo = {};
      this.isAttendanceArrangeShow = false;
    },
    cancelAttendanceArrange () {
      this.studentArrangeList = [];
      this.courseInfo = {};
      this.isAttendanceArrangeShow = false;
      if (this.$parent.chgCourseUpdate) {
        this.$parent.chgCourseUpdate();
      }
      this.$emit('parentUpdate');
    },
    formatDate (date) {
      let arr = String(date).split('-');
      return arr[1] + '/' + arr[2];
    },
    updateCourseInfo (courseKey, selectedCourseInfo) {
      this.$emit('updateCourse', courseKey, selectedCourseInfo);
      this.getCourseInfo(courseKey);
    },
    async getCourseInfo (courseKey, callBack) {
      getCourseDetailed(courseKey).then(
        res => {
          if (res.code == 0) {
            if (Number(res.data.ArrangedNumCount) <= Number(res.data.FullPeopleCount)) {
              if (Number(res.data.AttenceCount) == 0 && Number(res.data.FullPeopleCount) == 0) {
                res.data.AttencePercentage = 0;
              } else {
                res.data.AttencePercentage = Math.ceil((Number(res.data.AttenceCount) / Number(res.data.FullPeopleCount)) * 100);
              }
              if (Number(res.data.ArrangedNumCount) == 0 && Number(res.data.FullPeopleCount) == 0) {
                res.data.ArrangedPercentage = 0;
              } else {
                res.data.ArrangedPercentage = Math.ceil((Number(res.data.ArrangedNumCount) / Number(res.data.FullPeopleCount)) * 100);
              }
            } else {
              if (Number(res.data.AttenceCount) == 0 && Number(res.data.ArrangedPercentage) == 0) {
                res.data.AttencePercentage = 0;
              } else {
                res.data.AttencePercentage = Math.ceil((Number(res.data.AttenceCount) / Number(res.data.ArrangedPercentage)) * 100);
              }
              res.data.ArrangedPercentage = 100;
            }
            if (res.data.ClassTime) {
              res.data.week = this.$utils.getWeekByTwo(res.data.ClassTime);
            }
            this.courseInfo = res.data;
            if (typeof callBack == 'function') {
              callBack(this.courseInfo);
            }
            this.ClassStudentsArrangeQuery();
          }
          console.log(this.courseInfo, '是否停课');
          if (this.courseInfo.IsClassStopKey == 1) {
            this.isSuspend = true;
          } else {
            this.isSuspend = false;
          }
        },
        err => {
          console.log(err);
        }
      );
    },

    // 超过10就归零
    Appendzero (obj) {
      return obj;
    },
    ClassStudentsArrangeQuery () {
      // 相关课学生
      console.log('ClassStudentsArrangeQuery', this.courseInfo);
      getScheduleCourseOnStudentList(this.courseInfo.ScheduleCourseKey).then(
        res => {
          this.studentArrangeList = [];
          if (res.code == 0) {
            res.data.forEach(e => {
              this.studentArrangeList.push(e);
            });
          }
        },
        err => {
          console.log(err);
        }
      );
    },
    resgisterBusEvent () {
      this.$bus.on(this, 'AfterBatchAttendance', scheduleCourseKey => {
        if (this.courseInfo.ScheduleCourseKey == scheduleCourseKey) {
          this.getCourseInfo(scheduleCourseKey);
        }
      });
      this.$bus.on(this, 'AfterStudentAddToCourse', (scheduleCourseKey, studentKey) => {
        if (this.courseInfo.ScheduleCourseKey == scheduleCourseKey) {
          this.getCourseInfo(scheduleCourseKey);
        }
      });
      this.$bus.on(this, 'AfterChangeAttendanceStatus', (scheduleCourseKey, studentKey) => {
        if (this.courseInfo.ScheduleCourseKey == scheduleCourseKey) {
          this.getCourseInfo(scheduleCourseKey);
        }
      });
    }

  }
};
</script>

<style scoped>
/* 快速签到 */
.quick_signIn {
  float: right;
}
</style>

