<template>
  <!-- 前后置说明文本  -->
  <div class="form_list_children_box flex">
    <div class="children_name"
         v-if="item.FieldDescription">
      <span class="text_box">{{item.FieldDescription}}</span>
    </div>
    <div class="">
      <show-input :inputStyle="'width: 178px;'"
                  :inputClass="'children_input'"
                  :item="item"></show-input>
    </div>
    <div class="children_name"
         v-if="item.FieldsThat">
      <span class="text_box">{{item.FieldsThat}}</span>
    </div>
  </div>
</template> 
<script>
import showInput from './show-input';
export default {
  components: {
    showInput
  },
  data () {
    return {

    };
  },
  props: {
    categoryItem: Object, // 类目项
    item: Object // 字段项
  },
  created () {
    // console.log('单行文本:', this.categoryItem, this.item);
  },
  computed: {

  },
  methods: {

  }
};
</script>

<style>
</style>