<!-- 排课备注 -->
<template>
 <el-table-column :fixed="columnData.fixed||false"
                              :label="columnData.label"
                              :prop="columnData.prop"
                              :min-width="columnData.minWidth"
                              :width="columnData.width"
                              :sortable="columnData.sortable"
                              :align="columnData.align||'left'"
                              :label-class-name="columnData.columnClass"
                              :key="columnData.prop">
  <template slot-scope="scope">
    <span class="edit_icon" :class="columnData.className" v-if="!scope.row.isEmpertyRow"
          @click="clickEvent(scope.row)">{{scope.row[columnData.prop]}}</span>
  </template>
</el-table-column>
</template>

<script>
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          type: String,
          extend: {
            studentKey: 'StudentKey', // 由本组件完成修改排课备注 所以需要辅助信息 学生ID和学生名
            studentKeyValue: 'StudentKeyValue',
            click: () => {} // 表示该列可点击
          }
        };
      }
    }
  },
  created () {
  },
  methods: {
    clickEvent (rowData) {
      if (typeof this.columnData.extend.click == 'function') {
        let item = {
          routerName: this.$route.name,
          moduleName: 'arrangeSupportEdit',
          name: '学生：' + rowData[this.columnData.extend.studentKeyValue],
          data: { studentKey: rowData[this.columnData.extend.studentKey], content: rowData[this.columnData.prop] },
          callBack: {updateSuccess: (val) => {
            rowData[this.columnData.prop] = val;
          }}
        };
        this.$dialog.open(this, item);
      }
    }
  }
};
</script>

<style>

</style>