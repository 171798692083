const workBench = {
  state: {
    showAlerDialog: false,
    showAlerDialogForStu: false,
    top10RequestionApi: [] // 前10个请求的api
  },
  getters: {
    showAlerDialog: state => {
      return state.showAlerDialog;
    },
    showAlerDialogForStu: state => {
      return state.showAlerDialogForStu;
    },
    top10RequestionApi: state => {
      return state.top10RequestionApi;
    }
  },
  mutations: {
    showAlerDialog: (state, obj) => {
      state.showAlerDialog = obj;
    },
    showAlerDialogForStu: (state, obj) => {
      state.showAlerDialogForStu = obj;
    },
    setTop10RequestionApi: (state, apiParams) => {
      if (state.top10RequestionApi.length >= 10) {
        state.top10RequestionApi.pop();
      }
      state.top10RequestionApi.unshift({apiParams: apiParams, happenTime: Date.new()});
    }

  }
};

export default workBench;
