<template>
  <div class="form_item form-input  form_info_line"
       :class="{ form_info_required: required }">
    <div class="form_info_field ">
      {{formTitle}}
    </div>
    <div class="form_info_value pr button"
         :class="readonly?'form_info_value_readonly':''">
      <div style="position: absolute;top: 1px;right: 48px;"
           v-if="!value"
           :class="!readonly?'font_blue':'font_gray'">{{uploadTitle}}</div>
      <img src="../../../public/image/upload_default_pic.png"
           v-if="!value"
           :onerror="defaultImg"
           alt="../../../public/image/upload_default_pic.png"
           class=""
           style="width: 20px !important;height: 20px;z-index: 10;margin-right: 20px;">
      <input type="file"
             name="file"
             class="info_file-upload apiclient pa"
             :disabled="readonly"
             @change="uploadingFile($event,'file')"
             data-type="userIconImg"
             style="opacity: 0;right:0"
             :accept="acceptType">
      <input type="text"
             v-if="value"
             :value="value"
             readonly
             placeholder="请上传">
    </div>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
import { UploadFiles } from '../../API/workbench';
export default {
  name: 'inputSelect',
  data () {
    return {
      selectPicUrl: '',          // 选中图片url
      isChgPic: false,
      imgURlString: this.ImgURl || '',
      imgstyleSize: this.imgstyle  // 裁切大小
    };
  },
  props: {
    value: [String, Number, Boolean],
    required: Boolean,
    formTitle: String,
    readonly: {// 是否只读，
      type: Boolean,
      default: false
    },
    uploadTitle: {
      type: String,
      default: '请上传'
    },
    acceptType: {
      // 是否必填，
      type: String,
      default: ''
    },
    helpDescription: String, // 表单说明提示

  },
  computed: {
    headImg () {
      if (!this.imgURlString) {
        this.imgURlString = '';
      }
      if (this.imgURlString.indexOf('http') > -1) {
        return this.imgURlString;
      } else {
        return this.$store.getters.CDNURL + this.imgURlString;
      }
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../public/image/no_logo@2x.png') + '"';
    }
  },
  created () {
  },
  watch: {
    ImgURl (n, c) {
      this.imgURlString = n;
    }
  },
  methods: {
    uploadingFile (e, type) {
      // 文件对象
      let typeNum = type == 'file' ? 6 : (type || 1);
      var form = e.target.files[0];
      var formData = new FormData();
      // 文件对象
      formData.append('file', form);
      // 防止文件取消时
      if (form) {
        UploadFiles(typeNum, '', formData).then(result => {
          this.$emit("UploadFilesAfter", result.data.url, form.name, form, this.acceptType)
          // if (type == 'file') {
          //   this.dataInfo.PayMent.ContractName = form.name;
          //   this.dataInfo.PayMent.AccessorySupport = result.data.url;
          // } else {
          //   this.dataInfo.PayMent.PayCutSupport = result.data.url;
          // }
          console.log(result.data.url, '撒的发生的');
        }, err => {
          layer.alert(err.msg);
        });
      }
    }
  }
};
</script>

 <style>
.form_info_value_readonly input {
  color: #999 !important;
}
.my_datum_picture_img_text {
  /* width: 70px;
  height: 70px; */
  /* border-radius: 4px; */
}
/* .my_datum_picture_text {
  width: 100%;
  background-color: #fff;
 padding: 4px 76px;
  border-bottom: 1px solid #f2f2f2;
  overflow: hidden;
} */
</style>