<template>
  <div class="workbench_view_news massive_bg">
    <div class="workbench_view_title button"
         @click="toList">
      <span>最新资讯</span>
      <div class="workbench_view_title_next"></div>
    </div>
    <div class="workbench_view_news_content"
         style="height:299px">
      <div class="news_list"
           v-for="(item,key) in dataInfo.PageDataList"
           :key="key"
           @click="openLink(item)">
        <div class="">
          {{item.sname}} {{item.title}}
        </div>
        <div class="font_gray">
          {{vdate(item)}}
        </div>
        <div class="news_list_btn">
        </div>
      </div>
      <div v-if="dataInfo.PageDataList.length==0"
           style="margin-top:55px"
           class="course_block_nodata">
        <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
      </div>
    </div>
  </div>
</template>
<script>
import { SearchLatestNewsForData } from '../../API/workbench.js';
export default {
  data () {
    return {
      dataInfo: {
        PageDataList: []
      },
      searchData: {
        pageIndex: 0,
        pageSize: 20,
        orderBy: '',
        sequence: '',
        searchText: ''
      }
    };
  },
  created () {
    this.getLastNewData();
  },
  methods: {
    getLastNewData () {
      SearchLatestNewsForData(this.searchData).then(result => {
        this.dataInfo = result.data;
        console.log('getLastNewData,', result.data);
      });
    },
    vdate (item) {
      if (item) {
        return item.date.substring(0, 10);
      }
    },
    // 跳转到详情页.
    openLink (item) {
      console.log(item, '新窗口');
      let Link = '';
      if (window.location.host.indexOf('zx.zhixuerj.com') !== -1) { // 产品环境
        Link = 'http://www.sztuoke.com/index.php/content/' + item.id + '.html ';
        window.open(Link);
      } else { // 非产品环境
        Link = 'http://webtest.wktdevtest.zhixuerj.com/index.php/content/' + item.id + '.html';
        window.open(Link);
      }
    },
    // 跳转到列表
    toList () {
      let Link = '';
      if (window.location.host.indexOf('zx.zhixuerj.com') !== -1) { // 产品环境
        Link = 'http://www.sztuoke.com/xinwenzixun';
        window.open(Link);
      } else { // 非产品环境
        Link = 'http://webtest.wktdevtest.zhixuerj.com/xinwenzixun';
        window.open(Link);
      }
    }
  }
};
</script>

