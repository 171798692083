<template>
  <div>
    <!-- 门店历史记录列表 -->
    <div>
      <list ref="studentApplyCourseTable"
            :tableData="mendianList"
            :totalNum="totalNum"
            :pageTotal="pageTotal"
            :PageCount="PageCount"
            :tableColumns="tableColumns"
            :isSearchText="false"
            :listType="listType"
            :statusList="statusList"
            :rowKey="'Number'"
            :defaultSort="defaultSort"
            :queryParams="queryParams"
            @searchValue="searchValue"
            @tableBtnClick="doAfterTableBtnClick"
            @loadTableData="getMessageSearchSaaSClientOperationLog"
            @clearSearchParams="clearSearchCondition"></list>
    </div>
  </div>
</template>
<script>
import {
  MessageSearchSaaSClientOperationLog
} from '../../../API/workbench.js';

import list from './table';
import dropDownBox from '../../common/drop-down-box';
export default {
  components: {
    dropDownBox,
    list
  },
  props: {
    listType: String
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 15, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: 'desc', // 排序字段
        OperationTypeKey: '',
        OperationTypeKeyValue: ''
      },
      statusList: [
        { name: '全部', key: '' },
        { name: '清理数据', key: 2 },
        { name: '关停门店', key: 5 },
        { name: '临时续期', key: 6 },
        { name: '正式续期', key: 7 },
        { name: '导出学生课单', key: 1 },
        { name: '临时开通门店', key: 3 },
        { name: '正式开通门店', key: 4 },
        { name: '永久关停门店', key: 8 }

      ],
      pageTotal: 0, // 分页器总数
      mendianList: [], // 门店列表
      mendianData: {}, // 门店信息
      isShowRenewalFormPopup: false, // 变更正式续费
      isShowClearDataFormPopup: false, // 清理数据
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 60,
          sortable: false,
          align: 'left',
          type: 'text-item',
          className: 'font_gray',
          decimalPlace: 0
        },
        {
          label: '执行时间',
          prop: 'OperationTime',
          width: 200,
          align: 'left',
          sortable: 'custom',
          type: 'date-item',
          extend: {
            dateType: 'YYMMRR HH:DD' // 日期类型
          }
        },
        {
          label: '门店Key',
          prop: 'TargetSaaSClientKey',
          width: 200,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '门店ID',
          prop: 'SaaSClientNumber',
          width: 200,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '门店名称',
          prop: 'TargetSaaSClientKeyValue',
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '操作类型',
          prop: 'OperationTypeKeyValue',
          width: 200,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '操作内容',
          prop: 'OperationSupport',
          width: 200,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '操作人',
          prop: 'OperatorKeyValue',
          width: 200,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        }
      ],
      PageCount: 0,
      totalNum: 0,
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  created () {
  },
  mounted () {
    this.getMessageSearchSaaSClientOperationLog();
  },
  methods: {
    // 门店记录列表
    getMessageSearchSaaSClientOperationLog (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.queryParams.searchText = this.queryParams.searchText.trim();
      MessageSearchSaaSClientOperationLog(this.queryParams).then(result => {
        console.log('门店记录列表', result.data);
        this.$nextTick(() => {
          if (result.data.PageDataList.length == 0) {
            this.$refs.studentApplyCourseTable.isLoading = true;
          }
        });
        this.pageTotal = result.data.Total;
        if (this.queryParams.status == 0 && this.queryParams.searchText == '') {
          this.totalNum = result.data.Total;
        }
        // 分页数
        if (result.data && result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach(o => {
            o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
          });
          this.mendianList = result.data.PageDataList;
        } else {
          this.mendianList = [];
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 搜索
    searchValue (searchText) {
      this.queryParams.searchText = searchText;
      this.queryParams.pageIndex = 0;
      console.log('searchValue', searchText);
      this.getMessageSearchSaaSClientOperationLog();
    },
    // 按钮点击
    doAfterTableBtnClick (rowData) {
      console.log(rowData, '按钮点击');
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.queryParams.searchText = '';
      this.queryParams.pageIndex = 0;
      this.queryParams.status = 0;
      this.$refs.studentApplyCourseTable.queryParams.pageIndex = 0;
      this.getMessageSearchSaaSClientOperationLog();
    }
  },
  computed: {}
};
</script>
