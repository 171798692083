<template>
  <div>
    <!-- 门店列表 -->
    <div>
      <list ref="studentApplyCourseTable"
            :tableData="mendianList"
            :totalNum="totalNum"
            :pageTotal="pageTotal"
            :PageCount="PageCount"
            :tableColumns="tableColumns"
            :isSearchText="false"
            :rowKey="'Number'"
            :defaultSort="defaultSort"
            :queryParams="queryParams"
            :listType="'applyForList'"
            :statusList="[]"
            @searchValue="searchValue"
            @tableBtnClick="doAfterTableBtnClick"
            @loadTableData="getMessageSearchApplySaaSClient"
            @clearSearchParams="clearSearchCondition"></list>
    </div>
  </div>
</template>
<script>
import {
  MessageSearchApplySaaSClient,
  DredgeForeverSaaSClient,
  DredgeTempSaaSClient
} from '../../../../API/workbench.js';

import list from '../table';
// import dropDownBox from '../../../common/drop-down-box';
export default {
  components: {
    list
  },
  props: {
    applyForType: String
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 15, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: 'desc' // 排序字段
      },
      mendianList: [], // 门店列表
      mendianData: {}, // 门店信息
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 60,
          sortable: false,
          align: 'left',
          type: 'text-item',
          className: 'font_gray',
          decimalPlace: 0
        },
        {
          label: '门店名称',
          prop: 'MainDemoName',
          width: 180,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '状态',
          prop: 'StatusKeyValue',
          width: 80,
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '申请时间',
          prop: 'StartTime',
          width: 180,
          align: 'left',
          sortable: 'custom',
          type: 'date-item',
          extend: {
            dateType: 'YYMMRR HH:DD' // 日期类型
          }
        },
        {
          label: '联系人',
          prop: 'ContactName',
          width: 120,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '联系人手机',
          prop: 'ContactPhoneName',
          width: 120,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '申请类型',
          prop: 'applyTypeNameKeyValue',
          width: 90,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '来源',
          prop: 'FromSourceKeyValue',
          width: 90,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '跟单顾问',
          prop: 'AdvisorKeyValue',
          width: 90,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '公司全称',
          prop: 'ClientFullName',
          width: 150,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '电话号码',
          prop: 'TelePhoneName',
          width: 120,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '地址',
          prop: 'AddressName',
          width: 120,
          align: 'left',
          type: 'text-item',
          sortable: 'custom'
        },
        {
          label: '备注',
          prop: 'RemarkSupport',
          sortable: 'custom',
          type: 'text-item'
        },
        {
          label: '操作',
          prop: 'option',
          width: 100,
          type: 'table-btn',
          extend: {
            tableBtnGroup: [
              {
                name: this.applyForType + '开通',
                extend: {
                  IsInquiryHint: 'inquiryBtn',
                  setClassName: (rowData) => {
                    if (rowData.inquiryBtn) { // 开启询问提示，显示询问弹窗时，界面按钮置灰，不可操作
                      return rowData.inquiryBtn;
                    }
                  },
                  click: (rowData, callBack) => {
                    if (callBack) {
                      callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: 'table_btn_gray' });
                    }
                    this.operateBtnEvent(rowData, callBack);
                  },
                  ModulePowerKey: ''
                }
              }

            ]
          }
        }
      ],
      pageTotal: 0, // 分页器总数
      PageCount: 0,
      totalNum: 0,
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  created () {
  },
  mounted () {
    this.getMessageSearchApplySaaSClient();
  },
  computed: {

  },
  methods: {
    // 门店申请列表
    getMessageSearchApplySaaSClient (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.queryParams.searchText = this.queryParams.searchText.trim();
      MessageSearchApplySaaSClient(this.queryParams).then(result => {
        console.log('门店申请列表', result.data);
        this.$nextTick(() => {
          if (result.data.PageDataList.length == 0) {
            this.$refs.studentApplyCourseTable.isLoading = true;
          }
        });
        this.pageTotal = result.data.Total;
        if (this.queryParams.status == 0 && this.queryParams.searchText == '') {
          this.totalNum = result.data.Total;
        }
        // 分页数
        if (result.data && result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach(o => {
            o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
          });
          this.mendianList = result.data.PageDataList;
        } else {
          this.mendianList = [];
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 搜索
    searchValue (searchText) {
      this.queryParams.searchText = searchText;
      this.queryParams.pageIndex = 0;
      console.log('searchValue', searchText);
      this.getMessageSearchApplySaaSClient();
    },
    // 按钮点击
    doAfterTableBtnClick (rowData) {
      console.log(rowData, '按钮点击');
    },
    // 操作按钮
    operateBtnEvent (item, callBack) {
      console.log(item, '操作按钮');
      switch (this.applyForType) {
        case '正式':// 正式开通
          layer.confirm('正式开通门店，有效期为1年' + ',是否继续?', {
            btn: [
              {
                name: '取消',
                callBack: function () {
                  layer.close();
                  if (callBack) {
                    callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
                  }
                }
              },
              {
                name: '确认',
                callBack: () => {
                  DredgeForeverSaaSClient(item.OLAPKey).then(result => {
                  // this.getMessageSearchApplySaaSClient();
                    this.$emit('afterSuccess', result.data);
                    layer.alert('正式开通成功!');
                    if (callBack) {
                      callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
                    }
                  }, error => {
                    layer.alert(error.msg);
                  });
                  layer.close();
                }
              }
            ]
          });
          break;
        case '临时':// 临时开通
          layer.confirm('临时开通门店，有效期为30天' + ',是否继续?', {
            btn: [
              {
                name: '取消',
                callBack: function () {
                  layer.close();
                  if (callBack) {
                    callBack('');
                  }
                }
              },
              {
                name: '确认',
                callBack: () => {
                  DredgeTempSaaSClient(item.OLAPKey).then(result => {
                  // this.getMessageSearchApplySaaSClient();
                    this.$emit('afterSuccess', result.data);
                    layer.alert('临时开通成功!');
                    if (callBack) {
                      callBack('');
                    }
                  }, error => {
                    layer.alert(error.msg);
                  });
                  layer.close();
                }
              }
            ]
          });
          break;

        default:
          break;
      }
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.queryParams.searchText = '';
      this.queryParams.pageIndex = 0;
      this.queryParams.status = 0;
      this.$refs.studentApplyCourseTable.queryParams.pageIndex = 0;
      this.getMessageSearchApplySaaSClient();
    }
  }
};
</script>
