<template>
	<div class="a_cycle_lesson_has_been_selected_time">
		<div class="day_of_the_week">
			<span>{{dataInfo.WeekKeyValue}}</span>
		</div>
		<div class="time">
			<span>{{dataInfo.ClassTimeName}}</span>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		props:{
			dataInfo:Object
		}
	}
</script>

<style>
</style>
