<template>
  <div class="margin_left_right_div">
    <div class="tryArrangeCourse template_minOrmax_width_div"
         style="width: auto;min-width: 0;">
      <div class="">
        <div class="tryArrangeCourse_title">
          <!-- 筛选条件 -->
          <div class="summarizing_number_left"
               style="padding:0">
            <span class>试课追销
            </span>
          </div>
          <div class="mendian_backstage_search">
            <div class="btn_hover_white search_clear"
                 v-if="isFollowUpSales"
                 @click.stop="changeShowTrialDialog(true)">
              销售试课计划
              <span class="font_blue">({{Number(TrialLessonCount)}})</span>
            </div>
            <div class="btn_hover_white search_clear"
                 @click.stop="changeShowOnlineDialog(true)">
              线上申请
              <span class="font_blue">({{Number(onlineNum)}})</span>
            </div>
            <div class="btn_hover_white search_clear "
                 @click.stop="changeShowAbandonDialog(true)">
              已放弃
              <span class="font_red">({{Number(abandonNum)}})</span>
            </div>
            <div class="btn_hover_bg_blue search_btn "
                 @click.stop="changeShowArrangDialog(true)">学生试课</div>
            <div class="import_class"
                 @click="exportClick">
              <div class="import_btn">导出</div>
            </div>
          </div>

        </div>

        <div class="course_statistics_table_search flex">
          <date-range ref="saveDate"
                      :searchObj="searchObj"
                      :fromTitle="'安排日期'"
                      :minDate="vMinDate"
                      @changeDate="changeSaveDate"></date-range>
          <div class="table_select_box">
            <div class="table_select_title">课名</div>
            <el-select :value="vCourseNameKeyValue"
                       filterable
                       :style="searchObj.isShowDialog?' width: 210px;':''"
                       placeholder="必选"
                       @change="subjectEvent"
                       value-key="OLAPKey">
              <el-option v-for="item in courseNameList"
                         :key="item.OLAPKey"
                         :label="item.MainDemoName"
                         :value="item"></el-option>
            </el-select>
          </div>
          <div class="table_select_box">
            <div class="table_select_title">来源</div>
            <el-select :value="vAttenStatu"
                       filterable
                       :style="searchObj.isShowDialog?' width: 150px;':''"
                       placeholder="必选"
                       @change="attenStatusEvent"
                       value-key="OLAPKey">
              <el-option v-for="item in statusList"
                         :key="item.OLAPKey"
                         :label="item.MainDemoName"
                         :value="item"></el-option>
            </el-select>
          </div>
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入姓名、姓名首字母、手机号'"
                                    :clearAll="true"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="searchData"></input-search-contain-btn>
        </div>
        <!-- list -->
        <div class="tryArrangeCourse_table table_list_content">
          <table-list class="summarizing_list"
                      :key="tableKey"
                      :tableData="vdataReportList"
                      :totalNum="totalNum"
                      :PageCount="PageCount"
                      :tableColumns="tableCourseColumns"
                      :isSearchText="false"
                      :isNeedInnerScroll="false"
                      :defaultSort="defaultSort"
                      :queryParams="searchObj"
                      :footerContent="vFooterContent"
                      @loadTableData="getDataList"></table-list>
        </div>
      </div>
      <!-- 销售试课计划 -->
      <custom-dialog :title="'销售试课计划'"
                     class=" "
                     :width="'1290px'"
                     :visible.sync="isShowTrial"
                     :before-close="changeShowTrialDialog">
        <trial-class-records-table></trial-class-records-table>
      </custom-dialog>
      <!-- 线上申请 -->
      <custom-dialog :title="'线上申请'"
                     class=" "
                     :width="'1213px'"
                     :visible.sync="isShowOnline"
                     :before-close="changeShowOnlineDialog">
        <online-table @updateData="getDataList"
                      @arrangeClick="arrangeClickAfter"></online-table>
      </custom-dialog>
      <!-- 已放弃 -->
      <custom-dialog :title="'已放弃'"
                     class=" "
                     width="1250px"
                     :visible.sync="isShowAbandon"
                     :before-close="changeShowAbandonDialog">
        <abandon-table @updateData="getDataList"
                       @changeShowUpSellDialog="changeShowUpSellDialog"
                       @close="changeShowAbandonDialog"></abandon-table>
      </custom-dialog>
      <custom-dialog :title="'追销记录'"
                     class=" "
                     width="410px"
                     :visible.sync="isShowUpSell"
                     :before-close="changeShowUpSellDialog">
        <up-sell-record :dataInfo="seletedItem"
                        @updateData="getDataList"
                        @close="changeShowUpSellDialog"></up-sell-record>
      </custom-dialog>
      <custom-dialog :title="'报名'"
                     class=" "
                     width="410px"
                     :visible.sync="isShowApply"
                     :before-close="changeShowApplyDialog">
        <apply-record :dataInfo="seletedItem"
                      @updateData="getDataList"
                      @close="changeShowApplyDialog"></apply-record>
      </custom-dialog>

      <div>
        <dialog-factory ref="tryArrangeCourse"
                        :routerName="'tryArrangeCourse'"></dialog-factory>
        <dialog-factory-common ref="tryArrangeCourseFactoryCommon"></dialog-factory-common>
      </div>
    </div>
  </div>
</template>
<script>
import dialogFactory from '../../common/dialog-factory';
import tableList from '../../common/table-list/index';
import onlineTable from './components/online-table';
import trialClassRecordsTable from './components/trial-class-records-table';
import upSellRecord from './components/up-sell-record';
import arrangeCourse from './components/arrange-course';
import applyRecord from './components/apply-record';
import abandonTable from './components/abandon-table';
import { getCourseTypeNameListX, MessageSearchPageTrialClassForArrange, GetSaaSClientMsg } from '../../../API/workbench';
import inputSearchContainBtn from '../common/input-search-contain-btn';
export default {
  name: 'tryArrangeCourse',
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        type: '',
        sequence: 'DESC',
        stutasKey: 0,
        CourseNameKey: 0,
        CourseNameKeyValue: '',
        sourceKey: 0,
        sourceKeyValue: '',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd'), // this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd'),
        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      onlineNum: 0,
      TrialLessonCount: 0,
      abandonNum: 0,
      seletedItem: {},
      dataReportList: [],
      defaultSort: { prop: '', order: 'ascending' },
      statusList: [
        {
          MainDemoName: '全部',
          OLAPKey: '0'
        },
        {
          MainDemoName: '线上申请',
          OLAPKey: '3'
        },
        {
          MainDemoName: '员工录入',
          OLAPKey: '2'
        }
      ],
      courseNameList: [],
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          className: 'table_index_gray'
        },
        {
          label: '安排时间',
          prop: 'PlanDateTime',
          width: ' ',
          minWidth: 90,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '来源',
          prop: 'OrderRootInKeyValue',
          minWidth: 72,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            className: 'font_dual'
          }
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: ' ',
          minWidth: 82,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'student',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: () => { },
            className: 'font_dual'
          }
        },
        {
          label: '试课课名',
          prop: 'OrderClassKeyValue',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '试课时间',
          prop: 'TrialTime',
          minWidth: 115,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            className: 'font_dual'
          }
        },
        {
          label: '试课进度',
          prop: 'ScheduleStatus',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            className: 'radio_type',
            setClassName: (rowData) => {
              let className = '';
              console.log(rowData, 'setClassNamesetClassName');
              switch (Number(rowData.ScheduleStatusKey)) {
                case 1:
                  className = 'type_blue';
                  break;
                case 2:
                  className = 'type_green';
                  break;
                case 3:
                  className = 'type_yellow';
                  break;
                case 4:
                  className = 'type_yellow';
                  break;
                case 5:
                  className = 'type_green';
                  break;
                default:
                  break;
              }
              return className;
            }
          }
        },
        {
          label: '追销记录',
          prop: 'FollowRecordsSupport',
          minWidth: 352,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'up-sell-support',
          extend: {
            isEdit: true,
            click: (rowData) => {
              // if (rowData.TrialTime) {
              this.seletedItem = rowData;
              this.changeShowUpSellDialog(true, rowData);
              console.log('触发');
              // }
            }

          }
        },
        {
          label: '下次跟进',
          prop: 'NextFollowTime',
          sortable: 'custom',
          minWidth: 95,
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            className: 'font_dual'
          }

        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'left',
          headerAlign: 'center',
          width: 132,
          extend: {
            tableBtnGroup: [
              {
                name: '报名',
                extend: {
                  isHide: (rowData) => {
                    return rowData.ScheduleStatusKey == 5;
                  },
                  click: (rowData) => { this.studentPaymentEvent(rowData); },
                  ModulePowerKey: ''
                }
              },
              {
                name: '重排',
                extend: {
                  isHide: (rowData) => {
                    return rowData.ScheduleStatusKey == 5 || rowData.ScheduleStatusKey == 2;
                  },
                  click: (rowData) => {
                    if (rowData.TrialTime) {
                      console.log('触发');
                      this.arrangeClickAfter(rowData);
                    }
                  }

                }
              }

            ]
          }
        }
      ],
      isShowTrial: false, // 试课
      isShowOnline: false,   // 显示线上申请列表
      isShowUpSell: false,    // 显示追销
      isShowApply: false, // 显示报名记录//
      isShowAbandon: false,    // 显示放弃列表
      isFollowUpSales: false

    };
  },
  components: {
    inputSearchContainBtn,
    tableList,
    dialogFactory,
    onlineTable,
    trialClassRecordsTable,
    upSellRecord,
    arrangeCourse,
    applyRecord,
    abandonTable
  },
  created () {
    this.getCourseNameList();
    this.getMendianInfo();
  },
  mounted () {
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'tryArrangeCourse') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'tryArrangeCourse') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });
  },
  activated(){
      console.log('activatedactivated',this.$route.params)
    if(this.$route.params.data&&this.$route.params.data.dataInfo){
      this.searchObj.startTime = this.$route.params.data.dataInfo.startTime;
      this.searchObj.endTime = this.$route.params.data.dataInfo.endTime;
    }
    this.getDataList();
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // let info = o.ClassTime.substring(0, 10) + ',' + o.ClassTime.substring(11);
        // o.classTimeObj = info.split(',');
        // o.ClassTime = o.ClassTime.substring(5);
        // o.AttenceTime = o.AttenceTime ? o.AttenceTime.substring(5, 16) : '';
        // this.$set(o, 'OLAPKey', o.CourseNameKey);

        o.NextFollowTime = (o.NextFollowTime ? o.NextFollowTime.substring(0, 10) : '') + ' ' + this.setAfterDay(o.NextFollowTime);
        newArr.push(o);
      });
      return newArr;
    },
    vCourseNameKeyValue () {
      return this.searchObj.CourseNameKey == 0
        ? '全部'
        : this.searchObj.CourseNameKeyValue;
    },
    vAttenStatu () {
      return !this.searchObj.sourceKey
        ? '全部'
        : this.searchObj.sourceKeyValue;
    },
    vFooterContent () {
      return '共' + this.totalNum + '条数据 ';
    }
  },
  methods: {
    getMendianInfo () {
      GetSaaSClientMsg().then(result => {
        result.data.SaaSClientSetting.forEach(o => {
          // 是否追销2.0
          if (o.SetTypeKey == 17) {
            if (o.SetKey == 1 && o.SetContent == '2.0') {
              this.isFollowUpSales = true;
            } else {
              this.isFollowUpSales = false;
            }
          }
        });
      }, err => {
        layer.alert(err.msg);
      });
    },
    setAfterDay (dateStr) {
      if (!dateStr) {
        return '';
      }
      let times = Date.new(dateStr).getTime() - Date.new(Date.new().toLocaleDateString()).getTime();
      if (times == 0) {
        return '今天';
      } else if (times > 0) {
        return (times / 86400000) + '天后';
      } else {
        return '已过期';
      }
    },
    // 选中课程
    subjectEvent (obj) {
      console.log(obj, '选中课程');
      this.searchObj.CourseNameKeyValue = obj.MainDemoName;
      this.searchObj.CourseNameKey = obj.OLAPKey;
      this.searchData();
      this.$emit('changeSearchObj');
    },
    // 选中考勤状态
    attenStatusEvent (obj) {
      console.log(obj, '选中考勤');
      this.searchObj.sourceKeyValue = obj.MainDemoName;
      this.searchObj.sourceKey = obj.OLAPKey;
      this.searchData();
      this.$emit('changeSearchObj');
    },
    getCourseNameList () {
      getCourseTypeNameListX('OLAPKey', 'desc').then(result => {
        this.courseNameList = result.data.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; });
        this.courseNameList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
      });
    },
    // 选择日期
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      // this.searchObj.orderBy = '';
      this.searchObj.type = '';
      // this.searchObj.sequence = 'DESC';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.QueryID = 0;
      this.searchObj.IsExportKey = 0;
      this.searchObj.CourseNameKey = 0;
      this.searchObj.sourceKeyValue = '';
      this.searchObj.sourceKey = 0;
      this.searchObj.AttenceStatusKey = '';
      this.$refs.saveDate.resetTime();
      this.searchData();
    },
    // 获取数据
    getDataList (queryParams) {
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      MessageSearchPageTrialClassForArrange(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.totalDeductAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        this.onlineNum = result.data.OnlineAppliCount;
        this.TrialLessonCount = result.data.TrialLessonCount;
        this.abandonNum = result.data.AbandonCount;
        console.log(result.data, 'getDataList');
      }).catch(error => {
        console.log(error, 'error');
      });
    },
    // 导出
    exportClick () {
      console.log('导出');
      if (this.dataReportList.length > 0) {
        this.searchObj.IsExportKey = 1;
        let jsonFormate = {
          SheetTitle: '试课追销',
          clist: [
            { title: '安排时间', cName: 'PlanDateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '来源', cName: 'OrderRootInKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生名', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '试课课名', cName: 'OrderClassKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '试课时间', cName: 'TrialTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '试课进度', cName: 'ScheduleStatus', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '追销记录', cName: 'FollowRecordsSupport', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '下次跟进', cName: 'NextFollowTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchPageTrialClassForArrange(this.searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.searchObj.IsExportKey = 0;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
    },
    searchData () {
      // this.searchObj.StudentKey = '';
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 线上申请模块点击安排
    arrangeClickAfter (item, callBack) {
      console.log('arrangeClickAfter', item);
      this.seletedItem = item;
      this.toArrangeCourse(callBack);
    },
    // 工作流-试课
    toArrangeCourse (callback) {
      this.$dialog.open(this, {
        name: '学生试课',
        routerName: this.$route.name,
        moduleName: 'arrangeCourse',
        data: { dataInfo: this.seletedItem },
        callBack: {
          afterSuccess: () => {
            this.getDataList();
            if (callback) {
              callback();
            }
          }
        }
      });
    },
    // 显示销售试课计划弹出层
    changeShowTrialDialog (type) {
      this.isShowTrial = type || false;
      if (!type) {
        this.searchObj.pageIndex = 0;
        this.getDataList();
      }
      console.log(this.isShowTrial, 'isShowTrial');
    },
    // 显示线上申请弹出层
    changeShowOnlineDialog (type) {
      this.isShowOnline = type || false;
      console.log(this.isShowOnline);
    },
    // 显示安排试课弹出层
    changeShowArrangDialog (type) {
      if (type) {
        this.seletedItem = {};
      }
      this.toArrangeCourse();
    },
    // 显示追销记录弹出层
    changeShowUpSellDialog (type, item) {
      this.seletedItem = item || {};
      this.isShowUpSell = type || false;
    },
    // 显示报名弹出层
    changeShowApplyDialog (type) {
      this.isShowApply = type || false;
    },
    changeShowAbandonDialog (type) {
      this.isShowAbandon = type || false;
    },
    // 续费按钮
    studentPaymentEvent (item) {
      let obj = {
        StudentKey: item.StudentKey,
        isReadOnly: false,
        TableID: ''
      };
      this.$dialog.open(this, {
        name: '报名收款',
        routerName: this.$route.name,
        moduleName: 'signuppayment',
        moduleDescription: '学员报名购买课程，如未登记的学生可点界面“+”新增学生信息后再进行报名。',
        data: { dataInfo: obj },
        callBack: { afterSucces: () => { this.reload(); } }
      });
      this.seletedItem = item;
      // this.changeShowApplyDialog(true);
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.tryArrangeCourse) {
        this.$refs.tryArrangeCourse.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.tryArrangeCourseFactoryCommon) {
        this.$refs.tryArrangeCourseFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>
<style scoped>
.import_btn {
  background: #3498db;
  width: 56px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  margin-left: 10px;
  border-radius: 7px;
  color: #fff;
  margin-right: 10px;
}
</style>