<template>
<div>
    <div class="banner_lable flex">
      <div class="banner_lable_type"  :class="[vStudentType]">
        {{vStudentStatus}}
      </div>
      <!-- 学生头像 -->
      <div class="banner_lable_img student_courses_img fl">
        <heads-img 
                :imgUrl="studentInfo.HeadImgSupport"
                :showUpInput="!isReadonly"
                :dataInfo="studentInfo"></heads-img>
        <div class="student_courses_img_sex" :class="studentInfo.SexKeyValue == '男'?'boy':'gril'"></div>
      </div>
      <div class="banner_lable_info">
        <div class="banner_lable_name">
          <span>{{studentInfo.MainDemoName}}</span>
          <div class="banner_lable_face" :class="{no_type:studentInfo.FaceRecognition==0}"></div>
        </div>
        <div class="banner_lable_text">
          <div class="banner_lable_number">
            课时: {{vCurrentClassHourCount}}
          </div>
          <!-- <div class="banner_lable_number next_icon">
            积分: 100
          </div> -->
        </div>
        <div class="banner_lable_remarks" @click.stop="showAlterMessagePopup">
          <span :class="{font_gray:!studentInfo.ArrangeSupport}">{{studentInfo.ArrangeSupport || '排课备注'}}</span>
        </div>
      </div>
      <div class="banner_lable_btn" @click="readStudentFile">
        查看档案
      </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
    };
  },
  props: {
    studentInfo: Object,
    isReadonly: Boolean
  },
  computed: {
    vStudentStatus () {
      let status = '在校';
      if (this.studentInfo.StudentTypeKey == 3) {
        status = ' 潜客';
      } else if (this.studentInfo.IsActiveKey == 1) {
        status = ' 在校';
      } else {
        status = ' 离校';
      }
      return status;
    },
    vStudentType () {
      let className = '';
      if (this.studentInfo.StudentTypeKey == 3) {
        className = 'type_yellow';
      } else if (this.studentInfo.IsActiveKey == 1) {
        className = 'type_blue';
      } else {
        className = 'type_gray';
      }
      return className;
    },
    // 可用课时兼容年月日卡显示
    vCurrentClassHourCount () {
      if (Number(this.studentInfo.CurrentClassHourCount) == 0 && Number(this.studentInfo.YearCardMaxDayCount) > 0) {
        return '有';
      } else {
        return Number(this.studentInfo.CurrentClassHourCount);
      }
    }
  },
  mounted () {
    this.$dialog.register(this, ['studentFile']);
  },
  methods: {
    // 打开修改排课备注
    showAlterMessagePopup () {
      console.log('showAlterMessagePopup');
      this.$emit('editArrangeSupport', this.studentInfo);
    },
    // 查看学生档案
    readStudentFile () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'studentFile',
        data: { studentID: this.studentInfo.OLAPKey }
      });
    }
  }
};
</script>

<style>

</style>