<template>
    <div class="all_workbench_brand_info">
          <div class="info_list">
            <span class="font_gray">预发布小程序APPID:</span>
            <span>{{dataInfo.PreMiniProgramAppID}}</span>
          </div>
          <div class="info_list">
            <span class="font_gray">最新隐私版本修改日期时间:</span>
            <span>{{(dataInfo.NewPrivacyVersionEditTime)}}</span>
          </div>
          <div class="info_list">
            <span class="font_gray">最新发布审核设置修改日期时间:</span>
            <span>{{dataInfo.NewAuditSetEditTime}}</span>
          </div>
        </div>
</template>
<script>
export default {
    props:{
        dataInfo:Object
    }
}
</script>
<style scoped>
.all_workbench_brand_info {
  min-height: 88px;
  background: rgba(231, 234, 239, 0.35);
  border-radius: 4px;
  padding: 12px 0;
  padding-left: 20px;
}

.all_workbench_brand_info .info_title {
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 10px;
}

.all_workbench_brand_info .info_list {
  font-size: 14px;
  line-height: 20px;
}

.all_workbench_brand_info .info_list .font_gray {
  color: #999999;
}
</style>