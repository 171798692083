<template>
  <div>
    <!-- 课消金额日报 -->
    <custom-dialog :title="listTitle"
                   width="1213px"
                   :top="30"
                   class="class_staistics_list defult_dialog"
                   :visible.sync="isShowListDialog"
                   :before-close="closeDialog">
      <div class="table_list_content class_count_list">
        <div class="single_result_detail">
          <!-- 表格 -->
          <div class="table_content margin_T_0 single_shadow">
            <div class="screening_condition clearfix">
              <div class="screening_condition_number fl">
                <img src="../../../../public/image/screening_condition_number_icon01.png"
                     alt=" " />
                <span style="color:#999;">{{listTime}}</span>
              </div>
            </div>
            <!-- 列表 -->
            <el-table class="table_list"
                      ref="multipleTable"
                      :data="tableData"
                      highlight-current-row
                      stripe
                      empty-text
                      toggleRowSelection
                      @row-click="clickRow"
                      :row-key="rowKey||'none'"
                      :expand-row-keys="expandRowKeys"
                      style="width: 100%">
              <!-- 表头 -->
              <el-table-column type="selection"
                               width="55"></el-table-column>
              <el-table-column v-for="(item,index) in vTableColumns"
                               :width="item.width"
                               :sortable="item.sortable"
                               :align="item.align"
                               :key="index"
                               :type="item.type"
                               :label="item.label"
                               :prop="item.prop"
                               :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <span v-if="item.type=='number'">{{rounding(scope.row,item.prop,item.decimalPlace)}}</span>
                  <el-button v-else-if="item.type=='tableBtn'"
                             v-for="(info,indexs) in item.tableBtnGroup"
                             :class="info.className(scope.row[info.Stutas])"
                             class="tableBtn"
                             :key="indexs"
                             size="mini"
                             @click.stop="tableBtnClick(scope.row,info.eventName)">{{info.name}}</el-button>
                  <span v-else>{{scope.row[item.prop]}}</span>
                </template>
              </el-table-column>
              <!-- 收起部分内容 -->
              <el-table-column fixed="center"
                               type="expand">
                <template slot-scope="props">
                  <el-form label-position="left"
                           inline
                           class="table_expand_item demo-table-expand">
                    <el-form-item :label="item.label"
                                  v-for="(item,index) in detailColumns"
                                  :key="index">
                      <span :title="props.row[item.prop]">{{ props.row[item.prop] }}</span>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </custom-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isShowListDialog: false, // 是否显示主界面
      expandRowKeys: [] // 展开的行
    };
  },
  props: {
    listTitle: {
      // 列表头
      type: String,
      required: true
    },
    tableData: Array, // 列表数据
    tableColumns: Array, // table column
    listTime: String,
    detailColumns: Array, // 显示详情内容
    rowKey: String // 显示详情对应绑定的ID
  },
  computed: {
    vTableColumns () {
      let newTableColumns = [];
      this.tableColumns.forEach(element => {
        if (element.isShow) {
          newTableColumns.push(element);
        }
      });
      return newTableColumns;
    }
  },
  methods: {
    closeDialog () {
      this.isShowListDialog = false;
      this.searchText = '';
    },
    // 选中行
    clickRow (row, column, event) {
      this.selectedRow = row;
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row, true);
    },
    // 保留小数位数
    rounding (row, value, decimalPlace) {
      return Number(row[value]).toFixed(decimalPlace);
    },
    // 点击表单按钮
    tableBtnClick (rowData, eventName) {
      if (eventName == this.$initJson.baseFunctionBtnName.detail) {
        if (rowData[this.rowKey] == this.expandRowKeys[0]) {
          this.expandRowKeys = [];
        } else {
          this.expandRowKeys = [];
          this.expandRowKeys.push(rowData[this.rowKey]);
        }
        console.log('tableBtnClick', this.expandRowKeys);
      } else {
        this.$emit('tableBtnClick', rowData, eventName);
      }
    }
  }
};
</script>