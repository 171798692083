<template>
  <div>
    <!-- 课程选择 -->
    <div class="form_item form_info_line"
         :class="{form_info_required:required}">
      <div class="form_info_field">
        <span>{{formTitle}}</span>
        <span v-if="helpDescription">
          <input-form-tip :tips='helpDescription'></input-form-tip>
        </span>
      </div>
      <div class="form_info_value form_info_select"
           v-if="!isReadOnly"
           @click.stop="showEditDialog">
        <input type="text"
               readonly
               :value="value.label"
               :placeholder="required?'必选':'可选'" />
        <!-- <div class="class_table_courseChoose_icon el-icon-arrow-right"></div> -->
      </div>
      <div v-else
           class="input_readonly form_info_value"
           style="padding-right:26px">{{value.label}}</div>
    </div>

    <custom-dialog :title="vDialogTitleName"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowMianBody"
                   :before-close="doShowPopupHideClick">

      <btn-add-select v-if="isShowBtnAdd"
                      :addTitleName="'新增'"
                      :addmoduleName="'course-name-setting'"
                      :addmoduleTitleName="'add'"
                      :addmoduleKey='45'
                      @doAddSuccessEvent="doAddCoursSuccessEvent"></btn-add-select>

      <single-select-tree :sourceDate="dataSource"
                          :selectionDate="value"
                          :disabledType="disabledType"
                          @loadSourceData="loadSourceData"
                          @confirmClcik="confirmEdit"
                          @cancleClcik="cancelEdit"></single-select-tree>
    </custom-dialog>
  </div>
</template>
<script>
import singleSelectTree from '../common/single-select-tree';
export default {
  name: 'singleSelectTreeDialog',
  data () {
    return {
      isShowMianBody: false
    };
  },
  props: {
    formTitle: {
      // 标题，必填
      type: String,
      required: true
    },
    disabledType:{
      type:[Number,String],
      default:1
    },
    dataSource: Array, // 数据源
    value: {
      // 值，双向绑定，如不想双向绑定，则自行实现input事件
      type: Object,
      required: true,
      // 对象或数组默认值必须从一个工厂函数获取
      default: function () {
        return {
          label: '',
          id: '',
          typeKey: ''
        };
      }
    },
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    formPlaceholder: String, // 默认文本，可选，如不提供，则默认显示title
    isReadOnly: {
      type: Boolean,
      default: false
    },
    isShowBtnAdd: {
      type: Boolean,
      default: true
    },
    helpDescription: String // 表单说明提示
  },
  computed: {
    vPlaceholder () {
      // 默认文本，如不提供，则默认显示title
      return this.formPlaceholder || this.formTitle;
    },
    vDialogTitleName () {
      return '选择' + this.formTitle;
    }
  },
  components: {
    singleSelectTree
  },
  methods: {
    doAddCoursSuccessEvent (result) {
      console.log(result, '新增回调');

      // 接收选中值.
      var item = {};
      item.id = result.OLAPKey;
      item.label = result.MainDemoName;
      this.$emit('doAddCoursSuccessEvent', item);
      this.confirmEdit(item);
    },
    // 关闭弹窗
    doShowPopupHideClick () {
      this.isShowMianBody = false;
    },
    // 点击时，显示选择层
    showEditDialog () {
      this.isShowMianBody = true;
    },
    confirmEdit (val) {
      this.value.id = val.id;
      this.value.label = val.label;
      this.$emit('input', this.value);
      this.cancelEdit();
    },
    cancelEdit () {
      this.isShowMianBody = false;
    },
    loadSourceData () {
      this.$emit('loadSourceData');
    }
  }
};
</script>

