<template>
  <div class="ymt">
    <div class="ymt_info">
      <div class="ymt_headImg_box">
        <img :src="vImgUrl"
             :onerror="vDefaultImgUrl"
             class="ymt_headImg">
      </div>
      <div class="ymt_name">{{customerInfo.MainDemoName}}</div>
      <div class="ymt_phone">{{customerInfo.MobileNumber}}</div>
      <div class="ymt_content_box">
        <img src="../../../../public/image/tjbm.png"
             class="contentIcon">
        <div class="ymt_content_text">累计推荐：<span class="ymt_content_text_number">{{Number(customerInfo.VolumeCount) || '0'}}人</span></div>
      </div>
      <div class="ymt_contenthb_box">
        <img src="../../../../public/image/hb.png"
             class="contenthbIcon">
        <span class="ymt_content_text">累计佣金：<span class="ymt_content_text_number">{{Number(customerInfo.TotalConcessionAmount) || '0'}}元</span></span>
      </div>
      <div class="ymt_qr_box"
           @click="showQrCode = true">
        <div class="ymt_qr_code">
          <!-- <vue-qr :text="qrcodeUrl"
                  :size="80"
                  :margin="0"></vue-qr> -->
          <div v-if="qrcodeUrl==''"
               class="qrtext">二维码生成中</div>
          <img v-else
               :src="qrcodeUrl"
               style='width:84px;' />
        </div>
        <div class="ymt_qr_text">您的专属二维码</div>
      </div>
    </div>
    <div class="ymt_hr">当前方案</div>
    <div class="ymt_active">
      <activeItem class="tmmplate_box"
                  :dataInfo='currentActivityList'
                  :isCurrActive='true'></activeItem>
    </div>
    <div class="ymt_hr"
         v-if="historyActivityList.length>0">历史方案</div>
    <div style=" background-color: #ededed;padding-bottom:15px"
         v-if="historyActivityList.length>0">
      <div class="ymt_active"
           v-for="(item,index) in historyActivityList"
           :key="index">
        <activeItem class="tmmplate_box"
                    @getHistoryDetail="getHistoryDetail"
                    :dataInfo='item'
                    :isCurrActive='false'></activeItem>
      </div>
    </div>
    <custom-dialog title="一码通"
                   width="500px"
                   :visible.sync="showQrCode"
                   :before-close="closeCode">
      <code-show :qrcodeurl="qrcodeUrl"></code-show>
    </custom-dialog>
  </div>
</template>

<script>
import { GetCustomerChannelInfo, GetCurrentActivityList, GetHistoryActivityList, GetHistoryActivityRebateList } from '../../../API/workbench';
import activeItem from './channel-list-detail-item.vue';
import codeShow from './code-show.vue';
import vueQr from './vue-qr.js';
export default {
  data () {
    return {
      telephone: '',
      timeout: null,
      showQrCode: false,
      customerOLAPKey: '', // 合伙人id
      qrcodeUrl: '',
      customerInfo: {
        ChannelCount: '50000',
        ResolvedAmount: '2000',
        TurnoverAmount: '100000',
        VolumeCount: '50',
        MobileNumber: '16637628898',
        OLAPKey: '54615',
        MainDemoName: '下局到'
      }, // 渠道商信息
      currentActivityList: {
        ActivityKeyValue: '特殊活动',
        ActivityKey: '123456',
        OLAPKey: '25463145',
        Rebate1Amount: '1000',
        Rebate0Amount: '500',
        ChannelCount: '50',
        IsHasActivity: true,
        ActivityList: [{
          GenerateTime: '2023-10-01',
          StudentKeyValue: '张自立',
          ApplyAmount: '5000',
          RebateAmount: '200',
          RebateStatusKey: '0',
          RebateStatusKeyValue: '待返'
        },
        {
          GenerateTime: '2023-10-01',
          StudentKeyValue: '张自生',
          ApplyAmount: '6000',
          RebateAmount: '400',
          RebateStatusKey: '1',
          RebateStatusKeyValue: '已返'
        }
        ]
      }, // 当前活动信息列表
      historyActivityList: [{
        ActivityKeyValue: '双11特惠活动',
        ActivityKey: '123456',
        OLAPKey: '23235',
        Rebate1Amount: '9000',
        Rebate0Amount: '800',
        ChannelCount: '120',
        IsHasActivity: true,
        ActivityList: []
      },
      {
        ActivityKeyValue: '国庆大促销活动',
        ActivityKey: '123456',
        OLAPKey: '223235',
        Rebate1Amount: '3400',
        Rebate0Amount: '400',
        ChannelCount: '50',
        IsHasActivity: false,
        ActivityList: []
      }
      ] // 历史活动列表
    };
  },
  components: {
    activeItem,
    codeShow,
    vueQr
  },
  props: {
    rowobj: Object
  },
  created () {
    let ENV = '';
    if (process.env.NODE_ENV == 'development') {
      ENV = 'develop';
    } else {
      if (window.zxConfig.Product_ENV === 'product') {
        ENV = 'release';
      } else {
        ENV = 'trial';
      }
    }
    this.qrcodeUrl = this.$store.getters.APIURL + '/public/Util/qrcode.php?Content=' + this.$store.getters.SaaSClientInfo.MiniProgramUrl + '/release?targetRouterParms=' + encodeURIComponent('ChannelKey=' + this.rowobj.OLAPKey + '&targetSaaSClientID=' + this.rowobj.SaaSClientKey) + '&size=10';
    this.customerOLAPKey = this.rowobj.OLAPKey;
    this.telephone = this.rowobj.MobileNumber;
    if (this.customerOLAPKey > 0) {
      this.getCustomerChannelInfo();
      this.getCurrentActivityList();
      this.getHistoryActivityList();
    }
  },
  mounted () {
  },
  computed: {
    vDefaultImgUrl () {
      return ('this.src="' + require('../../../../public/image/deflut_teacher_photo@2x.png') + '"');
    },
    vImgUrl () {
      if (!this.customerInfo.Headimg) {
        return '';
      } else if (this.customerInfo.Headimg.indexOf('http') > -1) {
        return this.customerInfo.Headimg;
      } else {
        return this.$store.getters.CDNURL + this.customerInfo.Headimg;
      }
    }
  },
  methods: {
    closeCode () {
      this.showQrCode = false;
    },
    // 获取渠道商信息详情
    getCustomerChannelInfo () {
      GetCustomerChannelInfo(this.customerOLAPKey).then(res => {
        this.customerInfo = res.data;
      });
    },
    // 获取当前活动列表
    getCurrentActivityList () {
      GetCurrentActivityList(this.customerOLAPKey).then(res => {
        this.currentActivityList = res.data;
      });
    },
    // 获取历史活动列表
    getHistoryActivityList () {
      GetHistoryActivityList(this.customerOLAPKey).then(res => {
        res.data.forEach(item => {
          item.ActivityList = [];
        });
        this.historyActivityList = res.data;
      });
    },
    getHistoryDetail (data) {
      console.log(data, 'data');
      GetHistoryActivityRebateList(this.customerOLAPKey, data.ActivityKey).then(res => {
        this.historyActivityList.forEach(item => {
          if (item.ActivityKey == res.data[0].ActivityKey) {
            item.ActivityList = res.data;
            console.log(item, 'item');
          }
        });
        // this.$forceUpdate();
      });
    }
  }
};
</script>

<style scoped>
.ymt {
  position: relative;
  height: 600px;
  overflow: auto;
  background-color: #ededed;
  flex: 1;
}

.ymt_info {
  width: 100%;
  height: 200px;
  background-image: url('../../../../public/image/ymtbg.png');
  background-size: 100%;
  background-position: center bottom;
  position: relative;
}

.ymt >>> .view_header_box.cu-bar {
  background-color: inherit;
  background-image: url('../../../../public/image/ymtbg.png');
  background-size: 100%;
  border-bottom: inherit;
}

.ymt_headImg_box {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 30px;
  left: 65px;
  background: #ffffff;
  border: 5px solid #ffffff;
  box-shadow: 0px 3px 10px 0px rgba(234, 71, 44, 0.3);
  border-radius: 30px;
}

.ymt_headImg {
  width: 100%;
  height: 100%;
}

.ymt_name {
  position: absolute;
  width: 200px;
  height: 30px;
  top: 30px;
  left: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  text-align: left;
}

.ymt_phone {
  position: absolute;
  width: 200px;
  height: 20px;
  top: 70px;
  left: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #999999;
}

.contenthbIcon {
  width: 20px;
  height: 20px;
}

.contentIcon {
  width: 20px;
  height: 20px;
}

.ymt_content_box {
  position: absolute;
  width: 200px;
  height: 20px;
  top: 100px;
  left: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}

.ymt_contenthb_box {
  position: absolute;
  width: 200px;
  height: 20px;
  top: 130px;
  left: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}

.ymt_content_text {
  font-size: 14px;
  color: #999999;
  align-items: center;
  margin-left: 7px;
}

.ymt_content_text_number {
  font-weight: bold;
  color: #333333;
}

.ymt_qr_box {
  background-image: url('../../../../public/image/ymt_code.png');
  background-size: 100%;
  position: absolute;
  width: 120px;
  height: 120px;
  top: 15px;
  right: 25px;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.ymt_qr_box_t {
  background-image: url('../../../../public/image/ymt_code.png');
  background-size: 100%;
  width: 400px;
  height: 460px;
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
}

.ymt_qr_code {
  flex: 1;
  text-align: center;
  padding-top: 12px;
}

.ymt_qr_text {
  width: 100%;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  align-items: center;
  color: #ffffff;
}

.ymt_qr_text_t {
  width: 100%;
  height: 130px;
  font-size: 30px;
  line-height: 100px;
  text-align: center;
  align-items: center;
  color: #ffffff;
}

.ymt_hr {
  font-size: 24px;
  text-align: left;
  margin-left: 20px;
  margin-top: 7px;
}

.ymt_active {
  display: flex;
  margin: 20px 20px;
  background: #ffffff;
  border-radius: 8px;
}

.tmmplate_box {
  flex: 1;
}

.code_pop_shodaw {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex: 1;
  height: 100%;
  background: rgb(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.code_pop_content {
  width: 100px;
  height: 100px;
}
</style>