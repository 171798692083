<template>
  <div>
    <div class="choose-courseware">
      <el-tree class="choose-courseware-tree"
               ref="tree"
               :data="dataList"
               :props="prop"
               :load="loadNode"
               lazy
               node-key="OLAPKey"
               empty-text="暂⽆数据"
               @check-change="treeNodeClick">
        <span slot-scope="{ node, data }"
              class="span__">

          <i v-if="data.icon"
             style="font-size: 16px;"
             :class="data.icon" />
          <el-checkbox-group v-if="data.check"
                             v-model="checkList"
                             @change="seletedItem">

            <el-checkbox :label="node.data">{{
                node.label
              }}</el-checkbox>
          </el-checkbox-group>
          <!-- <el-checkbox :label="node" v-if="data.check" class="tree-node-span" @change="seletedItem">{{ node.label }}</el-checkbox> -->
          <!-- <el-radio
            :label="node"
            v-model="chooseKey"
            v-if="data.check"
            class="tree-node-span"
            >{{ node.label }}</el-radio
          > -->
          <span class="tree-node-span"
                v-else>{{ node.label }}</span>
        </span>
      </el-tree>
    </div>
    <save-cancel-btn-group :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
  </div>
</template>
<script>
import singleSelectionItem from "../../../common/single-selection-item.vue";
import {
  commonGetCoursewarePackageParentList,
  commonGetCoursewareParentList,
} from "../../../../API/workbench";
export default {
  data () {
    return {
      selectList: [],
      dataList: [],
      chooseKey: [],
      FailureFilteringKey: 0,
      prop: {
        label: "MainDemoName",
        // children: 'zones',
        isLeaf: "leaf"
      },
      count: 1,
      checkList: []
    };
  },
  props: {
    seletedCWList: Array
  },
  components: {
    singleSelectionItem
  },
  computed: {
    vToken () {
      return this.$store.getters.token;
    },
    vRoleKey () {
      return Number(this.vToken.RoleKey) == 2; // 2为校长；非则为老师
    }
  },
  watch: {},
  created () {
    console.log("this.seletedCWList", this.seletedCWList)

  },
  mounted () {
    this.loadDatalist();
  },
  methods: {
    handleCheckChange (data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },
    handleNodeClick (data) {
      console.log(data);
    },
    // 获取大课包
    loadDatalist () {
      commonGetCoursewarePackageParentList(0, this.FailureFilteringKey)
        .then(result => {
          console.log("加载初始大课包", result);
          result.data.forEach((o, index) => {
            o.icon = "folder-blue";
            o.check = false;
          });
          this.dataList = result.data;
          console.log(this.dataList, "this.dataList");
        })
        .catch(error => {
          console.log("加载初始大课包异常");
        });
    },
    treeNodeClick (data, checked) {
      console.log(data);
      const node = this.$refs.tree.getNode(data);
      const str = [];
      function filterName (node) {
        if (node) {
          if (node.parent) {
            str.push(node.data.name);
            filterName(node.parent);
          }
        }
      }
      // 获取当前选择的id在数组中的索引
      const indexs = this.selectList.indexOf(data.OLAPKey);
      // 如果不存在数组中，并且数组中已经有⼀个id并且checked为true的时候，代表不能再次选择。
      if (indexs < 0 && this.selectList.length === 1 && checked) {
        console.log(1);
        this.$message({
          message: "只能选择⼀个区域！",
          type: "error",
          showClose: true
        });
        // 设置已选择的节点为false 很重要
        this.$refs.tree.setChecked(data, false);
      } else if (this.selectList.length === 0 && checked) {
        console.log(2);
        // 发现数组为空并且是已选择
        // 防⽌数组有值，⾸先清空，再push
        this.selectList = [];
        // this.ruleForm.branch = ''
        this.selectList.push(data.OLAPKey);
        filterName(node);
        // this.ruleForm.branch = str.reverse().join('/')
      } else if (indexs >= 0 && this.selectList.length === 1 && !checked) {
        console.log(checked);
        console.log(3);
        // 再次直接进⾏赋值为空操作
        this.selectList = [];
        // this.ruleForm.branch = ''
      }
    },
    loadNode (node, resolve) {
      if (node.level > 3) return resolve([]);
      if (node.level === 0) return resolve([]);

      var data;
      console.log(node, "node");
      setTimeout(() => {
        if (node.level === 1) {
          commonGetCoursewarePackageParentList(
            node.data.OLAPKey,
            this.FailureFilteringKey
          )
            .then(result => {
              console.log("子课包", result.data);
              result.data.forEach((o, index) => {
                o.icon = "folder-blue";
                o.check = false;
                // o.leaf=true
              });
              data = result.data;
            })
            .catch(error => {
              cosnole.log("获取子课包异常");
              return null;
            });
        } else if (node.level === 2) {
          if (node.data.TypeKey == "2") {
            commonGetCoursewarePackageParentList(
              node.data.OLAPKey,
              this.FailureFilteringKey
            )
              .then(result => {
                console.log("子课包", result.data);
                result.data.forEach((o, index) => {
                  o.icon = "folder-blue";
                  o.check = false;
                  // o.leaf=true
                });
                data = result.data;
              })
              .catch(error => {
                cosnole.log("获取子课包异常");
                return null;
              });
          } else {
            commonGetCoursewareParentList(node.data.OLAPKey)
              .then(result => {
                console.log("课件", result.data);
                node.expanded = false;
                result.data.forEach((o, index) => {
                  o.leaf = true;
                  o.check = true;
                });
                data = result.data;
              })
              .catch(error => {
                cosnole.log("获取课件异常");
                return null;
              });
          }
        } else if (node.level === 3) {
          if (node.data.TypeKey == "0") {
            commonGetCoursewareParentList(node.data.OLAPKey)
              .then(result => {
                console.log("课件", result.data);
                node.expanded = false;
                result.data.forEach((o, index) => {
                  o.check = true;
                  o.leaf = true;
                });
                data = result.data;
              })
              .catch(error => {
                cosnole.log("获取课件异常");
                return null;
              });
          } else {
            return resolve([]);
          }
        }
        console.log("data", data);
      }, 100);
      setTimeout(() => {
        console.log("data", data);
        resolve(data);
      }, 400);
    },
    seletedItem (item) {
      console.log('checkList', this.checkList)
      console.log("seletedItem", item)
    },
    confirmClick (callBack) {
      // if (this.chooseKey.length) {
      //   layer.alert("请选择课包");
      //   if (callBack) {
      //     callBack();
      //   }
      // } else {
      // this.$emit("afterSuccess", this.chooseKey.data);
      // }
      this.$emit("afterSuccess", this.checkList);
    },
    cancelClick () {
      this.$emit("cancelClick");
    }
  }
};
</script>
<style scoped>
.choose-courseware {
  height: 500px;
  overflow: auto;
}
.choose-courseware .form_info_list {
  padding: 0;
}

.choose-courseware .folder-blue {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url('../../../../../public/cw-img/file_icon@1.png');
  background-size: 23px;
  position: relative;
  top: 4px;
  left: -3px;
}
.choose-courseware >>> .el-tree-node__content {
  height: 30px;
  border: none;
}
.choose-courseware .tree-node-span {
  width: 292px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 18px;
  -o-text-overflow: ellipsis;
}
.choose-courseware >>> .el-tree > .el-tree-node > .el-tree-node__content {
  border-bottom: none;
}

.choose-courseware >>> .el-tree > div {
  border-bottom: 1px solid #ececec;
}
/* .choose-courseware .el-tree{
  padding: 0px 0px;
} */
/* .choose-courseware .el-tree>.el-tree-node>.el-tree-node__content{
  border: none;
  height: 30px;
    line-height: 30px;
    margin-bottom: 0px;
} */
/* .choose-courseware .el-tree>.el-tree-node>.el-tree-node__children>.el-tree-node>.el-tree-node__content{
  padding-left: 26px !important;
} */
</style>
