<template>
  <div class="search_result_cq search_result single_result_detail">
    <div class="search_result_content content_marginTop_30">
      <div class="search_result_list single_shadow content_marginRL ">
        <el-tree class="filter-tree"
                 ref="tree"
                 :data="treeData"
                 :props="defaultProps"
                 node-key="id"
                 show-checkbox
                 :check-strictly="true"
                 @check="checkChange">

        </el-tree>
      </div>
    </div>
    <div class="content_marginRL coures_type_result form_info_line content_marginTop_8 single_shadow"
         style="padding: 0px 30px;"
         v-if="isRemarksShow?isRemarksShow:false">
      <input-text :formTitle="'处理原因'"
                  style="width: 100%;"
                  :readonly="false"
                  :formPlaceholder="'选填'"
                  :required="false"
                  v-model="courseRemarks"></input-text>
    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmSelectedValue"
                           @cancelClick="closeDialog"></save-cancel-btn-group>
  </div>
</template>
<script>
export default {
  name: 'mutilSelectTree',
  data () {
    return {
      treeData: [],
      defaultCheckedKeys: [], // 默认选中节点
      defaultExpandedKeys: ['2'], // 默认展开节点
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      selectedItems: { selectedIDs: [], selectedNames: [], levelPath: [] },
      courseRemarks: '' // 处理原因
    };
  },
  props: ['sourceDate', 'isRemarksShow'], // 单选数据项 需绑定到界面
  created () {
    this.initTreeDate();
    console.log(this.sourceDate, '需绑定到界面');
  },
  computed: {

  },
  watch: {
    sourceDate () {
      this.initTreeDate();
    }
  },
  methods: {
    confirmSelectedValue (callBack) {
      let selectionIDs = this.$refs.tree.getCheckedKeys();
      this.$emit('buttonClickEvent', selectionIDs, this.courseRemarks, callBack);
    },
    closeDialog () {
      this.$emit('cancleClickForMutilSelected');
    },
	// 通过check的回调里面获取节点id,再获取节点的node对象
    checkChange (data) {
      const node = this.$refs.tree.getNode(data.id);
      this.setNode(node);
    },
    // 递归设置子节点和父节点
    setNode (node) {
      if (node.checked) {
      // 如果当前是选中checkbox,则递归设置父节点和父父节点++选中
        this.setParentNode(node);
      } else {
      // 如果当前是取消选中checkbox,则递归设置子节点全部取消选中
        this.setChildNode(node);
      }
    },
    // 递归设置父节点全部选中
    setParentNode (node) {
      if (node.parent) {
        for (const key in node) {
          if (key === 'parent') {
            node[key].checked = true;
            this.setParentNode(node[key]);
          }
        }
      }
    },
    // 递归设置子节点全部取消选中
    setChildNode (node) {
      if (node.childNodes && node.childNodes.length) {
        node.childNodes.forEach(item => {
          item.checked = false;
          this.setChildNode(item);
        });
      }
    },
    initTreeDate () {
      var initDate = this.$utils.parseJson(this.sourceDate);
      // 定义一个空数组
      var hash = [];
      var idField = 'OLAPKey';
      var parentField = 'FatherKey';
      var textField = 'MainDemoName';
      var typeKey = 'TypeKey';
      if (!initDate) {
        return hash;
      }
      for (var i = 0; i < initDate.length; i++) {
        var dataitem = initDate[i];
        var item = {
          id: dataitem[idField],
          parentId: dataitem[parentField],
          label: dataitem[textField],
          children: dataitem,
          typeKey: dataitem[typeKey]
        };
        // 以id为数组下标，获取值
        hash[item.id] = hash[item.id] || [];
        hash[item.parentId] = hash[item.parentId] || [];
        item.children = hash[item.id];
        hash[item.parentId].push(item);
      }
      this.treeData = hash[0] || [];
      console.log(this.treeData);
    }
  }
};
</script>


