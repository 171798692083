import { render, staticRenderFns } from "./stop-school-list.vue?vue&type=template&id=3f4f1893"
import script from "./stop-school-list.vue?vue&type=script&lang=js"
export * from "./stop-school-list.vue?vue&type=script&lang=js"
import style0 from "./stop-school-list.vue?vue&type=style&index=0&id=3f4f1893&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports