<template>
  <div class="homework_student_list">
    <div class="student_list_img">
      <img :src="vHeadImg"
           :onerror="vDefaultImg"
           alt="../../../../../public/image/no_logo@2x.png"
           class="img_box">
    </div>
    <div class="student_list_name">
      <div class="name_box"
           style="max-width: 5.2rem">{{praiseItem.Reader}}</div>
    </div>
  </div>
</template>

<script>
import scoreItem from './score-item';
export default {
  data () {
    return {
      scoreList: [
        {
          index: 0
        },
        {
          index: 1
        },
        {
          index: 2
        }, {
          index: 3
        }, {
          index: 4
        }
      ]
    };
  },
  props: {
    praiseItem: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  components: {
    scoreItem
  },
  watch: {

  },
  methods: {
    coinState (praiseItem, index) {
      // console.log(praiseItem, coinIndex, '学分');
      praiseItem.coinList.forEach((o, index) => {
        if (index <= index) {
          o.coinState = true;
        } else {
          o.coinState = false;
        }
      });
      praiseItem.Currency = index + 1;
      this.$emit('coinStateClick', praiseItem);
    }
  },
  computed: {
    vHeadImg () {
      if (this.praiseItem.ReaderHeadImg) {
        if (this.praiseItem.ReaderHeadImg.indexOf('http') > -1) {
          return this.praiseItem.ReaderHeadImg;
        } else {
          return this.$store.getters.CDNURL + this.praiseItem.ReaderHeadImg;
        }
      } else {
        if (Number(this.praiseItem.SexKey) == 2) {
          return require('../../../../../public/image/deflut_studentPhoto_boy.png');
        } else {
          return require('../../../../../public/image/deflut_studentPhoto_gril.png');
        }
      }
    },
    // 默认显示logo
    vDefaultImg () {
      return 'this.src="' + require('../../../../../public/image/face_screenage_noImg.png') + '"';
    }
  }
};
</script>

<style>
</style>