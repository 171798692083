<template>
  <div class="WKT_index_from">
    <div class="WKT_index_from_title">
      机构简介
    </div>
    <div class="WKT_index_from_content">
      <div class="WKT_slideshow">
        <div class="WKT_slideshow_upload">
          <div class="WKT_slideshow_upload_title">
            机构LOGO
          </div>
          <div class="WKT_introduction">
            <crop-upload-img :size="'尺寸：204px*204px'"
                             :remarks="'备注：建议上传机构LOGO或门店照片，更好的展示机构形象。'"
                             :imgHeight="204"
                             :imgWidth="204"
                             :isShowImg="true"
                             :isShrink="false"
                             :ImgURl="dataInfo.logo||mendianInfo.LogoPath"
                             @uploadImgSuccess="doAfterUploadImgSuccess"></crop-upload-img>
          </div>
        </div>
        <div>
          <input-edit-form :titleName="'机构名称'"
                           :isReadonly="true"
                           :value="dataInfo.name"
                           @doEditBtnClick="doEditOrganizationNameClick"></input-edit-form>

          <div class="WKT_slideshow_title">
            <span>机构简介</span>
          </div>
          <div class="WKT_introduction_textarea"
               @click="doEditOrganizationIntroClick">
            <textarea readonly
                      v-model="dataInfo.introduce"></textarea>
            <div class="WKT_introduction_btn"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 编辑 -->
    <div>
      <custom-dialog :title="'修改机构名称'"
                     :visible.sync="selectMendianInfoEdit.isShow"
                     :before-close="cancelClick">
        <div class="mendian_setting_content ">
          <input-text :formTitle="'机构名称'"
                      :readonly="false"
                      :formPlaceholder="'必填'"
                      :required="true"
                      style="padding-right: 22px"
                      v-model="selectMendianInfoEdit.name"></input-text>
        </div>
        <!-- 按钮组 -->
        <save-cancel-btn-group :isSingleSubmit="true"
                               :singleSubmitType="'dynamic'"
                               @confirmClick="submitOrganizationName"
                               @cancelClick="cancelClick"></save-cancel-btn-group>
      </custom-dialog>
    </div>
    <div>
      <textarea-pop :title="'修改机构简介'"
                    :maxLength="99999"
                    :message="selectMendianInfoEdit.introduce"
                    :placeholderText="'请输入机构简介...'"
                    ref="textareaPop"
                    @submitStudentArrangeSupport="submitOrganizationIntro"></textarea-pop>
    </div>
  </div>
</template>

<script>
import inputEditForm from '../common/input-edit-form';
import textareaPop from '../../common/textarea-pop';
import cropUploadImg from '../common/crop-upload-img';
import { AmendOrganizationIntro, AmendOrganizationLogo, AmendOrganizationName } from '../../../API/workbench';
export default {
  components: { textareaPop, cropUploadImg, inputEditForm },
  data () {
    return {
      isOrganizationDialogShow: true,
      selectMendianInfoEdit: { isShow: false, name: '', introduce: '' },
      mendianInfo: this.$store.getters.SaaSClientInfo
    };
  },
  props: {
    dataInfo: {// WKT门店信息
      type: Object,
      default: function () {
        return {
          id: 0, // 门店id
          name: '', // 门店名称
          logo: '', // 门店logo
          introduce: '' // 简介
        };
      }
    }
  },
  created () {
    console.log(this.dataInfo, 'WKT门店信息');
  },
  watch: {

  },
  computed: {

  },
  methods: {

    // 编辑机构名称弹出层显示
    doEditOrganizationNameClick () {
      this.selectMendianInfoEdit.isShow = true;
      this.selectMendianInfoEdit.name = this.dataInfo.name;
    },
    // 编辑机构简介弹出层显示
    doEditOrganizationIntroClick () {
      this.$refs.textareaPop.isAlterMessagePopup = true;
      this.selectMendianInfoEdit.introduce = this.dataInfo.introduce;
    },
    // 编辑机构logo
    doAfterUploadImgSuccess (url) {
      if (url) {
        AmendOrganizationLogo(url).then(result => {
          this.dataInfo.logo = url;
          this.mendianInfo.LogoPath = this.dataInfo.logo;
          this.$store.commit('setSaaSClientInfo', this.mendianInfo);
          layer.alert('机构LOGO上传成功');
        }, error => {
          layer.alert(error.msg);
        });
      }
    },
    // 提交机构名称
    submitOrganizationName (callBack) {
      let flag = false;
      if (!this.selectMendianInfoEdit.name || !this.selectMendianInfoEdit.name.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('请输入机构名称');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      AmendOrganizationName(this.selectMendianInfoEdit.name).then(result => {
        this.selectMendianInfoEdit.isShow = false;
        this.dataInfo.name = this.selectMendianInfoEdit.name;
        layer.alert('机构名称修改成功');
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 提交机构简介
    submitOrganizationIntro (messageText, callBack) {
      // let flag = false;
      // if (!messageText || !messageText.replace(/(^\s*)|(\s*$)/g, '')) {
      //   this.$refs.textareaPop.isAlterMessagePopup = true;
      //   layer.alert('请输入内容');
      //   flag = true;
      // }
      // if (flag) {
      //   if (callBack) {
      //     callBack();
      //   }
      //   return false;
      // }
      let Obj = { Intro: messageText };
      AmendOrganizationIntro(Obj).then(result => {
        this.dataInfo.introduce = messageText;
        this.$refs.textareaPop.isAlterMessagePopup = false;
        layer.alert('机构简介修改成功');
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 取消
    cancelClick () {
      console.log('取消');
      this.selectMendianInfoEdit.isShow = false;
      // this.selectMendianInfoEdit.text = null;
    }
  }
};
</script>

<style>
</style>