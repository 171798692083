<template>

  <div class="flow_packet_bill_contont_list pr">
    <div class="flow_packet_bill_contont_list_content">

      <div class="flow_packet_bill_img"
           :class="dataInfo.className">
        <!--  -->

      </div>
      <div class="flow_packet_bill_detail">
        <div class="font_gray"
             style="margin-bottom: 5px;">{{dataInfo.title}}</div>
        <div>
          <span style="font-size:28px">{{Number(dataInfo.value)||'--'}}</span>
          <span>{{Number(dataInfo.value)>0?dataInfo.unit:''}}</span>
        </div>
      </div>
      <div class="detail_btn"
           v-if="dataInfo.showBtn"
           @click.stop="goDetail">查看详情</div>
    </div>
    <div class="detail_list"
         v-if="dataInfo.list.length>0">
      <div class="detail_list_item"
           v-for="(item,key) in dataInfo.list"
           :key="key"
           @click.stop="goflowPacketDetail(item)">
        <span class="fl detail_item_text">{{item.ClientName}}{{item.ClientName=='通用流量'?'':'授权流量'}}</span>
        <span class="fr next_step"
              :title="$utils.flowFormate(item.ConsumeFlow,'','sumType')">{{$utils.flowFormate(item.ConsumeFlow,'','sumType')}}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    }
  },
  props: {
    dataInfo: Object,
    searchData: Object,
    OLAPKey: [Number, String]
  },
  computed: {

  },
  methods: {
    goDetail (item) {
      console.log("goDetail", item)
      if (this.dataInfo.type == 1) {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '余额明细',
          moduleName: 'balanceDetailReport',
          data: {
            dataInfo: {
              StartTime: this.searchData.StartTime,
              EndTime: this.searchData.EndTime,
              OLAPKey: this.OLAPKey
            }
          },
          callBack: {
          }
        })
      } else {  //type==3
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '流量明细',
          moduleName: 'flowPacketDetailReport',
          data: {
            dataInfo: {
              StartTime: this.searchData.StartTime,
              EndTime: this.searchData.EndTime,
              HappenKey: 1,
              HappenKeyValue: '购买充值',
              OLAPKey: this.OLAPKey
            }
          },
          callBack: {
          }
        })
      }
    },
    goflowPacketDetail (item) {

      console.log("goDetail", item)
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '流量明细',
        moduleName: 'flowPacketDetailReport',
        data: {
          dataInfo: {
            StartTime: this.searchData.StartTime,
            EndTime: this.searchData.EndTime,
            HappenKey: 2,
            HappenKeyValue: '课件消耗',
            FlowPackagesTypeKey: item.ClientName == '通用流量' ? 1 : 2,
            FlowPackagesTypeKeyValue: item.ClientName == '通用流量' ? '通用' : '定向',
            OLAPKey: this.OLAPKey
          }
        },
        callBack: {
        }
      })
    }
  }
}
</script>
<style scoped>
.detail_item_text {
  color: #999;
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flow_packet_bill_contont_list {
  margin: 0 20px;
  font-size: 14px;
}
.flow_packet_bill_contont_list_content {
  display: flex;
  flex-direction: row;
  min-height: 102px;
  align-items: center;
}
.flow_packet_bill_img {
  flex: 1;
  height: 102px;
}
.flow_packet_bill_detail {
  flex: 3;
  align-items: center;
}
.detail_btn {
  position: absolute;
  top: 20px;
  right: 0;
  width: 90px;
  height: 28px;
  line-height: 28px;
  background: #f9f9f9;
  border-radius: 100px;
  cursor: pointer;
  text-align: center;
}
.flow_packet_bill_img.type_01 {
  background: url(../../../../../public/image/flow_packet_type_01.png) no-repeat
    center left;
  background-size: 60px;
}
.flow_packet_bill_img.type_02 {
  background: url(../../../../../public/image/flow_packet_type_02.png) no-repeat
    center left;
  background-size: 60px;
}
.flow_packet_bill_img.type_03 {
  background: url(../../../../../public/image/flow_packet_type_03.png) no-repeat
    center left;
  background-size: 60px;
}
.flow_packet_bill_img.type_04 {
  background: url(../../../../../public/image/flow_packet_type_04.png) no-repeat
    center left;
  background-size: 60px;
}
.detail_list {
  padding-left: 82px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.detail_list_item {
  margin-bottom: 16px;
}
.detail_list_item .next_step {
  background: url(../../../../../public/image/next_icon@2x.png) no-repeat center
    right;
  background-size: 8px;
  padding-right: 12px;
  text-align: right;
  width: 72px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>