<template>
  <div class="statistics_echarts_right fr">
    <div class="statistics_echarts_right_title">{{vTitle}}</div>
    <div class="statistics_echarts_right_content">
      <div class="statistics_echarts_right_list">
        <div class="list_left dot_green fl">
          <div class="font_top">最高</div>
          <div class="font_bottom">{{topInfoCount||'0'}}</div>
        </div>
        <div class="list_right fl">
          <div class="font_top">{{topMainDemoName}}</div>
          <div class="font_bottom">已上 <span>{{topInfoOnClass||0}}</span> 节课
          </div>
        </div>
      </div>
      <div class="statistics_echarts_right_list">
        <div class="list_left dot_blue fl">
          <div class="font_top">次高</div>
          <div class="font_bottom">{{secondInfoCount||'0'}}</div>
        </div>
        <div class="list_right fl">
          <div class="font_top">{{secondMainDemoName}}</div>
          <div class="font_bottom">已上 <span>{{secondInfoOnClass||0}}</span> 节课
          </div>
        </div>
      </div>
      <div class="statistics_echarts_right_list">
        <div class="list_left dot_yellow fl">
          <div class="font_top">最低</div>
          <div class="font_bottom">{{lastInfoCount||'0'}}</div>
        </div>
        <div class="list_right fl">
          <div class="font_top">{{lastInfoMainDemoName}}</div>
          <div class="font_bottom">已上 <span>{{lastInfoAlreadyCount||0}}</span> 节课
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'classStatistics',
  data () {
    return {

    };
  },
  props: ['lastInfo', 'statisticsList', 'type'],
  computed: {
    // 类名显示.
    vTitle () {
      if (this.type == 'attenType') {
        return '班级出勤率';
      } else {
        return '班级课时消耗';
      }
    },
    // 返回门店信息
    topMainDemoName () {
      if (this.statisticsList.length > 0) {
        return this.statisticsList[0].MainDemoName;
      } else {
        return '';
      }
    },
    // 最高信息
    topInfoCount () {
      if (this.statisticsList.length > 0) {
        if (this.type == 'attenType') {
          return this.statisticsList[0].AttendanceRate + '%';
        } else {
          return Number(this.statisticsList[0].ClassCount);
        }
      } else {
        return '';
      }
    },
    topInfoOnClass () {
      if (this.statisticsList.length > 0) {
        return Number(this.statisticsList[0].AlreadyCount);
      } else {
        return '';
      }
    },
    // 第二信息
    secondMainDemoName () {
      if (this.statisticsList.length > 1) {
        return this.statisticsList[1].MainDemoName;
      } else {
        return '';
      }
    },
    secondInfoCount () {
      if (this.statisticsList.length > 1) {
        if (this.type == 'attenType') {
          return this.statisticsList[1].AttendanceRate + '%';
        } else {
          return Number(this.statisticsList[1].ClassCount);
        }
      } else {
        return '';
      }
    },
    secondInfoOnClass () {
      if (this.statisticsList.length > 1) {
        return Number(this.statisticsList[1].AlreadyCount);
      } else {
        return '';
      }
    },
    // 最低信息.
    lastInfoCount () {
      if (this.lastInfo) {
        console.log(this.statisticsList);
        if (this.type == 'attenType') {
          return this.lastInfo.AttendanceRate + '%';
        } else {
          return Number(this.lastInfo.ClassCount);
        }
      } else {
        return '';
      }
    },
    // 最低已上节课
    lastInfoAlreadyCount () {
      if (this.lastInfo) {
        return Number(this.lastInfo.AlreadyCount);
      } else {
        return '';
      }
    },
    lastInfoMainDemoName () {
      if (this.lastInfo) {
        return this.lastInfo.MainDemoName;
      } else {
        return '';
      }
    }

  }
};
</script>

