<template>
  <div class="workbench_view_backlog massive_bg form_info_field">
    <div class="workbench_view_title">
      <span @click.stop="testYK">待办事项
        <input-form-tip :tips="'系统根据员工的角色权限分配给每个员工需要去处理的工作。'"></input-form-tip>
        <span class="renew_btn"
              @click.stop="renew">刷新</span>
      </span>
      <!-- <div class="backlog_refresh fr" @click="reload">刷新</div> -->
    </div>
    <div class="workbench_view_backlog_content">
      <div class="backlog_nav form_info_field">
        <div class="backlog_nav_list"
             :style="isSysVersionTY?{'width':'25%'}:''"
             v-for="(item,key) in vToDoList"
             :key="key"
             :class="indexType==item.TypeKey?'opt':''"
             @click="chgType(item)">
          <!-- {{item.TypeKeyValue=='上课'?'签到':item.TypeKeyValue}} -->
          {{item.value}}
          <span class="font_blue">{{Number(item.num)>99?99:item.num}}</span>
        </div>
      </div>
      <div v-if="dataList.length>0"
           style="position:relative">
        <div class="backlog_ul"
             v-if="indexType==4">
          <div class="backlog_list clearfix"
               v-for="(item,key) in dataList"
               :key="key"
               @click="workFlow(item,key)">
            <div class="backlog_list_img fl">
              <img :src="toDoType(indexType)"
                   alt />
            </div>
            <div class="backlog_list_text fl">
              <div :title="item.EventKeyValue+' '+item.Title">{{item.EventKeyValue+' '+item.Title}}</div>
              <div class="font_gray">{{setShowTime(item)}}</div>
            </div>
            <!-- 收支记录 -->
            <div class="backlog_list_btn btn_hover_white fr"
                 v-if="item.EventKey==2">处理</div>
            <div class="backlog_list_btn btn_hover_white fr"
                 v-if="item.EventKey==3">审核</div>
            <!-- 试课 -->
            <div class="backlog_list_btn btn_hover_white fr"
                 v-if="item.EventKey==4">审核</div>
            <!-- 试课 -->
            <div class="backlog_list_btn btn_hover_white fr"
                 v-if="item.EventKey==5">办理</div>
            <!-- 试课 -->
            <div class="backlog_list_btn btn_hover_white fr"
                 v-if="item.EventKey==6">审核</div>
            <div class="backlog_list_btn btn_hover_white fr"
                 v-if="item.EventKey==7">审核</div>
            <div class="backlog_list_btn btn_hover_white fr"
                 v-if="item.EventKey==8">审核</div>
            <div class="backlog_list_btn btn_hover_white fr"
                 v-if="item.EventKey==9">审核</div>
          </div>

        </div>
        <div class="backlog_ul pr"
             v-if="indexType==7"
             @scroll="scrollTOP">
          <div class="backlog_list clearfix"
               style="position:relative;height:61px"
               v-for="(item,key) in dataList"
               :key="item.EventKey"
               @mouseout="item.isShow=false">
            <div class="backlog_list_img fl">
              <img :src="toDoType(indexType)"
                   alt />
            </div>
            <div class="backlog_list_text fl">
              <div :title="item.Title">{{item.Title}}</div>
              <div class="font_gray">{{item.AppendTip}}</div>
            </div>
            <div class="backlog_list_btn btn_hover_white fr"
                 @click.stop="showDropDownBox(item,key)">办理</div>
            <!-- <div class="module_content_menuList"
                 style="top:40px;left:60%"
                 v-show="item.isShow"
                 @mouseover.stop="item.isShow=true">
              <div class="top_bg"
                   style="top:-2px"></div>
              <drop-down-box :optionJsonList="menuList"
                             @clickOptionForAlertDialog="clickAfter"></drop-down-box>
            </div> -->
          </div>
          <!-- <div class="module_content_menuList"
                   :style="{'top':topValue+'px','left':'60%'}"
                   v-show="isShowdropDownBox"
                   @mouseover.stop="isShowdropDownBox=true">
                <div class="top_bg"
                     style="top:-2px"></div>
              <drop-down-box :optionJsonList="menuList"
                                @clickOptionForAlertDialog="clickAfter"></drop-down-box>
          </div> -->
        </div>
        <function-btn v-if="indexType==2 && vNoAttenNum>=30"
                      :item="cleanAllbtn"
                      @clickItem="cleanAllCourse"
                      :className="'backlog_clearAll'">
          <span>一键清除全部未考勤学生</span>
          <span class="font_blue">一键清除</span>
        </function-btn>
        <!-- <div v-if="indexType==2 && vNoAttenNum>=30"
             @click.stop="cleanAllCourse"
             class="backlog_clearAll">
          <span>一键清除全部未考勤学生</span>
          <span class="font_blue">一键清除</span>
        </div> -->
        <div class="backlog_ul pr"
             v-if="indexType==2"
             @scroll="scrollTOP">

          <div class="backlog_list clearfix"
               v-for="(item,key) in dataList"
               :key="key">
            <!-- @click="showArrange(item,key)" -->
            <div class="backlog_list_img fl">
              <img :src="toDoType(indexType)"
                   alt />
            </div>
            <div class="backlog_list_text fl">
              <div :title="item.Title">{{item.Title}}</div>
              <div class="font_gray">{{setClassTime(item.otherData)}} {{setShowTime(item)}}</div>
            </div>
            <div class="backlog_list_btn btn_hover_white fr"
                 @click.stop="showDropDownBox(item,key)">办理</div>
          </div>
        </div>
        <div class="backlog_ul pr"
             v-if="indexType==5"
             @scroll="scrollTOP">
          <div class="backlog_list clearfix"
               v-for="(item,key) in dataList"
               :key="key">
            <!--       @click="verificationItem(item,key)" -->
            <div class="backlog_list_img fl">
              <img :src="toDoType(indexType)"
                   alt />
            </div>
            <div class="backlog_list_text fl">
              <div :title="item.Title">{{item.Title}}</div>
              <div class="font_gray">{{setMemberShowTime(item)}}</div>
            </div>
            <div class="backlog_list_btn btn_hover_white fr"
                 @click.stop="showDropDownBox(item,key)">办理</div>
          </div>
        </div>
        <div class="backlog_ul"
             v-if="indexType==6">
          <div class="backlog_list clearfix"
               v-for="(item,key) in dataList"
               :key="key"
               @click="showApplyCourse(item,key,'applyCourse')">
            <div class="backlog_list_img fl">
              <img :src="toDoType(indexType)"
                   alt />
            </div>
            <div class="backlog_list_text fl">
              <div :title="item.Title">{{item.Title}}</div>
              <div class="font_gray">{{setShowTime(item)}}</div>
            </div>
            <div class="backlog_list_btn btn_hover_white fr">详情</div>
          </div>
        </div>
        <div class="module_content_menuList"
             :style="{'top':topValue+'px','left':'68.5%'}"
             v-show="isShowdropDownBox"
             @mouseover.stop="isShowdropDownBox=true">
          <div class="top_bg"></div>
          <drop-down-box :optionJsonList="vMenuList"
                         @clickOptionForAlertDialog="clickAfter"></drop-down-box>
        </div>
      </div>
      <div v-if="dataList.length==0"
           class="backlog_ul"
           style="padding-top:70px">
        <div class="course_block_nodata ">
          <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
        </div>
      </div>
    </div>
    <!-- 未考勤模块弹出的。 -->
    <!-- <attendance-arrange ref="arrangeDialog"
                        :courseKey="seletedCourseInfo.ID"
                        :formTitle="'安排考勤'"
                        @updateCourse="reload"></attendance-arrange> -->
    <!-- 验证学生 -->
    <verify-student :studentKey="studentItem.ID"
                    :isEmit="true"
                    :Isverify="0"
                    ref="verifyFrom"></verify-student>
    <!-- 收支审核 -->
    <custom-dialog :title="formName"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isIncomeAndexpenses"
                   :before-close="IncomeAndexpensesSHow">
      <home-incomeAndexpenses-form :item="IncomeAndexpensesItem"
                                   @passHide="IncomeAndexpensesSHow"></home-incomeAndexpenses-form>
    </custom-dialog>
    <!-- 删除学生备注弹出 -->
    <div>
      <textarea-pop :title="'学生：'+studentItem.Title"
                    :message="''"
                    :placeholderText="'请输入删除原因...'"
                    ref="textareaPop"
                    @submitStudentArrangeSupport="submitDeleteStudent "></textarea-pop>
    </div>
  </div>
</template>
<script>
import homeIncomeAndexpensesForm from '../../../src/components/pop-up-menu/income-pay-manage/home-incomeAndexpenses-form';
import verifyStudent from '../../components/common/verify-student';
import dropDownBox from '../common/drop-down-box';
import textareaPop from '../common/textarea-pop';
import arrangeTryCourse from '../../components/pop-up-menu/arrange-try-course/index';
import attendanceArrange from '../pop-up-menu/exception-handle/attendance-arrange-form/attendance-arrange';
import {
  getTodoStatisticsByStaff,
  getTodoDetail,
  MessageSearchAuditForSZGL,
  MessageSearchDoForSZGL,
  setNoRerew, setLeaveSchool,
  RecycleStudent, ConfirmedStudentsMerge, webvCoursewareAuditPass, webvCoursewareRefuse, CWSvCoursewareAuditPass, CWSvCoursewareRefuse,
  CancelArrangeForNoAttendByCourse, CancelArrangeForAllNoAttendByCourse, WEBVAuditReject, WEBVAuditAdopt, agreeApply, refuseApply,
  RefuseRevokeCollectionRecordApply, AgreeRevokeCollectionRecordApply,
  GetMonitorInfo, MonitorOverlook
} from '../../API/workbench';
export default {
  data () {
    return {
      toDoList: [],
      indexType: 4,
      limit: 20,
      dataList: [],
      seletedCourseInfo: {}, // 未考勤，
      studentItem: {}, // 验证学生
      isIncomeAndexpenses: false, // 显示收支表单
      IncomeAndexpensesItem: {}, // 收支项
      formName: '', // 标题
      searchBoxShow: false,
      menuList: [
        {
          name: '续费',
          type: 'renew',
          isShow: true,
          ModulePowerKey: 50
        },
        {
          name: '离校',
          type: 'norenew',
          isShow: true,
          ModulePowerKey: 50
        },
        {
          name: '七天后提醒',
          type: 'delay',
          isShow: true,
          ModulePowerKey: 50
        }
      ],
      menuListForVerfire: [
        {
          name: '选老生合并',
          type: 'oldStudent',
          isShow: true,
          ModulePowerKey: 0
        },
        {
          name: '登记为新生',
          type: 'newStudent',
          isShow: true,
          ModulePowerKey: 0
        },
        {
          name: '删除',
          type: 'deleted',
          isShow: true,
          ModulePowerKey: 0
        }
      ],
      menuListFoNoAtten: [
        {
          name: '查看详情',
          type: 'atten',
          isShow: true,
          ModulePowerKey: 0
        },
        {
          name: '清除排课',
          type: 'cleanCourse',
          isShow: true,
          ModulePowerKey: 0
        },
        {
          name: '刷新',
          type: 'reloadNoAtten',
          isShow: true,
          ModulePowerKey: 0
        },
      ],
      courseWareMenuList: [
        {
          name: '查看详情',
          type: 'coursewaredetail',
          isShow: true,
          ModulePowerKey: 0
        },
        {
          name: '审核通过',
          type: 'coursewarepass',
          isShow: true,
          ModulePowerKey: 0
        },
        {
          name: '退回',
          type: 'coursewarereback',
          isShow: true,
          ModulePowerKey: 0
        }
      ],
      arrearsWriteoffMenuList: [
        {
          name: '审核通过',
          type: 'agreeApply',
          isShow: true,
          ModulePowerKey: 0
        },
        {
          name: '审核拒绝',
          type: 'refuseApply',
          isShow: true,
          ModulePowerKey: 0
        }
      ],
      CollectionRecordMenuList: [
        {
          name: '审核通过',
          type: 'agreeApplyDelete',
          isShow: true,
          ModulePowerKey: 0
        },
        {
          name: '审核拒绝',
          type: 'refuseApplyDelete',
          isShow: true,
          ModulePowerKey: 0
        }
      ],
      screenRecordMenuList: [
        {
          name: '忽略',
          type: 'refuseScreenRecord',
          isShow: true,
          ModulePowerKey: 0
        },

      ],
      todoListType: {
        gzl: '4', // 4-工作流
        verificationStudent: '5', // 5-待验证
        applyClass: '6', // 6-待报课
        leaveSchool: '7'// 7-续费
      },
      authMenuList: [
        {
          name: '审核通过',
          type: 'authPass',
          isShow: true,
          ModulePowerKey: 0
        },
        {
          name: '审核拒绝',
          type: 'authReject',
          isShow: true,
          ModulePowerKey: 0
        }
      ],
      isShowdropDownBox: false,
      isComfired: false,
      // isShowItem: false,
      newIndex: -1,
      topValue: 0, // 默认第一个top值
      toScrollTop: 0, // 滚动条的高度
      cleanAllbtn: {
        name: '',
        ModulePowerKey: 51
      }
    };
  },
  components: {
    attendanceArrange,
    verifyStudent,
    homeIncomeAndexpensesForm,
    arrangeTryCourse,
    dropDownBox,
    textareaPop
  },
  created () {
    console.log('created看板');
    this.loadData();
  },
  computed: {
    vMenuList () {
      if (this.indexType == 2) {
        return this.menuListFoNoAtten;
      } else if (this.indexType == 5) {
        return this.menuListForVerfire;
      } else if (this.indexType == 4) {
        if (this.studentItem.EventKey == 6) {
          return this.authMenuList;
        } else if (this.studentItem.EventKey == 7) {
          return this.arrearsWriteoffMenuList;
        } else if (this.studentItem.EventKey == 8) {
          return this.CollectionRecordMenuList;
        } else if (this.studentItem.EventKey == 9) {
          return this.screenRecordMenuList;
        } else {
          return this.courseWareMenuList;
        }
      } else {
        return this.menuList;
      }
    },
    isSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    },
    vToDoList () {
      // 显示的长度.
      let list = [];
      this.toDoList.forEach(o => {
        this.sortTodoList(o);
        o.newOne = o.newOne || {};
        if (o.newType != 6) {
          if (o.newType == 4 && this.isSysVersionTY) {

          } else if (o.newType == 8) {

          } else {
            list.push(o);
          }
        }
      });
      list.sort((x, y) => {
        return x.newType - y.newType;
      });
      return list;
    },
    // 未考勤学生数
    vNoAttenNum () {
      let findItem = this.toDoList.find(todoItem => { return todoItem.TypeKey == 2; });
      return Number(findItem.num);
    },
    vRolePowerList () {
      return this.$store.getters.RolePowerList;
    }

  },
  mounted () {
    this.registerBusEvent();
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isShowdropDownBox = false;
    }, true);
  },
  methods: {
    renew () {
      this.loadData();
    },
    loadData () {
      this.getTodoStatistics();
      this.getDetailList(); // 初次加载, 1-上课;2-未考勤;3-通知;4-工作流;5-待验证;6-待报课;7-续费
    },
    scrollTOP (e) {
      this.toScrollTop = e.target.scrollTop;
      if (e.target.scrollTop > 0) {
        this.isShowdropDownBox = false;
      }
    },

    // 计算TOP值
    doOneTopValueCount (index) {
      console.log(index, 'index');
      this.newIndex = index;
      this.topValue = 0;
      let oneTopValue = 61;
      if (this.toScrollTop > 0) {
        this.topValue = oneTopValue * this.newIndex - this.toScrollTop + this.topValue + 42;
      } else {
        this.topValue = this.topValue + (oneTopValue * this.newIndex) + 42;
      }
      if (this.indexType == 2 && this.vNoAttenNum >= 30) {
        this.topValue += 20;
      }
    },
    // 延期
    setNoRerewByID () {
      setNoRerew(this.studentItem.ID, 7).then(result => {
        layer.alert(this.studentItem.otherData.MainDemoName + '成功延期七天');
      }).catch(error => {
        layer.alert(error.msg);
        console.log(error, 'setNoRerewByID Error');
      });
    },
    // 取消续费
    setLeaveSchoolByID () {
      if (this.isComfired) {
        return false;
      } else {
        this.isComfired = true;
        setLeaveSchool(this.studentItem.ID).then(result => {
          this.isComfired = false;
          layer.alert(this.studentItem.otherData.MainDemoName + '离校成功');
        }).catch(error => {
          layer.alert(error.msg);
          this.isComfired = false;
          console.log(error, 'setLeaveSchoolByID Error');
        });
      }
    },
    showDropDownBox (item, key) {
      this.dataList.forEach(o => {
        if (o.ID == item.ID) {
          o.isShow = true;
        } else {
          o.isShow = false;
        }
      });
      this.studentItem = item;
      this.isShowdropDownBox = true;
      this.doOneTopValueCount(key);
      console.log(this.isShowdropDownBox, item, key, 'isShowdropDownBox');
    },
    clickAfter (item) {
      this.dataList.forEach(o => {
        o.isShow = false;
      });
      this.isShowdropDownBox = false;
      switch (item.type) {
        case 'renew':
          let obj = {
            StudentKey: this.studentItem.ID,
            isReadOnly: false,
            TableID: ''
          };
          this.$dialog.open(this, {
            name: '报名收款',
            routerName: this.$route.name,
            moduleName: 'signuppayment',
            moduleDescription: '学员报名购买课程，如未登记的学生可点界面“+”新增学生信息后再进行报名。',
            data: { dataInfo: obj },
            callback: {}
          });
          break;
        case 'norenew':
          layer.confirm(this.studentItem.otherData.MainDemoName + '是否离校？', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.setLeaveSchoolByID();
                }
              }
            ]
          });

          break;
        case 'delay':
          layer.confirm(this.studentItem.otherData.MainDemoName + '是否延期七天通知？', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.setNoRerewByID();
                }
              }
            ]
          });
          break;
        case 'oldStudent':
          this.mergeStudentVerifyForm();
          break;
        case 'newStudent':
          this.confirmStudentVerifyForm();
          break;
        case 'deleted':
          this.$refs.textareaPop.textSupport = '';
          this.$refs.textareaPop.isAlterMessagePopup = true;
          break;
        case 'atten':
          this.showArrange(this.studentItem);
          break;
        case 'cleanCourse':
          console.log('cleanCourse');
          this.cleanCourse(this.studentItem.ID);
          break;
        case 'reloadNoAtten':
          console.log('reloadNoAtten');
          this.reload()
          break;
        case 'coursewaredetail':
          console.log('coursewaredetail', this.$zxClient);
          if (this.$zxClient.IsClient) {
            let routerItem = {
              'name': '',
              'routerName': 'generalCoursewareManagement',
              'routertitle': '自研课件管理',
              'auth': true,
              'roleName': '',
              'ModulePowerKey': 118,
              'moduleName': '',
            };
            console.log()
            this.goRouter(routerItem);
          } else {
            layer.alert('请使用客户端');
          }
          break;
        case 'coursewarepass':
          console.log('coursewarepass');
          this.courseWarePass(this.studentItem.ID);
          break;
        case 'coursewarereback':
          console.log('coursewarereback');
          this.courseWareRefuse(this.studentItem.ID);
          // this.cleanCourse(this.studentItem.ID);
          break;
        case 'authPass':
          console.log('authPass');
          this.authPass(this.studentItem.ID);
          break;
        case 'authReject':
          console.log('authReject');
          this.authReject(this.studentItem.ID);
          // this.cleanCourse(this.studentItem.ID);
          break;
        case 'agreeApply':
          console.log('agreeApply');
          this.agreeApply(this.studentItem.ID);
          break;
        case 'refuseApply':
          console.log('refuseApply');
          this.refuseApply(this.studentItem.ID);
          break;
        case 'agreeApplyDelete':
          console.log('agreeApplyDelete');
          this.agreeApplyDelete(this.studentItem.ID);
          break;
        case 'refuseApplyDelete':
          console.log('refuseApplyDelete');
          this.refuseApplyDelete(this.studentItem.ID);
          break;
        case 'refuseScreenRecord':
          console.log('refuseScreenRecord');
          this.refuseScreenRecord(this.studentItem.ID);
          break;
        default:
          break;
      }
    },
    refuseScreenRecord () {
      layer.confirm('请确定是否忽略该项?', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              layer.close();
              // GetMonitorInfo(this.studentItem.ID,0).then(result => {
              // }, error => {
              //   layer.alert(error.msg);
              // });
              MonitorOverlook(this.studentItem.ID, 0).then(result => {
                this.loadData();
              }, error => {
                layer.alert(error.msg);
              });
            }
          }
        ]
      });

    },
    // 将指定课的所有未考勤的学生取消排课
    cleanCourse (CourseKey) {
      console.log("cleanCourse", CourseKey)
      this.authToDo(51, () => {
        layer.confirm('请确认是否要清除全部未考勤学生?', {
          btn: [{
            name: '确认',
            callBack: () => {
              CancelArrangeForNoAttendByCourse(this, CourseKey).then(result => {
                // layer.alert('清除排课成功');
                this.locationDelete();
              }).catch(error => {
                layer.alert(error.msg);
              });
              layer.close();
            }
          }
          ]
        });
      });
    },
    goRouter (item) {
      let isNew = this.$store.getters.headerBarList.findIndex(o => {
        return o.routerName == item.routerName
      })
      // dataKey
      item.data = {
        levelPath: this.studentItem.AppendTip,
        Title: this.studentItem.Title,
        isNew: isNew == -1

      }
      console.log(isNew, "this.$store.getters.headerBarList", this.$store.getters.headerBarList)
      item.dataKey = this.studentItem.ID;
      item.ModulePowerKey = 118;
      console.log(item, 'goRouter');
      this.$bus.emit('needOpenRouter', item);
    },
    // 一次清除所有学生
    cleanAllCourse () {
      layer.confirm('请确认是否要清除全部未考勤学生', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              layer.close();
              CancelArrangeForAllNoAttendByCourse(this).then(result => {
                this.$bus.emit('allCourseQuit');
                this.loadData();
              }).catch(error => {
                layer.alert(error.msg);
              });
            }
          }
        ]
      });
    },
    authToDo (ModulePowerKey, callback) {
      if (this.vHasModulePower(ModulePowerKey)) {
        callback();
      } else {
        this.$utils.CheckModulePowerToTips(ModulePowerKey);
      }
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vRolePowerList, ModulePowerKey);
    },
    // 工作流操作.
    workFlow (item, index) {
      // this.authToDo(42, () => {
      if (item.EventKey == 2 || item.EventKey == 3) {
        this.workEventSZItem(item, index);
      } else if (item.EventKey == 4) {
        this.workEventTryCourseItem(item, index);
      } else if (item.EventKey == 5) {
        this.showDropDownBox(item, index);
      } else if (item.EventKey == 6) {
        this.showDropDownBox(item, index);
        // this.toAuthManager(item)
      } else if (item.EventKey == 7) {
        this.showDropDownBox(item, index);
      } else if (item.EventKey == 8) {
        this.showDropDownBox(item, index);
      } else if (item.EventKey == 9) {
        this.showDropDownBox(item, index);
      }
      // });
    },
    // 去授权管理
    toAuthManager (item) {
      //  this.seletedItem=item
      this.$dialog.open(this, {
        name: '授权设备管理',
        routerName: this.$route.name,
        routertitle: '工作台',
        ModulePowerKey: '',
        moduleName: 'cwsAuthManagerList',
        data: {
          dataInfo: {
            CoursewarePackageKey: item.ID,
            type: 'WEBV'
            // CoursewarePackageKeyValue:item.CoursewarePackageKeyValue,
          }
        },
        callBack: {
          afterSuccess: (data) => { this.reload(); }
        }
      });
    },
    // 工作流- 课件审核显示
    courseWarePass () {
      let apiFN = '';
      let type = this.$store.getters.SaaSClientInfo.EditionKey;
      if (type == 6) {
        apiFN = CWSvCoursewareAuditPass;
      } else {
        apiFN = webvCoursewareAuditPass;
      }
      apiFN(this.studentItem.ID).then(result => {
        this.loadData();
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    // 工作流- 课件审核显示
    courseWareRefuse () {
      let apiFN = '';
      let type = this.$store.getters.SaaSClientInfo.EditionKey;
      if (type == 6) {
        apiFN = CWSvCoursewareRefuse;
      } else {
        apiFN = webvCoursewareRefuse;
      }
      apiFN(this.studentItem.ID).then(result => {
        this.loadData();
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    // 工作流- 授权审核通过
    authPass () {
      WEBVAuditAdopt(this.studentItem.ID).then(result => {
        this.loadData();
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    // 工作流- 授权审核拒绝
    authReject () {
      // webvCoursewareRefuse
      WEBVAuditReject(this.studentItem.ID).then(result => {
        this.loadData();
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    // 工作流- 欠款销账审核通过
    agreeApply () {
      agreeApply(this.studentItem.ID).then(result => {
        this.loadData();
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    // 工作流- 欠款销账审核拒绝
    refuseApply () {
      refuseApply(this.studentItem.ID).then(result => {
        this.loadData();
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    // 工作流- 作废单据审核通过
    agreeApplyDelete () {
      AgreeRevokeCollectionRecordApply(this.studentItem.ID).then(result => {
        this.loadData();
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    // 工作流- 作废单据审核拒绝
    refuseApplyDelete () {
      RefuseRevokeCollectionRecordApply(this.studentItem.ID).then(result => {
        this.loadData();
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    // 工作流-试课
    workEventTryCourseItem (item, index) {
      console.log(item, 'workEventTryCourseItem');
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'arrangeCourse',
        data: { dataInfo: item.otherData },
        callBack: { afterSuccess: () => { this.reload(); } }
      });
    },
    // 工作流-收支
    workEventSZItem (item, index) {
      this.isIncomeAndexpenses = true;
      // 待审核查询接口
      if (item.EventKey == 3) {
        this.formName = '待审核';
        MessageSearchAuditForSZGL().then(result => {
          this.IncomeAndexpensesItem = result.data.find(obj => {
            return item.ID == obj.TableID;
          });
          this.IncomeAndexpensesItem.HapPeolKeyValue = this.IncomeAndexpensesItem.HapPeolKeyValue.split('(')[0];
        });
      } else {
        // 待处理查询接口
        this.formName = '待处理';
        MessageSearchDoForSZGL().then(result => {
          this.IncomeAndexpensesItem = result.data.find(obj => {
            return item.ID == obj.TableID;
          });
          this.IncomeAndexpensesItem.HapPeolKeyValue = this.IncomeAndexpensesItem.HapPeolKeyValue.split('(')[0];
        });
      }
    },
    // 收支表单
    IncomeAndexpensesSHow () {
      this.isIncomeAndexpenses = false;
    },
    // 验证学生项
    verificationItem (item, index) {
      this.authToDo(48, () => {
        if (this.$refs.verifyFrom) {
          this.$refs.verifyFrom.studentVerifyForm(item.ID);
        }
        this.studentItem = item;
      });
    },
    // 显示考勤。
    showArrange (item, index) {
      this.seletedCourseInfo = item;
      this.authToDo(51, () => {
        // this.$refs.arrangeDialog.isAttendanceArrangeShow = true;
        let moduleInfo = {
          name: '课程详情',
          moduleName: 'attendanceArrange',
          routerName: this.$route.name,
          data: { scheduleCourseKey: this.seletedCourseInfo.ID }
        };
        this.$bus.emit('openCustomDialog', moduleInfo);
        // this.$refs.arrangeDialog.getCourseInfo(this.seletedCourseInfo.ID);
      });
    },
    showApplyCourse (item, index, moduleName) {
      this.authToDo(51, () => {
        let obj = item.otherData;
        this.$dialog.open(this, {
          name: '课单报课',
          routerName: this.$route.name,
          moduleName: 'courseIDApplyList',
          data: { dataInfo: obj },
          callBack: { afterSucces: () => { this.reload(); } }
        });
      });
    },
    // 弹出层
    doAfterClickFunctionBtn (moduleName) {
      let moduleInfo = {
        routerName: this.$route.name,
        moduleName: moduleName
      };
      this.$bus.emit('needOpenRouter', moduleInfo);
    },

    // 工作流显示时间
    setShowTime (item) {
      return this.$utils.StrForTodoTime(item.GenerateTime);
    },
    // 签到显示时间
    setSignTime (item) {
      if (item.GenerateTime) {
        return (
          this.$utils.formatDateStr(item.GenerateTime, 'yyyy') +
          '年' +
          this.$utils.formatDateStr(item.GenerateTime, 'MM') +
          '月' +
          this.$utils.formatDateStr(item.GenerateTime, 'ddddd')
        );
      } else {
        return '';
      }
    },
    // 签到显示时间
    vclassTime (item) {
      if (
        item.otherData &&
        item.otherData.BeginClassTime &&
        item.otherData.EndClassTime
      ) {
        return (
          item.otherData.BeginClassTime.substring(11, 16) +
          '-' +
          item.otherData.EndClassTime.substring(11, 16)
        );
      } else {
        return '';
      }
    },
    // 未签到显示时间
    setClassTime (item) {
      if (item && item.BeginClassTime && item.EndClassTime) {
        return (
          this.$utils.formatDateStr(item.BeginClassTime, 'ddddd') +
          ' ' +
          this.$utils.formatDateStr(item.BeginClassTime, 'HH:mm') +
          '-' +
          this.$utils.formatDateStr(item.EndClassTime, 'HH:mm')
        );
      } else {
        return '';
      }
    },
    setMemberShowTime (item) {
      return (
        this.$utils.formatDateStr(item.GenerateTime, 'ddddd') +
        ' ' +
        this.$utils.formatDateStr(item.GenerateTime, 'HH:mm:ss') +
        ' ' +
        this.$utils.formatDateStr(item.GenerateTime, 'MM') +
        '月' +
        this.$utils.formatDateStr(item.GenerateTime, 'dd') +
        '日'
      );
    },
    // 重新加载
    reload () {
      this.getDetailList();
      this.getTodoStatistics();
    },
    // 获取员工的待办事项统计.
    getTodoStatistics () {
      getTodoStatisticsByStaff().then(result => {
        this.toDoList = result.data; // type==3为通知,去掉
        console.log('getTodoStatisticsByStaff', result.data);
      });
    },
    // 判断类型.
    toDoType (type) {
      let url = '';
      if (type == 1) {
        url = require('../../../public/image/schedule_view_icon01@2x.png');
      } else if (type == 2) {
        url = require('../../../public/image/schedule_view_icon03@2x.png');
      } else if (type == 3) {
        url = require('../../../public/image/schedule_view_icon06@2x.png');
      } else if (type == 4) {
        url = require('../../../public/image/schedule_view_icon01@2x.png');
      } else if (type == 5) {
        url = require('../../../public/image/schedule_view_icon04@2x.png');
      } else if (type == 6) {
        url = require('../../../public/image/schedule_view_icon05@2x.png');
      } else if (type == 7) {
        url = require('../../../public/image/schedule_view_icon07@2x.png');
      }
      return url;
    },
    sortTodoList (item) {
      switch (String(item.TypeKey)) {
        // 要过滤掉。
        case '8':
          item.newType = 8;
          item.authCode = 0;
          item.pathName = 'autowarning';
          item.value = '智能预警';
          break;
        case '7':
          item.newType = 7;
          item.authCode = 50;
          item.pathName = 'renew';
          item.value = '续费';
          break;
        case '2':
          item.newType = 2;
          item.authCode = 51;
          item.pathName = 'noAttendance';
          item.value = '未考勤';
          break;
        case '3':
          item.newType = 6;
          item.authCode = 0;
          item.pathName = 'notification';
          item.value = '通知';
          break;
        case '4':
          item.newType = 1;
          item.authCode = 43;
          item.pathName = 'workflow';
          item.value = '工作流';
          break;
        case '5':
          item.newType = 3;
          item.authCode = 48;
          item.TypeKeyValue = '验证';
          item.pathName = 'member';
          item.value = '注册';
          break;
        case '6':
          item.newType = 4;
          item.authCode = 51;
          item.pathName = 'applyClass';
          item.value = '报课';
          break;
        default:
          break;
      }
    },
    chgType (item) {
      console.log(item.TypeKey);
      this.isShowdropDownBox = false;
      this.toScrollTop = 0;
      this.getDetailList(item.TypeKey, () => {
        this.$nextTick(() => {
          document.getElementsByClassName('backlog_ul')[0].scrollTop = 0;
          console.log('this.indexType', item);
          this.indexType = item.TypeKey;
        });
      });
    },
    getDetailList (TypeKey, callback) {
      getTodoDetail(TypeKey || this.indexType, 0, this.limit).then(result => {
        result.data.PageDataList.sort((x, y) => {
          return Date.new(y.GenerateTime) - Date.new(x.GenerateTime);
        });
        result.data.PageDataList.forEach(o => {
          o.isShow = false;
        });
        this.dataList = result.data.PageDataList;
        console.log(result.data.PageDataList, 'result.data.PageDataList');
        if (callback) {
          callback();
        }
      });
    },
    // 长连接通知
    // 示例：IncomePayToDo-收支待处理; IncomePayToAudited-收支待审核;IncomePayAudited-收支已审核;TryClassApplay-试课申请;
    // TryClassArranged-试课追销;UnConfirmedStudent-待验证学生;ConfirmedStudent-已验证学生;TobePlanedStudent-待报课学生；
    websocketNotice (data) {
      let findItem = this.toDoList.find(todoItem => { return todoItem.TypeKey == data.newData.TypeKey; });
      let findIndex = this.dataList.findIndex(obj => { return obj.ID == data.newData.ID; });
      // 当前显示的跟变更的不一致 只更新titleBar数量
      if (this.indexType != data.newData.TypeKey) {
        this.getTodoStatistics();
      } else {
        // 找不到 push一条
        if (findIndex == -1) {
          if (data.newData.TypeKey == this.todoListType.leaveSchool && data.newData.otherData.OperationKey == 2) { // 离校 续费办结的不理会
            return;
          } else if (data.newData.TypeKey == this.todoListType.verificationStudent && data.newData.otherData.IsConfirmKey == 1) { // 已验证的不理会
            return;
          }
          this.dataList.unshift(data.newData);
          findItem.num = Number(findItem.num) + 1;
        } else {
          switch (data.newData.TypeKey) {
            case this.todoListType.gzl:// 工作流
              if (this.dataList[findIndex].EventKey == 2 && data.newData.otherData.StatusKey == 3) {
                this.dataList.splice(findIndex, 1, data.newData);
              } else {
                this.updateTodoList(findIndex, findItem);
              }
              break;
            case this.todoListType.verificationStudent:// 验证
            case this.todoListType.leaveSchool:// 离校（续费）
              this.updateTodoList(findIndex, findItem);
              break;
            case this.todoListType.applyClass:// 待报课
              // 没有待报 直接删除
              if (findIndex > -1) {
                if (data.newData.otherData.waitPlanCount == 0) {
                  this.updateTodoList(findIndex, findItem);
                } else { // 还有待报 直接把之前的给替换了
                  this.dataList.splice(findIndex, 1, data.newData);
                }
              }
              break;
            default:
              break;
          }
        }
      }
    },
    updateTodoList (findIndex, findItem) {
      this.dataList.splice(findIndex, 1);
      findItem.num = Number(findItem.num) - 1;
      this.getDetailList();
    },
    // 合并为老生
    mergeStudentVerifyForm () {
      this.$emit('closeVerifyPopup');
      this.$dialog.open(this, {
        name: '选老生合并',
        routerName: this.$route.name,
        routertitle: '',
        ModulePowerKey: 48,
        moduleName: 'mergeStudent',
        data: { studentKey: this.studentItem.ID }
      });
    },
    // 登记为新生
    confirmStudentVerifyForm () {
      ConfirmedStudentsMerge(this.studentItem.ID).then(result => {
        layer.alert('验证成功');
      });
    },
    // 确定删除学生操作
    submitDeleteStudent (messageText, callBack) {
      if (this.studentItem.ID) {
        RecycleStudent(this.studentItem.ID, messageText).then(result => {
          // this.closeRegisterCustomerDialog();
          this.$refs.textareaPop.isAlterMessagePopup = false;
          this.locationDelete();
          layer.alert('删除成功');
          callBack();
        }, error => {
          layer.alert(error.msg);
        });
      }
      // layer.alert('缺少删除学生接口！');
    },
    locationDelete () {
      let findItem = this.toDoList.find(todoItem => { return todoItem.TypeKey == this.indexType; });
      let locaIndex = this.dataList.findIndex(o => {
        return o.ID == this.studentItem.ID;
      });
      console.log(locaIndex, 'locaIndex', this.studentItem.ID);
      this.dataList.splice(locaIndex, 1);
      findItem.num = Number(findItem.num) - 1;
    },
    registerBusEvent () {
      this.$bus.on(this, 'wsTodoList', (data) => {
        this.websocketNotice(data);
      });
      // this.$bus.on(this, 'switchHeaderBarToIndexView', () => {
      //   console.log("switchHeaderBarToIndexView工作台")
      //   if(this.$route.name=='indexView'){
      //     this.loadData();
      //   }
      // });
      // this.$bus.on(this, 'switchHeaderBarToPresidentKanban', () => {
      //    console.log("switchHeaderBarToPresidentKanban看板")
      //   if(this.$route.name=='presidentKanban'){
      //     this.loadData();
      //   }
      // });
      this.$bus.on(this, 'wsAfterUpdateRole', () => {
        this.loadData();
      });
      this.$bus.on(this, 'todayQuitAll', (data) => {
        console.log('todayQuitAll');
        if (this.indexType == 2) {
          this.getTodoStatistics();
          this.getDetailList(); // 初次加载, 1-上课;2-未考勤;3-通知;4-工作流;5-待验证;6-待报课;7-续费
        }
      });
      // 添加学生进课
      this.$bus.on(this, 'AfterStudentAddToCourse', (scheduleCourseKey, studentKey, courseStatusKey) => {
        if (courseStatusKey == 4) {
          this.getTodoStatistics();
          if (this.indexType == 2) {
            this.getDetailList(); // 初次加载, 1-上课;2-未考勤;3-通知;4-工作流;5-待验证;6-待报课;7-续费
          }
        }
      });
      // 变更考勤
      this.$bus.on(this, 'AfterChangeAttendanceStatus', (scheduleCourseKey, studenID, courseStatusKey) => {
        // if (courseStatusKey == 4) {
        this.getTodoStatistics();
        if (this.indexType == 2) {
          this.getDetailList(); // 初次加载, 1-上课;2-未考勤;3-通知;4-工作流;5-待验证;6-待报课;7-续费
        }
        // }
      });
      // 批量考勤
      this.$bus.on(this, 'AfterBatchAttendance', (scheduleCourseKey, courseInfo) => {
        if (courseInfo.StatusKey == 4) {
          this.getTodoStatistics();
          if (this.indexType == 2) {
            this.getDetailList(); // 初次加载, 1-上课;2-未考勤;3-通知;4-工作流;5-待验证;6-待报课;7-续费
          }
        }
      });
    }

  }
};
</script>

