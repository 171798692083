<template>
  <div class="apply_payment_details_box massive_bg is_class_count_amount fl">
    <div class="apply_payment_box_title">
      {{formTitle}}
    </div>
    <div class="apply_payment_box_number box_dot dot_green">
      <span>￥</span>{{DeductAmount}}
    </div>
    <div class="apply_payment_box_singular box_dot dot_blue">
      {{secondTitle}}
      <span>{{DeductCount}}</span> 课时
    </div>
    <div class="apply_payment_box_singular box_dot dot_yellow">
      {{thirdTitle}}
      <span>{{count}}</span> {{lastTitle}}
    </div>
    <div @click.stop="showDetail"
         class="apply_payment_box_btn">
      本月详情
    </div>
  </div>
</template>
<script>
export default {
  name: 'bottomStatistics',
  data () {
    return {
    };
  },
  props: {
    formTitle: String,
    secondTitle: String,
    thirdTitle: String,
    dataInfo: Object,
    lastTitle: String
  },
  computed: {
    DeductAmount () {
      if (this.dataInfo) {
        return (Math.round(Number(this.dataInfo.DeductAmount) * 100) / 100).toFixed(2) || 0;
      }
    },
    DeductCount () {
      if (this.dataInfo) {
        return Number(this.dataInfo.DeductCount).toFixed(1) || 0;
      }
    },
    count () {
      if (this.dataInfo) {
        return this.dataInfo.Number || 0;
      }
    }
  },
  methods: {
    showDetail () {
      this.$emit('showDetail');
    }
  }
};
</script>
