<template>
  <div class="WKT_course_info_popup_list">
    <div class="WKT_course_info_list">
      <div class="WKT_course_info_block">
        <div class="WKT_course_info_name">
          <span>{{item.CourseKeyValue}}</span>
        </div>
        <div class="WKT_course_info_text"
             :id="vCourseInfoTestID"
             v-if="isShowAll">
          <span class="WKT_course_content"
                :id="vCourseContentID">{{item.CourseIntro}}</span>
          <div class="WKT_course_info_text_btn"
               v-if="isShowOpenBtn"
               @click.stop="isShowOpenBtn=false,isShowPutItAwayBtn = true,isShowAll=false">
            展开
          </div>
        </div>
        <div class="WKT_course_info_text_unfold"
             :id="vCourseInfoTestID"
             v-if="isShowPutItAwayBtn">
          <span class="WKT_course_content"
                :id="vCourseContentID">{{item.CourseIntro}}</span>
          <div class="WKT_course_info_text_btn"
               @click.stop="isShowOpenBtn=true,isShowPutItAwayBtn = false,isShowAll=true">
            收起
          </div>
        </div>
        <div class="WKT_course_info_imgList">
          <enable-item-img v-for="(imgItem,key) in item.ImgList"
                           :key="key"
                           :imgItem="imgItem">
          </enable-item-img>
        </div>
        <div class="WKT_course_info_date"
             >
          <span v-if="item.ClassTime">上课时间：{{item.ClassTime}}</span>
        </div>
      </div>
      <div class="WKT_information_openBtn btn_hover_bg_blue"
           @click.stop="enable">
        启用
      </div>
    </div>
  </div>
</template>
<script>
import enableItemImg from '../enable-item-img';
export default {
  name: 'singleSelectionItem',
  data () {
    return {
      isShowAll: true, // 默认显示全部
      isShowOpenBtn: false, // 展开
      isShowPutItAwayBtn: false // 收起
    };
  },
  components: {
    enableItemImg
  },
  props: {
    item: Object,
    index: Number
  },
  computed: {
    vHot () {
      return this.index <= 1 ? 'hot_icon' : '';
    },
    vCourseIntro () {
      if (this.item.CourseIntro.length > 60) {
        if (this.showMore) {
          return this.item.CourseIntro;
        } else {
          return this.item.CourseIntro.substring(0, 60);
        }
      } else {
        return this.item.CourseIntro;
      }
    },
    vCourseInfoTestID () {
      return 'WKT_course_info_text_' + this.item.CourseKey;
    },
    vCourseContentID () {
      return 'WKT_course_content_' + this.item.CourseKey;
    }
  },
  mounted () {
    this.caculationContentHeight();
  },
  methods: {
    caculationContentHeight () {
      setTimeout(() => {
        if (document.getElementById(this.vCourseInfoTestID)) {
          const contentHeight = document.getElementById(this.vCourseInfoTestID).scrollHeight; // 内容告诉-padding
          const viewHeight = document.getElementById(this.vCourseInfoTestID).offsetHeight; // 可视高度
          const innerText = document.getElementById(this.vCourseContentID).innerText; // 渲染内容
          console.log(contentHeight, viewHeight, 'contentHeight,viewHeight');
          console.log('innerText:' + innerText, 'CourseIntro:' + this.item.CourseIntro);
          if (!innerText) { // 拿到的innerText与实际绑定的内容不等，说明数据渲染还没有完成，则在等200毫秒
            this.caculationContentHeight();
            return;
          }
          if (contentHeight <= viewHeight) {
            this.isShowOpenBtn = false;
          } else {
            this.isShowOpenBtn = true;
          }
        }
      }, 200);
    },
    enable () {
      this.$emit('enable', this.item);
    }
  }
};
</script>

