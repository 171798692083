<template>
	<div class="bgGray_nav_list cp" :class="{opt:itemData.isSelection}" @click="clickEvent">
		{{itemData.value}}
	</div>
</template>

<script>

export default {
  data () {
    return {
    };
  },
  props: {
    itemData: {
      type: Object,
      default: function () {
        return {
          key: 0,
          value: '',
          isSelection: false // 是否选中
        };
      }
    }
  },
  methods: {
    clickEvent () {
      this.$set(this.itemData, 'isSelection', true);
      this.$emit('click', this.itemData);
    }
  }
};
</script>

  <style>
</style>
