<template>
  <div>
    <div class="add_screening_condition_box fl ">
      <span>{{dataModel.formTitle}} : </span>
      <input type="text"
             name=""
             readonly
             :value="vShowText"
             @click.stop="chooseCourseTypeName">
    </div>
    <!-- 选择课程-->
    <div>
      <custom-dialog title="选择课程"
                     class="refund_dialog defult_dialog"
                     :visible.sync="isShowSingleSelectTree"
                     :before-close="closeDialogForcourseTypeName">
        <div>
          <single-selection-list isShowSearchInput="false"
                                 :dataSourceList="dataModel.fieldList[0].sourceData"
                                 :isShowUnChoose="false"
                                 @onSelectedItem="confirmChooseCourseTypeName"></single-selection-list>
        </div>
      </custom-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isShowSingleSelectTree: false,
      selectedData: { id: 0, label: '' }
    };
  },
  model: {
    prop: 'dataModel'
  },
  props: {
    dataModel: Object
  },
  created () {
  },
  methods: {
    // 选择课名
    chooseCourseTypeName () {
      this.isShowSingleSelectTree = true;
    },
    // 确定 选择课名
    confirmChooseCourseTypeName (selectionData) {
      this.closeDialogForcourseTypeName();
      this.dataModel.fieldList[0].value = selectionData.OLAPKey;
      this.selectedData = selectionData;
    },
    // 取消 选择课名
    closeDialogForcourseTypeName () {
      this.isShowSingleSelectTree = false;
    }
  },
  computed: {
    vShowText () {
      let findItem = this.dataModel.fieldList[0].sourceData.find(o => o.OLAPKey == this.dataModel.fieldList[0].value);
      if (findItem) {
        this.selectedData.id = findItem.OLAPKey;
        this.selectedData.label = findItem.MainDemoName;
      } else {
        this.selectedData.id = 0;
        this.selectedData.label = '';
      }
      return this.selectedData.label;
    }
  }
};
</script>
