<template>
  <div class="checkbox_list" :class="{opt:item.isSelected}" @click="clickItem">
    {{item.name}}
  </div> 
</template>

<script>
export default {
props:{
  item:Object,
  parentList:Object
},
methods:{
  clickItem(){
    if(this.parentList.isSingleChoose){
      this.parentList.childrenList.forEach(obj => {
        if(obj.field == this.item.field){
          this.$set(obj,'isSelected',!obj.isSelected)
        }else{
          this.$set(obj,'isSelected',false)
        }
      });
      console.log()
    }else{
      this.$set(this.item,'isSelected',!this.item.isSelected)
    }
    this.$emit('clickItem')
  }
}
}
</script>

<style>

</style>