<template>
  <div class="homerwork_details_ul">
    <item v-for="(item,key) in workStudentList"
          :key="key"
          :item="item"
          @doWorkCorrectDetailsClick="doWorkCorrectDetailsClick"></item>
    <div v-if="workStudentList.length == 0">
      <basics-nodata-tips></basics-nodata-tips>
    </div>
  </div>
</template>

<script>
import item from './item.vue';

export default {
  components: {
    item
  },
  props: {
    workStudentList: Array,
    scheduleCourseID: [Number, String]
  },
  data () {
    return {

    };
  },
  mounted () {
    // this.$dialog.register(this, ['taskCorrectDetails']);
  },
  methods: {
    // 去批改作业
    doWorkCorrectDetailsClick (dataInfo) {
      console.log(dataInfo, '去批改作业');
      // this.$dialog.open(this, {
      //   routerName: this.$route.name,
      //   ModulePowerKey: '',
      //   name: '批改作业',
      //   moduleName: 'taskCorrectDetails',
      //   data: { taskData: { courseName: '', essayID: dataInfo.EssayID, isEdit: true,SubHomeWorkKey:dataInfo.StudentSubHomeWork.ID } },
      //   callBack: {
      //     afterSuccess: () => { this.$emit('doEditTaskClick'); }
      //   }
      // });
      // const url = '/pages/home-school-communication/correct-home-task/index';
      // this.$zxrouter.navigateTo({
      //   url: url +
      //     '?essayID=' + dataInfo.EssayID +
      //     '&SubHomeWorkKey=' + dataInfo.StudentSubHomeWork.ID +
      //     '&isCheck=true'
      // });
    }
  }
};
</script>

<style>
</style>
