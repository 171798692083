<template>
	<div @click="doItemClick">
		<div class="setting_view_left_list" :class="[vOpt,vActive]">
			<div class="mult_course_view_left" v-if="Item.multLeftClick" @click.stop="doCourseItemClick"></div>
			{{Item.MainDemoName}}
		</div>
	</div>
</template>

<script>
export default {
  props: {
    Item: Object // 左侧视图项
  },
  data () {
    return {

    };
  },
  computed: {
    vOpt () {
      if (this.Item.isOpt) {
        return 'opt';
      } else {
        return '';
      }
    },
    vActive () {
      if (this.Item.isActive) {
        return 'active';
      } else {
        return '';
      }
    }
  },
  methods: {
    doItemClick () {
      this.$emit('doItemClick', this.Item);
    },
	// 点击课查看
	doCourseItemClick(){
		     this.$emit('doCourseItemClick', this.Item);
	}
  }
};
</script>

<style>

</style>
