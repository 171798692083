<template>
  <!-- 校区备课检查 -->
  <div class="title_package_list" style="padding:10px;background:white">
    <auth-school-list :SchoolKey='Number(SchoolKey)'
                     :showPagePagination='false'
                     :monitorA="monitorA"
                     @loadShow="loadShow"
                     @afterSuccess='afterSuccess'></auth-school-list>
  </div>
</template>
<script>
import authSchoolList from '../common/auth-school-list'
export default {
  // name: 'coursewareAuthorizationManagement',
  props: {
    CoursewarePackageId: {
      type: String || Number,
      default: '0'
    },
    plan: {
      // 模式 A 课件授权管理中使用 以大课包id为定性条件 多一个状态搜索框 table字段也会不同  模式B 以状态为定性条件 课包id和校区类型作为搜索条件 table字段略作调整
      type: String, // 模式C 以校区ID做为定性条件 去掉搜索条件  // 模式D 关闭分页搜索框
      default: 'A'
    },
    StatusKey: {
      // 状态 0-已授权校区(全部) 1-在用 2-失效校区 默认在用校区
      type: Number,
      default: 1
    },
    SchoolTypeKey: {
      //  校区类型 1-直营2-加盟3-课件
      type: Number,
      default: 0
    },
    SchoolKey: {
      //  校区key SchoolKey
      type: Number || String,
      default: 0
    },
    showPagePagination: {
      // 是否显示显示分页
      type: Boolean,
      default: true
    },
    pageSize: {
      // 控制表格条数
      type: Number,
      default: 10
    },
    retailInfo: {
      // 由C模块引用 传进来的上一层表格的当前行数据
      type: Object,
      default: null
    },
    monitorA: {
      // 由A模块引用 用于监听是否在模块外面操作了表格数据 如果操作将监听调用当前表格刷新时间
      type: Number,
      default: 0
    },
    UseStatusKey: {
      type: Number,
      default: 1
    },
    useStopAuthorization: {// 由B模块引用 用于判断是否显示批量暂停授权
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  components: {
    authSchoolList
  },
  computed: {

    campusID () {
      if (this.plan == 'C') {
        return this.retailInfo.OLAPKey;
      } else {
        return null;
      }
    }
  },
  created () {
    // this.$dialog.register(this, ['singleSelectionList', 'campusListMultipleEls', 'chooseCoursewareMultiple']);
  },
  mounted () {
    console.log(this.showPagePagination, 'this.showPagePagination');
   
  },
  watch: {
   
  },
  methods:{
     // 子列表回调
    afterSuccess () {
      this.$emit('afterSuccess');
    },
    loadShow(){
      this.$emit("loadShow")
    }
  }
 
};
</script>

<style scoped>
.el-table .el-table__body-wrapper tr, .el-table .el-table__fixed-body-wrapper tr {
    height: 60px !important;
}
.title_package_list {
  position: relative;
}
.title_package_list .prepare_lesson_module {
  position: relative;
  width: 1208px;
  padding: 26px 0px 54px;
  /* margin: 0 auto; */
  /* margin: 20px 50px 50px 50px; */
}
.title_package_list .prepare_lesson_module .tltle_margin {
  border-bottom: 1px solid #ebeef5;
  width: 100%;
}
.title_package_list .prepare_lesson_module .table_nav_box {
  background-color: #fafafa;
}
.title_package_list.prepare_lesson_module .table_fixed_nav_list {
  padding: 0px 0px;
  font-weight: 400;
}
.title_package_list.prepare_lesson_module .table_select_box {
  margin-right: 10px;
}
.title_package_list.prepare_lesson_module .course_statistics_title {
  padding: 0;
}
.title_package_list.prepare_lesson_module
  .course_statistics_title
  .title_date_select {
  width: 200px;
  border: none;
  margin-right: 10px;
  margin-left: 0;
}
.title_package_list.prepare_lesson_module .table_select_box .el-select {
  width: 200px;
}
.title_package_list.prepare_lesson_module
  .course_statistics_title
  .title_date_select
  .el-input
  input {
  width: 190px;
}
/* .title_package_list.buttom_sq {
  display: block;
  width: 76px;
  height: 35px;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  right: 20px;
  top: 6px;
} */
.title_package_list .font_dual {
  line-height: 15px !important;
  padding-top: 7px;
  display: table-cell !important;
  /* width: 87px;
  text-align: center;
  align-items: center;
  overflow: visible;
  line-height: 16px;
  white-space: pre-line;
  text-align: center; */
}
.title_package_list .font_dual1 {
  line-height: 15px !important;
  padding-right: 16px !important;
  display: table-cell !important;
}
.title_package_list.chainstore_manage_table_box {
  padding: 0 0;
}
.title_package_list.headquarters_info_summarizing {
  margin: 0 auto;
}
.title_package_list.summarizing_list .el-table th {
  padding: 6px 0;
}
.title_package_list.el-table .el-table__fixed-body-wrapper tr,
.title_package_list.prepare_lesson_module .el-table .el-table__row tr,
.title_package_list
  .school_inspector_statistics
  .el-table
  .el-table__body-wrapper
  tr {
  height: 60px!important;
}
.title_package_list .el-table .el-table__fixed-body-wrapper tr{
  height: 60px!important;
}
.title_package_list.el-table .el-table__body-wrapper tr,
.title_package_list.el-table .el-table__fixed-body-wrapper tr{
  height: 60px!important;
}
.prepare_lesson_module .title_package_list .el-table .el-table__row td,
.useAuth .title_package_list .el-table .el-table__row td {
  padding: 10.5px 0;
}
.title_package_list.prepare_lesson_modul {
  margin: 0;
}
.title_package_list .el-table th:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  background-color: #e7e9e8;
  right: 0;
  z-index: 11;
}
.useAuth .title_package_list .courseware-tablist .popup_title_padding {
  background-color: #ffffff;
}
.title_package_list >>> .el-table__body-wrapper {
    overflow-x: auto !important;
}
.list_drop_box
  .list_drop_banner
  .title_package_list
  .courseware-tablist
  .table_list_content {
  padding: 0;
}

.title_package_list .table_list_content .cell.el-tooltip div {
  padding-right: 0px;
}
.location_icon {
  width: 10px;
  height: 11px;
  margin-left: 10px;
}    
.title_package_list >>>.noBtn{
  display: none;
}
.ansyio >>> .table_list_content{
  padding : 0
}
</style>

