<template>
  <div class="form_info_detail text_overflow_hidden flow_packet_bill_bg">
    <div class="flow_packet_bill ">
      <div class="flow_packet_bill_header pr">
        <div class="header_prod fl"
             @click="prodMounth">{{vPreMonth}}月</div>
        <div class="header_date fl">
          <!-- <input-date class="flow_packet_bill_date_box"
                          :formTitle="''"
                          :popperClass="'course_date_el'"
                          @changeDate="changeDay"></input-date> -->
          <span class="flow_packet_bill_date_week_box">{{vCurSelectedDate}}</span>
        </div>
        <div class="header_next fl"
             v-if="vIsThisMonth"
             @click="nextMounth">{{vNextMonth}}月</div>
      </div>
      <div class="flow_packet_bill_contont">
        <item :dataInfo="dataReportInfo.rechargeInfo"
              :searchData="searchData"
              :OLAPKey="dataInfo.OLAPKey"></item>
        <item :dataInfo="dataReportInfo.balanceInfo"
              :searchData="searchData"
              :OLAPKey="dataInfo.OLAPKey"></item>

      </div>
      <div class="flow_packet_bill_contont">
        <item :dataInfo="dataReportInfo.buyInfo"
              :searchData="searchData"
              :OLAPKey="dataInfo.OLAPKey"></item>
        <item :dataInfo="dataReportInfo.spendInfo"
              :searchData="searchData"
              :OLAPKey="dataInfo.OLAPKey"></item>
      </div>
      <!-- <div class="flow_packet_bill_contont">
             
          </div> -->
      <!-- <div v-else>
            <div class="course_block_nodata"
                 style="background-color:#FBF9F4;">
              <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
            </div>
          </div> -->
    </div>
  </div>
</template>
<script>
import { WEBVSummaryForMonth, CWSSummaryForMonth, AdminSummaryForMonth } from '../../../../API/workbench';
import item from './item'
export default {
  data () {
    return {
      curSelectedDate: this.$utils.getCurrentMonthFirst(Date.new()),
      searchData: {
        StartTime: '',
        EndTime: ''
      },
      dataReportInfo: {
        rechargeInfo: {
          value: 0,
          title: '本月充值金额',
          showBtn: true,
          type: 1,
          className: 'type_01',
          unit: '元',
          list: [],
          showList: false
        },
        balanceInfo: {
          value: 0,
          title: '本月余额消耗',
          className: 'type_02',
          type: 2,
          showBtn: false,
          unit: '元',
          list: [],
          showList: false
        },
        buyInfo: {
          value: 0,
          title: '本月购买流量',
          className: 'type_04',
          type: 3,
          showBtn: true,
          unit: '',
          list: [],
          showList: false
        },
        spendInfo: {
          value: 0,
          title: '本月流量消耗',
          className: 'type_03',
          type: 4,
          unit: '',
          showBtn: false,
          list: [],
          showList: true
        }
      }
      // isShowMainForm: false // 显示
    };
  },
  components: {
    item
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          StartTime: '',
          EndTime: '',
          OLAPKey: 0     //门店ID
        }
      }
    }
  },
  created () {
    if (this.dataInfo && this.dataInfo.StartTime) {
      this.curSelectedDate = this.dataInfo.StartTime
    }
    this.getDataInfo();
  },
  mounted () {
    this.$dialog.register(this, ['flowPacketDetailReport', 'balanceDetailReport']);
  },
  computed: {
    vCurSelectedDate () {
      let text = this.$utils.formatDatet(
        Date.new(this.curSelectedDate),
        'yyyy'
      ) + '年' + this.$utils.formatDatet(
        Date.new(this.curSelectedDate),
        'MM'
      ) + '月'
      console.log("text", text)
      return text
    },
    vIsThisMonth () {
      if (Date.new(this.curSelectedDate).getTime() >= Date.new(this.$utils.getCurrentMonthFirst(Date.new())).getTime()) {
        return false
      } else {
        return true
      }
    },
    vPreMonth () {
      let thisMonth = Date.new(this.curSelectedDate).getMonth()
      let month = this.$utils.formatDatet(
        Date.new(Date.new(this.curSelectedDate).setMonth(thisMonth - 1)),
        'M'
      )
      return month
    },
    vNextMonth () {
      let thisMonth = Date.new(this.curSelectedDate).getMonth()
      let month = this.$utils.formatDatet(
        Date.new(Date.new(this.curSelectedDate).setMonth(thisMonth + 1)),
        'M'
      )
      return month
    },
    vmdInfo () {
      return this.$store.getters.SaaSClientInfo
    },
  },
  methods: {

    getDataInfo () {
      this.getMonthTime(this.curSelectedDate.split('-')[0], this.curSelectedDate.split('-')[1]);
      let fn = null
      let OLAPKey = this.dataInfo ? Number(this.dataInfo.OLAPKey) : 0
      // // 2标准 3旗舰 4高级 5总部 6课件
      if (this.vmdInfo.OLAPKey == 1) {
        fn = AdminSummaryForMonth
      } else if (this.vmdInfo.EditionKey == 6) {
        fn = CWSSummaryForMonth
      } else if (this.vmdInfo.EditionKey == 5) {
        fn = HQSummaryForMonth
      } else {
        fn = WEBVSummaryForMonth
      }
      fn(this.searchData, OLAPKey).then(result => {
        let newArr = result.data.ConsumeFlowForMonthList.filter(x => {
          return x.ClientName == '通用流量'
        })
        console.log("111", newArr, result.data.ConsumeFlowForMonthList)
        newArr = newArr.concat(result.data.ConsumeFlowForMonthList.filter(x => {
          return x.ClientName != '通用流量'
        }))

        this.dataReportInfo.rechargeInfo.value = result.data.RechargeForMonth;
        this.dataReportInfo.balanceInfo.value = Math.abs(Number(result.data.ConsumeBalanceForMonth));
        this.dataReportInfo.buyInfo.value = this.$utils.flowFormate(result.data.PurchaseFlowForMonth, 'number', 'sunType');
        this.dataReportInfo.buyInfo.unit = this.$utils.flowFormate(result.data.PurchaseFlowForMonth, 'unit');
        this.dataReportInfo.spendInfo.value = this.$utils.flowFormate(result.data.ConsumeFlowForMonth, 'number', 'sunType');
        this.dataReportInfo.spendInfo.unit = this.$utils.flowFormate(result.data.ConsumeFlowForMonth, 'unit');
        this.dataReportInfo.spendInfo.list = newArr;//result.data.ConsumeFlowForMonthList


        console.log('getDataInfo', result.data);
      }).catch(error => {
        console.log('getDataInfoError', error);
      });
    },
    prodMounth () {
      this.curSelectedDate = this.$utils.getPreMonth(this.curSelectedDate);
      this.getDataInfo()
    },
    nextMounth () {
      this.curSelectedDate = this.$utils.getNextMonth(this.curSelectedDate);
      this.getDataInfo()

    },
    getMonthTime (year, month) {
      var date = Date.new();
      var year = year || date.getFullYear() + '';
      var month = month || (date.getMonth() + 1) + '';
      if (Number(month) < 10) {
        month = '0' + Number(month);
      }
      // 本月第一天日期
      var begin = year + '-' + month + '-01';
      // 本月最后一天日期
      var lastDateOfCurrentMonth = Date.new(year, month, 0);
      var end = year + '-' + month + '-' + lastDateOfCurrentMonth.getDate();
      this.searchData.StartTime = begin;
      this.searchData.EndTime = end;
      // this.getDataInfo(this.searchData);
    },
  }
};
</script>
<style scoped>
/* 签到本 */
.flow_packet_bill_bg {
  background: linear-gradient(0deg, #ffffff, #ffffff), #f9f9f9;
  border-radius: 0px 0px 4px 4px;
}
.flow_packet_bill {
  position: relative;
  height: 636px;
  overflow-y: auto;
  padding: 20px;
}

.flow_packet_bill_header {
  height: 48px;
  width: 100%;
  background: #f9f9f9;
  margin-bottom: 25px;
  border-radius: 5px;

  /* border-bottom: 1px solid #e0d8cf; */
}

.flow_packet_bill_header .header_prod {
  width: 40px;
  height: 100%;
  margin-right: 90px;
  background: url(../../../../../public/image/book_prod@2x.png) no-repeat center
    center;
  background-size: 8px;
  cursor: pointer;
  line-height: 46px;
  padding-left: 33px;
  color: #999999;
}

.flow_packet_bill_header .header_date {
  width: 160px;
  line-height: 48px;
  height: 100%;
  font-size: 15px;
  /* text-align: center; */
  color: #333333;
  padding-left: 20px;
  /* margin-left: 40px; */
  /* background: url(../../../../../public/image/book_date@2x.png) no-repeat left 10px;
  background-size: 15px; */
  cursor: pointer;
}

.flow_packet_bill_date_box {
  position: absolute;
  width: 46%;
  height: 100%;
  left: 130px;
  opacity: 0;
}
.flow_packet_bill_date_week_box {
  font-size: 15px;
  max-width: 160px;
  display: inline-block;
}

.flow_packet_bill_header .header_next {
  width: 42px;
  height: 100%;
  line-height: 46px;
  margin-left: 22px;
  padding-left: 2px;
  background: url(../../../../../public/image/book_next@2x.png) no-repeat center
    right;
  background-size: 8px;
  cursor: pointer;
  color: #999999;
}

.flow_packet_bill_title {
  height: 36px;
  border-bottom: 1px solid #e0d8cf;
  font-size: 16px;
  line-height: 36px;
}

.flow_packet_bill_title .book_name {
  width: 150px;
  margin-left: 56px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flow_packet_bill_title .book_teacher {
  width: 130px;
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flow_packet_bill_title .book_number {
  color: #3498db;
  width: 70px;
}

.flow_packet_bill_title .book_number_left {
  display: inline-block;
  vertical-align: top;
  width: 34px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flow_packet_bill_list {
  padding-left: 48px;
  font-size: 15px;
  color: #666666;
  height: 36px;
  line-height: 36px;
  border-bottom: 1px solid #e0d8cf;
  /* padding-right: 14px; */
}

.flow_packet_bill_list > span {
  display: inline-block;
  width: 70px;
  text-align: left;
  overflow: hidden;
}

.flow_packet_bill_list > span .font {
  max-width: 100%;
  padding-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top;
}

.flow_packet_bill_list > span:nth-child(2) {
  text-align: left;
}
.flow_packet_bill_contont {
  width: 100%;
  margin-bottom: 10px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.flow_packet_bill_contont .flow_packet_bill_contont_list {
  border-bottom: 1px solid #ececec;
}
.flow_packet_bill_contont:last-child {
  border-bottom: none;
}
</style>
