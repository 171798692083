<template>
  <div class="add_gift_form">
    <div class="form_list">
      <div class="form_list_title">物品名称</div>
      <div class="form_list_content">
        <input type="text" v-model="formDataInfo.MainDemoName" placeholder="请输入物品名称"/>
      </div>
    </div>
    <div class="form_list">
      <div class="form_list_title">学分</div>
      <div class="form_list_content">
        <input type="number" v-model="formDataInfo.CreditCount" oninput="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" />
      </div>
    </div>
    <div class="form_list">
      <div class="form_list_title">原价</div>
      <div class="form_list_content">
        <input type="number" v-model="formDataInfo.PriceAmount" oninput="this.value=this.value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" />
      </div>
    </div>
    <div class="form_list">
      <div class="form_list_title">物品图片</div>
      <div class="form_list_content">
        <div>
          <crop-upload-img
            :placeholder="'上传图片'"
            :isShowImg="true"
            :imgWidth="640"
            :imgHeight="640"
            :imgClass="'WKT_slideshow_upload_box hover_border_blue'"
            :ImgURl="formDataInfo.PicSupport"
            @uploadImgSuccess="doAfterUploadImgSuccess"
          ></crop-upload-img>
        </div>
        <div class="form_list_img_tips">
          <span class="font_red">*</span> 图片上传尺寸建议640px*640px
        </div>
      </div>
    </div>
    <save-cancel-btn-group
      :fixedBtn="true"
      :singleSubmitType="'dynamic'"
      :btnText="'提交'"
      @confirmClick="submitForm"
      @cancelClick="closeForm"
    ></save-cancel-btn-group>
  </div>
</template>
<script>
import { AddExchangeGoods, EditExchangeGoods } from "../../../../API/workbench";
import cropUploadImg from "../../../common/crop-upload-img";
export default {
  data() {
    return {
      type: "add",
      formDataInfo: {
        MainDemoName: "",
        PicSupport: "",
        CreditCount: 0,
        PriceAmount: 0,
      },
    };
  },
  props: {
    dataInfo: Object,
  },
  components: {
    cropUploadImg
  },
  computed: {
    CDNURL() {
      return this.$store.getters.CDNURL;
    },
  },
  created() {
    if (this.dataInfo.MainDemoName) {
      this.formDataInfo = this.$utils.parseJson(this.dataInfo);
      this.formDataInfo.CreditCount = Number(this.formDataInfo.CreditCount || 0);
      this.formDataInfo.PriceAmount = Number(this.formDataInfo.PriceAmount || 0)
      this.type = "edit";
    }else {
      this.formDataInfo ={
        MainDemoName: "",
        PicSupport: "",
        CreditCount: 0,
        PriceAmount: 0,
      }
    }
  },
  methods: {
    doAfterUploadImgSuccess(url) {
        this.formDataInfo.PicSupport = url;
    },
    submitForm() {
      if (this.formDataInfo.MainDemoName.length == 0) {
        layer.alert("请填写物品名称");
        return false;
      } else if (!this.formDataInfo.CreditCount || this.formDataInfo.CreditCount == 0) {
        layer.alert("学分必须大于0");
        return false;
      } else if (this.formDataInfo.PriceAmount.length == 0) {
        layer.alert("请填写原价");
        return false;
      }else if (this.formDataInfo.PicSupport.length == 0) {
          layer.alert("请上传物品图片");
          return false;
        }
      if (this.type == "add") {
        AddExchangeGoods(this.formDataInfo).then(
          (res) => {
            layer.alert("添加物品成功");
            this.closeForm();
          },
          (err) => {
            layer.alert(err.msg);
          }
        );
      } else {
        EditExchangeGoods(this.formDataInfo.OLAPKey, this.formDataInfo).then(
          (res) => {
            layer.alert("修改物品成功");
            this.closeForm();
          },
          (err) => {
            layer.alert(err.msg);
          }
        );
      }
    },
    closeForm() {
      this.$emit("cancelAddGiftInfoDialog");
    },
  },
};
</script>