<template>
  <div class="serach_ul flex pr">

    <el-select-option
      style="margin-right: 0; margin-left: 20px"
      class="serach_list"
      :selectTitle="'来源'"
      :dataSourceList="sourseInfoList"
      :selectionKey="params.sourceKey"
      @changeEvent="selectSourseList"
    ></el-select-option>
      <el-select-option 
      style="margin-right: 0; margin-left: 20px"
      :selectTitle="'招商顾问'" 
      :dataSourceList="counselorList" 
      :selectionKey="params.counselorKey"
      @changeEvent="chooseCounselor">
    </el-select-option>
    <el-select-option
      style="margin-right: 0; margin-left: 20px"
      class="serach_list"
      :selectTitle="'加盟方式'"
      :dataSourceList="addInfoList"
      :selectionKey="params.joinTheWay"
      @changeEvent="selectAddList"
    ></el-select-option>
    <input-search-contain-btn
      class="serach_input"
      v-model="params.searchText"
      :placeholder="'请输入'"
      @onSearchEvent="searchClick"
      @clearSearch="clearSearchParams"
    ></input-search-contain-btn>
    <div class="mendian_backstage_search" style="position: absolute;right: 20px;">
      <div class="btn_hover_bg_blue search_btn "
                 style="margin: 0;"
                 @click.stop="doExportInfoClick">导出</div>
    </div>
  </div>
</template>

<script>
import {hqAttractInvestmentSourceSet,GetCounselorList} from '../../../../API/workbench.js'
import elSelectOption from "../../../report/common/el-select-option"; // 下拉选择框
import inputSearchContainBtn from "../../../report/common/input-search-contain-btn";
export default {
  data() {
    return {
      counselorList:[],
      sourseInfoList: [],
      addInfoList: [
        {
          OLAPKey: 0,
          MainDemoName: "全部",
        },
        {
          OLAPKey: 1,
          MainDemoName: "课程加盟",
        },
        {
          OLAPKey: 2,
          MainDemoName: "整店加盟",
        },
      ],
      paymentScheduleTypeList: [  //付款进度
        {
          OLAPKey: 0,
          MainDemoName: "全部",
        },
        {
          OLAPKey: 1,
          MainDemoName: "已签单",
        },
        {
          OLAPKey: 2,
          MainDemoName: "付定中",
        },
        {
          OLAPKey: 3,
          MainDemoName: "已付定",
        },
        {
          OLAPKey: 4,
          MainDemoName: "尾款中",
        },
        {
          OLAPKey: 5,
          MainDemoName: "全款中",
        },
         {
          OLAPKey: 6,
          MainDemoName: "已全款",
        },
      ],
      selectSourseInfo: {
        OLAPKey: 0,
        MainDemoName: "全部",
      },
      addInfo: {
        OLAPKey: 0,
        MainDemoName: "全部",
      },
      paymentScheduleTypeInfo: {
        OLAPKey: 0,
        MainDemoName: "全部",
      },
      sourceTypeList:[], //来源列表
    };
  },
  props: {
    params: Object,
    tableType: Number,
    showAddType:{
      type:Boolean,
      default:false
    }
  },
  components: {
    elSelectOption,
    inputSearchContainBtn,
  },
  computed: {
    
    vSearchDataRange() {
      return { startTime: this.params.StartTime, endTime: this.params.EndTime };
    },
    vShowDate(){
      if(this.tableType>=4){
        return true
      }else{
        return false
      }
    },
    vshowAddType(){
       if(this.tableType<=4){
        return true
      }else{
        return false
      }
    },
    paymentTitle(){
       if(this.tableType==2){
        return '付款类型'
      }else{
        return false
      }
      
    },
    vshowPaymentType(){
      if(this.tableType==2){
        return true
      }else{
        return false
      }
    }
  },
  created() {
    this.getCorseInfoList();
    this.loadSourceType()
    this.loadAdvisorList()
  },
  methods: {
    //获取投资顾问列表
    loadAdvisorList(){
      GetCounselorList().then(result=>{
        this.counselorList = result.data;
        this.counselorList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
      });
    },
    loadSourceType(){
      hqAttractInvestmentSourceSet().then(result => {
        this.sourseInfoList = result.data||[];
        this.sourseInfoList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
        console.log(this.sourseInfoList, '查询所有招商来源设置');
      }, error => {
        layer.alert(error.msg);
      });
    },
    changeDate(data) {
      this.params.StartTime = data.startTime;
      this.params.EndTime = data.endTime;
      this.$emit("searchClick");

    },
    selectCorseList(data) {
      this.selectCorseInfo = data;
      this.params.IndustryKey = data.OLAPKey;
      this.$emit("searchClick");
    },
    // 选择来源
    selectSourseList(data) {
      this.selectSourseInfo = data;
      this.params.sourceKey = data.OLAPKey;
      this.$emit("searchClick");

    },
    // 选择加盟类型
    selectAddList(data) {
      this.selectSourseInfo = data;
      this.params.joinTheWay = data.OLAPKey;
      this.$emit("searchClick");
    },
    // 选择付款类型
    selectPaymentList(data) {
      this.paymentScheduleTypeInfo = data;
      this.params.paymentScheduleKey = data.OLAPKey;
      this.$emit("searchClick");
    },
      //选择投资顾问
    chooseCounselor(item){
      this.params.counselorKey = item.OLAPKey;
      this.$emit("searchClick");
    },
    searchClick() {
      this.$emit("searchClick");
    },
    clearSearchParams() {
      this.selectSourseInfo = {
        OLAPKey: 0,
        MainDemoName: "全部",
      };
      this.addInfo = {
        OLAPKey: 0,
        MainDemoName: "全部",
      };
      this.paymentScheduleTypeInfo={
        OLAPKey: 0,
        MainDemoName: "全部",
      },
      this.$emit("clearSearchParams");
    },
    getCorseInfoList() {
      // this.$api.GetNotDelIndustry().then(
      //   (res) => {
      //     res.data.forEach((e) => {
      //       this.corseInfoList.push(e);
      //     });
      //   },
      //   (err) => {
      //     console.log(err);
      //   }
      // );
    },
    doExportInfoClick(){
      this.$emit("doExportInfoClick")
    }
  },
};
</script>

<style scoped>
.serach_ul {
  padding: 15px 0 20px;
}

.serach_list {
  width: 222px;
   margin-bottom: 20px;
}

.serach_list:first-child {
  margin-right: 20px;
}

.serach_input {
  margin-left: 20px;
}

</style>