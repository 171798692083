    <template>
  <!-- 课 单 详细信息 -->
  <div class="single_result_courses_info">
    <!-- 1. 可编辑 courses_info_edit (界面显示修改按钮)
                   2. 只读 courses_info_readonly
                   3. 表单界面位于中间的时候添加距离顶部间距  content_marginTop_8
                   4. 表单只读 course_form_readonly
    -->
    <div
      class="courses_info_edit course_form_readonly form_info_edit content_marginTop_8 form_info_list single_shadow"
    >
      <div class="form_info_line">
        <div class="form_info_field">
          <span>课单号</span>
        </div>
        <div class="form_info_value">
          <input type="text" placeholder="选填" readonly :value="selectCourseFormItem.NumberName" />
        </div>
      </div>
      <div class="form_info_line">
        <div class="form_info_field">
          <span>购买日期</span>
        </div>
        <div class="form_info_value">
          <input
            type="text"
            placeholder="可选"
            readonly
            :value="PaidTime(selectCourseFormItem.PaidTime)"
          />
        </div>
      </div>
      <div class="form_info_line">
        <div class="form_info_field">
          <span>购买数量</span>
        </div>
        <div class="form_info_value">
          <input
            type="text"
            placeholder="选填"
            readonly
            :value="Number(selectCourseFormItem.NumCount)+selectCourseFormItem.UnitKeyValue"
          />
        </div>
      </div>
      <div class="form_info_line">
        <div class="form_info_field">
          <span>有效期</span>
        </div>
        <div class="form_info_value">
          <input
            type="text"
            placeholder="选填"
            readonly
            :value="(selectCourseFormItem.StartTime.date).slice(0,10).replace(/-/g,'.')+'至'+(selectCourseFormItem.ExpireTime.date).slice(0,10).replace(/-/g,'.')"
          />
        </div>
      </div>
      <div class="form_info_line">
        <div class="form_info_field">
          <span>适用课程</span>
        </div>
        <div class="form_info_value">
          <input type="text" placeholder="可选" readonly :value="selectCourseFormItem.ApplyCorName" />
        </div>
      </div>
      <div class="form_info_line">
        <div class="form_info_field">
          <span>剩余数量</span>
        </div>
        <div class="form_info_value">
          <input
            type="text"
            placeholder="可选"
            readonly
            :value="Number(selectCourseFormItem.CurrentCount) +selectCourseFormItem.UnitKeyValue"
          />
        </div>
      </div>
      <div class="form_info_line">
        <div class="form_info_field">
          <span>剩余天数</span>
        </div>
        <div class="form_info_value">
          <input
            type="text"
            placeholder="选填"
            readonly
            :value="Number(selectCourseFormItem.PeriodCount) +'天'"
          />
        </div>
      </div>
      <div class="form_info_line">
        <div class="form_info_field">
          <span>剩余价值</span>
        </div>
        <div class="form_info_value">
          <input
            type="text"
            placeholder="0.00元"
            readonly
            :value="Number(selectCourseFormItem.ValueAmount)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["selectCourseFormItem"],
  created() {
    console.log(this.selectCourseFormItem, "PeriodCount");
  },
  computed: {},
  methods: {
    PaidTime(item) {
      if (item) {
        return item.date.slice(0, 10).replace(/-/g, ".");
      } else {
        return "";
      }
    }
  }
};
</script>
