<template>
  <!-- 有效期 -->
  <div class="form_info_edit content_marginRL ">
    <div class="taoCanSetting clearfix">
      <div class="label_radio_bg_white">
        <input-phone :formTitle="'手机号'"
                     :formPlaceholder="'请输入'"
                     :required="true"
                     :readonly="false"
                     v-model="phoneNumber"></input-phone>
      </div>
    </div>
    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="submitDate"
                             @cancelClick="closeFrom"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>

export default {
  data () {
    return {
      phoneNumber: ''
    };
  },

  props: {
    mendianInfo: Object // 套餐信息
  },
  computed: {

  },
  created () {

  },
  methods: {
    //  关闭
    closeFrom (ExpireDateTime) {
      this.$emit('closeFrom', ExpireDateTime);
    },
    // 提交
    submitDate (callBack) {
      if (callBack) {
        callBack();
      }
      if ((this.phoneNumber.length != 11 && this.phoneNumber) || !this.phoneNumber) {
        layer.alert('请输入11位的手机号码');
      } else {
        this.$emit('afterSuccess', this.phoneNumber, callBack);
      }
    }

  }
};
</script>


