<template>
  <div>
    <!-- 课表信息 -->
    <div class=" single_result_info single_shadow">
      <div class="single_result_info_left">
        <span>目标班级</span>
      </div>
      <div class="single_result_info_right ">
        <!-- active 选中当前 -->
        <div class=" single_result_info_detail ">
          <div class="info_detail_name">
            <span>{{classItem.MainDemoName}}</span>
          </div>
          <div class="info_detail_list">
            <span class="info_detail_item">报班学生：</span>
            <span class="">{{parseInt(classItem.CurrentPeoCount)?parseInt(classItem.CurrentPeoCount):"0"}}</span>
          </div>
        </div>
      </div>
      <!-- 右上切换按钮 -->
      <div class="change_student_info"
           @click.stop="changeClass()">
      </div>
    </div>
  </div>
</template>

  <script>
export default {
  name: 'searchBox',
  data () {
    return {
    };
  },
  props: ['classItem'],
  created () {
  },
  methods: {
    changeClass () {
      this.$emit('isChangeClass');
    }
  }
};
</script>

  <style>
</style>

