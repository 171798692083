<template>
  <div class="border_bottom pr">
    <div class="YK_added_content_name">
      <span>课研与督学信息</span>
    </div>
    <div v-if="FormTemplateCategory">
      <div class="YK_added_content_form"
           v-for="columnInfo in FormTemplateCategory"
           :key="columnInfo.OLAPKey">
        <head-tltle :columnData="columnInfo"></head-tltle>
        <div class="form_list"
             v-for="categoryItem in columnInfo.nextCategory"
             :key="categoryItem.OLAPKey">
          <row-tltle :categoryItem="categoryItem"></row-tltle>
          <!-- 目前行宽为737 -->
          <div style="flex-wrap: wrap; display: flex"
               class="content_list_form">
            <component v-for="(FieldsItem, index) in categoryItem.FormFields"
                       :key="index"
                       v-bind:is="FieldsItem.type"
                       :categoryItem="categoryItem"
                       :rowWidth="737"
                       :keyIndex="index"
                       :dataLength="categoryItem.FormFields.length"
                       :nextItem="categoryItem.FormFields[index+1]"
                       :item="FieldsItem"></component>
            <!-- <text-item
              v-for="(FieldsItem, index) in categoryItem.FormFields"
              :key="index"
              :categoryItem="categoryItem"
              :rowWidth="737"
              :item="FieldsItem"
            ></text-item> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headTltle from "../../class-research-info/base-form-fields/head-tltle";
import rowTltle from "../../class-research-info/base-form-fields/row-tltle";
import formSingleText from "./base-form-fields/form-single-text/index";
import formCheckBox from "./base-form-fields/form-check-box";
import formSingleBox from "./base-form-fields/form-single-box";
import formRowTable from "./base-form-fields/form-row-table";
import { GetFormTemplate } from "../../../../../API/workbench";
export default {
  components: {
    headTltle,
    rowTltle,
    formSingleText,
    formCheckBox,
    formSingleBox,
    formRowTable
  },
  data () {
    return {
      FormTemplateCategory: null, // 表单模板
      // 字段模板
      FormFieldsTemplate: [
        {
          // DefaultValue: '单行文本',
          // FieldDescription: '前置说明',
          // FieldsThat: '后置说明',
          // WaterMark: '水印字',
          // Unit: '单位',
          // ParentName: '父级',
          // Length: '占长',
          // FieldTypeKey: '字段类型1整数;2文本;3选项;4日期;5表',
          // FormCategoryName: '类目name',
          // FieldName: '字段名',
          ShowTypeKey: 2, // '显示类型1多行文本;2单行文本;3复选;4单选;5复合复选;6行表',
          type: "form-single-text",
        },
        {
          ShowTypeKey: 3, // '显示类型1多行文本;2单行文本;3复选;4单选;5复合复选;6行表',
          type: "form-check-box",
        },
        {
          ShowTypeKey: 4, // '显示类型1多行文本;2单行文本;3复选;4单选;5复合复选;6行表',
          type: "form-single-box",
        },
        // {
        //   ShowTypeKey: 5, // '显示类型1多行文本;2单行文本;3复选;4单选;5复合复选;6行表',
        //   type: 'form-complex-box'
        // },
        {
          ShowTypeKey: 6, // '显示类型1多行文本;2单行文本;3复选;4单选;5复合复选;6行表',
          type: "form-row-table",
        },
      ]
    };
  },
  props: {
    dataInfo: Object,
  },
  created () {
    //   this.getFormTemplate();
  },
  computed: {

  },
  methods: {
    // 动态获取调研表单模板
    getFormTemplate () {
      GetFormTemplate().then((res) => {
        console.log("动态获取调研表单模板:", res.data);
        res.data.Category.forEach((o) => {
          o.nextCategory.forEach((i) => {
            i.FormFields.forEach((Fields) => {
              this.Fieldssfhsh(Fields);
            });
          });
        });
        this.FormTemplateCategory = res.data.Category;
        // 修改模式
        if (this.dataInfo && this.dataInfo.FormValue.length > 0) {
          this.editAnalysisInfo(this.dataInfo.FormValue);
        }
      },
        (err) => {
          console.log(err);
        }
      );
    },
    Fieldssfhsh (item) {
      this.FormFieldsTemplate.forEach((o) => {
        if (o.ShowTypeKey == item.ShowTypeKey) {
          this.$set(item, "type", o.type);
          if (item.ShowTypeKey == 3 || item.ShowTypeKey == 4) {
            this.$set(item, "isOpt", false);
          }
        }
      });
    },
    // 修改-解析提交数据
    editAnalysisInfo (FormValue) {
      this.FormTemplateCategory.forEach((o) => {
        o.nextCategory.forEach((category) => {
          category.FormFields.forEach((Fields, i) => {
            FormValue.forEach((value) => {
              if (Fields.FieldName == value.FormFieldName) {
                Fields.DefaultValue = value.FieldValue; // 基础类型
                if (Fields.ShowTypeKey == 3 || Fields.ShowTypeKey == 4) {
                  // 选项类型
                  this.$set(Fields, "isOpt", Number(Fields.DefaultValue) > 0);
                }
                let item = {}; // 行表对象
                let length = this.$utils.parseJson(category.FormFields.length); // 该行表所有字段长度
                if (Fields.ShowTypeKey == 6 && value.FieldValue) {

                  // 行表类型
                  value.FieldValue = JSON.parse(value.FieldValue);
                  if (value.FieldValue.length > 0) {
                    // 行表有值时，输出内容
                    value.FieldValue.forEach((s) => {
                      // console.log(s, category.FormFields, '修改-解析提交数据-ss');
                      for (let index = 0; index < length; index++) {
                        item = this.$utils.parseJson(
                          category.FormFields[index]
                        );
                        item.DefaultValue = s[index].FieldValue;
                        item.OLAPKey = Math.floor(
                          Math.random() * (-1 - -100) + -100
                        );
                        item.rowType = "minus";
                        item.rowCount = s[index].rowCount;
                        category.FormFields.push(item);
                      }
                    });
                  }
                  // Fields.DefaultValue = '</br>'
                }
              }
            });
          });
        });
      });
      console.log(this.FormTemplateCategory, "修改-解析提交数据");
    },
  },
};
</script>