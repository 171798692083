<template>
  <div class="general_summarizing_student">
    <div class="general_summarizing_student_navUl"
         style="margin: 0px;">
      <div class="title_list"
           @click="tableSwitch(1)"
           :class="{ opt: tableType == 1 }">
        学生结余统计
      </div>
      <div class="title_list"
           @click="tableSwitch(2)"
           :class="{ opt: tableType == 2 }">
        课程结余统计
      </div>
      <div class="title_list"
           @click="tableSwitch(3)"
           :class="{ opt: tableType == 3 }">
        课时结余明细
      </div>
      <div class="title_list"
           @click="tableSwitch(4)"
           :class="{ opt: tableType == 4 }">
        天数结余明细
      </div>
    </div>
    <div class="payment_statistics_bottom clearfix">
      <div v-if="tableType == 1">
        <student-balance-report @toTableType="tableSwitch"
                                @changeType="changeType"
                                :searchTime="searchObj"
                                :schoolItem="schoolItem"></student-balance-report>
      </div>
      <div v-if="tableType == 2">
        <course-balance-report @toTableType="tableSwitch"
                               :searchText="searchText"
                               :searchTime="searchObj"
                               :schoolItem="schoolItem"></course-balance-report>
      </div>
      <div v-if="tableType == 3">
        <classcount-balance-report @toTableType="tableSwitch"
                                   :searchText="searchText"
                                   :searchTime="searchObj"
                                   :schoolItem="schoolItem"></classcount-balance-report>
      </div>
      <div v-if="tableType == 4">
        <day-balance-report @toTableType="tableSwitch"
                            :searchText="searchText"
                            :searchTime="searchObj"
                            :schoolItem="schoolItem"></day-balance-report>
      </div>

    </div>
  </div>
</template>
<script>

import studentBalanceReport from './components/student-balance-report';
import courseBalanceReport from './components/course-balance-report';
import classcountBalanceReport from './components/classcount-balance-report';
import dayBalanceReport from './components/day-balance-report';
export default {
  data () {
    return {
      searchText: '',
      searchObj: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0))
      }
    };
  },
  components: {
    studentBalanceReport, courseBalanceReport, classcountBalanceReport, dayBalanceReport
  },
  props: {
    tableType: [Number, String],
    schoolItem: Object
  },
  created () {
    console.log(this.$route.query, 'this.$route.query');
  },
  activated () {
    console.log('activated');
  },
  methods: {
    initData (type, searchData) {
      this.searchText = '';
      this.searchObj = searchData;
      console.log('initData', this.searchObj);
    },
    changeType (item, type) {
      // console.log("searchData",searchData)
      this.searchText = item.MainDemoName;
      this.$nextTick(o => {
        this.$parent.indexType = type;
      });
    },
    tableSwitch (type, optDataTime) {
      this.$parent.indexType = type;
      this.searchText = '';
    }
  }
};
</script>
<style scoped>
.payment_statistics_bottom >>> .course_statistics_table_search {
  padding: 20px 0px !important;
}
</style>