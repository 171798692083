<template>
  <div class="quick_enable_content massive_bg flex_row">
    <div class="quick_enable_title">快捷启用</div>
    <div class="flex_row quick_enable_value"
         style="margin-left: 5px">
      <div class="flex_row">
        <span class="step_num">1</span>
        <span class="button quick_hover"
              @click="doOpreateForCourseName">设置课程</span>
        <span class="red_next"></span>
      </div>
      <div class="flex_row">
        <span class="step_num">2</span>
        <span class="button quick_hover"
              @click="doOpreateForStaff">录入老师</span>
        <span class="red_next"></span>
      </div>
      <div class="flex_row">
        <span class="step_num">3</span>
        <span class="button quick_hover"
              @click="doOpreateForXJKB">建班排课</span>
        <span class="red_next"></span>
      </div>
      <div class="flex_row">
        <span class="step_num">4</span>
        <span class="button quick_hover"
              @click="doOpreateForLSZL">导入学生</span>
        <span class="red_next"></span>
      </div>
      <div class="flex_row">
        <!-- <span class="step_num">4</span> -->
        <span class="">启用完成</span>
        <!-- <span class="red_next"></span> -->
      </div>
    </div>
    <div class="see_radio_btn button"
         @click="playVideo"
         v-if="vshowVideo">看视频学习</div>
    <div class="closebtn button"
         @click="closeQuickEnable"></div>
    <custom-dialog :title="'快捷启用1'"
                   width="1000px"
                   :visible.sync="isShowPlayView"
                   :before-close="closePalyViewPopup">
      <playView :dataInfo="vshowItem"
                :isReadonly="false"
                @close="closePalyViewPopup"></playView>
    </custom-dialog>
  </div>
</template>
<script>
import playView from "../../courseware-management/courseware-editor/courseware-view/play-view";
export default {
  data () {
    return {
      isShowPlayView: false,
      showItem: {
        AttachViewUrl: "/publicImg/video/video_learning.mp4",
        AttachTypeKey: 2,
        CoverUrl:
          this.$store.getters.PCWebURL + "/publicImg/image/video_learning.png",
      },
    };
  },
  computed: {
    vshowVideo () {
      if (!this.vBrandInfo.isOpenBrand) {
        return true
      } else if (this.vBrandInfo.isOpenBrand && this.vBrandInfo.HomePageVideo) {
        return true
      } else {
        return false
      }
    },
    vshowItem () {
      let obj = {}
      console.log("his.vBrandInfo.isOpenBrand", this.vBrandInfo)
      if (this.vBrandInfo.isOpenBrand) {
        obj = {
          AttachViewUrl: this.$store.getters.CDNURL + this.vBrandInfo.HomePageVideo,
          AttachTypeKey: 2,
          CoverUrl: '',//this.$store.getters.CDNURL + this.vBrandInfo.HomePageUrl
        }
      } else {
        obj = this.showItem
      }
      return obj
    },
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    },
    vBrandInfo () {
      let obj = {};
      if (window.brandCustomSettingStr && window.brandCustomSettingStr != '') {
        obj = JSON.parse(window.brandCustomSettingStr);
        obj.isOpenBrand = true;
        obj.LoginBackground = 'url(' + this.$store.getters.CDNURL + obj.LoginBackground + ')';
        obj.LogoUrl = this.$store.getters.CDNURL + obj.LogoUrl;
        obj.QRCode = this.$store.getters.CDNURL + obj.QRCode;
        obj.inputBackground = '#fff';
      } else {
        obj = {
          isOpenBrand: false,
          LoginBackground: '',
          MainBodyColor: '#f85a59',
          MainDemoName: '治学培训机构',
          QRCode: '',
          inputBackground: '#f7f7f7',
          AdvertisingConfig: [],
          HomePageVideo: ''
        };
      }
      return obj;
    },
  },
  components: {
    playView,
  },
  methods: {
    closeQuickEnable () {
      this.$emit("closeQuickEnable");
    },
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vRolePowerList, ModulePowerKey);
    },
    powerToDo (item) {
      if (this.vHasModulePower(item.ModulePowerKey)) {
        this.$bus.emit("needOpenRouter", item);
      } else {
        this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
      }
    },
    // 执行课名相关操作
    doOpreateForCourseName () {
      let item = {
        name: "课程课类管理",
        routerName: this.$route.name,
        routertitle: "首页",
        auth: true,
        roleName: "",
        moduleName: "courseClassManageSetting",
        ModulePowerKey: 45,
      };
      this.powerToDo(item);
      // this.$bus.emit("needOpenRouter", item);
    },
    // 执行员工相关操作
    doOpreateForStaff () {
      let item = {
        name: "员工管理",
        routerName: this.$route.name,
        routertitle: "首页",
        auth: true,
        roleName: "",
        moduleName: "staffManagementList",
        ModulePowerKey: 44,
        callBack: {
          cancelClick: () => {
            console.log("资料回调");
          },
        }, // 新增或修改员工后触发回调
      };
      this.powerToDo(item);
      // this.$bus.emit("needOpenRouter", item);
    },
    // 执行班级相关操作
    doOpreateForSquadClass () {
      let item = {
        name: "班级设置",
        routerName: this.$route.name,
        routertitle: "首页",
        auth: true,
        roleName: "",
        moduleName: "gradeClassManageSetting",
        ModulePowerKey: 45,
      };
      this.powerToDo(item);
      // this.$bus.emit("needOpenRouter", item);
    },
    // 执行导入学生点击相关操作
    doOpreateForLSZL () {
      let item = {
        name: "批量录入老生",
        routerName: this.$route.name,
        routertitle: "首页",
        auth: true,
        roleName: "",
        moduleName: "batchAddOldStudent",
        ModulePowerKey: 49,
      };
      this.powerToDo(item);
      // this.$bus.emit("needOpenRouter", item);
    },
    // 建课排课  到 排课管理 （精准版 到课程表）
    doOpreateForXJKB () {
      let item = {
        ModulePowerKey: 53,
        name: "班级排课",
        auth: true,
        routerName: "classGradeApplycourse",
        routertitle: "班级排课",
      };
      this.powerToDo(item);
      // this.$bus.emit("needOpenRouter", item);
    },
    playVideo () {
      this.isShowPlayView = true;
    },
    closePalyViewPopup () {
      this.isShowPlayView = false;
    },
  },
};
</script>
<style scoped>
.quick_enable_content {
  height: 74px;
  width: 100%;
  border: 1px solid #f2f2f2;
  position: relative;
  margin-bottom: 20px;
}
.flex_row {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.quick_enable_title {
  color: #e64024;
  font-size: 20px;
  margin: 0 10px 0 20px;
  font-weight: 700;
}
.flex_row span {
  display: inline-block;
  margin-right: 9px;
}
.step_num {
  border: 1px solid #e64024;
  border-radius: 50%;
  color: #e64024;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 20px;
}
.red_next {
  width: 30px;
  height: 30px;
  border: none !important;
  background: url('../../../../public/image/home_page_red_next.png') center
    no-repeat;
  background-size: 29px;
}
.see_radio_btn {
  width: 118px;
  height: 32px;
  border-radius: 100px;
  margin-left: 2px;
  background: #e64024;
  line-height: 32px;
  position: relative;
  color: white;
  padding-left: 33px;
  position: absolute;
  right: 35px;
}
.see_radio_btn::before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border: none !important;
  position: absolute;
  left: 9px;
  top: 6px;
  background: url('../../../../public/image/see_radiu.png') center no-repeat;
  background-size: 16px;
}
.quick_enable_content .closebtn {
  background: url('../../../../public/image/quick_close.png') center no-repeat;
  background-size: 26px;
  width: 30px;
  height: 30px;
  position: absolute;
  right: -13px;
  top: -13px;
}
.quick_enable_content .closebtn:hover {
  background: url('../../../../public/image/see_radiu_close.png') center
    no-repeat;
  background-size: 26px;
}
.quick_hover:hover {
  color: #e64024;
}
</style>
