<template>
  <!-- 清理数据-递进梳理 [1,2,3] json数据 1-业务;2-客户;3-课次;4-基础资料-->
  <div class="">
    <div class="list_checked_box">
      <div class="from_checked_box"
           v-for="(item,index) in clearDataList"
           :key="index">
        <el-checkbox v-model="item.isSelected"
                     style=""
                     @change="changeChecked(item)"
                     :disabled="item.disabled">
          <span>{{item.MainDemoName}}</span>
          <span class="from_checked_hint">{{item.fromHint}}</span>
        </el-checkbox>
      </div>
    </div>
    <div>
      <!-- 按钮组 -->
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="submitDate"
                             @cancelClick="closeFrom"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import clearDataTree from './clear-data-tree';
export default {
  components: {
    clearDataTree
  },
  data () {
    return {
      checked: false,
      clearDataList: [
        {
          isSelected: false,
          MainDemoName: '业务',
          OLAPKey: '1',
          FatherKey: '0',
          FatherKeyValue: '全部',
          fromHint: '当选中清理业务时，才可继续选择清理客户。',
          disabled: false

        },
        {
          isSelected: false,
          MainDemoName: '客户',
          OLAPKey: '2',
          FatherKey: '1',
          FatherKeyValue: '业务',
          fromHint: '当选中清理客户时，才可继续选择清理课次。',
          disabled: true

        },
        {
          isSelected: false,
          MainDemoName: '课次',
          OLAPKey: '3',
          FatherKey: '2',
          FatherKeyValue: '业务',
          fromHint: '当选中清理课次时，才可继续选择清理基础资料。',
          disabled: true

        },
        {
          isSelected: false,
          MainDemoName: '基础资料',
          OLAPKey: '4',
          FatherKey: '3',
          FatherKeyValue: '业务',
          fromHint: '',
          disabled: true

        }
      ], // 课类课名列表
      delScope: [], //	[1,2,3] json数据 1-业务;2-客户;3-课次;4-基础资料
      showFormDataType: 0
    };
  },

  props: {
    mendianInfo: Object // 门店信息
  },
  computed: {

  },
  created () {
    console.log(this.mendianInfo, '门店信息');
  },
  watch: {

  },
  methods: {
    changeChecked (item) {
      console.log(item, '选择');
      switch (Number(item.OLAPKey)) {
        case 1:// 业务
          if (item.isSelected) {
            this.clearDataList[1].disabled = !item.isSelected;
          } else {
            this.clearDataList[1].disabled = !item.isSelected;
            this.clearDataList[2].disabled = !item.isSelected;
            this.clearDataList[3].disabled = !item.isSelected;
            this.clearDataList[1].isSelected = item.isSelected;
            this.clearDataList[2].isSelected = item.isSelected;
            this.clearDataList[3].isSelected = item.isSelected;
          }
          break;
        case 2:// 客户
          if (item.isSelected) {
            this.clearDataList[2].disabled = !item.isSelected;
          } else {
            this.clearDataList[2].disabled = !item.isSelected;
            this.clearDataList[3].disabled = !item.isSelected;
            this.clearDataList[2].isSelected = item.isSelected;
            this.clearDataList[3].isSelected = item.isSelected;
          }
          break;
        case 3:// 课次
          if (item.isSelected) {
            this.clearDataList[3].disabled = !item.isSelected;
          } else {
            this.clearDataList[3].s = !item.isSelected;
            this.clearDataList[3].isSelected = item.isSelected;
          }
          break;
        case 4:// 基础资料

          break;
        default:
          break;
      }
      this.delScope = [];
      this.clearDataList.forEach(o => {
        if (o.isSelected) {
          this.delScope.push(o.OLAPKey);
        }
      });
      console.log(this.delScope, '选择List');
    },
    // 是否只读
    showDisabled () {
      this.clearDataList.forEach(o => {

      });
    },
    // 是否选中
    showSelected () {

    },
    // 提交
    submitDate (callBack) {
      console.log(this.delScope, '提交');
      if (callBack) {
        callBack();
      }
      if (this.delScope.length == 0) {
        layer.alert('清理的数据选项不能为空，请至少选择一项。');
      } else {
        this.delScope = JSON.stringify(this.delScope);
        this.closeFrom(this.delScope);
      }
    },
    //  关闭
    closeFrom (delScope) {
      this.$emit('closeFrom', delScope);
    }
  }
};
</script>

<style>
.list_checked_box {
  background-color: #f9f9f9;
  height: 347px;
}
.from_checked_box {
  padding: 8px 15px;
  min-height: 47px;
  display: flex;
  border-bottom: 1px solid #ececec;
  line-height: 30px;
}
.from_checked_box .from_checked_hint {
  /* display: block;
  margin-left: 15px; */
  color: #999;
}
</style>

