<template>
  <div>
    <div class="titltText">{{vTitle}}</div>
    <div class="listbox">
      <div class="course_statistics_table_box table_list_content">
        <table-list class="summarizing_list"
                    :tableData="vDataList"
                    :tableColumns="vTableColumns"
                    :queryParams="queryParams"
                    :showPagePagination="false"
                    :heightValue="'277px'"
                    @loadTableData="getLists"></table-list>
      </div>
    </div>
  </div>
</template>

<script>

import tableList from '../../../common/table-list/index';
export default {
  data () {
    return {
      queryParams: {
        pageIndex: 0,
        pageSize: 5,
        orderBy: '',
        sequence: '',
        searchText: ''
      },
      tableColumns1: [
        {
          label: '销售',
          prop: 'SalesKeyValue',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '报名收款',
          prop: 'ActualColAmount',
          width: 150,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'amount-item'
        },
        {
          label: '报名人次',
          prop: 'SignUpPersonCount',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'number-item'
        },
        {
          label: '课时数',
          prop: 'ClassHourCount',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'number-item'
        },
        {
          label: '课时单价',
          prop: 'ClassHourUnitPriceCount',
          align: 'left',
          type: 'amount-item'
        }
      ],
      tableColumns2: [
        {
          label: '销售',
          prop: 'SalesKeyValue',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '新增线索',
          prop: 'NewThreadCount',
          width: 150,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'amount-item'
        },
        {
          label: '转化报名',
          prop: 'SignUpCount',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'number-item'
        },
        {
          label: '线索转化率',
          prop: 'TransformationRateText',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        }
      ],
      tableColumns3: [
        {
          label: '销售',
          prop: 'SalesKeyValue',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '新增诺访',
          prop: 'NewAddPromiseVisitCount',
          width: 150,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'amount-item'
        },
        {
          label: '实际到校',
          prop: 'GetToSchoolCount',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'number-item'
        },
        {
          label: '诺访到校率',
          prop: 'GetToSchoolRateText',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '诺访转化率',
          prop: 'TransformationRateText',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        }
      ],
      tableColumns4: [
        {
          label: '销售',
          prop: 'SalesKeyValue',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '约试课',
          prop: 'InvitedTrialLessonCount',
          width: 150,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'amount-item'
        },
        {
          label: '已试课',
          prop: 'TrialLessonCount',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'number-item'
        },
        {
          label: '试课出勤率',
          prop: 'TrialLessonFinishRateText',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '试课转化率',
          prop: 'TransformationRateText',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        }
      ]
    };
  },
  components: {
    tableList
  },
  created () {
  },
  props: {
    tableData: Array,
    modeType: Number
  },

  mounted () {

  },
  computed: {
    vDataList () {
      return this.tableData;
    },
    vTitle () {
      return this.modeType == 1 ? '销售报名收款统计' : this.modeType == 2 ? '销售新增线索统计' : this.modeType == 3 ? '销售完成诺访/到校统计' : '销售约试课/已试课统计';
    },
    vTableColumns () {
      return this.modeType == 1 ? this.tableColumns1 : this.modeType == 2 ? this.tableColumns2 : this.modeType == 3 ? this.tableColumns3 : this.tableColumns4;
    }
  },
  methods: {
    getLists () {
      this.$emit('getList');
    }
  }
};
</script>

<style scoped>
.course_statistics_table_box {
  padding: 0;
}
.titltText {
  font-family: Alibaba PuHuiTi;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  color: #333333;
}
.listbox {
  margin-top: 20px;
}
</style>