<template>
  <div class="teacher_performance_statistics course_statistics_box">
    <div class="course_statistics_left box_shadow_border_div">
      <div class="course_statistics_left_title flex">
        <span>主讲业绩统计</span>
        <div style="width:200px">
          <!-- <div class="font_gray">本页面数据统计截止昨日</div> -->
          <div class="font_gray">数据截止 {{generationTime}}</div>
        </div>
        <div class="course_header_inner3 course_statistics_renew_btn"
             @click.stop="setNewData">刷新</div>
      </div>
      <year-report ref="yearReport"
                   :IsMTeaKey="1"
                   style="margin-top:-12px"></year-report>
      <month-report ref="monthReport"
                    :IsMTeaKey="1"
                    @clickItem="clickItemAfter"></month-report>
    </div>
    <right-content ref="rightContent"
                   :IsMTeaKey="1"></right-content>

  </div>
</template>
<script>
import yearReport from './year-report';
import monthReport from './month-report';
import rightContent from './right-content';
export default {
  data () {
    return {

    };
  },
  props: {
    generationTime: String
  },
  components: {
    yearReport, monthReport, rightContent
  },
  methods: {
    setNewData () {
      this.$emit('setNewData', () => {
        this.$refs.monthReport.getReportList();
        this.$refs.yearReport.getReportList();
        this.$refs.rightContent.updateAllData();
      });
    },
    clickItemAfter (item, seletedDate) {
      console.log('clickItemAfter', item);
      this.$refs.rightContent.openCourseReportGetDataByDate(item, seletedDate);
    }
  }
};
</script>


