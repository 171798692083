<template>
  <!-- 课类内容 -->
  <div class="manage_content_list manage_block">
    <div class="manage_content_left"
         ref="classBoxName">
      <div class="manage_content_name">{{courseClasBox.MainDemoName}}</div>
      <div class="manage_content_menuBtn"
           v-if="courseClasBox.OLAPKey>0"
           @click.stop="doCourseClassOperateClick(courseClasBox,$event)">
      </div>
    </div>
    <!-- 课程项  -->
    <div class="manage_content_right">
      <draggable v-model="courseClasBox.buildCourseList"
                 group="site"
                 animation="100"
                 dragClass="dragClass"
                 ghostClass="ghostClass"
                 chosenClass="chosenClass"
                 filter=".forbid"
                 v-bind="{sort: false,store: false,}"
                 @start="onStart"
                 @end="onEnd"
                 @add="onAdd">
        <transition-group class="draggable"
                          :id="courseClasBox.OLAPKey">
          <course-name-box v-for="(item,index) in courseClasBox.buildCourseList"
                           :key="item.OLAPKey"
                           :index="index"
                           :courseNameItem="item"
                           :isDrag="isDrag"
                           @doMouseEvent="doMouseEvent"></course-name-box>
          <div :class="'item forbid'"
               :key="'新增'"
               v-if="courseClasBox.OLAPKey>0">
            <div class="manage_content_block block_add"
                 @click="onAddCourseNameClick">
              <span class="add_icon">+</span>
              <span>新增</span>
            </div>
          </div>

        </transition-group>
      </draggable>
    </div>
  </div>
</template> 

<script>
// 导入draggable组件(vuedraggable)
import draggable from 'vuedraggable';
import { hqEditTheClassType } from '../../../../API/workbench';
import courseNameBox from './course-name-box';
import dropDownBox from '../../../common/drop-down-box';
export default {
  data () {
    return {
      draggableItem: {},
      menuItemList: [
        { type: 1, name: '查看', eventName: 'view', isShow: true, ModulePowerKey: '' },
        { type: 2, name: '修改', eventName: 'edit', isShow: true, ModulePowerKey: '' },
        { type: 3, name: '停用', eventName: 'unActive', isShow: true, ModulePowerKey: '' }
      ],
      newOffsetHeight: 60,
      draggableItemID: '',
      isDrag: false // 正在拖拽
    };
  },
  props: {
    courseClasBox: Object,
    courseClasIndex: Number,
    courseClassList: Array
  },
  components: {
    courseNameBox,
    dropDownBox,
    draggable
  },

  created () {

  },
  mounted () {

  },
  activated () {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    // 开始拖拽事件
    onStart (e) {
      this.isDrag = true;
      this.courseClasBox.buildCourseList.forEach(o => {
        o.isMenu = false;
      });
      this.draggableItem = this.courseClasBox.buildCourseList[e.oldIndex];
    },
    onAdd (e) {
      this.courseClasBox.buildCourseList.forEach(o => {
        o.isMenu = false;
      });
    },
    // 拖拽结束事件
    onEnd (e) {
      console.log(this.draggableItem, '拖拽项1');
      this.courseClasBox.buildCourseList.forEach(o => {
        o.isEmptiness = false;
      });
      if (this.draggableItem && e.to.id !== e.from.id) {
        this.draggableItem.FatherKey = Number(e.to.id) == 0 ? 2 : e.to.id;
        let findItem = this.courseClassList.find(o => {
          return o.OLAPKey == this.draggableItem.FatherKey;
        });
        if (findItem) {
          this.draggableItem.FatherKeyValue = findItem.MainDemoName;
        }
        console.log(findItem, '拖拽项');
        this.draggableItem.isMenu = false;
        // 提交数据对象
        let dataJson = {
          OLAPKey: this.draggableItem.OLAPKey,
          MainDemoName: this.draggableItem.MainDemoName, // -- 名称
          FatherKey: this.draggableItem.FatherKey, // ---所属课类Key 0为全部
          TypeKey: this.draggableItem.TypeKey, // --  类型key
          UnitPrice: this.draggableItem.UnitPrice, // --单价
          StyleName: this.draggableItem.StyleName
        };
        hqEditTheClassType(0, dataJson).then(result => {
          console.log(this.courseClasBox.buildCourseList, '拖拽项1');
        }, error => {
          layer.alert(error.msg);
        });
      }
      this.isDrag = false;
    },
    //  课类操作显示
    doCourseClassOperateClick (item, e) {
      // console.log(e, '课类操作显示');
      this.courseClassList.forEach(o => {
        if (this.courseClasBox.OLAPKey == o.OLAPKey) {
          o.isMenu = true;
        } else {
          o.isMenu = false;
        }
      });
      let locationHeight = this.$refs.classBoxName.offsetHeight / 2 - 30;// 点击当前课类所在的位置
      this.$emit('onCourseClassOperateClick', item, this.courseClasIndex, locationHeight);
      console.log(this.$refs.classBoxName.offsetHeight / 2, 'offsetHeight');
    },
    //  课程移出移入显示
    doMouseEvent (type, courseIndex, item) {
      let locationHeight = this.$refs.classBoxName.offsetHeight;// 点击当前课类所在的位置
      this.$emit('doMouseEvent', type, this.courseClasIndex, locationHeight, courseIndex, item);
    },
    // 新增课程
    onAddCourseNameClick () {
      let obj = {
        type: 'add',
        moduleName: 'courseNameSetting',
        data: {
          FatherKey: this.courseClasBox.OLAPKey,
          FatherKeyValue: this.courseClasBox.MainDemoName
        }
      };
      this.$emit('oncourseNameItemClick', obj);
    }
  }

};
</script>

<style>
/*被拖拽对象的样式*/
/* .draggable_item { */
/* padding: 6px;
  background-color: #fdfdfd;
  border: solid 1px #eee;
  margin-bottom: 10px;
  cursor: move; */
/* } */
/* .draggable_item:hover {
  background-color: #f1f1f1;
  cursor: move;
} */
/*选中样式*/
/* .chosenClass {
  border: solid 2px #3089dc !important;
} */
</style>