<template>
  <div>
    <btn-add-select :addTitleName="'新增'"
                    @doAddSuccessEvent="doAddItem"></btn-add-select>
    <div class="search_result_list">
      <div class="single_result"
           v-for="(item,index) in dataSourceList"
           :key="index"
           @click.stop="doSelectedItem(item,index)">
        <div class="single_result_info_detail">
          <div class="single_class_name">
            <span>{{item.MainDemoName}}</span>
          </div>
        </div>
      </div>
      <div v-if="dataSourceList.length>0"
           class="btn_fixed_bottom_placeholder"></div>
      <div v-if="dataSourceList.length==0"
           class="course_block_nodata">
        <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
      </div>
    </div>
    <div class="module_content_menuList"
         :style="{'top': scrollTop+'px'}"
         v-show="isShowmenuBox">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="menuItemList"
                     @clickOptionForAlertDialog="clickAfter"></drop-down-box>
    </div>
    <custom-dialog :title="formTitleName"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowProceedsAccountSetting"
                   :before-close="closeProceedsAccountSettingForm">
      <setting-form :editItem="incomePayAccount"
                    :isReadonly="isReadonly"
                    @afterSuccess="getIncomePayAccount"
                    @cancelClick="closeProceedsAccountSettingForm"></setting-form>
    </custom-dialog>
  </div>
</template>
<script>
import {
  hqMessageSearchPageForIncomePayAccount,
  hqStopIncomePayAccount
} from '../../../../API/workbench.js';
import dropDownBox from '../../../common/drop-down-box';
import settingForm from './setting-form';
import proceedsAccountSetting from '../../../base-module/basis-setting-module/proceeds-account-setting/setting-item';// 收款方式设置
export default {
  components: {
    dropDownBox,
    settingForm,
    proceedsAccountSetting
  },
  data () {
    return {
      queryParams: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 0, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: 'asc', // 排序字段
        StatusKey: 1,        // 收款账户状态 1-启用;2-停用
        AccountTypeKey: '', // 	账户类型 1-储蓄卡; 2-信用卡;3-支付宝;4-微信;5-现金;6-其它;
        QueryID: ''
      },
      dataSourceList: [],
      isShowmenuBox: false,
      scrollTop: 80,
      menuItemList: [
        { type: 1, name: '查看', eventName: 'view', isShow: true, className: 'list_icon08', ModulePowerKey: '' },
        { type: 2, name: '修改', eventName: 'edit', isShow: true, className: 'list_icon09', ModulePowerKey: '' },
        { type: 3, name: '停用', eventName: 'unActive', isShow: true, className: 'list_icon10', ModulePowerKey: '' }
      ],
      isShowProceedsAccountSetting: false,
      formTitleName: '',
      isReadonly: '',
      incomePayAccount: {}
    };
  },
  props: {

  },
  created () {
    this.getIncomePayAccount();
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isShowmenuBox = false;
    }, true);
  },
  methods: {
    // 返回总部门店所有收支账户
    getIncomePayAccount () {
      hqMessageSearchPageForIncomePayAccount(this.queryParams).then(result => {
        this.dataSourceList = result.data.PageDataList.filter(obj => {
          this.$set(obj, 'isSelected', false);
          return obj.OLAPKey > 0 && obj.StatusKey != 2;
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 新增
    doAddItem () {
      this.formTitleName = '新增收支账户';
      this.incomePayAccount = {};
      this.isReadonly = false;
      this.isShowProceedsAccountSetting = true;
      console.log('添加');
    },
    // 选中
    doSelectedItem (item, index) {
      console.log(item, '选中');
      this.scrollTop = index * 53 + 80;
      this.incomePayAccount = item;
      this.isShowmenuBox = true;
    },
    clickAfter (item) {
      switch (item.eventName) {
        case 'view':
          this.formTitleName = '查看收支账户';
          this.isReadonly = true;
          this.isShowProceedsAccountSetting = true;
          break;
        case 'edit':
          this.formTitleName = '修改收支账户';
          this.isReadonly = false;
          this.isShowProceedsAccountSetting = true;
          break;
        case 'unActive':
          layer.confirm('请确定是否需要停用该账户?', {
            btn: [{
              name: '确认',
              callBack: () => {
                layer.close();
                hqStopIncomePayAccount(this.incomePayAccount.OLAPKey).then(result => {
                  this.getIncomePayAccount();
                }, error => {
                  layer.alert(error.msg);
                });
              }
            }
            ]
          });
          break;
        default:
          break;
      }
    },
    closeProceedsAccountSettingForm () {
      this.isShowProceedsAccountSetting = false;
    }
  }
};
</script>
