<template>
  <div class="particular_list">
    <div class="particular_list_front">
      <div class="particular_list_front_title">
        <!-- 列表第一个图标 -->
        <div class="particular_list_front_title_img particular_list_front_title_img_text">
          <!-- <div class=""> -->
          {{item.ShowOrder >= 10 ? item.ShowOrder : '0' + item.ShowOrder}}
          <!-- </div> -->
        </div>
        <!-- 列表第一个文本 -->
        <el-tooltip effect="light"
                    :content="item.MainDemoName"
                    placement="top-end">
          <div class="particular_list_front_title_text">{{item.MainDemoName}}</div>
        </el-tooltip>

      </div>
      <!-- 最近备课部分 -->
      <div class="particular_list_front_content">
        <img class="particular_list_img"
             src="../../../../public/cw-img/prepare_lessons.png"
             alt="" />
        <div v-if="item.PrepareLessonsTime != ''"
             class="particular_list_front_content_text">
          最近上课 ：
          <div style="color: #000; margin-top: 1px;">{{item.PrepareLessonsTime}}</div>
        </div>
        <div v-else
             class="prepareType">
          <div style="color: #ff6c67">未上课</div>
        </div>
      </div>
      <div class="particularBtn"
           style="margin-right:25px;width:60px"
           @click="prepare"
           v-if="maxCoursewarePackage.IsPCAuthKey==1">上课</div>
    </div>
  </div>
</template>

<script>
import {
  CWSTeacherGiveLectureFinishScheduleCourse
} from '../../../API/workbench';
import dropDownBox from '../../common/drop-down-box';
export default {
  props: {
    item: Object, // 传入的子课包数据
    maxCoursewarePackage: Object, // 全部课包详情
    CoursewarePackage: Object, // 子课包
    TeacherID: [Number, String] // 老师ID
  },
  components: {
    dropDownBox
  },
  data () {
    return {
      atPresentTeacher: '', // 当前老师ID
      isEditPlan: false,
      chooseScheduleCourseKey: '',
      chooseCourseID: '',
      chooseType: '',
      chooseCoursewareInfoType: '',
      menuList: [
        {
          name: '继续上课',
          type: 1,
          isShow: true
        },
        {
          name: '停止上课',
          type: 2,
          isShow: true
        }
      ],
      isShowdropDownBox: false
    };
  },
  computed: {},
  created () {
    this.getAtPresent();
    this.$bus.on(this, 'scanToAuthCourseware', res => {
      console.log('监听到长连接');
      console.log('监听到长连接', res, this.chooseCourseID);
      if (this.chooseCourseID == this.item.OLAPKey) {
        this.$router.push({
          name: 'coursewareTeachingView',
          query: {
            type: this.chooseType,
            coursewareId: this.chooseCourseID,
            Accuracy: this.maxCoursewarePackage.Accuracy,
            IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

          }
        });
      }
    });
  },

  methods: {
    // 进入备课模式
    prepare () {
      console.log(this.item.OLAPKey, '去预览');
      if (!this.$zxClient.IsClient) {
        layer.alert('请用桌面端打开程序');
      } else {
        console.log('第一层', this.maxCoursewarePackage.DeviceIsAuth);
        if (this.maxCoursewarePackage.DeviceIsAuth == 0) {
          layer.alert('设备未授权');
        } else {
          console.log('第二层', this.$store.getters.AcademicMdCwType);
          if (this.$store.getters.AcademicMdCwType == 1 || this.$store.getters.AcademicMdCwType == 2) { // 授权
            console.log('第三层', this.maxCoursewarePackage.PCIsLocationLockKey, this.$store.getters.geoInfo.Longitude);
            if (this.maxCoursewarePackage.PCIsLocationLockKey == 1 && !this.$store.getters.geoInfo.Longitude) {
              console.log('第四层this.item', this.item);
              this.openAuthDialog(this.item.OLAPKey, '', 4);
            } else {
              console.log('第四层', this.item);
              let newdataInfo = {
                courserId: this.CoursewarePackage.OLAPKey,
                client_id: this.$store.getters.clientId,
                SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
                DeviceTypeKey: '2',  //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
                introType: 22,
                DeviceCode: this.$zxClient.GetMCode() || ''  // 桌面端设备码
              };
              this.$utils.verifySystemLocation(newdataInfo, (success) => {
                this.$router.push({
                  name: 'coursewareTeachingView', query: {
                    type: 4, coursewareId: this.item.OLAPKey, coursewareInfoType: '', Accuracy: this.maxCoursewarePackage.Accuracy, IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
                  }
                });
                setTimeout(() => {
                  this.$emit('getPrincipalWorkbenchGetCoursewareList');
                }, 1000);
              }, (error) => {
                this.openAuthDialog(this.item.OLAPKey, '', 4);
              });
            }
          }
        }
      }
    },
    // 开始上课
    // 打开上课弹窗
    // doViewCoursewareDetails() {
    //   this.$dialog.open(this, {
    //     name: '开课',
    //     routerName: this.$route.name,
    //     ModulePowerKey: '',
    //     moduleName: 'cwsGoToClass',
    //     data: {
    //       dataInfo: {
    //         item: this.item
    //       }
    //     },
    //     callBack: {
    //       afterSuccess: (data) => {
    //         this.addClassGradeForTeacher(data);
    //       }
    //     }
    //   });
    // },
    addClassGradeForTeacher () {
      // this.$emit('getPrincipalWorkbenchGetCoursewareList');
      // this.$emit('getCoursewarePackageDetails');
    },
    // 继续上课
    goOnAttendClass () {
      console.log('继续上课', this.item);
      if (this.item.MTeacherKey != this.$store.getters.token.UserID) {
        layer.alert('您不是当前的主讲老师，无法上课');
        return false;
      }
      if (!this.$zxClient.IsClient) {
        layer.alert('请使用桌面端');
      } else {
        if (this.maxCoursewarePackage.PCIsLocationLockKey == 1 && this.maxCoursewarePackage.HQAuthKey != 0) {
          let text = this.maxCoursewarePackage.HQAuthKey == 1 ? '  当前门店定位，课件授权方禁止访问课件' : ' 当前门店定位，课件授权方正在审核中，暂时无法访问课件';
          layer.alert(text);
          return false;
        } else {
          layer.confirm('是否继续上课', {
            btn: [{
              name: '确认',
              callBack: () => {
                // 点击确定调用接口
                // if (!this.$zxClient.IsClient) {
                //   layer.alert('请用桌面端打开程序')
                // } else {
                if (this.maxCoursewarePackage.DeviceIsAuth == 0) {
                  layer.alert('设备未授权');
                } else {
                  this.$router.push({
                    name: 'coursewareTeachingView',
                    query: {
                      type: 1,
                      scheduleCourseID: this.item.ScheduleCourseKey, // 正在上课的Key
                      Accuracy: this.maxCoursewarePackage.Accuracy,
                      IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

                    }
                  });
                }
                // }
                // 取消事件
                layer.close();
              }
            }]
          });
        }
      }
    },
    // 停止上课
    stopGoOnAttendClass () {
      console.log('停止上课');
      layer.confirm('是否停止上课', {
        btn: [{
          name: '确认',
          callBack: () => {
            // 点击确定调用接口
            CWSTeacherGiveLectureFinishScheduleCourse(this.item.ScheduleCourseKey).then(result => {
              this.$emit('getPrincipalWorkbenchGetCoursewareList');
            });
            // 取消事件
            layer.close();
          }
        }]
      });
    },
    clickAttendanceOption (item) {
      if (item.type == 1) {
        this.goOnAttendClass();
        this.isShowdropDownBox = false;
      } else {
        this.stopGoOnAttendClass();
        this.isShowdropDownBox = false;
      }
    },
    // 获取当前老师ID
    getAtPresent () {
      this.atPresentTeacher = this.$store.getters.token.UserID;
      console.log(this.atPresentTeacher, '当前老师');
      console.log(this.item, '传入的子课包数据');
      console.log(this.maxCoursewarePackage, '全部课包xinxi');
      console.log(this.$store.getters, '当前用户信息');
    },
    openAuthDialog (OLAPKey, type, model) {
      console.log('OLAPKey', OLAPKey);
      if (this.maxCoursewarePackage.HQAuthKey != 0) {
        let text = this.maxCoursewarePackage.HQAuthKey == 1 ? '  当前门店定位，课件授权方禁止访问课件' : ' 当前门店定位，课件授权方正在审核中，暂时无法访问课件';
        layer.alert(text);
        return false;
      }
      this.chooseCourseID = OLAPKey;
      this.chooseType = model;
      this.chooseCoursewareInfoType = type;
      console.log('进来方法');
      if (this.maxCoursewarePackage.PCIsLocationLockKey == 1) {
        console.log('courseAuthQrcode');
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '安全验证',
          moduleName: 'courseAuthQrcode',
          data: {
            dataInfo: {
              courserId: this.CoursewarePackage.OLAPKey,
              client_id: this.$store.getters.clientId,
              SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
              DeviceTypeKey: '2',  //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
              introType: 22,
              DeviceCode: this.$zxClient.GetMCode() || ''  // 桌面端设备码
            }
          },
          callBack: {
            afterSuccess: () => {
              this.$emit('updateParentData');
              this.$router.push({
                name: 'coursewareTeachingView',
                query: {
                  type: model, coursewareId: OLAPKey, coursewareInfoType: type, Accuracy: this.maxCoursewarePackage.Accuracy, IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0

                }
              });
              setTimeout(() => {
                this.$emit('getPrincipalWorkbenchGetCoursewareList');
              }, 1000);
            }
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.particular_list {
  display: flex;
  align-items: center;
  border: 1px solid #ececec;
  border-radius: 5px;
  width: 884px;
  height: 70px;
  margin-bottom: 10px;
}

.particular_list_front {
  display: flex;
  flex: 1;
  align-items: center;
  /* justify-content: space-between; */
  position: relative;
}

.particular_list_img {
  width: 15px;
  height: 15px;
}

.particular_list_front_title {
  display: flex;
  margin-left: 25px;
  align-items: center;
}

.particular_list_front_title_img {
  background: url('../../../../public/cw-img/Subtract.png') no-repeat;
  background-size: 18px;
  width: 18px;
  height: 18px;
  line-height: 18px;
}

.particular_list_front_title_img_text {
  font-family: 'Arial';
  font-style: normal;
  font-size: 12px;
  color: #6dc4bf;
  padding-left: 3px;
}

.particular_list_front_title_text {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-size: 16px;
  margin: 0px 20px 0 10px;
  font-weight: 700;
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
  width: 450px;
  /* flex: 1; */
}

.particular_list_front_content {
  display: flex;
  align-items: center;
  color: #999999;
  padding-right: 100px;
  /* position: absolute;
  left: 320px; */
}

.particularBtn {
  color: #3498db;
  cursor: pointer;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  width: 30px;
}

.particular_list_front_content_text {
  display: flex;
  font-family: 'Arial';
  font-style: normal;
  margin-left: 10px;
}

.particular_list_back {
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: space-between;
}

.particular_list_back_text {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 10px;
  height: 70px;
}

.prepareType {
  border: 1px solid #ffe2e1;
  height: 20px;
  width: 77px;
  line-height: 20px;
  text-align: center;
  margin: 0 70px 0 10px;
  background-color: #ffe2e1;
  border-radius: 4px;
  font-family: 'Arial';
  font-style: normal;
  font-size: 12px;
}

.beClassType {
  border: 1px solid #ffedce;
  height: 20px;
  width: 67px;
  line-height: 20px;
  text-align: center;
  margin: 0 5px 0 0px;
  background-color: #ffedce;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Arial';
  font-style: normal;
  font-size: 12px;
}
</style>
