<template>
  <div class="margin_left_right_div">
    <div class="general_class_grade general_summarizing_student template_minOrmax_width_div">
      <div class="course_class_summarizing clearfix">
        <!-- 全部班级 -->
        <div class="course_class_summarizing_left">
          <div class="summarizing_number_left">
            <span style="font-size: 20px;">全部班级</span>
            <!-- <span class="font_number">({{ applyGradeNameList.length }})</span> -->
            <span style="color: #3498db;cursor: pointer;"
                  class="fr"
                  @click="noAddClassClick">
              <span style="font-size: 16px;">+</span>
              <span style="font-size: 16px;"
                    class="font_number">新班</span>
            </span>
          </div>
          <div class="left_search_box"
               style="position: relative;margin-top: -5px;padding: 0 10px;">
            <el-select :value="vClassTypeInfo"
                       filterable
                       placeholder="必选"
                       @change="changeClassType"
                       value-key="OLAPKey">
              <el-option class="select_class_type"
                         v-for="item in classType"
                         :key="item.type"
                         :label="item.name"
                         :value="item"></el-option>
            </el-select>
            <div class="left_search">
              <input type="text"
                     placeholder="搜索班级"
                     v-model="searchTextForCourseName" />
              <span v-show="searchTextForCourseName"
                    class="home_remove_btn"
                    @click="searchTextForCourseName=''"></span>
            </div>
          </div>
          <div class="left_content">
            <class-item v-for="(item, index) in vApplyGradeNameList"
                        :key="index"
                        dir="ltr"
                        :courseItem="item"
                        @clickCourse="doAfterClickClass"></class-item>
            <div v-if="vApplyGradeNameList.length == 0"
                 class="course_block_nodata"
                 style="background-size: 100px">
              <div class="monitoring_content_nodata_txt">
                亲，当前没有任何数据哦~
              </div>
            </div>
          </div>
        </div>
        <div class="course_class_summarizing_right"
             v-if="classInfo.OLAPKey">
          <class-content-head ref="classContentHead"
                              :classInfo="classInfo"
                              :classHeadBox="classHeadBox"
                              :paramsForStudent="paramsForStudent"
                              :classRecordParams="classRecordParams"
                              :arrangebtn="arrangebtn"
                              @getDataList="getDataList"
                              @courseInfoFun="courseInfoFun"
                              @upForStudentList="getAllClassList"
                              @doSwitchClassHead="doAfterSwitchClassHead"
                              @searchStudent="searchStudent"
                              @clearSearchCondition="clearSearchCondition"
                              @changeStatus="changeStatus"
                              @addToClass="addToClass"
                              @addSuccessEvent="chgClassSuccessEvent"
                              @changeAttendClassDate="changeAttendClassDate"></class-content-head>

          <!-- 班级课表 -->
          <div v-if="classHeadBox[0].isShow == true">
            <div class="right_table">
              <div class="general_course_summarizing_content"
                   v-if="vTypeCourseNameList.length > 0">
                <courseNameItem v-for="item in vTypeCourseNameList"
                                :key="item.CourseNameKey"
                                :item="item"
                                :copyData="copyData"
                                :searchText="paramsForStudent.searchText"
                                :isTemporaryStu="isTemporaryStu"
                                :seletedStructuredRuleKey="seletedStructuredRuleKey">
                </courseNameItem>
              </div>
              <div v-if="vTypeCourseNameList.length ==0 && !isLoading"
                   class="course_block_nodata"
                   style="margin-top: 200px;height: 470px">
                <div class="monitoring_content_nodata_txt">
                  亲，当前没有任何数据哦~
                </div>
              </div>
              <div v-if="isLoading"
                   class="loading_fixed_box"
                   style="height: 600px;">
                <div class="loading_fixed_gray">
                  <span class="loading_style">加载中...</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 班内学生 -->
          <div v-if="classHeadBox[1].isShow == true">
            <div class="right_table">
              <!-- 班内学生总览 -->
              <!-- <div> -->
              <list ref="gradeRef"
                    :tableData="vStudentList"
                    :totalNum="totalNum"
                    :PageCount="PageCount"
                    :tableColumns="tableClassColumns"
                    :isSearchText="false"
                    :expandRowData="expandRowData"
                    :isNeedInnerScroll="false"
                    :isShowSearch="true"
                    :rowKey="'Number'"
                    :defaultSort="defaultSort"
                    :queryParams="paramsForStudent"
                    @loadTableData="loadAfterTableData"></list>
              <!-- </div> -->
            </div>
          </div>
          <!-- 上课记录 -->
          <div v-if="classHeadBox[2].isShow == true">
            <div>
              <attend-class-record-list :classKey="classInfo.OLAPKey"
                                        :classRecordListL="classRecordListL"
                                        :classRecordTotal="classRecordTotal"
                                        :classRecordParams="classRecordParams"
                                        :isCourseInfo="isCourseInfo"
                                        @getDataList="getDataList"
                                        @loadTableData="loadAfterClassRecord"></attend-class-record-list>
            </div>
          </div>
          <!-- 统计分析 -->
          <!-- <div v-if="classHeadBox[3].isShow == true">
            <statistics-analyze-form :classKey="classInfo.OLAPKey"></statistics-analyze-form>
          </div> -->

        </div>
        <div v-show="!classInfo.OLAPKey"
             class="course_class_summarizing_right">
          <div class="course_block_nodata"
               style=" height: 600px;  background-position: center 70px; background-size: 100px;">
            <div style="padding-top: 150px"
                 class="monitoring_content_nodata_txt">
              亲，当前没有任何数据哦~
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <dialog-factory ref="classGradeApplycourseDialogFactory"
                      :routerName="'classGradeApplycourse'"></dialog-factory>
    </div>
    <div>
      <dialog-factory-common ref="dialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import {
  getUsingClass,
  getStudentAllCourse,
  GradeMessageSearchArrangedPageForGradeStudent,
  SearchStructedRule,
  GradeSearchStructedRule,
  ExportGradeStudent,
  GetTheClassByID,
  MessageSearchPageForGradeClassRecord,
  MessageSearchPageForGradeClassHistoryRecord,
  MessageSearchPageForGradeClassDeductRecord,
  MessageSearchPageForGradeClassDeductRecordHistory,
  MessageSearchPageForGradeClassRecordNew,
  MessageSearchPageForGradeClassRecordNewHistory

} from '../../../../API/workbench.js';

import list from './general-table';
import classItem from './class-item';
import courseNameItem from './course-name-item/course-name-item';
import classContentHead from './class-content-head';
import dialogFactory from '../../../common/dialog-factory';
import attendClassRecordList from './attend-class-record-list';
import statisticsAnalyzeForm from './statistics-analyze-form/index';
export default {
  data () {
    return {
      // 班级类型
      classType: [
        { type: 4, name: '进行中' },
        { type: 5, name: '已结束' }
      ],
      studentsClassInfo: {
        StatusKey: 4,
        StatusKeyValue: '进行中'
      },
      isShowHistory: false, // true 2022前/false 2022后
      isCourseInfo: true,
      classGradeKey: 0,
      applyGradeNameList: [], // 班级统计列表
      curSelectedClassInfo: {}, // 当前选中班级
      classInfo: {}, // 当前选中班级的班级信息
      // 查询 选中的班级的在读学生信息
      paramsForStudent: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: 'StudentKeyValue', // 排序字段
        sequence: 'asc', // 排序字段
        ApplyClassKey: 0, // 整型	必须 课名ID
        GradeClassKey: 0, // 整型	必须	0	数据源：GET	班级ID
        SpecialFilter: 0, // 整型	必须	0	数据源：GET	0-不生效;1-按待排>0;2-按待报>0;3-按当前报课<=5;
        SubscribeKey: '', //	字符串	可选		数据源：GET	关注状态：0-未关注;1-已关注;空-不生效
        FaceRecognition: '',	// 字符串	可选		数据源：GET	录入状态：0-未录入;1-已录入;空-不生效
        QueryID: 0, // 整型	可选	0	数据源：GET	学生ID
        noMore: false, // 没有更多数据加载了
        IsExportKey: 0 // 是否导出：0-否;1-是;如果为是pageIndex传0
      },
      // 上课记录
      classRecordParams: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        orderBy: 'BeginClassTime', // 排序字段
        sequence: '', // 排序字段
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        StartTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        EndTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd'),
        GradeID: '' //		整型	必须		数据源：GET	 班级id
      },
      expandRowData: [], // 学生课单详情
      classRecordListL: [], // 上课记录列表
      classRecordTotal: 0, // 上课记录总数
      InGradeClassStudentList: [], // 班内学生列表
      arrangebtn: {
        name: '学生进班',
        nameValue: 'XSJB',
        ModulePowerKey: 51
      },
      isTemporaryStu: false, // 是否开启临时学生
      isLoading: false,
      isAddStudent: false,
      tableClassColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 60,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          className: 'font_gray',
          decimalPlace: 0
        },
        {
          label: '学管师',
          prop: 'AdviserStudentKeyValue',
          width: 100,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 100,
          type: 'student',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            click: () => { }
          }
        },
        {
          label: '手机号',
          prop: 'CustomerPhoneName',
          width: 120,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '销售',
          prop: 'AdviserKeyValue',
          width: 100,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '可用课单',
          prop: 'ClassUnitCount',
          width: 100,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '可用课时',
          prop: 'CurrentClassHourCount',
          width: 120,
          // sortable: 'custom',
          align: 'center',
          isShow: true,
          type: 'class-hour'
        },
        // {
        //   label: '可用课时',
        //   prop: 'CurrentClassHourCount',
        //   type: 'class-hour',
        //   isShow: true,
        //   extend: {
        //     type: 'expand',
        //     click: () => { },
        //     isLoading: true, // 正在加载中
        //     tableWidth: '920px',
        //     tableleft: '18px',
        //     isShowOweMark: true,
        //     loadtableData: (rowData, callBack) => { // 获取详情数据
        //       this.studentKey = rowData.StudentKey;
        //       this.getStudentAllCourseDetial(callBack);
        //     },
        //     tableColumnsDetail: [
        //       {
        //         label: '购买时间',
        //         prop: 'newPaidTime',
        //         type: 'date-item',
        //         width: 140,
        //         extend: {
        //           dateType: 'YYMMRR HH:DD' // 日期类型
        //         }
        //       },
        //       {
        //         label: '适用课程',
        //         prop: 'ApplyCorName',
        //         type: 'reading-courses',
        //         width: 160
        //       },
        //       {
        //         label: '类型',
        //         prop: 'newSemesterCalText',
        //         type: 'text-item',
        //         width: 80
        //       },
        //       {
        //         label: '购买课时',
        //         prop: 'newNumCount',
        //         type: 'class-hour',
        //         width: 90
        //       },
        //       {
        //         label: '消耗课时',
        //         prop: 'expendCount',
        //         type: 'class-hour',
        //         width: 90
        //       },
        //       {
        //         label: '调整课时',
        //         prop: 'AdjustCount',
        //         type: 'class-hour',
        //         width: 90
        //       },
        //       {
        //         label: '可用课时',
        //         prop: 'newCurrentCount',
        //         type: 'class-hour',
        //         width: 90
        //       },
        //       {
        //         label: '可用天数',
        //         prop: 'PeriodCount',
        //         type: 'class-hour',
        //         width: 90
        //       },
        //       {
        //         label: '操作',
        //         prop: 'option',
        //         type: 'table-btn',
        //         extend: {
        //           tableBtnGroup: [
        //             {
        //               name: '课消',
        //               extend: {
        //                 click: (rowData) => {
        //                   this.doCourseDetialConsumeBtnClick(rowData);
        //                 },
        //                 ModulePowerKey: ''
        //               }
        //             }
        //           ]
        //         }
        //       }
        //     ]
        //   }
        // },
        {
          label: '加入班级',
          prop: 'addClassValue',
          width: 160,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '排课计划',
          prop: 'sourceData',
          type: 'reading-course-detail',
          columnClass: 'no_border',
          width: 420
          // renderHeader: (h, { column, $index }) => {
          //   return customTipsForTableHeadColumn(h, column, '学生在上的课，所在班级以及所在的排课。');
          // },
          // extend: {
          //   width: 420,
          //   splitSymbol: ','
          // }
        },
        {
          label: '排课备注',
          prop: 'ArrangeSupport',
          type: 'arrange-support',
          width: 251,
          extend: {
            studentKey: 'StudentKey', // 学生ID
            studentKeyValue: 'StudentKeyValue', // 学生名
            click: () => { }
          }
        },
        {
          label: '关注',
          prop: 'SubscribeKey',
          width: 60,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'wechat-subscribe'
        },
        {
          label: '最近上课',
          prop: 'LastSignTim',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item'
        },
        // {
        //   label: '刷脸',
        //   prop: 'FaceRecognition',
        //   width: 60,
        //   sortable: false,
        //   align: 'center',
        //   isShow: true,
        //   type: 'face-recognition'
        // },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          fixed: 'right',
          width: 180,
          extend: {
            tableBtnGroup: [
              {
                name: '排课',
                extend: {
                  click: (rowData) => { this.doArrangeCourseBtnClick(rowData); },
                  ModulePowerKey: '51'
                }
              },
              {
                name: '退班',
                extend: {
                  click: (rowData) => { this.doReturnAClassBtnClick(rowData); },
                  ModulePowerKey: '51'
                }
              },
              {
                name: '查看',
                extend: {
                  click: (rowData) => { this.showStudentFile(rowData); }
                }
              }
            ]
          }
        }
      ],
      classHeadBox: [
        { title: '班级课表', index: 1, count: 0, isShow: true },
        { title: '班内学生', index: 2, count: 0, isShow: false },
        { title: '上课记录', index: 3, count: 0, isShow: false }
        // { title: '统计分析', index: 4, count: 0, isShow: false }
      ],
      PageCount: 0,
      totalNum: 0,
      searchTextForCourseName: '', // 搜索班级
      defaultSort: { prop: 'studentInfo', order: 'ascending' },
      tableKey: '0',
      structedRuleData: [], // 循环规律列表
      copyData: {
        StructuredRuleKey: 0,
        copyStudentList: []
      },
      seletedStructuredRuleKey: 0 // 指定更新的规律课块
    };
  },
  components: {
    dialogFactory,
    list,
    classItem,
    classContentHead,
    courseNameItem,
    attendClassRecordList,
    statisticsAnalyzeForm
  },
  created () {
    this.classGradeKey = this.$route.params.dataKey;
    if (this.$route.params.data && this.$route.params.data.isAddStudent) {
      this.isAddStudent = true;
    }
    if (this.$route.params.isShowClassCourseTable) {
      this.classHeadBox[1].isShow = false;
      this.classHeadBox[0].isShow = true;
    }
    this.getAllClassList();

    console.log(this.$route.params, ' this.$route.params');
    console.log(this.classHeadBox, '$created');
  },
  activated () {
    if (this.$route.params.dataKey && this.$route.params.data && this.$route.params.data.isAddStudent && this.classGradeKey != this.$route.params.dataKey) {
      this.classGradeKey = this.$route.params.dataKey;
      this.isAddStudent = true;
      this.getAllClassList();
    } else if (this.classGradeKey != this.$route.params.dataKey) {
      this.classGradeKey = this.$route.params.dataKey || this.classGradeKey;
      this.getAllClassList();
    }
    console.log(this.$route.params.dataKey, this.classGradeKey);
  },
  mounted () {
    this.registerBusEvent();
  },
  watch: {
    'curSelectedClassInfo.OLAPKey': {
      handler (n, o) {
        if (n > 0 && n != o) {
          this.tableKey = n;
        }
      },
      deep: true
    },
    $route (to, from) {
      // 监听路由是否变化
      console.log(to, '$route');
      if (to.meta.keepAlive) {
        this.classGradeKey = this.$route.params.dataKey;
        if (to.params.isShowClassCourseTable) {
          this.classHeadBox[0].isShow = true;
          this.classHeadBox[1].isShow = false;
        }
        this.getAllClassList();
      }
    }
  },
  computed: {
    // 来源方式
    vClassTypeInfo () {
      return this.studentsClassInfo.StatusKeyValue;
    },
    vApplyGradeNameList () {
      let newList = [];
      if (this.searchTextForCourseName) {
        let trueSearchText = this.searchTextForCourseName.toLowerCase();
        this.applyGradeNameList.forEach((obj) => {
          if (obj.MainDemoName.toLowerCase().indexOf(trueSearchText) > -1) {
            newList.push(obj);
          }
        });
      } else {
        newList = this.applyGradeNameList;
      }
      console.log(newList, 'newList');
      return newList;
    },
    vStudentList () {
      if (this.InGradeClassStudentList.length > 0) {
        let list = [];
        this.InGradeClassStudentList.forEach((o, index) => {
          if (o.ArrangeSupport) {
            o.rowCount = Math.ceil(this.$utils.getTextWidth(o.ArrangeSupport, 16) / 262.73);
          }
          o.sourceData = [];
          if (o.ApplyClass.length > 0) {
            o.ApplyClass.sort((x, y) => {
              return parseInt(x.ClassTimeName) - parseInt(y.ClassTimeName);
            });
            o.ApplyClass.sort((x, y) => {
              return parseInt(x.WeekKey) - parseInt(y.WeekKey);
            });
            o.ApplyClass.forEach((obj, index) => {
              o.sourceData.push(
                {
                  'CourseNameKey': obj.CourseNameKey,
                  'CourseNameKeyValue': obj.CourseNameKeyValue,
                  // 'GradeClassKey': obj.GradeClassKey,
                  // 'GradeClassKeyValue': obj.GradeClassKeyValue,
                  ClassList: [],
                  arrangedList: []
                }
              );
            });
            // 利用reduce方法遍历数组,reduce第一个参数是遍历需要执行的函数，第二个参数是item的初始值
            let Obj = {};
            o.sourceData = o.sourceData.reduce(function (item, next) {
              Obj[next.CourseNameKey] ? '' : Obj[next.CourseNameKey] = true && item.push(next);
              return item;
            }, []);
            o.ApplyClass.forEach((obja, index) => {
              o.sourceData.forEach((objb, index1) => {
                if (objb.CourseNameKey == obja.CourseNameKey) {
                  objb.ClassList.push({ 'GradeClassKey': obja.GradeClassKey, 'GradeClassKeyValue': obja.GradeClassKeyValue, arrangedList: [] });
                  // objb.arrangedList.push(obja);
                  const hash = {};
                  objb.ClassList = objb.ClassList.reduce((item, next) => {
                    hash[next.GradeClassKey] ? '' : hash[next.GradeClassKey] = true && item.push(next);
                    return item;
                  }, []);
                  objb.ClassList.forEach((objc, index2) => {
                    if (objc.GradeClassKey == obja.GradeClassKey) {
                      objc.arrangedList.push(obja);
                    }
                  });
                  // objb.arrangedList.push(obja);
                }
                // if (obja.CourseNameKey == objb.CourseNameKey && obja.GradeClassKey == objb.GradeClassKey) {
                // objb.arrangedList.push(obja, objb);
                // objb.arrangedList.push(obja);
                // }
              });
            });
          }

          if (o.sourceData.length == 0) {
            o.sourceData = [
              {
                'CourseNameKey': '-',
                'CourseNameKeyValue': '-',
                ClassList: [
                  {
                    GradeClassKey: '-',
                    GradeClassKeyValue: '-'
                  }
                ]
              }
            ];
          }
          if (o.ApplyClass.length == 0) {
            o.ApplyClass = [
              {
                WeekKeyValue: '-',
                ClassTimeName: '-',
                CourseNameKeyValue: '-',
                GradeClassKeyValue: '-',
                StudentKeyValue: '-'
              }
            ];
          }
          list.push(o);
        });
        // console.log(repetitionItemList, '重复数据');
        console.log(list, 'scope.row.sourceData.arrangedList.length');
        return list;
      } else {
        return this.InGradeClassStudentList;
      }
    },
    vTypeCourseNameList () {
      // 仅仅分类课程
      let newArr = [];
      this.structedRuleData.sort((x, y) => {
        if (x.CourseNameKeyValue != y.CourseNameKeyValue) {
          let xCourseName = this.charCodeAtSort(x.CourseNameKeyValue);
          let yCourseName = this.charCodeAtSort(y.CourseNameKeyValue);
          return xCourseName - yCourseName;
        }
        let xGradeClass = this.charCodeAtSort(x.GradeClassKeyValue);
        let yGradeClass = this.charCodeAtSort(y.GradeClassKeyValue);
        return xGradeClass - yGradeClass;
      });
      this.structedRuleData.forEach((o) => {
        this.$set(o, 'studentList', []);
        this.$set(o, 'rulesStudentList', []);
        let obj = {
          CourseNameKey: o.CourseNameKey,
          CourseNameKeyValue: o.CourseNameKeyValue,
          GradeClassKey: o.GradeClassKey,
          GradeClassKeyValue: o.GradeClassKeyValue,
          studentList: o.studentList,
          rulesList: [] // 班级列表
        };
        obj.rulesList.push(o);
        let locaIndex = newArr.findIndex((p) => {
          return p.CourseNameKey == o.CourseNameKey;
        });
        if (locaIndex == -1) {
          newArr.push(obj);
        } else {
          newArr[locaIndex].rulesList.push(o);
        }
      });
      console.log(newArr, 'newArr');
      return newArr;
    }
  },
  methods: {
    // 显示学生信息
    showStudentFile (item) {
      this.$dialog.open(this, {
        name: '学生档案',
        routerName: this.$route.name,
        moduleName: 'studentFile',
        data: { studentID: item.OLAPKey }
      });
    },
    // 选择班级类型
    changeClassType (obj) {
      console.log(obj, '选择来源方式');
      this.studentsClassInfo.StatusKey = obj.type;
      this.studentsClassInfo.StatusKeyValue = obj.name;
      this.getAllClassList();
    },
    // 新增班级
    noAddClassClick () {
      if (this.$utils.CheckModulePower(this.$store.getters.RolePowerList, 45)) {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '新增班级',
          moduleName: 'squadClassSetting',
          data: { editItem: {}, isReadonly: false },
          callBack: {
            afterSuccess: () => { this.chgClassSuccessEvent('Add'); }
          }
        });
      } else {
        this.$utils.CheckModulePowerToTips(45);
      }
    },
    // 搜索
    searchStudent (obj) {
      console.log(obj, '搜索');
      if (this.classHeadBox[1].isShow) { // 班内学生
        this.paramsForStudent.pageIndex = 0;
        this.getMessageSearchPageForGradeStudent();
      } else if (this.classHeadBox[2].isShow) { // 上课记录
        this.classRecordParams.searchText = obj.searchText;
        this.classRecordParams.pageIndex = 0;
        this.getMessageSearchPageForGradeClassRecord(); // 上课记录
      }
      this.expandRowData = [];
      if (this.$refs && this.$refs.gradeRef) {
        this.$refs.gradeRef.$refs.tableListRef.expandRowKeys = [];
      }
    },
    // 清除搜索条件
    clearSearchCondition (isCourseInfo) {
      console.log('清除搜索条件');
      this.paramsForStudent.searchText = '';
      if (this.classHeadBox[1].isShow) {
        this.paramsForStudent.pageIndex = 0;
        this.paramsForStudent.SubscribeKey = '';
        this.paramsForStudent.FaceRecognition = '';
        this.getMessageSearchPageForGradeStudent(); // 班内学生
      } else if (this.classHeadBox[2].isShow) { // 上课记录
        this.classRecordParams.searchText = '';
        this.classRecordParams.pageIndex = 0;
        this.classRecordParams.StartTime = '';
        this.classRecordParams.EndTime = '';
        this.isCourseInfo = isCourseInfo;
        this.$refs.classContentHead.attendClassDate.startTime = '';
        this.$refs.classContentHead.attendClassDate.endTime = '';
        this.getMessageSearchPageForGradeClassRecord(); // 上课记录
      }
      this.expandRowData = [];
      if (this.$refs && this.$refs.gradeRef) {
        this.$refs.gradeRef.$refs.tableListRef.expandRowKeys = [];
      }
    },
    //
    changeStatus (val) {
      console.log(val, 'val', this.paramsForStudent.searchText);
      this.isTemporaryStu = val;
    },
    // 班内学生 跳转页码
    loadAfterTableData (queryParams) {
      console.log(queryParams, '跳转页码');
      this.paramsForStudent.orderBy = queryParams.orderBy ? queryParams.orderBy : this.paramsForStudent.orderBy;
      this.paramsForStudent.pageIndex = queryParams.pageIndex ? queryParams.pageIndex : this.paramsForStudent.pageIndex;
      this.paramsForStudent.pageSize = queryParams.pageSize ? queryParams.pageSize : this.paramsForStudent.pageSize;
      this.paramsForStudent.searchText = queryParams.searchText ? queryParams.searchText : this.paramsForStudent.searchText;
      this.paramsForStudent.sequence = queryParams.sequence ? queryParams.sequence : this.paramsForStudent.sequence;
      this.getMessageSearchPageForGradeStudent(); // 班内学生
    },
    // 上课记录 跳转页码
    loadAfterClassRecord (queryParams) {
      console.log(queryParams, '上课记录-跳转页码');
      this.classRecordParams.pageIndex = queryParams.pageIndex;
      this.classRecordParams.pageSize = queryParams.pageSize;
      this.classRecordParams.orderBy = queryParams.orderBy ? queryParams.orderBy : this.classRecordParams.orderBy;
      this.classRecordParams.sequence = queryParams.sequence ? queryParams.sequence : this.classRecordParams.sequence;
      this.getMessageSearchPageForGradeClassRecord(); // 上课记录
    },
    // 上课记录 日期查询
    changeAttendClassDate (date) {
      this.classRecordParams.pageIndex = 0;
      this.classRecordParams.StartTime = date.startTime;
      this.classRecordParams.EndTime = date.endTime;
      this.getMessageSearchPageForGradeClassRecord(); // 上课记录
    },
    // 三位数加逗号隔开
    commaSeparation (key) {
      key += '';
      let x = key.split('.');
      let x1 = x[0];
      let x2 = x.length > 1 ? '.' + x[1] : '';
      let reg = /(\d+)(\d{3})/;
      while (reg.test(x1)) {
        x1 = x1.replace(reg, '$1' + ',' + '$2');
      }
      return x1 + x2;
    },
    // 上课记录
    getMessageSearchPageForGradeClassRecord () {
      let fn = '';
      if (this.isCourseInfo) {
        if (this.isShowHistory) {
          fn = MessageSearchPageForGradeClassRecordNewHistory;
        } else {
          fn = MessageSearchPageForGradeClassRecordNew;
        }
      } else {
        if (this.isShowHistory) {
          fn = MessageSearchPageForGradeClassDeductRecordHistory;
        } else {
          fn = MessageSearchPageForGradeClassDeductRecord;
        }
      }
      this.classRecordParams.GradeID = this.curSelectedClassInfo.OLAPKey;
      fn(this.classRecordParams).then(result => {
        result.data.PageDataList.forEach(o => {
          o.sourceData = o.CourseNameKeyValue.replace(/\，/g, ',').split(',');
          o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
          o.StudentKey = o.OLAPKey;
          o.AttendanceRate = o.AttendanceRate == 0 ? '-' : o.AttendanceRate + '%';
          o.Amount = o.Amount == 0 ? '-' : o.Amount;
          o.DeductAmount = Number(o.DeductAmount) ? '¥' + this.commaSeparation(Number(o.DeductAmount)) : '-';// 结转课消
          o.AttenceStatusKeyValue = o.AttenceStatusKeyValue ? o.AttenceStatusKeyValue : '-';
          o.ArrivedNumCount = Number(o.ArrivedNumCount);
          // 消耗课时
          if (Number(o.DeductCount)) {
            if (Number(o.OweCount)) {
              o.DeductCount = Number(o.DeductCount) + '(含欠' + Number(o.OweCount) + ')';
            } else {
              o.DeductCount = Number(o.DeductCount);
            }
          } else {
            o.DeductCount = '-';
          }
          o.AvergeAmount = Number(o.AvergeAmount) ? '¥' + this.commaSeparation(Number(o.AvergeAmount)) : '-';// 生均消费
          o.TruantNumCount = Number(o.TruantNumCount);
          o.TimeDifference = o.TimeDifference ? o.TimeDifference : '-';
          o.ApplyCorName = o.IsSingleKey == 0 ? '组合课|' + o.ApplyCorName : '单课|' + o.ApplyCorName;
        });
        this.classRecordListL = result.data.PageDataList;
        this.classRecordTotal = result.data.Total;
        console.log(result.data, 'result-班级应到实到统计');
      });
    },
    // 获取全部班级统计
    getAllClassList (params) {
      getUsingClass(this.studentsClassInfo.StatusKey).then((result) => {
        console.log('获取全部班级统计', result.data);
        this.applyGradeNameList = result.data;
        if (result.data.length > 0) {
          let findItem = null;
          if (
            JSON.stringify(this.curSelectedClassInfo) == '{}' ||
            this.classGradeKey
          ) {
            if (this.classGradeKey) {
              findItem = this.applyGradeNameList.find((o) => {
                return o.OLAPKey == this.classGradeKey;
              });
              if (!findItem) {
                findItem = result.data[0];
              }
            } else {
              findItem = result.data[0];
            }
          } else {
            findItem = this.applyGradeNameList.find((o) => {
              return o.OLAPKey == this.curSelectedClassInfo.OLAPKey;
            });
          }
          console.log('this.classGradeKey', this.classGradeKey, findItem);

          if (findItem) {
            this.curSelectedClassInfo = findItem;
          } else {
            this.curSelectedClassInfo = result.data[0];
          }
          this.getTheClassByInfo(this.curSelectedClassInfo.OLAPKey);
          this.$set(this.curSelectedClassInfo, 'isActive', true);
          this.paramsForStudent.GradeClassKey = this.curSelectedClassInfo.OLAPKey;
          // this.paramsForStudent.pageSize = 10;
          if (this.$refs.classContentHead) {
            this.$refs.classContentHead.getClassStatisticsForGrade(this.curSelectedClassInfo.OLAPKey);// 刷新班级课统计
            if (this.isAddStudent) {
              this.isAddStudent = false;
              this.$refs.classContentHead.switchClassHead(this.classHeadBox[1]);
              this.$refs.classContentHead.selectStudentAddClass({
                id: 'batch'
              });
            }
          }
          this.upForStudentList(params);
          this.expandRowData = [];
          if (this.$refs && this.$refs.gradeRef) {
            this.$refs.gradeRef.$refs.tableListRef.expandRowKeys = [];
          }
        }
      }, (error) => {
        layer.alert(error.msg);
      }
      ).finally(() => { });
    },
    // 获取班级信息
    getTheClassByInfo (OLAPKey) {
      GetTheClassByID(OLAPKey).then(result => {
        console.log(result.data, '班级信息');
        this.classInfo = result.data;
      });
    },
    // 新增修改班级后回调
    chgClassSuccessEvent (type, classItem) {
      if (type == 'Add') {
        this.getAllClassList();
      } else {
        this.getAllClassList();
        this.doAfterSwitchClassHead();
      }
    },
    // 切换班级排课类型
    doAfterSwitchClassHead (item) {
      this.isCourseInfo = true;
      console.log(item, '切换班级排课类型');
      if (item) {
        this.classHeadBox.forEach((o) => {
          if (item.title == o.title) {
            o.isShow = true;
          } else {
            o.isShow = false;
          }
        });
        this.$refs.classContentHead.externalClearSearchCondition(this.isCourseInfo);// 初始化关注或刷脸
      }
      // 班内学生
      this.paramsForStudent.SubscribeKey = '';
      this.paramsForStudent.FaceRecognition = '';
      this.paramsForStudent.searchText = '';
      this.paramsForStudent.pageIndex = 0;
      this.paramsForStudent.pageSize = 10;
      // 上课记录
      this.classRecordParams.searchText = '';
      this.classRecordParams.pageIndex = 0;
      this.classRecordParams.pageSize = 10;
      this.classRecordParams.StartTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      this.classRecordParams.EndTime = this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd');
      this.$refs.classContentHead.attendClassDate.startTime = this.classRecordParams.StartTime;
      this.$refs.classContentHead.attendClassDate.endTime = this.classRecordParams.EndTime;
      this.upForStudentList();
    },
    // 刷新学生列表
    upForStudentList (queryParams) {
      console.log(queryParams, 'queryParams');
      if (this.classHeadBox[1].isShow) { // 班内学生
        this.arrangebtn.name = '学生进班';
        this.arrangebtn.nameValue = 'XSJB';
        this.arrangebtn.ModulePowerKey = 51;
        this.paramsForStudent.pageIndex = queryParams ? queryParams.pageIndex : this.paramsForStudent.pageIndex;
        this.getMessageSearchPageForGradeStudent();// 班内学生
      } else if (this.classHeadBox[0].isShow) { // 班级课表
        this.arrangebtn.name = '新建课表';
        this.arrangebtn.nameValue = 'XJKB';
        this.arrangebtn.ModulePowerKey = 47;
        this.getStructedRuleDataByKey(0, this.curSelectedClassInfo.OLAPKey, (result) => {
          console.log(result.data, '班级课表11111');
          this.structedRuleData = result.data;
        });
      } else if (this.classHeadBox[2].isShow) { // 上课记录
        this.arrangebtn.name = '导出';
        this.arrangebtn.nameValue = 'DC';
        this.arrangebtn.ModulePowerKey = '';
        this.getMessageSearchPageForGradeClassRecord();// 上课记录
      } else if (this.classHeadBox[3].isShow) { // 统计分析
        this.arrangebtn.name = '';
        this.arrangebtn.nameValue = 'TJFX';
      }
    },
    // 点击班级
    doAfterClickClass (classItem) {
      if (this.$utils.isObjEqual(this.curSelectedClassInfo, classItem)) {
        // 防止重复点击
        return false;
      }
      console.log(classItem, '点击班级');
      this.$set(this.curSelectedClassInfo, 'isActive', false);
      this.paramsForStudent.pageIndex = 0;
      this.paramsForStudent.pageSize = 10;
      this.paramsForStudent.orderBy = 'StudentKeyValue';
      this.paramsForStudent.GradeClassKey = classItem.OLAPKey;
      this.curSelectedClassInfo = classItem;
      this.classGradeKey = classItem.OLAPKey;
      this.classInfo.OLAPKey = 0;
      this.getTheClassByInfo(this.curSelectedClassInfo.OLAPKey);
      this.doAfterSwitchClassHead();
    },
    // 查询选中班级下的学生(班内学生)
    getMessageSearchPageForGradeStudent () {
      this.paramsForStudent.orderBy = (this.paramsForStudent.orderBy == 'studentInfo' ? 'StudentKeyValue' : this.paramsForStudent.orderBy);
      this.paramsForStudent.OLAPKey = this.curSelectedClassInfo.OLAPKey;
      this.paramsForStudent.IsExportKey = 0;
      GradeMessageSearchArrangedPageForGradeStudent(this.paramsForStudent).then(result => {
        console.log(result.data, '班内学生');
        this.InGradeClassStudentList = result.data.PageDataList;
        this.totalNum = result.data.Total;
        if (this.InGradeClassStudentList.length > 0) {
          this.InGradeClassStudentList.forEach((o, index) => {
            let info = o.StudentKeyValue + ',' + o.CustomerPhoneName;
            o.studentInfo = info.split(',');
            o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
            o.OLAPKey = o.StudentKey;
            o.CustomerPhoneName = o.CustomerPhoneName ? o.CustomerPhoneName : '-';
            o.AdviserKeyValue = o.AdviserKeyValue ? o.AdviserKeyValue : '-';
            o.ArrangeSupport = o.ArrangeSupport ? o.ArrangeSupport : '-';
            o.addClassValue = o.TheClass.length > 0 ? o.TheClass.join() : '-';
            o.courseNotesValue = '';
            o.ApplyClass.forEach((obj, index) => {
              o.courseNotesValue = o.courseNotesValue + obj.CourseNameKeyValue + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
                obj.GradeClassKeyValue + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + obj.WeekKeyValue + '&nbsp;&nbsp;' + obj.ClassTimeName + '<br>';
            });
            // 学期卡课单学生 显示适用课时 有，支持展开
            if (o.CurrentClassHourCount == 0 && o.YearCardMaxDayCount > 0) {
              o.SemesterCalShow = '有';
            }
          });
        } else if (this.InGradeClassStudentList.length == 0 && this.paramsForStudent.pageIndex > 0) {
          this.paramsForStudent.pageIndex = this.paramsForStudent.pageIndex - 1;
          this.getMessageSearchPageForGradeStudent();
        }
        console.log(this.InGradeClassStudentList, '班内学生 this.InGradeClassStudentList');
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {

      });
    },
    // charCodeAt排序
    charCodeAtSort (val) {
      let num = 0;
      if (val) {
        for (var i = 0; i < val.length; i++) {
          num += Number(val[i].charCodeAt());
        }
      }
      return num;
    },
    // 班级课表获取所有循环课
    getStructedRuleDataByKey (CourseNameKey, GradeClassKey, callBack) {
      if (this.structedRuleData.length == 0) {
        this.isLoading = true;
      }
      GradeSearchStructedRule(CourseNameKey, GradeClassKey).then((result) => {
        if (callBack) {
          callBack(result);
        }
        this.isLoading = false;
      }).catch((error) => {
        this.isLoading = false;
        console.log(error, 'error');
      });
    },
    // 退班按钮
    doReturnAClassBtnClick (rowData, eventName, rowAtrribute) {
      if (rowData.StudentKey) {
        this.studentKey = rowData.StudentKey;
      }
      console.log(rowData, '按钮点击');
      let dataInfo = {
        moduleName: 'quitOrEnterClassForm',
        name: '学生退班',
        routerName: this.$route.name,
        routertitle: '',
        data: {
          dataInfo: { studentID: this.studentKey, classNameID: this.curSelectedClassInfo.OLAPKey, classTitle: '退班' }
        }
      };
      this.$bus.emit('needOpenRouter', dataInfo);
    },
    // 排课按钮
    doArrangeCourseBtnClick (rowData) {
      if (rowData.StudentKey) {
        this.studentKey = rowData.StudentKey;
      }
      let moduleInfo = {
        moduleName: 'studentArrangeCourseByClass',
        name: '跟班级排课',
        data: {
          studentID: this.studentKey,
          classNameID: this.curSelectedClassInfo.OLAPKey,
          className: this.curSelectedClassInfo.MainDemoName
        }
      };
      // 当UnitKey为true时 为课单详情，不需要预置课程
      if (rowData.UnitKey) {
        moduleInfo.classNameID = '';
        moduleInfo.className = '';
      }
      this.openCustomDialogByFactoryDialog(moduleInfo);
    },
    // 可用课时详情课消按钮
    doCourseDetialConsumeBtnClick (rowData) {
      let moduleInfo = {
        name: '课单课消',
        moduleName: 'buyClassDetailRecord',
        data: {
          studentKey: this.studentKey, // 学生ID
          courseBillKey: rowData.TableID // 课单ID
        }
      };
      this.openCommonCustomDialogByFactoryDialog(moduleInfo);
    },
    // 获取学生的课单详情(可用课时)
    getStudentAllCourseDetial (callBack) {
      if (!this.studentKey) {
        return;
      }
      getStudentAllCourse(this.studentKey).then((result) => {
        if (result.data) {
          this.expandRowData = [];
          if (result.data && result.data.length > 0) {
            result.data.forEach((o, index) => {
              o.Number = index + 1;
              o.ApplyClassKey = o.ApplyCorIDsName;
              o.ApplyClassKeyValue = o.ApplyCorName;
              o.sourceData = o.ApplyCorName.replace(/\，/g, ',').split(',');
              o.newPaidTime = o.PaidTime + '  ' + o.BuyTime.slice(0, 5);
              o.AdjustCount = Number(o.AdjustCount);
              // 可用课时或适用课时展开课单显示调整
              o.newCurrentCount = o.CurrentCount;
              o.newNumCount = o.NumCount;
              o.newSemesterCalText = o.UnitKeyValue + '卡';
              if (o.UnitKey != 5) { // 学期卡
                o.newCurrentCount = 0;
                o.newNumCount = 0;
              }
              if (o.StatusKey == 2 && o.CurrentCount > 0) {
                this.expandRowData.push(o);
              }
            });
          }
          console.log(result.data, 'result.data.PageDataList-可用课时');
          this.expandRowData.sort((x, y) => { return Date.new(y.PaidTime) - Date.new(x.PaidTime); });// 购买时间排序
          if (callBack) {
            callBack(this.expandRowData || []);
          }
        }
      });
    },
    // 新建课表
    doNewCrouse () {
      this.openCustomDialogByFactoryDialog({
        moduleName: 'newCircleCourseTable',
        data: {
          dataInfo: {
            BJPKIDKey: this.curSelectedClassInfo.OLAPKey,
            BJPKIDKeyValue: this.curSelectedClassInfo.MainDemoName
          }
        }
      });
    },
    // 学生进班/新建课表(按钮)
    addToClass (butItem) {
      switch (butItem.nameValue) {
        case 'XSJB':// 学生进班
          this.$dialog.open(this, {
            name: '加入班级',
            routerName: this.$route.name,
            routertitle: '',
            ModulePowerKey: 51,
            moduleName: 'addStudentToBJ',
            data: { dataInfo: { studentInfo: null, classInfo: this.curSelectedClassInfo } }
          });
          break;
        case 'XSDC':// 学生导出
          if (this.InGradeClassStudentList.length > 0) {
            let obj = {
              pageIndex: 0,
              pageSize: this.totalNum,
              orderBy: this.paramsForStudent.orderBy,
              sequence: this.paramsForStudent.sequence,
              searchText: this.paramsForStudent.searchText,
              SubscribeKey: this.paramsForStudent.SubscribeKey,
              FaceRecognition: this.paramsForStudent.FaceRecognition,
              QueryID: this.paramsForStudent.QueryID,
              GradeClassKey: this.paramsForStudent.GradeClassKey,
              IsExportKey: 1
            };
            this.paramsForStudent.IsExportKey = 1;
            let jsonFormate = {
              SheetTitle: '班内学生',
              clist: [
                { title: '学生名', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
                { title: '手机号', cName: 'CustomerPhoneName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
                { title: '销售', cName: 'AdviserKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
                { title: '可用课单', cName: 'ClassUnitCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
                { title: '可用课时', cName: 'CurrentClassHourCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
                { title: '加入班级', cName: 'TheClass', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
                { title: '排课备注', cName: 'ArrangeSupport', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
                { title: '关注', cName: 'SubscribeKey', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
                { title: '最近上课', cName: 'LastSignTim', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
              ]
            };
            console.log(jsonFormate, 'jsonFormate');
            GradeMessageSearchArrangedPageForGradeStudent(obj, jsonFormate).then(result => {
              console.log(result.data, '导出');
              window.location.href = this.$store.getters.APIURL + result.data.url;
            }, error => {
              layer.alert(error.msg);
            });
          } else {
            layer.alert('暂无数据导出');
          }
          console.log('导出');

          // ExportGradeStudent(this.curSelectedClassInfo.OLAPKey).then(result => {
          //   console.log(result.data, '学生导出');
          //   window.location.href = this.$store.getters.APIURL + result.data.url;
          // }, error => {
          //   layer.alert(error.msg);
          // });
          break;

        case 'XJKB':// 新建课表
          this.doNewCrouse();
          break;
        case 'DC':// 导出
          this.exportOperation();
          break;
        default:
          break;
      }
    },
    getDataList (startTime, endTime, isShowHistory) {
      this.classRecordParams.StartTime = startTime;
      this.classRecordParams.EndTime = endTime;
      this.isShowHistory = isShowHistory;
      this.getMessageSearchPageForGradeClassRecord(); // 上课记录
    },
    courseInfoFun (startTime, endTime, isShowHistory, isCourseInfo) {
      this.classRecordParams.StartTime = startTime;
      this.classRecordParams.EndTime = endTime;
      this.isShowHistory = isShowHistory;
      this.isCourseInfo = isCourseInfo;
      this.getMessageSearchPageForGradeClassRecord(); // 上课记录
    },
    // 导出操作-上课记录
    exportOperation () {
      if (this.classRecordListL.length > 0) {
        let fn = '';
        let courseTitle = '';
        let jsonFormate = {};
        if (this.isCourseInfo) {
          courseTitle = '上课记录 - 课次记录';
          if (this.isShowHistory) {
            fn = MessageSearchPageForGradeClassRecordNewHistory;
          } else {
            fn = MessageSearchPageForGradeClassRecordNew;
          }
        } else {
          courseTitle = '上课记录 - 结转课消';
          if (this.isShowHistory) {
            fn = MessageSearchPageForGradeClassDeductRecordHistory;
          } else {
            fn = MessageSearchPageForGradeClassDeductRecord;
          }
        }

        let obj = this.$utils.parseJson(this.classRecordParams);
        obj.IsExportKey = 1;
        obj.pageIndex = 0;
        obj.pageSize = 0;
        if (this.isCourseInfo) {
          jsonFormate = {
            SheetTitle: '上课记录 - 课次记录',
            clist: [
              { title: '上课日期', cName: 'ClassDate', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '上课时间', cName: 'SchoolTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '班名', cName: 'GradeClassKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '课名', cName: 'CourseNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '老师名', cName: 'MTeacherKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '应到学生', cName: 'ArrangedNumCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '出勤人次', cName: 'ArrivedNumCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '未出勤人次', cName: 'TruantNumCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '出勤率', cName: 'AttendanceRate', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '消耗课时', cName: 'DeductCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '结转课消', cName: 'DeductAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '生均消费', cName: 'AvergeAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
            ]
          };
        } else {
          jsonFormate = {
            SheetTitle: '上课记录 - 结转课消',
            clist: [
              { title: '上课日期', cName: 'ClassDate', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
              { title: '上课时间', cName: 'SchoolTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '考勤日期', cName: 'AttenceDate', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '考勤时间', cName: 'AttenceTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '考勤时差', cName: 'TimeDifference', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '操作人', cName: 'AttenceOpratorKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '班名', cName: 'GradeClassKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '课名', cName: 'CourseNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '老师名', cName: 'MTeacherKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '学生名', cName: 'StudentNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '考勤结果', cName: 'AttenceStatusKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '消耗课时', cName: 'DeductCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '结转课消', cName: 'DeductAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '消耗课单', cName: 'NumberName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '课单类型', cName: 'ClassUnitType', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
              { title: '适用课程', cName: 'ApplyCorName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
            ]
          };
        }
        console.log(jsonFormate, 'jsonFormate');
        fn(obj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.classRecordParams.IsExportKey = 0;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    //  多学生安排成功
    doAfterArrangeOrApplyCourseSuccess () {
      this.getAllClassList();
    },
    busUpdateStructedRuleData (arg2) {
      console.log(arg2, 'busUpdateStructedRuleData');
      this.seletedStructuredRuleKey = Number(arg2.StructuredRuleKey) || 0;
      this.getStructedRuleDataByKey(
        0,
        this.curSelectedClassInfo.OLAPKey,
        (result) => {
          console.log(result.data, 'AfterNewCourseTable');
          this.structedRuleData = result.data;
        }
      );
    },
    registerBusEvent () {
      // 批量修改课(停课/恢复课)操作
      this.$bus.on(this, 'AfterBatchEditCourses', () => {
        console.log('停课/恢复课');
        this.getAllClassList();// 上课记录
      });
      // 修改课单
      this.$bus.on(this, 'AfterEditCourse', () => {
        console.log('修改课单');
        this.getAllClassList();// 上课记录
      });
      // 添加学生进课
      this.$bus.on(this, 'AfterStudentAddToCourse', (scheduleCourseKey, studentKey) => {
        console.log('添加学生进课');
        this.getAllClassList();// 上课记录
      });
      // 变更考勤
      this.$bus.on(this, 'AfterChangeAttendanceStatus', (scheduleCourseKey, studentKey) => {
        console.log('变更考勤');
        this.getAllClassList();// 上课记录
      });
      // 批量考勤
      this.$bus.on(this, 'AfterBatchAttendance', (scheduleCourseKey, studentKey) => {
        console.log('批量考勤');
        this.getAllClassList();// 上课记录
      });
      // 修改学生档案的学生信息成功后触发回调
      this.$bus.on(this, 'AfterStudentUpdate', (studentKey) => {
        if (studentKey > 0) {
          this.getAllClassList(this.paramsForStudent);
        }
      });
      // 刷新排课数据
      this.$bus.on(this, 'afterArrangeScheduleCourse', () => {
        this.getAllClassList(this.paramsForStudent);
      });
      // 刷新循环课排课数据
      this.$bus.on(this, 'afterArrangeCirculationCourse', () => {
        this.getAllClassList(this.paramsForStudent);
      });
      this.$bus.on(this, 'AfterStudentApplyCourse', (applyCourseInfo) => {
        this.getAllClassList(this.paramsForStudent);
      });
      this.$bus.on(this, 'AfterArrangeForSingleStudent', (type, courseID, studentID) => {
        this.getAllClassList(this.paramsForStudent);
      }
      );
      this.$bus.on(this, 'AfterCourseNameApplyCourse', () => {
        this.getAllClassList(this.paramsForStudent);
      });
      // 课消记录
      this.$bus.on(this, 'AfterCourseConsumeUpDate', (result) => {
        this.getAllClassList(this.paramsForStudent);
      });
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'classGradeApplycourse') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      // 新建课表触发.
      this.$bus.on(this, 'AfterNewCourseTable', (ruleID, obj) => {
        this.busUpdateStructedRuleData(obj);
        this.getAllClassList();
      });
      // 修改课表或者其他触发.
      this.$bus.on(this, 'AfterEditCircleCourse', (ruleID, obj) => {
        this.busUpdateStructedRuleData(obj);
        this.getAllClassList();
      });
      // 新建循环课成功后 批量修改排课计划
      this.$bus.on(this, 'AfterBatchEditStructuredPlanList',
        (courseID, classGradeID, planList) => {
          this.busUpdateStructedRuleData(obj);
          this.getAllClassList(this.paramsForStudent);
        }
      );
      // 批量删除排课计划
      this.$bus.on(this, 'AfterBatchDeleteStructuredPlanList',
        (courseID, classGradeID, planList) => {
          if (classGradeID == this.curSelectedClassInfo.OLAPKey) {
            this.getStructedRuleDataByKey(0, classGradeID, (result) => {
              console.log(result.data, 'getStructedRuleDataByKey');
              this.structedRuleData = result.data;
            });
          }
          this.getAllClassList(this.paramsForStudent);
        }
      );
      // 学生退出加入循环课操作 goto
      this.$bus.on(this, 'AfterCancelApplyClassCourseByRule', (ruleID, obj) => {
        this.busUpdateStructedRuleData(obj);
        // this.getAllClassList(this.paramsForStudent);
      });
      // 退出循环课 AfterAbortCircleCorse
      this.$bus.on(this, 'AfterAbortCircleCorse', (ruleID, obj) => {
        console.log('bus-AfterAbortCircleCorse');
        this.busUpdateStructedRuleData(obj);
        this.getAllClassList(this.paramsForStudent);
      });
      // 退出班级/加入班级后
      this.$bus.on(this, 'afterQuitOrAddClass', (classID, studentID) => {
        console.log('afterQuitOrAddClass');
        this.getStructedRuleDataByKey(0, this.curSelectedClassInfo.OLAPKey, (result) => {
          this.structedRuleData = result.data;
        });
        this.getAllClassList(this.paramsForStudent);
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'classGradeApplycourse') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.classGradeApplycourseDialogFactory) {
        this.$refs.classGradeApplycourseDialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.dialogFactoryCommon) {
        this.$refs.dialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>
<style scoped>
.drop-down-item {
  /* background-color: #f2f2f2; */
  height: 100%;
  line-height: 36px;
  padding: 0;
  border-radius: 8px;
}

.drop-down-item .el-input {
  height: 36px;
  line-height: 36px;
}

.drop-down-item input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 99%;
}

.drop-down-item .el-icon-arrow-up:before {
  content: '\E78F';
  color: #b7b7b7;
}
.el-table__footer-wrapper,
.el-table__header-wrapper {
  width: 100%;
  overflow: hidden !important;
}
.left_search_box .el-select {
  width: 87px;
  border-radius: 4px;
  border: 1px solid #ececec;
  border-right: none;
  padding-left: 0px;
  height: 39px;
}
.left_search_box .el-select .el-input__inner {
  padding: 0 !important;
}
.left_search_box .left_search {
  width: 157px !important;
  position: absolute;
  top: 0px;
  left: 93px;
  border-radius: 0px 4px 4px 0 !important;
  height: 39px;
}
.course_class_summarizing_left {
  width: 252px !important;
}
.course_class_summarizing_right {
  margin-left: 50px;
}
.left_content >>> .left_list {
  padding: 10px 0 8px !important;
  padding-left: 28px !important;
}
.left_content >>> .left_list .left_list_title {
  line-height: 32px !important;
}
.select_class_type >>> span {
  width: 45px !important;
}
</style>
