<template>
  <div v-if="isShowMainForm">
    <custom-dialog title="预警忽略记录"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowMainForm"
                   :before-close="doShowPopupHideClick">
      <!-- <div class="defult_dialog_header"  >
          <span class="defult_dialog_header_return mint-toast-icon mintui mintui-back fl" @click.stop="$router.go(-1)"></span>
          <span class="defult_dialog_header_font">预警忽略记录</span>
        </div> -->
      <!-- 报名记录/潜在客户 -->
      <!-- <div class="course_block_gray">
          <span class="font_red">*</span>带<img class="tick" src="../../../../../public/image/open_green.png">学生是已报名学生。
        </div> -->
      <!-- <div class="course_block_gray">
          <span class="font_red">*</span>点击学生给学生排课和报课；点击可用课时等标题快捷排序。
        </div> -->
      <div class="content_marginTop_30 content_marginRL single_shadow btn_marginBottom_30 overflow_hidden">
        <!-- <div class="class_common_list_search">
          <input type="text"
                 v-model="searchData.searchText"
                 placeholder="请输入姓名、姓名首字母、手机号">
          <div class="btn_hover_bg_blue search_btn "
               @click="search">
            搜索
          </div>
        </div> -->
        <input-search-contain-btn v-model="searchData.searchText"
                                  :placeholder="'请输入姓名、姓名首字母、手机号'"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <div class="recording_data_table">
          <div class="recording_data_thead clearfix">
            <div class="recording_data_th"
                 style="width: 125px;">
              <div>时间</div>
            </div>
            <div class="recording_data_th"
                 style="width:  130px;">
              <div>预警类型</div>
            </div>
            <div class="recording_data_th"
                 style="width:  150px">
              <div>预警内容</div>
            </div>
            <div class="recording_data_th"
                 style="width: 100px;">
              <div>操作人</div>
            </div>
          </div>
          <div v-if="dataList.length>0"
               class="recording_data_tbody"
               @scroll="scrollTOP">
            <div class="recording_data_tr"
                 v-for="(item,key) in dataList"
                 :key="key">
              <div class="recording_data_td"
                   style="width:125px">
                <div>{{vDate(item)}}</div>
                <div>{{vHour(item)}}</div>
              </div>
              <div class="recording_data_td"
                   style="width: 130px">
                <div :title="item.WarningTypeKeyValue">{{item.WarningTypeKeyValue}}</div>
                <!-- <div>{{$utils.phoneModulePower(item.CustomerPhoneName)}}</div>  -->
              </div>
              <div class="recording_data_td"
                   style="width: 150px">
                <div :title="item.WarningSupport">{{item.WarningSupport}}</div>
              </div>
              <div class="recording_data_td"
                   style="width: 100px">
                <div :title="item.OperateAgentKeyValue">{{item.OperateAgentKeyValue}}</div>
              </div>
            </div>
            <div class="all_list_bottom">
              <span v-if="isLoadEnd"
                    class="loading">加载中...</span>
              <span v-if="noMore"
                    class="complete">已显示全部</span>
            </div>
          </div>
          <div v-else
               style="height:506px">
            <div class="monitoring_content_nodata">
              <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
            </div>
          </div>
        </div>

      </div>
    </custom-dialog>
  </div>
</template>
<script>

import { getIgnoreWarningList } from '../../../API/workbench';
export default {
  data () {
    return {
      searchData: {
        pageIndex: 0,
        pageSize: 20,
        searchText: '',
        orderBy: '',
        sequence: '',
        startime: '',
        endtime: ''
      },
      dataList: [],
      isLoadEnd: false,   // 判断滚动事件触发时是否接口已经处理完毕,处理完毕再执行滚动事件.
      noMore: false,
      isShowMainForm: false
    };
  },
  components: {

  },
  created () {
    // this.getPotentialCustomerData(this.searchData);
  },
  computed: {

  },
  watch: {
    'searchData.searchText': {
      deep: true,
      handler: function (c, o) {
        if (!c) {
          this.dataList = [];
          this.searchData.pageIndex = 0;
          this.searchData.pageSize = 20;
          this.getPotentialCustomerData(this.searchData);
        }
      }
    }
  },
  methods: {
    // 主表单开关
    doShowPopupHideClick () {
      this.searchData.pageIndex = 0;
      this.searchData.searchText = '';
      this.dataList = [];
      this.isShowMainForm = false;
    },
    doShowPopupShowClick () {
      this.isShowMainForm = true;
      // document.body.scrollTop = 0;
      this.getPotentialCustomerData(this.searchData);
    },
    getPotentialCustomerData (searchData) {
      this.isLoadEnd = true;
      getIgnoreWarningList(searchData).then(result => {
        console.log(result.data, 'getPotentialCustomerData');
        result.data.PageDataList.forEach(o => {
          this.dataList.push(o);
        });
        if (result.data.PageDataList.length < 20) {
          console.log('到底');
          this.noMore = true;
        } else {
          this.searchData.pageIndex++;
        }
        this.isLoadEnd = false;
      });
    },
    vDate (item) {
      if (item && item.GenerateTime) {
        return item.GenerateTime.substring(0, 10);
      }
    },
    vHour (item) {
      if (item && item.GenerateTime) {
        return item.GenerateTime.substring(10, 16);
      }
    },

    // 点击搜索按钮.
    search () {
      this.searchData.pageIndex = 0;
      if (this.searchData.searchText == '') {
        this.searchData.pageSize = 20;
      } else {
        this.searchData.pageSize = 0;
      }
      this.noMore = false;
      this.dataList = [];
      this.getPotentialCustomerData(this.searchData);
    },
    // 滚动加载更多
    scrollTOP (e) {
      let ETarget = e.target;
      if ((ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight || (ETarget.scrollTop + 1) == ETarget.scrollHeight - ETarget.clientHeight) && !this.searchData.searchText && !this.isLoadEnd && !this.noMore) {
        // console.log('scrollTOP', this.noMore);
        this.getPotentialCustomerData(this.searchData);
      }
    }
  }
};
</script>

