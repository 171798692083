<template>
  <div class="margin_left_right_div">
    <div class="template_minOrmax_width_div">
      <div class="payment_statistics_view height_type all_view">
        <top-report></top-report>
        <table-content></table-content>
        <div>
          <dialog-factory ref="operatingRevenueStatisticsFactory"
                          :key="'operatingRevenueStatistics'"
                          :routerName="'operatingRevenueStatistics'"></dialog-factory>
          <dialog-factory-common ref="operatingDialogFactoryCommon"></dialog-factory-common>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import topReport from './top-report';
import tableContent from './table-content';
import dialogFactory from '../../common/dialog-factory';
export default {
  name: 'operatingRevenueStatistics',
  data () {
    return {

    };
  },
  components: {
    topReport,
    tableContent,
    dialogFactory
  },
  created () {
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'operatingRevenueStatistics') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'operatingRevenueStatistics') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });
  },
  methods: {
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log(dialogInfo, 'operatingRevenueStatistics');
      if (this.$refs.operatingRevenueStatisticsFactory) {
        this.$refs.operatingRevenueStatisticsFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.operatingDialogFactoryCommon) {
        this.$refs.operatingDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>