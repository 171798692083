// chunkedUpload.js
import store from '../store';
import { md5 } from '../API/md5';
import {
  OSSUploadFilesPublic,
  OSSUploadFilesPrivateKJ
} from '../utils/ossupLoadTextImg';
export function uploadMultipart (file, type, fileData) {
  let totalSize = file.size;
  let ext = file.name.split('.')[file.name.split('.').length - 1];
  let stringmd5 = store.getters.SaaSClientInfo.OLAPKey + totalSize + ext + file.lastModified;
  let md5Text = md5(stringmd5);
  return OSSUploadFilesPublic(type, md5Text, file, fileData);
}

export function uploadMultipartHQ (file, type, fileData, coursewareParameter) {
  return OSSUploadFilesPrivateKJ(file, type, fileData, coursewareParameter);
}
