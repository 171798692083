<template>
  <div class="img_box">
    <div class="content_bannber">
      <div class="content_bannber_icon button left_btn" :class="{displayNone:currentNum == 1}" @click="backClick"></div>
      <div class="content_banner_box button">
        <img :src="vUrl" class="content_banner_box_img" />
        <div class="watermark">
          {{watermark}}
        </div>
      </div>
      <div class="content_bannber_icon button right_btn" :class="{displayNone:currentNum == dataInfo.DurationNum}" @click="nextClick"></div>
    </div>
    <div class="content_bottom">
      <div class="content_bottom_tag">{{currentNum}}/{{dataInfo.DurationNum}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      currentNum: 1
    };
  },
  props: {
    dataInfo: Object,
    watermark: String
  },
  computed: {
    vUrl () {
      if (this.dataInfo.AttachViewUrl) {
        if (this.dataInfo.AttachViewUrl.indexOf('data:image/png;') != -1) {
          return this.dataInfo.AttachViewUrl;
        } else {
          return this.dataInfo.AttachViewUrl + '/' + this.currentNum + '?sign=' + this.$store.getters.signData.Sign;
        }
      } else {
        return this.dataInfo.CoverUrl;
      }
    }
  },
  watch: {
    'dataInfo.AttachViewUrl' (n, o) {
      if (o && n != o) {
        this.currentNum = 1;
      }
    }
  },
  mounted () {
    this.$set(this.dataInfo, 'currentNum', this.currentNum);
  },
  methods: {
    backClick () {
      if (this.currentNum > 1) {
        this.currentNum = this.currentNum - 1;
        this.dataInfo.currentNum = this.currentNum;
      }
    },
    nextClick () {
      if (this.currentNum < this.dataInfo.DurationNum) {
        this.currentNum = this.currentNum + 1;
        this.dataInfo.currentNum = this.currentNum;
      }
    }
  }
};
</script>