<template>
  <!-- 门店设置 -->
  <div class="margin_left_right_div">
    <div v-if="isLoad"
         class="width_fixed"
         style="width: auto;">
      <div class="workbench_view clearfix template_minOrmax_width_div">
        <div class="view_block padding_l"
             style="width: 43%;">
          <div class="massive_bg">
            <div class="">
              <div class="workbench_view_title">
                <span>基础信息设置</span>
                <span class="weixin_type font_blue fr"
                      @click="showQuickEnable()">快捷启用</span>
              </div>
              <div class="mendian_setting_content">
                <mendian-teaching-set></mendian-teaching-set>
              </div>
            </div>
            <div class="">
              <div class="mendian_setting_content border_top">
                <mendian-manage-set></mendian-manage-set>
              </div>
            </div>
            <div class=" ">
              <div class="mendian_setting_content border_top">
                <mendian-sell-set></mendian-sell-set>
              </div>
            </div>
          </div>
        </div>
        <div class="view_block"
             style="width: 28.3%;">
          <mendian-wkt-setting :mendianInfo="mendianInfo"
                               :isReadonly="isReadonly"
                               @doWeChatSettingClick="doWeChatSettingClick"
                               @doTemplateSettingClick="doTemplateSettingClick"></mendian-wkt-setting>
          <div v-if="mendianInfo.IsOpenMicroClassKey == 0"
               class="no_wkt massive_bg">
            <img src="../../../../../public/image/no_wkt.png"
                 alt="">
          </div>
        </div>
        <div class="view_block padding_r"
             style="width: 28.3%;">
          <div class="massive_bg">
            <div class="mendian_info_box clearfix"
                 @click="mendianInfoClick">
              <div class="mendian_info_logo fl">
                <img :src="headImg"
                     alt="../../../../../public/image/no_logo@2x.png"
                     :onerror="defaultImg" />
              </div>
              <div class="mendian_info_text fl">
                <div class="mendian_info_name"
                     :title="mendianInfo.MainDemoName">
                  {{ mendianInfo.MainDemoName }}
                </div>
                <div class="mendian_info_linkman">
                  <span class="mendian_info_IDs">门店 ID:<span class="IDspan">&nbsp;&nbsp;{{mendianInfo.SaaSClientNO}}</span></span>
                </div>
                <div class="mendian_info_linkman">
                  <span class="mendian_info_IDs">账户有效期：<span class="IDspan"> <span v-if="Date.new( Date.new(mendianInfo.ExpireDateTime).setDate( Date.new(mendianInfo.ExpireDateTime).getDate()+1)) > Date.new()">余</span>
                      <span v-else>过期</span>
                      <span :class="Date.new(mendianInfo.ExpireDateTime) > Date.new()?'font_black':'font_red'">{{vExpireDateTime}}</span>天</span></span>
                </div>
                <div class="mendian_info_btn"></div>
              </div>
              <div class="phoneNumBox">
                <img class="icon_item"
                     :src="require('../../../../../public/cw-img/phone_icon.png')" />
                <span class="IDspan phone_text">{{ mendianInfo.Telephone || "&nbsp;" }}</span>
              </div>

              <div class="phoneNumBox1"
                   v-if="mendianInfo.Address.length>0">
                <img class="icon_item"
                     :src="require('../../../../../public/cw-img/address_icon.png')" />
                <span class="IDspan phone_text">{{ mendianInfo.Address || "&nbsp;" }}</span>
              </div>
              <div class="mendian_info_general">{{vMendianGeneral}}</div>
            </div>
            <div class="flowb">
              <flow-balance :dataInfo="mendianInfo"></flow-balance>
            </div>
            <div class="mendian_photo pr">
              <div id="screanContent"
                   style="z-index:1;position: absolute !important;"></div>
              <div v-if="vBrandImgSupport != ''">
                <img :style="{ width:'100%',  height:'100%', 'position': 'absolute', 'opacity': 0,'bottom': '0px','left': '0px'}">
                <input v-show="vHasPower"
                       type="file"
                       title=""
                       ref="uploading"
                       name="file"
                       accept="image/*"
                       @change="selectImg($event)"
                       class="mendian_my_view_chg_pic my_view_chg_index button"
                       style="top: 155px; right: 20px;opacity:0;z-index: 10"
                       data-type="userIconImg">

                <img :src="vBrandImgSupport"
                     alt=""
                     style="position: absolute;top:0;z-index: 2;"
                     crossOrigin="anonymous">
                <div class="mendian_my_view_chg_pic "
                     @click="checkPower">更换图片</div>
              </div>
              <div v-if="vBrandImgSupport == ''"
                   class="mendian_photo_nodata"
                   @click.stop="checkPower">
                <img :style="{ width:'100%',  height:'100%', 'position': 'absolute', 'opacity': 0,'bottom': '0px','left': '0px'}">
                <div>
                  点击上传门店形象照片
                </div>
                <div>
                  尺寸：600*336
                </div>
                <input v-show="vHasPower"
                       type="file"
                       title=""
                       name="file"
                       ref="uploading"
                       accept="image/*"
                       class="button my_view_chg_index"
                       @change="selectImg($event)"
                       style="width:100%;height:100%;opacity:0;position: absolute;top: 0;left: 0;"
                       data-type="userIconImg">
              </div>
            </div>
            <div class="">
              <div class="mendian_setting_content">
                <mendian-system-set :mendianInfo="mendianInfo"
                                    @modifySettingList="modifySettingList"
                                    @modifySetting="modifySettingAfter"></mendian-system-set>
              </div>
            </div>
          </div>
          <div v-if="mendianInfo.WktInfo && mendianInfo.WktInfo.sec_website"
               class="massive_bg"
               style="margin-top:15px">
            <div class="workbench_view_title">
              <span>扫码签到二维码</span>
            </div>
            <div class="sign_QRcode_img">
              <!-- <div id="signQRcode"></div> -->
              <qrcode-vue :value="codeValue"
                          :size="codeValue?124:0"
                          level="L"
                          id="signQRcode" />
              <a :href="signQRcodeUrl"
                 download="signQRcode"
                 id="signQRcodeDownload"></a>
            </div>

            <div class="sign_QRcode_text">
              <span class="font_red">*</span>点击二维码图片可下载。
            </div>
          </div>
        </div>
        <custom-dialog :title="'裁切图片'"
                       class=""
                       :visible.sync="isChgPic"
                       :before-close="closeChgPic">

          <vue-crop-img :selectPicUrl="selectPicUrl"
                        :cutImgWidth="600"
                        :cutImgHeight="336"
                        :uploadTargetPath="'WKT'"
                        @onConfirmClick="setPicCorp"
                        @onCancelClick="clearPicData"></vue-crop-img>
        </custom-dialog>
        <div class="popup_window"
             v-show="isChgPic"></div>
        <custom-dialog :title="'修改门店设置'"
                       class="new_class defult_dialog"
                       :visible.sync="isxgmdzlShow"
                       :before-close="doCloseShowForm">
          <div class="form_info_edit form_info_list"
               id="mendain_set_form">
            <div id="screanContent"
                 style="z-index:1;position: absolute !important;right: 45px; opacity: 0"></div>
            <input-upload-img :ImgURl="mendianInfo.LogoPath?mendianInfo.LogoPath:''"
                              :formTitle="'门店logo'"
                              :imgHeight="100"
                              :imgWidth="100"
                              :imgstyle="'width: 38px;height: 38px;'"
                              @doSetPicCorp="doSetPicCorpHeadImg"></input-upload-img>

            <input-phone :formTitle="'机构电话'"
                         :formPlaceholder="'必填'"
                         :required="true"
                         :numberType="'text'"
                         :readonly="isReadonly"
                         v-model="editMendianInfo.Telephone"></input-phone>

            <input-search-cities :formTitle="'所在地区'"
                                 :required="false"
                                 :readonly="isReadonly||isCampStore"
                                 :selectedInfo="locationAreaInfo"
                                 @selectedItem="changeRegion"></input-search-cities>
            <input-text :formTitle="'详细地址'"
                        :readonly="isReadonly||isCampStore"
                        :required="false"
                        :formPlaceholder="'详细地址'"
                        v-model="editMendianInfo.DetailAddress"></input-text>
            <!-- <input-text :formTitle="'门店地址'"
                      :readonly="isReadonly||isCampStore"
                      :required="true"
                      :formPlaceholder="'门店地址'"
                      v-model="editMendianInfo.Address"></input-text> -->
            <input-dialog :formTitle="'地图定位'"
                          :customPlaceholder="true"
                          :formPlaceholder="'设置'"
                          :formInputHint="locationInfo[0].SetContent?'':'未设置定位'"
                          :required="false"
                          :readonly="isReadonly || isCampStore"
                          :hiddenInput="true"
                          :class="locationInfo[0].SetContent?'content':''"
                          :value="locationInfo[0].SetContent ||'设置'"
                          @showEldilog="showLocationSet()"></input-dialog>

            <div class="mendian_location pr"
                 v-if="vLocationImg != ''">
              <img :src="vLocationImg"
                   crossOrigin="anonymous"
                   alt="">
            </div>
            <div class="bottom_text_content"
                 v-if="isCampStore">
              <div class="bottom_text_content_text"
                   style="padding-bottom:10px">
                <div class="bottom_text_content_text_content">
                  直营店 请从总部修改地址
                </div>
              </div>
            </div>
          </div>
          <!-- 按钮组 no_border_bottom-->
          <div v-if="!isReadonly">
            <save-cancel-btn-group :isSingleSubmit="true"
                                   :singleSubmitType="'dynamic'"
                                   @confirmClick="confirmClick"
                                   @cancelClick="cancelClick"></save-cancel-btn-group>
          </div>
          <div v-else
               class="on_form_footer_btn_group"></div>
        </custom-dialog>

        <custom-dialog :title="'位置'"
                       :visible.sync="isShowLocationForm"
                       :before-close="closeLocationFormPopup">
          <form-location :locationInfo="locationInfo"
                         @close="closeLocationFormPopup"
                         @setLocationInfo="setLocationInfo"></form-location>
        </custom-dialog>

        <!-- 弹窗区 -->
        <custom-dialog :title="'微信设置'"
                       class=""
                       :width="'972px'"
                       :top="'100'"
                       :visible.sync="isWeChatSettingFromShow"
                       :before-close="doCloseShowForm">
          <weChat-setting-dialog :mendianWKTInfo="mendianInfo"
                                 @confirmEdit="getMendianInfo"
                                 @cancelEdit="doCloseShowForm"></weChat-setting-dialog>
        </custom-dialog>

        <custom-dialog :title="templateTypeInfo.name"
                       class=""
                       :moduleDescription="''"
                       :visible.sync="isBeforeClassSettingFrom"
                       :before-close="doCloseShowForm">
          <!-- 模块重点说明 -->
          <div v-if="templateTypeInfo.name=='家校通知'">
            <module-description :textTips="'如果关注系统公众号后可微信推送通知给家长。'"
                                :btnTips="'查看教程'"
                                :btnUrl="'https://mp.weixin.qq.com/s/j8prgT9lOyJ19RvUbxKLlA'"></module-description>
          </div>
          <module-message-setting :mendianInfo="mendianInfo"
                                  :selectEventType="templateTypeInfo"
                                  @cancelEdit="doCloseShowForm"
                                  @modifySetting="modifySettingAfter"></module-message-setting>
        </custom-dialog>
      </div>

      <div>
        <added-shortcut-start-setting ref="shortcutStartSetting"></added-shortcut-start-setting>
      </div>

      <div>
        <dialog-factory ref="dialogFactory"
                        :key="'mendiansettingform'"
                        :routerName="'mendiansettingform'"></dialog-factory>
      </div>

      <div>
        <dialog-factory-common ref="dialogFactoryCommon"></dialog-factory-common>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GetSaaSClientMsg,
  updateTheSaaSClientMsg,
  ModifySetting,
  WEBVModifySaaSClientSetting
} from '../../../../API/workbench.js';
import mendianSystemSet from './mendian-system-set';
import mendianTeachingSet from './mendian-teaching-set';
import mendianManageSet from './mendian-manage-set';
import mendianSellSet from './mendian-sell-set';
import mendianWktSetting from './mendian-wkt-setting';
import dialogFactory from '../../../common/dialog-factory';
import weChatSettingFrom from './weChat-setting-from';
import weChatSettingDialog from './weChat-setting-dialog';
import moduleMessageSetting from './module-message-setting/index';
import addedShortcutStartSetting from '..//added-shortcut-start-setting/index';
import flowBalance from './flow-balance/index.vue';
import QrcodeVue from 'qrcode.vue';
// import myMixin from '../../../../assets/mixin/destory-cache';
export default {
  name: 'mendiansettingform',
  // mixins: [myMixin],
  data () {
    return {
      codeValue: '',
      isLoad: false,
      isWeChatSettingFromShow: false, // 微信设置
      isBeforeClassSettingFrom: false, // 课前设置/签到通知
      isShowLocationForm: false, // 位置
      locationInfo: [
        { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '14' }, // 定位地址信息
        { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '15' }, // 定位经纬度信息
        { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '16' }// 定位截图信息
      ],
      locationAreaInfo: [], // 地址
      editMendianInfo: {
        // 修改门店资料
        MainDemoName: '',
        Telephone: '',
        Address: '',
        DetailAddress: ''

      },
      isChgPic: false,
      mendianInfo: {}, // 门店信息
      dataMDInfo: {}, // 门店信息
      isxgmdzlShow: false,
      chengeUrl: '', // 变更的图片地址,
      token: null,
      selectPicUrl: '',          // 选中图片url
      templateTypeInfo: {// 模板类型
        name: '', // 模板名称
        eventId: '', // 模板事件ID
        templateKey: '', // 通知开关
        setTypeKey: ''// 通知类型
      },
      signQRcodeUrl: ''
    };
  },
  components: {
    mendianSystemSet,
    mendianTeachingSet,
    mendianManageSet,
    mendianSellSet,
    dialogFactory,
    weChatSettingFrom,
    moduleMessageSetting,
    mendianWktSetting,
    addedShortcutStartSetting,
    weChatSettingDialog,
    flowBalance,
    QrcodeVue
  },
  created () {
    this.getMendianInfo();
    this.token = this.$store.getters.token;
    this.registerBusEvent();
  },
  mounted () {

  },
  activated () { },
  watch: {
    isLoad (n, o) {
      if (n) {
        this.$nextTick(() => {
          this.createSignQRcode();
        });
      }
    }
  },
  computed: {
    // 是否连锁直营门店。
    isCampStore () {
      return this.mendianInfo.TypeKey == 8;
    },
    //  2-精准;3-通用;4-高级;5-连锁;
    vMendianGeneral () {
      if (this.mendianInfo.EditionKey == 2) {
        return '精准版';
      } else if (this.mendianInfo.EditionKey == 3) {
        return '旗舰版';
      } else if (this.mendianInfo.EditionKey == 4) {
        return '高级版';
      } else if (this.mendianInfo.EditionKey == 5) {
        return '连锁版';
      }
    },
    vMendianVersioneTY () {
      return this.$store.getters.isSysVersionTY;
    },
    vHasPower () {
      return this.$utils.CheckModulePower(this.$store.getters.RolePowerList, this.$initJson.PowerName.Power_MenDianSetting);
    },
    isReadonly () {
      return !this.vHasPower;
    },
    // 定位截图
    vLocationImg () {
      if (this.mendianInfo && this.locationInfo[2].SetContent) {
        return this.$store.getters.CDNURL + this.locationInfo[2].SetContent;
      } else {
        return '';
      }
    },
    vBrandImgSupport () {
      if (this.mendianInfo && this.mendianInfo.BrandPath) {
        if (this.mendianInfo.BrandPath.indexOf('http') > -1) {
          return this.mendianInfo.BrandPath;
        } else {
          return this.$store.getters.CDNURL + this.mendianInfo.BrandPath;
        }
      } else {
        return '';
      }
    },
    // logo
    headImg () {
      if (!this.mendianInfo.LogoPath) {
        this.mendianInfo.LogoPath = '';
      }
      if (this.mendianInfo.LogoPath.indexOf('http') > -1) {
        return this.mendianInfo.LogoPath;
      } else {
        // return 'http://zxbeta.zhixuerj.com/' + this.mendianInfo.LogoPath;
        return this.$store.getters.CDNURL + this.mendianInfo.LogoPath;
      }
    },
    changeHeadImg () {
      if (!this.chengeUrl) {
        this.chengeUrl = '';
      }
      if (this.chengeUrl.indexOf('http') > -1) {
        return this.chengeUrl;
      } else {
        // return 'http://zxbeta.zhixuerj.com/' + this.mendianInfo.LogoPath;
        return this.$store.getters.CDNURL + this.chengeUrl;
      }
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' +
        require('../../../../../public/image/no_logo@2x.png') +
        '"'
      );
    },
    vExpireDateTime () {
      let today = this.$utils.formatDateToLine(Date.new()).replace(/\-/g, '/');
      if (this.mendianInfo.ExpireDateTime) {
        let endDate = this.mendianInfo.ExpireDateTime.replace(/\-/g, '/');
        if (this.$utils.diffTwoTime(endDate, today) == 0) {
          console.log('11111', Date.new(this.mendianInfo.ExpireDateTime), Date.new());
          return 1;
        } else {
          if (endDate > today) {
            console.log('2222');
            return this.$utils.diffTwoTime(endDate, today) + 1;
          } else {
            console.log('3333');
            return this.$utils.diffTwoTime(endDate, today) + 1;
          }
        }
      }
    }
  },
  methods: {
    // 省市区
    changeRegion (keys, values) {
      this.editMendianInfo.ProvinceKey = keys[0] || '';// 省
      this.editMendianInfo.ProvinceKeyValue = values[0] || ''; // 省
      this.editMendianInfo.CityKey = keys[1] || '';// 市
      this.editMendianInfo.CityKeyValue = values[1] || ''; // 市
      this.editMendianInfo.AreaKey = keys[2] || ''; // 区/县
      this.editMendianInfo.AreaKeyValue = values[2] || ''; // 区/县
      console.log(keys, values, '省市区', this.editMendianInfo);
    },
    // 快捷启用
    showQuickEnable () {
      this.$refs.shortcutStartSetting.showShortcutStartSetting = true;
      this.$refs.shortcutStartSetting.loadData();
    },
    createSignQRcode () {
      if (!this.mendianInfo.WktInfo && !this.mendianInfo.WktInfo.sec_website) {
        return false;
      }
      let url = this.mendianInfo.WktInfo.sec_website + '/do/sign_in_scan.php';
      if (document.getElementById('signQRcode')) {
        this.codeValue = url;
        setTimeout(() => {
          let qrcode = document.getElementById('signQRcode').getElementsByTagName('canvas')[0];
          let codeBase = qrcode.toDataURL('image/png');
          console.log(codeBase, 'src');
          this.signQRcodeUrl = codeBase;
        }, 500);
      }
    },
    checkPower () {
      if (!this.vHasPower) {
        this.$utils.CheckModulePowerToTips(38);
      }
    },
    closeChgPic () {
      this.isChgPic = false;
    },
    selectImg (e) {
      let fileName = e.target.files[0].name;
      let typeText = '.jpg,.jpeg,.png';
      if (typeText.indexOf(fileName.substring(fileName.toLowerCase().lastIndexOf('.'), fileName.length)) == -1) {
        this.clearPicData();
        layer.alert('上传文件类型不符合，只能上传.jpg,.jpeg,.png类型文件');
        return false;
      } else {
        if (e.target.files.length > 0) {
          var reader = new FileReader();	// 实例化一个FileReader对象，用于读取文件
          var that = this;
          reader.onload = function (evt) {
            that.selectPicUrl = evt.target.result;
            that.$nextTick(() => {
              that.isChgPic = true;
            });
          };
          reader.readAsDataURL(e.target.files[0]);
        }
      }
    },
    // 确认裁切
    setPicCorp (imgUrl) {
      this.mendianInfo.BrandPath = imgUrl;
      this.upConfirmClick(this.mendianInfo);
      this.clearPicData();
    },
    clearPicData () {
      document.body.style.overflow = 'auto';
      this.isChgPic = false;
      this.selectPicUrl = '';
      this.$refs.uploading.value = '';
    },
    // 获取门店信息
    getMendianInfo () {
      if (!this.mendianInfo.OLAPKey) {
        this.isLoad = false;
      }
      GetSaaSClientMsg().then(result => {
        this.mendianInfo = result.data;
        this.chengeUrl = this.mendianInfo.LogoPath;

        console.log(this.mendianInfo, '获取门店信息-3', this.locationAreaInfo);
        this.$store.commit('setSaaSClientInfo', result.data);
        this.getLocation();
        this.isLoad = true;
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 控制返回表单
    toBack () {
      this.$router.back(-1);
    },
    // 上传头像
    doSetPicCorpHeadImg (Url) {
      this.chengeUrl = Url;
    },
    showLocationSet () {
      this.isShowLocationForm = true;
    },
    closeLocationFormPopup () {
      this.isShowLocationForm = false;
    },
    setLocationInfo (data) {
      this.locationInfo = data;
      console.log(data, 'locationInfo');
      this.closeLocationFormPopup();
    },
    // 修改门店资料确定按钮
    confirmClick (callBack) {
      console.log(this.editMendianInfo, 'this.editMendianInfo');
      let flag = false;
      if (this.isxgmdzlShow == true) {
        if (!this.editMendianInfo.Telephone || !this.editMendianInfo.Telephone.replace(/(^\s*)|(\s*$)/g, '')) {
          layer.alert('机构电话不能为空');
          flag = true;
        } else {
          this.mendianInfo.MainDemoName = this.editMendianInfo.MainDemoName;
          this.mendianInfo.Telephone = this.editMendianInfo.Telephone;
          this.mendianInfo.Address = this.editMendianInfo.Address;
          this.mendianInfo.DetailAddress = this.editMendianInfo.DetailAddress;
          this.mendianInfo.ProvinceKey = this.editMendianInfo.ProvinceKey;
          this.mendianInfo.ProvinceKeyValue = this.editMendianInfo.ProvinceKeyValue;
          this.mendianInfo.CityKey = this.editMendianInfo.CityKey;
          this.mendianInfo.CityKeyValue = this.editMendianInfo.CityKeyValue;
          this.mendianInfo.AreaKey = this.editMendianInfo.AreaKey;
          this.mendianInfo.AreaKeyValue = this.editMendianInfo.AreaKeyValue;
          this.mendianInfo.LogoPath = this.chengeUrl;
          this.modifySaaSClientSetting(callBack);// 门店定位
        }
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
    },
    // 门店定位
    modifySaaSClientSetting (callBack) {
      if (this.locationInfo[0].SetContent) {
        let isStr = (typeof this.locationInfo[1].SetContent == 'string');
        if (!isStr) {
          this.locationInfo[1].SetContent = JSON.stringify(this.locationInfo[1].SetContent);
        }
        const locationArr = JSON.parse(this.locationInfo[1].SetContent);
        console.log(locationArr[0], 'locationInfo');
        if (locationArr[0]) {
          this.dataMDInfo = this.mendianInfo;
          console.log(this.mendianInfo, 'this.mendianInfo222', this.locationInfo, 'this.dataMDInfo', this.dataMDInfo);
          WEBVModifySaaSClientSetting(this.locationInfo).then(result => {
            console.log(this.mendianInfo, 'this.mendianInfo333', this.locationInfo, 'this.dataMDInfo', this.dataMDInfo);
            // 地图定位设置
            if (this.mendianInfo.SaaSClientSetting.length > 0) {
              this.mendianInfo.SaaSClientSetting.forEach(o => {
                if (Number(o.SetTypeKey) == 14) { // 定位地址信息
                  o.SetContent = this.locationInfo[0].SetContent;
                }
                if (Number(o.SetTypeKey) == 15) { // 定位经纬度信息
                  o.SetContent = this.locationInfo[1].SetContent;
                }
                if (Number(o.SetTypeKey) == 16) { // 定位截图信息
                  o.SetContent = this.locationInfo[2].SetContent;
                }
              });
            }
            this.mendianInfo.LogoPath = this.chengeUrl;
            this.upConfirmClick(this.dataMDInfo, true, callBack, () => {
              let token = this.$store.getters.token;
              token.SaaSClientKeyValue = this.mendianInfo.MainDemoName;
              this.$store.commit('SetToken', token);
              this.$fun.loginInfo.setCookie('token', JSON.stringify(token));
            });
          }, err => {
            layer.alert(err.msg);
          });
        }
      } else {
        // 地图地位非必填
        this.mendianInfo.LogoPath = this.chengeUrl;
        this.upConfirmClick(this.mendianInfo, true, callBack, () => {
          let token = this.$store.getters.token;
          token.SaaSClientKeyValue = this.mendianInfo.MainDemoName;
          this.$store.commit('SetToken', token);
          this.$fun.loginInfo.setCookie('token', JSON.stringify(token));
        });
      }
    },
    // 更新门店定位信息
    getLocation () {
      if (this.mendianInfo.SaaSClientSetting.length > 0) {
        let findType14Item = this.mendianInfo.SaaSClientSetting.find(o => {
          return Number(o.SetTypeKey) == 14;
        });
        let findType15Item = this.mendianInfo.SaaSClientSetting.find(o => {
          return Number(o.SetTypeKey) == 15;
        });
        let findType16Item = this.mendianInfo.SaaSClientSetting.find(o => {
          return Number(o.SetTypeKey) == 16;
        });
        console.log(findType15Item.SetContent, 'findType15Item-15');
        this.locationInfo = [
          { 'SetKey': '', 'SetKeyValue': '', 'SetContent': findType14Item.SetContent ? findType14Item.SetContent : '', 'SetTypeKey': '14' },
          { 'SetKey': '', 'SetKeyValue': '', 'SetContent': findType15Item.SetContent ? JSON.parse(findType15Item.SetContent) : '', 'SetTypeKey': '15' },
          { 'SetKey': '', 'SetKeyValue': '', 'SetContent': findType16Item.SetContent ? findType16Item.SetContent : '', 'SetTypeKey': '16' }
        ];
      }
    },
    // 更新门店资料
    upConfirmClick (dataInfo, isHint, callBack) {
      if (dataInfo) {
        let editMDInfo = {
          MainDemoName: dataInfo.MainDemoName, // 门店名称
          Telephone: dataInfo.Telephone, // 门店Tel
          Address: dataInfo.Address, // 门店地址
          LogoPath: dataInfo.LogoPath, // 门店Logo
          BrandPath: dataInfo.BrandPath, // 门店形象图
          WktInfo: dataInfo.WktInfo,
          DetailAddress: dataInfo.DetailAddress,
          ProvinceKey: dataInfo.ProvinceKey,
          ProvinceKeyValue: dataInfo.ProvinceKeyValue,
          CityKey: dataInfo.CityKey,
          CityKeyValue: dataInfo.CityKeyValue,
          AreaKey: dataInfo.AreaKey,
          AreaKeyValue: dataInfo.AreaKeyValue

        };
        console.log(dataInfo, '提交门店资料-2');
        updateTheSaaSClientMsg(editMDInfo).then(result => {
          if (isHint) {
            layer.alert('设置成功');
          }
          if (callBack) {
            callBack();
          }
          this.getMendianInfo();
          this.isxgmdzlShow = false;
        }, err => {
          layer.alert(err.msg);
        });
      }
    },
    modifySettingList (list) {
      console.log('modifySettingList', list);
    },
    // 更新门店设置
    modifySettingAfter (dataInfo, succeedHint, callBack) {
      ModifySetting(dataInfo).then(res => {
        console.log(res, '更新门店设置成功');
        if (succeedHint == 62 || succeedHint == 63) {
          layer.alert('设置成功');
        } else {
          if (succeedHint) {
            layer.alert(succeedHint);
          }
          this.doCloseShowForm();
        }
        this.getMendianInfo();
      }, err => {
        layer.alert(err.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 修改门店资料取消按钮
    cancelClick () {
      this.isxgmdzlShow = false;
      this.isWeChatSettingFromShow = false;// 微信设置
      this.isBeforeClassSettingFrom = false;
      this.getLocation();
    },
    // 开启微信设置
    doWeChatSettingClick () {
      this.isWeChatSettingFromShow = true;
    },
    // 微信通知设置
    doTemplateSettingClick (titleName, eventId, templateKey, setTypeKey) {
      console.log(eventId, '微信通知开关设置');
      this.templateTypeInfo.name = titleName;
      this.templateTypeInfo.eventId = eventId;
      this.templateTypeInfo.templateKey = Number(templateKey);// el-switch 开关需要字符串
      this.templateTypeInfo.setTypeKey = setTypeKey;
      this.isBeforeClassSettingFrom = true;
      console.log(this.templateTypeInfo, '微信通知开关设置2');
      this.getMendianInfo();
    },
    // 关闭弹出层
    doCloseShowForm () {
      this.isxgmdzlShow = false;
      this.isWeChatSettingFromShow = false;// 微信设置
      this.isBeforeClassSettingFrom = false;// 课前设置
      this.getLocation();
    },
    // 门店信息点击事件
    mendianInfoClick () {
      console.log(this.editMendianInfo, this.locationInfo, '门店信息点击事件');
      if (this.vHasPower) {
        this.isxgmdzlShow = true;
        this.editMendianInfo.MainDemoName = this.mendianInfo.MainDemoName;
        this.editMendianInfo.Telephone = this.mendianInfo.Telephone;
        this.editMendianInfo.Address = this.mendianInfo.Address;
        this.editMendianInfo.DetailAddress = this.mendianInfo.DetailAddress;
        this.editMendianInfo.ProvinceKey = this.mendianInfo.ProvinceKey;
        this.editMendianInfo.ProvinceKeyValue = this.mendianInfo.ProvinceKeyValue;
        this.editMendianInfo.CityKey = this.mendianInfo.CityKey;
        this.editMendianInfo.CityKeyValue = this.mendianInfo.CityKeyValue;
        this.editMendianInfo.AreaKey = this.mendianInfo.AreaKey;
        this.editMendianInfo.AreaKeyValue = this.mendianInfo.AreaKeyValue;
        this.$nextTick(o => {
          this.locationAreaInfo = [this.editMendianInfo.ProvinceKey, this.editMendianInfo.CityKey, this.editMendianInfo.AreaKey];
        });
        console.log('this.locationAreaInfo', this.locationAreaInfo);
      } else {
        this.$utils.CheckModulePowerToTips(38);
      }
    },
    registerBusEvent () {
      this.$bus.on(this, 'switchHeaderBarToMendiansettingform', () => {
        this.getMendianInfo();
      });
      this.$bus.on(this, 'wsUpdateSaasClientInfo', (data) => {
        this.mendianInfo = data;
      });
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'mendiansettingform') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'mendiansettingform') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCustomDialogByFactoryDialog');
      if (this.$refs.dialogFactory) {
        this.$refs.dialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.dialogFactoryCommon) {
        this.$refs.dialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>

<style  scoped>
.renew_warn1 {
  padding-left: 100px;
  padding-right: 60px;
}
.renew_warn2 {
  padding-left: 100px;
  padding-right: 60px;
}
.renew_warn3 {
  padding-left: 100px;
  padding-right: 60px;
}
.flowb {
  padding-left: 20px;
  padding-top: 20px;
}
.mendian_info_IDs {
}
.IDspan {
  color: #333333;
}
.mendian_info_box {
  padding-left: 20px;
}
.mendian_info_text {
  margin-left: 20px;
}
.icon_item {
  display: block;
  width: 16px;
  height: 16px;
  float: left;
}
.phoneNumBox {
  position: absolute;
  top: 130px;
  left: 20px;
}
.phoneNumBox1 {
  position: absolute;
  top: 160px;
  left: 20px;
}
.phone_text {
  padding-left: 15px;
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: block;
  margin-left: 15px;
  width: 350px;
}
.mendian_info_btn {
  background: url(../../../../../public/cw-img/setting_icon.png) no-repeat right
    center;
  width: 20px;
  right: -55px;
}
.mendian_photo_nodata {
  margin: 5px 0px 20px;
}
.mendian_photo {
  margin: 5px 20px 20px 20px;
}
.mendian_photo img {
  padding: 0;
}
.bottom_text_content {
  display: flex;
  flex-direction: column;

  margin: 20px 0px;
  background: linear-gradient(0deg, #f9fbfd, #f9fbfd), #f5f5f5;
  border-radius: 8px;
}
.bottom_text_content_text {
  display: flex;
  flex-direction: row;
  color: #999;
  padding: 20px;
  font-size: 12px;
  line-height: 16px;
}
.bottom_text_content_text_title {
  width: 68px;
}
.bottom_text_content_text_content {
  flex: 1;
}
.amap-sug-result {
  display: none !important;
  visibility: hidden !important;
}
</style>
