<template>
  <li class="box_right_li" :class=" 'choose'+keyIndex" @click.stop="seletedItemAfter">
    <div
      class="right_li_span"
      :class="{ 'display-table-cell': item.GradeKeyValue.length < 10 }"
      :title="item.GradeKeyValue"
    >
      {{ item.GradeKeyValue }}
    </div>
  </li>
</template>
<script>
import dropDownBox from "../../../common/drop-down-box";
export default {
  components: {
    dropDownBox,
  },
  props: {
    item: Object,
    keyIndex: Number
  },
  data() {
    return {
      isShowDiv: false,
      typeList: [
        {
          name: "班级详情",
          type: 1,
          isShow: true,
        },
        {
          name: "退出班级",
          type: 2,
          isShow: true,
        },
      ],
    };
  },

  created() {},
  mounted() {
   
  },
  methods: {
    getItem() {},
    clickAfter(item) {
      this.isShowDiv = false;
      if (item.type == 1) {
        this.$emit("getClassDetail", this.item);
      } else {
        this.$emit("quitClass", this.item);
      }
    },
    getClassDetail() {
      this.$emit("getClassDetail", this.item);
    },
    quitClass() {
      this.$emit("quitClass", this.item);
    },
    showClick() {
      this.isShowDiv = true;
    },
    desClick() {
      this.isShowDiv = false;
    },
    seletedItemAfter(e){
      // const box = document.querySelector('choose'+this.keyIndex)
      // var Rect = this.$el.getBoundingClientRect()
      this.$emit('seletedItem',this.item, this.keyIndex,e)
    },
  },
};
</script>
<style scoped>
.display-table-cell {
  display: table-cell !important;
}
.box_right_li {
  /* position: absolute; */
  width: 150.25px;
  height: 43px;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 5px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: block;
  float: left;
}
.right_li_span {
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  align-items: center;
  vertical-align: middle;
  width: 150.25px;
  height: 43px;
  padding-left: 13px;
  cursor: pointer;
  overflow: hidden;
  line-height: 22.5px;
   /* white-space: nowrap; */
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis; 
  -webkit-line-clamp: 2;
  text-overflow: -o-ellipsis-lastline;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  
}
.box_right_li_div {
  width: 50px;
  height: 40px;
  background-color: #ffffff;
  right: 15px;
  border: 1px solid #ccc;
}
.box_right_li_div span {
  display: block;
  width: 50px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}
.box_right_li_div span:hover {
  color: #27b1db;
}
</style>