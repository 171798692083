<template>
  <div class="swiper-slide">
    <img :src="headImg"
         alt="">
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  computed: {
    headImg () {
      if (!this.item.AttachUrl) {
        this.item.AttachUrl = '';
      }
      return this.$utils.ossLitimgSet(this.item.AttachUrl);
    }
  }
};
</script>