<template>
  <div class="">
    <div class="course_statistics_title">
      <div class="title_date">
        <span>选择月份</span>
        <div class="title_date_select">
          <div class="el-select">
            <div class="el-input el-input--small el-input--suffix is-focus">
              <single-year-picker @chgDate="chgDate"
                                  :type="'month'"
                                  :maxDate="vMaxDate"
                                  :isShowHistory="isShowHistory"
                                  ref="monthPicker"
                                  :minDate="vMinDate"></single-year-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="change_history_data"
           @click.stop="historyBtn(historyName)">{{ historyName }}</div>
      <!-- <div class="title_number">
        <span>共计</span>
        <span class="font_number">{{monthTotal}}</span>
        <span>元</span>
      </div> -->
    </div>
    <div class="course_statistics_table_content">
      <div class="table_thead">
        <div class="table_th tl"
             :style="{'width': vWith+'%','padding-left': '5px'}">
          上课日期
        </div>
        <!-- 92px -->
        <!-- <div class="table_th tl"
             :style="{'width': vWith2+'%','padding-left': '5px'}">
          课消金额
        </div> -->
        <div class="table_th tl"
             :style="{'width': vWith3+'%','padding-left': '5px'}">
          课次
        </div>
        <div class="table_th tl"
             :style="{'width': otherWitdh+'%','padding-left': '5px'}">
          签到
        </div>
        <div class="table_th tl"
             :style="{'width': otherWitdh+'%','padding-left': '5px'}">
          请假
        </div>
        <div class="table_th tl"
             :style="{'width': otherWitdh+'%','padding-left': '5px'}">
          <span v-if="this.$utils.getSaaSClientSet(59) > 0">旷课</span>
        </div>
      </div>
      <div class="table_tbody">
        <!-- <div class="table_tr">
              <div class="table_td tl"
                   style="width: 96px;padding-left: 15px;">
                08-14
              </div>
              <div class="table_td tl"
                   style="width: 94px;padding-left: 17px;">
                50/5
              </div>
              <div class="table_td tl"
                   style="width: 55px;padding-left: 18px;">
                25
              </div>
              <div class="table_td tl"
                   style="width: 55px;padding-left: 18px;">
                5
              </div>
              <div class="table_td tl"
                   style="width: 55px;padding-left: 18px;">
                1
              </div>
            </div> -->
        <day-item v-for="(item,key) in reportList"
                  :key="key"
                  :item="item"
                  :isShowLeave="isShowLeave"
                  :isShowTruant="isShowTruant"
                  @clickItem="clickItemAfter">
        </day-item>
      </div>

    </div>
  </div>
</template>
<script>
import singleYearPicker from '../../base-module/date-picker-common/single-year-picker';

import { MessageSearchClassAwayByMonth, MessageSearchClassAwayByMonthH } from '../../../API/workbench';
import dayItem from './day-item';
export default {
  data () {
    return {
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      seletedDate: Date.new(),
      searchObj: {
        startTime: '',
        endTime: ''
      },
      reportList: [],
      monthTotal: 0
    };
  },
  components: {
    singleYearPicker, dayItem
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    },
    vMenDianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    isShowLeave () {
      if (this.$utils.getSaaSClientSet(32) == 3) {
        return true;
      } else if (this.$utils.getSaaSClientSet(32) == 4) {
        let locaIndex = this.reportList.findIndex(o => {
          return Number(o.LeaveNumCount) > 0;
        });
        if (locaIndex >= 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isShowTruant () {
      if (this.$utils.getSaaSClientSet(33) == 3) {
        return true;
      } else if (this.$utils.getSaaSClientSet(33) == 4) {
        let locaIndex = this.reportList.findIndex(o => {
          return Number(o.TruantNumCount) > 0;
        });
        if (locaIndex >= 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    vWith () {
      let width = Math.round((80 / 377) * 100);
      let otherWitdh = Math.round((55 / 377) * 100);
      let count = 0;
      if (this.isShowLeave && this.isShowTruant) {
        return width;
      } else {
        if (!this.isShowLeave) {
          count++;
        }
        if (!this.isShowTruant) {
          count++;
        }
        return width + (otherWitdh * count) / (5 - count);
      }
    },
    vWith2 () {
      let width = Math.round((90 / 377) * 100);
      let otherWitdh = Math.round((55 / 377) * 100);
      let count = 0;
      if (this.isShowLeave && this.isShowTruant) {
        return width;
      } else {
        if (!this.isShowLeave) {
          count++;
        }
        if (!this.isShowTruant) {
          count++;
        }
        return width + (otherWitdh * count) / (5 - count);
      }
    },
    vWith3 () {
      let width = Math.round((80 / 377) * 100);
      let otherWitdh = Math.round((85 / 377) * 100);
      let count = 0;
      if (this.isShowLeave && this.isShowTruant) {
        return width;
      } else {
        if (!this.isShowLeave) {
          count++;
        }
        if (!this.isShowTruant) {
          count++;
        }
        return width + (otherWitdh * count) / (5 - count);
      }
    },
    otherWitdh () {
      let otherWitdh = Math.round((85 / 377) * 100);
      let count = 0;
      if (this.isShowLeave && this.isShowTruant) {
        return otherWitdh;
      } else {
        if (!this.isShowLeave) {
          count++;
        }
        if (!this.isShowTruant) {
          count++;
        }
        return otherWitdh + (otherWitdh * count) / (5 - count);
      }
    }

  },
  mounted () {
    this.$bus.on(this, 'wsAttendanceOrArrange', (data) => {
      this.getReportList();
    });
    this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy/MM/dd');
    this.searchObj.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy/MM/dd');
    this.getReportList();
  },
  methods: {
    historyBtn (name) {
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.searchObj.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.searchObj.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isShowHistory = true;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0));
        this.isShowHistory = false;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.getReportList();
      this.$refs.monthPicker.changeHistoryTime(this.searchObj.endTime);
    },
    chgDate (val, startTime, endTime) {
      this.seletedDate = val;
      this.searchObj.startTime = startTime;
      this.searchObj.endTime = endTime;
      this.getReportList();
    },
    getReportList () {
      let fn = '';
      if (this.isShowHistory) {
        fn = MessageSearchClassAwayByMonthH;
      } else {
        fn = MessageSearchClassAwayByMonth;
      }
      fn(this.searchObj).then(result => {
        this.monthTotal = Number(Number(result.data.totalDeductAmount).toFixed(2));
        result.data.DayDeductAmount.sort((x, y) => {
          return Date.new(y.SetUpTime) - Date.new(x.SetUpTime);
        });
        this.reportList = result.data.DayDeductAmount;
        console.log(result.data, 'MessageSearchClassAwayByMonth');
      }).catch(error => {
        console.log('error', error.msg);
      });
    },
    clickItemAfter (item) {
      this.$emit('clickItem', item);
    }
  }
};
</script>
<style scoped>
.change_history_data {
  position: absolute;
  margin-left: 258px !important;
  line-height: 34px !important;
  width: 120px !important;
  height: 36px !important;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
</style>