<template>
  <!-- 标签 -->
  <div class="label_set_div">
    <div class="label_content"
         style="margin-bottom: 30px;">
      <!-- 左边  分组-->
      <div class="content_div">
        <div class="label_title">
          <div class="group_title">标签分组</div>
          <div class="name_title">标签名称</div>
        </div>

        <div class="group_and_label"
             v-for="(item,index) in labelGroupList"
             :key="index">

          <!-- 组 -->
          <div class="group_content"
               @click.stop="(index == labelGroupList.length-1)?addLabelGroup(item,index,'add1'):''">
            <img :class="(index == labelGroupList.length-1)?'hide_img':''"
                 src="../../../../public/image/courseware_menu_list10.png"
                 class="group_img"
                 style="top: 4px;right: 27px;"
                 @click.stop="groupSetBtn(item[0],index,'edit')" />
            <img :class="(index == labelGroupList.length-1)?'hide_img':''"
                 src="../../../../public/image/courseware_menu_list09.png"
                 class="group_img"
                 style="top: 4px;right: 5px;"
                 @click.stop="groupSetBtn(item[0],index,'delete')" />
            <div class="group_value"
                 :title="item[0].MainDemoName"
                 :class="(index == labelGroupList.length-1)?'add_div add_group_div':''">{{ item[0].MainDemoName }}</div>
          </div>
          <!-- 右边 标签名称 -->
          <div class="label_right">

            <div class="label_div"
                 v-for="(child,aindex) in item"
                 :key="aindex">
              <div v-if="child.LabelKeyValue"
                   class="label_name"
                   style="position: relative;padding-left: 8px;margin-top: 15px;"
                   :class="(aindex == item.length-1)?'add_div':''"
                   @click.stop="(aindex == item.length-1)?addLabelGroup(child,aindex,'add2'):''">
                <span class="label_span"
                      :title="child.LabelKeyValue">{{child.LabelKeyValue}} </span>
                <img src="../../../../public/image/courseware_menu_list10.png"
                     style="position: absolute;right: 27px;top: 6px; cursor: pointer;"
                     :class="(aindex == item.length-1)?'hide_img':''"
                     @click.stop="labelSetBtn(child,index,'edit')" />
                <img src="../../../../public/image/courseware_menu_list09.png"
                     style="position: absolute;right: 5px;top: 6px;cursor: pointer;"
                     :class="(aindex == item.length-1)?'hide_img':''"
                     @click.stop="labelSetBtn(child,index,'delete')" />
              </div>

            </div>

          </div>

        </div>

      </div>

    </div>
    <custom-dialog :title="groupLabelTitle"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowLabelSet"
                   :before-close="closeForm">
      <item-form :threadInfo="threadInfo"
                 :operateType="operateType"
                 @cancelClick="closeForm"
                 @afterSuccess="afterSuccess">
      </item-form>
    </custom-dialog>
  </div>
</template>

<script>
import { GetLabelGroupList, DelLabelGroup, DelLabel } from '../../../API/workbench.js';
import itemForm from './item-form.vue';// 销售
export default {
  data () {
    return {
      isShowLabelSet: false, // 标签
      groupLabelTitle: '添加标签组',
      operateType: 'add1',
      labelGroupList: [],
      threadInfo: {}
    };
  },
  components: {
    itemForm
  },
  created () {
    this.getGetLabelGroupList();
  },
  methods: {
    closeForm () {
      this.isShowLabelSet = false;// 标签
    },
    afterSuccess () {
      this.isShowLabelSet = false;// 标签
      this.getGetLabelGroupList();
    },
    // 添加 组 和 标签
    addLabelGroup (item, index, type) {
      this.operateType = type;
      if (type == 'add1') {
        this.threadInfo = {};
        this.groupLabelTitle = '添加标签组';
        this.isShowLabelSet = true;
      } else {
        this.threadInfo = item;
        this.groupLabelTitle = '添加标签';
        if (item.OLAPKey) {
          this.isShowLabelSet = true;
        } else {
          layer.alert('请先添加标签组');
        }
      }
      console.log(item, index, 'addLabelGroup item,index');
    },
    // 修改 或者 删除 组
    groupSetBtn (item, index, type) {
      this.operateType = 'edit1';
      this.threadInfo = item;
      this.groupLabelTitle = '修改标签组';
      console.log(item, index, 'groupSetBtn item,index ', type, 'this.threadInfo', this.threadInfo);
      if (type == 'edit') {
        this.isShowLabelSet = true;
      } else if (type == 'delete') {
        layer.confirm('是否删除该标签组?', {
          btn: [{
            name: '确认',
            callBack: () => {
              layer.close();
              DelLabelGroup(item.OLAPKey).then(result => {
                layer.alert('删除成功');
                this.getGetLabelGroupList();
              }, error => {
                layer.alert(error.msg);
              });
            }
          }
          ]
        });
      }
    },
    // 修改 或者 删除 标签
    labelSetBtn (item, index, type) {
      this.groupLabelTitle = '修改标签';
      this.operateType = 'edit2';
      this.threadInfo = item;
      console.log(item, index, 'labelSetBtn item,index', type, 'this.threadInfo', this.threadInfo);

      if (type == 'edit') {
        this.isShowLabelSet = true;
      } else if (type == 'delete') {
        layer.confirm('是否删除该标签?', {
          btn: [{
            name: '确认',
            callBack: () => {
              layer.close();
              DelLabel(item.LabelKey).then(result => {
                layer.alert('删除成功');
                this.getGetLabelGroupList();
              }, error => {
                layer.alert(error.msg);
              });
            }
          }
          ]
        });
      }
    },
    getGetLabelGroupList () {
      GetLabelGroupList().then(result => {
        result.data.forEach((o, i) => {
          o.push({ OLAPKey: o[0].OLAPKey, MainDemoName: o[0].MainDemoName, LabelKey: '', LabelKeyValue: '+ 添加标签' });
        });
        result.data.push([{ OLAPKey: '', MainDemoName: '+ 添加分组', LabelKey: '', LabelKeyValue: '+ 添加标签' }]);
        this.labelGroupList = result.data;
        console.log(this.labelGroupList, 'this.labelGroupList', result);
        this.$forceUpdate();
      }, error => {
        layer.alert(error.msg);
      });
    }

  }
};
</script>
<style scoped>
.label_set_div {
  min-height: 550px;
}
.content_div {
  width: 370px;
  margin: 30px auto;
  border: 1px solid rgba(236, 236, 236, 1);
  max-height: 550px;
  overflow-y: auto;
}
.label_title {
  display: flex;
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: rgba(151, 156, 160, 1);
  background: rgba(250, 250, 250, 1);
}
.group_title {
  font-size: 14px;
  width: 138px;
  border-right: 1px solid rgba(236, 236, 236, 1);
  text-align: center;
}
.name_title {
  width: 232px;
  text-align: center;
}
.group_and_label {
  display: flex;
  border-bottom: 1px solid rgba(236, 236, 236, 1);
}
.group_content {
  width: 138px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(236, 236, 236, 1);
  position: relative;
}
.group_img {
  position: absolute;
  cursor: pointer;
}
.group_value {
  max-width: 125px;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.label_right {
  width: 232px;
}
.label_name {
  height: 30px;
  line-height: 30px;
  width: 208px;
  width: 200p;
  margin: 10px auto;
  border-radius: 4px;
  color: rgba(102, 102, 102, 1);
  border: 1px solid rgba(236, 236, 236, 1);
}
.hide_img {
  display: none;
}
.add_div {
  position: relative;
  text-align: center;
  color: rgba(102, 102, 102, 1);
  cursor: pointer;
}
.add_group_div {
  width: 120px;
  height: 30px;
  border: 1px solid rgba(236, 236, 236, 1);
  line-height: 30px;
  border-radius: 4px;
  color: rgba(102, 102, 102, 1);
}
.label_span {
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  width: 163px;
}
.add_div span {
  width: 100% !important;
}
</style>