<template>
  <!-- 购课课消详情 -->
  <div class="content_marginTop_30 content_marginRL">
    <div class="form_info_edit  single_shadow">
      <div class="lately_course_details lately_course_list"
           v-if="courseBillInfo">
        <div class="list_type">
          {{courseBillInfo.UnitKeyValue?courseBillInfo.UnitKeyValue+'卡':''}}
        </div>
        <div class="list_content">
          <div class="list_content_title clearfix">
            <div v-if="courseBillInfo.TaoCanKey>0 && courseBillInfo.TaoCanKeyValue">
              <div class="list_content_taocan fl">餐</div>
              <div class="text_overflow_hidden fl"
                   style="width: 280px;">{{courseBillInfo.TaoCanKeyValue}}</div>
            </div>
            <div class="list_content_taocan fl"
                 v-else>非套餐</div>
          </div>
          <!-- 10月25日 -->
          <div class="font_gray"
               v-if="courseBillInfo.PaidTime">{{mClassTime(courseBillInfo.PaidTime)}}购买
            <span class="font_black">{{$utils.mAmountType(courseBillInfo.NumCount,1)}}</span> {{(courseBillInfo.UnitKey==4)?'天':courseBillInfo.UnitKeyValue}} ,剩余
            <span class="font_black">{{vPeriodCount}}</span> 天
            <span v-if="courseBillInfo.UnitKey==5">
              <span class="font_black">{{$utils.mAmountType(courseBillInfo.CurrentCount||0,1)}}</span> 课时
            </span>
          </div>
          <div class="font_gray clearfix">
            <div class="list_content_block fl">适用</div>
            <div class="text_overflow_hidden fl">{{courseBillInfo.ApplyCorName}}</div>
          </div>
          <div class="list_bottom clearfix">
            <div class="fl">
              <span class="font_gray">签到</span>
              <span class="font_data">{{courseBillInfo.AttenceCount}}</span>
              <span class="font_gray">次</span>
              <span class="text_right font_gray">上课消耗</span>
              <span class="font_data">{{$utils.mAmountType(courseBillInfo.expendCount,1) ||0}}</span>
              <span class="font_gray">课时</span>
              <!-- <span class="font_gray"
                    v-if="Number(courseBillInfo.DifCount)!==0">其他原因{{Number(courseBillInfo.DifCount)>0?'减少':'增加'}}{{$utils.mAmountType(Math.abs(courseBillInfo.DifCount),1)||0}}课时</span> -->
            </div>
          </div>
        </div>
      </div>
      <div class="class_away_ul"
           style="max-height: 600px;overflow-y:auto"
           v-if="classConsumptionMonthList.length>0">
        <div class="class_away_list"
             v-for="(item,index) in classConsumptionMonthList"
             :key="index">
          <!-- :class="{'opt':item.isOpt,'class_away_list_title':(item.isDeductType == true&&courseBillInfo.UnitKey==5),'v_class_away_list_title':item.isDeductType == false || courseBillInfo.UnitKey!==5}" -->
          <div class="clearfix"
               :class="{'opt':item.isOpt,'class_away_list_title':true}"
               @click="doConsumeSelectListShowClick(item)">
            <div class="fl">{{item.timer}}</div>
            <!--fl fl_2 font_gray：左 -->
            <div class="fr font_gray">消耗
              <span class="font_black">{{$utils.mAmountType(item.totolCoursrHourCount,1)||0}}</span>课时
            </div>
            <!-- <div class="fr font_gray "
                 v-if="courseBillInfo.UnitKey==5">剩余
              <span class="font_black">{{$utils.mAmountType(item.childList[0].ClassSingleCount,1)}}</span>{{item.classUnitKeyValue}}
            </div> -->
          </div>
          <div class="class_away_list_content"
               v-if="item.isOpt==true">
            <div :class="(child.DeductTypeKey == 2||child.DeductTypeKey == 3||child.DeductTypeKey == 10)?'content_data_list':'v_content_data_list'"
                 :style="child.DeductTypeKey == 6?'line-height: 46px;padding: 0':''"
                 v-for="(child,index) in item.childList"
                 :key="index"
                 @click="doConsumeSelectCheckingInShowClick(child)">
              <div class="clearfix">
                <div class="font_gray fl"
                     style="margin-right:10px">{{(child.SetUpTime).slice(8,10)}}日 {{$utils.getWeekByTwo(child.SetUpTime)}} {{(child.SetUpTime).slice(11,16)}}</div>
                <div class="font_gray fl"
                     v-if="child.CreatorKeyValue">{{child.CreatorKeyValue}}录入</div>
                <div class="fr">{{ mDeductTypeKeyValue(child)}}</div>
              </div>
              <div class="list_bottom clearfix">
                <div class="font_gray fl"
                     v-if="child.ClassTypeKeyValue">{{child.ClassTypeKeyValue}}</div>
                <div class="fr" v-if="(child.DeductTypeKey!=2&&child.DeductTypeKey!=3&&child.DeductTypeKey!=10)||courseBillInfo.UnitKey==5">
                  <div class="font_gray fl">
                    <div>{{$utils.mAmountType(child.DeductCount,1)>0?'减少':'增加'}} &nbsp;
                    </div>
                  </div>
                  <span>{{Math.abs($utils.mAmountType(child.DeductCount,1))}}<span class="font_gray">{{courseBillInfo.UnitKey==5?'课时':'天'}}</span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template> 

<script>
import attendanceArrange from '../../pop-up-menu/exception-handle/attendance-arrange-form/attendance-arrange';
import { GetTicketExpenseDetail, getStudentAllCourse } from '../../../API/workbench';
export default {
  data () {
    return {
      classConsumptionMonthList: [],       // 购课课消-月份.
      courseBillInfo: {},
      courseArrangeKey: 0 //, 课项
      // studentKey: this.$route.query.StudentKey || 0
    };
  },
  props: {
    studentKey: {//  课ID
      type: [String, Number],
      default: null
    },
    courseBillKey: {// 学生ID
      type: [String, Number],
      default: null
    }
  },

  components: {
    attendanceArrange
  },

  created () {
    this.uploadData();
  },
  mounted () {
    this.$dialog.register(this, ['attendanceArrange']);
  },
  // 一进入页面就触发；可用于初始化页面数据等
  activated () {
  },
  watch: {

  },
  computed: {
    vPeriodCount () {
      // 计算提前购买日期
      // let theSameDay = this.$utils.formatDatet(Date.new(), 'yyyy-MM-dd');
      // if (this.courseBillInfo.StartTime > theSameDay) {
      //   let advanceTime = Number(this.$utils.DateDiffDay(theSameDay, this.courseBillInfo.StartTime)) + Number(this.courseBillInfo.PeriodCount);
      //   return advanceTime;
      // } else {
      return Number(this.courseBillInfo.PeriodCount).toFixed(0);
      // }
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    }
  },
  methods: {
    // 考勤签到后刷新数据
    updateStudentFileEvent (StudentKey, ScheduleCourseKey) {
      console.log(StudentKey, '考勤签到后刷新数据');
      this.$bus.emit('AfterCourseConsumeUpDate', StudentKey);
      this.uploadData();
    },
    uploadData () {
      // 外部进入课消记录 - 需要一个课单信息
      console.log(this.courseBillKey, '课单ID');
      if (Number(this.courseBillKey) > 0 && this.studentKey > 0) {
        console.log(this.studentKey, '学生ID');
        getStudentAllCourse(this.studentKey).then(result => {
          this.courseBillInfo = result.data.find(o => {
            return o.TableID == this.courseBillKey;
          });
          console.log(this.courseBillInfo, '课单信息 ');
          if (this.courseBillInfo) {
            this.getAllConsumeHistroyByCourseBillID(this.courseBillInfo);
          }
        });
      }
      console.log(this.classConsumptionMonthList.length, '购课课消-月份1');
    },
    // -课消方式 2-签到;3-旷课;4-过期;5-延期;6-年卡;7-调整;8-转出;9-转入;10-请假;11-退课
    mDeductTypeKeyValue (item) {
      if (item.DeductTypeKey == 2) {
        return '签到';
      } else if (item.DeductTypeKey == 6) {
        return '年卡';
      } else {
        return item.DeductTypeKeyValue;
      }
    },
    // 日期
    mClassTime (date) {
      if (date) {
        let month = date.slice(5, 7) + ' 月 ';
        let day = date.slice(8, 10) + ' 日';
        if (month.slice(0, 1) == '0') {
          month = month.slice(1, 2) + ' 月 ';
        }
        return month + day;
      } else {
        return '';
      }
    },
    doConsumeSelectListShowClick (item) {
      // if (item && (item.classUnitKeyValue == '课时' || item.isDeductType == true)) {
      item.isOpt = !item.isOpt;
      // }
      // console.log(item, '课消记录');
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    },
    // 去安排考勤页面
    doConsumeSelectCheckingInShowClick (child) {
      if (this.vHasModulePower(51)) {
        if (child.DeductTypeKey == 2 || child.DeductTypeKey == 3 || child.DeductTypeKey == 10) {
          this.courseArrangeKey = child;
          this.$dialog.open(this, {
            name: '课程详情',
            moduleName: 'attendanceArrange',
            routerName: this.$route.name,
            data: { scheduleCourseKey: this.courseArrangeKey.TheLessonKey, isAlter:false}
          });
          console.log(child, '课消项');
        }
      } else {
        this.$utils.CheckModulePowerToTips(51);
      }
    },
    // 获取所有的课消记录
    getAllConsumeHistroyByCourseBillID (item) {
      this.classConsumptionMonthList.length = 0;
      GetTicketExpenseDetail(this.studentKey, item.TableID).then(result => {
        console.log(result.data, '课消记录');
        result.data.sort((x, y) => {
          return Date.new(y.SetUpTime) - Date.new(x.SetUpTime);
        });
        result.data.forEach(p => {                                                          // 数据重组.
          let thisTimer = this.$utils.formatDateStr(p.SetUpTime, 'yyyy 年 MM 月');
          let monthItem = this.classConsumptionMonthList.find(o => {
            return o.timer == thisTimer;
          });
          if (!monthItem) {
            monthItem = {
              timer: thisTimer,
              totolConsumeSpend: 0,
              totolCoursrHourCount: 0,
              totolDeductCount: 0,
              childList: [],
              classUnitKeyValue: '',
              isDeductType: false,
              isOpt: false
            };
            this.classConsumptionMonthList.push(monthItem);
          }
          monthItem.childList.push(p);
          monthItem.totolConsumeSpend += Number(p.DeductAmount);// 总剩余金额
          monthItem.totolCoursrHourCount += Number(p.DeductCount);// 总消费课时
          monthItem.totolDeductCount += Number(p.DeductCount);  // 总（增加或减少）课时

          // 课单单位 2-年;3-月;4-日;5-课时
          if (p.ClassUnitKey == 4) {
            monthItem.classUnitKeyValue = '天';
          } else {
            monthItem.classUnitKeyValue = p.ClassUnitKeyValue;
          }
          // if (p.DeductTypeKey !== 6) {
          //   monthItem.isDeductType = true;
          // }
        });
        // this.classConsumptionMonthList.sort((x, y) => {
        //   return Date.new(y.timer) - Date.new(x.timer);
        // });
        // this.classConsumptionMonthList.forEach(item => {
        //   item.childList.sort((x, y) => {
        //     return this.$utils.getFormatDateToQuery(y.SetUpTime) - this.$utils.getFormatDateToQuery(x.SetUpTime);
        //   });
        // });
        if (this.classConsumptionMonthList.length > 0) {
          console.log(this.classConsumptionMonthList, '购课课消-月份');
          // if (this.classConsumptionMonthList[0].classUnitKeyValue == '课时' || this.classConsumptionMonthList[0].isDeductType == true) {
          this.classConsumptionMonthList[0].isOpt = true;  // 默认显示第一条
          // }
        }
      });
    }
  }

};
</script>

  <style>
.v_content_data_list {
  height: 46px;
  padding: 6px 0;
  padding-right: 20px;
  border-bottom: 1px solid #ececec;
  background-size: 6px;
  font-size: 13px;
  line-height: 16px;
  font-family: Arial;
  /* color: black */
}
.v_class_away_list_title {
  font-size: 14px;
  line-height: 40px;
  border-bottom: 1px solid #ececec;
  background-size: 10px;
  padding-left: 12px;
  padding-right: 12px;
}
</style>