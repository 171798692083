<template>
  <!-- 优惠券数量 -->
  <div class="list_number_box">
    <!-- <div class="list_number">
      <span class="font_gray">总量：</span>
      <span>{{Number(dataInfo.total_get_num)+Number(dataInfo.total_use_num)}}</span>
    </div> -->
    <div class="list_number" :class="Number(dataInfo.total_get_num)>0?'number_btn':''"  @click="doAlreadyReceivedClick">
      <span class="font_gray" :class="Number(dataInfo.total_get_num)>0?'number_btn':''">已领取：</span>
      <span>{{Number(dataInfo.total_get_num)}}</span>
    </div>
    <div class="list_number" :class="Number(dataInfo.total_use_num)>0?'number_btn':''" @click="doHaveBeenUsedClick">
      <span class="font_gray" :class="Number(dataInfo.total_use_num)>0?'number_btn':''">已使用：</span>
      <span>{{Number(dataInfo.total_use_num)}}</span>
    </div>
  </div>
</template>
<script>

export default {
  components: {},
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object // 券信息
  },
  created () {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    doAlreadyReceivedClick (type, data) {
      if (Number(this.dataInfo.total_get_num) > 0) {
        this.$emit('doAlreadyReceivedClick', this.dataInfo);
      }
    },
    doHaveBeenUsedClick () {
      if (Number(this.dataInfo.total_use_num) > 0) {
        this.$emit('doHaveBeenUsedClick', this.dataInfo);
      }
    }
  }
};
</script>

