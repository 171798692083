<template>
  <div class="pr">
    <list ref="wholeTable"
          class="summarizing_list summarizing_list_all"
          :key="tableKey"
          :defaultSort="defaultSort"
          :tableData="studentList"
          :totalNum="totalNum"
          :PageCount="PageCount"
          :tableColumns="tableColumns"
          :isSearchText="false"
          :isHint="false"
          :isNeedInnerScroll="isShowInnerScroll"
          :queryParams="params"
          @tableBtnClick="doAfterTableBtnClick"
          @loadTableData="loadMessageSearchTicketPlanPageForTaoCan"></list>
    <div v-if="studentList.length == 0">
      <div class="custom_made_course_block_nodata">
        <div class="custom_made_monitoring_content_nodata_txt">
          亲，当前没有任何数据哦~
        </div>
      </div>
      <!-- 分页 -->
      <div v-if="studentList.length != 0"
           class="table_pagination">
        <el-pagination :page-size="10"
                       :current-page.sync="vPageIndex"
                       background
                       :pager-count="5"
                       layout="prev, pager, next, jumper"
                       :total="totalNum>0?totalNum:10"></el-pagination>
      </div>
    </div>
  </div>

</template>

<script>
import { MessageSearchTicketPlanPageForTaoCan, GetTheTaocanApplyClassStudent } from '../../../API/workbench.js';
import list from '../table';
import initJson from '../../../js/initJson.json';
let initTableColumns = [
  {
    label: '学生ID',
    prop: 'StudentKey',
    width: 20,
    sortable: false,
    align: 'left',
    isShow: false,
    type: '',
    decimalPlace: 0
  },
  {
    label: '学生',
    prop: 'studentInfo',
    width: 100,
    sortable: 'custom',
    align: 'left',
    isShow: true,
    type: 'student',
    decimalPlace: 0,
    className: '',
    fixed: true,
    eventName: initJson.baseFunctionBtnName.read
  },
  {
    label: '排课备注',
    prop: 'ArrangeSupport',
    sortable: false,
    align: 'left',
    width: 192,
    isShow: true,
    type: 'popDialog',
    decimalPlace: 0,
    fixed: true,
    className: 'edit_icon'
  },
  {
    label: '可用课时',
    prop: 'Total_CurrentCount',
    width: 54,
    sortable: 'custom',
    align: 'center',
    isShow: true,
    type: 'number',
    columnClass: 'font_dual',
    decimalPlace: 1,
    fixed: true,
    bottomWarnProp: 'Total_CurrentCount_Warn',
    isBottomWarn: true
  },
  {
    label: '待报课时',
    prop: 'Total_ToBePlaned',
    width: 54,
    sortable: 'custom',
    align: 'center',
    isShow: true,
    type: 'redNumber',
    decimalPlace: 1,
    columnClass: 'font_dual',
    fixed: true,
    bottomWarnProp: 'Sum_ToBePlaned_Warn',
    isBottomWarn: true,
    eventName: initJson.baseFunctionBtnName.applyCourse
  },
  // {
  //   label: '超上提示',
  //   isShow: false,
  //   prop: 'Total_ToBePlaned_Pop_Tips'
  // },
  // {
  //   label: '超排提示',
  //   isShow: false,
  //   prop: 'PkItem_ToBeArranged_Pop_Tips'
  // },
  {
    label: '',
    isShow: true,
    fixed: 'right',
    prop: 'th_placeholder',
    columnClass: 'th_placeholder',
    className: 'td_placeholder'
  },
  {
    label: '操作',
    prop: '',
    sortable: false,
    fixed: 'right',
    width: '108',
    align: 'center',
    tableBtnGroup: [
      {
        name: '报课',
        eventName: initJson.baseFunctionBtnName.applyCourse,
        Stutas: '',
        className: () => {
          return 'btn_light_blue';
        },
        roleName: '',
        ModulePowerKey: 51
      },
      {
        name: '排课',
        eventName: initJson.baseFunctionBtnName.arrange,
        Stutas: '',
        className: () => {
          return 'btn_light_blue';
        },
        roleName: '',
        ModulePowerKey: 51
      }
    ],
    isShow: true,
    type: 'tableBtn',
    decimalPlace: 0
  }
];
let initParams = {
  pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
  pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
  orderBy: 'StudentKeyValue', // 字符串	可选		数据源：GET	排序字段
  sequence: 'asc', //	字符串	可选		数据源：GET	排序顺序
  TaoCanKey: '', // 整型	可选	0	数据源：GET	套餐ID
  ApplyClassKey: 0, // 整型	必须	0	数据源：GET	课程ID
  SpecialFilter: 0, // 整型	必须	0	数据源：GET	0-不生效;1-按待排>0;2-按待报>0;3-课时不足[0<按当前报课<=5];
  searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配 单号 学生名 学生首字母 手机号
  QueryID: '' // 整型	可选	0	数据源：GET	学生ID
};
export default {
  data () {
    return {
      studentList: [],
      params: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'StudentKeyValue', // 排序字段
        sequence: 'asc', // 排序字段
        TaoCanKey: this.packageInfo.TableID, // 整型	可选	0	数据源：GET	套餐ID
        ApplyClassKey: 0, // 整型	必须	0	数据源：GET	课程ID
        SpecialFilter: 0, // 整型	必须	0	数据源：GET	0-不生效;1-按待排>0;2-按待报>0;3-课时不足[0<按当前报课<=5];
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配 单号 学生名 学生首字母 手机号
        QueryID: '' // 整型	可选	0	数据源：GET	学生ID
      },
      tableColumns: initTableColumns,
      studentKey: 0,
      PageCount: 0,
      totalNum: 0,
      taocanApplyClassStudentList: [],
      defaultSort: { prop: 'studentInfo', order: 'ascending' },
      tableKey: '0',
      isShowInnerScroll: false
    };
  },
  props: {
    packageInfo: Object,
    SpecialFilter: {
      type: [Number, String],
      default: 0
    }
  },
  components: {
    list
  },
  computed: {
    vPageIndex () {
      return this.params.pageIndex + 1;
    }
  },
  watch: {
    'packageInfo.TableID': {
      handler (n, o) {
        if (n) {
          if (n != o) {
            this.isShowInnerScroll = false;
            this.params = this.$utils.parseJson(initParams);
          }
          this.loadData();
        }
      },
      deep: true
    }
  },
  created () {
    this.params.pageIndex = 0;
    this.loadData();
  },
  methods: {
    async loadData () {
      this.params.TaoCanKey = this.packageInfo.TableID;
      let result = await this.getTheTaocanApplyClassStudent();
      if (result.data.length > 0) {
        this.taocanApplyClassStudentList = result.data;
      }
      this.loadMessageSearchTicketPlanPageForTaoCan();
    },
    // 获取选中套餐下的学生（排课全局）
    loadMessageSearchTicketPlanPageForTaoCan () {
      this.params.orderBy = this.params.orderBy == 'studentInfo' ? 'StudentKeyValue' : this.params.orderBy;
      if (this.params.orderBy && this.params.orderBy.indexOf('_') > -1) {
        let arr = this.params.orderBy.split('_');
        if (arr[0] == 'PkItem' && arr.length == 3) {
          this.params.orderBy = arr[0] + '_' + arr[1];
          this.params.ApplyClassKey = arr[2];
        }
      }
      MessageSearchTicketPlanPageForTaoCan(this.params).then(result => {
        if (result.data) {
          let tableColumns = [];
          this.studentList = result.data.PageDataList;
          this.totalNum = result.data.Total;
          if (this.studentList.length > 0) {
            this.studentList.forEach(obj => {
              obj.studentInfo = [obj.StudentKeyValue, obj.CustomerPhoneName];
              if (Number(obj.Total_CurrentCount) <= 5 && Number(obj.Total_CurrentCount) != 0) {
                obj.Total_CurrentCount_Warn = '不足';
              }
              obj.PkItem_ApplyClass.push(obj.PkItem_Addtion);

              obj.PkItem_ApplyClass.forEach(item => {
                if (Number(item.Sum_SuperQuote) > 0) {
                  obj['PkItem_CurrentCount_Warn_' + item.ApplyClassKey] = '超上';
                  obj['PkItem_CurrentCount_' + item.ApplyClassKey + '_Pop_Tips'] = '超上' + Math.abs(item.Sum_SuperQuote) + '课时';
                }
                if (Number(item.Sum_Overplace) > 0) { // 超排
                  obj['PkItem_ToBeArranged_Warn_' + item.ApplyClassKey] = '超排';
                  obj['PkItem_ToBeArranged_' + item.ApplyClassKey + '_Pop_Tips'] = '超排' + Math.abs(item.Sum_Overplace) + '课时';
                }
                obj['PkItem_SpendCount_' + item.ApplyClassKey] = item.PkItem_SpendCount;
                obj['PkItem_CurrentCount_' + item.ApplyClassKey] = item.PkItem_CurrentCount;
                obj['PkItem_ToBeArranged_' + item.ApplyClassKey] = item.PkItem_ToBeArranged;
              });
            });
            let otherItem = null;
            result.data.PageDataList[0].PkItem_ApplyClass.forEach((obj, index) => {
              let courseItem = {
                label: obj.ApplyClassKeyValue,
                isShow: true,
                align: 'center',
                prop: '',
                width: 185,
                columnClass: '',
                headerCount: true,
                headerCountNum: '',
                headerCountNumUnit: '人',
                renderHeader: (h, { column, $index }) => {
                  if (obj.ApplyClassKey > 0) {
                    let findItem = this.taocanApplyClassStudentList.find(item => { return obj.ApplyClassKey == item.ApplyClassKey; });
                    let totalStudent = 0;
                    if (findItem) {
                      totalStudent = findItem.Total_Student;
                    }
                    if (this.$utils.getTextWidth(column.label, 14) > 196) {
                      return h('span', [
                        h('el-popover', { props: (column.label.length > 12 ? { placement: 'top-start', width: 'auto', trigger: 'hover', content: column.label + ' ' + totalStudent + '人' } : '') },
                          [h('i', { slot: 'reference', class: '', style: 'font-style: normal;' }, column.label)
                          ])
                      ]);
                    } else {
                      return h('span', [
                        // 课程
                        h('span', {
                          class: ''
                        }, column.label + ' '),

                        // 人数
                        h('span', {
                          class: 'course_font_blue'
                        }, totalStudent),
                        // 单位
                        h('span', '人')
                      ]);
                    }
                  } else {
                    console.log('123');
                    return h('span', column.label);
                  }
                },
                childColumn: [{
                  label: '上课消耗',
                  prop: 'PkItem_SpendCount_' + obj.ApplyClassKey,
                  width: 65,
                  sortable: obj.ApplyClassKeyValue == '其他' ? false : 'custom',
                  align: 'center',
                  isShow: true,
                  type: 'number',
                  decimalPlace: 0,
                  className: 'package_apply_dual',
                  // bottomWarnProp: 'PkItem_CurrentCount_Warn_' + obj.ApplyClassKey,
                  isBottomWarn: true
                },
                {
                  label: ' 当前报课',
                  prop: 'PkItem_CurrentCount_' + obj.ApplyClassKey,
                  width: 65,
                  sortable: obj.ApplyClassKeyValue == '其他' ? false : 'custom',
                  align: 'center',
                  isShow: true,
                  type: 'blueNumber',
                  decimalPlace: 1,
                  className: 'package_apply_dual',
                  bottomWarnProp: 'PkItem_CurrentCount_Warn_' + obj.ApplyClassKey,
                  isBottomWarn: true,
                  eventName: initJson.baseFunctionBtnName.applyCourse
                },
                {
                  label: '待排课时',
                  prop: 'PkItem_ToBeArranged_' + obj.ApplyClassKey,
                  width: 65,
                  sortable: obj.ApplyClassKeyValue == '其他' ? false : 'custom',
                  align: 'center',
                  isShow: true,
                  type: 'blueNumber',
                  decimalPlace: 1,
                  className: 'package_apply_dual',
                  bottomWarnProp: 'PkItem_ToBeArranged_Warn_' + obj.ApplyClassKey,
                  isBottomWarn: true,
                  eventName: initJson.baseFunctionBtnName.arrange

                }
                ]
              };
              let superQuoteItem = {
                label: '超上提示',
                isShow: false,
                prop:
                  'Total_ToBePlaned_' + obj.ApplyClassKey + '_Pop_Tips'
              };
              let placeOverItem = {
                label: '超排提示',
                isShow: false,
                prop: 'PkItem_ToBeArranged_' + obj.ApplyClassKey + '_Pop_Tips'
              };

              // 其他的先不加进去
              if (obj.ApplyClassKey != 0) {
                // 从倒数第一项开始添加
                tableColumns.splice(-1, 0, courseItem, superQuoteItem, placeOverItem);
              } else {
                otherItem = courseItem;
              }
            });
            tableColumns.splice(-1, 0, otherItem);
          }
          let isEmptyItem = initTableColumns.find(obj => { return obj.label == ''; });
          if (result.data.PageDataList.length > 0 && result.data.PageDataList[0].PkItem_ApplyClass.length >= 4) {
            if (isEmptyItem) {
              isEmptyItem.isShow = false;
            }
          } else {
            if (isEmptyItem) {
              isEmptyItem.isShow = true;
            }
          }
          if (result.data.PageDataList.length > 0 && result.data.PageDataList[0].PkItem_ApplyClass.length > 4) {
            this.isShowInnerScroll = true;
          }
          if (!this.params.searchText) {
            this.tableColumns = initTableColumns.concat(tableColumns);
          }
          if (tableColumns.length > 0) {
            this.tableKey = this.params.TaoCanKey;
          }
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    getTheTaocanApplyClassStudent () {
      return GetTheTaocanApplyClassStudent(this.packageInfo.TableID);
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, columnInfo) {
      if (columnInfo.prop == 'Total_ToBePlaned' || columnInfo.prop == 'studentInfo') {

      } else {
        let arr = (columnInfo && columnInfo.prop) ? columnInfo.prop.split('_') : [];
        if (arr[arr.length - 1] > 0) {
          rowData.ApplyClassKey = arr[arr.length - 1];
          rowData.ApplyClassKeyValue = rowData.PkItem_ApplyClass.find(obj => { return obj.ApplyClassKey == rowData.ApplyClassKey; }).ApplyClassKeyValue;
        } else {
          rowData.ApplyClassKey = 0;
          rowData.ApplyClassKeyValue = '';
        }
      }
      this.$emit('doAfterTableBtnClick', rowData, eventName, columnInfo);
    }
  }
};
</script>

<style>
</style>