<template>
  <div>
    <div class="general_summarizing_student">
      <div class="course_statistics_table_search flex">
        <date-range ref="saveDate"
                    :searchObj="searchObj"
                    :fromTitle="'选择日期'"
                    :maxDate="$utils.formatDateToLine(Date.new())"
                    @changeDate="changeSaveDate"></date-range>
        <div class="course_statistics_search">
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入学生名、备注'"
                                    :clearAll="true"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="searchData"></input-search-contain-btn>
          <div class="mendian_backstage_search">
            <div class="btn_hover_bg_blue search_btn "
                 style="margin: 0;"
                 @click.stop="doExportInfoClick">导出</div>
          </div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <list class="summarizing_list"
            :key="tableKey"
            :tableData="vdataReportList"
            :totalNum="totalNum"
            :PageCount="PageCount"
            :tableColumns="tableCourseColumns"
            :isSearchText="false"
            :isNeedInnerScroll="false"
            :defaultSort="defaultSort"
            :queryParams="searchObj"
            :footerContent="vFooterTotal"
            @loadTableData="getDataList"></list>
    </div>
  </div>
</template>
<script>
import list from '../../../../common/table-list/index';
import applyAcountItem from './../apply-acount-item';
import inputSearchContainBtn from '../../../common/input-search-contain-btn';
import { MessageSearchInComePayReportForIncidentals } from '../../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: '',
        endTime: '',
        IsExportKey: 0
      },

      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          decimalPlace: 0,
          className: 'table_index_gray'
        },
        {
          label: '发生日期',
          prop: 'GenerateTime',
          width: 180,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0

        },
        {
          label: '学生',
          prop: 'StudentKeyValue',
          width: 150,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'student',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '购买杂费',
          prop: 'ProjectKeyValue',
          minWidth: 170,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '购买数量',
          prop: 'NumCount',
          minWidth: 126,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          eventName: '4'
        },
        {
          label: '购买单价',
          prop: 'UnitPrice',
          minWidth: 126,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'amount-item',
          decimalPlace: 2,
          eventName: '4'
        },
        {
          label: '购买金额',
          prop: 'ActualPayAmount',
          minWidth: 126,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'amount-item',
          decimalPlace: 2,
          eventName: '4'
        }
      ],
      dataReportList: []
    };
  },
  components: {
    list,
    applyAcountItem,
    inputSearchContainBtn
  },
  props: {

  },
  created () {
    this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
    this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // o.GenerateTime = o.GenerateTime.substring(5);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      return '共' + this.totalNum + '笔，合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
    }

  },
  mounted () {
    this.getDataList();
  },
  methods: {
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime; ;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.AccountTypeKey = '';
      this.searchObj.searchText = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    searchData () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '杂费销售明细',
          clist: [
            { title: '发生日期', cName: 'GenerateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '购买杂费', cName: 'ProjectKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '购买数量', cName: 'NumCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '购买单价', cName: 'UnitPrice', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '购买金额', cName: 'ActualPayAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchInComePayReportForIncidentals(searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      console.log(this.searchObj);
      MessageSearchInComePayReportForIncidentals(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchInComePayReportForKDSY');
      }).catch(error => {
        console.log('MessageSearchInComePayReportForKDSY', error.msg);
      });
    },
    toApplyReport (item) {
      this.$emit('toApplyReport', item, this.searchObj);
    },
    toOtherIncomeReport (item) {
      this.$emit('toOtherIncomeReport', item, this.searchObj);
    },
    toExpenditureReport (item) {
      this.$emit('toExpenditureReport', item, this.searchObj);
    },
    selectAccountType (item) {
      console.log(item, 'selectAccountType');
      this.searchObj.AccountTypeKey = item.OLAPKey;
      this.searchObj.AccountTypeKeyValue = item.MainDemoName;
      this.searchData();
    },
    // 到报名收款
    toApplyRecord (item) {
      console.log('toApplyRecord', item);
      this.$dialog.open(this, {
        name: '报名收款',
        routerName: this.$route.name,
        moduleName: 'signuppayment',
        moduleDescription: '学员报名购买课程，如未登记的学生可点界面“+”新增学生信息后再进行报名。',
        data: { dataInfo: { StudentKey: item.StudentKey, TableID: item.TableID, isReadOnly: true } },
        callBack: { afterSucces: () => { this.getDataList(); } }
      });
    }

  }
};
</script>