 <template>
  <!-- 按钮组（通用） -->
  <div :class="vNorole">
    <div v-if="fixedBtn"
         class="btn_fixed_bottom_placeholder"></div>
    <div v-if="btnClass == 'btn_fixed_blue btn_hover_bg_blue'">
      <div class="btn_fixed_bottom"
           :class="{btn_box_fixed:fixedBtn}">
        <div @click.stop="cancelBtn"
             class="btn_fixed_white btn_hover_white canel_btn">{{cancelBtnText}}</div>
        <zx-button :btnType="btnType"
                   :btnClass="'btn_fixed_blue btn_hover_bg_blue sumbit_btn'"
                   :btnText="btnText"
                   :isSingleSubmit="isSingleSubmit||true"
                   :singleSubmitType="singleSubmitType ||'static'"
                   @click="doClick"></zx-button>
      </div>
    </div>
    <div v-else
         :class="{btn_box_fixed:fixedBtn}"
         class="footer_btn_group form_footer_btn_group ">
      <el-button round
                 class="fl canel_btn"
                 :class="vcancelBtnColor"
                 @click.stop="cancelBtn">{{cancelBtnText}}</el-button>
      <zx-button :btnClass="'btn_hover_bg_blue sumbit_btn'"
                 :btnText="btnText"
                 :isSingleSubmit="isSingleSubmit||true"
                 :singleSubmitType="singleSubmitType||'static'"
                 @click="doClick"></zx-button>
    </div>
  </div>
</template> 

<script>
import zxButton from '../common/zx-button';
export default {
  data () {
    return {
      buttontClickEventShow: false
    };
  },
  props: {
    cancelBtnText: {
      type: String,
      default: '取消'
    },
    btnText: String,
    btnClass: String, // 自定义搜索名
    isSingleSubmit: Boolean, // 是否启用单次点击
    singleSubmitType: String, // static（根据两次点击的时间间隔）, dynamic（提交完成后 手动更改参数）
    btnType: String,
    fixedBtn: Boolean,
    cancelBtnColor: [Boolean, String],
    ModulePowerKey: {// 权限
      type: Number,
      default: 0
    }
  },
  components: { zxButton },
  created () { },
  computed: {
    vcancelBtnColor () {
      if (this.cancelBtnColor == true) {
        return 'btn_light_border_blue';
      } else if (this.cancelBtnColor == false) {
        return 'btn_light_red btn_hover_white';
      } else {
        return this.cancelBtnColor;
      }
    },
    vCheckModulePower () {
      if (this.$utils.CheckModulePower(this.$store.getters.RolePowerList, this.ModulePowerKey)) {
        return true;
      } else {
        return false;
      }
    },
    vNorole () {
      if (this.vCheckModulePower) {
        return '';
      } else {
        return 'norole';
      }
    }
  },
  methods: {
    cancelBtn () {
      if (this.vCheckModulePower || this.cancelBtnText == '取消') {
        this.$emit('cancelClick'); // 点击取消触发考勤eldiago隐藏.
      } else {
        this.$utils.CheckModulePowerToTips(this.ModulePowerKey);
      }
    },
    doClick (callBack) {
      if (this.vCheckModulePower) {
        this.$emit('confirmClick', callBack);
      } else {
        this.$utils.CheckModulePowerToTips(this.ModulePowerKey);
      }
    }
  }
};
</script>

<style>
.btn_hover_bg_red.el-button:hover,
.btn_hover_bg_red.el-button:focus {
  background-color: #fca8a5 !important;
  color: #fff !important;
  cursor: pointer;
}
</style>