<template>
  <div class="content">
    <div class="content_info">
      <div class="content_top" v-if="dataInfo.AttachTypeKey">
        <div class="content_top_text">{{ vMainDemoName }}</div>
        <div class="content_top_btn">
          <div
            class="btn_icon icon_left"
            v-if="dataInfoLength > 1"
            :class="{ type_gray: dataInfo.ShowOrder == 1 }"
            @click="upInfo"
          ></div>
          <div
            class="btn_icon icon_right"
            v-if="dataInfoLength > 1"
            :class="{ type_gray: dataInfo.ShowOrder == dataInfoLength }"
            @click="downInfo"
          ></div>
          <div class="btn_icon icon_all" @click="showAllView"></div>
        </div>
      </div>
      <image-view
        v-if="dataInfo.AttachTypeKey == '1'"
        :dataInfo="dataInfo"
        :watermark="vWatermarkMendian"
      ></image-view>
      <video-view
        v-if="dataInfo.AttachTypeKey == '2'"
        :dataInfo="dataInfo"
        :watermark="vWatermarkMendian"
      ></video-view>
      <audio-view
        v-if="dataInfo.AttachTypeKey == '3'"
        :dataInfo="dataInfo"
        :watermark="vWatermarkMendian"
      ></audio-view>
      <ppt-view
        v-if="dataInfo.AttachTypeKey == '4'"
        :dataInfo="dataInfo"
        :watermark="vWatermarkMendian"
      ></ppt-view>
      <all-pdf-view
        v-if="dataInfo.AttachTypeKey == '5'"
        :dataInfo="dataInfo"
        :watermark="vWatermarkMendian" 
        :type="1"
      ></all-pdf-view>

    </div>
    <watermark-view v-if="dataInfo.AttachTypeKey" :textInfo="vWatermarkBrand"></watermark-view>
  </div>
</template>
<script>
import watermarkView from './watermark-view';
import audioView from './audio-view';
import imageView from './image-view';
import pptView from './ppt-view';
// import pdfView from "./pdf-view";
import allPdfView from '../all-pdf-view';
import videoView from './video-view';
export default {
  data () {
    return {
      type: 0
    };
  },
  components: {
    watermarkView,
    audioView,
    imageView,
    pptView,
    allPdfView,
    videoView
  },
  props: {
    dataInfo: Object,
    dataInfoLength: Number,
    watermarkInfo: Array
  },
  computed: {
    vWatermarkMendian () {
      console.log(this.watermarkInfo, 'this.watermarkInfo');
      if (this.watermarkInfo[0].SetKey && this.watermarkInfo[0].SetKey == 1 && this.watermarkInfo[1].SetKey == 1) {
        return (
          this.$store.getters.SaaSClientInfo.MainDemoName +
          ' ' +
          this.$store.getters.token.UserName
        );
      } else if (this.watermarkInfo[0].SetKey == 1) {
        return this.$store.getters.token.UserName;
      } else if (this.watermarkInfo[1].SetKey == 1) {
        return this.$store.getters.SaaSClientInfo.MainDemoName;
      } else {
        return '';
      }
    },
    vWatermarkBrand () {
      if (this.watermarkInfo[2].SetKey && this.watermarkInfo[2].SetKey == 1) {
        return this.watermarkInfo[2].SetContent;
      } else {
        return '';
      }
    },
    vMainDemoName () {
      if (this.dataInfo.MainDemoName) {
        return this.dataInfo.MainDemoName;
      } else if (this.dataInfo.AttachTypeKey) {
        return '未命名';
      } else {
        return '';
      }
    }
  },
  created () {
  },
  methods: {
    showAllView () {
      this.$emit('showAllView');
    },
    upInfo () {
      if (this.dataInfo.ShowOrder != 1) {
        this.$emit('upInfo', this.dataInfo.ShowOrder);
      }
    },
    downInfo () {
      if (this.dataInfo.ShowOrder < this.dataInfoLength) {
        this.$emit('downInfo', this.dataInfo.ShowOrder);
      }
    }
  }
};
</script>

