<template>
  <div class="homework_publish_title_box">
    <div class="homework_publish_title">
      {{tips}}
    </div>
    <div class="content_box">
      <input type="text"
             :value="value"
             v-if="!readonly"
             :placeholder="placeholder"
             @input="inputEvent($event)"
             @change="changeEvent($event)" />

      <!-- 只读模式 -->
      <span v-if="readonly">
        {{value}}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      Title: ''
    };
  },
  props: {
    tips: String,
    value: String,
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    maxLength: {
      type: Number,
      default: 100
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  created () {
  },
  methods: {
    inputEvent (e) {
      this.onkeyupEvent(e);
      this.$emit('input', e.target.value);
    },
    changeEvent (e) {
      this.onkeyupEvent(e);
      this.$emit('change', e.target.value);
    },
    limitMaxLength (str, maxLen) {
      var result = [];
      var re = /^[a-zA-Z]+$/;
      for (var i = 0; i < maxLen; i++) {
        var char = str[i];
        if (!re.test(char)) { maxLen--; }
        result.push(char);
      }
      return result.join('');
    },
    onkeyupEvent (e) {
      if (this.maxLength > 0) {
        let val = e.target.value;
        if (this.$utils.strLength(val) > this.maxLength * 2) { e.target.value = this.limitMaxLength(val, this.maxLength * 2); }
      }
    }
  }
};
</script>

<style>
</style>