<template>
  <div>
    <div style="display: flex; justify-content: space-between">
      <inputSearchContainBtn v-model="params.searchText"
                             :width="'280px'"
                             :placeholder="'请输入校区名、账户ID、手机号'"
                             @onSearchEvent="search"
                             @clearSearch="clear"></inputSearchContainBtn>
      <function-btn :item="{ name: '添加课程合作', ModulePowerKey: '' }"
                    :className="'el-button btn_light_blue btn_hover_bg_blue'"
                    @clickItem="addCampus">
      </function-btn>
    </div>

    <div class="table_list_content introduce_list campusManagement_list"
         style="margin-top: 20px">
      <tableList ref="tableListRef"
                 :tableData="dataInfo"
                 :position="''"
                 :tableColumns="tableColumns"
                 :rowKey="'Number'"
                 :totalNum="totalNum"
                 :queryParams="params"
                 :defaultSort="defaultSort"
                 @flashData='afterSuccess'
                 :footerContent="'共' + totalNum + '条数据'"
                 @loadTableData="loadTableData"
                 :expandRowData="expandRowData"
                 :monitorA="monitorA"
                 :plan="'D'">
      </tableList>
    </div>
    <div>
      <custom-dialog :title="'授权课件'"
                     :visible.sync="isShowCoursewareFormPopup"
                     :before-close="closeFormPopup">
        <authorization-courseware-form :campusID="retailInfo"
                                       @close="closeFormPopup"
                                       @afterSuccess="afterSuccess"></authorization-courseware-form>
      </custom-dialog>
      <dialog-factory ref="chainWorkBenchDialogFactory"
                      :key="'chainWorkBench'"
                      :routerName="'chainWorkBench'">
      </dialog-factory>
      <dialog-factory-common ref="dialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>

<script>
import authorizationCoursewareForm from '../../headquarters-management/authorization-courseware/form/authorization-courseware-form.vue';
import dialogFactory from '../../../common/dialog-factory';
import inputSearchContainBtn from '../../../report/common/input-search-contain-btn.vue';
import tableList from '../../../common/table-list/common-table-list';
export default {
  components: {
    inputSearchContainBtn,
    tableList,
    dialogFactory,
    authorizationCoursewareForm
  },

  props: {
    dataInfo: Array,
    totalNum: [Number, String],
    defaultSort: {
      type: Object,
      default: () => {
        return {
          prop: '',
          order: 'ascending'
        };
      }
    }
  },

  data () {
    return {
      retailInfo: '',
      monitorA: 0,
      isShowCoursewareFormPopup: false,
      expandRowData: [],
      params: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：校区名，账户ID，校区负责人手机号
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: '' //	字符串	可选		数据源：GET	排序顺序
      },
      coursewareIndo: {},
      titleName: '授权课件',
      // 总数据
      // tableData: [],
      // 表头
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '开通日期',
          width: 100,
          prop: 'OpenTime',
          type: 'date-item',
          sortable: 'custom'
        },
        {
          label: '校区名称',
          width: 150,
          prop: 'MainDemoName',
          type: 'md-name',
          sortable: false,
          extend: {
            // mendianID: 'SaaSClientNO',
            click: (rowData) => {
              this.viewClicks(rowData);
            }
          }
        },
        {
          label: '账户ID',
          prop: 'ClientNumber',
          width: 100,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '管理员',
          prop: 'AdminName',
          width: 100,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '管理员手机号',
          width: 120,
          prop: 'DefaultMobile',
          type: 'text-item',
          sortable: false
        },
        {
          label: '大课包数',
          prop: 'AuthorizeNum',
          type: 'class-hour',
          align: 'left',
          sortable: false,
          extend: {
            type: 'expand',
            click: (rowData) => { },
            isLoading: true, // 正在加载中
            tableWidth: '1275px',
            isShowOweMark: true,
            isStringClick: true,
            left: '100px',
            loadtableData: (rowData, callBack) => {
              // 获取详情数据
              this.getSearchPageList(rowData, callBack);
            },
            tableColumnsDetail: [
              {
                label: '授权日期',
                width: 100,
                prop: 'AuthorizeTime',
                type: 'date-item'
              },
              {
                label: '大课包',
                prop: 'BigCoursewarePackageKeyValue',
                width: 180,
                type: 'text-item'
              },
              {
                label: '子课包',
                prop: 'AuthContent',
                width: 160,
                type: 'text-item'
              },
              {
                label: '授权方式',
                prop: 'ClearingFormKeyValue',
                type: 'text-item',
                width: 115
              },
              {
                label: '剩余',
                prop: 'residue',
                width: 141,
                type: 'text-item',
                extend: {
                  setClassName: (rowData) => {
                    return rowData.residue == 0
                      ? 'font_red'
                      : '';
                  }
                }
              },

              {
                label: 'APP\n授权',
                prop: 'IsAPPAuthKeyValue',
                width: 50,
                type: 'text-item',
                columnClass: 'font_dual',
                extend: {
                  // className: 'font_blue ',
                  type: 'expand',
                  click: (rowData) => {
                    this.switchAppClick(rowData);
                  }
                }
              },
              {
                label: '授权\n设备',
                prop: 'AuthorizeDeviceNum',
                width: 50,
                type: 'text-item',
                columnClass: 'font_dual',
                extend: {
                  // className: 'font_blue ',
                  type: 'expand',
                  click: (rowData) => {
                    this.editAppAuthorization(rowData);
                  }
                }
              },

              {
                label: '授权\n老师',
                prop: 'AuthTeacherCount',
                width: 50,
                type: 'class-hour',
                columnClass: 'font_dual',
                sortable: false
              },

              {
                label: '状态',
                prop: 'StatusKeyValue',
                width: 80,
                align: 'left',
                type: 'text-item',
                sortable: false,
                extend: {
                  setClassName: (rowData) => {
                    // 1：启用 0：停用 2：已用完 3：已过期
                    if (rowData.StatusKey == 1) {
                      return 'status_spot_red';
                    } else if (rowData.StatusKey == 2) {
                      return 'status_spot_green';
                    } else if (rowData.StatusKey > 2) {
                      return 'status_spot_gray';
                    }
                  }
                }
              },

              {
                label: '操作',
                prop: 'option',
                type: 'table-btn',
                width: 280,
                extend: {
                  tableBtnGroup: [
                    {
                      name: '授权详情',
                      extend: {
                        isHide: (rowData) => {
                          return (
                            rowData.StatusKey ==
                            0 ||
                            rowData.ClearingFormKey ==
                            3
                          );
                        },
                        click: (rowData) => {
                          this.renewClick(rowData);
                        },
                        ModulePowerKey: ''
                      }
                    },
                    {
                      name: (rowData) => {
                        return rowData.StatusKey == 1
                          ? '恢复授权'
                          : '暂停授权';
                      },
                      extend: {
                        isHide: (rowData) => {
                          return (
                            rowData.SysStatusKey ==
                            3
                          );
                        },
                        IsInquiryHint: 'inquiryBtn',
                        setClassName: (rowData) => {
                          if (rowData.inquiryBtn) {
                            // 开启询问提示，显示询问弹窗时，界面按钮置灰，不可操作
                            return rowData.inquiryBtn;
                          }
                        },
                        click: (rowData, callBack) => {
                          if (callBack) {
                            callBack({
                              IsInquiryHint:
                                'inquiryBtn',
                              InquiryClassName:
                                'table_btn_gray'
                            });
                          }
                          this.switchClick(
                            rowData,
                            callBack
                          );
                        },
                        ModulePowerKey: ''
                      }
                    },
                    {
                      name: '续费授权',
                      extend: {
                        isHide: (rowData) => {
                          return (
                            rowData.StatusKey ==
                            1 ||
                            rowData.ClearingFormKey ==
                            3
                          );
                        },
                        click: (rowData) => {
                          this.renewClick(rowData);
                        },
                        ModulePowerKey: ''
                      }
                    }
                  ]
                }
              }
            ]
          }
        },
        {
          label: '课包续费天数',
          prop: 'AuthorizeMinimum',
          type: 'text-item',
          align: 'left',
          width: 120,
          sortable: false
        },
        {
          label: '端口到期日期',
          prop: 'CoursewareChannelExpiredTimeText',
          type: 'text-item',
          isShow: true,
          align: 'center',
          width: 150,
          sortable: 'custom'
        },
        {
          label: '使用状态',
          prop: 'StatusKeyValue',
          width: 100,
          align: 'center',
          type: 'text-item',
          sortable: false,
          extend: {
            setClassName: (rowData) => {
              // 状态 0-已停用;1-已过期;2-使用中
              if (rowData.StatusKey == 0) {
                return 'status_spot_red';
              } else if (rowData.StatusKey == 1) {
                return 'status_spot_gray';
              } else if (rowData.StatusKey == 2) {
                return 'status_spot_green';
              } else {
                return '';
              }
            }
          }
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          width: 242,
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                type: 'expand',
                extend: {
                  click: (rowData) => { },
                  tableWidth: '1275px',
                  left: '100px',
                  loadtableData: (rowData, callBack) => {
                    // 获取详情数据
                    this.getSearchPageList(
                      rowData,
                      callBack
                    );
                  },
                  tableColumnsDetail: [
                    {
                      label: '授权日期',
                      width: 100,
                      prop: 'AuthorizeTime',
                      type: 'date-item'
                    },
                    {
                      label: '大课包',
                      prop: 'BigCoursewarePackageKeyValue',
                      width: 180,
                      type: 'text-item'
                    },
                    {
                      label: '子课包',
                      prop: 'AuthContent',
                      width: 160,
                      type: 'text-item'
                    },
                    {
                      label: '授权方式',
                      prop: 'ClearingFormKeyValue',
                      type: 'text-item',
                      width: 115
                    },
                    {
                      label: '剩余',
                      prop: 'residue',
                      width: 141,
                      type: 'text-item',
                      extend: {
                        setClassName: (rowData) => {
                          return rowData.residue == 0
                            ? 'font_red'
                            : '';
                        }
                      }
                    },

                    {
                      label: 'APP\n授权',
                      prop: 'IsAPPAuthKeyValue',
                      width: 50,
                      type: 'text-item',
                      columnClass: 'font_dual',

                      extend: {
                        // className: 'font_blue ',
                        type: 'expand',
                        click: (rowData) => {
                          this.switchAppClick(
                            rowData
                          );
                        }
                      }
                    },
                    {
                      label: '授权\n设备',
                      prop: 'AuthorizeDeviceNum',
                      width: 50,
                      type: 'text-item',
                      columnClass: 'font_dual',
                      extend: {
                        // className: 'font_blue ',
                        type: 'expand',
                        click: (rowData) => {
                          this.editAppAuthorization(
                            rowData
                          );
                        }
                      }
                    },

                    {
                      label: '授权\n老师',
                      prop: 'AuthTeacherCount',
                      width: 50,
                      type: 'class-hour',
                      columnClass: 'font_dual',
                      sortable: false
                    },

                    {
                      label: '状态',
                      prop: 'StatusKeyValue',
                      width: 80,
                      align: 'left',
                      type: 'text-item',
                      sortable: false,
                      extend: {
                        setClassName: (rowData) => {
                          // 1：启用 0：停用 2：已用完 3：已过期
                          if (
                            rowData.StatusKey == 1
                          ) {
                            return 'status_spot_red';
                          } else if (
                            rowData.StatusKey == 2
                          ) {
                            return 'status_spot_green';
                          } else if (
                            rowData.StatusKey > 2
                          ) {
                            return 'status_spot_gray';
                          }
                        }
                      }
                    },

                    {
                      label: '操作',
                      prop: 'option',
                      type: 'table-btn',
                      width: 280,
                      extend: {
                        tableBtnGroup: [
                          {
                            name: '授权详情',
                            extend: {
                              isHide: (
                                rowData
                              ) => {
                                return (
                                  rowData.StatusKey ==
                                  0 ||
                                  rowData.ClearingFormKey ==
                                  3
                                );
                              },
                              click: (
                                rowData
                              ) => {
                                this.renewClick(
                                  rowData
                                );
                              },
                              ModulePowerKey: ''
                            }
                          },
                          {
                            name: (rowData) => {
                              return rowData.StatusKey ==
                                1
                                ? '恢复授权'
                                : '暂停授权';
                            },
                            extend: {
                              isHide: (
                                rowData
                              ) => {
                                return (
                                  rowData.SysStatusKey ==
                                  3
                                );
                              },
                              IsInquiryHint:
                                'inquiryBtn',
                              setClassName: (
                                rowData
                              ) => {
                                if (
                                  rowData.inquiryBtn
                                ) {
                                  // 开启询问提示，显示询问弹窗时，界面按钮置灰，不可操作
                                  return rowData.inquiryBtn;
                                }
                              },
                              click: (
                                rowData,
                                callBack
                              ) => {
                                if (callBack) {
                                  callBack({
                                    IsInquiryHint:
                                      'inquiryBtn',
                                    InquiryClassName:
                                      'table_btn_gray'
                                  });
                                }
                                this.switchClick(
                                  rowData,
                                  callBack
                                );
                              },
                              ModulePowerKey: ''
                            }
                          },
                          {
                            name: '续费授权',
                            extend: {
                              isHide: (
                                rowData
                              ) => {
                                return (
                                  rowData.StatusKey ==
                                  1 ||
                                  rowData.ClearingFormKey ==
                                  3
                                );
                              },
                              click: (
                                rowData
                              ) => {
                                this.renewClick(
                                  rowData
                                );
                              },
                              ModulePowerKey: ''
                            }
                          }
                        ]
                      }
                    }
                  ],
                  isHide: (data) => {
                    /// /有效性-1-无效;0-待确认;1-有效
                    //   console.log(
                    //     "data.IsValidKey",
                    //     data.IsValidKey != -1,
                    //     data.IsValidKey
                    //   );
                    //   return data.CounselorKey > 0;
                  }
                }
              },
              {
                name: '端口续期',
                extend: {
                  click: (rowData) => {
                    this.deleteInvestor(rowData);
                  },
                  isHide: (data) => {
                    /// /有效性-1-无效;0-待确认;1-有效
                    // return data.CounselorKey == 0;
                  }
                }
              },
              {
                name: '授权课件',
                extend: {
                  click: (rowData) => {
                    this.AuthorizeCourseware(rowData);
                  },
                  isHide: (data) => {
                    return data.StatusKey == 0 || data.StatusKey == 1;
                  }
                }
              }
            ]
          }
        }
      ],
      dataList: {},
      selectedInfo: {}
    };
  },

  created () {
    console.log(this.dataInfo, '传入的dataInfo');
    // this.tableData = this.dataInfo.PageDataList
  },
  mounted () {
    this.registerBusEvent();
  },
  methods: {
    // 搜索
    search () {
      console.log('搜索', this.params);
      this.params.pageIndex = 0;
      // this.initParams();
      // this.$refs.tableListRef.expandRowKeys = [];
      this.$emit('loadTableData', this.params);
    },
    // 清除
    clear () {
      console.log('清除', this.params);
      this.initParams();
      // this.$refs.tableListRef.expandRowKeys = [];
      this.$emit('loadTableData', this.params);
    },
    initParams () {
      this.params.pageIndex = 0;
      this.params.orderBy = '';
      this.params.searchText = '';
      this.params.sequence = '';
      // this.$emit('reloadStatictisData')
    },
    // 排序
    loadTableData (queryParams) {
      console.log('排序', queryParams);
      if (queryParams.orderBy == 'CoursewareChannelExpiredTimeText') {
        queryParams.orderBy = 'CoursewareChannelExpiredTime';
      }
      this.$emit('loadTableData', queryParams);
    },

    // 端口续费
    deleteInvestor (rowData) {
      console.log(rowData, '点击');
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '续期',
        moduleName: 'renewalForm',
        data: {
          dataInfo: {
            OLAPKey: rowData.OLAPKey, // 校区ID
            MainDemoName: rowData.MainDemoName,
            DefaultMobile: rowData.DefaultMobile,
            IsOpenChannelKey: rowData.IsOpenChannelKey, // "是否开通课件 0-否;1-是",
            IsOpenAcademicKey: rowData.IsOpenAcademicKey, // "是否开通教务  0-否;1-是",
            ExpireTime: rowData.CoursewareChannelExpiredTime // 到期日
          }
        },
        callBack: {
          afterSuccess: (data) => {
            this.$emit('afterSuccess');
          }
        }
      });
    },
    // 授权课件
    AuthorizeCourseware (rowData) {
      // let authorizationCoursewareForm = {
      //     name: "",
      //     routerName: "chainWorkBench",
      //     routertitle: "工作台",
      //     value: "授权课件",
      //     auth: true,
      //     roleName: "",
      //     keyvalue: "09",
      //     ModulePowerKey: 108,
      //     moduleName: "authorizationCoursewareForm",
      //     verifyIsSignYKKey: 1,
      //     data: {
      //         campusID: rowData.OLAPKey,
      //     },
      //     callBack: {
      //         afterSuccess: function() {
      //             this.$emit("afterSuccess");
      //         },
      //     },
      // };
      // this.openCustomDialogByFactoryDialog(authorizationCoursewareForm);
      // this.$dialog.open(this, {
      //     routerName: this.$route.name,
      //     ModulePowerKey: 108,
      //     name: "授权课件",
      //     moduleName: "authorizationCoursewareForm",
      //     data: {
      //         campusID: rowData.OLAPKey,
      //     },
      //     callBack: {
      //         afterSuccess: (data) => {
      //             this.$emit("afterSuccess");
      //         },
      //     }
      // })
      this.retailInfo = rowData.OLAPKey;
      this.isShowCoursewareFormPopup = true;
    },
    closeFormPopup () {
      this.isShowCoursewareFormPopup = false;
    },
    // 查看
    viewClicks (rowData) {
      if (rowData.OLAPKey) {
        this.$emit('viewClick', rowData);
        console.log(rowData, '校区管理-修改');
      }
    },
    //  打开添加课程合作弹窗
    addCampus () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '添加课程合作',
        moduleName: 'addCampus',
        data: {},
        callBack: {
          afterSuccess: (data) => {
            this.$emit('afterSuccess', 1);
          }
        }
      });
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.dialogFactoryCommon) {
        this.$refs.dialogFactoryCommon.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCustomDialog', dialogInfo);
      if (this.$refs.chainWorkBenchDialogFactory) {
        this.$refs.chainWorkBenchDialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title,
          'HQ'
        );
      }
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'courseCooperationManagement') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'courseCooperationManagement') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    getSearchPageList () { },
    afterSuccess () {
      this.monitorA++;
      this.$emit('afterSuccess');
    }
  }
};
</script>

<style scoped>
.table_list_content >>> .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

.table_list_content >>> .list_drop_box {
  padding-left: 25px;
}
.list_drop_box>>> .el-table__body-wrapper {
  overflow-x: auto;
}
.table_list_content >>> .el-table td .font_dual {
  display: table-cell;
}
/* 
.table_list_content >>> .pr {
  display: flex;
  padding-right: 0 !important;
  align-items: center;
  flex-direction: column;
} */

.table_list_content >>> .table_student_column_show_phone {
  font-size: 14px;
  flex: 1;
}

.table_list_content >>> .list_drop_box_icon {
  height: 0px;
}
.table_list_content >>> .list_drop_banner {
  /* overflow: auto; */
}
.table_list_content >>> .courseware-tablist {
  width: 1280px !important;
}
</style>
