<template>
  <div style="padding:20px">
    <div class="activities_statistics_number_box flex" >
       
      <div class="mendian_backstage_search">
        <div class="table_select_box" style="margin-right:20px">
          <div class="table_select_title">活动</div>
          <el-select :value="vseletedValue"
                    filterable
                    placeholder="必选"
                    @change="typeEvent"
                    value-key="OLAPKey">
            <el-option v-for="item in vActivityList"
                      :key="item.OLAPKey"
                      :label="item.MainDemoName"
                      :value="item"></el-option>
          </el-select>
        </div>
        <input-search-contain-btn v-model="searchObj.searchText"
                                 :placeholder="'请输入姓名、姓名首字母、手机号'"
                                 :clearAll="true"
                                 @clearSearch="clearSearchCondition"
                                 @onSearchEvent="searchTableInfoList"></input-search-contain-btn>
        
          
  
      </div>
      <div class="mendian_backstage_search">
         <div class="btn_hover_bg_blue search_btn "
               style="margin-left: 15px;"
               @click.stop="doExportInfoClick">导出</div>
      </div>
     
    </div>
    <div class="table_list_content summarizing_list">
        <table-list 
              :tableData="tableInfoList"
              :tableColumns="tableCourseColumns"
              :totalNum="totalNum"
              :queryParams="searchObj"
              @loadTableData="getTableInfoList"></table-list>
    </div>
  </div>
</template>
<script>
import {
  MessageSearchActivity,
  MessageSearchActivityForApplyNew,
  PushYHQManage
} from '../../../../API/workbench.js';
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../../report/common/input-search-contain-btn';

export default {
  data () {
    return {
      dataInfo: {},
      tableInfoList: [],
      totalNum: 0,
      PageCount: 0,
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        id: '0',
        idValue:'全部',
         TypeKey:'1', //活动类型 1-优惠券招生;2-引流套餐招生;3-拼团招生;4-阶梯团招生5-员工分销招生6-家长分销招生

        IsExportKey:0

      },
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          className: 'table_index_gray'
        },
        {
          label: '报名时间',
          prop: 'add_time',
          width: 140,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '活动',
          prop: 'active_title',
          width: 160,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },

        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 'auto',
          minWidth: 90,
          sortable: '',
          align: 'left',
          isShow: true,
          className: '',
          type: 'student',
          extend: {
            studentKey: 'StudentNameKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: () => {}
          }
        },
        {
          label: '报名人',
          prop: 'username',
          minWidth: 90,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item'
        },
        {
          label: '手机号',
          prop: 'telephone',
          minWidth: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item'
        },
        {
          label: '是否到店',
          prop: 'statusValue',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item'
        },
        {
          label: '到店付款',
          prop: 'payOrderMoney',
          minWidth: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item'
        },
         {
          label: '推送时间',
          prop: 'message_push_time',
          minWidth: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item'
        },
         {
          label: '分享人',
          prop: 'ShareUserName',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          width: '100',
          isShow: true,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '到店报名',
                extend: {
                  isHide: (rowData) => {
                    return rowData.status == 2;
                  },
                  click: (rowData) => {
                    this.toSignuppayment(rowData);
                  }
                }
              },
              {
                name: '推送消息',
                extend: {
                  isHide: (rowData) => {
                    if(Date.new(rowData.activity_time_end)>=Date.new()){
                      return false
                    }else{
                      return true
                    }
                  },
                  click: (rowData) => {
                    this.pushMSG(rowData)
                    // this.toSignuppayment(rowData);
                  }
                }
              }
            ]
          }
        }
      ],
      activityList:[]
    };
  },
  props: {
    selectedCouponInfo: Object
  },
  components: {
    tableList,
    inputSearchContainBtn
  },
  computed: {
     vActivityList () {
      let newArr = this.activityList;
      newArr.unshift({
        MainDemoName: '全部',
        OLAPKey: 0
      });
      return newArr;
    },
    vseletedValue () {
      return this.searchObj.id == 0
        ? '全部'
        : this.searchObj.idValue;
    },
    vAmountInfo () {
      if (this.dataInfo.buy_amount == 0) {
        return 0;
      } else {
        return this.dataInfo.you_hu_money;
      }
    },
    vActivityType () {
      if (this.dataInfo.playType == 1) {
        return '0元报名';
      } else if (this.dataInfo.playType == 2) {
        return '线上付定金';
      } else if (this.dataInfo.playType == 3) {
        this.tableCourseColumns[5].isShow = false;
        this.tableCourseColumns[6].isShow = false;
        this.tableCourseColumns[7].isShow = false;
        return '线上全款';
      } else {
        return '';
      }
    }
  },
  created () {
    this.searchObj.TypeKey = this.$route.query.TypeKey || 1;
    this.getDataList()
    this.getTableInfoList();
  },
  mounted () {
  },
  methods: {
    getDataList () {
      let fn = MessageSearchActivity;
      let searchData={
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        PublishStatus:'1',  //-1 全部， 0未发布，1已发布
        startTime: '',
        endTime: '',
        TypeKey:this.searchObj.TypeKey //活动类型 5-优惠券招生;6-引流套餐招生;7-拼团招生;8-阶梯团招生9-员工分销招生10-家长分销招生
      }
      fn(searchData).then(result => {
        this.activityList=[]
        result.data.PageDataList.forEach(o=>{
          let obj={
            MainDemoName:o.active_title,
            OLAPKey:o.id
          }
          this.activityList.push(obj)
        })
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // 选中类型
    typeEvent (obj) {
      console.log(obj, '选中');
      this.searchObj.idValue = obj.MainDemoName;
      this.searchObj.id = obj.OLAPKey;
    },
    doExportInfoClick(){
        if (this.tableInfoList.length > 0) {
        var newData = this.$utils.parseJson(this.searchObj);
        this.searchObj.IsExportKey = 1;
        this.searchObj.pageIndex = 0;
        this.searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '活动报名统计表',
          clist: [
            { title: '报名时间', cName: 'add_time', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '活动', cName: 'active_title', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '学生名', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '报名人', cName: 'username', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '手机号', cName: 'telephone', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '是否到店', cName: 'statusValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '到店付款', cName: 'payOrderMoney', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '推送时间', cName: 'message_push_time', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '分享人', cName: 'ShareUserName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
        
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchActivityForApplyNew(this.searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.searchObj.IsExportKey = 0;
          this.searchObj.pageIndex = newData.pageIndex;
          this.searchObj.pageSize = newData.pageSize;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    pushMSG(item){
      PushYHQManage(item.id).then(
        (res) => {
          this.getTableInfoList();
          layer.alert("推送消息成功")
          
        },
        (err) => {
          console.log(err.msg);
        }
      );
    },
    toSignuppayment (item) {
      let moduleInfo = {
        name: '报名收款',
        routerName: this.$route.name,
        moduleName: 'signuppayment',
        data: { dataInfo: { StudentKey: item.StudentKey, couponID: item.you_hui_id} },
        callBack: { afterSucces: () => {
          this.searchObj.pageIndex = 0;
          this.getTableInfoList();
          this.$emit('callback');
        }}
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    },
    searchTableInfoList () {
      this.searchObj.pageIndex = 0;
      this.getTableInfoList();
    },
    clearSearchCondition () {
      this.searchObj.searchText = '';
      this.searchObj.pageIndex = 0;
       this.searchObj.idValue ='';
      this.searchObj.id =0;
      this.getTableInfoList();
    },

    getTableInfoList () {
      MessageSearchActivityForApplyNew(this.searchObj).then(
        (res) => {
          console.log(res.data, 'res.data.datares.data.data');
          this.totalNum = res.data.Total;
          this.tableInfoList = [];
          res.data.PageDataList.forEach(e => {
            e.add_time = e.add_time.substring(0, 16);
            if (e.status == 2) {
              e.statusValue = '是';
            } else {
              e.statusValue = '否';
            }
            e.telephone  = this.$utils.phoneModulePower(e.telephone);
            this.tableInfoList.push(e);
          });
        },
        (err) => {
          console.log(err.msg);
        }
      );
    }
  }
};
</script>