<template>
  <div>
    <div class="choose-courseware">
      <el-tree :data="vCoursewareTreeList"
               node-key="OLAPKey"
               default-expand-all
               :default-checked-keys="vDefualtKeys"
               :expand-on-click-node="false"
               :filter-node-method="loadDatalist">
        <span class="custom-tree-node"
              slot-scope="{ node, data }">
          <span style="position: relative;height: 30px;line-height: 30px;">
            <img :src="vIconSrc(data)"
                 class="tree-icon" />
            <span :class="data.ParentKey==0?'bigFont':'smallFont'"
                  :title="data.MainDemoName">{{data.MainDemoName}}</span>
          </span>
          <span class="scopeview">
            <span style="width: 75px;display: flex;">
              <span v-if="data.StatusKey =='2'"
                    class="atext">已暂停</span>
              <span v-if="data.StatusKey =='3'"
                    class="atext"
                    style="color: red;">已终止</span>
            </span>
          </span>
        </span>
      </el-tree>
    </div>
  </div>
</template>
<script>
import {
  GetAuthorizeDetails
} from '../../../../API/workbench';
export default {
  data () {
    return {
      coursewareList: [],
      dekeys: []
    };
  },
  components: {
  },
  props: {
    AuthLotKey: {
      type: [Number, String],
      default: 0
    }
  },
  computed: {
    vCoursewareTreeList () {
      let arr = [];
      // console.log(this.newCoursewareList, this.maxCoursewareInfo, 'newCoursewareList');
      if (this.coursewareList.length > 0) {
        console.log('this.vCoursewareTreeList', this.coursewareList);
        let bigInfo = this.coursewareList.filter(item => {
          return item.TypeKey == '1';
        });
        console.log('bigInfo[0]', bigInfo);
        bigInfo[0].children = [];
        console.log('bigInfo[0]', bigInfo[0]);
        bigInfo[0].children = this.coursewareList.filter(item => {
          return item.ParentKey == bigInfo[0].OLAPKey;
        });
        console.log('bigInfo[0]', bigInfo[0]);
        bigInfo[0].children.forEach(item => {
          item.children = [];
          item.children = this.coursewareList.filter(items => {
            return items.ParentKey == item.OLAPKey;
          });
        });
        arr = bigInfo;
        console.log('bigInfo', bigInfo);
      }

      return arr;
    },
    vDefualtKeys () {
      let arr = [];
      if (this.coursewareList.length > 0) {
        // let arr1 = this.coursewareList.filter(item => {
        //   return item.StatusKey != null && item.AuthorizeMsg.AuthorizeStatusKey == '1';
        // });
        // console.log('arr1', arr1);
        this.coursewareList.forEach(item => {
          arr.push(item.OLAPKey);
        });
      }
      console.log('arr', arr);
      return arr;
    }
  },
  watch: {

  },
  created () {
    this.loadDatalist();
  },
  mounted () {

  },
  methods: {
    vIconSrc (data) {
      let str = '';
      if (data.StatusKey == '0') {
        str = require('../../../../../public/image/tree_lost.png');
      } else {
        str = require('../../../../../public/image/tree_true.png');
      }

      return str;
    },
    handleCheckChange (data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },
    handleNodeClick (data) {
      console.log(data);
    },
    // 获取大课包
    loadDatalist () {
      GetAuthorizeDetails(this.AuthLotKey).then(result => {
        console.log('加载初始大课包', result);
        result.data.forEach((o, index) => {
          // o.icon = 'folder-blue';
          // o.check = false;
        });
        this.coursewareList = result.data;
        console.log('this.dataList', this.coursewareList);
      }).catch(error => {
        console.log('加载初始大课包异常');
      });
    },
    treeNodeClick (data, checked) {
      console.log(data);
      const node = this.$refs.tree.getNode(data);
      const str = [];
      function filterName (node) {
        if (node) {
          if (node.parent) {
            str.push(node.data.name);
            filterName(node.parent);
          }
        }
      }
      // 获取当前选择的id在数组中的索引
      const indexs = this.selectList.indexOf(data.OLAPKey);
      // 如果不存在数组中，并且数组中已经有⼀个id并且checked为true的时候，代表不能再次选择。
      if (indexs < 0 && this.selectList.length === 1 && checked) {
        console.log(1);
        this.$message({
          message: '只能选择⼀个区域！',
          type: 'error',
          showClose: true
        });
        // 设置已选择的节点为false 很重要
        this.$refs.tree.setChecked(data, false);
      } else if (this.selectList.length === 0 && checked) {
        console.log(2);
        // 发现数组为空并且是已选择
        // 防⽌数组有值，⾸先清空，再push
        this.selectList = [];
        // this.ruleForm.branch = ''
        this.selectList.push(data.OLAPKey);
        filterName(node);
        // this.ruleForm.branch = str.reverse().join('/')
      } else if (indexs >= 0 && this.selectList.length === 1 && !checked) {
        console.log(checked);
        console.log(3);
        // 再次直接进⾏赋值为空操作
        this.selectList = [];
        // this.ruleForm.branch = ''
      }
    },
    renderContent (h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span>{data.MainDemoName}</span>
          <span v-if="data.AuthorizeMsg!=null" class="scopeview">
            <span>剩余{data.AuthorizeMsg.ResidueNum}天</span>
            <span>
              <el-button size="mini" type="text" on-click={() => this.append(data)}>Append</el-button>
              <el-button size="mini" type="text" on-click={() => this.remove(node, data)}>Delete</el-button>
            </span>
          </span>
        </span>);
    },
    append (data) {
      console.log('data', data);
    },
    remove (node, data) {
      console.log('data', node, data);
    },
    confirmClick (callBack) {
      if (this.chooseKey == '') {
        layer.alert('请选择课包');
        if (callBack) {
          callBack();
        }
      } else {
        this.$emit('afterSuccess', this.chooseKey.data);
      }
    },
    cancelClick () {
      this.$emit('cancelClick');
    }
  }

};
</script>
<style scoped>
.choose-courseware {
  height: 500px;
  overflow: auto;
  margin-top: 10px;
}
.choose-courseware .form_info_list {
  padding: 0;
}

.choose-courseware .folder-blue {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url('../../../../../public/cw-img/file_icon@1.png');
  background-size: 23px;
  position: relative;
  top: 4px;
  left: -3px;
}
.choose-courseware >>> .el-tree-node__content {
  height: 30px;
  border: none;
}
.choose-courseware .tree-node-span {
  width: 292px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 18px;
  -o-text-overflow: ellipsis;
}
.choose-courseware >>> .el-tree > .el-tree-node > .el-tree-node__content {
  border-bottom: none;
}

/* .choose-courseware >>> .el-tree > div {
  border-bottom: 1px solid #ececec;
} */
/* .choose-courseware .el-tree{
  padding: 0px 0px;
} */
/* .choose-courseware .el-tree>.el-tree-node>.el-tree-node__content{
  border: none;
  height: 30px;
    line-height: 30px;
    margin-bottom: 0px;
} */
/* .choose-courseware .el-tree>.el-tree-node>.el-tree-node__children>.el-tree-node>.el-tree-node__content{
  padding-left: 26px !important;
} */
.scopeview {
  width: 86px;
  display: flex;
  justify-content: space-between;
  height: 30px;
}
.custom-tree-node {
  margin-left: 20px;
  width: 336px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  height: 30px;
}
.atext {
  /* padding: 7px 0px !important; */
}

.bigFont {
  line-height: 30px;
  font-size: 17px;
  width: 250px;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600;
  display: block;
}
.smallFont {
  line-height: 30px;
  font-size: 15px;
  width: 250px;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
.choose-courseware >>> .el-icon-caret-right:before {
  content: '';
}
.tree-icon {
  position: absolute;
  left: -30px;
  top: 2px;
  width: 25px;
}
</style>
