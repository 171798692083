<template>
  <div class="general_summarizing_student"
       style="bottom: 80px;">
    <div class="payment_statistics_bottom clearfix">
      <div class="payment_statistics_bottom_select flex">
        <!-- 日期 -->
        <div>
          <date-range ref="monthPicker"
                      :fromTitle="'处理时间'"
                      :searchObj="searchObj"
                      @changeDate="changeSaveDate"></date-range>
        </div>
        <!-- 课程 -->
        <div class="table_select_box">
          <div class="table_select_title">处理类型</div>
          <el-select style="width: 100%;"
                     :value="vOperationTypeKeyValue"
                     filterable
                     placeholder="必选"
                     @change="selectedCourseEvent"
                     value-key="OLAPKey">
            <el-option v-for="item in OperationReasonSupportList"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item"></el-option>
          </el-select>
        </div>
      </div>
      <div class="course_statistics_table_search flex course_statistics_title">
        <div class="course_statistics_search">
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入姓名、姓名首字母、手机号'"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="search"></input-search-contain-btn>
          <!-- <div class="mendian_backstage_search">
            <div class="search_btn btn_hover_bg_blue"
                 style="margin: 0;"
                 @click.stop="doExportInfoClick">导出</div>
          </div> -->
        </div>
      </div>
      <div v-if="isLoading">

        <div class="table_list_content summarizing_list">
          <div class="single_result_detail">
            <table-list ref="tableListRef"
                        :tableData="dataReportList"
                        :tableColumns="vtableCourseColumns"
                        :rowKey="'Number'"
                        :totalNum="totalNum"
                        :footerContent="'共'+totalNum+'条数据'"
                        :defaultSort="defaultSort"
                        :queryParams="searchObj"
                        @loadTableData="getDataList"></table-list>
          </div>
        </div>
      </div>
      <div v-else
           class="loading_fixed_box"
           style="height: 600px;">
        <div class="loading_fixed_gray">
          <span class="loading_style">加载中...</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import tableList from '../../../common/table-list/index';
import { MessageSearchPageForException } from '../../../../API/workbench';
export default {
  data () {
    return {
      searchValue: '', // 搜索内容，防止双向绑定联动
      searchLeaveNum1: '', //	请假次数1，防止双向绑定联动
      searchLeaveNum2: '', //	请假次数2，防止双向绑定联动
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        typeKey: 0,
        leaveNum1: '', // 整型	可选		数据源：GET	请假次数1
        leaveNum2: '', // 整型	可选		数据源：GET	请假次数2
        startTime: '',
        endTime: '',
        IsAttendanceException: 1,
        OperationTypeKeyValue: ''
      },
      attendanceSearchObj: {}, // 考勤明细搜索
      OperationReasonSupportList: [
        { MainDemoName: '全部', OLAPKey: 0 },
        { MainDemoName: '校正考勤', OLAPKey: 3 },
        { MainDemoName: '超时考勤', OLAPKey: 25 }
      ], // 课程
      isLoading: false,
      totalNum: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      // 明细总览 table 列
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          type: 'text-item',
          sortable: false,
          width: 68
        },
        {
          label: '处理时间',
          prop: 'GenerateTime',
          width: 140,
          sortable: 'custom',
          type: 'text-item'
        },
        {
          label: '处理人',
          prop: 'OperationAgentKeyValue',
          type: 'text-item',
          width: 100
        },
        {
          label: '处理类型',
          prop: 'OperationTypeKeyValue',
          type: 'text-item',
          width: 120
        },
        {
          label: '对象',
          prop: 'DataKeyValue',
          type: 'text-item',
          width: 250,
          decimalPlace: 0
        },
        {
          label: '处理内容',
          prop: 'OperationSupport',
          type: 'text-item',
          width: 250
        },
        {
          label: '处理原因',
          prop: 'OperationReasonSupport',
          type: 'text-item',
          minWidth: 100
        }
      ],
      dataReportList: []
    };
  },
  components: {
    inputSearchContainBtn,
    tableList
  },
  props: {
    teacherList: Array,
    gradeClassList: Array
    // courseNameList: Array
  },
  created () {
    this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
    this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
    this.getDataList();
    this.registerBusEvent();
    console.log(this.searchObj, 'created-123');
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vtableCourseColumns () {
      let list = [];
      this.tableCourseColumns.forEach(o => {
        if (this.$utils.getSaaSClientSet(59) > 0 && o.label == '旷课') {
          o.isShow = true;
        } else if (this.$utils.getSaaSClientSet(59) == 0 && o.label == '旷课') {
          o.isShow = false;
        }
        list.push(o);
      });
      return list;
    },
    vOperationTypeKeyValue () {
      return this.searchObj.typeKey == 0 ? '全部' : this.searchObj.typeKeyValue;
    }
  },
  mounted () {

  },
  methods: {
    //  根据日期搜索
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 根据课程搜索
    selectedCourseEvent (obj) {
      console.log(obj, '选中课程');
      this.searchObj.typeKeyValue = obj.MainDemoName;
      this.searchObj.typeKey = obj.OLAPKey;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.searchText = '';
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.typeKey = 0;
      this.getDataList();
    },
    // 搜索
    search () {
      console.log(this.searchObj, '该初始值不能大于结束值');
      if (this.searchLeaveNum1 && this.searchLeaveNum2 && Number(this.searchLeaveNum1) > Number(this.searchLeaveNum2)) {
        layer.alert('请假次数的起始次数不能大于截止次数');
      } else {
        this.searchObj.pageIndex = 0;
        this.searchObj.leaveNum1 = this.searchLeaveNum1;
        this.searchObj.leaveNum2 = this.searchLeaveNum2;
        this.getDataList();
      }
    },
    // // 导出
    // doExportInfoClick (callBack) {
    //   if (this.dataReportList.length > 0) {
    //     let obj = this.$utils.parseJson(this.searchObj);
    //     obj.IsExportKey = 1;
    //     obj.pageIndex = 0;
    //     obj.pageSize = 0;
    //     let jsonFormate = {
    //       SheetTitle: '异常考勤明细',
    //       clist: [
    //         { title: '学生', cName: 'StudentNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '课程', cName: 'CourseNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '考勤课次', cName: 'ArrangeCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '签到', cName: 'SignInCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '请假', cName: 'LeaveCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '旷课', cName: 'TruantCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '补课', cName: 'MakeupCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '出勤率', cName: 'SignInRate', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
    //         { title: '学生课时', cName: 'PeriodCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }

    //       ]
    //     };
    //     console.log(jsonFormate, 'jsonFormate');
    //     MessageSearchPageForStudentCourseAttendenceReport(obj, jsonFormate).then(result => {
    //       console.log(result.data, '导出');
    //       window.location.href = this.$store.getters.APIURL + result.data.url;
    //       this.searchObj.IsExportKey = 0;
    //     }, error => {
    //       layer.alert(error.msg);
    //     });
    //   } else {
    //     layer.alert('暂无数据导出');
    //   }
    // },
    // 返回符合条件的, 当前页的指定条数学生数据
    getDataList (queryParams) {
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj);
      MessageSearchPageForException(this.searchObj).then(result => {
        this.isLoading = true;
        this.totalNum = result.data.Total;
        this.dataReportList = result.data.PageDataList;
        console.log(result.data.PageDataList, '指定条数学生数据');
      }).catch(error => {
        console.log('MessageSearchPageForStudentCourseAttendenceReport:', error.msg);
      });
    },
    // 考勤课次
    doAfterTableBtnClick (rowData) {
      console.log(rowData, 'table 按钮点击');
      this.attendanceSearchObj = this.$utils.parseJson(this.searchObj);
      this.attendanceSearchObj.StudentKey = rowData.StudentKey;
      this.attendanceSearchObj.CourseKey = rowData.CourseNameKey;
      this.attendanceSearchObj.CourseKeyValue = rowData.CourseNameKeyValue;
      this.attendanceSearchObj.searchText = rowData.StudentNameKeyValue;
      this.$emit('doAfterTableBtnClicks', this.attendanceSearchObj);
    },
    registerBusEvent () {
      // 修改学生档案的学生信息成功后触发回调
      this.$bus.on(this, 'AfterStudentUpdate', studentKey => {
        // console.log(studentKey, '修改学生档案的学生信息成功后触发回调');
        if (studentKey > 0) {
          this.getDataList();
        }
      });
      // 关闭安排考勤刷新
      this.$bus.on(this, 'closeArrangeAttendanceDialog', (data) => {
        this.getDataList();
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      this.$emit('openCustomDialogByFactoryDialog', dialogInfo);
    }
  }
};
</script>