<template>
  <div class="manage_statistics_bottom">
    <s-list></s-list>
    <synthesis-list></synthesis-list>
    <introduce-list></introduce-list>

  </div>
</template>
<script>
import sList from './footer-components/s-list';
import synthesisList from './footer-components/synthesis-list';
import introduceList from './footer-components/introduce-list';
export default {
  data () {
    return {

    };
  },
  components: {
    sList, synthesisList, introduceList
  }
};
</script>
