<template>
     <div>
        <div class="form_info_edit form_info_list">
            <input-text :formTitle="'姓名'"
                  :required="true"
                  :readonly="isReadonly"
                  v-model="dataJson.MainDemoName"></input-text>

            <input-phone :formTitle="'手机号'"
                   :required="true"
                   :readonly="vIfDefaultAdmin"
                   v-model="dataJson.Mobile"></input-phone>
          

            <form-select :formTitle="'性别'"
                           :required="true"
                           :readonly="isReadonly"
                           :dataItem="dataJson.SexKeyValue"
                           :dataList="sexData"
                           v-model="dataJson.SexKeyValue"
                           @choseItem="changeSexData"></form-select>
               <div id="screanContent"
                   style="z-index:1;position: absolute !important;right: 45px; opacity: 0"></div>
            <input-upload-img :ImgURl="dataJson.XMLIcon?dataJson.XMLIcon:''"
                                :formTitle="'头像'"
                                :imgHeight="101"
                                :imgWidth="101"
                                :readonly="isReadonly"
                                @doSetPicCorp="doSetPicCorpHeadImg"></input-upload-img>
             <input-password v-if="!TeacherId"
                            :formTitle="'初始密码'"
                            :readonly="isReadonly"
                            :formPlaceholder="'选填'"
                            :required="false"
                            :customPlaceholder='true'
                            :passwordDefaultVal="'默认密码 111111'"
                            v-model="dataJson.PassWord"></input-password>
        
        </div>
         <save-cancel-btn-group :isSingleSubmit="true"
                                 :singleSubmitType="'dynamic'"
                                 @confirmClick="confirmClick"
                                 @cancelClick="cancelClick"></save-cancel-btn-group>
     </div>
</template>
<script>
import { CWSUpdateTeacher,CWSAddTeacher,CWSGetTeacherForUpdate} from "../../../../API/workbench";
import formSelect from '../../../form-item/form-select';
export default {
    data(){
        return{
             sexData: [
        { key: 2, value: '男' },
        { key: 3, value: '女' }
      ],
            // 提交数据对象
            dataJson: {
                MainDemoName: '', // "投资人姓名",
                SexKey: '2', // "性别",
                SexKeyValue: '男', // "2-男;3-女",
                Mobile: '', // "手机号",
                PassWord:'',
                XMLIcon:''
            },
      
        }
    },
    components:{
        formSelect,
    },
    props:{
        TeacherId:{
            type:[String,Number],
            default:0
        },
        isReadonly:Boolean,
        vIfDefaultAdmin:{
            type:Boolean,
            default:()=>{
                return false
            }
        }
    },
    created(){
        if(Number(this.TeacherId)>0){
            this.getDataInfo()
        }
    },
    computed:{
    },
    methods:{
           // 员工头像地址
        doSetPicCorpHeadImg (Url) {
            this.dataJson.XMLIcon = Url;
        },
          // 性别显示
        changeSexData (val) {
            this.dataJson.SexKey = val.key;
            this.dataJson.SexKeyValue = val.value;
        },
        getDataInfo(){
            CWSGetTeacherForUpdate(this.TeacherId).then(result=>{
                console.log("CWSGetTeacherForUpdate",result.data)
                this.dataJson=result.data
            }).catch(error=>{
                console.log("errorCWSGetTeacherForUpdate",error.msg)
            })
        },
        verifyData(){
            let isFail=false
               console.log("verifyData",this.dataJson)
            if(!this.dataJson.MainDemoName){
                isFail=true
                layer.alert("请输入名字")
            }else if(!this.dataJson.Mobile){
                 isFail=true
                 layer.alert("请输入手机号码")
            }else if(this.dataJson.Mobile.length!=11){
                 isFail=true
                 layer.alert("手机号码长度只能11位数")
            }else if(this.dataJson.Mobile.toString().indexOf('1')!=0){
                 isFail=true
                 layer.alert("手机号码第一位必须为数字1")
            }else if(!this.TeacherId){      //新增
                if(this.dataJson.PassWord&&(this.dataJson.PassWord.length<6||this.dataJson.PassWord.length > 18)){
                    isFail=true
                    layer.alert("密码长度为6-18位")
                }
            }
            return isFail
        },   
        confirmClick(callBack){
            if(this.verifyData()){
                if(callBack){
                    callBack()
                }
                return false
            }
            if(this.TeacherId>0){       //修改
                CWSUpdateTeacher(this.dataJson).then(result=>{
                    console.log("CWSUpdateTeacher",result)
                       this.$emit("afterSuccess",result.data)
                        this.$emit("cancelClick")
                }).catch(error=>{
                    layer.alert(error.msg)
                    if(callBack){
                    callBack()
                }
                    console.log("errorCWSUpdateTeacher",error.msg)
                })
            }else{                 //新增
                CWSAddTeacher(this.dataJson).then(result=>{
                    console.log("CWSAddTeacher",result)
                    layer.alert("新增成功")
                     this.$emit("afterSuccess",result.data)
                        this.$emit("cancelClick")
                }).catch(error=>{
                      layer.alert(error.msg)
                    if(callBack){
                    callBack()
                }
                    console.log("errorCWSAddTeacher",error.msg)
                })
            }

        },
        cancelClick(){
            this.$emit("cancelClick")
        }
    }
}
</script>