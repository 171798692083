<template>
  <div>
    <div class="course_statistics_table_search flex course_statistics_title">
      <date-range ref="saveDate"
                  :searchObj="searchObj"
                  :fromTitle="'选择日期'"
                  @changeDate="changeSaveDate"></date-range>
      <!--  :minDate="vMinDate"
                    :maxDate="vMaxDate"
                    :isShowHistory="isShowHistory" -->
      <div class="course_statistics_search">

        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入学生名、课单号'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class=" table_list_content">
      <tableList class="summarizing_list"
                 :key="tableKey"
                 :tableData="vdataReportList"
                 :totalNum="totalNum"
                 :PageCount="PageCount"
                 :tableColumns="tableCourseColumns"
                 :isSearchText="false"
                 :isNeedInnerScroll="false"
                 :defaultSort="defaultSort"
                 :queryParams="searchObj"
                 :vFooterTotal="vFooterTotal"
                 @loadTableData="getDataList"></tableList>
    </div>
  </div>
</template>
<script>
import tableList from '../../../../common/table-list/index';
import inputSearchContainBtn from '../../../common/input-search-contain-btn';
import { GetSettlementsDayList } from '../../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0)),

        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          className: 'table_index_gray'
        },
        {
          label: '结转日期',
          prop: 'GenerateTime',
          width: ' ',
          minWidth: 120,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '学生名',
          prop: 'MainDemoName',
          width: ' ',
          minWidth: 90,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },

        {
          label: '消耗天数',
          prop: 'DeductCount',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',

        },
        {
          label: '结转天数',
          prop: 'DeductAmount',
          minWidth: 120,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',

        },
        {
          label: '消耗课单',
          prop: 'ClassNumKeyValue',
          minWidth: 120,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            setClassName: (rowData) => {
              return this.$store.getters.SaaSClientEditionKey != 5 ? 'font_blue' : 'font_black';
            },
            click: (rowData) => {
              // if (rowData.TrialTime) {
              this.seletedItem = rowData;
              if (this.$store.getters.SaaSClientEditionKey == 5) {
                return false
              }
              this.goRouter(rowData)
              // this.changeType(rowData,4);
              console.log('触发');
              // }
            }

          }
        },
        {
          label: '课单类型',
          prop: 'IsSingleKeyValue',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',

        },
        {
          label: '适用课程',
          prop: 'ApplyCorIDsNameValue',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',

        },


      ],

      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
  },
  props: {
    toSearchObj: {
      type: Object
    },
    searchTime: Object,
    searchText: String,
    schoolItem: Object
  },
  created () {
    console.log(this.toSearchObj, 'this.toSearchObj');
    this.schoolKey = this.$route.query.schoolKey || 0
    this.searchObj.searchText = this.searchText || ''
  },
  watch: {
    searchTime: {
      handler (c, o) {
        if (c) {
          //  this.getDateRangeCoursewareStat( this.$utils.getCurrentMonthFirst(),this.$utils.getCurrentMonthLast())
          setTimeout(o => {
            console.log("searchTime", this.searchObj.searchText)
            this.searchObj.startTime = c.startTime
            this.searchObj.endTime = c.endTime
            this.getDataList();
          }, 100)
        }
      },
      immediate: true,
      deep: true
    },
    'schoolItem.OLAPKey': {
      handler (c, o) {
        if (c) {
          //  this.getDateRangeCoursewareStat( this.$utils.getCurrentMonthFirst(),this.$utils.getCurrentMonthLast())
          setTimeout(o => {
            this.getDataList();
          }, 100)
        }
      },
    }

  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.DeductCount = Number(o.DeductCount)
        o.DeductAmount = '￥' + Number(Number(o.DeductAmount).toFixed(2))
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text;
      if (this.totalAmount > 0) {
        text = '+' + this.$utils.setMoneyformatToParseInt(this.totalAmount);
        text = ',合计' + text + '元';
      } else {
        text = ',合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      }
      return text;
    }
  },
  mounted () {
    // this.getDataList();
  },
  methods: {
    goRouter (item) {
      console.log('goRouter', item);
      let moduleInfo = {
        routerName: 'classListQueryStatistics',
        moduleName: '',
        ModulePowerKey: 127,
        data: { dataInfo: { StudentKey: item.StudentNameKey, TableID: item.TableID, searchText: item.ClassNumKeyValue } },

      };
      this.$bus.emit('needOpenRouter', moduleInfo);
      // layer.alert("此功能暂未开通")
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    changeType (item, type) {
      console.log("changeType", item, type)
      this.$emit("changeType", item, type)
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = GetSettlementsDayList;
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '天数结转明细',
          clist: [
            { title: '结转日期', cName: 'GenerateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生名', cName: 'MainDemoName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            {
              title: '消耗天数', cName: 'DeductCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::DeductCount::2::0}}"
            },
            {
              title: '结转天数', cName: 'DeductAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::DeductAmount::2::0}}"
            },
            { title: '消耗课单', cName: 'ClassNumKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课单类型', cName: 'IsSingleKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '适用课程', cName: 'ApplyCorIDsNameValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },


          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(searchObj, this.schoolItem.OLAPKey, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      let fn = GetSettlementsDayList;
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj, '22222');
      fn(this.searchObj, this.schoolItem.OLAPKey).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },


  }
};
</script>
<style>
.hover {
  cursor: pointer;
}
</style>