<template>
  <div style="padding-bottom:20px">
    <div class="WKT_activity_my_list" style="margin: 0;box-shadow: none;">
      <div class="list_info" style="width:100%">
        <div class="list_info_name">{{ dataInfo.active_title }}</div>
        <div class="list_info_text">
          <div class="list_info_date">
            <div class="list_info_title">活动截止日期：</div>
            <div class="list_info_font">{{ dataInfo.activitytimeend }}</div>
          </div>
          <div v-if="dataInfo.type == 4 || dataInfo.type == 2" class="list_info_date">
            <div class="list_info_title">活动售价：</div>
            <div class="list_info_font"><span class="font_yellow">{{Number(dataInfo.buy_amount) }}</span>（线上<span class="font_black">{{Number(dataInfo.buy_amount)}}</span>元，扣减 <span class="font_black">{{Number(dataInfo.di_xian_jin)}}</span>元）</div>
          </div>
          <div v-else-if="dataInfo.taocanid > 0" class="list_info_date">
            <div class="list_info_title">活动售价：</div>
            <div class="list_info_font"><span class="font_yellow">{{Number(dataInfo.you_hu_money) }}</span>（线上<span class="font_black">{{Number(dataInfo.buy_amount)}}</span>元，到店 <span class="font_black">{{Number(dataInfo.you_hu_money) - Number(dataInfo.buy_amount)}}</span>元）</div>
          </div>
          <div v-else-if="dataInfo.activityType == 1" class="list_info_date">
            <div class="list_info_title">活动售价：</div>
            <div class="list_info_font"><span class="font_yellow">{{Number(dataInfo.you_hu_money) }}</span>（线上<span class="font_black">0</span>元，到店 <span class="font_black">{{Number(dataInfo.you_hu_money)}}</span>元）</div>
          </div>
          <div v-else-if="dataInfo.activityType == 2" class="list_info_date">
            <div class="list_info_title">活动售价：</div>
            <div class="list_info_font"><span class="font_yellow">{{Number(dataInfo.you_hu_money) }}</span>（线上<span class="font_black">{{Number(dataInfo.buy_amount)}}</span>元，到店 <span class="font_black">{{Number(dataInfo.you_hu_money) -Number(dataInfo.buy_amount)}}</span>元）</div>
          </div>
          <div v-else-if="dataInfo.activityType == 3" class="list_info_date">
            <div class="list_info_title">活动售价：</div>
            <div class="list_info_font"><span class="font_yellow">{{Number(dataInfo.you_hu_money) }}</span>（线上<span class="font_black">{{Number(dataInfo.buy_amount)}}</span>元，到店 <span class="font_black">0</span>元）</div>
          </div>
          <div v-if="dataInfo.only_newuser == 1" class="last_box text_list">
            <div class="list_info_font">限新生参加</div>
          </div>
        </div>
        <div class="list_info_text">
          <div class="text_list">
            <div class="list_info_title">使用模板：</div>
            <div class="list_info_font">
              {{ dataInfo.SceneKeyValue }} | {{ dataInfo.activityTitle }}
            </div>
          </div>
          <div class="text_list">
            <div class="list_info_title">玩法类型：</div>
            <div class="list_info_font">
              {{ vActivityType }} | {{ dataInfo.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="activities_statistics_number_box flex">
      <div class="activities_statistics_number flex">
          <div class="number_dual flex">
              <div class="number_list">
                  <span class="font_gray">线上报名（人）</span>
                  <span>{{dataInfo.total_get_num}}</span>
              </div>
              <div class="number_list">
                  <span class="font_gray">报名金额（元）</span>
                  <span>{{dataInfo.buyCouponMony}}</span>
              </div>
          </div>
          <div class="number_dual flex">
              <div class="number_list">
                  <span class="font_gray">到店付款（人）</span>
                  <span>{{dataInfo.total_use_num}}</span>
              </div>
              <div class="number_list">
                  <span class="font_gray">报名金额（元）</span>
                  <span>{{dataInfo.payOrderMoney}}</span>
              </div>
          </div>
      </div>
      <div class="mendian_backstage_search">
        <input-search-contain-btn v-model="searchObj.searchText"
                                 :placeholder="'请输入姓名、姓名首字母、手机号'"
                                 :clearAll="true"
                                 @clearSearch="clearSearchCondition"
                                 @onSearchEvent="searchTableInfoList"></input-search-contain-btn>
        
          <div class="btn_hover_bg_blue search_btn "
               style="margin-left: 15px;"
               @click.stop="doExportInfoClick">导出</div>
  
      </div>
    </div>
    <div class="table_list_content summarizing_list">
        <table-list 
              :tableData="tableInfoList"
              :tableColumns="tableCourseColumns"
              :totalNum="totalNum"
              :queryParams="searchObj"
              @loadTableData="getTableInfoList"></table-list>
    </div>
  </div>
</template>
<script>
import {
  GetCouponInfoById,
  MessageSearchActivityForApply,
  PushYHQManage
} from '../../../API/workbench.js';
import tableList from '../../common/table-list/index';
import inputSearchContainBtn from '../../report/common/input-search-contain-btn';

export default {
  data () {
    return {
      dataInfo: {},
      tableInfoList: [],
      totalNum: 0,
      PageCount: 0,
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        id: '',
        IsExportKey:0

      },
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          className: 'table_index_gray'
        },
        {
          label: '报名时间',
          prop: 'add_time',
          width: 171,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 'auto',
          minWidth: 110,
          sortable: '',
          align: 'left',
          isShow: true,
          className: '',
          type: 'student',
          extend: {
            studentKey: 'StudentNameKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: () => {}
          }
        },
        {
          label: '报名人',
          prop: 'username',
          minWidth: 163,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item'
        },
        {
          label: '手机号',
          prop: 'telephone',
          minWidth: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item'
        },
        {
          label: '是否到店',
          prop: 'statusValue',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item'
        },
        {
          label: '到店付款',
          prop: 'payOrderMoney',
          minWidth: 130,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item'
        },
         {
          label: '推送时间',
          prop: 'message_push_time',
          minWidth: 180,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item'
        },
         {
          label: '分享人',
          prop: 'ShareUserName',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          width: '100',
          isShow: true,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '到店报名',
                extend: {
                  isHide: (rowData) => {
                    return rowData.status == 2;
                  },
                  click: (rowData) => {
                    this.toSignuppayment(rowData);
                  }
                }
              },
              {
                name: '推送消息',
                extend: {
                  isHide: (rowData) => {
                    if(Date.new(this.dataInfo.activitytimeend)>=Date.new()){
                      return false
                    }else{
                      return true
                    }
                  },
                  click: (rowData) => {
                    this.pushMSG(rowData)
                    // this.toSignuppayment(rowData);
                  }
                }
              }
            ]
          }
        }
      ]
    };
  },
  props: {
    selectedCouponInfo: Object
  },
  components: {
    tableList,
    inputSearchContainBtn
  },
  computed: {
    vAmountInfo () {
      if (this.dataInfo.buy_amount == 0) {
        return 0;
      } else {
        return this.dataInfo.you_hu_money;
      }
    },
    vActivityType () {
      if (this.dataInfo.playType == 1) {
        return '0元报名';
      } else if (this.dataInfo.playType == 2) {
        return '线上付定金';
      } else if (this.dataInfo.playType == 3) {
        this.tableCourseColumns[5].isShow = false;
        this.tableCourseColumns[6].isShow = false;
        this.tableCourseColumns[7].isShow = false;
        return '线上全款';
      } else {
        return '';
      }
    }
  },
  created () {
    this.searchObj.id = this.selectedCouponInfo.id;
    this.getActivitiesInfo();
    this.getTableInfoList();
  },
  mounted () {
  },
  methods: {
    doExportInfoClick(){
        if (this.tableInfoList.length > 0) {
        var newData = this.$utils.parseJson(this.searchObj);
        this.searchObj.IsExportKey = 1;
        this.searchObj.pageIndex = 0;
        this.searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '活动报名统计表',
          clist: [
            { title: '报名时间', cName: 'add_time', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '学生名', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '报名人', cName: 'username', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '手机号', cName: 'telephone', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '是否到店', cName: 'statusValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '到店付款', cName: 'payOrderMoney', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '推送时间', cName: 'message_push_time', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '分享人', cName: 'ShareUserName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
        
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchActivityForApply(this.searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.searchObj.IsExportKey = 0;
          this.searchObj.pageIndex = newData.pageIndex;
          this.searchObj.pageSize = newData.pageSize;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    pushMSG(item){
      PushYHQManage(item.id).then(
        (res) => {
           this.getActivitiesInfo();
          this.getTableInfoList();
          layer.alert("推送消息成功")
          
        },
        (err) => {
          console.log(err.msg);
        }
      );
    },
    toSignuppayment (item) {
      let moduleInfo = {
        name: '报名收款',
        routerName: this.$route.name,
        moduleName: 'signuppayment',
        data: { dataInfo: { StudentKey: item.StudentKey, couponID: item.you_hui_id} },
        callBack: { afterSucces: () => {
          this.searchObj.pageIndex = 0;
          this.getActivitiesInfo();
          this.getTableInfoList();
          this.$emit('callback');
        }}
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    },
    searchTableInfoList () {
      this.searchObj.pageIndex = 0;
      this.getTableInfoList();
    },
    clearSearchCondition () {
      this.searchObj.searchText = '';
      this.searchObj.pageIndex = 0;
      this.getTableInfoList();
    },
    getActivitiesInfo () {
      GetCouponInfoById(this.selectedCouponInfo.id).then(
        (res) => {
          this.dataInfo = res.data;
          this.dataInfo.activitytimeend = this.dataInfo.activitytimeend.substring(0, 10);
        },
        (err) => {
          console.log(err.msg);
        }
      );
    },
    getTableInfoList () {
      MessageSearchActivityForApply(this.searchObj).then(
        (res) => {
          console.log(res.data, 'res.data.datares.data.data');
          this.totalNum = res.data.Total;
          this.tableInfoList = [];
          res.data.PageDataList.forEach(e => {
            e.add_time = e.add_time.substring(0, 16);
            if (e.status == 2) {
              e.statusValue = '是';
            } else {
              e.statusValue = '否';
            }
            e.telephone  = this.$utils.phoneModulePower(e.telephone);
            this.tableInfoList.push(e);
          });
        },
        (err) => {
          console.log(err.msg);
        }
      );
    }
  }
};
</script>