<template>
  <div class="courseware_add_right">
    <div v-if="editType == '0'"
         class="courseware_add_nodata">点击左侧添加课件内容</div>
    <teaching-step-editor v-if="editType == '1'"
                          :dataInfo="dataInfo"
                          :coursewareId="coursewareId"
                          :authorizedCode="authorizedCode"
                          @editText="editTeachingStep"
                          @saveText="saveTeachingStep"></teaching-step-editor>

    <accessory-editor v-if="editType == '2'"
                      :attachType="attachType"
                      :dataInfo="editInfo"
                      :authorizedCode="authorizedCode"
                      @seletedPPTItem="seletedPPTItem"
                      @deleteItem="deleteAccessory"
                      @saveAccessory="saveAccessory"></accessory-editor>

    <language-editor v-if="editType == '3'"
                     :coursewareId="coursewareId"
                     :dataInfo="editInfo"
                     @upCourseEvaluationInfo="upCourseEvaluationInfo"></language-editor>
    <home-work v-if="editType == '4'"
               :coursewareId="coursewareId"
               :dataInfo="dataInfo.HomeWork"
               @upHomeworkInfo="upHomeworkInfo"></home-work>
    <cover-editor v-if="editType == '5'"
               :coursewareId="coursewareId"
               :dataInfo="dataInfo"
               @deleteCoverUrl="deleteCoverUrl"
               ></cover-editor>
  </div>
</template>
<script>
import accessoryEditor from './accessory-editor/index';
import languageEditor from './language-editor';
import teachingStepEditor from './teaching-step-editor';
import homeWork from './home-work';
import coverEditor from './cover-editor';
export default {
  data () {
    return {
    };
  },
  props: {
    dataInfo: Object,
    editType: String,
    attachType: String,
    editInfo: Object,
    coursewareId: [String, Number],
    authorizedCode: String
  },
  components: {
    accessoryEditor, languageEditor, teachingStepEditor, homeWork,coverEditor
  },
  methods: {
    deleteCoverUrl(data){
      console.log("deleteCoverUrl",data)
      this.$emit('deleteCoverUrl');
    },
    seletedPPTItem(data){
      this.$emit('selectPPTAccessory',data)
    },
    saveAccessory () {
      this.$emit('saveAccessory', this.editInfo);
    },
    saveTeachingStep () {
      this.$emit('uploadCourseware');
    },
    editTeachingStep (text) {
      this.dataInfo.TeachingProcedure = text;
    },
    deleteAccessory (data) {
      console.log(data, 'data');
      console.log(this.dataInfo, 'this.dataInfo');
      this.$emit('deleteAccessory');
      if (data.TypeKey == 1) {
        let n = this.dataInfo.CoursewareAttach.findIndex((item) => { return item.ShowOrder == data.ShowOrder; });
        this.dataInfo.CoursewareAttach.splice(n, 1);
        for (let i = 0; i < this.dataInfo.CoursewareAttach.length; i++) {
          this.dataInfo.CoursewareAttach[i].ShowOrder = i + 1;
        }
        this.dataInfo.CoursewareAttach.sort((x, y) => {
          return x.ShowOrder - y.ShowOrder;
        });
      } else if (data.TypeKey == 2) {
        let t = this.dataInfo.CoursewareTeachingStep.findIndex((item) => { return item.ShowOrder == data.ShowOrder; });
        this.dataInfo.CoursewareTeachingStep.splice(t, 1);
        for (let i = 0; i < this.dataInfo.CoursewareTeachingStep.length; i++) {
          this.dataInfo.CoursewareTeachingStep[i].ShowOrder = i + 1;
        }
        this.dataInfo.CoursewareTeachingStep.sort((x, y) => {
          return x.ShowOrder - y.ShowOrder;
        });
      }
    },
    upCourseEvaluationInfo (data) {
      if (data) {
        let i = this.dataInfo.Evaluation.findIndex((obj) => { return obj.OLAPKey == data.essayID; });
        if (i == -1) {
          this.dataInfo.Evaluation.push({
            OLAPKey: data.essayID,
            Content: data.Content
          });
        } else {
          this.dataInfo.Evaluation[i].Content = data.Content;
        }
      }
      this.$emit('upCourseEvaluationInfo', data);
    },
    upHomeworkInfo () {
      this.$emit('upHomeworkInfo');
    }
  }
};
</script>