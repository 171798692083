<template>
  <div class="single_result"
       :class="item.isActive?'opt':''"
       @click.stop="seletedItem">
    <div class="single_result_left student_photo">
      <!-- <img :src="headImg"
           :onerror="defaultImg"
           alt=""> -->
      <heads-img :imgUrl="headImg"
                 :dataInfo="item"></heads-img>
    </div>
    <div class="single_result_right student_info">
      <div class="student_info_name">
        <span>
          <span :title="item.MainDemoName||item.StuKeyValue"
                class="fl">{{item.MainDemoName||item.StuKeyValue}}</span>
        </span>
        <div class="course_student_info fr">
          <div class="course_student_sign_type sign_type_attendance"
               v-if="studentAttenceStatusKey==3">签到</div>
          <div class="course_student_sign_type sign_type_truancy"
               v-else-if="studentAttenceStatusKey==5">旷课</div>
          <div class="course_student_sign_type sign_type_ask_leave"
               v-else-if="studentAttenceStatusKey==4">请假</div>
          <div class="course_student_sign_type sign_type_noattendance"
               v-else-if="studentAttenceStatusKey==2">未考勤</div>
        </div>
      </div>
      <div class="student_info_otherinfo font_gray">{{item.RelationKeyValue}} {{$utils.phoneModulePower(item.MobileSupport)}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'studentItem',
  data () {
    return {

    };
  },
  props: ['item'],
  computed: {
    headImg () {
      let pcDomainURLOld = this.$store.getters.CDNURL;
      if (!this.item.HeadImgSupport) {
        this.item.HeadImgSupport = '';
        if (parseInt(this.item.SexKey) == 2) {
          return require('../../../../../public/image/deflut_studentPhoto_boy.png');
        } else {
          return require('../../../../../public/image/deflut_studentPhoto_gril.png');
        }
      }
      if (this.item.HeadImgSupport.indexOf('http') > -1) {
        return this.item.HeadImgSupport;
      } else if (this.item.HeadImgSupport && this.item.HeadImgSupport.indexOf('/') == -1) {
        return pcDomainURLOld + '/' + this.item.HeadImgSupport;
      } else {
        return pcDomainURLOld + this.item.HeadImgSupport;
      }
    },
    studentAttenceStatusKey () {
      return this.item.AttenceStatusKey;
    }
  },
  methods: {
    seletedItem () {
      this.$emit('seletedItem', this.item);
    }
  }
};
</script>
