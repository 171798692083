import { render, staticRenderFns } from "./sand-student-list.vue?vue&type=template&id=fad43bbc"
import script from "./sand-student-list.vue?vue&type=script&lang=js"
export * from "./sand-student-list.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports