<template>
  <div class="">
    <div class="from_title">
      <span>虚拟人气设置</span>
      <span class="font_gray">适量的虚拟数据能制造人气提升效果。</span>
    </div>
    <div>
      <form-input v-show="settingInfo.virtualSoftHitsSetting.isOpen&&settingInfo.virtualSoftHitsSetting.browseCount.isOpen"
                  :formTitle="'虚拟浏览'"
                  :unintTitle="'人'"
                  v-model="CouponInfo.virtual_read_person_num"
                  :tipsTitle="''"
                  :decimalPlaces="0"
                  :isNumber="true"
                  :required="false"
                  :inputClass="'from_list_input_three'"></form-input>
      <!-- <form-input v-show="settingInfo.virtualSoftHitsSetting.enrollCount.isOpen"
                  :formTitle="'已虚拟参与'"
                  :unintTitle="'人'"
                  :value="VirtualNumber"
                  :tipsTitle="'虚拟报名人数会占用报名名额'"
                  :required="false"
                  :isNumber="true"
                  :readonly="true"
                  :inputClass="'from_list_input_three'"></form-input> -->
      <div class="from_list"
           v-if="settingInfo.groupSetting.virtualGroup.isOpen">
        <div class="from_list_title">
          虚拟团数
        </div>
        <div class="from_list_content">
          <div class="from_list_content">
            <form-content-input :formTitle="''"
                                v-model="CouponInfo.groupSetting.virtual_group_count"
                                :tipsTitle="''"
                                :required="false"
                                :unintTitle="'个'"
                                :inputClass="'from_list_input_three'"
                                :formPlaceholder="'例如: 2'"
                                :isNumber="true"
                                :decimalPlaces="0"
                                :titleClass="''"></form-content-input>
            <form-content-input :formTitle="''"
                                v-model="CouponInfo.groupSetting.virtual_group_person_num"
                                :tipsTitle="'少于0个虚拟团会自动补齐'"
                                :required="false"
                                :unintTitle="'人团'"
                                :decimalPlaces="0"
                                :formPlaceholder="'例如: 5'"
                                :inputClass="'from_list_input_three'"
                                :isNumber="true"
                                :titleClass="''"></form-content-input>
            <span class="font_gray">少于{{Number(CouponInfo.groupSetting.virtual_group_count)}}个代表团会自动补齐</span>
          </div>
        </div>
      </div>
      <form-input v-show="settingInfo.virtualSoftHitsSetting.isOpen&&settingInfo.virtualSoftHitsSetting.enrollCount.isOpen"
                  :formTitle="'每日虚拟报名'"
                  :unintTitle="'人'"
                  :isNumber="true"
                  :decimalPlaces="0"
                  v-model="CouponInfo.virtual_get_person_setting.perDayNum"
                  :required="false"
                  :inputClass="'from_list_input_three'"></form-input>
      <form-input v-show="settingInfo.groupSetting.virtualGroup.isOpen||settingInfo.virtualSoftHitsSetting.enrollCount.isOpen"
                  :formTitle="'最多虚拟报名'"
                  :unintTitle="'人'"
                  :isNumber="true"
                  :decimalPlaces="0"
                  v-model="CouponInfo.maxVirNum"
                  :required="false"
                  :inputClass="'from_list_input_three'">
        <span class="font_gray">当前已虚拟报名
          <span class="font_black">{{VirtualNumber}}</span>人
        </span>
      </form-input>
      <!-- <div class="from_list"
           v-show="settingInfo.virtualSoftHitsSetting.enrollCount.isOpen">
        <div class="from_list_info_box WKT_discount_info_from">
          <form-input-date v-model="CouponInfo.virtual_get_person_setting.bgeinDate"
                           :elementId="'classDate'"
                           :formTitle="'起始日期'"
                           :minDate="$utils.formatDateToLine(Date.new())"
                           :formPlaceholder="'请选择'"
                           :tipsTitle="''"
                           @changeDate="changeStartDate">
          </form-input-date>
          <form-input-date v-model="CouponInfo.virtual_get_person_setting.endDate"
                           :elementId="'classDate'"
                           :formTitle="'结束日期'"
                           :minDate="$utils.formatDateToLine(Date.new())"
                           :formPlaceholder="'请选择'"
                           :tipsTitle="''"
                           @changeDate="changeEndDate">
          </form-input-date>
          <div class="from_list">
            <div class="from_list_title">
              参与时间
            </div>
            <div class="from_list_content">
              <div class="from_list_time">
                <div class="from_list_dual_time">
                  <el-time-select placeholder=""
                                  class="form_date_two"
                                  v-model="CouponInfo.virtual_get_person_setting.bgeinTime"
                                  @change="changeStartTime($event)"
                                  :picker-options="{ start: '08:00', step: '00:60', end: '24:00'}">
                  </el-time-select>
                </div>
                <div class="from_list_dual_time">
                  <el-time-select placeholder=""
                                  class="form_date_two tl"
                                  v-model="CouponInfo.virtual_get_person_setting.endTime"
                                  @change="changeEndTime($event)"
                                  :picker-options="{ start: '08:00', step: '00:60',  end: '24:00', minTime: CouponInfo.virtual_get_person_setting.bgeinTime}">
                  </el-time-select>
                </div>
              </div>
            </div>
          </div>
          <form-input :formTitle="'每日参与'"
                      :unintTitle="'人'"
                      :isNumber="true"
                      :decimalPlaces="0"
                      v-model="CouponInfo.virtual_get_person_setting.perDayNum"
                      :tipsTitle="'共'+vVirtualDay+'天'"
                      :required="false"
                      :inputClass="'from_list_input_three'"></form-input>
        </div>
      </div> -->
      <div class="WX_message_tips"
           v-show="settingInfo.virtualSoftHitsSetting.isOpen&&settingInfo.virtualSoftHitsSetting.enrollCount.isOpen">
        <div class="WX_message_tips_title">
          虚拟报名人数会占用报名名额
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import formInput from '../common/form-input';
import formInputDate from '../common/form-input-date';
import formContentInput from '../common/form-content-input';
export default {
  data () {
    return {
      timeList: [
        { name: '08:00' },
        { name: '09:00' },
        { name: '10:00' },
        { name: '11:00' },
        { name: '12:00' },
        { name: '13:00' },
        { name: '14:00' },
        { name: '15:00' },
        { name: '16:00' },
        { name: '17:00' },
        { name: '18:00' },
        { name: '19:00' },
        { name: '20:00' },
        { name: '21:00' },
        { name: '22:00' },
        { name: '23:00' }
      ]
    };
  },
  props: {
    CouponInfo: Object,
    settingInfo: Object
  },
  components: {
    formContentInput, formInput, formInputDate
  },
  computed: {
    vEndtimeList () {
      let newArr = [];
      if (this.CouponInfo.virtual_get_person_setting.bgeinTime) {
        let locatIndex = this.timeList.findIndex(o => {
          return o.name == this.CouponInfo.virtual_get_person_setting.bgeinTime;
        });
        if (locatIndex >= 0) {
          this.timeList.forEach((o, index) => {
            if (index < locatIndex) {
              o.disable = true;
            } else {
              o.disable = false;
            }
          });
        }
      } else {
        newArr = this.timeList;
      }
      return newArr;
    },
    VirtualNumber () {
      return this.CouponInfo.VirtualNumber || 0;
    },
    vVirtualDay () {
      let num = 0;
      if (this.CouponInfo.virtual_get_person_setting.bgeinDate && this.CouponInfo.virtual_get_person_setting.endDate) {
        let endTime = Date.new(this.CouponInfo.virtual_get_person_setting.endDate).getTime();
        let startTime = Date.new(this.CouponInfo.virtual_get_person_setting.bgeinDate).getTime();
        num = (endTime / 1000 / 86400) - (startTime / 1000 / 86400);
        return num + 1;
      } else {
        return num;
      }
    }
  },
  methods: {
    changeStartDate (val) {
      console.log('changeStartTime', val);
    },
    changeEndDate (val) {
      console.log('changeEndTime', val);
    },
    clickEvent (val) {

    },
    changeStartTime (val) {
      console.log(val, 'changeStartTime');
      let startIndex = this.timeList.findIndex(o => {
        return o.name == this.CouponInfo.virtual_get_person_setting.bgeinTime;
      });
      let endIndex = this.timeList.findIndex(o => {
        return o.name == this.CouponInfo.virtual_get_person_setting.endTime;
      });
      if (startIndex >= 0 && endIndex >= 0 && startIndex > endIndex) {
        layer.alert('开始时间不能大于结束时间');
      }
    },
    changeEndTime (val) {
      console.log(val, 'val', this.timeList);
      let startIndex = this.timeList.findIndex(o => {
        return o.name == this.CouponInfo.virtual_get_person_setting.bgeinTime;
      });
      let endIndex = this.timeList.findIndex(o => {
        return o.name == this.CouponInfo.virtual_get_person_setting.endTime;
      });
      console.log(startIndex, endIndex, 'changeEndTime');
      if (startIndex >= 0 && endIndex >= 0 && startIndex > endIndex) {
        layer.alert('开始时间不能大于结束时间');
      }
    }
  }
};
</script>
