<template>
  <div class="course_statistics_echarts_box pr"
       :class="{marginleft:modeType%2==0}">
    <div class="titletext">{{vTitleArr}} </div>
    <type-echart :echartID="'comparativeAnalysis'+modeType"
                 :reqList="monthList"
                 :nowDataList="nowList"
                 :preDataList="oldList"
                 :monthTitle="yUnit"
                 :secondTitle="unit"
                 :echartType="'Bar'"
                 ref="echart"></type-echart>
  </div>
</template>
<script>
import typeEchart from './type-echart';
export default {
  data () {
    return {
      monthList: [],
      nowList: [],
      oldList: [],
      yUnit:'',
      unit:''
    };
  },
  props: {
    modeType: Number,
    DataList: Array
  },
  components: {
    typeEchart
  },
  watch: {
    'DataList': {
      handler (newval, oldval) {
        this.getDataList();
      },
      deep: true
    }
  },
  computed: {
    vDataList () {
      return this.DataList || [];
    },
    vTitleArr () {
      let arr = '';
      if (this.modeType == 1) {
        arr = '诺访率对比';
      } else if (this.modeType == 2) {
        arr = '约课率对比';
      } else if (this.modeType == 3) {
        arr = '试课率对比';
      } else {
        arr = '成交率占比';
      }
      return arr;
    }
  },
  mounted () {
    this.getDataList();
  },
  methods: {
    getDataList () {
      this.monthList = [];
      this.vDataList.forEach((o, i) => {
        if (o.SourceTypeKeyValue || o.SourceTypeKeyValue == '') {
          this.monthList.push(o.SourceTypeKeyValue);
        }
      });
      if (this.monthList.length < 8) {
        let j = 8 - this.monthList.length;// 显示8个
        for (var i = 0; i < j; i++) {
          this.monthList.push(' ');
        }
      }

      if(this.monthList.length > 8){
        this.monthList = this.monthList.splice(0,9)
      }
      this.nowList = this.getNowList();
      console.log(this.vDataList, 'this.nowDataList this.nowList this.oldList', this.nowList, this.oldList);
      this.$nextTick(() => {
        this.$refs.echart.echartInit();
      });
      this.$forceUpdate();
    },
    getNowList () {
      let nowList = [];
      this.monthList.forEach(p => {
        let item = this.vDataList.find(o => {
          return o.SourceTypeKeyValue == p;
        });
        if (item) {
          nowList = this.getItem(nowList, item);
        } else {
          nowList.push('0');
        }
      });

      console.log('数据测试', nowList, this.modeType);
      return nowList;
    },
    getItem (nowList, item) {
      console.log('jjjj', item);
      if (this.modeType == 1) {
        nowList.push(Number(item.PromiseVisitRate));
        this.unit = '%'
          this.yUnit = '%'
      } else if (this.modeType == 2) {
        nowList.push(Number(item.InvitedLessonRate));
        this.unit = '%'
          this.yUnit = '%'
      } else if (this.modeType == 3) {
        nowList.push(Number(item.FinishTrialLessonRate));
        this.unit = '%'
          this.yUnit = '%'
      } else if (this.modeType == 4) {
        nowList.push(Number(item.SignUpTransformationRate));
        this.unit = '%'
          this.yUnit = '%'
      }

      return nowList;
    }
  }
};
</script>
<style scoped>
.course_statistics_echarts_box {
  height: 310px !important;
  border: 1px solid #ececec;
  width: 43%;
  margin-bottom: 50px;
}
.marginleft {
  margin-left: 10%;
}
.titletext {
  font-family: Alibaba PuHuiTi;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
  margin-left: 15px;
  margin-top: 15px;
}
</style>

