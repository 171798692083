<template>
  <!-- 设置表单 -->
  <div class="general_class_list"
       @click.stop="doComboClick(children,$event)">
    <div class="list_name">
      <span ref="MainDemoName">{{children.MainDemoName}}</span>
      <div class="list_name_icon"></div>
    </div>
    <div class="list_info">
      <!-- <el-tooltip effect="light"
                    visible-arrow="false"
                    :content="children.CourseName"
                    :disabled="children.CourseName.length>14"
                    placement="top"> -->
      <div class="list_info_course"
           v-if="children.ApplyCorName">{{children.ApplyCorName}}</div>
      <!-- </el-tooltip> -->
      <div class="list_info_teacher"
           v-if="children.LecturerKeyValue">{{children.LecturerKeyValue}}</div>
    </div>
  </div>
</template> 

<script>

export default {
  data () {
    return {
    };
  },
  props: {
    children: Object
  },
  components: {

  },

  created () {

  },
  mounted () {

  },
  activated () {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    doComboClick (item, e) {
      // if (this.$refs.MainDemoName) {
      let offsetWidth = this.$refs.MainDemoName.offsetWidth;
      // }
      this.$emit('noComboClick', item, e, offsetWidth);
    }

  }

};
</script>

  <style>
/*  */
</style>