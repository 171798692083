<template>
  <div class="franchisee_head_summarizing flex">
    <div class="top_left_ul">
      <statistics-box :dataList="statisticsData"
                      @clickEvent="doAfterCilckLeftSummaryData"></statistics-box>
    </div>

    <div class="top_right_ul">
      <statistics-box :dataList="portStatisticsData"
                      @clickEvent="doAfterCilckRightSummaryData"></statistics-box>
    </div>
  </div>
</template>

<script>
import statisticsBox from '../../../report/common/statistics-box';// 统计栏 GetFranchiseeStatisticalData
export default {
  components: {
    statisticsBox
  },
  data () {
    return {
      statisticsData: {
        InUse: { title: '使用中', value: 0, key: 1, type: 0, numberClassName: '', isSelection: true },
        Renew: { title: '门店续费预警', value: 0, key: 2, type: 1, numberClassName: 'number_color_orange', isSelection: false },
        // Disable: { title: '门店已停用', value: 0, key: 3, type: 2, numberClassName: 'number_color_red', isSelection: false }
      },
      portStatisticsData: {
        AcademicPortExpire: { title: '教务端口到期', value: 0, key: 4, type: 0, numberClassName: 'number_color_red', isSelection: false },
        AcademicPortExpireWarning: { title: '教务端口到期预警', value: 0, key: 5, type: 1, numberClassName: 'number_color_orange', isSelection: false },
        CoursewarePortExpire: { title: '课件端口到期', value: 0, key: 6, type: 2, numberClassName: 'number_color_red', isSelection: false },
        CoursewarePortExpireWarning: { title: '课件端口到期预警', value: 0, key: 7, type: 3, numberClassName: 'number_color_orange', isSelection: false }
      }
    };
  },
  props: {
    summaryData: Object,
    params: Object
  },
  watch: {
    summaryData (n, o) {
      if (n) {
        this.initSummaryData(n);
      }
    }
  },
  methods: {
    // 汇总数据
    initSummaryData (data) {
      console.log(data, '汇总数据');
      this.statisticsData.InUse.value = Number(data.ValidCampusCount) || 0;// 使用中
      this.statisticsData.Renew.value = Number(data.RenewalAlertCampusCount) || 0;// 续费预警
      // this.statisticsData.Disable.value = Number(data.DeactivatedCampusCount) || 0;// 已停用

      this.portStatisticsData.AcademicPortExpire.value = Number(data.AcademicBeOverdueCount) || 0;// 教务端口到期
      this.portStatisticsData.AcademicPortExpireWarning.value = Number(data.AcademicSoonBeOverdueCount) || 0;// 教务端口到期预警
      this.portStatisticsData.CoursewarePortExpire.value = Number(data.ChannelBeOverdueCount) || 0;// 课件端口到期
      this.portStatisticsData.CoursewarePortExpireWarning.value = Number(data.ChannelSoonBeOverdueCount) || 0;// 课件端口到期预警
    },
    initializeOptData (list, item) {
      Object.keys(list).forEach(key => {
        let obj = list[key];
        obj.isSelection = false;
      });
      this.params.searchText = '';
      this.params.pageIndex = 0;
      this.params.StartTime = '';//	起始日期
      this.params.EndTime = ''; //	终止日期
      this.params.TypeKey = '';//	加盟类型3-课件加盟;4-整店加盟
      this.params.IsExpire = ''; //	空表示条件不生效;1-过期,0-正常
      this.params.IsRenew = ''; // 空表示条件不生效;1-查找要续费的课件
      this.params.IsWarning = ''; // 空表示条件不生效;1-课件排课警戒
      this.params.IsAbort = ''; // 终止 1-0
    },
    // 1-使用中;2-校区续费预警;3-校区已停用;
    doAfterCilckLeftSummaryData (item) {
      console.log(item, 'doAfterCilckSummaryData');
      this.initializeOptData(this.portStatisticsData, item);
      this.params.HeadTypeKey = item.key;
      this.$emit('loadTableData', this.params);
    },
    // 4-教务端口到期;5-教务端口到期预警;6-课件端口到期;7-课件端口到期预警
    doAfterCilckRightSummaryData (item) {
      console.log(item, 'doAfterCilckSummaryData');
      this.initializeOptData(this.statisticsData, item);
      this.params.HeadTypeKey = item.key;
      this.$emit('loadTableData', this.params);
    }
  }
};
</script>

<style>
.franchisee_head_summarizing {
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 0px;
}
.franchisee_head_summarizing .top_left_ul {
  min-width: 332px;
}
.franchisee_head_summarizing .top_right_ul {
  min-width: 681px;
}
.franchisee_head_summarizing .top_right_ul .all_workbench_left_list {
  display: inherit;
  flex: auto;
}
.franchisee_head_summarizing
  .top_right_ul
  .all_workbench_left_list
  .list_title {
  margin: 0 10px 0 18px;
  line-height: 48px;
}

.franchisee_head_summarizing
  .top_right_ul
  .all_workbench_left_list
  .list_number {
  font-size: 24px;
  line-height: 48px;
  text-align: center;
  font-weight: 400;
  margin-right: 5px;
}
.number_color_orange {
  color: #ff8723;
}
.number_color_red {
  color: #ff6c67;
}
</style>