<template>
  <!-- 推荐品牌 -->
  <div class="all_workbench_brand_lable"
       :class="contentSet.iconType">
    <div class="form_text_title">
      <span class="font_text">{{contentSet.name}}</span>
    </div>
    <div class="form_textarea">
      <textarea :placeholder="contentSet.isReadonly?'':'添加'+contentSet.name+'内容'"
                :disabled="contentSet.isReadonly"
                v-model="dataInfo.Content"
                @change="change"></textarea>
    </div>
    <!-- <div class="form_text_btn btn_hover_bg_blue" 
         v-if="contentSet.addBtn"
         @click="confirm">{{contentSet.addBtn}}
    </div> -->
  </div>
</template>

<script>

export default {
  props: {
    dataInfo: Object,
    contentSet: Object
  },
  data () {
    return {
      content: ''
    };
  },
  computed: {

  },
  created () {

  },
  methods: {
    change () {
      this.$emit('change', this.dataInfo.Content);
    }
  }
};
</script>

<style>
.all_workbench_brand_lable {
  padding-bottom: 20px;
}
.all_workbench_brand_lable .lable_title {
  font-size: 16px;
  line-height: 20px;
  padding-left: 30px;
  margin-bottom: 10px;
}
.all_workbench_brand_lable .form_text_title {
  font-size: 14px;
  line-height: 40px;
  color: #666666;
}
.all_workbench_brand_lable .form_textarea {
  width: 330px;
  height: 120px;
  border: 1px solid #ececec;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
}
.all_workbench_brand_lable .form_textarea textarea {
  width: 100%;
  height: 100%;
  color: #666666;
}
.all_workbench_brand_lable .font_blue {
  color: #3498db;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
</style>