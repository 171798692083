<template>
  <div class="">
    <div class="shade"></div>
    <div class="loading_page">
      <div class="loading_page_tou">
        <div class="loading_page_tou_img">
          <heads-img :imgUrl="headImg"
                     :dataInfo="studentItem"></heads-img>
        </div>
        <div class="loading_page_name">{{studentItem.StudentKeyValue}}</div>
      </div>
      <div class="loading_page_tou_gb button"
           @click.stop="closePopup"></div>
      <div class="loading_page_bottom">
        <div class="loading_page_butt button"
             @click.stop="clickAtten(3)">
          签到<img v-if="vAttendanceStatusKey==3"
               src="../../../../../public/image/kq.png"
               class="loading_page_butt_img" />
        </div>
        <div class="loading_page_butt button"
             @click.stop="clickAtten(4)">请假
          <img v-if="vAttendanceStatusKey==4"
               src="../../../../../public/image/kq.png"
               class="loading_page_butt_img" />
        </div>
        <div class="loading_page_butt button"
             @click.stop="clickAtten(5)"
             v-if="vShowTruant">旷课
          <img v-if="vAttendanceStatusKey==5"
               src="../../../../../public/image/kq.png"
               class="loading_page_butt_img" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    studentItem: Object
  },
  computed: {
    // 是否显示旷课
    vShowTruant () {
      return this.$utils.getSaaSClientSet(59) == 1;
    },
    vAttendanceStatusKey () {
      return this.studentItem.AttenceStatusKey;
    },
    // 头像
    headImg () {
      if (this.studentItem && this.studentItem.HeadImgSupport && this.studentItem.HeadImgSupport !== 'null') {
        if (this.studentItem.HeadImgSupport.indexOf('http') > -1) {
          return this.studentItem.HeadImgSupport;
        } else {
          let PCAPIURL = this.$store.getters.CDNURL;
          return (
            PCAPIURL + this.studentItem.HeadImgSupport
          );
        }
      } else {
        return '';
      }
    }
  },
  methods: {
    closePopup () {
      this.$emit('closePopup');
    },
    clickAtten (type) {
      if (this.vAttendanceStatusKey == type) {
        // 撤销考勤
        this.$emit('clickAtten', 2);
      } else {
        this.$emit('clickAtten', type);
      }
    }
  }
};
</script>
