<template>
  <div class="customer_registration_studentInfo">
    <div class="registration_studentInfo_content clearfix">
      <div class="studentInfo_title">同手机号的学生</div>
      <div class="studentInfo_single"
           :class="{active:item.isActive}"
           v-for="(item,index) in sourceData"
           :key="index"
           @click="clickItem(item)">
        <span class="registration_student_name">{{item.MainDemoName}}</span>
        <!-- <span class="registration_student_sex fr">{{item.SexKeyValue}}</span> -->
      </div>
      <!-- <div class="studentInfo_add"
           @click="addStudentBtn"
           :class="{active:isAdd}">
        <span class="studentInfo_plus">新增</span>
      </div> -->
    </div>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: 'inputSelect',
  data () {
    return {
      isAdd: true
    };
  },
  props: {
    sourceData: Array   // 为数组
  },
  computed: {
  },
  created () {
  },
  watch: {

  },
  methods: {
    addStudentBtn () {
      this.isAdd = true;
      this.sourceData.forEach(obj => {
        obj.isActive = false;
      });
      this.$emit('selectedItem', {});
    },
    // 点击一个学生//取消切换操作
    clickItem (item) {
      // this.isAdd = false;
      // this.sourceData.forEach(obj => {
      //   obj.isActive = false;
      // });
      // item.isActive = true;
      // this.$emit('selectedItem', item);
    }

  }
};
</script>


