<template>
  <div>
    <div class="form_info_edit form_info_list">
      <input-dialog :formTitle="'关联方案'" :readonly="false" :required="true" :value="plan.MainDemoName" @showEldilog="showPlan"></input-dialog>
    </div>
    <save-cancel-btn-group :isSingleSubmit="true" @confirmClick="submit" @cancelClick="cancel"></save-cancel-btn-group>
  </div>
</template>

<script>
import {GetBelowCustomerChannelActivitylist,ContactCustomerChannelActivity} from '../../../API/workbench';
export default {
  data () {
    return {
      plan:{
        OLAPKey:'',
        MainDemoName:''
      },
      list:[]
    };
  },
  components: {
  },
  props: {
    rowkey: String
  },
  created () {
    GetBelowCustomerChannelActivitylist(1).then(result => {
      if (result.code == 0) {
        this.list = result.data;
      } else {
        console.log(result.msg);
      }
    }).catch(error => {
      console.log(error);
    });
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    showPlan () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '关联方案',
        moduleName: 'singleSelectionList',
        data: { selectedKey: this.plan.OLAPKey, dataSourceList: this.list, required: true },
        callBack: {
          onSelectedItem: (data) => { this.planChange(data); }
        }
      });
    },
    planChange (data) {
      this.plan.OLAPKey = data.OLAPKey;
      this.plan.MainDemoName = data.MainDemoName;
    },
    submit () {
      let that = this;
      if (!that.plan.OLAPKey) {
        layer.alert('请选择关联方案');
        return;
      }
      ContactCustomerChannelActivity(that.rowkey,that.plan.OLAPKey).then(result => {
        if (result.code == 0) {
          that.$emit('afterSuccess');
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    cancel () {
      this.$emit('cancelClick');
    }
  }
};
</script>

<style scoped>
</style>