<template>
  <!-- 试课记录 -->
  <div class="documentary_records_list">
    <div class="trial_class_conten">
      <!-- 销售  -->
      <div class="table_select_box ascription"
           style="margin-right: 15px;">
        <el-select :value="vStaffValue"
                   filterable
                   placeholder="必选"
                   value-key="OLAPKey"
                   style="width: 120px;margin-left: 0px;"
                   @change="doSelectedStaff">
          <el-option v-for="item in staffList"
                     :key="item.OLAPKey "
                     :label="item.MainDemoName"
                     :value="item"></el-option>
        </el-select>
      </div>
      <date-range ref="monthPicker"
                  class="records_data"
                  :fromTitle="'试课日期'"
                  :searchObj="searchObj"
                  @changeDate="changeSaveDate"></date-range>

      <div class="trialClass_handle_div">
        <div class="trialClass_both mover_class"
             :style="{border: isNuoFangNum == 1 &&NuoFangIndex % 2 ==0?'1px solid rgba(52, 152, 219, 1)':'1px solid orange',
               color: isNuoFangNum == 1 && NuoFangIndex % 2 ==0?'rgba(52, 152, 219, 1)':'orange',background: isNuoFangNum == 1 && NuoFangIndex % 2 ==0?'rgba(52, 152, 219, 0.2)':'#fff'}"
             @click.stop="handleBtn(1)">
          近3天试课： <div class="trialClass_num">{{ AppointTrialLessonCount }}</div>
        </div>
        <div class="trialClass_both mover_class"
             style="margin-left: 15px;"
             :style="{border: isNuoFangNum == 2 &&NuoFangIndex % 2 ==0?'1px solid rgba(52, 152, 219, 1)':'1px solid red',
               color: isNuoFangNum == 2 && NuoFangIndex % 2 ==0?'rgba(52, 152, 219, 1)':'red',background: isNuoFangNum == 2 && NuoFangIndex % 2 ==0?'rgba(52, 152, 219, 0.2)':'#fff'}"
             @click.stop="handleBtn(2)">
          过期待处理： <div class="trialClass_num">{{ AppointTrialLessonExpireCount }}</div>
        </div>
      </div>

      <div class="table_select_box"
           style="margin-right: 20px;">
        <div class="table_select_title">试课状态</div>
        <el-select v-model="selectedtrialClassState"
                   filterable
                   style="width: 120px;"
                   placeholder="必选"
                   @change="chgSearchtrialClassStateValue"
                   value-key="OLAPKey">
          <el-option v-for="item in trialClassStateList"
                     :key="item.OLAPKey"
                     :label="item.MainDemoName"
                     :value="item"></el-option>
        </el-select>
      </div>
      <!-- 已选条件 -->
      <input-search-contain-btn v-model="queryParams.searchText"
                                :placeholder="'搜索学生'"
                                @clearSearch="clearExtendSearchParams"
                                @onSearchEvent="search"></input-search-contain-btn>
    </div>
    <div class="table_list_content introduce_list campusManagement_list">
      <table-list ref="tableListRef"
                  class="summarizing_list schedule_table_class"
                  :tableData="trialClassList"
                  :queryParams="queryParams"
                  :tableColumns="tableColumns"
                  :rowKey="'OLAPKey'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  @loadTableData="getManageMessageSearchPageTrialLessonList"></table-list>
    </div>
    <custom-dialog :title="'改约'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowFollowUp"
                   :before-close="doShowFollowUpHide">
      <follow-up-form :dataInfo="dataInfo"
                      :scheduleTitle="'改约'"
                      :followTitle="'试课记录'"
                      @afterSuccess="closeData">
      </follow-up-form>
    </custom-dialog>
    <custom-dialog :title="'分配员工'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowSalesList"
                   :before-close="closeForm">
      <get-sale-list :selectedKey="selectedKey"
                     :OLAPKey="OLAPKey"
                     @closeForm="closeForm"
                     @onSelectedItem="onSelectedItem">
      </get-sale-list>
    </custom-dialog>
    <!-- 图1 -->
    <custom-dialog :title="'关联学生档案'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowAssociationStudentOne"
                   :before-close="doShowApplicationHide">
      <clew-add-form :threadInfo="threadInfo"
                     :isPopupShow="true"
                     @afterSuccess="afterSuccess">
      </clew-add-form>
    </custom-dialog>
    <!-- 图2 和 图3-->
    <custom-dialog :title="'关联学生档案'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowAssociationStudentTwoThree"
                   :before-close="doShowApplicationHide">
      <association-student-form :threadInfo="threadInfo"
                                :isAssociationTwoOrThree="isAssociationTwoOrThree"
                                :isPopupShow="true"
                                @afterSuccess="afterSuccess"
                                @cancelClick="doShowApplicationHide">
      </association-student-form>
    </custom-dialog>
  </div>
</template>
<script>
import { ManageMessageSearchPageTrialLessonList, MessageSearchPageForEmployee, UpdateTrialLessonStatus, GetBindingStatus, GetStudentCommonMsgTwo } from '../../../API/workbench.js';
import inputSearchContainBtn from '../../report/common/input-search-contain-btn';
import tableList from '../../common/table-list/index';
import followUpForm from '../follow-up-form.vue';
import getSaleList from '../form/getSale-list.vue';// 销售
import clewAddForm from '../../student/clew-add-form';
import associationStudentForm from '../../recruit-students-management/form/association-student-form';
export default {
  data () {
    return {
      isNuoFangNum: 0,
      NuoFangIndex: 1,
      threadInfo: {},
      isShowAssociationStudentOne: false, // 关联学生档案 1
      isShowAssociationStudentTwoThree: false, // 关联学生档案 2 3
      isAssociationTwoOrThree: false, // false 图2   ， true 图3
      isShowSalesList: false, // 销售
      selectedKey: '', // 销售ID
      OLAPKey: '', // 线索ID
      isShowFollowUp: false,
      dataInfo: {},
      selectedtrialClassState: '全部', // 最近跟单
      staffList: [], // 销售
      DocumentaryKeyValue: '',
      trialClassStateList: [
        {
          OLAPKey: 0,
          MainDemoName: '全部'
        },
        {
          OLAPKey: 1,
          MainDemoName: '未安排'
        },
        {
          OLAPKey: 2,
          MainDemoName: '已排课'
        },
        {
          OLAPKey: 3,
          MainDemoName: '未到'
        },
        {
          OLAPKey: 4,
          MainDemoName: '改约'
        },
        {
          OLAPKey: 5,
          MainDemoName: '已到'
        }
      ],
      queryParams: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        StartTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        EndTime: this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd'),
        StatusKey: '', // 试课状态:1-待处理;2-未到;3-改约;4-已到
        SalesKey: '', // 销售ID
        NearlyThreeDays: '', // 近三天试课
        IsExpire: '' // 是否过期
      },
      searchObj: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd')
      },
      AppointTrialLessonCount: 0, // 近三天试课数量
      AppointTrialLessonExpireCount: 0, // 过期待处理
      trialClassList: [],
      tableColumns: [
        {
          label: '序号',
          width: 60,
          prop: 'Number',
          align: 'center',
          type: 'tableIndex',
          sortable: false
        },
        {
          label: '录入日期',
          width: 95,
          prop: 'GenerateTime',
          type: 'text-item'
        },
        {
          label: '归属销售',
          prop: 'SalesKeyValue',
          width: 90,
          align: 'center',
          type: 'text-item'
          // extend: {
          //   isClick: true,
          //   click: (rowData) => {
          //     this.doFollowClick(rowData);
          //   }
          // }
        },
        {
          label: '录入人',
          prop: 'OperatePersonKeyValue',
          width: 80,
          align: 'center',
          type: 'text-item'
        },
        {
          label: '处理人',
          prop: 'TLDOperatePersonKeyValue',
          width: 80,
          align: 'center',
          type: 'text-item'
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 100,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '手机号',
          prop: 'MobilePhone',
          width: 110,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '试课日期',
          width: 100,
          sortable: 'custom',
          prop: 'AppointmentTime',
          type: 'text-item'
        },
        {
          label: '试课课程',
          prop: 'trialClassCourseValue',
          type: 'text-item'
        },
        {
          label: '操作',
          prop: 'option',
          width: 245,
          type: 'table-btn',
          extend: {
            tableBtnGroup: [
              {
                name: '安排试课',
                extend: {
                  isHide: (rowData) => {
                    return Number(rowData.StatusKey, 1) != 1;
                  },
                  click: (rowData) => {
                    this.arrangeCourseBtn(rowData);
                  }
                }
              },
              {
                name: '已试课',
                extend: {
                  isHide: (rowData) => {
                    return Number(rowData.StatusKey) != 1;
                  },
                  click: (rowData) => {
                    this.editBtn(rowData, 4);
                  }
                }
              },
              {
                name: '未到',
                extend: {
                  isHide: (rowData) => {
                    return Number(rowData.StatusKey) != 1;
                  },
                  click: (rowData) => {
                    this.editBtn(rowData, 2);
                  }
                }
              },
              {
                name: '改约',
                extend: {
                  isHide: (rowData) => {
                    return Number(rowData.StatusKey) != 1 && Number(rowData.StatusKey) != 2;
                  },
                  click: (rowData) => {
                    this.editBtn(rowData, 3);
                  }
                }
              }
            ]
          }
        }
      ],
      totalNum: 0
    };
  },
  components: {
    inputSearchContainBtn,
    tableList,
    followUpForm,
    getSaleList,
    clewAddForm,
    associationStudentForm
  },
  computed: {
    // 销售
    vStaffValue () {
      return this.DocumentaryKeyValue == '' ? '全部销售' : this.DocumentaryKeyValue;
    }
  },
  created () {
    this.loadData();
    this.getManageMessageSearchPageTrialLessonList();
  },
  watch: {
  },
  activated () {
  },
  mounted () {
  },
  methods: {
    handleBtn (type) {
      this.queryParams.StartTime = '';
      this.queryParams.EndTime = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      console.log(this.NuoFangIndex, 'this.NuoFangIndex', this.isNuoFangNum, type);
      if (this.isNuoFangNum != type) {
        this.NuoFangIndex = 1;
      }
      this.isNuoFangNum = type;

      this.queryParams.pageIndex = 0;
      if (this.NuoFangIndex % 2 == 0) {
        this.queryParams.NearlyThreeDays = '';
        this.queryParams.IsExpire = '';
      } else {
        this.queryParams.NearlyThreeDays = type == 1 ? 1 : '';// 近三天试课
        this.queryParams.IsExpire = type == 2 ? 1 : '';// 是否过期
      }
      this.NuoFangIndex++;
      console.log(this.NuoFangIndex, 'this.NuoFangIndex', this.isNuoFangNum);
      this.getManageMessageSearchPageTrialLessonList();
    },
    closeForm () {
      this.isShowSalesList = false;// 销售
    },
    // 打开员工列表
    doFollowClick (rowData) {
      this.selectedKey = rowData.SalesKey;
      this.OLAPKey = rowData.StudentThreadKey;
      this.isShowSalesList = true;
    },
    onSelectedItem (item) {
      console.log('item0,', item);
      this.isShowSalesList = false;// 销售
      this.getManageMessageSearchPageTrialLessonList();
    },
    // 根据标签筛选
    doSelectedStaff (obj) {
      console.log(obj, '标签');
      this.DocumentaryKeyValue = obj.MainDemoName;
      this.queryParams.SalesKey = obj.OLAPKey;
      this.queryParams.pageIndex = 0;
      this.getManageMessageSearchPageTrialLessonList();
    },
    loadData () {
      let obj = {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号、首字母
        orderBy: 'IsLeaveKey', //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为姓名
        sequence: 'DESC', //	字符串	可选	ASC	数据源：GET	为空则默认顺序; ASC正序;DESC倒序
        IsLeaveKey: '' //	字符串	可选		数据源：GET	是否在职;空表示该条件不生效;0-离职;1-在职
      };
      MessageSearchPageForEmployee(obj).then(
        (result) => {
          if (result.data.PageDataList && result.data.PageDataList.length > 0) {
            result.data.PageDataList.forEach((o) => {
              if (Number(o.IsAdviserKey) == 1) {
                this.staffList.push(o);
              }
            });
          } else {
            this.staffList = [];
          }
          this.staffList.splice(0, 0, {
            OLAPKey: '',
            MainDemoName: '全部销售'
          });
          this.staffList.splice(1, 0, {
            OLAPKey: -1,
            MainDemoName: '未分配'
          });
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    chgSearchtrialClassStateValue (item) {
      console.log(item, 'item');
      this.queryParams.pageIndex = 0;
      this.selectedtrialClassState = item.MainDemoName;
      this.queryParams.StatusKey = item.OLAPKey;
      this.getManageMessageSearchPageTrialLessonList();
    },
    // 试课记录 数据查询
    getManageMessageSearchPageTrialLessonList () {
      ManageMessageSearchPageTrialLessonList(this.queryParams).then(result => {
        this.AppointTrialLessonCount = Number(result.data.AppointTrialLessonCount);
        this.AppointTrialLessonExpireCount = Number(result.data.AppointTrialLessonExpireCount);
        this.totalNum = result.data.Total;
        this.trialClassList = result.data.PageDataList;
        this.trialClassList.forEach(o => {
          o.StudentKeyValue = o.StudentKeyValue ? o.StudentKeyValue : '-';
          o.MobilePhone = o.MobilePhone ? o.MobilePhone : '-';
          o.CurrentNodeKeyValue = o.CurrentNodeKeyValue ? o.CurrentNodeKeyValue : '-';
          o.CurrentLabelNames = o.CurrentLabelNames ? o.CurrentLabelNames : '-';
          o.GenerateTime = o.GenerateTime ? o.GenerateTime : '-';
          o.SalesKeyValue = !o.SalesKeyValue ? '未分配' : o.SalesKeyValue;
          o.TLDOperatePersonKeyValue = o.TLDOperatePersonKeyValue ? o.TLDOperatePersonKeyValue : '-';
          o.OperatePersonKeyValue = o.OperatePersonKeyValue ? o.OperatePersonKeyValue : '-';
          // 试课课程
          if ((o.StatusKey == 4) || o.AttendenceStatusKey == null || o.AttendenceStatusKey == 0) {
            // 未安排课
            o.trialClassCourseValue = '<div> <span class=' + (o.StatusKey == 1 ? 'grey_class' : o.StatusKey == 3 ? 'orange_class' : o.StatusKey == 4 ? 'blue_class' : 'cyan_class') + '>' +
              (o.StatusKey == 1 ? '未安排' : o.StatusKey == 3 ? '未到' : o.StatusKey == 4 ? '改约' : '已试课') + '</span> &nbsp;&nbsp;' + (o.OperateTime ? o.OperateTime.slice(0, 10) : o.OperateTime2.slice(0, 10)) +
              '&nbsp;&nbsp;' + (o.StatusKey == 1 ? o.OperatePersonKeyValue : o.TLDOperatePersonKeyValue) + '&nbsp;&nbsp;' + (o.StatusKey == 1 ? '登记约试课' : o.StatusKey == 3 ? '操作未到' : o.StatusKey == 4 ? '操作改约' : '操作已试课') + '</div>';
          } else {
            // 已安排课
            o.trialClassCourseValue = '<div> <span class=' + (o.AttendenceStatusKey == 1 ? 'blue_class' : o.AttendenceStatusKey == 2 ? 'cyan_class' : o.AttendenceStatusKey == 3 ? 'orange_class' : 'yellow_class') + '>' +
              (o.AttendenceStatusKey == 1 ? '已排课' : o.AttendenceStatusKey == 2 ? '出勤' : o.AttendenceStatusKey == 3 ? '请假' : '旷课') + '</span> &nbsp;&nbsp;' +
              (o.AttendClassTime ? o.AttendClassTime.slice(0, 10) : '-') + '&nbsp;&nbsp;' + o.StartAndFinishTime + '&nbsp;&nbsp;<span style="color: #dd761f;">|课|</span>' + o.ClassTypeKeyValue + '&nbsp;&nbsp;<span style="color: #dd761f;">|班|</span>' + o.GradeKeyValue +
              '&nbsp;&nbsp;<span style="color: #dd761f;">|师|</span>' + o.TeacherKeyValue + '</div>';
          }
        });
        console.log(result, '试课记录 数据查询');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 安排试课
    arrangeCourseBtn (rowData) {
      this.threadInfo = rowData;
      // 关联
      if (Number(rowData.StudentKey) == 0) {
        // 未关联学生
        GetBindingStatus(rowData.StudentThreadKey).then(result => {
          console.log(result.data, 'GetBindingStatus 未关联学生');
          if (result.data.StatusKey == 1) {
            // 打开关联学生档案 1图
            this.isShowAssociationStudentOne = true;
          } else if (result.data.StatusKey == 2) {
            // 打开关联学生档案 2图
            this.isShowAssociationStudentTwoThree = true;
            this.isAssociationTwoOrThree = false;// 图2
          } else if (result.data.StatusKey == 3) {
            // 打开关联学生档案 2图
            this.isShowAssociationStudentTwoThree = true;
            this.isAssociationTwoOrThree = true;// 图3
          }
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        // 已关联
        this.$dialog.open(this, {
          routerName: this.$route.name,
          moduleName: 'arrangeCourse',
          data: { dataInfo: this.threadInfo },
          callBack: {
            afterSuccess: () => {
              this.getManageMessageSearchPageTrialLessonList();
            }
          }
        });
      }
    },
    // 关闭报名收款   关联学生档案 1图
    doShowApplicationHide () {
      this.isShowAssociationStudentOne = false;
      this.isShowAssociationStudentTwoThree = false;
    },
    closeData () {
      this.isShowAssociationStudentOne = false;
      this.isShowAssociationStudentTwoThree = false;
      this.isShowFollowUp = false;
      this.getManageMessageSearchPageTrialLessonList();
    },
    afterSuccess (OLAPKey) {
      this.isShowAssociationStudentOne = false;
      this.isShowAssociationStudentTwoThree = false;
      this.isShowFollowUp = false;
      GetStudentCommonMsgTwo(this.threadInfo.StudentKeyValue).then(result => {
        this.threadInfo.StudentKey = OLAPKey || result.data.OLAPKey;
        this.$dialog.open(this, {
          routerName: this.$route.name,
          moduleName: 'arrangeCourse',
          data: { dataInfo: this.threadInfo },
          callBack: {
            afterSuccess: () => {
              this.getManageMessageSearchPageTrialLessonList();
            }
          }
        });
        console.log('GetStudentCommonMsgTwo', result.data);
      }).catch(error => {
        console.log(error, 'GetStudentCommonMsgTwo');
      });

      this.getManageMessageSearchPageTrialLessonList();
    },
    doShowFollowUpHide () {
      this.isShowFollowUp = false;
    },
    editBtn (rowData, type) {
      this.dataInfo = rowData;
      let title = '';
      let obj = {};
      if (type == 3) {
        // 改约
        this.isShowFollowUp = true;
      } else {
        title = type == 2 ? '未到' : '已试课';
        obj = {
          StudentThreadKey: rowData.StudentThreadKey, // 线索ID
          TrialLessonKey: rowData.TrialLessonKey, // 试课记录ID
          TrialLessonDisposeKey: rowData.TrialLessonDisposeKey, // 试课记录处理ID
          TrialLessonTime: '', // 改约日期
          StatusKey: type, // 2-未到;3-改约;4-已试课
          StatusKeyValue: title // 状态
        };
        console.log(obj, 'obj66');
        layer.confirm('确定处理' + title + '数据吗？', {
          btn: [
            {
              name: '确认',
              callBack: () => {
                UpdateTrialLessonStatus(obj).then(result => {
                  layer.close();
                  layer.alert('处理成功');
                  this.getManageMessageSearchPageTrialLessonList();
                }, error => {
                  layer.alert(error.msg);
                });
              }
            }
          ]
        });
      }
    },
    changeSaveDate (dataInfo) {
      console.log(dataInfo, 'dataInfo');
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.queryParams.StartTime = dataInfo.startTime;
      this.queryParams.EndTime = dataInfo.endTime;
      this.getManageMessageSearchPageTrialLessonList();
    },
    search () {
      this.queryParams.pageIndex = 0;
      this.getManageMessageSearchPageTrialLessonList();
    },
    clearExtendSearchParams () {
      this.NuoFangIndex = 1;
      this.selectedtrialClassState = '全部';
      this.DocumentaryKeyValue = '全部销售';
      this.queryParams = {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        StartTime: '',
        EndTime: '',
        StatusKey: '',
        SalesKey: '', // 销售ID
        NearlyThreeDays: '', // 近三天试课
        IsExpire: '' // 是否过期
      };
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.getManageMessageSearchPageTrialLessonList();
    }
  }
};
</script>

<style scoped>
.trialClass_handle_div {
  display: flex;
  margin-right: 20px;
}
.trial_class_conten {
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;
  line-height: 70px;
  align-items: center;
  margin-top: -20px;
}
.trialClass_both {
  display: flex;
  line-height: 34px;
  padding: 0 5px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}
.records_data >>> .date_title {
  min-width: 75px;
}

/* 未安排 */
.schedule_table_class >>> .el-table .grey_class {
  width: 52px;
  display: inline-block;
  color: #000;
  background: #ccc;
  border-radius: 4px;
  text-align: center;
}
/* 未到 请假*/
.schedule_table_class >>> .el-table .orange_class {
  width: 52px;
  display: inline-block;
  color: #a36717;
  background: #facd91;
  border-radius: 4px;
  text-align: center;
}
/* 改约 已排课 */
.schedule_table_class >>> .el-table .blue_class {
  width: 52px;
  display: inline-block;
  color: #093f7d;
  background: #81d3f8;
  border-radius: 4px;
  text-align: center;
}
/* 已试课 出勤*/
.schedule_table_class >>> .el-table .cyan_class {
  width: 52px;
  display: inline-block;
  color: #63a103;
  background: #caf982;
  border-radius: 4px;
  text-align: center;
}
/* 旷课 */
.schedule_table_class >>> .el-table .yellow_class {
  width: 52px;
  display: inline-block;
  color: #a36717;
  background: #facd91;
  border-radius: 4px;
  text-align: center;
}
.mover_class:hover {
  background: rgba(52, 152, 219, 0.2) !important;
  color: rgba(52, 152, 219, 1) !important;
}
</style>

