<template>
  <!-- 课程内容 -->
  <div class="package_list label_radio_bg_white"
       :class="{delete_type:item.isOpt}"
       @click.stop="showMenu(item,index)">
    <div class="package_list_name">{{item.ApplyCorName}}</div>
    <span class="package_type">{{vDrainageKeyValue}}</span>
    <!-- 课时卡 -->
    <div class="package_list_text"
         v-if="Number(item.UnitKey)==5">
      {{$utils.mMoneyType(item.UnitPrice,2)}}元 X{{$utils.mAmountType(Number(item.NumCount),1)}}{{item.UnitKeyValue}}
    </div>
    <!-- 学期卡 -->
    <div class="package_list_text"
         v-else>
      {{item.UnitKeyValue}}卡丨有效期：{{$utils.mAmountType(Number(item.NumCount),Number(item.UnitKey)==5?1:0)}}{{vUnitKeyValue}}
    </div>
    <div class="package_list_amount">
      <div>
        <span v-if="Number(item.AfterDisAmount)==0">赠送</span>
        <span v-else>￥{{$utils.mMoneyType(item.AfterDisAmount,2)}}</span>
      </div>
      <div class="font_gray"
           v-if="Number(item.DiscountCount)>0&&Number(item.DiscountCount)<10">
        <span class="text_center_line">￥{{$utils.mMoneyType(item.ValueAmount,2)}}</span>
        <span>{{vDiscount}}</span>
      </div>
    </div>
    <!-- <div class="delete_btn"
         v-if="isShowDeleteBtn"
         @click.stop="doDeleteBtnClick">
      删除
    </div> -->

    <!--操作菜单 -->
    <!-- <div class="module_content_menuList"
         style="width: 120px; left: 260px; top: 44px;"
         v-if="item.isOpt">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="menuList"
                     @clickOptionForAlertDialog="onItemClick"></drop-down-box>
    </div> -->
  </div>

</template> 

<script>
// import dropDownBox from '../../../../../common/drop-down-box';
// import { GetTheClassType } from '../../../../../../API/workbench';
export default {
  data () {
    return {
      isShowMenu: false, // 显示操作菜单
      menuList: [
        { key: 1, name: '修改', eventName: 'edit', isShow: true },
        { key: -1, name: '删除', eventName: 'delete', isShow: true }
      ]
    };
  },
  props: {
    item: Object, // 课程信息 （必填）
    index: Number, // 下标
    taocanType: String
  },
  components: {
    // dropDownBox
  },
  created () {
    console.log(this.item, '课程信息-123');
  },
  mounted () {

  },
  watch: {

  },
  computed: {
    vDrainageKeyValue () {
      if (this.item.IsDrainageKey == '1' || this.item.ApplyTypeKey == '4') {
        return '引流';
      } else if (Number(this.item.AfterDisAmount) == 0) {
        return '赠送';
      } else {
        return '正课';
      }
    },
    vUnitKeyValue () {
      if (this.item.UnitKey == 3) {
        return '个月';
      } else {
        return this.item.UnitKeyValue;
      }
    },
    vDiscount () {
      let str = '';
      if (Number(this.item.AfterDisAmount) == 0) {
        str = '';
      } else if (Number(this.item.AfterDisAmount) < Number(this.item.ValueAmount)) {
        str = this.$utils.mAmountType(this.item.DiscountCount, 1) + ' 折';
      } else {
        str = '';
      }
      return str;
    }
  },
  methods: {
    // 显示菜单
    showMenu () {
      if (this.taocanType == 'view') {
        return false;
      }
      this.$emit('showMenu', this.index, this.item);
    }
    // 操作项
    // onItemClick (item, index) {
    //   console.log(item, index, '编辑');
    //   if (item.eventName == 'edit') {
    //     this.doEditClick();
    //   } else {
    //     this.doDeleteBtnClick();
    //   }
    // },
    // 编辑
    // doEditClick () {
    //   console.log('编辑');
    //   if (this.item.ApplyCorIDName.indexOf(',') != -1) {
    //     this.$emit('edit', 'multi', this.index, this.item);
    //   } else {
    //     GetTheClassType(this.item.ApplyCorIDName).then(res => {
    //       // || res.data.FatherKey == 0
    //       if (Number(res.data.TypeKey) == 1) { // 1为课类，2为课名
    //         this.$emit('edit', 'multi', this.index, this.item);
    //       } else {
    //         this.$emit('edit', 'single', this.index, this.item);
    //       }
    //     }, err => {
    //       layer.alert(err.msg);
    //     });
    //   }
    // },
    // 删除
    // doDeleteBtnClick () {
    //   this.$emit('delete', this.index, this.item);
    // }
  }
};
</script>

  <style scoped>
.package_type {
  position: absolute;
  top: 3px;
  right: 17px;
  font-size: 12px;
  color: #999;
}
/*  */
</style>