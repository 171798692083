<template>
  <div class="course_statistics_table_search flex">
    <div class="query_box_content"
         v-show="!isBatchDistribute">
      <div class="query_box_first_line">
        <div class="search_item">
          <el-select-option :selectTitle="'来源方式'"
                            :dataSourceList="sourceTypeList"
                            :selectionKey="paramsForQuery.SourceTypeKey"
                            @changeEvent="chooseSourceType"></el-select-option>
        </div>
        <div class="search_item">
          <el-select-option :selectTitle="'付款进度'"
                            :dataSourceList="PaymentScheduleList"
                            :selectionKey="paramsForQuery.PaymentScheduleKey"
                            @changeEvent="choosePaymentSchedule"></el-select-option>
        </div>
        <div class="search_item">
          <el-select-option :selectTitle="'招商顾问'"
                            :dataSourceList="counselorList"
                            :selectionKey="paramsForQuery.CounselorKey"
                            @changeEvent="chooseCounselor"></el-select-option>
        </div>
        <div class="search_item">
          <el-select-option :selectTitle="'加盟方式'"
                            :dataSourceList="franchiseTypeList"
                            :selectionKey="paramsForQuery.FranchiseTypeKey"
                            @changeEvent="chooseFranchiseType"></el-select-option>
        </div>
      </div>
      <div class="query_box_second_line">
        <div class="search_item">
          <input-search-contain-btn v-model="paramsForQuery.searchText"
                                    :placeholder="'请输入'"
                                    @onSearchEvent="searchClick"
                                    @clearSearch="clearSearchParams"></input-search-contain-btn>
        </div>
        <div class="option_btn"
             @click="exportClick">
          <div class="import_investor btn_hover_bg_blue">导出</div>
        </div>
      </div>
    </div>
    <div v-if="isBatchDistribute"
         class="batch_option_box flex">
      <div class="pitch_count pr">已选中 {{batchAllotList.length}} 项
        <div class="remove_db_btn"
             @click="cancleBatchDistribute"></div>
      </div>
      <div class="btn_hover_bg_blue batch_btn"
           @click="doBatchAllot">批量分配</div>
    </div>
  </div>
</template>

<script>
import elSelectOption from '../../../../report/common/el-select-option'; // 下拉选择框
import inputSearchContainBtn from '../../../../report/common/input-search-contain-btn';
import {
  GetCounselorList,
  hqAttractInvestmentSourceSet,
  SearchPageHasSignTheBillInvestment
} from '../../../../../API/workbench';
export default {
  props: {
    paramsForQuery: {
      // 查询参数
      type: Object
    },
    batchAllotList: {
      // 批量选中的投资人数
      type: Array
    },
    tableData: Array
  },
  data () {
    return {
      counselorList: [], // 投资顾问列表
      sourceTypeList: [], // 来源列表
      PaymentScheduleList: [
        // 1-已签单;2-付定中;3-已付定;4-尾款中;5-全款中;6-已全款
        { OLAPKey: 0, MainDemoName: '全部' },
        { OLAPKey: 1, MainDemoName: '已签单' },
        { OLAPKey: 2, MainDemoName: '付定中' },
        { OLAPKey: 3, MainDemoName: '已付定' },
        { OLAPKey: 4, MainDemoName: '尾款中' },
        { OLAPKey: 5, MainDemoName: '全款中' },
        { OLAPKey: 6, MainDemoName: '已全款' }
      ],
      franchiseTypeList: [
        // 加盟方式1-单店;2-课程
        { OLAPKey: 0, MainDemoName: '全部' },
        { OLAPKey: 1, MainDemoName: '单店' },
        { OLAPKey: 2, MainDemoName: '课程' }
      ],
      isBatchDistribute: false // 是否显示查询条件
    };
  },
  components: {
    elSelectOption,
    inputSearchContainBtn
  },
  computed: {},
  created () {
    this.loadAdvisorList();
    this.loadSourceType();
  },
  methods: {
    // 获取投资顾问列表
    loadAdvisorList () {
      GetCounselorList().then((result) => {
        this.counselorList = result.data;
        this.counselorList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
      });
    },
    // 查询所有招商来源设置-总部
    loadSourceType () {
      hqAttractInvestmentSourceSet().then(
        (result) => {
          this.sourceTypeList = result.data || [];
          this.sourceTypeList.unshift({
            MainDemoName: '全部',
            OLAPKey: 0
          });
          console.log(this.sourceTypeList, '查询所有招商来源设置');
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    // 选择投资顾问
    chooseCounselor (item) {
      this.paramsForQuery.CounselorKey = item.OLAPKey;
      this.searchClick();
    },
    // 选择付款进度
    choosePaymentSchedule (item) {
      this.paramsForQuery.PaymentScheduleKey = item.OLAPKey;
      this.searchClick();
    },
    // 选择来源方式
    chooseSourceType (item) {
      this.paramsForQuery.SourceTypeKey = item.OLAPKey;
      this.searchClick();
    },
    // 选择加盟方式
    chooseFranchiseType (item) {
      this.paramsForQuery.FranchiseTypeKey = item.OLAPKey;
      this.searchClick();
    },
    // 点击搜索
    searchClick () {
      this.paramsForQuery.pageIndex = 0;
      this.$emit('searchClick', this.paramsForQuery);
    },
    // 清除搜索条件
    clearSearchParams () {
      this.$emit('clearSearchParams');
    },
    // 导出
    exportClick () {
      if (this.tableData.length > 0) {
        let obj = this.$utils.parseJson(this.paramsForQuery);
        obj.IsExportKey = 1;
        obj.pageIndex = 0;
        obj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '招商管理已签单记录表',
          clist: [
            {
              title: '签单日期',
              cName: 'ApplicantTime',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '来源',
              cName: 'SourceTypeKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '投资人',
              cName: 'MainDemoName',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '顾问',
              cName: 'CounselorKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '合同金额',
              cName: 'ContractAmount',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '加盟方式',
              cName: 'FranchiseTypeKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '合同变更条款',
              cName: 'AmendingClause',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '订金',
              cName: 'EarnestAmount',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            },
            {
              title: '付款进度',
              cName: 'PaymentScheduleKeyValue',
              backgroundColor: '',
              fontColor: '',
              fomate: '',
              numberzero: ''
            }
          ]
        };
        SearchPageHasSignTheBillInvestment(obj, jsonFormate).then(
          (result) => {
            window.location.href = this.$store.getters.APIURL + result.data.url;
          },
          (error) => {
            layer.alert(error.msg);
          }
        );
      } else {
        layer.alert('暂无数据导出');
      }
    }
  }
};
</script>

<style scoped>
.course_statistics_table_search {
  padding: 13px 0px;
}

.query_box_content {
  width: 100%;
}

.query_box_first_line {
  display: flex;
}
.query_box_second_line {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}
.course_statistics_table_search .search_item {
  margin-right: 20px;
}
.option_btn {
  display: flex;
}
.import_investor {
  height: 35px;
  width: 56px;
  line-height: 35px;
  text-align: center;
  border-radius: 4px;
}
.batch_option_box {
  height: 86px;
  display: flex;
  align-items: center;
}
.remove_db_btn {
  background-image: url(../../../../../../public/image/dialog_del_icon.png);
  position: absolute;
  right: -40px;
  top: 14%;
  margin-top: -8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 8px;
}
.batch_btn {
  position: absolute;
  left: 180px;
  top: 170px;
  height: 40px;
  width: 100px;
  line-height: 38px;
  text-align: center;
  border-radius: 4px;
  padding-left: 27px;
  padding-right: 7px;
  background: #3498db url(../../../../../../public/image/recycled_icon.png)
    no-repeat 8px;
}
</style>