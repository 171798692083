<template>
  <div class="teaching_mode">
    <div class="teaching_mode_cousewarepackge">
      <div class="teaching_mode_header">
        <div style="width: 104.32rem;">
        </div>
        <div class="teaching_mode_header_middle">
          <search-btn @search="searchData"
                      @clearSearch="clearSearch"
                      v-model="searchText"></search-btn>
        </div>
        <div class="teaching_mode_header_right">
          <div class="teaching_mode_header_right_btn"
               @click.stop="goBack">
            首页
          </div>

        </div>
      </div>
      <div v-show="!loading">

        <div v-if="showCWLevel">

          <courseware-list ref="coursewareList"
                           :dataInfo="dataInfo"
                           :dataList="dataList"
                           :seletedOLAPKey="seletedOLAPKey"
                           @seletedItem="seletedCWItem"
                           @toPage="toPage">

          </courseware-list>
        </div>
        <div v-else>
          <child-courseware-list :dataList="coursewareList"
                                 :dataInfo="dataInfo"
                                 :seletedOLAPKey="seletedOLAPKey">

          </child-courseware-list>
        </div>
      </div>
      <div class="teaching_mode_date">{{timer}}</div>

    </div>
    <div>
      <dialog-factory ref="teachingModeCousewarePackgeDetailDialog"
                      :key="'teachingModeCousewarePackgeDetail'"
                      :routerName="'teachingModeCousewarePackgeDetail'"></dialog-factory>
      <dialog-factory-common ref="teachingModeCousewarePackgeDetailCommonDialog"></dialog-factory-common>
    </div>
    <couseware-search-list v-if="showSearchList"
                           :dataInfo="dataInfo"
                           :seletedOLAPKey="seletedOLAPKey"
                           @closeDialog="closeSearch"></couseware-search-list>
    <teaching-mode-custom-dialog :title="'设备授权申请'"
                                 class="new_class defult_dialog"
                                 :visible.sync="isShowAuthForm"
                                 :before-close="closeForm">
      <auth-form @closeDialog="closeForm"
                 :dataInfo="authInfo"
                 @afterSuccess="afterSuccessForApplyAuth"
                 @afterSuccessCallback="afterSuccessCallback"
                 :successCallback="true"
                 :isTeachingMode="true"></auth-form>
    </teaching-mode-custom-dialog>
  </div>
</template>
<script>
import {
  TeachingModeGetCoursewarePackageDetails,
  teachingModeGetSearchCoursewarePackage,
  TeachingModegetSearchCourseware,
  TeachingModeGetCoursewareList,
  CWSAuthorizedDeviceManagementAuthorizedTheDevice,
  AddInterfaceLog
} from '../../../API/workbench';
import coursewareList from './courseware-list';
import childCoursewareList from './child-courseware-list';
import cousewareSearchList from './couseware-search-list';
import searchBtn from './search-btn';
import dialogFactory from '../../common/dialog-factory';
import authForm from '../../courseware-work-bench/components/report/auth-components/apply-auth.vue';
export default {
  data () {
    return {
      dataInfo: {
        CoursewarePackageList: []
        //         {
        //     'CoursewarePackageKey':'课包key',
        //     'CoursewarePackageKeyValue':'课包keyValue',
        //     'ParentKey':'父级',
        //     'TypeKey':'类型0子类;1父类;2目录',
        //     'ISAuthTeacher':'是否课包老师授权:1-是;0-否;',
        //     'IsLockKey':'是否释放:1-已释放;0-未释放',
        //     'BigCoursewarePackageCoverUrl':'大课包封面路径',
        //     'ShowOrder':'子课包序号',
        // },
      },
      dataList: [],
      showCWLevel: true,
      seletedOLAPKey: '', // 选中ID
      coursewareList: [],
      showSearchList: false,
      isShowAuthForm: false,
      authInfo: {},
      loading: true,
      searchText: '',
      timer: ''
    };
  },
  components: {
    coursewareList,
    childCoursewareList,
    dialogFactory,
    cousewareSearchList,
    authForm,
    searchBtn
  },
  computed: {
    //  vDateTime(){
    //   return this.$utils.formatDatet(Date.new(), 'yyyy-MM-dd') +' '+this.$utils.formatDatet(Date.new(), 'mm:ss')
    // },
    vTitle () {
      if (!this.showCWLevel) {
        let findItem = this.dataInfo.CoursewarePackageList.find(o => {
          return this.seletedOLAPKey == o.CoursewarePackageKey;
        });
        if (findItem) {
          return findItem.CoursewarePackageKeyValue;
        }
      } else if (
        this.seletedOLAPKey != this.dataInfo.OLAPKey &&
        this.dataInfo.CoursewarePackageList
      ) {
        let findItem = this.dataInfo.CoursewarePackageList.find(o => {
          return this.seletedOLAPKey == o.CoursewarePackageKey;
        });
        if (findItem) {
          return findItem.ParentKeyValue;
        }
      } else {
        return this.dataInfo.MainDemoName;
      }
    },
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    },
    vHasAuthModulePower () {
      let ModulePowerKey =
        this.$store.getters.SaaSClientEditionKey == 5 ? 108 : 123;
      console.log('this.vRolePowerLis', this.vRolePowerList);
      console.log(' vHasAuthModulePower', ModulePowerKey, this.$utils.CheckModulePower(this.vRolePowerList, ModulePowerKey));
      return this.$utils.CheckModulePower(this.vRolePowerList, ModulePowerKey);
    },
    // 判断是校长还是老师
    vRoleKey () {
      return this.$store.getters.token ? Number(this.$store.getters.token.RoleKey) == 2 : false; // 2为校长；非则为老师
    },
    isAutoAuth () {
      return this.$utils.getSaaSClientSetInfo(75).SetKey == 1;
    }
  },
  created () {
    console.log('this.$route.query', this.$route.query);
  },
  mounted () {
    if (this.$route.query.OLAPKey) {
      this.seletedOLAPKey = this.$route.query.OLAPKey;
      this.$nextTick(o => {
        this.getDataList(this.$route.query.OLAPKey);
        this.getCoursewarePackgeDetail(this.$route.query.OLAPKey);
      });
    }
    this.vDateTime();
    this.$dialog.register(this, ['courseAuthQrcode']);
    this.registerBusEvent();
    //    if (this.$zxClient.IsClient) {
    //   this.$zxClient.FullScreen();
    // } else {
    //   this.fullScreen();
    // }
  },
  methods: {
    toPage (type, item) {
      console.log('toPage', item);
      if (type == 0) {
        this.$router.push('/teachingMode');
      } else if (type == 1) {
        this.seletedOLAPKey = item.OLAPKey;
        this.searchText = '';
        this.getDataList(this.seletedOLAPKey);
      } else {

      }
    },
    vDateTime () {
      setInterval(o => {
        this.timer = this.$utils.formatDatet(Date.new(), 'yyyy-MM-dd') + ' ' + this.$utils.formatDatet(Date.new(), 'mm:ss');
      }, 1000);
    },
    clearSearch () {
      this.searchText = '';
      if (this.showCWLevel) {
        this.getDataList(this.seletedOLAPKey);
      } else {
        this.getCoursewareList();
      }
      // this.getCoursewarePackgeDetail(this.$route.query.OLAPKey);
    },
    searchData (value) {
      this.searchText = value;
      console.log('searchData', this.searchText, this.seletedOLAPKey);
      if (this.showCWLevel) {
        this.getDataList(this.seletedOLAPKey);
      } else {
        this.getCoursewareList();
      }

      // this.getCoursewarePackgeDetail(this.$route.query.OLAPKey);
    },
    applyAUth () {
      if (this.$zxClient && this.$zxClient.IsClient) {
        if (this.dataInfo.DeviceIsAuth != 1) {
          if (this.vHasAuthModulePower) {
            if (this.$zxClient.GetMInfo() === '{}') {
              layer.alert('设备信息为空');
              let interfaceLogData = {
                InterfaceName: this.$store.getters.APIURL + 'CWS.AuthorizedDeviceManagement.AuthorizedTheDevice',
                parameter: { BigCoursewarePackageKey: this.dataInfo.OLAPKey },
                PostData: JSON.stringify(this.$store.getters.token),
                LogContents: '设备信息为空',
                LogTitleName: '设备信息为空',
                RefererName: window.location.href
              };
              AddInterfaceLog(interfaceLogData).then(result => {
                console.log('记录日志');
              });
            } else {
              let DeviceInfo = JSON.parse(this.$zxClient.GetMInfo());
              layer.confirm('是否授权本设备', {
                mode: 'teachingMode',
                btn: [
                  {
                    name: '确认',
                    callBack: () => {
                      CWSAuthorizedDeviceManagementAuthorizedTheDevice(
                        DeviceInfo,
                        this.dataInfo.OLAPKey
                      ).then(
                        res => {
                          this.$zxClient.SetUCode(res.data.DeviceUCode);
                          // if (this.isAutoAuth) {
                          this.dataInfo.DeviceIsAuth = 1;
                          // }
                        },
                        err => {
                          layer.alert(err.msg, {
                            mode: 'teachingMode'
                          });
                        }
                      );
                      layer.close();
                    }
                  }
                ]
              });
            }

            // 无权限
          } else {
            this.authInfo = {
              CoursewareKey: this.dataInfo.OLAPKey,
              DeviceCode: this.$zxClient.GetMCode()
            };
            this.isShowAuthForm = true;
          }
        }
      } else {
        layer.alert('请用桌面端打开程序', {
          mode: 'teachingMode'
        });
      }
    },
    afterSuccessCallback () {
      this.isShowAuthForm = false;
      this.dataInfo.DeviceIsAuth = 1;
    },
    // 申请授权成功。
    afterSuccessForApplyAuth () {
      this.isShowAuthForm = false;
      // this.dataInfo.DeviceIsAuth = 1;
    },
    closeForm () {
      this.isShowAuthForm = false;
    },
    closeSearch () {
      this.showSearchList = false;
    },
    showSearch () {
      this.showSearchList = true;
    },
    fullScreen () {
      var element = document.documentElement;
      // 判断各种浏览器，找到正确的方法
      var requestMethod =
        element.requestFullScreen || // W3C
        element.webkitRequestFullScreen || // Chrome等
        element.mozRequestFullScreen || // FireFox
        element.msRequestFullScreen; // IE11
      if (requestMethod) {
        requestMethod.call(element);
      } else if (typeof window.ActiveXObject !== 'undefined') {
        // for Internet Explorer
        // eslint-disable-next-line no-undef
        var wscript = new ActiveXObject('WScript.Shell');
        if (wscript !== null) {
          wscript.SendKeys('{F11}');
        }
      }
    },
    seletedCWItem (item, isAutoAuth) {
      console.log('seletedCWItem', item, isAutoAuth);
      console.log('isAutoAuth', isAutoAuth);
      isAutoAuth = isAutoAuth || 0;
      if (item.TypeKey == 0) {
        this.getCoursewareList(item, isAutoAuth);
        this.getCoursewarePackgeDetail(this.$route.query.OLAPKey);
        this.seletedOLAPKey = item.CoursewarePackageKey;
        this.getDataList(this.seletedOLAPKey);
      } else {
        this.seletedOLAPKey = item.CoursewarePackageKey;
        this.searchText = '';
        this.getDataList(this.seletedOLAPKey);
        this.showCWLevel = true;
      }
    },
    // 根据大课包及子课包ID获取课件list
    getCoursewareList (item, isAutoAuth) {
      let teacherKey = this.vRoleKey ? '' : this.$store.getters.token.UserID;
      this.dataInfo.OLAPKey = this.dataInfo.OLAPKey || 0;
      if (this.dataInfo.OLAPKey) {
        TeachingModegetSearchCourseware(this.dataInfo.OLAPKey, this.searchText, teacherKey).then(result => {
          console.log('getCoursewareList', result.data);
          this.coursewareList = result.data;
          // this.seletedOLAPKey = item.ParentKey;
          this.showCWLevel = false;
          // this.searchEnd=true
        });
      }
      // TeachingModeGetCoursewareList(
      //   this.dataInfo.OLAPKey,
      //   item.CoursewarePackageKey,
      //   this.$store.getters.token.UserID,
      //   isAutoAuth
      // ).then(result => {
      //   console.log("getCoursewareList", result.data);
      //   this.coursewareList = result.data;
      //   this.seletedOLAPKey = item.CoursewarePackageKey;
      //   this.showCWLevel = false;

      // });
    },
    getDataList (OLAPKey) {
      var DeviceCode = '';
      if (this.$zxClient && this.$zxClient.IsClient) {
        DeviceCode = this.$zxClient.GetMCode(); // 340BC40DC769411B
      }
      teachingModeGetSearchCoursewarePackage(this.searchText, this.$store.getters.token.UserID, OLAPKey).then(result => {
        console.log('teachingModeGetSearchCoursewarePackage', result.data);
        this.dataList = result.data;
      }).catch(error => {
        console.log('errPackageErr', error);
      });
    },
    // 获取大课包详情
    getCoursewarePackgeDetail (OLAPKey) {
      var DeviceCode = '';
      if (this.$zxClient && this.$zxClient.IsClient) {
        DeviceCode = this.$zxClient.GetMCode(); // 340BC40DC769411B
      }
      this.loading = true;
      TeachingModeGetCoursewarePackageDetails(OLAPKey, DeviceCode).then(
        result => {
          console.log(result.data, 'getCoursewarePackgeDetail');
          this.loading = false;
          if (!this.vRoleKey) {
            result.data.CoursewarePackageList = result.data.CoursewarePackageList.filter(o => {
              return Number(o.IsLockKey) != 0;
            });
          }
          this.dataInfo = result.data;
          this.$nextTick(o => {
            if (this.showCWLevel) {
              this.$refs.coursewareList.initSwiper();
            }
          });
          let geoInfo = {
            Longitude: this.dataInfo.Longitude || '',
            Latitude: this.dataInfo.Latitude || '',
            Accuracy: this.dataInfo.Accuracy || '',
            PositioningOffset: this.dataInfo.PositioningOffset || ''
          };
          this.$store.commit('setGeoInfo', geoInfo);
        }
      );
    },
    goBack () {
      // 判断选中ID是否等于当前的大课包id
      if (this.seletedOLAPKey == this.dataInfo.OLAPKey) {
        this.$router.push('/teachingMode');
      } else {
        if (!this.showCWLevel) {
          let findItem = this.dataInfo.CoursewarePackageList.find(o => {
            return o.CoursewarePackageKey == this.seletedOLAPKey;
          });
          console.log('goBack', this.seletedOLAPKey, findItem);
          if (findItem) {
            // this.seletedOLAPKey = findItem.ParentKey;
            this.seletedOLAPKey = findItem.ParentKey;
          }
          this.showCWLevel = true;
        } else {
          let findItem = this.dataInfo.CoursewarePackageList.find(o => {
            return o.CoursewarePackageKey == this.seletedOLAPKey;
          });
          if (findItem) {
            this.seletedOLAPKey = findItem.ParentKey;
          }
        }
        this.getDataList(this.seletedOLAPKey);
      }
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCustomDialog', dialogInfo);
      if (this.$refs.teachingModeCousewarePackgeDetailDialog) {
        this.$refs.teachingModeCousewarePackgeDetailDialog.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCommonCustomDialogByFactoryDialog', dialogInfo);
      if (this.$refs.teachingModeCousewarePackgeDetailCommonDialog) {
        this.$refs.teachingModeCousewarePackgeDetailCommonDialog.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', dialogInfo => {
        if (dialogInfo.routerName == 'teachingModeCousewarePackgeDetail') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', dialogInfo => {
        if (dialogInfo.routerName == 'teachingModeCousewarePackgeDetail') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    }
  }
};
</script>
<style scoped>
.teaching_mode {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: url('../../../../public/image/new_teaching_mode_bg.png') center
    no-repeat;
  background-size: 100%;
  overflow: hidden;
}
.teaching_mode_header {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13.5rem 19.5rem 19.5rem;
  justify-content: space-between;
  z-index: 2;
}
.teaching_mode_header_left {
  display: flex;
  align-items: center;
  margin-left: 3.12rem;
}
.teaching_mode_header_hover_img:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(208.99deg, #424b59 13.46%, #272f3a 86.66%);
  box-shadow: 0px 5px 20px rgba(22, 131, 249, 0.7);
}
.teaching_mode_header_hover_img {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(208.99deg, #424b59 13.46%, #272f3a 86.66%);
  /* box-shadow: 0px 5px 20px rgba(41, 49, 61, 0.3); */
  border-radius: 32.57rem;
  width: 28.86rem;
  height: 28.86rem;
  overflow: hidden;
  text-align: center;
  line-height: 28rem;
  cursor: pointer;
  /* margin: auto; */
}
.teaching_mode_header_hover_img.has_auth {
  background: none !important;
  box-shadow: none !important;
  cursor: auto !important;
}
.teaching_mode_header_hover_img.has_auth:hover {
  background: none !important;
  box-shadow: none !important;
}
.teaching_mode_header_middle {
  line-height: 28rem;
  font-size: 13.04rem;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  position: relative;
}
.teaching_mode_header_right {
  width: 45.44rem;
  height: 37.44rem;
  display: flex;
  flex-direction: row;
  padding-top: 4rem;
  /* border-radius: 7.8rem; */
  /* overflow: hidden; */
}
.teaching_mode_header_right .teaching_mode_location {
  /* line-height: 26rem; */
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(208.99deg, #424b59 13.46%, #272f3a 86.66%);
  box-shadow: 0px 5px 20px rgba(41, 49, 61, 0.3); */
  /* border-radius: 32.57rem; */
  /* width: 28.86rem;
  height: 28.86rem;
  overflow: hidden;
  text-align: center; */
  line-height: 28rem;
  /* margin-right: 6rem; */
  /* cursor: pointer; */
}
.teaching_mode_header_right .teaching_mode_location img {
  width: 11.29rem;
  height: 13.65rem;
}
.teaching_mode_header_right div {
  margin-left: 10rem;
}
.teaching_mode_header_right img {
  width: 14.56rem;
  height: 12.13rem;
}
.teaching_mode_header_middle .search_btn {
  position: absolute;
  right: -25rem;
  margin-top: 2.5rem;
  display: inline-block;
  width: 22rem;
  height: 22rem;
  background-image: url(../../../../public/image/teaching_mode_search2.png);
  background-repeat: no-repeat;
  background-position: center center;
  /* background-size: 15rem; */
  /* background-color: rgba(0, 0, 0, 0.2); */
  cursor: pointer;
}
.teaching_mode_header_middle .search_btn:hover {
  background-image: url(../../../../public/image/teaching_mode_search_hover.png);
  background-repeat: no-repeat;
  background-position: center center;
  /* background-size: 15rem; */
  /* background-color: rgba(0, 0, 0, 0.2); */
  cursor: pointer;
}
.teaching_mode_header_right >>> .header_tips_icon {
  background: none !important;
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}
.teaching_mode_header_right_btn {
  margin-top: 6rem;
  width: 45rem;
  height: 20rem;
  opacity: 1;
  border-radius: 50rem;
  background: rgba(255, 96, 0, 1);
  font-size: 9rem;
  line-height: 20rem;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.teaching_mode_header_right_btn:hover {
  opacity: 0.8;
}

/*---------------------------- 搜索表单弹出层 -----------------------------*/

/* 搜索框 */

.defult_dialog_searchform {
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 225.81rem;
  height: 21.84rem;
  opacity: 1;
  border-radius: 3.12rem;
  background: rgba(255, 255, 255, 1), rgba(243, 245, 243, 1), rgba(0, 0, 0, 1);
  background-blend-mode: normalnormalnormal;

  border: 0.39rem solid rgba(255, 96, 0, 1);
  padding: 6.24rem 7.8rem 3.9rem;
  /* box-shadow: 0 2px 4px 0px #e7e7e7; */
  /* background-color: #fff; */
  /* border-radius: 8px; */
  overflow: hidden;
  position: relative;
  background: #fff;
}

.search_form {
  height: 16.38rem;
  padding: 3.9rem 5.85rem;
  border-radius: 1.56rem;
  border: 0.39rem solid #ececec;
  position: relative;
}

/* 学生搜索 */

.student_search_form {
  padding-left: 3.12rem;
  border-radius: 2.34rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 16.38rem;
  background-color: #fff;
  border: 0.39rem solid #ececec;
}

.student_search_form input {
  height: 100%;
  width: 100%;
  padding-right: 25.74rem;
  padding-left: 11.7rem;
  background: transparent url(../../../../public/image/search_icon01.png)
    no-repeat 4px center;
  background-size: 5.07rem;
}

.btn_light_blue_search,
.list_btn_light_blue_search {
  position: absolute;
  color: #3498db;
  width: 25.74rem;
  /* border-left: 1px solid #c1c1c1; */
  height: 14rem;
  font-size: 5.46rem;
  line-height: 14rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  right: 8.58rem;
  bottom: 5.07rem;
  background-color: transparent;
}

.search_form .search_input {
  width: 100%;
  padding-left: 7.8rem;
  height: 8rem;
  background-color: transparent;
  background-image: url(../../../../public/image/search_icon01.png);
  background-size: 5.07rem;
  background-position: left center;
  background-repeat: no-repeat;
}

.defult_dialog_searchform .remove_btn {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(../../../../public/image/dialog_del_icon.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.search_form .remove_btn:hover,
.search_form_CQ .remove_btn:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.teaching_mode_date {
  position: absolute;
  font-size: 11rem;
  right: 20rem;
  bottom: 12rem;
  width: 103rem;
  height: 20rem;
  line-height: 20rem;
  opacity: 1;
  border-radius: 50rem;
  text-align: center;
  background: rgba(247, 246, 250, 1);
}
</style>
