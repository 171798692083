<template>
  <div class="ZSJK_statistics_list" :class="statisticsData.className">
    <div class="ZSJK_statistics_list_title">
       {{statisticsData.title}}
    </div>
    <div class="ZSJK_statistics_list_number" :class="{'font_blue':statisticsData.title == '线上报名（人）'}" @click="clickItem">
        {{statisticsData.num}}
    </div>
    <div class="ZSJK_statistics_list_numberType">
        <div v-if="isShow(statisticsData.showNum)">
          <span class="font_gray">{{statisticsData.showNumName}}</span>
          <span>{{statisticsData.showNum}}</span>
        </div>
        <span v-if="isShow(statisticsData.virtualNum) && isShow(statisticsData.showNum)" class="font_gray" style="margin: 0 4px">|</span>
        <div v-if="isShow(statisticsData.virtualNum)">
          <span class="font_gray">{{statisticsData.virtualNumName}}</span>
          <span>{{statisticsData.virtualNum}}</span>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    statisticsData: Object
  },
  methods: {
    isShow (num) {
      return !isNaN(parseInt(num));
    },
    clickItem(){
      if(this.statisticsData.title == '线上报名（人）'){
        this.$emit('openActivitiesStatisticsList')
      }
    }
  }
};
</script>
<style>
</style>