<template>
  <div class="pitch_batch_distribution">
    <div class="mendian_backstage_search">
      <div class="pitch_count pr">已选中 {{batchAllotList.length}} 项
        <div class="remove_db_btn"
             @click="doCancelAllot"></div>
      </div>
      <div class="btn_hover_bg_blue search_btn"
           @click="doBatchAllot">批量分配</div>
    </div>
    <custom-dialog :title="'分配线索'"
                   class=" "
                   width="410px"
                   :visible.sync="showAllot"
                   :before-close="closeshowAllot">
      <allot-dialog :studentArray="studentArray"
                    @updateData="getDataList"
                    @closeDialog="closeshowAllot"></allot-dialog>
    </custom-dialog>
  </div>
</template>

<script>
import allotDialog from './allot-dialog';
export default {
  components: {
    allotDialog
  },
  data () {
    return {
      showAllot: false,
      studentArray: []

    };
  },
  props: {
    batchAllotList: {//  标题 （选填）
      type: Array,
      default: []
    }
  },
  methods: {
    // 添加批量分配
    doBatchAllot () {
      this.studentArray = [];
      this.batchAllotList.forEach(o => {
        this.studentArray.push(o.StudentKey);
      });
      this.showAllot = true;
    },
    // 取消批量分配
    doCancelAllot () {
      this.$emit('doCancelAllot');
    },
    // 成功回调
    getDataList () {
      this.closeshowAllot();
      this.$nextTick(() => {
        this.$emit('doCancelAllot', 'succeed');
      });
    },
    closeshowAllot () {
      this.showAllot = false;
    }
  }

};
</script>

<style>
</style>