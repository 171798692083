<template>
  <div>
    <div class="course_statistics_table_search flex course_statistics_title">

      <div class="course_statistics_search">

        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入学生名、课单号'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class=" table_list_content">
      <tableList class="summarizing_list"
                 :key="tableKey"
                 :tableData="vdataReportList"
                 :totalNum="totalNum"
                 :PageCount="PageCount"
                 :tableColumns="tableCourseColumns"
                 :isSearchText="false"
                 :isNeedInnerScroll="false"
                 :defaultSort="defaultSort"
                 :queryParams="searchObj"
                 :vFooterTotal="vFooterTotal"
                 @loadTableData="getDataList"></tableList>
    </div>
  </div>
</template>
<script>
import tableList from '../../../../common/table-list/index';
import inputSearchContainBtn from '../../../common/input-search-contain-btn';
import { GetTuitionBalanceClassHourList } from '../../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        GradeKey: '',
        GradeKeyValue: '',
        CourseKey: '',
        CourseKeyValue: '',
        TeacherKey: '',
        TeacherKeyValue: '',
        startTime: '',
        endTime: '',
        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          className: 'table_index_gray'
        },
        {
          label: '购课日期',
          prop: 'GenerateTime',
          width: ' ',
          minWidth: 112,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '订单号',
          prop: 'NumberName',
          width: ' ',
          minWidth: 90,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '课单号',
          prop: 'XNumberName',
          width: ' ',
          minWidth: 90,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '学生名',
          prop: 'MainDemoName',
          width: ' ',
          minWidth: 90,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '手机号',
          prop: 'CustomerPhoneName',
          minWidth: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },
        {
          label: '课单类型',
          prop: 'SourceTypeKeyValue',
          minWidth: 92,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },
        {
          label: '适用课程',
          prop: 'ApplyCorIDsNameValueContent',
          minWidth: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },
        {
          label: '购买课时',
          prop: 'NumCount',
          minWidth: 80,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'class-hour',
        },
        {
          label: '实收款',
          prop: 'ActualPayAmount',
          width: ' ',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',

        },
        {
          label: '生效日期',
          prop: 'StartTime',
          minWidth: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '失效日期',
          prop: 'ExpireTime',
          minWidth: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },
        {
          label: '消耗课时',
          prop: 'ConsumeClassHourNum',
          minWidth: 80,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'class-hour',
        },
        {
          label: '结转课消',
          prop: 'ConsumeClassHourAmount',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },
        {
          label: '可用课时',
          prop: 'CurrentCount',
          minWidth: 80,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'class-hour',

        },
        {
          label: '课时结余',
          prop: 'ValueAmount',
          sortable: false,
          minWidth: 100,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },
        {
          label: '可用天数',
          prop: 'PeriodCount',
          sortable: false,
          minWidth: 80,
          align: 'left',
          isShow: true,
          type: 'class-hour',
        },

      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
  },
  props: {
    toSearchObj: {
      type: Object
    },
    searchTime: Object,
    searchText: String,
    schoolItem: Object
  },
  created () {
    console.log(this.toSearchObj, 'this.toSearchObj');
    this.schoolKey = this.$route.query.schoolKey || 0
    this.searchObj.searchText = this.searchText
  },
  watch: {
    searchTime: {
      handler (c, o) {
        if (c) {
          //  this.getDateRangeCoursewareStat( this.$utils.getCurrentMonthFirst(),this.$utils.getCurrentMonthLast())
          console.log("searchTime")
          setTimeout(o => {
            console.log("searchTime", this.searchObj.searchText)
            this.searchObj.startTime = c.startTime
            this.searchObj.endTime = c.endTime
            this.getDataList();
          }, 100)
        }
      },
      immediate: true,
      deep: true
    },
    'schoolItem.OLAPKey': {
      handler (c, o) {
        if (c) {
          console.log('schoolItem.OLAPKey', this.schoolItem.OLAPKey)
          //  this.getDateRangeCoursewareStat( this.$utils.getCurrentMonthFirst(),this.$utils.getCurrentMonthLast())
          setTimeout(o => {
            this.getDataList();
          }, 100)
        }
      },
    }

  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.StartTime = o.StartTime == '1900-01-01' ? '-' : o.StartTime
        o.ActualPayAmount = Number(o.ActualPayAmount) == 0 ? '-' : '￥' + Number(Number(o.ActualPayAmount).toFixed(2))
        o.ConsumeClassHourAmount = Number(o.ConsumeClassHourAmount) == 0 ? '-' : '￥' + Number(Number(o.ConsumeClassHourAmount).toFixed(2))
        o.ValueAmount = Number(o.ValueAmount) == 0 ? '-' : '￥' + Number(Number(o.ValueAmount).toFixed(2))
        o.ApplyCorIDsNameValueContent = (o.IsSingleKey == 1 ? '单课|' : '组合课|') + o.ApplyCorIDsNameValue
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text;
      if (this.totalAmount > 0) {
        text = '+' + this.$utils.setMoneyformatToParseInt(this.totalAmount);
        text = ',合计' + text + '元';
      } else {
        text = ',合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      }
      return text;
    }
  },
  mounted () {
    // this.getDataList();
  },
  methods: {
    changeType (item, type) {
      console.log("changeType", item, type)
      this.$emit("changeType", item, type)
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.GradeKey = '';
      this.searchObj.CourseKey = '';
      this.searchObj.TeacherKey = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = GetTuitionBalanceClassHourList;
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '课时结余明细',
          clist: [
            { title: '购课日期', cName: 'GenerateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '订单号', cName: 'NumberName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课单号', cName: 'XNumberName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生名', cName: 'MainDemoName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '手机号', cName: 'CustomerPhoneName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课单类型', cName: 'SourceTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '是否单课', cName: 'IsSingleKey', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '适用课程', cName: 'ApplyCorIDsNameValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            {
              title: '购买课时', cName: 'NumCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::NumCount::2::0}}"
            },
            { title: '生效日期', cName: 'StartTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '失效日期', cName: 'ExpireTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },

            {
              title: '消耗课时', cName: 'ConsumeClassHourNum', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::ConsumeClassHourNum::2::0}}"
            },
            {
              title: '结转课消', cName: 'ConsumeClassHourAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::ConsumeClassHourAmount::2::0}}"
            },
            {
              title: '可用课时', cName: 'CurrentCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::CurrentCount::2::0}}"
            },
            {
              title: '课时结余', cName: 'ValueAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::ValueAmount::2::0}}"
            },
            {
              title: '可用天数', cName: 'PeriodCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::PeriodCount::2::0}}"
            },

          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(searchObj, this.schoolItem.OLAPKey, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      let fn = GetTuitionBalanceClassHourList;
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj, '22222');
      fn(this.searchObj, this.schoolItem.OLAPKey).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.red:
          this.doAttendanceDetailsClick(rowData);
          break;
        case this.$initJson.baseFunctionBtnName.detail:
          this.seletedItemToAttenDetail(rowData);
          break;
        // seletedItemToAttenDetail
        default:
          break;
      }
    },
    seletedItemToAttenDetail (item) {
      console.log(item, 'seletedItemToAttenDetail');
      let dataInfo = {
        CourseKey: item.CourseNameKey,
        CourseKeyValue: item.CourseNameKeyValue,
        GradeKey: item.GradeClassKey,
        GradeKeyValue: item.GradeClassKeyValue,
        TeacherKey: item.MTeacherKey,
        TeacherKeyValue: item.MTeacherKeyValue,
        ScheduleCourseKey: item.OLAPKey || 0,
        startTime: item.showClassTime.substring(0, 10),
        endTime: item.showClassTime.substring(0, 10)
      };
      let moduleInfo = {
        name: '课消明细',
        moduleName: 'attenDetailReport',
        routerName: this.$route.name,
        data: { dataInfo: dataInfo }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    },
    // 打开课消明细
    openAttenDetail () {
      let dataInfo = {
        startTime: this.searchObj.startTime,
        endTime: this.searchObj.endTime
      };
      let moduleInfo = {
        name: '课消明细',
        moduleName: 'attenDetailReport',
        routerName: this.$route.name,
        data: { dataInfo: dataInfo }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    },
    doAttendanceDetailsClick (item) {
      // if (this.mIsShowArrangeDialog(item)) {
      // this.$refs.arrangeDialog.isAttendanceArrangeShow = true;
      // this.$refs.arrangeDialog.getCourseInfo(item.OLAPKey);
      // }
      let moduleInfo = {
        name: '课程详情',
        moduleName: 'attendanceArrange',
        routerName: this.$route.name,
        data: { scheduleCourseKey: item.OLAPKey, isAlter: false }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    }

  }
};
</script>
<style>
.hover {
  cursor: pointer;
}
</style>