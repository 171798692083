<template>
  <div>
    <div>
      <div class="package_revise_title">
        <div class="title_name">
          购买项目
        </div> 
        <div v-if="vHasSelectedProject&&!isReadonly"
             class="pr title_btn"
             @click="chooseProjectClick($event,290)">
          添加项目
        </div>
      </div>
      <div v-if="!vHasSelectedProject"
           class="pr package_setting_add label_radio_bg_white"
           @click="chooseProjectClick($event,145)">
        <div class="package_setting_add_btn">选择项目</div>
      </div> 
      <div v-else
           class="package_revise_from"
           @scroll="scrollEvent">
        <div class="package_revise_ul package_details_ul">
          <div v-for="(item,index) in vProjectList"
               :key="index">
            <package-item v-if="item.type=='package'"
                          :packageInfo="item.data"
                          :isReadonly="isReadonly"
                          @showAlertDialog="showAlertDialog"
                          @onDeleteClick="deletePackage"></package-item>
            <class-hour-item v-if="item.type=='classHour'"
                             :item="item.data"
                             :isReadonly="isReadonly"
                             @showAlertDialog="showAlertDialog"
                             @onEditClick="editCourseBillForm"
                             @onDeleteClick="deleteCourseBillItem"></class-hour-item> 
            <zf-item v-if="item.type=='zf'"
                     :item="item.data"
                     :isReadonly="isReadonly"
                     @showAlertDialog="showAlertDialog"
                     @onEditClick="editZFForm"
                     @onDeleteClick="deleteZFItem"></zf-item>
          </div>
        </div>
        <!-- 底部统计 -->
        <bottom-total :formData="formData"
                      :isReadonly="isReadonly"
                      :couponList="couponList"
                      :curSelectionCoupon="curSelectionCoupon"
                      @afterChooseCouponEvent="doAfterChooseCoupon"></bottom-total>
        <div class="package_setting_fixed_bottom"
             v-if="!isReadonly">
          <div>
            <div>
              <span class="font_black">应收：</span>
              <span class="font_yellow">￥{{vRecableAmount}}</span>
            </div>
          </div>
          <div class="btn_hover_bg_blue"
               @click="clickCollection">
            立即收款
          </div>
        </div>
        <!-- 只读底部 -->
        <div class="signuppayment_readonly_bottom"
             v-if="isReadonly">
          <div class="bottom_left"
               :class="{card_type:collectionAccount.AccountTypeKey==1||collectionAccount.AccountTypeKey==2,alipay_type:collectionAccount.AccountTypeKey==3,WX_type:collectionAccount.AccountTypeKey==4,cash_type:collectionAccount.AccountTypeKey==5,other_type:collectionAccount.AccountTypeKey==6}">
            {{formData.CollectAccontKeyValue}}
          </div>
          <div class="bottom_right">
            <div class="bottom_big">
              <span class="font_gray">实收:</span>
              <span class="font_yellow">￥{{Number(formData.ActualColAmount)}}</span>
            </div>
            <div class="bottom_small"
                 v-if="Number(formData.StoPayAmount)>0||Number(formData.DebtAmount)>0">
              <span v-if="Number(formData.StoPayAmount)>0">
                <span class="font_gray">余额:</span>
                <span class="font_red">{{Number(formData.StoPayAmount)}}</span>
              </span>
              <span v-if="Number(formData.DebtAmount)>0">
                <span class="font_gray">欠款:</span>
                <span class="font_red">{{Number(formData.DebtAmount)}}</span>
              </span>
            </div>
          </div>
        </div>
        <div v-if="isReadonly">
          <div v-if='isDeleteBill == true'
               style="background-color:#fff;border-top:1px solid #ececec;width: 100%;"
               class="confirm_btn btn_fixed_bottom">
            <div style="width: 100%;cursor: no-drop;background: #E5E5E5;
                color: #999999;">{{this.formData.StatusKeyValue}}</div>
          </div>
          <div v-if='isDeleteBill == false'
               style="background-color:#fff;border-top:1px solid #ececec;width: 100%;"
               class="confirm_btn btn_fixed_bottom">
            <div @click="printTicketClick"
                 class="btn_fixed_blue btn_hover_bg_blue"
                 style="width: 50%;">打印收据</div>
            <el-button @click="deleteBillClick"
                       type="danger"
                       style="width: 50%;margin-left: 24px;">作废单据</el-button>
          </div>

        </div>
      </div>
      <!-- 下拉弹窗层 选择项目类型 课时 套餐 杂费 -->
      <div class="module_content_menuList"
           :style="'left:'+ positionLeft +'px;'+'top:'+ positionTop +'px' "
           v-show="isShowAlerDialog">
        <div class="top_bg"></div>
        <drop-down-box :optionJsonList="alertDialogMenuList"
                       @clickOptionForAlertDialog="chooseProjectType"></drop-down-box>
      </div>
    </div>
    <!-- 选择开单类型 -->
    <custom-dialog title="报名类型"
                   width="410px"
                   show-position="visibleMiddle"
                   ref="validityPeriod"
                   :visible.sync="isShowSignupTypeListDialog"
                   :append-to-body="false"
                   :close-on-click-modal="false"
                   :before-close="closeSignupTypeListDialog">
      <signup-type-list @onChooseSignupType="doAfterChooseSignupType"
                        @onBack="closeSignupTypeListDialog"></signup-type-list>
    </custom-dialog>
    <custom-dialog title="作废单据"
                   width="410px"
                   :visible.sync="isShowDeleteBillDialog"
                   :before-close="closeAllDialog">
      <delete-bill-table :searchParams="afterSearchParams"
                         @deleteBillBoolean="deleteBillBoolean"
                         @close="closeAllDialog"
                         @afterSuccess="afterSuccess"></delete-bill-table>
    </custom-dialog>
    <!-- 课时表单 -->
    <custom-dialog :title="'课时'"
                   width="410px"
                   show-position="top"
                   :visible.sync="isShowCourseBillFormDialog"
                   :append-to-body="false"
                   :close-on-click-modal="false"
                   :before-close="cancelCourseBillForm">
      <course-bill-form :KDNR="curSelectionKDNR"
                        @onConfirmClick="confirmCourseBillForm"
                        @onCancelClick="cancelCourseBillForm"></course-bill-form>
    </custom-dialog>
    <!-- 杂费表单 -->
    <custom-dialog :title="'杂费'"
                   width="410px"
                   show-position="top"
                   :visible.sync="isShowZFFormDialog"
                   :append-to-body="false"
                   :close-on-click-modal="false"
                   :before-close="cancelZFForm">
      <zf-form :ZFNR="curSelectionZFNR"
               @onConfirmClick="confirmZFForm"
               @onCancelClick="cancelZFForm"></zf-form>
    </custom-dialog>
    <!-- 收款界面 -->
    <custom-dialog :title="'收款金额'"
                   width="410px"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isShowCollectionAmountDialog"
                   :append-to-body="false"
                   :close-on-click-modal="false"
                   :before-close="closeCollectionAmountDialog">
      <collection-amount-dialog :submitData="formData"
                                :sumAmount="Number(studentData.SumAmount)"
                                @collectionClick="submitClick"
                                @cancelClick="closeCollectionAmountDialog"></collection-amount-dialog>
    </custom-dialog>
  </div>
</template>

<script>
import { GetPackageDetailInfoById, getCombo, saveBillingAccept, GetTheTicketMsg, getIncomeAccount } from '../../../../API/workbench.js';
import ClassHourItem from './browse-list/class-hour-item';
import ZfItem from './browse-list/zf-item';
import PackageItem from './browse-list/package-item';
import SignupTypeList from '../common/signup-type-list';
import courseBillForm from './form-data/course-bill-form';
import zfForm from './form-data/zf-form';
import bottomTotal from './bottom-total';
import collectionAmountDialog from '../common/collection-amount-dialog.vue';
import dropDownBox from '../../../common/drop-down-box';
import deleteBillTable from '../../../pop-up-menu/delete-bill-table/index';// 作废单据
const courseBillInfo = {
  xykdKey: 0,
  ApplyCorIDName: 0, // 适用课程IDs
  ApplyCorName: '',
  UnitKey: 5, // 课单单位
  UnitKeyValue: '课时',
  NumCount: 1, // 数量
  UnitPrice: 0, // 单价
  ValueAmount: 0, // 价值
  DiscountCount: 10, // 折扣
  AfterDisAmount: 0, // 金额
  DiscountAmount: 0, // 折扣金额
  PackageKey: 0, // 套餐
  PackageKeyValue: '',
  ApplyTypeKey: 1, // '报名类型',
  ApplyTypeKeyValue: '新报'// ''1-新生;2-续费;3-扩课'
};
const zfInfo = {
  ProjectKey: 0, // 杂费项目
  ProjectKeyValue: '',
  NumCount: 0, // 数量
  UnitName: '', // 单位
  UnitPrice: 0, // 单价
  ValueAmount: 0, // 价值
  DiscountCount: 10, // 折扣
  AfterDisAmount: 0, // 金额
  DiscountAmount: 0, // 折扣金额
  CommentSupport: '', // 备注
  PackageKey: 0, // 套餐
  PackageKeyValue: '',
  ApplyTypeKey: 1, // "报名类型",
  ApplyTypeKeyValue: '新报'// "1-新生;2-续费;3-扩课"

};
export default {
  data () {
    return {
      isDeleteBill: false,
      isShowDeleteBillDialog: false, // 作废单据弹出框
      afterSearchParams: null,
      formData: {
        StudentKey: 0, // int,--学生
        StudentKeyValue: '', // int, --学生
        SaleManIDsName: '',
        SaleMansName: '', // varchar(100), --销售人员
        BillTypeKey: 1, // "报名方式"
        BillTypeKeyValue: '到店', // "1-到店;2-活动;3-微网校;"
        JoinGradeClassKey: 0, // varchar(100),班级
        JoinGradeClassKeyValue: '', // int, 班级
        CollectAccontKey: 0, // int,--收款账户
        CollectAccontKeyValue: '', // varchar(100)

        PackageKey: 0, // int, 购买套餐
        PackageKeyValue: '不使用', // int, 购买套餐
        ComboDiscountAmount: 0, // 套餐折扣金额
        isSaving: false, // 是否正在保存
        KDNR: [],
        ZFNR: [],

        CouponKey: 0, // int,	--优惠券ID 0表示不用优惠券
        CouponKeyValue: '不使用', // varchar(100), --券名
        CouponSenderKey: 0, // int, --推广人ID
        commission: 0, // varchar(100), --用券提成
        CouponAmount: 0, // decimal(18,2),	--优惠券金额
        CouponLevelPathName: '',

        TotalValueAmount: 0, // 订单原价
        OrderAmount: 0.0, // 订单金额OrderAmount = TotalValueAmount - DiscountAmount
        DiscountAmount: 0, // 订单折扣金额 (所有从表的折扣金额累加)
        ReduceAmount: 0, // 整个订单最后的折后优惠金额（最终的应收金额抹去的金额，例如：算出应收金额318 ，还价后抹去18的零头，最终的应收金额（RecableAmount）=300，折后优惠=18）
        AfterDisPreAmount: 0, // 总的折后优惠=ReduceAmount + ComboDiscountAmount
        RecableAmount: 0, // 应收金额(RecableAmount = OrderAmount - CouponAmount - AfterDisPreAmount - DepositAmount )

        DebtAmount: 0, // decimal(18,2),	--欠款金额
        StoPayAmount: 0, // decimal(18,2),	--储值金额
        DepositAmount: 0, // 订金
        ActualColAmount: 0, // decimal(18,2),--实收

        UnitKey: 2,
        UnitKeyValue: '年',
        PeriodCount: 1,
        StartTime: null, // --开始时间
        EndTime: this.$utils.formatDateToLine(this.$utils.getNextYear(Date.new())) // --结束时间
      }, // 表单数据
      projectTypes: [// 项目类型
        {
          type: 'package',
          name: '套餐',
          isShow: true
        },
        {
          type: 'classHour',
          name: '课时',
          isShow: true
        },
        {
          type: 'zf',
          name: '杂费',
          isShow: true
        }
      ],
      alertDialogMenuList: [], // 弹出层操作项
      selectionProjectType: {}, // 当前选中的项目类型,
      curSelectionSignupType: {}, // 当前选中的报名类型,
      optionBtns: [// 操作按钮
        {
          type: 'edit',
          name: '修改',
          isShow: true
        },
        {
          type: 'delete',
          name: '删除',
          isShow: true
        }
      ],
      isShowSignupTypeListDialog: false, // 缴费类型 新生，扩课，
      packageList: [], // 套餐列表
      selectionPackage: {}, // 当前选中的套餐
      curSelectionKDNR: {}, // 当前选中的课单内容
      curSelectionZFNR: {}, // 当前选中的杂费内容
      curSelectionProject: {}, // 当前选中的项目
      isShowZFFormDialog: false, // 显示杂费表单页
      isShowCourseBillFormDialog: false, // 显示课时表单页
      isShowCollectionAmountDialog: false, // 收款页面
      isShowAlerDialog: false, // 是否显示项目类型
      isReadonly: false, // 是否只读
      collectionAccount: [], // 收款账户
      positionTop: 0,
      positionLeft: 0,
      curSelectionCoupon: null
    };
  },
  props: {
    studentData: Object,
    tableID: [Number, String],
    guid: String,
    couponID: [Number, String],
    couponList: Array
  },
  computed: {
    vRecableAmount () {
      if (this.$utils.mMoneyType(this.formData.RecableAmount - Number(this.formData.DepositAmount), 2) > 0) {
        return this.$utils.mMoneyType(this.formData.RecableAmount - Number(this.formData.DepositAmount), 2);
      } else {
        return 0;
      }
    },
    vYear () {
      let flag = false;
      this.formData.KDNR.forEach(e => {
        if (e.UnitKey != 5) {
          flag = true;
        }
      });
      return flag;
    },
    vCourse () {
      let flag = false;
      this.formData.KDNR.forEach(e => {
        if (e.UnitKey == 5) {
          flag = true;
        }
      });
      return flag;
    },
    vCourseBillFormTitle () {
      let formTitle = '';
      if (!this.curSelectionKDNR.ApplyCorName) {
        formTitle = '购买课单';
      } else {
        formTitle = '编辑课单';
      }
      return formTitle;
    },
    vZFFormTitle () {
      let formTitle = '';
      if (!this.curSelectionZFNR.ProjectKey) {
        formTitle = '购买杂费';
      } else {
        formTitle = '编辑杂费';
      }
      return formTitle;
    },
    vHasSelectedProject () {
      let flag = false;
      if (this.formData.KDNR.length > 0 || this.formData.ZFNR.length > 0) {
        flag = true;
      }
      return flag;
    },
    vCourseBillList () {
      return this.formData.KDNR.filter(obj => { return obj.PackageKey == 0; });
    },
    vZFProjectList () {
      return this.formData.ZFNR.filter(obj => { return obj.PackageKey == 0; });
    },
    vProjectList () {
      let projectList = [];
      let isAddPackage = false;
      let packageData = { type: 'package', data: { TableID: 0, PackageName: '', ApplyTypeKeyValue: '', ValueAmount: 0, AfterDisAmount: 0, SalePrice: 0, AfterDisPreAmount: this.formData.ComboDiscountAmount, KDNR: [], ZFNR: [] } };
      if (this.formData.KDNR.length > 0) {
        this.formData.KDNR.forEach(obj => {
          if (obj.PackageKey > 0) {
            packageData.data.KDNR.push(obj);
            packageData.data.AfterDisAmount += Number(obj.AfterDisAmount);
            packageData.data.ValueAmount += Number(obj.ValueAmount);
            if (!isAddPackage) {
              isAddPackage = true;
              packageData.data.TableID = obj.PackageKey;
              packageData.data.PackageName = obj.PackageKeyValue;
              packageData.data.ApplyTypeKeyValue = obj.ApplyTypeKeyValue;
              projectList.push(packageData);
            }
          } else {
            projectList.push({ type: 'classHour', data: obj });
          }
        });
      }
      if (this.formData.ZFNR.length > 0) {
        this.formData.ZFNR.forEach(obj => {
          if (obj.PackageKey > 0) {
            packageData.data.ZFNR.push(obj);
            packageData.data.AfterDisAmount += Number(obj.AfterDisAmount);
            packageData.data.ValueAmount += Number(obj.ValueAmount);
            if (!isAddPackage) {
              isAddPackage = true;
              packageData.data.TableID = obj.PackageKey;
              packageData.data.PackageName = obj.PackageKeyValue;
              packageData.data.ApplyTypeKeyValue = obj.ApplyTypeKeyValue;
              projectList.push(packageData);
            }
          } else {
            projectList.push({ type: 'zf', data: obj });
          }
        });
      }
      if (packageData.data.TableID > 0) {
        packageData.data.SalePrice = this.$utils.mMoneyType(packageData.data.AfterDisAmount - Number(packageData.data.AfterDisPreAmount), 2);
      }
      return projectList;
    }
  },
  watch: {
    studentData (n, o) {
      if (n.CustKey != o.CustKey && !this.isReadonly) {
        this.resetCouponInfo();
      }
      this.formData.StudentKey = n.OLAPKey;

      this.formData.StudentKeyValue = n.MainDemoName;
    },
    couponList (n, o) {
      if (n.length > 0 && this.couponID > 0) { // 线上预付款后 直接开单
        this.curSelectionCoupon = this.couponList.find(obj => { return obj.you_hui_id == this.couponID; }) || {};
        this.formData.DepositAmount = this.$utils.mMoneyType(Number(this.curSelectionCoupon.buy_amount), 2);
        this.formData.CouponKey = this.curSelectionCoupon.you_hui_id;// 优惠券ID 0表示不用优惠券
        this.formData.CouponKeyValue = this.curSelectionCoupon.scopeExplain; // 券名
        this.formData.CouponType = this.curSelectionCoupon.type; // 券类型 2满减券 3特价券 4代金券
        if (this.curSelectionCoupon.taocanid > 0 && this.curSelectionCoupon.taocanid != this.formData.PackageKey) {
          this.getPackageList(this.curSelectionCoupon.taocanid);
        }
      }
    },
    'formData.KDNR' (n,o){
      if(n){
        console.log('1------------',n)
        let {course,year} = false
        n.forEach((item)=>{
          if (item.UnitKey == 5) {
            course = true;
        }

        if (item.UnitKey != 5) {
            year = true;
        }
        })
        if(year){
          this.formData.EndTime = ''
              // this.formData.StartTime =this.$utils.formatDateToLine(Date.new())
            
        }
        if(course && (this.formData.EndTime == '' || !this.formData.EndTime)){
            let stratTime = Date.new();
            if (this.formData.UnitKey == 2) { // 年
              stratTime = stratTime.setFullYear(stratTime.getFullYear() + Number(this.formData.PeriodCount?this.formData.PeriodCount:1));
            } else if (this.formData.UnitKey == 3) { // 月
              stratTime = stratTime.setMonth(stratTime.getMonth() + Number(this.formData.PeriodCount?this.formData.PeriodCount:1)
              );
            } else if (this.formData.UnitKey == 4) { // 日
              stratTime = stratTime.setDate(stratTime.getDate() + Number(this.formData.PeriodCount?this.formData.PeriodCount:1)
              );
            }
            stratTime = stratTime - 86400000;
            this.formData.EndTime = this.$utils.formatDateToLine(stratTime); // --结束时间``
            // console.log('this.formData.StartTime',this.formData.StartTime)
            this.formData.StartTime =this.formData.StartTime == null||this.formData.StartTime =='1970-01-01'||this.formData.StartTime == ''? '': this.$utils.formatDateToLine(this.formData.StartTime); // --结束时间``
            // console.log('this.formData.StartTime',this.formData.StartTime)
        } 

        if (this.selectionPackage.ExpireTime) {
          this.formData.EndTime = this.$utils.formatDateToLine(this.selectionPackage.ExpireTime); // --结束时间
        } 
      }
    },

  },
  components: {
    ClassHourItem,
    ZfItem,
    PackageItem,
    SignupTypeList,
    courseBillForm,
    zfForm,
    bottomTotal,
    collectionAmountDialog,
    dropDownBox,
    deleteBillTable
  },
  created () {
    this.formData.StudentKey = this.studentData.OLAPKey;
    this.formData.StudentKeyValue = this.studentData.MainDemoName;
    this.formData.guid = this.guid;
    if (this.tableID) {
      this.isReadonly = true;
      this.getTheTicketMsg();
    }
    if (this.dataInfo && this.dataInfo.guid) { // 添加记录ID
      this.purchaseCountInfo.guid = this.dataInfo.guid;
    }
    document.addEventListener('click', e => {
      const ele = document.getElementsByClassName('alert_dialog')[0];
      if (e.target && !e.target.contains(ele)) {
        this.isShowAlerDialog = false;
      }
    });
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList', 'signuppaymentSuccess']);
    console.log(this.formData, 'this.formDatathis.formData');
  },
  methods: {
    // 选择项目
    chooseProjectClick (e, left) {
      this.alertDialogMenuList = this.projectTypes;
      this.positionTop = e.currentTarget.offsetTop + e.currentTarget.clientHeight / 2 + 10;
      this.positionLeft = left;
      setTimeout(() => {
        this.isShowAlerDialog = true;
      }, 50);
    },
    // 选择项目类型
    chooseProjectType (item) {
      this.selectionProjectType = item;
      switch (item.type) {
        case 'package':
          this.getPackageList();
          break;
        case 'classHour':
          this.curSelectionKDNR = this.$utils.parseJson(courseBillInfo);
          this.curSelectionKDNR.ID = Math.round(Math.random() * 1000000);// 生成随机数以便后面的修改和删除
          this.isShowCourseBillFormDialog = true;
          break;
        case 'zf':
          this.curSelectionZFNR = this.$utils.parseJson(zfInfo);
          this.curSelectionZFNR.ID = Math.round(Math.random() * 1000000);// 生成随机数以便后面的修改和删除
          this.isShowZFFormDialog = true;
          break;
        case 'edit':
          if (this.curSelectionProject.type == 'classHour') {
            this.editCourseBillForm(this.curSelectionProject);
          } else {
            this.editZFForm(this.curSelectionProject);
          }
          break;
        case 'delete':
          if (this.curSelectionProject.type == 'classHour') {
            this.deleteCourseBillItem(this.curSelectionProject);
          } else if (this.curSelectionProject.type == 'zf') {
            this.deleteZFItem(this.curSelectionProject);
          } else {
            this.deletePackage();
          }
          break;
        default:
          break;
      }
      this.isShowAlerDialog = false;
    },
    // 获取套餐列表
    getPackageList (id) {
      getCombo().then(result => {
        result.data.forEach(obj => {
          obj.OLAPKey = obj.TableID;
          obj.MainDemoName = obj.PackageName;
        });
        this.packageList = result.data;
        if (id > 0) { // 线上预付款后 直接开单
          this.selectionPackage = this.packageList.find(obj => { return obj.TableID == id; });
          this.doAfterChooseSignupType({ ApplyTypeKey: 1, ApplyTypeKeyValue: '新生' });
        } else {
          this.$dialog.open(this, {
            routerName: this.$route.name,
            name: '选择套餐',
            moduleName: 'singleSelectionList',
            data: { selectedKey: this.formData.PackageKey, dataSourceList: this.packageList, required: true, addModuleName: 'tao-can-setting', addModuleTitleName: '添加套餐', ModulePowerKey: 46 },
            callBack: {
              onSelectedItem: (data) => { this.doAfterChoosePackage(data); }
            }
          });
        }
      }).catch(error => {
        console.info(error.msg);
      });
    },
    // 选择套餐内容
    doAfterChoosePackage (item) {
      if (this.formData.PackageKey > 0 && this.formData.PackageKey != item.TableID) {
        layer.confirm('请注意，同一次报名，仅能选择一个套餐。如果继续，将会移除之前添加的套餐，是否继续？', {
          btn: [
            {
              name: '取消',
              callBack: function () {
                layer.close();
              }
            },
            {
              name: '继续',
              callBack: () => {
                layer.close();
                // 处理重选套餐后,只删除套餐项保留课和杂费项
                this.formData.KDNR = this.formData.KDNR.filter(obj => { return obj.PackageKey == 0; });
                this.formData.ZFNR = this.formData.ZFNR.filter(obj => { return obj.PackageKey == 0; });
                this.formData.PackageKey = 0;
                this.formData.PackageKeyValue = '';
                this.formData.ComboDiscountAmount = 0;
                this.selectionPackage = item;
                this.openSignupTypeList();
              }
            }
          ]
        });
      } else if (this.formData.PackageKey == 0) {
        this.selectionPackage = item;
        this.openSignupTypeList();
      }
    },
    // 打开报名类型列表
    openSignupTypeList () {
      this.isShowSignupTypeListDialog = true;
    },
    // 选中报名类型
    doAfterChooseSignupType (item) {
      console.log(item, 'doAfterChooseSignupType');
      this.curSelectionSignupType = item;
      // 套餐结束方式 限日期
      if (this.formData.KDNR.length == 0 && this.formData.ZFNR.length == 0) {
        if (this.selectionPackage.ExpireTime) {
          this.formData.EndTime = this.$utils.formatDateToLine(this.selectionPackage.ExpireTime); // --结束时间
          console.log(666666,this.formData.EndTime)
        } else {  // 套餐结束方式 限时长
          this.formData.UnitKey = this.selectionPackage.UnitKey;
          this.formData.UnitKeyValue = this.selectionPackage.UnitKeyValue;
          // this.formData.StartTime = this.$utils.formatDateToLine(Date.new()); // --开始时间
          this.formData.PeriodCount = Number(this.selectionPackage.PeriodCount);
          let stratTime = Date.new();
          if (this.formData.UnitKey == 2) { // 年
            stratTime.setFullYear(stratTime.getFullYear() + this.formData.PeriodCount);
          } else if (this.formData.UnitKey == 3) { // 月
            stratTime.setMonth(stratTime.getMonth() + this.formData.PeriodCount);
          } else if (this.formData.UnitKey == 4) { // 日
            stratTime.setDate(stratTime.getDate() + this.formData.PeriodCount);
          }
          stratTime.setDate(stratTime.getDate() - 1);
          this.formData.EndTime = this.$utils.formatDateToLine(stratTime); // --结束时间
        }
      }
      this.loadPackageDetail();
      this.closeSignupTypeListDialog();
    },
    // 获取套餐详情
    loadPackageDetail () {
      GetPackageDetailInfoById(this.selectionPackage.TableID).then(result => {
        Object.assign(result.data, this.selectionPackage);
        this.selectionPackage = result.data;
        this.formData.PackageKey = result.data.TableID;
        this.formData.PackageKeyValue = result.data.PackageName;
        this.formData.StartTime = result.data.StartTime;
        this.formData.ComboDiscountAmount = Number(result.data.AfterDisPreAmount);
        result.data.KDNRList.forEach(obj => {
          obj.NumCount = Number(obj.NumCount); // 数量
          obj.UnitPrice = Number(obj.UnitPrice); // 数量
          obj.ValueAmount = Number(obj.ValueAmount); // 价值
          obj.DiscountCount = Number(obj.DiscountCount); // 折扣
          obj.AfterDisAmount = Number(obj.AfterDisAmount); // 金额
          obj.DiscountAmount = obj.ValueAmount - obj.AfterDisAmount;// 折扣金额
          obj.PackageKey = result.data.TableID;
          obj.PackageKeyValue = result.data.PackageName;
          obj.ApplyTypeKey = obj.IsDrainageKey == '1' ? '4' : this.curSelectionSignupType.ApplyTypeKey;
          obj.ApplyTypeKeyValue = obj.IsDrainageKey == '1' ? '引流' : this.curSelectionSignupType.ApplyTypeKeyValue;
          if (this.formData.UnitKey != 5) {
              this.formData.EndTime = ''
              // console.log(11111,this.formData.EndTime)
            }
          this.formData.KDNR.push(obj);
          
        });

        if (result.data.ExpireTime) {
              this.formData.EndTime = result.data.ExpireTime
            }else{
              console.log('this.vCourse',this.vCourse,result.data)
              if(this.vCourse){
                let stratTime = Date.new();
                    if (result.data.UnitKey == 2) { // 年
                      stratTime = stratTime.setFullYear(stratTime.getFullYear() + Number(result.data.PeriodCount));
                    } else if (result.data.UnitKey == 3) { // 月
                      stratTime = stratTime.setMonth(stratTime.getMonth() + Number(result.data.PeriodCount)
                      );
                    } else if (result.data.UnitKey == 4) { // 日
                      stratTime = stratTime.setDate(stratTime.getDate() + Number(result.data.PeriodCount)
                      );
                    }
                    stratTime = stratTime - 86400000;
                    this.formData.EndTime = this.$utils.formatDateToLine(stratTime); // --结束时间
              }
              
            }


        result.data.ZFList.forEach(obj => {
          obj.NumCount = Number(obj.NumCount); // 数量
          obj.UnitPrice = Number(obj.UnitPrice); // 单价
          obj.ValueAmount = Number(obj.ValueAmount); // 价值
          obj.DiscountCount = Number(obj.DiscountCount); // 折扣
          obj.AfterDisAmount = Number(obj.AfterDisAmount); // 金额
          obj.DiscountAmount = obj.ValueAmount - obj.AfterDisAmount;// 折扣金额
          obj.PackageKey = result.data.TableID;
          obj.PackageKeyValue = result.data.PackageName;
          obj.ApplyTypeKey = this.curSelectionSignupType.ApplyTypeKey;
          obj.ApplyTypeKeyValue = this.curSelectionSignupType.ApplyTypeKeyValue;
          this.formData.ZFNR.push(obj);
        });
        this.caculateTotalAmount();
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 关闭报名类型
    closeSignupTypeListDialog () {
      this.isShowSignupTypeListDialog = false;
    },
    // 关闭作废单据
    closeAllDialog () {
      this.isShowDeleteBillDialog = false;
    },
    // 修改课单
    editCourseBillForm (data) {
      this.curSelectionKDNR = data;
      this.isShowCourseBillFormDialog = true;
    },
    // 确定课单
    confirmCourseBillForm (data) {
      console.log(data, 'data');
      this.cancelCourseBillForm();
      if (this.curSelectionKDNR.ApplyCorIDName) {
        let courseBillIndex = this.formData.KDNR.findIndex(obj => { return obj.ID == this.curSelectionKDNR.ID; });
        this.formData.KDNR.splice(courseBillIndex, 1, data);
      } else {
        this.formData.KDNR.push(data);
      }
      this.caculateTotalAmount();
    },
    // 取消 课单弹窗
    cancelCourseBillForm () {
      this.isShowCourseBillFormDialog = false;
    },
    // 修改杂费
    editZFForm (data) {
      this.curSelectionZFNR = data;
      this.isShowZFFormDialog = true;
    },
    // 确定杂费
    confirmZFForm (data) {
      this.cancelZFForm();
      if (this.curSelectionZFNR.ProjectKey) {
        let zfIndex = this.formData.ZFNR.findIndex(obj => { return obj.ID == this.curSelectionZFNR.ID; });
        this.formData.ZFNR.splice(zfIndex, 1, data);
      } else {
        this.formData.ZFNR.push(data);
      }
      this.caculateTotalAmount();
    },
    // 取消 学杂费弹窗
    cancelZFForm () {
      this.isShowZFFormDialog = false;
    },
    showAlertDialog (item, type, top) {
      this.curSelectionProject = item;
      if (type == 'package') {
        this.optionBtns[0].isShow = false;
      } else {
        this.optionBtns[0].isShow = true;
      }
      this.curSelectionProject.type = type;
      this.alertDialogMenuList = this.optionBtns;
      this.positionTop = top;
      this.positionLeft = 300;
      setTimeout(() => {
        this.isShowAlerDialog = true;
      }, 50);
    },
    scrollEvent () {
      this.isShowAlerDialog = false;
    },
    // 删除套餐
    deletePackage () {
      this.selectionPackage = {};
      this.formData.PackageKey = 0;
      this.formData.PackageKeyValue = '';
      this.formData.ComboDiscountAmount = 0;

      this.formData.KDNR = this.formData.KDNR.filter(obj => { return obj.PackageKey == 0; });
      this.formData.ZFNR = this.formData.ZFNR.filter(obj => { return obj.PackageKey == 0; });
      this.caculateTotalAmount();
    },
    // 删除课单
    deleteCourseBillItem (data) {
      let courseBillIndex = this.formData.KDNR.findIndex(obj => { return obj.ID == data.ID; });
      this.formData.KDNR.splice(courseBillIndex, 1);
      this.caculateTotalAmount();
    },
    // 删除杂费
    deleteZFItem (data) {
      let ZFIndex = this.formData.ZFNR.findIndex(obj => { return obj.ID == data.ID; });
      this.formData.ZFNR.splice(ZFIndex, 1);
      this.caculateTotalAmount();
    },
    // 计算总金额
    caculateTotalAmount () {
      console.log(this.formData, '计算总金额1');
      this.formData.TotalValueAmount = 0;  // 总价值
      this.formData.OrderAmount = 0; // 总金额
      if (this.formData.KDNR.length > 0 || this.formData.ZFNR.length > 0) {
        this.formData.KDNR.forEach(e => {
          this.formData.TotalValueAmount += Number(e.ValueAmount);
          this.formData.OrderAmount += Number(e.AfterDisAmount);
        });
        this.formData.ZFNR.forEach(e => {
          this.formData.TotalValueAmount += Number(e.ValueAmount);
          this.formData.OrderAmount += Number(e.AfterDisAmount);
        });
        this.formData.OrderAmount = this.$utils.mMoneyType(this.formData.OrderAmount, 2);
        // 折扣金额=订单原价-订单金额
        this.formData.DiscountAmount = this.$utils.mMoneyType(this.formData.TotalValueAmount - this.formData.OrderAmount, 2);
        // 总折后优惠 = ReduceAmount + ComboDiscountAmount
        this.formData.AfterDisPreAmount = this.formData.ReduceAmount + this.formData.ComboDiscountAmount;
        // 重算优惠券金额

        if (this.formData.CouponKey > 0) {
          let recAmount = this.$utils.mMoneyType(this.formData.OrderAmount - this.formData.ComboDiscountAmount, 2);
          if (this.curSelectionCoupon.type == 2) { // 满减
            if (recAmount < Number(this.curSelectionCoupon.xiao_fei_tiao_jian)) { // 未达到满减条件 不可用
              this.resetCouponInfo('unCaculateTotalAmount');
            } else {
              this.formData.CouponAmount = this.$utils.mMoneyType(Number(this.curSelectionCoupon.di_xian_jin, 2));
            }
          } else if (this.curSelectionCoupon.type == 3) { // 特价
            if (this.formData.PackageKey > 0 && this.curSelectionCoupon.taocanid != this.formData.PackageKey) { // 适用某个套餐的券 当前未选择该套餐 不可用
              this.resetCouponInfo('unCaculateTotalAmount');
            } else {
              // 特价券作用于套餐，则只取套餐的总金额
              let packageAmount = this.formData.KDNR.find(o => { return Number(o.PackageKey) > 0; }).AfterDisAmount;
              if (Number(packageAmount) - Number(this.formData.ComboDiscountAmount) <= Number(this.curSelectionCoupon.you_hu_money)) {
                this.formData.CouponAmount = recAmount;
              } else {
                this.formData.CouponAmount = this.$utils.mMoneyType(packageAmount - this.formData.ComboDiscountAmount - Number(this.curSelectionCoupon.you_hu_money), 2);
              }
            }
          } else if (this.curSelectionCoupon.type == 4) { // 代金
            if (recAmount <= this.curSelectionCoupon.di_xian_jin) { // 应收金额 < 代金券金额 则优惠券金额 = 应收金额
              this.formData.CouponAmount = this.formData.DepositAmount > 0 ? recAmount - this.formData.DepositAmount : recAmount; // 金额小于代金时，有预付款需减
            } else {
              this.formData.CouponAmount = this.curSelectionCoupon.di_xian_jin;
            }
          }
        }
        // 应收 = 订单金额 - 优惠券金额 - 总折后优惠 - 订金
        let newRecableAmount = this.$utils.mMoneyType(this.formData.OrderAmount - this.formData.CouponAmount - this.formData.AfterDisPreAmount, 2);
        this.formData.RecableAmount = newRecableAmount > 0 ? newRecableAmount : 0;
        // 实收 = 应收
        if ((this.formData.RecableAmount - this.formData.DepositAmount) > 0) {
          this.formData.ActualColAmount = this.formData.RecableAmount - this.formData.DepositAmount;
        } else {
          this.formData.ActualColAmount = 0;
        }
      } else {
        this.formData.TotalValueAmount = 0;
        this.formData.OrderAmount = 0;
        this.formData.RecableAmount = 0;
        this.formData.ActualColAmount = 0;
        this.formData.DiscountAmount = 0;// 折扣金额(从表所有打折金额的和)
        this.formData.ReduceAmount = 0;// 折后优惠（优惠额）
        this.formData.ComboDiscountAmount = 0;
        this.resetCouponInfo('unCaculateTotalAmount');
      }
      console.log(this.formData, '计算总金额2');
    },
    // 立即收款
    clickCollection () {
      this.isShowCollectionAmountDialog = true;
    },
    closeCollectionAmountDialog () {
      this.isShowCollectionAmountDialog = false;
    },
    validateSaveForm () {
      let flag = true;
      // let curDate = Date.new();
      if (this.studentData.IsConfirmKey == 0) {
        layer.alert('该学生待验证确认，请先去学生档案中进行确认操作。');
        flag = false;
      } else if (this.formData.KDNR.length <= 0 && this.formData.ZFNR <= 0) {
        layer.alert('课单不能为空');
        flag = false;
      } else if (this.formData.KDNR.length <= 0 && this.formData.JoinGradeClassKey > 0 && this.studentData.IsActiveKey == 0) {
        layer.alert('该学生为潜在客户，没有购买课单 ，不可加入班级');
        flag = false;
      } else if ((this.vCourse && this.vYear && (!this.formData.StartTime || !this.formData.EndTime)) || (this
            .vYear && !this.vCourse && !this.formData.StartTime) || (!this.vYear && this
            .vCourse && !this.formData.EndTime)) {
        layer.alert('选择正确的有效期');
        flag = false;
      } else if (this.formData.PeriodCount <= 0) {
        layer.alert('有效期至少大于等于1日');
        flag = false;
      } else if (this.formData.OrderAmount < 0) {
        layer.alert('订单金额不能小于0');
        flag = false;
      } else if (this.formData.RecableAmount < 0) {
        layer.alert('应收金额不能小于0');
        flag = false;
      } else if (this.formData.RecableAmount >= 100000000) {
        layer.alert('应收金额必须小于一亿');
        flag = false;
      }
      //  else {
      //   for (let index = 0; index < this.formData.KDNR.length; index++) {
      //     const obj = this.formData.KDNR[index];
      //     if (obj.PackageKey > 0 && obj.UnitKey != 5) { // 套餐ID》0,且为期卡
      //       let stratTime = Date.new(this.formData.StartTime);
      //       if (obj.UnitKey == 2) { // 年
      //         stratTime = stratTime.setFullYear(stratTime.getFullYear() + obj.NumCount);
      //       } else if (obj.UnitKey == 3) { // 月
      //         stratTime = stratTime.setMonth(stratTime.getMonth() + obj.NumCount);
      //       } else if (obj.UnitKey == 4) { // 日
      //         stratTime = stratTime.setDate(stratTime.getDate() + obj.NumCount);
      //       }
      //       stratTime = stratTime - 86400000;
      //       this.formData.EndTime = this.$utils.formatDateToLine(stratTime); // --结束时间
      //       console.log('--结束时间', this.formData.EndTime);
      //       if (stratTime < Date.new(this.$utils.formatDateToLine(Date.new())).getTime()) {
      //         layer.alert(obj.ApplyCorName + ':学期卡的有效期结束时间需大于等于今天');
      //         this.closeCollectionAmountDialog();
      //         flag = false;
      //         break;
      //       }
      //     }
      //   }
      // }
      // else if (this.formData.CouponKey > 0) { // 券类型 2满减券 3特价券 4代金券
      //   if (this.curSelectionCoupon.type == 2 && (this.formData.OrderAmount - this.formData.ComboDiscountAmount) < Number(this.curSelectionCoupon.xiao_fei_tiao_jian)) { // 未达到满减条件 不可用
      //     layer.alert('所选优惠券未满足使用条件');
      //     this.closeCollectionAmountDialog();
      //     this.resetCouponInfo();
      //     flag = false;
      //   } else if (this.formData.PackageKey > 0 && this.curSelectionCoupon.taocanid != this.formData.PackageKey) { // 适用某个套餐的券 当前未选择该套餐 不可用
      //     layer.alert('所选优惠券不适用所选套餐');
      //     this.closeCollectionAmountDialog();
      //     this.resetCouponInfo();
      //     flag = false;
      //   }
      // }
      return flag;
    },
    setSemesterEndTime () {
      let flag = true;
      if (this.formData.PackageKey > 0 && this.formData.KDNR.length == 1) {
        this.formData.PeriodCount = this.formData.KDNR[0].NumCount;
      }
      for (let index = 0; index < this.formData.KDNR.length; index++) {
        const obj = this.formData.KDNR[index];
        const findUnit = this.formData.KDNR.findIndex(o => {
          return o.UnitKey == 5;
        });
        if (findUnit >= 0 && this.formData.EndTime < this.$utils.formatDateToLine(Date.new())) {
          layer.alert('结束日期不能小于今天');
          flag = false;
          break;
        }
        if (obj.PackageKey > 0 && obj.UnitKey != 5) { // 套餐ID》0,且为期卡
          
          this.formData.PeriodCount = this.formData.KDNR[index].NumCount;
          if (!this.formData.StartTime) {
            console.log('12111112121221');
            this.formData.StartTime = this.$utils.formatDateToLine(Date.new());
          }
          console.log('obj.NumCount', this.formData);
          let stratTime = Date.new(this.formData.StartTime);
          if (obj.UnitKey == 2) { // 年
            stratTime = stratTime.setFullYear(stratTime.getFullYear() + obj.NumCount);
          } else if (obj.UnitKey == 3) { // 月
            stratTime = stratTime.setMonth(stratTime.getMonth() + obj.NumCount);
          } else if (obj.UnitKey == 4) { // 日
            stratTime = stratTime.setDate(stratTime.getDate() + obj.NumCount);
          }
          stratTime = stratTime - 86400000;
          // if( findUnit < 0){
          // this.formData.EndTime = this.$utils.formatDateToLine(stratTime); // --结束时间
          // }
          console.log('--结束时间', stratTime);
          if (stratTime < Date.new(this.$utils.formatDateToLine(Date.new())).getTime()) {
            layer.alert(obj.ApplyCorName + ':学期卡的有效期结束时间需大于等于今天');
            flag = false;
            break;
          }
        }
      }
      return flag;
    },
    // 确定按钮
    submitClick (data) {
      console.log('数据', data, this.isSaving);
      if (this.isSaving) {
        return;
      }
      this.formData = data;
      if (this.formData.KDNR.length == 0 && this.formData.ZFNR.length == 0) {
        if (this.selectionPackage.ExpireTime) {
          this.formData.EndTime = this.$utils.formatDateToLine(this.selectionPackage.ExpireTime); // --结束时间
          console.log(222222,this.formData.EndTime)
        } else {  // 套餐结束方式 限时长
          this.formData.UnitKey = this.selectionPackage.UnitKey;
          this.formData.UnitKeyValue = this.selectionPackage.UnitKeyValue;
          // this.formData.StartTime = this.$utils.formatDateToLine(Date.new()); // --开始时间
          this.formData.PeriodCount = Number(this.selectionPackage.PeriodCount);
          let stratTime = Date.new();
          if (this.formData.UnitKey == 2) { // 年
            stratTime.setFullYear(stratTime.getFullYear() + this.formData.PeriodCount);
          } else if (this.formData.UnitKey == 3) { // 月
            stratTime.setMonth(stratTime.getMonth() + this.formData.PeriodCount);
          } else if (this.formData.UnitKey == 4) { // 日
            stratTime.setDate(stratTime.getDate() + this.formData.PeriodCount);
          }
          stratTime.setDate(stratTime.getDate() - 1);
          this.formData.EndTime = this.$utils.formatDateToLine(stratTime); // --结束时间
        }
      } else {
        this.formData.PeriodCount = 1;
      }
      this.isSaving = true;
      // 14257 验证单选学期卡 并计算结束日期
      let semester = this.setSemesterEndTime();
      if (!semester) {
        this.isSaving = false;
        this.closeCollectionAmountDialog();
        return false;
      }
      console.log('数据', data, this.isSaving);
      let res = this.validateSaveForm();
      if (!res) {
        this.isSaving = false;
        this.closeCollectionAmountDialog();
        return false;
      }
      console.log('数据', data, this.isSaving);
      // this.formData = data;
      let submitData = this.$utils.parseJson(this.formData);
      submitData.KDNR = JSON.stringify(submitData.KDNR);
      submitData.ZFNR = JSON.stringify(submitData.ZFNR);
      submitData.RecableAmount = submitData.RecableAmount > 0 ? submitData.RecableAmount : submitData.DepositAmount;// (提交时应收金额有预付款显示预付款)
      console.log('数据', data, this.isSaving);
      saveBillingAccept(submitData).then(result => {
        this.$emit('sigunupSuccess', this.formData, result.data);
        this.printTicketClick(result.data.TableID);
      }, error => {
        if (error.code == 60007) {
          this.$emit('sigunupSuccess', this.formData, error.data);
          this.printTicketClick(error.data.TableID);
        } else {
          layer.alert(error.msg);
        }
      }).finally(() => {
        this.isSaving = false;
        this.closeCollectionAmountDialog();
      });
    },
    // 切换学生 重置优惠券信息
    resetCouponInfo (val) {
      this.formData.DepositAmount = 0;
      this.formData.CouponAmount = 0;
      this.formData.CouponType = 0;
      this.formData.CouponKey = 0;
      this.formData.CouponKeyValue = '';
      this.formData.CouponSenderKey = 0;
      this.formData.commission = 0;
      this.formData.CouponLevelPathName = '';
      this.curSelectionCoupon = null;
      if (!val) {
        this.caculateTotalAmount();
      }
    },
    // 选中优惠券
    doAfterChooseCoupon (data) {
      this.curSelectionCoupon = data;
      this.caculateTotalAmount();
    },
    getTheTicketMsg () {
      console.log(111, '没进的');
      GetTheTicketMsg(this.tableID).then(result => {
        this.formData = result.data;
        if (this.formData.StatusKeyValue == '作废中' || this.formData.StatusKeyValue == '作废' ||
          this.formData.StatusKeyValue == '已作废') {
          this.isDeleteBill = true;
        } else {
          this.isDeleteBill = false;
        }
        this.loadCollectAccountList();
        console.log('GetTheTicketMsg', this.formData);
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    loadCollectAccountList () {
      getIncomeAccount().then(
        result => {
          this.collectionAccount = result.data.find(obj => { return obj.OLAPKey == this.formData.CollectAccontKey; }) || {};
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 打印收据
    printTicketClick (tableID) {
      console.log(tableID, '打印收据');
      if (tableID > 0) {
        let moduleInfo = {
          name: '报名成功',
          routerName: this.$route.name,
          routertitle: '',
          moduleName: 'signuppaymentSuccess',
          data: { tableID: tableID, studentInfo: this.studentData }
        };
        this.$bus.emit('needOpenRouter', moduleInfo);
      } else {
        let moduleInfo = {
          name: '报名成功',
          routerName: this.$route.name,
          routertitle: '',
          moduleName: 'signuppaymentSuccess',
          data: { tableID: Number(this.tableID), studentInfo: this.studentData, isMoneyReceipt: true }
        };
        this.$bus.emit('needOpenRouter', moduleInfo);
      }
    },
    deleteBillClick (tableID) {
      console.log(this.isDeleteBill, 'this.isDeleteBill');
      this.afterSearchParams = this.$utils.parseJson(this.tableID);
      this.isShowDeleteBillDialog = true;
      // this.$dialog.open(this, {
      //   name: '作废单据',
      //   routerName: this.$route.name,
      //   moduleName: 'deletebill',
      //   routertitle: '',
      //   data: { tableID: Number(this.tableID), studentInfo: this.isDeleteBill }
      // });
    },
    deleteBillBoolean () {
      this.formData.StatusKeyValue = '作废中';
      this.getTheTicketMsg();
    },
    afterSuccess () {
      this.$emit('afterSuccess');
    }
  }
};
</script>

<style>
</style>