<template>
  <!-- 单选 -->
  <div :class="{ 'form_info_required': required }">
    <span class="font_gray">{{formTitle}}</span>
    <div class="form_first_list_radio flex">
      <div class="radio_list"
           :class="{'opt':item.isOpt}"
           v-for="(item,index) in radioList"
           :key="index"
           @click="doClick(item)">
        <span>{{item.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formTitle: {
      // 标题，必填
      type: String,
      required: true
    },
    radioList: Array,
    required: Boolean // 是否必填，可选，如不提供
  },
  data () {
    return {
      isReadonly: false // 是否只读
    };
  },
  computed: {

  },
  created () {

  },
  methods: {
    doClick (item) {
      this.radioList.forEach(o => {
        if (o.id == item.id) {
          o.isOpt = true;
        } else {
          o.isOpt = false;
        }
      });
      this.$emit('doClick', item);
    }
  }
};
</script>

<style>
.form_first_list_radio .radio_list {
  position: relative;
  padding-left: 26px;
  line-height: 35px;
  min-width: 80px;
  max-width: 348px;
  cursor: pointer;
}
.form_first_list_radio {
  display: flex;
}
.form_first_list_radio .radio_list {
  position: relative;
  padding-left: 26px;
  line-height: 35px;
  min-width: 80px;
  max-width: 348px;
  flex: 1;
}
.form_first_list_radio .radio_list::after {
  content: '';
  position: absolute;
  left: 0;
  top: 9px;
  width: 16px;
  height: 16px;
  border: 1px solid #dcdcdc;
  border-radius: 50%;
  box-sizing: border-box;
}
.form_first_list_radio .radio_list.opt::after {
  border-color: #3498db;
}
.form_first_list_radio .radio_list.opt:before {
  position: absolute;
  content: '';
  left: 4px;
  top: 13px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #3498db;
}
.form_info_required .form_first_list_radio {
  padding-bottom: 10px;
  padding-top: 5px;
}
</style>