<template>
  <div class="mendian_backstage_summarizing_day">
    <div class="day_switch">
      <!-- <div class="day_switch_btn btn_left" @click.stop="seletedPreDay"></div> -->
      <div class="day_switch_text font_blue pr">{{vDay}}{{vSeletedDate}}
        <input-date class="mendianOperationManagement_date_box"
                    :formTitle="''"
                    :popperClass="'course_date_el'"
                    @changeDate="changeDay"></input-date>
      </div>
      <!-- <div class="day_switch_btn btn_right" @click.stop="seletedAfterDay"></div> -->
    </div>
    <div class="day_content">
      <div class="day_list">
        <span class="font_gray">开户</span>
        <span class="font_number">{{Number(reportInfo.CreateDateCount)}}</span>
      </div>
      <div class="day_list">
        <span class="font_gray">辅导</span>
        <span class="font_number">{{Number(reportInfo.TutorialDateCount)}}</span>
      </div>
      <div class="day_list">
        <span class="font_gray">留存</span>
        <span class="font_number">{{Number(reportInfo.KeepAliveCount)}}</span>
      </div>
      <div class="day_list">
        <span class="font_gray">留存率</span>
        <span class="font_number">{{Math.round(Number(reportInfo.KeepAliveRate)||0)}}</span>
        <span class="font_unit">%</span>
      </div>
    </div>
  </div>
</template>
<script>
import { SummarizeKeepAliveData } from '../../../../API/workbench';
export default {
  data () {
    return {
      seletedDate: Date.new(),
      reportInfo: {
        CreateDateCount: 0,
        TutorialDateCount: 0,
        KeepAliveCount: 0,
        KeepAliveRate: 0
      }
    };
  },
  computed: {
    vSeletedDate () {
      return this.$utils.formatDateToLine(this.seletedDate);
    },
    vDay () {
      if (this.$utils.formatDateToLine(this.seletedDate) == this.$utils.formatDateToLine(Date.new())) {
        return '今天 ';
      } else if (this.isYestday(this.seletedDate)) {
        return '昨天 ';
      } else {
        return '';
      }
    }
  },
  created () {
    this.getSummarizeKeepAliveData();
  },
  mounted () {
    this.$bus.on(this, 'updateMendianOperationManagement', (classID, studentID) => {
      console.log('updateMendianOperationManagement');
      this.getSummarizeKeepAliveData();
    });
  },
  methods: {
    changeDay (date) {
      // 复制一下date.
      this.seletedDate = Date.new(date);
      this.getSummarizeKeepAliveData();
    },
    isYestday (theDate) {
      var date = Date.new();    // 当前时
      var today = Date.new(date.getFullYear(), date.getMonth(), date.getDate()).getTime(); // 今天凌晨
      var yestday = Date.new(today - 24 * 3600 * 1000).getTime();
      return theDate.getTime() < today && yestday <= theDate.getTime();
    },
    seletedPreDay () {
      let date = this.seletedDate.setDate(this.seletedDate.getDate() - 1);
      this.seletedDate = Date.new(date);
      this.getSummarizeKeepAliveData();
    },
    seletedAfterDay () {
      let date = this.seletedDate.setDate(this.seletedDate.getDate() + 1);
      this.seletedDate = Date.new(date);
      this.getSummarizeKeepAliveData();
    },
    getSummarizeKeepAliveData () {
      let param = {
        StartTime: this.$utils.formatDateToLine(this.seletedDate),
        EndTime: this.$utils.formatDateToLine(this.seletedDate)
      };
      SummarizeKeepAliveData(param).then(result => {
        console.log(result.data, 'SummarizeKeepAliveData');
        this.reportInfo = result.data;
      }).catch(error => {
        console.log(error, 'error');
      });
    }
  }
};
</script>

