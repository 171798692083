import axios from 'axios';
import store from '../store';
import router from '../router';
import fun from '../assets/KPI';
import {
  AddInterfaceLog,
  GetNewToken
} from '../API/workbench';
import qs from 'qs';
import {
  GetRequest
} from '../utils';
let interfaceName = null;

let fetchWaiteList = [];

let fetchTimeoutCount = 0;

export function fetchToken (options, callbackForNetworkError) {
  if (store.getters.signData) {
    if (!options.params.signdata) {
      options.params.sign = store.getters.signData.Sign;
    }
  }

  return new Promise((resolve, reject) => {
    if (options.checkResubmit && isTest()) {
      let top10RequestionApi = store.getters.top10RequestionApi;
      let curTime = Date.new();
      if (top10RequestionApi.length > 0) {
        let isExistsApi = null;
        isExistsApi = top10RequestionApi.find(obj => {
          return obj.apiParams == JSON.stringify(options.params) && curTime - obj.happenTime < 500;
        });
        if (isExistsApi) {
          layer.alert(JSON.stringify(options.params) + '重复提交了接口，请注意测试');
          return;
        }
      }
      store.commit('setTop10RequestionApi', JSON.stringify(options.params));
    }
    fetch(options, callbackForNetworkError).then(resolve,
      data => {
        if (data.ret == 410) {
          if (!store.getters.signData || !options.paramsData.sign || options.paramsData.service.indexOf('GetNewToken') > 0) {
            toLogin();
          }

          let fetchHandler = (data) => {
            options.paramsData.sign = data;
            fetch(options, callbackForNetworkError).then(resolve, reject);
          };
          if (store.getters.signData && options.paramsData.sign != store.getters.signData.Sign) {
            fetchHandler(store.getters.signData.Sign);
          } else {
            // 更换签名队列
            if (fetchWaiteList.length == 0) {
              fetchWaiteList.push(fetchHandler);
              // 更换签名
              GetNewToken(JSON.stringify(store.getters.signData)).then(res => {
                localStorage.setItem('apiList', JSON.stringify(res.data.ApiList));
                delete res.data.ApiList;
                store.commit('selectNewAPIURL');
                store.commit('SetToken', res.data);
                fun.loginInfo.SetToken(res.data);
                fun.loginInfo.setCookie('token', JSON.stringify(res.data));
                let execList = fetchWaiteList;
                fetchWaiteList = [];
                execList.forEach(fetchItem => {
                  fetchItem(res.data.SignData.Sign);
                });
              }, x => {
                toLogin();
              });
            } else {
              fetchWaiteList.push(fetchHandler);
            }
          }
        } else {
          if (reject) {
            reject(data);
          }
        }
      }
    );
  });
}
// var token = '&signdata={%22APIKey%22:%228a08a1febd44426b8353a54b746d037d%22,%22SignType%22:2,%22UserID%22:82,%22SaaSClientKey%22:10017,%22UserName%22:%2213714926475%22,%22SignTime%22:1524364714,%22ExpirationTime%22:1524365314,%22Sign%22:%221b9aead45d71b1addbdd142f2d757b77a3de26c9%22,%22OtherData%22:{%22HeadImg%22:3333}}'
export function fetch (options, callbackForNetworkError) {
  let logConent = {};

  if (options.params && Object.keys(options.params).length > 0) {
    interfaceName = options.params.service;
    let qsStr = qs.stringify(options.params);
    options.url += options.url.indexOf('?') > 0 ? '&' : '?';
    options.url += qsStr;
    options.paramsData = options.params;
    options.params = {};
  } else if (options.paramsData && Object.keys(options.paramsData).length > 0) {
    interfaceName = options.paramsData.service;
    let qsStr = qs.stringify(options.paramsData);
    options.url = options.url.split('?')[0];
    options.url += options.url.indexOf('?') > 0 ? '&' : '?';
    options.url += qsStr;
  }
  let timeoutNum = 60000;
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      params: {},
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      baseURL: store.getters.APIURL,
      timeout: timeoutNum // 超时
    });
    instance(options).then(response => {
      let res = response.data;
      // 请求成功返回，将超时计数器置0
      fetchTimeoutCount = 0;
      if (!res.ret && typeof (res) == 'string' && res.indexOf('{"ret":') >= 0) {
        try {
          res = JSON.parse(res.substring(res.indexOf('{"ret":')));

          logConent.LogTitleName = '接口服务异常: 代码警告';
          logConent.LogContents = response.data;
          addInterfaceLog(options, logConent);
        } catch (ex) { }
      }
      if (res.ret == 200 && res.data && (res.data.code >= 0 || res.data.errcode >= 0)) {
        const data = res.data;
        if (data.code === 0 || data.errcode === 0) {
          resolve(data);
        } else if (data.code > 0) {
          if (reject) {
            reject(data);
          } else {
            console.log('业务异常:' + res.data.code + ',' + res.data.msg, res.data, options);
            layer.alert(res.data.msg || data.errtip);
          }
        } else if (data.errcode > 0) {
          let msg;
          switch (data.errcode) {
            case 40003:
              msg = '推送失败 : 该用户的微信公众号已过期,请重新登录';
              break;
            default:
              msg = '微信公众号异常, 请联系客服确认原因，异常代码：' + data.errcode;
              break;
          }
          layer.alert(msg);
        } else {
          console.log('业务未知异常:' + res.data.code + ',' + res.data.msg, response, options);
          layer.alert('业务未知异常:' + res.data.msg || data.errtip);
        }
      } else {
        // 异常
        if (res.ret == 410) {
          if (reject) {
            reject(res);
          } else {
            toLogin();
          }
        } else if (res.ret > 0 && res.ret !== 200) {
          logConent.LogTitleName = '接口服务异常:' + res.ret;
          logConent.LogContents = res.msg;
          addInterfaceLog(options, logConent);
        } else {
          logConent.LogTitleName = '接口服务异常:未知异常';
          logConent.LogContents = res;
          addInterfaceLog(options, logConent);
        }
      }
    }).catch(error => {
      console.log('fetch error', error, options);
      let customerMsg = '';
      if ((error.message == ('timeout of ' + timeoutNum + 'ms exceeded') && error.code == 'ECONNABORTED') || error.message == 'Network Error') { // 超时 或 网络异常
        logConent.LogTitleName = error.code;
        logConent.LogContents = error.stack || error.message;
        customerMsg = '请检查您的网络.';
      } else if (error.response) {
        logConent.LogTitleName = error.response.headers;
        logConent.LogContents = error.response.data;
        customerMsg = '啊呀，遇到了点小问题<br/>错误代码为' + error.response.status + '-{code}<br/>如果问题多次发生，请联系您的专属客服。';
      } else if (error.request) {
        logConent.LogTitleName = '无response';
        logConent.LogContents = error.request;
        customerMsg = '啊呀，遇到了点小问题<br/>错误代码为1-{code}<br/>如果问题多次发生，请联系您的专属客服。';
      } else {
        logConent.LogTitleName = '未知问题';
        logConent.LogContents = error.stack || error.message;
        customerMsg = '啊呀，遇到了点小问题<br/>错误代码为2-{code}<br/>如果问题多次发生，请联系您的专属客服。';
      }
      // 请求超时，将超时计数器+1，达到3时，切换接口地址
      if (error.code == 'ECONNABORTED' || error.message == 'Network Error' || error.message.includes('timeout')) {
        let count = parseInt(fetchTimeoutCount);
        count = count + 1;
        if (count >= 3) {
          store.commit('selectNewAPIURL');
          fetchTimeoutCount = 0;
        } else {
          fetchTimeoutCount = count;
        }
        // 请求发出但是返回的状态码不是2XX，切换接口地址
      } else if (error.response) {
        store.commit('selectNewAPIURL');
      }
      addInterfaceLog(options, logConent, (result) => {
        if (!isTest()) { // 产品环境
          if (customerMsg) {
            customerMsg = customerMsg.replace('{code}', result.data);
            layer.alert(customerMsg);
          }
        }
      });
      if (error.message == 'Network Error' && callbackForNetworkError) {
        callbackForNetworkError();
      }
    });
  });
}

function isTest () {
  const locationUrl = GetRequest();
  return window.location.host.indexOf('test') > -1 || window.location.host.indexOf('beta') > -1 || window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('192.168.') > -1 || locationUrl.testit == 1;
}

function addInterfaceLog (fetchOptions, logConent, callback) {
  // {
  //   InterfaceName:"http://api.zxtest.zhixuerj.com:8888/public/?service=WEBV.SaaSClient.GetTheSaaSClientMsg",//接口名称
  //   parameter:"&RoleKey=33&RoleKey=33",//接口get参数
  //   PostData:"{"OLAPKey":"","MainDemoName":"koko","SexKey":"3","SexKeyValue":"女"}",//接口post参数
  //   LogContents:"",//日志内容
  //   LogTitleName:"",//日志标识
  // }

  console.error('接口服务异常: ' + logConent.LogTitleName, fetchOptions, logConent.LogContents);
  let curRoutePath = 'curRoutePath:location.hash=' + location.hash + '&curOpenDialogName=' + store.getters.curOpenDialogName + '&FromType=PC';
  if (typeof (logConent.LogContents) == 'object') {
    logConent.LogContents.curRoutePath = curRoutePath;
    logConent.LogContents = JSON.stringify(logConent.LogContents);
  } else if (typeof (logConent.LogContents) == 'string') {
    logConent.LogContents = logConent.LogContents ? (logConent.LogContents + curRoutePath) : curRoutePath;
  } else {
    logConent.LogContents = logConent.LogContents ? (typeof (logConent.LogContents) + logConent.LogContents + curRoutePath) : curRoutePath;
  }

  if (typeof (fetchOptions.params) == 'object') {
    fetchOptions.params = JSON.stringify(fetchOptions.params);
  }
  if (isTest()) {
    layer.alert('<div style="user-select: all;text-align: left;">' + logConent.LogTitleName + '<br>' + fetchOptions.url + '<br>' + logConent.LogContents + '</div>');
  }

  let postData = fetchOptions.params.service != 'WEB.Files.Upload' ? fetchOptions.data : '';
  let interfaceLogData = {
    InterfaceName: store.getters.APIURL + fetchOptions.url,
    parameter: fetchOptions.params || '',
    PostData: postData || '',
    LogContents: logConent.LogContents || '',
    LogTitleName: logConent.LogTitleName || '',
    RefererName: window.location.href
  };

  // 判断是否为门店端日志记录接口，如果是则不再记录（跳过）。
  // 如果不是，则提交异常到门店端日志记录接口。
  if (interfaceName != 'WEBV.Common.AddInterfaceLog') {
    AddInterfaceLog(interfaceLogData).then(result => {
      if (typeof callback == 'function') {
        callback(result);
      }
    });
  }
}

function toLogin () {
  fun.loginInfo.delCookie('token');
  if (store.getters.signData) {
    location.reload();
  } else {
    store.commit('SetToken', null);
    localStorage.removeItem('apiList');
    localStorage.removeItem('newAPIURL');
    store.commit('selectNewAPIURL');
    let path = '/login';
    router.push(path);
  }
}
// 获取外部权限列表
export function loadMoudlePowerData (type, callback) {
  const configHost = store.getters.APIURL; // 本地开发及小程序 指定域名，发布H5从当前域名获取
  const timeoutNum = 60000;
  var typeString = type || ''; // type 为HQ则请求总部权限。
  console.log(configHost, 'loadMoudlePowerData');
  const instance = axios.create({
    method: 'GET',
    params: {
      //
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    },
    baseURL: store.getters.APIURL + '/public/dynamicJS/' + typeString + 'ModulePowerSetting.php?IsJSON=1&SaaSClientKey=' + store.getters.SaaSClientInfo.OLAPKey,
    timeout: timeoutNum // 超时
  });
  // return instance
  instance().then(result => {
    callback(result);
  }).catch(error => {
    console.log(error, 'errloadMoudlePowerData');
  });
}
