<!-- 文本 -->
<template>
  <div>
    <div slot="title"
         class="title"
         style="padding:10px">自定义列 <span style="font-size: 14px;">勾选需要显示的列，拖动列名进行排序</span></div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="字段选择"
                   name="fieldsChosen">
        <el-checkbox :indeterminate="isIndeterminate"
                     v-model="checkAll"
                     @change="handleCheckAllChange">全部</el-checkbox>
        <div style="margin:15px 0;"></div>
        <el-checkbox-group v-model="columns"
                           @change="handleCheckedList">
          <div style="padding:10px;">
            <el-checkbox style="width:40%;"
                         v-for="item in allColumns"
                         :label="item"
                         :key="item">{{item}}</el-checkbox>
          </div>
        </el-checkbox-group>

      </el-tab-pane>
      <el-tab-pane label="字段顺序"
                   name="fieldSorted">
        <draggable v-model="columns"
                   handle=".move">
          <transition-group>
            <div v-for="field in columns"
                 :key="field"
                 style="background-color:gainsboro;margin:10px; text-align: center;"
                 class="move">
              {{field}}
            </div>
          </transition-group>
        </draggable>

      </el-tab-pane>
    </el-tabs>
    <div slot="footer"
         class="dialog-footer"
         style="text-align: center;">
      <el-button class="buttonLeft"
                 type="primary"
                 plain
                 @click="recoverChecked">恢复默认</el-button>
      <el-button @click="close"
                 class="buttonRight">取 消</el-button>
      <el-button type="primary"
                 @click="submit"
                 class="buttonRight">确 定</el-button>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
export default {
  data () {
    return {
      activeName: 'fieldsChosen',
      isIndeterminate: false,
      checkAll: false,
      checkedColumns: [],
      fieldList: [],
      columns: [],
      allColumns: [],
      fieldsTemp: [], // 存放按指定顺序排列的fields数组
      staticColumns: []
    };
  },
  props: {
    tableColumns: {
      type: Array,
      default: () => {
        return [
          {
            label: {// 列名
              type: String,
              required: true
            },
            prop: {// 绑定的字段名
              type: String,
              required: true
            },
            type: {// 类型
              type: String,
              required: true
            },
            extend: {
            },
            width: 120, // 默认宽
            sortable: true, // 是否可排序
            align: 'left', // 对戏方式
            isShow: false// 是否显示
          }
        ];
      }
    },
    defualtColumns: {
      type: Array,
      default: () => {
        return [
          {
            label: {// 列名
              type: String,
              required: true
            },
            prop: {// 绑定的字段名
              type: String,
              required: true
            },
            type: {// 类型
              type: String,
              required: true
            },
            extend: {
            },
            width: 120, // 默认宽
            sortable: true, // 是否可排序
            align: 'left', // 对戏方式
            isShow: false// 是否显示
          }
        ];
      }
    }
  },
  created () {
    this.fieldList = Object.assign([], this.tableColumns);  // 用于编辑显示数据
    this.fieldList.forEach(item => {
      this.columns.push(item.label);
    });
    // this.columns = this.fieldList.filter(obj => { return obj.isShow == undefined || obj.isShow; });
    this.columnOptions = Object.assign([], this.defualtColumns);
    this.columnOptions.forEach(item => {
      if (item.isShow == undefined || item.isShow) {
        this.staticColumns.push(item.label);
        this.allColumns.push(item.label);
      }
    });
  },
  computed: {

  },
  components: {
    draggable
  },
  methods: {
    handleCheckAllChange (val) {
      this.checkedColumns = val ? this.staticColumns : [];
      this.isIndeterminate = false;
    },
    handleCheckedList (val) {
      this.checkedColumns = val;

      console.log('this.checkedColumns ', this.checkedColumns);
      let checkedCount = val.length;
      this.checkAll = checkedCount === this.columns.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.columns.length;
    },
    recoverChecked () {
      this.checkedColumns = this.staticColumns;
      this.columns = this.checkedColumns;
      console.log('this.checkedColumns', this.checkedColumns);
      // this.fieldList.forEach(items => {
      //   let isHas = this.checkedColumns.findIndex(item => {
      //     return item == items.label;
      //   });
      //   if (isHas > -1) {
      //     items.isShow = true;
      //   } else {
      //     items.isShow = false;
      //   }
      // });
    },
    submit () {
      // 更新表格展示列
      // this.fieldList.sort((a, b) => {
      //   const aKey = a.label;
      //   const bKey = b.label;
      //   console.log('aKey', aKey, bKey, Object.keys(a));
      //   if (this.columns.indexOf(aKey) == -1) {
      //     return false;
      //   }
      //   if (this.columns.indexOf(bKey) == -1) {
      //     return true;
      //   }
      //   return this.columns.indexOf(aKey) - this.columns.indexOf(bKey);
      // });
      // this.fieldList.forEach(item => {
      //   item.isShow = false;
      // });
      // this.fieldList.forEach(items => {
      //   let isHas = this.columns.findIndex(item => {
      //     return item == items.label;
      //   });
      //   if (isHas > -1) {
      //     items.isShow = true;
      //   }
      // });
      // this.fieldList = JSON.parse(JSON.stringify(this.fieldList));
      // console.log('this.fieldList', this.fieldList);
      this.$emit('afterSuccess', this.columns);
      this.$emit('close');
    },
    close () {
      this.$emit('close');
    }
  }
};
</script>

<style>
</style>