<template>
  <div class="from_list" :class="required?'from_list_required':''">
    <div class="from_list_title">{{titleName}}</div>
    <div class="from_list_content">
      <div class="WKT_discount_package_blcok">
        <add-box :titleName="titleName"
                 @onAddBoxClick="onAddSelect"></add-box>
      </div>
    </div>
  </div>
</template>

<script>
import addBox from './add-box';
export default {
  components: { addBox },
  props: {
    titleName: {//  标题 （选填）
      type: String,
      default: ''
    },
    TableID: {//  标题 （选填）
      type: [Number, String],
      default: 0
    },
    required: {//  标题 （选填）
      type: Boolean,
      default: false
    }

  },
  methods: {
    onAddSelect () {
      this.$emit('onAddSelect');
    }
  }

};
</script>

<style>
</style>