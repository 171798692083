<template>
  <div class="investorAdvisorWorkbench all_workbench_left box_withe_radius"
       :style='{width:vTableWidth}'>
    <div class="content_top">
      <div class="statistics-box">
        <statistics-box :dataList="statisticsData"
                        @clickEvent="clcikStatictisItem"></statistics-box>
      </div>
      <div class="all_workbench_left_search_condition"
           v-if="isShowAll">
        <div class="search_item">
          <el-select-option :selectTitle="'意向级别'"
                            :dataSourceList="intentionLevels"
                            :selectionKey="paramsForQuery.IntentionKey"
                            @changeEvent="chooseIntention"></el-select-option>
        </div>
        <div class="search_item">
          <el-select-option :selectTitle="'签单进度'"
                            :dataSourceList="signBillPlan"
                            :selectionKey="paramsForQuery.StatusKey"
                            @changeEvent="chooseSignBillPlan"></el-select-option>
        </div>
        <div class="search_item">
          <date-range ref="saveDate"
                      :searchObj="vSearchDataRange"
                      :maxDate="$utils.formatDateToLine(Date.new())"
                      :fromTitle="'添加日期'"
                      @changeDate="changeDate"></date-range>
        </div>
        <div class="search_item">
          <div class="import_investor btn_hover_bg_blue"
               @click="exportClick">导出</div>
        </div>
      </div>
    </div>
    <div class="all_workbench_left_table table_list_content scroll_type"
         :style="{paddingRight:vTablePaddingRight}">
      <el-table element-loading-text="拼命加载中"
                class="table_list"
                ref="multipleTable"
                :data="tableData"
                highlight-current-row
                stripe
                :border="false"
                empty-text
                toggleRowSelection
                style="width: 100%;"
                @scroll="scrollEvent"
                @row-click="clickRow"
                max-height="670">
        <!-- :default-sort="defaultSort" -->
        <component v-for="columnInfo in vTableColumns"
                   :key="columnInfo.prop"
                   v-bind:is="columnInfo.type"
                   :columnData="columnInfo"
                   :paramsForQuery="paramsForQuery"
                   @searchEvent="queryTableEvent"
                   @clearAllOptions="clearSearchParams"></component>
        <div slot="empty"
             style="height:440px;line-height:440px;">
          <i class="el-icon-warning-outline">
            <div class="monitoring_content_nodata"></div>
            亲，当前没有任何数据哦~
          </i>
        </div>
      </el-table>
      <div class="info_btn"
           @click="addInvestor">
        添加投资人
      </div>
    </div>

    <div class="left_btn"
         @click="expandTableList"
         :class="{left_btn_openRight:isShowAll}"> </div>
  </div>
</template>

<script>
import elSelectOption from '../../../../report/common/el-select-option';// 下拉选择框
import statisticsBox from '../../../../report/common/statistics-box';// 统计栏
import textItem from '../../../../common/table-list/column/text-item';// 文本
import tableBtn from '../../../../common/table-list/column/table-btn';// 操作

import followUpRecordColumn from './common/follow-up-record-column';// 跟单记录
import queryColumn from './common/query-column';// 文本

import { hqSearchPageAttractThread } from '../../../../../API/workbench';
export default {
  components: {
    queryColumn,
    followUpRecordColumn,
    textItem,
    tableBtn,
    elSelectOption,
    statisticsBox
  },
  data () {
    return {
      statisticsData: {
        stayFollow: { title: '待跟进', value: 0, type: 1, isSelection: true },
        all: { title: '全部', value: 0, type: 0, isSelection: false }
      },
      isShowAll: false,
      tableColumns: [
        {
          label: '',
          prop: 'MainDemoName',
          width: 322,
          type: 'query-column',
          extend: {
            MobilePhone: 'MobilePhone',
            CityKeyValue: 'CityKeyValue',
            ProvinceKeyValue: 'ProvinceKeyValue',
            IntentionKeyValue: 'IntentionKeyValue', // 最近跟单意向级别 1-S;2-A;3-B;4-C
            StatusKey: 'StatusKey',
            StatusKeyValue: 'StatusKeyValue', // 1-首次跟进;2-日常跟进;3-预约到店;4-到店考察;5-档案完善;6-签约;7-付款;8-放弃;9-无效线索
            NextFollowTime: 'NextFollowTime'// 下次跟进时间
          }
        },
        {
          label: '添加日期',
          prop: 'EntryTime',
          type: 'text-item',
          width: 150,
          isShow: false
        },
        {
          label: '来源方式',
          prop: 'SourceTypeKeyValue',
          width: 110,
          isShow: false,
          type: 'text-item'
        },
        {
          label: '签单进度',
          prop: 'StatusKeyValueText',
          type: 'text-item',
          width: 90,
          isShow: false
        },
        {
          label: '跟进记录',
          prop: 'Content',
          type: 'follow-up-record-column',
          width: 'auto',
          isShow: false,
          extend: {
            FollowTime: 'FollowTime' // 最近跟进时间
          }
        },
        {
          label: '下次跟进',
          prop: 'NextFollowTimeText',
          type: 'text-item',
          width: 100,
          isShow: false
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          width: 160,
          isShow: false,
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  click: (rowData) => { this.lookUpDetail(rowData); }
                }
              },
              {
                name: '签单',
                extend: {
                  isHide: (rowData) => {
                    if (Number(rowData.IsSignedKey) == 0) { // 是否已签单
                      return false;
                    } else {
                      return true;
                    }
                  },
                  click: (rowData) => { this.openSignForm(rowData); }
                }
              },
              {
                name: '付款',
                extend: {
                  isHide: (rowData) => {
                    if (Number(rowData.IsPay) == 1) { // 是否显示付款
                      return false;
                    } else {
                      return true;
                    }
                  },
                  click: (rowData) => { this.openSignForm(rowData); }
                }
              }
            ]
          }
        }
      ],
      paramsForQuery: {
        pageIndex: 0, //	页码，0为第一页
        pageSize: 15, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        TypeKey: 1, // 0-全部;1-待跟进;2-待推动
        IntentionKey: 0, //	整型	可选	0	数据源：GET	意向级别 1-S;2-A;3-B;4-C
        StatusKey: 0, //	整型	可选	0	数据源：GET	签单进度 2-跟进中;3-已预约;4-已考察;5-已签单;6-已付订;7-已全款
        QueryID: 0,
        AddToDateStart: '',
        AddToDateEnd: ''
      },
      noMore: false, // 是否还可加载更多数据
      tableData: [],
      intentionLevels: [ // 意向级别1-S;2-A;3-B;4-C
        {
          OLAPKey: 0,
          MainDemoName: '全部'
        },
        {
          OLAPKey: 1,
          MainDemoName: 'S'
        },
        {
          OLAPKey: 2,
          MainDemoName: 'A'
        },
        {
          OLAPKey: 3,
          MainDemoName: 'B'
        },
        {
          OLAPKey: 4,
          MainDemoName: 'C'
        }
      ],
      signBillPlan: [
        { OLAPKey: 0, MainDemoName: '全部' },
        { OLAPKey: 1, MainDemoName: '新线索' },
        { OLAPKey: 2, MainDemoName: '跟进中' },
        { OLAPKey: 3, MainDemoName: '已预约' },
        { OLAPKey: 4, MainDemoName: '已考察' },
        { OLAPKey: 5, MainDemoName: '已签单' },
        { OLAPKey: 6, MainDemoName: '已付订' },
        { OLAPKey: 7, MainDemoName: '已全款' }
      ], // 签单进度
      isLoading: false, // 用于限制滚动加载
      curSelectedAdvisor: {}
    };
  },
  computed: {
    vTableColumns () {
      let columns = this.tableColumns;
      if (!this.isShowAll) {
        columns = [columns[0]];
      }
      return columns;
    },
    vTableWidth () {
      let width = '322px';
      if (this.isShowAll) {
        width = '100%';
      }
      return width;
    },
    vTablePaddingRight () {
      let paddingRight = '0';
      if (this.isShowAll) {
        paddingRight = '22px';
      }
      return paddingRight;
    },
    vSearchDataRange () {
      return {
        startTime: this.paramsForQuery.AddToDateStart,
        endTime: this.paramsForQuery.AddToDateEnd
      };
    }
  },
  created () {
    this.initData();
  },
  activated () {
    // this.initData();
  },
  methods: {
    initData () {
      let outSideParams = this.$route.params.data || {};
      console.log('this.$route.params.data.', outSideParams);
      if (outSideParams && Object.keys(outSideParams).length > 0) {
        let type = outSideParams.type;
        for (var i in this.statisticsData) {
          if (i == type) {
            this.statisticsData[i].isSelection = true;
          } else {
            this.statisticsData[i].isSelection = false;
          }
        }
        this.initParamsForQuery();
        if (outSideParams.dataKey > 0) {
          this.paramsForQuery.QueryID = outSideParams.dataKey;
        }
        if (type == 'stayFollow') {
          this.paramsForQuery.TypeKey = 1;
        } else if (type == 'all') {
          this.paramsForQuery.TypeKey = 0;
        }
      }
      this.loadInvestorList();
    },
    // 跟单 推荐后 列表更新
    updataData (OLAPKey) {
      let params = {
        pageIndex: 0, //	页码，0为第一页
        pageSize: 15, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        TypeKey: this.paramsForQuery.TypeKey, // 0-全部;1-待跟进;2-待推动
        IntentionKey: 0, //	整型	可选	0	数据源：GET	意向级别 1-S;2-A;3-B;4-C
        StatusKey: 0, //	整型	可选	0	数据源：GET	签单进度 2-跟进中;3-已预约;4-已考察;5-已签单;6-已付订;7-已全款
        QueryID: 0,
        AddToDateStart: '',
        AddToDateEnd: ''
      };
      params.QueryID = OLAPKey;
      hqSearchPageAttractThread(params).then(result => {
        this.statisticsData.all.value = result.data.AttractThreadCount;
        this.statisticsData.stayFollow.value = result.data.ToBeFollowUpCount;
        let idx = this.tableData.findIndex(obj => { return obj.OLAPKey == OLAPKey; });
        let data = result.data.PageDataList && result.data.PageDataList[0];
        if (data) {
          if (idx > -1) {
            data.IntentionKeyValue = (data.IntentionKey > 0 && data.IsValidKey > 0) ? data.IntentionKeyValue : 'N';
            Object.assign(this.tableData[idx], data);
          }
        } else {
          this.tableData.splice(idx, 1);
        }
      });
    },
    // 加载招商顾问列表
    loadInvestorList () {
      hqSearchPageAttractThread(this.paramsForQuery).then(result => {
        this.noMore = result.data.PageDataList.length < this.paramsForQuery.pageSize;
        console.log('招商顾问列表', result.data);
        this.statisticsData.all.value = result.data.AttractThreadCount;
        this.statisticsData.stayFollow.value = result.data.ToBeFollowUpCount;

        result.data.PageDataList.forEach(obj => {
          obj.IntentionKeyValue = (obj.IntentionKey > 0 && obj.IsValidKey > 0) ? obj.IntentionKeyValue : 'N';
          // obj.locationText = (obj.ProvinceKey ? obj.ProvinceKeyValue + '·' + obj.CityKeyValue : '');// '广州·深圳';
          obj.EntryTime = obj.EntryTime ? obj.EntryTime.slice(0, 10) : '';
          obj.NextFollowTimeText = obj.NextFollowTime ? obj.NextFollowTime.slice(5, 10) : '';
          if (Number(obj.StatusKey) < 5) { // 0-待接收;1-新线索;2-跟进中;3-已预约;4-已考察;5-已签单;6-已付订;7-已全款
            obj.StatusKeyValueText = `<span class="type_yellow">${obj.StatusKeyValue}</span>`;
          } else if (Number(obj.StatusKey) == 5 || Number(obj.StatusKey) == 6) {
            obj.StatusKeyValueText = `<span class="type_aqua">${obj.StatusKeyValue}</span>`;
          } else if (Number(obj.StatusKey) == 7) {
            obj.StatusKeyValueText = `<span class="type_green">${obj.StatusKeyValue}</span>`;
          }
        });
        if (this.tableData.length < result.data.Total) {
          result.data.PageDataList.forEach((e, index) => {
            let idx = this.tableData.findIndex(obj => { return obj.OLAPKey == e.OLAPKey; });
            if (idx < 0) {
              this.tableData.push(e);
            } else {
              this.tableData.splice(idx, 1, e);
            }
          });
          this.tableData.sort((pre, next) => { return Number(pre.Number) - Number(next.Number); });
          console.log('this.tableData::', this.tableData);
        }
        if (this.paramsForQuery.pageIndex == 0 && this.tableData.length > 0) {
          if (this.curSelectedAdvisor.OLAPKey > 0) {
            let idx = this.tableData.findIndex(obj => { return obj.OLAPKey == this.curSelectedAdvisor.OLAPKey; });
            if (idx >= 0) {
              this.$refs.multipleTable.setCurrentRow(this.tableData[idx]);
              this.clickRow(this.tableData[idx]);
            }
          } else {
            this.$refs.multipleTable.setCurrentRow(this.tableData[0]);
            this.clickRow(this.tableData[0]);
          }
        }
        if (this.paramsForQuery.pageIndex == 0 && result.data.Total > result.data.PageDataList.length) {
          this.mountScrollEvent();
        }
        this.isLoading = false;
      });
    },
    // 选中行
    clickRow (row, column, event) {
      this.curSelectedAdvisor = row;
      this.$emit('chooseInvestor', row);
    },
    // 点击投资人分类
    clcikStatictisItem (item) {
      this.initParamsForQuery();
      this.paramsForQuery.TypeKey = item.type;
      this.curSelectedAdvisor = {};
      this.loadInvestorList();
    },
    // 重置查询条件
    resetParamsForQuery () {
      this.tableData = [];
      this.paramsForQuery.pageIndex = 0;
      this.paramsForQuery.QueryID = 0;
    },
    // 重置查询条件
    initParamsForQuery () {
      this.tableData = [];
      this.paramsForQuery.searchText = '';
      this.paramsForQuery.IntentionKey = 0; // 意向级别 1-S;2-A;3-B;4-C
      this.paramsForQuery.StatusKey = 0;// 行业
      this.paramsForQuery.AddToDateStart = '';
      this.paramsForQuery.AddToDateEnd = '';
      this.paramsForQuery.QueryID = 0;
      this.paramsForQuery.pageIndex = 0;
    },
    // 清除条件查询
    clearSearchParams () {
      this.initParamsForQuery();
      this.loadInvestorList();
    },
    // 点击表头查询
    queryTableEvent (searchText) {
      this.resetParamsForQuery();
      this.paramsForQuery.searchText = searchText;
      this.loadInvestorList();
    },
    // 投资人列表展开收起
    expandTableList () {
      this.isShowAll = !this.isShowAll;
      this.loadInvestorList();
      this.$emit('expandTableList', this.isShowAll);
    },
    // 详情
    lookUpDetail (rowData) {
      this.isShowAll = !this.isShowAll;
      this.$emit('chooseInvestorDetails', this.isShowAll);
      rowData.Number = Number(rowData.Number);
      this.$refs.multipleTable.setCurrentRow(this.tableData[rowData.Number - 1]);
      this.clickRow(rowData);
    },
    // 签单
    openSignForm (rowData) {
      this.$dialog.open(this, {
        name: '签单申请',
        routerName: this.$route.name,
        moduleName: 'crmSignContractForm',
        data: { dataInfo: rowData, isReadonly: false },
        callBack: {
          afterSuccess: () => {
            this.loadInvestorList();
          }
        }
      });
    },
    // 付费
    openPaymentForm (rowData) {
      console.log(rowData, '付费');
      this.$dialog.open(this, {
        name: '付款申请', // 新增传付款申请， 审核传付款审核
        routerName: this.$route.name,
        moduleName: 'crmPaymentForm',
        data: {
          OLAPKey: Number(rowData.OLAPKey),
          isReadonly: false,
          paymentType: 'add'
        }, // OLAPKey 投资线索ID， isReadonly是否只读，paymentType为add时为新增，other时为审核，审核会显示收支账户收支科目
        callBack: {
          afterSuccess: () => {
            this.afterSuccess(rowData);
          }
        }
      });
    },
    // 选择意向级别
    chooseIntention (item) {
      this.paramsForQuery.IntentionKey = item.OLAPKey;
      this.resetParamsForQuery();
      this.loadInvestorList();
    },
    // 签单进度
    chooseSignBillPlan (item) {
      this.paramsForQuery.StatusKey = item.OLAPKey;
      this.resetParamsForQuery();
      this.loadInvestorList();
    },
    // 改变日期
    changeDate (dataInfo) {
      this.paramsForQuery.AddToDateStart = dataInfo.startTime;
      this.paramsForQuery.AddToDateEnd = dataInfo.endTime;
      this.resetParamsForQuery();
      this.loadInvestorList();
    },
    // 导出
    exportClick () {
      if (this.tableData.length > 0) {
        var obj = this.$utils.parseJson(this.paramsForQuery);
        obj.IsExportKey = 1;
        obj.pageIndex = 0;
        obj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '招商顾问工作台',
          clist: [
            { title: '意向级别', cName: 'IntentionKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '投资人', cName: 'MainDemoName', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '最近跟进类型', cName: 'TypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '手机号', cName: 'MobilePhone', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '省份', cName: 'ProvinceKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '城市', cName: 'CityKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '添加日期', cName: 'EntryTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '来源方式', cName: 'SourceTypeKeyValue', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '签单进度', cName: 'StatusKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '最近跟进时间', cName: 'FollowTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '最近跟进内容', cName: 'Content', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '下次跟进时间', cName: 'NextFollowTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        hqSearchPageAttractThread(obj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    // 给table挂载scroll事件
    mountScrollEvent () {
      function findElement () {
        let element = document.querySelector('.investorAdvisorWorkbench .el-table__body-wrapper');
        if (element) {
          return element;
        } else {
          findElement();
        }
      }
      let ele = findElement();
      ele.addEventListener('scroll', (e) => {
        this.scrollEvent(e);
      });
    },
    // 滚动条事件 addClewForm
    scrollEvent (e) {
      let ETarget = e.target;
      if ((ETarget.scrollTop + ETarget.clientHeight >= ETarget.scrollHeight) && !this.noMore && !this.isLoading) {
        this.isLoading = true;
        this.paramsForQuery.pageIndex++;
        this.loadInvestorList();
      }
    },
    // 添加投资人
    addInvestor () {
      this.$dialog.open(this, {
        name: '添加投资人',
        routerName: this.$route.name,
        moduleName: 'addClewForm',
        ModulePowerKey: '',
        data: {
          initCounselorKey: true
        },
        callBack: {
          afterSuccess: () => {
            console.log('添加投资人成功');
            this.loadInvestorList();
          }
        }
      });
    }
  }
};
</script>

<style>
.investorAdvisorWorkbench .el-table tbody tr:last-child td {
  border-bottom: 1px solid rgb(235, 238, 245);
}
</style>

<style scoped>
.all_workbench_left {
  min-width: 322px;
  height: 821px;
  position: relative;
  margin-right: 20px;
}
.content_top {
  display: flex;
}
.statistics-box {
  width: 322px;
}
.all_workbench_left_search_condition {
  height: 50px;
  display: flex;
}
.search_item {
  margin-top: 28px;
  margin-right: 10px;
}
.all_workbench_left .left_btn {
  position: absolute;
  top: 371px;
  right: -22px;
  width: 22px;
  height: 80px;
  background: #8d96a9
    url(../../../../../../public/image/arrows_withe_bottom_icon.png) no-repeat
    center center;
  background-size: 18px;
  border-radius: 0 21px 21px 0;
}
.all_workbench_left .left_btn_openRight {
  transform: rotate(180deg);
  right: 0;
}
.all_workbench_left .info_btn {
  width: 219px;
  height: 35px;
  line-height: 32px;
  text-align: center;
  color: #3498db;
  background: #f9fbfd;
  border: 1px solid #e8ebef;
  border-radius: 4px;
  position: relative;
  padding-left: 20px;
  top: 22px;
  margin-left: 50px;
}

.all_workbench_left .info_btn::before {
  content: '';
  position: absolute;
  left: 73px;
  top: 16px;
  width: 10px;
  height: 2px;
  border-radius: 1px;
  background-color: #3498db;
}

.all_workbench_left .info_btn::after {
  content: '';
  position: absolute;
  left: 77px;
  top: 12px;
  width: 2px;
  height: 10px;
  border-radius: 1px;
  background-color: #3498db;
}
.search_item .import_investor {
  height: 35px;
  width: 56px;
  line-height: 35px;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  right: 20px;
}
</style>

<style>
.investorAdvisorWorkbench .el-table__body-wrapper {
  border: none;
}
.search_item .table_select_box .el-select {
  width: 150px;
}
.box_withe_radius {
  background-color: #fff;
  border-radius: 8px;
}
.all_workbench_left .table_list_content .el-table th .cell {
  color: #909399;
  line-height: 50px;
  height: 100%;
}
.all_workbench_left_table
  .el-table__body
  tr.current-row
  > td.el-table__cell:first-child
  .cell:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #3498db;
}
.all_workbench_left_table
  .el-table__body
  tr.current-row
  > td.el-table__cell
  .cell
  span {
  color: #3498db;
}
.all_workbench_left_table .el-table .el-table__row td:first-child .cell {
  padding-left: 22px;
  position: relative;
  height: 60px;
}
.all_workbench_left_table .el-table .el-table__row td:first-child {
  padding: 0;
}

.all_workbench_left .table_list_content .el-table thead:after {
  /* margin-top:0 */
}
.all_workbench_left .table_list_content .el-table thead:after {
  height: 0;
}
.all_workbench_left_table .rank_label.rank_new {
  background-color: #fc7345;
}

.all_workbench_left_table .rank_label.rank_S {
  background-color: #282828;
}

.all_workbench_left_table .rank_label.rank_A {
  background-color: #d0a487;
}

.all_workbench_left_table .rank_label.rank_B {
  background-color: #d8b376;
}

.all_workbench_left_table .rank_label.rank_C {
  background-color: #a6a2a3;
}
.all_workbench_left_table .rank_label.rank_number {
  background-color: #78d1b5;
}
.all_workbench_left_table .rank_label {
  width: 28px;
  height: 21px;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  color: #fff;
  border-radius: 3px;
}
.all_workbench_left_table .cell.el-tooltip .type_yellow {
  width: 55px;
  height: 22px;
  background: #f9e0c7;
  border-radius: 6px;
  padding: 5px;
  line-height: 22px;
  color: #333 !important;
}
.all_workbench_left_table .cell.el-tooltip .type_aqua {
  width: 55px;
  height: 22px;
  background: #bcebdc;
  border-radius: 6px;
  padding: 5px;
  line-height: 22px;
  color: #333 !important;
}
.all_workbench_left_table .cell.el-tooltip .type_green {
  width: 55px;
  height: 22px;
  background: #78d1b5;
  border-radius: 6px;
  padding: 5px;
  line-height: 22px;
  color: #333 !important;
}
.all_workbench_left_table .el-table thead tr th {
  height: 51px;
  padding: 0;
  line-height: 24px;
}

.all_workbench_left_table .el-table thead tr th:first-child .cell {
  padding: 0;
}
.all_workbench_left_table .el-table th:after {
  content: '';
  position: absolute;
  width: 1px;
  top: 9px;
  bottom: 9px;
  background-color: #e7e9e8;
  right: 0;
  z-index: 11;
}
.all_workbench_left_table .el-table .el-table__body-wrapper tr {
  color: #333;
}
</style>


