<template>
  <div>
    <div class="general_summarizing_student">
      <div class="payment_statistics_bottom_select flex">
        <!-- <div class="table_select_box">
          <div class="table_select_title">收支类型</div>
          <el-select :value="vIncomePayTypeKeyValue"
                     filterable
                     style="width:240px"
                     placeholder="必选"
                     @change="typeEvent"
                     value-key="OLAPKey">
            <el-option v-for="item in typeList"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item"></el-option>
          </el-select>
        </div> -->
        <div class="table_select_box">
          <div class="table_select_title">收支账户</div>
          <el-select :value="vIncomePayAccountKeyValue"
                     filterable
                     style="width:240px"
                     placeholder="必选"
                     @change="accountEvent"
                     value-key="OLAPKey">
            <el-option v-for="item in incomePayAccountList"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item"></el-option>
          </el-select>
        </div>
        <div class="table_select_box">
          <div class="table_select_title">收支科目</div>
          <el-select :value="vIncomePaySubKeyValue"
                     filterable
                     style="width:240px"
                     placeholder="必选"
                     @change="subjectEvent"
                     value-key="OLAPKey">
            <el-option v-for="item in subjectListS"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item"></el-option>
          </el-select>
        </div>
      </div>
      <div class="course_statistics_table_search flex">
        <date-range ref="saveDate"
                    :searchObj="searchObj"
                    :fromTitle="'选择日期'"
                    :maxDate="$utils.formatDateToLine(Date.new())"
                    @changeDate="changeSaveDate"></date-range>
        <div class="course_statistics_search">
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入经办人、发生人、备注'"
                                    :clearAll="true"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="search"></input-search-contain-btn>
          <div class="mendian_backstage_search">
            <div class="btn_hover_bg_blue search_btn "
                 style="margin: 0;"
                 @click.stop="doExportInfoClick">导出</div>
          </div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <list class="summarizing_list"
            :key="tableKey"
            :tableData="vdataReportList"
            :totalNum="totalNum"
            :PageCount="PageCount"
            :tableColumns="tableCourseColumns"
            :isSearchText="false"
            :isNeedInnerScroll="false"
            :defaultSort="defaultSort"
            :queryParams="searchObj"
            :footerContent="vFooterTotal"
            @loadTableData="getDataList"></list>
    </div>
    <div v-if="vPayTypeList.length > 0"
         class="payment_statistics_bottom_ul">
      <acount-item v-for="(item,key) in vPayTypeList"
                   :key="key"
                   :item="item">
      </acount-item>
    </div>
  </div>
</template>
<script>
import list from '../../../common/table-list/index';
import acountItem from './acount-item';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import { MessageSearchInComePayReportForOther, getIncomeAccount, getIncomeSubject } from '../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        IncomePayAccountKey: '',
        IncomePayAccountKeyValue: '',
        IncomePaySubKey: '',
        IncomePaySubKeyValue: '',
        startTime: '',
        endTime: '',
        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          decimalPlace: 0,
          className: 'table_index_gray'
        },
        {
          label: '收支流水',
          prop: 'SourceKeyName',
          minWidth: 120,
          sortable: '',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0

        },
        {
          label: '发生日期',
          prop: 'GenerateTime',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0

        },
        {
          label: '经办人',
          prop: 'AgentKeyValue',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item',
          decimalPlace: 0
        },
        {
          label: '收支账户',
          prop: 'IncomePayAccountKeyValue',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item',
          decimalPlace: 0
        },
        {
          label: '收支科目',
          prop: 'IncomePaySubKeyValue',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item',
          decimalPlace: 0
        },
        {
          label: '收支金额',
          prop: 'InAmount',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0

        },
        {
          label: '发生人',
          prop: 'HapPeolKeyValue',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          eventName: '4'
        },
        {
          label: '备注',
          prop: 'CommentSupport',
          minWidth: 126,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          eventName: '4'
        }

      ],
      dataReportList: [],
      PayTypeReport: [],
      incomePayAccountList: [],    // 收支账户列表
      IncomePaySubjectList: [],    // 收支科目列表
      isDebtCourseHoursDetailsShow: false,
      typeList: [
        {
          MainDemoName: '全部',
          OLAPKey: 0
        },
        {
          MainDemoName: '收',
          OLAPKey: 1
        }, {
          MainDemoName: '支',
          OLAPKey: 2
        }
      ]
    };
  },
  components: {
    list,
    acountItem,
    inputSearchContainBtn
  },
  props: {
    searchParams: Object
  },
  created () {
    this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
    this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
  },
  computed: {

    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      return '共' + this.totalNum + '笔，合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
    },
    vPayTypeList () {
      let dataInfo = {};
      this.PayTypeReport.forEach((item, index) => {
        let { IncomePayAccountKey } = item;
        let { IncomePayAccountKeyValue } = item;
        let { AccountTypeKey } = item;
        if (!dataInfo[IncomePayAccountKey]) {
          dataInfo[IncomePayAccountKey] = {
            IncomePayAccountKey,
            IncomePayAccountKeyValue,
            AccountTypeKey,
            child: []
          };
        }
        dataInfo[IncomePayAccountKey].child.push(item);
      });
      let list = Object.values(dataInfo); // list 转换成功的数据
      console.log(list);
      return list;
    },
    // 过滤收科目(收)
    subjectListS () {
      var SubjectResult = [];
      this.IncomePaySubjectList.map(item => {
        // if (item.IncomePayTypeKey == 1) {
        SubjectResult.push(item);
        // }
      });
      return SubjectResult;
    },
    // 过滤支科目(支)
    subjectListZ () {
      var SubjectResult = [];
      this.IncomePaySubjectList.map(item => {
        if (item.IncomePayTypeKey == 2) {
          SubjectResult.push(item);
        }
      });
      return SubjectResult;
    },
    vIncomePaySubKeyValue () {
      return this.searchObj.IncomePaySubKey == 0
        ? '全部'
        : this.searchObj.IncomePaySubKeyValue;
    },
    vIncomePayAccountKeyValue () {
      return this.searchObj.IncomePayAccountKey == 0
        ? '全部'
        : this.searchObj.IncomePayAccountKeyValue;
    },
    vIncomePayTypeKeyValue () {
      return this.searchObj.IncomePayTypeKey == 0
        ? '全部'
        : this.searchObj.IncomePayTypeKeyValue;
    }
  },
  mounted () {
    this.getConnectorData();
    console.log('mounted', this.searchParams);
    if (this.searchParams) {
      if (this.searchParams.SetUpTime) {      // 日报表
        this.searchData(this.searchParams.SetUpTime);
      } else {        // 月报表
        this.searchData(this.searchParams.startTime, this.searchParams.endTime);
      }
    } else {
      this.getDataList();
    }
  },
  methods: {
    // 收支录入-数据源
    getConnectorData () {
      // 获取收支账户接口
      getIncomeAccount().then(result => {
        if (result.code == 0) {
          console.log('getIncomeAccount', result.data);
          this.incomePayAccountList = result.data;
          this.incomePayAccountList.unshift({
            MainDemoName: '全部',
            OLAPKey: 0
          });
        } else {
          layer.alert('接口异常，注意检查');
        }
      });
      // 获取收支科目接口
      getIncomeSubject().then(result => {
        if (result.code == 0) {
          this.IncomePaySubjectList = result.data;
          // this.IncomePaySubjectList.forEach(o => { });
          this.IncomePaySubjectList.unshift({
            MainDemoName: '全部',
            OLAPKey: 0
          });
        } else {
          layer.alert('接口异常，注意检查');
        }
      });
    },
    // 选中类型
    typeEvent (obj) {
      console.log(obj, '选中类型');
      this.searchObj.IncomePayTypeKeyValue = obj.MainDemoName;
      this.searchObj.IncomePayTypeKey = obj.OLAPKey;
      this.search();
    },
    // 选中账户
    accountEvent (obj) {
      console.log(obj, '收支账户');
      this.searchObj.IncomePayAccountKeyValue = obj.MainDemoName;
      this.searchObj.IncomePayAccountKey = obj.OLAPKey;
      this.search();
    },
    // 选中科目
    subjectEvent (obj) {
      console.log(obj, '收支科目');
      this.searchObj.IncomePaySubKeyValue = obj.MainDemoName;
      this.searchObj.IncomePaySubKey = obj.OLAPKey;
      this.search();
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime; ;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.GradeKey = '';
      this.searchObj.IncomePaySubKey = '';
      this.searchObj.IncomePayAccountKey = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (date, endDate) {
      console.log('searchData', date);
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = date;
      this.searchObj.endTime = endDate || date;
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '其他收入统计',
          clist: [
            { title: '收支流水', cName: 'SourceKeyName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '发生日期', cName: 'GenerateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '经办人', cName: 'AgentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '收支账户', cName: 'IncomePayAccountKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '收支科目', cName: 'IncomePaySubKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '收支金额', cName: 'InAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '发生人', cName: 'HapPeolKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '备注', cName: 'CommentSupport', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchInComePayReportForOther(searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      console.log(this.searchObj);
      MessageSearchInComePayReportForOther(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        this.PayTypeReport = result.data.PayTypeReport;
        console.log(result.data, 'MessageSearchInComePayReportForOther');
      }).catch(error => {
        console.log('MessageSearchInComePayReportForOther', error.msg);
      });
    },
    toApplyReport (item) {
      this.$emit('toApplyReport', item, this.searchObj);
    },
    toOtherIncomeReport (item) {
      this.$emit('toOtherIncomeReport', item, this.searchObj);
    },
    toExpenditureReport (item) {
      this.$emit('toExpenditureReport', item, this.searchObj);
    }

  }
};
</script>