<template>
  <div>
    <div class="shade"></div>
    <div class="class_grade_warning_popup">
      <div class="popup_close"
           @click.stop="closeTipPopUp">

      </div>
      <div class="popup_title">
        <span>班级冲突</span>
      </div>
      <div class="popup_text">
        多个老师同时上一门课，应分不同班级建课。
      </div>
      <div class="popup_btn_box"
           v-if="generatedClassTimeData.length==0">
        <div class="popup_btn"
             @click.stop="changeClassGrade">更换班级</div>
        <div @click.stop="newClassGrade"
             class="popup_btn">新建班级 </div>
      </div>
      <div class="popup_bottom"
           @click="backToEdit">
        返回修改
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    generatedClassTimeData: Array
  },
  methods: {
    closeTipPopUp () {
      this.backToEdit();
    },
    // 更换班级
    changeClassGrade () {
      this.$emit('changeClassGrade');
    },
    // 新建班级
    newClassGrade () {
      this.$emit('newClassGrade');
    },
    // 返回修改
    backToEdit () {
      this.$emit('backToEdit');
    }
  }
};
</script>

<style>
</style>