<template>
  <div class="content_list"
       :class="isShow?'opt':''"
       @click.stop="seletedItem"
       @mouseenter="showShade(true)"
       @mouseleave="showShade(false)">
    <div class="content_list_img">
      <img :src="vImg"
           :onerror="vDefaultImg"
           alt="">
      <div class="content_list_img_hot"
           v-if="item.isHot"></div>
      <div class="content_list_img_shade"
           v-if="isShow||item.isActive">
      </div>
      <div class="content_list_img_btn"
           v-if="isShow||item.isActive"
           @click.stop="seletedItem">立即制作</div>
    </div>
    <div class="content_list_info">
      <div class="content_list_title">
        <div class="content_list_type">{{item.SceneKeyValue}}</div>
        <div class="text_overflow_hidden">{{item.title}}</div>
      </div>
      <div class="content_list_label">
        <div class="content_list_label_text"
             v-for="(agenciesItem,key) in vAgenciesIDSNameList"
             :key="key">
          {{agenciesItem}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isShow: false
    };
  },
  props: {
    item: Object
  },
  computed: {
    vAgenciesIDSNameList () {
      let AgenciesIDSName = this.item.AgenciesIDSName || '';
      return AgenciesIDSName ? AgenciesIDSName.split(',') : [];
    },
    vImg () {
      if (!this.item.coverimg) {
        this.item.coverimg = '';
      }
      if (this.item.coverimg.indexOf('http') > -1) {
        return this.item.coverimg;
      } else {
        return this.$store.getters.CDNURL + this.item.coverimg;
      }
    },
    // 默认显示logo
    vDefaultImg () {
      return 'this.src="' + require('../../../../public/image/no_logo@2x.png') + '"';
    }
  },
  methods: {
    showShade (type) {
      this.isShow = type;
    },
    seletedItem () {
      this.$emit('seletedItem', this.item);
    }
  }
};
</script>
<style scoped>
.content_list {
  width: 236px !important;
  height: 288px !important;
}
.content_list:nth-child(5n) {
  margin-right: 20px !important;
}
</style>