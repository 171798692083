<template>
  <!-- 学生信息 -->
  <div class="register_form_top bg_white">
    <form-student-select-sex :dataInfo="customerInfo"
                             :placeholder="'学生名(必填)'"
                             :isPopupShow="isPopupShow"></form-student-select-sex>
    <div class="register_form_flex pr">
      <div class="form_info_border_input phone">
        <input-phone class="input_box"
                     :formTitle="' '"
                     :formPlaceholder="'手机号'"
                     v-model="customerInfo.MobileSupport"
                     :readonly="isReadonly||isPopupShow"
                     :required="false"></input-phone>
      </div>
      <div class="form_info_border_input form_info_select relation">
        <input-select :formTitle="''"
                      :id="'key'"
                      :name="'value'"
                      class="no_border_bottom"
                      :required="false"
                      :readonly="isReadonly"
                      :dataList="customerRelationship"
                      :selectedInfo="customerRelationshipInfo"
                      @selectedItem="chgCustomerRelationship"></input-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      customerRelationshipInfo: { id: 0, name: '家长' },
      customerRelationship: [
        { key: 0, value: '家长' },
        { key: 2, value: '爸爸' },
        { key: 3, value: '妈妈' },
        { key: 11, value: '本人' },
        { key: 10, value: '其他' }
      ]
    };
  },
  props: {
    customerInfo: Object,
    isReadonly: Boolean,
    isPopupShow: Boolean
  },
  computed: {

  },
  created () {
    console.log(this.customerInfo, 'this.customerInfo');
    this.customerRelationshipInfo.id = this.customerInfo.CustomerRelationshipKey;
    this.customerRelationshipInfo.name = this.customerInfo.CustomerRelationshipKeyValue;
  },
  methods: {
    // 选择家长关系: 0--家长;2--爸爸;3-妈妈;11-本人	;10-其他;
    chgCustomerRelationship (item) {
      this.customerRelationshipInfo.id = item.key;
      this.customerRelationshipInfo.name = item.value;
      this.customerInfo.CustomerRelationshipKey = this.customerRelationshipInfo.id;
      this.customerInfo.CustomerRelationshipKeyValue = this.customerRelationshipInfo.name;
    }
  }

};
</script>

<style scoped>
.input_box >>> .form_info_value {
  text-align: left !important;
}
</style>
