<template>
  <div class="top_right_list"
       @click="cilckEvent"
       :style="vCursor">
    <div class="list_title">{{sumData.title}}</div>
    <div class="list_number"
         :class="sumData.colorClass">{{vTextValue}}</div>
  </div>
</template>

<script>
export default {
  props: {
    sumData: {
      type: Object,
      required: true,
      default: () => {
        return { title: '标题名', value: 0, colorClass: '', decimalPlace: 0, isFilter: true }
      }
    }
  },
  computed: {
    vCursor () {
      if (Number(this.sumData.value) > 0 && this.sumData.isFilter) {
        return 'cursor: pointer';
      } else {
        return 'cursor: default';
      }
    },
    vTextValue () {
      if (Number(this.sumData.value) > 0) {
        return this.$utils.mAmountType(this.sumData.value, this.sumData.decimalPlace)
      } else if (this.sumData.decimalPlace >= 0 && Number(this.sumData.value) == 0) {
        return '-';
      } else {
        return this.sumData.value;
      }
    }
  },
  methods: {
    cilckEvent () {
      if (Number(this.sumData.value) > 0 && this.sumData.isFilter) {
        this.$emit('onCilckEvent', this.sumData);
      }
    }
  }
};
</script>

<style>
</style>