<template>
  <!-- 添加购买课单 -->
  <div style="hieight:100%"
       class="bg_gray clearfix">
    <!-- 选项卡 -->
    <option-card v-if="vIsSysVersionTY"
                 :cardList="optionCardList"
                 @dochgCardClick="dochgCardClick"></option-card>
    <div class="form_info_edit label_radio_bg_white ">
      <div class="mendian_setting_content">
        <input-dialog :formTitle="'适用课程'"
                      :customPlaceholder="true"
                      :formPlaceholder="fromInfo.type=='single'?'单选':'多选'"
                      :readonly="false"
                      :required="true"
                      @showEldilog="doCourseDialogShow"
                      v-model="newKDNRItem.ApplyCorName"></input-dialog>

        <input-number-unit :min="0"
                           :max='9999'
                           v-model="newKDNRItem.NumCount"
                           :decimalPlaces="cardItem.cardKey==5?1:0"
                           :formTitle="cardItem.cardKey==5?'课时数量':'有效期'"
                           :unit="vCardUnit"
                           :formInputHint="vFormInputHint"
                           :placeholder="'必填'"
                           :required="true"
                           @inputChange="ksDocourseChange"></input-number-unit>

        <!-- 日月年卡，不需要显示单价、折扣 -->
        <input-number-unit v-if="cardItem.cardKey==5"
                           :min="0"
                           :value="newKDNRItem.UnitPrice"
                           :decimalPlaces="2"
                           :unit="'元/课时'"
                           :formTitle="cardItem.cardKey==5?'课时单价':'单价'"
                           :placeholder="'必填'"
                           :required="true"
                           @inputChange="djDocourseChange"></input-number-unit>

        <input-dialog v-if="cardItem.cardKey==5"
                      :formTitle="'优惠折扣'"
                      :formPlaceholder="'必填'"
                      :readonly="false"
                      :required="true"
                      @showEldilog="isShowDiscountDialog=true"
                      :value="vDiscountCount"></input-dialog>

        <input-number-unit :min="0"
                           :max="cardItem.cardKey!=5?999999:$utils.mAmountType(newKDNRItem.ValueAmount>0?newKDNRItem.ValueAmount:newKDNRItem.UnitPrice,2)"
                           :value="newKDNRItem.AfterDisAmount"
                           :decimalPlaces="2"
                           :formTitle="'收费价格'"
                           :placeholder="'必填'"
                           :unit="'元'"
                           :required="true"
                           :readonly="false"
                           @inputChange="jgDocourseChange"></input-number-unit>
        <div class=" form_info_line form_info_required">
          <div class=" form_info_field">是否用做引流活动</div>
          <div class=" form_info_value ">
            <el-switch v-model="newKDNRItem.IsDrainageKey"
                       active-color="#49b5f3"
                       inactive-color="#a5b6c8"
                       active-value="1"
                       inactive-value="0">
            </el-switch>
          </div>
        </div>
      </div>
    </div>
    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmFromClick"
                             @cancelClick="closeFrom"></save-cancel-btn-group>
    </div>
    <!-- 报组合课 -->
    <custom-dialog :title="'选择组合课'"
                   :visible.sync="isShowMutilSelectCourseDialog"
                   :before-close="doclosemultiSelection">
      <btn-add-select :addTitleName="'新增'"
                      :addmoduleName="'course-name-setting'"
                      :addmoduleTitleName="'add'"
                      :addmoduleKey='45'
                      @doAddSuccessEvent="doAddSuccessEvent"></btn-add-select>
      <mutil-select-tree :sourceDate="courseTreeList"
                         :selectionDate="applyCourseDatas"
                         @buttonClickEvent="doAfterSelectedMultiItem"
                         @cancleClickForMutilSelected="doclosemultiSelection"
                         @firstSelectTreeEvent="doFirstSelectTreeEvent"></mutil-select-tree>
    </custom-dialog>

    <!-- 报课折扣 -->
    <custom-dialog title="折扣"
                   :visible.sync="isShowDiscountDialog"
                   :before-close="closeDiscountDialog">
      <form-discount v-model="newKDNRItem.DiscountCount"
                     @onConfirm="zkDocourseChange"
                     @onClose="closeDiscountDialog"></form-discount>
    </custom-dialog>

  </div>
</template> 

<script>
import { getCourseTypeNameList, GetTheClassType } from '../../../../../../API/workbench';
import mutilSelectTree from '../../../../../common/mutil-select-tree';
import optionCard from '../../common-module/option-card';
import inputNumber from '../../../../../base-component/input-number-unit';
export default {
  components: {
    mutilSelectTree,
    optionCard,
    inputNumber
  },
  props: {
    fromInfo: Object // 课程信息 （必填）
  },
  data () {
    return {
      isShowDiscountDialog: false, // 报课折扣弹窗
      isShowMutilSelectCourseDialog: false, // 组合课弹窗
      isValidityDate: false, // 有效期
      coursList: [], // 课名列表
      courseTreeList: [], // 课类列表
      optionCardList: [// 选项卡菜单
        { cardName: '课时卡', cardKey: 5, cardValue: '课时', isOpt: true },
        { cardName: '日卡', cardKey: 4, cardValue: '日', isOpt: false },
        { cardName: '月卡', cardKey: 3, cardValue: '月', isOpt: false },
        { cardName: '年卡', cardKey: 2, cardValue: '年', isOpt: false }
      ],
      cardItem: { cardName: '课时卡', cardKey: 5, cardValue: '课时', isOpt: true }, // 默认选项卡
      // 提交的组合课
      applyCourseDatas: {
        IDs: '',
        Names: ''
      },
      // 提交的组合课
      selectionMultiList: {
        IDs: '',
        Names: ''
      },
      newSelectionMultiListIDs: '',
      newKDNRItem: {
        TableID: 0, // 课程单ID
        ApplyCorIDName: '', // --适用课程ID;
        ApplyCorName: '', // --适用课程;
        NumCount: 1, // --数量;
        UnitKey: 5, // --单位(5-课时;4-日;3-月;2-年);
        UnitKeyValue: '课时', // ;
        UnitPrice: 0, // --单价;
        ValueAmount: 0, // --价值;
        DiscountCount: 10, // --折扣;
        DiscountAmount: 0, // --折扣金额;
        AfterDisAmount: '0', // --金额;
        IsDrainageKey: '0' // 是否做引流活动
      }
    };
  },
  created () {
    console.log(this.fromInfo, '单课、组合课信息');
    this.loadDataList();
    if (this.fromInfo.data && JSON.stringify(this.fromInfo.data) != '{}') { // 修改单课、组合课
      this.newKDNRItem = this.$utils.parseJson(this.fromInfo.data);
      this.cardItem.cardKey = this.fromInfo.data.UnitKey;
      this.cardItem.cardValue = this.fromInfo.data.UnitKeyValue;
      this.applyCourseDatas.IDs = this.fromInfo.data.ApplyCorIDName;
      this.applyCourseDatas.Names = this.fromInfo.data.ApplyCorName;
      this.optionCardList.forEach(o => { // 选项卡高亮
        if (o.cardKey == this.cardItem.cardKey) {
          o.isOpt = true;
        } else {
          o.isOpt = false;
        }
      });
    }
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
  },
  computed: {
    // 单位(5-课时;4-日;3-月;2-年);
    vFormInputHint () {
      if (this.cardItem.cardKey < 5 && Number(this.newKDNRItem.NumCount) > 0) {
        let units = this.cardItem.cardValue == '月' ? '个月' : this.cardItem.cardValue;
        return this.$utils.mAmountType(this.newKDNRItem.NumCount, 0) + units;
      } else {
        return '';
      }
    },
    vDiscountCount () {
      if (this.newKDNRItem.DiscountCount == 0) {
        return '赠送';
      } else if (this.newKDNRItem.DiscountCount == 10) {
        return '原价';
      } else {
        return this.$utils.mAmountType(this.newKDNRItem.DiscountCount, 1) + ' 折';
      }
    },
    vIsSysVersionTY () {
      console.log(this.$store.getters, '通用版--显示年月日卡');
      return this.$store.getters.isSysVersionTY && Number(this.$utils.getSaaSClientSet(35)) > 0;// true：通用版，false精准版
    },
    vCardUnit () {
      let unit = '课时';
      switch (Number(this.cardItem.cardKey)) {
        case 5:// 课时卡
          unit = '课时';
          break;
        case 4:// 日卡
          unit = '日';
          break;
        case 3:// 月卡
          unit = '月';
          break;
        case 2:// 年卡
          unit = '年';
          break;
        default:
          break;
      }

      return unit;
    }
  },
  methods: {
    // 选项卡
    dochgCardClick (item) {
      this.newKDNRItem.NumCount = 0;// 数量重置
      this.newKDNRItem.AfterDisAmount = 0;// 收费价格重置
      switch (Number(item.cardKey)) {
        case 5:// 课时卡
          this.newKDNRItem.UnitKey = 5; // (5-课时;4-日;3-月;2-年);
          this.newKDNRItem.UnitKeyValue = '课时';
          break;
        case 4:// 日卡
          this.newKDNRItem.UnitKey = 4;
          this.newKDNRItem.UnitKeyValue = '日';
          this.newKDNRItem.UnitPrice = 0;
          this.newKDNRItem.DiscountCount = 10;
          break;
        case 3:// 月卡
          this.newKDNRItem.UnitKey = 3;
          this.newKDNRItem.UnitKeyValue = '月';
          this.newKDNRItem.UnitPrice = 0;
          this.newKDNRItem.DiscountCount = 10;
          break;
        case 2:// 年卡
          this.newKDNRItem.UnitKey = 2;
          this.newKDNRItem.UnitKeyValue = '年';
          this.newKDNRItem.UnitPrice = 0;
          this.newKDNRItem.DiscountCount = 10;
          break;
        default:
          break;
      }
      this.cardItem = item;
    },
    // 报课回填
    ksDocourseChange (val) {
      let num = this.cardItem.cardKey == 5 ? 1 : 0;
      this.newKDNRItem.NumCount = this.$utils.mAmountType(val, num);
      // 1.课时，计算报课价格
      if (this.cardItem.cardKey != 5) { // 通用版，且为年月日卡
        this.newKDNRItem.DiscountCount = 10;
        this.newKDNRItem.UnitPrice = this.$utils.mAmountType(Number(this.newKDNRItem.AfterDisAmount) / Number(this.newKDNRItem.NumCount), 2);
      } else {
        if (this.newKDNRItem.UnitPrice) {
          this.newKDNRItem.AfterDisAmount = this.$utils.mAmountType(Number(this.newKDNRItem.NumCount * this.newKDNRItem.UnitPrice * (this.newKDNRItem.DiscountCount / 10)), 2);
        }
      }
    },
    // 单价回填
    djDocourseChange (val) {
      this.newKDNRItem.UnitPrice = Number(val);
      // 2.单价，计算报课价格
      if (this.newKDNRItem.NumCount) {
        this.newKDNRItem.AfterDisAmount = this.$utils.mAmountType(Number(this.newKDNRItem.NumCount * this.newKDNRItem.UnitPrice * (this.newKDNRItem.DiscountCount / 10)), 2);
      }
    },
    // 限制折扣最大为10折
    zkDocourseChange (val) {
      if (Number(val) > 10 && this.newKDNRItem.DiscountCount > 10) {
        this.zkDocourseChange(10);
        this.newKDNRItem.DiscountCount = 10;
      } else {
        this.newKDNRItem.DiscountCount = this.$utils.mAmountType(Number(val), 1);
        // 3.折扣，计算报课价格
        if (this.newKDNRItem.UnitPrice) {
          this.newKDNRItem.AfterDisAmount = this.$utils.mAmountType(Number(this.newKDNRItem.NumCount * this.newKDNRItem.UnitPrice * (this.newKDNRItem.DiscountCount / 10)), 2);
        }
      }
      this.closeDiscountDialog();
    },
    // 关闭折扣弹出层
    closeDiscountDialog (val) {
      this.isShowDiscountDialog = false;
    },
    // 报课价格
    jgDocourseChange (val) {
      this.newKDNRItem.AfterDisAmount = this.$utils.mAmountType(val, 2);
      if (this.cardItem.cardKey != 5) { // 通用版，且为年月日卡
        this.newKDNRItem.DiscountCount = 10;
        this.newKDNRItem.UnitPrice = this.$utils.mAmountType(Number(this.newKDNRItem.AfterDisAmount) / Number(this.newKDNRItem.NumCount), 2);
      } else {
        this.newKDNRItem.DiscountCount = this.$utils.parseIntType(this.newKDNRItem.AfterDisAmount / this.newKDNRItem.ValueAmount * 10, 10);
        if (this.newKDNRItem.DiscountCount > 10 || isNaN(this.newKDNRItem.DiscountCount)) {
          this.newKDNRItem.DiscountCount = 10;
        }
      }
      if (this.newKDNRItem.UnitPrice > 0 && this.newKDNRItem.NumCount > 0) {
        this.newKDNRItem.ValueAmount = this.$utils.mAmountType(Number(this.newKDNRItem.UnitPrice) * Number(this.newKDNRItem.NumCount), 2);
      }
    },
    // 选择适用课程
    doCourseDialogShow () {
      this.loadDataList();
      this.$nextTick(() => {
        if (this.fromInfo.type == 'single') {
          this.$dialog.open(this, {
            routerName: this.$route.name,
            name: '单选课',
            moduleName: 'singleSelectionList',
            data: { selectedKey: this.newKDNRItem.ApplyCorIDName, dataSourceList: this.coursList, addModuleName: 'course-name-setting' },
            callBack: {
              onSelectedItem: (data) => {
                this.doAfterSelectedSingleItem(data);
              }
            }
          });
        } else if (this.fromInfo.type == 'multi') {
          this.doFirstSelectTreeEvent(this.newKDNRItem.ApplyCorIDName, this.newKDNRItem.ApplyCorName);
          this.newSelectionMultiListIDs = this.newKDNRItem.ApplyCorIDName;// 未改动前的数据ID
          this.isShowMutilSelectCourseDialog = true;
        }
      });
    },
    // 单课-选择
    doAfterSelectedSingleItem (item) {
      console.log(item, 'this.CourseTypeNameList');
      this.getCourseheClassInfo(item.OLAPKey, 1); // 单价需要显示价格，组合课不需要
      this.newKDNRItem.ApplyCorIDName = item.OLAPKey;
      this.newKDNRItem.ApplyCorName = item.MainDemoName;
    },
    // 组合课-选择
    doFirstSelectTreeEvent (keys, vals) {
      this.selectionMultiList.IDs = keys;
      this.selectionMultiList.vals = vals;
    },
    // 组合课-确定
    doAfterSelectedMultiItem (selectionDate, callBack) {
      console.log(selectionDate, 'this.CourseTypeNameList');
      this.applyCourseDatas.IDs = '';
      this.applyCourseDatas.Names = '';
      if (selectionDate.selectedIDs.length == 0) {
        layer.alert('适用课程不能为空');
      } else {
        selectionDate.selectedIDs.forEach(o => {
          this.applyCourseDatas.IDs += o + ','; // 返课时类型课程IDs
        });
        selectionDate.selectedNames.forEach(o => {
          this.applyCourseDatas.Names += o + ','; // 返课时类型课程Values
        });
        this.newKDNRItem.ApplyCorIDName = this.applyCourseDatas.IDs.substring(0, this.applyCourseDatas.IDs.lastIndexOf(','));
        this.newKDNRItem.ApplyCorName = this.applyCourseDatas.Names.substring(0, this.applyCourseDatas.Names.lastIndexOf(','));
        if (callBack) {
          callBack();
        }
      }
      // 判断改动前的数据ID和改动后的ID是否相等，是否需要重新计算价格
      if (this.newSelectionMultiListIDs !== this.newKDNRItem.ApplyCorIDName) {
        this.getCourseheClassInfo(this.applyCourseDatas.IDs, selectionDate.selectedIDs.length);
      }
      this.isShowMutilSelectCourseDialog = false;
    },
    // 组合课-取消
    doclosemultiSelection () {
      this.isShowMutilSelectCourseDialog = false;
    },
    // 提交
    confirmFromClick (callBack) {
      let flag = false;
      // 防止学期卡的价值存在差异
      if (this.cardItem.cardKey == 5) {
        this.newKDNRItem.ValueAmount = this.$utils.mAmountType(Number(this.newKDNRItem.UnitPrice) * Number(this.newKDNRItem.NumCount), 2);
      } else {
        this.newKDNRItem.ValueAmount = this.$utils.mAmountType(this.newKDNRItem.AfterDisAmount, 2);
      }
      if (this.newKDNRItem.ValueAmount < this.newKDNRItem.AfterDisAmount) { // 防止小数的回填计算存在差异问题
        this.newKDNRItem.ValueAmount = this.newKDNRItem.AfterDisAmount;
      }
      this.newKDNRItem.DiscountAmount = this.$utils.mAmountType(Number(this.newKDNRItem.ValueAmount) - Number(this.newKDNRItem.AfterDisAmount), 2);
      if (!this.newKDNRItem.ApplyCorName) {
        layer.alert('请选择课程');
        flag = true;
      } else if (!this.newKDNRItem.NumCount || this.newKDNRItem.NumCount == 0) {
        let name = this.cardItem.cardKey == 5 ? '课时数量' : '有效期';
        layer.alert(name + '必须大于0');
        flag = true;
      } else if ((!this.newKDNRItem.UnitPrice || this.newKDNRItem.UnitPrice <= 0) && this.cardItem.cardKey == 5) {
        layer.alert('单价必须大于0');
        flag = true;
      } else if (
        !this.newKDNRItem.DiscountCount &&
        this.newKDNRItem.DiscountCount !== 0
      ) {
        layer.alert('请输入的折扣');
        flag = true;
      } else if (this.newKDNRItem.AfterDisAmount > 1000000) {
        layer.alert('基数过大，报课价格不能超过一百万');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.$emit('doConfirmFrom', this.fromInfo.type, this.fromInfo.index, this.newKDNRItem);
    },
    // 返回
    closeFrom () {
      this.$emit('doCloseFrom');
    },
    // 获取课类课名列表
    loadDataList () {
      getCourseTypeNameList().then(result => {
        this.coursList = [];
        this.courseTreeList = [];
        result.data.forEach(obj => {
          // StateKey:3为停用 TypeKey:0-全部；1-课类；2-课名
          if (obj.StateKey == 2 && obj.TypeKey == 2) {
            this.coursList.push(obj);
          }
          if (Number(obj.StateKey) == 2) {
            obj.UnitPrice = this.$utils.mAmountType(Number(obj.UnitPrice), 1);
            this.courseTreeList.push(obj);
          }
        });
        this.courseTreeList.splice(0, 0, {
          OLAPKey: '2',
          MainDemoName: '全部课程',
          TypeKey: '2',
          TypeKeyValue: '课类',
          LevelPath: '0.2.',
          FatherKey: '0',
          FatherKeyValue: '',
          StateKey: 2,
          StateKeyValue: '启用',
          UnitPrice: '0'
        });
        console.log(this.courseTreeList, 'this. this.courseTreeList');
      });
    },
    // 查询课类课程信息
    getCourseheClassInfo (OLAPKey, length) {
      console.log(OLAPKey, 'res.data11');
      if (OLAPKey && this.cardItem.cardKey == 5) { // 仅为课时卡（5）才进行填值
        GetTheClassType(OLAPKey).then(res => {
          if (res.data.UnitPrice > 0 && this.newKDNRItem.UnitPrice != res.data.UnitPrice && length < 2) {
            this.newKDNRItem.UnitPrice = Number(res.data.UnitPrice);
            this.newKDNRItem.AfterDisAmount = this.$utils.mAmountType(Number(this.newKDNRItem.NumCount * this.newKDNRItem.UnitPrice * (this.newKDNRItem.DiscountCount / 10)), 2);
            this.newKDNRItem.ValueAmount = this.newKDNRItem.UnitPrice;
          }
        }, err => {
          layer.alert(err.msg);
        });
      }
    },
    // 新增回调
    doAddSuccessEvent (result) {
      // 接收选中值.
      console.log(result, '新增回调');
      this.loadDataList();
      switch (this.fromInfo.type) {
        case 'single':
          this.doAfterSelectedSingleItem(result);// 单课
          break;
        case 'multi':
          if (this.selectionMultiList.IDs && this.selectionMultiList.vals) {
            this.selectionMultiList.IDs = result.OLAPKey + ',' + this.selectionMultiList.IDs;
            this.selectionMultiList.vals = result.MainDemoName + ',' + this.selectionMultiList.vals;
          } else {
            this.selectionMultiList.IDs = result.OLAPKey;
            this.selectionMultiList.vals = result.MainDemoName;
          }
          this.applyCourseDatas.IDs = this.selectionMultiList.IDs;
          this.applyCourseDatas.Names = this.selectionMultiList.vals;
          console.log(this.applyCourseDatas, '组合课选择项1');
          break;
        default:
          break;
      }
    }
  }
};
</script>

  <style>
</style>