<template>
  <!-- 校区备课检查 -->
  <div class="table_nav_box">
    <div class="table_fixed_nav tltle_margin" style="min-height:52px">
      <div v-for="item in titleList"
           :key="item.key"
           @click="chgTitleList(item)"
           class="table_fixed_nav_list"
           :class="{opt:item.isSelected}">{{item.name}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    titleList: Array
  },
  created () {
  },
  methods: {
    // 切换门店列表
    chgTitleList (item) {

      this.titleList.forEach(o => {
        if (item.key == o.key) {
          o.isSelected = true;
        } else {
          o.isSelected = false;
        }
      });
      console.log("chgTitleList",item,this.titleList)
      this.$emit('chgTitleList', item);
    }
  },
  computed: {

  }
};
</script>
