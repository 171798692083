<template>
  <div class="form_item form-input form_info_line"
       :class="{ form_info_required: required }">
    <div class="form_info_field">{{formTitle}}</div>
    <div class="form_info_value">
      <input type="text"
             v-if="!readonly"
             :value="value"
             :placeholder="required?'必填':'选填'"
             @input="updateVal($event.target.value)"
             @change="changeVal($event.target.value)"
             @blur="testTelNo" />
      <span v-if="readonly"
            class="input_readonly">{{value}}</span>
    </div>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: 'formInput',
  data () {
    return {};
  },
  props: {
    formTitle: {
      type: String,
      required: true
    }, // 标题，必填
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    formPlaceholder: String // 默认文本，可选，如不提供，则默认显示title
  },
  computed: {
    vPlaceholder () {
      // 默认文本，如不提供，则默认显示title
      return this.formPlaceholder || this.formTitle;
    }
  },
  created () { },
  watch: {},
  methods: {
    updateVal (val) {
      this.$emit('input', val);
    },
    changeVal (val) {
      this.$emit('change', val);
    },
    showEldilog () {
      this.$emit('showEldilog');
    },
    testTelNo () {
      if (this.vPlaceholder == '请输入客户名称') {
        this.$emit('chgInputType', true); // 有发生改变就触发.
      }
    }
  }
};
</script>
<style scoped>
.form_info_value > span {
  display: inline !important;
}
</style>
