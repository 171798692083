<template>
  <div class="pr teacher_img"
       style="z-index: 2">
    <!-- 显示 -->
    <img :src="headImg"
         :onerror="defaultImg"
         alt="../../../public/image/deflut_teacher_photo@2x.png"
         crossOrigin="anonymous"
         class="my_datum_picture_img_text"
         style="width: 60px; height: 60px; border-radius: 50px" />
    <div class="edit_text_btn"
         v-if="isImgEditText">编辑</div>
    <!-- 上传 -->
    <input type="file"
           name="file"
           title=""
           ref="uploading"
           @click.stop=""
           @change="selectImg($event)"
           class="img-logo"
           accept="image/*"
           data-type="userIconImg" />
    <custom-dialog :title="'裁切图片'"
                   class=""
                   :visible.sync="isChgPic"
                   :before-close="chgisChgPic">
      <div id="screanContent"
           style="z-index: 1; position: absolute !important"></div>
      <vue-crop-img :selectPicUrl="selectPicUrl"
                    :cutImgWidth="imgWidth"
                    :cutImgHeight="imgHeight"
                    @onConfirmClick="setPicCorp"
                    @onCancelClick="clearPicData"></vue-crop-img>
    </custom-dialog>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
import { editEmployeeHeadImg } from '../../API/workbench.js';
export default {
  name: 'inputSelect',
  data () {
    return {
      selectPicUrl: '', // 选中图片url
      isChgPic: false,
      imgURlString: ''
    };
  },
  props: {
    ImgURl: String, //
    formTitle: String,
    staffInfo: Object,
    imgHeight: {
      // 标题，
      type: Number,
      default: 210
    },
    imgWidth: {
      // 标题，
      type: Number,
      default: 320
    },
    isImgEditText: {
      // 编辑文字显示
      type: Boolean,
      default: false
    }
  },
  created () { },
  computed: {
    headImg () {
      this.imgURlString = this.ImgURl;
      if (!this.imgURlString) {
        return '';
      } else if (this.imgURlString.indexOf('http') > -1) {
        return this.imgURlString;
      } else {
        console.log(
          this.$store.getters.CDNURL + this.imgURlString,
          'headImgheadImg'
        );
        return this.$store.getters.CDNURL + this.imgURlString;
      }
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' +
        require('../../../public/image/deflut_teacher_photo@2x.png') +
        '"'
      );
    }
  },
  watch: {},
  methods: {
    selectImg (e) {
      let fileName = e.target.files[0].name;
      let typeText = '.jpg,.jpeg,.png';
      if (
        typeText.indexOf(
          fileName.substring(
            fileName.toLowerCase().lastIndexOf('.'),
            fileName.length
          )
        ) == -1
      ) {
        this.clearPicData();
        layer.alert('上传文件类型不符合，只能上传.jpg,.jpeg,.png类型文件');
        return false;
      } else {
        if (e.target.files.length > 0) {
          // 上传头像
          var reader = new FileReader(); // 实例化一个FileReader对象，用于读取文件
          // 读取File对象的数据
          var that = this;
          reader.onload = function (evt) {
            that.selectPicUrl = evt.target.result;
            that.$nextTick(() => {
              that.isChgPic = true;
            });
          };
          reader.readAsDataURL(e.target.files[0]);
        }
      }
    },
    updateEmployeeHeadImg (OLAPKey, url) {
      editEmployeeHeadImg(OLAPKey, url).then((result) => {
        console.log('updateEmployeeHeadImg', result);
      });
    },
    // 确认裁切
    setPicCorp (imgUrl) {
      this.imgURlString = imgUrl;
      this.clearPicData();
      this.updateEmployeeHeadImg(this.staffInfo.OLAPKey, this.imgURlString);
      this.$emit('doSetPicCorp', this.imgURlString);
    },
    clearPicData () {
      this.isChgPic = false;
      this.selectPicUrl = '';
      this.$refs.uploading.value = '';
    },
    chgisChgPic () {
      this.isChgPic = false;
    }
  }
};
</script>


 <style scoped>
.my_datum_picture_img_text {
  width: 70px;
  height: 70px;
  border-radius: 4px;
}
.edit_text_btn {
  position: absolute;
  width: 105px;
  height: 25px;
  bottom: 0px;
  left: 9px;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0 0 8px 8px;
  text-align: center;
  line-height: 25px;
}
</style>