<template>
  <div class="attract_investment_manage_records">
    <search-box :paramsForQuery="paramsForQuery"
                :batchAllotList="batchAllotList"
                :tableData="investorList"
                @searchClick="searchClick"
                @clearSearchParams="clearSearchParams"></search-box>
    <div class="table_list_content">
      <table-list ref="tableListRef"
                  :tableData="investorList"
                  :tableColumns="tableColumns"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="paramsForQuery"
                  @loadTableData="loadFollowOrderRecordList"></table-list>
      <!-- :selectable="false" -->
    </div>
  </div>
</template>

<script>
import tableList from '../../../../common/table-list'; // 下拉选择框
import searchBox from './search-box';
import { SearchPageFollowOrderRecord } from '../../../../../API/workbench';
export default {
  data () {
    return {
      tableColumns: [
        {
          label: '跟进日期',
          prop: 'FollowTime',
          width: 120,
          type: 'date-item',
          sortable: 'custom'
        },
        {
          label: '跟单人',
          prop: 'FollowKeyValue',
          width: 100,
          type: 'text-item'
        },
        {
          label: '意向级别',
          prop: 'IntentionKeyValue',
          width: 60,
          type: 'text-item',
          columnClass: 'font_dual',
          sortable: 'custom'
        },
        {
          label: '投资人',
          prop: 'MainDemoName',
          width: 120,
          type: 'text-item'
        },
        {
          label: '手机号',
          prop: 'MobilePhone',
          width: 120,
          type: 'text-item'
        },
        {
          label: '计划城市',
          prop: 'CityKeyValue',
          type: 'text-item',
          align: 'center',
          width: 150
        },
        {
          label: '加盟方式',
          prop: 'FranchiseTypeKeyValue',
          type: 'text-item',
          align: 'center',
          width: 80
        },
        {
          label: '招商预算',
          prop: 'budgetAmount',
          type: 'text-item',
          align: 'center',
          width: 150
        },
        {
          label: '来源方式',
          prop: 'SourceTypeKeyValue',
          type: 'text-item',
          width: 120
        },
        {
          label: '跟进类型',
          prop: 'StatusKeyValue',
          type: 'text-item',
          width: 120,
          extend: {
            setClassName: (data) => {
              // 签单进度1-首次跟进;2-日常跟进;3-预约到店;4-到店考察;5-档案完善;6-签约;7-支付定金;8-支付全款;9-放弃;10-无效线索
              let className = '';
              if (
                data.StatusKey == 0 ||
                data.StatusKey == 1 ||
                data.StatusKey == 2 ||
                data.StatusKey == 3 ||
                data.StatusKey == 4 ||
                data.StatusKey == 5
              ) {
                className = 'status_color_yellow';
              } else if (data.StatusKey == 6) {
                className = 'status_color_grass_green';
              } else if (data.StatusKey == 7 || data.StatusKey == 8) {
                className = 'status_color_green';
              } else if (data.StatusKey == 9 || data.StatusKey == 10) {
                className = 'status_color_grass_yellow';
              }
              return className;
            }
          }
        },
        {
          label: '招商顾问',
          prop: 'CounselorKeyValue',
          type: 'text-item',
          width: 100
        },
        {
          label: '跟进记录',
          prop: 'Content',
          type: 'follow-up-record-column',
          width: 280,
          extend: {
            FollowTime: 'FollowTime' // 最近跟进时间
          }
        },
        {
          label: '下次跟进',
          prop: 'NextFollowTime',
          type: 'next-follow-time-column',
          align: 'center',
          width: 100
        }
      ],
      paramsForQuery: {
        pageIndex: 0, //	页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: '', // 排序顺序
        entryTimeStart: '', // 跟进开始时间
        entryTimeEnd: '', // 跟进结束时间
        CounselorKey: 0, // 投资顾问
        SourceTypeKey: 0, // 来源
        StatusKey: '', // 1-首次跟进;2-日常跟进;3-预约到店;4-到店考察;5-档案完善;6-签约;7-支付定金;8-支付全款;9-放弃;10-无效线索
        IsExportKey: 0, // 是否导出：0-否;1-是;如果为是pageIndex传0,pageSize传0
        jsonFormate: null
      },
      investorList: [], // 投资人列表

      totalNum: 0,
      defaultSort: {},
      batchAllotList: [], // 选择的投资人列表
      isBatchDistribute: false
    };
  },
  components: {
    tableList,
    searchBox
  },
  computed: {},
  created () {
    this.loadFollowOrderRecordList();
  },
  methods: {
    // 获取跟单记录列表
    loadFollowOrderRecordList () {
      SearchPageFollowOrderRecord(this.paramsForQuery).then((result) => {
        console.log('获取跟进中投资人列表', result.data.PageDataList);
        // 推荐记录 当前状态：CurrentStageKeyValue 1-投资审核;2-品牌审核;3-管理审核;4-品牌接收;5-到达品牌;6-签单;7-排斥;8-重推;0-推荐发起
        result.data.PageDataList.forEach((obj) => {
          obj.CityKeyValue = obj.ProvinceKeyValue + '·' + obj.CityKeyValue;
          obj.budgetAmount =
            this.$utils.mMoneyType(obj.StratBudgetAmount) +
            '-' +
            this.$utils.mMoneyType(obj.EndBudgetAmount) +
            '万';
        });
        this.investorList = result.data.PageDataList;
        this.totalNum = result.data.Total;
      });
    },
    // 点击搜索
    searchClick (queryParams) {
      this.paramsForQuery = queryParams;
      this.loadFollowOrderRecordList();
    },
    // 清除搜索条件
    clearSearchParams () {
      this.paramsForQuery.pageIndex = 0;
      this.paramsForQuery.searchText = '';
      this.paramsForQuery.pageIndex = 0;
      this.paramsForQuery.entryTimeStart = 0;
      this.paramsForQuery.entryTimeEnd = 0;
      this.paramsForQuery.CounselorKey = 0;
      this.paramsForQuery.SourceTypeKey = 0; // 来源
      this.paramsForQuery.StatusKey = ''; // 签单进度;
      this.loadFollowOrderRecordList();
    }
  }
};
</script>

<style >
.investor_manage_view {
  justify-content: space-between;
}
.recycle_bin {
  position: absolute;
  right: 20px;
  top: 30px;
  height: 40px;
  width: 80px;
  line-height: 38px;
  text-align: center;
  border-radius: 4px;
  padding-left: 27px;
  padding-right: 7px;
  background: #3498db url(../../../../../../public/image/recycled_icon.png)
    no-repeat 8px;
}
.attract_investment_manage_records .status_color_yellow,
.attract_investment_manage_records .status_color_grass_green,
.attract_investment_manage_records .status_color_green,
.attract_investment_manage_records .status_color_grass_yellow {
  position: absolute;
  width: 65px;
  height: 22px;
  border-radius: 2px;
  left: 0;
  top: 0;
  margin: auto;
  bottom: 0;
  right: 0;
  line-height: 22px;
  text-align: center;
  color: #333333;
  margin-left: 10px;
}
.attract_investment_manage_records .status_color_yellow {
  background: rgba(252, 239, 228);
}
.attract_investment_manage_records .status_color_grass_green {
  background: rgba(220, 245, 237);
}
.attract_investment_manage_records .status_color_green {
  background: rgba(120, 209, 181);
}
.attract_investment_manage_records .status_color_grass_yellow {
  background: #f9d7d9;
}
</style>