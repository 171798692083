var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hearbar_mendianInfo massive_bg"},[_c('div',{staticClass:"mendian_info_top"},[_c('div',{staticClass:"mendian_info_img"},[_c('img',{attrs:{"src":_vm.vMendianLogo,"alt":""}})]),_c('div',{staticClass:"flex_column mendian_info_name pr"},[_c('div',{staticClass:"mendian_info_name_text",attrs:{"title":_vm.vMDInfo.MainDemoName}},[_vm._v(_vm._s(_vm.vMDInfo.MainDemoName)+" "),_c('span',{},[_vm._v(" "+_vm._s(_vm.$store.getters.SaaSClientEditionKey == 5?'总部':'校区')+" ")])]),_c('div',{staticClass:"mendian_info_name_id"},[_vm._v("系统账号ID："+_vm._s(_vm.vMDInfo.SaaSClientNO))])]),(_vm.vMendianGeneral)?_c('div',{staticClass:"mendian_info_general"},[_vm._v(_vm._s(_vm.vMendianGeneral))]):_vm._e()]),(_vm.$store.getters.SaaSClientEditionKey != 6)?_c('div',{staticClass:"mendian_acount"},[_c('div',{staticClass:"mendian_acount_title"},[_vm._v("账户期限")]),_c('div',{staticClass:"mendian_acount_text"},[_c('span',{staticClass:"IDspan"},[(
            Date.new(
              Date.new(_vm.vMDInfo.ExpireDateTime).setDate(
                Date.new(_vm.vMDInfo.ExpireDateTime).getDate() + 1
              )
            ) > Date.new()
          )?_c('span',[_vm._v("余")]):_c('span',[_vm._v("过期")]),_c('span',{class:Date.new(_vm.vMDInfo.ExpireDateTime) > Date.new()
              ? 'font_black'
              : 'font_red'},[_vm._v(" "+_vm._s(_vm.vExpireDateTime)+" ")]),_vm._v("天 ")])])]):_vm._e(),_c('div',{staticClass:"mendian_list_title"},[_vm._v("切换校区")]),_c('div',{staticClass:"mendian_list"},_vm._l((_vm.allSaasClientList),function(item,index){return _c('mendian-list',{key:index,attrs:{"item":item},on:{"switchSaasClient":_vm.switchSaasClient}})}),1),_c('div',{staticClass:"mendian_reback"},[_c('div',{on:{"click":_vm.outLoginClick}},[_vm._v(" 退出账号 ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }