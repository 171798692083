<template>
  <div class="content_box_list clearfix"
       @click.stop="clickEvent(courseInfo,$event)">
    <div class="content_box_list_date fl">
      <div>{{courseInfo.WeekKeyValue}}</div>
      <div class="font_gray">{{vBeginClassTime}}</div>
    </div>
    <div class="content_box_list_info fr">
      <div class="list_info_left fl"
           :style="courseInfo.alreadyAttenCount>0?'max-width:210px':''">
        <div class=""
             :class="$utils.getTextWidth(courseInfo.CourseNameKeyValue, 16)>178?'font_dual':''">
          <span>{{courseInfo.CourseNameKeyValue}}
            <span class="text_teacher">{{courseInfo.MTeacherKeyValue}}</span>
          </span>
        </div>
        <div class="font_gray">
        <span v-if="courseInfo.IsNotEndKey==1">不结束</span>
        <span v-else>
          <span>截止</span>
          <span :class="{font_red:vLastClassTimeDay}">{{vLastClassTime}}</span>
          <span class="font_black">{{$utils.mAmountType(courseInfo.ClassCount,0)}}</span>节
        </span>
        <span>丨</span>
        <span class="font_black"
              v-if="vIsSysVersionTY">{{$utils.mAmountType(courseInfo.Num_SyncArranged,0)}}人</span>
        <span class="font_black"
                v-else>{{$utils.mAmountType(courseInfo.Num_InGrade,0)}}人</span>
        </div>
      </div>
      <!-- <div class="list_info_right fr"
           v-if="courseInfo.alreadyAttenCount>0">
        <div>已排节数</div>
        <div class="font_number font_blue">{{courseInfo.alreadyAttenCount}}</div>
      </div> -->
      <div class="list_info_btn"> </div>
    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {
    };
  },
  props: {
    courseInfo: Object,
    contentBoxTitle: {
      type: String,
      default: ''
    }
  },
  components: {
  },
  computed: {
    vBeginClassTime () {
      return this.courseInfo.ClassTimeName.split('-')[0];
    },
    vLastClassTime () {
      return this.courseInfo.LastClassTime.slice(0, 10);
    },
    vLastClassTimeDay () {
      return this.$utils.DateDiffDay(Date.new(), this.courseInfo.LastClassTime) <= 14;
    },
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    }
  },
  methods: {
    // 选中一节课
    clickEvent (courseInfo, e) {
      console.log(this.$utils.getTextWidth(this.courseInfo.CourseNameKeyValue, 16));
      this.$emit('clickEvent', courseInfo, e);
    }
  }
};
</script>

<style>
</style>