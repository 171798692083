<template>
  <div @click.stop="isShowAlerDialog = false">
    <!-- 学生个人信息 -->
    <div class>
      <student-lable class="content_marginRL single_shadow"
                     :studentID="newInitJson.OLAPKey"
                     :CustStatusKey="1"
                     :isShowAddBtn="false"
                     :isShowaddOldStudentBtn="true"
                     :readonly="isReadonly"
                     :mobileSupport="newInitJson.MobileSupport"
                     @changeStudent="changeStudent"
                     @alterStudent="alterStudent">
      </student-lable>
    </div>
    <!-- 课单列表 -->
    <div class="new_refund_dialog defult_dialog">
      <div class="single_result_detail">
        <div v-if="isReadonly"
             class="class_bill_content_CQ single_shadow"
             ref="dataScroll">
          <!-- 课单列表   @scroll="scrollTOP"-->
          <div>
            <course-refund-item v-for="(item,index) in DATA.xykdList"
                                :key="index"
                                :item="item"
                                :courseList="allStudentCourseList"></course-refund-item>
          </div>
          <!-- 杂费列表 -->
          <div>
            <incidentalsRefundItem v-for="(item,index) in DATA.incidentalsList"
                                   :key="index"
                                   :item="item"
                                   :incidentalsList="allIncidentalsList"></incidentalsRefundItem>
          </div>
        </div>
        <div v-else
             class="class_bill_content_CQ single_shadow"
             ref="dataScroll">
          <!-- 课单列表   @scroll="scrollTOP"-->
          <div>
            <course-refund-item v-for="(item,index) in allStudentCourseList"
                                :key="index"
                                :item="item"
                                :courseList="allStudentCourseList"
                                @courseItemAmountAdd="clickCourseItemAmountAdd"></course-refund-item>
          </div>
          <!-- 杂费列表 -->
          <div>
            <incidentalsRefundItem v-for="(item,index) in allIncidentalsList"
                                   :key="index"
                                   :item="item"
                                   :incidentalsList="allIncidentalsList"
                                   @incidentalsItemAmountAdd="clickIncidentalsItemAmountAdd"></incidentalsRefundItem>
          </div>
        </div>
        <!-- 已购课单 -->
        <div v-if="isReadonly"
             class="single_result_courses_purchased marginTop_s">
          <!-- 购买课单详情 -->
          <div class="refund_of_classes_form marginTop_s single_shadow box_shadow">
            <div class="single_result_courses_info">
              <div class="form_info_edit">
                <div class="form_info_line">
                  <div class="form_info_field">
                    <span>剩余价值</span>
                  </div>
                  <div class="form_info_value">
                    <input type="text"
                           class="font_black"
                           readonly
                           :value="DATA.ValueAmount" />
                  </div>
                </div>
                <input-number :min="0"
                              v-model="DATA.ActualBackAmount"
                              :formTitle="'退款金额'"
                              :decimalPlaces="2"
                              :placeholder="'选填'"
                              :required="false"
                              :readonly="isReadonly"></input-number>
                <div>
                  <input-text :formTitle="'支付方式'"
                              :required="false"
                              :readonly="isReadonly"
                              v-model="DATA.IncomePayAccountKeyValue"></input-text>
                  <input-text :formTitle="'退款方式'"
                              :required="false"
                              :readonly="isReadonly"
                              v-model="DATA.BackCourseTypeKeyValue"></input-text>
                  <div>
                  </div>

                  <div>
                    <input-text :formTitle="'处理原因'"
                                :readonly="isReadonly"
                                :formPlaceholder="'无'"
                                :required="false"
                                v-model="DATA.CommentSupport"></input-text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="single_result_courses_purchased marginTop_s"
             v-else-if="allStudentCourseList.length !==0 ||allIncidentalsList.length !==0">
          <!-- 购买课单详情 -->
          <div class="refund_of_classes_form marginTop_s single_shadow box_shadow">
            <div class="single_result_courses_info">
              <div class="form_info_edit">
                <div class="form_info_line">
                  <div class="form_info_field">
                    <span>剩余价值</span>
                  </div>
                  <div class="form_info_value">
                    <input type="text"
                           class="font_black"
                           readonly
                           :value="$utils.mMoneyType((Number(courseAmount)+Number(incidentalsAmount)),2)" />
                  </div>
                </div>
                <input-number :min="0"
                              v-model="DATA.RefundMoney"
                              :formTitle="'退款金额'"
                              :decimalPlaces="2"
                              :placeholder="'选填'"
                              :required="false"
                              :readonly="isReadonly"></input-number>
                <div>
                  <input-select :dataList="optionStatus"
                                :formTitle="'退款方式'"
                                :id="'type'"
                                :name="'name'"
                                :required="false"
                                :readonly="isReadonly"
                                :selectedInfo="vOptionStatusInfo"
                                @selectedItem="doAfterClick"></input-select>
                </div>
                <div v-if="DATA.RefundMethodKey == 2">
                  <input-select :dataList="incomePayAccountList"
                                :formTitle="'支付方式'"
                                :id="'OLAPKey'"
                                :name="'MainDemoName'"
                                :required="true"
                                :readonly="isReadonly"
                                :selectedInfo="vSelectedAccountInfo"
                                @selectedItem="doAfterSelectedAccount"></input-select>
                </div>

                <div>
                  <input-text :formTitle="'处理原因'"
                              :readonly="isReadonly"
                              :formPlaceholder="'选填'"
                              :required="false"
                              v-model="DATA.Remarks"></input-text>
                </div>
              </div>
            </div>
          </div>
          <!-- 按钮组 -->
          <div>
            <save-cancel-btn-group :isSingleSubmit="true"
                                   :singleSubmitType="'dynamic'"
                                   @confirmClick="buttonClickEvent"
                                   @cancelClick="refunDevent"></save-cancel-btn-group>
          </div>
        </div>
        <div v-if="!isReadonly && allStudentCourseList.length==0 && allIncidentalsList.length ==0 ">
          <div class="monitoring_content_nodata">
            <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 选择框 作为公共组件
import studentLable from '../../../../components/common/student-lable';
import courseSingleList from '../../../../components/common/course-single-list';
import chagneRefundItem from './chagne-refund-item';
import courseRefundItem from './course-refund-item';
import incidentalsRefundItem from './incidentals-refund-item';
import dropDownBox from '../../../../components/common/drop-down-box';
import { dropMultiCourseApply, getIncomeAccount, GetDropMultiCourseApplyInfo } from '../../../../API/workbench.js';
export default {
  data () {
    return {
      // isInterfaceShow: true,
      newInitJson: this.studentItem, // 选中学生信息
      selectCourseFormItem: {}, // 选中退课课单项
      allStudentCourseFormShow: false, // 是否显示表单
      isShowAlerDialog: false,
      refundValue: '直接退款',
      courseAmount: 0, // 课单退款金额
      incidentalsAmount: 0, // 杂费退款金额
      radio: '2',
      selectMode: false,
      // paymentTypeValue: '请选择支付方式',
      // RefundMoney: 0,         // float --实退金额
      incomePayAccountList: [], // 收支账户
      selectedRefundAccount: { key: 0, name: '' },
      DATA: {
        StudentKey: this.studentItem.OLAPKey, // int,	--学生
        RefundMoney: 0, // float --实退金额
        CourseTicketList: [], // array --课单ID
        MiscellaneousFeeList: [], // array, --杂费ID
        RefundMethodKey: 2, // int, --退款方式 1 - 账户余额; 2 - 直接退款
        RefundAccountKey: 0, // int, --退款账户
        Remarks: '' // varchar(100), --备注
      },
      cIndex: '',
      optionStatus: [
        {
          type: 1,
          name: '退入账户余额',
          eventName: 'balance',
          isShow: true
        },
        {
          type: 2,
          name: '直接退款',
          eventName: 'refund',
          isShow: true
        }
      ]
    };
  },
  props: {
    studentItem: {
      type: Object
    },
    allStudentCourseList: {
      type: Array
    },
    allIncidentalsList: {
      type: Array
    },
    courseIndex: {
      type: [String, Number]
    },
    isReadonly: {
      type: Boolean,
      default: false
    },
    tableId: {
      type: [String, Number]
    }
  },
  components: {
    studentLable,
    courseSingleList,
    chagneRefundItem,
    courseRefundItem,
    incidentalsRefundItem,
    dropDownBox
  },
  created () {
    this.getConnectorData();
    this.cIndex = this.courseIndex;
    if (this.isReadonly) {
      this.getDropMultiCourseApplyInfo();
    }
  },
  watch: {
    courseIndex () {
      this.cIndex = this.courseIndex.split('-')[0];
      // 防止第一次没有渲染出来的问题
      this.$nextTick(() => {
        this.$refs.dataScroll.scrollTop = this.cIndex * 102;
        console.log(this.$refs.dataScroll.scrollTop, '回显课单项3');
      });
    }
  },
  computed: {
    vSelectedAccountInfo () {
      return {
        id: this.selectedRefundAccount.key,
        name: this.selectedRefundAccount.name
      };
    },
    vOptionStatusInfo () {
      return {
        id: this.DATA.RefundMethodKey,
        name: this.refundValue
      };
    }
  },
  methods: {
    //  外部选中课单杂费后
    externalOptCourseItem (item, type) {
      if (item) {
        if (type == 'Zf') { // 杂费
          this.DATA.CourseTicketList = [];
          this.DATA.MiscellaneousFeeList.push(item.TableID); ;
        } else { // 课单
          this.DATA.CourseTicketList = [];
          this.DATA.CourseTicketList.push(item.TableID); // 选中提交课单ID列表
        }
        console.log(item, 'item111111111');
        this.courseAmount = item.ValueAmount;
        this.DATA.RefundMoney = this.$utils.mMoneyType(Number(item.ValueAmount), 2); // 实退课单价值总额
      }
    },
    getDropMultiCourseApplyInfo () {
      GetDropMultiCourseApplyInfo(this.tableId).then(res => {
        console.log(res, 'tableIdtableIdtableIdtableIdtableIdtableIdtableId');
        if (!res.data.incidentalsList) {
          res.data.incidentalsList = [];
        }
        if (!res.data.xykdList) {
          res.data.xykdList = [];
        }
        if (res.data.CommentSupport.length == 0) {
          res.data.CommentSupport = '无';
        }
        this.DATA = res.data;
      });
    },
    // 获取账户列表
    getConnectorData () {
      getIncomeAccount().then(result => {
        // 收支账户接口
        result.data.forEach(o => {
          if (this.selectedRefundAccount.key == o.OLAPKey) {
            this.$set(o, 'isActive', true); // 找到显示高亮效果
          } else {
            this.$set(o, 'isActive', false); // 没有隐藏高亮效果
          }
        });
        this.incomePayAccountList = result.data;
      });
    },
    // 选中账户后
    doAfterSelectedAccount (data) {
      console.log(data, '选中账户后');
      this.hiddenRefundDrop();
      this.DATA.RefundAccountKey = data.OLAPKey;
      this.selectedRefundAccount.key = data.OLAPKey;
      this.selectedRefundAccount.name = data.MainDemoName;
    },
    showRefundDrop () {
      this.isShowAlerDialog = true;
      let that = this;
      document.onclick = function () {
        that.isShowAlerDialog = false;
      };
    },
    hiddenRefundDrop () {
      this.isShowAlerDialog = false;
    },
    // 选择退款方式
    doAfterClick (optionItem) {
      if (optionItem.type == 1) {
        this.DATA.RefundMethodKey = 1;
        this.refundValue = '退入账户余额';
      } else {
        this.DATA.RefundMethodKey = 2;
        this.refundValue = '直接退款';
      }
      this.isShowAlerDialog = false;
    },
    // 选择课单...
    clickCourseItemAmountAdd () {
      let sum = 0;
      var courseTicketIdList = [];
      this.allStudentCourseList.forEach(o => {
        if (o.isActive == true) {
          sum += Number(o.ValueAmount || 0);
          courseTicketIdList.push(o.TableID);
        }
      });
      this.courseAmount = sum; // 剩余课单价值总额
      this.DATA.RefundMoney = this.$utils.mMoneyType((Number(this.courseAmount) + Number(this.incidentalsAmount)), 2); // 实退课单价值总额
      this.DATA.CourseTicketList = courseTicketIdList; // 选中提交课单ID列表
      console.log(this.DATA.CourseTicketList, '课单');
    },
    // 选择杂费...
    clickIncidentalsItemAmountAdd () {
      let sum = 0;
      var miscellaneousFeeIdList = [];
      this.allIncidentalsList.forEach(o => {
        if (o.isActive == true) {
          sum += Number(o.ActualPayAmount);
          miscellaneousFeeIdList.push(o.TableID);
        }
      });
      this.incidentalsAmount = sum; // 剩余杂费价值总额
      this.DATA.RefundMoney = this.$utils.mMoneyType((Number(this.courseAmount) + Number(this.incidentalsAmount)), 2); // 实退杂费价值总额
      this.DATA.MiscellaneousFeeList = miscellaneousFeeIdList; // 选中提交杂费ID列表
    },
    // 显示学员档案.
    showStudentFile () {
      this.$emit('showStudentFile');
    },
    // 退款按钮
    refunDevent () {
      this.$emit('close');
    },
    // 确定按钮
    buttonClickEvent (callBack) {
      var reg = /^[0-9]+.?[0-9]*$/;
      let flag = false;
      const courseIndex = this.allStudentCourseList.findIndex(obj => {
        return obj.isActive == true;
      });
      const IncidentalsIndex = this.allIncidentalsList.findIndex(obj => {
        return obj.isActive == true;
      });
      console.log(courseIndex, '：课单', IncidentalsIndex, '：杂费');
      if (courseIndex < 0 && IncidentalsIndex < 0) {
        layer.alert('课单,杂费至少选择一项');
        flag = true;
      } else if (
        Number(this.DATA.RefundMoney) == 0 &&
        this.DATA.RefundMethodKey == 2
      ) {
        layer.alert(
          '退款金额不能为0，如不需退款，请选择退款方式为 退入账户余额'
        );
        flag = true;
      } else if (Number(this.DATA.RefundMoney) >= 100000000) {
        layer.alert('退款金额必须小于一亿');
        flag = true;
      } else if (!reg.test(this.DATA.RefundMoney)) {
        layer.alert('请输入正确的数字金额');
      } else if (!this.DATA.RefundAccountKey && this.DATA.RefundMethodKey == 2) {
        layer.alert('请选择支付方式');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      dropMultiCourseApply(this.DATA).then(
        result => {
          layer.alert('退款成功');
          this.$bus.emit('AfterEditCourseSingle');
          this.DATA.RefundMoney = 0;
          this.incidentalsAmount = 0;
          this.courseAmount = 0;
          this.DATA.RefundAccountKey = 0;
          this.$emit('updateCourseEvent');// 通知学生档案更新.
          this.refunDevent();
        },
        reject => {
          layer.alert(reject.msg);
        }
      )
        .finally(() => {
          if (callBack) {
            callBack();
          }
        });
    },
    // 改变学生
    changeStudent () {
      console.log('changeStudent-123');
      this.$emit('changeStudent');
    },
    alterStudent (item) {
      console.log(item, 'alterStudent-123');
      // if (this.newInitJson.OLAPKey != item.OLAPKey) {
      this.$emit('alterStudent', item);
      // }
      this.DATA.StudentKey = item.OLAPKey;
      this.newInitJson.OLAPKey = item.OLAPKey;
      this.newInitJson.MobileSupport = item.MobileSupport;
    }
    // changeRefundMoney (val) {
    //   if (val) {
    //     this.DATA.RefundMoney = val.replace(/[^\d.]/g, '');
    //   }
    // }
  }
};
</script>
  <style>
.class_bill_content_CQ {
  max-height: 400px;
  overflow-y: auto;
}
</style>