<template>
  <div>
    <div class="setting_view_right_ul">
      <div class="setting_view_right_list"
           :class="vtype"
           @click.stop="doTypeItemClick"
           v-if="isViewBox&& vTypeInfo">
        <div class="">
          {{dataTypeInfo.MainDemoName}}
        </div>
        <div class="list_text">
          {{dataTypeInfo.dataNameSum}}
        </div>
      </div>
      <div>
        <setting-view-right-item v-for="(item,index) in dataList"
                                 :key="index"
                                 :index="index"
                                 :Item="item"
                                 @doItemClick="doNameItemClick"></setting-view-right-item>
      </div>

    </div>

    <div class="setting_view_left_btn"
         v-if="addDataName && dataList.length>=0"
         @click="doAddNameClick">
      <span class="font_icon">+</span> {{addDataName}}
    </div>
    <!-- <basics-nodata-tips v-if="dataList.length==0 &&!addDataName&&!isViewBox"></basics-nodata-tips> -->
  </div>
</template>

<script>
import settingViewRightItem from './setting-view-right-item';
export default {
  components: {
    settingViewRightItem
  },
  props: {
    dataList: Array, // 左侧视图-列表
    dataTypeInfo: Object, // 类型信息
    addDataName: String, // 下方添加按钮-标题
    isViewBox: {
      type: Boolean,
      default: true
    },
    isDataType: {// 默认普通显示
      type: String,
      default: 'common'
    }
  },
  data () {
    return {

    };
  },
  computed: {
    vTypeInfo () {
      if (this.dataTypeInfo.OLAPKey == 2) {
        if (this.dataList.length > 0) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    vtype () {
      console.log(this.dataTypeInfo, "dataTypeInfo")
      if (this.dataTypeInfo.OLAPKey <= 2) { // 类型为全部时
        return 'cancel_type';
      } else { // 反之
        return 'info_type';
      }
    }
  },
  methods: {
    // 点击名称-操作
    doNameItemClick (item, index) {
      this.$emit('doNameItemClick', item, index);
    },
    // 点击类型-操作
    doTypeItemClick () {
      if (this.dataTypeInfo.OLAPKey > 2) { // 类型为全部时,禁止操作
        this.$emit('doTypeItemClick', this.dataTypeInfo);
      }
    },
    // 点击添加名称-操作
    doAddNameClick () {
      this.$emit('doAddNameClick');
    }
  }
};
</script>

<style>
</style>
