<template>
  <div>
    <div class="form_info_list">
      <form-input :formTitle="'小程序版本说明和功能解释'"
                  :formPlaceholder="' '"
                  :required="true"
                  v-model="dataInfo.Explain"></form-input>
      <form-input :formTitle="'反馈内容'"
                  :formPlaceholder="' '"
                  :required="true"
                  v-model="dataInfo.Feedback"></form-input>
    </div>
    <div class="form_info_list"
         style="padding-bottom: 20px">
      <div class="form_item form-input form_info_line form_info_required no_border_bottom">
        <div class="form_info_field">
          <span>审核图片</span>
        </div>
        <div class="form_info_value"></div>
      </div>
      <div class="flex"
           style="flex-wrap: wrap">
        <div v-for="(item, index) in this.dataInfo.AttachUrlList"
             :key="index"
             class="bulk_assessment_students_image_view_image pr">
          <img class="img_box"
               :src="$store.getters.CDNURL + item.AttachUrl" />
          <div class="delete"
               @click="doDeleteImage(item)"></div>
        </div>
        <div class="bulk_assessment_students_image_view_image pr">
          <img class="img_box"
               src="../../../public/image/user_icon.png" />
          <choose-img-mutil class="upload_box"
                            :maxNum="12"
                            @onUpLoadImgSuccess="doAfterUpLoadImgSuccess"></choose-img-mutil>
        </div>
      </div>
    </div>
    <button-info @closeDialog="closePopup"
                 @buttonClickEvent="submitForm"></button-info>
  </div>
</template>
<script>
import {
  GetNewAuditVersion,
  SubmitNewAuditVersionn
} from '../../API/workbench';
export default {
  data () {
    return {
      dataInfo: {
        Explain: '',
        Feedback: ''
      }
    };
  },
  created () {
    this.getDataInfo();
  },
  methods: {
    closePopup () {
      this.$emit('closePopup');
    },
    doAfterUpLoadImgSuccess (imgInfo, callback) {
      this.dataInfo.AttachUrlList.push(imgInfo);
    },
    doDeleteImage (item) {
      var list = [];
      list = this.dataInfo.AttachUrlList.filter(obj => { return obj.OLAPKey != item.OLAPKey; });
      this.dataInfo.AttachUrlList = list;
    },
    submitForm () {
      let arr = [];
      this.dataInfo.AttachUrlList.forEach((e) => {
        console.log(e);
        arr.push(e.AttachUrl);
      });
      if (!this.dataInfo.Explain) {
        layer.alert('请输入小程序版本说明和功能解释');
        return false;
      } else if (!this.dataInfo.Feedback) {
        layer.alert('请输入反馈内容');
        return false;
      } else if (arr.length == 0) {
        layer.alert('请选择图片');
        return false;
      }
      SubmitNewAuditVersionn(this.dataInfo.Feedback, this.dataInfo.Explain, arr).then(
        (res) => {
          layer.alert('修改成功');
          this.$emit('succeed');
          this.closePopup();
        }, (err) => {
          layer.alert(err.msg);
        }
      );
    },
    getDataInfo () {
      GetNewAuditVersion().then((res) => {
        this.dataInfo = res.data;
        console.log(this.dataInfo);
      },
        (err) => {
          console.log(err);
        }
      );
    }
  }
};
</script>

<style scoped>
.bulk_assessment_students_image_view_image {
  margin-right: 15px;
  margin-bottom: 15px;
}
.bulk_assessment_students_image_view_image .delete {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 24px;
  left: 80px;
  top: 0px;
  width: 30px;
  height: 30px;
  background-image: url(../../../public/image/delete_the_photo.png);
  cursor: pointer;
}
</style>
