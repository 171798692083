<template>
  <div class="form_info_detail">
    <div class="student_info_edit form_info_edit form_info_list content_marginTop_30 content_marginRL single_shadow">
      <div class="form_info_line">
        <div class="form_info_field">
          <span>客户关系</span>
        </div>
        <!-- 下拉菜单 form_info_select -->
        <div class="form_info_value form_info_select">
          <el-select v-model="urgentInformation.RelationshipKeyValue"
                     placeholder="可选"
                     @change="changeVal">
            <el-option v-for="(item,index) in customerRelationship"
                       :key="index"
                       :label="item.value"
                       :value="item"></el-option>
          </el-select>
        </div>
      </div>
      <div class="form_info_line">
        <div class="form_info_field">
          <span>客户电话</span>
        </div>
        <div class="form_info_value">
          <input type="text"
                 v-model="urgentInformation.ParentPhoneName"
                 placeholder="选填" />
        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <common-button-group @closeDialog="closeDialog"
                         @sendItem="confirmSave"></common-button-group>
  </div>
</template>
<script>
export default {
  data () {
    return {
      urgentInformation: {
        ParentName: '', // 紧急联系人
        ParentPhoneName: '', // 紧急联系人电话
        RelationshipKey: '', // 整型  紧急联系人关系.
        RelationshipKeyValue: ''
      }
    };
  },
  props: ['customerRelationship', 'customerInfo'],
  watch: {
    customerInfo () {
      this.urgentInformation.ParentPhoneName = this.$utils.parseJson(
        this.customerInfo
      ).ParentPhoneName;
      this.urgentInformation.RelationshipKeyValue = this.$utils.parseJson(
        this.customerInfo
      ).RelationshipKeyValue;
      this.urgentInformation.RelationshipKey = this.$utils.parseJson(
        this.customerInfo
      ).RelationshipKey;
    }
  },
  created () {
    this.urgentInformation.ParentPhoneName = this.$utils.parseJson(
      this.customerInfo
    ).ParentPhoneName;
    this.urgentInformation.RelationshipKeyValue = this.$utils.parseJson(
      this.customerInfo
    ).RelationshipKeyValue;
    this.urgentInformation.RelationshipKey = this.$utils.parseJson(
      this.customerInfo
    ).RelationshipKey;
  },
  methods: {
    closeDialog () {
      this.$emit('closeIsShowUrgent');
    },
    changeVal (val) {
      this.urgentInformation.RelationshipKey = val.key;
      // this.urgentInformation.ParentName = val.value
      this.urgentInformation.RelationshipKeyValue = val.value;
    },
    // RelationshipKey:'',                   //整型  紧急联系人关系.
    // RelationshipKeyValue: '',
    confirmSave () {
      this.$emit('sendItem', this.urgentInformation);
    }
  }
};
</script>