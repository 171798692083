<template>
  <div class="particular_header">
    <!-- 左侧图片 -->
    <div class="header_left">
      <div class="header_left_titleFence"></div>
      <span class="header_left_titleFence_text">{{CoursewarePackage.BigCoursewarePackageKeyValue}}</span>
      <img :src="headImg"
           style="width:100%"
           alt="../../../../../public/image/no_logo@2x.png">
    </div>
    <!-- 右侧标题信息 -->
    <div class="header_right">
      <div :class=" role ? 'header_right_title' : 'header_right_title_tea'">
        <div class="header_right_title_textForCut">
          <!-- 大标题 -->
          <div class="header_right_title_textForCut_text">
            {{CoursewarePackage.CoursewarePackageKeyValue}}
          </div>

        </div>
        <!-- 当前设备状态 -->
        <div class="font_blue button cancel_about"
             @click="cancelAboutCW">
          解除课包关联
        </div>
      </div>
      <!-- 老师按钮列表 -->
      <div class="header_right_teacher pr"
           v-if="!vIsClient">
        <div class="header_right_teacher_list">当前设备： </div>
        <div class="no_auth header_right_title_type_text_err"
             style="width:65px">
          <div style="color: #ff6c67">非桌面端</div>
        </div>
      </div>
      <div class="header_right_teacher pr"
           v-else>
        <div class="header_right_teacher_list">当前设备： </div>
        <div class="no_auth header_right_title_type_text_err"
             v-if="CoursewarePackage.DeviceIsAuth == 0">
          <div style="color: #ff6c67">未授权</div>
        </div>
        <div class="header_right_title_type_text"
             v-if="CoursewarePackage.DeviceIsAuth == 1">
          <div style="color: #24da7e">已授权</div>
        </div>
        <div class="button"
             :class="role ? 'header_right_title_type_authorization' : 'header_right_title_type_authorization_tea'"
             v-if="CoursewarePackage.DeviceIsAuth == 0"
             @click="applyAuthorization">
          {{!role?'申请设备授权':'授权本设备'}}
        </div>
      </div>
      <!-- <classBiginData :par="'par'" :BigCoursewarePackageKey="maxCoursewarePackage.BigCoursewarePackageKey" :dataInfo="particularData"></classBiginData> -->
    </div>
  </div>
</template>
<script>
import {
  CWSAuthorizedDeviceManagementAuthorizedTheDevice,
  AddInterfaceLog
} from '../../../../../API/workbench';
// import classBiginData from '../../right-box-work-bench/home-page-details/class-bigin-data.vue'
export default {

  data () {
    return {
      dataInfo: {
        CoursewareKey: '', // 教案课件ID
        DeviceCode: '' // "340BC40DC769411B",  // 设备码
      },
      tipContont: ''
    };
  },

  components: {
    // classBiginData
  },

  created () {
    if (this.$zxClient && this.$zxClient.IsClient) {
      this.dataInfo.DeviceCode = this.$zxClient.GetMCode();
    } else {
      this.tipContont = '非桌面端';
      this.dataInfo.DeviceCode = '';
    }
  },

  props: {
    CoursewarePackage: Object, // 传入的子课包信息
    role: Boolean, // true为校长 ，false为老师
    particularData: Object // 上课统计
  },

  computed: {
    // 大课包封面
    headImg () {
      console.log('CoursewarePackage', this.CoursewarePackage);
      if (this.CoursewarePackage && this.CoursewarePackage.CoverUrl) {
        if (this.CoursewarePackage.CoverUrl.indexOf('http') > -1) {
          return this.CoursewarePackage.CoverUrl;
        } else {
          return this.$store.getters.CDNURL + this.CoursewarePackage.CoverUrl;
        }
      } else {
        return '';
      }
    },
    vIsClient () {
      return this.$zxClient && this.$zxClient.IsClient;
    }
  },

  methods: {
    cancelAboutCW () {
      this.$emit('cancelAboutCW');
    },
    clickNoIsClient () {
      layer.alert('请用桌面端打开程序');
    },
    // 申请按钮点击事件
    applyAuthorization () {
      console.log('申请授权', this.CoursewarePackage);
      if (this.$zxClient && this.role) {
        if (this.$zxClient.GetMInfo() === '{}') {
          layer.alert('设备信息为空')
          let interfaceLogData = {
            InterfaceName: this.$store.getters.APIURL + 'CWS.AuthorizedDeviceManagement.AuthorizedTheDevice',
            parameter: {
              BigCoursewarePackageKey: this.CoursewarePackage
                .BigCoursewarePackageKey
            },
            PostData: JSON.stringify(this.$store.getters.token),
            LogContents: '设备信息为空',
            LogTitleName: '设备信息为空',
            RefererName: window.location.href
          };
          AddInterfaceLog(interfaceLogData).then(result => {
            console.log('记录日志')
          });
        } else {
          let DeviceInfo = JSON.parse(this.$zxClient.GetMInfo());
          CWSAuthorizedDeviceManagementAuthorizedTheDevice(DeviceInfo, this.CoursewarePackage
            .BigCoursewarePackageKey).then(result => {
              console.log(result, '授权本设备');
              this.$zxClient.SetUCode(result.data.DeviceUCode);
              this.$emit('getCoursewarePackageDetails');
            }).catch(error => {
              layer.alert(error.msg);
            });
        }

      } else {
        this.dataInfo.CoursewareKey = this.CoursewarePackage.CoursewarePackageKey;
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '申请设备授权',
          moduleName: 'cwsApplyAuth',
          data: {
            dataInfo: this.dataInfo
          },
          callBack: {
            afterSuccess: (data) => {
              this.$emit('getCoursewarePackageDetails');
            }
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.particular_header {
  display: flex;
  width: 884px;
  height: 113px;
  margin-bottom: 20px;
  /* padding-top: 20px; */
}

.header_left {
  width: 200px;
  height: 113px;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.header_left_titleFence {
  width: 100%;
  height: 30px;
  border-radius: 0 0 8px 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.3;
}

.header_left_titleFence_text {
  color: #fff;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 200px;
  height: 29px;
  line-height: 29px;
  text-align: center;
}

.header_right {
  flex: 1;
  margin-left: 18px;
}

.header_right_title {
  display: flex;
  position: relative;
}

.header_right_title_tea {
  display: flex;
  flex-direction: column;
  position: relative;
}

.header_right_title_teacher {
  display: flex;
  align-items: center;
  color: #999;
  margin-top: 17px;
}

.header_right_title_textForCut {
  display: flex;
  align-items: flex-end;
}

.header_right_title_textForCut_text {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-size: 20px;
  font-weight: 700;
}

.header_right_title_textForCut_cut {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  margin-left: 15px;
  color: #3498db;
  cursor: pointer;
}

.header_right_title_type {
  font-family: 'Arial';
  font-style: normal;
  display: flex;
  align-items: center;
  color: #999999;
  position: absolute;
  right: 0;
}

.header_right_title_type_text {
  border: 1px solid #24da7e;
  border-radius: 10px 10px 10px 1px;
  width: 48px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  background-color: #d3f8e5;
  font-family: 'Arial';
  font-style: normal;
  font-size: 12px;
}

.header_right_title_type_text_err {
  border: 1px solid #ff6c67;
  border-radius: 10px 10px 10px 1px;
  width: 48px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  background-color: #ffe2e1;
  font-family: 'Arial';
  font-style: normal;
  font-size: 12px;
}

.header_right_title_type_authorization {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-size: 14px;
  color: #3498db;
  cursor: pointer;
  margin-left: 10px;
  /* position: absolute; */
  /* right: 0px;
    top: 30px; */
}

.header_right_title_type_authorization_tea {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-size: 14px;
  color: #3498db;
  cursor: pointer;
  margin-left: 10px;
  /* position: absolute;
    left: 125px;
    top: 40px; */
}

.header_right_teacher {
  display: flex;
  margin-top: 15px;
  align-items: center;
}

.header_right_teacher_list {
  margin-right: 15px;
  color: #999;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
}

.header_right_teacher_name {
  display: flex;
  flex: 1;
}

.header_right_teacher_name_text {
  border: 1px solid #e8ebef;
  border-radius: 3px;
  width: 60px;
  height: 24px;
  line-height: 22px;
  text-align: center;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-size: 12px;
  margin-right: 8px;
  cursor: pointer;
}
.no_auth {
  width: 48px;
  background: rgba(255, 108, 103, 0.2);
  border: 1px solid #ff6c67;
  border-radius: 10px 10px 10px 1px;
  font-size: 12px;
}
.cancel_about {
  position: absolute;
  right: 0px;
}
.cancel_about::before {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  left: -20px;
  background: url('../../../../../../public/image/cancel_about.png');
  background-size: 14px;
}
</style>
