<template>
  <div class="compile_from_box from_padding">
    <div class="compile_from_content">
      <!-- 2：满减券 3：特价券 4：代金券' -->
      <div v-if="dataInfo.type==2">
        <from-amount-multi-box :titleName="'报名金额'"
                               :required="true"
                               :oneValue="dataInfo.xiao_fei_tiao_jian"
                               :twoValue="dataInfo.di_xian_jin"
                               :decimalPlaces="2"
                               @inputChangeOneEvent="inputChangeOneEvent"
                               @inputChangeTwoEvent="inputChangeTwoEvent"></from-amount-multi-box>
        <from-text-box :titleName="'适用范围'"
                       :required="true"
                       :placeholder="'请输入优惠券名称，15个字内'"
                       :value="dataInfo.scopeExplain"
                       @inputEvent="chgScopeExplain"></from-text-box>
      </div>
      <div v-if="dataInfo.type==3">
        <div v-if="dataInfo.taocanid&&Number(dataInfo.taocanid)>0">
          <combo-Item :comboItem="comboItem"
                      @onSelectPackage="onSelectPackage"
                      @doLookComboDetailsClick="doAfterSelectedItemDetails"></combo-Item>
        </div>

        <div v-else>
          <from-add-box :titleName="'关联套餐'"
                        :required="true"
                        @onAddSelect="onSelectPackage"></from-add-box>
        </div>

        <div class="coupon_edit_pr pr">
          <from-amount-box :titleName="'特价金额'"
                           :required="true"
                           :decimalPlaces="2"
                           :unitName="'元'"
                           :value="dataInfo.you_hu_money"
                           @inputChange="chgYouHuMoney"></from-amount-box>
          <span class="pa">到店支付</span>
        </div>
      </div>

      <div v-if="dataInfo.type==4">
        <div class="coupon_edit_pr pr">
          <from-amount-box :titleName="'抵扣金额'"
                           :required="true"
                           :decimalPlaces="2"
                           :value="dataInfo.di_xian_jin"
                           :unitName="'元'"
                           @inputChange="chgdiXianJin"></from-amount-box>
        </div>
        <from-text-box :titleName="'适用范围'"
                       :required="true"
                       :value="dataInfo.scopeExplain"
                       :placeholder="'请输入优惠券名称，15个字内'"
                       @inputEvent="chgScopeExplain"></from-text-box>
      </div>

      <from-date-single-box :fromTitle="'有效期'"
                            :required="true"
                            :minDate="$utils.formatDateToLine(Date.new())"
                            :dateTime="dataInfo.time_end"
                            @changeDate="changeSaveDate"></from-date-single-box>

      <from-checkbox-box :titleName="' '"
                         :checkedValue="Number(dataInfo.only_newuser)>0"
                         :checkedName="'限新生领取'"
                         :checkedExplain="'开启后在校学生不可领取'"
                         @changeChecked="changeChecked"></from-checkbox-box>
      <from-explain-box :titleName="'使用说明'"
                        :value="dataInfo.coupon_discribe"
                        @inputEvent="chgCouponDiscribe"></from-explain-box>
      <!-- 按钮 -->
      <div>
        <from-btn-box @doBtnRightClick="submit"
                      @doBtnLeftClick="cancel"></from-btn-box>
      </div>

    </div>
    <custom-dialog :title="'选择套餐'"
                   :visible.sync="isSelectComboListShow"
                   :before-close="doComboListCloseClick">
      <combo-selection-list :dataSourceLsit="selectComboList"
                            @addSelectedItem="doAfterAddSelectCombo"
                            @selectedItemDetails="doAfterSelectedItemDetails"
                            @noCancelSelected="doComboListCloseClick"></combo-selection-list>
    </custom-dialog>

    <custom-dialog :title="'套餐详情'"
                   :visible.sync="isSelectComboDetailsShow"
                   :before-close="doComboDetailsCloseClick">
      <combo-select-details :detailInfo="comboItem"></combo-select-details>
    </custom-dialog>
  </div>
</template>
<script>

import fromAddBox from '../../common/from-add-box';
import fromAmountBox from '../../common/from-amount-box';
import fromTextBox from '../../common/from-text-box';
import fromAmountMultiBox from '../../common/from-amount-multi-box';
import fromBtnBox from '../../common/from-btn-box';
import fromCheckboxBox from '../../common/from-checkbox-box';
import fromDateSingleBox from '../../common/from-date-single-box';
import fromExplainBox from '../../common/from-explain-box';
import comboItem from './combo-Item';
import comboSelectionList from '../../discount-package/combo-list/combo-selection-list';
import comboSelectDetails from '../../discount-package/combo-select-details';
import { AddSimpleCoupon, EditSimpleCoupon, SelectPackageForCoupon, GetComboInfo } from '../../../../API/workbench';

export default {
  components: {
    fromAddBox,
    fromAmountBox,
    fromTextBox,
    fromAmountMultiBox,
    fromBtnBox,
    fromCheckboxBox,
    fromDateSingleBox,
    fromExplainBox,
    comboItem,
    comboSelectionList,
    comboSelectDetails
  },
  data () {
    return {
      searchText: '',
      checked: true,
      selectComboList: [],
      isSelectComboListShow: false,
      isSelectComboDetailsShow: false,
      comboItem: {
        xykdList: [],
        incidentalsList: []
      },
      parameter: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
        sequence: '', //	字符串	可选		数据源：GET	 排序方向
        searchText: ''//	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
      }
    };
  },
  props: {
    dataInfo: Object // 券信息
  },
  created () {
    console.log(this.dataInfo, '券信息');
    if (this.dataInfo.taocanid && Number(this.dataInfo.taocanid) > 0) {
      this.getComboInfo(this.dataInfo.taocanid);
    }
  },
  watch: {

  },
  computed: {

  },
  methods: {
    // 满减金额
    inputChangeOneEvent (val) {
      this.dataInfo.xiao_fei_tiao_jian = Number(val);
    },
    // 抵现金
    inputChangeTwoEvent (val) {
      this.dataInfo.di_xian_jin = Number(val);
    },
    // 特价金额
    chgYouHuMoney (val) {
      this.dataInfo.you_hu_money = Number(val);
    },
    // 适用范围
    chgScopeExplain (val) {
      this.dataInfo.scopeExplain = val;
    },
    // 选择套餐
    onSelectPackage (Item) {
      SelectPackageForCoupon(this.parameter).then(result => {
        console.log(result.data.PageDataList, '选择套餐添加');
        result.data.PageDataList.forEach(o => {
          o.isOpt = false;
        });
        this.selectComboList = result.data.PageDataList;
        this.isSelectComboListShow = true;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取套餐信息
    getComboInfo (TableID) {
      GetComboInfo(TableID).then(result => {
        this.comboItem = result.data;
      });
    },
    // 查看套餐
    doAfterSelectedItemDetails (item) {
      console.log(item, '查看套餐详情');
      GetComboInfo(item.TableID).then(result => {
        this.isSelectComboDetailsShow = true;
        this.comboItem = result.data;
      });
    },
    // 添加套餐
    doAfterAddSelectCombo (item, callBack) {
      console.log(item, '添加套餐');
      let flag = false;
      if (!item || !item.TableID) {
        layer.alert('请先选择套餐');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.comboItem = item;
      this.dataInfo.taocanid = item.TableID;
      this.dataInfo.packgePrice = this.$utils.mAmountType(item.SalePrice, 2);
      this.dataInfo.scopeExplain = '适用' + item.PackageName;
      this.isSelectComboListShow = false;
    },
    doComboListCloseClick () {
      this.isSelectComboListShow = false;
    },
    doComboDetailsCloseClick () {
      this.isSelectComboDetailsShow = false;
    },
    // 抵扣金额
    chgdiXianJin (val) {
      this.dataInfo.di_xian_jin = Number(val);
    },
    // 使用说明
    chgCouponDiscribe (val) {
      this.dataInfo.coupon_discribe = val;
    },
    // 是否允许新用户
    changeChecked (val) {
      this.dataInfo.only_newuser = val ? 1 : 0;
    },
    changeSaveDate (date) {
      console.log(date, '有效期');
      this.dataInfo.time_end = date;
    },
    // 验证
    checkSubmitData () {
      let flag = false;
      // 2：满减 3：特价 4：代金券'
      if (Number(this.dataInfo.type) == 2) {
        if (!this.dataInfo.xiao_fei_tiao_jian || !this.dataInfo.di_xian_jin) {
          layer.alert('报名金额的满减内容不能为空');
          flag = true;
        }
      } else if (Number(this.dataInfo.type) == 3) {
        if (!this.dataInfo.taocanid) {
          layer.alert('关联套餐不能为空');
          flag = true;
        } else if (!this.dataInfo.you_hu_money) {
          layer.alert('特价金额不能为空');
          flag = true;
        }
      } else if (Number(this.dataInfo.type) == 4) {
        if (!this.dataInfo.di_xian_jin) {
          layer.alert('抵扣金额不能为空');
          flag = true;
        }
      }
      if (!this.dataInfo.scopeExplain) {
        layer.alert('适用范围不能为空');
        flag = true;
      } else if (this.dataInfo.scopeExplain.length > 15&&Number(this.dataInfo.type) != 3) {
        layer.alert('适用范围不能超过15个字');
        flag = true;
      } else if (!this.dataInfo.time_end) {
        layer.alert('有效期不能为空');
        flag = true;
      }

      return flag;
    },
    submit (callBack) {
      let isWrong = this.checkSubmitData();
      if (isWrong) {
        if (callBack) {
          callBack();
        }
        return;
      }
      console.log(this.dataInfo, '提交');
      if (this.dataInfo.id && Number(this.dataInfo.id) > 0) {  // 修改接口
        EditSimpleCoupon(this.dataInfo.id, this.dataInfo).then(result => {
          layer.alert('修改成功');
          this.$emit('submit', 'Edit', this.dataInfo);
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      } else {
        AddSimpleCoupon(this.dataInfo).then(result => {
          layer.alert('新增成功');
          this.$emit('submit', 'Add', this.dataInfo);
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      }
    },
    cancel () {
      console.log('取消');
      this.$emit('cancel');
    }
  }
};
</script>

