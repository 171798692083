<template>
  <div class="other_attach_item">
    <img class="img_box"
         :src="vUrl"
         alt=""
         mode="aspectFit">
    <div class="list_name">
      <span class="list_name_text">{{ item.MainDemoName }}</span>
    </div>
    <div class="list_btn"
         @click="clickEvent"></div>
  </div>
</template>
<script>
export default {
  data () {
    return {};
  },
  props: {
    item: Object,
  },
  computed: {
    vSizeText () {
      // let mb= Number(this.item.SizeNum)/1024/1024
      // if(mb>0.1){
      //   return '('+Number(mb.toFixed(1)) +'MB)'
      // }else{
      //   return '('+Number((mb*1024).toFixed(1)) +'KB)'
      // }
      // return '('+Number(mb.toFixed(3)) +'MB)'
      return '(' + Number(this.$utils.mMoneyType((Number(this.item.AttachSize) / 1024 / 1024), 3)) + 'MB)'
    },
    vUrl () {
      return require("../../../../public/cw-img/file_icon@1.png");
    },
    vClass () {
      if (this.item.AttachTypeKey == "1") {
        return "type_01";
      } else if (this.item.AttachTypeKey == "2") {
        return "type_02";
      } else if (this.item.AttachTypeKey == "3") {
        return "type_04";
      } else if (this.item.AttachTypeKey == "4") {
        return "type_03";
      } else if (this.item.AttachTypeKey == "5") {
        return "type_05";
      }
    },
  },
  methods: {
    clickEvent () {
      console.log("this.item", this.item)
      this.$emit("clickEvent", this.item)
      // this.testdownLoadFile();
    },
    testdownLoadFile () {
      if (this.item.CoverUrl || this.item.AttachViewUrl) {
        let url = ''
        if (this.item.AttachViewUrl) {
          url = this.item.AttachViewUrl//this.$store.getters.PCWebURL + this.item.AttachViewUrl + '?sign=' + this.$store.getters.signData.Sign
        } else {
          url = this.$store.getters.CDNURL + this.item.CoverUrl;
        }
        let link = document.createElement('a');
        // 这里是将url转成blob地址，
        fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
          link.href = URL.createObjectURL(blob);
          console.log(link.href);
          link.download = '';
          link.download = this.item.MainDemoName;
          document.body.appendChild(link);
          link.click();
          // layer.alert("下载成功")
        });
      }
    },
  },
};
</script>
<style scoped>
.other_attach_item {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border-bottom: 1px solid #e8ebef; */
  margin-bottom: 28px;
}
.other_attach_item .img_box {
  width: 34px;
  height: 40px;
}
.other_attach_item .list_name {
  flex: 5;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.other_attach_item .list_name_text {
  font-size: 14px;
}
.other_attach_item .list_btn {
  /* flex: 1; */
  width: 76px;
  height: 25px;
  background: url(../../../../public/image/courseware_icon_02.png) center
    no-repeat;
  background-size: 20px;
  cursor: pointer;
}
.other_attach_item .list_btn .list_btn_text {
}
</style>

