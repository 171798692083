<template>
  <div class="margin_left_right_div">
    <div class="template_minOrmax_width_div">
      <el-container class="page">
        <el-main class="page_content">
          <div class="class_hour_consume clearfix">
            <div class="class_statistics_details fl">
              <leftSide :formTitle="'今天 '"
                        :dataInfo="ClassCountAmountDetail.CountUsedToday"
                        :secondCount="Number(ClassCountAmountDetail.CountUsedToday.Total_ClassCount)"
                        :thirdCount="Number(ClassCountAmountDetail.CountUsedToday.Attence3)"
                        @showDetail="showDetail(ClassCountAmountDetail.CountUsedToday)">
              </leftSide>
              <leftSide :formTitle="'昨天 '"
                        :dataInfo="ClassCountAmountDetail.CountUsedYesterday"
                        :secondCount="Number(ClassCountAmountDetail.CountUsedYesterday.Total_ClassCount)"
                        :thirdCount="Number(ClassCountAmountDetail.CountUsedYesterday.Attence3)"
                        @showDetail="showDetail(ClassCountAmountDetail.CountUsedYesterday)">
              </leftSide>
              <leftSide :formTitle="'本月日均'"
                        :btnShow="1"
                        :dataInfo="ClassCountAmountDetail.CountUsedThisMonth"
                        :secondCount="Number(ClassCountAmountDetail.CountUsedThisMonth.ClassCountRate)"
                        :thirdCount="Number(ClassCountAmountDetail.CountUsedThisMonth.Attence3Rate)">
              </leftSide>
              <leftSide :formTitle="'欠课时'"
                        :dataInfo="ClassCountAmountDetail.CountUsedOwedClassCount"
                        :secondTitle="'欠课时总数'"
                        :secondCount="Number(ClassCountAmountDetail.CountUsedOwedClassCount.ClassCount)"
                        :thirdTitle="'欠课时学生'"
                        :thirdCount="Number(ClassCountAmountDetail.CountUsedOwedClassCount.totalPerson)"
                        @showDetail="showDetail('欠课时')">
              </leftSide>
            </div>
            <div class="apply_payment_echarts fl">
              <classSpendDayReport @showDetail="showDetail"></classSpendDayReport>
              <classSpendMonthReport @showDetail="showDetail"></classSpendMonthReport>
              <classSpendForTeacher></classSpendForTeacher>
              <classCountReport :className="'apply_payment_echarts_hous'"
                                :idName="'hour9'"
                                :echartClass="'echarts_m'"></classCountReport>
            </div>
          </div>
        </el-main>
        <!-- 弹窗区域 -->
        <list ref="classSpendGrade"
              :listTitle="listTitle"
              :extendSearchCondition="extendSearchCondition"
              :tableData="classSpendGradeList"
              :totalNum="totalNum"
              :PageCount="PageCount"
              :tableColumns="tableColumns"
              :rowKey='rowKey'
              :detailColumns="detailColumns"
              @loadTableData="loadTableData"></list>
        <classSpendList ref="classSpendListGrade"
                        :listTime="classSpendListTime"
                        :listTitle="classSpendListTitle"
                        :tableData="classSpendListData"
                        :tableColumns="classSpendTableColumns">
        </classSpendList>
      </el-container>
      <div>
        <dialog-factory ref="dialogFactory"></dialog-factory>
      </div>
    </div>
  </div>
</template>
<script>
import classSpendDayReport from './class-spend-day-report';
import classSpendMonthReport from './class-spend-month-report';
import classSpendForTeacher from '../teacher-grade/class-spend-for-teacher';
import classCountReport from '../class-grade/class-count-report';
import leftSide from './left-side';
import list from '../../list/index';
import classSpendList from './class-spend-list';
import dialogFactory from '../../common/dialog-factory';
import { getClassCountSpendStatistics, getClassCountSpendReportByDay, getClassCountSpendReportByMonth, MessageSearchPageForClassCountUsed } from '../../../API/workbench';
export default {
  name: 'classSpendGrade',
  data () {
    return {
      ClassCountAmountDetail: {
        CountUsedOwedClassCount: {},
        CountUsedThisMonth: {},
        CountUsedToday: {},
        CountUsedYesterday: {}

      },
      listTitle: '', // 欠课时列表标题
      classSpendListTitle: '', // 课消日报标题
      classSpendListTime: '', // 课消日报时间
      classSpendListData: [], // 课消列表数据
      classSpendGradeList: [], // 欠课时列表数据
      PageCount: 0,
      totalNum: 0,
      rowKey: 'OLAPKey',
      tableColumns: [ // table 列
        {
          label: 'OLAPKey',
          prop: 'OLAPKey',
          width: 0,
          sortable: false,
          align: 'left',
          isShow: false,
          type: '',
          decimalPlace: 0
        },
        {
          label: '上课日期',
          prop: 'ClassTime',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '上课时间',
          prop: 'BeginClassTime',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '课程安排',
          prop: 'CourseNameKeyValue',
          width: 150,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '客户关系/手机号',
          prop: 'CustomerRelationshipKeyValue',
          width: 180,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '考勤课消结果',
          prop: 'AttenceStatusKeyValue',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '欠课时数',
          prop: 'ClassCount',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '操作',
          prop: '',
          width: '',
          sortable: false,
          align: 'center',
          tableBtnGroup: [
            {
              name: '详情',
              eventName: this.$initJson.baseFunctionBtnName.detail,
              Stutas: 'StutasKey',
              className: () => {
                return 'btn_light_white';
              }
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        }
      ],
      classSpendTableColumns: [ // table 列
        {
          label: 'OLAPKey',
          prop: 'OLAPKey',
          width: 0,
          sortable: false,
          align: 'left',
          isShow: false,
          type: '',
          decimalPlace: 0
        },
        {
          label: '课消日期',
          prop: 'SetUpTime',
          width: '',
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '上课节数',
          prop: 'HavedClass',
          width: '',
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '总课消数',
          prop: 'Total_ClassCount',
          width: '',
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '欠课时数',
          prop: 'Total_DebtClassCount',
          width: '',
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '试课课消',
          prop: 'TrialClassCount',
          width: '',
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '签到课时消耗',
          prop: 'ClassCount3',
          width: 180,
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '请假课时消耗',
          prop: 'ClassCount4',
          width: 180,
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '旷课课时消耗',
          prop: 'ClassCount5',
          width: 180,
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '未考勤人数',
          prop: 'Attence2',
          width: '',
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        }
      ],
      extendSearchCondition: [// 扩展查询条件
        {
          type: this.$initJson.extendSearchConditionType.dateRange, // 日期范围
          formTitle: '上课日期',
          fieldList: [
            {
              name: 'startTime', // 参数名
              defaultValue: '', // 默认值
              value: '', // 绑定值
              validateHandle: '', // 自定义验证函数
              sourceData: []// 数据源
            },
            {
              name: 'endTime',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: []
            }
          ]
        }
      ],
      detailColumns: [
        { label: '上课日期', prop: 'ClassTime' },
        { label: '课名', prop: 'CourseNameKeyValue' },
        { label: '学生名', prop: 'StudentKeyValue' },
        { label: '考勤课消结果', prop: 'AttenceStatusKeyValue' },
        { label: '欠课时数', prop: 'ClassCount' },
        { label: '上课时间', prop: 'BeginClassTime' },
        { label: '主讲老师', prop: 'LecturerKeyValue' },
        { label: '关系', prop: 'CustomerRelationshipKeyValueName' },
        { label: '课单号', prop: 'xykdKeyValue' },
        { label: '打考勤人', prop: 'AttenceOpratorKeyValue' },
        { label: '班名', prop: 'GradeClassKeyValue' },
        { label: '教室', prop: 'classRoomName', isShowTitle: true },
        { label: '手机', prop: 'CustomerPhoneName' },
        { label: '剩余课时', prop: 'CurrentCount' }
      ]

    };
  },
  components: {
    leftSide, classSpendDayReport, classSpendMonthReport, classSpendForTeacher, classCountReport, list, classSpendList, dialogFactory
  },
  mounted () {
    this.getClassCountSpendData();
  },
  methods: {
    getClassCountSpendData () {
      getClassCountSpendStatistics().then(result => {
        this.ClassCountAmountDetail = result.data;
      });
    },
    showDetail (type, startTime, endTime) {
      let initSelectedParams = [];
      let time = '';
      if (startTime && endTime) {
        startTime = startTime.split('/')[0] + '-' + startTime.split('/')[1] + '-' + startTime.split('/')[2];
        endTime = endTime.split('/')[0] + '-' + endTime.split('/')[1] + '-' + endTime.split('/')[2];
        time = startTime.split('-');
      }

      switch (type) {
        case this.ClassCountAmountDetail.CountUsedToday:
          this.listTitle = '课时消耗明细列表';
          initSelectedParams.push({ name: 'startTime', value: this.ClassCountAmountDetail.CountUsedToday.SetUpTime }, { name: 'endTime', value: this.ClassCountAmountDetail.CountUsedToday.SetUpTime });
          this.$refs.classSpendGrade.isShowListDialog = true;
          this.$refs.classSpendGrade.initSelectedParams(initSelectedParams);
          break;
        case this.ClassCountAmountDetail.CountUsedYesterday:
          this.listTitle = '课时消耗明细列表';
          initSelectedParams.push({ name: 'startTime', value: this.ClassCountAmountDetail.CountUsedYesterday.SetUpTime }, { name: 'endTime', value: this.ClassCountAmountDetail.CountUsedYesterday.SetUpTime });
          this.$refs.classSpendGrade.isShowListDialog = true;
          this.$refs.classSpendGrade.initSelectedParams(initSelectedParams);
          break;
        case '欠课时':
          this.listTitle = '欠课时统计列表';
          this.$refs.classSpendGrade.isShowListDialog = true;
          this.$refs.classSpendGrade.initSelectedParams(initSelectedParams);
          break;
        case '日报':
          this.classSpendListTitle = '课时消耗统计日报';
          this.$refs.classSpendListGrade.isShowListDialog = true;
          this.classSpendListTime = time[0] + '年' + time[1] + '月';
          this.classSpendLoadTableDay(startTime, endTime);
          break;
        case '月报':
          this.classSpendListTitle = '课时消耗统计月报';
          this.$refs.classSpendListGrade.isShowListDialog = true;
          this.classSpendListTime = time[0] + '年';
          this.classSpendLoadTableMonth(startTime, endTime);
          break;
        default:
          break;
      }
    },
    loadTableData (queryParams) {
      if (queryParams.orderBy == '' && queryParams.sequence == '') {
        queryParams.orderBy = 'ClassTime';
        queryParams.sequence = 'DESC';
      }
      if (this.listTitle == '欠课时统计列表') {
        queryParams.ISClassHourDebtKey = 1;
      }
      MessageSearchPageForClassCountUsed(queryParams).then(result => {
        this.classSpendGradeList = [];
        if (result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach((obj, index) => {
            obj.CustomerPhoneName = this.$utils.phoneModulePower(obj.CustomerPhoneName) || '无手机号';
            obj.CustomerRelationshipKeyValueName = obj.CustomerRelationshipKeyValue;
            obj.CustomerRelationshipKeyValue = obj.CustomerRelationshipKeyValue + '/' + (obj.CustomerPhoneName || '无手机号');
            obj.BeginClassTime = obj.BeginClassTime.substring(0, 5) + '-' + obj.EndClassTime.substring(0, 5);
            if (obj.ClassHourKey == 5 || obj.ISClassHourDebtKey == 1) {
              if (obj.AttenceStatusKeyValue == '出勤') {
                obj.AttenceStatusKeyValue = '签到';
              }
              obj.AttenceStatusKeyValue = obj.AttenceStatusKeyValue + ' 扣' + Number(obj.ClassCount) + '课时';
            }
            if (obj.ClassCount) {
              obj.ClassCount = Number(obj.ClassCount);
            }
            if (obj.ISClassHourDebtKey == 0) {
              obj.ClassCount = 0;
            }
            obj.CurrentCount = Number(obj.CurrentCount);

            if (obj.UnitKeyValue) {
              obj.CurrentCount = obj.CurrentCount + obj.UnitKeyValue;
            }

            obj.ClassCount = obj.ClassCount + '课时';
            this.classSpendGradeList.push(obj);
          });
        }
        this.totalNum = result.data.Total;
        this.PageCount = result.data.PageCount;
        this.$refs.classSpendGrade.isLoading = false;
      });
    },
    classSpendLoadTableDay (startTime, endTime) {
      getClassCountSpendReportByDay(startTime, endTime).then(result => {
        this.classSpendListData = [];
        if (result.data.DataList.length > 0) {
          result.data.DataList.forEach((obj, index) => {
            obj.SetUpTime = obj.SetUpTime.split('-')[1] + '.' + obj.SetUpTime.split('-')[2] + ' ' + this.$utils.getWeekTime(obj.SetUpTime);
            obj.Total_ClassCount = Number(obj.Total_ClassCount);

            obj.ClassCount3 = Number(obj.ClassCount3);
            obj.ClassCount3 = '签到' + obj.Attence3 + '人 消耗' + obj.ClassCount3 + '课时';

            obj.ClassCount4 = Number(obj.ClassCount4);
            obj.ClassCount4 = '请假' + obj.Attence4 + '人 消耗' + obj.ClassCount4 + '课时';

            obj.ClassCount5 = Number(obj.ClassCount5);
            obj.ClassCount5 = '旷课' + obj.Attence5 + '人 消耗' + obj.ClassCount5 + '课时';

            obj.Total_DebtClassCount = Number(obj.Total_DebtClassCount);

            obj.TrialClassCount = Number(obj.TrialClassCount);

            this.classSpendListData.push(obj);
          });
          console.log(this.classSpendListData);
        }
      });
    },
    classSpendLoadTableMonth (startTime, endTime) {
      getClassCountSpendReportByMonth(startTime, endTime).then(result => {
        this.classSpendListData = [];
        if (result.data.DataList.length > 0) {
          result.data.DataList.forEach((obj, index) => {
            obj.SetUpTime = obj.SetUpTime.split('-')[0] + '.' + obj.SetUpTime.split('-')[1];
            obj.Total_ClassCount = Number(obj.Total_ClassCount);

            obj.ClassCount3 = Number(obj.ClassCount3);
            obj.ClassCount3 = '签到' + obj.Attence3 + '人 消耗' + obj.ClassCount3 + '课时';

            obj.ClassCount4 = Number(obj.ClassCount4);
            obj.ClassCount4 = '请假' + obj.Attence4 + '人 消耗' + obj.ClassCount4 + '课时';

            obj.ClassCount5 = Number(obj.ClassCount5);
            obj.ClassCount5 = '旷课' + obj.Attence5 + '人 消耗' + obj.ClassCount5 + '课时';

            obj.Total_DebtClassCount = Number(obj.Total_DebtClassCount);

            obj.TrialClassCount = Number(obj.TrialClassCount);

            this.classSpendListData.push(obj);
          });
          console.log(this.classSpendListData);
        }
      });
    }
  }
};
</script>

