<template>
  <div class="class_active massive_bg class_statistics_details_box"
       :class="[setColor]">
    <div class="class_statistics_details_title"
         v-show="title">
      {{title}}
    </div>
    <div class="class_statistics_details_content">
      <p>{{count}}</p>
      <p>{{content}}</p>
    </div>
    <a @click.stop="showDetail"
       class="class_statistics_details_btn">
      查看详情
    </a>
  </div>
</template>
<script>
export default {
  // 简略显示各种班级信息
  name: 'briefClassInfo',
  data () {
    return {
      btnShow: true
    };
  },
  // setColor 绑定样式. 有'dot_green','dot_blue','dot_cyan','dot_yellow'
  props: ['setColor', 'title', 'count', 'content'],
  methods: {
    showDetail () {
      this.$emit('showDetail');
    }
  }
};
</script>

