<template>
  <div class="home_nav">
    <div class="home_nav_back">
      
    </div>
    <div class="home_nav_text">{{dataInfo.Title}}</div>
    <div class="home_nav_tool">
      <!-- <div class="home_nav_tool_status">签到 10 | 未到 1</div> -->
      <div class="home_nav_tool_button"
           @click.stop="changeIsShowTeachingStep">教学步骤</div>
      <div class="home_nav_tool_button"
           @click.stop="changeIsShowEvaluation">课评话术</div>
      <div class="home_nav_back_icon button"
         @click.stop="reback"></div>
    </div>
    

  </div>
</template>
<script>
import popupView from '../popup-view';
export default {
  data () {
    return {
    };
  },
  props: {
    dataInfo: Object
  },
  components: {
    popupView
  },
  created () {

  },
  methods: {
    // 退出教学模式
    reback () {
      this.$emit('reback');
    },
    changeIsShowTeachingStep () {
      this.$emit('showPopupStep');
    },
    changeIsShowEvaluation () {
      this.$emit('showPopupEvaluation');
    }
  }
};
</script>