<template>
  <!-- 通用列表 norole-->
  <div class="headquarters_info_summarizing">
    <div class="headquarters_info_search pr">
      <input-search-contain-btn v-model="searchTextV"
                                :width="'280px'"
                                :placeholder="'请输入'"
                                @onSearchEvent="search"
                                @clearSearch="clear"></input-search-contain-btn>
         
      <div class="summarizing_number_right_btn"
           style="top: 15px;right: 20px;">
        <!-- <function-btn v-if="vZYPower"
                      :item="{ name: '添加直营校区', ModulePowerKey: '' }"
                      :className="'el-button btn_light_blue btn_hover_bg_blue'"
                      @clickItem="addCampus('ZY')">
        </function-btn>
        <function-btn v-if="vJMPower"
                      :item="{ name: '添加加盟校区', ModulePowerKey: '' }"
                      :className="'el-button btn_light_blue btn_hover_bg_blue'"
                      @clickItem="addCampus('JM')">
        </function-btn> -->
        <div class="menu_list" style="padding-right: 10px;" @click="preparingSetListClick" v-if="preparingSetList.length > 0">
           {{preparingSetList[1].MainDemoName.replace("天数","")}} <span class="font_blue">{{preparingSetList[1].SetContent}}</span>天，{{preparingSetList[0].MainDemoName}} <span class="font_blue">{{preparingSetList[0].SetContent}}</span>秒
        </div>
           <custom-dialog :title="'备课设置'"
                   :visible.sync="isShowApplyPortFormPopup"
                   :before-close="closeApplyPortFormPopup">
            <apply-port-form @close="closeApplyPortFormPopup" :preparingSetList="preparingSetList"
                            @afterSuccess="getSearchPageForCampusPortApplyRecord"></apply-port-form>
          </custom-dialog>
      </div>
    </div>
    <div class="table_list_content introduce_list campusManagement_list">
      <table-list ref="tableListRef"
                  :tableData="tableData"
                  :position="''"
                  :tableColumns="tableColumns"
                  :rowKey="rowKey"
                  :totalNum="totalNum"
                  :defaultSort="defaultSort"
                  :queryParams="params"
                  :footerContent="'共' + totalNum + '条数据'"
                  @loadTableData="loadTableData"></table-list>
    </div>
  </div>
</template>
<script>
import { GetSaaSClientSet ,getsLessonPreparationSettings} from '../../../../API/workbench.js';
import inputSearchContainBtn from '../../../report/common/input-search-contain-btn';
import tableList from '../../../common/table-list/index';
import applyPortForm from './port-apply-record-list/apply-port-form';
export default {
  data () {
    return {
      getSaaSClientSet: [],
        isShowApplyPortFormPopup :false,
        portApplyRecordList :[],
        preparingSetList :[], //备课列表数据
        searchTextV : ''
    };
  },
  props: {
    tableData: Array, // 列表数据
    tableColumns: Array, // table column,
    rowKey: [String, Number], // 显示详情对应绑定的ID
    totalNum: Number, // 分页总共条数
    params: Object,
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: '', order: 'ascending' };
      }
    },
    
  },
  components: {
    inputSearchContainBtn,
    tableList,
    applyPortForm
  },
  watch: {

  },
  created () {
    this.getMenDianSet();
    this.getsLessonPreparationSettingsFn()
  },
  computed: {
    vZYPower () {
      if (this.getSaaSClientSet.length > 0) {
        let locaIndex = this.getSaaSClientSet.findIndex(o => {
          return (o.SetTypeKey == 6 && o.SetKey == 1) || (o.SetTypeKey == 7 && o.SetKey == 1);
        });
        if (locaIndex >= 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    vJMPower () {
      if (this.getSaaSClientSet.length > 0) {
        let locaIndex = this.getSaaSClientSet.findIndex(o => {
          return (o.SetTypeKey == 4 && o.SetKey == 1) || (o.SetTypeKey == 5 && o.SetKey == 1);
        });
        if (locaIndex >= 0) {
          return true;
        } else {
          return false;
        }
      }
    }
  
  },
  methods: {
    // 获取门店设置
    getMenDianSet () {
      GetSaaSClientSet().then(result => {
        this.getSaaSClientSet = result.data;
        console.log('GetSaaSClientSet', result.data);
      });
    },
      // 备课设置弹出框数据
    getSearchPageForCampusPortApplyRecord (v) {
      // modifyLessonPreparationSettings(this.params).then(result => {
      //   this.portApplyRecordList = result.data.PageDataList;
      //   console.log('备课设置弹出框数据', result.data.PageDataList);
      // }, error => {
      //   layer.alert(error.msg);
      // });
       console.log(v,"$emit事件")
       this.getsLessonPreparationSettingsFn()
    },
    //备课设置弹出框
    preparingSetListClick(){
        console.log("备课设置弹出框")
        this.isShowApplyPortFormPopup = true;
        //  this.getSearchPageForCampusPortApplyRecord();
    },
     //获取备课设置
    getsLessonPreparationSettingsFn(){
        
         getsLessonPreparationSettings().then(result => {
          console.log(result.data,"获取备课设置")
            this.preparingSetList = result.data;
          }, error => {
            layer.alert(error.msg);
          }).finally(() => {
            // if (callback) {
            //   callback();
            // }
          });
    },
    // 添加
    addCampus (type) {
      this.$emit('addCampus', type);
    },
    // 搜索
    search () {
      console.log('搜索', this.params);
       this.params.searchText = this.searchTextV;
      this.initParams();
      // this.$refs.tableListRef.expandRowKeys = [];
      this.$emit('loadTableData', this.params);
    },
    // 清除
    clear () {
      console.log('清除', this.params);
      this.params.searchText = '';
      this.initParams();
      // this.$refs.tableListRef.expandRowKeys = [];
      this.$emit('loadTableData', this.params);
    },
    initParams () {
      this.params.pageIndex = 0;
      this.params.orderBy = '';
      this.params.IsActiveKey = '';//	整型	可选		数据源：GET	是否活跃校区
      this.params.IsStopKey = ''; //	整型	可选		数据源：GET	是否停用
      this.params.IsExpiredKey = '';//	整型	可选		数据源：GET	是否已过期
      this.params.IsExpired30Key = ''; //	整型	可选		数据源：GET	是否30天内过期
    },
    // 排序
    loadTableData (queryParams) {
      console.log('排序', queryParams);
      this.$emit('loadTableData', queryParams);
    },
     closeApplyPortFormPopup () {
      this.isShowApplyPortFormPopup = false;
    }
  }
};
</script>
<style>
</style>

