<!-- 操作 -->
<template>
  <el-table-column :fixed="columnData.fixed||false"
                   :label="columnData.label"
                   :prop="columnData.prop"
                   :width="columnData.width"
                   :align="columnData.align||'center'"
                   :header-align="columnData.headerAlign||'center'"
                   :label-class-name="columnData.columnClass">
    <template slot-scope="scope">
      <div v-if="!scope.row.isEmpertyRow">
        <span v-for="(btnInfo,indexs) in columnData.extend.tableBtnGroup"
              :key="indexs">
          <div v-if="(typeof(btnInfo.name)=='function'&&btnInfo.name(scope.row))||btnInfo.name"
               :class="[
               hasModulePower(btnInfo)?'':'norole',
               isBtnHide(btnInfo.extend&& typeof(btnInfo.extend.isHide)=='function'?!btnInfo.extend.isHide(scope.row):true),
               btnInfo.extend.className,
               mSetClassName(typeof(btnInfo.extend.setClassName)=='function'&&btnInfo.extend.setClassName(scope.row))]"
               class="el-button el-button--default el-button--mini tableBtn"
               size="mini"
               @click.stop="clickEvent(scope.row,btnInfo,$event)">
            <span>{{typeof(btnInfo.name)=='function'?btnInfo.name(scope.row):btnInfo.name}}</span>
          </div>
        </span>
      </div>
      <div v-else
           class="empoty_row">
        <el-button class="tableBtn btn_light_blue btn_hover_bg_blue"></el-button>
      </div>
    </template>
  </el-table-column>
</template>

<script>

export default {
  data () {
    return {
    };
  },
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                type: 'expand', // 展开
                click: (rowData) => { }, // 支持点击
                loadtableData: (rowData, callBack) => { }, // 获取详情数据
                expandRowColumns: [] // 展开行的tableColomns
              }
            ]
          }
        };
      }
    }
  },
  components: {

  },
  computed: {
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    }
  },
  created () {
  },

  methods: {
    clickEvent (rowData, btnInfo, e) {
      let PowerKey = (btnInfo.extend ? btnInfo.extend.ModulePowerKey : '');
      if (this.hasModulePower(btnInfo)) {
        if (typeof btnInfo.extend.click == 'function') {
          if (btnInfo.type == 'expand') {
            let cellClientWidth = e.currentTarget.offsetParent.offsetParent.clientWidth;
            let positionLeft = e.currentTarget.offsetParent.offsetParent.offsetLeft + ((cellClientWidth / this.columnData.extend.tableBtnGroup.length) / 2);
            this.$emit('onClickExpandRow', rowData, btnInfo, positionLeft);
          } else {
            if (btnInfo.showMore) {
              this.$emit('onclickShowMore', rowData, btnInfo, e);
            } else {
              if (btnInfo.extend.IsInquiryHint) {
                if (rowData[btnInfo.extend.IsInquiryHint]) {
                  return false;
                }
                btnInfo.extend.click(rowData, (obj) => {
                  this.$set(rowData, obj.IsInquiryHint, obj.InquiryClassName);
                  btnInfo.extend.setClassName(rowData);
                });
              } else {
                btnInfo.extend.click(rowData);
              }
            }
          }
        }
      } else {
        this.$utils.CheckModulePowerToTips(PowerKey);
      }
    },
    // 判断权限,
    hasModulePower (btnInfo) {
      let PowerKey = (btnInfo.extend ? btnInfo.extend.ModulePowerKey : '');
      return this.$utils.CheckModulePower(this.vModulePowerList, PowerKey);
    },
    // 判断是否隐藏按钮,
    isBtnHide (btnInfo) {
      if (btnInfo) {
        return '';
      } else {
        return 'noBtn';
      }
    },
    mSetClassName (val) {
      if (val) {
        return val;
      } else {
        return 'btn_light_blue btn_hover_bg_blue';
      }
    }
  }
};
</script>

<style scoped>
.noBtn {
  /* display: none; */
}
</style>