import store from '../store';
import { GetSaaSClientMsg, CWSVerifyLocation, WEBVPreviewVerifyLocation, CWSPreviewVerifyLocation, VerifyLocation } from '../API/workbench';
import fun from '../assets/KPI';
import bus from '../js/bus';
import initJson from '../js/initJson';
export function setMoneyformatToParseInt (num) {
  return Number(num)
    .toFixed(0)
    .toString()
    .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
}
export function formatNumber (n) {
  const str = n.toString();
  return str[1] ? str : `0${str}`;
}
export function group (array, subGroupLength) {
  let index = 0;
  let newArray = [];
  while (index < array.length) {
    newArray.push(array.slice(index, (index += subGroupLength)));
  }
  return newArray;
}
export function getPreDay (date) {
  var arr = date.split('-');
  var year = arr[0]; // 获取当前日期的年份
  var month = arr[1]; // 获取当前日期的月份
  var day = arr[2]; // 获取当前日期的日
  // var days = Date.new(year, month, 0);
  // days = days.getDate(); // 获取当前日期中月的天数
  var year2 = year;
  var month2 = parseInt(month);
  var day3 = parseInt(day) - 1;
  if (day3 == 0) {
    // 计算上个月最后一天
    day3 = Date.new(Date.new(date).setDate(0)).getDate();
    month2 = month2 - 1;
  }
  if (month2 == 0) {
    year2 = parseInt(year2) - 1;
    month2 = 12;
  }
  var day2 = day3;
  var days2 = Date.new(year2, month2, 0);
  days2 = days2.getDate();
  if (day2 > days2) {
    day2 = days2;
  }
  if (month2 < 10) {
    month2 = '0' + month2;
  }
  if (day2 < 10) {
    day2 = '0' + day2;
  }
  var t2 = year2 + '-' + month2 + '-' + day2;
  return t2;
}
export function getNextDay (date) {
  var arr = date.split('-');
  var year = arr[0]; // 获取当前日期的年份
  var month = arr[1]; // 获取当前日期的月份
  var day = arr[2]; // 获取当前日期的日
  // var days = Date.new(year, month, 0);
  // days = days.getDate(); // 获取当前日期中月的天数
  var year2 = year;
  var month2 = parseInt(month);
  var day3 = parseInt(day) + 1;
  var lastDay = Date.new(year, month, 0).getDate(); // 当前月最大一天.
  if (day3 > lastDay) {
    // 计算上个月最后一天
    day3 = 1;
    month2 = month2 + 1;
  }
  if (month2 == 13) {
    year2 = parseInt(year2) + 1;
    month2 = 1;
  }
  var day2 = day3;
  var days2 = Date.new(year2, month2, 0);
  days2 = days2.getDate();
  if (day2 > days2) {
    day2 = days2;
  }
  if (month2 < 10) {
    month2 = '0' + month2;
  }
  if (day2 < 10) {
    day2 = '0' + day2;
  }
  var t2 = year2 + '-' + month2 + '-' + day2;
  return t2;
}
export function formatTime (date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  const t1 = [year, month, day].map(formatNumber).join('/');
  const t2 = [hour, minute, second].map(formatNumber).join(':');

  return `${t1} ${t2}`;
}
// 2019年12月20日
export function formatDateToYYYYMMDD (date) {
  date = Date.new(date);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  return year + '年' + month + '月' + day + '日';
}

export function leadTime (time) {
  // 时间前后差距
  let days = newDateDiffIOS(time, formatDateToLine(Date.new()));
  if (days > 0) {
    if (parseInt(days) >= 1) {
      return parseInt(days) + '天后 ';
    } else {
      return '今天';
    }
  } else if (days < 0) {
    if (parseInt(days) <= -1) {
      return parseInt(Math.abs(days)) + '天前 ';
    } else {
      return '今天';
    }
  } else {
    return '今天 ';
  }
}
export function StrForTodoTime (value) {
  return simplyforTodoShowTime(getFormatDateToQuery(value));
}

function simplyforTodoShowTime (value) {
  // 转化回复时间,以回复时间计算出多久之前.
  let currentUnixTime = Math.round(Date.new().getTime() / 1000); // 当前时间的秒数
  let deltaSecond = currentUnixTime - value.getTime() / 1000; // 当前时间与要转换的时间差（ s ）
  let result;

  if (deltaSecond < 60) {
    result = '刚刚';
  } else if (deltaSecond < 3600) {
    result = Math.floor(deltaSecond / 60) + '分钟前';
  } else if (deltaSecond < 86400) {
    result = Math.floor(deltaSecond / 3600) + '小时前';
  } else if (deltaSecond < 2592000) {
    result = Math.floor(deltaSecond / 3600 / 24) + '天前';
  } else {
    let year = Date.new(value).getFullYear();
    let month = Date.new(value).getMonth() + 1;
    let day = Date.new(value).getDate();
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    result = year + '-' + month + '-' + day;
  }
  return result;
}
export function formatDate (date) {
  date = Date.new(date.replace(/-/g, '/'));
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  return year + '-' + month + '-' + day;

  // const t1 = [year, month, day].map(formatNumber).join('/')

  // return `${t1}`
}
// 保留小数位数
export function saveDecimal (value) {
  var x = String(value).indexOf('.') + 1; // 小数点的位置
  var y = String(value).length - x; // 小数的位数   x + 1
  if (x >= 0 && y > 1) {
    return Number(value.toFixed(1));
  } else {
    return value;
  }
}
export function formatDateToBias (date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  return year + '/' + month + '/' + day;
}

export function formTime (date) {
  let Hours = date.getHours();
  let Minutes = date.getMinutes();
  if (Hours < 10) {
    Hours = '0' + Hours;
  }
  if (Minutes < 10) {
    Minutes = '0' + Minutes;
  }
  return Hours + ':' + Minutes;
}
export function formatDateToLine (date) {
  date = Date.new(date);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  return year + '-' + month + '-' + day;
}

export function formatDateToDot (date) {
  date = Date.new(date);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  return year + '.' + month + '.' + day;

  // const t1 = [year, month, day].map(formatNumber).join('/')

  // return `${t1}`
}
export function getWeekByTime (date) {
  const day = date.getDay();
  let week = '';
  if (day === 0) {
    week = '星期日';
  } else if (day === 1) {
    week = '星期一';
  } else if (day === 2) {
    week = '星期二';
  } else if (day === 3) {
    week = '星期三';
  } else if (day === 4) {
    week = '星期四';
  } else if (day === 5) {
    week = '星期五';
  } else if (day === 6) {
    week = '星期六';
  }
  return week;
}

export function getWeek (date) {
  date = Date.new(date.replace(/-/g, '/'));
  const day = date.getDay();
  let week = '';
  if (day === 0) {
    week = '星期日';
  } else if (day === 1) {
    week = '星期一';
  } else if (day === 2) {
    week = '星期二';
  } else if (day === 3) {
    week = '星期三';
  } else if (day === 4) {
    week = '星期四';
  } else if (day === 5) {
    week = '星期五';
  } else if (day === 6) {
    week = '星期六';
  }
  return week;
}
export function getWeekByTwo (date) {
  date = Date.new(date.replace(/-/g, '/'));
  const day = date.getDay();
  let week = '';
  if (day === 0) {
    week = '周日';
  } else if (day === 1) {
    week = '周一';
  } else if (day === 2) {
    week = '周二';
  } else if (day === 3) {
    week = '周三';
  } else if (day === 4) {
    week = '周四';
  } else if (day === 5) {
    week = '周五';
  } else if (day === 6) {
    week = '周六';
  }
  return week;
}
// 选中月份后 返回改月分 年月及该月分最后一天.
export function setChoseEndTime (timer) {
  let Year = timer.getFullYear();
  let Month = timer.getMonth() + 1;
  let EndDate = Date.new(Year, Month, 0).getDate();
  return Year + '/' + Month + '/' + EndDate;
}
export function getWeekByNum (num) {
  let week = '';
  num = parseInt(num);
  if (num === 7 || num === 0) {
    week = '日';
  } else if (num === 1) {
    week = '一';
  } else if (num === 2) {
    week = '二';
  } else if (num === 3) {
    week = '三';
  } else if (num === 4) {
    week = '四';
  } else if (num === 5) {
    week = '五';
  } else if (num === 6) {
    week = '六';
  }
  return week;
}
// 深拷贝
export function parseJson (json) {
  return JSON.parse(JSON.stringify(json));
}
// 正则表达式 验证URL的合法性
export function isURL (str) {
  var strRegex =
    '^((https|http|ftp|rtsp|mms)?://)' +
    "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" + // ftp的user@
    '(([0-9]{1,3}.){3}[0-9]{1,3}' + // IP形式的URL- 199.194.52.184
    '|' + // 允许IP和DOMAIN（域名）
    "([0-9a-z_!~*'()-]+.)*" + // 域名- www.
    '([0-9a-z][0-9a-z-]{0,61})?[0-9a-z].' + // 二级域名
    '[a-z]{2,6})' + // first level domain- .com or .museum
    '(:[0-9]{1,4})?' + // 端口- :80
    '((/?)|' + // a slash isn't required if there is no file name
    "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
  var re = new RegExp(strRegex);
  if (re.test(str)) {
    return true;
  } else {
    return false;
  }
}

export function getCookie (name) {
  let reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
  let arr = document.cookie.match(reg);
  if (arr) {
    return unescape(arr[2]);
  } else {
    return null;
  }
}

export function getWKTRole () {
  // 授权信息.
  let WKTRole = null;
  if (process.env.NODE_ENV === 'development') {
    WKTRole =
      'WKTBackRole=potential_customers_list#report,potential_customers_list#report,total_coupon_receiveRecord0#report,couponUseRecord0#report,coupon_list0#report,share_account_list#report,member_list#report,member_list_push#report,coupon_list1#coupon1,coupon_add1#coupon1,coupon_cashBack1#coupon1,coupon_receiveRecord1#coupon1,couponUseRecord1#coupon1,coupon_list3#coupon3,coupon_add3#coupon3,coupon_cashBack3#coupon3,coupon_ReceiveRecord3#coupon3,couponUseRecord3#coupon3,coupon_list2#coupon2,coupon_add2#coupon2,coupon_receiveRecord2#coupon2,couponUseRecord2#coupon2,billtemp_list#billtemp6,redpaper_list#redpaper,redpaper_add#redpaper,redpaper_ReceiveRecord#redpaper,redpaperUseRecord#redpaper,activity_list6#enrol4,activity_add6#enrol4,activity_enrol_list#enrol4,activity_list2#active,add_activity#active,activity_list3#case,add_activity#case,activity_list4#teacher,add_teacher#teacher,activity_list5#teacher,add_student#teacher,active_cagry_list#configMenu,goods_cagry_list#goods,goods_list1#goods,goods_add#goods,goods_recovery1#goods,goods_list2#taocan,taocan_add#taocan,goods_recovery2#taocan,points_cost_list#points,points_list#points,order_list#order,index_show#setting,enterprise_qrcode#setting,enterprise_logo#setting,wifi_set#setting,cooperatePartner_list#setting,cooperatePartne_add#setting,wechat_set1#wechat,wechat_set2#wechat,wechat_set3#wechat,wechat_set4#wechat,weChat_fansManage#wechat,weChat_fansManage#wechat,weixin_set6#wechat,wechat_set8#wechat,wechat_set9#wechat,wechat_set10#wechat,company_info#company,company_list#company,company_staff#company,company_role#company,add_staff#company,add_company#company,';
  } else {
    WKTRole = getCookie('WKTBackRole');
  } // 管理权限
  return WKTRole;
}
// 公共函数.
export function doOne (actionName, action) {
  // 封装点击事件. 只能单次触发.  间隔事件为1秒.
  window.clickDisableObj = window.clickDisableObj || {};
  const disable = window.clickDisableObj[actionName];
  if (!disable) {
    window.clickDisableObj[actionName] = true;
    setTimeout(() => {
      window.clickDisableObj[actionName] = false;
    }, 300);
    action();
  }
}

// 计算两个日期之间的天数差,兼容IOS
export function newDateDiff (dateString1, dateString2) {
  // 两个时间相差天数 格式  2006-12-18
  //  d1 开始日期
  //  d2 结束日期
  var startDate = Date.parse(dateString1);
  var endDate = Date.parse(dateString2);
  if (startDate > endDate) {
    return 0;
  }
  var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
  return days;
}

// 计算两个日期之间的天数差(不区分前后),兼容IOS
export function newDateDiffIOS (dateString1, dateString2) {
  // 两个时间相差天数 格式  2006-12-18
  //  d1 开始日期
  //  d2 结束日期
  var startDate = Date.parse(dateString1);
  var endDate = Date.parse(dateString2);
  var days = (startDate - endDate) / (1 * 24 * 60 * 60 * 1000);
  if (startDate > endDate) {
    return days;
  } else if (startDate < endDate) {
    return days;
  } else if (startDate == endDate) {
    return 0;
  }
}
export function GetRequest () {
  const url = window.location.search; // 获取url中"?"符后的字串
  const theRequest = new Object();
  if (url.indexOf('?') != -1) {
    const str = url.substr(1);
    const strs = str.split('&');
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1]);
    }
  }
  return theRequest;
}

// 图片压缩
// img    原始图片
// width   压缩后的宽度
// height  压缩后的高度
// ratio   压缩比率
export function compress (img, width, height, ratio) {
  var canvas, ctx, img64;
  canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0, width, height);
  img64 = canvas.toDataURL('image/jpeg/gif', ratio);

  return img64;
}
export function dataURLtoFile (dataurl, filename) {
  // 将base64转换为文件
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {
    type: mime
  });
}

// value为字符串.  返回的值为多久前 年月日.
export function getDistanceNowTime (value) {
  return distanceNowTime(getFormatDateToQuery(value));
}

function distanceNowTime (value) {
  var date = Date.new().getTime() - value.getTime();
  let year = Math.floor(date / 1000 / 3600 / 24 / 365);
  let month = Math.floor(date / 1000 / 3600 / 24 / 30.416) - year * 12;
  let day =
    Math.floor(date / 1000 / 3600 / 24) -
    Math.floor(month * 30.416) -
    year * 365;
  // if (day == 0) {
  //   day = 1;
  // }
  if (year == 0 && month != 0) {
    return month + '个月' + day + '天';
  } else if (month == 0 && year == 0) {
    return day + '天';
  } else if (year != 0 && month == 0) {
    return year + '年' + day + '天';
  } else {
    return year + '年' + month + '个月' + day + '天';
  }
}
export function StrToRelativeTime (value) {
  return simplyToRelativeTime(getFormatDateToQuery(value));
}

function simplyToRelativeTime (value) {
  // 转化回复时间,以回复时间计算出多久之前.
  let currentUnixTime = Math.round(Date.new().getTime() / 1000); // 当前时间的秒数
  let deltaSecond = currentUnixTime - value.getTime() / 1000; // 当前时间与要转换的时间差（ s ）
  let result;
  if (deltaSecond < 60) {
    result = '刚刚';
  } else if (deltaSecond < 3600) {
    result = Math.floor(deltaSecond / 60) + '分钟前';
  } else if (deltaSecond < 86400) {
    result = Math.floor(deltaSecond / 3600) + '小时前';
  } else if (deltaSecond < 2592000) {
    result = Math.floor(deltaSecond / 86400) + '天前';
  } else if (deltaSecond < 31104000) {
    result = Math.floor(deltaSecond / 2592000) + '个月前';
  } else {
    result = Math.floor(deltaSecond / 31104000) + '年前';
  }
  return result;
}
export function getFormatDateToQuery (item) {
  // 为了处理服务器时间***.date数据结构类型. 兼容苹果手机日期格式' / '
  if (item) {
    if (item.date) {
      item = item.date;
    }
    var hmindex = item.indexOf('.');
    if (hmindex > 0) {
      item = item.substr(0, hmindex);
    }
    return Date.new(item.replace(/-/g, '/'));
  } else {
    return null;
  }
}
export function formatDateStr (dateStr, formatStr) {
  // 日期匹配

  var date = getFormatDateToQuery(dateStr);
  return date ? formatDatet(date, formatStr) : '';
}
export function formatDatet (date, formatStr) {
  /*
函数：填充0字符
参数：value-需要填充的字符串, length-总长度
返回：填充后的字符串
*/

  var zeroize = function (value, length) {
    if (!length) {
      length = 2;
    }
    value = new String(value);
    for (var i = 0, zeros = ''; i < length - value.length; i++) {
      zeros += '0';
    }
    return zeros + value;
  };
  return formatStr.replace(
    /"[^"]*"|'[^']*'|\b(?:d{1,5}|M{1,4}|yy(?:yy)?|([hHmstT])\1?|[lLZ]|[\u4e00-\u9fa5])\b/g,
    function ($0) {
      switch ($0) {
        case 'd':
          return date.getDate();
        case 'dd':
          return zeroize(date.getDate());
        case 'ddd':
          return ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'][
            date.getDay()
          ];
        case 'ddddd':
          return ['周日', '周一', '周二', '周三', '周四', '周五', '周六'][
            date.getDay()
          ];
        case 'dddd':
          return [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday'
          ][date.getDay()];

        case 'M':
          return date.getMonth() + 1;
        case 'MM':
          return zeroize(date.getMonth() + 1);
        case 'MMM':
          return [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ][date.getMonth()];
        case 'MMMM':
          return [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
          ][date.getMonth()];
        case 'yy':
          return new String(date.getFullYear()).substr(2);
        case 'yyyy':
          return date.getFullYear();
        case 'h':
          return date.getHours() % 24 || 12;
        case 'hh':
          return zeroize(date.getHours() % 24);
        case 'H':
          return date.getHours();
        case 'HH':
          return zeroize(date.getHours());
        case 'm':
          return date.getMinutes();
        case 'mm':
          return zeroize(date.getMinutes());
        case 's':
          return date.getSeconds();
        case 'ss':
          return zeroize(date.getSeconds());
        case 'l':
          return date.getMilliseconds();
        case 'll':
          return zeroize(date.getMilliseconds());
        case 'tt':
          return date.getHours() < 24 ? 'am' : 'pm';
        case 'TT':
          return date.getHours() < 24 ? 'AM' : 'PM';
        case 'TTT':
          return date.getHours() < 24 ? '早上' : '下午';
        default:
          return '';
      }
    }
  );
}

/**
 *计算二个日期间有几个星期一到星期日
 *date_sta及date_end需是Date型参数
 */
export function getWeekNum (sta, end) {
  let a = Date.new(sta);
  let b = Date.new(end);
  let w = [0, 0, 0, 0, 0, 0, 0];
  while (a <= b) {
    w[a.getDay()]++;
    a.setDate(a.getDate() + 1); // 第二天
  }
  return {
    7: w[0],
    1: w[1],
    2: w[2],
    3: w[3],
    4: w[4],
    5: w[5],
    6: w[6]
  };
}
export function numberToDecimalPlaces (val, decimalPlaces) {
  let vv = Number(val).toFixed(decimalPlaces);
  let n = 1;
  for (let i = 0; i < decimalPlaces; i++) {
    n = n * 10;
  }
  vv = Math.round(vv * n) / n;
  return vv;
}

// 计算两个日期之间的天数差
export function DateDiff (sDate1, sDate2) {
  // sDate1和sDate2是2002.12.18格式
  var aDate, oDate1, oDate2, iDays;
  aDate = sDate1.split('.');
  oDate1 = Date.new(aDate[1] + '-' + aDate[2] + '-' + aDate[0]); // 转换为12-18-2002格式
  aDate = sDate2.split('.');
  oDate2 = Date.new(aDate[1] + '-' + aDate[2] + '-' + aDate[0]);
  iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); // 把相差的毫秒数转换为天数
  return iDays;
}

// date1:小日期   date2:大日期
export function DateDiffDay (date1, date2) {
  var sdate = Date.new(date1);
  var now = Date.new(date2);
  var days = now.getTime() - sdate.getTime();
  var day = Math.ceil(days / (1000 * 60 * 60 * 24));
  return day;
}

/**
 * 获取当前月的第一天
 */
export function getCurrentMonthFirst () {
  var date = Date.new();
  date.setDate(1);
  var month = parseInt(date.getMonth() + 1);
  var day = date.getDate();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  return date.getFullYear() + '-' + month + '-' + day;
}

// 下一年
export function getNextYear (date) {
  let curDate = Date.new(date);
  let fullYear = curDate.getFullYear();
  curDate.setFullYear(fullYear + 1);
  let day = curDate.getDate();
  curDate.setDate(day - 1);
  return curDate;
}
export function getMonthMAX (t) {
  // 时间戳
  var ot = Date.new(t * 1000); // 转为时间对象（js的时间戳是毫秒数）
  var oy = ot.getFullYear(); // 年
  var om = ot.getMonth() + 1; // 月dao
  var tt = Date.new(); // 今天

  var ty = tt.getFullYear(); // 今天年
  var tm = tt.getMonth() + 1; // 今天月
  var gm = (ty - oy) * 12 + (tm - om); // 距今月数
  return gm;
}
/**
 * 获取当前月的最后一天
 */
export function getCurrentMonthLast () {
  var date = Date.new();
  var currentMonth = date.getMonth();
  var nextMonth = ++currentMonth;
  var nextMonthFirstDay = Date.new(date.getFullYear(), nextMonth, 1);
  var oneDay = 1000 * 60 * 60 * 24;
  var lastTime = Date.new(nextMonthFirstDay - oneDay);
  var month = parseInt(lastTime.getMonth() + 1);
  var day = lastTime.getDate();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  return date.getFullYear() + '-' + month + '-' + day;
}
// 获取指定月的最后一天
export function getDataMonthLast (d) {
  let month = d.getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  let youWant = d.getFullYear() + '-' + month + '-' + d.getDate();
  return youWant;
}
// 根据日期返回最后一天 比如 2016-04-15 返回2016-04-30
export function getday (datetime) {
  var d = Date.new(datetime);
  var year = '';
  if (d.getMonth() == 11 || d.getMonth() == 9) {
    year = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + 31;
    return year;
  }
  d.setMonth(d.getMonth() + 1);
  d.setDate(0);
  if (d.getMonth() == 10) {
    year = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
  } else {
    year = d.getFullYear() + '-0' + (d.getMonth() + 1) + '-' + d.getDate();
  }
  return year;
}
/**
 * 获取对应日期的星期数
 */
export function getWeekTimeByNum (day) {
  let week = '';
  if (day === 7 || day === 0) {
    week = '周日';
  } else if (day === 1) {
    week = '周一';
  } else if (day === 2) {
    week = '周二';
  } else if (day === 3) {
    week = '周三';
  } else if (day === 4) {
    week = '周四';
  } else if (day === 5) {
    week = '周五';
  } else if (day === 6) {
    week = '周六';
  }
  return week;
}
export function getWeekTime (date) {
  date =
    date.split('-')[0] + '/' + date.split('-')[1] + '/' + date.split('-')[2];
  let myDate = Date.new(Date.parse(date));
  let day = myDate.getDay();
  let week = '';
  if (day === 0) {
    week = '周日';
  } else if (day === 1) {
    week = '周一';
  } else if (day === 2) {
    week = '周二';
  } else if (day === 3) {
    week = '周三';
  } else if (day === 4) {
    week = '周四';
  } else if (day === 5) {
    week = '周五';
  } else if (day === 6) {
    week = '周六';
  }
  return week;
}
// 计算两个日期相差 返回
export function diffTwoTime (date1, date2) {
  date1 = Date.parse(date1);
  date2 = Date.parse(date2);
  // 计算两个日期之间相差的毫秒数的绝对值
  var ms = Math.abs(date2 - date1);
  // 毫秒数除以一天的毫秒数,就得到了天数
  var days = Math.floor(ms / (24 * 3600 * 1000));
  return days;
}
// 计算两个日期相差 返回（{几天，几时，几分，几秒}每单位）
export function diffTwoTimeTDMSM (startDate, endDate) {
  var returnStr = {};
  var diff = endDate.getTime() - (startDate.getTime() + 24 * 3600); // 时间差的毫秒数

  // 计算出相差天数
  returnStr.days = Math.floor(diff / (24 * 3600 * 1000));

  // 计算出小时数
  var leave1 = diff % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
  returnStr.hours = getFormatDate(Math.floor(leave1 / (3600 * 1000)));
  // 计算相差分钟数
  var leave2 = leave1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
  returnStr.minutes = getFormatDate(Math.floor(leave2 / (60 * 1000)));

  // 计算相差秒数
  var leave3 = leave2 % (60 * 1000); // 计算分钟数后剩余的毫秒数
  returnStr.seconds = getFormatDate(Math.round(leave3 / 1000));

  return returnStr;
}
// 判断数字 最后一位是否为点或为零（中间状态0.，0.0，1.，1.0等）
export function isIntermediateValue (val) {
  let lastPlaceValue = val.toString().substr(val.length - 1, 1);
  let lastTwoPlaceValue = val.toString().substr(val.length - 2, 2);
  if (lastPlaceValue == '.' || lastTwoPlaceValue == '.0') {
    return true;
  } else {
    return false;
  }
}
// 指定日期的前几天，后几天
export function getNextDate (date, day) {
  var dd = date;
  dd.setDate(dd.getDate() + day);
  var y = dd.getFullYear();
  var m =
    dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1;
  var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
  return y + '-' + m + '-' + d;
}

export function getPreMonth (date) {
  var arr = date.split('-');
  var year = arr[0]; // 获取当前日期的年份
  var month = arr[1]; // 获取当前日期的月份
  var day = arr[2]; // 获取当前日期的日
  var days = Date.new(year, month, 0);
  days = days.getDate(); // 获取当前日期中月的天数
  var year2 = year;
  var month2 = parseInt(month) - 1;
  if (month2 == 0) {
    year2 = parseInt(year2) - 1;
    month2 = 12;
  }
  var day2 = day;
  var days2 = Date.new(year2, month2, 0);
  days2 = days2.getDate();
  if (day2 > days2) {
    day2 = days2;
  }
  if (month2 < 10) {
    month2 = '0' + month2;
  }
  var t2 = year2 + '-' + month2 + '-' + day2;
  return t2;
}

export function getNextMonth (date) {
  var arr = date.split('-');
  var year = arr[0]; // 获取当前日期的年份
  var month = arr[1]; // 获取当前日期的月份
  var day = arr[2]; // 获取当前日期的日
  var days = Date.new(year, month, 0);
  days = days.getDate(); // 获取当前日期中的月的天数
  var year2 = year;
  var month2 = parseInt(month) + 1;
  if (month2 == 13) {
    year2 = parseInt(year2) + 1;
    month2 = 1;
  }
  var day2 = day;
  var days2 = Date.new(year2, month2, 0);
  days2 = days2.getDate();
  if (day2 > days2) {
    day2 = days2;
  }
  if (month2 < 10) {
    month2 = '0' + month2;
  }

  var t2 = year2 + '-' + month2 + '-' + day2;
  return t2;
}
export function getNextMonthFinallyDay (date) {
  var arr = date.split('-');
  var year = arr[0]; // 获取当前日期的年份
  var month = arr[1]; // 获取当前日期的月份
  // var day = arr[2]; // 获取当前日期的日
  var year2 = year;
  var month2 = parseInt(month) + 1;
  if (month2 == 13) {
    year2 = parseInt(year2) + 1;
    month2 = 1;
  }
  var days2 = Date.new(year2, month2, 0);
  days2 = days2.getDate();
  if (month2 < 10) {
    month2 = '0' + month2;
  }

  var t2 = year2 + '-' + month2 + '-' + days2;
  return t2;
}
export function getDateParagraph (start, end) {
  var data = [];
  var startTime = Date.new(start.replace(/-/g, '/'));
  var endTime = Date.new(end.replace(/-/g, '/'));
  while (endTime.getTime() - startTime.getTime() >= 0) {
    var year = startTime.getFullYear();
    var month =
      (startTime.getMonth() + 1).toString().length == 1
        ? '0' + (startTime.getMonth() + 1).toString()
        : startTime.getMonth() + 1;
    var day =
      startTime.getDate().toString().length == 1
        ? '0' + startTime.getDate()
        : startTime.getDate();
    startTime.setDate(startTime.getDate() + 1);
    data.push({
      date: year + '-' + month + '-' + day,
      week: getWeekByTwo(year + '-' + month + '-' + day),
      info: []
    });
  }
  return data;
}

export function saveHowMuchDecimal (value, decimalPlaces) {
  var x = String(value).indexOf('.') + 1; // 小数点的位置
  var y = String(value).length - (x + 1); // 小数的位数
  if (x >= 0 && y >= 1) {
    return Number(Number(value).toFixed(decimalPlaces));
  } else {
    return value;
  }
}

export function transformDecimal (value, point) {
  return value == '' ? '' : Number(value).toFixed(point);
}

export function isObjEqual (o1, o2) {
  var props1 = Object.getOwnPropertyNames(o1);
  var props2 = Object.getOwnPropertyNames(o2);
  if (props1.length != props2.length) {
    return false;
  }
  for (var i = 0, max = props1.length; i < max; i++) {
    var propName = props1[i];
    if (o1[propName] !== o2[propName]) {
      return false;
    }
  }
  return true;
}

// 是否有权限 用于类名显示
export function CheckModulePower (modulePowerList, modulePowerKey) {
  modulePowerKey = modulePowerKey || 0;
  modulePowerList = modulePowerList || [];
  let hasPower = false;
  if (modulePowerKey instanceof Array) {
    // 判断是否为数组
    for (let i = 0; i < modulePowerKey.length; i++) {
      hasPower =
        modulePowerList.findIndex(value => {
          return value == modulePowerKey[i];
        }) > -1;
      if (hasPower == true) {
        break;
      }
    }
  } else {
    hasPower = CheckModulePowerByOne(modulePowerList, modulePowerKey);
  }
  return hasPower;
}

function CheckModulePowerByOne (modulePowerList, modulePowerKey) {
  let hasPower = false;
  if (modulePowerKey == 0) {
    hasPower = true;
  } else {
    hasPower =
      modulePowerList.findIndex(value => {
        return value == modulePowerKey;
      }) > -1;
  }
  return hasPower;
}
// 是否有权限 用于点击提示
export function CheckModulePowerToTips (modulePowerKey) {
  let findItem = null;
  var ModulePowerSetting = null;
  let TypeKey = store.getters.SaaSClientInfo.TypeKey;
  if (TypeKey == 6) {
    ModulePowerSetting = window.HQModulePowerSetting;
  } else {
    ModulePowerSetting = window.ModulePowerSetting;
  }
  console.log('CheckModulePowerToTips', ModulePowerSetting);
  if (modulePowerKey instanceof Array) {
    // 判断是否为数组
    for (var i = 0; i < modulePowerKey.length; i++) {
      for (let j = 0; j < ModulePowerSetting.length; j++) {
        const powerItem = ModulePowerSetting[j];
        findItem = powerItem.ModulePowerList.find(item => {
          return item.OLAPKey == modulePowerKey[i];
        });
        if (findItem) {
          break;
        }
      }
      if (findItem) {
        break;
      }
    }
    console.log(findItem, 'findItem');
  } else {
    for (let i = 0; i < ModulePowerSetting.length; i++) {
      const powerItem = ModulePowerSetting[i];
      findItem = powerItem.ModulePowerList.find(item => {
        return item.OLAPKey == modulePowerKey;
      });
      if (findItem) {
        break;
      }
    }
  }
  if (findItem) {
    var tips = '';
    if (JSON.stringify(modulePowerKey) === JSON.stringify([118, 123])) {
      tips =
        '您暂无此权限，请联系系统管理员。此功能对应权限为：课件 -> 课包排序';
    } else {
      tips =
        '您暂无此权限，请联系系统管理员。此功能对应权限为：' +
        findItem.ModuleKeyValue +
        '->' +
        findItem.PowerKeyValue +
        '。';
    }

    layer.alert(tips);
  }
}
// count为数字，a为1，保留整数，10保留一位小数，100保留两位小数，三位小数。。。 同理
export function parseIntType (count, a) {
  if (Number(count) >= 0) {
    // 计算必须为数字
    var res = parseInt(count * a) / a;
    return res;
  } else {
    return a;
  }
}
// 保留一位小数，小数位为0时，保留整数(课时) 没有值时默认显示：isdefault
export function mAmountType (count, decimalPlaces) {
  let newCount = Number(Number(count).toFixed(1));
  let lastCount = newCount.toString().substr(newCount.length - 2, 2);
  if (lastCount == '.0') {
    return Number(Number(count).toFixed(0));
  } else {
    return Number(Number(count).toFixed(decimalPlaces));
  }
}
// 保留两位小数，小数位为0时，保留整数(金额) 没有值时默认显示：isdefault
export function mMoneyType (count, decimalPlaces) {
  if (typeof count == 'undefined') {
    return Number(count);
  }
  if (count == '.0000') {
    return Number(count);
  }
  let arr = [];

  // count为零时不取值
  if (count != 0) {
    arr = count.toString().split('.'); // 取小数点后面的值
  }
  // 判断是否取到值
  if (arr.length > 1) {
    // 当小数点后面的长度大于需要的长度时 返回
    if (arr[1].length > decimalPlaces) {
      return Number(Number(count).toFixed(decimalPlaces));
    }
  }
  // 值
  let newCount = Number(Number(count).toFixed(2));
  // 小数
  let decimalValue = newCount.toString().substr(newCount.length - 3, 3);
  // 最后一位小数
  let secondDecimal = newCount.toString().substr(newCount.length - 1, 1);
  if (decimalValue == '.00') {
    // console.log(Number(Number(count).toFixed(0)), 'Number(Number(count).toFixed(0))')
    return Number(Number(count).toFixed(0));
  } else if (secondDecimal == '0') {
    // console.log(Number(Number(count).toFixed(decimalPlaces)), 'Number(Number(count).toFixed(decimalPlaces))')
    return count;
  } else {
    // console.log(Number(Number(count).toFixed(decimalPlaces)), 'Number(Number(count).toFixed(decimalPlaces))')
    return count;
  }
}
// 与上同理，不同点：为0时显示'-'
export function newAmountType (count, decimalPlaces) {
  let newCount = Number(Number(count).toFixed(1));
  let lastCount = newCount.toString().substr(newCount.length - 2, 2);
  if (lastCount == '.0') {
    if (Number(Number(count).toFixed(0)) == 0) {
      return '-';
    } else {
      return Number(Number(count).toFixed(0));
    }
  } else {
    if (Number(Number(count).toFixed(decimalPlaces)) == 0) {
      return '-';
    } else {
      return Number(Number(count).toFixed(decimalPlaces));
    }
  }
}
// true:数值型的，false：非数值型
export function myIsNaN (value) {
  return typeof value === 'number' && !isNaN(value);
}

// 根据日期范围获取指定周的日期
export function getDateArrForOneDate (begin, end, weekNum) {
  weekNum = weekNum == 7 ? 0 : weekNum;
  var dateArr = [];
  begin = Date.new(begin);
  end = Date.new(end);

  var stoday = Date.new();
  stoday.setUTCFullYear(begin.getFullYear(), begin.getMonth(), begin.getDate());
  var etoday = Date.new();
  etoday.setUTCFullYear(end.getFullYear(), end.getMonth(), end.getDate());

  var unixDb = stoday.getTime(); // 开始时间的毫秒数
  var unixDe = etoday.getTime(); // 结束时间的毫秒数

  for (var k = unixDb; k <= unixDe;) {
    let needJudgeDate = msToDate(parseInt(k)).withoutTime;
    // 不加这个if判断直接push的话就是已知时间段内的所有日期
    if (Date.new(needJudgeDate).getDay() == weekNum) {
      dateArr.push(needJudgeDate);
    }
    k = k + 24 * 60 * 60 * 1000;
  }
  return dateArr;
}
// 根据毫秒数获取日期
export function msToDate (msec) {
  let datetime = Date.new(msec);
  let year = datetime.getFullYear();
  let month = datetime.getMonth();
  let date = datetime.getDate();
  let hour = datetime.getHours();
  let minute = datetime.getMinutes();
  let second = datetime.getSeconds();

  let result1 =
    year +
    '-' +
    (month + 1 >= 10 ? month + 1 : '0' + (month + 1)) +
    '-' +
    (date + 1 < 10 ? '0' + date : date) +
    ' ' +
    (hour + 1 < 10 ? '0' + hour : hour) +
    ':' +
    (minute + 1 < 10 ? '0' + minute : minute) +
    ':' +
    (second + 1 < 10 ? '0' + second : second);

  let result2 =
    year +
    '-' +
    (month + 1 >= 10 ? month + 1 : '0' + (month + 1)) +
    '-' +
    (date + 1 < 11 ? '0' + date : date);

  let result = {
    hasTime: result1,
    withoutTime: result2
  };
  return result;
}

// 获取当月第几周
export function getMonthWeek (info) {
  /**
   * a = d = 当前日期
   * b = 6 - w = 当前周的还有几天过完(不算今天)
   * a + b 的和在除以7 就是当天是当前月份的第几周
   */

  var a = info.split('-')[0];
  var b = info.split('-')[1];
  var c = info.split('-')[2];
  var date = Date.new(a, parseInt(b) - 1, c),
    w = date.getDay(),
    d = date.getDate();
  if (w == 0) {
    w = 7;
  }
  var config = {
    getMonth: date.getMonth() + 1,
    getYear: date.getFullYear(),
    getWeek: Math.ceil((d + 6 - w) / 7)
  };
  return config.getWeek;
}
// 文本域光标插入
export function insertInputTxt (id, insertTxt) {
  // 标签ID，插入的内容
  var elInput = document.getElementById(id);
  var startPos = elInput.selectionStart;
  var endPos = elInput.selectionEnd;
  if (startPos === undefined || endPos === undefined) {
    return;
  }
  var txt = elInput.value;
  var result = txt.substring(0, startPos) + insertTxt + txt.substring(endPos);
  elInput.value = result;
  elInput.focus();
  elInput.selectionStart = startPos + insertTxt.length;
  elInput.selectionEnd = startPos + insertTxt.length;

  this.templateSettingItem.valueName = elInput.value;
}

export function strLength (str) {
  var len = 0;
  var re = /[^\u4e00-\u9fa5]/;
  for (var i = 0; i < str.length; i++) {
    var c = str.charCodeAt(i);
    // 单字节加1 中文加2
    if (re.test(c)) {
      len += 2;
    } else {
      len++;
    }
  }
  return len;
}

export function createGUID () {
  let S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  ).toUpperCase();
}
// 日期系列化为年月日时分秒
export function formateDateYMRHMS (date, type) {
  date = Date.new(date);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let mins = date.getMinutes();
  let sec = date.getSeconds();
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;
  hour = hour < 10 ? '0' + hour : hour;
  mins = mins < 10 ? '0' + mins : mins;
  sec = sec < 10 ? '0' + sec : sec;
  let formatTime =
    year + '-' + month + '-' + day + ' ' + hour + ':' + mins + ':' + sec;
  if (type == 'yyyymmddhhmm') {
    formatTime = year + '-' + month + '-' + day + ' ' + hour + ':' + mins;
  }
  return formatTime;
}

// JS光标定位到文本框字符串末尾
export function setCaretPosition (tObj, sPos) {
  if (tObj.setSelectionRange) {
    setTimeout(function () {
      tObj.setSelectionRange(sPos, sPos);
      tObj.focus();
    }, 0);
  } else if (tObj.createTextRange) {
    var rng = tObj.createTextRange();
    rng.move('character', sPos);
    rng.select();
  }
}

// 对比两个对象的值是否完全相等 返回值 true/false
export function isObjectValueEqual (a, b) {
  // 取对象a和b的属性名
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);
  // 判断属性名的length是否一致
  if (aProps.length != bProps.length) {
    return false;
  }
  // 循环取出属性名，再判断属性值是否一致
  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];
    if (a[propName] !== b[propName]) {
      console.log(a[propName], '不一样');
      return false;
    }
  }
  return true;
}

// 获取字符串像素宽度
export function getTextWidth (str, fontSize) {
  let result = 10;
  if (str) {
    str = str.replace(/\ +/g, '');
    str = str.replace(/[\r\n]/g, '');
    let ele = document.createElement('span');
    // 字符串中带有换行符时，会被自动转换成<br/>标签，若需要考虑这种情况，可以替换成空格，以获取正确的宽度
    // str = str.replace(/\\n/g,' ').replace(/\\r/g,' ');
    ele.innerText = str;
    // 不同的大小和不同的字体都会导致渲染出来的字符串宽度变化，可以传入尽可能完备的样式信息
    ele.style.fontSize = fontSize;
    // 由于父节点的样式会影响子节点，这里可按需添加到指定节点上
    document.documentElement.append(ele);
    result = ele.offsetWidth;
    document.documentElement.removeChild(ele);
  }
  return result;
}
// JS对象数组根据属性分组方法
// 调用方式
// var sorted = this.groupBySomeProperty(list, function (item) {
//   return [属性名]; //根据多个属性分组  return [属性名 + 属性名]
// });
export function groupBySomeProperty (array, fun) {
  var groups = {};
  array.forEach(function (o) {
    var group = JSON.stringify(fun(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
}

// 是否测试环境
export function isTest () {
  const locationUrl = GetRequest();
  return (
    window.location.host.indexOf('test') > -1 ||
    window.location.host.indexOf('beta') > -1 ||
    window.location.host.indexOf('localhost') > -1 ||
    window.location.host.indexOf('192.168.') > -1 ||
    locationUrl.testit == 1
  );
}
export function trim (str) {
  // 删除左右两端的空格
  return str.replace(/(^\s*)|(\s*$)/g, '');
}
export function ltrim (str) {
  // 删除左边的空格
  return str.replace(/(^\s*)/g, '');
}
export function rtrim (str) {
  // 删除右边的空格
  return str.replace(/(\s*$)/g, '');
}
export function myReplace (textString, f, e) {
  let reg = new RegExp(f, 'g'); // 创建正则RegExp对象
  return textString.replace(reg, e);
}

// 格式化日期的月份或天数的显示（小于10，在前面增加0）
export function getFormatDate (value) {
  var result;
  result = value < 10 ? '0' + value : value;
  return result;
}
export function getNextOrPreDate (date, day) {
  // 获取前几天或者后几天, 前几天为负数
  var dd = Date.new(date);
  dd.setDate(dd.getDate() + day);
  var y = dd.getFullYear();
  var m =
    dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1;
  var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
  return y + '-' + m + '-' + d;
}
// js 计算两个日期之间 相差几年几月几日
export function monthDayDiff (startDate, endDate) {
  // debugger;
  const flag = [1, 3, 5, 7, 8, 10, 12, 4, 6, 9, 11, 2];
  const start = Date.new(startDate);
  const end = Date.new(endDate);
  let year = end.getFullYear() - start.getFullYear();
  let month = end.getMonth() + 1 - (start.getMonth() + 1);
  let day = end.getDate() - start.getDate();
  // 先算天
  if (day < 0) {
    month--;
    const index = flag.findIndex(temp => {
      return temp === start.getMonth() + 1;
    });
    let monthLength;
    if (index <= 6) {
      monthLength = 31;
    } else if (index > 6 && index <= 10) {
      monthLength = 30;
    } else {
      monthLength = 28 + 3; // 2月计算有误
    }
    day = end.getDate() + (monthLength - start.getDate());
  }
  // 后算月
  if (month < 0) {
    year--;
    // month = end.getMonth() + (12 - start.getMonth());
    month = 12 + month;
  }
  return {
    year: year,
    month: month,
    day: day
  };
}
// 用于家校通文本转译  isHideTag 是否标隐藏
export function translationSchoolText (val, name, isShowTag) {
  var newValue = isShowTag ? val : delHtmlTag(val);
  var memepattern = new RegExp('{{([0-9]*)?}}', 'g');
  var namepattern = new RegExp('{{([A-Za-z]*)?}}', 'g');
  newValue = newValue.replace(new RegExp('\n', 'g'), '</br>'); // PC和WXB发布时显示的是\n
  // 表情匹配
  newValue = newValue.replace(
    memepattern,
    '<img src="' + store.getters.PCWebURL + '/publicImg/image/expression/$1.png" alt="$1" class="editor_meme_img">'
  );
  if (name) {
    // @真实匹配
    newValue = newValue.replace(
      namepattern,
      `<text>${' ' + name + ' '}</text>`
    );
  } else {
    // @关联匹配
    newValue = newValue.replace(
      namepattern,
      '<img src="' + store.getters.PCWebURL + '/publicImg/image/expression/$1.png" alt="$1" class="editor_stu_img">'
    );
  }
  return newValue;
}
// 设置6位数金额万元
export function setSixMoneyToWan (num) {
  num = Number(num) || 0;
  if (num > 99999.99) {
    return Math.floor((Number(num) / 10000) * 100) / 100 + '万';
  } else {
    return Number(Number(num).toFixed(1));
  }
}
// 获取首页
export function getIndexPath () {
  console.log(store.getters, '切换门店');
  // this.$store.commit('selectNewAPIURL');
  let indexPath = 'homePage';// '/indexView';
  if (store.getters.token) {
    if (store.getters.SaaSClientEditionKey == 5) {
      indexPath = '/chainWorkBench';
    } else if (store.getters.SaaSClientEditionKey == 6) {
      indexPath = '/teachingModeView';
    } else {
      let hasJW = CheckModulePower(store.getters.RolePowerList, 51);
      if (!hasJW) {
        let hasXZ = CheckModulePower(store.getters.RolePowerList, 63);
        if (!hasXZ) {
          let isTeacher = store.getters.token.IsTeacherKey == 1;
          if (!isTeacher) {
            indexPath = '/CourseTable';
          } else {
            indexPath = '/teacherAttenClass';
          }
        } else {
          indexPath = '/presidentKanban';
        }
      } else {
        indexPath = '/homePage';
      }
    }
  } else {
    indexPath = '/login';
  }
  return indexPath;
}
// 计算版本号大小,转化大小
function versionToNum (v) {
  let numplace = ['', '0', '00', '000', '0000', '00000'];
  let numplaceR = numplace.reverse();
  let vArr = v.toString().split('.');
  for (var i = 0; i < vArr.length; i++) {
    var len = vArr[i].length;
    vArr[i] = numplaceR[len] + vArr[i];
  }
  var res = vArr.join('');
  return res;
}
// 检测插件版本号是否需要更新
export function checkVersion (a, b) {
  a = versionToNum(a);
  b = versionToNum(b);
  let r = 0;
  if (a == b) {
    r = 0;
  } else if (a > b) {
    r = 1;
  } else {
    r = -1;
  }
  return r;
}
export function delHtmlTag (str) {
  return str.replace(/<[^>]+>/g, '');
}

export function phoneModulePower (phone) {
  if (phone) {
    if (CheckModulePower(store.getters.RolePowerList, 124)) {
      return phone;
    } else {
      let reg = /^(\d{3})\d{4}(\d{4})$/;
      phone = String(phone).replace(reg, '$1****$2');
      return phone;
    }
  } else {
    return phone;
  }
}
// 门店设置开关 =>
// 1-是否统一管理课名课类;
// 2-是否禁止下载附件;
// 3-是否禁止复制课本的文本内容;
// 4-是否显示课件加盟
// ;5-是否显示整店加盟;
// 6-是否显示直属直营;
// 7-是否显示参股直营;
// 8-是否开启老师水印;
// 9-是否开启门店水印;
// 10-是否开启品牌水印;
// 11-是否开启定位锁定;
// 12-加密授权;
// 13-课件安全老师授权;
// 14-地图位置;
// 15-地图定位坐标;
// 16-地图定位图片;
// 17-是否开通追销功能;
// 18-课件上课时间范围;
// 19-默认微课堂支出收支账户（支）;
// 20-默认课件授权费收支科目（收）;
// 21-默认加盟收费收支科目（收）;
// 22-默认进货入库收支科目（收）;
// 23-默认门店订单收支科目（收）;
// 24-默认微课堂支出收支科目（支）;
// 25-默认物品出库收支科目（支）;
// 26-默认门店充值收支科目（收）;
// 27-默认门店提现收支科目（支）;
// 28-默认微课堂订单收支科目（收）;
// 29-默认微课堂充值收支科目（收）;
// 30-默认推广券;
// 31-签到提前时间（分钟）;
// 32-请假扣课时;
// 33-旷课扣课时;
// 34-是否显示学生剩余课时;
// 35-是否显示年月日卡;
// 36-微信通知欠费;
// 37-微信通知变更考勤;
// 38-微信通知课单过期;
// 39-微信通知优惠券过期;
// 40-微信通知欠课时;
// 41-微信通知成长时光;
// 42-微信通知家校布置作业;
// 43-微信通知家校批改作业;
// 44-微信通知家校点评;
// 45-微信通知家校通知;
// 46-微信通知家校回复;
// 47-微信通知课时不足;
// 48-微信通知请假;
// 49-微信通知微消息;
// 50-微信通知还款;
// 51-微信通知缴费（报名）;
// 52-微信通知赠送优惠券;
// 53-微信通知课前;
// 54-微信通知还课时;
// 55-微信通知签到;
// 56-微信通知旷课;
// 57-是否开启快捷启用;
// 58-销售提成设置开启;
// 59-是否开启旷课;
// 60-课时不足预警课时数;
// 61-课时不足预警天数;
// 62-上课1次提醒(分钟);
// 63-上课2次提醒(分钟)
export function getSaaSClientSet (power, setlist) {
  let list = setlist || store.getters.SaaSClientInfo.SaaSClientSetting || [];
  if (list.length > 0) {
    let findItem = list.find(o => {
      return o.SetTypeKey == power && Number(o.SetKey) > 0;
    });
    if (findItem && Number(findItem.SetKey) > 0) {
      return findItem.SetKey;
    } else {
      return 0;
    }
  }
}
export function getSaaSClientSetInfo (power, setlist) {
  let list = setlist || store.getters.SaaSClientInfo.SaaSClientSetting || [];
  let setItem = { SetContent: 0, SetKey: '', SetKeyValue: '', SetTypeKey: '' };
  if (store.getters.SaaSClientInfo && list.length > 0) {
    let findItem = list.find(o => {
      return o.SetTypeKey == power;
    });
    if (findItem) {
      setItem = findItem;
    }
    return setItem;
  } else {
    return setItem;
    // GetSaaSClientMsg().then(result => { // 防止刷新时无数据
    //   let findItem = result.data.SaaSClientSetting.find(o => {
    //     return (o.SetTypeKey == power);
    //   });
    //   console.log(findItem, 'findItem-list');
    //   if (findItem) { return findItem; }
    // }, err => {
    //   layer.alert(err.msg);
    // });
  }
}
export function setSaaSClientSet (power, obj, setlist) {
  let list = setlist ||
    store.getters.SaaSClientInfo.SaaSClientSetting || [
      { SetContent: '', SetKey: '', SetKeyValue: '', SetTypeKey: '' }
    ];
  if (list.length > 0) {
    let findItem = list.find(o => {
      return o.SetTypeKey == power;
    });
    if (findItem) {
      Object.assign(findItem, obj);
      return list;
    }
  } else {
    return [];
  }
}
// export function isIE() {
//   if (!!window.ActiveXObject || 'ActiveXObject' in window) {
//     return true;
//   } else {
//     return false;
//   }
// }
// 输入框绑定事件
export function addPasteEvent (ele, callBack) {
  // var isIE = isIE();
  // 只有IE支持beforepaste
  if (!!window.ActiveXObject || 'ActiveXObject' in window) {
    ele.addEventListener('beforepaste', function () {
      if (window.clipboardData) {
        var txt = window.clipboardData.getData('text');
        if (txt != '' && txt != null) {
          window.clipboardData.setData('text', val);
        }
      }
    });
  } else {
    // 绑定粘贴事件
    ele.addEventListener('paste', function (e) {
      if (e.clipboardData) {
        // 阻止默认行为
        e.preventDefault();
        var clipboardData = e.clipboardData;
        // 获取剪贴板的文本
        var clipText = clipboardData.getData('text').toString();
        console.log(clipText, 'clipText');
        var end = '';
        if (clipText.length > 2) {
          end = clipText.substr(clipText.length - 2);
          if (end == '\r\n') {
            clipText = clipText.substr(0, clipText.length - 2);
          }
        }
        clipText = clipText.replace(/\r\n/g, '<br>');
        // clipText = clipText.replace(/&nbsp;/g, ' ')
        if (window.getSelection && clipText !== '' && clipText !== null) {
          var range = window.getSelection().getRangeAt(0);
          // 删除选中文本
          range.deleteContents();
          // 创建文本节点
          var spanNode = document.createElement('span');

          // e.clipboardData.items[1].getAsString((str) => {
          //   console.log('str', str);
          //   let pattern = new RegExp('<img\\b[^<>]*?\\balt="([A-Za-z0-9]*)?"[^<>]*/?>', 'g');
          //   str = str.toString().replace(pattern, '{{$1}}');
          //   var reg = /<[^<>]+>/g;// 1、全局匹配g肯定忘记写,2、<>标签中不能包含标签实现过滤HTML标签
          //   str = str.replace(reg, '');// 替换HTML标签
          //   // str = str.replace(/&nbsp;/ig, '');// 替换HTML空格
          //   str = str.replace(/[\r\n ]+/ig, '');// 替换HTML空行

          //   if (str.indexOf('{{') > -1 && str.indexOf('}}') > -1 && typeof callBack == 'function') {
          //     str = callBack(str);
          //   }
          //   spanNode.innerHTML = str;
          // // 在当前的光标处插入文本节点
          //   range.insertNode(spanNode);
          //   range = range.cloneRange();
          //   range.collapse(false);
          //   this.seletedSelection = window.getSelection();
          //   this.seletedSelection.removeAllRanges();
          //   this.seletedSelection.addRange(range);
          //   bus.emit('copyContentAfterUpdate');
          // });
          if (
            clipText.indexOf('{{') > -1 &&
            clipText.indexOf('}}') > -1 &&
            typeof callBack == 'function'
          ) {
            clipText = callBack(clipText);
          }
          spanNode.innerHTML = clipText;
          // 在当前的光标处插入文本节点
          range.insertNode(spanNode);
          range = range.cloneRange();
          range.collapse(false);
          this.seletedSelection = window.getSelection();
          this.seletedSelection.removeAllRanges();
          this.seletedSelection.addRange(range);
          bus.emit('copyContentAfterUpdate');
        }
      }
    });
  }
}
// 插入
// 为字符串插入字符 其中soure为原字符串,start为将要插入字符的位置，newStr为要插入的字符
export function insertStr (soure, start, newStr) {
  return soure.slice(0, start) + newStr + soure.slice(start);
}
export function replyTime (data) {
  const curTime = Math.round(Date.new().getTime() / 1000); // 当前时间的秒数
  const ReplyTimeIOS = data.replace(/\-/g, '/'); // 兼容苹机果显示
  const differTime = curTime - Date.new(ReplyTimeIOS).getTime() / 1000; // 当前时间与要转换的时间差（ s ）
  let time = '';
  if (differTime < 60) {
    time = '刚刚';
  } else if (differTime > 60 && differTime < 3600) {
    time = Math.floor(differTime / 60) + '分钟前';
  } else if (differTime > 3600 && differTime < 86400) {
    time = data.slice(11, 16); // 时间
  } else if (differTime > 86400 && differTime < 604800) {
    time = Math.floor(differTime / 86400) + '天前';
  } else if (differTime > 604800 && differTime < 2678400) {
    time = Math.floor(differTime / 604800) + '周前';
  } else if (differTime > 2678400 && differTime < 31536000) {
    time = Math.floor(differTime / 2678400) + '月前';
  } else if (differTime > 31536000) {
    time = Math.floor(differTime / 31536000) + '年前';
  }
  return time;
}

// 传入一个数组
// 按照特定方式格式化
export function sortArr (arr, str) {
  var _arr = [],
    _t = [],
    // 临时的变量
    _tmp;

  // 按照特定的参数将数组排序将具有相同值得排在一起
  arr = arr.sort(function (a, b) {
    var s = a[str],
      t = b[str];

    return s < t ? -1 : 1;
  });

  if (arr.length) {
    _tmp = arr[0][str];
  }
  // console.log( arr );
  // 将相同类别的对象添加到统一个数组
  for (var i in arr) {
    console.log(_tmp);
    if (arr[i][str] === _tmp) {
      console.log(_tmp);
      _t.push(arr[i]);
    } else {
      _tmp = arr[i][str];
      _arr.push(_t);
      _t = [arr[i]];
    }
  }
  // 将最后的内容推出新数组
  _arr.push(_t);
  return _arr;
}
export function setStringmd5 (
  DeviceTypeKey,
  DeviceCode,
  type,
  Longitude,
  Latitude
) {
  Longitude = Longitude || '';
  Latitude = Latitude || '';
  let strDeviceTypeKey = DeviceTypeKey ? DeviceTypeKey.toString() : '';
  let strDeviceCode = DeviceCode ? DeviceCode.toString() : '';
  let stringmd5 =
    store.getters.SaaSClientInfo.OLAPKey.toString() +
    strDeviceTypeKey +
    strDeviceCode +
    Longitude +
    Latitude +
    store.getters.signData.Sign +
    type;
  return stringmd5;
}
// 递归集合
export function loopData (
  ParentList,
  childrenList,
  treeData,
  Key,
  ParentKey,
  noDataType
) {
  const arr = [];
  childrenList.forEach(child => {
    let isExist = false;
    ParentList.forEach(father => {
      if (father.childTree && father[Key] == child[ParentKey]) {
        isExist = true;
        let locaIndex = father.childTree.findIndex(o => {
          return o.ID == child.ID;
        });
        if (locaIndex == -1) {
          father.childTree.push(child);
        }
        return false;
      }
    });
    if (!isExist) {
      arr.push(child);
    }
  });
  ParentList.forEach(item => {
    if (item.childTree && item.childTree.length > 0) {
      this.loopData(item.childTree, arr, treeData, Key, ParentKey, noDataType);
    } else {
      if (noDataType) {
        delete item.childTree;
      }
    }
  });
  return ParentList;
}

// 处理缩略图
export function ossLitimgSet (imgurl) {
  if (imgurl) {
    return (
      store.getters.CDNURL + imgurl + '?x-oss-process=image/resize,h_600,w_600'
    );
  } else {
    return '';
  }
}

// 根据当前路由，刷新导航栏
export function refreshHeaderBarList (router) {
  let headerBarList = store.getters.headerBarList;
  headerBarList.forEach(obj => {
    obj.isSelected = false;
  });
  let findIndex = headerBarList.findIndex(obj => {
    return obj.routerName == router.name;
  });
  if (findIndex == -1) {
    if (
      router.name != 'login' &&
      router.name != 'coursewareTeaching' &&
      router.name != 'coursewareTeachingView'
    ) {
      let routerItem = {
        openTime: Date.new(),
        routerName: router.name,
        isCanClose: true,
        titleName: router.meta.title,
        isSelected: true,
        iconClass:
          router.name == 'mendiansettingform'
            ? 'icon_mendian'
            : router.name == 'allStudent'
              ? 'icon_allStudent'
              : router.name == 'wktBack'
                ? 'icon_wkt'
                : router.name == 'recruitStudentShow'
                  ? 'icon_zsx'
                  : router.name == 'growthTime'
                    ? 'icon_gtime'
                    : 'icon_view',
        index: headerBarList.length + 1
      };
      console.log('router push', routerItem);
      // if (router.name.indexOf('teachingMode') == -1) {
      headerBarList.push(routerItem);
      console.log('router headerBarList', headerBarList);
      // }
      // store.commit('increaseHasOpenRouterList', to);
    }
  } else {
    headerBarList[findIndex].isSelected = true;
  }
}
// 处理容量 传入b值 返回相对应级 并返回单位
export function capacityFormate (size) {
  size = Math.abs(Number(size));
  // if (size < 1) {
  //   return 0 + 'M';
  // }
  if (size < 1024 * 1024) {
    return Number((size / (1024 * 1024)).toPrecision(6)).toFixed(2) == 0
      ? 0 + 'M'
      : Number((size / (1024 * 1024)).toPrecision(6)).toFixed(2) + 'M';
  }
  let kb = 1024;
  let unit = ['B', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Ys'];
  let i = Math.floor(Math.log(size) / Math.log(kb));
  return (
    Number((size / Math.pow(kb, i)).toPrecision(6)).toFixed(2) + ' ' + unit[i]
  );
}

// 处理流量 传入Kb值 返回相对应级 并返回单位
export function flowFormate (size, unitType, sunType) {
  let sum = Number(size) < 0 ? '-' : '';
  if (sunType) {
    sum = '';
  }
  size = Math.abs(Number(size));

  if (size < 1) {
    if (unitType == 'unit') {
      return '';
    } else if (unitType == 'number') {
      return '--';
    } else {
      return '--';
    }
  }
  let kb = 1024;
  let unit = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  let i = Math.floor(Math.log(size) / Math.log(kb));
  if (unitType == 'unit') {
    return unit[i];
  } else if (unitType == 'number') {
    return (
      sum + Number(Number((size / Math.pow(kb, i)).toPrecision(6)).toFixed(2))
    );
  } else {
    return (
      sum +
      Number(Number((size / Math.pow(kb, i)).toPrecision(6)).toFixed(2)) +
      ' ' +
      unit[i]
    );
  }
}
// 处理流量 传入Kb值 返回相对应级 不返回单位
export function flowFormateNotCompany (size) {
  let sum = Number(size) < 0 ? '-' : '';
  size = Math.abs(Number(size));
  if (size < 1) {
    return 0 + '';
  }
  let kb = 1024;
  let i = Math.floor(Math.log(size) / Math.log(kb));
  return (
    sum + Number(Number((size / Math.pow(kb, i)).toPrecision(6)).toFixed(2))
  );
}
// 验证地理信息
export function verifySystemLocation (
  dataInfo,
  callback,
  errorCallback
) {
  console.log('verifySystemLocation', store.getters.geoInfo);
  if (store.getters.geoInfo.Longitude) {
    // introType 10-19 开头就是课件  个位数1结尾表示进入上课模式，2结尾为预览模式
    // introType 20-29开头就是教务门店  个位数1结尾表示进入上课模式，2结尾为预览模式
    if (dataInfo.introType == 11) {
      console.log(1111111111111);
      CWSVerifyLocation(
        dataInfo.ScheduleCourseKey,
        store.getters.geoInfo.Longitude,
        store.getters.geoInfo.Latitude,
        dataInfo.SaaSClientKey,
        dataInfo.client_id,
        dataInfo.DeviceTypeKey,
        dataInfo.CourseWarePackageKey,
        dataInfo.DeviceCode,
        store.getters.geoInfo.Accuracy
      )
        .then(result => {
          console.log('verifyLocation', result);
          callback();
        })
        .catch(error => {
          errorCallback();
        });
    } else if (dataInfo.introType == 22) {
      console.log(22222, dataInfo);
      WEBVPreviewVerifyLocation(
        dataInfo.courserId,
        store.getters.geoInfo.Longitude,
        store.getters.geoInfo.Latitude,
        dataInfo.SaaSClientKey,
        dataInfo.client_id,
        dataInfo.DeviceTypeKey,
        dataInfo.DeviceCode,
        store.getters.geoInfo.Accuracy
      )
        .then(result => {
          console.log('verifyLocation', result);
          callback();
        })
        .catch(error => {
          errorCallback();
        });
    } else if (dataInfo.introType == 12) {
      console.log('课件门店预览验证参数', dataInfo);
      CWSPreviewVerifyLocation(
        dataInfo.courserId,
        store.getters.geoInfo.Longitude,
        store.getters.geoInfo.Latitude,
        dataInfo.SaaSClientKey,
        dataInfo.client_id,
        dataInfo.DeviceTypeKey,
        dataInfo.DeviceCode,
        store.getters.geoInfo.Accuracy
      )
        .then(result => {
          console.log('verifyLocation', result);
          callback();
        })
        .catch(error => {
          errorCallback();
        });
    } else {
      console.log(44444);
      VerifyLocation(
        dataInfo.ScheduleCourseKey,
        store.getters.geoInfo.Longitude,
        store.getters.geoInfo.Latitude,
        dataInfo.SaaSClientKey,
        dataInfo.client_id,
        dataInfo.DeviceTypeKey,
        dataInfo.DeviceCode,
        store.getters.geoInfo.Accuracy
      )
        .then(result => {
          console.log('verifyLocation', result);
          callback();
        })
        .catch(error => {
          errorCallback();
        });
    }
  } else {
    callback();
  }
}
// 是否开通设置 用于类名显示
export function CheckOpenSettingPower (openSettingList, openKey) {
  openKey = openKey || 0;
  openSettingList = openSettingList || [];
  console.log('CheckOpenSettingPower', initJson);
  console.log('openSettingList', openSettingList);
  let hasPower = false;
  if (openKey instanceof Array) {
    // 判断是否为数组
    for (let i = 0; i < openKey.length; i++) {
      hasPower =
        openSettingList.findIndex(value => {
          return value == openKey[i];
        }) > -1;
      if (hasPower == true) {
        break;
      }
    }
  } else {
    hasPower = CheckOpenSettingByOne(openSettingList, openKey);
  }
  return hasPower;
}
function CheckOpenSettingByOne (openSettingList, openKey) {
  let hasPower = false;
  if (openKey == 0) {
    hasPower = true;
  } else {
    // if(openSettingList instanceof Array ){
    //   openSettingList = []
    // }
    hasPower =
      openSettingList.findIndex(value => {
        return value == openKey;
      }) > -1;
  }
  return hasPower;
}
// 是否有权限 用于点击提示
export function CheckOpenSettingToTips (openKey) {
  let findItem = null;
  var openSettingList = initJson.openSettingList;

  console.log('CheckOpenSettingToTips', openSettingList, openKey);
  if (openKey instanceof Array) {
    // 判断是否为数组
    for (var i = 0; i < openKey.length; i++) {
      const powerItem = openSettingList;
      findItem = powerItem.find(item => {
        return item.OLAPKey == openKey[i];
      });
      if (findItem) {
        break;
      }

      if (findItem) {
        break;
      }
    }
    console.log(findItem, 'findItem');
  } else {
    const powerItem = openSettingList;
    findItem = powerItem.find(item => {
      return item.key == openKey;
    });
  }
  if (findItem) {
    var tips = '';
    tips =
      '您暂未开通功能，请联系管理员' +
      findItem.value +
      '。';
    layer.alert(tips);
  }
}
export function getLastYearMinDate () {
  // 获取向前推27年的日期  不用可不减27
  let myDate = new Date(); // 获取今天日期
  let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
  let year = nowDate.getFullYear();
  let month = '01';
  let day = '01';
  let hour = '00';
  let minute = '00';
  let second = '01';
  let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
  console.log('vMinDate', dateStr);
  return dateStr;
}

export function getIsLastData (date) {
  console.log('currDate', date);
  let currDate = new Date(date).getTime() / 1000;
  let minDate = new Date(getLastYearMinDate()).getTime() / 1000;
  let ishave = false;
  if (currDate < minDate) {
    ishave = true;
  }
  console.log('currDate', currDate, minDate);
  return ishave;
}
export function doSuportExportInfoClick (tableKey) {
  let defaultExportList = Object.assign([], initJson.defaultExportList);
  let tableExportList = [];
  defaultExportList.forEach(item => {
    if (item.tableKey == tableKey) {
      tableExportList = Object.assign([], item);
    }
  });
  let exportDefaultContent = tableExportList.exportDefaultContent;
  let arr = JSON.parse(localStorage.getItem('AgentSettingInfor'));
  let tempArr = [];
  if (arr.length > 0) {
    arr.forEach(item => {
      if (item.SetKey == tableKey) {
        tempArr = item.SetValue;
      }
    });
  }

  let arrd = getLocalDataFunc(tempArr, exportDefaultContent.clist, 2);
  exportDefaultContent.clist = Object.assign([], arrd);

  return exportDefaultContent;
}

function getLocalDataFunc (tempArr, tableColumns, type) {
  if (typeof tempArr == 'string') {
    tempArr = JSON.parse(tempArr);
  }
  let columnArr = [];
  console.log('tempArr', tempArr, tableColumns);
  if (tempArr.length > 0) {
    let btempArr = [];
    tempArr.forEach(item => {
      btempArr.push(item.label);
    });
    console.log('tempArr', btempArr);
    tableColumns.forEach(item => {
      let aindex = tempArr.findIndex(i => {
        return type == 1 ? i.label == item.label : i.label == item.title;
      });
      if (aindex > -1 && tempArr[aindex].isShow) {
        item.width = tempArr[aindex].width;
        // item.isShow = tempArr[aindex].isShow;
        columnArr.push(item);
      }
    });

    // 更新表格展示列
    columnArr.sort((a, b) => {
      const aKey = type == 1 ? a.label : a.title;
      const bKey = type == 1 ? b.label : b.title;
      console.log('aKey', aKey, bKey, Object.keys(a));
      if (btempArr.indexOf(aKey) == -1) {
        return false;
      }
      if (btempArr.indexOf(bKey) == -1) {
        return true;
      }
      return btempArr.indexOf(aKey) - btempArr.indexOf(bKey);
    });
  }else{
    columnArr = tableColumns
  }
  console.log('columnArr', columnArr);
  return columnArr;
}
export function getWeekDates (date) {
  // 获取当前日期
  var today = new Date(date);
  // 获取当前是星期几
  var day = today.getDay();
  if (day == 0) {
    // 计算本周第一天的日期
    var startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day - 6);
    // 计算本周最后一天的日期
    var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day);
  } else {
    var startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day + 1);
    var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day + 7);
  }
  // 返回本周的日期范围
  return { start: formatDatet(startDate, 'yyyy-MM-dd'), end: formatDatet(endDate, 'yyyy-MM-dd') };
}
export function getThisWeekDates () {
  // 获取当前日期
  var today = new Date();
  // 获取当前是星期几
  var day = today.getDay();
  if (day == 0) {
    // 计算本周第一天的日期
    var startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day - 6);
    // 计算本周最后一天的日期
    var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day);
  } else {
    var startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day + 1);
    var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day + 7);
  }
  // 返回本周的日期范围
  return { start: formatDatet(startDate, 'yyyy-MM-dd'), end: formatDatet(endDate, 'yyyy-MM-dd') };
}

export function replyTimeNotHasHour(data) {
  const curTime = Math.round(Date.new().getTime() / 1000); // 当前时间的秒数
  const ReplyTimeIOS = data.replace(/\-/g, '/'); // 兼容苹机果显示
  const differTime = curTime - Date.new(ReplyTimeIOS).getTime() / 1000; // 当前时间与要转换的时间差（ s ）
  let time = '今天';
  if (differTime > 86400 && differTime < 604800) {
    time = Math.floor(differTime / 86400) + '天前';
  } else if (differTime > 604800 && differTime < 2678400) {
    time = Math.floor(differTime / 604800) + '周前';
  } else if (differTime > 2678400 && differTime < 31536000) {
    time = Math.floor(differTime / 2678400) + '月前';
  } else if (differTime > 31536000) {
    time = Math.floor(differTime / 31536000) + '年前';
  }
  return time;
}