<template>
  <div :id="echartID" class="echartbox"></div>
</template>

<script>
export default {
  name: 'echartCircle',
  data () {
    return {
      echart: null
    };
  },
  props: {
    echartID: String,
    dataList: Array
  },
  mounted () {
  },
  methods: {
    echartInit () {
      this.echart = echarts.init(document.getElementById(this.echartID));
      window.chartList.push(this.echart);
      var option = {
        series: [
          {
            type: 'pie',
            radius: ['40%', '60%'],
            center: ['50%', '50%'],
            hoverAnimation: false,
            labelLine: {
              show: false
            },
            data: this.dataList
          }
        ]
      };
      this.echart.setOption(option);
    }

  }
};
</script>