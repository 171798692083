<template>
  <div class="index_JXT_ul">
    <div class="JXT_dynamic_list">
      <div class="JXT_dynamic_content">
        <div class="dynamic_content_body JXT_inform_box pr">
          <!-- 点评浏览头部 -->
          <browse-item-head :headBtnType="headBtnType"
                            :dataInfo="dataInfo"
                            :dispatchType="'发布于'"
                            :contentLeft="false"
                            :contentTop="false"
                            :IsAllSchool="IsAllSchool"
                            @doHeadBrowseButClick="doHeadBrowseButClick"></browse-item-head>

          <!-- 学分评论项 -->
          <div>
            <comment-currency-item :dataInfo="dataInfo"
                                   :isLikeCommentShow="false"></comment-currency-item>
          </div>
          <!-- 浏览内容 -->
          <browse-item-content :dataInfo="dataInfo"></browse-item-content>
          <!-- 学生-->
          <div class="body_relevant_people"
               v-if="dataInfo.ReaderSetContent">
            <span class="people_icon">@</span>
            <span class="people_text">{{dataInfo.ReaderSetContent}}</span>
          </div>
        </div>
      </div>
      <!-- 点赞评论 区 -->
      <div v-if="dataInfo.PraiseList.length>0||dataInfo.ReplyList.length>0">
        <comment-reply-box :dataInfo="dataInfo"
                           :IsAllSchool="IsAllSchool"
                           @toggleReply="toggleReply"
                           @doClickImageList="doClickImageList"></comment-reply-box>
      </div>
    </div>
    <!-- 点赞列表  -->
    <div>
      <custom-dialog title="点赞"
                     class="student_info_dialog defult_dialog"
                     :visible.sync="isShowHeadImgDetails"
                     :before-close="closeHeadImgDetails">
        <headImg-details :praiseList="praisePersonList"
                         :isShowBtn="false"
                         @returnPage="closeHeadImgDetails"></headImg-details>
      </custom-dialog>
    </div>

    <div>
      <custom-dialog title="回复"
                     class="student_info_dialog defult_dialog"
                     :visible.sync="replyStutas"
                     :before-close="closeTimeRangeDilog">
        <send-message :byReply="byReplyContent"
                      :essayDetail="byReplyDataInfo"
                      @replyCompleted="replyCompleted"></send-message>
      </custom-dialog>

    </div>
  </div>
</template>

<script>
import { DeleteReply } from '../../../API/workbench';
import browseItemHead from '../common-component/browse-box/browse-item-head';
import browseItemContent from '../common-component/browse-box/browse-item-content';
import commentCurrencyItem from './reply-content/comment-currency-item';
import commentReplyBox from './comment-reply-box';
import headImgDetails from '../common-component/headImg-details/details-list';
import sendMessage from './send-message';
// 点评浏览项
export default {
  components: {
    browseItemContent,
    browseItemHead,
    commentReplyBox,
    commentCurrencyItem,
    headImgDetails,
    sendMessage
  },
  data () {
    return {
      praisePersonList: [], // 点赞人列表
      replyStutas: false,
      isShowHeadImgDetails: false,
      byReplyContent: {}, // 回复项
      byReplyDataInfo: {}
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: function () {
        return {
          ID: 0, // 短文id',
          AuthorID: 0, // 作者id',
          Author: '', // 作者',
          HeadImg: '', // 作者头像',
          Title: '', // 标题',
          Content: '', // 内容',
          ReaderSetContent: '', // 有点评的学生',
          Currency: 0, // 学分',
          AttachList: [
            {
              AttachTypeKey: '', // 类型',
              AttachUrl: '' // 附件地址',
            }
          ],
          ReplyList: [
            {
              ReplyTypeKey: '', // 回复类型',
              FromTypeKey: '', // 发送人类型',
              From: '', // 发送人',
              FromID: '', // 发送人ID',
              FromHeadImg: '', // 发送人头像',
              ToTypeKey: '', // 1老师2老师3家长',
              To: '', // 接收人',
              ToID: '', // 接收人ID',
              ToHeadImg: '', // 接收人头像',
              ForReplyID: '', // 当前目标回复ID',
              MainForReplyID: '', // 主回复ID0表示为回复短文',
              Content: '', // 回复内容',
              ReplyTime: '' // 回复时间',
            }
          ],
          PraiseList: [
            {
              ReaderTypeKey: '', // 点赞人类型',
              ReaderID: '', // 点赞人id',
              Reader: '', // 点赞人',
              ReaderHeadImg: '', // 点赞人头像',
              PraiseTime: '' // 点赞时间',
            }
          ]
        };
      }
    },
    headBtnType: {
      type: String,
      default: ''
    },
    IsAllSchool: {
      type: Number,
      default: 2
    }
  },
  computed: {

  },
  methods: {
    headImg (imgUrl) {
      if (imgUrl.indexOf('http') > -1) {
        return imgUrl;
      } else {
        return this.$store.getters.CDNURL + imgUrl;
      }
    },
    doHeadBrowseButClick () {
      this.$emit('edit', this.dataInfo);
    },
    // 点赞人
    doClickImageList () {
      this.praisePersonList = this.dataInfo.PraiseList;
      this.isShowHeadImgDetails = true;
      // this.$emit('doClickImageList', this.dataInfo.PraiseList);
    },
    closeHeadImgDetails () {
      this.isShowHeadImgDetails = false;
    },
    // 当回复框被隐藏时去除选中高亮
    remarkReplyOperation () {
      this.dataInfo.ReplyList.forEach(i => {
        this.$set(i, 'activeName', false); // 强制监听此属性.
      });
    },
    //     // 回复框显示
    toggleReply (essayItem, replyType) {
      // console.log(replyType, '回复框显示-replyType');
      this.byReplyContent = essayItem;
      this.byReplyDataInfo = this.dataInfo;
      if (replyType) {
        this.replyStutas = false;
        this.onItemClick(replyType);
      } else {
        this.replyStutas = true;
        console.log(essayItem, this.dataInfo, '回复框显示');
      }
    },
    closeTimeRangeDilog () {
      this.replyStutas = false;
      this.remarkReplyOperation();
    },
    // 回复操作成功触发
    replyCompleted () {
      console.log('回复操作成功触发');
      this.replyStutas = false;
      this.$emit('upData');
      this.remarkReplyOperation();
    },
    // 当前用户对自己的回复进行选择操作
    onItemClick (type) {
      console.log(type, 'type');
      switch (type) {
        case 'delete':
          if (this.byReplyContent.ID > 0) {
            DeleteReply(this.byReplyContent.ID).then(result => {
              this.dataInfo.ReplyList.forEach((i, index) => {
                if (i.EssayID == this.dataInfo.ID && i.ID == this.byReplyContent.ID) {
                  this.dataInfo.ReplyList.splice(index, 1);
                }
              });
              this.$emit('upData');
            });
          } else {
            console.log('删除异常');
          }
          break;
        default:
          break;
      }
      this.remarkReplyOperation();
    }
  }
};
</script>

<style>
</style>