<template>
  <div>
    <div class="swiper-slide course_laber">
      <div class="swiper_combo">套餐</div>
      <div class="swiper_title">
        <div class="swiper_title_name">{{tickItem.TaoCanKeyValue}}</div>
        <div class="swiper_title_introduce">适用丨{{tickItem.ApplyCorName}}</div>
        <div class="swiper_title_course">{{vGenerateTime}}购买<span class="font_black">{{Number(tickItem.NumCount)}}</span>课时,剩余<span class="font_black">{{Number(tickItem.PeriodCount)}}</span>天 </div>
      </div>
      <div class="swiper_conetnt">
        <div class="swiper_conetnt_list">
          <div class="swiper_conetnt_list_title">当前剩余</div>
          <div class="swiper_conetnt_list_number">{{Number(tickItem.CurrentCount)}}</div>
        </div>
        <div class="swiper_conetnt_list">
          <div class="swiper_conetnt_list_title">已报课</div>
          <div class="swiper_conetnt_list_number">{{vHasBingCount}}</div>
        </div>
        <div class="swiper_conetnt_list">
          <div class="swiper_conetnt_list_title">待报课</div>
          <div class="swiper_conetnt_list_number font_red">{{Number(tickItem.ToBeArranged)}}</div>
        </div>
      </div>
      <div  class="course_label_menu iconfont icon-qiehuanzhanghao" v-if="!showMenu" @click="showCourseListSearch" ></div>
      <!-- v-if="showMenu" @click="isShowPopup = true" -->
    </div>
    <!-- <custom-popup :className="'full-screen'"
                        ref="customPopup">
            <course-for :formTitle="'待报课课单列表'"
                        :switchItem="1"
                        @return="backToMain"
                        @selectedItem="doAfterSelectedCourse"
                       >
            </course-for>
    </custom-popup> -->
  </div>

</template>
<script>
import courseFor from './course-for/index';
export default {
  data () {
    return {

    };
  },
  props: ['tickItem', 'showMenu'],
  components: {
    courseFor
  },
  computed: {
    vHasBingCount () {
      return Number(this.tickItem.CurrentCount) - Number(this.tickItem.ToBeArranged);
    },
    vGenerateTime () {
      if (this.tickItem) {
        this.tickItem.GenerateTime = this.tickItem.GenerateTime.replace(/-/g,'/')
        return (Date.new(this.tickItem.GenerateTime).getMonth() + 1) + '月' + Date.new(this.tickItem.GenerateTime).getDate() + '日';
      }
    }
  },
  methods: {
    showCourseListSearch () {
      console.log('触发列表弹出');
      this.$emit('showCourseListBefore');
      // this.$refs.customPopup.PopUpShow = true;
    }
    // backToMain () {
    //   if (this.tickItem) {
    //     this.$refs.customPopup.PopUpShow = false;
    //   } else {
    //     this.$router.go(-1);
    //   }
    // },
    // doAfterSelectedCourse (item) {
    //   console.log(item, 'item');
    //   this.$emit('chgCourse', item);
    //   this.$refs.customPopup.PopUpShow = false;
    // }
  }
};
</script>
<style scoped>
  .course_laber{
    width:100%!important
  }
</style>
