<template>
  <div class="course_rightside">
    <div class="course_search font_big">
      快捷查询
      <div class="pointer_box course_header_fr_btn btn_hover_white  fr">
        <function-btn :item="{}"
                      @clickItem="searchByAll"
                      :className="'pointer'">
          全部
        </function-btn>
      </div>
    </div>
    <div class="course_search_form">
      <div class="course_search_form_box">
        <div class="course_search_select">
          <el-select v-model="value"
                     placeholder="请选择"
                     size="small"
                     @change="chgSearchValue">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="course_search_input">
          <input type="text"
                 v-model="searchText"
                 @keyup.enter="searchStudentData">
          <div v-if="searchText.length > 0"
               @click="clearSearchBtn"
               :class="{btnShow:value==1}"
               class="course_search_input_clear"></div>
          <button @click="searchBtn"
                  v-if="value==1">搜索</button>
        </div>
      </div>

    </div>
    <ul class="course_search_tree"
        v-if="value==1&&vDataList.length>0"
        @scroll="scrollTop">
      <li v-for="item in vDataList"
          :key="item.OLAPKey"
          @click="chgSearchData(item)"
          :class="{'opt':item.isOpt}">
        <div><span>{{item.MainDemoName}}</span></div>
      </li>
    </ul>
    <ul class="course_search_tree"
        v-else-if="value==1&&isLoad">
      <span class="noData">搜索中...</span>
    </ul>
    <ul class="course_search_tree"
        v-else-if="value==1&&vDataList.length==0">
      <span class="noData">没有匹配的数据</span>
    </ul>
    <!-- 非课程 -->
    <ul class="course_search_tree"
        v-else-if="value!=3&&vDataList.length>0">
      <li v-for="item in vDataList"
          :key="item.OLAPKey"
          @click="chgSearchData(item)"
          :class="{'opt':item.isOpt}">
        <div><span>{{item.MainDemoName}}</span></div>
      </li>
    </ul>
    <ul class="course_search_tree"
        v-else-if="value!=3&&vDataList.length==0">
      <span class="noData">没有匹配的数据</span>
    </ul>
    <!-- 课程列表 -->
    <ul class="search_result_list2"
        v-else-if="value==3&&vTreeData.length>0">
      <itemsFrom v-for="(model,index) in vTreeData"
                 :model="model"
                 :multie="false"
                 :key="index"
                 @selected="mutiSelected"></itemsFrom>
    </ul>
    <ul class="search_result_list2"
        v-else>
      <span class="noData">没有匹配的数据</span>
    </ul>
  </div>
</template>
<script>
import { SelectTeach, SearchClassRoom, SearchClass, messageSearchPageForStudent, SelectTheClassType } from '../../../API/workbench';
import itemsFrom from './items';
import { parseJson } from '../../../utils';
export default {
  data () {
    return {
      value: 3,
      searchValue: '课程',
      isActive: true,
      isActive2: false,
      searchValue2: '',
      searchValue2obj: '',
      isActive3: false,
      searchValue3: '',
      searchValue3obj: '',
      options: [
        // {label: '全部', value: 0},
        { label: '学生', value: 1 },
        { label: '老师', value: 2 },
        { label: '课程', value: 3 },
        { label: '教室', value: 4 },
        { label: '班级', value: 5 }
      ],
      pageIndex: 0,
      pageSize: 30,
      dataList: [],    //
      teacherList: [],     // 教室列表
      studentList: [],     // 学生列表
      classRoomList: [],    // 教室列表
      gradeList: [],         // 班级列表
      classTypeList: [],    // 课程列表
      searchText: '',       // 输入的查询字段.
      isSearchBtn: false,   // 是否需要点击按钮查询
      iconShow: false,
      open: false,
      seletedItem: {},     // 选中想
      searchLevelForCourse: [],
      noMore: false,
      isLoad: false
    };
  },
  components: {
    itemsFrom
  },
  created () {
    this.searchClassTypeList();
    // this.searchStudentList(this.pageIndex, this.pageSize, this.searchText);
    this.$bus.on(this, 'courseTableSelected', course => {
      this.isActive = false;
      if (course.multie == undefined) {
        course.multie = false;
      }
      if (!course.multie) {
        this.isSelectList(this.vTreeData, course.model.id);
      }
      if (course.model.level == 1) {
        console.log('1级菜单', course);
        this.searchLevelForCourse = [];
        this.searchValue2 = course.model.menuName;
        this.searchValue2obj = parseJson(course.model.data);
        // 判断种类是否相同. 不相同去除
        let isIndex = course.model.childTree.findIndex(o => {
          return o.menuName == this.searchValue3;
        });
        if (isIndex == -1) {
          this.searchValue3 = '';
        }
        this.isActive = false;
        this.isActive2 = true;
        this.isActive3 = false;
        // 重置选中
        this.classTypeList.forEach(o => {
          if (o.OLAPKey == course.model.id) {
            this.$set(o, 'isOpt', true);
          } else {
            this.$set(o, 'isOpt', false);
          }
        });
      } else {
        console.log('二级菜单', course);
        this.searchLevelForCourse = [];
        this.searchLevelForCourse.push({ OLAPKey: course.model.id, MainDemoName: course.model.menuName, FatherKey: course.model.data.FatherKey, isActive: true });

        if (course.model.data.FatherKey > 0) {
          this.findFatherLevel(course.model.data.FatherKey);
          // 重置选中
          this.classTypeList.forEach(o => {
            if (o.OLAPKey == course.model.data.FatherKey) {
              this.$set(o, 'isOpt', true);
            } else {
              this.$set(o, 'isOpt', false);
            }
          });
          let locaIndex = this.classTypeList.findIndex(p => {
            return p.OLAPKey == course.model.id;
          });
          this.classTypeList[locaIndex].isOpt = true;
          console.log(this.vTreeData, 'this.vTreeData');
        }
      }
      this.seletedItem = course.model;
      this.$emit('seletedItem', course.model.data, this.value);
    });

    this.$bus.on(this, 'switchHeaderBarToCourseTable', () => {
      // if (this.value == 1) {
      //   this.searchStudentList(0, 0, '', (result) => {
      //     console.log(this.vDataList.length < result.data.PageDataList.length);
      //     if (this.vDataList.length < result.data.PageDataList.length) {
      //       this.isSearchBtn = true;
      //     }
      //   });
      // }
      this.reloadSourData(this.value);
    });
  },
  computed: {
    vTreeData () {
      let treeData = [];
      this.classTypeList.map(o => {
        // if (this.searchValue2obj.OLAPKey == o.OLAPKey) {
        //   o.isOpt = true;
        // } else {
        //   o.isOpt = false;
        // }
        let item = {
          id: o.OLAPKey,
          FatherKey: o.FatherKey,
          menuName: o.MainDemoName,
          level: 1,
          type: o.TypeKey,
          isOpt: o.isOpt,
          open: o.open,
          data: o,
          childTree: []
        };
        treeData.push(item);
        return o;
      });
      // 父节点数据
      const fatherKey = treeData.filter(item => item.FatherKey === '2');

      // 子节点数据
      const childKey = treeData.filter(item => item.FatherKey !== '2');
      let data = this.loopData(fatherKey, childKey, treeData);
      let noData = [];
      data.forEach(e => {
        e.childTree.forEach(o => {
          let i = data.findIndex((item) => {
            return item.id == o.id;
          });
          console.log(i);
          if (i > -1) {
            noData.push(o);
          }
        });
      });
      let arr = [];
      data.forEach(e => {
        let i = noData.findIndex((item) => {
          return item.id == e.id;
        });
        if (i == -1) {
          arr.push(e);
        }
      });

      return arr;
    },
    vDataList () {
      let newArr = [];
      let dataList = [];
      switch (this.value) {
        case 1:
          newArr = this.studentList;
          break;
        case 2:
          newArr = this.teacherList;
          break;
        case 4:
          newArr = this.classRoomList;
          break;
        case 5:
          newArr = this.gradeList;
          break;
        default:
          break;
      }
      if (this.value == 1) {
        // console.log(newArr, 'newArrnewArr', this.isSearchBtn, this.searchText);
        newArr.forEach(o => {
          // if (!this.isSearchBtn) {
          dataList.push(o);
          // } else if (this.searchText == '' || (o.MainDemoName.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1 ||
          //   o.MobileSupport.indexOf(this.searchText) != -1 ||
          //   o.FirstLetterName.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1)) {
          //   dataList.push(o);
          // }
        });
        // this.isSearchBtn = false;
      } else {
        newArr.forEach(o => {
          if (o.MainDemoName.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1 || this.searchText == '') {
            dataList.push(o);
          }
        });
        this.isSearchBtn = false;
      }
      return dataList;
    }

  },
  methods: {
    findFatherLevel (id) {
      let findItem = this.classTypeList.find(o => { return o.OLAPKey == id; });
      if (findItem) {
        this.searchLevelForCourse.unshift({ OLAPKey: findItem.OLAPKey, MainDemoName: findItem.MainDemoName + '类', FatherKey: findItem.FatherKey ? findItem.FatherKey : 0, isActive: false });
        if (findItem.FatherKey > 0) {
          this.findFatherLevel(findItem.FatherKey);
        }
      }
    },
    isSelectList (data, id) {
      data.forEach(item => {
        if (item.id !== id) {
          item.isOpt = false;
        } else {
          item.isOpt = true;
        }
        if (item.childTree.length > 0) {
          this.isSelectList(item.childTree, id);
        }
      });
    },
    mutiSelected (item) {

    },
    loopData (fatherList, childrenList, treeData) {
      const arr = [];
      childrenList.forEach(child => {
        let isExist = false;
        fatherList.forEach(father => {
          if (father.id == child.FatherKey) {
            isExist = true;
            child.level = father.level + 1;
            father.childTree.push(child);
            return false;
          }
        });
        if (!isExist) {
          arr.push(child);
        }
      });
      let searchData = [];
      fatherList.forEach(item => {
        item.menuName = Number(item.type) == 1 && item.childTree ? item.menuName += '类' : item.menuName;
        if (item.childTree.length > 0) {
          this.loopData(item.childTree, arr, treeData);
        }
      });
      if (this.searchText) {
        treeData.forEach(o => {
          console.log(o, 'oooo');
          if (o.menuName && o.menuName.indexOf(this.searchText) !== -1) {
            searchData = [...searchData, o];
          }
        });
        if (searchData && searchData.length > 0) {
          return searchData;
        } else {
          // let item = [{
          //   id: 0,
          //   FatherKey: 0,
          //   menuName: '没有匹配的数据',
          //   level: 1,
          //   type: 1,
          //   isOpt: false,
          //   open: false,
          //   data: {},
          //   childTree: []
          // }];
          // return item;
          return [];
        }
      }
      this.isSearchBtn = false;
      return fatherList;
    },
    searchByAll () {
      this.chgSearchValue(0);
    },
    chgSearchValue (value) {
      if (value == 0) {
        this.isSelectList(this.vTreeData, 0);
        this.searchLevelForCourse = [];
        this.searchValue2 = '';
        this.searchValue2obj = '';
      }
      this.searchText = '';
      this.isSearchBtn = false;
      switch (value) {
        case 1:
          this.dataList = [];
          this.pageIndex = 0;
          this.noMore = false;
          this.searchValue = '学生';
          this.searchStudentList(this.pageIndex, this.pageSize, this.searchText);
          break;
        case 2:
          this.searchTeacherList();
          this.searchValue = '老师';
          break;
        case 3:
          this.searchClassTypeList();
          this.searchValue = '课程';
          break;
        case 4:
          this.searchClassRoomList();
          this.searchValue = '教室';
          break;
        case 5:
          this.searchValue = '班级';
          this.searchGradeList();
          break;
        case 0:
          this.searchValue = '全部';
          this.isActive = true;
          this.isActive2 = false;
          this.isActive3 = false;
          this.$emit('reloadAll', value);
          if (this.value != 3) {
            this.vDataList.forEach(o => {
              o.isOpt = false;
            });
          } else {

          }
          break;
        default:
          break;
      }
    },
    reloadSourData (value) {
      switch (value) {
        case 2:
          this.searchTeacherList();
          break;
        case 3:
          this.searchClassTypeList();
          break;
        case 4:
          this.searchClassRoomList();
          break;
        case 5:
          this.searchGradeList();
          break;
        default:
          break;
      }
    },
    // 查找老师列表 SelectTeach
    searchTeacherList () {
      SelectTeach().then(res => {
        this.teacherList = [];
        if (res.code == 0) {
          res.data.forEach(o => {
            if (this.searchValue2obj.OLAPKey == o.OLAPKey) {
              o.isOpt = true;
            } else {
              o.isOpt = false;
            }
            this.teacherList.push(o);
          });
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 查找教室列表 SearchClassRoom
    searchClassRoomList () {
      SearchClassRoom().then(res => {
        this.classRoomList = [];
        if (res.code == 0) {
          res.data.forEach(o => {
            if (this.searchValue2obj.OLAPKey == o.OLAPKey) {
              o.isOpt = true;
            } else {
              o.isOpt = false;
            }
            this.classRoomList.push(o);
          });
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 查找学生列表 MessageSearchPageForStudent
    searchStudentList (pageIndex, pageSize, searchText, callback) {
      this.isLoad = true;
      messageSearchPageForStudent(pageIndex, pageSize, searchText).then(result => {
        if (result.data) {
          // 切换学生类型时 (首页)需清空数据
          if (callback) {
            callback(result);
          }
          result.data.PageDataList.forEach(o => {
            o.isActive = false;
            let hasFind = false;

            if (this.searchValue2obj.OLAPKey == o.OLAPKey) {
              o.isOpt = true;
            } else {
              o.isOpt = false;
            }
            if (this.studentList.length > 0) {
              hasFind = this.studentList.findIndex(p => p.OLAPKey == o.OLAPKey) >= 0;
            }
            if (!hasFind) {
              this.studentList.push(o);
            }
          });
          this.pageIndex++;
          if (result.data.PageDataList.length < this.pageSize) {
            this.noMore = true;
          } else {
            this.noMore = false;
          }
          this.isLoad = false;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    // 查找班级列表 SearchClass
    searchGradeList () {
      SearchClass().then(res => {
        this.gradeList = [];
        if (res.code == 0) {
          res.data.forEach(o => {
            if (this.searchValue2obj.OLAPKey == o.OLAPKey) {
              o.isOpt = true;
            } else {
              o.isOpt = false;
            }
            this.gradeList.push(o);
          });
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 获取课类课名 SelectTheClassType
    searchClassTypeList () {
      SelectTheClassType().then(result => {
        this.classTypeList = [];
        result.data.forEach(k => {
          if (k.StateKey != 3) { // 3为停用
            k.open = false;
            if (this.searchValue2obj.OLAPKey == k.OLAPKey) {
              k.isOpt = true;
            } else {
              k.isOpt = false;
            }
            this.classTypeList.push(k);
          }
        });
      });
    },
    chgSearchData (item) {
      this.vDataList.forEach(o => {
        if (o.OLAPKey == item.OLAPKey) {
          this.searchValue2 = item.MainDemoName;
          this.searchValue2obj = item;
          o.isOpt = true;
          this.seletedItem = o;
        } else {
          o.isOpt = false;
        }
      });
      this.isActive = false;
      this.isActive2 = true;
      this.isActive3 = false;
      this.$emit('seletedItem', this.seletedItem, this.value);
    },

    searchValue2ToReq () {
      this.isActive = false;
      this.isActive2 = true;
      this.isActive3 = false;
      this.$emit('seletedItem', this.searchValue2obj, this.value);
    },
    searchValue3ToReq (item) {
      this.isSelectList(this.vTreeData, item.OLAPKey);
      this.searchLevelForCourse = [];
      this.searchLevelForCourse.push({ OLAPKey: item.OLAPKey, MainDemoName: item.MainDemoName, FatherKey: item.FatherKey, isActive: true });
      this.findFatherLevel(item.FatherKey);
      this.$emit('seletedItem', item, this.value);
    },
    searchStudentData () {
      if (this.value == 1) {
        this.searchBtn();
      }
    },
    searchBtn () {
      console.log('this.searchValue', this.searchValue, this.value);
      if (this.value == 1) {
        this.pageSize = 30;
        this.pageIndex = 0;
        this.noMore = false;
        this.studentList = [];
        this.searchStudentList(this.pageIndex, this.pageSize, this.searchText);
      }
      this.isSearchBtn = true;
    },
    clearSearchBtn () {
      this.searchText = '';
      if (this.value == 1) {
        this.searchBtn();
      }
    },
    showChildList (item) {
      item.open = true;
      this.vTreeData.forEach(o => {
        if (item.OLAPKey == o.OLAPKey) {
          o.isOpt = true;
          o.open = true;
        } else {
          o.isOpt = false;
          // o.open = false;
        }
      });
    },
    // 滚动加载事件.
    scrollTop (e) {
      let ETarget = e.target;
      let HeightView = ETarget.scrollHeight - ETarget.clientHeight;
      console.log(ETarget.scrollTop, 'scrollTOP', HeightView);
      if (parseInt(ETarget.scrollTop) + 20 >= parseInt(HeightView) && !this.noMore && !this.isLoad) {
        this.searchStudentList(this.pageIndex, this.pageSize, this.searchText);
      }
    }

  }
};
</script>
<style scoped>
.active {
  color: #0092fbcc;
}

span.noData {
  font-size: 14px;
  line-height: 36px;
  margin-left: 20px;
}
.course_rightside {
  width: 18% !important;
  position: absolute !important;
  margin-left: 0px !important;
  top: 20px !important;
  right: 40px !important;
  max-height: 795px;
}
</style>

