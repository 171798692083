<template>
  <div class="courseware_authorization_details"
       v-if="authDetailsInfo">
    <div class="campus_box">
      被授权方：{{authDetailsInfo.SchoolKeyValue}}
      <div class="campus_box_type_info">校区类型：{{authDetailsInfo.TypeKeyValue}}</div>
    </div>
    <div>
      <courseware-bag-info :bigCoursewareBox="bigCoursewareBox"
                           :authDetailsInfo="authDetailsInfo"></courseware-bag-info>
    </div>
    <courseware-bag-record-info v-for="(item,key) in authDetailsInfo.CoursewarePackageAuthLog"
                                :key="key"
                                :dataInfo="item"
                                :authDetailsInfo="authDetailsInfo"
                                :boxInfoList="authorizationInfoList"></courseware-bag-record-info>

  </div>
</template>
<script>
import coursewareBagInfo from './courseware-bag-info';
import coursewareBagRecordInfo from './courseware-bag-record-info';
import { HQGetAuthDetails } from '../../../../API/workbench';
export default {
  components: {
    coursewareBagInfo,
    coursewareBagRecordInfo
  },
  data () {
    return {
      authDetailsInfo: null,
      bigCoursewareBox: {
        CoverUrl: 'https://cdnzxbeta.zhixuerj.com/Upload/Resource/1052/Image/202205101552568680.png',
        name: '水墨小画',
        leftBoxList: [{ tltle: '含子课包：', value: '6' }, { tltle: '含课件数：', value: '6' }, { tltle: '授权老师：', value: '16' }]
        // rightBoxList: [ ]
      },
      authorizationInfoList: [
        {
          dataList: [
            { tltle: '授权日期:', value: 'AuthorizeTime', width: 188, className: '' },
            { tltle: '授权方式:', value: 'ClearingFormKeyValue', width: 320, className: '' },
            { tltle: '授权范围:', value: 'AuthContent', width: 0, className: 'SQ' }
          ]
        },
        {
          dataList: [
            { tltle: '已上课次：', value: 'CourseCount', width: 205, className: '' },
            { tltle: '上课时长：', value: 'CourseDurationCount', width: 240, className: '' },
            // { tltle: '预览时长：', value: 'PrepareLessonsDurationCount', width: 240, className: '' },
            { tltle: '展开', value: '', width: 78, className: 'font_blue', bottomBtn: 'bottom_btn', type: 'btn' }
          ]
        }
      ]
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          SchoolKey: '', // 10133
          BigCoursewarePackageKey: ''// 40545
        };
      }
    }
  },
  created () {
    console.log('this.dataInfo11111111111', this.dataInfo);
    if (this.dataInfo && this.dataInfo.SchoolKey > 0 && this.dataInfo.BigCoursewarePackageKey > 0) {
      this.getAuthDetails();
    }
  },
  mounted () {
    this.$dialog.register(this, ['attendClassRecord', 'prepareLessonsRecord']);
  },
  computed: {

  },
  methods: {
    // 获取授权详情
    getAuthDetails () {
      console.log(this.dataInfo, '获取授权详情111');
      HQGetAuthDetails(this.dataInfo.SchoolKey, this.dataInfo.BigCoursewarePackageKey, this.dataInfo.OLAPKey).then(result => { // 参股直营大学城校区
        console.log(result.data, '获取授权详情');
        this.authDetailsInfo = result.data;
        this.bigCoursewareBox.CoverUrl = result.data.CoverUrl;
        this.bigCoursewareBox.name = result.data.MainDemoName;
        this.bigCoursewareBox.leftBoxList[0].value = Number(result.data.CoursewarePackageCount);
        this.bigCoursewareBox.leftBoxList[1].value = Number(result.data.AuthDeviceCount) + '/' + Number(result.data.AuthorizeDeviceNum) + '台';
        this.bigCoursewareBox.leftBoxList[1].value = Number(result.data.CoursewareNum);
        this.bigCoursewareBox.leftBoxList[2].value = result.data.AuthTeacherCount == '全部' ? result.data.AuthTeacherCount : (Number(result.data.AuthTeacherCount) + '人');
        // 授权信息
        this.setAuthorizationInfo();
      }, error => {
        layer.alert(error.msg);
      });
    },
    setAuthorizationInfo () {
      this.authDetailsInfo.CoursewarePackageAuthLog.forEach(o => {
        this.$set(o, 'isOpen', false);
        o.AuthorizeTime = this.$utils.formatDateToYYYYMMDD(o.AuthorizeTime);
        let residueNum = (Number(o.ReleasedNum) - Number(o.UsageNum)) + '/' + Number(o.ReleasedNum);
        if (o.ClearingFormKey == 1 && Number(o.ReleasedNum) > 0) {
          o.ClearingFormKeyValue = '按次授权' + '(剩余可用' + residueNum + '课次)';
        } else if (o.ClearingFormKey == 2) {
          o.ClearingFormKeyValue = '按年授权' + '(剩余可用' + residueNum + '天)';
        } else if (o.ClearingFormKey == 4) {
          o.ClearingFormKeyValue = '按天授权' + '(剩余可用' + residueNum + '天)';
        }
        o.CourseCount = Number(o.CourseCount) + '节';
        o.CourseDurationCount = Number(o.CourseDurationCount) + '分钟';
        o.PrepareLessonsDurationCount = Number(o.PrepareLessonsDurationCount) + '分钟';
      });
      console.log(this.authDetailsInfo.CoursewarePackageAuthLog, 'CoursewarePackageAuthLog');
    },
    doClick (list, item) {
      if (item.isClick) {
        console.log(list, item, '按钮');
        this.dataInfo.isOpen = !this.dataInfo.isOpen;
        list.forEach(o => {
          if (o.isClick) {
            o.tltle = (item.tltle == '收起' ? '展开' : '收起');
          }
        });
      }
    }
  }
};
</script>
<style>
.campus_box {
  display: flex;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  border-bottom: 1px solid #ececec;
  font-size: 18px;
}
.campus_box_type_info {
  width: 142px;
  height: 30px;
  border: 1px solid #ececec;
  border-radius: 4px;
  color: #666666;
  font-size: 14px;
  line-height: 30px;
  margin: 10px 0px 0 40px;
  text-align: center;
}

/* 记录头部 */
.courseware_bag_record_list {
  margin: 20px;
  background: #f7f8fa;
  border-radius: 4px;
  width: 700px;
  /* height: 80px; */
}
.courseware_bag_head_box {
  width: 100%;
  height: 40px;
  display: flex;
  font-size: 14px;
  color: #999;
  line-height: 46px;
  padding-left: 15px;
}
.courseware_bag_head_box div div {
  height: 40px;
  line-height: 40px;
}
.courseware_bag_head_box span {
  color: #000;
}
.top_box {
  line-height: 46px;
}
.courseware_bag_head_box .font_blue {
  color: #3498db;
  position: relative;
  cursor: pointer;
  margin-left: 162px;
}
.courseware_bag_head_box .top_btn:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  right: 18px;
  top: 13px;
  background: url(../../../../../public/image/coupon_unfold_top.png) no-repeat
    50%;
  background-size: 18px;
  opacity: 0.7;
}
.courseware_bag_head_box .bottom_btn:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  right: 18px;
  top: 13px;
  background: url(../../../../../public/image/coupon_unfold_bottom.png)
    no-repeat 50%;
  background-size: 18px;
  opacity: 0.7;
}
.chg_btn_class {
  width: 700px;
  height: auto;
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 4px;
  border-top: 0px;
}
.chg_btn_class:last-child {
  border-bottom: 0px;
}
.monthly_bill_text {
  width: 80px;
  height: 48px;
  text-align: center;
  line-height: 60px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 15px;
}
.monthly_bill_record_list {
  display: flex;
  color: #000;
  border-bottom: 1px solid #ececec;
  padding-left: 1px;
}
.monthly_bill_record_list .record_box {
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-left: 14px;
  margin-right: 12px;
}
.monthly_bill_record_list .date_icon {
  position: relative;
  color: #999;
}
.monthly_bill_record_list .date_icon:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  left: 70px;
  top: 10px;
  background: url(../../../../../public/cw-img/attend_class.png) no-repeat 50%;
  background-size: 14px;
}
.monthly_bill_record_list .lessons_icon {
  position: relative;
  color: #999;
}
.monthly_bill_record_list .lessons_icon:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  left: -22px;
  top: 0px;
  background: url(../../../../../public/cw-img/prepare_lessons.png) no-repeat
    50%;
  background-size: 14px;
}
.SQ {
  min-width: 185px !important;
}
.SQ div {
  display: flex;
}
.SQ div span {
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
