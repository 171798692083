<template>
  <div class="form_item form-input form_info_line" :class="{ form_info_required: required }">
    <div class="form_info_field">{{formTitle}}</div>
    <div class="form_info_value" :class="{form_info_select:!readonly}" v-if="!oneSelect">
      <span v-if="!vNumberVerify">
        <input
          type="text"
          v-if="!readonly"
          :value="value"
          :placeholder="required?'必选':'可选'"
          readonly
          @click="showEldilog"
        />
      </span>
      <!-- 适用于数字类型 -->
      <span v-else>
        <input
          type="text"
          v-if="!readonly"
          :value="value"
          :placeholder="required?'必选':'可选'"
          readonly
          @click="showEldilog"
        />
      </span>
      <span v-if="readonly" class="input_readonly">{{value}}</span>
    </div>
    <div class="form_info_value" v-if="oneSelect && !readonly">
      <el-radio v-model="radio" label="1" @change="changeVal(radio)">{{radioValue1?radioValue1:"是"}}</el-radio>
      <el-radio v-model="radio" label="0" @change="changeVal(radio)">{{radioValue2?radioValue2:"否"}}</el-radio>
    </div>
    <div class="form_info_value" v-if="oneSelect && readonly">
      <el-radio
        v-model="radio"
        label="1"
        @change="changeVal(radio)"
        disabled
      >{{radioValue1?radioValue1:"是"}}</el-radio>
      <el-radio
        v-model="radio"
        label="0"
        @change="changeVal(radio)"
        disabled
      >{{radioValue2?radioValue2:"否"}}</el-radio>
    </div>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: "formInput",
  data() {
    return {
      radio: this.radioKey ? this.radioKey : "0",
      labelKey: "" // 选项Key
    };
  },
  props: {
    formTitle: {
      type: String,
      required: true
    }, // 标题，必填
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    formPlaceholder: String, // 默认文本，可选，如不提供，则默认显示title
    oneSelect: Boolean, // 显示单选框项
    radioValue1: String,
    radioValue2: String,
    radioKey: [String, Number], // 显示修改后的保存项
    numberVerify: Boolean // 校验数字
  },
  computed: {
    vPlaceholder() {
      // 默认文本，如不提供，则默认显示title
      return this.formPlaceholder || this.formTitle;
    },
    vNumberVerify() {
      return this.numberVerify ? this.numberVerify : false;
    }
  },
  created() {
    // console.log(this.radioKey, 'radioKey++++++++++');
  },
  watch: {},
  methods: {
    updateVal(val) {
      this.$emit("input", val);
    },
    changeVal(val) {
      // console.log('点击触发changeVal', val);
      this.$emit("change", this.formTitle, val);
    },
    showEldilog() {
      this.$emit("showEldilog");
    }
  }
};
</script>
<style scoped>
.form_info_value > span {
  display: inline !important;
}
</style>
