<!-- 日期 -->
<template>
 <el-table-column :fixed="columnData.fixed||false"
                  :label="columnData.label"
                  :prop="columnData.prop"
                  :width="columnData.width||68"
                  :align="columnData.align||'left'"
                  :sort-orders="['ascending', 'descending']"
                  :label-class-name="columnData.columnClass"
                  :class-name="columnData.className">
  <template slot-scope="scope">
    <div>{{setSerialNumber(scope.row[columnData.prop])}}</div>
  </template>
</el-table-column>
</template>

<script>
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          type: String
        };
      }
    }
  },
  created () {
  },
  methods: {
   // 设置序列号
    setSerialNumber (num) {
      if (num > 0) {
        return num > 9 ? num : '0' + Number(num);
      } else {
        return '';
      }
    }
  }
};
</script>

<style>

</style>