<template>
  <div class="attendance_course_info_list"
       @click.stop="goRouter"
       :class="[bantchChgCourse?'mult_info_select_type':'',item.isActive?'opt':'']">
    <div class="list_left">
      <div class="list_date">
        {{$utils.formatDateStr(item.ClassTime,'MM-dd')}} {{item.WeekKeyValue}}
      </div>
      <div class="list_time">
        {{item.ClassTimeName}} {{item.CourseNameKeyValue}}
      </div>
    </div>
    <div class="list_right"
         :class="bantchChgCourse?'no_bg':''">
      <div class="list_type "
           :class="vAttenClass">
        {{item.DeductTypeKey==2?'签到':item.DeductTypeKeyValue}}
      </div>
      <div class=""
           style="font-size:14px;display: flex;flex-direction: column;text-align: center;"
           v-if="item.IsMakeupKey==1">
        <span>{{Number(item.DeductCount)>0?'-'+Number(item.DeductCount):'-'}}</span>
        <span>补课</span>
      </div>
      <div class="list_number"
           v-else>-
        <span v-if="Number(item.DeductCount)>0 && vAttenClass != 'type_gray'">{{Number(item.DeductCount)}}</span>
      </div>
      <!-- <div v-if="Number(item.DeductCount) > 0"
           class="list_number">
        {{Number(item.DeductCount)>0?'-'+Number(item.DeductCount):''}}
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object,
    bantchChgCourse: { // 是否选择批量换课单扣
      type: Boolean,
      default: false
    },
    isDebtComponent: {
      type: Boolean,
      default: false
    }
  },
  created () {

  },
  computed: {
    isHistory () {
      return new Date(this.item.ClassTime) < new Date(this.$utils.getLastYearMinDate());
    },
    vAttenClass () {
      if (this.item.DeductTypeKey == 2) {
        return 'type_green';
      } else if (this.item.DeductTypeKey == 10) {
        return 'type_yellow';
      } else if (this.item.DeductTypeKey == 3) {
        return 'type_red';
      } else {
        return '';
      }
    },
    visPass () {
      if (Date.new(this.item.ClassTime).getTime() + (59 * 86400000) > Date.new().getTime()) {
        return true;
      } else {
        return false;
      }
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    }
  },
  methods: {
    // 判断权限,
    hasModulePower () {
      return this.$utils.CheckModulePower(this.vModulePowerList, 48);
    },
    goRouter () {
      if (!this.bantchChgCourse) {
        console.log('hasModulePower', this.hasModulePower());
        console.log('isHistory', this.isHistory);
        if (this.isDebtComponent && this.isHistory) {
          return false;
        }
        this.$dialog.open(this, {
          name: '课程详情',
          routerName: this.$route.name,
          moduleName: 'attendanceArrange',
          data: { scheduleCourseKey: this.item.TheLessonKey, isAlter: this.hasModulePower(), isHistory: this.isHistory },
          callBack: { afterSucces: () => { this.reload(); } }
        });
      } else if (!this.visPass) {
        layer.alert('不可选择59天前的课消');
      } else {
        this.item.isActive = !this.item.isActive;
      }
      console.log(this.item, 'goRouter');
    }
  }
};
</script>

<style>
.attendance_course_info_ul .mult_info_select_type {
  padding-left: 30px;
  position: relative;
}
.attendance_course_info_ul .mult_info_select_type::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -9px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #c2c2c2;
}
.attendance_course_info_ul .mult_info_select_type.opt::after {
  border-color: #ff6c67;
  background: #ff6c67 url(../../../../../../../public/image/tick_white@2x.png)
    no-repeat center center;
  background-size: 10px;
}
.no_bg {
  background: none !important;
}
.attendance_course_info_list {
  display: flex;
  padding: 16px 0;
  min-height: 70px;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  overflow: hidden;
  cursor: pointer;
}
.attendance_course_info_list .list_right {
  display: flex;
  padding-right: 18px;
  background: url(../../../../../../../public/image/next_icon@2x.png) no-repeat
    right center;
  background-size: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-content: center;
  align-content: center;
}
.attendance_course_info_list .list_date {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 215px;
}
.attendance_course_info_list .list_time {
  font-size: 12px;
  line-height: 15px;
  color: #999;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
}
.attendance_course_info_list .list_type {
  width: 39px;
  height: 19px;
  line-height: 19px;
  text-align: center;
  border-radius: 9px;
  font-size: 12px;
  margin-right: 5px;
}
.attendance_course_info_list .list_type.type_green {
  background-color: #ddf8eb;
  color: #1ed07a;
}
.attendance_course_info_list .list_type.type_yellow {
  background-color: #fff2c7;
  color: #ff8c39;
}
.attendance_course_info_list .list_type.type_red {
  color: #ff7527;
  border-color: #ffdfc7;
  background-color: #ffdfc7;
}
.attendance_course_info_list .list_type.type_pink {
  color: #ff6c67;
  background-color: #fcedf0;
}
.attendance_course_info_list .list_number {
  font-size: 16px;
  line-height: 19px;
  height: 19px;
  font-weight: bold;
  font-family: Arial;
  min-width: 35px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
