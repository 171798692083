<template>
  <div v-if="coursewareInfo.MainDemoName">
    <div class="right_title">
      <div class="title_name">
        {{coursewareInfo.MainDemoName}}
      </div>
      <div class="title_text"
           v-if="coursewareInfo.MainDemoName">
        课件：{{coursewareInfo.CoursewareNum}} 
      </div>
      <div class="title_right" v-if="coursewareInfo.Authorize">授权设备:
        <span class="font_black">{{coursewareInfo.Authorize.AuthorizeDeviceNum}}</span> 台（已授权设备
        <span :class="vPowerCss"
              @click="showAuth">{{coursewareInfo.Authorize.AuthDeviceNum}}</span>台）
      </div>
    </div>
    <div class="right_impower_text" v-if="coursewareInfo.Authorize">
      授权方:{{coursewareInfo.Authorize.SaaSClientKeyValue}} 丨 经办人:{{coursewareInfo.Authorize.OperatorKeyValue}} 丨 授权日期:{{this.$utils.formatDateToYYYYMMDD(coursewareInfo.Authorize.AuthorizeTime)}} 丨 授权方式:
      <span v-if="coursewareInfo.Authorize.ClearingFormKey==1&&Number(coursewareInfo.Authorize.ReleasedNum)>0">课次授权
        <span>{{Number(coursewareInfo.Authorize.ReleasedNum) }}课次</span>
        <span>(剩余{{ Number(coursewareInfo.Authorize.ReleasedNum) - Number(coursewareInfo.Authorize.UsageNum) }}课次)</span>
      </span>
      <span v-else-if="coursewareInfo.Authorize.ClearingFormKey==2">按年授权
        <span>{{Number(coursewareInfo.Authorize.ExpireTime.slice(0,4))-Number(coursewareInfo.Authorize.AuthorizeTime.slice(0,4))}}年</span>
        <span>(剩余{{ $utils.DateDiffDay(this.$utils.formatDateToLine(Date.new()),coursewareInfo.Authorize.ExpireTime.slice(0, 10))}}天)</span>
      </span>
      <span v-else>{{coursewareInfo.Authorize.ClearingFormKeyValue}}</span>
    </div>
    <custom-dialog :title="'授权设备'"
                   width="740px"
                   :visible.sync="showAuthList"
                   :before-close="closeAuthListDialog">
      <auth-manager-list :dataInfo="{CoursewarePackageKey:coursewareInfo.ID}"
                         @afterSucces="afterSucces"></auth-manager-list>
    </custom-dialog>
  </div>
</template>
<script>
// auth-components
import authManagerList from '../../pop-up-menu/auth-components/auth-manager-list';
export default {
  components: {
    authManagerList
  },
  data () {
    return {
      showAuthList: false
    };
  },
  props: {
    coursewareInfo: Object
  },
  created () {
    console.log(this.coursewareInfo, 'coursewareInfo');
  },
  computed: {
    vPowerCss () {
      // 有权限
      if (this.vHasModulePower(123) && !this.coursewareInfo.type) {
        return 'font_blue';
        // 无权限
      } else {
        return 'font_gray';
      }
    }
  },
  methods: {
    showAuth () {
      if (this.vHasModulePower(123) && !this.coursewareInfo.type) {
        this.showAuthList = true;
      } else {
        // this.$utils.CheckModulePowerToTips(123);
      }
    },
    afterSucces () {
      this.$emit('afterSuccesAuth');
    },
    closeAuthListDialog () {
      this.showAuthList = false;
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.$store.getters.RolePowerList, ModulePowerKey);
    }
  }
};
</script>