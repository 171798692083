<template>
  <div class="merchants_set"
       :class="{'abnormal_icon':item.StatusKey==0}"
       @mouseleave.stop="mouseOutNewTable">
    <div class="mult_info_list_text"
         @mouseenter.stop="mouseOverNewTable">
      <span>{{item.MainDemoName}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'items',
  props: ['item', 'index'],
  data () {
    return {

    };
  },
  computed: {

  },
  methods: {
    // 鼠标移入
    mouseOverNewTable () {
      this.$emit('mouseOverNewTable', this.index, this.item);
    },
    // 鼠标离开
    mouseOutNewTable () {
      this.$emit('mouseOutNewTable', this.index, this.item);
    }
  }
};
</script>

<style>
.merchants_set {
  line-height: 50px;
  border-bottom: 1px solid hsla(0, 0%, 90%, 0.5);
  font-size: 14px;
  position: relative;
  cursor: pointer;
}
.mult_info_list_text {
  border-bottom: 1px solid rgba(236, 236, 236, 0.5);
  background-repeat: no-repeat;
  background-position: 336px center;
  background-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
  position: relative;
  padding-left: 15px;
}
.merchants_set:hover {
  color: #42a3da;
  background-color: #eaf4fb;
}
</style>
