<template>
  <div class="contract_list">
    <function-btn :item="addbtn"
                  @clickItem="addBtnClick"
                  :className="'defult_dialog_header_btn fr'">
      <span class="btn_add">+</span>新增
    </function-btn>
    <div>
      <item v-for="(item,key) in contractList"
            :key="key"
            :item="item"
            :vHasModulePower="vHasModulePower"
            @deleteImg="deleteImg"
            @showPreview="showPreview"
            @editItem="editItem"></item>
      <basics-nodata-tips v-if="contractList.length==0"></basics-nodata-tips>
    </div>
    <div class="course_table_fixed_right"
         v-if="vHasModulePower">
      <choose-img-mutil class="upload_box"
                        :maxNum="9"
                        :imgMaxWidth="0"
                        @upLoadImgReject="doAfterUpLoadImgSuccess"></choose-img-mutil>
    </div>
    <custom-dialog :title="'预览图片'"
                   :visible.sync="isPreviewImg"
                   :before-close="closePreviewImg">
      <div class="home_work_img">
        <div class="defult_dialog_header_btn fr"
             @click="download">
          下载
        </div>

        <preview-img :imgList="vContractList"
                     :showIndex="contractInfo.index"
                     :previewText="contractInfo.Content"
                     @onClosePreviewImg="closePreviewImg"></preview-img>
      </div>
    </custom-dialog>
  </div>
  <!-- <div v-if="isPreviewImg">
      <preview-img :showIndex="imgShowIndex"
                   :imgHeight="'90%'"
                   :imgList="contractList"
                   :isCanEdit="false"
                   @onClosePreviewImg="toClosePreviewImg"></preview-img>
    </div> -->
</template>
<script>
import previewImg from '../../../../../home-school-communication/common-component/preview-img/index';
import item from './item.vue';
import { GetContractList, ContractAdd, ContractEdit, ContractDel } from '../../../../../../API/workbench';
export default {

  data () {
    return {
      PhotoUrl: '',
      contractList: [],
      isPreviewImg: false,
      contractInfo: { index: 0, Content: '' },
      ModulePowerKey: {// 权限
        type: Number,
        default: 0
      },
      addbtn: {
        name: '',
        type: '',
        ModulePowerKey: 48
      }
    };
  },
  components: {
    item,
    previewImg
  },
  props: {
    StudentKey: Number
  },
  computed: {
    vContractList () {
      let list = [];
      this.contractList.forEach(o => {
        list.push({ imgUrl: o.AttachUrl, id: o.OLAPKey });
      });
      return list;
    },
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    },
    vHasModulePower () {
      return this.$utils.CheckModulePower(
        this.vRolePowerList,
        this.addbtn.ModulePowerKey
      );
    }
  },
  created () {
    if (this.StudentKey > 0) {
      this.getContractList();
    }
  },
  methods: {
    getUrlBase64 (url) {
      return new Promise(resolve => {
        let Imgbox = document.getElementById('img_box_' + (this.contractInfo.index + 1));
        console.log(Imgbox, 'Imgbox', Imgbox.naturalWidth, Imgbox.naturalHeight);
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let img = new Image();
        img.crossOrigin = 'Anonymous'; // 允许跨域
        img.src = url;
        img.onload = function () {
          canvas.width = Imgbox.naturalWidth;
          canvas.height = Imgbox.naturalHeight;
          ctx.drawImage(img, 0, 0, Imgbox.naturalWidth, Imgbox.naturalHeight);
          let dataURL = canvas.toDataURL('image/png');
          canvas = null;
          resolve(dataURL);
        };
      });
    },
    // 下载图片
    download () {
      console.log('this.PhotoUrl', this.PhotoUrl);
      this.getUrlBase64(this.PhotoUrl).then(base64 => {
        let link = document.createElement('a');
        link.href = base64;
        link.download = 'qrCode.png';
        link.click();
      });
    },
    showPreview (item) {
      this.PhotoUrl = this.$store.getters.CDNURL + item.AttachUrl;
      console.log(item, 'aaa');
      this.isPreviewImg = true;
      let locaIndex = this.contractList.findIndex(o => {
        return o.OLAPKey == item.OLAPKey;
      });
      if (locaIndex >= 0) {
        this.contractInfo.Content = item.Title;
        this.contractInfo.index = locaIndex;
      } else {
        this.contractInfo.index = 0;
      }
    },
    // 关闭预览图片
    closePreviewImg () {
      this.isPreviewImg = false;
      this.contractInfo.index = 0;
    },
    addBtnClick () {

    },
    // 删除
    deleteImg (item) {
      layer.confirm('请问是否删除该合同图片?', {
        btn: [{
          name: '确认',
          callBack: () => {
            ContractDel(item.OLAPKey).then(result => {
              let locaIndex = this.contractList.findIndex(o => {
                return item.OLAPKey == o.OLAPKey;
              });
              if (locaIndex >= 0) {
                this.contractList.splice(locaIndex, 1);
              }
              this.$emit('upContractCount', this.contractList);
            });
            layer.close();
          }
        }
        ]
      });
    },
    // 修改
    editItem (item) {
      item.Title = item.Title || '';
      ContractEdit(item.OLAPKey, item.AttachUrl, item.Title).then(result => {
        console.log('ContractEdit', result.data);
      }).catch(error => {
        console.log('errorContractEdit', error);
      });
    },
    // 新增
    doAfterUpLoadImgSuccess (imgUrl, callback) {
      console.log('doAfterUpLoadImgSuccess', imgUrl);
      ContractAdd(this.StudentKey, imgUrl, '').then(result => {
        this.contractList.push(result.data);
        this.$emit('upContractCount', this.contractList);
      }).catch(error => {
        console.log('errorContractAdd', error);
      });
    },
    getContractList () {
      GetContractList(this.StudentKey).then(result => {
        console.log('GetContractList', result.data);
        this.contractList = result.data;
      }).catch(error => {
        console.log('errorGetContractList', error);
      });
    }
  }
};
</script>
<style>
.contract_list {
  height: 625px;
  overflow-y: auto;
  background-color: #f5f5f5;
  padding-top: 20px;
  padding-bottom: 20px;
}
.contract_list .uploading_input {
  position: absolute;
  top: 4px;
  right: 0px;
  left: 295px;
  bottom: 0;
  opacity: 0;
  width: 60px;
  height: 40px;
  z-index: 99;
}
.contract_list .monitoring_content_nodata {
  background-color: #f5f5f5;
}
.contract_list .monitoring_content_nodata_txt2 {
  background-color: #f5f5f5;
}
</style>