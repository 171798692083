const ishowEditor = {
  state: {
    showSelectBox: false
  },

  getters: {
    showSelectBox: state => {
      return state.showSelectBox;
    }
  },
  mutations: {
    showSelectBox: (state, bol) => {
      console.log('searchDialogVisible:' + bol);
      state.showSelectBox = bol;
    }
  }
};

export default ishowEditor;
