<template>
  <div class="apply_payment_details_box massive_bg  fl">
    <div class="apply_payment_box_title">
      {{formTitle}}
    </div>
    <div class="apply_payment_box_number box_dot dot_green">
      <span>￥</span>{{Amount}}
    </div>
    <div class="apply_payment_box_singular box_dot dot_blue">
      单数
      <span>{{Count}}</span> 单
    </div>
    <div class="apply_payment_box_btn"
         @click.stop="showDetail">
      本月详情
    </div>
  </div>
</template>
<script>
export default {
  name: 'applyAmountItem',
  data () {
    return {

    };
  },
  props: {
    formTitle: String,
    AmountValue: [String, Number],
    dataInfo: Object
  },
  computed: {
    Amount () {
      if (this.AmountValue) {
        return (Math.round(Number(this.AmountValue) * 100) / 100).toFixed(2) || 0;
      } else {
        return 0;
      }
    },
    Count () {
      if (this.dataInfo) {
        return this.dataInfo.Number || this.dataInfo.number || 0;
      }
    }
  },
  methods: {
    showDetail () {
      this.$emit('showDetail');
    }
  }
};
</script>

