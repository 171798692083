<template>
  <div>
    <div class="from_title">
      <span>{{formTitle}}</span>
      <span class="font_gray">{{tipsTitle}}</span>
    </div>
    <div class="from_list">
      <div class="from_list_title">
        <!-- {{formTitle}} -->
        <!-- <span>{{tipsTitle}}</span> -->
      </div>
      <div class="from_list_content">
        <div class="from_list_textarea">
          <textarea v-html="value"
                    :placeholder="formPlaceholder?formPlaceholder:'请输入'"
                    :readonly="readonly"
                    :contenteditable="true"
                    @click="seletedItem"
                    @keypress="changeVal($event.target.value)"
                    @keyup="changeVal($event.target.value)"
                    @input="updateVal($event.target.value)"
                    @change="changeVal($event.target.value)"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    formTitle: String,
    unintTitle: String,       // 单位
    tipsTitle: String,        // 提示.
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    formPlaceholder: String, // 默认文本，可选，如不提供，则默认显示title,
    inputClass: String,
    titleClass: String,
    unitClass: String
  },
  methods: {
    updateVal (val) {
      this.$emit('input', val);
    },
    changeVal (val) {
      this.$emit('change', val);
    },
    showEldilog () {
      this.$emit('showEldilog');
    },
    keypressChange (val) {
      this.$emit('keypressChange', val);
    },
    seletedItem () {
      this.$emit("seletedItem")
    }
  }

};
</script>
