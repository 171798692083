<template>
  <!-- 老师上课绩效列表 -->
  <div>

    <span class="defult_dialog_header_btn fr">
      <zx-Import-button @click="doExportInfoClick()"
                        :isSingleSubmit="true"
                        :btnClass="'btn_fixed_Import'"
                        :singleSubmitType="'static'"
                        style="margin-left: 22px;"
                        :btnText="'导出'"></zx-Import-button>
    </span>

    <div style="background-color: #fff">
      <!-- 搜索选择层 -->
      <input-search-immediately v-model="searchText"
                                :placeholder="'请输入老师名、课程名'"
                                :styleText="'right: 40px;'"
                                @clearSearch="clearSearch"></input-search-immediately>

      <div class="new_table_title_date">
        <div class="date_last fl"
             @click.stop="preMonth"></div>
        <span class="date_text">{{vData[0] + '年' + vData[1] + '月'}}</span>
        <div class="date_next fr"
             @click.stop="nextMonth"></div>
      </div>
      <div class="new_table_list_box">
        <div class="new_table_list_thead">
          <div style="width: 100px;"
               class="new_table_list_th style_gray style_br_left tl">老师</div>
          <div style="width: 140px;"
               class="new_table_list_th style_yellow style_br_left tl">课程</div>
          <div class="new_table_list_th style_red style_br tc">上课<br>节数</div>
          <div class="new_table_list_th style_green style_br tc">签到<br>人数</div>
          <div class="new_table_list_th style_blue style_br tc">消耗<br>课时</div>
        </div>
        <div class="new_table_list_tbody"
             style="max-height:600px;overflow-y: auto;cursor: pointer">
          <div class="new_table_list_tr"
               v-for="(item,index) in vTeacherStatisticsList"
               :key="index"
               @click="doTeacherCourseDetailsClick(item)">
            <div style="width: 100px;"
                 class="new_table_list_td tl">
              <div class="font_name">
                <span>{{item.TeacherKeyValue}}</span>
              </div>
            </div>
            <div style="width: 140px;"
                 class="new_table_list_td tl">
              <span class="">{{item.CourseTypeKeyValue}}</span>
            </div>
            <div style="width: 65px;"
                 class="new_table_list_td tc">
              <span class="">{{$utils.newAmountType(item.CourseCount,1)}}</span>
            </div>
            <div style="width: 65px;"
                 class="new_table_list_td tc">
              <div>
                <span class="">{{$utils.newAmountType(item.SignCount,0)}}</span>
              </div>
            </div>

            <div style="width: 65px;"
                 class="new_table_list_td tc">
              <div>
                <span class="font_blue">{{$utils.newAmountType(item.StudentHourCount,1)}}</span>
              </div>
            </div>
            <div class="new_table_list_arrow"
                 style="right: 5px;"
                 v-if="Number(item.CourseCount)>0"></div>
          </div>
          <div v-if="vTeacherStatisticsList.length==0">
            <div class="monitoring_content_nodata">
              <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <teacherCourseDetailsItem ref="getTeacherCourseDetails"></teacherCourseDetailsItem>
    </div>
  </div>
</template> 

<script>
import zxImportButton from '../../common/zx-Import-button';
import teacherCourseDetailsItem from './teacher-course-details-item';
import { MessageSearchTeachingPerformance } from '../../../API/workbench';

export default {
  data () {
    return {
      searchText: '', // 搜索项
      date: '', // 日期
      // 老师统计查询
      parameter: {
        startTime: '',
        endTime: ''
      },
      teacherStatisticsList: [], // 老师统计列表
      isShowModule: false, // 控制显示表单
      isShowAdd: true // 导出权限是否开启
    };
  },
  props: [

  ],
  components: {
    teacherCourseDetailsItem,
    zxImportButton
  },

  created () {
    this.date = this.$utils.getCurrentMonthFirst();
    this.parameter.startTime = this.date;
    this.parameter.endTime = this.$utils.getday(this.date);
    this.loadData();
  },
  mounted () {

  },
  activated () {

  },
  watch: {

  },
  computed: {
    vData () {
      let arr = this.date.split('-');
      return arr;
    },
    //  即时搜索
    vTeacherStatisticsList () {
      let newArr = [];
      let trueSearchText = this.searchText.toLowerCase();
      if (trueSearchText) {
        this.teacherStatisticsList.forEach(o => {
          if (trueSearchText && (
            o.TeacherKeyValue.toLowerCase().indexOf(trueSearchText) != -1 ||
            o.CourseTypeKeyValue.toLowerCase().indexOf(trueSearchText) != -1 ||
            (o.TFirstLetterName && o.TFirstLetterName.toLowerCase().indexOf(trueSearchText) != -1) ||
            (o.CFirstLetterName && o.CFirstLetterName.toLowerCase().indexOf(trueSearchText) != -1)
          )) {
            newArr.push(o);
          }
        });
        return newArr;
      } else {
        return this.teacherStatisticsList;
      }
    }
  },
  methods: {
    // 清空搜索条件并查找
    clearSearch () {
      this.searchText = '';
    },
    closeModule () {
      this.isShowModule = false;
      this.searchText = '';
      this.date = this.$utils.getCurrentMonthFirst();
      this.$emit('closeDialog');
    },
    loadData () {
      this.isShowModule = true;
      console.log(this.parameter, '日期');
      MessageSearchTeachingPerformance(this.parameter).then(result => {
        this.teacherStatisticsList = result.data;
        console.log(result.data, '老师统计查询');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 上月
    preMonth () {
      this.date = this.$utils.getPreMonth(this.date);
      this.parameter.startTime = this.date;
      this.parameter.endTime = this.$utils.getday(this.parameter.startTime);
      document.getElementsByClassName('new_table_list_tbody')[0].scrollTop = 0;
      // console.log(this.parameter, '上月');
      this.loadData();// 渲染列表数据
    },
    // 下月
    nextMonth () {
      this.date = this.$utils.getNextMonth(this.date);
      this.parameter.startTime = this.date;
      this.parameter.endTime = this.$utils.getday(this.parameter.startTime);
      document.getElementsByClassName('new_table_list_tbody')[0].scrollTop = 0;
      // console.log(this.parameter, '下月');
      this.loadData();// 渲染列表数据
    },

  // 老师上课详情页面
    doTeacherCourseDetailsClick (item) {
      if (Number(item.CourseCount) > 0) {
        this.$refs.getTeacherCourseDetails.teacherItem = item;
        this.$refs.getTeacherCourseDetails.parameter.startTime = this.parameter.startTime;
        this.$refs.getTeacherCourseDetails.parameter.endTime = this.parameter.endTime;
        this.$refs.getTeacherCourseDetails.parameter.TecherId = item.TeacherKey;
        this.$refs.getTeacherCourseDetails.parameter.CourseId = item.CourseTypeKey;
        this.$refs.getTeacherCourseDetails.loadData();
      }
    },
  // 导出
    doExportInfoClick () {
      console.log('导出');
      if (this.teacherStatisticsList.length > 0) {
        this.parameter.IsExportKey = 1;
        let jsonFormate = {
          SheetTitle: '老师上课绩效',
          clist: [
          { title: '老师', cName: 'TeacherKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '课程', cName: 'CourseTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '上课节数', cName: 'CourseCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '签到人数', cName: 'SignCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '消耗课时', cName: 'StudentHourCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchTeachingPerformance(this.parameter, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.parameter.IsExportKey = 0;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
    }
  }

};
</script>

  <style>
/*  */
</style>