<template>
  <!-- 授权管理 -->
  <div class="authorization_courseware_manage balance_detail_report">
    <div class="course_statistics_table_search flex">
      <date-range
        ref="saveDate"
        style="align-items: center; margin-right: 20px"
        :searchObj="searchObj"
        :fromTitle="'时间范围'"
        @changeDate="changeSaveDate"
      ></date-range>
      <div
        class="table_select_box"
        style="margin-right: 20px; min-width: 192px"
      >
        <div class="table_select_title">发生类型</div>
        <el-select
          :value="vTypeKeyValue"
          placeholder="请选择"
          style="width: 115px"
          ref="resetText"
          @change="selectTypeKey"
        >
          <el-option
            v-for="item in TypeList"
            :key="item.value"
            :label="item.label"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="course_statistics_table_box table_list_content">
      <table-list
        class="summarizing_list"
        :tableData="vdataReportList"
        :tableColumns="tableCourseColumns"
        :key="tableKey"
        :totalNum="totalNum"
        :PageCount="PageCount"
        :queryParams="searchObj"
        :footerContent="vFooterTotal"
        @loadTableData="getDataList"
      ></table-list>
    </div>
  </div>
</template>
<script>
import tableList from "../../../common/table-list/index";
import inputSearchContainBtn from "../../common/input-search-contain-btn";
import prepareLessonsHead from "../../../head-quarters/campus-prepare-lessons-examine/common/prepare-lessons-head";
import {
  CWSSearchPageBalanceDetailsList,
  WEBVSearchPageBalanceDetailsList,
  AdminSearchPageBalanceDetailsList
} from "../../../../API/workbench";
// import dialogFactory from '../../common/dialog-factory/index';
export default {
  components: {
    prepareLessonsHead,
    tableList,
    inputSearchContainBtn,
    // coursewareDetails
  },
  data() {
    return {
      TypeList: [  //生类型:0-所有;1-现金充值;2-购买流量;3-功能续费;4-余额提现
        {
          label: "全部",
          value: 0,
        },
        {
          label: "现金充值",
          value: 1,
        },
        {
          label: "购买流量",
          value: 2,
        },
        {
          label: "功能续费",
          value: 3,
        },
        {
          label: "余额提现",
          value: 4,
        },
      ],
      // 查询参数
      searchObj: {
        startTime: '', //	字符串	必须		数据源：GET	起始时间
        endTime: '', //	字符串	必须		数据源：GET	结束时间
        pageIndex: 0,
        pageSize: 10,
        searchText: "",
        sequence: "DESC",
        orderBy: "",
        TypeKey: 0, //发生类型:0-所有;1-现金充值;2-购买流量;3-功能续费;4-余额提现
        TypeKeyValue: "全部", 
      
      },
      dataReportList: [],
      PageCount: 0,
      totalNum: 0,
      tableKey: "0",
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: "序号",
          prop: "Number",
          width: 68,
          align: "left",
          type: "tableIndex",
          className: "table_index_gray",
        },
        {
          label: "时间",
          prop: "GenerateTime",
          minWidth: 140,
          align: "left",
          sortable: "custom",
          type: "textItem",
        },
         {
          label: "发生类型",
          prop: "TypeKeyValue",
          minWidth: 100,
          sortable: "custom",
          align: "left",
          type: "textItem",
        },
         {
          label: "金额",
          prop: "AmountCount",
          sortable: false,
          minWidth: 120,
          width: " ",
          type: "textItem",
        },
        {
          label: "内容",
          prop: "Content",
          minWidth: 220,
          sortable: false,
          align: "left",
          type: "textItem",
        },
        {
          label: "操作人",
          prop: "OperatorKeyValue",
          minWidth: 120,
          sortable: false,
          align: "left",
          type: "textItem",
        },
       
       
      ],
     
    };
  },
  props:{
    dataInfo: {
      type:Object,
      default:()=>{
        return{
          StartTime:'',
          EndTime:'',
          OLAPKey:0  //门店ID。 超级管理中需要传递。
        }
      }
    }
  },
  created() {
    console.log(this.dataInfo,'this.dataInfo')
    if(this.dataInfo&&this.searchObj.startTime){
      this.searchObj.startTime =this.dataInfo.StartTime
      this.searchObj.endTime =this.dataInfo.EndTime
    }
    this.getDataList();
  },
  computed: {
    vdataReportList() {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        o.OperatorKeyValue=o.OperatorKeyValue||'-'
        o.AmountCount = Number(o.AmountCount)
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal() {
      let text = "共" + this.totalNum + "条数据";
      return text;
    },
    vmdInfo() {
      return this.$store.getters.SaaSClientInfo;
    },
    vTypeKeyValue() {
      return this.searchObj.TypeKey == 0
        ? "全部"
        : this.searchObj.TypeKeyValue;
    },
  },
  methods: {
    
    getDataList() {
      let fn = "";
       let OLAPKey=this.dataInfo?Number(this.dataInfo.OLAPKey):0
      // // 2标准 3旗舰 4高级 5总部 6课件
      console.log("this.vmdInfo", this.vmdInfo);
       if (this.vmdInfo.OLAPKey == 1) {
        fn =AdminSearchPageBalanceDetailsList
      }else if (this.vmdInfo.EditionKey == 6) {
        fn = CWSSearchPageBalanceDetailsList;
      } else {
        fn = WEBVSearchPageBalanceDetailsList;
      }
      fn(this.searchObj,OLAPKey)
        .then((result) => {
          console.log(result.data, "WEBVGetFluxPackageForList");
          this.dataReportList = result.data.PageDataList;
          this.totalNum = result.data.Total;
        })
        .catch((error) => {
          console.log("errorWEBVGetFluxPackageForList", error.msg);
        });
    },
    changeSaveDate(dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.search()
    },
    search() {
      this.searchObj.pageIndex=0
      this.getDataList();
    },
    selectTypeKey(item) {
      this.searchObj.TypeKey = item.value;
      this.searchObj.TypeKeyValue = item.label;
      this.search()
    },
  },
};
</script>

<style>
.authorization_courseware_manage .tltle_margin {
  border-bottom: 1px solid #ebeef5;
  width: 100%;
}
.authorization_courseware_manage .table_fixed_nav_list {
  margin: 0 20px;
  padding: 0px 2px;
}
.authorization_courseware_manage .course_statistics_title {
  padding: 0;
}
.authorization_courseware_manage .course_statistics_title .title_date_select {
  width: 200px;
  border: none;
  margin-right: 10px;
  margin-left: 0;
}
.authorization_courseware_manage .table_select_box .el-select {
  width: 200px;
}
.authorization_courseware_manage
  .course_statistics_title
  .title_date_select
  .el-input
  input {
  width: 190px;
}

.authorization_courseware_manage
  .cw_summarizing
  .top_content_list
  .list_content
  .list_num {
  text-overflow: clip;
}
.flow_packet_total {
  position: absolute;
  right: 20px;
  top: 65px;
  line-height: 35px;
}
.balance_detail_report{
  height: 654px;
}
</style>

