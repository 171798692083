<template>
  <!-- 跟单记录 -->
  <div class="documentary_records_list">
    <div class=""
         style="display: flex;margin-bottom: 20px;">

      <!-- 销售  -->
      <div class="table_select_box ascription"
           style="margin-right: 15px;">
        <el-select :value="vStaffValue"
                   filterable
                   placeholder="必选"
                   value-key="OLAPKey"
                   style="width: 160px;margin-left: 0px;"
                   @change="doSelectedStaff">
          <el-option v-for="item in staffList"
                     :key="item.OLAPKey "
                     :label="item.MainDemoName"
                     :value="item"></el-option>
        </el-select>
      </div>
      <date-range ref="monthPicker"
                  :fromTitle="'跟单日期'"
                  :searchObj="searchObj"
                  @changeDate="changeSaveDate"></date-range>

      <!-- 已选条件 -->
      <input-search-contain-btn v-model="queryParams.searchText"
                                :placeholder="'搜索学生'"
                                @clearSearch="clearExtendSearchParams"
                                @onSearchEvent="search"></input-search-contain-btn>
    </div>
    <div class="table_list_content introduce_list campusManagement_list">
      <table-list ref="tableListRef"
                  class="summarizing_list schedule_table_class"
                  :tableData="threadFollowUpList"
                  :queryParams="queryParams"
                  :tableColumns="tableColumns"
                  :rowKey="'OLAPKey'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  @loadTableData="getManageMessageSearchPageThreadFollowUpList"></table-list>
    </div>
    <custom-dialog :title="'分配员工'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowSalesList"
                   :before-close="closeForm">
      <get-sale-list :selectedKey="selectedKey"
                     :OLAPKey="OLAPKey"
                     @closeForm="closeForm"
                     @onSelectedItem="onSelectedItem">
      </get-sale-list>
    </custom-dialog>
    <custom-dialog title="线索跟进记录"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowClewForm"
                   :before-close="doShowFollowUpHide">
      <clew-follow-form :studentItem="studentItem"
                        @close="doShowFollowUpHide"
                        @afterSuccess="getManageMessageSearchPageThreadFollowUpList"></clew-follow-form>
    </custom-dialog>
    <custom-dialog title="跟单记录流水"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowRecordList"
                   :before-close="doShowFollowUpHide">
      <clew-follow-record-list :studentItem="studentItem"
                               :StudentThreadKey="StudentThreadKey"
                               :isAllFollowRecord="isAllFollowRecord"
                               @close="doShowFollowUpHide"></clew-follow-record-list>
    </custom-dialog>
  </div>
</template>
<script>
import { ManageMessageSearchPageThreadFollowUpList, MessageSearchPageForEmployee } from '../../../API/workbench.js';
import inputSearchContainBtn from '../../report/common/input-search-contain-btn';
import tableList from '../../common/table-list/index';
import getSaleList from '../form/getSale-list.vue';// 销售
import clewFollowRecordList from '../../recruit-students-workbenches/clue-list/clew-follow-record-list';
import clewFollowForm from '../../recruit-students-workbenches/clue-list/clew-follow-form';
export default {
  data () {
    return {
      isAllFollowRecord: false, // false 单条数据  true 全部数据
      isShowRecordList: false,
      studentItem: null,
      StudentThreadKey: 0,
      isShowClewForm: false,
      isShowSalesList: false, // 销售
      selectedKey: '', // 销售ID
      OLAPKey: '', // 线索ID
      staffList: [], // 销售
      DocumentaryKeyValue: '',
      queryParams: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        StartTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        EndTime: this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd'),
        SalesKey: '' // 销售ID
      },
      searchObj: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd')
      },
      threadFollowUpList: [],
      tableColumns: [
        {
          label: '序号',
          width: 65,
          prop: 'Number',
          align: 'center',
          type: 'tableIndex',
          sortable: false
        },
        {
          label: '跟单日期',
          width: 100,
          sortable: 'custom',
          prop: 'GenerateTime',
          type: 'text-item'
        },
        {
          label: '归属销售',
          prop: 'SalesKeyValue',
          width: 90,
          align: 'center',
          type: 'text-item'
          // extend: {
          //   isClick: true,
          //   click: (rowData) => {
          //     this.doFollowClick(rowData);
          //   }
          // }
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 100,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '手机号',
          prop: 'MobilePhone',
          width: 110,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '节点变化',
          prop: 'CurrentFormerNodeValue',
          width: 120,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '标签变化',
          prop: 'LabelChange',
          width: 240,
          align: 'left',
          type: 'text-item'
        },
        {
          label: '内容记录',
          prop: 'FollowContentValue',
          align: 'left',
          type: 'text-item',
          extend: {
            type: 'expand',
            click: (rowData) => { this.doFollowContentClick(rowData); }
          }
        },
        {
          label: '操作',
          prop: 'option',
          width: 65,
          type: 'table-btn',
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  isHide: false,
                  click: (rowData) => {
                    this.editBtn(rowData);
                  }
                }
              }
            ]
          }
        }
      ],
      totalNum: 0
    };
  },
  components: {
    inputSearchContainBtn,
    tableList,
    getSaleList,
    clewFollowForm,
    clewFollowRecordList
  },
  computed: {
    // 销售
    vStaffValue () {
      return this.DocumentaryKeyValue == '' ? '全部销售' : this.DocumentaryKeyValue;
    }
  },
  created () {
    this.loadData();
    this.getManageMessageSearchPageThreadFollowUpList();
  },
  watch: {
  },
  activated () {
  },
  mounted () {
  },
  methods: {
    closeForm () {
      this.isShowSalesList = false;// 销售
    },
    // 打开员工列表
    doFollowClick (rowData) {
      this.selectedKey = rowData.SalesKey;
      this.OLAPKey = rowData.OLAPKey;
      this.isShowSalesList = true;
    },
    onSelectedItem (item) {
      console.log('item0,', item);
      this.isShowSalesList = false;// 销售
      this.getManageMessageSearchPageThreadFollowUpList();
    },
    // 根据标签筛选
    doSelectedStaff (obj) {
      console.log(obj, '标签');
      this.DocumentaryKeyValue = obj.MainDemoName;
      this.queryParams.SalesKey = obj.OLAPKey;
      this.queryParams.pageIndex = 0;
      this.getManageMessageSearchPageThreadFollowUpList();
    },
    loadData () {
      let obj = {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号、首字母
        orderBy: 'IsLeaveKey', //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为姓名
        sequence: 'DESC', //	字符串	可选	ASC	数据源：GET	为空则默认顺序; ASC正序;DESC倒序
        IsLeaveKey: '' //	字符串	可选		数据源：GET	是否在职;空表示该条件不生效;0-离职;1-在职
      };
      MessageSearchPageForEmployee(obj).then(
        (result) => {
          if (result.data.PageDataList && result.data.PageDataList.length > 0) {
            result.data.PageDataList.forEach((o) => {
              if (Number(o.IsAdviserKey) == 1) {
                this.staffList.push(o);
              }
            });
          } else {
            this.staffList = [];
          }
          this.staffList.splice(0, 0, {
            OLAPKey: '',
            MainDemoName: '全部销售'
          });
          this.staffList.splice(1, 0, {
            OLAPKey: -1,
            MainDemoName: '未分配'
          });
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    // e为要改变颜色的字符（传入的参数）
    searchAddinfo (a) {
      let res;
      res = a.replace(/\+/g, "<span class='add_minus_red'>+</span>");
      return res;
    },
    searchMinusinfo (a) {
      let res;
      res = a.replace(/-/g, "<span class='add_minus_red'>-</span>");
      return res;
    },
    searchAddOrMinusinfo (a) {
      let res1, res2;
      res1 = a.replace(/\+/g, "<span class='add_minus_red'>+</span>");
      res2 = res1.replace(/-/g, "<span class='add_minus_red'>-</span>");
      return res2;
    },
    // 跟单记录 数据查询
    getManageMessageSearchPageThreadFollowUpList () {
      ManageMessageSearchPageThreadFollowUpList(this.queryParams).then(result => {
        this.totalNum = result.data.Total;
        this.threadFollowUpList = result.data.PageDataList;
        this.threadFollowUpList.forEach(o => {
          o.StudentKeyValue = o.StudentKeyValue ? o.StudentKeyValue : '-';
          o.MobilePhone = o.MobilePhone ? o.MobilePhone : '-';
          o.GenerateTime = o.GenerateTime ? o.GenerateTime : '-';
          o.LabelChange = o.LabelChange ? ((o.LabelChange.indexOf('+') != -1 && o.LabelChange.indexOf('-') != -1) ? this.searchAddOrMinusinfo(o.LabelChange) :
            o.LabelChange.indexOf('+') != -1 ? this.searchAddinfo(o.LabelChange) : (o.LabelChange != '-' && o.LabelChange.indexOf('-') != -1) ? this.searchMinusinfo(o.LabelChange) : '-') : '-';

          // 节点变化
          if (!o.FormerNodeKeyValue) {
            // 原节点为空时
            o.CurrentFormerNodeValue = o.CurrentNodeKeyValue;
          } else if (o.FormerNodeKey < o.CurrentNodeKey) {
            o.CurrentFormerNodeValue = o.FormerNodeKeyValue + '→' + o.CurrentNodeKeyValue;
          } else if (o.FormerNodeKey > o.CurrentNodeKey) {
            o.CurrentFormerNodeValue = '<div style="color:red;">' + o.CurrentNodeKeyValue + '←' + o.FormerNodeKeyValue + '</div>';
          } else {
            // 先节点和原节点相等 或者为空
            o.CurrentFormerNodeValue = '-';
          }
          o.SalesKeyValue = !o.SalesKeyValue ? '未分配' : o.SalesKeyValue;
          o.FollowContentValue = o.FollowContent ? '<div class="recently_documentary_class">' + o.FollowContent + '</div>' : '';
        });
        console.log(result, '跟单记录 数据查询');
      }, error => {
        layer.alert(error.msg);
      });
    },
    doFollowContentClick (item) {
      this.isAllFollowRecord = true;
      console.log(item, 'item studentItem', this.studentItem);
      this.StudentThreadKey = item.StudentThreadKey;
      this.studentItem = item;
      this.isShowRecordList = true;
    },
    doShowFollowUpHide () {
      this.isShowRecordList = false;
      this.isShowClewForm = false;
    },
    editBtn (item) {
      this.isAllFollowRecord = false;
      this.StudentThreadKey = item.FollowUpKey;
      this.studentItem = item;
      this.isShowRecordList = true;
    },
    changeSaveDate (dataInfo) {
      console.log(dataInfo, 'dataInfo');
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.queryParams.StartTime = dataInfo.startTime;
      this.queryParams.EndTime = dataInfo.endTime;
      this.getManageMessageSearchPageThreadFollowUpList();
    },
    search () {
      this.queryParams.pageIndex = 0;
      this.getManageMessageSearchPageThreadFollowUpList();
    },
    clearExtendSearchParams () {
      this.DocumentaryKeyValue = '全部销售';
      this.queryParams = {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        StartTime: '',
        EndTime: '',
        SalesKey: '' // 销售ID
      };
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.getManageMessageSearchPageThreadFollowUpList();
    }
  }
};
</script>

<style scoped>
.schedule_table_class >>> .el-table .recently_documentary_class {
  width: 99%;
  max-height: 84px;
  line-height: 54px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  right: 1px;
  z-index: 13;
  border: 1px solid transparent !important;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 40px;
  color: #333 !important;
}
.schedule_table_class >>> .el-table .recently_documentary_class::after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -11.5px;
  right: 20px;
  width: 21px;
  height: 21px;
  padding-right: 17px;
  z-index: 2;
  background: url(../../../../public/image/course_header.png) no-repeat 50%;
  background-size: 18px 12px;
}
.schedule_table_class >>> .el-table .add_minus_red {
  color: red;
  font-weight: 800;
}
</style>

