<template>
  <!-- 通用列表 -->
  <div class=" table_list_content summarizing_list"
       style="border-radius:8px;">
    <div class="single_result_detail">
      <div class="flex">
        <!-- 已选条件 -->
        <div class="content_search fr pr"
             v-if="!isBatchDistribute"
             style="margin-top: 15px;margin-bottom:15px;display: flex;width: 100%;">
          <div class="course_search_form"
               style="margin-right:20px;width: 365px;">
            <div class="course_search_select border_right search_top">
              <el-select v-model="queryParams.StudentTypeKey"
                         size="small"
                         @change="chgSearchValue">
                <el-option v-for="item in options"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                  <span style="float: left">{{item.label}}</span>
                  <span style="position: absolute;text-align: right;color: #8492a6; font-size: 13px">{{item.sum}}</span>
                </el-option>
              </el-select>
            </div>

            <div class="course_search_input pr">
              <input type="text"
                     :placeholder="'请输入姓名、姓名首字母、手机号'"
                     v-model="searchText"
                     @keyup.enter="searchStudent" />
              <span class="remove_btn"
                    style="right: 0;"
                    v-show="searchText != ''"
                    @click.stop="clearExtendSearchParams()"></span>
            </div>
          </div>

          <el-button class="btn_light_blue btn_hover_bg_blue"
                     style="margin-right:10px"
                     @click.stop="searchStudent">搜索</el-button>
          <el-button class="btn_light_white btn_hover_white"
                     @click.stop="clearExtendSearchParams">清除条件</el-button>

          <div>
            <div @click.stop="showisBatchDistribute"
                 class="batch_allocation_class">批量转线索</div>
          </div>
        </div>
        <div v-if="isBatchDistribute"
             style="margin-top: 15px;margin-bottom: 15px;display: flex;">
          <div class="mendian_allocation_search">
            <div class="pitch_count pr"
                 style="line-height: 36px;">已选中 {{courseList.length}} 项
              <div class="remove_db_btn"
                   @click="toggleSelection"></div>
            </div>
          </div>
          <div>
            <div @click.stop="allocationSaleBtn"
                 class="batch_allocation_class batch_btn_delay">批量转线索</div>
          </div>
        </div>
      </div>
      <table-list ref="tableListRef"
                  :tableData="tableData"
                  :tableColumns="tableColumns"
                  :rowKey="'OLAPKey'"
                  :totalNum="totalNum"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  :footerContent="'共' + totalNum + '条数据'"
                  :isShowCheckBox="true"
                  @changeCheckBoxFun="clickCheckBox"
                  @loadTableData="loadTableData"></table-list>

    </div>
  </div>
</template>

<script>
import { GetPotentialCustomerList, WeChatUnbind, StudentTransformThread } from '../../../API/workbench.js';
import tableList from '../../common/table-list/index';
export default {
  data () {
    return {
      isBatchDistribute: false,
      courseList: [],
      searchText: '',
      queryParams: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选
        sequence: 'DESC', //	字符串
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号、首字母
        StudentTypeKey: 0 // 状态 3-潜客2-离校
      },
      options: [
        { label: '全部', value: 0, sum: 0 },
        { label: '潜客', value: 3, sum: 0 },
        { label: '离校', value: 2, sum: 0 }
      ],
      isShowSalesList: false, // 销售
      totalNum: 0,
      // 查询销售员工
      tableData: [],
      tableColumns: [
        {
          label: '序号',
          width: 65,
          prop: 'Number',
          align: 'center',
          type: 'tableIndex',
          sortable: false
        },
        {
          label: '学生ID',
          prop: 'OLAPKey',
          width: 20,
          sortable: false,
          align: 'left',
          isShow: false,
          type: 'number-item',
          decimalPlace: 0
        },
        {
          label: '登记日期',
          prop: 'RegisterTime',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: 'custom',
          type: 'date-item',
          decimalPlace: 0,
          className: '',
          eventName: this.$initJson.baseFunctionBtnName.read
        },
        {
          label: '学生名',
          prop: 'MainDemoName',
          width: 100,
          sortable: 'custom',
          type: 'student',
          extend: {
            studentKey: 'OLAPKey', // 学生ID
            click: () => { }
          }
        },
        {
          label: '性别',
          prop: 'SexKeyValue',
          width: 50,
          type: 'text-item'
        },
        {
          label: '排课备注',
          prop: 'ArrangeSupport',
          type: 'arrange-support',
          extend: {
            studentKey: 'OLAPKey', // 学生ID
            studentKeyValue: 'MainDemoName', // 学生名
            click: () => { }
          }
        },
        {
          label: '手机号',
          prop: 'CustomerPhoneName',
          width: 110,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          className: ''
        },
        {
          label: '出生日期',
          prop: 'BirthdayTime',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          className: ''
        },
        {
          label: '状态',
          prop: 'StudentTypeKeyValue',
          width: 90,
          sortable: 'custom',
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          className: ''
        },
        {
          label: '登记类型',
          prop: 'RegisterTypeKeyValue',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          className: ''
        },
        {
          label: '关注',
          prop: 'SubscribeKey',
          type: 'wechat-subscribe',
          width: 60,
          extend: {
            click: (rowData) => { this.wechatSubscribeUnbind(rowData); }
          }
        }
      ],
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  components: {
    tableList
  },
  created () {
    this.loadTableData();
  },
  methods: {
    // 选择搜索类型
    chgSearchValue (value) {
      console.log(value, '选择搜索类型', this.queryParams);
      this.queryParams.StudentTypeKey = value;
      this.queryParams.pageIndex = 0;
      this.searchText = '';
      this.queryParams.searchText = '';
      this.loadTableData();
    },
    // 批量分配销售
    showisBatchDistribute () {
      this.isBatchDistribute = true;
    },
    // 批量分配销售 选中一行
    clickCheckBox (lists, rowData) {
      console.log('clickCheckBox', lists, rowData);
      this.courseList = lists;
    },
    // 情况批量选择
    emptySelection (rows) {
      this.$refs.tableListRef.$refs.multipleTable.clearSelection();
      this.$refs.tableListRef.checkBoxList = [];
      this.courseList = [];
      this.isBatchDistribute = false;
    },
    // 批量分配销售
    allocationSaleBtn () {
      if (this.courseList.length == 0) {
        layer.alert('请选择分配线索');
      } else {
        let arr = [];
        // 批量分配
        layer.confirm('确定批量分配为线索吗?', {
          btn: [{
            name: '确认',
            callBack: () => {
              layer.close();
              this.courseList.forEach(o => {
                arr.push(o.OLAPKey);
              });
              console.log('arr1', arr);
              StudentTransformThread(arr).then(result => {
                this.emptySelection();
                layer.alert('分配成功');
                this.loadTableData();
              }, error => {
                layer.alert(error.msg);
              });
            }
          }
          ]
        });
      }
      console.log('allocationSaleBtn66', this.courseList);
    },
    toggleSelection (rows) {
      this.emptySelection();
      console.log(rows, 'toggleSelection');
      this.isBatchDistribute = false;
    },
    // 微信关注解绑
    wechatSubscribeUnbind (rowData) {
      console.log(rowData, '微信关注解绑');
      layer.confirm('请问是否解绑?', {
        btn: [{
          name: '确认',
          callBack: () => {
            WeChatUnbind(rowData.CustKey).then(
              result => {
                layer.alert('解绑成功');
                this.loadTableData();
              }, error => {
                layer.alert(error.msg);
              });
            layer.close();
          }
        }
        ]
      });
    },
    loadTableData () {
      GetPotentialCustomerList(this.queryParams).then(
        (result) => {
          this.totalNum = result.data.Total;
          console.log(result.data, 'result.data');
          this.options[0].sum = result.data.TotalStudentCount || 0;// 全部学生数
          this.options[1].sum = result.data.BrokerCount || 0;// 潜客学生数
          this.options[2].sum = result.data.LeavSchoolCount || 0;// 离校学生数
          if (result.data.PageDataList && result.data.PageDataList.length > 0) {
            result.data.PageDataList.forEach(o => {
              // o.sourceData = o.ApplyClassKeyValue.split(',');
              o.RegisterTime = o.RegisterTime || '';
              o.RegisterTime = o.RegisterTime.substring(0, 10);
              o.BirthdayTime = o.BirthdayTime ? o.BirthdayTime.slice(0, 10) : '-';
              o.CustomerPhoneName = this.$utils.phoneModulePower(o.CustomerPhoneName) || '--';
              o.SubscribeValue = o.SubscribeKey == 1 ? '已关注' : '';
              let info = o.StudentKeyValue + ',' + o.CustomerPhoneName;
              o.studentInfo = info.split(',');
              o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
            });
            this.tableData = result.data.PageDataList;
          } else {
            this.tableData = [];
          }
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    searchStudent () {
      this.queryParams.pageIndex = 0;
      this.queryParams.searchText = this.searchText;
      this.loadTableData();
    },
    clearExtendSearchParams () {
      this.searchText = '';
      this.queryParams = {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选
        sequence: 'DESC', //	字符串
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号、首字母
        StudentTypeKey: 0 // 状态 3-潜客2-离校
      };
      this.loadTableData();
    },
    closeForm () {
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.batch_allocation_class {
  border: 1px solid #2e86c1;
  background-color: #3498db;
  color: #fff !important;
  cursor: pointer;
  line-height: 36px;
  text-align: center;
  height: 36px;
  border-radius: 4px;
  width: 110px;
  position: absolute;
  right: 0px;
}
.batch_btn_delay {
  height: 36px;
  width: 110px !important;
  align-items: center;
  line-height: 35px;
  text-align: center;
  border-radius: 4px;
  background: #3498db url(../../../../public/image/batch_distribution_icon.png)
    no-repeat left 5px center;
  background-size: 16px;
  padding-left: 20px;
  position: static !important;
  margin-left: 20px;
}
.mendian_allocation_search :deep(.search_box) {
  width: 290px !important;
  margin-right: 30px !important;
  min-width: 290px !important;
}
.remove_db_btn {
  background-image: url(../../../../public/image/dialog_del_icon.png);
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 8px;
  margin-top: 3px;
}
</style>