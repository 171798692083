<template>
  <div>

    <!-- 续费预警数据列表 -->
    <div class="renew_warning content_marginTop_30 content_marginRL single_shadow btn_marginBottom_30"
         v-if="renewFeeWarning.PageDataList.length>0">
      <!-- <div class="population">
          <div class="population_left">
            <span class="font_gray">循环课到期续课</span>
            <span class="font_red">{{$route.query.num}}</span>
        </div>-->
      <!-- <div class="population_right">
            <span class="font_gray">剩余天数 ≤ 15天 :</span>
            <span>{{surplusDayCount}}人</span>
        </div>-->
      <!-- <span @click.stop="rewardData">
            <img src="../../../../../public/image/cbb064bf0dc53ffd.jpg"
                alt
                class="update_img">
          </span>
        </div>-->
      <!-- <div class="course_block_white">
          <span class="font_red">*</span>点击续课可快捷建立课表
        </div> -->
      <div class="all_course_table_content"
           style="max-height:615px;height:615px;padding:0 17px"
           @scroll="scrollTOP">
        <div class="content_box_list button"
             v-for="item in renewFeeWarning.PageDataList "
             :key="item.DataKey"
             @click.stop="selectAmendEvent(item,$event)">
          <div class="content_box_list_title">
            {{item.GradeClassKeyValue}}
          </div>
          <div class="clearfix">
            <div class="content_box_list_date fl">
              <div style="padding-top:0">{{item.WeekKeyValue}}</div>
              <div class="font_gray">{{item.ClassTimeName.substring(0, 5)}}</div>
            </div>
            <div class="content_box_list_info fr"
                 style="width:304px">
              <div class="list_info_left fl">
                <div :class="item.CourseNameKeyValue.length>12?'font_dual':''">{{item.CourseNameKeyValue}}</div>
                <div class="font_gray">
                  截止<span :class="vClassTimeStatu(item)?'font_red':''">{{setLastTime(item)}}</span>
                  <span class="font_black">{{Number(item.ClassCount)}}</span>节丨
                  <span class="font_black">{{Number(item.SyncArrangedCount)}}</span>

                  <!-- <span class="font_black"
                        v-if="vIsSysVersionTY">{{Number(item.SyncArrangedCount)}}</span>
                  <span class="font_black"
                        v-else>{{Number(item.RecentNumCount)}}</span> -->
                  人
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="circulation_content_list_left">
              <div class="circulation_content_list_left_inner inner_01">{{item.CourseNameKeyValue}}</div>
              <div class="circulation_content_list_left_inner">每{{item.WeekKeyValue+item.ClassTimeName}} {{item.teaKeyValue}}</div>
              <div class="circulation_content_list_left_inner">截止{{setLastTime(item)}}</div>
            </div>
            <div class="circulation_content_list_right">
              <div class="circulation_content_btn btn_01"
                   @click.stop="overlookEvent(item,index)">忽略</div>
              <function-btn @clickItem="goRouter(item)"
                            :item="reply"
                            :className="'circulation_content_btn btn_02'"></function-btn>
            </div> -->
        </div>
        <div class="new_table_list_bottom"
             v-if="isLoadEnd">
          <span class="loading_btn">加载中...</span>
        </div>
        <div class="new_table_list_bottom"
             v-else-if="noMore">
          <span class="font_gray">已显示全部</span>
        </div>
      </div>
    </div>
    <div class="course_block_nodata content_marginTop_30 content_marginRL single_shadow btn_marginBottom_30"
         style="height:615px"
         v-else>
      <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
    </div>
    <pop-up-drop-down-box :isShowDialog="isShowAlertDialog"
                          :dropDownList="popUpList"
                          :positionLeft="positionLeft"
                          :positionTop="positionTop"
                          @onClick="doAfterClick"></pop-up-drop-down-box>
  </div>
</template>
<script>
import {
  toDoClassWarning,
  getWarningIgnore
} from '../../../API/workbench.js';
import popUpDropDownBox from '../../common/pop-up-drop-down-box';
export default {
  props: ['GOwarningDetailsList', 'callBackFunction'],
  data () {
    return {
      renewFeeWarning: {
        PageDataList: [],
        Total_RestClassHour: 0,
        Total_RestDay: 0
      },
      PageMax: 0,
      PopUpShow: false, // 弹出层选择菜单
      studentItem: {}, // 操作按钮选择对象
      searchObj: {
        pageIndex: 0,
        pageSize: 20,
        searchText: '',
        orderBy: '',
        sequence: ''
      },
      reply: {
        name: '续课',
        eventName: 'newCourseTable', // params: { isRegister: true }, // params:{isRegister:true}为了区分是正常模块进入还是从其他模块进入 其他模块进入需要返回新增修改的值
        ModulePowerKey: 47
      },
      noMore: false, // 判断是否没有数据了.
      isLoadEnd: false, // 判断是否加载完.
      isShowMainForm: false,
      popUpList: [
        { name: '续课', type: 'newCourseTable', isShow: true, ModulePowerKey: 47 },
        { name: '忽略', type: 'ignore', isShow: true }
      ],
      positionLeft: 0,
      positionTop: 0,
      isShowAlertDialog: false
    };
  },
  components: {
    popUpDropDownBox
  },
  created () {
    // document.body.scrollTop = 0;
    // this.updatewarningList();
    document.addEventListener('click', e => {
      const ele = document.getElementsByClassName('alert_dialog')[0];
      if (e.target && !e.target.contains(ele)) {
        this.isShowAlertDialog = false;
      }
    });
    this.doShowPopupShowClick();
  },
  mounted () {
    this.$dialog.register(this, ['continueNewCircleCourseTable']);
  },
  watch: {},
  methods: {
    // 判断到期显示
    vClassTimeStatu (item) {
      if (item) {
        if ((Date.new(item.LastClassTime).getTime() - Date.new().getTime()) / 1000 < 86400 * 14) {
          return true;
        } else {
          return false;
        }
      }
    },
    // 主表单开关
    doShowPopupHideClick () {
      this.isShowAlertDialog = false;
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.renewFeeWarning.PageDataList = [];
      this.renewFeeWarning.Total_RestClassHour = 0;
      this.renewFeeWarning.Total_RestDay = 0;
      this.$emit('chgCallBackFunction');
      this.noMore = false;
      this.isShowMainForm = false;
    },
    doShowPopupShowClick () {
      this.isShowMainForm = true;

      document.body.scrollTop = 0;
      this.updatewarningList();
    },
    resetWarningList () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.renewFeeWarning.PageDataList = [];
      this.renewFeeWarning.Total_RestClassHour = 0;
      this.renewFeeWarning.Total_RestDay = 0;
      document.body.scrollTop = 0;
      this.updatewarningList();
    },
    goRouter (item) {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'continueNewCircleCourseTable',
        data: { ruleID: item.DataKey },
        callBack: { afterSucces: () => { this.rewardData(); } }
      });
    },
    // 滚动加载事件.
    scrollTOP (e) {
      this.isShowAlertDialog = false;
      let ETarget = e.target;
      if (
        (ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight ||
          ETarget.scrollTop + 1 ==
          ETarget.scrollHeight - ETarget.clientHeight) &&
        !this.noMore && !this.isLoadEnd
      ) {
        console.log('scrollTOP', this.noMore);
        this.updatewarningList();
      }
    },
    rewardData () {
      this.renewFeeWarning.PageDataList = [];
      this.searchObj.pageIndex = 0;
      this.renewFeeWarning.Total_RestClassHour = 0;
      this.renewFeeWarning.Total_RestDay = 0;
      this.updatewarningList();
      setTimeout(() => {
        this.$emit('afterSucces');
      }, 500);
      console.log('afterSucces');
    },
    // 调整时间
    setLastTime (item) {
      item.LastClassTime = item.LastClassTime || '';
      return item.LastClassTime.substring(0, 10);
    },
    // 剩余天数
    CurrentMaxDayCount (item) {
      return Number(item);
    },
    // 剩余课时.
    CurrentClassHourCount (item) {
      // 排课时间精确到1位小数点.
      return Number(item).toFixed(1);
    },
    // 返回页面
    returnEvent () {
      this.$router.back(-1);
    },
    //  显示弹出层
    selectAmendEvent (item, e, index) {
      let scrollTop = document.getElementsByClassName('all_course_table_content')[0].scrollTop;
      this.positionLeft = 313;
      this.positionTop = e.currentTarget.offsetTop - scrollTop + 30;
      this.isShowAlertDialog = true;
      this.studentItem = item; // 按钮选择的学生对象
      this.PopUpShow = true;
      document.body.scrollTop = 0;
    },
    // 取消弹出层
    cancelAmendEvent () {
      this.PopUpShow = false;

      // this.$refs.customPopup.PopUpShow = false;
    },
    // 刷新续费预警
    updatewarningList () {
      this.isLoadEnd = true;
      toDoClassWarning(this.searchObj).then(result => {
        result.data.PageDataList.forEach(o => {
          this.renewFeeWarning.PageDataList.push(o);
        });
        console.log(result.data.PageDataList, 'updatewarningList');
        // this.renewFeeWarning = result.data;
        if (result.data.PageDataList.length == this.searchObj.pageSize) {
          this.searchObj.pageIndex++;
        } else {
          this.noMore = true;
        }
        this.isLoadEnd = false;
      });
    },
    // 忽略按钮
    overlookEvent (item, index) {
      let title = item.CourseNameKeyValue + '需续课';
      getWarningIgnore(8, parseInt(item.DataKey), title).then(result => {
        let locaIndex = this.renewFeeWarning.PageDataList.findIndex(o => {
          return item.DataKey == o.DataKey;
        });
        if (locaIndex >= 0) {
          this.renewFeeWarning.PageDataList.splice(locaIndex, 1);
        }
        this.$emit('afterSucces');
      });
    },
    doAfterClick (item) {
      console.log(item, 'doAfterClick');
      switch (item.type) {
        case 'newCourseTable':
          this.goRouter(this.studentItem);
          break;
        case 'ignore':
          this.overlookEvent(this.studentItem);
          break;
        case 'cancel':
          break;
        default:
          break;
      }
      this.isShowAlertDialog = false;
    },
    // 延期按钮
    studentPostponeEvent () {
      this.PopUpShow = false;
      this.$fun.execActionByRole(this.vEKDNorole, () => {
        this.studentItem.OLAPKey = this.studentItem.StudentKey; // 兼容修改课单里面的学生ID不同
        this.$router.push({
          name: 'changeCourseBill',
          params: { StudentKey: this.studentItem.StudentKey }
        });
      }); // 跳转到修改课单模块
    }
    // 查看详情
    // viewMoreList () {
    //   this.isMoreShow = false
    // },
    // // 查看更多
    // isMoreList () {
    //   this.ismoreItem = !this.ismoreItem
    //   if (this.ismoreItem == true) {
    //     this.PageMax = 50
    //     this.addCustomerList(this.PageMax)
    //   } else {
    //     this.PageMax = 3
    //     this.addCustomerList(this.PageMax)
    //   }
    // },
    // showStudentFile (obj) {
    //   obj.OLAPKey = obj.OLAPKey || obj.StudentKey
    //   // console.log(obj, "预警弹出学员档案入口")
    //   this.$emit("showStudentFile", obj)
    // }
  },
  computed: {
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;// true：通用版，false精准版
    },
    // 剩余课时
    surplusHourCount () {
      if (this.renewFeeWarning) {
        return this.renewFeeWarning.Total_RestClassHour;
      } else {
        return 0;
      }
    },
    // 剩余天数
    surplusDayCount () {
      if (this.renewFeeWarning) {
        return this.renewFeeWarning.Total_RestDay;
      } else {
        return 0;
      }
    },
    vBMSKNorole () {
      return !this.$fun.CheckPower(this.$store.getters.getRolestr, 'BMSK');
    },
    vEKDNorole () {
      return !this.$fun.CheckPower(this.$store.getters.getRolestr, 'EKD');
    }
  }
};
</script>
<style scoped>
/* 刷新按钮样式 */
.update_img {
  opacity: 0.5;
  position: relative;
  right: 0.4rem;
  width: 0.36rem;
  height: 0.36rem;
}
</style>