

<template>
  <!-- 当前选中课块的学生 -->

  <!-- 线条类名 border_bottom -->
  <div class="course_student_single_box row_student_item">

    <div class="course_student_single course_student_sign_defult "
         :class="{course_student_sign_disable:studentItem.unSelected}">

      <div class="course_student_photo pr">
        <heads-img :imgUrl="headImg"
                   :dataInfo="studentItem"></heads-img>

      </div>

      <div class="course_student_info"
           style="flex:1">
        <div class="course_student_name">{{studentItem.StudentKeyValue}}</div>
        <div class="row_student_item_btn sign_text"
             :class="studentItem.AttenceStatusKey==4?'sign_text_yellow':studentItem.AttenceStatusKey==5?'sign_text_red':''"
             v-if="!(!studentItem.AttenceStatusKey||studentItem.AttenceStatusKey == 2)">
          {{vAttendanceStatusKeyValue}}
        </div>
        <div class="row_student_item_btn type_green"
             v-if="!studentItem.AttenceStatusKey||studentItem.AttenceStatusKey == 2"
             @click.stop="editAtten(3)">
          签到
        </div>
        <div class="row_student_item_btn type_yellow"
             v-if="!studentItem.AttenceStatusKey||studentItem.AttenceStatusKey == 2"
             @click.stop="editAtten(4)">
          请假
        </div>
        <div class="row_student_item_btn type_red"
             v-if="!studentItem.AttenceStatusKey||studentItem.AttenceStatusKey == 2"
             @click.stop="editAtten(5)">
          旷课
        </div>
        <div class="row_student_item_btn publish_comment_btn"
             v-if="!(!studentItem.AttenceStatusKey||studentItem.AttenceStatusKey == 2)&&studentItem.IsReview!=1"
             @click.stop="editAtten('publishComment')">
          发课评
        </div>
        <div class="row_student_item_btn comment_detail_btn"
             v-if="!(!studentItem.AttenceStatusKey||studentItem.AttenceStatusKey == 2)&&studentItem.IsReview==1"
             @click.stop="editAtten('commentDetail')">
          课评详情>
        </div>

      </div>
      <div class="course_student_options item_menu_bottom_edit"
           v-if="!(!studentItem.AttenceStatusKey||studentItem.AttenceStatusKey == 2)"
           @click.stop="editAtten('')">
        <div>修改</div>

      </div>
      <div class="course_student_options item_menu_bottom_attendance button"
           v-if="(!studentItem.AttenceStatusKey||studentItem.AttenceStatusKey == 2)"
           @click.stop="!studentItem.unSelected&&selectedStudent(studentItem)">
        <div>更多操作</div>

      </div>
    </div>
  </div>
</template>

<script>
/// 有四种 状态 请假，旷课，签到，未签到
// 点击事件 根据状态有两种弹框且要 通知父组件 我被选中（已考勤 未考勤）
// 学生排课表状态 2：占位 3:排队中 4：已安排 5：已上课 6：已旷课 7：已请假 8：已取消
export default {
  name: 'studentItem',
  data () {
    return {
    };
  },
  props: {
    studentItem: Object,
    isCourseEnd: Boolean,
    isBatchAttendance: Boolean,
    isAlter: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },

  computed: {
    vAttendanceStatusKey () {
      return this.studentItem.AttenceStatusKey;
    },
    vAttendanceStatusKeyValue () {
      let text = ''
      if (this.studentItem.AttenceStatusKey == 3) {
        text = '出勤-'
      } else if (this.studentItem.AttenceStatusKey == 4) {
        text = '请假-'
      } else if (this.studentItem.AttenceStatusKey == 5) {
        text = '旷课-'
      }
      return text + Number(this.studentItem.ClassCount) + '课时';
    },
    vNoClassHourse () { // 无课时 未考勤且欠课时
      if (this.studentItem.AttenceStatusKey == 2 && this.studentItem.IsClassHourLackKey == 1) {
        return true;
      } else {
        return false;
      }
    },
    // 头像
    headImg () {
      if (this.studentItem && this.studentItem.HeadImgSupport && this.studentItem.HeadImgSupport !== 'null') {
        if (this.studentItem.HeadImgSupport.indexOf('http') > -1) {
          return this.studentItem.HeadImgSupport;
        } else {
          let PCAPIURL = this.$store.getters.CDNURL;
          return (
            PCAPIURL + this.studentItem.HeadImgSupport
          );
        }
      } else {
        return '';
      }
    }
  },
  methods: {
    editAtten (type) {
      this.$emit("editAtten", type, this.studentItem)
    },
    // 选中学生事件 告诉 <student-list> 并传递选中项信息
    selectedStudent () {
      if (this.isAlter) {
        if (!this.isBatchAttendance) {
          let Rect = this.$el.getBoundingClientRect();
          let parentRect = this.$parent.$el.getBoundingClientRect();
          let that = this;
          document.onclick = function () {
            that.$store.commit('showAlerDialog', false);
            that.studentItem.isSelected = false;
          };
          if (typeof this.$parent.isShowDialog == 'boolean') {
            this.$parent.isShowDialog = true;
          }
          let isRowList = true
          this.$emit('selectedStudent', this.studentItem);
          this.$store.commit('showAlerDialog', true);
          this.$emit('setPosition', Rect.left - parentRect.left, Rect.bottom - parentRect.top, parentRect.top, isRowList); // event.clientX  event.clientY   //显示修改考勤
        } else {
          this.studentItem.isSelected = !this.studentItem.isSelected;
        }
      }
    },
  }
};
</script>
<style scoped>
.course_student_single_box.row_student_item {
  min-height: 100% !important;
}
.row_student_item .course_student_single {
  display: flex !important;
  flex-direction: row !important;
  flex: 1;
  width: 100% !important;
  height: 100% !important;
  align-items: center !important;
}
.row_student_item .course_student_info .course_student_name {
  width: 58px;
  text-align: left;
}
.row_student_item .course_student_info {
  display: flex !important;
  flex-direction: row !important;
  flex: 1;
  margin: 0 10px;
  padding-top: 0px !important;
  align-items: center;
}
.course_student_options {
  /* margin-right: 10px; */
}
.row_student_item_btn {
  /* width: 35px; */
  height: 20px;
  border-radius: 4px !important;
  padding: 2px 4px;
  /* border: 1px solid #32D77E; */
  text-align: center;
  /* line-height: 20px; */
  margin-left: 5px;
  cursor: pointer;
}
.row_student_item_btn.type_green {
  border: 1px solid #32d77e;
  color: #32d77e;
}
.row_student_item_btn.type_yellow {
  border: 1px solid #feb564;
  color: #feb564;
}
.row_student_item_btn.type_red {
  border: 1px solid #f85a59;
  color: #f85a59;
}
.row_student_item_btn.sign_text {
  height: 20px;
  background: rgb(50 215 126 / 20%);
  color: #52c3a1 !important;
}
.sign_text.sign_text_yellow {
  height: 20px;
  background: rgb(254 181 100 / 20%);
  color: #feb564 !important;
}
.sign_text.sign_text_red {
  height: 20px;
  background: rgb(248 90 89 / 20%);
  color: #f85a59 !important;
}
.row_student_item_btn.publish_comment_btn {
  color: #2d98d9;
  border: 1px solid #2d98d9;
  border-radius: 4px;
}
.row_student_item_btn.comment_detail_btn {
  color: #2d98d9;
  background: rgb(45 152 217 / 20%);
  border-radius: 4px;
  border: 1px solid #ececec;
}
.course_student_options.item_menu_bottom_attendance {
  line-height: 30px;
  border-radius: 15px;
  border: 1px solid #ececec;
  width: 90px;
  text-align: center;

  font-size: 14px;
  padding-left: 20px;
  background: url(../../../../public/image/index_menu_icon.png) no-repeat 8px
    center;
  background-size: 14px;
  /* background-position: 12px; */
}

.course_student_options.item_menu_bottom_edit {
  font-size: 14px;
  padding-left: 20px;
  background: url(../../../../public/image/edit_appoint_course.png) no-repeat
    left center;
  background-size: 14px;
  line-height: 30px;
  border-radius: 15px;
  border: 1px solid #ececec;
  width: 72px;
  text-align: center;
  background-position: 12px;
}
</style>