<template>
  <!-- 修改课单 -->
  <div>
    <module-description :textTips="'可更换课程，修改课单和有效期天数。'"
                        :btnTips="'查看教程'"
                        :btnUrl="'https://mp.weixin.qq.com/s/iSb8_ZMhYlQQ7AnuINZxYQ'"></module-description>
    <!-- 搜索学生弹框 -->
    <div v-if="JSON.stringify(isSelectedStudentItem) == '{}'">
      <searchBox :searchInitJson="{ name: '目标学生' }"
                 @searchClick="chooseClick"></searchBox>
    </div>
    <!-- 选中后的课 -->
    <div v-else>
      <selectedCourse-item @optionItem="optionItem"
                           @studentFileItem="studentFileItem"
                           @CourseIndex="CourseIndex"
                           @alterStudent="doAfterSelectedStudent"
                           :isSelectedStudentItem="isSelectedStudentItem"
                           :allStudentCourseList="allStudentCourseList"
                           ref="changeCourse"></selectedCourse-item>
    </div>
    <!-- 选中修改项-课名-->
    <custom-dialog title="调整适用的课"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="couresItemShow"
                   :before-close="closeDialog">
      <mutil-select-tree :sourceDate="courseNameList"
                         :selectionDate="applyCourseDatas"
                         :isRemarksShow="true"
                         @buttonClickEvent="confirmApplyCourseName"
                         @cancleClickForMutilSelected="closeDialog"></mutil-select-tree>
    </custom-dialog>
    <!-- 剩余数量 -->
    <custom-dialog title="调整剩余数量"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="courseHourItemShow"
                   :before-close="closeDialog">
      <course-hour-item :residueCourseCount="residueCourseCount"
                        :formItem="initStudentFileItem"
                        :StudentKey="isSelectedStudentItem.OLAPKey"
                        @buttonClickEvent="buttonClickEvent"
                        @closeDialog="closeDialog"></course-hour-item>
    </custom-dialog>
    <!-- 剩余天数 -->
    <custom-dialog title="调整剩余天数"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="dayNumberItemShow"
                   :before-close="closeDialog">
      <day-number-item :StudentKey="isSelectedStudentItem.OLAPKey"
                       :formItem="initStudentFileItem"
                       :residueDay="residueDay"
                       @closeDialog="closeDialog"
                       @buttonClickEvent="buttonClickEvent"></day-number-item>
    </custom-dialog>
  </div>
</template>

<script>
// 修改课单
import searchBox from '../../../../components/common/search-box';
import selectedCourseItem from './selectedCourse-item';
import mutilSelectTree from '../../../common/mutil-select-tree';
import courseHourItem from './course-hour-item';
import dayNumberItem from './day-number-item';
import {
  getStudentAllCourse,
  getCourseTypeNameList,
  UpdateClassScope
} from '../../../../API/workbench.js';
export default {
  name: 'changecoursecill',
  props: {
    consumeDetail: {
      type: Object
    },
    dataInfo: {
      type: Object
    },
    courseInfo: {
      type: Object
    }
  },
  data () {
    return {
      // 修改 课单弹框
      couresItemShow: false, // 课单
      courseHourItemShow: false, // 剩余数量
      dayNumberItemShow: false, // 剩余天数
      // 学生列表弹框
      isSelectedStudentItem: {}, // 学生选中项
      allStudentCourseList: [],
      applyCourseDatas: { IDs: '', Names: '' },
      residueCourseCount: '', // 剩余数量
      residueDay: '', // 剩余天数
      initStudentFileItem: null,
      courseNameList: [],
      changeCourseIndex: 0,
      ShowstudentFileItem: {}, // 显示选择学生课单项
      newCourseKey: 0
    };
  },
  components: {
    searchBox, // 搜索学生
    selectedCourseItem, // 选中项
    mutilSelectTree,
    courseHourItem,
    dayNumberItem
  },
  created () {
    // this.visAmendSingleCourse = this.isAmendSingleCourse;
    if (this.consumeDetail) {
      this.initStudentFileItem = this.consumeDetail;
    }
    this.getCourseNameList();
    if (this.dataInfo && this.dataInfo.OLAPKey) {
      if (this.courseInfo) {
        this.doAfterSelectedStudent(this.dataInfo, this.courseInfo);
      } else {
        this.doAfterSelectedStudent(this.dataInfo);
      }
    }
  },
  mounted () {
    this.$dialog.register(this, ['studentList']);
  },
  computed: {},
  methods: {
    // 关闭弹窗
    isAmendSingleCourseHide () {
      this.$parent.isAmendSingleCourse = false;
      this.$emit('closeViewDialog');
      this.changeCourseIndex = 0; // "清除学员档案所有课单第一次进入的下标"
    },
    // 点击搜索 切换学生
    chooseClick () {
      console.log('点击搜索 搜索框可见 并查询数据');
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'studentList',
        data: {
          CustStatusKey: 1,
          isShowaddOldStudentBtn: true,
          isShowAddBtn: true
        },
        callBack: {
          selectedItem: (item) => {
            this.doAfterSelectedStudent(item);
          }
        }
      });
    },
    // 适用课程 确认
    confirmApplyCourseName (selectionDate, callBack, Remarks) {
      if (selectionDate.selectedIDs.length == 0) {
        layer.alert('适用课程不能为空');
        if (callBack) {
          callBack();
        }
        return false;
      }
      UpdateClassScope(
        this.initStudentFileItem.TableID,
        selectionDate.selectedIDs.join(','),
        selectionDate.selectedNames.join(','),
        Remarks,
        this.isSelectedStudentItem.OLAPKey).then(resulr => {
          this.$emit('updateCourseEvent');
          layer.alert('修改成功');
          this.closeDialog(this.initStudentFileItem.TableID);
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
    },
    // 点击取消
    closeDialog (tableID) {
      this.validClassList(tableID);
      this.couresItemShow = false; // 课单
      this.courseHourItemShow = false; // 剩余数量
      this.dayNumberItemShow = false; // 剩余天数
    },
    // 打开
    show (title) {
      this.doAfterSelectedStudent(this.dataInfo, this.courseInfo);
    },
    // 选择单项学生
    doAfterSelectedStudent (item, targetCourseItem) {
      console.log(item, '选择单项学生');
      this.isSelectedStudentItem = item;
      let targetCouresKey = targetCourseItem ? targetCourseItem.TableID : null;
      this.refreshStudentAllCourse(
        this.isSelectedStudentItem.OLAPKey ||
        this.isSelectedStudentItem.StudentKey,
        targetCouresKey
      );
    },
    // 选中课单下标
    CourseIndex (index) {
      this.changeCourseIndex = index;
    },
    // 学员 ID获取所有课单.
    validClassList (TableID) {
      let targetCouresKey = TableID || null;
      this.refreshStudentAllCourse(
        this.isSelectedStudentItem.OLAPKey,
        targetCouresKey
      );
    },
    // 点击确定
    buttonClickEvent (TableID) {
      this.closeDialog(TableID);
      this.$emit('updateCourseEvent');
    },
    // 学员 ID获取所有课单.
    refreshStudentAllCourse (studentKey, courseKey) {
      if (Number(courseKey) > 0) {
        this.newCourseKey = JSON.parse(JSON.stringify(courseKey));
      }
      if (studentKey > 0) {
        getStudentAllCourse(studentKey).then(result => {
          this.allStudentCourseList = [];
          if (result.data.length > 0) {
            // 过滤.  只保留状态为正常的课单.
            result.data.forEach(p => {
              if (p.StatusKey == 2) {
                this.allStudentCourseList.push(p);
                // 当外部传入的课ID存在并且符合
                if (this.newCourseKey && this.newCourseKey == p.TableID) {
                  this.changeCourseIndex = this.allStudentCourseList.length - 1;
                }
              }
            });
            if (this.allStudentCourseList.length > 0) {
              // 修改课单-学生档案-监听
              this.allStudentCourseList.forEach(o => {
                this.$set(o, 'isActive', false); // 深度监听.高亮效果.
              });
              this.ShowstudentFileItem = this.allStudentCourseList[this.changeCourseIndex]; // 触发高亮事件.
              this.ShowstudentFileItem.isActive = true;
              this.$refs.changeCourse.selectedItem(this.ShowstudentFileItem); // 这是为了触发子组件的渲染事件.
            }
          }
        });
      }
    },
    studentFileItem (item) {
      this.initStudentFileItem = item;
    },
    // 修改项
    optionItem (optionItem, itemValue, selectionName) {
      if (optionItem == '调整适用的课') {
        this.getCourseNameList();
        this.couresItemShow = true;
        this.applyCourseDatas.IDs = itemValue;
        this.applyCourseDatas.Names = selectionName;
      } else if (optionItem == '调整剩余数量') {
        this.courseHourItemShow = true;
        this.residueCourseCount = itemValue;
      } else {
        this.dayNumberItemShow = true;
        this.residueDay = itemValue;
      }
    },
    // 调用课类课名列表接口
    getCourseNameList () {
      this.courseNameList = [];
      getCourseTypeNameList().then(result => {
        if (result.code == 0) {
          result.data.forEach(obj => {
            if (obj.StateKey == 2) {
              this.courseNameList.push(obj);
            }
          });
          this.courseNameList.splice(0, 0, {
            OLAPKey: '2',
            MainDemoName: '全部课程',
            TypeKey: '1',
            TypeKeyValue: '课类',
            LevelPath: '0.2.',
            FatherKey: '0',
            FatherKeyValue: '',
            StateKey: '2',
            StateKeyValue: '启用',
            UnitPrice: '0.0'
          });
        } else {
          layer.alert('接口异常，注意检查');
        }
      });
    }
  }
};
</script>

  <style>
</style>