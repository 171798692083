<template>
  <div class="img_box">
    <div class="content_bannber">
      <iframe id="PPTiframe" ref="iframe" class="iframe_box"></iframe>
    </div>
    <div class="watermark">
      {{ watermark }}
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
    };
  },
  props: {
    dataInfo: Object,
    watermark: String
  },
  computed: {
    vUrl () {
      if (this.dataInfo.AttachViewUrl) {
        return this.$store.getters.PCWebURL + this.dataInfo.AttachViewUrl + '?sign=' + this.$store.getters.signData.Sign;
      } else {
        return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
      }
    }
  },
  watch: {
    vUrl (n, o) {
      this.$refs.iframe.contentWindow.location.replace(n);
    }
  },
  mounted () {
    window.addEventListener('message', (e) => {
      if (e.data.cmd == 'OnInitalise' && this.$refs.iframe && this.$refs.iframe.contentWindow) {
        console.log(this.dataInfo.currentNum, 'this.dataInfo.currentNum');
        this.$refs.iframe.contentWindow.Presentation.GotoSlide(this.dataInfo.currentNum);
      }
      if (e.data.cmd == 'OnSlideChange' && this.$refs.iframe && this.$refs.iframe.contentWindow) {
        this.slideChange(e.data.params);
      }
      if (e.data.cmd == 'OnSlideEnd' && this.$refs.iframe && this.$refs.iframe.contentWindow) {
        this.slideEnd(e.data.params);
      }
    });
    this.$refs.iframe.contentWindow.location.replace(this.vUrl);
    this.$refs.iframe.focus();
  },
  methods: {
    slideChange (data) {
      this.dataInfo.currentNum = data.slideNo;
    },
    slideEnd (data) {

    }
  }
};
</script>