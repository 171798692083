<template>
  <div>
    <!-- 搜索选择层 -->
    <input-search-immediately v-model="searchText"
                       :placeholder="'请输入套餐名'"
                       :styleText="'right: 40px;'"
                       :inputStyle="'width: 396px;'"
                       @clearSearch="clearSearText"></input-search-immediately>
    <!-- 历史套餐-->
    <div class="WKT_discount_package_popup_ul">
      <combo-history-item v-for="(item,index) in vGetHistoryComboList"
                          :key="index"
                           :comboIndex="index"
                          :comboItem="item"
                          @onStartComboClick="onStartComboClick"></combo-history-item>
      <div v-if="vGetHistoryComboList.length==0">
        <div class="monitoring_content_nodata"
             style="margin-top:160px">
          <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import comboHistoryItem from './combo-history-item';
export default {
  name: 'singleSelectionItem',
  components: {
    comboHistoryItem
  },
  data () {
    return {
      searchText: ''
    };
  },
  props: {
    historyComboList: Array
  },
  created () {

  },
  watch: {

  },
  computed: {
    vGetHistoryComboList () {
      let newList = [];
      let trueSearchText = this.searchText.toLowerCase();
      this.historyComboList.forEach(o => {
        if (o.PackageName && (o.PackageName.toLowerCase().indexOf(trueSearchText) >= 0 ||
         (o.FirstLetterName && o.FirstLetterName.toUpperCase().indexOf(trueSearchText.toUpperCase()) >= 0))) {
          newList.push(o);
        }
      });
      return newList;
    }
  },
  methods: {
    onStartComboClick (comboItem, comboIndex) {
      this.$emit('onStartComboClick', comboItem, comboIndex);
    },
    // 清除按钮
    clearSearText () {
      this.searchText = '';
    }
  }
};
</script>

