<template>
  <!-- 通用列表 -->
  <div>
    <!-- 弹框显示模式 -->
    <div>
      <custom-dialog title="拼团奖励"
                     width="1200px"
                     class="class_staistics_list defult_dialog"
                     :visible.sync="isShowListDialog"
                     :before-close="closeDialog">
        <div class="single_result_detail pr">
          <!-- 拼团信息 -->
          <div class="group_detail_box single_shadow">
            <div class="group_detail_list"
                 style="width:260px">
              <div class="group_detail_list_top"><span class="group_detail_list_name">团长 : </span>{{groupDetail.groupLeaderKeyValue}}</div>
              <div><span class="group_detail_list_name">购券人数 : </span>{{groupDetail.get_num}}人</div>
            </div>
            <div class="group_detail_list"
                 style="width:260px">
              <div class="group_detail_list_top"><span class="group_detail_list_name">开团时间 : </span>{{groupDetail.create_time}}</div>
              <div><span class="group_detail_list_name">用券人数 : </span>{{groupDetail.use_num}}人</div>
            </div>
            <div class="group_detail_list"
                 style="width:260px">
              <div class="group_detail_list_top"><span class="group_detail_list_name">奖励达标 : </span>{{groupDetail.result_type_value}}</div>
              <div><span class="group_detail_list_name">总目标 : </span>{{groupDetail.get_target_rate}}人</div>
            </div>
            <!-- <div class="group_detail_list"
                   style="width:200px">
                <div class="group_detail_list_top"><span class="group_detail_list_name">结算类型 : </span>{{groupDetail.target_type}}</div>
              </div> -->
          </div>
          <div class="group_detail_type"
               :class="groupDetail.status == 1?'type_green':''">{{vStatusValue}}</div>
          <div class="group_detail_award_box flex">
            <div class="group_detail_award">
              <div v-if="groupDetail.get_result_type == 0">
                未达标
              </div>
              <div v-if="groupDetail.get_result_type == 1">
                一阶目标 :
                <span class=""
                      style="margin-right:15px;">
                  团长 :
                  <span v-if="groupDetail.groupSetting.target_1_A.fanxian_amount > 0">
                    {{Number(groupDetail.groupSetting.target_1_A.fanxian_amount)}}元({{fanxianTypeFont(groupDetail.groupSetting.target_1_A.fanxian_type)}}) ;
                  </span>
                  <span v-if="groupDetail.groupSetting.target_1_A.fanhour_num > 0">{{Number(groupDetail.groupSetting.target_1_A.fanhour_num)}}课时 ;</span>
                  <span v-if="groupDetail.groupSetting.target_1_A.fangift_name">{{groupDetail.groupSetting.target_1_A.fangift_name}}</span>
                </span>
                <span class="">团员 :
                  <span v-if="groupDetail.groupSetting.target_1_B.fanxian_amount > 0">
                    {{Number(groupDetail.groupSetting.target_1_B.fanxian_amount)}}元({{fanxianTypeFont(groupDetail.groupSetting.target_1_B.fanxian_type)}}) ;
                  </span>
                  <span v-if="groupDetail.groupSetting.target_1_B.fanhour_num > 0">{{Number(groupDetail.groupSetting.target_1_B.fanhour_num)}}课时 ;</span>
                  <span v-if="groupDetail.groupSetting.target_1_B.fangift_name">{{groupDetail.groupSetting.target_1_B.fangift_name}}</span>
                </span>
              </div>
              <div v-if="groupDetail.get_result_type == 2">
                二阶目标 :
                <span class=""
                      style="margin-right:15px;">
                  团长 :
                  <span v-if="groupDetail.groupSetting.target_2_A.fanxian_amount > 0">
                    {{Number(groupDetail.groupSetting.target_2_A.fanxian_amount)}}元({{fanxianTypeFont(groupDetail.groupSetting.target_2_A.fanxian_type)}}) ;
                  </span>
                  <span v-if="groupDetail.groupSetting.target_2_A.fanhour_num > 0">{{Number(groupDetail.groupSetting.target_2_A.fanhour_num)}}课时 ;</span>
                  <span v-if="groupDetail.groupSetting.target_2_A.fangift_name">{{groupDetail.groupSetting.target_2_A.fangift_name}}</span>
                </span>
                <span class="">团员 :
                  <span v-if="groupDetail.groupSetting.target_2_B.fanxian_amount > 0">
                    {{Number(groupDetail.groupSetting.target_2_B.fanxian_amount)}}元({{fanxianTypeFont(groupDetail.groupSetting.target_2_B.fanxian_type)}}) ;
                  </span>
                  <span v-if="groupDetail.groupSetting.target_2_B.fanhour_num > 0">{{Number(groupDetail.groupSetting.target_2_B.fanhour_num)}}课时 ;</span>
                  <span v-if="groupDetail.groupSetting.target_2_B.fangift_name">{{groupDetail.groupSetting.target_2_B.fangift_name}}</span>
                </span>
              </div>
              <div v-if="groupDetail.get_result_type == 3">
                三阶目标 :
                <span class=""
                      style="margin-right:15px;">
                  团长 :
                  <span v-if="groupDetail.groupSetting.target_3_A.fanxian_amount > 0">
                    {{Number(groupDetail.groupSetting.target_3_A.fanxian_amount)}}元({{fanxianTypeFont(groupDetail.groupSetting.target_3_A.fanxian_type)}}) ;
                  </span>
                  <span v-if="groupDetail.groupSetting.target_3_A.fanhour_num > 0">{{Number(groupDetail.groupSetting.target_3_A.fanhour_num)}}课时 ;</span>
                  <span v-if="groupDetail.groupSetting.target_3_A.fangift_name">{{groupDetail.groupSetting.target_3_A.fangift_name}}</span>
                </span>
                <span class="">团员 :
                  <span v-if="groupDetail.groupSetting.target_3_B.fanxian_amount > 0">
                    {{Number(groupDetail.groupSetting.target_3_B.fanxian_amount)}}元({{fanxianTypeFont(groupDetail.groupSetting.target_3_B.fanxian_type)}}) ;
                  </span>
                  <span v-if="groupDetail.groupSetting.target_3_B.fanhour_num > 0">{{Number(groupDetail.groupSetting.target_3_B.fanhour_num)}}课时 ;</span>
                  <span v-if="groupDetail.groupSetting.target_3_B.fangift_name">{{groupDetail.groupSetting.target_3_B.fangift_name}}</span>
                </span>
              </div>
            </div>
            <div>
              <el-button v-if="groupDetail.get_num == 0 && groupDetail.status != 3"
                         class="btn_hover_bg_blue norole">结算奖励</el-button>
              <el-button v-if="groupDetail.status == 3"
                         class="btn_hover_bg_blue norole">已结算</el-button>
              <el-button class="btn_hover_bg_blue"
                         v-if="groupDetail.get_num != 0 && groupDetail.status != 3"
                         @click.stop="clearExtendSearchParams">结算奖励</el-button>
            </div>
          </div>
          <!-- 表格 -->
          <div class="table_list_content summarizing_list">
            <!-- 筛选条件 -->

            <!-- 列表 -->
            <!-- 
            选中行高亮  highlight-current-row 
            隔行换色  stripe
            数据为空展示的文字  empty-text
            选中行 复选框联动  toggleRowSelection
          -->
            <el-table class="table_list "
                      ref="multipleTable"
                      :data="tableData"
                      highlight-current-row
                      stripe
                      empty-text
                      toggleRowSelection
                      @sort-change="sortChange"
                      @row-click="clickRow"
                      style="width: 100%">
              <!-- 表头 -->
              <el-table-column v-for="(item,index) in tableColumns"
                               :width="item.width"
                               :sortable="item.sortable"
                               :align="item.align"
                               :key="index"
                               :type="item.type"
                               :label="item.label"
                               :prop="item.prop"
                               :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <div class="template_DIV">
                    <span v-if="item.type=='number'">{{rounding(scope.row,item.prop,item.decimalPlace)}}</span>
                    <el-button v-else-if="item.type=='tableBtn' && groupDetail.status == 3"
                               v-for="(info,indexs) in item.tableBtnGroup"
                               :class="info.className(scope.row[info.Stutas.split(',')[0]],scope.row[info.Stutas.split(',')[1]])"
                               class="tableBtn"
                               :key="indexs"
                               size="mini"
                               @click.stop="tableBtnClick(scope.row,info)">{{info.name}}</el-button>
                    <span v-else-if="item.type=='html'"
                          :class="groupDetail.status != 3?'min_height':'min_height'"
                          v-html="scope.row[item.prop]">{{scope.row[item.prop]}}</span>
                    <span v-else
                          :class="groupDetail.status != 3?'min_height':'min_height'">{{scope.row[item.prop]}}</span>
                  </div>
                </template>
              </el-table-column>
              <div slot="empty"
                   style="height:440px;line-height:440px;">
                <i class="el-icon-warning-outline">
                  <div class="monitoring_content_nodata"></div>
                  亲，当前没有任何数据哦~
                </i>
              </div>
            </el-table>
            <!-- 分页 -->
            <div class="table_pagination_right table_pagination">
              <el-pagination @current-change="handleCurrentChange"
                             :page-size="10"
                             :current-page.sync="vPageIndex"
                             background
                             layout="prev, pager, next, jumper"
                             :total="totalNum"></el-pagination>
            </div>
          </div>
        </div>
      </custom-dialog>
    </div>
  </div>
</template>

<script>
import { GetGroupDetail, MessageSearchPageForReword, ParentsPromoteDistributionIncentives, FinishedGroup } from '../../../API/workbench.js';
// import layer from '../../../public/js/layer';
export default {
  data () {
    return {
      groupDetail: {},
      couponId: null,
      queryParams: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: 'add_time',
        sequence: '',
        searchText: ''
      },
      tableColumns: [// 列
        {
          label: "序号",
          prop: "Number",
          width: 68,
          sortable: false,
          align: "left",
          isShow: true,
          type: "table-index",
          className: "table_index_gray",
        },
        { label: '报名时间', prop: 'user_youhui_add_time', width: 157, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '学生名', prop: 'StudentKeyValue', width: 113, sortable: false, align: 'left', isShow: true, type: '', decimalPlace: 0, icon: '' },
        { label: '报名人', prop: 'username', width: 165, sortable: false, align: 'left', isShow: true, type: '', decimalPlace: 0, icon: '' },
        { label: '手机号', prop: 'telephone', width: 138, sortable: false, align: 'left', isShow: true, type: '', decimalPlace: 0, icon: '' },
        { label: '身份', prop: 'group_identity', width: 138, sortable: false, align: 'left', isShow: true, type: 'html', decimalPlace: 0 },
        { label: '奖励', prop: 'reward', width: 138, sortable: false, align: 'left', isShow: true, type: 'html', decimalPlace: 0 },
        { label: '兑现状态', prop: 'Stutas', width: 138, sortable: false, align: 'left', isShow: true, type: 'html', decimalPlace: 0 },
        {
          label: '操作',
          prop: '',
          width: '',
          align: 'center',
          sortable: false, // width: 280, align: 'center',
          tableBtnGroup: [
            {
              name: '发放现金',
              Stutas: 'fanxian_amount,is_fafang_fanxian',
              className: (value1, value2, value3) => {
                let className = '';
                if (value1 > 0) { // 返现金额》0
                  if (value2 != 1) {
                    className = 'btn_light_blue';
                  } else {
                    className = 'btn_light_gray';
                  }
                } else {
                  className = 'btn_light_hide';
                }
                return className;
              },
              roleName: ''
            },
            {
              name: '发放课时',
              Stutas: 'fanhour_num,is_fafang_fanhour',
              className: (value1, value2) => {
                let className = '';
                if (value1 > 0) { // 返现金额》0
                  if (value2 != 1) {
                    className = 'btn_light_blue';
                  } else {
                    className = 'btn_light_gray';
                  }
                } else {
                  className = 'btn_light_hide';
                }
                return className;
              },
              roleName: ''
            },
            {
              name: '发放礼品',
              Stutas: 'fangift_name,is_fafang_fangift',
              className: (value1, value2) => {
                let className = '';
                if (value1 > 0) { // 返现金额》0
                  if (value2 != 1) {
                    className = 'btn_light_blue';
                  } else {
                    className = 'btn_light_gray';
                  }
                } else {
                  className = 'btn_light_hide';
                }
                return className;
              },
              roleName: ''
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        }
      ],
      tableData: [], // 列表数据
      isShowListDialog: false, // 是否显示主界面
      selectedRow: {}, // 选中行
      functionBtnGroup: [], // 功能按钮组
      totalNum: 0, // 列表总共条数
      PageCount: 0, // 共几页
      rowKey: '' // 显示详情对应绑定的ID
    };
  },
  props: ['groupKey'],
  computed: {
    vStatusValue () {
      if (this.groupDetail.status == 1) {
        return '进行中';
      } else {
        return '已结束'
      }
    },
    vPageIndex: {
      get () {
        return this.queryParams.pageIndex + 1;
      },
      set (n, o) {
      }
    }
  },
  created () {
  },
  methods: {
    // 获取拼团信息详情
    loadGroupBuyingDetail (item) {
      if (!this.couponId) {
        this.couponId = item.id;
      }
      GetGroupDetail(this.couponId).then(res => {
        this.queryParams.id = this.couponId;
        this.groupDetail = res.data;
        this.groupDetail.create_time = this.groupDetail.create_time.split(' ')[0];
        this.groupDetail.status_value = this.groupDetail.status == 3 ? '拼团结束' : this.groupDetail.status == 2 ? '拼团成功' : '进行中';

        this.groupDetail.target_type = this.groupDetail.target_type == 1 ? '用券' : '售券';
        if (this.groupDetail.groupLeaderKey != 0) {
          this.groupDetail.get_target_rate = this.groupDetail.target_num;
        } else {
          this.groupDetail.groupLeaderKeyValue = '虚拟';
          this.groupDetail.get_target_rate = Number(this.groupDetail.target_num) - Number(this.groupDetail.virtual_num);
        }
        switch (String(this.groupDetail.get_result_type)) {
          case '1':
            this.groupDetail.result_type_value = '一阶团';
            break;
          case '2':
            this.groupDetail.result_type_value = '二阶团';
            break;
          case '3':
            this.groupDetail.result_type_value = '三阶团';
            break;
          default:
            this.groupDetail.result_type_value = '未达标';
            break;
        }
        this.loadGroupBuyingDetailList(this.queryParams);
      }, err => {
        layer.alert(error.data.msg);
      });
    },
    // 获取拼团奖励列表
    loadGroupBuyingDetailList () {
      MessageSearchPageForReword(this.queryParams).then(res => {
        this.tableData = [];
        this.totalNum = 0;
        this.PageCount = 0;
        if (res.data.PageDataList.length > 0) {
          res.data.PageDataList.forEach((obj, index) => {
            if (obj.add_time) {
              obj.add_time = obj.add_time.substring(0, obj.add_time.length - 3);
            }
            if (this.groupDetail.groupLeaderKey == obj.uid) {
              obj.group_identity = '<div class="group_leader" style="line-height: 30px;">团长</div>';
            } else {
              obj.group_identity = '团员';
            }
            if (this.groupDetail.status == 3) {
              if (obj.fanxian_amount > 0) {
                obj.reward = '<div>' + obj.fanxian_amount + '元</div>';
                if (obj.is_fafang_fanxian == 1) {
                  obj.Stutas = '<div class="padding_L reach">已兑现</div>';
                } else {
                  obj.Stutas = '<div class="padding_L">未兑现</div>';
                }
              }
              if (obj.fanhour_num > 0) {
                obj.reward = obj.reward ? obj.reward + '<div >' + obj.fanhour_num + '课时</div>' : '<div>' + obj.fanhour_num + '课时</div>';
                if (obj.is_fafang_fanhour == 1) {
                  obj.Stutas = '<div class="padding_L reach">已兑现</div>';
                } else {
                  obj.Stutas = '<div class="padding_L">未兑现</div>';
                }
              }
              if (obj.fangift_name) {
                obj.reward = obj.reward ? obj.reward + '<div>' + obj.fangift_name + '</div>' : '<div>' + obj.fangift_name + '</div>';
                if (obj.is_fafang_fangift == 1) {
                  obj.Stutas = '<div class="padding_L reach ">已兑现</div>';
                } else {
                  obj.Stutas = '<div class="padding_L">未兑现</div>';

                }
              }
              if (!obj.fanxian_amoun && !obj.fanhour_num && !obj.fangift_name) {
                obj.reward = '<div style="line-height:30px;">无</div>';
                obj.Stutas = '<div style="line-height:30px;">未发放</div>';
              }
            } else {
              obj.reward = '<div style="line-height:30px;">未结算</div>';
              obj.Stutas = '<div style="line-height:30px;">未发放</div>';
            }
            obj.telephone = this.$utils.phoneModulePower(obj.telephone);
            this.tableData.push(obj);
          });
          this.totalNum = res.data.Total;
          this.PageCount = res.data.PageCount;
        } else {
          this.totalNum = 0;
        }
      }, err => {
        layer.alert(error.data.msg);
      });
    },
    // 结算奖励
    clearExtendSearchParams () {
      layer.confirm('是否要手动结算团购奖励？',
        {
          btn: [{
            name: '取消',
            callBack: function () {
              layer.close();
            }
          }, {
            name: '确认',
            callBack: e => {
              FinishedGroup(this.groupDetail.id).then(res => {
                if (res.code == 0) {
                  this.loadGroupBuyingDetail();
                  this.$emit('refreshGroupList');
                  layer.alert('结算成功');
                }
              }, err => {
                console.log(err);
                layer.alert(err.msg);
              });
            }
          }]
        }
      );
    },
    fanxianTypeFont (type) {
      let text;
      switch (String(type)) {
        case '1':
          text = '线下';
          break;
        case '2':
          text = '自动红包';
          break;
        case '3':
          text = '手动红包';
          break;
        default:
          text = '';
          break;
      }
      return text;
    },
    // 奖励发放
    parentsPromoteDistributionIncentives (rowData, type) {
      ParentsPromoteDistributionIncentives(rowData.id, type).then(res => {
        if (res.code == 0) {
          this.loadGroupBuyingDetail();
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 跳转到第几页
    handleCurrentChange (val) {
      this.selectedRow = {};
      this.$refs.multipleTable.setCurrentRow();
      this.queryParams.pageIndex = val - 1;
      this.loadGroupBuyingDetail();
    },
    // 保留小数位数
    rounding (row, value, decimalPlace) {
      return Number(row[value]).toFixed(decimalPlace);
    },
    // 排序
    sortChange (column, prop) {
      if (column.prop) {
        this.queryParams.orderBy = column.prop;
        this.queryParams.sequence =
          column.order == 'ascending' ? 'asc' : 'desc';
      }
      this.loadGroupBuyingDetail();
    },
    // 关闭窗口
    closeDialog () {
      this.isShowListDialog = false;
      this.queryParams.pageIndex = 0;
      this.queryParams.id = null;
      this.couponId = null;
      this.tableData = [];
    },
    // 操作点击表单按钮(第一个参数为券对象，第二个参数为按钮对象)
    tableBtnClick (rowData, eventName) {
      let type = 0;
      let canDo = true;
      switch (eventName.name) {
        case '发放现金':
          canDo = !rowData.is_fafang_fanxian == 1;
          type = 1;
          break;
        case '发放课时':
          canDo = !rowData.is_fafang_fanhour == 1;
          type = 2;
          break;
        case '发放礼品':
          canDo = !rowData.is_fafang_fangift == 1;
          type = 3;
          break;
        default:
          break;
      }
      console.log(rowData, 'rowData');
      // 问题/
      if (canDo) {
        this.parentsPromoteDistributionIncentives(rowData, type);
      }
    },
    // 选中行
    clickRow (row, column, event) {
      this.selectedRow = row;
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row, true);
    }
  }
};
</script>
<style scoped>
.tableBtn {
  padding: 0;
}
.template_DIV {
  height: 32px !important;

  line-height: 30px !important;
}
</style>