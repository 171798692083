<template>
  <div class="mendian_backstage_search">
    <div class="search_box"
         :style="{width:width}">
      <div class="search_box_content">
        <div class="el-input el-input--small el-input--suffix is-focus">
          <input type="text"
                 v-model="searchText"
                 @input="inputEvent($event)"
                 @keydown="keydownEvent($event)"
                 autocomplete="off"
                 :placeholder="placeholder"
                 class="el-input__inner">
          <span class="remove_btn"
                v-show="searchText != ''"
                @click.stop="clearSearText"></span>
        </div>
      </div>
    </div>
    <div class="btn_hover_bg_blue search_btn "
         @click.stop="searchEvent"
         v-if="showSearchBtn">搜索</div>
    <div class="btn_hover_white search_clear"
         @click.stop="clearSearchCondition"
         v-if="clearAll">
      清除条件</div>
  </div>
</template>

<script>
export default {
  props: {
    value: String, // 搜索文本
    placeholder: {// 水印提示
      type: String,
      default: ''
    },
    width: { // 搜索框的宽度
      type: String,
      default: ''
    },
    clearAll: {       // 是否清除所有条件. 若为True,  对外开放事件this.$emit('onSearchEvent');，若为false 类似清除文本
      type: Boolean,
      default: true
    },
    showSearchBtn: {   // 是否显示点击按钮. 若为True,  对外开放事件this.$emit('onSearchEvent');，若为false 类似清除文本
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      searchText: this.value// 搜索文本
    };
  },
  watch: {
    value (c, o) {
      this.searchText = c;
    }
  },
  methods: {
    // 回车
    keydownEvent (e) {
      if (e.keyCode == 13) {
        this.searchEvent();// 搜索函数
      }
    },
    inputEvent (e) {
      this.$emit('input', e.target.value);
    },
    // 搜索
    searchEvent () {
      this.searchText = this.searchText.replace(/(^\s*)|(\s*$)/g, '');
      console.log('searchEvent', this.searchText);
      this.$emit('input', this.searchText);
      this.$emit('onSearchEvent');
    },
    // 清除搜索文本
    clearSearText () {
      this.searchText = '';
      this.$emit('input', this.searchText);
      this.$emit('onSearchEvent');
    },
    // 清除搜索条件
    clearSearchCondition () {
      // console.log("清除搜索条件")
      this.searchText = '';
      this.$emit('input', this.searchText);
      this.$emit('clearSearch');
    }
  }
};
</script>

<style scoped>
.btn_hover_bg_blue {
  line-height: 34px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
.btn_hover_white {
  text-align: center;
  line-height: 34px;
  font-size: 14px;
  border: 1px solid #ececec;
  border-radius: 4px;
  min-width: 76px;
  padding: 0 5px;
  cursor: pointer;
  margin-right: -5px;
  height: 36px;
}
</style>