<template>
  <div class="margin_left_right_div">
    <div class="credit_management_view massive_bg template_minOrmax_width_div"
         style="width: auto;min-width: 0;">
      <div class="view_title_flex">
        <div class="summarizing_number_left">
          <span>学分管理</span>
        </div>
        <div class="text_right">
          <span class="button"
                @click="showCreditRuleFormDialog">学分规则</span>
          丨
          <span class="button"
                @click="showGiftManagementDialog">兑换物品</span>
        </div>
      </div>
      <div class="view_title_flex">
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入姓名、姓名首字母、手机号'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <div class="">
          <div @click="showConversionRecordDialog"
               class="all_student_right_top_btn btn_hover_bg_blue">
            兑换记录
          </div>
          <div @click="showCreditDetailDialog"
               class="all_student_right_top_btn btn_hover_bg_blue">
            学分明细
          </div>
        </div>
      </div>
      <div class="credit_management_table">
        <table-list class="summarizing_list table_list_content"
                    :tableData="vdataReportList"
                    :tableColumns="tableCourseColumns"
                    :totalNum="totalNum"
                    :queryParams="searchObj"
                    :footerContent="vFooterTotal"
                    @loadTableData="getDataList"></table-list>
      </div>
      <custom-dialog width="1200px"
                     :title="'兑换记录'"
                     :visible.sync="isShowConversionRecordDialog"
                     :before-close="cancelConversionRecordDialog">
        <conversion-record></conversion-record>
      </custom-dialog>
      <custom-dialog width="1200px"
                     :title="'学分明细'"
                     :visible.sync="isShowCreditDetailDialog"
                     :before-close="cancelCreditDetailDialog">
        <credit-detail :selectStudent="selectStudentInfo"></credit-detail>
      </custom-dialog>
      <custom-dialog width="740px"
                     :title="'学分规则'"
                     :visible.sync="isShowCreditRuleFormDialog"
                     :before-close="cancelCreditRuleFormDialog">
        <credit-rule-form @closePopup="cancelCreditRuleFormDialog"></credit-rule-form>
      </custom-dialog>
      <custom-dialog width="972px"
                     :title="'兑换物品'"
                     :visible.sync="isShowGiftManagementDialog"
                     :before-close="cancelGiftManagementDialog">
        <gift-management ref="giftManagement"
                         @showAddGiftInfoDialog="showAddGiftInfoDialog"></gift-management>
      </custom-dialog>
      <custom-dialog width="436px"
                     :title="addGiftTitle"
                     :visible.sync="isShowAddGiftInfoDialog"
                     :before-close="cancelAddGiftInfoDialog">
        <add-gift-form :dataInfo="selectGiftInfo"
                       @cancelAddGiftInfoDialog="cancelAddGiftInfoDialog"></add-gift-form>
      </custom-dialog>
      <custom-dialog width="436px"
                     :title="'兑换礼品'"
                     :visible.sync="isShowGiftExchangeDialog"
                     :before-close="cancelGiftExchangeDialog">
        <gift-exchange :studentInfo="selectStudentInfo"
                       @cancelPopup="cancelGiftExchangeDialog"></gift-exchange>
      </custom-dialog>
      <custom-dialog title="调整学分"
                     :visible.sync="isShowInventoryAdjustForm"
                     :before-close="closeDialog">
        <credit-adjust-form :dataInfo="selectGiftInfo"
                            @cancelClick="closeDialog"
                            @afterSuccessCallBack="searchCreditData"></credit-adjust-form>
      </custom-dialog>
    </div>
    <dialog-factory ref="creditManagementDialogFactory"
                    :key="'creditManagement'"
                    :routerName="'creditManagement'"></dialog-factory>
  </div>
</template>
<script>
import { MessageSearchPageForStudentCredit } from '../../../API/workbench';
import inputSearchContainBtn from '../common/input-search-contain-btn';
import conversionRecord from './table-list/conversion-record';
import creditDetail from './table-list/credit-detail';
import creditRuleForm from './credit-rule-form';
import giftManagement from './gift-management/index';
import addGiftForm from './gift-management/add-gift-form';
import giftExchange from './gift-exchange';
import creditAdjustForm from './credit-adjust-form';
import tableList from '../../common/table-list/index';
import dialogFactory from '../../common/dialog-factory';
export default {
  name: 'creditManagement',
  data () {
    return {
      isShowConversionRecordDialog: false,
      isShowCreditRuleFormDialog: false,
      isShowCreditDetailDialog: false,
      isShowGiftManagementDialog: false,
      isShowAddGiftInfoDialog: false,
      isShowGiftExchangeDialog: false,
      isShowInventoryAdjustForm: false,
      dataReportList: [],
      selectStudentInfo: {},
      selectGiftInfo: {},
      addGiftTitle: '添加物品',
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: '',
        endTime: ''
      },
      totalNum: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '学生名',
          prop: 'MainDemoName',
          minWidth: 138,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '手机号',
          prop: 'CustomerPhoneName',
          minWidth: 145,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '奖励学分',
          prop: 'TotalCredit',
          minWidth: 135,
          sortable: false,
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '抵用学分',
          prop: 'UseCredit',
          width: ' ',
          minWidth: 135,
          sortable: false,
          type: 'textItem'
        },
        {
          label: '当前学分',
          prop: 'LearnCurrencyCount',
          minWidth: 135,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '兑换次数',
          prop: 'ExchangeCount',
          minWidth: 135,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '最近兑换',
          prop: 'RecentTime',
          minWidth: 150,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '操作',
          prop: '',
          width: 196,
          sortable: false,
          align: 'center',
          type: 'tableBtn',
          extend: {
            tableBtnGroup: [
              {
                name: '明细',
                extend: {
                  isHide: false,
                  className: 'table_btn_margin',
                  click: (rowData) => {
                    this.selectStudentItme(rowData);
                  }
                }
              },
              {
                name: '兑换',
                extend: {
                  isHide: false,
                  className: 'table_btn_margin',
                  click: (rowData) => {
                    this.showGiftExchangeDialog(rowData);
                  }
                }
              },
              {
                name: '调整',
                extend: {
                  isHide: false,
                  className: 'table_btn_margin',
                  click: (rowData) => {
                    this.doAfterTableBtnClick(rowData);
                  }
                }
              }
            ]
          }
        }
      ]
    };
  },
  components: {
    inputSearchContainBtn,
    tableList,
    conversionRecord,
    creditRuleForm,
    creditDetail,
    giftManagement,
    addGiftForm,
    giftExchange,
    dialogFactory,
    creditAdjustForm
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        o.LearnCurrencyCount = Number(o.LearnCurrencyCount);
        o.CustomerPhoneName = this.$utils.phoneModulePower(o.CustomerPhoneName) || '--';
        if (o.UseCredit == 0) {
          o.UseCredit = '-';
        }
        if (o.ExchangeCount == 0) {
          o.ExchangeCount = '-';
        }
        o.RecentTime = o.RecentTime || '-';
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    }
  },
  created () {
    this.getDataList();
    this.resgisterBusEvent();
  },
  methods: {
    cancelAddGiftInfoDialog () {
      this.$refs.giftManagement.getGiftListInfo();
      this.isShowAddGiftInfoDialog = false;
    },
    showAddGiftInfoDialog (item) {
      if (item) {
        this.selectGiftInfo = item;
        this.addGiftTitle = '修改物品';
      } else {
        this.selectGiftInfo = {};
        this.addGiftTitle = '添加物品';
      }
      this.isShowAddGiftInfoDialog = true;
    },
    cancelGiftExchangeDialog () {
      this.getDataList();
      this.isShowGiftExchangeDialog = false;
    },
    // 兑换
    showGiftExchangeDialog (item) {
      if (item) {
        this.selectStudentInfo = item;
      } else {
        this.selectStudentInfo = {};
      }
      this.isShowGiftExchangeDialog = true;
    },
    // 调整
    doAfterTableBtnClick (rowData) {
      console.log(rowData, '按钮点击');
      this.selectGiftInfo = rowData;
      this.isShowInventoryAdjustForm = true;
    },
    searchCreditData () {
      this.search();
    },
    closeDialog () {
      this.isShowInventoryAdjustForm = false;
    },
    // --------------
    cancelGiftManagementDialog () {
      this.isShowGiftManagementDialog = false;
    },
    showGiftManagementDialog () {
      this.isShowGiftManagementDialog = true;
    },
    cancelCreditDetailDialog () {
      this.isShowCreditDetailDialog = false;
    },
    showCreditDetailDialog () {
      this.selectStudentInfo = {};
      this.isShowCreditDetailDialog = true;
    },
    cancelCreditRuleFormDialog () {
      this.isShowCreditRuleFormDialog = false;
    },
    showCreditRuleFormDialog () {
      this.isShowCreditRuleFormDialog = true;
    },
    cancelConversionRecordDialog () {
      this.isShowConversionRecordDialog = false;
    },
    showConversionRecordDialog () {
      this.isShowConversionRecordDialog = true;
    },
    selectStudentItme (item) {
      if (item) {
        this.selectStudentInfo = item;
        this.isShowCreditDetailDialog = true;
      } else {
        this.showCreditDetailDialog();
      }
    },
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    getDataList () {
      MessageSearchPageForStudentCredit(this.searchObj).then(
        (res) => {
          this.totalNum = res.data.Total;
          this.dataReportList = res.data.PageDataList;
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.creditManagementDialogFactory) {
        this.$refs.creditManagementDialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.creditManagementDialogFactoryCommon) {
        console.log('creditManagementDialogFactoryCommon');
        this.$refs.creditManagementDialogFactoryCommon.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name
        );
      }
    },
    resgisterBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'creditManagement') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'creditManagement') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    }
  }
};
</script>