<template>
  <div class="backlog_list border_bottom"
       :class="vIconClass"
       @click.stop="seletedItem"
       :title="item.TypeKeyValue+'：'+item.Title">
    <div class="backlog_list_name">{{item.TypeKeyValue}}：{{item.Title}}</div>
    <div class="backlog_list_text" v-if="item.GenerateTime">{{$utils.StrForTodoTime(item.GenerateTime)}}</div>
    <div class="list_btn"></div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object,
    keyIndex: Number
  },
  computed: {
    	// 1-授权申请审核；2-课件过期; 蓝色 黄色
    vIconClass () {
      if (this.item.TypeKey == 1) {
        return 'icon_blue';
      } else {
        return 'icon_yellow';
      }
    }
  },
  methods: {
    seletedItem () {
      this.$emit('seletedItem', this.item, this.keyIndex);
    }
  }
};
</script>

<style scoped>
.backlog_list.border_bottom {
  border-bottom: 1px solid rgba(245, 248, 255, 1);
  padding-bottom: 5px;
}
.backlog_list {
  position: relative;
  padding-left: 21px;
  padding-right: 30px;
  margin-bottom: 16px;
}

.backlog_list.icon_blue::after {
  content: '';
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  left: 0;
  top: 6px;
  background-color: #4581F9;
}
.backlog_list.icon_yellow::after {
  content: '';
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  left: 0;
  top: 6px;
  background-color: #FDB078;
}
</style>
