<template>
  <div class="label_bottom_content_material">
    <courseware-attach-item v-for="(item,key) in dataList"
                            :key="key"
                            :item="item"
                            @clickEvent="clickEvent"></courseware-attach-item> 
  </div>
</template>
<script>
import coursewareAttachItem from './courseware-attach-item';
export default {
  data () {
    return {

    };
  },
  props: {
    dataList: Array
  },
  components: {
    coursewareAttachItem
  },
  methods:{
    clickEvent(item){
      this.$emit('clickEvent',item);
    }
  }
};
</script>

