<template>
  <!-- 部门职务-设置 -->
  <div class="">
    <div class="course_setting_view">
      <div class="course_setting_box">
        <!-- 左侧类型列表 -->
        <div class="setting_view_left cp">
          <view-left-box :dataList="dataTypeList"
                         :addDataName="'新增部门'"
                         @doTypeSearchClick="doTypeSearchClick"
                         @doAddTypeClick="doAddTypeClick"></view-left-box>
        </div>
        <!-- 右侧名称列表 -->
        <div class="setting_view_right cp">
          <view-right-box :dataList="dataNameList"
                          :dataTypeInfo="dataDepartmentInfo"
                          :addDataName="'新增职务'"
                          @doNameItemClick="doNameItemClick"
                          @doTypeItemClick="doeditClick"
                          @doAddNameClick="doAddNameClick"></view-right-box>
        </div>
      </div>
    </div>
    <!-- 新增/修改部门-弹出层 -->
    <custom-dialog :title="operationInfo.name"
                   class="incomepay_dialog defult_dialog"
                   :visible.sync="isShowDepartmentPopup"
                   :before-close="cancelBtnClick">
      <departmente-form @afterSuccess="departmentAfterSuccess"
                        @cancelClick="cancelBtnClick"
                        :dataInfo='dataDepartmentInfo'
                        :isReadonly="(dataDepartmentInfo.type=='view')"></departmente-form>
    </custom-dialog>

    <!-- 新增/修改职务-弹出层 -->
    <custom-dialog :title="operationInfo.name"
                   class="incomepay_dialog defult_dialog"
                   :visible.sync="isShowPostPopup"
                   :before-close="cancelBtnClick">
      <post-form @afterSuccess="postAfterSuccess"
                 @cancelClick="cancelBtnClick"
                 :dataInfo="dataPostInfo"
                 :isReadonly="(dataPostInfo.type=='view')"></post-form>
    </custom-dialog>

    <!-- 操作菜单 -->
    <!-- <bottom-popup-operate-form :formTitle="operationInfo.name"
                               v-if="isShowEditPopup"
                               @cancelClick="cancelBtnClick">
      <basics-popup-list :dataList="projectTypes"
                         @seletedItem="doOperationItem"></basics-popup-list>
    </bottom-popup-operate-form> -->

    <div class="course_student_attendance_operation module_content_menuList"
         :style="{'top':topValue+'px','left':'270px'}"
         v-if="isShowEditPopup">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="projectTypes"
                     @clickOptionForAlertDialog="doOperationItem"></drop-down-box>
    </div>
  </div>
</template>

<script>
import viewLeftBox from './view-left-box/index';
import viewRightBox from './view-right-box/index';
import departmenteForm from './departmente-form';
import postForm from './post-form';
import bottomPopupOperateForm from '../common/bottom-popup-operate-form';
import basicsPopupList from '../common/basics-popup-list';
import dropDownBox from '../../../common/drop-down-box';
import {
  hqMessageSearchPageForDepartment,
  hqMessageSearchPageForStation,
  hqStopDepartment,
  hqStopStation
} from '../../../../API/workbench.js';
export default {
  components: {
    viewLeftBox,
    viewRightBox,
    departmenteForm,
    postForm,
    dropDownBox,
    bottomPopupOperateForm,
    basicsPopupList
  },
  data () {
    return {
      topValue: 85,
      dataList: [], // 数据源
      dataTypeList: [], // 类型列表
      dataNameList: [], // 名称列表，
      dataDepartmentInfo: {}, // 类型信息
      dataPostInfo: {}, // 名称信息
      operationInfo: {
        name: '',
        sum: '',
        type: ''
      },
      params: {
        pageIndex: 0, // 每页记录数，0：表示查询所有
        pageSize: 0, // 页码，0为第一页
        searchText: '', //	搜索条件：模糊匹配姓名、昵称、学号、客户手机号、客户名 课
        orderBy: '', // 排序字段
        sequence: '' // 排序字段
      },
      isShowDepartmentPopup: false,
      isShowEditPopup: false,
      isShowPostPopup: false,
      projectTypes: [ // 项目类型
        { type: 0, name: '查看', eventName: 'view', isShow: true, className: 'list_icon08' },
        { type: 1, name: '修改', eventName: 'edit', isShow: true, className: 'list_icon09' },
        { type: 2, name: '停用', eventName: 'disable', isShow: true, className: 'list_icon10' }
      ],
      // 查询职务
      parameter: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号、首字母
        orderBy: '', //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为姓名
        sequence: 'DESC', //	字符串	可选	ASC	数据源：GET	为空则默认顺序; ASC正序;DESC倒序
        IsLeaveKey: '', //	字符串	可选		数据源：GET	是否在职;空表示该条件不生效;0-离职;1-在职
        StateKey: 0
      }
    };
  },
  created () {
    this.isShowEditPopup = false;
    this.getMessageSearchPageForDepartment();
    this.getMessageSearchPageForEmployee();
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isShowEditPopup = false;
    }, false);
  },
  computed: {
    vSum () {
      if (this.operationInfo.type == 'department') {
        return this.dataNameList.length + '个职务';
      } else {
        return '';
      }
    }
  },
  methods: {
    // 返回该门店所有部门
    getMessageSearchPageForDepartment () {
      hqMessageSearchPageForDepartment(this.params).then(res => {
        console.log(res.data.PageDataList, '所有部门');
        this.dataTypeList = res.data.PageDataList.filter(o => {
          this.$set(o, 'isOpt', false);
          return Number(o.StatusKey) === 1;
        });
        this.dataTypeList.splice(0, 0, {
          OLAPKey: '2',
          isOpt: true,
          MainDemoName: '全部职务'
        });
        this.dataDepartmentInfo = {
          OLAPKey: this.dataTypeList[0].OLAPKey,
          MainDemoName: this.dataTypeList[0].MainDemoName,
          dataNameSum: this.dataList.length + '个职务'
        };
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 返回该门店所有岗位
    getMessageSearchPageForEmployee (departmentInfo) {
      hqMessageSearchPageForStation(this.parameter).then(result => {
        this.dataList = result.data.PageDataList.filter(o => {
          return Number(o.StatusKey) === 1;
        });
        this.dataNameList = this.dataList;
        console.log(this.dataList, '岗位');
        this.dataDepartmentInfo.dataNameSum = this.dataList.length + '个职务'; // 全部：2 ；默认：0
        // 刷新后筛选
        if (departmentInfo && departmentInfo.OLAPKey != 2) {
          this.getClassifyData(departmentInfo);
        }
      }, error => {
        layer.alert(error.msg);
      });
    },

    // 点击部门筛选
    doTypeSearchClick (item) {
      console.log(item, '点击部门筛选');
      // 保证数据是最新的
      this.dataDepartmentInfo = item;
      this.dataDepartmentInfo.dataNameSum = this.dataNameList.length + '个职务';
      this.getMessageSearchPageForEmployee(item);
    },
    // 筛选部门下的职务
    getClassifyData (departmentInfo) {
      const arr = this.dataList.filter(o => {
        return departmentInfo.OLAPKey == o.FatherKey;
      });
      this.dataNameList = arr;
      this.dataDepartmentInfo.dataNameSum = this.dataNameList.length + '个职务';
    },
    // 新增部门
    doAddTypeClick () {
      this.dataDepartmentInfo.OLAPKey = '';
      this.dataDepartmentInfo.type = 'add';
      this.operationInfo.name = '新增部门';
      this.operationInfo.type = 'department';
      this.isShowDepartmentPopup = true;
    },
    // 修改部门
    doeditClick (item) {
      this.dataDepartmentInfo = item;
      this.operationInfo.type = 'department';
      this.operationInfo.name = item.MainDemoName;
      this.topValue = 85;
      this.isShowEditPopup = true;
      console.log(item, '修改部门');
    },
    // 新增职务
    doAddNameClick () {
      this.dataPostInfo.OLAPKey = '';
      this.dataPostInfo.type = 'add';
      // 新增职务时，有部门默认填充部门项
      this.dataPostInfo.FatherKey = (this.dataDepartmentInfo.OLAPKey > 2 ? this.dataDepartmentInfo.OLAPKey : '');
      this.dataPostInfo.FatherKeyValue = (this.dataDepartmentInfo.OLAPKey > 2 ? this.dataDepartmentInfo.MainDemoName : '');
      this.operationInfo.name = '新增职务';
      this.operationInfo.type = 'post';
      this.isShowPostPopup = true;
      console.log(this.dataPostInfo, '添加职务');
    },
    // 修改职务
    doNameItemClick (item, index) {
      this.topValue = 85;
      this.topValue += (index + 1) * 50 + index;
      this.dataPostInfo = item;
      this.operationInfo.type = 'post';
      this.operationInfo.name = item.MainDemoName;
      this.isShowEditPopup = true;
      console.log(item, '修改职务');
    },
    // 取消弹出层显示
    cancelBtnClick () {
      this.isShowDepartmentPopup = false;
      this.isShowEditPopup = false;
      this.isShowPostPopup = false;
    },
    // 操作项
    doOperationItem (item) {
      if (item.eventName === 'disable') {
        this.operateDisable(this.operationInfo.type);
        return;
      } else if (this.operationInfo.type == 'department') {
        this.dataDepartmentInfo.type = item.eventName;
        this.isShowEditPopup = false;
        this.isShowDepartmentPopup = true;
      } else if (this.operationInfo.type == 'post') {
        this.dataPostInfo.type = item.eventName;
        this.isShowEditPopup = false;
        this.isShowPostPopup = true;
      }
      const name = (this.operationInfo.type == 'department' ? '部门' : '职务');
      const value = item.name ? item.name : '新增';
      this.operationInfo.name = value + name;
      console.log(item, '修改项');
    },
    // 停用操作
    operateDisable (type) {
      if (type === 'department') {
        layer.confirm('确定是否要停用该部门?', {
          btn: [{
            name: '确认',
            callBack: () => {
              layer.close();
              hqStopDepartment(this.dataDepartmentInfo.OLAPKey).then(result => {
                this.getMessageSearchPageForDepartment();
                this.getMessageSearchPageForEmployee();
                layer.alert('停用成功');
                this.cancelBtnClick();
              }, error => {
                layer.alert(error.msg);
              });
            }
          }
          ]
        });
      } else {
        layer.confirm('确定是否停用该职务?', {
          btn: [{
            name: '确认',
            callBack: () => {
              layer.close();
              hqStopStation(this.dataPostInfo.OLAPKey).then(result => {
                const findIndex = this.dataNameList.findIndex(o => {
                  return o.OLAPKey == this.dataPostInfo.OLAPKey;
                });
                this.dataNameList.splice(findIndex, 1);
                layer.alert('停用成功');
                this.dataDepartmentInfo.dataNameSum = this.dataNameList.length + '个职务';
                this.cancelBtnClick();
              }, error => {
                layer.alert(error.msg);
              });
            }
          }
          ]
        });
      }
    },
    // 部门成功回调
    departmentAfterSuccess (type, data) {
      console.log(type, data, '成功回调');
      this.dataTypeList.forEach(o => {
        o.isOpt = false;
      });
      if (data && data.OLAPKey > 0) {
        this.dataDepartmentInfo = data;
      }
      this.$set(data, 'isOpt', true); // 回调后默认选中该项
      // 新增
      if (type == 'Add') {
        this.dataNameList = [];
        this.dataDepartmentInfo.dataNameSum = '0个职务';
        this.dataTypeList.push(data);
      } else {
        // 修改
        this.dataDepartmentInfo.dataNameSum = this.dataNameList.length + '个职务';
        this.dataTypeList.forEach(o => {
          if (o.OLAPKey == data.OLAPKey) {
            Object.assign(o, data);
          }
        });
      }
      this.cancelBtnClick();
    },
    // 职务成功回调
    postAfterSuccess (type, data) {
      console.log(type, data, '成功回调');
      // 新增
      if (type == 'Add') {
        this.dataList.unshift(data);
      }
      // 修改
      const arr = this.dataList.filter(o => {
        if (o.OLAPKey == data.OLAPKey) {
          Object.assign(o, data);
        }
        return Number(this.dataDepartmentInfo.OLAPKey) == Number(o.FatherKey);
      });
      if (this.dataDepartmentInfo.OLAPKey == 2) { // 全部时显示
        this.dataNameList = this.dataList;
      } else {
        this.dataNameList = arr;
      }
      this.dataDepartmentInfo.dataNameSum = this.dataNameList.length + '个职务';
      this.cancelBtnClick();
    }
  }
};
</script>

<style>
</style>
