<template>
  <div class="recruit_student_activity_content ">
    <div class="course_statistics_table_search flex course_statistics_title"  style="padding: 13px 0px">

      <div class="course_statistics_search">

        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入模板'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <!-- <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;width:76px"
               @click.stop="addActivity">新建模板</div>
        </div> -->
      </div>
    </div>
    <div class="content_ul" style="min-height:500px"
         v-if="vTemplateList.length>0">
      <template-item v-for="(item,key) in vTemplateList"
                     :key="key"
                     :item="item"
                     @seletedItem="seletedTemplateAfter"></template-item>

    </div>
    <div v-if="vTemplateList.length==0"
         style="padding-top:150px;height:500px">
      <div class="course_block_nodata">
        <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
      </div>
    </div>

    <!-- 分页 -->
    <div v-if="searchObj.pageSize > 0"
         class="table_pagination table_pagination_right" style="margin: 20px;">
      <div class="table_left_text">
        共{{total}}条数据
      </div>
      <el-pagination @current-change="handleCurrentChange"
                     :page-size="searchObj.pageSize"
                     :current-page.sync="vPageIndex"
                     background
                     :pager-count="5"
                     layout="prev, pager, next, jumper"
                     :total="total>0?total:searchObj.pageSize"></el-pagination>
      <table-pagination-number :pageSize="searchObj.pageSize"
                               :queryParams="searchObj"
                               @loadTableData="getDataList"></table-pagination-number>
    </div>
    <custom-dialog 
                   :title="'预览'"
                   :visible.sync="isShowViewContent"
                   :before-close="closeEditContent">
      <div style="width:100%;height:680px;background:white;padding:0px 10px;overflow: auto;">
          <iframe id="ifra"
              :src="editContentUrl"
              class="ishowView"
              frameborder="0"></iframe>
      </div>
    
    </custom-dialog>
    <custom-dialog class="editContent_iframe_dialog"
                   :title="'修改'"
                   :visible.sync="isShowEditContent"
                   :before-close="closeEditContent">
      <iframe id="ifra2"
              :src="editContentUrl"
              class="ishowEdit"
              frameborder="0"></iframe>
    </custom-dialog>
    <!-- <custom-dialog :title="'活动预览'"
                   width="1200px"
                   :visible.sync="isShowActivityDetailsPopup"
                   :before-close="hiddenActivityDetailsPopup">
      <activities-preview :dataInfo="selectActivityInfo"
                          @closePopup="hiddenActivityDetailsPopup"></activities-preview>
    </custom-dialog> -->
  </div>
</template>
<script>
import templateItem from './template-item';
import tablePaginationNumber from '../../../common/table-pagination-number'; // table 分页
import inputSearchContainBtn from '../../../report/common/input-search-contain-btn';
import { MessageSearchActivitytemplate,DeleteActivitytemplate } from '../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        PublishStatus:'-1', //-1 全部， 0未发布，1已发布
        startTime: '',
        endTime: '',
        TypeKey:'5' //活动类型 5-优惠券招生;6-引流套餐招生;7-拼团招生;8-阶梯团招生9-员工分销招生10-家长分销招生
      },
      total: 0,
      templateList:[
      ],
      defaultSort: { prop: '', order: 'ascending' },
        isShowEditContent:false,
        isShowViewContent:false,
      editContentUrl:'',
      
    };
  },
  components: {
    inputSearchContainBtn,templateItem,tablePaginationNumber
  },
  props: {
    
  },
  created () {
    console.log(this.toSearchObj, 'this.toSearchObj');
     this.searchObj.TypeKey = this.$route.query.TypeKey || 1;
    this.getDataList()
  },
  watch:{

  },
  computed: {
    vTemplateList () {
      let newArr = [];
      this.templateList.forEach((o, index) => {
        this.$set(o, 'isActive', false);
        if (!this.searchObj.searchText && this.searchObj.pageIndex == 0 && this.searchObj.SceneKey == 0 && this.searchObj.AgenciesKey == 0) {
          if (index < 5) {
            this.$set(o, 'isHot', true);
          }
        } else {
          this.$set(o, 'isHot', false);
        }
        newArr.push(o);
      });
      return newArr;
    },
    vPageIndex: {
      get () {
        return this.searchObj.pageIndex + 1;
      },
      set (n, o) {
      }
    },
  },
  mounted () {
    // this.getDataList();
  },
  methods: {
     // 预览模板
    showPreviewContent(item){
      let SaaSClientKey=item.templateType ==2?this.$store.getters.token.SaaSClientKey:'1'
      console.log("this.$store.getters.wktDomainURL",this.$store.getters.wktDomainURL)
      let wktDomainURL=this.$store.getters.wktDomainURL.replace('http://','https://')
      // /do/shareCouponToGet.php?coupon_id=2738&source_type=4&share_user_id=975&SaaSClientKey=1&preview=1&temp_id=914
      let url='/do/shareCouponToGet.php?coupon_id='+item.activeDataID+'&source_type=4&share_user_id='+this.$store.getters.token.UserID+'&SaaSClientKey='+SaaSClientKey+'&preview=1&temp_id='+item.id;
      this.editContentUrl= wktDomainURL+url
      this.$nextTick(o=>{
        this.isShowViewContent=true
      })
    },
    // 修改模板
    showEditContent(item){
      this.editContentUrl= this.$store.getters.PCWebURL+'/iShowEditor/#/ishow?activityTempId=' + item.id+'&isHiddenModule=1'
      this.$nextTick(o=>{
        this.isShowEditContent=true
      })
    },
    closeEditContent(){
      this.isShowViewContent=false
      this.isShowEditContent=false
      this.getDataList()
    },
    // 选中模板
    seletedTemplateAfter (item,type) {
      this.templateList.forEach(o => {
        if (item.id == o.id) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      console.log(type,'seletedTemplateAfter')
      this.coverInfo = item;
      switch (type) {
        case 'view':    //查看
        console.log(item,'预览')
            this.showPreviewContent(item)
            // this.previewPoster(item)
          // this.newAddmoduleName = 'classroom-setting';
          break;
        case 'edit':  //修改
            console.log(item,'修改')
            this.showEditContent(item)
          // this.newAddmoduleName = 'squad-class-Setting';
          break;
        case 'del':    //删除
          console.log(item,'删除')
          layer.confirm('请问是否删除该模板', {
              btn: [
                {
                  name: '确认',
                  callBack:  ()=> {
                    this.delItem(item);
                    layer.close();
                  }
                }
              ]
            });
          // this.newAddmoduleName = 'squad-class-Setting';
          break;
        default:
          break;
      }
    },
    // 预览
    previewPoster(item){

    },
    // 删除
    delItem(item){
      DeleteActivitytemplate(item.id).then(result=>{
        console.log("DeleteActivitytemplate",result.data)
        this.getDataList()
      }).catch(error=>{
        console.log('DeleteActivitytemplateError',error)
      })
    },
     // 跳转到第几页
    handleCurrentChange (val) {
      this.searchObj.pageIndex = val - 1;
      this.getDataList(this.searchObj);
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 新增模板
    addActivity (callBack) {
      
      console.log('新增模板');
    },
    getDataList (queryParams) {
      let fn = MessageSearchActivitytemplate;
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      fn(this.searchObj).then(result => {
        this.total = result.data.Total;
        this.templateList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },

  }
};
</script>
<style>
.hover {
  cursor: pointer;
}
.editContent_iframe_dialog{
  position: fixed;
  left: 0 !important;
   bottom: 0 !important;
    right: 0 !important;
     top: 0 !important;
     z-index: 9999;
}
.editContent_iframe_dialog .popup_dialog_box{
  position: absolute;
  left: 0 !important;
   bottom: 0 !important;
    right: 0 !important;
     top: 0 !important;
     width: auto !important; 
         transform: translateX(0) !important;

}
.ishowView{
      width: 640px;
    transform-origin: 8% bottom;
    height: 4895px;
    margin-top: -2227.22px;
    transform: scale(0.545);
    border-top-left-radius: 22px!important;
    border-top-right-radius: 22px!important;
}
.ishowEdit{
  position: absolute;
    left: 0;
    right: 0;
    top: 48px;
    bottom: 0;
    width: 100%;
    height: 100%;
      padding-bottom: 50px;
}
</style>