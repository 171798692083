<template>
  <div class="from_list">
    <div class="from_list_title">
      {{title}}
    </div>
    <div class="from_list_content">
      <div class="from_list_content"
           v-show="settingInfo.returnMoney.isOpen">
        <div class="from_list_radio_left">
          <el-radio v-model="backStatus"
                    :label="0"
                    @change="changeStatus">返现</el-radio>
        </div>
        <form-content-input :formTitle="'返'"
                            :unintTitle="'元/人'"
                            v-model="dataInfo.fanxian_amount"
                            :tipsTitle="''"
                            :required="false"
                            :decimalPlaces="2"
                            :readonly="backStatus!=0"
                            :inputClass="'from_list_input_three'"
                            :titleClass="'from_list_input_left'"
                            :unitClass="'from_list_unit_big'"></form-content-input>
        <div class="from_list_radio_right"
             v-if="inShowOnLine">
          <el-radio v-model="dataInfo.fanxian_type"
                    :disabled="backStatus!=0||backStatus==null"
                    :label="2">线上返</el-radio>
        </div>
        <div class="from_list_radio_right"
             v-if="inShowOnLine">
          <el-radio v-model="dataInfo.fanxian_type"
                    :disabled="backStatus!=0||backStatus==null"
                    :label="1">线下返</el-radio>
        </div>
        <div class="font_gray font_icon_yellow"
             v-if="!inShowOnLine">线下返</div>
      </div>
      <div class="from_list_content"
           v-show="settingInfo.returnClassHour.isOpen">
        <div class="from_list_radio_left">
          <el-radio v-model="backStatus"
                    :label="1"
                    @change="changeStatus">赠送课时</el-radio>
        </div>
        <form-content-input :formTitle="'送'"
                            :unintTitle="'课时/人'"
                            v-model="dataInfo.fanhour_num"
                            :tipsTitle="''"
                            :isNumber="true"
                            :required="false"
                            :decimalPlaces="1"
                            :supportInit="true"
                            :readonly="backStatus!=1"
                            :inputClass="'from_list_input_three'"
                            :titleClass="'from_list_input_left'"
                            :unitClass="'from_list_unit_big'"></form-content-input>
      </div>
      <div class="from_list_course_box WKT_discount_info_from"
           v-show="settingInfo.returnClassHour.isOpen">
        <div class="from_list from_list_select el-icon-eleme">
          <div class="from_list_title">
            适用课程
          </div>
          <div class="from_list_content ">
            <div class=" from_list_input"
                 @click.stop="showCourseNameListDialog">
              <input type="text"
                     readonly
                     :class="!dataInfo.fanhour_keyvalues?'font_gray':'font_blue'"
                     placeholder="请选择"
                     v-model="dataInfo.fanhour_keyvalues">
            </div>
          </div>
        </div>
      </div>
      <div class="from_list_content"
           v-show="settingInfo.returnPrize.isOpen">
        <div class="from_list_radio_left">
          <el-radio v-model="backStatus"
                    :label="2"
                    @change="changeStatus">赠送奖品</el-radio>
        </div>
        <form-content-input :formTitle="'送'"
                            :unintTitle="'个/人'"
                            v-model="dataInfo.fangift_name"
                            :tipsTitle="''"
                            :required="false"
                            :readonly="backStatus!=2"
                            :inputClass="'from_list_input_three'"
                            :titleClass="'from_list_input_left'"
                            :unitClass="'from_list_unit_big'"></form-content-input>

      </div>

    </div>
    <!-- 报组合课 -->
    <custom-dialog :title="'选择课程'"
                   :visible.sync="isShowSelectCourseDialog"
                   :before-close="docloseSelection">
      <btn-add-select :addTitleName="'新增'"
                      :addmoduleName="'course-name-setting'"
                      :addmoduleTitleName="'add'"
                      :addmoduleKey='45'
                      @doAddSuccessEvent="addSusscess"></btn-add-select>
      <singleChoiceSelectTree :sourceDate="courseTreeList"
                              :selectionDate="applyCourseDatas"
                              @buttonClickEvent="doAfterSelectionCourseName"
                              @cancleClickForMutilSelected="docloseSelection"></singleChoiceSelectTree>
    </custom-dialog>
  </div>
</template>
<script>
import singleChoiceSelectTree from '../../../../../../common/single-choice-select-tree';
import formContentInput from '../common/form-content-input';
export default {
  data () {
    return {
      backStatus: null,
      isShowSelectCourseDialog: false,
      // 提交的组合课
      applyCourseDatas: {
        IDs: '',
        Names: ''
      }
      // onLineStatus: false   // 线上
    };
  },
  props: {
    title: String,
    settingInfo: Object,      // 玩法设置中是否开启现金,课时,礼品.
    dataInfo: Object,
    courseTreeList: Array
  },
  mounted () {
    this.addInit();
  },
  watch: {
    'dataInfo.pid': {
      handler (c, o) {
        if (c > 0) {
          this.initBackStatus();
        }
      }
    }
  },
  components: {
    formContentInput, singleChoiceSelectTree
  },
  computed: {
    vMenDianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    inShowOnLine () {
      console.log('inShowOnLine', this.vMenDianInfo);
      return this.vMenDianInfo.WktInfo.is_open_redpaper && !this.vMenDianInfo.IsCommonTrusteeshipKey;
      // {{mendianInfo.WktInfo && mendianInfo.WktInfo.is_open_redpaper ?'开启':'关闭'}}
    },
    vCourseNameList () {
      return (this.$store.getters.courseNameList && this.$store.getters.courseNameList.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; })) || [];
    }
  },
  methods: {

    addInit () {
      if (this.settingInfo.returnMoney.isOpen && !this.settingInfo.returnClassHour.isOpen && !this.settingInfo.returnPrize.isOpen) {
        this.changeStatus(0);
      } else if (!this.settingInfo.returnMoney.isOpen && this.settingInfo.returnClassHour.isOpen && !this.settingInfo.returnPrize.isOpen) {
        this.changeStatus(1);
      } else if (!this.settingInfo.returnMoney.isOpen && !this.settingInfo.returnClassHour.isOpen && this.settingInfo.returnPrize.isOpen) {
        this.changeStatus(2);
      }
    },
    initBackStatus () {
      console.log('initBackStatus', this.dataInfo);
      if (this.dataInfo.fanhour_num > 0) {
        this.backStatus = 1;
      } else if (this.dataInfo.fanxian_amount > 0) {
        this.backStatus = 0;
      } else if (this.dataInfo.fangift_name) {
        this.backStatus = 2;
      }
      this.$emit('changebackStatus', this.backStatus);
    },
    changeStatus (val) {
      console.log(val, this.backStatus);
      this.backStatus = val;
      if (val == 0) {     // 返现
        this.dataInfo.fanhour_num = '';
        this.dataInfo.fanhour_keys = '';
        this.dataInfo.fanhour_keyvalues = '';
        this.dataInfo.fangift_name = '';
        this.applyCourseDatas.IDs = '';
        this.applyCourseDatas.Names = '';
        if (this.inShowOnLine) {
          this.dataInfo.fanxian_type = 1;
        } else {
          this.dataInfo.fanxian_type = 1;
        }
      } else if (val == 1) {    // 赠送课时
        this.dataInfo.fanxian_amount = '';
        this.dataInfo.fanxian_type = '';
        this.dataInfo.fangift_name = '';
      } else {    // 赠送礼品
        this.applyCourseDatas.IDs = '';
        this.applyCourseDatas.Names = '';
        this.dataInfo.fanxian_amount = '';
        this.dataInfo.fanxian_type = '';
        this.dataInfo.fanhour_num = '';
        this.dataInfo.fanhour_keyvalues = '';
        this.dataInfo.fanhour_keys = '';
      }
      this.$emit('changebackStatus', val);
    },
    // 打开课程
    showCourseNameListDialog () {
      if (this.backStatus != 1) {
        return false;
      }
      this.isShowSelectCourseDialog = true;
    },

    addSusscess (item) {
      let obj = {
        selectedNames: [],
        selectedIDs: []
      };
      obj.selectedNames.push(item.MainDemoName);
      obj.selectedIDs.push(item.OLAPKey);
      this.doAfterSelectionCourseName(obj);
      this.$emit('updateCourseList');
      // this.loadDataList();
    },
    doAfterSelectionCourseName (obj) {
      console.log(obj, 'doAfterSelectionCourseName');
      if (obj && obj.selectedNames) {
        let item = {
          MainDemoName: obj.selectedNames[0],
          OLAPKey: obj.selectedIDs[0]
        };
        this.applyCourseDatas = {
          IDs: obj.selectedIDs[0],
          Names: obj.selectedNames[0]
        };
        this.dataInfo.fanhour_keyvalues = item.MainDemoName;
        this.dataInfo.fanhour_keys = item.OLAPKey;
        this.docloseSelection();
      }
    },
    docloseSelection () {
      this.isShowSelectCourseDialog = false;
    }

  }
};
</script>
