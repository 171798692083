<template>
  <div class="credit_management_popup">
    <!-- <div class="statistics-box" style="width: 322px">
      <statistics-box
        :dataList="statisticsData"
        @clickEvent="clcikStatictisItem"
      ></statistics-box>
    </div> -->
     <div class="statistics-box" style="width: 322px;margin: 20px">
      <statistics-box
        :dataList="statisticsData"
        @clickEvent="clcikStatictisItem"
      ></statistics-box>
    </div>
    <div class="popup_title_padding view_title_flex">
      <input-search-contain-btn
        v-model="searchObj.searchText"
        :placeholder="'请输入学生名'"
        @clearSearch="clearSearchCondition"
        @onSearchEvent="search"
      ></input-search-contain-btn>
      <div class="mendian_backstage_search">
        <div
          class="btn_hover_bg_blue search_btn"
          style="margin: 0"
          @click.stop="addItem"
        >
          新增
        </div>
      </div>
    </div>
    <div class="credit_management_table">
      <table-list
        class="summarizing_list table_list_content"
        :tableData="vdataReportList"
        :tableColumns="tableCourseColumns"
        :totalNum="totalNum"
        :queryParams="searchObj"
        :footerContent="vFooterTotal"
        @loadTableData="getDataList"
      ></table-list>
    </div>
    <custom-dialog
      :title="vAddFormTitle"
      :visible.sync="showAddForm"
      :before-close="closeDialog"
    >
      <add-student
        :dataInfo="seletedItem"
        @afterSuccess="updateData"
        @cancelClick="closeDialog"
      ></add-student>
    </custom-dialog>
  </div>
</template>
<script>
import addStudent from "../../form/add-student";
import statisticsBox from "../../../../report/common/statistics-box";
import { CWSSearchPageStudentList,CWSStudentLeave,CWSStudentEnable } from "../../../../../API/workbench";
import inputSearchContainBtn from "../../../../report/common/input-search-contain-btn";
import tableList from "../../../../common/table-list/index";
export default {
  data() {
    return {
      statisticsData: {
        StudentNotLeave: {
          title: "在校学生数",
          value: 0,
          type: 1,
          isSelection: true,
        },
        StudentLeave: {
          title: "离校学生数",
          value: 0,
          type: 2,
          isSelection: false,
        },
      },
      seletedItem: null, //选中单个
      showAddForm: false,
      dataReportList: [],
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: "",
        orderBy: "",
        sequence: "DESC",
        StudentType: "1",
      },
      tableData: {},
      totalNum: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: "序号",
          prop: "Number",
          width: 68,
          sortable: false,
          align: "left",
          type: "tableIndex",
          className: "table_index_gray",
        },
        {
          label: "学生名",
          prop: "MainDemoName",
          width: 100,
          sortable: false,
          align: "left",
          className: "",
          type: "textItem",
        },
        {
          label: "头像",
          prop: "HeadImgSupport",
          width: 68,
          sortable: false,
          align: "center",
          type: "headimg-item",
        },
        {
          label: "性别",
          prop: "SexKeyValue",
          width: 70,
          sortable: false,
          align: "left",
          type: "textItem",
        },
        {
          label: "所在班级",
          prop: "GradeKeyValueList",
          width: 250,
          sortable: false,
          align: "left",
          type: "textItem",
        },
        {
          label: "状态",
          prop: "vActiveKeyValue",
          width: 80,
          sortable: false,
          type: "textItem",
        },
        {
          label: "操作",
          prop: "option",
          type: "hidden-btn",
          width: 200,
          align: "center",
          extend: {
            tableBtnGroup: [
              // {
              //   name: "详情",
              //   extend: {
              //     isHide: (rowData) => {
              //       return rowData.OLAPKey==0;
              //     },
              //     click: (rowData) => {
              //       this.goDetail(rowData);
              //     },
              //   },
              // },
              {
                name: "修改",
                extend: {
                  isHide: (rowData) => {
                    return rowData.OLAPKey==0;
                  },
                  click: (rowData) => {
                    this.editItem(rowData);
                  },
                },
              },
              {
                name: "在校",
                extend: {
                  isHide: (rowData) => {
                    return rowData.IsActiveKey == 1;
                  },
                  click: (rowData) => {
                     this.enableItem(rowData);
                  },
                },
              },
              {
                name: "离校",
                extend: {
                  isHide: (rowData) => {
                    return rowData.IsActiveKey != 1;
                  },
                  click: (rowData) => {
                    this.stopItem(rowData);
                  },
                },
              },
            ],
          },
        },
      ],
    };
  },
  props: {},
  components: {
    inputSearchContainBtn,
    tableList,
    statisticsBox,
    addStudent,
  },
  computed: {
    vdataReportList() {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        o.vActiveKeyValue=o.IsActiveKey==1?'在校':'离校'
        o.GradeKeyValueList=''
        if(o.Grade.length>0){
          o.Grade.forEach((item,index)=>{
            if(index<o.Grade.length-1){
              o.GradeKeyValueList+=item.GradeKeyValue+','
            }else{
              o.GradeKeyValueList+=item.GradeKeyValue
            }
          })
        }
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal() {
      let text = "共" + this.totalNum + "条数据";
      return text;
    },
    vAddFormTitle() {
      return !this.seletedItem ? "新增学生" : "修改学生";
    },
    vSeletedItemKey() {
      return this.seletedItem ? this.seletedItem.OLAPKey : 0;
    },
  },

  created() {
    this.getDataList();
  },
  mounted () {
    this.$dialog.register(this, ['editPassWord']);
  },
  methods: {
    // 查看详情
    goDetail(item){
       this.seletedItem=item
    },
    // 修改密码
    editPassWord(item){
      this.seletedItem=item
      let itemObj = {
        routerName: this.$route.name,
        moduleName: 'editPassWord',
        name: '修改密码',
        data: { OLAPKey: this.seletedItem.OLAPKey },
        callBack: {
          afterSuccess: (data) => {
            // this.$emit('afterSuccess', data);
          }
        }
      };
      this.$dialog.open(this, itemObj);
    },
    // 修改单个
    editItem(item) {
      this.seletedItem = item;
      this.showAddForm = true;
    },
    // 新增按钮
    addItem() {
      this.seletedItem = null;
      this.showAddForm = true;
    },
    closeDialog() {
      this.showAddForm = false;
    },
    updateData() {
      this.getDataList();
    },
    // 重置查询条件
    initParamsForQuery() {
      this.dataReportList = [];
      this.searchObj.searchText = "";
      this.searchObj.pageIndex = 0;
    },
    // 点击分类
    clcikStatictisItem(item) {
      this.initParamsForQuery();
      this.searchObj.StudentType = item.type;
      this.getDataList();
    },
    clearSearchCondition() {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = "";
      this.getDataList();
    },
    search() {
      this.searchObj.pageIndex = 0;
      if (this.searchObj.searchText == "") {
        this.searchObj.StudentKey = "";
      }
      this.getDataList();
    },
    // 启用
    enableItem(item) {
      this.seletedItem = item;
      layer.confirm("请问是否将该学生转为在校学生", {
        btn: [
          {
            name: "确认",
            callBack: () => {
              CWSStudentEnable(this.seletedItem.OLAPKey).then((result) => {
                this.updateData();
              });
              layer.close();
            },
          },
        ],
      });
    },
    //停用
    stopItem(item) {
      this.seletedItem = item;
      layer.confirm("请问是否将该学生转为离校学生", {
        btn: [
          {
            name: "确认",
            callBack: () => {
              CWSStudentLeave(this.seletedItem.OLAPKey).then((result) => {
                // layer.alert("启用成功")
                this.updateData();
              });
              layer.close();
            },
          },
        ],
      });
    },
    getDataList() {
      this.dataReportList=[]
      CWSSearchPageStudentList(this.searchObj).then(
        (res) => {
          console.log("CWSSearchPageTeacherList", res.data);
          this.totalNum = res.data.Total;
          this.dataReportList = res.data.PageDataList;
          this.statisticsData.StudentLeave.value=Number(res.data.LeaveSchoolNum)
          this.statisticsData.StudentNotLeave.value=Number(res.data.InSchoolNum)
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
  },
};
</script>