<template>
  <div class="apply_payment_echarts fl">
    <div class="apply_payment_echarts_hous massive_bg echarts_hous_mb">
      <div class="statistics_echarts_left fl">
        <div class="apply_payment_echarts_left_title clearfix">
          <div class="title_font fl">异常处理统计日报</div>
          <single-year-picker @chgDate="chgDate"
                              :type="'month'"></single-year-picker>
        </div>
        <div>
          <doubleTypeEchart :echartID="'graph3'"
                            :reqList="monthList"
                            :nowDataList="nowList"
                            :echartType="'line'"
                            ref="echart"></doubleTypeEchart>
        </div>
        <div>
          <a @click.stop="showDetail"
             class="class_statistics_details_btn">
            查看详情
          </a>
        </div>
      </div>
      <div class="statistics_echarts_right fl">
        <div class="apply_payment_box_title">
          {{thisMonth}} <span>{{vDateTime}}</span>
        </div>
        <div class="box_dot dot_green">
          异常处理
        </div>
        <div class="apply_payment_box_number">
          {{vtotalCount}}
          <span>次</span>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import singleYearPicker from '../date-picker-common/single-year-picker';
import doubleTypeEchart from '../echart-common/double-type-echart';
import { getExceptionReport } from '../../../API/workbench';
export default {
  name: 'exceptionReport',
  data () {
    return {
      seletedDate: Date.new(),
      startTime: null,
      endTime: null,
      monthList: [],      // 报表底下显示长度.
      nowList: [],    // 选中月数据显示的内容
      nowDataList: []     // 选中当月数据源
    };
  },
  components: {
    singleYearPicker, doubleTypeEchart
  },
  computed: {
    thisMonth () {
      if (this.seletedDate.getFullYear() == Date.new().getFullYear() && this.seletedDate.getMonth() == Date.new().getMonth()) { return '本月'; }
    },
    vDateTime () {
      return this.$utils.formatDatet(this.seletedDate, 'yyyy') + '年' + this.$utils.formatDatet(this.seletedDate, 'MM') + '月';
    },
    vtotalCount () {
      let totalCount = 0;
      this.nowDataList.forEach(o => {
        totalCount += o.totalCount;
      });
      return totalCount;
    }
  },
  mounted () {
    this.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy/MM/dd');
    this.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy/MM/dd');
    this.monthList = this.getDaysOfMonth(this.seletedDate);
    this.getNowKQDataByDay(this.startTime, this.endTime);
  },
  methods: {
    showDetail () {
      this.$emit('showDetail', '异常统计日报', this.startTime, this.endTime);
    },
    chgDate (val, startTime, endTime) {
      this.seletedDate = val;
      this.startTime = startTime;
      this.endTime = endTime;
      this.monthList = this.getDaysOfMonth(this.seletedDate);
      console.log(this.monthList, '最长', startTime, endTime);
      this.getNowKQDataByDay(this.startTime, this.endTime, this.getLastKQDataByDay);
    },
    // 获取天数数组
    getDaysOfMonth (monthDate) {
      monthDate = monthDate || Date.new(Date.new().setDate(1));
      let dayList = [];
      let selectedMonth = Date.new(monthDate.getFullYear(), monthDate.getMonth() + 1, 0);
      let dayMany = selectedMonth.getDate();
      for (let i = 1; i <= dayMany; i++) {
        dayList.push(i);
      }
      return dayList;
    },
    // 获取选中月数据.
    getNowKQDataByDay (startTime, endTime, callback) {
      getExceptionReport(startTime, endTime).then(result => {
        this.nowDataList = result.data;
        this.nowList = this.getNowList();
        this.$nextTick(() => {
          this.$refs.echart.echartInit();
        });
        if (callback) {
          callback();
        }
      }, r => {
        console.log(r);
      });
    },
    // 获取选中年数据 因为数据呈现为12个月. 所以如果返回数据不符合该月份的话直接push '0'
    getNowList () {
      let nowList = [];
      let monthList = this.getDaysOfMonth(this.seletedDate);
      monthList.forEach(p => {
        let item = this.nowDataList.find(o => {
          return Date.new(o.SetUpTime).getDate() == parseInt(p);
        });
        if (item) {
          nowList.push(Number(item.totalCount).toFixed(0));
        } else {
          nowList.push('0');
        }
      });
      return nowList;
    }

  }
};
</script>

