<template>
  <div>
    <div class="new_course_time_from">
      <student-label :studentID="studentInfo.StudentKey"
                     :dataInfo="studentInfo"
                     :recordListLength="recordDataListLength"
                     @alterStudent="alterStudent"
                     @showRecord="showRecord"
                     :showMenu="true"></student-label>
      <div class="up_sell_record_from bg_white">
        <!-- 选项卡 -->
        <div class="up_sell_record_from_title">
          客户意向
        </div>
        <option-list :optionList="optionList"
                     @seletedItem="seletedOption"></option-list>
        <div class="from_textarea_box">
          <div class="up_sell_record_from_title from_textarea_title">
            跟进记录/结果
          </div>
          <div class="from_textarea_content">
            <textarea v-model="dataObj.FollowRecordsSupport"
                      placeholder="请输入跟进记录"
                      maxlength="600"></textarea>
          </div>
        </div>
        <div class="up_sell_record_from_date pr">
          <input-date v-model="dataObj.NextFollowTime"
                      :elementId="'classDate'"
                      :formTitle="'下次跟进'"
                      :minDate="$utils.formatDateToLine(Date.new())"
                      :formPlaceholder="'请选择'"
                      @changeDate="changeDate">
          </input-date>
          <div class="btn_hover_red"
               @click.stop="abandon">
            <span>放弃</span>
          </div>
        </div>

      </div>
      <common-button-group @buttonClickEvent="submit"
                           @closeDialog="doAfterClickCancelBtn"></common-button-group>
    </div>
    <custom-dialog title="全部记录"
                   :visible.sync="isShowRecord"
                   :before-close="closeisShowRecord">
      <recordList :recordList="recordDataList"></recordList>
    </custom-dialog>
  </div>
</template>
<script>
import studentLabel from './up-sell-record-component/up-sell-student-label';
import recordList from './up-sell-record-component/record-list';
import optionList from './up-sell-record-component/select-option';
import { AddFollowUpRecords, SelectFollowUpRecords, AbandonFollowUp } from '../../../../API/workbench';

export default {
  data () {
    return {
      studentInfo: {},
      optionList: [// 选项卡菜单
        { level: 'S', value: '极高', cardKey: 1, isOpt: true },
        { level: 'A', value: '较高', cardKey: 2, isOpt: false },
        { level: 'B', value: '一般', cardKey: 3, isOpt: false },
        { level: 'C', value: '较低', cardKey: 4, isOpt: false }
      ],
      dataObj: {
        PurposeName: '极高', // '客户意向:S-极高;A-较高;B-一般;C-较低',
        PurposeKeyName: 'S', // '客户意向Key:S A B C',
        FollowRecordsSupport: '', // '跟进记录',
        NextFollowTime: '' // '下次跟进时间'
      },
      recordDataList: [],  // 全部记录
      isShowRecord: false
    };
  },
  props: {
    dataInfo: Object
  },
  components: {
    studentLabel, optionList, recordList
  },
  computed: {
    recordDataListLength () {
      return this.recordDataList.length;
    }
  },
  created () {
    this.studentInfo = this.dataInfo;
    this.getAllFollowRecord();
    this.initOptionHighLinght();
  },
  methods: {
    initOptionHighLinght () {
      if (this.studentInfo.PurposeKeyName) {
        this.optionList.forEach(o => {
          if (o.level === this.studentInfo.PurposeKeyName) {
            o.isOpt = true;
            this.dataObj.PurposeName = o.value;
            this.dataObj.PurposeKeyName = o.level;
          } else {
            o.isOpt = false;
          }
        });
      }
    },
    alterStudent (item) {

    },
    seletedOption (item) {
      this.dataObj.PurposeName = item.value;
      this.dataObj.PurposeKeyName = item.level;
      console.log(item, 'seletedOption', this.dataObj);
    },
    // 放弃试课
    abandon () {
      layer.confirm('确认放弃后，将不再提示跟销信息。客户进入生源地。', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              AbandonFollowUp(this.studentInfo.TableID, this.dataObj).then(result => {
                this.$emit('updateData');
                this.$emit('close');
              });
              layer.close();
            }
          }
        ]
      });
    },
    getAllFollowRecord () {
      console.log(this.studentInfo, 'getAllFollowRecord');
      SelectFollowUpRecords(this.studentInfo.TableID).then(result => {
        // result.data.sort((x, y) => {
        //   return Date.new(x.StartTimeFrom) - Date.new(y.StartTimeFrom);
        // });
        this.recordDataList = result.data;
        console.log('SelectFollowUpRecords', result.data);
      }).catch(error => {
        console.log('errorSelectFollowUpRecords', error);
      });
    },
    submit (callback) {
      let isPass = false;
      if (this.dataObj.FollowRecordsSupport == '') {
        isPass = true;
        layer.alert('请输入跟进记录');
      } else if (this.dataObj.NextFollowTime == '') {
        isPass = true;
        layer.alert('请选择下次跟进日期');
      }
      if (isPass) {
        if (callback) {
          callback();
        }
        return false;
      } else {
        console.log(this.studentInfo, 'AddFollowUpRecords');
        AddFollowUpRecords(this.studentInfo.TableID, this.dataObj).then(result => {
          layer.alert('添加跟进记录成功');
          this.$emit('updateData');
          this.$emit('close');
        });
      }
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    },
    changeDate (date) {
      console.log('changeDate', date);
    },
    showRecord () {
      this.isShowRecord = true;
    },
    closeisShowRecord () {
      this.isShowRecord = false;
    }
  }
};
</script>
