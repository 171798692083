<template>
  <div class="bottom_course_box" v-if="vSimplifyModel">
    <!-- 时间范围 -->
    <div class="bottom_time_ul">
      <div class="bottom_time_list">
        {{dataInfo.timeRange}}:00
      </div>
    </div>
    <!-- 根据时间范围滤出一周下的数据 -->
    <div class="bottom_course_week_ul">
      <div class="bottom_course_week_list"
           v-for="(item,key) in dataInfo.crosswiseList"
           :key="key">
        <div class="bottom_course_time_ul" v-if="item.data.length>0">
          <course-info-block v-for="itemInfo in item.data"
                             :key="itemInfo.OLAPKey"
                             :itemInfo="itemInfo"
                             @clickItem="clickItem"></course-info-block>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import courseInfoBlock from './course-info-block';
export default {
  components: {
    courseInfoBlock
  },
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object,
    isShowSimplifyModel: {// 是否精简模式（不显示没有数据的时间段）
      type: Boolean,
      default: true
    }
  },
  computed: {
    vSimplifyModel () {
      if (this.isShowSimplifyModel) {
        if (this.dataInfo.lengthwaysList.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  },
  created () {
    // console.log(this.dataInfo, 'item-item');
  },
  methods: {
    clickItem (item) {
      this.$emit('clickItem', item);
    }
  }
};
</script>

<style>
</style>