<template>
  <div class="ZSJK_activity_list swiper-slide"
       :class="{opt:couponData.isSelected}"
       @click.stop="$emit('click', couponData)">
    <div class="">
      <div class="ZSJK_activity_list_classify">
        <img :src="CDNUrl + couponData.pic"
             alt=""
             :onerror="defaultImg">
        <div v-if="couponData.programme_type == 1"
             class="ZSJK_activity_list_classify_icon"></div>
      </div>
      <div class="ZSJK_activity_list_content ZSJK_activity_list_right">
        <div class="ZSJK_activity_list_content_text">
          {{couponData.active_title}}
        </div>
        <div class="ZSJK_activity_list_text clearfix">
          <span class="font_gray">玩法类型：</span>
          <span class="">{{ vActivityType }} | {{ couponData.PlayTitle }}</span>
          <!-- <div class="fl" :class="{icon_zhuan:couponData.if_fenxiang == 1,icon_pu:couponData.if_fenxiang == 2,icon_lie:couponData.if_fenxiang == 3}">{{couponData.couponType}}</div> -->
          <!-- <div class="fr font_red" :class="{font_blue:couponData.statusKey==2}">{{couponData.statusName}}</div> -->
        </div>
      </div>
    </div>
    <div class="ZSJK_activity_list_type"
         :class="{type_green:couponData.statusKey==2}">{{couponData.statusName}}</div>
    <div class="ZSJK_activity_list_content_status clearfix">
      <div class="fl"><span class="font_gray">报名人数:</span>{{vTotalGetNum}}</div>
      <div class="fr"><span class="font_gray">报名金额:</span>{{Number(couponData.total_pay_amount) || 0}}</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      defaultImg: 'this.src="' + require('../../../../public/image/noBillImg.png') + '"'
    };
  },
  props: {
    couponData: Object
  },
  computed: {
    CDNUrl () {
      return this.$store.getters.CDNURL;
    },
    vTotalGetNum () {
      return this.couponData.total_get_num || 0;
    },
    vActivityType () {
      if (this.couponData.PlayType == 1) {
        return '0元报名';
      } else if (this.couponData.PlayType == 2) {
        return '预付款';
      } else if (this.couponData.PlayType == 3) {
        return '全款';
      } else {
        return '';
      }
    }
  }
};
</script>
<style>
</style>