import { render, staticRenderFns } from "./mini-program-details.vue?vue&type=template&id=0822ccf0&scoped=true"
import script from "./mini-program-details.vue?vue&type=script&lang=js"
export * from "./mini-program-details.vue?vue&type=script&lang=js"
import style0 from "./mini-program-details.vue?vue&type=style&index=0&id=0822ccf0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0822ccf0",
  null
  
)

export default component.exports