<template>
  <!-- 通用列表 -->
  <div class="headquarters_info_summarizing">
    <div class="table_list_content introduce_list">
      <table-list ref="tableListRef"
                  :tableWidth="'1246px'"
                  :isShowlistDropBox="false"
                  :downBoxText="'<div class=authorization_courseware_btn>授权课件</div>'"
                  :tableData="retailAuthorizeCoursewareList"
                  :tableColumns="tableColumns"
                  :rowKey="'Number'"
                  @downBoxClick="downBoxClick"></table-list>
    </div>

    <custom-dialog :title="titleName"
                   :visible.sync="isShowCoursewareFormPopup"
                   :before-close="closeFormPopup">
      <authorization-courseware-form :coursewareId="Number(coursewareIndo.OLAPKey)"
                                     :campusID="retailInfo.OLAPKey"
                                     :isReadonly="false"
                                     @close="closeFormPopup"
                                     @afterSuccess="afterSuccess"></authorization-courseware-form>
    </custom-dialog>

    <custom-dialog :title="'修改授权设备数量'"
                   :visible.sync="isShowAuthorizationAeviceFormPopup"
                   :before-close="closeFormPopup">
      <edit-authorization-device-form :coursewareId="Number(coursewareIndo.OLAPKey)"
                                      :deviceNum="Number(coursewareIndo.AuthorizeDeviceNum)"
                                      @close="closeFormPopup"
                                      @afterSuccess="afterSuccess"></edit-authorization-device-form>
    </custom-dialog>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/expand-row-table/index';
import authorizationCoursewareForm from '../authorization-courseware/form/authorization-courseware-form';
import editAuthorizationDeviceForm from '../authorization-courseware/form/edit-authorization-device-form';
import { RetailSearchAuthorizeCourseware, AuthorizationCoursewareEnable, AuthorizationCoursewareDisable } from '../../../../API/workbench.js';
export default {
  data () {
    return {
      titleName: '',
      coursewareIndo: {},
      isShowCoursewareFormPopup: false,
      isShowAuthorizationAeviceFormPopup: false,
      retailAuthorizeCoursewareList: [],
      tableColumns: [
        {
          label: '授权日期',
          prop: 'AuthorizeTime',
          type: 'date-item',
          width: 110,
          extend: {
            dateType: 'YYMMRR HH:DD' // 日期类型
          }
        },
        {
          label: '经办人',
          prop: 'OperatorKeyValue',
          width: 90,
          type: 'text-item'
        },
        {
          label: '课件包名称',
          prop: 'parentCoursewarePackageKeyValue',
          type: 'text-item'
        },
        {
          label: '结算方式',
          prop: 'ClearingFormKeyValue',
          width: 90,
          type: 'text-item'
        },
        {
          label: '授权设备',
          prop: 'AuthorizeDeviceNum',
          width: 90,
          type: 'number-item',
          sortable: 'custom',
          extend: {
            className: 'font_blue font_border_blue',
            type: 'expand',
            click: (rowData) => { this.editAuthorizationEquipment(rowData); }
          }
        },
        {
          label: '发放',
          prop: 'ReleasedNum',
          width: 60,
          type: 'number-item'
        },
        {
          label: '使用',
          prop: 'UsageNum',
          width: 60,
          type: 'number-item'
        },
        {
          label: '剩余',
          prop: 'residue',
          width: 60,
          type: 'number-item'
        },
        {
          label: '排课班次',
          prop: 'ArrangeGradeClassNum',
          type: 'class-hour',
          width: 80
        },
        {
          label: '排课课次',
          prop: 'ArrangeNum',
          type: 'class-hour',
          width: 80
        },
        {
          label: '排课警戒',
          prop: 'ArrangedWarningValue',
          type: 'text-item',
          width: 80
        },
        {
          label: '状态',
          prop: 'StatusKeyValue',
          align: 'center',
          type: 'text-item',
          width: 80,
          extend: {
            setClassName: (rowData) => { // 1：启用 0：停用 2：已用完 3：已过期
              if (rowData.StatusKey == 0) {
                return 'status_spot_red';
              } else if (rowData.StatusKey == 1) {
                return 'status_spot_green';
              } else {
                return 'status_spot_gray';
              }
            }
          }
        },
        {
          label: '定位授权',
          prop: 'IsLocationLockKeyValue',
          width: 80,
          type: 'text-item',
          sortable: false
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'left',
          width: 140,
          extend: {
            tableBtnGroup: [
              {
                name: (rowData) => {
                  return rowData.StatusKey == 0 ? '恢复授权' : '暂停授权';
                },
                extend: {
                  isHide: (rowData) => {
                    return rowData.SysStatusKey == 1;
                  },
                  IsInquiryHint: 'inquiryBtn',
                  setClassName: (rowData) => {
                    if (rowData.inquiryBtn) { // 开启询问提示，显示询问弹窗时，界面按钮置灰，不可操作
                      return rowData.inquiryBtn;
                    }
                  },
                  click: (rowData, callBack) => {
                    if (callBack) {
                      callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: 'table_btn_gray' });
                    }
                    this.switchClick(rowData, callBack);
                  },
                  ModulePowerKey: ''
                }
              },
              {
                name: '续费',
                extend: {
                  isHide: (rowData) => {
                    return rowData.StatusKey == 0;
                  },
                  click: (rowData) => { this.renewClick(rowData); },
                  ModulePowerKey: ''
                }
              }
            ]
          }
        }
      ],
      totalNum: 0,
      defaultSort: { prop: '', order: 'ascending' }
    };
  },
  props: {
    retailInfo: Object
  },
  components: {
    tableList,
    authorizationCoursewareForm,
    editAuthorizationDeviceForm
  },
  watch: {
    // retailInfo () {
    //   console.log(this.retailInfo, '授权课包11');
    //   this.getRetailSearchAuthorizeCourseware();
    // }
  },
  created () {
    this.getRetailSearchAuthorizeCourseware();
    this.$bus.on(this, 'updateCampusCourseware', (data) => {
      console.log(data, 'updateCampusCourseware');
      if (this.retailInfo.OLAPKey && this.retailAuthorizeCoursewareList.length > 0) {
        this.retailInfo.OLAPKey = data.DataKey;
        this.getAuthorizeCoursewareCountDetial();
      }
    });
  },
  methods: {
    // 授权课包数
    getRetailSearchAuthorizeCourseware () {
      RetailSearchAuthorizeCourseware(this.retailInfo.OLAPKey).then(result => {
        console.log(result.data, '授权课包11');
        if (result.data) {
          this.totalNum = result.data.Total;
          this.retailAuthorizeCoursewareList = result.data.PageDataList;
          this.retailAuthorizeCoursewareList.forEach(o => {
            o.AuthorizeTime = o.AuthorizeTime ? o.AuthorizeTime.slice(0, 10) : '';
            if (Number(o.StatusKey) == 1) {
              o.StatusKeyValue = '使用中';
            } else if (Number(o.StatusKey) == 0) {
              o.StatusKeyValue = '已暂停';
            } else if (Number(o.StatusKey) == 2) {
              o.StatusKeyValue = '已用完';
            } else if (Number(o.StatusKey) == 3) {
              o.StatusKeyValue = '已过期';
            }
            o.parentCoursewarePackageKeyValue = (o.ParentKeyValue ? o.ParentKeyValue + '-' : '') + o.CoursewarePackageKeyValue;
            if (Number(o.ArrangedAlert) == 1) {
              o.ArrangedWarningValue = `<div class="arranged_alert">警戒</div>`;
            } else if (Number(o.ArrangedWarning) == 1) {
              o.ArrangedWarningValue = `<div class="arranged_warning">预警</div>`;
            }
            o.IsLocationLockKeyValue = o.IsLocationLockKey > 0 ? '已开启' : '未开启';
          });
          console.log(this.retailAuthorizeCoursewareList, '授权课包数');
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 排序
    loadTableData (queryParams) {
      console.log('排序', queryParams);
      this.$emit('loadTableData', queryParams);
    },
    // 授权课件按钮
    downBoxClick () {
      console.log(this.retailInfo, '授权课件按钮');
      let days = this.$utils.DateDiffDay(this.$utils.formatDateToLine(Date.new()), this.retailInfo.ExpiredTime.slice(0, 10)) + 1;
      console.log(days);
      if (days < 0) {
        layer.alert('门店已过期,请及时续费！');
        return;
      }
      this.titleName = '授权课件';
      this.coursewareIndo = {};
      this.isShowCoursewareFormPopup = true;
    },
    // 停用/启用
    switchClick (rowData, callBack) {
      let name = rowData.StatusKey == 0 ? '恢复授权' : '暂停授权';
      layer.confirm('请确定是否' + name + '?', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              layer.close();
              if (callBack) {
                callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
              }
            }
          },
          {
            name: '确认',
            callBack: () => {
              if (rowData.StatusKey == 0) {
                AuthorizationCoursewareEnable(rowData.OLAPKey).then(result => {
                  this.getRetailSearchAuthorizeCourseware();
                  if (callBack) {
                    callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
                  }
                }, error => {
                  layer.alert(error.msg);
                });
              } else {
                AuthorizationCoursewareDisable(rowData.OLAPKey).then(result => {
                  this.getRetailSearchAuthorizeCourseware();
                  if (callBack) {
                    callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
                  }
                }, error => {
                  layer.alert(error.msg);
                });
              }
              layer.close();
            }
          }
        ]
      });
    },
    // 续费按钮
    renewClick (rowData) {
      this.titleName = '课件续费';
      this.coursewareIndo = rowData;
      this.isShowCoursewareFormPopup = true;
    },
    editAuthorizationEquipment (rowData) {
      this.coursewareIndo = rowData;
      this.isShowAuthorizationAeviceFormPopup = true;
    },
    afterSuccess () {
      this.getRetailSearchAuthorizeCourseware();
      this.$emit('afterSuccess');
    },
    // 关闭续费
    closeFormPopup () {
      this.isShowCoursewareFormPopup = false;
      this.isShowAuthorizationAeviceFormPopup = false;
    }
  }
};
</script>
<style>
</style>

