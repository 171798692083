<template>
  <div class="chain_workbench_file">
    <div class="file_list pr "
         @click.stop="isShowdropDownBox=true">
      <div>
        <div class="file_img"><img class="img_box"
               src="../../../../public/image/chain_workbench_file_banner01.png"
               alt=""></div>
        <div class="file_name">系统设置</div>
        <div class="module_content_menuList"
             :style="{'top':'90px','left':'2%'}"
             v-show="isShowdropDownBox"
             @mouseover.stop="isShowdropDownBox=true">
          <div class="top_bg"></div>
          <drop-down-box :optionJsonList="menuList"
                         @clickOptionForAlertDialog="clickAfter"></drop-down-box>
        </div>
      </div>
    </div>

    <function-btn :item="btnInfo.department"
                  @clickItem="openCustomDialogByFactoryDialog(btnInfo.department)"
                  :className="'file_list'">
      <div>

        <div class="file_img"><img class="img_box"
               src="../../../../public/image/chain_workbench_file_banner02.png"
               alt=""></div>
        <div class="file_name">部门岗位</div>
      </div>

    </function-btn>
    <function-btn :item="btnInfo.power"
                  @clickItem="openCustomDialogByFactoryDialog(btnInfo.power)"
                  :className="'file_list'">
      <div>

        <div class="file_img"><img class="img_box"
               src="../../../../public/image/chain_workbench_file_banner03.png"
               alt=""></div>
        <div class="file_name">角色权限</div>
      </div>

    </function-btn>
    <function-btn :item="btnInfo.staff"
                  @clickItem="openCustomDialogByFactoryDialog(btnInfo.staff)"
                  :className="'file_list'">
      <div>

        <div class="file_img"><img class="img_box"
               src="../../../../public/image/chain_workbench_file_banner04.png"
               alt=""></div>
        <div class="file_name">员工管理 </div>
      </div>

    </function-btn>
    <div class="file_list pr"
         @click.stop="isShowCheckInBox=true">
      <div>

        <div class="file_img"><img class="img_box"
               src="../../../../public/image/chain_workbench_file_banner05.png"
               alt=""></div>
        <div class="file_name">收支管理</div>
        <div class="module_content_menuList"
             :style="{'top':'90px','left':'2%'}"
             v-show="isShowCheckInBox"
             @mouseover.stop="isShowCheckInBox=true">
          <div class="top_bg"></div>
          <drop-down-box :optionJsonList="checkInMenuList"
                         @clickOptionForAlertDialog="clickAfter"></drop-down-box>
        </div>
      </div>
    </div>
    <function-btn :item="btnInfo.campusManagement"
                  @clickItem="openCustomDialogByFactoryDialog(btnInfo.campusManagement)"
                  :className="'file_list'">
      <div>

        <div class="file_img"><img class="img_box"
               src="../../../../public/image/school_manager.png"
               alt=""></div>
        <div class="file_name">系统端口管理</div>
      </div>
    </function-btn>
    <function-btn :item="btnInfo.schoolSetting"
                  @clickItem="openCustomDialogByFactoryDialog(btnInfo.superintendentSet)"
                  :className="'file_list'">
      <div>

        <div class="file_img"><img class="img_box"
               src="../../../../public/image/chain_workbench_file_banner06.png"
               alt=""></div>
        <div class="file_name">督学设置</div>
      </div>
    </function-btn>
  </div>
</template>
<script>
import dropDownBox from '../../common/drop-down-box';
export default {
  data () {
    return {
      menuList: [
        {
          name: '连锁类型',
          type: 1,
          isShow: true,
          routerName: 'chainWorkBench',
          auth: true,
          roleName: '',
          moduleName: 'chainTypeSetting',
          ModulePowerKey: 0
        },
        {
          name: '直属设置',
          type: 2,
          isShow: true,
          routerName: 'chainWorkBench',
          auth: true,
          roleName: '',
          moduleName: 'directlySetting',
          ModulePowerKey: 0
        },
        {
          name: '课件安全',
          type: 3,
          isShow: true,
          routerName: 'chainWorkBench',
          auth: true,
          roleName: '',
          moduleName: 'coursewareSecurity',
          ModulePowerKey: 0
        },
        {
          name: '收支科目',
          type: 4,
          isShow: true,
          routerName: 'chainWorkBench',
          auth: true,
          roleName: '',
          moduleName: 'incomePaySubject',
          ModulePowerKey: 112
        },
        {
          name: '招商杂费设置',
          type: 4,
          isShow: true,
          routerName: 'chainWorkBench',
          auth: true,
          roleName: '',
          moduleName: 'merchantsIncidentalsSet',
          ModulePowerKey: 130
        },
        {
          name: '招商来源设置',
          type: 4,
          isShow: true,
          routerName: 'chainWorkBench',
          auth: true,
          roleName: '',
          moduleName: 'merchantsSourceSet',
          ModulePowerKey: 131
        }
      ],
      checkInMenuList: [
        {
          'name': '收支录入',
          'routerName': 'chainWorkBench',
          'auth': true,
          'roleName': '',
          type: 'HQ',
          isShow: true,
          'moduleName': 'incomepayentry',
          'ModulePowerKey': 116
        },
        {
          'name': '收支管理',
          'routerName': 'chainWorkBench',
          'auth': true,
          'roleName': '',
          type: 'HQ',
          isShow: true,
          'moduleName': 'incomepaymanage',
          'ModulePowerKey': 116
        },
        {
          'name': '收支账户',
          'routerName': 'chainWorkBench',
          'auth': true,
          'roleName': '',
          type: 6,
          isShow: true,
          'moduleName': 'incomepayAccount',
          'ModulePowerKey': 116
        }
      ],
      isShowdropDownBox: false,
      isShowCheckInBox: false,  // 收支管理下拉框
      btnInfo: {}
    };
  },
  components: {
    dropDownBox
  },
  created () {
    this.btnInfo = this.$initJson.chainWorkBenchBtn;
  },
  mounted () {
    document.querySelector('body').addEventListener('click', e => {
      this.isShowdropDownBox = false;
      this.isShowCheckInBox = false;
    }, false);
  },
  props: {

  },
  methods: {
    clickAfter (item) {
      console.log('clickAfter', item);
      this.isShowdropDownBox = false;
      this.isShowCheckInBox = false;
      if (item.name == '直属设置') {
        item.name = '直属店设置';
      }
      this.openCustomDialogByFactoryDialog(item);
    },
    openCustomDialogByFactoryDialog (item) {
      this.isShowdropDownBox = false;
      this.isShowCheckInBox = false;
      if (item.moduleName == '') {
        this.$bus.emit('needOpenRouter', item);
      } else {
        this.$emit('openCustomDialogByFactoryDialog', item);
      }
    }

  }
};
</script>
