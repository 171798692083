import Vue from 'vue';
import Vuex from 'vuex';
import store from './store';
import App from './App';
import router from './router';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import ElementUI from 'element-ui';
import '../src/assets/css/reset.css';
import '../src/assets/css/index.css';
import '../src/assets/css/common.css';
import 'element-ui/lib/theme-chalk/index.css';
import * as utils from '../src/utils/index.js';
import * as openFactoryDialog from '../src/utils/openFactoryDialog.js';
import fun from '../src/assets/KPI';
import '../public/js/directives';

import basicData from '../src/assets/basic-data';
import initJson from './js/initJson.json';
import websocket from '../src/assets/websocket.js';
import asyncTask from '../src/assets/async-task.js';

import './vuecrop/Icon-svg/index.js';
import bus from './js/bus.js';
import { install } from './vuecrop/index.js';
import VueAMap from 'vue-amap';

Date.new = function () {
  if (arguments.length == 0) {
    return new Date();
  } else if (arguments.length == 1) {
    if (typeof arguments[0] == 'string') {
      arguments[0] = arguments[0].replace(/-/g, '/');
      if (arguments[0].length == 7 || arguments[0].length == 6) {
        arguments[0] += '/01';
      }
    }
    return new Date(arguments[0]);
  } else {
    var args = Array.prototype.slice.apply(arguments);
    while (args.length < 7) {
      args.push(0);
    }
    return new Date(
      args[0],
      args[1],
      args[2],
      args[3],
      args[4],
      args[5],
      args[6]
    );
  }
};

Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: 'a666b216da427ff39aa042be701ba9a9',
  plugin: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor',
    'AMap.Geolocation',
    'AMap.Weather'
  ],
  // 默认高德 sdk 版本为 1.4.4
  // v: '1.4.4'
  uiVersion: '1.1.1' // 添加 uiVersion 的脚本版本号
});

Vue.use(install);
Vue.use(ElementUI);
Vue.use(Vuex);
// 基础组件的自动化全局注册
const requireComponent = require.context(
  './components/base-component', // 其组件目录的相对路径
  false, // 是否查询其子目录
  /[a-z]\w+\.(vue|js)$/ // 匹配基础组件文件名的正则表达式
);

requireComponent.keys().forEach(fileName => {
  // 获取组件配置
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  );
  // 全局注册组件
  Vue.component(componentName, componentConfig.default || componentConfig);
});
// 注册window.store 方便外部引入的JS调用.
window.getEditorUrl = x => {
  return (
    store.getters.APIURL +
    '/public/?service=WEB.Files.Upload&type=2&s=WEBVAPIUploadFiles&md5=&sign=' +
    store.getters.token.SignData.Sign
  );
};
// 这个是提供给ifraim刷新所用.
window.chartList = [];
// 桌面端交互
let ZXClient = { IsClient: false };
if (window.CefSharp) {
  ZXClient.IsClient = true;
  window.CefSharp.BindObjectAsync('boundEvent').then(function (result) {
    let emit = (ZXClient.emit = (minVersion, eventName, sendObject) => {
      console.log('zxclient', eventName);
      let isVersionOK = true;
      if (
        minVersion &&
        ZXClient.Version &&
        utils.checkVersion(ZXClient.Version, minVersion) < 0
      ) {
        isVersionOK = false;
      }
      if (isVersionOK) {
        sendObject = sendObject || null;
        return window.boundEvent.raiseEvent(eventName, sendObject);
      } else {
        // eslint-disable-next-line no-alert
        window.alert('当前客户端版本不支持此功能，请升级到最新版。');
        return false;
      }
    });

    ZXClient.GetVersion = () => {
      return emit(0, 'GetVersion');
    }; // 获取版本号
    ZXClient.DocumentReady = () => {
      return emit(0, 'DocumentReady');
    }; // 加载完成
    ZXClient.Reload = () => {
      return emit(0, 'Reload');
    }; // 重新加载，清缓存
    ZXClient.Log = msg => {
      return emit(0, 'Log', msg);
    }; // 记录日志
    ZXClient.ReadLog = () => {
      return emit(0, 'ReadLog');
    }; // 读取日志
    ZXClient.Close = () => {
      return emit(0, 'Close');
    }; // 关闭
    ZXClient.Upgrade = () => {
      return emit(0, 'Upgrade');
    }; // 升级
    ZXClient.FullScreen = () => {
      return emit('9.7.7.0', 'FullScreen');
    }; // 全屏
    ZXClient.UnFullScreen = () => {
      return emit('9.7.7.0', 'UnFullScreen');
    }; // 退出全屏
    ZXClient.Print = htmlContent => {
      return emit('11.9.10.0', 'Print', htmlContent);
    }; // 打印
    ZXClient.GetCustomCode = () => {
      return emit('12.1.25.0', 'GetCustomCode');
    }; // 获取品牌订制总部Code
    ZXClient.SetCustomCode = customCode => {
      return emit('12.1.25.0', 'SetCustomCode', customCode);
    }; // 设置品牌订制总部Code
    ZXClient.StartSSP = () => {
      return emit('12.5.12.0', 'StartSSP');
    }; // 启动防截屏
    ZXClient.CheckSSP = () => {
      console.log('CheckSSP');
      let r = emit('12.5.12.0', 'CheckSSP');
      console.log('CheckSSP', r);
      return r;
    }; // 验证防截屏
    ZXClient.StopSSP = () => {
      return emit('12.5.12.0', 'StopSSP');
    }; // 关闭防截屏
    ZXClient.GetMCode = () => {
      let mcode = emit('12.8.31.0', 'GetMCode');
      if (mcode == '') {
        mcode = 'empty';
      }
      return mcode;
    }; // 获取机器码
    ZXClient.GetMInfo = () => {
      return emit('12.8.31.0', 'GetMInfo');
    }; // 获取设备信息
    ZXClient.SetUCode = ucode => {
      return emit('12.8.31.0', 'SetUCode', ucode);
    }; // 设置设备个性码
    ZXClient.GetOSVersion = () => {
      return emit(0, 'GetOSVersion');
    }; // 获取系统版本，如果当前版本不支持，则返回空字符串，不提示用户升级版本，返回值如下 Windows0,   Windows7,    Windows8,   Windows10,    Windows11,    UnKnown

    ZXClient.Version = ZXClient.GetVersion(); // 当前客户端版本

    // 监听zx客户端全屏变更事件
    // document.addEventListener('zxfullscreenchange', function (e) { console.log(e.detail.zxIsFullScreen); }, false);//
    window.zxfullscreenchange = isFullScreen => {
      window.zxIsFullScreen = isFullScreen;
      let custom = new CustomEvent('zxfullscreenchange', {
        detail: { zxIsFullScreen: isFullScreen }
      }); // 某个dom元素监听自定义事件
      document.dispatchEvent(custom); // this is a test
    };
    window.zxsendclientlog = logtext => {
      bus.emit('zxsendclientlog', logtext);
      logtext = decodeURIComponent(logtext);
      console.log('zxsendclientlog', logtext);
    };
  });

  window.upgradedevicecode = () => {
    console.log('upgradedevicecode');
    bus.emit('upgradedevicecode');
  };
}
Vue.prototype.$zxClient = ZXClient;
Vue.prototype.$utils = utils;
Vue.prototype.$dialog = openFactoryDialog;
Vue.prototype.$fun = fun;
Vue.prototype.$basicData = basicData;
Vue.prototype.$initJson = initJson;
Vue.prototype.$bus = bus;
Vue.prototype.$asyncTask = asyncTask;
Vue.prototype.$websocket = websocket;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
