<template>
  <div class="activity_drawer_box button">
    <div class="activity_drawer_box_title pr"
         @click.stop="changeShow">
      {{item.title}}
      <div class="nav_bar_btn "
           :class="item.isActive?'close':''"></div>
    </div>
    <div class="activity_drawer_box_ul"
         v-show="item.isActive">
      <play-method-item v-for="(playItem,key) in item.dataList"
                        :key="key"
                        :item="playItem"
                        :coverInfo="coverInfo"
                        @seletedItem="seletedItemAfter"></play-method-item>

    </div>
  </div>
</template>
<script>
import playMethodItem from './play-method-item';
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object,
    coverInfo: Object
  },
  components: {
    playMethodItem
  },
  methods: {
    seletedItemAfter (item) {
      this.$emit('seletedItem', item, this.item);
    },
    changeShow () {
      // this.$set(this.item, 'isActive', !this.item.isActive);
      console.log(this.item, 'changeShow');
      this.$emit('showMoreItem', this.item);
      // this.item.isActive = !this.item.isActive;
    }
  }
};
</script>
