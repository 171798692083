<template>
  <div class="WKT_course_info_popup_list">
    <div class="WKT_information_list pr">
      <div class="WKT_information_block">
        <div class="WKT_information_img">
          <img :src="vImg"
               :onerror="defaultImg"
               alt="">
        </div>
        <div class="WKT_information_info">
          <div class="WKT_information_name">
            {{item.TeacherName}}
          </div>
          <div class="WKT_information_post">
            主讲：{{item.courses}}
          </div>
          <div class="WKT_information_text"
               v-html="item.content">
            <!-- {{item.content}} -->
          </div>
        </div>
      </div>
      <div class="WKT_information_openBtn btn_hover_bg_blue"
           @click.stop="enable">
        启用
      </div>
    </div>
  </div>

</template>
<script>

export default {
  name: 'singleSelectionItem',
  data () {
    return {
      selectItem: {}
    };
  },
  props: {
    item: Object
  },
  created () {

  },
  watch: {

  },
  computed: {
    vImg () {
      if (!this.item.picurl) {
        this.item.picurl = '';
      }
      return this.$utils.ossLitimgSet(this.item.picurl);
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../../public/image/no_logo@2x.png') + '"';
    }
  },
  methods: {
    enable () {
      this.$emit('enable', this.item);
    }
  }
};
</script>

