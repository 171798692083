<template>
  <div class="data_content">
    <div class="report_item_header data_content_header pr">
      <function-btn :item="studentBtnobj"
                    @clickItem="clickItem(studentBtnobj)"
                    :className="'button'"
                    style="margin-bottom:0;padding-top: 3px;width: 18%;margin-right: 2%;">

      </function-btn>

      <input-search-contain-btn v-model="searchText"
                                :placeholder="'学生名、首字母、手机号'"
                                :clearAll="true"
                                @clearSearch="searchText==''"
                                @onSearchEvent="search"></input-search-contain-btn>

    </div>
    <!-- <div class="data_content_header pr">
            <function-btn :item="studentBtnobj"
                            @clickItem="clickItem(studentBtnobj)"
                            :className="'data_content_item data_content_header_btn'" 
                            style="margin-bottom:0">
              
            </function-btn>
            <input-search-contain-btn v-model="searchText"
                                  
                                 :placeholder="'学生名、首字母、手机号'"
                                 :clearAll="true"
                                 @clearSearch="searchText==''"
                                 @onSearchEvent="search"></input-search-contain-btn>
           
           
        </div> -->
    <div class="data_content_list">
      <function-btn v-for="(item,key) in optionList"
                    :key="key"
                    :item="item"
                    @clickItem="clickItem"
                    :className="'data_content_item'">

      </function-btn>
      <!-- <div class="data_content_item" v-for="(item,key) in optionList" :key="key" 
             @click="clickItem(item)">
                {{item.name}}
            </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      studentBtnobj: {
        "name": "学生管理",
        "routertitle": "学生管理",
        "routerName": "studentManagementReport",
        "auth": true,
        "roleName": "",
        "ModulePowerKey": 59,
        "img_type": "05"
      },
      searchText: '',
      optionList: []
    }
  },
  props: {

  },
  created () {
    this.optionList = this.$initJson.exceptionJson;
  },
  methods: {

    search () {
      console.log('点击搜索 搜索框可见 并查询数据');
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'studentList',
        data: {
          searchText: this.searchText
        },
        callBack: {
          selectedItem: (item) => {
            this.showStudentFile(item);
          }
        }
      });
    },
    // 显示学生信息
    showStudentFile (item) {
      let moduleInfo = {
        name: '学生档案',
        routerName: this.$route.name,
        moduleName: 'studentFile',
        data: { studentID: item.OLAPKey }
      };
      this.$bus.emit('needOpenRouter', moduleInfo);
    },
    clickItem (item) {
      this.$emit("doAfterClickFunctionBtn", item)
    }
  }
}
</script>
<style scoped>
.data_content {
  width: 100%;
  height: 159px;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  box-shadow: 2px 2px 2px 0px #d7d7d733;
  margin-bottom: 20px;
  padding: 15px 20px;
}
.data_content_list {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.data_content_header {
  display: flex;
  align-items: center;
}
.data_content_header_btn {
  /* position: absolute;
    right: 2px;
    top: 0; */
}
.data_content_item {
  font-size: 12px;
  color: #636a7c;
  width: 18%;
  height: 36px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  margin-right: 2%;
  margin-bottom: 2%;
  text-align: center;
  line-height: 36px;
  height: 100%;
  cursor: pointer;
}
.data_content_item:hover {
  color: #3498db !important;
  border: 1px solid #3498db !important;
  border-radius: 4px;
}
.data_content_header >>> .defult_dialog_searchform {
  padding: 0px;
  width: 78%;
}
.data_content_header >>> .student_search_form {
  background: #f2f3f8;
  border-radius: 100px;
  height: 36px;
}
.data_content_header >>> .btn_light_blue_search,
.data_content_header >>> .list_btn_light_blue_search {
  bottom: 0px;
  right: 0px;
  color: white;
  width: 68px;
  height: 36px;
  background: #3498db !important;
  text-align: center;
  border-radius: 100px;
}

.report_item_header {
  position: relative;
  color: #333;
  font-size: 16px;
  margin-bottom: 10px;
}
.report_item_header::after {
  position: absolute;
  display: block;
  content: '';
  width: 18px;
  height: 18px;
  left: 65px;
  top: 12px;
  background: url('../../../../public/image/next.png') no-repeat center;
  background-size: 9px;
}
</style>