<template>
  <div>
    <div v-if="!vIsSelectionStudent">
      <search-box :title="'选择学生'"
      :isShowSeachItem="isShowItem"
                  :searchInitJson="{ name: '目标学生', addBtnText: '新增', isShowAddBtn: true, roleName: 47 }"
                  @searchClick="doChooseClick"
                  @onSelectedStudent="doAfterSelectedStudent"
                  @addClick="addClick"></search-box>
    </div>
    <div v-if="vIsSelectionStudent">
      <div class="bg_white defult_dialog_pf_top">
        <div class="signuppayment_stundent_lable_banner ">
          <!-- 家长信息 -->
          <div class="banner_info flex">
            <div class="banner_info_left">
              <div class="banner_info_img">
                <!-- 家长头像 -->
                <heads-img :imgUrl="studentInfo.CustomerInfo.HeadImg"
                            :isCustomer="true"
                            :showUp="false"
                            :showUpInput="false"
                            :dataInfo="studentInfo"></heads-img>
              </div>
              <div class="banner_info_phone">
                <span v-if="studentInfo.CustomerInfo.MobileSupport">{{$utils.phoneModulePower(studentInfo.CustomerInfo.MobileSupport)}}</span>
                <span v-else>--未添加手机号</span>
              </div>
              <div class="banner_info_WX" :class="{no_type:studentInfo.CustomerInfo.SubscribeKey==0,open_type:studentInfo.CustomerInfo.SubscribeKey==1}"> </div>
            </div>
            <div class="banner_info_right flex">
              <div class="banner_info_coupon" @click.stop="openMyCouponList">
                <span>券包</span>
                <span>{{this.couponList.length}}</span>
              </div>
              <!-- 切换学生 -->
              <div class="banner_info_swtich"
                  @click="switchStudent"
                  v-if="!isReadonly"> </div>
            </div>
          </div>
          <!-- 学生信息 -->
          <div class="banner_lable_box">
            <div class="swiper-container sign_up_payment_swiper"
                v-if="studentInfo.OtherStudent.length>1&&!isReadonly">
              <div class="swiper-wrapper">
                <student-child class="swiper-slide"
                              v-for="item in studentInfo.OtherStudent"
                              :key="item.OLAPKey"
                              :studentInfo="item"
                              :isReadonly="isReadonly"
                              @editArrangeSupport="showAlterMessagePopup"></student-child>
              </div>
            <div class="swiper-scrollbar"></div>
            </div>
            <div v-else>
              <student-child :studentInfo="studentInfo"
                            :isReadonly="isReadonly"
                            @editArrangeSupport="showAlterMessagePopup"></student-child>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- 修改排课备注 -->
    <custom-dialog :title="'学生：' +curShowStudentInfo.MainDemoName"
                  :append-to-body="true"
                  :visible.sync="isShowEditArrangeSupport"
                  :before-close="closeEditArrangeSupport">
      <edit-arrange-support :studentKeyValue="curShowStudentInfo.MainDemoName"
                            :textContent="curShowStudentInfo.ArrangeSupport"
                            @onClickConfirm="updateArrangeSupport"
                            @onClickClose="closeEditArrangeSupport"></edit-arrange-support>

    </custom-dialog>
    <custom-dialog :title="'优惠券'"
                  :visible.sync="isCouponListDialogShow"
                  :before-close="closeCouponListDialog">
      <div class="WKT_discount_coupon_popup_ul">
        <coupon-list :isShowbtn="false"
                    :couponList="couponList"></coupon-list>
      </div>
    </custom-dialog>
  </div>
</template>

<script>
import searchBox from '../../../../common/search-box';
import { GetCustomerStudentByStudent, getCouponPackage, EditArrangeSupport } from '../../../../../API/workbench';
import studentFor from '../../../../common-module/search-list/student-for/index';
import couponList from '../../../../wkt-back/coupon/coupon-list/index';
import editArrangeSupport from './edit-arrange-support';
import studentChild from './student-child.vue';
export default {
  data () {
    return {
      isShowSingleStudentList: false,
      studentInfo: { OtherStudent: [], CustomerInfo: {} }, // 学生信息 包含兄弟姐妹的信息
      curShowStudentInfo: {}, // 当前显示的学生信息
      studentID: 0, // 学生ID
      isShowEditArrangeSupport: false, // 是否显示修改排课备注
      swiper: null,
      isCouponListDialogShow: false,
      couponList: []// 优惠券列表
    };
  },
  props: {
    studentKey: [String, Number],
    isReadonly: Boolean,
    isShowItem:{
      type:Number,
      default:0
    }
  },
  computed: {
    vIsSelectionStudent () {
      return this.studentInfo.OLAPKey > 0;
    }
  },
  components: {
    searchBox,
    studentFor,
    studentChild,
    editArrangeSupport,
    couponList
  },
  created () {
    if (this.studentKey) {
      this.studentID = this.studentKey;
      this.loadStudentInfo();
    }
  },
  mounted () {
    this.$dialog.register(this, ['studentList', 'registercustomer']);
    this.$bus.on(this, 'AfterStudentUpdate', studentKey => {
      if (studentKey) {
        this.studentID = studentKey;
        this.loadStudentInfo();
      }
    });
  },
  methods: {
    // 点击搜索
    doChooseClick (type) {
      let titlename = '学生列表'
      if(type == 2){
        titlename = '线索列表'
      }
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'studentList',
        name:titlename,
        data: {
          switchItem: 1,
          IsConfirmKey: 0,
          typeApi:type
        },
        callBack: {
          selectedItem: (item) => {
            this.doAfterSelectedStudent(item);
          }
        }
      });
    },
    // 学生 新增
    addClick () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'registercustomer',
        callBack: {
          doafterRegisterCustomer: (item) => { this.doAfterAddSuccess(item); }
        }
      });
    },
    // 学生 新增 成功
    doAfterAddSuccess (item) {
      this.doAfterSelectedStudent(item, true);
    },
    // 获取学生详情
    loadStudentInfo () {
      GetCustomerStudentByStudent(this.studentID).then(result => {
        this.studentInfo = result.data;
        if (this.studentInfo.OtherStudent.length > 1) {
          this.$nextTick(() => {
            let index = this.studentInfo.OtherStudent.findIndex(obj => { return obj.OLAPKey == this.studentInfo.OLAPKey; });
            if (index > 0) {
              let curSelectionStudent = this.$utils.parseJson(this.studentInfo.OtherStudent[index]);
              this.studentInfo.OtherStudent.splice(index, 1);
              this.studentInfo.OtherStudent.unshift(curSelectionStudent);
            }
            this.swiperInit();
          });
        }
        this.$emit('onSelectionStudent', this.studentInfo);
        this.getCouponList(this.studentInfo.CustKey);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 切换学生
    switchStudent () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'studentList',
        data: {
          switchItem: 0
        },
        callBack: {
          selectedItem: (item) => {
            this.doAfterSelectedStudent(item);
          }
        }
      });
    },
    // 选中学生
    doAfterSelectedStudent (item) {
      item.OtherStudent = [];
      item.CustomerInfo = {};
      this.studentInfo = item;
      this.studentID = item.OLAPKey;
      this.loadStudentInfo();
    },

    swiperInit () {
      let that = this;
      this.swiper = new Swiper('.sign_up_payment_swiper', {
        loop: false,
        observer: true,   // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,  // 修改swiper的父元素时，自动初始化swipe
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true
        },
        on: {
          transitionEnd: function () {
            if (this.activeIndex == 0) {
              // that.$nextTick(() => {
              //   that.swiper.updateSlides();
              //   that.swiper.slideTo(that.studentInfo.OtherStudent.length, 0);
              // });
            } if (this.activeIndex > that.studentInfo.OtherStudent.length) {
              that.$nextTick(() => {
                that.swiper.updateSlides();
                that.swiper.slideTo(1, 0);
              });
            } else {
              if (this.activeIndex > 0) {
                that.studentID = that.studentInfo.OtherStudent[this.activeIndex - 1].OLAPKey;
                that.$emit('onSelectionStudent', that.studentInfo.OtherStudent[this.activeIndex - 1]);
              }
            }
            this.scrollbar.updateSize();
          }
        }
      });
    },

    // 打开修改排课备注
    showAlterMessagePopup (data) {
      this.curShowStudentInfo = data;
      this.isShowEditArrangeSupport = true;
    },
    // 提交排课备注
    updateArrangeSupport (textContent) {
      EditArrangeSupport(this.curShowStudentInfo.OLAPKey, textContent).then(res => {
        this.curShowStudentInfo.ArrangeSupport = textContent;
      }, err => {
        layer.alert(err.msg);
      });
      this.closeEditArrangeSupport();
    },
    // 关闭排课备注
    closeEditArrangeSupport () {
      this.isShowEditArrangeSupport = false;
    },
    // 获取优惠券列表
    getCouponList (custKey) {
      getCouponPackage(custKey, 0).then(result => {
        console.log('getCouponPackage:', custKey, result.data);
        result.data.forEach(obj => {
          if (obj.status == 1) {
            if (Date.new(obj.time_start) > Date.new(this.$utils.formatDateToLine(Date.new())) || Date.new(obj.time_end) < Date.new(this.$utils.formatDateToLine(Date.new()))) { // 过期或者未开始 不可用
              this.$set(obj, 'unSelection', true);
            }
          } else {
            this.$set(obj, 'unSelection', true);
          }
        });
        this.couponList = result.data;
        this.$emit('setCouponListForCustomer', this.couponList);
      }, error => {
        console.log('getCouponPackage->error:' + error.msg);
      });
    },
    // 打开优惠券
    openMyCouponList () {
      this.isCouponListDialogShow = true;
    },
    // 关闭优惠券
    closeCouponListDialog () {
      this.isCouponListDialogShow = false;
    },
    reBack () {
      this.$emit('reBack');
    }
  }
};
</script>
