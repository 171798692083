<template>
  <div class="course_list_item">
    <rulesClassItem :rulesClassItem="item"
                    @showPupUp="showGradeClassPupUp"></rulesClassItem>

    <div class="course_list_right">
      <draggable v-model="vCombineStudentList"
                 :options="{group:{name: 'site',pull:'clone'},sort: false}"
                 animation="100"
                 dragClass="dragClass"
                 ghostClass="ghostClass"
                 chosenClass="chosenClass"
                 filter=".forbid"
                 handle=".mover"
                 v-bind="{ pull:'clone',sort: false, store: false }"
                 :move="onMove"
                 @start="onStart"
                 @end="onEnd"
                 @add="onAdd">
        <transition-group class="draggable"
                          :id="item.StructuredRuleKey+'_StructuredRuleKey'">
          <studentItem v-for="item in vCombineStudentList"
                       :key="item.StudentKey"
                       :stuItem="item"
                       :isTemporaryStu="isTemporaryStu"
                       :class="'mover'"
                       :isDrag="isDrag"
                       @setPopUpPosition="setPopUpPositionAfter"
                       @mouseOverEl="clearTimeOut"
                       @selectedStudent="selectedStudentAfter"></studentItem>
          <function-btn :item="addbtn"
                        :key="'新增'"
                        @clickItem="showAddStudentDialog"
                        :className="'list_add forbid'">
          </function-btn>
        </transition-group>

      </draggable>

      <div class="list_add no_bg"
           v-if="
          copyData.StructuredRuleKey != item.StructuredRuleKey &&
          copyData.copyStudentList.length > 0 &&
          !this.vIsSyncArranged
        "
           @click.stop="copyStudentInClass">
        粘贴学生
      </div>
      <div>
        <!-- 学生考勤操作-->
        <div class="
            course_student_attendance_operation
            module_content_menuList
            active
            stuOptionPupUp
          "
             :style="{
            left: pupupPositionLeft + 'px',
            top: pupupPositionTop + 'px',
          }"
             v-show="isShowDialog">
          <div class="top_bg"></div>
          <drop-down-box :optionJsonList="studentPopupMenu"
                         @clickOptionForAlertDialog="doAfterClickStudentPopup"></drop-down-box>
        </div>
      </div>
      <div>
        <!-- 课块操作-->
        <div class="
            course_student_attendance_operation
            module_content_menuList
            active
            courseOptionPupUp
          "
             :style="{
            left: pupupPositionLeft + 'px',
            top: pupupPositionTop + 'px',
          }"
             v-show="isShowCourseOptionDialog">
          <div class="top_bg"></div>
          <drop-down-box :optionJsonList="rolePopupMenu"
                         @clickOptionForAlertDialog="doAfterClickRolePopup"></drop-down-box>
        </div>
      </div>
      <!-- 调整上课时间 -->
      <custom-dialog title="调整上课时间"
                     class="student_info_dialog defult_dialog"
                     :visible.sync="isShowEditClassTimeDialog"
                     :before-close="closeEditClassTimeDialog">
        <edit-class-time :circleCourseInfo="seletedRuleItem"
                         :isShowCircleCourseItem="true"
                         @selectedItem="closeEditClassTimeDialog"
                         @closeDialog="closeEditClassTimeDialog">
        </edit-class-time>
      </custom-dialog>
      <!-- 调整上课时间 -->
      <custom-dialog title="调整老师"
                     class="student_info_dialog defult_dialog"
                     :visible.sync="isShowEditTeacherDialog"
                     :before-close="closeEditTeacherDialog">
        <edit-teacher :circleCourseInfo="seletedRuleItem"
                      :isShowCircleCourseItem="true"
                      @closeDilog="closeEditTeacherDialog"
                      @selectedItem="closeEditTeacherDialog">
        </edit-teacher>
      </custom-dialog>
      <!-- 显示加载的进度条 -->
      <custom-dialog class="progress_max progress"
                     width="300px"
                     :visible.sync="showProgress">
        <div class="progress_title">
          正在粘贴学生 ，{{ runCount }}/{{ totolPeoCount }}。
        </div>
        <div class="progress_box">
          <el-progress type="circle"
                       :percentage="progressStatus"></el-progress>
        </div>
      </custom-dialog>
      <!-- 添加学生弹框. -->
      <div>
        <student-for ref="studentsList"
                     :formTitle="studentTitle"
                     :classGradeID="item.GradeClassKey"
                     :selectCourseID="item.CourseNameKey"
                     @onAddStudent="addToCircleCourseForStudent"
                     @onDeleteStudent="deleteClick"></student-for>
      </div>
      <!-- <studentInfoPopUp ref="studentInfoPopUp"
                        v-show="showPopUp"
                        :vleft="positionLeft"
                        :vBottom="positionBottom"
                        :showPopUp="showPopUp"
                        @mouseOutEl="keepShow"
                        @mouseOverEl="closeShow"
                        :studentInfo="movestudentInfo"></studentInfoPopUp> -->
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import studentFor from '../../../common-module/students-list/index';
import studentItem from './student-item';
import rulesClassItem from './rules-class-item';
import studentInfoPopUp from './student-info-popup';
import dropDownBox from '../../../common/drop-down-box';
import editClassTime from '../../../relation-course-component/edit-circle-course-table/edit-class-time';
import editTeacher from '../../../relation-course-component/edit-circle-course-table/edit-teacher';
import { ArrangeStudent2MutiSameApplyClassCourseByRule, CancelStudent2MutiSameApplyClassCourseByRule, CountStructuredRuleSyncArranged, GradeCountStructuredRuleSyncArranged, StopNotEndStructuredRule, studentAddClass, AbortStructuredRule, BatchSyncSubmit } from '../../../../API/workbench';
export default {
  data () {
    return {
      positionLeft: 0,
      positionBottom: 0,
      pupupPositionLeft: 0,
      pupupPositionTop: 0,
      isShowDialog: false,  // 学生操作弹出框显示
      isShowCourseOptionDialog: false,      // 课操作弹出层显示.
      showPopUp: false,
      rulesStudentList: [],
      timer: '', // 延时器装载
      isMouseOnPopUp: false, // 判断是否移入了弹出层.
      studentInfo: {},    // 点击选中学生
      movestudentInfo: {},   // 移入选中学生
      seletedRuleItem: {},      // 选中循环规律课
      studentTitle: '',
      showProgress: false, // 显示批量添加的进度条
      totolPeoCount: 0,
      runCount: 0,
      isSecedeLoop: false,   // 是否退出循环课弹出层
      studentPopupMenu: [
        {
          type: this.$initJson.attendanceType.view,
          name: '查看档案',
          eventName: 'examine',
          className: '',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.del,
          name: '退出班级',
          eventName: 'deleteClick',
          className: 'course_student_del warning',
          isShow: false,
          ModulePowerKey: 51
        },
        {
          type: this.$initJson.attendanceType.add,
          name: '加入班级',
          eventName: 'add',
          className: 'course_student_del warning',
          isShow: false,
          ModulePowerKey: 51
        },
        {
          type: this.$initJson.attendanceType.secedeLoop,
          name: '退出循环课',
          eventName: 'secedeLoop',
          className: 'course_student_del warning',
          isShow: false,
          ModulePowerKey: 51
        },
        {
          type: this.$initJson.attendanceType.addLoop,
          name: '加入循环课',
          eventName: 'addLoop',
          className: 'course_student_del warning',
          isShow: false,
          ModulePowerKey: 51
        },
        {
          type: this.$initJson.attendanceType.delArrangeCourse,
          name: '退出安排的课',
          eventName: 'delArrangeCourse',
          className: 'course_student_del warning',
          isShow: false,
          ModulePowerKey: 51
        }

      ],
      rolePopupMenu: [
        { name: '循环课详情', eventName: 'detail', isShow: true, ModulePowerKey: 47 },
        { name: '修改课表', eventName: 'endCourseTable', isShow: true, ModulePowerKey: 47 },
        { name: '续建课表', eventName: 'continueNewCircleCourse', isShow: true, ModulePowerKey: 47 },
        { name: '结束循环课', eventName: 'endCircleCourse', isShow: true, ModulePowerKey: 47 },
        { name: '复制学生', eventName: 'copyStudent', isShow: false, ModulePowerKey: 51 }
      ],
      isShowEditClassTimeDialog: false, // 调整上课时间
      isShowEditTeacherDialog: false, // 调整老师
      addbtn: {
        name: '学生',
        type: '',
        ModulePowerKey: 51
      },
      isDrag: false
    };
  },
  components: {
    draggable, studentInfoPopUp, studentItem, rulesClassItem, dropDownBox, editClassTime, editTeacher, studentFor
  },
  props: {
    item: Object,
    studentList: {
      type: Array
    },
    searchText: String,
    isTemporaryStu: {
      type: Boolean,
      default: false
    },
    seletedStructuredRuleKey: {
      type: Number,
      default: 0
    },
    seletedStudentInfo: Object,
    copyData: {
      type: Object,
      default: {
        StructuredRuleKey: 0,
        copyStudentList: []
      }
    },
    RulesList: Array
  },
  created () {
    this.getRulesStuList();
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener(
      'click',
      e => {
        this.isShowDialog = false;
        this.isShowCourseOptionDialog = false;
        this.$store.commit('showAlerDialog', false);
      },
      true
    );
  },
  computed: {
    vCombineStudentList: {
      get: function () {
        let newArr = [];
        this.rulesStudentList.forEach(o => {
          let locaItem = this.studentList.find(p => {
            return o.StudentKey == p.StudentKey;
          });
          if (locaItem) {
            this.$set(o, 'FirstLetterName', locaItem.FirstLetterName || '');
            this.$set(o, 'IsInGradeClassKey', locaItem.IsInGradeClassKey);
            this.$set(o, 'IsInGradeClassKeyValue', locaItem.IsInGradeClassKeyValue);
            this.$set(o, 'DebtClassCount', locaItem.DebtClassCount);
            this.$set(o, 'CurrentCount', locaItem.CurrentCount);
            this.$set(o, 'CustomerPhoneName', this.$utils.phoneModulePower(locaItem.CustomerPhoneName) || '');
            this.$set(o, 'OLAPKey', locaItem.StudentKey);
            this.$set(o, 'MainDemoName', locaItem.StudentKeyValue || '');
            this.$set(o, 'YearCardMaxDayCount', Number(locaItem.YearCardMaxDayCount) || 0);
          } else {
            this.$set(o, 'FirstLetterName', o.StudentKeyValue || '');
            this.$set(o, 'IsInGradeClassKey', '0');
            this.$set(o, 'IsInGradeClassKeyValue', '否');
            this.$set(o, 'DebtClassCount', '0');
            this.$set(o, 'CurrentCount', '0');
            this.$set(o, 'CustomerPhoneName', this.$utils.phoneModulePower(o.CustomerPhoneName) || '');
            this.$set(o, 'OLAPKey', o.StudentKey);
            this.$set(o, 'MainDemoName', o.StudentKeyValue || '');
            this.$set(o, 'YearCardMaxDayCount', Number(o.YearCardMaxDayCount) || 0);
          }
          if (this.searchText != '' && (o.StudentKeyValue.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1 || o.FirstLetterName.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1 || o.CustomerPhoneName.indexOf(this.searchText) != -1)) {
            this.$set(o, 'isActive', true);
          } else {
            this.$set(o, 'isActive', false);
          }
          if (this.seletedStudentInfo && this.seletedStudentInfo.StudentKey == o.StudentKey) {
            this.$set(o, 'isActive', this.seletedStudentInfo.isActive);
          }
          if (this.isTemporaryStu) {
            this.$set(o, 'isTemporaryStu', true);
            newArr.push(o);
          } else {
            this.$set(o, 'isTemporaryStu', false);
            if (o.IsSyncArrangedKey == 1) {
              newArr.push(o);
            }
          }
        });
        return newArr;
      },
      // setter
      set: function (val) {
        this.rulesStudentList = val;
      }

    },
    vIsSyncArranged () {
      let locaIndex = this.rulesStudentList.find(o => {
        return o.IsSyncArrangedKey == 1;
      });
      return locaIndex;
    },
    //  进度条 百分比.
    progressStatus () {
      var percent = Math.floor(
        (this.runCount / this.totolPeoCount).toFixed(2) * 100
      );
      return percent || 0;
    }
  },
  methods: {
    onMove (e) {
      // 移入时判断是否可移入
      return this.$parent.draggRuleStudentMove(e);
    },
    // 开始拖拽事件
    onStart (e) {
      this.isDrag = true;
      this.draggableItem = this.vCombineStudentList[e.oldIndex];
      this.$emit('clearTimeOutAfter');
      this.$emit('draggRuleStudentStart', this.draggableItem, this.item);
    },
    onAdd (e) {
      this.isDrag = false;
      console.log(e.to, 'onAdd', e.from);
    },
    // 拖拽结束事件
    onEnd (e) {
      this.isDrag = false;
      this.$emit('draggRuleStudentEnd', e);
    },
    // 退出循环课父组件调用
    cancelArrangeByParent (draggableItem, callback) {
      CancelStudent2MutiSameApplyClassCourseByRule(this,draggableItem.StudentKey, this.item.StructuredRuleKey, 1).then(result => {
        if (callback) {
          callback();
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // //父组件选中班内学生高亮循环课块学生   已经用计算属性处理.
    // setRulesStudentListHighLight(item){
    //   console.log(item,'setRulesStudentListHighLight')
    //   this.rulesStudentList.forEach(o=>{
    //     if(o.StudentKey==item.StudentKey){
    //       o.isActive=item.isActive
    //     }else{
    //       o.isActive=false
    //     }
    //   })
    // },
    abnormalStatus () {
      if (this.studentInfo.IsInGradeClassKey == 0 || Number(this.studentInfo.DebtClassCount) > 0 || this.studentInfo.CurrentCount <= 0) {
        return true;
      } else {
        return false;
      }
    },
    // 移入学生设置位置偏移量
    setPopUpPositionAfter (el, item, left, top) {
      this.$emit('setPopUpPositionAfter', el, item, left, top, this.$el.offsetTop);
    },
    keepShow () {
      clearTimeout(this.timer);
      this.isMouseOnPopUp = true;
      this.showPopUp = true;
    },
    closeShow () {
      this.isMouseOnPopUp = false;
      this.showPopUp = false;
      this.movestudentInfo = {};
    },
    clearTimeOut () {
      // console.log("clearTimeOutAfter11")
      this.$emit('clearTimeOutAfter');
    },
    updateDataForParent (StructuredRuleKey) {
      this.getRulesStuList();
      // if(StructuredRuleKey&&StructuredRuleKey==this.item.StructuredRuleKey){
      //   this.getRulesStuList()
      // }else{
      //   // this.getRulesStuList()
      // }
    },
    getRulesStuList () {
      GradeCountStructuredRuleSyncArranged(this.item.StructuredRuleKey).then(result => {
        this.rulesStudentList = result.data;
        this.item.rulesStudentList = result.data;
      });
    },
    // 避免学生列表顺序因为添加出问题.
    updateRulesStuList () {
      if (this.item.StructuredRuleKey == this.seletedStructuredRuleKey) {
        GradeCountStructuredRuleSyncArranged(this.item.StructuredRuleKey).then(result => {
          let newArr = result.data.filter(o => {        // 查找出不同项.
            return !this.rulesStudentList.some(p => {
              return o.StudentKey === p.StudentKey;
            });
          });
          if (newArr.length > 0) {
            this.rulesStudentList = this.rulesStudentList.concat(newArr);
          } else {
            this.rulesStudentList = this.$utils.parseJson(result.data);
            this.item.rulesStudentList = this.$utils.parseJson(result.data);
          }
        });
      }
    },
    // 选中学员信息 根据选中学生状态显示及处理位移量问题.
    selectedStudentAfter (el, selectedStudentInfo, left, top) {
      this.studentInfo = selectedStudentInfo;
      console.log(selectedStudentInfo, 'selectedStudent', this.item);
      this.studentPopupMenu.forEach(obj => {
        if (obj.type == 0 || obj.type == 6 || obj.type == 11 || obj.type == 7 || obj.type == 13) {
          obj.isShow = true;
        }
        if (this.studentInfo.IsInGradeClassKey == 1) {    // 班内学生
          if (obj.type == 7) {    // 加入班级隐藏
            obj.isShow = false;
          }
        } else {
          if (obj.type == 6) {    // 退出班级隐藏
            obj.isShow = false;
          }
        }
        if (this.studentInfo.IsSyncArrangedKey == 1) {    // 是否循环课学生
          if (obj.type == 13) {
            obj.isShow = false;
          }
          if (obj.type == 12) {    // 加入循环课隐藏
            obj.isShow = false;
          }
          if (obj.type == 11) {    // 退出循环课隐藏
            obj.isShow = true;
          }
        } else {
          if (obj.type == 11) {    // 退出循环课隐藏
            obj.isShow = false;
          }
          if (obj.type == 12) {    // 加入循环课显示
            obj.isShow = true;
          }
        }
      });
      this.pupupPositionLeft = left + 225; // - 225;  // 30是边距.
      this.pupupPositionTop = top + 20;
      this.isShowDialog = true;
    },
    showGradeClassPupUp (el, ruleItem, left, top) {
      this.seletedRuleItem = ruleItem;
      this.pupupPositionLeft = left;  // 30是边距.
      this.pupupPositionTop = top;
      console.log(ruleItem, 'showGradeClassPupUp', this.vCombineStudentList);
      let locaIndex = this.vCombineStudentList.findIndex(o => {   // 决定是否显示复制学生按钮.
        return o.IsSyncArrangedKey == 1;
      });
      if (locaIndex >= 0) {
        this.rolePopupMenu[4].isShow = true;
      } else {
        this.rolePopupMenu[4].isShow = false;
      }
      let stopItem = this.rolePopupMenu.find(obj => { return obj.eventName == 'stopContinueNewCircleCourse'; });
      let continueItem = this.rolePopupMenu.find(obj => { return obj.eventName == 'continueNewCircleCourse'; });
      if (ruleItem.IsNotEndKey == 1) { // 不结束
        if (continueItem) {
          continueItem.name = '停止续课';
          continueItem.eventName = 'stopContinueNewCircleCourse';
        }
      } else {
        if (stopItem) {
          stopItem.name = '续建课表';
          stopItem.eventName = 'continueNewCircleCourse';
        }
      }
      this.isShowCourseOptionDialog = true;
    },
    // 循环课操作框选中
    doAfterClickRolePopup (menu) {
      console.log('doAfterSelectItemClick', this.seletedRuleItem);
      switch (menu.eventName) {
        case 'detail': // 循环课详情
          let item = {
            routerName: this.$route.name,
            routertitle: this.$route.meta.title,
            moduleName: 'circleCourseDetail',
            data: { ruleID: this.seletedRuleItem.StructuredRuleKey }
          };
          this.$dialog.open(this, item);
          break;
        case 'continueNewCircleCourse': // 续建课表
          let moduleInfo = {
            routerName: this.$route.name,
            routertitle: this.$route.meta.title,
            moduleName: 'continueNewCircleCourseTable',
            data: { ruleID: this.seletedRuleItem.StructuredRuleKey }
          };
          this.$dialog.open(this, moduleInfo);
          break;
        case 'stopContinueNewCircleCourse': // 停止续课
          layer.confirm('请确认是否停止续课?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.stopNotEndStructuredRule();
                  layer.close();
                }
              }
            ]
          });
          break;
        case 'endCourseTable': // 修改课表
          let courseParams = this.$utils.parseJson(this.seletedRuleItem);
          this.$dialog.open(this, { routerName: this.$route.name, routertitle: this.$route.meta.title, moduleName: 'editCircleCourseTable', data: { circleCourseInfo: courseParams } });
          break;
        case 'changeClassTime': // 调整上课时间
          this.isShowEditClassTimeDialog = true;
          break;
        case 'changeTeacher': // 调整老师
          this.isShowEditTeacherDialog = true;
          break;
        case 'endCircleCourse':// 结束循环课
          layer.confirm('请确认是否结束循环课?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  let IsProgressCourse = 0;// 是否终止进行中的课 0-否;1-是
                  this.abortStructuredRule(IsProgressCourse, 1);
                  layer.close();
                }
              }
            ]
          });
          break;
        case 'copyStudent': // 复制学生
          this.copyData.StructuredRuleKey = this.item.StructuredRuleKey;
          this.copyData.copyStudentList = [];
          this.vCombineStudentList.forEach(o => {
            if (o.IsSyncArrangedKey == 1) {
              this.copyData.copyStudentList.push(o);
            }
          });
          console.log('copyData', this.copyData);
          break;
        default:
          break;
      }
      this.isShowCourseOptionDialog = false;
    },
    // 学生操作框选中,
    doAfterClickStudentPopup (optionItem) {
      switch (optionItem.type) {
        case this.$initJson.attendanceType.view:
          // this.examine();
          this.goStudentFile();
          break;
        case this.$initJson.attendanceType.del:             // 退出班级
          this.cancelStudentFromClass();
          break;
        case this.$initJson.attendanceType.add:     // 加入班级
          this.addInClass();
          break;
        case this.$initJson.attendanceType.secedeLoop:      // 退出循环课
          console.log('退出循环课');
          this.cancelAllArrangeClick();
          break;
        case this.$initJson.attendanceType.delArrangeCourse:       // 退出循环课
          this.cancelAllArrangeClick();
          break;
        case this.$initJson.attendanceType.addLoop:       // 退出循环课
          this.addToCircleCourseForStudent(this.studentInfo);
          break;
        default:
          break;
      }
      this.isShowDialog = false;
      // this.$store.commit('showAlerDialog', false);
    },

    // 去在校学生
    goInSchoolStudent () {
      let moduleInfo = {
        name: '学生管理',
        routertitle: '学生管理',
        routerName: 'studentManagementReport',
        moduleName: '',
        dataKey: 0
      };
      this.$bus.emit('needOpenRouter', moduleInfo);
    },
    // 打开学生档案.
    goStudentFile () {
      let moduleInfo = {
        name: '学生档案',
        routerName: this.$route.name,
        routertitle: this.$route.meta.title,
        moduleName: 'studentFile',
        data: { studentID: this.studentInfo.StudentKey }
      };
      this.$dialog.open(this, moduleInfo);
    },
    spliceArray (List) {
      let locaIndex = List.findIndex(o => {
        return o.StudentKey == this.studentInfo.StudentKey;
      });
      if (locaIndex >= 0) {
        List.splice(locaIndex, 1);
      }
    },
    // 取消循环课
    cancelAllArrangeClick () {
      layer.confirm('是否安排' + this.studentInfo.StudentKeyValue + '退出循环课', {
        shadeClose: false,
        btn: [
          {
            name: '确认',
            callBack: () => {
              this.isSecedeLoop = true;
              CancelStudent2MutiSameApplyClassCourseByRule(this,this.studentInfo.StudentKey, this.item.StructuredRuleKey, 1).then(result => {
                this.spliceArray(this.studentList);
                this.spliceArray(this.rulesStudentList);
                this.$nextTick(o => {
                  this.isSecedeLoop = false;
                });
                this.$bus.emit('AfterCancelApplyClassCourseByRule', this.item.StructuredRuleKey, this.item);
              }, error => {
                layer.alert(error.msg);
              });
              layer.close();
            }

          }
        ]
      });
    },
    // 退出班级
    cancelStudentFromClass () {
      let moduleInfo = {
        moduleName: 'quitOrEnterClassForm',
        name: '学生退班',
        routerName: this.$route.name,
        routertitle: '',
        data: {
          dataInfo: { studentID: this.studentInfo.StudentKey, classNameID: this.item.GradeClassKey, classTitle: '退班' }
        },
        callBack: {
          afterSucces: () => {
            this.studentInfo.IsInGradeClassKey = 0;
            this.studentInfo.IsInGradeClassKeyValue = '无';
            this.studentList.forEach(o => {
              if (o.StudentKey == this.studentInfo.StudentKey) {
                o.IsInGradeClassKey = 0;
                o.IsInGradeClassKeyValue = '无';
              }
            });
            this.$bus.emit('afterQuitOrAddClass', this.item.GradeClassKey, this.studentInfo.StudentKey, 'quit');
          }
        }
      };
      this.$bus.emit('needOpenRouter', moduleInfo);
    },
    // 加入班级
    addInClass () {
      studentAddClass(this.studentInfo.StudentKey, this.item.GradeClassKey).then(result => {
        this.$bus.emit('afterQuitOrAddClass', this.item.GradeClassKey, this.studentInfo.StudentKey);
        layer.alert('加入班级成功');
        this.studentInfo.IsInGradeClassKey = 1;
        this.studentInfo.IsInGradeClassKeyValue = '有';
        this.studentList.forEach(o => {
          if (o.StudentKey == this.studentInfo.StudentKey) {
            o.IsInGradeClassKey = 1;
            o.IsInGradeClassKeyValue = '有';
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 关闭 调整上课时间
    closeEditClassTimeDialog () {
      this.$bus.emit('AfterEditCircleCourse', this.item.StructuredRuleKey, this.item);
      this.isShowEditClassTimeDialog = false;
    },
    // 关闭 调整上课时间
    closeEditTeacherDialog () {
      this.$bus.emit('AfterEditCircleCourse', this.item.StructuredRuleKey, this.item);
      this.isShowEditTeacherDialog = false;
    },
    // 停止续课
    stopNotEndStructuredRule () {
      StopNotEndStructuredRule(this.seletedRuleItem.StructuredRuleKey).then(result => {
        layer.alert('停止续课成功');
        this.seletedRuleItem.IsNotEndKey = 0;
        this.seletedRuleItem.IsNotEndKeyValue = '否';
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 结束循环课
    abortStructuredRule (IsProgressCourse, IsNotEndKey) {
      if (this.seletedRuleItem.StructuredRuleKey) {
        AbortStructuredRule(this.seletedRuleItem.StructuredRuleKey, IsProgressCourse, '', '', IsNotEndKey).then(result => {
          console.log('结束循环课成功返回:' + result.data);
          this.$bus.emit('AfterEditCircleCourse', this.item.StructuredRuleKey, this.item);
        }, error => {
          if (error.code == 10000) {
            layer.alert(error.msg);
          } else if (error.code == 60104) {
            layer.confirm('有进行中的课，是否继续结束循环课?', {
              btn: [
                {
                  name: '确认',
                  callBack: () => {
                    let IsProgressCourse = 1;// 是否终止进行中的课 0-否;1-是
                    this.abortStructuredRule(IsProgressCourse, IsNotEndKey);
                    layer.close();
                  }
                }
              ]
            });
          } else if (error.code == 70000) {
            layer.confirm(error.msg, {
              btn: [
                {
                  name: '确认',
                  callBack: () => {
                    this.abortStructuredRule(IsProgressCourse, 0);
                    layer.close();
                  }
                }
              ]
            });
          } else {
            layer.alert(error.msg);
          }
        });
      }
    },
    // 添加学生弹出层 isStudentAttendance
    showAddStudentDialog () {
      this.$refs.studentsList.isShowAddStudent = true;
      this.studentTitle = '安排学生上课';
      this.$nextTick(() => {
        this.$refs.studentsList.studenSearchText = '';
        this.$refs.studentsList.pushDataStudentList(this.vCombineStudentList);
        this.$refs.studentsList.getStudentList();
      });
    },
    // 选中学生.
    doAfterSelectedStudent (item, callBack) {

    },
    // 通用版 安排进循环课
    addToCircleCourseForStudent (item, callBack) {
      console.log(item, 'this.addToCircleCourseForStudent', this.item);
      let params = {
        StudentKey: item.OLAPKey,
        StructuredRuleKey: this.item.StructuredRuleKey,
        StartTime: this.$utils.formatDateToLine(Date.new()),
        EndTime: '',
        ClassCount: 0,
        IsClassHourLackKey: 1,
        IsSyncArrangedKey: 1, // 是否循环跟排 0-否;1-是
        ScheduleCourseKeyList: [] // 排出数组 无则空数组
      };
      if (item.IsConfirmKey == 0) { // switchItem 这里可做开关，1则关闭验证提示
        layer.alert('该学生待验证确认，请先去学生档案中进行确认操作。');
      } else {
        ArrangeStudent2MutiSameApplyClassCourseByRule(params)
          .then(result => {
            // this.$emit('updateClassStudentList', item.OLAPKey);
            this.studentInfo.IsSyncArrangedKey = 1;
            this.$bus.emit('AfterCancelApplyClassCourseByRule', this.item.StructuredRuleKey, this.item);
            this.$refs.studentsList.addDataStudentList(item);
            // if (callBack) {
            //   callBack();
            // }
          })
          .catch(error => {
            // if (callBack) {
            //   callBack();
            // }
            this.$refs.studentsList.addDataStudentList(item);
            layer.alert(error.msg);
          });
      }
    },
    // 删除
    deleteClick (data, index, callBack) {
      // 调用删除接口.
      CancelStudent2MutiSameApplyClassCourseByRule(this,data.OLAPKey, this.item.StructuredRuleKey, 1).then(result => {
        this.$bus.emit('AfterCancelApplyClassCourseByRule', this.item.StructuredRuleKey, this.item);
        if (callBack) {
          callBack();
        }
      }).catch(error => {
        if (callBack) {
          callBack();
        }
        console.log(error, 'err');
      });
    },
    // 复制学生
    copyStudentInClass () {
      console.log(this.copyData, 'copyStudentInClass');
      // this.copyData.StructuredRuleKey = 0;
      // this.copyData.copyStudentList = [];
      // this.$emit('copyStudentInClass');
      this.totolPeoCount = this.copyData.copyStudentList.length;
      this.showProgress = true;
      this.runCount = 0;
      this.banchAddCircleCourseForStudent(this.copyData.copyStudentList, 0, () => {
        this.copyData.StructuredRuleKey = 0;
        this.copyData.copyStudentList = [];
        this.showProgress = false;
        this.$bus.emit('AfterCancelApplyClassCourseByRule', this.item.StructuredRuleKey, this.item);
      });
    },
    banchAddCircleCourseForStudent (studentList, index, callBack) {
      // console.log(item, 'this.addToCircleCourseForStudent', this.item);
      console.log(index, 'indexindexindex');
      let params = {
        StudentKey: studentList[index].OLAPKey,
        StructuredRuleKey: this.item.StructuredRuleKey,
        StartTime: this.$utils.formatDateToLine(Date.new()),
        EndTime: '',
        ClassCount: 0,
        IsClassHourLackKey: 1,
        IsSyncArrangedKey: 1, // 是否循环跟排 0-否;1-是
        ScheduleCourseKeyList: [] // 排出数组 无则空数组
      };
      ArrangeStudent2MutiSameApplyClassCourseByRule(params)
        .then(result => {
          if (index < studentList.length - 1) {
            this.runCount++;
            this.banchAddCircleCourseForStudent(studentList, index + 1, callBack);
          } else {
            this.runCount++;
            if (callBack) {
              callBack();
            }
          }
        })
        .catch(error => {
          console.log(error, 'error');
          if (index < studentList.length - 1) {
            this.runCount++;
            this.banchAddCircleCourseForStudent(studentList, index + 1, callBack);
          } else {
            this.runCount++;
            if (callBack) {
              callBack();
              // layer.alert(error.msg);
            }
          }
        });
    }

  }
};
</script>
