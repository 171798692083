<template>
  <!-- 环境展示图片集 -->
  <div class="WKT_slideshow_list"
       @mouseleave="mouseOutNewCourseTable"
       @mouseenter="mouseOverNewCourseTable">
    <div class="WKT_slideshow_img hover_border_blue">
      <img :src="vShowImg"
           :onerror="vDefaultImg"
           alt="">
      <!-- <div class="WKT_slideshow_img_text">活动名称显示在这里</div> -->
      <div class="WKT_contact_info_shade"
           v-if="isShowChangeImage">
        更换图片
        <input type="file"
               title=""
               name="file"
               accept="image/*"
               class="button my_view_chg_index"
               @change="doChangeImageItemClick($event)"
               style="width:100%;height:100%;opacity:0;position: absolute;top: 0;left: 0;"
               data-type="userIconImg">
      </div>
    </div>
    <operation-btn :item="Item"
                   :className="'WKT_slideshow_btn_box'"
                   :isShowEditBtn="false"
                   :isShowRemoveBtn="true"
                   :dataList="dataList"
                   @promote="promote"
                   @decline="decline"
                   @remove="remove"></operation-btn>
  </div>
</template>

<script>
import operationBtn from '../common/operation-btn';
import { DeleteCorporateImage, MoveCorporateImage } from '../../../API/workbench';
export default {
  props: {
    Item: Object,
    dataList: Array,
    Index: Number
  },
  components: { operationBtn },
  data () {
    return {
      getSearchPopularRecommendList: [],
      isShowChangeImage: false
    };
  },
  created () {
    console.log(this.dataList, 'dataList-123');
  },
  watch: {

  },
  computed: {
    // 环境展示图片
    vShowImg () {
      if (this.Item && this.Item.imgUrl) {
        if (this.Item.imgUrl.indexOf('http') > -1) {
          return this.Item.imgUrl;
        } else {
          return this.$store.getters.CDNURL + this.Item.imgUrl;
        }
      } else {
        return '';
      }
    },
    // 默认显示图
    vDefaultImg () {

    }
  },
  methods: {
    // 排序
    setMoveContextDiagram (id, indedx) {
      MoveCorporateImage(id, indedx).then(result => {
        // this.$emit('afterMoveCombo');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 上升
    promote () {
      let Indedx = this.Index;
      Indedx = (Indedx == 0 ? 1 : Indedx);
      this.setMoveContextDiagram(this.Item.id, Indedx);
    },
    // 下降
    decline () {
      let Indedx = this.Index + 3;
      this.setMoveContextDiagram(this.Item.id, Indedx);
    },
    // 鼠标移入
    mouseOverNewCourseTable () {
      this.isShowChangeImage = true;
    },
    // 鼠标离开
    mouseOutNewCourseTable () {
      this.isShowChangeImage = false;
    },
    // 确定修改
    doChangeImageItemClick (event) {
      this.$emit('doChangeImageItemClick', event, this.Item);
    },
    // 删除
    remove () {
      console.log('删除');
      layer.confirm('是否需要删除该活动?', {
        btn: [{
          name: '确认',
          callBack: () => {
            DeleteCorporateImage(this.Item.id).then(result => {
              // this.dataList.splice(this.Index, 1);
              this.$emit('delCorporateImg');
            }, error => {
              layer.alert(error.msg);
            });
            layer.close();
          }
        }
        ]
      });
    }
  }
};
</script>

<style>
</style>