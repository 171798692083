<template>
  <div class="mainbox">
    <!-- 排课与课消汇总 -->
    <schedule-summary></schedule-summary>
    <!-- 排课饱和率 -->
    <saturation-rate></saturation-rate>
    <!-- 透视排课不足学生 -->
    <schedule-student></schedule-student>
    <!-- 透视出勤不足学生 -->
    <attendance-student></attendance-student>
    <!-- 透视出勤不足班级 -->
    <attendance-class></attendance-class>
    <!-- 合班可行性分析 -->
    <merge-feasibility></merge-feasibility>
    <!-- 设置 -->
    <dialog-factory ref="schedulePotentialReportDialogFactory"
                    :key="'schedulePotentialReport'"
                    :routerName="'schedulePotentialReport'"></dialog-factory>
    <dialog-factory-common ref="schedulePotentialReportDialogFactoryCommon"></dialog-factory-common>
  </div>
</template>

<script>
import scheduleSummary from './schedule-summary';
import saturationRate from './saturation-rate';
import scheduleStudent from './schedule-student';
import attendanceStudent from './attendance-student';
import attendanceClass from './attendance-class';
import mergeFeasibility from './merge-feasibility';
import dialogFactory from '../../common/dialog-factory';
export default {
  name: 'schedulePotentialReport',
  data () {
    return {
    };
  },
  components: {
    scheduleSummary,
    saturationRate,
    scheduleStudent,
    attendanceStudent,
    attendanceClass,
    mergeFeasibility,
    dialogFactory
  },
  created () {
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'schedulePotentialReport') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'schedulePotentialReport') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });
  },
  methods: {
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.schedulePotentialReportDialogFactory) {
        this.$refs.schedulePotentialReportDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.schedulePotentialReportDialogFactoryCommon) {
        this.$refs.schedulePotentialReportDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>

<style scoped>
.mainbox {
  padding: 20px 0;
}
.mainbox .modulebox {
  width: 1300px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 30px 20px 20px;
  margin: 0 auto 20px;
}
.mainbox .modulebox >>> .chartbox {
  position: relative;
  float: left;
  width: 360px;
}
.mainbox .modulebox >>> .chartbox .tit {
  height: 30px;
  line-height: 30px;
  font-size: 20px;
  font-weight: bold;
}
.mainbox .modulebox >>> .chartbox .tit span {
  font-size: 14px;
  font-weight: 400;
  color: #3498db;
  cursor: pointer;
  margin-left: 10px;
}
.mainbox .modulebox >>> .chartbox .echartbox {
  height: 360px;
}
.mainbox .modulebox >>> .listbox {
  float: left;
  width: 900px;
}
.mainbox .modulebox >>> .listbox .roomlist .block {
  float: left;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #f5f7fa;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 5px 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  min-height: 65px;
}
.mainbox .modulebox >>> .listbox .roomlist .block div {
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  color: #666666;
}
.mainbox .modulebox >>> .listbox .roomlist .block p {
  height: 30px;
  line-height: 30px;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}
.mainbox .modulebox >>> .listbox .roomlist .block.cur {
  border: 1px solid #3498db;
  padding: 4px 19px;
}
.mainbox .modulebox >>> .listbox .roomlist .block.cur div {
  color: #3498db;
}
.mainbox .modulebox >>> .listbox .roomlist .block.cur p {
  color: #3498db;
}
.mainbox .modulebox >>> .listbox .formbox {
  position: relative;
  padding: 10px 0;
}
.mainbox .modulebox >>> .listbox .formbox .text {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  color: #666666;
  margin-right: 10px;
}
.mainbox .modulebox >>> .listbox .formbox .el-select {
  display: inline-block;
  width: 300px;
  height: 36px;
  margin-right: 20px;
}
.mainbox .modulebox >>> .listbox .formbox .el-select input.el-input__inner {
  font-size: 14px;
  border: 1px solid #ececec;
  border-radius: 4px;
}
.mainbox .modulebox >>> .listbox .formbox .tip {
  display: inline-block;
  line-height: 36px;
  font-size: 12px;
  color: #999999;
}
.mainbox .modulebox >>> .listbox .formbox .tip span {
  color: #ff6c67;
  margin-right: 10px;
}
.mainbox .modulebox >>> .listbox .formbox > .el-input {
  display: inline-block;
  width: 300px;
  height: 36px;
  margin-right: 20px;
}
.mainbox .modulebox >>> .listbox .formbox .btn {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 10px;
  cursor: pointer;
}
.mainbox .modulebox >>> .listbox .formbox .btn.btn_1 {
  color: #ffffff;
  background-color: #2d98d9;
  border: 1px solid #2886bf;
  padding: 0 20px;
}
.mainbox .modulebox >>> .listbox .formbox .btn.btn_2 {
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #ececec;
  padding: 0 10px;
}
.mainbox .modulebox >>> .listbox .formbox .btn.btn_3 {
  position: absolute;
  color: #ffffff;
  background-color: #2d98d9;
  border: 1px solid #2886bf;
  padding: 0 20px;
  margin: 0;
  right: 0;
  top: 10px;
}
.mainbox .modulebox >>> .listbox .course_statistics_table_box {
  padding: 0;
}
</style>