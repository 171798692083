<template>
	<div>
		<div class="homerwork_details_date">
			{{vDateTime}}
		</div>
		<div class="info_name">
			{{dataInfo.ClassTimeName}} {{dataInfo.CourseNameKeyValue}} 
		</div>
		<div class="info_text">
			<span class="info_text_left">{{dataInfo.MTeacherKeyValue}}</span> | <span class="info_text_right">{{dataInfo.GradeClassKeyValue}}</span>
		</div>
	</div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object
  },
  computed: {
    vDateTime () {
      const date = this.$utils.formatDateToYYYYMMDD(this.dataInfo.ClassTime).slice(5, 11);
      const week = this.$utils.getWeek(this.$utils.formatDateToLine(this.dataInfo.ClassTime));
      return date + ' ' + week;
    }
  },
  methods: {

  }
};
</script>

<style>

</style>
