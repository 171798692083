<template>
  <div>
    <div class="form_info_detail form_info_serach_box">
      <div class="student_info_supplement form_info">
        <input-search-immediately v-model="searchText"
                                  :placeholder="'请输入...'"
                                  :styleText="'right: 45px;top: 35px;'"
                                  @clearSearch="clearSearText"></input-search-immediately>
      </div>
      <!-- 搜索结果 -->
      <div class="search_result">
        <div class="search_result_content search_result_content_for_student">
          <div class="search_result_list"
               style="height: 300px;">
            <div v-for="item in vDataSourceLsit"
                 :key="item.OLAPKey">
              <div class="single_result single_class_info"
                   @click.stop="selectedItem(item)"
                   :class="{active:item.isSelected}">
                <div class="single_result_info_detail">
                  <div class="single_class_name">
                    <span>{{item.MainDemoName}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="salesList.length>0"
                 class="btn_fixed_bottom_placeholder"></div>
            <div v-if="vDataSourceLsit.length == 0">
              <div class="has_arrange_course">
                <div class="has_arrange_course_content"
                     style="padding:0">
                  <div class="has_arrange_course_list">
                    <div class="has_arrange_course_nodata single_shadow">
                      <span>亲，当前没有任何数据哦~</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 按钮组 -->
          <div>
            <save-cancel-btn-group :isSingleSubmit="true"
                                   :singleSubmitType="'static'"
                                   @confirmClick="confirmClick"
                                   @cancelClick="cancelClick"></save-cancel-btn-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetSaleList, AddRecruitStudentSale, StudentThreadAllotSale, MessageSearchPageForEmployee } from '../../../API/workbench';
export default {
  data () {
    return {
      searchText: '',
      salesItem: {},
      salesList: []
    };
  },
  props: {
    courseList: Array,
    isBatchAllocation: {// 分配销售
      type: Boolean,
      default: false
    },
    isStaffSale: {// 搜索框水印
      type: Boolean,
      default: false
    },
    selectedKey: [String, Number], // 选中项key
    OLAPKey: [String, Number], // 线索Key
    placeholder: {// 搜索框水印
      type: String,
      default: '请输入...'
    }

  },
  created () {
    console.log(this.courseList, 'courseList456');
    if (this.isStaffSale) {
      this.getMessageSearchPageForEmployee();
    } else {
      this.getSalesList();
    }
  },
  watch: {
  },
  methods: {
    // 在职员工
    getMessageSearchPageForEmployee () {
      let obj = {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号、首字母
        orderBy: 'IsLeaveKey', //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为姓名
        sequence: 'DESC', //	字符串	可选	ASC	数据源：GET	为空则默认顺序; ASC正序;DESC倒序
        IsLeaveKey: 1 //	字符串	可选		数据源：GET	是否在职;空表示该条件不生效;0-离职;1-在职
      };
      MessageSearchPageForEmployee(obj).then(
        (result) => {
          this.salesList = result.data.PageDataList;
          this.isShowsalesList = true;
          this.initSelectedItem();
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    // 销售
    getSalesList () {
      GetSaleList().then(result => {
        this.salesList = result.data;
        this.isShowsalesList = true;
        this.initSelectedItem();
      }, error => {
        layer.alert(error.msg);
      });
    },
    initSelectedItem () {
      console.log(this.selectedKey, this.salesList, '选中一项');
      this.salesList.forEach(obj => {
        if (obj.OLAPKey == this.selectedKey) {
          this.salesItem = obj;
          console.log(obj, 'obj1');
          this.$set(obj, 'isSelected', true);
        } else {
          this.$set(obj, 'isSelected', false);
        }
      });
    },
    // 选中一项
    selectedItem (item) {
      console.log(item, this.salesList, '选中一项');
      this.salesList.forEach(o => {
        if (o.OLAPKey == item.OLAPKey) {
          this.$set(o, 'isSelected', true);
        } else {
          this.$set(o, 'isSelected', false);
        }
      });
      this.salesItem = item;
    },
    // 确定
    confirmClick () {
      console.log(this.selectedKey, this.OLAPKey, this.salesItem.OLAPKey, this.salesItem.MainDemoName, '1');
      if (this.salesItem.OLAPKey) {
        if (this.isBatchAllocation) {
          // 修改

          if (this.OLAPKey) {
            layer.confirm('确定分配为该销售?', {
              btn: [{
                name: '确认',
                callBack: () => {
                  layer.close();
                  StudentThreadAllotSale(this.OLAPKey, this.salesItem.OLAPKey, this.salesItem.MainDemoName).then(result => {
                    layer.alert('分配成功');
                    this.$emit('onSelectedItem', false);
                  }, error => {
                    layer.alert(error.msg);
                  });
                }
              }
              ]
            });
          } else {
            // 批量分配
            layer.confirm('确定批量分配为该销售?', {
              btn: [{
                name: '确认',
                callBack: () => {
                  layer.close();
                  this.courseList.forEach(o => {
                    console.log(o, 'o123');
                    StudentThreadAllotSale(o.OLAPKey, this.salesItem.OLAPKey, this.salesItem.MainDemoName).then(result => {
                      layer.alert('分配成功');
                      this.$emit('onSelectedItem', true);
                    }, error => {
                      layer.alert(error.msg);
                    });
                  });
                }
              }
              ]
            });
          }
        } else {
          // 添加
          layer.confirm('是否把该员工添加为销售?', {
            btn: [{
              name: '确认',
              callBack: () => {
                layer.close();
                AddRecruitStudentSale(this.salesItem.OLAPKey).then(result => {
                  layer.alert('新增成功');
                  this.$emit('onSelectedItem', this.salesItem);
                }, error => {
                  layer.alert(error.msg);
                });
              }
            }
            ]
          });
        }
      } else {
        layer.alert('请选择分配的销售');
      }
    },
    cancelClick () {
      this.$emit('closeForm');
    },
    clearSearText () {
      this.searchText = '';
    }
  },
  computed: {
    vDataSourceLsit () {
      let newArr = [];
      if (this.searchText) {
        this.salesList.forEach(obj => {
          if (obj.MainDemoName.indexOf(this.searchText) >= 0) {
            newArr.push(obj);
          }
        });
      } else {
        newArr = this.salesList;
      }
      return newArr;
    }
  }
};
</script>
