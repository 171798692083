<template>
  <!-- 已下架的优惠券 -->
  <div>
    <module-description :textTips="'直接发送优惠券至学员账户内，无需领取。'"
                        style="padding-right：0"></module-description>
    <div class="WKT_discount_coupon_send">
      <div class="WKT_discount_coupon_send_title">
        接收对象
      </div>
      <div>
        <div class="WKT_discount_coupon_send_list"
             v-for="item in sendTypeList"
             :key="item.key">
          <!-- :class="{'opt':item.isOpt}" -->
          <div class="list_name"
               @click="doSelectStudentClick(item)">
            {{item.name}}
          </div>
          <div class="list_text">
            已选择
            <span class="font_black">{{item.studentList.length}}</span>人
          </div>
        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="submit"
                           @cancelClick="cancel"></save-cancel-btn-group>

    <custom-dialog :title="sendTypeData.name"
                   width="815px"
                   :top="30"
                   :visible.sync="isShowStudentList"
                   :before-close="closeStudentListDialog">
      <sand-student-list :type="sendTypeData.key"
                         :checkBoxList="sendTypeData.studentList"
                         @submit="submitStudent"
                         @cancel="cancelStudent"></sand-student-list>
    </custom-dialog>
  </div>
</template>
<script>

import sandStudentList from './sand-student-list';
import { SendCouponToCustomer } from '../../../../../API/workbench';
export default {
  components: { sandStudentList },
  data () {
    return {
      sendTypeList: [
        { name: '在校学生', studentList: [], key: 1, isOpt: false },
        { name: '潜在学生', studentList: [], key: 2, isOpt: false },
        { name: '离校学生', studentList: [], key: 3, isOpt: false }
      ],
      studentList: [], // 学生列表
      sendTypeData: {},
      isShowStudentList: false
    };
  },
  props: {
    couponInfo: Object // 券信息
  },
  created () {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    // 选择学生
    doSelectStudentClick (item) {
      this.sendTypeList.forEach(o => {
        if (item.key == o.key) {
          o.isOpt = true;
        } else {
          o.isOpt = false;
        }
      });
      this.sendTypeData = item;
      this.isShowStudentList = true;
      console.log(this.sendTypeData, '选择学生');
    },
    // 选择学生
    submitStudent (list, type) {
      console.log(list, type, 'list, type');
      this.sendTypeList.forEach(o => {
        if (type == o.key) {
          o.studentList = list;
        }
      });
      this.cancelStudent();
    },
    // 取消选择学生
    cancelStudent () {
      this.isShowStudentList = false;
    },
    // 发送
    submit (callBack) {
      let list = [];
      // 收集学生
      this.sendTypeList[0].studentList.forEach(o => {
        list.push({ StudnetKey: o.OLAPKey, CusKey: o.CustKey });
      });
      this.sendTypeList[1].studentList.forEach(o => {
        list.push({ StudnetKey: o.OLAPKey, CusKey: o.CustKey });
      });
      this.sendTypeList[2].studentList.forEach(o => {
        list.push({ StudnetKey: o.OLAPKey, CusKey: o.CustKey });
      });
      console.log(list, '发送的学生');
      let flag = false;
      if (list.length == 0) {
        layer.alert('接收对象不能为空');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      SendCouponToCustomer(this.couponInfo.id, list).then(result => {
        layer.alert('发送成功');
        this.$emit('afterSuccess', result.data);
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 取消发送
    cancel () {
      this.$emit('cancel');
    },
    // 关闭学生列表
    closeStudentListDialog () {
      this.isShowStudentList = false;
      this.sendTypeList.forEach(o => { o.isOpt = false; });
    }
  }
};
</script>

