
  <template>
  <div>
    <div class="searchBar_content content_marginTop_8 single_shadow">
      <div class="search_type">转入学生</div>
      <div @click.stop="searchClick()" class="search_content">
        <span >请搜索</span>
      </div>
    </div>
  </div>
</template>

  <script>
// 选择框 作为公共组件
export default {
  // name: 'searchBox',
  data () {
    return {
      searchFormDialogVisible: true,
      searchResultSingleDialogVisible: true
    };
  },
  props: ['searchInitJson'],
  created () {
  },
  methods: {
    // 点击搜索 通知父组件
    searchClick () {
      this.$emit('searchClick');
    }
  }
};
</script>

  <style>
</style>

