<template>
  <!-- 出入库记录列表 -->
  <div class=" table_list_content">
    <div class="single_result_detail">
      <!-- 已选条件 -->
      <div class="course_statistics_table_search flex course_statistics_title">
        <div class="course_statistics_search record-table">
          <!-- 日期 -->
          <date-range ref="monthPicker"
                      :fromTitle="'选择日期'"
                      :searchObj="paramsInfo"
                      @changeDate="changeSaveDate"></date-range>
          <div class="table_select_box">
            <div class="table_select_title">类型</div>
            <el-select :value="vTypeValue"
                       filterable
                       placeholder="选填"
                       @change="chgOptionsTypeValue"
                       value-key="value">
              <el-option v-for="item in optionsTypeList"
                         :key="item.value"
                         :label="item.label"
                         :value="item"></el-option>
            </el-select>
          </div>
          <div class="table_select_box">
            <div class="table_select_title">物品类型</div>
            <el-select :value="vgoodsTypeValue"
                       filterable
                       placeholder="选填"
                       @change="chgGoodsTypeValue"
                       value-key="OLAPKey">
              <el-option v-for="item in goodsTypeList"
                         :key="item.OLAPKey"
                         :label="item.MainDemoName"
                         :value="item"></el-option>
            </el-select>
          </div>
          <div>
            <input-search-contain-btn v-model="searchText"
                                      :placeholder="'请输入'"
                                      @clearSearch="clearExtendSearchParams"
                                      @onSearchEvent="searchGoodsText"></input-search-contain-btn>
          </div>
          <div class="mendian_backstage_search">
            <function-btn :item="{ name: '导出', nameValue: 'export', ModulePowerKey: '' }"
                          :className="'btn_hover_bg_blue search_btn'"
                          @clickItem="doExportInfoClick">
            </function-btn>
          </div>
        </div>
      </div>
      <table-list ref="recordTable"
                  :tableData="tableData"
                  :tableColumns="tableColumns"
                  :rowKey="rowKey"
                  :totalNum="totalNum"
                  :defaultSort="defaultSort"
                  :queryParams="paramsInfo"
                  :footerContent="'共'+totalNum+'条数据'"
                  @loadTableData="loadTableData"></table-list>

    </div>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import { GetGoodsTypeList } from '../../../../API/workbench.js';
export default {
  data () {
    return {
      searchText: '',
      goodsTypeList: [], // 物品类型
      optionsTypeList: [ //  0-全部;1-入库;2-出库;3-调增;4-调减;5-售出;6-退货
        { label: '全部', value: 0 },
        { label: '入库', value: 1 },
        { label: '出库', value: 2 },
        { label: '调增', value: 3 },
        { label: '调减', value: 4 },
        { label: '售出', value: 5 },
        { label: '退货', value: 6 }
      ]
    };
  },
  props: {
    tableData: Array, // 列表数据
    tableColumns: Array, // table column
    rowKey: [String, Number], // 显示详情对应绑定的ID
    totalNum: Number, // 分页总共条数
    paramsInfo: Object,
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: '', order: 'ascending' };
      }
    }
  },
  components: {
    tableList,
    inputSearchContainBtn
  },
  created () {
    this.searchText = this.paramsInfo.searchText;
  },
  mounted () {
    this.getGoodsTypeList();
  },
  computed: {
    vTypeValue () {
      return this.paramsInfo.TypeKey > 0 ? this.paramsInfo.TypeKeyValue : '全部';// 类型
    },
    vgoodsTypeValue () {
      return this.paramsInfo.GoodsTypeKey > 2 ? this.paramsInfo.GoodsTypeKeyValue : '全部';// 物品类型
    }
  },
  methods: {
    // 导出
    doExportInfoClick () {
      this.$emit('doExportInfoClick');
    },
    // 搜索
    searchGoodsText () {
      this.paramsInfo.searchText = this.searchText;
      this.paramsInfo.pageIndex = 0;
      this.$emit('chgSearchValue', this.paramsInfo);
    },
    // 清除
    clearExtendSearchParams () {
      this.searchText = '';
      this.paramsInfo.searchText = this.searchText;
      this.paramsInfo.GoodsTypeKey = '';
      this.paramsInfo.GoodsKey = '';
      this.paramsInfo.TypeKey = 0;
      this.paramsInfo.pageIndex = 0;
      this.paramsInfo.startTime = '';
      this.paramsInfo.endTime = '';
      this.$emit('chgSearchValue', this.paramsInfo);
    },
    // 选择（类型）搜索
    chgOptionsTypeValue (obj) {
      console.log(obj, '选择搜索类型');
      this.paramsInfo.pageIndex = 0;
      this.paramsInfo.TypeKey = obj.value;
      this.paramsInfo.TypeKeyValue = obj.label;
      this.$emit('chgSearchValue', this.paramsInfo);
    },
    // 选择（物品类型）搜索
    chgGoodsTypeValue (obj) {
      console.log(obj, '选择搜索类型');
      this.paramsInfo.pageIndex = 0;
      this.paramsInfo.GoodsTypeKey = obj.OLAPKey;
      this.paramsInfo.GoodsTypeKeyValue = obj.MainDemoName;
      this.$emit('chgSearchValue', this.paramsInfo);
    },
    //  根据日期搜索
    changeSaveDate (dataInfo) {
      this.paramsInfo.pageIndex = 0;
      this.paramsInfo.startTime = dataInfo.startTime;
      this.paramsInfo.endTime = dataInfo.endTime;
      this.$emit('chgSearchValue', this.paramsInfo);
    },
    // 排序
    loadTableData (queryParams) {
      console.log(queryParams, '排序');
      this.paramsInfo.sequence = queryParams.sequence;
      this.paramsInfo.orderBy = queryParams.orderBy;
      this.$emit('chgSearchValue', this.paramsInfo);
    },
    // 获取物品类型列表
    getGoodsTypeList () {
      GetGoodsTypeList().then(result => {
        this.goodsTypeList = result.data;
        this.goodsTypeList.unshift({ MainDemoName: '全部', OLAPKey: 0 });
      }, error => {
        layer.alert(error.msg);
      });
    }
  }
};
</script>


