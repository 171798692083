<template>
  <div class="audio_view_content pr" @click.stop="">
    <div class="course_teaching_plan_voice audio_view"
          :class="{open_type:isPlaying}">{{vRecorderDuration}}</div>
    <audio class="audio_view_content_audio"
           :src="vAudioUrl"
           @play="play"
           @pause="pause"
           @ended="ended"
           :controls="controls"></audio>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isPlaying: false, // 是否正在播放音频
      playingDuration: 0, // 播放进度
      innerAudioContext: null
    };
  },
  props: {
    item: Object,// 音频信息 (AttachUrl，AudioSize)必填
    controls: {//是否播放
      type: Boolean,
      default: true
    }
  },
  computed: {
    vAudioUrl () {
      return this.$store.getters.CDNURL + this.item.AttachUrl;
    },
    vRecorderDuration () {
      const min = Math.floor(Number(this.item.AudioSize) / 60);
      let sec = Math.floor(Number(this.item.AudioSize) % 60);
      sec = sec < 10 ? '0' + sec : sec;
      const duration = min + "'" + sec + "''";
      if (isNaN(min) || isNaN(sec)) {
        return "0' 00''"
      } else {
        return duration;
      }
    }
  },
  mounted () {
    console.log(this.item, 'item');
  },
  methods: {
    // 点击播放
    play () {
      console.log('开始播放');
      this.isPlaying = true;
    },
    // 暂停播放
    pause () {
      this.isPlaying = false;
    },
    ended () {
      this.isPlaying = false;
    },
    // 继续播放
    resumePlay () {
      this.innerAudioContext.play();
      this.isPlaying = true;
      this.isStopPlay = false;
    }
  }
};
</script>

<style>
.audio_view_content_audio{
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  z-index: 9999;
}
</style>
