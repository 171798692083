<template>
  <div class="">
    <div v-if="bigCoursewarePackageList.length>0">
      <cw-summarizing :cwsSummList="cwsSummList"
                      @searchDate="searchDate"
                      @doSummarizingClick="doSummarizingClick"></cw-summarizing>

      <div class="courseware_list_ul">
        <div class="cw_details_list"
             v-for="(item,index) in bigCoursewarePackageList"
             :key="index">
          <courseware-info :bigCoursewareItem="item"
                           @doBigCwDetails="doBigCwDetails"></courseware-info>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="cw_content_nodata_txt_zys pr"
           v-if="$store.getters.AcademicMdCwType == 2&&$store.getters.SaaSClientInfo.IsOpenSelfCoursewareKey==0">
        <span>功能未开通</span>
        此功能为付费功能，请联系专属客服！

      </div>
      <div v-else
           class="cw_content_nodata_txt">还没有课包哦，请与课件方联系~</div>
    </div>
  </div>
</template>  
<script>

import cwSummarizing from '../../courseware-work-bench/right-box-work-bench/courseware-details/cw-summarizing';
import coursewareInfo from '../../courseware-work-bench/right-box-work-bench/courseware-details/courseware-info';
export default {
  data () {
    return {

    };
  },
  components: {
    cwSummarizing,
    coursewareInfo
  },
  props: {
    params: Object,
    cwsSummList: Array,
    bigCoursewarePackageList: Array
  },
  created () {
    console.log(this.$store.getters, 'this.$store.getters');
  },
  methods: {
    searchDate (startDate, endDate) {
      this.$emit('searchDate', startDate, endDate);
    },
    doSummarizingClick (item, startDate, endDate) {
      console.log(item, startDate, endDate, '点击当前汇总数据');
      let StartTime = item.key >= 5 ? this.$utils.formatDateToLine(Date.new()) : startDate; //	字符串	必须		数据源：GET	起始时间
      let EndTime = item.key >= 5 ? this.$utils.formatDateToLine(Date.new()) : endDate; //	字符串	必须		数据源：GET	结束时间
      this.$dialog.open(this, {
        name: '课件使用账单',
        routerName: this.$route.name,
        moduleName: 'coursewareUseReport',
        // name: '上课课件统计',
        // routerName: this.$route.name,
        // moduleName: 'WebvClassStatistics',
        data: {
          dataInfo: {
            TeacherKey: '', // 老师
            StartTime: StartTime,
            EndTime: EndTime,
            Source: this.$store.getters.AcademicMdCwType
          }
        }
      });
    },
    doBigCwDetails (item) {
      console.log(item, '获取授权大课包1');
      let detailsKey = this.$store.getters.AcademicMdCwType == 1 ? item.AuthKey : item.CoursewarePackageKey;
      this.$dialog.open(this, {
        name: '大课包详情',
        routerName: this.$route.name,
        moduleName: 'bigCoursewareDetails',
        data: { dataInfo: { AuthKey: detailsKey, Source: this.$store.getters.AcademicMdCwType, MainDemoName: item.MainDemoName, CoursewarePackageKey: detailsKey } },
        callBack: {
          afterSuccess: (data) => {
            console.log(data, 'data');
            this.$emit('afterSuccessupData');
          }
        }
      });
    }
  }
};
</script>

<style>
.courseware_list_ul {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 700px;
  padding-top: 10px;
}
.courseware_list_ul .cw_details_list {
  height: auto !important;
}
.cw_details_list {
  margin: 20px;
  width: 815px;
  position: relative;
  height: 100%;
  border-bottom: 1px solid #ececec;
  padding: 16px 0 26px 0;
  padding-right: 0px;
  /* cursor: pointer; */
}
.cw_content_nodata_txt_zys {
  text-align: center;
  padding-top: 200px;
  padding-bottom: 80px;
  background-image: url(../../../../public/cw-img/Group.png);
  background-repeat: no-repeat;
  background-position: center 30px;
  background-color: #fff;
  color: #999;
  font-size: 16px;
  margin-top: 80px;
}
.cw_content_nodata_txt_zys span {
  display: block;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
  /* identical to box height, or 157% */
  align-items: center;
  color: #000000;
}
</style>
