<template>
    <!-- 报表标题导航栏 -->
    <div class="table_fixed_nav_box">
      <div class="table_fixed_nav">
        <div class="table_fixed_nav_list"
             v-for="(item,index) in menuList"
             :key="index"
             :class="item.opt?'opt':''"
             @click.stop="changeMenuOpt(item)">{{item.name}}</div>
      </div>
    </div>
</template>
<script>

export default {
  props: {
    menuList: {// 标题菜单(必填)
      type: Array,
      default: () => {
        return [{ name: '标题名XX', className: '样式名称', opt: '高亮显示' }];
      }
    }
  },
  data () {
    return {

    };
  },

  created () {

  },
  methods: {
    changeMenuOpt (item) {
      this.menuList.forEach(o => {
        if (item.name == o.name) {
          o.opt = true;
        } else {
          o.opt = false;
        }
      });
      this.$emit('changeMenuOpt', item);// 发射事件
    }
  }
};
</script>
