<!-- 流量包模块 -->
<template>
  <!-- 授权管理 -->
  <div class="payment_statistics_bottom courseware_use_report">
    <!-- 头部 -->
    <div class="HQflow_packet_total">
      <div class="HQflow_Bill_date">
        <!-- 选择年月 -->
        <div class="date_time pr">
          <div class="new_table_title_date left_type ">
            <div @click.stop="prodMounth"
                 class="date_last fl button"></div>
            <div class="cw_date_calendar button">
              <single-year-picker class="cw_date_box"
                                  @chgDate="chgDate"
                                  :type="'month'"
                                  style="opacity: 0;"></single-year-picker>
            </div>
            <span class="date_text button">{{vCurSelectedDate}}</span>
            <div @click.stop="nextMounth"
                 class="date_next fr button"></div>
          </div>
        </div>
        <div class="buy_con">
          <!-- <div class="buy_flow">
            <div>{{Number(dataReportInfo.CoursewareCount)||'--'}}</div>
            <div>选班上课课次</div>
          </div>
          <div class="buy_flow">
            <div>{{Number(dataReportInfo.CoursewareTimeCount)||'--'}}</div>
            <div>选班上课时长</div>
          </div>
          <div class="buy_flow">
            <div>{{Number(dataReportInfo.ScheduleCourseCoursewareTimeCount)||'--'}}</div>
            <div>选班上课课件时长</div>
          </div>
          <div class="buy_flow">
            <div>{{$utils.flowFormate(dataReportInfo.ConsumedFlowCount,'','sumType')}}</div>
            <div>选班上课课件流量</div>
          </div> -->
          <div class="buy_flow">
            <div>{{Number(dataReportInfo.PreviewCount)||'--'}}</div>
            <div>上课次数</div>
          </div>
          <div class="buy_flow">
            <div>{{Number(dataReportInfo.PreviewTimeCount)||'--'}}</div>
            <div>上课课件时长</div>
          </div>
          <div class="buy_flow">
            <div>{{$utils.flowFormate(dataReportInfo.PreviewConsumedFlowCount,'','sumType')}}</div>
            <div>上课课件流量</div>
          </div>
          <div class="buy_flow">
            <div>{{$utils.flowFormate(dataReportInfo.FlowNumberCount,'','sumType')}}</div>
            <div>扣费流量</div>
          </div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_search flex">

      <div class="width_small course_statistics_search">
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入'"
                                  :clearAll="true"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>

      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list class="summarizing_list"
                  :tableData="vdataReportList"
                  :tableColumns="vTableCourseColumns"
                  :key="tableKey"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :queryParams="searchObj"
                  :footerContent="vFooterTotal"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import tableList from '../../common/table-list/index';
import inputSearchContainBtn from '../common/input-search-contain-btn';
import singleYearPicker from '../../base-module/date-picker-common/single-year-picker';
import {
  WEBVGetCoursewareUseBillSummaryForMonth, WEBVSearchPageCoursewareUseBillList, CWSGetCoursewareUseBillSummaryForMonth, CWSSearchPageCoursewareUseBillList, HQCoursewareUseBillSummaryForCampus, SearchPageCoursewareUseBillListForCampus
} from '../../../API/workbench';
export default {
  components: {
    inputSearchContainBtn,
    tableList,
    singleYearPicker
    // coursewareDetails
  },
  data () {
    return {
      curSelectedDate: this.$utils.getCurrentMonthFirst(Date.new()),
      dataReportInfo: {
        ConsumedFlowCount: 0,  //	上课消耗流量
        CoursewareTimeCount: 0,   // 上课时长
        CoursewareCount: 0,  // 上课课次
        FlowNumberCount: 0, // 扣费流量
        PreviewConsumedFlowCount: 0,  // --预览消耗流量
        PreviewCount: 0,   // 预览次数
        PreviewTimeCount: 0,     // 预览课件时长
        ScheduleCourseCoursewareTimeCount: 0  // 上课课件时长
      },
      // 查询参数
      searchObj: {
        startTime: '',
        endTime: '',
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        sequence: 'DESC',
        orderBy: '',
        CampusTypeKey: 0,
        CampusKey: 0
      },
      dataReportList: [],
      PageCount: 0,
      totalNum: 0,
      tableKey: '0',
      GeneralFluxCount: 0,
      usetableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 58,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '大课包',
          prop: 'MainDemoName',
          minWidth: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '课包类型',
          prop: 'SourceValue',
          sortable: 'custom',
          minWidth: 100,
          align: 'left',
          type: 'textItem'
        },
        // {
        //   label: '选班上课课次',
        //   prop: 'CoursewareCount',
        //   minWidth: 130,
        //   sortable: false,
        //   align: 'left',
        //   type: 'numberItem'
        // },
        // {
        //   label: '选班上课课件时长',
        //   prop: 'ScheduleCourseCoursewareTimeCount',
        //   minWidth: 140,
        //   sortable: false,
        //   align: 'left',
        //   type: 'numberItem'
        // },
        // {
        //   label: '选班上课课件流量',
        //   prop: 'ConsumedFlowCount',
        //   minWidth: 140,
        //   sortable: false,
        //   align: 'left',
        //   type: 'textItem'
        // },
        {
          label: '上课次数',
          prop: 'PreviewCount',
          minWidth: 130,
          sortable: false,
          align: 'left',
          type: 'numberItem'
        },
        {
          label: '上课课件时长',
          prop: 'PreviewTimeCount',
          minWidth: 140,
          sortable: false,
          align: 'left',
          type: 'numberItem'
        },
        {
          label: '上课课件流量',
          prop: 'PreviewConsumedFlowCount',
          minWidth: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '扣费流量',
          prop: 'FlowNumberCount',
          minWidth: 90,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          width: 88,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  click: (rowData) => {
                    this.doAfterTableBtnClick(rowData);
                  }
                },
                ModulePowerKey: 0
              }

            ]
          }
        }
      ]
    };
  },

  props: {
    dataInfo: {
      type: Object
      // default:()=>{
      //   return {
      //     StartTime:'',
      //     EndTime:'',
      //     SchoolKey:0   //总店传得课件门店ID
      //   }
      // }
    }
  },
  created () {
    console.log(this.dataInfo, 'dataReportInfo');
    if (this.dataInfo) {
      this.searchObj.startTime = this.dataInfo.StartTime;
      this.searchObj.endTime = this.dataInfo.EndTime;
      this.curSelectedDate = this.dataInfo.StartTime;
      this.searchObj.CampusKey = Number(this.dataInfo.SchoolKey) || 0;
    }
    this.getDataInfo();
  },
  mounted () {
    this.$dialog.register(this,
      [
        'WebvClassStatistics', 'courseAttendClassList', 'classStatistics', 'coursewareUseStatlist'
      ]);
  },
  computed: {
    vCurSelectedDate () {
      let text = this.$utils.formatDatet(
        Date.new(this.curSelectedDate),
        'yyyy'
      ) + ' 年 ' + this.$utils.formatDatet(
        Date.new(this.curSelectedDate),
        'MM'
      ) + ' 月';
      console.log('text', text);
      return text;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        o.SourceValue = o.Source == 1 ? '授权' : '自研';  // --来源 1授权 2 自研
        o.ConsumedFlowCount = this.$utils.flowFormate(o.ConsumedFlowCount, '', 'sumType'),  //	上课消耗流量
          o.FlowNumberCount = this.$utils.flowFormate(o.FlowNumberCount, '', 'sumType');  // 扣费流量
        o.PreviewConsumedFlowCount = this.$utils.flowFormate(o.PreviewConsumedFlowCount, '', 'sumType');  // --预览消耗流量

        newArr.push(o);
      });
      console.log(newArr, 'newArr');
      return newArr;
    },
    vTableCourseColumns () {
      //   if (this.vmdInfo.EditionKey == 5) {
      return this.usetableCourseColumns;
      //   } else {
      //     return this.tableCourseColumns;
      //   }
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    },
    vmdInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vTypeKeyValue () {
      return this.searchObj.FluxPackageType == 0
        ? '全部'
        : this.searchObj.FluxPackageTypeValue;
    }
  },
  methods: {
    prodMounth () {
      this.curSelectedDate = this.$utils.getPreMonth(this.curSelectedDate);
      this.getDataInfo();
    },
    nextMounth () {
      this.curSelectedDate = this.$utils.getNextMonth(this.curSelectedDate);
      this.getDataInfo();
    },
    getMonthTime (year, month) {
      var date = Date.new();
      var year = year || date.getFullYear() + '';
      var month = month || (date.getMonth() + 1) + '';
      if (Number(month) < 10) {
        month = '0' + Number(month);
      }
      // 本月第一天日期
      var begin = year + '-' + month + '-01';
      // 本月最后一天日期
      var lastDateOfCurrentMonth = Date.new(year, month, 0);
      var end = year + '-' + month + '-' + lastDateOfCurrentMonth.getDate();
      this.searchObj.startTime = begin;
      this.searchObj.endTime = end;
      // this.getDataInfo(this.searchData);
    },
    chgDate (val, startTime, endTime) {
      this.searchObj.startTime = startTime;
      this.searchObj.endTime = endTime;
      this.getDataInfo();
    },
    setFluxNumber (size) {
      size = Number(size);
      if (size == 0) {
        return 0 + 'KB';
      }
      let kb = 1024;
      let unit = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
      let i = Math.floor(Math.log(size) / Math.log(kb));
      return Number((size / Math.pow(kb, i)).toPrecision(4)) + ' ' + unit[i];
    },
    // 获取统计数据
    getDataInfo () {
      this.getMonthTime(this.curSelectedDate.split('-')[0], this.curSelectedDate.split('-')[1]);
      let fn = '';
      // // 2标准 3旗舰 4高级 5总部 6课件
      console.log('this.vmdInfo', this.vmdInfo);
      if (this.vmdInfo.EditionKey == 5) {
        fn = HQCoursewareUseBillSummaryForCampus;
      } else if (this.vmdInfo.EditionKey == 6) {
        fn = CWSGetCoursewareUseBillSummaryForMonth;
      } else {
        fn = WEBVGetCoursewareUseBillSummaryForMonth;
      }
      fn(this.searchObj).then(result => {
        console.log(result.data, 'getDataInfo');
        this.dataReportInfo = result.data;
      }).catch(error => {
        console.log('errgetDataInfo', error.msg);
      });
      this.getDataList();
    },
    // 获取列表数据
    getDataList () {
      this.getMonthTime(this.curSelectedDate.split('-')[0], this.curSelectedDate.split('-')[1]);
      let fn2 = '';
      if (this.vmdInfo.EditionKey == 5) {
        fn2 = SearchPageCoursewareUseBillListForCampus;
      } else if (this.vmdInfo.EditionKey == 6) {
        fn2 = CWSSearchPageCoursewareUseBillList;
      } else {
        fn2 = WEBVSearchPageCoursewareUseBillList;
      }

      fn2(this.searchObj).then((result) => {
        this.dataReportList = result.data.PageDataList;
        this.totalNum = result.data.Total;
        console.log('GetFluxPackageDetails', result.data);
      })
        .catch((error) => {
          console.log('errorGetFluxPackageDetails', error);
        });
    },
    clearSearchCondition () {
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.search();
    },
    search () {
      console.log(this.searchObj.PageDataList, 'this.searchObj.PageDataList');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // table 按钮点击
    doAfterTableBtnClick (item) {
      console.log(item, 'item');
      item.FlowPackagesKey = item.OLAPKey;
      item.FlowPackagesKeyValue = item.MainDemoName;
      let obj = {
        StartTime: this.searchObj.startTime,
        EndTime: this.searchObj.endTime,
        BigCoursewarePackageKey: item.OLAPKey,
        CampusKey: this.searchObj.CampusKey,
        SchoolKey: this.searchObj.CampusKey,
        SchoolKeyValue: this.dataInfo ? this.dataInfo.SchoolKeyValue : '',
        fromType: 'coursewareUseReport'
      };
      if (this.vmdInfo.EditionKey == 5) {
        this.$dialog.open(this, {
          name: '课件使用统计',
          routerName: this.$route.name,
          moduleName: 'courseAttendClassList',
          ModulePowerKey: '',
          data: { dataInfo: obj },
          callBack: {
            afterSuccess: () => {
              this.getDataInfo();
            }
          }
        });
      } else if (this.vmdInfo.EditionKey == 6) {
        this.$dialog.open(this, {
          name: '课件使用统计',
          routerName: this.$route.name,
          moduleName: 'coursewareUseStatlist',
          ModulePowerKey: '',
          data: { dataInfo: obj },
          callBack: {
            afterSuccess: () => {
              this.getDataInfo();
            }
          }
        });
      } else {
        this.$dialog.open(this, {
          name: '课件使用统计',
          routerName: this.$route.name,
          moduleName: 'WebvClassStatistics',
          ModulePowerKey: '',
          data: { dataInfo: obj },
          callBack: {
            afterSuccess: () => {
              this.getDataInfo();
            }
          }
        });
      }
    }
    // toBuy () {

    // }
  }
};
</script>

<style scoped>
.date_time .new_table_title_date.left_type {
  font-size: 14px;
  width: 160px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 480px;
}
.date_time .new_table_title_date.left_type .date_last,
.date_time .new_table_title_date.left_type .date_next {
  width: 30px;
  height: 30px;
  background-size: 5px;
}
.date_time .new_table_title_date .date_last {
  background: url(../../../../public/image/change_prev_defult.png) no-repeat
    center center;
}
.date_time .new_table_title_date .date_last:hover {
  background-image: url(../../../../public/image/change_prev_light.png);
}
.date_time .new_table_title_date .date_next {
  background: url(../../../../public/image/change_next_defult.png) no-repeat
    center center;
}
.date_time .new_table_title_date .date_next:hover {
  background-image: url(../../../../public/image/change_next_light.png);
}
.date_time .date_text {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.date_time .cw_date_calendar {
  width: 117px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  top: -1px;
  left: 24px;
}
single-year-picker {
  padding-left: 8px;
  padding-right: 9px;
}
.date_time .cw_date_box {
  position: absolute;
  width: 100px;
  height: 28px;
  cursor: pointer !important;
  opacity: 0;
}
.el-icon-time {
  position: absolute;
  left: 35px;
}
.title_date_select {
  display: inline-block;
  width: 118px;
  height: 36px;
  border-radius: 4px;
  margin-left: 10px;
  vertical-align: top;
  left: 290px;
  position: absolute;
}

.title_date_select .el-input input {
  padding-left: 10px;
  height: 34px;
  width: 101px;
  vertical-align: top;
  padding-right: 20px;
  font-size: 13px;
}

.title_date_select::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  right: 6px;
  top: 15px;
  pointer-events: none;
}

.title_date_select.open::after {
  border-top-color: transparent;
  border-bottom-color: #ccc;
  top: 9px;
}

.title_date_select .el-select {
  z-index: 2;
}
.head {
  position: absolute;
  top: 70px;
  left: 20px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.HQflow_packet_total {
  top: 96px;
  line-height: 35px;
  background-size: 30px;
  width: 100%;
}
.payment_statistics_bottom {
  height: auto;
}
.HQflow_Bill_date {
  box-sizing: border-box;
  /* width: 1000px; */
  height: 100px;
  margin: 20px 20px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #f1f7ff, #f1f7ff),
    linear-gradient(0deg, #f5f8ff, #f5f8ff), #f9f9fb;
  border: 1px solid #ececec;
  border-radius: 4px;
}
.course_statistics_table_search {
  padding: 0 20px 13px 20px;
}
.buy_con {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  width: 100%;
}
.buy_flow {
  flex: 1;
  text-align: center;
  border-right: 1px solid #ececec;
}
.buy_flow div {
  height: 20px;
}
.buy_flow div:nth-of-type(1) {
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
}
.buy_flow div:nth-of-type(2) {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
}
.buy_flow:nth-of-type(4) {
  border-right: none;
}
.courseware_use_report >>> .el-table .el-table__cell {
  padding: 0 !important;
}
.courseware_use_report >>> .el-input__inner {
  cursor: pointer !important;
}
.course_statistics_table_box >>> .el-table__row {
  height: 40px !important;
}
</style>

