<template>
  <!-- 自定义展示项 （通用） -->
  <div >
    <div v-for="(item,index) in vdataSource"
         :key="index" class="single_result single_class_info" :class="{'active':item.isActive==true}">
      <div style="width:100%"
           @click.stop="selectedItem(item)">
        <div class="single_result_info_detail" >
          <div class="student_info_name" >
            <span>{{item.MainDemoName}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'singleSelectionItem',
  data () {
    return {

    };
  },
  props: [ 'dataSource' ], // 单选数据项 需绑定到界面
  created () {

  },
  mounted () {
  },
  // 一进入页面就触发；可用于初始化页面数据等
  activated () {
  },
  watch: {

  },
  computed: {
    vdataSource () {
      var list = [];
      this.dataSource.forEach((item, index) => {
        if (item.OLAPKey == this.$store.getters.getShowTimetableId) {
          item.isActive = true;
          list.push(item);
          // this.dataSource[index].isActive = true;
          console.log(this.$store.getters.getShowTimetableId, 'this.wItemInfo');
        } else {
          item.isActive = false;
          list.push(item);
        }
      });
      return list;
    }
  },
  methods: {
    selectedItem (item) {
      console.log(item, '搜索项');
      this.dataSource.forEach(o => {
        if (item.OLAPKey == o.OLAPKey) {
          item.isActive = true;
          this.$emit('selectedItem', item);
          this.$store.commit('setShowTimetableId', item.OLAPKey);
        } else {
          item.isActive = false;
        }
      });
    }
  }
};
</script>

