<template>
  <!-- 收支科目-设置 -->
  <div class="common-setting bg_gray">
    <!-- 添加按钮 -->
    <div class="content_marginTop_30">
      <span class="defult_dialog_header_btn fr"
            @click="doAddBtnClick"
            :class="vHasModulePower(112)?'':'norole'">
        <span class="btn_add">+</span>新增{{subjectType == 1 ? '收入科目' : '支出科目'}}
      </span>
    </div>
    <mutil-switch-btn :btnList="signUpTypes"
                      @selectedItem="selectedSubjectType"></mutil-switch-btn>

    <div class="mult_info_text_right_icon mult_info_ul bg_white"
         style="overflow-y: auto; height: 550px;" v-if="dataList.length>0">
      <items v-for="(item,index) in dataList"
             :key="index"
             :item="item"
             :operateTypes="operateTypes"
             @editSubjectItemClick="editSubjectItemClick"
             @chooseEdit="chooseEdit" @doSubjectItemClick="doSubjectItemClick"></items>
    </div>
      <div v-else>
        <div class="monitoring_content_nodata">
          <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
        </div>
      </div>
    <!-- 操作弹窗 -->
    <!-- <bottom-popup-operate-form :formTitle="proceedsSubjectInfo.MainDemoName"
                               v-if="isShowOperateBottomPopup"
                               @cancelClick="closeDiscountDialog">
      <basics-popup-list :dataList="operateTypes"
                         @seletedItem="chooseEdit"></basics-popup-list>
    </bottom-popup-operate-form> -->
    <!-- <div class="course_student_attendance_operation module_content_menuList"
         :style="{'top':topValue+'px','left':'2px'}"
         v-if="isShowOperateBottomPopup">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="operateTypes"
                     @clickOptionForAlertDialog="chooseEdit"></drop-down-box>
    </div> -->

    <!-- 编辑弹窗 -->
    <custom-dialog :title="formData.titleName"
                   class="incomepay_dialog defult_dialog"
                   :visible.sync="isShowBasicsBottomEditPopup"
                   :before-close="closeDiscountDialog">
      <proceeds-subject-form :dataInfo='formData.data'
                             :isReadonly="formData.isReadonly"
                             @afterSuccess="afterSuccess"
                             @cancelClick="closeDiscountDialog"></proceeds-subject-form>
    </custom-dialog>
  </div>
</template>

<script>
import bottomPopupOperateForm from '../common/bottom-popup-operate-form';
import basicsPopupList from '../common/basics-popup-list';
import proceedsSubjectForm from './proceeds-subject-form';
import {
  hqMessageSearchPageForIncomePaySubject,
  hqStopIncomePaySubject
} from '../../../../API/workbench.js';
import mutilSwitchBtn from './mutil-switch-btn/list.vue';
import items from './items.vue';
import dropDownBox from '../../../common/drop-down-box';
export default {
  data () {
    return {
      topValue: 150,
      dataList: [],
      proceedsSubjectList: [], // 财务科目列表
      proceedsSubjectInfo: {}, // 财务科目信息
      isShowOperateBottomPopup: false, // 操作弹窗
      isShowBasicsBottomEditPopup: false, // 编辑弹窗
      subjectType: 1, // 1-收;2-支
      operateTypes: [], // 操作类型
      signUpTypes: [{
        key: 1,
        value: '收入科目',
        isSelection: true
      }, {
        key: 2,
        value: '支出科目',
        isSelection: false
      }],
      formData: {
        titleName: '', // 标题
        isReadonly: '', // 是否可改
        data: {} // 数据源
      },
      // 查询字段
      parameter: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称
        StateKey: 0, // 字符串	可选	0	数据源：GET	状态 2 启用 3停用
        IncomePayTypeKey: '' // 	收支类型 1-收;2-支
      }
    };
  },
  components: {
    basicsPopupList,
    bottomPopupOperateForm,
    proceedsSubjectForm,
    mutilSwitchBtn,
    dropDownBox,
    items
  },
  created () {
    this.getMessageSearchPageForIncomePaySubject(1); // 默认显示收入科目（1-收;2-支）
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      // this.isShowOperateBottomPopup = false;
      this.doSubjectItemClick();
    }, false);
  },
  computed: {

  },
  methods: {
    // 打开报名类型列表
    selectedSubjectType (item) {
      if (item.key) { // 收支类型Key 1-收;2-支
        this.subjectType = item.key;
        this.getMessageSearchPageForIncomePaySubject(this.subjectType);
      }
    },
    // 计算操作显示位置
    operatingLocation (item) {
      let index = 0;
      if (Number(item.FatherKey) <= 2) { // 父级，
        index = this.dataList.findIndex(obj => {
          return obj.OLAPKey == item.OLAPKey;
        });
        this.topValue += index * 50 + index;
      } else {
        index = this.dataList.findIndex(obj => {
          return obj.OLAPKey == item.FatherKey;
        });
        let child = 0;
        if (index >= 0 && this.dataList[index].FatherKey <= 2) { // 一级子级，
          child = this.dataList[index].childSubject.findIndex(obj => {
            return obj.OLAPKey == item.OLAPKey;
          });
          this.topValue += index * 50 + (child + 1) * 50 + index + 1;
        } else { // 多级子级，
          this.operatingLocation(this.dataList[index]);
        }
      }
    },
    // 显示操作
    editSubjectItemClick (item) {
      console.log(item, '编辑11');
      // this.topValue = 150;
      // this.operatingLocation(item);
      this.proceedsSubjectList.forEach(o => {
        if (item.OLAPKey == o.OLAPKey) {
          this.$set(o, 'isShow', true);
        } else {
          this.$set(o, 'isShow', false);
        }
      });
      // 当前是否有未分类项
      var findIndex = -1;
      if (item.childSubject && item.childSubject.length > 0) {
        findIndex = item.childSubject.findIndex(obj => {
          return Number(obj.SysPaySubjectKey) > 0;
        });
      }
      this.operateTypes = [ // 操作类型
        { type: 0, name: '查看', eventName: 'view', isShow: true, className: 'list_icon08' },
        { type: 1, name: '修改', eventName: 'edit', isShow: true, className: 'list_icon09' }
      ];
      console.log(findIndex, '编辑1');
      if (Number(item.SysPaySubjectKey) == 0 && findIndex < 0) { // 自定义可以停用
        this.operateTypes.push({ type: 2, name: '停用', eventName: 'disable', isShow: true, className: 'list_icon10' });
      }
      this.proceedsSubjectInfo = item;
      this.subjectType = item.IncomePayTypeKey;
      // this.isShowOperateBottomPopup = !this.isShowOperateBottomPopup;
    },
    // 获取所有财务科目列表
    getMessageSearchPageForIncomePaySubject (key) {
      hqMessageSearchPageForIncomePaySubject(this.parameter).then(result => {
        this.proceedsSubjectList = result.data.PageDataList.filter(obj => {
          this.$set(obj, 'childSubject', []);
          this.$set(obj, 'isOpt', false);
          this.$set(obj, 'isShow', false);
          return obj.StatusKey == 1 && Number(obj.IncomePayTypeKey) == Number(key);
        }); // 显示启用的数据
        var list = this.proceedsSubjectList.filter(obj => {
          this.$set(obj, 'level', 0);
          return Number(obj.FatherKey) == 2;
        });
        var childList = this.proceedsSubjectList.filter(obj => {
          this.$set(obj, 'level', 1);
          return Number(obj.FatherKey) > 2;
        });
        this.dataList = this.loopData(list, childList, this.proceedsSubjectList);

        console.log(this.dataList, '获取所有启用的数据');
      }, error => {
        layer.alert(error.msg);
      });
    },
    loopData (fatherList, childrenList, treeData) {
      const arr = [];
      childrenList.forEach(child => {
        let isExist = false;
        fatherList.forEach(father => {
          if (father.OLAPKey == child.FatherKey) {
            isExist = true;
            child.level = father.level + 1;
            father.childSubject.push(child);
            return false;
          }
        });
        if (!isExist) {
          arr.push(child);
        }
      });
      fatherList.forEach(item => {
        if (item.childSubject.length > 0) {
          this.loopData(item.childSubject, arr, treeData);
        }
      });
      return fatherList;
    },
    // 取消操作
    closeDiscountDialog () {
      this.isShowOperateBottomPopup = false;
      this.isShowBasicsBottomEditPopup = false;
    },
    // 新增操作
    doAddBtnClick () {
      this.chooseEdit({
        name: '新增',
        eventName: 'add'
      });
      console.log(this.subjectType, '新增操作');
    },
    // 选择操作
    chooseEdit (item) {
      console.log(this.proceedsSubjectInfo, '选择操作');
      this.doSubjectItemClick();
      if (item.eventName != 'disable') {
        this.isShowOperateBottomPopup = false;
        let naem = (this.subjectType == 1 ? '收入科目' : '支出科目');
        this.formData.titleName = item.name + naem;
        this.isShowBasicsBottomEditPopup = true;
      }
      switch (item.eventName) {
        case 'add':
          this.formData.data.OLAPKey = '';
          this.formData.data.IncomePayTypeKey = this.subjectType;
          this.formData.data.IncomePayTypeKeyValue = (this.subjectType == 1 ? '收' : '支');
          this.formData.isReadonly = false;
          break;
        case 'view':
          this.formData.data = this.proceedsSubjectInfo;
          this.formData.isReadonly = true;
          break;
        case 'edit':
          this.formData.data = this.proceedsSubjectInfo;
          this.formData.isReadonly = false;
          break;
        case 'disable':
          this.operateDisable();
          break;
        default:
          break;
      };
    },
    doSubjectItemClick () {
      this.proceedsSubjectList.forEach(o => {
        this.$set(o, 'isShow', false);
      });
    },
    // 停用操作
    operateDisable () {
      layer.confirm('是否需要停用该财务科目?', {
        btn: [{
          name: '确认',
          callBack: () => {
            layer.close();
            hqStopIncomePaySubject(this.proceedsSubjectInfo.OLAPKey).then(result => {
              this.getMessageSearchPageForIncomePaySubject(this.subjectType);
              this.closeDiscountDialog();
            }, error => {
              layer.alert(error.msg);
            });
          }
        }
        ]
      });
    },
    // 课类操作成功回调
    afterSuccess (type, data) {
      console.log(this.subjectType, data, '选择操作');
      this.getMessageSearchPageForIncomePaySubject(this.subjectType);
      this.closeDiscountDialog();
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.$store.getters.RolePowerList, ModulePowerKey);
    }
  }
};
</script>

<style>
</style>
