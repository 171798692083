<template>
  <!-- 音频 -->
  <div class="courseware_voice_content_box">
    <div class="courseware_voice_content"
         :class="{ open_type: audio.isPlaying }">
      <div class="voice_banner"></div>
      <div class="voice_player"></div>
      <div class="voice_btn"
           @click="playVoice"></div>
    </div>
    <div class="courseware_voice_progress">
      <div class="voice_left">{{ vCurrentTime }}</div>
      <div class="progress_banner"
           @mousedown="touchstartProgress">
        <div v-if="audio.currentTime > 0"
             class="progress_color"
             :style="{ width: vProgress }"></div>
      </div>
      <div class="voice_right">{{ vRecorderDuration }}</div>
    </div>
    <!-- <div ref="video"></div> -->
    <video v-if="dataInfo.AttachViewUrl&&vShowType"
           id="my-video"
           ref="audio"
           class="audio audio-file"
           preload="auto"
         
 
           @timeupdate="handleAudioTimeUpdated"
           controls></video>
    <audio v-if="dataInfo.AttachViewUrl&&!vShowType"
           controls
           preload="auto"
           autobuffer
           ref="audio"
           name="media"
           class="audio audio-file"
           :src="vAudioUrl"
           
  
           @error="onError"
           @ended="onEnded"
           @loadeddata="onLoadeddata"
           @timeupdate="handleAudioTimeUpdated"
           @loadstart="onLoadstart"
           @canplay="onCanplay"
           id="audio-file">
    </audio>
  </div>
</template>
<script>
// import Hls from 'hls.js';
export default {
  data () {
    return {
      audioObj: null, // 音频对象
      playDurationTimeout: null, // 播放进度自增1
      isError: false,
      audio: {
        playOpen: false,
        isPlaying: false, // 该字段是音频是否处于播放状态的属性
        currentTime: 0, // 音频当前播放时长
        recorderDuration: 0, // 音频最大播放时长
        minTime: 0,
        step: 0.1
      },
      isProgress: false,
      myVideo: null,
      isPlaying:false
    };
  },
  props: {
    dataInfo: Object
  },
  computed: {
    vAudioUrl () {
      return this.dataInfo.AttachViewUrl;
    },
    vProgress () {
      if ((this.audio.currentTime / this.audio.recorderDuration * 100).toFixed(2) < 100) {
        return (this.audio.currentTime / this.audio.recorderDuration * 100).toFixed(2) + '%';
      } else {
        return 100;
      }
    },
    vRecorderDuration () {
      let min = '00';
      let sec = '00';
      if (this.audio.recorderDuration > 0) {
        min = Math.floor(this.audio.recorderDuration / 60);
        min = min < 10 ? '0' + min : min;
        sec = Math.floor(this.audio.recorderDuration % 60);
        sec = sec < 10 ? '0' + sec : sec;
      }
      let duration = min + ':' + sec;
      return duration;
    },
    vCurrentTime () {
      let min = '00';
      let sec = '00';
      min = Math.floor(this.audio.currentTime / 60);
      min = min < 10 ? '0' + min : min;
      sec = Math.floor(this.audio.currentTime % 60);
      sec = sec < 10 ? '0' + sec : sec;
      let duration = min + ':' + sec;
      return duration;
    },
    vCurPlayingAudio () {
      return this.$store.getters.curPlayingAudio;
    },
    vIsCloseAudioPlay () {
      return this.$store.getters.isCloseAudioPlay;
    },
    vShowType () {
      let url = this.dataInfo.AttachViewUrl;
      // 根据流地址的后缀设置好播放类型
      if (url.indexOf('.m3u8') !== -1) {
        // hls类型
        return true;
      } else {
        // rtmp类型
        return false;
      }
    }
  },
  watch: {
    // vAudioUrl: {
    //   handler (newVal, oldVal) {
    //     console.log('监听到切换', this.vAudioUrl);
    //     // this.getVideo();
    //     // if (this.$refs.audio) {
    //     //   this.$refs.audio.load();
    //     // }

    //     // this.isShowAudio = false;
    //     this.isProgress = false;
    //     this.audio.isPlaying = false;
    //     if (this.audioObj) {
    //       this.audio.recorderDuration = this.dataInfo.DurationNum;

    //       this.audio.currentTime = 0;
    //       this.audioPause();
    //       this.audioObj.load(this.vAudioUrl);
    //     } else {
    //       this.audio.recorderDuration = this.dataInfo.DurationNum;
    //       this.audioObj = this.$refs.audio;
    //     }
    //     console.log('this.dataInfo.DurationNum', this.dataInfo.DurationNum);
    //   },
    //   immediate: true
    // },
    vCurPlayingAudio (n, o) {
      // 同时打开多个音频 把前面打开的音频关闭
      if (n != this.vAudioUrl) {
        this.isProgress = false;
        this.audio.isPlaying = false;
        this.audioObj.pause();
        if (this.vShowType) {
          this.audioObj.currentTime(0);
        } else {
          this.audioObj.currentTime = 0;
        }

        this.audio.currentTime = 0;
      }
    },
    vIsCloseAudioPlay (n, o) {
      // 点击其他地方 关闭音频
      if (n) {
        this.isProgress = false;
        this.audio.isPlaying = false;
        this.audioObj.pause();
        if (this.vShowType) {
          this.audioObj.currentTime(0);
        } else {
          this.audioObj.currentTime = 0;
        }
        this.audio.currentTime = 0;
      }
    },
    $route (to, from) {
      // 路由变更 关闭音频
      // this.isProgress = false;
      // this.audio.isPlaying = false;
      // this.audioObj.pause();
      // if (this.vShowType) {
      //   this.audioObj.currentTime(0);
      // } else {
      //   this.audioObj.currentTime = 0;
      // }
      // this.audio.currentTime = 0;
    }
  },
  mounted () {
    console.log(this.dataInfo, 'this.dataInfo');
    if (this.vShowType) {
      this.getVideo();
    } else {
      this.audioObj = this.$refs.audio;
    }

    this.audio.recorderDuration = this.dataInfo.DurationNum;
    // this.$once('hook:beforeDestroy', () => {
    //   this.myVideo.dispose();
    // });
  },
  destroyed () {
    console.log('注销');
    if(this.audio.isPlaying){
      this.dataInfo.currentTime = this.audio.currentTime;
       this.$bus.emit("endAttachVisit", {
            dataInfo: this.dataInfo,
            EndPosition:  this.dataInfo.currentTime
          })
    }
    if (this.vShowType&&this.myVideo) {
      this.myVideo.dispose();
    } else {
      // this.audioObj = this.$refs.audio;
    }
  },
  methods: {
    touchstartProgress (e) {
      if (this.audio.recorderDuration != 0 && !this.isError && this.audio.playOpen) {
        this.isProgress = true;
        console.log("e.offsetX",e.offsetX,(e.offsetX / 750).toFixed(4))
        let n = this.audio.recorderDuration * (e.offsetX / 750).toFixed(4);
        this.audio.currentTime = n;
        if (this.vShowType) {
          this.audioObj.currentTime(n);
        } else {
          this.audioObj.currentTime = n;
        }
      }
    },
    onLoadeddata (res) {
      this.audio.playOpen = true;
    },
    getMiniType (url) {
      console.log('application/x-mpegURL', url);
      let type = '';
      // 根据流地址的后缀设置好播放类型
      if (url.indexOf('.m3u8') !== -1) {
        // hls类型
        type = 'application/vnd.apple.mpegURL';
      } else {
        // rtmp类型
        type = 'application/x-mpegURL';
      }
      return type;
    },
    getVideo () {
      let _this = this;
      setTimeout(() => {
        this.myVideo = videojs(
          'my-video',
          {
            // autoplay: false, // 自动播放
            // loop: true, // 循环播放
            controls: true, // 控制元件
            width: 500, // 视频播放器显示的宽度
            height: 300, // 视频播放器显示的高度
            preload: 'none',
            bigPlayButton: true,
            posterImage: false,
            textTrackDisplay: false,
            errorDisplay: false,
            controlBar: true,
            sources: [{
              src: this.dataInfo.AttachViewUrl,
              type: 'application/x-mpegURL'
            }]
          },
          function () {
            console.log('ssasass');
            this.on('stalled', function (stalled) { // 网速失速
              _this.isError = true;
              layer.alert('网速失速');
            });
            this.on('canplaythrough', function () {
              console.log('可以播放');
              if (_this.audioObj && _this.audioObj.recorderDuration > 0) {
                _this.audio.recorderDuration = _this.audioObj.recorderDuration;
              }
            });
            this.on('ended', function () {
              console.log('暂停播放');
              _this.isProgress = false;
              _this.audioPause();
            });
            this.on('loadedmetadata', function () {
              console.log('获取资源长度完成 ');
            });
            this.on('error', function () {
              _this.isError = true;
              layer.alert('音频下载出错');
            });
            _this.audio.playOpen = true;
            // this.play();
          }
        );
        this.audioObj = this.myVideo;
        console.log('this.audioObj', this.audioObj);
      }, 500);
    },
    onError () {
      // if (this.isErrorAudio) {
      this.isError = true;
      layer.alert('音频下载出错');
      // }
      // if (Hls.isSupported()) {
      //   var hls = new Hls();// 实例化 Hls 对象
      //   hls.loadSource(this.vAudioUrl);// 传入路径
      //   hls.attachMedia(this.$refs.audio);
      //   hls.on(Hls.Events.MANIFEST_PARSED, () => { // 加载成功
      //     // setTimeout(() => {
      //     // this.audioObj = this.$refs.audio;
      //     this.isErrorAudio = true;
      //     // }, 10);
      //   });
      // }
    },
    onLoadstart () {
      console.log('准备加载');
    },
    onEnded () {
      this.isProgress = false;
      this.audioPause();
    },
    onCanplay () {
      if (this.audioObj && this.audioObj.recorderDuration > 0) {
        this.audio.recorderDuration = this.audioObj.recorderDuration;
      }
    },
    playVoice () {
      console.log('this.dataInfo.ResultStageKey', this.dataInfo.ResultStageKey, this.dataInfo.ResultStageKey != '0');
      if (this.dataInfo.ResultStageKey != '0') {
        layer.alert('音频上传中');
        return false;
      }
      if (this.isError) {
        layer.alert('音频下载出错');
        return false;
      }
      if (!this.audio.playOpen) {
        layer.alert('请等待音频下载完成');
        return false;
      }
      if (!this.audio.isPlaying) {
        // 播放
        this.$store.commit('setCurPlayingAudio', this.vAudioUrl);
        this.audioPlay();
      } else {
        // 暂停
        this.audioPause();
      }
    },
    // 播放
    audioPlay () {
      this.audio.isPlaying = true;
      this.dataInfo.currentTime = this.audio.currentTime;
      this.$bus.emit("beginAttachVisit", {
            dataInfo: this.dataInfo,
            StartPosition:  this.dataInfo.currentTime
          })
      this.$refs.audio.play();
    },
    // 暂停
    audioPause () {
      this.audio.isPlaying = false;
    
       this.dataInfo.currentTime = this.audio.currentTime;
         console.log("audioPause",this.dataInfo)
      this.$bus.emit("endAttachVisit", {
            dataInfo: this.dataInfo,
            EndPosition:  this.dataInfo.currentTime
          })
      this.$refs.audio.pause();
    },
    handleAudioTimeUpdated (res) {
      // if (res.target.currentTime == 0 && this.isProgress) {
      //   layer.alert('音频未下载完成，无法指定播放时间');
      // }
      this.audio.currentTime = res.target.currentTime;
    },
    getCurrentTime() {
        let t = 0;
        if (!this.isPlayEnd) {
          t = this.myVideo.currentTime();
        }
        return t;
      }
  }
};
</script>