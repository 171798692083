<template>
  <div class="register_form_flex pr">
    <div class="form_info_border_input">
      <input adjust-position="false"
             class="input_box"
             type="text"
             value=""
             :readonly="isPopupShow"
             v-model="dataInfo.MainDemoName"
             :placeholder="placeholder"
             @input="chgInputVal($event.target.value)" />
    </div>
    <seleted-option class="gender_ul"
                    :dataList="sexList"
                    :dataKey="dataInfo.SexKey"
                    @seletedItem="seletedItem"></seleted-option>
  </div>
</template>

<script>
import seletedOption from '../common/seleted-option/index';
export default {
  data () {
    return {
      sexList: [
        {
          value: '女生',
          key: 3,
          isActive: false
        },
        {
          value: '男生',
          key: 2,
          isActive: false
        }
      ]
    };
  },
  components: {
    seletedOption
  },
  props: {
    dataInfo: Object,    // 学生信息对象
    readonly: {			// 是否只读
      type: Boolean,
      default: false
    },
    isPopupShow: {			// 是否只读
      type: Boolean,
      default: false
    },
    placeholder: String
  },
  methods: {
    seletedItem (item) {
      if (!this.readonly) {
        this.dataInfo.SexKey = item.key;
        this.dataInfo.SexKeyValue = item.key == 3 ? '女' : '男';
      }
    },
    chgInputVal (val) {
      this.$emit('input', val);
    }
  }
};
</script>

<style>
</style>
