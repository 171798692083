<template>
  <!--ShowTypeKey-2:单行文本 -->
  <div class="form_list_content"
       :style="vFieldsWidth">
    <!-- 前后置说明文本  -->
    <hint-text v-if="(item.FieldDescription||item.FieldsThat)&&!item.Unit"
               :item="item"></hint-text>

    <!-- 前置说明+单位文本 -->
    <hint-and-unit-text v-else-if="item.FieldDescription&&item.Unit"
                        :item="item"></hint-and-unit-text>

    <!-- 单位文本 -->
    <unit-text v-else-if="item.Unit"
               :item="item"></unit-text>

    <!-- 默认文本 -->
    <show-input v-else
                :inputStyle="''"
                :inputClass="'form_list_input'"
                :item="item"></show-input>

  </div>
</template> 
<script>
import hintAndUnitText from './hint-and-unit-text';
import hintText from './hint-text';
import unitText from './unit-text';
import showInput from './show-input';
export default {
  components: {
    hintAndUnitText,
    hintText,
    unitText,
    showInput
  },
  data () {
    return {

    };
  },
  props: {
    categoryItem: Object, // 类目项
    item: Object, // 字段项
    rowWidth: Number // 总行宽
  },
  created () {
    // console.log('单行文本:', this.categoryItem, this.item);
  },
  computed: {
    vFieldsWidth () {
      let FWh = 0;
      if (Number(this.item.Length) > FWh) {
        FWh = Number(this.rowWidth) / Number(this.item.Length);
        return 'max-width:' + FWh + 'px';
      } else {
        return '';
      }
    }
  },
  methods: {

  }
};
</script>

<style>
</style>