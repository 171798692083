<template>
  <div class="report_item"
       :class="className"
       @click.stop="clickbtn">
    <div class="report_item_header button">
      {{ dataInfo.title }}
      <div class="report_item_header_btn button"
           v-if="dataInfo.btnName"
           @click.stop="clickbtn">
        {{ dataInfo.btnName }}
      </div>
      <div class="report_item_header_tip"
           style="color: #999"
           v-if="dataInfo.tipsText">
        课前截至预约{{ Number(this.$utils.getSaaSClientSetInfo(70).SetContent)
        }}{{
          Number(this.$utils.getSaaSClientSetInfo(70).SetKey == 1)
            ? "小时"
            : "天"
        }}
      </div>
    </div>
    <div class="report_item_content">
      <div class="content_compare_item"
           style="text-align: left; margin-right: 20px">
        <div class="content_item_title"
             :class="className">
          {{ isbuke ? "本月" : "今日" }}
        </div>
        <div class="content_item_data">
          <div class="content_item_data_content"
               @click.stop="goRouter(1)">
            <span style="color: #666">{{ isbuke ? "待处理" : "已达成" }}</span>
            <div style="font-size: 16px; margin-top: 5px">
              {{
                isbuke
                  ? Number(dataInfo.data.ThisMonthPendingCount)
                  : Number(dataInfo.data.todayReach)
              }}
            </div>
          </div>
          <div class="content_item_data_content"
               @click.stop="goRouter(2)">
            <div style="color: #666">{{ isbuke ? "未关联" : "未达成" }}</div>
            <div style="font-size: 16px; margin-top: 5px">
              {{
                isbuke
                  ? Number(dataInfo.data.ThisMonthNoRelevancyCount)
                  : Number(dataInfo.data.todayNotReachNum)
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="content_compare_item">
        <div class="content_item_title"
             :class="className">
          {{ isbuke ? "上月" : "明日" }}
        </div>
        <div class="content_item_data">
          <div class="content_item_data_content"
               @click.stop="goRouter(3)">
            <span style="color: #666">{{ isbuke ? "待处理" : "已达成" }}</span>
            <div style="font-size: 16px; margin-top: 5px">
              {{
                isbuke
                  ? Number(dataInfo.data.LastMonthPendingCount)
                  : Number(dataInfo.data.tomorrowReach)
              }}
            </div>
          </div>
          <div class="content_item_data_content"
               @click.stop="goRouter(4)">
            <div style="color: #666">{{ isbuke ? "未关联" : "未达成" }}</div>
            <div style="font-size: 16px; margin-top: 5px">
              {{
                isbuke
                  ? Number(dataInfo.data.LastMonthNoRelevancyCount)
                  : Number(dataInfo.data.tomorrowNotreach)
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {};
  },
  props: {
    dataInfo: Object,
    isbuke: Boolean,
    className: {
      type: String,
      default: () => {
        return "type_blue";
      },
    },
  },
  created () { },
  methods: {
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vRolePowerList, ModulePowerKey);
    },
    clickbtn () {
      this.$emit("doAfterClickFunctionBtn", this.dataInfo);
    },
    goRouter (type) {
      let btnInfo = null;

      if (this.isbuke) {
        btnInfo = {
          name: "补课管理",
          routertitle: "补课管理",
          routerName: "repairCourseManagement",
          auth: true,
          roleName: "payment",
          ModulePowerKey: 0,
          data: { dataInfo: {} },
        };
        switch (type) {
          case 1:
            btnInfo.data.dataInfo.showTypeKey = 2;
            btnInfo.data.dataInfo.startTime = this.$utils.formatDateStr(
              Date.new(Date.new().setDate(1)).toLocaleDateString(),
              "yyyy-MM-dd"
            );
            btnInfo.data.dataInfo.endTime = this.$utils.formatDateToLine(
              Date.new(Date.new().getFullYear(), Date.new().getMonth() + 1, 0)
            );
            btnInfo.data.dataInfo.isLastMonth = 0;
            break;
          case 2:
            btnInfo.data.dataInfo.showTypeKey = 4;
            btnInfo.data.dataInfo.startTime = this.$utils.formatDateStr(
              Date.new(Date.new().setDate(1)).toLocaleDateString(),
              "yyyy-MM-dd"
            );
            btnInfo.data.dataInfo.endTime = this.$utils.formatDateToLine(
              Date.new(Date.new().getFullYear(), Date.new().getMonth() + 1, 0)
            );

            break;

          case 3:
            btnInfo.data.dataInfo.showTypeKey = 2;
            btnInfo.data.dataInfo.startTime = this.$utils.formatDateToLine(
              Date.new(Date.new().getFullYear(), Date.new().getMonth() - 1, 1)
            );
            btnInfo.data.dataInfo.endTime = this.$utils.formatDateToLine(
              Date.new(Date.new().getFullYear(), Date.new().getMonth(), 0)
            );
            btnInfo.data.dataInfo.isLastMonth = 1;
            break;
          case 4:
            btnInfo.data.dataInfo.showTypeKey = 4;
            btnInfo.data.dataInfo.startTime = this.$utils.formatDateToLine(
              Date.new(Date.new().getFullYear(), Date.new().getMonth() - 1, 1)
            );
            btnInfo.data.dataInfo.endTime = this.$utils.formatDateToLine(
              Date.new(Date.new().getFullYear(), Date.new().getMonth(), 0)
            );

            break;
          default:
            break;
        }
      } else {
        btnInfo = {
          title: "约课表",
          routertitle: "约课表",
          btnName: "",
          moduleName: "",
          routerName: "appointmentCourse",
          ModulePowerKey: 0,
          data: { dataInfo: {} },
        };
        if (type == 1 || type == 3) {
          btnInfo.data.dataInfo.SubscribeCourseType = 1
        } else {
          btnInfo.data.dataInfo.SubscribeCourseType = 2
        }
        // this.$emit("doAfterClickFunctionBtn", btnInfo);
      }
      if (btnInfo) {
        if (this.vHasModulePower(btnInfo.ModulePowerKey)) {
          if (btnInfo.isComment) {
            this.$emit("openCommonCustomDialogByFactoryDialog", btnInfo);
          } else {
            this.$emit("doAfterClickFunctionBtn", btnInfo);
          }
          // 无权限
        } else {
          this.$utils.CheckModulePowerToTips(btnInfo.ModulePowerKey);
        }
      }
    },
  },
};
</script>
<style scoped>
.report_item {
  width: 100%;
  height: 106px;
  padding: 10px 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.report_item.type_blue {
  background: linear-gradient(180deg, #ddecff 0%, rgba(221, 236, 255, 0) 100%),
    linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 2px 2px 2px 0px #d7d7d733;
}
.report_item.type_yellow {
  background: linear-gradient(180deg, #fff8e5 0%, rgba(255, 248, 229, 0) 100%),
    linear-gradient(0deg, #ffffff, #ffffff);

  box-shadow: 2px 2px 2px 0px #d7d7d733;
}
.report_item.type_pink {
  background: linear-gradient(180deg, #ffeeee 0%, rgba(255, 238, 238, 0) 100%),
    linear-gradient(0deg, #ffffff, #ffffff);

  box-shadow: 2px 2px 2px 0px #d7d7d733;
}
.report_item.type_purple {
  background: linear-gradient(
      180deg,
      #e9e5ff 0%,
      #f4f2ff 0.01%,
      rgba(244, 242, 255, 0) 100%
    ),
    linear-gradient(0deg, #ffffff, #ffffff);

  box-shadow: 2px 2px 2px 0px #d7d7d733;
}
.report_item_header {
  position: relative;
  color: #333;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 24px;
}
.report_item_header::after {
  position: absolute;
  display: block;
  content: '';
  width: 18px;
  height: 18px;
  left: 70px;
  top: 3px;
  background: url('../../../../public/image/next.png') no-repeat center;
  background-size: 9px;
}
.report_item_content {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
}
.report_item_header_btn {
  width: 64px;
  height: 24px;
  border-radius: 4px;
  background: #ffffff;
  border-image-source: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #ececec, #ececec);
  color: #333;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  line-height: 24px;
}
.report_item_header_tip {
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
}
.report_item_header_btn:hover {
  color: #3498db !important;
  border: #3498db !important;
}
.report_item_content .content_compare_item {
  flex: 1;
  text-align: center;
  position: relative;
  /* cursor: pointer; */
}
/* .report_item_content .content_compare_item::after{
        position: absolute;
        display: block;
        content: '';
        width: 1px;
        height: 55px;
        right: 0px;
        top: 3px;
        border: 1px solid #E7E9EA
    } */
.report_item_content .content_compare_item:last-child::after {
  display: none;
}
.content_compare_item .content_item_title {
  width: 100%;
  height: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
  line-height: 20px;
}
.content_item_title.type_pink {
  background: #ffe6e6;
}
.content_item_title.type_purple {
  background: #e7e2ff;
}
.content_item_data {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  width: 100%;
}
.content_item_data_content {
  flex: 1;
  text-align: center;
  position: relative;
  height: 42px;
  padding-top: 5px;
  cursor: pointer;
}
.report_item_content .content_item_data_content::after {
  position: absolute;
  display: block;
  content: '';
  width: 1px;
  height: 24px;
  right: 0;
  top: 8px;
  border: 1px solid #e7e9ea;
}
.report_item_content .content_item_data_content:last-child::after {
  display: none;
}
</style>
