<template>
  <!-- 剩余课时 -->
  <div :class="{opt:isFocus,'import_list':rowType=='add','table_td':rowType!='add' }"
       style="width:74px">
    <input :class="{text_error:vCurrentCountWarning}"
           :value="rowData.CurrentCount"
           v-if="vIsEdit"
           type="text"
           ref="currentCount"
           :placeholder="rowType=='add'?'剩余课时':''"
           @keydown="keydownEvent($event)"
           @blur="blurEvent"
           @focus="focusEvent($event)"
           @input="inputEvent($event.target.value,$event)"
           @change="changeEvent($event.target.value,$event)"
           onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/\.{1,}/g,'.')">
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentCount: '',
      isFocus: false
    };
  },
  props: {
    rowType: {
      type: String,
      default: ''
    }, // 输出类型
    rowData: Object,
    tableData: Array,
    isShowPart: Boolean
  },
  computed: {
    vCurrentCountWarning () {
      let flag = false;
      let findItem = this.rowData.ExceptionList.find(obj => { return obj.TargetField == 9; });
      if (findItem) {
        flag = true;
      }
      return flag;
    },
    vIsEdit () {
      let flag = false;
      if (this.rowData.guid || this.rowType == 'add') {
        flag = true;
      }
      return flag;
    }
  },
  watch: {
    'rowData.focusField': {
      handler (n, o) {
        if (n == 'CurrentCount' && this.rowData.optioningRow && this.$refs.currentCount) {
          this.rowData.focusField = 'CurrentCount';
          this.$refs.currentCount.focus();
          // 光标定位到文本框字符串末尾
          var tObj = this.$refs.currentCount;
          if (tObj.value) {
            var sPos = tObj.value.length;
            this.$utils.setCaretPosition(tObj, sPos);
          }
        }
      },
      deep: true // 表示开启深度监听
    }
  },
  created () {
  },
  methods: {
    // 鼠标聚焦
    focusEvent (e) {
      this.isFocus = true;
      this.rowData.focusField = 'CurrentCount';
      this.$emit('onFocusEvent', 'CurrentCount');
    },
    // 键盘事件
    keydownEvent (e) {
      let tabList = this.tableData.filter(obj => { return obj.guid; }).length;
      switch (e.keyCode) {
        case 9: // Tab
          if (Number(this.rowData.Number) < tabList && this.rowType != 'add') {
            this.rowData.focusField = 'StudentKeyValue';
            this.$emit('doDownwardMove', this.rowData, 'StudentKeyValue');
          } else { // 为最后一格时，禁止点击
            e.preventDefault();
            console.log(e.keyCode);
          }
          break;
        case 13: // 回车
          if (Number(this.rowData.Number) < tabList && this.rowType != 'add') {
            this.rowData.focusField = 'StudentKeyValue';
            this.$emit('doDownwardMove', this.rowData, 'StudentKeyValue');
          }
          break;
        case 37: // 左键
          this.rowData.optioningRow = true;
          this.rowData.focusField = 'NumCount';
          break;
        case 38: // 向上键
          if (Number(this.rowData.Number) > 1) {
            this.$emit('doUpwardMove', this.rowData, 'CurrentCount');
          }
          break;
        case 39: // 右键
          if (Number(this.rowData.Number) < tabList && this.rowType != 'add') {
            this.rowData.focusField = 'StudentKeyValue';
            this.$emit('doDownwardMove', this.rowData, 'StudentKeyValue');
          }
          break;
        case 40: // 向下键
          this.$emit('doDownwardMove', this.rowData, 'CurrentCount');
          break;
        default:
          break;
      }
    },
    inputEvent (val, e) {
      if (val.toString().length > 10) {
        e.target.value = (val === '' ? '' : Number(val.toString().substr(0, 10)));
        this.rowData.CurrentCount = e.target.value;
      }
    },
    changeEvent (val, e) {
      this.rowData.CurrentCount = (val === '' ? '' : this.$utils.mAmountType(val, 1));
    },
    // 失去焦点事件
    blurEvent () {
      this.rowData.focusField = '';
      this.isFocus = false;
      if (this.rowData.CurrentCount && isNaN(this.rowData.CurrentCount)) {
        this.rowData.CurrentCount = this.rowData.CurrentCount.replace(/[^\d.]/g, '');// 校验数字类型（字符串提取数字和小数点）
      }
      this.verificationCurrentCount();
      this.rowData.updateTime = Date.new();
      this.$emit('onBlurEvent', this.rowData, 'CurrentCount');
    },
    // 验证 剩余课时
    // 异常TargetField：9-》 1：剩余课时[非数字]填写错误，2：剩余课时[负数]必须大于0，3：剩余课时超出99999，4：剩余课时不能大于购买课时，5：剩余课时未填写
    verificationCurrentCount () {
      if (typeof this.rowData.NumCount === 'number') {
        this.rowData.NumCount = Number(this.rowData.NumCount);
      }
      if (typeof this.rowData.CurrentCount === 'number') {
        this.rowData.CurrentCount = Number(this.rowData.CurrentCount);
      }
      console.log(this.rowData.CurrentCount, 'this.rowData.CurrentCount');
      // 1：剩余课时[非数字]填写错误
      let NANErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 9 && obj.TypeKey == 1; });
      if ((!this.rowData.CurrentCount || this.rowData.CurrentCount > 0) && NANErrorIndex >= 0) {
        this.rowData.ExceptionList.splice(NANErrorIndex, 1);
      }
      // 2：剩余课时[负数]必须大于0
      let negativeErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 9 && obj.TypeKey == 2; });
      if ((this.rowData.CurrentCount === 0 || this.rowData.CurrentCount < 0) && negativeErrorIndex < 0) {
        this.rowData.ExceptionList.push({ TargetField: 9, TypeKey: 2, TypeKeyValue: '剩余课时必须大于0' });
      } else if ((this.rowData.CurrentCount > 0 || this.rowData.CurrentCount === '') && negativeErrorIndex >= 0) {
        this.rowData.ExceptionList.splice(negativeErrorIndex, 1);
      }
      // 3：剩余课时超出99999
      let maxErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 9 && obj.TypeKey == 3; });
      if (this.rowData.CurrentCount > 99999 && maxErrorIndex < 0) {
        let maxItem = { TargetField: 9, TypeKey: 3, TypeKeyValue: '剩余课时超出99999' };
        this.rowData.ExceptionList.push(maxItem);
      } else if (this.rowData.CurrentCount <= 99999 && maxErrorIndex >= 0) {
        this.rowData.ExceptionList.splice(maxErrorIndex, 1);
      }
      // 4：剩余课时不能大于购买课时
      let overErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 9 && obj.TypeKey == 4; });
      if (typeof this.rowData.CurrentCount === 'number' && typeof this.rowData.NumCount === 'number') {
        if (this.rowData.NumCount >= 0 && this.rowData.CurrentCount > this.rowData.NumCount && overErrorIndex < 0) {
          let overItem = { TargetField: 9, TypeKey: 4, TypeKeyValue: '剩余课时不能大于购买课时' };
          this.rowData.ExceptionList.push(overItem);
        } else if (this.rowData.NumCount > 0 && this.rowData.CurrentCount <= this.rowData.NumCount && overErrorIndex >= 0) {
          this.rowData.ExceptionList.splice(overErrorIndex, 1);
        }
      } else if ((this.rowData.CurrentCount === '' || this.rowData.CurrentCount === null) && overErrorIndex >= 0) {
        this.rowData.ExceptionList.splice(overErrorIndex, 1);
      }

      // 在读课程已选中
      if (this.rowData.ApplyClassKeyValue) {
        // 5：剩余课时未填写
        let emptyErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 9 && obj.TypeKey == 5; });
        if ((this.rowData.CurrentCount === '' || this.rowData.CurrentCount === null) && emptyErrorIndex < 0) {
          this.rowData.ExceptionList.push({ TargetField: 9, TypeKey: 5, TypeKeyValue: '剩余课时未填写' });
        } else if (emptyErrorIndex >= 0 && (this.rowData.CurrentCount > 0 || this.rowData.CurrentCount === 0)) {
          this.rowData.ExceptionList.splice(emptyErrorIndex, 1);
        }
        // 剩余课时一切正常
        // if (this.rowData.CurrentCount <= 99999 &&
        //   ((this.rowData.NumCount > 0 && this.rowData.CurrentCount <= this.rowData.NumCount) || (this.rowData.NumCount == 0 && this.rowData.CurrentCount > 0)) &&
        //   emptyErrorIndex >= 0) {
        //   this.rowData.ExceptionList.splice(emptyErrorIndex, 1);
        // }
      } else { // 在读课程未选择
        let unSelectedErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 4 && obj.TypeKey == 3; });
        if ((this.rowData.CurrentCount > 0 || this.rowData.CurrentCount === 0) && unSelectedErrorIndex < 0) {
          this.rowData.ExceptionList.push({ TargetField: 4, TypeKey: 3, TypeKeyValue: '在读课程未选择' });
        } else if ((this.rowData.CurrentCount === '' || this.rowData.CurrentCount === null) && unSelectedErrorIndex >= 0) {
          if (!this.rowData.PayTime && !this.rowData.ExpireTime && (this.rowData.ActualPayAmount === '' || this.rowData.ActualPayAmount === null) && (this.rowData.NumCount === '' || this.rowData.NumCount === null) && unSelectedErrorIndex >= 0) {
            this.rowData.ExceptionList.splice(unSelectedErrorIndex, 1);
          }
        }
      }
    }
  }
};
</script>

<style>
</style>