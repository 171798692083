<template>
  <div class="play_video">
    <!-- 视频 -->
    <div class="courseware_add_video"
         ref="video">
      <!-- <video id="myVideo"
             class="video-js vjs-default-skin"
             :width="874"
             :height="492"
             data-setup="{}"
             controls
             @play="playVideo">
        <source :src="vUrl"
                type="application/vnd.apple.mpegURL" />
      </video> -->
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      myVideo: null,
      isPlaying: false,
      isPlayEnd: false
    };
  },
  props: {
    dataInfo: Object
  },
  computed: {
    vUrl () {
      if (this.dataInfo.AttachViewUrl) {
        if (this.dataInfo.AttachViewUrl.indexOf('http') == -1) {
          return this.$store.getters.PCWebURL + this.dataInfo.AttachViewUrl + '?sign=' + this.$store.getters.signData.Sign;
        } else {
          return this.dataInfo.AttachViewUrl + '?sign=' + this.$store.getters.signData.Sign;
        }
      } else {
        return this.$store.getters.PCWebURL;
      }
    },
    vCoverUrl () {
      this.dataInfo.CoverUrl = this.dataInfo.CoverUrl || '';
      if (this.dataInfo.CoverUrl.indexOf('http') == 0) {
        return this.dataInfo.CoverUrl;
      } else {
        return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
      }
    }
  },
  watch: {
    // vUrl (n, o) {
    //   if (o && n != o) {
    //     if (this.myVideo) {
    //       this.myVideo.reset();
    //       this.myVideo.src({
    //         src: this.vUrl,
    //         type: 'application/vnd.apple.mpegURL'
    //       });
    //       this.myVideo.load();
    //     } else {
    //       this.initVideo();
    //     }
    //   }
    // },
    dataInfo (n, o) {
      if (n) {
        if (o) {
          o.currentTime = this.getCurrentTime();
          if (this.isPlaying) {
            this.$bus.emit('endAttachVisit', {
              dataInfo: o,
              EndPosition: o.currentTime
            });
          }
        }

        this.openVideo();
      }
    }
  },
  mounted () {
    if (this.dataInfo.AttachViewUrl) {
      this.initVideo();
    }
  },
  destroyed () {
    this.dataInfo.currentTime = this.getCurrentTime();
    if (this.isPlaying) {
      this.$bus.emit('endAttachVisit', {
        dataInfo: this.dataInfo,
        EndPosition: this.dataInfo.currentTime
      });
    }
    this.myVideo.dispose();
  },
  methods: {
    getMiniType (url) {
      let type = '';
      // 根据流地址的后缀设置好播放类型
      if (url.indexOf('.m3u8') !== -1) {
        // hls类型
        type = 'application/vnd.apple.mpegURL';
      } else if (url.indexOf('.mp4') !== -1) {
        // mp4类型
        type = 'video/mp4';
      } else if (url.indexOf('.flv') !== -1) {
        // flv类型
        type = 'video/flv';
      } else if (url.indexOf('.3gp') !== -1) {
        // flv类型
        type = 'video/3gpp';
      } else {
        // rtmp类型
        type = 'application/vnd.apple.mpegURL';
      }
      return type;
    },
    // #示例代码,部分变量是业务中用到的
    initVideo () {
      let video = document.createElement('video');
      video.id = 'myVideo';
      video.style = ' min-width: 874px;min-height: 492px;padding: 0px;';
      video.className = 'video-js vjs-default-skin';
      video.controls = true;
      // video.setAttribute('playsinline', true) //IOS微信浏览器支持小窗内播放
      // video.setAttribute('webkit-playsinline', true) //这个bai属性是ios 10中设置可以让视频在小du窗内播放，也就是不是全屏播放的video标签的一个属性
      // video.setAttribute('x5-video-player-type', 'h5') //安卓 声明启用同层H5播放器 可以在video上面加东西
      let source = document.createElement('source');
      source.src = this.vUrl; // 设定的流地址
      console.log('source.src', this.vUrl);
      source.type = this.getMiniType(this.vUrl);
      // 将播放源添加到video的子级
      video.appendChild(source);
      // 挂载到视频容器中
      this.$refs.video.appendChild(video);
      // 获取屏幕宽高计算出需要展现的比例
      // let aspectRatio = '16:9' //默认横屏
      // //activityDetail变量是我业务中用到的，请自行根据实际情况做调整
      // if (this.activityDetail.style.mode == 1) {
      //   //如果是竖屏模式，动态计算出设备宽高，设定一个比例
      //   const systemInfo = uni.getSystemInfoSync()
      //   aspectRatio = systemInfo.windowWidth + ':' + systemInfo.windowHeight
      // }
      let that = this;
      setTimeout(() => {
        this.myVideo = videojs(
          'myVideo', {
          poster: this.vCoverUrl,
          fluid: true,
          bigPlayButton: true,
          textTrackDisplay: false,
          errorDisplay: false,
          FullscreenToggle: false,
          LoadProgressBar: false,
          PlayProgressBar: false,
          ProgressControl: false,
          preload: 'metadata',
          controlBar: {
            volumePanel: {
              // 声音样式
              inline: false // 不使用水平方 式
            },
            timeDivider: true, // 时间分割线
            durationDisplay: true, // 总时间
            progressControl: true, // 进度条
            remainingTimeDisplay: true, // 当前以播放时间
            fullscreenToggle: false, // 全屏按钮
            pictureInPictureToggle: false // 画中画
          }
        },
          function () {
            this.on('error', function () {
              // 请求数据时遇到错误
              console.log('请求数据时遇到错误');
            });
            this.on('loadedmetadata', function () {
              console.log('loadedmetadata-视频源数据加载完成', that.dataInfo.currentTime, this.duration());
              // 设置上次播放时间lastLearnTime(秒)
              if (that.dataInfo.currentTime) {
                this.currentTime(that.dataInfo.currentTime);
              }
            });
            // this.on('stalled', function() {
            //   //网速失速
            //   console.log('网速失速')
            // })
            this.on('play', function () {
              // 开始播放
              console.log('开始播放');
              that.isPlaying = true;
              that.isPlayEnd = false;
              that.$bus.emit('beginAttachVisit', {
                dataInfo: that.dataInfo,
                StartPosition: that.getCurrentTime()
              });
            });
            this.on('pause', function () {
              // 暂停
              console.log('暂停');
              that.isPlaying = false;
              that.$bus.emit('endAttachVisit', {
                dataInfo: that.dataInfo,
                EndPosition: that.getCurrentTime()
              });
            });
            this.on('ended', function () {
              console.log('视频播放结束');
              that.isPlayEnd = true;
            });
            // this.on('timeupdate', function() {})
          }
        );
      }, 500);
    },
    openVideo () {
      try {
        this.isPlayEnd = false;
        this.myVideo.poster(this.vCoverUrl);
        this.myVideo.src({
          src: this.vUrl,
          type: this.getMiniType(this.vUrl)
        });
      } catch (e) {
        console.log('watch dataInfo', e);
      }
    },
    getCurrentTime () {
      let t = 0;
      if (!this.isPlayEnd) {
        t = this.myVideo.currentTime();
      }
      return t;
    }
  }
};
</script>
<style scoped>
.courseware_add_video{
 
}
#myVideo{
  width: 874px;
  height: 492px;
}
</style>