<template>
  <div class="mendian_backstage_summarizing">
      <headerDayReport></headerDayReport>
      <headerMonthReport></headerMonthReport>
      <headerReport></headerReport>
  </div>
</template>
<script>
import headerDayReport from './component/header-day-report';
import headerMonthReport from './component/header-month-report';
import headerReport from './component/header-report';
export default {
  data () {
    return {

    };
  },
  components: {
    headerDayReport,
    headerMonthReport,
    headerReport
  },
  created () {

  }

};
</script>

