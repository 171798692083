<template>
  <div class="interpretationOfSalesTrackingData">
    <div class="interpretationHead">
      <div class="interpretationHeadTitle">追销数据解读：节点丨人效丨来源转化率分析</div>
      <div class="title_date_select"
           style="margin-left: 0px;">
        <span class="interpretationHeadDate">日期范围</span>
        <div class="el-select">
          <div class="el-input el-input--small el-input--suffix is-focus">
            <single-year-picker @chgDate="chgDate"
                                ref="monthPicker"
                                :type="'month'"></single-year-picker>
          </div>
        </div>
      </div>
      <div class="interpretationHeadBoxList">
        <div class="interpretationBox borderBlue">
          <a href="#target-element1">
            <div class="titleText">报名收款</div>
            <div class="titleTextP">￥<span class="titleSpan">{{vTransformationData.ActualColAmount}}</span></div>
          </a>
        </div>
        <div class="headImgBox">
          <img src="../../../../public/image/Subtractright.png"
               class="headBoxImg">
        </div>
        <div class="interpretationBox">
          <a href="#target-element2">
            <div class="titleText">新增线索</div>
            <div class="titleTextP">{{vTransformationData.NewThreadCount}}</div>
          </a>
        </div>
        <div class="headImgBox">
          <img src="../../../../public/image/Subtractright.png"
               class="headBoxImg">
        </div>
        <div class="interpretationBox">
          <a href="#target-element3">
            <div class="titleText">诺访/到校</div>
            <div class="titleSpan">{{vTransformationData.PromiseVisitCount+'/'+vTransformationData.GetToSchoolCount}}</div>
            <div class="bottomTetx">诺访到校率</div>
            <div class="bottomTetx colorBlack">{{vTransformationData.PromiseVisitTransformationRate+'%'}}</div>
          </a>
        </div>
        <div class="headImgBox">
          <img src="../../../../public/image/Subtractright.png"
               class="headBoxImg">
        </div>
        <div class="interpretationBox">
          <a href="#target-element4">
            <div class="titleText">约试课/已试课</div>
            <div class="titleSpan">{{vTransformationData.InvitedClassCount+'/'+vTransformationData.TrialLessonCount}}</div>
            <div class="bottomTetx">试课出勤率</div>
            <div class="bottomTetx colorBlack">{{vTransformationData.TrialLessonTransformationRate+'%'}}</div>
          </a>
        </div>
        <div class="headImgBox">
          <img src="../../../../public/image/Subtractright.png"
               class="headBoxImg">
        </div>
        <div class="interpretationBox flexrow">
          <a href="#target-element5"
             style="display: contents;">
            <div>
              <div class="titleText">转化报名</div>
              <div class="titleSpan">{{vTransformationData.SignUpCount}}</div>
              <div class="bottomTetx">到校转化率</div>
              <div class="bottomTetx colorBlack">{{vTransformationData.GetToSchoolTransformationRate+'%'}}</div>
            </div>
            <div class="marginTop22">
              <div class="bottomTetx">线索转化率</div>
              <div class="bottomTetx colorBlack"
                   style="margin-bottom: 5px;">{{vTransformationData.ThreadTransformationRate+'%'}}</div>
              <div class="bottomTetx">试课转化率</div>
              <div class="bottomTetx colorBlack">{{vTransformationData.TrialSignUpTransformationRate+'%'}}</div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="interpretationHead"
         id="target-element1">
      <commonTableView :searchObj="searchObj"
                       :modeType="1"></commonTableView>
    </div>
    <div class="interpretationHead"
         id="target-element2">
      <commonTableView :searchObj="searchObj"
                       :modeType="2"></commonTableView>
    </div>
    <div class="interpretationHead"
         id="target-element3">
      <commonTableView :searchObj="searchObj"
                       :modeType="3"></commonTableView>
    </div>
    <div class="interpretationHead"
         id="target-element4">
      <commonTableView :searchObj="searchObj"
                       :modeType="4"></commonTableView>
    </div>

    <div class="interpretationHead"
         id="target-element5">
      <registrationConversionTable :searchObj="searchObj"></registrationConversionTable>
    </div>
  </div>
</template>

<script>
import singleYearPicker from '../../base-module/date-picker-common/single-year-picker';
import commonTableView from './report/common-table-view.vue';
import registrationConversionTable from './report/registration-conversion-table';
import { TransformationAnalysis } from '../../../API/workbench.js';
export default {
  name: 'interpretationOfSalesTrackingData',
  data () {
    return {
      searchObj: {
        StartTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        EndTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0))
      },
      transformationData: {
        ActualColAmount: 0,
        GetToSchoolCount: 0,
        GetToSchoolTransformationRate: 0,
        InvitedClassCount: 0,
        NewThreadCount: 0,
        PromiseVisitCount: 0,
        PromiseVisitTransformationRate: 0,
        SignUpCount: 0,
        ThreadTransformationRate: 0,
        TrialLessonCount: 0,
        TrialLessonTransformationRate: 0,
        TrialSignUpTransformationRate: 0
      }
    };
  },
  components: {
    singleYearPicker,
    commonTableView,
    registrationConversionTable
  },
  created () {

  },
  computed: {
    vTransformationData () {
      return this.transformationData;
    }
  },
  mounted () {
    this.getTransformationAnalysis();
  },
  methods: {
    chgDate (val, startTime, endTime) {
      this.searchObj.StartTime = startTime;
      this.searchObj.EndTime = endTime;
      this.getTransformationAnalysis();
    },
    getTransformationAnalysis () {
      TransformationAnalysis(this.searchObj).then(result => {
        this.transformationData = result.data;
      }, error => {
        layer.alert(error.msg);
      });
    }
  }
};
</script>

<style scoped>
.interpretationOfSalesTrackingData {
  padding: 20px 40px;
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.interpretationHead {
  background: #fff;
  margin: 0 auto;
  /* width: 1800px; */

  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 20px;
  max-width: 1920px;
  min-width: 1366px;
}
.interpretationHeadTitle {
  height: 27px;
  font-family: Alibaba PuHuiTi;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  color: #333333;
  margin: 10px 0;
}
.title_date_select {
  display: flex;
  flex-direction: row;
}
.title_date_select >>> .title_date.date_year input {
  border: 1px solid #ddd;
}
.interpretationHeadDate {
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  color: #666666;
  margin-right: 15px;
}
.el-select {
  width: 120px;
  border-radius: 4px;
}

.interpretationBox {
  width: 215px;
  height: 122px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px;
  border: 1.25px solid #ececec;
}

.interpretationHeadBoxList {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.headImgBox {
  width: 30px;
  -ms-flex-line-pack: center;
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.headBoxImg {
}
.borderBlue {
  border: 1.25px solid #3b75ff !important;
}
.titleText {
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  color: #666666;
}
.titleTextP {
  font-family: Alibaba PuHuiTi;
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  color: #333333;
}
.titleSpan {
  font-family: Arial;
  font-size: 34px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}
.bottomTetx {
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #999999;
}
.colorBlack {
  color: #333333;
}
.flexrow {
  flex-direction: row !important;
  justify-content: space-between;
}
.marginTop22 {
  margin-top: 22px;
}
</style>