<template>
  <div>
    <div class="shade" @click="cancelClick"></div>
    <div class="course_table_fixed_bottom">
      <div class="fixed_popup_title">
        <div class="font_black">
          {{formTitle}}
        </div>
      </div>
      <slot></slot>
      <div class="popup_exit_btn"
            @click="cancelClick">
        取消
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    formTitle: {
      type: String,
      default: '标题'
    }
  },
  methods: {
    // 确定
    doClick () {
      this.$emit('confirmClick');
    },
    // 取消
    cancelClick () {
      this.$emit('cancelClick');
    }
  }
};
</script>

  <style>
  .course_table_fixed_bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 1000;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
}
.fixed_popup_title {
    font-size: 14px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    line-height: 20px;
    font-weight: bold;
    padding: 15px 0;
    padding-left: 15px;
    position: relative;
    border-bottom: 1px solid rgba(236,236,236,.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333;
}
.popup_exit_btn {
    font-size: 14px;
    line-height: 55px;
    border-top: 1px solid rgba(236,236,236,.5);
    text-align: center;
    margin-top: -1px;
    z-index: 2;
    position: relative;
    color: black;
}
</style>
