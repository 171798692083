<template>
  <div class="material_list"
       @click="clickEvent">
    <div class="list_img"
         :style="{'border':this.item.AttachTypeKey==3?'1px solid #ececec':''}">
      <div class="list_number">{{ item.ShowOrder }}</div>
      <img class="img_box"
           :src="vUrl"
           alt=""
           :onerror="defaultImg">
    </div>
    <div class="list_name "
         :class="vClass">
      {{item.MainDemoName||"未命名"}}
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  computed: {
    vUrl () {
      if (this.item.CoverUrl.indexOf('data:image/png;') != -1) {
        return this.item.CoverUrl;
      } else {
        return this.$store.getters.CDNURL + this.item.CoverUrl;
      }
    },
    vClass () {
      if (this.item.AttachTypeKey == '1') {
        return 'type_01';
      } else if (this.item.AttachTypeKey == '2') {
        return 'type_02';
      } else if (this.item.AttachTypeKey == '3') {
        return 'type_04';
      } else if (this.item.AttachTypeKey == '4') {
        return 'type_03';
      } else if (this.item.AttachTypeKey == '5') {
        return 'type_05';
      }
    },
    // 默认图片
    defaultImg () {
      if (this.item.AttachTypeKey == '1') {
        return 'this.src="' + require('../../../../../public/image/pic.png') + '"';
        // return require('../../../../../public/image/pic.png');
      } if (this.item.AttachTypeKey == '2') {
        return 'this.src="' + require('../../../../../public/image/courseware_list_type01.png') + '"';
      } else if (this.item.AttachTypeKey == '3') {
        return 'this.src="' + require('../../../../../public/image/courseware_list_type02.png') + '"';
      } else if (this.item.AttachTypeKey == '4') {
        return 'this.src="' + require('../../../../../public/image/courseware_list_type03.png') + '"';
      } else if (this.item.AttachTypeKey == '5') {
        return 'this.src="' + require('../../../../../public/image/courseware_pdf_cover.png') + '"';
      }
    }
  },
  methods: {
    clickEvent () {
      this.$emit('clickEvent', this.item);
    }
  }
};
</script>

