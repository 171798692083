<template>
	<div class="article_details_comment dynamic_content_body">
		<div class="article_details_comment_title list_credit_type">
			<div class="title_left flex">
				<span class="title_text">作业评分</span>
				<div class="list_credit">
					<div class="list_credit_img" v-for="(obj,key) in 5" :key="key" :class="{'opt':key<Number(Currency)}"></div>
				</div>
			</div>
			<!-- <div class="title_right" @click="remarkEdit">
				修改
			</div> -->
		</div>
		<div class="comment_list">
			<span class="font_blue">{{dataInfo.TeacherKeyValue}}：</span>
			<span v-html="$utils.translationSchoolText(dataInfo.Content)">
			<!-- {{dataInfo.Content}} -->
			</span>
		</div>
		<!-- 批改附件内容 -->
		<div class="work_attach_bottom" v-if="dataInfo.AttachList.length>0">
			<browse-item-content :dataInfo="dataInfo" :noShowContent="true"></browse-item-content>
			<!-- <attach-content-list :AttachList="dataInfo.AttachList" :content="dataInfo.Content"></attach-content-list> -->
		</div>
		<div class="complete_the_job_date">
			<span>{{$utils.replyTime(dataInfo.CreateTime)}}</span>
		</div>
	</div>
</template>

<script>
import browseItemContent from '../../../common-component/browse-box/browse-item-content'
import attachContentList from '../../common/attach-content-list.vue';
export default {
  components: {
	  attachContentList,browseItemContent
  },
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object, // 作业项
    Currency: [Number, String]// 学币
  },
  methods: {
    // 修改点评
    remarkEdit () {
		 this.$emit('remarkEdit');
    }
  }
};
</script>

<style>

</style>
