<template>
  <!-- 联系我们 -->
  <div class="WKT_index_from">
    <div class="WKT_index_from_title">
      联系我们
    </div>
    <div class="WKT_contact_info"
         v-if="dataInfo">
      <input-edit-form :titleName="'门店地址'"
                       :isReadonly="true"
                       :value="dataInfo.address"
                       @doEditBtnClick="doEditAddressUsClick"></input-edit-form>
      <input-edit-form :titleName="'门店电话'"
                       :isReadonly="true"
                       :value="dataInfo.tel"
                       @doEditBtnClick="doEditStoreThePhoneClick"></input-edit-form>
      <div class="">
        <div class="WKT_slideshow_title">
          <span>环境展示</span>
          <span class="font_gray">尺寸：640px*477px</span>
        </div>
        <div class="WKT_slideshow_ul">
          <environment-show-picture-list v-for="(item,index) in dataInfo.imgList"
                                         :Item="item"
                                         :Index="index"
                                         :key="index"
                                         :dataList="dataInfo.imgList"
                                         @doChangeImageItemClick="doChangeImageItemClick"></environment-show-picture-list>
          <!-- 上传图片，最多只能添加4张 这里不直接隐藏，防止父集的clientWidth取不到值-->
          <div :style="dataInfo.imgList&&dataInfo.imgList.length<4?'':'opacity: 0;pointer-events: none'">
            <crop-upload-img ref="uploadImg"
                             :imgClass="''"
                             :imgHeight="477"
                             :imgWidth="640"
                             @uploadImgSuccess="doAfterUploadImgSuccess"></crop-upload-img>
          </div>

        </div>
      </div>
    </div>
    <custom-dialog :title="selectMendianInfoEdit.isShowName"
                   :visible.sync="selectMendianInfoEdit.isShow"
                   :before-close="doCloseDialogClick">
      <div class="mendian_setting_content"
           v-if="selectMendianInfoEdit.isShowType=='address'">
        <input-text :formTitle="'门店地址'"
                    :readonly="false"
                    :formPlaceholder="'必填'"
                    :required="true"
                    style="padding-right: 22px"
                    v-model="selectMendianInfoEdit.address"></input-text>
      </div>
      <div class="mendian_setting_content "
           v-else-if="selectMendianInfoEdit.isShowType=='tel'">
        <input-phone :formTitle="'门店电话'"
                     :formPlaceholder="'必填'"
                     :required="true"
                     :numberType="'text'"
                     style="padding-right: 22px"
                     :readonly="false"
                     v-model="selectMendianInfoEdit.tel"></input-phone>
      </div>
      <!-- 按钮组 -->
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="submitOrganizationName"
                             @cancelClick="doCloseDialogClick"></save-cancel-btn-group>
    </custom-dialog>

  </div>
</template>

<script>
import inputEditForm from '../common/input-edit-form';
import cropUploadImg from '../common/crop-upload-img';
import environmentShowPictureList from './environment-show-picture-list';
import { AmendOrganizationAddress, AmendOrganizationPhone, AddOrganizationContextDiagram, AmendOrganizationContextDiagram } from '../../../API/workbench';
export default {
  components: { environmentShowPictureList, inputEditForm, cropUploadImg },
  data () {
    return {
      // 门店信息修改
      selectMendianInfoEdit: { isShow: false, isShowType: '', isShowName: '', address: '', tel: '' },
      imgKey: 1, // 新增的环境展示图片位置
      changeImgId: 0// 上传的图片，0为新增，反之修改
    };
  },
  props: {
    dataInfo: {// WKT门店信息
      type: Object,
      default: function () {
        return {
          id: 0, // 门店id
          address: '', // 门店地址
          tel: ''// 门店电话
        };
      }
    }
  },
  created () {
    console.log(this.dataInfo, '123');
  },
  watch: {

  },
  computed: {

  },
  methods: {
    // 修改门店地址
    doEditAddressUsClick () {
      this.selectMendianInfoEdit.isShow = true;
      this.selectMendianInfoEdit.isShowType = 'address';
      this.selectMendianInfoEdit.isShowName = '修改门店地址';
      this.selectMendianInfoEdit.address = this.dataInfo.address;
    },
    // 修改门店电话
    doEditStoreThePhoneClick () {
      this.selectMendianInfoEdit.isShow = true;
      this.selectMendianInfoEdit.isShowType = 'tel';
      this.selectMendianInfoEdit.isShowName = '修改门店电话';
      this.selectMendianInfoEdit.tel = this.dataInfo.tel;
    },
    // 确认修改中
    submitOrganizationName (callBack) {
      let flag = false;
      if (this.selectMendianInfoEdit.isShowType == 'address') { // 修改门店地址
        if (!this.selectMendianInfoEdit.address || !this.selectMendianInfoEdit.address.replace(/(^\s*)|(\s*$)/g, '')) {
          layer.alert('请输入门店地址');
          flag = true;
        }
        if (flag) {
          if (callBack) {
            callBack();
          }
          return false;
        }
        AmendOrganizationAddress(this.selectMendianInfoEdit.address).then(result => {
          console.log(result.data, '门店地址');
          this.selectMendianInfoEdit.isShow = false;
          this.dataInfo.address = this.selectMendianInfoEdit.address;
          layer.alert('门店地址修改成功');
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      } else if (this.selectMendianInfoEdit.isShowType == 'tel') { // 修改门店电话
        if (!this.selectMendianInfoEdit.tel || !this.selectMendianInfoEdit.tel.replace(/(^\s*)|(\s*$)/g, '')) {
          layer.alert('请输入门店电话');
          flag = true;
        }
        if (flag) {
          if (callBack) {
            callBack();
          }
          return false;
        }
        AmendOrganizationPhone(this.selectMendianInfoEdit.tel).then(result => {
          console.log(result.data, '门店电话');
          this.selectMendianInfoEdit.isShow = false;
          this.dataInfo.tel = this.selectMendianInfoEdit.tel;
          layer.alert('门店电话修改成功');
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      }
    },
    // 取消修改关闭对话框
    doCloseDialogClick () {
      this.selectMendianInfoEdit.isShow = false;
    },
    // 环境展示图(添加/修改)
    doAfterUploadImgSuccess (url) {
      console.log(url, '添加环境展示图');
      if (url) {
        if (this.changeImgId > 0) { // 修改
          AmendOrganizationContextDiagram(this.changeImgId, url).then(result => {
            this.dataInfo.imgList.forEach(o => {
              if (o.id == this.changeImgId) { o.imgUrl = url; }
            });
            layer.alert('图片修改成功');
            this.changeImgId = 0;
          }, error => {
            layer.alert(error.msg);
          });
        } else { // 添加
          this.imgKey = this.dataInfo.imgList.length == 0 ? 1 : this.dataInfo.imgList.length;
          AddOrganizationContextDiagram(url, this.imgKey).then(result => {
            console.log(result.data, '添加成功');
            this.dataInfo.imgList.push(result.data);
            layer.alert('图片添加成功');
            this.changeImgId = 0;
          }, error => {
            layer.alert(error.msg);
          });
        }
      }
    },
    // 选择图片修改环境展示图
    doChangeImageItemClick (e, item) {
      console.log(this.$refs.uploadImg, 'e');
      this.$refs.uploadImg.chooseImg(e);
      this.changeImgId = item.id;
    }
  }
};
</script>

<style>
</style>