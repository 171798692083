import {
  fetchToken
} from '../utils/fetch';

import qs from 'qs';
// 学生已登记面容查询翻页 WEBV.FaceRecognition.MessageSearchFaceRecognition
export function getFaceRecognitionStuList (data) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.FaceRecognition.MessageSearchFaceRecognition',
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      searchText: data.searchText
    },
    method: 'GET'
  });
}
// 录入面容
export function AddStudentFace (FaceImgPath, OLAPKey, IsHeadImgKey) {
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.FaceRecognition.AddStudentFace',
      FaceImgPath,
      OLAPKey,
      IsHeadImgKey
    },
    method: 'GET'
  });
}

// 刷脸 签到
export function AttendanceStudentFace (ImageBase64) {
  let imageData = {
    Image: ImageBase64
  };
  return fetchToken({
    url: process.env.VUE_APP_WKT_API_Url,
    params: {
      service: 'WEBV.FaceRecognition.AttendanceStudentFace'
    },
    method: 'post',
    data: qs.stringify(imageData)
  });
}
