<template>
  <div>
    <div v-for="(item,index) in mergeSearchConditionAndParams"
         :key="index">
      <!-- 日期范围：{类型，标题，值} -->
      <date-picker v-if="item.type==searchConditionType.dateRange"
                   v-model="mergeSearchConditionAndParams[index]"></date-picker>
      <!-- 下拉选择：{类型，标题，值，数据源} -->
      <select-option v-if="item.type==searchConditionType.selectOption"
                     v-model="mergeSearchConditionAndParams[index]"></select-option>
      <!-- 百分比范围：{类型，标题，值，数据源} -->
      <number-range-percent v-if="item.type==searchConditionType.numberRangePercent"
                            v-model="mergeSearchConditionAndParams[index]">
      </number-range-percent>
      <!-- 数字范围：{类型，标题，值，数据源} -->
      <number-range v-if="item.type==searchConditionType.numberRange"
                    v-model="mergeSearchConditionAndParams[index]">
      </number-range>
      <!-- 数字范围：{类型，标题，值，数据源} -->
      <single-tree v-if="item.type==searchConditionType.singleTree"
                   v-model="mergeSearchConditionAndParams[index]">
      </single-tree>
      <!-- 可继续扩展多种类型 -->
    </div>
  </div>
</template>
<script>
import datePicker from './date-picker';
import selectOption from './select-option';
import numberRange from './number-range';
import numberRangePercent from './number-range-percent';
import singleTree from './single-tree';
export default {
  data () {
    return {
      searchConditionType: {}, // 扩展查询条件类型
      mergeSearchConditionAndParams: [], // 合并查询条件和参数
      selectedParams: []
    };
  },
  props: {
    extendSearchCondition: {// 扩展查询条件
      type: Array,
      default: function () {
        return {
          type: 0,
          formTitle: '',
          fieldList: [
            {
              name: '',
              defaultValue: '',
              validateHandle: () => { },
              sourceData: []
            }
          ]
        };
      }
    }
  },
  components: {
    selectOption,
    datePicker,
    numberRange,
    singleTree,
    numberRangePercent
  },
  created () {
    this.searchConditionType = this.$initJson.extendSearchConditionType;
    this.showExtendSearchCondition([]);
    this.mergeSearchConditionAndParams = this.$utils.parseJson(this.extendSearchCondition);
  },
  methods: {
    // 点击搜索 从扩展条件列表获取显示参数 (收集selectedParams)
    getSelectedParams () {
      this.selectedParams = [];
      var flag = true;
      this.mergeSearchConditionAndParams.forEach(condition => {
        condition.fieldList.forEach((param, index) => {
          // 默认值与初始值不一样
          if (param.defaultValue !== param.value) {
            // 日期需要转换成指定格式
            if (condition.type == this.searchConditionType.dateRange) {
              param.value = param.value ? this.$utils.formatDateToLine(Date.new(param.value)) : '';
            }
            // 验证
            if (param.validateHandle) {
              flag = param.validateHandle(param.value);
            }
            // 显示参数
            this.selectedParams.push({ name: param.name, value: param.value });
          }
        });
        // 数值范围 起始值不能大于结束值
        if (condition.type == this.searchConditionType.numberRange || condition.type == this.searchConditionType.numberRangePercent) {
          if (parseInt(condition.fieldList[0].value) > parseInt(condition.fieldList[1].value)) {
            layer.alert(condition.formTitle + '起始值不能大于结束值');
            flag = false;
          }
          // 日期范围 起始日期不能大于结束日期
        } else if (condition.type == this.searchConditionType.dateRange) {
          if (Date.new(condition.fieldList[0].value).getTime() > Date.new(condition.fieldList[1].value).getTime()) {
            layer.alert(condition.formTitle + '开始日期不能大于结束日期');
            flag = false;
          }
        }
      });
      // 若有一项验证不通过 则不继续（查询及收起扩展条件）
      if (!flag) {
        return false;
      }
      this.$emit('getSelectedParamsByClickSearchBtn', this.selectedParams);
    },
    // 显示扩展查询基本列表 （供外部使用）合并基本数据和用户数据selectedParams: [{name: 'statusKey',value: '1'}];
    showExtendSearchCondition (selectedParams) {
      this.selectedParams = selectedParams;
      this.mergeSearchConditionAndParams.forEach(condition => {
        condition.fieldList.forEach((param, index) => {
          let findItem = selectedParams.find(o => { return o.name == param.name; });
          if (findItem) {
            param.value = findItem.value;
          } else {
            param.value = param.defaultValue;
          }
        });
      });
    }
  }
};
</script>
