<template>
  <div>
    <div class="list_top">
      <div class="list_name">第
        <span>{{keyIndex}}</span>
        次跟进
      </div>
      <div v-if="item.IntentionKeyValue"
           class="client_support_rank">
        <div class="sell_support_info_rank"
             :class="vPurposeClass">
          <span>
            {{item.IntentionKeyValue}}
          </span>
        </div>
        <div class="client_support_rank_text"
             :class="vPurposeClass">
          {{vPurposeName}}
        </div>
      </div>
      <div class="list_date">{{this.$utils.formatDate(item.FollowTime)}} {{getAgoTime()}}</div>
    </div>
    <div class="list_text">
      <span>{{item.ContentScript}}</span>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object,
    keyIndex: Number,
    listLength: Number
  },
  computed: {
    vPurposeClass () {
      let className = '';
      if (this.item.IntentionKeyValue == 'S') {
        className = 'purpose_black';
      } else if (this.item.IntentionKeyValue == 'A') {
        className = 'purpose_brown';
      } else if (this.item.IntentionKeyValue == 'B') {
        className = 'purpose_yellow';
      } else {
        className = 'purpose_gray';
      }
      return className;
    },
    vPurposeName () {
      let className = '';
      if (this.item.IntentionKeyValue == 'S') {
        className = '极高';
      } else if (this.item.IntentionKeyValue == 'A') {
        className = '较高';
      } else if (this.item.IntentionKeyValue == 'B') {
        className = '一般';
      } else if (this.item.IntentionKeyValue == 'C') {
        className = '较低';
      }
      return className;
    },
    vStartTimeFrom () {
      return this.item.StartTimeFrom.substring(0, 10);
    }
  },
  methods: {
    getAgoTime () {
      if (this.item.FollowTime) {
        let timer = this.$utils.StrToRelativeTime(this.item.FollowTime.substring(0, 10).replace(/-/g, '/'));
        let today = this.$utils.StrToRelativeTime(Date.new().toLocaleDateString());
        if (timer == today) {
          return this.$utils.StrToRelativeTime(this.item.FollowTime);
        } else {
          return this.$utils.StrToRelativeTime(this.item.FollowTime.substring(0, 10).replace(/-/g, '/'));
        }
      }
      return '';
    }
  }
};
</script>
