<template>
  <div class="form_item form_info_line"
       :class="{ form_info_required: required }">
    <div :class="formPlaceholder==' '?'form_info_field_init':'form_info_field'">
      <span>{{formTitle}}</span>
    </div>
    <div class="form_info_value form_info_select"
         v-if="!readonly">
      <input type="text"
             readonly
             :disabled="disabled"
             :value="value"
             :placeholder="vPlaceholder"
             :class="className"
             @click.stop="showEldilog"
             @input="updateVal($event.target.value)"
             @change="changeVal($event.target.value)" />

      <div v-if="!vHasData"
           class="mendian_input_data_left no_data pa"
           style="padding-left: 12px">
        {{'未设置'}}
      </div>
      <div v-else
           style="pointer-events:auto"
           class="mendian_input_data_left pa">
        <!-- <span :class="(isShowOpt ||vdataInfoStopListLength==0)?'opt':''" -->
        <span :class="(isShowOpt)?'opt':''"
              @click.stop="doStartSwitchClick(true)"
              v-if="vdataInfoStartListLength>0">使用中
          <span :class="vHasModulePower(ModulePowerKey)?'':'norole'">{{"("+vdataInfoStartListLength+")"}}</span>
        </span>
        <!-- <span style="margin-left: 8px;font-weight: bold"
              v-if="vdataInfoStopListLength>0&& vdataInfoStartListLength>0">|</span>

        <span :class="(!isShowOpt||vdataInfoStartListLength==0)?'opt':''"
              @click.stop="doStartSwitchClick(false)"
              v-if="vdataInfoStopListLength>0">已停用
          <span :class="vHasModulePower(ModulePowerKey)?'':'norole'">{{"("+vdataInfoStopListLength+")"}}</span>
        </span> -->

        <span style="margin-left: 8px;font-weight: bold">|</span>

        <!-- <span :class="(!isShowOpt||vdataInfoStartListLength==0)?'opt':''" -->
        <span :class="(!isShowOpt)?'opt':''"
              @click.stop="doStartSwitchClick(false)">已停用
          <span :class="vHasModulePower(ModulePowerKey)?'':'norole'">{{"("+vdataInfoStopListLength+")"}}</span>
        </span>
      </div>
    </div>
    <div v-else
         class="input_readonly form_info_value">{{value?value:""}}</div>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: 'formDialog',
  data () {
    return {
      isShowOpt: true
    };
  },
  props: {
    formTitle: {
      type: String,
      required: true
    }, // 标题，必填
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    disabled: Boolean, // 是否禁止修改，可选，如不提供，则可修改
    formPlaceholder: String, // 默认文本，可选，如不提供，则默认显示title
    isNumber: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    },
    customPlaceholder: {
      type: Boolean,
      default: false
    },
    isSwitchShowBox: {
      type: Boolean,
      default: true
    },
    dataInfoList: Array,
    ModulePowerKey: [String, Number, Boolean]
  },
  computed: {
    vdataInfoStartListLength () {
      const list = [];
      this.dataInfoList.forEach(o => {
        if (o.dataKey && o.isActive) {
          list.push(o);
        }
      });
      return list.length;
    },
    vdataInfoStopListLength () {
      const list = [];
      this.dataInfoList.forEach(o => {
        if (o.dataKey && !o.isActive) {
          list.push(o);
        }
      });
      return list.length;
    },
    vHasData () {
      return this.dataInfoList.length > 0;
    },
    vPlaceholder () {
      let str = '';
      if (!this.customPlaceholder) {
        if (this.required) {
          if (this.isNumber) {
            str = '必填';
          } else {
            str = '必选';
          }
        } else {
          if (this.isNumber) {
            str = '选填';
          } else {
            str = '可选';
          }
        }
      } else {
        str = this.formPlaceholder;
      }
      return str;
    }
  },
  created () {

  },

  watch: {
    isSwitchShowBox (n, c) {
      this.isShowOpt = n;
    }
  },
  methods: {
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.$store.getters.RolePowerList, ModulePowerKey);
    },
    doStartSwitchClick (boo) {
      // console.log(this.isShowOpt, 'this.isShowOpt', boo);
      // if (this.vdataInfoStartListLength > 0 && this.vdataInfoStopListLength > 0) {
      this.isShowOpt = boo;
      this.$emit('switchClick', this.isShowOpt);
      // }
    },
    updateVal (val) {
      this.$emit('input', val);
    },
    changeVal (val) {
      this.$emit('change', val);
    },
    showEldilog () {
      this.$emit('showEldilog');
    }
  }
};
</script>
