<template>
  <!-- 通用列表 -->
  <div class=" courseware-tablist">
    <div class="popup_title_padding view_title_flex">
      <div class="search_input pr"
           v-if="plan == 'B' || plan == 'A'">
        <div v-if="!isBatchStopAuthorization">
          <div class="search_item">
            <el-select-option :width="'280px'"
                              :selectTitle="'校区类型'"
                              :dataSourceList="SchoolType"
                              :selectionKey="params.SchoolTypeKey"
                              @changeEvent="chooseSchoolType"></el-select-option>
          </div>
          <div class="search_item"
               v-if="plan == 'B'">
            <el-select-option :width="'280px'"
                              :selectTitle="'大课包'"
                              :dataSourceList="BigCoursewarePackageList"
                              :selectionKey="params.BigCoursewarePackageKey"
                              @changeEvent="chooseBigCoursewarePackage"></el-select-option>
          </div>
          <input-search-contain-btn v-model="params.searchText"
                                    :width="'280px'"
                                    :placeholder="'请输入'"
                                    @onSearchEvent="search"
                                    @clearSearch="clear"></input-search-contain-btn>

          <div v-if="plan!='A'"
               class="summarizing_number_right_btn"
               :style="useStopAuthorization?'right: 240px;':'right: 0px;'"
               style="top:0px;">
            <function-btn :item="{ name: '授权', ModulePowerKey: '' }"
                          :className="'el-button btn_light_blue btn_hover_bg_blue '"
                          style="width: 76px;"
                          @clickItem="addAuthorizationCourseware('ZY')">
            </function-btn>
          </div>
          <div v-if="useStopAuthorization"
               class="summarizing_number_right_btn"
               style="top:0px;right: 140px;">
            <div>
              <function-btn :item="stopAuthorization"
                            @clickItem="showIsBatchStopAuthorization"
                            :className="'class_btn_delays search_btn btn_hover_bg_blue'">
              </function-btn>
            </div>
          </div>
          <div v-if="useStopAuthorization"
               class="summarizing_number_right_btn"
               style="top:0px;right: 0px;">
            <div>
              <function-btn :item="chgAuthorization"
                            @clickItem="showIsBatchStopAuthorization"
                            style="width:130px"
                            :className="'class_btn_delays search_btn btn_hover_bg_blue'">
              </function-btn>
            </div>
          </div>
        </div>

        <div v-if="isBatchStopAuthorization && plan=='B'"
             style="margin:14px 0px">
          <div class="mendian_backstage_search">
            <div class="pitch_count pr"
                 style="line-height: 36px;margin-left:10px">已选中 {{courseList.length}} 项
              <div class="remove_db_btn"
                   @click="toggleSelection"></div>
            </div>
            <div class="tsjj"
                 style="display: flex;">
              <function-btn :item="stopAuthorization"
                            @clickItem="batchStopAuthorization"
                            :className="'stop_authorization_btn class_btn_delay search_btn btn_hover_bg_blue'">
              </function-btn>
              <function-btn :item="chgAuthorization"
                            @clickItem="batchChgAuthorization"
                            :className="'batch_authorization_btn class_btn_delay search_btn btn_hover_bg_blue'">
              </function-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table_list_content introduce_list  ansyio">
      <table-list ref="tableListRef"
                  :tableData="tableData"
                  :position="''"
                  :tableColumns="tableColumns"
                  :expandRowData="expandRowData"
                  :rowKey="rowKey"
                  :totalNum="totalNum"
                  :defaultSort="defaultSort"
                  :queryParams="params"
                  :showPagePagination="showPagePagination"
                  :footerContent="isTotal"
                  :isShowCheckBox="useStopAuthorization"
                  @loadTableData="loadTableData"
                  @changeCheckBoxFun="clickCheckBox"></table-list>

    </div>
    <div v-if="plan=='C'"
         class="planCBtn"
         style="top:0px;right: -15px;">
      <!-- <div class="iconPlanC"></div> -->
      <function-btn :item="{ name: '+  授权课件', ModulePowerKey: '' }"
                    :className="'el-button btn_light_blue btn_hover_bg_blue iconPlanC'"
                    style="width: 76px;"
                    @clickItem="addAuthorizationCourseware('ZY')">
      </function-btn>
    </div>
  </div>
</template>
<script>
import {
  hqGetParentCourseWarePackageList,
  BatchDisable
} from '../../../../API/workbench';
import elSelectOption from '../../../report/common/el-select-option';
import inputSearchContainBtn from '../../../report/common/input-search-contain-btn';
import tableList from '../../../common/table-list/index';
import itemsVue from '../../../common-module/timetable-list/class-and-name-single-list/items.vue';
export default {
  data () {
    return {
      searchText: '',
      queryParams: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: 'asc', //	字符串	可选		数据源：GET	排序顺序
        searchText: '',
        SchoolTypeKey: 0
      },
      SchoolType: [
        { MainDemoName: '全部', OLAPKey: 0 },
        { MainDemoName: '直营店', OLAPKey: 1 },
        { MainDemoName: '加盟店', OLAPKey: 2 },
        { MainDemoName: '课件合作', OLAPKey: 3 }
      ],
      BigCoursewarePackageList: [],
      stopAuthorization: {
        name: '批量暂停授权',
        type: '',
        ModulePowerKey: 0
      },
      chgAuthorization: {
        name: '批量修改授权信息',
        type: '',
        ModulePowerKey: 0
      },
      isBatchStopAuthorization: false,
      isBatchStopAuthorizationDialog: false,
      courseList: []
    };
  },
  props: {
    tableData: Array, // 列表数据
    expandRowData: Array, // 列表详情数据
    tableColumns: Array, // table column,
    rowKey: [String, Number], // 显示详情对应绑定的ID
    totalNum: Number, // 分页总共条数
    params: Object,
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: '', order: 'ascending' };
      }
    },
    showPagePagination: {
      type: Boolean,
      default: true
    },
    tableColumnsType: {
      type: String,
      default: ''
    },
    plan: {
      type: String,
      default: 'A'
    },
    useStopAuthorization: {
      type: Boolean,
      default: false
    }

  },
  computed: {
    isTotal () {
      if (this.plan == 'C' || this.plan == 'D') {
        return '';
      } else {
        let str = '共' + this.totalNum + '条数据';
        return str;
      }
    }
  },
  components: {
    inputSearchContainBtn,
    tableList,
    elSelectOption
  },
  watch: {

  },
  created () {
    console.log(this.expandRowData, '展开行1');
  },
  mounted () {
    if (this.plan == 'B') {
      this.getBigCoursewarePackageList();
    }
  },
  methods: {
    chooseSchoolType (item) {
      console.log('校区选择item', item);
      this.params.SchoolTypeKey = item.OLAPKey;
      this.search();
    },
    chooseBigCoursewarePackage (item) {
      console.log('课包选择item', item);
      this.params.BigCoursewarePackageKey = item.OLAPKey;
      this.search();
    },
    // 添加
    addAuthorizationCourseware (type) {
      this.$emit('addAuthorizationCourseware', type);
    },
    // 搜索
    search () {
      console.log('搜索', this.params);
      this.initParams();
      this.$emit('loadTableData', this.params);
    },
    // 清除
    clear () {
      console.log('清除', this.params);
      this.params.searchText = '';
      this.params.SchoolTypeKey = '';
      if (this.plan == 'B') {
        this.params.BigCoursewarePackageKey = '0';
      }

      this.initParams();
      this.$emit('loadTableData', this.params);
      this.$refs.tableListRef.expandRowKeys = [];
    },
    initParams () {
      this.params.pageIndex = 0;
      this.params.type = 0;
    },
    // 汇总排序
    doAfterCilckSummaryData (item) {
      console.log('点击汇总数据排序', item);
      if (item.type == 'CurrentPortalNum') {
        this.$parent.isShowPortApplyRecordListPopup = true;
      } else {
        this.params.searchText = '';
        this.params.pageIndex = 0;
        this.$emit('loadTableData', this.params);
      }
    },
    // 排序
    loadTableData (queryParams) {
      console.log('排序', queryParams);
      this.$emit('loadTableData', queryParams);
    },

    getBigCoursewarePackageList () {
      hqGetParentCourseWarePackageList().then(result => {
        this.BigCoursewarePackageList = result.data;
        this.BigCoursewarePackageList.forEach(o => {
          o.OLAPKey = o.ID;
        });
        this.BigCoursewarePackageList.unshift({ MainDemoName: '全部', OLAPKey: '0' });
        console.log(result.data, '课包选择框');
      }).catch(error => {
        this.loading = false;
        console.log(error);
      });
    },
    // 批量暂停按钮
    batchChgAuthorization () {
      if (this.courseList.length == 0) {
        layer.alert('请选择校区')
        return
      } else {
        this.$emit('batchAuthorization', this.courseList);
      }
    },
    // 批量暂停按钮
    batchStopAuthorization () {
      if (this.courseList.length == 0) {
        layer.alert('请选择校区')
        return
      }
      layer.confirm('确定批量暂停授权', {
        btn: [{
          name: '取消',
          callBack: function () {
            layer.close();
          }
        }, {
          name: '确认',
          callBack: () => {
            console.log('this.courseList', this.courseList);
            let arr = [];
            this.courseList.forEach(item => {
              arr.push(item.OLAPKey);
            });
            BatchDisable(arr).then(result => {
              console.log('批量暂停成功', result.data);
              layer.alert('暂停成功');
              this.toggleSelection();
              this.$emit('loadTableData', this.params);
            }).catch(error => {
              console.log(error);
            });
            layer.close();
          }
        }]
      });
    },
    // 批量暂停授权
    showIsBatchStopAuthorization () {
      this.isBatchStopAuthorization = true;
    },
    // 批量暂停 选中一行
    clickCheckBox (lists, rowData) {
      console.log('clickCheckBox', lists, rowData);
      if (!this.isBatchStopAuthorization && lists.length > 0) {
        this.isBatchStopAuthorization = true;
      } else if (lists.length == 0) {
        console.log('为零');
        this.isBatchStopAuthorization = false;
      }
      console.log('isBatchStopAuthorization', this.isBatchStopAuthorization);
      let arr = lists.filter((item) => { return item.OLAPKey > 0; });
      this.courseList = lists;
    },
    toggleSelection (rows) {
      this.$refs.tableListRef.$refs.multipleTable.clearSelection();
      this.$refs.tableListRef.checkBoxList = [];
      this.courseList = [];
      this.isBatchStopAuthorization = false;
    }
  }
};
</script>
<style>
.courseware-tablist {
  background-color: #fafafa;
  width: 1208px !important;
  margin: 0 auto;
}
.courseware-tablist .search_input {
  margin: 10px 0;
}
.courseware-tablist .table_list_content {
  padding: 0 0 !important;
  background-color: #f6f6f6;
}
.courseware-tablist .table_pagination .el-pagination {
  /* padding: 0 0 !important; */
  /* margin: 0 !important; */
  margin-top: 0px;
}
.courseware-tablist .table_pagination_right {
  margin-top: 0px;
}
.courseware-tablist .table_pagination_right .el-pagination {
  padding-bottom: 10px;
  padding-top: 10px;
}
.courseware-tablist .el-pagination__jump {
  margin-right: 40px;
}
.courseware-tablist .table_number_select .select_text {
  margin-right: 40px;
  margin-top: 10px;
}
.courseware-tablist .table_left_text {
  top: 10px;
  left: 12px;
}
.courseware-tablist .table_select_box {
  float: left;
}

.courseware-tablist .el-table__body-wrapper {
  width: 104.2%;
  overflow: auto !important;
}
.courseware-tablist .planCBtn {
  margin: 5px auto;
  width: 117px;
}
.courseware-tablist .iconPlanC {
  width: 117px !important;
  margin: 10px 0;
}
.courseware-tablist .search_item {
  float: left;
  margin-right: 20px;
}
.courseware-tablist
  .search_item
  .table_select_box
  .el-select
  .el-input--suffix {
  background-color: #ffffff;
}
.courseware-tablist .popup_title_padding {
  padding: 10px 0px 15px !important;
}
.courseware-tablist .el-table td .font_dual {
  display: table-cell;
}
.list_drop_box
  .list_drop_banner
  .title_package_list
  .courseware-tablist
  .popup_title_padding {
  padding: 0 !important;
}
.courseware-tablist .class_btn_delays {
  height: 36px;
  width: 90px;
  align-items: center;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
}
.courseware-tablist .remove_db_btn {
  background-image: url(../../../../../public/image/dialog_del_icon.png);
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 8px;
  margin-top: 3px;
}
.stop_authorization_btn {
  width: 105px !important;
}
.batch_authorization_btn {
  width: 135px !important;
}
.table_list_content >>> .el-table__header-wrapper {
  width: 104%;
}
</style>
