<template>
  <div class="box">
    <iframe v-if="isShow"
            id="CTIframe"
            ref="iframe"
            width="100%"
            height="100%"
            :src="vUrl"
            class="iframe_box"></iframe>

  </div>
</template>
<script>
import { md5 } from '../../API/md5';
export default {
  data () {
    return {
      isSSP: true, // 启动防截屏程序,
      sspError: '', // 启动防截屏程序报错
      sspCheckHandler: null, // 防截屏确认定时器，默认30秒一次。
      rlen: 0,
      viewType: 0,
      coursewareId: 0,
      authCoursewareID: 0,
      scheduleCourseID: 0,
      authorizeType: 0,
      isShow: true,
      isTeachingMode: false,
      clientId: 0
      // authorizeType: 0,
      // authorizeType: 0
    };
  },
  props: {
    // dataInfo: Object,
    // watermark: String
  },
  computed: {
    vUrl () {
      // return '/CT';
      console.log('process.env.NODE_ENV', process.env.NODE_ENV);
      if (process.env.NODE_ENV === 'development') { // 开发环境
        console.log('dev');
        return this.$store.getters.PCWebURL + '/CT';
      } else {
        console.log('p');
        return '/CT';
      }
    }
  },
  created () {
    this.rlen = window.history.length;
    let isFullscreen = this.getFullScreenStatus();
    if (this.$zxClient.IsClient) {
      try {
        this.$zxClient.FullScreen();
      } catch (ex) {
        let isFullscreenS = document.body.scrollHeight == window.screen.height && document.body.scrollWidth == window.screen.width;
        console.log('isFullscreenS', isFullscreenS);
        // if (!isFullscreenS) {
        //   this.isExceptionForFullScreen();
        // }
      }
    } else {
      if (!isFullscreen) {
        this.fullScreen();
      }
    }
    if (this.$route.query.type) {
      this.viewType = this.$route.query.type;
    }
    this.clientId = this.$store.getters.clientId;
    if (this.$route.query.isTeachingMode) {
      this.isTeachingMode = this.$route.query.isTeachingMode;
    }
    if (this.$route.query.scheduleCourseID) {
      this.scheduleCourseID = this.$route.query.scheduleCourseID;
      console.log(this.$route.query.scheduleCourseID, '课ID');
    }
    if (this.$route.query.coursewareId) {
      this.coursewareId = this.$route.query.coursewareId;
      console.log(this.$route.query.coursewareId, '课件ID');
    }
    if (this.$route.query.authCoursewareID) {
      this.authCoursewareID = this.$route.query.authCoursewareID;
      console.log(this.$route.query.authCoursewareID, '授权课件ID');
    }
    if (this.$route.query.coursewareInfoType) {
      this.authorizeType = this.$route.query.coursewareInfoType == 'ZZ' ? 2 : 1;
    }
    this.$bus.on(this, 'scanToAuthCourseware', (res) => {
      console.log('scanToAuthCourseware', res.data);
      let data = JSON.stringify(res.data);
      console.log('scanToAuthCourseware2222', data);
      this.wvExecCommand('scanToAuthCourseware(' + data + ')');
    });
    this.$bus.on(this, 'wsquitAttendClassPreview', (data) => {
      // layer.alert('该课已被强制结束！');
      console.log('wsquitAttendClassPreview', data);
      this.wvExecCommand('closeCourseware("该课已被强制结束！")');
    });
    this.$bus.on(this, 'wsCoursewareMutualExclusion', (data) => {
      // layer.alert('该课已被强制结束！');
      console.log('wsCoursewareMutualExclusion', data);
      let DeviceTypeKeyValue = '';
      if (data.newData.DeviceTypeKey == 1) {
        DeviceTypeKeyValue = '浏览器端';
      } else if (data.newData.DeviceTypeKey == 2) {
        DeviceTypeKeyValue = '桌面端';
      } else if (data.newData.DeviceTypeKey == 3) {
        DeviceTypeKeyValue = 'APP端';
      } else {
        DeviceTypeKeyValue = 'TV端';
      }
      let text = '您已在' + DeviceTypeKeyValue + '进行上课！';
      console.log('texttext', text);
      this.wvExecCommand('closeCourseware("' + text + '")');
    });
    this.$bus.on(this, 'wsVerifyISEndVisit', (data) => {
      // layer.alert('该课已被强制结束！');
      console.log('wsVerifyISEndVisit', data);
      this.wvExecCommand('VerifyISEndVisitApp()');
    });
    //  this.$bus.on(this, 'wsquitAttendClassPreview', (data) => {
    //   // layer.alert('该课已被强制结束！');
    //   console.log('wsquitAttendClassPreview', data);
    //   this.wvExecCommand('closeCourseware("")');
    // });
    document.body.style.overflow = 'hidden';
  },
  mounted () {
    window.addEventListener('message', this.doMessage);
    window.ExitCT = this.exitCT;
  },
  beforeDestroy () {
    window.removeEventListener('message', this.doMessage);
    document.body.style.overflow = 'visible';
    this.closeSSP();
    this.isSSP = false;
    window.ExitCT = null;
  },
  methods: {
    doMessage (messageData) {
      console.log('recive message', messageData);
      switch (messageData.data.cmd) {
        case 'OnCTInitalise':
          console.log('initCTIframe111');
          setTimeout(o => {
            this.initCTIframe();
          }, 300);
          break;
        case 'CloseSSP':
          console.log('关闭防录屏', 'CloseSSP');
          this.closeSSP();
          break;
        case 'startSSP':
          console.log('开启防录屏', 'startSSP');
          this.startSSP();
          break;
        case 'exitCourseware':
          console.log('退出aaa', this.rlen, window.history.length, window.history);
          if (!this.isTeachingMode && this.$zxClient.IsClient) {
            this.$zxClient.UnFullScreen();
          } else {
            this.exitFullscreen();
          }
          this.isShow = false;
          setTimeout(() => {
            let len = this.rlen - window.history.length - 1;// -1是不进入iframe页面的下级页面直接退出的话，执行后退一步的操作
            console.log('back', len, window.history.length, this.rlen);
            // window.history.go(len);
            let url = this.$utils.getIndexPath();
            console.log('url', url);
            this.$router.push({
              path: url == '/teachingModeView' ? '/coursewareWorkBench' : url
            });
            console.log('go', len);
          }, 500);
          break;
        case 'setUCode':
          console.log('setUCode', messageData.data);
          if (this.$zxClient.IsClient) {
            this.$zxClient.SetUCode(messageData.data.UCode);

            this.$nextTick(o => {
              let DeviceCode = this.$zxClient.GetMCode();
              console.log('setUCodeDeviceCode', DeviceCode);
              this.wvExecCommand('setDeviceCode("' + DeviceCode + '")');
            });
          }
          break;
        case 'setFullscreen':
          console.log('setFullscreen');
          this.$zxClient.FullScreen();
          break;
        case 'coursewareVisit':
          this.$websocket.commonWebsocketApi(messageData.data.data);
          break;
        default:
          break;
      }
    },
    exitCT () {
      this.wvExecCommand('closeCourseware()');
    },
    initCTIframe () {
      console.log('this.isSSP', this.isSSP);
      if (this.isSSP) {
        let initData = {};
        initData.config = {
          CDNURL: this.$store.getters.CDNURL,
          PCWebURL: location.origin,
          APIURL: this.$store.getters.APIURL,
          APIBasicUrl: this.$store.getters.APIURL + process.env.VUE_APP_WKT_API_Url
        };
        initData.token = this.$store.getters.token;
        initData.signData = initData.token.SignData;
        initData.clientId = this.$store.getters.clientId;
        console.log('token', initData.token);
        initData.mdInfo = this.$store.getters.SaaSClientInfo;
        initData.DeviceDataInfo = this.$zxClient && this.$zxClient.GetMInfo ? JSON.parse(this.$zxClient.GetMInfo()) : '';
        if (window.brandCustomSettingStr && window.brandCustomSettingStr != '') {
          let obj = JSON.parse(window.brandCustomSettingStr);
          initData.isOpenBrand = true;
          initData.LoginBackground = obj.LoginBackground;
          initData.TeachingBackground = obj.TeachingBackground;
          initData.LogoUrl = obj.LogoUrl;
        } else {
          initData.isOpenBrand = false;
          initData.LoginBackground = '';
          initData.TeachingBackground = '';
          initData.LogoUrl = '';
          // if (this.$store.getters.SaaSClientEditionKey == 6) {
          //   obj.LogoUrl = require('../../../public/image/zhixue_logo.png');
          // } else {
          //   obj.LogoUrl = require('../../../public/image/header_logo.png');
          // }
        }
        initData.plateform = 'PC';
        initData.deviceInfo = {
          DeviceTypeKey: this.$zxClient.IsClient ? '2' : '1',
          DeviceTypeKeyValue: this.$zxClient.IsClient ? '桌面端' : '浏览器',
          DeviceCode: this.$zxClient && this.$zxClient.GetMCode ? this.$zxClient.GetMCode() : '',
          Longitude: this.$store.getters.geoInfo ? this.$store.getters.geoInfo.Longitude : '',
          Latitude: this.$store.getters.geoInfo ? this.$store.getters.geoInfo.Latitude : '',
          IsSettle: 1
        };
        initData.deviceInfo.Sign = md5(this.$utils.setStringmd5(initData.deviceInfo.DeviceTypeKey, initData.deviceInfo.DeviceCode, 'KJ', initData.deviceInfo.Longitude, initData.deviceInfo.Latitude));
        this.wvExecCommand('init(' + JSON.stringify(initData) + ')');
        let settings = {
          viewType: this.viewType,
          scheduleCourseID: this.scheduleCourseID,
          coursewareId: this.coursewareId,
          authCoursewareID: this.authCoursewareID,
          authorizeType: this.authorizeType,
          courseWarePackageKey: 0,
          courseWareOrder: 0,
          prepareLessonsKey: 0,
          clientId: this.clientId,
          routerType: 'teachingMode'
        };
        this.wvExecCommand('openCT(' + JSON.stringify(settings) + ')');
      } else if (this.isSSP === null) {
        setTimeout(() => {
          console.log('initCTIframe');
          this.initCTIframe();
        }, 100);
      }
    },
    // 翻页封装
    wvExecCommand (command) {
      console.log('wvExecCommand', command);
      this.$refs.iframe.contentWindow.postMessage({
        cmd: 'evalJS',
        content: command
      }, '*');
    },
    startSSP () {
      console.log('startSSP');
      let r = this.$zxClient.StartSSP();

      console.log('startSSP', r);
      this.isSSP = r == 'OK';
      if (!this.isSSP) {
        this.sspError = r == 'NO' ? '环境异常，无法启动课件' : r;
      } else {
        if (this.sspCheckHandler) {
          clearInterval(this.sspCheckHandler);
        }
        this.sspCheckHandler = setInterval(() => {
          console.log('checkSSP Interval');
          this.checkSSP();
        }, 30000);
      }
      let obj = {
        isSSP: this.isSSP,
        r: r
      };
      this.wvExecCommand('SSPStart(' + JSON.stringify(obj) + ')');
      console.log('StartSSP', this.isSSP, r);
      this.wvExecCommand('SSPStart("' + this.isSSP, r + '")');
      return this.isSSP;
    },
    checkSSP () {
      let r = this.$zxClient.CheckSSP();
      this.isSSP = r == 'OK';
      if (!this.isSSP) {
        this.sspError = r == 'NO' ? '环境异常，无法启动课件' : r;
        this.wvExecCommand('SSPCheck("' + this.isSSP, r + '")');
        this.$zxClient.UnFullScreen();
      }
    },
    closeSSP () {
      if (this.sspCheckHandler) {
        clearInterval(this.sspCheckHandler);
        this.sspCheckHandler = null;
      }
      if (this.$zxClient.IsClient) {
        console.log('StopSSP');
        this.$zxClient.StopSSP();
      }
    },
    // 判断当前页面是否全屏
    getFullScreenStatus () {
      return !!(
        document.fullScreen ||
        document.mozFullScreen ||
        document.webkitIsFullScreen ||
        document.webkitFullScreen ||
        document.msFullScreen
      );
    },
    isExceptionForFullScreen () {
      layer.alert('点击确定进入全屏模式', {
        mode: 'teachingMode',
        btn: [
          {
            name: '确认',
            callBack: () => {
              layer.close();
              if (this.$zxClient.IsClient) {
                this.$zxClient.FullScreen();
              } else {
                this.fullScreen();
              }
            }
          }
        ]
      });
    },
    fullScreen () {
      let isFullscreen = this.getFullScreenStatus();
      if (!isFullscreen) {
        if (document.documentElement.RequestFullScreen) {
          document.documentElement.RequestFullScreen();
        }
        // 兼容火狐
        console.log(document.documentElement.mozRequestFullScreen);
        if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        }
        // 兼容谷歌等可以webkitRequestFullScreen也可以webkitRequestFullscreen
        if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen();
        }
        // 兼容IE,只能写msRequestFullscreen
        if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        }
      } else {
        console.log('已在全屏状态');
      }
    },
    isFullscreenForNoScroll () {
      var explorer = window.navigator.userAgent.toLowerCase();
      if (explorer.indexOf('chrome') > 0) { // webkit
        if (document.body.scrollHeight === window.screen.height && document.body.scrollWidth === window.screen.width) {
          return true;
        } else {
          return false;
        }
      } else { // IE 9+  fireFox
        if (window.outerHeight === window.screen.height && window.outerWidth === window.screen.width) {
          return true;
        } else {
          return false;
        }
      }
    },
    exitFullscreen () {
      let isFullscreen = this.getFullScreenStatus();
      console.log('isFullscreen', isFullscreen);
      if (isFullscreen) {
        if (document.exitFullScreen) {
          document.exitFullscreen();
        }
        // 兼容火狐
        console.log(document.mozExitFullScreen);
        if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        }
        // 兼容谷歌等
        if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        // 兼容IE
        if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        console.log('不在全屏状态');
      }
    }
  }
};
</script>

<style scoped>
.box {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3001;
  width: 100%;
  height: 100%;
  background-color: white;
}
.ssp_tips {
  background-color: #150b35;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ssp_tips span {
  margin: auto;
  color: white;
  font-size: 32px;
  opacity: 0.6;
}
.ssp_tips_txt_ext {
  font-size: 16px !important;
  line-height: 40px;
}
.iframe_box {
  border: 0;
}
</style>