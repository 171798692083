<template>
  <div class="swiper-slide pr"
       @click="chgCoureDate">
    <div class="principal_swiper_list" style="font-size:12px"
         :class="{'opt':item.isActive}">
         <!-- ,'label':vDate == '今天' -->
      <div class="list_date">
        <span class="list_date_week">{{vWeek}}</span>
        {{vDate}} 
      </div>
      <div v-if="Number(item.ScheduleCourseNum)>0" style="margin-top:3px">
        <div class="font_black ellipse">
          <span class="font_gray_new">排课 </span>
          <span>{{Number(item.ScheduleCourseNum)||0}}节课/</span>
          <span>{{Number(item.ArrangedNumCount)||0}}人次</span>
        </div>
        <div class="font_black ellipse" :title="'考勤 '+(isDateType==1?'出勤'+Number(item.ArrivedNumCount)+'人/缺勤'+Number(item.FAttenceNumCount)+'人':'')">
          <span  class="font_gray_new">考勤 </span>
          <span v-if="isDateType==1">出勤{{Number(item.ArrivedNumCount)||0}}人/</span>
          <span v-if="isDateType==1">缺勤{{Number(item.FAttenceNumCount)||0}}人</span>
        </div>
        <div class="font_black ellipse" v-if="commentCheck" :title="'课评 '+(isDateType==1?'已发'+Number(item.CommentStudentCount)+'人/未发 '+Number(item.UnCommentStudentCount)+'人':'')">
          <span  class="font_gray_new">课评 </span>
          <span class="" v-if="isDateType==1">已发<span class="font_black">{{Number(item.CommentStudentCount)||0}}人</span>
          </span>
          <span class="" v-if="isDateType==1">/未发<span class="font_black">{{Number(item.UnCommentStudentCount)||0}}人</span></span>
        </div>
      </div>
      <div v-else class="no_job"  :style="{height:commentCheck?'52px':'36px'}">
          休息日
      </div>
      <!-- <div class="list_number"
           v-if="isDateType!=3">
        {{Number(item.ArrivedNumCount||0)}} <span class="font_gray">(人)签到</span>
      </div>
      <div class="list_number"
           v-else>
        {{Number(item.ArrangedNumCount||0)}} <span class="font_gray">(人)安排</span>
      </div>
      <div class="list_info clearfix">
        <div class="fl"
             v-if="isDateType==1">
          上满<span class="font_black">{{item.HaveClassNum}}</span>节课
        </div>
        <div class="fl"
             v-if="isDateType==2">
          已上<span class="font_black">{{item.HaveClassNum}}/{{item.ScheduleCourseNum}}</span>节课
        </div>
        <div class="fl"
             v-if="isDateType==3">
          共<span class="font_black">{{item.ScheduleCourseNum}}</span>节课
        </div>
        <div class="fr"
             v-if="isDateType!=3">
          出勤率<span class="font_black">{{Number(item.AttendanceRate)}}%</span>
        </div>
        <div class="fr"
             v-else>
          满员率<span class="font_black">{{parseInt(Number(item.FullRate))}}%</span>
        </div>
      </div> -->
      <!-- 昨日以前 -->
      <!-- <div class="list_tips tips_warning"
           v-if="isDateType==1&&Number(item.FAttenceNumCount)>0">
        <div>
          未考勤：<span class="font_red">{{item.FAttenceNumCount}}</span>人/<span class="font_red">{{item.NoAttenceCount}}</span>节课
        </div>
      </div>
      <div class="list_tips tips_normal tick_green"
           v-if="isDateType==1&&Number(item.FAttenceNumCount)==0">
 
      </div> -->
      <!-- 今天 -->
      <!-- <div class="list_tips tips_warning"
           v-if="isDateType==2&&Number(item.HaveClassNum)==Number(item.ScheduleCourseNum)&&Number(item.FAttenceNumCount)>0">
        <div>
          未考勤：<span class="font_red">{{item.FAttenceNumCount}}</span>人/<span class="font_red">{{item.NoAttenceCount}}</span>节课
        </div>
      </div>
      <div class="list_tips tips_warning"
           v-if="isDateType==2&&Number(item.HaveClassNum)!=Number(item.ScheduleCourseNum)&&Number(item.WarnLossCourseCount)>0">
        <div>
          <span class="font_red">{{item.WarnLossCourseCount}}</span>节课 学生不足
        </div>
      </div>
      <div class="list_tips tips_normal tick_green"
           v-if="isDateType==2&&Number(item.HaveClassNum)==Number(item.ScheduleCourseNum)&&Number(item.FAttenceNumCount)==0">
      </div>
      <div class="list_tips tips_normal tick_green"
           v-if="isDateType==2&&Number(item.HaveClassNum)!=Number(item.ScheduleCourseNum)&&Number(item.WarnLossCourseCount)==0">
      </div> -->
      <!-- 明天以后 -->
      <!-- <div class="list_tips tips_warning"
           v-if="isDateType==3&&Number(item.WarnLossCourseCount)>0">
        <div>
          <span class="font_red">{{item.WarnLossCourseCount}}</span>节课 学生不足
        </div>
      </div>
      <div class="list_tips tips_normal tick_green"
           v-if="isDateType==3&&Number(item.WarnLossCourseCount)==0">
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item:Object,
    commentCheck:Boolean
  },
  computed: {
    vDate () {
      if (this.item) {
        let date = this.item.ClassTime.replace(/-/g, '/');
        let lastDate = Date.new(Date.new().setTime(Date.new().getTime() - 24 * 60 * 60 * 1000)).toLocaleDateString();
        let preDate = Date.new(Date.new().setTime(Date.new().getTime() + 24 * 60 * 60 * 1000)).toLocaleDateString();
      
        if (date == this.$utils.formatDatet(Date.new(), 'yyyy/MM/dd')) {
          return '今天';
        } 
        // else if (date == this.$utils.formatDatet(Date.new(lastDate), 'yyyy/MM/dd')) {
        //   console.log(date, '昨天111', Date.new(lastDate));
        //   return '昨天';
        // } else if (date == this.$utils.formatDatet(Date.new(preDate), 'yyyy/MM/dd')) {
        //     console.log(date, '明天', Date.new(preDate));
        //   return '明天';
        // }
         else {
          return this.$utils.formatDatet(Date.new(date), 'MM') + '-' + this.$utils.formatDatet(Date.new(date), 'dd') + '';
        }
      }
    },
    vWeek () {
      if (this.item) {
        return this.$utils.formatDatet(Date.new(this.item.ClassTime.replace(/-/g, '/')), 'ddddd');
      }
    },
    // 判断日期是否小于今天,大于今天.以便控制显示
    isDateType () {
      if (this.item) {
        let date = this.item.ClassTime.replace(/-/g, '/');
        if (date == this.$utils.formatDatet(Date.new(), 'yyyy/MM/dd')) {
          return 2;
        } else if (Date.new() < Date.new(date)) {     // 明天或以后
          return 3;
        } else {            // 昨天或以前
          return 1;
        }
      } else {
        return 1;
      }
    }
  },
  methods: {
    chgCoureDate () {
      console.log(this.item, 'isDateType', this.isDateType);
      // let type = this.isDateType != 3;
      this.$emit('chgCoureDateAfter', this.item);
    }

  }

};
</script>
<style scoped>
.list_date{
  width: 80px !important;
  height: 23px !important;
  /* background: #FFB8AC !important; */
  background: #FFFFFF;
  border-radius: 10px !important;
  border: 1px solid #ececec;
  border-top: 0;
  /* padding-left: 1px !important; */
  font-size: 12px !important;
  text-align: center !important;
  line-height: 23px !important;
      margin-left: 17px !important;
          border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;

}
.list_date_week{
  /* width: 33px !important; */
  height: 21px !important;
    font-size: 12px !important;
  /* border-radius: 100px !important; */
  text-align: center !important; 
   line-height: 21px !important;
   /* background: white !important; */
   display: inline-block !important;
}
.no_job{
  font-size: 16px !important;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 36px;
  color: #999999;

}
.principal_swiper_list:hover{
  border: 1px solid #3498db !important;;
}
.principal_swiper_list.opt{
  background: linear-gradient(180deg, #FFCCC4 0%, rgba(255, 255, 255, 0.0962963) 100%)
}
.principal_swiper_list.opt::before{
  display: none !important;
}
.principal_swiper_list.opt .list_date{
  background: #FFB8AC;
  color: #000;
  border:none

}
.principal_swiper_list.opt::after{
  content: '';
  position: absolute;
  width: 100%;
  height:3px;
  background: #E64024;
  bottom: -16px;
  left: 0;
  right: 0;

}
.principal_swiper_list .principal_list_conent{
  
}
.font_gray_new{
  color: #999;
}
.ellipse{
  margin-top: 7px;
}
</style>
