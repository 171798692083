<template>
  <div class="WKT_information_fromBox">
    <div class="from_input_list">
      <div class="from_input_title">
        原名称
      </div>
      <div class="from_input_title">
        {{item.TeacherName}}
      </div>
    </div>
    <div class="from_input_list">
      <div class="from_input_title">
        新名称
      </div>
      <div class="from_input_box">
        <input type="text"
               v-model="value"
               placeholder="请输入学员姓名">
      </div>
    </div>
    <!-- 按钮组 -->
    <div>
      <common-button-group @buttonClickEvent="editItem"
                           @closeDialog="doAfterClickCancelBtn"></common-button-group>
    </div>
  </div>
</template>
<script>
import { EditTeacher } from '../../../../API/workbench';
export default {
  data () {
    return {
      value: ''
    };
  },
  props: {
    item: Object
  },
  methods: {
    editItem () {
      let obj = this.$utils.parseJson(this.item);
      obj.TeacherName = this.value;
      EditTeacher(obj).then(result => {
        layer.alert('修改成功');
        this.$emit('editAfter');
        this.doAfterClickCancelBtn();
      }).catch(error => {
        console.log('EditTeacherError', error);
      });
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    }
  }
};
</script>

