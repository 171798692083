<template>
  <div class="single_result"
       @click.stop="authorizationItem">
    <div class="single_result_info_detail">
      <div class="single_class_name">
        <span>{{Item.MainDemoName}}</span>
        <span style="float: right;color: #3498DB;margin-left: 34px;font-size: 14px;">
          {{Item.isAuthorizationValue}}
        </span>
        <span v-if="Item.TypeKeyValue"
              class="single_rightcontent"
              style="float: right;">{{Item.TypeKeyValue}}</span>
      </div>
    </div>
  </div>
</template>
<script>;
export default {
  name: 'multiSelectItem',
  data () {
    return {
    };
  },
  props: { Item: Object }, // 单选数据项 需绑定到界面
  created () {

  },
  mounted () {

  },
  computed: {
    vDataItem () {
      return this.Item;
    }
  },
  watch: {

  },
  methods: {
    authorizationItem () {
      this.$emit('authorizationItem', this.Item);
    }
  }
};
</script>

<style >
.single_class_info {
  position: relative;
}
.single_rightcontent {
  color: #999;
  font-size: 14px;
}
</style>
<style scoped>
.single_result_info_detail {
  padding: 15px 0px 15px 15px !important;
}
.single_class_name {
  max-width: 375px;
  margin-left: 5px;
}
.single_class_name > span:first-child {
  max-width: 50%;
}
</style>

