<template>
     <div class="all_workbench_left_ul">
        <div class="all_workbench_left_list button" @click="swtichTableType(1)" :class="{opt:tableType == 1}">
          <div class="list_title">签单待审核</div>
          <div class="list_number">{{Number(dataInfo.PendingAuditStat)}}</div>
        </div>
        <div class="all_workbench_left_list button" @click="swtichTableType(2)" :class="{opt:tableType == 2}">
          <div class="list_title">签单待付款</div>
          <div class="list_number">{{Number(dataInfo.PendingPaymentStat)}}</div>
        </div>
        <div class="all_workbench_left_list button" @click="swtichTableType(3)" :class="{opt:tableType == 3}">
          <div class="list_title">签单已全款</div>
          <div class="list_number ">{{Number(dataInfo.HaveFullStat)}}</div>
        </div>
        <div class="all_workbench_left_list button" @click="swtichTableType(4)" :class="{opt:tableType == 4}">
          <div class="list_title">合同付款记录</div>
          <div class="list_number ">{{Number(dataInfo.PaymentRecordStat)}}</div>
        </div>
        <div class="all_workbench_left_list button" @click="swtichTableType(5)" :class="{opt:tableType == 5}">
          <div class="list_title">合同付款待审核</div>
          <div class="list_number ">{{Number(dataInfo.PaymentPendingAuditStat)}}</div>
        </div>
        <div class="all_workbench_left_list button" @click="swtichTableType(6)" :class="{opt:tableType == 6}">
          <div class="list_title">合同付款拒绝</div>
          <div class="list_number font_red">{{Number(dataInfo.PaymentRefusedAuditStat)}}</div>
        </div>
       
        </div>
</template>
<script>
export default {
    props:{
      tableType:[Number,String],
      dataInfo:Object
    },
    methods:{
      swtichTableType(type){
        this.$emit('swtichTableType',type);
      }
    }
}
</script>
<style scoped>
.all_workbench_left_ul {
  width: 52.8%;
  height: 50px;
  border: 1px solid #ececec;
  border-radius: 4px;
  display: flex;
}

.all_workbench_left_list {
  flex: 1;
  text-align: center;
  position: relative;
}

.all_workbench_left_list .list_title {
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  margin-top: 5px;
}

.all_workbench_left_list .list_number {
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  font-weight: bold;
}

.all_workbench_left_list.opt {
  border-bottom: 3px solid #3498db;
}

.all_workbench_left_list.opt .list_title,
.all_workbench_left_list.opt .list_number {
    color: #3498db !important;
}

.all_workbench_left_list::after {
  content: "";
  position: absolute;
  right: -1px;
  top: 10px;
  width: 1px;
  height: 30px;
  background: #e7e9e8;
}
</style>