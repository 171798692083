<template>
  <!-- 审批-提现-->
  <div class=" table_list_content summarizing_list">
    <div class="single_result_detail">
      <div class="accraditation_summary">
        <div class="course_class_summarizing_right">
          <div class="flex">
            <div class="right_top_box">
              <title-summary-data v-for="(item,key,index) in headSummaryData"
                                  :sumData="item"
                                  :key="index"
                                  @onCilckEvent="doTitleBoxClick"></title-summary-data>
            </div>
          </div>
        </div>
      </div>
      <table-list ref="tableListRef"
                  :position="''"
                  :tableData="accraditationList"
                  :totalNum="totalNum"
                  :tableColumns="tableColumns"
                  :isSearchText="false"
                  :rowKey="'Number'"
                  :footerContent="'共'+ totalNum + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  @loadTableData="getMessageSearchPageForExtractBalance"></table-list>
    </div>
  </div>
</template>
<script>
import { MessageSearchPageForExtractBalance, AuditWithdrawalApplyForAdopt, AuditWithdrawalApplyForRefuse } from '../../../../../../API/workbench.js';
import tableList from '../../../../../common/table-list/index';
import returnIntroduceFee from '../../../components/form-components/return-introduce-fee';
import customerThread from '../../../components/form-components/customer-thread';
import titleSummaryData from './components/title-summary-data';
export default {
  components: {
    tableList,
    customerThread,
    returnIntroduceFee,
    titleSummaryData
  },
  props: {
    threadInfo: Object // 线索信息
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: 'desc', // 排序字段
        StatusKey: 1 //	整型	必须		数据源：GET	审批状态1-待审;2-已审核
      },
      accraditationList: [], // 门店列表
      dataInfo: {}, // 审批信息
      isShowAddThreadFormPopup: false, // 线索表单
      isShowReturnExpensesPopup: false, // 返介绍表单

      headSummaryData: [
        { title: '待审批', className: 'font_blue', value: 0, StatusKey: 1, decimalPlace: 0, isSelected: true },
        { title: '已审批', className: 'font_gold', value: 0, StatusKey: 2, decimalPlace: 0, isSelected: false }
      ],
      tableColumns: [],
      // 待审批
      awaitAccraditationtableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 70,
          sortable: false,
          align: 'left',
          type: 'text-item',
          className: 'font_gray',
          decimalPlace: 0
        },
        {
          label: '申请日期',
          prop: 'GenerateTime',
          width: 140,
          align: 'left',
          type: 'date-item',
          sortable: 'custom',
          extend: {
            dateType: 'YYMMRR HH:DD' // 日期类型
          }
        },
        {
          label: '申请人',
          prop: 'ApplicatorKeyValue',
          width: 150,
          type: 'text-item',
          sortable: false
        },
        {
          label: '签约机构',
          prop: 'MainDemoName',
          width: 180,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '联系人',
          prop: 'ContactName',
          width: 150,
          align: 'left',
          type: 'text-item',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '提现原因',
          prop: 'Reson',
          width: 150,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '提现金额',
          prop: 'WithdrawalAmount',
          width: 170,
          align: 'left',
          sortable: false,
          type: 'text-item'
        },
        {
          label: '操作',
          prop: 'option',
          width: 150,
          type: 'table-btn',
          isEmpertyRow: true,
          extend: {
            tableBtnGroup: [
              {
                name: '同意',
                extend: {
                  IsInquiryHint: 'consentInquiryBtn', // 开启询问提示
                  setClassName: (rowData) => {
                    if (rowData.consentInquiryBtn) { // 开启询问提示时，显示询问弹窗时，界面按钮置灰，不可操作
                      console.log(rowData, name, '开启询问提示');
                      return rowData.consentInquiryBtn;
                    }
                  },
                  click: (rowData, callBack) => {
                    if (callBack) {
                      callBack({ IsInquiryHint: 'consentInquiryBtn', InquiryClassName: 'table_btn_gray' });
                    }
                    console.log(rowData, callBack, 'rowData, callBack');
                    this.doConsentOperate(rowData, callBack);
                  },
                  ModulePowerKey: '100'
                }
              },
              {
                name: '拒绝',
                extend: {
                  IsInquiryHint: 'refuseInquirybtn', // 开启询问提示
                  setClassName: (rowData) => {
                    if (rowData.refuseInquirybtn) { // 开启询问提示时，显示询问弹窗时，界面按钮置灰，不可操作
                      return rowData.refuseInquirybtn;
                    }
                  },
                  click: (rowData, callBack) => {
                    if (callBack) {
                      callBack({ IsInquiryHint: 'refuseInquirybtn', InquiryClassName: 'table_btn_gray' });
                    }
                    this.doRejectOperate(rowData, callBack);
                  },
                  ModulePowerKey: '100'
                }
              }
            ]
          }
        }
      ],
      // 已审批
      passAccraditationtableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 70,
          sortable: false,
          align: 'left',
          type: 'text-item',
          className: 'font_gray',
          decimalPlace: 0
        },
        {
          label: '申请日期',
          prop: 'GenerateTime',
          width: 120,
          align: 'left',
          type: 'date-item',
          sortable: 'custom',
          extend: {
            dateType: 'YYMMRR HH:DD' // 日期类型
          }
        },
        {
          label: '申请人',
          prop: 'ApplicatorKeyValue',
          width: 130,
          type: 'text-item',
          sortable: false
        },
        {
          label: '签约机构',
          prop: 'MainDemoName',
          width: 130,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '联系人',
          prop: 'ContactName',
          width: 110,
          align: 'left',
          type: 'text-item',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '提现原因',
          prop: 'Reson',
          width: 130,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '提现金额',
          prop: 'WithdrawalAmount',
          width: 110,
          align: 'left',
          sortable: false,
          type: 'text-item'
        },
        {
          label: '状态',
          prop: 'AuditStatusKeyValue',
          width: 120,
          align: 'left',
          type: 'text-item',
          sortable: false,
          extend: {
            setClassName: (rowData) => {
              return rowData.AuditStatusKey == 1 ? 'font_green' : 'font_red';
            }
          }
        },
        {
          label: '审批人',
          prop: 'AuditKeyValue',
          width: 130,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '审批时间',
          prop: 'AuditTime',
          width: 150,
          align: 'left',
          sortable: false,
          type: 'date-item',
          extend: {
            dateType: 'YYMMRR HH:DD' // 日期类型
          }
        }

      ],
      totalNum: 0,
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  created () {
  },
  mounted () {
    this.getMessageSearchPageForExtractBalance();
    this.tableColumns = this.awaitAccraditationtableColumns;
    console.log(this.tableColumns, 'this.tableColumns');
  },
  computed: {

  },
  methods: {
    doTitleBoxClick (item) {
      console.log(item, 'doAfterTitleBoxClick');
      this.accraditationList = [];
      this.headSummaryData.forEach(o => { // 高亮显示
        if (item.title == o.title) {
          o.isSelected = true;
        } else {
          o.isSelected = false;
        }
      });
      this.queryParams.StatusKey = item.StatusKey;
      // 1-待审;2-已审核/列表
      if (this.queryParams.StatusKey == 1) {
        this.tableColumns = this.awaitAccraditationtableColumns;
      } else {
        this.tableColumns = this.passAccraditationtableColumns;
      }
      this.queryParams.pageIndex = 0;
      this.getMessageSearchPageForExtractBalance();
    },
    // 审批-导出列表
    getMessageSearchPageForExtractBalance (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      MessageSearchPageForExtractBalance(this.queryParams).then(result => {
        console.log('审批-导出列表', result.data);
        this.isShowAddThreadFormPopup = false;
        this.totalNum = result.data.Total;
        // 1-待审;2-已审核
        this.headSummaryData[0].value = result.data.WaitApprovalCount;
        this.headSummaryData[1].value = result.data.AlreadyApprovalCount;
        // 分页数
        if (result.data && result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach(o => {
            o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
            o.SaaSClientKey = o.SaaSClientKey == 0 ? '' : o.SaaSClientKey;
            o.StatusKeyValue = o.StatusKey == 2 ? '同意' : o.StatusKeyValue;
            let UnitKeyText = o.UnitKey == 1 ? 'G' : 'T';
            o.NumberCountText = Number(o.NumberCount) + UnitKeyText;
            o.WithdrawalAmount = Number(o.WithdrawalAmount);
          });
          this.accraditationList = result.data.PageDataList;
        } else {
          this.accraditationList = [];
        }
      }, error => {
        layer.alert(error.msg);
      });
    },

    // 操作按钮-同意
    doConsentOperate (item, callBack) {
      console.log(item, '操作按钮');
      layer.confirm('请问是否同意审批?', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              layer.close();
              if (callBack) {
                callBack({ IsInquiryHint: 'consentInquiryBtn', InquiryClassName: '' });
              }
            }
          },
          {
            name: '确认',
            callBack: () => {
              AuditWithdrawalApplyForAdopt(item.OLAPKey).then(result => {
                this.$emit('afterSuccess');
                this.getMessageSearchPageForExtractBalance();
                if (callBack) {
                  callBack({ IsInquiryHint: 'consentInquiryBtn', InquiryClassName: '' });
                }
              }).catch(error => {
                layer.alert(error.msg);
              });
              layer.close();
            }
          }
        ]
      });
    },
    // 操作按钮-拒绝
    doRejectOperate (item, callBack) {
      console.log(item, '操作按钮');
      layer.confirm('请问是否拒绝审批?', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              layer.close();
              if (callBack) {
                callBack({ IsInquiryHint: 'refuseInquirybtn', InquiryClassName: '' });
              }
            }
          },
          {
            name: '确认',
            callBack: () => {
              AuditWithdrawalApplyForRefuse(item.OLAPKey).then(result => {
                this.$emit('afterSuccess');
                this.getMessageSearchPageForExtractBalance();
                if (callBack) {
                  callBack({ IsInquiryHint: 'refuseInquirybtn', InquiryClassName: '' });
                }
              }).catch(error => {
                layer.alert(error.msg);
              });
              layer.close();
            }
          }
        ]
      });
    }

  }
};
</script>
