<template>
  <div class="customer_management_from form_info_list popup_height_from">
    <div class="new_course_time_from up_sell_record_from_date pr">
      <input-date v-model="dataInfo.PayMent.PaymentTime"
                  :elementId="'classDate'"
                  :formTitle="'付款日期'"
                  :formPlaceholder="'请选择'"
                  :readonly="vIsEdit"
                  @changeDate="changeDate">
        <!-- :minDate="$utils.formatDateToLine(Date.new())" -->
      </input-date>
    </div>
    <input-text :formTitle="'申请人'"
                :readonly="true"
                :formPlaceholder="'请输入申请人'"
                class=""
                v-model="dataInfo.ApplicantKeyValue"></input-text>
    <input-text :formTitle="'机构名称'"
                :readonly="true"
                :formPlaceholder="'请输入机构名称'"
                class=""
                v-model="dataInfo.SaaSClientNameKeyValue"></input-text>
    <input-text :formTitle="'开户状态'"
                :readonly="true"
                :formPlaceholder="''"
                class=""
                :value="vStatusValve"></input-text>

    <div class="from_input_list">
      <div class="from_input_title"
           :class="{'form_info_required':seletedItem.TypeKey==6}">
        开通功能
      </div>
      <div class="from_list_select flex"
           style="align-items: center;"
           v-if="seletedItem.TypeKey==6 ">
        <!-- Number(seletedItem.SaaSClientCount)>1 -->
        <el-checkbox v-model="dataInfo.PayMent.IsOpenAcademicPortKey"
                     true-label="1"
                     false-label="0"
                     :disabled="vIsEdit || (dataInfo.PayMent.TypeKey!=3&&!isAdd)|| Number(seletedItem.IsOpenAcademicPortKey)>0"
                     @change="openAcademicPort">教务端口</el-checkbox>
        <el-checkbox v-model="dataInfo.PayMent.IsOpenCoursewarePortKey"
                     true-label="1"
                     false-label="0"
                     :disabled="vIsEdit || (dataInfo.PayMent.TypeKey!=3&&!isAdd)|| Number(seletedItem.IsOpenCoursewarePortKey)>0"
                     @change="openCoursewarePort">课件端口</el-checkbox>
      </div>

      <div class="from_list_select flex"
           style="align-items: center;"
           v-else>
        <el-checkbox style="margin-left: -7px;"
                     v-model="dataInfo.PayMent.IsOpenAcademicKey"
                     true-label="1"
                     false-label="0"
                     :disabled="vIsEdit||vIsOpenAcademicKey||isAdd"
                     @change="seletedbusiness">教务</el-checkbox>
        <el-checkbox v-model="dataInfo.PayMent.IsOpenWKTKey"
                     style="margin-left: 7px;"
                     true-label="1"
                     false-label="0"
                     :disabled="vIsEdit||vIsOpenWKTKey||disableOpenWKTKey"
                     @change="seletedcleanCustor">微网校</el-checkbox>
        <el-checkbox v-model="dataInfo.PayMent.IsOpenOfficialAccountsKey"
                     style="margin-left: 7px;"
                     true-label="1"
                     false-label="0"
                     :disabled="vIsEdit||vIsOpenOfficialAccountsKey||disableOpenOfficialAccountsKey"
                     @change="seletedcleanClassCount">公众号</el-checkbox>
        <el-checkbox v-model="dataInfo.PayMent.IsOpenSelfCoursewareKey"
                     style="margin-left: 7px;"
                     true-label="1"
                     false-label="0"
                     :disabled="vIsEdit||vIsOpenSelfCoursewareKey||disableOpenCoursewareKey"
                     @change="seletedcleanCourseware">自研课件</el-checkbox>

      </div>
    </div>
    <div v-if="seletedItem.TypeKey==6">
      <input-number v-if="Number(dataInfo.PayMent.IsOpenAcademicPortKey)>0"
                    :min="0"
                    :formTitle="'教务端口'"
                    :decimalPlaces="0"
                    :required="false"
                    :readonly="vCanAcademicPortInput"
                    v-model="dataInfo.AcademicPortCount"></input-number>

      <input-number v-if="Number(dataInfo.PayMent.IsOpenCoursewarePortKey)>0"
                    :min="0"
                    :formTitle="'课件端口'"
                    :decimalPlaces="0"
                    :required="false"
                    :readonly="vCanCoursewarePortInput"
                    v-model="dataInfo.CoursewarePortCount"></input-number>
    </div>
    <div class="table_select_box"
         style=";margin-right:0;">
      <div class="table_select_title">付款类型</div>
      <el-select :value="vTypeKeyValue"
                 placeholder="请选择"
                 ref="resetText"
                 :disabled="Number(this.seletedItem.StatusKey)==0||Number(this.seletedItem.PayAuditKey)>0||paymentType>0"
                 @change="selectPaymenttype">
        <el-option v-for="item in vPaymenttypeList"
                   :key="item.value"
                   :label="item.label"
                   :value="item">
        </el-option>
      </el-select>
    </div>
    <!-- 首付 -->
    <div class="new_course_time_from up_sell_record_from_date pr"
         v-if="vTypeKey==1">
      <input-date v-model="dataInfo.PayMent.ExpireDateTime"
                  :elementId="'classDate2'"
                  :formTitle="'门店有效期'"
                  :readonly="vIsEdit"
                  :minDate="$utils.formatDateToLine(Date.new())"
                  :formPlaceholder="'请选择'"
                  @changeDate="changeDate">
      </input-date>
    </div>
    <!-- 续费 -->
    <input-number v-if="vTypeKey==2"
                  :min="0"
                  :formTitle="'年数'"
                  :required="true"
                  :readonly="vIsEdit"
                  v-model="dataInfo.PayMent.RenewCount"
                  :placeholder="'必填'"
                  :decimalPlaces="0"></input-number>
    <!-- 首付跟续费 -->
    <div v-if="vTypeKey==1||vTypeKey==2"
         class="from_radio_pr pr"
         :class="{border_bottom:allPay!=2}">
      <input-number :min="0"
                    :formTitle="'合同金额'"
                    :required="true"
                    :readonly="vIsEdit"
                    class="no_border_bottom"
                    v-model="dataInfo.PayMent.ContractAmount"
                    :placeholder="'必填'"
                    :decimalPlaces="2"></input-number>
      <div class="from_list_select flex pa"
           style="align-items: center;">
        <el-radio v-model="allPay"
                  :disabled="vIsEdit"
                  :label="1">全款</el-radio>
        <el-radio v-model="allPay"
                  :disabled="vIsEdit"
                  :label="2">定金</el-radio>
      </div>

    </div>
    <div v-if="allPay==2">
      <input-number :min="0"
                    :formTitle="'实付'"
                    :required="true"
                    :readonly="vIsEdit"
                    v-model="dataInfo.PayMent.ActuallyAmount"
                    :placeholder="'必填'"
                    :decimalPlaces="2"></input-number>
      <input-number :min="0"
                    :formTitle="'尾款'"
                    :readonly="true"
                    :required="true"
                    :value="vBalanceAmount"
                    :placeholder="'必填'"
                    :decimalPlaces="2"></input-number>
    </div>
    <!-- 加功能 -->
    <input-number v-if="vTypeKey==3"
                  :min="0"
                  :formTitle="'合同金额'"
                  :required="true"
                  :readonly="vIsEdit"
                  v-model="dataInfo.PayMent.ContractAmount"
                  :decimalPlaces="2"
                  :placeholder="'必填'"></input-number>
    <!-- 还尾款尾款 -->
    <input-number v-if="vTypeKey==4"
                  :min="0"
                  :formTitle="'实收'"
                  :readonly="true"
                  v-model="dataInfo.PayMent.ActuallyAmount"
                  :decimalPlaces="2"
                  :placeholder="'必填'"></input-number>

    <div class="from_input_list form_info_required">
      <div class="from_input_title ">
        合同附件
      </div>
      <div class="from_input_box pr">
        <input type="file"
               name="file"
               class="info_file-upload apiclient pa"
               :disabled="vIsEdit"
               @change="uploadingFile($event,'file')"
               data-type="userIconImg">
        <input type="text"
               v-model="dataInfo.PayMent.ContractName"
               readonly
               placeholder="请上传">
      </div>
    </div>
    <input-text :formTitle="'修改条款'"
                :formPlaceholder="'请输入'"
                class="form_info_field_red"
                :readonly="vIsEdit"
                v-model="dataInfo.PayMent.AmendScript"></input-text>
    <div class="from_input_list pr">
      <div class="from_input_title form_info_required">
        付款截图
      </div>
      <div class="from_input_box pr">
        <input type="file"
               name="file"
               :disabled="vIsEdit"
               class="info_file-upload apiclient pa"
               @change="uploadingFile($event,'img')"
               data-type="userIconImg">
        <input type="text"
               v-model="dataInfo.PayMent.PayCutSupport"
               readonly
               placeholder="请上传">
        <!-- <span class="redPaper1">{{dataInfo.PayMent.AccessorySupport||'请上传'}}</span> -->

      </div>
    </div>
    <div class="form_info_edit">
      <input-dialog :formTitle="'数据库服务器'"
                    :required="false"
                    :readonly="vIsEdit||isDataBaseBoolean"
                    :placeholder="'必填'"
                    v-model="DataBaseType.name"
                    @showEldilog="changeDatabaseType"></input-dialog>
    </div>
    <div v-if="!vIsEdit">
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             :fixedBtn="true"
                             @confirmClick="submitAdd"
                             @cancelClick="doAfterClickCancelBtn"></save-cancel-btn-group>
    </div>
    <div v-else>
      <save-cancel-btn-group :cancelBtnText="'撤销'"
                             :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             :fixedBtn="true"
                             :btnText="'提交审核'"
                             @confirmClick="submitAudit"
                             @cancelClick="revocation"></save-cancel-btn-group>
    </div>
    <custom-dialog :title="'选择数据库服务器'"
                   :visible.sync="isShowDatabaseServer"
                   :before-close="closeDatabaseServerPopuP">
      <database-type @close="closeDatabaseServerPopuP"
                     @UpdataDatabaseServer="UpdataDatabaseServer"
                     :mendianInfo='DataBaseType'></database-type>
    </custom-dialog>
    <custom-dialog :title="'付款申请'"
                   class=" "
                   :visible.sync="showAuthCode"
                   :before-close="closeShowAuthCode">
      <auth-code :dataInfo="dataInfo"
                 @close="closeShowAuthCode"
                 @afterSuccess="afterSuccess"></auth-code>
    </custom-dialog>
  </div>
</template>
<script>
import authCode from './auth-code';
import databaseType from './database-type';
import { AddApply, GetApplyDetails, CancelApply, UploadFiles, GetDatabaseServeSaaSClientList } from '../../../../../API/workbench';
export default {
  data () {
    return {
      dataInfo: {
        'ApplyTime': this.$utils.formatDateToLine(Date.new()), // '申请时间',
        'ApplicantKey': '', // '申请人',
        'ApplicantKeyValue': '', // '申请人',
        'SaaSClientNameKey': '', // '机构Key',
        'SaaSClientNameKeyValue': '', // '机构',
        'SaaSClientStatusKey': '', // '机构状态0-未开通;1-临时开通;2-正式开通;3-关停',
        'SaaSClientStatusKeyValue': '', // '机构状态',
        'MobileSupport': '', // '手机号',
        'ContactsName': '', // '联系人',
        'ThreadKey': '', // '线索key',
        'TypeKey': '2', // '1-开户;2-付款;3-返介绍费;4-清数据',
        'TypeKeyValue': '付款', // '1-开户;2-付款;3-返介绍费;4-清数据',
        'CoursewarePortCount': 0, // 课件端口
        'AcademicPortCount': 0, // 教务端口
        'ReasonScript': '', // '申请原因',
        'DataBaseKey': '', // 1默认服务器 3一般服务器
        'PayMent': // null,
        {// 付款申请
          'PaymentTime': this.$utils.formatDateToLine(Date.new()), // '付款时间',
          'IsOpenAcademicKey': '0', // '是否开通教务',
          'IsOpenWKTKey': '0', // '是否开通WKT',
          'IsOpenOfficialAccountsKey': '0',
          'IsOpenSelfCoursewareKey': '0', // 是否开通自研课件
          'TypeKey': 1, // '1-首付;2-续费;3-加功能;4-还尾款',
          'TypeKeyValue': '首付', // '1-首付;2-续费;3-加功能;4-还尾款',
          'ContractAmount': 0, // '合同金额',
          'ActuallyAmount': 0, // '实付金额',
          'BalanceAmount': 0, // '尾款',
          'AccessorySupport': '', // '合同附件',
          'AmendScript': '', // '修改条款',
          'PayCutSupport': '', // '付款截图',
          'ExpireDateTime': '', // '门店有效期',
          'RenewCount': 0, // '续费年数'
          'ContractName': '',
          'IsOpenCoursewarePortKey': '0', // 是否课件端口
          'IsOpenAcademicPortKey': '0'// 是否教务端口
        },
        'ClearSaaSClien': null,
        // {// 清数据申请
        //   'ClearScopeSupport': '', // '清理范围',
        //   'ClearScopeIDSName': '',
        //   'ClearSaaSClientKey': '', // '清除数据门店',
        //   'ClearSaaSClientKeyValue': '' // '清除数据门店'
        // },
        'CashBack': null,
        'DredgeTime': ''
        // {// 返介绍费
        //   'CashSaaSClientKey': '', // '返现人门店',
        //   'CashSaaSClientKeyValue': '', // '返现人门店',
        //   'CashBackAmount': '', // '返现金额',
        //   'MobileSupport': '', // '返现人手机号',
        //   'CashBackKey': '', // '返现人',
        //   'CashBackKeyValue': '' // '返现人'
        // }
      },
      paymenttypeList: [
        {
          label: '首付',
          value: 1
        },
        {
          label: '续费',
          value: 2
        },
        {
          label: '加功能',
          value: 3
        },
        {
          label: '尾款',
          value: 4
        }
      ],
      allPay: 1,
      DataBaseType: {
        TypeKey: '', // 1默认服务器 3一般服务器
        name: '',
        id: ''
      },
      showAuthCode: false,
      isDataBaseBoolean: false,
      isAdd: false,     // 是否新用户.
      disableOpenWKTKey: false,   // 是否选中了续费及还尾款. 如果是则不允许点击
      disableOpenOfficialAccountsKey: false,   // 是否选中了续费及还尾款. 如果是则不允许点击
      disableOpenCoursewareKey: false,
      isShowDatabaseServer: false// 是否选择数据库服务器
    };
  },
  components: {
    authCode,
    databaseType
  },
  props: {
    seletedItem: Object,
    paymentType: {
      type: Number,
      default: 0
    }
  },
  created () {
    if (this.seletedItem.StatusKey == 2) {
      this.isDataBaseBoolean = true;
    }
    console.log(this.seletedItem.StatusKey, this.vIsEdit, this.isDataBaseBoolean, 'this.isDataBaseBoolean');
    if (this.seletedItem && this.seletedItem.PayAuditKey > 0) {
      console.log(this.seletedItem.PayAuditKey, 'this.seletedItem.PayAuditKey');
      this.getDetailInfo(this.seletedItem.PayAuditKey);
    } else {
      this.initAddData();
    };
    console.log(this.dataInfo, 'dataInfo', this.seletedItem);
  },
  computed: {
    vCanAcademicPortInput () {
      if (this.vIsEdit) {
        return true;
      } else if (Number(this.seletedItem.IsOpenAcademicPortKey) == 0) {
        return false;
      } else {
        if (this.vTypeKey != 3) {
          return true;
        } else {
          return false;
        }
      }
    },
    vCanCoursewarePortInput () {
      if (this.vIsEdit) {
        return true;
      } else if (Number(this.seletedItem.IsOpenCoursewarePortKey) == 0) {
        return false;
      } else {
        if (this.vTypeKey != 3) {
          return true;
        } else {
          return false;
        }
      }
    },

    vIsEdit () {
      if (this.seletedItem && this.seletedItem.PayAuditKey > 0) {
        return true;
      } else {
        return false;
      }
    },
    vStatusValve () {
      if (Number(this.seletedItem.StatusKey) == 0) {
        return '未开通';
      } else if (Number(this.seletedItem.StatusKey) == 1) {
        return '临时开通';
      } else if (Number(this.seletedItem.StatusKey) == 2) {
        return '正式开通';
      } else {
        return '关闭';
      }
    },
    vTypeKey () {
      return this.dataInfo.PayMent.TypeKey;
    },
    vTypeKeyValue () {
      return this.dataInfo.PayMent.TypeKey == 0 ? '' : this.dataInfo.PayMent.TypeKeyValue;
    },
    vBalanceAmount () {
      console.log(this.dataInfo.PayMent, 'this.dataInfo.PayMent');
      return Number(this.dataInfo.PayMent.ContractAmount) - Number(this.dataInfo.PayMent.ActuallyAmount);
    },
    vPaymenttypeList () {
      let newArr = [];
      this.paymenttypeList.forEach(o => {
        if (o.value == 1) {
          if (this.seletedItem.StatusKey != 2 && !this.seletedItem.OfficialDredgeTime) {
            newArr.push(o);
          }
        } else {
          newArr.push(o);
        }
      });
      return newArr;
    },
    // 是否开通业务功能
    vIsOpenAcademicKey () {
      return this.seletedItem.IsOpenAcademicKey == 1;
    },
    // 是否开通微网校功能
    vIsOpenWKTKey () {
      return this.seletedItem.IsOpenWKTKey == 1;
    },
    // 是否开通公众号
    vIsOpenOfficialAccountsKey () {
      return this.seletedItem.IsOpenOfficialAccountsKey == 1;
    },
    // 是否开通自研课件
    vIsOpenSelfCoursewareKey () {
      return this.seletedItem.IsOpenSelfCoursewareKey == 1;
    },
    // 是否开通教务端口
    visAcademicPort () {
      return this.seletedItem.IsOpenAcademicPortKey == 1;
    },
    // 是否开通课件端口
    visCoursewarePort () {
      return this.seletedItem.IsOpenCoursewarePortKey == 1;
    }

  },
  watch: {
    vBalanceAmount (c, o) {
      if (this.allPay == 2) {
        this.dataInfo.PayMent.BalanceAmount = c;
      }
    }
  },
  methods: {
    getGetDatabaseServeSaaSClientList (id) {
      GetDatabaseServeSaaSClientList().then(result => {
        result.data.forEach(o => {
          if (o.id == Number(id)) {
            this.DataBaseType.name = o.name;
            this.DataBaseType.TypeKey = o.TypeKey;
            this.DataBaseType.id = o.id;
            console.log('GetDatabaseServeSaaSClientList', o, this.DataBaseType, this.dataInfo.DataBaseKey);
          }
        });
      });
    },
    UpdataDatabaseServer (type) {
      this.DataBaseType.name = type.name;
      this.DataBaseType.TypeKey = type.TypeKey;
      this.DataBaseType.id = type.id;
      this.dataInfo.DataBaseKey = type.id;
      this.isShowDatabaseServer = false;
      console.log(type, 'type', this.dataInfo.DataBaseKey);
    },
    // 打开选择服务器
    changeDatabaseType () {
      if (this.isDataBaseBoolean) {
        this.isShowDatabaseServer = false;
      } else {
        this.isShowDatabaseServer = true;
      }
    },
    // 关闭数据库服务器
    closeDatabaseServerPopuP () {
      this.isShowDatabaseServer = false;
    },
    changeDate (date) {
      console.log('changeDate', date);
    },
    initAddData () {
      console.log(this.$store.getters.token, 'initAddData');
      // this.dataInfo.DredgeTime = this.seletedItem.DredgeTime;
      this.dataInfo.ApplicantKey = this.$store.getters.token.UserID;
      this.dataInfo.ApplicantKeyValue = this.$store.getters.token.UserName;
      this.dataInfo.SaaSClientNameKey = this.seletedItem.SaaSClientNameKey; // '机构Key',
      this.dataInfo.SaaSClientNameKeyValue = this.seletedItem.SaaSClientNameKeyValue; // '机构',
      this.dataInfo.MobileSupport = this.seletedItem.MobileSupport; // '手机号',
      this.dataInfo.ContactsName = this.seletedItem.ContactsName; // '联系人',
      this.dataInfo.ThreadKey = this.seletedItem.OLAPKey; // '线索key',
      this.dataInfo.SaaSClientStatusKey = this.seletedItem.StatusKey;
      this.dataInfo.SaaSClientStatusKeyValue = this.seletedItem.StatusKeyValue;

      if (this.seletedItem.DataBaseKey == undefined) {
        this.dataInfo.DataBaseKey = 1;
      } else {
        this.dataInfo.DataBaseKey = this.seletedItem.DataBaseKey;
      }
      this.getGetDatabaseServeSaaSClientList(this.dataInfo.DataBaseKey);

      if (this.vIsOpenAcademicKey) {
        this.dataInfo.PayMent.IsOpenAcademicKey = '1';
      }
      if (this.vIsOpenWKTKey) {
        this.dataInfo.PayMent.IsOpenWKTKey = '1';
      }
      if (this.vIsOpenOfficialAccountsKey) {
        this.dataInfo.PayMent.IsOpenOfficialAccountsKey = '1';
      }
      if (this.vIsOpenSelfCoursewareKey) {
        this.dataInfo.PayMent.IsOpenSelfCoursewareKey = '1';
      }

      if (Number(this.seletedItem.StatusKey) != 2 && !this.seletedItem.OfficialDredgeTime) {
        this.dataInfo.PayMent.TypeKey = 1;
        this.dataInfo.PayMent.TypeKeyValue = '首付';
        if (Number(this.seletedItem.SaaSClientCount) > 1) {
          this.isAdd = true;
        } else {
          this.isAdd = false;
        }
      } else {
        this.dataInfo.PayMent.TypeKey = 2;
        this.dataInfo.PayMent.TypeKeyValue = '续费';
        this.disableOpenWKTKey = true;
        this.disableOpenCoursewareKey = true;
        this.disableOpenOfficialAccountsKey = true;
        this.isAdd = false;
      }

      console.log('paymenttypeList', this.seletedItem);

      if (this.seletedItem.EditionKey == 6 && this.seletedItem.TypeKey == 3) {
        this.disableOpenWKTKey = true;
        this.disableOpenOfficialAccountsKey = true;
        this.isAdd = false;
        this.disableOpenCoursewareKey = false;
      }

      if (this.seletedItem.TypeKey == 6) {
        this.dataInfo.PayMent.IsOpenAcademicPortKey = this.seletedItem.IsOpenAcademicPortKey;
        this.dataInfo.PayMent.IsOpenCoursewarePortKey = this.seletedItem.IsOpenCoursewarePortKey;

        this.dataInfo.CoursewarePortCount = Number(this.seletedItem.CoursewarePortCount) || 0;
        this.dataInfo.AcademicPortCount = Number(this.seletedItem.AcademicPortCount) || 0;

        this.disableOpenWKTKey = false;
        this.disableOpenOfficialAccountsKey = false;

        this.disableOpenCoursewareKey = false;
      }
      if (this.seletedItem.EditionKey == 6 && this.seletedItem.TypeKey == 6) {
        this.disableOpenWKTKey = true;
        this.disableOpenCoursewareKey = false;
        this.disableOpenOfficialAccountsKey = true;
        this.isAdd = true;
      }
      if (this.seletedItem.EditionKey == 3) {
        this.disableOpenWKTKey = false;
        this.disableOpenOfficialAccountsKey = false;
        if (this.seletedItem.IsOpenSelfCoursewareKey == 1) {
          this.disableOpenCoursewareKey = true;
        }
        if (this.seletedItem.IsOpenAcademicKey == 1) {
          this.isAdd = true;
        }
        if (this.seletedItem.IsOpenSelfCoursewareKey != 1 && this.seletedItem.IsOpenAcademicKey != 1) {
          this.isAdd = false;
          this.disableOpenCoursewareKey = false;
        }
      }

      if (this.seletedItem.SaaSClientCountText == '单店') {
        this.dataInfo.PayMent.IsOpenAcademicKey = '1';
        this.isAdd = true;
      }

      // 课件门店不支持续费
      if (this.seletedItem.EditionKey == 6 || this.paymentType == 3) {
        this.paymenttypeList = this.paymenttypeList.filter(o => { return o.value != 2; });
        this.dataInfo.PayMent.TypeKey = 3;
        this.dataInfo.PayMent.TypeKeyValue = '加功能';
        this.disableOpenWKTKey = false;
        this.disableOpenCoursewareKey = false;
        this.disableOpenOfficialAccountsKey = false;
        this.isAdd = false;
      }
      // 续费
      if (this.dataInfo.PayMent.TypeKey == 4 || this.dataInfo.PayMent.TypeKey == 2) {
        if (!this.vIsOpenWKTKey) {
          this.dataInfo.PayMent.IsOpenWKTKey = 0;
          this.disableOpenWKTKey = true;
          this.disableOpenCoursewareKey = true;
        }
        if (!this.vIsOpenOfficialAccountsKey) {
          this.dataInfo.PayMent.IsOpenOfficialAccountsKey = 0;
          this.disableOpenOfficialAccountsKey = true;
        }
        if (!this.vIsOpenSelfCoursewareKey) {
          this.dataInfo.PayMent.IsOpenSelfCoursewareKey = 0;
          this.disableOpenOfficialAccountsKey = true;
        }
      } else {
        this.disableOpenWKTKey = false;
        this.disableOpenCoursewareKey = false;
        this.disableOpenOfficialAccountsKey = false;
      }
      if (this.seletedItem.EditionKey == 6 && this.seletedItem.TypeKey != 3) {
        this.disableOpenWKTKey = true;
        this.disableOpenCoursewareKey = true;
        this.disableOpenOfficialAccountsKey = true;
        this.isAdd = true;
        if (this.dataInfo.PayMent.IsOpenSelfCoursewareKey == 0) {
          this.disableOpenCoursewareKey = false;
        } else {
          this.disableOpenCoursewareKey = true;
        }
      }
    },
    getDetailInfo (TableID) {
      GetApplyDetails(Number(TableID)).then(result => {
        this.dataInfo = result.data;
        this.allPay = this.dataInfo.PayMent.BalanceAmount > 0 ? 2 : 1;
        this.dataInfo.PayMent.IsOpenAcademicPortKey = Number(this.dataInfo.PayMent.IsOpenAcademicPortKey).toString();
        this.dataInfo.PayMent.IsOpenCoursewarePortKey = Number(this.dataInfo.PayMent.IsOpenCoursewarePortKey).toString();
        this.getGetDatabaseServeSaaSClientList(result.data.DataBaseKey);
        this.dataInfo.DataBaseKey = Number(result.data.DataBaseKey);
        console.log('GetApplyDetails', result.data, this.dataInfo);
      });
    },
    // 提交审核
    submitAudit (callback) {
      console.log('submit', this.dataInfo);
      this.showAuthCode = true;
      callback();
    },
    // 提交新增
    submitAdd (callback) {
      console.log('submit', this.dataInfo);
      if (!this.verifyData()) {
        if (callback) {
          callback();
        }
        return false;
      }
      if (this.vIsOpenAcademicKey) {
        this.dataInfo.PayMent.IsOpenAcademicKey = '0';
      }
      if (this.vIsOpenWKTKey) {
        this.dataInfo.PayMent.IsOpenWKTKey = '0';
      }
      if (this.vIsOpenOfficialAccountsKey) {
        this.dataInfo.PayMent.IsOpenOfficialAccountsKey = '0';
      }
      if (this.vIsOpenSelfCoursewareKey) {
        this.dataInfo.PayMent.IsOpenSelfCoursewareKey = '0';
      }
      if (this.visAcademicPort) {
        // this.dataInfo.AcademicPortCount = 0;
        this.dataInfo.PayMent.IsOpenAcademicPortKey = '0';
      }
      if (this.visCoursewarePort) {
        // this.dataInfo.CoursewarePortCount = 0;
        this.dataInfo.PayMent.IsOpenCoursewarePortKey = '0';
      }
      AddApply(this.dataInfo).then(result => {
        layer.alert('新增成功');
        this.$emit('afterSuccess');
        this.doAfterClickCancelBtn();
        if (callback) {
          callback();
        }
      }).catch(error => {
        layer.alert(error.msg);
        if (callback) {
          callback();
        }
        console.log('errEditDefaultReply', error);
      });
    },
    verifyData () {
      let isPass = true;
      if (!this.dataInfo.PayMent.PaymentTime) {
        layer.alert('请选择付款日期');
        isPass = false;
      }
      if (this.dataInfo.PayMent.TypeKey == 1) {
        if (!this.dataInfo.PayMent.ExpireDateTime) {
          layer.alert('请选择门店有效期');
          isPass = false;
        }
        if (Date.new(this.dataInfo.PayMent.ExpireDateTime) < Date.new(this.dataInfo.PayMent.PaymentTime)) {
          layer.alert('付款日期不能大于门店有效期');
          isPass = false;
        }
      }
      if (this.dataInfo.PayMent.TypeKey == 2) {
        if (!this.dataInfo.PayMent.RenewCount) {
          layer.alert('请输入年数');
          isPass = false;
        }
      }
      if (this.dataInfo.PayMent.TypeKey == 2 || this.dataInfo.PayMent.TypeKey == 1) {
        if (!this.dataInfo.PayMent.ContractAmount || this.dataInfo.PayMent.ContractAmount == 0) {
          layer.alert('请选择输入合同金额');
          isPass = false;
        }
        if (this.allPay == 2) {
          if (!this.dataInfo.PayMent.ActuallyAmount) {
            layer.alert('请输入实付金额');
            isPass = false;
          }
          if (!this.dataInfo.PayMent.BalanceAmount) {
            layer.alert('请输入尾款金额');
            isPass = false;
          }
          if (Number(this.dataInfo.PayMent.ContractAmount) < this.dataInfo.PayMent.ActuallyAmount) {
            layer.alert('合同金额不能少于实付金额');
            isPass = false;
          }
          if (Number(this.dataInfo.PayMent.ActuallyAmount) + Number(this.dataInfo.PayMent.BalanceAmount) != Number(this.dataInfo.PayMent.ContractAmount)) {
            layer.alert('实付金额和尾款金额相加必须等于合同金额');
            isPass = false;
          }
        } else {
          this.dataInfo.PayMent.BalanceAmount = 0;
        }
      }
      if (this.dataInfo.PayMent.TypeKey == 3 && (!this.dataInfo.PayMent.ContractAmount || this.dataInfo.PayMent.ContractAmount == 0)) {
        layer.alert('请选择输入合同金额');
        isPass = false;
      }
      if (this.dataInfo.PayMent.TypeKey == 3 && this.seletedItem.TypeKey != 6) {
        console.log(this.dataInfo.PayMent, '加功能', this.vIsOpenAcademicKey, this.vIsOpenWKTKey, this.vIsOpenOfficialAccountsKey, this.vIsOpenSelfCoursewareKey);
        if (this.vIsOpenAcademicKey) {
          if (this.dataInfo.PayMent.IsOpenWKTKey == 0 && this.dataInfo.PayMent.IsOpenOfficialAccountsKey == 0 && this.dataInfo.PayMent.IsOpenSelfCoursewareKey == 0) {
            layer.alert('请选择要加的功能');
            isPass = false;
          }
          if (this.vIsOpenWKTKey && this.dataInfo.PayMent.IsOpenOfficialAccountsKey == 0 && this.dataInfo.PayMent.IsOpenSelfCoursewareKey == 0) {
            layer.alert('请选择要加的功能');
            isPass = false;
          }
          if (this.vIsOpenOfficialAccountsKey && this.dataInfo.PayMent.IsOpenWKTKey == 0 && this.dataInfo.PayMent.IsOpenSelfCoursewareKey == 0) {
            layer.alert('请选择要加的功能');
            isPass = false;
          }
          if (this.vIsOpenSelfCoursewareKey && this.dataInfo.PayMent.IsOpenOfficialAccountsKey == 0 && this.dataInfo.PayMent.IsOpenWKTKey == 0) {
            layer.alert('请选择要加的功能');
            isPass = false;
          }
        }
        if (this.vIsOpenWKTKey) {
          if (this.dataInfo.PayMent.IsOpenAcademicKey == 0 && this.dataInfo.PayMent.IsOpenOfficialAccountsKey == 0 && this.dataInfo.PayMent.IsOpenSelfCoursewareKey == 0) {
            layer.alert('请选择要加的功能');
            isPass = false;
          }
          if (this.vIsOpenAcademicKey && this.dataInfo.PayMent.IsOpenOfficialAccountsKey == 0 && this.dataInfo.PayMent.IsOpenSelfCoursewareKey == 0) {
            layer.alert('请选择要加的功能');
            isPass = false;
          }
          if (this.vIsOpenOfficialAccountsKey && this.dataInfo.PayMent.IsOpenAcademicKey == 0 && this.dataInfo.PayMent.IsOpenSelfCoursewareKey == 0) {
            layer.alert('请选择要加的功能');
            isPass = false;
          }
          if (this.vIsOpenSelfCoursewareKey && this.dataInfo.PayMent.IsOpenOfficialAccountsKey == 0 && this.dataInfo.PayMent.IsOpenAcademicKey == 0) {
            layer.alert('请选择要加的功能');
            isPass = false;
          }
        }
        if (this.vIsOpenOfficialAccountsKey) {
          if (this.dataInfo.PayMent.IsOpenAcademicKey == 0 && this.dataInfo.PayMent.IsOpenWKTKey == 0 && this.dataInfo.PayMent.IsOpenSelfCoursewareKey == 0) {
            layer.alert('请选择要加的功能');
            isPass = false;
          }
          if (this.vIsOpenAcademicKey && this.dataInfo.PayMent.IsOpenWKTKey == 0 && this.dataInfo.PayMent.IsOpenSelfCoursewareKey == 0) {
            layer.alert('请选择要加的功能');
            isPass = false;
          }
          if (this.vIsOpenWKTKey && this.dataInfo.PayMent.IsOpenAcademicKey == 0 && this.dataInfo.PayMent.IsOpenSelfCoursewareKey == 0) {
            layer.alert('请选择要加的功能');
            isPass = false;
          }
          if (this.vIsOpenSelfCoursewareKey && this.dataInfo.PayMent.IsOpenAcademicKey == 0 && this.dataInfo.PayMent.IsOpenWKTKey == 0) {
            layer.alert('请选择要加的功能');
            isPass = false;
          }
        }
        if (this.vIsOpenSelfCoursewareKey) {
          if (this.dataInfo.PayMent.IsOpenAcademicKey == 0 && this.dataInfo.PayMent.IsOpenWKTKey == 0 && this.dataInfo.PayMent.IsOpenOfficialAccountsKey == 0) {
            layer.alert('请选择要加的功能');
            isPass = false;
          }
          if (this.vIsOpenAcademicKey && this.dataInfo.PayMent.IsOpenWKTKey == 0 && this.dataInfo.PayMent.IsOpenOfficialAccountsKey == 0) {
            layer.alert('请选择要加的功能');
            isPass = false;
          }
          if (this.vIsOpenWKTKey && this.dataInfo.PayMent.IsOpenAcademicKey == 0 && this.dataInfo.PayMent.IsOpenOfficialAccountsKey == 0) {
            layer.alert('请选择要加的功能');
            isPass = false;
          }
          if (this.vIsOpenOfficialAccountsKey && this.dataInfo.PayMent.IsOpenAcademicKey == 0 && this.dataInfo.PayMent.IsOpenWKTKey == 0) {
            layer.alert('请选择要加的功能');
            isPass = false;
          }
        }
        if (this.isAdd) {
          this.dataInfo.PayMent.IsOpenAcademicKey = '0';
        }
        this.dataInfo.PayMent.BalanceAmount = 0;
      }
      if (this.dataInfo.PayMent.TypeKey == 3 && this.seletedItem.TypeKey == 6) {
        if (this.isAdd) {
          this.dataInfo.PayMent.IsOpenAcademicKey = '0';
        }
        this.dataInfo.PayMent.BalanceAmount = 0;
      }
      if (this.dataInfo.PayMent.TypeKey == 4) {
        if (!this.dataInfo.PayMent.ActuallyAmount) {
          layer.alert('请选择输入还尾款金额');
          isPass = false;
        } else {
          this.dataInfo.PayMent.BalanceAmount = 0;
        }
      } else if (this.allPay == 1) {
        this.dataInfo.PayMent.ActuallyAmount = this.dataInfo.PayMent.ContractAmount;
      }
      if (!this.dataInfo.PayMent.AccessorySupport) {
        layer.alert('请上传合同附件');
        isPass = false;
      }
      if (!this.dataInfo.PayMent.PayCutSupport) {
        layer.alert('请上传付款截图');
        isPass = false;
      }
      return isPass;
    },
    // 撤销
    revocation () {
      layer.confirm('请问是否要撤销该申请', {
        btn: [{
          name: '确认',
          callBack: () => {
            CancelApply(this.dataInfo.TableID).then(result => {
              this.$emit('afterSuccess');
              this.doAfterClickCancelBtn();
            }).catch(error => {
              layer.alert(error.msg);
            });
            layer.close();
          }
        }
        ]
      });
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    },
    closeShowAuthCode () {
      this.showAuthCode = false;
    },
    afterSuccess () {
      this.showAuthCode = false;
      this.$nextTick(() => {
        this.$emit('afterSuccess');
        this.$emit('close');
      });
    },
    seletedbusiness (val) {
      console.log(val, 'seletedbusiness');
    },
    seletedcleanCustor (val) {
      console.log(val, 'seletedcleanCustor');
    },
    // 教务端口
    openAcademicPort () {
      this.dataInfo.AcademicPortCount = 0;
    },
    // 课件端口
    openCoursewarePort () {
      this.dataInfo.CoursewarePortCount = 0;
    },
    seletedcleanClassCount (val) {
      console.log(val, 'seletedcleanClassCount');
    },
    seletedcleanCourseware (val) {
      console.log(val, 'seletedcleanCourseware');
    },
    selectPaymenttype (item) {
      this.allPay = 1;
      this.dataInfo.PayMent.ActuallyAmount = 0;
      this.dataInfo.PayMent.TypeKey = item.value;
      this.dataInfo.PayMent.TypeKeyValue = item.label;
      this.dataInfo.AcademicPortCount = 0;
      this.dataInfo.CoursewarePortCount = 0;

      if (this.dataInfo.PayMent.TypeKey == 4) {
        this.dataInfo.PayMent.ActuallyAmount = Number(this.seletedItem.BalanceAmount);
      }
      if (this.dataInfo.PayMent.TypeKey == 4 || this.dataInfo.PayMent.TypeKey == 2) {
        if (!this.vIsOpenWKTKey) {
          this.dataInfo.PayMent.IsOpenWKTKey = 0;
          this.disableOpenWKTKey = true;
          this.disableOpenCoursewareKey = true;
        }
        if (!this.vIsOpenOfficialAccountsKey) {
          this.dataInfo.PayMent.IsOpenOfficialAccountsKey = 0;
          this.disableOpenOfficialAccountsKey = true;
        }
        if (!this.vIsOpenSelfCoursewareKey) {
          this.dataInfo.PayMent.IsOpenSelfCoursewareKey = 0;
          this.disableOpenOfficialAccountsKey = true;
        }
      } else {
        this.disableOpenWKTKey = false;
        this.disableOpenCoursewareKey = false;
        this.disableOpenOfficialAccountsKey = false;
      }
      console.log('selectPaymenttype', item);
    },
    uploadingFile (e, type) {
      console.log(e.target.files[0], '什么鬼');
      // 文件对象
      let typeNum = type == 'file' ? 6 : 1;
      var form = e.target.files[0];
      var formData = new FormData();
      // 文件对象
      formData.append('file', form);
      // 防止文件取消时
      if (form) {
        UploadFiles(typeNum, '', formData).then(result => {
          if (type == 'file') {
            this.dataInfo.PayMent.ContractName = form.name;
            this.dataInfo.PayMent.AccessorySupport = result.data.url;
          } else {
            this.dataInfo.PayMent.PayCutSupport = result.data.url;
          }
          console.log(result.data.url, '撒的发生的');
        }, err => {
          layer.alert(err.msg);
        });
      }
      this.$emit('item', this.newData);
      this.radio = '1';
    }
  }
};
</script>
<style scoped>
.info_file-upload {
  opacity: 0;
}
.customer_management_from .table_select_box .el-select {
  width: 88px;
}
.el-checkbox {
  margin-right: 0px;
}
.popup_height_from {
  height: 638px;
}
.form_info_edit >>> .form_info_line .form_info_value input {
  color: #3498db;
}
</style>

