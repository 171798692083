<template>
  <!-- 物类内容 -->
  <div class="manage_content_list manage_block">
    <div class="manage_content_left"
         ref="classBoxName">
      <div class="manage_content_name">{{goodsTypeBox.MainDemoName}}</div>
      <div class="manage_content_menuBtn"
           v-if="goodsTypeBox.OLAPKey>0"
           @click.stop="doTypeOperateClick(goodsTypeBox,$event)">
      </div>
    </div>
    <!-- 物类项  -->
    <div class="manage_content_right">
      <draggable v-model="goodsTypeBox.buildGoodsList"
                 group="site"
                 animation="100"
                 dragClass="dragClass"
                 ghostClass="ghostClass"
                 chosenClass="chosenClass"
                 filter=".forbid"
                 v-bind="{sort: false,store: false,}"
                 @start="onStart"
                 @end="onEnd"
                 @add="onAdd">
        <transition-group class="draggable"
                          :id="goodsTypeBox.OLAPKey">
          <goods-name-box v-for="(item,index) in goodsTypeBox.buildGoodsList"
                          :key="item.OLAPKey"
                          :index="index"
                          :nameItem="item"
                          :isDrag="isDrag"
                          @doMouseEvent="doMouseEvent"></goods-name-box>
          <div :class="'item forbid'"
               :key="'新增'"
               v-if="goodsTypeBox.OLAPKey>0">
            <div class="manage_content_block block_add"
                 @click="onAddGoodsNameClick">
              <span class="add_icon">+</span>
              <!-- <span>新增</span> -->
            </div>
          </div>

        </transition-group>
      </draggable>
    </div>
  </div>
</template> 

<script>
// 导入draggable组件(vuedraggable)
import draggable from 'vuedraggable';
import { EditGoods } from '../../../../../API/workbench';
import goodsNameBox from './goods-name-box';
import dropDownBox from '../../../../common/drop-down-box';
export default {
  data () {
    return {
      draggableItem: {},
      menuItemList: [
        { type: 1, name: '查看', eventName: 'view', isShow: true, ModulePowerKey: '' },
        { type: 2, name: '修改', eventName: 'edit', isShow: true, ModulePowerKey: '' },
        { type: 3, name: '停用', eventName: 'unActive', isShow: true, ModulePowerKey: '' }
      ],
      newOffsetHeight: 60,
      draggableItemID: '',
      isDrag: false // 正在拖拽
    };
  },
  props: {
    goodsTypeBox: Object,
    goodsTypeIndex: Number,
    goodsList: Array
  },
  components: {
    goodsNameBox,
    dropDownBox,
    draggable
  },

  created () {
    console.log(this.goodsTypeBox, 'goodsTypeBox');
  },
  mounted () {

  },
  activated () {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    // 开始拖拽事件
    onStart (e) {
      this.isDrag = true;
      this.goodsTypeBox.buildGoodsList.forEach(o => {
        o.isMenu = false;
      });
      this.draggableItem = this.goodsTypeBox.buildGoodsList[e.oldIndex];
    },
    onAdd (e) {
      this.goodsTypeBox.buildGoodsList.forEach(o => {
        o.isMenu = false;
      });
    },
    // 拖拽结束事件
    onEnd (e) {
      console.log(this.draggableItem, '拖拽项1');
      this.goodsTypeBox.buildGoodsList.forEach(o => {
        o.isEmptiness = false;
      });
      if (this.draggableItem && e.to.id !== e.from.id) {
        this.draggableItem.GoodsTypeKey = Number(e.to.id) == 0 ? 2 : e.to.id;
        let findItem = this.goodsList.find(o => {
          return o.OLAPKey == this.draggableItem.GoodsTypeKey;
        });
        if (findItem) {
          this.draggableItem.GoodsTypeKeyValue = findItem.MainDemoName;
        }
        console.log(findItem, '拖拽项');
        this.draggableItem.isMenu = false;
        // 提交数据对象
        let dataJson = {
          MainDemoName: this.draggableItem.MainDemoName,
          GoodsTypeKey: this.draggableItem.GoodsTypeKey,
          GoodsTypeKeyValue: this.draggableItem.GoodsTypeKeyValue,
          IsSellKey: this.draggableItem.IsSellKey,
          IsSellKeyValue: this.draggableItem.IsSellKeyValue,
          IncidentalsKey: this.draggableItem.IncidentalsKey,
          IncidentalsKeyValue: this.draggableItem.IncidentalsKeyValue,
          SalesPriceAmount: this.draggableItem.SalesPriceAmount
        };
        EditGoods(this.draggableItem.OLAPKey, 0, dataJson).then(result => {
        }, error => {
          layer.alert(error.msg);
        });
      }
      this.isDrag = false;
    },
    //  物类操作显示
    doTypeOperateClick (item, e) {
      // console.log(e, '课类操作显示');
      this.goodsList.forEach(o => {
        if (this.goodsTypeBox.OLAPKey == o.OLAPKey) {
          o.isMenu = true;
        } else {
          o.isMenu = false;
        }
      });
      let locationHeight = this.$refs.classBoxName.offsetHeight / 2 - 30;// 点击当前课类所在的位置
      this.$emit('onTypeOperateClick', item, this.goodsTypeIndex, locationHeight);
      console.log(this.$refs.classBoxName.offsetHeight / 2, 'offsetHeight');
    },
    //  物品移出移入显示
    doMouseEvent (type, goodsIndex, item) {
      let locationHeight = this.$refs.classBoxName.offsetHeight;// 点击当前课类所在的位置
      this.$emit('doMouseEvent', type, this.goodsTypeIndex, locationHeight, goodsIndex, item);
    },
    // 新增物品
    onAddGoodsNameClick () {
      let obj = {
        type: 'add',
        moduleName: 'goodsNameSetting',
        data: {
          GoodsTypeKey: this.goodsTypeBox.OLAPKey,
          GoodsTypeKeyValue: this.goodsTypeBox.MainDemoName
        }
      };
      this.$emit('doGoodsNameItemClick', obj);
    }
  }

};
</script>

<style>
/*被拖拽对象的样式*/
/* .draggable_item { */
/* padding: 6px;
  background-color: #fdfdfd;
  border: solid 1px #eee;
  margin-bottom: 10px;
  cursor: move; */
/* } */
/* .draggable_item:hover {
  background-color: #f1f1f1;
  cursor: move;
} */
/*选中样式*/
/* .chosenClass {
  border: solid 2px #3089dc !important;
} */
</style>