<template>
  <!-- 学生课消统计 -->
  <div class="statistics_bottom_dual">
    <div class="statistics_bottom_title">
      <div>课消榜</div>
      <div class="change_history_data"
           @click.stop="historyBtn(historyName)">{{ historyName }}</div>
      <div class="">
        <date-range ref="saveDate"
                    :searchObj="studentClassAwayDate"
                    :fromTitle="'选择日期'"
                    :minDate="vMinDate"
                    :maxDate="vMaxDate"
                    :isShowHistory="isShowHistory"
                    @changeDate="changeSaveDate"></date-range>
      </div>
    </div>
    <div class="statistics_bottom_table">
      <class-away-list ref="studentApplyCourseTable"
                       :rowKey="'Number'"
                       :tableData="studentClassAwayList"
                       :tableColumns="vtableColumns"
                       :isSearchText="false"
                       :isNeedInnerScroll="false"
                       @tableBtnClick="doAfterTableBtnClick"
                       @loadTableData="loadAfterTableData"
                       @tableaRowClick="tableaRowClick"></class-away-list>
    </div>
  </div>
</template>
<script>
import classAwayList from './class-away-list';
import {
  MessageSearchClassAwayForGrade, MessageSearchHistoryClassAwayForGrade, GetTheClassByID
} from '../../../../../API/workbench.js';
import { parseJson } from '../../../../../utils';
export default {
  data () {
    return {
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      //  班级学生报名分析 student-apply-report
      studentClassAwayDate: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd'),
        GradeID: ''//		整型	必须		数据源：GET	 班级id
      },
      // 班级课学生课消统计
      studentClassAwayList: [],
      selectedClassName: '', // 班级名
      tableColumns: [
        {
          label: '排名',
          prop: 'Number',
          width: 53,
          sortable: false,
          align: 'center',
          isShow: true,
          type: '',
          className: 'index_icon',
          decimalPlace: 0
        },
        {
          label: '学生名',
          prop: 'StudentNameKeyValue',
          minWidth: 75,
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          className: ''
        },
        {
          label: '课消金额',
          prop: 'DeductAmount',
          width: 80,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'number',
          decimalPlace: 2
        },
        {
          label: '课次',
          prop: 'CourseNum',
          width: 60,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'number',
          decimalPlace: 0
        },
        {
          label: '签到',
          prop: 'AttendanceNum',
          width: 60,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'number',
          decimalPlace: 0
        },
        {
          label: '请假',
          prop: 'LeaveNum',
          width: 60,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'number',
          decimalPlace: 1
        }
        // {
        //   label: '旷课',
        //   prop: 'TruantNum',
        //   width: 73,
        //   sortable: false,
        //   align: 'left',
        //   isShow: true,
        //   type: 'number',
        //   decimalPlace: 0,
        //   className: 'TruantNum_123'
        // }
      ]
    };
  },
  props: {
    classKey: [Number, String] // 列表数据

  },
  components: {
    classAwayList
  },
  created () {

  },
  watch: {
    'classKey': {
      handler (n, o) {
        console.log(n, '班级id-123');
        if (Number(n) > 0) {
          this.studentClassAwayDate.GradeID = n;
          this.$nextTick(() => {
            this.getMessageSearchClassAwayForGrade();
          });
        }
      },
      deep: true // 表示开启深度监听
    }
  },
  mounted () {
    this.getMessageSearchClassAwayForGrade();
  },
  methods: {
    historyBtn (name) {
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.studentClassAwayDate.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.studentClassAwayDate.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isShowHistory = true;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.studentClassAwayDate.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.studentClassAwayDate.endTime = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0));
        this.isShowHistory = false;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.getMessageSearchClassAwayForGrade();
      this.$refs.saveDate.changeHistoryTime(this.studentClassAwayDate.startTime, this.studentClassAwayDate.endTime);
    },
    getMessageSearchClassAwayForGrade () {
      let fn = '';
      if (this.isShowHistory) {
        fn = MessageSearchHistoryClassAwayForGrade;
      } else {
        fn = MessageSearchClassAwayForGrade;
      }
      if (Number(this.classKey) > 0 || this.studentClassAwayDate.GradeID) {
        this.studentClassAwayDate.GradeID = this.classKey;
        fn(this.studentClassAwayDate).then(result => {
          console.log(result.data, '班级课学生课消统计');
          result.data.forEach((o, index) => {
            o.Number = index++ > 8 ? index++ : '0' + index++;
            o.StudentKey = o.StudentNameKey;
            o.DeductAmount = o.DeductAmount || 0;
          });
          this.studentClassAwayList = result.data;
        }).catch(error => {
          console.log('ErroretAttenReport', error);
        });
      }
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, eventName, '按钮点击');
      switch (eventName) {
        case 'KQAP': // 适用课时详情
          this.seletedCourseInfo = rowData;
          this.$refs.newAttendanceArrangeDialog.isAttendanceArrangeShow = true;
          this.$refs.newAttendanceArrangeDialog.getCourseInfo(this.seletedCourseInfo.OLAPKey);
          break;
        default:
          break;
      }
    },
    // 跳转页码
    loadAfterTableData (queryParams) {
      console.log(queryParams, '跳转页码');
      this.$emit('loadTableData', queryParams);
    },
    // 课消考勤
    tableaRowClick (item) {
      console.log('点击单带大家');
      GetTheClassByID(this.studentClassAwayDate.GradeID).then(result => {
        console.log(result.data, this.studentClassAwayDate, '班级信息');
        if (result.data) {
          this.$router.push({
            name: 'classSpendStatisticsAnalyze',
            query: {
              type: 2,
              GradeKey: result.data.OLAPKey,
              GradeKeyValue: result.data.MainDemoName,
              CourseKey: '',
              StudentNameKey: item.StudentNameKey,
              StudentNameKeyValue: item.StudentNameKeyValue,
              startTime: this.studentClassAwayDate.startTime,
              endTime: this.studentClassAwayDate.endTime
            }
          });
        }
      });
    },
    changeSaveDate (dataInfo) {
      this.studentClassAwayDate.startTime = dataInfo.startTime;
      this.studentClassAwayDate.endTime = dataInfo.endTime;
      this.getMessageSearchClassAwayForGrade();
    }
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    },
    vtableColumns () {
      let truantItem = {
        label: '旷课',
        prop: 'TruantNum',
        width: 73,
        sortable: false,
        align: 'left',
        isShow: true,
        type: 'number',
        decimalPlace: 0,
        className: 'TruantNum_123'
      };
      let list = this.$utils.parseJson(this.tableColumns);
      if (this.$utils.getSaaSClientSet(59) > 0) {
        list.push(truantItem);
      }
      return list;
    },
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    }
  }
};
</script>
<style scoped>
.change_history_data {
  position: absolute;
  line-height: 34px;
  margin-left: 46px;
  width: 110px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  right: auto;
}
</style>