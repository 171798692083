<template>
  <!-- 跟单记录 -->
  <div class="documentary_records_list">
    <div class=""
         style="display: flex;margin-bottom: 20px;">
      <date-range ref="monthPicker"
                  :fromTitle="'跟单日期'"
                  :searchObj="searchObj"
                  @changeDate="changeSaveDate"></date-range>

      <!-- 已选条件 -->
      <input-search-contain-btn v-model="queryParams.searchText"
                                :placeholder="'搜索学生'"
                                @clearSearch="clearExtendSearchParams"
                                @onSearchEvent="search"></input-search-contain-btn>
    </div>
    <div class="table_list_content introduce_list campusManagement_list">
      <table-list ref="tableListRef"
                  class="summarizing_list schedule_table_class"
                  :tableData="threadFollowUpList"
                  :queryParams="queryParams"
                  :tableColumns="tableColumns"
                  :rowKey="'OLAPKey'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  @loadTableData="getMessageSearchPageThreadFollowUpList"></table-list>
    </div>
    <custom-dialog title="线索跟进记录"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowClewForm"
                   :before-close="doShowFollowUpHide">
      <clew-follow-form :studentItem="studentItem"
                        @close="doShowFollowUpHide"
                        @afterSuccess="getMessageSearchPageThreadFollowUpList"></clew-follow-form>
    </custom-dialog>
    <custom-dialog title="跟单记录流水"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowRecordList"
                   :before-close="doShowFollowUpHide">
      <clew-follow-record-list :studentItem="studentItem"
                               :StudentThreadKey="StudentThreadKey"
                               :isAllFollowRecord="isAllFollowRecord"
                               @close="doShowFollowUpHide"></clew-follow-record-list>
    </custom-dialog>
  </div>
</template>
<script>
import { MessageSearchPageThreadFollowUpList } from '../../../API/workbench.js';
import inputSearchContainBtn from '../../report/common/input-search-contain-btn';
import tableList from '../../common/table-list/index';
import clewFollowForm from '../clue-list/clew-follow-form';
import clewFollowRecordList from '../clue-list/clew-follow-record-list';
export default {
  data () {
    return {
      isAllFollowRecord: false, // false 单条数据  true 全部数据
      isShowRecordList: false,
      studentItem: null,
      StudentThreadKey: 0,
      isShowClewForm: false,
      queryParams: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        StartTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        EndTime: this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd')
      },
      searchObj: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd')
      },
      threadFollowUpList: [],
      tableColumns: [
        {
          label: '序号',
          width: 65,
          prop: 'Number',
          align: 'center',
          type: 'tableIndex',
          sortable: false
        },
        {
          label: '跟单日期',
          width: 100,
          sortable: 'custom',
          prop: 'GenerateTime',
          type: 'text-item'
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 100,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '手机号',
          prop: 'MobilePhone',
          width: 110,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '节点变化',
          prop: 'CurrentFormerNodeValue',
          width: 120,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '标签变化',
          prop: 'LabelChange',
          width: 240,
          align: 'left',
          type: 'text-item'
        },
        {
          label: '内容记录',
          prop: 'FollowContentValue',
          align: 'left',
          type: 'text-item',
          extend: {
            type: 'expand',
            click: (rowData) => { this.doFollowContentClick(rowData); }
          }
        },
        {
          label: '操作',
          prop: 'option',
          width: 70,
          type: 'table-btn',
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  isHide: false,
                  click: (rowData) => {
                    this.editBtn(rowData);
                  }
                }
              }
            ]
          }
        }
      ],
      totalNum: 0
    };
  },
  components: {
    inputSearchContainBtn,
    tableList,
    clewFollowForm,
    clewFollowRecordList
  },
  computed: {
  },
  created () {
    this.getMessageSearchPageThreadFollowUpList();
  },
  watch: {
  },
  activated () {
  },
  mounted () {
  },
  methods: {
    doFollowContentClick (item) {
      this.isAllFollowRecord = true;
      console.log(item, 'item studentItem', this.studentItem);
      this.StudentThreadKey = item.StudentThreadKey;
      this.studentItem = item;
      this.isShowRecordList = true;
    },
    doShowFollowUpHide () {
      this.isShowRecordList = false;
      this.isShowClewForm = false;
    },
    editBtn (item) {
      this.isAllFollowRecord = false;
      this.StudentThreadKey = item.FollowUpKey;
      this.studentItem = item;
      this.isShowRecordList = true;
    },
    // e为要改变颜色的字符（传入的参数）
    searchAddinfo (a) {
      let res;
      res = a.replace(/\+/g, "<span class='add_minus_red'>+</span>");
      return res;
    },
    searchMinusinfo (a) {
      let res;
      res = a.replace(/-/g, "<span class='add_minus_red'>-</span>");
      return res;
    },
    searchAddOrMinusinfo (a) {
      let res1, res2;
      res1 = a.replace(/\+/g, "<span class='add_minus_red'>+</span>");
      res2 = res1.replace(/-/g, "<span class='add_minus_red'>-</span>");
      return res2;
    },
    // 跟单记录 数据查询
    getMessageSearchPageThreadFollowUpList () {
      MessageSearchPageThreadFollowUpList(this.queryParams).then(result => {
        this.totalNum = result.data.Total;
        this.threadFollowUpList = result.data.PageDataList;
        this.threadFollowUpList.forEach(o => {
          o.StudentKeyValue = o.StudentKeyValue ? o.StudentKeyValue : '-';
          o.MobilePhone = o.MobilePhone ? o.MobilePhone : '-';
          o.CurrentLabelNames = o.CurrentLabelNames ? o.CurrentLabelNames : '-';
          o.GenerateTime = o.GenerateTime ? o.GenerateTime : '-';
          o.LabelChange = o.LabelChange ? ((o.LabelChange.indexOf('+') != -1 && o.LabelChange.indexOf('-') != -1) ? this.searchAddOrMinusinfo(o.LabelChange) :
            o.LabelChange.indexOf('+') != -1 ? this.searchAddinfo(o.LabelChange) : (o.LabelChange != '-' && o.LabelChange.indexOf('-') != -1) ? this.searchMinusinfo(o.LabelChange) : '-') : '-';

          // 节点变化
          if (!o.FormerNodeKeyValue) {
            // 原节点为空时
            o.CurrentFormerNodeValue = o.CurrentNodeKeyValue;
          } else if (o.FormerNodeKey < o.CurrentNodeKey) {
            o.CurrentFormerNodeValue = o.FormerNodeKeyValue + '→' + o.CurrentNodeKeyValue;
          } else if (o.FormerNodeKey > o.CurrentNodeKey) {
            o.CurrentFormerNodeValue = '<div style="color:red;">' + o.CurrentNodeKeyValue + '←' + o.FormerNodeKeyValue + '</div>';
          } else {
            // 先节点和原节点相等 或者为空
            o.CurrentFormerNodeValue = '-';
          }
          o.FollowContentValue = o.FollowContent ? '<div class="recently_documentary_class">' + o.FollowContent + '</div>' : '';
        });
        console.log(result, '跟单记录 数据查询');
      }, error => {
        layer.alert(error.msg);
      });
    },
    changeSaveDate (dataInfo) {
      console.log(dataInfo, 'dataInfo');
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.queryParams.StartTime = dataInfo.startTime;
      this.queryParams.EndTime = dataInfo.endTime;
      this.getMessageSearchPageThreadFollowUpList();
    },
    search () {
      this.queryParams.pageIndex = 0;
      this.getMessageSearchPageThreadFollowUpList();
    },
    clearExtendSearchParams () {
      this.queryParams = {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        StartTime: '',
        EndTime: ''
      };
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.getMessageSearchPageThreadFollowUpList();
    }
  }
};
</script>

<style scoped>
.schedule_table_class >>> .el-table .recently_documentary_class {
  width: 99%;
  max-height: 84px;
  line-height: 54px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  right: 1px;
  z-index: 13;
  border: 1px solid transparent !important;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 40px;
  color: #333 !important;
}
.schedule_table_class >>> .el-table .recently_documentary_class::after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -11.5px;
  right: 20px;
  width: 21px;
  height: 21px;
  padding-right: 17px;
  z-index: 2;
  background: url(../../../../public/image/course_header.png) no-repeat 50%;
  background-size: 18px 12px;
}
.schedule_table_class >>> .el-table .add_minus_red {
  color: red;
  font-weight: 800;
}
</style>

