<template>
  <div class="up_sell_record_ul">
    <item v-for="(item,key) in recordList"
          :key="key"
          class="up_sell_record_list"
          :class="[recordList.length == 1?'no_line':'',key==0?'opt':'']"
          :keyIndex="recordList.length-key"
          :listLength="recordList.length"
          :item="item"></item>
    <div v-if="recordList.length==0">
      <div class="template_content_nodata_txt">暂无数据</div>
    </div>
  </div>
</template>
<script>
import item from './item';
export default {
  data () {
    return {

    };
  },
  props: {
    recordList: Array
  },
  components: {
    item
  },
  created () {

  }
};
</script>
