<template>
  <div>
    <div class="list_top">
      <div class="list_name">第
        <span>{{keyIndex}}</span>
        次跟进</div>
      <div v-if="item.PurposeKeyName"
           class="client_support_rank">
        <div class="sell_support_info_rank"
             :class="vPurposeClass">
          <span>
            {{item.PurposeKeyName}}
          </span>
        </div>
        <div class="client_support_rank_text"
             :class="vPurposeClass">
          {{item.PurposeName}}
        </div>
      </div>
      <div class="list_date">{{item.AgentKeyValue}} | {{vStartTimeFrom}}</div>
    </div>
    <div class="list_text">
      <span>{{item.FollowRecordsSupport}}</span>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object,
    keyIndex: Number,
    listLength: Number
  },
  computed: {
    vPurposeClass () {
      let className = '';
      if (this.item.PurposeKeyName == 'S') {
        className = 'purpose_black';
      } else if (this.item.PurposeKeyName == 'A') {
        className = 'purpose_brown';
      } else if (this.item.PurposeKeyName == 'B') {
        className = 'purpose_yellow';
      } else {
        className = 'purpose_gray';
      }
      return className;
    },
    vStartTimeFrom () {
      return this.item.StartTimeFrom.substring(0, 10);
    }
  }
};
</script>
