<template>
  <div>
    <div v-if="this.studentInfo.OLAPKey>0">
      <div class="form_info_edit">
        <input-dialog :formTitle="'补课课名'"
                      :formPlaceholder="''"
                      style="margin:0 20px"
                      :readonly="false"
                      :required="false"
                      @showEldilog="showCourseNameListDialog"
                      v-model="courseTableData.ClassSetKeyValue"></input-dialog>
        <calendar-choose ref="calendar"
                         :studentInfo="studentInfo"
                         :courseTableData="courseTableData"
                         @summit="summit"
                         @close="closeAfter"
                         @selectTimeRange="selectTimeRange"></calendar-choose>
      </div>
    </div>
  </div>
</template>
<script>
import calendarChoose from './calendar-choose';
import { getCourseTypeNameListX} from '../../../../API/workbench';
export default {
  data () {
    return {
      studentInfo: {},
      courseTableData: {
        DateList: [],    // 选中日期列表
        ClassSetKey: 0,
        ClassSetKeyValue: ''
      },
      courseNameList: []
    };
  },
  props: {
    dataInfo: Object
  },
  components: {
    calendarChoose
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
    if (this.dataInfo.StudentKey > 0) {
      this.studentInfo = { OLAPKey: this.dataInfo.StudentKey };
      this.courseTableData.ClassSetKey = this.dataInfo.OrderClassKey;
      this.courseTableData.ClassSetKeyValue = this.dataInfo.OrderClassKeyValue;
      console.log(this.courseTableData, 'mounted');
      this.$nextTick(() => {
        this.$refs.calendar.getTimeRangeCourseData(this.courseTableData);
      });
    }
    this.getCourseNameList();
  },
  methods: {
    selectTimeRange (item) {
      this.selectTimeInfo = item;
    },
    seletedCourseAfter (data) {
      // this.courseTableData = data;
      if (this.courseTableData.ClassSetKey != data.OLAPKey) {
        this.courseTableData.DateList = [];
      }
      this.courseTableData.ClassSetKey = data.OLAPKey;
      this.courseTableData.ClassSetKeyValue = data.MainDemoName;
      console.log(data, 'seletedCourseAfter', this.courseTableData);
      this.$refs.calendar.getTimeRangeCourseData(this.courseTableData);
    },
    getCourseNameList () {
      getCourseTypeNameListX('OLAPKey', 'desc').then(result => {
        this.courseNameList = result.data.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; });
      });
    },
    // 打开课程
    showCourseNameListDialog () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '选择课程',
        moduleName: 'singleSelectionList',
        data: { selectedKey: this.courseTableData.ClassSetKey, dataSourceList: this.courseNameList, required: false, addModuleName: 'course-name-setting' },
        callBack: {
          onSelectedItem: (data) => { this.seletedCourseAfter(data); }
        }
      });
    },
    // 取消
    closeAfter () {
      this.$emit('closeDialog');
    },
    // 提交
    summit (courseItem, callBack) {
      this.$emit('afterSuccess', courseItem);
    }
  }
};
</script>

