<template>
  <div class="courseware_textarea_box language_editor">
    <div class="textarea_title">
      <div>课评话术</div>

      <div class="btn_hover_bg_blue"
           @click="addContentList">添加</div>
    </div>
    <div v-if="deleteItme.essayID != 0"
         class="textarea_remove_btn"
         @click="deleteCourseEvaluation"></div>
    <div class="textarea_box paddingTop_20"
         v-for="(item,index) in contentList">
      <release-content ref="releaseContent"
                       :dataInfo="item"
                       :dataIndex="index"
                       :settingInfo="settingInfo"
                       :maxLength="4000"
                       @blurText="saveCourseEvaluation"
                       @focusFun="getFocus"></release-content>
    </div>
  </div>
</template>
<script>
import releaseContent from '../../../home-school-communication/common-component/release-content/index';
import {
  AddCourseEvaluation,
  CWSAddCourseEvaluation,
  WEBVAddCourseEvaluation,
  EditCourseEvaluation,
  CWSEditCourseEvaluation,
  WEVBEditCourseEvaluation,
  DeleteCourseEvaluation,
  CWSDeleteCourseEvaluation,
  WEBVDeleteCourseEvaluation
} from '../../../../API/workbench';
export default {
  data () {
    return {
      releaseData: {
        essayID: 0,
        Title: '', // 标题,
        Content: '', // 内容,
        AttachList: [] // 附件列表 { AttachTypeKey: 1, // 1-图片; 2-视频;3-音频  AttachUrl: '../../../../public/image/01.jpg'}
      },
      settingInfo: {
        placeholder: '请输入课评话术',
        showAddAudio: false,
        showAddPic: false,
        addVideoType: 0
      },
      deleteItme: {
        essayID: 0,
        Title: '', // 标题,
        Content: '', // 内容,
        AttachList: [] // 附件列表 { AttachTypeKey: 1, // 1-图片; 2-视频;3-音频  AttachUrl: '../../../../public/image/01.jpg'}
      },
      isSaveUpload: false,
      contentList: [],
      blurTimerOut: null
    };
  },
  components: {
    releaseContent
  },
  props: {
    dataInfo: Object,
    coursewareId: [String, Number]
  },
  computed: {

  },
  watch: {
  },
  created () {
    this.contentList = this.dataInfo.Evaluation;
    if (this.contentList.length == 0) {
      this.addContentList()
    }
    this.contentList.forEach(item => {
      item.essayID = item.OLAPKey || 0;
      item.Content = item.Content || '';
      item.AttachList = [];
    });


  },
  destroyed () { },
  methods: {
    addContentList () {
      this.contentList.push(JSON.parse(JSON.stringify(this.releaseData)));
    },
    deleteCourseEvaluation () {
      let deleteData = JSON.parse(JSON.stringify(this.deleteItme))
      console.log(' this.deleteItme.essayID', deleteData);
      layer.confirm('是否删除当前内容', {
        btn: [
          {
            name: '取消',
            callBack: () => {
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              layer.close();
              let apiFN = '';
              let type = this.$store.getters.SaaSClientInfo.EditionKey;
              if (type == 6) {
                apiFN = CWSDeleteCourseEvaluation;
              } else if (type == 5) {
                apiFN = DeleteCourseEvaluation;
              } else {
                apiFN = WEBVDeleteCourseEvaluation;
              }
              apiFN(deleteData.essayID).then(
                (res) => {
                  console.log(' this.deleteItme.essayID', deleteData.essayID);
                  let arr = JSON.parse(JSON.stringify(this.contentList));
                  this.contentList = [];
                  setTimeout(() => {
                    this.contentList = arr.filter(item => item.essayID != deleteData.essayID);
                    console.log(' this.contentList', this.contentList);
                    this.deleteItme.essayID = 0;
                  }, 50);
                  this.$emit('upCourseEvaluationInfo');
                },
                (err) => {
                  console.log(err.msg);
                }
              );
            }
          }
        ]
      });
    },
    getFocus (index) {

      console.log('聚焦', index);

      clearTimeout(this.blurTimerOut)
      this.deleteItme = this.contentList[index];
    },
    saveCourseEvaluation (text, id, index) {

      console.log('失焦', text, id, index);
      this.blurTimerOut = setTimeout(() => {
        this.deleteItme = {
          essayID: 0,
          Title: '', // 标题,
          Content: '', // 内容,
          AttachList: [] // 附件列表 { AttachTypeKey: 1, // 1-图片; 2-视频;3-音频  AttachUrl: '../../../../public/image/01.jpg'}
        }
      }, 1000)

      let releaseObj = this.contentList[index];
      console.log('失焦releaseObj', releaseObj);
      releaseObj.Content = text;
      releaseObj.essayID = id;
      if (releaseObj.Content.length > 4000) {
        releaseObj.Content = releaseObj.Content.substring(0, 4000);
      }
      if (releaseObj.Content.length == 0 || releaseObj.Content == '') {
        return false;
      }
      // if (this.isSaveUpload) {
      //   return false;
      // }
      console.log(releaseObj.Content.length, 'releaseObj.Content');
      if (!releaseObj.essayID) {
        let apiFN = '';
        let type = this.$store.getters.SaaSClientInfo.EditionKey;
        if (type == 6) {
          apiFN = CWSAddCourseEvaluation;
        } else if (type == 5) {
          apiFN = AddCourseEvaluation;
        } else {
          apiFN = WEBVAddCourseEvaluation;
        }
        apiFN(this.coursewareId, releaseObj.Content).then((res) => {
          releaseObj.essayID = res.data.CourseEvaluationID;
          this.$emit('upCourseEvaluationInfo', releaseObj);
        },
          (err) => {
            this.$emit('upCourseEvaluationInfo');
            layer.alert(err.msg);
          }
        );
      } else {
        console.log('releaseObj.essayID,', releaseObj.essayID);
        let apiFN = '';
        let type = this.$store.getters.SaaSClientInfo.EditionKey;
        if (type == 6) {
          apiFN = CWSEditCourseEvaluation;
        } else if (type == 5) {
          apiFN = EditCourseEvaluation;
        } else {
          apiFN = WEVBEditCourseEvaluation;
        }
        apiFN(
          releaseObj.essayID,
          releaseObj.Content
        ).then(
          (res) => {
            this.$emit('upCourseEvaluationInfo', releaseObj);
          },
          (err) => {
            this.$emit('upCourseEvaluationInfo');
            layer.alert(err.msg);
          }
        );
      }
    }
  }
};
</script>
<style scoped>
.language_editor >>> .publish_content_height {
  min-height: auto;
}

.language_editor >>> .homework_publish_accessory {
  padding-bottom: 0px;
}
.courseware_textarea_box {
  min-width: 550px;
  width: 45%;
  /* padding: 0 82px; */
  min-height: 820px;
  margin: 0 20px;
  padding: 0 !important;
}
.courseware_textarea_box .textarea_box {
  min-height: auto;
}
.textarea_remove_btn {
  top: 10px !important;
  right: 70px !important;
}
.textarea_title {
  padding-top: 0px !important;
  height: inherit !important;
  line-height: 33px !important;
}
</style>