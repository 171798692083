<template>
  <div class="swiper-slide">
    <div :class="{opt:packageItem.isActive,disable:packageItem.StatusKey == 3}"
         @click.stop="clickClassItemAction">
      <div :class="isPatriarchCourse?'data_title':'data_title_all'">
        <div class="fl">套餐</div>
        <!-- <span :title="packageItem.PackageName" class="student_label_patriarch "> -->
        {{packageItem.PackageName}}
        <!-- </span> -->
        <span class="taocan_arrange_open"
              v-show="visPatriarchCourseShow"
              @click.stop="showPatriarchPackage"></span>

      </div>
      <div class="data_text"
           style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
        <div v-if="packageItem.Range"
             slot="reference"
             :title="packageItem.Range">
          <span class="font_gray">适用：{{packageItem.Range=='全部'?'全部课程':packageItem.Range}}</span>
        </div>
        <div>
          <span class="font_gray">在读学生</span> {{$utils.mAmountType(packageItem.Total_Student||0,0)}}
          <span class="font_gray">人 |</span>
          <span class="font_gray">当前报课</span> {{$utils.mAmountType(packageItem.Sum_CurrentCount||0,1)}}
          <span class="font_gray">课时</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isPatriarchCourse: true
    };
  },
  props: {
    packageItem: Object
  },
  computed: {
    visPatriarchCourseShow () {
      return this.isPatriarchCourse && this.packageItem.PackageName.length >= 15;
    }
  },
  created () { },
  methods: {
    clickClassItemAction () {
      this.$set(this.packageItem, 'isActive', true);
      this.$emit('selectedPackageItem', this.packageItem);
    },
    showPatriarchPackage () {
      this.isPatriarchCourse = false;
    }
  }
};
</script>
<style>
.blackColor {
  background: #000 !important ;
}
</style>
<style>
</style>
