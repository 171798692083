<template>
  <div>
    <!-- 售前跟单 -->
    <div>
      <list ref="studentApplyCourseTable"
            :tableData="mendianQueryList"
            :pageTotal="pageTotal"
            :PageCount="PageCount"
            :tableColumns="tableColumns"
            :isSearchText="false"
            :rowKey="'Number'"
            :defaultSort="defaultSort"
            :queryParams="queryParams"
            :reportStatistics="mendianStatisticsInfo"
            @searchStatistics="searchStatistics"
            @searchValue="searchValue"
            @clearSearchParams="clearSearchCondition"
            @loadTableData="getMessageSearchPageForThreadMarket"
            @doAddCustomerClick="doAddCustomerClick"></list>
    </div>
    <!-- 添加客户信息 -->
    <custom-dialog :title="customerFormTltle"
                   width="640px"
                   :visible.sync="isShowAddCustomerFormPopup"
                   :before-close="closeAddCustomerFormPopup">
      <customer-thread :OLAPKey="threadInfo.OLAPKey"
                       :source="sourceInfo"
                       @closeFrom="getMessageSearchPageForThreadMarket"></customer-thread>
    </custom-dialog>

    <custom-dialog :title="'付款申请'"
                   :visible.sync="isShowPaymentRequestFormPopup"
                   :before-close="closePaymentRequestFormPopup">
      <payment-request :seletedItem="threadInfo"
                       @close="closePaymentRequestFormPopup"
                       @afterSuccess="getMessageSearchPageForThreadMarket"></payment-request>
    </custom-dialog>

    <custom-dialog :title="'临时开户'"
                   :visible.sync="isShowTemporaryAccountFormPopup"
                   :before-close="closeTemporaryAccountFormPopup">
      <temporary-account-opening :seletedItem="threadInfo"
                                 @close="closeTemporaryAccountFormPopup"
                                 @afterSuccess="getMessageSearchPageForThreadMarket"></temporary-account-opening>
    </custom-dialog>

    <custom-dialog :title="'清数据'"
                   :visible.sync="isShowCleanDataFormPopup"
                   :before-close="closeCleanDataFormPopup">
      <clean-data :seletedItem="threadInfo"
                  @close="closeCleanDataFormPopup"
                  @afterSuccess="getMessageSearchPageForThreadMarket"></clean-data>
    </custom-dialog>

    <custom-dialog :title="'跟进情况'"
                   :visible.sync="isShowUpSellRecordFormPopup"
                   :before-close="closeUpSellRecordFormPopup">
      <up-sell-record :dataInfo="threadInfo"
                      @close="closeUpSellRecordFormPopup"
                      @afterSuccess="getMessageSearchPageForThreadMarket"></up-sell-record>
    </custom-dialog>

    <custom-dialog title="全部记录"
                   :visible.sync="isShowRecord"
                   :before-close="closeisShowRecord">
      <recordList :recordList="recordDataList"></recordList>
    </custom-dialog>

    <custom-dialog title="导出"
                   :visible.sync="isShowExportPopup"
                   :before-close="closeExportPopup">
      <export-form :seletedItem="threadInfo"
                   @close="closeExportPopup"
                   @afterSuccess="getMessageSearchPageForThreadMarket"></export-form>
    </custom-dialog>

    <custom-dialog title="延期"
                   :visible.sync="isShowPostponePopup"
                   :before-close="closePostponePopup">
      <postpone-form :seletedItem="threadInfo"
                     @close="closePostponePopup"
                     @afterSuccess="getMessageSearchPageForThreadMarket"></postpone-form>
    </custom-dialog>
    <custom-dialog title="流量赠送"
                   :visible.sync="isShowFlowGivePopup"
                   :before-close="closeFlowGivePopup">
      <flowgive-form :seletedItem="threadInfo"
                     @close="closeFlowGivePopup"
                     @afterSuccess="getMessageSearchPageForThreadMarket"></flowgive-form>
    </custom-dialog>

  </div>
</template>
<script>
import {
  MessageSearchPageForThreadMarket, GetPreSalesReport, SelectFollowRecode, ExportXYKD
} from '../../../../API/workbench.js';
import list from './table';
import customerThread from '../components/form-components/customer-thread';
import temporaryAccountOpening from '../components/form-components/temporary-account-opening';
import upSellRecord from '../components/form-components/up-sell-record';
import recordList from '../components/form-components/up-sell-record-component/record-list';
import cleanData from '../components/form-components/clean-data';
import paymentRequest from '../components/form-components/payment-request';
import exportForm from '../components/form-components/export-form';
import postponeForm from '../components/form-components/postpone-form';
import flowgiveForm from '../components/form-components/flowgive-form';
export default {
  components: {
    list,
    customerThread,
    temporaryAccountOpening,
    upSellRecord,
    recordList,
    cleanData,
    paymentRequest,
    exportForm,
    postponeForm,
    flowgiveForm
  },
  props: {
    listType: String
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageIndex: 0, //	整型	必须		数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: 'desc', //	字符串	可选		数据源：GET	排序字段
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
        IntentionKey: '', //	整型	必须		数据源：GET	意向1-S;2-A;3-B;4-C;
        StatusKey: '', //	整型	必须		数据源：GET	开户状态0-未开通;1-临时开通;2-正式开通
        FollowStartTime: '', //	字符串	可选		数据源：GET	跟进日期开始
        FollowEndTime: '', //	字符串	可选		数据源：GET	跟进日期结束
        DemoStartTime: '', //	字符串	可选		数据源：GET	演示日期开始
        DemoEndTime: '', //	字符串	可选		数据源：GET	演示日期结束
        IsUseKey: '' //	字符串	可选		数据源：GET	是否在用 空-不生效;0-否;1-是
      },
      customerFormTltle: '添加客户信息',
      sourceInfo: {// 类型
        type: '',
        name: '',
        organizationName: '',
        organizationId: ''
      },
      pageTotal: 0, // 分页器总数
      mendianQueryList: [], // 门店查询列表
      mendianStatisticsInfo: {}, // 统计信息
      threadInfo: {}, // 线索信息
      isShowAddCustomerFormPopup: false, // 客户线索表单
      isShowTemporaryAccountFormPopup: false, // 临时开户表单
      isShowUpSellRecordFormPopup: false, // 跟进情况表单
      isShowRecord: false, // 记录表单
      isShowCleanDataFormPopup: false, // 清数据
      isShowPaymentRequestFormPopup: false, // 付款申请
      isShowExportPopup: false, // 导出
      isShowPostponePopup: false, // 延期
      isShowFlowGivePopup: false,  // 流量赠送
      recordDataList: [],
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          type: 'sell-documentary-table-row',
          className: 'font_gray',
          decimalPlace: 0,
          extend: {
            isClick: false
          }
        },
        {
          label: '意向',
          prop: 'IntentionKeyValue',
          width: 70,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'sell-documentary-table-row',
          extend: {
            isClick: false
          }
        },
        {
          label: '机构名',
          prop: 'SaaSClientNameKeyValue',
          width: 110,
          align: 'left',
          sortable: false,
          type: 'sell-documentary-table-row',
          extend: {
            isClick: true,
            click: (rowData) => {
              this.doOrganizationClick(rowData);
            }
          }
        },
        {
          label: '门店功能',
          prop: 'storeFunction',
          width: 80,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '门店类型',
          prop: 'SaaSClientCountText',
          width: 80,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '联系人/手机号',
          prop: 'ContactsName',
          width: 120,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '跟进情况描述',
          prop: 'FTime',
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            // isEdit: true,
            isClick: true,
            click: (rowData) => {
              this.doMarketFollowRecodeClick(rowData);
            }
          }
        },
        {
          label: '下次跟进',
          prop: 'FollowTime',
          width: 110,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: 'custom',
          extend: {
            isEdit: true,
            isClick: true,
            click: (rowData) => {
              this.doFollowTimeClick(rowData);
            }
          }
        },
        {
          label: '演示',
          prop: 'DemoTime',
          width: 120,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '来源方式',
          prop: 'SourceTypeKeyValue',
          width: 110,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '竞品',
          prop: 'CompetName',
          width: 180,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '开户',
          prop: 'DredgeTime',
          width: 160,
          align: 'left',
          sortable: 'custom',
          type: 'sell-documentary-table-row',
          extend: {
            isClick: false
          }
        },
        {
          label: '流量空间',
          prop: 'FluxCount',
          width: 120,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '操作',
          prop: 'option',
          width: 240,
          type: 'sell-documentary-table-btn',
          align: 'left',
          extend: {
            tableBtnGroup: [
              {
                extend: {
                  name: (rowData) => { // 付款中
                    return rowData.PayAuditKey > 0 ? '付款中' : '付款';
                  },
                  setClassName: (rowData) => {
                    return Number(rowData.PayAuditKey) > 0 ? 'font_green' : '';
                  },
                  click: (rowData) => {
                    this.operateBtnEvent('FK', rowData);
                  }
                }
              },
              {
                extend: {
                  name: (rowData) => { // 开户中
                    if (rowData.StatusKey == 0) {
                      return rowData.TempAuditKey > 0 ? '开户中' : '开户';
                    } else {
                      return '';
                    }
                  },
                  setClassName: (rowData) => {
                    return Number(rowData.TempAuditKey) > 0 ? 'font_green' : '';
                  },
                  click: (rowData) => {
                    this.operateBtnEvent('KH', rowData);
                  }
                }
              },
              {
                extend: {
                  name: (rowData) => { // 清数据  总部不支持操作
                    if (rowData.StatusKey != 0 && Number(rowData.SaaSClientCount) <= 1) {
                      return rowData.ClearAuditKey > 0 ? '清数据中' : '清数据';
                    } else {
                      return '';
                    }
                  },
                  setClassName: (rowData) => {
                    return Number(rowData.ClearAuditKey) > 0 ? 'font_green' : '';
                  },
                  click: (rowData) => {
                    this.operateBtnEvent('QSJ', rowData);
                  }
                }
              },
              {
                extend: {
                  name: (rowData) => { // 导出 总部不支持操作
                    if (rowData.StatusKey != 0 && Number(rowData.SaaSClientCount) <= 1) {
                      if (rowData.ExportExpirTime && rowData.ExportExpirTime >= this.$utils.formatDateToLine(Date.new()) && rowData.ExportAuditKey == 0) {
                        return '可导出';
                      } else if (!rowData.ExportExpirTime && rowData.ExportAuditKey > 0) {
                        return '导出中';
                      } else {
                        return '导出';
                      }
                    } else {
                      return '';
                    }
                  },
                  setClassName: (rowData) => {
                    return !rowData.ExportExpirTime && rowData.ExportAuditKey > 0 ? 'font_green' : '';
                  },
                  click: (rowData) => {
                    this.operateBtnEvent('DC', rowData);
                  }
                }
              },
              {
                extend: {
                  name: (rowData) => { // 延期
                    if (rowData.StatusKey > 0) {
                      return rowData.RenewalAuditKey > 0 ? '延期中' : '延期';
                    }
                  },
                  setClassName: (rowData) => {
                    return rowData.RenewalAuditKey > 0 ? 'font_green' : '';
                  },
                  click: (rowData) => {
                    this.operateBtnEvent('YQ', rowData);
                  }
                }
              },
              {
                extend: {
                  name: (rowData) => { // 流量赠送
                    if (rowData.PayAuditKey > 0 || rowData.TempAuditKey > 0) {
                      return Number(rowData.GiveFluxApplyKey) > 0 ? '流量赠送中' : '流量赠送';
                    } else {
                      return '';
                    }
                  },
                  setClassName: (rowData) => {
                    return Number(rowData.GiveFluxApplyKey) > 0 ? 'font_green' : '';
                  },
                  click: (rowData) => {
                    this.operateBtnEvent('LLZS', rowData);
                  }
                }
              }
            ]
          }
        }
      ],
      // 门店操作按钮
      PageCount: 0,
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  created () {

  },
  mounted () {
    this.getMessageSearchPageForThreadMarket();
  },
  methods: {
    // 获取售前跟单统计
    getPreSalesReport () {
      GetPreSalesReport().then(result => {
        console.log(result.data, '获取售前跟单统计');
        this.mendianStatisticsInfo = result.data;
      });
    },
    // 自用追销查询列表
    getMessageSearchPageForThreadMarket (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.queryParams.searchText = this.queryParams.searchText.trim();
      this.getPreSalesReport();// 统计
      MessageSearchPageForThreadMarket(this.queryParams).then(result => {
        console.log('自用追销查询列表', result.data);
        this.isShowAddCustomerFormPopup = false;
        this.pageTotal = result.data.Total;
        // 分页数
        if (result.data && result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach(o => {
            o.dredgeType = 0;// 售前
            o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
            o.SaaSClientCountText = Number(o.SaaSClientCount) > 1 ? Number(o.SaaSClientCount) : '单店';
          });
          this.mendianQueryList = result.data.PageDataList;
        } else {
          this.mendianQueryList = [];
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 添加客户线索
    doAddCustomerClick () {
      this.threadInfo.OLAPKey = '';
      this.sourceInfo = {
        type: '',
        name: '',
        organizationName: '',
        organizationId: ''
      };
      this.customerFormTltle = '添加客户信息';
      this.isShowAddCustomerFormPopup = true;
    },
    // 关闭线索弹窗
    closeAddCustomerFormPopup () {
      this.isShowAddCustomerFormPopup = false;
    },
    // 点击机构名操作 SourceTypeKey
    doOrganizationClick (rowData) {
      this.threadInfo = this.$utils.parseJson(rowData);
      console.log(rowData, '操作菜单');
      this.customerFormTltle = '修改客户信息';
      this.sourceInfo = {
        type: rowData.SourceTypeKey,
        name: rowData.SourceTypeKeyValue,
        organizationName: rowData.SaaSClientNameKeyValue,
        organizationId: rowData.SaaSClientNameKey
      };
      this.isShowAddCustomerFormPopup = true;
    },
    // 点击跟进情况描述操作
    doMarketFollowRecodeClick (rowData) {
      this.threadInfo = this.$utils.parseJson(rowData);
      SelectFollowRecode(this.threadInfo.OLAPKey).then(result => {
        this.recordDataList = result.data;
        console.log('SelectFollowRecode', result.data);
        this.isShowRecord = true;
      }, error => {
        layer.alert(error.msg);
      });
      console.log(rowData.Number, '操作菜单');
    },
    // 关闭跟进情况描述表单
    closeisShowRecord () {
      this.isShowRecord = false;
    },
    // 点击下次跟进操作
    doFollowTimeClick (rowData) {
      this.threadInfo = this.$utils.parseJson(rowData);
      this.isShowUpSellRecordFormPopup = true;
      console.log(rowData.Number, '操作菜单');
    },
    closeUpSellRecordFormPopup () {
      this.isShowUpSellRecordFormPopup = false;
    },
    // 操作菜单
    operateBtnEvent (type, rowData) {
      this.threadInfo = this.$utils.parseJson(rowData);
      console.log(type, rowData, '操作菜单');
      switch (type) {
        case 'FK':// 付款
          this.isShowPaymentRequestFormPopup = true;
          break;
        case 'KH':// 开户
          this.isShowTemporaryAccountFormPopup = true;
          break;
        case 'QSJ':// 清数据
          this.isShowCleanDataFormPopup = true;
          break;
        case 'DC':// 导出
          if (rowData.ExportExpirTime && rowData.ExportExpirTime >= this.$utils.formatDateToLine(Date.new()) && rowData.ExportAuditKey == 0) {
            this.doExportCustomerClick(this.threadInfo.OLAPKey);
          } else {
            this.isShowExportPopup = true;
          }
          break;
        case 'YQ':// 延期
          this.isShowPostponePopup = true;
          break;
        case 'LLZS':// 流量赠送
          this.isShowFlowGivePopup = true;
          break;
        default:
          break;
      }
    },
    // 导出
    doExportCustomerClick (OLAPKey) {
      ExportXYKD(OLAPKey).then(result => {
        console.log(result.data, '导出');
        window.location.href = this.$store.getters.APIURL + result.data.url;
        this.queryParams.searchText = '';
        this.queryParams.pageIndex = 0;
        this.getMessageSearchPageForThreadMarket();
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 关闭导出弹窗
    closeExportPopup () {
      this.isShowExportPopup = false;
    },
    closePostponePopup () {
      this.isShowPostponePopup = false;
    },
    closeFlowGivePopup () {
      this.isShowFlowGivePopup = false;
      this.getMessageSearchPageForThreadMarket();
    },
    // 关闭付款申请表单
    closePaymentRequestFormPopup () {
      this.isShowPaymentRequestFormPopup = false;
    },
    // 关闭临时开户表单
    closeTemporaryAccountFormPopup () {
      this.isShowTemporaryAccountFormPopup = false;
    },
    // 关闭清数据
    closeCleanDataFormPopup () {
      this.isShowCleanDataFormPopup = false;
    },
    // 搜索文本
    searchValue (searchText) {
      this.clearSearchCondition(true);
      this.queryParams.searchText = searchText;
      this.queryParams.pageIndex = 0;
      this.getMessageSearchPageForThreadMarket();
    },
    // 清除搜索条件
    clearSearchCondition (isUpdata) {
      this.queryParams = {
        pageIndex: 0, //		页码，0为第一页
        pageSize: this.queryParams.pageSize, //		每页记录数，0：表示查询所有
        orderBy: '', //			排序字段
        sequence: 'desc', // 排序字段
        searchText: '',   //	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
        IntentionKey: '', // 意向1-S;2-A;3-B;4-C;
        StatusKey: '',    //		开户状态0-未开通;1-临时开通;2-正式开通
        FollowStartTime: '', //	跟进日期开始
        FollowEndTime: '',  //		跟进日期结束
        DemoStartTime: '',  //		可选演示日期开始
        DemoEndTime: '',    //			演示日期结束
        IsUseKey: ''        // 是否在用 空-不生效;0-否;1-是
      };
      if (!isUpdata) {
        this.getMessageSearchPageForThreadMarket();
      }
    },
    // 搜索统计
    searchStatistics (type, value) {
      this.clearSearchCondition(true);
      switch (type) {
        case 'StatusKey':
          this.queryParams.StatusKey = value;
          break;
        case 'IsUseKey':
          this.queryParams.IsUseKey = value;
          break;
        case 'FollowStartTime':
          this.queryParams.FollowStartTime = value;
          this.queryParams.FollowEndTime = value;
          break;
        case 'FollowEndTime':
          this.queryParams.FollowStartTime = '';
          this.queryParams.FollowEndTime = value;
          break;
        case 'DemoStartTime':
          this.queryParams.DemoStartTime = value;
          this.queryParams.DemoEndTime = value;
          break;
        case 'DemoEndTime':
          this.queryParams.DemoStartTime = '';
          this.queryParams.DemoEndTime = value;
          break;
        case 'IntentionKey':
          this.queryParams.IntentionKey = value;
          break;
        default:
          break;
      }
      this.queryParams.pageIndex = 0;
      this.queryParams.searchText = '';
      this.getMessageSearchPageForThreadMarket();
    }
  },
  computed: {}
};
</script>
