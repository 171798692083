<template>
    <div class="class_detail_main">
        <detailHearderTeacher v-if="isTeacher" :isTeacher="isTeacher" :dataInfo="dataInfo" @updataData="updataData" @closeDialog="closeDialog"></detailHearderTeacher>
        <detailHearder v-else ref="detailHearder" :isTeacher="isTeacher" :dataInfo="dataInfo" @updataData="updataData"></detailHearder>
        <detailContent ref="detailContent" :isTeacher="isTeacher" :dataInfo="dataInfo" :DeviceCode="DeviceCode" @updataData="updataData"></detailContent>
    </div>
</template>
<script>
import detailContent from './detail-content'
import {CWSGetClassGradeDetails,PrincipalGetClassGradeDetails} from '../../../../API/workbench'
import detailHearderTeacher from './detail-hearder-teacher'
import detailHearder from './detail-hearder'
export default {
    data(){
        return{
            dataInfo:{
                OLAPKey:'',
                MainDemoName:'',
                BJStudentList:[],
                BJTeacherList:[]
            },
            DeviceCode:'340BC40DC769411B'
        }
    },
    props:{
        classKey:[Number,String], //班级ID
        isTeacher:{
            type:Boolean,
            default:false
        }
    },
    components:{
        detailHearderTeacher,detailContent,detailHearder
    },
    created(){
        console.log("classKey",this.classKey)
        if(this.classKey>0){
            this.getDataInfo()
        }
    },
    mounted(){
        this.$dialog.register(this, ['cwsChooseCourseware','selectTeacher','singleSelectionList','cwsGoToClass','cwsApplyAuth','classStatistics','courseAuthQrcode']);
    },
    methods:{
        // 老师退出班级关闭页面
        closeDialog(){
            this.$emit("afterSuccess")
            this.$emit("closeDialog")
        },
        updataData(){
            this.$emit("afterSuccess")
            this.getDataInfo()
        },
        getDataInfo(){
            if(this.isTeacher){
                CWSGetClassGradeDetails(this.classKey).then(result=>{
                    this.initData(result)
                }).catch(error=>{
                    console.log("error",error.msg)
                })
            }else{
                PrincipalGetClassGradeDetails(this.classKey).then(result=>{
                    this.initData(result)
                    if(this.$refs.detailHearder){
                        this.$refs.detailHearder.getDateRangeCoursewareStat('','',this.classKey)
                    }
                }).catch(error=>{
                    console.log("error",error.msg)
                })
            }
            
        },
        initData(result){
            this.dataInfo=result.data
                this.dataInfo.BJStudentList.forEach(o=>{
                    this.$set(o,'MainDemoName',o.StudentKeyValue)
                    this.$set(o,'OLAPKey',o.StudentKey)
                })
                  this.dataInfo.BJTeacherList.forEach(o=>{
                    this.$set(o,'MainDemoName',o.TeacherKeyValue)
                    this.$set(o,'OLAPKey',o.TeacherKey)
                })
                this.DeviceCode=''
                if (this.$zxClient&&this.$zxClient.IsClient) {
                    this.DeviceCode = this.$zxClient.GetMCode();
                } else {
                    this.tipContont = '非桌面端';
                    this.DeviceCode=''
                }
                console.log("getDataInfo",result.data,this.DeviceCode)

                this.$refs.detailContent.getDataList(this.classKey,this.DeviceCode)
        }
    }
}
</script>
<style>
.class_detail_main{
    width: 100%;
    max-height: 800px;
    overflow: scroll;
    /* padding: 20px; */
}
</style>