<template>
  <div class="sales_form_left_type">
    <!-- 学生档案-销售跟进  -->
    <div class="pr">
      <div class="form_info_box">
        <follow-form-dialog :formTitle="'感兴趣的课'"
                            v-model="threadInfo.FineSupport"
                            :styleName="'padding:0'"
                            @showEldilog="openSelectedRecordPage(1)"></follow-form-dialog>

        <follow-form-dialog :formTitle="'到店参观'"
                            :storeName="vStoreResult"
                            :storeClassName="threadInfo.ToStoreResultKey <= 1?'type_green':'type_red'"
                            :value="threadInfo.AppointTime?threadInfo.AppointTime.slice(0, 10):''"
                            @showEldilog="openSelectedRecordPage(2)"></follow-form-dialog>

        <follow-form-dialog :formTitle="'预约试课'"
                            :storeName="vTrialClassResult"
                            :storeClassName="threadInfo.TrialClassResultKey <= 1?'type_green':'type_red'"
                            :formHint="vformHint"
                            :styleName="'margin-top: -24rpx;'"
                            v-model="threadInfo.CourseNameKeyValue"
                            @showEldilog="openSelectedRecordPage(3)"></follow-form-dialog>

      </div>
    </div>
    <div class="form_header_gray">跟进记录</div>
    <div v-if="recordList.length>0">
      <everyday-order-item v-for="(item,key) in vRecordList"
                           :key="key"
                           :item="item"></everyday-order-item>
      <div class="no_list_text">已显示全部</div>
    </div>
    <basics-nodata-tips v-else></basics-nodata-tips>
  </div>
</template>

<script>
import followFormDialog from './follow-form-dialog';
import everydayOrderItem from './everyday-order-item';
import { GetCRMThreadForStudentProfile, SearchFollowUpRecordByStudentKey } from '../../../../../API/workbench';
export default {
  components: {
    followFormDialog,
    everydayOrderItem
  },
  data () {
    return {
      item: {
        MainDemoName: '',
        SexKeyValue: '',
        MobileSupport: ''
      },
      isReadonly: false,
      threadInfo: {},
      recordList: []
    };
  },
  props: {
    studentInfo: Object,
    showType: [String, Number]
  },
  created () {

  },
  watch: {
    'studentInfo.upDataTime': {
      handler (newTime, oldTime) {
        console.log(this.studentInfo, '学生信息-watch');
        if (newTime && this.studentInfo.OLAPKey) {
          this.getCRMThreadForStudentProfile(this.studentInfo.OLAPKey);
          this.getSearchFollowUpRecordByStudentKey(this.studentInfo.OLAPKey);
        }
      },
      immediate: true
    }
  },
  computed: {
    vformHint () {
      if (this.threadInfo.OLAPKey && this.threadInfo.ClassTime) {
        let date = this.threadInfo.ClassTime ? this.threadInfo.ClassTime.slice(5, 16) : '';
        return date + ' ' + this.threadInfo.GradeClassKeyValue + '-' + this.threadInfo.MTeacherKeyValue;
      } else {
        return '';
      }
    },
    // 预约到店结果 0-预约;1-已到店;2-未到店;3-已取消
    vStoreResult () {
      if (this.threadInfo.OLAPKey) {
        if (this.threadInfo.ToStoreResultKey == 0) {
          return '';
        } else if (this.threadInfo.ToStoreResultKey == 1) {
          return '已到店';
        } else if (this.threadInfo.ToStoreResultKey == 2) {
          return '未到店';
        } else if (this.threadInfo.ToStoreResultKey == 3) {
          return '已取消';
        } else {
          return '';
        }
      }
    },
    // 预约试课结果 0-预约;1-已试课;2-未试课;3-已取消
    vTrialClassResult () {
      if (this.threadInfo.OLAPKey) {
        if (this.threadInfo.TrialClassResultKey == 0) {
          return '';
        } else if (this.threadInfo.TrialClassResultKey == 1) {
          return '已试课';
        } else if (this.threadInfo.TrialClassResultKey == 2) {
          return '未试课';
        } else if (this.threadInfo.TrialClassResultKey == 3) {
          return '已取消';
        } else {
          return '';
        }
      }
    },
    vRecordList () {
      let list = [];
      list = this.recordList.filter((o, index) => {
        if (index > 0) { // 处理-除了第一条后面的记录不显示高亮
          o.Number = index;
        }
        return Number(o.OLAPKey) > 0;
      });
      return list;
    }
  },
  methods: {
    // 线索信息
    getCRMThreadForStudentProfile (studentKey) {
      GetCRMThreadForStudentProfile(studentKey).then(result => {
        console.log(result.data, '线索信息');
        this.threadInfo = result.data;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 跟进记录
    getSearchFollowUpRecordByStudentKey (studentKey) {
      SearchFollowUpRecordByStudentKey(studentKey).then(result => {
        console.log(result.data, '跟进记录');
        this.recordList = result.data;
      }, error => {
        layer.alert(error.msg);
      });
    },
    seletedOption () {
      this.$emit('seletedOption', this.item);
    },
    // 进入跟进记录并且自动选中该项
    openSelectedRecordPage (type) {
      // var pathUrl = '/pages/teacher-follow-form/index';
      // this.$zxrouter.navigateTo({
      //   url: pathUrl + '?studentKey=' + this.studentInfo.OLAPKey + '&type=' + type
      // });
    }
  }
};
</script>

<style>
</style>
