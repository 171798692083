<template>
  <div class="margin_left_right_div">
    <div class="template_minOrmax_width_div">
      <div class="chain_mendian_box">
        <header-report :reportInfo="headerReportInfo"></header-report>
        <content-chart></content-chart>
        <footer-report></footer-report>
        <dialog-factory ref="moreMendianReport"
                        :routerName="'moreMendianReport'"></dialog-factory>
      </div>
    </div>
  </div>
</template>
<script>
import { SaaSClientBaseReport } from '../../../API/workbench';
import dialogFactory from '../../common/dialog-factory';
import headerReport from './header-report';
import contentChart from './content-chart';
import footerReport from './footer-report';

export default {
  name: 'moreMendianReport',
  data () {
    return {
      headerReportInfo: {}
    };
  },
  components: {
    headerReport, contentChart, footerReport, dialogFactory
  },
  created () {
    this.getSaaSClientBaseReport();
  },
  methods: {
    getSaaSClientBaseReport () {
      SaaSClientBaseReport().then(result => {
        this.headerReportInfo = result.data;
        console.log('getSaaSClientBaseReport', result.data);
      }).catch(error => {
        console.log(error, 'error');
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.moreMendianReport) {
        this.$refs.moreMendianReport.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    }
  }
};
</script>

