<template>
  <div class="margin_left_right_div">
    <div class="template_minOrmax_width_div">
      <div class="WKT_index_view"
           style="width: auto;">
        <div class="WKT_index_top box_shadow_border_div">
          <div class="WKT_index_top_list">
            <div class="list_title">关注人数</div>
            <div class="list_text">
              <span class="pr">
                <span>{{Number(wktReport.TotalSubscribeNum||0)}}</span>
                <div class="list_text_pa">
                  <span class="font_gray">本月</span>
                  <span v-if="Number(wktReport.ThisMonthSubscribeNum||0)>0">+</span>{{Number(wktReport.ThisMonthSubscribeNum||0)}}
                </div>
              </span>
            </div>
          </div>
          <div class="WKT_index_top_list">
            <div class="list_title">文章浏览</div>
            <div class="list_text">
              <span class="pr">
                <span>{{Number(wktReport.TotalBrowseNum||0)}}</span>
                <div class="list_text_pa">
                  <span class="font_gray">本月</span>
                  <span v-if="Number(wktReport.ThisMonthBrowseNum||0)>0">+</span>{{Number(wktReport.ThisMonthBrowseNum||0)}}
                </div>
              </span>
            </div>
          </div>
          <div class="WKT_index_top_list">
            <div class="list_title">注册人数</div>
            <div class="list_text">
              <span class="pr">
                <span>{{Number(wktReport.TotalRegisterNum||0)}}</span>
                <div class="list_text_pa">
                  <span class="font_gray">本月</span>
                  <span v-if="Number(wktReport.ThisMonthRegisterNum||0)>0">+</span>{{Number(wktReport.ThisMonthRegisterNum||0)}}
                </div>
              </span>
            </div>
          </div>
          <!-- <div class="WKT_index_top_list">
        <div class="list_title">本月点评(人)</div>
        <div class="list_text">
          <span class="pr">
            <span>1605</span>
            <div class="list_text_pa">
              <span class="font_gray">本月</span> +100</div>
          </span>
        </div>
      </div> -->
        </div>
        <div class="WKT_index_content flex">
          <div class="WKT_index_left box_shadow_border_div">
            <div class="WKT_index_left_title">
              微网校
            </div>
            <div class="WKT_index_menu_ul">
              <!-- <a :href="'#'+menu.type"> -->
              <div class="WKT_index_menu_list"
                   v-for="menu in menuList"
                   :key="menu.type"
                   :class="{opt:menu.isActive}"
                   @click.stop="clickMenuEvent(menu)">
                {{menu.title}}
              </div>
            </div>
          </div>
          <div class="WKT_index_right box_shadow_border_div">
            <!-- 手机预览 -->
            <phone-preview ref="preViewPhone"
                           :curSelectionMenuItem="curSelectionMenuItem"
                           :corporateImageData="corporateImageData"
                           :mendianInfo="mendianInfo"
                           :hotRecommendationList="hotRecommendationList"
                           @onClickModuleEvent="clickModule"></phone-preview>
            <!-- 编辑 -->
            <component :is="curSelectionMenuItem.type"
                       :dataInfo="curSelectionMenuItem.data"
                       :hotRecommendationList="hotRecommendationList"
                       @updateCorporateImage="updateCorporateImage"
                       @updateCorporateData="getCorporateImage"
                       @updateHotRecommendation="getHotRecommendation"></component>

          </div>
        </div>
      </div>
      <div>
        <dialog-factory ref="wktBackDialogFactory"
                        :key="'wktBack'"
                        :routerName="'wktBack'"></dialog-factory>
      </div>
    </div>
  </div>
</template>

<script>
import corporateImage from './corporate-image/index';
import phonePreview from './phone-preview/index';
import organizationIntroduction from './organization-introduction/index';
import hotActivity from './hot-activity/index';
import coupon from './coupon/index';
import discountPackage from './discount-package/index';
import excellentTeacher from './excellent-teacher/index';
import starStudents from './star-students/index';
import hotRecommendation from './hot-recommendation/index';
import contactUs from './contact-us/index';
import courseName from './course-name/index';
import dialogFactory from '../common/dialog-factory';
import { GetCorporateImage, GetOrganizationInfo, GetOrganizationReport, SearchPopularRecommend } from '../../API/workbench';
export default {
  name: 'wktBack',
  components: {
    phonePreview,
    corporateImage,
    organizationIntroduction,
    hotActivity,
    coupon,
    discountPackage,
    excellentTeacher,
    starStudents,
    hotRecommendation,
    contactUs,
    courseName,
    dialogFactory
  },

  data () {
    return {
      menuList: [
        { title: '图片轮播', logo: '', isActive: true, type: 'corporate-image', data: {} },
        { title: '机构简介', logo: '', isActive: false, type: 'organization-introduction', data: {} },
        { title: '热门活动', logo: '', isActive: false, type: 'hot-activity' },
        { title: '课程', logo: '', isActive: false, type: 'course-name' },
        { title: '优惠券', logo: '', isActive: false, type: 'coupon' },
        { title: '优惠套餐', rightButName: '历史套餐', logo: '', isActive: false, type: 'discount-package' },
        { title: '优秀教师', logo: '', isActive: false, type: 'excellent-teacher' },
        { title: '明星学员', logo: '', isActive: false, type: 'star-students' },
        { title: '热门推荐', logo: '', isActive: false, type: 'hot-recommendation' },
        { title: '联系我们', logo: '', isActive: false, type: 'contact-us', data: {} }

      ],
      curSelectionMenuItem: null, // 当前选中的菜单项
      corporateImageData: { BrandImgSupport: '', CorporateImageList: [] }, // 图片轮播列表
      mendianInfo: {}, // 门店信息
      wktReport: {}, // 统计数据
      hotRecommendationList: [], // 热门推荐
      hotRecommendationParams: {
        pageIndex: 0,
        pageSize: 0,
        orderBy: '',
        sequence: '',
        searchText: ''
      }
    };
  },
  created () {
    this.curSelectionMenuItem = this.menuList[0];
    // this.getCorporateImage();  // 获取轮播图片)
    this.getOrganizationInfo();    // 获取kwt门店信息
    this.getWktReport();
    this.getHotRecommendation();// 获取热门推荐
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'wktBack') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
  },
  computed: {
    vcorporateImageList () {
      let newArr = [];
      console.log(this.corporateImageData, 'vcorporateImageList111');
      newArr = this.$utils.parseJson(this.corporateImageData.CorporateImageList || []);
      if (this.corporateImageData.BrandImgSupport) {
        let obj = {
          imgUrl: this.corporateImageData.BrandImgSupport
        };
        newArr.unshift(obj);
      }
      console.log(newArr, 'vcorporateImageList222');
      return newArr;
    }
  },
  mounted () {
    this.getCorporateImage();  // 获取轮播图片)
  },
  methods: {
    // 获取轮播图片
    getCorporateImage (callBack) {
      GetCorporateImage().then(result => {
        this.corporateImageData = result.data;
        if (callBack) {
          callBack();
        } else {
          this.curSelectionMenuItem.data = this.corporateImageData;
          this.$nextTick(() => {
            if (this.vcorporateImageList.length > 1) {
              if (this.$refs.preViewPhone.swiper) {
                console.log('updateSlides');
                this.$refs.preViewPhone.swiper.destroy(false);
                this.$refs.preViewPhone.swiper = null;
                this.$refs.preViewPhone.swiperInit();
                // this.$refs.preViewPhone.swiper.slideTo(0);
                // this.$refs.preViewPhone.swiper.updateSlides();
              } else {
                this.$refs.preViewPhone.swiperInit();
              }
            }
          });
        }
      });
    },
    // 轮播图上传更新图片.
    updateCorporateImage (url) {
      this.corporateImageData.BrandImgSupport = url;
      console.log('this.vcorporateImageList', this.vcorporateImageList);
      this.getCorporateImage();
    },
    // 获取kwt门店信息
    getOrganizationInfo (callBack) {
      GetOrganizationInfo().then(result => {
        this.mendianInfo = result.data;
        if (callBack) {
          callBack();
        }
      });
    },
    // 获取门店基本统计
    getWktReport () {
      GetOrganizationReport().then(result => {
        this.wktReport = result.data;
      }).catch(error => {
        console.log('GetOrganizationReportErr', error);
      });
    },
    // 获取热门推荐
    getHotRecommendation (callBack) {
      SearchPopularRecommend(this.hotRecommendationParams).then(result => {
        this.hotRecommendationList = result.data.PageDataList;
        if (callBack) {
          callBack();
        }
      }, error => {
        console.log('getHotRecommendation', error);
      });
    },
    // 点击菜单
    clickMenuEvent (item) {
      item.isActive = true;
      if (this.curSelectionMenuItem && this.curSelectionMenuItem.type != item.type) {
        this.curSelectionMenuItem.isActive = false;
      }
      this.curSelectionMenuItem = item;
      if (this.curSelectionMenuItem.type == 'corporate-image') {
        this.getCorporateImage(() => {
          this.curSelectionMenuItem.data = this.corporateImageData;
        });
      } else if (this.curSelectionMenuItem.type == 'organization-introduction' || this.curSelectionMenuItem.type == 'contact-us') {
        this.getOrganizationInfo(() => {
          this.curSelectionMenuItem.data = this.mendianInfo;
        });
      } else if (this.curSelectionMenuItem.type == 'hot-recommendation') {
        this.getHotRecommendation(() => {
          this.curSelectionMenuItem.data = this.hotRecommendationList;
        });
      }
    },
    // 点击模块
    clickModule (moduleName) {
      let selectionModule = this.menuList.find(obj => { return obj.type == moduleName; });
      if (selectionModule) {
        this.clickMenuEvent(selectionModule);
      }
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.wktBackDialogFactory) {
        this.$refs.wktBackDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    }
  }
};
</script>

<style>
</style>