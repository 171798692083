<template>
  <div>
    <div class="single_result_detail ">
      <!-- 学生个人信息 -->
      <div>
        <student-lable class="content_marginRL single_shadow"
                       :studentID="newInitJson.OLAPKey"
                       :mobileSupport="newInitJson.MobileSupport"
                       :CustStatusKey="1"
                       @changeStudent="changeStudent" 
                       @alterStudent="alterStudent">
        </student-lable>
      </div>
      <!-- 已报班级 -->
      <div class="single_shadow" style="overflow:hidden">
        <div class="single_result_list content_marginRL " style="max-height: 488px;overflow-y: auto;">
          <!-- 单个班级信息 -->
          <div class="result_list_item "
               v-for="(item,index) in getStudentClassList"
               :key="index">
            <div class="list_item_info">
              <div class="item_info_line">
                <span class="">已报班级 : </span>
                <span class="item_info_line_text">{{item.MainDemoName}}</span>
              </div>
              <div class="item_info_line">
                <span class="">报班学生 : </span>
                <span>{{Number(item.CurrentPeoCount)}}</span>
              </div>
            </div>
            <div class="list_item_option">
              <div>
                <el-button round
                           class="btn_light_blue btn_hover_bg_blue"
                           @click.stop="changeClass(item)">换班</el-button>
              </div>
              <div>
                <el-button round
                           class="btn_border_blue btn_hover_blue"
                           @click.stop="returnClass(item)">退班</el-button>
              </div>
            </div>
          </div>
          <div v-if="getStudentClassList.length==0">
            <div class="monitoring_content_nodata">
              <div class="monitoring_content_nodata_txt">该学生暂无班级</div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
// 学生换班
import studentLable from '../../../../components/common/student-lable';
export default {
  name: 'changeClassItem',
  props: ['isSelectedStudentItem', 'getStudentClassList'], // 需绑定到上面
  data () {
    return {
      newInitJson: this.isSelectedStudentItem // 选中学生信息
    };
  },
  components: {
    studentLable
  },
  created () {

  },
  mounted () {
    this.$dialog.register(this, ['quitOrEnterClassForm']);
  },
  methods: {
    showStudentFile () {        // 显示学员档案
      this.$emit('showStudentFile');
    },
    // 学生换班
    changeClass (item) {
      this.$emit('changeClass', item);
    },
    alterStudent (item) {
      this.newInitJson.OLAPKey = item.OLAPKey;
      this.$emit('alterStudent', item);
    },
    // 学生退班
    returnClass (item) {
      this.$dialog.open(this, {
        moduleName: 'quitOrEnterClassForm',
        name: '学生退班',
        routerName: this.$route.name,
        routertitle: '',
        data: { dataInfo: { studentID: this.isSelectedStudentItem.OLAPKey, classNameID: item.OLAPKey, classTitle: '退班' } },
        callBack: { afterSucces: () => {
          this.$emit('returnClass');
        } }
      });
    },
    changeStudent () {          // 变更学生
      this.$emit('changeStudent');
    }
  }
};
</script>