<template>
  <div class="payment_statistics_bottom "
       style="flex:1;">
    <div class="course_statistics_table_search flex course_statistics_title"
         style="flex-direction: column;padding-bottom:0px">
      <div class="statistics_list_right"
           style="margin-top: 15px;">
        <span class="statistics_item_content noborder font_gray">设置学管师: </span>
        <span class="statistics_item_content bg_blue"
              @click="addTeacher">+ 添加学管师</span>
        <span class="statistics_teacher_content">
          <span class="statistics_item_content font_black button"
                style="margin-bottom:15px"
                :class="item.ishover?'opt':''"
                @mouseenter="showDel(item)"
                @mouseleave="hiddenDel(item)"
                v-for="(item,key) in teacherList"
                :key="key"
                :title="item.MainDemoName+'/'+Number(item.StudentCount)">
            <span class="statistics_item_content_text">{{item.MainDemoName}}/{{Number(item.StudentCount)}}</span>
            <span class="del_red"
                  v-if="item.ishover"
                  @click.stop="delTeacher"></span>
          </span>
        </span>

      </div>
      <div class="statistics_list_right">
        <span class="statistics_item_content noborder font_gray">分配学生: </span>
        <span class="statistics_item_content bg_blue"
              @click.stop="toStudentCourseList(0,-1)">+ 分配学生</span>
        <span class="statistics_item_content font_black">全部/{{Number(studentCourseInfo.StudentCount)}} </span>
        <span class="statistics_item_content font_black">已分配/{{Number(studentCourseInfo.HaveCount)}} </span>
        <span class="statistics_item_content font_black">未分配/{{Number(studentCourseInfo.NotHaveCount)}} </span>
        <span class="statistics_item_content font_black"
              :title="'人均分配/'+Number(studentCourseInfo.averageCount)">人均分配/{{Number(studentCourseInfo.averageCount)}} </span>
      </div>
      <div class="statistics_list_right">
        <span class="statistics_item_content noborder font_gray">预警事项: </span>
        <span style="display:flex; justify-content: space-between;width: 100%;">
          <span style="display:flex">
            <span style="margin-right: 50px;margin-top: 5px;">
              <span class=" "
                    style="margin-right: 20px;">续费</span>
              <span class="statistics_item statistics_item_yellow"
                    @click.stop="toStudentCourseList(1)">续费预警:{{Number(warningInfo.RenewalWarningCount)}} </span>
              <span class="statistics_item statistics_item_red"
                    @click.stop="toStudentCourseList(2)">失效未续:{{Number(warningInfo.NotRenewalCount)}} </span>
            </span>
            <span style="margin-top: 5px;">
              <span class=" "
                    style="margin-right: 20px;">出勤</span>
              <span class="statistics_item statistics_item_yellow"
                    @click.stop="toStudentCourseList(3)">出勤率预警:{{Number(warningInfo.AttendanceWarningCount)}} </span>
              <span class="statistics_item statistics_item_yellow"
                    @click.stop="toStudentCourseList(5)">长期未到校预警:{{Number(warningInfo.CampusWarningCount)}} </span>
            </span>
          </span>

          <span class="warning_right"
                style="margin-top: 5px;margin-right: 25px;">
            <span class=" "
                  @click.stop="renewWarningSetBtn">续费预警 <span class="font_blue button"
                    style="margin-left: 10px;">设置</span> </span>
            <span class=" "
                  style="margin-left:20px"
                  @click.stop="attendanceWarningSetBtn">出勤预警 <span class="font_blue button"
                    style="margin-left: 10px;">设置</span> </span>

          </span>
        </span>

      </div>
    </div>

    <div class="general_summarizing_student_title">
      <div class="general_summarizing_student_nav">
        <menu-item v-for="item in menuList"
                   :key="item.type"
                   :item="item"
                   @seletedItem="seletedMenuAfter"></menu-item>
      </div>
    </div>
    <div style="width:99%">
      <data-total-list v-if="indexType==0"
                       :teacherList="teacherList"></data-total-list>
      <student-course-list ref="studentCourseList"
                           v-if="indexType==1"
                           :teacherList="teacherList"></student-course-list>
      <renew-cost-follow v-if="indexType==2"
                         :teacherList="teacherList"></renew-cost-follow>
      <renew-record-list v-if="indexType==3"
                         :teacherList="teacherList"></renew-record-list>
      <no-renew-list v-if="indexType==4"
                     :teacherList="teacherList"></no-renew-list>
      <arrange-course-list v-if="indexType==5"
                           :teacherList="teacherList"></arrange-course-list>
      <atten-detail-list v-if="indexType==6"
                         :teacherList="teacherList"></atten-detail-list>
    </div>
    <custom-dialog :title="'新增学管师'"
                   :visible.sync="showAddForm"
                   :before-close="closeDialog">
      <add-teacher @afterSuccess="updateTeacherList"
                   @cancelClick="closeDialog"></add-teacher>
    </custom-dialog>
    <custom-dialog title="续费预警设置"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowSetting"
                   :before-close="closeSettingDialog">
      <setting-warning-form @onConfirmClick="doAfterConfirm"
                            @cancelClick="closeSettingDialog"></setting-warning-form>
    </custom-dialog>
    <custom-dialog title="出勤预警设置"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowAttendanceSetting"
                   :before-close="closeSettingDialog">
      <attendance-warning-form @onConfirmClick="doAfterConfirm"
                               @cancelClick="closeSettingDialog"></attendance-warning-form>
    </custom-dialog>
  </div>
</template>
<script>
// import dateOption from '../../class-spend-statistics-analyze/components/date-option';
import studentCourseList from './list-components/student-course-list';
import dataTotalList from './list-components/data-total-list';
import renewCostFollow from './list-components/renew-cost-follow';
import renewRecordList from './list-components/renew-record-list';
import noRenewList from './list-components/no-renew-list';
import arrangeCourseList from './list-components/arrange-course-list';
import attenDetailList from './list-components/atten-detail-list';
import addTeacher from './list-components/add-teacher';
import menuItem from '../class-spend-statistics-analyze/components/menu-item';
import settingWarningForm from '../../intelligences-warning/renew-fee-warning/setting-warning-form';
import attendanceWarningForm from './attendance-warning-form';
import { GetCourseStudentList, GetRenewalWarningCount, GetActiveStudentCount, DelTheacherAdiser } from '../../../API/workbench';
export default {
  data () {
    return {
      isShowSetting: false, // 设置预警线.
      isShowAttendanceSetting: false, // 设置出勤预警线.
      showAddForm: false,
      menuList: [
        {
          type: 0,
          name: '数据汇总',
          isActive: true
        },
        {
          type: 1,
          name: '在校学生',
          isActive: false
        },
        {
          type: 2,
          name: '续费跟进',
          isActive: false
        },
        {
          type: 3,
          name: '已续记录',
          isActive: false
        },
        {
          type: 4,
          name: '不续记录',
          isActive: false
        },
        {
          type: 5,
          name: '排课考勤',
          isActive: false
        },
        {
          type: 6,
          name: '考勤明细',
          isActive: false
        }
      ],
      studentCourseInfo: {
        StudentCount: '', //	整型	人均分配正校生数
        NotHaveCount: '', //	整型	未分配正校生
        HaveCount: '', ///	整型	已分配正校生
        averageCount: 0
      },
      warningInfo: {
        RenewalWarningCount: 0, // 续费预警
        NotRenewalCount: 0, // 失效未续
        AttendanceWarningCount: 0, // 预警出勤率数
        CampusWarningCount: 0, // 长期未到校预警数
        StuentNotOneCount: 0 // 排课不足预警
      },
      indexType: 0,
      sletedTeacher: {}

    };
  },
  components: {
    menuItem,
    studentCourseList,
    renewCostFollow,
    renewRecordList,
    noRenewList,
    arrangeCourseList,
    attenDetailList,
    dataTotalList,
    addTeacher,
    settingWarningForm,
    attendanceWarningForm
  },
  props: {
    seletedItem: {
      type: Object,
      default: () => {
        return {
          OLAPKey: 0,
          MainDemoName: '趁',
          StudentCount: 50
        };
      }
    },
    teacherList: Array
  },

  created () {
  },
  computed: {

  },
  mounted () {
    this.getDataInfo();
  },
  watch: {

  },
  methods: {
    showDel (item) {
      this.sletedTeacher = item
      this.teacherList.forEach(o => {
        if (o.OLAPKey == item.OLAPKey) {
          o.ishover = true
        } else {
          o.ishover = false
        }
      })
    },
    hiddenDel (item) {
      // this.sletedTeacher=item
      this.teacherList.forEach(o => {
        o.ishover = false
      })
    },
    delTeacher () {
      console.log('delTeacher', this.sletedTeacher)
      layer.confirm(
        '请问是否删除该学管师',
        {
          btn: [
            {
              name: '确认',
              callBack: () => {
                DelTheacherAdiser(this.sletedTeacher.OLAPKey).then(result => {
                  let locaIndex = this.teacherList.findIndex(o => {
                    return o.OLAPKey == this.sletedTeacher.OLAPKey
                  })
                  if (locaIndex != -1) {
                    this.teacherList.splice(locaIndex, 1)
                  }
                }).catch(error => {

                })
                layer.close();
              }
            }
          ]
        }
      );
    },
    closeSettingDialog () {
      this.isShowSetting = false;// 关闭预警设置
      this.isShowAttendanceSetting = false;// 关闭出勤预警
    },
    // 预警设置确定触发.
    doAfterConfirm () {
      this.updateTeacherList();
      this.getDataInfo();
      this.getDataList();
    },
    // 续费预警设置
    renewWarningSetBtn () {
      this.isShowSetting = true;
    },
    // 出勤预警设置
    attendanceWarningSetBtn () {
      this.isShowAttendanceSetting = true;
    },
    updateTeacherList () {
      console.log('updateTeacherList');
      this.$emit('updateTeacherList');
    },
    closeDialog () {
      this.showAddForm = false;
    },
    // 新增老师
    addTeacher () {
      this.showAddForm = true;
    },
    // 分配学生
    toStudentCourseList (StatusType, TeacherKey) {
      let obj = {
        TeacherKey: TeacherKey || 0,
        StatusType: StatusType || 0
      };

      this.menuList.forEach(o => {
        if (o.type == 1) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      this.indexType = 1
      this.$nextTick(o => {
        this.$refs.studentCourseList.searchData(obj);
      })
    },
    getDataInfo () {
      GetActiveStudentCount().then(result => {
        this.studentCourseInfo = result.data;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
      GetRenewalWarningCount().then(result => {
        this.warningInfo = result.data;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    seletedMenuAfter (type, callback) {     // 防止seletedDate未清空.
      // console.log(callback, 'callback');
      this.menuList.forEach(o => {
        if (o.type == type) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });

      if (callback) {
        callback();
      }
      this.indexType = type;
      if (type == 1) {
        this.toStudentCourseList()
      }
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    getDataList (queryParams) {
      let fn = '';
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      GetCourseStudentList(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
    }

  }
};
</script>
<style scoped>
.mendian_backstage_search >>> .search_box {
  width: 240px !important;
  margin-right: 30px !important;
  min-width: 240px !important;
}
.change_history_data {
  position: absolute;
  right: 17px;
  top: 90px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
.payment_statistics_bottom .list_title {
  width: 100%;
  height: 87px;
  margin: auto;
  padding-left: 20px;
  line-height: 87px;
}
.statistics_list_right {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 55px;
  /* align-items: center; */
}
.statistics_list_right .statistics_item_yellow {
  height: 30px;
  padding: 5px;
  font-size: 14px;
  color: #ff9800;
  border: 1.25px solid #ff9800;
  border-radius: 4px;
  margin-right: 10px;
}
.statistics_list_right .statistics_item_content {
  width: 103px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  border: 1.25px solid #ececec;
  /* color: #333; */
  font-size: 14px;
  text-align: center;
  margin-right: 20px;
  min-width: 103px;
  position: relative;
}
.statistics_item_content_text {
  display: inline-block;
  width: 100%;
  padding: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.statistics_item_content.opt {
  border: 1.25px solid #3498db;
}
.statistics_teacher_content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.del_red {
  position: absolute;
  width: 18px;
  height: 18px;
  top: -9px;
  right: -9px;
  background: url('../../../../public/image/del_red.png') center no-repeat;
}
.statistics_item_content.noborder {
  border: none;
  text-align: left;
  margin-right: 0px;
}
.statistics_item_content.bg_blue {
  background-color: rgba(52, 152, 219, 0.2);
  color: #3498db;
}
.statistics_list_right .statistics_item_red {
  height: 30px;
  padding: 5px;
  font-size: 14px;
  color: #f75040;
  border: 1.25px solid #f75040;
  border-radius: 4px;
  margin-right: 10px;
  min-width: 103px;
}
.statistics_list_right .border_left {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-right: none;
}
.statistics_list_right .border_right {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: none;
}
.warning_right {
  position: relative;
  padding-left: 30px;
}
.warning_right::before {
  position: absolute;
  display: block;
  content: '';
  left: 0;
  width: 20px;
  height: 20px;
  background: url('../../../../public/image/menu_5.png') center no-repeat;
}

.statistics_item:hover {
  background: rgba(52, 152, 219, 0.2);
}
</style>