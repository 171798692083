<template>
  <div class="course_form_dialog">
    <div class="form_info_edit form_info_list single_shadow content_marginRL">
      <input-text :formTitle="'课程名称'"
                  :formPlaceholder="'请输入'"
                  :required="true"
                  v-model="submitData.MainDemoName"></input-text>

      <single-select-tree-dialog :formTitle="'所属行业'"
                                 :formPlaceholder="'请选择'"
                                 :required="true"
                                 v-model="vCourseNameSelectInfo"
                                 :dataSource="courseNameList"
                                 :isShowBtnAdd="false"
                                 :disabledType="0"
                                 class="new_class font_black"
                                 @doAddCoursSuccessEvent="showCourseNameListDialog"></single-select-tree-dialog>
      <div class="pr">
        <span class="pa input_date_week"
              style="top: 8px; right:220px">如5-14岁</span>
        <input-text :formTitle="'年龄范围'"
                    :formPlaceholder="'请输入'"
                    :required="true"
                    v-model="submitData.SchoolAgeRange"></input-text>
      </div>
      <input-number :formTitle="'课时单价'"
                    :min="0"
                    :max="9999999"
                    :maxLength="7"
                    v-model="submitData.UnitPriceAmount"
                    :decimalPlaces="2"
                    :required="true"></input-number>

      <input-number :formTitle="'上课次数'"
                    :min="0"
                    :max="9999"
                    :maxLength="4"
                    v-model="submitData.ClassTimeCount"
                    :decimalPlaces="0"
                    :required="true"></input-number>
      <input-text :formTitle="'课程简介'"
                  :formPlaceholder="'请输入'"
                  :required="true"
                  v-model="submitData.CourseIntro"></input-text>

      <form-select :formTitle="'收费模式'"
                   :required="true"
                   :dataItem="submitData.ChargeTypeKeyValue"
                   :dataList="chargeType"
                   v-model="submitData.ChargeTypeKeyValue"
                   @choseItem="chooseChargeType"></form-select>

      <input-number :formTitle="vPriceAmountTitle"
                    :min="0"
                    :max="9999999"
                    :maxLength="7"
                    v-model="submitData.PriceAmount"
                    :decimalPlaces="2"
                    :required="true"></input-number>

      <div id="screanContent"
           style="z-index:1;position: absolute !important;right: 45px; opacity: 0"></div>
      <input-upload-img :ImgURl="submitData.CourseCover?submitData.CourseCover:''"
                        :formTitle="'课程封面'"
                        :imgHeight="101"
                        :imgWidth="101"
                        :required="true"
                        @doSetPicCorp="selectionCourseCover"></input-upload-img>

    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>

  </div>
</template>

<script>
import formSelect from '../../../../../form-item/form-select';
import { GetYKCourse } from '../../../../../../API/workbench';
export default {
  components: {
    formSelect
  },
  data () {
    return {
      submitData: {
        MainDemoName: '', // 课程名
        CourseCover: '', // 课程封面
        PriceAmount: 0, // 报价
        ChargeTypeKey: 2, // 收费方式1终身;2按 年收费;3按次收费
        ChargeTypeKeyValue: '按年收费', // 收费方式1终身;2按年收费;3按次收费
        CourseIntro: '', // 课程简介
        ClassTimeCount: '', // 上课课次
        UnitPriceAmount: '', // 课时单价
        SchoolAgeRange: '', // 学龄范围
        IndustryKey: '', // 行业
        IndustryKeyValue: '', // 行业
        GID: ''
      },
      chargeType: [
        { key: 1, value: '一次买断' },
        { key: 2, value: '按年收费' },
        { key: 3, value: '按课次收费' }
      ],
      courseNameList: [],
      isShowCoursListDialog: false
    };
  },
  props: {
    dataInfo: Object,
    submitInfo: Object
  },
  computed: {
    vCourseNameSelectInfo: {
      get () {
        return {
          id: this.submitData.IndustryKey,
          label: this.submitData.IndustryKeyValue
        };
      },
      set (newValue) {
        this.submitData.IndustryKeyValue = newValue.label;
        this.submitData.IndustryKey = newValue.id;
      }
    },
    vPriceAmountTitle () {
      let str = '';
      if (this.submitData.ChargeTypeKey == 1) {
        str = '买断报价';
      } else if (this.submitData.ChargeTypeKey == 2) {
        str = '按年报价';
      }
      if (this.submitData.ChargeTypeKey == 3) {
        str = '课次报价';
      }
      return str;
    }
  },
  created () {
    this.getCourseNameList();
    this.submitData = Object.assign(this.submitData, this.dataInfo);
  },
  methods: {
    // 调用课类课名列表接口
    getCourseNameList () {
      this.courseNameList = [];
      GetYKCourse().then((result) => {
        this.courseNameList = result.data;
        this.courseNameList.splice(0, 0, {
          OLAPKey: '2',
          MainDemoName: '全部课程',
          TypeKey: '1',
          TypeKeyValue: '课类',
          LevelPath: '0.2.',
          FatherKey: '0',
          FatherKeyValue: '',
          StateKey: '2',
          StateKeyValue: '启用',
          UnitPrice: '0.0'
        });
      }, (error) => {
        layer.alert(error.msg);
      }
      );
    },
    // 打开课程
    showCourseNameListDialog () {
      console.log(this.submitInfo, 'submitInfo');
      this.isShowCoursListDialog = true;
    },
    closeCoursListDialog () {
      this.isShowCoursListDialog = false;
    },
    doAfterSelectionCourseName (item) {
      this.submitData.IndustryKey = item.OLAPKey;
      this.submitData.IndustryKeyValue = item.MainDemoName;
    },
    // 课程封面
    selectionCourseCover (Url) {
      this.submitData.CourseCover = Url;
    },
    chooseChargeType (item) {
      this.submitData.ChargeTypeKey = item.key;
      this.submitData.ChargeTypeKeyValue = item.value;
    },
    confirmClick (callBack) {
      let flag = true;
      if (
        !this.submitData.MainDemoName ||
        !this.submitData.MainDemoName.replace(/(^\s*)|(\s*$)/g, '')
      ) {
        layer.alert('请输入课程名称');
        flag = false;
      } else if (!this.submitData.IndustryKeyValue) {
        layer.alert('请选择所属行业');
        flag = false;
      } else if (!this.submitData.SchoolAgeRange) {
        layer.alert('请输入年龄范围');
        flag = false;
      } else if (!this.submitData.UnitPriceAmount) {
        layer.alert('请输入课时单价');
        flag = false;
      } else if (!this.submitData.ClassTimeCount) {
        layer.alert('请输入上课次数');
        flag = false;
      } else if (!this.submitData.CourseIntro) {
        layer.alert('请输入课程简介');
        flag = false;
      } else if (!this.submitData.PriceAmount) {
        layer.alert('请输入' + this.vPriceAmountTitle);
        flag = false;
      } else if (!this.submitData.CourseCover) {
        layer.alert('请上传课程封面');
        flag = false;
      }
      if (!flag) {
        if (typeof callBack == 'function') {
          callBack();
        }
        return false;
      }
      this.$emit('addCourseInfo', this.submitData);
    },
    cancelClick () {
      this.$emit('cancelClick');
    }
  }
};
</script>

<style>
.course_form_dialog .my_datum_picture_text{
  overflow: initial;
}
</style>