<template>
  <!-- 套餐设置表单 -->
  <div class="taoCanSetting clearfix">
    <!-- 查看 -->
    <div v-if="taocanType=='view'&& Number(taocanInfo.TableID)>0">
      <taocan-details-from :taocanInfo="taocanInfo"
                           :taocanType="taocanType"
                           @afterSuccess="afterSubmitSuccess"
                           @cancelClick="cancelClick"></taocan-details-from>
    </div>
    <!-- 修改 -->
    <div v-else-if="taocanType=='edit'&& Number(taocanInfo.TableID)>0">
      <taocan-setting-from :taocanInfo="taocanInfo"
                           :taocanType="taocanType"
                           @afterSuccess="afterSubmitSuccess"
                           @cancelClick="cancelClick"></taocan-setting-from>
    </div>
    <!-- 新增 -->
    <div v-else-if="taocanType=='add'">
      <taocan-setting-from :taocanInfo="taocanInfo"
                           :taocanType="taocanType"
                           @afterSuccess="afterSubmitSuccess"
                           @cancelClick="cancelClick"></taocan-setting-from>

    </div>
  </div>
</template> 

<script>
import {
  GetPackageDetailInfoById
} from '../../../../../API/workbench';
import taocanDetailsFrom from './taocan-details-from';
import taocanSettingFrom from './taocan-setting-from';
export default {
  data () {
    return {
      // 套餐数据源
      taocanInfo: {
        TableID: 0, // 套餐ID
        PackageName: '', // -- 名称;
        lastRefreshTime: 0, // 更新
        UnitKey: 2, // -- 有效期单位(2-年;3-月;4-日);
        UnitKeyValue: '年', // ;
        StartTime: '', // 课时卡生效日期
        ExpireTime: '', // 课时卡失效日期
        PeriodCount: 1, // -- 有效期;
        TotalAmount: 0, // -- 总价值;
        DiscountAmount: '0', // -- 折扣金额;
        AfterDisPreAmount: '0', // -- 折后优惠;
        SalePrice: '0', // -- 售价;
        CommentScript: '', // -- 备注;
        KDNRList: [], // 课程课类
        ZFList: [] // 杂费
      },
      taocanId: null, // 套餐ID
      taocanType: null// 套餐类型
    };
  },
  components: {
    taocanDetailsFrom,
    taocanSettingFrom
  },
  props: {
    TableID: {// 套餐ID
      type: [String, Number],
      default: 0
    },
    type: { // 套餐类型
      type: String,
      default: ''
    }
  },
  created () {
    this.showFrom();
  },
  mounted () {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    // 提交
    afterSubmitSuccess (type, result) {
      this.$emit('afterSuccess', type, result);
    },
    // 关闭
    cancelClick () {
      this.$emit('closeDialog');
    },
    // 获取套餐信息
    showFrom () {
      this.taocanId = this.TableID;// 套餐ID
      this.taocanType = this.type;// 套餐类型
      if (this.taocanId > 0) {
        GetPackageDetailInfoById(this.taocanId).then(result => {
          console.log(result.data, this.taocanType, '套餐项');
          this.taocanInfo = result.data;
          this.taocanInfo.CountAmount = 0; // 虚拟套餐合计金额字段
          if (this.taocanInfo.ZFList.length > 0) {
            this.taocanInfo.ZFList.forEach(o => {
              // this.$set(o, 'isOpt', false);
              this.taocanInfo.CountAmount += Number(o.AfterDisAmount);
            });
          }
          if (this.taocanInfo.KDNRList.length > 0) {
            this.taocanInfo.KDNRList.forEach(o => {
              // this.$set(o, 'isOpt', false);
              this.taocanInfo.CountAmount += Number(o.AfterDisAmount);
            });
          }
        }, error => {
          layer.alert(error.msg);
        });
      }
    }
  }
};
</script>

  <style>
/*  */
</style>