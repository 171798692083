<template>
  <div>
    <div class="content_marginRL content_marginTop_30">
      <div class="new_course_time_from">
        <div class="form_info_list">
          <input-date v-model="currentRuleTime"
                    :elementId="'beginDate'"
                    :required="true"
                    :formTitle="'开始日期'"
                    :minDate="minDate"
                    :maxDate="maxDate"
                    :placeholder="'开始日期'"
                    @changeDate="doAfterChangeBeginDate"></input-date>
        </div>
        
        <div class="form_info_list pr">
          <!-- <input-select class="popTop_3"
                  :formTitle="'结束方式'"
                  :id="'key'"
                  :name="'value'"
                  :selectedInfo="vSelectionEndType"
                  :readonly="false"
                  :dataList="endTypes"
                  :helpDescription="'不结束：一直延续，不结束循环课<br/>限日期：指定日期结束循环课<br/>限节数：指定开课节数，自动计算结束日期'"
                  @selectedItem="changeEndType"></input-select> -->
             <input-select class="popTop_3"
                  :formTitle="'结束日期'"
                  :id="'key'"
                  :name="'value'"
                  :selectedInfo="vSelectionEndType"
                  :readonly="false"
                  :dataList="endTypes"
                  @selectedItem="changeEndType"></input-select>
          <div class="new_course_btn_box" v-if="curSelectionEndType.EndTypeKey==2">
            <div class="new_course_btn"
                  v-for="item in fastBtnList"
                  :key="item.key"
                  :class="{opt:item.isActive}"
                  @click.stop="clickDataBtnType(item)">
              {{ item.value}}
            </div>
          </div>
        </div>
        <div class="form_info_list pr" v-if="curSelectionEndType.EndTypeKey==2">
          <input-date v-model="lastClassTime"
                    :elementId="'endDate'"
                    :required="true"
                    :formTitle="'结束日期'"
                    :minDate="minDate"
                    :maxDate="maxDate"
                    :placeholder="'结束日期'"
                    @changeDate="doAfterChangeEndDate"></input-date>
          <div class="new_course_btn_box">
            <span>{{classCount}}节</span>
          </div>
        </div>
      </div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="closeDilog"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { GetScheduleCourseByRule } from '../../../API/workbench';
export default {
  data () {
    return {
      currentRuleTime: '',
      lastClassTime: '',
      classCount: 0,
      scheduleCourseList: null, // 规律课块下的所有课
      endTypes: [
        { value: '不限', key: 1, isActive: false },
        { value: '限日期', key: 2, isActive: false }
      ],
      curSelectionEndType: {EndTypeKey: 1, EndTypeKeyValue: '不限'},
      fastBtnList: [
        { value: '1个月', key: 1, isActive: false },
        { value: '2个月', key: 2, isActive: false },
        { value: '1年', key: 5, isActive: false }
      ]
    };
  },
  props: {
    circleCourseInfo: Object,
    sClassDate: String,
    eClassDate: String,
    weekkey: [Number, String],
    minDate: String,
    maxDate: String
  },
  computed: {
    vSelectionEndType: {
      get () {
        return {
          id: this.curSelectionEndType.EndTypeKey,
          name: this.curSelectionEndType.EndTypeKeyValue
        };
      },
      set (newValue) {
        this.curSelectionEndType.EndTypeKey = newValue.id;
        this.curSelectionEndType.EndTypeKeyValue = newValue.name;
      }
    }
  },
  created () {
    this.currentRuleTime = this.sClassDate;
    this.lastClassTime = this.eClassDate;
    if(this.circleCourseInfo.StructuredRuleKey){

      this.getScheduleCourseByRule();
    }
  },
  methods: {
    // 选择开始日期
    doAfterChangeBeginDate (date) {
      this.currentRuleTime = date;
      this.calculateClassCount();
    },
    // 选择结束日期
    doAfterChangeEndDate (date) {
      this.lastClassTime = date;
      this.calculateClassCount();
    },
    // 变更结束类型
    changeEndType (item) {
      switch (item.key) {
        case 1:// 全部
          this.lastClassTime = this.maxDate;
          this.calculateClassCount();
          break;
        case 2:// 限日期
          this.lastClassTime = '';
          this.fastBtnList.forEach(obj => {
            obj.isActive = false;
          });
          this.classCount = 0;
          break;
        default:
          break;
      }
      this.curSelectionEndType.EndTypeKey = item.key;
      this.curSelectionEndType.EndTypeKeyValue = item.value;
    },
    // 快捷(二月 半年 一年) 按钮
    clickDataBtnType (item) {
      if (!this.currentRuleTime) {
        layer.alert('请先选择开始日期');
        return false;
      }
      let circleStartTime = Date.new(this.currentRuleTime);
      let year = circleStartTime.getFullYear();
      let month = circleStartTime.getMonth();
      let date = circleStartTime.getDate() - 1;
      this.fastBtnList.forEach(obj => {
        if (item.key == obj.key) {
          obj.isActive = true;
        } else {
          obj.isActive = false;
        }
      });

      switch (item.key) {
        case 1:// 一个月
          this.lastClassTime = this.$utils.formatDateToLine(Date.new(year, month + 1, date));
          break;
        case 2:// 两个月
          this.lastClassTime = this.$utils.formatDateToLine(Date.new(year, month + 2, date));
          break;
        case 5:// 一年
          this.lastClassTime = this.$utils.formatDateToLine(Date.new(year + 1, month, date));
          break;
        default:
          break;
      }
      this.calculateClassCount();
    },
    calculateClassCount () {
      this.classCount = 0;
      this.scheduleCourseList.forEach(obj => {
        if (obj.ClassTime.slice(0, 10) >= this.currentRuleTime && obj.ClassTime.slice(0, 10) <= this.lastClassTime) {
          this.classCount += 1;
        }
      });
    },
    getScheduleCourseByRule () {
      GetScheduleCourseByRule(this.circleCourseInfo.StructuredRuleKey).then(result => {
        this.scheduleCourseList = result.data;
        if (Date.new(this.lastClassTime).getTime() < Date.new(this.maxDate).getTime()) {
          this.curSelectionEndType.EndTypeKey = 2;
          this.curSelectionEndType.EndTypeKeyValue = '限日期';
        }
        this.calculateClassCount();
      });
    },
    confirmClick (callback) {
      let flag = false;
      if (!this.currentRuleTime) {
        layer.alert('开始日期不可为空 。');
        flag = true;
      } else if (!this.lastClassTime) {
        layer.alert('截至日期不可为空 。');
        flag = true;
      } else if (Date.new(this.currentRuleTime).getTime() > Date.new(this.lastClassTime).getTime()) {
        layer.alert('开始日期不可大于截至日期 。');
        flag = true;
      } else if (!this.classCount) {
        layer.alert('至少选择一节课 。');
        flag = true;
      }
      if (flag) {
        if (typeof callback == 'function') {
          callback();
        }
        return false;
      }
      this.$emit('afterChangeClassTime', this.currentRuleTime, this.lastClassTime, this.classCount);
    },
    closeDilog (ruleID) {
      this.$emit('closeDilog', ruleID);
    }
  }
};
</script>

<style>
</style>