<template>
  <div class="">
    <div class="form_info_edit form_info_list">
       <div id="screanContent"
                   style="z-index:1;position: absolute !important;right: 45px; opacity: 0"></div>
        <input-upload-img :ImgURl="staffItemInfo.XMLIcon?staffItemInfo.XMLIcon:''"
                                :formTitle="'员工头像'"
                                :imgHeight="101"
                                :imgWidth="101"
                                @doSetPicCorp="doSetPicCorpHeadImg"></input-upload-img>
        <input-dialog :formTitle="'修改密码'"
                      :formPlaceholder="' '"
                      class="no_border_bottom"
                      :value="' '"
                      @showEldilog="showEditPassword"></input-dialog>
    
    </div>
    <!-- <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="closeDilog"></save-cancel-btn-group> -->
    <custom-dialog title="修改密码"
                   :visible.sync="isShowEditPassword"
                   :before-close="closeisShowEditPassword">
      <edit-pass-word :TeacherId="staffItemInfo.OLAPKey"
                      @cancelClick="closeisShowEditPassword"></edit-pass-word>
    </custom-dialog>
  </div>
</template>
<script>
import editPassWord from '../../chain-work-bench/edit-pass-word';
import { GetTheEmployeeByID,editEmployeeHeadImg } from '../../../API/workbench';
export default {
  data () {
    return {
      isShowEditPassword:false,
      staffItemInfo: {
        MainDemoName: '', // --名称
        SexKey: 2, // --性别
        SexKeyValue: '男', // --性别
        Mobile: '', // --手机号
        StartTime: this.$utils.formatDateToLine(Date.new()), // 获取当前日期和时间, // --入职日期
        Birthday: '', // --出生日期
        IDCard: '', // --身份证号
        XMLIcon: '', // --头像照片
        ZangIcon: '', // --身份证照片
        DepartmentKey: '', // --所属部门
        DepartmentKeyValue: '', // --所属部门
        PostKey: '', // --岗位
        PostKeyValue: '', // --岗位
        IsAdviserKey: 1, // --是否销售(1:是;0否)
        IsAdviserKeyValue: '是', // --是否销售 顾问
        IsTeacherKey: 1, // --是否老师(1:是;0否)
        IsTeacherKeyValue: '是', // --是否老师
        IsPTJobKey: 0, // --是否兼职(1:是;0否)
        IsPTJobKeyValue: '', // --是否兼职
        Chose2Key: '', // --权限角色
        Chose2KeyValue: '', // --权限角色
        Script: '', // --备注
        SpreadConponKey: '', // --推广活动
        SpreadConponKeyValue: '', // --推广活动
        PassWord: '', // --密码
        IfCloseKey: 1, // --是否开通系统(1:是;0否)
        IfCloseKeyValue: '是'
      },
    };
  },
  components: {
    editPassWord
  },
  props: {
    staffInfo:Object
  },
  computed: {
  
  },
  created () {
    this.getEmployeeInfo()
  },
  mounted(){
    // this.$dialog.register(this, ['editPassWord']);
  },
  methods: {
    showEditPassword(){
      this.isShowEditPassword=true
      // this.$dialog.open(this, {
      //   name: '修改密码',
      //   routerName: this.$route.name,
      //   moduleName: 'editPassWord',
      //   callBack: { afterSucces: (data) => { } }
      // });
    },
    doSetPicCorpHeadImg (Url) {
      this.staffItemInfo.XMLIcon = Url;
       editEmployeeHeadImg(this.$store.getters.token.UserID, Url).then(res => {
          this.$emit('upUserInfo');
        }, err => {

        })
    },
    closeisShowEditPassword(){
      this.isShowEditPassword=false
    },
    getEmployeeInfo () {
      GetTheEmployeeByID(this.$store.getters.token.UserID).then(result => {
        this.staffItemInfo = result.data;
        if (this.staffItemInfo.StartTime) {
          this.staffItemInfo.StartTime = this.staffItemInfo.StartTime.slice(0, 10);
        }
        console.log(this.staffItemInfo, '加载员工项');
      }, error => {
        layer.alert(error.msg);
      });
    },

  }
};
</script>

<style>
</style>