<template>
  <div class="superintendent">
    <!-- 总部版本 -->
    <!-- <div class="course_mandate_top">
      <div class="top_right_ul">
        <summarizing-single-item v-for="(item,key,index) in headSummaryData"
                                 :sumData="item"
                                 :key="index"
                                 @onCilckEvent="doAfterCilckSummaryData"></summarizing-single-item>
      </div>
    </div> -->
    <list
      ref="campusManagement"
      class="summarizing_list"
      :tableData="vcampusmdList"
      :tableColumns="tableColumns"
      :totalNum="totalNum"
      :rowKey="'Number'"
      :params="params"
      :defaultSort="defaultSort"
      @addCampus="addCampus"
      @loadTableData="loadAfterTableData"
    ></list>

    <custom-dialog
      :title="titleName + '校区'"
      :visible.sync="isShowChainCampusFormPopup"
      :before-close="closeChainCampusFormPopup"
    >
      <retail-campus-edit-form
        :CampusKey="Number(campusEditIndo.SchoolKey)"
        :isReadonly="titleName == '查看' ? true : false"
        @close="closeChainCampusFormPopup"
        @afterSuccess="getSearchPageForCampus"
      ></retail-campus-edit-form>
    </custom-dialog>
    <custom-dialog
      :title="'督学设置'"
      :visible.sync="isShowParticipateCampusFormPopup"
      :before-close="closeParticipateCampusFormPopup"
    >
      <franchisee-campus-edit-form
        :CampusKey="Number(campusEditIndo.SchoolKey)"
        :campusEditIndo="campusEditIndo"
        :isReadonly="titleName == '查看' ? true : false"
        @close="closeParticipateCampusFormPopup"
        @afterSuccess="getSearchPageForCampus"
      ></franchisee-campus-edit-form>
    </custom-dialog>
    <div>
      <dialog-factory
        ref="campusManagementDialogFactory"
        :routerName="'campusManagement'"
      ></dialog-factory>
    </div>
  </div>
</template>

<script>
import {
  closeSuperintendentSet,
  superintendentSet,
  SearchPageForCampus,
  CampusManagementDisable,
  CampusManagementEnable,
  monitorSetupInformation,
} from "../../../../API/workbench.js";
import retailCampusEditForm from "./form/retail-campus-edit-form";
import franchiseeCampusEditForm from "./form/franchisee-campus-edit-form";
import renewalForm from "./form/renewal-form";
import summarizingSingleItem from "../common/summarizing-single-item";
import list from "./table";
import dialogFactory from "../../../common/dialog-factory";
export default {
  name: "campusManagement",
  components: {
    summarizingSingleItem,
    list,
    retailCampusEditForm,
    franchiseeCampusEditForm,
    renewalForm,
    dialogFactory,
  },
  data() {
    return {
      isShowChainCampusFormPopup: false,
      isShowParticipateCampusFormPopup: false,
      isShowCampusRenewalForm: false,
      isShowPortApplyRecordListPopup: false,
      // 校区管理
      params: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: "", //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母
        orderBy: "", //	字符串	可选		数据源：GET	排序字段
        sequence: "", //	字符串	可选		数据源：GET	排序顺序
        IsActiveKey: "", //	整型	可选		数据源：GET	是否活跃校区
        IsStopKey: "", //	整型	可选		数据源：GET	是否停用
        IsExpiredKey: "", //	整型	可选		数据源：GET	是否已过期
        IsExpired30Key: "", //	整型	可选		数据源：GET	是否30天内过期
      },
      campusmdList: [], // 列表
      // headSummaryData: [
      //   { title: '活跃校区', colorClass: 'font_blue', value: '-', type: 'IsActiveKey', decimalPlace: 0, isFilter: true },
      //   { title: '累计开通', colorClass: 'font_blue', value: '-', type: '', decimalPlace: 0, isFilter: true },
      //   { title: '停用校区', colorClass: 'font_blue', value: '-', type: 'IsStopKey', decimalPlace: 0, isFilter: true },
      //   { title: '已过期', colorClass: 'font_blue', value: '-', type: 'IsExpiredKey', decimalPlace: 0, isFilter: true },
      //   { title: '30天内到期', colorClass: 'font_yellow', value: '-', type: 'IsExpired30Key', decimalPlace: 0, isFilter: true },
      //   { title: '可用校区端口', colorClass: 'font_blue', value: '-', type: 'CurrentPortalNum', decimalPlace: 0, isFilter: true }
      // ],
      tableColumns: [
        {
          label: "序号",
          prop: "Number",
          width: 54,
          sortable: false,
          align: "left",
          type: "tableIndex",
          className: "table_index_gray",
        },
        {
          label: "开户日期",
          width: 120,
          prop: "OpenTime",
          type: "date-item",
          sortable: "custom",
        },
        {
          label: "校区名称",
          width: 160,
          prop: "SchoolKeyValue",
          type: "md-name",
          sortable: false,
          extend: {
            mendianID: "SaaSClientNO",
            click: (rowData) => {
              this.viewClick(rowData);
            },
          },
        },
        {
          label: "负责人",
          prop: "PrincipalName",
          width: 120,
          align: "left",
          type: "text-item",
          sortable: false,
        },
        {
          label: "校区类型",
          prop: "TypeKeyValue",
          width: 120,
          align: "left",
          type: "text-item",
          sortable: false,
          extend: {
            // setClassName: (rowData) => { // 状态 0-已停用;1-已过期;2-使用中
            //   if (rowData.TypeKey == '1') {
            //     return rowData.TypeKey= '直营-参股';
            //   } else if (rowData.TypeKey == '2') {
            //     return rowData.TypeKey= '直营-直属';
            //   } else if (rowData.TypeKey == '3') {
            //     return rowData.TypeKey= '课件加盟';
            //   } else if (rowData.TypeKey == '4') {
            //     return rowData.TypeKey= '整店加盟';
            //   } else {
            //     return '';
            //   }
            // }
          },
        },
        {
          label: "备课检查",
          prop: "IsEnablePreparingCheckKeyValue",
          width: 120,
          align: "left",
          type: "text-item",
          sortable: "custom",
          extend: {
            setClassName: (rowData) => {
              // 状态 0-已停用;1-已过期;2-使用中

              if (rowData.IsEnablePreparingCheckKey == 0) {
                return "status_spot_red";
              } else if (rowData.IsEnablePreparingCheckKey == 1) {
                return "status_spot_green";
              } else if (rowData.IsEnablePreparingCheckKey == 2) {
                return "status_spot_green";
              } else {
                return "";
              }
            },
          },
        },
        {
          label: "课评统计",
          prop: "IsEnableReviewCheckKeyValue",
          width: 120,
          align: "left",
          type: "text-item",
          sortable: "custom",
          extend: {
            setClassName: (rowData) => {
              // 状态 0-已停用;1-已过期;2-使用中

              if (rowData.IsEnableReviewCheckKey == 0) {
                return "status_spot_red";
              } else if (rowData.IsEnableReviewCheckKey == 1) {
                return "status_spot_green";
              } else if (rowData.IsEnablePreparingCheckKey == 2) {
                return "status_spot_green";
              } else {
                return "";
              }
            },
          },
        },
        {
          label: "作业统计",
          prop: "IsEnableHomeWorkCheckKeyValue",
          width: 120,
          align: "left",
          type: "text-item",
          sortable: "custom",
          extend: {
            setClassName: (rowData) => {
              // 状态 0-已停用;1-已过期;2-使用中

              if (rowData.IsEnableHomeWorkCheckKey == 0) {
                return "status_spot_red";
              } else if (rowData.IsEnableHomeWorkCheckKey == 1) {
                return "status_spot_green";
              } else if (rowData.IsEnableHomeWorkCheckKey == 2) {
                return "status_spot_green";
              } else {
                return "";
              }
            },
          },
        },
        {
          label: "督学专员",
          width: 120,
          prop: "InspectorsKeyValue",
          type: "text-item",
          sortable: "custom",
        },
        // {
        //   label: '操作',
        //   prop: 'SysStatusKeyValue',
        //   width: 120,
        //   align: 'left',
        //   type: 'text-item',
        //   sortable: 'custom',
        //   extend: {
        //     setClassName: (rowData) => { // 状态 0-已停用;1-已过期;2-使用中
        //       if (rowData.SysStatusKey == 0) {
        //         return 'status_spot_red';
        //       } else if (rowData.SysStatusKey == 1) {
        //         return 'status_spot_gray';
        //       } else if (rowData.SysStatusKey == 2) {
        //         return 'status_spot_green';
        //       } else {
        //         return '';
        //       }
        //     }
        //   }
        // },
        {
          label: "操作",
          prop: "option",
          type: "table-btn",
          align: "center",
          extend: {
            tableBtnGroup: [
              // {
              //   name: '设置',
              //   extend: {
              //     isHide: (rowData) => {
              //       return rowData.SysStatusKey != 2;
              //     },
              //     click: (rowData) => { this.editClick(rowData); },
              //     ModulePowerKey: ''
              //   }
              // },
              {
                name: "设置",
                extend: {
                  isHide: (rowData) => {
                    return rowData.SysStatusKey == 1;
                  },
                  IsInquiryHint: "inquiryBtn",
                  setClassName: (rowData) => {
                    if (rowData.inquiryBtn) {
                      // 开启询问提示，显示询问弹窗时，界面按钮置灰，不可操作
                      return rowData.inquiryBtn;
                    }
                  },
                  click: (rowData, callBack) => {
                    console.log(rowData, "设置");
                    // this.campusEditIndo = rowData;
                    this.isShowParticipateCampusFormPopup = true;
                    // if (callBack) {
                    // callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: 'table_btn_gray' });
                    // }
                    // this.switchClick(rowData, callBack);
                    this.getMonitorSetupInformation(rowData);
                  },
                  ModulePowerKey: "",
                },
              },
              {
                name: "关闭",
                extend: {
                  isHide: (rowData) => {
                    // return rowData.StatusKey == 1;
                  },
                  IsInquiryHint: "inquiryBtn",
                  setClassName: (rowData) => {
                    if (rowData.inquiryBtn) {
                      // 开启询问提示，显示询问弹窗时，界面按钮置灰，不可操作
                      return rowData.inquiryBtn;
                    }
                    if (rowData.StatusKey == 0) {
                      return "";
                    } else if (rowData.StatusKey == 1) {
                      return "disabled";
                    }
                  },
                  click: (rowData, callBack) => {
                    if (callBack) {
                      callBack({
                        IsInquiryHint: "inquiryBtn",
                        InquiryClassName: "table_btn_gray",
                      });
                    }
                    this.switchClick(rowData, callBack);
                  },
                  ModulePowerKey: "",
                },
              },
            ],
          },
        },
      ],
      PageCount: 0,
      totalNum: 0,
      defaultSort: { prop: "studentInfo", order: "ascending" },
      campusEditIndo: {},
      titleName: "添加",
      closeObj: {
        InspectorsKey: "0", //督学专员
        InspectorsKeyValue: "",
        IsEnableHomeWorkCheckKey: "0", //是否开启作业检查
        IsEnableHomeWorkCheckKeyValue: "关闭", //0-未开启;1-开启
        IsEnableReviewCheckKey: "0", //是否开启点评检查
        IsEnableReviewCheckKeyValue: "关闭", //0-未开启;1-开启
        IsEnablePreparingCheckKey: "0", //是否开启备课检查
        IsEnablePreparingCheckKeyValue: "关闭", //0-未开启;1-开启
      },
    };
  },
  created() {
    this.registerBusEvent();
    this.getSearchPageForCampus();
    // this.getsLessonPreparationSettingsFn();
  },
  onShow() {
    this.getSearchPageForCampus();
    this.getsLessonPreparationSettingsFn();
  },
  computed: {
    vcampusmdList() {
      let newArr = [];
      this.campusmdList.forEach((o) => {
        if (o.IsEnableHomeWorkCheckKey == 1) {
          o.IsEnableHomeWorkCheckKeyValue = "开启";
        } else {
          o.IsEnableHomeWorkCheckKeyValue = "关闭";
        }
        if (o.IsEnablePreparingCheckKey == 1) {
          o.IsEnablePreparingCheckKeyValue = "开启";
        } else {
          o.IsEnablePreparingCheckKeyValue = "关闭";
        }
        if (o.IsEnableReviewCheckKey == 1) {
          o.IsEnableReviewCheckKeyValue = "开启";
        } else {
          o.IsEnableReviewCheckKeyValue = "关闭";
        }
        if (
          o.IsEnableHomeWorkCheckKey == 0 &&
          o.IsEnablePreparingCheckKey == 0 &&
          o.IsEnableReviewCheckKey == 0
        ) {
          this.$set(o, "StatusKey", 1);
        } else {
          this.$set(o, "StatusKey", 0);
        }
        if (o.TypeKey == 1) {
          o.TypeKeyValue= '直营店';
  
        } else if (o.TypeKey == 2) {
           o.TypeKeyValue= '加盟店';
     
        } else if (o.TypeKey == 3) {
           o.TypeKeyValue= '课程合作';
     
        }
        newArr.push(o);
      });
      return newArr;
    },
  },
  methods: {
    // 督学设置-分页查询
    getSearchPageForCampus() {
      superintendentSet(this.params).then(
        (result) => {
          console.log(result.data, "督学设置-分页查询");
          this.campusmdList = [];
          this.totalNum = result.data.Total;
          this.campusmdList = result.data.PageDataList;

          // 汇总数据
          // this.headSummaryData[0].value = result.data.report.ActiveCampusCount;// 活跃校区
          // this.headSummaryData[1].value = result.data.report.CampusCount;// 累计开通数
          // this.headSummaryData[2].value = result.data.report.DisabledCount;// 停用校区数
          // this.headSummaryData[3].value = result.data.report.ExpiredCount;// 已过期数
          // this.headSummaryData[4].value = result.data.report.Expired30Count;// 30天内到期数
          // this.headSummaryData[5].value = result.data.report.CurrentPortalNum;// 可用端口数
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    //获取设置弹框信息
    getMonitorSetupInformation(data) {
      monitorSetupInformation(data)
        .then(
          (result) => {
            console.log(result.data, "设置获取信息");
            this.campusEditIndo = result.data;
          },
          (error) => {
            layer.alert(error.msg);
          }
        )
        .finally(() => {
          // if (callback) {
          //   callback();
          // }
        });
    },
    // 汇总排序
    doAfterCilckSummaryData(item) {
      console.log("点击汇总数据排序", item);
      if (item.type == "CurrentPortalNum") {
        this.isShowPortApplyRecordListPopup = true;
      } else {
        this.params.searchText = "";
        this.params.pageIndex = 0;
        this.params.IsActiveKey = ""; //	整型	可选		数据源：GET	是否活跃校区
        this.params.IsStopKey = ""; //	整型	可选		数据源：GET	是否停用
        this.params.IsExpiredKey = ""; //	整型	可选		数据源：GET	是否已过期
        this.params.IsExpired30Key = ""; //	整型	可选		数据源：GET	是否30天内过期
        this.params[item.type] = 1;
        this.getSearchPageForCampus();
      }
    },
    // 条件查询
    loadAfterTableData(queryParams) {
      queryParams.orderBy =
        queryParams.orderBy == "SysStatusKeyValue"
          ? "SysStatusKey"
          : queryParams.orderBy;
      if (queryParams) {
        Object.assign(this.params, queryParams);
      }
      this.getSearchPageForCampus();
    },
    // 新增
    addCampus(type) {
      this.titleName = "添加";
      this.campusEditIndo = {};
      if (type == "ZY") {
        // 添加直营校区
        this.isShowChainCampusFormPopup = true;
      } else {
        // 添加加盟校区
        this.isShowParticipateCampusFormPopup = true;
      }
    },
    // 设置
    editClick(rowData) {
      console.log(rowData, "设置111");
      // this.titleName = '修改';
      // this.campusEditIndo = rowData;
      // console.log(rowData, '校区管理-修改');
      // if (rowData.TypeKey > 2) { // 1-直营参股;2-直营直属;3-课件加盟;4-整店加盟
      //   this.isShowParticipateCampusFormPopup = true;
      // } else {
      //   this.isShowChainCampusFormPopup = true;
      // }
    },
    // 校区名称
    viewClick(rowData) {
      console.log(rowData, "校区名称");
      this.titleName = "查看";

      this.campusEditIndo = rowData;

      // if (rowData.TypeKey > 2) { // 1-直营参股;2-直营直属;3-课件加盟;4-整店加盟
      //   this.isShowParticipateCampusFormPopup = true;
      // } else {
      //   this.isShowChainCampusFormPopup = true;
      // }
      this.isShowChainCampusFormPopup = true;
    },
    // 续费
    renewClick(rowData) {
      this.campusEditIndo = rowData;
      this.isShowCampusRenewalForm = true;
    },
    // 关闭
    switchClick(rowData, callBack) {
      console.log(rowData, " 关闭123");
      let name = "关闭";
      layer.confirm("请确定是否" + name + "?", {
        btn: [
          {
            name: "取消",
            callBack: function () {
              layer.close();
              if (callBack) {
                callBack({ IsInquiryHint: "inquiryBtn", InquiryClassName: "" });
              }
            },
          },
          {
            name: "确认",
            callBack: () => {
              closeSuperintendentSet(rowData, this.closeObj).then(
                (result) => {
                  console.log(rowData, "rowData222");
                  this.getSearchPageForCampus();

                  if (callBack) {
                    this.$set(rowData, "StatusKey", 1);
                    callBack({
                      IsInquiryHint: "inquiryBtn",
                      InquiryClassName: "",
                    });
                  }
                },
                (error) => {
                  layer.alert(error.msg);
                }
              );
              layer.close();
            },
          },
        ],
      });
      // closeSuperintendentSet(this.params).then(result => {

      // }, error => {
      //   layer.alert(error.msg);
      // });
    },
    // 关闭
    closeChainCampusFormPopup() {
      this.isShowChainCampusFormPopup = false;
    },

    // 关闭
    closeParticipateCampusFormPopup() {
      this.isShowParticipateCampusFormPopup = false;
    },
    // 关闭
    closeCampusRenewalFormPopup() {
      this.isShowCampusRenewalForm = false;
    },
    // 关闭
    closePortApplyRecordListPopup() {
      this.isShowPortApplyRecordListPopup = false;
    },
    registerBusEvent() {
      this.$bus.on(this, "openCustomDialog", (dialogInfo) => {
        if (dialogInfo.routerName == "campusManagement") {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog(dialogInfo) {
      if (this.$refs.campusManagementDialogFactory) {
        this.$refs.campusManagementDialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name
        );
      }
    },
  },

};
</script>
<style>
</style>