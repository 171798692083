<template>
  <!-- 门店设置 -->
  <div class="margin_left_right_div">
    <div class="workbench_view clearfix width_fixed template_minOrmax_width_div"
         style="width: auto;">
      <div class="workbench_view_box">
        <div class="workbench_view_left"
             style="padding-right: 390px !important;">
          <div class="view_block_left">
            <div class="massive_bg overflow_hidden">
              <div class="workbench_view_title">
                <span class="font-weight:normal">考勤通报</span>
                <span class="font_gray"
                      style="margin-left:15px">
                  今天是 {{vTodayDateAndWeek}}
                </span>
              </div>
              <attenReportTop ref="attenReportTop"></attenReportTop>
              <div class="workbench_view_title no_border_bottom">
                <span style="font-weight:normal">考勤日报</span>
                <span class="font_gray"
                      style="margin-left:15px">
                  (左右滑动日期可查看更多)
                </span>
                <div class="workbench_view_title_calendar_box fr">
                  <div class="workbench_view_title_calendar fr">日历
                    <input-date class="new_index_view_date_box"
                                :formTitle="''"
                                :popperClass="'course_date_el'"
                                ref="inputDate"
                                @changeDate="changeDay"></input-date>
                  </div>
                  <!-- <input-date class="course_header_date"
                            style="left:371px;top:12px"
                            :formTitle="''"
                            @changeDate="changeDay"></input-date> -->

                </div>
              </div>

              <div class="principal_swiper">
                <div class="principal-swiper-container swiper-container"
                     style="width:100%">
                  <div class="swiper-wrapper">
                    <!-- 选中类名 opt -->
                    <kq-item v-for="(item, key) in kqList"
                             :key="key"
                             :item="item"
                             @chgCoureDateAfter="chgCoureDate"></kq-item>
                  </div>
                </div>
              </div>
              <div class="principal_swiper_details">
                <div class="principal_swiper_data clearfix"
                     v-if="showAttenInfo">
                  <div class="fl">
                    <div class="data_title">考勤小结</div>
                    <div class="data_box clearfix">
                      <div>
                        签到 {{Number(selectedDateInfo.ArrivedNumCount)}}人
                      </div>
                      <div class="">
                        未到
                        <span class="font_red">{{Number(selectedDateInfo.LeaveNumCount)+Number(selectedDateInfo.TruantNumCount)+Number(selectedDateInfo.FAttenceNumCount)}}</span>人
                      </div>
                      <div>
                        出勤率
                        <span class="font_black">{{$utils.saveDecimal(Number(selectedDateInfo.AttendanceRate))+'%'}}</span>
                      </div>
                      <div>
                        请假
                        <span class="font_red">{{Number(selectedDateInfo.LeaveNumCount)}}</span>人
                      </div>
                      <div class="">
                        旷课
                        <span class="font_red">{{Number(selectedDateInfo.TruantNumCount)}}</span>人
                      </div>
                      <div>
                        未考勤
                        <span class="font_red">{{Number(selectedDateInfo.FAttenceNumCount)}}</span>人
                      </div>
                    </div>
                  </div>
                  <div class="fr pr">
                    <div class="principal_progress_title_show"
                         :class="vUpDown">
                      <span>同比上周</span>
                    </div>
                    <div class="principal_progress_box"
                         :class="vDown">
                      <!-- <el-progress type="circle"
                            :percentage="100"
                            :stroke-width="10"
                            color="'#F97373'">
                </el-progress>-->
                      <div class="principal_progress_text pa">
                        <div>
                          <span class="font_black">{{ compareLastWeekAtten >= 0 ? "+" : ""
                          }}{{ compareLastWeekAtten }}</span>人
                        </div>
                        <div>签到</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="workbench_view_help_nav clearfix"
                     style="font-weight: inherit;"
                     v-if="isToday">
                  <div class="nav_list fl font_gray"
                       :class="{ opt: isTodayType == 1 }"
                       @click="isTodayType = 1">
                    已上课程
                  </div>
                  <div class="nav_list fl font_gray"
                       :class="{ opt: isTodayType == 2 }"
                       @click="isTodayType = 2">
                    未上课程
                  </div>
                </div>
                <div class="workbench_view_help_nav clearfix"
                     v-if="!isToday&&isfutrue">
                  <div class="nav_list opt fl">
                    未上课程
                  </div>
                </div>
                <div class="principal_swiper_ul"
                     :class="{ no_border: showAttenInfo }"
                     v-if="vcourseList.length > 0">
                  <courseItem v-for="(item, key) in vcourseList"
                              :key="key"
                              :item="item"
                              :showAttenInfo="showAttenInfo"
                              @clickitem="showArrange"></courseItem>
                  <div class="principal_swiper_more"
                       v-if="showCourseList.length > 5 && this.isTodayType == 1 && isShowAll == false"
                       @click="chgisOverShowMore(true)">
                    <span class="list_more">查看更多课程</span>
                  </div>
                  <div class="principal_swiper_more"
                       v-if="showCourseList.length > 5 && this.isTodayType == 2 && isNotBeginShowAll == false"
                       @click="chgisNotBeginShowMore(true)">
                    <span class="list_more">查看更多课程</span>
                  </div>
                  <div class="principal_swiper_more"
                       v-if="vcourseList.length > 5 && this.isTodayType == 1 && isOverShowMore == true"
                       @click="chgisOverShowMore(false)">
                    <span class="list_up">已显示全部课程</span>
                  </div>
                  <div class="principal_swiper_more"
                       v-if="vcourseList.length > 5 && this.isTodayType == 2 && isNotBeginShowMore == true"
                       @click="chgisNotBeginShowMore(false)">
                    <span class="list_up">已显示全部课程</span>
                  </div>
                </div>
                <div v-if="vcourseList.length == 0 && selectedDateInfo.ClassTime"
                     class="course_block_nodata">
                  <div class="monitoring_content_nodata_txt">
                    亲，当前没有任何数据哦~
                  </div>
                </div>
              </div>
            </div>
            <div class="massive_bg index_warning">
              <div class="principal_tody_box">
                <function-btn :item="abnormalHandlingbtn1"
                              @clickItem="doAfterClickFunctionBtn('abnormalhandling')"
                              :className="''">
                  <div class="workbench_view_title button">
                    <div class="fl">异常操作</div>
                    <div class="ignore_warning fr">
                      <span class="font_blue">详情</span>
                      <!-- {{ presidentReport.AbnormalNum }} -->
                    </div>
                  </div>
                </function-btn>
                <div class="principal_exception_data">
                  <div>
                    <span>今日异常</span>
                    <span class="font_bigger">{{ Number(presidentReport.AbnormalNum) }}</span>
                  </div>
                  <div>
                    <span>本月异常</span>
                    <span class="font_bigger">{{ Number(presidentReport.MonthAbnormalNum) }}</span>
                  </div>
                </div>
                <!-- <div class="principal_echart_option no_border_bottom" @click="goRouter('ignoreWarning')">
            <div class="fl">今日预警</div>
            <div class="fr">
              <span class="font_gray">待处理</span> {{presidentReport.TotalIgnore}},
              <span class="font_gray">忽略</span> {{presidentReport.IgnoreNum}}
            </div>
          </div>-->
              </div>
            </div>
            <div class="index_warning massive_bg">
              <div class="workbench_view_title form_info_field">
                智能预警
                <input-form-tip :tips="'根据考勤，自动统计课时不足，欠课时等预警情况，点击可查看详情。'"></input-form-tip>
                <function-btn :item="abnormalHandlingbtn"
                              @clickItem="doAfterClickFunctionBtn('ignorewarning')"
                              :className="'ignore_warning'">
                  本月已忽略
                  <span class="font_blue">{{Number(presidentReport.MonthIgnoreNum)}}条</span>

                </function-btn>

              </div>
              <div class="index_warning_content">
                <!-- :class="'list_0' + (key + 1)" -->
                <div class="index_warning_list"
                     v-for="(item, key) in vWarmingMenuList"
                     :key="key"
                     @click="toWarmingDetail(item)">
                  <div class="list_box form_info_field">
                    <div class="fl">{{ item.name }}

                    </div>
                    <div class="fr">
                      {{ item.num }}
                      <span class="font_unit">{{ key != 5 ? "人" : "个" }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="view_block_right">
            <manage-report></manage-report>
            <div class="massive_bg">
              <!-- <div class="workbench_view_title">运营汇总</div>
            <div class="principal_statistics">
              <div class="statistics_list">
                <div class="font_top"
                     :style="setSize(presidentReport.StudentNum)">
                  {{ integerData(presidentReport.StudentNum) }}
                  <span class="font_small">人</span>
                </div>
                <div class="font_bottom">在校学生</div>
              </div>
              <div class="statistics_list">
                <div class="font_top"
                     :style="setSize(presidentReport.AvailableClassHours)">
                  {{ integerData(presidentReport.AvailableClassHours) }}
                  <span class="font_small">课时</span>
                </div>
                <div class="font_bottom">可用课时</div>
              </div>
              <div class="statistics_list">
                <div class="font_top"
                     :style="setSize(presidentReport.AvailableHoursValue)">
                  {{ integerData(presidentReport.AvailableHoursValue) }}
                  <span class="font_small">元</span>
                </div>
                <div class="font_bottom">可用课时价值</div>
              </div>
            </div> -->
              <!-- <atten-report ></atten-report> -->
              <atten-chart ref="attenChart"
                           :screenWidth="screenWidth"></atten-chart>
              <!-- 课消金额 -->
              <classAmountReport ref="classAmountReport"
                                 :screenWidth="screenWidth"></classAmountReport>
              <!-- 报名业绩 -->
              <apply-performance-report ref="applyPerformanceReport"
                                        :screenWidth="screenWidth"></apply-performance-report>
            </div>
          </div>
        </div>
        <div class="workbench_view_right">
          <div class="workbench_view_user massive_bg"
               @click.stop="doAfterClickFunctionBtn('mine')">
            <div class="user_img">
              <!-- <img :src="headImg" alt :onerror="defaultImg" /> -->
              <staff-heads-img-upload :ImgURl="staffInfo.XMLIcon"
                                      :staffInfo="staffInfo"
                                      :formTitle="'员工头像:'"
                                      :imgHeight="300"
                                      :imgWidth="300"
                                      @doSetPicCorp="doSetPicCorpHeadImg"></staff-heads-img-upload>
            </div>
            <div class="user_name">
              嗨！{{staffInfo.MainDemoName}}
            </div>
            <div class="user_post">
              {{staffInfo.PostKeyValue||''}}
            </div>
            <div class="user_course"
                 v-if="latelyCourseInfo.CourseNameKey > 0">
              <span class="font_gray">最近的课：</span>
              {{vToday}}
              {{ latelyCourseInfo.SClassTimeName }}
              {{ latelyCourseInfo.CourseNameKeyValue }}
            </div>
            <div class="user_course"
                 v-else>
              <span class="font_gray">当前没有课</span>
            </div>
            <div class="user_btn"></div>
          </div>
          <todo-list ref="todoList"></todo-list>
          <!-- <instructions></instructions> -->
          <!-- <lastNew></lastNew> -->
        </div>
      </div>
      <!-- <attendance-arrange ref="newAttendanceArrangeDialog"
                        @updateCourse="updateCourseafter"
                        :formTitle="'安排考勤'"></attendance-arrange> -->

      <div>
        <dialog-factory ref="presidentKanbanDialogFactory"
                        :key="'presidentKanban'"
                        :routerName="'presidentKanban'"></dialog-factory>
        <dialog-factory-common ref="presidentKanbanDialogFactoryCommon"></dialog-factory-common>
      </div>
    </div>
  </div>
</template>
<script>
import kqItem from './kq-item';
import courseItem from './course-item';
import attenChart from './atten-chart';
import attenReport from './atten-report';
import attenReportTop from './atten-report-top';
import todoList from '../new-index-view/todo-list';
import instructions from '../new-index-view/instructions';
import lastNew from '../new-index-view/last-new';
import classAmountReport from './class-amount-report';
import applyPerformanceReport from './apply-performance-report';
import dialogFactory from '../common/dialog-factory';
import manageReport from './manage-report';
import attendanceArrange from '../pop-up-menu/exception-handle/attendance-arrange-form/attendance-arrange';
import {
  getPresidentStatistics,
  getKQsummaryStatistics,
  getKQsummaryForDay,
  getToDoStatistics,
  GetTheEmployeeByID,
  GetTheEmployeeTeachingPercentage
} from '../../API/workbench';
export default {
  name: 'presidentKanban',
  data () {
    return {
      curSelectedDate: Date.new().toLocaleDateString(),
      presidentReport: {}, // 校长统计
      startTime: '',
      endTime: '',
      token: {},
      swiper: {},
      selectedDateInfo: {}, // 选中天的详细数据
      kqList: [],
      showAttenInfo: false, // 显示考勤详情
      isToday: false, // 是否今天显示操作栏
      isTodayType: 1, // 1为已上课程, 2位未上课程.
      isfutrue: false,    // 判断是否为今天之后.
      courseList: [],
      isOverShowMore: false, // 点击触发显示更多.
      isNotBeginShowMore: false, // 点击触发未开始显示更多.
      isShowAll: false, // 是否已经显示所有
      isNotBeginShowAll: false, // 是否未开始已经显示所有
      showCourseList: [], // 显示长度.
      warmingMenuList: [], // 预警统计列表
      WarmingStatisical: {},
      isShowDialogFactory: false,
      seletedCourseInfo: {}, // 选中课
      staffInfo: {}, // 员工信息
      latelyCourseInfo: {}, // 最后一节课
      abnormalHandlingbtn: {      // 异常记录权限
        name: '',
        eventName: 'abnormalHandlingbtn',
        ModulePowerKey: 54
      },
      abnormalHandlingbtn1: {      // 异常记录权限
        name: '',
        eventName: 'abnormalHandlingbtn',
        ModulePowerKey: 62
      },
      screenWidth: '',
      curCourseIndex: 0,
      isSwitchHeaderBar: false
    };
  },
  components: {
    kqItem,
    courseItem,
    attenChart,
    attenReport,
    attenReportTop,
    classAmountReport,
    applyPerformanceReport,
    dialogFactory,
    attendanceArrange,
    todoList,
    instructions,
    lastNew,
    manageReport
  },
  created () {
    this.token = this.$store.getters.token;
    this.warmingMenuList = this.$initJson.prisidentToDoWarningList;
    this.loadData();
    this.resgisterBusEvent();
  },
  activated () {

  },
  mounted () {
    this.swiperInit();
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        // alert(this.screenWidth, 'this.screenWidth');
        console.log('this.screenWidth', this.screenWidth);
      })();
    };
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'presidentKanban') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vcourseList () {
      let newArr = [];
      this.showCourseList = [];
      if (this.isToday) {
        // 今天.
        if (this.isTodayType == 1) {
          // 已上,
          this.overShowMore(newArr);
        } else {
          // 未上.
          this.notBeginShowMore(newArr);
        }
        return newArr;
      } else {
        if (this.isOverShowMore) {
          this.courseList.forEach(o => {
            newArr.push(o);
          });
        } else {
          this.courseList.forEach(o => {
            if (newArr.length < 5) {
              newArr.push(o);
            }
          });
        }
        this.courseList.forEach(o => {
          this.showCourseList.push(o);
        });
        return newArr;
      }
    },
    // 同比上周-考勤小结
    compareLastWeekAtten () {
      return (
        Number(this.selectedDateInfo.ArrivedNumCount) -
        Number(this.selectedDateInfo.TheCycleArrivedNumCount)
      );
    },
    // 显示上升或下降.
    vUpDown () {
      if (this.compareLastWeekAtten > 0) {
        return 'principal_progress_title';
      } else if (this.compareLastWeekAtten < 0) {
        return 'principal_progress_title2';
      } else {
        return '';
      }
    },
    // 显示上升或下降.
    vDown () {
      if (this.compareLastWeekAtten > 0) {
        return 'top';
      } else if (this.compareLastWeekAtten < 0) {
        return 'down';
      } else {
        return '';
      }
    },
    // 头像
    headImg () {
      if (!this.staffInfo.XMLIcon) {
        this.staffInfo.XMLIcon = '';
      }
      if (this.staffInfo.XMLIcon.indexOf('http') > -1) {
        return this.staffInfo.XMLIcon;
      } else {
        return this.$store.getters.CDNURL + this.staffInfo.XMLIcon;
      }
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' +
        require('../../../public/image/deflut_teacher_photo@2x.png') +
        '"'
      );
    },
    vWarmingMenuList () {
      // let newArr = [];
      for (var i = 0; i < this.warmingMenuList.length; i++) {
        switch (String(this.warmingMenuList[i].type)) {
          case '1':
            this.warmingMenuList[
              i
            ].num = this.WarmingStatisical.CountRenewalNum;
            break;
          case '2':
            this.WarmingStatisical.DebtClassHourInfo = this.WarmingStatisical.DebtClassHourInfo || {};
            this.warmingMenuList[i].num = this.WarmingStatisical.DebtClassHourInfo.CountDebtClassHourNum;
            break;
          case '3':
            this.warmingMenuList[
              i
            ].num = this.WarmingStatisical.CountLeaveSchoolNum;
            break;
          case '4':
            this.warmingMenuList[
              i
            ].num = this.WarmingStatisical.ArrangeWarningNum;
            break;
          case '5':
            this.warmingMenuList[i].num = this.WarmingStatisical.ClassScheduleNum;
            break;
          case '6':
            this.WarmingStatisical.DebtAmountInfo =
              this.WarmingStatisical.DebtAmountInfo || {};
            this.warmingMenuList[i].num = this.WarmingStatisical.DebtAmountInfo.DebtAmountNum;
            break;
          default:
            break;
        }
      }
      return this.warmingMenuList;
    },
    SaaSClientInfo () { // 门店信息   要使用的字段 最早签到时间(分钟)
      return this.$store.getters.SaaSClientInfo;
    },
    modulePowerList () {
      return this.$store.getters.RolePowerList;
    },
    vToday () {
      if (this.latelyCourseInfo.CourseNameKey) {
        return (
          this.$utils.formatDatet(Date.new(this.latelyCourseInfo.ClassTime), 'MM') +
          '月' +
          this.$utils.formatDatet(Date.new(this.latelyCourseInfo.ClassTime), 'dd') +
          '日 ' +
          ' ' +
          this.$utils.formatDatet(Date.new(this.latelyCourseInfo.ClassTime), 'ddddd')
        );
      }
    },
    vTodayDateAndWeek () {
      return this.$utils.formatDateToLine(Date.new()) + ' ' + this.$utils.getWeekByTime(Date.new());
    }
  },
  watch: {

  },
  methods: {
    setSize (num) {
      if (this.integerData(num).toString().length >= 6) {
        return { 'font-size': '26px' };
      } else {
        return { 'font-size': '30px' };
      }
    },
    // 满员率变更 或者 切换headerBar
    reloadData () {
      getKQsummaryStatistics(this.kqList[0].ClassTime, this.kqList[this.kqList.length - 1].ClassTime).then(result => {
        this.kqList = result.data;
        this.kqList.forEach(o => {
          if (o.ClassTime == this.curSelectedDate) {
            this.$set(o, 'isActive', true);
          } else {
            this.$set(o, 'isActive', false);
          }
        });
        this.getCourseListForDate(this.curSelectedDate);
      });
    },
    loadData () {
      this.getPresidentReport();
      this.getWarmingStatisical();
      this.getStaffTeachingPercentage(this.token.UserID);
      this.getStaffInfo(this.token.UserID);
      if (!this.isSwitchHeaderBar) {
        this.todayTableInfoList(Date.new(), () => {
          this.$nextTick(() => {
            let locaIndex = this.kqList.findIndex(o => {
              return o.ClassTime == this.curSelectedDate;
            });
            if (locaIndex) {
              this.swiperIndex(locaIndex - 1); // 定位到当前日期.
              this.chgCoureDate(this.kqList[locaIndex]);
            }
          });
        });
      } else {
        this.reloadData();
      }
    },
    // 安排考勤中返回.
    updateCourseafter (OLAPKey, courseInfo) {
      // this.changeDay(Date.new(courseInfo.ClassTime));
      var date = Date.new(courseInfo.ClassTime);
      this.curSelectedDate = this.$utils.formatDatet(date, 'yyyy-MM-dd');
      let locaIndex = this.kqList.findIndex(o => {
        return o.ClassTime == this.curSelectedDate;
      });
      if (locaIndex) {
        // this.swiperIndex(locaIndex); // 定位到当前日期.
        this.chgCoureDate(this.kqList[locaIndex]);
      }
      console.log('updateCourseafter', OLAPKey, courseInfo);
    },
    // 更新头像.
    doSetPicCorpHeadImg (ImgURl) {
      this.staffInfo.XMLIcon = ImgURl;
    },
    // 获取员工教学信息
    getStaffTeachingPercentage (OLAPKey) {
      GetTheEmployeeTeachingPercentage(OLAPKey).then(result => {
        console.log('获取员工教学信息', result.data);
        this.staffTeaching = result.data;
        this.latelyCourseInfo = result.data.Today.RecentClass || {};
      });
    },
    // 获取员工信息.
    getStaffInfo (OLAPKey, callback) {
      GetTheEmployeeByID(OLAPKey)
        .then(result => {
          this.staffInfo = result.data;
          if (callback) {
            callback();
          }
          console.log('getStaffInfo', result.data);
        })
        .catch(error => {
          console.log('getStaffInfo', error);
        });
    },
    sumMinDate (dateTime) {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      console.log('new Date(dateTime)>=new Date(dateStr)', new Date(dateTime) >= new Date(dateStr));
      return new Date(dateTime) >= new Date(dateStr);
    },
    // 显示安排考勤
    showArrange (item) {
      console.log(item, 'showArrange11');
      // this.seletedCourseInfo = item;
      // if (this.vHasModulePower(51)) {
      this.$nextTick(() => {
        // this.$refs.newAttendanceArrangeDialog.showGetData(item.OLAPKey);
        let isAlter = this.sumMinDate(item.ClassTime);
        let moduleInfo = {
          name: '安排考勤',
          moduleName: 'attendanceArrange',
          routerName: this.$route.name,
          data: {
            scheduleCourseKey: item.OLAPKey,
            isAlter: isAlter,
            isHistory: !isAlter
          },
          ModulePowerKey: 51
        };
        this.$bus.emit('openCustomDialog', moduleInfo);
      });
      // } else {
      //   this.$utils.CheckModulePowerToTips(51);
      // }
    },
    reload () {
      this.getWarmingStatisical();
      this.getPresidentReport();
    },
    // 获取预警统计.
    getWarmingStatisical () {
      getToDoStatistics()
        .then(result => {
          this.WarmingStatisical = result.data;
          this.$store.commit('setWarmingStatisical', this.WarmingStatisical);
        })
        .catch(error => {
          console.log('getWarmingStatisical', error);
        });
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.modulePowerList, ModulePowerKey);
    },
    toWarmingDetail (item) {
      if (this.vHasModulePower(item.ModulePowerKey)) {
        item.callBack = {
          afterSucces: () => {
            this.getWarmingStatisical();
          }
        };
        console.log(item, 'item');
        this.openCustomDialogByFactoryDialog(item);
        // this.doAfterClickFunctionBtn(item.moduleName, '', '', this.reload);
      } else {
        this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
      }
    },
    overShowMore (newArr) {
      if (this.isOverShowMore) {
        this.courseList.forEach(o => {
          if (o.StatusKey == 3 || o.StatusKey == 4) {
            newArr.push(o);
          }
        });
      } else {
        this.courseList.forEach(o => {
          if (o.StatusKey == 3 || o.StatusKey == 4) {
            if (newArr.length < 5) {
              newArr.push(o);
            }
          }
        });
      }
      this.courseList.forEach(o => {
        if (o.StatusKey == 3 || o.StatusKey == 4) {
          this.showCourseList.push(o);
        }
      });
    },
    notBeginShowMore (newArr) {
      if (this.isNotBeginShowMore) {
        this.courseList.forEach(o => {
          if (o.StatusKey == 2) {
            newArr.push(o);
          }
        });
      } else {
        this.courseList.forEach(o => {
          if (o.StatusKey == 2) {
            if (newArr.length < 5) {
              newArr.push(o);
            }
          }
        });
      }
      this.courseList.forEach(o => {
        if (o.StatusKey == 2) {
          this.showCourseList.push(o);
        }
      });
    },
    chgisNotBeginShowMore (type) {
      this.isNotBeginShowAll = type;
      this.isNotBeginShowMore = type;
    },
    chgisOverShowMore (type) {
      this.isShowAll = type;
      this.isOverShowMore = type;
    },
    // 选中日期
    changeDay (date) {
      // 复制一下date.
      console.log('changeDay', date);
      date = Date.new(date);
      this.curSelectedDate = this.$utils.formatDatet(date, 'yyyy-MM-dd');
      this.todayTableInfoList(date, () => {
        let locaIndex = this.kqList.findIndex(o => {
          return o.ClassTime == this.curSelectedDate;
        });
        if (locaIndex) {
          this.swiperIndex(locaIndex); // 定位到当前日期.
          this.chgCoureDate(this.kqList[locaIndex]);
        }
      });
    },
    // 点击中考勤日期列表块触发
    chgCoureDate (item, type) {
      console.log('chgCoureDate', item.ClassTime);
      this.$refs.inputDate.pickerblur();
      this.curSelectedDate = item.ClassTime;
      this.selectedDateInfo = item;
      if (!this.isSwitchHeaderBar) {
        this.isTodayType = 1;
        this.isShowAll = false;
        this.isOverShowMore = false;
        this.isNotBeginShowMore = false;
        this.isNotBeginShowAll = false;
      }
      this.isSwitchHeaderBar = false;
      let date = item.ClassTime.replace(/-/g, '/');
      let locaIndex = this.kqList.findIndex(o => {      // 判断点击的item是否为隐藏半截的item. 是的话显示出来.
        return o.ClassTime == this.selectedDateInfo.ClassTime;
      });
      console.log('chgCoureDate', locaIndex, this.swiper.activeIndex);
      if (locaIndex >= 0 && this.swiper.activeIndex + 2 == locaIndex) {
        this.swiperIndex(locaIndex - 1);
      }
      this.getCourseListForDate(item.ClassTime, () => {
        if (date == this.$utils.formatDatet(Date.new(), 'yyyy/MM/dd')) {
          this.isToday = true;
        } else {
          this.isToday = false;
        }
        if (date > this.$utils.formatDatet(Date.new(), 'yyyy/MM/dd')) {
          this.isfutrue = true;
        } else {
          this.isfutrue = false;
        }
        if (
          Date.new(date) <
          Date.new(this.$utils.formatDatet(Date.new(), 'yyyy/MM/dd')) &&
          Number(item.HaveClassNum) + Number(item.UnderwayClassNum) ==
          Number(item.ScheduleCourseNum) &&
          this.courseList.length > 0
        ) {
          this.showAttenInfo = true;
        } else {
          this.showAttenInfo = false;
        }
        this.kqList.forEach(o => {
          if (o.ClassTime == item.ClassTime) {
            o.isActive = true;
          } else {
            o.isActive = false;
          }
        });
      });
    },
    // 获取指定日期的数据
    todayTableInfoList (date, callback) {
      if (date) {
        date = Date.new(date);
      }
      date = date || Date.new();
      this.curSelectedDate = this.$utils.formatDatet(date, 'yyyy-MM-dd');
      this.startTime = this.$utils.getNextDate(date, -6);
      this.endTime = this.$utils.getNextDate(date, 12);
      this.getKQStatisticsDateList(this.startTime, this.endTime, callback);
    },
    // 校长看板统计.
    getPresidentReport () {
      getPresidentStatistics().then(result => {
        this.presidentReport = result.data;
        console.log(this.presidentReport, 'getPresidentReport');
      });
    },
    // 初次及选中日期时触发请求的考勤统计
    getKQStatisticsDateList (startTime, endTime, callback) {
      getKQsummaryStatistics(startTime, endTime).then(result => {
        this.kqList = result.data;
        this.kqList.forEach(o => {
          this.$set(o, 'isActive', false);
        });
        if (callback) {
          callback();
        }
        console.log('考勤统计列表', result.data);
      });
    },
    // swiper往前拖动触发的考勤统计.
    getKQStatisticsBeforeList (startTime, endTime, callback) {
      getKQsummaryStatistics(startTime, endTime).then(result => {
        result.data.sort((x, y) => {
          return Date.new(y.ClassTime) - Date.new(x.ClassTime);
        });
        result.data.forEach(o => {
          o.isActive = false;
          let locationIndex = this.kqList.findIndex(p => {
            return p.ClassTime == o.ClassTime;
          });
          if (locationIndex < 0) {
            this.kqList.unshift(o);
          }
        });
        if (callback) {
          callback();
        }
      });
    },
    // swiper往后拖动触发的考勤统计.
    getKQStatisticsAfterList (startTime, endTime, callback) {
      getKQsummaryStatistics(startTime, endTime).then(result => {
        result.data.forEach(o => {
          o.isActive = false;
          let locationIndex = this.kqList.findIndex(p => {
            return p.ClassTime == o.ClassTime;
          });
          if (locationIndex < 0) {
            this.kqList.push(o);
          }
        });
        if (callback) {
          callback();
        }
        console.log('考勤统计列表', result.data);
      });
    },
    // 按天获取所有考勤课列表的信息
    getCourseListForDate (date, callback) {
      getKQsummaryForDay(date).then(result => {
        this.courseList = result.data;
        if (callback) {
          callback();
        }
        console.log('考勤按天详细统计列表', result.data);
      });
    },
    swiperInit () {
      var that = this;
      this.swiper = new Swiper('.principal-swiper-container', {
        // breakpoints: {
        //   3840: {
        //     slidesPerView: 2
        //   },
        //   1920: {
        //     slidesPerView: 2
        //   },
        //   1440: {
        //     slidesPerView: 2
        //   },
        //   1020: {
        //     slidesPerView: 2
        //   }
        // },
        slidesPerView: 2,
        spaceBetween: 5,
        // loop: false, // 循环模式选项
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swipe
        on: {
          // 拖动结束后触发事件预加载. 判断最左边隐藏的课程数据是否小于十
          slidePrevTransitionEnd: function () {
            let index = this.activeIndex; // 保存当前下标问题.
            that.curCourseIndex = this.activeIndex;
            console.log('slidePrev: that.curCourseIndex', that.curCourseIndex);
            let oldLength = Number(that.kqList.length);
            if (this.activeIndex < 14) {
              var thos = this;
              that.preCourseKey(that.kqList[0], function () {
                that.$nextTick(() => {
                  let newLength = that.kqList.length;
                  thos.activeIndex = newLength - oldLength + index; // 设定当前下标数.
                });
              });
            }
          },
          // 拖动结束后触发事件预加载. 判断最右边隐藏的课程数据是否小于十,activeIndex为内部的下标属性.
          slideNextTransitionEnd: function () {
            that.curCourseIndex = this.activeIndex;
            console.log('slideNext: that.curCourseIndex', that.curCourseIndex);
            if (that.kqList.length > 0) {
              if (that.kqList.length - this.activeIndex - 2 < 14) {
                that.nextCourseKey(that.kqList[that.kqList.length - 1], () => {
                  that.$nextTick(() => {
                    that.swiper.updateSlides();
                  });
                });
              }
            }
          }
        }
      });
      console.log(this.swiper, 'aaaa');
    },
    // 向前预加载
    preCourseKey (item, callback) {
      let date = Date.new(item.ClassTime.replace(/-/g, '/'));
      this.endTime = this.$utils.getNextDate(date, -1);
      this.startTime = this.$utils.getNextDate(date, -6);
      console.log(item, '向前预加载', this.startTime, this.endTime);
      this.getKQStatisticsBeforeList(this.startTime, this.endTime, callback);
      // this.getCourseBeforeList(ScheduleCourseKey, this.RowCount, callback);
    },
    // 向后预加载
    nextCourseKey (item, callback) {
      let date = Date.new(item.ClassTime.replace(/-/g, '/'));
      this.startTime = this.$utils.getNextDate(date, 1);
      this.endTime = this.$utils.getNextDate(date, 6);
      console.log(item, '向后预加载', this.startTime, this.endTime);
      this.getKQStatisticsAfterList(this.startTime, this.endTime, callback);
      // this.getCourseAfterList(ScheduleCourseKey, this.RowCount, callback);
    },
    swiperIndex (val) {
      this.swiper.updateSlides();
      console.log('swiperIndex', val);
      this.swiper.slideTo(val);
    },
    // 转为整数
    integerData (val) {
      val = val || 0;
      return Math.round(val);
    },
    doAfterClickFunctionBtn (moduleName, key, data, callback) {
      console.log('callback', callback);
      this.openCustomDialogByFactoryDialog({ moduleName: moduleName, dataKey: key, data: data, callBack: callback });
    },
    showMine () {
      layer.alert('开发中');
    },
    resgisterBusEvent () {
      this.$bus.on(this, 'switchHeaderBarToPresidentKanban', () => {
        this.isSwitchHeaderBar = true;
        this.$refs.attenReportTop.getAttenReport();
        this.$refs.applyPerformanceReport.loadData();
        this.$refs.classAmountReport.loadData();
        this.$refs.attenChart.loadData();
        this.$refs.todoList.loadData();
        this.loadData();
      });
      this.$bus.on(this, 'wsTodoList', (data) => {
        console.log('getTodoStatisticsByStaff');
        this.getWarmingStatisical();
      });
      this.$bus.on(this, 'updateWarmingStatisical', (data) => {
        console.log('updateWarmingStatisical');
        this.getWarmingStatisical();
      });
      this.$bus.on(this, 'wsAfterUpdateAgentInfo', (data) => {
        this.staffInfo = data;
      });
      this.$bus.on(this, 'AfterBatchAttendance', scheduleCourseKey => {
        this.getCourseListForDate(this.curSelectedDate);
      });
      this.$bus.on(this, 'AfterStudentAddToCourse', (scheduleCourseKey, studentKey) => {
        this.getCourseListForDate(this.curSelectedDate);
      });
      this.$bus.on(this, 'AfterChangeAttendanceStatus', (scheduleCourseKey, studentKey) => {
        this.getCourseListForDate(this.curSelectedDate);
      });
      this.$bus.on(this, 'allCourseQuit', (arg1, arg2, ...arr) => {
        this.todayTableInfoList(Date.new(), () => {
          this.$nextTick(() => {
            let locaIndex = this.kqList.findIndex(o => {
              return o.ClassTime == this.curSelectedDate;
            });
            if (locaIndex) {
              this.swiperIndex(locaIndex - 1); // 定位到当前日期.
              this.chgCoureDate(this.kqList[locaIndex]);
            }
          });
        });
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'presidentKanban') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    // 长连接通知
    websocketNotice (data) {
      console.log('websocketNotice', this.courseList);
      console.log('websocketNotice', data);
      let findItem = this.courseList.find(obj => { return obj.OLAPKey == data.newData[0].ScheduleCourseKey; });
      if (data.dataType == 'StudentAttendance') { // 变更考勤 找到对应课 找到对应学生 把学生替换掉
        if (findItem) {
          let findIndex = findItem.studentList.findIndex(item => { return item.StudentKey == data.StudentNameKey; });
          if (findIndex > -1) {
            findIndex.splice(findIndex, 1, data);
          }
        }
      } else if (data.dataType == 'StudentArrang') { // 安排学生 直接找到对应课 把学生追加进去
        if (findItem) {
          findItem.ArrangedNumCount += 1;
        }
      }
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.presidentKanbanDialogFactory) {
        this.$refs.presidentKanbanDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.presidentKanbanDialogFactoryCommon) {
        this.$refs.presidentKanbanDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>
