<template>
  <!-- 物品名称设置表单 -->
  <div>
    <div class="content_marginRL border_bottom">
      <!-- 内容 -->
      <div class="form_info_edit mendian_setting_content single_shadow"
           :class="{btn_marginBottom_30:true}">

        <input-text :formTitle="'物品类型'"
                    class="no_border_bottom"
                    :readonly="isReadonly"
                    :formPlaceholder="'必填'"
                    :required="true"
                    :maxLength="14"
                    v-model="dataJson.MainDemoName"></input-text>
      </div>
    </div>
    <!-- 按钮组 -->
    <div v-if="!isReadonly">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
  </div>
</template> 

<script>
import { AddGoodsType, EditGoodsType } from '../../../../../API/workbench';
export default {
  data () {
    return {
      // 数据源
      dataJson: {
        OLAPKey: '', // 物品类型id
        MainDemoName: '' // 物品类型
      }
    };
  },
  components: {},
  props: {
    dataInfo: {
      type: Object,
      default: {}
    },
    isReadonly: Boolean
  },
  created () {
    if (Number(this.dataInfo.OLAPKey) > 0) { // 修改物品类型
      this.dataJson.MainDemoName = this.dataInfo.MainDemoName || '';
      this.dataJson.OLAPKey = this.dataInfo.OLAPKey || '';
      console.log(this.dataInfo, '修改物品信息');
    }
  },
  methods: {
    // 确认按钮
    confirmClick (callBack) {
      let isWrong = this.checkSubmitData();
      if (isWrong) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (Number(this.dataJson.OLAPKey) > 0) { // 修改
        EditGoodsType(this.dataJson.OLAPKey, this.dataJson.MainDemoName, 0).then(result => {
          layer.alert('修改成功');
          this.$emit('afterSuccess');
          this.cancelClick();
        }, error => {
          if (error.code == 10002) {
            layer.confirm(error.msg + ', 是否继续?', {
              btn: [ {
                name: '确认',
                callBack: () => {
                  EditGoodsType(this.dataJson.OLAPKey, this.dataJson.MainDemoName, 1).then(result => {
                    layer.alert('修改成功');
                    this.$emit('afterSuccess');
                    this.cancelClick();
                  }, error => {
                    layer.alert(error.msg);
                  });
                  layer.close();
                }
              }
              ]
            });
          } else {
            layer.alert(error.msg);
          }
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      } else {
        AddGoodsType(this.dataJson.MainDemoName, 0).then(result => { // 新增
          layer.alert('新增成功');
          this.$emit('afterSuccess');
          this.cancelClick();
        }, error => {
          if (error.code == 10002) {
            layer.confirm(error.msg + ', 是否继续?', {
              btn: [ {
                name: '确认',
                callBack: () => {
                  AddGoodsType(this.dataJson.MainDemoName, 1).then(result => {
                    layer.alert('新增成功');
                    this.$emit('afterSuccess');
                    this.cancelClick();
                  }, error => {
                    layer.alert(error.msg);
                  });
                  layer.close();
                }
              }
              ]
            });
          } else {
            layer.alert(error.msg);
          }
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      }
    },
    // 验证要提交的数据
    checkSubmitData () {
      let flag = false;
      if (!this.dataJson.MainDemoName || !this.dataJson.MainDemoName.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('物品类型不能为空');
        flag = true;
      }
      return flag;
    },
    // 取消按钮
    cancelClick () {
      this.$emit('closeDialog');
    }
  }
};
</script>
