<template>
  <!-- 已检查列表 -->
  <div class=" table_list_content summarizing_list">
    <div class="single_result_detail">
      <!-- 搜索 -->
      <div class="table_select_box">
        <input-search-contain-btn v-model="queryParams.searchText"
                                  style="margin: 10px;"
                                  :width="'280px'"
                                  :placeholder="'请输入'"
                                  @clearSearch="clearSearch"
                                  @onSearchEvent="search"></input-search-contain-btn>
      </div>
      <!-- 通用列表 -->
      <table-list ref="tableListRef"
                  :tableData="tableDateList"
                  :tableColumns="tableColumns"
                  :rowKey="'Number'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  @loadTableData="getSearchPageNotCheckPrepareLessons"></table-list>
    </div>
    <!-- 备课检查 -->
    <custom-dialog :title="'备课检查'"
                   class="incomepay_dialog defult_dialog"
                   :visible.sync="isShowPopup"
                   :before-close="closeDialog">
      <prepare-lessons-examine :prepareLessonsKey="prepareLessonsInfo.OLAPKey"
                               :isReadonly="true"
                               @afterSuccess="submitAfter"
                               @closeDialog="closeDialog"></prepare-lessons-examine>
    </custom-dialog>
  </div>
</template>
<script>
import { hqInspectStatSearchPageCheckedPrepareLessons, SearchPageCheckedPrepareLessons } from '../../../../../../API/workbench.js';
import inputSearchContainBtn from '../../../../../report/common/input-search-contain-btn';
import tableList from '../../../../../common/table-list/index';
import prepareLessonsExamine from '../../../../campus-prepare-lessons-examine/form/prepare-lessons-examine';
export default {
  data () {
    return {
      // 查询参数
      queryParams: {
        pageIndex: 0, //	整型	可选		数据源：GET	页码
        pageSize: 10, //	整型	可选		数据源：GET	分页大小
        searchText: '', //	字符串	可选		数据源：GET	关键字
        StartDate: this.$utils.getCurrentMonthFirst(), //	字符串	可选		数据源：GET	开始日期
        EndDate: this.$utils.getCurrentMonthLast(), //	字符串	可选		数据源：GET	结束日期
        TeacherKey: '', //	整型	可选		数据源：GET	老师id
        SchoolKey: '', //	整型	可选		数据源：GET	校区id
        SchoolKeyValue: ''

      },
      tableDateList: [], // 门店列表
      isShowPopup: false, // 备课检查
      prepareLessonsInfo: {}, // 备课信息
      tableColumns: [
        {
          label: '备课时间',
          prop: 'PrepareLessonsTimeText',
          width: 160,
          align: 'left',
          sortable: false,
          type: 'date-item',
          extend: {
            dateType: 'YYMMRR HH:DD' // 日期类型
          }
        },
        {
          label: '课件名',
          prop: 'newCourseWareNameText',
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '提前量',
          prop: 'newAdvanceDayCount',
          width: 70,
          align: 'left',
          type: 'text-item',
          sortable: false
        },

        {
          label: '评分',
          prop: 'RummageScoreCount',
          classIconName: 'star_dynamic_list opt',
          width: 150,
          align: 'left',
          type: 'icon-item',
          sortable: false
        },
        {
          label: '检查反馈',
          prop: 'RummageFeedBack',
          width: 200,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          width: 80,
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  click: (rowData) => {
                    this.examineBtnEvent(rowData);
                  }
                }
              }

            ]
          }
        }
      ],
      pageTotal: 0,
      totalNum: 0,
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  components: {
    inputSearchContainBtn,
    tableList,
    prepareLessonsExamine
  },
  props: {
    paramsObj: Object
  },
  computed: {
    vTypeKey () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) { // 总部key为6
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    paramsObj: {
      // 深度监听该对象
      handler: function (c, o) {
        console.log(' paramsObj', c, o);
        this.queryParams.pageIndex = 0;
        this.queryParams.pageSize = 10;
        this.queryParams.StartDate = c.StartDate;
        this.queryParams.EndDate = c.EndDate;
        this.queryParams.TeacherKey = c.TeacherKey;

        this.queryParams.SchoolKey = c.SchoolKey;
        if (c.TeacherKey) {
          this.getSearchPageNotCheckPrepareLessons(this.queryParams);
        }
      },
      deep: true
    }
  },
  created () {
    this.queryParams.StartDate = this.paramsObj.StartDate;
    this.queryParams.EndDate = this.paramsObj.EndDate;
    this.queryParams.TeacherKey = this.paramsObj.TeacherKey;
    this.queryParams.SchoolKey = this.paramsObj.SchoolKey;
    if (this.queryParams.TeacherKey) {
      this.getSearchPageNotCheckPrepareLessons(this.queryParams);
    }
  },
  methods: {
    // 门店记录列表
    getSearchPageNotCheckPrepareLessons (queryParams) {
      queryParams.searchText = queryParams.searchText.trim();
      var apiFN = (this.vTypeKey ? hqInspectStatSearchPageCheckedPrepareLessons : SearchPageCheckedPrepareLessons);
      console.log('apiFN', this.vTypeKey, apiFN);
      apiFN(queryParams).then(result => {
        console.log('门店记录列表', result.data);
        this.totalNum = result.data.Total; // 分页数
        this.tableDateList = result.data.PageDataList || [];
        if (this.tableDateList.length > 0) {
          this.tableDateList.forEach(o => {
            o.PrepareLessonsTimeText = o.PrepareLessonsTime.slice(0, 16);
            // o.newCourseWareNameText = o.CourseWareName + `<br/><span class="form_dual_text">${o.ParentKeyValue?o.ParentKeyValue+' - ':''}${o.CourseWarePackageKeyValue}</span>`;
            o.newCourseWareNameText = o.CourseWareName + `<br/><span class="form_dual_text">${o.ParentKeyValue ? o.ParentKeyValue + ' - ' : ''}${o.AuthContent}</span>`;
            o.newAdvanceDayCount = (Number(o.AdvanceDayCount) == 1 ? `<span class="font_red">${Number(o.AdvanceDayCount)}</span>` : Number(o.AdvanceDayCount)) + '天';
            o.RummageFeedBack = this.$utils.insertStr(o.RummageFeedBack, 12, `<br/>`);
          });
        }
        // 获取动态老师排名列表滚动高度
        this.$nextTick(() => {
          if (this.$refs.tableListRef && this.$refs.tableListRef.$el.clientHeight > 0) {
            console.log(' 老师课评排名高度', this.$refs.tableListRef.$el.clientHeight);
            this.$emit('teacherListScrollHeight', this.$refs.tableListRef.$el.clientHeight);
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 操作按钮
    examineBtnEvent (rowData) {
      this.prepareLessonsInfo = rowData;
      this.isShowPopup = true;
      console.log(this.prepareLessonsInfo, '操作按钮');
    },
    // 搜索
    search (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.queryParams.pageIndex = 0;
      this.getSearchPageNotCheckPrepareLessons(this.queryParams);
    },
    // 清除条件
    clearSearch () {
      this.queryParams.pageIndex = 0;
      this.queryParams.searchText = '';
      this.getSearchPageNotCheckPrepareLessons(this.queryParams);
    },
    // 排序
    loadTableData (queryParams) {
      console.log(queryParams, '排序');
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.getSearchPageNotCheckPrepareLessons(this.queryParams);
    },
    submitAfter () {
      this.getSearchPageNotCheckPrepareLessons(this.queryParams);
      this.closeDialog();
    },
    closeDialog () {
      this.isShowPopup = false;
    }
  }
};
</script>
<style>
</style>

