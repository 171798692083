<template>
  <div class="details_content_number"
       :class="{font_gray:dateInfo.unArranged,opt:vHasSelected,border:vHasArranged}">
    <div @click.stop="clickItem"
         class="date_number">
      <span>{{dateInfo.dateName}}</span>
      <div class="details_content_number_icon"
           v-if="dateInfo.courseList.length>1">{{dateInfo.courseList.length}}</div>
    </div>
    <!-- 弹出层 -->
    <div v-if="isShowPopUp">
      <div class="shade"
           @click.stop="closePopUp"></div>
      <div class="course_number_details_pop courses_alter_popup">
        <div class="class_common_list clearfix">
          <div class="list_icon icon_yellow fl">课</div>
          <div class="list_text fl">
            <div>{{courseItem.CourseNameKeyValue}}</div>
            <div class="font_gray">{{vTimeRange}}</div>
          </div>
          <div class="pop_right fr">已排{{vHasArrangedNum}}节课</div>
        </div>
        <div class="list_ul"
             style="padding-bottom:0.2rem">
          <teacher-item v-for="(item,index) in dateInfo.courseList"
                        :key="index"
                        :courseInfo="item"
                        :dateInfo="dateInfo"
                        @clickTeacher="doAfterClickTeacher"></teacher-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import teacherItem from './teacher-item';
export default {
  data () {
    return {
      isShowPopUp: false
    };
  },
  props: {
    dateInfo: Object,
    index: Number,
    courseItem: Object
  },
  components: {
    teacherItem
  },
  computed: {
    vTimeRange () {
      let timeRange =
        this.$utils.formatDateToLine(
          Date.new(this.dateInfo.courseList[0].ClassTime)
        ) +
        ' ' +
        this.dateInfo.courseList[0].SClassTimeName +
        '-' +
        this.dateInfo.courseList[0].EClassTimeName;
      return timeRange;
    },
    vHasArrangedNum () {
      let num = 0;
      if (this.isShowPopUp) {
        this.dateInfo.courseList.forEach(obj => {
          if (obj.hasSelected || (obj.hasArranged && !obj.cancelArranged)) {
            num += 1;
          }
        });
      }
      return num;
    },
    vCancelArranged () {
      let bool = false;
      this.dateInfo.courseList.forEach(obj => {
        if (obj.hasArranged && obj.cancelArranged && !obj.hasSelected) {
          bool = true;
        } else {
          bool = false;
        }
      });
      return bool;
    },
    vHasSelected () {
      let flag = false;
      this.dateInfo.courseList.forEach(obj => {
        if (obj.hasSelected) {
          flag = true;
        }
      });
      return flag;
    },
    vHasArranged () {
      let flag = false;
      this.dateInfo.courseList.forEach(obj => {
        if (obj.hasArranged && !obj.cancelArranged) {
          flag = true;
        }
      });
      return flag;
    }
  },
  created () { },
  methods: {
    clickItem () {
      // 可安排的才可点击
      if (!this.dateInfo.unArranged) {
        // 一个日期可能包含多节课
        if (this.dateInfo.courseList && this.dateInfo.courseList.length > 1) {
          this.isShowPopUp = true;
        } else {
          this.dateInfo.courseList.forEach(obj => {
            // 已排
            if (obj.hasArranged && (obj.cancelArranged || !obj.cancelArranged)) {
              obj.cancelArranged = !obj.cancelArranged;
            } else {
              obj.hasSelected = !obj.hasSelected;
            }
          });
          this.$emit('clickOneDate', this.dateInfo);
        }
      }
    },
    closePopUp () {
      this.isShowPopUp = false;
    },
    doAfterClickTeacher () {
      this.$emit('clickItem', this.dateInfo);
      this.closePopUp();
    },
    cancelClick () {
      this.closePopUp();
    }
  }
};
</script>
<style>
</style>