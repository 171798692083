<template>
  <div>
    <div class="form_info_edit content_marginTop_30 form_info_list single_shadow content_marginRL pr">
      <!-- 85折输入8.5 -->
      <input-number-unit :min="0.1"
                         :max="10"
                         v-model="discountCount"
                         :decimalPlaces="1"
                         :formTitle="'打折优惠'"
                         :unit="'折'"
                         :placeholder="'必填'"
                         :required="true"
                         @inputChange="doAfterChangeDiscount"></input-number-unit>

      <!-- <div class="full_class_box form_info_required"
           style="border-bottom: 1px solid #ececec;">
        <div class="full_class_text">打折优惠</div>
        <div class="full_input_box">
          <input type="number"
                 min="0.1"
                 max="10"
                 v-model="discountCount"
                 @change="doAfterChangeDiscount($event.target.value,$event)"
                 class="full_class_input">
          <span class="people_class">折</span>
        </div>
      </div> -->
      <div class=" form_info_line">
        <div class=" form_info_field">免费赠送</div>
        <div class=" form_info_value ">
          <el-switch v-model="isFree"
                     active-color="#49b5f3"
                     inactive-color="#a5b6c8"
                     active-value="1"
                     inactive-value="0">
          </el-switch>
        </div>
      </div>
      <!-- <div class="form_info_paL pa">{{'10代表原价，0代表赠送'}}</div> -->
    </div>
    <save-cancel-btn-group @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>

  </div>
</template>

<script>
export default {
  data () {
    return {
      discountCount: 0,
      isFree: '0'
    };
  },
  props: {
    value: [String, Number]
  },
  watch: {
    // 优惠金额
    'isFree': {
      handler (newValue, oldValue) {
        if (newValue == '0' && this.discountCount == 0) {
          this.discountCount = 10;
        }
      }
      // deep: true
    }
  },
  computed: {
    vTips () {
      let tip = '';
      if (this.discountCount == 10) {
        tip = '原价';
      } else if (this.discountCount == 0) {
        tip = '赠送';
      } else if (this.discountCount < 10 && this.discountCount > 0) {
        tip = this.discountCount + '折';
      }
      return tip;
    }
  },
  created () {
    this.discountCount = this.value;
    this.isFree = Number(this.value) == 0 ? '1' : '0';
    console.log('this.isFree', this.isFree, this.value);
  },
  methods: {
    confirmClick () {
      if (this.isFree == '1') {
        this.discountCount = 0;
      }
      this.$emit('onConfirm', this.discountCount);
    },
    cancelClick () {
      this.discountCount = this.value;
      this.$emit('input', this.value);
      this.$emit('onClose', this.value);
    },
    doAfterChangeDiscount (val) {
      console.log('doAfterChangeDiscount', this.discountCount);
      //   this.discountCount = val;
    }
  }
};
</script>

<style scoped>
.full_class_box {
  display: flex;
  justify-content: space-between;
  height: 47px;
  margin-top: 0px;
}

.full_class_input {
  border: 1px solid #ccc;
  width: 60px;
  text-align: center;
  border-radius: 3px;
  color: #3498db;
}

.full_class_text {
  font-size: 14px;
  color: #666;
  line-height: 45px;
}
.full_class_text span {
  font-size: 10px !important;
  color: #ccc !important;
}
.full_input_box {
  margin-top: 12px;
}
</style>