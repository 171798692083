<template>
  <div class=" statistics_echarts_right fl ">
    <div class="apply_payment_box_title ">
      {{vFormTitle}}
      <span>{{vYear}}</span>
    </div>
    <div class="apply_payment_box_singular box_dot dot_green">
      签到人次 <span class="font_big">{{CQRS}}</span> 人 ; 出勤率 <span>{{ATTS}}</span>%
    </div>
    <div class="apply_payment_box_singular box_dot dot_blue"
         v-if="this.$utils.getSaaSClientSet(59)>0">
      请假 <span>{{QJRS}}</span> 人 ; 旷课 <span>{{KKRS}}</span> 人
    </div>
    <div class="apply_payment_box_singular box_dot dot_blue"
         v-else>
      请假 <span>{{QJRS}}</span> 人 ; 旷课 <span>{{KKRS}}</span> 人
    </div>
    <div class="apply_payment_box_singular box_dot dot_yellow">
      已上 <span>{{YSJK}}</span> 节课；未考勤 <span>{{WKQRS}}</span> 人
    </div>
    <div class="box_contrast"
         v-if="CQRS-preCQRS<0">
      <img class="fl "
           src="../../../../public/image/contrast_down.png"
           alt=" ">
      <div class="box_contrast_data fl ">
        <div class="data_number"><span v-if="increaseRate=='--'?false:true">-</span>{{increaseRate}}</div>
        <div>环比下降</div>
      </div>
    </div>
    <div class="box_contrast "
         v-else>
      <img class="fl "
           src="../../../../public/image/contrast_up.png"
           alt=" ">
      <div class="box_contrast_data fl ">
        <div class="data_number">{{increaseRate}}</div>
        <div>环比增长</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'nss',
  data () {
    return {

    };
  },
  props: ['formTitle', 'nowDataList', 'oldDataList', 'type', 'seletedDate'],
  computed: {

    CQRS () {     // 签到人数
      let CQRS = 0;
      this.nowDataList.forEach(o => {
        CQRS += Number(o.Attence3);
      });
      return CQRS || 0;
    },
    WKQRS () {      // 未考勤人数
      let WKQRS = 0;
      this.nowDataList.forEach(o => {
        WKQRS += Number(o.Attence2);
      });
      return WKQRS || 0;
    },
    QJRS () {     // 请假人数
      let QJRS = 0;
      this.nowDataList.forEach(o => {
        QJRS += Number(o.Attence4);
      });
      return QJRS || 0;
    },
    KKRS () {     // 旷课人数
      let KKRS = 0;
      this.nowDataList.forEach(o => {
        KKRS += Number(o.Attence5);
      });
      return KKRS || 0;
    },
    ATTS () {     // 出勤率
      let KKRS = 0;
      let TotalPerson = 0;
      this.nowDataList.forEach(o => {
        KKRS += Number(o.Attence3);
        TotalPerson += Number(o.TotalPerson);
      });
      let atts = TotalPerson == 0 ? 0 : (KKRS / TotalPerson) * 100;
      console.log('KKRS' + KKRS + 'TotalPerson' + TotalPerson + 'atts' + atts);
      return atts == 0 ? atts : atts < 1 ? atts.toFixed(2) : atts.toFixed(0);
    },
    YSJK () {     // 已上节数
      let YSJK = 0;
      this.nowDataList.forEach(o => {
        YSJK += Number(o.HavedClass);
      });
      return YSJK || 0;
    },
    preCQRS () {      // 上个月总人数
      let CQRS = 0;
      this.oldDataList.forEach(o => {
        CQRS += Number(o.Attence3);
      });
      return CQRS || 0;
    },
    // 环比增长率=（本期的某个指标的值-上一期这个指标的值）/上一期这个指标的值*100%。
    increaseRate () {
      if (this.preCQRS == 0 && this.CQRS > 0) {
        return '100%';
      } else if (this.preCQRS >= 0 && this.CQRS == 0) {
        return '---';
      } else {
        return parseInt(Math.abs((this.CQRS - this.preCQRS) / this.preCQRS) * 100) + '%';
      }
      // return parseInt(Math.abs((this.CQRS - this.preCQRS) / this.preCQRS) * 100) || 0;
    },
    vYear () {
      if (this.type == 'month') {
        if (this.nowDataList.length <= 0) {
          return Date.new().getFullYear() + '年' + (Date.new().getMonth() + 1) + '月';
        } else {
          return Date.new(this.nowDataList[0].SetUpTime).getFullYear() + '年' + (Date.new(this.nowDataList[0].SetUpTime).getMonth() + 1) + '月';
        }
      } else {
        if (this.nowDataList.length <= 0) {
          return Date.new().getFullYear() + '年';
        } else {
          return Date.new(this.nowDataList[0].SetUpTime).getFullYear() + '年';
        }
      }
    },
    vFormTitle () {
      if (this.type == 'month') {
        if (this.seletedDate.getFullYear() == Date.new().getFullYear() && this.seletedDate.getMonth() == Date.new().getMonth()) { return this.formTitle; }
      } else {
        if (this.seletedDate.getFullYear() == Date.new().getFullYear()) { return this.formTitle; }
      }
    }
  }
};
</script>
