<template>
  <!-- 学生作品模块 -->
  <div class="works_management">
    <div class="table_fixed_nav_box">
      <div class="table_fixed_nav tltle_margin">
        <div @click="showRecommendedWorksClick"
             class="table_fixed_nav_list"
             :class="{opt:showManagement=='recommendedWorks'}">推荐作品</div>
        <div @click="showExquisiteWorksClick"
             class="table_fixed_nav_list"
             :class="{opt:showManagement=='exquisiteWorks'}">精选作品</div>

        <div class="header_bottom"
             v-if="showManagement=='recommendedWorks'">
          <function-btn :item="{ name: '推荐作品', ModulePowerKey: '' }"
                        :className="'el-button btn_light_blue btn_hover_bg_blue buttom_sq'"
                        @clickItem="doOperateItemClick">
          </function-btn>
        </div>
      </div>
    </div>
    <div>
      <!-- 推荐作品 -->
      <div class="showManagement"
           v-if="showManagement=='recommendedWorks'">
        <div v-for="item,index in maxWorksUploadList"
             :key="index">
          <recommended-works :dataInfo="item"
                             :ShowOrder="index"
                             :showManagement="showManagement"
                             @afterSuccess="afterSuccess"></recommended-works>
        </div>
      </div>
      <!-- 精选作品 -->
      <div class="showManagement"
           v-else-if="showManagement=='exquisiteWorks'">
        <div v-for="item,index in maxWorksUploadList"
             :key="index">
          <exquisite-works :dataInfo="item"
                           :showManagement="showManagement"
                           :ShowOrder="index"></exquisite-works>
        </div>
      </div>

    </div>
    <div v-if="maxWorksUploadList.length == 0"
         style="margin-top: 90px;">
      <div class="right_nodata">亲，当前还没有任何数据哦~</div>
    </div>
    <div>
      <dialog-factory ref="worksUploadManagementDialog"
                      :key="'worksUploadManagement'"
                      :routerName="'worksUploadManagement'"></dialog-factory>
      <dialog-factory-common ref="worksUploadManagementCommonDialog"></dialog-factory-common>
    </div>
    <custom-dialog :title="'推荐作品'"
                   :visible.sync="isShowRecommendWorksPopup"
                   :before-close="closeRecommendWorksPopup">
      <recommend-works-popup @close="closeRecommendWorksPopup"
                             @afterSuccess="afterSuccess"></recommend-works-popup>
    </custom-dialog>
  </div>
</template>
<script>
import { GetRecommendWorksListForPC, GetSelectedWorksListPC } from '../../API/workbench';
import recommendedWorks from './recommended-works/index.vue';
import exquisiteWorks from './exquisite-works/index.vue';
import recommendWorksPopup from './recommend-works-popup';
import dialogFactory from '../common/dialog-factory';
export default {
  name: 'worksUploadManagement',
  data () {
    return {
      showManagement: 'recommendedWorks',
      isShowRecommendWorksPopup: false,
      maxWorksUploadList: []
    };
  },
  components: {
    recommendedWorks,
    exquisiteWorks,
    recommendWorksPopup,
    dialogFactory
  },
  created () {
    this.registerBusEvent();
    this.getWorksList();
    console.log(this.$store.getters, 'this.$store.getters.SaaSClientKey');
  },
  mounted () {

  },
  methods: {
    getWorksList () {
      let fn = GetSelectedWorksListPC;
      if (this.showManagement == 'recommendedWorks') {
        fn = GetRecommendWorksListForPC;
      } else {
        fn = GetSelectedWorksListPC;
      }
      fn().then(result => {
        this.maxWorksUploadList = result.data;
        console.log('查询上传作品', result.data);
      }, error => {
        layer.alert(error.msg);
      });
    },
    afterSuccess () {
      this.getWorksList();
      this.closeRecommendWorksPopup();
    },
    // 关闭推荐作品
    closeRecommendWorksPopup () {
      this.isShowRecommendWorksPopup = false;
    },
    // 打开推荐作品
    doOperateItemClick () {
      this.isShowRecommendWorksPopup = true;
    },
    // 切换门店列表
    showRecommendedWorksClick () {
      this.showManagement = 'recommendedWorks';
      this.getWorksList();
    },
    // 切换管理历史记录列表
    showExquisiteWorksClick () {
      this.showManagement = 'exquisiteWorks';
      this.getWorksList();
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        console.log('openCustomDialog', dialogInfo);
        if (dialogInfo.routerName == 'worksUploadManagement') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'worksUploadManagement') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.worksUploadManagementDialog) {
        this.$refs.worksUploadManagementDialog.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.worksUploadManagementCommonDialog) {
        this.$refs.worksUploadManagementCommonDialog.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }

  },
  computed: {}
};
</script>
<style scoped>
/* 查看作品的背景颜色 */
.works_management
  >>> .custom-dialog-factory
  .popup_dialog_bg
  .popup_dialog_box {
  background: #000 !important;
}
.works_management .tltle_margin {
  margin: 0 auto;
}
.showManagement {
  margin: 0 auto;
  width: 1340px;
}
.works_management {
  margin-bottom: 0px;
  margin-top: 68px;
}
.table_fixed_nav {
  width: 1340px !important;
}
.table_fixed_nav_list {
  padding: 0;
  width: 155px;
}
.header_bottom {
  position: absolute;
  margin-left: 1255px;
}
.right_nodata {
  height: 745px;
  font-size: 14px;
  line-height: 18px;
  color: #a9aaaa;
  text-align: center;
  padding-top: 430px;
  /* position: absolute;
  top: 160px;
  left: 0;
  right: 0; */
  background: #fff url(../../../public/image/courseware_no_data.png) no-repeat
    center center;
  width: 1340px;
  margin: 0 auto;
  border-radius: 10px;
  z-index: -1;
}
.showManagement div:nth-of-type(4n-2) >>> .max_courseware .courseware_list {
  margin: 20px 15px !important;
}
.showManagement div:nth-of-type(4n-1) >>> .max_courseware .courseware_list {
  margin: 20px 15px !important;
}
.showManagement div:nth-of-type(4n) >>> .max_courseware .courseware_list {
  float: right !important;
  margin: 20px 3px !important;
}
</style>