<template>
  <div>
    <div v-if="couponList.length>0"> 
      <coupon-item v-for="(item,index) in vShowCouponList"
                   :key="index"
                   :Item="item"
                   :Index="index"
                   :isView="false"
                   :isShowbtn="isShowbtn"
                   :isBrowse="isBrowse"
                   :dataList="couponList"
                   :selectionKey="selectionKey"
                   @doSelectCouponAdded="doSelectCouponAdded"
                   @edit="doEditCoupon"></coupon-item>
      <div class="staff_dimission_btn" v-if="vUnusableCouponList.length>0&&isBrowse" @click="isShowAll=true"><span :style="{background: isShowAll?'white':''}">{{vBottomTips}}</span></div>
      
    </div>
    <div v-if="couponList.length==0 &&!isShowbtn">
      <div class="monitoring_content_nodata"
           style="margin-bottom: 120px;margin-top: 60px;">
        <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
      </div>
    </div>
  </div>
</template>
<script>
import couponItem from './coupon-item';
export default {
  components: {
    couponItem
  },
  data () {
    return {
      isShowAll: ''
    };
  },
  props: {
    couponList: Array,
    isShowbtn: {
      type: Boolean,
      default: true
    },
    isBrowse: {
      type: Boolean,
      default: true
    },
    selectionKey: Number
  },
  created () {
    this.couponList.sort((a, b) => { return Date.new(b.time_start).getTime() - Date.new(a.time_start).getTime(); });
  },
  computed: {
    vBottomTips () {
      let str = '已显示全部';
      if (!this.isShowAll) {
        str = '显示不可用的券';
      }
      return str;
    },
    vUsableCouponList () { // 可用的优惠券列表
      return this.couponList.filter(obj => { return !obj.unSelection; });
    },
    vUnusableCouponList () { // 不可用的优惠券列表
      return this.couponList.filter(obj => { return obj.unSelection; });
    },
    vShowCouponList () { // 显示在界面的优惠券列表
      if (this.isShowAll || !this.isBrowse) {
        return this.vUsableCouponList.concat(this.vUnusableCouponList);
      } else {
        return this.vUsableCouponList;
      }
    }
  },
  watch: {
    vUnusableCouponList (n, o) {
      if (n.length > 0) {
        this.isShowAll = false;
      } else {
        this.isShowAll = false;
      }
    }
  },
  methods: {
    // 上架
    doSelectCouponAdded (Item) {
      this.$emit('doSelectCouponAdded', Item);
    },
    // 修改券信息
    doEditCoupon (Item) {
      this.$emit('doEditCoupon', Item);
    }
  }
};
</script>

