<template>
  <div class="swiper-slide courseware_packge_list_item"
       @click.stop="goDetailPage">
    <!-- @mouseover.stop="keepShow"
           @mouseleave="closeShow" -->
    <div class="courseware_packge_list_item_bg"></div>
    <img :src="vImg"
         alt="">
    <!-- https://cdnzxbeta.zhixuerj.com/Upload/Resource/10017/Image/20230129204308.png -->
    <div class="courseware_packge_list_item_bottom_name"
         v-if="!showOptionBtn">
      {{item.MainDemoName}}
    </div>
    <div class="courseware_packge_list_item_bottom no_padding"
         v-else>
      <div class="bottom_btn"
           @click.stop="goTeachingView"
           :style="vShowClassGoBtn?'margin-left:9.75rem;':''">快捷上课</div>
      <div class="bottom_btn"
           v-if="vShowClassGoBtn"
           @click.stop="seletedClassGo"
           style="margin-left: 0rem;">选班上课</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      showOptionBtn: false
    };
  },
  props: {
    item: Object,
    dataInfo: Object,
    seletedOLAPKey: [Number, String]  //选中id
    //  {
    //     'CoursewarePackageKey':'课包key',
    //     'CoursewarePackageKeyValue':'课包keyValue',
    //     'ParentKey':'父级',
    //     'TypeKey':'类型0子类;1父类;2目录',
    //     'ISAuthTeacher':'是否课包老师授权:1-是;0-否;',
    //     'IsLockKey':'是否释放:1-已释放;0-未释放',
    //     'BigCoursewarePackageCoverUrl':'大课包封面路径',
    //     'ShowOrder':'子课包序号',
    // },
  },

  computed: {
    vImg () {
      if (this.item.CoverUrl) {
        if (this.item.CoverUrl.indexOf("http") > -1) {
          return this.item.CoverUrl;
        } else {
          return (
            this.$store.getters.CDNURL + this.item.CoverUrl
          );
        }
      } else {
        return "";
      }
    },
    vShowClassGoBtn () {
      return this.$store.getters.SaaSClientEditionKey == 6
    },
    // 判断是校长还是老师
    vRoleKey () {
      console.log(Number(this.$store.getters.token.RoleKey), "校长and老师");
      return this.$store.getters.token.RoleKey ? Number(this.$store.getters.token.RoleKey) == 2 : false; // 2为校长；非则为老师
    }
  },
  methods: {
    goDetailPage () {
      console.log("goDetailPage", this.item)
      this.$router.push({ name: 'teachingModeCoursewareDetail', query: { CoursewareID: this.item.OLAPKey } });

    },
    keepShow () {
      this.showOptionBtn = true
    },
    closeShow () {
      this.showOptionBtn = false
    },
    goTeachingView () {
      if (this.$zxClient && this.$zxClient.IsClient) {
        console.log("goTeachingView", this.dataInfo, this.seletedOLAPKey)
        this.$emit("goTeachingView", this.item)
      } else {
        layer.alert('请用桌面端打开程序', {
          mode: 'teachingMode'
        });
      }
    },

    seletedClassGo () {
      if (this.$zxClient && this.$zxClient.IsClient) {
        this.$emit('chooseCourseForClass', this.item);
      } else {
        layer.alert('请用桌面端打开程序', {
          mode: 'teachingMode'
        });
      }

    }
  }
};
</script>
<style scoped>
.courseware_packge_list_item {
  width: 156.25rem !important;
  height: 117.19rem;
  margin-left: 10.5rem;
  position: relative;
  /* border: 0.78rem solid rgba(255, 255, 255, 0.4); */
  border-radius: 7.03rem;
  overflow: hidden;
  display: inline-block;
  background: #a9a9a9;
  margin-bottom: 10.92rem;
  margin-right: 4.8rem;
}
.courseware_packge_list_item:hover {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #2afea9 0.01%, #1c4ef4 102.48%);
  border: 1.17rem solid #ffffff;
  box-shadow: 0px 1.95rem 7.8rem rgba(22, 131, 249, 0.7);
  border-radius: 7.03rem;
  cursor: pointer;
}
.courseware_packge_list_item .courseware_packge_list_item_bg {
  /* background: linear-gradient(
    225.87deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 90.8%
  ); */
  /* opacity: 0.35; */
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('../../../../public/image/coureware_bg_highlight.png') center
    no-repeat;
  background-size: 100%;
}
.courseware_packge_list_item_bg.is_drak::after {
  display: block;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #000000, #000000),
    linear-gradient(180deg, #b94aff 0.01%, #6a20fe 102.48%);
  opacity: 0.5;
}
.courseware_packge_list_item img {
  width: 100%;
  /* height: 87%; */
  /* background: linear-gradient(225.87deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 90.8%);
        opacity: 0.35; */
}
.courseware_packge_list_item_bottom_name {
  width: 100%;
  height: 31.6rem;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1.17rem 2.34rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(7.5px);
  border-radius: 4.68rem;
  position: absolute;
  bottom: 0px;
  font-weight: 400;
  font-size: 9.36rem;
  line-height: 31.6rem;
  color: #ffffff;
  padding-left: 8.85rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  /* text-align: center; */
}
.courseware_packge_list_item_bottom {
  width: 100%;
  height: 31.6rem;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1.17rem 2.34rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(7.5px);
  border-radius: 4.68rem;
  position: absolute;
  bottom: 0px;
  font-weight: 400;
  font-size: 9.36rem;
  line-height: 31.6rem;
  color: #ffffff;
  padding-left: 8.85rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
}
.courseware_packge_list_item_bottom.no_padding {
  padding: 0;
}
.bottom_btn {
  width: 63.57rem;
  height: 21.06rem;
  /* background: rgba(255, 255, 255, 0.35); */
  border: 0.78rem solid #ffffff;
  border-radius: 39rem;
  margin: auto;
  line-height: 19rem;
  text-align: center;
  font-size: 8.36rem;
}
.bottom_btn:hover {
  background: rgba(255, 255, 255, 0.35);
}
</style>
