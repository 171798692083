<template>
  <div>
    <div class="course_statistics_table_search flex course_statistics_title">
      <date-range ref="saveDate"
                  :searchObj="searchObj"
                  :fromTitle="'选择日期'"
                  @changeDate="changeSaveDate"></date-range>
      <!--  :minDate="vMinDate"
                    :maxDate="vMaxDate"
                    :isShowHistory="isShowHistory" -->
      <div class="course_statistics_search">

        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入学生名'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class=" table_list_content">
      <tableList class="summarizing_list"
                 :key="tableKey"
                 :tableData="vdataReportList"
                 :totalNum="totalNum"
                 :PageCount="PageCount"
                 :tableColumns="tableCourseColumns"
                 :isSearchText="false"
                 :isNeedInnerScroll="false"
                 :defaultSort="defaultSort"
                 :queryParams="searchObj"
                 :vFooterTotal="vFooterTotal"
                 @loadTableData="getDataList"></tableList>
    </div>
  </div>
</template>
<script>
import tableList from '../../../../common/table-list/index';
import inputSearchContainBtn from '../../../common/input-search-contain-btn';
import { GetSettlementsStudentList } from '../../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0)),

        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          className: 'table_index_gray'
        },
        {
          label: '学生名',
          prop: 'MainDemoName',
          width: ' ',
          minWidth: 90,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '手机号',
          prop: 'CustomerPhoneName',
          minWidth: 92,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },
        {
          label: '结转总额',
          prop: 'SettlementsNum',
          minWidth: 120,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '结转课消',
          prop: 'SettlementsClassHourAmountValue',
          minWidth: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            click: (rowData) => {
              // if (rowData.TrialTime) {
              this.seletedItem = rowData;
              this.changeType(rowData, 2, 'SettlementsClassHourAmountValue');
              console.log('触发');
              // }
            }

          }
        },
        {
          label: '结转天数',
          prop: 'SettlementsDayAmountValue',
          minWidth: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            click: (rowData) => {
              // if (rowData.TrialTime) {
              this.seletedItem = rowData;
              this.changeType(rowData, 3, 'SettlementsDayAmountValue');
              console.log('触发');
              // }
            }

          }
        },
        {
          label: '异常结转',
          prop: 'ExceptionalAmountValue',
          minWidth: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            click: (rowData) => {
              // if (rowData.TrialTime) {
              this.seletedItem = rowData;
              this.changeType(rowData, 4, 'ExceptionalAmountValue');
              console.log('触发');
              // }
            }

          }
        },
        {
          label: '销售',
          prop: 'AdviserKeyValue',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',

        },
        {
          label: '最近上课',
          prop: 'LastSignTime',
          sortable: false,
          minWidth: 140,
          align: 'left',
          isShow: true,
          type: 'text-item',
        },

      ],

      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
  },
  props: {
    toSearchObj: {
      type: Object
    },
    searchTime: Object,
    searchText: String,
    schoolItem: Object
  },
  created () {
    console.log(this.toSearchObj, 'this.toSearchObj');
    this.schoolKey = this.$route.query.schoolKey || 0
    this.searchObj.searchText = this.searchText || ''
  },
  watch: {
    searchTime: {
      handler (c, o) {
        if (c) {
          //  this.getDateRangeCoursewareStat( this.$utils.getCurrentMonthFirst(),this.$utils.getCurrentMonthLast())
          setTimeout(o => {
            console.log("searchTime", this.searchObj.searchText)
            this.searchObj.startTime = c.startTime
            this.searchObj.endTime = c.endTime
            this.getDataList();
          }, 100)
        }
      },
      immediate: true,
      deep: true
    },
    'schoolItem.OLAPKey': {
      handler (c, o) {
        if (c) {
          console.log('schoolItem.OLAPKey', this.schoolItem.OLAPKey)
          //  this.getDateRangeCoursewareStat( this.$utils.getCurrentMonthFirst(),this.$utils.getCurrentMonthLast())
          setTimeout(o => {
            this.getDataList();
          }, 100)
        }
      },
    }

  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.SettlementsNum = '￥' + Number(Number(o.SettlementsNum).toFixed(2))
        o.SettlementsClassHourAmountValue = Number(o.SettlementsClassHourCount) == 0 ? `-` : `<span class="font_blue hover">` + Number(Number(o.SettlementsClassHourCount).toFixed(2)) + `课时/￥` + Number(Number(o.SettlementsClassHourAmount).toFixed(2)) + `</span>`
        o.SettlementsDayAmountValue = Number(o.SettlementsDayCount) == 0 ? `-` : `<span class="font_blue hover">` + Number(Number(o.SettlementsDayCount).toFixed(2)) + `天/￥` + Number(Number(o.SettlementsDayAmount).toFixed(2)) + `</span>`
        o.ExceptionalAmountValue = Number(o.ExceptionalDay) == 0 ? `-` : `<span class="font_red hover">` + Number(Number(o.ExceptionalDay).toFixed(2)) + `笔/￥` + Number(Number(o.ExceptionalAmount).toFixed(2)) + `</span>`

        o.LastSignTime = o.LastSignTime ? o.LastSignTime.substring(0, 16) : ''
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text;
      if (this.totalAmount > 0) {
        text = '+' + this.$utils.setMoneyformatToParseInt(this.totalAmount);
        text = ',合计' + text + '元';
      } else {
        text = ',合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      }
      return text;
    }
  },
  mounted () {

  },
  methods: {
    // 到报名收款
    toApplyRecord (item) {
      console.log('toApplyRecord', item);
      this.$dialog.open(this, {
        name: '报名收款',
        routerName: this.$route.name,
        moduleName: 'signuppayment',
        moduleDescription: '学员报名购买课程，如未登记的学生可点界面“+”新增学生信息后再进行报名。',
        data: { dataInfo: { StudentKey: item.StudentKey, TableID: item.TableID, isReadOnly: true } },
        callBack: { afterSucces: () => { this.getDataList(); } }
      });
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    changeType (item, type, indexKey) {
      console.log("changeType", item, type, indexKey)
      if (item[indexKey] == '-') {
        return false
      }
      this.$emit("changeType", item, type, this.searchObj)
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = GetSettlementsStudentList;
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '结转学生统计',
          clist: [
            { title: '学生名', cName: 'MainDemoName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '手机号', cName: 'CustomerPhoneName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            {
              title: '结转总额', cName: 'SettlementsNum', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::SettlementsNum::2::0}}"
            },
            {
              title: '结转课时数', cName: 'SettlementsClassHourCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::SettlementsClassHourCount::2::0}}"
            },
            {
              title: '结转天数', cName: 'SettlementsDayCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::SettlementsDayCount::2::0}}"
            },
            {
              title: '结转天数金额', cName: 'SettlementsDayAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::SettlementsDayAmount::2::0}}"
            },
            { title: '异常结转笔数', cName: 'ExceptionalDay', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            {
              title: '结转异常金额', cName: 'ExceptionalAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::ExceptionalAmount::2::0}}"
            },
            { title: '销售', cName: 'AdviserKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '最近上课', cName: 'LastSignTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },

          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(searchObj, this.schoolItem.OLAPKey, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      let fn = GetSettlementsStudentList;
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj, '22222');
      fn(this.searchObj, this.schoolItem.OLAPKey).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },


  }
};
</script>
<style>
.hover {
  cursor: pointer;
}
</style>