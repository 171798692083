<template>
  <!-- 杂费设置 -->
  <div class="common-setting bg_gray">
    <!-- 添加按钮 -->
    <div class="content_marginTop_30">
      <span class="defult_dialog_header_btn fr"
            @click="doAddBtnClick"
            :class="vHasModulePower(130)?'':'norole'">
        <span class="btn_add">+</span>新增
      </span>
    </div>
    <div class="student_info_supplement form_info">
      <input-search-immediately v-model="searchText"
                                :placeholder="''"
                                :styleText="'right: 45px;top: 35px;'"
                                @clearSearch="clearSearText"></input-search-immediately>
    </div>
    <div class="mult_info_text_right_icon mult_info_ul bg_white"
         style="overflow-y: auto; height: 550px;"
         v-if="dataList.length>0">
      <items v-for="(item,index) in vDataList"
             :key="index"
             :item="item"
             :index="index"
             @mouseOverNewTable="mouseOverNewTable"
             @mouseOutNewTable="mouseOutNewTable"></items>

      <div v-if="isDropDownShow"
           @mouseenter.stop="isDropDownShow=true"
           @mouseleave.stop="isDropDownShow=false">
        <div class="course_student_attendance_operation module_content_menuList"
             :style=" {'top':topValue+'px','left':'5px','width':'80px;'}">
          <div class="top_bg"></div>
          <drop-down-box :optionJsonList="operateTypes"
                         @clickOptionForAlertDialog="chooseEdit"></drop-down-box>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="monitoring_content_nodata">
        <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
      </div>
    </div>

  </div>
</template>

<script>
import {
  hqAttractInvestmentIncidentals,
  hqIncidentalsBlockUp,
  hqIncidentalsStartUsing,
  hqIncidentalsDelete
} from '../../../../API/workbench.js';
import items from './items.vue';
import dropDownBox from '../../../common/drop-down-box';
export default {
  data () {
    return {
      searchText: '',
      isDropDownShow: false,
      topValue: 150,
      dataList: [],
      dataInfo: {}, // 信息
      operateTypes: []// 操作类型
    };
  },
  components: {
    dropDownBox,
    items
  },
  created () {
    this.getMessageForList();
  },
  computed: {
    vDataList () {
      let newArr = [];
      if (this.searchText) {
        this.dataList.forEach(obj => {
          if (obj.MainDemoName.toUpperCase().indexOf(this.searchText.toUpperCase()) >= 0) {
            newArr.push(obj);
          }
        });
      } else {
        newArr = this.dataList;
      }
      return newArr;
    }
  },
  methods: {
    clearSearText () {
      this.searchText = '';
    },
    // 移入
    mouseOverNewTable (index, item) {
      console.log('鼠标移入', index, item);
      this.topValue = 150 + (index * 50);
      this.operateTypes = [
        { type: 0, name: '查看', eventName: 'view', isShow: true },
        { type: 1, name: '修改', eventName: 'edit', isShow: true },
        { type: 4, name: '删除', eventName: 'delete', isShow: true }
      ];
      if (Number(item.StatusKey) == 0) { // 0-停用 ;1-启用
        this.operateTypes.splice(2, 0, { type: 2, name: '启用', eventName: 'enable', isShow: true });
      } else {
        this.operateTypes.splice(2, 0, { type: 3, name: '停用', eventName: 'disable', isShow: true });
      }
      this.dataInfo = item;
      this.isDropDownShow = true;
    },
    // 移出
    mouseOutNewTable (index, item) {
      console.log('鼠标离开', index, item);
      this.isDropDownShow = false;
    },
    // 获取所有财务科目列表
    getMessageForList () {
      hqAttractInvestmentIncidentals().then(result => {
        this.dataList = result.data;
        console.log(result.data, '获取所有启用的数据');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 新增操作
    doAddBtnClick () {
      // 有权限
      if (this.vHasModulePower(130)) {
        this.chooseEdit({ name: '新增', eventName: 'add' });
        // 无权限
      } else {
        this.$utils.CheckModulePowerToTips(130);
      }
    },
    // 选择操作
    chooseEdit (item) {
      console.log(item, '选择操作');
      switch (item.eventName) {
        case 'add':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '新增招商杂费',
            moduleName: 'incidentalsSetting',
            data: { editItem: {}, isReadonly: false },
            callBack: {
              afterSuccess: () => { this.getMessageForList(); }
            }
          });
          break;
        case 'view':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '查看招商杂费',
            moduleName: 'incidentalsSetting',
            data: { editItem: this.dataInfo, isReadonly: true }
          });
          break;
        case 'edit':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '修改招商杂费',
            moduleName: 'incidentalsSetting',
            data: { editItem: this.dataInfo, isReadonly: false },
            callBack: {
              afterSuccess: () => { this.getMessageForList(); }
            }
          });
          break;
        case 'enable':
          layer.confirm('是否启用该招商杂费?', {
            btn: [{
              name: '确认',
              callBack: () => {
                layer.close();
                hqIncidentalsStartUsing(this.dataInfo.OLAPKey).then(result => {
                  this.getMessageForList();
                }, error => {
                  layer.alert(error.msg);
                });
              }
            }
            ]
          });
          break;
        case 'disable':
          layer.confirm('是否停用该招商杂费?', {
            btn: [{
              name: '确认',
              callBack: () => {
                layer.close();
                hqIncidentalsBlockUp(this.dataInfo.OLAPKey).then(result => {
                  this.getMessageForList();
                }, error => {
                  layer.alert(error.msg);
                });
              }
            }
            ]
          });
          break;
        case 'delete':
          layer.confirm('是否删除该招商杂费?', {
            btn: [{
              name: '确认',
              callBack: () => {
                layer.close();
                hqIncidentalsDelete(this.dataInfo.OLAPKey).then(result => {
                  this.getMessageForList();
                }, error => {
                  layer.alert(error.msg);
                });
              }
            }
            ]
          });
          break;
        default:
          break;
      };
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.$store.getters.RolePowerList, ModulePowerKey);
    }
  }
};
</script>

<style>
</style>
