<template>
  <!-- 上传 -->
  <div class="courseware_add_img_box">
    <div class="add_img">
      <img class="img_box"
           :src="vUrl"
           alt="" />
    </div>
    <div class="add_progress_banner">
      <div class="add_progress"
           :style="{left:vProgress}"></div>
    </div>
    <div v-if="dataInfo.percent && dataInfo.percent < 100"
         class="add_progress_text">上传中 {{vProgress}}</div>
    <div v-else-if="!dataInfo.percent ||  dataInfo.percent == 100"
         class="add_progress_text icon_type">{{dataInfo.Remark || ''}}</div>
  </div>
</template>
<script>
export default {
  props: {
    dataInfo: Object
  },
  created () {
    console.log(this.dataInfo, 'courseware_add_img_box-提示2');
  },
  computed: {
    vProgress () {
      return this.dataInfo.percent + '%';
    },
    vUrl () {
      if (this.dataInfo.AttachTypeKey == '1') {
        if (this.dataInfo.AttachViewUrl.indexOf('data:image') != -1) {
          return this.dataInfo.AttachViewUrl;
        } else {
          return this.dataInfo.AttachViewUrl;
        }
      } else if (this.dataInfo.AttachTypeKey == '2') {
        if (this.dataInfo.CoverUrl) {
          return this.dataInfo.CoverUrl;
        } else {
          return require('../../../../../public/image/courseware_list_type01.png');
        }
      } else if (this.dataInfo.AttachTypeKey == '3') {
        return require('../../../../../public/image/courseware_list_type02.png');
      } else if (this.dataInfo.AttachTypeKey == '4') {
        if (this.dataInfo.CoverUrl) {
          return this.dataInfo.CoverUrl;
        } else {
          return require('../../../../../public/image/courseware_list_type03.png');
        }
      } else if (this.dataInfo.AttachTypeKey == '5') {
        if (this.dataInfo.CoverUrl) {
          return this.dataInfo.CoverUrl;
        } else {
          return require('../../../../../public/image/courseware_pdf_cover.png');
        }
      }
    }
  }
};
</script>