<template>
  <!--  -->
  <div class="chain_workbench_teacher_info">
    <div class="teacher_info_img">
      <!-- staffInfo.XMLIcon  -->
      <staff-heads-img-upload :ImgURl="staffInfo.XMLIcon"
                              :staffInfo="staffInfo"
                              :formTitle="'员工头像:'"
                              :isImgEditText="true"
                              :imgHeight="300"
                              :imgWidth="300"
                              @doSetPicCorp="doSetPicCorpHeadImg"></staff-heads-img-upload>
    </div>
    <div class="head_shadow_img"></div>
    <div class="teacher_info_name">
      {{staffInfo.MainDemoName}}
    </div>
    <div class="teacher_info_text"
         v-if="!vRoleKey&&staffInfo.Chose2KeyValue">
      {{staffInfo.Chose2KeyValue}}
    </div>
    <div class="teacher_edit_btn"
         @click="doEditStaff"></div>

    <!-- <div class="student_info_btn"
         @click="doStatisticsClick">学生作品管理</div> -->
  </div>
</template>
<script>
import { GetTheEmployeeByID } from '../../../../API/workbench';
export default {
  data () {
    return {
      token: {},
      staffInfo: {},
      seletedDate: Date.new().toLocaleDateString(),
      CWSworksUploadObj: {
        name: '学生作品管理',
        routerName: 'worksUploadManagement',
        routertitle: '学生作品管理',
        auth: true,
        roleName: '',
        ModulePowerKey: 0,
        class: 'bg_yellow',
        firstTitle: 'L',
        moduleName: ''
      }
    };
  },
  computed: {
    vRoleKey () {
      return this.$store.getters.token ? Number(this.$store.getters.token.RoleKey) == 2 : false; // 2为校长；非则为老师
    }
  },
  created () {

  },
  mounted () {
    this.token = this.$store.getters.token;
    this.getStaffInfo(this.token.UserID);
  },
  methods: {
    doStatisticsClick () {
      this.$bus.emit('needOpenRouter', this.CWSworksUploadObj);
    },
    doEditStaff () {
      console.log('doEditStaff', this.staffInfo);
      this.$emit('doEditStaff');
    },
    // 获取员工信息.
    getStaffInfo (OLAPKey, callback) {
      GetTheEmployeeByID(OLAPKey).then(result => {
        this.staffInfo = result.data;
        if (callback) {
          callback();
        }
        console.log('getStaffInfo', result.data);
      });
    },
    // 更新头像.
    doSetPicCorpHeadImg (ImgURl) {
      console.log('ImgURl', this.staffInfo);
      this.staffInfo.XMLIcon = ImgURl;
    }
  }
};
</script>

<style scoped>
.chain_workbench_teacher_info {
  padding-top: 16px;
  height: 190px;
}
.head_shadow_img {
  width: 307px;
  height: 192px;
  position: absolute;
  top: 0;
  opacity: 0.5;
  background: url(../../../../../public/image/photo_shadow.png) no-repeat center
    100px;
}
.chain_workbench_teacher_info .teacher_edit_btn {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 16px;
  top: 8px;
  cursor: pointer;
  padding-right: 10px;
  font-size: 18px;
  line-height: 20px;
  color: #999;
  background: url(../../../../../public/cw-img/edit_icon.png) no-repeat center;
  background-size: 18px;
  z-index: 2;
}
.student_info_btn {
  text-align: center;
  width: 122px;
  height: 42px;
  border-radius: 4px;
  background-color: #f7f7f7;
  color: #666;
  line-height: 42px;
  margin: 15px auto;
  cursor: pointer;
}
.student_info_btn:hover {
  border: 1px solid #82cdff;
  background-color: #82cdff;
  color: #fff;
}
</style>

