<template>
  <div :id="echartID"
       :class="[vClass]"></div>
</template>
<script>
export default {
  name: 'columnarEchart',
  data () {
    return {
      // chartItem: {
      //   resize: ''
      // },
      echart: null
    };
  },
  props: {
    echartID: String,
    dataList: Array,
    reqList: Array,
    echartColor: String,
    echartBGColor: String,
    rotate: String,
    echartClass: String,
    maxType: String,
    rateType: String
  },
  computed: {
    vClass () {
      if (this.echartClass) {
        return this.echartClass;
      } else {
        return 'echarts_m';
      }
    },
    // 这是为了预防 当没有数据时, Y轴刻度 连数字都不显示的问题.  20这个数字到时候直接从外面传值进来处理
    maxSize () {
      let count = 0;
      for (var i = 0; i < this.dataList.length; i++) {
        count += Number(this.dataList[i]);
      }
      if (this.maxType == 'rate') {
        return 100;
      } else if (count == 0 || count < 10) {
        return 10;
      }
    }
  },
  mounted () {

  },
  methods: {
    echartInit () {
      this.echart = echarts.init(document.getElementById(this.echartID));
      window.chartList.push(this.echart);
      var that = this;
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'line' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params, ticket, callback) {
            let tips = '';                // 根据传进来的rateType是否存在确认是否显示tips.
            if (!that.rateType) {
              tips = 0;
            } else {
              tips = that.rateType;
            }
            var htmlStr = '';
            for (var i = 0; i < params.length; i++) {
              var param = params[i];
              var xName = param.name;// x轴的名称
              // var seriesName = param.seriesName;// 图例名称
              var value = param.value;// y轴值
              var color = param.color;// 图例颜色

              if (i === 0) {
                htmlStr += xName;// x轴的名称
              }
              htmlStr += '<div>';
              // 为了保证和原来的效果一样，这里自己实现了一个点的效果
              htmlStr += '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:' + color + ';"></span>';

              // 圆点后面显示的文本
              htmlStr += value + tips;
              htmlStr += '</div>';
            }
            return htmlStr;
          }
        },
        grid: {
          top: '30',
          left: '4%',
          right: '4%',
          bottom: '15%',
          containLabel: true
        },
        xAxis: {
          data: this.reqList,
          axisLabel: {
            textStyle: {
              color: '#c0c4cc'
            },
            rotate: this.rotate || 0,
            // 文字过长时隐藏
            formatter: function (value) {
              return (value.length > 8 ? (value.slice(0, 8) + '...') : value);
            },
            interval: 0
          },
          axisLine: {
            lineStyle: {
              color: '#cccccc' // 更改坐标轴颜色
            }
          },
          z: 10
        },
        yAxis: {
          min: 0,
          max: this.maxSize,
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#999'
            }
          },

          axisLine: {
            lineStyle: {
              color: '#cccccc' // 更改坐标轴颜色
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#f0f0f2']
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['#f7f7f7', '#fff']
            }
          }
        },
        // 禁止缩放
        // dataZoom: [{
        //   type: 'inside'
        // }],
        series: [{ // For shadow
          type: 'bar',
          itemStyle: {
            normal: {
              color: 'rgba(255,255,255,0.05)'
            }
            // barMinHeight: '0px'
          },
          barGap: '-100%',
          barCategoryGap: '40%'
        }, {
          type: 'bar',
          data: this.dataList,
          itemStyle: {
            normal: {
              barBorderWidth: 1,
              barBorderRadius: [4, 4, 4, 4],
              color: this.echartColor,
              barBorderColor: this.echartBGColor,
              abel: {
                show: true,
                textStyle: {
                  color: '#27727B'
                }
              }
            }
          },
          barMinHeight: '0px'

        }]
      };

      // 使用刚指定的配置项和数据显示图表。
      this.echart.setOption(option);
    },
    sumBorderWidth () {
      let borderList = [];
      this.dataList.forEach(o => {
        if (o == 0) {
          borderList.push(0);
        } else {
          borderList.push(1);
        }
      });
      return borderList;
    }
  }
};
</script>

