<template>
  <div class="credit_management_popup">
    <div class="statistics-box" style="width: 322px;margin: 20px" >
      <statistics-box
        :dataList="statisticsData"
        @clickEvent="clcikStatictisItem"
      ></statistics-box>
    </div>
    <div class="popup_title_padding view_title_flex">
      <input-search-contain-btn
        v-model="searchObj.searchText"
        :placeholder="'请输入班级名'"
        @clearSearch="clearSearchCondition"
        @onSearchEvent="search"
      ></input-search-contain-btn>
      <div class="mendian_backstage_search">
        <div
          class="btn_hover_bg_blue search_btn"
          style="margin: 0"
          @click.stop="addItem"
        >
          新增
        </div>
      </div>
    </div>
    <div class="credit_management_table">
      <table-list
        class="summarizing_list table_list_content"
        :tableData="vdataReportList"
        :tableColumns="tableCourseColumns"
        :totalNum="totalNum"
        :queryParams="searchObj"
        :footerContent="vFooterTotal"
        @loadTableData="getDataList"
      ></table-list>
    </div>
    <custom-dialog
      :title="vAddFormTitle"
      :visible.sync="showAddForm"
      :before-close="closeDialog"
    >
      <add-class
        :dataInfo="seletedItem"
        @afterSuccess="updateData"
        @cancelClick="closeDialog"
      ></add-class>
    </custom-dialog>
  </div>
</template>
<script>
import addClass from "../../form/add-class";
import statisticsBox from "../../../../report/common/statistics-box";
import { CWSSearchPageClassList,CWSClassLeave,CWSClassEnable } from "../../../../../API/workbench";
import inputSearchContainBtn from "../../../../report/common/input-search-contain-btn";
import tableList from "../../../../common/table-list/index";
export default {
  data() {
    return {
      statisticsData: {
        ClassEnable: {
          title: "启用班级数",
          value: 0,
          type: 4,
          isSelection: true,
        },
        ClassDeactivate: {
          title: "停用班级数",
          value: 0,
          type: 5,
          isSelection: false,
        },
      },
      seletedItem: null, //选中单个
      showAddForm: false,
      dataReportList: [],
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: "",
        orderBy: "",
        sequence: "DESC",
        ClassType: "4",
      },
      tableData: {},
      totalNum: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: "序号",
          prop: "Number",
          width: 68,
          sortable: false,
          align: "left",
          type: "tableIndex",
          className: "table_index_gray",
        },
        {
          label: "所在班级",
          prop: "MainDemoName",
          width: 120,
          sortable: false,
          align: "left",
          className: "",
          type: "textItem",
        },
        {
          label: "学生数",
          prop: "StudentCount",
          width: 80,
          sortable: false,
          align: "left",
          type: "numberItem",
        },
        {
          label: "老师数",
          prop: "TeacherNum",
          width: 80,
          sortable: false,
          align: "left",
          type: "numberItem",
        },
        {
          label: "课件包数",
          prop: "CoursewareListCount",
          width: 100,
          sortable: false,
           align: "left",
          type: "numberItem",
        },
        {
          label: "本月上课课次",
          prop: "ThisMonthCourseCount",
          width: 120,
          sortable: false,
          align: "left",
          type: "numberItem",
        },
        {
          label: "本月上课时长",
          prop: "ThisMonthCourseTime",
          width: 120,
          sortable: false,
          align: "left",
          type: "numberItem",
        },
        {
          label: "本月消耗流量",
          prop: "ThisMonthFlow",
          width: 120,
          sortable: false,
          align: "left",
          type: "textItem",
        },
        {
          label: "操作",
          prop: "option",
          type: "hidden-btn",
          minWidth: 200,
          align: "center",
          extend: {
            tableBtnGroup: [
              {
                name: "详情",
                extend: {
                  isHide: (rowData) => {
                    return rowData.OLAPKey==0;
                  },
                  click: (rowData) => {
                    this.goDetail(rowData);
                  },
                },
              },
              {
                name: "修改",
                extend: {
                  isHide: (rowData) => {
                    return rowData.OLAPKey==0;
                  },
                  click: (rowData) => {
                    this.editItem(rowData);
                  },
                },
              },
              {
                name: "启用",
                extend: {
                  isHide: (rowData) => {
                    return this.searchObj.ClassType == 4;
                  },
                  click: (rowData) => {
                     this.enableItem(rowData);
                  },
                },
              },
              {
                name: "停用",
                extend: {
                  isHide: (rowData) => {
                    return this.searchObj.ClassType == 5;
                  },
                  click: (rowData) => {
                    this.stopItem(rowData);
                  },
                },
              },
            ],
          },
        },
      ],
    };
  },
  props: {},
  components: {
    inputSearchContainBtn,
    tableList,
    statisticsBox,
    addClass,
  },
  computed: {
    vdataReportList() {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.StudentCount=Number(o.StudentCount)||0
        o.ThisMonthFlow=this.$utils.flowFormate(o.ThisMonthFlow)
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal() {
      let text = "共" + this.totalNum + "条数据";
      return text;
    },
    vAddFormTitle() {
      return !this.seletedItem ? "新增班级" : "修改班级";
    },
    vSeletedItemKey() {
      return this.seletedItem ? this.seletedItem.OLAPKey : 0;
    },
  },
  watch: {},
  created() {
    this.getDataList();
  },
  mounted(){
    this.$dialog.register(this, ['cwsClassDetail']);
  },
  methods: {
    // 查看详情
    goDetail(item){
       this.seletedItem=item
       this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: '',
              name: '班级详情',
              moduleName: 'cwsClassDetail',
              data: { 
                classKey:this.seletedItem.OLAPKey,   //班级详情适用， 班级Key
                isTeacher:false   //班级详情适用， 是否老师， false则为校长
              },
              callBack: {
                afterSuccess: () => { this.getDataList(); },
              }
            });
    },
    // 修改单个
    editItem(item) {
      this.seletedItem = item;
      this.showAddForm = true;
    },
    // 新增按钮
    addItem() {
      this.seletedItem = null;
      this.showAddForm = true;
    },
    closeDialog() {
      this.showAddForm = false;
    },
    updateData() {
      this.getDataList();
    },
    // 重置查询条件
    initParamsForQuery() {
      this.dataReportList = [];
      this.searchObj.searchText = "";
      this.searchObj.pageIndex = 0;
    },
    // 点击分类
    clcikStatictisItem(item) {
      this.initParamsForQuery();
      this.searchObj.ClassType = item.type;
      this.getDataList();
    },
    clearSearchCondition() {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = "";
      this.getDataList();
    },
    search() {
      this.searchObj.pageIndex = 0;
      if (this.searchObj.searchText == "") {
        this.searchObj.StudentKey = "";
      }
      this.getDataList();
    },
    // 启用
    enableItem(item) {
      this.seletedItem = item;
      layer.confirm("请问是否启用该班级", {
        btn: [
          {
            name: "确认",
            callBack: () => {
              CWSClassEnable(this.seletedItem.OLAPKey).then((result) => {
                this.updateData();
              });
              layer.close();
            },
          },
        ],
      });
    },
    //停用
    stopItem(item) {
      this.seletedItem = item;
      layer.confirm("请问是否停用该班级", {
        btn: [
          {
            name: "确认",
            callBack: () => {
              CWSClassLeave(this.seletedItem.OLAPKey).then((result) => {
                // layer.alert("启用成功")
                this.updateData();
              });
              layer.close();
            },
          },
        ],
      });
    },
    getDataList() {
      this.dataReportList=[]
      CWSSearchPageClassList(this.searchObj).then(
        (res) => {
          console.log("CWSCWSSearchPageClassList", res.data);
          this.totalNum = res.data.Total;
          this.dataReportList = res.data.PageDataList;
          this.statisticsData.ClassDeactivate.value=Number(res.data.ClassDeactivate||0)
          this.statisticsData.ClassEnable.value=Number(res.data.ClassEnable||0)
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
  },
};
</script>