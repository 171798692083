<template>
  <div>
    <div class="WKT_activity_my"
         @scroll="scrollActivityInfo">
      <activity-list v-for="(item, index) in activitiesCouponList"
                     :key="index"
                     :dataInfo="item"
                     @openActivitiesStatisticsList="openActivitiesStatisticsList"
                     @refreshActivityInfo="refreshActivityInfo"
                     @showActivityDetailsPopup="showActivityDetailsPopup"></activity-list>
      <div v-if="activitiesCouponList.length == 0">
        <div class="has_arrange_course"
             style="margin-top:200px">
          <div class="has_arrange_course_content"
               style="padding: 0px">
            <div class="has_arrange_course_list">
              <div class="has_arrange_course_nodata single_shadow">
                <span>亲，当前没有任何数据哦~</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <custom-dialog :title="'活动预览'"
                   width="1200px"
                   :visible.sync="isShowActivityDetailsPopup"
                   :before-close="hiddenActivityDetailsPopup">
      <activities-preview :dataInfo="selectActivityInfo"
                          @closePopup="hiddenActivityDetailsPopup"></activities-preview>
    </custom-dialog>
    <custom-dialog title="活动报名统计表"
                   width="1300px"
                   class="class_staistics_list defult_dialog"
                   :visible.sync="isShowActivitiesStatisticsDialog"
                   :before-close="closeActivitiesStatisticsDialog">
      <activities-statistics-list :selectedCouponInfo="selectActivityInfo"
                                  @callback="refreshActivityInfo"></activities-statistics-list>
    </custom-dialog>
  </div>
</template>
<script>
import { MessageSearchActivitiesCoupon } from '../../../API/workbench.js';
import activityList from './component/activity-list';
import activitiesStatisticsList from '../../wkt-back/activity-monitoring-platform/activities-statistics-list';
import activitiesPreview from './activities-preview';
export default {
  data () {
    return {
      isShowActivityDetailsPopup: false,
      isShowActivitiesStatisticsDialog:false,
      selectActivityInfo: {},
      total: 0,
      queryParams: {
        pageIndex: 0,
        pageSize: 5,
        searchText: '',
        orderBy: 'activity_time_end',
        sequence: '',
        StartTime: '',
        EndTime: ''
      },
      activitiesCouponList: []
    };
  },
  components: {
    activityList,
    activitiesPreview,
    activitiesStatisticsList
  },
  created () {
    this.getActivitiesCouponList();
  },
  comments:{
    vActivitiesCouponList(){
      const res = new Map()
      return this.activitiesCouponList.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
    }
  },
  methods: {
    openActivitiesStatisticsList(item){
      this.selectActivityInfo = item;
      this.isShowActivitiesStatisticsDialog = true;
    },
    closeActivitiesStatisticsDialog(){
      this.isShowActivitiesStatisticsDialog = false;
    },
    getActivitiesCouponList () {
      MessageSearchActivitiesCoupon(this.queryParams).then(
        (res) => {
          this.total = res.data.Total;
          res.data.PageDataList.forEach(e => {
            this.activitiesCouponList.push(e);
          });
          if (this.activitiesCouponList.length < this.total) {
            this.queryParams.pageIndex++;
          }
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    scrollActivityInfo (e) {
      if (e.target.scrollTop >= (e.target.scrollHeight - e.target.clientHeight)) {
        if (this.activitiesCouponList.length < this.total) {
          this.getActivitiesCouponList();
        }
      }
    },
    refreshActivityInfo () {
      this.queryParams.pageIndex = 0;
      this.activitiesCouponList = [];
      this.getActivitiesCouponList();
    },
    showActivityDetailsPopup (item) {
      this.selectActivityInfo = item;
      this.isShowActivityDetailsPopup = true;
    },
    hiddenActivityDetailsPopup () {
      this.$bus.emit('closeEditorDialog');
      this.isShowActivityDetailsPopup = false;
    }
  }
};
</script>