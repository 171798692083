<template>
  <!-- 连锁总部工作台 -->
  <div class="courseware_left_info">
    <div class="courseware_md_info">
      <md-info-box></md-info-box>
    </div>

    <div class="courseware_staff_info pr"
         v-if="vRoleKey">
      <!-- <staff-info-box @doEditStaff="doEditStaff"></staff-info-box> -->
      <todo-info-list @updateData="updateData"
                      ref="todoInfo"></todo-info-list>
    </div>

  </div>
</template>
<script>
import mdInfoBox from './md-info-box/index';
import staffInfoBox from './staff-info-box/index';
import todoInfoList from './todo-info-list/index';
export default {
  data () {
    return {
      mendianInfo: this.$store.getters.SaaSClientInfo
    };
  },
  components: {
    mdInfoBox,
    staffInfoBox,
    todoInfoList
  },
  computed: {
    vRoleKey () {
      return this.$store.getters.token ? Number(this.$store.getters.token.RoleKey) == 2 : false; // 2为校长；非则为老师
    }
  },
  created () {
    console.log(this.$store.getters, 'this.$store.getters');
  },
  mounted () {

  },
  methods: {
    doEditMdInfo () {
      this.isShowMDform = true;
    },
    doEditStaff () {
      this.$dialog.open(this, {
        name: '修改密码',
        routerName: this.$route.name,
        moduleName: 'editPassWord',
        callBack: { afterSucces: (data) => { } }
      });
    },
    updateData () {

    },
    reflashData () {
      this.$refs.todoInfo.getDataList();
    }
  }
};
</script>

<style>
.courseware_md_info {
  width: 307px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.courseware_staff_info {
  width: 307px;
  min-height: 330px;
  background-color: #fff;
  border-radius: 8px;
}
</style>
