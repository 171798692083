<template>
  <div class="class_common_list_search">
    <input type="text"
           :style="inputStyle"
           :value="value"
           @input="updateVal($event.target.value)"
           @change="changeVal($event.target.value)"
           :placeholder="placeholder">
    <span v-show="value"
          class="home_remove_btn"
          :style="styleText"
          @click="clearSearch"></span>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    placeholder: {
      type: String
    },
    inputStyle: {
      type: String,
      default: 'margin-bottom:5px'
    },
    styleText: {
      type: String,
      default: ''
    }
  },
  methods: {
    updateVal (val) {
      this.$emit('input', val);
    },
    changeVal (val) {
      this.$emit('change', val);
    },
    search () {
      this.$emit('search');
    },
    clearSearch () {
      this.$emit('clearSearch');
    }
  }

};
</script>
