<template>
  <!-- 授权课件表单 -->
  <div style="height:400px;position: relative;">
    <div class="form_info_edit form_info_list">
      <div>
        <input-date v-model="dataInfoed.startTime"
                    :formTitle="'开始日期'"
                    :readonly="isReadonly"
                    :minDate="$utils.formatDateToLine(Date.new())"
                    :required="true"
                    ref="inputStartDate"
                    :formPlaceholder="'必填'"
                    @changeDate="changeStartDay"></input-date>
        <input-date v-model="dataInfoed.endTime"
                    :formTitle="'结束日期'"
                    :readonly="isReadonly"
                    :minDate="$utils.formatDateToLine(dataInfoed.startTime)"
                    ref="inputEndDate"
                    :required="true"
                    :formPlaceholder="'必填'"
                    @changeDate="changeEndDay"></input-date>
      </div>
    </div>

    <div class="bottom_box">
      <div class="el-button btn_light_blue btn_hover_bg_blue bottom_box_btn"
           @click.stop="componentsAuth">完成</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dataInfoed: {
        startTime: '',
        endTime: ''
      }
    };
  },
  components: {

  },
  props: {
    dataInfo: Object,
    isReadonly: Boolean
  },
  created () {

  },
  mounted () {
    this.dataInfoed = JSON.parse(JSON.stringify(this.dataInfo));
    if (this.dataInfoed.startTime == '') {
      this.dataInfoed.startTime = this.getDateTime('');
    }
  },
  computed: {

  },
  methods: {
    changeStartDay (date) {
      if (this.dataInfoed.endTime && Date.new(this.dataInfoed.endTime) < Date.new(date)) {
        this.$refs.inputStartDate.resetDate();
        layer.alert('开始时间不能大于结束时间');
      } else {
        this.dataInfoed.startTime = date;
        this.$emit('changeDate', this.dataInfoed);
      }
    },
    changeEndDay (date) {
      if (this.dataInfoed.startTime && Date.new(this.dataInfoed.startTime) > Date.new(date)) {
        this.$refs.inputEndDate.resetDate();
        layer.alert('结束时间不能早于开始时间');
      } else {
        this.dataInfoed.endTime = date;
        this.$emit('changeDate', this.dataInfoed);
      }
    },
    getDateTime (type) {
      var date = new Date();
      var hengGang = '-';
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var curDate = date.getDate();

      if (month >= 1 && month <= 9) {
        month = '0' + month;
      }
      if (curDate >= 0 && curDate <= 9) {
        curDate = '0' + curDate;
      }
      var currentdate = '';
      if (type == 'year') {
        currentdate = year;
        return currentdate;
      } else if (type == 'month') {
        currentdate = year + hengGang + month;
        return currentdate;
      } else {
        currentdate = year + hengGang + month + hengGang + curDate;
        return currentdate;
      }
    },
    cancelClick () {
      this.$emit('cancelClick');
    },
    componentsAuth () {
      if (this.dataInfoed.startTime == '' || this.dataInfoed.endTime == '') {
        layer.alert('请确定结束时间');
        return;
      }
      this.$emit('afterSuccess', this.dataInfoed);
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.tipSpan {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */
  position: absolute;
  top: 14px;
  left: 94px;

  color: #999999;
}
.bottom_box {
  height: 60px;
  padding: 10px 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.bottom_box_btn {
  width: 100%;
  height: 100%;
}
</style>
