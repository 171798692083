<template>
  <div class="chain_workbench_warning height pr scroll_type">
    <div class="warning_title">
      待办事项
      <span class="renew_btn"
            @click.stop="renew">刷新</span>
    </div>
    <div class="backlog_ul "
         @scroll="scrollTOP"
         v-if="dataList.length>0">
      <item v-for="(item,key) in dataList"
            :key="key"
            :keyIndex="key"
            :item="item"
            @seletedItem="seletedItemAfter"></item>
    </div>
    <div v-if="dataList.length==0"
         class="course_block_nodata">
      <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
    </div>
    <div class="module_content_menuList"
         :style="{'top':topValue+'px','left':'68.5%'}"
         v-show="isShowdropDownBox"
         @mouseover.stop="isShowdropDownBox=true">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="menuList"
                     @clickOptionForAlertDialog="clickAfter"></drop-down-box>
    </div>
    <!-- <div class="todo_flex">
      <div style="width:50%">
        <div class="todo_summarizing pr"
             v-for="(item,index) in statisticsList.slice(0,4)"
             :key="index"
             :class="item.icon"
             @click="doStatisticsClick(item)">
          {{item.name}}
          <div class="summa_num">{{item.num}}</div>
          <div class="summa_btn"></div>
        </div>
      </div>

      <div style="width:50%">
        <div class="todo_summarizing pr"
             v-for="(item,index) in statisticsList.slice(4,7)"
             :key="index"
             :class="item.icon"
             @click="doStatisticsClick(item)">
          {{item.name}}
          <div class="summa_num"
               :class="($store.getters.SaaSClientInfo.IsOpenSelfCoursewareKey == 0&&item.key =='cwsgeneralCoursewareManagement') ?'colorRed':''">{{item.num}}</div>
          <div class="summa_btn"></div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import {
  CWSTodoForPrincipal,
  CWSGetSaaSClientStatistics,
  CWSAuditReject,
  CWSAuditAdopt,
  CWSIgnoreEarlyWarning,
  CWSvCoursewareAuditPass,
  CWSvCoursewareRefuse,
  MonitorOverlook,
  GetMonitorInfo
} from '../../../../API/workbench';
import homeIncomeAndexpensesForm from '../../../head-quarters/basis-setting-module/hq-income-pay-manage/home-incomeAndexpenses-form';
import dropDownBox from '../../../common/drop-down-box';
import item from './item';
export default {
  data () {
    return {
      searchObj: {
        PageSize: 10,
        PageIndex: 0
      },
      dataList: [],
      seletdItem: null,
      menuList: [
        {
          name: '查看详情',
          type: 'detail',
          isShow: false,
          ModulePowerKey: 0
        },
        { name: '通过', type: 'pass', isShow: true, ModulePowerKey: 0 },
        { name: '拒绝', type: 'reject', isShow: true, ModulePowerKey: 0 },
        { name: '忽略', type: 'Ignore', isShow: true, ModulePowerKey: 0 }
      ],
      isShowdropDownBox: false,
      newIndex: -1,
      topValue: 0, // 默认第一个top值
      toScrollTop: 0, // 滚动条的高度
      selecttoAuditedInfo: null,
      statisticsList: [
        { name: '授权课件', key: 'cwscoursewareManager', num: 0, icon: 'courseware_cion', tyle: 'left' },
        { name: '全部老师', key: 'cwsteacherManager', num: 0, icon: 'teacher_cion', tyle: 'left' },
        { name: '全部学生', key: 'cwsstudentManager', num: 0, icon: 'student_cion', tyle: 'left' },
        { name: '操作日志', key: 'operationLogReport', num: '', icon: 'operation_log', tyle: 'left' },
        { name: '自研课件', key: 'cwsgeneralCoursewareManagement', num: 0, icon: 'authorized_cion', tyle: 'right' },
        { name: '全部班级', key: 'cwsclassManager', num: 0, icon: 'class_cion', tyle: 'right' },
        { name: '学生作品', key: 'worksUploadManagement', num: 0, icon: 'student_works', tyle: 'right' }
      ]

    };
  },
  components: {
    item, dropDownBox, homeIncomeAndexpensesForm
  },
  props: {

  },
  created () {
    this.getDataList();
  },
  mounted () {
    document.querySelector('body').addEventListener('click', e => {
      this.isShowdropDownBox = false;
    }, false);
  },
  methods: {
    renew () {
      this.getDataList();
    },
    scrollTOP (e) {
      this.toScrollTop = e.target.scrollTop;
      if (e.target.scrollTop > 0) {
        this.isShowdropDownBox = false;
      }
    },
    getDataList () {
      CWSTodoForPrincipal(this.searchObj).then(result => {
        this.dataList = result.data.PageDataList;
        console.log('CWSTodoForPrincipal', result.data);
        this.getSaaSClientStatistics();
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取统计
    getSaaSClientStatistics () {
      CWSGetSaaSClientStatistics().then(result => {
        console.log('获取统计', result.data);
        console.log(this.statisticsList);
        this.statisticsList[0].num = Number(result.data.CoursewareNum);// 授权课件
        this.statisticsList[1].num = Number(result.data.TeacherNum);// 老师数
        this.statisticsList[2].num = Number(result.data.StudentsNum);// 学生数
        this.statisticsList[5].num = Number(result.data.ClassNum);// 班级数
        this.statisticsList[6].num = Number(result.data.WorksSum) || 0;// 学生作品数
        let type = this.$store.getters.SaaSClientInfo.IsOpenSelfCoursewareKey;
        if (type == 0) {
          this.statisticsList[4].num = '未开通';// 自研课件
          return;
        }
        this.statisticsList[4].num = Number(result.data.ResearchCoursewareNum);// 自研课件
      }, error => {
        layer.alert(error.msg);
      });
    },
    seletedItemAfter (item, key) {
      this.menuList.forEach(o => { o.isShow = true; });
      if (item.TypeKey == 2 || item.TypeKey == 4) { // 过期
        this.menuList[0].isShow = false;
        this.menuList[1].isShow = false;
        this.menuList[2].isShow = false;
      } else if (item.TypeKey == 3) {
        this.menuList[0].isShow = true;
        this.menuList[3].isShow = false;
      } else { // 审核
        this.menuList[0].isShow = false;
        this.menuList[3].isShow = false;
      }
      this.seletdItem = item;
      this.isShowdropDownBox = true;
      this.doOneTopValueCount(key);
      console.log('seletedItemAfter', this.seletdItem);
    },
    clickAfter (item) {
      this.isShowdropDownBox = false;
      switch (item.type) {
        case 'reject':// 拒绝
          this.rejectItem(this.seletdItem);
          break;
        case 'pass':// 通过
          this.passItem(this.seletdItem);
          break;
        case 'Ignore':// 忽略
          this.IgnoreItem(this.seletdItem);
          break;
        case 'detail':// 详情
          let obj = {
            moduleName: "cwsgeneralCoursewareManagement",
            name: "自研课件",
            routerName: "coursewareWorkBench"
          }
          this.goRouter(obj);
          break;
        default:
          break;
      }
    },
    goRouter (item) {
      // dataKey
      this.$route.params.data = {
        levelPath: this.seletdItem.levelPath,
        Title: this.seletdItem.Title,
        isChild: true
      }
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'cwsgeneralCoursewareManagement',
        data: {},
        callBack: {

          afterSuccess: () => { this.$emit('afterSuccess'); }
        }
      });
      item.data = {
        levelPath: this.seletdItem.levelPath
      }
      item.dataKey = this.seletdItem.ID;
      console.log(item, 'goRouter');
      // this.$bus.emit('needOpenRouter', item);
    },
    // 拒绝
    rejectItem () {
      layer.confirm('请确定是否审核拒绝?', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              layer.close();
              let aptF = CWSAuditReject;
              if (this.seletdItem.TypeKey == '3') {
                aptF = CWSvCoursewareRefuse;
              }
              aptF(this.seletdItem.ID).then(result => {
                this.getDataList();
              }, error => {
                layer.alert(error.msg);
              });
            }
          }
        ]
      });
    },
    // 通过
    passItem () {
      console.log(this.seletdItem, 'this.seletdItem');
      layer.confirm('请确定是否审核通过?', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              layer.close();
              let aptF = CWSAuditAdopt;
              if (this.seletdItem.TypeKey == '3') {
                aptF = CWSvCoursewareAuditPass;
              }
              aptF(this.seletdItem.ID).then(result => {
                this.getDataList();
              }, error => {
                layer.alert(error.msg);
              });
            }
          }
        ]
      });
    },
    // 忽略
    IgnoreItem () {
      layer.confirm('请确定是否忽略该项?', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              layer.close();
              if (this.seletdItem.TypeKey == 4) {
                // GetMonitorInfo(this.seletdItem.ID,0).then(result => {
                // }, error => {
                //   layer.alert(error.msg);
                // });
                MonitorOverlook(this.seletdItem.ID, 0).then(result => {
                  this.getDataList();
                }, error => {
                  layer.alert(error.msg);
                });
              } else {
                CWSIgnoreEarlyWarning('', this.seletdItem.ID, 12, '').then(result => {
                  this.getDataList();
                }, error => {
                  layer.alert(error.msg);
                });
              }
            }
          }
        ]
      });
    },
    // 计算TOP值
    doOneTopValueCount (index) {
      this.newIndex = index;
      this.topValue = 0;
      let oneTopValue = 54;
      console.log(this.toScrollTop, 'doOneTopValueCount');
      if (this.toScrollTop > 0) {
        this.topValue = oneTopValue * this.newIndex - this.toScrollTop + this.topValue + 64 + 20;
      } else {
        this.topValue = this.topValue + (oneTopValue * this.newIndex) + 64 + 20;
      }
    },
    doStatisticsClick (item) {
      let obj = {
        name: item.name,
        routerName: this.$route.name,
        routertitle: '工作台',
        ModulePowerKey: '',
        moduleName: item.key,
        callBack: { close: () => { this.getSaaSClientStatistics(); } }
      };
      if (item.key == 'cwscoursewareManager') {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: 0,
          name: item.name,
          moduleName: 'cwscoursewareManager',
          data: {},
          callBack: {
            close: () => { this.getSaaSClientStatistics(); }
          }
        });
      } else if (item.key == 'cwsgeneralCoursewareManagement') {
        let type = this.$store.getters.SaaSClientInfo.IsOpenSelfCoursewareKey;
        if (type == 0) {
          layer.alert('未开通此功能');
          return;
        }
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: 0,
          name: item.name,
          moduleName: 'cwsgeneralCoursewareManagement',
          data: {},
          callBack: {
            close: () => { this.getSaaSClientStatistics(); },
            afterSuccess: () => { this.getDataList(); }
          }
        });
      } else if (item.key == 'worksUploadManagement' || item.key == 'operationLogReport') {
        let routerObj = {
          name: item.name,
          routerName: item.key,
          routertitle: item.name,
          auth: true,
          roleName: '',
          ModulePowerKey: item.key == 'worksUploadManagement' ? 0 : 153,
          class: 'bg_yellow',
          firstTitle: 'L',
          moduleName: ''
        };

        console.log(routerObj, 'routerObj');
        this.$bus.emit('needOpenRouter', routerObj);
      } else {
        this.$bus.emit('openCustomDialog', obj);
      }
    }
  }
};
</script>

<style scoped>
.chain_workbench_warning.height .backlog_ul {
  height: 190px;
}
.todo_flex {
  display: flex;
  margin: 0px 10px;
}
.todo_flex .summa_btn {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10px;
  top: 14px;
  background: url(../../../../../public/image/next_icon@2x.png) no-repeat right
    center;
  background-size: 7px;
  cursor: pointer;
}
.todo_summarizing {
  width: 123.5px;
  height: 71.27px;
  line-height: 50px;
  margin: 20px 10px;
  padding-left: 50px;
  font-size: 12px;
  cursor: pointer;
}
.todo_summarizing .summa_num {
  line-height: 0;
  font-size: 24px;
  width: 92px;
  height: 20px;
}
.todo_summarizing.teacher_cion {
  background: url(../../../../../public/cw-img/todo_teacher.png) no-repeat right
    center;
}
.todo_summarizing.class_cion {
  background: url(../../../../../public/cw-img/todo_class.png) no-repeat right
    center;
}
.todo_summarizing.student_works {
  background: url(../../../../../public/cw-img/works_icon.png) no-repeat right
    center;
}
.todo_summarizing.authorized_cion {
  background: url(../../../../../public/cw-img/todo_self_research.png) no-repeat
    right center;
}
.todo_summarizing.courseware_cion {
  background: url(../../../../../public/cw-img/todo_courseware.png) no-repeat
    right center;
}
.todo_summarizing.student_cion {
  background: url(../../../../../public/cw-img/todo_student.png) no-repeat right
    center;
}
.todo_summarizing.operation_log {
  background: url(../../../../../public/cw-img/opration_log.png) no-repeat right
    center;
}
.colorRed {
  color: #ff6c67;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
}
/* .warning_title {
  margin-top: 50px;
} */
</style>


