<template>
  <div class="courses_box">
    <div class="course_content">
      <div class="course_content_img">
        <img class="img_box"
             :src="vCourseCover"
             alt="">
      </div>
      <div class="">
        <div class="course_content_name">
          {{courseInfo.MainDemoName}}
        </div>
        <div class="course_content_info">
          {{courseInfo.SchoolAgeRange}}丨课时单价{{Number(courseInfo.UnitPriceAmount)}}元丨上课次数{{Number(courseInfo.ClassTimeCount)}}
        </div>
        <div class="course_content_text">
          {{courseInfo.CourseIntro}}
        </div>
      </div>
      <div class="course_content_amount">
        {{vPriceAmount}}
        <span class="font_unit">{{vUnitName}}</span>
      </div>
    </div>
    <div class="option_btn">
      <div class="edit_btn"
           @click="editClcik">修改</div>
      <div class="del_btn"
           @click="deleteClcik">删除</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    courseInfo: Object,
  },
  computed: {
    vCourseCover() {
      return this.$store.getters.CDNURL + this.courseInfo.CourseCover
    },
    vPriceAmount() {
      if (Number(this.courseInfo.PriceAmount) > 10000) {
        return Number(this.courseInfo.PriceAmount) / 10000
      } else {
        return Number(this.courseInfo.PriceAmount)
      }
    },
    vUnitName() {
      // 收费方式1终身;2按 年收费;3按次收费
      let str = ''
      if (this.courseInfo.ChargeTypeKey == 1) {
        if (Number(this.courseInfo.PriceAmount) > 10000) {
          str = '万/永久'
        } else {
          str = '元/永久'
        }
      } else if (this.courseInfo.ChargeTypeKey == 2) {
        if (Number(this.courseInfo.PriceAmount) > 10000) {
          str = '万/年'
        } else {
          str = '元/年'
        }
      } else if (this.courseInfo.ChargeTypeKey == 3) {
        if (Number(this.courseInfo.PriceAmount) > 10000) {
          str = '万/课次'
        } else {
          str = '元/课次'
        }
      }
      return str
    },
  },
  methods: {
    editClcik() {
      this.$emit('editClcik', this.courseInfo)
    },
    deleteClcik() {
      this.$emit('deleteClcik', this.courseInfo)
    },
  },
}
</script>

<style scoped>
.courses_box {
  position: relative;
}
.option_btn {
  display: flex;
  position: absolute;
  right: -120px;
  top: 30%;
}
.edit_btn,
.del_btn {
  border: 1px dashed #dcdcdc;
  line-height: 38px;
}
.edit_btn {
  margin-right: 15px;
}
</style>