<template>
  <div class="details_right">
    <div class="defult_dialog_searchform clearfix">
      <!-- 搜索框 -->
      <div class="student_search_form">
        <input type="text"
               class="search_input"
               placeholder="搜索课件名"
               v-model="searchText">
        <span class="remove_btn"
              v-if="searchText.length > 0"
              @click.stop="clearSearText"
              style="right:85px;background-size: 10px;width: 20px;height: 20px;"></span>
      </div>
      <div class="btn_light_blue_search fl"
           @click.stop="searchTextSearch()">搜索</div>
    </div>
    <div class="courseware_right_big">
      <div v-if="this.dataInfo&&this.dataInfo.CoursewarePackageList.length > 0">
        <!-- 课件 -->
        <div class="big_courseware_info"
             v-if="!isCoursewareDetail">
          <!-- 子课包级 当前项沒有子级 -->
          <div class="file_list_ul"
               v-if="vChildList.length>0">
            <div v-for="(item,i) in vChildList"
                 :key="i"
                 @click="doChildClick(item,'')"
                 class="big_courseware_img"
                 :class="item.CoverUrl?'':'big_courseware_default_img'">
              <img :src="item.img"
                   :class="item.CoverUrl?'swimg':'default_img'" />
              <span class="big_courseware_text">{{item.CoursewarePackageKeyValue}}</span>
            </div>
          </div>

          <!-- 目录级 当前项下有子级 -->
          <div v-else>
            <!-- 1 -->
            <div class=""
                 v-if="PackageInfo.length<=0 && !isEmptyCourseData"
                 style="display: flex;flex-wrap: wrap;">
              <div v-for="(item,i) in dataInfo.CoursewarePackageList"
                   :key="i">
                <div class="big_courseware_img"
                     :class="item.CoverUrl?'':'big_courseware_default_img'"
                     @click="doParentClick(item,i)">
                  <img :src="item.img"
                       :class="item.CoverUrl?'swimg':'default_img'" />
                  <span class="big_courseware_text">{{item.CoursewarePackageKeyValue}}</span>
                </div>

              </div>
            </div>
            <!-- 2 -->
            <div class=""
                 v-if="PackageInfo.length>0"
                 style="display: flex;flex-wrap: wrap;">
              <div v-for="(item,i) in PackageInfo"
                   :key="i">
                <div class="big_courseware_img"
                     :class="item.CoverUrl?'':'big_courseware_default_img'"
                     @click="doChildClick(item,true)">
                  <img :src="item.img"
                       :class="item.CoverUrl?'swimg':'default_img'" />
                  <span class="big_courseware_text">{{item.CoursewarePackageKeyValue || item.CourseWarePackageKeyValue}}</span>
                </div>
              </div>
            </div>
            <!-- 空 -->
            <div v-if="PackageInfo.length==0 && isEmptyCourseData"
                 class="course_block_nodata"
                 style="margin-top: 150px;">
              <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
            </div>

          </div>
        </div>
        <!-- 3 大课包 -->
        <div class="big_coursewarePackage_info"
             v-if="isCoursewareDetail"
             style="display: flex;flex-wrap: wrap;">
          <child-courseware-detail :CoursewarePackage="coursewareDataInfo"></child-courseware-detail>
        </div>
      </div>

      <div v-else>
        <div class="cw_content_nodata_txt">还没有课包哦，请与课件方联系~</div>
      </div>
    </div>
    <custom-dialog title="搜索课件"
                   :width="'1240px'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowCoursewareInfo"
                   :before-close="closeCoursewareInfoDilog">
      <search-courseware-info :dataInfo="dataInfo"
                              :Source="Source"
                              :TeacherKey="TeacherKey"
                              :searchText="searchText"
                              :CoursewarePackageKey="CoursewarePackageKey"
                              @cancelClick="closeCoursewareInfoDilog"></search-courseware-info>
    </custom-dialog>
  </div>

</template> 

<script>
import {
  CWSGetAuthBigCoursewarePackageDetails,
  CWSGetBigCoursewarePackageDetailsForPrincipaTeacher,
  CWSGetBigCoursewarePackageDetailsForTeacher,
  GetSubCoursewarePackageForAuth,
  GetSubCoursewarePackageForSelf,
  GetSubCoursewarePackage,
  GetSubCoursewarePackageForTeacher
} from '../../../../API/workbench';
import childCoursewareDetail from './child-courseware-detail';
import searchCoursewareInfo from './search-courseware-info';

export default {
  components: {
    childCoursewareDetail,
    searchCoursewareInfo
  },
  props: {
    CoursewarePackageKey: [Number, String],
    coursewarePackageInfo: Object,
    TeacherKey: [Number, String],
    Source: [Number, String]
  },
  data () {
    return {
      isShowCoursewareInfo: false, // 搜索课件
      isEmptyCourseData: false,
      coursewareDataInfo: {},
      isCoursewareDetail: false,
      searchText: '',
      dataInfo: null,
      PackageInfo: [],
      CoursewarePackageNum: 1
    };
  },
  created () {
    console.log(this.$store.getters, '$store.getters', this.PackageInfo, 'coursewarePackageInfo', this.coursewarePackageInfo, 'TeacherKey', this.TeacherKey);
  },
  mounted () {
  },
  computed: {
    vChildList () {
      let list = [];
      if (this.vDataList && this.vDataList.length > 0) {
        list = this.vDataList.filter(item => item.TypeKey == 0);
      }
      console.log('list', list, this.dataInfo.CoursewarePackageList);
      list.forEach((o, index) => {
        if (o.CoverUrl) {
          o.img = this.$store.getters.CDNURL + o.CoverUrl;
        } else {
          o.img = require('../../../../../public/image/default_directory_bj.png');
        }
      });
      return list;
    },
    vDataList () {
      // let arr = [];
      if (this.dataInfo && this.dataInfo.CoursewarePackageList.length > 0) {
        let treeData = this.dataInfo.CoursewarePackageList.filter(item => {
          this.$set(item, 'isOpt', false);
          this.$set(item, 'childTree', []);
          return item.CoursewarePackageKey > 0;
        });
        // 父节点数据
        const ParentList = treeData.filter(item => item.ParentKey == this.dataInfo.OLAPKey);
        // 老师全部展开 校长只展开第一个
        // ParentList.forEach((o, index) => {
        //   this.$set(o, 'isOpt', true);
        //   if (Number(this.$store.getters.token.RoleKey) == 2) {
        //     if (index != 0) {
        //       this.$set(o, 'isOpt', false);
        //     }
        //   }
        // });
        // 子节点数据
        const childList = treeData.filter(item => item.ParentKey != this.dataInfo.OLAPKey);
        let data = this.$utils.loopData(ParentList, childList, treeData, 'CoursewarePackageKey', 'ParentKey');
        console.log(data, 'data');
        let noData = [];
        data.forEach(e => {
          e.childTree.forEach(o => {
            let i = data.findIndex((item) => { return item.CoursewarePackageKey == o.CoursewarePackageKey; });
            if (i > -1) {
              noData.push(o);
            }
          });
        });
        let arr = [];
        data.forEach(e => {
          let i = noData.findIndex((item) => { return item.CoursewarePackageKey == e.CoursewarePackageKey; });
          if (i == -1) {
            arr.push(e);
          }
        });
        console.log(arr, '12313');
        return arr;
      }
    }
  },
  methods: {
    // 获取校长工作台课件包详情 340BC40DC769411B
    getAuthBigCoursewarePackageDetails (AuthKey, DeviceCode) {
      let CoursewarePackageKey = AuthKey ? AuthKey : this.CoursewarePackageKey;
      CWSGetAuthBigCoursewarePackageDetails(DeviceCode, CoursewarePackageKey).then(result => {
        this.PackageInfo = [];
        this.isEmptyCourseData = false;
        this.isCoursewareDetail = false;
        this.CoursewarePackageNum = 1;
        console.log(result.data, '获取指定日期范围的课件上课统计');
        this.dataInfo = result.data;
        this.dataInfo.CoursewarePackageList.forEach((o, index) => {
          if (o.CoverUrl) {
            o.img = this.$store.getters.CDNURL + o.CoverUrl;
          } else {
            o.img = require('../../../../../public/image/default_directory_bj.png');
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取校长工作台(老师)课件包详情
    getBigCoursewarePackageDetailsForPrincipaTeacher (TeacherKey, BigCoursewarePackageKey, DeviceCode, AuthKey) {
      let CoursewarePackageKey = BigCoursewarePackageKey ? BigCoursewarePackageKey : this.CoursewarePackageKey;
      CWSGetBigCoursewarePackageDetailsForPrincipaTeacher(TeacherKey, CoursewarePackageKey, DeviceCode, AuthKey).then(result => {
        this.PackageInfo = [];
        this.isEmptyCourseData = false;
        this.isCoursewareDetail = false;
        this.CoursewarePackageNum = 2;
        console.log(result.data, '获取指定日期范围的课件上课统计');
        this.dataInfo = result.data;
        this.dataInfo.CoursewarePackageList.forEach((o, index) => {
          if (o.CoverUrl) {
            o.img = this.$store.getters.CDNURL + o.CoverUrl;
          } else {
            o.img = require('../../../../../public/image/default_directory_bj.png');
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取老师工作台课件包详情
    getBigCoursewarePackageDetailsForTeacher (BigCoursewarePackageKey, DeviceCode, AuthKey) {
      let CoursewarePackageKey = BigCoursewarePackageKey ? BigCoursewarePackageKey : this.CoursewarePackageKey;
      CWSGetBigCoursewarePackageDetailsForTeacher(CoursewarePackageKey, DeviceCode, AuthKey).then(result => {
        this.PackageInfo = [];
        this.isEmptyCourseData = false;
        this.isCoursewareDetail = false;
        this.CoursewarePackageNum = 3;
        console.log(result.data, '获取指定日期范围的课件上课统计');
        this.dataInfo = result.data;
        this.dataInfo.CoursewarePackageList.forEach((o, index) => {
          if (o.CoverUrl) {
            o.img = this.$store.getters.CDNURL + o.CoverUrl;
          } else {
            o.img = require('../../../../../public/image/default_directory_bj.png');
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    searchCoursePackageinfo (ParentKey) {
      this.isCoursewareDetail = false;
      this.getGetSubCoursewarePackageForAuth('', ParentKey, '');
    },
    doParentClick (item, index) {
      this.getGetSubCoursewarePackageForAuth(item, item.CoursewarePackageKey, index);
      this.$emit('doOneTitle', item.CoursewarePackageKeyValue, item);
      console.log(this.PackageInfo, 'this.PackageInfo', item);
    },
    // 获取老师工作台课件包详情
    getGetSubCoursewarePackageForAuth (item, ParentKey, index) {
      let fn = '';
      if (this.CoursewarePackageNum == 1) {
        if (this.$store.getters.AcademicMdCwType == 2) {
          fn = GetSubCoursewarePackageForSelf;
        } else {
          fn = GetSubCoursewarePackageForAuth;
        }
      } else if (this.CoursewarePackageNum == 2) {
        fn = GetSubCoursewarePackageForTeacher;
      } else {
        fn = GetSubCoursewarePackage;
      }
      fn(ParentKey, this.TeacherKey).then(result => {
        console.log(result.data, '获取指定日期范围的课件上课统计');
        if (result.data.length == 0) {
          this.isEmptyCourseData = true;
        } else {
          this.isEmptyCourseData = false;
        }
        this.PackageInfo = result.data;
        this.PackageInfo.forEach((o, index) => {
          if (o.CoverUrl) {
            o.img = this.$store.getters.CDNURL + o.CoverUrl;
          } else {
            o.img = require('../../../../../public/image/default_directory_bj.png');
          }
        });
        console.log(this.PackageInfo, 'this.  result.data', result.data);
      }, error => {
        layer.alert(error.msg);
      });
      this.$forceUpdate();
    },
    doChildClick (item, bool) {
      this.isCoursewareDetail = true;
      let CoursewarePackageKey = '';
      let CoursewarePackageKeyValue = '';
      let ParentKeyValue = '';
      CoursewarePackageKey = item.CoursewarePackageKey || item.OLAPKey;
      ParentKeyValue = item.ParentKeyValue;
      CoursewarePackageKeyValue = item.CoursewarePackageKeyValue || item.CourseWarePackageKeyValue;
      console.log(item, bool, '子集列表数据', this.Source, 'CoursewarePackageKeyValue', CoursewarePackageKeyValue, ParentKeyValue);
      if (item.IsLockKey == '1') {
        this.isCoursewareDetail = false;
        layer.alert('总部尚未开放此课件');
        return false;
      }

      this.coursewareDataInfo = {
        MainDemoName: CoursewarePackageKeyValue,
        ParentKeyValue: ParentKeyValue || '',
        OLAPKey: CoursewarePackageKey,
        TeacherKey: this.TeacherKey,
        Source: this.Source
      };
      console.log(this.coursewareDataInfo, 'this.coursewareDataInfo', CoursewarePackageKeyValue);
      if (this.isCoursewareDetail) {
        if (bool) {
          this.$emit('doTwoTitle', CoursewarePackageKeyValue);
        } else {
          this.$emit('doOneTitle', CoursewarePackageKeyValue);
        }
      }

      // this.$dialog.open(this, {
      //   name: '课件详情',
      //   routerName: this.$route.name,
      //   moduleName: 'CoursewareParticular',
      //   data: {
      //     CoursewarePackage: {
      //       MainDemoName: CoursewarePackageKeyValue,
      //       ParentKeyValue: ParentKeyValue || '',
      //       OLAPKey: CoursewarePackageKey,
      //       TeacherKey: this.TeacherKey,
      //       Source: child.Source
      //     }
      //   },
      //   callBack: {
      //     afterSuccess: () => {
      //       console.log(1111123222222);
      //       this.$emit('afterSuccess');
      //     }
      //   }
      // });
    },
    closeCoursewareInfoDilog () {
      this.isShowCoursewareInfo = false;
    },
    // 点击搜索
    searchTextSearch () {
      this.searchText = this.searchText.replace(/ /g, '');
      this.isShowCoursewareInfo = true;
      console.log('this.searchText', this.searchText);
    },
    clearSearText () {
      this.searchText = '';
    }
  }
};
</script>

<style scoped>
.courseware_right_big {
  height: 600px;
  border-radius: 8px;
  margin: 80px 0px 0 0px;
  overflow-y: auto;
}
.big_courseware_file {
  position: relative;
  height: 50px;
  width: 100%;
  padding-left: 36px;
  line-height: 20px;
  font-size: 16px;
  cursor: pointer;
}
.big_courseware_file::after {
  content: '';
  background: url(../../../../../public/cw-img/file_icon@1.png) no-repeat 50%;
  background-size: 26px;
  width: 26px;
  height: 22px;
  position: absolute;
  top: 0px;
  left: 0;
}
.big_courseware_file .top_opt {
  background: url(../../../../../public/image/packDown_icon.png) no-repeat 50%;
  background-size: 12px;
  width: 40px;
  height: 18px;
  height: 20px;
  position: absolute;
}
.big_courseware_file .down_opt {
  background: url(../../../../../public/image/packUp_icon.png) no-repeat 50%;
  background-size: 12px;
  width: 40px;
  height: 18px;
  height: 20px;
  position: absolute;
}
.file_list_ul {
  display: flex;
  flex-wrap: wrap;
}
.file_box {
  width: 179px;
  height: 134px;
  background: #fff;
  border-radius: 8px;
  text-align: center;
  position: relative;
  line-height: 200px;
  margin-bottom: 20px;
  margin-right: 18px;
  cursor: pointer;
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file_box::after {
  content: '';
  background: url(../../../../../public/cw-img/file_icon@3x.png) no-repeat 50%;
  background-size: 70px;
  width: 66px;
  height: 52px;
  position: absolute;
  top: 25px;
  left: 60px;
}
.file_box.no_release::after {
  content: '';
  background: url(../../../../../public/cw-img/file_gray_icon@2x.png) no-repeat
    50%;
  background-size: 60px;
  width: 60px;
  height: 52px;
  position: absolute;
  top: 25px;
  left: 60px;
}
.big_courseware_img {
  position: relative;
  display: flex;
  margin-right: 7rem;
  width: 113rem;
  height: 66rem;
  margin-bottom: 19rem;
  border-radius: 4rem;
  cursor: pointer;
  border: 1px solid #ccc;
}
.big_courseware_default_img {
  background-color: #ccc;
}
.swimg {
  width: 100%;
  height: 100%;
  border-radius: 4rem;
}
.default_img {
  width: 55rem;
  height: 38rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 12rem;
  border-radius: 4rem;
}
.courseware_bj {
  width: 284px;
  height: 167px;
  position: absolute;
  top: 20px;
  left: 0;
  border-radius: 4rem;
  background-color: #ccc;
}
.big_courseware_text {
  position: absolute;
  font-family: Alibaba PuHuiTi;
  color: #333;
  font-size: 6rem;
  bottom: -17rem;
  width: 100%;
  height: 17rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 18rem;
}
.defult_dialog_searchform {
  width: 579px;
  top: 70px;
  left: -10px;
  padding: 10px 12px 20px !important;
}
.student_search_form {
  height: 56px;
  border: 1px solid #ff6000;
  background: linear-gradient(0deg, #fff 0%, #fff 100%),
    linear-gradient(0deg, #f3f5f3 0%, #f3f5f3 100%), #000;
  box-shadow: 0px 3px 8px 0px rgba(255, 96, 0, 0.2);
}
.student_search_form input {
  padding-right: 69px;
  padding-left: 38px;
  background-size: 19px;
  font-size: 18px;
}
.btn_light_blue_search {
  border-radius: 8px;
  background: linear-gradient(225deg, #ff6000 0%, #ff8900 100%), #000;
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #fff;
  bottom: 30px;
}
</style>
