<template>
  <!-- 分析统计 -->
  <div class="general_class_statistics">
    <div>
      <class-statistics-report :classKey="classKey"></class-statistics-report>
    </div>

    <div class="general_class_statistics_bottom">
      <div>
        <class-away-report :classKey="classKey"></class-away-report>
      </div>

      <div>
        <class-student-apply-report :classKey="classKey"></class-student-apply-report>
      </div>
    </div>
  </div>
</template>
<script>

import classStatisticsReport from './class-statistics-report';
import classStudentApplyReport from './class-student-apply-report';
import classAwayReport from './class-away-report';
import {
  // MessageSearchClassAwayForGrade
} from '../../../../../API/workbench.js';
export default {
  data () {
    return {
      //  班级学生报名分析 student-apply-report
      // studentApplyDate: {
      //   startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
      //   endTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd'),
      //   GradeID: '100521'//		整型	必须		数据源：GET	 班级id
      // }
      // 班级学生报名分析列表
      // GetApplyAmountForGradeStudentList: []
    };
  },
  props: {
    classKey: [Number, String] // 列表数据

  },
  components: {
    classStatisticsReport,
    classStudentApplyReport,
    classAwayReport

  },
  created () {
    // this.getMessageSearchClassAwayForGrade();
  },
  watch: {

  },
  mounted () {

  },
  methods: {
    // getMessageSearchClassAwayForGrade () {
    //   MessageSearchClassAwayForGrade(this.studentApplyDate).then(result => {
    //     console.log(result.data, '班级课学生课消统计');
    //     this.studentApplyDate = result.data;
    //   }).catch(error => {
    //     console.log('ErroretAttenReport', error);
    //   });
    // }
  },
  computed: {
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    }
  }
};
</script>
