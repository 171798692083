<template>
  <div class="list_block">
    <div class="box_title">
      {{item.FatherKeyValue+'类'}}
    </div>
    <course-item v-for="(childItem,key) in item.childList"
                 :key="key"
                 :keyIndex="key"
                 :item="childItem"
                 @seletedItem="seletedItem"></course-item>

  </div>
</template>
<script>
import courseItem from './course-item';
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  components: {
    courseItem
  },
  methods: {
    seletedItem (item) {
      this.$emit('seletedItem', item);
    }
  }
};
</script>

