<template>
  <!-- 加盟信息 -->
  <div>
    <div class="YK_added_content_form border_bottom">
      <div class="form_list">
        <div class="form_list_name required">
          <span class="text_box">整店加盟信息</span>
        </div>
        <div class="form_list_content">
          <div class="form_list_switch form_info_value">
            <el-switch :value="submitData.FranchiseTypeKey"
                       active-color="#49b5f3"
                       inactive-color="#a5b6c8"
                       :activeValue="vWholeStoreFranchiseActiveValue"
                       inactive-value="3"
                       @change="wholeStoreJoin">
            </el-switch>
          </div>
        </div>
      </div>
      <div v-if="vOpenWholeStoreFranchise">
        <div class="form_list">
          <div class="form_list_name required">
            <span class="text_box">整店加盟费</span>
          </div>
          <div class="form_list_content">
            <div class="flex">
              <div class="form_list_input_box"
                   style="margin-right: 16px">
                <input-number-form :className="'form_list_input'"
                                   v-model="submitData.StartBrandFranchiseAmount"
                                   :placeholder="'加盟费最低'"
                                   :decimalPlaces="2"
                                   :min="0"></input-number-form>
                <div class="form_list_input_unit">
                  万
                </div>
              </div>
              <span class="form_partition_line"></span>
              <div class="form_list_input_box">
                <input-number-form :className="'form_list_input'"
                                   v-model="submitData.EndBrandFranchiseAmount"
                                   :placeholder="'加盟费最高'"
                                   :decimalPlaces="2"
                                   :min="0"></input-number-form>
                <div class="form_list_input_unit">
                  万
                </div>
              </div>
            </div>
          </div>
        </div>
        <input-text-form :formTitle="'加盟费补充说明'"
                         :formPlaceholder="'请输入内容'"
                         :required="false"
                         v-model="submitData.BrandFranchiseRemark"></input-text-form>

        <input-text-form :formTitle="'加盟区域'"
                         :formPlaceholder="'请输入内容'"
                         :required="true"
                         v-model="submitData.FranchiseArea"></input-text-form>

        <franchise-support :franchiseSupportData="submitData.FranchiseSupport"
                           @updateFranchiseSupportData="afterUpdateFranchiseSupportData"></franchise-support>

        <input-text-form :formTitle="'对手品牌'"
                         :formPlaceholder="'请输入内容'"
                         :required="false"
                         v-model="submitData.RivalBrand"></input-text-form>

        <div class="form_list">
          <div class="form_list_name">
            <span class="text_box">您的优势</span>
          </div>
          <div class="form_list_content">
            <div class="form_list_textarea">
              <textarea v-model="submitData.Advantage"></textarea>
            </div>
          </div>
        </div>

        <!-- <input-text-form :formTitle="'主营行业'"
                        :formPlaceholder="'请输入内容'"
                        :required="true"
                        v-model="submitData.TheMainCourseName"></input-text-form> -->
        <div class="form_list">
          <div class="form_list_name required">
            <span class="text_box">主营行业</span>
          </div>
          <div class="form_list_content">
            <input class="form_list_input next_icon"
                   type="text"
                   :value="submitData.TheMainCourseName"
                   placeholder="请选择主营行业"
                   readonly
                   @click="openCoursListDialog">
          </div>
        </div>
        <!-- <input-dialog :formTitle="'主营行业'"
                          :formPlaceholder="'请输入内容'"
                          :required="true"
                          @showEldilog="openCoursListDialog"
                          v-model="submitData.TheMainCourseName"></input-dialog> -->
      </div>

      <div class="form_list">
        <div class="form_list_name required">
          <span class="text_box">课程加盟信息</span>
        </div>
        <div class="form_list_content">
          <div class="form_list_switch form_info_value">
            <el-switch :value="submitData.FranchiseTypeKey"
                       active-color="#49b5f3"
                       inactive-color="#a5b6c8"
                       :activeValue="vCourseStoreFranchiseInactiveValue"
                       inactive-value="2"
                       @change="coursesJoin">
            </el-switch>
          </div>
        </div>
      </div>
      <div v-if="vOpenCourseStoreFranchise">
        <course-box :dataInfo="submitData.CourseList"
                    :submitInfo="submitData"></course-box>
      </div>
    </div>

    <save-cancel-btn-group class="YK_added_save_cancel_bottom_btn_box"
                           :isSingleSubmit="true"
                           :cancelBtnColor="'btn_hover_bg_blue'"
                           :singleSubmitType="'dynamic'"
                           :cancelBtnText="'上一步'"
                           :btnText="'下一步'"
                           :btnClass="''"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>

    <!-- 选中修改项-课名-->
    <custom-dialog title="主营行业"
                   class="defult_dialog"
                   :visible.sync="isShowCoursListDialog"
                   :before-close="closeCoursListDialog">
      <mutil-select-tree :sourceDate="courseNameList"
                         :selectionDate="{ IDs: submitData.TheMainCourseIDS, Names: submitData.TheMainCourseName }"
                         @buttonClickEvent="confirmChooseCourseName"
                         @cancleClickForMutilSelected="closeCoursListDialog"></mutil-select-tree>
    </custom-dialog>
  </div>
</template> 
<script>
import franchiseSupport from './franchise-support/index';
import courseBox from './course-box/index';
import inputTextForm from '../common/input-text-form';
import inputNumberForm from '../common/input-number-form';
import mutilSelectTree from '../../../common/mutil-select-tree';
import { AmendFranchiseMsg, GetYKCourse } from '../../../../API/workbench';
export default {
  data () {
    return {
      submitData: {
        OLAPKey: 0, // 上架申请key
        StartBrandFranchiseAmount: 0, // 整店加盟费起,
        EndBrandFranchiseAmount: 0, // 整店加盟费止,
        BrandFranchiseRemark: '', // 整店加盟备注,
        FranchiseTypeKey: 0, // 加盟方式,
        FranchiseTypeKeyValue: 0, // 1-整店+课程加盟;2-整店加盟;3-课程加盟,
        FranchiseArea: '', // 加盟区域,
        FranchiseSupport: JSON.stringify(this.$initJson.FranchiseSupportData), // 加盟扶持,JSON字符串
        RivalBrand: '', // 对手品牌,
        Advantage: '', // 优势,
        TheMainCourseName: '', // 主营课程,
        TheMainCourseIDS: '', // 主营课程IDS,
        CourseList: [] // [{"MainDemoName":"课程名","CourseCover":"课程封面","PriceAmount":"报价","ChargeTypeKey":"收费方式1终身;2按年收费;3按次收费","CourseIntro":"课程简介","ClassTimeCount":"上课课次","UnitPriceAmount":"课时单价","SchoolAgeRange":"学龄范围","IndustryKey":"行业","IndustryKeyValue":""}]
      },
      courseNameList: [], // 主营课程列表
      isShowCoursListDialog: false // 主营课程列表
    };
  },
  props: {
    dataInfo: Object
  },
  components: {
    franchiseSupport,
    courseBox,
    inputTextForm,
    inputNumberForm,
    mutilSelectTree
  },
  created () {
    this.initData();
    this.getCourseNameList();
  },
  computed: {
    // 品牌
    vWholeStoreFranchiseActiveValue () {
      let key = this.submitData.FranchiseTypeKey;
      if (key == 1 || key == 2) {
        return this.submitData.FranchiseTypeKey;
      } else {
        return 1;
      }
    },
    // 课程
    vCourseStoreFranchiseInactiveValue () {
      let key = this.submitData.FranchiseTypeKey;
      if (key == 1 || key == 3) {
        return this.submitData.FranchiseTypeKey;
      } else {
        return 1;
      }
    },
    vOpenWholeStoreFranchise () {
      let key = this.submitData.FranchiseTypeKey;
      if (key == 1 || key == 2) {
        return true;
      } else {
        return false;
      }
    },
    vOpenCourseStoreFranchise () {
      let key = this.submitData.FranchiseTypeKey;
      if (key == 1 || key == 3) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    initData () {
      if (Object.keys(this.dataInfo).length > 0) {
        for (let key in this.submitData) {
          if (this.dataInfo[key]) {
            this.submitData[key] = this.dataInfo[key];
          }
        }
        console.log('this.submitData', this.submitData, this.dataInfo);
      }
    },
    wholeStoreJoin () {
      if (this.submitData.FranchiseTypeKey == 1) {
        this.submitData.FranchiseTypeKey = 3;
      } else if (this.submitData.FranchiseTypeKey == 2) {
        this.submitData.FranchiseTypeKey = '';
      } else if (this.submitData.FranchiseTypeKey == 3) {
        this.submitData.FranchiseTypeKey = 1;
      } else {
        this.submitData.FranchiseTypeKey = 2;
      }
      console.log('wholeStoreJoin', this.submitData.FranchiseTypeKey);
    },
    // 课程加盟 1-整店+课程加盟;2-整店加盟;3-课程加盟
    coursesJoin () {
      if (this.submitData.FranchiseTypeKey == 1) {
        this.submitData.FranchiseTypeKey = 2;
      } else if (this.submitData.FranchiseTypeKey == 2) {
        this.submitData.FranchiseTypeKey = 1;
      } else if (this.submitData.FranchiseTypeKey == 3) {
        this.submitData.FranchiseTypeKey = '';
      } else {
        this.submitData.FranchiseTypeKey = 3;
      }
    },
    // 更新 加盟扶持
    afterUpdateFranchiseSupportData (data) {
      console.log('afterUpdateFranchiseSupportData', data);
      this.submitData.FranchiseSupport = JSON.stringify(data);
    },
    // 保存加盟信息
    confirmClick (callBack) {
      console.log('doSubmitClick', this.submitData);
      let flag = false;
      // let franchiseSupport = JSON.parse(this.submitData.FranchiseSupport).find(obj)
      if (this.submitData.FranchiseTypeKey == 1) {
        flag = this.validWholeStoreData() || this.validCourseStoreData();
      } else if (this.submitData.FranchiseTypeKey == 2) {
        flag = this.validWholeStoreData();
      } else if (this.submitData.FranchiseTypeKey == 3) {
        flag = this.validCourseStoreData();
      }
      if (flag) {
        if (typeof callBack == 'function') {
          callBack();
        }
        return false;
      }
      AmendFranchiseMsg(this.submitData).then((result) => {
        this.$emit('saveSuccess', this.submitData, result.data);
      });
    },
    // 找 课程信息所属行业 与 加盟信息主营行业是否有差集
    verifyIndustry () {
      let flag = false;
      let strs = [];
      if (this.submitData.TheMainCourseIDS) {
        strs = this.submitData.TheMainCourseIDS.split(','); // 字符分割
        let _this = this;
        for (var i = 0; i < _this.submitData.CourseList.length; i++) {
          for (var y = 0; y < strs.length; y++) {
            if (_this.submitData.CourseList[i].IndustryKey == strs[y]) {
              flag = true;
              break;
            } else {
              flag = false;
              if (y == strs.length - 1) {
                return;
              }
            }
          }
        }
      }
      console.log(flag, '确认1');
      return flag;
    },
    cancelClick () {
      this.$emit('preStep');
    },
    // 验证整店加盟信息
    validWholeStoreData () {
      let flag = false;
      if (!this.submitData.StartBrandFranchiseAmount) {
        layer.alert('请输入整店最低加盟费');
        flag = true;
      } else if (!this.submitData.EndBrandFranchiseAmount) {
        layer.alert('请输入整店最高加盟费');
        flag = true;
      } else if (!this.submitData.FranchiseArea) {
        layer.alert('请输入加盟区域');
        flag = true;
      } else if (!this.submitData.TheMainCourseName) {
        layer.alert('请选择主营行业');
        flag = true;
      } else if (!this.verifyIndustry() && this.submitData.FranchiseTypeKey == 1) {
        layer.alert('课程信息所属行业与加盟信息主营行业不匹配');
        flag = true;
      }
      return flag;
    },
    // 验证课程加盟信息
    validCourseStoreData () {
      let flag = false;
      if (!this.submitData.CourseList.length) {
        layer.alert('请添加课程信息');
        flag = true;
      }
      return flag;
    },
    // 调用课类课名列表接口
    getCourseNameList () {
      this.courseNameList = [];
      GetYKCourse().then(
        (result) => {
          this.courseNameList = result.data;
          this.courseNameList.splice(0, 0, {
            OLAPKey: '2',
            MainDemoName: '全部课程',
            TypeKey: '1',
            TypeKeyValue: '课类',
            LevelPath: '0.2.',
            FatherKey: '0',
            FatherKeyValue: '',
            StateKey: '2',
            StateKeyValue: '启用',
            UnitPrice: '0.0'
          });
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    // 选中主营课程
    confirmChooseCourseName (selectionDate, callBack, Remarks) {
      if (selectionDate.selectedIDs.length == 0) {
        layer.alert('主营课程不能为空');
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.submitData.TheMainCourseName = selectionDate.selectedNames.join(','); // 主营课程,
      this.submitData.TheMainCourseIDS = selectionDate.selectedIDs.join(',');
      this.closeCoursListDialog();
    },
    // 打开主营课程列表
    openCoursListDialog () {
      this.isShowCoursListDialog = true;
    },
    // 关闭主营课程列表
    closeCoursListDialog () {
      this.isShowCoursListDialog = false;
    }
  }
};
</script>

<style scoped>
.form_list_content .next_icon {
  background: url(../../../../../public/image/next_icon_bottom@2x.png) no-repeat
    95% center;
  background-size: 12px;
  cursor: pointer;
}
.form_partition_line {
  width: 16px;
  height: 1px;
  border-bottom: 1px solid #ececec;
  position: relative;
  right: 8px;
  top: 16px;
}
</style>