<template>
  <div>
    <div class="course_statistics_table_search flex course_statistics_title">
      <div class="">
        <div class="">
          <date-range ref="monthPicker"
                      style="margin-right:36px"
                      :fromTitle="'选择日期'"
                      :searchObj="searchObj"
                      @changeDate="changeSaveDate"></date-range>
        </div>
      </div>
      <div class="table_select_box"
           style="margin-right:20px;min-width:304px">
        <div class="table_select_title">收入类型</div>
        <el-select v-model="searchObj.RevenueType"
                   placeholder="请选择"
                   style="width:239px"
                   ref="resetText"
                   @change="selectOverdueType">
          <el-option v-for="item in overdueTypeList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="course_statistics_search">
        <!-- <div class="mendian_backstage_search">
          <div class="search_box" style="margin-right:10px">
            <div class="search_box_content">
              <div class="">
                <div class="el-input el-input--small el-input--suffix is-focus">
                  <input type="text"
                         v-model="searchObj.searchText"
                         autocomplete="off"
                         placeholder="请输入姓名、姓名首字母、手机号"
                         class="el-input__inner">
                </div>
              </div>
            </div>
          </div>
          <div class="btn_hover_bg_blue search_btn "
               @click.stop="search">搜索</div>
          <div class="btn_hover_white search_clear"
               @click.stop="clearSearchCondition">清除条件</div>
        </div> -->

        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入姓名、姓名首字母、手机号'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <!-- <list class="summarizing_list"
            :key="tableKey"
            :tableData="vdataReportList"
            :totalNum="totalNum"
            :PageCount="PageCount"
            :tableColumns="tableCourseColumns"
            :isSearchText="false"
            :isNeedInnerScroll="false"
            :defaultSort="defaultSort"
            :queryParams="searchObj"
            :vFooterTotal="vFooterTotal"
            @tableBtnClick="doAfterTableBtnClick"
            @loadTableData="getDataList"></list> -->
      <table-list class="summarizing_list"
                  :tableData="vdataReportList"
                  :tableColumns="tableCourseColumns"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :queryParams="searchObj"
                  :footerContent="vFooterTotal"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
import seletedOption from './seleted-option';
import inputSearchContainBtn from '../../common/input-search-contain-btn';

import { MessageSearchOtherRevenue } from '../../../../API/workbench';
export default {
  data () {
    return {
      seletedCourseInfo: {},
      studentDetail: {},
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: '',
        endTime: '',
        IsExportKey: 0,
        RevenueType: 0
      },
      overdueTypeList: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '退课差额',
          value: 1
        }
      ],
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '录入时间',
          prop: 'GenerateTime',
          minWidth: 172,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '经办人',
          prop: 'CreatorKeyValue',
          minWidth: 192,
          sortable: 'custom',
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '收入类型',
          prop: 'RevenueTypeKeyValue',
          minWidth: 218,
          sortable: 'custom',
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '发生人',
          prop: 'StudentKeyValue',
          minWidth: 258,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '金额',
          prop: 'Amount',
          minWidth: 228,
          sortable: 'custom',
          align: 'left',
          // type: 'semaforoNumber',
          type: 'amountItem',
          extend: {
            symbol: 'plus-minus',
            setClassName: (rowData) => {
              return Number(rowData.Amount) > 0 ? '' : 'font_red';
            }
          }
        },
        {
          label: '操作',
          prop: '',
          width: '80',
          sortable: false,
          align: 'center',
          type: 'tableBtn',
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  isHide: false,
                  click: (rowData) => {
                    this.doAfterTableBtnClick(rowData);
                  }
                }

              }
            ]
          }
        }
      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
    seletedOption
  },
  props: {
    toSearchObj: {
      type: Object
    }
  },
  created () {
    if (this.toSearchObj.startTime) {
      this.searchObj.startTime = this.toSearchObj.startTime;
      this.searchObj.endTime = this.toSearchObj.endTime;
    } else {
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
    }
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.CourseNameKey = '0';
        o.StudentKeyValue = o.StudentKeyValue + '(学生)';
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      if (this.totalAmount > 0) {
        text = text + ',合计+' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      } else {
        text = text + ',合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      }
      return text;
    }
  },
  mounted () {
    this.$bus.on(this, 'wsAttendanceOrArrange', (data) => {
      console.log('触发wsAttendanceOrArrange');
      this.getDataList();
    });
    this.getDataList();
  },
  methods: {
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.IsExportKey = 0;
      this.searchObj.RevenueType = 0;
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.getDataList();
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (item, date) {
      console.log('searchData', date);
      this.searchObj.TeacherKey = item.TeacherKey;
      this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(date).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      let seletedYearMonth = Date.new(date).getFullYear() + ' ' + (Date.new(date).getMonth() + 1);
      let nowYearMonth = Date.new().getFullYear() + ' ' + (Date.new().getMonth() + 1);
      if (seletedYearMonth != nowYearMonth) {
        this.searchObj.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new(date)), 'yyyy-MM-dd');
      } else {
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
      }
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '退课差额',
          clist: [
            { title: '录入时间', cName: 'GenerateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '经办人', cName: 'CreatorKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '收入类型', cName: 'RevenueTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '发生人', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '金额', cName: 'Amount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchOtherRevenue(searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj);
      MessageSearchOtherRevenue(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      let obj = {
        courseDetailItem: { TableID: rowData.TableID },
        studentInfo: { OLAPKey: rowData.StudentKey },
        readonly: true
      };
      this.$dialog.open(this, {
        name: '课时退款',
        routerName: this.$route.name,
        moduleName: 'refundmoneybackcourse',
        moduleDescription: '单个学生退款操作，可自行修改退款金额。记得要点击课单进行选中哦！',
        data: { dataInfo: obj }
      });
    },
    selectOverdueType () {

    }

  }
};
</script>