<template>
  <div class="student_file_course_box">
    <div class="form_header_gray"
         :class="item.TableID>0?'cp':''"
         @click.stop="goRouter">
      <div class="date_left">
        {{item.GenerateTime.slice(0,10)}}
      </div>
      <div :class="item.TableID>0?'date_right':''"
           v-if="vClassCardItem">
        <span class="font_gray">课时剩:{{vCurrentCount}}天</span>
      </div>
      <div :class="item.TableID>0?'date_right':''"
           v-else-if="vUnitKeyValue!='天'">

      </div>
    </div>
    <div class="student_file_course_ul">
      <courseItem v-for="(courseItem,key) in vKDlist"
                  :key="key"
                  :item="courseItem"
                  @doAttenCourseSpendDetails="doAttenCourseSpendDetails"></courseItem>
    </div>
  </div>
</template>

<script>
import courseItem from './course-item.vue';
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  components: {
    courseItem
  },
  computed: {
    // 是否有课时卡
    vClassCardItem () {
      let locaItem = this.item.xykd_list.find(o => {
        return o.CurrentCount > 0 && o.UnitKey == 5;
      });
      return locaItem;
    },
    vCurrentCount () {
      // console.log('PeriodCount', this.vClassCardItem.PeriodCount, this.vClassCardItem.CurrentCount);
      if (this.vClassCardItem) {
        if (Number(this.vClassCardItem.PeriodCount) > 0) {
          return Number(this.vClassCardItem.PeriodCount);
        } else {
          return Number(this.vClassCardItem.CurrentCount);
        }
      }
      return 0;
    },
    vKDlist () {
      const newArr = [];
      const taocanlist = [];
      this.item.xykd_list.forEach(o => {
        const locaItem = taocanlist.find(p => {
          return o.TaoCanKey == p.TaoCanKey && o.TaoCanKey > 0;
        });
        if (!locaItem) {
          const newobj = this.$utils.parseJson(o);
          newobj.childList = [];
          taocanlist.push(newobj);
          // newArr.push(o);
        }
      });
      taocanlist.forEach(o => {
        this.item.xykd_list.forEach(p => {
          if (o.TaoCanKey == p.TaoCanKey && p.TaoCanKey > 0) {
            if (o.TableID != p.TableID) {
              o.childList.push(p);
            }
          }
          // o.childList.push(p);
        });
      });
      // console.log(taocanlist, 'taocanlist');
      return taocanlist;
    },
    vUnitKeyValue () {
      if (this.item.UnitKey == 2) {
        return '年';
      } else if (this.item.UnitKey == 3) {
        return '个月';
      } else {
        return '天';
      }
    }
  },
  methods: {
    goRouter () {
      // 跳转路由
      console.log('跳转路由', this.item);
      if (this.item.TableID > 0) {
        this.$emit('seletedItem', this.item);
      }
    },
    doAttenCourseSpendDetails (item) {
      this.$emit('doAttenCourseSpendDetails', item);
    }
  }

};
</script>

<style scoped>
.student_file_course_box {
  margin: -7px 15px 7px;
  position: relative;
}
.student_file_course_ul {
  background-color: #fff;
  border-radius: 4px;
  margin-top: -4px;
  position: relative;
}
.student_file_course_box .form_header_gray .date_right {
  line-height: 45px;
  height: 45px;
  font-size: 14px;
  padding-right: 16px;
  background: url(../../../../../../public/image/next_icon@2x.png) no-repeat
    right center;
  background-size: 8px;
}
</style>
