<template>
  <div class="principal_swiper_list_two"
       @click="goRouter">
    <div class="list_top clearfix">
      <div class="fl"
           style="min-width:5rem">
        <span style="display:inline-block;">{{item.SClassTimeName}}</span>
        <span style="display:inline-block;max-width:130px;padding-right:10px">{{item.GradeClassKeyValue}}</span>
        <span class="m_tea_span">{{item.MTeacherKeyValue}}</span>
      </div>
      <div class="fr">
        安排
        <span class="font_black">{{Number(item.ArrangedNumCount)}}</span>人
      </div>
    </div>
    <!-- isTodayAgo(item) -->
    <div class="list_bottom"
         v-if="Number(item.StatusKey)!=2">
      <div v-if="Number(item.ArrivedNumCount)==0&&Number(item.ArrivedNumCount)==Number(item.ArrangedNumCount)"
           class="list_red">没有上课学生</div>
      <div v-else-if="Number(item.NoAttenceCount)==Number(item.ArrangedNumCount)"
           class="list_red">全部未考勤</div>
      <div v-else-if="Number(item.ArrivedNumCount)>0&&Number(item.ArrivedNumCount)==Number(item.ArrangedNumCount)"
           class="list_green">恭喜，全勤！</div>
      <div class="list_bottom_text"
           v-else>
        签到
        <span class="font_black">{{Number(item.ArrivedNumCount)}}</span>人
        <span v-if="Number(item.LeaveNum)>0">
          丨请假
          <span class="font_red">{{Number(item.LeaveNum)}}</span>人
        </span>
        <span v-if="Number(item.TruantNum)>0">
          丨旷课
          <span class="font_red">{{Number(item.TruantNum)}}</span>人
        </span>
        <span v-if="Number(item.NoAttenceCount)>0">
          丨<span class="font_red">未考勤 {{Number(item.NoAttenceCount)}}人</span>
        </span>

      </div>
    </div>
    <div class="list_bottom"
         v-else>
      <div v-if="Number(item.LeaveNum)==0 &&Number(item.FullRate)>=Number(item.FullRateLineCount)&&Number(item.ClassHourLackNum)==0&&Number(item.TrialNum)==0"
           class="list_green">排课正常</div>
      <div class="list_green"
           v-else-if="Number(item.FullRate)<Number(item.FullRateLineCount)">排课正常</div>
      <div class="list_bottom_text"
           v-else>
        <span v-if="Number(item.LeaveNum)>0">
          请假
          <span class="font_red">{{Number(item.LeaveNum)}}</span>人
        </span>
        <span v-if="Number(item.LeaveNum)>0 &&Number(item.ClassHourLackNum)>0">
          |
        </span>
        <span v-if="Number(item.ClassHourLackNum)>0">
          课时不足
          <span class="font_red">{{Number(item.ClassHourLackNum)}}</span>人
        </span>
        <span v-if="(Number(item.LeaveNum)>0||Number(item.ClassHourLackNum)>0)&&Number(item.TrialNum)>0">
          |
        </span>
        <span v-if="Number(item.TrialNum)>0">
          试课
          <span class="font_red">{{Number(item.TrialNum)}}</span>人
        </span>
        <!-- <span v-if="(Number(item.LeaveNum)>0||Number(item.ClassHourLackNum)>0||Number(item.TrialNum)>0)&&Number(item.FullRate)<Number(item.FullRateLineCount)">
          |
        </span> -->
        <!-- <span class="fr_span"
              v-if="Number(item.FullRate)<Number(item.FullRateLineCount)">满员率<<span class="font_red ">{{Number(item.FullRateLineCount)}}%</span></span> -->
      </div>
    </div>
    <div class="list_btn"></div>
  </div>
</template>
<script>
export default {
  data () {
    return {};
  },
  props: ['item'],
  methods: {
    goRouter () {
      console.log(this.item);
      this.$emit('clickitem', this.item);
      // this.isTodayAgo()
      // this.$router.push({
      //   name: "attendanceArrange",
      //   query: {
      //     id: this.item.OLAPKey,
      //     title: "安排考勤"
      //   }
      // });
    },
    isTodayAgo (item) {
      let isTodayAgo = Date.new(this.item.ClassTime) <= Date.new(this.$utils.formatDatet(Date.new(), 'yyyy-MM-dd'));
      console.log(isTodayAgo, 'isTodayAgo');
      return isTodayAgo;
    }
  }
};
</script>
