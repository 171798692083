<template>
  <div class="atten_report_top">
    <!-- <div class="attn_report_top_div">
      <div>
        <span class="font_bigger">{{setNumber(attenReport.Attendance_ThisMonth.ArrangeClassCount )}}</span>
        <span class="font_gray">节</span>
      </div>
      <div class="atten_report_span_title">本月共排课</div>
      <div class="atten_report_span_bottom">
        <span class="font_gray">今天</span><span class="font_blue">+12,000</span>
      </div>
    </div>
    <div class="attn_report_top_div">
      <div>
        <span class="font_bigger">{{setNumber(attenReport.Attendance_ThisMonth.ArrangeCount)}}</span>
        <span class="font_gray">人</span>
      </div>
      <div class="atten_report_span_title">共安排</div>
      <div class="atten_report_span_bottom">
        <span class="font_gray">今天</span><span class="font_blue">+12,000</span>
      </div>
    </div> -->
    <div class="attn_report_top_div">
      <div>
        <span class="font_bigger"
              :style="setSizeByLength(attenReport.Attendance_ThisMonth.ArrangeClassCount||0,2)">
          <span class="font_green">{{attenReport.Attendance_ThisMonth.HavedClass||0}}</span>
          <span class="">/{{attenReport.Attendance_ThisMonth.ArrangeClassCount||0}}</span>
        </span>
        <!-- <span class="font_bigger"
              :style="setSizeByLength(attenReport.Attendance_ThisMonth.TotalRecableAmount||0)">{{$utils.setMoneyformatToParseInt(attenReport.Attendance_ThisMonth.TotalRecableAmount||0)}}</span> -->
        <!-- <span class="font_gray">节</span> -->
      </div>
      <div class="atten_report_span_title">本月排课</div>
      <div class="atten_report_span_bottom">
        <span class="font_gray">今天</span><span class="font_green">{{isAdd(Number(attenReport.Attendance_Today.HavedClass))}}</span>
      </div>
    </div>
    <div class="attn_report_top_div">
      <div>
        <span class="font_bigger"
              :style="setSizeByLength(attenReport.Attendance_ThisMonth.TotalDeductAmount||0)">{{$utils.setMoneyformatToParseInt(attenReport.Attendance_ThisMonth.TotalDeductAmount||0)}}</span>
        <!-- <span class="font_gray">人</span> -->
      </div>
      <div class="atten_report_span_title">本月课消</div>
      <div class="atten_report_span_bottom">
        <span class="font_gray">今天</span><span class="font_blue">{{isAdd(Number(attenReport.Attendance_Today.TotalDeductAmount))}}</span>
      </div>
    </div>
    <div class="attn_report_top_div">
      <div>
        <span class="font_bigger font_green">{{setNumber(attenReport.Attendance_ThisMonth.Attence3)}}</span>
        <!-- <span class="font_gray">人</span> -->
      </div>
      <div class="atten_report_span_title">签到学生</div>
      <div class="atten_report_span_bottom">
        <span class="font_gray">今天</span><span class="font_green">{{isAdd(Number(attenReport.Attendance_Today.Attence3))}}</span>
      </div>
    </div>
    <div class="attn_report_top_div">
      <div>
        <span class="font_bigger font_red">{{vNoAtten}}</span>
        <!-- <span class="font_gray">人</span> -->
      </div>
      <div class="atten_report_span_title">未到学生</div>
      <div class="atten_report_span_bottom">
        <span class="font_gray">今天</span><span class="font_red">{{vTodayNoAtten}}</span>
      </div>
    </div>
    <div class="attn_report_top_div">
      <div>
        <span class="font_bigger">{{attenRatePercent(attenReport.Attendance_ThisMonth.Attence3,attenReport.Attendance_ThisMonth.TotalPerson)}}</span>
      </div>
      <div class="atten_report_span_title">出勤率</div>
      <div class="atten_report_span_bottom">
        <span class="font_gray">今天</span><span class="font_blue">{{attenRatePercent(attenReport.Attendance_Today.Attence3,attenReport.Attendance_Today.TotalPerson)}}</span>
      </div>
    </div>
  </div>
  <!-- <div class="principal_summarizing">
    <div class="principal_summarizing_title">
      安排考勤
    </div>
    <div class="principal_summarizing_content clearfix">
      <div class="content_list fl">
        <div class="fl">
          <div class="content_list_title">本月签到</div>
          <div><span class="font_black">{{setNumber(attenReport.Attendance_ThisMonth.Attence3)}}</span><span class="">人</span></div>
        </div>
        <div class="fl">
          <div class="content_list_title">安排人数</div>
          <div>
            <span class="font_black ">{{setNumber(attenReport.Attendance_ThisMonth.TotalPerson)}}</span><span class="">人</span>
          </div>
        </div>
      </div>
      <div class="content_list fl">
        <div class="fl">
          <div class="content_list_title">出勤率</div>
          <div><span class="font_black">{{attenRatePercent(attenReport.Attendance_ThisMonth.Attence3,attenReport.Attendance_ThisMonth.TotalPerson)}}</span></div>
        </div>
        <div class="fl">
          <div class="content_list_title">上课节数</div>
          <div><span class="font_black ">{{setNumber(attenReport.Attendance_ThisMonth.HavedClass)}}</span><span class="">节</span></div>
        </div>
      </div>
      <div class="content_list fl">
        <div class="fl">
          <div class="content_list_title">上月签到</div>
          <div><span class="font_black">{{setNumber(attenReport.Attendance_LastMonth.Attence3)}}</span><span class="">人</span></div>
        </div>
        <div class="fl">
          <div class="content_list_title">安排人数</div>
          <div>
            <span class="font_black ">{{setNumber(attenReport.Attendance_LastMonth.TotalPerson)}}</span><span class="">人</span>
          </div>
        </div>
      </div>
      <div class="content_list fl">
        <div class="fl">
          <div class="content_list_title">出勤率</div>
          <div><span class="font_black">{{attenRatePercent(attenReport.Attendance_LastMonth.Attence3,attenReport.Attendance_LastMonth.TotalPerson)}}</span></div>
        </div>
        <div class="fl">
          <div class="content_list_title">上课节数</div>
          <div><span class="font_black ">{{setNumber(attenReport.Attendance_LastMonth.HavedClass)}}</span><span class="">节</span></div>
        </div>
      </div>
    </div>
  </div> -->

</template>
<script>
import { getKQStatistics } from '../../API/workbench';
export default {
  data () {
    return {
      attenReport: {
        Attendance_ThisMonth: {},
        Attendance_LastMonth: {},
        Attendance_Yesterday: {},
        Attendance_Today: {},
        Attendance_ThisYear: {}
      }
    };
  },
  created () {
    this.getAttenReport();
  },
  computed: {
    vNoAtten () {
      return Number(this.attenReport.Attendance_ThisMonth.NoAttenceCount || 0);
      // return Number(this.attenReport.Attendance_ThisMonth.Attence2) + Number(this.attenReport.Attendance_ThisMonth.Attence4) + Number(this.attenReport.Attendance_ThisMonth.Attence5) || 0;
    },
    vTodayNoAtten () {
      // let noAttenNum = (Number(this.attenReport.Attendance_Today.Attence2) + Number(this.attenReport.Attendance_Today.Attence4) + Number(this.attenReport.Attendance_Today.Attence5)) || 0;
      let noAttenNum = Number(this.attenReport.Attendance_Today.NoAttenceCount || 0);
      return (noAttenNum > 0 ? '+' : '') + noAttenNum;
    }
  },
  methods: {
    // 根据字数设定字体大小
    setSizeByLength (num, count) {
      num = num || 0;
      count = count || 5;
      let size = parseInt(num).toString().length <= count ? 'font-size:22px' : 'font-size:16px';
      return size;
    },
    isAdd (num) {
      num = num || 0;
      return (num > 0 ? '+' : '') + num.toFixed(0);
    },
    getAttenReport () {
      getKQStatistics().then(result => {
        this.attenReport = result.data;
        console.log(result.data, 'getAttenReport');
      });
    },
    // 比较数字同时格式化
    compareNumber (nowNum, oldNum) {
      nowNum = Number(nowNum) || 0;
      oldNum = Number(oldNum) || 0;
      console.log(nowNum - oldNum, this.$utils.setMoneyformatToParseInt((nowNum - oldNum)));
      return ((nowNum - oldNum) > 0 ? '+' : '') + this.$utils.setMoneyformatToParseInt((nowNum - oldNum));
    },
    // 比较未到学生
    compareNoAttnNumber (newData, oldData) {
      let nowNum = Number(newData.Attence2) + Number(newData.Attence4) + Number(newData.Attence5) || 0;
      let oldNum = Number(oldData.Attence2) + Number(oldData.Attence4) + Number(oldData.Attence5) || 0;
      return ((nowNum - oldNum) > 0 ? '+' : '') + this.$utils.setMoneyformatToParseInt((nowNum - oldNum));
    },
    setNumber (num) {
      num = num || 0;
      return Number(num);
    },
    attenRatePercent (num, lastnum) {
      num = this.setNumber(num);
      lastnum = this.setNumber(lastnum);
      return Math.round((Math.round(num / lastnum * 10000) / 100.00) || 0) + '%';
    },
    // 计算比率
    toPercent (num, lastnum) {
      num = this.setNumber(num);
      lastnum = this.setNumber(lastnum);
      let rate = 0;
      if (num < lastnum) {
        rate = 100 - (Math.round(num / lastnum * 10000) / 100.00);
        console.log(rate, 'rete', (Math.round(num / lastnum * 10000) / 100.00));
      } else if (num == lastnum) {
        rate = 100;
      } else {        // 大于
        rate = (Math.round(num / lastnum * 10000) / 100.00) - 100;
      }
      return rate + '%';// 小数点后两位百分比
    },
    compareNum (num, lastnum) {
      return this.setNumber(num) - this.setNumber(lastnum);
    }
  }
};
</script>

