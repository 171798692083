<template>
  <!-- 全部学生 -->
  <div class="homework_student_list"
       v-if="this.studentItem.TasksStatus!=3">
    <div class="student_list_img">
      <img class="img_box"
           :src="vStudentImg"
           :onerror="defaultImg"
           alt="">
    </div>
    <div class="student_list_name">
      <div class="name_box">{{studentItem.StudentKeyValue}}</div>
      <div class="student_list_status"
           :class="vAttenceType">
        {{vAttenceStatusKeyValue}}
      </div>
    </div>
    <div class="student_list_score">
      <div v-for="(item,key) in studentItem.Currency"
           :key="key">
        <div class="list_score"></div>
      </div>
      <!-- <div class="list_score" ></div>
          <div class="list_score"></div>
          <div class="list_score"></div>
          <div class="list_score"> </div>
          <div class="list_score"></div> -->
    </div>
    <div class="student_list_type">
      {{vTasksStatus}}
    </div>
  </div>
</template>

<script>
// import browseItemHead from '../common-component/browse-box/browse-item-head';
import browseItemContent from '../../common-component/browse-box/browse-item-content';
import releaseBtn from '../../common-component/release-btn';
export default {
  components: {
    // browseItemHead,
    browseItemContent,
    releaseBtn
  },
  props: {
    studentItem: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  computed: {
    // 头像
    vStudentImg () {
      if (this.studentItem.HeadImgSupport) {
        if (this.studentItem.HeadImgSupport.indexOf('http') > -1) {
          return this.studentItem.HeadImgSupport;
        } else {
          return this.$store.getters.CDNURL + this.studentItem.HeadImgSupport;
        }
      } else {
        return '';
      }
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../../public/image/face_screenage_noImg.png') + '"';
    },
    vAttenceStatusKeyValue () {
      if (this.studentItem.AttenceStatusKey == 2) {
        return '未考勤';
      }
      if (this.studentItem.AttenceStatusKey == 3) {
        return '签到';
      }
      if (this.studentItem.AttenceStatusKey == 4) {
        return '请假';
      }
      if (this.studentItem.AttenceStatusKey == 5) {
        return '旷课';
      }
    },
    vAttenceType () {
      if (this.studentItem.AttenceStatusKey == 2) {
        return '';
      }
      if (this.studentItem.AttenceStatusKey == 3) {
        return 'type_green';
      }
      if (this.studentItem.AttenceStatusKey == 4) {
        return 'type_yellow';
      }
      if (this.studentItem.AttenceStatusKey == 5) {
        return 'type_red';
      }
    },
    vTasksStatus () {
      if (this.studentItem.TasksStatus == 0) {
        return '未提交';
      }
      if (this.studentItem.TasksStatus == 1) {
        return '待批改';
      }
      if (this.studentItem.TasksStatus == 2) {
        return '已批改';
      }
      if (this.studentItem.TasksStatus == 3) {
        return '未布置';
      }
    }
  },
  methods: {
    // 点击切换
    onClickEvent (data) {
      // this.$set(this.item, 'isActive', true);
      // this.$emit('onClickSwitch', this.item);
    },
    correctTaskButClick () {

    }
  }
};
</script>

<style>
</style>