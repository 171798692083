<template>
  <!-- 点评学分详情-->
  <div class="body_number_box">
    <div class="">
      <div  v-if="vCurrency>0" class="body_score_ul flex">
        <div class="body_score_list"
             v-for="(item,index) in vCurrency"
             :key="index"></div>
      </div>
    </div>
    <!-- click_like body_number_like-->
    <div class="">
      <div class="flex"
           v-if="isLikeCommentShow">
        <div :class="vIsNotclickPraise>0?'click_like':'body_number_like'"
             @click.stop="clickPraise">
          {{vIsNotclickPraise>0?'取消':'点赞'}} <span v-if="vIsNotclickPraise==0&&dataInfo.PraiseNum>0">{{vPraiseNum}}</span>
        </div>
        <div class="body_number_comment"
             @click.stop="clickComment">
          评论 {{dataInfo.ReplyNum>0?dataInfo.ReplyNum:''}}
        </div>
      </div>
    </div>
  </div>
</template> 

<script>

export default {
  components: {

  },
  data () {
    return {
      isclickOptLike: false
      // newCurrency: 0
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: {}
    },
    isLikeCommentShow: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    // 深度监听对象
    // 'dataInfo.Currency': {
    //   handler (newVal, oldVal) {
    //     this.newCurrency = newVal;
    //     // console.log(newVal, '新');
    //     // console.log(oldVal, '旧');
    //   },
    //   deep: true
    //   // immediate: true
    // }

  },
  created () {
    // this.newCurrency = this.dataInfo.Currency;
  },
  computed: {
    vPraiseNum () {
      if (this.dataInfo.PraiseNum && Number(this.dataInfo.PraiseNum) > 0) {
        return Number(this.dataInfo.PraiseNum);
      } else {
        return 0;
      }
    },
    vIsNotclickPraise () {
      if (this.dataInfo.PraiseList.length > 0) {
        let hasFind = false;
        hasFind = this.dataInfo.PraiseList.findIndex(p => p.ReaderID == this.$store.getters.getToken.UserID) >= 0;
        hasFind = hasFind ? 1 : 0;
        console.log(hasFind, 'hasFind');
        return hasFind;
      } else {
        return 0;
      }
    },
    vCurrency () {
      // this.dataInfo.ReaderSetContent = this.dataInfo.ReaderSetContent.replace(/,/gi, '、');
      // let list = this.dataInfo.ReaderSetContent.split('、');
      // console.log(this.newCurrency, list.length, 'length');
      // if (this.newCurrency && list.length > 0) {
      //   let num = Math.ceil(this.newCurrency / list.length);
      //   num = num > 5 ? 5 : num;
      //   return num;
      // } else {
      //   return 0;
      // }
      if (this.dataInfo.Currency > 5) {
        return 5;
      } else {
        return Number(this.dataInfo.Currency);
      }
    },
    vAuthor () {
      if (this.dataInfo.Author == this.$store.getters.getToken.UserName) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    // doHideImageView () {
    //   this.$emit('doHideImageView');
    // }
    // 点赞
    clickPraise () {
      // this.isclickOptLike = !this.isclickOptLike;
      if (this.vAuthor) {
        let newPraiseType = this.vIsNotclickPraise == 0 ? 1 : 0;
        this.$emit('onClickPraise', this.dataInfo, newPraiseType);
      } else {
        layer.alert('亲，不能给自己点赞哦!');
      }
    },
    // 评论
    clickComment () {
      this.$emit('onClickComment', this.dataInfo);
    }
  }
};
</script>

<style>
</style>