<template>
  <!-- 通用说明模块 -->
  <div style="position: relative"
       v-if="isExplainTextShow">
    <span class="tips_info_required"
          v-if="required">*</span>
    <div class="form_dialog_tips"
         :style="newStyle">
      <span>{{explainText}}</span>
      <div class="tips_close"
           @click="isExplainTextShow=false"
           :style="closeStype"
           v-if="!required"></div>
    </div>
  </div>
</template> 

<script>
export default {
  data () {
    return {
      isExplainTextShow: true // 说明模块开关
    };
  },
  props: {
    // 标题，必填
    explainText: {
      type: String,
      default: ''
    },
    // 是否显示红星标示
    required: {
      type: Boolean,
      default: false
    },
    newStyle: {
      type: String,
      default: ''
    },
    closeStype: {
      type: String,
      default: ''
    }
  },
  components: {

  },

  created () {

  },
  mounted () {

  },
  activated () {

  },
  watch: {

  },
  computed: {

  },
  methods: {

  }

};
</script>

  <style>
/*  */
</style>