<template>
  <!-- 精选推荐  recommended_works_div recommended_works_list-->
  <div class="max_courseware"
       style="display: flex;justify-content: space-between;margin-bottom: 100px;">
    <!-- 精选推荐左边 -->
    <div class="hq_fine_works_left">
      <div class="all_works">
        全部作品 {{campusWorksLeftList.StudentPhotoCount}}
      </div>
      <div class="overflow_y_div">
        <div class="fine_campus_works"
             v-for="item,index in campusWorksLeftList.List"
             :class="{'opt':item.isOpt}"
             :key="index"
             @click.stop="campusLeftClick(item)">
          <div class="campus_div">{{item.SaaSClientKeyValue}}</div>
          <div class="fine_div">精选作品: {{item.SaaSClientCount}}</div>
        </div>
      </div>
    </div>
    <!-- 精选推荐右边 -->
    <div class="hq_fine_works_right"
         v-if="campusWorksRightList.length != 0">
      <div class="courseware_list"
           v-for="item,index in campusWorksRightList"
           @mouseleave.stop="mouseOutEvent(item)"
           :key="index">
        <div class="max_list_box"
             @click.stop="selectEvent">
          <div class="list_img"
               @mouseenter="mouseOverEvent(item)">
            <div class="list_number">{{index+1}}</div>
            <!-- 作品按钮 显示... -->
            <div class="list_img_btn"
                 v-if="isShowBtn&&newOperateMenu.length>0&&item.isOpt"
                 @mouseenter="showOperateMenu">
              <div class="list_img_ul"
                   v-if="isShowOperateMenu">
                <!-- 作品按钮按钮显示 -->
                <div v-for="menu in newOperateMenu"
                     :key="menu.key">
                  <div class="list_img_menu"
                       :class="menu.className"
                       v-if="menu.isShow"
                       @click.stop="doOperateItemClick(item,menu)">
                    {{menu.name}}
                  </div>
                </div>

              </div>
            </div>
            <img class="img_box"
                 :src="$store.getters.CDNURL+item.PhotoUrl"
                 alt="">
          </div>
          <div class="max_list_info">
            <div class="list_name">
              {{item.StudentName}}
            </div>
            <div class="list_text">
              <span>指导老师：{{item.GuideTeacherName}}</span>
            </div>
            <div class="list_text">
              <span>创作日期：{{$utils.formatDateToLine(item.CreateDateTime)}}</span>
            </div>
            <div class="list_text">
              <span>所属校区：{{item.SaaSClientKeyValue}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else
         style="margin-top: 20px;">
      <div class="right_nodata">亲，当前还没有任何数据哦~</div>
    </div>
    <custom-dialog :title="dataInfo.StudentName"
                   :visible.sync="isShowPhoneFormPopup"
                   :before-close="closePhoneFormPopup">
      <look-works-popup :PhotoUrl="dataInfo.PhotoUrl"
                        @closeFrom="closePhoneFormPopup"></look-works-popup>
    </custom-dialog>
  </div>
</template>
<script>
import { HQCollectStudentPhoto } from '../../../API/workbench';
import LookWorksPopup from '../recommended-works/look-works-popup';
export default {
  data () {
    return {
      isShowPhoneFormPopup: false,
      dataInfo: {},
      WorksTitle: '查看作品',
      isShowBtn: false, // 显示按钮
      isShowOperateMenu: false, // 显示菜单
      isShowMenuBeing: true, // 是否已是移出状态
      isShowLookWorksPopup: false,
      newOperateMenu: [],
      operateMenu: [
        { name: '查看', className: 'see_icon', key: 'see', isShow: true },
        { name: '回收', className: 'recover_icon', key: 'recover', isShow: true }
      ]
    };
  },
  components: {
    LookWorksPopup
  },
  props: {
    campusWorksLeftList: [Object, Array],
    campusWorksRightList: Array
  },
  created () {
    console.log(this.campusWorksLeftList, 'campusWorksLeftListcampusWorksLeftList');
  },
  computed: {
  },
  methods: {
    // 鼠标移入,显示按钮
    mouseOverEvent (item) {
      item.isOpt = true;
      console.log('鼠标移入');
      this.isShowBtn = true;
      this.newOperateMenu = this.$utils.parseJson(this.operateMenu);
    },
    // 鼠标移入,显示操作菜单
    showOperateMenu () {
      this.isShowOperateMenu = true;
    },
    // 鼠标离开
    mouseOutEvent (item) {
      item.isOpt = false;
      this.isShowBtn = false;
      this.isShowOperateMenu = false;
    },
    // 点击事件
    selectEvent () {
      this.$emit('selectEvent', this.campusWorksRightList);
    },
    // 点击选择校区
    campusLeftClick (item) {
      this.campusWorksLeftList.List.forEach(o => {
        if (o.SaaSClientKey == item.SaaSClientKey) {
          o.isOpt = true;
        } else {
          o.isOpt = false;
        }
      });
      this.$emit('campusLeftClick', item.SaaSClientKey);
    },
    closePhoneFormPopup (type) {
      if (!type) {
        this.isShowPhoneFormPopup = false;
      }
    },
    // 操作项
    doOperateItemClick (item, menu) {
      console.log(item, 'item', menu);
      if (menu.name == '查看') {
        this.dataInfo = item;
        this.isShowPhoneFormPopup = true;

        item.isOpt = false;
        this.isShowBtn = false;
        this.isShowOperateMenu = false;
      } else {
        // 回收
        layer.confirm(
          '确定将此作品回收?',
          {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  HQCollectStudentPhoto(Number(item.OLAPKey)).then(result => {
                    layer.close('回收作品成功');
                    this.$emit('afterSuccess', Number(item.OLAPKey));
                  }, error => {
                    console.log(error.msg);
                  });
                  layer.close();
                }
              }
            ]
          }
        );
      }
    }
  }
};
</script>
<style scoped>
.hq_fine_works_left {
  width: 264px;
  height: 864px;
  margin-top: 20px;
  border-radius: 10px 0 0 10px;
  background: #fff;
}
.hq_fine_works_left > .all_works {
  line-height: 80px;
  height: 80px;
  border-bottom: 2px solid #ececec;
  margin-right: 0;
  padding-left: 20px;
  font-size: 16px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 700;
  color: #333333;
}
.hq_fine_works_left > .overflow_y_div .fine_campus_works {
  line-height: 23px;
  cursor: pointer;
  height: 80px;
  margin-right: 0;
  padding-left: 20px;
  padding-top: 20px;
}
/* 回收 */
.courseware_list .list_img_menu.recover_icon {
  background-image: url(../../../../public/image/recover_icon01.png);
}

.courseware_list .list_img_menu.recover_icon:hover {
  background-image: url(../../../../public/image/recover_icon02.png);
}
/* 查看 */
.courseware_list .list_img_menu.see_icon {
  background-image: url(../../../../public/image/see_icon01.png);
}

.courseware_list .list_img_menu.see_icon:hover {
  background-image: url(../../../../public/image/see_icon02.png);
}
.overflow_y_div .opt {
  border-left: 3px solid #3498db;
  background: #eaf4fb;
}
.hq_fine_works_left > .overflow_y_div .fine_campus_works .campus_div {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}
.hq_fine_works_left > .overflow_y_div .fine_campus_works .fine_div {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}
.overflow_y_div {
  overflow-y: auto;
  height: 780px;
}
/*滚动条样式*/
.overflow_y_div::-webkit-scrollbar,
.hq_fine_works_right::-webkit-scrollbar {
  width: 4px;
}
.overflow_y_div::-webkit-scrollbar-thumb,
.hq_fine_works_right::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.overflow_y_div::-webkit-scrollbar-track,
.hq_fine_works_right::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.hq_fine_works_right {
  width: 1050px;
  height: 864px;
  border-radius: 0px 10px 10px 0px;
  margin-top: 20px;
  background: #fff;
  overflow-y: auto;
}
.courseware_list .list_img_btn {
  z-index: 6;
  position: absolute;
  top: 20px;
  right: 50px;
  width: 42px;
  height: 32px;
  cursor: pointer;
  background: url(../../../../public/image/courseware_menu_btn_gray.png)
    no-repeat;
  background-size: 30px;
}
.courseware_list .list_img_btn:hover {
  background: url(../../../../public/image/courseware_menu_btn_blue.png)
    no-repeat;
  background-size: 30px;
}
.courseware_list .list_img_btn {
  top: 8px;
  right: 0px;
  background-size: 40px;
}
.list_img {
  width: 300px !important;
  height: 168px !important;
}
.courseware_list {
  display: flex;
  width: 310px;
  float: left;
  margin: 20px 10px 20px 20px;
}
.courseware_list .max_list_box {
  height: 295px;
  background: #fff;
  border-radius: 8px;
  padding: 6px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.courseware_list .list_btn {
  position: relative;
  width: 0px;
}
.courseware_list .max_list_info {
  width: 280px;
  height: 50px;
  cursor: pointer;
  margin: 10px 10px 10px 15px;
}
.capacity {
  padding-left: 2px;
}
.list_text {
  margin-top: 3px;
}
.courseware_list .list_number {
  left: 0px !important;
  z-index: 10;
}
.right_nodata {
  width: 1050px;
  height: 864px;
  font-size: 14px;
  line-height: 18px;
  color: #a9aaaa;
  text-align: center;
  padding-top: 490px;
  /* position: absolute;
  top: 156px;
  left: 290px;
  right: 0; */
  background: #fff url(../../../../public/image/courseware_no_data.png)
    no-repeat center center;
  margin: 0 auto;
  border-radius: 10px;
  z-index: -1;
  border-radius: 0px 10px 10px 0px;
}
.img_box {
  width: auto !important;
  height: auto !important;
  display: block;
  margin: 0 auto;
  max-width: 300px;
  max-height: 168px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>