<template>
  <div class="home_page">
    <!--  :style="{'transform': 'scaleY('+scaleY+')'}" -->
    <div class="home_page_left_contont">
      <div class="left_contont_top">
        <div class="contont_top_left">
          <searchContent :dataInfo="dataInfo"
                         @doAfterClickFunctionBtn="doAfterClickFunctionBtn"></searchContent>
        </div>
        <div class="contont_top_right">
          <report-item :className="'type_blue'"
                       style="flex:1"
                       :dataInfo="teacherReportInfo"
                       @doAfterClickFunctionBtn="doAfterClickFunctionBtn"
                       @showTeacherList="showTeacherList"></report-item>
        </div>
      </div>
      <!-- 课包 -->
      <div class="workbench_view_attendance my_courewarebag massive_bg"
           v-if="isCoursewareListNull"
           :style="{'width':this.swiperWidth+'px'}">
        <div class="workbench_view_title clearfix form_info_field"
             style="border-bottom:inherit">
          <span class="fl">我的课包</span>
          <!-- <input-form-tip :tips="'统计每天上课节数，考勤数据，方便查看每天的上课考勤情况，也可以对每节课的考勤数据进行操作。'"></input-form-tip> -->
          <span class="font_gray"
                style="margin-left: 20px;">向左拖动查看更多课包</span>
        </div>
        <div ref="swiper1"
             class="swiper-container swiper_attendance_contents"
             style="height: 160px;padding-top:10px">
          <div class="swiper-wrapper">
            <courseware-swiper v-for="(item, key) in coursewareList"
                               :key="key"
                               :item="item"
                               @chgCoureDateAfter="chgCouseware"></courseware-swiper>
          </div>
        </div>
      </div>
      <!-- 考勤 -->
      <div class="workbench_view kq_workbench_view">
        <div class="workbench_view_box">
          <div class="workbench_view_left">
            <attendance-date @showDialog="showDialog"
                             @doAfterClickFunctionBtn="doAfterClickFunctionBtn"
                             @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog">

            </attendance-date>
          </div>
        </div>
      </div>
    </div>
    <right-content></right-content>

    <div>
      <custom-dialog title="老师业绩统计"
                     width="1000px"
                     class=""
                     :visible.sync="isshowList"
                     :before-close="closeisshowList">
        <teacher-report :teacherKey="$store.getters.token.UserID"></teacher-report>
      </custom-dialog>
      <dialog-factory ref="indexViewDialogFactory"
                      :key="'teacherAttenClass'"
                      :routerName="'teacherAttenClass'"></dialog-factory>
      <dialog-factory-common ref="indexViewDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import coursewareSwiper from './courseware-swiper/index.vue';
import dialogFactory from "../common/dialog-factory";
import attendanceDate from "../new-index-view/attendance-date";
import rightContent from '../home-page/right-content'
import searchContent from './components/search-content'
import reportItem from './components/report-item'
import teacherReport from '../report/teacher-performance/components/teacher-report'

import {
  GetTheEmployeeByID,
  GetTheEmployeeTeachingPercentage,
  GetBigCoursewarePackageForTeacher,
  GetRenewalWarningCount,
  GetAdiserStudentList,
  GetTeachingPerformanceForThisMonth
} from "../../API/workbench";
export default {
  name: "teacherAttenClass",
  data () {
    return {
      isCoursewareListNull: true, // 我的课包是否有值
      coursewareList: [], // 我的课包list
      staffInfo: {},
      swiper1: {},
      dataInfo: {
        RenewalWarningCount: 0,//续费预警
        NotRenewalCount: 0, //失效未续
        AttendanceWarningCount: 0, //预警出勤率数
        CampusWarningCount: 0, //长期未到校预警数
        StuentNotOneCount: 0, //排课不足预警
      },
      teacherStudentList: [],
      teacherReportInfo: {     //报名记录
        moduleName: '',
        ThisMonthCount: 0,//整型	本月报名
        ThisMonthActualColAmount: 0,//	整型 本月实收
        ThisWeekCount: 0,//	整型	本周报名
        ThisWeekCountActualColAmount: 0,//	整型	本周实收
        TodayCount: 0,//	整型	今天报名
        TodayCountActualColAmount: 0,//	整型 今天实收
        ToBeAddedCount: 0,//	整型	待补充    
      },
      isshowList: false,
      scaleY: 1,
      swiperWidth: ''
    };
  },
  components: {
    attendanceDate,
    dialogFactory,
    rightContent,
    searchContent,
    reportItem,
    teacherReport,
    coursewareSwiper

  },
  created () {
    this.token = this.$store.getters.token;
    this.warmingMenuList = this.$initJson.toDoWarningList;
    console.log(
      "$utils.formatDateToLine",
      this.$utils.formatDateToLine(Date.new())
    );

  },
  mounted () {
    this.registerBusEvent();
    this.$nextTick(() => {
      this.swiperInit();
      this.loadData();
      this.getDataInfo()
      this.getCoursewareList();
    });
    let screenWidth = document.body.clientWidth
    console.log("mounted screenWidth", screenWidth)
    if (screenWidth >= 1900) {
      this.swiperWidth = 1920 - 372 - 60 - 130
    } else {
      this.swiperWidth = screenWidth - 372 - 60 - 40
    }
    this.scaleY = screenWidth <= 1360 ? 1 : ((screenWidth - 432) / (1360 - 432))
    if (this.scaleY > 1.2) {
      this.scaleY = 1.2
    }
    console.log("this.swiperWidth", this.swiperWidth)
    this.$bus.on(this, 'screenOnResize', (screenWidth) => {
      console.log("screenOnResize", screenWidth)
      if (screenWidth >= 1920) {
        this.swiperWidth = 1920 - 372 - 60 - 100
      } else {
        this.swiperWidth = screenWidth - 372 - 60 - 100
      }
      this.scaleY = screenWidth <= 1360 ? 1 : ((screenWidth - 432) / (1360 - 432))
      //432 //padding+员工宽度
      if (this.scaleY > 1.2) {
        this.scaleY = 1.2
      }
    });
  },
  activated () {
    let moduleName = this.$route.params.moduleName;
    if (moduleName) {
      this.openCustomDialogByFactoryDialog(this.$route.params);
    }
  },
  computed: {
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    },


  },
  methods: {
    getThisMonthReport () {
      GetTeachingPerformanceForThisMonth().then((result) => {
        this.teacherReportInfo = result.data;
        console.log("GetTeachingPerformanceForThisMonth", result.data);
      });
    },
    getDataInfo () {
      GetAdiserStudentList(this.$store.getters.token.UserID).then(result => {
        this.teacherStudentList = result.data
        console.log(result.data, 'GetAdiserStudentList');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
      GetRenewalWarningCount(this.$store.getters.token.UserID).then(result => {
        this.dataInfo = result.data
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    swiperInit () {
      var that = this;
      console.log("swiper_attendance_contents")
      this.swiper1 = new Swiper('.swiper_attendance_contents', {
        slidesPerView: 3.2,
        initialSlide: 1,
        grabCursor: true,

        observer: true,   // 修改swiper自己或子元素时，自动初始化swiper
        // observeParents: true,  // 修改swiper的父元素时，自动初始化swipe

        on: {
          // 拖动结束后触发事件预加载. 判断最右边隐藏的课程数据是否小于十,activeIndex为内部的下标属性.
          slideNextTransitionEnd: function () {
            console.log(this.activeIndex);
          },
          slidePrevTransitionEnd: function () {
            console.log(this.activeIndex);
          }
        }
      });

      // this.swiper1.slideTo(0);
      console.log(this.swiper1, 'this.swiper1');
    },
    getCoursewareList () {
      // console.log('刷新1')
      GetBigCoursewarePackageForTeacher().then((result) => {
        result.data.forEach((o, index) => {
          o.isActive = false;
          if (index == 0) {
            o.isActive = true;
          }
        });
        this.coursewareList = result.data;

        console.log(this.coursewareList, '老师大课包list');
        if (this.coursewareList.length == 0) {
          this.isCoursewareListNull = false;
        } else {

        }
        setTimeout(() => {
          console.log('刷新');
          this.swiper1.updateSlides();
          this.swiper1.slideTo(0);
        }, 500);
      });
      // console.log('延时')
      // this.$refs.swiper1..slideTo(0)
      console.log(this.swiper1, 'this.$refs.swiper1');
    },
    loadData () {
      // this.getRealtimeData();
      this.getDataReportInfo()
      this.getThisMonthReport()
      this.getStaffInfo(this.token.UserID);
      this.getStaffTeachingPercentage(this.token.UserID);
    },

    // 获取统计数据
    getDataReportInfo () {
      // 报名记录统计
      // GetRegistrastionRecord().then(result => {
      //    this.applyRecordInfo.data=result.data
      //   console.log("GetRegistrastionRecord", result.data);
      // })
      // .catch(error => {
      //   console.log("ErrGetRegistrastionRecord", error);
      // });

    },
    // 获取员工信息.
    getStaffInfo (OLAPKey, callback) {
      GetTheEmployeeByID(OLAPKey)
        .then(result => {
          this.staffInfo = result.data;
          if (callback) {
            callback();
          }
          console.log("getStaffInfo", result.data);
        })
        .catch(error => {
          console.log("getStaffInfo", error);
        });
    },
    // 获取员工教学信息
    getStaffTeachingPercentage (OLAPKey) {
      GetTheEmployeeTeachingPercentage(OLAPKey).then(result => {
        console.log("获取员工教学信息", result.data);
        this.staffTeaching = result.data;
        this.latelyCourseInfo = result.data.Today.RecentClass || {};
      });
    },

    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(
        this.vModulePowerList,
        ModulePowerKey
      );
    },
    closeisshowList () {
      this.isshowList = false
    },
    showTeacherList (item) {
      if (this.vHasModulePower(item.ModulePowerKey)) {
        this.isshowList = true
      } else {
        this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
      }
    },
    showDialog (val) {
      this.openCustomDialogByFactoryDialog({ moduleName: val });
    },
    doAfterClickFunctionBtn (item) {
      console.log("doAfterClickFunctionBtn111", item.moduleName)
      if (item.moduleName) {
        this.openCustomDialogByFactoryDialog({
          moduleName: item.moduleName,
          callBack: item.callBack
        });
      } else {
        this.$dialog.openRouter(item, this.$route.name);
      }

    },

    goRouter (item) {
      if (item.moduleName) {
        this.openCustomDialogByFactoryDialog(item);
      } else {
        if (item.routerName == "studentManagementReport") {
          item.data = {
            headSummaryData: {
              title: "待排人数",
              className: "font_blue",
              value: 0,
              type: "Num_ToBeArranged",
              dataType: "number",
              decimalPlace: 0,
              filterName: true,
              isSelected: false
            } // 待排课学生
          };
        }
        console.log("item", item);
        this.$bus.emit("needOpenRouter", item);
      }
    },
    chgCouseware (item) {
      this.coursewareList.forEach((o, index) => {
        o.isActive = false;
        if (o.OLAPKey == item.OLAPKey) {
          o.isActive = true;
        }
      });
      console.log(this.$store.getters.token, 'this.$store.getters.token;', item);
      let token = this.$store.getters.token;
      this.$dialog.open(this, {
        name: '课包详情',
        routerName: this.$route.name,
        moduleName: 'bigCoursewareDetailst',
        data: {
          dataInfo: {
            TeacherKey: token.UserID,
            BigCoursewarePackageKey: item.OLAPKey,
            AuthKey: 0,
            TeacherName: token.UserName,
            Source: item.Source,
            MainDemoName: item.MainDemoName,
            CoursewarePackageKey: item.OLAPKey,
          }
        },
        callBack: {
          afterSuccess: () => {
            this.$emit('flashData');
          },
          closeDialog: () => {
            this.$emit('flashData');
          }
        }
      });
    },
    registerBusEvent () {
      this.$bus.on(this, "switchHeaderBarToTeacherAttenClass", () => {
        this.loadData();
        // this.$refs.todoList.loadData();
      });

      this.$bus.on(this, "wsAfterUpdateAgentInfo", data => {
        this.staffInfo = data;
      });
      this.$bus.on(this, "openCustomDialog", dialogInfo => {
        if (dialogInfo.routerName == "teacherAttenClass") {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, "openCommonCustomDialog", dialogInfo => {
        if (dialogInfo.routerName == "teacherAttenClass") {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.indexViewDialogFactory) {
        this.$refs.indexViewDialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.indexViewDialogFactoryCommon) {
        this.$refs.indexViewDialogFactoryCommon.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    }
  }
};
</script>
<style>
#teacherAttenClass {
  width: 100%;
  height: auto;
  min-height: 100%;
  display: flex;
  padding-bottom: 20px;
}
.home_page {
  margin: 0 auto;
  max-width: 1850px;
  min-width: 1296px;
  background: white;
  height: auto;
  flex: 1;
  /* margin-bottom: 35px; */
  padding: 30px;
  display: flex;
  flex-direction: row;
  transform-origin: top left;
}
.home_page_left_contont {
  flex: 1;
  /* background: yellow; */
  padding: 0 30px;
  transform-origin: top left;
  display: flex;
  flex-direction: column;
}
.left_contont_top {
  width: 100%;
  /* height: 100%; */
  /* background: blue; */
  display: flex;
  flex-direction: row;
}
.contont_top_left {
  flex: 1;
  margin-right: 30px;
  /* background: yellow; */
}
.contont_top_right {
  flex: 1;
  /* background: green; */
  display: flex;
  flex-direction: column;
}
.data_content {
  width: 100%;
  height: 159px;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  box-shadow: 2px 2px 2px 0px #d7d7d733;
  margin-bottom: 20px;
  padding: 15px 20px;
}
.workbench_view {
  padding: 0px !important;
}
.workbench_view_left {
  padding-right: 0px !important;
}
.kq_workbench_view {
  flex: 1;
  margin: 0 auto;
  background: #fff;
  position: relative;
  width: 100%;
}
.my_courewarebag {
  overflow: hidden;
}
</style>
