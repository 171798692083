<template>
  <div class="bottom_course_block"
       @click="clickItem"
       :class="[vOpt,vBorderBlue,vTypeGray,vLinkType]">

    <el-tooltip :content="itemInfo.GradeClassKeyValue"
                placement="top"
                :disabled="itemInfo.GradeClassKeyValue.length<8">
      <div class="bottom_course_name">
        {{itemInfo.GradeClassKeyValue}}
      </div>
    </el-tooltip>
    <el-tooltip :content="itemInfo.MTeacherKeyValue"
                placement="top"
                :disabled="itemInfo.MTeacherKeyValue.length<6">
      <div class="bottom_course_info">
        {{itemInfo.StartTime}} {{itemInfo.MTeacherKeyValue}}
      </div>
    </el-tooltip>
    <div class="bottom_course_date">
      {{itemInfo.LastClassTime}}
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    itemInfo: Object
  },
  created () {
    // console.log(this.itemInfo, '分发信息');
  },
  computed: {
    // 选中
    vOpt () {
      return this.itemInfo.isOpt ? 'opt' : '';
    },
    // 高亮
    vBorderBlue () {
      return this.itemInfo.isSelected ? 'border_blue' : '';
    },
    // 置灰
    vTypeGray () {
      return this.itemInfo.isGray ? 'type_gray' : '';
    },
    // 链条
    vLinkType () {
      return this.itemInfo.isLink ? 'link_type' : '';
    }
  },
  methods: {
    clickItem () {
      this.$emit('clickItem', this.itemInfo);
    }
  }
};
</script>

<style>
</style>