<template>
  <!-- 授权课件表单 -->
  <div style="height:400px">
    <div class="form_info_edit form_info_list">
      <div>
        <input-switch v-model="dataInfoed.IsAuthKey"
                      :formTitle="'允许访问'"
                      :showInputValue="false"
                      :required="true"
                      :readonly="isReadonly"
                      @change="changeSwitch"></input-switch>
      </div>
    </div>
    <div class="form_info_edit form_info_list pr"
         v-if="dataInfoed.IsAuthKey>0">
      <input-text :formTitle="'设备数量'"
                  :required="true"
                  v-model="dataInfoed.AuthorizeDeviceNum"
                  :readonly="isReadonly">
      </input-text>
      <span class="tipSpan">0表示不限</span>
    </div>
    <div class="form_info_edit form_info_list pr"
         v-if="dataInfoed.IsAuthKey>0">
      <input-select :formTitle="'定位'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :readonly="isReadonly"
                    :dataList="locationInfo"
                    :selectedInfo="vLocationInfo"
                    @selectedItem="changeLocationLockType"></input-select>
    </div>
    <div class="form_info_edit form_info_list pr"
         v-if="this.dataInfoed.IsLocationLockKey == 1">
      <input-select :formTitle="'定位精度'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :readonly="isReadonly"
                    :dataList="locationAccuracyInfo"
                    :selectedInfo="vLocationAccuracyInfo"
                    @selectedItem="changeLocationAccuracyType"></input-select>
    </div>
    <div class="form_info_edit form_info_list pr"
         v-if="this.dataInfoed.IsLocationLockKey == 1&&(vIsReadOnlyForTv||isPC_TV)">
      <input-select :formTitle="'是否开启定位网关验证'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :readonly="isReadonly"
                    :dataList="recordInfo"
                    :selectedInfo="vIsGatewayLogin"
                    @selectedItem="changeIsGatewayLogin"></input-select>
    </div>
    <div class="form_info_edit form_info_list pr">
      <input-select :formTitle="'是否开启防录屏'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :readonly="isReadonly ||vIsReadOnlyForTv"
                    :dataList="recordInfo"
                    :selectedInfo="vRecordInfo"
                    @selectedItem="changeRecordType"></input-select>
    </div>

    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      locationInfo: [
        { type: 1, name: '开启' },
        { type: 0, name: '不限' }
      ],
      locationAccuracyInfo: [  // 0-未设置; 1-高精度; 2-超高精度
        // { type: 0, name: '未设置' },
        { type: 1, name: '高精度 300-1300半径范围 ', value: '高精度' },
        { type: 2, name: '超高精度 200-700半径范围', value: '超高精度' }
      ],
      recordInfo: [
        { type: 1, name: '是' },
        { type: 0, name: '否' }
      ],
      dataInfoed: {
        IsAuthKey: false,
        IsLocationLockKey: 0,
        IsLocationLockKeyValue: '不限',
        AuthorizeDeviceNum: 0,
        IsRecordKey: 1,
        IsRecordKeyValue: '是',
        IsLocationAccuracyKey: 1,
        IsLocationAccuracyKeyValue: '高精度',
        IsGatewayLogin: 0,
        IsGatewayLoginValue: '否'
      }
    };
  },
  components: {

  },
  props: {
    dataList: Object,
    isReadonly: Boolean
  },
  created () {
  },
  mounted () {
    this.dataInfoed = JSON.parse(JSON.stringify(this.dataList));
    console.log('this.dataInfoed', this.dataInfoed, this.dataList);
    if (this.dataInfoed.AuthorizeDeviceNum == '-1') {
      this.dataInfoed.AuthorizeDeviceNum = '';
    }
    this.dataInfoed.IsLocationAccuracyKey = this.dataInfoed.IsLocationAccuracyKey || 1;
    this.dataInfoed.IsGatewayLogin = this.dataInfoed.IsGatewayLogin || 0;
  },
  computed: {
    vLocationInfo () {
      return {
        type: this.dataInfoed.IsLocationLockKey,
        name: this.dataInfoed.IsLocationLockKey == 1 ? '开启' : '不限'
      };
    },
    vLocationAccuracyInfo () {
      return {
        type: this.dataInfoed.IsLocationAccuracyKey,
        name: this.dataInfoed.IsLocationAccuracyKey == 2 ? '超高精度' : '高精度'
        // name: Number(this.dataInfoed.IsLocationAccuracyKey == 0) ? '未设置' : this.dataInfoed.IsLocationAccuracyKey == 1?'高精度':'超高精度'
      };
    },
    vIsGatewayLogin () {
      console.log('vIsGatewayLogin', this.dataInfoed.IsGatewayLogin);
      return {
        type: this.dataInfoed.IsGatewayLogin,
        name: this.dataInfoed.IsGatewayLogin == 1 ? '是' : '否'
      };
    },
    vRecordInfo () {
      console.log('vIsGatewayLogin', this.dataInfoed);
      return {
        type: this.dataInfoed.IsRecordKey,
        name: this.dataInfoed.IsRecordKey == 1 ? '是' : '否'
      };
    },
    isPC_TV () {
      return this.dataInfoed.AuthorizeDeviceType == 1 || this.dataInfoed.AuthorizeDeviceType == 3;
    },
    vIsReadOnlyForTv () {
      return this.dataInfoed.AuthorizeDeviceType == 3;
    }
  },
  methods: {
    changeSwitch () {
      console.log('点击后', this.dataInfoed);
    },
    // 选择定位
    changeLocationLockType (obj) {
      console.log(obj, '选择定位方式');
      this.dataInfoed.IsLocationLockKey = obj.type;
      this.dataInfoed.IsLocationLockKeyValue = obj.name;
    },
    changeIsGatewayLogin (obj) {
      this.dataInfoed.IsGatewayLogin = obj.type;
      this.dataInfoed.IsGatewayLoginValue = obj.name;
      console.log(this.dataInfoed, '选择网关验证');
    },
    changeLocationAccuracyType (obj) {
      this.dataInfoed.IsLocationAccuracyKey = obj.type;
      this.dataInfoed.IsLocationAccuracyKeyValue = obj.value;
      console.log(obj, '选择定位精度');
    },
    // 选择防录屏
    changeRecordType (obj) {
      console.log(obj, '选择是否防录屏');
      this.dataInfoed.IsRecordKey = obj.type;
      this.dataInfoed.IsRecordKeyValue = obj.name;
    },
    submit (callback) {
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      // console.log(, 'dataInfoed.AuthorizeDeviceNum');
      // if (this.dataInfoed.IsAuthKey) {
      //   if (isNaN(Number(this.dataInfoed.AuthorizeDeviceNum))) {
      //     layer.alert('设备数量不能为字符，请输入大于等于0的数');
      //     return;
      //   }
      // } else {
      // this.dataInfoed.AuthorizeDeviceNum = 0;
      // this.dataInfoed.IsLocationLockKey = 0;
      // }

      // this.dataInfoed.AuthorizeDeviceNum = Number(this.dataInfoed.AuthorizeDeviceNum).toFixed(0);
      this.$emit('afterSuccess', this.dataInfoed);
      this.$emit('close');
    },
    verificationInfo () {
      let flag = true;
      if (this.dataInfoed.IsAuthKey) {
        if (this.dataInfoed.AuthorizeDeviceNum == '') {
          layer.alert('设备数量不能为空');
          flag = false;
        }
      }
      return flag;
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.tipSpan {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */
  position: absolute;
  top: 14px;
  left: 94px;

  color: #999999;
}
</style>
