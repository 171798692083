<template>
  <div class="fontItem">
    <img :src="headImg"
         height="20px"
         v-if="type == -1">
    <span v-else
          :style="getStyle"
          class="fonts">{{fontItem.name}}</span>
    <div v-if="type == -1"
         class="download"
         @click="fontDownload">下载</div>
  </div>
</template>
<script>
export default {

  data () {
    return {
    };
  },
  props: ['fontItem', 'type'], // 单选数据项 需绑定到界面
  created () {
  },
  watch: {
  },
  computed: {
    // 头像
    headImg () {
      let pcDomainURLOld = this.$store.getters.CDNURL;
      return pcDomainURLOld + this.fontItem.svgTitle;
    },
    getStyle () {
      console.log('sad', 'font-family:' + this.fontItem.fontFamily);
      return 'font-family:' + this.fontItem.fontFamily;
    }
  },
  methods: {
    selectedItem (item) {
      item.isActive = true;
      this.$emit('selectedItem', item);
    },
    fontDownload () {
      if (this.fontItem.fontUrl && this.fontItem.fontUrl != '') {
        let url = this.fontItem.fontUrl;
        if (this.fontItem.fontUrl.indexOf('http') == -1) {
          url = this.$store.getters.CDNURL + this.fontItem.fontUrl;
        }

        // let link = document.createElement('a');
        // // 这里是将url转成blob地址，
        // fetch(encodeURIComponent(url)).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        //   link.href = URL.createObjectURL(blob);
        //   console.log(link.href);
        //   link.download = '';
        //   link.download = this.fontItem.name + '免费商用字体';
        //   document.body.appendChild(link);
        //   link.click();
        // });
        window.location.href = url;
      }
    }

  }
};
</script>
<style scoped>
.fontItem {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
}
.download {
  width: 50px;
  height: 30px;
  background: #3498db;
  border-radius: 8px;
  color: #ffff;
  text-align: center;
  align-items: center;
  line-height: 30px;
  cursor: pointer;
}
.fonts {
  font-size: 20px;
}
</style>

