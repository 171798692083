<template>
  <div>
    <div class="WX_auto_respond_box">
      <div class="WX_auto_respond_btn btn_hover_bg_blue"
           @click.stop="addReply">
        添加默认回复
      </div>
      <div class="WX_auto_respond_table">
        <list class="summarizing_list"
              :key="tableKey"
              :tableData="vDataList"
              :totalNum="totalNum"
              :PageCount="PageCount"
              :tableColumns="tableCourseColumns"
              :isSearchText="false"
              :isNeedInnerScroll="false"
              :defaultSort="defaultSort"
              :queryParams="searchObj"
              @tableBtnClick="doAfterTableBtnClick"
              @loadTableData="getKeyWordList"></list>
      </div>
    </div>
    <custom-dialog :title="'关键词回复设置'"
                   class="new_class defult_dialog"
                   :visible.sync="isShowAddReply"
                   :before-close="closeShowAddReply">
      <add-keyword-reply @close="closeShowAddReply"
                         @afterSuccess="getKeyWordList"
                         :seletedItem="seletedItem"></add-keyword-reply>
    </custom-dialog>
  </div>
</template>
<script>
import { SelectDefaultReply, DelDefaultReply } from '../../../../../API/workbench';
import addKeywordReply from './common/add-keyword-reply';
import list from '../../../../report/class-spend-statistics-analyze/components/table';
export default {
  data () {
    return {
      dataList: [],
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: ''
      },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'serial',
          decimalPlace: 0,
          className: 'table_index_gray',
          eventName: this.$initJson.baseFunctionBtnName.read
        },
        {
          label: '用户关键词',
          prop: 'messagekey',
          minWidth: 80,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text',
          decimalPlace: 0
        },
        {
          label: '回复',
          prop: 'content',
          minWidth: 80,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text',
          decimalPlace: 0
        },
        {
          label: '操作',
          prop: '',
          width: '',
          sortable: false,
          align: 'center',
          tableBtnGroup: [
            {
              name: '编辑',
              eventName: this.$initJson.baseFunctionBtnName.edit,
              Stutas: 'StutasKey',
              className: () => {
                return 'btn_light_blue';
              },
              roleName: '',
              ModulePowerKey: ''
            },
            {
              name: '删除',
              eventName: this.$initJson.baseFunctionBtnName.del,
              Stutas: 'StutasKey',
              className: () => {
                return 'btn_light_blue';
              },
              roleName: '',
              ModulePowerKey: ''
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        }
      ],
      isShowAddReply: false,
      seletedItem: null

    };
  },
  components: {
    list, addKeywordReply
  },
  created () {
    this.getKeyWordList();
  },
  computed: {
    vDataList () {
      let newArr = [];
      this.dataList.forEach(o => {
        o.CourseNameKey = 1;
        newArr.push(o);
      });
      return newArr;
    }
  },
  methods: {
    getKeyWordList (queryParams) {
      this.dataList = [];
      this.closeShowAddReply();
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      SelectDefaultReply(this.searchObj).then(result => {
        this.dataList = result.data.PageDataList;
        this.totalNum = result.data.Total;
        console.log(result.data, 'SelectDefaultReply');
      }).catch(error => {
        console.log(error, 'errorSelectDefaultReply');
      });
    },
    delData (item) {
      layer.confirm('请问是否删除该回复', {
        btn: [{
          name: '确认',
          callBack: () => {
            DelDefaultReply(item.id).then(result => {
              this.getKeyWordList();
            }).catch(error => {
              console.log(error, 'errorDelDefaultReply');
            });
            layer.close();
          }
        }]
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      // this.seletedMDInfo = rowData;
      this.seletedItem = rowData;
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.edit:
          console.log('edit');
          this.isShowAddReply = true;
          // this.$refs.textareaPop.isAlterMessagePopup = true;
          break;
        case this.$initJson.baseFunctionBtnName.del:
          // this.seletedCourseInfo = rowData;
          console.log('del');
          this.delData(this.seletedItem);
          break;
        default:
          break;
      }
    },
    addReply () {
      this.seletedItem = null;
      this.isShowAddReply = true;
    },
    closeShowAddReply () {
      this.isShowAddReply = false;
    }
  }
};
</script>

