<template>
  <div v-if="isShowMainForm">
    <custom-dialog title="个人推广招生活动列表"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowMainForm"
                   :before-close="doShowPopupHideClick">
      <div class="form_info_detail form_info_serach_box">
        <!-- <div class="defult_dialog_header"
            v-if="formTitle">
          <span class="defult_dialog_header_return mint-toast-icon mintui mintui-back fl"
                @click.stop="returnEvent"></span>
          <span class="defult_dialog_header_font">{{formTitle}}</span>
          <span class="defult_dialog_header_btn fr"  @click="doAddClick()" >
          </span>
        </div> -->
        <!-- 搜索选择层 -->
        <div class="defult_dialog_searchform clearfix">
          <!-- 搜索框 -->
          <div class="student_search_form">
            <input type="text"
                   class="search_input"
                   placeholder=""
                   v-model="studenSearchText">
            <span class="remove_btn"
                  style="right:70px"
                  v-if="studenSearchText.length > 0"
                  @click.stop="clearSearText"></span>
          </div>
          <div @click.stop="searchTextSearch()">
            <div class="btn_light_blue_search">搜索</div>
          </div>
        </div>
        <!-- 搜索结果 -->
        <div class="search_result">
          <div class="search_result_content search_result_content_for_student"
               @scroll="scrollTOP">
            <div class="search_result_list">
              <!--  选择客户列表 -->
              <!--  选择学生列表 -->
              <div>
                <singleCouponItem v-for="item in vcouponList"
                                  :key="item.OLAPKey"
                                  :isTestStudentItem="item"
                                  @selectedItem="selectedItem"></singleCouponItem>
              </div>
              <div style="background:#f2f2f2"
                   class="tc"
                   v-if="loading">
                <span class="el-icon-loading"></span>
                <span class="loading_style">加载中</span>
              </div>
              <div v-else-if="vcouponList.length==0">
                <div class="monitoring_content_nodata"
                     style="margin-top:150px">
                  <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </custom-dialog>
  </div>

</template>
<script>
import singleSelectionItem from '../../../common/single-selection-item';
import singleCouponItem from './single-coupon-item';
import { getMendianCoupon } from '../../../../API/workbench';
export default {
  props: {
    formTitle: String, // 自定义标题名
    switchItem: [String, Number, Boolean], // 自定义跳转，不跳转路由的返回.switchItem为任意值
    addTitle: String,
    SpreadConponKey: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      token: this.$store.getters.token, // 获取客户ID
      couponList: [],
      studenSearchText: '',
      pageSize: 20,     // 每次搜索人数.
      pageIndex: 0,      // 每次查询页数
      loading: false,
      noMore: false, // 没有更多数据加载了
      isSearchEnd: false,   // 作为点击搜索时的判断条件.
      isShowMainForm: false

    };
  },
  components: {
    singleCouponItem,
    singleSelectionItem
  },
  watch: {
    // studenSearchText () {
    //   if (this.studenSearchText == '') {
    //     this.couponList.length = 0;
    //     this.pageSize = 20;
    //     this.pageIndex = 0;
    //     this.getStudentList();
    //   } else {
    //     this.couponList.length = 0;
    //     this.textSeachergetStudentList();
    //   }
    // }
  },
  created () {
    // this.getStudentList();
  },
  methods: {
    // 主表单开关
    doShowPopupHideClick () {
      document.getElementsByClassName('popup_dialog_shade')[0].style.zIndex = 11;  // 这是因为custom-dialog这个组件打开多个的时候会修改原来的z-index，
      this.couponList.length = 0;
      this.isShowMainForm = false;
    },
    doShowPopupShowClick () {
      this.isShowMainForm = true;
      this.getStudentList();
    },
    doAddClick () {
      this.$emit('addClick');
    },
    // 点击搜索
    searchTextSearch () {
      this.isSearchEnd = true;
      this.studenSearchText = this.studenSearchText.replace(/ /g, '');
      this.pageIndex = 0;
      this.noMore = false;
      // this.getStudentList();
    },
    doAfterSelectionItem (item) {
      this.studenSearchText = '';
      this.$emit('selectedItem', item);
    },
    // 自定义返回或取消事件
    returnEvent () {
      if (this.switchItem) {      // 不跳转路由的返回.switchItem为任意值.
        this.$emit('return');
      } else {
        this.$router.push('/indexview');
      }
    },
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;
      if ((ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight || (ETarget.scrollTop + 1) == ETarget.scrollHeight - ETarget.clientHeight) && !this.noMore) {
        console.log('scrollTOP', this.noMore);
        this.getStudentList();
      }
    },
    // getMendianCoupon(this.token.SaaSClientKey).then(obj => {
    //   if (obj.data && obj.data.length > 0) {
    //     if (obj.code == 0 || obj.code == 2) {
    //       this.mendianCouponList = obj.data;
    //     } else if (obj.code == 3) {
    //       obj.code = ''; // 不显示当前的的错误提示
    //       this.wktSaasIsExists = true;
    //     }
    //   }
    // }).catch(error => {
    //   // layer.alert(error.msg);
    // });
    textSeachergetStudentList () {                      // 根据搜索条件获取学生.
      // let that = this;
      getMendianCoupon(this.token.SaaSClientKey, 0).then(result => {
        // this.couponList = result.data;
        this.couponList.length = 0;
        result.data.PageDataList.forEach(obj => {
          console.log('this.SpreadConponKey,', this.SpreadConponKey);
          if (this.SpreadConponKey == obj.id) {
            obj.isActive = true;
          } else {
            obj.isActive = false;
          }
          this.couponList.push(obj);
        });
        // that.pageIndex++;
      }).catch(error => {
        console.log(error);
      });
    },
    getStudentList () {
      this.loading = true;
      getMendianCoupon(this.token.SaaSClientKey, 0).then(result => {
        this.couponList.length = 0;
        result.data.forEach(obj => {
          console.log('this.SpreadConponKey,', this.SpreadConponKey);
          if (this.SpreadConponKey == obj.id) {
            obj.isActive = true;
          } else {
            obj.isActive = false;
          }
          this.couponList.push(obj);
        });
        this.loading = false;
      }).catch(error => {
        this.loading = false;
        console.log(error);
      });
    },
    // 选择学生
    selectedItem (item) {
      this.couponList.forEach(obj => {
        obj.isActive = false;
        if (obj.OLAPKey == item.OLAPKey) {
          obj.isActive = true;
        }
      });
      this.doShowPopupHideClick();
      // console.log("52352352")
      this.verifyStudent(item);
      this.scrollBackTop();
    },
    // 验证学生
    verifyStudent (item) {
      console.log(this.switchItem, 'item');
      // if (item.IsConfirmKey == 0 && this.switchItem !== 1) { // switchItem 这里可做开关，1则关闭验证提示
      //   layer.alert('该学生待验证确认，请先去学生档案中进行确认操作。');
      // } else {
      this.studenSearchText = '';
      this.$emit('selectedItem', item);
      // }
    },
    scrollBackTop () {
      this.studenSearchText = '';
      document.getElementsByClassName('search_result_content_for_student')[0].scrollTop = 0;
      if (document.getElementsByClassName('search_result_content_for_student')[1]) {
        document.getElementsByClassName('search_result_content_for_student')[1].scrollTop = 0;
      }
    },
    clearSearText () {
      this.studenSearchText = '';
    }
  },
  computed: {
    //  即时搜索
    vcouponList () {
      let newArr = [];
      if (this.studenSearchText && this.isSearchEnd) {
        this.couponList.forEach(o => {
          if (this.studenSearchText && o.coupon_name.indexOf(this.studenSearchText) != -1) {
            newArr.push(o);
          }
        });
        this.isSearchEnd = false;
        return newArr;
      } else {
        return this.couponList;
      }
    }
  }
};
</script>

