import { render, staticRenderFns } from "./multiple-item.vue?vue&type=template&id=6a2b0a1c&scoped=true"
import script from "./multiple-item.vue?vue&type=script&lang=js"
export * from "./multiple-item.vue?vue&type=script&lang=js"
import style0 from "./multiple-item.vue?vue&type=style&index=0&id=6a2b0a1c&prod&lang=css"
import style1 from "./multiple-item.vue?vue&type=style&index=1&id=6a2b0a1c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a2b0a1c",
  null
  
)

export default component.exports