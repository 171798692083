<template>
  <div class="course_essay_label_box">
    <div class="course_popup_label class_details_label attendance_label">

      <div class="label_name">
        {{$utils.getWeekByTwo(courseInfo.ClassTime)}} <span class="font_gray">{{courseInfo.ClassTimeName}}</span> {{courseInfo.CourseNameKeyValue}}
      </div>
      <div class="label_text icon_03">
        <span class="">{{courseInfo.GradeClassKeyValue}}</span> - <span>{{courseInfo.MTeacherKeyValue}}</span>丨{{Number(courseInfo.PeriodCount)||0}}课时
      </div>
      <div class="edit_type"
           @click.stop="toEdit"></div>
      <!-- <div class="label_text icon_05">
        安排{{Number(courseInfo.ArrangedNumCount)||0}}人
      </div> -->
      <!-- <div class="label_right_type"
           :class="vStatuClass">
        {{vStatu}}
      </div> -->

    </div>
  </div>
</template>

<script>
import {
  getCoursewarePackageIs
} from '../../../API/workbench';
export default {
  data () {
    return {
    }
  },
  props: {
    courseInfo: Object,
  },
  components: {

  },
  computed: {
    vClassDate () {
      let dateArr = this.$utils.formatDateToLine(this.courseInfo.ClassTime).split('-');
      return dateArr[1] + '-' + dateArr[2];
    },
    vTeacherhead () {
      if (this.courseInfo.XMLIcon && this.courseInfo.XMLIcon.length > 0) {
        return this.$store.getters.CDNURL + this.courseInfo.XMLIcon;
      } else {
        return '';
      }
    },
    defaultImg () {
      return 'this.src="' + require('../../../../public/image/deflut_teacher_photo@2x.png') + '"';
    },
    vIsleaveKey () {
      return this.courseInfo.IsleaveKey == 0;
    },
    vIsChargesKey () {
      return this.courseInfo.IsChargesKey == 0 || this.$utils.getSaaSClientSet(59) == 0;
    }
  },
  created () {
    // this.getCWePackageIs()
  },
  methods: {
    updateCourseInfo (ScheduleCourseKey) {
      this.$emit('updateCourseInfo', ScheduleCourseKey);
    },
    toEdit () {
      this.$emit("toEdit")
    }

  }
};
</script>

<style scoped>
.course_essay_label_box {
  padding-bottom: 5px !important;
}

.course_essay_label_box .edit_type {
  position: absolute;
  right: 20px;
  top: 14px;
  width: 18px;
  height: 18px;
  background: url('../../../../public/image/edit_appoint_course.png') center
    center no-repeat;
  background-size: 15px;
  opacity: 0.7;
}
</style>