<template>
  <div>
    <div class="form_info_edit form_info_list"
         style="height: 220px">

      <input-select :formTitle="'课前截止预约单位'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :selectedInfo="vSourceTypeInfo"
                    :dataList="sourceType"
                    @selectedItem="changeSourceType"></input-select>

      <input-number :formTitle="'数量'"
                    :min="0"
                    :max="9999999"
                    :required="true"
                    :formPlaceholder="'请输入'"
                    v-model="dataInfo.SetContent"></input-number>
    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { GetSaaSClientSettingSubscribeCourse } from '../../../../API/workbench';
export default {
  data () {
    return {
      sourceType: [
        { type: 1, name: '小时' },
        { type: 2, name: '天' }
      ],
      dataInfo: {
        SetKey: '', // "天数状态 1小时 2天" ,
        SetContent: 0 // 内容
      }
    };
  },
  components: {

  },
  props: {
    courseSetInfo: Object // 门店信息
  },
  created () {

  },
  mounted () {
    if (this.$store.getters.token.SaaSClientKey && Number(this.$store.getters.token.SaaSClientKey) > 0) {
      this.getSubscribeCourse();
    }
    this.dataInfo.SetKey = this.courseSetInfo.SetKey;
    this.dataInfo.SetContent = this.courseSetInfo.SetContent;
    console.log(this.courseSetInfo, 'this.courseSetInfo', this.dataInfo, this.dataInfo.SetContent);
  },
  computed: {
    vSourceTypeInfo () {
      return {
        type: this.dataInfo.SetKey,
        name: this.courseSetInfo.SetValue
      };
    }
  },
  methods: {
    // 选择类型
    changeSourceType (obj) {
      console.log(obj, '选择来源方式');
      this.dataInfo.SetKey = obj.type;
      this.courseSetInfo.SetValue = obj.name;
    },
    getSubscribeCourse () {
      GetSaaSClientSettingSubscribeCourse(this.$store.getters.token.SaaSClientKey).then(result => {
        console.log(result.data, 'getSubscribeCourse');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 提交
    submit (callback) {
      console.log('submit', this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      console.log(this.dataInfo.SetContent, 'this.dataInfo.SetContent');
      this.$emit('UpdataCourse', this.dataInfo);
      this.cancel();
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
      if (!this.dataInfo.SetKey) {
        layer.alert('请选择预约');
        flag = false;
      } else if (!this.dataInfo.SetContent) {
        layer.alert('请输入数量');
        flag = false;
      } else if (this.dataInfo.SetContent < 0 || Number(this.dataInfo.SetContent) !== Math.round(this.dataInfo.SetContent)) {
        layer.alert('请输入正整数');
        flag = false;
      }
      return flag;
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
