<template>
  <div class="general_summarizing_student"
       style="bottom: 80px;">
    <div class="payment_statistics_bottom clearfix">
      <div class="payment_statistics_bottom_select flex">
        <!-- 日期 -->
        <div>
          <date-range ref="monthPicker"
                      :fromTitle="'发生日期'"
                      :searchObj="searchObj"
                      :minDate="vMinDate"
                      :maxDate="vMaxDate"
                      :isShowHistory="isShowHistory"
                      @changeDate="changeSaveDate"></date-range>
        </div>
        <div class="change_history_data"
             @click.stop="historyBtn(historyName)">{{ historyName }}</div>
        <!-- 课程 -->
        <div class="table_select_box">
          <div class="table_select_title">班级</div>
          <el-select style="width: 100%;"
                     :value="vGradeKeyValue"
                     filterable
                     placeholder="必选"
                     @change="selectedClassEvent"
                     value-key="OLAPKey">
            <el-option v-for="item in gradeClassList"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item"></el-option>
          </el-select>
        </div>
        <!-- 请假次数 -->
        <div class="table_select_box">
          <div class="table_select_title"
               style="margin-right: 10px;">请假次数</div>
          <div class="table_select_leave">
            <input type="number"
                   style="width: 60px;margin-right: 40px;"
                   maxlength="6"
                   placeholder="请输入"
                   v-model="searchLeaveNum1"
                   onkeyup="value=value.replace(/[^\d]/g,'')"
                   oninput="if(value.length>6)value=value.slice(0,6)">
            <div class="arrive-icon"></div>
            <input type="number"
                   style="width: 50px;"
                   maxlength="6"
                   placeholder="请输入"
                   v-model="searchLeaveNum2"
                   onkeyup="value=value.replace(/[^\d]/g,'')"
                   oninput="if(value.length>6)value=value.slice(0,6)">
          </div>
        </div>
      </div>
      <div class="course_statistics_table_search flex course_statistics_title">
        <div class="course_statistics_search">
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入姓名、姓名首字母、手机号'"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="search"></input-search-contain-btn>
          <div class="mendian_backstage_search">
            <div class="search_btn btn_hover_bg_blue"
                 style="margin: 0;"
                 @click.stop="doExportInfoClick">导出</div>
          </div>
        </div>
      </div>
      <div v-if="isLoading">
        <div class="table_list_content summarizing_list">
          <div class="single_result_detail">
            <table-list ref="tableListRef"
                        :tableData="dataReportList"
                        :tableColumns="vtableGradeColumns"
                        :rowKey="'Number'"
                        :totalNum="totalNum"
                        :footerContent="'共'+totalNum+'条数据'"
                        :defaultSort="defaultSort"
                        :queryParams="searchObj"
                        @loadTableData="getDataList"></table-list>
          </div>
        </div>
      </div>
      <div v-else
           class="loading_fixed_box"
           style="height: 600px;">
        <div class="loading_fixed_gray">
          <span class="loading_style">加载中...</span>
        </div>
      </div>
    </div>
    <custom-dialog title="学生考勤明细"
                   width="1213px"
                   :top="30"
                   class="class_staistics_list defult_dialog"
                   :visible.sync="isShowStudentAttendanceDialog"
                   :before-close="closeDialog">
      <attenReport :searchInfo="attendanceSearchObj"
                   :teacherList="teacherList"
                   :gradeClassList="gradeClassList"
                   :courseNameList="courseNameList"
                   @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"></attenReport>
    </custom-dialog>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import attenReport from '../../class-spend-statistics-analyze/components/atten-report';

import { MessageSearchPageForStudentGradeAttendenceReport, getUsingClass, MessageSearchPageForStudentGradeAttendenceReportH } from '../../../../API/workbench';
export default {
  data () {
    return {
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      searchValue: '', // 搜索内容，防止双向绑定联动
      searchLeaveNum1: '', //	请假次数1，防止双向绑定联动
      searchLeaveNum2: '', //	请假次数2，防止双向绑定联动
      // 提交数据
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        GradeKey: 0,
        GradeKeyValue: '',
        leaveNum1: '', // 整型	可选		数据源：GET	请假次数1
        leaveNum2: '', // 整型	可选		数据源：GET	请假次数2
        startTime: '',
        endTime: '',
        IsExportKey: 0
      },
      attendanceSearchObj: {}, // 考勤明细搜索
      gradeClassList: [], // 课程
      isShowStudentAttendanceDialog: false, // 学生考勤明细弹窗
      isLoading: false,
      totalNum: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      // 明细总览 table 列
      tableGradeColumns: [
        {
          label: '序号',
          prop: 'Number',
          type: 'text-item',
          sortable: false,
          width: 68
        },
        {
          label: '学生',
          prop: 'StudentNameKeyValue',
          sortable: 'custom',
          type: 'student',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            click: () => { }
          }
        },
        {
          label: '班级',
          prop: 'GradeClassKeyValue',
          sortable: 'custom',
          type: 'reading-courses',
          width: 180
        },
        {
          label: '考勤课次',
          prop: 'ArrangeCount',
          sortable: 'custom',
          type: 'class-hour',
          width: 150,
          decimalPlace: 0,
          extend: {
            click: (rowData) => {
              this.doAfterTableBtnClick(rowData);
            },
            ModulePowerKey: ''
          }
        },
        {
          label: '签到',
          prop: 'SignInCount',
          sortable: 'custom',
          type: 'number-item',
          width: 120,
          decimalPlace: 0
        },
        {
          label: '请假',
          prop: 'LeaveCount',
          sortable: 'custom',
          type: 'number-item',
          width: 120,
          decimalPlace: 0
        },
        {
          label: '旷课',
          prop: 'TruantCount',
          sortable: 'custom',
          type: 'number-item',
          width: 120,
          decimalPlace: 0
        },
        {
          label: '补课',
          prop: 'MakeupCount',
          sortable: 'custom',
          type: 'number-item',
          minWidth: 100,
          decimalPlace: 0
        },
        {
          label: '出勤率',
          prop: 'SignInRate',
          sortable: 'custom',
          type: 'text-item',
          width: 150

        },
        {
          label: '学生课时',
          prop: 'PeriodCount',
          sortable: 'custom',
          type: 'class-hour',
          minWidth: 100

        }
      ],
      dataReportList: []
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
    attenReport
  },
  props: {
    teacherList: Array,
    // gradeClassList: Array,
    courseNameList: Array
  },
  created () {
    this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
    this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
    this.getDataList();
    this.getClassNameList();
    this.registerBusEvent();
    console.log(this.searchObj, 'created-123');
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    },
    vtableGradeColumns () {
      let list = [];
      this.tableGradeColumns.forEach(o => {
        if (this.$utils.getSaaSClientSet(59) > 0 && o.label == '旷课') {
          o.isShow = true;
        } else if (this.$utils.getSaaSClientSet(59) == 0 && o.label == '旷课') {
          o.isShow = false;
        }
        list.push(o);
      });
      return list;
    },
    vGradeKeyValue () {
      return this.searchObj.GradeKey == 0 ? '全部' : this.searchObj.GradeKeyValue;
    }
  },
  mounted () {

  },
  methods: {
    historyBtn (name) {
      console.log(this.isShowHistory, 'isShowHistory');
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.searchObj.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.searchObj.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isShowHistory = true;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0));
        this.isShowHistory = false;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.getDataList();
      this.$refs.monthPicker.changeHistoryTime(this.searchObj.startTime, this.searchObj.endTime);
    },
    //  根据日期搜索
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 根据班级搜索
    selectedClassEvent (obj) {
      console.log(obj, '选中班级');
      this.searchObj.GradeKeyValue = obj.MainDemoName;
      this.searchObj.GradeKey = obj.OLAPKey;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchLeaveNum1 = '';
      this.searchLeaveNum2 = '';
      this.searchObj.searchText = '';
      this.searchObj.leaveNum1 = this.searchLeaveNum1;
      this.searchObj.leaveNum2 = this.searchLeaveNum2;
      this.searchObj.pageIndex = 0;
      this.searchObj.GradeKey = 0;
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    // 搜索
    search () {
      if (this.searchLeaveNum1 && this.searchLeaveNum2 && Number(this.searchLeaveNum1) > Number(this.searchLeaveNum2)) {
        layer.alert('请假次数的起始次数不能大于截止次数');
      } else {
        this.searchObj.pageIndex = 0;
        this.searchObj.leaveNum1 = this.searchLeaveNum1;
        this.searchObj.leaveNum2 = this.searchLeaveNum2;
        this.getDataList();
      }
    },
    // 查询课程
    getClassNameList () {
      getUsingClass().then(result => {
        this.gradeClassList = result.data;
        this.gradeClassList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
      });
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = '';
        if (this.isShowHistory) {
          fn = MessageSearchPageForStudentGradeAttendenceReportH;
        } else {
          fn = MessageSearchPageForStudentGradeAttendenceReport;
        }
        let obj = this.$utils.parseJson(this.searchObj);
        obj.IsExportKey = 1;
        obj.pageIndex = 0;
        obj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '学生考勤表',
          clist: [
            { title: '学生', cName: 'StudentNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '班级', cName: 'GradeClassKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '考勤课次', cName: 'ArrangeCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '签到', cName: 'SignInCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '请假', cName: 'LeaveCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '旷课', cName: 'TruantCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '补课', cName: 'MakeupCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '出勤率', cName: 'SignInRate', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生课时', cName: 'PeriodCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{float::PeriodCount::1::0}}" }

          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(obj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.searchObj.IsExportKey = 0;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    // 返回符合条件的, 当前页的指定条数学生数据
    getDataList (queryParams) {
      let fn = '';
      if (this.isShowHistory) {
        fn = MessageSearchPageForStudentGradeAttendenceReportH;
      } else {
        fn = MessageSearchPageForStudentGradeAttendenceReport;
      }
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj);
      fn(this.searchObj).then(result => {
        this.isLoading = true;
        this.totalNum = result.data.Total;
        result.data.PageDataList.forEach(o => {
          o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
          o.StudentKey = o.StudentNameKey;
          if (o.SignInRate == 0) {
            o.SignInRate = '-';
          }
        });
        this.dataReportList = result.data.PageDataList;
        console.log(result.data.PageDataList, '指定条数学生数据');
      }).catch(error => {
        console.log('MessageSearchPageForStudentGradeAttendenceReport:', error.msg);
      });
    },
    // 考勤课次
    doAfterTableBtnClick (rowData) {
      console.log(rowData, '考勤课次');
      this.attendanceSearchObj = this.$utils.parseJson(this.searchObj);
      this.attendanceSearchObj.StudentKey = rowData.StudentKey;
      this.attendanceSearchObj.GradeKey = rowData.GradeClassKey;
      this.attendanceSearchObj.GradeKeyValue = rowData.GradeClassKeyValue;
      this.attendanceSearchObj.searchText = rowData.StudentNameKeyValue;
      // this.isShowStudentAttendanceDialog = true;

      this.$emit('doAfterTableBtnClicks', this.attendanceSearchObj);
    },
    closeDialog () {
      this.isShowStudentAttendanceDialog = false;
    },
    registerBusEvent () {
      // 修改学生档案的学生信息成功后触发回调
      this.$bus.on(this, 'AfterStudentUpdate', studentKey => {
        // console.log(studentKey, '修改学生档案的学生信息成功后触发回调');
        if (studentKey > 0) {
          this.getDataList();
        }
      });
      // 关闭安排考勤刷新
      this.$bus.on(this, 'closeArrangeAttendanceDialog', (data) => {
        this.getDataList();
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      this.$emit('openCustomDialogByFactoryDialog', dialogInfo);
    }
  }
};
</script>
<style scoped>
.change_history_data {
  position: absolute;
  right: 20px;
  top: 135px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
</style>