<template>
  <div class="apply_payment_details_box except_details_box massive_bg  fl">
    <div class="apply_payment_box_title box_dot "
         :class="[dotColor]">
      {{formTitle}}
    </div>
    <div class="apply_payment_box_singular">
      <span>{{count||0}}</span> 次
    </div>
    <div @click.stop="showDetail"
         class="apply_payment_box_btn">
      本月详情
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    formTitle: String,
    count: [String, Number],
    dotColor: String
  },
  methods: {
    showDetail () {
      this.$emit('showDetail');
    }
  }
};
</script>
