<template>
  <div class="modulebox clearfix">
    <div class="chartbox">
      <div class="tit">合班可行性分析<span @click="setup">设置</span></div>
      <echart-pie :echartID="'echart5'" ref="echart5" :dataList="echartList"></echart-pie>
    </div>
    <div class="listbox">
      <div class="roomlist clearfix">
        <div class="block" :class="search.TypeKey==1 ? 'cur' : ''" @click="roomClick(1)">
          <div>低满班率 ≤ {{grade.low}}%</div>
          <p>{{echartList[0].value}}班</p>
        </div>
        <div class="block" :class="search.TypeKey==2 ? 'cur' : ''" @click="roomClick(2)">
          <div>{{grade.low}}% ＜ 中满班率 ＜ {{grade.high}}%</div>
          <p>{{echartList[1].value}}班</p>
        </div>
        <div class="block" :class="search.TypeKey==3 ? 'cur' : ''" @click="roomClick(3)">
          <div>高满班率 ≥ {{grade.high}}%</div>
          <p>{{echartList[2].value}}班</p>
        </div>
      </div>
      <div class="formbox">
        <div class="text">选择日期</div>
        <date-range ref="selectDate" @changeDate="selectDateChange" style="display:inline-block;margin-right:20px"></date-range>
        <a class="btn btn_1" @click="searchClick">搜索</a>
        <a class="btn btn_2" @click="resetClick">清除条件</a>
      </div>
      <div class="course_statistics_table_box table_list_content">
        <table-list class="summarizing_list" :tableData="tableData" :totalNum="totalNum" :PageCount="PageCount" :tableColumns="tableColumns" :queryParams="search" :footerContent="vFooterContent" @loadTableData="getList"></table-list>
      </div>
    </div>
  </div>
</template>

<script>
import {GetSaaSClientMsg,GetGradeFullDataCount,GetGradeFullList} from '../../../API/workbench';
import echartPie from './echart-pie';
import tableList from '../../common/table-list/index';
export default {
  data () {
    return {
      grade:{
        low:'',
        high:''
      },
      echartList:[
        {
          value: 0,
          name: '低满班率',
          itemStyle: {color:'#C7C9D1'}
        },
        {
          value: 0,
          name: '中满班率',
          itemStyle: {color:'#FE9647'}
        },
        {
          value: 0,
          name: '高满班率',
          itemStyle: {color:'#519CF7'}
        }
      ],
      search:{
        TypeKey:1,
        StartTime:'',
        EndTime:'',
        pageIndex:0,
        pageSize:5
      },
      tableData:[],
      totalNum:0,
      PageCount:0,
      tableColumns:[
        {
          label: '班名',
          prop: 'MainDemoName',
          width: 200,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '学生',
          prop: 'CurrentPeoCount',
          width: 80,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '满班率',
          prop: 'GradeFull',
          width: 80,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '排课课次',
          prop: 'ArrangeCourseCount',
          width: 80,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '排课人次',
          prop: 'ArrangedStudentCount',
          width: 80,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '出勤人次',
          prop: 'ArrivedNumCount',
          width: 80,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '出勤率',
          prop: 'Attence',
          width: 80,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '课次消耗',
          prop: 'CourseDeductCount',
          width: 80,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '课次课消',
          prop: 'CourseDeductAmount',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        }
      ]
    };
  },
  components: {
    echartPie,
    tableList
  },
  created () {
  },
  mounted () {
    this.getGrade();
    this.getList();
  },
  computed: {
    vFooterContent () {
      return '共 ' + this.totalNum + ' 条数据';
    }
  },
  methods: {
    draw () {
      this.$refs.echart5.echartInit();
    },
    getGrade () {
      let that = this;
      GetSaaSClientMsg().then(result => {
        if (result.code == 0) {
          let list = result.data.SaaSClientSetting;
          for (let i = 0; i < list.length; i++) {
            if (list[i].SetTypeKey==111) {
              that.grade.high = list[i].SetContent;
              that.echartList[2].name = list[i].SetContent+'%以上';
            }
            if (list[i].SetTypeKey==112) {
              that.grade.low = list[i].SetContent;
              that.echartList[0].name = list[i].SetContent+'%以下';
              that.echartList[1].name = list[i].SetContent+'%以上';
            }
          }
          that.getChart();
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    getChart () {
      let that = this;
      GetGradeFullDataCount().then(result => {
        if (result.code == 0) {
          that.echartList[0].value = parseInt(result.data.LowGradeFull);
          that.echartList[1].value = parseInt(result.data.MediumGradeFull);
          that.echartList[2].value = parseInt(result.data.HighGradeFull);
          that.$nextTick(() => {
            that.draw();
          });
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    selectDateChange (obj) {
      this.search.StartTime = obj.startTime;
      this.search.EndTime = obj.endTime;
    },
    searchClick () {
      this.search.pageIndex = 0;
      this.getList();
    },
    resetClick () {
      this.$refs.selectDate.resetTime();
      this.search.StartTime = '';
      this.search.EndTime = '';
      this.search.pageIndex = 0;
      this.getList();
    },
    roomClick (key) {
      let that = this;
      that.search.TypeKey = key;
      this.search.pageIndex = 0;
      this.getList();
    },
    getList () {
      let that = this;
      GetGradeFullList(that.search).then(result => {
        if (result.code == 0) {
          let list = result.data.PageDataList;
          for (let i = 0; i < list.length; i++) {
            list[i].GradeFull = list[i].GradeFull + '%';
            list[i].Attence = list[i].Attence + '%';
          }
          that.totalNum = result.data.Total;
          that.PageCount = result.data.PageCount;
          that.tableData = list;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    setup () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '满班率档级设置',
        moduleName: 'courseSettingItem',
        data: {editItem: {attendanceNum: 3}},
        callBack: {
          afterSuccess: () => {
            this.search.pageIndex = 0;
            this.getList();
            this.getGrade();
          }
        }
      });
    }
  }
};
</script>

<style scoped>
</style>