<template>
  <div>
    <div class="form_info_edit form_info_list">
      <input-date
        v-model="dataJson.ServiceChargeStartTime"
        :formTitle="'开始时间'"
        :minDate="''"
        :readonly="isReadonly"
        :elementId="'BirthdayTime'"
        :required="true"
        :formPlaceholder="'必填'"
        @changeDate="changeDate"
      ></input-date>
      <input-number
        :formTitle="'间隔时间（年）'"
        :required="true"
        :readonly="isReadonly"
        :formPlaceholder="'请选择'"
        v-model="dataJson.ServiceChargeNum"
      ></input-number>
      <input-number
        :formTitle="'服务费用'"
        :required="true"
        :readonly="isReadonly"
        :formPlaceholder="'请输入'"
        v-model="dataJson.ServiceChargeAmount"
      ></input-number>
    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group
      v-if="!isReadonly"
      :isSingleSubmit="true"
      :singleSubmitType="'dynamic'"
      @confirmClick="confirmClick"
      @cancelClick="cancelClick"
    ></save-cancel-btn-group>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataJson: {
        ServiceChargeAmount: "", // "服务费",
        ServiceChargeNum: "", // "服务收费间隔",
        ServiceChargeStartTime: "", // "服务收费开始时间",
      },
      showList:false
    };
  },
  props:{
      dataInfo:Object,
      isReadonly:Boolean
  },
  created(){
      if(this.dataInfo){
          this.dataJson=this.$utils.parseJson(this.dataInfo)
      }
  },
  mounted(){
  },
  methods:{
      cancelClick(){
          this.$emit("cancelClick")
      },
      verifyData(callback){
          if(!this.dataJson.ServiceChargeStartTime){
              layer.alert('请选择开始时间')
              if(callback){
                  callback()
              }
              return false
          }else if(Number(this.dataJson.ServiceChargeNum)==0){
              layer.alert('间隔时间不能为0')
              if(callback){
                  callback()
              }
              return false
          }else if(Number(this.dataJson.ServiceChargeAmount)==0){
              layer.alert('服务费用不能为0')
              if(callback){
                  callback()
              }
              return false
          }else{
              return true
          }
        
      },
      changeDate(date){
        this.dataJson.ServiceChargeStartTime=date
      },
      confirmClick(callback){
          if(this.verifyData(callback)){
              this.$emit('afterSuccess',this.dataJson)
              this.$emit("cancelClick")
          }else{
           
          }
      },
  }

};
</script>