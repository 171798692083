<template>
  <div class="course_mandate_top">
    <summarizing-box v-for="(item,key,index) in summarizingBoxList"
                     :sumData="item"
                     :key="index"
                     @onCilckEvent="doAfterCilckSummaryData"></summarizing-box>
    <div class="top_right_ul">
      <summarizing-single-item v-for="(item,key,index) in summarizingSingleList"
                               :sumData="item"
                               :key="index"
                               @onCilckEvent="doAfterCilckSummaryData"></summarizing-single-item>
    </div>
  </div>
</template>

<script>
import summarizingBox from '../common/summarizing-box';
import summarizingSingleItem from '../common/summarizing-single-item';
export default {
  components: {
    summarizingBox,
    summarizingSingleItem
  },

  data () {
    return {

    };
  },
  props: {
    summarizingBoxList: Array,
    summarizingSingleList: Array
  },
  methods: {
    doAfterCilckSummaryData (item) {
      this.$emit('doAfterCilckSummaryData', item);
    }
  }
};
</script>

<style>
</style>