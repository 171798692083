<template>
  <div class="general_atSchool_student_list">
    <div class="table_fixed_nav_box">
      <div class="table_fixed_nav">
        <div @click="doAtSchoolstudentClick"
             class="table_fixed_nav_list"
             :class="{opt:showStudentList=='atSchool'}">
          在校学生
          <span class="form_info_field">
            <input-form-tip :tips="'已报名购课且未办理离校的学生。'"></input-form-tip>
          </span>
        </div>
        <div @click="doAllstudentClick"
             class="table_fixed_nav_list"
             :class="{opt:showStudentList=='all'}">全部学生</div>

        <!-- <div @click="dostudentKQClick"
             class="table_fixed_nav_list"
             :class="{opt:showStudentList=='studentKQ'}">学生考勤</div> -->
      </div>
    </div>
    <div class="margin_left_right_div">
      <div style="margin-top:70px"
           class="template_minOrmax_width_div">
        <!-- 全部学生 -->
        <div v-if="showStudentList=='all'">
          <allStudent ref="allstudent"
                      @openCustomDialog="openCustomDialogByFactoryDialog"
                      @openCommonCustomDialog="openCommonCustomDialogByFactoryDialog"></allStudent>
        </div>
        <!-- 学生考勤表 -->
        <div v-else-if="showStudentList=='studentKQ'">
          <student-attendance-list @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"></student-attendance-list>
        </div>
        <!-- 在校学生 -->
        <div v-else
             style="border-radius:8px;overflow:hidden;">
          <list ref="studentApplyCourseTable"
                class="summarizing_list "
                :tableData="vStudentList"
                :pageSize="10"
                :totalNum="totalNum"
                :tableColumns="tableColumns"
                :paramsForStudent="paramsForStudent"
                :summary="summary"
                :rowKey="'Number'"
                :defaultSort="defaultSort"
                :titleContent="headSummaryData"
                :showStudentList="showStudentList"
                @searchStudent="searchStudent"
                @loadTableData="loadAfterTableData"
                @clearSearchParams="clearSearchCondition"
                @doTitleBoxClick="doAfterTitleBoxClick"></list>
        </div>
      </div>
    </div>
    <div>
      <dialog-factory ref="studentApplyCourseReportDialogFactory"
                      :routerName="'studentManagementReport'"></dialog-factory>
    </div>
    <dialog-factory-common ref="studentManagementCommonDialogFactory"></dialog-factory-common>
  </div>
</template>
<script>
import {
  getStudentAllCourse,
  MessageSearchArrangedPageForActiveStudent,
  setLeaveSchool,
  WeChatUnbind,
  GradeMessageSearchArrangedPageForGradeStudent
} from '../../../API/workbench.js';
import list from './general-table';
import textareaPop from '../../common/textarea-pop';
import dialogFactory from '../../common/dialog-factory';
import studentCoursesArranging from '../../pop-up-menu/student-courses-arranging/index';
import allStudent from '../all-student/index';
import studentAttendanceList from './student-attendance-list/index';
import { customTipsForTableHeadColumn } from '../../../assets/index.js';
export default {
  data () {
    return {
      showStudentList: 'atSchool', // atSchool-在校列表，all-全部学生列表
      // 在校学生统计
      paramsForStudent: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母
        orderBy: 'StudentKeyValue', //	字符串	可选		数据源：GET	排序字段
        sequence: 'asc', //	字符串	可选		数据源：GET	排序顺序
        QueryID: '', // 整型	可选	0	数据源：GET	学生ID
        SpecialFilter: '0', // 整型	可选	0	数据源：GET	0-不生效;2-按待报>0;3-按课时不足<=5
        SubscribeKey: ''// 关注状态：0-未关注;1-已关注;空-不生效
      },
      studentList: [], // 按学生 的 学生列表
      searchText: '',
      headSummaryData: [
        { title: '在校学生', opt: '在校学生', className: 'font_blue', value: 0, SpecialFilter: '0', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: true },
        { title: '课时不足', type: 'Num_NotEnought', className: 'font_orange', value: 0, SpecialFilter: '3', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false },
        { title: '课时耗尽', type: 'Num_UsedUpClassCount', className: 'font_orange', value: 0, SpecialFilter: '2', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false },
        { title: '已关注', className: 'font_blue', value: 0, SubscribeKey: '1', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false },
        { title: '未关注', type: 'Num_UnSubscribe', className: 'font_orange', value: 0, SubscribeKey: '0', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false }
      ],
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          type: 'text-item',
          sortable: false,
          width: 58
        },
        {
          label: '学管师',
          prop: 'AdviserStudentKeyValue',
          width: 100,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 85,
          type: 'student',
          renderHeader: (h, { column, $index }) => {
            return customTipsForTableHeadColumn(h, column, '点击名字可查看学生详细档案');
          },
          extend: {
            studentKey: 'StudentKey', // 学生ID
            click: () => { }
          }
        },
        {
          label: '手机号',
          prop: 'CustomerPhoneName',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '可用课单',
          prop: 'ClassUnitCount',
          width: 80,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '可用课时',
          prop: 'CurrentClassHourCount',
          width: 90,
          sortable: 'custom',
          align: 'center',
          isShow: true,
          type: 'class-hour'
        },
        // {
        //   label: '可用课时',
        //   prop: 'CurrentClassHourCount',
        //   type: 'class-hour',
        //   width: 120,
        //   sortable: 'custom',
        //   isShow: true,
        //   renderHeader: (h, { column, $index }) => {
        //     return customTipsForTableHeadColumn(h, column, '学生所有可用课时，点击数字查看明细课消');
        //   },
        //   extend: {
        //     type: 'expand',
        //     click: () => { },
        //     isLoading: true, // 正在加载中
        //     tableWidth: '1060px',
        //     left: '100px',
        //     isShowOweMark: true,
        //     loadtableData: (rowData, callBack) => { // 获取详情数据
        //       this.studentKey = rowData.StudentKey;
        //       this.getStudentAllCourseDetial(callBack);
        //     },
        //     tableColumnsDetail: [
        //       {
        //         label: '购买时间',
        //         prop: 'newPaidTime',
        //         type: 'date-item',
        //         width: 140,
        //         extend: {
        //           dateType: 'YYMMRR HH:DD' // 日期类型
        //         }
        //       },
        //       {
        //         label: '适用课程',
        //         prop: 'ApplyCorName',
        //         type: 'reading-courses'
        //       },
        //       {
        //         label: '类型',
        //         prop: 'newSemesterCalText',
        //         type: 'text-item'
        //       },
        //       {
        //         label: '购买课时',
        //         prop: 'newNumCount',
        //         type: 'class-hour'
        //       },
        //       {
        //         label: '消耗课时',
        //         prop: 'expendCount',
        //         type: 'class-hour'
        //       },
        //       {
        //         label: '调整课时',
        //         prop: 'AdjustCount',
        //         type: 'class-hour'
        //       },
        //       {
        //         label: '可用课时',
        //         prop: 'newCurrentCount',
        //         type: 'class-hour'
        //       },
        //       {
        //         label: '可用天数',
        //         prop: 'PeriodCount',
        //         type: 'class-hour'
        //       },
        //       {
        //         label: '操作',
        //         prop: 'option',
        //         type: 'table-btn',
        //         width: 120,
        //         extend: {
        //           tableBtnGroup: [
        //             {
        //               name: '课消',
        //               extend: {
        //                 click: (rowData) => {
        //                   this.doCourseDetialConsumeBtnClick(rowData);
        //                 },
        //                 ModulePowerKey: 51
        //               }
        //             }
        //           ]
        //         }
        //       }
        //     ]
        //   }
        // },
        {
          label: '加入班级',
          prop: 'addClassValue',
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item'
        },
        // {
        //   label: '最近签到',
        //   prop: 'newLastSignTime',
        //   type: 'text-item',
        //   width: 60,
        //   sortable: 'custom',
        //   columnClass: 'font_dual'
        // },
        {
          label: '排课计划',
          prop: 'sourceData',
          type: 'reading-course-detail',
          columnClass: 'no_border',
          width: 400,
          renderHeader: (h, { column, $index }) => {
            return customTipsForTableHeadColumn(h, column, '学生在上的课，所在班级以及所在的排课。');
          },
          extend: {
            width: 400,
            splitSymbol: ','
          }
        },
        {
          label: '排课备注',
          prop: 'ArrangeSupport',
          type: 'arrange-support',
          width: 100,
          extend: {
            studentKey: 'StudentKey', // 学生ID
            studentKeyValue: 'StudentKeyValue', // 学生名
            click: () => { }
          }
        },
        {
          label: '关注',
          prop: 'SubscribeKey',
          type: 'wechat-subscribe',
          width: 60,
          extend: {
            click: (rowData) => { this.wechatSubscribeUnbind(rowData); }
          }
        },
        {
          label: '最近上课',
          prop: 'LastSignTime',
          width: 110,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '销售',
          prop: 'AdviserKeyValue',
          width: 60,
          sortable: false,
          align: 'center',
          isShow: true,
          type: 'text-item'
        },
        // {
        //   label: '刷脸',
        //   prop: 'FaceRecognition',
        //   type: 'face-recognition',
        //   width: 60
        // },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          fixed: 'right',
          width: 183,
          extend: {
            tableBtnGroup: [
              {
                name: '排课',
                extend: {
                  click: (rowData) => { this.doArrangeCourseBtnClick(rowData); },
                  ModulePowerKey: '51'
                }
              },
              {
                name: '续费',
                extend: {
                  click: (rowData) => { this.doSignupPaymentBtnClick(rowData); },
                  ModulePowerKey: '50'
                }
              },
              {
                name: '离校',
                extend: {
                  // isHide: true,
                  IsInquiryHint: 'inquiryBtn',
                  setClassName: (rowData) => {
                    if (rowData.inquiryBtn) { // 开启询问提示，显示询问弹窗时，界面按钮置灰，不可操作
                      return rowData.inquiryBtn;
                    } else if (Number(rowData.CurrentClassHourCount) > 0 || Number(rowData.YearCardMaxDayCount) > 0) {
                      return 'btn_light_gray';
                    }
                  },
                  click: (rowData, callBack) => {
                    if (callBack) {
                      callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: 'table_btn_gray' });
                    }
                    this.doLeaveSchoolBtnClick(rowData, callBack);
                  },
                  ModulePowerKey: '48'
                }
              },
              {
                name: '查看',
                extend: {
                  click: (rowData) => { this.showStudentFile(rowData); }
                }
              }
            ]
          }
        }
      ],
      expandRowData: [], // 展开行的数据
      totalNum: 0,
      summary: {
        needSummariesColumns: [
          { prop: 'StudentKeyValue', SumType: 'string', unit: '人' },
          { prop: 'Total_NumCount', SumType: 'number', unit: '' },
          { prop: 'Sum_SpendCount', SumType: 'number', unit: '' },
          { prop: 'CurrentClassHourCount', SumType: 'number', unit: '' },
          { prop: 'Sum_CurrentCount', SumType: 'number', unit: '' },
          { prop: 'Total_ToBePlaned', SumType: 'number', unit: '' },
          { prop: 'Sum_ArrangedCount', SumType: 'number', unit: '' },
          { prop: 'Sum_ToBeArranged', SumType: 'number', unit: '' }
        ],
        isShowSummary: false,
        summaryName: '合计',
        summaryData: []
      },
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  components: {
    list,
    textareaPop,
    studentCoursesArranging,
    dialogFactory,
    allStudent,
    studentAttendanceList
    // courseApplycourse: () => import('../../pop-up-menu/course-applycourse/index') // 异步注册组件
  },
  created () {
    if (this.$route.params.data && this.$route.params.data.headSummaryData) { // 外部进入定位
      console.log(this.$route.params.data, '外部进入定位');
      this.headSummaryData.forEach(o => {
        if (o.title == this.$route.params.data.headSummaryData.title) {
          this.doAfterTitleBoxClick(o);
        }
      });
    } else {
      this.getMessageSearchTicketPlanPageForStudent();
    }
    // console.log(this.$route.params, '外部进入定位-created');
    this.registerBusEvent();
  },
  watch: {
    // 对路由变化作出响应...
    '$route' (to, from) {
      console.log(this.showStudentList, from.name, to.name, '监听在校学生路由变化');
      if (this.showStudentList == 'atSchool') {
        this.getMessageSearchTicketPlanPageForStudent();
      }
    }
  },
  activated () {
    if (this.$route.params.data && this.$route.params.data.headSummaryData) { // 外部进入定位,防止打开时，第二次没有默认选中的问题
      // console.log(this.$route.params.data, '外部进入定位-activated');
      this.headSummaryData.forEach(o => {
        if (o.title == this.$route.params.data.headSummaryData.title) {
          this.doAfterTitleBoxClick(o);
        }
      });
    }
  },
  mounted () {

  },
  methods: {
    // 显示学生信息
    showStudentFile (item) {
      console.log(item, 'ite6666');
      this.$dialog.open(this, {
        name: '学生档案',
        routerName: this.$route.name,
        moduleName: 'studentFile',
        data: { studentID: item.StudentKey }
      });
    },
    // 在校学生
    doAtSchoolstudentClick () {
      this.showStudentList = 'atSchool';
      console.log(this.paramsForStudent.orderBy, '在校学生');
      this.doAfterTitleBoxClick(this.headSummaryData[0]);
    },
    // 通用版， 全部学生
    doAllstudentClick () {
      this.showStudentList = 'all';
    },
    // 通用版，学生考勤
    dostudentKQClick () {
      this.showStudentList = 'studentKQ';
    },
    // 选择筛选在校学生类型
    doAfterTitleBoxClick (item) {
      console.log(item, 'doAfterTitleBoxClick');
      this.headSummaryData.forEach(o => { // 高亮显示
        if (item.title == o.title) {
          o.isSelected = true;
          o.className = 'font_blue';
        } else {
          if (o.type == 'Num_NotEnought' || o.type == 'Num_UsedUpClassCount' || o.type == 'Num_UnSubscribe') {
            o.className = 'font_orange';
          }
          o.isSelected = false;
        }
      });
      this.paramsForStudent.searchText = '';
      this.paramsForStudent.pageIndex = 0;
      this.paramsForStudent.SubscribeKey = '';
      this.paramsForStudent.SpecialFilter = '';
      this.expandRowData = [];
      if (this.$refs && this.$refs.studentApplyCourseTable) {
        this.$refs.studentApplyCourseTable.$refs.tableListRef.expandRowKeys = [];
      }
      if (item.SpecialFilter) {
        this.paramsForStudent.SpecialFilter = item.SpecialFilter;
      } else if (item.SubscribeKey) {
        this.paramsForStudent.SubscribeKey = item.SubscribeKey;
      }
      this.getMessageSearchTicketPlanPageForStudent();
    },
    // 获取通用版在校学生统计
    getMessageSearchTicketPlanPageForStudent () {
      // if (this.paramsForStudent.orderBy == 'newLastSignTime') {
      //   this.paramsForStudent.orderBy = 'LastSignTime';
      // }
      this.paramsForStudent.orderBy = (this.paramsForStudent.orderBy == 'studentInfo' ? 'StudentKeyValue' : this.paramsForStudent.orderBy);
      MessageSearchArrangedPageForActiveStudent(this.paramsForStudent).then(result => {
        this.studentList = [];
        this.initSummaryData(result.data.sumamarize);
        this.totalNum = result.data.Total;
        if (result.data.PageDataList.length > 0) {
          let newStartTime = this.$utils.formatDatet(Date.new(), 'yyyy-MM-dd');
          result.data.PageDataList.forEach((o, index) => {
            // 学生块
            let info = o.StudentKeyValue;
            o.studentInfo = info.split(',');
            o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
            o.CustomerPhoneName = o.CustomerPhoneName ? o.CustomerPhoneName : '-';
            o.AdviserKeyValue = o.AdviserKeyValue ? o.AdviserKeyValue : '-';
            o.addClassValue = o.TheClass.join();
            // 最近签到
            if (o.LastSignTime) {
              o.newLastSignTime = this.$utils.DateDiffDay(o.LastSignTime, newStartTime);
              if (o.newLastSignTime == -1) {
                o.newLastSignTime = '明天';
              } else if (o.newLastSignTime == 0) {
                o.newLastSignTime = '今天';
              } else if (o.newLastSignTime > 0 && o.newLastSignTime < 7) { // 显示天
                o.newLastSignTime = o.newLastSignTime + '天前';
              } else if (o.newLastSignTime >= 7 && o.newLastSignTime < 30) { // 显示周
                o.newLastSignTime = parseInt(o.newLastSignTime / 7) + '周前';
              } else if (o.newLastSignTime >= 30 && o.newLastSignTime < 365) { // 显示月
                o.newLastSignTime = parseInt(o.newLastSignTime / 30) + '月前';
              } else if (o.newLastSignTime >= 365) { // 显示年
                o.newLastSignTime = parseInt(o.newLastSignTime / 365) + '年前';
              }
            } else {
              o.newLastSignTime = '';
            }
            o.ClassUnitCount = o.ClassUnitCount ? o.ClassUnitCount : '-';
            o.LastSignTime = o.LastSignTime ? o.LastSignTime : '-';
            o.addClassValue = o.addClassValue ? o.addClassValue : '-';
            // 是否会员
            o.IsmemberValue = '关注';
            // 学期卡课单学生 显示适用课时 有，支持展开
            if (Number(o.CurrentClassHourCount) == 0 && o.YearCardMaxDayCount > 0) {
              o.SemesterCalShow = '有';
            }
          });
        }
        this.studentList = result.data.PageDataList || [];
        console.log(this.studentList, '在校学生统计');
      });
    },
    // 汇总数据
    initSummaryData (data) {
      console.log(data, ' 汇总数据');
      if (data) {
        this.headSummaryData[0].value = data.Total_Student;// 在校学生
        this.headSummaryData[1].value = data.Num_NotEnought;// 课时不足
        this.headSummaryData[2].value = data.Num_UsedUpClassCount;// 课时耗尽
        this.headSummaryData[3].value = data.Num_Subscribe;// 已关注
        this.headSummaryData[4].value = data.Num_UnSubscribe;// 未关注
      }
    },
    // 跳转页码
    loadAfterTableData (queryParams) {
      console.log(queryParams, '跳转页码');
      this.paramsForStudent.orderBy = queryParams.orderBy ? queryParams.orderBy : this.paramsForStudent.orderBy;
      this.paramsForStudent.pageIndex = queryParams.pageIndex ? queryParams.pageIndex : this.paramsForStudent.pageIndex;
      this.paramsForStudent.pageSize = queryParams.pageSize ? queryParams.pageSize : this.paramsForStudent.pageSize;
      this.paramsForStudent.searchText = queryParams.searchText ? queryParams.searchText : this.paramsForStudent.searchText;
      this.paramsForStudent.sequence = queryParams.sequence ? queryParams.sequence : this.paramsForStudent.sequence;
      this.expandRowData = [];
      this.getMessageSearchTicketPlanPageForStudent();
    },
    // 搜索
    searchStudent (searchText) {
      this.paramsForStudent.searchText = searchText;
      this.paramsForStudent.pageIndex = 0;
      this.paramsForStudent.ApplyClassKey = 0;
      this.expandRowData = [];
      this.getMessageSearchTicketPlanPageForStudent();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchText = '';
      this.paramsForStudent.searchText = '';
      this.paramsForStudent.pageIndex = 0;
      this.paramsForStudent.ApplyClassKey = 0;
      this.getMessageSearchTicketPlanPageForStudent();
    },
    // 排课按钮
    doArrangeCourseBtnClick (rowData) {
      this.studentKey = rowData.StudentKey;
      let moduleInfo = {};
      if (((rowData.ApplyClassKey > 0 && !rowData.sourceData) || (rowData.sourceData && rowData.sourceData.length == 1)) &&
        !rowData.UnitKey// 当UnitKey为true时 为课单详情，不需要预置课程
      ) {
        moduleInfo = {
          routerName: 'studentManagementReport',
          routertitle: '学生管理',
          moduleName: 'studentArrangeCourseByCourseName',
          name: '按课程排课',
          data: {
            studentID: this.studentKey,
            courseNameID: rowData.ApplyClassKey,
            courseName: rowData.ApplyClassKeyValue
          }
        };
        this.openCommonCustomDialogByFactoryDialog(moduleInfo);
        // this.openCustomDialogByFactoryDialog(moduleInfo);
      } else {
        moduleInfo = {
          routerName: 'studentManagementReport',
          routertitle: '学生管理',
          moduleName: 'studentArrangeCourseByCourseName',
          name: '按课程排课',
          data: {
            studentID: this.studentKey
          }
        };
        this.openCommonCustomDialogByFactoryDialog(moduleInfo);
        // this.openCustomDialogByFactoryDialog(moduleInfo);
      }
    },
    // 续费按钮
    doSignupPaymentBtnClick (rowData) {
      this.studentKey = rowData.StudentKey;
      let moduleInfo = {
        name: '报名收款',
        routerName: this.$route.name,
        moduleName: 'signuppayment',
        data: { dataInfo: { StudentKey: this.studentKey, isReadOnly: false, TableID: '' } },
        callBack: {
          afterSucces: () => {
            this.getMessageSearchTicketPlanPageForStudent();
            this.getStudentAllCourseDetial();
          }
        }
      };
      this.openCommonCustomDialogByFactoryDialog(moduleInfo);
    },
    // 离校按钮
    doLeaveSchoolBtnClick (rowData, callBack) {
      console.log(rowData, '离校');
      var _this = this;
      if (Number(rowData.CurrentClassHourCount) == 0) {
        layer.confirm(rowData.StudentKeyValue + '同学是否需要离校?', {
          btn: [
            {
              name: '取消',
              callBack: function () {
                layer.close();
                if (callBack) {
                  callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
                }
              }
            }, {
              name: '确认',
              callBack: function () {
                layer.close();
                setLeaveSchool(rowData.StudentKey).then(result => {
                  _this.$emit('auditPass');
                  layer.close();
                  _this.getMessageSearchTicketPlanPageForStudent();
                  if (callBack) {
                    callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
                  }
                }, error => {
                  layer.alert(error.msg);
                });
              }
            }
          ]
        });
      }
    },
    // 可用课时详情课消按钮
    doCourseDetialConsumeBtnClick (rowData) {
      console.log(rowData, '可用课时详情课消按钮');
      // this.studentKey = rowData.StudentKey;
      let moduleInfo = {
        name: '课单课消',
        moduleName: 'buyClassDetailRecord',
        data: {
          studentKey: this.studentKey, // 学生ID
          courseBillKey: rowData.TableID // 课单ID
        }
      };
      this.openCommonCustomDialogByFactoryDialog(moduleInfo);
    },
    // 获取学生的课单详情(可用课时)
    getStudentAllCourseDetial (callBack) {
      if (!this.studentKey) {
        return;
      }
      getStudentAllCourse(this.studentKey).then(result => {
        if (result.data) {
          this.expandRowData = [];
          if (result.data && result.data.length > 0) {
            result.data.forEach((o, index) => {
              o.Number = index + 1;
              o.ApplyClassKey = o.ApplyCorIDsName;
              o.ApplyClassKeyValue = o.ApplyCorName;
              o.AdjustCount = Number(o.AdjustCount);
              o.sourceData = o.ApplyCorName.replace(/\，/g, ',').split(',');
              // 日期
              o.newPaidTime = o.PaidTime + '  ' + o.BuyTime.slice(0, 5);

              // 可用课时或适用课时展开课单显示调整
              o.newCurrentCount = o.CurrentCount;
              o.newNumCount = o.NumCount;
              o.newSemesterCalText = o.UnitKeyValue + '卡';
              if (o.UnitKey != 5) { // 学期卡
                o.newCurrentCount = 0;
                o.newNumCount = 0;
              }

              if (o.StatusKey == 2 && o.CurrentCount > 0) {
                this.expandRowData.push(o);
              }
            });
          }
          this.expandRowData.sort((x, y) => { return Date.new(y.PaidTime) - Date.new(x.PaidTime); });// 购买时间排序
          if (callBack) {
            callBack(this.expandRowData || []);
          }
          console.log(this.expandRowData, 'result.data.PageDataList-可用课时');
        }
      });
    },
    // 微信关注解绑
    wechatSubscribeUnbind (rowData) {
      console.log(rowData, '微信关注解绑');
      layer.confirm('请问是否解绑?', {
        btn: [{
          name: '确认',
          callBack: () => {
            WeChatUnbind(rowData.CustKey).then(
              result => {
                layer.alert('解绑成功');
                this.getMessageSearchTicketPlanPageForStudent();
              }, error => {
                layer.alert(error.msg);
              });
            layer.close();
          }
        }
        ]
      });
    },
    registerBusEvent () {
      // 修改学生档案的学生信息成功后触发回调 SelectedSummaryItem
      this.$bus.on(this, 'AfterStudentUpdate', studentKey => {
        if (studentKey > 0) {
          this.getMessageSearchTicketPlanPageForStudent();
        }
      });
      // 刷新排课数据
      this.$bus.on(this, 'afterArrangeScheduleCourse', () => {
        console.log('刷新排课');
        this.getMessageSearchTicketPlanPageForStudent();
        this.getStudentAllCourseDetial();
      });
      // 刷新循环课排课数据
      this.$bus.on(this, 'afterArrangeCirculationCourse', () => {
        this.getMessageSearchTicketPlanPageForStudent();
        this.getStudentAllCourseDetial();
      });
      // 学生报课
      this.$bus.on(this, 'AfterStudentApplyCourse', applyCourseInfo => {
        this.getMessageSearchTicketPlanPageForStudent();
        this.getStudentAllCourseDetial();
      });
      // 单学生排课
      this.$bus.on(this, 'AfterArrangeForSingleStudent', (type, courseID, studentID) => {
        this.getMessageSearchTicketPlanPageForStudent();
        this.getStudentAllCourseDetial();
      });
      // 课程报课成功
      this.$bus.on(this, 'AfterCourseNameApplyCourse', () => {
        this.getMessageSearchTicketPlanPageForStudent();
        this.getStudentAllCourseDetial();
      });
      // 课消记录
      this.$bus.on(this, 'AfterCourseConsumeUpDate', result => {
        this.getMessageSearchTicketPlanPageForStudent();
        this.getStudentAllCourseDetial();
      });
      // 还课时
      this.$bus.on(this, 'AfterRepayClassHour', result => {
        this.getMessageSearchTicketPlanPageForStudent();
        this.getStudentAllCourseDetial();
      });
      // 修改课单内容成功后的回调;
      this.$bus.on(this, 'AfterEditCourseSingle', result => {
        this.getMessageSearchTicketPlanPageForStudent();
        this.getStudentAllCourseDetial();
      });
      // 加入班级、退出班级后
      this.$bus.on(this, 'afterQuitOrAddClass', (classID, studentKey, type) => {
        console.log(classID, studentKey, '加入班级后');
        this.getMessageSearchTicketPlanPageForStudent();
        this.getStudentAllCourseDetial();
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'studentManagementReport') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'studentManagementReport') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    // 单例
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCustomDialogByFactoryDialog', dialogInfo);
      // this.$emit('openCustomDialog', dialogInfo);
      if (dialogInfo.routerName == 'studentManagementReport') {
        if (this.$refs.studentApplyCourseReportDialogFactory) {
          this.$refs.studentApplyCourseReportDialogFactory.openDialog(
            dialogInfo.moduleName,
            dialogInfo.dataKey,
            dialogInfo.data,
            dialogInfo.callBack,
            dialogInfo.name
          );
        }
      }
    },
    // 多例
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      // this.$emit('openCommonCustomDialog', dialogInfo);
      if (this.$refs.studentManagementCommonDialogFactory) {
        this.$refs.studentManagementCommonDialogFactory.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  },
  computed: {
    // 构建数据
    vStudentList () {
      if (this.studentList.length > 0) {
        let list = [];
        this.studentList.forEach((o, index) => {
          if (o.ArrangeSupport) {
            o.rowCount = Math.ceil(this.$utils.getTextWidth(o.ArrangeSupport, 16) / 262.73);
          }
          o.sourceData = [];
          o.ApplyClass.sort((x, y) => {
            return parseInt(x.ClassTimeName) - parseInt(y.ClassTimeName);
          });
          o.ApplyClass.sort((x, y) => {
            return parseInt(x.WeekKey) - parseInt(y.WeekKey);
          });
          o.ApplyClass.forEach((obj, index) => {
            o.sourceData.push(
              {
                'CourseNameKey': obj.CourseNameKey,
                'CourseNameKeyValue': obj.CourseNameKeyValue,
                // 'GradeClassKey': obj.GradeClassKey,
                // 'GradeClassKeyValue': obj.GradeClassKeyValue,
                ClassList: [],
                arrangedList: []
              }
            );
          });
          // 利用reduce方法遍历数组,reduce第一个参数是遍历需要执行的函数，第二个参数是item的初始值
          let Obj = {};
          o.sourceData = o.sourceData.reduce(function (item, next) {
            Obj[next.CourseNameKey] ? '' : Obj[next.CourseNameKey] = true && item.push(next);
            return item;
          }, []);
          o.ApplyClass.forEach((obja, index) => {
            o.sourceData.forEach((objb, index1) => {
              if (objb.CourseNameKey == obja.CourseNameKey) {
                objb.ClassList.push({ 'GradeClassKey': obja.GradeClassKey, 'GradeClassKeyValue': obja.GradeClassKeyValue, arrangedList: [] });
                // objb.arrangedList.push(obja);
                const hash = {};
                objb.ClassList = objb.ClassList.reduce((item, next) => {
                  hash[next.GradeClassKey] ? '' : hash[next.GradeClassKey] = true && item.push(next);
                  return item;
                }, []);
                objb.ClassList.forEach((objc, index2) => {
                  if (objc.GradeClassKey == obja.GradeClassKey) {
                    objc.arrangedList.push(obja);
                  }
                });
                // objb.arrangedList.push(obja);
              }
              // if (obja.CourseNameKey == objb.CourseNameKey && obja.GradeClassKey == objb.GradeClassKey) {
              // objb.arrangedList.push(obja, objb);
              // objb.arrangedList.push(obja);
              // }
            });
          });
          if (o.sourceData.length == 0) {
            o.sourceData = [
              {
                'CourseNameKey': '-',
                'CourseNameKeyValue': '-',
                ClassList: [
                  {
                    GradeClassKey: '-',
                    GradeClassKeyValue: '-'
                  }
                ]
              }
            ];
          }
          if (o.ApplyClass.length == 0) {
            o.ApplyClass = [
              {
                WeekKeyValue: '-',
                ClassTimeName: '-',
                CourseNameKeyValue: '-',
                GradeClassKeyValue: '-',
                StudentKeyValue: '-'
              }
            ];
          }
          list.push(o);
        });
        // console.log(repetitionItemList, '重复数据');
        console.log(list, 'scope.row.sourceData.arrangedList.length');
        return list;
      } else {
        return this.studentList;
      }
    }
  }
};
</script>

<style scoped>
.table_fixed_nav {
  width: auto !important;
  min-width: 1366px;
  max-width: 1920px;
  padding-left: 20px;
}
.general_atSchool_student_list .el-table__body-wrapper,
.el-table__footer-wrapper,
.el-table__header-wrapper {
  width: 104%;
}
</style>

