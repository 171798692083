<template>
  <div class="form_item form-input form_info_line" :class="{ form_info_required: required }">
    <div class="form_info_field">
      <span>{{formTitle}}</span>
    </div>
    <div class="form_info_value form_info_select" v-if="!readonly" @click.stop="showEldilog">
      <input
        type="text"
        :readonly="readonly"
        :disabled="disabled"
        :value="value"
        :placeholder="vPlaceholder"
        @input="updateVal($event.target.value)"
        @change="changeVal($event.target.value)"
      />
    </div>
    <div v-else class="form_info_value" :class="formInfoSelect==false?'':'form_info_select'">
      <span class="input_readonly">{{value}}</span>
    </div>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: 'formDialog',
  data () {
    return {};
  },
  props: {
    formTitle: {
      type: String,
      required: true
    }, // 标题，必填
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    disabled: Boolean, // 是否禁止修改，可选，如不提供，则可修改
    formPlaceholder: String, // 默认文本，可选，如不提供，则默认显示title
    formInfoSelect: {
      type: Boolean,
      default: false
    } // 只读时，是否需要显示箭头符号，默认为false
  },
  computed: {
    vPlaceholder () {
      // 默认文本，如不提供，则默认显示title
      return this.formPlaceholder || this.formTitle;
    }
  },
  created () {},
  watch: {},
  methods: {
    updateVal (val) {
      this.$emit('input', val);
    },
    changeVal (val) {
      this.$emit('change', val);
    },
    showEldilog () {
      this.$emit('showEldilog');
    }
  }
};
</script>
