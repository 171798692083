<template>
  <span>
    <el-tooltip popper-class="from_tooltip_box"
                effect="dark"
                visible-arrow="false"
                placement="top">
      <div slot="content"
           v-html="tips"></div>
      <div class="header_tips_icon"></div>
    </el-tooltip>
  </span>
</template>


<script>
export default {
  props: {
    tips: String
  }
};
</script>

<style>
</style>