<template>
  <div class="record_list_month single_shadow">
    <div class="record_list_month_title">
      <div class="layout_flex1"><span>{{monthAttnItem.Year}}.{{monthAttnItem.Month}}</span></div>
      <div class="layout_flex2"><span class="font_gray">上课：</span><span>{{monthAttnItem.Total_Course}}节</span></div>
      <div class="layout_flex3"><span class="font_gray">小计：</span><span>{{Number(monthAttnItem.Total_ClassCount).toFixed(1)}}课时</span></div>
      <div class="layout_flex4"><span class="font_gray">月出勤率：</span><span>{{AttanRate}}</span></div>
      <div class="layout_flex5"></div>
      <div class="layout_flex6 font_blue"
           v-if="monthAttnItem.Total_Course>0"
           @click.stop="getMonthAttenDetail">{{isShowAtten}}<span class="record_list_month_title_icon"
              :class="monthAttnItem.isActive &&monthAttnItem.attenList.length>0?'month_title_icon_up':'month_title_icon_down'"></span></div>
      <div class="layout_flex6"
           v-else
           :style="{color:'gray'}">展开<span class="record_list_month_title_icon month_title_icon_down_gray"></span></div>

    </div>
    <el-collapse-transition>
      <div class="record_list_month_content"
           v-show="monthAttnItem.isActive">
        <attenItem v-for="(attenItem,key) in monthAttnItem.attenList"
                   :key="key"
                   :attenItem="attenItem"
                   @showCourseDetail="showCourseDetail"></attenItem>
      </div>
    </el-collapse-transition>
  </div>
</template>
<script>
import attenItem from './atten-item';
export default {
  name: 'attandanceMonth',
  data () {
    return {

    };
  },
  components: {
    attenItem
  },
  props: ['monthAttnItem'],
  computed: {
    isShowAtten () {
      if (this.monthAttnItem.isActive == true && this.monthAttnItem.attenList.length > 0) {
        return '收起';
      } else {
        return '展开';
      }
    },
    AttanRate () {
      return (Number(this.monthAttnItem.AttendanceRate) * 100).toFixed(2) + '%';
    }
  },
  methods: {
    getMonthAttenDetail () {
      this.$emit('getMonthAttenDetail', this.monthAttnItem.Year, this.monthAttnItem.Month, () => {
        this.$nextTick(() => {
          this.monthAttnItem.isActive = !this.monthAttnItem.isActive;
        });
      });
    },
    showCourseDetail (val) {
      this.$emit('showCourseDetail', val);
    }
  }

};
</script>

