<template>
  <div class="all_workbench_right"
       v-if="investorInfo">
    <!-- top 招商顾问详情-->
    <investor-info v-if="merchantsDataInfo"
                   :dataInfo="merchantsDataInfo"
                   @afterSuccess="updateData"></investor-info>
    <!-- bottom 对招商顾问的跟单和推荐详情-->
    <div class="all_workbench_bottom"
         v-if="merchantsDataInfo.OLAPKey>0">
      <!-- left 跟单记录 -->
      <div class="all_workbench_center">
        <follow-up-records :dataInfo="merchantsDataInfo"
                           :followRecordInfo="followRecordInfo"></follow-up-records>
      </div>
      <!-- right 跟单-->
      <div class="all_workbench_right">
        <follow-up-form ref="followUp"
                        :followUpInfo="followUpInfo"
                        @afterSuccess="updateData"
                        :merchantsDataInfo="merchantsDataInfo"></follow-up-form>
      </div>
    </div>
  </div>
</template>

<script>
import investorInfo from './investor-info';
import followUpRecords from './follow-up-records';
import followUpForm from './follow-up-form/index';
import { hqGetAttractThreadFollowUpRecord, hqGetAttractThreadMsg } from '../../../../../API/workbench';
export default {
  data () {
    return {
      merchantsDataInfo: {}, // 招商顾问信息
      followRecordInfo: {
        FollowRecordCount: 0,
        NextFollowTime: '',
        FollowRecordList: [],
        isActive: true
      },
      // 跟进
      followUpInfo: {
        TypeKey: 1, // 1-首次跟进;2-日常跟进;3-推荐品牌;4-放弃;5-推荐反馈（必填）
        NextFollowTime: this.$utils.formatDatet(Date.new(), 'yyyy-MM-dd'), // 跟进日期（可选）
        AttractInvestKey: '', // 招商线索key （必填）
        AttractInvestKeyValue: '' // 招商线索（必填）
      }
    };
  },
  components: {
    investorInfo,
    followUpRecords,
    followUpForm
  },
  props: {
    investorInfo: Object
  },
  created () {
    console.log('investorInfo', this.investorInfo);
  },
  methods: {
    initGetData (investorInfo) {
      this.getInvestorData(investorInfo, () => {
        this.getInvestFollowRecordList(investorInfo);
      });
    },
    // 招商顾问跟单记录
    getInvestFollowRecordList (investorInfo) {
      hqGetAttractThreadFollowUpRecord(investorInfo.OLAPKey).then((result) => {
        console.log('招商顾问跟单记录', result.data);
        result.data.isActive = true;
        this.followRecordInfo = result.data;
        this.doClickfollowUp();// 默认选中跟进
      }, (error) => {
        layer.alert(error.msg);
      });
    },
    // 获取招商线索详情
    getInvestorData (investorInfo, callback) {
      hqGetAttractThreadMsg(investorInfo.OLAPKey).then((result) => {
        console.log('获取招商线索详情', result.data);
        this.merchantsDataInfo = result.data;
        if (callback) {
          callback();
        }
      }, (error) => {
        layer.alert(error.msg);
      });
    },
    // 跟进显示。IsFirstFollowUpKey-是否首次跟进 1已跟进0未跟进;IsAnswerKey 是否接头1是0否;
    doClickfollowUp () {
      if (Number(this.merchantsDataInfo.IsFirstFollowUpKey) == 0 || Number(this.merchantsDataInfo.IsAnswerKey) == 0) {
        this.followUpInfo.TypeKey = 1;
      } else {
        this.followUpInfo.TypeKey = 2;
      }
      this.followUpInfo.AttractInvestKey = this.merchantsDataInfo.OLAPKey;
      this.followUpInfo.AttractInvestKeyValue = this.merchantsDataInfo.MainDemoName;
    },
    updateData () {
      console.log(this.investorInfo, 'updaateData');
      this.initGetData(this.investorInfo);
      this.$emit('updateData', this.investorInfo);// 通知外围左侧招商顾问列表更新数据
    }
  }
};
</script>

<style scoped>
.all_workbench_top {
  /* width: 1500px; */
  width: 945px;
  height: 121px;
  border-radius: 8px;
  display: flex;
}

.all_workbench_bottom {
  display: flex;
  margin-top: 20px;
}

.all_workbench_center {
  margin-right: 20px;
  /* width: 1110px; */
  width: 555px;
}
</style>