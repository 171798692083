<template>
  <div>
    <div class="course_statistics_table_search flex course_statistics_title" style="padding: 13px 0px;">

      <div class="course_statistics_search">

        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入校区名'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <!-- <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div> -->
      </div>
    </div>
    <div class="table_list_content">
      <tableList class="summarizing_list"
                 :key="tableKey"
                 :tableData="vdataReportList"
                 :totalNum="totalNum"
                 :PageCount="PageCount"
                 :tableColumns="vtableCourseColumns"
                 :isSearchText="false"
                 :isNeedInnerScroll="false"
                 :defaultSort="defaultSort"
                 :queryParams="searchObj"
                    :footerContent="vFooterTotal"
                 @loadTableData="getDataList"></tableList>
    </div>
    <custom-dialog :title="'校区课件详情'"
                   :width="'1366px'"
                   :visible.sync="isShowCoursewareDetails"
                   :before-close="closeCoursewareDetails">
      <courseware-details :ClientKey="rowData.ClientKey"
                          ref="coursewareDetails"
                          :rowData="rowData"
                          @renewClick="renewClick"
                          @close="closeCoursewareDetails"></courseware-details>
    </custom-dialog>
    <custom-dialog :title="'续端口'"
                   :visible.sync="isShowCampusRenewalForm"
                   :before-close="closeCampusRenewalFormPopup">
      <renewal-form :campusInfo="campusEditIndo"
                    @close="closeCampusRenewalFormPopup"
                    @afterSuccess="getSearchPageForCampus"></renewal-form>
    </custom-dialog>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
import coursewareDetails from '../../campus-courseware-management/report/courseware-details.vue';
import renewalForm from '../../headquarters-management/campus-management/form/new-renewal-form';
import inputSearchContainBtn from '../../../report/common/input-search-contain-btn';
import { MessageSearchPageDisuseCampus } from '../../../../API/workbench';
export default {
  data () {
    return {

      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
       
        IsExportKey: 0
      },
      rowData:{},
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          className: 'table_index_gray'
        },
        {
          label: '首次授权日期',
          prop: 'AuthorizeTime',
          width: ' ',
          minWidth: 120,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '校区名',
          prop: 'SchoolKeyValue',
          minWidth: 180,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '子课包',
          prop: 'CoursewarePackgeCount',
          width: ' ',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'

        },
        {
          label: '授权次数',
          prop: 'AuthorizeCount',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'number-item'
        },
        {
          label: '在用授权',
          prop: 'InUseAuthorizeCount',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'number-item',
          
        },
        {
          label: '失效预警',
          prop: 'ExpireWarningCount',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          
        },
        {
          label: '失效授权',
          prop: 'ExpireCount',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'number-item'

        },
        {
          label: '地址定位',
          prop: 'IsLocation',
          sortable: false,
          minWidth: 100,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '状态',
          prop: 'AuthorizeStatusContent',
          sortable: false,
          minWidth: 100,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          // fixed: 'right',
          align: 'center',
          headerAlign: 'center',
          width: 100,
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  click: (rowData) => {
                    console.log("详情",rowData)
                    this.openCoursewareDetails(rowData);
                    // this.showStudentFile(rowData);
                  },
                  ModulePowerKey: ''
                }
              }

            ]
          }
        }
      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false,
      isShowCoursewareDetails: false,
        isShowCampusRenewalForm: false,
           campusEditIndo: {},
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
     coursewareDetails,
    renewalForm,
  },
  props: {
    CoursewarePackageId:[Number,String],
    monitorA: {
      // 由A模块引用 用于监听是否在模块外面操作了表格数据 如果操作将监听调用当前表格刷新时间
      type: Number,
      default: 0
    },
  },
  created () {

  },
  watch:{
    monitorA: {
      handler (c, o) {
        console.log('监听到数据变化 自动刷新页面');
        this.getDataList();
      }
    }
   
   
  },
  computed: {
    vtableCourseColumns(){
      return this.tableCourseColumns
    },  
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
         o.IsLocation=o.IsLocation=='未定位'?`<span style="color:#F7B763">`+o.IsLocation+`</span>`:`<span style="color:#3DBEBE">`+o.IsLocation+`</span>`
          o.ExpireWarningCount=Number(o.ExpireWarningCount)==0?'-':`<span style="color:#F7B763">`+Number(o.ExpireWarningCount)+`</span>`
         o.CoursewarePackgeCount=o.AuthTypeKey==1?'全部':Number(o.CoursewarePackgeCount)
        if(o.AuthorizeStatusKey==0){
          o.AuthorizeStatusContent=`<div>授权失效</div>`
          if(new Date(o.CoursewareChannelExpiredTime)<new Date()||o.ChannelStatusKey==0){
            o.AuthorizeStatusContent+=`<div>端口过期</div>`
          }
        }else{
          if(new Date(o.CoursewareChannelExpiredTime)<new Date()||o.ChannelStatusKey==0){
            o.AuthorizeStatusContent=`<div>端口过期</div>`
          }
        }
        
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    }
  },
  mounted () {
    this.getDataList();
  },
  methods: {
    getSearchPageForCampus () {
      if (this.isShowCoursewareDetails) {
        this.$refs.coursewareDetails.getDataDetails();
      }
      this.getDataList();
    },
    // 关闭
    closeCampusRenewalFormPopup () {
      this.isShowCampusRenewalForm = false;
    },
    renewClick(rowData, type){
      console.log(rowData, '续费');
      if (type) {
        this.campusEditIndo = {
          OLAPKey: 0,  // 校区ID
          MainDemoName: '',
          IsOpenChannelKey: 0, // "是否开通课件 0-否;1-是",
          IsOpenAcademicKey: 1, // "是否开通教务  0-否;1-是",
          ExpireTime: ''   // 到期日
        };
        this.isShowCampusRenewalForm = true;
      } else {
        let NowTime = new Date();
        let PortExpiredTime = new Date(rowData.PortExpiredTime);
        // 续费教务时，如门店教务还在有效期内
        if ((rowData.portTypeKey == 1 && rowData.RenewOwnKey != 1) && (PortExpiredTime > NowTime && rowData.SysStatusKey != 1)) {
          layer.confirm('该门店教务功能自费，不可进行该操作，如有问题请与专属客服联系', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  layer.close();
                }
              }
            ]
          });
        } else {
          console.log('campusEditIndo');
          this.campusEditIndo = {
            OLAPKey: rowData.OLAPKey,  // 校区ID
            MainDemoName: rowData.MainDemoName,
            IsOpenChannelKey: rowData.portTypeKey == 2 ? 1 : 0, // "是否开通课件 0-否;1-是",
            IsOpenAcademicKey: rowData.portTypeKey == 1 ? 1 : 0, // "是否开通教务  0-否;1-是",
            ExpireTime: rowData.ExpiredTime   // 到期日
          };
          this.isShowCampusRenewalForm = true;
        }
      }
    },
    closeCoursewareDetails () {
      this.isShowCoursewareDetails = false;
    },
    openCoursewareDetails (rowData) {
      this.ids = rowData.ClientKey;
      this.rowData = rowData;
      this.isShowCoursewareDetails = true;
    },
    changeType (item, type,indexKey) {
      console.log('changeType', item, type);
      if(item[indexKey]=='-'){
        return false
      }
      this.$emit('changeType', item, type);
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.GradeKey = '';
      this.searchObj.CourseKey = '';
      this.searchObj.TeacherKey = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
   
    getDataList (queryParams) {
      let fn = MessageSearchPageDisuseCampus;
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      fn(this.searchObj,this.CoursewarePackageId).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
   

  }
};
</script>
<style>
.hover {
  cursor: pointer;
}
</style>