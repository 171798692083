<template>
  <div :id="echartID"
       :class="[vClass]"></div>
</template>
<script>
export default {
  name: 'lineTypeEchart',
  data () {
    return {
      chartItem: {
        resize: ''
      },
      echart: null
    };
  },
  props: {
    echartID: String,
    reqList: Array,
    nowDataList: Array,      // 当前
    preDataList: Array,    // 上一个.
    rotate: String,
    echartClass: String,
    echartType: String,
    maxType: String,
    tipTitle: {
      type: String,
      default: ''
    }
  },
  computed: {

    vClass () {
      if (this.echartClass) {
        return this.echartClass;
      } else {
        return 'echarts_m';
      }
    },
    maxSize () {
      let count = 0;
      for (var i = 0; i < this.nowDataList.length; i++) {
        count += Number(this.nowDataList[i]);
      }
      if (this.preDataList) {
        for (var j = 0; j < this.preDataList.length; j++) {
          count += Number(this.preDataList[j]);
        }
      }
      if (this.maxType == 'rate') {
        return 100;
      } else if (count == 0) {
        return 10;
      }
    }
  },
  mounted () {
    console.log(this.reqList, 'reqList');
    console.log(this.nowDataList, 'nowDataList');
    console.log(this.preDataList, 'preDataList');
  },
  methods: {
    echartInit () {
      if (this.echartType == 'line') {
        this.initEchartLine();
      } else {
        this.initEchartBar();
      }
    },
    // 柱形图初始.
    initEchartBar () {
      this.echart = echarts.init(document.getElementById(this.echartID));
      window.chartList.push(this.echart);
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'line' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: '30',
          left: '4%',
          right: '4%',
          bottom: '15%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.reqList,
          axisLabel: {
            show: true,
            textStyle: {
              color: '#c0c4cc'
            },
            rotate: 0,
            // 文字过长时隐藏
            formatter: function (value) {
              return (value.length > 8 ? (value.slice(0, 8) + '...') : value);
            }
          },
          axisLine: {
            lineStyle: {
              color: '#cccccc' // 更改坐标轴颜色
            }
          },
          z: 10
        },
        yAxis: {
          min: 0,
          max: this.maxSize,
          type: 'value',
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#999'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#cccccc' // 更改坐标轴颜色
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#f0f0f2']
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['#f7f7f7', '#fff']
            }
          }
        },
        series: [{ // For shadow
          type: 'bar',
          itemStyle: {
            barBorderWidth: 1,
            barBorderRadius: [4, 4, 4, 4],
            color: 'rgba(73,181,243,0.6)',
            barBorderColor: 'rgba(73,181,243,1)',
            abel: {
              show: true,
              textStyle: {
                color: '#27727B'
              }
            }
          },
          data: this.nowDataList
        }, {
          type: 'bar',
          itemStyle: {
            normal: {
              barBorderWidth: 1,
              barBorderRadius: [4, 4, 4, 4],
              color: 'rgba(255,108,103,0.6)',
              barBorderColor: 'rgba(255,108,103,1)',
              abel: {
                show: true,
                textStyle: {
                  color: '#E87C25'
                }
              }
            }
          },
          data: this.preDataList
        }]
      };

      // 使用刚指定的配置项和数据显示图表。
      this.echart.setOption(option);
    },
    // 线性图初始
    initEchartLine () {
      // 双曲线图表
      let _this = this;
      this.echart = echarts.init(document.getElementById(this.echartID));
      window.chartList.push(this.echart);
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'line' // 默认为直线，可选为：'line' | 'shadow'
          },
          backgroundColor: '#fff',
          padding: [5, 5, 5, 12],
          textStyle: {
            color: '#333'
          },
          extraCssText: 'box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06),-8px 0px 26px 2px rgba(0, 0, 0, 0.06),-6px 0px 16px 0px rgba(0, 0, 0, 0.06);',
          formatter: function (params, ticket, callback) {
            // console.log(params, ticket, '图例名称');
            var htmlStr = '';
            for (var i = 0; i < params.length; i++) {
              var param = params[i];
              var xName = param.name;// x轴的名称
              // var seriesName = param.seriesName;// 图例名称
              var value = param.value;// y轴值
              var color = param.color;// 图例颜色
              xName = _this.tipTitle + xName + '日';
              if (i === 0) {
                htmlStr += xName + '<br/>';// x轴的名称
              }
              htmlStr += '<div>';
              // 为了保证和原来的效果一样，这里自己实现了一个点的效果
              htmlStr += '<span style="margin-bottom:3px;margin-right:5px;display:inline-block;width:4px;height:4px;border-radius:2px;background-color:' + color + ';"></span>';
              if (i == 0) {
                htmlStr = htmlStr + '本月 ' + (value || 0) + ' 人次';
              } else {
                htmlStr = htmlStr + '上月 ' + (value || 0) + ' 人次';
              }
              htmlStr += '</div>';
            }
            return htmlStr;
          }
        },

        grid: {
          top: '10',
          left: '10',
          right: '10',
          bottom: '0%',
          containLabel: true
        },
        xAxis: [{
          boundaryGap: false,
          axisLabel: {
            textStyle: {
              color: '#c0c4cc',
              lineHeight: 40
            },
            // 文字过长时隐藏
            formatter: function (value) {
              return (value.length > 8 ? (value.slice(0, 8) + '...') : value);
            }
          },
          axisLine: {
            lineStyle: {
              color: '#cccccc' // 更改坐标轴颜色
            }
          },
          data: this.reqList
        }],
        yAxis: {
          min: 0,
          max: this.maxSize,
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#999'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#fff' // 更改坐标轴颜色
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#f0f0f2']
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['#fff', '#fff']
            }
          }
        },
        series: [{
          type: 'line',
          smooth: true,
          symbolSize: 1, // 结点大小，0表示不显示
          itemStyle: {
            color: '#0d62ff',
            lineStyle: { // 系列级个性化折线样式
              width: 2,
              type: 'dashed'
            }

          },
          // areaStyle: {
          //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          //     offset: 0,
          //     color: 'rgba(73,181,243,1)'
          //   }, {
          //     offset: 0.8,
          //     color: 'rgba(73,181,243,0.5)'
          //   }, {
          //     offset: 0.6,
          //     color: 'rgba(73,181,243,0.8)'
          //   }])
          // },

          data: this.nowDataList
        }, {
          type: 'line',
          smooth: true,
          symbolSize: 1, // 结点大小，0表示不显示
          itemStyle: {
            color: 'rgb(255,108,103)',
            lineStyle: { // 系列级个性化折线样式
              width: 2,
              type: 'dashed'
            }
          },
          // areaStyle: {
          //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          //     offset: 0,
          //     color: 'rgb(255,108,103)'
          //   }, {
          //     offset: 0.8,
          //     color: 'rgba(255,108,103,0.5)'
          //   }, {
          //     offset: 0.6,
          //     color: 'rgba(255,108,103,0.8)'
          //   }])
          // },
          data: this.preDataList
        }
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      this.echart.setOption(option);
    }

  }
};
</script>

