<template>
  <div>
    <div class="class_file_detail">
      <!-- 班级综合数据 -->
      <div class="class_file_data"
           @click.stop="isShowAlerDialog=false">
        <selectedClassIntro :classItem="classDetail"
                            @changeClass="changeClass"
                            @showCourseDetail="showCourseDetail"></selectedClassIntro>
        <div class="project_class single_shadow fl">
          <div class="project_class_list">
            <span class="font_gray">本月已上：</span>
            <span>{{statisticMsgByNow.Total_Course||0}}节课</span>
          </div>
          <div class="project_class_list">
            <span class="font_gray">出 勤 率 ：</span>
            <span>{{AttendanceRate}}</span>
          </div>
          <div class="project_class_list">
            <span class="font_gray">计划招生：</span>
            <span>{{WantPeopleCount}}人</span>
            <function-btn :item="editbtn"
                          @clickItem="chgPlanCount"
                          :className="'project_class_student_amend fr'">
            </function-btn>
          </div>
        </div>
        <div class="time_class single_shadow fl">
          <div class="time_class_top">
            <span class="font_gray">建班日期：</span>
            <span>{{StartTime}}</span>
          </div>
          <div class="time_class_bottom">
            <span class="font_gray">累计</span>
            <span class="time_class_bottom_cumulative">{{startTimeDistanceNow}}</span>
          </div>
        </div>
      </div>
      <!-- 班级详细数据记录 -->
      <div class="class_file_info_detail"
           @click.stop="isShowAlerDialog=false">
        <el-tabs v-model="studentsShow">
          <el-tab-pane label="班内学生"
                       name="students"
                       class="class_file_students">
            <div class="class_file_students_statistics  single_shadow">
              <div class="class_file_students_statistics_number">
                <span class="class_file_students_statistics_icon"></span>
                <span class="font_gray">班内学生 ：</span>
                <span>{{totalInClassStudents}}人</span>
              </div>
              <div class="class_file_students_statistics_hour">
                <span class="class_file_students_statistics_icon"></span>
                <span class="font_gray">无 课 单 ：</span>
                <span>{{noCouser}}人</span>
              </div>
            </div>
            <div class="class_file_info_detail_students_list">
              <div class="select_course_student_info">
                <inClassStudents v-for="studentItem in studentList"
                                 :key="studentItem.StuKey"
                                 :studentItem="studentItem"
                                 :isShowWKD="true"
                                 @setPosition="getPosition"></inClassStudents>
                <!-- 添加学生 -->
                <div class="add_student class_file_add_student"
                     @click.stop="getPosition">
                  <div class="add_student_left">
                    <img src="../../../../public/image/plus.png"
                         alt="">
                  </div>
                  <div class="add_student_right">
                    <span>添加学生</span>
                  </div>
                </div>
              </div>
              <div class="course_student_attendance_operation module_content_menuList"
                   :style="{'left':positionLeft+'px','top':positionTop+5+'px'}"
                   v-show="isShowAlerDialog">
                <div class="top_bg"></div>
                <drop-down-box :optionJsonList="operationList"
                               v-on:clickOptionForAlertDialog="diolgeOpration"></drop-down-box>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="考勤课消记录"
                       name="record"
                       class="class_file_record">
            <div class="record_list"
                 v-if="monthAttendanceList.length>0">
              <attandanceMonth v-for="(monthAttnItem,key) in monthAttendanceList"
                               :key="key"
                               :monthAttnItem="monthAttnItem"
                               ref="monthItem"
                               @getMonthAttenDetail="receiveDate"
                               @showCourseDetail="showCourseDetail"></attandanceMonth>
            </div>
            <div class="student_payment_nodata form_info_list "
                 v-else>
              <span>亲，当前没有任何数据哦~</span>
            </div>
          </el-tab-pane>
          <el-tab-pane label="排课计划"
                       name="course"
                       class="class_file_course">
            <div v-if="coursePlanList.length>0">
              <arrangeCourse v-for="(planItem,key) in coursePlanList"
                             :key="key"
                             :planItem="planItem"></arrangeCourse>
            </div>
            <div class="student_payment_nodata form_info_list "
                 v-else>
              <span>亲，当前没有任何数据哦~</span>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- <edit-course :vchangeCourseDialogVisible="isShowEditCourse"
                 :courseInfo="courseItem"
                 @editCourse="editCourse"
                 @showEditCourse="showEditCourse"
                 @chgClassStopKey="chgClassStopKey"
                 ref="editCourse"></edit-course> -->

  </div>
</template>
<script>

import selectedClassIntro from './selected-class-intro';
import inClassStudents from './tabs/in-class-students';
import attandanceMonth from './tabs/attandance-month';
import arrangeCourse from './tabs/arrange-course';
import dropDownBox from '../../common/drop-down-box';
import editCourse from '../../common/edit-course';
import { GetTheClassByID, GetClassStudentsByID, getClassStatisticMsgByDate, GetClassAttendanceList, GetClassAttendanceListByDate, getGoingCoursePlanByClass, QuitStudentFromClass } from '../../../API/workbench';
import { getDistanceNowTime } from '../../../utils/index';
export default {
  name: 'selectedClassFile',
  data () {
    return {
      studentsShow: 'students',      // 第一次进来绑定班级学生Tabs
      classDetail: {},              // 班级详细信息
      studentList: [],               // 班级所有学生
      courseItem: {},              // 选中的该节课程.
      Year: '',
      Month: '',
      statisticMsgByNow: {},     // 当前月份统计
      monthAttendanceList: [],    // 课考勤历史.
      coursePlanList: [],         // 排课计划
      positionLeft: '',       // 位置左边坐标
      positionTop: '',
      isShowAlerDialog: false,
      operationList: [],      // 传入弹出层的值.
      showBranthAddStudent: false,   // 批量加入学生
      seletedStudent: {},  // 选中学生
      // 点击学生时弹出层绑定的值
      studentOperation: [
        {
          type: 1,
          name: '查看',
          eventName: 'check',
          isShow: true
        },
        {
          type: 2,
          name: '换班',
          eventName: 'chgClass',
          isShow: true,
          noRole: false,
          ModulePowerKey: 51
        }, {
          type: 3,
          name: '退班',
          eventName: 'quitClass',
          isShow: true,
          noRole: false,
          ModulePowerKey: 51
        }
      ],
      // 加入学生弹出层绑定的值.
      addStudentOperation: [
        {
          type: 1,
          name: '添加学生',
          eventName: 'addStudent',
          isShow: true,
          ModulePowerKey: 51
        },
        {
          type: 1,
          name: '批量加学生',
          eventName: 'batchAddStudent',
          isShow: true,
          ModulePowerKey: 51
        }
      ],
      isShowEditCourse: false,  // 修改课程弹出
      editbtn: {
        name: '修改',
        type: 'xgkc',
        ModulePowerKey: 45
      }

    };
  },
  props: ['selectedClassItem'],
  components: {
    selectedClassIntro, inClassStudents, attandanceMonth, arrangeCourse, dropDownBox, editCourse
  },
  created () {
    this.Year = Date.new().getFullYear();
    this.Month = Date.new().getMonth() + 1;
    this.initGetAllData(this.selectedClassItem.OLAPKey, this.Year, this.Month);
    this.$bus.on(this, 'updateCourse', classItem => {
      if (classItem.OLAPKey == this.selectedClassItem.OLAPKey) {
        this.initGetAllData(classItem.OLAPKey, this.Year, this.Month);
      }
    });
  },
  watch: {
    selectedClassItem () {
      this.studentsShow = 'students';      // 更换班级后默认选中第一个.
      this.initGetAllData(this.selectedClassItem.OLAPKey, this.Year, this.Month);
    }
  },
  computed: {
    // 当月出勤率
    AttendanceRate () {
      return Number(this.statisticMsgByNow.AttendanceRate * 100).toFixed(2) + '%';
    },
    // 班内总人数
    totalInClassStudents () {
      if (this.studentList.length > 0) {
        return this.studentList.length;
      } else {
        return 0;
      }
    },
    // 无课单总人数
    noCouser () {
      if (this.studentList.length > 0) {
        let num = 0;
        this.studentList.forEach(o => {
          if (o.CustStatusKey != 1) {
            num++;
          }
        });
        return num;
      } else {
        return 0;
      }
    },
    // 计算招生数
    WantPeopleCount () {
      return Number(this.classDetail.WantPeopleCount);
    },
    // 班内学生
    CurrentPeoCount () {
      return Number(this.classDetail.CurrentPeoCount);
    },
    StartTime () {
      if (this.classDetail.StartTime) {
        return this.classDetail.StartTime.replace(/-/g, '.');
      }
    },
    startTimeDistanceNow () {           // //首次报名距离现在时间.
      if (this.classDetail.StartTime) {
        if (Date.new().getTime() - Date.new(this.classDetail.StartTime.replace(/-/g, '/')).getTime() > 0) {
          return getDistanceNowTime(this.classDetail.StartTime);
        } else {
          return '0天';
        }
      }
    }
  },
  methods: {
    // 初始化页面获取所有数据.
    initGetAllData (OLAPKey, Year, Month) {
      this.getClassDetailByID(OLAPKey);
      this.getClassStudentList(OLAPKey);
      this.getClassStatisticByDate(OLAPKey, Year, Month);
      this.getClassMonthAttendanceList(OLAPKey);
      this.getCoursePlanListByID(OLAPKey);
    },
    // 显示班级列表
    changeClass () {
      this.isShowAlerDialog = false;
      this.$emit('changeClass');
    },
    // 显示课程考勤详情
    showCourseDetail (val) {
      this.isShowAlerDialog = false;
      this.$emit('showCourseDetail', val);
    },
    // 班级ID获取班级详细信息.
    getClassDetailByID (OLAPKey) {
      GetTheClassByID(OLAPKey).then(result => {
        console.log(result.data, 'getClassDetailByID');
        this.classDetail = result.data;
        this.$emit('updateClass', this.classDetail);
        // this.$emit('chgCurrentPeoCount',this.classDetail.CurrentPeoCount)
      }).catch(error => {
        console.log('getClassDetailByID', error);
      });
    },
    // 获取班里所有学生.
    getClassStudentList (OLAPKey) {
      this.studentList.length = 0;
      GetClassStudentsByID(OLAPKey).then(result => {
        this.studentList = result.data;
      }).catch(error => {
        console.log('getClassStudentList', error);
      });
    },
    // 根据年月班级ID获取某个时间的统计.
    getClassStatisticByDate (OLAPKey, Year, Month) {
      getClassStatisticMsgByDate(OLAPKey, Year, Month).then(result => {
        console.log(result.data, 'getClassStatisticByDate');
        this.statisticMsgByNow = result.data;
      }).catch(error => {
        console.log('getClassStatisticByDate', error);
      });
    },
    // 根据ID获取所有课考勤统计历史.年月格式返回.
    getClassMonthAttendanceList (OLAPKey) {
      GetClassAttendanceList(OLAPKey).then(result => {
        this.monthAttendanceList = [];
        if (result.data.DataList && result.data.DataList.length > 0) {
          result.data.DataList.forEach(o => {
            o.attenList = [];
            o.dateTime = o.Year + '/' + o.Month;
            o.isActive = false;
            if (Date.new().getFullYear() == o.Year) {
              if (Date.new().getMonth() + 1 >= o.Month) {
                this.monthAttendanceList.push(o);
              }
            } else if (Date.new().getFullYear() > o.Year) {
              this.monthAttendanceList.push(o);
            }
          });
          this.monthAttendanceList.sort(function (x, y) {
            return Date.new(y.dateTime) - Date.new(x.dateTime);
          });
        }
      }).catch(error => {
        console.log('getClassMonthAttendanceList', error);
      });
    },
    // 根据班级ID,年月获取单月详细考勤.
    getAttenMonthDetailByDate (OLAPKey, Year, Month, callback) {
      GetClassAttendanceListByDate(OLAPKey, Year, Month).then(result => {
        if (result.data) {
          if (callback) {
            callback(result.data.DataList);     // 判断是否有数据. 有的话直接返回.
          }
        }
      }).catch(error => {
        console.log('getAttenMonthDetailByDate', error);
      });
    },
    // 考勤课消记录选中单项接收日期. 遍历monthAttendanceList. 符合条件则请求. 并保留.
    receiveDate (Year, Month, callback) {
      console.log(Year, Month);
      let locaIndex = this.monthAttendanceList.findIndex(p => {
        return p.Year == Year && p.Month == Month;
      });
      if (this.monthAttendanceList[locaIndex].attenList.length == 0) {
        this.getAttenMonthDetailByDate(this.selectedClassItem.OLAPKey, Year, Month, (data) => {
          console.log(data, 'data');
          this.monthAttendanceList[locaIndex].attenList = data || [];
          if (this.monthAttendanceList[locaIndex].attenList.length > 0) {
            this.monthAttendanceList[locaIndex].attenList.sort(function (x, y) {
              return Date.new(y.ClassTime.replace(/-/g, '/')) - Date.new(x.ClassTime.replace(/-/g, '/'));
            });
          }
          if (callback) {
            callback();
          }
        });
      } else {
        callback();
      }
    },
    // 班级ID获取排课计划
    getCoursePlanListByID (OLAPKey) {
      getGoingCoursePlanByClass(OLAPKey).then(result => {
        this.coursePlanList = result.data;
        console.log(this.coursePlanList, 'getCoursePlanListByID');
      }).catch(error => {
        console.log('getCoursePlanListByID', error);
      });
    },
    // 点击弹出层选项触发..
    diolgeOpration (optionItem) {
      this.isShowAlerDialog = false;    // 关闭显示层.
      switch (optionItem.eventName) {
        case 'check':
          // 显示学员档案
          this.$emit('showStudentFile', this.seletedStudent);
          break;
        case 'chgClass':
          this.$emit('showChgClass', this.selectedClassItem, this.seletedStudent);
          break;
        case 'quitClass':
          this.cancelStudentFromClass(this.seletedStudent, this.selectedClassItem);
          break;
        case 'addStudent':
          this.$emit('chgShowAddStudent', true);
          break;
        case 'batchAddStudent':
          this.$emit('showBranchAdd');
          break;
        default: break;
      }
    },
    // 设置弹出层位置及弹出层内容.
    getPosition (left, top, studentItem) {
      this.noRoleStudent();// 权限
      if (studentItem) {
        this.seletedStudent = studentItem;
      }
      document.onclick = function () {
        this.isShowAlerDialog = false;
      };
      if (left && top) {
        // 点击学生或者加入学生时弹出层显示.
        this.operationList = this.$utils.parseJson(this.studentOperation);
        this.isShowAlerDialog = true;
        this.positionLeft = left;
        this.positionTop = top;
      } else {
        // 点击学生或者加入学生时弹出层显示.
        this.operationList = this.$utils.parseJson(this.addStudentOperation);
        this.isShowAlerDialog = true;
        let Rect = document.querySelector('.class_file_add_student').getBoundingClientRect();
        let parentRect = document.querySelector('.class_file_students').getBoundingClientRect();
        this.positionLeft = (Rect.left - parentRect.left - 8);
        this.positionTop = (Rect.bottom - parentRect.top);
      }
    },
    // 学生换班权限
    noRoleStudent () {
      this.studentOperation.forEach(o => {
        if (o.type == 2 || o.type == 3) {
          o.noRole = true;
        }
      });
    },
    // 点击修改时选
    seletorCourseItem (val) {
      this.courseItem = val;
    },
    // 显示修改课程
    showEditCourse (booler, val) {
      // this.isShowEditCourse = booler;
      // if (val) {
      //   val.ScheduleCourseKey = val.ClassSetKey;
      //   this.courseItem = val;
      // }
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'editCourse',
        data: { courseInfo: this.courseItem }
      });
      console.log('showEditCourse', this.courseItem);
    },
    editCourse (booler, val, type) {
      this.showEditCourse(booler);
      // this.$emit("editCourse", val, type)
    },
    chgClassStopKey (val, date) {
      this.$emit('chgClassStopKey', val, date);
    },
    // 退出班级
    cancelStudentFromClass (studentItem, classInfo) {
      var that = this;
      layer.confirm('是否要退出班级', {
        btn: [{
          name: '取消',
          callBack: function () {
            layer.close();
          }
        }, {
          name: '确认',
          callBack: function () {
            QuitStudentFromClass(studentItem.StuKey, classInfo.OLAPKey).then(result => {
              if (result.code == 0) {
                that.initGetAllData(that.selectedClassItem.OLAPKey, that.Year, that.Month);
                layer.close();
                layer.alert('退出成功');
              } else {
                layer.alert(result.msg);
              }
            }).catch(error => {
              console.log(error);
            });
          }
        }]
      });
    },
    // 修改招生数
    chgPlanCount () {
      this.$emit('chgPlanCount', this.classDetail);
    }
  }

};
</script>

