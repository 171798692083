<template>
 <div class="form_color_list" :class="{opt:item.isSelected}" @click.stop="clickEvent">
    <div class="color_box" :class="item.className"></div>
    <span>{{item.colorName}}</span>
  </div>
</template>

<script>
export default {
  props: {
    item: Object
  },
  methods: {
    clickEvent () {
      this.$emit('clickEvent', this.item);
    }
  }
};
</script>
<style>
</style>