<template>
  <div>
    <module-description :textTips="'试课方式安排上课的学生签到不扣课时。'"
                        :btnTips="'查看教程'"
                        :btnUrl="'https://mp.weixin.qq.com/s/pSArsX3yRBR_h09cvg7tnA'"></module-description>
    <!-- 安排试课 列表 -->
    <table-list ref="arrangeTryCourse"
                :extendSearchCondition="extendSearchCondition"
                :functionBtnGroup="functionBtnGroup"
                :tableData="tryCourseArrangeList"
                :totalNum="totalNum"
                :PageCount="PageCount"
                :tableColumns="tableColumns"
                :detailColumns="detailColumns"
                :rowKey="rowKey"
                :top="30"
                :isSearchText="isSearchText"
                :isShowExportBtn="true"
                @actionBtnClick="doAfterFunctionBtnClick"
                @tableBtnClick="doAfterTableBtnClick"
                @loadTableData="getTryCourseList"
                @doExportInfoClick="doAfterExportInfoClick"
                @closeTableList="closeTableList"></table-list>

    <!-- 新增试课申请-->
    <div>
      <custom-dialog title="新增试课"
                     :visible.sync="addTryCourseDialogVisible"
                     :before-close="closeAddTryCourseDialog">
        <div>
          <add-try-course :selectionCourseNameData="selectionCourseNameData"
                          @showCourseNameList="showCourseNameList"
                          @closeAddTryCourseDialog="closeAddTryCourseDialog"
                          @confirmSubmitEvent="confirmAddTryCourse"></add-try-course>
        </div>
      </custom-dialog>
    </div>
    <!-- 安排课 -->
    <div>
      <custom-dialog title="安排"
                     :visible.sync="arrangeScheduleCourseDialogVisible"
                     :before-close="closeArrangeScheduleCourseDialog">
        <div>
          <arrang-schedule-course :studentKey="studentKey"
                                  :searchScheduleCourseCondition="searchScheduleCourseCondition"
                                  @cancleBtn="closeArrangeScheduleCourseDialog"
                                  @confirmBtn="confirmArrangeScheduleCourseBtn"></arrang-schedule-course>
        </div>
      </custom-dialog>
    </div>

    <!-- 选择课程-->
    <div>
      <custom-dialog title="选择课程"
                     class="refund_dialog defult_dialog"
                     :visible.sync="isShowCourseNameList"
                     :before-close="closeDialogForcourseTypeName">
        <div>
          <single-selection-list isShowSearchInput="false"
                                 :dataSourceList="courseTypeNameList"
                                 :selectedKey="selectionCourseNameData.id"
                                 :required="true"
                                 @onSelectedItem="confirmChooseCourseTypeName"></single-selection-list>
        </div>
      </custom-dialog>
    </div>
  </div>
</template>
<script>
import tableList from '../../table-list/index';
import {
  getCourseTypeNameList,
  CancelTrialClass,
  getTryCourseList
} from '../../../API/workbench.js';
import studentList from '../../common/student-list';
import arrangScheduleCourse from './arrang-schedule-course';
import addTryCourse from './add-try-course';
const initSearchData = {
  PageIndex: 1, // 页码
  PageSize: 10, // 每页记录数
  StutasKey: 0, // 2-申请中;3-已通过;4-已安排;5-已取消;6-已拒绝;
  SearchText: '', // 搜索条件
  CourseNameKey: 0, // 课名ID
  TableId: 0,
  StartTime: '', // 申请日期开始
  EndTime: '', // 申请日期结束
  OrderBy: '', // 排序字段
  Sequence: 'desc' // 排序方式
};
export default {
  name: 'arrangeTryCourse',
  data () {
    return {
      studentKey: '', // 学生ID
      selectionCourseNameData: {},
      studentArrangeVisible: false,
      tryCourseArrangeList: [], // 试课追销列表
      courseTypeNameList: [], // 课名列表
      searchScheduleCourseCondition: {
        // 查询课的条件
        PageIndex: 0, // 页码，0为第一页
        PageSize: 0,
        OrderBy: 'ClassTime', // 排序字段
        Sequence: 'asc', // 排序字段
        SearchText: '', // ,
        StartTime: '', // 上课日期开始
        EndTime: '', // 上课日期结束
        CourseNameKey: 0, // 课名ID
        TableId: 0,
        IsClassStopKey: 0, // 是否停课0-否; 1-是; 空-全部;
        StutasKey: 0 // 课状态:0/空-全部; 2-未开始;3-进行中;4-已完结;
      },
      addTryCourseDialogVisible: false, // 新增试课显示与否
      arrangeScheduleCourseDialogVisible: false, // 安排课显示与否
      // 列表参数
      functionBtnGroup: [
        {
          name: '新增',
          eventName: this.$initJson.baseFunctionBtnName.add,
          icon: 'add_icon',
          roleName: '',
          ModulePowerKey: 64
        }
        // {
        //   name: '导出',
        //   eventName: this.$initJson.baseFunctionBtnName.read,
        //   icon: '',
        //   roleName: '',
        //   ModulePowerKey: 64
        // }
      ], // 功能按钮组
      extendSearchCondition: [
        // 扩展查询条件
        {
          type: this.$initJson.extendSearchConditionType.dateRange, // 日期范围
          formTitle: '申请日期',
          fieldList: [
            {
              name: 'StartTime', // 参数名
              defaultValue: '', // 默认值
              value: '', // 绑定值
              validateHandle: '', // 自定义验证函数
              sourceData: [] // 数据源
            },
            {
              name: 'EndTime',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: []
            }
          ]
        },
        {
          type: this.$initJson.extendSearchConditionType.singleTree,
          formTitle: '课程',
          fieldList: [
            {
              name: 'CourseNameKey',
              defaultValue: 0,
              value: 0,
              validateHandle: '',
              sourceData: []
            }
          ]
        },
        {
          type: this.$initJson.extendSearchConditionType.selectOption,
          formTitle: '预约状态',
          fieldList: [
            {
              name: 'StutasKey',
              defaultValue: 0,
              value: 0,
              validateHandle: '',
              sourceData: [
                { key: 0, value: '不限' },
                { key: 2, value: '申请中' },
                { key: 4, value: '已安排' },
                { key: 5, value: '已取消' }
              ]
            }
          ]
        }
      ],
      tableColumns: [
        // table 列
        {
          label: 'ID',
          prop: 'ID',
          width: 10,
          sortable: false,
          align: 'left',
          isShow: false,
          type: '',
          decimalPlace: 0
        },
        {
          label: '申请日期',
          prop: 'ApplicantDateTime',
          width: 180,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'date',
          decimalPlace: 0
        },
        {
          label: '预约学生',
          prop: 'StudentKeyValue',
          width: 180,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '预约课程',
          prop: 'OrderClassKeyValue',
          width: 180,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '安排上课时间',
          prop: 'ClassTime',
          width: 200,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'date',
          decimalPlace: 0
        },
        {
          label: '预约状态Key',
          prop: 'StutasKey',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: false,
          type: '',
          decimalPlace: 0
        },
        {
          label: '预约状态',
          prop: 'StutasKeyValue',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '操作',
          prop: '',
          sortable: false,
          // width: 280,
          align: 'center',
          tableBtnGroup: [
            {
              name: '详情',
              eventName: this.$initJson.baseFunctionBtnName.detail,
              Stutas: 'StutasKey',
              className: () => {
                return 'btn_light_white';
              },
              roleName: ''
            },
            {
              name: '安排',
              eventName: this.$initJson.baseFunctionBtnName.arrange,
              Stutas: 'StutasKey',
              className: value => {
                if (value == 2) {
                  return 'btn_light_blue btn_hover_bg_blue';
                } else {
                  return 'btn_light_gray';
                }
              },
              roleName: '',
              ModulePowerKey: 51
            },
            {
              name: '取消',
              eventName: this.$initJson.baseFunctionBtnName.finish,
              Stutas: 'StutasKey',
              className: value => {
                if (value == 2) {
                  return 'btn_light_red btn_hover_white';
                } else {
                  return 'btn_light_gray';
                }
              },
              roleName: ''
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        }
      ],
      detailColumns: [
        { label: '申请日期', prop: 'ApplicantDateTime' },
        { label: '预约学生', prop: 'StudentKeyValue' },
        { label: '预约课程', prop: 'OrderClassKeyValue' },
        { label: '安排人', prop: 'PlanPersonKeyValue' },
        { label: '安排时间', prop: 'PlanDateTime' },
        { label: '客户编号', prop: 'CustNumName' },
        { label: '课编号', prop: 'SourceKey' },
        { label: '预约状态', prop: 'StutasKeyValue' },
        { label: '上课日期', prop: 'ClassTime' },
        { label: '关系', prop: 'CustomerRelationshipKeyValue' },
        { label: '预约来源', prop: 'OrderRootInKeyValue' },
        { label: '预约要求', prop: 'OrderAskForSupport', isShowTitle: true },
        { label: '上课时间', prop: 'ClassTimeName' },
        { label: '手机', prop: 'CustomerPhoneName' },
        { label: '主讲老师', prop: 'MTeacherKeyValue' }
      ],
      rowKey: 'ID',
      PageCount: 0,
      totalNum: 0,
      isSearchText: 0,
      isShowCourseNameList: false
    };
  },
  props: {
    tableID: {
      type: String,
      default: ''
    }
  },
  components: {
    tableList,
    arrangScheduleCourse,
    addTryCourse,
    studentList
  },
  created () {
    this.getCourseTypeNameList();
  },
  mounted () {
    console.log('this.tableID', this.tableID);
    if (this.tableID) {
      this.isSearchText = -1;
      this.$refs.arrangeTryCourse.queryParams.StutasKey = 0;
      this.$refs.arrangeTryCourse.queryParams.TableId = this.tableID;
    }
    this.$refs.arrangeTryCourse.selectedParams = [];
    this.getTryCourseList(this.$refs.arrangeTryCourse.queryParams);
  },
  methods: {
    // 获取试课列表 140998
    getTryCourseList (queryParams) {
      queryParams.PageIndex = queryParams.pageIndex;
      queryParams.PageSize = queryParams.pageSize;
      queryParams.SearchText = queryParams.searchText;
      queryParams.OrderBy = queryParams.orderBy || 'ApplicantDateTime';
      queryParams.Sequence = queryParams.sequence || 'desc';
      this.searchScheduleCourseCondition.SearchText = queryParams.SearchText;
      this.searchScheduleCourseCondition.OrderBy = queryParams.OrderBy;
      this.searchScheduleCourseCondition.Sequence = queryParams.Sequence;
      this.searchScheduleCourseCondition.TableId = queryParams.TableId;
      this.$refs.arrangeTryCourse.isLoading = true;
      getTryCourseList(queryParams).then(
        result => {
          this.tryCourseArrangeList = [];
          if (result.data.PageDataList.length > 0) {
            result.data.PageDataList.forEach((obj, index) => {
              this.tryCourseArrangeList.push(obj);
            });
            this.totalNum = result.data.Total;
            this.PageCount = result.data.PageCount;
            this.currentPage = queryParams.PageIndex;
          } else {
            this.totalNum = 0;
          }
          this.$refs.arrangeTryCourse.isLoading = false;
        },
        error => {
          layer.alert(error.data.msg);
          this.$refs.arrangeTryCourse.isLoading = false;
        }
      );
    },
    // 新增试课申请
    addTryCourseApplication () {
      this.addTryCourseDialogVisible = true;
    },
    // 取消试课申请
    cancleTryCourseApplication (rowData) {
      if (rowData.StutasKey != 2) {
        return false;
      }
      let that = this;
      layer.confirm('是否取消当前这条试课申请', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: function () {
              CancelTrialClass(rowData.ID).then(
                result => {
                  layer.alert('取消成功');
                  that.$emit('afterSuccess');       // 传递到父组件刷新.
                  that.getTryCourseList(
                    that.$refs.arrangeTryCourse.queryParams
                  );
                },
                err => {
                  layer.alert(err.msg);
                }
              );
              layer.close();
            }
          }
        ]
      });
    },
    // 关闭试课窗口
    closeArrangeTryCourseDialog () {
      this.queryParamsForList = this.$utils.parseJson(initSearchData);
      this.studentArrangeVisible = false;
    },
    // 关闭新增窗口
    closeAddTryCourseDialog () {
      this.selectionCourseNameData = {};
      this.addTryCourseDialogVisible = false;
    },
    // 确定 新增窗口 刷新当前数据
    confirmAddTryCourse () {
      this.closeAddTryCourseDialog();
      this.$emit('afterSuccess');       // 传递到父组件刷新.
      this.getTryCourseList(this.$refs.arrangeTryCourse.queryParams);
    },
    // 安排课
    arrangeCourse (rowData) {
      if (rowData.StutasKey == 2) {
        this.searchScheduleCourseCondition.CourseNameKey = rowData.OrderClassKey;
        this.searchScheduleCourseCondition.CourseNameKeyValue = rowData.OrderClassKeyValue;
        this.searchScheduleCourseCondition.ID = rowData.ID;
        if (this.searchScheduleCourseCondition.OrderBy == 'ApplicantDateTime') {
          this.searchScheduleCourseCondition.OrderBy = 'ClassTime';
        }
        this.arrangeScheduleCourseDialogVisible = true;
      } else {
        return false;
      }
    },
    // 确认 安排成功 刷新当前这条数据状态
    confirmArrangeScheduleCourseBtn () {
      this.$emit('afterSuccess');       // 传递到父组件刷新.
      this.getTryCourseList(this.$refs.arrangeTryCourse.queryParams);
      this.closeArrangeScheduleCourseDialog();
    },
    // 关闭安排接口
    closeArrangeScheduleCourseDialog () {
      this.arrangeScheduleCourseDialogVisible = false;
    },
    // table 功能按钮点击
    doAfterFunctionBtnClick (eventName) {
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.add:
          this.addTryCourseApplication();
          break;
        default:
          break;
      }
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName) {
      console.log(rowData, eventName, 'rowData, eventName');
      this.studentKey = rowData.StudentKey;
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.arrange:
          this.arrangeCourse(rowData);
          break;
        case this.$initJson.baseFunctionBtnName.finish:
          this.cancleTryCourseApplication(rowData);
          break;
        default:
          break;
      }
    },
    // 获取课类课名
    getCourseTypeNameList () {
      getCourseTypeNameList().then(
        result => {
          result.data.forEach(obj => {
            // StateKey:3为停用 TypeKey:0-全部；1-课类；2-课名
            if (obj.StateKey == 2 && obj.TypeKey == 2) {
              this.courseTypeNameList.push(obj);
            }
          });
          this.extendSearchCondition[1].fieldList[0].sourceData = this.courseTypeNameList;
        },
        err => {
          console.info(err);
          layer.alert(err.msg);
        }
      );
    },
    // 显示课名列表
    showCourseNameList () {
      this.isShowCourseNameList = true;
    },
    // 确定课名选择
    confirmChooseCourseTypeName (selectionData) {
      let dataInfo = {
        id: selectionData.OLAPKey,
        label: selectionData.MainDemoName,
        parentId: selectionData.FatherKey

      };
      this.closeDialogForcourseTypeName();
      this.selectionCourseNameData = dataInfo;
    },
    // 取消课名选择
    closeDialogForcourseTypeName () {
      this.isShowCourseNameList = false;
    },
    closeTableList () {
      this.studentArrangeVisible = false;
    },
    doAfterExportInfoClick () {
      if (this.tryCourseArrangeList.length > 0) {
        this.searchScheduleCourseCondition.IsExportKey = 1;
        this.searchScheduleCourseCondition.StartTime = '';
        this.searchScheduleCourseCondition.EndTime = '';
        this.searchScheduleCourseCondition.pageIndex = 0;
        this.searchScheduleCourseCondition.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '安排试课',
          clist: [
            { title: '申请日期', cName: 'ApplicantDateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '预约学生', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '预约课程', cName: 'OrderClassKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '安排上课时间', cName: 'ClassTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '预约状态', cName: 'StutasKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(this.searchScheduleCourseCondition, 'jsonFormate');
        getTryCourseList(this.searchScheduleCourseCondition, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.searchScheduleCourseCondition.IsExportKey = 1;
          this.searchScheduleCourseCondition.pageIndex = 0;
          this.searchScheduleCourseCondition.pageSize = 0;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
    }
  }
};
</script>
