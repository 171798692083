<template>
  <div>
    <div v-if="isShowPPT"
         class="courseware_add_img_box">
      <div class="add_img">
        <img class="img_box"
             :src="vUrl"
             alt="" />

      </div>
      <div class="left_btn"
           :class="{btn_no_type:currentNum == 1}"
           @click="backClick"></div>
      <div class="right_btn"
           :class="{btn_no_type:currentNum >= dataInfo.DurationNum}"
           @click="nextClick"></div>
    </div>
    <div v-if="isShowPPT"
         class="total_info">{{currentNum}}/{{dataInfo.DurationNum}}</div>
    <div v-if="!isShowPPT"
         class="courseware_add_img_box">
      <div class="add_img">
        <img class="img_box" />
      </div>
      <div class="add_progress_banner">
        <div class="add_progress"></div>
      </div>
      <div class="add_progress_text">未转码完成</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      currentNum: 1,
      isShowPPT: true
    };
  },
  props: {
    dataInfo: Object
  },
  computed: {
    vUrl () {
      console.log("courseware_add_img_box", this.dataInfo.previewList)
      if (this.dataInfo.previewList) {
        return this.dataInfo.previewList[this.currentNum]
      } else {

        return this.dataInfo.AttachViewUrl + '/' + this.currentNum + '?sign=' + this.$store.getters.signData.Sign;
      }
    }
  },
  watch: {
    'dataInfo.AttachViewUrl' (n, o) {
      if (o && n != o) {
        this.currentNum = 1;
      }
    }
  },
  created () {
    console.log(this.dataInfo);
    if (this.dataInfo.DurationNum == 0) {
      this.isShowPPT = false;
    }
  },
  methods: {
    backClick () {
      if (this.currentNum > 1) {
        this.currentNum = this.currentNum - 1;
      }
    },
    nextClick () {
      if (this.currentNum < this.dataInfo.DurationNum) {
        this.currentNum = this.currentNum + 1;
      }
    }
  }
};
</script>

<style scoped>
.courseware_add_img_box .btn_no_type {
  opacity: 0.5;
  cursor: default;
}

.courseware_add_img_box .left_btn.btn_no_type:hover {
  background: url(../../../../../../public/image/left_btn.png);
}

.courseware_add_img_box .right_btn.btn_no_type:hover {
  background: url(../../../../../../public/image/right_btn.png);
}
</style>