<template>
  <div class="recommend_manage_view table_list_content all_width box_withe_radius">
    <table-type-ul :dataInfo="dataInfo"
                   :tableType="tableType"
                   @swtichTableType="swtichTableType"></table-type-ul>
    <search-box :params="params"
                :tableType="tableType"
                @searchClick="searchClick"
                @clearSearchParams="clearSearchParams"></search-box>
    <div>
      <not-receive-table v-if="tableType == 1"
                         :params="params"
                         @upDataInfo="upDataInfo"
                         @refreshListInfo="searchClick"></not-receive-table>
      <follow-up-table v-if="tableType == 2"
                       :params="params"
                       @upDataInfo="upDataInfo"
                       @openRecommendDetails="openRecommendDetails">
      </follow-up-table>

      <refuse-table v-if="tableType == 3"
                    :params="params"
                    @upDataInfo="upDataInfo"
                    @openRecommendDetails="openRecommendDetails">
      </refuse-table>

      <receive-overtime-table v-if="tableType == 4"
                              :params="params"
                              @upDataInfo="upDataInfo">
      </receive-overtime-table>
      
      <sign-bill-table v-if="tableType == 5"
                       :params="params"
                       @upDataInfo="upDataInfo"
                       @openRecommendDetails="openRecommendDetails">
      </sign-bill-table>
    </div>
    <dialog-factory-common ref="recommendManageFactoryCommon"></dialog-factory-common>
  </div>
</template>
<script>
import tableTypeUl from './table-type-ul';
import searchBox from './search-box';
import notReceiveTable from './table/not-receive-table';
import followUpTable from './table/follow-up-table';
import refuseTable from './table/refuse-table';
import receiveOvertimeTable from './table/receive-overtime-table';
import signBillTable from './table/sign-bill-table';
export default {
  name: 'recommendManage',
  data () {
    return {
      tableType: 1,
      dataInfo: {
        ToBeReceiveCount: '0',
        FollowUpCount: '0',
        RefuseCount: '0',
        ReceiveOvertimeCount: '0',
        SignCount: '0'
      },
      params: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        StartTime: '',
        EndTime: '',
        StatusKey: '',
        PaymentScheduleKey: ''
      }
    };
  },
  components: {
    tableTypeUl,
    searchBox,
    notReceiveTable,
    followUpTable,
    refuseTable,
    receiveOvertimeTable,
    signBillTable
  },
  created () {
    this.resgisterBusEvent();
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList', 'crmImproveClewForm']);
  },
  methods: {
    openRecommendDetails (data) {
      this.$dialog.open(this, {
        name: '投资人详情',
        routerName: this.$route.name,
        moduleName: 'crmImproveClewForm',
        ModulePowerKey: '',
        data: { dataInfo: data, isReadonly: true,isHideEditBtn:true}
      });
    },
    upDataInfo (data) {
      this.dataInfo = data;
    },
    searchClick () {
      const type = this.tableType;
      this.tableType = 0;
      this.params.pageIndex = 0;
      this.$nextTick(() => {
        this.tableType = type;
      });
    },
    clearSearchParams () {
      this.params = {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        StartTime: 0,
        EndTime: 0,
        StatusKey: '',
        PaymentScheduleKey: ''
      };
      this.searchClick();
    },
    swtichTableType (type) {
      this.tableType = 0;
      this.clearSearchParams();
      this.$nextTick(() => {
        this.tableType = type;
      });
    },
    resgisterBusEvent () {
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'recommendManage') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.recommendManageFactoryCommon) {
        this.$refs.recommendManageFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>
<style scoped>
.recommend_manage_view {
  padding: 20px 20px 10px;
}

.all_width {
  width: 1290px;
  margin: 20px auto 40px;
}

.small_width {
  width: 1290px;
  margin: 20px auto 40px;
}

.box_withe_radius {
  background-color: #fff;
  border-radius: 8px;
}
</style>