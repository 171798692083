<template>

  <div class="import_box">
    <student-name :rowType="'add'"
                  :isShowPart="isShowPart"
                  :rowData="rowData"
                  @onBlurEvent="afterBlurEvent"
                  @onFocusEvent="afterFocusEvent"></student-name>

    <!-- :scrollTopVal="scrollTopVal" -->
    <sex-cell :rowType="'add'"
              :rowData="rowData"
              :tableData="tableData"
              @onBlurEvent="afterBlurEvent"
              @onFocusEvent="afterFocusEvent"></sex-cell>

    <customer-phone-name :rowType="'add'"
                         :rowData="rowData"
                         :tableData="tableData"
                         @onBlurEvent="afterBlurEvent"
                         @onFocusEvent="afterFocusEvent"></customer-phone-name>
    <!-- :scrollTopVal="scrollTopVal" -->
    <course-name :rowType="'add'"
                 :rowData="rowData"
                 :courseNameList="courseNameList"
                 :tableData="tableData"
                 @onBlurEvent="afterBlurEvent"
                 @onFocusEvent="afterFocusEvent"></course-name>

    <buy-date :rowType="'add'"
              class="import_list"
              :rowData="rowData"
              :isShowPart="isShowPart"
              @onBlurEvent="afterBlurEvent"
              @onFocusEvent="afterFocusEvent"></buy-date>

    <expire-time :rowType="'add'"
                 class="import_list"
                 :isShowPart="isShowPart"
                 :rowData="rowData"
                 @onBlurEvent="afterBlurEvent"
                 @onFocusEvent="afterFocusEvent"></expire-time>

    <buy-amount :rowType="'add'"
                v-if="!isShowPart"
                :rowData="rowData"
                @onBlurEvent="afterBlurEvent"
                @onFocusEvent="afterFocusEvent"></buy-amount>

    <buy-classHour :rowType="'add'"
                   v-if="!isShowPart"
                   :rowData="rowData"
                   @onBlurEvent="afterBlurEvent"
                   @onFocusEvent="afterFocusEvent"></buy-classHour>

    <current-count :rowType="'add'"
                   :rowData="rowData"
                   :tableData="tableData"
                   :isShowPart="isShowPart"
                   @onBlurEvent="afterBlurEvent"
                   @onFocusEvent="afterFocusEvent"></current-count>

    <warning-list :rowType="'add'"
                  class="import_waring"
                  :rowData="rowData"></warning-list>

    <div class="import_btn"
         @mouseout.stop="mouseOutNewCourseTable">
      <div class="import_btn_save btn_hover_bg_blue"
           :class="{type_blue:importBtnStyle.optSave}"
           @click="onImportBtnSave"
           @mouseover.stop="mouseOverbtnSave">保存</div>
      <div class="import_btn_delete"
           :class="{type_blue:importBtnStyle.optDelete}"
           @click="onImportBtnDelete"
           @mouseover.stop="mouseOverbtnDelete"></div>
    </div>

  </div>
</template>

<script>
import studentName from './row-cell/student-name';
import sexCell from './row-cell/sex-cell';
import customerPhoneName from './row-cell/customer-phone-name';
import courseName from './row-cell/course-name';
import buyDate from './row-cell/buy-date';
import expireTime from './row-cell/expire-time';
import buyAmount from './row-cell/buy-amount';
import buyClassHour from './row-cell/buy-classHour';
import currentCount from './row-cell/current-count';
import warningList from './row-cell/warning-list';
export default {
  data () {
    return {
      importBtnStyle: {
        optSave: false,
        optDelete: false
      },
      rowData: {}
    };
  },
  props: {
    isShowPart: Boolean,
    courseNameList: Array,
    tableData: Array,
    scrollTopVal: Number,
    SpecialFilter: Number
  },
  components: {
    studentName,
    sexCell,
    customerPhoneName,
    courseName,
    buyDate,
    expireTime,
    buyAmount,
    buyClassHour,
    currentCount,
    warningList
  },
  watch: {
    'rowData.focusField': {
      handler (newVal, oldVal) {
        if (!newVal && oldVal) {
          this.rowData.isAdd = true;
        }
      }
    }
  },
  created () {
    this.initData();
  },
  methods: {
    // 验证是否正在新增
    valifyIsAdding () {
      let flag = false;
      if (this.rowData.StudentKeyValue || this.rowData.CustomerPhoneName || this.rowData.ApplyClassKeyValue ||
        this.rowData.PayTime || this.rowData.ExpireTime || this.rowData.ActualPayAmount ||
        this.rowData.NumCount || this.rowData.CurrentCount ||
        this.rowData.ExceptionList.length > 0 || this.rowData.WarningList.length > 0) {
        flag = true;
      }
      return flag;
    },
    // 初始化
    initData () {
      this.rowData = {
        guid: '', //
        StudentKey: 0, // 学生ID
        StudentKeyValue: '',
        SexKey: 3,
        SexKeyValue: '女',
        CustomerPhoneName: '',
        RegisterTypeKey: '',
        RegisterTypeKeyValue: '', // 4-系统导入;5-补录老生;
        ApplyClassKey: 0, // 课程ID
        ApplyClassKeyValue: '', // 课程名
        UnitKey: '', // 课单单位
        UnitKeyValue: '',
        ActualPayAmount: '', // 购买金额
        NumCount: '', // 购买课时
        CurrentCount: '', // 剩余课时
        PayTime: '', // 购买时间
        ExpireTime: '', // 过期时间
        ExceptionList: [],
        WarningList: [],
        Number: 1,
        randomNumber: '',
        focusField: '',
        optioningRow: false,
        saveTime: '',
        updateTime: '',
        isValifiStudent: false, // 是否正在验证学生名
        isAdd: false
      };
    },
    // 提交当前新增行
    onImportBtnSave () {
      if (this.rowData.isValifiStudent) {
        layer.alert('操作太快，请缓一缓。');
        return;
      }
      setTimeout(() => {
        this.rowData.ExceptionList.sort((x, y) => { return x.TargetField < y.TargetField; });
        if (this.rowData.ApplyClassKey && (this.rowData.CurrentCount === '' || this.rowData.CurrentCount === null)) {
          let errorCurrentCountIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 9 && obj.TypeKey == 5; });
          if (errorCurrentCountIndex < 0 && !this.rowData.focusField) {
            this.rowData.ExceptionList.push({ TargetField: 9, TypeKey: 5, TypeKeyValue: '剩余课时未填写' });
          }
        }
        if (!this.rowData.StudentKeyValue) {
          let errorEmptyIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 1 && obj.TypeKey == 1; });
          if (errorEmptyIndex < 0) {
            this.rowData.ExceptionList.push({ TargetField: 1, TypeKey: 1, TypeKeyValue: '未填写学生名' });
          }
        }
        if (this.rowData.ExceptionList.length > 0) {
          layer.alert(this.rowData.ExceptionList[0].TypeKeyValue);
        } else {
          this.rowData.isAdd = false;
          this.rowData.guid = this.$utils.createGUID();
          this.$emit('onImportBtnSave', this.rowData);
          this.initData();
        }
      }, 150);
    },
    // 删除当前新增行
    onImportBtnDelete () {
      this.initData();
    },
    // 聚焦事件
    afterFocusEvent (focusField) {
      this.$emit('onFocusEvent', this.rowData, focusField, 'add');
    },
    // 失焦事件
    afterBlurEvent () {
      // console.log(this.rowData, '失焦事件');
    },
    // 鼠标移入
    mouseOverbtnSave (item) {
      this.importBtnStyle.optSave = true;
      this.importBtnStyle.optDelete = false;
    },
    // 鼠标移入
    mouseOverbtnDelete (item) {
      this.importBtnStyle.optDelete = true;
      this.importBtnStyle.optSave = false;
    },
    // 鼠标离开
    mouseOutNewCourseTable () {
      this.importBtnStyle.optSave = false;
      this.importBtnStyle.optDelete = false;
    }
  }
};
</script>

<style>
</style>