<template>
  <!-- 学员ID查询欠课时的课按课名排列. -->
  <div class="rush_hour_record_tbody_line">
    <div class="rush_hour_record_courseData tbody_value">
      <span class="">{{setUpTime}}</span>
    </div>
    <div class="rush_hour_record_courseName tbody_value">
      <span class="">{{item.CourseNameKeyValue}}</span>
    </div>
    <div class="rush_hour_record_courseTime tbody_value">
      <span class="">{{classCount}}课时</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'studentOweCourseItem',
  props: ['item'],
  data () {
    return {

    };
  },
  computed: {
    classCount () {
      return Number(this.item.ClassCount).toFixed(1);
    },
    setUpTime () {
      console.log(this.item.ClassTime);
      if (this.item.ClassTime) {
        return this.$utils.formatDateStr(this.item.ClassTime, 'yyyy-MM-dd');
      } else {
        return '';
      }
    }
  },
  methods: {

  }
};
</script>


