<template>
  <!-- 上传 -->
  <div>
    <div class="courseware_add_img_box"
         @click.stop="seletedPPTItem">
      <div class="add_img">
        <img v-if="dataInfo.percent != 100||dataInfo.Remark.length<6"
             class="img_box"
             :src="vUrl"
             :style="dataInfo.AttachTypeKey != 1 && dataInfo.AttachTypeKey != 3 && !dataInfo.CoverUrl?'margin-top:40px':''" />
      </div>
      <div class="add_progress_banner">
        <div class="add_progress"
             :style="{left: vProgress}"></div>
      </div>
      <div v-if="dataInfo.percent && dataInfo.percent < 100"
           class="add_progress_text">上传中 {{vProgress}}</div>
      <div v-else-if="!dataInfo.percent || dataInfo.percent == 100"
           class="add_progress_text icon_type">{{dataInfo.Remark}}{{dataInfo.Remark.indexOf('元素时不兼容')!=-1?',点击启用独立转码':''}}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataInfo: Object
  },
  created () {
    // console.log(this.dataInfo, 'courseware_add_img_box-提示22');
  },
  computed: {
    vTips () {
      if (this.dataInfo) {
        if (this.dataInfo.UnusualStatusKey) {
          if (this.dataInfo.UnusualStatusKey == 1) {
            return this.dataInfo.Remark;
          } else if (this.dataInfo.UnusualStatusKey == 0) {
            return this.dataInfo.ResultStageKeyValue;
          }
        } else {
          return this.dataInfo.Remark;
        }
      }
      // (dataInfo.ResultStageKey == 5||dataInfo.ResultStageKey==4)?dataInfo.ResultStageKeyValue:(dataInfo.Remark || '')
    },
    vProgress () {
      return this.dataInfo.percent + '%';
    },
    vUrl () {
      // console.log(this.dataInfo, this.dataInfo.percentText + '-上传进度。。。');// this.$store.getters.CDNURL
      if (this.dataInfo.AttachTypeKey == '1') {
        if (this.dataInfo.AttachViewUrl.indexOf('data:image') != -1) {
          return this.dataInfo.AttachViewUrl;
        } else {
          return this.dataInfo.AttachViewUrl;
        }
      } else if (this.dataInfo.AttachTypeKey == '2') {
        if (this.dataInfo.CoverUrl) {
          return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
        } else {
          return require('../../../../../../public/image/video_big_banner.png');
        }
      } else if (this.dataInfo.AttachTypeKey == '3') {
        return require('../../../../../../public/image/voice_banner.png');
      } else if (this.dataInfo.AttachTypeKey == '4') {
        if (this.dataInfo.CoverUrl) {
          return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
        } else {
          return require('../../../../../../public/image/ppt_big_banner.png');
        }
      } else if (this.dataInfo.AttachTypeKey == '5') {
        if (this.dataInfo.AttachViewUrl) {
          return this.dataInfo.AttachViewUrl;
        } else {
          return require('../../../../../../public/image/courseware_pdf_cover.png');
        }
      }
    }
  },
  methods: {
    seletedPPTItem () {
      console.log(this.dataInfo, 'seletedPPTItem');
      this.$emit('seletedPPTItem', this.dataInfo);
    }
  }
};
</script>

<style scoped>
.courseware_add_img_box {
  height: 225px;
  width: 400px;
  pointer-events: none;
}
.courseware_add_img_box .add_progress,
.courseware_add_img_box .add_progress_banner {
  /* background-color: inherit; */
}
.add_progress_banner {
  background: unset;
}
.courseware_add_img_box .add_img {
  background: #a9a9a9;
  border-radius: 12px;
}
</style>