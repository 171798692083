<template>
  <!-- 授权课件表单 -->
  <div style="height:400px">
    <div class="form_info_edit form_info_list">
      <div>
        <input-switch v-model="data1.SetKey"
                      :formTitle="'销售业绩归属'"
                      :showInputValue="false"
                      :readonly="isReadonly"
                      @change="changeSwitch"></input-switch>
      </div>
    </div>
    <div class="form_info_edit form_info_list">
      <div>
        <input-switch v-model="data2.SetKey"
                      :formTitle="'进班排课'"
                      :showInputValue="false"
                      :readonly="isReadonly"
                      @change="changeSwitch"></input-switch>
      </div>
    </div>
    <div class="form_info_edit form_info_list">
      <div>
        <input-switch v-model="data3.SetKey"
                      :formTitle="'关联转化单'"
                      :showInputValue="false"
                      :readonly="isReadonly"
                      @change="changeSwitch"></input-switch>
      </div>
    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>

  </div>
</template>
<script>

import {
  WEBVModifySaaSClientSetting, GetSaaSClientMsg
} from '../../../API/workbench.js';
export default {
  data () {
    return {
      data1: {
        SetKey: '0'
      },
      data2: {
        SetKey: '0'
      },
      data3: {
        SetKey: '1'
      }

    };
  },
  components: {

  },
  props: {
    dataList: Object,
    isReadonly: Boolean
  },
  created () {
  },
  mounted () {
    console.log('vSaaSClientSetting', this.vSaaSClientSetting);
    let index1 = this.vSaaSClientSetting.findIndex((item) => {
      return item.SetTypeKey == '100';
    });
    console.log();
    this.data1 = JSON.parse(JSON.stringify(this.vSaaSClientSetting[index1]));
    console.log('data1', this.data1, index1);
    let index2 = this.vSaaSClientSetting.findIndex((item) => {
      return item.SetTypeKey == '101';
    });
    console.log();
    this.data2 = JSON.parse(JSON.stringify(this.vSaaSClientSetting[index2]));
    console.log('data2', this.data2, index2);
    let index3 = this.vSaaSClientSetting.findIndex((item) => {
      return item.SetTypeKey == '102';
    });
    console.log();
    this.data3 = JSON.parse(JSON.stringify(this.vSaaSClientSetting[index3]));
    console.log('data3', this.data3, index3);
  },
  computed: {
    vSaaSClientSetting () {
      return this.$store.getters.SaaSClientInfo.SaaSClientSetting;
    }
  },
  methods: {

    changeSwitch () {
      console.log('点击后', this.vSaaSClientSetting);
    },
    submit () {
      this.data1.SetKeyValue = this.data1.SetKey == 1 ? '开启' : '关闭';
      this.data2.SetKeyValue = this.data2.SetKey == 1 ? '开启' : '关闭';
      this.data3.SetKeyValue = this.data3.SetKey == 1 ? '开启' : '关闭';
      let data = [this.data1, this.data2, this.data3];
      WEBVModifySaaSClientSetting(data).then((res) => {
        console.log('WEBVModifySaaSClientSetting', res.data);
        GetSaaSClientMsg().then(result => {
          this.$store.commit('setSaaSClientInfo', result.data);
        }, err => {
          layer.alert(err.msg);
        });
      });
      this.$emit('afterSuccess', data);
      this.$emit('cancelClick');
    },
    cancel () {
      this.$emit('cancelClick');
    }
  }
};
</script>
<style scoped>
.tipSpan {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */
  position: absolute;
  top: 14px;
  left: 94px;

  color: #999999;
}
</style>
