<template>
  <el-table-column :fixed="columnData.fixed||false"
                   :label="columnData.label"
                   :prop="columnData.prop"
                   :width="columnData.width"
                   :sortable="columnData.sortable"
                   :align="columnData.align||'left'"
                   :show-overflow-tooltip="false"
                   :label-class-name="columnData.columnClass"
                   :key="columnData.prop">
    <template slot-scope="scope">
      <div v-if="scope.row.OLAPKey"
           :class="mShowEditOpt(scope.row)">
        <div :class="[mShoweditIcon(scope.row)]"
             :style="columnData.extend&&columnData.extend.isEdit?'min-height: 67px;display: flex;':''"
             class="customer_list_row"
             @click="clickEvent(scope.row)">
          <!-- 意向 -->
          <div v-if="columnData.prop=='IntentionKeyValue'&&scope.row.IntentionKeyValue"
               class="sell_support_info_rank pa"
               :class="vIntention(scope.row)"
               style="left: 16px;">
            <div class="">{{mshowText(scope.row.IntentionKeyValue)}}</div>
          </div>

          <!-- 要紧 -->
          <div v-else-if="columnData.prop=='IsGradeStarKey'"
               class="important_icon">
            <div class="table_start_icon"
                 :class="scope.row.IsGradeStarKey>0?'open_icon':''"
                 style="margin-right: 10px;"
                 @click.stop="clickEvent(scope.row,'IsGradeStarKey')"></div>

            <div class="table_v_icon"
                 :class="scope.row.IsGradeVKey>0?'open_icon':''"
                 @click.stop="clickEvent(scope.row,'IsGradeVKey')"> </div>
          </div>

          <!-- 机构名 -->
          <div v-else-if="columnData.prop=='SaaSClientNameKeyValue'">
            <div class="row_info_name"
                 :class="[columnData.extend && typeof columnData.extend.click == 'function'?'font_blue button':'']">
              {{scope.row.SaaSClientNameKeyValue}}
            </div>
            <div class="row_info_text font_green"
                 v-if="Number(scope.row.support20)!=0">
              {{scope.row.support20}}
            </div>
          </div>
          <!-- 联系人/手机号 -->
          <div v-else-if="columnData.prop=='ContactsName'">
            <div class="row_info_name">
              {{scope.row.ContactsName}}
            </div>
            <div class="row_info_text font_gray">
              {{$utils.phoneModulePower(scope.row.MobileSupport)}}
            </div>
          </div>
          <!-- 跟进情况描述 -->
          <div v-else-if="columnData.prop=='FTime'&&scope.row.FTime">
            <div class="row_info_name"
                 style="-webkit-line-clamp: 3;padding-right: 2px;">
              <span class="font_gray">{{getAgoTime(scope.row)}}：</span>
              {{ mshowText(scope.row.ContentScript)}}
            </div>
          </div>
          <!-- 下次跟进 -->
          <div v-else-if="columnData.prop=='FollowTime'">
            <div v-if="scope.row.IsAbandonKey!=1&&scope.row.FollowTime">
              <div class="row_info_name font_gray">
                {{mBeforeTime(scope.row)}}
              </div>
              <div class="row_info_name">
                {{scope.row.FollowTime.slice(5, 10)}}
              </div>
            </div>
            <div class="row_info_name font_red"
                 v-else-if="scope.row.IsAbandonKey==1">
              已放弃
            </div>
            <div class="row_info_name"
                 v-else>-</div>
          </div>
          <!-- 演示 -->
          <!-- tick_type close_type -->
          <div v-else-if="columnData.prop=='DemoTime'&&scope.row.DemoTime"
               :class="mDemoRecodeStatusKey(scope.row.DemoStatusKey)">
            <div class="row_info_name font_gray">
              {{mBeforeTime(scope.row.DemoTime)}}{{scope.row.StartTimeName}}
            </div>
            <div class="row_info_name">
              {{scope.row.DemoSupport}}
            </div>
          </div>
          <!-- 开户 -->
          <div v-else-if="columnData.prop=='DredgeTime'&&scope.row.DredgeTime">
            <div v-if="scope.row.DredgeTime">
              <div class="row_info_name">
                {{scope.row.DredgeTime}}
                <span class="font_red"
                      v-if="scope.row.StatusKey == 1 &&scope.row.dredgeType==1">试用中</span>
              </div>
              <div class="row_info_name">
                {{scope.row.ExpireDateTime}}
              </div>
              <div class="row_right_centre"
                   :style="mDredgeTimeType(scope.row)">
                {{ mExpireDateTime(scope.row) }}
              </div>
            </div>
          </div>

          <div v-else-if="columnData.prop=='SourceTypeKeyValue'">
            <div class="row_info_name font_gray">
              {{scope.row.SourceTypeKeyValue}}
            </div>
            <div class="row_info_text exceed_100">
              {{ showSourceType(scope.row)}}
            </div>
            <!-- 3：转介绍-介绍人 -->
            <div class="row_info_text exceed_100"
                 v-if="scope.row.SourceTypeKey==3">
              {{scope.row.IntroduceKeyValue}}
            </div>
          </div>
          <!-- 运行情况 -->
          <div v-else-if="columnData.prop=='SaaSClientPort'">
            <div v-if="scope.row.TypeKey==6">
              <div class="row_info_text">
                <span v-if="scope.row.SaaSClientPort">教务：
                  剩{{Number(scope.row.SaaSClientPort.CurrentPortalNum)||0}}&nbsp;
                  临{{Number(scope.row.SaaSClientPort.ExpAcademicCount)||0}}&nbsp;
                  存{{Number(scope.row.SaaSClientPort.AcademicCount)||0}}
                </span>
              </div>
              <div class="row_info_text">
                <span v-if="scope.row.SaaSClientPort">课件：
                  剩{{Number(scope.row.SaaSClientPort.CoursewarePortalCount)||0}}&nbsp;
                  临{{Number(scope.row.SaaSClientPort.ExpCoursewareCount)||0}}&nbsp;
                  存{{Number(scope.row.SaaSClientPort.CoursewareCount)||0}}
                </span>
              </div>
            </div>
            <div v-else>
              <div class="row_info_text"
                   v-if="scope.row.SaaSClientPort">学生：{{Number(scope.row.StudentCount)||0}}</div>
              <div class="row_info_text"
                   v-if="scope.row.SaaSClientPort">七天考勤：{{Number(scope.row.AttendRate) > 0 ? `${Number(scope.row.AttendRate)}%` : 0}}</div>
            </div>

          </div>
          <!-- 流量空间 -->
          <div v-else-if="columnData.prop=='FluxCount'">
            <div class="flux_main"
                 v-if="isAllZero(scope.row)">
              <div class="link_pointer"
                   @click.stop="clickTraffic(scope.row)">
                流量：<span class="normal_font"
                      :class="{highlighted_font: Number(scope.row.FluxCount) < 0}">{{trafficUseStatus(scope.row.FluxCount)}}</span>
              </div>
              <div class="space_box">
                空间：<span>{{Number(scope.row.UsedSpaceCount) == 0 ? "-" : $utils.flowFormate(scope.row.UsedSpaceCount) }}&nbsp;&nbsp;</span>
                <span v-if="!Number(scope.row.UsedSpaceCount) == 0 && Number(scope.row.SpaceSizeNum) != 0">{{scope.row.SpaceSizeNum}}%</span>
              </div>
            </div>
            <div v-else>
              -
            </div>
          </div>
          <div v-else
               :class="[columnData.extend &&columnData.extend.className?columnData.extend.className:'']"
               :style=" mshowText(scope.row[columnData.prop])=='-'?'padding-left: 10px;color: black !important;':''"
               @click="clickEvent(scope.row)"
               v-html="mshowNumber(scope.row)">
          </div>
        </div>

      </div>
    </template>
  </el-table-column>
</template>

<script>
// import dialogFactory from '../../../common/dialog-factory';
export default {
  data () {
    return {
      flag: false
    };
  },
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          type: String,
          extend: {
            className: 'font_blue button',
            isEdit: Boolean,
            click: () => { } // 表示该列可点击
          }
        };
      }
    }
  },
  computed: {
    // vClassName () {
    //   let className = '';
    //   if (this.columnData.extend && this.columnData.extend.className) {
    //     className = this.columnData.extend.className;
    //   }
    //   return className;
    // },
  },
  created () {
  },
  components: {
    // dialogFactory
  },
  methods: {
    vIntention (rowData) {
      let className = '';
      // 客户意愿 S-极高 A-较高 B-一般 C-较低
      if (rowData.IntentionKeyValue) {
        if (rowData.IntentionKeyValue == 'S') {
          className = 'purpose_black';
        } else if (rowData.IntentionKeyValue == 'A') {
          className = 'purpose_brown';
        } else if (rowData.IntentionKeyValue == 'B') {
          className = 'purpose_yellow';
        } else if (rowData.IntentionKeyValue == 'C') {
          className = 'purpose_gray';
        }
      }
      return className;
    },
    clickEvent (rowData, val) {
      if (this.columnData.extend && typeof this.columnData.extend.click == 'function') {
        // 普通点击事件 直接执行回调
        this.columnData.extend.click(rowData, val);
      }
    },
    vAgoTime (item) {
      if (item.StartTimeFrom) {
        return this.$utils.StrToRelativeTime(item.StartTimeFrom);
      }
    },
    vStartTimeFrom (item) {
      if (item.StartTimeFrom) {
        return item.StartTimeFrom.substring(0, 10);
      }
    },
    // 演示状态 0-预约;1-完成;2-取消
    mDemoRecodeStatusKey (statusKey) {
      if (statusKey == 1) {
        return 'tick_type';
      } else if (statusKey == 2) {
        return 'close_type';
      } else {
        return '';
      }
    },
    // 开户时间
    mDredgeTimeType (rowData) {
      if (this.$utils.formatDateToLine(Date.new()) > rowData.ExpireDateTime) { // 关停状态
        return 'color: #fa5a46';
      } else {
        let days = this.$utils.newDateDiffIOS(rowData.DredgeTime, rowData.ExpireDateTime);
        if (days <= 30) {
          if (rowData.StatusKey == 1 && rowData.dredgeType == 1) {
            return 'color: #fa5a46;bottom: 22px;';
          } else {
            return 'color: #fa5a46';
          }
        } else if (days <= 60) {
          return 'color: #ffa843';
        } else {
          return '';
        }
      }
    },
    // 开户结束日期 DateDiff
    mExpireDateTime (rowData) {
      if (rowData.ExpireDateTime) {
        console.log(rowData.ExpireDateTime);
        let today = this.$utils.formatDateToLine(Date.new()).replace(/\-/g, '/');
        let endDate = rowData.ExpireDateTime.replace(/\-/g, '/');;
        if (this.$utils.diffTwoTime(today, endDate) == 0) {
          return '今天';
        } else if (today > endDate) {
          return '关停';
        } else {
          return this.$utils.diffTwoTime(today, endDate) + 1 + '天';
        }
      } else {
        return '';
      }
    },
    // 前后时间
    mBeforeTime (rowData) {
      if (rowData.FollowTime) {
        let timer = this.$utils.StrToRelativeTime(rowData.FollowTime.substring(0, 10).replace(/-/g, '/'));
        let today = this.$utils.StrToRelativeTime(Date.new().toLocaleDateString());
        if (timer == today) {
          return this.$utils.StrToRelativeTime(rowData.FollowTime);
        } else {
          return this.$utils.StrToRelativeTime(rowData.FollowTime.substring(0, 10).replace(/-/g, '/'));
        }
      }
      return '';
    },
    mshowText (val) {
      if (val) {
        return val;
      } else {
        return '-';
      }
    },
    mshowNumber (rowData) {
      if (this.columnData.decimalPlace >= 0 && Number(rowData[this.columnData.prop]) >= 0) { // 数字类型
        if (Number(rowData[this.columnData.prop]) > 0) {
          return this.$utils.mAmountType(rowData[this.columnData.prop], this.columnData.decimalPlace);
        } else {
          return '-';
        }
      } else { // 非数字类型
        if (rowData[this.columnData.prop]) {
          return rowData[this.columnData.prop];
        } else {
          return '-';
        }
      }
    },
    // 修改图标显示
    mShoweditIcon (rowData) {
      if (this.columnData.extend && this.columnData.extend.isEdit) {
        if (this.columnData.prop == 'MarketFollowRecode' && !rowData.MarketFollowRecode) {
          return '';
        } else {
          return 'edit_icon';
        }
      } else {
        return '';
      }
    },
    // 修改高亮显示
    mShowEditOpt (rowData) {
      if (this.columnData.extend && this.columnData.extend.isClick) {
        if (this.columnData.prop == 'MarketFollowRecode' && !rowData.MarketFollowRecode) {
          return '';
        } else {
          return 'customer_list_box';
        }
      } else {
        return '';
      }
    },
    // 来源 1-自约;2-引流;3-转介绍;4-约单
    showSourceType (rowData) {
      switch (rowData.SourceTypeKey) {
        case '1':
          return rowData.DocumentaryKeyValue;
        case '2':
          return rowData.ShuntTypeKeyValue;
        case '3':
          return rowData.IntroduceSaaSClientKeyValue;
        case '4':
          return rowData.EngageBillKeyValue;
        default:
          break;
      }
    },
    // 跟进时间
    getAgoTime (rowData) {
      if (rowData.FTime) {
        let timer = this.$utils.StrToRelativeTime(rowData.FTime.substring(0, 10).replace(/-/g, '/'));
        let today = this.$utils.StrToRelativeTime(Date.new().toLocaleDateString());
        if (timer == today) {
          return this.$utils.StrToRelativeTime(rowData.FTime);
        } else {
          return this.$utils.StrToRelativeTime(rowData.FTime.substring(0, 10).replace(/-/g, '/'));
        }
      }
      return '';
    },
    // 流量使用情况
    trafficUseStatus (count) {
      const countVal = this.$utils.flowFormate(count);
      return Number(count) < 0 ? `${countVal}` : countVal;
    },
    // 流量点击
    clickTraffic (rowData) {
      // console.log(rowData);
      let name = '';
      let moduleName = '';
      let obj = {
        OLAPKey: rowData.SaaSClientNameKey || rowData.OLAPKey
      };

      if (rowData.TypeKey == '6') { // 总部账单
        name = '流量账单';
        moduleName = 'HQflowBillReport';
      } else {
        name = '月度汇总';
        moduleName = 'flowPacketBill';
      }
      this.$dialog.open(this, {
        name,
        routerName: this.$route.name,
        moduleName,
        ModulePowerKey: '',
        data: { dataInfo: obj }
      });
    },
    // 是否数据都是零
    isAllZero (data) {
      let isAllZero = true;
      if (Number(data.FluxCount) == 0 && Number(data.UsedSpaceCount) == 0 && Number(data.SpaceSizeNum) == 0) {
        isAllZero = false;
      }
      return isAllZero;
    }
  }
};
</script>

<style scoped>
.flux_main {
  overflow: unset !important;
  text-overflow: unset !important;
  padding: 0 !important;
}
.normal_font {
  color: #3498db;
}
.highlighted_font {
  color: #fa5a46;
}
.link_pointer {
  cursor: pointer;
  line-height: 20px;
}
.space_box {
  line-height: 20px;
}
.row_right_centre {
  margin-top: -17px;
  bottom: inherit !important;
}
</style>