<template>
  <!-- 超级管理 -->
  <div class="super_management">
    <div class="table_fixed_nav_box">
      <div class="table_fixed_nav tltle_margin">
        <div @click="showMendainListClick"
             class="table_fixed_nav_list"
             :class="{opt:showManagement=='mendainList'}">门店列表</div>

        <div @click="showHistoryListClick"
             class="table_fixed_nav_list"
             :class="{opt:showManagement=='recordList'}">门店管理历史记录</div>
      </div>
    </div>
    <div>
      <!-- 门店列表 -->
      <div v-if="showManagement=='mendainList'">
        <mendain-list :listType="showManagement"></mendain-list>
      </div>
      <!-- 门店历史记录列表 -->
      <div v-else-if="showManagement=='recordList'">
        <mendian-history-record-list :listType="showManagement"></mendian-history-record-list>
      </div>
    </div>

    <div>
      <dialog-factory ref="superManagementDialogFactory"
                      :key="'allStudent'"
                      :routerName="'superManagement'"></dialog-factory>
      <dialog-factory-common ref="superManagement"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import mendainList from './mendain-list/index';
import mendianHistoryRecordList from './mendian-history-record-list';
import dialogFactory from '../../common/dialog-factory';
export default {
  name: 'superManagement',
  data () {
    return {
      showManagement: 'mendainList'
    };
  },
  components: {
    mendainList,
    mendianHistoryRecordList,
    dialogFactory
  },
  created () {
  },
  mounted () {
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'superManagement') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      console.log('openCommonCustomDialog', dialogInfo);
      if (dialogInfo.routerName == 'superManagement') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });
  },
  methods: {
    // 切换门店列表
    showMendainListClick () {
      this.showManagement = 'mendainList';
    },
    // 切换管理历史记录列表
    showHistoryListClick () {
      this.showManagement = 'recordList';
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.superManagementDialogFactory) {
        this.$refs.superManagementDialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.superManagement) {
        this.$refs.superManagement.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  },
  computed: {}
};
</script>
