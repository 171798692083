<template>
  <div
    class="form_item form-input form_info_list form_info_line"
    :class="{ form_info_required: required }"
  >
    <div class="form_info_field">{{formTitle}}</div>
    <div class="form_info_value input-Number">
      <input
        type="number"
        :placeholder="required?'必填':'选填'"
        :value="vInputValue"
        :readonly="readonly"
        @focus="focusEvent"
        @blur="blurEvent"
        @change="changeEvent($event.target.value)"
      />
    </div>
  </div>
</template>
<script>
import { numberToDecimalPlaces } from "../../utils/index.js";
export default {
  name: "inputNumber",
  data() {
    return {
      inputValue: 0,
      defaultValue: 0, // 默认值,
      isWrithing: false
    };
  },
  props: {
    // 单选数据项 需绑定到界面
    formTitle: String,
    max: [String, Number],
    min: [String, Number],
    value: Number,
    placeholder: String,
    decimalPlaces: {
      type: [String, Number],
      default: 2
    },
    readonly: Boolean,
    required: Boolean
  },
  computed: {
    vInputValue: function() {
      return this.isWrithing
        ? numberToDecimalPlaces(this.inputValue, this.vDecimalPlaces)
        : this.inputValue.toFixed(this.vDecimalPlaces);
    },
    vDecimalPlaces() {
      return this.decimalPlaces;
    }
  },
  created() {
    this.setInputValue(this.value);
  },
  watch: {
    value(c, o) {
      this.setInputValue(c);
    }
  },
  methods: {
    setInputValue(v) {
      this.inputValue = Number(v);
      this.inputValue = this.inputValue || this.defaultValue;
    },
    changeEvent(val) {
      this.inputValue = numberToDecimalPlaces(val, this.vDecimalPlaces);
      this.inputValue = this.inputValue > this.max ? this.max : this.inputValue;
      this.inputValue = this.inputValue < this.min ? this.min : this.inputValue;

      this.$emit("change", this.inputValue);
      this.$emit("inputChange", this.inputValue);
    },
    focusEvent() {
      this.isWrithing = true;
    },
    blurEvent() {
      this.isWrithing = false;
    }
  }
};
</script>
<style scoped>
.mint-field-clear {
  display: none;
}
.mintui-field-error:before {
  content: "" !important;
}
</style>



