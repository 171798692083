<template>
  <div class="attendance_course_content" @scroll="scrollTOP">
    <div v-if="vDataList.length!=0"
         class="attendance_course_date_ul" >
      <atten-date-item v-for="(item,key) in vDataList"
                       :key="key"
                       :dateItem="item"></atten-date-item>
      <div class="no_list_text" v-if="noMore">已显示全部</div>
    </div>
    <basics-nodata-tips v-if="vDataList.length==0"></basics-nodata-tips>
  </div>
</template>

<script>
import attenDateItem from './atten-date-item.vue';
import { getAttendanceListByStu } from '../../../../../API/workbench';
export default {
  data () {
    return {
      dataList: [],
      isLoadEnd: false, // 是否加载完
      noMore: false, // 是否没有数据
      OrderByTime:''

    };
  },
  props: {
    studentInfo: Object
  },
  components: {
    attenDateItem
  },
  created () {
    if (this.studentInfo.OLAPKey > 0) {
      this.OrderByTime=''
      this.noMore=false
      this.getDataList();
    }
  },
  watch:{
     'studentInfo.OLAPKey':{
      handler(c,o){
        if(c>0){
          console.log('watchstudentInfo.OLAPKey')
            this.OrderByTime=''
            this.noMore=false
            this.dataList=[]
            this.getDataList()
        }
      },
      deep:true
    }
  },
  computed: {
    vDataList () {
      const datelist = [];
      this.dataList.forEach(o => {
        const locaItem = datelist.find(p => {
          return p.date == o.ClassTime.substring(0, 7);
        });
        if (!locaItem) {
          const newobj = {
            date: o.ClassTime.substring(0, 7),
            childList: []
          };
          datelist.push(newobj);
        }
      });
      datelist.forEach(o => {
        this.dataList.forEach(p => {
          if (o.date == p.ClassTime.substring(0, 7)) {
            o.childList.push(p);
          }
        });
      });
      console.log(datelist, 'vDataList111');
      return datelist;
    }
  },
  methods: {
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;
      console.log(ETarget.scrollHeight, ETarget.clientHeight, ETarget.scrollTop);
      if (
        (ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight ||
          ETarget.scrollTop + 1 ==
          ETarget.scrollHeight - ETarget.clientHeight) &&
        !this.noMore &&
        !this.isLoadEnd
      ) {
        this.getDataList();
      }
    },
    getDataList () {
      this.isLoadEnd=true
      getAttendanceListByStu(this.studentInfo.OLAPKey,this.OrderByTime).then(result => {
        console.log('getAttendanceListByStu', result.data);
        if(result.data.length>0){
          result.data.forEach(o=>{
            this.dataList.push(o)
          })
          //this.dataList = result.data;
          if(this.dataList.length>0){
            this.OrderByTime=this.dataList[this.dataList.length-1].ClassTime.substring(0,10)
          }else{
            this.OrderByTime=''
          }
          console.log('this.OrderByTime',this.OrderByTime)
          this.noMore=false
        }else{
           this.noMore=true
        }
          this.isLoadEnd=false
      }, error => {
        layer.alert(error.msg);
      });
    }
  }

};
</script>

<style scoped>
.attendance_course_content {
  max-height: 600px;
  overflow-y: auto;
}
</style>
