<template>
  <div class="maxHeight_popup_ul">
    <basics-popup-list-item v-for="(item,key) in dataList"
                            :key="key"
                            :item="item"
                            :showType="showType"
                            :seletedKey="seletedKey"
                            @cancelClick="cancelClickAfter"
                            @seletedItem="seletedItem"
                            @seletedHint="seletedHint">
    </basics-popup-list-item>
  </div>
</template>

<script>
import basicsPopupListItem from './basics-popup-list-item';
export default {
  components: {
    basicsPopupListItem
  },
  data () {
    return {

    };
  },
  props: {
    dataList: Array,	  // 选择列表
    seletedKey: [Number, String],  // 选中的Key
    showType: {
      type: String,
      default: 'other'
    } // opt 为选中类型,提供撤销    其他other则为即时操作. 没有选中效果.
  },
  methods: {
    cancelClickAfter (item) {
      this.$emit('cancelClickAfter', item);
    },
    seletedItem (item) {
      if (!this.seletedKey) {
        this.dataList.forEach(o => {
          if (o.key == item.key) {
            this.$set(o, 'isActive', true);
          } else {
            this.$set(o, 'isActive', false);
          }
        });
      }

      this.$emit('seletedItem', item);
    },
    seletedHint (item) {
      this.$emit('seletedHint', item);
    }
  }
};
</script>

<style>
.maxHeight_popup_ul {
    max-height: 400px;
    overflow-y: scroll;
}
</style>
