<template>
  <div class="credit_management_popup">
    <div class="credit_management_table">
      <table-list class="summarizing_list table_list_content contentItem"
                  :tableData="vdataReportList"
                  :tableColumns="tableCourseColumns"
                  :queryParams="searchObj"
                  @loadTableData="getDataList"></table-list>
    </div>
    <custom-dialog title="转化关联"
                   width="410px"
                   :visible.sync="isShowRelevantForm"
                   :before-close="closeRelevantFormDialog">
      <relevant-form :IsRenewalKey="IsRenewalKey"
                     :StudentKey="StudentKey"
                     :TableID="ids"
                     :studentName="studentName"
                     @afterSuccess="relevantFormSuccess"
                     @cancelClick="closeRelevantFormDialog"></relevant-form>
    </custom-dialog>

    <custom-dialog title="修改课单类型"
                   width="400px"
                   :visible.sync="isOpenApplyTypeUpdate"
                   :before-close="closeApplyTypeUpdate">
      <applyTypeUpdate :dataInfo="applyData"
                       :TableID="ids"
                       @afterSuccess="supplementtarySettingSuccess"
                       @cancelClick="closeApplyTypeUpdate"></applyTypeUpdate>
    </custom-dialog>
  </div>
</template>
<script>
import { GetXYKDMsgByTicketID } from '../../API/workbench';
import tableList from './table-list/index.vue';
import relevantForm from './relevant-form.vue';
import applyTypeUpdate from '../registration-supplementary-correction/report/apply-type-update.vue';
export default {
  data () {
    return {
      applyData: {},
      isOpenApplyTypeUpdate: false,
      seletedItem: null, // 选中单个
      showAddForm: false,
      studentName: '',
      dataReportList: [],
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        ClassType: '4'
      },
      isShowRelevantForm: false,
      StudentKey: '',
      IsRenewalKey: '',
      ids: '',
      tableData: {},
      totalNum: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '适用课程',
          prop: 'ApplyCorName',
          width: 120,
          sortable: false,
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '购买数量',
          prop: 'NumCountText',
          width: 100,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '实收款',
          prop: 'ActualPayAmountText',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '生效日期',
          prop: 'StartTimeText',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '失效日期',
          prop: 'ExpireTime',
          width: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '课单类型',
          prop: 'ApplyTypeKeyValue',
          width: 80,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            setClassName: (rowData) => {
              console.log('owData.ActualPayAmount', Number(rowData.ActualPayAmount) != 0 && Number(rowData.ApplyTypeKey) < 4);
              return (Number(rowData.ActualPayAmount) != 0 && Number(rowData.ApplyTypeKey) < 4) ? 'font_blue' : 'font_black';
            },
            type: 'expand',
            click: (rowData) => {
              if ((Number(rowData.ActualPayAmount) != 0 && Number(rowData.ApplyTypeKey) < 4)) {
                this.openApplyTypeUpdate(rowData);
              }
            }
          }
        },
        {
          label: '关联转化单',
          prop: 'RelevanceContentText',
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            setClassName: (rowData) => {
              return (Number(rowData.ActualPayAmount) != 0 && Number(rowData.ApplyTypeKey) < 4) ? 'font_blue' : 'font_black';
            },
            type: 'expand',
            click: (rowData) => {
              if (Number(rowData.ActualPayAmount) != 0 && Number(rowData.ApplyTypeKey) < 4) {
                this.openRelevantFormDialog(rowData);
              }
            }
          }
        }
        // {
        //   label: '关联转化单',
        //   prop: 'option',
        //   type: 'hidden-btn',
        //   minWidth: 200,
        //   align: 'center',
        //   extend: {
        //     tableBtnGroup: [
        //       {
        //         name: `RelevanceContent`,
        //         extend: {
        //           click: (rowData) => {
        //             console.log('rowData', rowData);
        //           }
        //         }
        //       }
        //     ]
        //   }
        // }
      ]
    };
  },
  props: {
    tableID: {
      type: [Number, String],
      default: 0
    }
  },
  components: {
    relevantForm,
    tableList,
    applyTypeUpdate
  },
  watch: {
    // 优惠金额
    'dataReportList': {
      handler (newValue, oldValue) {
        if (newValue.length > 0) {
          let text = '设置';
          let count = 0;
          let sumCount = 0;
          newValue.forEach((item) => {
            console.log('dasdasd', item.RelevanceContent == '未设置' && (Number(item.ActualPayAmount) == 0 || Number(item.ApplyTypeKey) == 4), item.RelevanceContent);
            if (!(Number(item.ActualPayAmount) == 0 || Number(item.ApplyTypeKey) == 4)) {
              sumCount++;
            }
            if (item.RelevanceContent != '未设置' && item.RelevanceContentText != '-') {
              count++;
            }
          });
          if (count == 0) {
            text = '设置';
          }
          if (sumCount == 0) {
            text = '无';
          }
          if (sumCount == count && sumCount != 0) {
            text = '已关联全部';
          }

          if (count > 0 && count < sumCount) {
            text = '已关联部分';
          }
          console.log('setText', text, count, sumCount);
          this.$emit('setText', text);
        }
      },
      deep: true
    }
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.StudentCount = Number(o.StudentCount) || 0;
        o.ThisMonthFlow = this.$utils.flowFormate(o.ThisMonthFlow);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    },
    vAddFormTitle () {
      return !this.seletedItem ? '新增班级' : '修改班级';
    },
    vSeletedItemKey () {
      return this.seletedItem ? this.seletedItem.OLAPKey : 0;
    }
  },
  created () {
    this.getDataList();
  },
  mounted () {
    this.$dialog.register(this, ['cwsClassDetail']);
  },
  methods: {
    unitKeyValue (type) {
      let a = '';
      switch (type) {
        case '2':
          a = '年';
          break;
        case '3':
          a = '月';
          break;
        case '4':
          a = '日';
          break;
        case '5':
          a = '课时';
          break;

        default:
          break;
      }
      return a;
    },
    // 查看详情
    goDetail (item) {
      this.seletedItem = item;
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '班级详情',
        moduleName: 'cwsClassDetail',
        data: {
          classKey: this.seletedItem.OLAPKey,   // 班级详情适用， 班级Key
          isTeacher: false   // 班级详情适用， 是否老师， false则为校长
        },
        callBack: {
          afterSuccess: () => { this.getDataList(); }
        }
      });
    },
    // 修改单个
    editItem (item) {
      this.seletedItem = item;
      this.showAddForm = true;
    },
    // 新增按钮
    addItem () {
      this.seletedItem = null;
      this.showAddForm = true;
    },
    closeDialog () {
      this.showAddForm = false;
    },
    updateData () {
      this.getDataList();
    },
    // 重置查询条件
    initParamsForQuery () {
      this.dataReportList = [];
      this.searchObj.searchText = '';
      this.searchObj.pageIndex = 0;
    },
    // 点击分类
    clcikStatictisItem (item) {
      this.initParamsForQuery();
      this.searchObj.ClassType = item.type;
      this.getDataList();
    },
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      if (this.searchObj.searchText == '') {
        this.searchObj.StudentKey = '';
      }
      this.getDataList();
    },
    getDataList () {
      this.dataReportList = [];
      GetXYKDMsgByTicketID(this.tableID).then(
        (res) => {
          console.log('CWSCWSSearchPageClassList', res.data);
          this.totalNum = res.data.Total;
          this.dataReportList = res.data;

          this.dataReportList.forEach(item => {
            item.StartTimeText = item.StartTime == '1900-01-01' ? '-' : item.StartTime;
            item.NumCountText = Number(item.NumCount) + this.unitKeyValue(item.UnitKey);
            item.ActualPayAmountText = Number(item.ActualPayAmount) == 0 ? '-' : '￥' + this.$utils.setMoneyformatToParseInt(Number(item.ActualPayAmount));
            item.RelevanceContentText = (Number(item.ActualPayAmount) == 0 || Number(item.ApplyTypeKey) == 4) ? '-' : item.RelevanceContent;
          });
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    closeRelevantFormDialog () {
      this.isShowRelevantForm = false;
    },
    openRelevantFormDialog (data) {
      this.StudentKey = data.StudentKey;
      this.IsRenewalKey = (data.ApplyTypeKey == 2 && Number(data.ActualPayAmount) > 0) ? 1 : 0;
      this.ids = data.TableID;
      this.studentName = data.StudentKeyValue;
      this.isShowRelevantForm = true;
    },
    relevantFormSuccess () {
      this.getDataList();
      this.$emit('afterSuccess');
    },
    openApplyTypeUpdate (data) {
      this.ids = data.TableID;
      this.applyData = {
        ApplyTypeKey: data.ApplyTypeKey,
        ApplyTypeKeyValue: data.ApplyTypeKey == '1' ? '新生报名' : data.ApplyTypeKey == '2' ? '老生续费' : '老生扩科'
      };
      this.isOpenApplyTypeUpdate = true;
    },
    closeApplyTypeUpdate () {
      this.isOpenApplyTypeUpdate = false;
    },
    supplementtarySettingSuccess () {
      this.getDataList();
    }
  }
};
</script>

<style scoped>
.contentItem {
  padding: 20px !important;
}
</style>