<template>
  <div>
    <div class="content_marginRL content_marginTop_30"
         style="height:600px">
      <course-info :circleCourseInfo="circleCourseData"></course-info>

      <!-- 内容 -->
      <div class="border_top_line form_info_edit mendian_setting_content single_shadow border_bottom">
        <div @click.stop="toEditClassTime"
             class="button">
          <input-dialog :formTitle="'调整上课时间'"
                        :customPlaceholder="true"
                        :formPlaceholder="''"
                        @showEldilog="toEditClassTime"></input-dialog> 
        </div>
        <div @click.stop="toEditTeacher"
             class="button">
          <input-dialog :formTitle="'调整老师'"
                        :customPlaceholder="true"
                        :formPlaceholder="''"
                        @showEldilog="toEditTeacher"></input-dialog>
        </div>
        <div @click.stop="toEditFullPeopleCount"
             class="button">
          <input-dialog :formTitle="'修改计划满员数'"
                        :customPlaceholder="true"
                        :formPlaceholder="''"
                        @showEldilog="toEditFullPeopleCount"></input-dialog>
        </div>
        <div v-if="isShowAllMenu"
             @click.stop="toEditClassRoom"
             class="button">
          <input-dialog :formTitle="'修改教室'"
                        :customPlaceholder="true"
                        :formPlaceholder="''"
                        @showEldilog="toEditClassRoom"></input-dialog>
        </div>
        <div v-if="isShowAllMenu"
             @click.stop="toEditPeriodCount"
             class="button">
          <input-dialog :formTitle="'修改课消'"
                        :customPlaceholder="true"
                        :formPlaceholder="''"
                        @showEldilog="toEditPeriodCount"></input-dialog>
        </div>
        <div v-if="isShowAllMenu && vIsShowChargeLeave"
             @click.stop="toEditIsleaveKey"
             class="button">
          <input-dialog :formTitle="'修改请假扣课时'"
                        :customPlaceholder="true"
                        :formPlaceholder="''"
                        @showEldilog="toEditIsleaveKey"> </input-dialog>
        </div>
        <div v-if="isShowAllMenu && vIsShowChargeTruant"
             @click.stop="toEditIsChargesKey"
             class="button">
          <input-dialog :formTitle="'修改旷课扣课时'"
                        :customPlaceholder="true"
                        :formPlaceholder="''"
                        @showEldilog="toEditIsChargesKey"></input-dialog>
        </div>
        <div v-if="isShowAllMenu"
            @click.stop="toEditWarnLossCount"
             class="button">
          <input-dialog :formTitle="'修改亏损预警人数'"
                        :customPlaceholder="true"
                        :formPlaceholder="''"
                        @showEldilog="toEditWarnLossCount"></input-dialog>
        </div>
      </div>
      <div class="form_edit_extend_btn_box"
           @click="isShowAllMenu = true">
        <div v-show="!isShowAllMenu"
             class="form_edit_extend_btn">
          修改更多
        </div>
      </div>
      <div class="confirm_btn btn_fixed_bottom pa">
        <div class="btn_fixed_blue btn_hover_bg_blue"
             @click.stop="doCompleteBack">完成并返回</div>
      </div>
    </div>
    <custom-dialog title="调整上课时间"
                   class="edit_class_from popup_dialog_height_from defult_dialog"
                   :visible.sync="isShowEditClassTimeDialog"
                   :before-close="closeEditClassTimeDialog">
      <edit-class-time :circleCourseInfo="circleCourseData"
                       @closeDialog="closeEditClassTimeDialog">

      </edit-class-time>
    </custom-dialog>
    <custom-dialog title="调整老师"
                   class="edit_class_from popup_dialog_height_from defult_dialog"
                   :visible.sync="isShowEditTeacherDialog"
                   :before-close="closeEditTeacherDialog">
      <edit-teacher :circleCourseInfo="circleCourseData"
                    @closeDilog="closeEditTeacherDialog">

      </edit-teacher>
    </custom-dialog>
    <custom-dialog title="修改计划满员数"
                   class="edit_class_from defult_dialog popup_dialog_height_from"
                   :visible.sync="isShowFullPeopleCountDialog"
                   :before-close="closeFullPeopleCountDilog">
      <edit-full-people-count :circleCourseInfo="circleCourseData"
                              @closeDilog="closeFullPeopleCountDilog"></edit-full-people-count>
    </custom-dialog>
    <custom-dialog title="修改教室"
                   class="edit_class_from defult_dialog popup_dialog_height_from"
                   :visible.sync="isShowClassRoomDialog"
                   :before-close="closeClassRoomDialog">
      <edit-class-room :circleCourseInfo="circleCourseData"
                       @closeDilog="closeClassRoomDialog"></edit-class-room>
    </custom-dialog>
    <custom-dialog title="修改课消"
                   class="edit_class_from defult_dialog popup_dialog_height_from"
                   :visible.sync="isShowPeriodCountDialog"
                   :before-close="closePeriodCountDilog">
      <edit-period-count :circleCourseInfo="circleCourseData"
                         @closeDilog="closePeriodCountDilog"></edit-period-count>
    </custom-dialog>

    <custom-dialog title="修改旷课扣课时"
                   class="defult_dialog popup_dialog_height_from"
                   :visible.sync="isShowIsChargesKeyDialog"
                   :before-close="closeIsChargesKeyDilog">
      <edit-charge-truant :circleCourseInfo="circleCourseData"
                          @closeDilog="closeIsChargesKeyDilog"></edit-charge-truant>
    </custom-dialog>
    <custom-dialog title="修改请假扣课时"
                   class="defult_dialog popup_dialog_height_from"
                   :visible.sync="isShowIsleaveKeyDialog"
                   :before-close="closeIsleaveKeyDilog">
      <edit-isleave-key :circleCourseInfo="circleCourseData"
                        @closeDilog="closeIsleaveKeyDilog"></edit-isleave-key>
    </custom-dialog>
         <custom-dialog title="修改亏损预警人数"
                   class="popup_dialog_height_from defult_dialog"
                   :visible.sync="isShowWarnLossCountDialog"
                   :before-close="closeWarnLossCountDilog">
      <edit-warnLoss-count :circleCourseInfo="circleCourseData"
                         @closeDilog="closeWarnLossCountDilog"></edit-warnLoss-count>
    </custom-dialog>
  </div>
</template>
<script>
import courseInfo from '../course-info';
import editClassTime from './edit-class-time';
import editTeacher from './edit-teacher';
import editClassRoom from './edit-class-room';
import editPeriodCount from './edit-period-count';
import editFullPeopleCount from './edit-full-people-count';
import editChargeTruant from './edit-charge-truant';
import editIsleaveKey from './edit-isleave-key';
import editWarnLossCount from './edit-warnLoss-count';
import { GetStructedRuleDetail } from '../../../API/workbench';
export default {
  data () {
    return {
      isShowAllMenu: false,
      isShowEditTeacherDialog: false, // 老师
      isShowEditClassTimeDialog: false, // 上课时间
      isShowClassRoomDialog: false, // 修改教室
      isShowPeriodCountDialog: false, // 修改课消
      isShowFullPeopleCountDialog: false, // 修改计划满员数
      isShowIsChargesKeyDialog: false, // 修改旷课扣课时
      isShowIsleaveKeyDialog: false, // 修改请假扣课时
      isShowWarnLossCountDialog: false,
      circleCourseData: {}
    };
  },
  components: {
    courseInfo,
    editClassRoom,
    editPeriodCount,
    editFullPeopleCount,
    editChargeTruant,
    editIsleaveKey,
    editTeacher,
    editClassTime,
    editWarnLossCount
  },
  props: {
    circleCourseInfo: {
      type: Object
    }
  },
  computed: {
    // 2不扣课时 3扣课时 4建课设置
    vIsShowChargeTruant () {
      return this.$utils.getSaaSClientSet(33) == 4 && this.$utils.getSaaSClientSet(59) > 0;
    },
    //  2不扣课时 3扣课时 4建课设置
    vIsShowChargeLeave () {
      return this.$utils.getSaaSClientSet(32) == 4;
    }
  },
  created () {
    this.circleCourseData = this.circleCourseInfo;
  },
  methods: {
    // 修改上课时间
    toEditClassTime () {
      this.isShowEditClassTimeDialog = true;
    },
    // 修改老师
    toEditTeacher () {
      this.isShowEditTeacherDialog = true;
    },
    // 修改教室
    toEditClassRoom () {
      this.isShowClassRoomDialog = true;
    },
    // 修改课消
    toEditPeriodCount () {
      this.isShowPeriodCountDialog = true;
    },
    // 修改计划满员数
    toEditFullPeopleCount () {
      this.isShowFullPeopleCountDialog = true;
    },
    // 修改请假扣课时
    toEditIsleaveKey () {
      this.isShowIsleaveKeyDialog = true;
    },
    // 修改旷课扣课时
    toEditIsChargesKey () {
      this.isShowIsChargesKeyDialog = true;
    },
    // 修改亏损预警人数
    toEditWarnLossCount () {
      this.isShowWarnLossCountDialog = true;
    },
    // 关闭 修改教室
    closeClassRoomDialog (ruleID) {
      this.reLoadData(ruleID);
      this.isShowClassRoomDialog = false;
    },
    // 关闭 修改课消
    closePeriodCountDilog (ruleID) {
      this.reLoadData(ruleID);
      this.isShowPeriodCountDialog = false;
    },
    // 关闭 修改计划满员数
    closeFullPeopleCountDilog (ruleID) {
      this.reLoadData(ruleID);
      this.isShowFullPeopleCountDialog = false;
    },
    // 关闭 修改旷课扣课时
    closeIsChargesKeyDilog (ruleID) {
      this.reLoadData(ruleID);
      this.isShowIsChargesKeyDialog = false;
    },
    // 关闭 修改请假扣课时
    closeIsleaveKeyDilog (ruleID) {
      this.reLoadData(ruleID);
      this.isShowIsleaveKeyDialog = false;
    },
     // 关闭 亏损预警人数
    closeWarnLossCountDilog (ruleID) {
      this.reLoadData(ruleID);
      this.isShowWarnLossCountDialog = false;
    },
    // 关闭 调整上课时间
    closeEditClassTimeDialog (ruleID) {
      if (ruleID) {
        this.getStructedRuleDetail(ruleID);
      }
      this.reLoadData(ruleID);
      this.isShowEditClassTimeDialog = false;
    },
    // 关闭 调整老师
    closeEditTeacherDialog (ruleID) {
      this.reLoadData(ruleID);
      this.isShowEditTeacherDialog = false;
    },
    // 重新刷新数据
    reLoadData (ruleID) {
      if (ruleID) {
        this.$bus.emit('AfterEditCircleCourse', ruleID, this.circleCourseData);
      }
    },
    // 完成并返回
    doCompleteBack () {
      this.$emit('closeDialog');
    },
    getStructedRuleDetail (ruleID) {
      GetStructedRuleDetail(ruleID).then(result => {
        this.circleCourseData = result.data;
        this.circleCourseData.SubRule = null;
      });
    }
  }
};
</script>

<style>
</style>