<template>
  <div class="list_left pr"
       :class="courseClass"
       @click="clickItem">
    <el-tooltip :content="item.GradeClassKeyValue"
                popper-class="mendian_block_tooltip"
                effect="light"
                visible-arrow="false"
                :disabled="disabled"
                placement="top">
      <div class="list_title">
        <span ref="nameBox">
          {{item.GradeClassKeyValue}}
        </span>
      </div>
    </el-tooltip>
    <div class="list_top">
      <span class="font_gray">{{item.SClassTimeName}}-{{item.EClassTimeName}}&nbsp;&nbsp;{{vMTeacherKeyValue}}</span>
    </div>

    <div class="list_bottom"
         :class="item.StyleName+ '_bottom_color'">
      <span v-if="setStatusKeyValue !='未开始'">签到 {{Number(item.ArrivedNumCount)}}人 | 未到 {{noAttenCount}} 人</span>
      <span v-else>安排 {{Number(item.ArrangedNumCount)}} 人 </span>
    </div>
    <div class="list_type"
         :class="item.StyleName+'_bottom_color'">{{setStatusKeyValue}}</div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      disabled: false
    };
  },
  props: ['item'],
  computed: {
    SaaSClientInfo () {           //  最早签到时间(分钟)
      return this.$store.getters.SaaSClientInfo;
    },
    courseClass () {
      let c = '';
      c += this.item.StyleName + '_color';
      if (this.item.StatusKey != 2 && this.item.NoAttendanceCount > 0) {
        c += ' warning_icon';
      }
      if (this.item.isActive) {
        c += ' opt';
      }
      return c;
    },
    vMTeacherKeyValue () {
      if (this.item) {
        this.item.MTeacherKeyValue = this.item.MTeacherKeyValue || '';
        if (this.isShowMTeaName) {
          return this.item.MTeacherKeyValue;
        } else {
          if (this.item.MTeacherKeyValue.indexOf(',') == -1) {
            return this.item.MTeacherKeyValue;
          } else {
            return this.item.MTeacherKeyValue.substring(0, this.item.MTeacherKeyValue.indexOf(',')) + '...';
          }
        }
      } else {
        return '';
      }
    },
    noAttenCount () {     // 未考勤人数
      return (this.item.ArrangedNumCount) - (this.item.ArrivedNumCount);
    },
    setStatusKeyValue () {
      let timer = this.item.ClassTime.replace(/-/g, '/') + ' ' + this.item.SClassTimeName;        // 开始时间
      let seconds = Number(Date.new(timer).getTime() / 1000) - Number(this.$utils.getSaaSClientSetInfo(31).SetContent) * 60;  // 获取当前数据的时间
      if (this.item.StatusKey == 4) {
        return '已上完';
      } else if (this.item.StatusKey == 3) {
        return '上课中';
      } else if (this.item.StatusKey == 2 && seconds < (Date.new().getTime()) / 1000) {
        return '可签到';
      } else {
        return '未开始';
      }
    }
  },
  mounted () {
    if (this.$refs.nameBox) {
      this.disabled = this.$refs.nameBox.offsetWidth < 120;
    }
  },
  methods: {
    clickItem () {
      console.log(this.item);
      this.$emit('clickItem', this.item);
    }

  }
};
</script>
