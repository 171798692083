<template>
  <div class="loop_course_dual_box">
    <!-- 班级内容 -->
    <div class="dual_box_list reference">
      <div v-if="$utils.getTextWidth(classItem.GradeClassKeyValue, 16)>236">
        <el-popover popper-class="new_popper_class"
                    placement="top"
                    width="auto"
                    trigger="hover"
                    :content="classItem.GradeClassKeyValue">
          <div slot="reference"
               class=""
               style="cursor: pointer;">
            <span>{{classItem.GradeClassKeyValue}}</span>
          </div>
        </el-popover>
      </div>
      <div v-else
           class="reference">
        <span>{{classItem.GradeClassKeyValue}}</span>
      </div>
    </div>
    <!-- 循环安排内容-->
    <div class="dual_box_list">
      <arranged-content v-for="(item,index2) in classItem.arrangedList"
                        :key="index2"
                        :arrangedItem="item"></arranged-content>
    </div>
  </div>
</template>
<script>
import arrangedContent from './arranged-content';
export default {
  data () {
    return {

    };
  },
  props: {
    classItem: Object // 列表数据

  },
  components: {
    arrangedContent
  },
  created () {

  },
  watch: {

  },
  mounted () {

  },
  methods: {

  },

  computed: {
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    }
  }
};
</script>
