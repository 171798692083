<template>
  <div @mouseenter.stop="mouseoverAfter(item)"
       @mouseleave.stop="mouseoutAfter(item)"
       :class="setClass(item)">
    <div class="file_img">
      <img class="img_box"
           :src="setImg(item)"
           alt="" />
    </div>
    <div class="file_name">
      {{ item.value }}
    </div>
    <div class="course_student_attendance_operation module_content_menuList"
         :style="{ top: '100px', left: '-18px' }"
         v-if="isShowOption">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="vTypeList"
                     @clickOptionForAlertDialog="clickAfter"></drop-down-box>
    </div>
  </div>
</template>
<script>
import dropDownBox from '../../common/drop-down-box';
export default {
  data () {
    return {
      isShowOption: false,
      typeList: [
        {
          name: '校区课件管理',
          routerName: 'campusCoursewareManagement',
          routertitle: '校区课件管理',
          auth: true,
          roleName: '',
          isShow: true,
          ModulePowerKey: 108,
          moduleName: '',
        },

        {
          name: '批量授权课件',
          routerName: 'chainWorkBench',
          auth: true,
          roleName: '',
          isShow: true,
          moduleName: 'authorizationCoursewareForm',
          ModulePowerKey: 108,
          verifyIsSignYKKey: 1
        },


      ],
      coursewareSecurityTypeList: [
        {
          name: '课件安全',
          type: 3,
          isShow: true,
          routerName: 'chainWorkBench',
          auth: true,
          roleName: '',
          moduleName: 'coursewareSecurity',
          ModulePowerKey: 111,
          verifyIsSignYKKey: 1
        },

        {
          name: '课件释放池',
          routerName: 'coursewareBagAutoreleasePool',
          auth: true,
          roleName: '',
          isShow: true,
          moduleName: '',
          ModulePowerKey: '140'
        },
        {
          name: '课件消息通知',
          routerName: '',
          auth: true,
          roleName: '',
          isShow: true,
          moduleName: 'coursewareMsgInform',
          ModulePowerKey: '148'
        },
        {
          name: '作品推荐管理',
          routerName: 'hqworksUploadManagement',
          auth: true,
          roleName: '',
          isShow: true,
          moduleName: '',
          ModulePowerKey: '151'
        },
        {
          name: '校区定位记录',
          routerName: 'campusPositioningReport',
          auth: true,
          roleName: '',
          isShow: true,
          moduleName: '',
          ModulePowerKey: '155'
        },

        {
          name: '截屏录屏通知',
          routerName: 'chainWorkBench',
          auth: true,
          roleName: '',
          isShow: true,
          moduleName: 'screenRecordList',
          ModulePowerKey: 108
        },
        {
          name: '  教学模式主题',
          routerName: 'chainWorkBench',
          auth: true,
          roleName: '',
          isShow: true,
          moduleName: 'coursewareTeachingmodeSetting',
          ModulePowerKey: 0
        },

      ],
      merchantsTypeList: [
        {
          name: '添加投资人',
          value: '添加投资人',
          routerName: 'chainWorkBench',
          routertitle: '首页',
          auth: true,
          roleName: '',
          isShow: true,
          ModulePowerKey: 135,
          moduleName: 'addClewForm',

        },
        {
          name: '招商管理',
          value: '招商管理',
          routerName: 'attractInvestmentManage',
          routertitle: '招商管理',
          auth: true,
          roleName: '',
          isShow: true,
          ModulePowerKey: 135,
          moduleName: '',
        },
        {
          name: '招商工作台',
          value: '招商工作台',
          routerName: 'merchantsCounselorWorkbench',
          routertitle: '招商顾问工作台',
          auth: true,
          roleName: '',
          isShow: true,
          ModulePowerKey: 136,
          moduleName: '',

        },
        {
          name: '签约收款',
          routerName: 'crmJoinSignManage',
          routertitle: '加盟签单管理',
          auth: true,
          roleName: '',
          value: '签约收款',
          auth: true,
          roleName: '',
          isShow: true,
          ModulePowerKey: 132,
          moduleName: '',

        },

      ],
      systemTypeList: [
        {
          name: '收支科目',
          type: 4,
          isShow: true,
          routerName: 'chainWorkBench',
          auth: true,
          roleName: '',
          moduleName: 'incomePaySubject',
          ModulePowerKey: 112
        },
        {
          name: '招商杂费设置',
          type: 4,
          isShow: true,
          routerName: 'chainWorkBench',
          auth: true,
          roleName: '',
          moduleName: 'merchantsIncidentalsSet',
          ModulePowerKey: 130
        },
        {
          name: '招商来源设置',
          type: 4,
          isShow: true,
          routerName: 'chainWorkBench',
          auth: true,
          roleName: '',
          moduleName: 'merchantsSourceSet',
          ModulePowerKey: 131
        },
        {
          name: '部门岗位',
          routerName: 'chainWorkBench',
          routertitle: '工作台',
          auth: true,
          roleName: '',
          isShow: true,
          ModulePowerKey: 113,
          moduleName: 'departmentPost'
        },
        {
          name: '角色权限',
          routerName: 'chainWorkBench',
          routertitle: '工作台',
          auth: true,
          roleName: '',
          isShow: true,
          ModulePowerKey: 114,
          moduleName: 'permissionsettingform'
        },
        {
          name: '督学设置',
          routerName: 'superintendentSet',
          routertitle: '督学设置',
          auth: true,
          isShow: true,
          roleName: '',
          ModulePowerKey: 129,
          moduleName: '',
          verifyIsSignYKKey: 1
        },
        {
          name: '直属设置',
          type: 2,
          isShow: true,
          routerName: 'chainWorkBench',
          auth: true,
          roleName: '',
          moduleName: 'directlySetting',
          ModulePowerKey: 111,
          verifyIsSignYKKey: 1
        },

      ],
      moreTypeList: [
        {
          name: '收支录入',
          routerName: 'chainWorkBench',
          auth: true,
          roleName: '',
          type: 'HQ',
          isShow: true,
          moduleName: 'incomepayentry',
          ModulePowerKey: 116,
          verifyIsSignYKKey: 1
        },
        {
          name: '收支管理',
          routerName: 'chainWorkBench',
          auth: true,
          roleName: '',
          type: 'HQ',
          isShow: true,
          moduleName: 'incomepaymanage',
          ModulePowerKey: 116,
          verifyIsSignYKKey: 1
        },
        {
          name: '收支账户',
          routerName: 'chainWorkBench',
          auth: true,
          roleName: '',
          type: 6,
          isShow: true,
          moduleName: 'incomepayAccount',
          ModulePowerKey: 116
        },

        {
          name: '操作日志',
          routerName: 'operationLogReport',
          auth: true,
          roleName: '',
          isShow: true,
          moduleName: '',
          ModulePowerKey: 154
        },
        {
          name: '学费结余汇总',
          routerName: 'tuitionBalanceReport',
          auth: true,
          roleName: '',
          isShow: true,
          moduleName: '',
          ModulePowerKey: 160
        },
        {
          name: '学费结转汇总',
          routerName: 'tuitionTransferReport',
          auth: true,
          roleName: '',
          isShow: true,
          moduleName: '',
          ModulePowerKey: 161
        },
        {
          name: '学费收入汇总',
          routerName: 'tuitionIncomeReport',
          auth: true,
          roleName: '',
          isShow: true,
          moduleName: '',
          ModulePowerKey: 162
        },
        {
          name: '校区督学统计',
          routerName: 'schoolEduReport',
          routertitle: '校区督学统计',
          auth: true,
          isShow: true,
          roleName: '',
          moduleName: '',
          ModulePowerKey: 0
        },
      ]
    };
  },
  components: {
    dropDownBox
  },
  props: {
    item: Object
  },
  computed: {
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    },
    vTypeList () {
      if (this.item.keyvalue == '01') {   //授权课件
        return this.typeList
      } else if (this.item.keyvalue == '12') {  //课件安全
        return this.coursewareSecurityTypeList
      } else if (this.item.keyvalue == '02') {   //招商加盟
        return this.merchantsTypeList
      } else if (this.item.keyvalue == '09') {   //系统设置
        return this.systemTypeList
      } else {                           //更多
        return this.moreTypeList
      }
    }
  },
  mounted () {
    document.querySelector('body').addEventListener(
      'click',
      (e) => {
        this.isShowOption = false;
      },
      false
    );
  },
  methods: {
    // 判断权限,
    isHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(
        this.vModulePowerList,
        ModulePowerKey
      );
    },
    setImg (item) {
      let roleText = '';
      if (!this.isHasModulePower(item.ModulePowerKey)) {
        roleText = '_gray';
      }
      return require('../../../../public/image/index_menu_icon' +
        item.keyvalue +
        roleText +
        '.png'); // require(item.urlPath)
      // return ''
    },
    setClass (item) {
      let roleText = '';
      if (!this.isHasModulePower(item.ModulePowerKey)) {
        roleText = 'norole_gray';
      }
      return roleText;
    },
    mouseoverAfter () {
      if (this.item.keyvalue == '07' || this.item.keyvalue == '01'
        || this.item.keyvalue == '12'
        || this.item.keyvalue == '02'
        || this.item.keyvalue == '09') {
        this.isShowOption = true;
      }
    },
    mouseoutAfter () {
      this.isShowOption = false;
    },

    clickAfter (optionItem) {
      this.isShowOption = false;
      this.$emit('clickAfter', optionItem);
    }
  }
};
</script>