import store from '../store';
import router from '../router';
import bus from '../js/bus.js';
// 新框架 打开模块
// item = {
//   routerName: 'indexView', //目标路由
//   routertitle: '工作台', //目标路由名
//   moduleName: 'taoCanSettingList' //打开的模块名 对应factory-dialog-》openDialog（moduleName,dataKey,data,callBack）
// };
// curRouterName:当前路由名
export function openRouter (item, curRouterName) {
  console.log(item, curRouterName, 'openRouter');
  let headerBarList = store.getters.headerBarList;
  let findIndex = headerBarList.findIndex(obj => {
    return obj.routerName == item.routerName;
  });
  let params = null;
  // 已打开
  if (findIndex > -1 && item.routerName == curRouterName) {
    if (item.moduleName) {
      bus.emit('openCustomDialog', item);
    } else {
      params = {
        moduleName: item.moduleName,
        dataKey: item.dataKey || 0,
        data: item.data || {},
        name: item.name
      };
      router.push({
        name: item.routerName,
        query: {
          t: Date.now()
        },
        params: params
      });
    }
    return;
  }
  // let findCurRouterObj = headerBarList.findIndex(obj => {
  //   return obj.routerName == curRouterName;
  // });
  // } else if (findIndex > -1 && item.routerName != curRouterName) {
  // } else if (findIndex == -1) {
  //   // 未打开过
  //   // 判断是否还可继续打开（窗口大小问题 不可换行）
  //   let headerWidth = document.getElementsByClassName('head_bar')[0].clientWidth;
  //   let oneHeaderModuleBoxtwidth = document.getElementsByClassName('header_fixed_ul')[0].children[0].clientWidth;
  //   let headerModuleBoxtNum = document.getElementsByClassName('header_fixed_ul')[0].children.length;
  //   let headerModuleBoxtWidth = oneHeaderModuleBoxtwidth * headerModuleBoxtNum;
  //   let oneHeaderModuleBoxtRatio = oneHeaderModuleBoxtwidth / headerWidth;
  //   let canOpenBoxratio = headerModuleBoxtWidth / headerWidth;
  //   if (
  //     (canOpenBoxratio + oneHeaderModuleBoxtRatio >= 0.6 && headerWidth >= 1900) ||
  //     (canOpenBoxratio + oneHeaderModuleBoxtRatio >= 0.53 && headerWidth >= 1600 && headerWidth < 1900) ||
  //     (canOpenBoxratio + oneHeaderModuleBoxtRatio >= 0.47 && headerWidth >= 1200 && headerWidth < 1600)
  //   ) {
  //     layer.alert(
  //       '当前打开窗口太多' +
  //         '最多只能同时打开' +
  //         headerBarList.length +
  //         '个窗口。请先关闭一些窗口。'
  //     );
  //     return false;
  //   }
  //   let routerItem = {
  //     openTime: Date.new(),
  //     routerName: item.routerName,
  //     isCanClose: true,
  //     titleName: item.routertitle || '',
  //     isSelected: true,
  //     iconClass:
  //       item.routerName == 'mendiansettingform'
  //         ? 'icon_mendian'
  //         : item.routerName == 'allStudent'
  //           ? 'icon_allStudent'
  //           : item.routerName == 'wktBack'
  //             ? 'icon_wkt'
  //             : item.routerName == 'recruitStudentShow'
  //               ? 'icon_zsx'
  //               : item.routerName == 'growthTime'
  //                 ? 'icon_gtime'
  //                 : 'icon_view'
  //   };
  //   headerBarList.push(routerItem);
  // }

  // 已打开 但被隐藏 或 未打开 变更isSelected值（选中效果）
  // headerBarList.forEach(obj => {
  //   if (obj.routerName != item.routerName) {
  //     obj.isSelected = false;
  //     if (obj.index >= findCurRouterObj.index) {
  //       obj.index -= 1;
  //     }
  //   } else {
  //     obj.isSelected = true;
  //     obj.index = headerBarList.length;
  //   }
  // });

  if (
    item.moduleName == 'signuppayment' ||
    item.moduleName == 'newschedulecourse'
  ) {
    params = {
      moduleName: item.moduleName,
      dataKey: item.dataKey || 0,
      data: {
        dataInfo: item.data || {}
      },
      name: item.name
    };
  } else {
    params = {
      moduleName: item.moduleName,
      dataKey: item.dataKey || 0,
      data: item.data || {},
      name: item.name
    };
  }
  router.push({
    name: item.routerName,
    params: params
    // replace: true
  });
  document.documentElement.scrollTop = 0;
}
// 打开
export function open (curEl, dialogData) {
  console.log(dialogData, '当前单例弹窗');
  if (curEl.$options && curEl.$options._componentTag) {
    let componentTag = curEl.$options._componentTag;
    let nonRegister = false;
    if (componentTag === 'custom-dialog' || componentTag === 'teaching-mode-custom-dialog') {
      let childrentNodes = curEl.$children;
      for (let i = 0; i < childrentNodes.length; i++) {
        const nodeEl = childrentNodes[i];
        if (nodeEl.$options._componentTag === 'dialog-factory-common') {
          // 未注册过组件
          if (
            nodeEl.registerDialogList.findIndex(dialogName => {
              return dialogName == dialogData.moduleName;
            }) >= 0
          ) {
            nonRegister = true;
            nodeEl.openDialogPlus(
              dialogData.moduleName,
              dialogData.dataKey,
              dialogData.data,
              dialogData.callBack,
              dialogData.name
            );
          } else {
            open(curEl.$parent, dialogData);
          }
          break;
        }
        if (i == childrentNodes.length && !nonRegister) {
          bus.emit('openCommonCustomDialog', dialogData);
        }
      }
    } else {
      open(curEl.$parent, dialogData);
    }
  } else {
    bus.emit('openCommonCustomDialog', dialogData);
  }
}
// 注册 奖需要打开的弹窗注册到最近的custom-dialog下的dialog-factory-common
export function register (curEl, dialogList) {
  if (curEl) {
    let componentTag = curEl.$options._componentTag;
    if (componentTag === 'custom-dialog' || componentTag === 'teaching-mode-custom-dialog') {
      let childrentNodes = curEl.$children;
      childrentNodes.forEach(node => {
        if (
          node.$options &&
          node.$options._componentTag === 'dialog-factory-common'
        ) {
          // console.log('register:node', node);
          dialogList.forEach(moduleName => {
            let isRegister = node.registerDialogList.find(val => {
              return val == moduleName;
            });
            if (!isRegister) {
              node.registerDialogList.push(moduleName);
            }
          });
        }
      });
    } else {
      register(curEl.$parent, dialogList);
    }
  }
}
