<template>
  <!-- 品牌录入 -->
  <div>
    <div class="form_info_edit form_info_list">
      <input-date v-model="dataJson.EntryTime"
                          :formTitle="'获取日期'"
                          :readonly="true"
                          :elementId="'BirthdayTime'"
                          :maxDate="$utils.formatDateToLine(Date.new())"
                          :required="false"
                          :formPlaceholder="'必填'"
                          @changeDate="changeDate"></input-date>
      <input-text :formTitle="'招商顾问'"
                    :required="false"
                    :readonly="true"
                    :formPlaceholder="'请选择'"
                    v-model="dataJson.CounselorKeyValue"
                    ></input-text>
      <input-text :formTitle="'姓名'"
                  :required="isReadonlyType?false:true"
                  :readonly="isReadonlyType"
                  v-model="dataJson.MainDemoName"></input-text>

      <input-phone :formTitle="'手机号'"
                 :required="isReadonlyType?false:true"
                   :readonly="isReadonlyType"
                   v-model="dataJson.MobilePhone"></input-phone>

      <input-dialog :formTitle="'来源方式'"
                      :required="isReadonlyType?false:true"
                    :readonly="dataInfo.SourceTypeKey==1?true:isReadonlyType"
                    :formPlaceholder="'请选择'"
                    :value="dataJson.SourceTypeKeyValue"
                    @showEldilog="openSourceTypeSet"></input-dialog>
      <input-option-text :formTitle="'性别'"
                        :readonly="isReadonlyType"
                           :required="isReadonlyType?false:true"
                        :selectList="sexList"
                        :selectedInfo="vSexInfo"
                        @change="chgSexType">
      </input-option-text>
      <!-- <input-age :formTitle="'年龄'"
                 :required="true"
                 :readonly="isReadonly"
                 v-model="dataJson.Age"></input-age> -->
      <input-option-text :formTitle="'意向级别'"
                        :readonly="isReadonlyType"
                        :required="false"
                        :selectList="intentionList"
                        :selectedInfo="vIntentionInfo"
                        @change="chgIntentionType">
      </input-option-text>
      <input-dialog :formTitle="'投资预算'"
                    :required="false"
                    :readonly="isReadonlyType"
                    :formPlaceholder="'请选择'"
                    :value="vBudgetAmount"
                    @showEldilog="isShowInvestmentsBudget=true"></input-dialog>

      <province-and-city-input :formTitle="'计划城市'"
                               :required="false"
                               :readonly="isReadonlyType"
                               :level="2"
                               :value="vlocationInfo"
                               :selectedInfo="locationInfo"
                               @selectedItem="changeRegion"></province-and-city-input>

      <input-select :formTitle="'加盟方式'"
                    :id="'id'"
                    :name="'name'"
                    :required="false"
                    :readonly="isReadonlyType"
                    :dataList="franchiseList"
                    :selectedInfo="vFranchiseInfo"
                    @selectedItem="chgFranchiseType"></input-select>
      <input-option-text :formTitle="'教育从业经历'"
                        :readonly="isReadonlyType"
                        :readonlyValue="vExperienceValue"
                        :required="false"
                        :selectList="experienceTypeList"
                        :selectedInfo="vExperienceTypeInfo"
                        @change="chgExperienceTypeForJob">
      </input-option-text>
      <input-option-text  v-if="dataJson.JobExperienceKey>0"
                        :formTitle="'从事岗位'" 
                        :readonly="isReadonlyType"
                        :required="false"
                        :selectList="experienceList"
                        :selectedInfo="vExperienceInfo"
                        @change="chgExperienceType">
      </input-option-text>
      <input-option-text v-if="dataJson.JobExperienceKey>0"
                        :formTitle="'门店经营'"
                        :readonly="isReadonlyType"
                        :required="false"
                        :selectList="jobStoreList"
                        :selectedInfo="vJobStoreInfo"
                        @change="chgJobStoreType">
      </input-option-text>

      <!-- <input-text v-if="dataJson.JobExperienceKey>0"
                  :formTitle="'课程类型'"
                  :required="false"
                  :readonly="isReadonlyType"
                  v-model="dataJson.JobCourseTypeNames"></input-text> -->

     
      <input-select :formTitle="'门店面积'" v-if="dataJson.JobExperienceKey>0"
                    :id="'id'"
                    :name="'name'"
                    :required="false"
                    :readonly="isReadonlyType"
                    :dataList="JobStoreAreaList"
                    :selectedInfo="vJobStoreAreaInfo"
                    @selectedItem="chgJobStoreAreaType"></input-select>
      <!-- <input-number v-if="dataJson.JobExperienceKey>0"
                    :formTitle="'门店面积'"
                    :required="false"
                    :readonly="isReadonly"
                    v-model="dataJson.JobStoreArea"></input-number> -->
       <input-select :formTitle="'教室数量'" v-if="dataJson.JobExperienceKey>0"
                    :id="'id'"
                    :name="'name'"
                    :required="false"
                    :readonly="isReadonlyType"
                    :dataList="gradeclassList"
                    :selectedInfo="vGradeclassInfo"
                    @selectedItem="chgGradeclassType"></input-select>
      <!-- <input-number v-if="dataJson.JobExperienceKey>0"
                    :formTitle="'教室数量'"
                    :required="false"
                    :readonly="isReadonly"
                    v-model="dataJson.JobClassRoomCount"></input-number> -->

      <input-text-area :formTitle="'个人描述'"
                  :required="false"
                  :isWrap="true"
                  :readonly="isReadonlyType"
                  v-model="dataJson.Remark"></input-text-area>
    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group v-if="!isReadonlyType"
                           :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
    <div class="confirm_btn btn_fixed_bottom" v-else-if="isReadonlyType&&!isHideEditBtn" @click.stop="chgisReadonlyType">
        <div class="btn_fixed_blue btn_hover_bg_blue"
             >+ 完善信息</div>
      </div>
    <custom-dialog title="投资预算"
                   class="customer_dialog defult_dialog"
                   :visible.sync="isShowInvestmentsBudget"
                   :before-close="closeDialog">
      <investments-budget-form :dataInfo="dataJson"
                               @afterSuccess="changeInvestmentsBudget"
                               @cancelClick="closeDialog"></investments-budget-form>
    </custom-dialog>
  </div>
</template>

<script>

import {
  hqGetAttractThreadMsg,
  hqCompleteAttractMsg,
  hqAttractInvestmentSourceSet,
} from '../../../../API/workbench.js'
import investmentsBudgetForm from '../../../head-quarters/headquarters-management/merchants-counselor-workbench/form/investments-budget-form'
import provinceAndCityInput from '../../../head-quarters/headquarters-management/merchants-counselor-workbench/form/province-and-city-input'

// import InputTextArea from '../../../../base-component/input-text-area.vue'
export default {
  components: {
    investmentsBudgetForm,
    provinceAndCityInput
    // InputTextArea,
  },
  data() {
    return {
      isReadonlyType:false,
      sexList: [
        {
          id: 1,
          name: '男',
        },
        {
          id: 2,
          name: '女',
        },
      ], // 来源1-抖音;2-百度;3-转介绍
      intentionList: [
        {
          id: 1,
          name: 'S',
        },
        {
          id: 2,
          name: 'A',
        },
        {
          id: 3,
          name: 'B',
        },
        {
          id: 4,
          name: 'C',
        },
      ],
      franchiseList: [
        {
          id:2,
          name: '整店加盟',
        },
        {
          id: 1,
          name: '课程加盟',
        },
      ],
      JobStoreAreaList: [
        {
          id:1,
          name: '100以内',
        },
        {
          id: 2,
          name: '100-300',
        },
         {
          id: 3,
          name: '300-500',
        },
         {
          id: 4,
          name: '500以上',
        },
      ],
       gradeclassList: [
        {
          id:1,
          name: '1',
        },
        {
          id: 2,
          name: '2-4',
        },
        {
          id:3,
          name: '5-7',
        },
        {
          id: 4,
          name: '8以上',
        },
      ],
      experienceTypeList: [
        // 0无，1校长;2-老师;3-销售
        {
          id: 1,
          name: '有',
        },
        {
          id: 0,
          name: '无',
        }
      ],
      experienceList: [
        // 0无，1校长;2-老师;3-销售
        {
          id: 1,
          name: '校长',
        },
        {
          id: 2,
          name: '老师',
        },
        {
          id: 3,
          name: '销售',
        },
        {
          id: 4,
          name: '其他',
        },
      ],

      jobStoreList: [
        // 0无，1校长;2-老师;3-销售
        {
          id: 1,
          name: '营业中',
        },
        {
          id: 2,
          name: '已停业',
        },
      ],
      sourceList: [], // 来源方式列表
      // 提交数据对象
      dataJson: {
        OLAPKey: '', // "投资线索key",
        MainDemoName: '', // "投资人姓名",
        Age: 0,
        CounselorKey:'',
        CounselorKeyValue:'',
        EntryTime:'',
        SexKey: '', // "性别",
        SexKeyValue: '', // "1-男;2-女",
        MobilePhone: '', // "手机号",
        IntentionKey: '1', // "意向级别",
        IntentionKeyValue: 'S', // "1-S;2-A;3-B;4-C",
        StratBudgetAmount: '', // "投资预算起",
        EndBudgetAmount: '', // "投资预算止",
        SourceTypeKey: '', // "来源方式Key"
        SourceTypeKeyValue: '', // "来源方式"
        FranchiseTypeKey: '', // "加盟方式",
        FranchiseTypeKeyValue: '', // "1-单店;2-课程",
        JobExperienceKey: '', // "从业经历",
        JobExperienceKeyValue: '', // "0-无;1-校长;2-老师;3-销售",
        JobCourseTypeIDS: '', // "从业类型ids",
        JobCourseTypeNames: '', // "从业类型",
        JobStoreStatusKey: '', // "从业门店经营状况",
        JobStoreStatusKeyValue: '', // "1-营业中;2-已停业",
        JobStoreArea: '', // "从业门店面积",
        JobClassRoomCount: '', // "从业教室数",
        Remark: '', // "个人描述",
        CityKey: '', // "计划城市",
        CityKeyValue: '',
        ProvinceKey: '', // "计划省份",
        ProvinceKeyValue: '',
        JobStoreAreaKey:'',//"从业门店面积 1-100以内;2-100-300;3-300-500;4-500以上"
        JobStoreAreaKeyValue:'',//"从业门店面积Value"
        JobClassRoomKey:'',//"从业教室数  1-1;2-2-4;3-5-7;4-大于8"
        JobClassRoomKeyValue:'',//"从业教室数Value"
      },
      locationInfo: [], // 地址
      isShowInvestmentsBudget: false,
      JobObj:{
        id:1,
        name:'有'
      }

    }
  },
  props: {
    dataInfo: Object, // 职务信息
    isReadonly: Boolean, // 是否只读
    isHideEditBtn:Boolean  //是否显示完善投资人按钮
  },
  created() {
    this.isReadonlyType=this.isReadonly||false
    if (this.dataInfo) {
      this.getDataInfo(this.dataInfo.OLAPKey)
    }
  },
  mounted() {
    this.$dialog.register(this, ['singleSelectionList'])
  },
  computed: {
    vlocationInfo() {
      if (this.dataJson.ProvinceKey > 0) {
        return this.dataJson.ProvinceKeyValue + '-' + this.dataJson.CityKeyValue
      } else {
        return ''
      }
    },
    // 性别
    vSexInfo() {
      return {
        id: Number(this.dataJson.SexKey),
        name: this.dataJson.SexKeyValue,
      }
    },
    // 意向级别
    vIntentionInfo() {
      return {
        id: Number(this.dataJson.IntentionKey),
        name: this.dataJson.IntentionKeyValue,
      }
    },
    vFranchiseInfo() {
      return {
        id: Number(this.dataJson.FranchiseTypeKey),
        name: this.dataJson.FranchiseTypeKeyValue,
      }
    },
    vExperienceTypeInfo(){
      if(this.dataJson.JobExperienceKey==0){
         return {
          id: Number(this.dataJson.JobExperienceKey),
          name: '无',
        }
      }else{
         return this.JobObj
      }
      
    },
    vExperienceInfo() {
      return {
        id: Number(this.dataJson.JobExperienceKey),
        name: this.dataJson.JobExperienceKeyValue,
      }
    },
    vExperienceValue(){
      if(Number(this.dataJson.JobExperienceKey)==0){
        return '无'
      }else if(this.dataJson.JobExperienceKey<4){
        return '当过'+this.dataJson.JobExperienceKeyValue
      }else{
        return this.dataJson.JobExperienceKeyValue
      }
    },
    // 门店经营
    vJobStoreInfo() {
      return {
        id: Number(this.dataJson.JobStoreStatusKey),
        name: this.dataJson.JobStoreStatusKeyValue,
      }
    },
    // 教室
    vGradeclassInfo() {
      let value = ''
      if(this.dataJson.JobClassRoomKeyValue){
        if(this.dataJson.JobClassRoomKey==4){
          value= '大于8 个教室'
        }else{
          value= this.dataJson.JobClassRoomKeyValue+' 个教室'
        }
      }
      return {
        id: Number(this.dataJson.JobClassRoomKey),
        name: value,
      }
    },
    // 门店面积
    vJobStoreAreaInfo() {
      return {
        id: Number(this.dataJson.JobStoreAreaKey),
        name: this.dataJson.JobStoreAreaKeyValue?this.dataJson.JobStoreAreaKeyValue+' 平方米':'',
      }
    },
    vBudgetAmount() {
      if (
        Number(this.dataJson.StratBudgetAmount) > 0 &&
        Number(this.dataJson.EndBudgetAmount) > 0
      ) {
        return (
          Number(this.dataJson.StratBudgetAmount) +
          '~' +
          Number(this.dataJson.EndBudgetAmount) +
          '万'
        )
      } else {
        return ''
      }
    },
  },
  methods: {
    // 获取来源方式
    openSourceTypeSet() {
      hqAttractInvestmentSourceSet().then(
        (result) => {
          this.sourceList = result.data.filter((obj) => {
            return obj.StatusKey == 1 && obj.OLAPKey != 1
          }) // 云课项不显示，不管理
          this.$dialog.open(this, {
            routerName: this.$route.name,
            name: '选择来源方式',
            moduleName: 'singleSelectionList',
            data: {
              selectedKey: this.dataJson.SourceTypeKey,
              dataSourceList: this.sourceList,
              required: false,
              ModulePowerKey: 0,
            },
            callBack: {
              onSelectedItem: (data) => {
                this.dataJson.SourceTypeKey = data.OLAPKey
                this.dataJson.SourceTypeKeyValue = data.MainDemoName
              },
            },
          })
        },
        (err) => {
          layer.alert(err.msg)
        }
      )
    },
    closeDialog() {
      this.isShowInvestmentsBudget = false
    },
    changeInvestmentsBudget(item) {
      this.dataJson.StratBudgetAmount = item.StratBudgetAmount
      this.dataJson.EndBudgetAmount = item.EndBudgetAmount
      this.closeDialog()
    },
    // 选择意向
    chgIntentionType(item) {
      this.dataJson.IntentionKey = item.id
      this.dataJson.IntentionKeyValue = item.name
    },
    // 选择加盟方式
    chgFranchiseType(item) {
      this.dataJson.FranchiseTypeKey = item.id
      this.dataJson.FranchiseTypeKeyValue = item.name
    },
    // 选择教室数量
    chgGradeclassType(item) {
      this.dataJson.JobClassRoomKey = item.id
      this.dataJson.JobClassRoomKeyValue = item.name
    },
    // 选择门店面积
    chgJobStoreAreaType(item) {
      this.dataJson.JobStoreAreaKey = item.id
      this.dataJson.JobStoreAreaKeyValue = item.name
    },
    // 教育从业经历
    chgExperienceTypeForJob(item){
      this.dataJson.JobExperienceKey = item.id
      this.dataJson.JobExperienceKeyValue = item.id==1?'校长':""
      if(Number(this.dataJson.JobExperienceKey)==0){
        this.dataJson.JobStoreStatusKey = ''
        this.dataJson.JobStoreStatusKeyValue = ''
        this.dataJson.JobStoreStatusKey = ''
        this.dataJson.JobStoreStatusKeyValue =''
        this.dataInfo.JobCourseTypeNames=''
      }
    },
    // 选择从业经历
    chgExperienceType(item) {
      this.dataJson.JobExperienceKey = item.id
      this.dataJson.JobExperienceKeyValue = item.name
    },
    // 选择门店营业
    chgJobStoreType(item) {
      this.dataJson.JobStoreStatusKey = item.id
      this.dataJson.JobStoreStatusKeyValue = item.name
    },
    getDataInfo(OLAPKey) {
      hqGetAttractThreadMsg(OLAPKey).then(
        (result) => {
          // Object.keys(this.dataJson).forEach((key) => {
          //   this.dataJson[key] = result.data[key]
          // })
          this.dataJson = result.data;
          console.log(this.dataJson, 'hqGetAttractThreadMsg')
          this.locationInfo = [result.data.ProvinceKey, result.data.CityKey]
          this.dataJson.EntryTime=this.dataJson.EntryTime.substring(0,10)
          if(Number(this.dataJson.IntentionKey)==0){
            this.dataJson.IntentionKey='1'
            this.dataJson.IntentionKeyValue='S'
          }
        },
        (error) => {
          layer.alert(error.msg)
        }
      )
    },
    changeDate() {},

    // 选择性别
    chgSexType(item) {
      console.log(item,'chgSexType')
      this.dataJson.SexKey = item.id
      this.dataJson.SexKeyValue = item.name
    },
    // 省市区
    changeRegion(keys, values) {
      console.log('changeRegion', keys, values)
      this.dataJson.ProvinceKey = keys[0] || '' // 省
      this.dataJson.ProvinceKeyValue = values[0] || '' // 省
      this.dataJson.CityKey = keys[1] || '' // 市
      this.dataJson.CityKeyValue = values[1] || '' // 市
    },
    verifyData() {
      let flag = false
      if (!this.dataJson.MainDemoName) {
        layer.alert('请输入姓名')
        flag = true
        return flag
      }
      if (!this.dataJson.MobilePhone) {
        layer.alert('请输入手机号')
        flag = true
        return flag
      }
      // if (!this.dataJson.SourceTypeKey) {
      //   layer.alert('请选择来源方式')
      //   flag = true
      //   return flag
      // }
      if (this.dataJson.MobilePhone.length != 11) {
        layer.alert('手机号码必须为11位数')
        flag = true
      }
      if (!this.dataJson.SexKey || this.dataJson.SexKey == 0) {
        layer.alert('请选择性别')
        flag = true
        return flag
      }
      // if (Number(this.dataJson.Age) == 0) {
      //   layer.alert('请输入年龄')
      //   flag = true
      //   return flag
      // }
      // if (!this.dataJson.IntentionKey) {
      //   layer.alert('请选择意向级别')
      //   flag = true
      //   return flag
      // }
      // if (this.vBudgetAmount == '') {
      //   layer.alert('请输入投资预算')
      //   flag = true
      //   return flag
      // }
      // if (!this.dataJson.CityKeyValue) {
      //   layer.alert('请选择城市')
      //   flag = true
      //   return flag
      // }
      // if (
      //   !this.dataJson.FranchiseTypeKey ||
      //   this.dataJson.FranchiseTypeKey == 0
      // ) {
      //   layer.alert('请选择加盟方式')
      //   flag = true
      //   return flag
      // }
      return flag
    },
    chgisReadonlyType(){
      this.isReadonlyType=false
      console.log('chgisReadonlyType',this.isReadonlyType)
    },
    // 确认按钮
    confirmClick(callBack) {
      if (this.verifyData()) {
        if (callBack) {
          callBack()
        }
        return false
      }
      hqCompleteAttractMsg(this.dataJson)
        .then(
          (result) => {
            this.$emit('afterSuccess', result.data)
            this.cancelClick()
            layer.alert('完善成功')
          },
          (error) => {
            layer.alert(error.msg)
          }
        )
        .finally(() => {
          if (callBack) {
            callBack()
          }
        })
    },
    cancelClick() {
      this.$emit('cancelClick')
    },
  },
}
</script>

<style>
.el-icon-arrow-right.el-cascader-node__postfix {
  right: 20px;
}
</style>