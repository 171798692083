import {
  getCourseTypeNameList,
  getUsingClass,
  getTeacherList
} from '../API/basic-data-api';
import store from '../store';

var lock = {};
var basicData = {
  // 调用课类课名列表接口
  async loadCourseNameList (isOpenThrow) {
    let courseNameList = store.getters.courseNameList;
    if (courseNameList == null) {
      if (!lock.course) {
        lock.course = true;
        let result = await getCourseTypeNameList();
        lock.course = false;
        if (result.code == 0) {
          store.commit('setCourseNameList', result.data);
          courseNameList = store.getters.courseNameList;
        } else {
          if (isOpenThrow) {
            throw result;
          } else {
            courseNameList = [];
          }
        }
      }
    }
    return JSON.parse(JSON.stringify(courseNameList));
  },
  async loadGradeClassList (isOpenThrow) {
    let gradeClassList = store.getters.gradeClassList;
    if (gradeClassList == null) {
      if (!lock.gradeClass) {
        lock.gradeClass = true;
        let result = await getUsingClass();
        lock.gradeClass = false;
        if (result.code == 0) {
          store.commit('setGradeClassList', result.data);
          gradeClassList = store.getters.gradeClassList;
        } else {
          if (isOpenThrow) {
            throw result;
          } else {
            gradeClassList = [];
          }
        }
      }
    }

    return JSON.parse(JSON.stringify(gradeClassList));
  },
  async loadTeacherList (isOpenThrow) {
    let teacherList = store.getters.teacherList;
    if (teacherList == null) {
      if (!lock.teacher) {
        lock.teacher = true;
        let result = await getTeacherList();
        lock.teacher = false;
        if (result.code == 0) {
          store.commit('setTeacherList', result.data);
          teacherList = store.getters.teacherList;
        } else {
          if (isOpenThrow) {
            throw result;
          } else {
            teacherList = [];
          }
        }
      }
    }
    return JSON.parse(JSON.stringify(teacherList));
  }
};
export default basicData;
