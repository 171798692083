<template>
  <div style="border: 1px solid #f2f3f8">
    <div class="workbench_view_attendance" style="margin-bottom: 5px">
      <div
        class="form_info_field workbench_view_title_gray"
        style="border: none"
      >
        <div style="flex: 1; font-size: 16px; color: #333">
          考勤课消
          <span class="font_gray" style="font-size: 12px; margin-left: 10px"
            >打考勤后，系统自动课消、结转、统计</span
          >
        </div>
        <!-- <input-form-tip :tips="'统计每天上课节数，考勤数据，方便查看每天的上课考勤情况，也可以对每节课的考勤数据进行操作。'"></input-form-tip> -->
        <div class="workbench_view_title_btn_list">
          <div class="workbench_view_title_btn" @click="searchKQData('pre')">
            上一页
          </div>
          <div
            class="workbench_view_title_btn"
            :class="isToday ? 'opt' : ''"
            @click="searchKQData('today')"
          >
            今天
          </div>
          <div class="workbench_view_title_btn" @click="searchKQData('next')">
            下一页
          </div>
          <div
            class="workbench_view_title_btn"
            @click.stop="doAfterClickFunctionBtn(coursetableInfo)"
            v-if="isIndexView"
          >
            课程表
          </div>
        </div>
        <div
          class="workbench_view_title_btn_list"
          style="justify-content: flex-end; align-items: center"
        >
          <el-checkbox
            v-model="commentCheck"
            @change="chgcommentCheck"
            style="color: #333; margin-right: 10px"
            >课评</el-checkbox
          >
          <div
            class="workbench_view_title_btn"
            @click.stop="doAfterClickFunctionBtn(banjiInfo)"
            v-if="isIndexView"
          >
            班级排课
          </div>
          <div
            class="workbench_view_title_btn"
            @click.stop="doAfterClickFunctionBtn(kechengInfo)"
            v-if="isIndexView"
          >
            课程管理
          </div>
          <div
            class="workbench_view_title_btn pr"
            v-if="isIndexView"
            @click.stop="showdropDownBox"
          >
            更多
            <div
              class="module_content_menuList"
              :style="{ top: '24px', left: '-50px' }"
              v-show="isShowdropDownBox"
              @mouseover.stop="isShowdropDownBox = true"
            >
              <div class="top_bg"></div>
              <drop-down-box
                :optionJsonList="moreBtnList"
                @clickOptionForAlertDialog="clickAfter"
              ></drop-down-box>
            </div>
          </div>
        </div>
        <!-- <div class="workbench_view_title_calendar fr">日历
          <input-date class="new_index_view_date_box"
                      :formTitle="''"
                      :popperClass="'course_date_el'"
                      
                      ref="inputDate"
                      @changeDate="changeDay"></input-date>
        </div> -->
      </div>
      <div class="workbench_view_attendance_content clearfix">
        <!-- <div class="swiper-container swiper_attendance_content">
          <div class="swiper-wrapper"> -->
        <kq-item
          v-for="(item, key) in kqList"
          :key="key"
          :item="item"
          :commentCheck="commentCheck"
          @chgCoureDateAfter="chgCoure"
        ></kq-item>
        <!-- </div>
        </div> -->
      </div>
    </div>
    <slot></slot>
    <div
      class="workbench_view_todaySign"
    
      style="padding-left: 20px"
    >
    <!--   v-show="vHasModulePower" -->
      <div class="clearfix form_info_field workbench_view_todaySign_top">
        <div
          class="todaySign_fr fl"
          style="font-size: 12px; line-height: 50px; flex: 1"
        >
          <span class="fl" style="font-size: 12px; line-height: 50px;color:#333"
            >快捷签到：
          </span>
          <span class="" style="color:#999">课前</span>
          <span class="font_blue"
            >{{ Number($utils.getSaaSClientSetInfo(31).SetContent) }}分钟</span
          >
          <span class="" style="color:#999">，点击学生名即可快捷签到</span>
        </div>

        <!-- <div class="workbench_view_title_sign fr"
             :class="!abnormalSign?'hide_all':'show_more'"
             @click.stop="showAbnormalSign">
          <span>异常签到
            <input-form-tip :tips="'使用自动考勤模式产生的不可识别的考勤数据，如小朋友多次刷脸，是否多次上课！'"></input-form-tip>
          </span>
          <span class="font_red">{{vPageDataList.length}}</span>
          <span class="font_gray">人次</span>
        </div> -->
        <div class="search_blook_box" style="flex: 1">
          <input-search-contain-btn
            v-model="textSearch"
            :placeholder="'搜索'"
            :clearAll="true"
            @clearSearch="textSearch == ''"
            @onSearchEvent="searchStudent"
          ></input-search-contain-btn>
          <!-- <div class="workbench_view_title_search fl">
             
            <input type="text"
                   placeholder="请输入姓名、姓名首字母、手机号"
                   v-model="textSearch">
            <span v-show="textSearch"
                  class="home_remove_btn"
                  style=""
                  @click="textSearch=''"></span>
          </div> -->
          <!-- <div class="workbench_view_title_notebook fl"
               @click="showDialog('attendancebook')"></div> -->
        </div>
        <div
          class="workbench_view_title_btn_list sign_btn_list"
          style="flex: 1"
        >
          <div
            class="sign_btn"
            @click="showDialog('attendancebook')"
            v-if="isIndexView"
          >
            签到本
          </div>
          <div
            class="sign_btn pr"
            v-if="isIndexView"
            @mouseenter="showFaceStatus(true)"
            @mouseleave="showFaceStatus(false)"
          >
            刷脸签到
            <div
              class="face_sign_popup pa"
              style="top: 17px"
              v-show="faceStatus"
            >
              <function-btn
                v-if="isOpenFaceStatus"
                :item="openFace"
                @clickItem="openPeopleFaceDiscem"
                :className="'popup_list'"
              >
                开启刷脸签到
              </function-btn>
              <function-btn
                v-else
                :item="openFace"
                @clickItem="stopMediaStreamTrack"
                :className="'popup_list'"
              >
                关闭刷脸签到
              </function-btn>
              <function-btn
                :item="saveFace"
                @clickItem="openSavePeopleFaceDiscem"
                :className="'popup_list'"
              >
                面容信息管理
              </function-btn>
            </div>
          </div>
        </div>
      </div>
      <!-- show_sign_box 显示异常签到需要的类名 -->
      <div :class="abnormalSign ? 'show_sign_box' : ''">
        <div class="workbench_view_todaySign_content">
          <!-- <div class="couser_used_block clearfix"
               v-if="vEndInfo.count!=0"
               @click.stop="upOrDownEndCourseList">
            <div class="fl pr"
                 style="overflow:visible"
                 v-if="!isShowEndList">
          
              <div class="fl"
                   style="max-width:100%">
                <span ref="tooltipBox1">已上完
                  <span class="font_black">{{vEndInfo.count}}</span>节课
               
                </span>
              </div>
         
            </div>

            <div class="fl"
                 v-if="isShowEndList">已展开
              <span class="font_black">{{vEndInfo.count}}</span>节已完结的课</div>
            <div class="fr"
                 :class="isShowEndList?'donw':'up'">{{!isShowEndList?'查看全部':'恢复隐藏'}}</div>
          </div> -->
          <div
            v-if="vcourseList.length > 0"
            class="couser_block_ul"
            :class="{ height_top: vEndInfo.count == 0 }"
            :style="
              vcourseList.length == 0 && abnormalSign
                ? 'border-right: 1px solid #ececec;'
                : ''
            "
          >
            <div class="couser_block_ul_left pr">
              <div class="couser_block_ul_list" :style="{top:listTop+'px'}">
                 <div
                class="couser_block_ul_left_item"
                v-for="(item, key) in vTimeList"
                :key="key"
                :class="item.isDateActive?'opt':''"
                @click.stop="clickTimescope(item)"
              >
              <!-- :class="setTimeClass(item)" -->
                {{ setClassTimeName(item) }}-{{ setTimeCount(item) }}
              </div>
              </div>
             
            </div>
            <div style="flex: 1">
              <coursesign
                ref="coursesign"
                v-for="item in vcourseList"
                :key="item.OLAPKey"
                :textSearch="textSearch"
                :item="item"
                :commentCheck="commentCheck"
                @attendanceArrange="showArrange"
              ></coursesign>
              <div :style="{height:contentHeight+'px'}">

              </div>
            </div>
          </div>
          <!-- <div v-if="vcourseList.length==0&&(vStartInfo.count!=0||vEndInfo.count!=0)&&!isShowStartList&&!isShowEndList"
               class="workbench_view_todaySign_nodata_01"
               :class="{height_top:vEndInfo.count==0}"
               style="cursor: default">暂无签到中的课。</div>
          <div class="couser_untapped_block clearfix"
               @click="isShowStartList=!isShowStartList"
               v-if="vStartInfo.count!=0">
            <div class="fl pr"
                 style="overflow:visible"
                 v-if="!isShowStartList">

              <div class="fl"
                   style="max-width:100%">
                <span ref="tooltipBox2">未开始
                  <span class="font_black">{{vStartInfo.count}}</span>节课
            
                </span>
              </div>
         
            </div>
            <div class="fl"
                 v-if="isShowStartList">已展开
              <span class="font_black">{{vStartInfo.count}}</span>节未开始的课</div>
            <div class="fr"
                 :class="isShowStartList?'up':'donw'">{{!isShowStartList?'查看全部':'恢复隐藏'}}</div>
          </div> -->
          <div
            class="workbench_view_todaySign_nodata_02"
            :class="{ height_top: vEndInfo.count == 0 }"
            v-if="courseList.length == 0"
          >
            今天还没有安排课程哦!!
          </div>
        </div>
        <!-- 异常签到列表 -->

        <div class="workbench_view_abnormalSign_content" v-show="abnormalSign">
          <div v-for="item in vAbnormalSignList" :key="item.CreateTime">
            <div class="abnormalSign_title">
              {{ setCreateTime(item) }}
            </div>
            <div class="abnormalSign_ul">
              <abnormal-sign-pool
                v-for="abnormalItem in item.PageDataList"
                :key="abnormalItem.OLAPKey"
                :abnormalItem="abnormalItem"
                @clickItemAfter="seletedAbnormalItem"
              ></abnormal-sign-pool>
            </div>
          </div>
          <div
            class="course_block_nodata"
            style="padding-bottom: 20px; margin-top: 110px"
            v-if="vAbnormalSignList.length == 0"
          >
            <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
          </div>
        </div>
      </div>
    </div>

    <custom-show-dialog
      title="刷脸签到"
      width="715px"
      class="popup_dialog_withe"
      :visible.sync="isShowPeopleFaceDiscern"
      :before-close="closePeopleFaceDiscern"
    >
      <face-recognition :isOpenFaceStatus="isOpenFaceStatus"></face-recognition>
    </custom-show-dialog>

    <!-- 面容识别保存或编辑 -->

    <custom-dialog
      title="面容信息管理"
      width="715px"
      class="popup_dialog_withe"
      :visible.sync="isShowSavePeopleFaceDiscern"
      :before-close="closeSavePeopleFaceDiscern"
    >
      <face-recognition-save ref="faceSave"></face-recognition-save>
    </custom-dialog>
  </div>
</template>
<script>
import kqItem from "./kq-item";
import abnormalSignPool from "./abnormal-sign-pool";
import {
  SpecialLongLink,
  getKQsummaryStatistics,
  getKQsummaryForDay,
  getScheduleCourseOnStudentList,
  getSignWarning,
  GetMainTeaAttendanceDaily,
  GetMainTeaScheduleCourseListByDate,
} from "../../API/workbench";
import coursesign from "./course-sign";
import { setIntervalCheck } from "../../assets/index.js";
import faceRecognition from "../face-recognition/index";
import faceRecognitionSave from "../face-recognition-save/index";
import dropDownBox from "../common/drop-down-box";
export default {
  data() {
    return {
      curSelectedDate: Date.new().toLocaleDateString(),
      presidentReport: {}, // 校长统计
      startTime: "",
      endTime: "",
      swiper: {},
      selectedDateInfo: "", // 选中天的详细数据
      kqList: [],
      disabled1: false,
      disabled2: false,
      showAttenInfo: false, // 显示考勤详情
      isToday: false, // 是否今天显示操作栏
      isTodayType: 1, // 1为已上课程, 2位未上课程.
      courseList: [],
      isOverShowMore: false, // 点击触发显示更多.
      isNotBeginShowMore: false, // 点击触发未开始显示更多.
      isShowAll: false, // 是否已经显示所有
      isNotBeginShowAll: false, // 是否未开始已经显示所有
      perviewLength: 6,
      screenWidth: "",
      isShowStartList: false, // 是否显示未开始,
      isShowEndList: false, // 是否显示进行中.
      seletedCourseInfo: {},
      parseDataList: "",
      textSearch: "",
      setTimeoutObj: null,
      abnormalSign: false, // 显示异常签到池
      searchObj: {
        pageIndex: 0,
        pageSize: 0,
        orderBy: "",
        sequence: "",
        searchText: "",
        startTime: "", // this.$utils.formatDateToLine(Date.new()),
        endTime: "", // this.$utils.formatDateToLine(Date.new()),
        QueryID: 0,
      },
      abnormalSignData: {
        Total: 0,
        PageDataList: [],
      },
      seletedAbnormalInfo: {},
      isToday: true,
      isShowAttendanceArrangeDialog: false,
      banjiInfo: {
        name: "班级排课",
        routertitle: "班级排课",
        routerName: "classGradeApplycourse",
        auth: true,
        roleName: "",
        ModulePowerKey: 53,
      },
      kechengInfo: {
        name: "课程管理",
        routertitle: "课程管理",
        routerName: "courseScheduleManagement",
        auth: true,
        roleName: "router",
        ModulePowerKey: 53,
      },
      coursetableInfo: {
        name: "课程表",
        routertitle: "课程表",
        routerName: "CourseTable",
        auth: true,
        roleName: "",
        ModulePowerKey: [146, 47],
      },
      isShowPeopleFaceDiscern: false, // 面容识别签到
      isShowSavePeopleFaceDiscern: false, // 面容识别管理
      faceStatus: false, // 显示面容识别功能模块.
      isOpenFaceStatus: true, // 是否显示开启面容识别按钮,
      openFace: {
        name: "",
        type: "",
        ModulePowerKey: 81,
      },
      saveFace: {
        name: "",
        type: "",
        ModulePowerKey: 48,
      },
      commentCheck: false,
      isShowdropDownBox: false,
      moreBtnList: [
        {
          name: "新建循环课",
          routerName: "indexView",
          routertitle: "工作台",
          auth: true,
          roleName: "",
          ModulePowerKey: 47,
          moduleName: "newCircleCourseTable",
          isShow: true,
        },
         {
          name: "日历建课",
          routerName: "indexView",
          routertitle: "工作台",
          auth: true,
          roleName: "",
          ModulePowerKey: 47,
          moduleName: "newCourseByCalendar",
          isShow: true,
        },
       
        {
          name: "长假停课",
          routerName: "indexView",
          routertitle: "工作台",
          auth: true,
          roleName: "",
          isShow: true,
          moduleName: "holidayssuspendclasses",
          ModulePowerKey: 47,
        },
        {
          name: "长假调课",
          routerName: "indexView",
          routertitle: "首页",
          isShow: true,
          roleName: "",
          ModulePowerKey: 47,
          moduleName: "holidaysAdjustClasses",
        },
        {
          name: "复制课表",
          routerName: "indexView",
          routertitle: "首页",
          isShow: true,
          roleName: "",
          ModulePowerKey: 47,
          moduleName: "copyCourse",
        }
      ],
      listTop:0,
      contentHeight:0
    };
  },
  components: {
    kqItem,
    coursesign,
    abnormalSignPool,
    faceRecognition,
    faceRecognitionSave,
    dropDownBox,
  },
  created() {
    this.screenWidth=window.innerWidth
    console.log('created screenWidth',this.screenWidth)
    this.registerBusEvent();
    this.setIntervalCheckCourse();
  },
  mounted() {
      document.querySelector('body').addEventListener(
      'click',
      (e) => {
        this.isShowdropDownBox = false;
      },
      false
    );
     this.$bus.on(this, 'screenOnResize', (screenWidth) => {
            console.log("screenOnResize attendance-date",screenWidth)
          this.screenWidth=screenWidth
      });
    window.addEventListener("scroll", ()=> {
    // 在这里编写滚动事件的处理逻辑
     if(this.$route.name=='indexView'||this.$route.name=='teacherAttenClass'){
       if(this.$route.name=='indexView'){
        let parentTop=document.getElementsByClassName("left_contont_top")[0].getBoundingClientRect().height+217   //207是自身的日期及搜索框高度
        this.listTop= document.documentElement.scrollTop<= parentTop?0:document.documentElement.scrollTop-parentTop
      }else if(this.$route.name=='teacherAttenClass'){
         let parentTop=document.getElementsByClassName("left_contont_top")[0].getBoundingClientRect().height+217   //207是自身的日期及搜索框高度
         if(document.getElementsByClassName("my_courewarebag")&&document.getElementsByClassName("my_courewarebag")[0]){
            parentTop=parentTop+document.getElementsByClassName("my_courewarebag")[0].getBoundingClientRect().height
         }
        this.listTop= document.documentElement.scrollTop<= parentTop?0:document.documentElement.scrollTop-parentTop
      }
      
      let locaIndex=this.vTimeList.findIndex(o=>{
          let top=document.getElementsByClassName("couser_"+o.OLAPKey)[0].getBoundingClientRect().top
          return top<=92&&top>40
      })
      console.log("scrollscroll",locaIndex)
      if(locaIndex>-1){
        this.vTimeList.forEach((o,index)=>{
          if(index==locaIndex){
            o.isDateActive=true
          }else{
            o.isDateActive=false
          }
        })
      }
      console.log("document.documentElement.scrollTop",document.documentElement.scrollTop)
      
     
     }
     // console.log("listTop",this.listTop)
    });
  
    this.getSignWarningList();
    // this.swiperInit();
    this.loadData();
    if (this.$refs.tooltipBox1) {
      this.disabled1 =
        this.$refs.tooltipBox1.offsetWidth <
        this.$refs.tooltipBox1.parentElement.offsetWidth;
    }
    if (this.$refs.tooltipBox2) {
      this.disabled2 =
        this.$refs.tooltipBox2.offsetWidth <
        this.$refs.tooltipBox2.parentElement.offsetWidth;
    }
  },
  watch: {
    screenWidth(c, o) {
      if (c >= 1901) {
        this.perviewLength = 6;
      } else if (c < 1901 && c > 1440) {
        this.perviewLength = 5;
      } else {
        this.perviewLength = 3;
      }
      this.$nextTick(() => {
        // this.swiperInit();
      });
    },
  },
  computed: {
    
    isIndexView() {
      return this.$route.name == "indexView";
    },
    vMinDate() {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); //获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = "01";
      let day = "01";
      let hour = "00";
      let minute = "00";
      let second = "01";
      let dateStr =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      console.log("vMinDate", dateStr);
      return dateStr;
    },
    // 判断权限,
    vHasModulePower() {
      return this.$utils.CheckModulePower(
        this.$store.getters.RolePowerList,
        51
      ); // 上课安排权限
    },
    SaaSClientInfo() {
      // 门店信息   要使用的字段 最早签到时间(分钟)
      return this.$store.getters.SaaSClientInfo;
    },
    vTimeList() {
      let newArr = [];
      this.vcourseList.forEach((o) => {
        this.$set(o,'isDateActive',false)
        let locaIndex = newArr.findIndex((p) => {
          p.ClassTimeName = p.ClassTimeName || "";
          return (
            o.ClassTimeName.substring(0, 2) == p.ClassTimeName.substring(0, 2)
          );
        });
        if (locaIndex == -1) {
          newArr.push(o);
        }
      });
      return newArr;
    },
    vcourseList() {
      let newArr = [];
      newArr = [].concat(this.courseList);
      // if (!this.isShowStartList && !this.isShowEndList) {      // 初始化时只显示进行中的课.
      //   newArr = [].concat(this.vProcessCourseList);
      // } else {
      //   if (this.isShowStartList && this.isShowEndList) {
      //     newArr = [].concat(this.vEndCourseList).concat(this.vProcessCourseList).concat(this.vUnStartCourseList);
      //   } else if (this.isShowEndList) {
      //     newArr = [].concat(this.vEndCourseList).concat(this.vProcessCourseList);
      //   } else {
      //     newArr = [].concat(this.vProcessCourseList).concat(this.vUnStartCourseList);
      //   }
      // }
      return newArr;
    },
    vDate() {
      if (this.selectedDateInfo) {
        return (
          this.$utils.formatDateStr(this.selectedDateInfo.ClassTime, "ddddd") +
          " " +
          this.$utils.formatDateStr(this.selectedDateInfo.ClassTime, "MM") +
          "月" +
          this.$utils.formatDateStr(this.selectedDateInfo.ClassTime, "dd") +
          "日"
        );
      }
    },
    // 已结束的课
    vEndInfo() {
      let endInfo = {
        count: 0, //
        atten: 0,
        noAtten: 0,
        val: "",
      };
      this.vEndCourseList.forEach((o, index) => {
        endInfo.count++;
        endInfo.atten += Number(o.ArrivedNumCount);
        endInfo.noAtten += Number(o.NoAttenceCount);
        if (index + 1 == this.vEndCourseList.length) {
          endInfo.val +=
            o.CourseNameKeyValue +
            " ( 签到" +
            Number(o.ArrivedNumCount) +
            "人 ) ";
        } else {
          endInfo.val +=
            o.CourseNameKeyValue +
            " ( 签到" +
            Number(o.ArrivedNumCount) +
            "人 ) , ";
        }
      });
      return endInfo;
    },
    // 未开始的课列表
    vUnStartCourseList() {
      let unStartCourseList = [];
      // let curTime = Date.new().getTime() / 1000 / 60;
      this.courseList.forEach((o) => {
        if (o.StatusKey == 2 && !o.canSign) {
          // 未来的课
          unStartCourseList.push(o);
        }
      });
      return unStartCourseList;
    },
    // 进行中的课列表
    vProcessCourseList() {
      let processCourseList = [];
      this.courseList.forEach((o) => {
        if (o.StatusKey == 2 && o.canSign) {
          // 未来的课
          processCourseList.push(o);
        } else if (o.StatusKey == 3 && o.canSign) {
          processCourseList.push(o);
        }
      });
      console.log("vProcessCourseList", processCourseList);
      return processCourseList;
    },
    // 已结束的课列表
    vEndCourseList() {
      let endCourseList = [];
      this.courseList.forEach((o) => {
        if (o.StatusKey == 4) {
          // 已结束的课
          endCourseList.push(o);
        }
      });
      return endCourseList;
    },
    // 未开始的课
    vStartInfo() {
      let startInfo = {
        count: 0, //
        ArrangedNumCount: 0,
        val: "",
      };
      this.vUnStartCourseList.forEach((o, index) => {
        startInfo.count++;
        startInfo.ArrangedNumCount += Number(o.ArrangedNumCount);
        if (index + 1 == this.vUnStartCourseList.length) {
          startInfo.val +=
            o.CourseNameKeyValue +
            " ( 安排" +
            Number(o.ArrangedNumCount) +
            "人 )";
        } else {
          startInfo.val +=
            o.CourseNameKeyValue +
            " ( 安排" +
            Number(o.ArrangedNumCount) +
            "人 ) , ";
        }
      });
      return startInfo;
    },
    vPageDataList() {
      return this.abnormalSignData.PageDataList;
    },
    vAbnormalSignList() {
      let newArr = [];
      this.vPageDataList.forEach((o) => {
        let locaIndex = newArr.findIndex((p) => {
          return o.CreateTime.substring(0, 10) == p.CreateTime.substring(0, 10);
        });
        if (locaIndex >= 0) {
          newArr[locaIndex].PageDataList.push(o);
        } else {
          newArr.push({
            CreateTime: o.CreateTime,
            PageDataList: [o],
          });
        }
      });
      console.log(newArr, "vAbnormalSignList");
      return newArr;
    },
  },
  methods: {
    chgcommentCheck() {
      console.log("chgcommentCheck", this.commentCheck);
      this.getKQStatisticsDateList(this.startTime, this.endTime, () => {
        this.$nextTick(() => {
          let locaIndex = this.kqList.findIndex((o) => {
            return o.ClassTime == this.curSelectedDate;
          });
          if (locaIndex) {
            this.swiperIndex(locaIndex - 1); // 定位到当前日期.
          }
          this.chgCoureDate(this.kqList[locaIndex]);
        });
      });
    },
    //
    searchKQData(type) {
      if (type == "today") {
        this.isToday = true;
        this.loadData();
      } else if (type == "pre") {    //根据屏幕分辨率决定显示多少个日期块。
       let num =this.sumscreenWidthgetNum()
        this.isToday = false;
        let dayTime = Date.new(
          Date.new(this.startTime).getTime() - 86400000 * num
        );
        this.todayTableInfoList(dayTime,null,type);
      } else {
         let num =this.sumscreenWidthgetNum()
        this.isToday = false;
        let dayTime = Date.new(
          Date.new(this.endTime).getTime() + 86400000 * num
        );
        this.todayTableInfoList(dayTime,null,type);
      }
    },
    searchStudent() {},
    clickTimescope(item) {
      
      //     height: 88px+padding 8px
      this.vTimeList.forEach(o=>{
        if(o.OLAPKey==item.OLAPKey){
          o.isDateActive=true
        }else{
          o.isDateActive=false
        }
      })
      console.log("clickTimescope", item);
      let locaIndex = this.vcourseList.findIndex((o) => {
        return o.OLAPKey == item.OLAPKey;
      });

      this.contentHeight=0      //初始化空组件的高度
      var heightForParent =0
      var overHeight=0
      if (locaIndex > -1) {
        // contentdata.scrollTop+elTop - 130 || 0
        this.$nextTick(p=>{
          if(this.$route.name=='indexView'){
            // 左侧上方的高度
            let parentTop=document.getElementsByClassName("left_contont_top")[0].getBoundingClientRect().height+217   //207是自身的日期及搜索框高度
            // 整个页面的总高度
            let documentHeight=document.getElementsByClassName('home_page')[0].getBoundingClientRect().height
            // document.documentElement.scrollTop= parentTop+88*locaIndex
            // 遍历数组，符文条件的高度相加
      
            this.vcourseList.forEach((o,index) => {
              if(index<locaIndex){
                let height=document.getElementsByClassName("couser_"+this.vcourseList[index].OLAPKey)[0].getBoundingClientRect().height
                heightForParent+=height+8
              }else{
                let height=document.getElementsByClassName("couser_"+this.vcourseList[index].OLAPKey)[0].getBoundingClientRect().height
                overHeight+=height+8
                
              }
            
            })
            // 计算空组件应该显示的高度
            this.contentHeight=document.body.clientHeight-overHeight-90
            // this.contentHeight=documentHeight-document.documentElement.scrollTop
            this.$nextTick(o=>{
               // 计算滚动条高度
              document.documentElement.scrollTop= parentTop+heightForParent
            })
            console.log('heightForParent',heightForParent,this.contentHeight)
            console.log('document.documentElement.scrollTop',document.documentElement.scrollTop)
         
          }else{
            let parentTop=document.getElementsByClassName("left_contont_top")[0].getBoundingClientRect().height+217   //207是自身的日期及搜索框高度
             let documentHeight=document.getElementsByClassName('home_page')[0].getBoundingClientRect().height
            if(document.getElementsByClassName("my_courewarebag")&&document.getElementsByClassName("my_courewarebag")[0]){
                parentTop=parentTop+document.getElementsByClassName("my_courewarebag")[0].getBoundingClientRect().height
            }
            this.vcourseList.forEach((o,index) => {
              if(index<locaIndex){
                let height=document.getElementsByClassName("couser_"+this.vcourseList[index].OLAPKey)[0].getBoundingClientRect().height
                heightForParent+=height+8
              }else{
                let height=document.getElementsByClassName("couser_"+this.vcourseList[index].OLAPKey)[0].getBoundingClientRect().height
                overHeight+=height+8
                
              }
            
            })
            console.log("parentTop",parentTop)
            //  this.contentHeight=documentHeight-parentTop+heightForParent
              this.contentHeight=document.body.clientHeight-overHeight-90
              this.$nextTick(o=>{
                document.documentElement.scrollTop=parentTop+heightForParent
               })
               console.log('heightForParent',heightForParent,this.contentHeight)
         
          }
        })
        // document.documentElement.scrollTop = 850 + 80 * locaIndex;
      }
    },
    setClassTimeName(item) {
      if (item.ClassTimeName) {
        return item.ClassTimeName.substring(0, 2) + ":00";
      }
    },
    setTimeCount(item){
      let count = 0;
      this.vcourseList.forEach((o) => {
        if (o.ClassTimeName.substring(0, 2) == item.ClassTimeName.substring(0, 2)) {
          count++;
        }
      });
      return count+'课次';
    },
    setTimeValue(item) {
      if (Date.new(item.ClassTime + " " + item.SClassTimeName) > Date.new()) {
        return "未开始";
      } else if (
        Date.new(item.ClassTime + " " + item.EClassTimeName) > Date.new()
      ) {
        return "进行中";
      } else {
        return "已结束";
      }
    },
    setTimeClass(item) {
      if (Date.new(item.ClassTime + " " + item.SClassTimeName) > Date.new()) {
        return "red_type";
      } else if (
        Date.new(item.ClassTime + " " + item.EClassTimeName) > Date.new()
      ) {
        return "green_type";
      } else {
        return "";
      }
    },
    // 关闭考勤详情时更新当天的数据
    courseAttenCloseUpdate() {
      console.log("courseAttenClose", this.curSelectedDate);

      let api =
        this.$route.name == "indexView"
          ? getKQsummaryStatistics
          : GetMainTeaAttendanceDaily;
      let iscommentCheck = this.commentCheck ? 1 : 0;
      api(this.curSelectedDate, this.curSelectedDate, iscommentCheck).then(
        (result) => {
          console.log("获取指定日期数据", result.data);
          let locaIndex = this.kqList.findIndex((o) => {
            return o.ClassTime == this.curSelectedDate;
          });
          if (locaIndex >= 0) {
            result.data[0].isActive = false;
            result.data[0].isOpt = false;
            this.kqList.splice(locaIndex, 1, result.data[0]);
            // this.kqList[locaIndex] = this.$utils.parseJson(result.data[0]);
            console.log(this.kqList[locaIndex], "处理后", locaIndex);
          }
          this.chgCoureDate(this.kqList[locaIndex]);
          // this.kqList.forEach(o => {
          //   if (o.ClassTime == this.curSelectedDate) {
          //     console.log(o, '处理前');
          //     o = this.$utils.parseJson(result.data[0]);
          //     console.log(o, '处理后');
          //   }
          // });
        }
      );
    },
    // 定时检查是否有课从未开始-》可签到
    setIntervalCheckCourse() {
      setIntervalCheck(() => {
        this.setScheduleCourseStatus();
      });
    },
    setScheduleCourseStatus() {
      this.courseList.forEach((o) => {
        let starttime = o.ClassTime.replace(/-/g, "/") + " " + o.SClassTimeName; // 开始时间
        let endtime = o.ClassTime.replace(/-/g, "/") + " " + o.EClassTimeName; // 开始时间
        let startClassTime = Date.new(starttime).getTime() / 1000 / 60;
        let endClassTime = Date.new(endtime).getTime() / 1000 / 60;
        let curTime = Date.new().getTime() / 1000 / 60;
        if (o.StatusKey == 2) {
          // 未来的课
          if (
            startClassTime - curTime >=
            Number(this.$utils.getSaaSClientSetInfo(31).SetContent)
          ) {
            // 当前课上课时间大于当前时间 超过最早签到时间
            o.canSign = false;
          } else {
            o.canSign = true; // 可以签到
            if (startClassTime - curTime <= 0) {
              // 当前课上课时间-当前时间 < 0 变为上课中
              o.StatusKey = 3;
            }
          }
        } else if (o.StatusKey == 4) {
          // 已结束的课
          o.canSign = true; // 可以签到.0+-
        } else if (o.StatusKey == 3) {
          o.canSign = true; // 可以签到
          if (endClassTime - curTime <= 0) {
            // 当前课下课时间-当前时间 < 0 变为已结束
            o.StatusKey = 4;
          }
        }
      });
    },
    scrollTOP(e) {
      let ETarget = e.target;
      if (
        ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight ||
        ETarget.scrollTop + 1 == ETarget.scrollHeight - ETarget.clientHeight
      ) {
        console.log("scrollTOP");
        this.getSignWarningList(this.searchObj);
      }
    },
    setCreateTime(item) {
      if (item) {
        let date = item.CreateTime.replace(/-/g, "/").substring(0, 10);
        let lastDate = Date.new(
          Date.new().setTime(Date.new().getTime() - 24 * 60 * 60 * 1000)
        );
        let last2Date = Date.new(
          Date.new().setTime(Date.new().getTime() - 24 * 60 * 60 * 1000 * 2)
        );
        let preDate = Date.new(
          Date.new().setTime(Date.new().getTime() + 24 * 60 * 60 * 1000)
        );
        if (date == this.$utils.formatDatet(Date.new(), "yyyy/MM/dd")) {
          return "今天";
        } else if (date == this.$utils.formatDatet(lastDate, "yyyy/MM/dd")) {
          return "昨天";
        } else if (date == this.$utils.formatDatet(preDate, "yyyy/MM/dd")) {
          return "明天";
        } else if (date == this.$utils.formatDatet(last2Date, "yyyy/MM/dd")) {
          return "前天";
        } else {
          return (
            this.$utils.formatDatet(Date.new(date), "yyyy") +
            "-" +
            this.$utils.formatDatet(Date.new(date), "MM") +
            "-" +
            this.$utils.formatDatet(Date.new(date), "dd")
          );
        }
      }
    },
    // 选中异常签到池的单项,弹出处理层.
    seletedAbnormalItem(item) {
      console.log(this.vAbnormalSignList, "seletedAbnormalItem", item);
      this.seletedAbnormalInfo = item;
      let moduleInfo = {
        name: "异常签到池",
        routerName: this.$route.name,
        moduleName: "abnormalchecksign",
        data: item,
      };
      this.$bus.emit("needOpenRouter", moduleInfo);
    },
    // 显示异常签到池.
    showAbnormalSign() {
      this.abnormalSign = !this.abnormalSign;
    },
    spliceWarningList(OLAPKey) {
      console.log(OLAPKey, "sliceWarningList");
      if (OLAPKey > 0) {
        let index = this.abnormalSignData.PageDataList.findIndex((o) => {
          return o.OLAPKey == OLAPKey;
        });
        console.log(index, this.abnormalSignData.PageDataList);
        if (index >= 0) {
          this.abnormalSignData.PageDataList.splice(index, 1);
        }
        console.log(this.abnormalSignData.PageDataList);
      }
    },
    getSignWarningList() {
      getSignWarning(this.searchObj)
        .then((result) => {
          this.abnormalSignData.Total = result.data.Total;
          result.data.PageDataList.forEach((o) => {
            let locaIndex = this.abnormalSignData.PageDataList.findIndex(
              (p) => {
                return p.OLAPKey == o.OLAPKey;
              }
            );
            if (locaIndex < 0) {
              // 不存在追加.
              this.abnormalSignData.PageDataList.push(o);
            }
          });
          console.log("getSignWarningList", result.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadData(type) {
      this.todayTableInfoList(Date.new(), () => {
        this.$nextTick(() => {
          let locaIndex = this.kqList.findIndex((o) => {
            return o.ClassTime == this.curSelectedDate;
          });
          if (locaIndex) {
            this.swiperIndex(locaIndex - 1); // 定位到当前日期.
          }
          if (!type) {
            this.chgCoureDate(this.kqList[locaIndex]);
          } else {
            this.kqList[locaIndex].isActive = true;
          }
        });
      });
    },
    // 弹出层
    showDialog(val) {
      this.$emit("showDialog", val);
    },
    // 打开安排考勤
    showArrange(item, updateFunc) {
      console.log("showArrange");
      this.seletedCourseInfo = item;
      let moduleInfo = {
        name: "安排考勤",
        moduleName: "attendanceArrange",
        routerName: this.$route.name,
        data: { scheduleCourseKey: this.seletedCourseInfo.OLAPKey },
      };
      this.$emit("openCustomDialogByFactoryDialog", moduleInfo);
    },
    overShowMore(newArr) {
      if (this.isOverShowMore) {
        this.courseList.forEach((o) => {
          if (o.StatusKey == 3 || o.StatusKey == 4) {
            newArr.push(o);
          }
        });
      } else {
        this.courseList.forEach((o) => {
          if (o.StatusKey == 3 || o.StatusKey == 4) {
            if (newArr.length < 5) {
              newArr.push(o);
            }
          }
        });
      }
    },
    notBeginShowMore(newArr) {
      if (this.isNotBeginShowMore) {
        this.courseList.forEach((o) => {
          if (o.StatusKey == 2) {
            newArr.push(o);
          }
        });
      } else {
        this.courseList.forEach((o) => {
          if (o.StatusKey == 2) {
            if (newArr.length < 5) {
              newArr.push(o);
            }
          }
        });
      }
    },
    chgisNotBeginShowMore() {
      this.isNotBeginShowAll = true;
      this.isNotBeginShowMore = true;
    },
    chgisOverShowMore() {
      this.isShowAll = true;
      this.isOverShowMore = true;
    },
    // 显示日期插件
    showDatePicker() {
      this.$refs.eventCalendarBox.showBox = true;
    },
    // 选中日期
    // changeDay (date) {
    //   // 复制一下date.
    //   this.curSelectedDate = date;
    //   this.todayTableInfoList(Date.new(date), () => {
    //     console.log('changeDay', this.curSelectedDate);
    //     let locaIndex = this.kqList.findIndex(o => {
    //       return o.ClassTime == this.curSelectedDate;
    //     });
    //     if (locaIndex) {
    //       this.swiperIndex(locaIndex);      // 定位到当前日期.
    //       this.chgCoure(this.kqList[locaIndex], true);
    //     }
    //   });
    // },
    chgCoure(item, type) {
      // 只提供高亮,不提供请求.
      // this.$el.querySelector('.el-input__inner').blur();
      // this.$refs.inputDate.pickerblur();
      this.curSelectedDate = item.ClassTime;
      this.isTodayType = 1;
      this.isOverShowMore = false;
      this.isNotBeginShowMore = false;
      this.isShowAll = false;
      this.isNotBeginShowAll = false;
      this.kqList.forEach((o) => {
        if (o.ClassTime == item.ClassTime) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      this.chgCoureDate(item);
      // if (!type) {
      //   if (this.vHasModulePower) {
      //     let moduleInfo = {
      //       name: '考勤详情', // + ' ' + this.curSelectedDate,
      //       routerName: this.$route.name,
      //       moduleName: this.$initJson.allCustomerDialogNames.attendanceDetail.moduleName,
      //       data: { queryDate: this.curSelectedDate, ClassName: 'noscroll' }
      //     };
      //     this.$bus.emit('needOpenRouter', moduleInfo);
      //   } else {
      //     this.$utils.CheckModulePowerToTips(51);
      //   }
      // }
    },
    // 点击中考勤日期列表块触发
    chgCoureDate(item, type) {
      // 提供高亮,提供请求.
      this.selectedDateInfo = item;
      this.isTodayType = 1;
      this.isOverShowMore = false;
      this.isNotBeginShowMore = false;
      this.isShowAll = false;
      this.isNotBeginShowAll = false;

      let date = item.ClassTime.replace(/-/g, "/");
      this.getCourseListForDate(item.ClassTime, "", () => {
        if (date == this.$utils.formatDatet(Date.new(), "yyyy/MM/dd")) {
          this.isToday = true;
        } else {
          this.isToday = false;
        }
        if (
          Date.new(date) <=
            Date.new(this.$utils.formatDatet(Date.new(), "yyyy/MM/dd")) &&
          Number(item.HaveClassNum) + Number(item.UnderwayClassNum) ==
            Number(item.ScheduleCourseNum) &&
          this.courseList.length > 0
        ) {
          this.showAttenInfo = true;
        } else {
          this.showAttenInfo = false;
        }
        this.kqList.forEach((o) => {
          if (o.ClassTime == item.ClassTime) {
            o.isActive = true;
          } else {
            o.isActive = false;
          }
        });
      });
    },
    sumscreenWidthgetNum(){
      let num = 6
          let screenWidthA=1366
            let costWidth=140
      if(this.screenWidth<=1366){
        screenWidthA=1366
        costWidth=140
      }else if(this.screenWidth>=1900){
        screenWidthA=1920
             costWidth=180
      }else{
        screenWidthA=this.screenWidth
      }
    
      num = Math.floor((screenWidthA-372-costWidth)/140)
      console.log('sumscreenWidthgetNum',num)
      return num
    },
    // 获取指定日期的数据
    todayTableInfoList(date, callback,type) {
      console.log("todayTableInfoList",date,type)
      date = date || Date.new();
      let num =this.sumscreenWidthgetNum()
      
      // this.startTime = this.$utils.getNextDate(date, -20);
      // this.endTime = this.$utils.getNextDate(date, 40);
       this.curSelectedDate = this.$utils.formatDatet(date, "yyyy-MM-dd");
      // if(num==7){
      //   this.curSelectedDate = this.$utils.formatDatet(date, "yyyy-MM-dd");
      //   this.startTime = this.$utils.getWeekDates(this.curSelectedDate).start;
      //   this.endTime = this.$utils.getWeekDates(this.curSelectedDate).end;
      // }else{
        if (type == "next") {
          this.endTime=this.$utils.formatDatet(date, "yyyy-MM-dd")
          this.startTime =this.$utils.formatDatet(Date.new(
          Date.new(date).getTime() - 86400000 * (num-1)
        ), "yyyy-MM-dd")
        } else if (type == "pre") {
           this.startTime=this.$utils.formatDatet(Date.new(
          Date.new(date).getTime()
        ), "yyyy-MM-dd")
          this.endTime=this.$utils.formatDatet(Date.new(
          Date.new(date).getTime() + 86400000 * (num-1)
        ), "yyyy-MM-dd")
        } else {
            this.curSelectedDate = this.$utils.formatDatet(Date.new(), "yyyy-MM-dd");
          this.startTime=this.$utils.formatDatet(Date.new(
          Date.new(date).getTime() - 86400000 * ((num-3)-1)
        ), "yyyy-MM-dd")
          this.endTime=this.$utils.formatDatet(Date.new(
          Date.new(date).getTime() + 86400000 * (num-(num-3))
        ), "yyyy-MM-dd")

        
        // }
        console.log("curSelectedDate",this.curSelectedDate)
      }
      
      this.getKQStatisticsDateList(this.startTime, this.endTime, callback);
    },
    // 初次及选中日期时触发请求的考勤统计
    getKQStatisticsDateList(startTime, endTime, callback) {
      console.log("this.startTime", this.startTime);
      console.log("this.endTime", this.endTime);
      // getKQsummaryStatistics
      let api =
        this.$route.name == "indexView"
          ? getKQsummaryStatistics
          : GetMainTeaAttendanceDaily;
      let iscommentCheck = this.commentCheck ? 1 : 0;
      api(startTime, endTime, iscommentCheck).then((result) => {
        this.kqList = result.data;
        this.kqList.forEach((o) => {
          this.$set(o, "isActive", false);
          this.$set(o, "isOpt", false);
        });

        if (callback) {
          callback();
        }
      });
    },
    // swiper往前拖动触发的考勤统计.
    getKQStatisticsBeforeList(startTime, endTime, callback) {
      // getKQsummaryStatistics
      let api =
        this.$route.name == "indexView"
          ? getKQsummaryStatistics
          : GetMainTeaAttendanceDaily;
      api(startTime, endTime).then((result) => {
        result.data.sort((x, y) => {
          return Date.new(y.ClassTime) - Date.new(x.ClassTime);
        });
        result.data.forEach((o) => {
          o.isActive = false;
          let locationIndex = this.kqList.findIndex((p) => {
            return p.ClassTime == o.ClassTime;
          });
          if (locationIndex < 0) {
            this.kqList.unshift(o);
          }
        });
        if (callback) {
          callback();
        }
      });
    },
    // swiper往后拖动触发的考勤统计.
    getKQStatisticsAfterList(startTime, endTime, callback) {
      // GetMainTeaAttendanceDaily
      let api =
        this.$route.name == "indexView"
          ? getKQsummaryStatistics
          : GetMainTeaAttendanceDaily;
      api(startTime, endTime).then((result) => {
        result.data.forEach((o) => {
          o.isActive = false;
          let locationIndex = this.kqList.findIndex((p) => {
            return p.ClassTime == o.ClassTime;
          });
          if (locationIndex < 0) {
            this.kqList.push(o);
          }
        });
        if (callback) {
          callback();
        }
      });
    },
    // 按天获取所有考勤课列表的信息
    getCourseListForDate(date, queryID, callback) {
      // GetMainTeaScheduleCourseListByDate
      let api =
        this.$route.name == "indexView"
          ? getKQsummaryForDay
          : GetMainTeaScheduleCourseListByDate;
      let iscommentCheck = this.commentCheck ? 1 : 0;
      api(date, iscommentCheck).then((result) => {
        result.data.forEach((o) => {
          o.canSign = false;
          o.studentList = [];
        });
        if (queryID > 0) {
          let findIndex = this.courseList.findIndex((obj) => {
            return obj.OLAPKey == queryID;
          });
          if (findIndex > -1) {
            this.courseList.splice(findIndex, 1, result.data[0]);
          } else {
            this.courseList.push(result.data[0]);
          }
        } else {
          this.courseList = [];
          this.$nextTick((o) => {
            this.courseList = result.data;
          });
          this.setScheduleCourseStatus();
        }
        if (callback) {
          callback();
        }
      });
    },
    //
    swiperInit() {
      var that = this;
      this.swiper = new Swiper(".swiper_attendance_content", {
        slidesPerView: 4,
        breakpoints: {
          1020: {
            slidesPerView: 3,
          },
          1440: {
            slidesPerView: 4,
          },
          1680: {
            slidesPerView: 5,
          },
          1920: {
            slidesPerView: 6,
          },
          2560: {
            slidesPerView: 8,
          },
          3840: {
            slidesPerView: 12,
          },
        },
        spaceBetween: 5,
        // loop: false, // 循环模式选项
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swipe
        on: {
          // 拖动结束后触发事件预加载. 判断最左边隐藏的课程数据是否小于十
          slidePrevTransitionEnd: function () {
            let index = this.activeIndex; // 保存当前下标问题.
            let oldLength = Number(that.kqList.length);
            console.log(this.activeIndex);
            if (this.activeIndex < 10) {
              var thos = this;
              that.preCourseKey(that.kqList[0], function () {
                that.$nextTick(() => {
                  let newLength = that.kqList.length;
                  thos.activeIndex = newLength - oldLength + index; // 设定当前下标数.
                });
              });
            }
          },
          // 拖动结束后触发事件预加载. 判断最右边隐藏的课程数据是否小于十,activeIndex为内部的下标属性.
          slideNextTransitionEnd: function () {
            console.log(this.activeIndex);
            if (that.kqList.length > 0) {
              if (that.kqList.length - this.activeIndex < 20) {
                this.curCourseIndex = this.activeIndex;
                that.nextCourseKey(that.kqList[that.kqList.length - 1], () => {
                  that.$nextTick(() => {
                    // that.swiper.updateSlides();
                  });
                });
              }
            }
          },
        },
      });
    },
    // 向前预加载
    preCourseKey(item, callback) {
      let date = Date.new(item.ClassTime.replace(/-/g, "/"));
      this.endTime = this.$utils.getNextDate(date, -1);
      this.startTime = this.$utils.getNextDate(date, -6);
      this.getKQStatisticsBeforeList(this.startTime, this.endTime, callback);
    },
    // 向后预加载
    nextCourseKey(item, callback) {
      let date = Date.new(item.ClassTime.replace(/-/g, "/"));
      this.startTime = this.$utils.getNextDate(date, 1);
      this.endTime = this.$utils.getNextDate(date, 6);
      this.getKQStatisticsAfterList(this.startTime, this.endTime, callback);
    },
    swiperIndex(val) {
      // this.swiper.updateSlides();
      // this.swiper.slideTo(val);
    },
    // 转为整数
    integerData(val) {
      val = val || 0;
      return Math.round(val);
    },
    goRouter(url) {
      this.$router.push({ name: url });
    },
    // 长连接通知
    websocketNoticeAttendanceOrArrange(data) {
      switch (data.dataType) {
        case "StudentAttendance":
          this.websocketNoticeStudentAttendance(data.newData);
          break;
        case "StudentArrang":
          this.websocketNoticeStudentArrang(data.newData);
          break;
        case "ScheduleCourse":
          this.websocketNoticeScheduleCourse(data.newData);
          break;
        default:
          break;
      }
    },
    // 长连接通知 学生考勤
    websocketNoticeStudentAttendance(dataArr) {
      console.log("长连接通知 学生考勤", dataArr);
      dataArr.forEach((item) => {
        let findCourseItem = this.courseList.find((obj) => {
          return obj.OLAPKey == item.ScheduleCourseKey;
        }); // 找到对应的课
        let findStudentIndex = -1;
        if (findCourseItem) {
          findStudentIndex = findCourseItem.studentList.findIndex((obj) => {
            return obj.StudentKey == item.StudentKey;
          }); // 找到对应学生
          // 已结束且可签到
          if (
            (findCourseItem.StatusKey == 4 &&
              findCourseItem.canSign &&
              findStudentIndex == -1) ||
            (findCourseItem.StatusKey == 4 &&
              findCourseItem.canSign &&
              !this.isShowEndList)
          ) {
            if (item.AttenceStatusKey == 3) {
              // 已考勤
              findCourseItem.ArrivedNumCount =
                Number(findCourseItem.ArrivedNumCount) + 1;
              findCourseItem.studentList.push(item);
            } else {
              if (
                findStudentIndex > -1 &&
                findCourseItem.studentList[findStudentIndex].AttenceStatusKey ==
                  3
              ) {
                findCourseItem.ArrivedNumCount =
                  Number(findCourseItem.ArrivedNumCount) - 1;
                findCourseItem.studentList.splice(findStudentIndex, 1);
              }
            }
          } else if (findCourseItem && findStudentIndex > -1) {
            this.$set(item, "isActive", false); // 这里是为了高亮效果.
            this.$set(item, "isOpt", false);
            if (item.AttenceStatusKey == 3) {
              findCourseItem.ArrivedNumCount =
                Number(findCourseItem.ArrivedNumCount) + 1;
            } else if (
              item.AttenceStatusKey != 3 &&
              findCourseItem.studentList[findStudentIndex].AttenceStatusKey == 3
            ) {
              findCourseItem.ArrivedNumCount =
                Number(findCourseItem.ArrivedNumCount) - 1;
            }
            findCourseItem.studentList.splice(findStudentIndex, 1, item);
          }
        }
      });
    },
    // 长连接通知 安排学生
    websocketNoticeStudentArrang(dataArr) {
      console.log("长连接通知 安排学生", dataArr);
      dataArr.forEach((item) => {
        this.$set(item, "isActive", false); // 这里是为了高亮效果.
        this.$set(item, "isOpt", false);
        let findCourseItem = this.courseList.find((obj) => {
          return obj.OLAPKey == item.ScheduleCourseKey;
        }); // 找到对应的课

        let findStudentIndex = -1;
        if (findCourseItem) {
          findStudentIndex = findCourseItem.studentList.findIndex((obj) => {
            return obj.StudentKey == item.StudentKey;
          }); // 找到对应的学生

          // 未开始的课且不可签到
          if (findCourseItem.StatusKey == 2 && !findCourseItem.canSign) {
            if (!this.isShowStartList) {
              // 未展开
              if (item.IfDel == 0) {
                // 添加
                findCourseItem.ArrangedNumCount =
                  Number(findCourseItem.ArrangedNumCount) + 1;
              } else if (item.IfDel == 1) {
                // 删除
                findCourseItem.ArrangedNumCount =
                  Number(findCourseItem.ArrangedNumCount) - 1;
              }
            } else {
              // 已展开
              if (item.IfDel == 0 && findStudentIndex == -1) {
                // 添加
                findCourseItem.ArrangedNumCount =
                  Number(findCourseItem.ArrangedNumCount) + 1;
                findCourseItem.studentList.push(item);
              } else if (item.IfDel == 1 && findStudentIndex > -1) {
                // 删除
                findCourseItem.ArrangedNumCount =
                  Number(findCourseItem.ArrangedNumCount) - 1;
                findCourseItem.studentList.splice(findStudentIndex, 1);
              }
            }
          } else if (
            findCourseItem &&
            item.IfDel == 0 &&
            findStudentIndex == -1
          ) {
            // 存在的课 不存在的学生
            findCourseItem.studentList.push(item);
            findCourseItem.ArrangedNumCount =
              Number(findCourseItem.ArrangedNumCount) + 1;
          } else if (
            findCourseItem &&
            findStudentIndex > -1 &&
            item.IfDel == 1
          ) {
            // 删除学生
            findCourseItem.studentList.splice(findStudentIndex, 1);
            findCourseItem.ArrangedNumCount =
              Number(findCourseItem.ArrangedNumCount) - 1;
          }
        }
      });
    },
    // 长连接通知 修改课（停课 恢复 修改 新增）
    websocketNoticeScheduleCourse(dataArr) {
      console.log("websocketNoticeScheduleCourse", dataArr);
      dataArr.forEach((item) => {
        let findIndexForCourse = this.courseList.findIndex((obj) => {
          return item.OLAPKey == obj.OLAPKey;
        }); // 找到对应的课
        let findItem = this.courseList[findIndexForCourse];
        // 停课 或 把今天的课 改为其他天的课
        if (
          findIndexForCourse > -1 &&
          (item.IsClassStopKey == 1 ||
            this.$utils.formatDateToLine(Date.new(item.ClassTime)) !=
              this.$utils.formatDateToLine(Date.new()))
        ) {
          this.courseList.splice(findIndexForCourse, 1);
          return;
        } else if (findIndexForCourse > -1 && item.IfDel == 1) {
          this.courseList.splice(findIndexForCourse, 1);
          return;
        } else if (findIndexForCourse == -1 && item.IsClassStopKey != 1) {
          // 恢复 或 新增
          this.setCanSignStatus(item);
          if (item.ArrangedNumCount > 0) {
            this.getStudentListInCourse(item, () => {
              this.courseList.push(item);
              this.courseList.sort((a, b) => {
                return (
                  Date.new(a.ClassTime + " " + a.SClassTimeName) -
                  Date.new(b.ClassTime + " " + b.SClassTimeName)
                );
              });
            });
          } else {
            this.$set(item, "studentList", []);
            this.courseList.push(item);
          }
          let locaIndex = this.kqList.findIndex((o) => {
            return o.ClassTime == this.curSelectedDate;
          });
          if (locaIndex >= 0) {
            this.kqList[locaIndex].ScheduleCourseNum++;
            console.log(locaIndex, "新增课", this.kqList[locaIndex]);
          }
        } else if (findIndexForCourse > -1 && item.IsClassStopKey != 1) {
          // 修改
          this.$set(item, "studentList", findItem.studentList || []);
          this.setCanSignStatus(item);
          this.courseList.splice(findIndexForCourse, 1, item);
        }
        this.courseList.sort((a, b) => {
          return (
            Date.new(a.ClassTime + " " + a.SClassTimeName) -
            Date.new(b.ClassTime + " " + b.SClassTimeName)
          );
        });
      });
    },
    setCanSignStatus(item) {
      let starttime =
        item.ClassTime.replace(/-/g, "/") + " " + item.SClassTimeName; // 开始时间
      let startClassTime = Date.new(starttime).getTime() / 1000 / 60;
      let curTime = Date.new().getTime() / 1000 / 60;
      if (
        startClassTime - curTime >=
        Number(this.$utils.getSaaSClientSetInfo(31).SetContent)
      ) {
        // 当前课上课时间大于当前时间 超过最早签到时间
        this.$set(item, "canSign", false);
      } else {
        this.$set(item, "canSign", true);
      }
    },
    getStudentListInCourse(item, callback) {
      getScheduleCourseOnStudentList(item.OLAPKey)
        .then((result) => {
          item.studentList = result.data;
          if (typeof callback == "function") {
            callback();
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    upOrDownEndCourseList() {
      this.isShowEndList = !this.isShowEndList;
      if (!this.isShowEndList) {
        this.courseList.forEach((obj) => {
          if (obj.StatusKey == 4) {
            let asignNum = 0;
            obj.studentList.forEach((item) => {
              if (item.AttenceStatusKey == 3) {
                asignNum += 1;
              }
            });
            obj.ArrivedNumCount = asignNum;
          }
        });
      }
    },
    websocketNoticeSignWarning(data) {
      // 已解决
      if (data.StatusKey == 2 || data.StatusKey == 3) {
        let findIndex = this.abnormalSignData.PageDataList.findIndex((obj) => {
          return obj.OLAPKey == data.OLAPKey;
        });
        if (findIndex > -1) {
          this.abnormalSignData.PageDataList.splice(findIndex, 1);
        }
      } else if (data.StatusKey == 1) {
        this.abnormalSignData.PageDataList.unshift(data);
      }
    },
    registerBusEvent() {
      // 长连接通知 考勤相关
      this.$bus.on(this, "wsAttendanceOrArrange", (data) => {
        if (data) {
          this.websocketNoticeAttendanceOrArrange(data);
        } else {
          this.courseAttenCloseUpdate();
        }
      });
      this.$bus.on(this, "switchHeaderBarToIndexView", () => {
        this.loadData("nonUpdateCourseList");
      });
      // 长连接通知 异常签到
      this.$bus.on(this, "wsAfterSignWarning", (data) => {
        this.websocketNoticeSignWarning(data);
      });
      // 提前处理.
      this.$bus.on(this, "updateSignWrongling", (arg1, arg2, ...arr) => {
        this.spliceWarningList(arg1);
      });
      this.$bus.on(this, "allCourseQuit", (arg1, arg2, ...arr) => {
        this.getKQStatisticsDateList(this.startTime, this.endTime, () => {
          let findItem = this.kqList.find((o) => {
            return o.ClassTime == this.curSelectedDate;
          });
          if (findItem) {
            findItem.isActive = true;
          }
        });
      });
      this.$bus.on(this, "closeAttendanceDetailDialog", (data) => {
        console.log("closeAttendanceDetailDialog");
        this.courseAttenCloseUpdate();
      });
      this.$bus.on(this, "closeArrangeAttendanceDialog", (data) => {
        console.log("closeArrangeAttendanceDialog");
        this.courseAttenCloseUpdate();
      });
    },
    closeAttendanceArrangeDialog() {
      this.isShowAttendanceArrangeDialog = false;
    },
    doAfterClickFunctionBtn(item) {
      this.$emit("doAfterClickFunctionBtn", item);
    },
    // 面容识别录入关闭
    closeShowExportDataList() {
      this.isShowExportDataList = false;
    },

    openFaceSign(data) {
      SpecialLongLink(data)
        .then((result) => {
          console.log(result, "SpecialLongLink");
        })
        .catch((error) => {
          console.log(error, "SpecialLongLink");
        });
    },
    showFaceStatus(type) {
      this.faceStatus = type;
    },
    // 面容识别
    openPeopleFaceDiscem() {
      this.faceStatus = false;
      this.isOpenFaceStatus = false;
      this.isShowSavePeopleFaceDiscern = false;
      this.isShowPeopleFaceDiscern = true;
      this.$store.commit("setIsOpenFaceRecognition", true);
      this.$store.commit("setIsOpenFaceSave", true);
      this.specialData.targetAgentKey = this.token.UserID;
      this.specialData.eventContent = this.token.UserID;
      this.openFaceSign(this.specialData);
    },
    // 关闭面容识别
    closePeopleFaceDiscern() {
      this.isShowPeopleFaceDiscern = false;
    },
    // 面容识别录入
    openSavePeopleFaceDiscem() {
      this.faceStatus = false;
      this.$store.commit("setIsOpenFaceSave", false);

      this.isShowSavePeopleFaceDiscern = true;
    },
    // 面容识别录入关闭
    closeSavePeopleFaceDiscern() {
      this.$refs.faceSave.closeCanmara();
      this.$store.commit("setIsOpenFaceSave", true);
      this.isShowSavePeopleFaceDiscern = false;
    },
    // 关闭面容识别  //检测关闭摄像头
    stopMediaStreamTrack() {
      this.faceStatus = false;
      this.isShowPeopleFaceDiscern = false;
      this.isOpenFaceStatus = true;
      this.$store.commit("setIsOpenFaceRecognition", false);
      console.log("关闭刷脸");
    },
    clickAfter(item) {
      console.log("clickAfter", item);
      this.isShowdropDownBox = false;
      this.doAfterClickFunctionBtn(item);
    },
    showdropDownBox() {
      this.isShowdropDownBox = true;
      console.log("showdropDownBox", (this.isShowdropDownBox = true));
    },
  },
  beforeDestroy() {
    clearTimeout(this.setTimeoutObj);
  },
};
</script>
<style scoped>
.workbench_view_attendance_content {
  display: flex;
  flex-direction: row;
  padding: 15px 0px !important;
  padding-left: 20px !important;
  border-bottom: 1px solid #f2f3f8;
  /* padding-top: 15px !important; */
}
.workbench_view_title_gray {
  background: #f2f3f8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 42px;
  padding-left: 20px;
}
.workbench_view_title_btn_list {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.workbench_view_title_btn {
  padding: 0px 5px;
  min-width: 48px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  border-radius: 4px;
  border: 1px;
  border: 1px solid #ececec;
  background: white;
  font-size: 12px;
  color: #333;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid rgb(255 255 255 / 0%);
}
.workbench_view_title_btn:hover {
  color: #2d98d9;
  border: 1px solid #2d98d9;
}
.workbench_view_todaySign_top {
  display: flex;
  flex-direction: row;
}
.sign_btn_list {
  justify-content: flex-end;
  align-items: center;
}
.sign_btn {
  width: 60px;
  height: 24px;
  border-radius: 100px;
  border: 1px solid #ececec;
  text-align: center;
  line-height: 24px;
  margin-right: 15px;
  font-size: 12px;
  cursor: pointer;
}
.sign_btn:hover {
  color: #2d98d9;
  border: 1px solid #2d98d9;
}
.search_blook_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search_blook_box >>> .defult_dialog_searchform {
  padding: 0px;
  width: 77%;
}
.search_blook_box >>> .student_search_form {
  background: #f2f3f8;
  border-radius: 100px;
  height: 36px;
}
.search_blook_box >>> .btn_light_blue_search,
.search_blook_box >>> .list_btn_light_blue_search {
  bottom: 0px;
  right: 0px;
  color: white;
  width: 68px;
  height: 36px;
  background: #3498db !important;
  text-align: center;
  border-radius: 100px;
}
.couser_block_ul {
  display: flex;
  flex-direction: row;
  padding-left: 0px;
}
.couser_block_ul_left {
  width: 110px;
  height: 100%;
  margin-right: 10px;
}
.couser_block_ul_list{
  position: absolute;
  left:0;
  margin-left: -20px;

}
.couser_block_ul_left_item {
  width: 115px;
  height: 29px;
  border-radius: 4px;
  border: 1px solid #e3e9f0;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  color: #999;
}
.couser_block_ul_left_item.green_type {
  color: #35d780;
  border: 1px solid #35d780;
}
.couser_block_ul_left_item.red_type {
  color: #f27601;
  border: 1px solid #f27601;
}
.couser_block_ul_left_item:hover {
  border: 1px solid #E64024
}
.couser_block_ul_left_item.opt{
  background: linear-gradient(180deg, #FFCCC4 0%, rgba(255, 255, 255, 0.0962963) 100%);
  border: 1px solid #E64024;
  box-shadow: 0px 2px 4px 0px #E6402480;
  color: #E64024;

}
</style>
