<template>
  <div>
    <div class="single_result_detail form_info_detail">
      <div class="student_info_edit form_info_edit form_info_list content_marginTop_30 content_marginRL single_shadow">
        <div>
          <div class="form_data form_info_line">
            <div class="form_info_field">
              <span>辅导日期</span>
            </div>
            <div class="form_info_value form_info_select">
              <el-date-picker type="date"
                              v-model="dataInfo.TutorialTime"
                              @change="chgTime"
                              :picker-options="pickerOptions"
                              placeholder="必填"></el-date-picker>
            </div>
          </div>
          <input-dialog :formTitle="'项目经理'"
                        :formPlaceholder="'请输入'"
                        :readonly="false"
                        @showEldilog="showManagerList"
                        v-model="dataInfo.ProjectManagerKeyValue"></input-dialog>
          <input-dialog :formTitle="'电话销售'"
                        :formPlaceholder="'请输入'"
                        :readonly="false"
                        @showEldilog="showSalesList"
                        v-model="dataInfo.SalesPersonKeyValue"></input-dialog>
        </div>
        <save-cancel-btn-group :isSingleSubmit="true"
                               :fixedBtn="true"
                               :singleSubmitType="'dynamic'"
                               :cancelBtnColor="'btn_light_red_red'"
                               :cancelBtnText="'清空'"
                               @confirmClick="saveComfire"
                               @cancelClick="closeDialog"></save-cancel-btn-group>
      </div>
    </div>

    <custom-dialog title="选择销售员"
                   class="customer_dialog defult_dialog"
                   :visible.sync="isShowSalesList"
                   :before-close="closeListDialog">
      <btn-add-select :addTitleName="'新增'"
                      :addmoduleName="'yuangong-setting'"
                      :addmoduleTitleName="'添加员工'"
                      :addmoduleKey='44'
                      @doAddSuccessEvent="receiveSaler"></btn-add-select>

      <div class=" form_info_detail form_info_serach_box">
        <!-- 课单详细信息 -->
        <searchForList @searchAddress="searchSales"></searchForList>
        <div v-if="vsalesList.length > 0"
             class="search_result">
          <div class="search_result_content">
            <div class="search_result_list">
              <sales-item v-for="item in vsalesList"
                          :key="item.OLAPKey"
                          :item="item"
                          :dataList="salesList"
                          @closeIsShow="closeListDialog"
                          @sendItem="receiveSaler"></sales-item>
            </div>
          </div>
        </div>
        <div v-if="vsalesList.length == 0"
             class="course_block_nodata">
          <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
        </div>
      </div>
    </custom-dialog>
    <custom-dialog title="选择项目经理"
                   class="customer_dialog defult_dialog"
                   :visible.sync="isShowManagerList"
                   :before-close="closeListDialog">

      <btn-add-select :addTitleName="'新增'"
                      :addmoduleName="'yuangong-setting'"
                      :addmoduleTitleName="'添加员工'"
                      :addmoduleKey='44'
                      @doAddSuccessEvent="receiveManager"></btn-add-select>

      <div class=" form_info_detail form_info_serach_box">
        <!-- 课单详细信息 -->
        <searchForList @searchAddress="searchSales"></searchForList>
        <div v-if="vsalesList.length > 0"
             class="search_result">
          <div class="search_result_content">
            <div class="search_result_list">
              <sales-item v-for="item in vsalesList"
                          :key="item.OLAPKey"
                          :item="item"
                          :dataList="salesList"
                          @closeIsShow="closeListDialog"
                          @sendItem="receiveManager"></sales-item>
            </div>
          </div>
        </div>
        <div v-if="vsalesList.length == 0"
             class="course_block_nodata">
          <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
        </div>
      </div>
    </custom-dialog>
  </div>
</template>
<script>
import { SetTutorialData, getInJobSalesList } from '../../../../API/workbench';
import searchForList from '../../../common/search-for-list';
import salesItem from './sales-item';
export default {
  data () {
    return {
      dataInfo: {
        TutorialTime: '',
        ProjectManagerKey: '',
        ProjectManagerKeyValue: '',
        SalesPersonKey: '',
        SalesPersonKeyValue: ''
      },
      isShowManagerList: false,
      isShowSalesList: false,
      pickerOptions: {
        disabledDate: (time) => {
          let nowData = Date.new();
          return time > nowData;
        }
      },
      salesList: [],
      salesSearch: '',
      isSearchSales: false
    };
  },
  components: {
    salesItem, searchForList
  },
  props: {
    seletedInfo: Object
  },
  created () {
    this.dataInfo.TutorialTime = this.seletedInfo.TutorialTime == '-' ? '' : this.seletedInfo.TutorialTime;
    this.dataInfo.ProjectManagerKey = this.seletedInfo.ProjectManagerKey;
    this.dataInfo.ProjectManagerKeyValue = this.seletedInfo.ProjectManagerKeyValue;
    this.dataInfo.SalesPersonKey = this.seletedInfo.SalesPersonKey;
    this.dataInfo.SalesPersonKeyValue = this.seletedInfo.SalesPersonKeyValue;
  },
  computed: {
    vsalesList () {
      let newList = [];
      // const PinyinMatch = require('pinyin-match');// 根据字母搜索
      this.salesList.forEach(o => {
        // var m = PinyinMatch.match(o.MainDemoName, this.salesSearch.toUpperCase());
        if (this.isSearchSales == true) {
          if (
            o.MainDemoName &&
            (o.MainDemoName.toUpperCase().indexOf(this.salesSearch.toUpperCase()) >= 0 || (o.FirstLetterName && o.FirstLetterName.toUpperCase().indexOf(this.salesSearch.toUpperCase()) >= 0))
          ) {
            newList.push(o);
            // if (this.addressSearch === '') { this.Search = "" } else { this.Search = 3 } //有点问题
          }
        } else {
          newList.push(o);
        }
      });
      this.salesSearch = '';
      this.isSearchSales = false;
      return newList;
    }
  },
  methods: {
    chgTime (val) {
      console.log(val, 'chgTime', this.dataInfo);
      this.dataInfo.TutorialTime = val ? val.toLocaleDateString() : '';
    },
    closeListDialog () {
      this.isShowManagerList = false;
      this.isShowSalesList = false;
    },
    // 销售人员层显示并获取数据
    showSalesList () {
      getInJobSalesList()
        .then(result => {
          this.salesList = result.data;
          this.salesList.forEach(o => {
            this.$set(o, 'isActive', false);
          });
          if (this.dataInfo.SalesPersonKey != 0) {
            let locaIndex = this.salesList.findIndex(o => {
              return o.OLAPKey == this.dataInfo.SalesPersonKey;
            });
            if (locaIndex >= 0) {
              this.salesList[locaIndex].isActive = true;
            }
          }
          this.isShowSalesList = true;
        })
        .catch(err => {
          console.info(err);
        });
    },
    // 项目经理层显示并获取数据
    showManagerList () {
      console.log('showManagerList');
      getInJobSalesList()
        .then(result => {
          this.salesList = result.data;
          this.salesList.forEach(o => {
            this.$set(o, 'isActive', false);
          });
          if (this.dataInfo.ProjectManagerKey != 0) {
            let locaIndex = this.salesList.findIndex(o => {
              return o.OLAPKey == this.dataInfo.ProjectManagerKey;
            });
            if (locaIndex >= 0) {
              this.salesList[locaIndex].isActive = true;
            }
          }
          this.isShowManagerList = true;
        })
        .catch(err => {
          console.info(err);
        });
    },
    searchSales (val) {
      this.salesSearch = val;
      this.isSearchSales = true;
    },
    receiveSaler (val) {
      // 接收销售人员
      if (val.isActive) {
        this.dataInfo.SalesPersonKeyValue = val.MainDemoName;
        this.dataInfo.SalesPersonKey = val.OLAPKey;
      } else {
        this.dataInfo.SalesPersonKeyValue = '';
        this.dataInfo.SalesPersonKey = '';
      }
      this.chgSales();
    },
    chgSales () {
      // 点击确定时改变值
      // this.dataInfo.AdviserKey = this.saleValueKey;
      // this.dataInfo.AdviserKeyValue = this.saleValue;
      this.closeListDialog();
    },
    receiveManager (val) {
      // 接收项目经理
      if (val.isActive) {
        this.dataInfo.ProjectManagerKeyValue = val.MainDemoName;
        this.dataInfo.ProjectManagerKey = val.OLAPKey;
      } else {
        this.dataInfo.ProjectManagerKeyValue = '';
        this.dataInfo.ProjectManagerKey = '';
      }
      this.chgSales();
    },
    chgManager () {
      // 点击确定时改变值
      // this.dataInfo.AdviserKey = this.saleValueKey;
      // this.dataInfo.AdviserKeyValue = this.saleValue;
      this.closeListDialog();
    },
    verify (callback) {
      if (!this.dataInfo.TutorialTime) {
        layer.alert('请选择日期');
        return false;
      } else if (!this.dataInfo.ProjectManagerKey) {
        layer.alert('请选择项目经理');
        return false;
      } else if (!this.dataInfo.SalesPersonKey) {
        layer.alert('请选择销售人员');
        return false;
      } else {
        return true;
      }
    },
    // 确定保存
    saveComfire (callback) {
      this.saveMenDianTutorialData(this.seletedInfo.SaaSClientKey, this.dataInfo, callback);
    },
    // 关闭弹出层
    closeDialog () {
      this.dataInfo = {
        TutorialTime: '',
        ProjectManagerKey: '',
        ProjectManagerKeyValue: '',
        SalesPersonKey: '',
        SalesPersonKeyValue: ''
      };
      // this.$emit('closeDialog');
      // this.saveMenDianTutorialData(this.seletedInfo.SaaSClientKey, this.dataInfo);
    },
    // 设置门店辅导数据
    saveMenDianTutorialData (SaaSClientKey, TutorialData, callback) {
      SetTutorialData(SaaSClientKey, TutorialData).then(result => {
        console.log('SetTutorialData', result.data);
        this.$emit('saveComfire');
        if (callback) {
          callback();
        }
      }).catch(error => {
        console.log(error, 'SetTutorialDataError');
      });
    }
  }
};
</script>
