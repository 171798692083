<template>
  <!-- 通用列表 -->
  <div class="table_list_content summarizing_list">
    <div class="single_result_detail">
      <table-list ref="tableListRef"
                  :tableData="tableData"
                  :tableColumns="tableColumns"
                  :expandRowData="expandRowData"
                  :rowKey="rowKey"
                  :totalNum="totalNum"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  @loadTableData="loadTableData"></table-list>
    </div>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
export default {
  data () {
    return {
      isShowInnerScroll: true,
      isShowSummary: false
    };
  },
  props: {
    tableData: Array, // 列表数据
    detailColumns: Array, // 显示详情内容
    expandRowData: Array, // 展开行列表数据
    rowKey: [String, Number], // 显示详情对应绑定的ID
    totalNum: Number, // 分页总共条数
    tableColumns: Array, // table column
    queryParams: Object,
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: '', order: 'ascending' };
      }
    }
  },
  components: {
    tableList
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {
    // 点击别处时弹框自动收回
    // document.querySelector('body').addEventListener('click', e => {
    //   if (this.$refs && this.$refs.tableListRef) {
    //     this.$refs.tableListRef.expandRowKeys = [];
    //   }
    //   console.log(this.$refs, '点击别处时弹框自动收回');
    // }, false);
  },
  methods: {
    loadTableData (queryParams) {
      this.$emit('loadTableData', queryParams);
    }
  }
};
</script>


