<template>
  <!-- 调研头部标题 required-->
  <div class="form_list_name">
    <span class="text_box">{{categoryItem.MainDemoName}}</span>
  </div>
</template> 
<script>

export default {
  data () {
    return {

    };
  },
  props: {
    categoryItem: {// 列名配置
      type: Object,
      default: () => {
        return {
          MainDemoName: String
        };
      }
    }
  },
  created () {
    // console.log('调研头部标题:', this.categoryItem);
  },
  computed: {

  },
  methods: {

  }
};
</script>

<style>
</style>