<template>
  <div class="content_box_list clearfix">
    <div class="clearfix"
         @click.stop="clickEvent($event)">
      <div class="content_box_list_date fl">
        <div>{{courseInfo.WeekKeyValue}}</div>
        <div class="font_gray">{{vBeginClassTime}}</div>
      </div>
      <div class="content_box_list_info fr"
           style="width: 305px;">
        <div class="list_info_left fl"
             :style="courseInfo.alreadyAttenCount>0?'max-width:210px':''">
          <div ref="CourseNameKeyValue"
               class=""
               :class="visMaxWidth?'font_dual':''">
            <span>{{courseInfo.CourseNameKeyValue}}
              <span class="text_teacher"
                    style="height: 16px;"> {{courseInfo.MTeacherKeyValue}}</span>
            </span>
          </div>
          <div class="font_gray"> 
            <span v-if="courseInfo.IsNotEndKey==1">不结束</span>
            <span v-else>
              截止
              <span :class="{font_red:vLastClassTimeDay}">{{vLastClassTime}}</span> 
              <span class="font_black">{{$utils.mAmountType(courseInfo.ClassCount,0)}}</span>节
            </span>
            丨
            <span class="font_black"
                  v-if="vIsSysVersionTY">{{$utils.mAmountType(courseInfo.Num_SyncArranged,0)}}人</span>
            <span class="font_black"
                  v-else>{{$utils.mAmountType(courseInfo.Num_InGrade,0)}}人</span>
          </div>
        </div> 
        <div class="list_info_right fr"
             v-if="courseInfo.alreadyAttenCount>0">
          <div>已排节数</div>
          <div class="font_number font_blue">{{courseInfo.alreadyAttenCount}}</div>
        </div>
        <div class="list_info_btn"> </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isMaxWidth: false
    };
  },
  props: {
    courseInfo: Object
  },
  components: {

  },
  mounted () {

  },
  computed: {
    visMaxWidth () {
      let MaxWidth = this.courseInfo.alreadyAttenCount > 0 ? 210 : 254;
      if (this.$utils.getTextWidth(this.courseInfo.CourseNameKeyValue, 16) > MaxWidth) {
        return true;
      } else {
        return false;
      }
    },
    vBeginClassTime () {
      return this.courseInfo.ClassTimeName.split('-')[0];
    },
    vLastClassTime () {
      return this.courseInfo.LastClassTime.slice(0, 10);
    },
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    },
    vLastClassTimeDay () { // 截止日期小于14天
      return this.$utils.DateDiffDay(Date.new(), this.courseInfo.LastClassTime) <= 14;
    }
  },
  methods: {
    // 选中一节课
    clickEvent (e) {
      this.$emit('clickEvent', this.courseInfo, e);
    }
  }
};
</script>

<style>
</style>