<template>
  <div class="play_video_fixed">
    <!-- @click.stop="closeClick" -->
    <!-- <div class="shade">

    </div> -->
    <div class="play_video_box">
      <video class="video"
             :src="vAttachUrl"
             controls="true"
             autoplay="true"
             controlsList="nodownload"
             disablePictureInPicture></video>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object
  },
  computed: {
    vAttachUrl () {
      return this.$store.getters.CDNURL + this.dataInfo.AttachUrl;
    }
  },
  methods: {
    closeClick () {
      this.$emit('close');
    }
  }
};
</script>

<style>
</style>
