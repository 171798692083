var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course_essay_label_box"},[_c('div',{staticClass:"course_popup_label class_details_label attendance_label"},[_c('div',{staticClass:"label_name"},[_vm._v(" "+_vm._s(_vm.courseInfo.WeekKeyValue)+_vm._s(_vm.courseInfo.ClassTimeName)+" "+_vm._s(_vm.courseInfo.CourseNameKeyValue)+" ")]),_c('div',{staticClass:"label_text icon_03"},[_c('span',{},[_vm._v(_vm._s(_vm.courseInfo.GradeClassKeyValue))]),_vm._v(" - "),_c('span',[_vm._v(_vm._s(_vm.courseInfo.MTeacherKeyValue))]),_vm._v("丨"+_vm._s(Number(_vm.courseInfo.PeriodCount) || 0)+"课时 ")]),_c('div',{staticClass:"label_text icon_05"},[_vm._v(" 安排"+_vm._s(Number(_vm.courseInfo.ArrangedNumCount) || 0)+"人 "),(
          (_vm.courseInfo.IsleaveKey == 1 && _vm.$utils.getSaaSClientSet(32) != 3) ||
            (_vm.courseInfo.IsleaveKey == 0 && _vm.$utils.getSaaSClientSet(32) != 2)
        )?_c('span',{staticClass:"font_text"},[_vm._v(" 请假"+_vm._s(_vm.courseInfo.IsleaveKey == 1 ? "" : "不")+"扣课时")]):_vm._e(),(
          ((_vm.courseInfo.IsChargesKey == 1 &&
            _vm.$utils.getSaaSClientSet(33) != 3) ||
            (_vm.courseInfo.IsChargesKey == 0 &&
              _vm.$utils.getSaaSClientSet(33) != 2)) &&
            this.$utils.getSaaSClientSet(59) > 0
        )?_c('span',{staticClass:"font_text"},[_vm._v(" 旷课"+_vm._s(_vm.courseInfo.IsChargesKey == 1 ? "" : "不")+"扣课时")]):_vm._e()]),_c('div',{staticClass:"label_right_type",class:_vm.vStatuClass},[_vm._v(" "+_vm._s(_vm.vStatu)+" ")])]),(_vm.courseInfo.ScheduleCourseKey && _vm.courseInfo.TeachingPlan)?_c('newTeachingPlanInfo',{attrs:{"courseInfo":_vm.courseInfo,"teachingPlanInfo":_vm.courseInfo.TeachingPlan,"isAlter":_vm.isAlter},on:{"updateCourseInfo":_vm.updateCourseInfo}}):(!_vm.courseInfo.TeachingPlan && _vm.ishasCWePackage)?_c('div',{staticClass:"course_essay_label no_cw_course_essay_label",on:{"click":_vm.showAddCW}},[_c('div',[_c('img',{attrs:{"src":require("../../../../public/cw-img/file_icon@1.png"),"alt":""}})]),_c('div',{staticClass:"font_blue"},[_vm._v("点击选择课件上课")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }