<template>
  <div>
    <div class="general_course_summarizing">
      <div class="general_course_summarizing_title form_info_field">
        <div class="title_text ">
          排课管理
          <input-form-tip :tips="'现有课程可按课程表建立。<a class=tips_a href=https://mp.weixin.qq.com/s/6XihH6O6nVqyVPZko3FupQ target=_Blank>详情<a>'"></input-form-tip>
          <!-- <module-description :textTips="'现有课程可按课程表建立。'"
                              :btnTips="'查看教程'"
                              :btnUrl="'https://mp.weixin.qq.com/s/6XihH6O6nVqyVPZko3FupQ'"></module-description> -->
        </div>
        <div class="title_function">
          <!-- <div class="title_switch">
            <el-switch v-model="isTemporaryStu"
                       @change="changeStatus"></el-switch>
            <span>临时排课学生</span>
          </div> -->
          <input-search v-model="searchText"
                        :placeholder="'请输入姓名、姓名首字母、手机号'"
                        @search="search"
                        @clearSearch="clearSearch">
            <function-btn :item="addNewCourseBtn"
                          :className="'content_search_btn btn_hover_bg_blue'"
                          @clickItem="doNewCrouse">
            </function-btn>
          </input-search>

        </div>
      </div>
      <div v-if="vTypeCourseNameList.length> 0"
           class="general_course_summarizing_content">
        <courseNameItem v-for="item in vTypeCourseNameList"
                        :key="item.CourseNameKey"
                        :item="item"
                        :searchText="searchText"
                        :isTemporaryStu="isTemporaryStu"
                        :copyData="copyData"
                        :seletedStructuredRuleKey="seletedStructuredRuleKey"></courseNameItem>
      </div>
      <div v-else
           class="course_block_nodata"
           style="border-radius: 8px;height: 500px;background-position:center 200px">
        <div class="monitoring_content_nodata_txt"
             style="padding-top: 290px;">亲，当前没有任何数据哦~</div>
      </div>
    </div>
    <div>
      <dialog-factory ref="courseArrangementDialogFactory"
                      :key="'courseArrangement'"
                      :routerName="'courseArrangement'"></dialog-factory>

      <dialog-factory-common ref="courseArrangementDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>

</template>
<script>
import dialogFactory from '../../common/dialog-factory';
import courseNameItem from './course-name-item';
import { SearchStructedRule } from '../../../API/workbench';
export default {
  name: 'courseArrangement',
  data () {
    return {
      isTemporaryStu: false,
      searchText: '',
      addNewCourseBtn: {
        id: '2',
        name: '新建课表',
        ModulePowerKey: 47,
        isShow: true
      },
      GradeClassKey: 0,
      CourseNameKey: 0,
      structedRuleData: [],      // 规律课
      copyData: {
        StructuredRuleKey: 0,
        copyStudentList: []
      },
      seletedStructuredRuleKey: 0 // 更新的循环规律id
    };
  },
  components: {
    courseNameItem, dialogFactory
  },
  watch: {
    searchText: {
      handler (c, o) {
        console.log(c, 'watch');
      }
    }
  },
  computed: {
    vTypeCourseNameList () {      // 仅仅分类课程
      let newArr = [];
      this.structedRuleData.sort((x, y) => {
        if (x.CourseNameKeyValue != y.CourseNameKeyValue) {
          let xCourseName = this.charCodeAtSort(x.CourseNameKeyValue);
          let yCourseName = this.charCodeAtSort(y.CourseNameKeyValue);
          return xCourseName - yCourseName;
        }
        let xGradeClass = this.charCodeAtSort(x.GradeClassKeyValue);
        let yGradeClass = this.charCodeAtSort(y.GradeClassKeyValue);
        return xGradeClass - yGradeClass;
      });
      this.structedRuleData.forEach(o => {
        this.$set(o, 'studentList', []);
        this.$set(o, 'rulesStudentList', []);
        let obj = {
          CourseNameKey: o.CourseNameKey,
          CourseNameKeyValue: o.CourseNameKeyValue,
          classList: []        // 班级列表
        };
        obj.classList.push(o);
        let locaIndex = newArr.findIndex(p => {
          return p.CourseNameKey == o.CourseNameKey;
        });
        if (locaIndex == -1) {
          newArr.push(obj);
        } else {
          newArr[locaIndex].classList.push(o);
        }
      });
      console.log(newArr, 'newArr');

      return newArr;
    }
  },
  created () {
    this.getStructedRuleDataByKey(0, 0, (result) => {
      this.structedRuleData = result.data;
    });
    this.resgisterBusEvent();
  },
  mounted () {
    console.log('this.$route.name', this.$route.name);
  },
  methods: {
    busUpdateStructedRuleData (arg2) {
      console.log(arg2, 'busUpdateStructedRuleData');
      if (this.$route.name != 'courseArrangement') {
        return false;
      }
      arg2.GradeClassKey = arg2.GradeClassKey || arg2.classGradeId;
      // 更新指定的学生列表.
      this.seletedStructuredRuleKey = Number(arg2.StructuredRuleKey) || 0;
      this.getStructedRuleDataByKey(arg2.CourseNameKey, arg2.GradeClassKey, (result) => {
        let newArr = [];
        this.structedRuleData.forEach(o => {
          if (o.CourseNameKey == arg2.CourseNameKey && o.GradeClassKey == arg2.GradeClassKey) {
          } else {
            newArr.push(o);
          }
        });
        console.log(newArr, 'concatbefore', result.data);
        // newArr = newArr.concat(result.data);
        result.data.forEach(p => {
          let locaItem = newArr.find(item => {
            return item.StructuredRuleKey == p.StructuredRuleKey;
          });
          if (!locaItem) {
            console.log(p, '00000000structedRuleData');
            newArr.push(p);
          }
        });

        console.log(newArr, 'concat', result.data);
        this.structedRuleData = newArr;
      });
    },
    // 获取所有循环课
    getStructedRuleDataByKey (CourseNameKey, GradeClassKey, callBack) {
      SearchStructedRule(CourseNameKey, GradeClassKey).then(result => {
        console.log(result.data, 'getStructedRuleData');
        if (callBack) {
          callBack(result);
        }
        // this.structedRuleData = result.data;
      }).catch(error => {
        console.log(error, 'error');
      });
    },
    // 更改是否离校学生状态.
    changeStatus (val) {
      this.$store.commit('setIsTemporaryStu', this.isTemporaryStu);
      console.log(this.isTemporaryStu, 'changeStatus', val);
    },
    // 查找
    search () {
      console.log(this.searchText, 'search');
    },
    // 清空搜索条件并查找
    clearSearch () {
      this.searchText = '';
    },
    // charCodeAt排序
    charCodeAtSort (val) {
      let num = 0;
      for (var i = 0; i < val.length; i++) {
        num += Number(val[i].charCodeAt());
      }
      return num;
    },
    // 新建课表
    doNewCrouse (item) {
      this.openCustomDialogByFactoryDialog({ moduleName: 'newCircleCourseTable' });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.courseArrangementDialogFactory) {
        this.$refs.courseArrangementDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      };
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.courseArrangementDialogFactoryCommon) {
        console.log('courseArrangementDialogFactoryCommon');
        this.$refs.courseArrangementDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      };
    },
    resgisterBusEvent () {
      if (this.$route.name == 'courseArrangement') {
        // 新建课表
        this.$bus.on(this, 'AfterNewCourseTable', (type, obj) => {
          console.log('bus-AfterNewCourseTable', obj);

          this.busUpdateStructedRuleData(obj);
        });
        // 修改课表
        this.$bus.on(this, 'AfterEditCircleCourse', (ruleID, obj) => {
          console.log('bus-AfterEditCircleCourse');
          this.busUpdateStructedRuleData(obj);
        });

        // 批量删除排课计划
        this.$bus.on(this, 'AfterBatchDeleteStructuredPlanList', (courseID, classGradeID, planList) => {
          this.busUpdateStructedRuleData({ CourseNameKey: courseID, GradeClassKey: classGradeID });
        });
        // 退出循环课 AfterAbortCircleCorse
        this.$bus.on(this, 'AfterAbortCircleCorse', (ruleID, obj) => {
          console.log('bus-AfterAbortCircleCorse');
          this.busUpdateStructedRuleData(obj);
        });
        // 学生退出加入循环课操作
        this.$bus.on(this, 'AfterCancelApplyClassCourseByRule', (ruleID, obj) => {
          console.log('bus-AfterCancelApplyClassCourseByRule');
          this.busUpdateStructedRuleData(obj);
        });
        // 新建循环课成功后 批量修改排课计划
        this.$bus.on(this, 'AfterBatchEditStructuredPlanList', (courseID, classGradeID, planList) => {
          let obj = {
            GradeClassKey: classGradeID,
            CourseNameKey: courseID
          };
          console.log(obj, 'obj');
          this.busUpdateStructedRuleData(obj);
        });
        this.$bus.on(this, 'afterQuitOrAddClass', (classID, studentID) => {
          console.log('afterQuitOrAddClass');
          let obj = {
            GradeClassKey: classID,
            CourseNameKey: 0
          };
          this.busUpdateStructedRuleData(obj);
        });
      }
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'courseArrangement') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'courseArrangement') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });

      // 切换路由为本路由时触发.
      this.$bus.on(this, 'switchHeaderBarToCourseArrangement', () => {
        this.getStructedRuleDataByKey(0, 0, (result) => {
          this.structedRuleData = result.data;
        });
      });
    }
  }
};
</script>
