<template>
  <!-- 优惠套餐 -->
  <div class="WKT_index_from">
    <div class="WKT_index_from_title">
      优惠套餐
      <div class="title_btn btn_hover_bg_blue"
           @click="onHistoryComboClick">
        历史套餐
      </div>
    </div>
    <div class="WKT_index_from_content">
      <div class="WKT_discount_package">
        <combo-enabled-list v-for="(item,index) in enableComboList "
                            :comboItem="item"
                            :comboIndex="index"
                            :key="index"
                            :dataList="enableComboList "
                            @selectedItemDetails="doAfterSelectedItemDetails"
                            @afterMoveCombo="getSelectEnableCombo"></combo-enabled-list>
        <div class="WKT_discount_package_list">
          <div class="WKT_discount_package_blcok">
            <add-box :titleName="'添加套餐'"
                     @onAddBoxClick="onAddComboClick"></add-box>
          </div>
        </div>
        <custom-dialog :title="'选择套餐'"
                       :visible.sync="isSelectComboListShow"
                       :before-close="doComboListCloseClick">
          <combo-selection-list :dataSourceLsit="selectComboList"
                                @addSelectedItem="doAfterAddSelectCombo"
                                @selectedItemDetails="doAfterSelectedItemDetails"
                                @noCancelSelected="doComboListCloseClick"></combo-selection-list>
        </custom-dialog>

        <custom-dialog :title="'套餐详情'"
                       :visible.sync="isSelectComboDetailsShow"
                       :before-close="doComboDetailsCloseClick">
          <combo-select-details :detailInfo="selectComboDetailInfo"></combo-select-details>
        </custom-dialog>

        <custom-dialog :title="'历史套餐'"
                       width="436px"
                       :visible.sync="isHistoryComboShow"
                       :before-close="doHistoryComboCloseClick">
          <combo-history-list :historyComboList="historyComboList"
                              @onStartComboClick="onStartComboClick"></combo-history-list>
        </custom-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import comboEnabledList from './combo-enabled-list';
import comboSelectDetails from './combo-select-details';
import comboHistoryList from './combo-list/combo-history-list';
import comboSelectionList from './combo-list/combo-selection-list';
import addBox from '../common/add-box';
import { SelectEnableCombo, SelectDisEnableCombo, SelectCombo, GetComboInfo, AddCombo, EnableCombo } from '../../../API/workbench';

export default {
  components: { comboEnabledList, comboSelectionList, comboSelectDetails, comboHistoryList, addBox },
  data () {
    return {
      // 查询套餐
      parameter: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
        sequence: '', //	字符串	可选		数据源：GET	 排序方向
        searchText: ''//	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
      },
      enableComboList: [], // 已启用的套餐列表
      selectComboList: [],  // 选择未上架的套餐列表
      historyComboList: [], // 历史套餐列表
      selectComboDetailInfo: {}, // 套餐详情
      isSelectComboListShow: false, // 选择套餐列表显示
      isSelectComboDetailsShow: false, // 选中套餐详情显示
      isHistoryComboShow: false, // 历史套餐列表显示
      enableComboItem: {}//

    };
  },
  props: {

  },
  created () {
    console.log(this.dataInfo, 'WKT门店信息');
    this.getSelectEnableCombo();
  },
  watch: {

  },
  computed: {

  },
  methods: {
    // 查询已启用的套餐
    getSelectEnableCombo () {
      SelectEnableCombo(this.parameter).then(result => {
        console.log(result.data.PageDataList, '查询已启用的套餐');
        this.enableComboList = result.data.PageDataList;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 查询已停用的历史套餐
    onHistoryComboClick () {
      SelectDisEnableCombo(this.parameter).then(result => {
        this.isHistoryComboShow = true;
        this.historyComboList = result.data.PageDataList;
        console.log(result.data.PageDataList, '查询已停用的历史套餐');
      });
    },
    // 启用选择的历史套餐
    onStartComboClick (item, index) {
      console.log(this.enableComboItem, 'this.enableComboItem');
      if (item.id == this.enableComboItem.id) {
        return false;
      }
      this.enableComboItem = item;
      EnableCombo(item.id).then(result => {
        this.historyComboList.splice(index, 1);
        this.enableComboList.push(item);
        if (this.historyComboList.length == 0) { // 没有可启用的项时，成功后关闭弹窗
          this.doHistoryComboCloseClick();
        }
        this.enableComboItem = {};// 启用成功后初始化
        layer.alert('该套餐启用成功');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 选择套餐添加
    onAddComboClick () {
      SelectCombo(this.parameter).then(result => {
        console.log(result.data.PageDataList, '选择套餐添加');
        result.data.PageDataList.forEach(o => {
          o.isOpt = false;
        });
        this.selectComboList = result.data.PageDataList;
        this.isSelectComboListShow = true;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 添加套餐
    doAfterAddSelectCombo (item, callBack) {
      console.log(item, '添加套餐');
      let flag = false;
      if (!item || !item.TableID) {
        layer.alert('请先选择套餐');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      let Obj = { ComboID: item.TableID, combo: item.PackageName, ComboColor: '', Sort: '' };// asc
      AddCombo(Obj).then(result => {
        this.getSelectEnableCombo();
        this.isSelectComboListShow = false;
        layer.alert('套餐添加成功');
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 查看套餐详情
    doAfterSelectedItemDetails (item) {
      console.log(item, '查看套餐详情');
      GetComboInfo(item.TableID).then(result => {
        // this.isSelectComboListShow = false;
        this.isSelectComboDetailsShow = true;
        this.selectComboDetailInfo = result.data;
      });
    },
    doComboListCloseClick () {
      this.isSelectComboListShow = false;
    },
    doComboDetailsCloseClick () {
      this.isSelectComboDetailsShow = false;
    },
    doHistoryComboCloseClick () {
      this.isHistoryComboShow = false;
    },
    // 编辑机构logo
    doAfterUploadImgSuccess (url) {
      console.log(url, '编辑机构logo');
    }
  }
};
</script>

<style>
</style>