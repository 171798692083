<template>
  <div class="title_date date_year fl"
       :class="{title_date_blue:isBorder}"
       @click.stop="showInputTime">
    <!-- <div class="date_time_year"
         v-if="vType=='year'?true:false">
      <span>年</span>
    </div> -->
    <el-date-picker v-model="selectedDate"
                    prefix-icon="none"
                    clear-icon="none"
                    :format="isYear"
                    :type="vType"
                    :editable="false"
                    :picker-options="pickerOptions"
                    @focus="focusEvent($event)"
                    @blur="closedPicker"
                    @change="chgDate"
                    placeholder="选择时间">
    </el-date-picker>

  </div>
</template>
<script>
export default {
  name: 'singleYearPicker',
  data () {
    return {
      isBorder: false,
      selectedDate: Date.new(),
      pickerOptions: {
        // 限制日期
        disabledDate: this.disabledDate
      }
    };
  },
  props: {
    type: String,
    defaultDate: {
      type: [Date, String],
      default: () => {
        return Date.new();
      }
    },
    isDisableNow: {       // 禁止选中以后的日期.
      type: Boolean,
      default: false
    }
  },
  created () {
    if (this.defaultDate) {
      this.selectedDate = this.defaultDate;
    }
  },
  computed: {
    isYear () {
      if (this.vType == 'year') {
        return 'yyyy年';
      } else if (this.vType == 'month') {
        return 'yyyy-MM';
      } else {
        return 'yyyy-MM-dd';
      }
    },
    vType () {
      if (this.type) {
        return this.type;
      } else {
        return 'year';
      }
    }
  },
  methods: {
    disabledDate (time) {
      if (this.isDisableNow) {
        return time.getTime() > Date.new().getTime();
      } else {
        return time.getTime() > Date.new('2099-01-01').getTime();
      }
    },
    chgDate (val) {
      let startTime;
      let endTime;
      if (this.vType == 'year') {
        startTime = val.getFullYear() + '-01-01';
        endTime = val.getFullYear() + '-12-31';
      } else {
        startTime = this.$utils.formatDateStr(val.toLocaleDateString(), 'yyyy-MM-dd');
        endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(val), 'yyyy-MM-dd');
      }
      console.log(val, startTime, endTime);

      this.$emit('chgDate', val, startTime, endTime);
    },
    cleanTime () {
      this.selectedDate = '';
    },
    // 这是触发时间空间选中弹出.
    showInputTime () {
      this.$el.querySelector('.el-input__inner').focus();
    },
    focusEvent () {
      this.isBorder = true;
      this.$emit('focusEvent');
    },
    closedPicker () {
      this.isBorder = false;
      this.$emit('blurEvent');
    }
  }
};
</script>
<style scoped>
/* .date_time_year {
  position: absolute;
  z-index: 10;
  left: 42px;
  top: 1px;
} */
.el-input--prefix >>> .el-input__inner {
  cursor: pointer;
}
</style>


