<template>
  <div class="merchantsCounselorWorkbench all_workbench_view all_width">
    <!-- left 招商顾问列表-->
    <left-investor-list ref="investorList"
                        @expandTableList="expandTableList"
                        @chooseInvestorDetails="chooseInvestorDetails"
                        @chooseInvestor="chooseInvestor"></left-investor-list>
    <!-- right 详情-->
    <right-investor-detail v-if="isShowRightContent"
                           ref="rightInvestorDetail"
                           :investorInfo="CurSelectionInvestorData"
                           @updateData="updateData"></right-investor-detail>
    <dialog-factory ref="merchantsCounselorWorkbenchDialogFactory"
                    :key="'merchantsCounselorWorkbench'"
                    :routerName="'merchantsCounselorWorkbench'"></dialog-factory>

    <dialog-factory-common ref="dialogFactoryCommon"></dialog-factory-common>
  </div>
</template>

<script>
import leftInvestorList from './left-investor-list';
import rightInvestorDetail from './right-investor-detail';
import dialogFactory from '../../../common/dialog-factory';
export default {
  name: 'merchantsCounselorWorkbench',
  data () {
    return {
      CurSelectionInvestorData: null, // 选中的投资人信息
      isShowRightContent: true // 隐藏右边内容
    };
  },
  components: {
    leftInvestorList,
    rightInvestorDetail,
    dialogFactory
  },
  mounted () {
    this.registerBusEvent();
  },
  methods: {
    // 展开投资人表格
    expandTableList (bool) {
      this.isShowRightContent = !bool;
      if (this.isShowRightContent && this.CurSelectionInvestorData) {
        this.$nextTick((o) => {
          this.$refs.rightInvestorDetail.initGetData(
            this.CurSelectionInvestorData
          );
        });
      }
    },
    // 选中投资人
    chooseInvestor (data) {
      this.CurSelectionInvestorData = data;
      if (this.isShowRightContent) {
        this.$nextTick((o) => {
          this.$refs.rightInvestorDetail.initGetData(
            this.CurSelectionInvestorData
          );
        });
      }
    },
       // 切换投资人详情
    chooseInvestorDetails (bool) {
      this.isShowRightContent = !bool;
    },
    updateData (data) {
      console.log('投资顾问工作台updateData更新数据', data);
      this.$refs.investorList.updataData(this.CurSelectionInvestorData.OLAPKey);
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'merchantsCounselorWorkbench') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'merchantsCounselorWorkbench') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCustomDialog', dialogInfo);
      if (this.$refs.merchantsCounselorWorkbenchDialogFactory) {
        this.$refs.merchantsCounselorWorkbenchDialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title,
          'HQ'
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.dialogFactoryCommon) {
        this.$refs.dialogFactoryCommon.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    }
  }
};
</script>

<style>
.all_workbench_view {
  display: flex;
  justify-content: space-between;
}
.merchantsCounselorWorkbench {
  /* width: 1842px !important;
  margin: 20px auto 40px; */
  padding: 26px 40px 0px 40px;
  width: 1360px;
  margin: 0 auto;
}
.merchantsCounselorWorkbench .all_workbench_left_ul {
  margin: 20px 20px 0;
}
</style>

