 <template>
  <div>
    <div class="form_info_detail">
      <!-- 学生个人信息-欠款还款记录 -->
      <div class="single_result_info content_marginRL content_marginTop_30 single_shadow">
        <div class="single_result_info_left">
          <span>欠款金额</span>
        </div>
        <div class="single_result_info_right ">
          <div class="arrears_account">
            <span>{{RepaymentLogSum.toFixed(2)}}</span>
            <!-- 立即还款 -->
            <div class="arrears_account_btn edit_btn">
              <span @click.stop="repaymentEvent()">立即还款</span>
            </div>
          </div>

        </div>
      </div>

      <div class="arrears_repayment_record content_marginRL single_shadow"
           v-if="billRecord.length>0">
        <div class="arrears_repayment_title">
          <span>欠款还款记录</span>
        </div>
        <payment-detail-item v-for="item in billRecord"
                             :key="item.TableID"
                             :item="item"></payment-detail-item>
      </div>
      <!-- 按钮组 -->
      <div>
        <button-info @buttonClickEvent="confirmDebtDetails"
                     @closeDialog="closeDebtDetails"></button-info>
      </div>
    </div>

  </div>
</template>

<script>
import { getCustomerBillRecord, getCustomerBillDetail } from '../../../API/workbench';
import paymentDetailItem from '../../common/payment-detail-item';
export default {
  data () {
    return {
      repaymentItemShow: false,
      billRecord: [],
      studentInfo: {},
      RepaymentLog: 0, // 还款记录
      RepaymentLogSum: 0    // 还款记录总额
    };
  },
  components: {
    paymentDetailItem
  },
  props: {
    studentItem: {
      type: Object,
      default: function () {
        return {
          CustKey: 0
        };
      }
    }
  },
  watch: {
    studentItem () {
      // 初始化学生信息.
      this.studentItem.DebtAmount = Math.round(this.studentItem.DebtAmount * 100) / 100;
      this.studentInfo = this.studentItem;
    },
    studentInfo () {
      this.getStudentBillRecord();
    }
  },
  created () {
    this.studentInfo = this.studentItem;
  },
  methods: {
    // 传递按钮事件
    confirmDebtDetails () {
      this.$emit('confirmDebtDetails');
      // this.closeDebtDetails();
    },
    // 点击取消
    closeDebtDetails () {
      this.$emit('closeDebtDetails', false);          // 学员档案中 传fasle为关闭页面.
    },

    getStudentBillRecord () {  // 学生-获取所有欠款还款记录.
      getCustomerBillRecord(this.studentInfo.CustKey).then(result => {
        this.billRecord = result.data;
        result.data.forEach(e => {
          this.RepaymentLog += Number(e.RefundAmount);
        });
        this.getStudentBillDetail();
      }).catch(error => {
        console.log(error);
      });
    },
    getStudentBillDetail () {   // 返回当前客户待还款金额（待还款 = 总欠款 - 已还款 ）(可以忽略)
      getCustomerBillDetail(this.studentInfo.CustKey).then(result => {
        let totalBill = result.data.TotalBill;
        // this.RepaymentLogSum = totalBill - this.RepaymentLog;
        this.RepaymentLogSum = result.data.ToBeRepaid
      }).catch(error => {
        console.log(error);
      });
    },
    repaymentEvent () {
      this.$emit('repaymentEvent', this.RepaymentLogSum);
    }

  }
};
</script>

  <style>
</style>