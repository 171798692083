<template>
  <div class="payment_statistics_top height_type box_shadow_border_div">
    <div class="payment_moneyType_ul">
      <div class="payment_moneyType_list"
           @click.stop="changeTypeSearch('DAY')"
           :class="searchTime.DateType == 'DAY' ? 'opt' : ''">
        <div class="list_title">本月总营收</div>
        <div class="list_content">
          ￥{{ $utils.setMoneyformatToParseInt(reportDetail.MonthRevenue) }}
        </div>
      </div>
      <div class="payment_moneyType_list"
           @click.stop="changeTypeSearch('MONTH')"
           :class="searchTime.DateType == 'MONTH' ? 'opt' : ''">
        <div class="list_title">本年总营收</div>
        <div class="list_content">
          ￥{{ $utils.setMoneyformatToParseInt(reportDetail.YearRevenue) }}
        </div>
      </div>
      <div class="payment_moneyType_list no_button">
        <div class="list_title">累计总营收</div>
        <div class="list_content">
          ￥{{ $utils.setMoneyformatToParseInt(reportDetail.TotalRevenue) }}
        </div>
      </div>
    </div>
    <div class="payment_echarts_box"
         style="display:block">
      <div class="chain_mendian_center_left ">
        <div class="left_type_text type_blue">
          总营收
        </div>
        <div class="left_type_text type_red">
          课消收入
        </div>
        <div class="left_type_text type_green">
          年月日卡收入
        </div>
      </div>
      <doubleTypeEchart :echartID="'dual_histogram5'"
                        :reqList="monthList"
                        :nowDataList="nowList"
                        :preDataList="oldList"
                        :nextDataList="nextList"
                        :echartType="'line'"
                        ref="echart"></doubleTypeEchart>
    </div>
  </div>
</template>
<script>
import doubleTypeEchart from './line-type-echart';
import {
  GetOperatingReceiptReportForDate,
  GetOperatingReceiptReportByGroup,
  GetInComePayReportForProceeds
} from '../../../API/workbench';
export default {
  data () {
    return {
      searchTime: {
        // 收款查询日期
        startTime: '',
        endTime: '',
        DateType: 'MONTH'
      },
      reportDetail: {
        MonthRevenue: 0,
        YearRevenue: 0,
        TotalRevenue: 0
      }, // 基本统计数据
      paymentDetail: [],
      monthList: [
        '01月',
        '02月',
        '03月',
        '04月',
        '05月',
        '06月',
        '07月',
        '08月',
        '09月',
        '10月',
        '11月',
        '12月'
      ],
      nowList: [],
      oldList: [],
      nextList: []
    };
  },
  components: {
    doubleTypeEchart
  },
  created () {
    this.getReceiptAndPaymentReport();
    this.changeTypeSearch('MONTH');
  },
  methods: {
    // 点击echart触发事件
    doAfterClickEchart (item) { },
    // 获取基本统计
    getReceiptAndPaymentReport () {
      GetOperatingReceiptReportForDate()
        .then((result) => {
          this.reportDetail = result.data;
          console.log(result.data, 'GetOperatingReceiptReportForDate');
        })
        .catch((error) => {
          console.log(error, 'errorGetInComePayReportForDate');
        });
    },
    // 获取营收
    getPaymentListReport (data) {
      GetOperatingReceiptReportByGroup(data)
        .then((result) => {
          this.paymentDetail = result.data;
          this.setMonthList();
          this.nowList = this.getNowList();
          this.oldList = this.getOldList();
          this.nextList = this.getNextList();
          this.$nextTick(() => {
            this.$refs.echart.echartInit();
          });
          console.log(result.data, 'GetInComePayReportForDate');
        })
        .catch((error) => {
          console.log(error, 'errorGetInComePayReportForDate');
        });
    },
    // 点击切换日期搜索
    changeTypeSearch (type) {
      this.searchTime.DateType = type;
      if (type == 'DAY') {
        // 选中月
        this.searchTime.startTime = this.$utils.formatDateStr(
          Date.new(Date.new().setDate(1)).toLocaleDateString(),
          'yyyy-MM-dd'
        );
        this.searchTime.endTime = this.$utils.formatDateStr(
          Date.new().toLocaleDateString(),
          'yyyy-MM-dd'
        );
      } else {
        // //选中年
        this.searchTime.startTime = Date.new().getFullYear() + '-01-01';
        this.searchTime.endTime = this.$utils.formatDateStr(
          Date.new().toLocaleDateString(),
          'yyyy-MM-dd'
        );
      }
      console.log(this.searchTime.startTime, this.searchTime.endTime);
      this.getPaymentListReport(this.searchTime);
    },
    setMonthList () {
      if (this.searchTime.DateType == 'DAY') {
        // 选中月
        this.monthList = this.setEchartXForMonth();
        console.log(this.monthList, '选中月');
      } else {
        // //选中年
        this.monthList = [
          '01月',
          '02月',
          '03月',
          '04月',
          '05月',
          '06月',
          '07月',
          '08月',
          '09月',
          '10月',
          '11月',
          '12月'
        ];
      }
    },
    // 设置X轴
    setEchartXForMonth () {
      let newArr = [];
      this.paymentDetail.forEach((o, index) => {
        let num = index < 9 ? '0' + (index + 1) : index + 1;
        newArr.push(num);
      });
      let n = Number(this.$utils.getCurrentMonthLast().split('-')[2]);
      if (newArr.length < n) {
        for (let i = newArr.length; i < n; i++) {
          newArr.push(i + 1);
        }
      }
      return newArr;
    },
    // 总营收
    getNowList () {
      let nowList = [];
      this.monthList.forEach(p => {
        let item = null;
        if (this.searchTime.DateType == 'MONTH') {
          item = this.paymentDetail.find(o => {     // 区分年
            return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
          });
        } else if (this.searchTime.DateType == 'DAY') {      // 区分月
          item = this.paymentDetail.find(o => {
            return Date.new(o.SetUpTime).getDate() == parseInt(p);
          });
        }
        if (item) {
          nowList.push(Number(item.TotalRevenue).toFixed(0));
        } else {
          nowList.push('');
        }
      });
      return nowList;
    },
    // 课消营收
    getOldList () {
      let nowList = [];
      this.monthList.forEach(p => {
        let item = null;
        if (this.searchTime.DateType == 'MONTH') {
          item = this.paymentDetail.find(o => {     // 区分年
            return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
          });
        } else if (this.searchTime.DateType == 'DAY') {      // 区分月
          item = this.paymentDetail.find(o => {
            return Date.new(o.SetUpTime).getDate() == parseInt(p);
          });
        }
        if (item) {
          nowList.push(Number(item.TotalClassAmount).toFixed(0));
        } else {
          nowList.push('');
        }
      });
      return nowList;
    },
    // 年月卡营收
    getNextList () {
      let nowList = [];
      this.monthList.forEach(p => {
        let item = null;
        if (this.searchTime.DateType == 'MONTH') {
          item = this.paymentDetail.find(o => {     // 区分年
            return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
          });
        } else if (this.searchTime.DateType == 'DAY') {      // 区分月
          item = this.paymentDetail.find(o => {
            return Date.new(o.SetUpTime).getDate() == parseInt(p);
          });
        }
        if (item) {
          nowList.push(Number(item.TotalTermCardClassAmount).toFixed(0));
        } else {
          nowList.push('');
        }
      });
      return nowList;
    }
  }
};
</script>
