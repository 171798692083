<template>
  <div>
    <div class="form_info_edit content_marginTop_30 form_info_list single_shadow content_marginRL pr">
      <div class="form_info_edit"
           v-if="mistiming">
        <div class="form_info_line">
          <div class="form_info_field form_info_field_timeInput">时长</div>
          <div class="form_info_value duration">
            <input type="text"
                   class="duration_style"
                   placeholder="必填"
                   :value="vMistiming +'分钟'"
                   readonly />
          </div>
        </div>
      </div>

      <div class="form_info_edit"
           v-if="timeTitleObj&&timeTitleObj.startTimeName">
        <div class="form_info_line form_info_required">
          <div style="width: 100px; height: 30px; line-height: 30px;">{{timeTitleObj.startTimeName}}</div>
          <div class="form_info_value_HCQ form_info_timeInput">
            <div style="margin-left: 120px;">
              <time-picker :timeRange="selectableRangeForClassTimeName"
                           :placeholder="'必选'"
                           style="width:100%"
                           :readonly="isReadonly"
                           :isNoLimitTimeRange="isNoLimitTimeRange"
                           :dateNumber="vSClassTimeName"
                           @upDateNumber="upSClassTimeName"></time-picker>
            </div>
          </div>
        </div>
        <div class="form_info_line form_info_required">
          <div style="width: 100px; height: 30px; line-height: 30px;">{{timeTitleObj.endTimeName}}</div>
          <div class="form_info_value_HCQ form_info_timeInput">
            <div style="margin-left: 120px;">
              <time-picker :timeRange="selectableRangeForClassTimeName"
                           :isNoLimitTimeRange="isNoLimitTimeRange"
                           :placeholder="'必选'"
                           :readonly="isReadonly"
                           style="width:100%"
                           :dateNumber="vEClassTimeName"
                           @upDateNumber="upEClassTimeName"></time-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="form_info_edit"
           v-else>
        <div class="form_info_line">
          <div class="form_info_field form_info_field_timeInput">上课时间</div>
          <div class="form_info_value_HCQ form_info_timeInput">
            <div>
              <time-picker :timeRange="selectableRangeForClassTimeName"
                           :isNoLimitTimeRange="isNoLimitTimeRange"
                           :placeholder="'必选'"
                           :dateNumber="vSClassTimeName"
                           @upDateNumber="upSClassTimeName"></time-picker>
              至
              <time-picker :timeRange="selectableRangeForClassTimeName"
                           :isNoLimitTimeRange="isNoLimitTimeRange"
                           :placeholder="'必选'"
                           :dateNumber="vEClassTimeName"
                           @upDateNumber="upEClassTimeName"></time-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
    <common-button-group v-on:buttonClickEvent="confirmChooseTimes"
                         v-on:closeDialog="cancleChooseTimes"></common-button-group>

  </div>
</template>
<script>
import timePicker from '../common/time-picker';
export default {
  data () {
    return {
      vSClassTimeName: this.SClassTimeName,
      vEClassTimeName: this.EClassTimeName,
      vMistiming: Number,
      selectableRangeForClassTimeName: '',
      saasClientInfo: this.$store.getters.SaaSClientInfo
    };
  },
  props: {
    SClassTimeName: [String, Number],
    EClassTimeName: [String, Number],
    mistiming: [String, Number],
    timeTitleObj: {
      type: Object,
      default: () => { }
    },
    isNoLimitTimeRange: {
      type: Boolean,
      default: true
    },
    isReadonly: {
      type: Boolean,
      default: true
    }

  },
  components: {
    timePicker
  },
  created () {
    this.selectableRangeForClassTimeName = this.$utils.getSaaSClientSetInfo(64).SetContent + ':00-' + this.$utils.getSaaSClientSetInfo(65).SetContent + ':00';
    this.vMistiming = this.mistiming;
    console.log(this.SClassTimeName, '1');
    console.log(this.EClassTimeName, '2');
    console.log(this.mistiming, '3');
  },
  watch: {},
  methods: {
    upSClassTimeName (date) {
      this.vSClassTimeName = date;
    },
    upEClassTimeName (date) {
      this.vEClassTimeName = date;
    },
    chgStartTime () {
      this.vMistiming = this.mistiming;
      let latestHour = Number(this.$utils.getSaaSClientSetInfo(65).SetContent.split(':')[0]);
      let latestMin = Number(this.$utils.getSaaSClientSetInfo(65).SetContent.split(':')[1]);
      if (this.vSClassTimeName) {
        if (Number(this.vSClassTimeName.slice(4, 5)) < 5) {
          this.vSClassTimeName = this.vSClassTimeName.slice(0, 4) + '0';
        } else {
          this.vSClassTimeName = this.vSClassTimeName.slice(0, 4) + '5';
        }
        var mins = Number(this.vSClassTimeName.slice(0, 2)) * 60 + Number(this.vSClassTimeName.slice(3, 5)) + this.vMistiming; // 结束时间的总分钟数 有异常 会显示NaN
        var hour = 0;
        var min = 0;
        min = mins % 60;
        hour = (mins - min) / 60;
        if (hour >= latestHour) {
          hour = latestHour;
          min = latestMin;
          this.vEClassTimeName = (hour < 10 ? '0' + hour : hour) + ':' + (min < 10 ? '0' + min : min); // 计算结束时间值
          this.calculateDuration();
        } else {
          this.vEClassTimeName = (hour < 10 ? '0' + hour : hour) + ':' + (min < 10 ? '0' + min : min); // 计算结束时间值
        }
      } else {
        return null;
      }
    },
    chgEndTime () {
      this.vMistiming = this.mistimin;
      // 开始时间再次切换为10点之前的会导致结束时间的小时部分缺少0导致数据为NaN
      if (!this.vEClassTimeName || !this.vSClassTimeName) {
        this.vMistiming = 0;
        // EClassTime = this.vEClassTimeName.slice(0, 1) * 60 + Number(this.vEClassTimeName.slice(2, 4));
      } else {
        if (Number(this.vEClassTimeName.slice(4, 5)) < 5) {
          this.vEClassTimeName = this.vEClassTimeName.slice(0, 4) + '0';
        } else {
          this.vEClassTimeName = this.vEClassTimeName.slice(0, 4) + '5';
        }
        this.calculateDuration();
      }
    },
    confirmChooseTimes () {
      // 防止时间为控.
      if (!this.vSClassTimeName || !this.vEClassTimeName) {
        layer.alert('上课时间不能为空');
        return false;
      }
      let startTime = Number(this.vSClassTimeName.slice(0, 2)) * 60 + Number(this.vSClassTimeName.slice(3, 5));
      let endTime = Number(this.vEClassTimeName.slice(0, 2)) * 60 + Number(this.vEClassTimeName.slice(3, 5));
      if (startTime >= endTime) {
        layer.alert('开始时间不能大于结束时间');
      } else {
        this.$emit('confirmChooseEvent', this.vSClassTimeName, this.vEClassTimeName);
      }
    },
    cancleChooseTimes () {
      this.$emit('cancleChooseEvent');
    },
    calculateDuration () {
      var EClassTime = this.vEClassTimeName.slice(0, 2) * 60 + Number(this.vEClassTimeName.slice(3, 5));
      var SClassTime = this.vSClassTimeName.slice(0, 2) * 60 + Number(this.vSClassTimeName.slice(3, 5));
      this.vMistiming = Number(EClassTime - SClassTime);
    }
  }
};
</script>
