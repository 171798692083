<template>
  <!-- 课程详情  表单 -->
  <div class="">
    <div class="form_info_edit form_info_list single_shadow content_marginRL">
      <input-dialog :formTitle="'上课班级'"
                    :formPlaceholder="'默认班级'"
                    :readonly="isEditPlan"
                    :required="true"
                    @showEldilog="showSingleSelectionDialog"
                    v-model="courseData.GradeClassKeyValue"></input-dialog>
      <input-dialog :formTitle="'上课课程'"
                    :formPlaceholder="'必选'"
                    :readonly="isEditPlan"
                    :required="true"
                    @showEldilog="showCourseNameListDialog"
                    v-model="courseData.CourseNameKeyValue"></input-dialog> 
      <input-dialog :formTitle="'上课老师'"
                    :formPlaceholder="'必选'"
                    :required="true"
                    @showEldilog="showTeacherListDialog"
                    v-model="courseData.MTeacherKeyValue"></input-dialog>
      <input-dialog :formTitle="'上课教室'"
                    :formPlaceholder="'可选'"
                    v-model="courseData.ClassroomKeyValue"
                    @showEldilog="showClassRoomDialog"></input-dialog>

      <div class="pr">
        <span class="pa input_date_week"
              style="top:8px">消耗课时</span>
        <input-number-dialog :formTitle="'消耗课时'"
                             :dialogTitleName="'输入课时'"
                             v-model="vPeriodCount"
                             :decimalPlaces="1"
                             :isShowButtonInfo="true"
                             :required="true"
                             :helpDescription="'每节课的扣除课时数。'"
                             :formPlaceholder="'学生上课消耗'"></input-number-dialog>
      </div>
      <input-number :formTitle="'开课人数'"
                    :min="0"
                    v-model="courseData.StartCourseCount"
                    :decimalPlaces="0"
                    :required="true"
                    @inputChange="doAfterChangeStartCourseCount"></input-number>
      <input-number :formTitle="'满员人数'"
                    :min="0"
                    v-model="courseData.FullPeopleCount"
                    :decimalPlaces="0"
                    :required="true"
                    @inputChange="doAfterChangeFullPeopleCount"></input-number>
      <div v-if="vIsShowChargeLeave">
          <input-switch v-model="courseData.IsleaveKey"
                    :formTitle="'请假扣课时'"
                    :required="true"></input-switch>
      </div>
      <div v-if="vIsShowChargeTruant">
          <input-switch v-model="courseData.IsChargesKey"
                    :formTitle="'旷课扣课时'"
                    :required="true"></input-switch>
      </div>
    </div>
    <div class="bottom_text_content" style="margin-top:50px">
      <div class="bottom_text_content_text" style="padding-bottom:10px">
        <div class="bottom_text_content_text_title">
          开课人数：
        </div>
        <div class="bottom_text_content_text_content">
          指开课的最低人数，截止时间预约人数不足，自动取消课程。
        </div>
      </div>
      <div class="bottom_text_content_text" style="padding-top:0px">
        <div class="bottom_text_content_text_title">
          满员人数：
        </div>
        <div class="bottom_text_content_text_content" >
          指可预约人数的上限，预约满员预约自动结束。
        </div>
      </div>
    </div>
    <!-- 修改排课计划 -->
    <div v-if="isEditPlan">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>  
    <div v-else>
      <save-cancel-btn-group :btnText="'下一步'"
                             @confirmClick="nextTime"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
  </div>
</template>

<script>
import {
  getValidClassroomList,
  geTteacherList,
  getUsingClass
} from '../../../API/workbench.js';
export default {
  data () {
    return {
      courseNameList: [],
      teacherList: [],
      classRoomList: [],
      gradeClassList: [],
      courseData: this.$utils.parseJson(this.courseTableData),
      curSelectionClassGradeInfo: {} // 当前选中的班级（用于选中班级后，默认填充上课课程，然后在变更上课课程 进行提示）
    };
  },
  props: {
    courseTableData: Object,
    isEditPlan: Boolean
  },
  computed: {
    vLecTeaSelectInfo: {
      get () {
        return {
          idText: this.courseData.MTeacherKey,
          nameText: this.courseData.MTeacherKeyValue
        };
      },
      set (newValue) {
        this.courseData.MTeacherKey = newValue.idText;
        this.courseData.MTeacherKeyValue = newValue.nameText;
      }
    },
    vBtnText () {
      let str = '下一步';
      if (this.isEditPlan) {
        str = '确定';
      }
      return str;
    },
    vCourseNameList () {
      return (this.$store.getters.courseNameList && this.$store.getters.courseNameList.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; })) || [];
    },
    vPeriodCount: {
      get () {
        return {
          number: this.courseData.PeriodCount,
          UnitName: '课时 / 节课'
        };
      },
      set (newValue) {
        this.courseData.PeriodCount = newValue.number;
      }
    },
    vSaasClientInfo () {
      return this.$store.getters.SaaSClientInfo;
    }, //  2不扣课时 3扣课时 4建课设置
    vIsShowChargeTruant () {
      return this.$utils.getSaaSClientSet(33) == 4 && this.$utils.getSaaSClientSet(59) > 0;
    },
    //  2不扣课时 3扣课时 4建课设置
    vIsShowChargeLeave () {
      return this.$utils.getSaaSClientSet(32) == 4;
    }
  },
  watch: {
    vCourseNameList (n, o) {
      this.getCourseNameList();
    },
    'courseData.IsleaveKey': {
      handler (newVal, oldVal) {
        if (newVal == 1) {
          this.courseData.IsleaveKeyValue = '是';
        } else {
          this.courseData.IsleaveKeyValue = '否';
        }
      },
      deep: true
    },
    'courseData.IsChargesKey': {
      handler (newVal, oldVal) {
        if (newVal == 1) {
          this.courseData.IsChargesKeyValue = '是';
        } else {
          this.courseData.IsChargesKeyValue = '否';
        }
      },
      deep: true
    }
  },
  created () {
    this.getCourseNameList();
    this.loadDataSourceClassRoom();
    this.getValidClassList();
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
    this.initIsSpendClassHour();
  },
  methods: {
    // 请假是否扣课时，旷课是否扣课时
    initIsSpendClassHour () {
      let isLeaveKey = this.$utils.getSaaSClientSet(32);// 请假
      let isTruantKey = this.$utils.getSaaSClientSet(33);// 旷课
      // 2不扣课时 3扣课时 4建课设置
      if (isLeaveKey == 2) {
        this.courseData.IsleaveKey = 0;
      } else if (isLeaveKey == 3) {
        this.courseData.IsleaveKey = 1;
      }
      if (isTruantKey == 2) {
        this.courseData.LeaveClassKey = 0;
      } else if (isLeaveKey == 3) {
        this.courseData.LeaveClassKey = 1;
      }
    },
    // 调用课类课名列表接口
    getCourseNameList () {
      this.$basicData.loadCourseNameList();
    },
    // 调用在职老师接口
    loadDataSourceTeacher () {
      geTteacherList().then(result => {
        this.teacherList = result.data;
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择老师',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.courseData.MTeacherKey, dataSourceList: this.teacherList, required: true, addModuleName: 'yuangong-setting', addModuleTitleName: '添加老师', ModulePowerKey: 44 },
          callBack: {
            onSelectedItem: (data) => { this.doAfterSelectionTeacher(data); }
          }
        });
      });
    },
    // 获取教室列表
    loadDataSourceClassRoom () {
      getValidClassroomList().then(result => {
        this.classRoomList = result.data;
      });
    },
    // 获取有效班级列表
    getValidClassList () {
      getUsingClass().then(result => {
        this.gradeClassList = [];
        result.data.forEach(obj => {
          obj.isActive = false;
          if (this.selectedClassInfo && this.selectedClassInfo.OLAPKey == obj.OLAPKey) {
            obj.isActive = true;
          }
          if (Date.new() <= Date.new(obj.EndTime) || !obj.EndTime) {
            this.gradeClassList.push(obj);
          }
        });
        if (this.courseData.GradeClassKey > 0) {
          let findItem = this.gradeClassList.find(obj => {
            return obj.OLAPKey == this.courseData.GradeClassKey;
          });
          this.courseData.GradeClassKeyValue = findItem.MainDemoName;
          this.curSelectionClassGradeInfo = findItem;
          if (!this.courseData.CourseNameKey && findItem.ClassSetKey && (!findItem.ApplyCorIDsName || findItem.ApplyCorIDsName.split(',').length == 1)) {
            // 该课程是否已停用
            let isExists = this.vCourseNameList.find(obj => { return obj.OLAPKey == findItem.ClassSetKey; });
            if (isExists) {
              this.courseData.CourseNameKey = findItem.ClassSetKey;
              this.courseData.CourseNameKeyValue = findItem.ClassSetKeyValue;
            }
          }
          if (!this.courseData.MTeacherKey && findItem.MTeacherKey) {
            this.courseData.MTeacherKeyValue = findItem.MTeacherKeyValue;
            this.courseData.MTeacherKey = findItem.MTeacherKey;
          }
        }
      });
    },
    // 打开班级列表
    showSingleSelectionDialog () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '选择班级',
        moduleName: 'singleSelectionList',
        data: { selectedKey: this.courseData.GradeClassKey, dataSourceList: this.gradeClassList, required: true, addModuleName: 'squad-class-Setting', ModulePowerKey: 45 },
        callBack: {
          onSelectedItem: (data) => { this.doAfterSelectClassGrade(data); }
        }
      });
    },
    // 选择班级
    doAfterSelectClassGrade (item) {
      this.curSelectionClassGradeInfo = item;
      this.courseData.GradeClassKey = item.OLAPKey;
      if (item.OLAPKey) {
        this.courseData.GradeClassKeyValue = item.MainDemoName;
      } else {
        this.courseData.GradeClassKeyValue = '默认班级';
      }
      this.presetCourseNameAndTeacher(item);
      this.getValidClassList();
    },
    // 根据班级预置课程和老师
    presetCourseNameAndTeacher (item) {
      // 班级预设课程
      if (item.ClassSetKey && (!item.ApplyCorIDsName || item.ApplyCorIDsName.split(',').length == 1)) {
        let isExists = this.vCourseNameList.find(obj => { return obj.OLAPKey == item.ClassSetKey; });
        if (isExists) {
          this.courseData.CourseNameKey = item.ClassSetKey;
          this.courseData.CourseNameKeyValue = item.ClassSetKeyValue;
        }
      }
      if (item.LecturerKey) {
        this.courseData.MTeacherKey = item.LecturerKey;
        this.courseData.MTeacherKeyValue = item.LecturerKeyValue;
      }
    },
    // 打开课程
    showCourseNameListDialog () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '选择课程',
        moduleName: 'singleSelectionList',
        data: { selectedKey: this.courseData.CourseNameKey, dataSourceList: this.vCourseNameList, required: true, addModuleName: 'course-name-setting', ModulePowerKey: 45 },
        callBack: {
          onSelectedItem: (data) => { this.doAfterSelectionCourseName(data); }
        }
      });
    },
    // 选择课程
    doAfterSelectionCourseName (item) {
      if (this.curSelectionClassGradeInfo.ClassSetKey > 0 && this.curSelectionClassGradeInfo.ClassSetKey == this.courseData.CourseNameKey && item.OLAPKey != this.courseData.CourseNameKey) {
        layer.confirm('当前选择的课程不是班级的预设的课程，是否继续？',
          {
            shadeClose: false,
            btn: [
              {
                name: '取消',
                callBack: () => {
                  layer.close();
                  return false;
                }
              },
              {
                name: '确认',
                callBack: () => {
                  layer.close();
                  this.courseData.CourseNameKeyValue = item.MainDemoName;
                  this.courseData.CourseNameKey = item.OLAPKey;
                }
              }
            ]
          }
        );
      } else {
        this.courseData.CourseNameKeyValue = item.MainDemoName;
        this.courseData.CourseNameKey = item.OLAPKey;
        if (!this.courseData.GradeClassKey) {
          let lastStr = item.MainDemoName.substring(item.MainDemoName.length - 1);
          let courseName = '';
          if (lastStr == '课') {
            courseName = item.MainDemoName.substring(0, item.MainDemoName.length - 1);
          } else {
            courseName = item.MainDemoName;
          }

          let findItem = this.gradeClassList.find(obj => { return obj.MainDemoName == courseName + '班'; });
          if (findItem) {
            this.courseData.GradeClassKey = findItem.OLAPKey;
            this.courseData.GradeClassKeyValue = findItem.MainDemoName;
          }
        }
      }
      // 默认上课时长
      // let courseItem = this.courseNameList.find(obj => { return this.courseData.CourseNameKey == obj.OLAPKey; });
      // if (courseItem) {
      //   this.courseData.DefaultTimeCount = Number(courseItem.DefaultTimeCount);
      //   if (this.courseData.DefaultTimeCount > 0) {
      //     this.caculClassTimeNameRange();
      //   }
      // }
    },
    // 打开教室列表
    showClassRoomDialog () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '选择教室',
        moduleName: 'singleSelectionList',
        data: { selectedKey: this.courseData.ClassroomKey, dataSourceList: this.classRoomList, addModuleName: 'classroom-setting', ModulePowerKey: 45 },
        callBack: {
          onSelectedItem: (data) => { this.doAfterSelectClassRoom(data); }
        }
      });
    },
    // 选择教室
    doAfterSelectClassRoom (item) {
      this.courseData.ClassroomKey = item.OLAPKey || 0;
      this.courseData.ClassroomKeyValue = item.MainDemoName || '';
      this.loadDataSourceClassRoom();
    },
    doAfterChangeFullPeopleCount (value) {
      this.courseData.FullPeopleCount = Number(value);
    },
    doAfterChangeStartCourseCount (value) {
      this.courseData.StartCourseCount = Number(value);
    },
    // 验证要提交的数据
    checkSubmitData () {
      let flag = false;
      if (this.courseData.GradeClassKey <= 0) {
        layer.alert('班级不能为空');
        flag = true;
      }else if (this.courseData.CourseNameKey <= 0) {
        layer.alert('课程不能为空');
        flag = true;
      } else if (!(this.courseData.MTeacherKey > 0)) {
        layer.alert('老师不能为空');
        flag = true;
      } else if (this.courseData.PeriodCount <= 0) {
        layer.alert('课消不能小于等于0');
        flag = true;
      } else if(Number(this.courseData.StartCourseCount)<=0){
        layer.alert('开课人数不能小于等于0');
        flag = true;
      }else if(Number(this.courseData.FullPeopleCount)<=0){
        layer.alert('满员人数不能小于等于0');
        flag = true;
      }else if(Number(this.courseData.StartCourseCount)>Number(this.courseData.FullPeopleCount)){
        layer.alert('开课人数不能大于满员人数');
        flag = true;
      }
    
      return flag;
    },
    confirmClick (callBack) {
      let isWrong = this.checkSubmitData();
      if (isWrong) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.$emit('confirmClick', this.courseData, callBack);
    },
    // 下一步
    nextTime () {
      let isWrong = this.checkSubmitData();
      if (isWrong) {
        return false;
      }
      this.courseData.StartCourseCount=Math.round(this.courseData.StartCourseCount)
        this.courseData.FullPeopleCount=Math.round(this.courseData.FullPeopleCount)
      this.$emit('nextStep', this.courseData);
    },
    // 取消
    cancelClick () {
      this.$emit('cancelClick');
    },
    caculClassTimeNameRange () {
      if (this.courseData.SClassTimeName && !this.courseData.EClassTimeName) {
        let earliesHour = Number(this.$utils.getSaaSClientSetInfo(64).SetContent.split(':')[0]);
        let latestHour = Number(this.$utils.getSaaSClientSetInfo(65).SetContent.split(':')[0]);
        let latestMin = Number(this.$utils.getSaaSClientSetInfo(65).SetContent.split(':')[1]);
        var mins = Number(this.courseData.SClassTimeName.slice(0, 2)) * 60 + Number(this.courseData.SClassTimeName.slice(3, 5)) + this.courseData.DefaultTimeCount;
        var hour = 0;
        var min = 0;
        min = mins % 60;
        hour = (mins - min) / 60;
        hour = hour < earliesHour ? earliesHour : hour;
        if (hour >= latestHour) {
          hour = latestHour;
          min = latestMin;
          this.courseData.EClassTimeName = (hour < 10 ? '0' + hour : hour) + ':' + (min < 10 ? '0' + min : min); // 计算结束时间值
          this.calculateDuration();
        } else {
          this.courseData.EClassTimeName = (hour < 10 ? '0' + hour : hour) + ':' + (min < 10 ? '0' + min : min); // 计算结束时间值
        }
      }
    },
    showTeacherListDialog () {
      this.loadDataSourceTeacher();
    },
    doAfterSelectionTeacher (item) {
      this.courseData.MTeacherKey = item.OLAPKey;
      this.courseData.MTeacherKeyValue = item.MainDemoName;
    }
  }

};
</script>
<style scoped>
.bottom_text_content{
  display: flex;
  flex-direction: column;
  
  margin: 20px;
  background: linear-gradient(0deg, #F9FBFD, #F9FBFD), #F5F5F5;
  border-radius: 8px;
}
.bottom_text_content_text{
  display: flex;
  flex-direction: row;
  color: #999;
  padding: 20px;
  font-size: 12px;
  line-height: 16px;
}
.bottom_text_content_text_title{
  width: 68px;
}
.bottom_text_content_text_content{
  flex: 1;
}

</style>