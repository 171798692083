<template>
  <div v-dialogDrag
       class="popup_dialog"
       v-if="vIsVisible||visible">
    <!-- 弹出框 -->
    <div class="popup_dialog_box"
         :style="popupDialogBoxStyle">
      <!-- 表头 -->
      <div class="popup_dialog_box_header"
           v-show="title">
        <span class="popup_dialog_box_header_title">{{title}}</span>
        <span v-if="moduleDescription">
          <input-form-tip :tips='moduleDescription'></input-form-tip>
        </span>
        <span class="popup_dialog_box_header_slogn">{{descriptionText}}</span>
        <div class="popup_dialog_box_header_btn"
             @click.stop="handleClose"></div>
      </div>
      <!-- 内容 -->
      <div class="popup_dialog_box_content clearfix">
        <slot></slot>
      </div>
      <dialog-factory-common></dialog-factory-common>
    </div>
  </div>
</template> 

<script>
export default {
  data () {
    return {
      popupDialogBoxStyle: null
    };
  },
  props: {
    title: String, // 标题
    dialogParamList: Array, // 打开弹窗所带的参数
    curModuleName: { // 当前所打开弹窗的ModuleName
      type: String,
      default: ''
    },
    showPosition: { // 弹窗位置（偏上 居中 偏下）
      type: String,
      default: 'visibleMiddle'
    },
    visible: {// 显示隐藏
      type: Boolean,
      default: false
    },
    beforeClose: Function, // 关闭后的回调
    width: {
      type: String,
      default: '159.9rem'
    },
    top: { // 距离顶部位置
      type: [Number, String],
      default: 30
    },
    upTop: { // 距离顶部偏移位置
      type: [Number, String],
      default: 0
    },
    closeOnClickModal: {// 点击遮罩层关闭弹窗
      type: Boolean,
      default: false
    },
    descriptionText: {// 说明文字
      type: String,
      default: ''
    },
    appendToBody: {// 弹窗添加到Body
      type: Boolean,
      default: false
    },
    moduleDescription: {
      type: String,
      default: ''
    },
    isOpenFactoryDialogCommon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    vIsVisible () {
      let flag = false;
      if (this.dialogParamList && this.dialogParamList.length > 0) {
        let visibleDialog = this.dialogParamList.find(obj => { return obj.moduleName == this.curModuleName; });
        if (visibleDialog) {
          flag = true;
        }
      }
      return flag;
    }
  },
  created () {
    if (this.visible) {
      this.initPopupDialogBoxStyle();
    }
  },
  mounted () {
    if (this.appendToBody) {
      document.body.appendChild(this.$el);
    }
  },
  watch: {
    visible (n, o) {
      if (n) {
        this.initPopupDialogBoxStyle();
        this.$nextTick(() => {
          this.appendDialogToElement();
        });
      } else {
        this.hide();
      }
    },
    vIsVisible (n, o) {
      if (n) {
        this.initPopupDialogBoxStyle();
        this.$nextTick(() => {
          this.appendDialogToElement();
        });
      } else {
        this.handleClose();
      }
    },
    width (n, o) {
      if (n != o && this.popupDialogBoxStyle) {
        this.popupDialogBoxStyle.width = n;
      }
    }
  },
  methods: {
    initPopupDialogBoxStyle () {
      let top = 0;
      this.$store.commit('setCurOpenDialogName', this.title);
      this.$nextTick(() => {
        this.popupDialogBoxStyle = {};
        if (this.width) {
          this.popupDialogBoxStyle.width = this.width;
        }
        let dialogHeight = this.$el.querySelector('.popup_dialog_box').offsetHeight;
        console.log('弹窗高度：' + dialogHeight + ';scrollTop:' + document.documentElement.scrollTop + '浏览器高度clientHeight:' + document.documentElement.clientHeight)
        if (this.showPosition == 'visibleMiddle') {
          top = document.documentElement.scrollTop + document.documentElement.clientHeight / 2 - dialogHeight / 2;
        } else if (this.showPosition == 'top') {
          top = document.documentElement.scrollTop + Number(this.top) + 70;
        } else if (this.showPosition == 'bottom') {
        } else if (this.showPosition == 'clickNearby') {
        }
        if (this.upTop == 0) {
          this.popupDialogBoxStyle.top = parseInt(top) + 'px';
        } else {
          console.log('upTop', this.upTop)
          this.popupDialogBoxStyle.top = (parseInt(top) - Number(this.upTop)) + 'px';
        }
        this.setMaxZIndex();
      });
    },
    setMaxZIndex () {
      let maxZIndex = this.caculateMaxZIndex();
      this.popupDialogBoxStyle.zIndex = maxZIndex + 2;
      this.$store.commit('SetPopupMaxZIndex', maxZIndex + 1);
    },
    caculateMaxZIndex () {
      let shadeDiv = document.querySelector('.popup_dialog_shade');
      if (!shadeDiv) {
        this.$store.commit('SetPopupShade', true);
      }
      let curRouterElement = document.getElementById(this.$route.name);
      let divs = curRouterElement.querySelectorAll('.popup_dialog_box');
      let max = 0;
      for (let i = 0; i < divs.length; i++) {
        let divEL = divs[i];
        max = Math.max(max, divEL.style.zIndex || 0);
      }
      // 如果打开了面容识别窗口
      if (this.$store.getters.faceSign) {
        let faceDialogZIndex = document.getElementsByClassName('popup_dialog_box_of_face')[0].style.zIndex;
        max = Math.max(max, faceDialogZIndex);
      }
      if (max <= 0) {
        max = 20;
      }
      return max;
    },
    // 把弹窗添加到指定元素上
    appendDialogToElement () {
      let curRouterElement = document.getElementById(this.$route.name);
      if (curRouterElement.getElementsByClassName('custom-dialog-factory')[0]) {
        curRouterElement.getElementsByClassName('custom-dialog-factory')[0].appendChild(this.$el);
      }
    },
    removeDialogFromElement () {
      let deleteZIndex = this.$el.querySelector('.popup_dialog_box').style.zIndex;
      let parentNode = this.$el.parentNode;
      let dialogList = parentNode.querySelectorAll('.popup_dialog');
      dialogList.forEach(el => {
        let zIndex = el.querySelector('.popup_dialog_box').style.zIndex;
        if (zIndex > deleteZIndex) {
          parentNode.removeChild(el);
        }
      });
    },
    handleClose () {
      this.removeDialogFromElement();
      if (typeof this.beforeClose === 'function') {
        this.beforeClose();
      }
      if (this.dialogParamList && this.dialogParamList.length > 0) {
        let visibleDialog = this.dialogParamList.find(obj => { return obj.moduleName == this.curModuleName; });
        if (visibleDialog) {
          this.$emit('beforeClose', this.curModuleName);
        }
      }
      this.hide();
    },
    hide () {
      let shadeDiv = document.querySelector('.popup_dialog_shade');
      if (shadeDiv) {
        // 关闭时 把zIndex设置为零 下次打开重计算
        if (this.popupDialogBoxStyle) {
          this.popupDialogBoxStyle.zIndex = 0;
        }
        let maxZIndex = this.caculateMaxZIndex();
        this.$store.commit('SetPopupMaxZIndex', maxZIndex - 3);
      }
    },

    destroyed () {
      if (this.appendToBody && this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
    }
  }
};
</script>
<style scoped>
.popup_dialog_box {
  background-color: #323a46;
  border-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      #e5e8e9d4 50%,
      rgba(255, 255, 255, 0) 99%
    )
    2 2 0 0;
  /* background: red; */
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.popup_dialog_box::before {
  border-left: 1px solid;
  display: block;
  content: '';
  position: absolute;
  height: 80%;
  top: 11.7rem;
  left: -0.39rem;
  border-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #e5e8e9d4 50%,
      rgba(255, 255, 255, 0) 99%
    )
    2 2 2 2;
}
.popup_dialog_box::after {
  border-right: 0.39rem solid;
  display: block;
  content: '';
  position: absolute;
  height: 60%;
  right: -0.39rem;
  top: 23.4rem;
  border-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #e5e8e9d4 50%,
      rgba(255, 255, 255, 0) 99%
    )
    2 2 2 2;
}
.popup_dialog_box_header_title {
  color: #7d8596;
  position: absolute;
  top: 8.08rem;
  font-size: 7.02rem;
  line-height: 9.36rem;
}
.popup_dialog_box_header_btn {
  width: 13.65rem;
  height: 13.65rem;
  border-radius: 50%;
  top: 2.23rem;
  right: 3.315rem;
  background-size: 5.12rem;
}
.popup_dialog_box_header {
  background-color: #323a46;
  border-radius: 5.85rem;
  padding: 5.68rem 7.8rem;
}
.popup_dialog_box_content {
  background-color: #323a46;
  border-bottom-left-radius: 7.8rem !important;
  border-bottom-right-radius: 9.36rem !important;
}
.popup_dialog_box_content >>> .form_footer_btn_group {
  background-color: #323a46;
}
.popup_dialog_box_content >>> .form_info_edit {
  background-color: #323a46;
  border-bottom-left-radius: 9.36rem !important;
  border-bottom-right-radius: 9.36rem !important;
}
.popup_dialog_box_content >>> .ApplyAuth {
  margin-bottom: 19.5rem;
}
.popup_dialog_box_content >>> .form_footer_btn_group {
  border-top: inherit;
}
.popup_dialog_box_content >>> .single_result_detail .form_footer_btn_group {
  padding: 10.24rem 7.8rem;
  border-bottom-left-radius: 9.36rem !important;
  border-bottom-right-radius: 9.36rem !important;
}
.popup_dialog_box_content >>> .btn_fixed_bottom_placeholder {
  height: 26.52rem;
}
.popup_dialog_box_content >>> .sumbit_btn {
  border: initial;
  border-radius: 9.75rem !important;
  height: 18.33rem !important;
  background-color: #e5e5e5 !important;
  color: #21262e !important;
  font-size: 5.85rem;
  font-weight: 500;
  width: 66.3rem;
  line-height: 18.33rem;
}
.popup_dialog_box_content >>> .canel_btn {
  border: initial;
  border-radius: 9.75rem !important;
  height: 18.33rem !important;
  background-color: #e5e5e5 !important;
  color: #21262e;
  font-size: 5.85rem;
  font-weight: 500;
  width: 66.3rem;
  line-height: 18.33rem;
}
.popup_dialog_box_content >>> .canel_btn:hover {
  background-color: #3498db !important;
  cursor: pointer;
  color: #ffffff;
}
.popup_dialog_box_content >>> .sumbit_btn:hover {
  /* background: #777e90; */
  background-color: #3498db !important;
  color: #ffffff !important;
  cursor: pointer;
}
.popup_dialog_box_header_btn:hover {
  box-shadow: 0px 1.95rem 7.8rem rgba(22, 131, 249, 0.7);
  cursor: pointer;
}
.popup_dialog_box_content >>> .form_info_list {
  padding: 0 7.8rem;
}
.popup_dialog_box_content >>> .form_info_line {
  background-color: #21262e;
  min-height: 17.55rem;
  margin: 5.46rem 0;
  padding: 0;
  line-height: 17.55rem;
  border-bottom: inherit;
  border-radius: 9.75rem;
}
.popup_dialog_box_content >>> .form_info_line:hover {
  box-shadow: 0.22rem 1.05rem 1rem rgba(22, 131, 249, 0.7);
}
.popup_dialog_box_content >>> .form_info_field {
  line-height: 17.55rem;
  color: #7d8596;
  margin-left: 9.75rem;
  font-size: 5.46rem;
}
.popup_dialog_box_content >>> .form_info_value {
  line-height: 17.55rem;
  color: #ffffff4d;
  padding-left: 3.9rem;
  font-size: 5.46rem;
}
.popup_dialog_box_content >>> .form_info_required:before {
  left: 5.46rem;
  top: 6.63rem;
  line-height: 7.02rem;
  font-size: 7.02rem;
}
.popup_dialog_box_content >>> .form_info_edit .form_info_select {
  margin-right: 7.8rem;
}
.popup_dialog_box_content >>> .form_info_value input {
  color: #ffffff;
  padding-right: 10.14rem;
  height: 11.7rem;
  line-height: 11.7rem;
  font-size: 6.02rem;
}
.popup_dialog_box_content >>> .DurationCount {
  background-color: #21262e;
  min-height: 17.55rem;
  margin: 5.46rem 0;
  padding: 0;
  line-height: 17.55rem;
  border-bottom: inherit;
  border-radius: 9.75rem;
  height: 17.55rem;
  font-size: 5.46rem;
}
.popup_dialog_box_content >>> .DurationCount:hover {
  box-shadow: 0.22rem 1.05rem 1rem rgba(22, 131, 249, 0.7);
}
.popup_dialog_box_content >>> .DurationCount .class_over_time {
  line-height: 17.55rem;
  color: #7d8596;
  margin-left: 9.75rem;
}
.popup_dialog_box_content >>> .el-date-editor {
  background-color: #21262e;
  border-radius: 5.85rem;
}
.popup_dialog_box_content >>> .DurationCount .el-input__inner {
  width: 100%;
  border-radius: 5.85rem;
  background-color: #21262e;
  margin-right: 7.8rem;
  border: inherit;
  padding-left: 24.35rem;
  background: url(../../../public/image/next_icon@2x.png) no-repeat 85%;
  background-size: 3.12rem;
  cursor: pointer;
  color: #ffffff;
  padding-right: 11.7rem;
  min-height: 11.7rem;
  font-size: 6.02rem;
}
.popup_dialog_box_content >>> .form_info_edit .form_info_select {
  background-size: 3.12rem;
}
.popup_dialog_box_content >>> .DurationCount .time_picker {
  display: flex;
  width: 54.6rem;
}
.popup_dialog_box_content >>> .el-input__suffix {
  display: none;
}

.popup_dialog_box_content >>> .el-input__prefix {
  display: none;
}
.popup_dialog_box_content >>> .time_picker_box {
  background-color: #323a46;
  border-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      #e5e8e9 50%,
      rgba(255, 255, 255, 0) 99%
    )
    2 2 2 2;
  top: -3.12rem;
  left: 62.79rem;
  width: 160.3rem;
  height: 133.38rem;
  border-radius: 3.12rem 3.12rem 0 0;
}
.popup_dialog_box_content >>> .time_picker_date {
  padding: 0 7.8rem;
  margin-bottom: -1.56rem;
}
.popup_dialog_box_content >>> .form_info_detail {
  border-bottom-left-radius: 5.85rem;
  border-bottom-right-radius: 5.85rem;
}
.popup_dialog_box_content >>> .time_picker_top {
  background-color: #21262e;
  border-top: 0.14rem solid rgba(255, 255, 255, 0.2) !important;
  border-left: 0.14rem solid rgba(255, 255, 255, 0.2) !important;
  border-right: 0.14rem solid rgba(255, 255, 255, 0.2) !important;
  border-bottom: 0.14rem dashed #ececec2b !important;
  border-radius: 5.85rem 5.85rem 0 0;
}
.popup_dialog_box_content >>> .time_picker_bottom {
  background-color: #21262e;
  border-bottom: 0.14rem solid rgba(255, 255, 255, 0.2) !important;
  border-left: 0.14rem solid rgba(255, 255, 255, 0.2) !important;
  border-right: 0.14rem solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 0px 0px 5.85rem 5.85rem;
}
.popup_dialog_box_content >>> .time_picker_bottom .time_picker_type {
  border-radius: 5.07rem 0 0 0px;
  width: 14.82rem;
  height: 10.92rem;
  font-size: 4.68rem;
  line-height: 7.02rem;
  padding-left: 1.17rem;
}
.popup_dialog_box_content >>> .time_picker_top .time_picker_type {
  border-radius: 5.07rem 0 0 0px;
  width: 14.82rem;
  height: 10.92rem;
  font-size: 4.68rem;
  line-height: 7.02rem;
  padding-left: 1.17rem;
}
.popup_dialog_box_content >>> .time_picker_type {
  color: #323a46;
  background: url(../../../public/image/picker_type.png) no-repeat 0 0;
  background-size: 12.48rem;
}
.popup_dialog_box_content >>> .time_picker_list.font_gray {
  color: #42464e;
}
.popup_dialog_box_content >>> .time_picker_list {
  color: #9ea1a5;
  width: 10.92rem;
  height: 10.92rem;
  line-height: 10.92rem;
  margin-right: 1.17rem;
  margin-bottom: 3.12rem;
}
.popup_dialog_box_content >>> .time_picker_list.opt {
  background: #1683f9;
  color: #ffffff;
}
.popup_dialog_box_content >>> .time_picker_right_content {
  background-color: #21262e;
  border-radius: 5.85rem;
  border: 0.14rem solid rgba(255, 255, 255, 0.2);
  width: 41.78rem;
  height: 88.06rem;
  padding: 3.9rem 7.8rem;
}
.popup_dialog_box_content >>> .time_picker_content {
  border: inherit;
  padding: 3.9rem 0.24rem 11.24rem 5.7rem;
}
.popup_dialog_box_content >>> .time_picker_hour {
  width: 85.02rem;
}
.popup_dialog_box_content >>> .time_picker_title {
  line-height: 17.16rem;
  font-size: 5.46rem;
}
.popup_dialog_box_content >>> .form_footer_btn_group {
  align-items: center;
  padding-top: 5.85rem;
}
.popup_dialog_box_content >>> .form_info_serach_box,
.searchBar_content {
  background-color: #323a46;
}
.popup_dialog_box_content >>> .defult_dialog_searchform {
  background-color: inherit;
  margin: 0 0.39rem;
  padding: 6.24rem 7.8rem 3.9rem;
}
.popup_dialog_box_content >>> .student_search_form {
  border-radius: 9.75rem;
  background-color: #21262e;
  border: inherit;
  padding-left: 7.8rem;
  color: #21262e;
  height: 17.94rem;
  font-size: 5.46rem;
}
.popup_dialog_box_content >>> .course_auth_qrcode_img img {
  border: 0.39rem solid #ffffff;
  height: 74.295rem;
  width: 74.295rem;
}
.popup_dialog_box_content >>> .student_search_form input {
  background: transparent url(../../../public/image/search_icon_01.png)
    no-repeat 122.07rem !important;
  padding-left: 0;
  color: #7d8596;
  padding-right: 25.74rem;
  background-size: 8.07rem !important;
}
.popup_dialog_box_content >>> .form_info_serach_box {
  border-radius: 5.85rem;
}
.popup_dialog_box_content >>> .student_search_form input:focus {
  color: #ffffff;
}
.popup_dialog_box_content >>> .search_result_list {
  background-color: inherit;
  overflow-x: hidden;
  width: 156.39rem;
  min-width: 156rem;
  margin-bottom: 7.8rem;
  height: 191.1rem;
}
.popup_dialog_box_content >>> .single_result {
  background-color: inherit;
  border-bottom: 0.39rem solid rgba(255, 255, 255, 0.2);
}
.popup_dialog_box_content >>> .all_list_bottom {
  background-color: inherit;
  border-top: inherit;
  margin: 0px 7.8rem;
}
.popup_dialog_box_content >>> .has_arrange_course_nodata {
  background-color: inherit;
  margin-left: -15.6rem;
  background-size: 43.86rem;
}
.popup_dialog_box_content
  >>> .has_arrange_course_list
  .has_arrange_course_nodata
  span {
  font-size: 6.24rem;
  padding-top: 37.83rem;
}
.popup_dialog_box_content >>> .has_arrange_course {
  background-color: inherit;
  border-top: inherit;
  margin: 0px 7.8rem;
}
.popup_dialog_box_content >>> .single_result.single_student:after {
  display: none;
}
.popup_dialog_box_content >>> .single_result.single_student {
  margin-left: 7.8rem;
  margin-right: 1.95rem;
  padding: 3.9rem 0;
}
.popup_dialog_box_content >>> .stundent_list_box .student_info {
  margin: 0;
  padding: 0;
  line-height: 14.43rem;
  font-size: 5.46rem;
}
.popup_dialog_box_content >>> .student_info_name {
  margin-left: 7.8rem;
  width: 58.5rem !important;
  font-size: 6.24rem;
}
.popup_dialog_box_content >>> .student_info_name span {
  color: #7d8596;
}
.popup_dialog_box_content >>> .student_info_name span:hover {
  color: #1683f9;
}
.popup_dialog_box_content >>> .course_auth_qrcode_footer {
  color: #ffffff;
  font-size: 6.24rem;
  margin-top: 11.7rem;
}
.popup_dialog_box_content >>> .input_search_show_btn .remove_btn {
  display: none;
}
.popup_dialog_box_content >>> .course_auth_qrcode_title {
  color: #ffffff;
  font-size: 8.58rem;
  height: 31.2rem;
  padding-top: 11.7rem;
}
.popup_dialog_box_content >>> .course_auth_qrcode_img {
  height: 74.295rem;
}
.popup_dialog_box_content >>> .search_result_list::-webkit-scrollbar {
  width: 1.95rem !important;
  height: 1.95rem !important;
  background-color: #f71d1d00 !important;
  left: -3.9rem !important;
}
.popup_dialog_box_content >>> .search_result_list::-webkit-scrollbar-thumb {
  border-radius: 3.12rem;
  background-color: #777e90 !important;
}
.popup_dialog_box_content >>> .search_result_list::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.popup_dialog_box_content >>> .goToClassBtn.footer_btn_group.sumbit_btn {
  width: 66.3rem;
}

.popup_dialog_box_content >>> .has_arrange_course_list {
  min-width: 159.12rem;
  overflow-y: auto;
  max-height: 124rem;
}
.popup_dialog_box_content >>> .course_auth_qrcode {
  height: 224.64rem !important;
}
/deep/ input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.3) !important;
  opacity: inherit !important;
}
/* Mozilla Firefox 4 to 18 */
/deep/ input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.3) !important;
  opacity: inherit !important;
}
/* Mozilla Firefox 19+ */
/deep/ input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.3) !important;
  opacity: inherit !important;
}
/* Internet Explorer 10+ */
/deep/ input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3) !important;
  opacity: inherit !important;
}
</style>