<template>
  <div class="workbench_view_news" >
    <div class="workbench_view_news_content">
      <div class="news_list no_border_bottom"
           v-for="(item,key) in instructionsList"
           :key="key"
           @click.stop="openLink(item)">
        <div class="help_list_inquiry clearfix">
          <!-- <span class="inquiry_icon fl">问</span> -->
          <span>{{item.title}}</span>
          <span class="news_list_btn"></span>
        </div>
        <!-- <div class="help_list_answer flex">
          <span class="answer_icon">答</span>
          <span class="answer_text" style="padding-left:0">{{item.content}}</span>
        </div> -->
      </div>
      <div v-if="instructionsList.length==0"
           style="margin-top:90px"
           class="course_block_nodata">
        <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
      </div>
    </div>
  </div>
</template>
<script>
import { SearchUseQuestionsAndAnswers } from '../../API/workbench.js';
export default {
  data () {
    return {
      searchData: {
        pageIndex: 0,
        pageSize: 0,
        orderBy: '',
        sequence: '',
        searchText: ''
      },
      indexType: 1,     //
      instructionsList: []
    };
  },
  created () {
    this.getSearchQuestionsAndAnswers();
  },
  methods: {
    // -使用回答查询
    getSearchQuestionsAndAnswers () {
      SearchUseQuestionsAndAnswers(this.searchData).then(result => {
        this.instructionsList = result.data.PageDataList;
        console.log('getSearchQuestionsAndAnswers', this.instructionsList);
      }).catch(error => {
        console.log(error, 'getSearchInstructionsError');
      });
    },
    // 跳转到详情页.
    openLink (item) {
      console.log(item, '新窗口');
      let Link = '';
      if (item.outlink) {
        window.open(item.outlink);
      } else if (window.location.host.indexOf('zx.zhixuerj.com') !== -1) { // 产品环境
        Link = 'http://www.sztuoke.com/content/' + item.id + '.html ';
        window.open(Link);
      } else { // 非产品环境
        Link = 'http://webtest.wktdevtest.zhixuerj.com/content/' + item.id + '.html';
        window.open(Link);
      }
      // this.$emit('closeInstructions');
    },
    // 跳转到列表
    toList () {
      let Link = '';
      if (window.location.host.indexOf('zx.zhixuerj.com') !== -1) { // 产品环境
        Link = 'http://www.sztuoke.com/news';
        window.open(Link);
      } else { // 非产品环境
        Link = 'http://webtest.wktdevtest.zhixuerj.com/news';
        window.open(Link);
      }
    },
    replaceContent (item) {
      item.content = item.content || '';
      return item.content.replace(/[&lt;p&gt;]/g, '');
    }
  }
};
</script>

