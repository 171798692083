<template>
  <div class="table_select_box_flex">
    <!-- <div class="table_select_box">
      <div class="table_select_title">{{vTeacher}}</div>
      <el-select :value="vTeacherKeyValue"
                 filterable
                 placeholder="必选"
                 @change="typeEvent"
                 value-key="OLAPKey">
        <el-option v-for="item in vTeacherList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item"></el-option>
      </el-select>
    </div> -->
    <div class="table_select_box" v-if="vIsMTeaKey==2"> 
      <div class="table_select_title">选择套餐</div>
      <el-select :value="vTaoCanValue"
                 filterable
                 placeholder="必选"
                 @change="accountEvent"
                 value-key="TableID">
        <el-option v-for="item in vTaoCanList"
                   :key="item.TableID"
                   :label="item.PackageName"
                   :value="item"></el-option>
      </el-select>
    </div>
    <div class="table_select_box" v-if="vIsMTeaKey==1||vIsMTeaKey==3">
      <div class="table_select_title">适用课程</div>
      <el-select :value="vCourseKeyValue"
                 filterable
                 placeholder="必选"
                 @change="subjectEvent"
                 value-key="OLAPKey">
        <el-option v-for="item in vCourseNameList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item"></el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { geTteacherList, getUsingClass, getCourseTypeNameListX,SumarizeTaocanTicketPlan } from '../../../API/workbench';
export default {
  data () {
    return {
      teacherList: [], // 老师列表
      gradeClassList: [], // 班级列表
      courseNameList: []
    };
  },
  created () {
    // this.loadDataSourceTeacher();
    // this.getValidClassList();
    // this.getCourseNameList();
  },
  props: {
    searchObj: Object,
    IsMTeaKey: {
      type: Number,
      default: 1
    }
  },
  computed: {
    vCourseNameList () {
      let newArr = (this.$store.getters.courseNameList && this.$store.getters.courseNameList.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; })) || [];
      newArr.unshift({
        MainDemoName: '全部',
        OLAPKey: 0
      });
      return newArr;
    },
    // vGradeClassList () {
    //   let newArr = this.$utils.parseJson(this.gradeClassList || []);
    //   newArr.unshift({
    //     MainDemoName: '全部',
    //     OLAPKey: 0
    //   });
    //   return newArr;
    // },
    // vTeacherList () {
    //   let newArr = this.$utils.parseJson(this.$store.getters.teacherList || []);
    //   newArr.unshift({
    //     MainDemoName: '全部',
    //     OLAPKey: 0
    //   });
    //   return newArr;
    // },
    vCourseKeyValue () {
      return this.searchObj.ApplyClassTypeKey == 0
        ? '全部'
        : this.searchObj.ApplyClassTypeKeyVal;
    },
    vTaoCanValue () {
      return this.searchObj.TaoCanKey == 0
        ? '全部'
        : this.searchObj.TaoCanKeyVal;
    },
    // vTeacherKeyValue () {
    //   return this.searchObj.TeacherKey == 0
    //     ? '全部'
    //     : this.searchObj.TeacherKeyValue;
    // },
    vIsMTeaKey () {
      return this.IsMTeaKey
    }

  },
  created(){
    this.getSumarizeTaocanTicketPlan()
  },
  watch: {

  },
  methods: {
    // 选中类型
    // typeEvent (obj) {
    //   console.log(obj, '选中老师');
    //   this.searchObj.TeacherKeyValue = obj.MainDemoName;
    //   this.searchObj.TeacherKey = obj.OLAPKey;
    //   this.$emit('changeSearchObj');
    // },
    // // 选中套餐
    accountEvent (obj) {
      console.log(obj, '选中班级');
      this.searchObj.TaoCanKeyVal = obj.PackageName;
      this.searchObj.TaoCanKey = obj.TableID;
      this.$emit('changeSearchObj');
    },
    // 选中课程
    subjectEvent (obj) {
      console.log(obj, '适用课程');
      this.searchObj.ApplyClassTypeKeyVal = obj.MainDemoName;
      this.searchObj.ApplyClassTypeKey = obj.OLAPKey;
      this.$emit('changeSearchObj');
    },
    getSumarizeTaocanTicketPlan(){
       SumarizeTaocanTicketPlan().then(result => {
        console.log(result.data, '套餐');
        this.vTaoCanList = result.data;
        this.vTaoCanList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
      }, error => {
        layer.alert(error.msg);
      }
      );
    }
    // 调用在职老师接口
    // loadDataSourceTeacher () {
    //   geTteacherList().then(result => {
    //     this.teacherList = result.data;
    //     this.teacherList.unshift({
    //       MainDemoName: '全部',
    //       OLAPKey: 0
    //     });
    //   });
    // },
    // 获取有效班级列表
    // getValidClassList () {
    //   getUsingClass().then(result => {
    //     this.gradeClassList = result.data;
    //     this.gradeClassList.unshift({
    //       MainDemoName: '全部',
    //       OLAPKey: 0
    //     });
    //   });
    // }
    // 课名列表
    // getCourseNameList () {
    //   getCourseTypeNameListX('OLAPKey', 'desc').then(result => {
    //     this.courseNameList = result.data.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; });
    //     this.courseNameList.unshift({
    //       MainDemoName: '全部',
    //       OLAPKey: 0
    //     });
    //   });
    // }
  }

};
</script>