<template>
  <div class="pr">
    <div class="select_course_student_info"
         ref="selectCourseStudentList"
         @scroll="ListScroll">
      <div class="course_student_info_list"
           :class="{paddingBottom:isBatchAttendance}">
        <student-item v-for="item in studentArrangeList"
                      :key="item.StudentArrangeKey"
                      :studentItem="item"
                      @selectedStudent="selectedStudent"
                      :isBatchAttendance="isBatchAttendance"
                      :isCourseType="vIsCourseType"
                      @setPosition="getPosition"></student-item>

        <!-- 添加学生 -->
        <add-student-btn v-if="!isBatchAttendance"
                         @studentAddCourse="showAddStudentDialog"
                         @studentAddCourseAndAttendance="showAddStudentAttendance"
                         @studentAddCoursePlan="showAddStudentPlan"
                         @setPosition="doAfterClickAddStudentBtn"></add-student-btn>

        <select-all-tip :isBatchAttendance="isBatchAttendance"
                        @cancleBatchAttendance="cancelBatchAttendance"
                        @confirmBatchAttendance="confirmBatchAttendance"></select-all-tip>
      </div>
      <!-- 添加学生弹框. -->
      <div>
        <student-for ref="studentsList"
                     :formTitle="studentTitle"
                     :classGradeID="selectedCourseInfo.GradeClassKey"
                     :selectCourseID="selectedCourseInfo.CourseKeyName || selectedCourseInfo.CourseNameKey"
                     :selectCourseTime="selectedCourseInfo.ClassTime"
                     @return="reBackSearchStudentBox"
                     @onAddStudent="doAfterSelectedStudent"
                     @onDeleteStudent="deleteClick"
                     @completeData="closeStundetList"></student-for>
      </div>
      <!-- 显示加载的进度条 -->
      <custom-dialog class="progress_max progress"
                     width="300px"
                     :visible.sync="showProgress">
        <div class="progress_title">
          正在批量考勤 ，{{runCount}}/{{totolPeoCount}}。
        </div>
        <div class="progress_box">
          <el-progress type="circle"
                       :percentage="progressStatus"></el-progress>
        </div>
        <!-- <div class="progress_exit" @click="showProgress = false"></div> -->
      </custom-dialog>

    </div>
    <div>
      <!-- 学生考勤操作-->
      <div class="course_student_attendance_operation module_content_menuList active"
           :style="{'left':positionLeft+'px','top':positionTop+'px'}"
           v-show="isShowDialog && vIsShowAlerDialog">
        <div class="top_bg"></div>
        <drop-down-box :optionJsonList="attendanceStatus"
                       @clickOptionForAlertDialog="chgAttendance"></drop-down-box>
      </div>
    </div>
    <custom-dialog :title="'学生列表'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowStudentListDialog"
                   :before-close="closeStudentListDialog">
      <student-list :isShowSearchInput="true"
                    :addTitleName="''"
                    :CustStatusKey="1"
                    :isShowAddBtn="false"
                    @selectedItem="toAddLoop"></student-list>
    </custom-dialog>
  </div>
</template>

<script>
import studentFor from '../../../common-module/students-list/index';
import studentItem from './student-item';
import studentList from '../../../common/student-list';
import amendAttendanceItem from '../change-attendance/index';
import addStudentBtn from '../../../common/add-student-btn';
import selectAllTip from './select-all-tip';
import dropDownBox from '../../../common/drop-down-box';
import {
  getScheduleCourseOnStudentList,
  courseAddStudent,
  setAttendance,
  kqChange,
  kqDelete,
  kqLeave,
  kqTruancy,
  BatchSyncSubmit,
  ArrangeStudentToPlan,
  CancelStudent2MutiSameApplyClassCourseByRule,
  getRuleByCourseInfo,
  ArrangeStudent2MutiSameApplyClassCourseByRule
} from '../../../../API/workbench';
export default {
  data () {
    return {
      deleteCount:1,//手动防抖
      studentArrangeList: [], // 学员排课列表
      isShowDialog: false,
      isScrollHidden: true,
      attendanceStatus: [
        {
          type: this.$initJson.attendanceType.view,
          name: '查看档案',
          eventName: 'examine',
          className: '',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.change,
          name: '变更考勤',
          eventName: 'changeAttendance',
          className: '',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.cancel,
          name: '撤销考勤',
          eventName: 'changeToUnAttendance',
          className: '',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.attendance,
          name: '签到',
          eventName: 'changeToAttendance',
          className: '',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.leave,
          name: '请假',
          eventName: 'changeToLeave',
          className: '',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.absence,
          name: '旷课',
          eventName: 'changeToAbsence',
          className: '',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.del,
          name: '退课',
          eventName: 'deleteClick',
          className: 'course_student_del warning',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.secedeLoop,
          name: '退出循环课',
          eventName: 'secedeLoop',
          className: 'course_student_del warning',
          isShow: false
        },

        {
          type: this.$initJson.attendanceType.addToCourse,
          name: '安排学生上课',
          eventName: 'studentAddCourse',
          className: '',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.addToCourseAndAttendance,
          name: '安排学生并签到',
          eventName: 'studentAddCourseAndAttendance',
          className: '',
          isShow: false
        },
        {
          type: this.$initJson.attendanceType.addLoop,
          name: '加入循环课',
          eventName: 'addLoop',
          className: 'course_student_del warning',
          isShow: false
        }
      ],
      curSelectedStudentInfo: {}, // 选中学生.
      vStudentArrangeList: [],
      positionLeft: '',
      positionTop: '',
      isShowChangeAttendance: false,
      isShowSearchInput: false,
      isShowStudentListDialog: false,
      isBatchAttendanceSucces: false, // 判断批量考勤是否成功过
      isStudentAttendance: '', // 学生签到
      showProgress: false, // 显示批量添加的进度条
      totolPeoCount: 0, // 百分比弹出层总人数
      runCount: 0, // 进度条.
      isShowAddStudentDialog: '',
      courseRule: 0, // 选中课的排课规律id
      studentTitle: '', // 添加学生列表弹窗名
      addStudentMode: 0, // 添加学生列表类型
      addLoopStudentInfo: {},  // 加入循环课选中的学生
      isTheBatchProcessShow: false, // 显示批量排课
      parmsInfo: null
    };
  },
  props: {
    isBatchAttendance: Boolean,
    selectedCourseInfo: Object
  },
  components: {
    studentItem,
    addStudentBtn,
    studentFor,
    selectAllTip,
    dropDownBox,
    amendAttendanceItem,
    studentList
  },
  created () {
    if (this.selectedCourseInfo.ScheduleCourseKey) {
      this.loadStudentArrangeList();
      this.getRuleByCourse();
    }
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener(
      'click',
      e => {
        this.isShowDialog = false;
        this.$store.commit('showAlerDialog', false);
      },
      true
    ); this.$bus.on(this, 'afterArrangeScheduleCourse', () => {
      if (this.parmsInfo) {
        this.loadStudentArrangeList();
        this.$bus.emit('AfterStudentAddToCourse', this.selectedCourseInfo.ScheduleCourseKey, 0);
      }
    });
    this.$bus.on(this, 'AfterStudentUpdate', (studentKey, studentData) => {
      // debugger;
      if (studentKey) {
        let studentIndex = this.studentArrangeList.findIndex(obj => { return obj.StudentKey == studentKey; });
        if (studentIndex >= 0 && studentData.MainDemoName) {
          this.studentArrangeList[studentIndex].StudentKeyValue = studentData.MainDemoName;
          let newStudentInfo = this.$utils.parseJson(this.studentArrangeList[studentIndex]);
          this.studentArrangeList.splice(studentIndex, 1, newStudentInfo);
        }
      }
    });
  },
  watch: {
    selectedCourseInfo: {
      handler (n, o) {
        if (n.ScheduleCourseKey != o.ScheduleCourseKey) {
          if (n.ScheduleCourseKey) {
              this.getRuleByCourse();
          }        
          this.loadStudentArrangeList();
        }
      },
      deep: true
    },
    /// 监听父组件是否启用批量考勤 Boolen值
    isBatchAttendance (n, o) {
      // 启用批量考勤
      if (n) {
        this.openBatchAttendance();
        // 关闭批量考勤
      } else {
        this.studentArrangeList.forEach(obj => {
          obj.isSelected = false;
          obj.unSelected = false;
          this.showProgress = false;
        });
        this.$parent.isBatchAttendance = false;
        this.$parent.$parent.isBatchAttendance = false;
      }
    }
  },
  computed: {
    vIsCourseType () {
      return this.selectedCourseInfo.StatusKey == 4;
    },
    vIsShowAlerDialog () {
      console.log(
        this.$store.getters.showAlerDialog,
        'this.$store.getters.showAlerDialog'
      );
      return this.$store.getters.showAlerDialog;
    },
    //  进度条 百分比.
    progressStatus () {
      var percent = Math.floor(
        (this.runCount / this.totolPeoCount).toFixed(2) * 100
      );
      return percent || 0;
    },
    SaaSClientInfo () { // 门店信息   要使用的字段 最早签到时间(分钟)
      return this.$store.getters.SaaSClientInfo;
    },
    // 是否通用版
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    }
  },
  methods: {
    getRuleByCourse () {
      getRuleByCourseInfo(this.selectedCourseInfo).then(result => {
        this.courseRule = result.data;
      }).catch(error => {
        console.log(error, 'getRuleByCourseerror');
      });
    },
    ListScroll () {
      if (this.isScrollHidden) {
        this.isShowDialog = false;
      } else {
        this.isScrollHidden = true;
      }
    },
    doShowProgressClick () {
      this.showProgress = false;
    },
    // 启用批量考勤
    openBatchAttendance () {
      console.log('openBatchAttendance');
      this.studentArrangeList.forEach(obj => {
        this.$set(obj, 'isSelected', false);
        if (
          obj.AttenceStatusKey == 2 ||
          obj.AttenceStatusKey == null ||
          obj.AttenceStatusKey == 0
        ) {
          this.$set(obj, 'isSelected', true);
        } else {
          this.$set(obj, 'unSelected', true);
        }
      });
    },
    // 关闭批量考勤
    cancelBatchAttendance () {
      this.studentArrangeList.forEach(obj => {
        obj.isSelected = false;
        obj.unSelected = false;
        this.showProgress = false;
      });
      this.$parent.isBatchAttendance = false;
      this.$parent.$parent.isBatchAttendance = false;
    },
    // 确认批量考勤 确认 遍历this.studentArrangeList isSelected为true的调用考勤接口
    confirmBatchAttendance (data) {
      let hasSelectedNum = 0; // 选中的学生数
      let detStudentNames = ''; // 欠课时学生姓名拼接字符串
      let newKQList = [];
      let dataList = [];
      if (data) {
        dataList = data;
      } else {
        dataList = this.studentArrangeList;
      }
      dataList.forEach(obj => {
        if (data || obj.isSelected) {
          ++hasSelectedNum;
          // 未考勤 欠课时学生
          if (obj.AttenceStatusKey == 2 && obj.IsClassHourLackKey == 1) {
            detStudentNames = detStudentNames == '' ? obj.StudentKeyValue : detStudentNames + '、' + obj.StudentKeyValue;
          }
          newKQList.push(obj);
        }
      });
      if (hasSelectedNum <= 0) {
        layer.alert('至少选择一位学生，再确认!');
        return;
      }
      this.verifyStudent(newKQList);
    },
    // 验证学生
    verifyStudent (newKQList) {
      if (newKQList.length > 0) {
        let isConfirm = true;
        let names = '';
        newKQList.forEach(o => {
          if (o.IsConfirmKey == 0 && o.IsTrialKey == 0) {
            isConfirm = false;
            names += o.StudentKeyValue + ',';
            layer.alert(
              names + '该学生待验证确认，请先在学生档案中进行确认操作。'
            );
            return false;
          }
        });
        if (isConfirm) {
          this.changeBatchAttendance(newKQList, 0);
        }
      }
    },
    // 刷新安排考勤页数据
    async refreshAttendance (callBack) {
      if (typeof this.$parent.getCourseInfo == 'function') {
        this.$parent.getCourseInfo();
        if (callBack) {
          callBack();
        }
      }
    },
    // 批量考勤
    changeBatchAttendance (dataList, IsClassHourLackKey) {
      console.log(this.$websocket.ws.readyState, 'this.$websocket.ws.readyState');
      if (this.$websocket.ws.readyState != 1) {
        layer.alert('网络不稳定，请稍后再试。');
        return false;
      }
      this.totolPeoCount = dataList.length;
      this.showProgress = true;
      let APIPromiseCreator = obj => {
        if (obj.IsClassHourLackKey == 1) {
          IsClassHourLackKey = 1;
        }
        return setAttendance(obj.StudentKey, obj.ScheduleCourseKey, IsClassHourLackKey);
      };
      let resolve = (r, obj) => {
        this.runCount++;
        Object.assign(obj, r.data);
        if (this.selectedCourseInfo.WKQRS != 0) {
          this.selectedCourseInfo.WKQRS -= 1;
        }
        // 已签到的 课时状态未已消费
        if (obj.AttenceStatusKey == 3) {
          obj.ClassHourKey = 5;
        }
        this.selectedCourseInfo.CQRS += 1;
        this.isBatchAttendanceSucces = true;
      };
      let reject = (r, obj) => {
        this.runCount++;
      };
      let resultCallback = resultList => {
        let isLackOfClassTime = []; // 课时不足的数组.
        let isLackOfClassTimeTS = ''; // 课时不足时提示.
        let otherJecCode = []; // 其他错误时的数组
        let otherPeopleTS = ''; // 其他错误时提示.
        let otherErrorTS = []; // 其他错误时提示.
        resultList.forEach(o => {
          if (o.r.code == 60001) {
            isLackOfClassTime.push(o.data);
            isLackOfClassTimeTS = isLackOfClassTimeTS == '' ? o.data.StudentKeyValue : isLackOfClassTimeTS + ',' + o.data.StudentKeyValue;
          } else if (o.r.code != 0) {
            otherJecCode.push(o.data);
            otherErrorTS.push(o.r);
            otherPeopleTS = otherPeopleTS == '' ? o.data.StudentKeyValue : otherPeopleTS + ',' + o.data.StudentKeyValue;
          }
        });
        if (isLackOfClassTime.length > 0) {
          let that = this;
          layer.confirm(isLackOfClassTimeTS + '课时不足，是否以欠课时的方式继续签到？', {
            shadeClose: false,
            btn: [
              {
                name: '取消',
                callBack: function () {
                  layer.close();
                  that.runCount = 0;
                  that.cancelBatchAttendance();
                }
              },
              {
                name: '确认',
                callBack: function () {
                  that.runCount = 0;
                  that.changeBatchAttendance(isLackOfClassTime, 1);
                  layer.close();
                }
              }
            ]
          });
        } else {
          this.runCount = 0;
          if (otherJecCode.length > 0) {
            layer.alert(otherErrorTS[0].msg);
          }
          this.cancelBatchAttendance();
        }
        this.$emit('updateStudentFileEvent');
        this.$bus.emit('AfterBatchAttendance', this.selectedCourseInfo.ScheduleCourseKey, this.selectedCourseInfo);
      };
      BatchSyncSubmit(
        dataList,
        APIPromiseCreator,
        resultCallback,
        resolve,
        reject
      );
    },
    // 签到接口 GOTO
    toSetAttendance (IsClassHourLackKey, callBack) {
      setAttendance(this.curSelectedStudentInfo.StudentKey, this.curSelectedStudentInfo.ScheduleCourseKey, IsClassHourLackKey).then(result => {
        layer.alert('签到成功');
        this.$emit('changeAttendanceStutus', this.curSelectedStudentInfo.ScheduleCourseKey, this.curSelectedStudentInfo.StudentKey, this.selectedCourseInfo.StatusKey);
        if (callBack) {
          callBack();
        }
        Object.assign(this.curSelectedStudentInfo, result.data);
        if (this.selectedCourseInfo.WKQRS != 0) {
          this.selectedCourseInfo.WKQRS -= 1;
        }
        this.selectedCourseInfo.CQRS += 1;
        this.curSelectedStudentInfo.isSelected = false;
      }, err => {
        let that = this;
        if (err.code == 60001) {
          layer.confirm(this.curSelectedStudentInfo.StudentKeyValue + '课时不足，是否以欠课时的方式继续签到？', {
            shadeClose: false,
            btn: [
              {
                name: '取消',
                callBack: function () {
                  that.isSaving = false;
                  that.$refs.studentsList.isAdding = false;
                  if (callBack) {
                    callBack();
                  }
                  layer.close();
                }
              },
              {
                name: '确认',
                callBack: function () {
                  that.toSetAttendance(1, callBack);
                  layer.close();
                }
              }
            ]
          });
        } else {
          layer.alert(err.msg);
          if (callBack) {
            callBack();
          }
        }
      }
      );
    },
    // 获取学员排课表
    loadStudentArrangeList (callBack) {
      getScheduleCourseOnStudentList(this.selectedCourseInfo.ScheduleCourseKey).then(
        result => {
          this.studentArrangeList = [];
          let cqrs = 0;
          let qjrs = 0;
          let kkrs = 0;
          let wkqrs = 0;
          let zrs = 0;
          if (result.data && result.data.length > 0) {
            result.data.forEach(obj => {
              obj.isSelected = false;
              obj.unSelected = false;
              obj.OLAPKey = obj.StudentKey;
              this.studentArrangeList.push(obj);
              if (obj.AttenceStatusKey == 2) {
                wkqrs++;
              } else if (obj.AttenceStatusKey == 3) {
                cqrs++;
              } else if (obj.AttenceStatusKey == 4) {
                qjrs++;
              } else if (obj.AttenceStatusKey == 5) {
                kkrs++;
              }
              zrs++;
            });
          }
          console.log('this.studentArrangeList', this.$utils.parseJson(result.data));
          this.selectedCourseInfo.CQRS = cqrs;
          this.selectedCourseInfo.QJRS = qjrs;
          this.selectedCourseInfo.KKRS = kkrs;
          this.selectedCourseInfo.WKQRS = wkqrs;
          this.selectedCourseInfo.ZRS = zrs;
          if (callBack) {
            callBack();
          }
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 添加学生弹出层 isStudentAttendance
    showAddStudentDialog () {
      this.$refs.studentsList.isShowAddStudent = true;
      this.isShowAddStudentDialog = '安排'; // 选择学生签到
      this.studentTitle = '安排学生上课';
      this.addStudentMode = 0;
      this.$nextTick(() => {
        this.$refs.studentsList.studenSearchText = '';
        this.$refs.studentsList.pushDataStudentList(this.studentArrangeList);
        this.$refs.studentsList.getStudentList();
      });
    },
    // 添加学生并签到弹出层
    showAddStudentAttendance () {
      this.$refs.studentsList.isShowAddStudent = true;
      this.isShowAddStudentDialog = '签到'; // 选择学生签到
      this.studentTitle = '安排学生并签到';
      this.addStudentMode = 1;
      this.$nextTick(() => {
        this.$refs.studentsList.studenSearchText = '';
        this.$refs.studentsList.pushDataStudentList(this.studentArrangeList);
        this.$refs.studentsList.getStudentList();
      });
    },
    // 取消循环课
    cancelAllArrangeClick () {
      layer.confirm('是否安排' + this.curSelectedStudentInfo.StudentKeyValue + '退出循环课', {
        shadeClose: false,
        btn: [
          {
            name: '确认',
            callBack: () => {
              CancelStudent2MutiSameApplyClassCourseByRule(this,this.curSelectedStudentInfo.StudentKey, this.courseRule).then(result => {
                let findIndex = this.studentArrangeList.findIndex(o => {
                  // 遍历数组找到相对应ID.  删除.
                  return (o.StudentArrangeKey == this.curSelectedStudentInfo.StudentArrangeKey);
                });
                if (findIndex > -1) {
                  this.studentArrangeList.splice(findIndex, 1);
                }
                this.$refs.studentsList.dataStudentList.splice(findIndex, 1);
                this.$refs.studentsList.getStudentCourse(this.curSelectedStudentInfo);
                this.$emit('changeAttendanceStutus', this.curSelectedStudentInfo.ScheduleCourseKey, this.curSelectedStudentInfo.StudentKey, this.selectedCourseInfo.StatusKey);
              }, error => {
                layer.alert(error.msg);
              });
              layer.close();
            }

          }
        ]
      });
    },
    // 添加学生到课计划弹出层
    showAddStudentPlan () {
      this.isShowAddStudentDialog = '排课'; // 选择学生排课
      var presentDate = this.$utils.formatTime(Date.new()); // 获取当前日期和时间

      let getDate1 = presentDate.split(' ')[0].replace(/\//g, '-'); // 当前日期
      let getTime1 = presentDate.split(' ')[1]; // 当前时间
      let getDate2 = (
        this.selectedCourseInfo.ClassTime +
        ' ' +
        this.selectedCourseInfo.EndClassTime
      ).split(' ')[0]; // 排课日期
      let getTime2 = (
        this.selectedCourseInfo.ClassTime +
        ' ' +
        this.selectedCourseInfo.EndClassTime
      )
        .split(' ')[1]
        .slice(0, 8); // 排课时间
      // console.log(getDate1 > getDate2, '当天日期1');
      // console.log(getTime1 > getTime2, '当天日期2');

      // 对比日期;
      if (getDate2 > getDate1) {
      } else if (getDate2 == getDate1) {
        // 对比时间
        if (getTime2 > getTime1) {
        } else {
          layer.alert('只有未开始的课可添加到课计划,该课上课时间已过');
        }
      } else {
        layer.alert('只有未开始的课可添加到课计划,该课上课日期已过');
      }
    },
    closeStundetList () { },
    // 添加完学生 选中学生后
    doAfterSelectedStudent (item, callBack) {
      this.curSelectedStudentInfo = item;
      var AgentKey = '666'; // 临时.
      var IsAllowClassHourLackKey = 0; // 是否允许无课时 1是0否
      var IsTrialKey = 0; // 是否试课(1是0否)

      this.setStudentHasClass(this.selectedCourseInfo.ClassTime, this.selectedCourseInfo.ScheduleCourseKey, item.OLAPKey, AgentKey, IsAllowClassHourLackKey, IsTrialKey,
        obj => {
          obj.StudentKeyValue = item.MainDemoName;
          obj.StudentKey = item.OLAPKey;
          this.curSelectedStudentInfo = obj;
          if (this.isShowAddStudentDialog == '安排') {
            // if (callBack) {
            //   callBack();
            // }
            this.$refs.studentsList.addDataStudentList(item);
            this.$bus.emit('AfterStudentAddToCourse', this.selectedCourseInfo.ScheduleCourseKey, item.OLAPKey, this.selectedCourseInfo.StatusKey);
          } else if (this.isShowAddStudentDialog == '签到') {
            this.toSetAttendance(item.IsClassHourLackKey || 0, callBack);
          } else if (this.isShowAddStudentDialog == '排课') {
            ArrangeStudentToPlan(item.OLAPKey, this.selectedCourseInfo.StructuredPlanKey).then(result => {
              if (callBack) {
                callBack();
              }
              layer.alert('添加成功');
              this.$bus.emit('AfterStudentAddToCourse', this.selectedCourseInfo.ScheduleCourseKey, item.OLAPKey, this.selectedCourseInfo.StatusKey);
            }).catch(error => {
              this.alertMsg = error.msg;
            });
          }
        }
      );
    },
    // 课程添加学员接口.
    setStudentHasClass (classTime, scheduleCourseKey, studentKey, agentKey, isAllowClassHourLackKey, isTrialKey, callBack) {
      courseAddStudent(classTime, scheduleCourseKey, studentKey, agentKey, isAllowClassHourLackKey, isTrialKey).then(
        result => {
          result.data.OLAPKey = result.data.StudentNameKey;
          this.$set(result.data, 'isSelected', false);
          this.$set(result.data, 'unSelected', false);
          this.studentArrangeList.push(result.data);
          if (callBack) {
            callBack(result.data);
          }
        }, error => {
        let that = this;
        if (error.code == 60001) {
          console.log(this.curSelectedStudentInfo, 'this.curSelectedStudentInfo222');
            // layer.confirm(this.curSelectedStudentInfo.MainDemoName + '课时不足,是否继续安排？', {
            //   shadeClose: false,
            //   btn: [
            //     {
            //       name: '取消',
            //       callBack: () => {
            //         this.$refs.studentsList.isAdding = false;
            //         layer.close();
            //       }
            //     },
            //     {
            //       name: '确认',
            //       callBack: function () {
          that.setStudentHasClass(classTime, scheduleCourseKey, studentKey, agentKey, 1, isTrialKey, callBack);
            //         layer.close();
            //       }
            //     }
            //   ]
            // });
        } else if (error.code == 10009) {
          layer.confirm('该学生没有可用课单，是否无课时安排？', {
            shadeClose: false,
            btn: [
              {
                name: '确认',
                callBack: function () {
                  that.setStudentHasClass(classTime, scheduleCourseKey, studentKey, agentKey, 1, isTrialKey, callBack);
                  layer.close();
                }
              },
              {
                name: '取消',
                callBack: () => {
                  this.$refs.studentsList.isAdding = false;
                  layer.close();
                }
              }
            ]
          });
        } else {
          this.$refs.studentsList.isAdding = false;
          layer.alert(error.msg);
        }
      }
      );
    },
    // 选中学员信息 根据选中学生状态 弹出学生考勤框（变更考勤，未考勤，签到，请假，旷课，删除）根据状态显示哪几个
    selectedStudent (selectedStudentInfo) {
      console.log('selectedStudent', selectedStudentInfo);
      this.curSelectedStudentInfo = selectedStudentInfo;
      let StatusKey = this.selectedCourseInfo.StatusKey;
      this.attendanceStatus.forEach(obj => {
        if (parseInt(selectedStudentInfo.AttenceStatusKey) === 3 ||
          parseInt(selectedStudentInfo.AttenceStatusKey) === 4 ||
          parseInt(selectedStudentInfo.AttenceStatusKey) === 5) {
          if (obj.type === 3 ||
            obj.type === 4 ||
            obj.type === 5 ||
            obj.type === 6 ||
            obj.type === 8 ||
            obj.type === 9 ||
            obj.type === 10) {
            obj.isShow = false;
          } else {
            obj.isShow = true;
          }
        } else {
          if (obj.type === 0 ||
            obj.type === 3 ||
            obj.type === 4 ||
            obj.type === 5 ||
            obj.type === 6) {
            obj.isShow = true;
          } else {
            obj.isShow = false;
          }
        }
        if (obj.type === 12) {
          obj.isShow = false;
        }
        if (obj.type == 11) {
          if (StatusKey == 4 || StatusKey == 5 || StatusKey == 6) {
            obj.isShow = false;
          } else if (selectedStudentInfo.AttenceStatusKey == 2 || !selectedStudentInfo.AttenceStatusKey) {
            obj.isShow = true;
          } else {
            obj.isShow = false;
          }
        }
      });
      // let scrollTop = document.getElementsByClassName('course_student_info_list')[0].scrollTop;
      // this.vTop = Math.abs(scrollTop - (seletedEle.offsetTop + 140 + seletedEle.offsetHeight - 18)) + 'px';        // 140为固定高度,52为该元件的高度
    },
    chgAttendance (optionItem) {
      switch (optionItem.type) {
        case this.$initJson.attendanceType.view:
          this.examine();
          this.studentArrangeList.forEach(function (obj) {
            obj.isSelected = false;
          });
          break;
        case this.$initJson.attendanceType.change:
          this.changeAttendance();
          break;
        case this.$initJson.attendanceType.cancel:
          this.changeAttendanceStatus(optionItem);
          break;
        case this.$initJson.attendanceType.attendance:
          this.toSetAttendance(this.curSelectedStudentInfo.IsClassHourLackKey);
          break;
        case this.$initJson.attendanceType.leave:
          this.changeToLeave();
          break;
        case this.$initJson.attendanceType.absence:
          this.changeToAbsence();
          break;
        case this.$initJson.attendanceType.del:
          this.deleteClick();
          break;
        case this.$initJson.attendanceType.addToCourse:
          this.showAddStudentDialog();
          break;
        case this.$initJson.attendanceType.addToCourseAndAttendance:
          this.showAddStudentAttendance();
          break;
        case this.$initJson.attendanceType.addCoursePlan:
          this.showAddStudentPlan();
          break;
        case this.$initJson.attendanceType.secedeLoop:      // 退出循环课
          this.cancelAllArrangeClick();
          break;
        case this.$initJson.attendanceType.addLoop:       // 加入循环课
          this.isShowStudentListDialog = true;
          break;
        default:
          break;
      }
      this.isShowDialog = false;
      this.$store.commit('showAlerDialog', false);
    },
    // 查看学员的学员档案
    examine () {
      let item = {
        routerName: this.$route.name,
        name: '学生档案',
        moduleName: 'studentFile',
        data: { studentID: this.curSelectedStudentInfo.StudentKey }
      };
      this.$dialog.open(this, item);
    },
    // 学生考勤弹出框 选中一项 不同的项对应不同的方法
    clickOptionForAlertDialog (optionItem, detailStudentItem) {
      this.isShowChangeAttendance = false;
      if (optionItem.type == 3) {
        this.selectedCourseInfo.CQRS += 1;
        this.curSelectedStudentInfo.AttenceStatusKey = 3;
        this.curSelectedStudentInfo.AttenceStatusKeyValue = '签到';
      } else if (optionItem.type == 4) {
        this.selectedCourseInfo.QJRS += 1;
        this.curSelectedStudentInfo.AttenceStatusKey = 4;
        this.curSelectedStudentInfo.AttenceStatusKeyValue = '请假';
      } else if (optionItem.type == 5) {
        this.selectedCourseInfo.KKRS += 1;
        this.curSelectedStudentInfo.AttenceStatusKey = 5;
        this.curSelectedStudentInfo.AttenceStatusKeyValue = '旷课';
      }
      this.curSelectedStudentInfo.ClassHourKey = detailStudentItem.ClassHourKey; // 消费状态. 5为消费.
      this.curSelectedStudentInfo.ISClassHourDebtKey =
        detailStudentItem.ISClassHourDebtKey;
      this.curSelectedStudentInfo.ISClassHourDebtKeyValue =
        detailStudentItem.ISClassHourDebtKeyValue;
      this.isShowDialog = false;
      this.$store.commit('showAlerDialog', false);
    },
    // 变更考勤
    changeAttendance () {
      let moduleInfo = {
        name: '修改考勤',
        routerName: this.$route.name,
        moduleName: 'changeAttendance',
        data: { dataInfo: this.curSelectedStudentInfo, courseInfo: this.selectedCourseInfo, isShowSearchInput: true },
        callBack: { afterSucces: () => { this.loadStudentArrangeList(); } }
      };
      this.$bus.emit('needOpenRouter', moduleInfo);
    },
    // 变更考勤
    showChangeAttendance (scheduleCourseKey, studentKey, attendanceType) {
      this.loadStudentArrangeList();
    },
    // 变更考勤（已考勤的变更成另一种状态）
    changeAttendanceStatus (optionItem, IsClassHourLackKey) {
      this.curSelectedStudentInfo.IsClassHourLackKey = IsClassHourLackKey || 0;
      kqChange(
        this.curSelectedStudentInfo.StudentKey,
        this.curSelectedStudentInfo.ScheduleCourseKey,
        this.curSelectedStudentInfo.IsClassHourLackKey,
        optionItem.type,
        this.curSelectedStudentInfo.StudentArrangeKey
      ).then(
        result => {
          if (result.code === 0) {
            if (this.curSelectedStudentInfo.AttenceStatusKey == 4) {
              // 请假
              this.selectedCourseInfo.QJRS -= 1;
            } else if (this.curSelectedStudentInfo.AttenceStatusKey == 5) {
              // 旷课
              this.selectedCourseInfo.KKRS -= 1;
            } else if (this.curSelectedStudentInfo.AttenceStatusKey == 3) {
              // 签到
              this.selectedCourseInfo.CQRS -= 1;
            }
            console.log('kqChange', this.$utils.parseJson(result.data));
            Object.assign(this.curSelectedStudentInfo, result.data);
            if (optionItem.type == 2) {
              this.selectedCourseInfo.WKQRS += 1;
            }
          }
          this.curSelectedStudentInfo.isSelected = false;
          layer.alert('变更考勤成功');
          this.$emit('updateStudentFileEvent');
          this.$emit('changeAttendanceStutus', this.curSelectedStudentInfo.ScheduleCourseKey, this.curSelectedStudentInfo.StudentKey, this.selectedCourseInfo.StatusKey);
        }).catch(err => {
          let that = this;
          if (err.code == 60001) {
            layer.confirm(
              this.curSelectedStudentInfo.StudentKeyValue + '课时不足，是否以欠课时的方式变更考勤？',
              {
                btn: [
                  {
                    name: '取消',
                    callBack: function () {
                      layer.close();
                    }
                  },
                  {
                    name: '确认',
                    callBack: function () {
                      that.changeAttendanceStatus(optionItem, 1);
                      layer.close();
                    }
                  }
                ]
              }
            );
          } else {
            layer.alert(err.msg);
          }
        });
    },
    // 请假
    changeToLeave () {
      this.toLeave(0);
    },
    toLeave (IsClassHourLackKey) {
      // 调用请假接口.
      kqLeave(
        this.curSelectedStudentInfo.StudentKey,
        this.curSelectedStudentInfo.ScheduleCourseKey,
        IsClassHourLackKey // 第一次请求IsClassHourLackKey默认为0, 由服务器返回报错是否为课时不足, 再进行操作
      ).then(
        result => {
          Object.assign(this.curSelectedStudentInfo, result.data);
          this.selectedCourseInfo.WKQRS -= 1;
          this.selectedCourseInfo.QJRS += 1;
          layer.alert('请假成功');
          this.$emit('updateStudentFileEvent');
          this.$emit('changeAttendanceStutus', this.curSelectedStudentInfo.ScheduleCourseKey, this.curSelectedStudentInfo.StudentKey, this.selectedCourseInfo.StatusKey);

          this.curSelectedStudentInfo.isSelected = false;
        },
        err => {
          let that = this;
          if (err.code == 60001) {
            layer.confirm(this.curSelectedStudentInfo.StudentKeyValue + '课时不足，是否以欠课时的方式继续请假？', {
              btn: [
                {
                  name: '确认',
                  callBack: function () {
                    that.toLeave(1);
                    layer.close();
                  }
                }
              ]
            });
          } else {
            layer.alert(err.msg);
          }
        }
      );
    },
    // 旷课
    changeToAbsence () {
      this.toAbsence(0);
    },
    // 调用旷课接口
    toAbsence (IsClassHourLackKey) {
      console.log('toAbsence', this.selectedCourseInfo);
      kqTruancy(
        this.curSelectedStudentInfo.StudentKey,
        this.curSelectedStudentInfo.ScheduleCourseKey,
        IsClassHourLackKey // 第一次请求IsClassHourLackKey默认为0, 由服务器返回报错是否为课时不足, 再进行操作
      ).then(result => {
        Object.assign(this.curSelectedStudentInfo, result.data);
        this.selectedCourseInfo.WKQRS -= 1;
        this.selectedCourseInfo.KKRS += 1;
        layer.alert('设置旷课成功');
        this.$emit('updateStudentFileEvent');
        this.$emit('changeAttendanceStutus', this.curSelectedStudentInfo.ScheduleCourseKey, this.curSelectedStudentInfo.StudentKey, this.selectedCourseInfo.StatusKey);

        this.curSelectedStudentInfo.isSelected = false;
      })
        .catch(err => {
          let that = this;
          if (err.code == 60001) {
            // 60001为课时不足.  课时不足才提示.
            layer.confirm(this.curSelectedStudentInfo.StudentKeyValue + '课时不足，是否以欠课时的方式继续旷课？', {
              btn: [
                {
                  name: '确认',
                  callBack: function () {
                    that.toAbsence(1);
                    layer.close();
                  }
                }
              ]
            });
          } else {
            layer.alert(err.msg);
          }
        });
    },
    // 删除
    deleteClick (data, index, callBack) {
      // 调用删除接口.
      if(this.deleteCount!=1){//手动拦截
        console.log('手动防抖成功！重复点击！！')
        return
      }
      this.deleteCount=2;
      if (data) {
        this.curSelectedStudentInfo = data;
        this.curSelectedStudentInfo.StudentKey = data.OLAPKey;
        this.curSelectedStudentInfo.ScheduleCourseKey = this.selectedCourseInfo.ScheduleCourseKey;
      }
      if (this.curSelectedStudentInfo.StudentKey > 0 && this.curSelectedStudentInfo.ScheduleCourseKey > 0) {
        kqDelete(this,this.curSelectedStudentInfo.StudentKey, this.curSelectedStudentInfo.ScheduleCourseKey).then(
          result => {
            this.curSelectedStudentInfo.AttenceStatusKey = 7;
            this.curSelectedStudentInfo.AttenceStatusKeyValue = '已取消';
            this.selectedCourseInfo.ZRS -= 1;
            this.selectedCourseInfo.WKQRS -= 1;
            let findIndex = this.studentArrangeList.findIndex(o => { return (o.StudentKey == this.curSelectedStudentInfo.StudentKey); });
            if (findIndex > -1) {
              this.studentArrangeList.splice(findIndex, 1);
            }
            layer.alert('退课成功');
            this.deleteCount=1
            if (callBack) {
              callBack();
            }
            this.$emit('changeAttendanceStutus', this.curSelectedStudentInfo.ScheduleCourseKey, this.curSelectedStudentInfo.StudentKey, this.selectedCourseInfo.StatusKey);
          },
          err => {
            layer.alert(err.msg);
            this.deleteCount=1
          }
        );
      }
    },
    getPosition (left, top, clientTop) {
      console.log(left, 'positionLeft', clientTop);
      if (top > 280) {
        this.isScrollHidden = false;
        let num = this.$refs.selectCourseStudentList.scrollTop;
        this.$refs.selectCourseStudentList.scrollTop = this.$refs.selectCourseStudentList.scrollTop + 80;
        num = this.$refs.selectCourseStudentList.scrollTop - num;
        top -= num;
      }
      this.positionLeft = left - 5;
      this.positionTop = top;
    },
    doAfterUpdateAttenHistory (val) {
      this.loadStudentArrangeList();
    },
    reBackSearchStudentBox () {
      this.$refs.customPopup.PopUpShow = false;
    },
    doAfterClickAddStudentBtn (left, top, clientTop) {
      if (top > 290) {
        this.isScrollHidden = false;
        let num = this.$refs.selectCourseStudentList.scrollTop;
        this.$refs.selectCourseStudentList.scrollTop = this.$refs.selectCourseStudentList.scrollTop + 80;
        num = this.$refs.selectCourseStudentList.scrollTop - num;
        top -= num;
      }
      this.positionLeft = left - 5;
      this.positionTop = top - 24;
      let StatusKey = this.selectedCourseInfo.StatusKey;
      this.attendanceStatus.forEach(obj => {
        if (obj.type === 8 || obj.type === 9 || obj.type === 10 || obj.type == 12) {
          obj.isShow = true;
        } else {
          obj.isShow = false;
        }
        if (obj.type === 12) {
          if (StatusKey == 4 || StatusKey == 5 || StatusKey == 6) {
            obj.isShow = false;
          } else {
            obj.isShow = true;
          }
        }
        if (obj.type == 11) {
          obj.isShow = false;
        }
      });
      this.studentArrangeList.forEach(function (obj) {
        obj.isSelected = false;
      });
    },
    closeStudentListDialog () {
      this.isShowStudentListDialog = false;
    },
    closeTheBatchProcessShow () {
      this.isTheBatchProcessShow = false;
    },

    // 加入循环课
    toAddLoop (stuItem) {
      this.isShowStudentListDialog = false;
      this.addLoopStudentInfo = stuItem;
      this.isTheBatchProcessShow = true;
      if (this.vIsSysVersionTY) {
        this.addToCircleCourseForStudent();
      } else {
        this.parmsInfo = {
          routerName: this.$route.name,
          moduleName: 'batchArrangeScheduleCourse',
          data: { paramsInfo: { studentKey: this.addLoopStudentInfo.OLAPKey, ruleID: this.courseRule, courseNameKey: this.selectedCourseInfo.CourseNameKey, classGradeKey: this.selectedCourseInfo.GradeClassKey } }
        };
        this.$bus.emit('needOpenRouter', this.parmsInfo);
      }
    },
    // 通用版 安排进循环课
    addToCircleCourseForStudent () {
      let params = {
        StudentKey: this.addLoopStudentInfo.OLAPKey,
        StructuredRuleKey: this.courseRule,
        StartTime: this.$utils.formatDateToLine(Date.new()),
        EndTime: '',
        ClassCount: 0,
        IsClassHourLackKey: 1,
        IsSyncArrangedKey: 1, // 是否循环跟排 0-否;1-是
        ScheduleCourseKeyList: [] // 排出数组 无则空数组
      };
      ArrangeStudent2MutiSameApplyClassCourseByRule(params)
        .then(result => {
          this.$bus.emit('AfterStudentAddToCourse', this.selectedCourseInfo.ScheduleCourseKey, this.addLoopStudentInfo.OLAPKey, this.selectedCourseInfo.StatusKey);
          this.isTheBatchProcessShow = false;
          this.loadStudentArrangeList();
        }, error => {
          layer.alert(error.msg);
        });
    }
  }
};
</script>

