<template>
  <div class="course_number_details clearfix pad"
       @click="clickTeacher"
       :class="{opt:courseInfo.hasArranged&&!courseInfo.cancelArranged||courseInfo.hasSelected}">
    <div class="list_icon marginLeft_m fl"></div>
    <div class="fl">{{courseInfo.LecturerKeyValue}}</div>
  </div>
</template>

<script>
export default {
  props: {
    courseInfo: Object,
    dateInfo: Object
  },
  methods: {
    clickTeacher () {
      console.log('courseInfo', this.courseInfo);
      console.log('dateInfo', this.dateInfo);
      // 已安排未取消
      if (this.courseInfo.hasArranged && !this.courseInfo.cancelArranged) {
        this.courseInfo.cancelArranged = true;
      } else if (this.courseInfo.hasArranged && this.courseInfo.cancelArranged) {
        this.courseInfo.cancelArranged = false;
      } else {
        this.courseInfo.hasSelected = !this.courseInfo.hasSelected;
      }
      // this.dateInfo.courseList.forEach(obj => {
      //   if (obj.OLAPKey == this.courseInfo.OLAPKey) {
      //     // 已安排的
      //     if (obj.hasArranged) {
      //       this.$set(obj, 'cancelArranged', !obj.cancelArranged);
      //       this.dateInfo.cancelArranged = obj.cancelArranged;
      //       // 已安排的未取消 那其它选中的则取消选中
      //       if (!obj.cancelArranged) {
      //         this.dateInfo.hasSelected = false;
      //       }
      //       // 未安排的
      //     } else {
      //       this.$set(obj, 'hasSelected', !obj.hasSelected);
      //       if (obj.hasSelected) {
      //         this.dateInfo.hasSelected = this.courseInfo.hasSelected;
      //       } else {
      //         this.dateInfo.hasSelected = this.courseInfo.hasSelected;
      //       }
      //     }
      //   } else {
      //     // 已安排的 取消安排
      //     if (obj.hasArranged) {
      //       this.$set(obj, 'cancelArranged', true);
      //     }
      //     // 已选中的 取消选中
      //     if (obj.hasSelected) {
      //       this.$set(obj, 'hasSelected', false);
      //     }
      //   }
      // });
      this.$emit('clickTeacher', this.dateInfo);
    }
  }
};
</script>
<style>
</style>