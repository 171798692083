<template>
<div class="form_list">
        <div class="form_list_name" :class="{ required: required }">
          <span class="text_box">{{formTitle}}</span>
        </div>
        <div class="form_list_content">
          <input class="form_list_input"
                 type="text"
                 :value="value"
                 :placeholder="vPlaceholder"
                 @input="updateVal($event)"
                 @change="changeVal($event)">
        </div>
      </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  data () {
    return {};
  },
  props: {
    formTitle: {
      type: String,
      required: true
    }, // 标题，必填
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    formPlaceholder: String, // 默认文本，可选，如不提供，则默认显示title
    maxLength: {
      type: Number,
      default: 100
    },
  },
  computed: {
    vPlaceholder () {
      // 默认文本，如不提供，则默认显示title
      return this.formPlaceholder?this.formPlaceholder:(this.required?'必填':'选填')
    }
  },
  created () { },
  watch: {},
  methods: {
    updateVal (e) {
      this.onkeyupEvent(e);
      this.$emit('input', e.target.value);
    },
    changeVal (e) {
      this.onkeyupEvent(e);
      this.$emit('change', e.target.value);
    },
    // 修改按钮触发事件
    amendVal () {
      this.$emit('clickAmend');
    },
    limitMaxLength (str, maxLen) {
      var result = [];
      var re = /^[a-zA-Z]+$/;
      for (var i = 0; i < maxLen; i++) {
        var char = str[i];
        if (!re.test(char)) { maxLen--; }
        result.push(char);
      }
      return result.join('');
    },
    onkeyupEvent (e) {
      let val = e.target.value;
      if (this.$utils.strLength(val) > this.maxLength * 2) { e.target.value = this.limitMaxLength(val, this.maxLength * 2); }
    }
  }
};
</script>
