<template>
  <div class="form_item form-input form_info_line"
       :class="{ form_info_required: required }">
    <div class="form_info_field">
      <span>{{formTitle}}</span>
      <span v-if="helpDescription">
        <input-form-tip :tips='helpDescription'></input-form-tip>
      </span>
    </div>
    <div class="form_info_value">
      <div v-if="!vNumberVerify">
        <input type="text"
               v-if="!readonly"
               :value="value"
               :placeholder="required?'必填':'选填'"
               @input="updateVal($event.target.value)"
               @change="changeVal($event.target.value)"
               @click="showEldilog($event)"
               @blur="testTelNo" />
      </div>

      <div v-else>
        <input v-if="!readonly"
               type="number"
               :value="value"
               :placeholder="required?'必填':'选填'"
               @input="updateVal($event.target.value)"
               @change="changeVal($event.target.value)"
               @click="showEldilog($event)"
               @blur="testTelNo"
               onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');" />
        <span v-if="VunitVal">{{unitVal}}</span>
      </div>
      <span v-if="readonly"
            style="padding-right: 0.36rem"
            class="input_readonly">{{value}}</span>
    </div>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: 'formInput',
  data () {
    return {};
  },
  props: {
    formTitle: {
      type: String,
      required: true
    }, // 标题，必填
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    formPlaceholder: String, //
    numberVerify: Boolean, // input框是否校验数字 默认false
    unitVal: String, // 单位 是否带单位,仅支持数字类型的input框
    decimalPlaces: {
      type: [String, Number],
      default: ''
    },
    helpDescription: String // 表单说明提示
  },
  computed: {
    // 默认文本，如不提供，则默认显示title
    vPlaceholder () {
      return this.formPlaceholder || this.formTitle;
    },
    // 负责校验数字
    vNumberVerify () {
      return this.numberVerify ? this.numberVerify : false;
    },
    // 负责显示单位
    VunitVal () {
      return this.unitVal ? this.unitVal : false;
    }
  },
  created () { },
  watch: {},
  methods: {
    updateVal (val) {
      if (this.numberVerify && this.decimalPlaces >= 0) {
        val = Number(val).toFixed(this.decimalPlaces);
      }
      this.$emit('input', val);
    },
    changeVal (val) {
      this.$emit('change', val);
    },
    showEldilog (event) {
      if (this.numberVerify) {
        event.currentTarget.select();
      }
      this.$emit('showEldilog');
    },
    testTelNo () {
      if (this.vPlaceholder == '请输入客户名称') {
        this.$emit('chgInputType', true); // 有发生改变就触发.
      }
    }
  }
};
</script>
<style>
</style>
