<template>
  <div class="right_course_table"
       style="position: relative;">
    <div class="pr">
      <div class="summarizing_number_left">
        <span>循环课表</span>
        <span class="font_number">({{courseTableList.length}})</span>
      </div>
      <div class="btn_hover_bg_blue right_course_table_title_btn "
           v-if="!isStopOfCourse"
           @click="newCourseTable">
        新建课表
      </div>
    </div>
    <circle-course-table :courseNameKey="courseNameKey"
                         :nodataPadding="160"
                         @structedRuleData="structedRuleData"
                         @openCustomDialog="openCustomDialog"></circle-course-table>

  </div>
</template>

<script>
import circleCourseTable from '../../relation-course-component/circle-course-table/index';

export default {
  data () {
    return {
      courseTableList: []
    };
  },
  props: {
    courseNameKey: String,
    courseName: String,
    taoCanKey: String,
    isStopOfCourse: Boolean
  },
  components: {
    circleCourseTable
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  mounted () {
  },
  methods: {
    // 新建课表
    newCourseTable () {
      console.log('新建课表');
      let item = {
        routerName: this.$route.name,
        routertitle: this.$route.meta.title,
        moduleName: 'newCircleCourseTable',
        data: { dataInfo: { ClassSetKey: this.courseNameKey, ClassSetKeyValue: this.courseName } }
      };
      this.$bus.emit('needOpenRouter', item);
    },
    structedRuleData (data) {
      this.courseTableList = data;
    },
    openCustomDialog (item) {
      this.$emit('openCustomDialog', item);
    }
  }
};
</script>

<style>
</style>