<template>
  <div>
    <div id="WKT_information_fromBox"
         class="WKT_information_fromBox">
      <div class="from_input_list">
        <div class="from_input_title">
          教师姓名
        </div>
        <div class="from_input_box">
          <input type="text"
                 v-model="dataObj.TeacherName"
                 placeholder="请输入教师姓名">
        </div>
      </div>
      <div class="from_input_list">
        <div class="from_input_title">
          课程名
        </div>
        <div class="from_input_box">
          <input type="text"
                 v-model="dataObj.courses"
                 placeholder="请输入课程名">
        </div>
      </div>
      <div class="from_input_list">
        <div class="from_input_title">
          形象图片
        </div>
        <div class="from_input_img_box">
          <crop-upload-img :size="'尺寸：411px*354px'"
                           :isShowImg="true"
                           :ImgURl="dataObj.picurl"
                           :imgWidth="411"
                           :imgHeight="354"
                           :isShrink="false"
                           @uploadImgSuccess="doAfterUploadImgSuccess"></crop-upload-img>
        </div>

      </div>
      <div class="from_rich_edit">
        <div v-if="isUEditor">
          <script id="container"
                  name="content"
                  type="text/plain"
                  style="height:450px;width:600px;">{{dataObj.content}}</script>
          <UEditor :initData="dataObj.content?dataObj.content:''"
                   ref="ueditor"></UEditor>
          <!-- <textarea v-model="categoryData.keywords"></textarea> -->
        </div>
      </div>

    </div>
    <!-- 按钮组 -->
    <div>
      <common-button-group @buttonClickEvent="submit"
                           @closeDialog="doAfterClickCancelBtn"></common-button-group>
    </div>
  </div>
</template>
<script>
import cropUploadImg from '../../common/crop-upload-img';
import UEditor from '../../common/ueditor';
import { AddTeacher, EditTeacher, GetTeacherInfo } from '../../../../API/workbench';
export default {
  data () {
    return {
      isUEditor: false,
      dataObj: {
        'TeacherName': '',
        'courses': '',
        'picurl': '',
        'content': '',
        'save': ''
      }
    };
  },
  props: {
    item: Object,
    dataList: Array
  },
  components: {
    cropUploadImg, UEditor
  },
  created () {
    if (this.item) {
      console.log(this.item, 'created');
      this.dataObj = this.$utils.parseJson(this.item);
      this.getDataInfo(this.item.id);
    }
  },
  mounted () {
    this.isUEditor = true;
  },
  computed: {
    vPicurl () {
      if (this.dataObj.picurl) {
        return this.$store.getters.CDNURL + this.dataObj.picurl;
      }
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../../public/image/no_logo@2x.png') + '"';
    }
  },
  methods: {
    submit (callback) {
      this.dataObj.content = this.$refs.ueditor.getUEContent(); // -- 文章内容
      if (!this.checkForm()) {
        if (callback) {
          callback();
        }
        return false;
      }
      console.log(this.dataObj, 'submit2', this.$refs.ueditor.getUEContent());
      if (this.dataObj.id > 0) {
        this.editItem(callback);
      } else {
        this.dataObj.save = this.dataList.length + 1;
        this.addItem(callback);
      }
    },
    checkForm () {
      let ischeck = true;
      if (this.dataObj.TeacherName == '') {
        ischeck = false;
        layer.alert('请输入教师名');
      } else if (this.dataObj.courses == '') {
        ischeck = false;
        layer.alert('请输入课程名');
      } else if (this.dataObj.content == '') {
        ischeck = false;
        layer.alert('请输入内容');
      } else if (this.dataObj.picurl == '') {
        ischeck = false;
        layer.alert('请选择形象图片');
      }
      return ischeck;
    },
    addItem (callback) {
      AddTeacher(this.dataObj).then(result => {
        layer.alert('新增成功');
        this.$emit('addAfter');
        this.$nextTick(o => {
          setTimeout(() => {
            this.doAfterClickCancelBtn();
          }, 500);
        });
        if (callback) {
          callback();
        }
      }).catch(error => {
        console.log('AddTeacher', error);
      });
    },
    editItem (callback) {
      EditTeacher(this.dataObj).then(result => {
        layer.alert('修改成功');
        this.$emit('addAfter');
        this.$nextTick(o => {
          setTimeout(() => {
            this.doAfterClickCancelBtn();
          }, 500);
        });
        if (callback) {
          callback();
        }
      }).catch(error => {
        console.log('EditTeacherError', error);
      });
    },
    // 獲取詳情
    getDataInfo (id) {
      GetTeacherInfo(id).then(result => {
        this.dataObj = result.data;
        console.log(result.data);
      });
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    },
    doAfterUploadImgSuccess (url) {
      this.dataObj.picurl = url;
    }
  }
};
</script>

