<template>
  <div>
    <custom-dialog title="选择学生"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowAddStudent"
                   :before-close="closeAddStudent">
      <search-box :butMessage="'新增学生'"
                  :searchInitJson="searchDialogInitJson"
                  @searchClick="searchClick"
                  @onSelectedStudent="doAfterSelectedStudent"></search-box>
    </custom-dialog>
    <!-- 学生列表 -->
    <div>
      <custom-dialog title="学生列表"
                     class="refund_dialog defult_dialog"
                     :visible.sync="isShowStudentList"
                     :before-close="closeDialogForStudentList">
        <div>
          <student-list :isShowSearchInput="true"
                        @selectedItem="doAfterSelectedStudent"
                        ref="studentListDialog"></student-list>
        </div>
      </custom-dialog>
    </div>
    <custom-dialog title="欠还款详情"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isShowDebtDetails"
                   :before-close="closeDebtAmountDetailsDialog">
      <div>
        <debt-details :studentItem="studentInfo"
                      @repaymentEvent="showDebtDetails"
                      @confirmDebtDetails="confirmDebtDetails"
                      @closeDebtDetails="closeDebtAmountDetailsDialog"></debt-details>
      </div>
    </custom-dialog>
    <!-- 还款金额页面 -->
    <custom-dialog title="还款"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isShowBackAmount"
                   :before-close="closeDebtBackAmount">
      <div>
        <payment-and-withdraw :payOrWithdrawMethods="'还款方式'"
                              :amount="'还款金额'"
                              :canAmount="'欠款金额'"
                              :allClean="'全部还清'"
                              :studentItem="studentInfo"
                              v-on:closeshowWithDraw="closeDebtBackAmount"
                              v-on:showsucceedPayment="showsucceedPayment"
                              :cashRepayment="studentInfo.DebtAmount"></payment-and-withdraw>
      </div>
    </custom-dialog>
    <!-- 还款成功 -->
    <custom-dialog title="成功"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isShowSucceedPayment"
                   :before-close="closedsucceedPayment">
      <succeed-payment @closedsucceedPayment="closedsucceedPayment"></succeed-payment>
    </custom-dialog>
  </div>
</template>
<script>
import searchBox from './search-box';
import studentList from './student-list';
import debtDetails from '../pop-up-menu/sign-up-payment/debt-details';
import paymentAndWithdraw from './payment-and-withdraw';
import succeedPayment from './succeed-payment';
export default {
  data () {
    return {
      isShowStudentList: false,
      searchDialogInitJson: {
        name: '目标学生',
        isShowAddBtn: false,
        roleName: ''
      },
      studentInfo: {},
      isShowDebtDetails: false, // 是否显示客户还款
      detailAmount: 0, // 还款的详情.
      isShowBackAmount: false,
      isShowSucceedPayment: false,
      isShowAddStudent: false
    };
  },
  props: ['studentItem', 'isShowDebtAmountDetails'],
  components: {
    searchBox,
    studentList,
    debtDetails,
    paymentAndWithdraw,
    succeedPayment
  },
  watch: {
    studentItem (n, o) {
      this.studentInfo = n;
    },
    isShowDebtAmountDetails (n, o) {
      if (n == undefined) {
        this.isShowAddStudent = JSON.stringify(this.studentInfo) == '{}';
      } else {
        this.isShowAddStudent = n;
      }
    }
  },
  created () {
    this.studentInfo = this.studentItem;
    if (this.isShowDebtAmountDetails == undefined) {
      this.isShowAddStudent = JSON.stringify(this.studentInfo) == '{}';
    } else {
      this.isShowAddStudent = this.isShowDebtAmountDetails;
    }
  },
  methods: {
    // 查询数据 弹出列表
    searchClick () {
      // 调用接口查询学生列表 待完成
      this.isShowStudentList = true;
    },
    // 关闭学生列表
    closeDialogForStudentList () {
      this.isShowStudentList = false;
    },
    // 选中学生后裔
    doAfterSelectedStudent (val) {
      this.closeDialogForStudentList();
      this.isShowDebtDetails = true;
      this.isShowAddStudent = false;
      this.studentInfo = val;
    },
    showDebtDetails () {
      this.isShowBackAmount = true;
    },
    closeRepaymentEvent () {
      this.isShowBackAmount = false;
    },
    // 关闭成功提示页面
    closedsucceedPayment (booler) {
      this.isShowSucceedPayment = booler;
      this.$emit('backAmountSuccess');
    },
    // 确定 欠款详情
    confirmDebtDetails () {
      this.isShowBackAmount = true;
      // this.closeDebtAmountDetailsDialog();
    },
    // 关闭 欠款详情
    closeDebtAmountDetailsDialog () {
      this.isShowDebtDetails = false;
      this.closeAddStudent();
    },
    // 还款成功
    showsucceedPayment (Val) {
      this.isShowDebtDetails = false;
      this.isShowBackAmount = false;
      this.isShowSucceedPayment = true;
    },
    // 关闭还款页面
    closeDebtBackAmount () {
      this.isShowBackAmount = false;
    },
    // 显示学员档案.
    showStudentFile (item) {
      this.$emit('showStudentFile', item);
    },
    closeAddStudent () {
      this.$emit('closeDebtAmountDetail');
    }
  }
};
</script>

