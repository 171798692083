<template>
  <!-- 排课创收潜力挖掘 - 校区饱和排课设置 通用设置 修改-->
  <div class="saturationCourse_us">
    <div class="content_marginRL content_marginTop_30">
      <div class="saturation_course_content">
        <div class="saturation_course_top">
          <div class="index_title pr">
            <span>{{ this.updateTitle }}</span>
            <input class="input_num"
                   type="text"
                   v-model="updateNum" />

          </div>
        </div>
      </div>

    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
  </div>

</template> 

<script>
import { UpdateSchoolRoomSetting, ModifySetting } from '../../../../API/workbench';
export default {
  props: {
    updateText: [Number, String],
    updateTitle: String,
    dateInfo: Object,
    isAttendance: Boolean
  },
  data () {
    return {
      updateNum: '',
      SchoolRoomKey: '',
      updateInfo: {
        ArrangeCourseNum: '', // 排课次数
        CourseStudentNum: ''// 每课次学生数
      }
    };
  },

  components: {
  },
  computed: {
  },
  watch: {
  },
  created () {
    if (!this.isAttendance) {
      this.updateInfo.ArrangeCourseNum = this.dateInfo.ArrangeCourseNum;
      this.updateInfo.CourseStudentNum = this.dateInfo.CourseStudentNum;
      this.SchoolRoomKey = this.dateInfo.OLAPKey;
    }
    console.log(this.updateText, 'updateText', this.dateInfo, this.updateTitle);
    this.updateNum = this.isAttendance ? this.dateInfo.SetContent : this.updateText;
  },
  mounted () {
  },
  methods: {
    confirmClick () {
      if (this.isAttendance) {
        this.dateInfo.SetContent = this.updateNum;
        this.modifySettingAfter(this.dateInfo);
      } else {
        if (this.updateTitle == '周饱和排课') {
          this.updateInfo.ArrangeCourseNum = this.updateNum;
        } else {
          this.updateInfo.CourseStudentNum = this.updateNum;
        }
        UpdateSchoolRoomSetting(this.SchoolRoomKey, this.updateInfo)
          .then(result => {
            console.log(result.data, 'UpdateSchoolRoomSetting');
            layer.alert('修改成功');
            this.$emit('afterSuccess');
          }, err => {
            layer.alert(err.msg);
          });
      }
    },
    // 更新门店设置
    modifySettingAfter (dataInfo, callBack) {
      ModifySetting(dataInfo).then(res => {
        layer.alert('修改成功');
        this.$emit('afterSuccess');
      }, err => {
        layer.alert(err.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    cancelClick () {
      this.$emit('cancelClick');
    }
  }
};
</script>

<style scoped>
/*  */
.saturationCourse_us {
  height: 120px;
}
.saturation_course_content {
  width: 387px;
  margin: 0 auto;
  height: 52px;
}
.index_title {
  font-size: 14px;
  line-height: 50px;
  display: flex;
  width: 380px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(236, 236, 236, 1) !important;
  padding-left: 0px !important;
}
.right_surFlow {
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #3498db;
}
.input_num {
  border: 1px solid #ececec;
  height: 40px;
  border-radius: 4px;
  padding-left: 10px;
  color: #3498db;
  margin-top: 5px;
}
</style>