<template>
  <!-- 课程排课 / 课程管理 -->
  <div class="course_arrange_manage_view all_view "
       style="padding: 0px!important;width: auto!important;">
    <generalClassGradeApplyCourseList></generalClassGradeApplyCourseList>
    <div>
      <dialog-factory ref="courseScheduleManagementDialogFactory"
                      :key="'courseScheduleManagement'"
                      :routerName="'courseScheduleManagement'"></dialog-factory>

      <dialog-factory-common ref="courseScheduleManagementDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>

</template>
<script>
import generalClassGradeApplyCourseList from './general-class-grade-apply-course-list/index';
import dialogFactory from '../../common/dialog-factory';
export default {
  name: 'courseScheduleManagement',
  data () {
    return {
    };
  },
  components: {
    dialogFactory,
    generalClassGradeApplyCourseList
  },
  watch: {
  },
  computed: {
  },
  created () {

  },
  mounted () {
    this.$dialog.register(this,
      [
        'studentFile', 'attendanceArrange', 'courseScheduleManagement', 'newCircleCourseTable'
      ]);
    this.registerBusEvent();
  },
  methods: {
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'courseScheduleManagement') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'courseScheduleManagement') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'courseScheduleManagement') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.courseScheduleManagementDialogFactory) {
        this.$refs.courseScheduleManagementDialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.courseScheduleManagementDialogFactoryCommon) {
        this.$refs.courseScheduleManagementDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>

<style>
</style>
