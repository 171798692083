<template>
  <!-- 流量余额List -->
  <div class="flow_balance">
    <div class="flow_box">
      <div class="box_icon">
        <img class="box_icon_img"
             :src="require('../../../../../../public/image/balance.png')">
      </div>
      <div class="box_content">
        <div class="box_content_top "
             @click.stop="currentAmountClick('balanceDetailReport')">
          <span class="box_content_top_text font_flow">当前余额</span>
          <span class="box_content_top_number pr">{{Number(vCurrentAmount).toFixed(2)}} <span class="mendian_info_btn"></span></span>

        </div>
        <div class="box_content_top">
          <!--  -->
          <div class="box_content_top_tip"
               v-if="vWithdrawalAmount > 0">
            <span class="box_content_top_text font_color_flow">-{{Number(vWithdrawalAmount)}}</span>
            <span class="box_content_top_text font_color_org">提现中</span>
          </div>
        </div>
      </div>
      <div class="box_bottom">
        <div type="primary"
             class="button_right_box btn_hover_bg_blue btn_down_btn btn_top_btn"
             @click.stop="currentAmountClick('rechargeTheBalance',1)">充值</div>
        <div type="primary"
             class="flow_cash_btn btn_down_btn"
             @click.stop="currentAmountClick('rechargeTheBalance',2)">提现</div>
      </div>
    </div>

    <div class="flow_box height_bottom flowtip pr">
      <div class="box_icon">
        <img class="box_icon_img"
             :src="require('../../../../../../public/image/flow.png')">
      </div>
      <div class="box_content pr ">
        <div class="box_content_top"
             @click.stop="currentAmountClick('flowPacketReport')">
          <span class="box_content_top_text font_flow">当前流量</span>
          <span class="box_content_top_number pr"
                :class="vFlowTips?'font_color_red':''">{{this.$utils.flowFormate(vCurrentFlow)}}
            <span class="mendian_info_btn"></span>
          </span>

        </div>
        <div class="box_content_top pat ">
          <span class="box_content_top_text font_flow_tip">通用流量</span>
          <span class="box_content_top_text font_tip_color"
                :class="vFlowTips?'font_color_red':''">&nbsp;&nbsp;{{this.$utils.flowFormate(vGeneralFluxCount)}}</span>
          <div style="display: inline;line-height: 10px;"
               v-if="!Number(vGiveFluxCount) == 0">
            <span class="box_content_top_text font_flow_tip">&nbsp;|&nbsp;定向流量</span>
            <span class="box_content_top_text font_tip_color"
                  :class="vFlowTips?'font_color_red':''">{{this.$utils.flowFormate(vGiveFluxCount)}}</span>
          </div>
        </div>
        <div class="box_content_top pr"
             @click.stop="currentAmountClick('flowPacketBill')">
          <span class="box_content_top_text font_flow flowMonth">当月消耗</span>
          <span class="box_content_top_number flowMonth pr">{{this.$utils.flowFormate(vConsumeFlowForMonth,'no','-')}}
            <span class="mendian_info_btn btnflowMonth"></span>
          </span>

        </div>
      </div>
      <div class="box_bottom pa box_bottom_t">
        <div type="primary"
             class="button_right_box btn_hover_bg_blue buy_buttom"
             @click.stop="currentAmountClick('rechargeTheBalance',3)">购买</div>
      </div>
    </div>
  </div>

</template> 
<script>
import {
  GetSaaSClientMsg,
  GetSaaSClientDynamicData
} from '../../../../../API/workbench';
export default {
  data () {
    return {
      'flowPacketReport': {
        'title': '流量包',
        'dialogWidth': '1000px',
        'moduleName': 'flowPacketReport'
      },
      'flowPacketDetailReport': {
        'title': '流量明细',
        'dialogWidth': '1000px',
        'moduleName': 'flowPacketDetailReport'
      },
      'balanceDetailReport': {
        'title': '余额明细',
        'dialogWidth': '1000px',
        'moduleName': 'balanceDetailReport'
      },
      'flowPacketBill': {
        'title': '流量账单',
        'moduleName': 'flowPacketBill'
      },
      dataList: this.$store.getters.SaaSClientInfo || {},
      dynamicData: {}
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: []
    }
  },
  components: {
  },
  created () {
    this.getMendianInfo();
    this.getDynamicData();
    console.log(this.dataList, 'this.dataInfo111');
  },
  mounted () {

  },
  watch: {
  },
  methods: {
    // flowFormate (size) {
    //   size = Number(size);
    //   if (size == 0) {
    //     return 0 + 'KB';
    //   }
    //   let kb = 1024;
    //   let unit = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
    //   let i = Math.floor(Math.log(size) / Math.log(kb));
    //   return (size / Math.pow(kb, i)).toPrecision(3) + ' ' + unit[i];
    // },

    getMendianInfo () {
      GetSaaSClientMsg().then(result => {
        this.dataList = result.data;
        this.$store.commit('setSaaSClientInfo', result.data);
      }, err => {
        layer.alert(err.msg);
      });
    },
    getDynamicData () {
      GetSaaSClientDynamicData().then(result => {
        this.dynamicData = result.data;
      }, err => {
        layer.alert(err.msg);
      });
    },
    currentAmountClick (str, number) {
      let obj = {};
      let titleName = '';
      if (str == 'rechargeTheBalance' && number == 3) {
        titleName = '流量充值';
        obj = {
          putData: {
            type: '3',
            title: '购买通用流量',
            placeholder: '',
            buttomName: '确认充值',
            scTitle: '付款方式',
            isShowBottom: false
          }
        };
      }
      if (str == 'rechargeTheBalance' && number == 2) {
        if (!this.vHasModulePower) {
          layer.alert('暂无权限');
          return;
          // 无权限
        }

        if (this.dynamicData.WithdrawalApply > 0) {
          layer.alert('已有一笔提现申请正在审核中');
          return;
        }
        titleName = '余额提现';
        obj = {
          putData: {
            type: '2',
            title: '提现金额',
            placeholder: '手动输入提现金额',
            buttomName: '提交',
            scTitle: '提现原因',
            isShowBottom: true
          }
        };
      }
      if (str == 'rechargeTheBalance' && number == 1) {
        titleName = '余额充值';
      }
      console.log(str, '弹窗路由');
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: titleName,
        moduleName: str,
        data: obj,
        callBack: {
          afterSuccess: () => {
            this.getMendianInfo();
            this.getDynamicData();
          }
        }
      });
    }

  },
  computed: {
    vDataInfo () {
      return this.$store.getters.SaaSClientInfo;
    },

    vGeneralFluxCount () {
      return this.dataList.GeneralFluxCount || 0;
    },
    vCurrentFlow () {
      return this.dataList.CurrentFlow || 0;
    },
    vGiveFluxCount () {
      return this.dataList.GiveFluxCount || 0;
    },
    vConsumeFlowForMonth () {
      return this.dynamicData.ConsumeFlowForMonth || 0;
    },
    vWithdrawalAmount () {
      return this.dynamicData.WithdrawalAmount || 0;
    },
    vCurrentAmount () {
      return this.dataList.CurrentAmount || 0;
    },
    vFlowConsumeForWeek () {
      return this.dynamicData.FlowConsumeForWeek || 0;
    },
    vFlowTips () {
      return Number(this.vFlowConsumeForWeek) > Number(this.vCurrentFlow);
    },
    vHasModulePower () {
      // if (this.dataList.EditionKey == 6)
      // if (this.vDataInfo.EditionKey == 6) {
      //   console.log(this.$store.getters.token.RoleKey,'this.$store.getters.token.RoleKey')
      //   return Number(this.$store.getters.token.RoleKey) == 2
      // }
      return this.$utils.CheckModulePower(
        this.vRolePowerList,
        143
      );
    },
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    }
  }
};
</script>

<style scoped>
.pat {
  padding-top: 15px;
}
.flow_balance {
  margin-top: 20px;
  margin-right: 20px;
}
.flow_box {
  width: 103%;
  height: 80px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ececec;
}
.height_bottom {
  height: 90px;
}
.box_icon {
  width: 38px;
}
.box_content {
  flex: 1;
  flex-direction: column;
}
.box_content_top {
  display: flex;
  height: 37px;
  width: 100%;
  flex-direction: row;
  white-space: nowrap;
}
.box_content_top_tip {
  box-sizing: border-box;
  background: rgba(231, 234, 239, 0.35);
  border: 1px solid #ececec;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.02), 0px 3px 4px rgba(0, 0, 0, 0.03);
  border-radius: 100px;
  line-height: 20px;
  height: 24px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 11px;
}
.box_content_top_text {
  font-size: 12px;
}
.font_color_org {
  color: #fea409;
}
.font_flow {
  color: #999999;
  line-height: 57px;
  /* margin-right: 10px; */
}
.font_flow_tip {
  color: #999999;
}
.font_color_flow {
  color: #666666;
}
.box_bottom {
  width: 60px;
  display: flex;
  flex-direction: column;
}
.box_icon_img {
  width: 30px;
  height: 30px;
  margin: 13px 10px 0 0;
}
.box_content_top_number {
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 50px;
  flex: 1;
  padding-left: 10px;
}
.box_content_top_detail {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 35px;
  /* or 183% */
  align-items: center;
  cursor: pointer;
  color: #3498dc;
  padding-left: 15px;
  width: 10px;
  height: 15px;
  background-image: url(../../../../../../public/image/list_next@2x.png);
  background-size: contain;
  margin-top: 18px;
  margin-left: 5px;
}
.btn_down_btn {
  width: 50px;
  height: 24px;
  border-radius: 4px;
  margin-top: 10px;
  text-align: center;
  align-items: center;
  line-height: 23px;
  margin-left: 10px;
}
.btn_top_btn {
  margin-top: 15px;
}
.buy_buttom {
  width: 50px;
  height: 24px;
  border-radius: 4px;
  text-align: center;
  align-items: center;
  line-height: 23px;
  margin-left: 10px;
}
.flow_cash_btn {
  color: #666666;
  background: #ffffff;
  border: 1px solid #ececec;
  cursor: pointer;
}
.title_tip {
  display: flex;
  width: 266px;
  bottom: -18px;
  left: 0;
}
.font_tip_color {
  color: #333333;
  /* padding-left: 10px; */
}
.font_color_red {
  color: #ff6c67 !important;
}
.mendian_info_btn {
  top: 18px;
  right: inherit;
}
.flowtip {
  height: 100px;
}
.flowMonth {
  line-height: 20px;
  margin-right: 0;
}
.btnflowMonth {
  top: 0px;
}
.box_bottom_t {
  top: 15px;
  right: 0px;
}
</style>