<template>
    <div class="ppt_uploading">
        <div class="statistics-box pr " style="width: 221px;margin: 20px">
            <statistics-box
                :dataList="statisticsData"
                @clickEvent="clcikStatictisItem"
            ></statistics-box>
            <div class="ppt_uploading_tips_box">
                请处理问题后，更新上传。页面不可删除、添加，不可修改顺序。
            </div>
            <div class="mendian_backstage_search">
                <div class="btn_hover_bg_blue search_btn pr" style="width:80px">
                    更新文件
                     <upload :attachType="'4'"
                        :coursewareId="coursewareId"
                        :VisitAuthKey="VisitAuthKey"
                        :dataInfo="coursewareInfo.CoursewareAttach"
                        :authorizedCode="authorizedCode"
                        :IsUpdateFile="1"
                        @deleteAccessory="deleteAccessory"
                        @saveAccessory="saveAccessory"></upload>
                    <!-- <input @change="chooseFiles($event)"
                        class="upload_input"
                        type="file"
                        :accept="'.ppt,.pptx,.pps,.ppsx'" /> -->
                </div>
            </div>
        </div>
        <div class="ppt_upload_list">
            <accessory-item v-for="(item, index) in vdataList"
                          :key="index"
                          :keyIndex="index"
                          :dataInfo="item"
                          @selectItem="selectAccessory"
                          @upItem="upTeachingStepAccessory"
                          @downItem="downTeachingStepAccessory"></accessory-item>
            <div class="index_JXT_noData" style="width: 100%;margin-top:100px"
                v-if="dataList.length==0">
                <span class="index_JXT_noData_text">加载中...</span>
            </div>
        </div>
    </div>
</template>
<script>
import statisticsBox from "../../../report/common/statistics-box";
import accessoryItem from './accessory-item'
import {
 WEBVPptPageEscapeVerify,HQPptPageEscapeVerify,CWSPptPageEscapeVerify
} from '../../../../API/workbench';
import upload from '../courseware-view/upload';
export default {
    data(){
        return{
            statisticsData: {
                errorCount: {
                title: "异常",
                value: 0,
                type: 1,
                isSelection: false,
                numberClassName:'font_red' //#FF6C67
                },
                totalCount: {
                title: "全部",
                value: 0,
                type: 2,
                isSelection: true,
                },
            },
            searchType:2,
        }
    },
    components:{
        statisticsBox,accessoryItem,upload
    },
    props:{
        dataInfo:Object,
        coursewareInfo:Object,
        dataList:Array,
        coursewareId: [String, Number],
        authorizedCode: String,

    },
    computed:{
        VisitAuthKey () {
            return this.coursewareInfo.Authorized.OLAPKey || '';
        },
        vmdInfo () {
            return this.$store.getters.SaaSClientInfo;
        },
        vdataList(){
            let newArr=[]
            this.statisticsData.errorCount.value=0
            this.statisticsData.totalCount.value=this.dataList.length
            this.dataList.forEach(o=>{
                if(o.progress!=100||o.isError){ //o.isError
                    this.statisticsData.errorCount.value++
                }
                if(this.searchType==2){
                    newArr.push(o)
                }else{
                   if(o.progress!=100||o.isError){  //o.isError
                        newArr.push(o)
                    } 
                }
            })
            return newArr
        }
    },
    created(){
        console.log("dataInfo",this.dataInfo)
    },
    methods:{
        
        // 点击分类
        clcikStatictisItem(item) {
        this.searchType = item.type;
        // this.getDataList();
        },
       
        saveAccessory(data){
            console.log('updatePPTAuthData',data)
            this.$emit('updatePPTAuthData',this.dataInfo,data);
            // this.getDataList()
        },
        deleteAccessory(){
            this.$emit("deleteAccessory")
        },
        selectAccessory(item){

        },
        upTeachingStepAccessory(item){

        },
        downTeachingStepAccessory(item){

        }

    }
}
</script>
<style scoped>
    .ppt_uploading_tips_box{
        margin: 10px 20px;
        padding: 8px 10px;
        /* padding-right: 80px; */
        line-height: 18px;
        background-color: #fffbe6;
        border: 1px solid #ffe694;
        position: absolute;
        left: 241px;
        top: 0px;
        width: 450px;
        border-radius: 2px;
        display: flex;
        justify-content: space-between;
    }
    .ppt_upload_list{
        margin: 20px;
        height: 600px;
        overflow-y:scroll;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
    }
    .ppt_uploading>>>.upload_input{
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        left: 0px;
        top: 0px;
        
    }
    .ppt_uploading .mendian_backstage_search{
        position: absolute;
        top: 10px;
        left: 820px;
    }
</style>