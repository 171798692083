<template>
  <div class="">
    <div v-if="vDataList.length!=0"
         class="attendance_course_date_ul">
      <atten-date-item v-for="(item,key) in vDataList"
                       :bantchChgCourse="bantchChgCourse"
                       :key="key"
                       :dateItem="item"></atten-date-item>
    </div>
    <basics-nodata-tips v-if="vDataList.length==0"></basics-nodata-tips>
  </div>

</template>

<script>
import attenDateItem from './atten-date-item.vue';
import { GetXYKDClassHourseForAttendance } from '../../../../../../API/workbench';
export default {
  data () {
    return {
      dataList: []
    };
  },
  components: {
    attenDateItem
  },
  props: {
    searchObj: Object,
    bantchChgCourse: { // 是否选择批量换课单扣
      type: Boolean,
      default: false
    }
  },
  created () {
    if (this.searchObj.TableID > 0) {
      this.getDataList();
    }
  },
  computed: {
    vDataList () {
      const datelist = [];
      this.dataList.forEach(o => {
        const locaItem = datelist.find(p => {
          return p.date == o.ClassTime.substring(0, 7);
        });
        if (!locaItem) {
          const newobj = {
            date: o.ClassTime.substring(0, 7),
            childList: []
          };
          datelist.push(newobj);
        }
      });
      datelist.forEach(o => {
        this.dataList.forEach(p => {
          if (o.date == p.ClassTime.substring(0, 7)) {
            o.childList.push(p);
          }
        });
      });
      console.log(datelist, 'vDataList111');
      return datelist;
    }
  },
  methods: {
    initDataList () {
      this.dataList.forEach(o => {
        o.isActive = false;
      });
    },
    getDataList () {
      this.dataList = [];
      GetXYKDClassHourseForAttendance(this.searchObj.TableID).then(result => {
        console.log('GetXYKDClassHourseForAttendance', result.data);
        this.dataList = result.data;
        this.dataList.forEach(o => {
          this.$set(o, 'isActive', false);
        });
      });
    }
  }
};
</script>

<style scoped>
.attendance_course_date_ul {
  max-height: 600px;
  overflow-y: auto;
}
</style>
