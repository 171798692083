<template>
  <div class="batchEnter_student_box">
    <!-- 模块重点说明 -->
    <module-description :textTips="'导入老学员可分批次导入。'"
                        :btnTips="'查看教程'"
                        :btnUrl="'https://mp.weixin.qq.com/s/9kLtbXOLBg7yPB-dzSS8Hg'"></module-description>
    <div class="batchEnter_student_top course_table_header">
      <div class>
      </div>
      <!-- 录入汇总数据 -->
      <div class="batchEnter_student_statistics fl">
        <div class="statistics_list"
             :class="{opt:queryExceptionCatchParams.SpecialFilter==0}"
             @click.stop="clickStatisticsNumber(0)">
          <div>当前录入</div>
          <div class="statistics_list_number">{{statisticsInfo.TotalNum}}</div>
        </div>
        <div class="statistics_list"
             :class="{opt:queryExceptionCatchParams.SpecialFilter==1}"
             @click.stop="clickStatisticsNumber(1)">
          <div>异常录入</div>
          <div class="statistics_list_number">{{statisticsInfo.ExceptionNum}}</div>
        </div>
        <div class="statistics_list"
             :class="{opt:queryExceptionCatchParams.SpecialFilter==2}"
             @click.stop="clickStatisticsNumber(2)">
          <div>学生同名异常</div>
          <div class="statistics_list_number">{{statisticsInfo.DuplicationNum}}</div>
        </div>
      </div>
      <div class="fr">
        <div class="el-button btn_light_blue btn_hover_bg_blue"
             @click="doOpenHistoryRecordClick()">历史记录{{ "("+statisticsInfo.HistoryNum+")"}}</div>
        <a class="el-button btn_light_blue btn_hover_bg_blue"
           :href="$store.getters.CDNURL + '/ImportTemplate/老生导入模版.xls'">下载模板</a>
        <div class="el-button btn_light_blue btn_hover_bg_blue"
             style="padding:0">
          <el-upload accept=".xls, .xlsx"
                     action="string"
                     ref="upload"
                     :show-file-list="false"
                     :http-request="importEvent">导入</el-upload>
        </div>
      </div>
    </div>
    <div class="batchEnter_student_table">
      <table-header :isShowPart="isShowPart"></table-header>
      <table-body ref="tableBody"
                  :isShowPart="isShowPart"
                  :tableData="tableData"
                  :cacheParams="queryCacheParams"
                  :SpecialFilter="queryExceptionCatchParams.SpecialFilter"
                  @deleteRecord="afterDeleteRecord"
                  @onImportBtnSave="afterImportBtnSave"
                  @doScrollPageLoadData="afterScrollPageLoadData"></table-body>
    </div>
    <div class="batchEnter_student_bottom">
      <div class="batchEnter_student_bottom_date"
           v-if="lastSaveTime">上次自动保存时间：{{lastSaveTime}}</div>
      <div class="batchEnter_student_bottom_tips"
           v-if="vSameStudentName">学生重名提交后会合并学生档案。</div>

      <div class="batchEnter_student_bottom_btn_box clearfix">
        <save-cancel-btn-group :cancelBtnText="'重置'"
                               :btnText="'提交'"
                               :isSingleSubmit="true"
                               :fixedBtn="false"
                               :singleSubmitType="'dynamic'"
                               :btnClass="vSubmitBtnClass"
                               @confirmClick="submitClick"
                               @cancelClick="resetClear"></save-cancel-btn-group>
      </div>
    </div>
    <custom-dialog title="历史记录"
                   width="1233px"
                   class="popup_dialog_withe"
                   :visible.sync="isShowHistoryRecord"
                   :before-close="closeHistoryRecord">
      <history-record :contentList="chistoryRecordList"
                      :parameter="queryHistoryParames"
                      @onCilckSearchName="getMessageSearchPageHistoryForMakeUp"
                      @closeSwitchoverShow="closeHistoryRecord"></history-record>
    </custom-dialog>

    <!-- 显示加载的进度条 -->
    <custom-dialog class="progress"
                   width="300px"
                   :visible.sync="showProgress">
      <submit-progress-tip :tips="'正在批量'+ProgressHintText"></submit-progress-tip>
    </custom-dialog>
  </div>
</template>

<script>
import tableHeader from './table-header/header-row';
import tableBody from './table-body';
import {
  OldStudentEnterTemp,
  MessageSearchPageHistoryForMakeUp,
  SubmitMakeUp,
  ImportMakeUp,
  MessageSearchPageCacheForMakeUp,
  MessageSearchPageExceptionForMakeUp,
  ClearInputStudent
} from '../../../API/workbench';
import historyRecord from './history-record/index';
export default {
  data () {
    return {
      statisticsInfo: {
        TotalNum: 0,
        ExceptionNum: 0,
        DuplicationNum: 0,
        HistoryNum: 0 // 历史记录条数
      },
      isShowPart: false, // 全部、精简
      tableData: [],
      deleteData: [], // 删除的数据
      lastSaveTime: '', // 上次保存时间
      isShowHistoryRecord: false, // 显示历史记录
      // 查询历史数据字段
      queryHistoryParames: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	 页码，0为第一页
        pageSize: 20, //	整型	必须	0	数据源：GET	  每页记录数，0：表示查询所有
        orderBy: 'SubmitTime', //	字符串	可选		数据源：GET	 排序字段
        sequence: '', //	字符串	可选		数据源：GET	 排序字段
        searchText: '', //	字符串	可选		数据源：GET	 搜索条件：模糊匹配学生姓名，首字母，手机号
        IsExportKey: 0,
        noMore: false, // 翻页查询
        loading: false // 加载中提示
      },
      // 查询已缓存且异常的数据
      queryExceptionCatchParams: {
        pageIndex: 0,
        pageSize: 20,
        orderBy: 'Rank',
        sequence: 'ASC',
        searchText: '',
        QueryID: '', // 数据id
        SpecialFilter: 0// 1-异常录入;2-同名异常
      },
      // 查询已缓存未提交的全部数据
      queryCacheParams: {
        pageSize: 20,
        guid: '',
        noMore: false
      },
      parseTableData: [],
      // 历史记录列表
      chistoryRecordList: [],
      chistoryRecordListTotal: 0, // 历史记录数量
      cacheRecordList: [],
      timeoutObj: null,
      showProgress: false, // 显示进度条状态
      ProgressHintText: '',
      clientId: this.$store.getters.clientId, // 当前登录终端的唯一识别
      loading: false// 正在加载
    };
  },
  props: {
    isShowHistoryRecords: Boolean
  },
  components: {
    tableHeader,
    tableBody,
    historyRecord
  },
  computed: {
    // 学生已存在 和 同一批录入的 学生姓名 手机号完全相同
    vSameStudentName () {
      let flag = false;
      for (let index = 0; index < this.tableData.length; index++) {
        let obj = this.tableData[index];
        if (obj.guid) {
          let isExists = obj.WarningList.findIndex(obj => { return obj.TypeKey == 1 && obj.TypeKeyValue != '学生姓名重复且手机号不同'; });
          if (isExists >= 0) {
            flag = true;
            break;
          }
        }
      }
      return flag;
    },
    vTableData () {
      return this.tableData.filter(obj => { return obj.guid; });
    },
    vSubmitBtnClass () {
      let className = 'btn_light_gray';
      if (this.statisticsInfo.TotalNum > 0) {
        className = 'btn_light_blue btn_hover_bg_blue';
      }
      return className;
    }
  },
  watch: {
  },
  created () {
    this.loadMessageSearchPageCacheForMakeUp();
    this.registerBusEvent();
    if (this.isShowHistoryRecords) {
      this.doOpenHistoryRecordClick();
    }
  },
  mounted () {
    this.autoSaveCatchData();
  },
  methods: {
    // 点击汇总 数据
    clickStatisticsNumber (type) {
      this.$refs.tableBody.blurForRow();
      this.queryExceptionCatchParams.SpecialFilter = type;
      this.queryCacheParams.noMore = false;
      document.getElementsByClassName('table_tbody_box')[0].scrollTop = 0;
      this.saveOldStudentEnterTemp(() => {
        this.parseTableData = this.$utils.parseJson(this.tableData);
        this.tableData = [];
        if (type == 0) {
          this.queryCacheParams.guid = '';
          this.loadMessageSearchPageCacheForMakeUp();
        } else {
          this.queryExceptionCatchParams.pageIndex = 0;
          if (type == 2) {
            this.queryExceptionCatchParams.pageSize = 0;
          } else {
            this.queryExceptionCatchParams.pageSize = 20;
          }
          this.loadMessageSearchPageExceptionForMakeUp();
        }
      });
      // console.log('自动缓存', this.tableData);
    },
    // 滚动加载下一页
    afterScrollPageLoadData () {
      if (this.queryExceptionCatchParams.SpecialFilter == 0) {
        this.loadMessageSearchPageCacheForMakeUp();
      } else {
        this.loadMessageSearchPageExceptionForMakeUp();
      }
    },
    // 获取已缓存未提交的数据
    loadMessageSearchPageCacheForMakeUp (callBack) {
      if (this.vTableData.length > 0) {
        this.queryCacheParams.guid = this.vTableData[this.vTableData.length - 1].guid;
      } else {
        this.queryCacheParams.guid = '';
      }
      MessageSearchPageCacheForMakeUp(this.queryCacheParams.pageSize, this.queryCacheParams.guid, this.$store.getters.clientId).then(result => {
        console.log(result.data, '获取已缓存未提交的数据');
        let dataList = result.data.pageDataList;
        let sumamarize = result.data.sumamarize;
        dataList.forEach(obj => {
          this.$set(obj, 'optioningRow', false);
          this.$set(obj, 'focusField', '');
          obj.randomNumber = Math.random().toString().slice(-8);
        });
        if (this.queryCacheParams.guid) {
          this.tableData = this.vTableData.concat(dataList);
        } else {
          this.tableData = dataList;
        }

        this.statisticsInfo = sumamarize;
        this.resetCatchData(this.tableData);
        this.queryCacheParams.noMore = this.tableData.length >= this.statisticsInfo.TotalNum;
        if (callBack) {
          callBack();
        }
      });
    },
    //  获取已缓存且异常的数据
    loadMessageSearchPageExceptionForMakeUp () {
      MessageSearchPageExceptionForMakeUp(this.queryExceptionCatchParams).then(result => {
        console.log(result.data, '获取已缓存且异常的数据');
        if (result.data) {
          // 切换学生类型时 (首页)需清空数据
          if (this.queryExceptionCatchParams.pageIndex == 0) {
            this.tableData = [];
          }
          this.statisticsInfo = result.data.sumamarize;
          let dataList = result.data.PageDataList;
          this.tableData = this.tableData.filter(obj => { return obj.guid; });
          dataList.forEach(obj => {
            let hasFind = false;
            if (this.tableData.length > 0) {
              hasFind = this.tableData.findIndex(p => p.guid == obj.guid) >= 0;
            }
            if (!hasFind) {
              this.$set(obj, 'optioningRow', false);
              this.$set(obj, 'focusField', '');
              obj.randomNumber = Math.random().toString().slice(-6);
              this.tableData.push(obj);
            }
          });
          this.queryCacheParams.noMore = this.tableData.length >= result.data.Total;
          this.resetCatchData();
        }
        if (this.queryExceptionCatchParams.SpecialFilter == 1) {
          this.queryExceptionCatchParams.pageIndex++;
        }
      });
    },
    // 对加载回来的缓存数据进行加工
    resetCatchData (data) {
      // 未撑满全屏 末尾追加空行（全屏 11行）
      let hasRecodeLength = this.tableData.length;
      if (hasRecodeLength <= 11) {
        for (let index = 1; index <= 11 - hasRecodeLength; index++) {
          this.tableData.push({ Number: index + hasRecodeLength, focusField: '', ExceptionList: [], WarningList: [] });
        }
      }
    },
    // 字段缓存数据
    autoSaveCatchData () {
      this.timeoutObj = setTimeout(() => {
        this.saveOldStudentEnterTemp();
      }, 60 * 1000);
    },
    // 自动保存
    saveOldStudentEnterTemp (callBack) {
      let catchData = this.needSaveTempRecord();
      console.log('自动缓存新变更的数据', catchData, this.deleteData);
      if (catchData.length > 0 || this.deleteData.length > 0) {
        if (this.timeoutObj) {
          clearTimeout(this.timeoutObj);
        }
        // 用于快速删除的时候 防止清空
        let deleteDataList = this.$utils.parseJson(this.deleteData);
        this.deleteData = [];
        OldStudentEnterTemp(catchData, deleteDataList).then(result => {
          console.log('自动缓存成功', this.$utils.parseJson(result.data));
          this.autoSaveCatchData();
          this.lastSaveTime = this.$utils.formateDateYMRHMS(Date.new(), 'yyyymmddhhmm');
          this.statisticsInfo = result.data;
          if (typeof callBack == 'function') {
            callBack();
          }
        });
      } else {
        if (typeof callBack == 'function') {
          callBack();
        }
      }
    },
    // 加载更多的缓存数据（删除的时候）
    loadMoreByDeleteLessThan15 () {
      this.loading = true;
      this.saveOldStudentEnterTemp(() => {
        if (this.queryExceptionCatchParams.SpecialFilter == 0) {
          this.loadMessageSearchPageCacheForMakeUp(() => { this.loading = false; });
        } else {
          this.loadMessageSearchPageExceptionForMakeUp();
        }
      });
    },
    // 需要缓存的数据
    needSaveTempRecord () {
      let needSaveData = [];
      this.tableData.forEach(obj => {
        if (obj.guid && obj.updateTime) {
          needSaveData.push(obj);
          console.log(obj.guid, obj.updateTime);
          obj.updateTime = '';
        }
      });
      return needSaveData;
    },
    // 删除一行
    afterDeleteRecord (rowData, deleteIndex) {
      console.log(rowData.guid, rowData.StudentKeyValue, deleteIndex);
      this.deleteData.push(rowData.guid);
      this.tableData.splice(deleteIndex, 1);
      if (this.queryExceptionCatchParams.SpecialFilter == 1) {
        this.statisticsInfo.ExceptionNum -= 1;
      } else if (this.queryExceptionCatchParams.SpecialFilter == 2) {
        // 同名异常的如果大于等于两条不做处理 等于一条就删除剩下的一条
        let sameNameExpArr = this.tableData.filter(obj => { return obj.StudentKeyValue === rowData.StudentKeyValue && obj.CustomerPhoneName != rowData.CustomerPhoneName; });
        if (sameNameExpArr.length == 1) {
          let index = this.tableData.findIndex(obj => { return obj.guid == sameNameExpArr[0].guid; });
          if (index >= 0) {
            this.tableData.splice(index, 1);
          }
          this.statisticsInfo.DuplicationNum -= 1;
        }
      }
      this.tableData.forEach((o, index) => { o.Number = index + 1; });// 重新归置序号
      this.statisticsInfo.TotalNum -= 1;
      // 删除后剩下的数据小于15条 且还有更多数据可加载回来 自动去加载下一页
      if (this.vTableData.length < 15 && !this.queryCacheParams.noMore) {
        if (!this.loading) {
          this.loadMoreByDeleteLessThan15();
        }
      } else {
        if (this.tableData.length < 12 && this.queryCacheParams.noMore) { // 删除后剩下的数据小于12条 且没有更多数据可供加载 增加一行空行
          this.tableData.push({ Number: this.tableData.length + 1, focusField: '', ExceptionList: [], WarningList: [] });
        }
      }
    },
    // 添加新增录入行
    afterImportBtnSave (newRow) {
      newRow.ApplyClassKey = newRow.ApplyClassKey ? Number(newRow.ApplyClassKey) : 0;
      newRow.randomNumber = Math.random().toString().slice(-6);
      this.tableData.unshift(newRow);
      this.tableData.forEach((obj, index) => {
        if (obj.guid != newRow.guid) {
          obj.Number = index + 1;
        }
      });
      if (this.queryExceptionCatchParams.SpecialFilter != 0) {
        this.clickStatisticsNumber(0);
      } else {
        this.saveOldStudentEnterTemp();// 暂存
      }
      if (document.getElementsByClassName('table_tbody_box')[0].scrollTop > 0) {
        document.getElementsByClassName('table_tbody_box')[0].scrollTop = 0;
      }
    },
    // 打开历史记录弹窗
    doOpenHistoryRecordClick () {
      console.log('打开历史记录弹窗');
      this.isShowHistoryRecord = true;
      this.queryHistoryParames.pageIndex = 0;
      this.queryHistoryParames.noMore = false;
      this.getMessageSearchPageHistoryForMakeUp();
    },
    // 获取历史记录
    getMessageSearchPageHistoryForMakeUp (parames, callBack) {
      if (parames) {
        Object.assign(this.queryHistoryParames, parames);
      }
      this.queryHistoryParames.loading = true;
      MessageSearchPageHistoryForMakeUp(this.queryHistoryParames, '').then(result => {
        // this.chistoryRecordList = result.data.PageDataList;
        console.log(result.data.PageDataList, '获取历史记录');
        if (!this.queryHistoryParames.searchText) {
          this.chistoryRecordListTotal = result.data.Total;
        }
        if (result.data) {
          // 切换学生类型时 (首页)需清空数据
          if (this.queryHistoryParames.pageIndex == 0) {
            this.chistoryRecordList = [];
          }
          result.data.PageDataList.forEach(o => {
            let hasFind = false;
            if (this.chistoryRecordList.length > 0) {
              hasFind = this.chistoryRecordList.findIndex(p => p.guid == o.guid) >= 0;
            }
            if (!hasFind) {
              this.chistoryRecordList.push(o);
            }
          });
          if (result.data.PageDataList.length < this.queryHistoryParames.pageSize) {
            this.queryHistoryParames.noMore = true;
          }
        }
        this.queryHistoryParames.loading = false;
        this.queryHistoryParames.pageIndex++;
        if (typeof callBack == 'function') {
          callBack();
        }
      }, error => {
        layer.alert(error.msg);
        this.queryHistoryParames.loading = false;
      });
    },
    // 关闭历史记录弹窗
    closeHistoryRecord () {
      this.queryHistoryParames.searchText = '';
      this.isShowHistoryRecord = false;
    },
    // 提交
    submitClick (callBack) {
      let isAdd = this.$refs.tableBody.$refs.addRow.valifyIsAdding();
      if (isAdd) {
        layer.alert('请先保存或清空新增内容，再提交。');
        if (typeof callBack == 'function') { callBack(); }
        return;
      }
      // let exceptionList = this.tableData.filter(obj => { return obj.ExceptionList.length > 0; });
      // if (this.statisticsInfo.ExceptionNum > 0 || exceptionList.length > 0) {
      //   layer.alert('有异常未处理，请先将所有异常处理好在提交。');
      //   if (typeof callBack == 'function') { callBack(); }
      //   if (this.queryExceptionCatchParams.SpecialFilter != 1) {
      //     this.clickStatisticsNumber(1);// 切到异常录入选项卡
      //   }
      //   return;
      // }
      let isValifiStudentIndex = this.vTableData.findIndex(obj => { return obj.isValifiStudent == true; });
      if (isValifiStudentIndex >= 0) {
        layer.alert('操作太快，请缓一缓。');
        if (typeof callBack == 'function') { callBack(); }
        return;
      }
      if (this.statisticsInfo.TotalNum > 0) {
        let needSubmitData = this.needSaveTempRecord();
        this.showProgress = true;
        this.ProgressHintText = '提交中...';
        console.log('提交：needSubmitData', needSubmitData, this.deleteData);
        SubmitMakeUp(needSubmitData, this.deleteData).then(result => {
          console.log('提交成功后', result.data);
          this.showProgress = false;
          if (result.code == 0) {
            layer.alert('提交成功!');
            this.$bus.emit('AfterBatchAddOldStudent');
            if (this.timeoutObj) {
              clearTimeout(this.timeoutObj);
              this.timeoutObj = null;
            }
            setTimeout(() => {
              this.$emit('closeDialog');
            }, 200);
          }
        }, error => {
          this.showProgress = false;
          if (error.code == 10001) {
            this.statisticsInfo.DuplicationNum = error.data.sumamarize.DuplicationNum;
            this.statisticsInfo.ExceptionNum = error.data.sumamarize.ExceptionNum;
            this.statisticsInfo.TotalNum = error.data.sumamarize.TotalNum;
          }
          layer.alert(error.msg);
          if (typeof callBack == 'function') { callBack(); }
        });
      } else {
        if (typeof callBack == 'function') { callBack(); }
      }
    },
    // 提交前验证数据
    verifyneedSubmitData () {
      let flag = false;
      // 处于同名异常选项卡
      if (this.queryExceptionCatchParams.SpecialFilter == 2) {
        let exceptionList = this.parseTableData.filter(obj => { return obj.ExceptionList.length > 0; });
        if (exceptionList.length > 0) {
          layer.alert('有异常未处理，请先将所有异常处理好在提交。');
          flag = true;
        }
      } else {
        for (let index = 0; index < this.vTableData.length; index++) {
          let obj = this.tableData[index];
          if (obj.ExceptionList.length > 0) {
            obj.ExceptionList.sort((x, y) => { return x.TargetField < y.TargetField; });
            layer.alert('第' + obj.Number + '行，' + obj.ExceptionList[0].TypeKeyValue);
            flag = true;
            break;
          }
        }
      }
      return flag;
    },
    // 导入
    importEvent (param) {
      this.showProgress = true;
      this.ProgressHintText = '导入中...';
      const formData = new FormData();
      formData.append('file', param.file);
      this.saveOldStudentEnterTemp(() => {
        ImportMakeUp(formData).then(result => {
          this.statisticsInfo = result.data;
          if (this.queryExceptionCatchParams.SpecialFilter != 0) {
            this.queryExceptionCatchParams.SpecialFilter = 0;
            this.queryCacheParams.guid = '';
            this.tableData = [];
          }
          console.log('导入成功', result.data);
          this.loadMessageSearchPageCacheForMakeUp();
          layer.alert('导入成功');
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          this.showProgress = false;
          this.$refs.upload.clearFiles(); // 上传成功之后清除历史记录
        });
      });
    },
    // 重置清空
    resetClear () {
      layer.confirm('重置会清空所有数据，是否确定重置', {
        shadeClose: false,
        btn: [{
          name: '取消',
          callBack: () => {
            layer.close();
          }
        }, {
          name: '确认',
          callBack: () => {
            ClearInputStudent().then(result => {
              this.tableData = [];
              this.statisticsInfo = { TotalNum: 0, ExceptionNum: 0, DuplicationNum: 0, HistoryNum: this.statisticsInfo.HistoryNum };
              this.queryCacheParams.guid = '';
              this.queryExceptionCatchParams.SpecialFilter = 0;
              this.resetCatchData();
            });
            layer.close();
          }
        }]
      });
    },
    // 注册bus事件
    registerBusEvent () {
      // 修改学生档案的学生信息成功后触发回调
      this.$bus.on(this, 'AfterStudentUpdate', studentKey => {
        this.queryHistoryParames.pageIndex = 0;
        this.queryHistoryParames.noMore = false;
        this.getMessageSearchPageHistoryForMakeUp();
      });
      // 报名收款新增成功后的回调
      this.$bus.on(this, 'AfterSignUpPaymentSuccess', TableID => {
        this.queryHistoryParames.pageIndex = 0;
        this.queryHistoryParames.noMore = false;
        this.getMessageSearchPageHistoryForMakeUp();
      });
      // 修改课单内容成功后的回调;
      this.$bus.on(this, 'AfterEditCourseSingle', result => {
        this.queryHistoryParames.pageIndex = 0;
        this.queryHistoryParames.noMore = false;
        this.getMessageSearchPageHistoryForMakeUp();
      });
      this.$bus.on(this, 'wsQuitBatchAddOldStudent', data => {
        if (this.$store.getters.clientId != data.client_id) {
          document.activeElement.blur();
          this.saveOldStudentEnterTemp();
          layer.alert('您的小伙伴' + data.UserName + '正进行批量录入老生，您将退出当前功能',
            {
              shadeClose: false,
              btn: [
                {
                  name: '确认',
                  callBack: () => {
                    this.isShowHistoryRecord = false;
                    setTimeout(() => {
                      this.$emit('closeDialog');
                    }, 200);
                    layer.close();
                  }
                }
              ]
            }
          );
        }
      });
    },
    // 精简|全部 切换
    switchType () {
      this.$emit('switchModule');
    }
  },
  destroyed () {
    if (this.timeoutObj) {
      clearTimeout(this.timeoutObj);
      this.timeoutObj = null;
    }
  }
};
</script>

<style>
</style>