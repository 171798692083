<template>
  <div class="pr"
       @click="hideTips">
    <month-chart ref="monthChart"
                 :isBut="true"
                 :monthInfo="monthInfo"
                 @getMonthInfo="getMonthInfo"
                 @continuePress="continuePress"
                 @continueClick="continueClick"
                 @headerMenu="serachPopup"></month-chart>

    <div class="course_student_attendance_operation module_content_menuList"
         style="top: 38px;left: auto;right: -42px;"
         v-if="isShowAlerDialog">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="optionStatusList"
                     @clickOptionForAlertDialog="hideBox"></drop-down-box>
    </div>

    <div>
      <custom-dialog :title="typeName"
                     class="student_arrange_dialog defult_dialog no_hidden"
                     :visible.sync="isSearchPageBox"
                     :before-close="close">

        <single-selection-list :selectedKey="serachInfo.OLAPKey"
                               :dataSourceList="searchSingleArrangeList"
                               :required="true"
                               @onSelectedItem="doSelectedmonthSearchClick"></single-selection-list>

      </custom-dialog>
    </div>
  </div>

</template>

<script>
import monthChart from '../common/month-chart';
import dropDownBox from '../common/drop-down-box';
import {
  SearchStudentHasArrangeSupport,
  GetScheduleCourseListByClassType,
  kqDelete,
  courseAddStudent,
  getCourseTypeNameList,
  SearchClass,
  SelectTeach,
  SearchCourseForClass,
  GetScheduleCourseListByTeacher
} from '../../API/workbench';
export default {
  data () {
    return {
      selectTypeKey: 0, // 显示的高亮项ID
      monthInfo: [], // 月视图数据
      type: 0, // 搜索类型
      typeName: '',
      serachInfo: {}, // 搜索条件数据
      // 搜索条件ID
      serachInfoIDList: {
        classKey: '',
        courseKey: '',
        teacherKey: ''
      },
      searchSingleArrangeList: [],
      AgentKey: '', // 当前登录人ID
      isShowAlerDialog: false,
      isSearchPageBox: false
    };
  },
  props: {
    studentInfo: {
      type: Object,
      default: {}
    },
    selectClassInfo: {
      type: Object,
      default: {}
    },
    selectCourseInfo: {
      type: Object,
      default: {}
    },
    optionStatusList: Array
  },
  components: {
    monthChart,
    dropDownBox
    // searchBox
  },
  created () {
    this.AgentKey = this.$store.getters.token.UserID;
  },
  mounted () {
    console.log('selectClassInfo-课程-班级');
    this.loadData();// 初始化数据
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isShowAlerDialog = false;
    }, false);
  },
  watch: {
    'selectCourseInfo.OLAPKey': {
      handler (n, o) {
        console.log(n, 'selectClassInfo-课程');
        if (Number(n) > 0 && n != o) {
          this.serachInfoIDList.courseKey = n;
          this.loadData();
        }
      },
      deep: true
    },
    'selectClassInfo.OLAPKey': {
      handler (n, o) {
        console.log(n, 'selectClassInfo-班级');
        if (Number(n) > 0 && n != o) {
          this.serachInfoIDList.classKey = n;
          this.loadData();
        }
      },
      deep: true
    },
    'studentInfo.OLAPKey': {
      handler (n, o) {
        console.log(n, 'selectClassInfo-学生');
        if (Number(n) > 0 && n != o) {
          this.loadData();
        }
      },
      deep: true
    }
  },
  computed: {

  },
  methods: {
    loadData () {
      this.$store.commit('setShowTimetableId', 0);
      if (JSON.stringify(this.studentInfo) != '{}' && JSON.stringify(this.selectClassInfo) != '{}') { // 班级
        this.type = 5;
        this.serachInfoIDList.classKey = this.selectClassInfo.OLAPKey;
        this.serachInfoIDList.courseKey = '';
        this.serachInfoIDList.teacherKey = '';
      } else if (JSON.stringify(this.studentInfo) != '{}' && JSON.stringify(this.selectCourseInfo) != '{}') { // 课程
        this.type = 3;
        this.serachInfoIDList.courseKey = this.selectCourseInfo.OLAPKey;
        this.serachInfoIDList.classKey = '';
        this.serachInfoIDList.teacherKey = '';
      }
      this.getMonthInfo();
    },
    continuePress () {
      // 长按事件
    },
    serachPopup () {
      this.isShowAlerDialog = true;
    },
    hideBox (menu) {
      console.log(menu, 'menu');
      switch (menu.eventName) {
        case 'clear':
          this.restoreSearch();
          break;
        case 'teacher':
          this.typeName = '选择老师';
          this.type = 1;
          this.getOptionList(this.type);
          break;
        case 'course':
          this.typeName = '选择课程';
          this.type = 3;
          this.getOptionList(this.type);
          break;
        case 'class':
          this.typeName = '选择班级';
          this.type = 5;
          this.getOptionList(this.type);
          break;

        default:
          break;
      }
      this.isShowAlerDialog = false;
    },
    getOptionList (type) {
      console.log(type);
      this.searchSingleArrangeList = [];
      switch (String(this.type)) {
        case '1':
          SelectTeach().then(res => {
            if (res.code == 0) {
              this.searchSingleArrangeList = res.data;
              this.isSearchPageBox = true;
            }
          }, err => {
            layer.alert(err.msg);
          });
          break;
        case '3':
          getCourseTypeNameList().then(result => {
            this.courseNameList = [];
            result.data.forEach(o => {
              if (o.TypeKey == 2 && o.StateKey == 2) {
                this.searchSingleArrangeList.push(o);
              }
            });
            this.isSearchPageBox = true;
          }, error => {
            layer.alert(error.msg);
          });
          break;
        case '5':
          SearchClass().then(res => {
            if (res.code == 0) {
              this.searchSingleArrangeList = res.data;
              this.isSearchPageBox = true;
            }
          }, err => {
            layer.alert(err.msg);
          }
          );
          break;
        default:
          break;
      }
    },
    close () {
      this.isSearchPageBox = false;
    },
    // 切换筛选条件查询
    doSelectedmonthSearchClick (item) {
      console.log(this.type, item, '切换筛选条件查询');
      if (this.type && item) {
        this.isShowAlerDialog = false;
        this.serachInfoIDList.teacherKey = '';
        this.serachInfoIDList.courseKey = JSON.stringify(this.selectCourseInfo) != '{}' ? this.selectCourseInfo.OLAPKey : '';
        this.serachInfoIDList.classKey = JSON.stringify(this.selectClassInfo) != '{}' ? this.selectClassInfo.OLAPKey : '';
        switch (this.type) {
          case 1:// 老师
            this.serachInfoIDList.teacherKey = item.OLAPKey;
            break;
          case 3:// 课程
            this.serachInfoIDList.courseKey = item.OLAPKey;
            break;
          case 5:// 班级
            this.serachInfoIDList.classKey = item.OLAPKey;
            break;
          default:
            break;
        }
        this.serachInfo = item;
        this.getMonthInfo();// 初始化数据
      }
    },
    restoreSearch () {
      console.log('清除');
      this.serachInfo = {};
      this.serachInfoIDList.teacherKey = '';
      this.serachInfoIDList.courseKey = JSON.stringify(this.selectCourseInfo) != '{}' ? this.selectCourseInfo.OLAPKey : '';
      this.serachInfoIDList.classKey = JSON.stringify(this.selectClassInfo) != '{}' ? this.selectClassInfo.OLAPKey : '';
      this.loadData();
      this.getMonthInfo();
    },
    // 长按查看该课块
    hideTips () {
      console.log('长按查看该课块');
      this.isSearchPageBox = false;
    },
    getStudentHasArrangeSupport (firstDate, lastDate, courseID, classID, data) {
      SearchStudentHasArrangeSupport(this.studentInfo.OLAPKey, courseID, firstDate, lastDate, classID).then(res2 => {
        this.monthInfo = [];
        data.forEach(e => {
          res2.data.forEach(o => {
            if (e.OLAPKey == o.CourseId) {
              e.alreadyJoin = true;
            }
          });
          if (e.StatusKey == 2 || e.StatusKey == 3) { // 要求只显示 进行中和未开始的课，已完结和已停的课不显示(2-未开始;3-进行中;4-已完结;5-已中止;6-已取消)
            this.monthInfo.push(e);
          }
        });
        console.log(res2.data, 'SearchStudentHasArrangeSupport');
        console.log(data, '学生已排课查询1');
        console.log(this.monthInfo, '查询数据2');
      }, err2 => {
        layer.alert(err2.msg);
      });
    },
    // 获取指名课名的当月课程
    getMonthInfo () {
      let firstDate = this.$refs.monthChart.vAllWeek[0][0].date;
      let lastDate = this.$refs.monthChart.vAllWeek[this.$refs.monthChart.vAllWeek.length - 1][this.$refs.monthChart.vAllWeek[this.$refs.monthChart.vAllWeek.length - 1].length - 1].date;

      if (firstDate && firstDate.indexOf('010') > -1) {
        firstDate = firstDate.replace(/\-010-/g, '-10-'); // 开始日期
      }
      if (lastDate && lastDate.indexOf('010') > -1) {
        lastDate = lastDate.replace(/\-010-/g, '-10-'); // 开始日期
        // console.log(lastDate);
      }
      if (this.type == 1) {
        // 老师
        this.getTeacherCourseList(firstDate, lastDate);
      } else if (this.type == 5) {
        // 班级
        this.getClassCourseList(firstDate, lastDate);
      } else if (this.type == 3) {
        // 课程
        this.getCourseList(firstDate, lastDate);
      }
      console.log(this.type, '类型');
      this.isSearchPageBox = false;
    },
    // 获取老师下的课
    getTeacherCourseList (firstDate, lastDate) {
      let obj = {
        startTime: firstDate,
        endTime: lastDate,
        tescherKey: this.serachInfoIDList.teacherKey
      };
      GetScheduleCourseListByTeacher(obj).then(res => {
        if (res.code == 0) {
          let classCourseList = [];
          // 根据班级搜索该班级下的老师
          if (JSON.stringify(this.selectClassInfo) != '{}') {
            let obj = {
              startTime: firstDate,
              endTime: lastDate,
              ClassId: this.serachInfoIDList.classKey
            };
            SearchCourseForClass(obj).then(resA => {
              res.data.forEach(o => {
                let findItem = resA.data.find(obj => { return obj.OLAPKey == o.OLAPKey; });
                if (findItem) {
                  classCourseList.push(findItem);
                }
              });
              this.getStudentHasArrangeSupport(firstDate, lastDate, this.serachInfoIDList.courseKey, this.serachInfoIDList.classKey, classCourseList);
              console.log(classCourseList, '班级=>老师=List');
            });
          }
          // 根据课程搜索该班级下的老师
          if (JSON.stringify(this.selectCourseInfo) != '{}') {
            let obj = {
              startTime: firstDate,
              endTime: lastDate,
              classTypeKey: this.serachInfoIDList.courseKey
            };
            GetScheduleCourseListByClassType(obj).then(resB => {
              res.data.forEach(o => {
                let findItem = resB.data.find(obj => { return obj.OLAPKey == o.OLAPKey; });
                if (findItem) {
                  classCourseList.push(findItem);
                }
              });
              this.getStudentHasArrangeSupport(firstDate, lastDate, this.serachInfoIDList.courseKey, this.serachInfoIDList.classKey, classCourseList);
              console.log(classCourseList, '课程=>老师=List');
            });
          }
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 获取班级下的课
    getClassCourseList (firstDate, lastDate) {
      let CourseObj = {
        startTime: firstDate,
        endTime: lastDate,
        ClassId: this.serachInfoIDList.classKey,
        IsExportKey: 0
      };
      console.log(firstDate, lastDate, 'getClassCourseList', this.serachInfoIDList);
      SearchCourseForClass(CourseObj).then(res => {
        console.log(res.data, '班级List');
        if (res.code == 0) {
          console.log(this.selectCourseInfo, '课2');
          if (JSON.stringify(this.selectCourseInfo) == '{}') {
            this.getStudentHasArrangeSupport(firstDate, lastDate, this.serachInfoIDList.courseKey, this.serachInfoIDList.classKey, res.data);
          } else {
            // 选择课程时获取该班级下的的课
            let obj = {
              startTime: firstDate,
              endTime: lastDate,
              classTypeKey: this.serachInfoIDList.courseKey
            };
            GetScheduleCourseListByClassType(obj).then(resA => {
              if (resA.code == 0) {
                let classCourseList = [];
                resA.data.forEach(o => {
                  let findItem = res.data.find(obj => { return obj.OLAPKey == o.OLAPKey; });
                  if (findItem) {
                    classCourseList.push(findItem);
                  }
                });
                // console.log(resA.data, '课程List');
                console.log(classCourseList, '课程=>班级=List');
                this.getStudentHasArrangeSupport(firstDate, lastDate, this.serachInfoIDList.courseKey, this.serachInfoIDList.classKey, classCourseList);
              }
            });
          }
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 获取课程
    getCourseList (firstDate, lastDate) {
      let obj = {
        startTime: firstDate,
        endTime: lastDate,
        classTypeKey: this.serachInfoIDList.courseKey
      };
      GetScheduleCourseListByClassType(obj).then(res => {
        if (res.code == 0) {
          if (JSON.stringify(this.selectClassInfo) == '{}') {
            // console.log(res.data, '课程List');
            this.getStudentHasArrangeSupport(firstDate, lastDate, this.serachInfoIDList.courseKey, this.serachInfoIDList.classKey, res.data);
          } else {
            // 选择班级时获取该课程下的的课
            SearchCourseForClass(firstDate, lastDate, this.serachInfoIDList.classKey).then(resB => {
              if (resB.code == 0) {
                let classCourseList = [];
                // console.log(resB.data, '班级List');
                resB.data.forEach(o => {
                  let findItem = res.data.find(obj => { return obj.OLAPKey == o.OLAPKey; });
                  if (findItem) {
                    classCourseList.push(findItem);
                  }
                });
                console.log(classCourseList, '班级=>课程=List');
                this.getStudentHasArrangeSupport(firstDate, lastDate, this.serachInfoIDList.courseKey, this.serachInfoIDList.classKey, classCourseList);
              }
              console.log(resB.data, '班级List');
            });
          }
          console.log(res.data, '课程List');
        }
      }, err => {
        layer.alert(err.msg);
      }
      );
    },
    continueClick (item) {
      if (this.studentInfo.IsConfirmKey == 0) {
        layer.alert('该学生未验证，请先前往学生档案进行验证');
        return;
      }
      console.log('该学生未验证，请先前往学生档案进行验证');
      if (item.alreadyJoin && this.studentInfo.OLAPKey > 0 && item.OLAPKey > 0) { // 取消课成功
        kqDelete(this,this.studentInfo.OLAPKey, item.OLAPKey).then(res => {
          if (res.code == 0) {
            this.getMonthInfo();
            this.$bus.emit('afterArrangeScheduleCourse', this.studentInfo.OLAPKey);
            this.$emit('upLoadArrangeStatisticsInfo');// 刷新统计数据
          }
        }, err => {
          layer.alert(err.msg);
        }
        );
      } else { // 排课成功
        courseAddStudent(item.ClassTime, item.OLAPKey, this.studentInfo.OLAPKey, this.AgentKey, 1, 0).then(
          res => {
            if (res.code == 0) {
              this.getMonthInfo();
              this.$bus.emit('afterArrangeScheduleCourse', this.studentInfo.OLAPKey);
              this.$emit('upLoadArrangeStatisticsInfo');// 刷新统计数据
            }
          }, err => {
            layer.alert(err.msg);
          });
      }
    }
  }

};
</script>

<style>
</style>