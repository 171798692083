<template>
  <div class="swiper-slide courseware_packge_list_item"
       @click.stop="seletedItem">
    <div class="courseware_packge_list_item_bg"></div>
    <img :src="vImg"
         alt="">
    <div class="courseware_packge_list_item_bottom">
      {{item.MainDemoName}}
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },

  computed: {
    vImg () {
      if (this.item.CoverUrl) {
        if (this.item.CoverUrl.indexOf('http') > -1) {
          return this.item.CoverUrl;
        } else {
          return this.$store.getters.CDNURL + this.item.CoverUrl;
        }
      } else {
        return '';
      }
    }
  },
  methods: {
    seletedItem () {
      if (this.item.ISAuthExpireKey == 1) {
        layer.alert('课件端口过期 ，无法加载课件，请联系课件授权方!', {
          mode: 'teachingMode'
        });
        return;
      }
      this.$emit('seletedItem', this.item);
    }
  }
};
</script>
<style scoped>
.courseware_packge_list_item {
  width: 156.25rem !important;
  height: 117.19rem;
  margin-right: 12.5rem !important;
  position: relative;
  border: 0.78rem solid rgba(255, 255, 255, 0.4);
  /*0.78rem solid rgba(255, 255, 255, 0.4); */
  border-radius: 7.03rem;
  overflow: hidden;
  display: inline-block;
  background: #a9a9a9;
}
.courseware_packge_list_item:hover {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #2afea9 0.01%, #1c4ef4 102.48%);
  border: 1.17rem solid #ffffff;
  box-shadow: 0px 1.95rem 7.8rem rgba(22, 131, 249, 0.7);
  border-radius: 7.03rem;
  cursor: pointer;
}
.courseware_packge_list_item .courseware_packge_list_item_bg {
  background: url('../../../../public/image/coureware_bg_highlight.png') center
    no-repeat;
  background-size: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
}
.courseware_packge_list_item img {
  width: 100%;
  /* height: 87%; */
  /* background: linear-gradient(225.87deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 90.8%);
        opacity: 0.35; */
}
.courseware_packge_list_item_bottom {
  width: 100%;
  height: 31.6rem;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1.17rem 2.34rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(7.5px);
  border-radius: 4.68rem;
  position: absolute;
  bottom: 0px;
  font-weight: 400;
  font-size: 9.36rem;
  line-height: 31.6rem;
  color: #ffffff;
  /* text-align: center; */
  padding-left: 8.85rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>