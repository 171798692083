<template>
  <!-- 其他信息 -->
  <div>
    <div class="form_header_gray">其他信息</div>
    <div class="form_info_box bg_white">
      <input-date :formTitle="'出生日期'"
                 :endDate="$utils.formatDateToLine(Date.new())"
                 :formPlaceholder="'请选择'"
                 @doBindDateChange="chgBirthdayTime"
                 v-model="customerInfo.BirthdayTime"></input-date>

      <input-number :formTitle="'年龄'"
                   :min="0"
                   :decimalPlaces="0"
                   :formPlaceholder="'请输入'"
                   :readonly="isReadonly"
                   v-model="customerInfo.AgeName"
                   @inputChange="chgAgeName"></input-number>

      <input-dialog :formTitle="'住宅小区'"
                   :formPlaceholder="'请选择'"
                   :readonly="isReadonly"
                   v-model="customerInfo.VillageKeyValue"
                   @showEldilog="openVillageKeyValue()"></input-dialog>

      <input-text :formTitle="'就读学校'"
                 :maxLength="14"
                 :formPlaceholder="'请输入'"
                 :readonly="isReadonly"
                 v-model="customerInfo.SchoolName"></input-text>

      <input-text class="no_border_bottom"
                 :formTitle="'备注事项'"
                 :maxLength="14"
                 :formPlaceholder="'请输入'"
                 :readonly="isReadonly"
                 v-model="customerInfo.RemarksSupport"></input-text>
    </div>
  </div>
</template>

<script>
import { getLivingCommunity } from '../../../API/workbench';
export default {
  data () {
    return {
      addressList: []
    };
  },
  props: {
    customerInfo: Object,
    isReadonly: Boolean
  },
  computed: {

  },
  created () {

  },
  methods: {
    // 选择出生日期
    chgBirthdayTime (date) {
      this.customerInfo.BirthdayTime = date;
    },
    // 选择年龄
    chgAgeName (val) {
      if (Number(val) <= 0) {
        val = 0;
      }
      this.customerInfo.AgeName = this.$utils.mMoneyType(val, 0);
    },
    // 打开住宅小区列表
    openVillageKeyValue () {
      getLivingCommunity().then(result => {
        this.addressList = result.data;
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择小区',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.customerInfo.VillageKey, dataSourceList: this.addressList, required: false, addModuleName: 'community-setting', ModulePowerKey: 45 },
          callBack: {
            onSelectedItem: (data) => {
              this.receiveAddress(data);
            }
          }
        });
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 选择住宅小区
    receiveAddress (item) {
      this.customerInfo.VillageKey = item.OLAPKey;
      this.customerInfo.VillageKeyValue = item.MainDemoName;
    }
  }

};
</script>

<style>
</style>
