<template>
  <!-- 已下架的优惠券 -->
  <div>
    <coupon-content-box :dataInfo="dataInfo"></coupon-content-box>
    <div class="list_bottom">
      <coupon-number-box :dataInfo="dataInfo"
                         @doAlreadyReceivedClick="doAlreadyReceivedClick"
                         @doHaveBeenUsedClick="doHaveBeenUsedClick"></coupon-number-box>
    </div>
  </div>
</template>
<script>

import couponContentBox from '../common/coupon-content-box';
import couponNumberBox from '../common/coupon-number-box';

export default {
  components: { couponContentBox, couponNumberBox },
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object // 券信息
  },
  created () {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    doAlreadyReceivedClick (data) {
      this.$emit('doAlreadyReceivedClick', data);
    },
    doHaveBeenUsedClick (data) {
      this.$emit('doHaveBeenUsedClick', data);
    }
  }
};
</script>

