<template>
  <div>
    <div class="form_info_edit form_info_list">
      <input-text :formTitle="'名称'"
                  :required="true"
                  :readonly="false"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.MainDemoName"></input-text>

      <input-number :min="0"
                    :formTitle="'数量'"
                    :required="true"
                    :readonly="false"
                    :formPlaceholder="'请输入'"
                    v-model="dataInfo.SizeNum"></input-number>
      <input-select :formTitle="'单位'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :readonly="isReadonly"
                    :dataList="UnitKeyType"
                    :selectedInfo="vUnitKeyTypeInfo"
                    @selectedItem="changeUnitKeyType"></input-select>
      <input-select :formTitle="'适用门店'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :readonly="isReadonly"
                    :dataList="UseClientKeyType"
                    :selectedInfo="vUseClientKeyTypeInfo"
                    @selectedItem="changeUseClientKeyType"></input-select>
      <input-select :formTitle="'适用总部'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :readonly="isReadonly"
                    :dataList="UseHQKeyType"
                    :selectedInfo="vUseHQKeyTypeInfo"
                    @selectedItem="changeUseHQKeyType"></input-select>
      <input-select :formTitle="'是否课件功能赠送'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :readonly="isReadonly"
                    :dataList="AdminGiveKeyType"
                    :selectedInfo="vAdminGiveKeyTypeInfo"
                    @selectedItem="changeAdminGiveKeyType"></input-select>
      <input-number :min="0"
                    :formTitle="'原价'"
                    :required="true"
                    :readonly="false"
                    :formPlaceholder="'请输入'"
                    v-model="dataInfo.PrimeAmount"></input-number>
      <input-number :min="0"
                    :formTitle="'售价'"
                    :required="true"
                    :readonly="false"
                    :formPlaceholder="'请输入'"
                    v-model="dataInfo.PriceAmount"></input-number>
      <input-text :formTitle="'备注'"
                  :readonly="false"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.Remark"></input-text>
    </div>
    <div v-if="!isReadonly">
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { EditFluxComboInfo, GetFluxComboInfo } from '../../../API/workbench';
export default {
  data () {
    return {
      // 选择单位
      UnitKeyType: [
        { type: 1, name: 'G' },
        { type: 2, name: 'T' }
      ],
      // 选择适用门店
      UseClientKeyType: [
        { type: 0, name: '否' },
        { type: 1, name: '是' }
      ],
      // 选择适用总部
      UseHQKeyType: [
        { type: 0, name: '否' },
        { type: 1, name: '是' }
      ],
      // 选择课件管理赠送
      AdminGiveKeyType: [
        { type: 0, name: '否' },
        { type: 1, name: '是' }
      ],
      dataInfo: {
        IsGiveKey: '', // 是否赠予 0-否;1-是
        IsGiveKeyValue: '', // 是否管理赠送Value
        IsUseClientKey: '', // 是否适用门店
        IsUseClientKeyValue: '', // 是否适用门店Value
        IsUseHQKey: '', // 是否适用总部
        IsUseHQKeyValue: '', // 是否适用总部Value
        MainDemoName: '', // 名称
        PriceAmount: '', // 售价
        PrimeAmount: '', // 原价
        Remark: '', // 备注
        SizeNum: '', // 数量
        UnitKey: '', // 单位 1-G 2-T
        UnitKeyValue: '' // 单位 1-G 2-T Value
      }
    };
  },
  components: {

  },
  props: {
    CampusKey: {
      type: Number,
      default: 0
    },
    isReadonly: Boolean
  },
  created () {
    this.getDetailInfo();
  
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
  },
  computed: {
    // 选择单位
    vUnitKeyTypeInfo () {
      return {
        type: this.dataInfo.UnitKey,
        name: this.dataInfo.UnitKeyValue
      };
    },
    // 适用门店
    vUseClientKeyTypeInfo () {
      return {
        type: this.dataInfo.IsUseClientKey,
        name: this.dataInfo.IsUseClientKeyValue
      };
    },
    // 适用总部
    vUseHQKeyTypeInfo () {
      return {
        type: this.dataInfo.IsUseHQKey,
        name: this.dataInfo.IsUseHQKeyValue
      };
    },
    // 适用流量管理赠送
    vAdminGiveKeyTypeInfo () {
      return {
        type: this.dataInfo.IsGiveKey,
        name: this.dataInfo.IsGiveKeyValue
      };
    }

  },
  methods: {
    // 选择单位
    changeUnitKeyType (obj) {
      console.log(obj, '选择来源方式');
      this.dataInfo.UnitKey = obj.type;
      this.dataInfo.UnitKeyValue = obj.name;
      console.log(this.dataInfo.UnitKeyValue, 'this.dataInfo');
    },
    // 选择适用门店
    changeUseClientKeyType (obj) {
      console.log(obj, '选择来源方式');
      this.dataInfo.IsUseClientKey = obj.type;
      this.dataInfo.IsUseClientKeyValue = obj.name;
      console.log(this.dataInfo.IsUseClientKeyValue, 'this.dataInfo');
    },
    // 选择适用总部
    changeUseHQKeyType (obj) {
      console.log(obj, '选择来源方式');
      this.dataInfo.IsUseHQKey = obj.type;
      this.dataInfo.IsUseHQKeyValue = obj.name;
      console.log(this.dataInfo.IsUseHQKeyValue, 'this.dataInfo');
    },
    // 适用流量管理赠送
    changeAdminGiveKeyType (obj) {
      console.log(obj, '选择来源方式');
      this.dataInfo.IsGiveKey = obj.type;
      this.dataInfo.IsGiveKeyValue = obj.name;
      console.log(this.dataInfo.IsGiveKeyValue, 'this.dataInfo');
    },
    initAddData () {
      console.log(this.$store.getters.token, 'initAddData');
    },
    // 获取校区详情信息
    getDetailInfo () {
      GetFluxComboInfo(this.CampusKey).then(result => {
        console.log('获取校区详情信息', result.data);
        Object.assign(this.dataInfo, result.data);
        this.dataInfo = result.data;
        this.dataInfo.IsUseClientKeyValue = result.data.IsUseClientKey == 0 ? '否' : '是';
        this.dataInfo.IsUseHQKeyValue = result.data.IsUseHQKey == 0 ? '否' : '是';
        this.dataInfo.IsGiveKeyValue = result.data.IsGiveKey == 0 ? '否' : '是';
        this.dataInfo.UnitKeyValue = result.data.UnitKey == 1 ? 'G' : 'T';
        console.log('dataInfo111', this.dataInfo);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 提交
    submit (callback) {
      console.log('submit', this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      EditFluxComboInfo(this.CampusKey, this.dataInfo).then(result => {
        layer.alert('修改成功');
        this.$emit('afterSuccess');
        this.cancel();
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callback) {
          callback();
        }
      });
    },
    // 验证客户信息
    verificationInfo () {
      // if (this.dataInfo.CampusName) {
      //   this.dataInfo.CampusName = this.dataInfo.CampusName.replace(/(^\s*)|(\s*$)/g, '');
      // }
      let flag = true;
      if (!this.dataInfo.MainDemoName) {
        layer.alert('请输入名称');
        flag = false;
      } else if (!this.dataInfo.SizeNum) {
        layer.alert('请输入数量');
        flag = false;
      } else if (!this.dataInfo.UnitKey) {
        layer.alert('请输入单位');
        flag = false;
      } else if (!this.dataInfo.PriceAmount) {
        layer.alert('请输入售价');
        flag = false;
      } else if (!this.dataInfo.PrimeAmount) {
        layer.alert('请输入原价');
        flag = false;
      }
      return flag;
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
