var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-crop",staticStyle:{"display":"block"}},[_c('div',{staticClass:"c-crop--main",style:({height:_vm.height+'px'})},[_c('div',{staticClass:"c-crop--area",style:({
                width:_vm.imgW+'px',
                height:_vm.imgH+'px',
                'margin-top':(_vm.height-_vm.imgH)/2+'px'
            })},[(_vm.url)?_c('img',{attrs:{"src":_vm.url,"alt":"原始图片","width":"100%"}}):_vm._e(),(_vm.url)?_c('div',{staticClass:"c-crop--cut",style:({
                  width:_vm.elWidth+'px',
                  height:_vm.elHeight+'px',
                  left:_vm.cursorLeft+'px',
                  top:_vm.cursorTop+'px'
              }),on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{staticClass:"c-crop--cut_img",style:({
                      width:_vm.imgW+'px',
                      height:_vm.imgH+'px',
                      left:-_vm.cursorLeft+'px',
                      top:-_vm.cursorTop+'px'
                  }),attrs:{"src":_vm.url,"alt":"裁切后图片"}}),_c('VueCropTool',{attrs:{"crop-json":_vm.cropJson,"el-width":_vm.elWidth,"el-height":_vm.elHeight,"cursor-top":_vm.cursorTop,"cursor-left":_vm.cursorLeft},on:{"updateSize":_vm.drapSizeUpdate,"afterCrop":_vm.afterCrop}})],1):_vm._e()])]),(_vm.previewJson.length)?_c('div',{staticClass:"c-crop--preview"},_vm._l((_vm.previewJson),function(item,index){return _c('div',{key:index,staticClass:"c-crop--preview_item",class:{'c-crop--radius':item.radius},style:({height:item.height+'px',width:item.width+'px'})},[(_vm.url&&_vm.previewImgSize&&_vm.previewImgSize.length)?_c('img',{style:({
              width:_vm.previewImgSize[index].w+'px',
              height:_vm.previewImgSize[index].h+'px',
              left:_vm.previewImgSize[index].l+'px',
              top:_vm.previewImgSize[index].t+'px'
          }),attrs:{"src":_vm.url,"alt":""}}):_vm._e()])}),0):_vm._e(),_c('div',{staticClass:"c-crop--hide_main"},[_c('img',{attrs:{"id":"c-crop--hide_img","src":_vm.url,"alt":""}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }