<template>
  <div class="add_student"
       @click.stop="showDialog">
    <div class="add_student_left">
      <img src="../../../../public/image/plus.png"
           alt="">
    </div>
    <div class="add_student_right">
      <span>添加学生
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'addStudentBtn',
  data () {
    return {
    };
  },
  components: {
  },
  methods: {
    showDialog () {
      this.$emit('addStudentBtnClick')
    }
  }
};
</script>

<style>
.el-select .el-input--suffix {
  border: none;
  background-color: transparent;
  color: #49b5f3;
}
</style>
