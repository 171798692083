<template>
  <el-container class="page">
    <el-main class="page_content">
      <div class="attendance_statistics clearfix">
        <div class="apply_payment_details fl">
          <dayAtten :formTitle="'今日考勤'"
                    :dataInfo="attenDatail.Attendance_Today"
                    @showDetail="showDetail(attenDatail.Attendance_Today)"></dayAtten>
          <dayAtten :formTitle="'昨日考勤'"
                    :dataInfo="attenDatail.Attendance_Yesterday"
                    @showDetail="showDetail(attenDatail.Attendance_Yesterday)"></dayAtten>
          <div class=" massive_bg apply_payment_details_box">
            <div class="apply_payment_box_title">
              未考勤
            </div>
            <div class="apply_payment_box_half clearfix">
              <div class="box_half fl">
                <div class="font_big">{{attenDatail.Attendance2.Attence2?attenDatail.Attendance2.Attence2:0}}</div>
                <div>人次</div>
              </div>
              <div class="box_half fl">
                <div class="font_big">{{attenDatail.Attendance2.HavedClass?attenDatail.Attendance2.HavedClass:0}}</div>
                <div>节课</div>
              </div>
            </div>
            <a class="class_statistics_details_btn"
               @click.stop="showDetail('未考勤')">
              查看详情
            </a>
          </div>
        </div>
        <div class="apply_payment_echarts fl">
          <day-statistics-report @showDetail="showDetail">
          </day-statistics-report>
          <monthStatisticsReport @showDetail="showDetail"></monthStatisticsReport>
        </div>
      </div>
    </el-main>
    <!-- 弹窗区域 -->
    <list ref="attentionGrade"
          class="table_button_arrow_small_right"
          :listTitle="'学生考勤统计列表'"
          :extendSearchCondition="vextendSearchCondition"
          :tableData="attentionGradeList"
          :totalNum="totalNum"
          :PageCount="PageCount"
          :tableColumns="tableColumns"
          :rowKey="rowKey"
          :detailColumns="detailColumns"
          :expandRowData="[]"
          @loadTableData="loadTableData"></list>
  </el-container>
</template>
<script>
import dayAtten from './day-for-atten';
import dayStatisticsReport from './day-statistics-report';
import monthStatisticsReport from './month-statistics-report';
import list from '../../list/index';
import { getKQStatistics, MessageSearchPageForStudentArrange } from '../../../API/workbench';
export default {
  name: 'attentionGrade',
  data () {
    return {
      attenDatail: {
        'Attendance_Today': {},     // 今天
        'Attendance_Yesterday': {},   // 昨天
        'Attendance2': {}
      },
      totalNum: 0,
      attentionGradeList: [],
      PageCount: 0,
      rowKey: 'Number',
      tableColumns: [ // table 列
        {
          label: '上课日期',
          prop: 'ClassTime',
          width: 140,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },

        {
          label: '上课时间',
          prop: 'BeginClassTime',
          width: 150,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '课程安排',
          prop: 'CourseNameKeyValue',
          width: 150,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 120,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '考勤课消结果',
          prop: 'AttenceStatusKeyValue',
          width: 140,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '打考勤人',
          prop: 'AttenceOpratorKeyValue',
          width: 120,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '打考勤时间',
          prop: 'AttenceTime',
          width: 120,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '操作',
          isShow: true,
          type: 'table-btn',
          prop: 'option',
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                type: 'expand',
                extend: {
                  click: (rowData) => { },
                  expandType: 'form',
                  tableColumnsDetail: [{ label: '上课日期', prop: 'ClassTime', type: 'text-item' },
                  { label: '课名', prop: 'CourseNameKeyValue', type: 'text-item' },
                  { label: '学生名', prop: 'StudentKeyValue', type: 'text-item' },
                  { label: '考勤课消结果', prop: 'AttenceStatusKeyValue', type: 'text-item' },
                  { label: '欠课时数', prop: 'ClassCount', type: 'text-item' },
                  { label: '上课时间', prop: 'BeginClassTime', type: 'text-item' },
                  { label: '主讲老师', prop: 'MTeacherKeyValue', type: 'text-item' },
                  { label: '关系', prop: 'CustomerRelationshipKeyValueName', type: 'text-item' },
                  { label: '课单号', prop: 'xykdKeyValue', type: 'text-item' },
                  { label: '打考勤人', prop: 'AttenceOpratorKeyValue', type: 'text-item' },
                  { label: '班名', prop: 'GradeClassKeyValue', type: 'text-item' },
                  { label: '教室', prop: 'classRoomName', isShowTitle: true, type: 'text-item' },
                  { label: '手机', prop: 'CustomerPhoneName', type: 'text-item' },
                  { label: '剩余课时', prop: 'CurrentCount', type: 'text-item' },
                  { label: '打考勤时间', prop: 'AttenceTime', type: 'text-item' }]
                }
              }
            ]
          }
        }
      ],
      extendSearchCondition: [// 扩展查询条件
        {
          type: this.$initJson.extendSearchConditionType.dateRange, // 日期范围
          formTitle: '上课日期',
          fieldList: [
            {
              name: 'startTime', // 参数名
              defaultValue: '', // 默认值
              value: '', // 绑定值
              validateHandle: '', // 自定义验证函数
              sourceData: []// 数据源
            },
            {
              name: 'endTime',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: []
            }
          ]
        }
      ],
      detailColumns: [
        { label: '上课日期', prop: 'ClassTime' },
        { label: '课名', prop: 'CourseNameKeyValue' },
        { label: '学生名', prop: 'StudentKeyValue' },
        { label: '考勤课消结果', prop: 'AttenceStatusKeyValue' },
        { label: '欠课时数', prop: 'ClassCount' },
        { label: '上课时间', prop: 'BeginClassTime' },
        { label: '主讲老师', prop: 'MTeacherKeyValue' },
        { label: '关系', prop: 'CustomerRelationshipKeyValueName' },
        { label: '课单号', prop: 'xykdKeyValue' },
        { label: '打考勤人', prop: 'AttenceOpratorKeyValue' },
        { label: '班名', prop: 'GradeClassKeyValue' },
        { label: '教室', prop: 'classRoomName', isShowTitle: true },
        { label: '手机', prop: 'CustomerPhoneName' },
        { label: '剩余课时', prop: 'CurrentCount' },
        { label: '打考勤时间', prop: 'AttenceTime' }
      ]

    };
  },
  components: {
    dayAtten, dayStatisticsReport, monthStatisticsReport, list
  },
  computed: {
    vextendSearchCondition () {
      let list = this.$utils.parseJson(this.extendSearchCondition);
      if (this.$utils.getSaaSClientSet(59) > 0) {
        list.push({
          type: this.$initJson.extendSearchConditionType.selectOption,
          formTitle: '考勤状态',
          fieldList: [
            {
              name: 'stutasKey',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: [{ key: 2, value: '未考勤' }, { key: 3, value: '签到' }, { key: 4, value: '请假' }, { key: 5, value: '旷课' }]
            }
          ]
        });
      } else {
        list.push({
          type: this.$initJson.extendSearchConditionType.selectOption,
          formTitle: '考勤状态',
          fieldList: [
            {
              name: 'stutasKey',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: [{ key: 2, value: '未考勤' }, { key: 3, value: '签到' }, { key: 4, value: '请假' }]
            }
          ]
        });
      }
      return list;
    }
  },
  mounted () {
    this.getKQGrade();
  },
  methods: {
    getKQGrade () {
      getKQStatistics().then(result => {
        result.data.Attendance_Today = result.data.Attendance_Today || {};
        result.data.Attendance_Yesterday = result.data.Attendance_Yesterday || {};
        result.data.Attendance2 = result.data.Attendance2 || {};
        this.attenDatail = result.data;
      }, r => {
        console.log(r);
      });
    },
    showDetail (type, startTime, endTime) {
      let initSelectedParams = [];
      if (startTime && endTime) {
        startTime = startTime.split('/')[0] + '-' + startTime.split('/')[1] + '-' + startTime.split('/')[2];
        endTime = endTime.split('/')[0] + '-' + endTime.split('/')[1] + '-' + endTime.split('/')[2];
      }
      switch (type) {
        case this.attenDatail.Attendance_Today:
          console.log('this.attenDatail.Attendance_Today.SetUpTime', this.attenDatail.Attendance_Today);
          initSelectedParams.push({ name: 'startTime', value: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd') }, { name: 'endTime', value: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd') }, { name: 'stutasKey', value: 3 });
          break;
        case this.attenDatail.Attendance_Yesterday:
          initSelectedParams.push({ name: 'startTime', value: this.$utils.formatDatet(Date.new(Date.new().setDate(Date.new().getDate() - 1)), 'yyyy-MM-dd') }, { name: 'endTime', value: this.$utils.formatDatet(Date.new(Date.new().setDate(Date.new().getDate() - 1)), 'yyyy-MM-dd') }, { name: 'stutasKey', value: 3 });
          break;
        case '未考勤':
          initSelectedParams.push({ name: 'stutasKey', value: 2 });
          break;
        case '考勤统计日报':
          initSelectedParams.push({ name: 'startTime', value: startTime }, { name: 'endTime', value: endTime }, { name: 'stutasKey', value: 3 });
          break;
        case '考勤统计月报':
          initSelectedParams.push({ name: 'startTime', value: startTime }, { name: 'endTime', value: endTime }, { name: 'stutasKey', value: 3 });
          break;
        default:
          break;
      }
      this.$refs.attentionGrade.isShowListDialog = true;
      this.$refs.attentionGrade.initSelectedParams(initSelectedParams);
    },
    // 加载列表数据
    loadTableData (queryParams) {
      if (queryParams.orderBy == '' && queryParams.sequence == '') {
        queryParams.orderBy = 'ClassTime';
        queryParams.sequence = 'DESC';
      }
      queryParams.CourseStatusKey = 4;
      MessageSearchPageForStudentArrange(queryParams).then(
        result => {
          this.attentionGradeList = [];
          if (result.data.PageDataList.length > 0) {
            result.data.PageDataList.forEach((obj, index) => {
              obj.CustomerPhoneName = this.$utils.phoneModulePower(obj.CustomerPhoneName) || '无手机号';
              obj.CustomerRelationshipKeyValueName = obj.CustomerRelationshipKeyValue;
              obj.CustomerRelationshipKeyValue = obj.CustomerRelationshipKeyValue + '/' + (obj.CustomerPhoneName || '无手机号');
              obj.BeginClassTime = obj.BeginClassTime.substring(0, 5) + '-' + obj.EndClassTime.substring(0, 5);
              if (obj.AttenceStatusKeyValue == '出勤') {
                obj.AttenceStatusKeyValue = '签到';
              }
              if (obj.UnitKey == 2 || obj.UnitKey == 3 || obj.UnitKey == 4) {
                if (obj.AttenceStatusKeyValue == '未考勤') {

                } else {
                  obj.AttenceStatusKeyValue = obj.AttenceStatusKeyValue + ' 不扣课时';
                }
              } else if (obj.ClassHourKey == 5 || obj.ISClassHourDebtKey == 1) {
                obj.AttenceStatusKeyValue = obj.AttenceStatusKeyValue + ' 扣' + Number(obj.ClassCount) + '课时';
              }
              if (obj.ClassCount) {
                obj.ClassCount = Number(obj.ClassCount);
              }
              if (obj.ISClassHourDebtKey == 0) {
                obj.ClassCount = 0;
              }
              if (obj.CurrentCount) {
                obj.CurrentCount = Number(obj.CurrentCount);
              }
              if (obj.UnitKey != 5) {
                obj.CurrentCount = '-';
              }
              obj.isShowExpand = false;
              this.attentionGradeList.push(obj);
            });
          }
          this.totalNum = result.data.Total;
          this.PageCount = result.data.PageCount;
          this.$refs.attentionGrade.isLoading = false;
        });
    }

  }
};
</script>
