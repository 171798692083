<template>
  <!-- 遮罩层 -->
  <!-- maxZIndex 层级过高，多次触发时遮罩层异常 -->
  <div class="popup_dialog_shade"
       v-if="isShow"
       :style="{zIndex:maxZIndex}"
       @click="clickShade">
    <div class="shade"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      maxZIndex: 0,
      isShow: false
    };
  },
  computed: {
    vIsPopupDialogShade () {
      return this.$store.getters.isShowPopupShade;
    },
    vMaxZIndex () {
      return this.$store.getters.popupMaxZIndex;
    }
  },
  watch: {
    vIsPopupDialogShade (n, o) {
      this.isShow = n;
    },
    vMaxZIndex (n, o) {
      this.maxZIndex = n;
      this.$nextTick(() => {
        let popupDialog = document.querySelectorAll('.popup_dialog');
        if (this.$route.name == 'recruitStudentShow' && document.querySelectorAll('.open').length > 0) {       // 当前路由是

        } else if (this.$store.getters.faceSign) {
          this.$store.commit('SetPopupShade', true);
        } else if (popupDialog.length == 0 && !this.$store.getters.faceSign) {
          console.log('自己关闭');
          this.$store.commit('SetPopupShade', false);
        }
      });
    }
  },
  methods: {
    clickShade () {
      this.$bus.emit('clickShade');
    }
  }
};
</script>
<style>
</style>