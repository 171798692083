<template>

  <div class="content_box">
    <div class="content_box_title"
         :class="{open:vIsOpen}"
         @click.stop="openOrCloseDetail">
      <span>{{dataObj.MainDemoName}}</span>
    </div>
    <div v-if="vIsOpen"
         class="content_box_ul">
      <rule-course-item v-for="item in dataObj.dataList"
                        :key="item.index"
                        :courseInfo="item"
                        :contentBoxTitle="contentBoxTitle"
                        @clickEvent="onClickonClickEvent"></rule-course-item>
    </div>
  </div>
</template>

<script>
import ruleCourseItem from './rule-course-item';
export default {
  data () {
    return {
      // curSelectecCourseInfo: {}
    };
  },
  props: {
    dataObj: {
      type: Object,
      required: true,
      default: () => {
        return {
          OALPKey: 0,
          MainDemoName: '', // 班级名称/课程名
          dataList: [{ isActive: false }], // 内循环列表
          isShow: false, // 班级要显示加入班级按钮
          isOpen: false
        };
      }
    },
    contentBoxTitle: {
      type: String,
      default: ''
    }
  },
  components: {
    ruleCourseItem
  },
  computed: {
    vIsOpen () {
      return this.dataObj.isOpen;
    }

  },
  mounted () {
  },
  methods: {
    openOrCloseDetail () {
      this.dataObj.isOpen = !this.dataObj.isOpen;
      this.$emit('openOrCloseDetail', this.dataObj);
    },
    onClickonClickEvent (courseInfo, e) {
      this.$emit('doOneTopValueEvent', courseInfo, e);
    }
  }
};
</script>

<style>
</style>