<template>
  <!-- 授权课件表单 -->
  <div style="height:600px">
    <div class="form_info_edit form_info_list">
      <div>
        <input-switch v-model="isShare"
                      :formTitle="'是否统一管理课程课类'"
                      :showInputValue="false"
                      :className="'no_border'"
                      :helpDescription="'设置科目开启后，直属店只可选择，不可增加、修改'"
                      :required="false"
                      @change="changeSwitch"></input-switch>
      </div>
    </div>
    <div class="form_info_edit form_info_list"
         v-if="isShare">
      <div>
        <input-dialog :formTitle="'设置课程课类'"
                      :formPlaceholder="''"
                      class=""
                      :readonly="false"
                      :required="false"
                      @showEldilog="showClassTypeDialog()"
                      v-model="FatherKeyValue"></input-dialog>
      </div>
    </div>
    <!-- <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div> -->
  </div>
</template>
<script>
import {
  GetSaaSClientSet, ModifySaaSClientSetting
} from '../../../API/workbench';
export default {
  data () {
    return {
      menDianSetInfo: [],
      isShare: 0,   // 股份7
      FatherKeyValue: '',
      isShowClassType: false
    };
  },
  components: {

  },
  props: {
    coursewareId: Number,
    isReadonly: Boolean
  },
  created () {
    this.getMenDianSet();
  },
  mounted () {

  },
  computed: {

  },
  methods: {
    showClassTypeDialog () {
      let item = {
        name: '课程课类管理',
        routerName: this.$route.name,
        routertitle: '工作台',
        auth: true,
        roleName: '',
        moduleName: 'courseClassManageSetting',
        ModulePowerKey: ''
      };
      this.$bus.emit('needOpenRouter', item);
    },
    // 获取门店设置
    getMenDianSet () {
      GetSaaSClientSet().then(result => {
        console.log('GetSaaSClientSet', result.data);
        this.menDianSetInfo = result.data;
        this.isShare = this.initData(1, this.isShare);
        console.log('initData', this.isShare, this.isDirectly, this.isBrand, this.isCourseware);
      });
    },
    initData (type, value) {
      let locaIndex = this.menDianSetInfo.findIndex(o => {
        return o.SetTypeKey == type;
      });
      if (locaIndex >= 0) {
        return Number(this.menDianSetInfo[locaIndex].SetKey) || 0;
      }
    },
    setData (type, value) {
      let locaIndex = this.menDianSetInfo.findIndex(o => {
        return o.SetTypeKey == type;
      });
      if (locaIndex >= 0) {
        this.menDianSetInfo[locaIndex].SetKeyValue = value == 1 ? '是' : '否';
        this.menDianSetInfo[locaIndex].SetKey = value;
      }
    },
    // 找到匹配项加到数组中.
    findItemPushArray (type, newArr) {
      let locaIndex = this.menDianSetInfo.findIndex(o => {
        return o.SetTypeKey == type;
      });
      if (locaIndex >= 0) {
        newArr.push(this.menDianSetInfo[locaIndex]);
      }
    },
    changeSwitch () {
      console.log('changeSwitch', this.isShare);
      this.submit();
    },
    // 提交
    submit (callback) {
      console.log('submit', this.menDianSetInfo);
      // 设置是否统一管理课程课类
      this.setData(1, this.isShare);

      console.log('submit', this.menDianSetInfo);
      // 续费
      let newObj = [];
      this.findItemPushArray(1, newObj);
      console.log(newObj, 'newObj');
      ModifySaaSClientSetting(newObj).then(result => {
        layer.alert('修改成功');
        this.$emit('afterSuccess');
        this.cancel();
      }, error => {
        this.isShare = 0;
        layer.alert(error.msg);
      }).finally(() => {
        if (callback) {
          callback();
        }
      });
    },
    cancel () {
      this.$emit('closeDialog');
    }
  }
};
</script>

