<template>
  <div class="cover_content content_list"
       @click.stop="seletedItem()"
       :class="isShow?'opt':''"
       @mouseenter="showShade(true)"
       @mouseleave="showShade(false)">
    <div class="content_list_img">
      <img :src="vImg"
           :onerror="vDefaultImg"
           alt="">
      <!-- <div class="content_list_img_hot" v-if="item.isHot"></div> -->
      <div class="content_list_img_make"
           v-if="item.templateType ==2">自定义</div>
      <div class="content_list_img_self"
           v-else>公共</div>
    </div>
    <div class="content_list_info">
      <div class="content_list_title">
        <div class="content_list_type">{{item.SceneKeyValue}}</div>
        <div class="text_overflow_hidden">{{item.title}}</div>
      </div>
      <div class="content_list_label">
        <div class="content_list_label_text"
             v-for="(agenciesItem,key) in vAgenciesIDSNameList"
             :key="key">
          {{agenciesItem}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isShow: false
    };
  },
  props: {
    item: Object
  },
  computed: {
    vAgenciesIDSNameList () {
      let AgenciesIDSName = this.item.AgenciesIDSName || '';
      return AgenciesIDSName ? AgenciesIDSName.split(',') : [];
    },
    // 是否活动结束
    vTimeEnd () {
      return Date.new(this.item.time_end) < Date.new()
    },
    vImg () {
      if (!this.item.pic) {
        this.item.pic = '';
      }
      if (this.item.pic.indexOf('http') > -1) {
        return this.item.pic;
      } else {
        return this.$store.getters.CDNURL + this.item.pic;
      }
    },
    // 默认显示logo
    vDefaultImg () {
      return 'this.src="' + require('../../../../../public/image/no_logo@2x.png') + '"';
    }
  },
  methods: {
    showShade (type) {
      this.isShow = type;
    },
    seletedItem (type) {
      this.$emit('seletedItem', this.item, type);
    }
  }
};

</script>
<style scoped>
.content_list_img .options_list {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.content_list_content {
  margin-top: 5px;
}
.content_list.cover_content {
  width: 192px;
  height: 206px;
  margin-right: 14px;
  margin-bottom: 20px;
}
.content_list.cover_content .content_list_img {
  width: 100%;
  height: 148px;
}
.cover_content .content_list_info {
  height: 60px;
}

.content_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content_list_img_self {
  position: absolute;
  width: 48px;
  height: 20px;
  left: 8px;
  top: 11px;
  z-index: 1;
  background: #f8fafd;
  color: #666666;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
}
.content_list_img_make {
  position: absolute;
  width: 48px;
  height: 20px;
  left: 8px;
  top: 11px;
  z-index: 1;
  background: #ff7a00;
  color: white;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
}
.content_list_img_going {
  position: absolute;
  width: 48px;
  height: 20px;
  left: 8px;
  top: 11px;
  z-index: 1;
  background: #49cd89;
  color: white;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
}
.content_list_img_over {
  position: absolute;
  width: 48px;
  height: 20px;
  left: 8px;
  top: 11px;
  z-index: 1;
  background: #f75040;
  color: white;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  /* background: url(../image/wkt_hot_icon.png) no-repeat; */
}
</style>