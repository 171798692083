<template>
  <div>
    <div class="general_class_top"
         style="justify-content: flex-start;">
      <!-- <div class="">
        <date-range ref="saveDate"
                    :searchObj="classStatisticsForGrade"
                    :fromTitle="'选择日期'"
                    :minDate="vMinDate"
                    :maxDate="vMaxDate"
                    :isShowHistory="isBigShowHistory"
                    @changeDate="changeSaveDate"></date-range>
      </div>
      <div class="change_history_data"
           style="right: auto;margin-left: 340px;top: auto;"
           @click.stop="historyBigBtn(historyBigName)">{{ historyBigName }}</div> -->

      <div class="attend_class_title">{{ this.classInfo.MainDemoName }}</div>

      <div class="general_class_top_text"
           style="margin-left: 5px;padding-bottom: 14px;line-height: 17px;">
        <!-- <span v-if="classInfo.ApplyCorName"
              class="text_overflow_hidden">上课课程：{{classInfo.ApplyCorName}}</span>
        <span v-if="classInfo.IsSyncGradeClassKey>0">全班一起上课</span> -->
        <!-- 修改 -->
        <div class="general_class_alter"
             @click="onEditClassInfo">&nbsp;</div>
      </div>
    </div>

    <div class="general_right_top_box flex">
      <div class="right_top_box box_right fr"
           style="width: 734px; border:none;height: 24px;    background: rgb(217, 223, 228,0.4);border-radius: 3px;line-height: 19px;">
        <!-- <summary-data-item v-for="(item,key,index) in headSummaryData"
                           :sumData="item"
                           :key="index"
                           :cursorShow="'cursor: default'"></summary-data-item> -->
        <div class="weekly_class_scheduling general_info"
             :title="gradeClassInfo.WeekClassArrangeNum">周排课次 <span>{{ Number(gradeClassInfo.WeekClassArrangeNum)||'-' }}</span></div>
        <div class="classroom_courses general_info"
             :title="gradeClassInfo.ApplyCorName">上课课程 <span>{{ gradeClassInfo.ApplyCorName||'-' }}</span></div>
        <div class="Lecturer_class general_info"
             style="width: 150px;"
             :title="gradeClassInfo.LecturerKeyValue">主讲老师 <span>{{ gradeClassInfo.LecturerKeyValue }}</span></div>
        <div class="studen_attend_class general_info"
             style="width: 180px;">全部学生一起上课 <span :class="gradeClassInfo.IsSyncGradeClassKey == 1?'open_attend_class':
             'close_attend_class'">{{ gradeClassInfo.syncGradeClassValue }}</span></div>
        <div class="full_class_number general_info"
             style="border-right: none;width: 165px;"
             :title="gradeClassInfo.EnrollmentCount">招生满班人数 <span>{{ Number(gradeClassInfo.EnrollmentCount)||'-' }}</span></div>
      </div>
    </div>

    <div class="studen_attendance_box">
      <div class="studen_content_box">
        <!-- 结转课消 -->
        <div class="forward_course_cancellation f_c_flex"
             style="background: #E7E1FB;box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04);margin-left: 20px;">
          <div class="course_cancellation_text c_text">结转课消</div>
          <div class="course_cancellation_num c_num"
               :title="classDeductAmountInfo.DeductAmountData.DeductAmount">
            {{ Number(classDeductAmountInfo.DeductAmountData.DeductAmount) }}
            <!-- <span>万元</span>  -->
          </div>
          <div class="consumption_class_hours a_num"
               :title="classDeductAmountInfo.DeductAmountData.DeductCount">消耗课时：

            {{ Number(classDeductAmountInfo.DeductAmountData.DeductCount) }}
          </div>
        </div>
        <!-- 出勤率 -->
        <div class="attendance_info f_c_flex"
             style="background: #FDF3E9;box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04);">
          <div class="attendance_text c_text">出勤率</div>
          <div class="percentage_num c_num"
               :title="classDeductAmountInfo.AttendanceRateData.AttendanceRate">

            {{ Number(classDeductAmountInfo.AttendanceRateData.AttendanceRate) }}
            %
          </div>
          <div class="attendance_num a_num"
               :title="classDeductAmountInfo.AttendanceRateData.ArrivedNumCount">出勤人次：

            {{ Number(classDeductAmountInfo.AttendanceRateData.ArrivedNumCount) }}
          </div>
        </div>
        <!-- 本月排课 -->
        <div class="month_course_scheduling">
          <!-- 1 -->
          <div class="scheduling_box month_flex">
            <div class="course_scheduling_text s_text_flex">本月排课</div>
            <div class="scheduling_schedulel schedulel_flex"
                 :style="{'width':Number(monthAttendanceInfo.ThisMonthArrangedNumCount)?'150px':'0px'}"></div>
            <div class="scheduling_num people_flex">{{Number(monthAttendanceInfo.ThisMonthArrangedNumCount)||0}}人次</div>
          </div>
          <!-- 2 -->
          <div class="current_arrive_box month_flex">
            <div class="current_arrive_text s_text_flex">当前应到</div>
            <div class="arrive_schedulel schedulel_flex"
                 :style="{'width':Number(monthAttendanceInfo.ArrangedNumCount)?(monthAttendanceInfo.ArrangedNumCount/monthAttendanceInfo.ThisMonthArrangedNumCount)*150+'px':'0px'}"></div>
            <div class="arrive_num people_flex">{{Number(monthAttendanceInfo.ArrangedNumCount)||0}}人次</div>
          </div>
          <!-- 3 -->
          <div class="current_actual_box month_flex">
            <div class="current_actual_text s_text_flex">当前实到</div>
            <div class="actual_schedulel schedulel_flex"
                 :style="{'width':Number(monthAttendanceInfo.ArrivedNumCount)?(monthAttendanceInfo.ArrivedNumCount/monthAttendanceInfo.ThisMonthArrangedNumCount)*150+'px':'0px'}"></div>
            <div class="actual_num people_flex">{{Number(monthAttendanceInfo.ArrivedNumCount)||0}}人次</div>
          </div>
        </div>
        <!-- 班内全部学生 -->
        <div class="all_students_info">
          <div class="echart_big_round"></div>
          <div class="echart_small_round"></div>
          <circle-echart :echartID="'circle_echart'"
                         :nowDataList="nowList"
                         :tipstitle="vtipstitle"
                         ref="echart"></circle-echart>
          <div style="position: absolute;left: 133px;top: 0;">
            <!-- 1 -->
            <div class="scheduling_box month_flex"
                 style="width: 225px;">
              <div class="course_scheduling_text s_text_flex">班内全部学生</div>
              <div class="scheduling_schedulel schedulel_flex m_c_s"
                   style=" z-index: 2;"
                   :style="{'width':Number(scheduleInfo.EnrollmentCount)?(Number(scheduleInfo.CurrentPeoCount)/Number(scheduleInfo.EnrollmentCount))*150+'px':Number(scheduleInfo.CurrentPeoCount)?'150px':'0px'}"></div>
              <div class="schedulel_bj"
                   :style="{'width':Number(scheduleInfo.EnrollmentCount)?'150px':'0px'}"></div>
              <div v-if="Number(gradeClassInfo.EnrollmentCount)"
                   class="full_shift_rate">满班率：{{Math.ceil(Number(scheduleInfo.AttendRate))}}%</div>
              <div class="scheduling_num people_flex"
                   style="margin-left: 80px;">{{Number(scheduleInfo.CurrentPeoCount)}}人</div>
            </div>
            <!-- 2 -->
            <div class="current_arrive_box month_flex"
                 style="width: 225px;">
              <div class="current_arrive_text s_text_flex">周排课0-1次</div>
              <div class="arrive_schedulel schedulel_flex m_c_s"
                   :style="{'width':(Number(scheduleInfo.CurrentPeoCount)>Number(scheduleInfo.EnrollmentCount))?(Number(scheduleInfo.CourseArrangeNumberType1)/Number(scheduleInfo.CurrentPeoCount))*150+'px':
                   Number(scheduleInfo.EnrollmentCount)?(Number(scheduleInfo.CourseArrangeNumberType1)/Number(scheduleInfo.EnrollmentCount))*150+'px':Number(scheduleInfo.CourseArrangeNumberType1)?'150px':'0px'}"></div>
              <div class="details_text"
                   @click.stop="studentsDetailsFun(1)">详情></div>
              <div class="arrive_num people_flex"
                   style="margin-left: 80px;">{{Number(scheduleInfo.CourseArrangeNumberType1)}}人</div>
            </div>
            <!-- 3 -->
            <div class="current_actual_box month_flex"
                 style="width: 225px;">
              <div class="current_actual_text s_text_flex">周排课2次以上</div>
              <div class="actual_schedulel schedulel_flex m_c_s"
                   :style="{'width':(Number(scheduleInfo.CurrentPeoCount)>Number(scheduleInfo.EnrollmentCount))?(Number(scheduleInfo.CourseArrangeNumberType2)/Number(scheduleInfo.CurrentPeoCount))*150+'px':
                   Number(scheduleInfo.EnrollmentCount)?(Number(scheduleInfo.CourseArrangeNumberType2)/Number(scheduleInfo.EnrollmentCount))*150+'px':Number(scheduleInfo.CourseArrangeNumberType2)?'150px':'0px'}"></div>
              <div class="details_text"
                   @click.stop="studentsDetailsFun(2)">详情></div>
              <div class="actual_num people_flex"
                   style="margin-left: 80px;">{{Number(scheduleInfo.CourseArrangeNumberType2)}}人</div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="right_table">
      <div class="general_course_summarizing_title right_table_box clearfix"
           style="display:block;">
        <!-- 类型 -->
        <div class="right_table_nav fl">
          <div class="nav_list"
               v-for="(item,index) in classHeadBox"
               :key="index"
               :class="{'opt':item.isShow}"
               @click="switchClassHead(item)">{{item.title}}
          </div>
        </div>
      </div>
      <div class="general_course_summarizing_title right_table_box no_border_bottom pr"
           style="justify-content:left"
           :class="arrangebtn.nameValue=='DC'?'class_record_module':''"
           v-if="arrangebtn.nameValue!='TJFX'">
        <div class="flex"
             v-if="arrangebtn.nameValue=='XSJB'">
          <div class="table_select_box"
               style="margin-right:20px">
            <div class="table_select_title">关注</div>
            <el-select v-model="defaultAttentionText"
                       placeholder="请选择"
                       ref="resetText"
                       @change="selectAttention">
              <el-option v-for="item in attention"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
          <!-- <div class="table_select_box"
               style="margin-right:0px">
            <div class="table_select_title">刷脸</div>
            <el-select v-model="defaultFaceRecognitionText"
                       placeholder="请选择"
                       ref="resetText"
                       @change="selectFaceRecognition">
              <el-option v-for="item in faceSwiping"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div> -->
        </div>
        <!-- 上课记录日期查询 -->
        <div v-if="arrangebtn.nameValue=='DC'"
             style="padding-top:13px">
          <div class="record_course_cancellation">
            <div class="course_record course_flex"
                 :class="isCourseInfo?'courseOpt':''"
                 @click.stop="courseInfoFun(true)">课次记录</div>
            <div class="course_cancellations course_flex"
                 :class="!isCourseInfo?'courseOpt':''"
                 @click.stop="courseInfoFun(false)">结转课消</div>
          </div>
          <date-range ref="saveDate"
                      :searchObj="attendClassDate"
                      :fromTitle="'选择日期'"
                      :minDate="vMinDate"
                      :maxDate="vMaxDate"
                      :isShowHistory="isShowHistory"
                      @changeDate="changeAttendClassDate"></date-range>
          <div class="change_history_data"
               @click.stop="historyBtn(historyName)">{{ historyName }}</div>
        </div>

        <div :style="arrangebtn.nameValue=='XJKB'?'width:100%':''">
          <div class="course_search_form"
               v-if="arrangebtn.nameValue=='XJKB'">
            <div class="course_search_input">
              <input type="text"
                     v-model="paramsForStudent.searchText"
                     @keydown="keydownEvent($event)"
                     placeholder="请输入姓名、姓名首字母、手机号">
              <span v-show="paramsForStudent.searchText"
                    class="home_remove_btn"
                    @click="paramsForStudent.searchText=''"></span>
            </div>
          </div>

          <div v-else>
            <div style="margin-left: 30px;margin-top: 12px;">
              <input-search-contain-btn v-model="paramsForStudent.searchText"
                                        :placeholder="vplaceholder"
                                        :width="'286px'"
                                        @onSearchEvent="searchStudent"
                                        @clearSearch="clearSearchCondition"></input-search-contain-btn>
            </div>
          </div>
          <div v-if="arrangebtn.nameValue =='XJKB'">
            <function-btn :item="arrangebtn"
                          @clickItem="addToStudent"
                          style="position: absolute;right: 0px;top: 0;"
                          :className="'right_top_btn btn_hover_bg_blue fr'">
            </function-btn>
          </div>
          <div v-if="arrangebtn.nameValue =='DC'">
            <function-btn :item="arrangebtn"
                          @clickItem="addToStudent"
                          style="position: absolute;right: 0px;top: 0;width: 55px"
                          :className="'right_top_btn btn_hover_bg_blue fr'">
            </function-btn>
          </div>
          <div v-if="arrangebtn.nameValue =='XSJB'"
               class="">
            <function-btn :item="arrangebtn"
                          @clickItem="addToClass"
                          @mouseover.native="addToClass"
                          @mouseleave.native="closeStudentAddClass"
                          style="position: absolute;top: 0;right: 75px;width: 60px"
                          :className="'right_top_btn btn_hover_bg_blue fr'">
            </function-btn>

            <function-btn v-if="arrangebtn.nameValue =='XSJB'"
                          :item="{name:'导出',ModulePowerKey:121}"
                          @clickItem="studentExport"
                          style="position: absolute;right: 0;top: 0;width: 60px"
                          :className="'right_top_btn btn_hover_bg_blue fr'">
            </function-btn>
            <pop-up-drop-down-box :style="{ zIndex: 14,left:'89%!important' }"
                                  :isShowDialog="isShowStudentAddClass"
                                  :dropDownList="studentAddClassSelect"
                                  :positionTop="47"
                                  @onClick="selectStudentAddClass"
                                  @mouseover.native="isShowStudentAddClass = true"
                                  @mouseleave.native="closeStudentAddClass"></pop-up-drop-down-box>
          </div>

          <!-- <div class="title_switch fr"
               style="margin-right: 90px;"
               v-if="arrangebtn.nameValue=='XJKB'">
            <el-switch v-model="isTemporaryStu"
                       @change="changeStatus"></el-switch>
            <span>临时排课学生</span>
          </div> -->
        </div>
      </div>
    </div>
    <students-list ref="studentsList"
                   :formTitle="'批量进班'"
                   @onAddStudent="addStudentClass"
                   @onDeleteStudent="quitStudentClass"></students-list>

    <custom-dialog title="班级学生排课分类统计"
                   :visible.sync="isShowClassStudentsStatistics"
                   :before-close="closeisShowClassStudentsStatistics">
      <class-students-form :classInfo="classInfo"
                           :scheduleInfo="scheduleInfo"
                           :classType="classType"></class-students-form>
    </custom-dialog>
  </div>
</template>

<script>
import {
  GetClassStatisticsForGrade,
  GetClassHistoryStatisticsForGrade,
  studentAddClass,
  QuitStudentFromClass,
  MessageSearchArrangedPageForGradeStudent,
  MessageSearchGradeCourseArrangeRepost, // 排课统计
  MessageSearchThisMonthAttendanceReportForClass, // 本月应到实到统计
  MessageSearchClassDeductAmountAndAttendanceReportForClass, // 结转课消统计
  MessageSearchGradeClassInfoReport // 班级信息
} from '../../../../API/workbench.js';
import summaryDataItem from '../../summary-data/item';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import popUpDropDownBox from '../../../common/pop-up-drop-down-box';
import studentsList from './students-list';
import classStudentsForm from './class-students-form';
import circleEchart from './statistics-analyze-form/circle-echart';

export default {
  data () {
    return {
      isCourseInfo: true, // true 课次记录 false 结转课消
      classType: 1,
      isShowClassStudentsStatistics: false,
      gradeClassInfo: {},
      scheduleInfo: {},
      monthAttendanceInfo: {},
      classDeductAmountInfo: {
        AttendanceRateData: {
          ArrangedNumCount: '0',
          ArrivedNumCount: '0',
          AttendanceRate: '0'
        },
        DeductAmountData: {
          DeductAmount: '0',
          DeductCount: '0'
        }
      },
      nowList: [
        {
          itemStyle: {
            color: '#A9D0FF'
          },
          name: '班级满编人数',
          value: 0
        },
        {
          itemStyle: {
            color: '#0075FF'
          },
          name: '班内学生数量',
          value: 0
        }
      ],
      historyBigName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isBigShowHistory: false, // true 2022前/false 2022后
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      defaultAttentionText: '全部',
      defaultFaceRecognitionText: '全部',
      attention: [
        { value: '全部', id: '' },
        { value: '已关注', id: 1 },
        { value: '未关注', id: 0 }
      ],
      faceSwiping: [
        { value: '全部', id: '' },
        { value: '已录入', id: 1 },
        { value: '未录入', id: 0 }
      ],
      // 班级课统计日期查询
      classStatisticsForGrade: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd'),
        GradeID: ''
      },
      // 上课记录日期查询
      attendClassDate: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate())))),
        GradeID: ''
      },
      isTemporaryStu: false,  // 是否开启临时学生
      headSummaryData: [
        { title: '课次', value: '', className: '', SpecialFilter: '0', dataType: 'number', decimalPlace: 0 },
        { title: '学生课时', value: '', type: 'Num_NotEnought', className: '', SpecialFilter: '3', dataType: 'number', decimalPlace: 0 },
        { title: '课消金额(元)', value: '', type: 'Num_UsedUpClassCount', className: '', SpecialFilter: '2', dataType: 'number', decimalPlace: 0 },
        { title: '实到/应到', value: '', className: '', SubscribeKey: '1', dataType: 'number' },
        { title: '出勤率', value: '', type: 'Num_UnSubscribe', className: '', SubscribeKey: '0', dataType: 'number' }
      ],
      isShowStudentAddClass: false,
      studentAddClassSelect: [
        {
          id: 'add',
          name: '学生进班',
          ModulePowerKey: 51,
          isShow: true
        },
        {
          id: 'batch',
          name: '学生批量进班',
          ModulePowerKey: 51,
          isShow: true
        }
      ],
      isShowBatchStudentAddClass: false
    };
  },
  props: {
    classInfo: Object,
    classHeadBox: Array,
    paramsForStudent: Object,
    arrangebtn: Object
  },
  components: {
    summaryDataItem,
    inputSearchContainBtn,
    popUpDropDownBox,
    studentsList,
    circleEchart,
    classStudentsForm
  },
  computed: {
    vtipstitle () {
      return '月报名金额';
    },
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    },
    vplaceholder () {
      if (this.arrangebtn.nameValue == 'XSJB') { // 学生进班
        return '请输入姓名、姓名首字母、手机号';
      } else if (this.arrangebtn.nameValue == 'DC') { // 导入
        return '请输入课程、老师';
      }
    }
  },
  watch: {
    'classInfo.OLAPKey': {
      handler (n, o) {
        console.log(n, '班级id-123');
        if (Number(n) > 0) {
          this.getClassStatisticsForGrade(n);
        }
      },
      // deep: true,
      immediate: true
    }
  },
  created () {
    this.$dialog.register(this, ['squadClassSetting']);
    // this.getClassStatisticsForGrade();
  },
  mounted () {
    this.getMessageSearchGradeCourseArrangeRepost();
  },
  methods: {
    courseInfoFun (bool) {
      this.paramsForStudent.searchText = '';
      this.paramsForStudent.pageIndex = 0;
      this.isCourseInfo = bool;
      this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      this.attendClassDate.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      this.attendClassDate.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));// 选中当天日期
      this.isShowHistory = false;
      this.$emit('courseInfoFun', this.attendClassDate.startTime, this.attendClassDate.endTime, this.isShowHistory, this.isCourseInfo);
    },
    // 打开 班级学生排课分类统计
    studentsDetailsFun (type) {
      this.classType = type;
      this.isShowClassStudentsStatistics = true;
    },
    // 关闭 班级学生排课分类统计
    closeisShowClassStudentsStatistics () {
      this.isShowClassStudentsStatistics = false;
    },
    getMessageSearchGradeCourseArrangeRepost () {
      console.log(this.classInfo, 'this.classInfo.OLAPKey123');
      MessageSearchGradeClassInfoReport(this.classInfo.OLAPKey).then(res => {
        this.gradeClassInfo = res.data;
        this.gradeClassInfo.LecturerKeyValue = this.gradeClassInfo.LecturerKeyValue ? this.gradeClassInfo.LecturerKeyValue : '-';
        this.gradeClassInfo.syncGradeClassValue = this.gradeClassInfo.IsSyncGradeClassKey == 0 ? '关闭' : '开通';
        console.log(res.data, 'getMessageSearchGradeCourseArrangeRepost11');
      }, err => {
        layer.alert(err.msg);
      });
      MessageSearchGradeCourseArrangeRepost(this.classInfo.OLAPKey).then(res => {
        res.data.AttendRate = Math.ceil(Number(res.data.AttendRate));
        this.scheduleInfo = res.data;
        if (Number(res.data.AttendRate)) {
          if (res.data.AttendRate < 100) {
            this.nowList[0].value = 100 - res.data.AttendRate;
            this.nowList[1].value = res.data.AttendRate;
          } else {
            this.nowList.splice(0, 1);
            this.nowList[0].value = Number(res.data.AttendRate);
          }
        } else {
          if (Number(this.scheduleInfo.EnrollmentCount) && !Number(this.scheduleInfo.CurrentPeoCount)) {
            this.nowList.splice(1, 1);
            this.nowList[0].value = Number(this.scheduleInfo.EnrollmentCount);
          } else if (!Number(this.scheduleInfo.EnrollmentCount) && Number(this.scheduleInfo.CurrentPeoCount)) {
            this.nowList.splice(0, 1);
            this.nowList[0].value = Number(this.scheduleInfo.CurrentPeoCount);
          } else if (!Number(this.scheduleInfo.EnrollmentCount) && !Number(this.scheduleInfo.CurrentPeoCount)) {
            this.nowList.splice(1, 1);
            this.nowList[0].value = Number(this.scheduleInfo.EnrollmentCount);
          } else {
            this.nowList[0].value = Number(this.scheduleInfo.EnrollmentCount);
            this.nowList[1].value = Number(this.scheduleInfo.CurrentPeoCount);
          }
        }
        this.$refs.echart.echartInit();
        console.log(res.data, 'MessageSearchGradeCourseArrangeRepost111');
        console.log(this.nowList, 'this.nowList');
      }, err => {
        layer.alert(err.msg);
      });
      MessageSearchThisMonthAttendanceReportForClass(this.classInfo.OLAPKey).then(res => {
        this.monthAttendanceInfo = res.data[0];
        console.log(res.data, 'MessageSearchThisMonthAttendanceReportForClass');
      }, err => {
        layer.alert(err.msg);
      });
      MessageSearchClassDeductAmountAndAttendanceReportForClass(this.classInfo.OLAPKey).then(res => {
        this.classDeductAmountInfo = res.data;

        console.log(this.classDeductAmountInfo, 'this.classDeductAmountInfo');
        console.log(res.data, 'MessageSearchClassDeductAmountAndAttendanceReportForClass');
      }, err => {
        layer.alert(err.msg);
      });
      console.log('this.scheduleInfo ', this.scheduleInfo);
    },
    historyBtn (name) {
      console.log(this.isShowHistory, 'isShowHistory');
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.attendClassDate.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.attendClassDate.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isShowHistory = true;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.attendClassDate.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.attendClassDate.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
        this.isShowHistory = false;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.$emit('getDataList', this.attendClassDate.startTime, this.attendClassDate.endTime, this.isShowHistory);
      this.$refs.saveDate.changeHistoryTime(this.attendClassDate.startTime, this.attendClassDate.endTime);
    },
    historyBigBtn (name) {
      console.log(this.isBigShowHistory, 'isBigShowHistory', name);
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.classStatisticsForGrade.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.classStatisticsForGrade.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isBigShowHistory = true;
        this.historyBigName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.classStatisticsForGrade.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.classStatisticsForGrade.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
        this.isBigShowHistory = false;
        this.historyBigName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.getClassStatisticsForGrade();
      this.$refs.saveDate.changeHistoryTime(this.classStatisticsForGrade.startTime, this.classStatisticsForGrade.endTime);
    },
    addStudentClass (item, completeCallback, isAllowClassHourLackKey, errorCallback) {
      console.log(item, 'QuitStudentFromClass');
      studentAddClass(item.OLAPKey, this.classInfo.OLAPKey).then(res => {
        this.$emit('upForStudentList');
      }, err => {
        errorCallback();
        layer.alert(err.msg);
      });
    },
    quitStudentClass (item, index, completeCallback) {
      QuitStudentFromClass(item.StudentKey, this.classInfo.OLAPKey).then(res => {
        completeCallback();
        this.$emit('upForStudentList');
      }, err => {
        layer.alert(err.msg);
      });
    },
    selectStudentAddClass (e) {
      if (e.id == 'add') {
        this.$emit('addToClass', this.arrangebtn);
      } else if (e.id == 'batch') {
        let params = this.$utils.parseJson(this.paramsForStudent);
        params.pageIndex = 0;
        params.pageSize = 0;

        MessageSearchArrangedPageForGradeStudent(params).then(result => {
          console.log(result.data, '班内学生');
          let studentList = result.data.PageDataList;
          this.isShowBatchStudentAddClass = true;
          this.$refs.studentsList.isShowAddStudent = true;
          this.$refs.studentsList.pushDataStudentList(studentList);
        }, error => {
          layer.alert(error.msg);
        });
      }
    },
    closeStudentAddClass () {
      this.isShowStudentAddClass = false;
    },
    getClassStatisticsForGrade (classkey) {
      let fn = '';
      if (this.isBigShowHistory) {
        fn = GetClassHistoryStatisticsForGrade;
      } else {
        fn = GetClassStatisticsForGrade;
      }
      console.log(this.classInfo, '班级课统计');
      this.classStatisticsForGrade.GradeID = this.classInfo.OLAPKey || classkey;
      if (this.classStatisticsForGrade.GradeID) {
        fn(this.classStatisticsForGrade).then(result => {
          console.log(result.data, 'GetClassStatisticsForGrade');
          this.headSummaryData[0].value = result.data.CourseNum;// 课次
          this.headSummaryData[1].value = result.data.ClassCount;// 学生课时
          this.headSummaryData[2].value = result.data.Amount;// 课消金额(元)
          if (Number(result.data.ArrivedNumCount) > 0 || Number(result.data.ArrangedNumCount) > 0) {
            this.headSummaryData[3].dataType = 'text';
            this.headSummaryData[3].value = Number(result.data.ArrivedNumCount) + '/' + Number(result.data.ArrangedNumCount);// 实到/应到
          } else {
            this.headSummaryData[3].dataType = 'number';
            this.headSummaryData[3].value = '';
          }
          if (Number(result.data.AttendanceRate) > 0) {
            this.headSummaryData[4].dataType = 'text';
            this.headSummaryData[4].value = this.$utils.mAmountType(Number(result.data.AttendanceRate) * 100, 2) + '%';// 出勤率
          } else {
            this.headSummaryData[4].dataType = 'number';
            this.headSummaryData[4].value = '';
          }
          console.log(result.data, '班级课统计');
        });
      }
    },
    // 切换日期后获取班级课统计
    changeSaveDate (dataInfo) {
      console.log(dataInfo, '班级课统计');
      this.classStatisticsForGrade.startTime = dataInfo.startTime;
      this.classStatisticsForGrade.endTime = dataInfo.endTime;
      this.getClassStatisticsForGrade();
    },
    // 上课记录日期查询
    changeAttendClassDate (dataInfo) {
      console.log(dataInfo, '上课记录日期查询');
      this.$emit('changeAttendClassDate', dataInfo);
    },
    // 查询 关注
    selectAttention (val) {
      let findItem = this.attention.find(o => {
        return o.value == val;
      });
      this.paramsForStudent.SubscribeKey = findItem ? findItem.id : '';
      this.$emit('searchStudent');
    },
    // 查询 刷脸
    selectFaceRecognition (val) {
      let findItem = this.faceSwiping.find(o => {
        return o.value == val;
      });
      this.paramsForStudent.FaceRecognition = findItem ? findItem.id : '';
      this.$emit('searchStudent');
    },
    // 修改班级
    onEditClassInfo () {
      if (this.$utils.CheckModulePower(this.$store.getters.RolePowerList, 45)) {
        if (this.classInfo.OLAPKey) {
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '修改班级',
            moduleName: 'squadClassSetting',
            data: { editItem: this.classInfo, isReadonly: false },
            callBack: {
              afterSuccess: () => { this.addSuccessEvent('Edit'); }
            }
          });
        }
      } else {
        this.$utils.CheckModulePowerToTips(45);
      }
    },
    // 修改班级成功后回调
    addSuccessEvent (type) {
      this.getMessageSearchGradeCourseArrangeRepost();
      this.$emit('addSuccessEvent', type, this.classInfo);
    },
    // 切换班级排课类型
    switchClassHead (item) {
      this.$emit('doSwitchClassHead', item);
    },
    // 搜索
    searchStudent () {
      this.$emit('searchStudent', this.paramsForStudent);
    },
    // 清除
    clearSearchCondition () {
      this.$emit('clearSearchCondition', this.isCourseInfo, this.isCourseBalance);
      this.externalClearSearchCondition(this.isCourseInfo);
    },
    // 外部清除
    externalClearSearchCondition (isCourseInfo) {
      this.defaultAttentionText = '全部';
      this.defaultFaceRecognitionText = '全部';
      this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      this.attendClassDate.startTime = '';
      this.attendClassDate.endTime = '';
      this.isShowHistory = false;
      this.isCourseInfo = isCourseInfo;
      if (this.$refs.resetText) {
        this.$refs.resetText.blur();
      }
    },
    addToStudent () {
      this.$emit('addToClass', this.arrangebtn);
    },
    // 按钮
    addToClass () {
      if (this.arrangebtn.nameValue == 'XSJB' && this.classInfo.IsSyncGradeClassKey == 1) {
        this.studentAddClassSelect[1].ModulePowerKey = 51;
      } else {
        this.studentAddClassSelect[1].ModulePowerKey = -1;
      }
      this.isShowStudentAddClass = true;
      console.log(this.arrangebtn.nameValue, '按钮');
    },
    // 班级学生导出
    studentExport () {
      let arrangebtn = {
        name: '班级学生导出', nameValue: 'XSDC', ModulePowerKey: ''
      };
      this.$emit('addToClass', arrangebtn);
    },
    // 更改是否离校学生状态.
    changeStatus (val) {
      this.$emit('changeStatus', val);
      // console.log(this.isTemporaryStu, 'changeStatus', val);
    },
    // 回车搜索
    keydownEvent (e) {
      switch (e.keyCode) {
        case 13: // 回车
          this.searchStudent();
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style scoped>
.attend_class_title {
  color: #333;
  font-family: Alibaba PuHuiTi;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.change_history_data {
  position: absolute;
  right: 80px;
  top: 13px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
.general_info {
  width: 150px;
  text-align: center;
  border-right: 1px solid #dce3eb;
  height: 18px;
  margin-top: 3px;
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #999;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.general_info span {
  color: #333;
}
.studen_attend_class span {
  border-radius: 3px;
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  width: 28px;
  line-height: normal;
  display: inline-block;
}
.open_attend_class {
  background: rgb(30, 208, 122, 0.2);
  color: #1ed07a !important;
}
.close_attend_class {
  background: rgba(225, 108, 103, 0.2);
  color: #ff6c67 !important;
}
.studen_content_box {
  display: flex;
  justify-content: space-between;
}
.f_c_flex {
  width: 145px;
  height: 124px;
  flex-shrink: 0;
  border-radius: 8px;
  text-align: center;
  line-height: 40px;
}
.month_course_scheduling {
  width: 240px;
  height: 124px;
}
.all_students_info {
  width: 400px;
  height: 124px;
  position: relative;
}
.all_students_info >>> .echarts_m {
  width: 210px !important;
  height: 154px !important;
  margin-left: -80px !important;
  margin-top: -6px;
}
/* .all_students_info >>> .echarts_m div canvas {
  width: 140px !important;
  height: 140px !important;
} */
.c_text {
  color: #666;
  text-align: center;
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}
.c_num {
  color: #333;
  font-family: Arial;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  height: 47px;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.c_num span {
  font-size: 14px;
}
.a_num {
  width: 111px;
  height: 26px;
  margin: 0 auto;
  border-radius: 100px;
  color: #fff;
  text-align: center;
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 26px;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.consumption_class_hours {
  background: rgb(180, 152, 253, 0.5);
}
.attendance_num {
  background: rgb(250, 185, 117, 0.5);
}
.month_flex {
  position: relative;
  height: 46px;
}
.s_text_flex {
  color: #999;
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}
.schedulel_flex {
  width: 150px;
  height: 8px;
  position: absolute;
  top: 0px;
  left: 60px;
  max-width: 150px;
}
.scheduling_schedulel {
  background: linear-gradient(90deg, #a9d0ff 0%, #0075ff 80.24%);
}
.arrive_schedulel {
  background: linear-gradient(90deg, #ffe49b 0%, #fb0 80.24%);
}
.actual_schedulel {
  background: linear-gradient(90deg, #c0f8e3 0%, #12cd88 80.24%);
}
.people_flex {
  color: #333;
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: 60px;
}
.studen_attendance_box {
  margin-top: 10px;
}
.m_c_s {
  left: 80px;
}
.schedulel_bj {
  width: 150px;
  height: 8px;
  position: absolute;
  top: 0px;
  left: 80px;
  background: #e2eefe;
}
.details_text {
  position: absolute;
  top: 0px;
  right: -38px;
  cursor: pointer;
  color: #3498db;
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}
.full_shift_rate {
  position: absolute;
  right: -5px;
  color: #333;
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}
.class_record_module {
  margin-top: 50px;
}
.record_course_cancellation {
  position: absolute;
  top: -54px;
  display: flex;
  width: 230px;
  justify-content: space-between;
  margin-top: 18px;
}
.course_flex {
  width: 115px;
  line-height: 30px;
  border: 1px solid #ececec;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.course_record {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.course_cancellations {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.courseOpt {
  color: #3498db;
  border-color: #3498db;
  background-color: rgba(52, 152, 219, 0.15);
  z-index: 2;
}
.general_class_alter {
  background: url(../../../../../public/image/edit_icon.png) no-repeat 0 !important;
  background-size: 15px !important;
}
.echart_big_round {
  position: absolute;
  width: 161px;
  height: 161px;
  background: #fff;
  border-radius: 50%;
  left: -44px;
  top: -9px;
  box-shadow: rgba(0, 117, 255, 0.12) 1px 1px 4px 1px;
  animation-name: echart_example;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}
.echart_small_round {
  position: absolute;
  width: 83px;
  height: 83px;
  background: #fff;
  border-radius: 50%;
  left: -4px;
  top: 30px;
  box-shadow: rgba(0, 117, 255, 0.12) 1px 1px 4px 1px;
  animation-name: echart_example;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
@keyframes echart_example {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>