<template>
  <div class="mainbox">
    <!-- 课效数据解读 -->
    <teacher-report></teacher-report>
    <!-- 透视已读家长 -->
    <parent-read></parent-read>
    <!-- 透视转发家长 -->
    <parent-share></parent-share>
  </div>
</template>

<script>
import teacherReport from './teacher-report';
import parentRead from './parent-read';
import parentShare from './parent-share';
export default {
  name: 'readShareReport',
  data () {
    return {
    };
  },
  components: {
    teacherReport,
    parentRead,
    parentShare
  },
  created () {
  },
  methods: {
  }
};
</script>

<style scoped>
.mainbox {
  padding: 20px 0;
}
.mainbox .modulebox {
  width: 1300px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 30px 20px;
  margin: 0 auto 20px;
}
.mainbox .modulebox >>> .chartbox {
  position: relative;
  float: left;
  width: 360px;
}
.mainbox .modulebox >>> .chartbox .tit .text {
  float: left;
  height: 30px;
  line-height: 30px;
  font-size: 20px;
  font-weight: bold;
}
.mainbox .modulebox >>> .chartbox .tit .title_date {
  position: relative;
  width: 100px;
  margin-left: 20px;
}
.mainbox .modulebox >>> .chartbox .tit .title_date:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #ccc;
  right: 6px;
  top: 14px;
}
.mainbox .modulebox >>> .chartbox .tit .title_date .el-input__inner {
  padding: 0 10px;
  cursor: pointer;
}
.mainbox .modulebox >>> .chartbox .tit .setup {
  float: right;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #3498db;
  cursor: pointer;
  margin-right: 20px;
}
.mainbox .modulebox >>> .chartbox .echartbox {
  height: 360px;
}
.mainbox .modulebox >>> .listbox {
  float: left;
  width: 900px;
}
.mainbox .modulebox >>> .listbox .roomlist .block {
  float: left;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #f5f7fa;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 5px 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.mainbox .modulebox >>> .listbox .roomlist .block div {
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  color: #666666;
}
.mainbox .modulebox >>> .listbox .roomlist .block p {
  height: 30px;
  line-height: 30px;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}
.mainbox .modulebox >>> .listbox .roomlist .block.cur {
  border: 1px solid #3498db;
  padding: 4px 19px;
}
.mainbox .modulebox >>> .listbox .roomlist .block.cur div {
  color: #3498db;
}
.mainbox .modulebox >>> .listbox .roomlist .block.cur p {
  color: #3498db;
}
.mainbox .modulebox >>> .listbox .formbox {
  position: relative;
  padding: 10px 0;
}
.mainbox .modulebox >>> .listbox .formbox .text {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  color: #666666;
  margin-right: 10px;
}
.mainbox .modulebox >>> .listbox .formbox .el-select {
  display: inline-block;
  width: 300px;
  height: 36px;
  margin-right: 20px;
}
.mainbox .modulebox >>> .listbox .formbox .el-select input.el-input__inner {
  font-size: 14px;
  border: 1px solid #ececec;
  border-radius: 4px;
}
.mainbox .modulebox >>> .listbox .formbox .tip {
  display: inline-block;
  line-height: 36px;
  font-size: 12px;
  color: #999999;
}
.mainbox .modulebox >>> .listbox .formbox .tip span {
  color: #ff6c67;
  margin-right: 10px;
}
.mainbox .modulebox >>> .listbox .formbox > .el-input {
  display: inline-block;
  width: 300px;
  height: 36px;
  margin-right: 20px;
}
.mainbox .modulebox >>> .listbox .formbox .btn {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 10px;
  cursor: pointer;
}
.mainbox .modulebox >>> .listbox .formbox .btn.btn_1 {
  color: #ffffff;
  background-color: #2d98d9;
  border: 1px solid #2886bf;
  padding: 0 20px;
}
.mainbox .modulebox >>> .listbox .formbox .btn.btn_2 {
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #ececec;
  padding: 0 10px;
}
.mainbox .modulebox >>> .listbox .formbox .btn.btn_3 {
  position: absolute;
  color: #ffffff;
  background-color: #2d98d9;
  border: 1px solid #2886bf;
  padding: 0 20px;
  margin: 0;
  right: 0;
  top: 10px;
}
.mainbox .modulebox >>> .listbox .course_statistics_table_box {
  padding: 0;
}
</style>