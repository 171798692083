<template>
  <!-- 跟进表单 -->
  <div class="">
    <div class="all_workbench_center_form box_withe_radius">
      <div class="all_workbench_title">
        <div class="title_text">
          {{followUpInfo.TypeKey==1?'首次跟单':'添加跟进记录'}}
        </div>
      </div>
      <!-- 首次跟单 -->
      <div class="form_box"
           v-if="followUpInfo.TypeKey==1">
        <first-record-form :dataInfo="dataInfo"
                           ref="firstRecord"></first-record-form>
      </div>

      <div class="form_box"
           v-else-if="followUpInfo.TypeKey>1&& dataInfo.AttractInvestKey">
        <add-record-form :dataInfo="dataInfo"
                         ref="addRecord"></add-record-form>
      </div>
      <!-- 提交 -->
      <div class="form_text_btn btn_hover_bg_blue"
           @click="confirm">确定
      </div>
    </div>
    <!-- 招商顾问信息 -->
    <!-- <investor-info-form :dataInfo="merchantsDataInfo"
                        @afterSuccess="afterSuccessUpData"></investor-info-form> -->
  </div>
</template>

<script>
// import investorInfoForm from './investor-info-form';
import firstRecordForm from './first-record-form';
import addRecordForm from './add-record-form';
import { hqAddAttractFollowRecord } from '../../../../../../API/workbench';
const Data = {
  Content: '', // 跟进内容
  NextFollowTime: '', // 下次跟进日期
  TypeKey: '', // 跟进类型
  TypeKeyValue: '', // 1-首次跟进;2-日常跟进;3-预约到店;4-到店考察;5-档案完善;6-签约;7-付款;9-放弃;10-无效线索
  IntentionKey: '', // 意向级别
  IntentionKeyValue: '', // 1-S;2-A;3-B;4-C
  AppointmentTime: '', // 预约日期
  AttractInvestKey: '', // 招商线索key
  AttractInvestKeyValue: '', // 招商线索key
  IsAnswerKey: -1, // 是否接听
  IsAnswerKeyValue: '', // 是否接听
  WeChatStatusKey: -1, // 是否通过微信好友申请
  WeChatStatusKeyValue: '' // 是否通过微信好友申请
};
export default {
  components: {
    // investorInfoForm,
    firstRecordForm,
    addRecordForm
  },
  props: {
    merchantsDataInfo: Object, // 招商顾问信息
    followUpInfo: {// 选择项列表
      type: Object,
      default: function () {
        return {
          TypeKey: 1, // 1-首次跟进;2-日常跟进;3-预约到店;4-到店考察;8-放弃;（必填）
          NextFollowTime: '', // 跟进日期（可选）
          AttractInvestKey: '', // 招商线索key （必填）
          AttractInvestKeyValue: '' // 招商线索（必填）
        };
      }
    }

  },
  data () {
    return {
      isReadonly: false, // 是否只读
      lastClickTime: null, // 防止重复点击
      dataInfo: {},
      typeList: [
        { id: 1, name: '首次跟进' },
        { id: 2, name: '日常跟进' },
        { id: 3, name: '预约到店' },
        { id: 4, name: '到店考察' },
        { id: 9, name: '放弃' }
      ]
    };
  },
  computed: {

  },
  watch: {
    'followUpInfo.AttractInvestKey' (n, o) {
      console.log('监听招商顾问', this.followUpInfo);
      if (n && n != o) {
        this.initData();
      }
    }
  },
  created () {
    this.initData();
  },
  methods: {
    initData () {
      this.dataInfo = this.$utils.parseJson(Data);
      this.showType();
    },
    showType () {
      Object.assign(this.dataInfo, this.followUpInfo);
      let findItem = this.typeList.find(o => {
        return this.followUpInfo.TypeKey == o.id;
      });
      this.dataInfo.TypeKeyValue = findItem.name;
    },
    // 提交
    confirm () {
      console.log(this.dataInfo, '提交');
      if (this.checkCanClick()) {
        let res = this.verificationInfo();
        if (!res) {
          return false;
        }
        hqAddAttractFollowRecord(this.dataInfo).then((result) => {
          console.log(result.data, '添加跟进表单成功');
          if (this.dataInfo.TypeKey == 1 && this.dataInfo.IsAnswerKey == 0) { // 未接通
            this.dataInfo = this.$utils.parseJson(Data);
            this.followUpInfo.TypeKey = 1;
            if (this.$refs.firstRecord) {
              this.$refs.firstRecord.initData();
            }
          } else { // 已接通或已跟进
            this.dataInfo = this.$utils.parseJson(Data);
            this.followUpInfo.TypeKey = 2;
            this.dataInfo.TypeKey = 2;
            this.dataInfo.TypeKeyValue = '日常跟进';
            if (this.$refs.addRecord) {
              this.$refs.addRecord.initData();
            }
          }
          this.showType();
          this.afterSuccessUpData(result.data);
          layer.alert('添加成功');
        }, (error) => {
          layer.alert(error.msg);
        });
      }
    },
    // 更新数据
    afterSuccessUpData (data) {
      this.$emit('afterSuccess', data);
    },
    // 验证 1-首次跟进;2-日常跟进;3-预约到店;4-到店考察;8-放弃;
    verificationInfo () {
      let flag = true;
      if (this.dataInfo.TypeKey == 3 && !this.dataInfo.AppointmentTime) {
        layer.alert('请选择预约日期。');
        flag = false;
      }
      return flag;
    },
    // 防止快速点击
    checkCanClick () {
      let now = Date.new();
      let r = !this.lastClickTime || now - this.lastClickTime > 500;
      if (r) {
        this.lastClickTime = now;
      }
      return r;
    }
  }
};
</script>

<style>
.all_workbench_center_form {
  width: 370px;
  height: 680px;
  margin-bottom: 20px;
}
.box_withe_radius {
  background-color: #fff;
  border-radius: 8px;
}
.all_workbench_title {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 20px;
  height: 60px;
  border-bottom: 1px solid #ececec;
}
.all_workbench_title .title_text {
  font-size: 18px;
  line-height: 24px;
}
.all_workbench_center_form .form_box {
  margin: 16px 12px 0px 16px;
}
.all_workbench_center_form .form_info_line {
  border-bottom: none;
  padding: 0px 0;
  min-height: 45px;
}
.all_workbench_center_form .mecism .form_info_field {
  line-height: 18px;
}
.all_workbench_center_form .form_info_value input,
.all_workbench_center_form .input_readonly.form_info_value {
  width: 100%;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #ececec !important;
  text-align: left;
  padding-left: 12px !important;
}
.all_workbench_center_form .input_readonly.form_info_value {
  margin-left: 25px !important;
  color: #333333 !important;
}
.all_workbench_center_form .form_text_title {
  font-size: 14px;
  line-height: 40px;
  color: #666666;
}
.all_workbench_center_form .form_textarea {
  width: 330px;
  height: 120px;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 4px;
}
.all_workbench_center_form .form_textarea textarea {
  width: 100%;
  height: 100%;
}
.form_text_btn {
  width: 76px;
  height: 35px;
  text-align: center;
  background: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  line-height: 33px;
  text-align: center;
  margin-top: 20px;
  margin-left: 265px;
}
.all_workbench_center_form .form_info_line .form_info_value {
  padding-left: 0px;
  padding-top: 5px;
}
.all_workbench_center_form .form_info_line .form_info_value .el-input__suffix {
  left: auto;
}
.all_workbench_center_form .form_info_select .el-select .el-input__suffix {
  display: block;
}
.all_workbench_center_form .date_icon .el-input__inner {
  height: 100%;
  border: none;
  text-align: left;
  padding-left: 20px;
  font-size: 12px;
  height: 34px;
  background: url(../../../../../../../public/image/calendar_icon.png) 280px
    center no-repeat;
}
.all_workbench_center_form .form_info_required::before {
  top: 0px;
}
.all_workbench_center_form .form_info_line .form_info_value .readonly_color {
  color: #333333 !important;
}
.all_workbench_center_form .form_box .form_info_line {
  display: block;
}
.all_workbench_center_form .form_box .form_info_line .form_info_field {
  height: 20px;
  line-height: 14px;
}
</style>