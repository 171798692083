<template>
  <div class="new_course_weekList">
    <div class="weekList_date">
       <div v-if="classTimeData.EndTypeKey!=1">{{classTimeData.CircleStartTime}}~{{classTimeData.CircleEndTime}} ({{classTimeData.ClassCount}}节)</div>
      <div v-else>{{classTimeData.CircleStartTime}}~不结束</div>
      <div class="weekList_date_btn"
           @click.stop="deleteClick">删除</div>
    </div>
    <div class="weekList_week">
      <span>{{vWeekList}}</span>
      <span>{{classTimeData.TimeQuantum}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classTimeData: Object
  },
  computed: {
    vWeekList () {
      this.classTimeData.weekListData.sort((x, y) => { return x.WeekKey - y.WeekKey; });
      let weekStr = '每周';
      this.classTimeData.weekListData.forEach((obj, index) => {
        let weekName = this.$utils.getWeekByNum(obj.WeekKey);
        if (index == this.classTimeData.weekListData.length - 1) {
          weekStr = weekStr + weekName;
        } else {
          weekStr = weekStr + weekName + '、';
        }
      });
      return weekStr;
    }
  },
  methods: {
    deleteClick () {
      this.$emit('onDeleteClick', this.classTimeData);
    }
  }
};
</script>

<style>
</style>