<template>
  <div @click.stop="doClick">
    <div>
      <div round>
        {{vBtnText}}
      </div>
      <span :class="vBtnClass"></span>
    </div>
  </div>
</template> 

<script>

export default {
  data () {
    return {
      defaultBtnText: '确认',
      defaultBtnClass: 'btn_light_blue btn_hover_bg_blue',
      lastClickTime: null,
      isSubmiting: false,
      isClick:true
    };
  },
  props: {
    btnText: String,
    btnType: String, // 自定义标题名
    btnClass: String, // 自定义搜索名
    isSingleSubmit: {
      type: Boolean,
      default: false
    }, // 是否启用单次点击
    singleSubmitType: {
      type: String,
      default: 'static'
    } // static（根据两次点击的时间间隔）, dynamic（提交完成后 手动更改参数）
  },
  components: {

  },
  computed: {
    vBtnClass () {
      return this.btnClass || this.defaultBtnClass;
    },
    vBtnText () {
      return this.btnText || this.defaultBtnText;
    }
  },
  created () {
  },
  methods: {
    doClick () {
       if(this.isClick){
          this.isClick=false
          setTimeout(o=>{
            this.isClick=true
          },2000)
          if (!this.isSingleSubmit || this.checkCanClick()) {
            this.$emit('click', () => {
              this.isSubmiting = false;
            });
          }
        
        }else{
          layer.alert('请勿重复点击')
          // this.$showModal({
          //   content: '请勿重复点击'
          // });
        }
      // if (!this.isSingleSubmit || this.checkCanClick()) {
      //   this.$emit('click', () => { this.isSubmiting = false; });
      // }
    },
    completeSubmit () {
      this.isSubmiting = false;
    },
    checkCanClick () {
      if (this.singleSubmitType == 'static') {
        let now = Date.new();
        console.log('now - this.lastClickTime', now - this.lastClickTime);
        let r = !this.lastClickTime || now - this.lastClickTime > 800;
        if (r) {
          this.lastClickTime = now;
        }
        console.log('static: checkCanClick', r);
        return r;
      } else {
        let r = !this.isSubmiting;
        this.isSubmiting = true;
        console.log('dynamic: checkCanClick', r);
        return r;
      }
    }
  }
};
</script>

  <style>
</style>