<template>
  <!-- 设备授权 -->
  <div class="table_nav_box">
    <div class="table_fixed_nav tltle_margin">
      <div v-for="item in titleList"
           :key="item.key"
           @click="chgTitleList(item)"
           class="table_fixed_nav_list"
           :class="{opt:item.isSelected}">

        <div class="nav_list_big">
          <div class="img_name">
            <!-- 授权设备 -->
            <div v-if="item.key == 1"
                 class="authorize_div">
              <div class="authorize_item">
                {{item.name}}
              </div>
              <span class="disable_false">{{item.AuditDeviceNum}}</span>
            </div>
            <!-- PC APP TV -->
            <div v-else
                 class="authorize_div">
              <div class="authorize_item">
                <img :src="item.url"
                     class="icon_img" />{{item.name}}
              </div>
              <!-- 是否禁用状态 -->
              <div v-if="item.IsAuthKey == 0"
                   class="disable_true">禁用</div>
              <div v-else
                   class="disable_false">
                <!-- 是否定位有限 -->
                <span v-if="item.AuthorizeDeviceNum == 0">不限</span>
                <span v-else>{{item.UseDeviceNum}}/{{item.AuthorizeDeviceNum}}</span>
                <img :src="item.IsLocationLockKey==1?lockUrl:allwayUrl"
                     class="location_img" />
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      lockUrl: require('../../../../public/image/lock.png'),
      allwayUrl: require('../../../../public/image/all-way.png')
    };
  },
  props: {
    titleList: Array
  },
  created () {
    console.log(this.titleList, 'this.titleList');
  },
  methods: {
    // 切换授权
    chgTitleList (item) {
      this.titleList.forEach(o => {
        if (item.key == o.key) {
          o.isSelected = true;
        } else {
          o.isSelected = false;
        }
      });
      this.$emit('chgTitleList', item);
    }
  },
  computed: {
  }
};
</script>
<style scoped>
.table_fixed_nav {
  width: 820px !important;
  margin: 0px auto;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 15px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
.table_fixed_nav_list {
  line-height: 70px;
  height: 70px;
  font-size: 14px;
  padding: 0 15px;
  text-align: center;
  cursor: pointer;
  color: #999;
  width: 205px;
}
.opt {
  color: #3498db !important;
}
.authorize_item {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
}
.disable_false {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
}
.nav_list_big {
  display: flex;
  justify-content: space-around;
  line-height: 25px;
  margin-top: 15px;
  border-right: 1px solid #ececec;
  width: 200px;
  margin-left: -10px;
  height: 45px;
  font-weight: 600;
}
.table_fixed_nav_list:nth-of-type(4) .nav_list_big {
  border-right: none;
}
.table_fixed_nav .table_fixed_nav_list:nth-of-type(1) .authorize_item {
  font-family: 'Alibaba PuHuiTi' !important;
  font-style: normal;
}
.table_fixed_nav .table_fixed_nav_list:nth-of-type(3) .disable_false {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
}
.icon_img {
  margin: -2px 5px 0 0;
  width: 15px;
}
.location_img {
  width: 9px;
  margin-top: -3px;
}
.disable_false {
  color: #333;
}
.opt .disable_false {
  color: #3498db;
}
.disable_true {
  color: red;
}
</style>
