<template>
  <!-- 课件包表单 -->
  <div style="min-height: 680px;">

    <div class="form_info_edit form_info_list border_bottom">
      <input-text :formTitle="'课包名称'"
                  :required="true"
                  :readonly="isReadonly"
                  :customPlaceholder="true"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.CoursewarePackageName"></input-text>

      <input-text :formTitle="'课包封面'"
                  :required="true"
                  :readonly="true"
                  :formPlaceholder="''"
                  :helpDescription="'上传图片大小不超过8MB，类型格式为.jpg,.jpeg,.png。'"
                  class="no_border_bottom"
                  :value="''"></input-text>
      <div class="courseware_form_upload_box"
           :class="dataInfo.CoverUrl ? 'show_Img' : ''">
        <div class="courseware_form_upload_noImg"
             v-if="!dataInfo.CoverUrl">
          <div class="upload_icon">+</div>
          <div>上传图片</div>
        </div>
        <div id="screanContent"
             style="z-index:1;position: absolute !important;right: 45px; opacity: 0"></div>
        <input-upload-img :ImgURl="dataInfo.CoverUrl"
                          :formTitle="''"
                          :imgHeight="360"
                          :imgWidth="640"
                          :readonly="isReadonly"
                          :acceptType="'image/*'"
                          @doSetPicCorp="doSetPicCorpHeadImg"></input-upload-img>
      </div>
      <div class="input_readonly form_info_value"
           style="text-align: center;padding-bottom: 10px;border-bottom: 1px solid #ececec;">
        上传图片尺寸为 640*360px(16:9)
      </div>

      <!-- <input-number
        :formTitle="'学期时长（月）'"
        :required="false"
        :readonly="isReadonly"
        :formPlaceholder="'请输入'"
        v-model="dataInfo.TermDurationNum"
      ></input-number> -->

      <input-select :formTitle="'目录层级'"
                    :id="'type'"
                    :name="'name'"
                    :required="false"
                    class=""
                    :readonly="coursewareId > 0 ? true : isReadonly"
                    :dataList="catalogueList"
                    :selectedInfo="vLevelCount"
                    @selectedItem="changeAlertWayType"></input-select>
      <div class="pr"
           style="border-bottom: 1px solid #ececec;">
        <div class=" form_info_line no_border">
          <div class=" form_info_field">
            <span>课包介绍</span>
            <span>
              <input-form-tip :tips="'上传图片大小不超过8MB，类型格式为.jpg,.jpeg,.png。上传pdf大小不超过20MB，类型格式为.pdf。'"></input-form-tip>
            </span>
          </div>
          <div class=" form_info_value ">
            <span class="upload_btn font_blue"
                  @click="isShowOperateMenu = true">
              上传
            </span>
          </div>
        </div>

        <div class="list_img_ul"
             v-show="isShowOperateMenu">
          <!-- 大课包按钮显示 -->
          <div class="list_img_menu hover pr"
               v-for="(item, key) in operateMenu"
               :key="key">
            <input @change="chooseFiles($event,item)"
                   class="upload_input"
                   :ref="'fileInput'+item.type"
                   type="file"
                   :accept="item.key" />
            <!-- <input-upload-file
              :formTitle="''"
              :readonly="isReadonly"
              :acceptType="item.key"
              :type="item.type"
              @UploadFilesAfter="UploadFilesAfter"
            ></input-upload-file> -->
            {{ item.name }}
          </div>
        </div>
        <div class="other_attach_list"
             v-if="vCwIntroduceList.length > 0">
          <other-attch-item v-for="(item, key) in vCwIntroduceList"
                            :key="key"
                            :item="item"
                            @clickEvent="deleteItem"></other-attch-item>
        </div>
      </div>
      <div class="pr"
           style="border-bottom: 1px solid #ececec;">
        <div class=" form_info_line no_border">
          <div class=" form_info_field">
            <span>附件下载</span>
            <input-form-tip :tips="'附件上传大小不能超过100MB'"></input-form-tip>
          </div>
          <div class=" form_info_value ">
            <span class="upload_btn font_blue">
              上传
              <input @change="chooseFiles($event)"
                     class="upload_input"
                     ref="otherfileInput"
                     type="file"
                     :accept="vAcceptType" />
            </span>
          </div>
        </div>

        <div class="other_attach_list"
             v-if="vCwOtherList.length > 0">
          <other-attch-item v-for="(item, key) in vCwOtherList"
                            :key="key"
                            :item="item"
                            @clickEvent="deleteItem"></other-attch-item>
        </div>
      </div>
      <div class="pr"
           style="border-bottom: 1px solid #ececec;">
        <input-dialog class="no_border_bottom"
                      :formTitle="'关联课件'"
                      :formPlaceholder="''"
                      :required="false"
                      :readonly="isReadonly"
                      :value="''"
                      @showEldilog="showAddCW"></input-dialog>
        <div class="other_attach_list"
             v-if="dataInfo.CoursewareRelevanceList.length > 0">
          <courseware-item v-for="(item, key) in dataInfo.CoursewareRelevanceList"
                           :key="key"
                           :item="item"
                           @clickEvent="deleteItemForCW"></courseware-item>
        </div>
      </div>

      <input-switch v-model="CoverIsDownload"
                    :formTitle="'封面下载'"
                    :showInputValue="false"
                    :className="'no_border'"
                    :required="false"></input-switch>
    </div>
    <div v-if="!isReadonly">
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
    <custom-dialog :title="'选择课包'"
                   :visible.sync="showChooseCourseware"
                   :before-close="closeDialog">
      <choose-courseware :seletedCWList="dataInfo.CoursewareRelevanceList"
                         @afterSuccess="selectedCW"
                         @cancelClick="closeDialog"></choose-courseware>
    </custom-dialog>
  </div>
</template>
<script>
import {
  hqAddParentCourseWarePackage, // 新增大课包
  hqEditParentCourseWarePackage, // 修改大课包
  CWSEditParentCourseWarePackage,
  // hqDeleteParentCourseWarePackage,//删除大课包
  hqCoursewarePackageManageGetDetail, // 详情
  CWSCoursewarePackageManageGetDetail,
  CWSAddParentCourseWarePackage,
  AddParentCourseWarePackage,
  EditParentCourseWarePackage,
  // DeleteParentCourseWarePackage
  CoursewarePackageManageGetDetail
} from "../../../API/workbench";
import { uploadMultipartHQ } from '../../../utils/chunkedUpload';
import otherAttchItem from "./other-attch-item.vue";
import coursewareItem from "./courseware-item.vue"
import ChooseCourseware from "./choose-courseware/index";
export default {
  data () {
    return {
      // 直营类型
      sourceType: [
        { type: 1, name: "警告提示" },
        { type: 2, name: "自动禁用" }
      ],
      catalogueList: [
        { type: 2, name: "二级" },
        { type: 3, name: "三级" }
      ],
      locationInfo: [], // 地址
      dataInfo: {
        CoursewarePackageName: "", //	课件包名称
        CoverUrl: "", // 课件包封面
        TermDurationNum: "", // 学期时长（月）
        ArrangedAlertNum: "", // 排课警戒课次
        AlertWayKey: 1, // 排课警戒方式 1：警告提示 2：自动禁用
        AlertWayKeyValue: "警告提示",
        LevelCount: 2, //	整型	可选	3	数据源：GET	层级数
        CoursewarePackageParent: 0, //关联课件包
        CoursewarePackageParentValue: "",
        CoverIsDownload: 0, //是否支持下载封面
        CoursewarePackageAttach: [],
        // [{"AttachUrl" : "课包附件",
        //     "AttachSize":"附件大小",
        //     "AttachTypeKey":"附件类型 1-图片;2-视频;3-音频;4-PPT;5-PDF;6-ZIP;7-excel;8-word",
        //     "TypeKey":"1-课包介绍2-附件下载"
        //     "MainDemoName":"标题"
        //   }]
        CoursewareRelevanceList: []

      },
      CoverIsDownload: false,
      isShowOperateMenu: false,
      operateMenu: [
        {
          name: "上传图片",
          className: "",
          key: ".jpg,.jpeg,.png",
          isShow: true,
          type: 1
        },
        { name: "上传PDF", className: "", key: ".pdf", isShow: true, type: 5 }
      ],
      showChooseCourseware: false
    };
  },
  components: {
    otherAttchItem,
    ChooseCourseware,
    coursewareItem
  },
  props: {
    coursewareId: [Number, String],
    isReadonly: Boolean
  },
  created () {
    if (this.coursewareId > 0) {
      this.getDetailInfo();
    }
    console.log(this.coursewareId, "coursewareId");
  },
  mounted () {
    document.querySelector("body").addEventListener(
      "click",
      e => {
        this.isShowOperateMenu = false;
      },
      true
    );

    this.$dialog.register(this, ["singleSelectionList"]);
  },
  computed: {
    // 课包介绍list
    vCwIntroduceList () {
      return this.dataInfo.CoursewarePackageAttach.filter(o => {
        return o.TypeKey == 1;
      });
    },
    // 课包介绍list
    vCwOtherList () {
      return this.dataInfo.CoursewarePackageAttach.filter(o => {
        return o.TypeKey == 2;
      });
    },
    vAcceptType () {
      // 其他附件
      return ".mp4,.pdf,.zip,.jpg,.jpeg,.png,.mp3,.docx,.ppt,.xls,.doc,.txt,.xlsx,.rar,.pptx,.pps,.ppsx";
    },
    // 模式
    vTypeKey () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) {
        // 总部key为6
        return true;
      } else {
        return false;
      }
    },
    // 结算方式
    vLevelCount () {
      return {
        type: this.dataInfo.LevelCount,
        name: this.dataInfo.LevelCount == 2 ? "二级" : "三级"
      };
    },
    vMenDianInfo () {
      return this.$store.getters.SaaSClientInfo.EditionKey;
    }
  },
  methods: {
    chooseFiles (e, item) {
      console.log("item", item)
      let form = e.target.files[0];
      let fileName = e.target.files[0].name;
      // console.log('e.target.files[0]', fileName, this.vAcceptType);
      // console.log('VisitAuthKey', this.VisitAuthKey);
      let typeText = this.vAcceptType;
      let mbSize = 1048576;
      if (item) {
        typeText = item.key
      } else {
        typeText = this.vAcceptType;
      }
      if (
        typeText.indexOf(
          fileName
            .toLowerCase()
            .substring(fileName.toLowerCase().lastIndexOf("."), fileName.length)
        ) == -1
      ) {
        this.$refs.otherfileInput.value = null
        this.$refs.fileInput1[0].value = null
        this.$refs.fileInput5[0].value = null
        layer.alert("上传文件类型不符合，只能上传" + typeText + "类型文件");
        return false;
      } else if (item) {
        if (item.type == 1 && form.size > mbSize * 8) {

          this.$refs.otherfileInput.value = null
          this.$refs.fileInput1[0].value = null
          this.$refs.fileInput5[0].value = null
          layer.alert('上传图片大于8MB，无法上传');
          return false;

        } else if (item.type == 5 && form.size > mbSize * 20) {

          this.$refs.otherfileInput.value = null
          this.$refs.fileInput1[0].value = null
          this.$refs.fileInput5[0].value = null
          layer.alert('上传PDF大于20MB，无法上传');
          return false;

        } else {

          let formData = new FormData();
          formData.append("file", form, form.name);
          this.otherAttachTypeUpload(formData, form, item);
        }
      } else {
        this.$refs.otherfileInput.value = null
        this.$refs.fileInput1[0].value = null
        this.$refs.fileInput5[0].value = null

        if (form.size > mbSize * 100) {
          layer.alert("上传附件大于100MB，无法上传");
          return false;
        }

        let DurationNum = 0;
        let formData = new FormData();
        formData.append("file", form, form.name);
        // if(item){

        // }else{
        this.otherAttachTypeUpload(formData, form, item);
        // }

        // }
      }
    },
    closeDialog () {
      this.showChooseCourseware = false;
    },
    selectedCW (dataList) {
      console.log('selectedCW', dataList)
      this.dataInfo.CoursewareRelevanceList = this.dataInfo.CoursewareRelevanceList || []
      dataList.forEach(o => {
        let obj = {
          MainDemoName: o.MainDemoName,
          OLAPKey: o.OLAPKey,
        }
        let locaIndex = this.dataInfo.CoursewareRelevanceList.findIndex(p => {
          return p.OLAPKey == obj.OLAPKey
        })
        if (locaIndex == -1) {
          this.dataInfo.CoursewareRelevanceList.push(obj)
        }
      })
      console.log(" this.dataInfo", this.dataInfo)
      //  this.dataInfo.CoursewareRelevanceList=dataList
      this.showChooseCourseware = false;
    },
    showAddCW () {
      this.showChooseCourseware = true;
    },
    // 选择警戒方式
    changeAlertWayType (obj) {
      console.log(obj, "选择结算方式");
      this.dataInfo.LevelCount = obj.type;
    },
    UploadFilesAfter (url, name, file, acceptType, ShowOrder) {
      console.log(url, name);
      console.log("file", file);
      console.log("acceptType", acceptType);
      this.isShowOperateMenu = false;

      let obj = {
        AttachUrl: url || '',
        AttachSize: file.size,
        AttachTypeKey: acceptType == 1 ? 1 : 5,
        ShowOrder:
          this.dataInfo.CoursewarePackageAttach.length > 0
            ? Number(this.dataInfo.CoursewarePackageAttach[
              this.dataInfo.CoursewarePackageAttach.length - 1
            ].ShowOrder) + 1
            : 1,
        // '',//"附件类型 1-图片;2-视频;3-音频;4-PPT;5-PDF;6-ZIP;7-excel;8-word",
        TypeKey: 1, //"1-课包介绍2-附件下载",
        MainDemoName: name
      };
      this.dataInfo.CoursewarePackageAttach.push(obj);
      console.log(
        "CoursewarePackageAttach",
        this.dataInfo.CoursewarePackageAttach
      );
    },
    deleteItemForCW (item) {
      let locaIndex = this.dataInfo.CoursewareRelevanceList.findIndex(o => {
        return o.OLAPKey == item.OLAPKey;
      });
      if (locaIndex != -1) {
        this.dataInfo.CoursewareRelevanceList.splice(locaIndex, 1);
      }
    },
    deleteItem (item) {
      let locaIndex = this.dataInfo.CoursewarePackageAttach.findIndex(o => {
        return o.ShowOrder == item.ShowOrder && o.TypeKey == item.TypeKey;
      });
      console.log(locaIndex, 'deleteItem')
      if (locaIndex != -1) {
        this.dataInfo.CoursewarePackageAttach.splice(locaIndex, 1);
      }
    },
    // 上传文件类型 1-图;2-视频; 3-音频; 4-文本图; 5-字体文件;6-文档;7-证书;8-ppt文件;9-pdf文件;10-Zip文件;11-Docx文档文件;12-xls表格文件;
    setAcceptType (attachType) {
      if (attachType == "1") {
        return "1";
      } else if (attachType == "2") {
        // 视频
        return "2";
      } else if (attachType == "3") {
        return "3";
      } else if (attachType == "4") {
        return "8";
      } else if (attachType == "5") {
        // PDF
        return "9";
      } else if (attachType == "6") {
        // Zip
        return "10";
      } else if (attachType == "7") {
        // xls
        return "12";
      } else if (attachType == "8") {
        // Docx
        return "11";
      } else {
        return "1";
      }
    },
    otherAttachTypeUpload (formData, file, item) {
      file.MainDemoName = file.name;
      file.DurationNum = file.size;
      file.SizeNum = file.size;
      file.AttachTypeKey = 0;
      console.log("otherAtt item  ", item);
      console.log("file", file);
      let fileType = file.name.split(".")[file.name.split(".").length - 1];
      // console.log(fileType.toLowerCase(), '文件类型');
      //  1-图片;2-视频;3-音频;4-PPT;5-PDF;6-ZIP;7-excel;8-word
      switch (fileType.toLowerCase()) {
        case "jpg":
        case "jpeg":
        case "png":
          file.AttachTypeKey = 1;
          break;
        case "mp4":
          file.AttachTypeKey = 2;
          break;
        case "mp3":
          file.AttachTypeKey = 3;
          break;
        case "ppt":
        case "pptx":
        case "pps":
        case "ppsx":
          file.AttachTypeKey = 4;
          break;
        case "pdf":
          file.AttachTypeKey = 5;
          break;
        case "zip":
        case "rar":
          file.AttachTypeKey = 6;
          break;
        case "xls":
        case "xlsx":
          file.AttachTypeKey = 7;
          break;
        case "docx":
        case "doc":
        case "txt":
          file.AttachTypeKey = 8;
          break;
        default:
          break;
      }
      let parameter = {
        type: '1',
        CoursewareID: 0,
        AuthorizeCode: 0,
        coursewareType: !item ? 3 : 1,
        MainDemoName: file.MainDemoName,
        VisitAuthKey: 0,
        IsUpdateFile: 0
      };
      let TypeKey = this.setAcceptType(file.AttachTypeKey);
      uploadMultipartHQ(formData, TypeKey, file, parameter).then(
        result => {
          console.log("result.data", result)
          console.log("item After", item)
          if (item) {
            this.$refs.otherfileInput.value = null
            this.$refs.fileInput1[0].value = null
            this.$refs.fileInput5[0].value = null
            this.UploadFilesAfter(
              result.data.url,
              file.name,
              file,
              file.AttachTypeKey)
          } else {
            this.$refs.otherfileInput.value = null
            this.$refs.fileInput1[0].value = null
            this.$refs.fileInput5[0].value = null
            this.UploadFilesForOtherAttachAfter(
              result.data.url,
              file.name,
              file,
              file.AttachTypeKey
            );
          }
          console.log(result.data.url, "11111");
        },
        err => {
          this.$refs.otherfileInput.value = null
          this.$refs.fileInput1[0].value = null
          this.$refs.fileInput5[0].value = null
          console.log("error", err);
          layer.alert(err.msg);
        }
      );
    },
    UploadFilesForOtherAttachAfter (url, name, file, TypeKey) {
      console.log(url, name);
      console.log("file", file);
      console.log("acceptType", TypeKey);
      let obj = {
        AttachUrl: url || '',
        AttachSize: file.size,
        AttachTypeKey: TypeKey,
        ShowOrder:
          this.dataInfo.CoursewarePackageAttach.length > 0
            ? Number(this.dataInfo.CoursewarePackageAttach[
              this.dataInfo.CoursewarePackageAttach.length - 1
            ].ShowOrder) + 1
            : 1,
        // '',//"附件类型 1-图片;2-视频;3-音频;4-PPT;5-PDF;6-ZIP;7-excel;8-word",
        TypeKey: 2, //"1-课包介绍2-附件下载",
        MainDemoName: name
      };
      this.dataInfo.CoursewarePackageAttach.push(obj);
      console.log(
        "CoursewarePackageAttach",
        this.dataInfo.CoursewarePackageAttach
      );
    },
    // 课件包封面地址
    doSetPicCorpHeadImg (Url) {
      console.log("课件包封面地址", Url);
      this.dataInfo.CoverUrl = Url;
    },
    // 获取授权详情信息
    getDetailInfo () {
      let apiFN = "";
      let type = this.$store.getters.SaaSClientInfo.EditionKey;
      if (type == 6) {
        apiFN = CWSCoursewarePackageManageGetDetail;
      } else if (type == 5) {
        apiFN = hqCoursewarePackageManageGetDetail;
      } else {
        apiFN = CoursewarePackageManageGetDetail;
      }
      apiFN(this.coursewareId).then(
        result => {
          Object.assign(this.dataInfo, result.data);
          this.dataInfo.AlertWayKeyValue =
            this.dataInfo.AlertWayKey == 1 ? "警告提示" : "自动禁用";
          this.CoverIsDownload = this.dataInfo.CoverIsDownload == 1;
          console.log("获取授权详情信息", this.dataInfo);
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 提交
    submit (callback) {
      console.log("submit", this.dataInfo);
      console.log(this.CoverIsDownload, "vMenDianInfo");
      this.dataInfo.CoverIsDownload = this.CoverIsDownload ? 1 : 0;
      const that = this;
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      let apiFN = "";
      // 修改
      if (this.coursewareId > 0) {
        let type = this.$store.getters.SaaSClientInfo.EditionKey;
        if (type == 6) {
          apiFN = CWSEditParentCourseWarePackage;
        } else if (type == 5) {
          apiFN = hqEditParentCourseWarePackage;
        } else {
          apiFN = EditParentCourseWarePackage;
        }
        apiFN(this.coursewareId, this.dataInfo)
          .then(
            result => {
              layer.alert("修改成功");
              this.$emit("afterSuccess", "edit", result.data);
              this.cancel();
            },
            error => {
              layer.alert(error.msg);
            }
          )
          .finally(() => {
            if (callback) {
              callback();
            }
          });
      } else {
        // 新增
        let type = this.$store.getters.SaaSClientInfo.EditionKey;
        if (type == 6) {
          apiFN = CWSAddParentCourseWarePackage;
        } else if (type == 5) {
          apiFN = hqAddParentCourseWarePackage;
        } else {
          apiFN = AddParentCourseWarePackage;
        }

        apiFN(this.dataInfo)
          .then(
            result => {
              layer.alert("新增成功");
              this.$emit("afterSuccess", "add", result.data);
              this.cancel();
            },
            error => {
              layer.alert(error.msg);
            }
          )
          .finally(() => {
            if (callback) {
              callback();
            }
          });
      }
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
      if (!this.dataInfo.CoursewarePackageName) {
        layer.alert("请输入课件包名称");
        flag = false;
      } else if (!this.dataInfo.CoverUrl) {
        layer.alert("请选择上传课件包封面");
        flag = false;
      } else {
        const reg = /^([\u4E00-\u9FA5A-Za-z0-9\uFF00-\uFFFF\u2026\u00D7\u00B7\u3010\u3011,\s`~!@#$%^&*()_。，——·‘’“”、。《》－；+<>?:"{},.\/;'[\]=\-|\\])+$/;
        //  /[\s\x21-\x7e//\u4e00-\u9fa5A-Za-z0-9_-]*/
        ///^[\u4e00-\u9fa5A-Za-z0-9_-]+[\)）（，；\(\.\%。\u4e00-\u9fa5A-Za-z0-9_-]?$/;
        const nonChineseCharacter = this.dataInfo.CoursewarePackageName;
        for (let i = 0; i < nonChineseCharacter.length; i++) {
          if (!reg.test(nonChineseCharacter.charAt(i))) {
            flag = false;
            layer.alert("课件包名称不能有特殊字符");
            return false;
          }
        }
      }
      return flag;
    },
    cancel () {
      this.$emit("close");
    }
  }
};
</script>
<style scoped>
.list_img_ul {
  position: absolute;
  right: -4px;
  top: 44px;
  width: 86px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.02), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  z-index: 99;
  border: 1px solid #ececec;
}
.list_img_menu {
  font-size: 14px;
  color: #666;
  text-align: center;
  line-height: 34px;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 13px center;
  border-bottom: 1px solid #ececec;
}
.list_img_menu >>> .form_info_line {
  position: absolute !important;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
.list_img_menu >>> .form_info_value {
  position: absolute !important;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
.upload_btn {
  display: inline-block;
  line-height: 36px;
  width: 76px;
  height: 36px;
  border-radius: 4.6769232749938965px;
  text-align: center;
  padding-left: 20px;
  font-size: 14px;
  background: #f1f3f9;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.upload_btn::before {
  content: '';
  display: inline-block;
  position: absolute;
  right: 44px;
  top: 8px;
  width: 20px;
  height: 20px;
  background: url('../../../../public/image/Vector_upload.png') center no-repeat;
  background-size: 16px;
}
.upload_input {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.form_info_edit .other_attach_list {
  margin-top: 5px;
}
.courseware_form_upload_box {
  width: 200px;
  height: 126px;
  background: #f9fafc;
  border: 1px solid #ececec;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  margin: auto;
  margin-top: -30px;
}
.courseware_form_upload_box >>> .my_datum_picture_portrait img {
  width: 200px !important;
  height: 125px !important;
  border-radius: 0 !important;
}
</style>
