<template>
  <div class="mult_info_list cp"
       :class="{'top_icon':item.isOpt,'bottom_icon':!item.isOpt,'opt':item.isSelected}">
    <div class="mult_info_list_text"
         :class="{'select_btn':item.childSubject.length>0}"
         :style="liStype"
         @click.stop="editSubjectItemClick(item)">
      <span :class="{'text_box':Number(item.SysPaySubjectKey)>0}">{{item.MainDemoName}}</span>
      <div class="mult_info_list_right"
           v-if="item.childSubject.length>0"
           @click.stop="doSubjectItemClick(item)"></div>
    </div>
    <div v-if="open||item.isOpt">
      <items v-for="item in item.childSubject"
             :item="item"
             :key="item.OLAPKey"
             :operateTypes="operateTypes"
             @chooseEdit="chooseEdit"
             @editSubjectItemClick="editSubjectItemClick"></items>
    </div>

    <div class="course_student_attendance_operation module_content_menuList"
         style="top: 30px;left: 5px;width: 100px;"
         v-if="item.isShow">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="operateTypes"
                     @clickOptionForAlertDialog="chooseEdit"></drop-down-box>
    </div>
  </div>
</template>

<script>
import dropDownBox from '../../../common/drop-down-box';
export default {
  name: 'items',
  props: ['item', 'operateTypes'],
  components: {
    dropDownBox
  },
  data () {
    return {
      open: false
    };
  },
  computed: {
    liStype () {
      let le = this.item.level;
      let widthli = 365 - le * 15;
      return 'background-position: ' + widthli + 'px center;';
    }
  },
  methods: {
    // 展开收起
    doSubjectItemClick (item) {
      console.log(item, '展开收起1');
      this.open = !this.open;
      item.isOpt = !item.isOpt;
      this.loopData(item);
      this.$parent.isShowOperateBottomPopup = false;
      this.$emit('doSubjectItemClick', item);
    },
    // 父级收起时,递归子集同时需要收起
    loopData (item) {
      if (!item.isOpt && item.childSubject.length > 0) {
        item.childSubject.forEach(o => {
          o.isOpt = false;
          if (o.childSubject.length > 0) {
            this.loopData(o);
          }
        });
      }
    },
    // 显示操作
    editSubjectItemClick (item) {
      this.$emit('editSubjectItemClick', item);
    },
    chooseEdit (item) {
      this.$emit('chooseEdit', item);
    }
  }
};
</script>

<style>
</style>
