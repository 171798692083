<template>
  <!-- 套餐表单 -->
  <div>
    <!-- 名称 -->
    <div class="package_details_name bg_white">
      <input-text :formTitle="'套餐名'"
                  :formPlaceholder="'必填'"
                  :required="true"
                  v-model="taocanInfo.PackageName"></input-text>
    </div>
    <!-- 编辑 -->
    <div class="package_revise_title">
      <div class="title_name">套餐内容</div>
      <div class="title_btn"
           @click.stop="doShowAddItem"
           v-if="taocanInfo.KDNRList.length>0||taocanInfo.ZFList.length>0">添加项目</div>
    </div>
    <!-- 新增 -->
    <div class="package_setting_add label_radio_bg_white"
         v-if="taocanInfo.KDNRList.length==0&&taocanInfo.ZFList.length==0"
         @click.stop="doShowSelectItem">
      <div class="package_setting_add_btn">选择项目</div>
    </div>
    <!-- 内容 -->
    <div v-show="taocanInfo.KDNRList.length>0||taocanInfo.ZFList.length>0"
         class="taocan_content">
      <content-item ref="contentItem"
                    :taocanInfo="taocanInfo"
                    :taocanType="taocanType"
                    @doEditClick="showContentFrom"
                    @doDeleteClick="doDeleteClick"></content-item>

      <!-- 日期 -->
      <div class="label_radio_bg_white"
           v-if="vShowCreateTime">
        <div class="form_info_edit">
          <input-dialog :formTitle="vShowTitle"
                        class="no_border_bottom"
                        :formPlaceholder="'必填'"
                        :isTitleClick="true"
                        :readonly="false"
                        :required="false"
                        @showEldilog="showDateDialog"
                        :value="vShowCreateTime"></input-dialog>
        </div>
      </div>
      <!-- 合计 -->
      <div class="label_radio_bg_white">
        <input-number :min="0"
                      :value="$utils.mAmountType(taocanInfo.CountAmount, 2)"
                      :decimalPlaces="2"
                      :formTitle="'价格合计'"
                      :placeholder="'价格合计'"
                      :required="false"
                      :readonly="true"></input-number>
        <input-number :min="0"
                      :max='taocanInfo.CountAmount'
                      v-model="taocanInfo.AfterDisPreAmount"
                      :decimalPlaces="2"
                      :placeholder="'必填'"
                      :formTitle="'优惠金额'"
                      :required="false"></input-number>
      </div>
      <div class="label_radio_bg_white">
        <input-number :min="0"
                      :max='taocanInfo.CountAmount'
                      v-model="taocanInfo.SalePrice"
                      :decimalPlaces="2"
                      :placeholder="'必填'"
                      :formTitle="'套餐售价'"
                      :required="false"></input-number>
      </div>
      <!-- 按钮组 -->
      <div v-if="taocanInfo.TableID>0">
        <save-cancel-btn-group :isSingleSubmit="true"
                               :singleSubmitType="'dynamic'"
                               :btnText="'确定'"
                               @confirmClick="confirmClick"
                               @cancelClick="cancelClick"></save-cancel-btn-group>
      </div>
      <div v-if="taocanInfo.TableID==0">
        <custom-bill-save-btn :btnInfo="{type: '总计：',count: taocanInfo.SalePrice,explain:'已优惠'+taocanInfo.AfterDisPreAmount+'元', btnName: '提交'}"
                              @doSubmitClick="confirmClick"></custom-bill-save-btn>
      </div>
    </div>
    <!-- 新增,编辑、操作菜单 -->
    <div class="module_content_menuList"
         :style="{'top':topValue+'px','left':leftValue+'px','width': '200px'}"
         v-if="isShowItem">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="menuList"
                     @clickOptionForAlertDialog="onItemClick"></drop-down-box>
    </div>
    <!-- 单课、多课内容表单 -->
    <custom-dialog :title="fromInfo.name"
                   :visible.sync="isShowCourseSingleFrom"
                   :before-close="doAfterCloseFrom">
      <course-from :fromInfo="fromInfo"
                   @doConfirmFrom="doAfterEditItem"
                   @doCloseFrom="doAfterCloseFrom"></course-from>
    </custom-dialog>

    <!-- 杂费内容表单 -->
    <custom-dialog title="杂费"
                   :visible.sync="isShowIncidentalsFrom"
                   :before-close="doAfterCloseFrom">
      <incidentals-from :fromInfo="fromInfo"
                        @doConfirmFrom="doAfterEditItem"
                        @doCloseFrom="doAfterCloseFrom"></incidentals-from>
    </custom-dialog>

    <!-- 有效期 -->
    <custom-dialog title="课时有效期"
                   :visible.sync="isShowValidityDateFrom"
                   :before-close="doAfterCloseFrom">
      <popup-validity-date :taocanInfo="taocanInfo"
                           @closeFrom="doAfterCloseFrom"></popup-validity-date>
    </custom-dialog>
  </div>
</template> 

<script>
import { AddPackage, EditPackage } from '../../../../../API/workbench';
import inputAmount from '../common-module/input-amount';
import contentItem from './content-item/index';
import courseFrom from './content-item/course-from';
import incidentalsFrom from './content-item/incidentals-from';
import popupValidityDate from '../common-module/popup-validity-date';
import customBillSaveBtn from '../common-module/custom-bill-save-btn';
import dropDownBox from '../../../../common/drop-down-box';
export default {
  data () {
    return {
      isShowItem: false, // 操作菜单
      isShowCourseSingleFrom: false, // 课单
      isShowIncidentalsFrom: false, // 杂费
      isShowValidityDateFrom: false, // 有效期
      topValue: 0,
      leftValue: 0,
      menuList: [
        { type: 0, name: '单选课', eventName: 'single', isShow: true, explain: '只允许上单门课' },
        { type: 0, name: '组合课', eventName: 'multi', isShow: true, explain: '允许上选定的多门课' },
        { type: 0, name: '杂费', eventName: 'incidentals', isShow: true, explain: '教材、物料等杂费' }
      ],
      // 表单对象
      fromInfo: {
        type: '', // 类型
        name: '', // 名称
        index: -1, // 下标
        data: {}// 数据信息
      },
      dataInfo: {}  // 提交数据源
    };
  },
  props: {
    taocanType: {// 套餐标题，必填
      type: String,
      required: ''
    },
    taocanInfo: Object // 套餐信息 （必填）

  },
  components: {
    inputAmount,
    contentItem,
    courseFrom,
    incidentalsFrom,
    popupValidityDate,
    customBillSaveBtn,
    dropDownBox
  },

  created () {
    console.log(this.taocanInfo, ' 套餐信息 （必填）');
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isShowItem = false;
      // this.$refs.contentItem.isShowMenu = false;
    }, false);
  },
  watch: {
    // 优惠金额
    'taocanInfo.AfterDisPreAmount': {
      handler (newValue, oldValue) {
        console.log(newValue, oldValue, '优惠金额');
        this.taocanInfo.AfterDisPreAmount = Number(Number(newValue).toFixed(2));
        if (Number(newValue) > Number(this.taocanInfo.CountAmount)) {
          this.taocanInfo.SalePrice = 0;
        } else {
          this.taocanInfo.SalePrice = this.$utils.mMoneyType(Number(this.taocanInfo.CountAmount) - this.taocanInfo.AfterDisPreAmount, 2);
        }
      }
      // deep: true
    },
    // 套餐售价
    'taocanInfo.SalePrice': {
      handler (newValue, oldValue) {
        console.log(newValue, oldValue, '套餐售价');
        this.taocanInfo.SalePrice = Number(Number(newValue).toFixed(2));
        if (this.taocanInfo.SalePrice > Number(this.taocanInfo.CountAmount)) {
          this.taocanInfo.AfterDisPreAmount = 0;
        } else {
          this.taocanInfo.AfterDisPreAmount = this.$utils.mMoneyType(Number(this.taocanInfo.CountAmount) - this.taocanInfo.SalePrice, 2);
        }
      }
      // deep: true
    }
  },
  computed: {
    // 有效期
    vShowCreateTime () {
      let findItem = this.taocanInfo.KDNRList.find(o => { return Number(o.UnitKey) == 5; });
      if (findItem) {
        if (this.taocanInfo.ExpireTime) {
          // let newStartTime = this.taocanInfo.StartTime.replace(/\-/g, '.');
          // let newExpireTime = this.taocanInfo.ExpireTime.replace(/\-/g, '.');
          // let days = this.$utils.newDateDiff(this.taocanInfo.StartTime, this.taocanInfo.ExpireTime) + 1;
          // return newStartTime + ' - ' + newExpireTime + ' ' + days + ' 天';
          return this.taocanInfo.ExpireTime;
        } else {
          return this.$utils.mAmountType(this.taocanInfo.PeriodCount, 0) + (this.taocanInfo.UnitKeyValue == '月' ? '个月' : this.taocanInfo.UnitKeyValue);
        }
      } else {
        return false;
      }
    },
    vShowTitle () {
      let findItem = this.taocanInfo.KDNRList.find(o => { return Number(o.UnitKey) == 5; });
      if (findItem) {
        if (this.taocanInfo.ExpireTime) {
          if (this.taocanInfo.StartTime) {
            return `<span>生效日期&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:#3498db;cursor: pointer;">` + this.taocanInfo.StartTime + `</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;失效日期</span>`;
          } else {
            return `<span>生效日期&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:#3498db;cursor: pointer;">不限</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;失效日期</span>`;
          }
        } else {
          return '课时有效期';
        }
      } else {
        return false;
      }
    }
  },
  methods: {
    // 添加项目
    doShowAddItem () {
      this.topValue = 130;
      this.leftValue = 260;
      this.isShowItem = true;
      this.$refs.contentItem.isShowMenu = false;
    },
    // 选择项目
    doShowSelectItem () {
      this.topValue = 190;
      this.leftValue = 112;
      this.isShowItem = true;
    },
    // 有效期设置
    showDateDialog () {
      this.isShowValidityDateFrom = true;
    },
    // 新增套餐某项表单
    onItemClick (Obj) {
      console.log(Obj.eventName, '菜单操作项');
      this.showContentFrom(Obj.eventName, -1, null);
      this.isShowItem = false;
    },
    // 修改套餐某项表单
    showContentFrom (type, index, item) {
      this.fromInfo = {
        type: type,
        index: index,
        data: item
      };
      this.$refs.contentItem.isShowMenu = false;
      switch (this.fromInfo.type) {
        case 'single':// 单课
          this.fromInfo.name = '单选课';
          this.isShowCourseSingleFrom = true;
          break;
        case 'multi':// 组合课
          this.fromInfo.name = '组合课';
          this.isShowCourseSingleFrom = true;
          break;
        case 'incidentals': // 杂费
          this.fromInfo.name = '杂费';
          this.isShowIncidentalsFrom = true;
          break;
        default:
          break;
      }
    },
    // 删除套餐某项课单杂费
    doDeleteClick (index, item, type) {
      console.log(index, item, type, '删除套餐某项');
      if (type == 'incidentals') {
        this.taocanInfo.ZFList.splice(index, 1);
      } else {
        this.taocanInfo.KDNRList.splice(index, 1);
      }
      this.circulationItem();
    },
    // 关闭弹出层
    doAfterCloseFrom () {
      this.isShowIncidentalsFrom = false;
      this.isShowCourseSingleFrom = false;
      this.isShowValidityDateFrom = false;
    },
    // 提交表单数据
    doAfterEditItem (type, index, item) {
      console.log(type, index, item, '保存套餐内容');
      if (index >= 0) { // 修改
        if (type == 'incidentals') {
          this.taocanInfo.ZFList.splice(index, 1);
          this.taocanInfo.ZFList.splice(index, 0, item);
          // this.taocanInfo.ZFList[index] = item;
        } else {
          this.taocanInfo.KDNRList.splice(index, 1);
          this.taocanInfo.KDNRList.splice(index, 0, item);
          // this.taocanInfo.KDNRList[index] = item;
        }
      } else { // 新增
        if (type == 'incidentals') {
          this.taocanInfo.ZFList.push(item);
        } else {
          this.taocanInfo.KDNRList.push(item);
        }
      }
      this.circulationItem();
      this.doAfterCloseFrom();// 关闭
    },
    // 计算套餐汇总级数据
    circulationItem () {
      this.taocanInfo.TotalAmount = 0;// 总价值
      this.taocanInfo.CountAmount = 0;// 价格合计
      this.taocanInfo.DiscountAmount = 0;// -- 折扣金额;
      this.taocanInfo.SalePrice = 0;// -- 售价;
      // 杂费数据
      if (this.taocanInfo.ZFList.length > 0) {
        this.taocanInfo.ZFList.forEach(o => {
          this.setTransitionParameters(o);
        });
      }
      // 课单数据
      if (this.taocanInfo.KDNRList.length > 0) {
        this.taocanInfo.KDNRList.forEach(o => {
          this.setTransitionParameters(o);
        });
      }
      console.log(this.taocanInfo, '套餐设置项');
    },
    setTransitionParameters (o) {
      o.NumCount = Number(o.NumCount); // --数量;
      o.UnitPrice = Number(o.UnitPrice);  // --单价;
      o.ValueAmount = Number(o.ValueAmount);  // --价值;
      o.DiscountCount = Number(o.DiscountCount);  // --折扣;
      o.DiscountAmount = Number(o.DiscountAmount);  // --折扣金额;
      o.AfterDisAmount = Number(o.AfterDisAmount); // --金额;
      // 总计
      console.log(this.$utils.mAmountType(o.AfterDisAmount, 2), '套餐价格合计');
      this.taocanInfo.TotalAmount += this.$utils.mAmountType(o.ValueAmount, 2);// 套餐总价值
      this.taocanInfo.DiscountAmount += this.$utils.mAmountType(o.ValueAmount - o.AfterDisAmount, 2);// 套餐折扣金额
      this.taocanInfo.CountAmount = this.$utils.mAmountType(this.taocanInfo.CountAmount + o.AfterDisAmount, 2);// 套餐价格合计
      this.taocanInfo.SalePrice = this.$utils.mAmountType(Number(this.taocanInfo.CountAmount - this.taocanInfo.AfterDisPreAmount), 2);// 套餐售价（优惠金额不为0时还需再次计算）
    },
    // 提交
    confirmClick (callBack) {
      let flag = false;
      console.log(this.taocanInfo, '提交');
      if (!this.taocanInfo.PackageName.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('请输入套餐名称');
        flag = true;
      } else if (
        !this.taocanInfo.CountAmount &&
        Number(this.taocanInfo.CountAmount) < 0
      ) {
        layer.alert('价格合计不能为空,至少大于等于0。');
        flag = true;
      } else if (isNaN(this.taocanInfo.AfterDisPreAmount)) {
        layer.alert('优惠金额不能为空');
        flag = true;
      } else if (Number(this.taocanInfo.AfterDisPreAmount) < 0) {
        layer.alert('优惠金额至少大于等于0');
        flag = true;
      } else if (Number(this.taocanInfo.AfterDisPreAmount) > this.taocanInfo.CountAmount) {
        layer.alert('优惠金额不能超过价格合计金额');
        flag = true;
      } else if (isNaN(this.taocanInfo.SalePrice)) {
        layer.alert('套餐售价不能为空');
        flag = true;
      } else if (Number(this.taocanInfo.SalePrice) < 0) {
        layer.alert('套餐售价至少大于等于0');
        flag = true;
      } else if (this.taocanInfo.SalePrice > this.taocanInfo.CountAmount) {
        layer.alert('套餐售价不能超过价格合计金额');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      // 数据源
      this.dataInfo = {
        PackageName: this.taocanInfo.PackageName, // -- 名称;
        UnitKey: this.taocanInfo.UnitKey || 0,  // -- 有效期单位(2-年;3-月;4-日);
        UnitKeyValue: this.taocanInfo.UnitKeyValue,  // ;
        PeriodCount: this.taocanInfo.PeriodCount || 0,  // -- 有效期;
        StartTime: this.taocanInfo.StartTime, // 课时卡生效日期
        ExpireTime: this.taocanInfo.ExpireTime, // 课时卡失效日期
        TotalAmount: this.taocanInfo.TotalAmount,  // -- 总价值;
        DiscountAmount: this.taocanInfo.DiscountAmount,  // -- 折扣金额;
        AfterDisPreAmount: this.taocanInfo.AfterDisPreAmount,  // -- 折后优惠;
        SalePrice: this.taocanInfo.SalePrice, // -- 售价;
        CommentScript: this.taocanInfo.CommentScript,  // -- 备注;
        KDNRList: this.taocanInfo.KDNRList, // 课程课类
        ZFList: this.taocanInfo.ZFList  // 杂费
      };
      if (this.taocanInfo.TableID > 0) {
        EditPackage(this.dataInfo, this.taocanInfo.TableID).then(result => {
          console.log(result, '修改-套餐');
          layer.alert('修改成功');
          this.$emit('afterSuccess', 'edit', result.data || {});
          this.cancelClick();
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      } else {
        AddPackage(this.dataInfo).then(result => {
          console.log(result, '新增-套餐');
          layer.alert('新增成功');
          this.$emit('afterSuccess', 'add', result.data || {});
          this.cancelClick();
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      }
    },
    // 关闭
    cancelClick () {
      this.$emit('cancelClick');
    }
  }
};
</script>

  <style>
/*  */
</style>