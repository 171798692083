<template>
  <!-- 加盟店表单 -->
  <div class="courseware_bag_form">
    <div class="form_info_edit form_info_list">
      <input-dialog :formTitle="'消息内容'"
                    :formPlaceholder="''"
                    :required="true"
                    :value="'设置'"
                    @showEldilog="showMsgText"></input-dialog>
      <div class="campus_list_ul">
        <div class="campus_list_box pr">
          <span class="campus_item"
                :title="dataInfo.NoticContent">
            {{dataInfo.NoticContent}}
          </span>
        </div>
      </div>
      <input-dialog :formTitle="'通知校区'"
                    :formPlaceholder="'请选择'"
                    :required="true"
                    :value="vcampusListLength"
                    @showEldilog="getCampusList"></input-dialog>
      <div class="campus_list_ul">
        <div class="campus_list_box pr"
             v-for="(item,index) in dataInfo.InfomCampusList"
             :key="index">
          <span :title="item.ClientKeyValue"
                class="campus_item">{{item.ClientKeyValue}}</span>
          <span class="campus_delete_box"
                @click="deleteAmpus(item)">×</span>
        </div>
      </div>
      <div class="pr">
        <div class="campus_list_box add_btn pr"
             @click="getCampusList"></div>
      </div>

    </div>
    <div>
      <textarea-pop :title="'设置消息内容'"
                    :maxLength="100"
                    :message="dataInfo.NoticContent"
                    :placeholderText="'请输入~'"
                    ref="textareaPop"
                    @submitStudentArrangeSupport="editMsg"></textarea-pop>
    </div>
  </div>
</template>
<script>
import {
  HQGetInfomCampusList,
  HQGetNotInfomCampusList,
  HQRemoveInformCampus,
  HQSetUpInformCampus,
  HQSetUpInformWords
} from '../../../../../API/workbench';
import textareaPop from '../../../../common/textarea-pop';
export default {
  data () {
    return {
      dataInfo: {
        InfomCampusList: [], //	整型	必须		数据源：GET	子课包释放数据Key
        InfomCampusSum: 0, //	整型	必须		数据源：GET	 大课包key
        NoticContent: '', //	整型	必须		数据源：GET	小课包key

      },
    };
  },
  components: {
    textareaPop
  },
  props: {

  },
  created () {

  },
  mounted () {
    this.getDataInfo()
    this.$dialog.register(this, ['singleSelectionList', 'campusForList']);
  },
  computed: {
    // 结算方式
    vType () {
      return {
        type: this.dataInfo.TypeKey,
        name: this.dataInfo.InfomCampusList.length == 0 ? '' : this.dataInfo.InfomCampusList.length + '个'
      };
    },
    vcampusListLength () {
      return this.dataInfo.InfomCampusList.length + '个'
    }
  },
  methods: {
    // 设置消息
    showMsgText () {
      this.$refs.textareaPop.isAlterMessagePopup = true;
    },
    getDataInfo () {
      HQGetInfomCampusList().then(result => {
        console.log("HQGetInfomCampusList", result.data)
        this.dataInfo = result.data
      }).catch(error => {

      })
    },
    // 获取校区列表添加
    getCampusList () {
      HQGetNotInfomCampusList().then(result => {
        console.log("HQGetNotInfomCampusList", result.data)
        result.data.forEach(o => {
          o.OLAPKey = o.ClientKey
          o.MainDemoName = o.ClientKeyValue
        })
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择校区',
          moduleName: 'singleSelectionList',
          data: { selectedKey: 0, dataSourceList: result.data, required: true, addModuleName: '', ModulePowerKey: 0 },
          callBack: {
            onSelectedItem: (data) => {
              this.doCampusSelected(data);
            }
          }
        });
      }).catch(error => {

      })
      console.log('获取校区列表添加');
    },
    // 选中的校区设置
    doCampusSelected (obj) {
      HQSetUpInformCampus(obj.ClientKey).then(result => {
        console.log('HQSetUpInformCampus', result.data)
        this.getDataInfo()
      }).catch(error => {
        layer.alert(error.msg)
      })
    },
    // 删除该校区
    deleteAmpus (obj) {
      console.log('deleteAmpus', obj)
      HQRemoveInformCampus(obj.ClientKey).then(result => {
        console.log('HQRemoveInformCampus', result.data)
        this.getDataInfo()
      }).catch(error => {
        layer.alert(error.msg)
      })
    },
    editMsg (value) {
      this.dataInfo.NoticContent = value
      HQSetUpInformWords(this.dataInfo.NoticContent).then(result => {
        console.log('HQSetUpInformWords', result.data)
      })
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
<style>
.courseware_bag_form {
  min-height: 550px;
}
.courseware_bag_form .form_item .input_readonly {
  overflow: hidden !important;
}
.cwb_form_box_info {
  box-sizing: border-box;
  width: 370px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 20px;
}
.cwb_form_box_info.minheight {
  height: 73px;
}
.cwb_form_box_info.maxheight {
  height: 103px;
}
.cwb_form_box_info .tltle_info {
  padding: 10px 5px 10px 20px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cwb_form_box_info .file_info {
  font-size: 14px;
  border-top: 1px dashed #ececec;
  margin: 0px 16px;
  position: relative;
  padding: 10px 0 10px 35px;
}
.cwb_form_box_info .file_info::after {
  content: '';
  /* background: url(../../../../../public/cw-img/file_icon@2x.png) no-repeat 50%; */
  background-size: 22px;
  width: 28px;
  height: 22px;
  position: absolute;
  top: 6px;
  left: 0;
}
.cwb_form_box_info .cwb_num_info {
  font-size: 14px;
  margin: 0 16px;
  color: #999999;
  font-family: 'Alibaba';
}
.campus_list_ul {
  overflow-y: auto;
  max-height: 300px;
}
.campus_list_box {
  height: 46px;
  width: 360px;
  border: 1px solid #e8ebef;
  border-radius: 3px;
  line-height: 46px;
  background: rgb(249, 251, 253);
  margin: 10px 0;
  padding-left: 25px;
}
.campus_delete_box {
  right: 0px;
  position: absolute;
  color: #c4c4c4;
  cursor: pointer;
  width: 30px;
  top: 0;
  text-align: right;
  padding-right: 10px;
}
.campus_list_box.add_btn {
  cursor: pointer;
}
.campus_list_box.add_btn:after {
  content: '';
  position: absolute;
  width: 2px;
  height: 18px;
  left: 180px;
  top: 12px;
  background-color: #3498dc;
}
.campus_list_box.add_btn:before {
  content: '';
  position: absolute;
  width: 18px;
  height: 2px;
  left: 172px;
  top: 20px;
  background-color: #3498dc;
}
.campus_list_box .campus_item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
  display: block;
}
.campus_select_input {
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
}
.campus_select_input .form_info_line .form_info_field {
  display: none;
}
</style>

