var fun = {
  loginInfo: {
    Token: null,
    // 获取cookie
    getCookie (name) {
      var arr;
      var reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
      if ((arr = document.cookie.match(reg))) {
        return unescape(arr[2]);
      } else {
        return null;
      }
    },
    // 添加cookie
    setCookie (name, value) {
      document.cookie = name + '=' + escape(value)+ ';path=/';
    },
    // 删除cookie
    delCookie (name) {
      var exp = Date.new();
      exp.setTime(exp.getTime() - 1);
      var cval = this.getCookie(name);
      if (cval != null) {
        document.cookie = name + '=' + cval + ';path=/;expires=' + exp.toGMTString();
      }
    },
    GetToken () {
      return this.Token;
    },
    SetToken (token) {
      this.Token = token;
    },
    CheckIsLogin () {
      return this.GetToken() != null;
    }
  }
};
export default fun;
