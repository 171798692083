<template>
  <!-- 优化组件 -->
  <!-- @click.stop="selectedItem(courseItem)" -->
  <div class="apply_course_search_list"
       @click.stop="selectedItem(courseItem)">
    <div class="list_class">
      套餐
    </div>
    <div class="list_name">
      {{vTaoCanKeyValue}}
    </div>
    <div class="clearfix">
      <div class="list_left fl">
        <div class="line_clamp_1 border_r">
          {{courseItem.StudentKeyValue}} {{$utils.phoneModulePower(courseItem.CustomerPhoneName)}}
        </div>
        <div class="line_clamp_1 border_r">
          <span class="font_gray">购买日期</span> {{vGenerateTime}}
        </div>
      </div>
      <div class="list_right fl">
        <div class=" line_clamp_1">
          <span class="font_gray">适用</span> | {{courseItem.ApplyCorName}}
        </div>
        <div class="list_right_dual line_clamp_1">
          <div class="line_clamp_1 border_r fl">
            <span class="font_gray">购买课时</span> {{courseItem.NumCount}}
          </div>
          <div class="padding_l line_clamp_1 font_red fl">
            <span class="font_gray">待报课时</span> {{vCount}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'singleSelectionItem',
  data () {
    return {
      vTestStudentItem: ''
    };
  },
  props: ['courseItem'], // 单选数据项 需绑定到界面
  created () {
    this.vTestStudentItem = this.courseItem;
  },
  watch: {
    courseItem () {
      this.vTestStudentItem = this.courseItem;
    }
  },
  computed: {
    vGenerateTime () {
      if (this.courseItem.GenerateTime) {
        return this.courseItem.GenerateTime.substring(0, 10);
      }
    },
    vTaoCanKeyValue () {
      if (this.courseItem && Number(this.courseItem.TaoCanKey) > 0) {
        return this.courseItem.TaoCanKeyValue;
      } else {
        return '不使用';
      }
    },
    vCount () {
      if (Number(this.courseItem.CurrentCount) < Number(this.courseItem.ToBeArranged)) {
        return this.$utils.saveHowMuchDecimal(Number(this.courseItem.CurrentCount), 1);
      } else {
        return this.$utils.saveHowMuchDecimal(Number(this.courseItem.ToBeArranged), 1);
      }
    }
  },
  methods: {
    selectedItem (item) {
      item.isActive = true;
      this.$emit('selectedItem', item);
    }
  }
};
</script>

