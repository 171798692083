<template>
  <!-- 课评统计 -->
  <div>
    <div class="school_inspector_statistics_top">
      <!-- 条件过滤 -->
      <div class="top_select">
        <condition-filtration-box :queryParams="queryParams"
                                  :campusList="campusList"
                                  @searchValue="search"></condition-filtration-box>
      </div>
      <!-- 汇总信息 -->
      <div class="top_content">
        <statistics-content-list v-for="(item,key) in statisticsInfoList"
                                 :key="key"
                                 :dataInfo="item"></statistics-content-list>
      </div>
    </div>
    <div class="school_inspector_statistics_bottom">
      <div class="bottom_left scroll_type">
        <div class="left_list_title">老师课评排名</div>
        <div class="left_list_ul" :style="leftScrollHeight">
          <teacher-ranking-list v-for="(item,key) in vTeacherRankedData"
                                :key="key"
                                :dataInfo="item"
                                :dataList="vTeacherRankedData"
                                @selectTeacherRanking="selectTeacherRanking"></teacher-ranking-list>

          <div v-if="vTeacherRankedData.length==0"
               style="margin-top: 200px;">
            <basics-nodata-tips></basics-nodata-tips>
          </div>
        </div>
      </div>
      <div class="bottom_right"
           v-if="summarizingInfo">
        <table-list :paramsObj="queryParams" @teacherListScrollHeight="teacherListScrollHeight"></table-list>
      </div>
    </div>
  </div>
</template>
<script>
import conditionFiltrationBox from '../../common/condition-filtration-box';
import statisticsContentList from '../../common/statistics-content-list';
import teacherRankingList from '../teacher-ranking-list';
import tableList from './table-list';
import { hqInspectAssessmentStat, InspectAssessmentStat } from '../../../../../API/workbench.js';
export default {
  data () {
    return {
      statisticsInfoList: [
        { name: '课评率', key: 1, type: 'progress', value: 0, className: '' },
        { name: '应课评', key: 2, type: 'count', value: 0, className: '' },
        { name: '已课评', key: 3, type: 'count', value: 0, className: '' },
        { name: '未课评', key: 4, type: 'count', value: 10, className: 'font_red' }
      ],
      summarizingInfo: null, // 汇总信息查询
      // 查询参数
      queryParams: {
        StartDate: this.$utils.getCurrentMonthFirst(), //	字符串	可选		数据源：GET	开始日期
        EndDate: this.$utils.getCurrentMonthLast(), //	字符串	可选		数据源：GET	结束日期
        SchoolKey: '', //	门店id
        TeacherKey: ''
      },
      leftScrollHeight: '' // 老师排名列表滚动高度
    };
  },
  components: {
    conditionFiltrationBox,
    statisticsContentList,
    teacherRankingList,
    tableList
  },
  props: {
    campusList: {
      type: Array,
      default: []
    },
    schoolInfo: Object
  },
  created () {
    this.queryParams.SchoolKey = this.schoolInfo.SchoolKey;
    this.queryParams.StartDate = this.schoolInfo.StartDate;
    this.queryParams.EndDate = this.schoolInfo.EndDate;
    this.getInspectAssessmentStat(this.queryParams);
  },
  methods: {
    // 汇总信息查询
    getInspectAssessmentStat (queryParams) {
      let apiFN = (this.vTypeKey ? hqInspectAssessmentStat : InspectAssessmentStat);
      apiFN(queryParams).then(result => {
        this.summarizingInfo = result.data;
        if (this.summarizingInfo.TeacherRankedData.length > 0) { // 默认选中第一位老师
          this.queryParams.TeacherKey = this.summarizingInfo.TeacherRankedData[0].MTeacherKey;
        }
        console.log('课评汇总信息查询', this.summarizingInfo);
        if (this.summarizingInfo.AssessmentStatData) {
          this.statisticsInfoList[0].value = Number(this.summarizingInfo.AssessmentStatData.AssessmentRate); // 课评率
          this.statisticsInfoList[1].value = this.summarizingInfo.AssessmentStatData.OughtToNum;// 应课评
          this.statisticsInfoList[2].value = this.summarizingInfo.AssessmentStatData.Assessment;// 已课评
          this.statisticsInfoList[3].value = this.summarizingInfo.AssessmentStatData.NotAssessment;// 未课评
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 根据校区时间搜索
    search (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.$emit('search', queryParams);
      this.getInspectAssessmentStat(this.queryParams);
    },
    // 根据老师排名搜索
    selectTeacherRanking (dataInfo) {
      console.log('根据老师排名搜索', dataInfo);
      this.queryParams.TeacherKey = dataInfo.MTeacherKey;
    },
    // 获取动态老师排名列表滚动高度
    teacherListScrollHeight (setHeight) {
      this.leftScrollHeight = 'height:' + setHeight + 'px';
    }
  },
  computed: {
    vTypeKey () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) { // 总部key为6
        return true;
      } else {
        return false;
      }
    },
    vTeacherRankedData () {
      let list = [];
      if (this.summarizingInfo && this.summarizingInfo.TeacherRankedData.length > 0) {
        this.summarizingInfo.TeacherRankedData.forEach(o => {
          this.$set(o, 'isOpt', false);
          this.$set(o, 'SubmitName', '已课评');
          this.$set(o, 'Submit', o.num);
          this.$set(o, 'NotSubmitName', '未课评');
          this.$set(o, 'NotSubmit', o.NotAssessment);
          this.$set(o, 'SubmitRateName', '课评率');
          this.$set(o, 'SubmitRate', o.AssessmentRate);
          list.push(o);
        });
        list[0].isOpt = true;
      }
      return list;
    }
  }
};
</script>

<style>
</style>

