<template>
  <div class="massive_bg">
    <div id="screanContent"></div>
    <div>
      <div class="workbench_view_title">
        <span>
          微信设置
        </span>
        <span v-if="mendianInfo.WktInfo && mendianInfo.WktInfo.PatternKey==1"
              style="font-size:14px"
              class="font_gray">
          <span v-if="mendianInfo.WktInfo.is_official_component_authorizer == 1">(授权独立模式)</span>
          <span v-else>(独立模式)</span>
        </span>
        <span v-if="vIsThirdpartyTencentShow"
              class="weixin_type fr font_blue type_big"
              :class="isReadonly?'norole':''"
              @click="showThirdpartyTencent()">使用自有微信公众号</span>
        <span v-else
              class="weixin_type weixin_type_01  fr"
              :class="{font_blue:mendianInfo.WktInfo && mendianInfo.WktInfo.PatternKey == 1,norole:isReadonly}"
              @click="doWeChatSettingClick(mendianInfo.WktInfo)">
          {{mendianInfo.WktInfo && mendianInfo.WktInfo.PatternKey==1?'设置':'托管模式'}}
        </span>

      </div>
      <div class="weixin_setting_content clearfix">
        <div class="weixin_setting_QRcode fl">
          <div v-if="mendianInfo.WktInfo.QRcode"
               @click="QrcodeDownload"
               class="pr">
            <div id="mendianQRcode"
                 title=""></div>
            <img :src="vQrcodeImg"
                 id="QRcodeImg"
                 alt="">
            <!-- <a :href="vQrcodeImg"
               download="QRcodeImg"
               id="QRcodeDownload"></a> -->
          </div>
          <div class="weixin_setting_QRcode_noData"
               v-else>
            抱歉！<br />公众号配置错误。
          </div>
          <div>
            <function-btn :item="resetbtn"
                          @clickItem="resetQRcode"
                          :className="'QRcode_btn btn_hover_blue'">
              重置
            </function-btn>
            <function-btn :item="resetbtn"
                          @clickItem="checkPower"
                          :className="'QRcode_btn btn_hover_blue pr'">
              上传
              <input v-show="vHasModulePower(38) && mendianInfo.WktInfo"
                     type="file"
                     title=""
                     name="file"
                     accept="image/*"
                     ref="uploading"
                     v-on:change="selectImgB"
                     class="my_view_chg_pic my_view_chg_index button"
                     style="top: 0;opacity:0;height:100%"
                     data-type="userIconImg">
            </function-btn>
          </div>
        </div>
        <div class="weixin_setting_from fr"
             style="width: 43%;">
          <div class="from_list clearfix">
            <div class="fl">公众号：</div>
            <div class="fr"
                 :class="{font_red:mendianInfo.WktInfo && mendianInfo.WktInfo.wechat_set_status != '正常'}">{{mendianInfo.WktInfo?mendianInfo.WktInfo.wechat_set_status || '未验证':'异常'}}</div>
          </div>
          <div class="from_list clearfix">
            <div class="fl">支付：</div>
            <div class="fr">{{mendianInfo.WktInfo?mendianInfo.WktInfo.pay_set_status || '未验证':'异常'}}</div>
          </div>
          <div class="from_list clearfix">
            <div class="fl">红包：</div>
            <div class="fr">{{mendianInfo.WktInfo && mendianInfo.WktInfo.is_open_redpaper ?'开启':'关闭'}}</div>
          </div>
          <div class="from_list clearfix">
            <div class="fl">小程序：</div>
            <div class="fr"
                 :class="{font_red:mendianInfo.WktInfo && mendianInfo.WktInfo.progm_set_satus != '未配置' && mendianInfo.WktInfo.progm_set_satus != '正常'}">{{mendianInfo.WktInfo? mendianInfo.WktInfo.progm_set_satus || '未配置':'未配置'}}</div>
          </div>
        </div>
        <div class="weixin_setting_tips"
             style="right: 20%;">
          <span class="font_red">*</span>点击二维码图片可下载。
        </div>
      </div>
    </div>
    <div class="form_info_color">
      <div class="workbench_view_title border_top">
        <span>微信通知设置</span>
        <div class="template_reset"
             v-if="mendianInfo.WktInfo && mendianInfo.WktInfo.PatternKey==1"
             @click.stop="templateResetBtn">模板重置</div>
      </div>
      <weChat-inform-setting-list :mendianInfo="mendianInfo"
                                  :isReadonly="isReadonly"
                                  @doTemplateSettingClick="doTemplateSettingClick"></weChat-inform-setting-list>
    </div>
    <div class="form_info_color"
         v-if="mendianInfo.IsOpenMicroClassKey == 1 && mendianInfo.WktInfo">
      <div class="workbench_view_title border_top">
        <span>微网校</span>
        <div class="wkt_setting_explain fr"
             @click="showDescription">
          <span>设置</span>
        </div>
      </div>
      <div class="mendian_setting_content form_info_edit">
        <div class="content_title">基本信息</div>
        <div class="form_info_line padding_LR_10 no_border_bottom"
             style="padding-top:3px">
          <div class="form_info_field_init ">
            <span>专属域名</span>
          </div>
          <div class="form_info_value font_blue text_overflow_hidden"
               style="line-height:30px;padding-right:20px;font-size:16px">
            <!-- <a :href="mendianInfo.WktInfo.sec_website"
               target="_blank">{{mendianInfo.WktInfo.sec_website}}</a> -->
            <span @click="websiteClick"
                  style="border-bottom: 1px solid #3498db;">{{mendianInfo.WktInfo.sec_website}}</span>
          </div>
        </div>
        <div class="form_info_line padding_LR_10 no_border_bottom">
          <div class="form_info_field_init ">
            <span>微网校名称</span>
          </div>
          <div @click="showIsNameShow"
               :class="!isReadonly?'':'norole'"
               class="form_info_value form_info_select font_blue text_overflow_hidden"
               style="line-height:30px;padding-right:20px;font-size:16px">
            {{mendianInfo.WktInfo.name}}
          </div>
        </div>
        <div class="form_info_line padding_LR_10 no_border_bottom">
          <div class="form_info_field_init ">
            <span>机构介绍</span>
          </div>
          <div @click="showMendianIntroduce"
               class="form_info_value form_info_select font_blue"
               :class="!isReadonly?'':'norole'">
            <input type="text"
                   :class="!isReadonly?'':'norole'"
                   name=""
                   placeholder="请设置机构简介"
                   :value="WKTmendianInfo.introduce?'已设置':'未设置'"
                   disabled
                   style="padding-right:20px;">
          </div>
        </div>
        <div v-if="WKTmendianInfo.introduce"
             style="padding-bottom:20px">
          <el-tooltip :content="WKTmendianInfo.introduce || mendianInfo.WktInfo.introduce"
                      popper-class="mendian_introduce_tooltip"
                      placement="bottom"
                      :disabled="introduceDisabled()"
                      effect="light">
            <div class="mendian_introduce">
              <div>
                <pre ref="introduceName"
                     v-html="mendianInfo.WktInfo.introduce"></pre>
              </div>
            </div>
          </el-tooltip>
        </div>

      </div>
    </div>
    <custom-dialog :title="'裁切图片'"
                   class=""
                   :visible.sync="isChgPic"
                   :before-close="closeChgPic">
      <vue-crop-img :selectPicUrl="selectPicUrl"
                    :cutImgWidth="250"
                    :cutImgHeight="320"
                    @onConfirmClick="setPicCorp"
                    @onCancelClick="clearPicData"></vue-crop-img>
    </custom-dialog>
    <custom-dialog :title="'微网校名称'"
                   class="new_class defult_dialog"
                   :visible.sync="isNameShow"
                   :before-close="doCloseName">
      <div class="content_marginRL content_marginTop_30 single_shadow overflow_hidden ">
        <div class="mendian_setting_content">
          <input-text :readonly="false"
                      v-model="mendianName"
                      :formTitle="'微网校名称'"
                      class="no_border_bottom"
                      :formPlaceholder="'必填'"
                      :required="true"></input-text>
        </div>
      </div>
      <!-- 按钮组 -->
      <div>
        <save-cancel-btn-group @confirmClick="alertName"
                               @cancelClick="doCloseName"></save-cancel-btn-group>
      </div>
    </custom-dialog>
    <custom-dialog :title="'机构介绍'"
                   :visible.sync="isAlterMessagePopup"
                   :before-close="hideAlterMessagePopup">
      <div class="form_info_detail micro_message">
        <div class="micro_message_edit content_marginRL single_shadow">
          <div class="message_edit_input">
            <pre class="textbox"
                 ref="textboxPre"
                 contenteditable
                 placeholder="请输入机构介绍"
                 v-html="mendianIntroduce"></pre>
          </div>
        </div>
        <!-- 按钮组 -->
        <div class="footer_btn_group form_footer_btn_group">
          <el-button round
                     class="btn_light_red btn_hover_white"
                     @click.stop="hideAlterMessagePopup">取消</el-button>
          <el-button round
                     class="btn_light_blue btn_hover_bg_blue"
                     @click.stop="alertIntroduce">确认</el-button>
        </div>
      </div>
    </custom-dialog>
    <custom-dialog width="1180px"
                   class="WXQRcode_dialog cancelShadow"
                   :title="'微信扫码授权'"
                   :visible.sync="isWXQRcode"
                   :before-close="closeWXQRcode">
      <iframe id="ifra"
              :src="'/OpenPlatform/OpenPlatformAuthRequest.php?SaaSClientKey='+vMendianInfo.OLAPKey + '&client_id=' + this.$store.getters.clientId"
              class="WXQRcodeIframe"
              frameborder="0"></iframe>
    </custom-dialog>
  </div>
</template>
<script>
import { GetMenDianInfoByMenDianId, GetQRcode, resettingTemplate } from '../../../../API/workbench';
import weChatInformSettingList from './weChat-inform-setting-list';
export default {
  data () {
    return {
      isWXQRcode: false,
      isMendianQRcode: false,
      isChgPic: false,
      isNameShow: false,
      isAlterMessagePopup: false,

      QrcodeImgSrc: '',
      time: '',
      mendianIntroduce: '',
      mendianName: '',
      WKTmendianInfo: {},
      selectData: [
        { key: 0, value: '关闭' },
        { key: 1, value: '开启' }
      ],
      selectPicUrl: '',          // 选中图片url
      crood2: {},           // 截图坐标之类
      preWHRatio: 1,
      selectedCurrentTarget: null,
      resetbtn: {
        name: '',
        type: '',
        ModulePowerKey: 38
      }
    };
  },
  computed: {
    vMendianInfo () {
      this.isWXQRcode = false;
      console.log(this.$store.getters.SaaSClientInfo);
      return this.$store.getters.SaaSClientInfo;
    },
    vQRcode () {
      if (this.vMendianInfo.WktInfo && this.vMendianInfo.WktInfo.QRcode) {
        if (this.vMendianInfo.WktInfo.QRcode.indexOf('http') > -1) {
          return this.vMendianInfo.WktInfo.QRcode;
        } else {
          return this.$store.getters.CDNURL + this.vMendianInfo.WktInfo.QRcode;
        }
      } else {
        return '';
      }
    },
    vQrcodeImg () {
      if (this.mendianInfo.WktInfo.QRcode.indexOf('http') > -1) {
        return this.mendianInfo.WktInfo.QRcode;
      } else {
        return this.$store.getters.CDNURL + this.mendianInfo.WktInfo.QRcode;
      }
    },
    vIsThirdpartyTencentShow () {
      if (this.mendianInfo.WktInfo && this.mendianInfo.WktInfo.PatternKey != 1 && this.mendianInfo.IsCommonTrusteeshipKey == 1) {
        return true;
      } else {
        return false;
      }
    }

  },
  components: {
    weChatInformSettingList
  },
  props: {
    mendianInfo: Object,
    isReadonly: Boolean
  },
  watch: {
    'mendianInfo.OLAPKey': {
      handler (n, o) {
        if (n != o && n > 0) {
          this.getMendianInfo();
        }
      },
      deep: true
    }
  },
  created () {
    console.log(this.mendianInfo.WktInfo.QRcode, 'this.$store.getters.CDNURL + this.mendianInfo.WktInfo.QRcode', this.$store.getters.CDNURL + this.mendianInfo.WktInfo.QRcode);
    this.$bus.on(this, 'switchHeaderBarToMendiansettingform', () => {
      this.getMendianInfo();
    });
    this.getMendianInfo();
  },
  mounted () {

  },
  methods: {
    closeWXQRcode () {
      this.isWXQRcode = false;
    },
    templateResetBtn () {
      var _this = this;
      layer.confirm('所有模版将重置为标准模版，及标准消息配置，不可退回，请确认是否继续', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              layer.close();
              resettingTemplate().then(
                res => {
                  console.log(res, 'resettingTemplate');
                  layer.alert('模板重置成功');
                  _this.getMendianInfo();
                },
                error => {
                  if (error.code == 61007) {
                    console.log(error, 'error1');
                    _this.isWXQRcode = true;
                    let time = setTimeout(() => {
                      _this.isWXQRcode = false;
                      layer.alert('授权失败，请重新点击立即授权');
                    }, 5000);
                    _this.$nextTick(() => {
                      const iframe = document.querySelector('#ifra');
                      // 处理兼容行问题
                      if (iframe.attachEvent) {
                        console.log(iframe, 'iframe1');
                        iframe.attachEvent('onload', () => {
                          // iframe加载完毕以后执行操作
                          // console.log(iframe.contentWindow.document)
                          // if(iframe.contentWindow.document.querySelector('.card_box')){
                          clearTimeout(time);
                          // }
                          console.log('iframe已加载完毕');
                        });
                      } else {
                        console.log(iframe, 'iframe2');
                        iframe.onload = () => {
                          // iframe加载完毕以后执行操作
                          // console.log(iframe.contentWindow)
                          // if(iframe.contentWindow.document.querySelector('.card_box')){
                          clearTimeout(time);
                          // }
                          console.log('iframe已加载完毕');
                        };
                      }
                    });
                  } else {
                    layer.alert(error.msg);
                  }
                  console.log(error, 'eee');
                }
              );
            }
          }
        ]
      });
    },
    websiteClick () {
      layer.alert('只支持手机微信打开');
    },
    showThirdpartyTencent () {
      if (!this.isReadonly) {
        let moduleInfo = {
          name: '使用自有微信公众号',
          routerName: this.$route.name,
          moduleName: 'thirdpartyTencent',
          data: { dataInfo: {} }
        };
        this.$bus.emit('needOpenRouter', moduleInfo);
      } else {
        this.$utils.CheckModulePowerToTips(38);
      }
    },
    introduceDisabled () {
      if (this.$refs.introduceName) {
        return this.$refs.introduceName.offsetHeight < 80;
      } else {
        return false;
      }
    },
    QrcodeDownload () {
      if (this.vQrcodeImg) {
        console.log('downLoadQrCode');
        let src = this.vQrcodeImg;
        // src = src.replace('cdn', '');
        src = src.replace(':8999', '');
        var canvas = document.createElement('canvas');
        var img = document.createElement('img');
        img.setAttribute('crossOrigin', 'Anonymous');
        img.onload = function (e) {
          canvas.width = img.width;
          canvas.height = img.height;
          var context = canvas.getContext('2d');
          context.drawImage(img, 0, 0, img.width, img.height);
          canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
          canvas.toBlob((blob) => {
            let link = document.createElement('a');
            console.log('blob', blob);
            link.href = window.URL.createObjectURL(blob);
            link.download = '公众号二维码';
            link.click();
          }, 'image/jpeg');
        };
        img.src = src;
      }
    },
    checkPower () {
      if (!this.vHasModulePower(38)) {
        this.$utils.CheckModulePowerToTips(38);
      }
    },
    // 判断权限
    vHasModulePower (key) {
      return this.$utils.CheckModulePower(this.$store.getters.RolePowerList, key);
    },
    showDescription () {
      if (this.vHasModulePower(96)) {
        let moduleInfo = {
          routerName: 'wktBack',
          routertitle: '微网校',
          ModulePowerKey: 96
        };
        this.$bus.emit('needOpenRouter', moduleInfo);
      } else {
        this.$utils.CheckModulePowerToTips(96);
      }
    },
    showMendianIntroduce () {
      if (!this.isReadonly) {
        this.mendianIntroduce = this.WKTmendianInfo.introduce;
        this.isAlterMessagePopup = true;
      } else {
        this.$utils.CheckModulePowerToTips(38);
      }
    },
    hideAlterMessagePopup () {
      this.isAlterMessagePopup = false;
    },
    showIsNameShow () {
      if (!this.isReadonly) {
        this.mendianName = this.WKTmendianInfo.name;
        this.isNameShow = true;
      } else {
        this.$utils.CheckModulePowerToTips(38);
      }
    },
    doCloseName () {
      this.isNameShow = false;
    },
    closeChgPic () {
      this.isChgPic = false;
    },
    selectImgB (e, type) {
      let fileName = e.target.files[0].name;
      let typeText = '.jpg,.jpeg,.png';
      if (typeText.indexOf(fileName.substring(fileName.toLowerCase().lastIndexOf('.'), fileName.length)) == -1) {
        this.clearPicData();
        layer.alert('上传文件类型不符合，只能上传.jpg,.jpeg,.png类型文件');
        return false;
      } else {
        if (e.target.files.length > 0) {
          var reader = new FileReader();	// 实例化一个FileReader对象，用于读取文件
          var that = this;
          reader.onload = function (evt) {
            that.selectPicUrl = evt.target.result;
            that.$nextTick(() => {
              that.isChgPic = true;
            });
          };
          reader.readAsDataURL(e.target.files[0]);
        }
      }
    },
    // 确认裁切
    setPicCorp (imgUrl) {
      this.mendianInfo.WktInfo.QRcode = imgUrl;
      this.isMendianQRcode = false;
      this.alertWKT();
      this.clearPicData();
    },
    clearPicData () {
      document.body.style.overflow = 'auto';
      this.isChgPic = false;
      this.selectPicUrl = '';
      this.$refs.uploading.value = '';
    },
    alertName (callBack) {
      let flag = false;
      if (this.mendianName.length == 0) {
        layer.alert('微网校名称不可为空');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.mendianInfo.WktInfo.name = this.mendianName;
      this.alertWKT();
    },
    alertIntroduce () {
      this.mendianIntroduce = this.$refs.textboxPre.innerHTML;
      this.mendianInfo.WktInfo.introduce = this.mendianIntroduce;
      this.alertWKT();
    },
    alertWKT () {
      this.$parent.upConfirmClick(this.mendianInfo, false, () => {
        this.$parent.getMendianInfo();
        this.getMendianInfo();
        this.isNameShow = false;
        this.isAlterMessagePopup = false;
      });
    },
    // 获取裁切数据
    afterCrop2 (json, url) {  // 获取裁切的坐标XY及大小
      this.crood2 = json;
      this.selectPicUrl = url;
    },
    // 进去微信设置表单
    doWeChatSettingClick (item) {
      if (item && item.PatternKey == 1) {
        if (!this.isReadonly) {
          this.$emit('doWeChatSettingClick');
        } else {
          this.$utils.CheckModulePowerToTips(38);
        }
      }
    },
    // 显示消息模板类型
    doTemplateSettingClick (titleName, eventId, templateKey, setTypeKey) {
      this.$emit('doTemplateSettingClick', titleName, eventId, templateKey, setTypeKey);
    },
    getMendianInfo () {
      GetMenDianInfoByMenDianId(this.mendianInfo.OLAPKey).then(res => {
        this.WKTmendianInfo = res.data;
        if (this.WKTmendianInfo.introduce) {
          this.mendianIntroduce = this.WKTmendianInfo.introduce.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ').replace(/&nbsp;/g, ' ');
        }
      }, err => {
        console.log(err, '异常WKT门店设置');
      });
    },
    resetQRcode () {
      if (this.mendianInfo.WktInfo) {
        GetQRcode(0, 'MDTGS').then(async res => {
          if (res.code == 0) {
            this.mendianInfo.WktInfo.QRcode = res.data;
            await this.alertWKT();
            layer.alert('重置二维码成功');
          }
        }, err => {
          layer.alert(err.msg);
        });
      } else {
        layer.alert('公众号配置错误，无法生成二维码');
      }
    }
  }
};
</script>
<style scoped>
.template_reset {
  position: absolute;
  z-index: 2;
  top: 3px;
  right: 17px;
  border: 1px solid #2e86c1;
  background-color: #3498db;
  color: #fff !important;
  cursor: pointer;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
  width: 85px;
}
</style>