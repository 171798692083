<template>
  <!-- 模板显示内容 -->
  <div>
    <div class="form_title no_border_bottom pr"
         style="padding:0;height:20px;line-height:10px;">
      <span v-if="isManualTemplate">消息配置</span>
      <span class="font_blue"
            v-if="false">配置说明</span>
    </div>
    <div class="template_banner">
      <div class="template_banner_title">{{messageTitle}}</div>
      <div class="template_banner_content">
        <div class="font_small clearfix"
             v-for="(item,index) in templateInfo.incontent"
             :key="index">
          <div class="banner_content_text fl"
               style="background-color: #fff;width: 282px;">
            <span class="font_title"
                  v-if="item.key!=='first'">{{item.value}}：</span>
            <span class="first_text"
                  :class="[{'font_text':item.key!=='first'}]"
                  v-html="item.valueName"></span>
          </div>
          <div class="banner_content_btn btn_hover_bg_blue fr"
               @click="doSettingItemClick(item)"
               v-if="!isReadonly">设置
          </div>
        </div>
      </div>
      <div class="template_banner_bottom">
      </div>
    </div>
    <div class="weixin_setting_form_tips"
         v-if="isManualTemplate">
      需要该手机号关注公众号，并进行关联注册。
    </div>
    <div class="weixin_setting_form_tips"
         v-if="vRecentlyTime&&!isManualTemplate">
      上次发送时间：{{vRecentlyTime}}
    </div>
    <div class="weixin_setting_phone"
         v-if="isManualTemplate">
      <input type="number"
             placeholder="测试接收手机号"
             v-model="testPhoneNumber">
      <div class="weixin_setting_phone_btn fr"
           @click="doTestSendClick">测试发送</div>
    </div>
  </div>
</template> 

<script>

import { SendTestTemplateMsg } from '../../../../../API/workbench';
export default {
  data () {
    return {
      testPhoneNumber: '', // 模板测试号码
      // 测试提交内容
      parameterTest: {
        tempID: '',
        eventId: '',
        url: '',
        phone: '',
        param: {}

      }
    };
  },
  props: {
    messageTitle: String, // 模板标题
    templateInfo: Object, // 模板信息
    isReadonly: Boolean, // 是否只读，不可设置
    selectEventID: [Number, String], // 事件ID
    isManualTemplate: {   // 是否为配置模板（true）-手动模板（false）
      type: Boolean,
      default: true
    },
    recentlyTime: String// 上次发送时间
  },
  components: {

  },

  created () {
    console.log(this.templateInfo, '模板信息');
  },
  mounted () {

  },
  activated () {

  },
  watch: {

  },
  computed: {
    // 上次发送时间
    vRecentlyTime () {
      if (this.recentlyTime) {
        let timeTest = this.recentlyTime;
        return timeTest.slice(0, 16);
      } else {
        return null;
      }
    }
  },
  methods: {
    doSettingItemClick (item) {
      this.$emit('doSettingItemClick', item);
    },
    // 发送测试接口
    doTestSendClick () {
      console.log(this.templateInfo, '发送测试接口');
      var param = {};
      if (this.templateInfo.incontent) {
        this.templateInfo.incontent.forEach(o => {
          param[o.key] = o.valueNameInit;
        });
      }
      this.parameterTest = {
        tempID: this.templateInfo.id || null,
        eventId: this.selectEventID || null,
        url: '',
        phone: this.testPhoneNumber,
        param: param
      };
      console.log(this.parameterTest, '发送测试接口2');
      if (!/^1[345789]\d{9}$/.test(this.testPhoneNumber)) {
        layer.alert('手机格式不正确');
      } else if (!this.testPhoneNumber.replace(/(^\s*)|(\s*$)/g, '')) {
        layer.alert('手机号不能为空');
      } else {
        SendTestTemplateMsg(this.parameterTest).then(res => {
          console.log(res.data, '发送测试接口');
          if (res.data.errcode == 0) {
            layer.alert('发送成功');
          } else {
            if (res.data.errcode == 40037) {
              layer.alert('微信模板不存在');
            } else if (res.data.errcode == 43004) {
              layer.alert('请先关注公众号');
            } else {
              layer.alert('模板异常，发送失败');
            }
          }
        }, err => {
          layer.alert(err.msg);
        });
      }
    }
  }

};
</script>

  <style>
/*  */
</style>