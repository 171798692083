<template>
  <div class="teacher_auth_form">
    <div class="form_info_edit form_info_list"
         style="height: 540px;overflow-y: scroll;">
      <input-dialog :formTitle="'授权老师'"
                    :formPlaceholder="'必选'"
                    :required="true"
                    :readonly="teacherReadonly"
                    @showEldilog="showTeacherListDialog"
                    v-model="dataJson.TeacherKeyValue"></input-dialog>
      <input-dialog :formTitle="'授权课包'"
                    :formPlaceholder="'必选'"
                    :required="true"
                    :readonly="coursewareReadonly"
                    @showEldilog="showCWLDialog"
                    v-model="dataJson.BigCoursewarePackageKeyValue"></input-dialog>
      <input-option-text v-if="dataJson.TeacherKey > 0 && dataJson.BigCoursewarePackageKey > 0"
                         :formTitle="'授权子课包'"
                         :readonly="isReadonly"
                         :required="true"
                         :selectList="childCWOptionList"
                         :selectedInfo="childCWInfo"
                         @change="chgchildCWType">
      </input-option-text>
      <el-tree v-if="dataJson.TeacherKey > 0 &&
            dataJson.BigCoursewarePackageKey > 0 &&
            isAllInfo.id == 2"
               ref="tree"
               :data="vdataList"
               :props="prop"
               show-checkbox
               node-key="id"
               empty-text="暂⽆数据"
               :load="loadNode"
               lazy
               :default-checked-keys="defaultCheckedKeys"
               :default-expanded-keys="defaultExpandedKeys"
               @check-change="treeNodeClick">
        <span class="custom-tree-node"
              slot-scope="{ node, data }">
          <span>
            <i class="tree_file"></i>{{ node.label }}
          </span>
        </span>
      </el-tree>
      <!-- <div
        class="teacher_auth_check_box_content"
        v-if="
          dataJson.TeacherKey > 0 &&
          dataJson.BigCoursewarePackageKey > 0 &&
          isAllInfo.id == 2
        "
        style="height: 380px; overflow-y: auto ;"
      >
        <el-checkbox-group v-model="checkList" @change="changeCheckBox">
          <div
            class="teacher_auth_check_item"
            v-for="item in childCWDataList"
            :key="item.CoursewarePackageKey"
          >
            <el-checkbox :label="item.CoursewarePackageKey">{{
              item.CoursewarePackageKeyValue
            }}</el-checkbox>
          </div>
        </el-checkbox-group>
      </div> -->
    </div>
    <save-cancel-btn-group :cancelBtnText="'撤销授权'"
                           :btnText="'确认授权'"
                           :isSingleSubmit="true"
                           :cancelBtnColor="'btn_hover_bg_red'"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelAuth"></save-cancel-btn-group>
  </div>
</template>
<script>
import {
  CWSSearchPageTeacherList,
  getInJobTeacherList,
  CWSGetBigCoursewarePackageForAuthTercher,
  CWSGetTeacherAuthCoursewarePackageList,
  CWSSubmitCWPTeacherAuth,
  CWSGetCWPByParentKeyForAuthTercher,
  CWSCancelCWPTeacherAuth,
  GetSubCoursewarePackageForSelf,
  GetSubCoursewarePackageForAuth
} from '../../../../API/workbench';

export default {
  data () {
    return {
      childNodes: [],
      // 提交数据对象
      dataJson: {
        TeacherKey: '',
        TeacherKeyValue: '',
        BigCoursewarePackageKey: '',
        BigCoursewarePackageKeyValue: '',
        IsAuthKey:'default',
        CoursewarePackageKeyList: []
      },
      teacherList: [],
      bigCWlist: [],
      childCWOptionList: [
        {
          id: 1,
          name: '全部'
        },
        {
          id: 2,
          name: '部分'
        }
      ], // 来源1-抖音;2-百度;3-转介绍
      childCWDataList: [],
      teacherAuthCWList: [], // 已授权列表
      isAllInfo: {
        id: 1,
        name: '全部'
      },
      checkList: [],    // 选中id列表
      defaultCheckedKeys: [], // 默认选中节点
      defaultExpandedKeys: ['2'], // 默认展开节点
      prop: {
        label: 'label',
        children: 'children',
        id: 'id',
        icon: 'icon',
        disabled: (node) => {
          return false;
        },
        isLeaf: 'leaf'
      },
      curren: '三级 2-1-1'
    };
  },
  components: {},
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          TeacherKey: 0,
          TeacherKeyValue: '',
          BigCoursewarePackageKey: 0,
          BigCoursewarePackageKeyValue: ''
        };
      }
    },
    isReadonly: Boolean,
    teacherReadonly: Boolean,     // 老师只读
    coursewareReadonly: Boolean      // 课件只读
  },
  created () {
    console.log('created', this.dataInfo);
    if (this.dataInfo) {
      this.dataJson.TeacherKey = this.dataInfo.TeacherKey || 0;
      this.dataJson.TeacherKeyValue = this.dataInfo.TeacherKeyValue || '';
      this.dataJson.BigCoursewarePackageKey =
        this.dataInfo.BigCoursewarePackageKey;
      this.dataJson.BigCoursewarePackageKeyValue =
        this.dataInfo.BigCoursewarePackageKeyValue;
    }
    if (
      this.dataJson.TeacherKey > 0 &&
      this.dataJson.BigCoursewarePackageKey > 0
    ) {
      this.getTeacherAuthCWList();
    }
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
  },
  computed: {
    // 性别
    childCWInfo () {
      return {
        id: this.isAllInfo.id,
        name: this.isAllInfo.name
      };
    },
    vdataList () {
      let newArr = [];
      let childArr = [];
      // type 0-子课包；1-大课包；2-目录
      this.childCWDataList.forEach(o => {
        o.label = o.MainDemoName;
        o.id = o.OLAPKey;
        o.OLAPKey = o.OLAPKey;
        o.icon = 'tree_file';
        if (o.TypeKey == 2) {
          o.level = 0;
          o.children = [];
          newArr.push(o);
        }
        if (o.TypeKey == 0) {
          o.level = 1;
          childArr.push(o);
        }
        if (o.children == undefined) {
          o.leaf = true;
        }
        console.log(o, 'oo');
      });
      if (newArr.length > 0) {
        newArr.forEach(o => {
          childArr.forEach(p => {
            if (o.OLAPKey == p.ParentKey) {
              o.children.push(p);
            }
          });
        });
      } else {
        newArr = childArr;
      }
      console.log(newArr, 'newArr111');
      return newArr;
    },
    childArr () {
      let childArr = [];
      // type 0-子课包；1-大课包；2-目录
      this.childCWDataList.forEach(o => {
        o.label = o.MainDemoName;
        o.id = o.OLAPKey;
        o.OLAPKey = o.OLAPKey;
        o.icon = 'tree_file';
        if (o.TypeKey == 0) {
          o.level = 1;
          childArr.push(o);
        }
      });
      return childArr;
    }
  },
  methods: {
    treeNodeClick (data, checked) {
      console.log('aaa');
      var selectedPackageNodes = this.$utils.parseJson(this.$refs.tree.getCheckedNodes());
      this.checkList = [];

      // 全选时过滤掉子级，只选父级。
      let list = this.getSimpleCheckedNodes(this.$refs.tree.store);
      console.log(list, 'list11');
      list.forEach(obj => {
        // this.checkList.push(obj.id);
        console.log(obj, 'obj11');
        if (obj.CoursewarePackageKey) {
          this.checkList.push(obj.CoursewarePackageKey);
          let findIndex = this.defaultCheckedKeys.indexOf(obj.CoursewarePackageKey);
          if (findIndex < 0) {
            this.defaultCheckedKeys.push(obj.CoursewarePackageKey);
            // this.$refs.tree.setChecked('40646',true)
          } else {
            this.defaultCheckedKeys.splice(findIndex, 1);
          }
        } else {
          this.checkList.push(obj.OLAPKey);
          let findIndex = this.defaultCheckedKeys.indexOf(obj.OLAPKey);
          if (findIndex < 0) {
            this.defaultCheckedKeys.push(obj.OLAPKey);
            // this.$refs.tree.setChecked('40646',true)
          } else {
            this.defaultCheckedKeys.splice(findIndex, 1);
          }
        }
      });
    },
    getSimpleCheckedNodes (store) {
      const checkedNodes = [];
      const traverse = function (node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach(child => {
          if (child.checked) {
            checkedNodes.push(child.data);
          }
          if (child.indeterminate) {
            traverse(child);
          }
        });
      };
      traverse(store);
      console.log(checkedNodes, 'checkedNodes');
      return checkedNodes;
    },
    //  //调用获取节点
    //   let res = this.getSimpleCheckedNodes(this.$refs.tree.store)
    // 设置亮度
    fetchTreeData (OLAPKey) {
      // 在 el-tree中 定义ref 来选中el-tree 调用setCurrentKey方法
      // 把想要高亮选中的项传入
      this.$refs.tree.setCurrentKey(OLAPKey);
    },
    chgchildCWType (item) {
      console.log(item, 'chgSexType');
      this.isAllInfo.id = item.id;
      this.isAllInfo.name = item.name;
      if (this.isAllInfo.id == 1) {
        this.checkList = [];
        // this.childCWDataList.forEach(o=>{
        // this.checkList.push(o.CoursewarePackageKeyValue)
        // })
      }
      console.log('this.checkList', this.checkList);
    },
    showTeacherListDialog () {
      this.loadDataSourceTeacher();
    },
    // 调用在职老师接口 getInJobTeacherList
    loadDataSourceTeacher () {
      if (this.$store.getters.SaaSClientEditionKey == 2 || this.$store.getters.SaaSClientEditionKey == 3) {
        getInJobTeacherList().then((result) => {
          this.teacherList = result.data;
          this.$dialog.open(this, {
            routerName: this.$route.name,
            name: '选择老师',
            moduleName: 'singleSelectionList',
            data: {
              selectedKey: this.dataJson.TeacherKey || 0,
              dataSourceList: this.teacherList,
              required: true,
              addModuleName: 'yuangong-setting',
              addModuleTitleName: '添加老师',
              ModulePowerKey: 44
            },
            callBack: {
              onSelectedItem: (data) => {
                this.doAfterSelectionTeacher(data);
              }
            }
          });
        });
      } else {
        let searchObj = {
          pageIndex: 0,
          pageSize: 0,
          searchText: '',
          TeacherType: 1
        };
        CWSSearchPageTeacherList(searchObj).then((result) => {
          this.teacherList = result.data.PageDataList;
          this.$dialog.open(this, {
            routerName: this.$route.name,
            name: '选择老师',
            moduleName: 'singleSelectionList',
            data: {
              selectedKey: this.dataJson.TeacherKey || 0,
              dataSourceList: this.teacherList,
              required: true,
              addModuleName: 'cws-add-teacher',
              addModuleTitleName: '添加老师',
              ModulePowerKey: 0
            },
            callBack: {
              onSelectedItem: (data) => {
                this.doAfterSelectionTeacher(data);
              }
            }
          });
        });
      }
    },
    showCWLDialog () {
      this.loadBigCWlist();
    },
    loadBigCWlist () {
      CWSGetBigCoursewarePackageForAuthTercher().then((result) => {
        result.data.forEach((o) => {
          o.MainDemoName = o.BigCoursewarePackageKeyValue;
          o.OLAPKey = o.BigCoursewarePackageKey;
        });
        this.bigCWlist = result.data;
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '授权课包',
          moduleName: 'singleSelectionList',
          data: {
            selectedKey: this.dataJson.BigCoursewarePackageKey,
            dataSourceList: this.bigCWlist,
            required: true
          },
          callBack: {
            onSelectedItem: (data) => {
              this.doAfterSelectionBigCW(data);
            }
          }
        });
      });
    },
    // 选中老师
    doAfterSelectionTeacher (item) {
      console.log('doAfterSelectionTeacher', item);
      this.dataJson.TeacherKey = item.OLAPKey;
      this.dataJson.TeacherKeyValue = item.MainDemoName;
      if (this.dataJson.BigCoursewarePackageKey > 0) {
        this.getTeacherAuthCWList();
      }
    },
    // 选中大课包
    doAfterSelectionBigCW (item) {
      console.log('doAfterSelectionBigCW', item);
      this.dataJson.BigCoursewarePackageKey = item.OLAPKey;
      this.dataJson.BigCoursewarePackageKeyValue = item.MainDemoName;
      this.dataJson.IsAuthKey=item.IsAuthKey
      if (this.dataJson.TeacherKey > 0) {
        this.getTeacherAuthCWList();
      }
    },
    // 根据传递进来的老师及课件包ID获取当前老师选中的子课件包列表
    getTeacherAuthCWList () {
      CWSGetTeacherAuthCoursewarePackageList(
        this.dataJson.BigCoursewarePackageKey,
        this.dataJson.TeacherKey
      )
        .then((result) => {
          console.log('getTeacherAuthCWList', result.data);
          this.teacherAuthCWList = result.data;
          this.getChildCwList();
        })
        .catch((error) => {
          console.log('errorCWSGetTeacherForUpdate', error.msg);
        });
    },
    handleNodeClick (data) {
      console.log(data);
    },
    loadNode (node, resolve) {
      if (node.level === 0) {
        return resolve(this.vdataList);
      }
      if (node.level > 1) { return resolve([]); }

      var hasChild;
      if (node.data.label) {
        hasChild = true;
      } else if (!node.data.label) {
        hasChild = false;
      } else {
        hasChild = Math.random() > 0.5;
      }
      console.log("loadNode111")
      this.getGetSubCoursewarePackageForAuth(node.data.OLAPKey,
        () => {
          setTimeout(() => {
            var data;
            if (hasChild) {
              data = this.childNodes;
            } else {
              data = [];
            }
            console.log(data, 'data');
            resolve(data);
          }, 100);
        });

      console.log(node, 'node,', resolve, ' resolve', hasChild, 'hasChild');
    },
    // 获取老师工作台课件包详情
    getGetSubCoursewarePackageForAuth (ParentKey, callBack) {
      let fn
      if(this.dataJson.IsAuthKey==1){
        fn = GetSubCoursewarePackageForAuth;
      }else if (this.dataJson.IsAuthKey==0) {
          fn = GetSubCoursewarePackageForSelf;
        } else if (this.$store.getters.AcademicMdCwType == 2) {
          fn = GetSubCoursewarePackageForSelf;
        } else {
          fn = GetSubCoursewarePackageForAuth;
        }
      fn(ParentKey, this.dataJson.TeacherKey).then(result => {
        result.data.forEach(o => {
          o.label = o.CoursewarePackageKeyValue;
          o.leaf = true;
        });
        this.childNodes = result.data;
        if (callBack) {
          callBack()
        }
        console.log(this.childNodes, 'this.childNodes', this.childCWDataList);
      }, error => {
        layer.alert(error.msg);
      });
      this.$forceUpdate();
    },
    // 获取子课件包列表
    getChildCwList () {
      this.defaultCheckedKeys = [];
      CWSGetCWPByParentKeyForAuthTercher(
        this.dataJson.BigCoursewarePackageKey
      )
        .then((result) => {
          let locaItem = this.teacherAuthCWList.find((p) => {
            return p.CoursewarePackageKey == 0;
          });
          // 是否全部选中。
          if (locaItem || this.teacherAuthCWList.length == 0) {
            this.isAllInfo.id = 1;
            this.isAllInfo.name = '全部';
            this.checkList = [];
            // result.data.forEach(o=>{
            //   this.checkList.push(o.CoursewarePackageKeyValue)

            //   o.isActive=true
            // })
          } else {
            this.isAllInfo.id = 2;
            this.isAllInfo.name = '部分';
            this.checkList = [];
            result.data.forEach((o) => {
              o.isActive = false;
              let locaItem = this.teacherAuthCWList.find((p) => {
                return o.CoursewarePackageKey == p.CoursewarePackageKey;
              });
              if (locaItem) {
                this.checkList.push(o.CoursewarePackageKey);
                this.defaultCheckedKeys.push(o.CoursewarePackageKey);
                o.isActive = true;
              }
            });
          }
          console.log('getChildCwList', result.data, this.checkList);
          this.childCWDataList = result.data;
        })
        .catch((error) => {
          // layer.alert(error.msg);
        });
    },
    verifyData (type) {
      console.log(type, this.isAllInfo, 'this.dataJson', this.dataJson);
      let isFail = false;
      if (!this.dataJson.TeacherKey) {
        isFail = true;
        layer.alert('请选择老师');
      } else if (!this.dataJson.BigCoursewarePackageKey) {
        isFail = true;
        layer.alert('请选择授权课包');
      } else if (
        type == 'confirm' &&
        this.isAllInfo.id == 2 &&
        this.dataJson.CoursewarePackageKeyList.length == 0
      ) {
        // 选择部分
        isFail = true;
        layer.alert('请选择授权子课包');
      }
      return isFail;
    },
    confirmClick (callBack) {
      this.dataJson.CoursewarePackageKeyList = [];
      if (this.isAllInfo.id == 1) {
        this.dataJson.CoursewarePackageKeyList = [];
      } else {
        console.log(this.childCWDataList, 'this.childCWDataList', this.checkList);
        // this.childCWDataList.forEach((o) => {
        this.checkList.forEach((p) => {
          // if (o.OLAPKey == p) {
          this.dataJson.CoursewarePackageKeyList.push(p);
          // }
        });
        // });
      }
      if (this.verifyData('confirm')) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      CWSSubmitCWPTeacherAuth(
        this.dataJson.TeacherKey,
        this.dataJson.BigCoursewarePackageKey,
        this.dataJson.CoursewarePackageKeyList
      )
        .then((result) => {
          console.log('CWSUpdateTeacher', result);
          layer.alert('授权成功');
          this.$emit('afterSuccess');
          this.$emit('closeDialog');
        })
        .catch((error) => {
          if (callBack) {
            callBack();
          }
          console.log('errorCWSUpdateTeacher', error.msg);
        });
    },
    cancelAuth () {
      if (this.verifyData('cancel')) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      layer.confirm('是否撤销当前老师对该大课包的授权？', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              CWSCancelCWPTeacherAuth(
                this.dataJson.TeacherKey,
                this.dataJson.BigCoursewarePackageKey
              )
                .then((result) => {
                  console.log('CWSUpdateTeacher', result);
                  // layer.alert("撤销授权成功");
                  this.$emit('cancelSuccess');
                  this.$emit('afterSuccess');
                  this.$emit('closeDialog');
                })
                .catch((error) => {
                  if (callBack) {
                    callBack();
                  }
                  console.log('errorCWSUpdateTeacher', error.msg);
                });
              layer.close();
            }
          }
        ]
      });
    },
    cancelClick () {
      this.$emit('closeDialog');
    },
    changeCheckBox (data) {
      console.log('changeCheckBox', data);

      console.log(this.dataJson.CoursewarePackageKeyList);
    }
  }
};
</script>
<style >
.teacher_auth_check_box_content {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}
.teacher_auth_check_item {
  margin-bottom: 5px;
}
.teacher_auth_form .el-tree {
  padding: 0px 0px;
}
.teacher_auth_form .el-tree > .el-tree-node > .el-tree-node__content {
  border: none;
  height: 30px;
  line-height: 30px;
  margin-bottom: 0px;
}
.teacher_auth_form
  .el-tree
  > .el-tree-node
  > .el-tree-node__children
  > .el-tree-node
  > .el-tree-node__content {
  padding-left: 26px !important;
}
.teacher_auth_form .el-tree .custom-tree-node {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 25px;
}
.form_info_edit .tree_file {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url('../../../../../public/cw-img/file_icon@1.png');
  background-size: 23px;
  position: relative;
  top: 8px;
  left: -3px;
}
.teacher_auth_form >>> .btn_hover_bg_red.el-button:hover,
.teacher_auth_form >>> .btn_hover_bg_red.el-button:focus {
  background-color: #ff6c67 !important;
  color: #fff !important;
  cursor: pointer;
}
.el-button:focus,
.el-button:hover {
  background-color: #ff6c67 !important;
  color: #fff !important;
  cursor: pointer;
}
</style>