<template>
  <div class="apply_payment_echarts_hous massive_bg echarts_hous_mb">
    <div class="statistics_echarts_left fl">
      <div class="apply_payment_echarts_left_title clearfix">
        <div class="title_font fl">考勤统计日报</div>
        <single-year-picker @chgDate="chgDate"
                            :type="'month'"
                            ref="monthPicker"
                            :minDate="vMinDate"
                            :maxDate="vMaxDate"
                            :isShowHistory="isShowHistory"></single-year-picker>
        <div class="change_history_data"
             @click.stop="historyBtn(historyName)">{{ historyName }}</div>
        <div class="title_month fr">
          <span class="this_month">本月</span>
          <span class="past_month">上个月</span>
        </div>
      </div>
      <div>
        <doubleTypeEchart :echartID="'graph2'"
                          :reqList="monthList"
                          :nowDataList="nowList"
                          :preDataList="oldList"
                          :echartType="'line'"
                          ref="echart"></doubleTypeEchart>
      </div>
      <div>
        <a @click.stop="showDetail"
           class="class_statistics_details_btn">
          查看详情
        </a>
      </div>
    </div>
    <nss :nowDataList="nowDataList"
         :oldDataList="oldDataList"
         :type="'month'"
         :formTitle="'本月合计'"
         :seletedDate="seletedDate"></nss>

  </div>
</template>
<script>
import singleYearPicker from '../date-picker-common/single-year-picker';
import doubleTypeEchart from '../echart-common/double-type-echart';
import nss from './now-statistics-summarize';
import { getKQStatisticsByDay, getKQStatisticsByDayH } from '../../../API/workbench';
export default {
  name: 'dayStatisticsReport',
  data () {
    return {
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      seletedDate: Date.new(Date.new().setDate(1)),
      startTime: null,
      endTime: null,
      monthList: [],
      nowList: [],    // 选中月数据显示的内容
      oldList: [],    // 上个月数据显示的内容
      nowDataList: [],     // 选中当月数据源
      oldDataList: []      // 选中的上个月数据源
    };
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    }
  },
  components: {
    nss, singleYearPicker, doubleTypeEchart
  },
  mounted () {
    this.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy/MM/dd');
    this.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy/MM/dd');
    this.monthList = this.getLongDaysOfMonth();
    this.getNowKQDataByDay(this.startTime, this.endTime, this.getLastKQDataByDay);
    // this.initGetMonthData(this.getLastKDSYDataByMonth);
  },
  methods: {
    historyBtn (name) {
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isShowHistory = true;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.endTime = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0));
        this.isShowHistory = false;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.getNowKQDataByDay(this.startTime, this.endTime, this.getLastKQDataByDay);
      this.$refs.monthPicker.changeHistoryTime(this.endTime);
    },
    showDetail () {
      this.$emit('showDetail', '考勤统计日报', this.startTime, this.endTime);
    },

    chgDate (val, startTime, endTime) {
      this.seletedDate = val;
      this.startTime = startTime;
      this.endTime = endTime;
      this.monthList = this.getLongDaysOfMonth();
      console.log(this.monthList, '最长', startTime, endTime);
      this.getNowKQDataByDay(this.startTime, this.endTime, this.getLastKQDataByDay);
    },
    // 获取最长的月份.
    getLongDaysOfMonth () {
      let testTime = this.seletedDate || Date.new();
      let nowDayOFMonth = this.getDaysOfMonth(this.seletedDate);
      let preMonthDate = Date.new(testTime.getFullYear(), testTime.getMonth() - 1, 1);
      let preDayOfMonth = this.getDaysOfMonth(preMonthDate);
      let xAxisData;
      if (nowDayOFMonth.length > preDayOfMonth.length) {
        xAxisData = nowDayOFMonth;
      } else {
        xAxisData = preDayOfMonth;
      }
      return xAxisData;
    },
    // 获取天数数组
    getDaysOfMonth (monthDate) {
      monthDate = monthDate || Date.new(Date.new().setDate(1));
      let dayList = [];
      let selectedMonth = Date.new(monthDate.getFullYear(), monthDate.getMonth() + 1, 0);
      let dayMany = selectedMonth.getDate();
      for (let i = 1; i <= dayMany; i++) {
        dayList.push(i);
      }
      return dayList;
    },
    // 获取选中月数据.
    getNowKQDataByDay (startTime, endTime, callback) {
      let fn = '';
      if (this.isShowHistory) {
        fn = getKQStatisticsByDayH;
      } else {
        fn = getKQStatisticsByDay;
      }
      fn(startTime, endTime).then(result => {
        this.nowDataList = result.data.DataList;
        console.log('考勤', result.data.DataList);
        this.nowList = this.getNowList();
        if (callback) {
          callback();
        }
      }, r => {
        console.log(r);
      });
    },
    // 获取选中月的上个月数据.
    getLastKQDataByDay () {
      let fn = '';
      if (this.isShowHistory) {
        fn = getKQStatisticsByDayH;
      } else {
        fn = getKQStatisticsByDay;
      }
      let year = Date.new(this.startTime).getFullYear();    // 纯年
      let month = Date.new(this.startTime).getMonth() + 1;   // 纯月
      let testTime = Date.new(year, month - 1); // 上个月
      let startTime = this.$utils.formatDateStr(testTime.toLocaleDateString(), 'yyyy/MM/') + '01';
      let endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(testTime), 'yyyy/MM/dd');
      fn(startTime, endTime).then(result => {
        this.oldDataList = result.data.DataList;
        this.oldList = this.getOldList(Date.new(this.seletedDate));
        this.$nextTick(() => {
          if (this.$refs.echart) {
            this.$refs.echart.echartInit();
          }
        });
      }, r => {
        console.log(r);
      });
    },
    // 获取选中年数据 因为数据呈现为12个月. 所以如果返回数据不符合该月份的话直接push '0'
    getNowList () {
      let nowList = [];
      let monthList = this.getDaysOfMonth(this.seletedDate);
      monthList.forEach(p => {
        let item = this.nowDataList.find(o => {
          return Date.new(o.SetUpTime).getDate() == parseInt(p);
        });
        if (item) {
          nowList.push(Number(item.Attence3).toFixed(0));
        } else {
          nowList.push('0');
        }
      });
      return nowList;
    },
    // 获取上一年数据   因为数据呈现为12个月. 所以如果返回数据不符合该月份的话直接push '0'
    getOldList (timeDate) {
      let oldList = [];
      timeDate = timeDate || Date.new();
      let preMoth = Date.new(timeDate.getFullYear(), timeDate.getMonth() - 1, 1);
      let monthList = this.getDaysOfMonth(preMoth);
      monthList.forEach(p => {
        let item = this.oldDataList.find(o => {
          return Date.new(o.SetUpTime).getDate() == parseInt(p);
        });
        if (item) {
          oldList.push(Number(item.Attence3).toFixed(0));
        } else {
          oldList.push('0');
        }
      });
      return oldList;
    }

  }
};
</script>
<style scoped>
.change_history_data {
  position: absolute;
  right: 180px;
  top: 20px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
</style>