<template>
  <div class="list_course_box_left "
       :class="vRolesType"
       @click.stop="showSeletedPupUp($event)">
    <div class="course_progress"></div>
    <div class="course_progress_bar"
         :style="{'height':vProgress}"></div>
    <div>
      <div class="course_date">{{rulesClassItem.WeekKeyValue}} {{vStartClassTime}} {{rulesClassItem.MTeacherKeyValue}} </div>
      <div class="course_text">
        <span v-if="rulesClassItem.IsNotEndKey==1">不结束</span>
        <span v-else>
          截止
          <span :class="vClassTimeStatu?'font_red':''">{{rulesClassItem.LastClassTime}}</span> 
          <span class="font_black">{{Number(rulesClassItem.ClassCount)}}</span>节
        </span>
        丨
        <span class="font_black">{{Number(rulesClassItem.Num_SyncArranged)}}</span>人 </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  components: {

  },
  props: {
    rulesClassItem: Object
  },
  computed: {
    vRolesType () {
      // console.log(this.rulesClassItem, 'rulesClassItem');
      // FullPeopleCount   Num_SyncArranged
      if (this.rulesClassItem) {
        if (Number(this.rulesClassItem.FullPeopleCount) == 0 || Number(this.rulesClassItem.FullPeopleCount) <= Number(this.rulesClassItem.Num_SyncArranged)) {
          return 'type_green';
        } else if (Number(this.rulesClassItem.WarnLossCount) > 0 && Number(this.rulesClassItem.Num_SyncArranged) <= Number(this.rulesClassItem.WarnLossCount)) {
          return 'type_red';
        } else {
          return 'type_blue';
        }
      }
      return 'type_blue';
    },
    vProgress () {
      if (this.vRolesType == 'type_blue') {
        return Number(this.rulesClassItem.Num_SyncArranged) / Number(this.rulesClassItem.FullPeopleCount) * 100 + '%';
      } else {
        return '100%';
      }
    },
    SaaSClientInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vStartClassTime () {
      if (this.rulesClassItem.ClassTimeName) {
        return this.rulesClassItem.ClassTimeName.substring(0, 5);
      } else {
        return '';
      }
    },
    // 剩余不到14天.
    vClassTimeStatu () {
      if (this.rulesClassItem) {
        if ((Date.new(this.rulesClassItem.LastClassTime).getTime() - Date.new().getTime()) / 1000 < 86400 * 14) {
          return true;
        } else {
          return false;
        }
      }
    }
  },
  created () {

  },
  methods: {
    showSeletedPupUp (e) {
      console.log(this.rulesClassItem, 'showSeletedPupUp');
      let Rect = this.$el.querySelector('.course_text').getBoundingClientRect();
      let parentRect = this.$parent.$el.getBoundingClientRect();
      // console.log(Rect, parentRect, 'this.$parent', this.$parent);
      this.$emit('showPupUp', this.$el.querySelector('.course_text'), this.rulesClassItem, Rect.left - parentRect.left, Rect.bottom - parentRect.top, parentRect.top);
    }
  }
};
</script>
