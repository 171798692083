<template>
  <div class="">
    <custom-dialog :title="'选择课件'"
                   :visible.sync="isShowList"
                   :before-close="cancelCoursewareList">
      <courseware-list :coursewareInfo="coursewareInfo"
                       @selectCourseware="selectCoursewareInfo"></courseware-list>
    </custom-dialog>
    <custom-dialog :title="'分发课件'"
                   :visible.sync="isShowBody"
                   :width="'436px'"
                   :before-close="cancelBody">
      <div>
        <div class="allocation_courseware_info">
          <div class="allocation_courseware_class_info">
            <div class="info_name">{{ classInfo.GradeClassKeyValue }}</div>
            <div class="info_text">
              {{ classInfo.CourseNameKeyValue }}丨{{
                classInfo.MTeacherKeyValue
              }}
            </div>
          </div>
          <div class="allocation_courseware_info_label"
               :class="{ no_btn: isConfirm }"
               @click="showCoursewareList">
            · {{ coursewareInfo.BigCoursewarePackageKeyValue?coursewareInfo.BigCoursewarePackageKeyValue+' - ':''}}{{ coursewareInfo.AuthContent }}
          </div>
        </div>
        <div class="a_cycle_lesson_has_been_selected">
          <div class="a_cycle_lesson_has_been_selected_student">
            <div>
              <span>已选循环课<span style="color: #999999">（{{ loopCourseInfoList.length }}）</span></span>
            </div>
          </div>
          <div class="pr"
               style="overflow:hidden">
            <div class="list_left"
                 :class="{type_gray:loopCourseLeft >= 0}"
                 @click="leftLoopCourse">

            </div>
            <div ref="loopCourse"
                 class="a_cycle_lesson_has_been_selected_drag pr"
                 :style="{left:loopCourseLeft + 'px'}">
              <loop-course-item v-for="(item, index) in loopCourseInfoList"
                                :key="index"
                                :dataInfo="item">
              </loop-course-item>
            </div>
            <div class="list_right"
                 :class="{type_gray:vLoopCourseEnd}"
                 @click="rightLoopCourse">

            </div>
          </div>
        </div>
        <div class="pr">
          <div class="a_cycle_lesson_has_been_cycle_lesson_text"
               :class="{ lock_type: isLock }">
            <span>循环课</span>
          </div>
          <div class="a_cycle_lesson_has_been_lesson_plan_text"
               :class="{ lock_type: isLock }">
            <span>{{ coursewareListName }}</span>
          </div>
          <div ref="bodyScroll"
               class="a_cycle_lesson_has_been_body"
               :class="{ lock_type: isLock }">
            <div class="a_cycle_lesson_has_been_body_body">
              <div class="a_cycle_lesson_has_been_content-left">
                <div ref="leftScroll"
                     @scroll="leftScroll"
                     class="a_cycle_lesson_has_been_cycle_lesson_time">
                  <left-list v-for="(item, index) in courseInfoList"
                             :key="index"
                             :dataInfo="item"
                             @clickItem="selectLeft"></left-list>
                </div>
              </div>
              <div class="chain"
                   :style="{ top: lockTop + 'px' }">
                <div class="chain_btn"
                     @click="clickLock"
                     :class="{button:!isConfirm}"></div>
              </div>

              <div class="a_cycle_lesson_has_been_content-right">
                <div ref="rightScroll"
                     @scroll="rightScroll"
                     class="a_cycle_lesson_has_been_lesson_plan_time">
                  <right-list v-for="(item, index) in planList"
                              :key="index"
                              :index="index"
                              :dataInfo="item"
                              @clickItem="selectRight"></right-list>
                </div>
              </div>
            </div>
          </div>
        </div>
        <save-cancel-btn-group v-if="!isConfirm"
                               :btnText="'确定'"
                               @cancelClick="cancelBody"
                               @confirmClick="confirm"></save-cancel-btn-group>
        <div v-else>
          <div class="btn_fixed_bottom_placeholder"></div>
          <div class="confirm_btn btn_fixed_bottom pf">
            <div class="btn_fixed_blue btn_hover_bg_blue"
                 @click.stop="cancelBody">
              完成并返回
            </div>
          </div>
        </div>
      </div>
    </custom-dialog>
  </div>
</template>

<script>
import loopCourseItem from './components/loop-course-item';
import rightList from './components/right-list';
import leftList from './components/left-list';
import coursewareList from './courseware-list';
import {
  GetCoursewareListByCoursewarePackageID,
  GetScheduleCourseListByStructureRuleID,
  BindCoursewareDistribute
} from '../../../../API/workbench';
export default {
  data () {
    return {
      isShowList: true,
      isShowBody: false,
      coursewareInfo: {},
      coursewareListName: '课件',
      oldLeftScrollTop: 0,
      oldRightScrollTop: 0,
      courseId: '',
      classInfo: {},
      loopCourseInfoList: [],
      courseInfoList: [],
      planList: [],
      rawCourseInfoList: [],
      rawPlanList: [],
      isLock: false,
      lockTop: 0,
      leftTop: 0,
      rightTop: 0,
      bodyTop: 0,
      screenWidth: 0,
      isConfirm: null,
      tickNumber: 0,
      closeNumber: 0,
      loopCourseLeft: 0
    };
  },
  props: {
    loopCourseList: Array
  },
  components: {
    loopCourseItem,
    rightList,
    leftList,
    coursewareList
  },
  computed: {
    vCourseTotal () {
      let n = 0;
      this.loopCourseInfoList.forEach((e) => {
        n += Number(e.ClassNum);
      });
      return n;
    },
    vBlockHeight () {
      return 48;
    },
    vLoopCourseEnd () {
      if (this.loopCourseInfoList.length > 4 && this.loopCourseInfoList.length - 4 - Math.abs(this.loopCourseLeft / 362 * 4) > 0) {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {
    leftTop (n, o) {
      setTimeout(() => {
        if (n == this.$refs.leftScroll.scrollTop) {
          this.leftTop = (this.leftTop / this.vBlockHeight).toFixed(0) * this.vBlockHeight;
          if (this.leftTop - this.oldLeftScrollTop < this.vBlockHeight * 3 && this.leftTop - this.oldLeftScrollTop >= this.vBlockHeight * 2) {
            this.leftTop = this.leftTop - this.vBlockHeight;
          }
          this.oldLeftScrollTop = n;
        }
      }, 200);
      if (this.oldLeftScrollTop == o) {
        console.log('滚动开始');
      }
      this.$nextTick(() => {
        this.$refs.leftScroll.scrollTop = this.leftTop;
      });
    },
    rightTop (n, o) {
      setTimeout(() => {
        if (n == this.$refs.rightScroll.scrollTop) {
          this.rightTop = (this.rightTop / this.vBlockHeight).toFixed(0) * this.vBlockHeight;
          if (this.rightTop - this.oldRightScrollTop < this.vBlockHeight * 3 && this.rightTop - this.oldRightScrollTop >= this.vBlockHeight * 2) {
            this.rightTop = this.rightTop - this.vBlockHeight;
          }
          this.oldRightScrollTop = n;
        }
      }, 200);
      if (this.oldRightScrollTop == o) {
        console.log('滚动开始');
      }
      this.$nextTick(() => {
        this.$refs.rightScroll.scrollTop = this.rightTop;
      });
    },
    bodyTop (n, o) {
      this.$refs.bodyScroll.scrollTop = this.bodyTop;
    }
  },
  mounted () {
    this.classInfo = this.loopCourseList[0];
  },
  methods: {
    selectLeft (e) {
      this.leftTop = e.target.offsetTop;
    },
    selectRight (e) {
      if (e.target.localName == 'span') {
        this.rightTop = e.target.parentElement.offsetTop;
      } else {
        this.rightTop = e.target.offsetTop;
      }
    },
    leftLoopCourse () {
      if (this.loopCourseLeft < 0) {
        this.loopCourseLeft = this.loopCourseLeft + 362;
      }
    },
    rightLoopCourse () {
      let l = this.loopCourseInfoList.length;
      let s = l - 4 - Math.abs(this.loopCourseLeft / 362 * 4);
      if (s > 0) {
        // if(s < 4){
        //   this.loopCourseLeft = this.loopCourseLeft - s * 88;
        // }else {
        this.loopCourseLeft = this.loopCourseLeft - 362;
        // }
      }
    },
    selectCoursewareInfo (data) {
      this.lockTop = 0;
      this.bodyTop = 0;
      this.rightTop = 0;
      this.leftTop = 0;
      this.isLock = false;
      this.coursewareInfo = data;
      this.isShowList = false;
      this.isShowBody = true;
      this.getPlanList();
      if (this.courseInfoList.length == 0) {
        this.selectedLoopCourseInfoList(this.loopCourseList);
      } else {
        if (this.rawCourseInfoList.length > 0) {
          this.courseInfoList = this.rawCourseInfoList;
        }
      }
    },
    cancelCoursewareList () {
      this.isShowList = false;
      if (!this.isShowBody) {
        this.$emit('cancelPopup');
      }
    },
    cancelBody () {
      this.isShowBody = false;
      this.$nextTick(() => {
        this.$emit('cancelPopup');
      });
    },
    showCoursewareList () {
      if (!this.isConfirm) {
        this.isShowList = true;
      }
    },
    confirm () {
      if (this.isLock) {
        let obj = this.getBindPlanList();

        let that = this;
        let text = '共为' + this.tickNumber + '节课分发课件';
        if (this.closeNumber > 0) {
          text = text + '，另有' + this.closeNumber + '节课将取消课件';
        }
        layer.confirm(text, {
          btn: [
            {
              name: '取消',
              callBack: function () {
                layer.close();
              }
            },
            {
              name: '确认',
              callBack: function () {
                that.bindDataInfo(obj);
                layer.close();
              }
            }
          ]
        });
      } else {
        layer.alert('请先锁定后再确认');
      }
    },
    bindDataInfo (data) {
      BindCoursewareDistribute(
        this.coursewareInfo.ID,
        this.classInfo.CourseNameKey,
        this.classInfo.GradeClassKey,
        this.coursewareInfo.MainDemoName,
        this.classInfo.CourseNameKeyValue,
        this.classInfo.GradeClassKeyValue,
        data
      ).then(
        (res) => {
          let m = this.bodyTop / this.vBlockHeight;
          for (m; m < this.planList.length; m++) {
            if (this.planList[m].OLAPKey && this.courseInfoList[m].OLAPKey) {
              this.courseInfoList[m].TeachingPlanKey = this.planList[m].OLAPKey;
              this.courseInfoList[m].CoursewarePackageKey = this.coursewareInfo.ID;
            } else if (this.courseInfoList[m].OLAPKey) {
              this.courseInfoList[m].TeachingPlanKey = '';
            }
          }
          this.isConfirm = true;
          this.$emit('complete');
        },
        (err) => {
          console.log(err.msg);
        }
      );
    },
    getBindPlanList () {
      let arr = [];
      let n = this.bodyTop / this.vBlockHeight;
      this.closeNumber = 0;
      console.log(this.planList[n], this.courseInfoList[n]);
      for (n; n < this.planList.length; n++) {
        if (this.courseInfoList[n] && this.courseInfoList[n].OLAPKey) {
          let obj = {
            ScheduleCourseKey: this.courseInfoList[n].OLAPKey,
            TeachingPlanKey: this.planList[n].ShowOrder || '',
            CoursewarePackageKey: ''
          };
          if (this.planList[n].OLAPKey) {
            obj.CoursewarePackageKey = this.coursewareInfo.ID;
          }
          if (this.courseInfoList[n].TeachingPlanLock) {
            arr.push(obj);
          } else if (
            this.courseInfoList[n].TeachingPlanKey != 0 &&
            this.courseInfoList[n].CoursewarePackageKey != 0 &&
            !this.planList[n].OLAPKey
          ) {
            this.closeNumber++;
            arr.push(obj);
          }
        }
      }
      return arr;
    },
    leftScroll (e) {
      this.leftTop = e.target.scrollTop;
    },
    rightScroll (e) {
      this.rightTop = e.target.scrollTop;
    },
    clickLock () {
      if (this.isConfirm) {
        return false;
      }
      if (
        this.rightTop % this.vBlockHeight != 0 ||
        this.leftTop % this.vBlockHeight != 0
      ) {
        this.leftTop =
          (this.leftTop / this.vBlockHeight).toFixed(0) * this.vBlockHeight;
        this.rightTop =
          (this.rightTop / this.vBlockHeight).toFixed(0) * this.vBlockHeight;
      }
      this.isLock = !this.isLock;
      if (this.isLock) {
        this.rawCourseInfoList = this.$utils.parseJson(this.courseInfoList);
        this.rawPlanList = this.$utils.parseJson(this.planList);
        if (this.leftTop > this.rightTop) {
          // 左边滚出距离大于右边
          const t = this.leftTop / this.vBlockHeight;
          let h = t;
          if (this.rightTop > 0) {
            h = h - this.rightTop / this.vBlockHeight;
          }
          for (let i = 0; i < h; i++) {
            this.planList.unshift({});
          }
          this.$nextTick(() => {
            this.lockTop = t * this.vBlockHeight;
            this.bodyTop = this.lockTop;
          });
          if (this.planList.length < this.courseInfoList.length) {
            let j = this.courseInfoList.length - this.planList.length;
            for (let o = 0; o < j; o++) {
              this.planList.push({});
            }
          } else {
            let j = this.planList.length - this.courseInfoList.length;
            for (let o = 0; o < j; o++) {
              this.courseInfoList.push({});
            }
          }
        } else {
          // 右边滚出距离大于左边
          const t = this.rightTop / this.vBlockHeight;
          let h = t;
          if (this.leftTop > 0) {
            h = h - this.leftTop / this.vBlockHeight;
          }
          console.log(h, 'h');
          for (let i = 0; i < h; i++) {
            this.courseInfoList.unshift({});
          }
          this.$nextTick(() => {
            this.lockTop = t * this.vBlockHeight;
            this.bodyTop = this.lockTop;
          });
          if (this.courseInfoList.length < this.planList.length) {
            let j = this.planList.length - this.courseInfoList.length;
            for (let o = 0; o < j; o++) {
              this.courseInfoList.push({});
            }
          } else {
            let j = this.courseInfoList.length - this.planList.length;
            for (let o = 0; o < j; o++) {
              this.planList.push({});
            }
          }
        }
        this.$nextTick(() => {
          this.tickNumber = 0;
          let m = this.lockTop / this.vBlockHeight;
          for (m; m < this.planList.length; m++) {
            if (
              this.planList[m] &&
              this.planList[m].OLAPKey &&
              this.courseInfoList[m].OLAPKey
            ) {
              this.courseInfoList[m].TeachingPlanLock = true;
              this.tickNumber++;
            }
          }
        });
      } else {
        this.lockTop = 0;
        this.bodyTop = 0;
        this.rightTop = 0;
        this.leftTop = 0;
        this.courseInfoList = this.rawCourseInfoList;
        this.planList = this.rawPlanList;
      }
    },
    selectedLoopCourseInfoList (data) {
      if (data) {
        this.loopCourseInfoList = data;
      }
      this.getCourseInfoList();
    },
    getCourseInfoList () {
      let arr = [];
      this.loopCourseInfoList.forEach(e => {
        arr.push(e.ID)
      })
      GetScheduleCourseListByStructureRuleID(arr).then(
        (res) => {
          res.data.forEach((e) => {
            e.TeachingPlanLock = false;
            this.courseInfoList.push(e);
          });
          this.courseInfoList = this.courseInfoList.sort((a, b) => {
            return a.DateTime.split(' ')[0].split('-')[0] - b.DateTime.split(' ')[0].split('-')[0];
          });
          this.courseInfoList = this.courseInfoList.sort((a, b) => {
            if (a.DateTime.split(' ')[0].split('-')[0] == b.DateTime.split(' ')[0].split('-')[0]) {
              return a.DateTime.split(' ')[0].split('-')[1] - b.DateTime.split(' ')[0].split('-')[1];
            }
          });
          this.courseInfoList = this.courseInfoList.sort((a, b) => {
            if (a.DateTime.split(' ')[0].split('-')[0] == b.DateTime.split(' ')[0].split('-')[0] && a.DateTime.split(' ')[0].split('-')[1] == b.DateTime.split(' ')[0].split('-')[1]) {
              return a.DateTime.split(' ')[0].split('-')[2] - b.DateTime.split(' ')[0].split('-')[2];
            }
          });
          this.courseInfoList = this.courseInfoList.sort((a, b) => {
            if (a.DateTime.split(' ')[0] == b.DateTime.split(' ')[0]) {
              return a.DateTime.split(' ')[1].split(':')[0] - b.DateTime.split(' ')[1].split(':')[0];
            }
          });
          this.courseInfoList = this.courseInfoList.sort((a, b) => {
            if (a.DateTime.split(' ')[0] == b.DateTime.split(' ')[0] && a.DateTime.split(' ')[1].split(':')[0] == b.DateTime.split(' ')[1].split(':')[0]) {
              return a.DateTime.split(' ')[1].split(':')[1] - b.DateTime.split(' ')[1].split(':')[1];
            }
          });
          for (let i = 0; i < 6; i++) {
            this.courseInfoList.push({});
          }
        },
        (err) => {
          console.log(err.msg);
        }
      );
    },
    getPlanList () {
      this.planList = [];
      GetCoursewareListByCoursewarePackageID(this.coursewareInfo.ID).then(
        (res) => {
          let i = 0;
          res.data.forEach((e) => {
            e.index = i;
            this.planList.push(e);
            i++;
          });
          for (let i = 0; i < 6; i++) {
            this.planList.push({});
          }
        },
        (err) => {
          console.log(err.msg);
        }
      );
    }
  }
};
</script>
<style scoped>
.list_left {
  position: absolute;
  left: 0;
  width: 38px;
  height: 48px;
  top: 0;
  background: #fff url(../../../../../public/image/select_icon_left.png)
    no-repeat 18px center;
  background-size: 8px;
  z-index: 2;
}

.list_left.type_gray {
  background-image: url(../../../../../public/image/select_icon_left_gray.png);
}

.list_right {
  position: absolute;
  right: 0;
  width: 38px;
  height: 48px;
  top: 0;
  background: #fff url(../../../../../public/image/select_icon@2x.png) no-repeat
    12px center;
  background-size: 8px;
  z-index: 2;
}

.list_right.type_gray {
  background-image: url(../../../../../public/image/select_icon_gray@2x.png);
}
</style>
