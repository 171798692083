
<template>
  <!-- 教学信息 -->
  <div class="form_info_edit">
    <div class="">
      <div>
        <box-content-list :dataInfoList="vCourseNameListForQuick"
                          :powerName="'abce'"
                          :isComplement="true"
                          :hasActive="portInitSettings.activeInfo.canActive"
                          :formTitle='"开设的课程"'
                          :operateButName="'设置'"
                          :ModulePowerKey="45"
                          :showItemCount="showItemCount"
                          @onOpreate="doOpreateForCourseName"></box-content-list>
      </div>

      <div>
        <box-content-list :dataInfoList="vSquadClassForQuick"
                          :powerName="'abce'"
                          :isComplement="true"
                          :hasActive="false"
                          :formTitle='"开设的班级"'
                          :operateButName="'设置'"
                          :ModulePowerKey="45"
                          :showItemCount="showItemCount"
                          @onOpreate="doOpreateForSquadClass"></box-content-list>

      </div>

      <div>
        <box-content-list :dataInfoList="vClassroomListForQuick"
                          :powerName="'abce'"
                          :hasActive="portInitSettings.activeInfo.canActive"
                          :formTitle='"上课的教室"'
                          class="no_border_bottom"
                          :ModulePowerKey="45"
                          :showItemCount="showItemCount"
                          @onOpreate="doOpreateForClassroom"></box-content-list>
      </div>

    </div>
  </div>
</template> 

<script>
import boxContentList from './box-content-list';
import {
  getCourseTypeNameList,
  getUsingClass,
  MessageSearchPageForSchoolRoom,
  StartTheClassType, // 启用 课名课类
  StopTheClassType, // 停用 课名课类
  FinishedGrade, // 结束班级
  StartSchoolRoom, // 启用 教室
  StopSchoolRoom // 停用 教室
} from '../../../../API/workbench';
export default {
  data () {
    return {
      showItemCount: (document.body.clientWidth >= 1890 && document.body.clientWidth <= 1930) ? 12 : (document.body.clientWidth >= 1570 && document.body.clientWidth <= 1890) ? 10 : 8,
      newInputText: '', // 默认值
      // switchItemCount: 0, // 开关
      shwoPushItem: { push: '', isShowMainForm: false, formTitle: '', isReadonly: false, editItem: {} }, // 显示弹出层
      toSelectItem: '', // 选择项
      toSelectItemValue: '', // 选择项名称
      readonlyInfo: false, // 是否只读

      portInitSettings: {
        lastRefreshTime: '',
        routerPath: '', // 通用操作表单路由
        activeInfo: {
          canActive: true,
          apiActive: '', // 启用接口
          apiUnActive: '', // 停用接口
          apiFinish: '', // 结束接口
          activeCallBack: (data) => {
            data.statusKey = 1; // 字段名不确定，需要确认 ToDo
            data.statusKeyValue = '启用';
          },
          unActiveCallBack: (data) => {
            data.statusKey = 0;// 字段名不确定，需要确认 ToDo
            data.statusKeyValue = '停用';
          },
          finishCallBack: (data) => {
            data.statusKey = 0;// 字段名不确定，需要确认 ToDo
            data.statusKeyValue = '停用';
          },
          otherOpreateHandler: null // 其它非通用操作处理方法
        }
      },
      courseNameList: [], // 课程列表
      classroomList: [], // 教室列表
      squadClass: [], // 班级列表   同意
      // 查询教室
      parameter: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配教室名称
        StateKey: 0 // 字符串	可选	0	数据源：GET	小区状态 2 启用 3停用
      }
    };
  },
  components: {
    boxContentList
  },
  created () {
    this.loadData();
    this.registerBusEvent();
  },
  mounted () {
    // this.$dialog.register(this, ['courseNameSetting', 'squadClassSetting', 'classroomSetting']);
  },
  methods: {
    loadData () {
      this.portInitSettings.lastRefreshTime = Date.new();
      this.upCourseNameData();
      this.squadClass = [];
      this.upSquadClassData(true);
      this.upSquadClassData(false);
      this.upClassroomData();
    },
    upLoadSettingData (upDataName) {
      this.portInitSettings.lastRefreshTime = Date.new();
      switch (upDataName) {
        case 'courseNameSetting':// 刷新课名
          this.upCourseNameData();
          break;
        case 'squadClassSetting':// 刷新班级
          this.squadClass = [];
          this.upSquadClassData(true);
          this.upSquadClassData(false);
          break;
        case 'classroomSetting':// 刷新教室
          this.upClassroomData();
          break;
        default:
          break;
      }
    },
    showModulesName (value) {
      switch (value) {
        case 'courseNameSetting':
          return '课程';
        case 'squadClassSetting':
          return '班级';
        case 'classroomSetting':
          return '教室';
        default:
          break;
      }
    },
    // 更新课名数据
    upCourseNameData () {
      getCourseTypeNameList().then(result => {
        this.courseNameList = [];
        result.data.forEach(o => {
          if (o.TypeKey == 2) {
            this.courseNameList.push(o);
          }
        });
        // console.log(result.data, '刷新课类/刷新课名');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 更新班级数据
    upSquadClassData (bol) {
      let num = bol ? 4 : 5;
      getUsingClass(num, 'OLAPKey', 'desc').then(result => {
        result.data.forEach(o => {
          this.squadClass.push(o);
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 更新教室数据
    upClassroomData () {
      MessageSearchPageForSchoolRoom(this.parameter).then(result => {
        this.classroomList = [];
        this.classroomList = result.data.PageDataList;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 执行课类相关操作
    doOpreateForCourseClass (opreateType, dataKey, data, callBack) {
      this.portInitSettings.routerPath = 'courseClassSetting';
      this.portInitSettings.activeInfo.apiActive = StartTheClassType;
      this.portInitSettings.activeInfo.apiUnActive = StopTheClassType;
      let options = this.getOptions(this.portInitSettings, opreateType, dataKey, data, callBack);
      this.doOpreate(options);
    },
    // 执行课名相关操作
    doOpreateForCourseName (opreateType, dataKey, data, callBack) {
      if (opreateType == 'setting') {
        let item = {
          name: '课程课类管理',
          routerName: 'mendiansettingform',
          routertitle: '门店设置',
          auth: true,
          roleName: '',
          moduleName: 'courseClassManageSetting',
          ModulePowerKey: ''
        };
        this.$bus.emit('needOpenRouter', item);
      } else {
        this.portInitSettings.routerPath = 'courseNameSetting';
        this.portInitSettings.activeInfo.apiActive = StartTheClassType;
        this.portInitSettings.activeInfo.apiUnActive = StopTheClassType;
        let options = this.getOptions(this.portInitSettings, opreateType, dataKey, data, callBack);
        this.doOpreate(options);
      }
    },
    // 执行班级相关操作
    doOpreateForSquadClass (opreateType, dataKey, data, callBack) {
      if (opreateType == 'setting') {
        let item = {
          name: '班级设置',
          routerName: 'mendiansettingform',
          routertitle: '门店设置',
          auth: true,
          roleName: '',
          moduleName: 'gradeClassManageSetting',
          ModulePowerKey: ''
        };
        this.$bus.emit('needOpenRouter', item);
      } else {
        this.portInitSettings.routerPath = 'squadClassSetting';
        this.portInitSettings.activeInfo.apiFinish = FinishedGrade;
        let options = this.getOptions(this.portInitSettings, opreateType, dataKey, data, callBack);
        this.doOpreate(options);
      }
    },
    // 执行教室相关操作
    // opreateType: add edit view active unActive
    doOpreateForClassroom (opreateType, dataKey, data, callBack) {
      this.portInitSettings.routerPath = 'classroomSetting';
      this.portInitSettings.activeInfo.apiActive = StartSchoolRoom;
      this.portInitSettings.activeInfo.apiUnActive = StopSchoolRoom;
      let options = this.getOptions(this.portInitSettings, opreateType, dataKey, data, callBack);
      this.doOpreate(options);
    },
    // 通用操作执行
    doOpreate (options) {
      options = options || {
        lastRefreshTime: '',
        routerPath: '', // 通用操作表单路由
        activeInfo: {
          canActive: true,
          apiActive: null,
          activeCallBack: null, // 启用回调
          apiUnActive: null,
          unActiveCallBack: null, // 停用回调
          apiFinish: null,
          finishCallBack: null // 结束回调
        },
        opreateType: '', // add edit view active unActive setting
        dataKey: 0, // 数据Key，新增为0或null
        data: null, // 操作数据对象，新增为null
        callBack: null, // 回调，必有
        otherOpreateHandler: null // 其它非通用操作处理方法
      };
      options.dataKey = options.dataKey || 0;
      this.shwoPushItem.push = options.routerPath;
      switch (options.opreateType) {
        case 'add':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '新增' + this.showModulesName(options.routerPath),
            moduleName: options.routerPath,
            data: { editItem: {}, isReadonly: false },
            callBack: {
              afterSuccess: () => { this.upLoadSettingData(options.routerPath); }
            }
          });
          break;
        case 'edit':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '修改' + this.showModulesName(options.routerPath),
            moduleName: options.routerPath,
            data: { editItem: options.data, isReadonly: false },
            callBack: {
              afterSuccess: () => { this.upLoadSettingData(options.routerPath); }
            }
          });
          break;
        case 'view':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '查看' + this.showModulesName(options.routerPath),
            moduleName: options.routerPath,
            data: { editItem: options.data, isReadonly: true }
          });
          break;
        case 'active':
          if (options.activeInfo.canActive) {
            var data = '';
            if (options.activeInfo.apiActive == StartTheClassType) { // 课名课类
              data = { IsSameContinue: '0', OLAPKey: options.dataKey };
            } else {
              data = options.dataKey;
            }
            this.execAPIForOpreate(options.activeInfo.apiActive, data, options.routerPath, resultData => {
              options.activeInfo.activeCallBack(options.data);
              options.callBack(options.data);
              options.lastRefreshTime = Date.new();
            });
          }
          break;
        case 'unActive':
          if (options.activeInfo.canActive) {
            this.execAPIForOpreate(options.activeInfo.apiUnActive, options.dataKey, options.routerPath, resultData => {
              options.activeInfo.unActiveCallBack(options.data);
              options.callBack(options.data);
              options.lastRefreshTime = Date.new();
            });
          }
          break;
        case 'finish':
          if (options.activeInfo.canActive) {
            this.execAPIForOpreate(options.activeInfo.apiFinish, options.dataKey, options.routerPath, resultData => {
              options.activeInfo.finishCallBack(options.data);
              options.callBack(options.data);
              options.lastRefreshTime = Date.new();
            });
          }
          break;
        default:// 超出标准操作，自行处理
          if (options.activeInfo.otherOpreateHandler) {
            options.otherOpreateHandler(options.opreateType, options.dataKey, options.data, options.callBack);
          }
          break;
      }
    },
    execAPIForOpreate (apiFN, data, upDataName, callBack) {
      apiFN(data).then(result => {
        this.upLoadSettingData(upDataName);
        callBack(result);
      }, error => {
        layer.alert(error.msg);
      });
    },
    getOptions (settings, opreateType, dataKey, data, callBack) {
      let options = {
        opreateType: opreateType, // add edit view active unActive
        dataKey: dataKey, // 数据Key，新增为0或null
        data: data, // 操作数据对象，新增为null
        callBack: callBack // 回调，必有
      };
      // 合并参数设定
      return Object.assign(settings, options);
    },
    registerBusEvent () {
      this.$bus.on(this, 'switchHeaderBarToMendiansettingform', () => {
        this.loadData();
      });
      // 课程课类管理=》 新增或修改，停用,启用后的刷新操作
      this.$bus.on(this, 'CourseClassNameAddOrEdit', (data) => {
        this.upCourseNameData();
      });
      this.$bus.on(this, 'AfterClassGradeAddOrEdit', (type, result) => {
        this.squadClass = [];
        this.upSquadClassData(true);
        this.upSquadClassData(false);
      });
    }
  },
  computed: {
    vCourseNameListForQuick () {
      // ToDo: 需要转换成通用组件需要的数据结构
      let list = [];
      if (this.portInitSettings.lastRefreshTime != '') {
        this.courseNameList.forEach(o => {
          let item = { dataKey: o.OLAPKey, itemName: o.MainDemoName, isActive: o.StateKey == 2, data: o, isMenu: false, type: 'courseName' };
          list.push(item);
        });
      }
      // console.log(list, 'list');
      return list;
    },
    vSquadClassForQuick () {
      // ToDo: 需要转换成通用组件需要的数据结构
      let list = [];
      if (this.portInitSettings.lastRefreshTime != '') {
        this.squadClass.forEach(o => {
          let item = { dataKey: o.OLAPKey, itemName: o.MainDemoName, isActive: o.StutasKey == 4, data: o, isMenu: false };
          list.push(item);
        });
      }
      // console.log(list, 'list');
      return list;
    },
    vClassroomListForQuick () {
      // ToDo: 需要转换成通用组件需要的数据结构
      let list = [];
      if (this.portInitSettings.lastRefreshTime != '') {
        this.classroomList.forEach(o => {
          let item = { dataKey: o.OLAPKey, itemName: o.MainDemoName, isActive: o.StateKey == 2, data: o, isMenu: false };
          list.push(item);
        });
      }
      return list;
    }
  }
};
</script>

  <style>
</style>