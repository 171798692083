<template>
  <div>
    <div class="recommend_histories_popup">
      <data-info :dataInfo="templateData"></data-info>

      <div class="btn_box">
        <div class="btn_item"
             v-for="item in btnOptionList"
             :key="item.type">
          <zx-button :btnClass="'btn_hover_bg_blue'"
                     :btnText="item.title"
                     :isSingleSubmit="true"
                     :singleSubmitType="'static'"
                     @click="clcikBtn(item)"></zx-button>
        </div>
        <div class="btn_item">
          <input-select :formTitle="'同步小程序服务器域名'"
                        :id="'key'"
                        :name="'value'"
                        :dataList="tbWebViewDomainList"
                        @selectedItem="modifyDomain"></input-select>
        </div>
        <div class="btn_item">
          <input-select :formTitle="'同步小程序业务域名配置'"
                        :id="'key'"
                        :name="'value'"
                        :dataList="tbWebViewDomainList"
                        @selectedItem="chgtbWebViewDomain"></input-select>
        </div>
      </div>

      <div class="all_popup_nav_ul">
        <div class="nav_list">
          <span>发布记录</span>
        </div>
      </div>
      <div class="popup_content">
        <div class="popup_content_ul">
          <stage-list v-for="(item, index) in preparePublishRecords"
                      :key="index"
                      :dataInfo="item"></stage-list>
        </div>
        <!-- <basics-nodata-tips
            v-if="preparePublishRecords.length == 0"
            style="margin-top: 80px"
          ></basics-nodata-tips> -->
      </div>
    </div>

    <custom-dialog :title="'体验版二维码'"
                   :visible.sync="isShowQRCodePopup"
                   :before-close="closeQRCodeUrlDialog">
      <div>
        <img :src="QRCodeUrl"
             class="QRcodeImg">
      </div>
    </custom-dialog>

    <custom-dialog :title="'修改预发布小程序APPID'"
                   :visible.sync="isShowEditAPPIDPopup"
                   :before-close="closeEditAPPIDPopup">
      <edit-appid v-model="appID"
                  :templateID="templateData.OLAPKey"
                  @input="loadPreparePublishTemplateMsg"
                  @cancelClick="closeEditAPPIDPopup"></edit-appid>
    </custom-dialog>

    <custom-dialog :title="'输入体验二维码路径'"
                   :visible.sync="isShowGetQRCodePopup"
                   :before-close="closeGetQRCodePopup">
      <get-qrcode :appID="appID"
                  @cancelClick="closeGetQRCodePopup"
                  @getQRCodeSuccess="getQRCodeForExperience"></get-qrcode>
    </custom-dialog>

    <custom-dialog :title="'版本发布审核设置'"
                   :visible.sync="isShowVersionsAuditSetting"
                   :before-close="closeAuditSettingPopup">
      <versions-audit-setting @closePopup="closeAuditSettingPopup"
                              @succeed="loadPreparePublishTemplateMsg"></versions-audit-setting>
    </custom-dialog>

    <custom-dialog :title="'版本隐私设置'"
                   :visible.sync="isShowVersionsPrivacySetting"
                   :before-close="closePrivacySettingPopup">
      <versions-privacy-setting @closePopup="closePrivacySettingPopup"
                                @succeed="loadPreparePublishTemplateMsg"></versions-privacy-setting>
    </custom-dialog>
  </div>
</template>
<script>
import dataInfo from './data-info';
import stageList from './stage-list';
import zxButton from '../../common/zx-button';
import editAppid from './edit-appid';
import getQrcode from './get-qrcode';
import versionsAuditSetting from '../versions-audit-setting';
import versionsPrivacySetting from '../versions-privacy-setting';
import { GetPreparePublishMsgByTemplateID, GetPreparePublishMsgByMiniProgramID, SetForVersion, ModifyDomain, SetWebViewDomain, SubmitAudit, UndoCodeAudit, UploadCode, ReleasePrepareMiniProgram } from '../../../API/workbench';
export default {
  data () {
    return {
      templateData: {}, // 模板信息
      preparePublishRecords: [], // 预发布版本的发布记录
      btnOptionList: [
        {
          title: '上传代码',
          type: 'uploadCode'
        },
        {
          title: '获取体验版二维码',
          type: 'GetQRCodeForExperience'
        },
        {
          title: '提交审核',
          type: 'submitCheck'
        },
        {
          title: '审核撤回',
          type: 'checkWithdraw'
        },
        {
          title: '重新提交审核',
          type: 'reSubmitCheck'
        },
        {
          title: '发布',
          type: 'preparePublish'
        },
        {
          title: '设为现行版本',
          type: 'setCurrentVersion'
        },
        {
          title: '修改预发布小程序APPID',
          type: 'editPreparePublishTemplateAppID'
        },
        {
          title: '最新版本隐私设置',
          type: 'versionsPrivacySetting'
        },
        {
          title: '最新版本发布审核设置',
          type: 'versionsAuditSetting'
        }
        // {
        //   title:'同步小程序服务器域名',
        //   type:'modifyDomain'
        // },
        // {
        //   title:'同步小程序业务域名配置',
        //   type:'setWebViewDomain'
        // }
      ],
      isShowQRCodePopup: false, // 是否显示体验二维码
      QRCodeUrl: '', // 小程序体验二维码
      isShowEditAPPIDPopup: false, // 显示修改APPID弹窗,
      isShowGetQRCodePopup: false, // 显示输入体验二维码路径,
      isShowVersionsAuditSetting: false,
      isShowVersionsPrivacySetting: false,
      templateID: 0,
      appID: 0,
      curSelectedData: { id: 0, name: '' },
      tbWebViewDomainList: [
        { key: 0, value: '' },
        { key: 1, value: '预发布小程序' },
        { key: 2, value: '全部' }
      ]
    };
  },
  props: {
    miniProgramData: {
      type: Object,
      required: true
    }
  },
  components: {
    dataInfo,
    stageList,
    zxButton,
    editAppid,
    getQrcode,
    versionsAuditSetting,
    versionsPrivacySetting
  },
  computed: {
  },
  created () {
    this.appID = this.miniProgramData.MiniProgramAppID;
    this.templateID = this.miniProgramData.TemplateID;
    this.loadPreparePublishTemplateMsg();
  },
  methods: {
    // 查询模板信息
    loadPreparePublishTemplateMsg () {
      GetPreparePublishMsgByTemplateID().then((res) => {
        this.templateData = res.data || {};
        this.appID = this.templateData.PreMiniProgramAppID;
        this.loadPreparePublishTemplateRecords();
      }, (err) => {
        layer.alert(err.msg);
      }
      );
    },
    // 查询模板发布记录
    loadPreparePublishTemplateRecords () {
      GetPreparePublishMsgByMiniProgramID(this.templateID, this.appID).then((res) => {
        this.preparePublishRecords = res.data;
      }, (err) => {
        layer.alert(err.msg);
      }
      );
    },
    // 点击按钮
    clcikBtn (item) {
      switch (item.type) {
        case 'uploadCode':
          this.uploadCode();
          break;
        case 'GetQRCodeForExperience':
          this.openGetQRCodeDialog();
          break;
        case 'submitCheck':
          this.submitCheck();
          break;
        case 'checkWithdraw':
          this.checkWithdraw();
          break;
        case 'reSubmitCheck':
          this.reSubmitCheck();
          break;
        case 'preparePublish':
          this.preparePublish();
          break;
        case 'modifyDomain':
          this.modifyDomain();
          break;
        case 'setCurrentVersion':
          this.setCurrentVersion();
          break;
        case 'setWebViewDomain':
          this.setWebViewDomain();
          break;
        case 'editPreparePublishTemplateAppID':
          this.openEditAPPIDDialog();
          break;
        case 'versionsPrivacySetting':
          this.isShowVersionsPrivacySetting = true;
          break;
        case 'versionsAuditSetting':
          this.isShowVersionsAuditSetting = true;
          break;
        default:
          break;
      }
    },
    // 上传代码
    uploadCode (callBack) {
      UploadCode(this.templateID, this.templateData.user_version, this.templateData.user_desc, this.appID).then(
        (res) => {
          layer.alert('上传成功');
          if (typeof callBack == 'function') {
            callBack();
          }
        },
        (err) => {
          layer.alert(err.msg);
          if (typeof callBack == 'function') {
            callBack();
          }
        }
      );
    },
    // 获取体验版二维码
    getQRCodeForExperience (url) {
      this.closeGetQRCodePopup();
      this.QRCodeUrl = url;
      this.isShowQRCodePopup = true;
    },
    // 关闭体验二维码
    closeQRCodeUrlDialog () {
      this.isShowQRCodePopup = false;
    },
    // 提交审核
    submitCheck (callBack) {
      SubmitAudit(this.appID).then(
        (res) => {
          layer.alert('提交成功');
          if (typeof callBack == 'function') {
            callBack();
          }
        },
        (err) => {
          layer.alert(err.msg);
          console.log(err);
          if (typeof callBack == 'function') {
            callBack();
          }
        }
      );
    },
    // 审核撤回
    checkWithdraw (callBack) {
      UndoCodeAudit(this.appID).then(
        (res) => {
          layer.alert('撤回成功');
          if (typeof callBack == 'function') {
            callBack();
          }
        },
        (err) => {
          layer.alert(err.msg);
          console.log(err);
          if (typeof callBack == 'function') {
            callBack();
          }
        }
      );
    },
    // 重新提交审核
    reSubmitCheck (callBack) {
      SubmitAudit(this.appID).then(
        (res) => {
          layer.alert('提交成功');
          if (typeof callBack == 'function') {
            callBack();
          }
        },
        (err) => {
          layer.alert(err.msg);
          console.log(err);
          if (typeof callBack == 'function') {
            callBack();
          }
        }
      );
    },
    // 发布
    preparePublish (callBack) {
      ReleasePrepareMiniProgram(this.appID).then(
        (res) => {
          layer.alert('发布成功');
          if (typeof callBack == 'function') {
            callBack();
          }
        },
        (err) => {
          if (typeof callBack == 'function') {
            callBack();
          }
          layer.alert(err.msg);
          console.log(err);
        }
      );
    },
    // 同步小程序服务器域名
    modifyDomain (item) {
      console.log('modifyDomain', item);
      let appid = '';
      if (item.key == 1) {
        appid = this.appID;
      }
      ModifyDomain(appid).then(
        (res) => {
          layer.alert('同步成功');
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    // 同步小程序业务域名
    chgtbWebViewDomain (item) {
      console.log('modifyDomain', item);
      let appid = '';
      if (item.key == 1) {
        appid = this.appID;
      }
      SetWebViewDomain(appid).then(
        (res) => {
          layer.alert('同步成功');
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    // 设为现行版本
    setCurrentVersion (callBack) {
      SetForVersion(this.templateID).then(
        (res) => {
          layer.alert('设置成功');
          if (typeof callBack == 'function') {
            callBack();
          }
        },
        (err) => {
          layer.alert(err.msg);
          console.log(err);
          if (typeof callBack == 'function') {
            callBack();
          }
        }
      );
    },
    // 打开修改APPID的弹窗
    openEditAPPIDDialog () {
      this.isShowEditAPPIDPopup = true;
    },
    // 关闭修改APPID的弹窗
    closeEditAPPIDPopup () {
      this.isShowEditAPPIDPopup = false;
    },
    // 打开输入体验二维码地址的弹窗
    openGetQRCodeDialog () {
      this.isShowGetQRCodePopup = true;
    },
    // 关闭输入体验二维码地址的弹窗
    closeGetQRCodePopup () {
      this.isShowGetQRCodePopup = false;
    },
    closeAuditSettingPopup () {
      this.isShowVersionsAuditSetting = false;
    },
    closePrivacySettingPopup () {
      this.isShowVersionsPrivacySetting = false;
    }
  }
};
</script>
<style>
.btn_box {
  width: 400px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  line-height: 45px;
  margin-left: 5px;
}
.btn_box .btn_item .is-round {
  width: 168px;
}
.btn_item {
  margin-right: 5px;
}
.recommend_histories_popup {
  width: 410px;
  height: 565px;
  padding-top: 20px;
  overflow: scroll;
}

.recommend_histories_popup .all_workbench_brand_info {
  margin: 0 20px 10px;
}

.all_popup_nav_ul {
  width: 100%;
  display: flex;
  font-size: 14px;
  line-height: 32px;
  color: #666;
  text-align: center;
  border-bottom: 1px solid #ececec;
}

.all_popup_nav_ul .nav_list {
  flex: 1;
  cursor: pointer;
}

.all_popup_nav_ul .nav_list span {
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  color: black;
}

.all_popup_nav_ul .nav_list.opt span {
  color: #3498db;
  border-bottom: 3px solid #3498db;
}

.recommend_histories_popup .popup_content_ul {
  position: relative;
}

.recommend_histories_popup .popup_content_ul::after {
  content: '';
  position: absolute;
  left: 30px;
  width: 0;
  top: 40px;
  bottom: 0;
  border-left: 1px dashed #999;
}
.QRcodeImg {
  width: 400px;
  padding-left: 10px;
}
</style>