<template>
    <div class="thirdparty_tencent">
        <progress-radio :completeNumber="vCurrentProgress"></progress-radio>
        <div class="thirdparty_tencent_from">
            <div class="thirdparty_tencent_from_list">
                <div class="from_list_title">
                    <span>1. 我已拥有微信认证的服务号</span>
                    <a href="https://mp.weixin.qq.com/s/OvMjMv8QSUg0Z71FfGpTwQ" target="_blank" class="font_blue">（如何申请并认证微信服务号）</a>
                </div>
                <div class="from_list_select">
                    <el-radio v-model="oneRadio" label="1">是</el-radio>
                    <el-radio v-model="oneRadio" label="2">否</el-radio>
                    <div v-if="oneRadio == '2'" class="from_list_btn btn_hover_white fl_type">去申请</div>
                </div>
            </div>
            <div v-if="oneRadio == '1'" class="thirdparty_tencent_from_list">
                <div class="from_list_title">
                    <span>2. 我已申请服务号模板消息</span>
                    <a href="https://mp.weixin.qq.com/s/BQ4Xiqe0K8lNx1KmQNdt-g" target="_blank" class="font_blue">（如何申请模板消息）</a>
                </div>
                <div class="from_list_select">
                    <el-radio v-model="twoRadio" label="1">是</el-radio>
                    <el-radio v-model="twoRadio" label="2">否</el-radio>
                </div>
            </div>
            <div v-if="twoRadio == '1'" class="thirdparty_tencent_from_list">
                <div class="from_list_title">
                    <span>3. 申请开通独立模式</span>
                    <span class="font_gray">（请联系您的专属客服开通）</span>
                </div>
                <div v-if="mendianType != 1" @click="applyMendianModule" class="from_list_btn btn_hover_white" :class="{norole:mendianType == 2}">{{mendianType != 2?'去申请':'申请中'}}</div>
                <div v-else class="from_list_tick">已开通</div>
            </div>
            <!--  -->
            <div v-if="twoRadio == '1' && mendianType == 1" class="thirdparty_tencent_from_list">
                <div class="from_list_title">   
                    <span>4. 将服务号授权给治学软件</span>
                    <a href="https://mp.weixin.qq.com/s/W35Z4nvm5dSOw_rZC58d4A" target="_blank" class="font_blue">（如何授权）</a>
                </div>
                <div  class="from_list_btn btn_hover_white" @click.stop="getQRCode">立即授权</div>
            </div>
        </div>
        <custom-dialog width="1180px"
                       class="WXQRcode_dialog cancelShadow"
                       :title="'微信扫码授权'"
                       :visible.sync="isWXQRcode"
                       :before-close="closeWXQRcode">
            <iframe id="ifra" :src="'/OpenPlatform/OpenPlatformAuthRequest.php?SaaSClientKey='+vMendianInfo.OLAPKey + '&client_id=' + this.$store.getters.clientId" class="WXQRcodeIframe" frameborder="0"></iframe>
        </custom-dialog>
        <custom-dialog width="410px"
                       :title="'微信扫码授权'"
                       :visible.sync="isMendianQRcode"
                       :before-close="closeMendianQRcode">
                       <WXQRcode :QRCodeImg="vQRcode"></WXQRcode>
        </custom-dialog>
    </div>
</template>
<script>
import progressRadio from './progress-radio';
import WXQRcode from './WXQRcode';
import { GetIndependentModule, ApplyIndependentModule } from '../../../API/workbench';
export default {
  data () {
    return {
      currentProgress: 1,
      mendianType: 0,
      isWXQRcode: false,
      isMendianQRcode: false,
      oneRadio: '2',
      twoRadio: '2'
    };
  },
  computed: {
    vMendianInfo () {
      console.log(this.$store.getters.SaaSClientInfo);
      return this.$store.getters.SaaSClientInfo;
    },
    vQRcode () {
      if (this.vMendianInfo.WktInfo && this.vMendianInfo.WktInfo.QRcode) {
        if (this.vMendianInfo.WktInfo.QRcode.indexOf('http') > -1) {
          return this.vMendianInfo.WktInfo.QRcode;
        } else {
          return this.$store.getters.CDNURL + this.vMendianInfo.WktInfo.QRcode;
        }
      } else {
        return '';
      }
    },
    vCurrentProgress () {
      let num = 1;
      if (this.oneRadio == 1) {
        num = 2;
      }
      if (this.twoRadio == 1) {
        num = 3;
      }
      if (this.mendianType == 1) {
        num = 4;
      }
      return num;
    }
  },
  watch: {
    oneRadio (n, o) {
      if (n == '2') {
        this.twoRadio = '2';
      }
    }
  },
  components: {
    progressRadio, WXQRcode
  },
  created () {
    this.getMendianType();
    this.busFunction();
  },
  methods: {
    closeWXQRcode () {
      this.isWXQRcode = false;
    },
    closeMendianQRcode () {
      this.isMendianQRcode = false;
      this.$nextTick(() => {
        this.$emit('closeDialog');
      });
    },
    busFunction () {
      this.$bus.on(this, 'thirdpartyTencentMandate', (bool, data) => {
        if (bool) {
          this.isMendianQRcode = true;
        }
        this.closeWXQRcode();
                // this.$emit('closeDialog');
      });
    },
    getMendianType () {
      GetIndependentModule().then(res => {
        this.mendianType = res.data.applyIndependentStatus;
        if (this.mendianType != 0) {
          this.oneRadio = '1';
          this.twoRadio = '1';
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    applyMendianModule () {
      if (this.mendianType != 2) {
        ApplyIndependentModule().then(res => {
          this.getMendianType();
        }, err => {
          layer.alert(err.msg);
        });
      }
    },
    getQRCode () {
      this.isWXQRcode = true;
      let time = setTimeout(() => {
        this.isWXQRcode = false;
        layer.alert('授权失败，请重新点击立即授权');
      }, 5000);
      this.$nextTick(() => {
        const iframe = document.querySelector('#ifra');
                // 处理兼容行问题
        if (iframe.attachEvent) {
          iframe.attachEvent('onload', () => {
                    // iframe加载完毕以后执行操作
                    // console.log(iframe.contentWindow.document)
                    // if(iframe.contentWindow.document.querySelector('.card_box')){
            clearTimeout(time);
                    // }
            console.log('iframe已加载完毕');
          });
        } else {
          iframe.onload = () => {
                    // iframe加载完毕以后执行操作
                    // console.log(iframe.contentWindow)
                    // if(iframe.contentWindow.document.querySelector('.card_box')){
            clearTimeout(time);
                    // }
            console.log('iframe已加载完毕');
          };
        }
      });
    }
  }
};
</script>