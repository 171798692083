<template>
  <!-- 补课管理 补课处理方案-->
  <div class="saturationCourse_us">
    <div class="content_marginRL content_marginTop_30">
      <div class="handle_programme_box"
           v-for="(item,index) in dataList"
           :key="index">
        <div class="programme_title">{{ item.AttenceStatusKeyValue }}</div>
        <div class="programme_content">{{ new Date(item.ClassTime).getMonth()+1 }}/{{ new Date(item.ClassTime).getDate() }} &nbsp;&nbsp;{{item.WeekKeyValue}}&nbsp;&nbsp;{{item.ClassTimeName}}
          &nbsp;&nbsp;{{item.GradeClassKeyValue}}&nbsp;&nbsp;{{item.TeacherKeyValue}}</div>
      </div>
      <div v-if="dataList.length == 0">
        <div class="has_arrange_course">
          <div class="has_arrange_course_content"
               style="padding:0">
            <div class="has_arrange_course_list">
              <div class="has_arrange_course_nodata single_shadow">
                <span>亲，当前没有任何数据哦~</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template> 

<script>
import { GetMakeUpHistoryRecord } from '../../../API/workbench';
export default {
  props: {
    repairProgrammObj: Object
  },
  data () {
    return {
      dataList: []
    };
  },

  components: {
  },
  computed: {
  },
  watch: {
  },
  created () {
    console.log(this.repairProgrammObj, 'repairProgrammObj');
    this.getGetMakeUpHistoryRecord();
  },
  mounted () {
  },
  methods: {
    // 获取所有 补课处理方案 数据
    getGetMakeUpHistoryRecord () {
      GetMakeUpHistoryRecord(this.repairProgrammObj.StudentNameKey, this.repairProgrammObj.ScheduleCourseKey).then(result => {
        this.dataList = result.data;
        console.log(result.data, 'GetMakeUpHistoryRecord');
      }, err => {
        layer.alert(err.msg);
      });
    }
  }
};
</script>

<style scoped>
/*  */
.saturationCourse_us {
  min-height: 110px;
}
.content_marginRL {
  margin-bottom: 20px;
}
.handle_programme_box {
  width: 350px;
  margin: 0 auto;
  border: 2px solid #ccc;
  padding-left: 10px;
  margin-top: 20px;
  line-height: 27px;
  border-radius: 4px;
}
.programme_title {
  font-size: 17px;
}
</style>