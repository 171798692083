<template>
  <!-- 成长时光 -->
  <div class="student_file">
    <div class="growth_time_title_box swiper-container">
      <div class="growth_time_title swiper-wrapper">
        <div class="title_list swiper-slide"
             v-for="(item,key) in studentCourseList"
             :key="key"
             :class="item.isOpt?'opt':''"
             @click.stop="selectCourse(item)">
          {{item.CourseNameKeyValue}}
        </div>
      </div>
    </div>
    <div v-if="studentGrowthTimeList.length>0">
      <div class="growth_time_ul_box"
           @scroll="scrollTOP">
        <div class="growth_time_ul">
          <student-time v-for="(item,index) in studentGrowthTimeList"
                        :key="index"
                        :dataInfo="item"
                        :courseInfo="parameter"
                        :studentInfo="studentInfo"></student-time>
          <div class="no_list_text"
               v-if="!noMore">已显示全部</div>
        </div>
      </div>
    </div>
    <basics-nodata-tips v-if="studentGrowthTimeList.length==0"></basics-nodata-tips>
  </div>
</template>

<script>
import studentTime from './student-time';
import {
  GetStudentGrowthTimeCourse,
  MessageSearchStudentGrowthTime
} from '../../../../../API/workbench';
export default {
  data () {
    return {
      studentCourseList: [], // 学生课程
      studentGrowthTimeList: [], // 学生时光列表
      noMore: false, // 加载更多
      newStartTime: -10,
      parameter: {
        StartTime: '', // 发布起始时间
        EndTime: this.$utils.formatDateToLine(new Date()), // 发布终止时间
        CourseKey: '', // 课程key
        StudentKey: '' //	//学生ID
      },
      swiper: null,
      loadEnd:false, 
    };
  },
  components: {
    studentTime
  },
  props: {
    studentInfo: Object
  },
  created () {
    console.log('studentInfo11', this.studentInfo);
    this.parameter.StudentKey = this.studentInfo.OLAPKey;
    if (this.studentInfo.CourseKey) {
      // 有课程ID时需要预置
      this.parameter.CourseKey = this.studentInfo.CourseKey;
    }
    // if (this.parameter.StudentKey) {
    //   this.getStudentCourse() // 获取学生课程
    // }
  },
  mounted () {
    this.swiper = new Swiper('.growth_time_title_box', {
      slidesPerView: 'auto',
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true // 修改swiper的父元素时，自动初始化swiper
    });
  },
  watch: {
    'studentInfo.OLAPKey': {
      handler (n, o) {
        console.log('n-studentInfo.OLAPKey', n);
        if (n) {
          this.parameter.StudentKey = n;
          this.parameter.OrderByTime = '';
          if (this.studentInfo.CourseKey) {
            // 有课程ID时需要预置
            this.parameter.CourseKey = this.studentInfo.CourseKey;
            this.parameter.CourseKeyValue = this.studentInfo.CourseKeyValue;
          }
          if (this.parameter.StudentKey) {
            this.getStudentCourse(); // 获取学生课程
          }
        }
      },
      immediate: true
    },
    'studentInfo.CourseKey': {
      handler (n, o) {
        if (n && n != o) {
          this.parameter.CourseKey = n;
        }
      },
      immediate: true
    }
  },
  methods: {
    getStudentCourse () {
      GetStudentGrowthTimeCourse(this.parameter.StudentKey).then(
        (res) => {
          console.log(res.data, 'getStudentCourse');
          res.data.forEach((o) => {
            o.isOpt = false;
          });
          if (res.data.length > 0) {
            this.studentCourseList = res.data;
            if (this.parameter.CourseKey) {
              this.studentCourseList.forEach((o) => {
                if (this.parameter.CourseKey == o.CourseNameKey) {
                  o.isOpt = true;
                } else {
                  o.isOpt = false;
                }
              });
            } else {
              this.studentCourseList[0].isOpt = true;
            }
            if (!this.parameter.CourseKey) {
              this.parameter.CourseKey = this.studentCourseList[0].CourseNameKey;
              this.parameter.CourseKeyValue = this.studentCourseList[0].CourseNameKeyValue;
            }
            this.parameter.StartTime = this.funFate(this.newStartTime); // 默认加载发布起始时间
            // 查询学生成长时光
            this.getMessageSearchStudentGrowthTime();
            this.$nextTick(() => {
              if (this.swiper) {
                console.log(this.swiper, 'swiper');
                this.swiper.updateSlides();
                this.swiper.slideTo(0);
              }
            });
          }
        },
        (err) => {
          console.log(err.msg);
        }
      );
    },
    selectCourse (item) {
      this.studentCourseList.forEach((o) => {
        if (item.CourseNameKey == o.CourseNameKey) {
          o.isOpt = true;
        } else {
          o.isOpt = false;
        }
      });
      this.parameter.CourseKey = item.CourseNameKey;
      this.parameter.CourseKeyValue = item.CourseNameKeyValue;
      this.parameter.OrderByTime = '';
      this.parameter.StartTime = this.funFate(-10); // 默认加载发布起始时间
      this.parameter.EndTime = this.$utils.formatDateToLine(new Date());
      this.studentGrowthTimeList = [];
      this.getMessageSearchStudentGrowthTime();
    },
    getMessageSearchStudentGrowthTime () {
      this.loadEnd=true
      MessageSearchStudentGrowthTime(
        this.parameter,
        this.parameter.CourseKey,
        this.parameter.StudentKey
      ).then(
        (res) => {
          if (
            this.parameter.EndTime == this.$utils.formatDateToLine(new Date())
          ) {
            this.studentGrowthTimeList = [];
          }
          if (res.data.length > 0) {
            res.data.forEach((o) => {
              if (
                o.AttenceOpratorKeyValue &&
                o.AttenceOpratorKeyValue.indexOf('老师') <= -1
              ) {
                // 签到 请假
                o.AttenceOpratorKeyValue = o.AttenceOpratorKeyValue + '老师';
              }
              if (o.Author && o.Author.indexOf('老师') <= -1) {
                // 学习成长报告
                o.Author = o.Author + '老师';
              }
              this.studentGrowthTimeList.push(o);
            });
          }
          if (res.data.length >= 10) {
            this.parameter.OrderByTime = res.data[res.data.length - 1].OrderByTime;
            this.noMore = true;
          } else {
            this.noMore = false;
          }
           this.loadEnd=false
          console.log(this.studentGrowthTimeList, '成长时光');
        },
        (err) => {
          console.log(err.msg);
        }
      );
    },
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;
      if (
        (ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight ||
          ETarget.scrollTop + 1 ==
          ETarget.scrollHeight - ETarget.clientHeight) &&
        this.noMore &&!this.loadEnd&&
        this.studentGrowthTimeList.length > 0
      ) {
        console.log('滚动加载事件');
        const EndTime =
          this.studentGrowthTimeList[this.studentGrowthTimeList.length - 1]
            .SetpTime;
        this.parameter.EndTime = this.funFate(-1, EndTime); // 默认加载发布起始时间
        this.parameter.StartTime = this.funFate(-11, EndTime); // 默认加载发布起始时间
        this.getMessageSearchStudentGrowthTime();
      }
    },
    // 获取指定时间前后多少天后的指定日期
    funFate (num, date) {
      var date1 = date ? new Date(date) : new Date();
      // 今天时间
      var time1 =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1) +
        '-' +
        date1.getDate();
      console.log(time1);
      var date2 = new Date(date1);
      date2.setDate(date1.getDate() + num);
      // num是正数表示之后的时间，num负数表示之前的时间，0表示今天
      const getMonth =
        date2.getMonth() + 1 < 10
          ? '0' + (date2.getMonth() + 1)
          : date2.getMonth() + 1;
      const getDate =
        date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate();
      var time2 = date2.getFullYear() + '-' + getMonth + '-' + getDate;
      console.log(time2);
      return time2;
    }
  }
};
</script>

<style>
.student_file .growth_time_title_box {
  overflow: hidden;
  height: 50px;
  margin-right: 20px;
}
.student_file .growth_time_ul_box {
  max-height: 560px;
  overflow-y: auto;
  height: 100%;
}
.student_file .growth_time_title {
  background-color: #fff;
  padding-top: 10px;
  padding-left: 15px;
  position: relative;
  height: 61px;
  display: flex;
  min-width: 618px;
  cursor: pointer;
}
.student_file .growth_time_title .title_list.opt {
  background-color: #ff6c67;
  color: #fff;
}
.student_file .growth_time_title .title_list {
  font-size: 13px;
  line-height: 35px;
  height: 35px;
  flex-shrink: 0;
  position: relative;
  min-width: 117px;
  padding: 0 11px;
  background-color: #f4f4f4;
  color: #666;
  border-radius: 4px;
  margin-right: 8px;
  text-align: center;
}
.student_file .growth_time_list {
  padding-left: 40px;
  position: relative;
}
.student_file .growth_time_list:first-child::before {
  top: 15px;
}
.student_file .growth_time_list::before {
  /* content: '';
  position: absolute;
  width: 1px;
  left: 20px;
  top: 0;
  bottom: 0;
  background-color: #ececec; */
}
.student_file .growth_time_list:last-child::before {
  bottom: 20px;
}
.student_file .growth_time_list_content {
  /* padding: 12px 0; */
  border-bottom: 1px solid #ececec;
  min-height: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.student_file .growth_time_list_title {
  font-size: 17px;
  line-height: 20px;
  width: 100%;
}
.student_file .growth_time_list_title .text_box {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75px;
  display: inline-block;
  vertical-align: top;
}
.student_file .growth_time_list_title .font_gray {
  font-size: 12.5px;
  color: #999;
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
  display: inline-block;
  vertical-align: top;
}
.student_file .growth_time_list_text {
  font-size: 15px;
  line-height: 20px;
  padding-right: 20px;
  display: -webkit-box;
  word-break: break-all;
  margin-bottom: 6px;
  margin-top: 0;
  width: 350px;
}
.student_file .growth_time_list::after {
  content: '';
  position: absolute;
  left: 12px;
  top: 15px;
  width: 18px;
  height: 18px;
  background-color: #ff6c67;
  border: 4px solid #ffd3d1;
  border-radius: 50%;
}
.student_file .growth_time_list_type .text_box {
  white-space: nowrap;
  /* overflow: hidden;
  text-overflow: ellipsis;
  max-width: 175px; */
}
.swiper-slide {
  width: auto;
}
.student_file .growth_time_right_title .title_list.opt {
  background-color: #ff6c67;
  color: #fff;
}

.student_file
  .growth_time_list_imgBox.dynamic_content_body
  .body_img_list.view_photo_img {
  width: 104px;
  height: 104px;
}
.student_file
  .growth_time_list_imgBox.dynamic_content_body
  .view_photo_img.one_img {
  width: 320px;
  height: 180px;
  border-radius: 10px;
  overflow: hidden;
  /* -webkit-box-pack: start; */
  /* -webkit-justify-content: flex-start; */
  /* justify-content: flex-start; */
  justify-content: center;
  margin: 0;
  border-radius: 4px;
}
.student_file .growth_time_list_swiper .swiper-button-next {
  right: 0px;
}
</style>
