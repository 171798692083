<template>
  <!-- 忘记密码 || 强制修改密码 -->
  <div :style="isAlertPwd?'height:240px':'height:300px'">
    <div v-if="!isAlertPwd"
         class="form_info_edit form_info_list">

      <div class="login_from_input"
           :style="{'--borderColor':vBrandInfo.MainBodyColor}">
        <input name="SaaSCN"
               type="text"
               id="SaaSCN"
               v-model="SaaSCN"
               placeholder="请输入门店ID"
               onkeypress="if(event.keyCode==13) {document.getElementById('mobile').focus();}" />
      </div>
      <div class="login_from_input"
           :style="{'--borderColor':vBrandInfo.MainBodyColor}">
        <input name="mobile"
               type="number"
               id="mobile"
               v-model="mobile"
               placeholder="请输入手机号码"
               onkeypress="if(event.keyCode==13) {document.getElementById('password').focus();}" />
      </div>
      <div class="login_from_input"
           :style="{'--borderColor':vBrandInfo.MainBodyColor}">
        <input type="text"
               id="verificationCode"
               placeholder="请输入验证码"
               v-model="verificationCode"
               @keyup="keyupVerfiCode($event)"
               @keypress="keypressToLogin($event)" />
      </div>
      <div class="code_font_red fr"
           :style="{color:vBrandInfo.MainBodyColor}"
           @click.stop="sendVerificationCode"
           v-if="vIsShowVerificationCodeBtn">获取验证码</div>
      <div class="fr"
           style="position: absolute;margin-left: 325px;margin-top: -26px;"
           :style="{color:vBrandInfo.MainBodyColor}"
           v-else>{{countdown}}S</div>
    </div>

    <div v-if="isAlertPwd"
         class="form_info_edit form_info_list">
      <div class="login_from_input"
           :style="{'--borderColor':vBrandInfo.MainBodyColor}">
        <input type="password"
               v-model="NewPassword"
               placeholder="请输入新密码,密码长度为6-18位,非纯数字" />
      </div>
      <div class="login_from_input"
           :style="{'--borderColor':vBrandInfo.MainBodyColor}">
        <input type="password"
               v-model="ConfirmPassword"
               placeholder="请确认密码" />
      </div>

    </div>
    <div class="login_phone_button">
      <div v-if="!isAlertPwd"
           @click.stop="resetClick"
           :style="{backgroundColor:vBrandInfo.MainBodyColor,'--color':vBrandInfo.MainBodyColor}"
           class="btn_light_blue btn_hover_bg_red button">
        重置密码
      </div>
      <div v-else
           @click.stop="alertClick"
           :style="{backgroundColor:vBrandInfo.MainBodyColor,'--color':vBrandInfo.MainBodyColor}"
           class="btn_light_blue btn_hover_bg_red button"
           style="height: 45px;line-height: 45px;">
        确定
      </div>
    </div>
  </div>
</template>
<script>
import { GetCaptcha, LoginForgetPassword, LoginForceEditPassword } from '@/API/workbench.js';
export default {
  components: {
  },
  data () {
    return {
      NewPassword: '',
      ConfirmPassword: '',
      SaaSCN: '', // 门店ID
      mobile: '',
      verificationCode: '',
      countdown: 0,
      isSendding: false,
      setTimeoutObj: null,
      dataInfo: {
      }
    };
  },
  props: {
    isAlertPwd: [Boolean, String],
    IdxSaaSClientNO: [String, Number],
    IdxMobile: [String, Number],
    vBrandInfo: Object
  },
  created () {
    console.log(this.vBrandInfo, 'vBrandInfo');
    this.NewPassword = '';
    console.log(this.isAlertPwd, 'isAlertPwd', this.IdxSaaSClientNO, this.IdxMobile, 'IdxSaaSClientNO IdxMobile');
    this.SaaSCN = this.IdxSaaSClientNO;
    this.mobile = this.IdxMobile;
  },
  computed: {
    vIsShowVerificationCodeBtn () {
      return this.countdown == 0;
    }
  },
  methods: {
    // 密码输入框 回车登录
    keypressToLogin (event) {
      if (event.keyCode == 13) {
        // this.resetClick();
        console.log('重置密码');
      }
    },
    // 发送验证码
    sendVerificationCode () {
      if (!this.SaaSCN) {
        layer.alert('请输入门店ID');
        return;
      } else if (!this.mobile) {
        layer.alert('请输入手机号码');
        return;
      } else if (this.mobile.length != 11) {
        layer.alert('请输入11位的手机号码');
        return;
      }
      if (this.isSendding) {
        return false;
      }
      this.isSendding = true;
      GetCaptcha(this.mobile).then(result => {
        if (result.code == 0) {
          this.settime();
        } else {
          layer.alert(result.msg);
          this.isSendding = false;
        }
      }, error => {
        layer.alert(error.msg);
        this.isSendding = false;
      });
    },
    // 发送验证码倒计时（读秒）
    settime () {
      if (this.countdown == 0) {
        this.countdown = 119;
      } else {
        if (this.countdown < 120 && this.countdown > 0) {
          this.countdown--;
        } else {
          this.verificationCode = ''; // 一分钟后，验证码继续重置
          this.isSendding = false;
        }
      }
      if (this.countdown > 0) {
        this.setTimeoutObj = setTimeout(() => {
          this.settime();
        }, 1000);
      } else {
        this.isSendding = false;
      }
    },
    // 输入验证码 最大长度不超过4个
    keyupVerfiCode (e) {
      if (this.verificationCode.length > 4) {
        this.verificationCode = this.verificationCode.substring(0, 4);
      }
    },
    // 提交新增
    submitAdd (callback) {
      console.log('submit', this.dataInfo);
    },
    // 重置密码
    resetClick () {
      if (!this.SaaSCN) {
        layer.alert('请输入门店ID');
        return;
      } else if (!this.mobile) {
        layer.alert('请输入手机号码');
        return;
      } else if (this.mobile.length != 11) {
        layer.alert('请输入11位的手机号码');
        return;
      } else if (!this.verificationCode) {
        layer.alert('请输入验证码');
        return;
      }
      LoginForgetPassword(this.SaaSCN, this.mobile, this.verificationCode).then(result => {
        console.log(result, 'LoginForgetPassword');
        this.$emit('close');
        layer.alert('密码重置成功，新密码稍后会发送至您的手机上');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 强制修改密码
    alertClick () {
      if (this.NewPassword == '') {
        // 新密码
        layer.alert('新密码不能为空');
        return;
      } else if ((this.NewPassword.length < 6 || this.NewPassword.length > 18) || (!isNaN(this.NewPassword) && this.NewPassword.indexOf('.') == -1)) {
        layer.alert('密码长度为6-18位,非纯数字');
        return;
      } else if (this.ConfirmPassword == '') {
        // 确认密码
        layer.alert('确认密码不能为空');
        return;
      } else if (this.ConfirmPassword != this.NewPassword) {
        layer.alert('两次输入密码不一致');
        return;
      }
      LoginForceEditPassword(this.NewPassword, this.ConfirmPassword).then(result => {
        console.log(result, 'LoginForceEditPassword');
        this.$emit('alertClick', this.NewPassword);
        layer.alert('密码修改成功');
      }, error => {
        layer.alert(error.msg);
      });
    }
  }
};
</script>
<style scoped>
.form_info_list {
  padding: 0 15px !important;
}
.login_from_input input {
  background: #f7f7f7;
  border-radius: 8px;
  padding-left: 25px;
  line-height: 54px !important;
  height: 54px !important;
  margin-bottom: 5px;
}
.login_from_input {
  margin-bottom: 10px;
}
.code_font_red {
  padding: 1px 16px;
  border: 1px solid var(--borderColor);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  position: absolute;
  right: 30px;
  line-height: 33px;
  height: 35px;
  margin-top: -37px;
  cursor: pointer;
}
.reset_pwd {
  margin-top: 61px;
  background-color: rgb(248, 90, 89);
  color: #fff;
  border-radius: 0px;
  cursor: pointer;
}
.login_from_input input:focus {
  border: 1px solid var(--borderColor);
}
.login_phone_button {
  margin-top: 40px !important;
}
.btn_light_blue {
  border: none !important;
  color: #fff;
}
.btn_light_blue:hover {
  background-color: var(--color) !important;
}
</style>