<template>
  <!-- 诺访记录 -->
  <div class="documentary_records_list">
    <div class="nuofang_records_conten">
      <!-- 销售  -->
      <div class="table_select_box ascription"
           style="margin-right: 15px;">
        <el-select :value="vStaffValue"
                   filterable
                   placeholder="必选"
                   value-key="OLAPKey"
                   style="width: 120px;margin-left: 0px;"
                   @change="doSelectedStaff">
          <el-option v-for="item in staffList"
                     :key="item.OLAPKey "
                     :label="item.MainDemoName"
                     :value="item"></el-option>
        </el-select>
      </div>
      <date-range ref="monthPicker"
                  :fromTitle="'诺访日期'"
                  :searchObj="searchObj"
                  @changeDate="changeSaveDate"></date-range>

      <div class="nuofang_handle_div">
        <div class="nuofang_both mover_class"
             :style="{border: isNuoFangNum == 1 &&NuoFangIndex % 2 ==0?'1px solid rgba(52, 152, 219, 1)':'1px solid orange',
               color: isNuoFangNum == 1 && NuoFangIndex % 2 ==0?'rgba(52, 152, 219, 1)':'orange',background: isNuoFangNum == 1 && NuoFangIndex % 2 ==0?'rgba(52, 152, 219, 0.2)':'#fff'}"
             @click.stop="handleBtn(1)">
          近3天诺访： <div class="nuoFang_num">{{ PromiseVisitCount }}</div>
        </div>
        <div class="nuofang_both mover_class"
             style="margin-left: 15px;"
             :style="{border: isNuoFangNum == 2 &&NuoFangIndex % 2 ==0?'1px solid rgba(52, 152, 219, 1)':'1px solid red',
               color: isNuoFangNum == 2 && NuoFangIndex % 2 ==0?'rgba(52, 152, 219, 1)':'red',background: isNuoFangNum == 2 && NuoFangIndex % 2 ==0?'rgba(52, 152, 219, 0.2)':'#fff'}"
             @click.stop="handleBtn(2)">
          过期待处理： <div class="nuoFang_num">{{ PromiseVisitExpireCount }}</div>
        </div>
      </div>

      <div class="table_select_box"
           style="margin-right: 20px;">
        <div class="table_select_title">诺访状态</div>
        <el-select v-model="selectednuoFangState"
                   filterable
                   style="width: 120px;"
                   placeholder="必选"
                   @change="chgSearchnuoFangStateValue"
                   value-key="OLAPKey">
          <el-option v-for="item in nuoFangStateList"
                     :key="item.OLAPKey"
                     :label="item.MainDemoName"
                     :value="item"></el-option>
        </el-select>
      </div>
      <!-- 已选条件 -->
      <input-search-contain-btn v-model="queryParams.searchText"
                                :placeholder="'搜索学生'"
                                @clearSearch="clearExtendSearchParams"
                                @onSearchEvent="search"></input-search-contain-btn>
    </div>
    <div class="table_list_content introduce_list campusManagement_list">
      <table-list ref="tableListRef"
                  class="summarizing_list schedule_table_class"
                  :tableData="nuoFangList"
                  :queryParams="queryParams"
                  :tableColumns="tableColumns"
                  :rowKey="'OLAPKey'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  @loadTableData="getManageMessageSearchPagePromiseVisitList"></table-list>
    </div>
    <custom-dialog :title="'改约'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowFollowUp"
                   :before-close="doShowFollowUpHide">
      <follow-up-form :dataInfo="dataInfo"
                      :scheduleTitle="'改约'"
                      :followTitle="'诺访记录'"
                      @afterSuccess="afterSuccess">
      </follow-up-form>
    </custom-dialog>
    <custom-dialog :title="'分配员工'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowSalesList"
                   :before-close="closeForm">
      <get-sale-list :selectedKey="selectedKey"
                     :OLAPKey="OLAPKey"
                     @closeForm="closeForm"
                     @onSelectedItem="onSelectedItem">
      </get-sale-list>
    </custom-dialog>
    <custom-dialog title="线索跟进记录"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowClewForm"
                   :before-close="doShowFollowUpHide">
      <clew-follow-form :studentItem="studentItem"
                        @close="doShowFollowUpHide"
                        @afterSuccess="getManageMessageSearchPagePromiseVisitList"></clew-follow-form>
    </custom-dialog>
    <custom-dialog title="跟单记录流水"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowRecordList"
                   :before-close="doShowFollowUpHide">
      <clew-follow-record-list :studentItem="studentItem"
                               :StudentThreadKey="StudentThreadKey"
                               :isAllFollowRecord="true"
                               @close="doShowFollowUpHide"></clew-follow-record-list>
    </custom-dialog>
  </div>
</template>
<script>
import { ManageMessageSearchPagePromiseVisitList, MessageSearchPageForEmployee, UpdatePromiseVisitStatus } from '../../../API/workbench.js';
import inputSearchContainBtn from '../../report/common/input-search-contain-btn';
import tableList from '../../common/table-list/index';
import followUpForm from '../follow-up-form.vue';
import getSaleList from '../form/getSale-list.vue';// 销售
import clewFollowForm from '../../recruit-students-workbenches/clue-list/clew-follow-form';
import clewFollowRecordList from '../../recruit-students-workbenches/clue-list/clew-follow-record-list';
export default {
  data () {
    return {
      isNuoFangNum: 0,
      NuoFangIndex: 1,
      StudentThreadKey: 0,
      isShowRecordList: false,
      isShowClewForm: false,
      studentItem: null,
      isShowSalesList: false, // 销售
      selectedKey: '', // 销售ID
      OLAPKey: '', // 线索ID
      isShowFollowUp: false,
      dataInfo: {},
      selectednuoFangState: '全部', // 最近跟单
      staffList: [], // 销售
      DocumentaryKeyValue: '',
      nuoFangStateList: [
        {
          OLAPKey: 0,
          MainDemoName: '全部'
        },
        {
          OLAPKey: 1,
          MainDemoName: '未处理'
        },
        {
          OLAPKey: 2,
          MainDemoName: '未到'
        },
        {
          OLAPKey: 3,
          MainDemoName: '改约'
        },
        {
          OLAPKey: 4,
          MainDemoName: '已到'
        }
      ],
      queryParams: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        StartTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        EndTime: this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd'),
        StatusKey: '', // 诺访状态:1-待处理;2-未到;3-改约;4-已到
        SalesKey: '', // 销售ID
        NearlyThreeDays: '', // 近三天试课
        IsExpire: '' // 是否过期
      },
      searchObj: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd')
      },
      PromiseVisitCount: 0, // 近三天诺访数量
      PromiseVisitExpireCount: 0, // 过期待处理
      nuoFangList: [],
      tableColumns: [
        {
          label: '序号',
          width: 65,
          prop: 'Number',
          align: 'center',
          type: 'tableIndex',
          sortable: false
        },
        {
          label: '诺访日期',
          width: 100,
          sortable: 'custom',
          prop: 'VisitTime',
          type: 'text-item'
        },
        {
          label: '归属销售',
          prop: 'SalesKeyValue',
          width: 90,
          align: 'center',
          type: 'text-item'
          // extend: {
          //   isClick: true,
          //   click: (rowData) => {
          //     this.doFollowClick(rowData);
          //   }
          // }
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 110,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '手机号',
          prop: 'MobilePhone',
          width: 110,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '登记日期',
          width: 100,
          sortable: 'custom',
          prop: 'GenerateTime',
          type: 'text-item'
        },
        {
          label: '最近跟单',
          prop: 'FollowTime',
          width: 100,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '最近跟单',
          prop: 'FollowContentValue',
          align: 'left',
          type: 'text-item',
          extend: {
            type: 'expand',
            click: (rowData) => { this.doFollowContentClick(rowData); }
          }
        },
        {
          label: '状态',
          prop: 'StatusKeyValue',
          width: 65,
          align: 'center',
          type: 'text-item',
          sortable: false
        },
        {
          label: '操作',
          prop: 'option',
          width: 160,
          type: 'table-btn',
          extend: {
            tableBtnGroup: [
              {
                name: '已到',
                extend: {
                  isHide: (rowData) => {
                    return Number(rowData.StatusKey) != 1;
                  },
                  click: (rowData) => {
                    this.editBtn(rowData, 4);
                  }
                }
              },
              {
                name: '未到',
                extend: {
                  isHide: (rowData) => {
                    return Number(rowData.StatusKey) != 1;
                  },
                  click: (rowData) => {
                    this.editBtn(rowData, 2);
                  }
                }
              },
              {
                name: '改约',
                extend: {
                  isHide: (rowData) => {
                    return Number(rowData.StatusKey) != 1;
                  },
                  click: (rowData) => {
                    this.editBtn(rowData, 3);
                  }
                }
              }
            ]
          }
        }
      ],
      totalNum: 0
    };
  },
  components: {
    inputSearchContainBtn,
    tableList,
    followUpForm,
    getSaleList,
    clewFollowForm,
    clewFollowRecordList
  },
  computed: {
    // 销售
    vStaffValue () {
      return this.DocumentaryKeyValue == '' ? '全部销售' : this.DocumentaryKeyValue;
    }
  },
  created () {
    this.loadData();
    this.getManageMessageSearchPagePromiseVisitList();
  },
  watch: {
  },
  activated () {
  },
  mounted () {
  },
  methods: {
    handleBtn (type) {
      this.queryParams.StartTime = '';
      this.queryParams.EndTime = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      console.log(this.NuoFangIndex, 'this.NuoFangIndex', this.isNuoFangNum, type);
      if (this.isNuoFangNum != type) {
        this.NuoFangIndex = 1;
      }
      this.isNuoFangNum = type;

      this.queryParams.pageIndex = 0;
      if (this.NuoFangIndex % 2 == 0) {
        this.queryParams.NearlyThreeDays = '';
        this.queryParams.IsExpire = '';
      } else {
        this.queryParams.NearlyThreeDays = type == 1 ? 1 : '';// 近三天试课
        this.queryParams.IsExpire = type == 2 ? 1 : '';// 是否过期
      }
      this.NuoFangIndex++;
      console.log(this.NuoFangIndex, 'this.NuoFangIndex', this.isNuoFangNum);
      this.getManageMessageSearchPagePromiseVisitList();
    },
    closeForm () {
      this.isShowSalesList = false;// 销售
    },
    // 打开员工列表
    doFollowClick (rowData) {
      this.selectedKey = rowData.SalesKey;
      this.OLAPKey = rowData.StudentThreadKey;
      this.isShowSalesList = true;
    },
    onSelectedItem (item) {
      console.log('item0,', item);
      this.isShowSalesList = false;// 销售
      this.getManageMessageSearchPagePromiseVisitList();
    },
    // 根据标签筛选
    doSelectedStaff (obj) {
      console.log(obj, '标签');
      this.DocumentaryKeyValue = obj.MainDemoName;
      this.queryParams.SalesKey = obj.OLAPKey;
      this.queryParams.pageIndex = 0;
      this.getManageMessageSearchPagePromiseVisitList();
    },
    loadData () {
      let obj = {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号、首字母
        orderBy: 'IsLeaveKey', //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为姓名
        sequence: 'DESC', //	字符串	可选	ASC	数据源：GET	为空则默认顺序; ASC正序;DESC倒序
        IsLeaveKey: '' //	字符串	可选		数据源：GET	是否在职;空表示该条件不生效;0-离职;1-在职
      };
      MessageSearchPageForEmployee(obj).then(
        (result) => {
          if (result.data.PageDataList && result.data.PageDataList.length > 0) {
            result.data.PageDataList.forEach((o) => {
              if (Number(o.IsAdviserKey) == 1) {
                this.staffList.push(o);
              }
            });
          } else {
            this.staffList = [];
          }
          this.staffList.splice(0, 0, {
            OLAPKey: '',
            MainDemoName: '全部销售'
          });
          this.staffList.splice(1, 0, {
            OLAPKey: -1,
            MainDemoName: '未分配'
          });
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    chgSearchnuoFangStateValue (item) {
      console.log(item, 'item');
      this.queryParams.pageIndex = 0;
      this.selectednuoFangState = item.MainDemoName;
      this.queryParams.StatusKey = item.OLAPKey;
      this.getManageMessageSearchPagePromiseVisitList();
    },
    // 诺访记录 数据查询
    getManageMessageSearchPagePromiseVisitList () {
      ManageMessageSearchPagePromiseVisitList(this.queryParams).then(result => {
        this.PromiseVisitCount = Number(result.data.PromiseVisitCount);
        this.PromiseVisitExpireCount = Number(result.data.PromiseVisitExpireCount);
        this.totalNum = result.data.Total;
        this.nuoFangList = result.data.PageDataList;
        this.nuoFangList.forEach(o => {
          o.VisitTime = o.VisitTime ? o.VisitTime.slice(0, 10) : '-';
          o.StudentKeyValue = o.StudentKeyValue ? o.StudentKeyValue : '-';
          o.MobilePhone = o.MobilePhone ? o.MobilePhone : '-';
          o.CurrentNodeKeyValue = o.CurrentNodeKeyValue ? o.CurrentNodeKeyValue : '-';
          o.CurrentLabelNames = o.CurrentLabelNames ? o.CurrentLabelNames : '-';
          o.GenerateTime = o.GenerateTime ? o.GenerateTime : '-';
          o.FollowTime = o.FollowTime ? o.FollowTime : '-';
          o.FollowContentValue = o.FollowContent ? '<div class="recently_documentary_class">' + o.FollowContent + '</div>' : '';
          o.SalesKeyValue = !o.SalesKeyValue ? '未分配' : o.SalesKeyValue;
        });
        console.log(result, '诺访记录 数据查询');
      }, error => {
        layer.alert(error.msg);
      });
    },
    afterSuccess () {
      this.isShowFollowUp = false;
      this.getManageMessageSearchPagePromiseVisitList();
    },
    doFollowContentClick (item) {
      this.StudentThreadKey = item.StudentThreadKey;
      this.studentItem = item;
      this.isShowRecordList = true;
    },
    doShowFollowUpHide () {
      this.isShowFollowUp = false;
      this.isShowClewForm = false;
      this.isShowRecordList = false;
    },
    editBtn (rowData, type) {
      this.dataInfo = rowData;
      let title = '';
      let obj = {};
      if (type == 3) {
        // 改约
        this.isShowFollowUp = true;
      } else {
        title = type == 2 ? '未到' : '已到';
        obj = {
          StudentThreadKey: rowData.StudentThreadKey, // 线索ID
          PromiseVisitKey: rowData.PromiseVisitKey, // 诺访ID
          PromiseVisitTime: '', // 改约诺访日期
          StatusKey: type, // 1-待处理;2-未到;3-改约;4-已到
          StatusKeyValue: title // 状态
        };
        console.log(obj, 'obj66');
        layer.confirm('确定处理' + title + '数据吗？', {
          btn: [
            {
              name: '确认',
              callBack: () => {
                UpdatePromiseVisitStatus(obj).then(result => {
                  layer.close();
                  layer.alert('处理成功');
                  this.getManageMessageSearchPagePromiseVisitList();
                }, error => {
                  layer.alert(error.msg);
                });
              }
            }
          ]
        });
      }
    },
    changeSaveDate (dataInfo) {
      console.log(dataInfo, 'dataInfo');
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.queryParams.StartTime = dataInfo.startTime;
      this.queryParams.EndTime = dataInfo.endTime;
      this.getManageMessageSearchPagePromiseVisitList();
    },
    search () {
      this.queryParams.pageIndex = 0;
      this.getManageMessageSearchPagePromiseVisitList();
    },
    clearExtendSearchParams () {
      this.NuoFangIndex = 1;
      this.selectednuoFangState = '全部';
      this.DocumentaryKeyValue = '全部销售';
      this.queryParams = {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        StartTime: '',
        EndTime: '',
        StatusKey: '',
        SalesKey: '', // 销售ID
        NearlyThreeDays: '', // 近三天试课
        IsExpire: '' // 是否过期
      };
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.getManageMessageSearchPagePromiseVisitList();
    }
  }
};
</script>

<style scoped>
.nuofang_handle_div {
  display: flex;
  margin-right: 20px;
}
.nuofang_records_conten {
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;
  line-height: 70px;
  align-items: center;
  margin-top: -20px;
}
.nuofang_both {
  display: flex;
  line-height: 34px;
  padding: 0 5px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}
.schedule_table_class >>> .el-table .recently_documentary_class {
  width: 99%;
  max-height: 84px;
  line-height: 54px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  right: 1px;
  z-index: 13;
  border: 1px solid transparent !important;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 40px;
  color: #333 !important;
}
.schedule_table_class >>> .el-table .recently_documentary_class::after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -11.5px;
  right: 20px;
  width: 21px;
  height: 21px;
  padding-right: 17px;
  z-index: 2;
  background: url(../../../../public/image/course_header.png) no-repeat 50%;
  background-size: 18px 12px;
}
.mover_class:hover {
  background: rgba(52, 152, 219, 0.2) !important;
  color: rgba(52, 152, 219, 1) !important;
}
</style>

