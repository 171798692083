<template>
  <div class="massive_bg apply_payment_details_box">
    <div class="apply_payment_box_title">
      {{formTitle}} <span>{{vYear}}</span>
    </div>
    <div class="box_dot dot_green">
      {{vSecondTitle}}
    </div>
    <div class="apply_payment_box_number">
      {{secondCount}}
    </div>
    <div class="box_dot dot_blue">
      {{vThirdTitle}}
    </div>
    <div class="apply_payment_box_number">
      {{thirdCount}}
    </div>
    <div>
      <a @click.stop="showDetail"
         v-if="!btnShow == 1"
         class="class_statistics_details_btn">
        查看详情
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    formTitle: String,
    dataInfo: Object,     // 数据源
    secondTitle: String,    // 可不提供.不提供则用默认值.
    secondCount: Number,
    thirdTitle: String,     // 可不提供.不提供则用默认值.
    thirdCount: Number,
    btnShow: Number
  },
  computed: {
    vYear () {
      if (this.dataInfo) {
        return this.dataInfo.SetUpTime;
      }
    },
    vSecondTitle () {
      if (this.secondTitle) {
        return this.secondTitle;
      } else {
        return '课时消耗';
      }
    },
    vThirdTitle () {
      if (this.thirdTitle) {
        return this.thirdTitle;
      } else {
        return '签到人次';
      }
    }
  },
  methods: {
    showDetail () {
      this.$emit('showDetail');
    }
  }
};
</script>

