<template>
  <div class="WKT_index_from">
    <div class="WKT_index_from_title">
      <div class="title_name">
        优秀教师
        <!-- <span>优秀教师</span> -->
        <!-- <div class="title_edit">

        </div> -->
      </div>
      <div class="title_btn btn_hover_bg_blue"
           @click.stop="showHistory">
        历史教师
      </div>
    </div>
    <div class="WKT_index_from_content">
      <div class="WKT_information">
        <enable-item v-for="(item,key) in enableList"
                     :key="key"
                     :item="item"
                     :dataList="enableList"
                     @moveIndex="moveIndex"
                     @edit="edit"
                     @remove="remove"></enable-item>
        <div class="WKT_information_list">
          <div class="WKT_information_block">
            <add-box :titleName="'添加教师'"
                     @onAddBoxClick="showAdd"></add-box>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹出层 -->
    <custom-dialog :title="addTitle"
                   width="436px"
                   class="WKT_information_uEditor"
                   :visible.sync="isAddComboShow"
                   :before-close="doAddComboCloseClick">
      <add :dataList="enableList"
           :item="seletedItem"
           @addAfter="onStartComboClick"
           @close="doAddComboCloseClick"></add>
    </custom-dialog>
    <!-- <custom-dialog :title="'修改名称'"
                   width="436px"
                   :visible.sync="isEditComboShow"
                   :before-close="doEditComboCloseClick">
      <edit :item="seletedItem"
            @editAfter="onStartComboClick"
            @close="doEditComboCloseClick"></edit>
    </custom-dialog> -->
    <custom-dialog :title="'历史教师'"
                   width="436px"
                   :visible.sync="isHistoryComboShow"
                   :before-close="doHistoryComboCloseClick">
      <combo-history-list @enableAfter="onStartComboClick"></combo-history-list>
    </custom-dialog>
  </div>
</template>

<script>
import addBox from '../common/add-box';
import enableItem from './enable-item';
import edit from './combo-list/edit';
import add from './combo-list/add';
import comboHistoryList from './combo-list/combo-history-list';
import { SelectEnableTeacher, MoveTeacher, StopTeacher } from '../../../API/workbench';
export default {
  data () {
    return {
      // 查询套餐
      searchObj: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
        sequence: '', //	字符串	可选		数据源：GET	 排序方向
        searchText: ''//	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
      },
      enableList: [],
      seletedItem: null,
      isHistoryComboShow: false, // 历史套餐列表显示
      isEditComboShow: false,   // 修改显示
      isAddComboShow: false,   // 新增显示
      addTitle: ''
    };
  },
  components: {
    enableItem, comboHistoryList, edit, add, addBox
  },
  props: {

  },
  created () {
    this.getEnableStarStudents(this.searchObj);
  },
  methods: {
    getEnableStarStudents (searchObj) {
      SelectEnableTeacher(searchObj).then(result => {
        this.enableList = result.data.PageDataList;
        this.enableList.sort((x, y) => {
          return x.typeSort - y.typeSort;
        });
        console.log('SelectEnableStarStudents', result.data);
      });
    },
    // 修改
    edit (item) {
      this.seletedItem = item;
      this.addTitle = '修改教师';
      this.isAddComboShow = true;
      console.log('edit', item);
    },
    // 删除
    remove (item) {
      layer.confirm('确实是否删除该教师', {
        btn: [{
          name: '取消',
          callBack: () => {
            layer.close();
          }
        }, {
          name: '确认',
          callBack: () => {
            StopTeacher(item.id).then(result => {
              this.getEnableStarStudents(this.searchObj);
            }).catch(error => {
              console.log('StopTeacher', error);
            });
            console.log('remove', item);
            layer.close();
          }
        }]
      });
    },
    moveIndex (item, index) {
      MoveTeacher(item.id, index).then(result => {
        console.log('MoveStarStudents', result);
        // this.getEnableStarStudents(this.searchObj);
      }).catch(error => {
        console.log('MoveStarStudentsERR', error);
      });
    },
    showHistory () {
      this.isHistoryComboShow = true;
    },
    showAdd () {
      this.seletedItem = null;
      this.addTitle = '新增教师';
      this.isAddComboShow = true;
    },
    // 关闭历史列表
    doHistoryComboCloseClick () {
      this.isHistoryComboShow = false;
    },
    // 启用修改新增都触发.
    onStartComboClick () {
      this.getEnableStarStudents(this.searchObj);
    },
    doEditComboCloseClick () {
      this.isEditComboShow = false;
    },
    doAddComboCloseClick () {
      this.isAddComboShow = false;
    }
  }
};
</script>

<style>
</style>