<template>
  <div>
    <!-- 作业详情内容 -->
    <div class="JXT_homework_box">
      <div class="">
        <div class="JXT_dynamic_list JXT_homework_publish">
          <div class="JXT_dynamic_content">
            <div class="dynamic_content_body">
              <div v-if="detailsItem.ID">
                <browse-item-head :dataInfo="detailsItem"></browse-item-head>
                <div class="task_detail_padding" style="margin-bottom: 80px">
                  <browse-item-content :dataInfo="detailsItem"
                                       :isUnfoldShow="false"
                                       :isUnfoldShowAllImg="true"
                                       :IsOneImgShow="true"></browse-item-content>
                                       
                </div>
              </div>
              <!-- 确认批改按钮 -->
              <div v-if="!dataInfo.readonly">
                <release-btn :btnText="'重新编辑'"
                             
                             :isFloatBtn="true"
                             @click="onClickEvent"></release-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import { GetCorrectingHomeWorkDetails } from '../../../API/workbench';
import browseItemHead from '../common-component/browse-box/browse-item-head';
import browseItemContent from '../common-component/browse-box/browse-item-content';
import releaseBtn from '../common-component/release-btn';
// 作业浏览项
export default {
  components: {
    browseItemHead,
    browseItemContent,
    releaseBtn
  },
  data () {
    return {
      headTitle: '作业详情',
      detailsItem: {}
    };
  },
  props: {
    dataInfo: { // 修改的作业信息
      type: Object,
      default: {}
    }
  },
  watch: {

  },
  created () {
    if (this.dataInfo.taskID > 0) {
      this.getCorrectingHomeWorkDetails(this.dataInfo.taskID);
    }
  },
  mounted () {
    this.$dialog.register(this, ['releaseHomeTask']);
  },
  methods: {
    // 获取作业详情
    getCorrectingHomeWorkDetails (detailsID) {
      GetCorrectingHomeWorkDetails(detailsID).then(result => {
        console.log(result.data, '获取通知设置详情');
        this.detailsItem = result.data;
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 重新编辑作业
    onClickEvent () {
      if (this.detailsItem.ID) {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '修改作业',
          moduleName: 'releaseHomeTask',
          data: { taskData: { scheduleCourseID: this.detailsItem.RelateCourseID, courseName: this.detailsItem.Title, essayID: this.detailsItem.ID } },
          callBack: {
            afterSuccess: () => {
              this.getCorrectingHomeWorkDetails(this.detailsItem.ID);
              this.$bus.emit('AfterUpdateSubmitHomeTask');
            }
          }
        });
      }
    }
  }
};
</script>

<style>
</style>