<template>
	<div class="article_details_crew">
			<div class="crew_index">
				# <span v-if="dataLength>1">{{dataIndex}}</span>
			</div>
			<div class="crew_staff">
				<span class="text_box">{{dataInfo.CommentCount}}人</span> 
				<span class="text_dual" style="line-height: 16px;">{{dataInfo.StudentContent}}</span>
			</div>
		</div>
</template>

<script>
	export default {
		data() {
			return {
	
			}
		},
		props: {
		  dataInfo: Object,
		  dataIndex: Number,
		  dataLength: Number,
		},
		methods: {
	
		}
	}
</script>

<style>

</style>
