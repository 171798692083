<template>
  <div>
    <span class="defult_dialog_header_btn fr">
      <span class="btn_add">+</span>添加音乐
      <input @change="chooseFiles($event)"
             class="uploading_input music_uploading"
             type="file"
             accept="audio/*">
    </span>
    <div class="WKT_activity_music_ul">
      <music-item v-for="(item,key) in dataList"
                  :key="key"
                  :item="item"
                  @seletedItem="seletedItem"></music-item>
    </div>
    <div class="WKT_activity_music_player">
      <div class="music_player_progress pr button"
           @mousedown="handleMouseDownStart">
        <div class="progress_content"
             :style="{'width': vPlayingPropress+'%'}"></div>

      </div>
      <div class="music_player_box flex pr">
        <div class="flex">
          <div class="music_player_btn pr"
               v-if="!isPause"
               @click="musicPlay">
          </div>
          <div class="music_player_btn pr pause"
               v-if="isPause"
               @click.stop="musicPause">
          </div>
          <audio controls="controls"
                 :src="vmusicTarget"
                 controlsList="nodownload"
                 @timeupdate="handleAudioTimeUpdated"
                 style="opacity: 0;position: absolute;left:12px;z-index:0;height: 37px;"
                 id="musicplayer">
            <source :src="vmusicTarget"
                    type="audio/wav">
          </audio>
          <div class="music_player_info">
            <div class="music_player_name">{{vMusicTitle}}</div>
            <div class="music_player_time"
                 v-if="vMusicTitle">{{vPlayRecorderDuration}} / {{vRecorderDuration}}</div>
            <div class="music_player_time"
                 v-else>当前未选择音乐</div>
          </div>
        </div>
        <div class="music_player_right">
          <div class="right_btn btn_hover_white"
               @click.stop="cleanMusic">
            清除音乐
          </div>
          <div class="right_btn btn_hover_bg_blue"
               @click.stop="seletedMusic">
            立即使用
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ResourceSearchPage, UploadFiles, AddBillResource } from '../../../../../../../API/workbench';
import musicItem from './music-item';
export default {
  data () {
    return {
      dataList: [],
      musicTarget: null,
      recorderDuration: 0, // 录音时长
      playRecorderDuration: 0, // 播放进度时长
      recorder: null,
      playDurationTimeout: null, // 播放进度
      isPause: false,
      audio: {
        isPlaying: false, // 该字段是音频是否处于播放状态的属性
        currentTime: 0, // 音频当前播放时长
        recorderDuration: 0, // 音频最大播放时长
        minTime: 0,
        step: 0.1
      }
    };
  },
  components: {
    musicItem
  },
  props: {
    dataInfo: Object
  },
  created () {
    this.getMusicList();
    if (this.dataInfo && this.dataInfo.isMusic == true) {
      this.musicTarget = this.dataInfo.musicTarget;
    }
  },
  mounted () {
    if (this.musicTarget && this.musicTarget.resourceUrl) {
      this.seletedItem(this.musicTarget);
    }
    this.$bus.on(this, 'closeEditorDialog', (data) => {
      console.log('closeEditorDialog', this.isPause, this.audioApi);
      if (this.isPause) {
        this.musicPause();
      }
    });
  },
  computed: {
    vmusicTarget () {
      if (this.musicTarget && this.musicTarget.resourceUrl) {
        return this.$store.getters.CDNURL + this.musicTarget.resourceUrl;
      } else {
        return '';
      }
    },
    vMusicTitle () {
      if (this.musicTarget) {
        return this.musicTarget.title;
      }
    },
    vMusicMin () {
      let num = 0;
      if (this.musicTarget) {
        num = this.musicTarget.mins;
      }
      return num;
    },
    vRecorderDuration () { // 00:10
      let min = Math.floor(this.recorderDuration / 60);
      min = min < 10 ? '0' + min : min;
      let sec = Math.floor(this.recorderDuration % 60);
      sec = sec < 10 ? '0' + sec : sec;
      let duration = min + ':' + sec;
      return duration;
    },
    vPlayRecorderDuration () {
      let min = Math.floor(this.playRecorderDuration / 60);
      min = min < 10 ? '0' + min : min;
      let sec = Math.floor(this.playRecorderDuration % 60);
      sec = sec < 10 ? '0' + sec : sec;
      let duration = min + ':' + sec;
      return duration;
    },
    vPlayingPropress () {
      console.log('vPlayingPropress', this.playRecorderDuration, this.recorderDuration);
      if (this.playRecorderDuration == 0 && this.recorderDuration == 0) {
        return 0;
      }
      let present = (this.playRecorderDuration / this.recorderDuration) * 100;
      return (present > 100 ? 100 : present);
    },
    vRecorderingPercentage () {
      let totalTime = 600; // 10分钟
      return (this.recorderDuration / totalTime) * 100;
    }
  },
  methods: {
    clickAudioPlayBtn () { // 点击播放
      if (!this.audio.isPlaying && !this.isPause) { // 未播放
        this.audio.isPlaying = true;
        this.musicPlay();
      } else if (this.audio.isPlaying && !this.isPause) { // 暂停播放
        this.audio.isPlaying = false;
        this.isPause = true;
        this.musicPause();
      } else { // 继续播放
        this.audio.isPlaying = true;
        this.isPause = false;
        this.musicPlay();
      }
    },
    // 开始播放
    musicPlay () {
      console.log('musicPlay', this.recorder.currentTime);
      if (!this.recorder) {
        return false;
      }
      this.playRecorderDurationIncrease();
      this.recorder.play();
      this.isPause = true;
    },
    // 暂停播放
    musicPause () {
      console.log(this.recorder.currentTime, 'this.recorder');
      this.recorder.pause();
      if (this.playDurationTimeout) {
        clearTimeout(this.playDurationTimeout);
      }
      this.isPause = false;
    },
    // 录音播放
    playRecord () {
      this.playRecorderDurationIncrease();
      this.recorder.play();
    },
    // 播放进度自增1
    playRecorderDurationIncrease () {
      this.playDurationTimeout = setTimeout(() => {
        if (parseInt(this.playRecorderDuration) < parseInt(this.recorderDuration)) {
          this.playRecorderDuration += 1;
          this.playRecorderDurationIncrease();
        } else {
          clearTimeout(this.playDurationTimeout);
          this.playDurationTimeout = null;
          this.playRecorderDuration = 0;
          this.isPause = false;
          this.recorder.load();
        }
      }, 1000);
    },
    getMusicList () {
      const data = {
        // mendian_id: this.mendian_id, // WKT门店ID
        searchText: '', // 查询条件
        resourceType: 3, // 1图 2视频 3音频
        category: 0, // 分类 0表示查所有 this.navbarID ? this.navbarID : 1
        pageSize: 0, // 每页条数，0表示不分页
        pageIndex: 0 // 页码，从1开始
      };
      ResourceSearchPage(data).then(result => {
        this.dataList = [];
        result.data.pageData.forEach(o => {
          o.isActive = false;
          if (o.ext == 'mp3' || o.ext == 'MP3') {
            this.dataList.push(o);
          }
        });
        // this.dataList = result.data.pageData;
        console.log('getMusicList', result.data);
      });
    },
    seletedItem (item) {
      console.log('seletedItem,', item);
      if (this.recorder) {
        this.musicPause();
        this.recorderDuration = 0;
        this.musicTarget = null;
        this.playRecorderDuration = 0;
        this.recorder = null;
      }
      this.musicTarget = item;
      this.recorder = document.getElementById('musicplayer');
      this.recorder.load();
      var that = this;
      this.recorder.oncanplay = function () {
        console.log(item, 'item');
        that.recorderDuration = that.recorder.duration;
        that.audio.recorderDuration = parseInt(that.recorder.duration);
        console.log('myVid.duration', that.recorder.duration);
      };

      // console.log(this.recorder, 'musicmusic', this.recorder.duration);
      // music.play();
      this.dataList.forEach(o => {
        if (o.id == item.id) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
    },
    cleanMusic () {
      if (this.recorder) {
        this.musicPause();
        this.recorderDuration = 0;
        this.musicTarget = null;
        this.playRecorderDuration = 0;
        this.recorder = null;
      }
      layer.alert('清除音乐成功');
      this.dataList.forEach(o => {
        o.isActive = false;
      });
      this.$emit('afterSuccess', this.musicTarget);
    },
    seletedMusic () {
      this.$bus.emit('closeEditorDialog');
      this.$emit('afterSuccess', this.musicTarget);
      this.$emit('cancelClick');
    },
    // 点击上传音频
    chooseFiles (e) {
      var form = e.target.files[0];
      this.uploadSectionFiles(form);
    },
    // 本地上传音乐
    uploadSectionFiles (param) {
      console.log(param, 'uploadSectionFiles', this.$store.getters.token);
      if (!param) {
        return false;
      }
      const fileObj = param;
      // FormData 对象
      const form = new FormData();
      // 文件对象
      form.append('file', fileObj);
      const index1 = fileObj.name.lastIndexOf('.');
      const index2 = fileObj.name.length;
      const suffix = fileObj.name.substring(index1 + 1, index2); // 后缀名
      //  console.log(form, 'fileobj', suffix, fileObj);
      // MP3、MIDI、WMA、VQF、AMR
      const reg = /(mp3|VQF)$/;
      if (fileObj.size > 4096000) {
        layer.alert('该音频大小超出限制4M');
      } else if (reg.test(suffix)) {
        UploadFiles(3, '', form).then(response => {
          let dataObj = {
            'mendian_id': this.$store.getters.token.SaaSClientKey,
            title: fileObj.name,
            resourceType: 3,
            saveType: 1,
            resourceUrl: response.data.url,
            ext: suffix,
            coverPicUrl: '',
            coverPicExt: '',
            dataSize: fileObj.size,
            category: 1
          };
          layer.alert('上传音乐成功');
          this.seletedItem(dataObj);
          this.setInputValue();
          // this.uploadImgSuccess(dataObj);
        }).catch(err => {
          layer.alert(err.msg);
          // console.info(err);
        });
      } else {
        layer.alert('不支持此类型音频,只支持MP3格式');
      }
    },
    setInputValue () {
      var loadInput = this.$el.querySelector('.music_uploading');
      console.log('loadInput', loadInput);
      loadInput.value = '';
    },
    // 添加图片成功
    uploadImgSuccess (data) {
      // console.log(data, '失败的数据')
      AddBillResource(0, data).then(response => {
        this.getMusicList();
      }).catch(err => {
        layer.alert('不支持此音频的上传');
      });
    },
    handleAudioTimeUpdated (res) {
      if (!this.recorder) {
        return false;
      }
      // this.audio.currentTime = res.target.currentTime;
      // if (this.audio.currentTime >= this.audio.recorderDuration) {
      //   this.audio.isPlaying = false;
      //   this.recorder.pause();
      //   this.recorder.currentTime = 0;
      // }
    },
    onLoadedmetadata (res) {
      console.log('onLoadedmetadata');
      this.audio.recorderDuration = parseInt(res.target.duration);
      this.clickAudioPlayBtn();
    },
    handleMouseDownStart (e) {
      this.setValue(e);
      document.addEventListener('mousemove', this.handleTouchMove);
      document.addEventListener('mouseup', this.handleTouchEnd);
    },
    handleTouchMove (e) {
      this.setValue(e);
    },
    handleTouchEnd (e) {
      this.setValue(e);
      document.removeEventListener('mousemove', this.handleTouchMove);
      document.removeEventListener('mouseup', this.handleTouchEnd);
    },
    // 从点击位置更新 value
    setValue (e) {
      // Element.getBoundingClientRect() 方法返回元素的大小及其相对于视口的位置。
      // clientX  点击位置距离当前body可视区域的x，y坐标
      if (!this.recorder) {
        return false;
      }
      const $el = this.$el;
      const { recorderDuration, minTime, step } = this.audio;
      let value = ((e.clientX - $el.getBoundingClientRect().left) / $el.offsetWidth) * (recorderDuration - minTime);
      value = Math.round(value / step) * step + minTime;
      value = parseFloat(value.toFixed(5));
      if (value > recorderDuration) {
        value = recorderDuration;
      } else if (value < minTime) {
        value = minTime;
      }
      this.recorder.currentTime = value;
      this.playRecorderDuration = value;
      // if (this.isPause && value == 0) {
      //   console.log('触发,');
      //   this.musicPlay();
      // }
      console.log(this.playRecorderDuration, 'setValue', this.recorder.currentTime);
    }

  }

};
</script>