<template>
  <!-- 优惠券内容 -->
    <div class="list_top">
      <div class="">
        <div class="list_info">
          <div class="list_info_type">
            {{vType}}
          </div>
          <div class="list_info_name">
            {{dataInfo.scopeExplain?dataInfo.scopeExplain:' --'}}
          </div>
        </div>
        <div class="list_info_date">
          有效期：{{dataInfo.time_start}} 至 {{dataInfo.time_end}}
        </div>
      </div>
      <div class="list_top_right">
        <div class="list_amount">
          <span class="font_unit">￥<span :style="vMoneySize">{{vParseInt}}</span><span class="font_number_small" v-if="vDecimals">.{{vDecimals}}</span> </span>
        </div>
        <div class="list_amount_scope" v-if="dataInfo.type==2 ||dataInfo.type==1"><span>满{{this.$utils.mMoneyType(dataInfo.xiao_fei_tiao_jian,2)}}元使用</span></div>
        <div class="list_scope" v-if="!(dataInfo.type==2 ||dataInfo.type==1) || dataInfo.only_newuser==1"><span v-if="dataInfo.only_newuser==1">限新生领取</span></div>
      </div>
    </div>
</template>
<script>

export default {
  components: {},
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object // 券信息
  },
  created () {

  },
  watch: {

  },
  computed: {
    // 优惠方式:1满折， 2满减, 3.特价 4.代金券
    vType () {
      switch (Number(this.dataInfo.type)) { // 优惠方式 2满减, 3.特价 4.代金券
        case 1:
          return '满折券';
        case 2:
          return '满减券';
        case 3:
          return '特价券';
        case 4:
          return '代金券';
        default:
          return '';
      }
    },
    // 优惠金额
    vCouponMoney () {
      switch (Number(this.dataInfo.type)) { // 优惠方式 2满减, 3.特价 4.代金券
        case 1:
        case 2:
        case 4:
          return this.$utils.mAmountType(this.dataInfo.di_xian_jin, 2) || 0;
        case 3:
          return this.$utils.mAmountType(this.dataInfo.you_hu_money, 2) || 0;
        default:
          return '';
      }
    },
    // 整数显示
    vParseInt () {
      return parseInt(this.vCouponMoney);
    },
    // 小数显示
    vDecimals () {
      if (this.vCouponMoney && JSON.stringify(this.vCouponMoney).indexOf('.') > -1) {
        return JSON.stringify(this.vCouponMoney).split('.')[1];
      } else {
        return '';
      }
    },
    //  金额显示尺寸
    vMoneySize () {
      let lengthSize = JSON.stringify(this.vParseInt).length;
      if (lengthSize >= 1 && lengthSize < 4) {
        return 'font-size:' + 36 + 'px;';
      } else {
        return 'font-size:' + 28 + 'px;';
      }
    }
  },
  methods: {
    afterSuccess (type, data) {
      this.$emit('afterSuccess', type, data);
    },
    closeDialog () {
      this.$emit('closeDialog');
    }
  }
};
</script>

