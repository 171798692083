<template>
  <div class=""
       v-if="isStudentPopups">
    <div class="shade">
    </div>
    <div class="student_conflict_popup">
      <div class="student_conflict_popup_close"
           @click="doCloseClick"></div>
      <div class="student_conflict_title"
           :class="{type_green:popupContent.mark=='normal'}">
        <div v-if="popupContent.titleContent">{{popupContent.titleContent}}</div>
        <div v-if="popupContent.textContent">{{popupContent.textContent}}</div>
      </div>
      <div class="student_conflict_ul">
        <studentList v-for="(item,key) in studentList"
                     :key="key"
                     :item="item"
                     :butNameShow="popupContent.studentButName"
                     @click="doAmendStudentClick"></studentList>
      </div>

      <div class="student_conflict_btn_box">
          <zx-button :btnClass="'student_conflict_btn btn_hover_bg_blue'"
                     :btnStype="' '"
                     :btnText="popupContent.submitButName"
                     :isSingleSubmit="isSingleSubmit"
                     :singleSubmitType="singleSubmitType ||'static'"
                     @click="doAddStudentClick"></zx-button>
     
      </div>
    </div>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
import studentList from './student-list';
import zxButton from '../../zx-button';
export default {
  data () {
    return {
      isStudentPopups: false,
      lastClickTime: null,
      isSubmiting: false
    };
  },
  props: {
    studentList: Array,   // 为数组
    isSingleSubmit: {
      type: Boolean,
      default: false
    }, // 是否启用单次点击
    singleSubmitType: {
      type: String,
      default: 'static'
    }, // static（根据两次点击的时间间隔）, dynamic（提交完成后 手动更改参数）
    popupContent: {
      type: Object,
      default: function () {
        return {
          // titleContent: '',显示的标题内容
          // textContent: '', 显示的文本内容
          // studentButName:''显示学生下的合并按钮名称
          // submitButName:'',提交按钮名称
          // mark: 'normal' , 标示==》 normal：正常, abnormal:异常

        };
      }
    }
  },
  components: {
    studentList,
    zxButton
  },
  computed: {
  },
  created () {
  },
  watch: {

  },
  methods: {
    // 新增学生
    doAddStudentClick () {
      if (!this.isSingleSubmit || this.checkCanClick()) {
        console.log(!this.isSingleSubmit);
        this.$emit('doAddStudentClick', () => { this.isSubmiting = false; });
      }
    },
    checkCanClick () {
      if (this.singleSubmitType == 'static') {
        let now = Date.new();
        let r = !this.lastClickTime || now - this.lastClickTime > 800;
        if (r) {
          this.lastClickTime = now;
        }
        return r;
      } else {
        let r = !this.isSubmiting;
        this.isSubmiting = true;
        return r;
      }
    },
    // 合并（修改）学生
    doAmendStudentClick (item, callBack) {
      this.$emit('doAmendStudentClick', item, callBack);
    },
    doCloseClick () {
      this.$emit('doCloseClick');
      this.isStudentPopups = false;
    }
  }
};
</script>


