<template>
  <div class="student_info_supplement form_info">
    <div class="defult_dialog_searchform">
      <!-- 搜索框 -->
      <div class="search_form">
        <input type="text"
               v-model="searchText"
               class="search_input"
               placeholder="">
        <span class="remove_btn"
              v-show="searchText.length > 0"
              @click.stop="ClearSearchText"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'searchForList',
  data () {
    return {
      searchText: ''
    };
  },
  created () {

  },
  watch: {
    searchText () {
      this.search();
    }
  },
  methods: {
    search () {
      this.$emit('searchAddress', this.searchText);
    },
    ClearSearchText () {
      this.searchText = '';
      this.$emit('searchAddress', this.searchText);
    }

  }
};
</script>
