<template>
  <div class="payment_statistics_bottom ">
    <div class="course_statistics_table_search flex course_statistics_title">
      <div class="table_select_box"
           style="margin-right: 10px; min-width: 120px;margin-left:0px">
        <el-select v-model="searchObj.TeacherKey"
                   placeholder="选择学管师"
                   style="width: 150px;margin-right:20px;height: 36px;"
                   ref="resetText"
                   @change="selectTeacher">
          <el-option v-for="item in vteacherList"
                     :key="item.OLAPKey"
                     :label="item.MainDemoName"
                     :value="item.OLAPKey">
          </el-option>
        </el-select>
        <div class="statistics_list">
          <span class="statistics_item border_left"
                :class="(!searchObj.StatusType||searchObj.StatusType==0)?'opt':''"
                @click="changeStatusType(0)">全部</span>
          <span class="statistics_item "
                style="border-left:none"
                :class="searchObj.StatusType==1?'opt':''"
                @click="changeStatusType(1)">续费预警:{{Number(dataInfo.RenewalWarningCount)}}</span>
          <span class="statistics_item statistics_item_red border_right"
                :class="searchObj.StatusType==2?'opt':''"
                @click="changeStatusType(2)">失效未续:{{Number(dataInfo.NotRenewalCount)}} </span>
        </div>
      </div>

      <div class="course_statistics_search">
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入学生名'"
                                  :clearAll="true"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin-left: 15px;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list class="summarizing_list"
                  :key="tableKey"
                  :tableData="vdataReportList"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :tableColumns="tableCourseColumns"
                  :isSearchText="false"
                  :isNeedInnerScroll="false"
                  :defaultSort="defaultSort"
                  :queryParams="searchObj"
                  :footerContent="vFooterContent"
                  @loadTableData="getDataList"></table-list>
    </div>
    <custom-dialog title="转化关联"
                   width="410px"
                   :visible.sync="isShowRelevantForm"
                   :before-close="closeRelevantFormDialog">
      <relevant-form :StudentKey="StudentKey"
                     :TableID="ids"
                     :studentName="studentName"
                     @afterSuccess="relevantFormSuccess"
                     @cancelClick="closeRelevantFormDialog"></relevant-form>
    </custom-dialog>
  </div>
</template>
<script>
// import dateOption from '../../class-spend-statistics-analyze/components/date-option';

// import list from '../../class-spend-statistics-analyze/components/table';
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import relevantForm from './relevant-form.vue';
import { GetRenewalXykdList, GetRenewalWarningCount, NotRenewXykd } from '../../../../API/workbench';
export default {
  data () {
    return {
      studentName: '',
      StudentKey: '',
      ids: '',
      isShowRelevantForm: false, // 转化关联
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 15,
        searchText: '',
        orderBy: '',
        type: '',
        TeacherKey: 0,
        TeacherKeyValue: '',
        StatusType: '',
        FollowTime: '',
        startTime: '', // this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: '', // this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0)),
        IsExportKey: 0
      },
      dataInfo: {
        RenewalWarningCount: 0, // 续费预警
        NotRenewalCount: 0, // 失效未续
        AttendanceWarningCount: 0, // 预警出勤率数
        CampusWarningCount: 0, // 长期未到校预警数
        StuentNotOneCount: 0 // 排课不足预警
      },
      overdueTypeList: [   // 1-续费预警 2-失效未续 3-出勤率预警 4-排课不足 5-长期未到校预警
        {
          label: '全部',
          value: 0
        },
        {
          label: '续费预警',
          value: 1
        },
        {
          label: '失效未续',
          value: 2
        },
        {
          label: '出勤率预警',
          value: 3
        },
        {
          label: '排课不足',
          value: 4
        },
        {
          label: '长期未到校预警',
          value: 5
        }
      ],
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 55,
          sortable: false,
          align: 'left',
          type: 'number-item',
          className: 'table_index_gray'
        },
        {
          label: '购课日期',
          prop: 'GenerateTime',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '学管师',
          prop: 'AdviserStudentKeyValue',
          width: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'text-item'
        },
        {
          label: '学生名',
          prop: 'MainDemoName',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'student',
          extend: {
            studentKey: 'OLAPKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '课单类型',
          prop: 'ApplyTypeKeyValue',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '可用课时',
          prop: 'CurrentCount',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'amount-item',
          decimalPlace: 1

        },
        {
          label: '可用天数',
          prop: 'PeriodCount',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'number-item'
        },
        {
          label: '课时/天数结余',
          prop: 'ValueAmount',
          sortable: 'custom',
          type: 'amount-item',
          width: 120,
          className: 'font_dual',
          decimalPlace: 2
        },
        {
          label: '适用课程',
          prop: 'ApplyCorName',
          sortable: false,
          type: 'text-item',
          minWidth: 180
        },
        {
          label: '课单状态',
          prop: 'StuatusTypeKeyValue',
          sortable: false,
          type: 'text-item',
          width: 100
        },

        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          fixed: 'right',
          minWidth: 160,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '关联续费课单',
                extend: {
                  click: (rowData) => {
                    this.doAfterTableBtnClick(rowData);
                  }
                }
              },
              {
                name: '不续',
                extend: {
                  click: (rowData) => {
                    this.noRenew(rowData);
                  }
                }
              }

            ]
          }
        }

      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
    relevantForm
  },
  props: {
    seletedItem: {
      type: Object,
      default: () => {
        return {
          OLAPKey: 0,
          MainDemoName: '趁',
          StudentCount: 50
        };
      }
    },
    teacherList: Array

  },

  created () {
  },
  computed: {
    vteacherList () {
      let newArr = []
      newArr = this.$utils.parseJson(this.teacherList || [])
      newArr.unshift({
        OLAPKey: -1,
        MainDemoName: '未分配学管师'
      })
      newArr.unshift({
        OLAPKey: 0,
        MainDemoName: '全部学管师'
      })

      return newArr
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // let info = o.ClassTime.substring(0, 10) + ',' + o.ClassTime.substring(11);
        // o.classTimeObj = info.split(',');
        // o.ClassTime = o.ClassTime.substring(5);
        o.ClassAwayAmountCount = Number(o.ClassAwayAmountCount || 0);
        o.ClassHourDebtCount = Number(o.ClassHourDebtCount) || 0;
        // o.AttenceTime = o.AttenceTime.substring(5, 16);
        // this.$set(o, 'OLAPKey', o.CourseNameKey);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterContent () {
      return '共' + this.totalNum + '条数据';
    }
  },
  mounted () {
    this.getDataList();
    this.getDataInfo();
  },
  watch: {

  },
  methods: {
    selectTeacher () {
      this.getDataList();

    },
    changeStatusType (type) {
      this.searchObj.StatusType = type
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = GetRenewalXykdList;
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '续费跟进统计',
          clist: [
            { title: '购课日期', cName: 'GenerateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学管师', cName: 'AdviserStudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生名', cName: 'MainDemoName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课单类型', cName: 'ApplyTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '可用课时', cName: 'CurrentCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{float::CurrentCount::1::0}}" },
            { title: '可用天数', cName: 'PeriodCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{float::PeriodCount::1::0}}" },
            { title: '课时/天数结余', cName: 'ValueAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', type: "complex", template: "{{float::ValueAmount::1::0}}" },
            { title: '适用课程', cName: 'ApplyCorName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课单状态', cName: 'StuatusTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },

          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    noRenew (item) {
      layer.confirm('请问是否不续该课单', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              NotRenewXykd(item.TableID).then(result => {
                this.getDataList();
                this.getDataInfo();
                console.log(result.data, 'MessageSearchClassAwayForCourse');
              }).catch(error => {
                console.log('errorMessageSearchClassAwayForCourse', error.msg);
              });
              layer.close();
            }
          }
        ]
      });
    },
    getDataInfo () {
      GetRenewalWarningCount(this.searchObj.TeacherKey).then(result => {
        this.dataInfo = result.data;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    selectOverdueType (item) { },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      // this.searchObj.orderBy = '';
      this.searchObj.type = '';
      this.getDataList();
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (item, date) {
      console.log('searchData', date);
      this.searchObj.TeacherKey = item.TeacherKey;
      this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    search () {
      // this.searchObj.TeacherKey = 0;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },

    getDataList (queryParams) {
      let fn = '';
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      GetRenewalXykdList(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    closeRelevantFormDialog () {
      this.isShowRelevantForm = false;
    },
    relevantFormSuccess () {
      this.getDataList();
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      this.StudentKey = rowData.OLAPKey;
      this.ids = rowData.TableID;
      this.studentName = rowData.MainDemoName;
      this.isShowRelevantForm = true;
    }

  }
};
</script>
<style scoped>
.mendian_backstage_search >>> .search_box {
  width: 240px !important;
  margin-right: 30px !important;
  min-width: 240px !important;
}
.course_statistics_search {
  width: auto !important;
}
.change_history_data {
  position: absolute;
  right: 17px;
  top: 90px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
.payment_statistics_bottom .list_title {
  width: 100%;
  height: 87px;
  margin: auto;
  padding-left: 20px;
  line-height: 87px;
}
.statistics_list {
  display: flex;
  flex-direction: row;
  width: auto;
}
.statistics_list .opt {
  background: rgba(52, 152, 219, 0.2);
  border: 1.25px solid #3498db !important;
  color: #3498db !important;
}
.statistics_list .statistics_item {
  height: 30px;
  padding: 5px;
  font-size: 14px;
  color: #ff9800;
  border: 1.25px solid #ff9800;
}
.statistics_list .statistics_item:hover {
  background: rgba(52, 152, 219, 0.2);
}
.statistics_list .statistics_item_red {
  height: 30px;
  padding: 5px;
  font-size: 14px;
  color: #f75040;
  border: 1.25px solid #f75040;
}
.statistics_list .border_left {
  min-width: 68px;
  text-align: center;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  /* border-right: none; */
}
.statistics_list .border_right {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: none;
}
</style>