<template>
  <div>
    <div class="form_course_block">
      <div v-if="dataInfo.length>0">
        <div>
          <div class="course_title">
            课程信息
          </div>
          <course-item v-for="(item,index) in dataInfo"
                       :key="index"
                       :courseInfo="item"
                       @editClcik="editCourse"
                       @deleteClcik="deleteCourse"></course-item>
        </div>
      </div>
      <div class="course_bottom">
        <div class="course_bottom_btn"
             @click="openCourseForm">
          <span class="btn_icon">+</span>添加课程
        </div>
      </div>
    </div>
    <!-- 选中修改项-课名-->
    <custom-dialog :title="vTitle"
                   :visible.sync="isShowCourseFormDialog"
                   :before-close="closeCoursFormDialog">
      <course-dialog :dataInfo="curSelectedCourse"
                     :submitInfo="submitInfo"
                     @addCourseInfo="confirmCourseForm"
                     @cancelClick="closeCoursFormDialog"></course-dialog>
    </custom-dialog>
  </div>
</template>

<script>
import courseItem from './course-item';
import courseDialog from './course-dialog/index';
export default {
  data () {
    return {
      optionType: 1, // 默认新增课程
      isShowCourseFormDialog: false,
      curSelectedCourse: {} // 当前选中的课程信息
    };
  },
  props: {
    dataInfo: Array,
    submitInfo: Object
  },
  computed: {
    vTitle () {
      let str = '添加课程';
      if (this.optionType == 2) {
        str = '修改课程';
      }
      return str;
    }
  },
  components: {
    courseItem,
    courseDialog
  },
  methods: {
    openCourseForm () {
      this.optionType = 1;
      this.curSelectedCourse = {};
      this.isShowCourseFormDialog = true;
    },
    editCourse (data) {
      this.optionType = 2;
      this.curSelectedCourse = data;
      this.isShowCourseFormDialog = true;
    },
    deleteCourse (data) {
      console.log(data, '删除');
      // 找到该下标
      let findIndex = this.dataInfo.findIndex((o) => {
        return Number(o.OLAPKey) == Number(data.OLAPKey);
      });
      this.dataInfo.splice(findIndex, 1);
    },
    confirmCourseForm (data) {
      console.log(data, this.dataInfo, '编辑');
      // 新增
      if (this.optionType == 1) {
        // data.Index = this.dataInfo.length
        this.dataInfo.push(data);
      } else {
        // 找到该下标
        let findIndex = this.dataInfo.findIndex((o) => {
          return Number(o.OLAPKey) == Number(data.OLAPKey);
        });
        // 修改
        this.dataInfo.splice(findIndex, 1, data);
      }
      this.closeCoursFormDialog();
    },
    closeCoursFormDialog () {
      this.isShowCourseFormDialog = false;
    }
  }
};
</script>

<style>
</style>