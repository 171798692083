<template>
  <div class="mainbox">
    <div class="modulebox">
      <div class="nav clearfix">
        <div class="block" :class="listNow==1 ? 'cur' : ''" @click="listNow=1">渠道列表</div>
        <div class="block" :class="listNow==2 ? 'cur' : ''" @click="toDealRecord(null)">成交与返佣记录</div>
        <div class="block" :class="listNow==3 ? 'cur' : ''" @click="listNow=3">设置方案佣金</div>
      </div>
      <!-- 渠道列表 -->
      <channel-list v-if="listNow==1" @rebate="toDealRecord"></channel-list>
      <!-- 成交与返佣记录 -->
      <deal-record v-if="listNow==2" :searchobj="searchDealRecord"></deal-record>
      <!-- 设置方案佣金 -->
      <setup-plan v-if="listNow==3"></setup-plan>
    </div>
    <!-- 弹层 -->
    <dialog-factory ref="customerChannelManagementDialogFactory" :key="'customerChannelManagement'" :routerName="'customerChannelManagement'"></dialog-factory>
    <dialog-factory-common ref="customerChannelManagementDialogFactoryCommon"></dialog-factory-common>
  </div>
</template>

<script>
import channelList from './channel-list.vue';
import dealRecord from './deal-record.vue';
import setupPlan from './setup-plan.vue';
import dialogFactory from '../../common/dialog-factory';
export default {
  name: 'customerChannelManagement',
  data () {
    return {
      listNow:1,
      searchDealRecord:{
        OLAPKey:'',
        MainDemoName:''
      }
    };
  },
  components: {
    channelList,
    dealRecord,
    setupPlan,
    dialogFactory
  },
  created () {
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'customerChannelManagement') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'customerChannelManagement') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });
  },
  methods: {
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.customerChannelManagementDialogFactory) {
        this.$refs.customerChannelManagementDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.customerChannelManagementDialogFactoryCommon) {
        this.$refs.customerChannelManagementDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    },
    toDealRecord (row) {
      if (this.listNow!=2) {
        if (row) {
          this.searchDealRecord.OLAPKey = row.OLAPKey;
          this.searchDealRecord.MainDemoName = row.MainDemoName;
        } else {
          this.searchDealRecord.OLAPKey = '';
          this.searchDealRecord.MainDemoName = '';
        }
        this.listNow = 2;
      }
    }
  }
};
</script>

<style scoped>
.mainbox{padding:20px;}
.mainbox .modulebox{min-width:1300px;max-width:1900px;background-color:#ffffff;border-radius:8px;padding:20px;margin:0 auto;}
.mainbox .modulebox .nav{margin-bottom:20px;}
.mainbox .modulebox .nav .block{float:left;height:50px;line-height:50px;font-size:18px;box-sizing:border-box;cursor:pointer;margin-right:20px;}
.mainbox .modulebox .nav .block.cur{font-weight:bold;border-bottom:3px solid #3498db;}
.mainbox .modulebox >>> .list .formbox{margin-bottom:10px;}
.mainbox .modulebox >>> .list .formbox .text{display:inline-block;height:36px;line-height:36px;font-size:14px;color:#666666;margin-right:10px;}
.mainbox .modulebox >>> .list .formbox .el-select{display:inline-block;width:150px;height:36px;margin-right:20px;}
.mainbox .modulebox >>> .list .formbox .el-select input.el-input__inner{border:1px solid #ececec;}
.mainbox .modulebox >>> .list .formbox .input_text{width:150px;height:36px;margin-right:20px;}
.mainbox .modulebox >>> .list .formbox .btn{display:inline-block;height:36px;line-height:36px;font-size:14px;border-radius:4px;box-sizing:border-box;margin-right:10px;cursor:pointer;}
.mainbox .modulebox >>> .list .formbox .btn.btn_1{color:#FFFFFF;background-color:#2D98D9;border:1px solid #2886BF;padding:0 20px;}
.mainbox .modulebox >>> .list .formbox .btn.btn_2{color:#333333;background-color:#FFFFFF;border:1px solid #ECECEC;padding:0 10px;}
.mainbox .modulebox >>> .list .el-table__header-wrapper .el-table__cell{color:#909399;background-color:#f0f2f6;}
.mainbox .modulebox >>> .list .el-pagination{text-align:right;background-color:transparent;}
.mainbox .modulebox >>> .list .row_btn{color:#3498db;cursor:pointer;margin-right:10px;}
</style>