<template>
  <div class="cw_summarizing"
       :class="$store.getters.SaaSClientEditionKey!=6?'jwTipbtomm':'cwsTipbtomm'
         ">
    <!-- 条件过滤 -->
    <div class="date_time pr">
      <div class="new_table_title_date left_type">
        <div @click.stop="searchLast()"
             class="date_last fl"></div>
        <div class="cw_date_calendar">
          <single-year-picker @chgDate="changeDay"
                              :type="'month'"
                              style="opacity: 0;"></single-year-picker>
        </div>
        <span class="date_text">{{yearView}}&nbsp;年&nbsp;{{monthView}}&nbsp;月</span>
        <div @click.stop="searchNext()"
             class="date_next fr"></div>
      </div>
    </div>
    <!-- 汇总信息 -->
    <div class="top_content">
      <statistics-content-list v-for="(item,key) in cwsSummList"
                               :key="key"
                               :dataInfo="item"
                               @doSelected="doSelected"></statistics-content-list>
    </div>
  </div>
</template> 
<script>
import statisticsContentList from '../../../head-quarters/campus-supervise-school-statistics/common/statistics-content-list';
import singleYearPicker from '../../../base-module/date-picker-common/single-year-picker';
export default {
  data () {
    return {
      monthView: '',
      yearView: '',
      startDate: this.$utils.getCurrentMonthFirst(),
      endDate: this.$utils.getCurrentMonthLast()
    };
  },
  components: {
    statisticsContentList,
    singleYearPicker
  },
  props: {
    cwsSummList: Array
  },
  created () {
    console.log(this.$store.getters, 'this.$store.getters');
    this.atTimeView(Date.new());
  },
  methods: {
    // 当前时间
    atTimeView (date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month < 10) { month = '0' + month; }
      this.yearView = year;
      this.monthView = month;
    },
    // 搜索上一个月
    searchLast () {
      this.monthView--;
      if (this.monthView < 10) { this.monthView = '0' + this.monthView; }
      if (this.monthView < 1) { this.monthView = 12; this.yearView = this.yearView - 1; }
      this.searchDate();
    },
    // 搜索下一个月
    searchNext () {
      this.monthView++;
      if (this.monthView < 10) { this.monthView = '0' + this.monthView; }
      if (this.monthView > 12) { this.monthView = '01'; this.yearView = this.yearView + 1; }
      this.searchDate();
    },
    // 选择某一天
    changeDay (val, startTime, endTime) {
      console.log(val, startTime, endTime, '点击当前汇总数据');
      let date = Date.new(startTime);
      this.atTimeView(date);
      this.startDate = startTime.replace(/\//g, '-');
      this.endDate = endTime.replace(/\//g, '-');
      this.$emit('searchDate', this.startDate, this.endDate);
    },
    // 搜索日期
    searchDate () {
      // 开始日期
      this.startDate = this.yearView + '-' + this.monthView + '-' + '01';
      // 结束日期
      this.endDate = this.yearView + '-' + this.monthView + '-' + this.mGetDate(this.yearView, this.monthView);
      this.$emit('searchDate', this.startDate, this.endDate);
    },
    mGetDate (year, month) {
      var d = Date.new(year, month, 0);
      return d.getDate();
    },
    // 汇总
    doSelected (item) {
      this.$emit('doSummarizingClick', item, this.startDate, this.endDate);
    }
  }
};
</script>

<style>
.date_time {
  padding-top: 20px;
}
.date_time .new_table_title_date.left_type {
  font-size: 14px;
  width: 160px;
}
.date_time .new_table_title_date.left_type .date_last,
.date_time .new_table_title_date.left_type .date_next {
  width: 30px;
  height: 30px;
  background-size: 5px;
}
.date_time .new_table_title_date .date_last {
  background: url(../../../../../public/image/change_prev_defult.png) no-repeat
    center center;
}
.date_time .new_table_title_date .date_last:hover {
  background-image: url(../../../../../public/image/change_prev_light.png);
}
.date_time .new_table_title_date .date_next {
  background: url(../../../../../public/image/change_next_defult.png) no-repeat
    center center;
}
.date_time .new_table_title_date .date_next:hover {
  background-image: url(../../../../../public/image/change_next_light.png);
}
.date_time .cw_date_calendar {
  width: 100px;
  height: 28px;
  cursor: pointer;
  position: absolute;
  top: 24px;
  left: 50px;
}
.date_time .cw_date_box {
  position: absolute;
  width: 100px;
  height: 28px;
  cursor: pointer;
  opacity: 0;
}
.cw_summarizing .top_content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 110px;
  padding: 20px 20px;
}
.cw_summarizing .top_content_list {
  width: 119px;
  height: 88px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  line-height: 37px;
}
.cw_summarizing .top_content_list.bottom_border_blue::after {
  content: '';
  background: #90bcff;
  border: 2px solid #90bcff;
  box-sizing: border-box;
  position: absolute;
  width: 119px;
  height: 4px;
  border-radius: 0px 0px 4px 4px;
}
.cw_summarizing .top_content_list.bottom_border_green::after {
  content: '';
  background: #7cd499;
  border: 2px solid #7cd499;
  box-sizing: border-box;
  position: absolute;
  width: 119px;
  height: 4px;
  border-radius: 0px 0px 4px 4px;
}

.cw_summarizing .top_content_list .list_title {
  font-size: 14px;
  padding: 0px 0 5px 15px;
  color: #666666;
}
.cw_summarizing .top_content_list .list_content {
  font-size: 30px;
  padding-left: 15px;
}
.cw_summarizing .top_content_list .list_content .list_unit {
  font-size: 12px;
  overflow: hidden;
  display: inline-block;
  position: absolute;
  margin: 7px 0 0 5px;
}
.cw_summarizing .top_content_list .list_content .list_num {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 68%;
}
.jwTipbtomm {
  margin-bottom: 5px;
}
.cwsTipbtomm {
  margin-bottom: 30px;
}
</style>
