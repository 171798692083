<template>
  <!-- 成果统计 -->
  <div class="achievement_statistics_box">
    <!-- 表格统计 -->
    <div class="achievement_left">
      <leftTable :tableData="tableData"
                 @getList="getList"
                 :modeType="modeType"></leftTable>
    </div>
    <!-- 柱状图统计 -->
    <div class="achievement_right">
      <rightFrom :DataList="tableData"
                 ref="statisticsRight"
                 :modeType="modeType"></rightFrom>
    </div>
  </div>
</template>
<script>
import { SignUpCollectMoneyStatistics, SaleNewAddThreadStatistics, SalePromiseVisitStatistics, SaleTrialLessonStatistics } from '../../../../API/workbench';
import leftTable from './left-table-view';
import rightFrom from './right-form-view';
export default {
  data () {
    return {
      tableData: []
    };
  },
  props: {
    searchObj: Object,
    modeType: Number
  },
  components: {
    leftTable,
    rightFrom
  },
  watch: {
    'searchObj': {
      handler (newval, oldval) {
        this.getList();
      },
      deep: true
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getList();
    });
  },
  methods: {
    getList () {
      let apiF = this.modeType == 1 ? SignUpCollectMoneyStatistics : this.modeType == 2 ? SaleNewAddThreadStatistics : this.modeType == 3 ? SalePromiseVisitStatistics : SaleTrialLessonStatistics;
      apiF(this.searchObj).then(result => {
        if (result.code == 0) {
          result.data.forEach(item => {
            item.TransformationRateText = Number(item.TransformationRate) == 0 ? '-' : item.TransformationRate + '%';
            item.GetToSchoolRateText = Number(item.GetToSchoolRate) == 0 ? '-' : item.GetToSchoolRate + '%';
            item.TrialLessonFinishRateText = Number(item.TrialLessonFinishRate) == 0 ? '-' : item.TrialLessonFinishRate + '%';
          });
          this.tableData = result.data;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
};
</script>
<style scoped>
.achievement_statistics_box {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.achievement_left {
  width: 602px;
}
.achievement_right {
  flex: 1;
  padding-left: 20px;
}
</style>


