<template>
  <div class="investor_recycleBin  table_list_content summarizing_list">
    <div class="recycle_title">投资人回收站</div>
    <search-box :isBatchDistribute="isBatchDistribute"
              :paramsForQuery="paramsForQuery" 
              :batchAllotList="batchAllotList" 
              :tableData="investorList"
              @searchClick="searchClick" 
              @clearSearchParams="clearSearchParams"
              @enableBatchAllot="enableBatchAllot"
              @doBatchAllot="distributeAdvisor"
              @cancleBatchDistribute="cancleBatchDistribute" ></search-box>
    <div class="table_list_content">
      <table-list ref="tableListRef"
                  :tableData="investorList"
                  :tableColumns="tableColumns"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="paramsForQuery"
                  :isShowCheckBox="true"
                  @changeCheckBoxFun="clickCheckBox"
                  @loadTableData="loadInvestorList"></table-list>
                  <!-- :selectable="false" -->
    </div>
    <dialog-factory-common ref="investorRecycleBinFactoryCommon"></dialog-factory-common>
  </div>
</template>

<script>

import tableList from '../../../../common/table-list';// 下拉选择框
import searchBox from './search-box';
import { SearchPageRecycleInvestor } from '../../../../../API/workbench';
export default {
  data () {
    return {
      tableColumns: [
        {
          label: '录入日期',
          prop: 'EntryTime',
          width: 120,
          type: 'date-item',
          sortable: 'custom'
        },
        {
          label: '意向级别',
          prop: 'IntentionKeyValue',
          width: 60,
          type: 'text-item',
          columnClass: 'font_dual',
          sortable: 'custom'
        },
        {
          label: '投资人',
          prop: 'MainDemoName',
          width: 120,
          type: 'text-item'
        },
        {
          label: '有效性',
          prop: 'IsValidKeyValue',
          type: 'text-item',
          width: 100
        },
        {
          label: '手机号',
          prop: 'MobilePhone',
          width: 120,
          type: 'text-item'
        },
        {
          label: '计划城市',
          prop: 'CityKeyValue',
          type: 'text-item',
          width: 120
        },
        {
          label: '加盟方式',
          prop: 'FranchiseTypeKeyValue',
          type: 'text-item',
          width: 80
        },
        {
          label: '招商预算',
          prop: 'budgetAmount',
          type: 'text-item',
          width: 120
        },
        {
          label: '来源方式',
          prop: 'SourceTypeKeyValue',
          type: 'text-item',
          width: 120
        },
        {
          label: '签单进度',
          prop: 'StatusKeyValue',
          type: 'text-item',
          width: 120
        },
        {
          label: '招商顾问',
          prop: 'CounselorKeyValue',
          type: 'text-item',
          width: 120,
          extend: {
            investorID: 'OLAPKey',
            className: 'font_blue',
            click: (rowData) => { this.distributeAdvisor(rowData); }
          }
        },
        {
          label: '跟进记录',
          prop: 'Content',
          type: 'follow-up-record-column',
          width: 391,
          extend: {
            FollowTime: 'FollowTime' // 最近跟进时间
          }
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          width: 80,
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  click: (rowData) => { this.lookUpDetail(rowData); }
                }
              }
            ]
          }
        }
      ],
      paramsForQuery: {
        pageIndex: 0, //	页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: '', // 排序顺序
        CounselorKey: 0, // 投资顾问
        IsValidKey: 0, // 行业
        SourceTypeKey: 0, // 意向级别1-S;2-A;3-B;4-C
        StatusKey: '', // 签单进度0-待接收;1-新线索;2-跟进中;3-已预约;4-已考察;5-已签单;6-已付订;7-已全款;-1-已拒绝
        IsExportKey: 0, // 是否导出：0-否;1-是;如果为是pageIndex传0,pageSize传0
        jsonFormate: null
      },
      investorList: [], // 投资人列表

      totalNum: 0,
      defaultSort: {},
      batchAllotList: [], // 选择的投资人列表
      isBatchDistribute: false
    };
  },
  components: {
    tableList,
    searchBox
  },
  computed: {
  },
  created () {
    this.loadInvestorList();
    this.resgisterBusEvent();
  },
  methods: {
    // 获取回收站投资人列表
    loadInvestorList () {
      SearchPageRecycleInvestor(this.paramsForQuery).then(result => {
        console.log('获取回收站投资人列表', result.data.PageDataList);
        result.data.PageDataList.forEach(obj => {
          obj.budgetAmount = this.$utils.mMoneyType(obj.StratBudgetAmount) + '-' + this.$utils.mMoneyType(obj.EndBudgetAmount) + '万';
        });
        this.investorList = result.data.PageDataList;
        this.totalNum = result.data.Total;
      });
    },
    // 点击搜索
    searchClick (queryParams) {
      this.paramsForQuery = queryParams;
      this.loadInvestorList();
    },
    // 清除搜索条件
    clearSearchParams () {
      this.paramsForQuery.pageIndex = 0;
      this.paramsForQuery.searchText = '';
      this.paramsForQuery.CounselorKey = 0;
      this.paramsForQuery.IsValidKey = 0;
      this.paramsForQuery.SourceTypeKey = 0;
      this.paramsForQuery.StatusKey = '';
      this.loadInvestorList();
    },
    // 查看详情
    lookUpDetail (item) {
      this.$dialog.open(this, {
        name: '投资人详情',
        routerName: this.$route.name,
        moduleName: 'crmImproveClewForm',
        ModulePowerKey: '',
        data: { dataInfo: item, isReadonly: true,isHideEditBtn:true }
      });
    },
    // 启用批量分配
    enableBatchAllot () {
      this.isBatchDistribute = true;
    },
    // 批量分配 选中一行
    clickCheckBox (lists, rowData) {
      if (!this.isBatchDistribute && lists.length > 0) {
        this.isBatchDistribute = true;
      } else if (lists.length == 0) {
        this.isBatchDistribute = false;
      }
      let arr = lists.filter((item) => { return item.OLAPKey > 0; });
      this.batchAllotList = arr;
    },
    // 取消批量分配
    cancleBatchDistribute () {
      this.isBatchDistribute = false;
      this.$refs.tableListRef.$refs.multipleTable.clearSelection();
      this.batchAllotList = [];
    },
    // 分配投资人
    distributeAdvisor (rowData, type) {
      let item = {
        routerName: this.$route.name,
        moduleName: 'distributeInvestmentAdvisor',
        name: '分配投资人',
        data: { investorIDs: rowData.OLAPKey },
        callBack: {
          afterSuccess: (data) => {
            this.loadInvestorList();
            if (type == 'batch') {
              this.cancleBatchDistribute();
            }
          }
        }

      };
      this.$dialog.open(this, item);
    },
    // 批量分配后
    doAfterBatchDistribute (data) {
      this.batchAllotList.forEach(obj => {
        obj.CounselorKey = data.OLAPKey;
        obj.CounselorKeyValue = data.name;
      });
      this.cancleBatchDistribute();
    },
    resgisterBusEvent () {
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'investorRecycleBin') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.investorRecycleBinFactoryCommon) {
        this.$refs.investorRecycleBinFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>

<style >
.investor_manage_view{
  justify-content: space-between;
}
.recycle_title{
  margin-top: 20px;
  line-height: 40px;
  width: 120px;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}
.investor_recycleBin{
  border-radius: 8px;
}
</style>