<template>
  <!-- 授权课件表单 -->
  <div style="height: 200px;">
    <div class="form_info_edit form_info_list">
      <input-number :min="0"
                    :decimalPlaces="0"
                    :formTitle="'硬件设备'"
                    :required="false"
                    :readonly="isReadonly"
                    :formPlaceholder="'请输入'"
                    v-model="AuthorizeDeviceNum"></input-number>
    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { UpdateAuthorizeDeviceNum } from '../../../../../API/workbench';
export default {
  data () {
    return {
      isReadonly: false,
      AuthorizeDeviceNum: '' //	整型	必须		数据源：GET	授权硬件设备数量

    };
  },
  components: {

  },
  props: {
    coursewareId: Number,
    deviceNum: Number
  },
  created () {
    this.AuthorizeDeviceNum = this.deviceNum || 0;
    console.log(this.deviceNum, 'deviceNum');
  },
  mounted () {

  },
  computed: {

  },
  methods: {
    // 提交
    submit (callback) {
      console.log('submit', this.AuthorizeDeviceNum);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      UpdateAuthorizeDeviceNum(this.coursewareId, this.AuthorizeDeviceNum).then(result => {
        layer.alert('修改成功');
        this.$emit('afterSuccess');
        this.cancel();
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callback) {
          callback();
        }
      });
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
      // if (!this.AuthorizeDeviceNum) {
      //   layer.alert('请输入授权硬件设备数量(不得小于0)');
      //   flag = false;
      // }
      return flag;
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>

