<template>
  <!-- 品牌信息 -->
  <div>
    <div class="YK_added_content_form">
      <input-text-form :formTitle="'品牌名称'"
                       :formPlaceholder="'请输入内容'"
                       :required="true"
                       v-model="submitData.BrandName"></input-text-form>
      <div class="form_list">
        <div class="form_list_name required">
          <span class="text_box">加盟方式</span>
        </div>
        <div class="form_list_content">
          <div class="form_list_checkbox flex">
            <div class="checkbox_list"
                 @click="wholeStoreJoin"
                 :class="{opt:submitData.FranchiseTypeKey==1||submitData.FranchiseTypeKey==2}">
              整店加盟
            </div>
            <div class="checkbox_list"
                 @click="coursesJoin"
                 :class="{opt:submitData.FranchiseTypeKey==1||submitData.FranchiseTypeKey==3}">
              课程加盟
            </div>
          </div>
        </div>
      </div>
      <div class="form_list">
        <div class="form_list_name required">
          <span class="text_box">门店数量</span>
        </div>
        <div class="form_list_content">
          <div class="flex">
            <div class="form_list_children_box">
              <div class="children_name">
                直营店
              </div>
              <div class="children_content">
                <input-number-form :className="'children_input'"
                                   v-model="submitData.DirectSaleStoreCount"
                                   :placeholder="'请输入内容'"
                                   :decimalPlaces="0"
                                   :min="0"></input-number-form>
              </div>
            </div>
            <div class="form_list_children_box">
              <div class="children_name">
                整店加盟
              </div>
              <div class="children_content">
                <input-number-form :className="'children_input'"
                                   v-model="submitData.WholeStoreCount"
                                   :placeholder="'请输入内容'"
                                   :decimalPlaces="0"
                                   :min="0"></input-number-form>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="form_list_children_box">
              <div class="children_name">
                课程加盟
              </div>
              <div class="children_content">
                <input-number-form :className="'children_input'"
                                   v-model="submitData.CourseStoreCount"
                                   :placeholder="'请输入内容'"
                                   :decimalPlaces="0"
                                   :min="0"></input-number-form>
              </div>
            </div>
            <div class="form_list_children_box">
              <div class="children_name">
                门店总数
              </div>
              <div class="children_content">
                <input-number-form :className="'children_input'"
                                   v-model="submitData.StoreCount"
                                   :placeholder="'请输入内容'"
                                   :decimalPlaces="0"
                                   :min="0"></input-number-form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form_list">
        <div class="form_list_name required">
          <span class="text_box">1年内加盟数</span>
        </div>
        <div class="form_list_content">
          <div class="flex">
            <div class="form_list_children_box">
              <div class="children_name">
                整店加盟
              </div>
              <div class="children_content">
                <input-number-form :className="'children_input'"
                                   v-model="submitData.OneYearWholeStoreCount"
                                   :placeholder="'请输入内容'"
                                   :decimalPlaces="0"
                                   :min="0"></input-number-form>
              </div>
            </div>
            <div class="form_list_children_box">
              <div class="children_name">
                课程加盟
              </div>
              <div class="children_content">
                <input-number-form :className="'children_input'"
                                   v-model="submitData.OneYearCourseStoreCount"
                                   :placeholder="'请输入内容'"
                                   :decimalPlaces="0"
                                   :min="0"></input-number-form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form_list">
        <div class="form_list_name required">
          <span class="text_box">品牌简介</span>
        </div>
        <div class="form_list_content">
          <div class="form_list_textarea">
            <textarea v-model="submitData.BrandIntroduction"></textarea>
          </div>
        </div>
      </div>
    </div>

    <save-cancel-btn-group class="YK_added_save_cancel_bottom_btn_box"
                           :isSingleSubmit="true"
                           :cancelBtnColor="'btn_hover_bg_blue'"
                           :singleSubmitType="'dynamic'"
                           :cancelBtnText="'上一步'"
                           :btnText="'下一步'"
                           :btnClass="''"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>

  </div>
</template> 
<script>
import inputTextForm from '../common/input-text-form';
import inputNumberForm from '../common/input-number-form';
import { AmendBrandMsg } from '../../../../API/workbench';
export default {
  data () {
    return {
      submitData: {
        OLAPKey: '', // 上架申请key"
        BrandName: '', // 品牌名,
        FranchiseTypeKey: '', // 加盟方式,
        FranchiseTypeKeyValue: '', // 1-整店+课程加盟;2-整店加盟;3-课程加盟,
        DirectSaleStoreCount: '', // 直营店数量,
        WholeStoreCount: '', // 整店加盟数,
        CourseStoreCount: '', // 课程加盟数,
        StoreCount: '', // 门店总数,
        OneYearWholeStoreCount: '', // 一年内整店加盟数,
        OneYearCourseStoreCount: '', // 一年内课程加盟数,
        BrandIntroduction: '' // 品牌简介,
      }
    };
  },
  props: {
    dataInfo: Object
  },
  components: {
    inputTextForm,
    inputNumberForm
  },
  created () {
    this.initData();
  },
  computed: {},
  methods: {
    initData () {
      if (Object.keys(this.dataInfo).length > 0) {
        for (let key in this.submitData) {
          if (this.dataInfo[key]) {
            this.submitData[key] = this.dataInfo[key];
          }
        }
        console.log('this.submitData', this.submitData, this.dataInfo);
      }
    },
    // 整店加盟 1-整店+课程加盟;2-整店加盟;3-课程加盟
    wholeStoreJoin () {
      if (this.submitData.FranchiseTypeKey == 1) {
        this.submitData.FranchiseTypeKey = 3;
        this.submitData.FranchiseTypeKeyValue = '课程加盟';
      } else if (this.submitData.FranchiseTypeKey == 2) {
        this.submitData.FranchiseTypeKey = '';
        this.submitData.FranchiseTypeKeyValue = '';
      } else if (this.submitData.FranchiseTypeKey == 3) {
        this.submitData.FranchiseTypeKey = 1;
        this.submitData.FranchiseTypeKeyValue = '整店+课程加盟';
      } else {
        this.submitData.FranchiseTypeKey = 2;
        this.submitData.FranchiseTypeKeyValue = '整店加盟';
      }
    },
    // 课程加盟 1-整店+课程加盟;2-整店加盟;3-课程加盟
    coursesJoin () {
      if (this.submitData.FranchiseTypeKey == 1) {
        this.submitData.FranchiseTypeKey = 2;
        this.submitData.FranchiseTypeKeyValue = '整店加盟';
      } else if (this.submitData.FranchiseTypeKey == 2) {
        this.submitData.FranchiseTypeKey = 1;
        this.submitData.FranchiseTypeKeyValue = '整店+课程加盟';
      } else if (this.submitData.FranchiseTypeKey == 3) {
        this.submitData.FranchiseTypeKey = '';
        this.submitData.FranchiseTypeKeyValue = '';
      } else {
        this.submitData.FranchiseTypeKey = 3;
        this.submitData.FranchiseTypeKeyValue = '课程加盟';
      }
    },
    // 保存品牌信息
    confirmClick (callBack) {
      console.log(this.submitData);
      let flag = false;
      if (
        !this.submitData.BrandName ||
        !this.submitData.BrandName.replace(/(^\s*)|(\s*$)/g, '')
      ) {
        layer.alert('请输入品牌名称');
        flag = true;
      } else if (!this.submitData.FranchiseTypeKey) {
        layer.alert('请选择加盟方式');
        flag = true;
      }
      //  else if (!this.submitData.DirectSaleStoreCount) {
      //   layer.alert('请输入直营店门店数量');
      //   flag = true;
      // } else if (!this.submitData.WholeStoreCount) {
      //   layer.alert('请输入整店加盟门店数量');
      //   flag = true;
      // } else if (!this.submitData.CourseStoreCount) {
      //   layer.alert('请输入课程加盟门店数量');
      //   flag = true;
      // } else if (!this.submitData.StoreCount) {
      //   layer.alert('请输入门店总数');
      //   flag = true;
      // } else if (!this.submitData.OneYearWholeStoreCount) {
      //   layer.alert('请输入1年内整店加盟数');
      //   flag = true;
      // } else if (!this.submitData.OneYearCourseStoreCount) {
      //   layer.alert('请输入1年内课程加盟数');
      //   flag = true;
      // }
      else if (!this.submitData.BrandIntroduction) {
        layer.alert('请输入品牌简介内容');
        flag = true;
      }
      if (flag) {
        if (typeof callBack == 'function') {
          callBack();
        }
        return false;
      }
      console.log('doSubmitClick', this.submitData);
      AmendBrandMsg(this.submitData).then(
        (result) => {
          this.$emit('saveSuccess', this.submitData, result.data);
        },
        (error) => {
          layer.alert(error.msg);
          if (typeof callBack == 'function') {
            callBack();
          }
        }
      );
    },
    cancelClick () {
      this.$emit('preStep');
    }
  }
};
</script>

<style >
</style>
