<template>
  <div>
    <div class="form_info_edit form_info_list single_shadow content_marginRL">
      <input-dialog :formTitle="'班级'"
                    :formPlaceholder="'必选'"
                    @showEldilog="addClassForTeacher"
                    :required="true"
                    v-model="dataList.classAndGradeL"></input-dialog>
      <input-dialog :formTitle="'课件'"
                    :formPlaceholder="'必选'"
                    :readonly="true"
                    v-if="!isTeachingMode"
                    v-model="dataList.courseware">
      </input-dialog>
      <input-dialog :formTitle="'老师'"
                    :formPlaceholder="'必选'"
                    v-if="!isTeachingMode"
                    :readonly="true"
                    v-model="dataList.teacher">
      </input-dialog>

      <div class="DurationCount form_info_required">
        <span class="class_over_time">下课时间</span>
        <div style="display: flex; align-items: center">
          <time-picker :timeRange="timeRangeForClassTime"
                       :placeholder="isTeachingMode?'必选':'下课时间'"
                       :readonly="true"
                       :isTeachingMode="isTeachingMode"
                       :dateNumber="endTime"
                       @upDateNumber="setEndTime"></time-picker>
        </div>
      </div>
      <div class="DurationCount"
           v-if="!isTeachingMode">
        <span>时长</span>
        <div style="display: flex; flex: 1; justify-content: flex-end">
          {{ dataList.quittingTime }} 分钟
        </div>
      </div>
      <!-- <select-class-time-rang @onSelectTimeRange="onSelectTimeRange" @onDelTimeRange="onDelTimeRange"></select-class-time-rang> -->

      <save-cancel-btn-group class="goToClassBtn"
                             :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
    <!-- 选择班级 -->
    <custom-dialog title="选择班级"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="vNotTeachingMode"
                   :before-close="closeCourseBatchDelayDialog">
      <btn-add-select :addTitleName="'新增'"
                      :addmoduleName="'cws-add-class'"
                      :addmoduleTitleName="'add'"
                      :addmoduleKey="0"
                      @doAddSuccessEvent="doAfterSelectedClassGrade"></btn-add-select>
      <div class="single_result_detail form_info_detail">
        <classe-grades @getclassAndGradeL="getclassAndGradeL"
                       @submit="addClassForTeacherSubmit"
                       @closeDialog="closeCourseBatchDelayDialog"></classe-grades>
      </div>
    </custom-dialog>
    <teaching-mode-custom-dialog title="选择班级"
                                 class="student_info_dialog defult_dialog"
                                 :visible.sync="vTeachingMode"
                                 :before-close="closeCourseBatchDelayDialog">
      <classe-grades @getclassAndGradeL="getclassAndGradeL"
                     @submit="addClassForTeacherSubmit"
                     @closeDialog="closeCourseBatchDelayDialog"
                     :isTeachingMode="isTeachingMode"></classe-grades>
    </teaching-mode-custom-dialog>

    <!-- 弹出扫码 -->
    <custom-dialog title="安全验证"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="vNotTeachingModeforQrcode"
                   :before-close="closeAuthCodeDialog">
      <courseAuthQrcode @closeDialog="closeAuthCodeDialog"
                        @afterSuccess="openAuthAfterSuccess"
                        :dataInfo="authQrcodeDataInfo"></courseAuthQrcode>

    </custom-dialog>
    <teaching-mode-custom-dialog title="安全验证"
                                 class="student_info_dialog defult_dialog"
                                 :visible.sync="vTeachingModeforQrcode"
                                 :before-close="closeAuthCodeDialog">
      <courseAuthQrcode @closeDialog="closeAuthCodeDialog"
                        @afterSuccess="openAuthAfterSuccess"
                        :dataInfo="authQrcodeDataInfo"></courseAuthQrcode>
    </teaching-mode-custom-dialog>
  </div>
</template>

<script>
import {
  CWSTeacherGiveLectureSetUpScheduleCourse,
  AddClassGradeForTeacher,
  AddClassTimeRange,
  GetScheduleDurationCount
} from '../../../../API/workbench';
import classeGrades from '../report/classe-grades';
import timePicker from '../../../common/time-picker.vue';
import { md5 } from '../../../../API/md5';
import applyAuth from '../report/auth-components/apply-auth';
import courseAuthQrcode from '../../../course-table/attendance-arrange/teaching-components/course-auth-qrcode';
export default {
  components: {
    classeGrades,
    timePicker,
    applyAuth,
    courseAuthQrcode
  },
  props: {
    dataInfo: Object,
    isTeachingMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      timeRangeForClassTime: '',
      role: this.vRoleKey(),
      // startTime: "",
      endTime: '',
      isClasseGradesDialog: false,
      isAuthQrcodeDialog: false,
      tipContont: '',
      // gradeClassList: [],
      dataList: {
        classAndGradeL: '', // 班级
        courseware: '', // 课件
        DurationCount: 0, // 上课时间
        quittingTime: 0, // 时长
        teacher: '' // 当前老师
      },
      IsContinueKey: 0, // 是否继续   0取消  1确定
      IsAuthKey: 0, // 是否自动授权 0-否1-是
      showAuth: false, // 显示授权弹出层
      authDataInfo: null,
      Data: {
        GradeKey: '', // 班级key
        GradeKeyValue: '', // 班级keyValue
        CoursewareKey: '', // 课包key
        DurationCount: '', // 上课时间(分钟)
        DeviceCode: '', // this.$zxClient.GetMCode() // 设备码
        DeviceTypeKey: 2, // 设备类型 1-浏览器;2-桌面端;3-APP;4-电视
        DeviceTypeKeyValue: '桌面端', // 设备类型
        Longitude: '', // 经度
        Latitude: '', // 维度
        Sign: 0, // 授权签名
        IsSettle:1
      },
      authCallBack: null,
      authQrcodeDataInfo: {},
      doubleClick:0
    };
  },

  created () {
    this.timeRangeForClassTime =
      this.$utils.getSaaSClientSetInfo(64).SetContent +
      ':00-' +
      this.$utils.getSaaSClientSetInfo(65).SetContent +
      ':00';
    if (this.$zxClient && this.$zxClient.IsClient) {
      this.Data.DeviceCode = this.$zxClient.GetMCode();
    } else {
      // this.tipContont = '非桌面端';
      // this.Data.DeviceCode = '340BC40DC769411B'
    }
    // 班级详情进来。
    if (this.dataInfo.GradeKey > 0) {
      this.Data.GradeKey = this.dataInfo.GradeKey;
      this.Data.GradeKeyValue = this.dataInfo.GradeKeyValue;
      this.dataList.classAndGradeL = this.dataInfo.GradeKeyValue;
    }
    // this.getAttendTime()
    this.IsContinueKey = 0;
    this.dataList.courseware = this.dataInfo.item.MainDemoName;
    this.dataList.teacher = this.$store.getters.token.UserName;
    console.log('this.dataInfo', this.dataInfo);
    this.getclassAndGradeL();
  },
  computed: {
    vTeachingMode () {
      return this.isClasseGradesDialog && this.isTeachingMode;
    },
    vNotTeachingMode () {
      return this.isClasseGradesDialog && !this.isTeachingMode;
    },
    vTeachingModeforQrcode () {
      return this.isAuthQrcodeDialog && this.isTeachingMode;
    },
    vNotTeachingModeforQrcode () {
      return this.isAuthQrcodeDialog && !this.isTeachingMode;
    },
    vTeachingModeTag () {
      return this.isTeachingMode ? 'teachingMode' : '';
    }
  },

  mounted () {
    // this.$dialog.register(this, ["courseAuthQrcode"]);
  },

  methods: {
    getclassAndGradeL () {
      console.log('传入dataInfo', this.dataInfo);
    },
    // 判断是校长还是老师
    vRoleKey () {
      return  this.$store.getters.token ?Number(this.$store.getters.token.RoleKey) == 2 : false; // 2为校长；非则为老师
    },
    doAfterSelectedClassGrade (item) {
      console.log(item, '新增班级');
      // this.isClasseGradesDialog = false;
      this.addClassForTeacherSubmit(item);
      // this.loadClassListForTeacher()
    },
    // 打开班级弹窗
    addClassForTeacher () {
      this.isClasseGradesDialog = true;
    },
    // 关闭班级弹窗
    closeCourseBatchDelayDialog () {
      this.isClasseGradesDialog = false;
    },

    // 打开班级弹窗
    openAuthCodeDialog (callBack) {
      this.authCallBack = callBack;
      this.isAuthQrcodeDialog = true;
      this.authQrcodeDataInfo = {};
      this.authQrcodeDataInfo = {
        ScheduleCourseKey: 0,
        client_id: this.$store.getters.clientId,
        SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
        DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
        introType: 11,
        CourseWarePackageKey: this.dataInfo.item.ParentKey,
        DeviceCode: this.$zxClient.GetMCode() || ''  // 桌面端设备码
      };
    },
    // 关闭班级弹窗
    closeAuthCodeDialog () {
      this.isAuthQrcodeDialog = false;
      console.log('点击了', this.vTeachingModeforQrcode, this.isAuthQrcodeDialog, this.isTeachingMode);
      setTimeout(() => {
        this.$emit('closeDialog');
      }, 100);
    },
    // 添加班级提交
    addClassForTeacherSubmit (item) {
      console.log('选中班级', item);
      this.Data.GradeKey = item.OLAPKey;
      this.Data.GradeKeyValue = item.MainDemoName;
      this.dataList.classAndGradeL = item.MainDemoName;
      this.closeCourseBatchDelayDialog();
    },
    // // 获取上课时长
    // getAttendTime(){
    //   GetScheduleDurationCount().then(res => {
    //     console.log(res,'上课时长');
    //     this.dataList.quittingTime = res.data.DurationCount
    //     // let o = Number(this.getCurrentTime()) + Number(res.data.DurationCount)
    //     let now = new Date()
    //     let time = new Date(now.getTime() + 1000 * 60 * Number(res.data.DurationCount)).toString().split(' ')[4]
    //     this.endTime = time.split(':')[0] + ':' +time.split(':')[1]
    //     console.log(time.split(':')[0] + ':' +time.split(':')[1] ,'o')
    //   })
    // },
    // 获取当前时间
    getCurrentTime () {
      let today = new Date();
      let h = today.getHours();
      let m = today.getMinutes();
      let o = Number(h) * 60 + Number(m);
      return o;
    },
    getDuration (time) {
      if (!time) {
        return;
      }
      console.log(time, 'time');
      let startTime = this.getCurrentTime();
      let selectTime =
        Number(time.split(':')[0]) * 60 + Number(time.split(':')[1]);
      // console.log('22222')
      let setTime = Number(selectTime) - Number(startTime);
      if (setTime < 0) {
        console.log('11111');
        // layer.alert('上课时间不能小于当前时间')
        this.dataList.quittingTime = 0;
        return false;
      } else {
        this.dataList.quittingTime = setTime;
        console.log(this.dataList.quittingTime, 'quittingTime');
        return true;
      }
    },
    setEndTime (date) {
      console.log('date', date);
      console.log('getCurrentTime', this.getCurrentTime());
      console.log('getDuration', this.getDuration(date));
      this.getDuration(date);
      if (this.dataList.quittingTime <= 0) {
        layer.alert('上课时间不能小于当前时间', { mode: this.vTeachingModeTag });
      } else {
        this.endTime = date;
      }
    },
    verifyData () {
      let isFail = false;
      if (this.dataList.classAndGradeL == '') {
        isFail = true;
        layer.alert('请选择班级', { mode: this.vTeachingModeTag });
        console.log('请选择班级');








      } else if (this.endTime == '') {
        isFail = true;
        layer.alert('请输入下课时间', { mode: this.vTeachingModeTag });
      }
      return isFail;
    },
    confirmClick (callBack) {
      if (this.verifyData()) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (this.Data.GradeKey) {
        this.Data.DurationCount = this.dataList.quittingTime;
        this.Data.CoursewareKey = this.dataInfo.item.OLAPKey;
        this.Data.Sign = md5(
          this.$utils.setStringmd5('2', this.$zxClient.GetMCode(), 'KJ')
        );
        if (this.dataInfo.PCIsLocationLockKey == 1 && !this.dataInfo.Longitude) {
          this.openAuthCodeDialog(callBack, this.dataInfo.item.ParentKey);
        } else {
          console.log('提交开课', this.Data);
          this.openClass(callBack, this.dataInfo);
        }
      }
    },
    openClass (callBack, data) {
      const that = this
      if (data) {
        this.Data.Longitude = data.Longitude || '';
        this.Data.Latitude = data.Latitude || '';
      }
      CWSTeacherGiveLectureSetUpScheduleCourse(
        this.Data,
        this.IsContinueKey,
        this.IsAuthKey
      )
        .then(result => {
          console.log(result, '上课返回数据');
          that.isClasseGradesDialog = false;
          this.isAuthQrcodeDialog = false
          setTimeout(()=>{
            that.$emit('afterSuccess');
             that.$emit('closeDialog');
          },100)
          if (callBack) {
            callBack();
          }
          this.doubleClick ++

          if(this.doubleClick>1){
            layer.alert('请勿重复点击', { mode: this.vTeachingModeTag });
          }
          if (result.data.ScheduleCourseKey && this.doubleClick==1) {
            this.doubleClick++
            setTimeout(()=>{
              this.$router.push({
              name: 'coursewareTeachingView',
              query: {
                type: 1,
                scheduleCourseID: result.data.ScheduleCourseKey,
                isTeachingMode: this.isTeachingMode,
                IsPCRecordKey:this.dataInfo.IsPCRecordKey||0
              }
            });

            this.doubleClick=0
            },500)
            
          } else {
            layer.alert(error.msg, { mode: this.vTeachingModeTag });
          }
        })
        .catch(error => {
          console.log(error, '失败事件');
          if (callBack) {
            callBack();
          }
          if (error.code == 80001) {
            this.isAuthCourseware();
          } else if (error.code == 80002) {
            layer.confirm(error.msg, {
              mode: this.vTeachingModeTag,
              btn: [
                {
                  name: '取消',
                  callBack: ()=> {
                    this.IsContinueKey = 0;
                    this.isAuthQrcodeDialog = false
                    setTimeout(()=>{
                      this.$emit('afterSuccess');
                     this.$emit('closeDialog');
                    },200)
                    
                    layer.close();
                  }
                },
                {
                  name: '确认',
                  callBack: () => {
                    this.IsContinueKey = 1;
                    CWSTeacherGiveLectureSetUpScheduleCourse(
                      this.Data,
                      this.IsContinueKey,
                      this.IsAuthKey
                    )
                      .then(res => {
                        if (res.data.ScheduleCourseKey) {
                          console.log('dddddddsssss')
                          this.isAuthQrcodeDialog = false
                          setTimeout(()=>{
                            this.$emit('afterSuccess');
                            this.$emit('closeDialog');
                          },100)
                         
                          setTimeout(()=>{
                            this.$router.push({
                            name: 'coursewareTeachingView',
                            query: {
                              type: 1,
                              scheduleCourseID: res.data.ScheduleCourseKey,
                              isTeachingMode: this.isTeachingMode,
                              IsPCRecordKey:this.dataInfo.IsPCRecordKey||0
                            }
                          });
                          },500)
                        } else {
                          layer.alert(error.msg, { mode: this.vTeachingModeTag });
                        }
                      })
                      .catch(error => {
                        if (error.code == 80001) {
                          this.isAuthCourseware();
                        } else {
                          layer.alert(error.msg, { mode: this.vTeachingModeTag });
                        }
                      });
                      console.log('ddddddd')
                     
                    layer.close();
                  }
                }
              ]
            });
          } else {
            layer.alert(error.msg, { mode: this.vTeachingModeTag });
          }

          console.log(
            'errorCWSTeacherGiveLectureSetUpScheduleCourse',
            error.msg
          );
        });
    },
    cancelClick () {
      this.isClasseGradesDialog = false;
      this.$emit('closeDialog');
    },

    isAuthCourseware () {
      const that =this
      if (this.role) {
        layer.confirm(
          '您还没有该课包的授权，如果继续，将会自动将该课包授权给您，是否继续?',
          {
            mode: this.vTeachingModeTag,
            btn: [
              {
                name: '取消',
                callBack: function () {
                  this.IsAuthKey = 0;
                  layer.close();
                  if (callBack) {
                    callBack();
                  }
                }
              },
              {
                name: '确认',
                callBack: () => {
                  this.IsAuthKey = 1; // 是否自动授权
                  CWSTeacherGiveLectureSetUpScheduleCourse(
                    this.Data,
                    this.IsContinueKey,
                    this.IsAuthKey
                  ).then(result => {
                    if (result.data.ScheduleCourseKey) {
                      
                      that.$emit('afterSuccess');
                      that.$emit('closeDialog');
                      that.isClasseGradesDialog = false;

                      setTimeout(()=>{
                        this.$router.push({
                        name: 'coursewareTeachingView',
                        query: {
                          type: 1,
                          scheduleCourseID: result.data.ScheduleCourseKey,
                          isTeachingMode: this.isTeachingMode,
                          IsPCRecordKey:this.dataInfo.IsPCRecordKey||0
                        }
                      });
                      },500)
                    } else {
                      layer.alert(error.msg, { mode: this.vTeachingModeTag });
                    }
                  });
                  layer.close();
                }
              }
            ]
          }
        );
      } else {
        layer.alert('该设备未授权，不可上课！', { mode: this.vTeachingModeTag });
      }
    },
    closeAuthDialog () {
      this.showAuth = false;
    },
    openAuthDialog (callBack) {
      console.log(this.Data, 'openAuthDialog111');
      console.log('this.dataInfo.courserId', this.dataInfo);
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '安全验证',
        moduleName: 'courseAuthQrcode',
        data: {
          dataInfo: {
            ScheduleCourseKey: 0,
            client_id: this.$store.getters.clientId,
            SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
            DeviceTypeKey: '2', //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
            introType: 11,
            CourseWarePackageKey: this.dataInfo.item.ParentKey,
            DeviceCode: this.$zxClient.GetMCode() || ''  // 桌面端设备码
          }
        },
        callBack: {
          afterSuccess: (data) => {
            // this.$emit('afterSuccess');
            // this.$emit('closeDialog'); 
            // this.isClasseGradesDialog = false;
            setTimeout(()=>{
              this.openClass(callBack, data);
            },100)
          },
          close: () => {
            this.$emit('closeDialog');
          }
        }
      });
    },
    openAuthAfterSuccess (data) {
      this.openClass(this.authCallBack, data);
      // this.$emit('afterSuccess');
      // this.$emit('closeDialog');
      // this.isClasseGradesDialog = false;
    },
    closeAuthClose () {
      this.$emit('closeDialog');
    }
  }
};
</script>

<style scoped>
.goToClassBtn >>> .form_footer_btn_group {
  padding: 16px 0;
}

.DurationCount {
  height: 47px;
  padding: 8px 0;
  border-bottom: 1px solid #ececec;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calendar_tiem_range.input_time_range {
  padding: 20px 0;
}
.DurationCount >>> .time_picker {
  width: 140px;
}
</style>
