<template>
  <!-- 热门推荐 -->
  <div class="WKT_index_from">
    <div class="WKT_index_from_title">
      热门推荐
    </div>
    <div class="WKT_index_from_content">
      <div class="WKT_information">
        <enable-item v-for="(item,key) in hotRecommendationList"
                     :key="key"
                     :item="item"
                     :dataList="hotRecommendationList"
                     @edit="doAfterEditClick"></enable-item>
        <div class="WKT_information_list">
          <div class="WKT_information_block">
            <add-box :titleName="'推荐文章'"
                     @onAddBoxClick="doAfterAddClick"></add-box>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹出层 -->
    <custom-dialog :title="'选择文章'"
                   width="436px"
                   :visible.sync="isSeleteAddListShow"
                   :before-close="doSeleteAddListClose">
      <add-list :enableList="hotRecommendationList"
                @doAfterAddRecommendSucceed="doAfterAddRecommendSucceed"></add-list>
    </custom-dialog>

    <custom-dialog :title="'修改文章'"
                   width="436px"
                   class="WKT_information_uEditor"
                   :visible.sync="isEditArticleShow"
                   :before-close="doEditArticleClose">

      <div v-if="editArticleInfo.type==4">
        <excellent-teacher :item="editArticleInfo"
                           @addAfter="doAfterEditSucceed"
                           @close="doEditArticleClose"></excellent-teacher>
      </div>

      <div v-if="editArticleInfo.type==5">
        <star-students :item="editArticleInfo"
                       @addAfter="doAfterEditSucceed"
                       @close="doEditArticleClose"></star-students>
      </div>

    </custom-dialog>
  </div>
</template>

<script>
import addBox from '../common/add-box';
import enableItem from './enable-item';
import addList from './add-recommend-article/add-list';
import excellentTeacher from '../excellent-teacher/combo-list/add';
import starStudents from '../star-students/combo-list/add';
import { SearchPopularRecommend } from '../../../API/workbench';
export default {
  name: 'singleSelectionItem',
  data () {
    return {
      // 查询套餐
      parameter: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
        sequence: '', //	字符串	可选		数据源：GET	 排序方向
        searchText: ''//	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
      },
      // enableList: [],
      editArticleInfo: {}, // 编辑文章信息
      isSeleteAddListShow: false,
      isEditArticleShow: false
    };
  },
  components: {
    addBox,
    enableItem,
    addList,
    excellentTeacher,
    starStudents
    // editRecommendArticle
  },
  props: {
    hotRecommendationList: Array // 查询热门推荐
  },
  created () {
  },
  watch: {

  },
  computed: {

  },
  methods: {
    //  查询热门推荐
    // getSearchPopularRecommend () {
    //   SearchPopularRecommend(this.parameter).then(result => {
    //     console.log(result.data.PageDataList, '查询热门推荐');
    //     this.hotRecommendationList = result.data.PageDataList;
    //   }, error => {
    //     layer.alert(error.msg);
    //   });
    // },
    // 显示新增文章列表
    doAfterAddClick () {
      this.isSeleteAddListShow = true;
    },
    // 新增文章成功后
    doAfterAddRecommendSucceed (item) {
      this.$emit('updateHotRecommendation');
      // this.getSearchPopularRecommend();
      this.isSeleteAddListShow = false;
    },
    // 显示修改文章表单
    doAfterEditClick (editItem) {
      if (editItem.type == 4) {
        editItem.TeacherName = editItem.title;
      } else if (editItem.type == 5) {
        editItem.StarStudentsName = editItem.title;
      }
      this.editArticleInfo = editItem;
      this.isEditArticleShow = true;
    },
    // 修改文章成功后
    doAfterEditSucceed (editItem) {
      this.$emit('updateHotRecommendation');
      // this.getSearchPopularRecommend();
      this.isEditArticleShow = false;
    },
    // 关闭选择添加文章
    doSeleteAddListClose () {
      this.isSeleteAddListShow = false;
    },
    // 关闭修改文章表单
    doEditArticleClose () {
      this.isEditArticleShow = false;
    }
  }
};
</script>

<style>
</style>