<template>
  <!-- 录音弹窗 -->
  <div>
    <div class=""></div>
    <!-- 播放 -->
    <div class="JXT_record_popup">
      <div class="JXT_record_popup_content">
        <div>
          <!-- 录音上面的播放进度 -->
          <div class="JXT_record_popup_progress">
            <div class="progress_banner" @mousedown="handleMouseDownStart">
              <div class="progress_color"
                   :style="{width:vPlayingPropress}"></div>
            </div>
            <div class="progress_number_box">
              <div>
                {{vPlayRecorderDuration}}
              </div>
              <div>
                {{vRecorderDuration}}
              </div>
            </div>
          </div>
        </div>
        <div class="JXT_record_popup_menu">
          <!-- 左边按钮 -->
          <!--remake：重录 -->
          <!-- <div class="menu_left remake">
            重录
            <div class="uploading_input uploading_voice"
                 @click.stop="redoRecord"></div>
          </div> -->
          <!-- 中间按钮 -->
          <div class="menu_center"
               id="startRecord"
               @click.stop="clickAudioPlayBtn">
            <!-- play：播放、pause：正在录入、rec：暂停 -->
            <div class="menu_center_type"
                 :class="{play:!audio.isPlaying,pause:audio.isPlaying}"></div>
            <div class="reset_audio_box">
              <audio controls
                     preload="auto"
                     name="media"
                     class="audio audio-file"
                     @timeupdate="handleAudioTimeUpdated" 
                     @loadedmetadata="onLoadedmetadata"
                     id="audio-file">
                <source :src="vAudioUrl"
                        type="audio/wav">
              </audio>
            </div>
          </div>
          <!-- 右侧按钮 save -->
          <div class="menu_right_box">
          </div>
        </div>
        <div class="JXT_record_popup_tips">{{vBottomTextCompleted}}</div>
      </div>
      <!-- 按钮 取消 确定 -->
      <div>
        <div class="JXT_record_popup_btn"
             @click.stop="confirmClick">
          确定
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isStopPlay: false, // 播放暂停
      audioObj: null, // 音频播放对象
      audio: {
        isPlaying: false, // 该字段是音频是否处于播放状态的属性
        currentTime: 0, // 音频当前播放时长
        recorderDuration: 0, // 音频最大播放时长
        minTime: 0,
        step: 0.1
      }
    };
  },
  props: {
    audioData: Object
  },
  computed: {
    vAudioUrl () {
      let audioUrl = '';
      if (this.audioData) {
        audioUrl =this.$store.getters.CDNURL+ this.audioData.AttachUrl;
      }
      return audioUrl;
    },
    vBottomTextCompleted () {
      let str = '点击播放';
      if (this.audio.isPlaying) {
        str = '点击暂停';
      }
      return str;
    },
    vRecorderDuration () { // 00:10 音频总时长
      let min = Math.floor(this.audio.recorderDuration / 60);
      min = min < 10 ? '0' + min : min;
      let sec = Math.floor(this.audio.recorderDuration % 60);
      sec = sec < 10 ? '0' + sec : sec;
      let duration = min + ':' + sec;
      return duration;
    },
    vPlayRecorderDuration () { // 音频播放进度时长
      let min = Math.floor(this.audio.currentTime / 60);
      min = min < 10 ? '0' + min : min;
      let sec = Math.floor(this.audio.currentTime % 60);
      sec = sec < 10 ? '0' + sec : sec;
      let duration = min + ':' + sec;
      return duration;
    },
    vPlayingPropress () {
      let percen = 0;
      if (this.audio.isPlaying || this.isStopPlay) {
        percen = (this.audio.currentTime / this.audio.recorderDuration) * 100 + '%';
      }
      return percen;
    }
  },
  mounted () {
    this.audioObj = this.$el.querySelector('.audio-file');
  },
  methods: {
    clickAudioPlayBtn () { // 点击播放
      if (!this.audio.isPlaying && !this.isStopPlay) { // 未播放
        this.audio.isPlaying = true;
        this.playRecord();
      } else if (this.audio.isPlaying && !this.isStopPlay) { // 暂停播放
        this.audio.isPlaying = false;
        this.isStopPlay = true;
        this.pausePlay();
      } else { // 继续播放
        this.audio.isPlaying = true;
        this.isStopPlay = false;
        this.playRecord();
      }
    },
    // 录音播放
    playRecord () {
      this.audioObj.play();
    },
    // 暂停播放
    pausePlay () {
      this.audioObj.pause();
    },
    // 重录
    redoRecord () {
      this.destroyInnerAudioContext();
      this.$emit('onRedoRecord', this.audioData.AttachUrl);
    },
    // 关闭播放源
    destroyInnerAudioContext () {
      this.audioObj.pause();
      this.audioObj = null;
    },
    // 确定
    confirmClick () {
      this.$emit('onCloseAudio');
    },
    // 保存音频到本地
    saveAudioToLocal () {
      var form = document.createElement('form');
      form.action = this.vAudioUrl;
      document.getElementsByTagName('body')[0].appendChild(form);
      form.submit();
      this.$emit('onCancelVoice');
    },
    // 当指定的音频/视频的元数据已加载时，会发生 loadedmetadata 事件。
    onLoadedmetadata (res) {
      this.audio.recorderDuration = parseInt(res.target.duration);
      this.clickAudioPlayBtn();
    },
    handleAudioTimeUpdated (res) {
      this.audio.currentTime = res.target.currentTime;
      if (this.audio.currentTime >= this.audio.recorderDuration) {
        this.audio.isPlaying = false;
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
      }
    },
    // mousemove	触摸结束，手指离开屏幕时
    // mouseup	触摸被取消，当系统停止跟踪触摸的时候触发
    handleMouseDownStart (e) {
      this.setValue(e);
      document.addEventListener('mousemove', this.handleTouchMove);
      document.addEventListener('mouseup', this.handleTouchEnd);
    },
    handleTouchMove (e) {
      this.setValue(e);
    },
    handleTouchEnd (e) {
      this.setValue(e);
      document.removeEventListener('mousemove', this.handleTouchMove);
      document.removeEventListener('mouseup', this.handleTouchEnd);
    },
    // 从点击位置更新 value
    setValue (e) {
      // Element.getBoundingClientRect() 方法返回元素的大小及其相对于视口的位置。
      // clientX  点击位置距离当前body可视区域的x，y坐标
      const $el = this.$el;
      const { recorderDuration, minTime, step } = this.audio;
      let value = ((e.clientX - $el.getBoundingClientRect().left) / $el.offsetWidth) * (recorderDuration - minTime);
      value = Math.round(value / step) * step + minTime;
      value = parseFloat(value.toFixed(5));
      if (value > recorderDuration) {
        value = recorderDuration;
      } else if (value < minTime) {
        value = minTime;
      }
      this.audioObj.currentTime = value;
    }
  }
};
</script>

<style>
.uploading_voice {
  top: 90px !important;
  left: 60px !important;
  width: 50px !important;
  height: 50px !important;
}
</style>