<template>
  <!-- 通用列表 -->
  <div class=" table_list_content summarizing_list"
       style="border-radius:8px;">
    <div class="single_result_detail">
      <div class="summarizing_number_left"
           style="padding-left:0">
        <span class>全部学生</span>
        <span class="font_number">({{totalNum}})</span>
        <div class="summarizing_number_right_btn">
          <function-btn :item="addbtn"
                        @clickItem="btnClick('registercustomer')"
                        :className="'all_student_right_top_btn btn_hover_bg_blue'">
            <div class="">登记新生</div>
          </function-btn>
          <div @click="toRecycledStudent"
               class="recycled_btn el-button btn_light_blue btn_hover_bg_blue el-button--default">
            回收站
          </div>
        </div>
      </div>
      <div class="flex">
        <!-- 已选条件 -->
        <div class="content_search fr pr"
             style="margin-top: 4px;margin-bottom:15px">
          <div class="course_search_form"
               style="margin-right:200px;width: 305px;">
            <div class="course_search_select border_right search_top">
              <el-select v-model="queryParams.status"
                         size="small"
                         @change="chgSearchValue">
                <el-option v-for="item in options"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                  <span style="float: left">{{item.label}}</span>
                  <span style="position: absolute;text-align: right;color: #8492a6; font-size: 13px">{{item.sum}}</span>
                </el-option>
              </el-select>
            </div>
            <div class="course_search_input pr"
                 style="width: 80%;">
              <input type="text"
                     :placeholder="'请输入姓名、姓名首字母、手机号'"
                     v-model="searchText"
                     @keyup.enter="searchStudent" />
              <span class="remove_btn"
                    v-show="searchText != ''"
                    @click.stop="clearText()"></span>
              <!-- @keyup="searchText=searchText.replace(/^\s+|\s+$/g,'')" -->
            </div>
          </div>
          <div class="table_select_box pa"
               style="left: 320px;top: 0px;">
            <div class="table_select_title">生日</div>
            <el-select v-model="birthdayTypeText"
                       placeholder="请选择"
                       value-key="id"
                       @change="selectBirthdayFilter">
              <el-option v-for="item in birthday"
                         :key="item.id"
                         :label="item.value"
                         :value="item">
              </el-option>
            </el-select>
          </div>

          <el-button class="btn_light_blue btn_hover_bg_blue"
                     style="margin-right:10px"
                     @click.stop="searchStudent">搜索</el-button>
          <el-button class="btn_light_white btn_hover_white"
                     @click.stop="clearExtendSearchParams">清除条件</el-button>
          <function-btn :item="{name:'全部学生导出',ModulePowerKey:120}"
                        :className="'btn_hover_bg_blue'"
                        style="display:inline-block;line-height:34px;border-radius:4px;padding:0 10px;margin-left:10px;"
                        @clickItem="doExportInfoClick">
          </function-btn>
        </div>
      </div>
      <table-list ref="tableListRef"
                  :tableData="tableData"
                  :tableColumns="tableColumns"
                  :rowKey="rowKey"
                  :totalNum="pageTotal"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  @loadTableData="loadTableData"></table-list>
    </div>
  </div>
</template>
<script>
import tableList from '../../common/table-list/index';
import summaryDataItem from '../summary-data/item';
import tablePaginationNumber from '../../common/table-pagination-number';
export default {
  data () {
    return {
      searchText: '',
      queryParams: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: 'RegisterTime', // 排序字段
        sequence: 'desc', // 排序字段
        status: 0,        //	0全部  学生状态 1-在校; 2-潜客;3-离校
        QueryID: '',
        BirthdayMonth: ''
      },
      selectedRow: {}, // 选中行
      expandRowKeys: [], // 展开的行,
      isShowInnerScroll: true,
      isLoading: true,
      isShowSummary: false,
      studentDetailList: [], // 学生的报课详情
      studentAllValidCourseBill: null, // 学生的所有有效课单
      curSelectedStudentInfo: {},
      countNum: 0,
      authbtn: {
        name: '',
        ModulePowerKey: 51
      },
      searchValue: 1, // 默认搜学生
      options: [
        { label: '全部', value: 0, sum: 0 },
        { label: '在校', value: 1, sum: 0 },
        { label: '潜客', value: 2, sum: 0 },
        { label: '离校', value: 3, sum: 0 }
      ],
      birthdayTypeText: '全部',
      birthday: [
        { value: '全部', id: '' },
        { value: '一月', id: '01' },
        { value: '二月', id: '02' },
        { value: '三月', id: '03' },
        { value: '四月', id: '04' },
        { value: '五月', id: '05' },
        { value: '六月', id: '06' },
        { value: '七月', id: '07' },
        { value: '八月', id: '08' },
        { value: '九月', id: '09' },
        { value: '十月', id: '10' },
        { value: '十一月', id: '11' },
        { value: '十二月', id: '12' }
      ],
      addbtn: {
        name: '',
        type: 'tjxs',
        ModulePowerKey: 48
      }

    };
  },
  props: {
    tableData: Array, // 列表数据
    detailColumns: Array, // 显示详情内容
    rowKey: String, // 显示详情对应绑定的ID
    totalNum: Number, // 全部学生数
    pageTotal: Number, // 分页总共条数
    tableColumns: Array, // table column
    studentInSchoolTotalNum: [String, Number],
    paramsForStudent: Object,
    isNeedInnerScroll: {
      type: Boolean,
      default: false
    },
    isShowSearch: Boolean,
    studentDetail: {
      type: Boolean,
      default: false
    },
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: '', order: 'ascending' };
      }
    }
  },
  components: {
    summaryDataItem,
    tableList,
    tablePaginationNumber
  },
  computed: {
    // 不足10行时空行补充 StudentStatusKey
    vtableData () {
      let items = {
        OLAPKey: '',
        studentInfo: []// 已有用到的数组需实例化，不然渲染时会报错
      };
      let arr = [];
      let oldTableDataLength = this.$utils.parseJson(this.tableData.length);
      if (this.tableData.length < this.queryParams.pageSize && this.tableData.length > 0) {
        arr = this.tableData;
        for (let index = 1; index <= (this.queryParams.pageSize - oldTableDataLength); index++) {
          arr.push(items);
        }
        return arr;
      } else {
        return this.tableData;
      }
    },
    modulePowerList () {
      return this.$store.getters.RolePowerList;
    },
    vIsSearchText () {
      if (this.isSearchText) {
        return true;
      } else {
        return false;
      }
    },
    vPageIndex: {
      get () {
        return this.queryParams.pageIndex + 1;
      },
      set (n, o) {
        console.log('vPageIndex', this.paramsForStudent);
        // this.queryParams = this.paramsForStudent;
      }
    },
    vTableColumns () {
      let newTableColumns = [];
      this.tableColumns.forEach(element => {
        if (element.isShow) {
          newTableColumns.push(element);
        }
      });
      return newTableColumns;
    },
    vHeadImg: {
      get () {
        // 头像
        let pcDomainURLOld = this.$store.getters.CDNURL;
        if (!this.curSelectedStudentInfo.HeadImgSupport) {
          this.curSelectedStudentInfo.HeadImgSupport = '';
          if (parseInt(this.curSelectedStudentInfo.SexKey) == 2) {
            return require('../../../../public/image/deflut_studentPhoto_boy.png');
          } else {
            return require('../../../../public/image/deflut_studentPhoto_gril.png');
          }
        }
        if (this.curSelectedStudentInfo.HeadImgSupport.indexOf('http') > -1) {
          return this.curSelectedStudentInfo.HeadImgSupport;
        } else if (this.curSelectedStudentInfo.HeadImgSupport && this.curSelectedStudentInfo.HeadImgSupport.indexOf('/') == -1) {
          return pcDomainURLOld + '/' + this.curSelectedStudentInfo.HeadImgSupport;
        } else {
          return pcDomainURLOld + this.curSelectedStudentInfo.HeadImgSupport;
        }
      },
      set () {

      }

    },
    vMobile () {
      return this.$utils.phoneModulePower(this.curSelectedStudentInfo.CustomerPhoneName) || '无手机号';
    },
    vAgeName () {
      if (this.curSelectedStudentInfo.AgeName > 0) {
        return this.curSelectedStudentInfo.AgeName + '岁';
      } else {
        return '无年龄'
          ;
      }
    }
  },
  watch: {
    tableColumns (n, o) {
      if (this.isNeedInnerScroll) {
        setTimeout(() => {
          this.caculInnerScroll();
        }, 800);
      }
    }

  },
  created () {
    this.searchConditionType = this.$initJson.extendSearchConditionType;

    console.log(this.studentDetailList, 'studentDetailList');
  },
  mounted () {
    if (this.isNeedInnerScroll) {
      setTimeout(() => {
        this.caculInnerScroll();
      }, 600);
    }
  },
  methods: {
    toRecycledStudent () {
      this.$router.push('recycledStudent');
    },
    searchStudent () {
      this.queryParams.pageIndex = 0;
      this.queryParams.searchText = this.searchText;
      this.$emit('searchStudent', this.queryParams.searchText);
    },
    clearText () {
      this.searchText = '';
      this.queryParams.searchText = '';
      this.$emit('searchStudent', this.queryParams.searchText);
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      ModulePowerKey = ModulePowerKey || '';
      return this.$utils.CheckModulePower(this.modulePowerList, ModulePowerKey);
    },
    applyCourseClick (item, val) {
      if (val && Number(val) == 0) {
        return false;
      }
      this.$emit('applyCourseClick', item);
    },
    arrangeClick (item, val) {
      if (val && Number(val) == 0) {
        return false;
      }
      this.$emit('arrangeClick', item);
    },
    // 清除
    clearExtendSearchParams () {
      this.searchText = '';
      this.expandRowKeys = [];
      this.queryParams.pageIndex = 0;
      this.queryParams.status = 0;
      this.queryParams.searchText = '';
      this.birthdayTypeText = '全部';
      this.queryParams.BirthdayMonth = '';
      this.$emit('clearSearchParams');
    },
    // 选择搜索类型
    chgSearchValue (value) {
      console.log(value, '选择搜索类型', this.queryParams, this.paramsForStudent);
      this.queryParams.status = value;
      this.queryParams.pageIndex = 0;
      this.searchText = '';
      this.queryParams.searchText = '';
      // if (this.searchValue == 1) { // 学生
      this.$emit('loadTableData', this.queryParams);
      // }
    },
    caculInnerScroll () {
      // table的宽度
      let tableWidth = document.getElementsByClassName('el-table table_list')[0] ? document.getElementsByClassName('el-table table_list')[0].clientWidth : 0;
      // table内容的宽度
      let contentWidth = document.getElementsByClassName('el-table__body')[0] ? document.getElementsByClassName('el-table__body')[0].clientWidth : 0;
      // table 左边固定宽
      let leftFix = document.getElementsByClassName('el-table__fixed')[0] ? document.getElementsByClassName('el-table__fixed')[0].clientWidth : 0;
      // table 右边固定宽
      let rightFix = document.getElementsByClassName('el-table__fixed-right')[0] ? document.getElementsByClassName('el-table__fixed-right')[0].clientWidth : 0;
      // 合计的高度
      let countColumnHeight = document.getElementsByClassName('el-table__fixed-footer-wrapper')[0] ? document.getElementsByClassName('el-table__fixed-footer-wrapper')[0].offsetHeight : 0;

      if (tableWidth >= contentWidth) {
        this.isShowInnerScroll = false;
      } else {
        this.isShowInnerScroll = true;
        if (document.getElementsByClassName('el-table__body-wrapper')[0]) {
          document.getElementsByClassName('el-table__body-wrapper')[0].style.overflowX = 'hidden';
        }
        this.$nextTick(() => {
          document.getElementsByClassName('scroll_content')[0].style.width = contentWidth + 'px';

          document.getElementsByClassName('float-scroll')[0].style.width = (tableWidth - leftFix - rightFix) + 'px';
          document.getElementsByClassName('float-scroll')[0].style.marginLeft = leftFix + 'px';
          document.getElementsByClassName('float-scroll')[0].style.marginTop = -countColumnHeight + 'px';
        });
      }
    },
    // 导出
    doExportInfoClick () {
      this.$emit('doExportInfoClick', this.queryParams);
    },
    // 新增学生
    btnClick (eventName) {
      this.$emit('btnFnClick', eventName);
    },
    loadTableData (queryParams) {
      this.$emit('loadTableData', queryParams);
    },
    selectBirthdayFilter (item) {
      this.queryParams.pageIndex = 0;
      this.queryParams.BirthdayMonth = item.id;
      this.birthdayTypeText = item.value;
      this.queryParams.searchText = this.searchText;
      this.$emit('loadTableData', this.queryParams);
    }
  }
};
</script>
<style>
/* .search_top {
   
} */
.search_top .el-select .el-input .el-select__caret {
  color: #333;
  font-size: 8px;
  position: relative;
  top: -1px;
  left: 13px;
  /* border-left: 1px solid #e6e6e6; */
}
</style>

