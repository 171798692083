<template>
  <!-- 左侧优惠券详情显示 -->
  <div class="compile_view_box coupon_box">
    <div class="compile_view coupon_view">
      <div class="WKT_discount_coupon_mobile">
        <div class="mobile_title">
          优惠券详情
        </div>
        <div class="mobile_content">
          <div class="WKT_discount_coupon_popup_ul">
            <coupon-item :Item="dataInfo"
                         :Index="0"
                         :isView="true"
                         :isShowbtn="false"
                         :dataList=[]></coupon-item>
          </div>
          <div class="mobile_text_title">
            优惠券须知:
          </div>
          <div class="mobile_text">
            1、优惠券有有效期限制，过了有效期不能使用；
          </div>
          <div class="mobile_text">
            2、优惠券只能抵扣订单金额，优惠金额超出订单金额部分不能 再次使用，不能兑换现金；
          </div>
          <div class="mobile_text">
            3、每个订单只能使用一张优惠券；
          </div>
          <div class="mobile_text">
            4、使用优惠券支付的订单，如果退货，优惠券抵扣金额不能退 还，只能退还实际支付商品金额；
          </div>
          <div class="mobile_text">
            5、如果部分退货，将按照订单实际成交商品的金额进行结算。
          </div>
        </div>
        <div class="WKT_footer_text">
          微网校由
          <span class="font_black">治学系统</span> 提供技术支持
        </div>
      </div>

    </div>

    <!-- <div class="WKT_index_phone_btn">
      <div class="btn_top"></div>
      <div class="btn_bottom"></div>
    </div> -->
  </div>
</template>
<script>
import couponItem from '../coupon-list/coupon-item';
export default {
  components: {
    couponItem
  },
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object // 券信息
  },
  created () {

  },
  watch: {

  },
  computed: {

  },
  methods: {

  }
};
</script>

