<template>
  <div class="img_box">
    <img :src="vImgUrl"
         style="border-radius: 4px;"
         :onerror="defaultImg" />
  </div>
</template>

<script>
export default {
  props: {
    imgUrl: String,
    isShrink: {
      type: Boolean, // 是否显示缩略图片地址
      default: true
    }
  },
  computed: {
    vImgUrl () {
      let url = this.imgUrl || '';

      if (this.isShrink) {
        return this.$utils.ossLitimgSet(url);
      } else {
        if (url && url.indexOf('http') == -1) {
          url = this.$store.getters.CDNURL + url;
        }
      }

      return url;
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../public/image/no_logo@2x.png') + '"';
    }
  }
};
</script>

<style>
</style>