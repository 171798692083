<template>
	<div class="" style="height: 640px;overflow: scroll;"> 
		<div style="height: 85%;" @click="isShowSendMessageBox=false">
		<!-- <basics-page-header :titleName="'课评详情'"></basics-page-header> -->
		<!-- 课信息块 -->
		<lesson-course-head-box v-if="courseCommentInfo.OLAPKey" :dataInfo="courseCommentInfo"></lesson-course-head-box>
		<div class="course_estimate_content">
			<!-- 课评作业块 -->
			<work-info-view v-if="courseCommentInfo.WorkReport&&dataInfo.showWorkInfo" :dataInfo="courseCommentInfo.WorkReport" @toWorkDetails="toWorkDetails"></work-info-view>
			<!-- 课评内容块 -->
			<dynamic-info-view v-for="(item,index) in courseCommentInfo.CommentList" :key="index" :dataInfo="item" :dataIndex="index+1"
			 :dataLength="courseCommentInfo.CommentList.length" @doMineReplyClick="doMineReplyClick"></dynamic-info-view>
		</div>
			</div>
		<!-- 回复框 -->
		<send-message-box v-if="isShowSendMessageBox" :dataInfo="replyInfo" :EssayID="replyInfo.nID" @sendSucceed="sendSucceed"
		></send-message-box>
		<!-- 删除回复 -->
		<basics-bottom-popup v-if="isShowDeletePopup" :isShowCancelBtn="true" @cancelClick="isShowDeletePopup=false">
			<template #title>
				
			</template>
			<template #content>
				<div class="comment_list" style="margin-left: 40rpx;margin-top: 20rpx;">
					<span class="font_blue">{{vReplyItemFromName}}：</span>
					<span v-html="$utils.translationSchoolText(replyInfo.Content)"></span>
				</div>
				<basics-popup-list :dataList="operateTypes" @seletedItem="chooseDelete"></basics-popup-list>
			</template>
		</basics-bottom-popup>
	</div>
</template>

<script>
import {GetScheduleCourseCommentDetails, DeleteReply} from '../../../../API/workbench';
import lessonCourseHeadBox from './common/lesson-course-head-box.vue';
import dynamicInfoView from './common/dynamic-info-view.vue';
import workInfoView from '../../lesson-dynamic-list-page/common/work-info-view.vue';
import sendMessageBox from '../../components/comment-box/send-message-box.vue';
export default {
  components: {
    lessonCourseHeadBox,
    workInfoView,
    dynamicInfoView,
    sendMessageBox
  },
  data () {
    return {
      courseCommentInfo: {},
      courseId: '',
      replyInfo: {}, // 回复信息
      isShowSendMessageBox: false, // 显示回复框
      isShowDeletePopup: false, // 显示删除框
      operateTypes: [{
        value: '删除',
        key: 'delete',
        className: 'list_icon14'
      }]
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          courseId: 0,
          showWorkInfo: false
        };
      }
    }
  },
  created () {
    this.courseId = this.dataInfo.courseId;
    if (this.courseId) {
      this.getScheduleCourseCommentDetails(this.courseId);
    }
  },
  computed: {
    vReplyItemFromName () {
      // -普通模式
      if (this.$store.getters.browseMessageRole == 2 && Number(this.replyInfo.FromID) == Number(this.$store.getters.signData.UserID)) {
        return '我';
      } else {
        return this.replyInfo.From;
      }
    }
  },
  methods: {
    // 获取课点评详情
    getScheduleCourseCommentDetails (courseId) {
      GetScheduleCourseCommentDetails(courseId).then(res => {
        console.log(res.data, 'res.data');
        this.courseCommentInfo = res.data;
      }, err => {
        this.$store.dispatch('popup/open', {
          content: err.msg
        });
      });
    },
    // 回复/评论
    doMineReplyClick (type, itme) {
      if (itme) {
        const nID = itme.EssayID ? itme.EssayID : itme.ID;
        this.replyInfo = itme;
        this.replyInfo.nID = nID;
        console.log(type, this.replyInfo, '回复/评论');
        if (type == 0) { // 自己
          // this.isShowDeletePopup = true;
          let that = this;
          layer.confirm('请问是否删除选中的评论', {
            shadeClose: false,
            btn: [{
              name: '取消',
              callBack: function () {
                layer.close();
              }
            }, {
              name: '确认',
              callBack: function () {
                that.chooseDelete();
                layer.close();
              }
            }]
          });
        } else { // 其他
          this.isShowSendMessageBox = !this.isShowSendMessageBox;
        }
      }
    },
    // 删除
    chooseDelete () {
      console.log(this.courseCommentInfo, '删除');
      DeleteReply(this.replyInfo.ID).then(result => {
        this.isShowDeletePopup = false;
        this.courseCommentInfo.CommentList.forEach(o => { // 删除
          o.Replylist.forEach((i, index) => {
            if (i.ID == this.replyInfo.ID) {
              o.Replylist.splice(index, 1);
            }
          });
        });
      });
    },
    // 回复成功回调
    sendSucceed (data) {
      console.log(data, '回复成功回调');
      this.isShowSendMessageBox = false;
      if (data && data.EssayID) {
        this.courseCommentInfo.CommentList.forEach(o => { // 添加
          if (o.ID == this.replyInfo.nID) {
            o.Replylist.push(data);
          }
        });
      }
    },
    // 作业详情
    toWorkDetails () {
      let moduleInfo = {
        name: '作业详情',
        moduleName: 'homeworkDetail',
        routerName: this.$route.name,
        data: { dataInfo: {
          courseId: this.courseCommentInfo.OLAPKey,
          workType: 1

        } }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
      // this.$zxrouter.navigateTo({
      //   url: '/pages/home-school-communication/details-page/work-details/index' + '?courseId=' + this.courseCommentInfo.OLAPKey
      // });
    }
  }
};
</script>

<style>

</style>
