<template>
  <!-- 通用列表 -->
  <div class="headquarters_info_summarizing">
    <div class="headquarters_info_search pr">
      <input-search-contain-btn v-model="params.searchText"
                                :width="'280px'"
                                :placeholder="'请输入'"
                                @onSearchEvent="search"
                                @clearSearch="clear"></input-search-contain-btn>

      <div class="summarizing_number_right_btn"
           style="top: 15px;right: 20px;">
        <function-btn :item="{ name: '授权课件', ModulePowerKey: '' }"
                      :className="'el-button btn_light_blue btn_hover_bg_blue'"
                      @clickItem="addAuthorizationCourseware('ZY')">
        </function-btn>
      </div>
    </div>
    <div class="table_list_content introduce_list">
      <table-list ref="tableListRef"
                  :tableData="tableData"
                  :position="''"
                  :tableColumns="tableColumns"
                  :expandRowData="expandRowData"
                  :rowKey="rowKey"
                  :totalNum="totalNum"
                  :defaultSort="defaultSort"
                  :queryParams="params"
                  :footerContent="'共' + totalNum + '条数据'"
                  @loadTableData="loadTableData"></table-list>
    </div>
  </div>
</template>
<script>
import inputSearchContainBtn from '../../../report/common/input-search-contain-btn';
import tableList from '../../../common/table-list/index';
export default {
  data () {
    return {
      searchText: '',
      queryParams: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: 'asc', //	字符串	可选		数据源：GET	排序顺序
        searchText: ''
      }
    };
  },
  props: {
    tableData: Array, // 列表数据
    expandRowData: Array, // 列表详情数据
    tableColumns: Array, // table column,
    rowKey: [String, Number], // 显示详情对应绑定的ID
    totalNum: Number, // 分页总共条数
    params: Object,
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: '', order: 'ascending' };
      }
    },
    tableColumnsType: {
      type: String,
      default: ''
    }

  },
  components: {
    inputSearchContainBtn,
    tableList
  },
  watch: {

  },
  created () {
    console.log(this.expandRowData, '展开行1');
  },
  mounted () {
  },
  methods: {
    // 添加
    addAuthorizationCourseware (type) {
      this.$emit('addAuthorizationCourseware', type);
    },
    // 搜索
    search () {
      console.log('搜索', this.params);
      this.initParams();
      this.$emit('loadTableData', this.params);
    },
    // 清除
    clear () {
      console.log('清除', this.params);
      this.params.searchText = '';
      this.initParams();
      this.$emit('loadTableData', this.params);
      this.$refs.tableListRef.expandRowKeys = [];
    },
    initParams () {
      this.params.pageIndex = 0;
      this.params.type = 0;
    },
    // 汇总排序
    doAfterCilckSummaryData (item) {
      console.log('点击汇总数据排序', item);
      if (item.type == 'CurrentPortalNum') {
        this.$parent.isShowPortApplyRecordListPopup = true;
      } else {
        this.params.searchText = '';
        this.params.pageIndex = 0;
        this.$emit('loadTableData', this.params);
      }
    },
    // 排序
    loadTableData (queryParams) {
      console.log('排序', queryParams);
      this.$emit('loadTableData', queryParams);
    }
  }
};
</script>
<style>
</style>

