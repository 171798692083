<template>
  <div class="aside_item button "
       :class="{opt:dataInfo.isSelect,audio_type:dataInfo.AttachTypeKey == 3}"
       @click.stop="clickItem">
    <div class="aside_item_pic">
      <img :src="vUrl" />
    </div>
    <div class="aside_item_info">
      <div class="aside_item_info_content">{{dataInfo.MainDemoName || '未命名'}}</div>
      <img :src="vIconUrl"
           class="aside_item_info_icon" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataInfo: Object
  },
  computed: {
    vUrl () {
      if (this.dataInfo.AttachTypeKey == '1') {
        if (this.dataInfo.AttachViewUrl && this.dataInfo.AttachViewUrl.indexOf('data:image/png;') != -1) {
          return this.dataInfo.AttachViewUrl;
        } else {
          if (this.dataInfo.CoverUrl && this.dataInfo.CoverUrl.length > 1) {
            return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
          } else {
            return this.$store.getters.PCWebURL + this.dataInfo.AttachViewUrl + '?sign=' + this.$store.getters.signData.Sign;
          }
        }
      } else if (this.dataInfo.AttachTypeKey == '2') {
        if (this.dataInfo.CoverUrl) {
          return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
        } else {
          return require('../../../../public/image/courseware_list_type01.png');
        }
      } else if (this.dataInfo.AttachTypeKey == '3') {
        return require('../../../../public/image/courseware_list_type02.png');
      } else if (this.dataInfo.AttachTypeKey == '4') {
        if (this.dataInfo.CoverUrl) {
          return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
        } else {
          return require('../../../../public/image/courseware_list_type03.png');
        }
      } else if (this.dataInfo.AttachTypeKey == '5') {
        return require('../../../../public/image/courseware_pdf_cover.png');
      }
    },
    vIconUrl () {
      if (this.dataInfo.AttachTypeKey == '1') {
        return require('../../../../public/image/pic.png');
      } else if (this.dataInfo.AttachTypeKey == '2') {
        return require('../../../../public/image/video.png');
      } else if (this.dataInfo.AttachTypeKey == '3') {
        return require('../../../../public/image/audio.png');
      } else if (this.dataInfo.AttachTypeKey == '4') {
        return require('../../../../public/image/ppt.png');
      } else if (this.dataInfo.AttachTypeKey == '5') {
        return require('../../../../public/image/courseware_list_type05.png');
      }
    }
  },
  methods: {
    clickItem () {
      this.$emit('clickItem', this.dataInfo);
    }
  }
};
</script>