<template>
  <!-- 条件搜索 -->
  <div class="flex">
    <div class="table_select_box"
         v-if="vTypeKey">
      <div class="table_select_title">选择校区</div>
      <el-select :value="selectedSchoolKeyValue"
                 filterable
                 placeholder="必选"
                 value-key="SchoolKey"
                 @change="doRecordSelectedStatus">
        <el-option v-for="item in campusList"
                   :key="item.SchoolKey"
                   :label="item.SchoolKeyValue"
                   :value="item"></el-option>
      </el-select>
    </div>
    <div class="course_statistics_title">
      <div class="table_select_box">
        <div class="table_select_title">选择时间</div>
        <div class="title_date_select">
          <div class="el-select">
            <single-year-picker @chgDate="changeSaveDate"
                                :defaultDate="queryParams.StartDate"
                                ref="monthPicker"
                                :type="'month'"></single-year-picker>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import singleYearPicker from '../../../base-module/date-picker-common/single-year-picker';
export default {
  data () {
    return {
      selectedSchoolKeyValue: '' // 校区名称
      // defaultDate: '2021-10'
    };
  },

  props: {
    queryParams: Object,
    campusList: {
      type: Array,
      default: []
    }
  },
  components: {
    singleYearPicker
  },
  computed: {
    vTypeKey () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) { // 总部key为6
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {

  },
  created () {
    if (this.vTypeKey) {
      let finditem = this.campusList.find(o => {
        return o.SchoolKey == this.queryParams.SchoolKey;
      });
      if (finditem) {
        this.selectedSchoolKeyValue = finditem.SchoolKeyValue;
      }
      console.log(finditem, this.campusList, '选中校区');
    };
  },
  methods: {
    // 选择时间
    changeSaveDate (dataInfo, startTime, endTime) {
      this.queryParams.StartDate = startTime.replace(/\//g, '-');
      this.queryParams.EndDate = endTime.replace(/\//g, '-');
      this.$emit('searchValue', this.queryParams);
    },
    // 选择校区
    doRecordSelectedStatus (obj) {
      console.log(obj, '选中状态');
      this.selectedSchoolKeyValue = obj.SchoolKeyValue;
      this.queryParams.SchoolKey = obj.SchoolKey;
      this.$emit('searchValue', this.queryParams);
    }
  }
};
</script>
<style>
</style>

