<template>
  <div class="margin_left_right_div">
    <div class="general_summarizing_student payment_statistics_view height_type all_view template_minOrmax_width_div">
      <div class="course_statistics_table_search flex course_statistics_title">
        <div class="table_select_box"
             style="margin-right:20px;min-width:306px">
          <div class="table_select_title">课程顾问</div>
          <el-select :value="vsaleManValue"
                     placeholder="请选择"
                     style="width:239px"
                     ref="resetText"
                     @change="selectOverdueType">
            <el-option v-for="item in vsalesList"
                       :key="item.value"
                       :label="item.label"
                       :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="">
          <div class="">
            <date-range ref="monthPicker"
                        style="margin-right:36px"
                        :fromTitle="'发生日期'"
                        :searchObj="searchObj"
                        @changeDate="changeSaveDate"></date-range>
          </div>
        </div>

        <div class="course_statistics_search">
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入姓名、姓名首字母、手机号'"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="search"></input-search-contain-btn>
        </div>
      </div>
      <div class="course_statistics_table_box table_list_content">
        <table-list class="summarizing_list"
                    :tableData="vdataReportList"
                    :tableColumns="tableCourseColumns"
                    :totalNum="totalNum"
                    :PageCount="PageCount"
                    :queryParams="searchObj"
                    :footerContent="vFooterTotal"
                    @loadTableData="getDataList"></table-list>
      </div>
      <div>
        <dialog-factory ref="applyRecordDialogFactory"
                        :key="'applyRecord'"
                        :routerName="'applyRecord'"></dialog-factory>

        <dialog-factory-common ref="applyRecordDialogFactoryCommon"></dialog-factory-common>
      </div>
    </div>
  </div>
</template>
<script>
import dialogFactory from '../../../common/dialog-factory';
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import { SaleStatReportRecord, GetAllSales } from '../../../../API/workbench';
export default {
  name: 'applyRecord',
  data () {
    return {
      seletedCourseInfo: {},
      studentDetail: {},
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: '',
        endTime: '',
        saleMan: 0,
        saleManValue: ''
      },
      salesList: [],
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '课程顾问',
          prop: 'SaleMansKeyValue',
          minWidth: 80,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '发生日期',
          prop: 'BillTime',
          minWidth: 160,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '经办人',
          prop: 'BillerKeyValue',
          minWidth: 100,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '学生',
          prop: 'StudentKeyValue',
          width: ' ',
          minWidth: 100,
          sortable: false,
          type: 'student',
          extend: {
            studentKey: 'StudentKey',
            phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '购买套餐',
          prop: 'PackageKeyValue',
          minWidth: 100,
          sortable: false,
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '应收',
          prop: 'RecableAmount',
          minWidth: 100,
          sortable: false,
          align: 'left',
          type: 'amountItem',
          decimalPlace: 2
        },
        {
          label: '实收',
          prop: 'ActualColAmount',
          minWidth: 100,
          sortable: false,
          align: 'left',
          type: 'amountItem',
          decimalPlace: 2
        },
        {
          label: '欠款',
          prop: 'DebtAmount',
          minWidth: 100,
          sortable: false,
          align: 'left',
          type: 'amountItem',
          decimalPlace: 2
        },
        {
          label: '预付款',
          prop: 'DepositAmount',
          minWidth: 100,
          sortable: false,
          align: 'left',
          type: 'amountItem',
          decimalPlace: 2
        },
        {
          label: '余额',
          prop: 'StoBalanceAmount',
          minWidth: 100,
          sortable: false,
          align: 'left',
          type: 'amountItem',
          decimalPlace: 2
        },
        {
          label: '收款方式',
          prop: 'AccountTypeKeyValue',
          sortable: false,
          minWidth: 100,
          align: 'left',
          type: 'textItem',
          noPlusminus: true,
          decimalPlace: 0
        },
        {
          label: '跟进情况描述',
          prop: 'followContent',
          sortable: false,
          minWidth: 200,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_dual'
          }
        },
        {
          label: '下次跟进',
          prop: 'NextFollowUpTime',
          sortable: false,
          minWidth: 88,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_dual'
          }
        },
        {
          label: '来源方式',
          prop: 'SourceTypeKeyValue',
          sortable: false,
          minWidth: 112,
          align: 'left',
          type: 'textItem',
          noPlusminus: true,
          decimalPlace: 0
        }

      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
    dialogFactory
    // seletedOption
  },
  props: {
  },
  created () {
    console.log('this.toSearchObj');
    this.searchObj.startTime = this.$route.query.startTime;
    this.searchObj.endTime = this.$route.query.endTime;
    this.searchObj.saleManValue = this.$route.query.SaleMan || '';
    this.searchObj.saleMan = this.$route.query.SaleManKey || 0;
  },
  activated () {
    console.log('activated', this.searchObj, this.$route.query);
    this.searchObj.startTime = this.$route.query.startTime;
    this.searchObj.endTime = this.$route.query.endTime;
    this.searchObj.saleManValue = this.$route.query.SaleMan || '';
    this.searchObj.saleMan = this.$route.query.SaleManKey || 0;
    this.getDataList();
  },
  //  beforeRouteEnter(to, from, next) {
  //       console.log('to',to);  //上一个页面前往的的页面(当前页面)
  //       console.log('from',from);//来自哪一个页面
  //       console.log("next",next)
  //       next((e) => {
  //           // 这个回调参数e,包含setup中暴露出去的数据以及内置方法
  //         console.log
  //       })
  //   },

  computed: {
    vsaleManValue () {
      return this.searchObj.saleMan == 0
        ? '全部'
        : this.searchObj.saleManValue;
    },
    vsalesList () {
      return this.salesList;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // o.NextFollowUpTime = '2021-10-11 16:30:25';
        // o.Content = '测试133113';
        // o.FollowUpTime = '2021-10-11 16:30:25';
        o.StoBalanceAmount = Number(o.StoBalanceAmount) || 0;
        o.SaleMansKeyValue = o.SaleMansKeyValue || '-';
        o.followContent = o.Content ? `<span class="font_gray">` + this.$utils.StrForTodoTime(o.FollowUpTime) + `:</span>` + o.Content : '';
        o.NextFollowUpTime = (o.NextFollowUpTime ? this.$utils.leadTime(o.NextFollowUpTime.substring(0, 10)) + ' ' + `<div>` + o.NextFollowUpTime.substring(5, 10) + `</div>` : '');
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      text = text + ',合计+' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      return text;
    }
  },
  mounted () {
    this.getSalesList();
    this.resgisterBusEvent();
  },
  methods: {
    // 销售人员层显示并获取数据
    getSalesList () {
      GetAllSales()
        .then(result => {
          result.data.forEach(o => {
            o.label = o.MainDemoName + (o.IsLeaveKey != 1 ? ' (离职)' : '');
            o.value = o.OLAPKey;
            // this.$set(o, 'isActive', false);
          });
          result.data.unshift({
            label: '全部',
            value: 0
          });
          this.salesList = result.data;
        })
        .catch(err => {
          console.info(err);
        });
    },
    setAfterDay (dateStr) {
      if (!dateStr) {
        return '';
      }
      let times = Date.new(dateStr).getTime() - Date.new(Date.new().toLocaleDateString()).getTime();
      if (times == 0) {
        return '今天';
      } else if (times > 0) {
        return (times / 86400000) + '天后';
      } else {
        return '已过期';
      }
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.getDataList();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.IsExportKey = 0;
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.saleMan = 0;
      this.searchObj.saleManValue = '';
      this.getDataList();
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (item, date) {
      console.log('searchData', date);
      this.searchObj.TeacherKey = item.TeacherKey;
      this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(date).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      let seletedYearMonth = Date.new(date).getFullYear() + ' ' + (Date.new(date).getMonth() + 1);
      let nowYearMonth = Date.new().getFullYear() + ' ' + (Date.new().getMonth() + 1);
      if (seletedYearMonth != nowYearMonth) {
        this.searchObj.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new(date)), 'yyyy-MM-dd');
      } else {
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
      }
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    getDataList (queryParams) {
      console.log(this.searchObj);
      SaleStatReportRecord(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.ActualColAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;

        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.red:
          this.$dialog.open(this, {
            name: '学生档案',
            routerName: this.$route.name,
            moduleName: 'studentFile',
            data: { studentID: rowData.StudentNumKey }
          });

          break;
        default:
          break;
      }
    },
    selectOverdueType (item) {
      console.log('selectOverdueType', item);
      this.searchObj.saleMan = item.value;
      this.searchObj.saleManValue = item.label;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.applyRecordDialogFactory) {
        this.$refs.applyRecordDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      };
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.applyRecordDialogFactoryCommon) {
        this.$refs.applyRecordDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      };
    },
    resgisterBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'applyRecord') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'applyRecord') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    }

  }
};
</script>