<template>
  <div class="margin_left_right_div">
    <div class="template_minOrmax_width_div">
      <div class="class_list_query_statistics kedanchaxun">
        <div class="table_fixed_nav_box "
             style="padding-left: 40px;">
          <div class="table_fixed_nav"
               style="width:100%">
            <div class="table_fixed_nav_list "
                 :class="IsMTeaKey==0?'opt':''"
                 @click.stop="changeIsMainTearcher(0)">
              全部
            </div>
            <div class="table_fixed_nav_list"
                 :class="IsMTeaKey==1?'opt':''"
                 @click.stop="changeIsMainTearcher(1)">单课</div>
            <div class="table_fixed_nav_list"
                 :class="IsMTeaKey==2?'opt':''"
                 @click.stop="changeIsMainTearcher(2)">套餐</div>
            <div class="table_fixed_nav_list"
                 :class="IsMTeaKey==3?'opt':''"
                 @click.stop="changeIsMainTearcher(3)">组合课</div>
            <div class="table_fixed_nav_list"
                 :class="IsMTeaKey==5?'opt':''"
                 @click.stop="changeIsMainTearcher(5)">课单异常明细</div>
            <div class="table_fixed_nav_list"
                 :class="IsMTeaKey==4?'opt':''"
                 @click.stop="changeIsMainTearcher(4)">课时不足</div>
          </div>
        </div>
        <div v-if="IsMTeaKey==5"
             class="payment_statistics_bottom clearfix kedanyichang box_shadow_border_div">
          <class-count-detail ref="classCountDetail"
                              :searchText="searchText"
                              @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"></class-count-detail>
        </div>
        <courseCountList v-else-if="IsMTeaKey==4"
                         :IsMTeaKey="IsMTeaKey"
                         class="course-report box_shadow_border_div"></courseCountList>
        <course-report v-else
                       :IsMTeaKey="IsMTeaKey"
                       class="course-report box_shadow_border_div"></course-report>

        <div>
          <dialog-factory ref="teacherPerformance"
                          :routerName="'teacherPerformance'"></dialog-factory>
          <dialog-factory-common ref="teacherPerformanceFactoryCommon"></dialog-factory-common>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dialogFactory from '../../common/dialog-factory';
// import teacherReport from './teacher-report.vue';
import courseReport from './course-report.vue';
import courseCountList from './couser-count-list.vue';
import classCountDetail from '../class-spend-statistics-analyze/components/class-count-detail';
import { GetTeacherDataGenerationTime, RegenTeacherPerformance } from '../../../API/workbench';
export default {
  name: 'classListQueryStatistics',
  data () {
    return {
      generationTime: '',
      isMainTeacher: 0,
      IsMTeaKey: 0,
      searchText: ''
    };
  },
  components: {
    dialogFactory,
    // teacherReport,
    courseReport,
    classCountDetail,
    courseCountList
  },
  created () {
    if (this.$route.params && this.$route.params.data && this.$route.params.data.dataInfo) {
      this.searchText = this.$route.params.data.dataInfo.searchText || '';
      console.log('init searchText', this.searchText);
    }
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'classListQueryStatistics') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'classListQueryStatistics') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.getCourseNameList();
    // this.getTeacherDataGenerationTime();
    // this.loadDataSourceTeacher();
    // this.getValidClassList();
  },
  activated () {

  },
  mounted () {
    console.log('课单查询统计 $route params', this.$route.params);
  },
  methods: {

    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.teacherPerformance) {
        this.$refs.teacherPerformance.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.teacherPerformanceFactoryCommon) {
        this.$refs.teacherPerformanceFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    },
    // 切换NAV
    changeIsMainTearcher (type) {
      console.log(type, this.searchObj, '切换NAV');
      this.IsMTeaKey = type;
      if (this.IsMTeaKey == 4) {
        this.$forceUpdate()
      }
    },

    // 课名列表
    getCourseNameList () {
      this.$basicData.loadCourseNameList();
    }
  }
};
</script>
<style scoped>
.kedanchaxun >>> .table_fixed_nav_list {
  width: 140px !important;
}
.kedanchaxun >>> .kedanyichang .course_statistics_table_search {
  padding: 20px;
}
</style>