<template>
  <div id="mendain_set_form">
    <module-description v-if="!isAdd"
                        style="padding-right:0"
                        :textTips="'课件合作校区开户须知：开通后的校区，与贵公司没有从属关系，登录账号为该手机号，其它信息会发送到该手机号上，请通知该校区负责人登录操作。开通后，请及时授权课件。'"></module-description>
    <!-- <div class="form_info_edit form_info_list"
         style="min-height:565px"
         v-if="isAdd">
      <input-option-text :formTitle="'合作校区类型'"
                         :readonly="false"
                         :required="true"
                         :selectList="campusList"
                         :selectedInfo="vcampusInfo"
                         @change="chgCampusType">
      </input-option-text>
      <input-text v-if="addInfo.id==2"
                  :formTitle="'校区ID'"
                  :required="true"
                  :readonly="false"
                  :formPlaceholder="'必填'"
                  v-model="dataJson.CampusKey"></input-text>
      <input-phone v-if="addInfo.id==2"
                   :formTitle="'机构电话'"
                   :formPlaceholder="'必填'"
                   :numberType="'text'"
                   :required="true"
                   :readonly="false"
                   v-model="dataJson.MobilePhone"></input-phone>
    
    </div> -->
    <div class="form_info_edit form_info_list"
         style="min-height:565px"
         v-if="!isAdd">
      <input-text :formTitle="'校区名'"
                  :required="true"
                  :readonly="false"
                  :formPlaceholder="'必填'"
                  v-model="dataJson.CampusName"></input-text>
      <input-text :formTitle="'管理员'"
                  :required="true"
                  :readonly="false"
                  :formPlaceholder="'必填'"
                  v-model="dataJson.AdminName"></input-text>

      <input-phone :formTitle="'管理员手机号'"
                   :formPlaceholder="'必填'"
                   :required="true"
                   :readonly="false"
                   v-model="dataJson.DefaultMobile"></input-phone>
        <input-select :formTitle="'流量设置'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :selectedInfo="vflowSetInfo"
                    :dataList="flowSetType"
                    @selectedItem="changeFlowSet"></input-select>
      <input-search-cities :formTitle="'所在地区'"
                           :required="false"
                           :readonly="false"
                           :selectedInfo="locationInfo"
                           @selectedItem="changeRegion"></input-search-cities>

      <input-text :formTitle="'详细地址'"
                  :formPlaceholder="'选填'"
                  class="site_icon_type"
                  :readonly="false"
                  :required="false"
                  v-model="dataJson.Address"></input-text>
      <input-dialog :formTitle="'地图定位'"
                    :customPlaceholder="true"
                    :formPlaceholder="'设置'"
                    :formInputHint="dataJson.SaaSClientSetting[0].SetContent?'':'未设置定位'"
                    :required="false"
                    :hiddenInput="true"
                    :readonly="false"
                    :class="dataJson.SaaSClientSetting[0].SetContent?'content':''"
                    :value="dataJson.SaaSClientSetting[0].SetContent ||'设置'"
                    @showEldilog="isShowLocationForm=true"></input-dialog>

      <div class="mendian_location pr"
           v-if="vLocationImg != ''">
        <img :src="vLocationImg"
             crossOrigin="anonymous"
             alt="">
      </div>
      <input-number :max="100"
                    :min="0"
                    :formTitle="'开通时长（年）'"
                    :formPlaceholder="'必填'"
                    :decimalPlaces="0"
                    :required="true"
                    :readonly="false"
                    v-model="dataJson.DeductCount"></input-number>

      <input-text :min="0"
                  :formTitle="'扣除端口数（个）'"
                  :decimalPlaces="0"
                  :required="false"
                  :readonly="true"
                  :className="'font_black'"
                  v-model="vDeductCountTitle"></input-text>
    
    </div>
    <save-cancel-btn-group v-if="isAdd"
                           :btnText="'下一步'"
                           @confirmClick="nextTime"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
    <save-cancel-btn-group v-else
                           :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
    <custom-dialog :title="'位置'"
                   :visible.sync="isShowLocationForm"
                   :before-close="closeLocationFormPopup">
      <form-location :locationInfo="dataJson.SaaSClientSetting"
                     @close="closeLocationFormPopup"
                     @setLocationInfo="setLocationInfo"></form-location>
    </custom-dialog>
  </div>
</template>
<script>
import { AddCourseCooperationCampus, QueryCampuInfo } from '../../../../../API/workbench';
export default {
  data () {
    return {
      // 流量设置
      flowSetType: [
        { type: 1, name: '总部' },
        { type: 2, name: '门店' }
      ],
      getDate: new Date(),
      dataJson: {
        CampusName: '', // "校区名",
        CampusKey: '',
        SaaSClientID: '',
        Principal: '', // "负责人",
        MobilePhone: '', // "手机号",
        AdminName: '', // 管理员
        DefaultMobile: '', // 管理员手机号
        ProvinceKey: '', // "省",
        ProvinceKeyValue: '', // "",
        CityKey: '', // "市",
        CityKeyValue: '', // "",
        AreaKey: '', // "区",
        AreaKeyValue: '', // "",
        Address: '', // "详细地址",
        // CounselorKey: "",//"招商顾问",
        // CounselorKeyValue: "",//"",
        DeductCount: '1', // "扣除端口数",
        // LeagueTime: "",//"加盟日期",
        IsOpenChannelKey: '1', // "是否开通课件 0-否;1-是",
        IsOpenAcademicKey: '0', // "是否开通教务  0-否;1-是",
        CurrentFluxObjectKey: 1, // 1-总部;2-门店
        CurrentFluxObjectKeyValue: '总部', // 总部、门店
        NextFluxObjectKey: 0, // 下个月扣费对象	 1-总部;2-门店;0-未设置
        NextFluxObjectKeyValue: '', // 下个月扣费对象	 1-总部;2-门店;0-未设置
        SaaSClientSetting: [
          { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '14' }, // 定位地址信息
          { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '15' }, // 定位经纬度信息
          { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '16' }// 定位截图信息
        ]
      },
      locationInfo: [], // 地址
      addInfo: {
        id: 1,
        name: '新校区'
      },
      isAdd: false,
      campusList: [
        {
          id: 1,
          name: '新校区'
        },
        {
          id: 2,
          name: '已存在校区'
        }
      ],
      isShowLocationForm: false // 位置
    };
  },
  computed: {
    vflowSetInfo () {
      return {
        type: this.dataJson.CurrentFluxObjectKey,
        name: this.dataJson.CurrentFluxObjectKeyValue
      };
    },
    // 性别
    vcampusInfo () {
      return {
        id: this.addInfo.id,
        name: this.addInfo.name
      };
    },
    vDeductCountTitle () {
      let text = '';
      if (parseInt(this.dataJson.DeductCount) > 0) {
        text += this.dataJson.IsOpenChannelKey == 1 ? '课件*' + parseInt(this.dataJson.DeductCount) : '';
      }
      return text;
    },
    // 定位截图
    vLocationImg () {
      if (this.dataJson && this.dataJson.SaaSClientSetting[2].SetContent) {
        return this.$store.getters.CDNURL + this.dataJson.SaaSClientSetting[2].SetContent;
      } else {
        return '';
      }
    }
  },
  mounted () {
    if (this.newData) {
      this.dataJson.CampusName = this.newData.MainDemoName;
      this.dataJson.AdminName = this.newData.PrincipalName;
      this.dataJson.Principal = this.newData.PrincipalName;
      this.dataJson.MobilePhone = this.newData.MobilePhone;
      this.dataJson.DefaultMobile = this.newData.MobilePhone;
      this.dataJson.Address = this.newData.Address;
    }
    this.$dialog.register(this, ['openOptionsForm']);
  },
  props: {
    dataInfo: Object,
    newData: Object
  },
  methods: {
    closeLocationFormPopup () {
      this.isShowLocationForm = false;
    },
    setLocationInfo (data) {
      this.dataJson.SaaSClientSetting = data;
      console.log(data, 'locationInfo');
      this.closeLocationFormPopup();
    },
    // 选择流量设置
    changeFlowSet (obj) {
      console.log(obj, '选择来源方式');
      this.dataJson.CurrentFluxObjectKey = obj.type;
      this.dataJson.CurrentFluxObjectKeyValue = obj.name;
    },
    chgCampusType (item) {
      console.log(item, 'chgSexType');
      this.addInfo = item;
    },
    // 省市区
    changeRegion (keys, values) {
      this.dataJson.ProvinceKey = keys[0] || '';// 省
      this.dataJson.ProvinceKeyValue = values[0] || ''; // 省
      this.dataJson.CityKey = keys[1] || '';// 市
      this.dataJson.CityKeyValue = values[1] || ''; // 市
      this.dataJson.AreaKey = keys[2] || ''; // 区/县
      this.dataJson.AreaKeyValue = values[2] || ''; // 区/县
      console.log(keys, values, '省市区');
    },
    nextTime () {
      // 选择新校区
      if (this.addInfo.id == 1) {
        this.dataJson.CampusKey = '';
        this.isAdd = false;
      } else {
        if (!this.dataJson.CampusKey || this.dataJson.CampusKey == 0) {
          layer.alert('请输入校区ID');
          return false;
        } else if (!this.dataJson.MobilePhone || !this.dataJson.MobilePhone.replace(/(^\s*)|(\s*$)/g, '')) {
          layer.alert('机构电话不能为空');
          return false;
          // flag = true;
        }
        this.getCampuInfo();
      }
    },
    getCampuInfo () {
      QueryCampuInfo(
        this.dataJson.MobilePhone,
        this.dataJson.CampusKey
      ).then((result) => {
        console.log(result.data, 'getDataInfo');
        if (result.data.CurrentFluxObjectKey == 1 && result.data.NextFluxObjectKey != 2) {
          this.dataJson.CurrentFluxObjectKeyValue = '总部负担';
        } else if (result.data.CurrentFluxObjectKey == 1 && result.data.NextFluxObjectKey == 2) {
          if ((this.getDate.getMonth() + 2) == 13) {
            this.dataJson.CurrentFluxObjectKeyValue = '总部负担,1月1号起门店负担';
          } else {
            this.dataJson.CurrentFluxObjectKeyValue = '总部负担,' + (this.getDate.getMonth() + 2) + '月1号起门店负担';
          }
        } else if (result.data.CurrentFluxObjectKey == 2 && result.data.NextFluxObjectKey != 1) {
          this.dataJson.CurrentFluxObjectKeyValue = '门店负担';
        } else if (result.data.CurrentFluxObjectKey == 2 && result.data.NextFluxObjectKey == 1) {
          if ((this.getDate.getMonth() + 2) == 13) {
            this.dataJson.CurrentFluxObjectKeyValue = '门店负担,1月1号起总部负担';
          } else {
            this.dataJson.CurrentFluxObjectKeyValue = '门店负担,' + (this.getDate.getMonth() + 2) + '月1号起总部负担';
          }
        } else if (result.data.CurrentFluxObjectKey == 0 && result.data.NextFluxObjectKey == 1) {
          if ((this.getDate.getMonth() + 2) == 13) {
            this.dataJson.CurrentFluxObjectKeyValue = '暂时未设置，1月1号起总部负担';
          } else {
            this.dataJson.CurrentFluxObjectKeyValue = '暂时未设置，' + (this.getDate.getMonth() + 2) + '月1号起总部负担';
          }
        } else if (result.data.CurrentFluxObjectKey == 0 && result.data.NextFluxObjectKey == 2) {
          if ((this.getDate.getMonth() + 2) == 13) {
            this.dataJson.CurrentFluxObjectKeyValue = '暂时未设置，1月1号起门店负担';
          } else {
            this.dataJson.CurrentFluxObjectKeyValue = '暂时未设置，' + (this.getDate.getMonth() + 2) + '月1号起门店负担';
          }
        } else {
          this.dataJson.CurrentFluxObjectKeyValue = '未设置';
        }
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '开通功能',
          moduleName: 'openOptionsForm',
          data: {
            dataInfo: {
              CampusKey: result.data.CampusKey,  // 校区ID
              CampusName: result.data.MainDemoName,
              DefaultMobile: this.dataJson.DefaultMobile,  // 管理员手机号·
              IsOpenChannelKey: '1', // "是否开通课件 0-否;1-是",
              IsOpenAcademicKey: '', // "是否开通教务  0-否;1-是",
              ExpireTime: '',   // 到期日
              type: 3,      // 1-直营店;2-加盟店;3-课程合作
              CurrentFluxObjectKeyValue: result.data.CurrentFluxObjectKeyValue,
              CurrentFluxObjectKey: result.data.CurrentFluxObjectKey
            }
          },
          callBack: {
            afterSuccess: (data) => {
              console.log('QueryCampuInfo');
              this.$emit('afterSuccess');
              this.$emit('close');
            }
          }
        });
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    cancelClick () {
      this.$emit('close');
    },
    verifyData () {
      let flag = false;
      this.dataJson.DeductCount = parseInt(this.dataJson.DeductCount);
      if (!this.dataJson.CampusName) {
        layer.alert('请输入校区名');
        flag = true;
        return flag;
      } else if (!this.dataJson.AdminName) {
        layer.alert('请输入管理员');
        flag = true;
        return flag;
      } else if (!this.dataJson.DefaultMobile) {
        layer.alert('请输入管理员手机号');
        flag = true;
        return flag;
      } else if (!this.dataJson.DeductCount || this.dataJson.DeductCount == 0) {
        layer.alert('开通时长不能小于等于0');
        flag = true;
        return flag;
      } else if (this.dataJson.DefaultMobile.length != 11) {
        layer.alert('管理员手机号码必须为11位数');
        flag = true;
      } else if (!this.dataJson.CurrentFluxObjectKeyValue) {
        layer.alert('请选择流量设置');
        flag = true;
        return flag;
      }
      return flag;
    },
    // 确认按钮
    confirmClick (callBack) {
      if (this.verifyData()) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.addOperation(callBack);
    },
    // 新增职务
    addOperation (callBack) {
      this.dataJson.Principal = this.dataJson.AdminName;
      this.dataJson.MobilePhone = this.dataJson.DefaultMobile;
      AddCourseCooperationCampus(this.dataJson)
        .then(
          (result) => {
            console.log(result, '职务新增');
            this.$emit('afterSuccess', result.data);
            this.$emit('close');
            layer.alert('新增成功');
          },
          (error) => {
            layer.alert(error.msg);
          }
        )
        .finally(() => {
          if (callBack) {
            callBack();
          }
        });
    }
  }
};
</script>