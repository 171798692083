<template>
  <div class="course_dispense_left_bottom">
    <div class="bottom_week_ul">
      <row-head v-for="(item,key) in newDataList[0].crosswiseList"
                :key="key"
                :item="item"></row-head>
    </div>

    <div v-if="list.length>0">
      <row-table v-for="(item,key) in newDataList"
                 :key="key"
                 :dataInfo="item"
                 @clickItem="clickItem"></row-table>
    </div>

    <div v-if="list.length==0"
         class="course_block_nodata" style="background-position: center 220px;height: 400px;line-height: 400px;">
      <div class="bottom_course_box"></div>
      <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
    </div>
  </div>
</template>

<script>
import rowHead from './row-head';
import rowTable from './row-table/index';
export default {
  components: {
    rowHead,
    rowTable
  },
  data () {
    return {
      newDataList: []
    };
  },
  props: {
    dataList: Array,
    list: Array
  },
  watch: {
    dataList (n, v) {
      if (n.length > 0) {
        this.newDataList = n;
      }
      console.log(this.newDataList, 'watch-dataList');
    }
  },
  created () {
    this.newDataList = this.dataList;
  },
  methods: {
    clickItem (item) {
      console.log(item, 'watch-item');
      this.$emit('clickAfterItem', item);
    }
  }
};
</script>

<style>
</style>