
<template>
  <!-- 教学信息 -->
  <div>
    <div class="mendian_setting_content">

   
      <div class="form_info_edit">
        <input-dialog :formTitle="'单节课退课'"
                          class="padding_LR_10"
                          :formPlaceholder="' '"
                          :readonly="isReadonly"
                          @showEldilog="showSystemSetform('单节课退课',122)"
                          :value="Number(singleQuitInfo.SetKey)>0?'开启':'关闭'"></input-dialog>
            <input-dialog :formTitle="'退出循环课'"
                          class="padding_LR_10"
                          :formPlaceholder="' '"
                          :readonly="isReadonly"
                          @showEldilog="showSystemSetform('退出循环课',123)"
                          :value="Number(quitBantchInfo.SetKey)>0?'开启':'关闭'"></input-dialog>
            <input-dialog :formTitle="'考勤校正'"
                          class="padding_LR_10"
                          :formPlaceholder="' '"
                          :readonly="isReadonly"
                          @showEldilog="showSystemSetform('考勤校正',124)"
                          :value="Number(attenInfo.SetKey)>0?'开启':'关闭'"></input-dialog>            
            <input-dialog :formTitle="'停课'"
                          class="padding_LR_10"
                          :formPlaceholder="' '"
                          :readonly="isReadonly"
                          @showEldilog="showSystemSetform('停课',125)"
                          :value="Number(stopCourseInfo.SetKey)>0?'开启':'关闭'"></input-dialog>
            <input-dialog :formTitle="'单节课清除排课'"
                          class="padding_LR_10"
                          :formPlaceholder="' '"
                          :readonly="isReadonly"
                          @showEldilog="showSystemSetform('单节课清除排课',126)"
                          :value="Number(singleCleanInfo.SetKey)>0?'开启':'关闭'"></input-dialog>
            <input-dialog :formTitle="'批量清除排课'"
                          class="padding_LR_10"
                          :formPlaceholder="' '"
                          :readonly="isReadonly"
                          @showEldilog="showSystemSetform('批量清除排课',127)"
                          :value="Number(bantchCleanInfo.SetKey)>0?'开启':'关闭'"></input-dialog>
        
      
      </div>
     </div>
     <save-cancel-btn-group :isSingleSubmit="true"
                               :singleSubmitType="'dynamic'"
                               @confirmClick="confirmFormClick"
                               @cancelClick="cancelClick"></save-cancel-btn-group>
      <!-- 系统设置弹窗 -->
      <custom-dialog :title="systemSetFormInfo.title"
                    class="new_class defult_dialog"
                    :visible.sync="isShowSystemSetForm"
                    :before-close="doCloseShowForm">
        <mendian-system-set-form :value="newInputText"
                                :systemSetFormInfo="systemSetFormInfo"
                                :seletedSettingInfo="seletedSettingInfo"
                                @editSettingInfoItem="editSettingInfoItem"
                                @doCloseShowForm="doCloseShowForm"
                               ></mendian-system-set-form>
      </custom-dialog>
  </div>
</template> 

<script>
import {
  WEBVModifySaaSClientSetting,GetSaaSClientMsg
} from '../../../../API/workbench.js';
import mendianSystemSetForm from './mendian-system-set-form';
export default {
  data () {
    return {
       systemSetFormInfo: { title: '', setKey: '' },
       isShowSystemSetForm:false,
       newInputText:'',
       seletedSettingInfo:null,
       singleQuitInfo:{},  //单节课退课
       quitBantchInfo:{},  //退出循环课
       attenInfo:{}, //考勤校正
       stopCourseInfo:{}, //停课
       singleCleanInfo:{},  //单节课清除排课
       bantchCleanInfo:{},  // 批量清除排课
       isReadonly:false

    };
  },
  components: {
    mendianSystemSetForm
  },
  created () {
    this.singleQuitInfo=this.$utils.getSaaSClientSetInfo(122)
    this.quitBantchInfo=this.$utils.getSaaSClientSetInfo(123)
    this.attenInfo=this.$utils.getSaaSClientSetInfo(124)
    this.stopCourseInfo=this.$utils.getSaaSClientSetInfo(125)
    this.singleCleanInfo=this.$utils.getSaaSClientSetInfo(126)
    this.bantchCleanInfo=this.$utils.getSaaSClientSetInfo(127)
  },
  mounted () {
    // this.$dialog.register(this, ['courseNameSetting', 'squadClassSetting', 'classroomSetting']);
  },
  methods: {
    confirmFormClick (callBack) {
      let newArr=[]
      newArr.push(this.singleQuitInfo)
      newArr.push(this.quitBantchInfo)
      newArr.push(this.attenInfo)
      newArr.push(this.stopCourseInfo)
      newArr.push(this.singleCleanInfo)
      newArr.push(this.bantchCleanInfo)
      WEBVModifySaaSClientSetting(newArr).then((res) => {
        console.log('WEBVModifySaaSClientSetting', res.data);
        GetSaaSClientMsg().then(result => {
        this.$store.commit('setSaaSClientInfo', result.data);
        }, err => {
          layer.alert(err.msg);
        });
      });
      this.$emit('updateStrictAtten',newArr)
      this.cancelClick();
     
    },
    // 修改系统设置
    editSettingInfoItem (val) {
      console.log('editSettingInfoItem',val)
      let obj = this.$utils.getSaaSClientSetInfo(this.systemSetFormInfo.setKey);
      obj=val
      if(val.SetTypeKey==122){
        this.singleQuitInfo=obj //单节课退课
      }else if(val.SetTypeKey==123){
        this.quitBantchInfo=obj   //退出循环课
      }else if(val.SetTypeKey==124){
        this.attenInfo=obj  //考勤校正
      }else if(val.SetTypeKey==125){
        this.stopCourseInfo=obj //停课
      }else if(val.SetTypeKey==126){
        this.singleCleanInfo=obj   //单节课清除排课
      }else if(val.SetTypeKey==127){
        this.bantchCleanInfo=obj  // 批量清除排课
      }
      console.log("singleQuitInfo",this.singleQuitInfo)
    },
    cancelClick(){
      this.$emit("closeFrom")
    },
    // 关闭弹窗
    doCloseShowForm () {
      this.isShowSystemSetForm=false
      // this.$emit('doCloseShowForm');
    },
    
     // 系统弹窗设置
    showSystemSetform (title, setKey, multipleKey) {
      this.systemSetFormInfo.title = title;
      this.systemSetFormInfo.setKey = setKey;
      if(setKey==122){
        //单节课退课
        this.seletedSettingInfo = this.$utils.parseJson(this.singleQuitInfo);
      }else if(setKey==123){
        //退出循环课
        this.seletedSettingInfo = this.$utils.parseJson(this.quitBantchInfo);
      }else if(setKey==124){
       //考勤校正
        this.seletedSettingInfo = this.$utils.parseJson(this.attenInfo); 
      }else if(setKey==125){
        //停课
        this.seletedSettingInfo = this.$utils.parseJson(this.stopCourseInfo);
      }else if(setKey==126){
        //单节课清除排课
         this.seletedSettingInfo = this.$utils.parseJson(this.singleCleanInfo); 
      }else if(setKey==127){
        // 批量清除排课
        this.seletedSettingInfo = this.$utils.parseJson(this.bantchCleanInfo); 
      }
      this.isShowSystemSetForm = true;
    },
  }
}
</script>

  <style>
</style>