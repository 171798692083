<template>
  <!-- 分享二维码 -->
  <div>
    <div class="popup_dialog_box_content clearfix">

      <!-- <module-description :textTips="'说明文字说明文字说明文字说明文字'"
                          :btnTips="'查看教程'"
                          :btnUrl="'https://mp.weixin.qq.com/s/9kLtbXOLBg7yPB-dzSS8Hg'"></module-description> -->
      <div class="share_QR_banner">
        <div class="QR_text">
          <div>家长注册后，可收到上课提醒和签到通知。 </div>
          <div>并可以查看学生排课，考勤课消，账单和交作业。</div>
        </div>
        <div class="QR_img">
          <div v-if="vQRcode"
               class="pr">
            <img :src="vQRcode"
                 id="QRcodeImg">
            <!-- <a v-show="isMendianQRcode"
               :href="QrcodeImgSrc"
               download="QRcode"
               id="QRcodeDownload"></a> -->
          </div>
          <div v-if="!vQRcode"
               class="weixin_setting_QRcode_noData"
               style="margin-left: 25px;">
            抱歉！<br />分享二维码不存在。
          </div>
        </div>
        <div class="QR_ul">
          <div class="QR_list">使用方法：</div>
          <div class="QR_list">
            <span>①</span>下载二维码或拍照
          </div>
          <div class="QR_list">
            <span>②</span>发二维码给家长
          </div>
          <div class="QR_list">
            <span>③</span>家长关注公众号并注册手机
          </div>
        </div>
      </div>
      <div class="share_QR_btn btn_hover_bg_blue"
           v-if="vQRcode"
           @click="qrcodeDownload">
        下载二维码保存到本地
      </div>
    </div>
  </div>
</template> 

<script>

import { } from '../../../../API/workbench';
export default {
  data () {
    return {

    };
  },
  props: [

  ],
  components: {
  },

  created () {
    console.log(this.$store.getters.SaaSClientInfo, '门店二维码');
  },
  mounted () {

  },
  activated () {

  },
  watch: {

  },
  computed: {
    vQRcode () {
      if (this.$store.getters.SaaSClientInfo.WktInfo && this.$store.getters.SaaSClientInfo.WktInfo.QRcode) {
        if (this.$store.getters.SaaSClientInfo.WktInfo.QRcode.indexOf('http') > -1) {
          return this.$store.getters.SaaSClientInfo.WktInfo.QRcode;
        } else {
          return this.$store.getters.CDNURL + this.$store.getters.SaaSClientInfo.WktInfo.QRcode;
        }
      }
    }
  },
  methods: {
    qrcodeDownload () {
      if (this.vQRcode) {
        let src = this.vQRcode;
        // src = src.replace('cdn', '');
        src = src.replace(':8999', '');
        console.log(src);
        var canvas = document.createElement('canvas');
        var img = document.createElement('img');
        img.setAttribute('crossOrigin', 'Anonymous');
        img.onload = function (e) {
          canvas.width = img.width;
          canvas.height = img.height;
          var context = canvas.getContext('2d');
          context.drawImage(img, 0, 0, img.width, img.height);
          canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
          canvas.toBlob((blob) => {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = '分享二维码下载';
            link.click();
          }, 'image/jpeg');
        };
        img.src = src;
        // console.log(img);
      }
    }
  }

};
</script>  

  <style>
/*  */
</style>