<template>
  <div>
    <!-- <module-description style="padding-right:0"
                        :textTips="'说明文字'"></module-description> -->
    <div class="port_apply_summarizing"
         style="padding-top:15px">
      <!-- <div class="left_top_box"
           style="margin: 0 auto 15px;float: none;width: 700px;">
        <div class="top_list line">
          <span class="text">已用设备</span>
          <span class="number">{{dataObj.HasUseDeviceNum}}</span>
        </div>
        <div class="top_list">
          <span class="text">可用设备</span>
          <span class="number font_gold">{{dataObj.CanUseDeviceNum}}</span>
        </div>
      </div> -->
      <equipment-authorize-head :titleList="authorizationHead"
                                @chgTitleList="chgTitleList"></equipment-authorize-head>
    </div>
    <div class="table_list_content summarizing_list"
         style="padding-bottom: 40px">
      <table-list ref="tableListRef"
                  :tableData="newTable"
                  :tableColumns="vTypeKeyValue"
                  :rowKey="'Number'"
                  :totalNum="totalNum"
                  :queryParams="params"
                  :footerContent="'共' + totalNum + '条数据'"
                  @loadTableData="getDataList"></table-list>
    </div>

  </div>
</template>
<script>
import { CWSSearchAuthorizedDeviceList, CWSCancelAuthorized, CWSAuditReject, CWSAuditAdopt, WEBVSearchAuthorizedDeviceList, WEBVCancelAuthorized, WEBVAuditReject, WEBVAuditAdopt, SearchAuthorizedDeviceList, CancelAuthorized, AuditReject, AuditAdopt } from '../../../../../API/workbench';
import tableList from '../../../../common/table-list/index';
import equipmentAuthorizeHead from '../../../../pop-up-menu/equipment-authorize-head/index';
export default {
  data () {
    return {
      authorizationHead: [
        { name: '待审核', key: 1, AuditDeviceNum: 0, isSelected: true },
        { name: 'PC', key: 2, AuthorizeDeviceNum: 0, UseDeviceNum: 0, IsLocationLockKey: 0, IsAuthKey: 0, url: '', isSelected: false },
        { name: 'APP', key: 3, AuthorizeDeviceNum: 0, UseDeviceNum: 0, IsLocationLockKey: 0, IsAuthKey: 0, url: '', isSelected: false },
        { name: 'TV', key: 4, AuthorizeDeviceNum: 0, UseDeviceNum: 0, IsLocationLockKey: 0, IsAuthKey: 0, url: '', isSelected: false }
      ],
      searchObj: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: 'asc' //	字符串	可选		数据源：GET	排序顺序
      },
      dataObj: {
        'AuthorizeDeviceList': [
          // {
          //   'OLAPKey': '5',
          //   'Remark': '141241',
          //   'AuditTime': '2021-09-02 17:34:24.573',
          //   'AuditorKey': '0',
          //   'AuditorKeyValue': '',
          //   'AuditStatusKey': '0',
          //   'AuditStatusKeyValue': '未审核',
          //   'ApplicantKey': '33558',
          //   'ApplicantKeyValue': '杜杜',
          //   'DeviceCode': 'DF5631E25C1FA0EA',
          //   'GenerateTime': '2021-09-02 17:34:32.000',
          //   'CoursewarePackageKey': '59'
          // },
          // {
          //   'OLAPKey': '5',
          //   'Remark': '141241',
          //   'AuditTime': '2021-09-02 17:34:24.573',
          //   'AuditorKey': '0',
          //   'AuditorKeyValue': '',
          //   'AuditStatusKey': '1',
          //   'AuditStatusKeyValue': '未审核',
          //   'ApplicantKey': '33558',
          //   'ApplicantKeyValue': '杜222',
          //   'DeviceCode': 'DF5631E25C1FA0EA',
          //   'GenerateTime': '2021-09-02 17:34:32.000',
          //   'CoursewarePackageKey': '59'
          // },
          // {
          //   'OLAPKey': '5',
          //   'Remark': '141241',
          //   'AuditTime': '2021-09-02 17:34:24.573',
          //   'AuditorKey': '0',
          //   'AuditorKeyValue': '',
          //   'AuditStatusKey': '2',
          //   'AuditStatusKeyValue': '未审核',
          //   'ApplicantKey': '33558',
          //   'ApplicantKeyValue': '杜111',
          //   'DeviceCode': 'DF5631E25C1FA0EA',
          //   'GenerateTime': '2021-09-02 17:34:32.000',
          //   'CoursewarePackageKey': '59'
          // }
        ],
        'HasUseDeviceNum': 0,
        'CanUseDeviceNum': 5
      },
      tableColumns: [
        {
          label: '序号',
          width: 50,
          prop: 'Number',
          type: 'number-item',
          sortable: false
        },
        {
          label: '申请人',
          prop: 'ApplicantKeyValue',
          align: 'left',
          width: 85,
          type: 'text-item',
          sortable: false
        },
        {
          label: '设备类型',
          prop: 'DeviceTypeKeyValue',
          width: 50,
          type: 'text-item',
          columnClass: 'font_dual'
        },
        {
          label: '设备名称',
          prop: 'DeviceName',
          width: 155,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '用户备注',
          prop: 'Remark',
          width: 100,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '设备码',
          prop: 'DeviceCode',
          width: 155,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '操作',
          prop: 'option',
          type: 'hidden-btn',
          // fixed: 'right',
          // width: 230,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '通过',
                extend: {
                  isHide: (rowData) => {
                    return rowData.StatusKey != 0;
                  },
                  click: (rowData) => { this.passItem(rowData); },
                  ModulePowerKey: ''
                }
              },
              {
                name: '拒绝',
                extend: {
                  isHide: (rowData) => {
                    return rowData.StatusKey != 0;
                  },
                  click: (rowData) => { this.rejectItem(rowData); },
                  ModulePowerKey: ''
                }
              },
              {
                name: '取消授权',
                extend: {

                  isHide: (rowData) => {
                    return rowData.StatusKey != 1;
                  },
                  click: (rowData) => { this.cancelAuthItem(rowData); },
                  ModulePowerKey: ''
                }
              },
              {
                name: '已拒绝',
                extend: {
                  className: 'font_red',
                  isHide: (rowData) => {
                    return rowData.StatusKey != 2;
                  },
                  click: (rowData) => { },
                  ModulePowerKey: ''
                }
              }

            ]
          }
        }
      ],
      tableColumnsType: [
        {
          label: '序号',
          width: 50,
          prop: 'Number',
          type: 'number-item',
          sortable: false
        },
        {
          label: '申请人',
          prop: 'ApplicantKeyValue',
          align: 'left',
          width: 80,
          type: 'text-item',
          sortable: false
        },
        {
          label: '设备名称',
          prop: 'DeviceName',
          width: 160,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '用户备注',
          prop: 'Remark',
          width: 130,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '设备码',
          prop: 'DeviceCode',
          width: 155,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '最后访问时间',
          prop: 'VisitTime',
          width: 140,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '操作',
          prop: 'option',
          type: 'hidden-btn',
          // fixed: 'right',
          // width: 230,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '通过',
                extend: {
                  isHide: (rowData) => {
                    return rowData.StatusKey != 0;
                  },
                  click: (rowData) => { this.passItem(rowData); },
                  ModulePowerKey: ''
                }
              },
              {
                name: '拒绝',
                extend: {
                  isHide: (rowData) => {
                    return rowData.StatusKey != 0;
                  },
                  click: (rowData) => { this.rejectItem(rowData); },
                  ModulePowerKey: ''
                }
              },
              {
                name: '取消授权',
                extend: {

                  isHide: (rowData) => {
                    return rowData.StatusKey != 1;
                  },
                  click: (rowData) => { this.cancelAuthItem(rowData); },
                  ModulePowerKey: ''
                }
              },
              {
                name: '已拒绝',
                extend: {
                  className: 'font_red',
                  isHide: (rowData) => {
                    return rowData.StatusKey != 2;
                  },
                  click: (rowData) => { },
                  ModulePowerKey: ''
                }
              }

            ]
          }
        }
      ],
      totalNum: 0,
      dataList: [],
      params: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: 'asc' //	字符串	可选		数据源：GET	排序顺序
      }
    };
  },
  components: {
    tableList,
    equipmentAuthorizeHead
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          CoursewarePackageKey: ''
        };
      }
    }
  },
  created () {
    console.log('createddataInfo', this.dataInfo);
    this.getDataList(this.dataInfo.CoursewarePackageKey);
  },
  computed: {
    vSaaSClientEditionKey () {      // 6为课件门店,5为总店
      return this.$store.getters.SaaSClientEditionKey;
    },
    newTable () {
      let newArr = [];
      this.dataObj.AuthorizeDeviceList.forEach((o) => {
        o.VisitTime = o.VisitTime || '';
        o.VisitTime = o.VisitTime.substring(0, 16);
        if (this.dataList.name == '待审核' && o.StatusKey == 0) {
          newArr.push(o);
        }
        if (this.dataList.name == 'PC' && (o.DeviceTypeKey == 0 || o.DeviceTypeKey == 2) && o.StatusKey != 0) {
          newArr.push(o);
        }
        if (this.dataList.name == 'APP' && o.DeviceTypeKey == 3 && o.StatusKey != 0) {
          newArr.push(o);
        }
        if (this.dataList.name == 'TV' && o.DeviceTypeKey == 4 && o.StatusKey != 0) {
          newArr.push(o);
        }
      });
      newArr.forEach((o, index) => {
        o.Number = index + 1;
      });
      this.totalNum = newArr.length;
      return newArr;
    },
    vTypeKeyValue () {
      let key = 1;
      key = this.dataList.key;
      if (key == 1) {
        return this.tableColumns;
      } else {
        return this.tableColumnsType;
      }
    }
  },
  methods: {
    // 分页查询校区端口申请记录
    getDataList (CoursewarePackageKey) {
      let fn = '';
      if (this.vSaaSClientEditionKey == 5) {
        fn = SearchAuthorizedDeviceList;
      } else if (this.vSaaSClientEditionKey == 6) {
        fn = CWSSearchAuthorizedDeviceList;
      } else {
        fn = WEBVSearchAuthorizedDeviceList;
      }
      fn(CoursewarePackageKey).then(result => {
        this.totalNum = result.data.AuthorizeDeviceList.length;
        this.dataObj = result.data;
        this.authorizationHead.forEach((o) => {
          switch (o.name) {
            case '待审核':
              o.AuditDeviceNum = this.dataObj.AuditDeviceNum;
              break;
            case 'PC':
              o.AuthorizeDeviceNum = this.dataObj.AuthorizeDeviceNum;
              o.UseDeviceNum = this.dataObj.PCUseDeviceNum;
              o.IsLocationLockKey = this.dataObj.PCIsLocationLockKey;
              o.IsAuthKey = this.dataObj.IsPCAuthKey;
              o.url = require('../../../../../../public/image/PC-icon.png');
              break;
            case 'APP':
              o.AuthorizeDeviceNum = this.dataObj.APPAuthorizeDeviceNum;
              o.UseDeviceNum = this.dataObj.APPUseDeviceNum;
              o.IsLocationLockKey = this.dataObj.IsLocationLockKey;
              o.IsAuthKey = this.dataObj.IsAPPAuthKey;
              o.url = require('../../../../../../public/image/APP-icon.png');
              break;
            case 'TV':
              o.AuthorizeDeviceNum = this.dataObj.TVAuthorizeDeviceNum;
              o.UseDeviceNum = this.dataObj.TVUseDeviceNum;
              o.IsLocationLockKey = this.dataObj.TVIsLocationLockKey;
              o.IsAuthKey = this.dataObj.IsTVAuthKey;
              o.url = require('../../../../../../public/image/TV-icon.png');
              break;
            default:
              break;
          }
        });
        if (this.dataList.key == undefined) {
          this.dataList.key = 1;
        }
        this.chgTitleList(this.authorizationHead[this.dataList.key - 1]);
        this.dataObj.AuthorizeDeviceList.forEach((o) => {
          if (o.DeviceTypeKey == 2) { o.DeviceTypeKeyValue = 'PC'; }
          if (o.DeviceTypeKey == 4) { o.DeviceTypeKeyValue = 'TV'; }
        });
        console.log('SearchAuthorizedDeviceList', result.data);
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 授权通过
    passItem (item) {
      console.log('passItem', item);
      let fn = '';
      if (this.vSaaSClientEditionKey == 5) {
        fn = AuditAdopt;
      } else if (this.vSaaSClientEditionKey == 6) {
        fn = CWSAuditAdopt;
      } else {
        fn = WEBVAuditAdopt;
      }
      fn(item.OLAPKey).then(result => {
        this.$emit('afterSuccess');
        this.getDataList(this.dataInfo.CoursewarePackageKey);
      }).catch(error => {
        layer.alert(error.msg);
        this.getDataList(this.dataInfo.CoursewarePackageKey);
        console.log('errorpassItem', error);
      });
    },
    // 拒绝授权
    rejectItem (item) {
      console.log('rejectItem', item);
      let fn = '';
      if (this.vSaaSClientEditionKey == 5) {
        fn = AuditReject;
      } else if (this.vSaaSClientEditionKey == 6) {
        fn = CWSAuditReject;
      } else {
        fn = WEBVAuditReject;
      }
      fn(item.OLAPKey).then(result => {
        this.$emit('afterSuccess');
        this.getDataList(this.dataInfo.CoursewarePackageKey);
      }).catch(error => {
        layer.alert(error.msg);
        this.getDataList(this.dataInfo.CoursewarePackageKey);
        console.log('errorrejectItem', error);
      });
    },
    // 取消授权
    cancelAuthItem (item) {
      console.log('cancelAuthItem', item);
      let fn = '';
      layer.confirm('确定要取消授权？', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              if (this.vSaaSClientEditionKey == 5) {
                fn = CancelAuthorized;
              } else if (this.vSaaSClientEditionKey == 6) {
                fn = CWSCancelAuthorized;
              } else {
                fn = WEBVCancelAuthorized;
              }
              fn(item.OLAPKey).then(result => {
                this.$emit('afterSuccess');
                this.getDataList(this.dataInfo.CoursewarePackageKey);
              }).catch(error => {
                layer.alert(error.msg);
                this.getDataList(this.dataInfo.CoursewarePackageKey);
                console.log('errorcancelAuthItem', error);
              });
              layer.close();
            }
          }
        ]
      });
    },
    // 导航栏切换
    chgTitleList (item) {
      this.dataList = [];
      this.dataList = item;
      console.log(item, this.authorizationHead, '导航栏切换');
    }
  }
};
</script>
<style scoped>
.summarizing_list.table_list_content >>> .el-table thead tr th .cell.font_dual {
  display: flex;
  text-align: center;
  align-items: center;
}
.summarizing_list
  >>> .el-table
  .el-table__body-wrapper
  tr
  td:nth-of-type(3)
  .cell {
  width: 155px !important;
}
.summarizing_list
  >>> .el-table
  .el-table__body-wrapper
  tr
  td:nth-of-type(5)
  .cell {
  width: 160px !important;
}
</style>