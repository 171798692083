<template>
  <div class="statistics_echarts_left_btn">
    <div class="statistics_echarts_btn_switchover fl"
         v-if="isType">
      <div class="fl"
           :class="{opt:isMainTalk?1:0}"
           @click.stop="setTime(3)">主讲</div>
      <div class="fl"
           :class="{opt:isMainTalk?0:1}"
           @click.stop="setTime(4)">全部</div>
    </div>
    <div class="statistics_echarts_btn_switchover fl"
         :class="{margin_left:isType}">
      <div class="fl"
           :class="{opt:isNow}"
           @click.stop="setTime(0)">本月</div>
      <div class="fl"
           :class="{opt:isThreeMonth}"
           @click.stop="setTime(1)">近3月</div>
      <div class="fl"
           :class="{opt:isYear}"
           @click.stop="setTime(2)">本年</div>
      <div class="fl"
           @click="isShowSelectDate"
           :class="{opt:isAuto}">自定义</div>
    </div>
    <div class="statistics_echarts_btn_date fr"
         @click="isShowSelectDate">
      <span>日期范围 :</span>
      <div class="date_scope">
        <el-date-picker v-model="sTime"
                        prefix-icon="none"
                        clear-icon="none"
                        type="month"
                        :readonly="true"
                        placeholder="必选"></el-date-picker>
        <span>~</span>
        <el-date-picker v-model="eTime"
                        prefix-icon="none"
                        clear-icon="none"
                        type="month"
                        :readonly="true"
                        placeholder="必选"></el-date-picker>
      </div>
    </div>
    <custom-dialog title="选择日期"
                   :showPosition="'visibleMiddle'"
                   :visible.sync="showSelectDate"
                   :before-close="closeShowSelectDate">
      <div class="select_date_content">
        <div class="select_date_box box_shadow">
          <div class="form_item form-input form_info_line">
            <div class="form_info_field">
              <span>起始日期</span>
            </div>
            <div class="form_info_value form_info_select"
                 @click="showStartTime">
              <el-date-picker clear-icon="none"
                              v-model="startTime"
                              type="month"
                              :picker-options="pickerOptions"
                              placeholder="必选"></el-date-picker>
            </div>
          </div>
          <div class="form_item form-input form_info_line">
            <div class="form_info_field">
              <span>结束日期</span>
            </div>
            <div class="form_info_value form_info_select"
                 @click="showEndTime">
              <el-date-picker clear-icon="none"
                              v-model="endTime"
                              prefix-icon="none"
                              type="month"
                              :picker-options="pickerOptions"
                              placeholder="必选"></el-date-picker>
            </div>
          </div>
        </div>
        <div class="footer_btn_group form_footer_btn_group">
          <el-button round
                     class="btn_light_red btn_hover_white"
                     @click.stop="closeShowSelectDate">取消</el-button>
          <el-button round
                     class="btn_light_blue btn_hover_bg_blue"
                     @click.stop="chgDateComfire">确认</el-button>
        </div>
      </div>
    </custom-dialog>
  </div>
</template>
<script>
export default {
  name: 'multiple-picker',
  data () {
    return {
      startTime: Date.new(Date.new().setDate(1)).toLocaleDateString(),
      endTime: this.setChoseEndTime(Date.new()),
      isNow: true, // 绑定样式显示
      isThreeMonth: false, // 绑定样式显示
      isYear: false, // 绑定样式显示
      isAuto: false, // 绑定样式显示
      isMainTalk: 1, // 主讲全部
      showSelectDate: false, // 时间插件弹出层
      pickerOptions: {
        // 限制日期
        disabledDate: this.disabledDate
      }
    };
  },
  props: ['sTime', 'eTime', 'isType'],
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    }
  },
  methods: {
    disabledDate (time) {
      // if (this.isDisableNow) {
      //   return time.getTime() > Date.new().getTime();
      // } else {
      //   return time.getTime() > Date.new('2099-01-01').getTime();
      // }
      if (this.vMinDate) {
        return Date.new(this.vMinDate).getTime() > time.getTime();
      } else {
        return '';
      }
    },
    // 显示修改时间弹出层  打开弹出层时给startTime,endTime 赋值
    isShowSelectDate () {
      this.startTime = this.sTime;
      this.endTime = this.eTime;
      this.showSelectDate = true;
    },
    // 关闭修改时间弹出层.
    closeShowSelectDate () {
      this.showSelectDate = false;
    },
    // 确认修改时间
    chgDateComfire () {
      if (Date.new(this.startTime) <= Date.new(this.endTime)) {
        this.isNow = false;
        this.isThreeMonth = false;
        this.isYear = false;
        this.isAuto = true;
        this.startTime = Date.new(this.startTime).toLocaleDateString();
        this.endTime = this.setChoseEndTime(Date.new(this.endTime));
        this.$emit('chgDate', this.startTime, this.endTime, this.isMainTalk);
        this.showSelectDate = false;
      } else {
        layer.alert('开始时间不能大于结束时间');
      }
    },
    // 处理本月,近三月,本年查询问题
    setTime (type) {
      switch (type) {
        case 0: // 本月
          this.isNow = true;
          this.isThreeMonth = false;
          this.isYear = false;
          this.isAuto = false;
          this.startTime = Date.new(Date.new().setDate(1)).toLocaleDateString();
          this.endTime = this.setChoseEndTime(Date.new());
          this.$emit('chgDate', this.startTime, this.endTime, this.isMainTalk);
          break;
        case 1: // 近3月
          this.isNow = false;
          this.isThreeMonth = true;
          this.isYear = false;
          this.isAuto = false;
          let Year = Date.new().getFullYear();
          let month = Date.new().getMonth() + 1;
          this.startTime = Date.new(
            Date.new(Year, month - 2, -1).setDate(1)
          ).toLocaleDateString(); // 获取两个月前的第一天.
          console.log(this.startTime);
          this.endTime = this.setChoseEndTime(Date.new());
          this.$emit('chgDate', this.startTime, this.endTime, this.isMainTalk);
          break;
        case 2: // 本年
          this.isNow = false;
          this.isThreeMonth = false;
          this.isYear = true;
          this.isAuto = false;
          this.startTime = Date.new().getFullYear() + '/1/1';
          this.endTime = this.setChoseEndTime(Date.new());
          this.$emit('chgDate', this.startTime, this.endTime);
          break;
        case 3: // 主讲
          this.isMainTalk = 1;
          this.$emit('chgDate', this.startTime, this.endTime, this.isMainTalk);
          break;
        case 4: // 全部
          this.isMainTalk = 0;
          this.$emit('chgDate', this.startTime, this.endTime, this.isMainTalk);
          break;
        default:
          break;
      }
    },
    setChoseEndTime (timer) {
      let Year = timer.getFullYear();
      let Month = timer.getMonth() + 1;
      let EndDate = Date.new(Year, Month, 0).getDate();
      return Year + '/' + Month + '/' + EndDate;
    },
    showStartTime () {
      console.log(this.$el);
      this.$el.querySelector('.el-input__inner').focus();
    },
    showEndTime () {
      console.log(this.$el);
      this.$el.querySelector('.el-input__inner').focus();
    }
  }
};
</script>

