<template>
  <div>
    <div>
      <custom-dialog title="选择学生"
                     class="student_info_dialog defult_dialog"
                     :visible.sync="isShowAddStudent"
                     :before-close="closeAddStudent">
        <search-box :butMessage="'新增学生'"
                    :searchInitJson="searchDialogInitJson"
                    @searchClick="searchClick"
                    @onSelectedStudent="doAfterSelectedStudent"></search-box>
      </custom-dialog>
    </div>
    <!-- 学生列表 -->
    <div>
      <custom-dialog title="学生列表"
                     class="refund_dialog defult_dialog"
                     :visible.sync="isShowStudentList"
                     :before-close="closeDialogForStudentList">
        <div>
          <student-list :isShowSearchInput="true"
                        @selectedItem="doAfterSelectedStudent"
                        ref="studentListDialog"></student-list>
        </div>
      </custom-dialog>
    </div>
    <!-- 提现金额页面 -->
    <div>
      <custom-dialog title="提现"
                     class="refund_dialog defult_dialog"
                     :visible.sync="isShowCashWithdrawal"
                     :before-close="closeCashWithdrawal">
        <div>
          <student-lable ref="studentLableBox"
                         class="form_info_top content_marginRL content_marginTop_30 "
                         :studentID="studentInfo.OLAPKey"
                         @alterStudent="alterStudent"
                         :showMenu="true"></student-lable>
          <payment-and-withdraw ref="paymentandwithdraw"
                                :payOrWithdrawMethods="'付款方式'"
                                :amount="'提现金额'"
                                :canAmount="'可提现金额'"
                                :allClean="'全部提现'"
                                :studentItem="studentInfo"
                                @closeshowWithDraw="closeCashWithdrawal"
                                @showsucceedPayment="showsucceedPayment"
                                :cashRepayment="studentInfo.SumAmount">
          </payment-and-withdraw>
        </div>
      </custom-dialog>
    </div>
    <!-- 还款成功 -->
    <div>
      <custom-dialog title="成功"
                     class="refund_dialog defult_dialog"
                     :visible.sync="isShowSucceedPayment"
                     :before-close="closedsucceedPayment">
        <succeed-payment @closedsucceedPayment="closedsucceedPayment"></succeed-payment>
      </custom-dialog>
    </div>
  </div>
</template>
<script>
import searchBox from './search-box';
import studentLable from './student-lable';
import studentList from './student-list';
import paymentAndWithdraw from './payment-and-withdraw';
import succeedPayment from './succeed-payment';
export default {
  data () {
    return {
      isShowStudentList: false,
      searchDialogInitJson: { name: '目标学生', isShowAddBtn: false, roleName: '' },
      studentInfo: {},
      detailAmount: 0,             // 还款的详情.
      isShowCashWithdrawal: false,
      isShowSucceedPayment: false,
      isShowAddStudent: false
    };
  },
  props: ['studentItem', 'isShowCashWithdrawals'],
  components: {
    searchBox, studentList, paymentAndWithdraw, succeedPayment, studentLable
  },
  computed: {
  },
  watch: {
    studentItem (n, o) {
      this.studentInfo = n;
    },
    isShowCashWithdrawals () {
      if (this.isShowCashWithdrawals == undefined) {
        this.isShowAddStudent = JSON.stringify(this.studentInfo) == '{}';
      } else {
        this.isShowAddStudent = this.isShowCashWithdrawals;
      }
    }
  },
  created () {
    this.studentInfo = this.studentItem;
    if (this.isShowCashWithdrawals == undefined) {
      this.isShowAddStudent = JSON.stringify(this.studentInfo) == '{}';
    } else {
      this.isShowAddStudent = this.isShowCashWithdrawals;
    }
  },
  methods: {
    alterStudent (item) {
      this.studentInfo = item;
      this.$refs.paymentandwithdraw.cashAmount = 0;
      this.$refs.paymentandwithdraw.studentInfo = item;
    },
    // 查询数据 弹出列表
    searchClick () {
      // 调用接口查询学生列表 待完成
      this.isShowStudentList = true;
    },
    // 关闭学生列表
    closeDialogForStudentList () {
      this.isShowStudentList = false;
    },
    // 选中学生后
    doAfterSelectedStudent (val) {
      this.closeDialogForStudentList();
      this.isShowCashWithdrawal = true;
      this.isShowAddStudent = false;
      this.studentInfo = val;
    },
    // 关闭成功提示页面
    closedsucceedPayment (booler) {
      this.isShowSucceedPayment = false;
      this.$emit('cashWithdrawalSuccess');
    },
    // 提现成功
    showsucceedPayment (Val) {
      this.isShowCashWithdrawal = false;
      this.isShowSucceedPayment = true;
    },
    // 关闭提现页面
    closeCashWithdrawal () {
      this.isShowCashWithdrawal = false;
      this.closeAddStudent();
    },
    // 显示学员档案.
    showStudentFile (item) {
      this.$emit('showStudentFile', item);
    },
    closeAddStudent () {
      this.$emit('closeCashWithdrawal');
    }
  }
};
</script>

