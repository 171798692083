<template>
  <div style="position: absolute;">
    <div class="courseware_pic_upload"
         :class="isDefualtSet?'forWidth':'forHeight'"
         style="position: relative;top: 15px;left: 0;right: 0;bottom: 0;width: 300px;
    height: 168px;">
      <div class="input_img_set"
           v-if="formTitle || helpDescription">
        <span>{{ formTitle }} </span>
        <span v-if="helpDescription">
          <input-form-tip :tips="helpDescription"></input-form-tip>
        </span>
      </div>
      <div class="img_defualt">
        <span class="img_defualt_span"> <span style="font-size: 12px;">＋</span style="font-size: 12px;">添加图片</span>
      </div>
      <img class="deleteCoverButtom"
           v-if="ImgURl"
           @click.stop="deleteCover"
           :src="require('../../../../../public/image/delete_courseware.png')"
           :onerror="defaultImg" />
      <div class="fr"
           style="z-index: 11;margin: auto;">

        <!-- 显示 -->
        <!-- <img :style="{ width:imgWidth+'px', height:imgHeight+'px', 'position': 'absolute', 'opacity': 0}"> -->
        <!-- <img :src="isStudentWork?imgURlString:headImg"
             :onerror="defaultImg"
             alt="../../../public/image/no_logo@2x.png"
             class="my_datum_picture_img_text"
             :style="!isStudentWork?imgstyleSize:'width: 100%!important;height: 100% !important;    max-height: 220px !important;max-width: 384px!important;'"
             crossOrigin="anonymous" /> -->
        <!-- 上传 -->
        <input type="file"
               v-if="!readonly&&!isStudentWork"
               ref="uploading"
               name="file"
               title=""
               @change="selectImg($event)"
               class="img-logo"
               data-type="userIconImg"
               :accept="acceptType" />
      </div>

    </div>
    <custom-dialog :title="'裁切图片'"
                   class=""
                   :visible.sync="isChgPic"
                   :before-close="closeChgPic">
      <vue-crop-img :selectPicUrl="selectPicUrl"
                    :cutImgWidth="imgWidth"
                    :cutImgHeight="imgHeight"
                    :saaSClientNameKey="saaSClientNameKey"
                    @onConfirmClick="setPicCorp"
                    @onCancelClick="clearPicData"></vue-crop-img>
    </custom-dialog>
  </div>
</template>
<script>
import { UploadFiles } from '../../../../API/workbench';
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: 'inputSelect',
  data () {
    return {
      selectPicUrl: '', // 选中图片url
      isChgPic: false,
      imgURlString: this.ImgURl || '',
      imgstyleSize: this.imgstyle // 裁切大小
    };
  },
  props: {
    ImgURl: String, //
    formTitle: String,

    imgHeight: {
      // 裁切的图片高度，默认210，
      type: Number,
      default: 101
    },
    imgWidth: {
      // 裁切的图片宽度，默认320，
      type: Number,
      default: 101
    },
    imgstyle: {
      // 默认img样式，
      type: String,
      default: 'width: 38px;height: 38px;border-radius: 50px'
    },
    readonly: {
      // 是否只读，
      type: Boolean,
      default: false
    },
    required: {
      // 是否必填，
      type: Boolean,
      default: false
    },
    isStudentWork: {
      // 是否从学生作品入口进来，
      type: Boolean,
      default: false
    },
    acceptType: {
      // 是否必填，
      type: String,
      default: ''
    },
    isDefualtSet: {
      type: Boolean,
      default: false
    },
    helpDescription: String, // 表单说明提示
    saaSClientNameKey: [Number, String] // 上传到指定门店
  },
  computed: {
    headImg () {
      if (!this.imgURlString) {
        this.imgURlString = '';
      }
      if (this.imgURlString.indexOf('http') > -1) {
        return this.imgURlString;
      } else {
        return this.$store.getters.CDNURL + this.imgURlString;
      }
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' + require('../../../../../public/image/no_logo@2x.png') + '"'
      );
    }
  },
  created () { },
  watch: {
    ImgURl (n, c) {
      this.imgURlString = n;
    }
  },
  methods: {
    closeChgPic () {
      this.isChgPic = false;
    },
    selectImg (e, type) {
      let fileName = e.target.files[0].name;
      let typeText = '.jpg,.jpeg,.png';
      if (
        typeText.indexOf(
          fileName.substring(
            fileName.toLowerCase().lastIndexOf('.'),
            fileName.length - 1
          )
        ) == -1
      ) {
        this.clearPicData();
        layer.alert('上传文件类型不符合，只能上传.jpg,.jpeg,.png类型文件');
        return false;
      } else {
        if (e.target.files.length > 0) {
          var reader = new FileReader(); // 实例化一个FileReader对象，用于读取文件
          // 读取File对象的数据
          var that = this;
          reader.onload = function (evt) {
            console.log('eevt', evt);
            that.selectPicUrl = evt.target.result;
            that.$nextTick(() => {
              console.log('that.selectPicUrl', that.selectPicUrl)
              that.isChgPic = true;
              if (that.isStudentWork) {
                that.imgURlString = evt.target.result;
                that.$emit('doSetStudentWorks', that.selectPicUrl);
              }
            });
          };
          reader.readAsDataURL(e.target.files[0]);
        }
      }
    },
    deleteCover () {
      this.$emit('deleteCover')
    },
    uploadingFile (e, type) {
      console.log(e, 'uploadingFile');
      // 文件对象
      let typeNum = 6;
      var form = e.target.files[0];
      var formData = new FormData();
      // 文件对象
      formData.append('file', form);
      // 防止文件取消时
      if (form) {
        UploadFiles(typeNum, '', formData).then(result => {
          console.log(result.data, 'UploadFiles');
          console.log(typeNum, 'formData', formData);
          this.$nextTick(() => {
            this.$emit('doSetStudentWorks', result.data.url);
          });
        }, err => {
          layer.alert(err.msg);
        });
      }
    },
    // 确认裁切
    setPicCorp (imgUrl) {
      console.log('imgUrl', imgUrl);
      this.imgURlString = imgUrl;
      this.clearPicData();
      this.$emit('doSetPicCorp', this.imgURlString);
    },
    clearPicData () {
      this.isChgPic = false;
      this.selectPicUrl = '';
      this.$refs.uploading.value = '';
    }
  }
};
</script>

 <style scoped>
.img_defualt {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background: linear-gradient(0deg, #e8ebef, #e8ebef),
    linear-gradient(0deg, #f9fbfd, #f9fbfd);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.img_defualt_span {
  color: rgb(12, 147, 237);
  font-size: 16px;
}
.deleteCoverButtom {
  position: absolute;
  top: 5px;
  right: 5px;
  pointer-events: auto;
  z-index: 10;
  opacity: 0;
  cursor: pointer;
}
.deleteCoverButtom:hover {
  z-index: 12;
  opacity: 1;
}
.forWidth {
  width: 200px !important;
  height: 112.5px !important;
}
.forHeight {
  width: 112.5px !important;
  height: 112.5px !important;
}
.courseware_pic_upload:hover .deleteCoverButtom {
  opacity: 1;
}
</style>