<template>
<div>
  <input type="number"
             :class="className"
             :placeholder="placeholder"
             :value="vInputValue"
             @click="clickEvent($event)"
             @input="inputEvent($event.target.value,$event)"
             @change="changeEvent($event.target.value,$event)"
             onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/\.{2,}/g,'.')" />
</div>
</template>

<script>
export default {
  data () {
    return {
      inputValue: 0,
      defaultValue: 0, // 默认值,
      defaultDecimalPlaces: 2 // 默认值
    };
  },
  props: {
    maxlength: [Number, String], // 文本输入位数
    max: [Number, String], // 最大值
    min: [Number, String], // 最小值
    value: [Number, String], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    placeholder: String, // 提示 文本，可选，如不提供，则默认显示title
    decimalPlaces: Number, // 保留的小数位数
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证,
    maxLength: {
      type: Number,
      default: 12
    },
    className: {
      type: String,
      default: ''
    }
  },
  computed: {
    vInputValue: {
      get () {
        if (this.inputValue == '0' || this.inputValue) {
          return this.inputValue;
        } else {
          return '';
        }
      },
      set (n, o) {
        this.inputValue = n;
      }
    },
    vDecimalPlaces () {
      if (this.decimalPlaces == 0) {
        return 0;
      } else {
        return this.decimalPlaces || this.defaultDecimalPlaces;
      }
    }
  },
  created () {
    this.setInputValue(this.value);
  },
  watch: {
    value (c, o) {
      this.setInputValue(c);
    }
  },
  methods: {
    setInputValue (v) {
      this.inputValue = this.$utils.mMoneyType(v, this.vDecimalPlaces);
      this.inputValue = this.inputValue || this.defaultValue;
    },
    inputEvent (val, e) {
      if (val.length > this.maxLength) {
        e.target.value = this.inputValue;
        return false;
      }
      if (val.length > this.maxLength) {
        e.target.value = this.inputValue;
        return false;
      }
      val = val > Number(this.max) ? Number(this.max) : val;
      val = val < Number(this.min) ? Number(this.min) : val;
      this.inputValue = val;
      e.target.value = this.inputValue;
      this.$emit('input', this.inputValue);
      if (!this.$utils.isIntermediateValue(this.inputValue)) {
        this.$emit('inputChange', Number(this.inputValue));
      }
    },
    changeEvent (val, e) {
      if (val.length > this.maxLength) {
        e.target.value = this.inputValue;
        return false;
      }
      this.inputValue = this.$utils.mMoneyType(val, this.vDecimalPlaces);
      this.inputValue = this.inputValue > Number(this.max) ? Number(this.max) : this.inputValue;
      this.inputValue = this.inputValue < Number(this.min) ? Number(this.min) : this.inputValue;
      e.target.value = this.inputValue;

      this.$emit('change', Number(this.inputValue));
      this.$emit('input', Number(this.inputValue));
      this.$emit('inputChange', Number(this.inputValue));
      this.$emit('changeEvent', Number(this.inputValue));
    },
    clickEvent (event) {
      event.currentTarget.select();
    }
  }
}
</script>

<style scoped>
.YK_added_content_form .form_list_input {
  width: 178px;
}
</style>