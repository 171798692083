<template>
  <div :class="{'import_list':rowType=='add','table_td':rowType!='add' }"
       v-if="!rowData.Number"
       style="width:154px"></div>
  <div :class="{type_green:vIsShowCorrect,'import_list':rowType=='add','table_td':rowType!='add' }"
       style="width:154px"
       ref="boxOffsetHeight"
       v-else>
    <div v-if="rowData.ExceptionList.length>0">
      <!-- 异常栏显示 -->
      <div class="type_red"
           v-for="(item,index1) in rowData.ExceptionList"
           :key="index1">
        <el-tooltip popper-class="mendian_block_tooltip"
                    effect="light"
                    visible-arrow="false"
                    :content="item.TypeKeyValue"
                    :disabled="item.TypeKeyValue.length>8?false:true"
                    placement="top">
          <div>{{item.TypeKeyValue}}</div>
        </el-tooltip>

      </div>
    </div>
    <!-- 警告栏显示 -->
    <div v-if="rowData.WarningList.length>0">
      <div class="type_yellow"
           v-for="(item,index2) in rowData.WarningList"
           :key="index2">
        <el-tooltip popper-class="mendian_block_tooltip"
                    effect="light"
                    visible-arrow="false"
                    :content="item.TypeKeyValue"
                    :disabled="item.TypeKeyValue.length>8?false:true"
                    placement="top">
          <div>{{item.TypeKeyValue}}</div>
        </el-tooltip>
      </div>
    </div>
    <div v-if="vRowType&&!vIsShowCorrect">
      <input type="text"
             placeholder="系统检测异常提醒"
             readonly="true">
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },
  props: {
    rowType: {
      type: String,
      default: ''
    }, // 输出类型
    rowData: Object,
    tableData: Array,
    courseNameList: Array,
    isShowPart: Boolean
  },
  computed: {
    vRowType () {
      if (this.rowType == 'add' &&
        this.rowData.ExceptionList.length == 0 &&
        this.rowData.WarningList.length == 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    vIsShowCorrect () {
      let flag = false;
      if ((this.rowData && this.rowData.guid && (this.rowData.ExceptionList.length == 0 && this.rowData.WarningList.length == 0))) {
        flag = true;
      }
      return flag;
    }
  },
  watch: {
    rowData: {
      handler (newVal, oldVal) {
        if (!newVal.optioningRow) {
          if (this.rowData.guid && this.rowData.ApplyClassKey && (this.rowData.CurrentCount === '' || this.rowData.CurrentCount === null)) {
            let emptyErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 9 && obj.TypeKey == 5; });
            if (emptyErrorIndex < 0) {
              this.rowData.ExceptionList.push({ TargetField: 9, TypeKey: 5, TypeKeyValue: '剩余课时未填写' });
            }
          }
        }
      },
      deep: true // 表示开启深度监听
    }
  },
  created () {
  },
  mounted () {
    if (this.$refs.boxOffsetHeight && this.rowData.Number) {
      this.rowData.offsetHeight = this.$refs.boxOffsetHeight.offsetHeight;
    }
  },
  methods: {

  }
};
</script>

<style>
</style>