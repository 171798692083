<template>
  <!-- 套餐内容 -->
  <div class="package_details_ul"
       @scroll="scrollTOP"
       :class="{'package_revise_ul':taocanType!='view'}">
    <!-- 课单 -->
    <div v-if="taocanInfo.KDNRList.length>0">
      <course-content v-for="(item,index) in taocanInfo.KDNRList"
                      :key="index"
                      :index='index'
                      :item="item"
                      :taocanType="taocanType"
                      @showMenu="showMenu"
                      @edit="doEditClick"
                      @delete="doDeleteClick"></course-content>
    </div>
    <!-- 杂费 -->
    <div v-if="taocanInfo.ZFList.length>0">
      <incidentals-content v-for="(item,index) in taocanInfo.ZFList"
                           :key="index"
                           :index='index'
                           :item="item"
                           :taocanType="taocanType"
                           @showMenu="showMenu"
                           @edit="doEditClick"
                           @delete="doDeleteClick"></incidentals-content>
    </div>
    <div v-if="isShowMenu"
         class="module_content_menuList"
         :style="'top:'+newOffsetHeight+'px;'+'left:312px;'+'width: 120px;'">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="menuList"
                     @clickOptionForAlertDialog="onItemClick"></drop-down-box>
    </div>
  </div>
</template> 

<script>
import { GetTheClassType } from '../../../../../../API/workbench';
import courseContent from './course-content';
import incidentalsContent from './incidentals-content';
import dropDownBox from '../../../../../common/drop-down-box';
export default {
  data () {
    return {
      isShowMenu: false, // 套餐项操作菜单
      newOffsetHeight: 0, // 高度位置偏移量
      newType: '',
      newItem: {},
      newIndex: 0,
      scrollTop: 0,
      menuList: [
        { key: 1, name: '修改', eventName: 'edit', isShow: true },
        { key: -1, name: '删除', eventName: 'delete', isShow: true }
      ]
    };
  },
  props: {
    taocanType: {// 套餐标题，必填
      type: String,
      required: ''
    },
    taocanInfo: Object // 套餐信息 （必填）
  },
  components: {
    courseContent,
    incidentalsContent,
    dropDownBox
  },
  created () {
    console.log(this.taocanInfo, '套餐信息-123');
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isShowMenu = false;
    }, false);
  },
  watch: {

  },
  computed: {

  },
  methods: {
    // 高亮显示
    showMenu (index, item, type) {
      this.newType = type;
      this.newItem = item;
      this.newIndex = index;
      console.log(index, '计算偏移量');
      if (this.newType == 'incidentals') {
        index = (index + this.taocanInfo.KDNRList.length);
      }
      this.newOffsetHeight = 185 + index * 77 - this.scrollTop + index * 1.4;/// / 第一项的初始高度是185， index算出该项的偏移的高度，scrollTop为滚动条滚动的距离
      this.isShowMenu = true;
      this.$parent.isShowItem = false;
    },
    // 操作项
    onItemClick (item) {
      console.log(item, '编辑');
      if (item.eventName == 'edit') {
        if (this.newType == 'incidentals') { // 杂费修改
          this.doEditClick(this.newType, this.newIndex, this.newItem);
        } else { // 课单修改
          if (this.newItem.ApplyCorIDName.indexOf(',') != -1) {
            this.doEditClick('multi', this.newIndex, this.newItem);
          } else {
            GetTheClassType(this.newItem.ApplyCorIDName).then(res => {
              if (Number(res.data.TypeKey) == 1 || res.data.OLAPKey == 2) { // 1为课类，2为课名 OLAPKey为2时为全部课类
                this.doEditClick('multi', this.newIndex, this.newItem);
              } else {
                this.doEditClick('single', this.newIndex, this.newItem);
              }
            }, err => {
              layer.alert(err.msg);
            });
          }
        }
      } else { // 删除
        this.doDeleteClick(this.newIndex, this.newItem, this.newType);
      }
      this.isShowMenu = false;
    },
    // 编辑
    doEditClick (type, index, item) {
      this.$emit('doEditClick', type, index, item);
    },
    // 删除
    doDeleteClick (index, item, type) {
      this.$emit('doDeleteClick', index, item, type);
    },
    // 滚动加载事件.
    scrollTOP (e) {
      this.scrollTop = e.target.scrollTop;
      this.isShowMenu = false;
    }
  }
};
</script>

  <style>
/*  */
</style>