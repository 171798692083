<template>
  <!-- 修改 考勤 弹窗主内容 -->
  <div class="single_result_detail">
    <student-lable ref="studentLableBox"
                   class="content_marginRL single_shadow"
                   :studentID="studentItem.OLAPKey"
                   :mobileSupport="studentItem.MobileSupport"
                   @changeStudent="changeStudent"
                   :showMenu="true"
                   @alterStudent="alterStudent">
    </student-lable>
    <!-- 修改考勤可操作列表 -->
    <div class="changeAttendance_content content_marginRL single_shadow"
         v-if="studentAttendanceList.length>0">
      <div class="changeAttendance_table">
        <!-- 表头 -->
        <div class="changeAttendance_thead">
          <div class="changeAttendance_courseData thead_field">上课日期</div>
          <div class="changeAttendance_courseName thead_field">课名</div>
          <div class="changeAttendance_courseTime thead_field">扣课时</div>
          <div class="changeAttendance_option thead_field">考勤</div>
          <div class="changeAttendance_optionbtn thead_field"></div>
        </div>
        <!-- 内容 -->
        <div class="changeAttendance_tbody">
          <!-- 单行 -->
          <div class="changeAttendance_tbody_line"
               v-for="(item,index) in studentAttendanceList"
               @click.stop="amendItem(item)"
               :key="index">
            <div class="changeAttendance_courseData tbody_value">
              <span class="">{{Date.new(item.ClassTime).toLocaleDateString()}}</span>
            </div>
            <div class="changeAttendance_courseName tbody_value">
              <span class="">{{item.CourseNameKeyValue}}</span>
            </div>
            <div class="changeAttendance_courseTime tbody_value">
              <!-- item.IsTrialKey == 1  -->
              <span class=""
                    v-if="(item.ClassHourKey == 5 ||item.ISClassHourDebtKey==1)&&item.IsTrialKey != 1">{{item.ClassCount}}课时</span>
              <span v-else>0课时</span>
              <span v-if="item.IsTrialKey == 1"
                    class="info_detail_green">(试)</span>
              <span v-if="oweClassCount(item)"
                    class="info_detail_red">(欠)</span>
            </div>
            <div class="changeAttendance_option tbody_value">
              <div class="">
                <span class="sign_type_attendance course_student_sign_type"
                      v-if="item.AttenceStatusKey ==3">{{item.AttenceStatusKeyValue?'签到':"无状态"}}</span>
                <span class="sign_type_truancy course_student_sign_type"
                      v-else-if="item.AttenceStatusKey ==5">{{item.AttenceStatusKeyValue?item.AttenceStatusKeyValue:"无状态"}}</span>
                <span class="sign_type_ask_leave course_student_sign_type"
                      v-else-if="item.AttenceStatusKey ==4">{{item.AttenceStatusKeyValue?item.AttenceStatusKeyValue:"无状态"}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="monitoring_content_nodata">
        <div class="monitoring_content_nodata_txt">该学生暂无考勤</div>
      </div>
    </div>
  </div>
</template>
<script>
import studentLable from '../../../common/student-lable';
export default {
  name: 'selectAttendanceItem',
  props: {
    studentItem: Object, // 考勤学生
    studentAttendanceList: Array // 考勤列表
  },
  data () {
    return {
      isShow: true,
      getTimeitem: '', // 日期
      searchInfo: {
        year: '',
        month: ''
      }
    };
  },
  components: {
    studentLable
  },
  created () {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    oweClassCount (item) {        // 判断是否欠课时显示欠字
      if (item.ISClassHourDebtKey == 1) {
        return true;
      } else {
        return false;
      }
    },
    amendItem (item) {
      console.log(item, '课消');
      this.$emit('amendItemShow', item);
    },
    changeStudent () {
      this.$emit('changeStudent');
    },
    alterStudent (item) {
      this.studentItem.OLAPKey = item.OLAPKey;
      this.$emit('updateAttenHistory');
    }
  }

};
</script>