<template>
  <div class="courseware_textarea_box">
    <div class="textarea_title">
      <div style="line-height: 35px;">课后作业</div>
      <div class="btn_hover_bg_blue"
           @click="saveCourseEvaluation">保存</div>
           <div v-if="dataInfo&&dataInfo.id != 0 && dataInfo.Title.length > 0"
         class="textarea_remove_btn"
         @click="deleteCourseEvaluation"></div>
    </div>
    
    <div class="textarea_box">
      <release-content ref="releaseContent"
                       :dataInfo="editInfo"
                       :maxLength="1000"
                       :settingInfo="settingInfo"></release-content>
    </div>
  </div>
</template>
<script>
import releaseContent from '../../../home-school-communication/common-component/release-content/index';
import {
  coursewareAddHomeWork,
  CWScoursewareAddHomeWork,
  WEBVcoursewareAddHomeWork,
  coursewareEditHomeWork,
  CWScoursewareEditHomeWork,
  WEBVcoursewareEditHomeWork,
  coursewareDeleteHomeWork,
  CWScoursewareDeleteHomeWork,
  WEBVcoursewareDeleteHomeWork
} from '../../../../API/workbench';
export default {
  data () {
    return {
      editInfo: {},
      settingInfo: {
        formTitle: '作业标题',
        contentTitle: '作业内容',
        formTitleplaceholder: '请输入作业标题',
        placeholder: '请输入作业内容',
        showAddAudio: true,
        showAddPic: true,
        addVideoType: 1,
        isSaveUpload: false
      }
    };
  },
  components: {
    releaseContent
  },
  props: {
    dataInfo: [Array,Object],
    coursewareId: [String, Number]
  },
  watch: {
    dataInfo (n, o) {
      if (n && o) {
        // console.log('nnnnn',this.dataInfo)
        // if(this.dataInfo.length==0){
        //   this.editInfo = this.$utils.parseJson({
        //       id: '',
        //       Title: '',
        //       Content: '',
        //       AttachList: []
        //     });
        // }else{
          this.editInfo = this.$utils.parseJson(this.dataInfo);
        this.$refs.releaseContent.editText();
        // }
       
      }
    }
  },
  created () {
    
          this.editInfo = this.$utils.parseJson(this.dataInfo);

   
  },
  methods: {
    deleteCourseEvaluation () {
      layer.confirm('是否删除当前内容', {
        btn: [
          {
            name: '取消',
            callBack: () => {
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              layer.close();

              let apiFN = '';
              let type = this.$store.getters.SaaSClientInfo.EditionKey;
              if (type == 6) {
                apiFN = CWScoursewareDeleteHomeWork;
              } else if (type == 5) {
                apiFN = coursewareDeleteHomeWork;
              } else {
                apiFN = WEBVcoursewareDeleteHomeWork;
              }
              apiFN(this.coursewareId).then(
                (res) => {
                  // this.editInfo=
                  // this.editInfo = this.$utils.parseJson({
                  //     id: '',
                  //     Title: '',
                  //     Content: '',
                  //     AttachList: []
                  //   });
                  this.$emit('upHomeworkInfo');
                },
                (err) => {
                  console.log(err.msg);
                }
              );
            }
          }
        ]
      });
    },
    saveCourseEvaluation () {
      if (this.editInfo.Content.length > 1000) {
        // this.editInfo.Content = this.editInfo.Content.substring(0, 1000);
        layer.alert('内容不能超过1000字');
        return false;
      }
      if (this.editInfo.Title.length == 0) {
        layer.alert('请输入课后作业标题');
        return false;
      } else if (this.editInfo.Content.length == 0) {
        layer.alert('请输入课后作业内容');
        return false;
      }
      let imgNumber = 0;
      let upload;
      this.editInfo.AttachList.forEach((e) => {
        if (e.AttachTypeKey == 1) {
          imgNumber++;
        }
        console.log(e.percent, 'e.percent');
        if (Number(e.percent) < 100) {
          upload = true;
        }
      });
      if (upload) {
        layer.alert('有附件正在上传');
        return false;
      }
      if (imgNumber > 12) {
        layer.alert('上传图片超过12张，请删除超过的图片');
        return false;
      }
      const uploadingVideoList = this.editInfo.AttachList.filter(obj => {
        return obj.AttachTypeKey == 2 && obj.VideoCoverUrl == '';
      });
      if (uploadingVideoList.length > 0) {
        layer.alert('视频正在上传中，请稍后提交');
        if (typeof callBack === 'function') {
          callBack();
        }
        return false;
      }
      if (this.isSaveUpload) {
        return false;
      }
      if (this.dataInfo&&this.dataInfo.id == 0) {
        this.isSaveUpload = true;
        this.$nextTick(() => {
          let apiFN = '';
          let type = this.$store.getters.SaaSClientInfo.EditionKey;
          if (type == 6) {
            apiFN = CWScoursewareAddHomeWork;
          } else if (type == 5) {
            apiFN = coursewareAddHomeWork;
          } else {
            apiFN = WEBVcoursewareAddHomeWork;
          }
          apiFN(this.coursewareId, this.editInfo).then(
            (res) => {
              this.isSaveUpload = false;
              this.$emit('upHomeworkInfo');
            },
            (err) => {
              this.isSaveUpload = false;
              layer.alert(err.msg);
            }
          );
        });
      } else {
        this.isSaveUpload = true;
        let apiFN = '';
        let type = this.$store.getters.SaaSClientInfo.EditionKey;
        if (type == 6) {
          apiFN = CWScoursewareEditHomeWork;
        } else if (type == 5) {
          apiFN = coursewareEditHomeWork;
        } else {
          apiFN = WEBVcoursewareEditHomeWork;
        }
        apiFN(this.coursewareId, this.editInfo).then(
          (res) => {
            this.isSaveUpload = false;
            this.$emit('upHomeworkInfo');
          },
          (err) => {
            this.isSaveUpload = false;
            layer.alert(err.msg);
          }
        );
      }
    }
  }
};
</script>
<style scoped>
.textarea_remove_btn{
  top: 8px!important;
    right: 80px!important;
}
.courseware_textarea_box{
  height:inherit !important;
  padding-top: 0!important;
  padding: 0 0 !important;
  margin: 0 20px;
}
.textarea_title{
  position: relative;
  height: 50px !important;
  padding-top: 0!important;
}
</style>