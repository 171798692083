<template>
  <div class="pr"
       v-if="Number(studentInfo.OLAPKey)>0">
    <div class="student_courses_arranging_info">
      <div class="student_label single_shadow"
           :style="styleInfo">
        <div class="single_result_info">
          <div class="single_student">
            <div class="student_courses_img fl">
              <heads-img v-if="showHeadsImg"
                         :imgUrl="studentInfo.HeadImgSupport"
                         :showUp="false"
                         :showUpInput="true"
                         :dataInfo="studentInfo"></heads-img>
              <div class="student_courses_img_sex"
                   :class="studentInfo.SexKeyValue == '男'?'boy':'gril'"></div>
            </div>
            <div class="student_info fl">
              <div class="student_label_name">
                <span class="student_info_name_content">{{studentInfo.MainDemoName || studentInfo.NickName}}</span>
                <span class="student_info_age">{{$utils.phoneModulePower(studentInfo.MobileSupport)}}</span>
                <span :class="Number(studentInfo.SubscribeKey)>0?'td_WX_open':'td_WX_close'"></span>
                <!-- 编辑按钮 -->
                <!-- <div v-if="showMenu"
                     @click="isShowPopup = true"
                     class="student_label_menu"></div> -->
              </div>
              <div v-if="studentInfo.IsActiveKey == 0 && studentInfo.StudentTypeKey == 3"
                   class="student_label_patriarch">
                <span class="font_black">未报名的潜客</span>
              </div>
              <div v-else-if="studentInfo.IsActiveKey == 0 && studentInfo.StudentTypeKey == 2"
                   class="student_label_patriarch ">
                <span class="font_black">已离校
                  <span class="font_red"
                        v-show="vLeaveDate > 0">{{vLeaveDate}}</span>
                  <span v-show="vLeaveDate != ''">天</span>
                </span>
              </div>
              <div v-else
                   class="student_label_patriarch"
                   ref="studentLabelPatriarch"
                   :class="{show_text:isPatriarchCourse}">
                可用课时
                <span :class="studentInfo.CurrentClassHourCount > 0||studentInfo.YearCardMaxDayCount>0?'font_black':'font_red'"> {{vCurrentClassHourCount}} </span>
                <span class="">| 在读</span>
                <span v-if="studentInfo.ReadingCourseList && studentInfo.ReadingCourseList.length >= 0"
                      class="patriarch_course_ul font_black"
                      ref="patriarchCourseUl">
                  <span v-for="(item,index) in studentInfo.ReadingCourseList"
                        :key="index">{{item.ApplyClassKeyValue}} </span>
                </span>
                <span v-else
                      class="font_black">--</span>
                <div class="arrange_open"
                     v-show="!isPatriarchCourse && studentInfo.ReadingCourseList && studentInfo.ReadingCourseList.length >= 0"
                     @click="showPatriarchCourse"
                     :class="{pa:isPatriarchCourseText}"></div>
              </div>
              <div @click.stop="showAlterMessagePopup"
                   class="student_label_arrange">
                <span class="arrange_content"
                      ref="arrangeContent"
                      :class="{font_gray:!studentInfo.ArrangeSupport,show_text:isShowArrangeText}">
                  <span ref="arrangeContentText"
                        id="arrangeContentText">{{studentInfo.ArrangeSupport || '排课备注'}}</span>
                  <div class="arrange_pa_box"
                       :class="{pa:isArrangeTextPa}">
                    <div v-show="isArrangeTextPa"
                         @click.stop="arrangeShowText"
                         class="arrange_open"></div>
                    <div class="arrange_btn"
                         ref="arrangeBtn"></div>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <!-- 编辑按钮 -->
          <div v-if="showMenu"
               @click.stop="showAlertDialog"
               class="student_label_menu"></div>
          <!-- 切换按钮 -->
          <div v-if="showSwitchBut"
               @click.stop="switchStudent"
               class="student_label_switch"></div>
        </div>
        <div v-if="studentInfo.IsConfirmKey == 0"
             class="unverify_stundent pa"
             :style="!IsConfirmBut?'right: 48px;top: 0px;':''"
             @click.stop="verifyStudentClick">
          未验证
        </div>
        <div v-show="!isPatriarchCourse && studentInfo.ReadingCourseList && studentInfo.ReadingCourseList.length >= 0"
             @click="showPatriarchCourse"
             class="arrange_open_pa_btn">
        </div>
      </div>
    </div>

    <div class="course_student_attendance_operation module_content_menuList module_content_menuList_student"
         :style="{'left': dropDownBoxLeft?dropDownBoxLeft+'px':'321px','top':'45px'}"
         v-show="vIsShowAlerDialog&&isShowAlerDialog">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="optionStatus"
                     @clickOptionForAlertDialog="doAfterClick"></drop-down-box>
    </div>
    <!-- 学生列表 -->
    <custom-dialog title="学生列表"
                   :visible.sync="isShowStudentList"
                   :before-close="closeStudentList">
      <div>
        <student-list :switchItem="switchItem"
                      :MobliePhone="MobliePhone"
                      :studentInfo="studentInfo"
                      :CustStatusKey="CustStatusKey"
                      :IsConfirmKey="IsConfirmKey"
                      :isShowAddBtn="CustStatusKey!=1"
                      :isShowaddOldStudentBtn="isShowaddOldStudentBtn"
                      @selectedItem="doAfterSelectedStudent"
                      ref="studentListDialog"></student-list>
      </div>
    </custom-dialog>
    <custom-dialog :title="'学生：' +studentInfo.MainDemoName"
                   :append-to-body="true"
                   :visible.sync="isAlterMessagePopup"
                   :before-close="hideAlterMessagePopup">
      <div class="form_info_detail micro_message">
        <div class="micro_message_edit content_marginRL single_shadow">
          <div class="message_edit_input">
            <textarea cols="30"
                      rows="10"
                      maxlength="100"
                      placeholder="请输入排课备注"
                      v-model="alterMessageText"
                      @input="updateVal($event)"
                      @change="changeVal($event)"></textarea>
          </div>
        </div>
        <!-- 按钮组 -->
        <div class="footer_btn_group form_footer_btn_group">
          <el-button round
                     class="btn_light_red btn_hover_white"
                     @click.native="hideAlterMessagePopup">取消</el-button>
          <el-button round
                     class="btn_light_blue btn_hover_bg_blue"
                     @click.native="submitStudentArrangeSupport">确认</el-button>
        </div>
      </div>
    </custom-dialog>
    <!-- 验证学生弹出层 -->
    <!-- <custom-dialog title="验证学生"
                   :visible.sync="verifyPopup"
                   :before-close="closeVerifyPopup">
      <verify-student-popup :studentInfo="studentInfo"
                            @closeVerifyPopup="closeVerifyPopup"></verify-student-popup>
    </custom-dialog> -->
  </div>
</template>
<script>
import {
  EditArrangeSupport,
  GetStudentCommonMsg,
  GetStudentCommonMsgTwo
} from '../../API/workbench.js';
import studentList from '../../components/common/student-list';
// import verifyStudentPopup from './popups-box/verify-student-popup';
import dropDownBox from './drop-down-box';

export default {
  data () {
    return {
      isAlterMessagePopup: false,
      alterMessageText: '',
      showHeadsImg: true,
      isShowStudentList: false,
      registerCustomerVisible: false,
      isShowStudentFile: false,
      studentInfo: {},
      showAlerDialogForStu: false,
      isArrangeTextPa: false,
      isShowArrangeText: false,
      isPatriarchCourse: false,
      isPatriarchCourseText: false,
      optionStatus: [
        {
          type: 4,
          name: '验证学生',
          eventName: 'read',
          isShow: false
        },
        {
          type: 0,
          name: '切换学生',
          eventName: 'switch',
          isShow: !this.readonly && !this.unshowChgStudent
        },
        {
          type: 1,
          name: '查看学生',
          eventName: 'read',
          isShow: this.showExamine
        },
        {
          type: 2,
          name: '修改档案',
          eventName: 'edit',
          isShow: !this.readonly,
          ModulePowerKey: 48
        },
        {
          type: 3,
          name: '打印收据',
          eventName: 'print',
          isShow: this.readonly && this.showPrint
        }
      ],
      positionLeft: 0,
      positionTop: 0,
      isRead: false,
      maxLength: 100,
      verifyPopup: false
    };
  },
  props: {
    isAssociationTwoOrThree: {// false 图2   true 图3
      type: Boolean,
      default: false
    },
    StudentKeyValue: String,
    MobliePhone: [Number, String],
    studentID: [Number, String],
    showMenu: { type: Boolean, default: true },
    unshowChgStudent: Boolean,
    readonly: {
      type: Boolean,
      default: false
    },
    isExternalAlter: {
      type: Boolean,
      default: false
    },
    showExamine: {
      type: Boolean,
      default: true
    },
    switchItem: {
      type: [String, Number],
      default: 0
    },
    IsConfirmKey: {
      type: Number,
      default: 0 // 0:显示全部学生， 1：过滤掉待验证的学生
    },
    styleInfo: {
      type: String,
      default: ''
    },
    CustStatusKey: { // 学生块的学生列表是否只显示在校学生1为是，0为否（可选）
      type: [Number, String],
      default: ''
    },
    dropDownBoxLeft: {
      type: [Number, String],
      default: ''
    },
    showSwitchBut: {
      type: Boolean,
      default: false
    },
    mobileSupport: {
      type: [Number, String],
      default: ''
    },
    IsConfirmBut: {
      type: Boolean,
      default: true
    },
    // 该学生是否回收1-是;0-否
    IsRecycleKey: {
      type: Boolean,
      default: false
    },
    showPrint: {
      type: Boolean,
      default: false
    },
    isShowaddOldStudentBtn: {// 学生块的学生列表是否需要显示补录老生按钮（可选）
      type: Boolean,
      default: false
    }
  },
  components: {
    dropDownBox,
    studentList
    // verifyStudentPopup
  },
  computed: {
    isShowAlerDialog () {
      return this.showAlerDialogForStu;
    },
    vIsShowAlerDialog () {
      return this.$store.getters.showAlerDialog;
    },
    vLeaveDate () {
      if (this.studentInfo.LeaveDateTime) {
        let today = Date.new();
        let d = today.getDate();
        let m = today.getMonth() + 1;
        if (d < 10) {
          d = '0' + d;
        }
        if (m < 10) {
          m = '0' + m;
        }
        today.setTime(today.getTime());
        let todayNumber = today.getFullYear() + '-' + m + '-' + d;
        let date = this.$utils.newDateDiff(this.studentInfo.LeaveDateTime, todayNumber) + 1;
        return date;
      } else {
        return '';
      }
    },
    // 可用课时兼容年月日卡显示
    vCurrentClassHourCount () {
      if (Number(this.studentInfo.CurrentClassHourCount) == 0 && Number(this.studentInfo.YearCardMaxDayCount) > 0) {
        return '有';
      } else {
        return Number(this.studentInfo.CurrentClassHourCount);
      }
    }
  },
  watch: {
    //     'isSelectedStudentItem.OLAPKey': {
    //   immediate: true,
    //   handler (nv, ov) {
    //     console.log(this.isSelectedStudentItem, 'dataInfodataInfodataInfo');

    //     this.newInitJson = this.isSelectedStudentItem;
    //   }
    // }
    studentID (n, o) {
      console.log(n, '学生IDDIDID');
      this.showHeadsImg = !this.showHeadsImg;
      this.isPatriarchCourse = false;
      this.isShowArrangeText = false;
      this.$nextTick(e => {
        this.showHeadsImg = true;
      });
      // if (n != o) {
      this.loadStudentDetailInfo();
      // }
    },
    'studentInfo.ArrangeSupport' (n, o) {
      // if (!this.IsRecycleKey) {
      this.$nextTick(() => {
        if (this.$refs.arrangeContentText && this.$refs.arrangeContentText.innerHTML == this.studentInfo.ArrangeSupport) {
          if (this.$refs.arrangeBtn && this.$refs.arrangeContent.offsetHeight >= 30) {
            this.isArrangeTextPa = true;
          } else {
            this.isArrangeTextPa = false;
          }
        } else if (this.$refs.arrangeContentText.innerHTML == '排课备注') {
          this.isArrangeTextPa = false;
        }
      });
      // }
    },
    'mobileSupport': {
      handler (newName, oldName) {
        if (newName) {
          this.studentInfo.MobileSupport = newName;
        }
        console.log(this.studentInfo.MobileSupport, '手机号码123');
      },
      deep: true
    }
  },
  created () {
    console.log(this.studentID, 'studentID');
    if (this.studentID > 0) {
      this.loadStudentDetailInfo();
    }
    document.addEventListener('click', e => {
      const ele = document.getElementsByClassName('alert_dialog')[0];
      if (e.target && !e.target.contains(ele)) {
        this.showAlerDialogForStu = false;
      }
    });
    this.$bus.on(this, 'AfterStudentUpdate', studentKey => {
      if (studentKey == this.studentID) {
        this.loadStudentDetailInfo(studentKey, true);
      }
    });
  },
  mounted () {
    this.$dialog.register(this, ['studentList', 'studentFile', 'registerCustomerDetails', 'verifyStudentPopup']);
  },
  methods: {
    refreshArrangeText () {
      this.$nextTick(() => {
        if (this.$refs.arrangeContentText && this.$refs.arrangeContentText.innerHTML == this.studentInfo.ArrangeSupport) {
          if (this.$refs.arrangeBtn && this.$refs.arrangeContent.offsetHeight >= 30) {
            this.isArrangeTextPa = true;
          } else {
            this.isArrangeTextPa = false;
          }
        } else if (this.$refs.arrangeContentText && this.$refs.arrangeContentText.innerHTML == '排课备注') {
          this.isArrangeTextPa = false;
        }
      });
    },
    showPatriarchCourse () {
      this.isPatriarchCourse = true;
    },
    arrangeShowText () {
      this.isShowArrangeText = true;
      this.isArrangeTextPa = false;
    },
    showAlterMessagePopup () {
      // if (!this.IsRecycleKey) {
      this.$emit('showAlterMessagePopup');
      this.isAlterMessagePopup = true;
      this.alterMessageText = this.studentInfo.ArrangeSupport;
      // }
    },
    hideAlterMessagePopup () {
      console.log('hideAlterMessagePopup');
      this.isAlterMessagePopup = false;
      this.$emit('closed');
    },
    submitStudentArrangeSupport () {
      EditArrangeSupport(this.studentInfo.OLAPKey, this.alterMessageText).then(
        res => {
          this.studentInfo.ArrangeSupport = this.alterMessageText;
          this.$nextTick(() => {
            if (this.$refs.patriarchCourseUl && this.$refs.patriarchCourseUl.offsetWidth >= (this.$refs.studentLabelPatriarch.offsetWidth * 0.5)) {
              this.isPatriarchCourseText = true;
            } else {
              this.isPatriarchCourseText = false;
            }
          });
          this.hideAlterMessagePopup();
        },
        err => {
          this.hideAlterMessagePopup();
          layer.alert(err.msg);
        }
      );
    },
    closeStudentList () {
      this.isShowStudentList = false;
    },
    // 切换学生
    doAfterSelectedStudent (item) {
      console.log(item, '切换学生');
      this.refreshArrangeText();
      this.loadStudentDetailInfo(item.OLAPKey);
      this.$emit('alterStudent', item);
      this.isShowStudentList = false;
    },

    doAfterClick (optionItem) {
      console.log(optionItem, 'optionItem');
      switch (optionItem.type) {
        case 0:
          if (this.isExternalAlter) {
            this.$emit('changeStudent');
          } else {
            this.isShowStudentList = true;
            // this.$dialog.open(this, {
            //   routerName: this.$route.name,
            //   moduleName: 'studentList',
            //   data: {
            //     switchItem: this.switchItem,
            //     studentInfo: this.studentInfo,
            //     CustStatusKey: this.CustStatusKey,
            //     IsConfirmKey: this.IsConfirmKey,
            //     isShowAddBtn: this.CustStatusKey != 1
            //   },
            //   callBack: {
            //     selectedItem: (item) => { this.doAfterSelectedStudent(item); }
            //   }
            // });
          }
          break;
        case 1:
          this.showStudentFile();
          break;
        case 2:
          let obj = {
            StudentKey: this.studentInfo.OLAPKey,
            Type: 'submit'
          };
          this.$dialog.open(this, {
            routerName: this.$route.name,
            moduleName: 'registerCustomerDetails',
            data: { dataInfo: obj }
          });
          break;
        case 3:
          this.$emit('showPrintFrom');
          break;
        default:
          break;
      }
      this.showAlerDialogForStu = false;
    },
    // 验证学生
    verifyStudentClick () {
      if (this.IsConfirmBut) {
        this.verifyPopup = true;
        this.$dialog.open(this, {
          routerName: this.$route.name,
          moduleName: 'verifyStudentPopup',
          data: { dataInfo: this.studentInfo },
          callBack: { afterSuccess: () => { this.closeVerifyPopup(); } }
        });
        console.log(this.studentInfo, 'this.studentInfo');
      }
    },
    // 显示验证学生弹出层
    closeVerifyPopup (OLAPKey) {
      console.log('closeVerifyPopup');
      this.verifyPopup = false;
      if (OLAPKey) { // 当学生ID为true时,刷新
        this.loadStudentDetailInfo(OLAPKey, 1);
      }
    },
    showStudentFile (switchCourse, courseInfo) {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'studentFile',
        data: { studentID: this.studentInfo.OLAPKey }
      });
    },
    loadStudentDetailInfo (studentID, alterStudent) {
      let OLAPKey = studentID || this.studentID;
      GetStudentCommonMsg(OLAPKey).then(result => {
        this.studentInfo = result.data;
        console.log(this.studentInfo, '学生块');
        if (alterStudent) {
          this.$emit('alterStudent', this.studentInfo);
        }
        // 排课管理中需要用到.
        this.$emit('getDetailInfo', this.studentInfo);
        this.$nextTick(() => {
          if (this.$refs.patriarchCourseUl && this.$refs.patriarchCourseUl.offsetWidth >= (this.$refs.studentLabelPatriarch.offsetWidth * 0.5)) {
            this.isPatriarchCourseText = true;
          } else {
            this.isPatriarchCourseText = false;
          }
        });
        this.refreshArrangeText();
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    showAlertDialog (e) {
      this.$store.commit('showAlerDialog', false);
      let Rect = e.target.getBoundingClientRect();
      let parentRect = this.$parent.$el.getBoundingClientRect();

      this.$nextTick(() => {
        this.$store.commit('showAlerDialog', true);
        this.showAlerDialogForStu = true;
      });
      this.positionLeft = Rect.left - parentRect.left - 8;
      this.positionTop = Rect.bottom - parentRect.top;

      this.positionLeft = Rect.left / 2 - 196;
      this.positionTop = Rect.top - 35;
    },
    switchStudent (e) {
      let Rect = e.target.getBoundingClientRect();
      let parentRect = this.$parent.$el.getBoundingClientRect();
      this.doAfterClick(this.optionStatus[1]);
      this.positionLeft = Rect.left - parentRect.left - 8;
      this.positionTop = Rect.bottom - parentRect.top;
      this.positionLeft = Rect.left / 2 - 196;
      this.positionTop = Rect.top - 35;
    },
    updateVal (e) {
      this.onkeyupEvent(e);
    },
    changeVal (e) {
      this.onkeyupEvent(e);
    },
    limitMaxLength (str, maxLen) {
      var result = [];
      var re = /^[a-zA-Z]+$/;
      for (var i = 0; i < maxLen; i++) {
        var char = str[i];
        if (!re.test(char)) { maxLen--; }
        result.push(char);
      }
      return result.join('');
    },
    onkeyupEvent (e) {
      let val = e.target.value;
      if (this.$utils.strLength(val) > this.maxLength * 2) {
        e.target.value = this.limitMaxLength(val, this.maxLength * 2);
        this.alterMessageText = e.target.value;
      }
    }
  }
};
</script>
<style scoped>
.student_label_name .td_WX_open {
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background: url(../../../public/image/subscribe_icon.png) no-repeat center
    center;
  background-size: 22px;
  display: inline-block;
}
.student_label_name .td_WX_close {
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background: url(../../../public/image/subscribe_icon_gray.png) no-repeat
    center center;
  background-size: 22px;
  display: inline-block;
}
</style>