<template>
  <!-- 学生调整学分 -->
  <div>
    <div class="content_marginRL border_bottom">
      <div class="form_info_edit mendian_setting_content single_shadow"
           :class="{btn_marginBottom_30:true}">

        <input-number :formTitle="'当前学分'"
                      :readonly="true"
                      v-model="dataJson.LearnCurrencyCount"
                      :decimalPlaces="0"
                      :placeholder="'必填'"
                      :required="true"></input-number>

        <input-number :formTitle="'实际学分'"
                      :min="-99999999"
                      :max="99999999"
                      v-model="dataJson.RealityCount"
                      :placeholder="'必填'"
                      :required="true"
                      @inputChange="chgRealityCount"></input-number>

        <input-rational-number :formTitle="'调整数量'"
                               :min="-99999999"
                               :max="99999999"
                               v-model="dataJson.CreditNum"
                               :decimalPlaces="0"
                               :placeholder="'必填'"
                               :required="true"
                               @inputChange="chgCreditNum"></input-rational-number>

        <input-text :formTitle="'原因说明'"
                    class="no_border_bottom"
                    :readonly="false"
                    :formPlaceholder="'选填'"
                    :required="true"
                    v-model="dataJson.Remake"></input-text>
      </div>
    </div>
    <!-- 按钮组 -->
    <div v-if="true"
         style="margin-top: 80px;">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
  </div>

</template> 

<script>
import { UpdateCredit } from '../../../API/workbench';
export default {
  data () {
    return {
      // 数据源
      dataJson: {
        StudentKey: '', // 学生id
        LearnCurrencyCount: '', // 当前学分
        RealityCount: '', // 实际学分
        Remake: ''// 备注
      }
    };
  },

  computed: {

  },
  props: {
    dataInfo: Object// 学生信息
  },
  watch: {

  },
  created () {
    if (JSON.stringify(this.dataInfo) != '{}') { // 学生学分信息
      this.dataJson.StudentKey = Number(this.dataInfo.OLAPKey); // 学生key
      this.dataJson.LearnCurrencyCount = Number(this.dataInfo.LearnCurrencyCount); // 当前学分
      console.log(this.dataInfo, '修改学分信息');
    } else {
      console.log(this.dataInfo, '暂无数据');
    }
    this.dataJson.RealityCount = Number(this.dataJson.LearnCurrencyCount);  // 实际学分
  },
  mounted () {

  },
  methods: {
    // 确认按钮
    confirmClick (callBack) {
      let isWrong = this.checkSubmitData();
      if (isWrong) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      UpdateCredit(this.dataJson.StudentKey, this.dataJson.RealityCount, this.dataJson.Remake).then(result => {
        layer.alert('调整成功');
        this.$emit('afterSuccessCallBack');
        this.cancelClick();
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    // 实际学分
    chgRealityCount (count) {
      console.log(count, 'chgRealityCount');
      if (count == '') {
        console.log(count, '');
      } else {
        this.dataJson.RealityCount = count;
      }

      if (Number(this.dataJson.LearnCurrencyCount) - Number(this.dataJson.RealityCount) > 0) {
        this.dataJson.CreditNum = -(Math.abs(this.dataJson.LearnCurrencyCount - this.dataJson.RealityCount));
      } else {
        this.dataJson.CreditNum = Math.abs(this.dataJson.LearnCurrencyCount - this.dataJson.RealityCount);
      }
    },
    // 调整数量
    chgCreditNum (count) {
      this.dataJson.CreditNum = count;
      this.dataJson.RealityCount = this.$utils.mMoneyType(Number(this.dataJson.LearnCurrencyCount) + Number(this.dataJson.CreditNum), 0);
      console.log(count, this.dataJson, 'chgCreditNum');
    },
    // 验证要提交的数据
    checkSubmitData () {
      let flag = false;
      if (!this.dataJson.CreditNum) {
        layer.alert('调整数量不能为空');
        flag = true;
      } else if (!this.dataJson.Remake) {
        layer.alert('原因说明不能为空');
        flag = true;
      }
      return flag;
    },
    // 取消按钮
    cancelClick () {
      this.$emit('cancelClick');
    }
  }
};
</script>
