<template>
  <!-- 排课创收潜力挖掘 - 学生/班级/满班率 出勤级别-->
  <div class="saturationCourse_si">
    <div class="content_marginRL content_marginTop_30">
      <div class="form_info_edit form_info_list pr"
           v-if="this.editItem.attendanceNum != 3">
        <input-select :formTitle="'统计时间范围'"
                      :id="'type'"
                      :name="'name'"
                      :required="false"
                      :readonly="false"
                      :dataList="timeStatisticsList"
                      :selectedInfo="vTimeStatisticsList"
                      @selectedItem="chgSearchTimeStatisticsValue"></input-select>
      </div>
      <div class="saturation_course_content">

        <div class="index_title pr">
          <span style="color: #666;">{{ editItem.attendanceNum != 3?'出勤级别设置':'满班率档级设置' }} </span>
          <div class="course_right">

            <div class="saturation_both">
              <div class="right_title">{{ editItem.attendanceNum != 3?'高出勤':'高满班率' }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ≥</div>
              <div class="right_surCourse"
                   :style="{'marginLeft':editItem.attendanceNum == 3?'90px':'99px'}"
                   @click="UpdateClick(editItem.attendanceNum != 3?'高出勤':'高满班率',topAttendanceInfo)"><span>{{Number(topAttendanceInfo.SetContent)}}%</span>
                <span class="second_content_list"></span>
              </div>
            </div>
            <div class="saturation_both">
              <div class="right_title">{{ editItem.attendanceNum != 3?'低出勤':'低满班率' }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ≤</div>
              <div class="right_surCourse"
                   :style="{'marginLeft':editItem.attendanceNum == 3?'90px':'99px'}"
                   @click="UpdateClick(editItem.attendanceNum != 3?'低出勤':'低满班率',lowAttendanceInfo)"><span>{{Number(lowAttendanceInfo.SetContent)}}%</span>
                <span class="second_content_list"></span>
              </div>
            </div>
            <div class="saturation_both">
              <div class="right_title">{{ editItem.attendanceNum != 3?'中出勤':'中满班率' }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </div>
              <div class="right_surCourse"
                   style="color: rgba(51, 51, 51, 1);margin-left: 77px;"><span>{{Number(lowAttendanceInfo.SetContent)}}%-{{Number(topAttendanceInfo.SetContent)}}%</span>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
    <custom-dialog title="修改数据"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowUpdateNum"
                   :before-close="closeUpdateNumPopup">
      <update-setting :formTitle="'修改数据'"
                      :updateTitle="updateTitle"
                      :dateInfo="dateInfo"
                      :isAttendance="true"
                      @cancelClick="cancelClick"
                      @afterSuccess="afterSuccess">
      </update-setting>
    </custom-dialog>
  </div>

</template> 

<script>
import updateSetting from './update-setting';
import { GetSaaSClientMsg, ModifySetting } from '../../../../API/workbench';
export default {
  props: {
    editItem: [Object, String]// this.editItem.attendanceNum == 1 学生出勤 / this.editItem.attendanceNum == 2 班级出勤 / this.editItem.attendanceNum == 3 满班率
  },
  data () {
    return {
      updateTitle: '',
      dateInfo: {},
      timeStatisticsList: [
        { type: 1, name: '近30天内' },
        { type: 2, name: '近60天内' },
        { type: 3, name: '近90天内' }
      ],
      // 统计时间范围
      timeRangeInfo: {
        SetContent: '30',
        SetKey: '1',
        SetKeyValue: '开启',
        SetTypeKey: ''
      },
      // 高出勤率
      topAttendanceInfo: {
        SetContent: '',
        SetKey: '1',
        SetKeyValue: '开启',
        SetTypeKey: ''
      },
      // 低出勤率
      lowAttendanceInfo: {
        SetContent: '',
        SetKey: '1',
        SetKeyValue: '开启',
        SetTypeKey: ''
      },
      isShowUpdateNum: false,
      mendianInfo: null
    };
  },

  components: {
    updateSetting
  },
  computed: {
    vTimeStatisticsList () {
      return {
        type: this.timeRangeInfo.SetContent == '30' ? 1 : this.timeRangeInfo.SetContent == '60' ? 2 : this.timeRangeInfo.SetContent == '90' ? 3 : '',
        name: this.timeRangeInfo.SetContent == '30' ? '近30天内' : this.timeRangeInfo.SetContent == '60' ? '近60天内' : this.timeRangeInfo.SetContent == '90' ? '近90天内' : ''
      };
    }
  },
  watch: {
  },
  created () {
    console.log(this.editItem.attendanceNum, 'editItem666');
    this.getMendianInfo();
  },
  mounted () {
  },
  methods: {
    closeUpdateNumPopup () {
      this.isShowUpdateNum = false;
    },
    UpdateClick (title, item) {
      this.updateTitle = title;
      this.dateInfo = item;
      this.isShowUpdateNum = true;
    },
    cancelClick () {
      this.isShowUpdateNum = false;
    },
    chgSearchTimeStatisticsValue (obj) {
      if (obj.type == 1) {
        this.timeRangeInfo.SetContent = '30';
      } else if (obj.type == 2) {
        this.timeRangeInfo.SetContent = '60';
      } else {
        this.timeRangeInfo.SetContent = '90';
      }
      this.modifySettingAfter(this.timeRangeInfo);
      console.log(obj, 'chgSearchTimeStatisticsValue', this.timeRangeInfo);
    },
    // 更新门店设置
    modifySettingAfter (dataInfo, callBack) {
      ModifySetting(dataInfo).then(res => {
        layer.alert('修改成功');
      }, err => {
        layer.alert(err.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    getMendianInfo () {
      GetSaaSClientMsg().then(result => {
        result.data.SaaSClientSetting.forEach(o => {
          if (this.editItem.attendanceNum == 1) {
            // 学生出勤
            if (o.SetTypeKey == '103') {
              this.timeRangeInfo.SetContent = o.SetContent;
              this.timeRangeInfo.SetKey = o.SetKey;
              this.timeRangeInfo.SetKeyValue = o.SetKeyValue;
              this.timeRangeInfo.SetTypeKey = o.SetTypeKey;
            }
            if (o.SetTypeKey == '104') {
              this.topAttendanceInfo.SetContent = o.SetContent;
              this.topAttendanceInfo.SetKey = o.SetKey;
              this.topAttendanceInfo.SetKeyValue = o.SetKeyValue;
              this.topAttendanceInfo.SetTypeKey = o.SetTypeKey;
            }
            if (o.SetTypeKey == '105') {
              this.lowAttendanceInfo.SetContent = o.SetContent;
              this.lowAttendanceInfo.SetKey = o.SetKey;
              this.lowAttendanceInfo.SetKeyValue = o.SetKeyValue;
              this.lowAttendanceInfo.SetTypeKey = o.SetTypeKey;
            }
          } else if (this.editItem.attendanceNum == 2) {
            // 班级出勤
            if (o.SetTypeKey == '108') {
              this.timeRangeInfo.SetContent = o.SetContent;
              this.timeRangeInfo.SetKey = o.SetKey;
              this.timeRangeInfo.SetKeyValue = o.SetKeyValue;
              this.timeRangeInfo.SetTypeKey = o.SetTypeKey;
            }
            if (o.SetTypeKey == '109') {
              this.topAttendanceInfo.SetContent = o.SetContent;
              this.topAttendanceInfo.SetKey = o.SetKey;
              this.topAttendanceInfo.SetKeyValue = o.SetKeyValue;
              this.topAttendanceInfo.SetTypeKey = o.SetTypeKey;
            }
            if (o.SetTypeKey == '110') {
              this.lowAttendanceInfo.SetContent = o.SetContent;
              this.lowAttendanceInfo.SetKey = o.SetKey;
              this.lowAttendanceInfo.SetKeyValue = o.SetKeyValue;
              this.lowAttendanceInfo.SetTypeKey = o.SetTypeKey;
            }
          } else {
            // 满班率
            if (o.SetTypeKey == '111') {
              this.topAttendanceInfo.SetContent = o.SetContent;
              this.topAttendanceInfo.SetKey = o.SetKey;
              this.topAttendanceInfo.SetKeyValue = o.SetKeyValue;
              this.topAttendanceInfo.SetTypeKey = o.SetTypeKey;
            }
            if (o.SetTypeKey == '112') {
              this.lowAttendanceInfo.SetContent = o.SetContent;
              this.lowAttendanceInfo.SetKey = o.SetKey;
              this.lowAttendanceInfo.SetKeyValue = o.SetKeyValue;
              this.lowAttendanceInfo.SetTypeKey = o.SetTypeKey;
            }
          }
        });
        console.log(this.timeRangeInfo, 'this.timeRangeInfo', this.topAttendanceInfo, 'this.lowAttendanceInfo', this.lowAttendanceInfo);
      }, err => {
        layer.alert(err.msg);
      });
    },
    aaa () {
      this.isShowUpdateNum = true;
    },
    afterSuccess () {
      this.isShowUpdateNum = false;
      this.getMendianInfo();
    }
  },
  destroyed () {
    this.$emit('afterSuccess');
  }
};
</script>

<style scoped>
/*  */
.saturationCourse_si {
  height: 300px;
}
.saturation_course_content {
  width: 370px;
  margin: 0 auto;
  height: 270px;
}

.index_title {
  font-size: 14px;
  line-height: 50px;
  width: 375px;
  padding-left: 0px !important;
  border-bottom: none !important;
}
.right_surFlow {
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 26px;
  color: #3498db;
}
.second_content_list::after {
  position: absolute;
  content: '';
  width: 33px;
  height: 20px;
  right: 6px;
  top: 17px;
  background: url(../../../../../public/image/next_icon@2x.png) no-repeat right
    center;
  background-size: 7px;
}
.course_right {
  width: 235px;
  position: relative;
  top: -48px;
  left: 140px;
}
.saturation_both {
  display: flex;
  position: relative;
}
.right_surCourse {
  margin-left: 99px;
  cursor: pointer;
  color: #3498db;
}
</style>