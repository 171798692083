<template>
  <div class="body_box">
      <span class="title_text">授权课包</span>
      <div class="swiper_box">
          <div class="swiper-container swiper_class_content">
            <div class="swiper-wrapper">
                <swiper-item v-for="(item,key) in classData"
                     :key="key"
                     :item="item"
                     :choosekey='choosekey'
                     @chgCoureDateAfter="chgCoure"></swiper-item>
            </div>
          </div>
          <div class="swiper-button-prev swiper-button-black"></div>
          <div class="swiper-button-next swiper-button-black"></div>
      </div>
  </div>
</template>
<script>
import topReportVue from '../../../report/student-management/student-attendance-list/top-report.vue';
import SwiperItem from './swiper-item.vue'
export default {
  components: {
    SwiperItem
  },
  props:[
    'classData','choosekey'
  ],
  data () {
    return {
      dataList:[
        {txtId:0,className:'水墨小画水墨画',isActive:true},
        {txtId:1,className:'水墨小画水墨水墨小画小画水墨小画',isActive:false},
        {txtId:2,className:'水墨小画水墨水水墨小画',isActive:false},
        {txtId:3,className:'水墨小画水墨小画',isActive:false},
        {txtId:4,className:'水墨小画水墨墨小画',isActive:false},
        ],
    };
  },
  watch:{
    classData:{
      handler(c,o){
        if(c){
        //  this.getDateRangeCoursewareStat( this.$utils.getCurrentMonthFirst(),this.$utils.getCurrentMonthLast())
          this.dataList=c
        }
      },
    },
  },
  created () {
    
  },
  mounted () {
    // this.dataList = this.classData
    // console.log('mounted',this.classData)
      // this.$emit('chgCoureDateAfter', this.classData[0]);
     this.swiper = new Swiper('.swiper_class_content', {
        grabCursor:true,
        slidesPerView: 4,
        spaceBetween: 10,
        // loop: false, // 循环模式选项
        observer: false,   // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: false,  // 修改swiper的父元素时，自动初始化swipe
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        
      }); 
        
  },
  methods: {
   chgCoure (item, type) {       // 只提供高亮,不提供请求.
      console.log('传item',item)
      console.log('classdata',this.classData)
      

      this.$emit('chgCoureDateAfter', item);
    },
  }
};
</script>
<style scoped>
.title_text{
    height: 22px;
    font-family: 'Alibaba PuHuiTi';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: block;
    align-items: center;
    color: #333333;
    
}
.body_box{
    width: 815px;
    margin-top: 20px;
    height: 110px;
}
.swiper_box{
  margin-top: 10px;
  width: 815px;
  height: 88px;
  position: relative;
}
.swiper {
    width: 160px;
    height: 60px;
}  

.swiper-button-prev{
    top: 46px;
    left: -20px;
    position: absolute;
    width: 30px;
    height: 10px;
    opacity: 0.3;
}
.swiper-button-next{
    top: 46px;
    right: -20px;
    position: absolute;
    width: 30px;
    height: 10px;
    opacity: 0.3;
}
.swiper-container{
  overflow: hidden;
}
swiper-container::after { height: 16px; content: "";  position:absolute; bottom:9px; right:0; padding-left:30px; background:-webkit-linear-gradient(left,transparent,#fff 55%); background:-o-linear-gradient(right,transparent,#fff 55%); background:-moz-linear-gradient(right,transparent,#fff 55%); background:linear-gradient(to right,transparent,#fff 55%); }
</style>