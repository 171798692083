<template>
  <!-- 课件授权管理 -->
  <div class="super_management">
    <div class="table_fixed_nav_box">
      <div class="table_fixed_nav tltle_margin campus_auto pr">
        <div @click="showCampusInUseClick"
             class="table_fixed_nav_list"
             :class="{opt:showManagement=='CampusInUse'}">在用校区</div>
        <div @click="showAuthorizationRecordClick"
             class="table_fixed_nav_list"
             :class="{opt:showManagement=='AuthorizationRecord'}">授权记录</div>
        <div @click="showDeactivateCampusClick"
             class="table_fixed_nav_list"
             :class="{opt:showManagement=='DeactivateCampus'}">停用校区</div>
        <div class="xiaoqu">
          <img src="../../../../public/image/xqzyk.png"
               style="width: 20px;height: 20px;">
          <span class="ku"
                @click="openResourceTableDialog">校区资源库</span>
        </div>
      </div>
    </div>
    <div>
      <!-- 在用校区列表 -->
      <div class="showManagement"
           v-if="showManagement=='CampusInUse'">
        <campus-in-use></campus-in-use>
      </div>
      <!-- 授权记录列表 -->
      <div class="showManagement"
           v-else-if="showManagement=='AuthorizationRecord'">
        <authorization-record></authorization-record>
      </div>
      <!-- 停用校区列表 -->
      <div class="showManagement"
           v-if="showManagement=='DeactivateCampus'">
        <deactivate-campus></deactivate-campus>
      </div>

    </div>

    <custom-dialog title="校区资源库"
                   width="1000px"
                   :visible.sync="isShowResourceTable"
                   :before-close="closeResourceTableDialog">
      <campus-resource-library @afterSuccess="ResourceTableSuccess"
                               @cancelClick="closeResourceTableDialog"></campus-resource-library>
    </custom-dialog>
    <div>
      <dialog-factory ref="campusCoursewareManagementDialog"
                      :routerName="'campusCoursewareManagement'"></dialog-factory>
    </div>
  </div>
</template>
<script>
import DialogFactory from '../../common/dialog-factory';
import campusInUse from './report/campus-in-use.vue';
import campusResourceLibrary from './report/campus-resource-library.vue';
import authorizationRecord from './report/authorization-record.vue';
import deactivateCampus from './report/deactivate-campus.vue';
export default {
  name: 'campusCoursewareManagement',
  data () {
    return {
      showManagement: 'CampusInUse',
      isShowResourceTable: false
    };
  },
  components: {
    DialogFactory,
    campusInUse,
    campusResourceLibrary,
    authorizationRecord,
    deactivateCampus
  },
  created () {
    this.registerBusEvent();
  },
  mounted () {

  },
  methods: {
    // 切换管理历史记录列表
    showCampusInUseClick () {
      this.showManagement = 'CampusInUse';
    },
    showAuthorizationRecordClick () {
      this.showManagement = 'AuthorizationRecord';
    },
    // 切换管理历史记录列表
    showDeactivateCampusClick () {
      this.showManagement = 'DeactivateCampus';
    },
    closeResourceTableDialog () {
      this.isShowResourceTable = false;
    },
    ResourceTableSuccess () {

    },
    openResourceTableDialog () {
      this.isShowResourceTable = true;
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'campusCoursewareManagement') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.campusCoursewareManagementDialog) {
        this.$refs.campusCoursewareManagementDialog.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    }

  },
  computed: {}
};
</script>
<style scoped>
.campus_auto {
  margin: auto !important;
}
.showManagement {
  width: 1290px;
  margin: auto;
  background: #ffffffff;
  padding: 0 20px;
  border-radius: 8px;
}
.ku {
  color: #8a8a8a;
  cursor: pointer;
}
.xiaoqu {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.summarizing_list >>> .table_list_content {
  padding: 0;
}
</style>