<template>
  <div class="recruit_student_activity">
    <div class="recruit_student_activity_title">
      <div class="title_list"
           :class="indexType==0?'opt':''"
           @click="changeIndexType(0)">
        全部活动
      </div>
      <div class="title_list"
           :class="indexType==1?'opt':''"
           @click="changeIndexType(1)">
        报名记录
      </div>
      <div class="title_list"
           :class="indexType==2?'opt':''"
           @click="changeIndexType(2)">
        活动模板
      </div>
    </div>
    <!-- 活动管理 -->
    <div v-if="indexType==0">
      <activity-list :seletedPlayMethodInfo="seletedPlayMethodInfo" @openFactoryDialog="openCustomDialogByFactoryDialog"></activity-list>
      
    </div>
    <!-- 我的活动 -->
    <div v-if="indexType==1">
      <apply-record-list></apply-record-list>
    </div>
     <div v-if="indexType==2">
       <template-list ></template-list>
    </div>
    <custom-dialog :title="'选择封面模板'"
                   class=""
                   width="855px"
                   :visible.sync="showPic"
                   :before-close="closeEditContent">
      <!-- <choose-coverimg-list @afterSuccess="seletedCoverImg"></choose-coverimg-list> -->
      <choose-template-list></choose-template-list>
    </custom-dialog>
  
    <div>
      <dialog-factory ref="activityReportDialogFactory"
                      :key="routerName"
                      :routerName="routerName"></dialog-factory>

      <dialog-factory-common ref="activityReportDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import dialogFactory from '../common/dialog-factory';
import activityList from './components/activity-list'
import applyRecordList from './components/apply-record-list'
import templateList from './components/template-list'
import chooseCoverimgList from './components/choose-coverimg-list'
import chooseTemplateList from './components/choose-template-list'
import { GetActivitiesPlayInfo  } from '../../API/workbench';
import router from '../../router';

export default {
  name: 'activityReport',
  data () {
    return {
      indexType: 0,
      showPic:false,
      seletedPlayMethodInfo:null
    };
  },
  components: {
   dialogFactory,activityList,applyRecordList,templateList,chooseCoverimgList,chooseTemplateList
  },
  props:{
    routerName:String
  },
  created () {
    console.log('activityReport created', this.indexType);
    this.getPlayInfo()
  },
  mounted () {
    this.resgisterBusEvent();
  },
  beforeRouteLeave (to, from, next) {
    console.log('触发beforeRouteLeave');
    this.$bus.emit('closeEditorDialog');
    next();
  },
  activated () {
    console.log('activityReport activated', this.indexType);
  },
  methods: {
    getPlayInfo(){
      console.log("getPlayInfo",this.$route.query.TypeKey)
      // 活动类型 1-优惠券招生;2-引流套餐招生;3-拼团招生;4-阶梯团招生5-员工分销招生6-家长分销招生

      GetActivitiesPlayInfo(this.$route.query.TypeKey||1).then(result=>{
        console.log('getPlayInfo',result.data)
        this.seletedPlayMethodInfo=result.data
      })
    },
     closeEditContent(){
      this.isShowViewContent=false
      this.isShowEditContent=false
    },
    seletedCoverImg(item){
      console.log(item)
    },
    changeIndexType (type) {
      this.indexType = type;
      // this.showPic=true
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log("openCustomDialogByFactoryDialog")
      if (this.$refs.activityReportDialogFactory) {
        this.$refs.activityReportDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      };
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.activityReportDialogFactoryCommon) {
        this.$refs.activityReportDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      };
    },
    resgisterBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        console.log("openCustomDialog")
        if (dialogInfo.routerName == this.routerName) {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
           console.log("openCommonCustomDialog")
        if (dialogInfo.routerName ==  this.routerName) {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    }
  }
};
</script>
<style>

</style>
