 <template>
  <div v-if="isShow">
    <div class="rush_hour form_info_detail"
         v-if="!showStudentList">
      <div>

        <student-lable class="content_marginRL single_shadow"
                       :studentID="studentInfo.OLAPKey"
                       :mobileSupport="studentInfo.MobileSupport"
                       @changeStudent="changeStudent"
                       @alterStudent="selectedItem">
        </student-lable>
      </div>
      <div class="single_result_courses_purchased content_marginRL">
        <div class="courses_purchased_list "
             ref="dataScroll"
             :class="{courses_purchased_list_padding:studentOweClassList.length>0 }"
             v-show="studentOweClassList.length>0">
          <studentOweClassCountItem v-for="item in studentOweClassList"
                                    :key="item.TableID"
                                    :item="item"
                                    @sendItem="pitchClassItem"></studentOweClassCountItem>
        </div>
        <div class="courses_purchased_list "
             v-show="studentOweClassList.length==0">
          <div>没有数据了.!</div>
        </div>
      </div>
      <!-- 修改考勤可操作列表 -->
      <div class="rush_hour_record content_marginRL single_shadow"
           v-if="courseNameKeyList.length">
        <div class="rush_hour_record_table">
          <!-- 表头 -->
          <div class="rush_hour_record_thead">
            <div class="rush_hour_record_courseData thead_field">上课日期</div>
            <div class="rush_hour_record_courseName thead_field">课名</div>
            <div class="rush_hour_record_courseTime thead_field">扣课时</div>
          </div>
          <!-- 内容 -->
          <div class="rush_hour_record_tbody">
            <div class="rush_hour_record_list">
              <studentOweCourseItem v-for="courseItem in courseNameKeyList"
                                    :key="courseItem.TableID"
                                    :item="courseItem"></studentOweCourseItem>

            </div>
          </div>

        </div>
      </div>
      <!-- 抵扣课单选择 -->
      <div class="rush_hour_choose content_marginTop_8 content_marginRL single_result_info single_shadow">
        <div class="single_result_info_left">
          <span>目标课单</span>
        </div>
        <!-- 课程选择 -->
        <div class="arrange_course_option">
          <!-- 选择课程下拉菜单 -->
          <!-- 可编辑界面 form_info_canedit -->
          <div class="form_info_canedit">
            <div class=" form_info_edit ">
              <div class=" form_info_line ">
                <div class=" form_info_field">
                  <span>课单</span>
                </div>
                <div class="form_info_value">
                  <el-select v-model="courseForm"
                             placeholder="请选择"
                             class="course_list"
                             @change="choseItem">
                    <el-option v-for="item in allCourseList"
                               :key="item.TableID"
                               :label="item.NumberName"
                               :value="item">
                      <div class="rush_hour_choose_content">
                        <div class="rush_hour_choose_courses">
                          <span :title="item.ApplyCorName"
                                class="rush_hour_courses_value">{{item.ApplyCorName}}</span>
                        </div>
                        <div class="rush_hour_choose_time">
                          <span class="rush_hour_courses_field ">
                            <span v-if="Number(item.UnitKey) ==5"> 剩余数量
                              <span>|</span>
                              <span>天数</span>
                            </span>
                            <span v-else>剩余天数</span>
                          </span>
                          <span class="rush_hour_courses_value">
                            <span v-if="Number(item.UnitKey) ==5">
                              <span>{{Number(item.CurrentCount).toFixed(1)}}{{item.UnitKeyValue}}</span>
                              <span class="parting_line">|</span>
                            </span>
                            <span>{{Number(item.PeriodCount)}}</span>天</span>
                        </div>
                        <div class="rush_hour_course_number">
                          <span class="rush_hour_course_num">{{ item.NumberName }}</span>
                        </div>
                      </div>
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class=" form_info_line content_marginTop_8">
                <div class=" form_info_field">
                  <span>还课时</span>
                </div>
                <div class="form_info_value">
                  <input type="text"
                         readonly="true"
                         v-model="DeductionOfClass">
                </div>
              </div>
              <!-- 操作说明 -->
              <!-- <div class=" form_info_describe">
                <span class="point_blue">所欠课时必须一次性还完，不可分批还。</span>
              </div> -->
            </div>
          </div>
        </div>

      </div>
      <!-- 按钮组 -->
      <div class="">
        <save-cancel-btn-group :isSingleSubmit="true"
                               @confirmClick="comfireBten"
                               @cancelClick="cancelBtn"></save-cancel-btn-group>

        <!-- <common-button-group v-on:buttonClickEvent="comfireBten"
                             v-on:closeDialog="cancelBtn"></common-button-group> -->
      </div>
    </div>
    <div v-if="showStudentList">
      <student-list v-on:selectedItem="selectedItem"></student-list>
    </div>
  </div>

</template>

<script>

import { getPlanForArrearageList, getStudentAllCourse, deductionOwedClassHour } from '../../../API/workbench';
import studentLable from '../../common/student-lable';
import studentList from '../../common/student-list';
import studentOweCourseItem from '../../common/student-owe-course-item';
import studentOweClassCountItem from '../../common/student-owe-class-count-item';
export default {
  props: ['studentItem', 'unShowReadStudnetInfo'],
  data () {
    return {
      studentInfo: {},                // 初始化学生信息.
      showStudentList: false,
      selectorCourseItem: {},          // 选中的课  暂无用处.
      selectorCourseNameKey: '',       // 默认选中的值.
      allCourseList: [],             // 该学员的所有课单.
      studentOweClassList: [],
      studentTotalOweClassList: [],
      BJTotalClassCount: 0,
      courseForm: '',
      remarks: '',         // 抵扣欠课时接口需要的备注.
      TableID: '',     // 选中的课单ID.
      value6: '',
      oweClassHour: '', // 学生档案传过来的学生欠课时数
      isShow: true,
      selectedCourseBill: null   // 选中课单
    };
  },
  components: {
    studentLable, studentList, studentOweClassCountItem, studentOweCourseItem
  },
  watch: {
    studentItem () {                 // 初始化学生信息.
      this.studentInfo = this.studentItem;
    },
    studentInfo () {
      this.selectedCourseBill = null;
      this.getOweclassList(this.studentInfo.OLAPKey);
      this.getStudentAllCourseForID(this.studentInfo.OLAPKey);
    },
    allCourseList () {
      if (this.allCourseList.length == 0) {
        this.selectorCourseNameKey = '';
      }
    }
  },
  computed: {
    courseNameKeyList () {                            // 去重.  绑定选中的值
      let courseNameKeyList = [];
      this.studentTotalOweClassList.forEach(o => {
        if (o.CourseNameKey == this.selectorCourseNameKey) {
          courseNameKeyList.push(o);
        }
      });
      courseNameKeyList.sort(function (x, y) {
        return Date.new(y.ClassTime.date) - Date.new(x.ClassTime.date);
      });
      return courseNameKeyList;
    },
    DeductionOfClass () {                         // 同一个课名下的总欠课时
      let count = 0;
      this.courseNameKeyList.forEach(o => {
        count += Number(o.ClassCount);
      });
      return count.toFixed(1);
    }
  },
  created () {                     // 初始化学生信息.
    this.studentInfo = this.studentItem;
  },
  mounted () {
    this.$dialog.register(this, ['changeCourseBill']);
  },
  methods: {
    choseItem (val) {           // 绑定下拉框事件. TableID
      this.courseForm = val.NumberName;
      this.TableID = val.TableID;
      this.selectedCourseBill = val;
    },
    setDeductionOwedClassHour (TableID, CourseNameKey, remarks) {
      if (!this.studentInfo.OLAPKey) {
        this.studentInfo.OLAPKey = this.studentInfo.StudentKey; // 预警传过来的学生ID不同
      }
      deductionOwedClassHour(TableID, CourseNameKey, remarks).then(result => {
        this.$bus.emit('AfterRepayClassHour', this.studentInfo.OLAPKey);
        this.$emit('backClassHourSuccess', this.studentInfo.OLAPKey);
        this.getStudentAllCourseForID(this.studentInfo.OLAPKey);
        this.getOweclassList(this.studentInfo.OLAPKey); // 刷新欠课时页面
        layer.alert('抵扣成功');
        this.cancelBtn();
      }, r => {
        // if (r.code == 60005 || r.code == 60006) {
        //   layer.alert(r.msg);
        //   this.$emit('confirmDebtCourseHoursDetails', r);
        //   this.$emit('backClassHourSuccess', this.studentInfo.OLAPKey);
        //   this.cancelBtn();
        // } else {
        //   layer.alert(r.msg);

        // }
        // this.getOweclassList(this.studentInfo.OLAPKey); // 刷新欠课时页面
        var that = this;
        let text = '';
        if (r.code == '60004') {
          text = '，是否去变更课单适用范围？';
        }
        layer.confirm(
          r.msg + text,
          {
            btn: [
              {
                name: '确认',
                callBack: function () {
                  that.toChangeCourseBilk();
                  layer.close();
                }
              }
            ]
          }
        );
      }).finally(() => {
        // console.log("ceshi1111")
        // this.getStudentAllCourseForID(this.studentInfo.OLAPKey);
      });
    },
    // 去修改课单
    toChangeCourseBilk () {
      this.$dialog.open(this, {
        name: '修改课单',
        routerName: this.$route.name,
        moduleName: 'changeCourseBill',
        data: { dataInfo: this.studentInfo, courseInfo: this.selectedCourseBill },
        callBack: { updateCourseEvent: () => { this.getOweclassList(this.studentInfo.OLAPKey); } }
      });
    },
    pitchClassItem (item) {
      this.studentOweClassList.forEach(o => {
        if (item.CourseNameKey == o.CourseNameKey) {
          o.isChoose = true;
        } else {
          o.isChoose = false;
        }
      });

      this.selectorCourseItem = item;
      this.selectorCourseNameKey = item.CourseNameKey;
    },
    getStudentAllCourseForID (OLAPKey) {                      // 学员ID获取所有课单.
      this.allCourseList = [];// [0,1,1,2,3,5,4,6]         //学生发生改变时直接清空数组
      if (OLAPKey > 0) {
        getStudentAllCourse(OLAPKey).then(result => {
          if (result.data.length > 0) {
            result.data.forEach(o => {
              // 把已过去及当前数量为0的排除.
              if (o.UnitKey == 5) {
                if (Number(o.CurrentCount) > 0 && Date.new(o.ExpireTime) >= Date.new(this.$utils.formatDatet(Date.new(), 'yyyy-MM-dd'))) {
                  if (o.IsTrialKey == 0 && o.StatusKey == 2) {
                    this.allCourseList.push(o);
                  }
                }
              } else {
                if (Number(o.PeriodCount) > 0 && Date.new(o.ExpireTime) >= Date.new(this.$utils.formatDatet(Date.new(), 'yyyy-MM-dd'))) {
                  if (o.IsTrialKey == 0 && o.StatusKey == 2) {
                    this.allCourseList.push(o);
                  }
                }
              }
            });
          }
          console.log(this.allCourseList, 'this.allCourseList');
        });
      }
    },
    getOweclassList (OLAPKey) {        // 获取学生欠课时课.
      if (this.studentInfo.OLAPKey > 0) {
        getPlanForArrearageList(OLAPKey).then(result => {
          this.studentOweClassList = [];
          if (result.data.length > 0) {
            result.data.forEach(element => {
              element.isChoose = false;
            });
            result.data[0].isChoose = true;
            this.selectorCourseNameKey = result.data[0].CourseNameKey;
          }
          this.studentTotalOweClassList = result.data;
          this.studentTotalOweClassList.forEach(o => {                    // 重组数据.                //这里逻辑有问题.  无法变为数字追加.
            let monthItem = this.studentOweClassList.find(p => {
              return p.CourseNameKey == o.CourseNameKey;
            });
            if (!monthItem) {
              monthItem = {
                isChoose: false,
                CourseNameKeyValue: o.CourseNameKeyValue,
                CourseNameKey: o.CourseNameKey,
                totalClassCount: 0
              };
              this.studentOweClassList.push(monthItem);
            }
            monthItem.totalClassCount += Number(o.ClassCount) || 0;
          });
          if (this.studentOweClassList.length > 0) {
            this.studentOweClassList[0].isChoose = true;
          }
          if (JSON.stringify(this.selectorCourseItem) != '{}') {
            this.pitchClassItem(this.selectorCourseItem);
          } else {
            this.pitchClassItem(this.studentOweClassList[0]);
          }
          this.selectedClassItem(); // 选中
        }).catch(error => {
          console.log(error);
        });
      }
    },
    selectedClassItem () {
      if (Number(this.studentItem.CourseNameKey) > 0 && this.studentOweClassList.length > 0) {
        this.studentOweClassList.forEach((o, index) => {
          if (o.CourseNameKey == this.studentItem.CourseNameKey) {
            this.pitchClassItem(o);
            this.$nextTick(() => {
              this.$refs.dataScroll.scrollLeft = index * 205 + index * 2;
              console.log(this.$refs.dataScroll.scrollLeft, o, '去还款');
            });
          }
        });
      }
    },
    unique1 (arr) {                 // 去重
      var result = [];
      var obj = {};
      for (var i = 0; i < arr.length; i++) {
        if (!obj[arr[i].CourseNameKey]) {
          result.push(arr[i]);
          obj[arr[i].CourseNameKey] = true;
        }
      }
      return result;
    },
    selectedItem (item) {           // 学生列表选中学生
      this.studentInfo = item;
      this.showStudentList = false;
      this.courseForm = '';
    },
    changeStudent () {            // 显示学生列表.
      this.showStudentList = true;
      // this.$parent.searchDialogVisible = true
      // this.studentArrangeFormDialogVisible = false
    },
    cancelBtn () {
      this.$emit('closeDialog');
      this.$emit('buttonClickEvent', false);        // 学员档案中传值false为直接关闭.
    },
    comfireBten (callback) {
      let flag = false;
      if (!this.TableID) {
        layer.alert('请选择课单');
        flag = true;
      } else if (!this.selectorCourseNameKey) {
        layer.alert('请选择课');
        flag = true;
      }
      if (flag) {
        if (callback) {
          callback();
        }
        return false;
      }
      this.setDeductionOwedClassHour(this.TableID, this.selectorCourseNameKey, this.remarks);
    },
    showStudentFile (item) {
      this.$emit('showStudentFile', item);
    }
  }
};
</script>

  <style>
</style>