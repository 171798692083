
<template>
  <!-- 选中课块 详情 -->
  <div class="course_atten"
       v-show="vSelectedCourseInfo">
    <div v-if="!isSuspend"
         :class="contentClassName"
         class="atten_scroll">
      <!-- 学生块 -->
      <div v-if="studentArrangeList.length> 0 || isAlter"
           :class="isAlter?'':'no_add'">
        <student-arrange-list ref="studentArrangeList"
                              :isTeacher="isTeacher"
                              :selectedCourseInfo="selectedCourseInfo"
                              :isBatchAttendance="isBatchAttendance"
                              @updateStudentFileEvent="updateStudentFileEvent"
                              @changeAttendanceStutus="afterChangeAttendanceStutus"></student-arrange-list>
      </div>
      <!-- 无数据展示 -->
      <div v-else
           class="course_block_nodata"
           style="background-color: #fff;">
        <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>

      </div>
    </div>
    <!-- 操作按钮 -->
    <div v-if="isAlter"
         class="course_student_btn clearfix"
         :class="{no_border_top:isSuspend}">
      <div class="course_student_btn_left fl">
        <function-btn v-if="vIsExpireDate && vIsClassStop!=1"
                      :item="editbtn"
                      @clickItem="editCourseShow"
                      :className="'course_student_btn_list btn_hover_white fl'"></function-btn>
        <function-btn v-if="selectedCourseInfo.IsClassStopKey == 0 && vIsExpireDate && vIsClassStop!=1"
                      :item="stopbtn"
                      @clickItem="courseSuspend"
                      :className="'course_student_btn_list btn_hover_white fl'"></function-btn>
      </div>
      <function-btn v-if="vIsClassStop!=1"
                    :item="attenbtn"
                    @clickItem="batchAttendance"
                    :className="'course_student_btn_list btn_hover_blue course_student_btn_right fr'"></function-btn>
      <!-- <div v-if="isShowEditCourseDialog">
        <edit-course :vchangeCourseDialogVisible="isShowEditCourse"
                     :courseInfo="selectedCourseInfo"
                     @editCourse="editCourse"
                     @showEditCourse="showEditCourse"
                     @chgClassStopKey="editCourse"
                     ref="editCourse"></edit-course>
      </div> -->
    </div>
  </div>
</template>
<script>
import studentArrangeList from './student-list';
import editCourse from '../../../common/edit-course';
import { PauseScheduleCourse, ScheduleCourseRecovery } from '../../../../API/workbench';
export default {
  name: 'studentArrange',
  props: {
    selectedCourseInfo: Object,
    isAlter: Boolean,
    studentArrangeList: Array,
    isSuspend: Boolean,
    isTeacher: Boolean,
    contentClassName: {
      type: String,
      default: 'index_tabs_two'
    }
  },
  data () {
    return {
      studentArrangeListJson: [],
      isBatchAttendance: false,
      detStudentNames: '',
      rejectBatchAttendanceArray: [],
      finish: false,
      isShowEditCourse: false,
      isShowEditCourseDialog: false

    };
  },

  components: {
    studentArrangeList, editCourse
  },
  computed: {
    vSelectedCourseInfo () {
      return JSON.stringify(this.selectedCourseInfo) != '{}';
    },
    vIsClassStop () {
      return this.selectedCourseInfo.IsClassStopKey;
    },
    vIsExpireDate () {
      if (this.$utils.getFormatDateToQuery(this.selectedCourseInfo.ClassTime + ' ' + this.selectedCourseInfo.BeginClassTime).getTime() + 5184000000 > Date.new().getTime()) {
        return true;
      }
    },
    editbtn () {
      return {
        name: '修改单课',
        ModulePowerKey: this.isTeacher ? '' : 47
      };
    },
    stopbtn () {
      console.log(this.isTeacher);
      return {
        name: '停课',
        ModulePowerKey: this.isTeacher ? '' : 47
      };
    },
    attenbtn () {
      return {
        name: '批量签到',
        ModulePowerKey: this.isTeacher ? '' : ''
      };
    },
    openbtn () {
      return {
        name: '恢复上课',
        ModulePowerKey: this.isTeacher ? '' : 47
      };
    }
  },
  watch: {
    rejectBatchAttendanceArray (n, o) {
      if (this.finish == true & this.rejectBatchAttendanceArray.ScheduleCourseKey) {
        this.$refs.changeBatchAttendance(this.rejectBatchAttendanceArray, 1);
      }
    }
  },
  mounted () {
    this.$dialog.register(this, ['editCourse']);
  },
  methods: {
    // 更新外部学生信息
    updateStudentFileEvent (StudentKey, ScheduleCourseKey) {
      this.$emit('updateStudentFileEvent', StudentKey, ScheduleCourseKey);
    },
    // 变更考勤成功
    afterChangeAttendanceStutus (scheduleCourseKey, studentKey, courseStatusKey) {
      this.$bus.emit('AfterChangeAttendanceStatus', scheduleCourseKey, studentKey, courseStatusKey);
    },
    showEditCourse () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'editCourse',
        data: { courseInfo: this.selectedCourseInfo }
      });
    },
    editCourse () {
      // this.showEditCourse();
      this.getCourseInfo();
    },
    // 批量考勤
    batchAttendance () {
      console.log('批量考勤');

      this.isBatchAttendance = true;
      this.$parent.isBatchAttendance = true;
      this.$nextTick(() => {
        this.$refs.studentArrangeList.$refs.selectCourseStudentList.scrollTop = this.$refs.studentArrangeList.$refs.selectCourseStudentList.firstChild.clientHeight;
      });
    },
    // 取消批量考勤
    cancleBatchAttendance () {
      this.isBatchAttendance = false;
      this.$parent.isBatchAttendance = false;
    },
    // 修改课程.
    editCourseShow () {
      // this.isShowEditCourseDialog = true;
      // this.$nextTick(() => {
      //   this.isShowEditCourse = true;
      //   this.$refs.editCourse.CourseDetailed(this.selectedCourseInfo.ScheduleCourseKey);
      //   this.$refs.editCourse.isAllowedUpdateClassCount(this.selectedCourseInfo.ScheduleCourseKey);
      // });
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'editCourse',
        data: { courseInfo: this.selectedCourseInfo },
        callBack: {afterSucces: () => { this.getCourseInfo(); }}
      });
    },
    // 恢复上课
    restoreCourse () {
      var that = this;
      layer.confirm('是否恢复上课', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: function () {
              that.setCourseRecovery();
              layer.close();
            }
          }
        ]
      });
    },
    // 根据课名ID 恢复课
    setCourseRecovery () {
      ScheduleCourseRecovery(this.selectedCourseInfo.ScheduleCourseKey).then(
        result => {
          this.getCourseInfo();
          layer.alert('恢复课成功');
          this.$bus.emit('AfterBatchEditCourses');
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 停课
    courseSuspend () {
      let that = this;
      layer.confirm('是否停课', {
        btn: [{
          name: '取消',
          callBack: function () {
            layer.close();
          }
        }, {
          name: '确认',
          callBack: function () {
            that.setCourseCancel(1);
            that.$bus.emit('AfterBatchEditCourses');
            layer.close();
          }
        }]
      });
    },
    getCourseInfo () {
      this.$emit('refresh', this.selectedCourseInfo.ScheduleCourseKey, this.selectedCourseInfo);
    },
    setCourseCancel (IsNotEndKey) {
      if(!this.selectedCourseInfo.ScheduleCourseKey){
        return false;
      } 
      PauseScheduleCourse(this,this.selectedCourseInfo.ScheduleCourseKey, IsNotEndKey).then(result => {
        layer.alert('停课成功');
        this.getCourseInfo();
        this.$emit('closeCourse');
      }, error => {
        if (error.code == 70001) {
          layer.confirm(error.msg, {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.setCourseCancel(0);
                  layer.close();
                }
              }
            ]
          });
        } else {
          layer.alert(error.msg);
        }
      });
    }
  }
};
</script>