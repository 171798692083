<template>
  <el-popover>
    <drop-down-box :optionJsonList="dropDownList"
                   @clickOptionForAlertDialog="doAfterClick"></drop-down-box>
    <div>
      <slot></slot>
    </div>
  </el-popover>
</template>

<script>
import dropDownBox from './drop-down-box';
export default {
  props: {
    position: {
      type: String,
      default: 'bottom'
    },
    width: {
      type: Number,
      default: 200
    },
    triggerType: {
      type: String,
      default: 'click'
    },
    dropDownList: Array
  },
  components: {
    dropDownBox
  },
  methods: {
    doAfterClick () {

    }
  }
};
</script>

<style>
</style>