<template>
  <!-- 通用列表 -->
  <div class="table_list_content"
       style="border-radius:8px;padding:0 20px">
    <div class="single_result_detail">
      <!-- 筛选条件 -->
      <div class="summarizing_number_left"
           style="padding-left:0">
        <span class>学生回收站</span>
      </div>
      <div class="summarizing_student_top_box content_top_data_box course_class_summarizing_right ">
        <div class="flex">
          <!-- 已选条件 -->
          <!-- <div class="content_search fr"
               style="margin-bottom:20px;margin-top:0">
            <div class="course_search_form"
                 style="margin-right:7px;width: 280px;">
              <div class="course_search_input"
                   style="width: 100%;">
                <input type="text"
                       :placeholder="'请输入姓名、姓名首字母、手机号'"
                       v-model="searchText" />
              </div>
            </div>
            <el-button class="btn_light_blue"
                       style="margin-right:7px"
                       @click.stop="searchStudent">搜索</el-button>
            <el-button class="btn_light_white"
                       @click.stop="clearExtendSearchParams">清除条件</el-button>
          </div> -->
          <input-search-contain-btn v-model="searchText"
                                    :width="'280px'"
                                    :placeholder="'请输入姓名、姓名首字母、手机号'"
                                    @clearSearch="clearExtendSearchParams"
                                    @onSearchEvent="searchStudent"></input-search-contain-btn>
        </div>
      </div>
      <!-- 
          选中行高亮  highlight-current-row 
          隔行换色  stripe
          数据为空展示的文字  empty-text
          选中行 复选框联动  toggleRowSelection
        -->
      <div>
        <el-table element-loading-text="拼命加载中"
                  class="table_list"
                  ref="multipleTable"
                  :data="vtableData"
                  highlight-current-row
                  stripe
                  :border="false"
                  empty-text
                  toggleRowSelection
                  @sort-change="sortChange"
                  :default-sort="defaultSort"
                  @row-click="clickRow"
                  :row-key="rowKey||'none'"
                  :expand-row-keys="expandRowKeys"
                  style="width: 100%">
          <!-- 表头 -->
          <el-table-column type="selection"
                           fixed
                           width="55"
                           v-if="vIsSearchText"></el-table-column>
          <!-- fixed:true, left, right -->
          <el-table-column v-for="(item,index) in vTableColumns"
                           :fixed="item.fixed||false"
                           :width="item.width"
                           :sortable="item.sortable"
                           :align="item.align"
                           :key="index"
                           :label="item.label"
                           :prop="item.prop"
                           :show-overflow-tooltip="true&&(!item.type||item.type!='popUp')&&item.type!='student'"
                           :label-class-name="item.columnClass"
                           :sort-orders="['ascending', 'descending']">
            <template slot-scope="scope"
                      v-if="!item.childColumn">
              <span v-if="item.type=='number'">{{rounding(scope.row,item.prop,item.decimalPlace)}}</span>
              <div v-else-if="item.type=='studentPhoto'"
                   class="cell_studentPhoto">
                <img v-if="scope.row[item.prop]"
                     :src="scope.row[item.prop]"
                     alt="">
              </div>
              <div v-else-if="item.type=='studentName' && scope.row[item.prop]"
                   class="cell_studentName">
                <div class="studentName_name"
                     :class="Number(scope.row['SexKey']) == 2?'boy':'gril'">{{scope.row[item.prop]}}</div>
                <div class="studentName_phone font_gray">{{$utils.phoneModulePower(scope.row['CustomerPhoneName'])}}</div>
              </div>
              <el-button v-else-if="item.type=='tableBtn'"
                         v-for="(info,indexs) in item.tableBtnGroup"
                         :class="[info.className(scope.row[info.Stutas]),vHasModulePower(info.ModulePowerKey)?'':'norole']"
                         :style="!scope.row.OLAPKey?'opacity: 0;cursor: default':''"
                         class="tableBtn"
                         :key="indexs"
                         size="mini"
                         @click.stop="tableBtnClick(scope.row,info)">{{info.eventName == 9?ifBtnName(scope.row):info.name}}</el-button>
              <span v-else-if="item.type=='popDialog' && item.className == 'edit_icon' && scope.row.OLAPKey"
                    :class="item.className"
                    @click.stop="editContentBtnClick(scope.row)"><input type="text"
                       placeholder="请输入"
                       disabled
                       :value="scope.row[item.prop]"></span>
              <span v-else-if="item.type=='popDialog' && scope.row.OLAPKey"
                    :class="item.className"
                    @click.stop="editContentBtnClick(scope.row)">{{scope.row[item.prop]}}</span>
              <span v-else-if="scope.row.OLAPKey"
                    :class="item.className">{{scope.row[item.prop]}}</span>
              <span class="bottom_warn"
                    :class="item.className"
                    v-if="item.isBottomWarn&&scope.row[item.bottomWarnProp]">
                <el-popover v-if="scope.row[item.prop+'_Pop_Tips']"
                            trigger="hover"
                            placement="top">
                  <p v-html="scope.row[item.prop+'_Pop_Tips']"></p>
                  <div slot="reference">
                    <div size="">{{scope.row[item.bottomWarnProp]}}</div>
                  </div>
                </el-popover>
                <span v-else>{{scope.row[item.bottomWarnProp]}}</span>
              </span>

            </template>
          </el-table-column>
          <div slot="empty"
               style="height:620px;line-height:620px;">
            <i class="el-icon-warning-outline">
              <div class="monitoring_content_nodata"> </div>
              亲，当前没有任何数据哦~
            </i>
          </div>

        </el-table>
        <!-- 分页 -->
        <div class="table_pagination table_pagination_right">
          <el-pagination @current-change="handleCurrentChange"
                         :page-size="queryParams.pageSize"
                         :current-page.sync="vPageIndex"
                         background
                         :pager-count="5"
                         layout="prev, pager, next, jumper"
                         :total="pageTotal>0?pageTotal:10"></el-pagination>
          <table-pagination-number :pageSize="queryParams.pageSize"
                                   :queryParams="queryParams"
                                   @loadTableData="loadTableData"></table-pagination-number>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import summaryDataItem from '../summary-data/item';
import inputSearchContainBtn from '../common/input-search-contain-btn';
import tablePaginationNumber from '../../common/table-pagination-number';
export default {
  data () {
    return {
      searchText: '',
      queryParams: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: 'asc', // 排序字段
        status: 0,        //	0全部  学生状态 1-在校; 2-潜客;3-离校
        QueryID: ''
      },
      selectedRow: {}, // 选中行
      expandRowKeys: [], // 展开的行,
      isShowInnerScroll: true,
      isLoading: true,
      isShowSummary: false,
      studentDetailList: [], // 学生的报课详情
      studentAllValidCourseBill: null, // 学生的所有有效课单
      curSelectedStudentInfo: {},
      countNum: 0,
      authbtn: {
        name: '',
        ModulePowerKey: 51
      },
      searchValue: 1, // 默认搜学生
      options: [
        { label: '全部', value: 0 },
        { label: '在校', value: 1 },
        { label: '潜客', value: 2 },
        { label: '离校', value: 3 }
      ],
      addbtn: {
        name: '',
        type: 'tjxs',
        ModulePowerKey: 48
      }

    };
  },
  props: {
    tableData: Array, // 列表数据
    detailColumns: Array, // 显示详情内容
    rowKey: String, // 显示详情对应绑定的ID
    totalNum: Number, // 全部学生数
    pageTotal: Number, // 分页总共条数
    tableColumns: Array, // table column
    studentInSchoolTotalNum: [String, Number],
    paramsForStudent: Object,
    isNeedInnerScroll: {
      type: Boolean,
      default: false
    },
    isShowSearch: Boolean,
    studentDetail: {
      type: Boolean,
      default: false
    },
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: '', order: 'ascending' };
      }
    }
  },
  components: {
    summaryDataItem,
    inputSearchContainBtn,
    tablePaginationNumber
  },
  computed: {
    // 不足10行时空行补充
    vtableData () {
      let items = {
        OLAPKey: '',
        studentInfo: []// 已有用到的数组需实例化，不然渲染时会报错
      };
      let arr = [];
      let oldTableDataLength = this.$utils.parseJson(this.tableData.length);
      if (this.tableData.length < this.queryParams.pageSize && this.tableData.length > 0) {
        arr = this.tableData;
        for (let index = 1; index <= (this.queryParams.pageSize - oldTableDataLength); index++) {
          arr.push(items);
        }
        return arr;
      } else {
        return this.tableData;
      }
    },
    modulePowerList () {
      return this.$store.getters.RolePowerList;
    },
    vIsSearchText () {
      if (this.isSearchText) {
        return true;
      } else {
        return false;
      }
    },
    vPageIndex: {
      get () {
        return this.queryParams.pageIndex + 1;
      },
      set (n, o) {
        this.queryParams = this.paramsForStudent;
      }
    },
    vTableColumns () {
      let newTableColumns = [];
      this.tableColumns.forEach(element => {
        if (element.isShow) {
          newTableColumns.push(element);
        }
      });
      return newTableColumns;
    },
    vHeadImg: {
      get () {
        // 头像
        let pcDomainURLOld = this.$store.getters.CDNURL;
        if (!this.curSelectedStudentInfo.HeadImgSupport) {
          this.curSelectedStudentInfo.HeadImgSupport = '';
          if (parseInt(this.curSelectedStudentInfo.SexKey) == 2) {
            return require('../../../../public/image/deflut_studentPhoto_boy.png');
          } else {
            return require('../../../../public/image/deflut_studentPhoto_gril.png');
          }
        }
        if (this.curSelectedStudentInfo.HeadImgSupport.indexOf('http') > -1) {
          return this.curSelectedStudentInfo.HeadImgSupport;
        } else if (this.curSelectedStudentInfo.HeadImgSupport && this.curSelectedStudentInfo.HeadImgSupport.indexOf('/') == -1) {
          return pcDomainURLOld + '/' + this.curSelectedStudentInfo.HeadImgSupport;
        } else {
          return pcDomainURLOld + this.curSelectedStudentInfo.HeadImgSupport;
        }
      },
      set () {

      }

    },
    vMobile () {
      return this.$utils.phoneModulePower(this.curSelectedStudentInfo.CustomerPhoneName) || '无手机号';
    },
    vAgeName () {
      if (this.curSelectedStudentInfo.AgeName > 0) {
        return this.curSelectedStudentInfo.AgeName + '岁';
      } else {
        return '无年龄'
          ;
      }
    }
  },
  watch: {
    tableColumns (n, o) {
      if (this.isNeedInnerScroll) {
        setTimeout(() => {
          this.caculInnerScroll();
        }, 800);
      }
    }

  },
  created () {
    this.searchConditionType = this.$initJson.extendSearchConditionType;

    console.log(this.studentDetailList, 'studentDetailList');
  },
  mounted () {
    if (this.isNeedInnerScroll) {
      setTimeout(() => {
        this.caculInnerScroll();
      }, 600);
    }
  },
  methods: {
    searchStudent () {
      this.queryParams.searchText = this.searchText;
      this.$emit('searchStudent', this.queryParams.searchText);
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      ModulePowerKey = ModulePowerKey || '';
      return this.$utils.CheckModulePower(this.modulePowerList, ModulePowerKey);
    },
    applyCourseClick (item, val) {
      if (val && Number(val) == 0) {
        return false;
      }
      this.$emit('applyCourseClick', item);
    },
    arrangeClick (item, val) {
      if (val && Number(val) == 0) {
        return false;
      }
      this.$emit('arrangeClick', item);
    },
    // 跳转到第几页
    handleCurrentChange (val) {
      console.log(val, '跳转到第几页');
      this.selectedRow = {};
      this.$refs.multipleTable.setCurrentRow();
      this.queryParams.pageIndex = val - 1;
      // this.queryParams.searchText = this.searchText;
      this.$emit('loadTableData', this.queryParams);
    },
    // 保留小数位数
    rounding (row, filedName, decimalPlace) {
      if (row[filedName] === '') {
        return row[filedName];
      } else if (row[filedName] == 0) {
        return '-';
      } else {
        if (!row[filedName]) {
          return '';
        } else {
          return this.$utils.mAmountType(row[filedName], 1);
        }
      }
    },
    // 日期转换
    convertDateformat (row, value, type) {
      if (type == 'YYMMDD') {
        return this.$utils.formatDateToLine(Date.new(row[value]));
      } else {
        return row[value];
      }
    },
    // 排序
    sortChange (column, prop) {
      if (column.prop == 'studentInfo' && this.queryParams.orderBy == 'StudentKeyValue' && column.order == 'ascending' && this.queryParams.sequence == 'asc') {
        return;
      }
      if (column.prop) {
        this.queryParams.orderBy = column.prop;
        this.queryParams.sequence = column.order == 'ascending' ? 'asc' : 'desc';
        if (column.prop == 'StudentStatusKeyValue') {
          this.queryParams.orderBy = 'StudentStatusKey';
        }
      }
      this.$emit('loadTableData', this.queryParams);
    },
    editContentBtnClick (rowData) {
      let item = {};
      item.eventName = this.$initJson.baseFunctionBtnName.popDialog;
      this.tableBtnClick(rowData, item);
    },
    clearExtendSearchParams () {
      this.searchText = '';
      this.expandRowKeys = [];
      this.queryParams.pageIndex = 0;
      this.queryParams.status = 0;
      this.queryParams.searchText = 0;
      this.$emit('clearSearchParams');
    },
    // 点击表单按钮
    tableBtnClick (rowData, item, val) {
      if (!rowData.OLAPKey || (val && Number(val) == 0)) {
        return false;
      }
      this.curSelectedStudentInfo = rowData;
      if (this.vHasModulePower(item.ModulePowerKey)) {
        if (item.eventName == this.$initJson.baseFunctionBtnName.detail) {
          if (rowData[this.rowKey] == this.expandRowKeys[0]) {
            this.expandRowKeys = [];
          } else {
            this.expandRowKeys = [];
            this.expandRowKeys.push(rowData[this.rowKey]);
            this.$emit('tableBtnClick', rowData, item.eventName);
          }
        } else {
          this.$emit('tableBtnClick', rowData, item.eventName);
        }
      } else {
        this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
      }
    },
    // 选择搜索类型
    chgSearchValue (value) {
      console.log(value, '选择搜索类型');
      this.queryParams.status = value;
      this.queryParams.pageIndex = 0;
      this.searchText = '';
      this.queryParams.searchText = '';
      // if (this.searchValue == 1) { // 学生
      this.$emit('loadTableData', this.queryParams);
      // }
    },
    // 选中行
    clickRow (row, column, event) {
      console.log(row, column, event, 'column, prop');
      this.selectedRow = row;
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row, true);
    },

    caculInnerScroll () {
      // table的宽度
      let tableWidth = document.getElementsByClassName('el-table table_list')[0] ? document.getElementsByClassName('el-table table_list')[0].clientWidth : 0;
      // table内容的宽度
      let contentWidth = document.getElementsByClassName('el-table__body')[0] ? document.getElementsByClassName('el-table__body')[0].clientWidth : 0;
      // table 左边固定宽
      let leftFix = document.getElementsByClassName('el-table__fixed')[0] ? document.getElementsByClassName('el-table__fixed')[0].clientWidth : 0;
      // table 右边固定宽
      let rightFix = document.getElementsByClassName('el-table__fixed-right')[0] ? document.getElementsByClassName('el-table__fixed-right')[0].clientWidth : 0;
      // 合计的高度
      let countColumnHeight = document.getElementsByClassName('el-table__fixed-footer-wrapper')[0] ? document.getElementsByClassName('el-table__fixed-footer-wrapper')[0].offsetHeight : 0;

      if (tableWidth >= contentWidth) {
        this.isShowInnerScroll = false;
      } else {
        this.isShowInnerScroll = true;
        if (document.getElementsByClassName('el-table__body-wrapper')[0]) {
          document.getElementsByClassName('el-table__body-wrapper')[0].style.overflowX = 'hidden';
        }
        this.$nextTick(() => {
          document.getElementsByClassName('scroll_content')[0].style.width = contentWidth + 'px';

          document.getElementsByClassName('float-scroll')[0].style.width = (tableWidth - leftFix - rightFix) + 'px';
          document.getElementsByClassName('float-scroll')[0].style.marginLeft = leftFix + 'px';
          document.getElementsByClassName('float-scroll')[0].style.marginTop = -countColumnHeight + 'px';
        });
      }
    },
    scrollEvent (e) {
      let ETarget = e.target;
      // table的宽度
      let tableWidth = document.getElementsByClassName('el-table table_list')[0].clientWidth;
      // table内容的宽度
      let contentWidth = document.getElementsByClassName('el-table__body')[0].clientWidth;
      // 左边固定宽
      let leftFix = document.getElementsByClassName('el-table__fixed')[0].clientWidth;
      // 右边固定宽
      let rightFix = document.getElementsByClassName('el-table__fixed-right')[0].clientWidth;
      // 滚动条外框的宽度
      document.getElementsByClassName('float-scroll')[0].style.width = (tableWidth - leftFix - rightFix) + 'px';

      document.getElementsByClassName('el-table__body-wrapper')[0].scrollLeft = (ETarget.scrollLeft / (contentWidth / (contentWidth - leftFix - rightFix))) - 120;
    },
    popTitle (data) {
      if (data && data.length > 10) {
        return data;
      } else {
        return '';
      }
    },
    studentTitle (data) {
      // console.log(data, '学生');
      if (data && data.length > 10) {
        return data;
      } else {
        return '';
      }
    },
    popStudentTitle (data) {
      if (data && data.length >= 5) {
        return data;
      } else {
        return '';
      }
    },
    // 新增学生
    btnClick (eventName) {
      this.$emit('btnFnClick', eventName);
    },
    loadTableData (queryParams) {
      this.$emit('loadTableData', queryParams);
    }
  }
};
</script>
<style>
/* .search_top {
   
} */
.search_top .el-select .el-input .el-select__caret {
  color: #333;
  font-size: 8px;
  position: relative;
  top: -1px;
  left: 13px;
  /* border-left: 1px solid #e6e6e6; */
}
</style>

