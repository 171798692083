<template>
  <div class="
      attract_investment_manage
      all_width
      table_list_content
      summarizing_list
    ">
    <div class="investor_manage_top pr">
      <div class="statistics-box">
        <statistics-box :dataList="statisticsData"
                        @clickEvent="clcikQueryType"></statistics-box>
      </div>
      <div class="recycle-bin btn_hover_bg_blue"
           @click="clickRecycle">
        回收站
      </div>
    </div>
    <component :key="curShowTableList.index"
               :curSelectedPrams="outSideParams"
               @showInvestorDetails="showInvestorDetails"
               @updataTableData="reloadStatictisData"
               v-bind:is="curShowTableList.type"
               @openCommonCustomDialogByFactoryDialog="openCommonCustomDialogByFactoryDialog"
               ref="InvestmentManagementList"></component>

    <dialog-factory ref="attractInvestmentManageDialogFactory"
                    :key="'attractInvestmentManage'"
                    :routerName="'attractInvestmentManage'"></dialog-factory>

    <dialog-factory-common ref="attractInvestmentManageFactoryCommon"></dialog-factory-common>
  </div>
</template>

<script>
import statisticsBox from '../../report/common/statistics-box'; // 统计栏
import newInvestorList from './table-list/new-investor-list'; // 新投资人列表
import followingList from './table-list/following-list'; // 跟进中列表
import giveUpList from './table-list/give-up-list'; // 已放弃列表
import signBillList from './table-list/sign-bill-list'; // 已签单列表
import followRecordsList from './table-list/follow-records-list'; // 跟单记录列表
import dialogFactory from '../../common/dialog-factory';
import { InvestorManageStat } from '../../../API/workbench';
export default {
  name: 'attractInvestmentManage',
  provide () {
    return {
      addInvestor: this.addInvestor
    }
  },
  data () {
    return {
      statisticsData: {
        newInvestor: {
          title: '新投资人',
          value: '',
          type: 'new-investor-list',
          isSelection: true,
          index: 1
        },
        following: {
          title: '跟进中',
          value: '',
          type: 'following-list',
          isSelection: false,
          index: 2
        },
        giveup: {
          title: '已放弃',
          numberClassName: 'font_red',
          value: 30,
          type: 'give-up-list',
          isSelection: false,
          index: 3
        },
        signBill: {
          title: '已签单',
          value: '',
          type: 'sign-bill-list',
          isSelection: false,
          index: 4
        },
        followRecords: {
          title: '跟单记录',
          value: '',
          type: 'follow-records-list',
          isSelection: false,
          index: 5
        }
      },
      curShowTableList: null, // 当前显示的投资人列表
      outSideParams: null, // 外部打开传入的参数
      isShowInvestorDetails: false, // 是否显示详情
      investorKey: 0,
      recycleCount: 0
    };
  },
  components: {
    statisticsBox,
    newInvestorList,
    followingList,
    giveUpList,
    signBillList,
    followRecordsList,
    dialogFactory
  },
  computed: {
    vSearchDataRange: {
      get () {
        return {
          startTime: this.paramsForQuery.entryTimeStart,
          endTime: this.paramsForQuery.entryTimeEnd
        };
      },
      set (n, o) {
        this.paramsForQuery.entryTimeStart = n.startTime;
        this.paramsForQuery.entryTimeEnd = n.endTime;
      }
    }
  },
  created () {
    this.initData();
    this.loadStatisticData();
  },
  mounted () {
    this.resgisterBusEvent();
  },
  activated () {
    this.initData('activated');
    this.loadStatisticData();
  },
  methods: {
    // 外部进入初始化数据
    initData (type) {
      let paramsData = this.$route.params.data;

      console.log(paramsData, 'this.$route.params');
      this.outSideParams = Object.assign({}, paramsData || {}, {
        radomNuber: Math.floor(Math.random() * 10000)
      });
      // this.$route.params.type 为数字， 对应列表最上方的数组下标显示，dataInfo是选中的那一项数据
      if (paramsData && Object.keys(paramsData).length > 0) {
        this.curShowTableList = this.statisticsData[paramsData.type];
        for (var i in this.statisticsData) {
          if (i == paramsData.type) {
            this.statisticsData[i].isSelection = true;
          } else {
            this.statisticsData[i].isSelection = false;
          }
        }
      } else if (!type) {
        this.curShowTableList = this.statisticsData.newInvestor;
        this.statisticsData.newInvestor.isSelection = true;
      }
      // else {
      //   this.curShowTableList = this.statisticsData.newInvestor;
      // }
    },
    // 获取投资人相关统计数据
    loadStatisticData () {
      InvestorManageStat().then((result) => {
        this.statisticsData.newInvestor.value = result.data.newThreadCount;
        this.statisticsData.following.value = result.data.FollowUpCount;
        this.statisticsData.giveup.value = result.data.AbandonCount;
        this.statisticsData.signBill.value = result.data.SignBillCount;
        this.statisticsData.followRecords.value = result.data.FollowCount;
        this.recycleCount = result.data.RecycleCount;
      });
    },
    // 分类查询统计数据
    clcikQueryType (item) {
      this.outSideParams = {};
      this.curShowTableList = item;
    },
    // 重新加载统计数据
    reloadStatictisData () {
      this.loadStatisticData();
    },
    // 查看回收站数据
    clickRecycle () {
      let item = {
        routerName: 'investorRecycleBin',
        moduleName: '',
        name: '投资人回收站'
      };
      this.$dialog.openRouter(item, this.$route.name);
    },
    showInvestorDetails (item) {
      // this.isShowInvestorDetails = true;
      // this.investorKey = item.OLAPKey;
      this.$dialog.open(this, {
        name: '投资人详情',
        routerName: this.$route.name,
        moduleName: 'crmImproveClewForm',
        ModulePowerKey: '',
        data: { dataInfo: item, isReadonly: true, isHideEditBtn: true }
      });
    },
    resgisterBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'attractInvestmentManage') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'attractInvestmentManage') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.attractInvestmentManageDialogFactory) {
        this.$refs.attractInvestmentManageDialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.attractInvestmentManageFactoryCommon) {
        this.$refs.attractInvestmentManageFactoryCommon.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title
        );
      }
    },
    addInvestor () {
      if (this.statisticsData.newInvestor.isSelection) {
        this.$refs.InvestmentManagementList.loadInvestorList()
      }
    }
  }
};
</script>

<style scoped>
.attract_investment_manage {
  width: 1290px;
}
.table_list_content {
  border-radius: 8px;
  padding-top: 20px !important;
}
.investor_manage_top {
  display: flex;
  /* margin-bottom: 20px; */
}
.investor_manage_view {
  justify-content: space-between;
  margin-bottom: 10px;
}
.statistics-box {
  width: 550px;
  cursor: pointer;
}
.recycle-bin {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 35px;
  width: 76px;
  line-height: 34px;
  text-align: center;
  border-radius: 4px;
  padding-left: 25px;
  padding-right: 7px;
  background: #3498db url(../../../../public/image/recycled_icon.png) no-repeat
    8px;
}
.query-box {
  display: flex;
  display: -webkit-box;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 13px 20px;
}
.option_btn {
  display: flex;
}
</style>