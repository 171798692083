<template>
  <div class="course_essay_label_box">
    <div class="course_popup_label class_details_label attendance_label">
      <!-- <div class="label_title">
        <div class="label_name">
          <span>{{courseInfo.CourseNameKeyValue}}</span>
          <span class="font_gray">({{courseInfo.GradeClassKeyValue}})</span>
        </div>
        <div v-if="unAttendanceNum > 0"
             class="label_attendance">未考勤{{unAttendanceNum}}人</div>
      </div> -->
      <div class="label_name">
        {{ courseInfo.WeekKeyValue }}{{ courseInfo.ClassTimeName }}
        {{ courseInfo.CourseNameKeyValue }}
      </div>
      <div class="label_text icon_03">
        <span class="">{{ courseInfo.GradeClassKeyValue }}</span> -
        <span>{{ courseInfo.MTeacherKeyValue }}</span>丨{{ Number(courseInfo.PeriodCount) || 0 }}课时
      </div>
      <div class="label_text icon_05">
        安排{{ Number(courseInfo.ArrangedNumCount) || 0 }}人
        <!--   2不扣课时 3扣课时 4建课设置 $utils.getSaaSClientSet(32) == 4 -->

        <span class="font_text"
              v-if="
            (courseInfo.IsleaveKey == 1 && $utils.getSaaSClientSet(32) != 3) ||
              (courseInfo.IsleaveKey == 0 && $utils.getSaaSClientSet(32) != 2)
          ">
          请假{{ courseInfo.IsleaveKey == 1 ? "" : "不" }}扣课时</span>
        <!-- $utils.getSaaSClientSet(33) == 4  -->
        <span class="font_text"
              v-if="
            ((courseInfo.IsChargesKey == 1 &&
              $utils.getSaaSClientSet(33) != 3) ||
              (courseInfo.IsChargesKey == 0 &&
                $utils.getSaaSClientSet(33) != 2)) &&
              this.$utils.getSaaSClientSet(59) > 0
          ">
          旷课{{ courseInfo.IsChargesKey == 1 ? "" : "不" }}扣课时</span>
      </div>
      <div class="label_right_type"
           :class="vStatuClass">
        {{ vStatu }}
      </div>
      <!-- <div class="label_info clearfix">
        <div class="label_info_list ">
          <div class="label_info_list_icon"><img src="../../../../public/image/label_time.png"
                 alt=""></div>
          <span class="font_gray">{{vClassDate}} {{courseInfo.week}} {{courseInfo.BeginClassTime}}-{{courseInfo.EndClassTime}}</span>
        </div>
        <div class="label_info_list list_right">
          <div class="label_info_list_icon"><img class="img_box"
                 :src="vTeacherhead"
                 :onerror="defaultImg"></div>
          <span class="font_gray">{{courseInfo.MTeacherKeyValue}}<span v-if="courseInfo.ATeaName">({{courseInfo.ATeaName}})</span></span>
        </div>
        <div class="label_info_list">
          <div class="label_info_list_icon"><img src="../../../../public/image/label_classroom.png"
                 alt=""></div>
          <span class="font_gray">{{courseInfo.classRoomName}}</span>
        </div>
        <div class="label_info_list list_right">
          <div class="label_info_list_icon"><img src="../../../../public/image/label_course.png"
                 alt=""></div>
          <span class="font_gray"><span class="font_black">{{courseInfo.PeriodCount}}</span>课时 <span :class="{'display_none':vIsleaveKey && vIsChargesKey}">(<span :class="{'display_none':vIsleaveKey}">请假</span><span :class="{'display_none':vIsleaveKey || vIsChargesKey}">、</span><span :class="{'display_none':vIsChargesKey}">旷课</span>扣课时)</span></span>
        </div>
      </div> -->
    </div>
    <newTeachingPlanInfo v-if="courseInfo.ScheduleCourseKey && courseInfo.TeachingPlan"
                         :courseInfo="courseInfo"
                         :teachingPlanInfo="courseInfo.TeachingPlan"
                         :isAlter="isAlter"
                         @updateCourseInfo="updateCourseInfo"></newTeachingPlanInfo>
    <div v-else-if="!courseInfo.TeachingPlan && ishasCWePackage"
         class="course_essay_label no_cw_course_essay_label"
         @click="showAddCW">
      <div><img src="../../../../public/cw-img/file_icon@1.png"
             alt="" /></div>
      <div class="font_blue">点击选择课件上课</div>
    </div>
    <!-- <teaching-plan-info v-if="courseInfo.ScheduleCourseKey&&courseInfo.TeachingPlan"
                        :courseInfo="courseInfo"
                        :teachingPlanInfo="courseInfo.TeachingPlan"
                        @updateCourseInfo="updateCourseInfo"></teaching-plan-info> -->
  </div>
</template>

<script>
// import teachingPlanInfo from './teaching-plan-info';
import newTeachingPlanInfo from "./new-teaching-plan-info";
import { getCoursewarePackageIs } from "../../../API/workbench";
export default {
  data () {
    return {
      ishasCWePackage: false
    };
  },
  props: {
    courseInfo: Object,
    unAttendanceNum: Number,
    isAlter: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  components: {
    newTeachingPlanInfo
  },
  computed: {
    vStatu () {
      if (this.courseInfo.StatusKey == 2) {
        return "即将开始";
      } else if (this.courseInfo.StatusKey == 3) {
        return "上课中";
      } else {
        return "已上完";
      }

      // 已上完 , 即将开始,  上课中
    },
    vStatuClass () {
      if (this.courseInfo.StatusKey == 2) {
        return "type_yellow";
      } else if (this.courseInfo.StatusKey == 3) {
        return "type_blue";
      } else {
        return "type_gray";
      }
    },
    vClassDate () {
      let dateArr = this.$utils
        .formatDateToLine(this.courseInfo.ClassTime)
        .split("-");
      return dateArr[1] + "-" + dateArr[2];
    },
    vTeacherhead () {
      if (this.courseInfo.XMLIcon && this.courseInfo.XMLIcon.length > 0) {
        return this.$store.getters.CDNURL + this.courseInfo.XMLIcon;
      } else {
        return "";
      }
    },
    defaultImg () {
      return (
        'this.src="' +
        require("../../../../public/image/deflut_teacher_photo@2x.png") +
        '"'
      );
    },
    vIsleaveKey () {
      return this.courseInfo.IsleaveKey == 0;
    },
    vIsChargesKey () {
      return (
        this.courseInfo.IsChargesKey == 0 ||
        this.$utils.getSaaSClientSet(59) == 0
      );
    }
  },
  created () {
    this.getCWePackageIs();
  },
  methods: {
    updateCourseInfo (ScheduleCourseKey) {
      this.$emit("updateCourseInfo", ScheduleCourseKey);
    },
    getCWePackageIs () {
      getCoursewarePackageIs()
        .then(result => {
          console.log("getCoursewarePackageIs", result.data);
          this.ishasCWePackage = result.data.IsCoursewarePackage == 1;
        })
        .catch(err => { });
    },
    showAddCW () {
      console.log("showAddCW", this.courseInfo);
      //  if (this.$zxClient&&this.$zxClient.IsClient) {
      if (this.isAlter) {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: "",
          name: "选择课件",
          moduleName: "webvChooseCourseware",
          data: { ScheduleCourseKey: this.courseInfo.ScheduleCourseKey },
          callBack: {
            afterSuccess: data => {
              console.log(data, "afterSuccess");
              this.$emit("updateCourseInfo", this.courseInfo.ScheduleCourseKey);
              // this.addCWforClass(data);
            }
          }
        });
      }
      // } else {
      //      layer.alert('请用桌面端打开程序')
      // }
    }
  }
};
</script>

<style>
.no_cw_course_essay_label {
  height: 86px;
  text-align: center;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
