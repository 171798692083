<template>
  <div class="flow_item"
       :class="chooiceKey == item.FluxComboKey?'option':''"
       @click.stop="optionItem">
    <span class="big_span">{{vItem.SizeNum}}<span style="font-size:15px">{{vItem.UnitKey==1?'G':'T'}}</span></span>
    <span class="line_def"></span>
    <span class="press_span">{{Number(vItem.PriceAmount)}}元</span>
    <img v-if="chooiceKey == item.FluxComboKey"
         class="option_img"
         :src="require('../../../../../../public/image/flow-option.png')" />
  </div>
</template>
<script>

export default {
  components: {
  },
  props: {
    item: Object,
    keyIndex: Number,
    chooiceKey: String
  },
  data () {
    return {
    };
  },

  created () {
  },
  mounted () {

  },
  computed: {
    vItem () {
      return this.item;
    }
  },
  watch: {
  },
  methods: {
    optionItem () {
      this.$emit('optionItem', this.item);
    }
  }
};
</script>
<style scoped>
.flow_item {
  cursor: pointer;
  box-sizing: border-box;
  width: 116.67px;
  height: 116.67px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  /* box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04); */
  border: 1px solid #ececec;
  border-radius: 4px;
  position: relative;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 9px;
}
.option {
  border: 1px solid #3498db;
}
.big_span {
  position: absolute;
  left: 0;
  top: 27px;
  width: 116.67px;
  height: 25px;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 22px;
  /* or 73% */
  align-items: center;
  text-align: center;
}
.line_def {
  position: absolute;
  width: 106.67px;
  height: 0px;
  left: 5px;
  top: 72px;
  border: 1px dashed #ececec;
}
.press_span {
  position: absolute;
  width: 116.67px;
  height: 18px;
  left: 0px;
  top: 87px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #333333;
  align-items: center;
}
.option_img {
  position: absolute;
  top: 1px;
  right: 1px;
}
</style>