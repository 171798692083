<template>
  <div class="credit_management_popup">
    <div class="popup_title_padding view_title_flex"
         style="justify-content: inherit">
      <div class="">
        <date-range ref="saveDate"
                    :searchObj="searchObj"
                    :fromTitle="'日期范围'"
                    @changeDate="changeDate"></date-range>
      </div>
      <!-- <div class="search_item">
          <el-select-option
            :selectTitle="'选择课包'"
            :dataSourceList="BigCounselorList"
            :selectionKey="searchObj.CounselorKey"
            @changeEvent="chooseCounselor"
            
          ></el-select-option>
        </div> -->
      <div>
        <span class="span-classpa">选择课包</span>
        <el-cascader ref="cascader"
                     style="height: 36px; width: 260px"
                     v-model="selectedOptions"
                     :options="BigCounselorList"
                     :props="props"
                     @change="BigCounselor"
                     placeholder="全部"
                     clearable></el-cascader>
      </div>
      <!-- <div class="search_item"
           style="margin-left: 15px">
        <el-select-option :selectTitle="'选择班级'"
                          :dataSourceList="sourceTypeList"
                          :selectionKey="searchObj.ClassKey"
                          @changeEvent="chooseSourceType"></el-select-option>
      </div> -->
      <div class="search_item"
           style="margin-left:10px">
        <el-select-option :selectTitle="'课件来源'"
                          :dataSourceList="coursewareSourceList"
                          :selectionKey="searchObj.Source"
                          @changeEvent="chooseIsSource"></el-select-option>
      </div>
      <!-- 类型 -->
      <div class="table_select_box"
           style="margin-left: 15px;">
        <div class="table_select_title">类型</div>
        <el-select v-model="selectedTypeAccount"
                   filterable
                   placeholder="必选"
                   @change="chgSearchValue"
                   value-key="OLAPKey">
          <el-option v-for="item in typeList"
                     :key="item.OLAPKey"
                     :label="item.MainDemoName"
                     :value="item"></el-option>
        </el-select>
      </div>
    </div>
    <div class="credit_management_table"
         style="margin-left: 15px">
      <table-list class="summarizing_list table_list_content"
                  :tableData="vdataReportList"
                  :tableColumns="tableCourseColumns"
                  :totalNum="totalNum"
                  :queryParams="searchObj"
                  :footerContent="vFooterTotal"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import elSelectOption from '../../../../report/common/el-select-option';
import addTeacher from '../../form/add-teacher';
import statisticsBox from '../../../../report/common/statistics-box';
import {
  getSearchTeacherCoursewareUseStatList,
  CWSSearchPageClassList,
  getAuthorizationBigCoursewarePackageListForTeacher,
  getAuthorizationCoursewarePackageListForTeacher,
  getAuthorizationCoursewareListForTeacher
} from '../../../../../API/workbench';
import inputSearchContainBtn from '../../../../report/common/input-search-contain-btn';
import tableList from '../../../../common/table-list/index';
export default {
  data () {
    return {
      ShowPage: false,
      selectedOptions: [],
      options: [],
      props: {
        value: 'OLAPKey',
        label: 'MainDemoName',
        children: 'children',
        checkStrictly: true,
        expandTrigger: 'hover',
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level;
          console.log(node, 'node');
          if (level > 0) {
            const id = node.value;
            console.log(node.data.TypeKey, 'node.data.type');
            if (node.data.TypeKey == '0') {
              getAuthorizationCoursewareListForTeacher(id).then(
                (res) => {
                  const { code, msg } = res;
                  const list = res.data;
                  console.log(list, 'SelectCityForProvince');
                  if (code === 0) {
                    const nodes = list;
                    nodes.forEach((item) => {
                      item.leaf = level + 5;
                    });
                    resolve(nodes);
                  }
                }
              );
            } else {
              getAuthorizationCoursewarePackageListForTeacher(
                id
                , 1).then((res) => {
                  const { code, msg } = res;
                  const list = res.data;
                  console.log(list, 'SelectCityForProvince');
                  if (code === 0) {
                    const nodes = list;
                    nodes.forEach((item) => {
                      item.children = [];
                    });
                    resolve(nodes);
                  }
                });
            }
          }
        }
      },
      statisticsData: {
        TeacherNotLeave: {
          title: '在职老师数',
          value: 0,
          type: 1,
          isSelection: true
        },
        TeacherLeave: {
          title: '离职老师数',
          value: 0,
          type: 0,
          isSelection: false
        }
      },
      sourceTypeList: [],
      TeacherList: [],
      BigCounselorList: [],
      seletedItem: null, // 选中单个
      showAddForm: false,
      dataReportList: [],
      tcParam: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号
        TeacherType: 1 //	整型	必须		数据源：GET	老师状态是否在职 -1-全部；1-是; 0-否
      },
      coursewareSourceList: [
        {
          MainDemoName: '全部',
          OLAPKey: 0
        },
        {
          MainDemoName: '授权课件',
          OLAPKey: 1
        },
        {
          MainDemoName: '自研课件',
          OLAPKey: 2
        }

      ],
      selectedTypeAccount: '全部',
      typeList: [
        {
          OLAPKey: 0,
          MainDemoName: '全部'
        },
        // {
        //   OLAPKey: 1,
        //   MainDemoName: '选班上课'
        // },
        {
          OLAPKey: 2,
          MainDemoName: '上课'
        },
        {
          OLAPKey: 3,
          MainDemoName: '备课'
        },
        {
          OLAPKey: 4,
          MainDemoName: '管理'
        }
      ],
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: '',
        TeacherType: '1',
        CounselorKey: '',
        IsValidKey: '',
        SourceTypeKey: '',
        BigCoursewarePackageKey: '',
        ClassKey: '',
        CoursewareKey: '',
        CoursewarePackageKey: '',
        endTime: '',
        startTime: '',
        Catalogue: '',
        Source: '',
        AttendClassTypeKey: 0// 0全部 1= 选班上课    2 = 快捷上课
      },
      tableData: {},
      totalNum: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '类型',
          prop: 'ClassTypeKeyValue',
          width: 100,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '上课日期',
          prop: 'ClassTime',
          width: 100,
          sortable: 'custom',
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '上课时间',
          prop: 'BeginClassTime',
          width: 105,
          sortable: false,
          type: 'textItem'
        },
        {
          label: '下课时间',
          prop: 'EndClassTime',
          width: 105,
          sortable: false,
          type: 'textItem'
        },
        {
          label: '课件使用时长',
          prop: 'DurationCountText',
          minWidth: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '大课包',
          prop: 'BigCoursewarePackageKeyValue',
          width: 150,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '子课包',
          prop: 'CoursewarePackageKeyValue',
          width: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '课件',
          prop: 'CoursewareKeyValue',
          width: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        // {
        //   label: '签到人数',
        //   prop: 'ArrivedNumCount',
        //   width: 100,
        //   sortable: false,
        //   align: 'left',
        //   type: 'numberItem'
        // },
        {
          label: '流量',
          prop: 'Flow',
          width: 90,
          sortable: false,
          align: 'left',
          type: 'textItem'
        }
        // {
        //   label: '状态',
        //   prop: 'StatusKeyValue',
        //   width: 60,
        //   sortable: false,
        //   align: 'left',
        //   type: 'textItem'
        // }
      ]
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          StartTime: '',
          EndTime: '',
          BigCoursewarePackageKey: '',
          ClassKey: ''
        };
      }
    }
  },
  components: {
    inputSearchContainBtn,
    tableList,
    statisticsBox,
    addTeacher,
    elSelectOption
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    },
    vSeletedItemKey () {
      return this.seletedItem ? this.seletedItem.OLAPKey : 0;
    }
  },
  watch: {
    // selectedOptions (c, o) {
    //   if (c.length > 0) {
    //     setTimeout(() => {
    //       this.getNodes([c[0]], (data) => {
    //         this.getNodes([c[0], c[1]], (data) => {
    //           // this.selectedOptions = c;
    //           console.log(data, 'selectedInfo-data');
    //         });
    //       });
    //     }, 150);
    //   }
    // }
  },

  created () {
    this.searchObj.BigCoursewarePackageKey =
      this.dataInfo.BigCoursewarePackageKey || '';
    this.searchObj.startTime = this.dataInfo.StartTime || '';
    this.searchObj.endTime = this.dataInfo.EndTime || '';
    this.searchObj.TeacherKey = this.dataInfo.TeacherKey || '';
    if (this.dataInfo.BigCoursewarePackageKey) {
      this.selectedOptions.push(this.dataInfo.BigCoursewarePackageKey);
    }
    if (this.dataInfo.CoursewarePackageKey) {
      this.selectedOptions.push(this.dataInfo.CoursewarePackageKey);
    }
    console.log(this.dataInfo, 'this.dataInfo', this.selectedOptions);
    this.$nextTick((o) => {
      console.log(this.selectedOptions, 'this.selectedOptions');
      this.getDataListforBig((BigCounselorList) => {
        if (this.dataInfo.BigCoursewarePackageKey > 0) {
          this.testInitSeletedTree(
            this.dataInfo.BigCoursewarePackageKey,
            BigCounselorList
          );
        }
      });
    });
    this.searchObj.ClassKey = this.dataInfo.ClassKey || '';
    this.searchObj.CoursewarePackageKey =
      this.dataInfo.CoursewarePackageKey || '';
    if (this.dataInfo.CoursewarePackageKey) {
      this.searchObj.BigCoursewarePackageKey = '';
    }
    this.getDataList();
    this.getClassList();
  },
  mounted () { },
  methods: {
    // getCoursewarePackageList(id){
    //     CommonGetAuthorizationCoursewareList(id).then(res => {

    //               console.log(list, '课件');
    //               if (code === 0) {
    //                 return res.data
    //               }else{
    //                 return null
    //               }
    //       });
    // },

    // 选择类型
    chgSearchValue (item) {
      console.log(item, 'item');
      this.selectedTypeAccount = item.MainDemoName;
      this.searchObj.AttendClassTypeKey = item.OLAPKey;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    chooseIsSource (item) {
      this.searchObj.Source = item.OLAPKey;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    testInitSeletedTree (BigCoursewarePackageKey, BigCounselorList) {
      getAuthorizationCoursewarePackageListForTeacher(
        BigCoursewarePackageKey, 1
      ).then((res) => {
        const { code, msg } = res;
        const list = res.data;
        console.log(list, 'SelectCityForProvince');
        if (code === 0) {
          const nodes = list;
          nodes.forEach((item) => {
            item.children = [];
          });
          let locaIndex = BigCounselorList.findIndex((obj) => {
            return obj.OLAPKey == BigCoursewarePackageKey;
          });
          if (locaIndex >= 0) {
            BigCounselorList[locaIndex].children = [];
            BigCounselorList[locaIndex].children.push(nodes);
          }
          console.log(this.selectedOptions, 'this.selectedOptions');
          this.handleNodeExpand(BigCounselorList);
        }
      });
    },
    // el-tree 懒加载load 手动触发load更新
    handleNodeExpand (data) {
      console.log(this.$refs.cascader, 'this.$refs.cascader');
      //  设置未进行懒加载状态
      if (this.dataInfo.CoursewarePackageName) {
        this.$refs.cascader._data.inputValue =
          this.dataInfo.BigCoursewarePackageName +
          '/' +
          this.dataInfo.CoursewarePackageName;
        this.$refs.cascader._data.presentText =
          this.dataInfo.BigCoursewarePackageName +
          '/' +
          this.dataInfo.CoursewarePackageName;
      }

      if (
        this.dataInfo.CoursewarePackageName &&
        this.dataInfo.packageName != ''
      ) {
        this.$refs.cascader._data.inputValue =
          this.dataInfo.BigCoursewarePackageName +
          '/' +
          this.dataInfo.CoursewarePackageName +
          '/' +
          this.dataInfo.packageName;
        this.$refs.cascader._data.presentText =
          this.dataInfo.BigCoursewarePackageName +
          '/' +
          this.dataInfo.CoursewarePackageName +
          '/' +
          this.dataInfo.packageName;
      }
      // 重新展开节点就会间接重新触发load达到刷新效果
      // _node.expand();
    },
    getClassList () {
      var param = {
        pageIndex: 0,
        pageSize: 0,
        ClassType: 4
      };
      CWSSearchPageClassList(param)
        .then((result) => {
          this.sourceTypeList = result.data.PageDataList;
          this.sourceTypeList.unshift({ MainDemoName: '全部', OLAPKey: 0 });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    // getNodes (val, callBack) {
    //   console.log(val, callBack);
    //   if (!val) {
    //     CommonGetAuthorizationBigCoursewarePackageList().then(result => {
    //       this.options = result.data.map((item, i) => {
    //         return {
    //           TableID: item.OLAPKey,
    //           name: item.MainDemoName,
    //           cities: []
    //         };
    //       });
    //     });
    //   } else if (val.length === 1) {
    //     this.options.map((value, i) => {
    //       if (value.TableID === val[0]) {
    //         CommonGetAuthorizationCoursewarePackageList(val[0]).then(result => {
    //           value.cities = result.data.map((value, i) => {
    //             return {
    //               TableID: value.OLAPKey,
    //               name: value.MainDemoName,
    //               cities: []
    //             };
    //           });
    //           if (callBack) {
    //             callBack();
    //           }
    //         });
    //       }
    //     });
    //   } else if (val.length === 2) {
    //     this.options.map((value, i) => {
    //       if (value.OLAPKey === val[0]) {
    //         value.ch.map((itec, i) => {
    //           if (itec.TableID === val[1]) {
    //             CommonGetAuthorizationCoursewareList(val[1]).then(result => {
    //               if (result.data.length > 0) {
    //                 itec.cities = result.data.map((every, i) => {
    //                   return {
    //                     TableID: every.OLAPKey,
    //                     name: every.MainDemoName
    //                   };
    //                 });
    //               }
    //               if (callBack) {
    //                 callBack();
    //               }
    //             });
    //           }
    //         });
    //       }
    //     });
    //   }
    //   // if (this.$refs.cascaderAddr) {
    //   //     console.log('sfhfyjsrgfjk-33', this.$refs.cascaderAddr.getCheckedNodes()[0].pathLabels);
    //   //   this.$emit('selectedItem', this.selectedOptions, this.$refs.cascaderAddr.currentLabels);
    //   // }
    // },
    getDataListforBig (callback) {
      getAuthorizationCoursewarePackageListForTeacher(0, 1).then((result) => {
        this.BigCounselorList = result.data;
        if (callback) {
          callback(this.BigCounselorList);
        }
      });
    },
    // 获取大课件包list
    //  async getBigCoursewarePackageList () {
    //     CommonGetAuthorizationBigCoursewarePackageList().then(result => {
    //       console.log(result.data, '大课包list');
    //       this.BigCounselorList=result.data
    //       this.BigCounselorList.forEach((o,index)=>{
    //         CommonGetAuthorizationCoursewarePackageList(o.OLAPKey).then(result => {
    //           console.log(result.data, '小课包');
    //           o.childrens=result.data
    //           o.childrens.forEach((d,p)=>{
    //             CommonGetAuthorizationCoursewareList(d.OLAPKey).then(result => {
    //                 d.childrens = result.data
    //                 console.log(result.data, '课件包列表');
    //               }, error => {
    //                 layer.alert(error.msg);
    //                });
    //             })
    //           }, error => {
    //             layer.alert(error.msg);
    //           });
    //       })
    //       setTimeout(() => {
    //                   // 延时可加可不加
    //                  this.ShowPage=true
    //                 }, 500);

    //       console.log(this.BigCounselorList,'最终课件获取')

    //     }, error => {
    //       layer.alert(error.msg);
    //     });
    //   },
    chooseSourceType (item) {
      this.searchObj.ClassKey = item.OLAPKey;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    chooseIsValid (item) {
      this.searchObj.TeacherKey = item.OLAPKey;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    BigCounselor (item) {
      this.searchObj.BigCoursewarePackageKey = 0;
      this.searchObj.Catalogue = 0;
      this.searchObj.CoursewareKey = 0;
      this.searchObj.CoursewarePackageKey = 0;
      this.searchObj.pageIndex = 0;
      let data = this.$refs['cascader'].getCheckedNodes();
      if (data.length == 0) {
        this.$refs.cascader.dropDownVisible = false;
        this.getDataList();
      } else {
        if (data[0].level == 1) {
          this.searchObj.BigCoursewarePackageKey = data[0].value;
        } else if (data[0].level == 2) {
          if (data[0].data.TypeKey == '2') {
            this.searchObj.Catalogue = data[0].value;
          } else {
            this.searchObj.CoursewarePackageKey = data[0].value;
          }
        } else if (data[0].level == 3) {
          if (data[0].data.TypeKey) {
            this.searchObj.CoursewarePackageKey = data[0].value;
          } else {
            this.searchObj.CoursewareKey = data[0].value;
          }
        } else if (data[0].level == 4) {
          this.searchObj.CoursewareKey = data[0].value;
        }
        this.$refs.cascader.dropDownVisible = false;
        this.getDataList();
      }
    },
    chooseCounselor (item) {
      this.searchObj.CounselorKey = item.OLAPKey;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    changeDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },

    closeDialog () {
      this.showAddForm = false;
    },
    updateData () {
      this.getDataList();
    },
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.getDataList();
    },
    search () {
      if (this.searchObj.searchText == '') {
        this.searchObj.StudentKey = '';
      }
      this.getDataList();
    },

    getDataList () {
      getSearchTeacherCoursewareUseStatList(this.searchObj).then(
        (res) => {
          console.log('getSearchTeacherCoursewareUseStatList', res.data);
          this.totalNum = res.data.Total;
          this.dataReportList = res.data.PageDataList;
          this.statisticsData.TeacherLeave.value = Number(
            res.data.TeacherLeaveNum
          );
          this.statisticsData.TeacherNotLeave.value = Number(
            res.data.TeacherNotLeaveNum
          );
          this.dataReportList.forEach((o) => {
            let data = new Date(o.BeginClassTime);
            o.BeginClassTime =
              this.getTimeOut(data.getHours()) +
              ':' +
              this.getTimeOut(data.getMinutes());
            let data1 = new Date(o.EndClassTime);
            o.EndClassTime =
              this.getTimeOut(data1.getHours()) +
              ':' +
              this.getTimeOut(data1.getMinutes());
            let data2 = new Date(o.ClassTime);
            o.ClassTime = this.getTimeOut(data2.getFullYear()) +
              '-' +
              this.getTimeOut(data2.getMonth() + 1) +
              '-' +
              this.getTimeOut(data2.getDate());
            o.DurationCountText = Number(o.DurationCount) + '分钟';
            o.Flow = this.$utils.flowFormate(o.Flow);
            // o.ClassTime = o.ClassTime
            // o.BeginClassTime = o.BeginClassTime
            // o.EndClassTime = o.BeginClassTime
          });
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    getTimeOut (date) {
      return date > 9 ? date : '0' + date;
    }
  }
};
</script>
<style scoped>
.span-classpa {
  margin-right: 5px;
  color: #666;
  white-space: nowrap;
  text-align: center;
}

/deep/ input::-webkit-input-placeholder {
  color: #333333 !important;
  opacity: inherit !important;
}
/* Mozilla Firefox 4 to 18 */
/deep/ input:-moz-placeholder {
  color: #333333 !important;
  opacity: inherit !important;
}
/* Mozilla Firefox 19+ */
/deep/ input::-moz-placeholder {
  color: #333333 !important;
  opacity: inherit !important;
}
/* Internet Explorer 10+ */
/deep/ input:-ms-input-placeholder {
  color: #333333 !important;
  opacity: inherit !important;
}
</style>