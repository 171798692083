<template>
  <!-- 验证学生 -->
  <div>
    <div>
      <div class="set_class_time_title"
           style="padding: 15px 15px; margin: 0;">
        合并后档案
        <span class="form_info_field">
          <input-form-tip :tips="'可进行手动选择需要保留的学生信息！'"></input-form-tip>
        </span>
      </div>
      <div class="verify_student_from pr"
           style="margin-bottom: 30px;">
        <div class="verify_student_from_ul">

          <div v-for="item in mergeInfoList"
               :key="item.name">
            <div class="verify_student_from_list"
                 v-if="item.title">
              <div class="list_title">{{item.title}}</div>
              <div class="list_content tr"
                   :style="item.name=='MobileSupport'&&studentKey?'background: none;color: #333':'color: #333'"
                   @click="noSelectStudentInfoClick(item)">{{item.value}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_fixed_bottom"
           style="margin-bottom: 2px">
        <div class="btn_fixed_white btn_hover_white"
             @click="cancel()">取消</div>
        <function-btn :item="addbtn"
                      @clickItem="merge"
                      :className="'btn_fixed_blue btn_hover_bg_blue'">确认合并
        </function-btn>
      </div>
    </div>

    <custom-dialog :title="editStudentSetName.title"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isMergeContentShow"
                   :before-close="closeMergeContent">
      <div class="student_dual_select_box flex">
        <div class="student_list"
             :class="{opt:!isSelectStudent&&isRegister}"
             @click="noSelectedStudentClick(studentOneInfo,false)">
          <div class="student_title">学生一{{studentKey?' (注册)':''}}</div>
          <div class="student_name">{{vStudentDataValue}}</div>
        </div>
        <div class="student_list"
             :class="{opt:isSelectStudent&&isRegister}"
             @click="noSelectedStudentClick(studentTwoInfo,true)">
          <div class="student_title">学生二</div>
          <div class="student_name">{{vSelectedStudentInfoValue}}</div>
        </div>
      </div>
      <div class="btn_fixed_bottom">
        <div class="btn_fixed_blue btn_hover_bg_blue"
             style="width:100%"
             @click.stop="noSelectedClick">确认并返回</div>
      </div>
    </custom-dialog>

  </div>
</template>
<script>
import { StudentsMerge } from '../../../../API/workbench';
export default {
  props: {
    studentKey: {
      type: [String, Number],
      default: ''
    },
    // （学生一）
    studentOneInfo: {
      type: Object,
      default: {}
    },
    // （学生二）
    studentTwoInfo: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      customerRelationship: [
        { key: 0, value: '家长' },
        { key: 2, value: '爸爸' },
        { key: 3, value: '妈妈' },
        { key: 11, value: '本人' },
        { key: 10, value: '其他' }
      ],
      mergeInfoList: [
        { name: 'MobileSupport', key: '', value: '', title: '手机号', isOpt: false },
        { name: 'MainDemoName', key: '', value: '', title: '学生名', isOpt: true },
        { name: 'SexKeyValue', key: '', value: '', title: '性别', isOpt: true },
        { name: 'AgeName', key: '', value: '', title: '年龄', isOpt: true },
        { name: 'BirthdayTime', key: '', value: '', title: '生日', isOpt: true },
        { name: 'CustomerRelationshipKeyValue', key: '', value: '', title: '关系', isOpt: true }
      ],
      sexData: [
        { key: 2, value: '男' },
        { key: 3, value: '女' }
      ],
      addbtn: {
        name: '',
        type: 'tjxs',
        ModulePowerKey: 51
      },
      mergeJsonData: {
        StuKey: '', // -- 学生id
        MainDemoName: '', // -- 学生姓名
        CustKey: '', // -- 客户id
        CustKeyValue: '', // -- 客户名
        MobileSupport: '', // -- 手机号
        SexKey: '', // --性别
        SexKeyValue: '', // 	--2-男;3-女;
        AgeName: '', // 	--年龄
        BirthdayTime: '', // 	--生日
        CustomerRelationshipKey: '', // 	--客户关系
        CustomerRelationshipKeyValue: '' // 	--客户关系
      },
      ISsubmit: true, // 防止多次点击
      isRegister: false,
      // selectedStudentInfo: {}, // 选择合并的学生信息（学生二）
      newStudentData: {}, // 拷贝一份学生信息，用于判断学生是否有过修改
      editStudentSetName: {
        title: '',
        key: ''
      }, // 需要修改的学生字段
      isSelectStudent: true, // 显示变更学生信息界面
      isMergeContentShow: false

    };
  },
  components: {
  },
  created () {

  },
  watch: {
    'studentTwoInfo.OLAPKey': {
      handler (newOLAPKey, oldOLAPKey) {
        if (!this.studentKey && newOLAPKey && oldOLAPKey && newOLAPKey != oldOLAPKey) {
          this.mergeInfoList[0].value = '';
        }
        this.selectedToStudent(newOLAPKey);
      },
      // 深度观察监听
      deep: true
    },
    'studentOneInfo.OLAPKey': {
      handler (newOLAPKey, oldOLAPKey) {
        if (!this.studentKey && newOLAPKey && oldOLAPKey && newOLAPKey != oldOLAPKey) {
          this.mergeInfoList[0].value = '';
        }
        this.selectedToStudent(newOLAPKey);
      },
      // 深度观察监听
      deep: true
    }
  },
  mounted () {
    if (this.studentKey) {
      this.mergeInfoList[0].value = this.studentOneInfo.MobileSupport;
    }
    if (this.studentOneInfo.OLAPKey && this.studentTwoInfo.OLAPKey) {
      this.selectedToStudent(this.studentTwoInfo.OLAPKey);
    }
  },
  computed: {
    // 学生一
    vStudentDataValue () {
      switch (this.editStudentSetName.key) {
        case 'MobileSupport':
          return this.studentOneInfo.MobileSupport;
        case 'MainDemoName':
          return this.studentOneInfo.MainDemoName;
        case 'SexKeyValue':
          return this.studentOneInfo.SexKeyValue;
        case 'AgeName':
          return this.studentOneInfo.AgeName;
        case 'BirthdayTime':
          return this.studentOneInfo.BirthdayTime;
        case 'CustomerRelationshipKeyValue':
          return this.studentOneInfo.CustomerRelationshipKeyValue;
        default:
          break;
      }
    },
    // 学生二
    vSelectedStudentInfoValue () {
      switch (this.editStudentSetName.key) {
        case 'MobileSupport':
          return this.studentTwoInfo.MobileSupport;
        case 'MainDemoName':
          return this.studentTwoInfo.MainDemoName;
        case 'SexKeyValue':
          return this.studentTwoInfo.SexKeyValue;
        case 'AgeName':
          return this.studentTwoInfo.AgeName;
        case 'BirthdayTime':
          return this.studentTwoInfo.BirthdayTime;
        case 'CustomerRelationshipKeyValue':
          return this.studentTwoInfo.CustomerRelationshipKeyValue;
        default:
          break;
      }
    }
  },
  methods: {
    // 选择合并学生 （学生二）
    selectedToStudent (toStudentKey) {
      console.log(toStudentKey, '二');
      this.ISsubmit = true;
      this.mergeInfoList.forEach(o => {
        switch (o.name) {
          case 'MainDemoName':
            o.value = this.studentTwoInfo.MainDemoName || ' '; ;
            o.key = this.studentTwoInfo.OLAPKey;
            break;
          case 'SexKeyValue':
            o.value = this.studentTwoInfo.SexKeyValue || ' '; ;
            o.key = this.studentTwoInfo.SexKey;
            break;
          case 'AgeName':
            if (this.studentOneInfo.AgeName > 0) {
              this.mergeInfoList[3].isOpt = false;
              o.value = this.studentOneInfo.AgeName;
            } else if (this.studentTwoInfo.AgeName > 0) {
              this.mergeInfoList[3].isOpt = true;
              o.value = this.studentTwoInfo.AgeName;
            } else {
              o.value = this.studentTwoInfo.AgeName || ' ';
            }
            break;
          case 'BirthdayTime':
            if (this.studentOneInfo.BirthdayTime) {
              this.mergeInfoList[4].isOpt = false;
              o.value = this.studentOneInfo.BirthdayTime;
            } else if (this.studentTwoInfo.BirthdayTime) {
              this.mergeInfoList[4].isOpt = true;
              o.value = this.studentTwoInfo.BirthdayTime;
            } else {
              o.value = this.studentTwoInfo.BirthdayTime || ' ';
            }
            break;
          case 'CustomerRelationshipKeyValue':
            o.value = this.studentTwoInfo.CustomerRelationshipKeyValue || ' ';
            o.key = this.studentTwoInfo.CustomerRelationshipKey;
            break;
          default:
            break;
        }
      });
      console.log(this.studentTwoInfo, '学生二');
      this.isChangeFrom = true;
    },
    // 开启选择合并的学生信息
    noSelectStudentInfoClick (item) {
      console.log(item, item.value == true, '开启选择合并的学生信息');
      if (item.value) {
        this.isRegister = true;
      } else {
        this.isRegister = false;
      }
      if (item.name != 'MobileSupport' || !this.studentKey) { // 手机号不可变更
        this.isSelectStudent = item.isOpt;
        this.editStudentSetName.title = item.title;
        this.editStudentSetName.key = item.name;
        this.isMergeContentShow = true;
      }
      this.newStudentData = {};
    },
    // 关闭选择合并的学生信息
    closeMergeContent () {
      this.isMergeContentShow = false;
    },
    // 选择选中的学生信息
    noSelectedStudentClick (studentInfo, studentType) {
      this.newStudentData = studentInfo;
      this.isRegister = true;
      this.isSelectStudent = studentType;
      console.log(this.isSelectStudent, studentType);
      // this.isSelectStudent = !this.isSelectStudent;
    },
    // 确认选择学生信息
    noSelectedClick () {
      console.log(this.editStudentSetName, ' this.newStudentData');
      if (!this.newStudentData.OLAPKey) {
        this.closeMergeContent();
        return false;
      }
      switch (this.editStudentSetName.key) {
        case 'MobileSupport':
          this.selectedParameter(this.editStudentSetName.key, this.newStudentData.MobileSupport, '', this.isSelectStudent);
          break;
        case 'MainDemoName':
          this.selectedParameter(this.editStudentSetName.key, this.newStudentData.MainDemoName, this.newStudentData.OLAPKey, this.isSelectStudent);
          break;
        case 'SexKeyValue':
          this.selectedParameter(this.editStudentSetName.key, this.newStudentData.SexKeyValue, this.newStudentData.SexKey, this.isSelectStudent);
          break;
        case 'AgeName':
          this.selectedParameter(this.editStudentSetName.key, this.newStudentData.AgeName, '', this.isSelectStudent);
          break;
        case 'BirthdayTime':
          this.selectedParameter(this.editStudentSetName.key, this.newStudentData.BirthdayTime, '', this.isSelectStudent);
          if (this.newStudentData.BirthdayTime) {
            if (!this.newStudentData.AgeName) {
              this.selectedParameter('AgeName', '无年龄', '', this.isSelectStudent);
            } else {
              var dd = Date.new().getTime() - Date.new(this.newStudentData.BirthdayTime).getTime();
              let AgeName = Math.floor(dd / 1000 / 24 / 3600 / 365);
              this.selectedParameter('AgeName', AgeName, '', this.isSelectStudent);
            }
          }
          break;
        case 'CustomerRelationshipKeyValue':
          this.selectedParameter(this.editStudentSetName.key, this.newStudentData.CustomerRelationshipKeyValue, this.newStudentData.CustomerRelationshipKey, this.isSelectStudent);
          break;
        default:
          break;
      }
      this.closeMergeContent();

      console.log(this.mergeInfoList, '开启选择合并的学生信息');
    },
    // 选择需要变更的参数
    selectedParameter (parameter, value, key, isOpt) {
      this.mergeInfoList.forEach(o => {
        if (parameter == o.name) {
          o.value = value || ' ';
          o.key = key;
          o.isOpt = isOpt;
        }
      });
    },
    // 合并（验证）
    merge () {
      console.log('合并');
      if (this.studentOneInfo.OLAPKey == this.studentTwoInfo.OLAPKey) {
        layer.alert('同一个学生不可合并,请重新确认');
      } else if (!this.mergeInfoList[0].value) {
        layer.alert('手机号不能为空');
      } else if (this.studentTwoInfo.OLAPKey) {
        if (this.studentOneInfo.AgeName == '无年龄' || isNaN(this.studentOneInfo.AgeName)) {
          this.studentOneInfo.AgeName = 0;
        }
        // 客户名称和ID以老生优先;
        this.mergeJsonData.CustKey = this.studentTwoInfo.CustKey;
        this.mergeJsonData.CustKeyValue = this.studentTwoInfo.CustKeyValue;
        // 其余的以选择为准
        this.mergeInfoList.forEach(o => {
          switch (o.name) {
            case 'MobileSupport':
              this.mergeJsonData.MobileSupport = o.value == ' ' ? '' : o.value;
              break;
            case 'MainDemoName':
              this.mergeJsonData.StuKey = o.key;
              this.mergeJsonData.MainDemoName = o.value == ' ' ? '' : o.value;
              break;
            case 'SexKeyValue':
              this.mergeJsonData.SexKeyValue = o.value == ' ' ? '' : o.value;
              this.mergeJsonData.SexKey = o.key;
              break;
            case 'AgeName':
              this.mergeJsonData.AgeName = o.value && o.value != ' ' ? o.value : 0;
              break;
            case 'BirthdayTime':
              this.mergeJsonData.BirthdayTime = o.value == ' ' ? '' : o.value;
              break;
            case 'CustomerRelationshipKeyValue':
              this.mergeJsonData.CustomerRelationshipKeyValue = o.value == ' ' ? '' : o.value;
              this.mergeJsonData.CustomerRelationshipKey = o.key;
              break;
            default:
              break;
          }
        });
        if (this.ISsubmit == true) {
          this.ISsubmit = false;
          console.log(this.mergeJsonData, 'this.mergeJsonData');
          StudentsMerge(this.studentOneInfo.OLAPKey, this.studentTwoInfo.OLAPKey, this.mergeJsonData).then(response => {
            console.log(response, 'result');
            // setTimeout(() => {
            this.$bus.emit('AfterStudentUpdate', this.mergeJsonData.StuKey, 'merge');
            this.cancel(this.mergeJsonData);
            // }, 200);
            layer.alert('学生合并成功');
          }, err => {
            this.ISsubmit = true;
            if (err.msg) {
              layer.alert(err.msg);
            }
          });
        }
      } else {
        layer.alert('请先选择需要合并的学生');
      }
    },
    // 取消
    cancel (studentitem) {
      console.log(studentitem, 'this.studentKey');
      this.ISsubmit = true;
      this.$emit('closePage');
    }

  }
};
</script>
<style>
.new_no_border_bottom {
  border-bottom: none !important;
  padding: 0px 10px 0px 30px !important;
  min-height: 30px !important;
}
.new_no_border_bottom .form_info_line {
  border-bottom: none !important;
}
/* .verify_student_lable .student_label {
  background-color: #f6f8f9;
  margin-left: -0.2rem;
  padding: 0 0.2rem;
} */
</style>


