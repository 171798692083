<template>
  <div class="all_workbench_left_ul">
    <statistics-item v-for="(item,key,index) in dataList" :key="index" :item="item" @click="clickItem(item)"></statistics-item>
  </div>
</template>

<script>
import statisticsItem from './item';
export default {
  components: {
    statisticsItem
  },
  props: {
    dataList: {
      type: Object,
      default: () => {
        return {
          a: { title: '标题名1', titleClassName: 'title样式', numberClassName: '值样式', value: 10, type: '', isSelection: false },
          b: { title: '标题名2', titleClassName: 'title样式', numberClassName: '值样式', value: 20, type: '', isSelection: false }
        };
      },
      required: true
    }
  },
  created () {
    console.log('手动阀发', this.dataList);
  },
  methods: {
    clickItem (item) {
      Object.keys(this.dataList).forEach(key => {
        let obj = this.dataList[key];
        if (obj.type == item.type) {
          obj.isSelection = true;
        } else {
          obj.isSelection = false;
        }
      });
      this.$emit('clickEvent', item);
    }
  }
};
</script>

<style scoped>

.all_workbench_left_ul{
  height: 50px;
  border: 1px solid #ececec;
  border-radius: 4px;
  display: flex
}
</style>