<template>
  <div class="courseware_add_img_box">
    <div class="add_img">
      <img class="img_box" :src="vUrl" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataInfo: Object
  },
  computed: {
    vUrl () {
      console.log(this.dataInfo.AttachViewUrl, 'this.dataInfo.AttachViewUrl');
      return this.dataInfo.AttachViewUrl;
    }
  }
};
</script>