import store from '../store';
import axios from 'axios';
// localhost/api/SSO/LoginAndGo?userID=2532&saasClientKey=2&systemID=2&goUrl=abc
// 数据获取，单字段求和统计
export function webAPI (control, action, urlData, callback, bodyData) {
  let token = store.getters.token;
  let url = store.getters.PCAPIURL + '/api/' + control + '/' + action + '?' + urlData + '&signdata=' + encodeURIComponent(JSON.stringify(token.SignData));

  axios.get(url).then(res => {
    if (res.data.Code == 0) {
      if (callback) {
        callback(res.data);
      }
    } else {
      layer.alert('警告:' + res.data.MSG);
    }
  }).catch(function (error) {
    layer.alert(error.msg);
    console.log(error);
  });
}

export function WKTLoginAndGO () {
  let token = store.getters.token;
  if (token && token.UserID) {
    webAPI(
      'SSO',
      'LoginAndGo',
      'userID=' + token.UserID + '&saasClientKey=' + token.SaaSClientKey + '&systemID=' + 2 + '&goUrl=go',
      (result) => {
        window.open(result.Obj);
      }
    );
  } else {
    layer.alert('当前登录已失效，请重新登录。');
    window.close();
  }
}
let setTimeoutObj = null;
export function setIntervalCheck (callback) {
  if (typeof callback == 'function') {
    callback();
  }
  if (setTimeoutObj) {
    clearTimeout(setTimeoutObj);
    setTimeoutObj = null;
  }
  setTimeoutObj = setTimeout(() => {
    setIntervalCheck(callback);
  }, 60 * 1000);
}
// 列表 header 字段名称添加帮助说明提示
// h： render函数的createdElement方法，由e-table renderHeader提供
// columnData： 列的配置信息
// tips: 帮助的说明提示文字
export function customTipsForTableHeadColumn (h, columnData, tips) {
  return h('span', [
      // 列标题
    h('span', columnData.label + ' '),
      // 提示
    h('span', [
      h('el-tooltip', {
        props: {
          placement: 'top'
        }
      }, [
        h('div', { slot: 'content', domProps: {innerHTML: tips} }),
        h('div', { class: 'header_tips_icon' })
      ])
    ])
  ]);
}
