<template>
  <div class="principal_echart">

    <div class="principal_echart_option ">
      <div class="fl font_big">课消金额分析</div>
      <function-btn :item="classAmountbtn"
                    @clickItem="routerGo(3)"
                    :className="'fr font_blue'">
        <!-- <div class="">
          详情
        </div> -->
      </function-btn>
    </div>
    <div class="principal_echart_content">
      <div class="principal_echart_data no_img clearfix">
        <div class="fl">
          <div class="font_top bg_gray_title">今日金额</div>
          <div class="font_bottom"
               :style="setSize(dataInfo.TodayClassAmount)">
            {{setNumber(dataInfo.TodayClassAmount)}}<span class="font_gray">元</span>
          </div>
        </div>
        <div class="fl">
          <div class="font_top bg_gray_title">昨日金额</div>
          <div class="font_bottom"
               :style="setSize(dataInfo.YesterdayClassAmount)">
            {{setNumber(dataInfo.YesterdayClassAmount)}}<span class="font_gray">元</span>
          </div>
        </div>
        <div class="fl">
          <div class="font_top bg_gray_title">本月金额</div>
          <div class="font_bottom"
               :style="setSize(dataInfo.MonthClassAmount)">
            {{setNumber(dataInfo.MonthClassAmount)}}<span class="font_gray">元</span>
          </div>
        </div>
        <div class="fr">
          <div class="font_top bg_gray_title"
               style="width:100% !important">本月异常金额</div>
          <function-btn :item="classAmountbtn2"
                        @clickItem="routerGo(6)"
                        :className="''">
            <div class="font_bottom font_red button"
                 :style="setSize(dataInfo.ThisMonthAbnormalClassAmount)">
              {{setNumber(dataInfo.ThisMonthAbnormalClassAmount)}}<span class="font_gray">元</span>
            </div>
          </function-btn>
        </div>
      </div>

      <div class="principal_echart_box">
        <div class="echart_btn">
          <div :class="{'opt':!isMonth}"
               @click="chgIsMonth(false)">按周</div>
          <div :class="{'opt':isMonth}"
               @click="chgIsMonth(true)">按月</div>
        </div>
        <div class="echart_box">
          <single-line-echart :echartID="'echart_02'"
                              :nowList="nowList"
                              :reqList="reqList"
                              ref="echart"></single-line-echart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import singleLineEchart from './single-line-echart';
import { getClassCountUsedAmountStatistics, GetClassAmountAnalysis, getClassCountUsedAmountForMonth, getClassCountUsedAmountForWeek } from '../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        startTime: '',
        endTime: ''
      },
      dataInfo: {
        TodayClassAmount: 0,
        YesterdayClassAmount: 0,
        MonthClassAmount: 0,
        ThisMonthAbnormalClassAmount: 0,
        CountUsedAmountToday: {},
        CountUsedAmountYesterday: {},
        CountUsedAmountMoth: {}
      },
      isMonth: false,        // 判断是否查询月接口或者周接口.
      nowList: [],     //
      reqList: [],      // x轴显示数组.
      classAmountbtn: {      // 报名欠款权限
        name: '详情',
        eventName: '',
        ModulePowerKey: 86
      },
      classAmountbtn2: {      // 报名欠款权限
        name: '',
        eventName: '',
        ModulePowerKey: 86
      }

    };
  },
  props: {
    screenWidth: [String, Number]
  },
  components: {
    singleLineEchart
  },
  watch: {
    screenWidth (c, o) {
      console.log('this.$refs.echart课消.', this.$refs.echart.myChart);
      if (this.$refs.echart.myChart) {
        this.$refs.echart.myChart.resize();
      }
    },
    $route (to, from) {
      // 监听路由是否变化
      if (to.name == 'presidentKanban') {
        this.$nextTick(() => {
          this.$refs.echart.echartInit();
        });
      }
    }
  },
  mounted () {
    this.loadData();
  },
  methods: {
    loadData () {
      this.setWeekTime();
      this.getClassAmountChartForWeek(this.searchObj);
      this.getClassAmountData();
    },
    routerGo (type) {
      // classSpendStatisticsAnalyze
      this.$router.push({ name: 'operatingRevenueStatistics',
        query: {
          type: type,
          GradeKey: 0,
          GradeKeyValue: 0,
          CourseKey: '',
          StudentNameKey: 0,
          StudentNameKeyValue: '',
          startTime: '',
          endTime: '' } });
    },
    setSize (num) {
      if (this.setNumber(num).toString().length >= 6) {
        return { 'font-size': '18px' };
      } else {
        return { 'font-size': '24px' };
      }
    },
    // 获取所有统计数据
    getClassAmountData () {
      // getClassCountUsedAmountStatistics
      GetClassAmountAnalysis().then(result => {
        this.dataInfo = result.data;
        console.log(this.dataInfo, 'getClassAmountDatathis.dataInfo ');
      });
    },
    setNumber (num) {
      num = num || 0;
      return Math.round(num);
    },
    // 选中周或月
    chgIsMonth (type) {
      this.isMonth = type;
      this.toLoadData();
    },
    toLoadData () {
      if (this.isMonth) {
        this.setMonthTime();
        this.getClassAmountChartForMonth(this.searchObj);
      } else {
        this.setWeekTime();
        this.getClassAmountChartForWeek(this.searchObj);
      }
    },
    // 设置月查询时间.
    setMonthTime () {
      let month = Date.new().getMonth();
      let startTime = this.$utils.formatDatet(Date.new(Date.new().setMonth(month - 5)), 'yyyy-MM') + '-01';
      let lastDate = Date.new(Date.new(this.$utils.formatDatet(Date.new(Date.new().setMonth(month + 1)), 'yyyy-MM') + '-01').setDate(-1));      // 获取这个月最后一天的日期.
      this.searchObj.endTime = this.$utils.formatDatet(lastDate, 'yyyy-MM-dd');
      this.searchObj.startTime = startTime;
      console.log(this.searchObj);
    },
    // 设置周查询时间.
    setWeekTime () {
      this.isMonth = false;
      let weekDay = Date.new().getDay();      // 获取这是周几
      let dateTime = Date.new().getTime();    // 时间戳,等待计算.
      this.searchObj.endTime = this.$utils.formatDatet(Date.new(), 'yyyy-MM-dd');   // (86400000 * (weekDay - 1)) 为这周的时间.
      this.searchObj.startTime = this.$utils.formatDatet(Date.new(dateTime - (86400000 * (weekDay - 1)) - (86400000 * 7 * 5)), 'yyyy-MM-dd');
      console.log(weekDay, 'setWeekTime', this.searchObj);
    },
    // 获取月份报表.
    getClassAmountChartForMonth (searchObj) {
      getClassCountUsedAmountForMonth(searchObj).then(result => {
        this.reportList = result.data.DataList;
        this.reqList = this.setMonthReqList();
        this.nowList = this.setNowList();
        if (this.$refs.echart) {
          this.$nextTick(() => {
            this.$refs.echart.echartInit();
          });
        }
      }).catch(error => {
        console.log('getKQChartForMonth  error', error);
      });
    },
    // 获取周报表.
    getClassAmountChartForWeek (searchObj) {
      getClassCountUsedAmountForWeek(searchObj).then(result => {
        this.reportList = result.data.DataList;
        this.reqList = this.setWeekReqList();
        this.nowList = this.setNowList();

        if (this.$refs.echart) {
          this.$nextTick(() => {
            this.$refs.echart.echartInit();
          });
        }
        console.log(this.reqList, 'getKQChartForWeek', this.dataList);
      }).catch(error => {
        console.log('getKQChartForWeek  error', error);
      });
    },
    // 设置月份X轴.
    setMonthReqList () {
      var newArr = [];
      if (this.reportList.length > 0) {
        this.reportList.forEach(o => {
          newArr.push(this.$utils.formatDateStr(o.SetUpTime, 'yyyy.M'));
          // totalDeductAmount
        });
      }
      return newArr;
    },
    // 获取X轴的数组
    setWeekReqList () {
      var newArr = [];
      if (this.reportList.length > 0) {
        for (var i = 0; i < this.reportList.length; i++) {
          if (i == 0) {
            newArr.push('前5周');
          } else if (i == 1) {
            newArr.push('前4周');
          } else if (i == 2) {
            newArr.push('前3周');
          } else if (i == 3) {
            newArr.push('前2周');
          } else if (i == 4) {
            newArr.push('上周');
          } else {
            newArr.push('本周');
          }
        }
      }
      return newArr;
    },
    // 设置图标数组
    setNowList () {
      var newArr = [];
      if (this.reportList.length > 0) {
        this.reportList.forEach(o => {
          newArr.push(this.setNumber(o.totalDeductAmount));
          // totalDeductAmount
        });
      }
      return newArr;
    }

  }
};
</script>
