<template>
  <div class="cell_course">
    <!-- 课程 -->
    <div class="course_loop_box">
      <el-popover v-if="$utils.getTextWidth(courseItem.CourseNameKeyValue, 16)>236"
                  popper-class="new_popper_class"
                  placement="top"
                  width="auto"
                  trigger="hover"
                  :content="courseItem.CourseNameKeyValue">
        <div slot="reference"
             class="reference"
             style="cursor: pointer;">
          <span>{{courseItem.CourseNameKeyValue}}</span>
        </div>
      </el-popover>
      <div v-else
           class="">
        <span>{{courseItem.CourseNameKeyValue}}</span>
      </div>
    </div>
    <!-- 班级内容 -->
    <div class="">
      <class-content v-for="(Item,index) in courseItem.ClassList"
                     :key="index"
                     :classItem="Item"></class-content>

    </div>
  </div>
</template>
<script>

import classContent from './class-content';
export default {
  data () {
    return {

    };
  },
  props: {
    courseItem: Object // 列表数据

  },
  components: {
    classContent
  },
  created () {

  },
  watch: {

  },
  mounted () {

  },
  methods: {

  },

  computed: {
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    }
  }
};
</script>
