<template>
  <div>
    <!-- 课消金额日报 -->
    <custom-dialog :title="listTitle"
                   width="1213px"
                   :top="30"
                   class="class_staistics_list defult_dialog"
                   :visible.sync="isShowListDialog"
                   :before-close="closeDialog">
      <div class="table_list_content">
        <div class="single_result_detail">
          <!-- 表格 -->
          <div class="table_content margin_T_0 single_shadow">
            <div class="screening_condition clearfix">
              <div class="screening_condition_number fl">
                <img src="../../../../public/image/screening_condition_number_icon01.png"
                     alt=" " />
                <span style="color:#999;">{{listTime}}</span>
              </div>
            </div>
            <!-- 列表 -->
            <el-table class="table_list"
                      ref="multipleTable"
                      :data="tableData"
                      highlight-current-row
                      stripe
                      empty-text
                      toggleRowSelection
                      @row-click="clickRow"
                      style="width: 100%">
              <!-- 表头 -->
              <el-table-column type="selection"
                               width="55"></el-table-column>
              <el-table-column v-for="(item,index) in vTableColumns"
                               :width="item.width"
                               :sortable="item.sortable"
                               :align="item.align"
                               :key="index"
                               :type="item.type"
                               :label="item.label"
                               :prop="item.prop"
                               :show-overflow-tooltip="true"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </custom-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isShowListDialog: false // 是否显示主界面
    };
  },
  props: {
    listTitle: {
      // 列表头
      type: String,
      required: true
    },
    tableData: Array, // 列表数据
    tableColumns: Array, // table column
    listTime: String
  },
  computed: {
    vTableColumns () {
      let newTableColumns = [];
      this.tableColumns.forEach(element => {
        if (element.isShow) {
          newTableColumns.push(element);
        }
      });
      return newTableColumns;
    }
  },
  methods: {
    closeDialog () {
      this.isShowListDialog = false;
      this.searchText = '';
    },
    // 选中行
    clickRow (row, column, event) {
      this.selectedRow = row;
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row, true);
    }
  }
};
</script>