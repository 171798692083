<template>
  <div class="popup_dialog_box_content clearfix">
    <div class="WX_setting_box">
      <div class="WX_setting_menu">
        <div class="menu_list"
             v-for="(item,key) in optionList"
             :key="key"
             :class="item.isActive?'opt':''"
             @click="changeType(item)">
          {{item.name}}
          <span class="font_gray"
                v-if="item.type==2">(自动回复)</span>
        </div>
      </div>
      <div class="WX_setting_content">
        <!-- 消息群发 -->
        <mass-message v-if="seletedType==0"></mass-message>
        <!-- 关注回复 -->
        <attention-to-reply v-if="seletedType==1"></attention-to-reply>
        <!-- 关键词回复 -->
        <keyword-reply v-if="seletedType==2"></keyword-reply>
        <!-- 消息管理 -->
        <message-manager v-if="seletedType==3"></message-manager>
        <!-- 菜单设置 -->
        <menu-setting v-if="seletedType==4"></menu-setting>
      </div>

    </div>
  </div>
</template>
<script>
import messageManager from './wechart-setting-components/message-manager';
import massMessage from './wechart-setting-components/mass-message';
import keywordReply from './wechart-setting-components/keyword-reply';
import menuSetting from './wechart-setting-components/menu-setting';
import attentionToReply from './wechart-setting-components/attention-to-reply';
export default {
  data () {
    return {
      optionList: [
        {
          name: '消息群发',
          type: 0,
          isActive: true
        }, {
          name: '关注回复',
          type: 1,
          isActive: false
        }, {
          name: '关键词回复',
          type: 2,
          isActive: false
        }, {
          name: '消息管理',
          type: 3,
          isActive: false
        }, {
          name: '菜单设置',
          type: 4,
          isActive: false
        }
      ],
      seletedType: 0
    };
  },
  components: {
    massMessage,
    attentionToReply,
    keywordReply,
    messageManager,
    menuSetting
  },
  props: {

  },
  created () {

  },
  methods: {
    changeType (item) {
      this.optionList.forEach(o => {
        if (item.type == o.type) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      this.seletedType = item.type;
    }
  }
};
</script>

