<template>
  <div class="swiper-slide"
       @click="chgCoureDate">
    <div class="principal_swiper_list"
         :class="{'opt':item.isActive,'label':vDate == '今天'}">
      <div class="list_date">{{vDate}} {{vWeek}}</div>
      <div class="list_number"
           v-if="isDateType!=3">
        {{Number(item.ArrivedNumCount||0)}} <span class="font_gray">(人)签到</span>
      </div>
      <div class="list_number"
           v-else>
        {{Number(item.ArrangedNumCount||0)}} <span class="font_gray">(人)安排</span>
      </div>
      <div class="list_info clearfix">
        <div class="fl"
             v-if="isDateType==1">
          上满<span class="font_black">{{item.HaveClassNum}}</span>节课
        </div>
        <div class="fl"
             v-if="isDateType==2">
          已上<span class="font_black">{{item.HaveClassNum}}/{{item.ScheduleCourseNum}}</span>节课
        </div>
        <div class="fl"
             v-if="isDateType==3">
          共<span class="font_black">{{item.ScheduleCourseNum}}</span>节课
        </div>
        <div class="fr"
             v-if="isDateType!=3">
          出勤率<span class="font_black">{{Number(item.AttendanceRate)}}%</span>
        </div>
        <div class="fr"
             v-else>
          满员率<span class="font_black">{{parseInt(Number(item.FullRate))}}%</span>
        </div>
      </div>
      <!-- 昨日以前 -->
      <div class="list_tips tips_warning"
           v-if="isDateType==1&&Number(item.FAttenceNumCount)>0">
        <div>
          未考勤：<span class="font_red">{{item.FAttenceNumCount}}</span>人/<span class="font_red">{{item.NoAttenceCount}}</span>节课
        </div>
      </div>
      <div class="list_tips tips_normal tick_green"
           v-if="isDateType==1&&Number(item.FAttenceNumCount)==0">
        <!-- <div>
          一切正常！
        </div> -->
      </div>
      <!-- 今天 -->
      <div class="list_tips tips_warning"
           v-if="isDateType==2&&Number(item.HaveClassNum)==Number(item.ScheduleCourseNum)&&Number(item.FAttenceNumCount)>0">
        <div>
          未考勤：<span class="font_red">{{item.FAttenceNumCount}}</span>人/<span class="font_red">{{item.NoAttenceCount}}</span>节课
        </div>
      </div>
      <div class="list_tips tips_warning"
           v-if="isDateType==2&&Number(item.HaveClassNum)!=Number(item.ScheduleCourseNum)&&Number(item.WarnLossCourseCount)>0">
        <div>
          <span class="font_red">{{item.WarnLossCourseCount}}</span>节课 学生不足
        </div>
      </div>
      <div class="list_tips tips_normal tick_green"
           v-if="isDateType==2&&Number(item.HaveClassNum)==Number(item.ScheduleCourseNum)&&Number(item.FAttenceNumCount)==0">
      </div>
      <div class="list_tips tips_normal tick_green"
           v-if="isDateType==2&&Number(item.HaveClassNum)!=Number(item.ScheduleCourseNum)&&Number(item.WarnLossCourseCount)==0">
      </div>
      <!-- 明天以后 -->
      <div class="list_tips tips_warning"
           v-if="isDateType==3&&Number(item.WarnLossCourseCount)>0">
        <div>
          <span class="font_red">{{item.WarnLossCourseCount}}</span>节课 学生不足
        </div>
      </div>
      <div class="list_tips tips_normal tick_green"
           v-if="isDateType==3&&Number(item.WarnLossCourseCount)==0">
      </div>
    </div>
    <!-- <div class="list_tips tips_warning"
           v-if="isDateType==3&&(Number(item.FullRateCount)>0||Number(item.LeaveCount)>0)">
        <div :class="{'tips_dual':Number(item.LeaveCount)>0}"
             v-if="Number(item.FullRateCount)>0&&isDateType==3">
          满员率：<span class="font_red">{{Number(item.FullRateCount)}}</span>节课<<span class="">{{Number(item.FullRateLineCount)}}%</span>
        </div>
        <div :class="{'tips_dual':Number(item.FullRateCount)>0}"
             v-if="Number(item.LeaveCount)>0">
          提前请假：<span class="font_red">{{Number(item.LeaveCount)}}</span>节课/<span class="font_red">{{Number(item.LeaveNumCount)}}</span>人
        </div>
      </div>
      <div class="list_tips tips_normal tick_green"
           v-if="isDateType==3&&(Number(item.FullRateCount)==0&&Number(item.LeaveCount)==0)">
        <div>
        </div>
      </div> -->

  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: ['item'],
  computed: {
    vDate () {
      if (this.item) {
        let date = this.item.ClassTime.replace(/-/g, '/');
        let lastDate = Date.new(Date.new().setTime(Date.new().getTime() - 24 * 60 * 60 * 1000));
        let preDate = Date.new(Date.new().setTime(Date.new().getTime() + 24 * 60 * 60 * 1000));
        if (date == this.$utils.formatDatet(Date.new(), 'yyyy/MM/dd')) {
          return '今天';
        } else if (date == this.$utils.formatDatet(lastDate, 'yyyy/MM/dd')) {
          console.log(date, '昨天111', lastDate);
          return '昨天';
        } else if (date == this.$utils.formatDatet(preDate, 'yyyy/MM/dd')) {
          return '明天';
        } else {
          return this.$utils.formatDatet(Date.new(date), 'MM') + '月' + this.$utils.formatDatet(Date.new(date), 'dd') + '日';
        }
      }
    },
    vWeek () {
      if (this.item) {
        return this.$utils.formatDatet(Date.new(this.item.ClassTime.replace(/-/g, '/')), 'ddddd');
      }
    },
    // 判断日期是否小于今天,大于今天.以便控制显示
    isDateType () {
      if (this.item) {
        let date = this.item.ClassTime.replace(/-/g, '/');
        if (date == this.$utils.formatDatet(Date.new(), 'yyyy/MM/dd')) {
          return 2;
        } else if (Date.new() < Date.new(date)) {     // 明天或以后
          return 3;
        } else {            // 昨天或以前
          return 1;
        }
      } else {
        return 1;
      }
    }
  },
  methods: {
    chgCoureDate () {
      console.log(this.item, 'isDateType', this.isDateType);
      // let type = this.isDateType != 3;
      this.$emit('chgCoureDateAfter', this.item);
    }

  }

};
</script>

