import {
  UploadFiles,
  AdminUploadFiles
} from '../API/workbench';
import {
  md5
} from '../API/md5';

// 上传WKT图片
export function upLoadImg (fileImg, md5Code, saasClientKey, callBack) {
  const fd = new FormData();
  fd.append('file', fileImg, 'image.png');
  UploadFiles(1, md5Code, fd)
    .then(response => {
      const data = response.data;
      if (data) {
        callBack(data.url);
      }
    }, err => {
      callBack('');
      layer.alert(err.msg);
      console.info(err);
    });
}
// 上传PC图片
export function upLoadPCImg (fileImg, md5Code, saasClientKey, callBack) {
  var formData = new FormData();
  formData.append('file', fileImg, 'image.png');
  let apiFN = (Number(saasClientKey) > 0 ? AdminUploadFiles : UploadFiles);
  apiFN(1, '', formData, saasClientKey).then(response => {
    if (callBack) {
      callBack(response.data.url);
    }
  }, (err) => {
    callBack('');
    layer.alert(err.msg);
  });
}
// 处理需要调用WKT上传接口的使用
export function htmlToUpLoadImg (dom, saasClientKey, height, width, fontNeedToImgList, md5code, callBack) {
  document.getElementById('staticMomery').contentWindow.html2Img(
    dom,
    height,
    width,
    fileImg => {
      upLoadImg(fileImg, md5code, saasClientKey, callBack);
    },
    fontNeedToImgList,
    window.document.getElementsByTagName('style') // 加载css
  );
}
// 处理需要调用PC上传接口的使用
export function htmlToPCUpLoadImg (dom, saasClientKey, height, width, fontNeedToImgList, md5code, callBack) {
  document.getElementById('staticMomery').contentWindow.html2Img(
    dom,
    height,
    width,
    fileImg => {
      upLoadPCImg(fileImg, md5code, saasClientKey, callBack);
    },
    fontNeedToImgList,
    window.document.getElementsByTagName('style') // 加载css
  );
}
// selectedCurrentTarget 被替换元素的currentTarget
// cutImgInfo 裁剪图片的信息
// newImgInfo 新图片的信息（原图）
// callBack 回调
export function createDomForCutImg (selectedCurrentTarget, cutImgInfo, newImgInfo, callBack) {
  // 判断是否已创建过 若创建过则先删除
  var parent = document.getElementById('screanContent');
  var child = document.getElementById('createdom');
  if (child) {
    parent.removeChild(child);
  }
  let createDom = document.createElement('div');
  createDom.setAttribute('style', 'z-index:0; opacity:1; position:relative; overflow:hidden;');
  createDom.id = 'createdom';

  // 2、创建一个img标签
  var newImg = document.createElement('img');
  // 1.把被选图片的宽和高获取出来 复制给装载的DIV
  createDom.style.width = selectedCurrentTarget.clientWidth + 'px';
  createDom.style.height = selectedCurrentTarget.clientHeight + 'px';
  let ratio = selectedCurrentTarget.clientWidth / cutImgInfo.w;
  // 3.把所选图片的地址给新创建的img
  newImg.src = newImgInfo.url;
  newImg.style.position = 'absolute';
  // 6.重新计算图片的宽高
  newImg.style.width = ratio * newImgInfo.width + 'px';
  newImg.style.height = ratio * newImgInfo.height + 'px';
  // 7.重新设置图片的坐标
  newImg.style.left = -(cutImgInfo.l < 0 ? 0 : cutImgInfo.l) * ratio + 'px';
  newImg.style.top = -(cutImgInfo.t < 0 ? 0 : cutImgInfo.t) * ratio + 'px';

  createDom.appendChild(newImg);
  document.getElementById('screanContent').appendChild(createDom);
  callBack(createDom);
  document.getElementById('screanContent').removeChild(createDom);
}
// 处理需要调用PC上传接口的使用
export function htmlToPCUpLoadImgSimple (dom, saasClientKey, callBack) {
  htmlToPCUpLoadImg(dom, saasClientKey, dom.offsetHeight, dom.offsetWidth, [], '', callBack);
}
// 处理需要调用wkt上传接口的使用
export function htmlToUpLoadImgSimple (dom, saasClientKey, callBack) {
  htmlToUpLoadImg(dom, saasClientKey, dom.offsetHeight, dom.offsetWidth, [], '', callBack);
}

// 文字转base64图片
export function htmlTocanvas (elJson, el, saasClientKey, callBack) {
  const dom = el.children[0];
  htmlToUpLoadImg(dom, saasClientKey, dom.offsetHeight, dom.offsetWidth, [elJson.fontSet], elJson.md5, callBack);
}

// 保存封面图片.
export function htmlToCoverImg (saasClientKey, height, width, callBack, fontNeedToImgList) {
  const dom = document.getElementById('screanContent');
  const md5code = '';
  htmlToUpLoadImg(dom, saasClientKey, height, width, fontNeedToImgList, md5code, callBack);
}

// 文本对象转MD5码
export function convertToMd5 (obj) {
  // 非默认字体的文本编辑器 需转为图片
  if (
    obj.text &&
    obj.text.fontFamily !== '' &&
    obj.text.fontFamily !== '默认字体' &&
    obj.text.fontFamily !== 'none' &&
    obj.text.fontFamily
  ) {
    // 生成md5码数据源
    const mdObj = {};
    mdObj.text = obj.text;
    mdObj.width = obj.width;
    mdObj.height = obj.height;
    mdObj.content = obj.content;
    const mdStr = JSON.stringify(mdObj);
    const md5Code = md5(mdStr);
    return md5Code;
  } else {
    return '';
  }
}

// base64转图片文件
export function base64ToBlob (dataURI, type) {
  const binary = atob(dataURI.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type });
}
