<template>
  <!-- 通用列表 -->
  <div class=" table_list_content">
    <div class="single_result_detail">
      <!-- 筛选条件 -->
      <div class="summarizing_number_left"
           style="padding:0">
        <span class>在校学生
          <span class="form_info_field">
            <input-form-tip :tips="'已报名购课且未办理离校的学生。'"></input-form-tip>
          </span>
        </span>
      </div>
      <div class="summarizing_student_top_box content_top_data_box course_class_summarizing_right ">
        <div class="flex">
          <div class="right_top_box box_right fr">
            <summary-data-item v-for="(item,key,index) in headSummaryData"
                               :sumData="item"
                               :key="index"
                               @onCilckEvent="doAfterCilckSummaryData"></summary-data-item>
          </div>
        </div>
      </div>
      <!-- 已选条件 -->
      <div class="content_search fr"
           style="margin-top: 7px"
           v-if="!tableColumnsType">
        <div class="course_search_form"
             style="margin-right:10px;width: 280px;">
          <div class="course_search_select search_top">
            <el-select v-model="searchValue"
                       size="small"
                       @change="chgSearchValue">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="course_search_input input_select"
               :style="tableColumnsType?'width: 100%':'width: 78%;'"
               @click="doSelectCourseSearch(searchValue)">
            <input type="text"
                   :style="tableColumnsType?'':'padding-right:4px'"
                   :readonly="searchValue==3"
                   :placeholder="searchValue==1?'请输入姓名、姓名首字母、手机号':'请选择'"
                   v-model="searchText" />
            <span v-show="searchText"
                  class="home_remove_btn"
                  @click="clearExtendSearchParams"></span>
          </div>
        </div>
        <el-button class="btn_light_blue btn_hover_bg_blue"
                   style="margin-right:10px"
                   @click="searchStudent"
                   v-if="searchValue!=3">搜索</el-button>
        <el-button class="btn_light_white btn_hover_white"
                   @click="clearExtendSearchParams">清除条件</el-button>
      </div>
      <div v-else-if="searchValue==1">
        <input-search-contain-btn v-model="searchText"
                                  style="margin-top:7px;margin-bottom: 22px;"
                                  :placeholder="'请输入姓名、姓名首字母、手机号'"
                                  :width="'280px'"
                                  @onSearchEvent="searchStudent"
                                  @clearSearch="clearExtendSearchParams"></input-search-contain-btn>
      </div>
      <!-- 
          选中行高亮  highlight-current-row 
          隔行换色  stripe
          数据为空展示的文字  empty-text
          选中行 复选框联动  toggleRowSelection
        -->
      <table-list ref="tableListRef"
                  :tableData="tableData"
                  :position="''"
                  :tableColumns="tableColumns"
                  :expandRowData="expandRowData"
                  :rowKey="rowKey"
                  :totalNum="totalNum"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  @loadTableData="loadTableData"></table-list>

    </div>
  </div>
</template>
<script>
import summaryDataItem from '../summary-data/item';
import inputSearchContainBtn from '../common/input-search-contain-btn';
import tablePaginationNumber from '../../common/table-pagination-number';
import tableList from '../../common/table-list/index';
export default {
  data () {
    return {
      searchText: '',
      queryParams: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: 'StudentKeyValue', //	字符串	可选		数据源：GET	排序字段
        sequence: 'asc', //	字符串	可选		数据源：GET	排序顺序
        searchText: ''
      },
      isShowSummary: false,
      searchValue: 1, // 默认搜学生//3 课程
      options: [
        // {label: '全部', value: 0},
        { label: '学生', value: 1 },
        { label: '课程', value: 3 }
      ],
      isLoading: false

    };
  },
  props: {
    tableData: Array, // 列表数据
    tableColumns: Array, // table column,
    expandRowData: Array, // 展开行列表数据
    rowKey: [String, Number], // 显示详情对应绑定的ID
    totalNum: Number, // 分页总共条数
    headSummaryData: Array, // 头部汇总数据列表
    summary: {
      type: Object,
      default: function () {
        return {
          isShowSummary: false,
          needSummariesColumns: [],
          summaryName: '合计',
          summaryData: []
        };
      }
    },
    isShowSearch: Boolean,
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: '', order: 'ascending' };
      }
    },
    tableColumnsType: {
      type: String,
      default: ''
    }

  },
  components: {
    summaryDataItem,
    inputSearchContainBtn,
    tablePaginationNumber,
    tableList
  },
  watch: {
    'summary.summaryData': {
      handler (n, o) {
        if (this.summary.isShowSummary) {
          this.$nextTick(() => {
            this.isShowSummary = true;
          });
        }
      },
      deep: true
    }
  },
  created () {
    if (this.summary.isShowSummary) {
      this.isShowSummary = true;
    }
  },
  mounted () {
  },
  methods: {
    searchStudent () {
      this.queryParams.searchText = this.searchText;
      this.$refs.tableListRef.expandRowKeys = [];
      this.$emit('searchStudent', this.queryParams.searchText);
    },
    // 汇总数据排序
    doAfterCilckSummaryData (item) {
      console.log('点击汇总数据排序', item);
      this.queryParams.pageIndex = 0;
      this.searchText = '';
      this.queryParams.searchText = this.searchText;
      this.isLoading = true;
      this.$refs.tableListRef.expandRowKeys = [];
      this.$emit('doAfterCilckSummaryData', item, () => {
        this.isLoading = false;
      });
    },
    // 清除
    clearExtendSearchParams () {
      this.searchText = '';
      this.queryParams.searchText = this.searchText;
      this.queryParams.pageIndex = 0;
      this.$refs.tableListRef.expandRowKeys = [];
      this.$emit('clearSearchParams');
    },
    // 选择搜索类型
    chgSearchValue (value) {
      console.log(value, '选择搜索类型');
      this.searchValue = value;
      this.searchText = '';
      this.$refs.tableListRef.expandRowKeys = [];
      this.$emit('searchStudent', this.searchText);
    },
    // 搜索课程
    doSelectCourseSearch (value) {
      if (value == 3) { // 课程
        this.queryParams.searchText = '';
        this.$refs.tableListRef.expandRowKeys = [];
        this.$emit('doSelectCourseSearch', this.queryParams);
      }
    },
    loadTableData (queryParams) {
      this.$emit('loadTableData', queryParams);
    }
  }
};
</script>
<style>
</style>

