<!-- 排课备注 -->
<template>
  <el-table-column :fixed="columnData.fixed||false"
                   :label="columnData.label"
                   :prop="columnData.prop"
                   :width="columnData.width||218"
                   :sortable="columnData.sortable"
                   :align="columnData.align||'left'"
                   :show-overflow-tooltip="true"
                   :label-class-name="columnData.columnClass"
                   :render-header="columnData.renderHeader">
    <template slot-scope="scope">

      <!-- <el-popover v-if="$utils.getTextWidth(scope.row[columnData.prop], 16)>236"
                  popper-class="new_popper_class"
                  placement="top"
                  width="auto"
                  trigger="hover"
                  :content="scope.row[columnData.prop]">
        <div slot="reference"
             style="cursor: pointer;"> -->
      <span :class="[vClassName,vIsEdit, ]"
            v-if="!scope.row.isEmpertyRow"
            @click="clickEvent(scope.row)">{{scope.row[columnData.prop]}}</span>
      <!-- </div>
      </el-popover> -->
    </template>
  </el-table-column>
</template>

<script>
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          type: String,
          extend: {
            click: () => { } // 表示该列可点击
          }
        };
      }
    }
  },
  computed: {
    vClassName () {
      let className = '';
      if (this.columnData.extend && this.columnData.extend.className) {
        className = this.columnData.extend.className;
      }
      return className;
    },
    vIsEdit () {
      if (this.columnData.extend && typeof this.columnData.extend.click == 'function') {
        return 'edit_icon';
      } else {
        return '';
      }
    }
  },
  created () {
  },
  methods: {
    clickEvent (rowData) {
      if (this.columnData.extend && typeof this.columnData.extend.click == 'function') {
        let item = {
          routerName: this.$route.name,
          moduleName: 'arrangeSupportEdit',
          name: '备注',
          data: { content: rowData[this.columnData.prop] },
          callBack: { updateSuccess: (val) => {
            rowData[this.columnData.prop] = val;
          } }
        };
        this.$dialog.open(this, item);
      }
    }
  }
};
</script>

<style>
</style>