<template>
  <div>
    <div class="attendance_arrange"
         :class="ClassName">
      <div class="class-list">
        <!-- 课信息 -->
        <course-info :courseInfo="courseInfo"
                     @toEdit="editCourseShow"
                     @updateCourseInfo="updateCourseInfo"></course-info>
        <div class="index_course_info_item form_info_bottom">
          <div >
            <div class="course_student_attendance_title">
              <div class="title_text">预约学生
                   <span class="font_red" v-if="Number(courseInfo.AppointmentCount)==Number(courseInfo.FullPeopleCount)">满</span>
                      <span  class="font_green" v-else-if="Number(courseInfo.AppointmentCount)>=Number(courseInfo.StartCourseCount)">到</span>
                      <span  class="font_yellow" v-else>约</span>
                <span class="font_gray">{{Number(courseInfo.AppointmentCount)}}/{{Number(courseInfo.FullPeopleCount)}}</span>
              </div>
              <div class="font_gray" style="line-height: 30px;">满{{Number(courseInfo.StartCourseCount)}}人开课</div>
            </div>
            <student-list ref="studentListRef"
                          :selectedCourseInfo="courseInfo"
                          :studentArrangeList="courseInfo.StudentList"
                          :isAlter="isAlter"
                          @updateCourseInfo="updateCourseInfo"
                          ></student-list>
            <!-- 操作按钮 -->

          </div>
          <!-- <course-bottom :courseInfo="courseInfo"></course-bottom> -->
        </div>
      </div>
    </div>
    <!-- <div v-if="isShowBtn"
         @click="cancelAndBack"
         style="background-color:#fff;border-top:1px solid #ececec"
         class="confirm_btn btn_fixed_bottom">
      <div class="btn_fixed_blue btn_hover_bg_blue"
           style="width:100%">完成并返回</div>
    </div> -->
       <save-cancel-btn-group :isSingleSubmit="true"
                                 :singleSubmitType="'dynamic'"
                                 @confirmClick="closeDialog"
                                 @cancelClick="closeDialog"></save-cancel-btn-group>
  </div>
</template>

<script>
import courseInfo from './course-info';
// import courseBottom from './course-bottom';
import studentList from './student-list';
// import attendanceStatistics from './attendance-statistics';
// import selectAllTip from './select-all-tip';
import {
  GetSubscribeCourseDetails, 
  getScheduleCourseOnStudentList
} from '../../../API/workbench';
export default {
  data () {
    return {
      scheduleCourseKey: '',
      isAlter: true,
      isBatchAttendance: false, // 是否批量考勤
      courseInfo: {
        StudentList:[]
      },
      isShowEditCourse: false,
      isShowEditCourseDialog: false
    };
  },
  components: {
    studentList, courseInfo,  
  },
  // 提供外部输出显示，不给就输出内部默认显示
  props: {
    courseKey: {
      type: [Number, String]
    },
    isShowBtn: {
      type: Boolean,
      default: true
    },
    formTitle: {
      // 标题，
      type: String,
      default: '默认'
    },
    ClassName: {
      type: String,
      default: 'course_overflow'
    }
  },
  created () {
    if (this.formTitle == '查看课程') {
      this.isAlter = false;
    }
    console.log(this.courseKey, '000000000000000000');
    if (this.courseKey > 0) {
      this.scheduleCourseKey = this.courseKey;
      this.getCourseInfo();
      this.resgisterBusEvent();
    }
  },
  watch: {
    courseKey (n, o) {
      if (n) {
        this.scheduleCourseKey = n;
        this.isBatchAttendance = false;
        this.getCourseInfo();
      }
    }
  },
  computed: {
    isTeacher () {
      if (this.courseInfo && this.courseInfo.ScheduleCourseKey) {
        if (this.courseInfo.MTeacherKey && this.courseInfo.MTeacherKey.indexOf(this.$store.getters.token.UserID) != -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  mounted () {
    this.$dialog.register(this, ['editAppointCourse', 'studentFile']);
  },
  destroyed(){
    console.log("destroyed",11111)
    this.$emit("afterSuccess")
  },
  methods: {
    updateCourseInfo (OLAPKey) {
      console.log('updateCourseInfo', OLAPKey, this.courseInfo);
      if (OLAPKey == this.courseInfo.OLAPKey) {
        this.getCourseInfo();
      }
    },
    getCourseInfo (callBack) {
      GetSubscribeCourseDetails(this.scheduleCourseKey || this.courseInfo.ScheduleCourseKey).then(
        res => {
          if (res.code == 0) {
            if (res.data.ClassTime) {
              res.data.week = this.$utils.getWeekByTwo(res.data.ClassTime);
            }
            this.courseInfo = res.data;
            console.log('getCourseDetailed', this.courseInfo);
            if (typeof callBack == 'function') {
              callBack(this.courseInfo);
            }
          }
        },
        err => {
          console.log(err);
        }
      );
    },
    // 修改课程.
    editCourseShow () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'editAppointCourse',
        data: { courseInfo: this.courseInfo },
        callBack: { afterSuccess: () => { this.doAfterEditCourse(); } }

      });
    },
   
    // 关闭 修改课程
    closeEditCourse () {
      this.isShowEditCourse = false;
      this.$nextTick(() => {
        this.isShowEditCourseDialog = false;
      });
    },
    // 修改课后
    doAfterEditCourse () {
      // this.closeEditCourse();
      this.getCourseInfo();
    },
    closeDialog(){
      this.$emit("closeDialog")
    },
    resgisterBusEvent () {
      
    }

  }
};
</script>

<style scoped>
/* 快速签到 */
.quick_signIn {
  float: right;
}
.title_text .font_red {
  border: 1px solid #fa5a46;
  color: #fa5a46;
  border-radius: 2px;
      margin-right: 5px;
          display: inline-block;
    line-height: 16px;
        padding: 1px;
}
.title_text .font_green {
  border: 1px solid #52c3a1;
  color: #52c3a1;
  border-radius: 2px;
      margin-right: 5px;
          display: inline-block;
    line-height: 16px;
        padding: 1px;
}
.title_text .font_yellow {
  border: 1px solid #fcc756;
  color: #fcc756;
  border-radius: 2px;
      margin-right: 5px;
          display: inline-block;
    line-height: 16px;
    padding: 1px;
}
</style>

