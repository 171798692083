<template>
  <!-- <div class="package_details_amount label_radio_bg_white"> -->
  <div class="form_item form-input form_info_line"
       :class="[vamountOpt,vRequired]">
    <div class="form_info_field">
      <span v-html="formTitle"></span>
    </div>
    <div class="form_info_value">
      <span>{{vTextValue}}</span>
    </div>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  data () {
    return {
    };
  },
  props: {
    formTitle: {
      type: String,
      required: ''
    }, // 标题，必填
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    amountOpt: {
      type: Boolean,
      required: false
    } // 标题，必填
  },
  computed: {
    vTextValue () {
      if (Number(this.value) >= 0) {
        return '￥' + this.$utils.mMoneyType(this.value, 2);
      } else {
        return this.value;
      }
    },
    vRequired () {
      if (this.required) {
        return 'form_info_required';
      } else {
        return '';
      }
    },
    vamountOpt () {
      if (this.amountOpt) {
        return 'form_info_required';
      } else {
        return '';
      }
    }
  },
  created () {
  },
  methods: {

  }
};
</script>


