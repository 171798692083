<template>
  <div>
    <div class="form_info_detail form_info_serach_box">
      <div class="title_pre">
        <div class="title_pre_box"
             :class="titleChooice?'title_pre_box_option':''"
             @click.stop="titleChooiceEvent(1)">未授权校区</div>
        <div class="title_pre_box"
             :class="titleChooice?'':'title_pre_box_option'"
             @click.stop="titleChooiceEvent(2)">已授权校区&nbsp;{{choiceCapusList.length}}</div>
      </div>
      <!-- 搜索选择层 -->
      <input-search-contain-btn v-model="
             params.searchText"
                                :placeholder="'请输入校区名称'"
                                :showSearchBtn="false"
                                @input="searchTextSearch">
      </input-search-contain-btn>
      <!-- 搜索结果 -->
      <div class="search_result">
        <div class="search_result_content search_result_content_for_student">
          <div class="search_result_list"
               @scroll="scrollTOP">
            <Item v-for="item in vShowCampusList"
                  :key="item.OLAPKey"
                  :Item="item"
                  @authorizationItem="authorizationItem"></Item>
            <div v-if="loading"
                 class="students_loading all_list_bottom">
              <span class="el-icon-loading"></span>
              <span class="loading_style">加载中...</span>
            </div>
            <div v-else-if="campusList.length==0&&titleChooice">
              <div class="monitoring_content_nodata">
                <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
              </div>
            </div>
            <div v-else-if="vShowCampusList.length==0&&!titleChooice">
              <div class="monitoring_content_nodata">
                <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
              </div>
            </div>
            <div v-else
                 class="all_list_bottom">
              <span class="complete">已显示全部</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom_box">
        <div class="el-button btn_light_blue btn_hover_bg_blue bottom_box_btn"
             @click.stop="componentsAuth">完成</div>
      </div>
    </div>
  </div>

</template>
<script>
import Item from './multiple-item.vue';
import { HQSearchPageForBigCoursewarePackageAuthorizaSaaSClient, SearchSaaClientList } from '../../../../../API/workbench';
export default {
  name: 'campusListMultipleEls',
  data () {
    return {
      campusList: [],
      params: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: '', //	字符串	可选		数据源：GET	排序顺序
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号
        BigCoursewarePackageKey: '', //	整型	必须		数据源：GET	 大课包ID
        IsOpenChannelKey: 1
      },
      loading: false,
      noMore: false, // 没有更多数据加载了
      titleChooice: true,
      choiceCapusList: [],
      searchTextAuth: '',
      isResultDatalenght:false //判断是否接口请求到了数据  没数据取消请求接口

    };
  },
  props: {
    BigCoursewarePackageKey: { // 是否在校 0-否; 1-是; 空- 全部
      type: [String, Number],
      default: ''
    },
    campusListTypeKey: [String, Number],
    dataList: {
      type: Array,
      default: []
    }
  },
  components: {
    Item
  },
  watch: {
    // 'params.searchText' () {
    //   if (this.params.searchText == '') {
    //     this.campusList.length = 0;
    //     this.pageSize = 20;
    //     this.params.pageIndex = 0;
    //     this.noMore = false;
    //     this.getCampusForList();
    //   }
    // }
  },
  computed: {
    vShowCampusList () {
      let arr1 = [];
      if (this.titleChooice) {
        arr1 = this.campusList;
      } else {
        if (this.searchTextAuth.length > 0) {
          arr1 = this.choiceCapusList.filter(p => p.MainDemoName.indexOf(this.searchTextAuth) > 0);
        } else {
          arr1 = this.choiceCapusList;
        }
      }
      return arr1;
    }
  },
  created () {
    this.params.BigCoursewarePackageKey = this.BigCoursewarePackageKey;
    this.choiceCapusList = JSON.parse(JSON.stringify(this.dataList));
    this.getCampusForList();
  },
  methods: {
    // 点击搜索
    searchTextSearch () {
      if (this.titleChooice) {
        this.params.searchText = this.params.searchText.replace(/ /g, '');
        this.params.pageIndex = 0;
        this.noMore = false;
        this.isResultDatalenght = false
        this.getCampusForList();
      } else {
        this.searchTextAuth = this.params.searchText.replace(/ /g, '');
        // let searchData = [];
        // searchData = this.choiceCapusList.filter(p => p.MainDemoName.indexOf(text) > 0);
        // console.log('搜索', searchData);
      }
    },
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;
      let HeightView = ETarget.scrollHeight - ETarget.clientHeight;
      // console.log(this.noMore, 'this.noMore');
      if (parseInt(ETarget.scrollTop) == parseInt(HeightView) && !this.noMore) {
        if (this.titleChooice) {
          this.getCampusForList();
        }
      }
    },
    getCampusForList () {
      if(this.isResultDatalenght){
        return
      }
      this.loading = true;
      let ApiName = this.campusListTypeKey == 1 ? HQSearchPageForBigCoursewarePackageAuthorizaSaaSClient : SearchSaaClientList;
      ApiName(this.params).then(result => {
        if (result.data) {
          // 切换校区类型时 (首页)需清空数据
          if (this.params.pageIndex == 0) {
            this.campusList = [];
          }
          let resultData = result.data.PageDataList || result.data.data.PageDataList;

          if(resultData.length < this.params.pageSize) {
            this.isResultDatalenght = true
          }
          resultData.forEach(o => {
            o.isActive = false;
            let hasFind = false;
            if (this.campusList.length > 0) {
              hasFind = this.campusList.findIndex(p => p.OLAPKey == o.OLAPKey) >= 0;
            }
            if (!hasFind) {
              this.campusList.push(o);
            }
            this.campusList.forEach(item => {
              item.isAuthorizationValue = '授权';
            });
          });
          // let list = this.campusList;
          let list = this.campusList.filter(item => !this.choiceCapusList.some(ele => ele.OLAPKey === item.OLAPKey));
          // this.campusList.forEach(items => {
          //   this.choiceCapusList.forEach(os => {
          //     if (os.OLAPKey == items.OLAPKey) {
          //       this.campusList.splice(this.campusList.indexOf(items), 1);
          //     }
          //   });
          // });
          this.campusList = list;
          console.log('去重后', list);
          // this.campusList = list;
          if (resultData.length < this.pageSize) {
            this.noMore = true;
          }
        }
        this.loading = false;
        this.params.pageIndex++;
      }).catch(error => {
        this.loading = false;
        console.log(error);
      });
    },
    scrollBackTop () {
      this.params.searchText = '';
      document.getElementsByClassName('search_result_content_for_student')[0].scrollTop = 0;
      if (document.getElementsByClassName('search_result_content_for_student')[1]) {
        document.getElementsByClassName('search_result_content_for_student')[1].scrollTop = 0;
      }
    },
    clearSearText () {
      this.params.searchText = '';
    },
    titleChooiceEvent (type) {
      if (type == 1) {
        this.titleChooice = true;
      } else {
        this.titleChooice = false;
      }
    },
    authorizationItem (item) {
      if (item.isAuthorizationValue == '授权') {
        this.campusList.splice(this.campusList.indexOf(item), 1);
        item.isAuthorizationValue = '取消';
        this.choiceCapusList.push(item);
        let list = this.campusList.filter(item => !this.choiceCapusList.some(ele => ele.OLAPKey === item.OLAPKey));
        console.log('list.length',list.length)
        if(list.length<10){
          this.getCampusForList();
        }
      } else {
        this.choiceCapusList.splice(this.choiceCapusList.indexOf(item), 1);
        item.isAuthorizationValue = '授权';
        this.campusList.push(item);
      }
    },
    componentsAuth () {
      this.choiceCapusList.forEach(item => {
        item.AuthorizationSaaSClientKey = item.OLAPKey;
        item.AuthorizationSaaSClientKeyValue = item.MainDemoName;
      });
      console.log('this.choiceCapusList', this.choiceCapusList);
      this.$emit('afterSuccess', this.choiceCapusList);
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.title_pre {
  cursor: pointer;
  width: 100%;
  height: 60px;
}
.title_pre_box {
  width: 50%;
  float: left;
  align-items: center;
  text-align: center;
  height: 60px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 60px;
  color: #666666;
  border-bottom: 1px solid #ececec;
}
.title_pre_box_option {
  font-style: normal;
  font-weight: 700;
  color: #333333;
  border-bottom: 4px solid #3498db;
}
.search_result_list {
  height: 430px;
}
.bottom_box {
  height: 60px;
  padding: 10px 20px;
}
.bottom_box_btn {
  width: 100%;
  height: 100%;
}
</style>
