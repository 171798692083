<template>
  <div class="form_info_value">
    <input
      :type="isTexttype?'text':'number'"
      :placeholder="placeholder?placeholder:(required?'必填':'选填')"
      :value="vInputValue"
      :readonly="readonly"
      @click="clickEvent()"
      @input="inputEvent($event.target.value)"
      @change="changeEvent($event.target.value)"
      onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/\.{2,}/g,'.')"
    />
  </div>
</template>
<script>
import {
  numberToDecimalPlaces,
  isIntermediateValue
} from '../../utils/index.js';
export default {
  name: 'inputNumber',
  data () {
    return {
      inputValue: 0,
      defaultValue: 0, // 默认值,
      defaultDecimalPlaces: 2, // 默认值
      isWrithing: false,
      isTexttype: this.isText
    };
  },
  props: ['max', 'min', 'value', 'placeholder', 'decimalPlaces', 'readonly', 'isText'], // 单选数据项 需绑定到界面
  computed: {
    vInputValue: function () {
      if (this.isTexttype && this.inputValue <= 0) {
        console.log(this.placeholder, 'this.placeholder');
        return this.placeholder;
      } else {
        console.log(this.placeholder, 'this.placeholder');
        return this.isWrithing
        ? isIntermediateValue(this.inputValue)
          ? this.inputValue
          : numberToDecimalPlaces(this.inputValue, this.vDecimalPlaces)
        : this.inputValue.toFixed(this.vDecimalPlaces);
      }
    },
    vDecimalPlaces () {
      return this.decimalPlaces || this.defaultDecimalPlaces;
    }
  },
  created () {
    this.setInputValue(this.value);
    console.log(this.placeholder, 'this.placeholder');
  },
  watch: {
    value (c, o) {
      this.setInputValue(c);
    }
  },
  methods: {
    setInputValue (v) {
      this.inputValue = Number(v);
      this.inputValue = this.inputValue || this.defaultValue;
    },
    inputEvent (val) {
      this.inputValue = val || '0';
      if (!isIntermediateValue(this.inputValue)) {
        this.$emit('inputChange', this.inputValue);
      }
    },
    changeEvent (val) {
      this.inputValue = numberToDecimalPlaces(val, this.vDecimalPlaces);
      this.inputValue = this.inputValue > this.max ? this.max : this.inputValue;
      this.inputValue = this.inputValue < this.min ? this.min : this.inputValue;
      console.log('changeEvent', this.inputValue);
      this.$emit('change', this.inputValue);
      this.$emit('input', this.inputValue);
      this.$emit('inputChange', this.inputValue);
    },
    focusEvent () {
      this.isWrithing = true;
    },
    blurEvent () {
      this.isWrithing = false;
    },
    clickEvent () {
      if (this.isTexttype) {
        this.inputValue = (this.inputValue > 0) ? this.inputValue : '';
        this.isTexttype = false;
      }
    }
  }
};
</script>


