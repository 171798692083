<template>
  <div class="right_top_list fl"
       style="cursor: pointer"
       :class="{opt:sumData.isSelected}"
       @click.stop="cilckEvent">
    <div class="">{{sumData.title}}
      <span :class="sumData.className"
            style="font-size: 18px;">{{mAmountType(sumData.value,sumData.decimalPlace)}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sumData: {
      type: Object,
      required: true,
      default: () => {
        return { title: '标题名', className: '样式名称', value: 0, type: 'number', decimalPlace: 1, opt: '高亮位置' };
      }
    },
    cursorShow: {
      type: String,
      default: 'cursor: pointer'
    },
    canClick: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    mAmountType (count, decimalPlaces) {
      let newCount = Number(Number(count).toFixed(1));
      let lastCount = newCount.toString().substr(newCount.length - 2, 2);
      if (lastCount == '.0') {
        if (Number(Number(count).toFixed(0)) == 0) {
          return '--';
        } else {
          return Number(Number(count).toFixed(0));
        }
      } else {
        if (Number(Number(count).toFixed(decimalPlaces)) == 0) {
          return '--';
        } else {
          return Number(Number(count).toFixed(decimalPlaces));
        }
      }
    },
    cilckEvent () {
      // if (Number(this.sumData.value) > 0) {
        this.$emit('onCilckEvent', this.sumData);
      // } else if (this.canClick) {           // 兼容没有数据时支持点击. 需要参数canClick为true
      //   this.$emit('onCilckEvent', this.sumData);
      // }
    }
  }
};
</script>

<style>
</style>