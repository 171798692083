<template>
  <div class="">
    <!-- 选择学生 -->
    <div class="select_xu_checkbox_group">
      <select-student-item v-for="(item) in studentListInfo"
                           :key="item.StudentNameKey"
                           :dataInfo="item"></select-student-item>
      
      <!-- <basics-button-single-comfire-btn :vBtnText="'确认'"
                                        :isSingleSubmit="false"
                                        @click="completeStudent"></basics-button-single-comfire-btn> -->
    </div>
    <div class="confirm_btn btn_fixed_bottom">
        <div class="btn_fixed_blue btn_hover_bg_blue"
             @click.stop="completeStudent">完成并返回</div>
      </div>
  </div>

</template>

<script>
import { GetScheduleCourseStudentListForHomeWork } from '../../../../../API/workbench';
import selectStudentItem from './select-student-item.vue';
export default {
  data () {
    return {
      studentListInfo: []
    };
  },
  components: {
    selectStudentItem
  },
  props: {
    courseId: [String, Number],
    studentList: Array
  },
  computed: {
    vStudentList () {
      let arr = [];
      this.studentListInfo.forEach(e => {
        if (e.isActive == true) {
          arr.push(e);
        }
      });
      return arr;
    }
  },
  created () {
    this.getStudentListInfo();
  },
  methods: {
    getStudentListInfo () {
      GetScheduleCourseStudentListForHomeWork(this.courseId || '326850').then(res => {
        this.studentListInfo = [];
        res.data.forEach(e => {
          e.isActive = false;
          e.StudentKey = e.StudentNameKey;
          e.StudentKeyValue = e.StudentNameKeyValue;
          this.studentListInfo.push(e);
        });
        this.studentListInfo.forEach(obj => {
          if (this.studentList && this.studentList.length > 0) {
            this.studentList.forEach(e => {
              if (obj.StudentKey == e.StudentKey) {
                obj.isActive = e.isActive;
              }
            });
          }
          // newArr.push(obj);
        });
      }, err => {
        console.log(err.msg);
      });
    },
    completeStudent () {
      console.log(this.vStudentList, '1111111');
      this.$emit('completeStudent', this.vStudentList);
    }
  }
};
</script>

<style>
</style>
