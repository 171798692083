<template>
  <div class="JXT_record_popup_menu">
    <div :class="{no_select:isRecording||isStopRecording}">
      <!-- uploading_voice -->
      <input v-if="!isRecording&&!isStopRecording"
             @change="chooseFiles($event)"
             class="uploading_input"
             type="file"
             accept="audio/*">
    </div>
  </div>
</template>

<script>import { UploadFiles } from '../../../../API/workbench.js';
export default {
  data () {
    return {
      isRecording: false, // 正在录音
      isStopRecording: false, // 暂停录音
      recorder: null,
      recorderDuration: 0, // 录音时长
      playRecorderDuration: 0 // 播放进度时长
    };
  },
  computed: {
    vBottomTextRecord () {
      let str = '点击开始录音';
      if (this.isRecording) {
        str = '点击暂停录音';
      } else if (this.isStopRecording) {
        str = '点击继续录音';
      }
      return str;
    },
    vRecordingDuration () { // 00:10 音频录入时长
      let min = Math.floor(this.recorderDuration / 60);
      min = min < 10 ? '0' + min : min;
      let sec = Math.floor(this.recorderDuration % 60);
      sec = sec < 10 ? '0' + sec : sec;
      let duration = min + ':' + sec;
      return duration;
    },
    vRecorderingPercentage () {
      let totalTime = 600; // 10分钟
      return (this.recorderDuration / totalTime) * 100;
    }
  },
  mounted () {
    // this.initRecorder();
  },
  methods: {
    // new一个Recorder对象
    initRecorder () {
      this.recorder = new window.Recorder({
        type: 'mp3',   // 此处的type类型是可修改的
        bitRate: 16,
        sampleRate: 16000,
        bufferSize: 8192
      });
    },
    // 点击开始 暂停 继续
    clickRecordbtn (event) {
      if (!this.isRecording && !this.isStopRecording) { // 点击开始录音
        this.startRecord();
      } else if (this.isRecording) { //  正在录音 -> 暂停录音
        this.pauseRecord();// 暂停录音
      } else if (this.isStopRecording) { // 暂停录音 -> 正在录音
        this.resumeRecord();
      }
    },
    // 开始录音
    startRecord () {
      var constraints = { audio: true };
      navigator.mediaDevices.getUserMedia(constraints)
        .then(() => {
          this.recorder.onprocess = (duration) => {
            console.log('this.recorder.onprocess->', duration);
            if (duration >= 600) {
              this.completeClick();
              layer.alert('录音时长不超过10分钟');
            }
            this.recorderDuration = Math.floor(duration);
          };
          this.isRecording = true;
          this.recorder.start();
        })
        .catch((error) => {
          console.log(error.name + ': ' + error.message);
          layer.alert('微信内置浏览器暂不支持录音,' + '<br/>请选择上传音频');
        });
    },
    // 暂停录音
    pauseRecord () {
      this.isStopRecording = true;
      this.isRecording = false;
      this.recorder.pause();
    },
    // 继续录音
    resumeRecord () {
      this.isRecording = true;
      this.isStopRecording = false;
      this.recorder.resume();
    },
    // 停止录音
    stopRecord () {
      this.recorder.stop();
    },
    // 完成录音
    completeClick () {
      this.stopRecord();
      if (this.recorderDuration < 5) {
        layer.alert('录制时间过短，必须大于5秒，请重新录制', { shadeClose: false }, 0, () => {
          this.isRecording = false;
          this.isStopRecording = false;
          this.recorderDuration = 0;
        });
      } else {
        let fileData = this.recorder.getWAVBlob();
        let formData = new FormData();
        formData.append('file', fileData, 'recorder.wav');
        this.uploadAudio(formData);
      }
    },
    // 点击上传音频
    chooseFiles (e) {
      var form = e.target.files[0];
      console.log(e.target.files[0], 'chooseFiles');
      // this.recorderDuration = e.target.files[0].size;
      let filename = (form.name.indexOf('.') > 0) ? form.name : form.name + '.wav';
      var formData = new FormData();
      formData.append('file', form, filename);
      this.uploadAudio(formData);
    },
    // 本地录音上传
    uploadAudio (formData) {
      console.log(formData, 'formData');
      UploadFiles(3, '', formData).then(result => {
        this.$emit('onUploadFiles', result.data.url, this.recorderDuration);
        this.cancelClick();
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 取消
    cancelClick () {
      // this.recorder.destroy();
      this.recorder = null;
      this.$emit('onCancelVoice');
    }
  }
};
</script>
