 <template>
  <!-- 购课 数量 -->
  <div class="bg_gray">
    <div class="purchase_order_form form_info_edit label_radio_bg_white">
        <div>
          <input-number :min="0"
                             v-model="NumCount"
                             :decimalPlaces="0"
                             :formTitle="'数量'"
                             :required="true"></input-number>
          <input-text :formTitle="'单位'"
                          :required="true"
                          :maxLength="4"
                        v-model="UnitName"></input-text>
          
      </div>
    </div>
    <!-- 按钮组 -->
    <div>
      <button-info @buttonClickEvent="confirmCount"
                   @closeDialog="cancelClick"></button-info>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      NumCount: 0,
      UnitName: ''
    };
  },
  props: ['counts', 'unitName'],
  created () {
    this.NumCount = Number(this.counts);
    this.UnitName = this.unitName;
  },
  methods: {
    // 传递按钮事件
    confirmCount () {
      if (!this.NumCount) {
        layer.alert('数量不能小于等于0!');
        return;
      }
      if (!this.UnitName) {
        layer.alert('单位不可为空');
        return;
      }
      this.$emit('confirmCount', this.NumCount, this.UnitName);
    },
    // 点击取消
    cancelClick () {
      this.$emit('cancleCountDialog');
    }
  }
};
</script>
