<template>
  <div :class="videoClass">
    <div @click="openVideoClick"
      class="img_box pr" >
           <!-- <video class="img_box"
             :src="vImgUrl"
             controls></video> -->
      <div class="play_button"></div>
      <img :src="vImgUrl" mode="aspectFill">
    </div>
    <play-video v-if="isShowPlayVideo"
                :dataInfo="videoInfo"
                @close="closePlayVideo"></play-video>


  	<!-- <div class="pr">
			<div class="marked_area_job_image">
				<div class="pr">
					<img src="../../../../assets/img/simulated_picture.jpg" mode=""></image>
					<div class="play_button">
						<img src="../../../../assets/img/play_button.png" mode=""></image>
					</div>
				</div>	
			</div>
	</div> -->
  </div>
</template>

<script>
import playVideo from '../../common-component/release-content/video-list/play-video';
export default {
  components: {
    playVideo
  },
  data () {
    return {
      isShowPlayVideo: false
    };
  },
  props: {
    videoClass: {
      type: String,
      default: 'article_details_video'
    },
    videoInfo: Object,
    controls: {// 是否播放
      type: Boolean,
      default: true
    }
  },
  computed: {
    vImgUrl () {
      let url = this.videoInfo.VideoCoverUrl;
      if (url && url.indexOf('data:image/') == -1 && url.indexOf('http://') == -1) {
        url = this.$store.getters.CDNURL + url;
      }
      return url;
    }
  },
  methods: {
    openVideoClick () {
      console.log('播放');
      if (this.controls) {
        this.isShowPlayVideo = true;
      }
    },
    closePlayVideo () {
      this.isShowPlayVideo = false;
    }
  }
};
</script>

<style>
	.article_details_video {
		width: 202rpx;
		height: 202rpx;
		margin-right: 12rpx;
		margin-top: 0;
	}
</style>
