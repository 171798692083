<template>
  <div>
    <div class="course_statistics_table_search flex course_statistics_title">

      <div class="course_statistics_search">

        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入学生名'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class="table_list_content">
      <tableList class="summarizing_list"
                 :key="tableKey"
                 :tableData="vdataReportList"
                 :totalNum="totalNum"
                 :PageCount="PageCount"
                 :tableColumns="vtableCourseColumns"
                 :isSearchText="false"
                 :isNeedInnerScroll="false"
                 :defaultSort="defaultSort"
                 :queryParams="searchObj"
                 :vFooterTotal="vFooterTotal"
                 @loadTableData="getDataList"></tableList>
    </div>
  </div>
</template>
<script>
import tableList from '../../../../common/table-list/index';
import inputSearchContainBtn from '../../../common/input-search-contain-btn';
import { GetTuitionBalanceStudentList } from '../../../../../API/workbench';
export default {
  data () {
    return {
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      seletedCourseInfo: {},
      studentDetail: {},
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        GradeKey: '',
        GradeKeyValue: '',
        CourseKey: '',
        CourseKeyValue: '',
        TeacherKey: '',
        TeacherKeyValue: '',
        startTime: '',
        endTime: '',
        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          className: 'table_index_gray'
        },
        {
          label: '学生名',
          prop: 'MainDemoName',
          width: ' ',
          minWidth: 90,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '手机号',
          prop: 'CustomerPhoneName',
          minWidth: 92,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '全部课单',
          prop: 'XYKDNum',
          width: ' ',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'number-item'

        },
        {
          label: '结余总额',
          prop: 'ValueAmount',
          minWidth: 120,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '课时结余',
          prop: 'ClassHourAmountValue',
          minWidth: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            click: (rowData) => {
              // if (rowData.TrialTime) {
              this.seletedItem = rowData;
              this.changeType(rowData, 3, 'ClassHourAmountValue');
              console.log('触发');
              // }
            }

          }
        },
        {
          label: '天数结余',
          prop: 'DayAmountValue',
          minWidth: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            click: (rowData) => {
              // if (rowData.TrialTime) {
              this.seletedItem = rowData;
              this.changeType(rowData, 4, 'DayAmountValue');
              console.log('触发');
              // }
            }

          }
        },
        {
          label: '销售',
          prop: 'AgentName',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'

        },
        {
          label: '最近上课',
          prop: 'LastSignTime',
          sortable: false,
          minWidth: 140,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        // {
        //   label: '操作',
        //   prop: 'option',
        //   type: 'table-btn',
        //   fixed: 'right',
        //   align: 'center',
        //   headerAlign: 'center',
        //   width: 100,
        //   extend: {
        //     tableBtnGroup: [
        //       {
        //         name: '查看',
        //         extend: {
        //           click: (rowData) => {
        //             this.showStudentFile(rowData);
        //           },
        //           ModulePowerKey: ''
        //         }
        //       }

        //     ]
        //   }
        // }
      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    inputSearchContainBtn
  },
  props: {
    toSearchObj: {
      type: Object
    },
    searchText: String,
    searchTime: Object,
    schoolItem: Object
  },
  created () {
    console.log(this.toSearchObj, 'this.toSearchObj');
    this.schoolKey = this.$route.query.schoolKey || 0;
  },
  watch: {
    searchTime: {
      handler (c, o) {
        if (c) {
          //  this.getDateRangeCoursewareStat( this.$utils.getCurrentMonthFirst(),this.$utils.getCurrentMonthLast())
          console.log("searchTime")
          this.searchObj.startTime = c.startTime
          this.searchObj.endTime = c.endTime
          this.getDataList();
        }
      },
      immediate: true,
      deep: true
    },
    'schoolItem.OLAPKey': {
      handler (c, o) {
        if (c) {
          console.log('schoolItem.OLAPKey', this.schoolItem.OLAPKey)
          //  this.getDateRangeCoursewareStat( this.$utils.getCurrentMonthFirst(),this.$utils.getCurrentMonthLast())
          setTimeout(o => {
            this.getDataList();
          }, 100)
        }
      },
    }

  },
  computed: {
    vtableCourseColumns () {

      if (this.$store.getters.SaaSClientEditionKey != 5) {
        let obj = {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          fixed: 'right',
          align: 'center',
          headerAlign: 'center',
          width: 100,
          extend: {
            tableBtnGroup: [
              {
                name: '查看',
                extend: {
                  click: (rowData) => {
                    this.showStudentFile(rowData);
                  },
                  ModulePowerKey: ''
                }
              }

            ]
          }
        }
        this.tableCourseColumns.push(obj)
      }
      return this.tableCourseColumns
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.ValueAmount = '￥' + Number(Number(o.ValueAmount).toFixed(2));
        o.ClassHourAmountValue = Number(o.ClassHourAmount) == 0 ? `-` : `<span class="font_blue hover">` + Number(Number(o.ClassHourNum).toFixed(2)) + `课时/￥` + Number(Number(o.ClassHourAmount).toFixed(2)) + `</span>`;
        o.DayAmountValue = Number(o.DayAmount) == 0 ? `-` : `<span class="font_blue hover">` + Number(Number(o.DayNum).toFixed(2)) + `天/￥` + Number(Number(o.DayAmount).toFixed(2)) + `</span>`;
        o.LastSignTime = o.LastSignTime ? o.LastSignTime.substring(0, 16) : '';
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text;
      if (this.totalAmount > 0) {
        text = '+' + this.$utils.setMoneyformatToParseInt(this.totalAmount);
        text = ',合计' + text + '元';
      } else {
        text = ',合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      }
      return text;
    }
  },
  mounted () {
    // this.getDataList();
  },
  methods: {
    changeType (item, type, indexKey) {
      console.log('changeType', item, type);
      if (item[indexKey] == '-') {
        return false
      }
      this.$emit('changeType', item, type);
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.GradeKey = '';
      this.searchObj.CourseKey = '';
      this.searchObj.TeacherKey = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = GetTuitionBalanceStudentList;
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '学生结余统计',
          clist: [
            { title: '学生名', cName: 'MainDemoName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '手机号', cName: 'CustomerPhoneName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '全部课单', cName: 'XYKDNum', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            {
              title: '结余总额', cName: 'ValueAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::ValueAmount::2::0}}"
            },
            {
              title: '课时', cName: 'ClassHourNum', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::ClassHourNum::2::0}}"
            },
            {
              title: '课时总额', cName: 'ClassHourAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::ClassHourAmount::2::0}}"
            },
            {
              title: '天数', cName: 'DayNum', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::DayNum::2::0}}"
            },
            {
              title: '天数总额', cName: 'DayAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::DayAmount::2::0}}"
            },
            { title: '销售', cName: 'AgentName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '最近上课', cName: 'LastSignTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(searchObj, this.schoolItem.OLAPKey, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      let fn = GetTuitionBalanceStudentList;
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj, '22222');
      fn(this.searchObj, this.schoolItem.OLAPKey).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // 显示学生信息
    showStudentFile (item) {
      this.$dialog.open(this, {
        name: '学生档案',
        routerName: this.$route.name,
        moduleName: 'studentFile',
        data: { studentID: item.StudentKey }
      });
    }

  }
};
</script>
<style>
.hover {
  cursor: pointer;
}
</style>