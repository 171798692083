<template>
  <!-- 售后客户明细表 -->
  <div class="table_list_content summarizing_list">
    <div class="single_result_detail after_sale_detail">
      <div class="after_sale_list">
        <div class="customer_table_follow">
          <div class="customer_table_box">
            <div class="table_title">跟进</div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('FollowStartTime',vtoday)">
              <span class="font_gray">今天</span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.FollowTodayCount,0)||0}}</span>
            </div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('FollowStartTime',vtomorrow)">
              <span class="font_gray">明天</span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.FollowTomorrowCount,0)||0}}</span>
            </div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('FollowEndTime',vyesterday)">
              <span class="font_gray">过期</span>
              <span class="table_number font_red">{{$utils.mAmountType(reportStatistics.FollowExpiredCount,0)||0}}</span>
            </div>
          </div>

          <div class="customer_table_box">
            <div class="table_title">要紧</div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('IsGradeStarKey',1)">
              <span class="table_icon01"></span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.GradeStarCount,0)||0}}</span>
            </div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('IsGradeVKey',1)">
              <span class="table_icon02"></span>
              <span class="table_number font_red">{{$utils.mAmountType(reportStatistics.GradeVCount,0)||0}}</span>
            </div>
          </div>

          <div class="customer_table_box">
            <div class="table_title">介绍</div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('StartCount',1)">
              <span class="font_gray">0-5</span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.IntroduceCount1,0)||0}}</span>
            </div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('StartCount',2)">
              <span class="font_gray">6-10</span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.IntroduceCount2,0)||0}}</span>
            </div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('StartCount',3)">
              <span class="font_gray">>11</span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.IntroduceCount3,0)||0}}</span>
            </div>
          </div>

          <div class="customer_table_box padding_font">
            <div class="table_name cp"
                 @click="doReportStatisticsClick('StayBack',1)">
              <span>介绍费</span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.Introduce,0)||0}}</span>
            </div>
          </div>

          <div class="customer_table_box padding_font">
            <div class="table_name cp"
                 @click="doReportStatisticsClick('AttenRate',1)">
              <span>考勤≤50%</span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.AttendRate50Count,0)||0}}</span>
            </div>
          </div>

          <div class="customer_table_box padding_font">
            <div class="table_name cp"
                 @click="doReportStatisticsClick('Renew',1)">
              <span>续费＜30天</span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.RenewCount,0)||0}}</span>
            </div>
          </div>
          <div class="customer_table_box">
            <div class="table_name cp"
                 @click="doReportStatisticsClick('IsBalance',1)">
              <span>尾款</span>
              <span class="table_number font_red">{{$utils.mAmountType(reportStatistics.BalanceAmount,0)||0}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex">
        <div class="table_select_box">
          <div class="table_select_title">销售</div>
          <el-select :value="vStaffValue"
                     filterable
                     placeholder="必选"
                     value-key="OLAPKey"
                     @change="doSelectedStaff">
            <el-option v-for="item in staffList"
                       :key="item.OLAPKey "
                       :label="item.MainDemoName"
                       :value="item"></el-option>
          </el-select>
        </div>
        <div class="table_select_box">
          <div class="table_select_title">成交</div>
          <el-select :value="vBargainValue"
                     filterable
                     placeholder="必选"
                     value-key="key"
                     @change="doSelectedBargain">
            <el-option v-for="item in bargainCountList"
                       :key="item.key "
                       :label="item.name"
                       :value="item"></el-option>
          </el-select>
        </div>
        <div class="table_select_box">
          <div class="table_select_title">学生</div>
          <el-select :value="vStudentValue"
                     filterable
                     placeholder="必选"
                     value-key="key"
                     @change="doSelectedStudent">
            <el-option v-for="item in studentCountList"
                       :key="item.key "
                       :label="item.name"
                       :value="item"></el-option>
          </el-select>
        </div>

      </div>

      <div class="flex">
        <div class="table_select_box date_box">
          <!-- <div class="table_select_title">开户时间</div> -->
          <el-select :value="dateItemValue"
                     filterable
                     placeholder="必选"
                     value-key="key"
                     @change="doDateSwitch">
            <el-option v-for="item in dateList"
                       style="'width:100px'"
                       :key="item.key "
                       :label="item.name"
                       :value="item"></el-option>
          </el-select>
        </div>
        <!-- drop-down-box  -->
        <div style="padding-right: 38px;">
          <date-range ref="monthPicker"
                      :fromTitle="''"
                      :searchObj="searchObj"
                      @changeDate="changeDredgeDate"></date-range>
        </div>
        <!-- 已选条件 -->
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入门店名称'"
                                  @clearSearch="clearExtendSearchParams"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin-left: 630px;"
               @click.stop="doExportInfoClick">导出</div>
        </div>

      </div>
      <table-list ref="tableListRef"
                  class="customer_mendian_list"
                  :tableData="mendianQueryList"
                  :tableColumns="tableColumns"
                  :rowKey="'Number'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  :heightValue="'100%'"
                  @loadTableData="getMessageSearchPageAfterSaleForManage"></table-list>
    </div>
    <custom-dialog :title="'转介绍'"
                   width="986px"
                   :visible.sync="isShowIntroduceCountPopup"
                   :before-close="closeIntroduceCountPopup">
      <introduce-list :threadInfo="threadInfo"
                      @closeFrom="getMessageSearchPageAfterSaleForManage"></introduce-list>
    </custom-dialog>

    <custom-dialog title="查看员工"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isShowStaffListDialog"
                   :before-close="doCloStaffList">
      <staff-list :threadInfo="threadInfo"></staff-list>
    </custom-dialog>

    <custom-dialog title="分配员工"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isShowMarketListDialog"
                   :before-close="doCloMarketList">
      <market-list :selectionKey="threadInfo.DocumentaryKey"
                   @selectedItem="chgSelectMarket"
                   @doAfterClickCancelBtn="doCloMarketList"></market-list>
    </custom-dialog>

    <custom-dialog :title="'跟进情况'"
                   :visible.sync="isShowUpSellRecordFormPopup"
                   :before-close="closeUpSellRecordFormPopup">
      <up-sell-record :dataInfo="threadInfo"
                      @close="closeUpSellRecordFormPopup"
                      @afterSuccess="getMessageSearchPageAfterSaleForManage"></up-sell-record>
    </custom-dialog>

    <custom-dialog title="全部记录"
                   :visible.sync="isShowRecord"
                   :before-close="closeisShowRecord">
      <recordList :recordList="recordDataList"></recordList>
    </custom-dialog>

    <custom-dialog :title="'详情'"
                   width="1600px"
                   :visible.sync="isShowSaasClientDetails"
                   :before-close="closeSaasClientDetails">
      <saas-client-details :searchParams="queryParams"
                           :isShowOperate="false"
                           :mendianId="threadInfo.support20"></saas-client-details>
    </custom-dialog>
  </div>
</template>
<script>
import { MessageSearchPageAfterSaleForManage, SelectFollowRecode, GetSalesAgentForSaaSClient, GetAfterSalesReportForManage, UpdateDocumentary } from '../../../../API/workbench.js';
// import list from './table';
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import staffList from '../components/form-components/staff-list';
import marketList from '../components/form-components/market-list';
import introduceList from './introduce-list';
import customerThread from '../components/form-components/customer-thread';
import temporaryAccountOpening from '../components/form-components/temporary-account-opening';
import upSellRecord from '../components/form-components/up-sell-record';
import recordList from '../components/form-components/up-sell-record-component/record-list';
import cleanData from '../components/form-components/clean-data';
import paymentRequest from '../components/form-components/payment-request';
import saasClientDetails from './saas-client-details';
const queryParamsInfo = {
  pageIndex: 0, //	整型	必须		数据源：GET	页码，0为第一页
  pageSize: 10, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
  orderBy: '', //	字符串	可选		数据源：GET	排序字段
  sequence: 'desc', //	字符串	可选		数据源：GET	排序字段
  searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
  IntentionKey: '', //	整型	必须		数据源：GET	意向1-S;2-A;3-B;4-C;
  FollowStartTime: '', //	字符串	可选		数据源：GET	跟进日期开始
  FollowEndTime: '', //	字符串	可选		数据源：GET	跟进日期结束
  DemoStartTime: '', //	字符串	可选		数据源：GET	演示日期开始
  DemoEndTime: '', //	字符串	可选		数据源：GET	演示日期结束
  StartDredgeTime: '', // 字符串	可选		数据源：GET	开户时间开始
  EndDredgeTime: '', // 字符串	可选		数据源：GET	开户时间结束
  StartExpireTime: '', // 续费日期起
  EndExpireTime: '', // 续费日期止
  DocumentaryKey: '', //	字符串	可选		数据源：GET	销售人id
  DocumentaryKeyValue: '',
  IsUseKey: '', //	字符串	可选		数据源：GET	是否在用 空-不生效;0-否;1-是
  IsGradeStarKey: '', //	字符串	可选		数据源：GET	是否为星 空-不生效;0-否;1-是
  IsGradeVKey: '', //	字符串	可选		数据源：GET	是否为V 空-不生效;0-否;1-是
  StartCount: '', //	整型	可选		数据源：GET	介绍起始数
  EndCount: '', //	整型	可选		数据源：GET	介绍终止数
  StartBargain: '', //	整型	可选		数据源：GET	成交起始数
  EndBargain: '', //	整型	可选		数据源：GET	成交起始数
  StartStudentCount: '', //	整型	可选		数据源：GET	学生起始数
  EndStudentCount: '', //	整型	可选		数据源：GET	学生起始数
  StayBack: '', //	整型	可选		数据源：GET	待返是否大于0 0-否;1-是
  Renew: '', //	整型	可选		数据源：GET	续费是否小于30天 0-否;1-是
  IsBalance: '', //	整型	可选		数据源：GET	是否有尾款 0-否 1-是
  AttenRate: '' //	整型	可选		数据源：GET	考勤率是否小于50% 0-否;1-是
};
export default {
  components: {
    tableList,
    inputSearchContainBtn,
    customerThread,
    introduceList,
    staffList,
    marketList,
    temporaryAccountOpening,
    upSellRecord,
    recordList,
    cleanData,
    paymentRequest,
    saasClientDetails
  },
  props: {
    searchParams: Object      // 外围传递进来的整个搜索条件.
  },
  data () {
    return {
      // 查询参数
      queryParams: {},
      mendianQueryList: [], // 门店查询列表
      mendianStatisticsInfo: {}, // 统计信息
      threadInfo: {}, // 线索信息
      isShowIntroduceCountPopup: false, // 转介绍列表
      isShowStaffListDialog: false, // 员工列表
      isShowMarketListDialog: false, // 销售列表
      isShowUpSellRecordFormPopup: false, // 跟进情况表单
      isShowRecord: false, // 记录表单
      isShowSaasClientDetails: false,
      recordDataList: [],
      // 销售
      staffList: [],
      // 成交
      bargainCountList: [
        { name: '全部', key: 0 },
        { name: '0', key: 1 },
        { name: '1-2', key: 2 },
        { name: '3以上', key: 3 }
      ],
      // 学生
      studentCountList: [
        { name: '全部', key: 0 },
        { name: '100', key: 1 },
        { name: '101-200', key: 2 },
        { name: '201-500', key: 3 },
        { name: '500以上', key: 4 }
      ],
      // 日期
      dateList: [{ name: '开户日期', key: 1 }, { name: '续费日期', key: 2 }],
      dateItemValue: '开户日期', // 默认选中开户日期
      reportStatistics: [], // 统计数据
      // 日期
      searchObj: {
        searchText: '', // 搜索
        startTime: '',
        endTime: ''
      },
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          fixed: 'left',
          type: 'sell-documentary-table-row',
          className: 'font_gray',
          decimalPlace: 0
        },
        {
          label: '销售',
          prop: 'DocumentaryKeyValue',
          width: 90,
          sortable: false,
          align: 'left',
          fixed: 'left',
          isShow: true,
          type: 'sell-documentary-table-row',
          extend: {
            className: 'font_blue',
            isClick: true,
            click: (rowData) => {
              this.doChgDocumentaryClick(rowData);
            }
          }
        },
        {
          label: '要紧',
          prop: 'IsGradeStarKey',
          width: 80,
          sortable: false,
          align: 'left',
          fixed: 'left',
          isShow: true,
          type: 'sell-documentary-table-row'
          // extend: {
          //   isClick: true,
          //   click: (rowData, val) => {
          //     this.doImportantClick(rowData, val);
          //   }
          // }
        },
        {
          label: '机构名',
          prop: 'SaaSClientNameKeyValue',
          width: 110,
          align: 'left',
          fixed: 'left',
          sortable: false,
          type: 'sell-documentary-table-row'
        },
        {
          label: '门店功能',
          prop: 'storeFunction',
          width: 80,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '门店类型',
          prop: 'TypeKeyValue', // SaaSClientCountText
          width: 80,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '门店版本',
          prop: 'EditionKeyValueText',
          width: 80,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '联系人/手机号',
          prop: 'ContactsName',
          width: 120,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '介绍',
          prop: 'IntroduceCount',
          width: 70,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: true,
            className: 'font_blue',
            click: (rowData) => {
              this.doIntroduceCountClick(rowData);
            }
          }
        },
        {
          label: '待返',
          prop: 'WaitReturnCount',
          width: 70,
          align: 'left',
          type: 'sell-documentary-table-row',
          decimalPlace: 0,
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '跟进情况描述',
          prop: 'FTime',
          align: 'left',
          width: 250,
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            // isEdit: true,
            isClick: true,
            click: (rowData) => {
              this.doMarketFollowRecodeClick(rowData);
            }
          }
        },
        {
          label: '下次跟进',
          prop: 'FollowTime',
          width: 90,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: 'custom',
          extend: {
            isEdit: true,
            isClick: true,
            click: (rowData) => {
              this.doFollowTimeClick(rowData);
            }
          }
        },
        {
          label: '员工',
          prop: 'AgentCount',
          width: 70,
          align: 'left',
          type: 'sell-documentary-table-row',
          decimalPlace: 0,
          sortable: false,
          extend: {
            isClick: true,
            className: 'font_blue',
            click: (rowData) => {
              this.doAgentClick(rowData);
            }
          }
        },
        {
          label: '运行情况',
          prop: 'SaaSClientPort',
          width: 180,
          align: 'left',
          type: 'sell-documentary-table-row',
          decimalPlace: 0,
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '来源方式',
          prop: 'SourceTypeKeyValue',
          width: 110,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '开户',
          prop: 'DredgeTime',
          width: 150,
          align: 'left',
          sortable: 'custom',
          type: 'sell-documentary-table-row',
          extend: {
            isClick: false
          }
        },
        {
          label: '合同付款',
          prop: 'TotalAmountText',
          width: 90,
          align: 'left',
          sortable: false,
          decimalPlace: 2,
          type: 'sell-documentary-table-row',
          extend: {
            isClick: false
          }
        },
        {
          label: '尾款',
          prop: 'BalanceAmount',
          width: 80,
          align: 'left',
          sortable: false,
          decimalPlace: 2,
          type: 'sell-documentary-table-row',
          extend: {
            isClick: false,
            className: 'font_red'
          }
        },
        {
          label: '流量空间',
          prop: 'FluxCount',
          width: 120,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '操作',
          prop: 'option',
          width: 100,
          isShow: this.searchParams.IsAbandon === 0,
          type: 'sell-documentary-table-btn',
          align: 'left',
          fixed: 'right',
          extend: {
            tableBtnGroup: [
              {
                extend: {
                  name: (rowData) => { // 详情
                    return Number(rowData.SaaSClientCount) > 1 ? '详情' : '';
                  },
                  click: (rowData) => { this.saaSClientDetails(rowData); }

                }
              }
            ]
          }
        }
      ],
      totalNum: 0, // 分页器总数
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  created () {
    this.queryParams = queryParamsInfo;
    if (this.searchParams) {
      console.log(this.searchParams, 'after-sale-detail-created');
      this.queryParams = this.$utils.parseJson(this.searchParams);
      // 续费是否小于30天 0-否;1-是
      if (this.searchParams.Renew == 1) {
        this.renewDateSet();
      }
    }
  },
  mounted () {
    this.getMessageSearchPageAfterSaleForManage();
    this.getSalesAgentForSaaSClient();
  },
  computed: {
    vIsAbandon () {
      // if (this.searchParams.IsAbandon && this.searchParams.IsAbandon === 0) { // 客户池
      return true;
      // } else {
      //   return false;
      // }
    },
    // 销售
    vStaffValue () {
      return this.queryParams.DocumentaryKey === '' ? '全部' : this.queryParams.DocumentaryKeyValue;
    },
    // 成交
    vBargainValue () {
      if (this.queryParams.StartBargain === 0 && !this.queryParams.EndBargain) {
        return '0';
      } else if (this.queryParams.StartBargain == 1 && this.queryParams.EndBargain == 2) {
        return '1-2';
      } else if (this.queryParams.StartBargain == 3 && !this.queryParams.EndBargain) {
        return '3以上';
      } else {
        return '全部';
      }
    },
    // 学生
    vStudentValue () {
      if (this.queryParams.StartStudentCount === 0 && this.queryParams.EndStudentCount == 100) {
        return '100';
      } else if (this.queryParams.StartStudentCount == 101 && this.queryParams.EndStudentCount == 200) {
        return '101-200';
      } else if (this.queryParams.StartStudentCount == 201 && this.queryParams.EndStudentCount == 500) {
        return '201-500';
      } else if (this.queryParams.StartStudentCount == 500 && !this.queryParams.EndStudentCount) {
        return '500以上';
      } else {
        return '全部';
      }
    },
    // 昨天的时间
    vyesterday () {
      var day1 = Date.new();
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000);
      let month = day1.getMonth() + 1;
      let date = day1.getDate();
      var s1 = day1.getFullYear() + '-' + (Number(month) < 10 ? '0' + month : month) + '-' + (Number(date) < 10 ? '0' + date : date);
      return s1;
    },
    // 今天的时间
    vtoday () {
      var day2 = Date.new();
      day2.setTime(day2.getTime());
      let month = day2.getMonth() + 1;
      let date = day2.getDate();
      var s2 = day2.getFullYear() + '-' + (Number(month) < 10 ? '0' + month : month) + '-' + (Number(date) < 10 ? '0' + date : date);
      return s2;
    },
    // 明天的时间
    vtomorrow () {
      var day3 = Date.new();
      day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000);
      let month = day3.getMonth() + 1;
      let date = day3.getDate();
      var s3 = day3.getFullYear() + '-' + (Number(month) < 10 ? '0' + month : month) + '-' + (Number(date) < 10 ? '0' + date : date);
      return s3;
    }
  },
  methods: {
    // 按续费日期过滤
    renewDateSet () {
      this.dateItemValue = '续费日期';
      this.searchObj.startTime = this.$utils.formatDateToLine(Date.new());
      this.searchObj.endTime = this.$utils.getNextDate(Date.new(), 29);
      this.queryParams.StartExpireTime = this.searchObj.startTime;// 续费日期起
      this.queryParams.EndExpireTime = this.searchObj.endTime;// 续费日期止
    },
    // 获取售前跟单统计
    getAfterSalesReportForManage () {
      GetAfterSalesReportForManage().then(result => {
        console.log(result.data, '售后-获取售前跟单统计');
        this.reportStatistics = result.data;
      });
    },
    // 获取指定门店销售
    getSalesAgentForSaaSClient () {
      GetSalesAgentForSaaSClient(1, '').then(result => { // 空-表示所有 0-否;1-是
        console.log('获取指定门店销售', result.data);
        // 分页数
        if (result.data && result.data.length > 0) {
          this.staffList = result.data;
        } else {
          this.staffList = [];
        }
        this.staffList.splice(0, 0, {
          OLAPKey: '',
          MainDemoName: '全部'
        });
        this.staffList.splice(1, 0, {
          OLAPKey: 0,
          MainDemoName: '未分配'
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 管理与统计-》售后跟单
    getMessageSearchPageAfterSaleForManage (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.queryParams.searchText = this.queryParams.searchText.trim();
      this.getAfterSalesReportForManage();// 统计售后跟单
      MessageSearchPageAfterSaleForManage(this.queryParams).then(result => {
        console.log('售后跟单', result.data);
        this.isShowAddCustomerFormPopup = false;
        this.totalNum = result.data.Total;
        // 分页数
        if (result.data && result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach(o => {
            o.dredgeType = 1;// 售后
            o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
            o.CompleteCount = Number(o.CompleteCount);
            o.IntroduceCount = o.CompleteCount + '/' + Number(o.IntroduceCount);
            // 根据学生数显示考勤率
            if (Number(o.StudentCount) > 0) {
              // 考勤人数+考勤率
              o.AttendText = Number(o.AttendCount) + ' | ' + (Number(o.AttendRate) > 50 ? Number(o.AttendRate) + '%' : `<span class="font_red">${Number(o.AttendRate) + '%'}</span>`);
            } else {
              o.AttendText = '';
            }
            if (o.TotalAmount && o.StrokeCount) {
              o.TotalAmountText = this.$utils.mAmountType(o.TotalAmount, 2) + ' ' + o.StrokeCount + '笔';
            }
            o.BalanceAmount = Number(o.BalanceAmount) > 0 ? o.BalanceAmount : '-';
            switch (Number(o.EditionKey)) { // 2标准 3旗舰 4高级 5总部 6课件
              case 2:
                o.EditionKeyValueText = '精准版';
                break;
              case 3:
                o.EditionKeyValueText = '旗舰版';
                break;
              case 4:
                o.EditionKeyValueText = '高级版';
                break;
              case 5:
                o.EditionKeyValueText = '连锁版';
                break;
              case 6:
                o.EditionKeyValueText = '课件版';
                break;
              default:
                break;
            }
          });
          console.log('tableColumns', this.tableColumns[this.tableColumns.length - 1].extend.tableBtnGroup);
          this.mendianQueryList = result.data.PageDataList;
        } else {
          this.mendianQueryList = [];
        }
        document.querySelector('.popup_dialog .el-table__body-wrapper ').scrollTop = 0;// 默认滚动条置顶
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 变更销售人
    chgSelectMarket (item) {
      console.log('SelectFollowRecode', item);
      UpdateDocumentary(this.threadInfo.OLAPKey, item.OLAPKey, item.MainDemoName).then(result => {
        this.doCloMarketList();
        this.getMessageSearchPageAfterSaleForManage();
        layer.alert('变更成功');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 开打销售人列表
    doChgDocumentaryClick (rowData) {
      this.threadInfo = rowData;
      this.isShowMarketListDialog = true;
    },
    // 关闭变更销售人列表
    doCloMarketList () {
      this.isShowMarketListDialog = false;
    },
    // 转介绍表单
    doIntroduceCountClick (rowData) {
      console.log(rowData, '转介绍表单');
      this.threadInfo = rowData;
      this.isShowIntroduceCountPopup = true;
    },
    // 关闭转介绍表单
    closeIntroduceCountPopup () {
      this.isShowIntroduceCountPopup = false;
    },
    // 员工表单
    doAgentClick (rowData) {
      console.log(rowData, '员工');
      this.threadInfo = rowData;
      this.isShowStaffListDialog = true;
    },
    // 关闭员工表单
    doCloStaffList () {
      this.isShowStaffListDialog = false;
    },
    // 点击跟进情况描述操作
    doMarketFollowRecodeClick (rowData) {
      this.threadInfo = rowData;
      SelectFollowRecode(this.threadInfo.OLAPKey).then(result => {
        this.recordDataList = result.data;
        console.log('SelectFollowRecode', result.data);
        this.isShowRecord = true;
      }, error => {
        layer.alert(error.msg);
      });

      console.log(rowData.Number, '操作菜单');
    },
    // 关闭跟进情况描述表单
    closeisShowRecord () {
      this.isShowRecord = false;
    },
    // 点击下次跟进操作
    doFollowTimeClick (rowData) {
      this.threadInfo = rowData;
      this.isShowUpSellRecordFormPopup = true;
      console.log(rowData.Number, '操作菜单');
    },
    closeUpSellRecordFormPopup () {
      this.isShowUpSellRecordFormPopup = false;
    },
    // 根据销售筛选
    doSelectedStaff (obj) {
      this.initializeData('SS');
      console.log(obj, '选中状态');
      this.queryParams.DocumentaryKeyValue = obj.MainDemoName;
      this.queryParams.DocumentaryKey = obj.OLAPKey;
      this.queryParams.pageIndex = 0;
      this.getMessageSearchPageAfterSaleForManage();
    },
    // 根据成交筛选
    doSelectedBargain (obj) {
      this.initializeData('SS');
      console.log(obj, '选中状态');
      switch (obj.key) {
        case 0:// 全部
          this.queryParams.StartBargain = '';
          this.queryParams.EndBargain = '';
          break;
        case 1:// 0
          this.queryParams.StartBargain = 0;
          this.queryParams.EndBargain = '';
          break;
        case 2:// 1-2
          this.queryParams.StartBargain = 1;
          this.queryParams.EndBargain = 2;
          break;
        case 3:// 3以上
          this.queryParams.StartBargain = 3;
          this.queryParams.EndBargain = '';
          break;
        default:
          break;
      }
      this.queryParams.pageIndex = 0;
      this.getMessageSearchPageAfterSaleForManage();
    },
    // 根据学生筛选
    doSelectedStudent (obj) {
      this.initializeData('SS');
      console.log(obj, '选中状态');
      switch (obj.key) {
        case 0:// 全部
          this.queryParams.StartStudentCount = '';
          this.queryParams.EndStudentCount = '';
          break;
        case 1:// 100
          this.queryParams.StartStudentCount = 0;
          this.queryParams.EndStudentCount = 100;
          break;
        case 2:// 101-200
          this.queryParams.StartStudentCount = 101;
          this.queryParams.EndStudentCount = 200;
          break;
        case 3:// 201-500
          this.queryParams.StartStudentCount = 201;
          this.queryParams.EndStudentCount = 500;
          break;
        case 4:// 500以上
          this.queryParams.StartStudentCount = 500;
          this.queryParams.EndStudentCount = '';
          break;

        default:
          break;
      }
      this.queryParams.pageIndex = 0;
      this.getMessageSearchPageAfterSaleForManage();
    },
    // 汇总信息筛选
    doReportStatisticsClick (type, value) {
      this.initializeData('SS');
      // setTimeout(() => {
      switch (type) {
        case 'IsGradeStarKey':
          this.queryParams.IsGradeStarKey = value;
          break;
        case 'IsGradeVKey':
          this.queryParams.IsGradeVKey = value;
          break;
        case 'FollowStartTime':
          this.queryParams.FollowStartTime = value;
          this.queryParams.FollowEndTime = value;
          break;
        case 'FollowEndTime':
          this.queryParams.FollowStartTime = '';
          this.queryParams.FollowEndTime = value;
          break;
        case 'StartCount':
          if (value == 1) {
            this.queryParams.StartCount = 0;
            this.queryParams.EndCount = 5;
          } else if (value == 2) {
            this.queryParams.StartCount = 6;
            this.queryParams.EndCount = 10;
          } else if (value == 3) {
            this.queryParams.StartCount = 11;
            this.queryParams.EndCount = 1000;
          }
          break;
        case 'StayBack':
          this.queryParams.StayBack = value;
          break;
        case 'AttenRate':
          this.queryParams.AttenRate = value;
          break;
        case 'Renew':
          this.renewDateSet();
          this.queryParams.Renew = '';
          break;
        case 'IsBalance':
          this.queryParams.IsBalance = value;
          break;
        default:
          break;
      }
      this.getMessageSearchPageAfterSaleForManage();
      // }, 100);
    },
    // 日期切换
    doDateSwitch (obj) {
      console.log(obj, '日期切换');
      if (this.dateItemValue == obj.name) {
        return false;
      }
      this.dateItemValue = obj.name;
      this.queryParams.StartDredgeTime = '';
      this.queryParams.EndDredgeTime = '';
      this.queryParams.StartExpireTime = '';
      this.queryParams.EndExpireTime = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
    },
    //  开户日期筛选
    changeDredgeDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.queryParams.pageIndex = 0;
    },
    // 搜索
    search () {
      this.initializeData('SS');
      this.queryParams.searchText = this.searchObj.searchText;
      if (this.dateItemValue == '开户日期') {
        this.queryParams.StartDredgeTime = this.searchObj.startTime;
        this.queryParams.EndDredgeTime = this.searchObj.endTime;
      } else {
        this.queryParams.StartExpireTime = this.searchObj.startTime;
        this.queryParams.EndExpireTime = this.searchObj.endTime;
      }
      this.queryParams.pageIndex = 0;
      this.getMessageSearchPageAfterSaleForManage();
    },
    // 清除条件
    clearExtendSearchParams (type) {
      console.log(this.queryParams, '清除条件');
      this.initializeData(); // 初始化
      this.searchObj = {
        searchText: '', // 搜索
        startTime: '',
        endTime: ''
      };
      this.getMessageSearchPageAfterSaleForManage();
    },
    // 初始化数据
    initializeData (type) {
      if (this.searchParams) {
        this.queryParams = this.$utils.parseJson(this.searchParams);
      } else {
        this.queryParams = this.$utils.parseJson(queryParamsInfo);
      }
      this.queryParams.DocumentaryKey = (type == 'SS' ? this.searchParams.DocumentaryKey : ''); //	字符串	可选		数据源：GET	销售人id
      this.queryParams.DocumentaryKeyValue = (type == 'SS' ? this.searchParams.DocumentaryKeyValue : '');
      this.queryParams.StartBargain = (type == 'SS' ? this.searchParams.StartBargain : '');// 成交起始数
      this.queryParams.EndBargain = (type == 'SS' ? this.searchParams.EndBargain : '');// 成交起始数
      this.queryParams.StartStudentCount = (type == 'SS' ? this.searchParams.StartStudentCount : '');// 学生起始数
      this.queryParams.EndStudentCount = (type == 'SS' ? this.searchParams.EndStudentCount : '');// 学生起始数
      console.log(this.queryParams, '初始化数据');
      this.$refs.tableListRef.$refs.multipleTable.clearSort();
    },
    // 详情
    saaSClientDetails (rowData) {
      this.threadInfo = rowData;
      this.isShowSaasClientDetails = true;
    },
    closeSaasClientDetails () {
      this.isShowSaasClientDetails = false;
    },
    // 导出
    doExportInfoClick () {
      if (this.mendianQueryList.length > 0) {
        var obj = this.$utils.parseJson(this.queryParams);
        obj.IsExportKey = 1;
        obj.pageIndex = 0;
        obj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '售后客户明细表',
          clist: [
            { title: '销售', cName: 'DocumentaryKeyValue', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '机构名', cName: 'SaaSClientNameKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '门店类型', cName: 'TypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '门店数', cName: 'SaaSClientCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '机构ID', cName: 'support20', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '联系人', cName: 'ContactsName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '手机号', cName: 'MobileSupport', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '介绍数量', cName: 'IntroduceCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '成交数量', cName: 'CompleteCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '待返数量', cName: 'WaitReturnCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '跟进时间', cName: 'FTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '跟进内容', cName: 'ContentScript', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '下次跟进', cName: 'FollowTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '员工数', cName: 'AgentCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生数', cName: 'StudentCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '考勤人数', cName: 'AttendCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '考勤率', cName: 'AttendRate', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '来源方式', cName: 'SourceTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '开户开始日期', cName: 'DredgeTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '开户失效日期', cName: 'ExpireDateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '合同总金额', cName: 'TotalAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '付款笔数', cName: 'StrokeCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '尾款', cName: 'BalanceAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchPageAfterSaleForManage(obj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    }
  }
};
</script>

<style>
.table_select_box.date_box {
  width: 95px;
  padding-right: 5px;
}
.table_select_box.date_box .el-select {
  margin-left: 0;
}
.table_select_box.date_box .el-select .el-input.is-focus .el-input__inner {
  border-color: white;
}
.table_select_box.date_box .el-select input.el-input__inner {
  color: #666;
  border: none;
  padding-left: 0px;
  font-size: 14px;
}
.after_sale_detail .apply_course_ul::-webkit-scrollbar,
.course_search_tree::-webkit-scrollbar,
.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar,
.float-scroll::-webkit-scrollbar,
.list_drop_box ::-webkit-scrollbar,
.president_board_right_box_content
  .month_ranking_content_taber
  tbody::-webkit-scrollbar,
.search_result_list2::-webkit-scrollbar,
.students_list_left .left_ul::-webkit-scrollbar {
  height: 3.5px;
}
</style>

