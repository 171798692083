<template>
  <div class="edit-full-people-count edit_class_from">
    <div class="content_marginRL content_marginTop_30">
      <course-info :circleCourseInfo="circleCourseInfo"
                   v-if="vIsShowCircleCourseItem"></course-info>
      <choose-class-time-range :circleCourseInfo="circleCourseInfo" @confirmClassRangeTime="confirmClassRangeTime"></choose-class-time-range>
      <div class="new_course_title border_top_line"
             style="margin-bottom:12px">
          修改内容
        </div>
      <div class="mendian_setting_content form_info_edit">
        <input-number :min="0"
                      v-model="WarnLossCount"
                      :decimalPlaces="0"
                      :maxLength="4"
                      class="form_info_border_type no_border_bottom"
                      :formTitle="'亏损预警人数'"
                      :required="true"></input-number>

      </div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="closeDilog"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import courseInfo from '../course-info';
import chooseClassTimeRange from './common/choose-class-time-range';
import { EditWarnLossCountByRuleId, GetStructuredRuleRecentlyCourseInfo } from '../../../API/workbench';
export default {
  data () {
    return {
      classRangeTimeType: '全部',
      WarnLossCount: '',
      sClassDate: '', // 开始上课时间
      eClassDate: '' // 结束上课时间
    };
  },
  components: {
    courseInfo,
    chooseClassTimeRange
  },
  props: {
    circleCourseInfo: {
      type: Object
    }
  },
  computed: {
    vIsShowCircleCourseItem () {
      let bool = false;
      if (!this.circleCourseInfo.SubRule) { // SubRule为NULL 就显示循环课块
        bool = true;
      }
      return bool;
    }
  },
  created () {
    this.sClassDate = this.circleCourseInfo.SubRule ? this.circleCourseInfo.SubRule.StartTime : this.circleCourseInfo.CurrentRuleTime;
    this.eClassDate = this.circleCourseInfo.SubRule ? this.circleCourseInfo.SubRule.EndTime : this.circleCourseInfo.LastClassTime;

    this.getStructuredRuleRecentlyCourseInfo();
  },
  methods: {
    // 排课规律-》获取规律最近一节课课信息
    getStructuredRuleRecentlyCourseInfo () {
      if (this.circleCourseInfo.SubRule) {
        this.WarnLossCount = Number(this.circleCourseInfo.SubRule.WarnLossCount);
        return;
      }
      GetStructuredRuleRecentlyCourseInfo(this.circleCourseInfo.StructuredRuleKey).then(result => {
        this.WarnLossCount = Number(result.data.WarnLossCount);
      }, error => {
        layer.alert(error.msg);
      });
    },
    confirmClick (callback) {
      EditWarnLossCountByRuleId(this.circleCourseInfo.StructuredRuleKey,
        this.sClassDate, this.eClassDate,
        this.WarnLossCount).then(resule => {
          this.$emit('AfterEditCircleCourseWarnLossCount', this.circleCourseInfo.StructuredRuleKey);
          layer.alert('修改循环课计划亏损预警人数成功');
          this.closeDilog(this.circleCourseInfo.StructuredRuleKey);
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (typeof callback == 'function') { callback(); }
        });
    },
    closeDilog (ruleID) {
      this.$emit('closeDilog', ruleID);
    },
    // 确认 修改课表范围
    confirmClassRangeTime (startDate, endDate, classNum) {
      this.sClassDate = startDate;
      this.eClassDate = endDate;
    }
  }
};
</script>

<style>
</style>