<template>
  <div class="bg_gray minHeight">
    <!-- <basics-page-header :titleName="'作业详情'"></basics-page-header> -->
    <div class="homerwork_details_view">
      <div class="homerwork_time_info homerwork_details_banner">
        <work-course-head-box :dataInfo="workDetailsInfo"
                              v-if="workDetailsInfo.OLAPKey"></work-course-head-box>
      </div>
      <div class="homerwork_details_banner">
        <div class="homerwork_details_navbar">
          <work-switch-tab v-for="(item,key) in vtabList"
                           :key="key"
                           :item="item"
                           @onClickSwitch="tabClickAfter"></work-switch-tab>
        </div>
        <!-- 未批改 -->
        <div class=""
             v-if="showNotCorrect">
          <student-not-correct-list :workStudentList="workStudentList"
                                    :scheduleCourseID="workDetailsInfo.CourseNameKey"></student-not-correct-list>
        </div>
        <!-- 未提交 -->
        <div class=""
             v-else-if="showNotSubmit">
          <student-not-submit-list :workStudentList="workStudentList"></student-not-submit-list>
        </div>
        <!-- 已批改 -->
        <div class=""
             v-else-if="showEndCorrect">
          <student-end-correct-list :workStudentList="workStudentList" :dataInfo="dataInfo"></student-end-correct-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetScheduleCourseStudentHomeWorkList, HQGetScheduleCourseStudentHomeWorkList, WEBVGetScheduleCourseStudentHomeWorkList } from '../../../../API/workbench';
import workCourseHeadBox from './common/work-course-head-box.vue';
import workSwitchTab from './common/work-switch-tab.vue';
import studentNotCorrectList from './student-not-correct-list/index.vue';
import studentNotSubmitList from './student-not-submit-list/index.vue';
import studentEndCorrectList from './student-end-correct-list/index.vue';
export default {
  components: {
    workCourseHeadBox,
    workSwitchTab,
    studentNotCorrectList,
    studentNotSubmitList,
    studentEndCorrectList
  },
  data () {
    return {
      courseId: '', // 课ID
      workDetailsInfo: {}, // 作业信息
      workStudentList: [],
      studentWorkType: 1,
      tabList: [{
        title: '未批改',
        type: 1,
        num: 0,
        isActive: true
      },
      {
        title: '未提交',
        type: 0,
        num: 0,
        isActive: false
      },
      {
        title: '已批改',
        type: 2,
        num: 0,
        isActive: false
      }
      ],
      IsAllSchool: (this.$route.name == 'indexView') ? 1 : 2	// 整型	可选		数据源：GET	模式 1-全校;2-普通
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          courseId: 0,
          workType: 1,
          notShowAll: false,  // 是否显示所有
          IsAllSchool: null
        };
      }
    }
  },
  created () {
    console.log('dataInfo111', this.dataInfo);
    this.courseId = this.dataInfo.courseId;
    this.TeacherKey = this.dataInfo.TeacherKey;
    this.SchoolKey = this.dataInfo.SchoolKey;
    this.studentWorkType = this.dataInfo.workType || 1;
    this.IsAllSchool = (this.dataInfo.IsAllSchool ? this.dataInfo.IsAllSchool : this.IsAllSchool);
    if (this.courseId) {
      this.getHomeWorkDetails(this.courseId);
    }
  },
  computed: {
    // 是否总店
    vIsHeadquarters () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) { // 总部key为6
        return true;
      } else {
        return false;
      }
    },
    vtabList () {
      console.log(this.dataInfo.notShowAll,'this.dataInfo.notShowAll')
      if (this.dataInfo.notShowAll) {
        let newArr = [];
        this.tabList.forEach(o => {
          if (o.type == 2) {
            o.isActive = true;
          } else {
            o.isActive = false;
          }
          if (o.type != 0) {
            newArr.push(o);
          }
        });
        return newArr;
      } else {
        return this.tabList;
      }
    },
    // 未批改
    showNotCorrect () {
      let locaIndex = this.vtabList.findIndex(o => {
        return o.type == 1;
      });
      return this.vtabList[locaIndex].isActive;
    },
    // 未提交
    showNotSubmit () {
      let locaIndex = this.vtabList.findIndex(o => {
        return o.type == 0;
      });
      if (locaIndex >= 0) {
        return this.vtabList[locaIndex].isActive;
      } else {
        return false;
      }
    },
    // 已批改
    showEndCorrect () {
      let locaIndex = this.vtabList.findIndex(o => {
        return o.type == 2;
      });
      return this.vtabList[locaIndex].isActive;
    }
  },
  mounted () {
    console.log('dataInfomounted', this.dataInfo);
    //  this.$dialog.register(this, ['workCorrectDetails','taskCorrectDetails']);
  },
  methods: {
    // 获取课内学生作业列表
    getHomeWorkDetails (courseId) {
      if (this.SchoolKey > 0) {
        if (this.vIsHeadquarters) {   // 是否总店
          HQGetScheduleCourseStudentHomeWorkList(courseId, this.$store.getters.browseMessageRole, this.TeacherKey, this.SchoolKey).then(res => {
            console.log(res.data, 'res.data');
            this.workDetailsInfo = res.data;
            // 默认选中未批改学生
            this.setTabList();
          }, err => {
            this.$customapi.showModal(err.msg);
          });
        } else {
          WEBVGetScheduleCourseStudentHomeWorkList(courseId, this.$store.getters.browseMessageRole, this.TeacherKey, this.SchoolKey).then(res => {
            console.log(res.data, 'res.data');
            this.workDetailsInfo = res.data;
            // 默认选中未批改学生
            this.setTabList();
          }, err => {
            this.$customapi.showModal(err.msg);
          });
        }
      } else {
        GetScheduleCourseStudentHomeWorkList(courseId, this.IsAllSchool).then(res => {
          console.log(res.data, 'res.data');
          this.workDetailsInfo = res.data;
          // 默认选中未批改学生
          this.setTabList();
        }, err => {
          this.$customapi.showModal(err.msg);
        });
      }
    },
    setTabList () {
      if (this.workDetailsInfo.StudentList) {
        this.workStudentList = this.workDetailsInfo.StudentList.filter(obj => {
          return obj.TasksStatus == this.studentWorkType;
        });
      }

      // 根据作业状态分组（作业状态：0-未提交；1-未批改；2-已批改）

      this.tabList[1].num = this.workDetailsInfo.StudentList.filter(obj => {
        return obj.TasksStatus == 0;
      }).length;
      this.tabList[0].num = this.workDetailsInfo.StudentList.filter(obj => {
        return obj.TasksStatus == 1;
      }).length;
      this.tabList[2].num = this.workDetailsInfo.StudentList.filter(obj => {
        return obj.TasksStatus == 2;
      }).length;
    },
    // 切换批改作业类型
    tabClickAfter (item) {
      this.studentWorkType = item.type;
      if (this.workDetailsInfo.StudentList) {
        this.workStudentList = this.workDetailsInfo.StudentList.filter(obj => {
          return obj.TasksStatus == this.studentWorkType;
        });
      }
      console.log(this.workStudentList, '切换批改作业类型');
      this.vtabList.forEach(o => {
        if (item.type == o.type) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
    }
  }
};
</script>

<style>
</style>
