<template>
  <div>
    <div class="course_statistics_table_search flex course_statistics_title">
      <div class="">
        <div class="">
          <date-range ref="monthPicker"
                      :fromTitle="'选择日期'"
                      :searchObj="searchObj"
                      :minDate="vMinDate"
                      :maxDate="vMaxDate"
                      :isShowHistory="isShowHistory"
                      @changeDate="changeSaveDate"></date-range>
        </div>
        <div class="change_history_data"
             @click.stop="historyBtn(historyName)">{{ historyName }}</div>
      </div>
      <div class="course_statistics_search">
        <div></div>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list class="summarizing_list"
                  :tableData="vdataReportList"
                  :tableColumns="tableCourseColumns"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :queryParams="searchObj"
                  :footerContent="vFooterTotal"
                  @loadTableData="getDataList"></table-list>
    </div>
    <custom-dialog :title="'日销明细'"
                   width="972px"
                   :visible.sync="isShowDialog"
                   :before-close="cancelDetail">
      <semester-detail-report :dayInfo="selectedDayInfo"></semester-detail-report>
    </custom-dialog>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
import semesterDetailReport from '../components/semester-detail-report';

import { MessageSearchClassAwayForTermCard, MessageSearchClassAwayForHistoryTermCard } from '../../../../API/workbench';
export default {
  data () {
    return {
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      isShowDialog: false,
      selectedDayInfo: {},
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: '',
        endTime: '',
        IsExportKey: 0,
        IncidentalsKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      PageCount: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '上课时间',
          prop: 'SetUpTime',
          minWidth: 245,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '持卡人数',
          prop: 'StudentCount',
          minWidth: 286,
          sortable: 'custom',
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '学期卡数',
          prop: 'TermCardCount',
          minWidth: 284,
          sortable: 'custom',
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '日消金额',
          prop: 'DeductAmount',
          minWidth: 271,
          sortable: 'custom',
          align: 'left',
          // type: 'semaforoNumber',
          type: 'textItem',
          extend: {
            setClassName: (rowData) => {
              if (rowData.DeductAmount) {
                return Number(rowData.DeductAmount.replace(/[+]/g, '') || 0) > 0 ? '' : 'font_red';
              } else {
                return 'font_red';
              }
            }
          }
        },
        {
          label: '操作',
          prop: '',
          width: '80',
          sortable: false,
          align: 'center',
          type: 'tableBtn',
          extend: {
            tableBtnGroup: [
              {
                name: '明细',
                extend: {
                  isHide: false,
                  click: (rowData) => {
                    this.doAfterTableBtnClick(rowData);
                  }
                }

              }
            ]
          }
        }
      ],
      dataReportList: []
    };
  },
  components: {
    tableList,
    semesterDetailReport
  },
  props: {
    toSearchObj: {
      type: Object
    }
  },
  created () {
    console.log(this.toSearchObj, 'this.toSearchObj');
    if (this.toSearchObj.startTime) {
      this.searchObj.startTime = this.toSearchObj.startTime;
      this.searchObj.endTime = this.toSearchObj.endTime;
    } else {
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
    }
    if (new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31' >= this.toSearchObj.startTime) {
      this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      this.isShowHistory = true;
    } else {
      this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      this.isShowHistory = false;
    }
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.CourseNameKey = '0';
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      if (this.totalAmount > 0) {
        text = text + ',合计+' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      } else {
        text = text + ',合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      }
      return text;
    }
  },
  mounted () {
    this.$bus.on(this, 'wsAttendanceOrArrange', (data) => {
      console.log('触发wsAttendanceOrArrange');
      this.getDataList();
    });
    this.getDataList();
  },
  methods: {
    historyBtn (name) {
      console.log(this.isShowHistory, 'isShowHistory');
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.searchObj.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.searchObj.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isShowHistory = true;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
        this.isShowHistory = false;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.getDataList();
      this.$refs.monthPicker.changeHistoryTime(this.searchObj.startTime, this.searchObj.endTime);
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.search();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.IsExportKey = 0;
      this.searchObj.IncidentalsKey = 0;
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.getDataList();
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (item, date) {
      console.log('searchData', date);
      this.searchObj.TeacherKey = item.TeacherKey;
      this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(date).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      let seletedYearMonth = Date.new(date).getFullYear() + ' ' + (Date.new(date).getMonth() + 1);
      let nowYearMonth = Date.new().getFullYear() + ' ' + (Date.new().getMonth() + 1);
      if (seletedYearMonth != nowYearMonth) {
        this.searchObj.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new(date)), 'yyyy-MM-dd');
      } else {
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
      }
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = '';
        if (this.isShowHistory) {
          fn = MessageSearchClassAwayForHistoryTermCard;
        } else {
          fn = MessageSearchClassAwayForTermCard;
        }
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '年月日卡扣费',
          clist: [
            { title: '上课时间', cName: 'SetUpTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '持卡人数', cName: 'StudentCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学期卡数', cName: 'TermCardCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '日消金额', cName: 'DeductAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      let fn = '';
      if (this.isShowHistory) {
        fn = MessageSearchClassAwayForHistoryTermCard;
      } else {
        fn = MessageSearchClassAwayForTermCard;
      }
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj);
      fn(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    cancelDetail () {
      this.isShowDialog = false;
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      rowData.isShowHistory = this.isShowHistory;
      this.selectedDayInfo = rowData;
      this.isShowDialog = true;
    }
  }
};
</script>
<style scoped>
.change_history_data {
  position: absolute;
  right: 150px;
  margin-top: -36px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
</style>