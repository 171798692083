<template>
  <div class="class_detail_content">
    <courseware-list v-if="coursewareList.length > 0"
                     :dataList="coursewareList"
                     @seletedItem="seletedItem"></courseware-list>
    <div class="
        course_block_nodata
        content_marginTop_30 content_marginRL
        single_shadow
        btn_marginBottom_30
      "
         v-else>
      <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
    </div>
    <div class="particulars"
         v-if="seletedInfo && coursewareList.length > 0">
      <particularHeader :role="vRoleKey"
                        @getCoursewarePackageDetails="updateData"
                        :CoursewarePackage="seletedInfo"
                        @cancelAboutCW="cancelAboutCW"></particularHeader>
      <!-- 底部 -->
      <div style="margin-bottom: 30px"
           v-if="childCWList.length > 0 && maxCoursewarePackage">
        <particularList @getCoursewarePackageDetails="updateData"
                        @updateParentData="getCoursewarePackageDetails"
                        :CoursewarePackage="seletedInfo"
                        :maxCoursewarePackage="maxCoursewarePackage"
                        v-for="(item, index) in childCWList"
                        :dataInfo="dataInfo"
                        :key="index"
                        :item="item"></particularList>
      </div>

      <div class="
          course_block_nodata
          content_marginTop_30 content_marginRL
          single_shadow
          btn_marginBottom_30
        "
           style="height: 720px"
           v-else>
        <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
      </div>
    </div>
    <div class="add_courseware_bag_btn"
         @click.stop="showAddCW">
      选择其他课件选班上课
    </div>
  </div>
</template>
<script>
import {
  CWSgetCoursewarePackageForGrade,
  cwsGetCoursewarelist,
  CWScancelRelevanceCoursewareForGrade,
  CWSrelevanceCoursewareForGrade,
  PrincipalgetCoursewarePackageForGrade,
  PrincipalcancelRelevanceCoursewareForGrade,
  PrincipalGetCoursewarelist,
  PrincipalrelevanceCoursewareForGrade,
  CWSGetCoursewarePackageDetails,
  CWSTeacherWorkbenchGetCoursewarePackageDetails,
  CWSTeacherGiveLectureSetUpScheduleCourse
} from '../../../../API/workbench';
import coursewareList from './components/courseware-list';
import particularHeader from './components/particularHeader';
import particularList from './components/particularList';
export default {
  data () {
    return {
      coursewareList: [
        // {
        //     BigCoursewarePackageKey:'1111',//	整型	大课包key
        //     BigCoursewarePackageKeyValue:'大课包keyValue',//	字符串	大课包keyValue
        //     CoursewarePackageKey:'40563',//	整型	子课件包key
        //     MainDemoName:'子课件包keyValue',//	整型
        //     CoursewarePackageKeyValue:'子课件包keyValue',// 子课件包keyValue
        //     CoverUrl:'/Upload/Resource/1052/Image/202204181042401601.png',//	整型	大课件包封面
        //     DeviceIsAuth:'1',//	整型 设备是否授权:'',// 0-否;1-是
        //     isActive:true
        // },
        //  {
        //     BigCoursewarePackageKey:'2222',//	整型	大课包key
        //     BigCoursewarePackageKeyValue:'大课包keyValue',//	字符串	大课包keyValue
        //     CoursewarePackageKey:'40563',//	整型	子课件包key
        //     MainDemoName:'子课件包keyValue',//	整型
        //     CoursewarePackageKeyValue:'子课件包keyValue',// 子课件包keyValue
        //     CoverUrl:'/Upload/Resource/1052/Image/202204181042401601.png',//	整型	大课件包封面
        //     DeviceIsAuth:'0',//	整型 设备是否授权:'',// 0-否;1-是
        //     isActive:false
        // }
      ],
      childCWList: [],
      seletedInfo: null,
      maxCoursewarePackage: null,
      openClassData: {},
      IsContinueKey: 0,
      IsAuthKey: 0,
      busResult: null
    };
  },
  components: {
    coursewareList,
    particularHeader,
    particularList
  },
  props: {
    dataInfo: Object,
    isTeacher: Boolean,
    DeviceCode: [Number, String]
  },
  created () { },
  computed: {
    vToken () {
      return this.$store.getters.token;
    },
    vRoleKey () {
      return Number(this.vToken.RoleKey) == 2; // 2为校长；非则为老师
    }
  },
  methods: {
    showAddCW () {
      if (this.$zxClient && this.$zxClient.IsClient) {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '选择课件关联',
          moduleName: 'cwsChooseCourseware',
          data: { selectedKey: 0 },
          callBack: {
            afterSuccess: (data) => {
              console.log(data, 'afterSuccess');
              this.getCoursewarePackageDetails(
                data.ParentKey,
                this.DeviceCode,
                '',
                () => {
                  this.$dialog.open(this, {
                    name: '开课',
                    routerName: this.$route.name,
                    ModulePowerKey: '',
                    moduleName: 'cwsGoToClass',
                    data: {
                      dataInfo: {
                        item: data,
                        GradeKey: this.dataInfo.OLAPKey, // 班级key
                        GradeKeyValue: this.dataInfo.MainDemoName,
                        PCIsLocationLockKey:
                          this.maxCoursewarePackage.PCIsLocationLockKey,
                        Longitude: this.$store.getters.geoInfo.Longitude,
                        Latitude: this.$store.getters.geoInfo.Latitude,
                        HQAuthKey: this.maxCoursewarePackage.HQAuthKey
                      }
                    },
                    callBack: {
                      afterSuccess: (data) => {
                        this.getDataList(
                          this.dataInfo.OLAPKey,
                          this.DeviceCode
                        );
                      }
                    }
                  });
                }
              );

              // this.addCWforClass(data);
            }
          }
        });
      } else {
        layer.alert('请用桌面端打开程序');
      }
    },
    addCWforClass (data) {
      console.log(data, 'addCWforClass');
      let additem = {};
      if (data.length > 0) {
        additem = data[0];
      }
      if (this.vRoleKey) {
        PrincipalrelevanceCoursewareForGrade(
          this.dataInfo.OLAPKey,
          additem.OLAPKey
        )
          .then((result) => {
            console.log('result', result);
            this.getDataList(this.dataInfo.OLAPKey, this.DeviceCode);
          })
          .catch((error) => {
            layer.alert(error.msg);
          });
      } else {
        CWSrelevanceCoursewareForGrade(this.dataInfo.OLAPKey, additem.OLAPKey)
          .then((result) => {
            console.log('result', result);
            this.getDataList(this.dataInfo.OLAPKey, this.DeviceCode);
          })
          .catch((error) => {
            layer.alert(error.msg);
          });
      }
    },
    // 取消课包关联
    cancelAboutCW () {
      layer.confirm('是否取消该课包关联', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              // 点击确定调用接口
              console.log(this.dataInfo, 'this.seletedInfo', this.seletedInfo);
              if (this.vRoleKey) {
                PrincipalcancelRelevanceCoursewareForGrade(
                  this.dataInfo.OLAPKey,
                  this.seletedInfo.CoursewarePackageKey
                )
                  .then((result) => {
                    this.getDataList(this.dataInfo.OLAPKey, this.DeviceCode);
                  })
                  .catch((error) => {
                    layer.alert(error.msg);
                  });
              } else {
                CWScancelRelevanceCoursewareForGrade(
                  this.dataInfo.OLAPKey,
                  this.seletedInfo.CoursewarePackageKey
                )
                  .then((result) => {
                    this.getDataList(this.dataInfo.OLAPKey, this.DeviceCode);
                  })
                  .catch((error) => {
                    layer.alert(error.msg);
                  });
              }
              // 取消事件
              layer.close();
            }
          }
        ]
      });
    },
    updateData () {
      this.getDataList(this.dataInfo.OLAPKey, this.DeviceCode);
      // this.getChildCWList(this.seletedInfo.CoursewarePackageKey)
    },
    cutCourseware (data) {
      console.log(data, '切换后的子课件');
    },
    getDataList (GradeKey, DeviceCode) {
      console.log('CWSgetCoursewarePackageForGrade', this.vRoleKey);
      if (this.vRoleKey) {
        PrincipalgetCoursewarePackageForGrade(GradeKey, DeviceCode)
          .then((result) => {
            result.data.forEach((o) => {
              o.isActive = false;
            });
            this.coursewareList = result.data;
            if (this.coursewareList.length > 0) {
              if (this.seletedInfo) {
                let locaIndex = this.coursewareList.findIndex((o) => {
                  return (
                    o.CoursewarePackageKey ==
                    this.seletedInfo.CoursewarePackageKey
                  );
                });
                if (locaIndex >= 0) {
                  this.coursewareList[locaIndex].isActive = true;
                  this.seletedItem(this.coursewareList[locaIndex]);
                } else {
                  this.coursewareList[0].isActive = true;
                  this.seletedItem(this.coursewareList[0]);
                }
              } else {
                this.coursewareList[0].isActive = true;
                this.seletedItem(this.coursewareList[0]);
              }
            }
            console.log('getDataList', this.coursewareList);
          })
          .catch((error) => {
            console.log(error.msg);
          });
      } else {
        CWSgetCoursewarePackageForGrade(GradeKey, DeviceCode)
          .then((result) => {
            result.data.forEach((o) => {
              o.isActive = false;
            });
            this.coursewareList = result.data;
            if (this.coursewareList.length > 0) {
              if (this.seletedInfo) {
                let locaIndex = this.coursewareList.findIndex((o) => {
                  return (
                    o.CoursewarePackageKey ==
                    this.seletedInfo.CoursewarePackageKey
                  );
                });
                if (locaIndex >= 0) {
                  this.coursewareList[locaIndex].isActive = true;
                  this.seletedItem(this.coursewareList[locaIndex]);
                } else {
                  this.coursewareList[0].isActive = true;
                  this.seletedItem(this.coursewareList[0]);
                }
              } else {
                this.coursewareList[0].isActive = true;
                this.seletedItem(this.coursewareList[0]);
              }
            }
            console.log('getDataList', this.coursewareList);
          })
          .catch((error) => {
            console.log(error.msg);
          });
      }
    },
    getChildCWList (CoursewarePackageKey) {
      if (this.vRoleKey) {
        PrincipalGetCoursewarelist(CoursewarePackageKey, this.dataInfo.OLAPKey)
          .then((result) => {
            console.log('CWSGetCoursewareForGrade', result.data);
            this.childCWList = result.data;
          })
          .catch((error) => {
            console.log(error.msg);
          });
      } else {
        cwsGetCoursewarelist(CoursewarePackageKey, this.dataInfo.OLAPKey)
          .then((result) => {
            console.log('CWSGetCoursewareForGrade', result.data);
            this.childCWList = result.data;
          })
          .catch((error) => {
            console.log(error.msg);
          });
      }
      this.getCoursewarePackageDetails(CoursewarePackageKey, this.DeviceCode);
    },
    // 获取子课件包详情
    getCoursewarePackageDetails (OLAPKey, deviceCode, TeacherID, callback) {
      if (this.vRoleKey) {
        CWSGetCoursewarePackageDetails(OLAPKey, deviceCode, TeacherID).then(
          (result) => {
            this.maxCoursewarePackage = result.data;
            let geoInfo = {
              Longitude: result.data.Longitude || '',
              Latitude: result.data.Latitude || '',
              Accuracy: result.data.Accuracy || '',
              PositioningOffset: result.data.PositioningOffset || ''
            };
            this.$store.commit('setGeoInfo', geoInfo);
            if (callback) {
              callback();
            }
            console.log('校长-获取子课件包详情index页面', result.data);
          }
        );
      } else {
        CWSTeacherWorkbenchGetCoursewarePackageDetails(
          OLAPKey,
          deviceCode,
          TeacherID
        ).then((result) => {
          this.maxCoursewarePackage = result.data;
          let geoInfo = {
            Longitude: result.data.Longitude || '',
            Latitude: result.data.Latitude || '',
            Accuracy: result.data.Accuracy || '',
            PositioningOffset: result.data.PositioningOffset || ''
          };
          this.$store.commit('setGeoInfo', geoInfo);
          if (callback) {
            callback();
          }
          console.log('老师-获取子课件包详情index页面', result.data);
        });
      }
    },
    seletedItem (item) {
      this.seletedInfo = item;
      console.log('seletedInfo', this.seletedInfo);
      this.getChildCWList(this.seletedInfo.CoursewarePackageKey);
    },
    isAuthCourseware () {
      layer.confirm(
        '您还没有该课包的授权，如果继续，将会自动将该课包授权给您，是否继续?',
        {
          btn: [
            {
              name: '取消',
              callBack: function () {
                this.IsAuthKey = 0;
                layer.close();
              }
            },
            {
              name: '确认',
              callBack: () => {
                this.IsAuthKey = 1; // 是否自动授权
                CWSTeacherGiveLectureSetUpScheduleCourse(
                  this.openClassData,
                  this.IsContinueKey,
                  this.IsAuthKey
                ).then((result) => {
                  if (result.data.ScheduleCourseKey) {
                    this.$router.push({
                      name: 'coursewareTeachingView',
                      query: {
                        type: 1,
                        coursewareId: result.data.ScheduleCourseKey,
                        Accuracy: this.maxCoursewarePackage.Accuracy,
                        IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
                      }
                    });
                  } else {
                    layer.alert(error.msg);
                  }
                });
                layer.close();
              }
            }
          ]
        }
      );
    },
    regisBus () {
      this.busResult = (res) => {
        this.openClassData.Longitude = res.data.Longitude;
        this.openClassData.Latitude = res.data.Latitude;
        console.log('this.openClassData', this.openClassData);
        CWSTeacherGiveLectureSetUpScheduleCourse(
          this.openClassData,
          this.IsContinueKey,
          this.IsAuthKey
        )
          .then((result) => {
            console.log(result, '上课返回数据');
            // this.$emit("afterSuccess");
            // this.$emit("closeDialog");
            if (result.data.ScheduleCourseKey) {
              this.$router.push({
                name: 'coursewareTeachingView',
                query: {
                  type: 1,
                  scheduleCourseID: result.data.ScheduleCourseKey,
                  Accuracy: this.maxCoursewarePackage.Accuracy,
                  IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
                }
              });
            } else {
              console.log(123456456);
              layer.alert(error.msg);
            }
          })
          .catch((error) => {
            console.log(123456456);
            if (error.code == 80001) {
              this.isAuthCourseware();
            } else if (error.code == 80002) {
              layer.confirm(error.msg, {
                btn: [
                  {
                    name: '取消',
                    callBack: function () {
                      this.IsContinueKey = 0;
                      layer.close();
                    }
                  },
                  {
                    name: '确认',
                    callBack: () => {
                      this.IsContinueKey = 1;
                      CWSTeacherGiveLectureSetUpScheduleCourse(
                        this.openClassData,
                        this.IsContinueKey,
                        this.IsAuthKey
                      )
                        .then((res) => {
                          if (res.data.ScheduleCourseKey) {
                            this.$router.push({
                              name: 'coursewareTeachingView',
                              query: {
                                type: 1,
                                scheduleCourseID: res.data.ScheduleCourseKey,
                                Accuracy: this.maxCoursewarePackage.Accuracy,
                                IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
                              }
                            });
                          } else {
                            layer.alert(error.msg);
                          }
                        })
                        .catch((error) => {
                          if (error.code == 80001) {
                            this.isAuthCourseware();
                          } else {
                            layer.alert(error.msg);
                          }
                        });
                      layer.close();
                    }
                  }
                ]
              });
            } else {
              layer.alert(error.msg);
            }
          });

        this.$bus.off(this, 'scanToAuthCourseware', this.busResult);
      };
      this.$bus.on(this, 'scanToAuthCourseware', this.busResult);
    }
  }
};
</script>
<style scoped>
.class_detail_content {
  width: 100%;
  /* height: 837px; */
  padding: 0 40px;
}
.particulars {
  /* margin-left: 15px; */
  /* overflow-y: auto;height: 720px; */
}
/* .add_courseware_btn{
    width: 100%;
    text-align: center;
    height: 34px;
    line-height: 34px;
    background: #d8eaf5;
} */
.class_detail_content .add_courseware_bag_btn {
  width: 915px;
  height: 46px;
  border-radius: 4px;
  background: #f1f7ff;
  text-align: center;
  line-height: 46px;
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
}
.class_detail_content .add_courseware_bag_btn::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  left: 362px;
  top: 12px;
  background: url(../../../../../public/image/add_icon_blue.png) no-repeat 100%;
  background-size: 19px;
}
</style>