<template>
  <div class="title_box"
       :class="{className,data_null_box:!isClassDataNull}"
       :style="this.$store.getters.token.RoleKey != 2? 'margin-left:0px;':'margin-top: -15px;'">
    <!-- <head-class-list :teacherData="teacherData"
                     :classList="classList"
                     :isTeacherInto="isTeacherInto"
                     :className="className"
                     @getClassDetail="getClassDetail"
                     @quitClass="quitClass"
                     @addClassForTeacher="addClassForTeacher"
                     @addClassPackageList="cwsTeacherAuthList"></head-class-list> -->
    <!-- <span v-if="isClassDataNull"
          class="title_text">授权课包</span> -->
    <div v-if="isClassDataNull">
      <package-class-head v-for="(item, key) in classData"
                          :key="key"
                          :item="item"
                          :isTeacherInto="isTeacherInto"
                          :isShowCourseware="false"
                          :TeacherKey="tyKey"
                          :TeacherName="teacherData.MainDemoName"
                          :packgeData="packgeData"
                          :classData="item"
                          :className="className"
                          @openTeacherAuthForm="openTeacherAuthForm"
                          @goAuthor="goAuthor"
                          @goAuthorOfTeacher="goAuthorOfTeacher"
                          @flashData="flashData"></package-class-head>
    </div>

    <div v-if="!isClassDataNull"
         style="height: 100%;">
      <div class="cw_content_nodata_txt"
           :style="this.$store.getters.token.RoleKey != 2&&!isClassDataNull?'width: 962px;height: 537px;padding-top: 300px!important;background-position-y: 135px;border-radius: 7px;':''">
        <div>还没有授权课件哦~请联系管理员授权课件~</div>
      </div>
    </div>
    <!-- <div v-if="isIsClient">
      <div class="right_nodata_type03">请用桌面端打开程序</div>
    </div> -->
    <custom-dialog title="选择班级"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isClasseGradesDialog"
                   :before-close="closeCourseBatchDelayDialog">
      <btn-add-select :addTitleName="'新增'"
                      :addmoduleName="'cws-add-class'"
                      :addmoduleTitleName="'add'"
                      :addmoduleKey="0"
                      @doAddSuccessEvent="doAfterSelectedClassGrade"></btn-add-select>
      <div class="single_result_detail form_info_detail">
        <classe-grades @submit="addClassForTeacherSubmit"
                       @closeDialog="closeCourseBatchDelayDialog"></classe-grades>
      </div>
    </custom-dialog>
    <custom-dialog :title="'选择课包'"
                   :visible.sync="ceshi"
                   :before-close="closeDialog">
      <cws-choose-courseware @afterSuccess="updateData"
                             @cancelClick="closeDialog"></cws-choose-courseware>
    </custom-dialog>

    <!-- <custom-dialog title="授权课包"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isClassPackageList"
                   :before-close="closeClassPackageListDialog">
      <class-package-list 
                       @submit="addClassForTeacherSubmit"
                       @closeDialog="closeClassPackageListDialog"></class-package-list>
    </custom-dialog> -->
  </div>
</template>
<script>
import classeGrades from '../../components/report/classe-grades/index';
import classPackageList from '../../components/report/class-package-list/index';
import HeadClassList from './head-class-list.vue';
import BodyClassDetail from './body-class-detail.vue';
import PackageClassHead from './package-class-head.vue';
import cwsChooseCourseware from '../../components/choose-courseware/index';
import {
  GetAppointTeacherDetails,
  PrincipalGetAuthBigCoursewarePackageForTeacher,
  GetClassGradeForTeacher,
  cwsAddClassGradeForTeacher,
  AddClassGradeForTeacher,
  CWSAuthorizedDeviceManagementAuthorizedTheDevice,
  GetTeacherWhereClass,
  GetAuthBigCoursewarePackageForTeacher,
  PrincipalquitClassGradeForTeacher,
  CWSquitClassGradeForTeacher,
  AddInterfaceLog
} from '../../../../API/workbench';
import itemVue from '../../../chain-work-bench/warning-list/item.vue';
export default {
  components: {
    HeadClassList,
    BodyClassDetail,
    PackageClassHead,
    classeGrades,
    classPackageList,
    cwsChooseCourseware
  },
  props: {
    teacherKey: {
      default: 0,
      type: [Number, String]
    },
    className: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      choosekey: 0,
      isIsClient: false,
      ceshi: false,
      teacherData: [],
      classData: [],
      tipContont: '',
      mCode: '',
      isClassDataNull: true,
      packgeData: {},
      classList: [],
      isClasseGradesDialog: false,
      isClassPackageList: false,
      classDataforChoose: [],
      tyKey: 0,
      isTeacherInto: false
    };
  },

  created () {
    this.tyKey = this.teacherKey;
    if (this.teacherKey == 0) {
      this.isTeacherInto = true;
      this.tyKey = this.$store.getters.token.UserID;
    }
  },
  watch: {
    teacherKey: {
      handler (c, o) {
        if (c) {
          this.tyKey = this.teacherKey;
          if (this.teacherKey == 0) {
            this.tyKey = this.$store.getters.token.UserID;
          }
          this.flashData();
        }
      }
    }
  },
  mounted () {
    this.flashData();
  },
  methods: {
    flashData () {
      this.loadTeacher();
      this.loadCoursewarePackage();
      this.loadClassListForTeacher();
    },
    // 授权课包弹出
    cwsTeacherAuthList () {
      this.$dialog.open(this, {
        name: '授权课包',
        routerName: this.$route.name,
        moduleName: 'cwsTeacherAuthCw',
        routertitle: '工作台',
        data: {
          dataInfo: {
            TeacherKey: this.tyKey,
            TeacherKeyValue: this.teacherData.MainDemoName
          }
        },
        callBack: {
          afterSuccess: (data) => {
            this.flashData();
          }
        }
      });
    },
    // 弹出设备授权
    goAuthor () {
      if (this.$zxClient) {
        if (this.$zxClient.GetMInfo() === '{}') {
          layer.alert('设备信息为空');
          let interfaceLogData = {
            InterfaceName: this.$store.getters.APIURL + 'CWS.AuthorizedDeviceManagement.AuthorizedTheDevice',
            parameter: { BigCoursewarePackageKey: this.classDataforChoose.BigCoursewarePackageKey },
            PostData: JSON.stringify(this.$store.getters.token),
            LogContents: '设备信息为空',
            LogTitleName: '设备信息为空',
            RefererName: window.location.href
          };
          AddInterfaceLog(interfaceLogData).then(result => {
            console.log('记录日志');
          });
        } else {
          let DeviceInfo = JSON.parse(this.$zxClient.GetMInfo());
          CWSAuthorizedDeviceManagementAuthorizedTheDevice(
            DeviceInfo,
            this.classDataforChoose.BigCoursewarePackageKey
          ).then(
            (res) => {
              this.classData.forEach((o) => {
                if (
                  o.BigCoursewarePackageKey ==
                  this.classDataforChoose.BigCoursewarePackageKeyValue
                ) {
                  this.classDataforChoose.DeviceIsAuth = 1;
                  o.DeviceIsAuth = 1;
                }
              });
              layer.alert('授权成功');
              // this.flashData()
            },
            (err) => {
              layer.alert(err.msg);
            }
          );
        }
      }
    },
    goAuthorOfTeacher (classData) {
      if (this.$zxClient.IsClient) {
        var DeviceCode = this.$zxClient.GetMCode();
      } else {
        layer.alert('请用客户端操作');
        return;
      }
      // var DeviceCode = '340BC40DC769411B'
      this.$dialog.open(this, {
        name: '申请设备授权',
        routerName: this.$route.name,
        routertitle: '工作台',
        ModulePowerKey: '',
        moduleName: 'cwsApplyAuth',
        data: {
          dataInfo: {
            CoursewareKey: classData.BigCoursewarePackageKey,
            DeviceCode: DeviceCode
          }
        },
        callBack: {
          afterSuccess: (data) => { this.flashData(); }
        }
      });
      // this.$dialog.open(this, {
      //     name: '设备授权',
      //     routerName: this.$route.name,
      //     moduleName: 'cwsTeacherAuthList',
      //     routertitle: '工作台',
      //     data: {dataInfo:{
      //     isTeacher: this.isTeacherInto,
      //     // EndTime: this.params.EndTime,
      //     BigCoursewarePackageKey: classData.BigCoursewarePackageKey,
      //     BigCoursewarePackageKeyValue: classData.BigCoursewarePackageKeyValue
      //     }},
      //      callBack: {
      //           afterSuccess: (data) => {this.flashData() }
      //         }
      //   });
    },
    updateData (data) {
      this.ceshi = false;
    },
    closeDialog () {
      this.ceshi = false;
    },
    ceshiDialog () {
      this.ceshi = true;
    },
    doAfterSelectedClassGrade (item) {
      this.isClasseGradesDialog = false;
      this.addClassForTeacherSubmit(item);
      // this.loadClassListForTeacher()
    },
    // 老师信息
    loadTeacher () {
      GetAppointTeacherDetails(this.tyKey).then(
        (res) => {
          this.teacherData = res.data;
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    // 班级listfor老师
    loadClassListForTeacher () {
      if (this.isTeacherInto) {
        GetTeacherWhereClass(this.tyKey).then(
          (res) => {
            this.classList = res.data;
          },
          (err) => {
            layer.alert(err.msg);
          }
        );
      } else {
        GetClassGradeForTeacher(this.tyKey).then(
          (res) => {
            this.classList = res.data;
          },
          (err) => {
            layer.alert(err.msg);
          }
        );
      }
    },
    // 获取课件包
    loadCoursewarePackage () {
      // this.mCode = '340BC40DC769411B';
      if (this.$zxClient.IsClient) {
        this.mCode = this.$zxClient.GetMCode();
      } else {
        this.mCode = '';
      }
      if (this.isTeacherInto) {
        GetAuthBigCoursewarePackageForTeacher(this.tyKey, this.mCode).then(
          (res) => {
            this.classData = res.data;
            if (this.classData.length == 0) {
              this.isClassDataNull = false;
            } else {
              this.isClassDataNull = true;
              this.classData.forEach((o, index) => {
                o.BigCoursewarePackageKey = o.OLAPKey;
                o.BigCoursewarePackageKeyValue = o.MainDemoName;
                if (index == 0) {
                  this.choosekey = o.BigCoursewarePackageKey;
                }
              });
            }
          },
          (err) => {
            layer.alert(err.msg);
          }
        );
      } else {
        PrincipalGetAuthBigCoursewarePackageForTeacher(
          this.tyKey,
          this.mCode
        ).then(
          (res) => {
            this.classData = res.data;
            if (this.classData.length == 0) {
              this.isClassDataNull = false;
            } else {
              this.isClassDataNull = true;
              this.classData.forEach((o, index) => {
                o.BigCoursewarePackageKey = o.OLAPKey;
                o.BigCoursewarePackageKeyValue = o.MainDemoName;
                if (index == 0) {
                  this.choosekey = o.BigCoursewarePackageKey;
                }
              });
            }
          },
          (err) => {
            layer.alert(err.msg);
          }
        );
      }
    },
    // 班级详情
    getClassDetail (item) {
      this.$dialog.open(this, {
        name: '班级详情',
        routerName: this.$route.name,
        routertitle: '工作台',
        ModulePowerKey: '',
        moduleName: 'cwsClassDetail',
        data: {
          isTeacher: this.isTeacherInto,
          classKey: item.GradeKey
        }
      });
    },
    // 退出班级
    quitClass (item) {
      if (this.isTeacherInto) {
        CWSquitClassGradeForTeacher(item.GradeKey, this.tyKey).then(
          (res) => {
            this.loadClassListForTeacher();
            this.$emit('afterSuccess');
          },
          (err) => {
            layer.alert(err.msg);
          }
        );
      } else {
        PrincipalquitClassGradeForTeacher(item.GradeKey, this.tyKey).then(
          (res) => {
            this.loadClassListForTeacher();
            this.$emit('afterSuccess');
          },
          (err) => {
            layer.alert(err.msg);
          }
        );
      }
    },
    // 打开班级弹窗
    addClassForTeacher () {
      this.isClasseGradesDialog = true;
    },
    // 关闭班级弹窗
    closeCourseBatchDelayDialog () {
      this.isClasseGradesDialog = false;
    },
    // 关闭授权课件弹窗
    closeClassPackageListDialog () {
      this.isClassPackageList = false;
    },
    // 打开班级弹窗
    addClassPackageList () {
      this.isClassPackageList = true;
    },
    // 添加班级提交
    addClassForTeacherSubmit (item) {
      console.log(item, '班级返回');
      if (this.isTeacherInto) {
        cwsAddClassGradeForTeacher(this.tyKey, item.OLAPKey).then(
          (res) => {
            layer.alert('添加成功');
            this.closeCourseBatchDelayDialog();
            this.loadClassListForTeacher();
            this.$emit('afterSuccess');
          },
          (err) => {
            layer.alert(err.msg);
          }
        );
      } else {
        AddClassGradeForTeacher(this.tyKey, item.OLAPKey).then(
          (res) => {
            layer.alert('添加成功');
            this.closeCourseBatchDelayDialog();
            this.loadClassListForTeacher();
            this.$emit('afterSuccess');
          },
          (err) => {
            layer.alert(err.msg);
          }
        );
      }
    },
    openTeacherAuthForm () {
      this.$dialog.open(this, {
        name: '授权老师',
        routerName: this.$route.name,
        routertitle: '工作台',
        ModulePowerKey: '',
        moduleName: 'cwsTeacherAuthCw',
        data: {
          dataInfo: {
            BigCoursewarePackageKey:
              this.classDataforChoose.BigCoursewarePackageKey,
            BigCoursewarePackageKeyValue:
              this.classDataforChoose.BigCoursewarePackageKeyValue,
            TeacherKey: this.tyKey,
            TeacherKeyValue: this.teacherData.MainDemoName
          }
        }
      });
    }
    // 授权课包提交
    // addSubmitCWPTeacherAuth(item){
    //   console.log('授权课包提交',item)
    //    SubmitCWPTeacherAuth(this.teacherKey,item).then(res => {
    //       console.log('添加班级',res)
    //       layer.alert('添加成功');
    //       this.closeCourseBatchDelayDialog()
    //       this.loadClassListForTeacher()
    //     }, err => {
    //       layer.alert(err.msg);
    //     });
    // }
  }
};
</script>
<style scoped>
.title_box {
  margin-top: 32px;
  margin-bottom: 32px;
}
.data_null_box {
  margin-top: 0px !important;
  margin-bottom: 0px;
  height: 100%;
}
.title_text {
  margin-top: 20px;
  height: 22px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: block;
  align-items: center;
  color: #333333;
}
.teacher_detail_dialog {
  /* width: 100%; */
  max-height: 800px;
  overflow: scroll;
}
.cw_content_nodata_txt {
  margin-top: 0 !important;
  /* height: 360px; */
  padding-top: 243px !important;
  background-position-y: 80px;
  height: 100%;
}
</style>
