<template>
  <div class="recharge_balance">
    <div v-if="showRechargeBalance">
      <div class="recharge_balance_title"
           v-if="putData.type == '1' || putData.type == '2'">
        <span class="recharge_balance_title_name">当前余额</span>
        <div class="recharge_balance_title_value">{{Number(vMendianInfo.CurrentAmount).toFixed(2)}}<span class="recharge_balance_title_bottom">&nbsp;元</span></div>
        <img :src="require('../../../../../../public/image/recharge-the-balance.png')"
             class="imgb" />

      </div>

      <div class="recharge_balance_title"
           v-if="putData.type == '3'">
        <span class="flow_title_name">当前流量</span>
        <div class="flow_title_value">{{this.$utils.flowFormate(this.fluxPackageList.CurrentFlow,'number') == 0?'--':this.$utils.flowFormate(this.fluxPackageList.CurrentFlow,'number')}}<span class="recharge_balance_title_bottom">&nbsp;{{this.$utils.flowFormate(this.fluxPackageList.CurrentFlow,'number') == 0 ?'':vCompany}}</span></div>
        <img :src="require('../../../../../../public/image/flow-logo.png')"
             class="imgb"
             style="right:0px" />
        <span class="flow_title_text">通用流量&nbsp;&nbsp;{{Number(this.fluxPackageList.GeneralFluxCount)==0?'--':this.$utils.flowFormate(this.fluxPackageList.GeneralFluxCount)}}&nbsp;&nbsp;<span v-if="!Number(this.fluxPackageList.GiveFluxCount) == 0&&!vIsHq">|&nbsp;&nbsp; 定向流量&nbsp;&nbsp;{{Number(this.fluxPackageList.GiveFluxCount)==0?'--':this.$utils.flowFormate(this.fluxPackageList.GiveFluxCount)}}</span></span>
      </div>
      <div class="recharge_balance_div"
           v-if="putData.type != '3' || vFluxComboList.length > 0">{{putData.title}}</div>

      <div class="recharge_balance_input_div"
           v-if="putData.type == '1' || putData.type == '2'">
        <div class="recharge_balance_small">￥</div>
        <!-- <input class="recharge_balance_input" type="number" min="0"  v-model="decemolNumber"  -->
        <input type="number"
               autocomplete="off"
               onclick="this.select()"
               onkeypress="return (/[\d.]/.test(String.fromCharCode(event.keyCode)))"
               onblur="this.value=Math.floor(this.value * 100)/100"
               @keyup="getValue"
               style="ime-mode: Disabled"
               class="recharge_balance_input"
               :placeholder="putData.placeholder"
               min="0"
               v-model="decemolNumber" />
        <div v-if="putData.type == '2'"
             class="all_in_withdrawal"
             @click.stop="allInWithdrawal">全部提现</div>
        <div v-if="putData.type == '2' && showAllIn"
             class="all_in_withdrawal_show"></div>
      </div>

      <div v-if="putData.type == '3' && vFluxComboList.length > 0"
           class="flow_box">
        <flowItem v-for="(item,index) in vFluxComboList"
                  :key="index"
                  :item="item"
                  :chooiceKey="chooiceKey"
                  @optionItem="optionItem"></flowItem>
      </div>
      <div class="recharge_balance_div"
           v-if="putData.type != '3' || vFluxComboList.length > 0">{{putData.scTitle}}</div>
      <div class="recharge_balance_play"
           v-if="putData.type == '1'">
        <div class="recharge_balance_play_list"
             @click.stop="showRedio =!showRedio">
          <img :src="require('../../../../../../public/image/wechat-pay-S.png')" />
          <span>微信支付</span>
          <div :class="showRedio?'recharge_balance_play_list_redio1':'recharge_balance_play_list_redio'"></div>
        </div>
      </div>

      <div class="recharge_balance_play"
           v-if="putData.type == '2'">
        <textarea type="text"
                  v-model="withdrawalText"
                  class="recharge_balance_input1 "
                  style="font-size : 16px;padding-left:10px"
                  placeholder="请输入提现原因" />
      </div>

      <div class="flow_choose_pay "
           :class="!vIsHq?'':'hqFlow_box'"
           v-if="putData.type == '3' && vFluxComboList.length > 0">
        <div class="flow_pay_item"
             :class="wechatPay?'option':''"
             @click.stop="payFlowChoose(1)">微信支付
          <img class="option_img"
               v-if="wechatPay"
               :src="require('../../../../../../public/image/flow-option.png')" />
        </div>

        <div class="flow_pay_item"
             v-if="!vIsHq"
             :class="currentAmountPay?'option':''"
             @click.stop="payFlowChoose(2)">余额支付（￥{{Number(vMendianInfo.CurrentAmount).toFixed(2)}}）
          <img class="option_img"
               v-if="currentAmountPay"
               :src="require('../../../../../../public/image/flow-option.png')" />
        </div>
      </div>
      <div class="wechatCurrentTips"
           v-if="wechatPay&&currentAmountPay">
        余额支付 ¥{{Number(vMendianInfo.CurrentAmount).toFixed(2)}}，微信支付 ¥{{(Number(chooiceItem.PriceAmount)-Number(vMendianInfo.CurrentAmount)).toFixed(2) }}
      </div>
      <div class="wechatTips"
           v-if="wechatPay">
        <img :src="require('../../../../../../public/image/wechat-pay-S.png')" />
        微信扫码，支付<span>{{this.payDataInfo.ActualColAmount}}</span> 元
      </div>
      <div class="sign_QRcode_img"
           v-if="wechatPay">
        <img :src="this.isLoading?this.$store.getters.APIURL + '//public/Util/qrcode.php?Content='+encodeURI(this.payDataInfo.Pay_data.code_url)+'&size=10':require('../../../../../../public/image/weixinjiaz.png')">
      </div>
      <div class="recharge_balance_bottom"
           v-if="showBottom">
        <div type="primary"
             class="button_right_box btn_hover_bg_blue btn_down_btn"
             @click="submitButtom">{{putData.buttomName}}</div>
      </div>
    </div>

    <div v-else>
      <payMoney :decemolNumber="vDecemoNumber"
                :dataInfo="dataInfo"
                :isShowCode="isShowCode"
                :type='witndrawalType'
                :successFlowData='successFlowData'
                :withdrawalText="withdrawalText"
                :isLoading="isLoading"
                @closeCompent="closeCompent"></payMoney>
    </div>
  </div>
</template> 

<script>
import {
  WebvcurrentAmountRecharge,
  CwsvcurrentAmountRecharge,
  WEBVwithdrawalOfBalance,
  CWSVwithdrawalOfBalance,
  HQgetFluxPackagelist,
  WEBVgetFluxPackagelist,
  CWSgetFluxPackagelist,
  HQGetFluxPackagePayCode,
  CWSGetFluxPackagePayCode,
  WEBVGetFluxPackagePayCode,
  CWSGetFluxPackageBalancePay,
  WEBVGetFluxPackageBalancePay,
  GetSaaSClientMsg
} from '../../../../../API/workbench';
import payMoney from './pay-money.vue';
import flowItem from './flow-item.vue';
export default {
  props: {
    putData: {
      type: Object,
      default: () => {
        return {
          type: '1',
          title: '充值金额',
          placeholder: '手动输入充值金额',
          buttomName: '立即充值',
          scTitle: '付款方式',
          isShowBottom: true
        };
      }
    }
  },
  data () {
    return {
      decemolNumber: '',
      showRedio: true,
      dataInfo: {},
      showRechargeBalance: true,
      showAllIn: false,
      withdrawalText: '',
      isShowCode: true,
      witndrawalType: '1',
      fluxPackageList: [],
      chooiceKey: '-1',
      showBottom: true,
      wechatPay: false,
      currentAmountPay: false,
      payDataInfo: [],
      isLoading: false,
      successFlowData: {},
      chooiceItem: {},
      MendianInfo: this.$store.getters.SaaSClientInfo

    };
  },
  computed: {
    vMendianInfo () {
      return this.MendianInfo;
    },
    vDecemoNumber () {
      return Number(this.decemolNumber).toFixed(2);
    },
    vCompany () {
      let str = this.$utils.flowFormate(this.fluxPackageList.CurrentFlow);
      str = str + '';
      return str.replace(/[^a-zA-Z]/g, '');
    },
    vIsZero (num) {
      num = Number(num);
      return num == 0 ? '--' : num;
    },
    vFluxComboList () {
      return this.fluxPackageList.FluxComboList || [];
    },
    vIsHq () {
      return this.vMendianInfo.EditionKey == 5;
    },
    vHasModulePower () {
      return this.$utils.CheckModulePower(
        this.vRolePowerList,
        143
      );
    },
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    }
  },
  created () {
    this.getSaaSClientMsgInfo();
    this.getFlowSendMessage();
    this.showBottom = this.putData.isShowBottom;
  },
  mounted () {
    if (this.putData.type == '3') {
      this.getFluxPackageList();
    }

    this.$dialog.register(this,
      [
        'verifyIdentityFlow'
      ]);
  },
  watch: {
    vDecemoNumber (n, o) {
      if (n) {
        if (Number(n) > 0) {
          this.showAllIn = true;
        } else {
          this.showAllIn = false;
        }
      }
    }
  },
  components: {
    payMoney,
    flowItem
  },
  methods: {
    // 返回输入值
    getValue (val) {
      let arr = [];
      // count为零时不取值
      if (this.decemolNumber != 0) {
        arr = this.decemolNumber.toString().split('.'); // 取小数点后面的值
      }
      // 判断是否取到值
      if (arr.length > 1) {
        // 当小数点后面的长度大于需要的长度时 返回
        if (arr[1].length > 2) {
          this.decemolNumber = Math.floor(this.decemolNumber * 100) / 100;
        }
      }
    },
    allInWithdrawal () {
      this.decemolNumber = Number(this.vMendianInfo.CurrentAmount).toFixed(2);
    },
    closeCompent (type) {
      // this.$emit('afterSuccess');
      this.$emit('close');
      this.decemolNumber = 0.00;
      this.showRechargeBalance = true;
      this.wechatPay = false;
      this.currentAmountPay = false;
      this.isLoading = false;
      this.chooiceKey = '-1';
      this.chooiceItem = {};
      this.getSaaSClientMsgInfo();
      if (type == '3') {
        this.getFluxPackageList();
        this.showBottom = false;
      }
    },
    submitButtom () {
      if (this.putData.type == '1') {
        this.rechargeBalance();
      }
      if (this.putData.type == '2') {
        this.Withdrawal();
      }
      if (this.putData.type == '3') {
        this.currentAmountPayAll();
      }
    },
    Withdrawal () {
      console.log(this.putData, 'this.putData');
      if (Number(this.decemolNumber) <= 0) {
        layer.alert('请输入提现金额!');
        return;
      }
      if (Number(this.decemolNumber) > Number(this.vMendianInfo.CurrentAmount)) {
        layer.alert('提现金额不能大于现有余额!');
        return;
      }
      // if (this.withdrawalText == '' || trim(this.withdrawalText.length) <= 0) {
      //   layer.alert('请输入提现原因!');
      //   return;
      // }
      let fn = null;
      if (this.vMendianInfo.EditionKey == 6) {
        console.log(1111);
        fn = CWSVwithdrawalOfBalance;
      } else if (this.vMendianInfo.EditionKey == 5) {
        console.log(22222);
        // fn= HQGetFluxPackageForSaaSClient
        layer.alert('总部正在全力开发中!');
        return;
      } else {
        fn = WEBVwithdrawalOfBalance;
      }
      fn(this.decemolNumber, this.withdrawalText, 1).then(result => {
        console.log(result, '余额提现');
        if (result.code == 0) {
          this.witndrawalType = '2';
          this.isShowCode = false;
          this.showRechargeBalance = false;
        }
        this.dataInfo = result.data;
        this.$emit('afterSuccess');
      }).catch(error => {
        layer.alert(error.msg);
        console.log(error, 'currentAmountRecharge');
      });
    },
    rechargeBalance () {
      console.log(this.putData, 'this.putData');
      if (!this.showRedio) {
        layer.alert('请确定付款方式!');
        return;
      }
      if (Number(this.decemolNumber) <= 0) {
        layer.alert('请输入充值金额!');
        return;
      }
      let fn = null;
      if (this.vMendianInfo.EditionKey == 6) {
        fn = CwsvcurrentAmountRecharge;
      } else if (this.vMendianInfo.EditionKey == 5) {
        // fn= HQGetFluxPackageForSaaSClient
        layer.alert('总部正在全力开发中!');
        return;
      } else {
        fn = WebvcurrentAmountRecharge;
      }
      this.showRechargeBalance = false;
      fn(1, this.decemolNumber).then(result => {
        console.log(result, '余额充值');
        if (result.code == 0) {
          this.isLoading = true;
          this.dataInfo = result.data;
        }
        this.$emit('afterSuccess');
        // this.$emit('afterSuccess');
      }).catch(error => {
        console.log(error, 'currentAmountRecharge');
      });
    },
    getFluxPackageList () {
      let fn = null;
      if (this.vMendianInfo.EditionKey == 6) {
        fn = CWSgetFluxPackagelist;
      } else if (this.vMendianInfo.EditionKey == 5) {
        fn = HQgetFluxPackagelist;
      } else {
        fn = WEBVgetFluxPackagelist;
      }
      fn().then(result => {
        console.log(result, '流量包获取');
        if (result.code == 0) {
          this.fluxPackageList = result.data;
        }
      }).catch(error => {
        console.log(error, 'currentAmountRecharge');
      });
    },
    optionItem (item) {
      this.fluxPackageList.FluxComboList.forEach((o, index) => {
        if (o.FluxComboKey == item.FluxComboKey) {
          this.chooiceKey = o.FluxComboKey;
          this.chooiceItem = o;
          this.wechatPay = false;
          this.currentAmountPay = false;
          this.isLoading = false;
        }
      });
      console.log(this.vFluxComboList, 'this.vFluxComboList');
    },
    payFlowChoose (number) {
      if (this.chooiceKey == '-1') {
        layer.alert('请确定购买的流量包!');
        return;
      }
      if (number == 1) {
        if (this.currentAmountPay) {
          if ((Number(this.vMendianInfo.CurrentAmount) > Number(this.chooiceItem.PriceAmount))) {
            this.wechatPay = true;
            this.showBottom = false;
            this.currentAmountPay = false;
            this.getPayBalance();
          } else {
            if (this.wechatPay) {
            } else {
              if (Number(this.vMendianInfo.CurrentAmount) == 0) {
                this.currentAmountPay = false;
              }
              this.wechatPay = true;
              this.showBottom = false;
              this.getPayBalance();
            }
          }
        } else {
          this.wechatPay = true;
          this.getPayBalance();
        }
      }
      if (number == 2) {
        if (!this.vHasModulePower) {
          layer.alert('暂无权限');
          return;
          // 无权限
        }
        if (this.currentAmountPay) {
          this.currentAmountPay = false;
          return;
        }
        this.wechatPay = false;
        this.isLoading = false;
        this.goCurrentAmountPay();
      }
    },
    goCurrentAmountPay () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '',
        moduleName: 'verifyIdentityFlow',
        data: {},
        callBack: {
          afterSuccess: () => {
            this.passwordInfo();
          }
        }
      });
    },
    passwordInfo () {
      console.log('成功回调');
      this.currentAmountPay = true;
      this.showBottom = true;
    },
    getPayBalance () {
      let parmgs = {};
      let fn = null;
      if (this.vMendianInfo.EditionKey == 6) {
        parmgs.FluxComboKey = this.chooiceKey;
        parmgs.PayWx = this.wechatPay ? 1 : 0;
        parmgs.IsPayBalance = this.currentAmountPay ? 1 : 0;
        fn = CWSGetFluxPackagePayCode;
      } else if (this.vMendianInfo.EditionKey == 5) {
        parmgs.FluxComboKey = this.chooiceKey;
        fn = HQGetFluxPackagePayCode;
      } else {
        parmgs.FluxComboKey = this.chooiceKey;
        parmgs.PayWx = this.wechatPay ? 1 : 0;
        parmgs.IsPayBalance = this.currentAmountPay ? 1 : 0;
        fn = WEBVGetFluxPackagePayCode;
      }
      console.log(parmgs, 'parmgs');
      fn(parmgs).then(result => {
        console.log(result, '流量包支付二维码');
        if (result.code == 0) {
          this.payDataInfo = result.data;
          this.isLoading = true;
        }
      }).catch(error => {
        console.log(error, 'currentAmountRecharge');
      });
    },
    getCurrentAmountPay () {
      let fn = null;
      if (this.vMendianInfo.EditionKey == 6) {
        fn = CWSGetFluxPackageBalancePay;
      } else if (this.vMendianInfo.EditionKey == 5) {
        layer.alert('同事正在连夜搬砖中！');
        return;
      } else {
        fn = WEBVGetFluxPackageBalancePay;
      }
      fn(this.chooiceKey).then(result => {
        console.log(result, '余额支付返回结果');
        if (result.code == 0) {
          this.successFlowData = result.data;
          this.witndrawalType = '3';
          this.isShowCode = false;
          this.successFlowData.dataInfo = {
            wechatPay: this.wechatPay,
            item: this.chooiceItem,
            mode: '2'
          };
          this.showRechargeBalance = false;
        }
        this.$emit('afterSuccess');
      }).catch(error => {
        console.log(error, 'currentAmountRecharge');
      });
    },
    getFlowSendMessage () {
      this.$bus.on(this, 'wsFlowSendsMessage', (data) => {
        console.log(data, 'data');
        let mode = '1';
        if (this.wechatPay && !this.currentAmountPay) {
          mode = '1';
        }
        if (!this.wechatPay && this.currentAmountPay) {
          mode = '2';
        }
        if (this.wechatPay && this.currentAmountPay) {
          mode = '3';
        }
        if (data) {
          this.successFlowData = data;
          this.witndrawalType = '3';
          this.isShowCode = false;
          this.successFlowData.dataInfo = {
            wechatPay: this.wechatPay,
            item: this.chooiceItem,
            mode: mode
          };
          this.showRechargeBalance = false;
        }
      });
    },
    currentAmountPayAll () {
      if (Number(this.chooiceItem.PriceAmount) > Number(this.vMendianInfo.CurrentAmount)) {
        layer.alert('余额不足!请使用其他支付方式！');
        return;
      }
      if (Number(this.vMendianInfo.CurrentAmount) >= Number(this.chooiceItem.PriceAmount)) {
        this.getCurrentAmountPay();
      }
    },

    getSaaSClientMsgInfo () {
      GetSaaSClientMsg().then(result => {
        console.log(result, '门店信息');
        if (result.code == 0) {
          this.MendianInfo = result.data;
        }
      }).catch(error => {
        console.log(error, 'currentAmountRecharge');
      });
    }

  }
};
</script>
<style scoped>
.recharge_balance {
  height: 615px;
  overflow: auto;
}
.recharge_balance_title {
  width: 372px;
  height: 77px;
  border-radius: 12px;
  background: linear-gradient(90deg, #6da3ff 0%, #4a8dff 100%);
  margin: 20px;
  position: relative;
}
.recharge_balance_title_name {
  position: absolute;
  left: 20px;
  top: 15px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}
.recharge_balance_title_value {
  position: absolute;
  left: 20px;
  top: 40px;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 22px;

  color: #ffffff;
}
.recharge_balance_title_bottom {
  font-size: 14px;
  color: #ffffff;
}
.imgb {
  right: 10px;
  position: absolute;
  bottom: 0px;
}
.recharge_balance_div {
  margin: 0 20px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  color: #333333;
}
.recharge_balance_input_div {
  width: 372px;
  height: 48px;
  margin: 20px;
  position: relative;
}
.recharge_balance_input {
  box-sizing: border-box;
  width: 372px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 4px;
  line-height: 48px;
  padding-left: 37px;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}
.recharge_balance_input1 {
  box-sizing: border-box;
  width: 372px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 4px;
  padding-left: 37px;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
}
.recharge_balance_small {
  position: absolute;
  left: 13px;
  top: 15px;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  color: #333;
}
.recharge_balance_play {
  width: 372px;
  height: 200px;
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
}
.recharge_balance_play_list {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 30px;
}
.recharge_balance_play_list img {
  width: 22px;
  height: 19px;
}
.recharge_balance_play_list span {
  flex: 1;
  padding-left: 15px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #666666;
}
.recharge_balance_play_list_redio1 {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(../../../../../../public/image/tip_right.png);
  background-repeat: no-repeat;
  background-size: 18px;
}
.recharge_balance_play_list_redio {
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #c7c7c7;
  border-radius: 100%;
}
.recharge_balance_bottom {
  width: 100%;
  border-top: 1px solid #ececec;
  height: 80px;
  position: absolute;
  bottom: 0;
}
.btn_down_btn {
  width: 370px;
  height: 40px;
  border-radius: 4px;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 38px;
  text-align: center;
  overflow: hidden;
  align-items: center;
  margin: 20px;
}
.all_in_withdrawal {
  position: absolute;
  right: 20px;
  top: 15px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: #3498db;
  cursor: pointer;
  z-index: 1;
}
.all_in_withdrawal_show {
  position: absolute;
  right: 20px;
  top: 15px;
  width: 56px;
  height: 20px;
  z-index: 2;
  background-color: #ffffff;
  opacity: 0.4;
}
.flow_title_name {
  position: absolute;
  left: 20px;
  top: 23px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}
.flow_title_value {
  position: absolute;
  left: 90px;
  top: 15px;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 22px;

  color: #ffffff;
}
.flow_title_text {
  position: absolute;
  left: 20px;
  top: 50px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  color: #ffffff;
}
.flow_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 0 10px 13px !important;
  /* justify-content: space-around; */
}
.flow_choose_pay {
  width: 372px;
  height: 50px;
  margin: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.hqFlow_box {
  justify-content: unset !important;
}
.flow_pay_item {
  position: relative;
  width: 175px;
  height: 46px;
  /* box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04); */
  border-radius: 4px;
  background: linear-gradient(0deg, #f9fbfd, #f9fbfd), #f5f5f5;
  border: 1px solid #e8ebef;
  text-align: center;
  align-items: center;
  color: #333333;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 46px;
  cursor: pointer;
}
.option_img {
  position: absolute;
  top: 1px;
  right: 1px;
}
.option {
  border: 1px solid #3498db;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
}
.wechatTips {
  font-size: 14px;
  text-align: center;
}
.wechatTips img {
  width: 17px !important;
  height: 17px !important;
}
.wechatTips span {
  font-size: 16px;
}
.wechatCurrentTips {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 371px;
  height: 35px;
  background: #fffbe6;
  border: 1px solid #ffe694;
  border-radius: 2px;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #666;
  line-height: 35px;
  padding-left: 36px;
  margin: 15px auto;
}
.sign_QRcode_img {
  margin: 10px auto 5px;
  width: 145px;
  height: 145px;
}
.sign_QRcode_img img {
  width: 138.58px;
  height: 138.58px;
}

/* WebKit browsers */
/deep/ input::-webkit-input-placeholder {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
/* Mozilla Firefox 4 to 18 */
/deep/ input:-moz-placeholder {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
/* Mozilla Firefox 19+ */
/deep/ input::-moz-placeholder {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
/* Internet Explorer 10+ */
/deep/ input:-ms-input-placeholder {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
</style>

