<template>
  <div>
    <div class="course_statistics_table_search flex course_statistics_title">
      <div class="flex">
        <span>选择年份</span>
        <div class="title_date_select">
          <div class="el-select">
            <div class="el-input el-input--small el-input--suffix is-focus">
              <single-year-picker @chgDate="changeSaveDate"
                                  ref="monthPicker"
                                  :type="'year'"
                                  :maxDate="vMaxDate"
                                  :isShowHistory="isShowHistory"
                                  :minDate="vMinDate"></single-year-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="change_history_data"
           @click.stop="historyBtn(historyName)">{{ historyName }}</div>
      <div class="course_statistics_search">
        <!-- <div class="mendian_backstage_search">
          <div class="search_box">
            <div class="search_box_content">
              <div class="">
                <div class="el-input el-input--small el-input--suffix is-focus">
                  <input type="text"
                         v-model="searchObj.searchText"
                         autocomplete="off"
                         placeholder="请输入姓名、姓名首字母、手机号"
                         class="el-input__inner">
                </div>
              </div>
            </div>
          </div>
          <div class="btn_hover_bg_blue search_btn "
               @click.stop="search">搜索</div>
          <div class="btn_hover_white search_clear"
               @click.stop="clearSearchCondition">清除条件</div>
        </div> -->

        <!-- <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入姓名、姓名首字母、手机号'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn> -->
        <div></div>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <!-- <list class="summarizing_list"
            :key="tableKey"
            :tableData="vdataReportList"
            :totalNum="totalNum"
            :PageCount="PageCount"
            :tableColumns="tableCourseColumns"
            :isSearchText="false"
            :isNeedInnerScroll="false"
            :defaultSort="defaultSort"
            :queryParams="searchObj"
            :vFooterTotal="vFooterTotal"
            @tableBtnClick="doAfterTableBtnClick"
            @loadTableData="getDataList"></list> -->
      <table-list class="summarizing_list"
                  :tableData="vdataReportList"
                  :tableColumns="tableCourseColumns"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :queryParams="searchObj"
                  :footerContent="vFooterTotal"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import debtCourseHourseDetails from '../../../pop-up-menu/sign-up-payment/debt-course-hourse-details';
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import singleYearPicker from '../../../base-module/date-picker-common/single-year-picker';
import { MessageSearchOperatingReceiptReportForMonth, MessageSearchOperatingReceiptHistoryReportForMonth } from '../../../../API/workbench';
export default {
  data () {
    return {
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      seletedCourseInfo: {},
      studentDetail: {},
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: Date.new().getFullYear() + '-01-01',
        endTime: Date.new().getFullYear() + '-12-31',
        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '月份',
          prop: 'SetUpTime',
          minWidth: 108,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '小计',
          prop: 'Total',
          minWidth: 160,
          sortable: 'custom',
          align: 'left',
          type: 'textItem',
          extend: {
            symbol: '',
            setClassName: (rowData) => {
              if ((Number(rowData.ClassAmount && rowData.ClassAmount.replace(/[+]/g, '')) != 0 || Number(rowData.OverdueClassAmount && rowData.OverdueClassAmount.replace(/[+]/g, '')) != 0 || Number(rowData.AbnormalClassAmount && rowData.AbnormalClassAmount.replace(/[+]/g, '')) != 0 || Number(rowData.IncidentalsAmount && rowData.IncidentalsAmount.replace(/[+]/g, '')) != 0 || Number(rowData.TotalBackCourseAmount && rowData.TotalBackCourseAmount.replace(/[+]/g, '')) != 0) && Number(rowData.Total && rowData.Total.replace(/[+]/g, '')) <= 0) {
                return 'font_red';
              }
            }
          }
          // type: 'redNumber'
        },
        {
          label: '课时课消',
          prop: 'ClassAmount',
          minWidth: 170,
          sortable: 'custom',
          align: 'left',
          className: '',
          type: 'textItem',
          extend: {
            className: 'font_blue font_border_blue',
            click: (rowData) => {
              rowData.eventName = '3';
              this.doAfterTableBtnClick(rowData);
            }
          }
        },
        {
          label: '年月日卡扣费',
          prop: 'TermCardAmount',
          minWidth: 170,
          sortable: 'custom',
          align: 'left',
          className: '',
          type: 'textItem',
          extend: {
            className: 'font_blue font_border_blue',
            click: (rowData) => {
              rowData.eventName = '4';
              this.doAfterTableBtnClick(rowData);
            }
          }
        },
        {
          label: '课时过期',
          prop: 'OverdueClassAmount',
          minWidth: 180,
          sortable: 'custom',
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue font_border_blue',
            click: (rowData) => {
              rowData.eventName = '5';
              this.doAfterTableBtnClick(rowData);
            }
          }
        },
        {
          label: '异常课消',
          prop: 'AbnormalClassAmount',
          minWidth: 185,
          sortable: 'custom',
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue font_border_blue',
            click: (rowData) => {
              rowData.eventName = '6';
              this.doAfterTableBtnClick(rowData);
            }
          }
        },
        {
          label: '杂费收入',
          prop: 'IncidentalsAmount',
          minWidth: 185,
          sortable: 'custom',
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue font_border_blue',
            click: (rowData) => {
              rowData.eventName = '7';
              this.doAfterTableBtnClick(rowData);
            }
          }
        },
        {
          label: '退课差额',
          prop: 'TotalBackCourseAmount',
          sortable: 'custom',
          minWidth: 190,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_blue font_border_blue',
            click: (rowData) => {
              rowData.eventName = '8';
              this.doAfterTableBtnClick(rowData);
            }
          }
        }
      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
    debtCourseHourseDetails,
    singleYearPicker
  },
  props: {
  },
  created () {
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.showSetUpTime = this.$utils.parseJson(o.SetUpTime);
        if ((o.ClassAmount != 0 || o.OverdueClassAmount != 0 || o.AbnormalClassAmount != 0 || o.IncidentalsAmount != 0 || o.TotalBackCourseAmount != 0) && o.Total <= 0) {
          o.isRed = true;
        }
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      if (this.totalAmount > 0) {
        text = text + ',合计+' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      } else {
        text = text + ',合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      }
      return text;
    }
  },
  mounted () {
    this.$bus.on(this, 'wsAttendanceOrArrange', (data) => {
      console.log('触发wsAttendanceOrArrange');
      this.getDataList();
    });
    this.getDataList();
  },
  methods: {
    historyBtn (name) {
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.searchObj.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.searchObj.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isShowHistory = true;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.searchObj.startTime = Date.new().getFullYear() + '-01-01';
        this.searchObj.endTime = Date.new().getFullYear() + '-12-31';
        this.isShowHistory = false;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.getDataList();
      this.$refs.monthPicker.changeHistoryTime(this.searchObj.endTime);
    },
    changeSaveDate (dataInfo, startTime, endTime) {
      console.log(dataInfo, '');
      this.searchObj.startTime = startTime.replace(/\//g, '-');
      this.searchObj.endTime = endTime.replace(/\//g, '-');;
      this.search();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      // this.searchObj.orderBy = '';
      // this.searchObj.sequence = 'DESC';
      this.searchObj.IsExportKey = 0;
      this.$refs.monthPicker.cleanTime();
      this.getDataList();
    },
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (item, date) {
      console.log('searchData', date);
      this.searchObj.TeacherKey = item.TeacherKey;
      this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(date).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      let seletedYearMonth = Date.new(date).getFullYear() + ' ' + (Date.new(date).getMonth() + 1);
      let nowYearMonth = Date.new().getFullYear() + ' ' + (Date.new().getMonth() + 1);
      if (seletedYearMonth != nowYearMonth) {
        this.searchObj.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new(date)), 'yyyy-MM-dd');
      } else {
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate() - 1))));
      }
      this.getDataList();
    },
    search () {
      this.searchObj.TeacherKey = 0;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = '';
        if (this.isShowHistory) {
          fn = MessageSearchOperatingReceiptHistoryReportForMonth;
        } else {
          fn = MessageSearchOperatingReceiptReportForMonth;
        }
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '营收月报',
          clist: [
            { title: '日期', cName: 'SetUpTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '小计', cName: 'Total', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课时课消', cName: 'ClassAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '年月日卡扣费', cName: 'TermCardAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课时过期', cName: 'OverdueClassAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '异常课消', cName: 'AbnormalClassAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '杂费收入', cName: 'IncidentalsAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '退课差额', cName: 'TotalBackCourseAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      let fn = '';
      if (this.isShowHistory) {
        fn = MessageSearchOperatingReceiptHistoryReportForMonth;
      } else {
        fn = MessageSearchOperatingReceiptReportForMonth;
      }
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj);
      fn(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData) {
      this.$emit('toTableType', rowData.eventName, rowData.showSetUpTime);
    },
    // 关闭弹窗页面
    closeDetails () {
      this.isDebtCourseHoursDetailsShow = false;
    }

  }
};
</script>
<style scoped>
.change_history_data {
  position: absolute;
  right: 150px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
</style>