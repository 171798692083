<!-- 释放设备 -->
<template>
  <div>
    <div class="form_info_edit form_info_list">
      <input-switch v-model="isSetModify"
                    :readonly="false"
                    style="padding-right: 23px;"
                    :formTitle="'授权老师所有课件'"
                    :required="false"
                    @change="changeSwitch"></input-switch>
      <input-switch v-model="isAutoAuth"
                    :readonly="false"
                    style="padding-right: 23px;"
                    :formTitle="'设备申请自动审批'"
                    :required="false"
                    @change="changeAutoAuthSwitch"></input-switch>
      <input-switch v-if="isAutoAuth"
                    v-model="isAutoReplace"
                    :readonly="false"
                    style="padding-right: 23px;"
                    :formTitle="'自动释放设备'"
                    :required="false"
                    @change="changeAutoReplace"></input-switch>
    </div>

    <div>
      <!-- <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submitAuto"
                             @cancelClick="doAfterClickCancelBtn"></save-cancel-btn-group> -->
    </div>
  </div>
</template>
<script>
import { CWSModifySetting } from '../../../../API/workbench';
export default {
  components: {
  },
  data () {
    return {
      isSetModify: false,
      dataInfo: [],
      autoAuthDataInfo: [],
      autoReplaceDataInfo: [],
      isAutoAuth: false,
      isAutoReplace: false
    };
  },
  props: {
    brandIosObj: Object
  },
  created () {
    this.dataInfo = this.$utils.getSaaSClientSetInfo(71);
    this.autoAuthDataInfo = this.$utils.getSaaSClientSetInfo(75);
    this.autoReplaceDataInfo = this.$utils.getSaaSClientSetInfo(94);
    console.log(this.dataInfo, 'this.dataInfo');
    if (this.dataInfo.SetKey == '1') {
      this.isSetModify = true;
    } else {
      this.isSetModify = false;
    }
    if (this.autoAuthDataInfo.SetKey == '1') {
      this.isAutoAuth = true;
    } else {
      this.isAutoAuth = false;
    }
    if (this.autoReplaceDataInfo.SetKey == '1') {
      this.isAutoReplace = true;
    } else {
      this.isAutoReplace = false;
    }
  },
  mounted () {

  },
  computed: {
  },
  methods: {
    changeSwitch () {
      console.log(this.isSetModify, 'this.isSetModify');
      const that = this;
      layer.confirm('确定' + (this.isSetModify ? '开启' : '关闭') + '授权老师所有课件开关吗？', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              that.isSetModify = !that.isSetModify;
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              if (that.isSetModify) {
                that.dataInfo.SetKey = '1';
              } else {
                that.dataInfo.SetKey = '0';
              }
              CWSModifySetting(
                that.dataInfo
              )
                .then((res) => {
                  console.log(res, '修改成功');
                })
                .catch((error) => {
                  layer.alert(error.msg);
                });
              layer.close();
            }
          }
        ]
      });
    },
    changeAutoAuthSwitch () {
      console.log(this.isAutoAuth, 'this.isAutoAuth');
      const that = this;
      layer.confirm('确定' + (this.isAutoAuth ? '开启' : '关闭') + '设备申请自动审批开关吗？', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              that.isAutoAuth = !that.isAutoAuth;
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              if (that.isAutoAuth) {
                that.autoAuthDataInfo.SetKey = '1';
              } else {
                that.autoAuthDataInfo.SetKey = '0';
              }
              CWSModifySetting(
                that.autoAuthDataInfo
              )
                .then((res) => {
                  console.log(res, '修改成功');
                })
                .catch((error) => {
                  layer.alert(error.msg);
                });
              layer.close();
            }
          }
        ]
      });
    },
    changeAutoReplace () {
      console.log(this.isAutoReplace, 'this.isAutoAuth');
      const that = this;
      layer.confirm('确定' + (this.isAutoReplace ? '开启' : '关闭') + '自动释放设备？', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              that.isAutoReplace = !that.isAutoReplace;
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              if (that.isAutoReplace) {
                that.autoReplaceDataInfo.SetKey = '1';
              } else {
                that.autoReplaceDataInfo.SetKey = '0';
              }
              CWSModifySetting(
                that.autoReplaceDataInfo
              )
                .then((res) => {
                  console.log(res, '修改成功');
                })
                .catch((error) => {
                  layer.alert(error.msg);
                });
              layer.close();
            }
          }
        ]
      });
    },
    // 提交新增
    submitAuto (callback) {
      this.$emit('submitAuto', this.isAutoAuth, this.isAutoReplace, this.autoReplaceDataInfo);
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    }
  }
};
</script>
