<template>
  <div class="courseware_add_img_box">
    <div class="courseware_add_title_box"
         style="padding-top:10px">
      <div class="input_box"
           style="border: none;"></div>
      <div class="delete_btn"
           @click="deleteItem"></div>
    </div>
    <div class="add_img">
      <img class="img_box"
           :src="vCoverUrl"
           :onerror="defaultImg"
           alt="" />
    </div>
  </div>
</template>
<script>
import {
  HQDeleteCoursewareAttach,
  DeleteCoursewareAttach,
  DeleteCoursewareManageAttach
} from '../../../../API/workbench';
export default {
  props: {
    dataInfo: Object
  },
  computed: {
    vCoverUrl () {
      if (!this.dataInfo.CoverUrl) {
        this.dataInfo.CoverUrl = '';
      }
      if (this.dataInfo.CoverUrl.indexOf('http') > -1) {
        return this.dataInfo.CoverUrl;
      } else {
        return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
      }
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' + require('../../../../../public/image/no_logo@2x.png') + '"'
      );
    }
  },
  methods: {
    deleteItem () {
      console.log('deleteItem', this.dataInfo);

      layer.confirm('是否删除当前课件封面图', {
        btn: [
          {
            name: '取消',
            callBack: () => {
              layer.close();
            }
          },
          {
            name: '确认',
            callBack: () => {
              this.dataInfo.CoverUrl = ''
              this.$emit('deleteCoverUrl', this.dataInfo);
              layer.close();
            }
          }
        ]
      });

    },
  }
};
</script>