<template>
  <!-- 小课包 -->
  <div class="courseware_manage_left">
    <div>
      <div class="cw_head_menu">
        <div class="font_black"
             style="line-height:32px;font-weight: bold;letter-spacing: 0.5px;">
          二级课包
        </div>
        <function-btn :item="{ name: '二级课包', ModulePowerKey: '' }"
                      :className="'el-button buttom_sq font_blue courseware_icon_03'"
                      style="width: 112px;padding-left: 40px;margin-right: -10px;"
                      @clickItem="doAddCourseBag({key:'add'})">
        </function-btn>
      </div>
      <div class="left_ul">
        <list-item v-for="(item,index) in vCoursewareTreeList"
                   :key="item.ID"
                   :keyIndex="index"
                   :type="vType"
                   :listLength="coursewareList.length"
                   :dataInfo="item"
                   :listHeight="Number(listHeight)"
                   @selectEvent="selectEvent"
                   @selectEventSecond="selectEventSecond"
                   @doOperateItemClick="doOperateItemClick"
                   @doListSort="doListSort"></list-item>
        <!-- 底部占位， 撑开底部防止最后的菜单栏显示不完整 -->
        <!-- <div style="width: 100%;height: 100px;"></div> -->
        <div v-if="coursewareList.length==0">
          <div class="monitoring_content_nodata">
            <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
          </div>
        </div>
      </div>
      <!-- 新增 -->
      <!-- <div v-if="vShowListAdd"
             class="left_list_add_btn"
             @click="doAddCourseBag()">新建课包
        </div> -->
    </div>
    <custom-dialog :title="titleName"
                   :visible.sync="isShowCoursewareFormPopup"
                   :before-close="closeCoursewareFormPopup">
      <courseware-form :coursewareId="coursewareId"
                       :maxCoursewareInfo="maxCoursewareInfo"
                       :isReadonly="false"
                       :parentCourseBag="parentCourseBag"
                       @close="closeCoursewareFormPopup"
                       @afterSuccess="editCoursewarePackageAfterSuccess"></courseware-form>
    </custom-dialog>
  </div>
</template>
<script>
import listItem from './list-item';
import coursewareForm from '../courseware-form';
import authorizationInfo from './authorization-info';
import { data } from 'qrcode.vue';
import { number } from '../../../../public/js/echarts';
export default {
  components: {
    listItem,
    coursewareForm,
    authorizationInfo
  },
  data () {
    return {
      investorInfo: { name: '', moduleName: 'recycleBin' },
      titleName: '创建二/三级课包',
      coursewareId: 0, // 课件包ID
      catalogKey: 0, // 1-目录2-子课包
      parentCourseBag: {
        ParentKey: '',
        ParentKeyValue: '',
        catalogKey: 0 // 1-目录2-子课包
      },
      newCoursewareList: [],
      editCoursewareInfo: null,
      isShowCoursewareFormPopup: false,
      recycleBinStyleFixed: true,
      recycleBinStyleAbsolute: false,
      listHeight: 670,
      elscrollTop: 0
    };
  },
  props: {
    maxCoursewareInfo: Object, // 大课包信息
    coursewareTypeList: Array,
    coursewareList: Array,
    vCoursewareTreeList: Array,
    coursewareInfo: Object,
    type: String// 大课包类型
  },
  computed: {
    vDataList () {
      let arr = [];
      return this.vCoursewareTreeList
      // console.log(this.newCoursewareList, this.maxCoursewareInfo, 'newCoursewareList');
      let treeData = this.newCoursewareList.filter(item => {
        // 找到该目录
        if ((this.editCoursewareInfo && Number(this.editCoursewareInfo.ParentKey) == Number(item.ID) && item.TypeKey == 2)) {
          this.$set(item, 'isOpt', true);
        } else {
          this.$set(item, 'isOpt', false);
        }
        if (this.editCoursewareInfo && Number(this.editCoursewareInfo.ID) == Number(item.ID)) {
          this.$set(item, 'isSelected', true);
        } else {
          this.$set(item, 'isSelected', false);
        }
        this.$set(item, 'childTree', []);
        return item.ID;
      });
      // 父节点数据
      const ParentList = treeData.filter(item => Number(item.ParentKey) == Number(this.maxCoursewareInfo.ID));
      // 子节点数据
      const childList = treeData.filter(item => Number(item.ParentKey) != Number(this.maxCoursewareInfo.ID));
      // console.log(ParentList, childList, '父节点数据-子节点数据');
      arr = this.$utils.loopData(ParentList, childList, treeData, 'ID', 'ParentKey');
      console.log(this.newCoursewareList, arr, 'vDataList');
      return arr;
    },
    // 非自研课件时不显示添加按钮
    vShowListAdd () {
      let existsIndex = this.vCoursewareType.findIndex(obj => {
        return obj.key == 'ZZ' && obj.isOpt;
      });
      if (existsIndex >= 0) {
        return true;
      } else {
        return false;
      }
    },
    vCoursewareType () {
      let list = [];
      list = this.coursewareTypeList.filter(obj => {
        return obj.isShow;
      });
      return list;
    },
    vType () {
      let findItem = this.vCoursewareType.find(o => {
        return o.isOpt == true;
      });
      return findItem.key;
    }
  },
  watch: {
    coursewareList (n, c) {
      console.log(n, 'this.newCoursewareList-watch');
      this.newCoursewareList = n;
      this.selectedDefaultCourseware(this.newCoursewareList);
    }
  },
  created () {
    this.newCoursewareList = this.coursewareList;
    // window.onscroll = () => {
    //   let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    //   let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
    //   let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    //   if (scrollTop + windowHeight >= scrollHeight - 20) {
    //     console.log(222)
    //     this.recycleBinStyleFixed = false;
    //     this.recycleBinStyleAbsolute = true;
    //   } else {
    //     console.log(1111)
    //     this.recycleBinStyleFixed = true;
    //     this.recycleBinStyleAbsolute = false;
    //   }
    // };
  },
  mounted () {
    this.$nextTick(() => {
      const el = document.querySelector('.left_ul');
      const offsetHeight = el.offsetHeight;
      el.onscroll = () => {
        this.elscrollTop = Number(el.scrollTop.toString());
        const scrollHeight = el.scrollHeight;
        this.$emit("updateElscrollTop", this.elscrollTop)
        console.log('this.elscrollTop', this.elscrollTop)
        // this.listHeight = offsetHeight
      };
    });
  },
  methods: {
    // 默认初始化,列表第一项选中高亮
    selectedDefaultCourseware (dataList) {
      if (dataList.length > 0 && this.coursewareInfo.ID) {
        dataList.forEach(o => {
          this.$set(o, 'isSelected', false);
        });
        let findIndex = -1;
        findIndex = dataList.findIndex(o => {
          return this.coursewareInfo.ID == o.ID;
        });
        if (findIndex >= 0) {
          this.$set(dataList[findIndex], 'isSelected', true);
        } else {
          this.$set(dataList[0], 'isSelected', true);
        }
      }
    },
    // 选择课件类型
    selectCoursewareType () {
      this.$emit('openCommonCustomDialogByFactoryDialog', this.investorInfo);
    },
    // 选择目录
    selectEventSecond (dataInfo, callBack) {
      console.log("selectEventSecond", dataInfo)
      this.$emit('selectEventSecond', dataInfo, callBack)
    },
    // 选择课件包
    selectEvent (dataInfo) {
      console.log(dataInfo, '点击事件');
      this.coursewareList.forEach(o => {
        this.$set(o, 'isSelected', false);
        if (Number(dataInfo.ID) === Number(o.ID)) {
          this.$set(o, 'isSelected', true);
        }
      });
      this.$parent.isLoadData = true;
      this.$emit('selectEvent', dataInfo);
    },
    // // 新建课包
    doAddCourseBag () {
      this.coursewareId = 0; //
      this.titleName = '创建二/三级课包';
      this.parentCourseBag.catalogKey = this.maxCoursewareInfo.LevelCount == 2 ? 2 : 1;// 1-目录2-子课包 LevelCount-2-3
      this.parentCourseBag.ParentKey = this.maxCoursewareInfo.ID;
      this.parentCourseBag.ParentKeyValue = this.maxCoursewareInfo.MainDemoName;
      this.isShowCoursewareFormPopup = true;
    },
    // 操作项
    doOperateItemClick (item, data) {
      console.log(item, data, '操作项');
      if (item.key == 'add') {
        this.coursewareId = 0; // 课件包ID
        this.titleName = '创建二/三级课包';
        this.isShowCoursewareFormPopup = true;
        this.parentCourseBag.catalogKey = 2;
        this.parentCourseBag.ParentKey = data.ID;
        this.parentCourseBag.ParentKeyValue = data.MainDemoName;
        return;
      }
      if (item.key == 'edit') {
        this.coursewareId = Number(data.ID); // 课件包ID
        this.titleName = data.TypeKey == 2 ? '修改课包' : '修改课件包';
        this.parentCourseBag.catalogKey = data.TypeKey == 2 ? 1 : 2;
        this.isShowCoursewareFormPopup = true;
        return;
      }
      this.$emit('doOperateItemClick', item, data);
    },
    // 添加/修改 课件包成功之后
    editCoursewarePackageAfterSuccess (type, data) {
      console.log(type, data, this.coursewareList, '添加/修改');
      if (type == 'add') {
        this.$set(data, 'isOpt', false);
        this.$set(data, 'isSelected', false);
        this.$set(data, 'childTree', []);
        this.coursewareList.push(data);
        if (Number(data.TypeKey) == 0) { // 子课包才需要选择
          setTimeout(() => {
            this.selectEvent(data);
          }, 500);
        }
      } else if (type == 'edit') {
        this.coursewareList.forEach(o => {
          if (o.ID == data.ID) {
            Object.assign(o, data);
          }
        });
      }
      this.editCoursewareInfo = data;
      this.isShowCoursewareFormPopup = false;
      console.log(data, this.coursewareList, '添加/修改 课件包成功之后');
    },
    // 排序
    doListSort (sortType, data) {
      this.editCoursewareInfo = data;
      this.$emit('doListSort', sortType, data);
    },
    // 授权列表操作成功回调
    afterSuccesAuth () {
      this.$emit('selectCoursewareType', { key: this.type }, this.coursewareInfo);
    },
    closeCoursewareFormPopup () {
      this.coursewareId = 0;
      this.isShowCoursewareFormPopup = false;
    }
  }
};
</script>

<style>
.left_box {
  /* height: 70px; */
  height: auto;
  /* border-bottom: 1px solid #ececec; */
  line-height: 20px;
  padding: 18px;
}
.left_box_title {
  display: flex;
  position: relative;
}
.left_box_name {
  color: #333333;
  font-size: 16px;
}
.left_box_btn {
  position: absolute;
  right: 0px;
  color: #3498db;
  width: 50px;
  top: 25px;
  text-align: right;
  cursor: pointer;
}
.left_return_btn {
  position: absolute;
  right: 0px;
  /* color: #3498db; */
  height: 20px;
  width: 28px;
  text-align: right;
  cursor: pointer;
  background: url(../../../../public/image/return_page_icon.png) no-repeat
    center center;
  background-size: 24px;
}
.left_box_add {
  font-size: 22px;
  position: absolute;
  right: 30px;
  top: -2px;
}
.left_box_text {
  color: #999999;
  padding-top: 4px;
}
.left_state {
  width: 54px;
  height: 17px;
  background: #ffd6d9;
  border-radius: 3px;
  color: #ff6c67;
  padding: 0 5px;
  margin-left: 12px;
}
.courseware_manage_right.max_courseware {
  top: 65px;
  position: absolute;
  width: 300px;
  right: 500px;
  height: 50px;
  min-height: 40px;
}
.left_list_add_btn {
  background: #f9fbfd;
  border: 1px solid #e8ebef;
  width: 219px;
  height: 35px;
  border-radius: 4px;
  position: relative;
  text-align: center;
  margin: 0px 66px 15px;
  line-height: 35px;
  cursor: pointer;
}
.left_list_add_btn::after {
  content: '';
  background: url(../../../../public/cw-img/courseware_btn@2X.png) no-repeat 50%;
  background-size: 18px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 48px;
  cursor: pointer;
}
.cw_head_menu {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(236, 236, 236, 1);
}
.courseware_icon_03 {
  margin-right: -10px;
}
.courseware_icon_02,
.courseware_icon_03 {
  padding-left: 45px;
  border: none !important;
}
.courseware_icon_02:hover,
.courseware_icon_03:hover {
  /* color: #b9ddf5 !important; */
  background: none !important;
}
.courseware_icon_02::before {
  content: '';
  position: absolute;
  width: 28px;
  height: 28px;
  left: 10px;
  top: 3px;
  background: url(../../../../public/image/courseware_icon_02.png) center
    no-repeat;
  background-size: 23px;
}
.courseware_icon_03::before {
  content: '';
  position: absolute;
  width: 28px;
  height: 28px;
  left: 10px;
  top: 3px;
  background: url(../../../../public/image/courseware_icon_03.png) center
    no-repeat;
  background-size: 24px;
}
.scroll_type ::-webkit-scrollbar {
  /* width: 0px !important; */
  height: 4px;
  background-color: #f5f5f5;
}
.courseware_manage_left .left_ul {
  height: 850px !important;
  overflow-y: auto;
}
</style>