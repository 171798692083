<template>
  <!-- 未提交 -->
  <div class="homework_student_list">
    <div class="student_list_img">
      <img class="img_box"
           :src="vStudentImg"
           :onerror="defaultImg"
           alt="">
    </div>
    <div class="student_list_name">
      <div class="name_box">{{studentItem.StudentKeyValue}}</div>
    </div>
  </div>
</template>

<script>
// import browseItemHead from '../common-component/browse-box/browse-item-head';
import browseItemContent from '../../common-component/browse-box/browse-item-content';
import releaseBtn from '../../common-component/release-btn';
export default {
  components: {
    // browseItemHead,
    browseItemContent,
    releaseBtn
  },
  props: {
    studentItem: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  computed: {
    // 头像
    vStudentImg () {
      if (this.studentItem.HeadImgSupport) {
        if (this.studentItem.HeadImgSupport.indexOf('http') > -1) {
          return this.studentItem.HeadImgSupport;
        } else {
          return this.$store.getters.CDNURL + this.studentItem.HeadImgSupport;
        }
      } else {
        return '';
      }
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../../public/image/face_screenage_noImg.png') + '"';
    }
  },
  methods: {
    // 点击切换
    onClickEvent (data) {
      // this.$set(this.item, 'isActive', true);
      // this.$emit('onClickSwitch', this.item);
    },
    correctTaskButClick () {

    }
  }
};
</script>

<style>
</style>