<template>
  <div class="massive_bg echarts_hous_mb" :class="[className]">
    <div class="statistics_echarts_left fl">
      <div class="statistics_echarts_left_title">
        课时消耗排名-按班级
      </div>
      <multiplePicker @chgDate="chgDate" :sTime="startTime" :eTime="endTime"></multiplePicker>
      <div class="statistics_echarts_left_btn_content">
        <columnarEchart :echartID="idName"
                        :reqList="reqList"
                        :dataList="dataList"
                        :echartColor="echartColor"
                        :echartBGColor="echartBGColor"
                        :rotate="'-45'"
                        :echartClass="echartClassName"
                        ref="echart"></columnarEchart> 
      </div>
    </div>
    <classStatistics :lastInfo="statisticsLastInfo"
                     :statisticsList="statisticsList"
                     :type="type"></classStatistics>
  </div>
</template>
<script>
import classStatistics from '../../common/class-statistics';
import columnarEchart from '../echart-common/columnar-echart';
import multiplePicker from '../date-picker-common/multiple-picker';
import { getSummarizeClassCountForGrade } from '../../../API/workbench';
export default {
  name: 'classCountReport',
  data () {
    return {
      // 当前月份第一天.
      startTime: Date.new(Date.new().setDate(1)).toLocaleDateString(),
      // 当前月份最后一天.
      // endTime: Date.new().getFullYear() + '/' + (Date.new().getMonth() + 1) + '/' + Date.new(Date.new().getFullYear(), Date.new().getMonth() + 1, 0).getDate(),
      endTime: this.setChoseEndTime(Date.new()),
      type: 'classCountType',
      statisticsLastInfo: {},   // 最后一个信息
      statisticsList: [],     // 接收result.data
      reqList: [],          // echart底下遍历的数据
      dataList: [],        // echart 中间遍历的数据
      echartColor: 'rgba(73,181,243,0.6)', // 报表颜色,这是红色,  'rgba(73,181,243,0.6)'这是蓝色 'rgba(255,108,103,0.6)' 这是红色
      echartBGColor: 'rgba(73,181,243,1)', // 报表颜色,这是红色, 'rgba(73,181,243,1)'这是蓝色  'rgba(255,108,103,1)' 这是红色
      echartClassName: 'echarts_l'        // 初始化echartClassName名字.
    };
  },
  props: {
    className: String,
    idName: String,
    echartClass: String
  },
  components: {
    classStatistics, multiplePicker, columnarEchart
  },
  created () {
    if (this.echartClass) {
      this.echartClassName = this.echartClass;
    }
  },
  watch: {
    echartClass (c, o) {
      this.echartClassName = c;
    }
  },
  computed: {
  },
  mounted () {
    this.initGetData();
    // this.getAttenStatisticalList(this.startTime, this.endTime);
  },
  methods: {
    chgDate (startTime, endTime) {
      this.startTime = startTime;
      this.endTime = endTime;
      console.log(this.startTime, this.endTime);
      this.initGetData();
    },
    // 绑定数据中的名字
    getDataToFoce () {
      var newArr = [];
      this.statisticsList.forEach(o => {
        if (o.OLAPKey) {
          newArr.push(o.MainDemoName);
        }
      });
      console.log('getDataToFoce', newArr);
      return newArr;
    },
    // echart的显示数据.
    getDataToCount () {
      var newArr = [];
      this.statisticsList.forEach(o => {
        if (o.OLAPKey) {
          newArr.push(Number(o.ClassCount));
        }
      });
      return newArr;
    },
    // 初始化获取数据并视callback是否存在触发后续事件.
    initGetData (callback) {
      this.getClassCountStatisicalList(this.startTime, this.endTime, callback);
    },
    // 封装获取选择的月份 及最后一天.timer 为时间类型.
    setChoseEndTime (timer) {
      let Year = timer.getFullYear();
      let Month = timer.getMonth() + 1;
      let EndDate = Date.new(Year, Month, 0).getDate();
      return Year + '/' + Month + '/' + EndDate;
    },
    getClassCountStatisicalList (startTime, endTime, callback) {
      this.statisticsList.length = 0;
      getSummarizeClassCountForGrade(startTime, endTime).then(result => {
        console.log(result.data, 'getClassCountStatisicalList');
        this.statisticsLastInfo = result.data.TheLastGrade;
        this.statisticsList = result.data.Top15GradeList || [];
        console.log(this.statisticsLastInfo, this.statisticsList);
        this.reqList = this.getDataToFoce();
        this.dataList = this.getDataToCount();
        this.$nextTick(() => {
          this.$refs.echart.echartInit();
        });
        if (callback) {
          callback();
        }
      });
    }

  }

};
</script>
