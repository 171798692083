<template>
  <!-- 直营店表单 -->
  <div id="mendain_set_form">
    <module-description v-if="!isReadonly"
                        style="padding-right:0"
                        :textTips="'直营店开户须知：直营店开户后，会自动同步总部设置的课程分类和名称'"></module-description>
    <div class="form_info_edit form_info_list">
      <input-text :formTitle="'校区名'"
                  :required="true"
                  :readonly="isReadonly||vIsEdit"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.CampusName"></input-text>

      <input-number-alphabet :formTitle="'门店ID'"
                             :required="true"
                             :readonly="isReadonly ||vIsEdit"
                             :formPlaceholder="'请输入'"
                             :formInputHint="vCurrentMendianId"
                             v-model="dataInfo.SaaSClientID"></input-number-alphabet>

      <input-text :formTitle="'管理员'"
                  :required="true"
                  :readonly="isReadonly || vIsEdit"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.AdminName"></input-text>

      <input-phone :formTitle="'管理员手机号'"
                   :formPlaceholder="'请输入'"
                   :required="true"
                   :readonly="isReadonly || vIsEdit"
                   v-model="dataInfo.DefaultMobile"></input-phone>
       <input-select v-if="isFlowSet"
                      :formTitle="'流量设置'"
                      :id="'type'"
                      :name="'name'"
                      :required="true"
                      :readonly="isReadonly"
                      :selectedInfo="vflowSetInfo"
                      :dataList="flowSetType"
                      @selectedItem="changeFlowSet"></input-select>
      <input-select v-if="vshowOption"
                    :formTitle="'直营类型'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :readonly="isReadonly||vIsEdit"
                    :dataList="sourceType"
                    :selectedInfo="vSourceTypeInfo"
                    @selectedItem="changeSourceType"></input-select>

      <input-search-cities :formTitle="'所在地区'"
                           :required="false"
                           :readonly="isReadonly ||vIsEdit"
                           :selectedInfo="locationInfo"
                           @selectedItem="changeRegion"></input-search-cities>
      <div class="form_info_edit form_info_list pr location_text"
           style="padding:0px">
        <input-text :formTitle="'详细地址'"
                    :formPlaceholder="'请输入'"
                    :readonly="isReadonly ||vIsEdit"
                    :required="false"
                    v-model="dataInfo.Address"></input-text>

      </div>
      <input-dialog :formTitle="'地图定位'"
                    class="site_icon_type"
                    :customPlaceholder="true"
                    :formPlaceholder="'设置'"
                    :formInputHint="dataInfo.SaaSClientSetting[0].SetContent?'':'未设置定位'"
                    :required="false"
                    :hiddenInput="true"
                    :readonly="isReadonly||vIsEdit"
                    :class="dataInfo.SaaSClientSetting[0].SetContent?'content':''"
                    :value="dataInfo.SaaSClientSetting[0].SetContent ||'设置'"
                    @showEldilog="isShowLocationForm=true">
        <span class="student_view_title_next"
              style="    left: 82px;
    top: 15px;
position: absolute;"
              @click.stop="openHistoryLocationPopup">历史定位</span>
      </input-dialog>

      <div class="mendian_location pr"
           v-if="vLocationImg != ''">
        <img :src="vLocationImg"
             crossOrigin="anonymous"
             alt="">
      </div>
      <input-number v-if="!CampusKey"
                    :min="0"
                    :formTitle="'开通时长（年）'"
                    :formPlaceholder="'请输入'"
                    :decimalPlaces="0"
                    :required="true"
                    :readonly="isReadonly"
                    v-model="dataInfo.DeductCount"></input-number>

      <input-number v-if="!CampusKey"
                    :min="0"
                    :formTitle="'扣除端口数（个）'"
                    :decimalPlaces="0"
                    :required="false"
                    :readonly="true"
                    :className="'font_black'"
                    v-model="dataInfo.DeductCount"></input-number>

      <input-dialog v-if="isShowInput"
                    :formTitle="'招商顾问'"
                    :formPlaceholder="'请输入'"
                    :required="false"
                    :readonly="isReadonly ||vIsEdit"
                    v-model="dataInfo.CounselorKeyValue"
                    @showEldilog="selectCounselor"></input-dialog>

      <input-date v-if="isShowInput"
                  v-model="dataInfo.LeagueTime"
                  :formTitle="'加盟日期'"
                  :readonly="isReadonly "
                  :elementId="'BirthdayTime'"
                  :required="false"
                  :formPlaceholder="'选填'"></input-date>
      <div v-if="isReadonly&&!noShowJW">
        <input-text :formTitle="'教务功能'"
                    class=""
                    :formPlaceholder="'请输入'"
                    :readonly="isReadonly"
                    :required="false"
                    :value="vExpiredTime"></input-text>
      </div>
      <div v-if="isReadonly">
        <input-text :formTitle="'课件功能'"
                    class=""
                    :formPlaceholder="'请输入'"
                    :readonly="isReadonly"
                    :required="false"
                    :value="vCoursewareChannelExpiredTime"></input-text>
      </div>


    </div>

    <div v-if="!isReadonly">
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
    <custom-dialog :title="'历史定位'"
                   width="540px"
                   :visible.sync="isShowHistoryLocation"
                   :before-close="closeHistoryLocationPopup">
      <history-location :CampusKey="Number(CampusKeyValue)"
                        @close="closeHistoryLocationPopup"></history-location>
    </custom-dialog>
    <custom-dialog :title="'位置'"
                   :visible.sync="isShowLocationForm"
                   :before-close="closeLocationFormPopup">
      <form-location :locationInfo="dataInfo.SaaSClientSetting"
                     @close="closeLocationFormPopup"
                     @setLocationInfo="setLocationInfo"></form-location>
    </custom-dialog>
  </div>
</template>
<script>
import { AddRetailStores, ModifyRetailStores, GetCampusDetails, GetOnJobEmployees, GetSaaSClientSet, AdjustmentCurrentFluxObject, AuditPassConfirm } from '../../../../../API/workbench';
import historyLocation from './history-location.vue';
export default {
  data () {
    return {
      schoolKey: 0,
      CampusKeyValue: 0,
      isShowHistoryLocation: false,
      isFlowSet: false,
      getDate: new Date(),
      // 流量设置
      flowSetType: [
        { type: 1, name: '总部' },
        { type: 2, name: '门店' }
      ],
      // 直营类型
      sourceType: [],
      locationInfo: [], // 地址
      dataInfo: {
        CampusName: '', // 校区名
        SaaSClientID: '', // 门店ID
        Principal: '', // 负责人
        MobilePhone: '', // 手机号
        AdminName: '', // 管理员
        DefaultMobile: '', // 管理员手机号
        TypeKey: '', // 加盟类型
        TypeKeyValue: '', // 1-直营参股;2-直营直属;3-课件加盟;4-整店加盟
        ProvinceKey: '', // 省
        ProvinceKeyValue: '',
        CityKey: '', // 市
        CityKeyValue: '',
        AreaKey: '', // 区
        AreaKeyValue: '',
        Address: '', // 详细地址
        CounselorKey: '', // 招商顾问
        CounselorKeyValue: '',
        DeductCount: 1, // 扣除端口数
        LeagueTime: this.$utils.formatDateToLine(Date.new()), // 加盟日期
        IsOpenAcademicKey: '',
        IsOpenChannelKey: '',
        ExpiredTime: '',
        CoursewareChannelExpiredTime: '',
        CurrentFluxObjectKey: 1, // 1-总部;2-门店
        CurrentFluxObjectKeyValue: '总部', // 总部、门店
        NextFluxObjectKey: 0, // 下个月扣费对象	 1-总部;2-门店;0-未设置
        NextFluxObjectKeyValue: '', // 下个月扣费对象	 1-总部;2-门店;0-未设置
        SaaSClientSetting: [
          { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '14' }, // 定位地址信息
          { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '15' }, // 定位经纬度信息
          { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '16' }// 定位截图信息
        ]
      },
      isShowLocationForm: false // 位置
    };
  },
  components: {
    historyLocation
  },
  props: {
    CampusKey: {
      type: Number,
      default: 0
    },
    isReadonly: Boolean,
    noShowJW: Boolean,
    isShowInput: {
      type: Boolean,
      default: false
    },
    isCheck: { // 是否是总部控制台点击进来  请求不同接口
      type: Boolean,
      default: false
    }
  },
  created () {
    this.schoolKey = this.CampusKey;
    if (this.schoolKey > 0) {
      this.getDetailInfo();
    } else {
      this.getMenDianSet();
    }
    // console.log(this.$store.getters, 'initAddData');
    console.log(this.schoolKey, 'CampusKey77777 isReadonly', this.isReadonly, 'noShowJW777', this.noShowJW, 'isShowInput777', this.isShowInput, 'isCheck777', this.isCheck);
  },
  computed: {
    vshowOption () {
      if (this.isReadonly) {
        return false;
      } else if (this.vIsEdit) {
        return false;
      } else {
        return true;
      }
    },
    vIsEdit () {
      if (this.schoolKey > 0) {
        return true;
      } else {
        return false;
      }
    },
    // 当前门店id
    vCurrentMendianId () {
      return this.$store.getters.SaaSClientInfo.SaaSClientNO + '-';
    },
    // 直营类型
    vSourceTypeInfo () {
      return {
        type: this.dataInfo.TypeKey,
        name: this.dataInfo.TypeKeyValue
      };
    },
    // 流量设置
    vflowSetInfo () {
      if (this.schoolKey > 0) {
        return {
          type: this.dataInfo.NextFluxObjectKey,
          name: this.dataInfo.CurrentFluxObjectKeyValue
        };
      } else {
        return {
          type: this.dataInfo.CurrentFluxObjectKey,
          name: this.dataInfo.CurrentFluxObjectKeyValue
        };
      }
    },
    // 是否开通教务-日期
    vExpiredTime () {
      if (this.dataInfo.IsOpenAcademicKey == 1) {
        return this.dataInfo.ExpiredTime;
      } else {
        return '未开通';
      }
    },
    // 是否开通课件-日期
    vCoursewareChannelExpiredTime () {
      if (this.dataInfo.IsOpenChannelKey == 1) {
        return this.dataInfo.CoursewareChannelExpiredTime;
      } else {
        return '未开通';
      }
    },
    // 定位截图
    vLocationImg () {
      if (this.dataInfo && this.dataInfo.SaaSClientSetting[2].SetContent) {
        return this.$store.getters.CDNURL + this.dataInfo.SaaSClientSetting[2].SetContent;
      } else {
        return '';
      }
    }
  },
  methods: {

    closeLocationFormPopup () {
      this.isShowLocationForm = false;
    },
    setLocationInfo (data) {
      this.dataInfo.SaaSClientSetting = data;
      console.log(data, 'locationInfo');
      this.closeLocationFormPopup();
    },
    // 获取门店设置
    getMenDianSet () {
      GetSaaSClientSet().then(result => {
        result.data.forEach(o => {
          if (o.SetTypeKey == 6 && o.SetKey == 1) { // 是否显示直属直营
            this.sourceType.push({ type: 2, name: '直属直营' });
          }
          if (o.SetTypeKey == 7 && o.SetKey == 1) { // 是否显示参股直营
            this.sourceType.push({ type: 1, name: '参股直营' });
          }
        });
        this.dataInfo.TypeKey = (this.sourceType[0].type == 2 ? 2 : 1);
        this.dataInfo.TypeKeyValue = (this.sourceType[0].type == 2 ? '直属直营' : '参股直营');
        console.log('GetSaaSClientSet', result.data);
      });
    },
    // 选择直营类型
    changeSourceType (obj) {
      console.log(obj, '选择来源方式');
      this.dataInfo.TypeKey = obj.type;
      this.dataInfo.TypeKeyValue = obj.name;
    },
    // 选择流量设置
    changeFlowSet (obj) {
      console.log(obj, '选择来源方式');
      if (this.schoolKey > 0) {
        this.dataInfo.NextFluxObjectKey = obj.type;
      } else {
        this.dataInfo.CurrentFluxObjectKey = obj.type;
      }
      this.dataInfo.CurrentFluxObjectKeyValue = obj.name;
      console.log(this.dataInfo.CurrentFluxObjectKey, 'this.dataInfo.CurrentFluxObjectKey');
    },
    // 省市区
    changeRegion (keys, values) {
      this.dataInfo.ProvinceKey = keys[0] || '';// 省
      this.dataInfo.ProvinceKeyValue = values[0] || ''; // 省
      this.dataInfo.CityKey = keys[1] || '';// 市
      this.dataInfo.CityKeyValue = values[1] || ''; // 市
      this.dataInfo.AreaKey = keys[2] || ''; // 区/县
      this.dataInfo.AreaKeyValue = values[2] || ''; // 区/县
      console.log(keys, values, '省市区');
    },
    // 选择招商顾问
    selectCounselor () {
      GetOnJobEmployees().then(result => {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择招商顾问',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.dataInfo.CounselorKey, dataSourceList: result.data, required: false, ModulePowerKey: 0 },
          callBack: {
            onSelectedItem: (data) => {
              console.log(data, '选择招商顾问');
              this.dataInfo.CounselorKey = data.OLAPKey;
              this.dataInfo.CounselorKeyValue = data.MainDemoName;
            }
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取校区详情信息
    getDetailInfo () {
      let apiFn = null;
      if (this.isCheck) {
        console.log('this.schoolKey', this.schoolKey);
        apiFn = AuditPassConfirm;
      } else {
        apiFn = GetCampusDetails;
      }
      apiFn(this.schoolKey).then(result => {
        console.log('获取校区详情信息', result.data);
        if (result.data.IsOpenAcademicKey == 1 && result.data.IsOpenChannelKey == 1) {
          this.isFlowSet = true;
        } else if (result.data.IsOpenAcademicKey == 1) {
          this.isFlowSet = false;
        } else {
          this.isFlowSet = true;
        }
        Object.assign(this.dataInfo, result.data);
        this.dataInfo.CampusName = result.data.MainDemoName;
        this.dataInfo.SaaSClientID = result.data.SaaSClientNO;
        this.dataInfo.AdminName = result.data.AdminName;
        this.dataInfo.Principal = result.data.PrincipalName;
        if (this.dataInfo.LeagueTime != null) {
          this.dataInfo.LeagueTime = this.dataInfo.LeagueTime.slice(0, 10);
        }
        this.locationInfo = [result.data.ProvinceKey, result.data.CityKey, result.data.AreaKey];
        if (result.data.CurrentFluxObjectKey == 1 && result.data.NextFluxObjectKey != 2) {
          this.dataInfo.CurrentFluxObjectKeyValue = '总部负担';
        } else if (result.data.CurrentFluxObjectKey == 1 && result.data.NextFluxObjectKey == 2) {
          if ((this.getDate.getMonth() + 2) == 13) {
            this.dataInfo.CurrentFluxObjectKeyValue = '总部负担,1月1号起门店负担';
          } else {
            this.dataInfo.CurrentFluxObjectKeyValue = '总部负担,' + (this.getDate.getMonth() + 2) + '月1号起门店负担';
          }
        } else if (result.data.CurrentFluxObjectKey == 2 && result.data.NextFluxObjectKey != 1) {
          this.dataInfo.CurrentFluxObjectKeyValue = '门店负担';
        } else if (result.data.CurrentFluxObjectKey == 2 && result.data.NextFluxObjectKey == 1) {
          if ((this.getDate.getMonth() + 2) == 13) {
            this.dataInfo.CurrentFluxObjectKeyValue = '门店负担,1月1号起总部负担';
          } else {
            this.dataInfo.CurrentFluxObjectKeyValue = '门店负担,' + (this.getDate.getMonth() + 2) + '月1号起总部负担';
          }
        } else if (result.data.CurrentFluxObjectKey == 0 && result.data.NextFluxObjectKey == 1) {
          if ((this.getDate.getMonth() + 2) == 13) {
            this.dataInfo.CurrentFluxObjectKeyValue = '暂时未设置，1月1号起总部负担';
          } else {
            this.dataInfo.CurrentFluxObjectKeyValue = '暂时未设置，' + (this.getDate.getMonth() + 2) + '月1号起总部负担';
          }
        } else if (result.data.CurrentFluxObjectKey == 0 && result.data.NextFluxObjectKey == 2) {
          if ((this.getDate.getMonth() + 2) == 13) {
            this.dataInfo.CurrentFluxObjectKeyValue = '暂时未设置，1月1号起门店负担';
          } else {
            this.dataInfo.CurrentFluxObjectKeyValue = '暂时未设置，' + (this.getDate.getMonth() + 2) + '月1号起门店负担';
          }
        } else {
          this.dataInfo.CurrentFluxObjectKeyValue = '未设置';
        }
        console.log('地址', this.locationInfo);
      });
    },
    // 提交
    submit (callback) {
      console.log('submit', this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      if (this.schoolKey > 0) {
        ModifyRetailStores(this.schoolKey, this.dataInfo).then(result => {
          layer.alert('修改成功');
          this.$emit('afterSuccess');
          this.cancel();
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callback) {
            callback();
          }
        });
        // 流量设置接口
        AdjustmentCurrentFluxObject(this.schoolKey, this.dataInfo.NextFluxObjectKey).then(result => {
          layer.alert('修改成功');
          this.$emit('afterSuccess');
          this.cancel();
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callback) {
            callback();
          }
        });
      } else {
        var obj = this.$utils.parseJson(this.dataInfo);
        obj.SaaSClientID = this.$store.getters.SaaSClientInfo.SaaSClientNO + '-' + this.dataInfo.SaaSClientID;
        AddRetailStores(obj).then(result => {
          layer.alert('新增成功');
          this.$emit('afterSuccess');
          this.cancel();
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callback) {
            callback();
          }
        });
      }
    },
    // 验证客户信息
    verificationInfo () {
      this.dataInfo.DeductCount = parseInt(this.dataInfo.DeductCount);
      if (this.dataInfo.CampusName) {
        this.dataInfo.CampusName = this.dataInfo.CampusName.replace(/(^\s*)|(\s*$)/g, '');
      }
      var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      let flag = true;
      if (!this.dataInfo.CampusName) {
        layer.alert('请输入校区名');
        flag = false;
      } else if (!this.dataInfo.SaaSClientID) {
        layer.alert('请输入大于0以上门店ID');
        flag = false;
      } else if (!this.dataInfo.AdminName) {
        layer.alert('请输入管理员');
        flag = false;
      } else if (!this.dataInfo.DefaultMobile) {
        layer.alert('请输入手机号');
        flag = false;
      } else if (!myreg.test(this.dataInfo.DefaultMobile)) {
        layer.alert('手机号输入错误');
        flag = false;
      } else if (!this.dataInfo.CurrentFluxObjectKeyValue) {
        layer.alert('请选择流量设置');
        flag = false;
      } else if (!this.dataInfo.DeductCount) {
        layer.alert('请输入开通时长（年）');
        flag = false;
      }
      return flag;
    },
    closeHistoryLocationPopup () {
      this.isShowHistoryLocation = false;
    },
    openHistoryLocationPopup () {
      this.CampusKeyValue = this.dataInfo.ClientKey;
      this.isShowHistoryLocation = true;
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.location_text >>> .form_info_field {
  margin-right: 62px;
}
</style>