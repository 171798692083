<template>
  <div>
    <div class="start_operation_box"
         v-if="vShowBeginOperation"
         @click="clickBeginOperation">
      开始操作
    </div>
    <div v-if="vShowStudentList">
         <student-list :CustStatusKey="1"
                    :switchItem="0"       
                    @selectedItem="doSelectedChooseStudent"></student-list>
    </div>
    <div v-if="vShowCourseNameList">
      <single-selection-list :selectedKey="externalSelectTypeKey"
                             :dataSourceList="singleArrangeList"
                             :required="true"
                             @onSelectedItem="doSelectedCourseOrClassName"></single-selection-list>
    </div>
    
  </div>

</template>

<script>
import studentList from '../common/student-list';
export default {
  data () {
    return {
      isShowStudentList: true,
      isShowCourseNameList: true
    };
  },
  props: {
    curShowProcessStepInfo: Object,
    processStepNames: Object,
    singleArrangeList: Array,
    externalSelectTypeKey: {
      type: [Number, String],
      default: 0
    }// 显示的高亮项ID

  },
  components: {
    studentList
  },
  created () {
  },
  computed: {
    vShowBeginOperation () {
      return !this.curShowProcessStepInfo.stepName;
    },
    vShowStudentList () {
      return this.curShowProcessStepInfo.stepName == this.processStepNames.chooseStudent;
    },
    vShowCourseNameList () {
      return this.curShowProcessStepInfo.stepName == this.processStepNames.chooseCourseName;
    }
  },
  methods: {
    // 点击开始操作
    clickBeginOperation () {
      this.$emit('clickBeginOperation');
    },
    // 选中学生后
    doSelectedChooseStudent (item) {
      this.$emit('selectedChooseStudent', item, 'showProcess');
    },
    // 选中课程或者班级名称后
    doSelectedCourseOrClassName (item) {
      this.$emit('selectedCourseOrClassName', item, 'showProcess');
    }
  }

};
</script>

<style>
</style>