<template>
  <!-- 线索录入 -->
  <div>
    <div class="form_info_edit form_info_list"
         style="margin-bottom: 100px;">
      <input-select :formTitle="'无效原因'"
                    :id="'id'"
                    :name="'name'"
                    :required="true"
                    :readonly="false"
                    :dataList="typeList"
                    :selectedInfo="typeInfo"
                    @selectedItem="chgType"></input-select>
    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group v-if="!isReadonly"
                           :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
  </div>
</template>

<script>
import {
  hqInvalid
} from '../../../../../API/workbench.js';
export default {
  data () {
    return {
      typeList: [
        {
          id: 1,
          name: '无人接听'
        },
        {
          id: 2,
          name: '无意向'
        }
      ], // 来源1-抖音;2-百度;3-转介绍
      typeInfo: {},
      dataJson: {
        AttractThreadKey: '', //	整型	投资线索Key
        InvalidReasonKey: '', //	整型	无效原因
        InvalidReasonKeyValue: '' //	字符串	1-无人接听;2-无意向

      }
    };
  },
  props: {
    dataInfo: Object, // 职务信息
    isReadonly: Boolean// 是否只读
  },
  created () {

  },
  computed: {

  },
  methods: {
    // 选择所属部门
    chgType (item) {
      console.log(item, 'chgType');
      this.dataJson.InvalidReasonKey = item.id;
      this.dataJson.InvalidReasonKeyValue = item.name;
    },
    // 确认按钮
    confirmClick (callBack) {
      if (!this.dataJson.InvalidReasonKey) {
        layer.alert('请选择无效原因');
        if (callBack) {
          callBack();
        }
        return false;
      }
      console.log(this.dataInfo, 'this.dataInfo');
      this.dataJson.AttractThreadKey = this.dataInfo.OLAPKey;
      hqInvalid(this.dataJson).then(result => {
        this.$emit('afterSuccess', result.data);
        layer.alert('设置无效线索成功');
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    cancelClick () {
      this.$emit('cancelClick');
    }
  }
};
</script>

<style>
</style>
