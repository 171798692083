<template>
  <div class="table_select_box_flex">
    <div class="table_select_box">
      <div class="table_select_title">选择校区</div>
      <el-select :value="vdefaut"
                 filterable
                 placeholder="必选"
                 @change="subjectEvent"
                 value-key="OLAPKey">
        <el-option v-for="item in courseNameList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item"></el-option>
      </el-select>
    </div>
    <!-- <div class="table_select_box">
      <div class="table_select_title">剩余课时</div>
      <el-select :value="vSeletedTimeValue"
                 filterable
                 placeholder="必选"
                 @change="seletedTime"
                 value-key="OLAPKey">
        <el-option v-for="item in maxTimeList"
                   :key="item.OLAPKey"
                   :label="item.MainDemoName"
                   :value="item"></el-option>
      </el-select>
    </div> -->
  </div>
</template>
<script>
import { hqSelectSchoolForInspector } from '../../../../API/workbench';
export default {
  data () {
    return {
      courseNameList: [], //校区列表
      vClass:'全部',
      maxTimeList: [
        {
          MainDemoName: '不限',
          OLAPKey: ''
        },
        {
          MainDemoName: '小于5',
          OLAPKey: '5'
        },
        {
          MainDemoName: '小于10',
          OLAPKey: '10'
        },
        {
          MainDemoName: '小于50',
          OLAPKey: '50'
        },
        {
          MainDemoName: '小于100',
          OLAPKey: '100'
        }
      ],
      vdefaut:'全部'
    };

  },
  created () {
    this.getCourseNameList();
    console.log(this.courseNameList,"courseNameList")
  },
  props: {
    searchObj: Object
  },
  computed: {
    vCourseKeyValue () {
      return this.courseNameList.OLAPKey == 0
        ? '全部'
        : this.courseNameList.MainDemoName;
    },
    vSeletedTimeValue () {
      return this.searchObj.MaxHour == 0
        ? '不限'
        : this.searchObj.MaxHourValue;
    },
  },
  watch: {

  },
  methods: {
    // 选中课程
    subjectEvent (obj) {
      console.log(obj, '选中课程');
      this.vdefaut = obj.MainDemoName;
      this.searchObj.SchoolKey = obj.OLAPKey;
      this.$emit('changeSearchObj');
    },
    seletedTime (obj) {
      this.searchObj.MaxHourValue = obj.MainDemoName;
      this.searchObj.MaxHour = obj.OLAPKey;
      this.$emit('changeSearchObj');
    },
    // 调用在职老师接口
    getCourseNameList () {
      hqSelectSchoolForInspector('OLAPKey', 'desc').then(result => {
        console.log(result.data,'选择校区')
        // this.courseNameList = result.data.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; });
        this.courseNameList = result.data;
      
        let arr = [];
        result.data.forEach(element => {
        let obj = {
          OLAPKey : '',
          MainDemoName : ''
        }
          obj.OLAPKey = element.SchoolKey;
          obj.MainDemoName = element.SchoolKeyValue;
          arr.push(obj)
        });
        this.courseNameList = arr;
        this.courseNameList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
        console.log(this.courseNameList,'全部')
      });
    }
  }

};
</script>