<template>
  <div class="customer_table_record">
    <div class="customer_table_box">
      <div class="table_title">审批</div>
      <div class="table_name"><span class="font_gray">开户</span><span class="table_number font_blue button"
              @click.stop="openDialog(1)">{{Number(statisticsInfo.ExpeAccountCount)}}</span></div>
      <div class="table_name"><span class="font_gray">付款</span><span class="table_number font_blue button"
              @click.stop="openDialog(2)">{{Number(statisticsInfo.PayCount)}}</span></div>
      <div class="table_name"><span class="font_gray">介绍费</span><span class="table_number font_blue button"
              @click.stop="openDialog(3)">{{Number(statisticsInfo.ReturnCashCount)}}</span></div>
      <div class="table_name"><span class="font_gray">清数据</span><span class="table_number font_blue button"
              @click.stop="openDialog(4)">{{Number(statisticsInfo.ClearCount)}}</span></div>
      <div class="table_name"><span class="font_gray">导出</span><span class="table_number font_blue button"
              @click.stop="openDialog(12)">{{Number(statisticsInfo.ExportCount)||0}}</span></div>
      <div class="table_name"><span class="font_gray">续期</span><span class="table_number font_blue button"
              @click.stop="openDialog(13)">{{Number(statisticsInfo.RenewalCount)||0}}</span></div>
      <div class="table_name"><span class="font_gray">赠送流量</span><span class="table_number font_blue button"
              @click.stop="openDialog(14)">{{Number(statisticsInfo.FluxComboCount)||0}}</span></div>
      <div class="table_name"><span class="font_gray">提现</span><span class="table_number font_blue button"
              @click.stop="openDialog(15)">{{Number(statisticsInfo.WaitApprovalCount)||0}}</span></div>
    </div>
    <div class="customer_table_box">
      <div class="table_title">风控</div>
      <div class="table_name"><span class="font_gray">待签</span><span class="table_number font_red button"
              @click.stop="openDialog(5)">{{Number(statisticsInfo.ToBeSignedCount)}}</span></div>
      <div class="table_name"><span class="font_gray">尾款</span><span class="table_number font_red button"
              @click.stop="openDialog(6)">{{Number(statisticsInfo.BalanceCount)}}</span></div>
      <div class="table_name"><span class="font_gray">续费</span><span class="table_number font_red button"
              @click.stop="openDialog(7)">{{Number(statisticsInfo.RenewCount)}}</span></div>
      <div class="table_name"><span class="font_gray">考勤</span><span class="table_number font_red button"
              @click.stop="openDialog(8)">{{Number(statisticsInfo.AttendRate50Count)}}</span></div>
    </div>
    <div class="customer_table_box dual_box">
      <div class="table_title">新线索
        <span class="table_number font_red button"
              @click.stop="openDialog(11)">{{Number(statisticsInfo.NewTheradCount)||0}}</span>
      </div>
    </div>
    <div class="customer_table_box dual_box">
      <div class="table_title">回收站
        <span class="table_number font_red button"
              @click.stop="openDialog(9)">{{Number(statisticsInfo.AbandonCount)}}</span>
      </div>
    </div>
    <div class="customer_table_box dual_box">
      <div class="table_title">客户池
        <span class="table_number font_blue button"
              @click.stop="openDialog(10)">{{Number(statisticsInfo.TheradCount)}}</span>
      </div>
    </div>
    <div class="customer_table_box dual_box">
      <div class="table_title">在线人数
        <span class="table_number font_blue ">{{onLineNum}}</span>
      </div>
    </div>
    <!-- 开户-width="1080px; 介绍费-1380px; 清数据-1320; 付款-1420px;售前明细-1220px； -->
    <custom-dialog title="开户"
                   width="1160px"
                   :visible.sync="isShowOpenAccountDialog"
                   :before-close="closeAllDialog">
      <openAccountList @afterSuccess="afterSuccess"></openAccountList>
    </custom-dialog>
    <custom-dialog title="清数据"
                   width="1200px"
                   :visible.sync="isShowCleanDataDialog"
                   :before-close="closeAllDialog">
      <cleanDataList @afterSuccess="afterSuccess"></cleanDataList>
    </custom-dialog>
    <custom-dialog title="介绍费"
                   width="1200px"
                   :visible.sync="isShowIntroduceDialog"
                   :before-close="closeAllDialog">
      <introduceList @afterSuccess="afterSuccess"></introduceList>
    </custom-dialog>
    <custom-dialog title="付款"
                   width="1200px"
                   :visible.sync="isShowPaymentDialog"
                   :before-close="closeAllDialog">
      <paymentList @afterSuccess="afterSuccess"></paymentList>
    </custom-dialog>
    <custom-dialog title="导出"
                   width="1200px"
                   :visible.sync="isShowExportCountDialog"
                   :before-close="closeAllDialog">
      <exportCountList @afterSuccess="afterSuccess"></exportCountList>
    </custom-dialog>
    <custom-dialog title="续期"
                   width="1200px"
                   :visible.sync="isShowRenewDataDialog"
                   :before-close="closeAllDialog">
      <renew-info-list @afterSuccess="afterSuccess"></renew-info-list>
    </custom-dialog>
    <custom-dialog title="流量赠送"
                   width="1200px"
                   :visible.sync="isShowFlowGiveDialog"
                   :before-close="closeAllDialog">
      <flowgive-list @afterSuccess="afterSuccess"></flowgive-list>
    </custom-dialog>
    <custom-dialog title="提现"
                   width="1200px"
                   :visible.sync="isShowWithdrawalDialog"
                   :before-close="closeAllDialog">
      <withdrawal-list @afterSuccess="afterSuccess"></withdrawal-list>
    </custom-dialog>
    <custom-dialog title="售前客户明细表"
                   width="1370px"
                   :visible.sync="isShowSaleBeforeDetailDialog"
                   :before-close="closeAllDialog">
      <pre-sales-detail :searchParams="beforeSearchParams"
                        @afterSuccess="afterSuccess"></pre-sales-detail>
    </custom-dialog>
    <custom-dialog title="售后客户明细表"
                   width="1600px"
                   :visible.sync="isShowSaleAfterDetailDialog"
                   :before-close="closeAllDialog">
      <after-sale-detail :searchParams="afterSearchParams"
                         @afterSuccess="afterSuccess"></after-sale-detail>
    </custom-dialog>
  </div>
</template>
<script>
// clean-data-list  introduce-list  payment-list
import openAccountList from '../customer-management/components/form-components/accraditation-stream/open-account-list';
import cleanDataList from '../customer-management/components/form-components/accraditation-stream/clean-data-list';
import introduceList from '../customer-management/components/form-components/accraditation-stream/introduce-list';
import paymentList from '../customer-management/components/form-components/accraditation-stream/payment-list';
import exportCountList from '../customer-management/components/form-components/accraditation-stream/export-count-list';
import flowgiveList from '../customer-management/components/form-components/accraditation-stream/flowgive-list';
import withdrawalList from '../customer-management/components/form-components/accraditation-stream/withdrawal-list';
import renewInfoList from '../customer-management/components/form-components/accraditation-stream/renew-info-list';
import preSalesDetail from '../customer-management/pre-sales-documentary-list/pre-sales-detail';
import afterSaleDetail from '../customer-management/after-sale-documentary-list/after-sale-detail';
import { GetElementaryStatistics, GetOnlineNumber } from '../../../API/workbench';
const saleAfterQueryParams = {
  pageIndex: 0, //	整型	必须		数据源：GET	页码，0为第一页
  pageSize: 10, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
  orderBy: '', //	字符串	可选		数据源：GET	排序字段
  sequence: 'desc', //	字符串	可选		数据源：GET	排序字段
  searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
  IntentionKey: '', //	整型	必须		数据源：GET	意向1-S;2-A;3-B;4-C;
  FollowStartTime: '', //	字符串	可选		数据源：GET	跟进日期开始
  FollowEndTime: '', //	字符串	可选		数据源：GET	跟进日期结束
  DemoStartTime: '', //	字符串	可选		数据源：GET	演示日期开始
  DemoEndTime: '', //	字符串	可选		数据源：GET	演示日期结束
  StartDredgeTime: '', // 字符串	可选		数据源：GET	开户时间开始
  EndDredgeTime: '', // 字符串	可选		数据源：GET	开户时间结束
  StartExpireTime: '', // 续费日期起
  EndExpireTime: '', // 续费日期止
  DocumentaryKey: '', //	字符串	可选		数据源：GET	销售人id
  DocumentaryKeyValue: '',
  IsUseKey: '', //	字符串	可选		数据源：GET	是否在用 空-不生效;0-否;1-是
  IsGradeStarKey: '', //	字符串	可选		数据源：GET	是否为星 空-不生效;0-否;1-是
  IsGradeVKey: '', //	字符串	可选		数据源：GET	是否为V 空-不生效;0-否;1-是
  StartCount: '', //	整型	可选		数据源：GET	介绍起始数
  EndCount: '', //	整型	可选		数据源：GET	介绍终止数
  StartBargain: '', //	整型	可选		数据源：GET	成交起始数
  EndBargain: '', //	整型	可选		数据源：GET	成交起始数
  StartStudentCount: '', //	整型	可选		数据源：GET	学生起始数
  EndStudentCount: '', //	整型	可选		数据源：GET	学生起始数
  StayBack: '', //	整型	可选		数据源：GET	待返是否大于0 0-否;1-是
  IsBalance: '',  // 整形  可选,  是否尾款
  Renew: '', //	整型	可选		数据源：GET	续费是否小于30天 0-否;1-是
  AttenRate: '', //	整型	可选		数据源：GET	考勤率是否小于50% 0-否;1-是
  IsNewTheradKey: '',
  IsRestrictKey: 1, // 是否全部
  IsParent: ''
};
const saleBeforeQueryParams = {
  pageIndex: 0, //	整型	必须		数据源：GET	页码，0为第一页
  pageSize: 10, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
  orderBy: '', //	字符串	可选		数据源：GET	排序字段
  sequence: 'desc', //	字符串	可选		数据源：GET	排序字段
  searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
  IntentionKey: '', //	整型	必须		数据源：GET	意向1-S;2-A;3-B;4-C;
  IntentionKeyValue: '',
  StatusKey: '', //	整型	必须		数据源：GET	开户状态0-未开通;1-临时开通;2-正式开通
  FollowStartTime: '', //	字符串	可选		数据源：GET	跟进日期开始
  FollowEndTime: '', //	字符串	可选		数据源：GET	跟进日期结束
  DemoStartTime: '', //	字符串	可选		数据源：GET	演示日期开始
  DemoEndTime: '', //	字符串	可选		数据源：GET	演示日期结束
  IsUseKey: '', //	字符串	可选		数据源：GET	是否在用 空-不生效;0-否;1-是
  StartDredgeTime: '', // 字符串	可选		数据源：GET	开户时间开始
  EndDredgeTime: '', // 字符串	可选		数据源：GET	开户时间结束
  DocumentaryKey: '', //	字符串	可选		数据源：GET	销售人id
  DocumentaryKeyValue: '',
  IsAbandon: '', // 是否放弃
  IsRestrictKey: 1 // 是否全部
};
export default {
  data () {
    return {
      statisticsInfo: {
        ExpeAccountCount: 0, // 开户
        PayCount: 0,  // 付款
        ReturnCashCount: 0,   // 介绍费
        ClearCount: 0,  // 清数据
        ToBeSignedCount: 0,  // 待签
        BalanceCount: 0,  // 尾款
        RenewCount: 0,   // 续费
        AttendRate50Count: 0,  // 考勤
        AbandonCount: 0,  // 回收站
        TheradCount: 0   // 客户池
      },
      onLineNum: 0,
      isShowOpenAccountDialog: false, // 开户弹出框
      isShowCleanDataDialog: false, // 清数据弹出框
      isShowIntroduceDialog: false, // 转介绍弹出框
      isShowPaymentDialog: false, // 付款弹出框
      isShowExportCountDialog: false, // 导出弹出框
      isShowRenewDataDialog: false, // 续期弹出框
      isShowFlowGiveDialog: false, // 赠送流量弹出框
      isShowWithdrawalDialog: false, // 提现弹出
      isShowSaleAfterDetailDialog: false, // 售后明细弹出框
      isShowSaleBeforeDetailDialog: false, // 售前明细弹出框: ''
      afterSearchParams: null,
      beforeSearchParams: null
    };
  },
  components: {
    openAccountList,
    cleanDataList,
    introduceList,
    paymentList,
    exportCountList,
    flowgiveList,
    withdrawalList,
    renewInfoList,
    preSalesDetail,
    afterSaleDetail
  },
  created () {
    this.getStatisticsInfo();
  },
  mounted () {
    this.$bus.on(this, 'switchHeaderBarToManagementAndStatistics', () => {
      this.getStatisticsInfo();
    });
  },
  methods: {
    getStatisticsInfo () {
      GetElementaryStatistics().then(result => {
        this.statisticsInfo = result.data;
        console.log(result.data, 'GetElementaryStatistics');
      }).catch(error => {
        console.log(error, 'errorGetElementaryStatistics');
      });
      GetOnlineNumber().then(result => {
        this.onLineNum = Number(result.data.OnlineNumber);
      }).catch(error => {
        console.log(error, 'errorGetOnlineNumber');
      });
    },
    openDialog (type) {
      this.afterSearchParams = this.$utils.parseJson(saleAfterQueryParams);
      this.beforeSearchParams = this.$utils.parseJson(saleBeforeQueryParams);
      switch (type) {
        case 1:   // 开户
          this.isShowOpenAccountDialog = true;
          break;
        case 2:   // 付款
          this.isShowPaymentDialog = true;
          break;
        case 3:   // 介绍费
          this.isShowIntroduceDialog = true;
          break;
        case 4:   // 清数据
          this.isShowCleanDataDialog = true;
          break;
        case 5:   // 待签
          this.beforeSearchParams.StatusKey = 1;
          this.isShowSaleBeforeDetailDialog = true;
          break;
        case 6:   // 尾款
          this.afterSearchParams.IsBalance = 1;
          this.$nextTick(() => {
            this.isShowSaleAfterDetailDialog = true;
          });
          break;
        case 7:   // 续费
          this.afterSearchParams.Renew = 1;
          this.isShowSaleAfterDetailDialog = true;
          break;
        case 8:   // 考勤
          this.afterSearchParams.AttenRate = 1;
          this.afterSearchParams.IsParent = 1;
          this.isShowSaleAfterDetailDialog = true;
          break;
        case 9:   // 回收站
          this.beforeSearchParams.IsAbandon = 1;
          this.isShowSaleBeforeDetailDialog = true;
          break;
        case 10:  // 客户池
          this.afterSearchParams.IsAbandon = 0;
          this.isShowSaleAfterDetailDialog = true;
          break;
        case 11:  // 新线索
          this.beforeSearchParams.IsNewTheradKey = 1;
          this.isShowSaleBeforeDetailDialog = true;
          break;
        case 12:  // 导出
          this.isShowExportCountDialog = true;
          break;
        case 13:  // 续期
          this.isShowRenewDataDialog = true;
          break;
        case 14:  // 流量赠送
          this.isShowFlowGiveDialog = true;
          break;
        case 15:  // 提现
          this.isShowWithdrawalDialog = true;
          break;
        default:
          break;
      }
    },
    // 关闭弹出层
    closeAllDialog () {
      this.isShowOpenAccountDialog = false; // 开户弹出框
      this.isShowCleanDataDialog = false;  // 清数据弹出框
      this.isShowIntroduceDialog = false;  // 转介绍弹出框
      this.isShowPaymentDialog = false;  // 付款弹出框
      this.isShowExportCountDialog = false;
      this.isShowRenewDataDialog = false; // 续期弹出框
      this.isShowFlowGiveDialog = false; // 流量赠送弹出框
      this.isShowWithdrawalDialog = false; // 提现弹出
      this.isShowSaleBeforeDetailDialog = false;   // 售前弹出
      this.isShowSaleAfterDetailDialog = false;   // 售前弹出
    },
    afterSuccess () {
      this.$emit('afterSuccess');
      this.getStatisticsInfo();
    }
  }
};
</script>
