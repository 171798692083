<template>
  <div class="form_list">
    <!-- required -->
    <div class="form_list_name">
      <span class="text_box">加盟扶持</span>
    </div>
    <div class="form_list_content">
      <list-box v-for="(listData,index) in dataInfo"
                :key="index"
                :listData="listData"
                @clickItem="clickItem"></list-box>
    </div>
  </div>
</template>

<script>
import listBox from './list-box'
export default {
  data() {
    return {
      dataInfo: [],
    }
  },
  props: {
    franchiseSupportData: String,
  },
  components: {
    listBox,
  },
  created() {
    console.log('this.franchiseSupportData', this.franchiseSupportData)
    this.dataInfo = JSON.parse(this.franchiseSupportData)
    console.log('this.franchiseSupportData', this.dataInfo)
  },
  methods: {
    clickItem() {
      this.$emit('updateFranchiseSupportData', this.dataInfo)
    },
  },
}
</script>

<style>
</style>