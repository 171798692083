<template>
  <div>
    <list ref="detailList"
          :listTitle="listTitle"
          :extendSearchCondition="vPopupTableParams.extendSearchCondition"
          :functionBtnGroup="functionBtnGroup"
          :tableData="vPopupTableParams.tableData"
          :totalNum="totalNum"
          :PageCount="PageCount"
          :tableColumns="vPopupTableParams.tableColumns"
          @actionBtnClick="doAfterFunctionBtnClick"
          @tableBtnClick="doAfterTableBtnClick"
          @loadTableData="loadTableData"></list>
  </div>
</template>
<script>
import list from '../activity-list';
import { BrowseRrewardsDetails, ParentsPromoteDistributionIncentives, BrowseGiftDistribution } from '../../../API/workbench.js';
let tableFunctionType = { fanxian: 'fanxian', fanhour: 'fanhour', fangift: 'fangift' };// 几个弹出层列表tabFunction 类型的初始化
export default {
  name: 'browseDetailList',
  data () {
    return {
      listTitle: '',
      totalNum: 0, // 返回的总记录数
      PageCount: 0, // 页数
      pageSize: 0, // 查询记录数
      // 浏览奖励明细列表
      LLJLExtendSearchCondition: [], // 扩展查询参数
      LLJLTableColumn: [ //  列
        { label: '最近浏览时间', prop: 'MaxOpenTime', width: 280, sortable: 'custom', align: 'left', isShow: true, type: 'date', decimalPlace: 0 },
        { label: '浏览人', prop: 'username', width: 280, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0, title: 'telephone', toolTip: '{{telephone}}' },
        { label: '浏览次数', prop: 'TotalNum', width: 200, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0, icon: 'fa fa-qrcode' },
        { label: '是否报名', prop: 'isapply', width: 280, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0 }
      ],
      LLJLTableData: [], //  数据
      functionBtnGroup: [] // 功能按钮
    };
  },
  props: ['selectedCouponInfo'],
  components: {
    list
  },
  computed: {
    // 弹出层列表参数
    vPopupTableParams () {
      let tableParams = {};
      tableParams.extendSearchCondition = this.LLJLExtendSearchCondition;
      tableParams.tableColumns = this.LLJLTableColumn;
      tableParams.tableData = this.LLJLTableData;
      return tableParams;
    }
  },
  methods: {
    // 加载浏览奖励明细
    loadBrowseRewardDetailList (queryParams) {
      console.log('loadBrowseRewardDetailList: queryParams', queryParams);
      BrowseRrewardsDetails(queryParams).then(result => {
        console.log('loadBrowseRewardDetailList: result.data', result.data);
        this.LLJLTableData = [];
        if (result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach((obj, index) => {
            if (obj.username) {
              obj.username = obj.username + '/' + this.$utils.phoneModulePower(obj.telephone);
            }
            this.LLJLTableData.push(obj);
          });
          this.totalNum = result.data.Total;
          this.PageCount = result.data.PageCount;
          this.currentPage = queryParams.PageIndex;
        } else {
          this.totalNum = 0;
        }
        this.$refs.detailList.isLoading = false;
      }, error => {
        layer.alert(error.data.msg);
        this.$refs.detailList.isLoading = false;
      });
    },
    // 加载弹出层列表数据
    loadTableData (queryParams) {
      this.$refs.detailList.isSearch = true;
      this.$refs.detailList.isLoading = true;
      console.log('TeacherCommissionDetails', queryParams);
      this.loadBrowseRewardDetailList(queryParams);
    },
    // // 浏览礼品发放   用户券id id UserCouponKey
    // browseGiftDistribution (rowData) {
    //   console.log('browseGiftDistribution', rowData);
    //   BrowseGiftDistribution(rowData.id).then(result => {
    //     console.log('BrowseGiftDistribution', result);
    //     // this.browseRewardData.list
    //     // 符合的当前下标
    //     let target = this.browseRewardData.list.findIndex(o => {
    //       return o.id == rowData.id;
    //     });
    //     // 符合的当前对象
    //     let targetItem = this.browseRewardData.list.find(o => {
    //       return o.id == rowData.id;
    //     });
    //     targetItem.read_gift_status = 3;
    //     // 替换/
    //     this.browseRewardData.list.splice(target, 1, targetItem);
    //   }, error => {
    //     layer.alert(error.msg);
    //   });
    // },
    // 点击table 的详情
    doAfterClickDetail (item, eventName) {
      // if (eventName == tableFunctionType.fangift) {
      //   console.log(item, 'tableFunctionType.fangift', eventName);
      //   if (item.read_gift_status == 2) {
      //     this.browseGiftDistribution(item);
      //   }
      //   return;
      // }
      console.log(item, 'doAfterClickDetail', eventName);
      let initSelectedParams = [];
      let unClearParams = [];
      this.listTitle = '浏览奖励';
      initSelectedParams.push({ name: 'id', value: this.selectedCouponInfo.id, unClear: true}, { name: 'clientid', value: item.uid, unClear: true});// uid: 家长id
      unClearParams.push({ name: ' 转发人', value: item.username});
      this.$refs.detailList.isShowListDialog = true;
      this.$refs.detailList.initSelectedParams(initSelectedParams, unClearParams);
    },
    // 点击 弹出层列表的按钮
    doAfterTableBtnClick (rowData, eventName) {
      let type = 0;
      switch (eventName) {
        case tableFunctionType.fanxian:
          if (rowData.is_fafang_fanxian == 1) {
            return;
          }
          type = 1;
          break;
        case tableFunctionType.fanhour:
          if (rowData.is_fafang_fanhour == 1) {
            return;
          }
          type = 2;
          break;
        case tableFunctionType.fangift:
          if (rowData.is_fafang_fangift == 1) {
            return;
          }
          type = 3;
          break;
        default:
          break;
      }
      console.log(rowData, 'rowData');
    // 问题/
      this.parentsPromoteDistributionIncentives(rowData, type);
    },
    showTeacherList () {
      this.$refs.detailList.isShowListDialog = true;
    },
    parentsPromoteDistributionIncentives (rowData, type) {
      ParentsPromoteDistributionIncentives(rowData.id, type).then(result => {
        console.log(this.LSTCTableData, 'LSTCTableData1');
        console.log(this.FXJLTableData, 'FXJLTableData111');
        this.FXJLTableData = this.utils.parseJson(this.chgArrayInfo(this.FXJLTableData, rowData, type));
        this.LSTCTableData = this.utils.parseJson(this.chgArrayInfo(this.LSTCTableData, rowData, type));
        console.log(this.LSTCTableData, 'LSTCTableData222');
        console.log(this.FXJLTableData, 'FXJLTableData2222');
        layer.alert(result.msg);
      }, error => {
        layer.alert(error.msg);
      });
    },
  // 点击 弹出层列表的右上角按钮
    doAfterFunctionBtnClick () {

    }
  }

};
</script>
