<template>
  <!-- 学生关联档案 2 和 3 -->
  <div class="association_student_form">
    <div class="association_div">
      <!-- 学生信息 -->
      <div style="background: #fff;">
        <div style="padding: 20px 0;border-bottom: 1px solid #ccc;">
          <span style="font-size: 20px;">{{ threadInfo.StudentKeyValue }}</span> <span>{{ threadInfo.MobilePhone }}</span>
        </div>
        <div v-if="!isAssociationTwoOrThree"
             style="padding: 15px 10px;margin-top: 10px;background: #f59a23;">
          学生档案已存在，请确认是否同一学生？如果不是，请返回并更改 改线索的学生姓名、手机号
        </div>
        <div v-else
             style="padding: 15px 10px;margin-top: 10px;background: #f59a23;">
          手机号已存在，请确认是否同一手机号下的不同学生？
        </div>
      </div>
      <div>
        <student-lable class="content_marginTop_30 single_shadow content_marginRL association_student_content"
                       :studentID="dataInfo.OLAPKey"
                       :MobliePhone="threadInfo.MobilePhone"
                       :StudentKeyValue="threadInfo.StudentKeyValue"
                       :isAssociationTwoOrThree="isAssociationTwoOrThree"
                       :readonly="readOnly"
                       :switchItem="switchItem"
                       :IsConfirmKey="IsConfirmKey"
                       :showExamine="showExamine"
                       :showMenu="showMenu"
                       :showSwitchBut="showSwitchBut"
                       :IsRecycleKey="IsRecycleKey"
                       :showPrint="showPrint"
                       @alterStudent="alterStudent"></student-lable>
      </div>

      <div class="mendian_backstage_search">
        <div class="btn_hover_bg_blue search_btn "
             @click.stop="confirmClick(1)">
          是同一个学生，直接与这个学生档案关联
        </div>
      </div>
      <div v-if="isAssociationTwoOrThree"
           class="mendian_backstage_search">
        <div class="btn_hover_bg_blue search_btn "
             @click.stop="confirmClick(2)">
          同一手机号下的不同学生，建立新学生档案
        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <div>
      <button-single-comfire-btn :btnText="'取消'"
                                 :btnClass="'popup_confirm_btn'"
                                 @click="cancelClick"></button-single-comfire-btn>
    </div>
    <!-- 图1 -->
    <custom-dialog :title="'关联学生档案'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowAssociationStudentOne"
                   :before-close="doShowApplicationHide">
      <clew-add-form :threadInfo="threadInfo"
                     :isPopupShow="true"
                     @afterSuccess="afterSuccess">
      </clew-add-form>
    </custom-dialog>
  </div>

</template> 

<script>
import { GetStudentDetailList, BindingStudentThread, GetStudentCommonMsgTwo, messageSearchPageForStudent } from '../../../API/workbench';
import studentLable from '../../common/student-lable.vue';
import clewAddForm from '../../student/clew-add-form';
export default {
  props: {// 是否显示表单
    threadInfo: Object,
    isAssociationTwoOrThree: {// false 图2   true 图3
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isShowAddBtn: {
      type: Boolean,
      default: true
    },
    switchItem: { // 选择未验证学生是否提示，1为不提示 (可选)
      type: [String, Number],
      default: 0
    },
    IsConfirmKey: {// 0:显示全部学生， 1：过滤掉待验证的学生 (可选)
      type: [String, Number],
      default: 0
    },
    showExamine: {
      type: Boolean,
      default: true
    },
    showMenu: { type: Boolean, default: true },
    showSwitchBut: {
      type: Boolean,
      default: false
    },
    IsRecycleKey: { // 该学生是否回收1-是;0-否
      type: Boolean,
      default: false
    },
    showPrint: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isShowAssociationStudentOne: false, // 关联学生档案 1
      dataInfo: {},
      pageIndex: 0,
      pageSize: 20,
      searchText: '',
      GradeClassKey: '',
      CourseKey: '',
      CustStatusKey: '',
      SubscribeCourseKey: ''
    };
  },
  components: {
    studentLable,
    clewAddForm
  },
  computed: {
  },
  created () {
    if (!this.isAssociationTwoOrThree) {
      this.getGetStudentCommonMsgTwo();
    } else {
      this.getStudentList();
    }
    console.log(this.threadInfo, 'threadInfo isAssociationTwoOrThree', this.isAssociationTwoOrThree);
  },

  methods: {
    getStudentList () {
      console.log(this.IsConfirmKey, 'this.IsConfirmKey');
      messageSearchPageForStudent(this.pageIndex, this.pageSize, this.studenSearchText, '', '', this.CustStatusKey, this.SubscribeCourseKey, this.threadInfo.MobilePhone).then(result => {
        this.dataInfo = result.data.PageDataList[0];
        console.log('messageSearchPageForStudent', result.data);
      }).catch(error => {
        console.log(error);
      });
    },
    // 切换学生
    alterStudent (item) {
      this.dataInfo = item;
      console.log(item, 'alterStudent qqq dataInfo', this.dataInfo);
    },
    getGetStudentCommonMsgTwo () {
      GetStudentCommonMsgTwo(this.threadInfo.StudentKeyValue).then(result => {
        this.dataInfo = result.data;
        console.log('GetStudentCommonMsgTwo', result.data);
      }).catch(error => {
        console.log(error, 'GetStudentCommonMsgTwo');
      });
    },
    // 关闭报名收款   关联学生档案 1图
    doShowApplicationHide () {
      this.isShowAssociationStudentOne = false;
    },
    afterSuccess () {
      this.isShowAssociationStudentOne = false;
      console.log(this.isShowAssociationStudentOne, 'this.isShowAssociationStudentOne 1', this.dataInfo);
      setTimeout(() => {
        this.$emit('afterSuccess');
      }, 100);
    },
    // 确认按钮
    confirmClick (type) {
      if (type == 1) {
        //
        layer.confirm('确定是否关联?', {
          btn: [{
            name: '确认',
            callBack: () => {
              layer.close();
              BindingStudentThread(this.threadInfo.StudentThreadKey, this.dataInfo.MainDemoName, this.dataInfo.OLAPKey).then(result => {
                console.log('tBindingStudentThread 1', this.dataInfo);
                layer.alert('关联成功');
                this.$emit('afterSuccess', this.dataInfo.OLAPKey);
              }, error => {
                layer.alert(error.msg);
              });
            }
          }
          ]
        });
      } else {
        this.isShowAssociationStudentOne = true;
      }
    },
    // 取消按钮
    cancelClick () {
      this.$emit('cancelClick');
    }
  }
};
</script>

  <style scoped>
.association_div {
  margin: 0 10px 20px 10px;
  min-height: 600px;
}
.btn_hover_bg_blue {
  width: 100% !important;
  height: 45px !important;
  line-height: 45px !important;
}
.mendian_backstage_search {
  margin: 20px 0px !important;
}
.association_student_content
  >>> .student_courses_arranging_info
  .student_label {
  margin-top: 20px;
  border-bottom: none !important;
  background: #f6f8f9;
  border-radius: 4px;
  padding: 10px 10px !important;
}
</style>