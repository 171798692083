<!-- 本月课件使用课次模块 -->
<template>
  <div class="attend-class-list">
    <div class="credit_management_popup">
      <!-- 头部 -->
      <div class="HQflow_packet_total">
        <div class="HQflow_Bill_date">
          <!-- 选择年月 -->
          <div class="date_time pr">
            <div class="new_table_title_date left_type">
              <div @click.stop="searchLast()"
                   class="date_last fl"></div>
              <div class="cw_date_calendar">
                <hqcourseware-year-bill @chgDate="chgDate"
                                        :type="'month'"
                                        style="opacity: 0;"></hqcourseware-year-bill>
              </div>
              <span class="date_text">{{yearView}}&nbsp;年&nbsp;{{monthView}}&nbsp;月</span>
              <div @click.stop="searchNext()"
                   class="date_next fr"></div>
            </div>
          </div>
          <div class="buy_con">
            <!-- <div class="buy_flow">
              <div>{{this.MonthCoursewareList.CoursewareCount}}</div>
              <div>选班上课课次</div>
            </div>
            <div class="buy_flow">
              <div>{{this.MonthCoursewareList.CoursewareTimeCount}}</div>
              <div>选班上课时长</div>
            </div>
            <div class="buy_flow">
              <div>{{this.MonthCoursewareList.ScheduleCourseCoursewareTimeCount}}</div>
              <div>选班上课课件时长</div>
            </div>
            <div class="buy_flow">
              <div>{{this.MonthCoursewareList.ConsumedFlowCount}}</div>
              <div>选班上课课件流量</div>
            </div> -->
            <div class="buy_flow">
              <div>{{this.MonthCoursewareList.PreviewCount}}</div>
              <div>上课次数</div>
            </div>
            <div class="buy_flow">
              <div>{{this.MonthCoursewareList.PreviewTimeCount}}</div>
              <div>上课课件时长</div>
            </div>
            <div class="buy_flow">
              <div>{{this.MonthCoursewareList.PreviewConsumedFlowCount}}</div>
              <div>上课课件流量</div>
            </div>
            <div class="buy_flow">
              <div>{{this.MonthCoursewareList.FlowNumberCount}}</div>
              <div>扣费流量</div>
            </div>
          </div>
        </div>
      </div>
      <div class="popup_title_padding view_title_flex"
           style="justify-content: inherit;">

        <div class="search_item attend_icon"
             style="margin-right: 35px;width:240px">
          <elSelectOption :selectTitle="'校区类型'"
                          @changeEvent="chooseType"
                          :selectionKey="searchObj.CampusTypeKey"
                          :dataSourceList="campusTypeList">
          </elSelectOption>
        </div>
        <div class="width_small course_statistics_search">

          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入'"
                                    :clearAll="true"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="search"></input-search-contain-btn>
        </div>
      </div>
      <div class="credit_management_table">
        <table-list class="summarizing_list table_list_content"
                    :tableData="vdataReportList"
                    :tableColumns="tableCourseColumns"
                    :totalNum="totalNum"
                    :PageCount="PageCount"
                    :queryParams="searchObj"
                    :footerContent="vFooterTotal"
                    @loadTableData="getDataList"></table-list>
      </div>
      <dialog-factory ref="coursewareClassList"
                      :routerName="'coursewareClassList'"></dialog-factory>
      <dialog-factory-common ref="coursewareClassList"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import elSelectOption from '../../../../report/common/el-select-option';
import {
  SearchPageCoursewareUseBillList,
  CoursewareUseBillSummaryForMonth
} from '../../../../../API/workbench';
import inputSearchContainBtn from '../../../../report/common/input-search-contain-btn';
import tableList from '../../../../common/table-list/index';
import dialogFactory from '../../../../common/dialog-factory';
import hqcoursewareYearBill from '../hqcourseware-year-bill.vue';
export default {
  components: {
    inputSearchContainBtn,
    tableList,
    elSelectOption,
    dialogFactory,
    hqcoursewareYearBill
  },
  data () {
    return {
      monthView: '',
      yearView: '',
      startDate: this.$utils.getCurrentMonthFirst(),
      endDate: this.$utils.getCurrentMonthLast(),
      campusTypeList: [
        // 校区类型
        {
          OLAPKey: 0,
          MainDemoName: '全部'
        },
        {
          OLAPKey: 1,
          MainDemoName: '直营店'
        },
        {
          OLAPKey: 2,
          MainDemoName: '加盟店'
        },
        {
          OLAPKey: 3,
          MainDemoName: '课程合作'
        }
      ],
      MonthCoursewareList: {
        CoursewareCount: '', // 上课课次
        CoursewareTimeCount: '', // 上课时长
        ScheduleCourseCoursewareTimeCount: '', // 上课课件时长
        ConsumedFlowCount: '', // 上课课件流量
        PreviewCount: '', // 预览次数
        PreviewTimeCount: '', // 预览课件时长
        PreviewConsumedFlowCount: '', // 预览课件流量
        FlowNumberCount: ''// 扣费流量
      },
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '', //	字符串	可选		数据源：GET	排序字段 为空则默认为课程名
        sequence: '', //	字符串	可选	asc	数据源：GET	排序方向 ASC正序 DESC 倒序
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配 课程名
        startTime: '', //	字符串	可选		数据源：GET	起始日期
        endTime: '', //	字符串	可选	0	数据源：GET	截止日期
        // CampusKey: 0, //	整型	可选	0	数据源：GET	校区
        CampusTypeKey: '', // 校区类型: 1-直营店;2-加盟店;3-课程合作
        ClientKey: '',
        MainDemoName: ''

      },
      dataReportList: [],
      tableData: {},
      totalNum: 0,
      PageCount: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          width: 90,
          prop: 'Number',
          align: 'left',
          type: 'tableIndex',
          sortable: false
        },
        {
          label: '校区',
          prop: 'MainDemoName',
          minWidth: 60,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        // {
        //   label: '选班上课课次',
        //   prop: 'CoursewareCount',
        //   width: 120,
        //   align: 'left',
        //   className: '',
        //   type: 'textItem'
        // },
        // {
        //   label: '选班上课课件时长',
        //   prop: 'CoursewareTimeCount',
        //   width: 140,
        //   align: 'left',
        //   type: 'textItem'
        // },
        // {
        //   label: '选班上课课件流量',
        //   prop: 'ConsumedFlowCount',
        //   width: 140,
        //   type: 'textItem'
        // },
        {
          label: '上课次数',
          prop: 'PreviewCount',
          width: 120,
          type: 'textItem'
        },
        {
          label: '上课课件时长',
          prop: 'PreviewTimeCount',
          width: 140,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '上课课件流量',
          prop: 'PreviewConsumedFlowCount',
          width: 140,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '扣费流量',
          prop: 'FlowNumberCount',
          width: 140,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          width: 100,
          align: 'center ',
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  click: (rowData) => {
                    this.doAfterTableBtnClick(rowData);
                  }
                },
                ModulePowerKey: 0
              }

            ]
          }
        }
      ]
    };
  },
  props: {
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    }
  },
  watch: {},
  created () {
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'coursewareClassList') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      console.log('openCommonCustomDialog', dialogInfo);
      if (dialogInfo.routerName == 'coursewareClassList') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.atTimeView(new Date());
    this.searchDate();
  },
  mounted () {
    console.log(this.searchObj, 'this.searchObj.EndTime ');
    // this.getDataList();
  },
  methods: {
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.coursewareClassList) {
        this.$refs.coursewareClassList.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.coursewareClassList) {
        this.$refs.coursewareClassList.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    },
    // 当前时间
    atTimeView (date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month < 10) { month = '0' + month; }
      this.yearView = year;
      this.monthView = month;
    },
    // 搜索上一个月
    searchLast () {
      this.monthView--;
      if (this.monthView < 10) { this.monthView = '0' + this.monthView; }
      if (this.monthView < 1) { this.monthView = 12; this.yearView = this.yearView - 1; }
      this.searchDate();
    },
    // 搜索下一个月
    searchNext () {
      this.monthView++;
      if (this.monthView < 10) { this.monthView = '0' + this.monthView; }
      if (this.monthView > 12) { this.monthView = '01'; this.yearView = this.yearView + 1; }
      this.searchDate();
    },
    // 选择某一天
    chgDate (val, startTime, endTime) {
      // this.seletedDate = val;
      console.log(val, startTime, endTime, '点击当前汇总数据');
      let date = Date.new(startTime);
      this.atTimeView(date);
      this.startDate = startTime.replace(/\//g, '-');
      this.endDate = endTime.replace(/\//g, '-');
      this.searchObj.startTime = this.startDate;
      this.searchObj.endTime = this.endDate;
      console.log(this.searchObj.startTime, this.searchObj.endTime, 'this.searchObj.endTime');
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 搜索日期
    searchDate () {
      this.searchObj.pageIndex = 0;
      // 开始日期
      this.startDate = this.yearView + '-' + this.monthView + '-' + '01';
      // 结束日期
      this.endDate = this.yearView + '-' + this.monthView + '-' + this.mGetDate(this.yearView, this.monthView);
      this.chgDate('', this.startDate, this.endDate);
    },
    mGetDate (year, month) {
      var d = Date.new(year, month, 0);
      return d.getDate();
    },
    // 选择校区状态
    chooseType (item) {
      this.searchObj.CampusTypeKey = item.OLAPKey;
      console.log(this.searchObj, 'this.searchObj');
      console.log(item, 'item');
      this.search();
    },
    // 查看详情
    doAfterTableBtnClick (item) {
      // layer.alert('该功能未开通');
      console.log(item, 'item');
      let obj = {
        StartTime: this.searchObj.startTime,
        EndTime: this.searchObj.endTime,
        SchoolKey: item.ClientKey,
        SchoolKeyValue: item.MainDemoName
      };
      console.log(obj, 'obj');
      this.$dialog.open(this, {
        name: item.MainDemoName + ' - 课件使用账单',
        routerName: this.$route.name,
        moduleName: 'coursewareUseReport',
        dialogWidth: '1000px',
        ModulePowerKey: '',
        data: { dataInfo: obj }
      });
    },
    setFluxNumber (size) {
      size = Number(size);
      if (size == 0) {
        return 0 + 'KB';
      }
      let kb = 1024;
      let unit = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
      let i = Math.floor(Math.log(Math.abs(size)) / Math.log(kb));
      return (size / Math.pow(kb, i)).toPrecision(3) + ' ' + unit[i];
    },

    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    clearSearchCondition () {
      this.searchObj.CampusTypeKey = '';
      this.searchObj.pageIndex = 0;
      this.search();
    },
    getDataList () {
      SearchPageCoursewareUseBillList(this.searchObj).then(
        (res) => {
          console.log('GetGiveLectureStat', res.data);
          this.totalNum = res.data.Total;
          this.dataReportList = res.data.PageDataList;
          this.dataReportList.forEach((o) => {
            o.CoursewareTimeCount = Number(o.CoursewareTimeCount) == 0 ? '-' : Number(o.CoursewareTimeCount);
            o.PreviewTimeCount = Number(o.PreviewTimeCount) == 0 ? '-' : Number(o.PreviewTimeCount);
            o.ConsumedFlowCount = Number(o.ConsumedFlowCount) < 0 ? this.$utils.flowFormate(Math.abs(Number(o.ConsumedFlowCount)))
              : Number(o.ConsumedFlowCount) == 0 ? '-' : this.$utils.flowFormate(Number(o.ConsumedFlowCount));
            o.PreviewConsumedFlowCount = Number(o.PreviewConsumedFlowCount) < 0 ? this.$utils.flowFormate(Math.abs(Number(o.PreviewConsumedFlowCount)))
              : Number(o.PreviewConsumedFlowCount) == 0 ? '-' : this.$utils.flowFormate(Number(o.PreviewConsumedFlowCount));
            o.FlowNumberCount = Number(o.FlowNumberCount) < 0 ? this.$utils.flowFormate(Math.abs(Number(o.FlowNumberCount)))
              : Number(o.FlowNumberCount) == 0 ? '-' : this.$utils.flowFormate(Number(o.FlowNumberCount));
            o.CoursewareCount = Number(o.CoursewareCount) == 0 ? '-' : Number(o.CoursewareCount);
            o.PreviewCount = Number(o.PreviewCount) == 0 ? '-' : Number(o.PreviewCount);
            this.searchObj.MainDemoName = o.MainDemoName;
            this.searchObj.ClientKey = o.ClientKey;
          });
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
      CoursewareUseBillSummaryForMonth(this.searchObj.startTime, this.searchObj.endTime).then((result) => {
        console.log('CoursewareUseBillSummaryForMonth', result.data);

        this.MonthCoursewareList = result.data;
        this.MonthCoursewareList.CoursewareCount = Number(result.data.CoursewareCount) == 0 ? '-' : Number(result.data.CoursewareCount);
        this.MonthCoursewareList.CoursewareTimeCount = Number(result.data.CoursewareTimeCount) == 0 ? '-' : Number(result.data.CoursewareTimeCount);
        this.MonthCoursewareList.ScheduleCourseCoursewareTimeCount = Number(result.data.ScheduleCourseCoursewareTimeCount) == 0 ? '-' : Number(result.data.ScheduleCourseCoursewareTimeCount);

        this.MonthCoursewareList.ConsumedFlowCount = Number(this.MonthCoursewareList.ConsumedFlowCount) < 0 ? this.$utils.flowFormate(Math.abs(Number(this.MonthCoursewareList.ConsumedFlowCount)))
          : Number(this.MonthCoursewareList.ConsumedFlowCount) == 0 ? '-' : this.$utils.flowFormate(Number(this.MonthCoursewareList.ConsumedFlowCount));

        this.MonthCoursewareList.PreviewCount = Number(result.data.PreviewCount) == 0 ? '-' : Number(result.data.PreviewCount);
        this.MonthCoursewareList.PreviewTimeCount = Number(result.data.PreviewTimeCount) == 0 ? '-' : Number(result.data.PreviewTimeCount);

        this.MonthCoursewareList.PreviewConsumedFlowCount = Number(this.MonthCoursewareList.PreviewConsumedFlowCount) < 0 ? this.$utils.flowFormate(Math.abs(Number(this.MonthCoursewareList.PreviewConsumedFlowCount)))
          : Number(this.MonthCoursewareList.PreviewConsumedFlowCount) == 0 ? '-' : this.$utils.flowFormate(Number(this.MonthCoursewareList.PreviewConsumedFlowCount));

        this.MonthCoursewareList.FlowNumberCount = Number(this.MonthCoursewareList.FlowNumberCount) < 0 ? this.$utils.flowFormate(Math.abs(Number(this.MonthCoursewareList.FlowNumberCount)))
          : Number(this.MonthCoursewareList.FlowNumberCount) == 0 ? '-' : this.$utils.flowFormate(Number(this.MonthCoursewareList.FlowNumberCount));
        console.log('this.MonthCoursewareList', this.MonthCoursewareList);
      })
        .catch((error) => {
          console.log('errorCoursewareUseBillSummaryForMonth', error);
        });
    }
  }
};
</script>
<style scoped>
.HQflow_packet_total {
  line-height: 20px;
  background-size: 30px;
  margin: 0 auto;
  width: 97%;
}
.HQflow_Bill_date {
  box-sizing: border-box;
  height: 100px;
  margin: 5px auto;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #f1f7ff, #f1f7ff),
    linear-gradient(0deg, #f5f8ff, #f5f8ff), #f9f9fb;
  border: 1px solid #ececec;
  border-radius: 4px;
}
.HQflow_Bill_date >>> .date_time {
  padding-top: 0px;
}
.date_time .new_table_title_date.left_type {
  font-size: 14px;
  width: 160px;
  position: absolute;
  cursor: pointer;
  top: 8px;
  left: 570px;
}
.date_time .new_table_title_date.left_type .date_last,
.date_time .new_table_title_date.left_type .date_next {
  width: 30px;
  height: 30px;
  background-size: 5px;
}
.date_time .new_table_title_date .date_last {
  background: url(../../../../../../public/image/change_prev_defult.png)
    no-repeat center center;
}
.date_time .new_table_title_date .date_last:hover {
  background-image: url(../../../../../../public/image/change_prev_light.png);
}
.date_time .new_table_title_date .date_next {
  background: url(../../../../../../public/image/change_next_defult.png)
    no-repeat center center;
}
.date_time .new_table_title_date .date_next:hover {
  background-image: url(../../../../../../public/image/change_next_light.png);
}
.date_time .date_text {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.date_time .cw_date_calendar {
  width: 117px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  top: -1px;
  left: 24px;
}
single-year-picker {
  padding-left: 8px;
  padding-right: 9px;
}
.buy_con {
  display: flex;
  justify-content: space-around;
  margin-top: 46px;
}
.buy_flow {
  flex: 1;
  text-align: center;
  border-right: 1px solid #ececec;
}
.buy_flow div {
  height: 20px;
}
.buy_flow div:nth-of-type(1) {
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
}
.buy_flow div:nth-of-type(2) {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  margin-top: 4px;
}
.buy_flow:nth-of-type(8) {
  border-right: none;
}
.span-classpa {
  margin-right: 10px;
  color: #666;
  white-space: nowrap;
  text-align: center;
  display: block;
  float: left;
  margin-top: 11px;
}
.attend-class-list {
  width: 1380px;
  margin: 0 auto;
  margin-top: 25px;
  background: #fff;
  border-radius: 8px;
  padding-top: 10px;
  margin-bottom: 25px;
}
.attend-class-list >>> .form_info_field {
  line-height: 35px;
}
.form_info_line {
  padding: 0px;
  min-height: 36px;
}
.attend-class-list >>> .form_info_value.form_info_select input {
  color: #333333;
  margin-top: 2px;
}

.attend-class-list >>> .form_info_value {
  border: 1px solid #ececec;
}
.attend-class-list >>> .form_info_value input {
  text-align: left;
}
.attend-class-list >>> .form_info_line {
  border-bottom: 0px solid #ececec;
}
.attend-class-list >>> .el-input__inne {
  color: #333333;
}
.attend-class-list >>> .el-table__row {
  height: 60px;
}
.attend_icon {
  position: relative;
}
</style>