<template>
  <div class="label_bottom_content_material">
    <evaluation-item v-for="(item,key) in dataList"
                     :key="key"
                     :item="item"
                     @releaseComment="releaseComment"></evaluation-item>
  </div>
</template>
<script>
import evaluationItem from './evaluation-item';
export default {
  data () {
    return {

    };
  },
  props: {
    dataList: Array
  },
  components: {
    evaluationItem
  },
  methods: {
    releaseComment (item) {
      this.$emit('releaseComment', item);
    }
  }
};
</script>

