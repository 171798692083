<template>
  <div class="publish_accessory_audio">
    <div class="course_teaching_plan_voice"
         @click.stop="clickAudio"
         :class="{open_type:audio.isPlaying}">
      <div class="reset_audio_box">
        <audio controls
               preload="auto"
               name="media"
               class="audio audio-file"
               @timeupdate="handleAudioTimeUpdated"
               @loadedmetadata="onLoadedmetadata"
               id="audio-file">
          <source :src="vAudioUrl"
                  type="audio/wav">
        </audio>
      </div>
      <span>{{vRecorderDuration}}</span>
      <!-- <div class="body_audio_btn"
           @click.stop="clickAudio"></div> -->
      <div class="publish_accessory_audio_delete"
           @click.stop="deleteAudio"
           v-if="isShowDeleteBtn"></div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      audioObj: null, // 音频对象
      playDurationTimeout: null, // 播放进度自增1
      audio: {
        isPlaying: false, // 该字段是音频是否处于播放状态的属性
        currentTime: 0, // 音频当前播放时长
        recorderDuration: 0, // 音频最大播放时长
        minTime: 0,
        step: 0.1
      }
    };
  },
  props: {
    audioUrl: String,
    isShowDeleteBtn: { // 是否显示删除按钮
      type: Boolean,
      default: false
    },
    recorderTime: [Number, String] // 音频时长
  },
  computed: {
    vAudioUrl () {
      console.log("vAudioUrl",this.$store.getters.CDNURL+this.audioUrl)
      return this.$store.getters.CDNURL+ this.audioUrl;
    },
    vRecorderDuration () {
      let min = '00';
      let sec = '00';
      if (this.recorderTime > 0) {
        min = Math.floor(this.recorderTime / 60);
        // min = min < 10 ? '0' + min : min;
        sec = Math.floor(this.recorderTime % 60);
        sec = sec < 10 ? '0' + sec : sec;
      } else {
        min = Math.floor(this.audio.recorderDuration / 60);
        // min = min < 10 ? '0' + min : min;
        sec = Math.floor(this.audio.recorderDuration % 60);
        sec = sec < 10 ? '0' + sec : sec;
      }
      let duration = min + "'" + sec + "''";
      return duration;
    },

    vCurPlayingAudio () {
      return this.$store.getters.curPlayingAudio;
    },
    vIsCloseAudioPlay () {
      return this.$store.getters.isCloseAudioPlay;
    }
  },
  watch: {
    'audioUrl': {
      handler (newVal, oldVal) {
        if (this.audioObj) {
          this.audioObj.load();
          this.audioObj.oncanplay = () => {
            if (this.audioObj.duration > 0) {
              this.recorderDuration = Math.floor(this.audioObj.duration);
            }
          };
        }
      },
      immediate: true
    },
    vCurPlayingAudio (n, o) { // 同时打开多个音频 把前面打开的音频关闭
      if (n != this.audioUrl) {
        this.audio.isPlaying = false;
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        this.audio.currentTime = 0;
      }
    },
    vIsCloseAudioPlay (n, o) { // 点击其他地方 关闭音频
      if (n) {
        this.audio.isPlaying = false;
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        this.audio.currentTime = 0;
      }
    },
    $route (to, from) { // 路由变更 关闭音频
      this.audio.isPlaying = false;
      this.audioObj.pause();
      this.audioObj.currentTime = 0;
      this.audio.currentTime = 0;
    }
  },
  mounted () {
    this.audioObj = this.$el.querySelector('.audio-file');
  },
  methods: {
    // 点击音频
    clickAudio (e) {
      console.log("clickAudio",e)
      e.stopPropagation();// 防止冒泡
      this.closeOtherAudio();
      if (this.isShowDeleteBtn) {
        this.$emit('onClickAudio');
      } else {
        this.$store.commit('setCloseAudioPlay', false);
        if (!this.audio.isPlaying) { // 播放
          this.$store.commit('setCurPlayingAudio', this.audioUrl);
          this.audioPlay();
        } else { // 暂停
          this.audioPause();
        }
      }
    },
    closeOtherAudio () {
      // let audioList = document.getElementsByTagName('audio');
      // for (let i = 0; i < audioList.length; i++) {
      //   audioList[i].pause();
      // }
    },
    // 播放
    audioPlay () {
      this.audio.isPlaying = true;
      this.audioObj.play();
      // this.playRecorderDurationIncrease();
    },
    // 暂停
    audioPause () {
      this.audio.isPlaying = false;
      this.audioObj.pause();
    },
    handleAudioTimeUpdated (res) {
      this.audio.currentTime = res.target.currentTime;
      if (this.audio.currentTime >= this.audio.recorderDuration) {
        this.audioPause();
      }
    },
    deleteAudio () {
      layer.confirm('请问是否删除该录音', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              this.$nextTick(() => {
                this.audio.recorderDuration = 0;
              });
              if (this.audio.isPlaying) {
                this.audio.isPlaying = false;
                this.audio.currentTime = 0;
              }
              this.$emit('onDeleteAudio', this.audioUrl);
              layer.close();
            }
          }
        ]
      });
    },
    // 当指定的音频/视频的元数据已加载时，会发生 loadedmetadata 事件。
    onLoadedmetadata (res) {
      console.log('onLoadedmetadata', this.audioUrl, parseInt(res.target.duration));
      this.audio.recorderDuration = parseInt(res.target.duration);
      // if (this.recorderTime <= 0) {
      this.$emit('onRecorderDuration', this.audioUrl, this.audio.recorderDuration);
      // }
    }
  }
};
</script>

<style>
</style>