<template>
  <div class="form_info_detail">
    <!-- 自定义展示列表 （通用） -->
    <!-- <div class="defult_dialog_header">
      <span class="defult_dialog_header_return mint-toast-icon mintui mintui-back fl"
            @click.stop="onBack"></span>
      <span class="defult_dialog_header_font">报名类型</span>
    </div> -->
    <div class="search_result_content nonBtnForList">
      <div class="search_result_list">
        <!-- active 选中当前 -->
        <div class="single_result single_class_info" v-for="item in signUpType" :key="item.ApplyTypeKey"
          @click.stop="selectedItem(item)" >
          <div class="single_result_info_detail">
            <div class="student_info_name">
              <span>{{item.newApplyTypeName}}</span>
              <span class="font_gray">{{item.comments}}</span>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      signUpType: [// 开单类型
        {
          newApplyTypeName: '新生报名',
          ApplyTypeKey: 1, // '报名类型',
          ApplyTypeKeyValue: '新报', // ''1-新生;2-续费;3-扩课'
          comments: '新学员首次报名'
        },
        {
          newApplyTypeName: '老生续费',
          ApplyTypeKey: 2, // '报名类型',
          ApplyTypeKeyValue: '续费', // ''1-新生;2-续费;3-扩课'
          comments: '老学员续费原课程'
        },
        {
          newApplyTypeName: '老生扩科',
          ApplyTypeKey: 3, // '报名类型',
          ApplyTypeKeyValue: '扩科', // ''1-新生;2-续费;3-扩课'
          comments: '老学员扩展新的课程'
        }
      ]
    };
  },
  methods: {
    selectedItem (item) {
      this.$emit('onChooseSignupType', item);
    }
  }
};
</script>

<style>

</style>