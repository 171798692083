<template>
  <div class="ZSJK">
    <!-- 活动栏目 -->
    <div class="ZSJK_title">
      <span>招生战况直播</span>
      <!-- <div class="ZSJK_title_btn">
        <div class="ZSJK_title_btn_refresh"
             title="刷新"
             @click.stop="refresh"></div>
        <div class="ZSJK_title_btn_down"
             title="上一页"
             @click.stop="prePage"
             v-show="couponList.length>0"></div>
        <div class="ZSJK_title_btn_up"
             title="下一页"
             @click.stop="nextPage"
             v-show="couponList.length>0"></div>
      </div> -->
    </div>
    <!-- 所有券 -->
    <div v-if="showCouponList"
         class="ZSJK_activity">
      <div class="swiper-container"
           v-show="couponList.length>0">
        <div class="swiper-wrapper">
          <coupon-item v-for="(item,index) in couponList"
                       :key="index"
                       @click="changeSelectionCoupon(item,true)"
                       :couponData="item">
          </coupon-item>
        </div>
      </div>
      <div class="noCouponData"
           :class="promptMsg=='加载中'?'noDataImg':''"
           v-show="couponList.length==0">{{promptMsg}}</div>
    </div>
    <!-- 活动统计 -->
    <div class="ZSJK_statistics flex ">
      <statistics-item v-for="(item,index) in statisticsList[0]"
                       :key="index"
                       :statisticsData="item"
                       @openActivitiesStatisticsList="openActivitiesStatisticsList">
      </statistics-item>
    </div>
    <!-- 多功能菜单 -->
    <div class="ZSJK_menuBox flex">
      <!-- <div class="ZSJK_menuBox_exhibition ZSJK_box">
        <div class="ZSJK_menuBox_exhibition_title">
          {{vActiveTitle}}
        </div>
        <div class="ZSJK_menuBox_exhibition_time clearfix">
          <div class="fl">活动结束时间：{{vActivityTimeEnd}}</div>
          <div class="ZSJK_menuBox_exhibition_QR fr"
               @click.stop="clickQRCode"
               v-show="couponList.length>0"></div>
        </div>
        <div class="ZSJK_menuBox_exhibition_cover">
          <div v-if="!isShowQRCode">
            <div v-if="selectedCouponInfo.billId && selectedCouponInfo.billId>0"
                 @click="editPoster"
                 class="no_bill_wap">
              <img class="no_bill"
                   :src="CDNUrl+selectedCouponInfo.pic?selectedCouponInfo.pic:''"
                   :onerror="defaultBigImg"
                   alt="">
              <div v-if="!selectedCouponInfo.pic"
                   class="no_bill_img">暂无海报图片</div>
            </div>
            <div v-else
                 @click="addPoster"
                 class="no_bill_wap">
              <img class="no_bill"
                   :src="CDNUrl+selectedCouponInfo.pic?selectedCouponInfo.pic:''"
                   :onerror="defaultBigImg"
                   alt="">
              <div v-if="!selectedCouponInfo.pic"
                   class="no_bill_img">暂无海报,请点击新增海报</div>
            </div>
          </div>
          <div class="ZSJK_menuBox_exhibition_cover_QR"
               v-show="isShowQRCode">
            <div id="QRCode"></div>
          </div>
        </div>
      </div> -->
      <div class="ZSJK_bottom_left ZSJK_box">
        <div v-if="vShowActivitiesView">
          <activities-view :dataInfo="selectedCouponInfo"></activities-view>
        </div>
        <div class="ZSJK_menuBox_current ">
          <div class="ZSJK_menuBox_current_ZKZB"
               v-if="distanceForEnd.seconds>=0">
            距结束还剩：<span class="font_red">{{distanceForEnd.days}}</span>天<span class="font_red">{{distanceForEnd.hours}}</span>时<span class="font_red">{{distanceForEnd.minutes}}</span>分<span class="font_red">{{distanceForEnd.seconds}}</span>秒
          </div>
          <div class="ZSJK_menuBox_current_ZKZB"
               v-else>活动已结束</div>
          <!-- 战况列表 -->
          <div class="ZSJK_menuBox_current_box">
            <situation-item class="ZSJK_menuBox_current_list new"
                            v-for="item in vLatestSituation"
                            :key="item.id"
                            :item="item"></situation-item>
            <situation-item class="ZSJK_menuBox_current_list"
                            v-for="item in vHistoricalSituation.dataList"
                            :key="item.id"
                            :item="item"></situation-item>
          </div>
          <div class="ZSJK_menuBox_current_menu">
            <div class="ZSJK_menuBox_current_menu_btn fl"
                 @click.stop="notForwardingClick">未转发</div>
            <div class="ZSJK_menuBox_current_menu_btn btn2 fl"
                 @click.stop="exportClick">导出</div>
            <div class="ZSJK_menuBox_current_menu_more fr"
                 @click.stop="loadMore">{{readMoreText}}</div>
          </div>
        </div>
      </div>

      <!-- 几个tab -->
      <div class="ZSJK_menuBox_dataList ZSJK_box">
        <div class="ZSJK_menuBox_dataList_title flex">
          <div :class="{displayNone:!vIsGroup,opt:tableActiveIndex == 1}"
               @click.stop="menuSwitch('1')"
               class="ZSJK_menuBox_dataList_title_list">
            拼团奖励
          </div>
          <div :class="{displayNone:vIsGroup,opt:tableActiveIndex == 2}"
               class="ZSJK_menuBox_dataList_title_list"
               @click.stop="menuSwitch('2')">{{vIntroduceType}}</div>
          <div class="ZSJK_menuBox_dataList_title_list"
               :class="{opt:tableActiveIndex == 3}"
               @click.stop="menuSwitch('3')">员工直销</div>
          <div class="ZSJK_menuBox_dataList_title_list"
               :class="{opt:tableActiveIndex == 4}"
               @click.stop="menuSwitch('4')">家长分销</div>
          <div class="ZSJK_menuBox_dataList_title_list"
               :class="{opt:tableActiveIndex == 5}"
               @click.stop="menuSwitch('5')">分销统计</div>
          <!-- <el-menu-item class="ZSJK_menuBox_dataList_title_list"
                        index="5">浏览奖励</el-menu-item> -->
        </div>
        <div class="ZSJK_menuBox_dataList_search">
          <div class="ZSJK_menuBox_dataList_search_input">
            <input type="text"
                   placeholder="请输入"
                   v-model="searchTextForTable">
            <div class="search_btn"
                 @click="queryTableData">搜索</div>
          </div>
        </div>
        <!-- 列表 -->
        <div>
          <table-list ref="tableList"
                      :tableColumns="tableHeadColumn"
                      :tableData="tableContentData"
                      @scrollUpdateData="scrollUpdateData"
                      @showDetail="doAfterClickDetail"></table-list>
        </div>
        <!-- v-if="tableActiveIndex!=1" -->
        <div class="ZSJK_menuBox_list_menu clearfix">
          <!-- 拼团奖励 -->
          <!-- <div class="ZSJK_menuBox_dataList_search_box fl"
               v-if="tableActiveIndex==1">
            <div class="ZSJK_menuBox_dataList_search_data"><span class="font_gray">总团数:</span>{{vTableInfo.total}}人</div>
            <div class="ZSJK_menuBox_dataList_search_data"><span class="font_gray">报名:</span>{{vTableInfo.totalData.total_get_num || 0}}人</div>
          </div> -->
          <!-- 老师提成 -->
          <!-- <div class="ZSJK_menuBox_dataList_search_box fl"
               v-if="tableActiveIndex==2">
            <div class="ZSJK_menuBox_dataList_search_data"><span class="font_gray">老师:</span>{{vTableInfo.tableData.length}}人</div>
            <div class="ZSJK_menuBox_dataList_search_data"><span class="font_gray">报名:</span>{{vTableInfo.totalData.totalwillnum||0}}人</div>
            <div class="ZSJK_menuBox_dataList_search_data"><span class="font_gray">提成:</span>{{Number(vTableInfo.totalData.totalamountbonus).toFixed(2)||0.00}}元</div>
          </div> -->
          <!-- 分销奖励 {totalwillnum: null, totalamountbonus: "0.00"}-->
          <!-- <div class="ZSJK_menuBox_dataList_search_box fl"
               v-if="tableActiveIndex==3">
            <div class="ZSJK_menuBox_dataList_search_data"><span class="font_gray">分销人:</span> <span>{{vTableInfo.total}}人</span></div>
            <div class="ZSJK_menuBox_dataList_search_data"><span class="font_gray">报名:</span>{{vTableInfo.totalData.totalapplynum||0}}人</div>
            <div class="ZSJK_menuBox_dataList_search_data"><span class="font_gray">奖励:</span>{{Number(vTableInfo.totalData.totalAwardAmount).toFixed(2)||0.00}}元</div>
          </div> -->
          <!-- 介绍奖励 -->
          <!-- <div class="ZSJK_menuBox_dataList_search_box fl"
               v-if="tableActiveIndex==4">
            <div class="ZSJK_menuBox_dataList_search_data"><span class="font_gray">介绍人:</span><span>{{vTableInfo.total}}人</span></div>
            <div class="ZSJK_menuBox_dataList_search_data">
              <span class="font_gray">报名:</span><span>{{vTableInfo.totalData.totalapplynum||0}}人</span></div>
            <div class="ZSJK_menuBox_dataList_search_data">
              <span class="font_gray">奖励 :</span>{{Number(vTableInfo.totalData.totalAwardAmount).toFixed(2)||0.00}}元</div>
          </div> -->
          <!-- 浏览奖励 // {totalreadnum: null, totalreadpersonnum: null, totalsharenum: null, standardnum: null}-->
          <!-- <div class="ZSJK_menuBox_dataList_search_box fl"
               v-if="tableActiveIndex==5">
            <div class="ZSJK_menuBox_dataList_search_data data_two">
              <span class="font_gray">浏览:</span>
              <span class="font_two">
                <div>{{vTableInfo.totalData.totalreadnum||0}}次</div>
                <div class="font_bottom">{{vTableInfo.totalData.totalreadpersonnum||0}}人</div>
              </span>
            </div>
            <div class="ZSJK_menuBox_dataList_search_data data_two">
              <span class="font_gray">转发:</span>
              <span class="font_two">
                <div>{{vTableInfo.totalData.totalsharenum||0}}次</div>
                <div class="font_bottom">{{vTableInfo.totalData.totalsharepersonnum||0}}人</div>
              </span>
            </div>
            <div class="ZSJK_menuBox_dataList_search_data data_two">
              <span class="font_gray">达标:</span>{{vTableInfo.totalData.standardnum||0}}人</div>
          </div> -->
          <!-- <div class="ZSJK_menuBox_current_menu_more fr"
               v-if="readMoreTextShow"
               @click.stop="loadMoreForTable">{{readMoreTextForTable}}</div> -->
        </div>
      </div>
    </div>
    <!-- 弹出列表 -->
    <div>
      <custom-dialog title="活动报名统计表"
                     width="1300px"
                     class="class_staistics_list defult_dialog"
                     :visible.sync="isShowActivitiesStatisticsDialog"
                     :before-close="closeActivitiesStatisticsDialog">
        <activities-statistics-list :selectedCouponInfo="selectedCouponInfo"></activities-statistics-list>
      </custom-dialog>
      <group-detail-list ref="groupDetailList"
                         @refreshGroupList="refreshGroupList"></group-detail-list>
      <receive-detail-list ref="receiveDetailList"
                           :selectedCouponInfo="selectedCouponInfo"></receive-detail-list>
      <teacherDetailList ref="teacherDetailList"
                         :selectedCouponInfo="selectedCouponInfo"></teacherDetailList>
      <dis-detail-list ref="disDetailList"
                       :selectedCouponInfo="selectedCouponInfo"></dis-detail-list>
      <intro-detail-list ref="introDetailList"
                         :selectedCouponInfo="selectedCouponInfo"></intro-detail-list>
      <sales-statistics-list ref="salesStatisticsList"
                             :selectedCouponInfo="selectedCouponInfo"></sales-statistics-list>
      <!-- <browse-detail-list ref="browseDetailList"
                          :selectedCouponInfo="selectedCouponInfo"></browse-detail-list> -->
    </div>
    <div>
      <dialog-factory ref="operatingRevenueStatisticsFactory"
                      :key="'operatingRevenueStatistics'"
                      :routerName="'operatingRevenueStatistics'"></dialog-factory>
      <dialog-factory-common ref="operatingDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import couponItem from './coupon-item';
import statisticsItem from './statistics-item';
import situationItem from './situation-item';
import tableList from './table-list';
import teacherDetailList from './teacher-detail-list';
import receiveDetailList from './receive-detail-list';
import browseDetailList from './browse-detail-list';
import introDetailList from './intro-detail-list';
import disDetailList from './dis-detail-list';
import groupDetailList from './group-detail-list';
import activitiesView from './activities-view';
import activitiesStatisticsList from './activities-statistics-list';
import salesStatisticsList from './sales-statistics-list';
import dialogFactory from '../../common/dialog-factory';

import list from '../list';
import {
  FiltrateCoupon, GetCouponInfoById, HistoricalSituation, LatestActionQuery, TeacherCommission, DistributionRewards,
  IntroduceRewardQuery, BrowseRrewards, CouponRedemptionDetails, BrowseGiftDistribution, MessageSearchPageForGroup, DistributionReport, UpdateUserState
} from '../../../API/workbench.js';
let initQueryParamsForTable = {
  pageIndex: 0, // 页码，0为第一页
  pageSize: 10, // 每页记录数，0：表示查询所有
  searchText: '', // 搜索条件：模糊匹配
  orderBy: '', // 排序方向
  sequence: ''
};
let tableFunctionType = { fanxian: 'fanxian', fanhour: 'fanhour', fangift: 'fangift' };// 几个弹出层列表tabFunction 类型的初始化
export default {
  name: 'activityMonitoringPlatform',
  data () {
    return {
      swiper: {},
      pageIndex: 0,
      showCouponList: true,
      swiperActiveIndex: 0, // swipe 划动的位置
      tableHeadColumn: [],
      tableContentData: [],
      defaultImg: 'this.src="' + require('../../../../public/image/noBillImg.png') + '"',
      defaultBigImg: 'this.src="' + require('../../../../public/image/noBillImg-Big.png') + '"',
      tableActiveIndex: '1', // 1拼团奖励 2：老师提成 3：分销奖励 4介绍奖励 5 浏览奖励
      unShareActive: false, // 点击未转发 显示列表
      readMoreText: '查看更多 10 条',
      promptMsg: '加载中',
      queryParamsForTable: null,
      vShowActivitiesView: false,
      readMoreTextShow: false,
      readMoreTextForTable: '查看10条',
      couponList: [],
      vTableInfo: { headColumn: null, tableData: null },
      selectedCouponInfo: {}, // 选中的优惠券
      latestSituationInterval: null, // 最新战况心跳包
      distanceForEnd: {}, // 距结束时间
      distanceForEndInterval: null, // 距结束时间心跳包
      isShowQRCode: false, // 是否显示券额二维码
      isShowActivitiesStatisticsDialog: false,
      statisticsList: [{// 统计栏
        readPerson: { title: '浏览（次）', num: 0, showNumName: '显示', showNum: 0, virtualNumName: '虚拟', virtualNum: 0 },
        share_number: { title: '浏览（人）', num: 0, showNum: '', virtualNum: '' },
        enrollPerson: { title: '线上报名（人）', className: 'border_none', num: 0, showNumName: '显示', showNum: 0, virtualNumName: '虚拟', virtualNum: 0 },
        payAmount: { title: '报名金额（元）', num: 0, showNum: '', virtualNum: '' },
        useEnrollPerson: { title: '到店付款（人）', className: 'border_none', num: 0 },
        usePayAmount: { title: '报名金额（元）', num: 0, showNum: '', virtualNum: '' },
        groupAmount: { title: '拼团奖励（元）', className: '', num: 0, showNum: '', virtualNum: '' },
        PromoteAmount: { title: '介绍奖励（元）', className: '', num: 0, showNum: '', virtualNum: '' },
        staffAmount: { title: '员工直销（元）', num: 0, showNum: '', virtualNum: '' },
        introduceAmount: { title: '分销奖励（元）', num: 0, showNum: '', virtualNum: '' }
      }],
      historicalSituationInfo: null, // 历史战况信息
      groupBuyingColumns: [// 拼团奖励,
        { label: '团长', prop: 'groupLeaderKeyValue', sortable: false, align: 'left', width: '85', isShow: true, type: '', decimalPlace: 0, className: 'font_blue', title: 'telephone', toolTip: '{{telephone}}' },
        { label: '虚拟团员', prop: 'virtual_num', sortable: false, align: 'left', width: '80', isShow: true, type: '', decimalPlace: 0 },
        { label: '实际团员', prop: 'actual_num', sortable: false, align: 'left', width: '80', isShow: true, type: '', decimalPlace: 0, icon: 'fa fa-qrcode' },
        { label: '待成团', prop: 'remain_num', sortable: false, align: 'left', width: '80', isShow: true, type: 'html', decimalPlace: 2, className: 'font_blue' },
        {
          label: '操作',
          prop: '',
          align: 'center',
          width: '45',
          sortable: false, // width: 280, align: 'center',
          tableBtnGroup: [
            {
              name: '详情',
              eventName: 'detail',
              Stutas: '',
              className: () => {
                return 'el-button-reset font_blue';
              },
              roleName: ''
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        }
      ],
      teacherPerformanceColumns: [// 老师提成列,
        { label: '员工', prop: 'name', sortable: false, width: '80', align: 'left', isShow: true, type: '', decimalPlace: 0, className: 'font_blue', title: 'telephone', toolTip: '{{telephone}}' },
        { label: '浏览', prop: 'resalenum', sortable: false, width: '80', align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '招生', prop: 'BMNum', sortable: false, width: '80', align: 'left', isShow: true, type: '', decimalPlace: 0, icon: 'fa fa-qrcode' },
        { label: '奖励', prop: 'commission_amount', sortable: false, width: '75', align: 'left', isShow: true, type: '', decimalPlace: 2 },
        {
          label: '操作',
          prop: '',
          align: 'center',
          width: '60',
          sortable: false, // width: 280, align: 'center',
          tableBtnGroup: [
            {
              name: '详情',
              eventName: 'detail',
              Stutas: '',
              className: () => {
                return 'el-button-reset font_blue';
              },
              roleName: ''
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        }
      ],
      distributionRewardColumns: [ // 分销奖励列,
        { label: '分销人', prop: 'username', sortable: false, width: '78', align: 'left', isShow: true, type: '', decimalPlace: 0, className: 'font_blue', title: 'telephone', toolTip: '{{telephone}}' },
        { label: '邀请人', prop: 'name', sortable: false, width: '78', align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '转发', prop: 'share_number', sortable: false, width: '54', align: 'left', isShow: true, type: '', decimalPlace: 0, icon: 'fa fa-qrcode' },
        { label: '招生', prop: 'get_num', sortable: false, width: '54', align: 'left', isShow: true, type: '', decimalPlace: 0, icon: 'fa fa-qrcode' },
        { label: '奖励', prop: 'amountbonus', sortable: false, width: '70', align: 'left', isShow: true, type: '', decimalPlace: 2 },
        {
          label: '操作',
          prop: '',
          width: '45',
          align: 'center',
          sortable: false, // width: 280, align: 'center',
          tableBtnGroup: [
            {
              name: '详情',
              eventName: 'detail',
              Stutas: '',
              className: () => {
                return 'el-button-reset font_blue';
              },
              roleName: ''
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        }
      ],
      introduceRewardColumns: [   // 介绍奖励列,
        { label: '邀请人', prop: 'username', sortable: false, width: '60', align: 'left', isShow: true, type: '', decimalPlace: 0, className: 'font_blue', title: 'telephone', toolTip: '{{telephone}}' },
        { label: '已邀请', prop: 'get_number', sortable: false, width: '60', align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '名额', prop: 'sub_number', sortable: false, width: '60', align: 'left', isShow: true, type: '', decimalPlace: 0, icon: 'fa fa-qrcode' },
        { label: '奖励', prop: 'BMJL', sortable: false, width: '60', align: 'left', isShow: true, type: '', decimalPlace: 2 },
        {
          label: '操作',
          prop: '',
          width: '45',
          align: 'center',
          sortable: false, // width: 280, align: 'center',
          tableBtnGroup: [
            {
              name: '详情',
              eventName: 'detail',
              Stutas: '',
              className: () => {
                return 'el-button-reset font_blue';
              },
              roleName: ''
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        }
      ],
      browseRewardColumns: [// 浏览奖励列
        { label: '转发人', prop: 'username', sortable: false, width: '50', align: 'left', isShow: true, type: '', decimalPlace: 0, className: 'font_blue', title: 'telephone', toolTip: '{{telephone}}' },
        { label: '转发', prop: 'share_number', sortable: false, width: '70', align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '浏览', prop: 'readPersonNum', sortable: false, width: '60', align: 'left', isShow: true, type: '', decimalPlace: 0, icon: 'fa fa-qrcode' },
        // { label: '达标', prop: 'DBStatus', sortable: false, align: 'left', isShow: true, type: 'html', decimalPlace: 2 },
        {
          label: '达标',
          prop: '',
          width: '60',
          sortable: false,
          tableBtnGroup: [
            {
              name: '未达标',
              eventName: tableFunctionType.fangift,
              Stutas: 'read_gift_status',
              className: (value) => {
                if (value == 1) {
                  return 'el-button-reset font_gray no_target';
                } else {
                  return 'el-button-reset btn_light_hide';
                }
              },
              roleName: ''
            }, {
              name: '送礼',
              eventName: tableFunctionType.fangift,
              Stutas: 'read_gift_status',
              className: (value) => { // 1未达标 2待发放 3 已发放
                if (value == 1) {
                  return 'el-button-reset btn_light_hide';
                } else if (value == 2) {
                  return 'el-button-reset font_blue';
                } else if (value == 3) {
                  return 'el-button-reset reach';
                }
              },
              roleName: ''
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        },
        {
          label: '操作',
          prop: '',
          width: '45',
          sortable: false, // width: 280, align: 'center',
          tableBtnGroup: [
            {
              name: '详情',
              eventName: 'detail',
              Stutas: '',
              className: () => {
                return 'el-button-reset font_blue';
              },
              roleName: ''
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        }
      ],
      salesStatisticsColumns: [
        { label: '员工', prop: 'name', sortable: false, width: '78', align: 'left', isShow: true, type: '', decimalPlace: 0, className: 'font_blue', title: 'telephone', toolTip: '{{telephone}}' },
        { label: '分销人', prop: 'ResaleNum', sortable: false, width: '80', align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '转发', prop: 'share_num', sortable: false, width: '80', align: 'left', isShow: true, type: '', decimalPlace: 0, icon: '' },
        { label: '招生', prop: 'get_num', sortable: false, width: '80', align: 'left', isShow: true, type: '', decimalPlace: 0, icon: '' },
        {
          label: '操作',
          prop: '',
          width: '60',
          align: 'center',
          sortable: false, // width: 280, align: 'center',
          tableBtnGroup: [
            {
              name: '详情',
              eventName: 'detail',
              Stutas: '',
              className: () => {
                return 'el-button-reset font_blue';
              },
              roleName: ''
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        }
      ],
      groupBuyingData: { list: [], totalData: {} },
      teacherPerformanceData: { list: [], totalData: {} }, // 老师提成tableData
      distributionRewardData: { list: [], totalData: {} }, // 分销奖励tableData
      introduceRewardData: { list: [], totalData: {} }, // 介绍奖励tableData
      browseRewardData: { list: [], totalData: {} }, //  浏览奖励tableData
      salesStatisticsData: { list: [], totalData: {} }, //  浏览奖励tableData
      // 4个 弹出层列表参数
      tableType: { groupBuying: 1, introduceReward: 2, teacherPerformance: 3, distributionReward: 4, salesStatistics: 5, browseReward: 6 },
      listTitle: '', // 列表title
      functionBtnGroup: [], // 功能按钮
      totalNum: 0, // 返回的总记录数
      PageCount: 0, // 页数
      pageSize: 0, // 查询记录数
      searchTextForTable: '', // tableList 查询文本
      updateUserStateTimeout: null
    };
  },
  components: {
    couponItem,
    statisticsItem,
    situationItem,
    tableList,
    list,
    teacherDetailList,
    receiveDetailList,
    browseDetailList,
    introDetailList,
    disDetailList,
    groupDetailList,
    activitiesView,
    activitiesStatisticsList,
    salesStatisticsList,
    dialogFactory
  },
  computed: {
    CDNUrl () {
      return this.$store.getters.CDNURL;
    },
    vIntroduceType () {
      if (this.selectedCouponInfo && this.selectedCouponInfo.if_fenxiang == 3) {
        this.statisticsList[0].PromoteAmount.title = '裂变奖励（元）';
        return '裂变奖励';
      } else {
        return '介绍奖励';
      }
    },
    vIsGroup () {
      if (this.selectedCouponInfo && this.selectedCouponInfo.programme_type == 1) {
        return true;
      } else {
        return false;
      }
    },
    vActiveTitle () {
      if (this.selectedCouponInfo) {
        return this.selectedCouponInfo.active_title ? this.selectedCouponInfo.active_title : this.selectedCouponInfo.coupon_name;
      } else {
        return '';
      }
    },
    vActivityTimeEnd () {
      if (this.selectedCouponInfo && this.selectedCouponInfo.activitytimeend) {
        let activityTime = Date.new(this.selectedCouponInfo.activitytimeend);
        let newTime = activityTime.getFullYear() + '年' + this.$utils.getFormatDate(activityTime.getMonth() + 1) + '月' + this.$utils.getFormatDate(activityTime.getDate()) + '日';
        return newTime;
      } else {
        return '';
      }
    },
    // 最新战况
    vLatestSituation () {
      let historicalSituation = this.$utils.parseJson(this.virtualhistoricalSituationInfo);
      let latestSituation = [];
      let newArr = [];
      if (this.virtualhistoricalSituationInfo) {
        if (historicalSituation.dataList.length > 2) {
          newArr = historicalSituation.dataList.splice(0, 2);
        } else {
          newArr = historicalSituation.dataList;
        }
        newArr.forEach(obj => {
          // var hourse = (Date.new().getTime() - Date.new(obj.add_time).getTime()) / 1000 / 60 / 60;
          // if (hourse <= 24) {
          //   if (hourse < 1) {
          //     let time = Math.floor((Number(hourse) * 60));
          //     if (time <= 0) {
          //       obj.addTime = '刚刚';
          //     } else {
          //       obj.addTime = parseInt(time) + '分钟前';
          //     }
          //   } else {
          //     obj.addTime = parseInt(hourse) + '小时前';
          //   }
          //   latestSituation.push(obj);
          // }
          latestSituation.push(obj);
        });
      }
      return latestSituation;
    },
    virtualhistoricalSituationInfo () {
      let newData = { dataList: [] };
      let alldatalist = { dataList: [] };
      if (this.historicalSituationInfo) {
        newData = this.$utils.parseJson(this.historicalSituationInfo);
        newData.dataList.sort((x, y) => {
          return Date.new(y.add_time) - Date.new(x.add_time);
        });
        newData.dataList.forEach(obj => {
          if (obj.add_time) {
            var hourse = (Date.new().getTime() - Date.new(obj.add_time).getTime()) / 1000 / 60 / 60;
          } else {
            var hourse = Date.new().getTime() / 1000 / 60 / 60;
          }
          if (hourse <= 24) {
            if (hourse < 1) {
              let time = Math.floor((Number(hourse) * 60));
              if (time <= 0) {
                obj.addTime = '刚刚';
              } else {
                obj.addTime = parseInt(time) + '分钟前';
              }
            } else {
              obj.addTime = parseInt(hourse) + '小时前';
            }
          }
          alldatalist.dataList.push(obj);
        });
      }
      return alldatalist;
    },
    // 历史战况
    vHistoricalSituation () {
      let historicalSituation = { dataList: [] };
      if (this.virtualhistoricalSituationInfo) {
        historicalSituation = this.$utils.parseJson(this.virtualhistoricalSituationInfo);
        historicalSituation.dataList.splice(0, this.vLatestSituation.length);
      }
      return historicalSituation;
    },
    // 几个menuItem 切换
    vActivityURL () {
      let url = this.$store.getters.wktDomainURL.replace('http:', 'https:') + '/do/shareCouponToGet.php?coupon_id=' + this.selectedCouponInfo.id + '&source_type=1&share_user_id=0&SaaSClientKey=' + this.$store.getters.SaaSClientInfo.OLAPKey;
      console.log(url);
      // this.createQRcode(url);
      return url;
    }
  },
  watch: {
    $route (to, from) {
      console.log(to, 'tttt');
      console.log(from, 'fffff');
      // if (‘条件判断’) {
      //     // 需要处理的业务逻辑
      //     console.log(1234533);
      // }
    },
    searchTextForTable (n, o) {
      this.queryParamsForTable = this.$utils.parseJson(initQueryParamsForTable);
      this.queryParamsForTable.searchText = this.searchTextForTable;
      this.queryParamsForTable.id = this.selectedCouponInfo.id;
    },
    'selectedCouponInfo.id' (n, o) {
      this.vShowActivitiesView = false;
      this.$nextTick(() => {
        this.vShowActivitiesView = true;
      });
    }
  },
  created () {
    this.createdFunction();
    this.queryParamsForTable = this.$utils.parseJson(initQueryParamsForTable);
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'activityMonitoringPlatform') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'activityMonitoringPlatform') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'wsZSZKUpData', (data) => {
      console.log('wsZSZKUpData', data);
      let arr = [];
      arr.push(data);
      this.updateActivitySituation(arr);
    });
  },
  activated () {
    if (this.$route.query.couponID) {
      this.createdFunction();
    }
    this.upUserState();
  },
  beforeDestroy () {
    if (this.updateUserStateTimeout) {
      clearTimeout(this.updateUserStateTimeout);
      this.updateUserStateTimeout = null;
    }
  },
  mounted () {
    this.$dialog.register(this, ['signuppayment']);
    this.swiperInit();
  },
  methods: {
    upUserStateInterval () {
      if (this.updateUserStateTimeout) {
        clearTimeout(this.updateUserStateTimeout);
        this.updateUserStateTimeout = null;
      }
      this.updateUserStateTimeout = setTimeout(() => {
        this.upUserState();
      }, 1800000);
    },
    upUserState () {
      UpdateUserState(this.$store.getters.token.UserID, this.$utils.formatDateToBias(Date.new()), ['1']).then(res => {
        console.log('UpdateUserState', res);
        this.upUserStateInterval();
      }, err => {
        console.log(err);
      });
    },
    createdFunction () {
      if (!this.$route.query.couponID) {
        this.loadAllCouponList(this.pageIndex, 20, 'DESC', '', '', 0, 0, 0, () => {
          if (this.couponList.length > 0) {
            this.showCouponList = true;
            this.couponList[0].isSelected = true;
            this.changeSelectionCoupon(this.couponList[0]);
            this.selectedCouponInfo = this.couponList[0];
            this.pageIndex++;
            this.$nextTick(() => {
              this.swiperIndex(0);
            });
          } else {
            this.promptMsg = '暂无数据';
          }
        });
      } else {
        this.showCouponList = false;
        this.selectedCouponInfo.id = this.$route.query.couponID;
        this.isShowActivitiesStatisticsDialog = false;
        this.loadLatestCouponInfoById(() => {
          this.$refs.groupDetailList.isShowListDialog = false;
          this.$refs.receiveDetailList.$refs.detailList.isShowListDialog = false;
          this.$refs.teacherDetailList.$refs.detailList.isShowListDialog = false;
          this.$refs.disDetailList.$refs.detailList.isShowListDialog = false;
          this.$refs.introDetailList.$refs.detailList.isShowListDialog = false;
          this.$refs.salesStatisticsList.$refs.detailList.isShowListDialog = false;
          this.changeSelectionCoupon(this.selectedCouponInfo);
        });
      }
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.operatingRevenueStatisticsFactory) {
        this.$refs.operatingRevenueStatisticsFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.operatingDialogFactoryCommon) {
        this.$refs.operatingDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    },
    closeActivitiesStatisticsDialog () {
      this.isShowActivitiesStatisticsDialog = false;
    },
    openActivitiesStatisticsList () {
      this.isShowActivitiesStatisticsDialog = true;
    },
    // 跳转海报券页面修改.
    editPoster () {
      // window.open('/iShowEditor/#/ishow?activityId=' + this.selectedCouponInfo.billId);
    },
    addPoster () {
      // window.open('/iShowEditor/#/ishow?activityId=0&activeType=1&activeSubType=' + this.selectedCouponInfo.if_fenxiang + '&activeDataID=' + this.selectedCouponInfo.id);
    },
    scrollUpdateData () {
      console.log(this.vTableInfo, 'this.vTableInfo');
      if (this.vTableInfo.total > this.vTableInfo.tableData.length) {
        this.loadMoreForTable();
      }
    },
    tableInfoFun () {
      let tableInfo = {};
      if (this.tableActiveIndex == this.tableType.groupBuying) {
        tableInfo.headColumn = this.groupBuyingColumns;
        tableInfo.tableData = this.groupBuyingData.list;
        tableInfo.totalData = this.groupBuyingData.totalData;
        tableInfo.total = this.groupBuyingData.total;
      } else if (this.tableActiveIndex == this.tableType.teacherPerformance) {
        tableInfo.headColumn = this.teacherPerformanceColumns;
        if (this.searchTextForTable) {
          let vTableData = [];
          this.teacherPerformanceData.list.forEach(item => {
            if (item.name.indexOf(this.searchTextForTable) > -1) {
              vTableData.push(item);
            }
          });
          tableInfo.tableData = vTableData;
        } else {
          tableInfo.tableData = this.teacherPerformanceData.list;
        }
        tableInfo.totalData = this.teacherPerformanceData.totalData;
      } else if (this.tableActiveIndex == this.tableType.distributionReward) {
        tableInfo.headColumn = this.distributionRewardColumns;
        tableInfo.tableData = this.distributionRewardData.list;
        tableInfo.totalData = this.distributionRewardData.totalData;
        tableInfo.total = this.distributionRewardData.total;
      } else if (this.tableActiveIndex == this.tableType.introduceReward) {
        tableInfo.headColumn = this.introduceRewardColumns;
        tableInfo.tableData = this.introduceRewardData.list;
        tableInfo.totalData = this.introduceRewardData.totalData;
        tableInfo.total = this.introduceRewardData.total;
      } else if (this.tableActiveIndex == this.tableType.browseReward) {
        tableInfo.headColumn = this.browseRewardColumns;
        tableInfo.tableData = this.browseRewardData.list;
        tableInfo.totalData = this.browseRewardData.totalData;
        tableInfo.total = this.browseRewardData.total;
      } else if (this.tableActiveIndex == this.tableType.salesStatistics) {
        tableInfo.headColumn = this.salesStatisticsColumns;
        tableInfo.tableData = this.salesStatisticsData.list;
        tableInfo.totalData = this.salesStatisticsData.totalData;
        tableInfo.total = this.salesStatisticsData.total;
      }
      this.vTableInfo = tableInfo;
      this.tableHeadColumn = this.vTableInfo.headColumn;
      this.tableContentData = this.vTableInfo.tableData;
    },
    // 获取门店下的所有优惠券列表         然后过滤掉已停用的.
    loadAllCouponList (pageIndex, pageSize, searchText, orderBy, sequence, status, couponClass, IsEnableKey, callBack) {
      FiltrateCoupon(pageIndex, pageSize, searchText, orderBy, sequence, status, couponClass, '', 1).then(result => {
        // 进行中
        result.data.PageDataList.forEach((obj, index) => {
          obj.isSelected = false;
          // 券类型 1：转介绍券，2优惠券 3裂变券
          // if (obj.v_status != 3) {
          if (obj.if_fenxiang == 1) {
            obj.couponType = '转介绍';
          } else if (obj.if_fenxiang == 2) {
            obj.couponType = '默认';
          } if (obj.if_fenxiang == 3) {
            obj.couponType = '裂变';
          }
          // 状态 1进行中2已过期3停用
          if (Date.new(obj.activity_time_end + ' 23:59:59').getTime() > Date.new().getTime()) {
            obj.statusName = '进行中';
            obj.statusKey = 2;
          } else {
            obj.statusName = '已结束';
            obj.statusKey = 3;
          }
          this.couponList.push(obj);
          // }
        });
        // if (this.couponList.length > 0) {
        //   this.couponList[0].isSelected = true;
        //   this.changeSelectionCoupon(this.couponList[0]);
        //   this.selectedCouponInfo = this.couponList[0];
        //   this.$nextTick(() => {
        //     this.swiperIndex(0);
        //   });
        // } else {
        //   this.promptMsg = '暂无数据';
        // }
        if (callBack) {
          callBack(result);
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 选中券 并 获取相应的券招生信息
    changeSelectionCoupon (couponInfo, boll) {
      // 重置信息
      this.historicalSituationInfo = null;
      this.latestSituationInterval = null;
      clearInterval(this.latestSituationInterval);
      clearInterval(this.distanceForEndInterval);
      // console.log('changeSelectionCoupon', couponInfo);
      if (couponInfo) {
        this.selectedCouponInfo = couponInfo;
      }
      if (this.selectedCouponInfo.id) {
        this.queryParamsForTable.id = this.selectedCouponInfo.id;
      }
      console.log(this.selectedCouponInfo, 'selectedCouponInfo');
      this.tableInfoFun();
      if (!this.vIsGroup) {
        this.loadIntroduceRewards();
        this.tableActiveIndex = '2';
      } else {
        this.loadGroupBuyingColumns();
        this.tableActiveIndex = '1';
      }
      // 获取选中优惠券的最新战况
      this.loadLatestCouponInfoById();

      // 获取历史战况（报名记录）
      this.loadHistoricalSituation(0, 12);
      // () => {
      //   this.latestSituationInterval = setInterval(() => {
      //     // 是否已结束
      //     // if (this.$route.name != 'indexview') {
      //     //   clearInterval(this.latestSituationInterval);
      //     // }
      //     if (this.historicalSituationInfo && this.historicalSituationInfo.dataList.length == 0 && this.distanceForEnd) {
      //       console.log('查询');
      //       this.loadHistoricalSituation(0, 12);
      //     } else if (this.distanceForEnd && this.historicalSituationInfo) {
      //       this.loadLatestActionQuery();
      //     } else {
      //       clearInterval(this.latestSituationInterval);
      //     }
      //   }, 10000);
      // }
      // 设置选中效果
      this.couponList.forEach(obj => {
        obj.isSelected = false;
        if (obj.id == couponInfo.id) {
          obj.isSelected = true;
        }
      });
      this.isShowQRCode = false;
      this.searchTextForTable = '';
    },
    // 获取选中优惠券的最新信息
    loadLatestCouponInfoById (callBack) {
      if (!this.selectedCouponInfo.id) {
        return false;
      }
      GetCouponInfoById(this.selectedCouponInfo.id).then(result => {
        // console.log('GetCouponInfoById', result.data.data);
        // 选中的优惠券信息
        // let pic = this.$utils.parseJson(this.selectedCouponInfo).pic;
        if (this.selectedCouponInfo.id == this.$utils.parseJson(result.data).id) {
          this.selectedCouponInfo = this.$utils.parseJson(result.data);
          this.selectedCouponInfo.activitytimeend = this.selectedCouponInfo.activitytimeend.substring(0, 10) + ' 23:59:59';
          // this.selectedCouponInfo.pic = pic;
          // 计算距结束还剩多长时间
          this.caculateSetInterval();
          // 统计栏信息
          this.initStatisticsData();
          if (callBack) {
            callBack();
          }
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 距结束还剩多长时间

    caculateSetInterval () {
      this.distanceForEnd = { days: '00', hours: '00', minutes: '00', seconds: '00' };
      console.log('caculateSetInterval1111', this.selectedCouponInfo.activitytimeend);
      let time = 0;
      if (this.selectedCouponInfo.status == 0) {
        time = 0;
      } else {
        time = Date.new(this.selectedCouponInfo.activitytimeend).getTime() + (3600000 * 16 - 1);
      }
      if (time > Date.new().getTime()) {
        let count = (time - Date.new().getTime()) / 1000;
        this.distanceForEndInterval = setInterval(() => {
          count--;
          // if (this.$route.name != 'indexview') {
          //   clearInterval(this.distanceForEndInterval);
          // }
          if (count > 0) {
            this.distanceForEnd = this.$utils.diffTwoTimeTDMSM(Date.new(), Date.new(this.selectedCouponInfo.activitytimeend));
          } else {
            clearInterval(this.distanceForEndInterval);
          }
        }, 1000);
      } else {
        this.distanceForEnd = {};
      }
    },
    // 统计栏信息
    initStatisticsData () {
      // 浏览人数
      console.log(this.selectedCouponInfo, '选中优惠券信息.');
      this.statisticsList[0].readPerson.num = Number(this.selectedCouponInfo.total_read_num) + Number(this.selectedCouponInfo.virtual_read_person_num); // 总浏览人数
      this.statisticsList[0].readPerson.showNum = Number(this.selectedCouponInfo.total_read_num) + Number(this.selectedCouponInfo.virtual_read_person_num);// 显示人数=浏览人数+虚拟人数
      this.statisticsList[0].readPerson.virtualNum = this.selectedCouponInfo.virtual_read_person_num || 0; // 虚拟人数
      // 转发次数
      this.statisticsList[0].share_number.num = this.selectedCouponInfo.total_read_person_num || 0; // 总分享次数
      // 报名人数
      this.statisticsList[0].enrollPerson.num = this.selectedCouponInfo.total_get_num || 0; // 总报名人数
      this.statisticsList[0].enrollPerson.showNum = Number(this.selectedCouponInfo.VgetNum || 0) + Number(this.selectedCouponInfo.total_get_num || 0);// 显示人数=总报名人数+虚拟人数（？？待接口请求）
      this.statisticsList[0].enrollPerson.virtualNum = this.selectedCouponInfo.VgetNum || 0; // 虚拟人数
      console.log(this.selectedCouponInfo.vgetNum, 'this.selectedCouponInfo.vgetNum ');
      // 活动金额
      this.statisticsList[0].payAmount.num = Number(this.selectedCouponInfo.buyCouponMony).toFixed(2) || '0.00'; // 总带动业绩
      // 到店付款
      this.statisticsList[0].useEnrollPerson.num = this.selectedCouponInfo.total_use_num || 0;
      // 活动金额
      this.statisticsList[0].usePayAmount.num = Number(this.selectedCouponInfo.payOrderMoney).toFixed(2) || '0.00'; // 总带动业绩
      // 介绍奖励
      this.statisticsList[0].introduceAmount.num = Number(this.selectedCouponInfo.DistriAmount || '0.00').toFixed(2); //
      this.statisticsList[0].staffAmount.num = Number(this.selectedCouponInfo.AgentAmount || '0.00').toFixed(2); //
      this.statisticsList[0].groupAmount.num = Number(this.selectedCouponInfo.GroupAmount || '0.00').toFixed(2); //
      this.statisticsList[0].PromoteAmount.num = Number(this.selectedCouponInfo.PromoteAmount || '0.00').toFixed(2); //
      if (this.vIsGroup) {
        this.statisticsList[0].groupAmount.className = '';
        this.statisticsList[0].PromoteAmount.className = 'displayNone';
      } else {
        this.statisticsList[0].groupAmount.className = 'displayNone';
        this.statisticsList[0].PromoteAmount.className = '';
      }
      // // 拼团奖励
      // if (this.selectedCouponInfo.programme_type == 1) {
      //   this.statisticsList[0].teacherPerformanceHeadAmount = { title: '拼团奖励（元）', icon: 'ZSJK_statistics_icon06', num: 0, showNumName: '活动团', showNum: 0, virtualNumName: '已成团', virtualNum: 0 };
      //   this.statisticsList[0].teacherPerformanceHeadAmount.num = Number(this.selectedCouponInfo.totalgrouprewards) || '0.00'; // 总拼团金额
      //   this.statisticsList[0].teacherPerformanceHeadAmount.showNum = Number(this.selectedCouponInfo.totalactivegroup) || '0'; // 活动团
      //   this.statisticsList[0].teacherPerformanceHeadAmount.virtualNum = Number(this.selectedCouponInfo.totalisgroup) || '0'; // 已成团
      // } else {
      //   delete this.statisticsList[0].teacherPerformanceHeadAmount;
      // }
    },
    // 获取拼团奖励
    loadGroupBuyingColumns () {
      MessageSearchPageForGroup(this.queryParamsForTable).then(res => {
        console.log(res, '拼团奖励');
        this.groupBuyingData.list = [];
        res.data.PageDataList.forEach(obj => {
          if (obj.groupLeaderKey != 0) {
            // if (obj.target_type == 1) {
            //   obj.remain_num = Number(obj.target_num) - Number(obj.use_num) - Number(obj.virtual_num) + '人';
            //   obj.actual_num = Number(obj.use_num);
            //   obj.get_target_rate = obj.use_num + '/' + obj.target_num;
            // } else {
            obj.remain_num = Number(obj.target_num) - Number(obj.get_num) - Number(obj.virtual_num) + '人';
            obj.actual_num = Number(obj.get_num);
            obj.get_target_rate = obj.get_num + '/' + obj.target_num;
            // }
          } else {
            obj.groupLeaderKeyValue = '虚拟';
            // if (obj.target_type == 1) {
            //   obj.remain_num = Number(obj.target_num) -  Number(obj.use_num) - Number(obj.virtual_num) + '人';
            //   obj.actual_num = Number(obj.use_num);
            //   obj.get_target_rate = obj.use_num + '/' + (Number(obj.target_num) - Number(obj.virtual_num));
            // } else {
            obj.remain_num = Number(obj.target_num) - Number(obj.get_num) - Number(obj.virtual_num) + '人';
            obj.actual_num = Number(obj.get_num);
            obj.get_target_rate = obj.get_num + '/' + (Number(obj.target_num) - Number(obj.virtual_num));
            // }
          }
          obj.virtual_num = obj.virtual_num + '人';
          obj.actual_num = obj.actual_num + '人';
          if (obj.status != 1) {
            obj.remain_num = '已成团';
          }
          if (obj.status == 0) {
            obj.showClass = 'already_settle';
          }

          this.groupBuyingData.list.push(obj);
        });
        if (res.data.PageDataList.length < 10) {
          this.readMoreTextShow = false;
          this.readMoreTextForTable = '查看 10 条';
        } else {
          this.readMoreTextShow = true;
          this.readMoreTextForTable = '查看更多 10 条';
        }
        this.groupBuyingData.total = res.data.Total;
        this.groupBuyingData.totalData = res.data.totalData;
        this.tableInfoFun();
        this.tableContentData = this.groupBuyingData.list;
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 获取老师提成
    loadTeacherCommission () {
      TeacherCommission(this.selectedCouponInfo.id).then(result => {
        console.log('TeacherCommission', this.$utils.parseJson(result.data));
        // if (result.data.dataList && result.data.dataList.length == 0) {
        //   let item = {
        //     id: 234,
        //     name: '何维佳',
        //     totalresalenum: 23, // 邀约家长数
        //     commission_amount: 55, // 提成金额
        //     couponid: 46,
        //     couponredemptionnum: 12 // 领取数 == 报名数？？？
        //   };
        //   result.data.dataList.push(item);
        // }
        this.teacherPerformanceData.list = [];
        result.data.dataList.forEach(obj => {
          obj.resalenum = (obj.readPeopleNum || 0) + '人';
          obj.BMNum = (obj.couponredemptionnum || 0) + '人';
          obj.commission_amount = (Number(obj.commission_amount).toFixed(2) || '0.00') + '元';
          this.teacherPerformanceData.list.push(obj);
        });
        this.teacherPerformanceData.totalData = result.data.totalData;
        this.tableContentData = this.teacherPerformanceData.list;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取分销奖励
    loadDistributionRewards () {
      DistributionRewards(this.queryParamsForTable).then(result => {
        console.log('loadDistributionRewards', this.$utils.parseJson(result.data));
        result.data.PageDataList = result.data.PageDataList || [];
        this.distributionRewardData.list = [];
        result.data.PageDataList.forEach(obj => {
          obj.share_number = (obj.share_num || 0) + '次';
          obj.get_num = (obj.get_num || 0) + '人';
          if (obj.amountbonus && obj.amountbonus > 0) {
            obj.amountbonus = (Number(obj.amountbonus).toFixed(2) || '0.00') + '元';
          } else if (obj.fanhour_num > 0) {
            obj.amountbonus = obj.fanhour_num + '课时';
          } else if (obj.fangift_name) {
            obj.amountbonus = obj.fangift_name;
          }

          this.distributionRewardData.list.push(obj);
        });
        if (result.data.PageDataList.length < 10) {
          this.readMoreTextShow = false;
          this.readMoreTextForTable = '查看 10 条';
        } else {
          this.readMoreTextShow = true;
          this.readMoreTextForTable = '查看更多 10 条';
        }
        this.distributionRewardData.totalData = result.data.totalData;
        this.distributionRewardData.total = result.data.Total;
        this.tableContentData = this.distributionRewardData.list;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取介绍奖励信息
    loadIntroduceRewards () {
      IntroduceRewardQuery(this.queryParamsForTable).then(result => {
        console.log('loadIntroduceRewards', result.data);
        this.introduceRewardData.list = [];
        result.data.PageDataList.forEach(obj => {
          obj.username = obj.username;
          if (obj.sub_number != 0) {
            obj.sub_number = obj.get_number + '/' + obj.sub_number;
          } else {
            obj.sub_number = '已完成';
          }
          obj.get_number = obj.get_number + '人';
          if (obj.amountbonus && obj.amountbonus > 0) {
            obj.BMJL = (Number(obj.amountbonus).toFixed(2) || '0.00') + '元';
          } else if (obj.fanhour_num > 0) {
            obj.BMJL = obj.fanhour_num + '课时';
          } else if (obj.fangift_name) {
            obj.BMJL = obj.fangift_name;
          }
          this.introduceRewardData.list.push(obj);
        });
        if (result.data.PageDataList.length < 10) {
          this.readMoreTextShow = false;
          this.readMoreTextForTable = '查看 10 条';
        } else {
          this.readMoreTextShow = true;
          this.readMoreTextForTable = '查看更多 10 条';
        }
        this.introduceRewardData.totalData = result.data.totalData;
        this.introduceRewardData.total = result.data.Total;
        this.tableInfoFun();
        this.tableContentData = this.introduceRewardData.list;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取浏览奖励信息
    loadBrowseRrewards () {
      BrowseRrewards(this.queryParamsForTable).then(result => {
        console.log('loadBrowseRrewards11', result.data);
        this.browseRewardData.list = [];
        result.data.PageDataList.forEach(obj => {
          obj.share_number = (obj.share_number || 0) + '次';
          obj.readPersonNum = (obj.read_person_num || 0) + '人';
          if (obj.read_gift_status == 0 || !obj.read_gift_status) {
            obj.read_gift_status = 1;
          }
          this.browseRewardData.list.push(obj);
        });
        if (result.data.PageDataList.length < 10) {
          this.readMoreTextShow = false;
          this.readMoreTextForTable = '查看 10 条';
        } else {
          this.readMoreTextShow = true;
          this.readMoreTextForTable = '查看更多 10 条';
        }
        this.browseRewardData.totalData = result.data.totalData; // {totalreadnum: null, totalreadpersonnum: null, totalsharenum: null, standardnum: null}
        this.browseRewardData.total = result.data.Total;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取分销统计信息
    loadSalesStatisticsData () {
      DistributionReport(this.queryParamsForTable).then(result => {
        this.salesStatisticsData.list = [];
        result.data.PageDataList.forEach(obj => {
          obj.share_num = (obj.share_num || 0) + '次';
          obj.ResaleNum = (obj.ResaleNum || 0) + '人';
          obj.get_num = (obj.get_num || 0) + '人';
          this.salesStatisticsData.list.push(obj);
        });
        this.salesStatisticsData.totalData = result.data.totalData; // {totalreadnum: null, totalreadpersonnum: null, totalsharenum: null, standardnum: null}
        this.salesStatisticsData.total = result.data.Total;
        this.tableContentData = this.salesStatisticsData.list;
      });
    },
    // 历史战况中的加载更多.
    getMoreHistorySituation (farthestid, pageSize, callBack) {
      HistoricalSituation(this.selectedCouponInfo.id, farthestid, pageSize).then(result => {
        console.log('HistoricalSituation.length', result.data.dataList, result.data.dataList.length);
        // 历史战况 若没有数据 直接赋值 若有往后追加(查看更多)
        if (result.data.dataList.length == 0) {
          layer.alert('没有更多数据');
          return;
        }
        if (this.historicalSituationInfo && this.historicalSituationInfo.dataList.length > 0) {
          // 历史战况 只有首次需要更新 上次查询时间 只有心跳包才需要每次更新
          this.historicalSituationInfo.newtime = this.historicalSituationInfo.newtime ? result.data.newtime : this.historicalSituationInfo.newtime;
          result.data.dataList.forEach(obj => {
            this.historicalSituationInfo.dataList.push(obj);
          });
          // this.historicalSituationInfo.dataList = result.data.dataList;
        } else {
          this.historicalSituationInfo = result.data;
          if (this.historicalSituationInfo && this.historicalSituationInfo.dataList.length) {
            console.log('进来触发.');
            this.loadLatestCouponInfoById();
          }
        }
        if (result.data.dataList.length < 10) {
          this.readMoreText = '查看 10 条';
        } else {
          this.readMoreText = '查看更多 10 条';
        }
        if (callBack) {
          callBack();
        } else { // 点击 查看更多10条 触发
          this.$nextTick(() => {
            let scrollDom = document.getElementsByClassName('ZSJK_menuBox_current_box');
            if (scrollDom[0] && scrollDom[0].scrollHeight) {
              scrollDom[0].scrollTop = scrollDom[0].scrollHeight;
            }
          });
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取历史战况 报名=领取
    loadHistoricalSituation (farthestid, pageSize, callBack) {
      HistoricalSituation(this.selectedCouponInfo.id, farthestid, pageSize).then(result => {
        console.log('HistoricalSituation.length', result.data.dataList, result.data.dataList.length);
        // 历史战况 若没有数据 直接赋值 若有往后追加(查看更多)
        if (this.historicalSituationInfo && this.historicalSituationInfo.dataList.length > 0) {
          // 历史战况 只有首次需要更新 上次查询时间 只有心跳包才需要每次更新
          this.historicalSituationInfo.newtime = this.historicalSituationInfo.newtime ? result.data.newtime : this.historicalSituationInfo.newtime;
          // result.data.dataList.forEach(obj => {
          //   this.historicalSituationInfo.dataList.unshift(obj);
          // });
          this.historicalSituationInfo.dataList = result.data.dataList;
        } else {
          this.historicalSituationInfo = result.data;
          if (this.historicalSituationInfo && this.historicalSituationInfo.dataList.length) {
            console.log('进来触发.');
            this.loadLatestCouponInfoById();
          }
        }
        if (result.data.dataList.length < 12) {
          this.readMoreText = '查看 10 条';
        } else {
          this.readMoreText = '查看更多 10 条';
        }
        if (callBack) {
          callBack();
        } else { // 点击 查看更多10条 触发
          this.$nextTick(() => {
            let scrollDom = document.getElementsByClassName('ZSJK_menuBox_current_box');
            if (scrollDom[0] && scrollDom[0].scrollHeight) {
              scrollDom[0].scrollTop = scrollDom[0].scrollHeight;
            }
          });
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 心跳包 间隔10秒获取 (获取回来的数据：时间大于上次查询时间的 + 上次查询记录 更新了转发次数的)
    loadLatestActionQuery () {
      let farthestid = 0;
      if (this.historicalSituationInfo && this.historicalSituationInfo.dataList.length > 0) {
        farthestid = this.historicalSituationInfo.dataList[0].id;
      }
      LatestActionQuery(this.selectedCouponInfo.id, this.historicalSituationInfo.newtime, farthestid).then(result => {
        this.historicalSituationInfo.newtime = result.data.newtime;
        // 获取优惠券信息.
        GetCouponInfoById(this.selectedCouponInfo.id).then(result => {
          this.selectedCouponInfo = this.$utils.parseJson(result.data);

          this.initStatisticsData();
        }, error => {
          layer.alert(error.msg);
        });
        if (result.data.dataList.length > 0) {
          // 重新加载统计数据
          // 重新绚烂数据
          this.updateActivitySituation(result.data.dataList);
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 查看更多10 条
    loadMore () {
      let farthestid = 0;
      if (this.historicalSituationInfo.dataList.length > 0) {
        farthestid = this.historicalSituationInfo.dataList[this.historicalSituationInfo.dataList.length - 1].id;
      }
      this.getMoreHistorySituation(farthestid, 10);
    },
    // 重新绚烂数据（活动战况心跳包）
    updateActivitySituation (dataList) {
      console.log('updateActivitySituation', this.historicalSituationInfo.dataList, dataList);
      dataList.forEach(item => {
        let findIndex = this.historicalSituationInfo.dataList.findIndex(obj => {
          return obj.id == item.id;
        });
        // 新对象 合并 旧对象（因为两者结构相同 所以新对象会覆盖旧对象）
        if (findIndex >= 0) {
          this.historicalSituationInfo.dataList[findIndex] = Object.assign({}, this.historicalSituationInfo.dataList[findIndex], item);
          console.log('updateActivitySituation', this.historicalSituationInfo.dataList[findIndex]);
          // Object.assign(this.historicalSituationInfo.dataList[findIndex], item);
        } else {
          this.historicalSituationInfo.dataList.unshift(item);
        }
      });
      console.log(this.couponList, 'this.couponList', this.selectedCouponInfo.id);
      // 手动增加报名数.
      // 找到当前选中的券.
      let locaIndex = this.couponList.find(o => {
        return o.id == this.selectedCouponInfo.id;
      });
      if (locaIndex) {
        locaIndex.total_get_num = this.historicalSituationInfo.dataList.length;
        // 找到当前下标.
        let isRepeat = this.couponList.findIndex(p => {
          return p.id == this.selectedCouponInfo.id;
        });
        this.couponList[isRepeat] = locaIndex;
        this.selectedCouponInfo.total_get_num = this.historicalSituationInfo.dataList.length;
      }
      this.historicalSituationInfo.dataList.sort((a, b) => {
        return a.id > b.id;
      });
    },
    // 切换菜单
    menuSwitch (index) {
      this.unShareActive = false;
      this.searchTextForTable = '';
      this.tableActiveIndex = index;
      this.tableInfoFun();
      if (this.selectedCouponInfo.id) {
        this.queryParamsForTable = this.$utils.parseJson(initQueryParamsForTable);
        this.queryParamsForTable.id = this.selectedCouponInfo.id;
        this.queryTableData();
      } else {
        layer.alert('暂无数据');
      }
    },
    // 几个切换菜单项 查看更多
    loadMoreForTable () {
      this.queryParamsForTable.pageSize = this.queryParamsForTable.pageSize + 10;
      this.queryTableData();
    },
    // 查询几个菜单的tableData
    queryTableData () {
      switch (Number(this.tableActiveIndex)) { // tableType: { teacherPerformance: 1, distributionReward: 2, introduceReward: 3, browseReward: 4 },
        case this.tableType.groupBuying:
          // 拼团奖励
          this.loadGroupBuyingColumns();
          break;
        case this.tableType.teacherPerformance:
          // 老师提成
          this.loadTeacherCommission();
          break;
        case this.tableType.distributionReward:
          // 分销奖励
          this.loadDistributionRewards();
          break;
        case this.tableType.introduceReward:
          // 介绍奖励信息
          this.loadIntroduceRewards();
          break;
        case this.tableType.browseReward:
          // 浏览奖励
          this.loadBrowseRrewards();
          break;
        case this.tableType.salesStatistics:
          this.loadSalesStatisticsData();
          break;
        default:
          break;
      }
    },
    // 点击table 的详情
    doAfterClickDetail (item, eventName) {
      console.log('触发doAfterClickDetail');
      if (eventName == tableFunctionType.fangift) {
        console.log(item, 'tableFunctionType.fangift', eventName);
        if (item.read_gift_status == 2) {
          this.browseGiftDistribution(item);
        }
        return;
      }
      switch (Number(this.tableActiveIndex)) { // tableType: { teacherPerformance: 1, distributionReward: 2, introduceReward: 3, browseReward: 4 },
        case this.tableType.groupBuying:
          this.$refs.groupDetailList.isShowListDialog = true;
          this.$refs.groupDetailList.loadGroupBuyingDetail(item);
          break;
        case this.tableType.teacherPerformance:
          this.$refs.teacherDetailList.doAfterClickDetail(item, eventName);
          break;
        case this.tableType.distributionReward:
          this.$refs.disDetailList.doAfterClickDetail(item, eventName);
          break;
        case this.tableType.introduceReward:
          this.$refs.introDetailList.doAfterClickDetail(item, eventName);
          break;
        case this.tableType.browseReward:
          this.$refs.browseDetailList.doAfterClickDetail(item, eventName);
          break;
        case this.tableType.salesStatistics:
          this.$refs.salesStatisticsList.doAfterClickDetail(item, eventName);
          break;
        default:
          break;
      }
    },
    // 浏览礼品发放   用户券id id UserCouponKey
    browseGiftDistribution (rowData) {
      console.log('browseGiftDistribution', rowData);
      BrowseGiftDistribution(rowData.policytype, rowData.id).then(result => {
        console.log('BrowseGiftDistribution', result);
        // this.browseRewardData.list
        // 符合的当前下标
        let target = this.browseRewardData.list.findIndex(o => {
          return o.id == rowData.id;
        });
        // 符合的当前对象
        let targetItem = this.browseRewardData.list.find(o => {
          return o.id == rowData.id;
        });
        targetItem.read_gift_status = 3;
        // 替换/
        this.browseRewardData.list.splice(target, 1, targetItem);
        layer.alert('发送成功');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 导出
    exportClick () {
      let queryParams = this.$utils.parseJson(initQueryParamsForTable);
      if (this.selectedCouponInfo.id) {
        queryParams.pageIndex = 0; // 0位全部
        queryParams.pageSize = 0;// this.historicalSituationInfo.dataList.length;
        queryParams.isshare = '';
        queryParams.id = this.selectedCouponInfo.id;
        queryParams.IsExportKey = 1;
        let jsonFormate = {
          SheetTitle: '战况直播',
          clist: [
            { title: '客户姓名', cName: 'username', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '手机号', cName: 'telephone', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '转发次数', cName: 'share_number', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '领取次数', cName: 'get_number', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '来源人姓名', cName: 'sourceusername', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '领取来源', cName: 'SourceKeyValue', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' }
          ]
        };
        CouponRedemptionDetails(queryParams, jsonFormate).then(result => {
          console.log(result.data, '导出');
          if (result.data.url) {
            window.location.href = this.$store.getters.APIURL + result.data.url;
          } else {
            layer.alert('无数据可导出');
          }
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
    },
    // 刷新拼团数据
    refreshGroupList () {
      this.queryTableData();
    },
    // 刷新
    refresh () {
      this.pageIndex = 0;
      this.couponList.length = 0;
      this.loadAllCouponList(this.pageIndex, 20, 'DESC', '', '', 0, 0, 0, () => {
        if (this.couponList.length > 0) {
          this.couponList[0].isSelected = true;
          this.changeSelectionCoupon(this.couponList[0]);
          this.selectedCouponInfo = this.couponList[0];
          this.pageIndex++;
          this.$nextTick(() => {
            this.swiperIndex(0);
          });
        } else {
          this.promptMsg = '暂无数据';
        }
      });
    },
    // 上一页
    prePage () {
      this.swiperIndex(this.swiperActiveIndex - 4);
    },
    // 下一页
    nextPage () {
      this.loadAllCouponList(this.pageIndex, 20, 'DESC', '', '', 0, 0, 0, () => {
        this.swiperIndex(this.swiperActiveIndex + 4);
        this.pageIndex++;
      });
    },
    // 点击二维码
    clickQRCode () {
      if (!this.isShowQRCode) {
        document.getElementById('QRCode').innerHTML = ''; // 清除二维码
        this.twoCode = this.$store.getters.wktDomainURL.replace('http:', 'https:') + '/do/shareCouponToGet.php?coupon_id=' + this.selectedCouponInfo.id + '&source_type=1&share_user_id=0&SaaSClientKey=' + this.$store.getters.SaaSClientInfo.OLAPKey;
        var qrcode = new QRCode(document.getElementById('QRCode'), {
          correctLevel: QRCode.CorrectLevel.L // 容错级别，
        });
        qrcode.makeCode(this.twoCode);
      }
      this.isShowQRCode = !this.isShowQRCode;
    },
    // 未转发
    notForwardingClick () {
      this.$refs.receiveDetailList.notForwardingClick();
    },
    // 初始化swiper.
    swiperInit () {
      let that = this;
      this.swiper = new Swiper('.swiper-container', {
        breakpoints: {
          1920: {
            slidesPerView: 4
          },
          1440: {
            slidesPerView: 4
          }
        },
        spaceBetween: 20,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        on: {
          slidePrevTransitionEnd: function () {
            that.$nextTick(() => {
              // that.swiperIndex(this.swiperActiveIndex);
            });
          },
          slideNextTransitionEnd: function () {         // 拖动结束后触发事件预加载. 判断最右边隐藏的课程数据是否小于十,activeIndex为内部的下标属性.
            if (that.couponList.length > 0) {
              that.swiperActiveIndex = that.swiper.activeIndex;       // 修改当前页面显示的第一个位置.
              console.log(that.swiperActiveIndex, '左拖动.', that.swiper.activeIndex);
              if (that.couponList.length - that.swiper.activeIndex <= 10) {
                that.loadAllCouponList(that.pageIndex, 20, 'DESC', '', '', 0, 0, 0, () => {
                  that.pageIndex++;
                  that.swiper.slideTo(that.swiper.activeIndex);
                });
              }
              //  swiper.update();
            }
          }
          // slideNextTransitionEnd: function () {
          //   that.$nextTick(() => {
          //     that.swiperIndex(this.activeIndex);
          //   });
          // }
        }
      });
    },
    // 刷新swipe滚动条
    swiperIndex (val) {
      if (val < 0) {
        val = 0;
      }
      this.swiperActiveIndex = val;
      this.swiper.updateSlides();
      this.swiper.slideTo(val);
    },
    // 点击 弹出层列表的右上角按钮
    doAfterFunctionBtnClick () {

    }
  }
};
</script>
<style>
</style>