<template>
  <div class="from_list" :class="required?'from_list_required':''">
    <div class="from_list_title" v-if="titleName">{{titleName}}</div>
      <div class="from_list_content">
        <div class="from_list_checked_box">
          <el-checkbox v-model="checked" @change="changeChecked"></el-checkbox>
          <span>{{checkedName}}</span>
          <span class="font_down_gray">{{checkedExplain}}</span>
        </div>
      </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      checked: false
    };
  },
  props: {
    titleName: {//  标题 （选填）
      type: String,
      default: ''
    },
    required: {//  是否必填 （选填）
      type: Boolean,
      default: false
    },
    checkedValue: {//  复选框值 （选填）
      type: Boolean,
      default: false
    },
    checkedName: {//   复选框名称（选填）
      type: String,
      default: '限新生领取'
    },
    checkedExplain: {//  复选框说明文本 （选填）
      type: String,
      default: '开启后在校学生不可领取'
    }
  },
  created () {
    this.checked = this.checkedValue;
  },
  methods: {
    changeChecked (val) {
      this.$emit('changeChecked', val);
    }
  }

};
</script>

<style>
</style>