
  <template>
  <!-- 待 处理页面组件 -->
  <div>
    <div class="income_expenditure_manage_dispose_list form_info_edit single_shadow"
         v-if="incomePayDialogVisible">
      <div class="form_info_line">
        <div class="form_info_field">
          <span>收支流水</span>
        </div>
        <div class="form_info_value">
          <input type="text"
                 placeholder
                 disabled="disabled"
                 :value="processedItem.SourceKeyName" />
        </div>
      </div>

      <div class="form_info_line"
           v-if="processedItem.IncomePayTypeKey ==1">
        <div class="form_info_field">
          <span>收入金额</span>
        </div>
        <div class="form_info_value">
          <input type="text"
                 placeholder="0.00"
                 disabled="disabled"
                 :value="Number(processedItem.InAmount).toFixed(2)" />
        </div>
      </div>
      <div class="form_info_line"
           v-if="processedItem.IncomePayTypeKey ==2">
        <div class="form_info_field">
          <span>支出金额</span>
        </div>
        <div class="form_info_value">
          <input type="text"
                 placeholder="必填"
                 disabled="disabled"
                 :value=" Number(processedItem.PayAmount).toFixed(2)" />
        </div>
      </div>
      <div class="form_info_line form_info_required">
        <div class="form_info_field">收支账户</div>
        <div class="form_info_value form_info_select">
          <el-select :value="vIncomePayAccountKeyValue"
                     placeholder="必选"
                     @change="accountEvent"
                     value-key="OLAPKey">
            <el-option v-for="item in incomePayAccountList"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item"></el-option>
          </el-select>
        </div>
      </div>
      <div v-if="processedItem.IncomePayTypeKey ==1">
        <div class="form_info_line form_info_required">
          <div class="form_info_field">收支科目</div>
          <div class="form_info_value form_info_select">
            <el-select :value="vIncomePaySubKeyValue"
                       placeholder="必选"
                       @change="subjectEvent"
                       value-key="OLAPKey">
              <el-option v-for="item in subjectListS"
                         :key="item.OLAPKey"
                         :label="item.MainDemoName"
                         :value="item"></el-option>
            </el-select>
          </div>
        </div>
      </div>

      <div v-else-if="processedItem.IncomePayTypeKey ==2">
        <div class="form_info_line form_info_required">
          <div class="form_info_field">收支科目</div>
          <div class="form_info_value form_info_select">
            <el-select :value="vIncomePaySubKeyValue"
                       placeholder="必选"
                       @change="subjectEvent"
                       value-key="OLAPKey">
              <el-option v-for="item in subjectListZ"
                         :key="item.OLAPKey"
                         :label="item.MainDemoName"
                         :value="item"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="form_info_line">
        <div class="form_info_field">
          <span>发生人类型</span>
        </div>
        <div class="form_info_value">
          <input type="text"
                 placeholder
                 disabled="disabled"
                 :value="processedItem.HapObjTypeKeyValue" />
        </div>
      </div>
      <div class="form_info_line">
        <div class="form_info_field">
          <!-- 客户 -->
          <span>{{processedItem.HapObjTypeKeyValue}}</span>
        </div>
        <div class="form_info_value">
          <input type="text"
                 placeholder="必选"
                 disabled="disabled"
                 :value="processedItem.HapPeolKeyValue.replace('(客户)', '').replace('(供应商)', '').replace('(员工)', '')" />
        </div>
      </div>
      <div class="form_info_line no_border_bottom">
        <div class="form_info_field">
          <span>备 注</span>
        </div>
        <div class="form_info_value">
          <input type="text"
                 placeholder="选填"
                 v-model="commentSupport" />
        </div>
      </div>
    </div>
    <div class="footer_btn_group form_footer_btn_group">
      <el-button round
                 class="btn_light_red btn_hover_white"
                 :class="vHasModulePower(42)?'':'norole'"
                 @click="cancellationButton(processedItem)">作废</el-button>
      <el-button round
                 class="btn_light_blue btn_hover_bg_blue"
                 :class="vHasModulePower(42)?'':'norole'"
                 @click="submitButton(processedItem)">提交</el-button>
    </div>
  </div>
</template>
<script>
import {
  InvalidateSZGL,
  DoSZGL,
  getIncomeAccount,
  getIncomeSubject
} from '../../../API/workbench.js';
export default {
  data () {
    return {
      incomePayAccountList: [], // 收支账户列表
      incomePaySubjectList: [], // 收支科目列表

      incomePayDialogVisible: true, //
      showSZZH: '', //
      showTypeSZKMS: false,
      showTypeSZKMZ: false,

      InPaySubKey: this.processedItem.IncomePaySubKey, // 收支科目Key
      InPaySubKeyValue: this.processedItem.IncomePaySubKey == 0 ? '' : this.processedItem.IncomePaySubKeyValue, // 收支科目
      InPayAccountKey: this.processedItem.IncomePayAccountKey, // 收款账户Key
      InPayAccountKeyValue: this.processedItem.IncomePayAccountKey == 0 ? '' : this.processedItem.IncomePayAccountKeyValue, // 收款账户
      commentSupport: this.processedItem.CommentSupport // 备注
    };
  },
  props: ['processedItem'],
  created () {
    this.IncomeAccount();
    this.IncomeSubject();
  },
  watch: {},
  methods: {
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    },
    // 获取收支账户列表
    IncomeAccount () {
      getIncomeAccount().then(result => {
        if (result.data && result.data.length > 0) {
          this.incomePayAccountList = result.data;
          // this.incomePayDialogVisible = false;
          // this.showSZZH = true;
        } else {
          layer.alert('接口异常，注意检查');
        }
      });
    },
    // 获取收支科目列表
    IncomeSubject () {
      getIncomeSubject().then(result => {
        if (result.data && result.data.length > 0) {
          this.incomePaySubjectList = result.data;
        } else {
          layer.alert('接口异常，注意检查');
        }
        // if (index == 1) {
        //   this.incomePayDialogVisible = false;
        //   this.showTypeSZKMS = true;
        // } else {
        //   this.incomePayDialogVisible = false;
        //   this.showTypeSZKMZ = true;
        // }
      });
    },

    // 选中账户
    accountEvent (item) {
      if (item) {
        this.InPayAccountKey = item.OLAPKey;
        this.InPayAccountKeyValue = item.MainDemoName;
        this.returnClick();
        console.log(item, '选中账户');
      }
    },
    // 选中科目
    subjectEvent (item) {
      if (item) {
        this.InPaySubKey = item.OLAPKey;
        this.InPaySubKeyValue = item.MainDemoName;
        this.returnClick();
      }
    },
    // 返回待处理详情页
    returnClick () {
      this.incomePayDialogVisible = true;
      this.showSZZH = false;
      this.showTypeSZKMS = false;
      this.showTypeSZKMZ = false;
    },
    // 待处理详情-提交按钮
    submitButton (item) {
      if (this.vHasModulePower(42)) {
        // 收支账户验证
        if (!this.InPayAccountKeyValue) {
          layer.alert('请选择您的收支账户');
        } else if (!this.InPaySubKeyValue) {
          layer.alert('请选择您的收支科目');
          // 验证成功
        } else {
          var _this = this;
          // layer.confirm('请确认是否提交?', {
          //   btn: [
          //     {
          //       name: '确认',
          //       callBack: function () {
          //         layer.close();
          DoSZGL(
            item.TableID,
            _this.InPaySubKey,
            _this.InPaySubKeyValue,
            _this.InPayAccountKey,
            _this.InPayAccountKeyValue,
            _this.commentSupport
          ).then(
            result => {
              layer.alert('提交成功');
              _this.$emit('processedPass');
            },
            error => {
              layer.alert(error.msg);
            }
          );
          //       }
          //     }
          //   ]
          // });
        }
      } else {
        this.$utils.CheckModulePowerToTips(42);
      }
    },
    // 待处理详情-作废按钮
    cancellationButton (item) {
      if (this.vHasModulePower(42)) {
        var _this = this;
        layer.confirm('请确认是否作废?', {
          btn: [
            {
              name: '确认',
              callBack: function () {
                layer.close();
                console.log(_this.processedItem);
                if (_this.processedItem.HapSourceKeyKey != 5) {
                  // 不是 人工收支，不可作废
                  layer.alert(
                    '仅限人工收支单据,' +
                    _this.processedItem.HapSourceKeyKeyValue +
                    '单据不支持作废。'
                  );
                } else {
                  InvalidateSZGL(item.TableID).then(
                    result => {
                      layer.alert('作废成功');
                      _this.$emit('processedPass');
                    },
                    error => {
                      layer.alert(error.msg);
                    }
                  );
                }
              }
            }
          ]
        });
      } else {
        this.$utils.CheckModulePowerToTips(42);
      }
    }
  },
  computed: {
    // 过滤收支科目(收)
    subjectListS () {
      let SubjectResult = [];
      this.incomePaySubjectList.map(item => {
        // 过滤为收的科目并且兼容内部组件数据结构
        if (item.IncomePayTypeKey == 1) {
          SubjectResult.push(item);
        }
      });
      return SubjectResult;
    },
    // 过滤收支科目(支)
    subjectListZ () {
      let SubjectResult = [];
      this.incomePaySubjectList.map(item => {
        if (item.IncomePayTypeKey == 2) {
          SubjectResult.push(item);
        }
      });
      return SubjectResult;
    },
    vIncomePaySubKeyValue () {
      return this.InPaySubKey == 0 ? '' : this.InPaySubKeyValue;
    },
    vIncomePayAccountKeyValue () {
      return this.processedItem.InPayAccountKey == 0 ? '' : this.InPayAccountKeyValue;
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    }
  }
};
</script>

<style>
</style>

