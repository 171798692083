<template>
  <div class="img_box">
    <div class="content_bannber">
      <div v-if="dataInfo.AttachViewUrl"
           class="content_banner_box video_type">
        <video id="myVideo"
               class="video-js vjs-default-skin"
               width="1000"
               height="562"
               crossOrigin="anonymous"
               controls>
          <source :src="vVideoUrl"
                  type="application/vnd.apple.mpegURL" />
        </video>
        <div class="watermark">
          {{ watermark }}
        </div>
      </div>
      <div v-else
           class="content_banner_box">
        <img :src="dataInfo.CoverUrl"
             class="content_banner_box_img"
             :style="{maxWidth: vMaxWidth,maxHeight:vMaxHeight}" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      myVideo: null
    };
  },
  props: {
    dataInfo: Object,
    watermark: String
  },
  computed: {
    vVideoUrl () {
      if (this.dataInfo.AttachViewUrl) {
        return this.dataInfo.AttachViewUrl + '?sign=' + this.$store.getters.signData.Sign;
      } else {
        return '';
      }
    },
    vMaxWidth () {
      return document.documentElement.clientWidth + 'px';
    },
    vMaxHeight () {
      return document.documentElement.clientHeight + 'px';
    }
  },
  watch: {
    vVideoUrl (n, o) {
      if (o && n != o) {
        this.myVideo.reset();
        this.myVideo.src({
          src: this.vVideoUrl,
          type: 'application/vnd.apple.mpegURL'
        });
        this.myVideo.load();
      }
      if (this.dataInfo.currentTime && n == o) {
        this.myVideo.currentTime(this.dataInfo.currentTime);
        this.myVideo.play();
      }
    }
  },
  mounted () {
    console.log(this.vVideoUrl, 'vVideoUrl');
    this.openVideo();
  },
  destroyed () {
    this.dataInfo.currentTime = this.myVideo.currentTime();
    this.myVideo.dispose();
  },
  methods: {
    showError (error) {
      alert('不支持该视频的播放');
      console.error(error);
    },
    openVideo () {
      let self = this;
      this.myVideo = videojs('myVideo', {
        fluid: true,
        bigPlayButton: true,
        textTrackDisplay: false,
        poster: false,
        errorDisplay: false,
        FullscreenToggle: false,
        LoadProgressBar: false,
        PlayProgressBar: false,
        ProgressControl: false
      }, function onPlayerReady () {
        this.on('error', function (error) { // 监听错误
          self.showError(error);
        });
      });
      this.myVideo.src({
        src: this.vVideoUrl,
        type: 'application/vnd.apple.mpegURL'
      });
      if (this.dataInfo.currentTime) {
        this.myVideo.currentTime(this.dataInfo.currentTime);
        this.myVideo.play();
      }
    }
  }
};
</script>