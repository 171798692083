<template>
  <!-- 出入库记录 -->
  <div class="report_margin_list">
    <list ref="inventoryDetail"
          class="summarizing_list"
          :tableData="stockRecordList"
          :tableColumns="tableColumns"
          :paramsInfo="paramsInfo"
          :totalNum="totalNum"
          :rowKey="'Number'"
          :defaultSort="defaultSort"
          @chgSearchValue="searchGoodsData"
          @doExportInfoClick="doExportInfoClick"></list>

  </div>
</template>
<script>
import {
  MessageSearchStockInOutRecord
} from '../../../../API/workbench.js';
import list from './record-table';
export default {
  data () {
    return {
      // 出入库记录查询
      paramsInfo: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母
        startTime: '', // 	字符串	可选		数据源：GET	开始时间
        endTime: '', // 	字符串	可选		数据源：GET	结束时间
        orderBy: 'GenerateTime', //	字符串	可选		数据源：GET	排序字段
        sequence: 'desc', //	字符串	可选		数据源：GET	排序顺序
        GoodsKey: '', // 物品key
        GoodsTypeKey: '', // 物品类型key
        GoodsTypeKeyValue: '', // 物品类型key
        TypeKey: 0, // 0-全部;1-入库;2-出库;3-调增;4-调减;5-售出;6-退货
        TypeKeyValue: '',
        IsExportKey: 0 // 是否导出：0-否;1-是;如果为是pageIndex传0
      },
      stockRecordList: [], //  出入库记录列表
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          type: 'table-index',
          className: 'table_index_gray',
          sortable: false,
          width: 70
        },
        {
          label: '发生日期',
          prop: 'GenerateTime',
          type: 'date-item',
          sortable: 'custom',
          width: 146,
          extend: {
            dateType: 'YYMMRR HH:DD' // 日期类型
          }
        },
        {
          label: '经办人',
          prop: 'OperatorKeyValue',
          type: 'text-item',
          sortable: 'custom',
          align: 'left',
          width: 90
        },
        {
          label: '类型',
          prop: 'TypeKeyValue',
          type: 'text-item',
          sortable: 'custom',
          align: 'left',
          width: 70
        },
        {
          label: '物品类型',
          prop: 'GoodsTypeKeyValue',
          type: 'text-item',
          sortable: 'custom',
          align: 'left',
          width: 105
        },
        {
          label: '物品名称',
          prop: 'GoodsKeyValue',
          type: 'text-item',
          sortable: 'custom',
          align: 'left',
          width: 105
        },
        {
          label: '出入库数量',
          prop: 'AdjustCount',
          type: 'text-item',
          sortable: 'custom',
          align: 'left',
          width: 86,
          columnClass: 'font_dual'
        },
        {
          label: '剩余库存',
          prop: 'CurrentInventoryCount',
          type: 'amount-item',
          sortable: 'custom',
          align: 'left',
          width: 86,
          columnClass: 'font_dual width_small'
        },
        {
          label: '出入库单价',
          prop: 'UnitPriceAmount',
          type: 'amount-item',
          decimalPlace: 2,
          sortable: 'custom',
          align: 'left',
          width: 86,
          columnClass: 'font_dual'
        },
        {
          label: '出入库总价',
          prop: 'TotalAmount',
          type: 'text-item',
          sortable: 'custom',
          align: 'left',
          width: 86,
          columnClass: 'font_dual'
        },
        {
          label: '出库毛利',
          prop: 'MarginAmount',
          type: 'text-item',
          sortable: 'custom',
          align: 'left',
          width: ' ',
          columnClass: 'font_dual width_small'
        },
        {
          label: '备注',
          width: '',
          prop: 'RemarkSupport',
          type: 'remarks-item',
          sortable: false
        }
      ],
      PageCount: 0,
      totalNum: 0,
      // summary: {
      //   needSummariesColumns: [],
      //   isShowSummary: false,
      //   summaryName: '合计',
      //   summaryData: []
      // }, recordInfo
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  components: {
    list
  },
  props: {
    tableData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  created () {
    console.log(this.tableData, 'tableData');
    if (Number(this.tableData.OLAPKey) > 0) {
      // this.paramsInfo.GoodsTypeKeyValue = '';
      // this.paramsInfo.GoodsTypeKey = this.tableData.GoodsTypeKey;
      this.paramsInfo.GoodsKey = this.tableData.OLAPKey;
      this.paramsInfo.searchText = this.tableData.MainDemoName;
    }
    this.getMessageSearchStockInOutRecord();
  },
  computed: {

  },
  methods: {
    // 获取出入库记录
    getMessageSearchStockInOutRecord () {
      MessageSearchStockInOutRecord(this.paramsInfo).then(result => {
        this.totalNum = result.data.Total;
        this.stockRecordList = [];
        if (result.data && result.data.PageDataList.length > 0) {
          // result.data.PageDataList.forEach(o => {
          //   o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
          // });
          this.stockRecordList = result.data.PageDataList;
          console.log(this.stockRecordList, '获取出入库记录');
        }
      }).finally(() => {

      });
    },
    // 导出入库记录
    doExportInfoClick () {
      if (this.stockRecordList.length > 0) {
        let obj = this.$utils.parseJson(this.paramsInfo);
        obj.IsExportKey = 1;
        obj.pageIndex = 0;
        obj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '出入库记录表',
          clist: [
            { title: '发生日期', cName: 'GenerateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '经办人', cName: 'OperatorKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '类型', cName: 'TypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '物品类型', cName: 'GoodsTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '物品名称', cName: 'GoodsKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '出入库数量', cName: 'AdjustCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '剩余库存', cName: 'CurrentInventoryCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '出入库单价', cName: 'UnitPriceAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '出入库总价', cName: 'TotalAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '出库毛利', cName: 'MarginAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '备注', cName: 'RemarkSupport', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchStockInOutRecord(obj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
    },
    // 物品设置成功后的刷新
    searchGoodsData (params) {
      console.log(params, '选择搜索类型');
      if (params) {
        this.paramsInfo = params;
      }
      if (!params.searchText) {
        this.paramsInfo.GoodsKey = '';
      }
      this.getMessageSearchStockInOutRecord();
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName) {
      console.log(rowData, eventName, '按钮点击');
    }
  }

};
</script>

