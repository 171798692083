<template>
  <div class="single_result_detail">
    <!-- 学生个人信息 -->
    <div>
      <student-lable class="content_marginRL single_shadow"
                     :studentID="newInitJson.OLAPKey"
                     :CustStatusKey="1"
                     :isShowaddOldStudentBtn="true"
                     :mobileSupport="newInitJson.MobileSupport"
                     @alterStudent="alterStudent">
      </student-lable>
    </div>
    <!-- 已购课单 -->
    <div>
      <div class="single_result_courses_purchased content_marginRL">
        <div class="courses_purchased_list"
             :class="{'courses_purchased_list_padding': allStudentCourseList.length >0}"
             @scroll="scrollLEFT"
             ref="dataScroll">
          <alreadyBuyCourse v-for="(item,index) in allStudentCourseList"
                            :key="index"
                            :allStudentCourseItem="item"
                            :allStudentCourseList="allStudentCourseList"
                            @selectedItem="selectedItem"></alreadyBuyCourse>
        </div>
      </div>
    </div>
    <!-- 课 单 详细信息 -->
    <div class="single_result_courses_info content_marginBottom_30 content_marginRL"
         v-if="allStudentCourseFormShow&&allStudentCourseList.length>0">
      <!-- 1. 可编辑 courses_info_edit (界面显示修改按钮)
                   2. 只读 courses_info_readonly
                   3. 表单界面位于中间的时候添加距离顶部间距  content_marginTop_8
              -->
      <div class=" courses_info_edit coruse_change form_info_edit content_marginTop_8 form_info_list single_shadow ">
        <div class="form_info_line">
          <div class="form_info_field">
            <span>课单号</span>
          </div>
          <div class="form_info_value">
            <input type="text"
                   id="focusInput"
                   placeholder="课单号"
                   readonly
                   :value="selectCourseFormItem.NumberName">
          </div>
        </div>
        <div class="form_info_line">
          <div class="form_info_field">
            <span>购买日期</span>
          </div>
          <div class="form_info_value">
            <input type="text"
                   placeholder="暂无日期"
                   readonly
                   :value="PaidTime">
          </div>
        </div>
        <div class="form_info_line">
          <div class="form_info_field">
            <span>购买数量</span>
          </div>
          <div class="form_info_value">
            <input type="text"
                   placeholder="购买数量"
                   readonly
                   :value="NumCount+vUnitKeyValue">
          </div>
        </div>
        <div class="form_info_line">
          <div class="form_info_field">
            <span>有效期</span>
          </div>
          <div class="form_info_value">
            <input type="text"
                   placeholder="有效期"
                   readonly
                   :value="formatDate(selectCourseFormItem.StartTime,selectCourseFormItem.ExpireTime)">
          </div>
        </div>

        <div>
          <input-dialog :formTitle="'适用课程'"
                        :formPlaceholder="'选填'"
                        :readonly="false"
                        :required="false"
                        @showEldilog="amendItem(1)"
                        v-model="selectCourseFormItem.ApplyCorName"></input-dialog>
        </div>

        <div>
          <input-dialog :formTitle="'剩余数量'"
                        :formPlaceholder="'选填'"
                        :readonly="selectCourseFormItem.UnitKey !=5 || !changeState"
                        :required="false"
                        @showEldilog="amendItem(2)"
                        :value="selectCourseFormItem.UnitKey !=5?'--': $utils.mAmountType(selectCourseFormItem.CurrentCount,1) + vUnitKeyValue">
          </input-dialog>
        </div>

        <div>
          <input-dialog :formTitle="'剩余天数'"
                        :formPlaceholder="'选填'"
                        :required="false"
                        @showEldilog="amendItem(3)"
                        :value="Number(selectCourseFormItem.PeriodCount) +'天'"></input-dialog>
        </div>

        <div class="form_info_line">
          <div class="form_info_field">
            <span>剩余价值</span>
          </div>
          <div class="form_info_value">
            <input type="text"
                   placeholder="0.00元"
                   readonly
                   :value="ValueAmount(selectCourseFormItem.ValueAmount)+'元'">
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="allStudentCourseList.length==0">
      <div class="course_block_nodata">
        <div class="monitoring_content_nodata_txt">该学生暂无课单</div>
      </div>
    </div>
  </div>
</template>
<script>
// 修改课单
import studentLable from '../../../../components/common/student-lable';
import alreadyBuyCourse from '../../../../components/common/already-buy-course-item';
import { GetTheClassType } from '../../../../API/workbench.js';
export default {
  props: ['isSelectedStudentItem', 'allStudentCourseList'], // 需绑定到上面
  data () {
    return {
      // 学生个人信息
      newInitJson: this.isSelectedStudentItem,
      allStudentCourseFormShow: false,
      selectCourseFormItem: {},
      changeState: true,
      courseheClassInfo: {}
    };
  },
  components: {
    studentLable,
    alreadyBuyCourse
  },
  created () {

  },
  watch: {

  },
  methods: {
    formatDate (start,end) {
      let str='';
      if (start && start.indexOf('1900-01-01') < 0) {
        str=start.replace(/-/g, '.') + ' 至 ' + end.replace(/-/g, '.');
      } else {
        str=end.replace(/-/g, '.') + '到期';
      }
      return str;
    },
    // 滚动加载事件.
    scrollLEFT (e) {

    },
    ValueAmount (Value) {                          // 剩余价值
      return this.$utils.mAmountType(Number(Value), 2);
    },
    amendItem (optionItem) {
      if (optionItem == 1) {
        this.$emit('optionItem', '调整适用的课', this.selectCourseFormItem.ApplyCorIDsName, this.selectCourseFormItem.ApplyCorName);
      } else if (optionItem == 2) {
        this.$emit('optionItem', '调整剩余数量', Number(this.selectCourseFormItem.CurrentCount));
      } else {
        this.$emit('optionItem', '调整剩余天数', Number(this.selectCourseFormItem.PeriodCount));
      }
    },
    alterStudent (item) {
      // this.newInitJson.OLAPKey = item.OLAPKey;
      this.$emit('alterStudent', item);
    },
    focusInput () {
      document.getElementById('focusInput').focus();
    },
    selectedItem (item, val) {
      this.selectCourseFormItem = item;
      console.log(item, 'selectedItem');
      if (item) {
        if (item.ExpireTime && this.$utils.getFormatDateToQuery(item.ExpireTime) >= Date.new(Date.new().toLocaleDateString())) {
          this.changeState = true;
        } else {
          this.changeState = false;
        }
        GetTheClassType(item.ApplyCorIDsName).then(res => {
          console.log(res.data, 'res.data');
          this.courseheClassInfo = res.data;
        }, err => {
          layer.alert(err.msg);
        });
      }
      //  选中课单位置
      this.$nextTick(() => {
        this.allStudentCourseList.forEach((o, i) => {
          if (this.selectCourseFormItem.TableID == o.TableID) {
            // console.log(i, "课单下标")
            this.$emit('CourseIndex', i);
            if (!val) {
              this.$refs.dataScroll.scrollLeft = (i * 205) + (i * 2);
            }
          }
        });
      });
      if (!val) {
        this.$refs.dataScroll.scrollLeft = 100;
      }
      this.allStudentCourseFormShow = true;
      this.$emit('studentFileItem', item);
    }
  },
  computed: {
    vUnitKeyValue () {
      if (this.selectCourseFormItem.UnitKeyValue == '日') {
        return '天';
      } else if (this.selectCourseFormItem.UnitKeyValue == '月') {
        return '个月';
      } else {
        return this.selectCourseFormItem.UnitKeyValue;
      }
    },
    NumCount () {
      return this.$utils.mAmountType(Number(this.selectCourseFormItem.NumCount), 1);
    },
    PaidTime () {
      if (this.selectCourseFormItem.PaidTime) {
        return Date.new(this.selectCourseFormItem.PaidTime).toLocaleDateString().replace(/\//g, '.');
      } else {
        return '';
      }
    }
  }
};
</script>
<style>
.vedit_btn span {
  color: lightsteelblue;
  font-size: 12px;
  border-bottom: 1px solid #e2e5ed;
}
</style>
