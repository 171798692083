import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
import * as utils from '../utils/index.js';
import { outLogin } from '../assets/login';
import fun from '../assets/KPI';
import ClassGrade from '../components/base-module/class-grade/index.vue';
import applyGrade from '../components/base-module/apply-grade/index.vue';
import attentionGrade from '../components/base-module/attention-grade/index.vue';
import classCountAmount from '../components/base-module/class-count-amount/index.vue';
import classSpendGrade from '../components/base-module/class-spend-grade/index.vue';
import exceptionGrade from '../components/base-module/exception-grade/index.vue';
import teacherGrade from '../components/base-module/teacher-grade/index.vue';
import customerClew from '../components/base-module/customer-clew/index.vue';
import indexView from '../components/new-index-view/index.vue';
import newIndexView from '../components/new-index-view/new-index.vue';
import homePage from '../components/home-page/index.vue';
import login from '../components/login/index.vue';
import CourseTable from '../components/course-table/schedule-course-table/index';
import appointmentCourse from '../components/course-table/appointment-course/index';
import applyCourseReport from '../components/report/apply-course/index';
import presidentKanban from '../components/president-kanban/index.vue';
import mendiansettingform from '../components/base-module/basis-setting-module/mendian-setting-from/index.vue'; // 门店设置模块
import studentManagementReport from '../components/report/student-management/index';
import allStudent from '../components/report/all-student/index';
import tryArrangeCourse from '../components/report/try-arrange-course';
import recycledStudent from '../components/report/recycled-student/index';
import classGradeApplycourse from '../components/report/class-grade-applycourse/index.vue';
import packageApplyCourseReport from '../components/report/package-apply-course/index';
import courseNameApplyCourseReport from '../components/report/course-name-apply-course/index';
import courseScheduleManagement from '../components/report/course-schedule-management/index';// 课程排课 / 课程管理
import courseArrangement from '../components/report/course-arrangement/index';
import newCourseArrangement from '../components/report/new-course-arrangement/index';
import mendianOperationManagement from '../components/report/mendian-operation-management';
import superManagement from '../components/report/super-management/index'; // 超级管理模块
import classSpendStatisticsAnalyze from '../components/report/class-spend-statistics-analyze/index';
import receiptAndPaymentStatistics from '../components/report/receipt-and-payment-statistics/index';
import operatingRevenueStatistics from '../components/report/operating-revenue-statistics/index';
import moreMendianReport from '../components/report/more-mendian-report/index';
import teacherPerformance from '../components/report/teacher-performance/index';
import registrationSupplementaryCorrection from '../components/registration-supplementary-correction/index';
import wktBack from '../components/wkt-back/index';
import inventoryManagementFile from '../components/report/inventory-management-file'; // 库存管理
import activityMonitoringPlatform from '../components/wkt-back/activity-monitoring-platform';
import recruitStudentShow from '../components/recruit-student-show';
import customerManagement from '../components/report/customer-management/index.vue'; // 客户管理模块
import creditManagement from '../components/report/credit-management/index.vue'; // 学分管理
import managementAndStatistics from '../components/report/management-and-statistics/index.vue'; // 管理与统计
import growthTime from '../components/growth-time/index.vue'; // 管理与统计
import campusManagement from '../components/head-quarters/headquarters-management/campus-management/index.vue'; // 校区管理
import retailManagement from '../components/head-quarters/headquarters-management/retail-management/index.vue'; // 直营管理
import franchiseeManagement from '../components/head-quarters/headquarters-management/franchisee-management/index.vue'; // 加盟管理
import authorizationCourseware from '../components/head-quarters/headquarters-management/authorization-courseware/index.vue'; // 授权课件
import distributeCoursewareTable from '../components/head-quarters/distribute-ourseware-table/index'; // 课件分发
import generalCoursewareManagement from '../components/courseware-management/index.vue'; // 课件管理-普通
import hqCoursewareManagement from '../components/head-quarters/headquarters-management/courseware-management/index.vue'; // 课件管理-总部
import teacherAttenClass from '../components/teacher-atten-class/index.vue';
import newTeacherAttenClass from '../components/teacher-atten-class/new-index.vue';

import chainWorkBench from '../components/chain-work-bench/index.vue';
import newChainWorkBench from '../components/new-chain-work-bench/index.vue';
import coursewareTeaching from '../components/courseware-teaching/index.vue';
import coursewareTeachingView from '../components/courseware-teaching-view/index.vue';
import classListQueryStatistics from '../components/report/class-list-query-statistics'; // 课单查询统计
import crmJoinSignManage from '../components/report/crm-join-sign-manage/index';
import crmContactRecycleBin from '../components/report/crm-join-sign-manage/crm-contact-recycle-bin/index';
import upsellManagement from '../components/report/upsell-management/index.vue';
import recruitStudentsWorkbenches from '../components/recruit-students-workbenches/index.vue';// 招生工作台
import interpretationOfSalesTrackingData from '../components/report/track-data/index.vue'; // 追销数据解读
import recruitStudentsManagement from '../components/recruit-students-management/index.vue';// 招生管理
import applyRecord from '../components/report/upsell-management/list-components/apply-record.vue';
import waitDistributionClue from '../components/report/upsell-management/list-components/wait-distribution-clue.vue';
import clueRecycleBin from '../components/report/upsell-management/list-components/clue-recycle-bin.vue';
import followingClue from '../components/report/upsell-management/list-components/following-clue.vue';
import tryCourse from '../components/report/upsell-management/list-components/try-course.vue';
import unfollowClue from '../components/report/upsell-management/list-components/unfollow-clue.vue';
import campusPrepareLessonsExamine from '../components/head-quarters/campus-prepare-lessons-examine/index.vue'; // 校区备课检查
import mendainSuperviseSchoolStatistics from '../components/head-quarters-branch-md/mendain-supervise-school-statistics/index.vue';// 门店督学统计
import schoolEduReport from '../components/head-quarters/headquarters-management/school-edu-report/index.vue'; // 校区督学统计
import superintendentSet from '../components/head-quarters/headquarters-management/superintendent-set/index.vue'; // 督学设置
import miniProgramTemplateVersionList from '../components/mini-program-publish/template-version-list/index.vue'; // 小程序模板版本列表
import FlowSetMealTemplateVersionList from '../components/head-quarters-flow/index.vue'; // 流量套餐管理
import worksUploadManagement from '../components/works-upload-management/index.vue'; // 学生作品管理 - 普通
import coursePackageSort from '../components/course-package-sort/index.vue'; // 课包排序
import hqworksUploadManagement from '../components/works-upload-management/hq-works-upload-management'; // 学生作品管理 - 总部
import miniProgramPublish from '../components/mini-program-publish/index';// 小程序现行发布
import recommendManage from '../components/head-quarters/recommend-manage/index';// 总部推荐管理
import attractInvestmentManage from '../components/head-quarters/attract-investment-manage/index';// 招商管理
import investorRecycleBin from '../components/head-quarters/attract-investment-manage/table-list/investor-recycle-bin/index';// 投资人回收站
import merchantsCounselorWorkbench from '../components/head-quarters/headquarters-management/merchants-counselor-workbench/index.vue'; // 招商顾问工作台
import cloudClassAttestation from '../components/head-quarters/cloud-class-attestation/index.vue'; // 云课认证
import cloudClassDetails from '../components/head-quarters/cloud-class-attestation/cloud-class-details/index.vue'; // 云课认证
import cloudClassAuthenticationDetails from '../components/head-quarters/cloud-class-attestation/cloud-class-authentication-details.vue'; // 云课认证
import coursewareTvTeaching from '../components/courseware-tv-teaching/index.vue';
import prestrainPage from '../components/courseware-tv-teaching/prestrain-page.vue'; // 预加载
import coursewareWorkBench from '../components/courseware-work-bench/index.vue';
import coursewareBagAutoreleasePool from '../components/head-quarters/courseware-bag-autorelease-pool/index.vue';// 课件释放池
import coursewareAuthorizationManagement from '../components/head-quarters/courseware-authorization-management/index.vue';// 课件授权管理
import campusCoursewareManagement from '../components/head-quarters/campus-courseware-management/index.vue';// 校区课件管理
import courseCooperationManagement from '../components/head-quarters/courseCooperationManagement/index.vue';// 课包释放池
// import courseAttendClassList from '../components/head-quarters/common-modules/courseware-authorization-details/attend-class-record/attend-class-list.vue';
import coursewareClassList from '../components/head-quarters/common-modules/MonthCourseware-Number/Courseware-class-list/Courseware-class-list.vue';
import pushMessageReport from '../components/report/push-message-report/index'; // 课单查询统计
import teachingMode from '../components/teaching-mode/index.vue';
import teachingModeView from '../components/teaching-mode-view/index.vue';
import teachingModeCoursewareDetail from '../components/teaching-mode/child-courseware-detail/index.vue';
import newTeachingMode from '../components/new-teaching-mode/index.vue';
import teachingModeCousewarePackgeDetail from '../components/teaching-mode/components/couseware-packge-detail.vue';
import operationLogReport from '../components/report/operation-log-report/index';
import campusPositioningReport from '../components/head-quarters/campus-positioning-report/index'; // 总部 校区变更记录
import tuitionTransferReport from '../components/report/tuition-report/tuition-transfer-report/index';
import tuitionBalanceReport from '../components/report/tuition-report/tuition-balance-report/index';
import tuitionIncomeReport from '../components/report/tuition-report/tuition-income-report/index';
import schedulePotentialReport from '../components/report/schedule-potential-report/index'; // 排课创收潜力挖掘
import repairCourseManagement from '../components/report/repair-course-management/index';// 补课管理
import activityReport from '../components/activity-report'; // 新活动管理
import customerChannelManagement from '../components/report/customer-channel-management/index'; // 异业合作一码通
import readShareReport from '../components/report/read-share-report/index'; // 课效督学统计
import couponEnrollment from '../components/activity-report/coupon-enrollment'; // 优惠券招生
import diversionPackageEnrollment from '../components/activity-report/diversion-package-enrollment'; // 引流套餐招生
import employeeDistributionEnrollment from '../components/activity-report/employee-distribution-enrollment'; // 员工分销招生
import groupRecruitment from '../components/activity-report/group-recruitment'; // 拼团招生
import ladderGroupEnrollment from '../components/activity-report/ladder-group-enrollment'; // 阶梯团招生
import parentsDistributionEnrollment from '../components/activity-report/parents-distribution-enrollment'; // 家长分销招生
import studentManagementEngineer from '../components/report/student-management-engineer/index';
import studentManagementEngineerWorkbench from '../components/report/student-management-engineer-workbench/index';

Vue.use(Router);
const originalPush = Router.prototype.push;
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject); };
  return originalPush.call(this, location).catch(err => err);
};
let router = new Router({
  routes: [
    {
      path: '/homePage',
      name: 'homePage',
      component: homePage,
      meta: {
        title: '首页'
      }
    },
    // {
    //   path: '/indexView',
    //   name: 'indexView',
    //   component: indexView,
    //   meta: {
    //     title: '工作台'
    //   }
    // },
    {
      path: '/indexView',
      name: 'indexView',
      component: newIndexView,
      meta: {
        title: '教务工作台',
        ModulePowerKey: 51
      }
    },
    {
      path: '/login',
      name: 'login',
      component: login,
      meta: {
        title: '登录页面'
      }
    },
    {
      path: '/presidentKanban',
      name: 'presidentKanban',
      component: presidentKanban,
      meta: {
        title: '校长看板',
        ModulePowerKey: 63
      }
    },
    {
      path: '/CourseTable',
      name: 'CourseTable',
      component: CourseTable,
      meta: {
        title: '课程表',
        ModulePowerKey: 146
      }
    },
    {
      path: '/appointmentCourse',
      name: 'appointmentCourse',
      component: appointmentCourse,
      meta: {
        title: '约课表',
        ModulePowerKey: 149
      }
    },
    {
      path: '/ClassGrade',
      name: 'ClassGrade',
      component: ClassGrade,
      meta: {
        title: '班级教学分析',
        ModulePowerKey: 67
      }
    },
    {
      path: '/applyGrade',
      name: 'applyGrade',
      component: applyGrade,
      meta: {
        title: '报名业绩分析',
        ModulePowerKey: 65
      }
    },
    // {
    //   path: '/attentionGrade',
    //   name: 'attentionGrade',
    //   component: attentionGrade,
    //   meta: {
    //     title: '学生考勤分析',
    //     ModulePowerKey: 70
    //   }
    // },
    // {
    //   path: '/studentGrade',
    //   name: 'studentGrade',
    //   component: studentGrade,
    //   meta: {
    //     title: '学生增减分析',
    //
    //
    //     ModulePowerKey: 69
    //   }
    // },
    {
      path: '/classCountAmount',
      name: 'classCountAmount',
      component: classCountAmount,
      meta: {
        title: '课消金额分析'
      }
    },
    {
      path: '/classSpendGrade',
      name: 'classSpendGrade',
      component: classSpendGrade,
      meta: {
        title: '课时消耗分析',
        ModulePowerKey: 72
      }
    },
    {
      path: '/exceptionGrade',
      name: 'exceptionGrade',
      component: exceptionGrade,
      meta: {
        title: '异常操作分析',
        ModulePowerKey: 62
      }
    },
    {
      path: '/teacherGrade',
      name: 'teacherGrade',
      component: teacherGrade,
      meta: {
        title: '老师统计'
      }
    },
    {
      path: '/customerClew',
      name: 'customerClew',
      component: customerClew,
      meta: {
        title: '客户线索'
      }
    },
    {
      path: '/applyCourseReport',
      name: 'applyCourseReport',
      component: applyCourseReport,
      meta: {
        title: '排课汇总'
      }
    },
    //  基础设置模块
    {
      path: '/mendiansettingform',
      name: 'mendiansettingform',
      meta: {
        title: '校区设置',
        ModulePowerKey: 38
      },
      component: mendiansettingform
    },
    {
      path: '/studentManagementReport',
      name: 'studentManagementReport',
      meta: {
        title: '学生管理',
        ModulePowerKey: 59
      },
      component: studentManagementReport
    },
    {
      path: '/allStudent',
      name: 'allStudent',
      meta: {
        title: '全部学生',
        ModulePowerKey: 59
      },
      component: allStudent
    },
    {
      path: '/recycledStudent',
      name: 'recycledStudent',
      meta: {
        title: '学生回收站',
        ModulePowerKey: 59
      },
      component: recycledStudent
    },
    {
      path: '/tryArrangeCourse',
      name: 'tryArrangeCourse',
      meta: {
        title: '试课管理',
        ModulePowerKey: 64
      },
      component: tryArrangeCourse
    },
    {
      path: '/classGradeApplycourse',
      name: 'classGradeApplycourse',
      meta: {
        title: '班级排课',
        ModulePowerKey: 53
      },
      component: classGradeApplycourse
    },
    {
      path: '/packageApplyCourseReport',
      name: 'packageApplyCourseReport',
      meta: {
        title: '套餐排课',
        ModulePowerKey: 53
      },
      component: packageApplyCourseReport
    },
    {
      path: '/courseScheduleManagement',
      name: 'courseScheduleManagement',
      meta: {
        title: '课程管理',
        ModulePowerKey: 53
      },
      component: courseScheduleManagement
    },
    {
      path: '/courseNameApplyCourseReport',
      name: 'courseNameApplyCourseReport',
      meta: {
        title: '课程排课',
        ModulePowerKey: 53
      },
      component: courseNameApplyCourseReport
    },
    {
      path: '/courseArrangement',
      name: 'courseArrangement',
      meta: {
        title: '排课管理',
        ModulePowerKey: 53
      },
      component: courseArrangement
    },
    {
      path: '/newCourseArrangement',
      name: 'newCourseArrangement',
      meta: {
        title: '排课管理',
        ModulePowerKey: 53
      },
      component: newCourseArrangement
    },
    {
      path: '/mendianOperationManagement',
      name: 'mendianOperationManagement',
      meta: {
        title: '客户跟单管理',
        ModulePowerKey: 88
      },
      component: mendianOperationManagement
    },
    {
      path: '/superManagement',
      name: 'superManagement',
      meta: {
        title: '超级管理',
        ModulePowerKey: 97
      },
      component: superManagement
    },
    {
      path: '/classSpendStatisticsAnalyze',
      name: 'classSpendStatisticsAnalyze',
      meta: {
        title: '学生考勤综合',
        ModulePowerKey: 86
      },
      component: classSpendStatisticsAnalyze
    },
    {
      path: '/receiptAndPaymentStatistics',
      name: 'receiptAndPaymentStatistics',
      meta: {
        title: '收付款统计',
        ModulePowerKey: 90
      },
      component: receiptAndPaymentStatistics
    },
    {
      path: '/operatingRevenueStatistics',
      name: 'operatingRevenueStatistics',
      meta: {
        title: '营业收入统计',
        ModulePowerKey: 89
      },
      component: operatingRevenueStatistics
    },
    {
      path: '/teacherPerformance',
      name: 'teacherPerformance',
      meta: {
        title: '老师业绩统计',
        ModulePowerKey: 71
      },
      component: teacherPerformance
    },
    {
      path: '/studentManagementEngineer',
      name: 'studentManagementEngineer',
      meta: {
        title: '学管师管理',
        ModulePowerKey: 167
      },
      component: studentManagementEngineer
    },
    {
      path: '/studentManagementEngineerWorkbench',
      name: 'studentManagementEngineerWorkbench',
      meta: {
        title: '学管师工作台',
        ModulePowerKey: 0
      },
      component: studentManagementEngineerWorkbench
    },
    {
      path: '/registrationSupplementaryCorrection',
      name: 'registrationSupplementaryCorrection',
      meta: {
        title: '报名补充校正',
        ModulePowerKey: 50
      },
      component: registrationSupplementaryCorrection
    },
    {
      path: '/moreMendianReport',
      name: 'moreMendianReport',
      meta: {
        title: '连锁门店统计',
        ModulePowerKey: 87
      },
      component: moreMendianReport
    },
    {
      path: '/wktBack',
      name: 'wktBack',
      meta: {
        title: '微网校',
        ModulePowerKey: 96
      },
      component: wktBack
    },
    {
      path: '/inventoryManagementFile',
      name: 'inventoryManagementFile',
      meta: {
        title: '库存管理',
        ModulePowerKey: 95
      },
      component: inventoryManagementFile
    },
    {
      path: '/activityMonitoringPlatform',
      name: 'activityMonitoringPlatform',
      meta: {
        title: '招生战况直播',
        ModulePowerKey: 55
      },
      component: activityMonitoringPlatform
    },
    {
      path: '/recruitStudentShow',
      name: 'recruitStudentShow',
      meta: {
        title: 'O2O分销招生',
        ModulePowerKey: 55
      },
      component: recruitStudentShow
    },
    {
      path: '/customerManagement',
      name: 'customerManagement',
      meta: {
        title: '客户管理',
        ModulePowerKey: 99
      },
      component: customerManagement
    },
    {
      path: '/creditManagement',
      name: 'creditManagement',
      meta: {
        title: '学分管理',
        ModulePowerKey: 98
      },
      component: creditManagement
    },
    {
      path: '/managementAndStatistics',
      name: 'managementAndStatistics',
      meta: {
        title: '管理与统计',
        ModulePowerKey: 100
      },
      component: managementAndStatistics
    },
    {
      path: '/growthTime',
      name: 'growthTime',
      meta: {
        title: '成长时光',
        ModulePowerKey: 101
      },
      component: growthTime
    },
    {
      path: '/campusManagement',
      name: 'campusManagement',
      meta: {
        title: '系统端口管理',
        ModulePowerKey: 109
      },
      component: campusManagement
    },
    {
      path: '/retailManagement',
      name: 'retailManagement',
      meta: {
        title: '直营店管理',
        ModulePowerKey: 105
      },
      component: retailManagement
    },
    {
      path: '/franchiseeManagement',
      name: 'franchiseeManagement',
      meta: {
        title: '加盟店管理',
        ModulePowerKey: 106
      },
      component: franchiseeManagement
    },
    {
      path: '/authorizationCourseware',
      name: 'authorizationCourseware',
      meta: {
        title: '授权课件',
        ModulePowerKey: 108
      },
      component: authorizationCourseware
    },
    {
      path: '/distributeCoursewareTable',
      name: 'distributeCoursewareTable',
      meta: {
        title: '课件分发',
        ModulePowerKey: 119
      },
      component: distributeCoursewareTable
    },
    // {
    //   path: '/teacherAttenClass',
    //   name: 'teacherAttenClass',
    //   meta: {
    //     title: '老师上课'
    //   },
    //   component: teacherAttenClass
    // },
    {
      path: '/teacherAttenClass',
      name: 'teacherAttenClass',
      meta: {
        title: '老师工作台'
      },
      component: newTeacherAttenClass
    },
    {
      path: '/chainWorkBench',
      name: 'chainWorkBench',
      meta: {
        title: '工作台'
      },
      component: chainWorkBench
    },
    {
      path: '/newChainWorkBench',
      name: 'newChainWorkBench',
      meta: {
        title: '工作台'
      },
      component: newChainWorkBench
    },
    {
      path: '/generalCoursewareManagement',
      name: 'generalCoursewareManagement',
      meta: {
        title: '自研课件管理',
        ModulePowerKey: 118
      },
      component: generalCoursewareManagement
    },
    {
      path: '/hqCoursewareManagement',
      name: 'hqCoursewareManagement',
      meta: {
        title: '课件管理',
        ModulePowerKey: 107
      },
      component: hqCoursewareManagement
    },
    {
      path: '/coursewareTeaching',
      name: 'coursewareTeaching',
      meta: {
        title: '课件教学'
      },
      component: coursewareTeaching
    },
    {
      path: '/coursewareTeachingView',
      name: 'coursewareTeachingView',
      meta: {
        title: '课件教学_'
      },
      component: coursewareTeachingView
    },
    {
      path: '/coursewareTvTeaching',
      name: 'coursewareTvTeaching',
      meta: {
        title: '课件TV教学'
      },
      component: coursewareTvTeaching
    },
    {
      path: '/prestrainPage',
      name: 'prestrainPage',
      meta: {
        title: '预加载界面'
      },
      component: prestrainPage
    },
    {
      path: '/upsellManagement',
      name: 'upsellManagement',
      meta: {
        title: '追销管理',
        ModulePowerKey: 125
      },
      component: upsellManagement
    },
    {
      path: '/recruitStudentsWorkbenches',
      name: 'recruitStudentsWorkbenches',
      meta: {
        title: '招生工作台',
        ModulePowerKey: ''
      },
      component: recruitStudentsWorkbenches
    },
    {
      path: '/interpretationOfSalesTrackingData',
      name: 'interpretationOfSalesTrackingData',
      meta: {
        title: '追销数据解读',
        ModulePowerKey: '168'
      },
      component: interpretationOfSalesTrackingData
    },
    {
      path: '/recruitStudentsManagement',
      name: 'recruitStudentsManagement',
      meta: {
        title: '招生管理',
        ModulePowerKey: 125
      },
      component: recruitStudentsManagement
    },
    {
      path: '/applyRecord',
      name: 'applyRecord',
      meta: {
        title: '报名记录',
        ModulePowerKey: 125
      },
      component: applyRecord
    },
    {
      path: '/waitDistributionClue',
      name: 'waitDistributionClue',
      meta: {
        title: '待分配线索',
        ModulePowerKey: 125
      },
      component: waitDistributionClue
    },
    {
      path: '/clueRecycleBin',
      name: 'clueRecycleBin',
      meta: {
        title: '线索回收站',
        ModulePowerKey: 125
      },
      component: clueRecycleBin
    },
    {
      path: '/followingClue',
      name: 'followingClue',
      meta: {
        title: '跟进中线索',
        ModulePowerKey: 125
      },
      component: followingClue
    },
    {
      path: '/unfollowClue',
      name: 'unfollowClue',
      meta: {
        title: '未及时跟进',
        ModulePowerKey: 125
      },
      component: unfollowClue
    },
    {
      path: '/tryCourse',
      name: 'tryCourse',
      meta: {
        title: '到店试课',
        ModulePowerKey: 125
      },
      component: tryCourse
    },
    {
      path: '/classListQueryStatistics',
      name: 'classListQueryStatistics',
      meta: {
        title: '课单查询统计',
        ModulePowerKey: 127
      },
      component: classListQueryStatistics
    },
    {
      path: '/campusPrepareLessonsExamine',
      name: 'campusPrepareLessonsExamine',
      meta: {
        title: '备课检查',
        ModulePowerKey: ''
      },
      component: campusPrepareLessonsExamine
    },
    {
      path: '/mendainSuperviseSchoolStatistics',
      name: 'mendainSuperviseSchoolStatistics',
      meta: {
        title: '校区督学综合',
        ModulePowerKey: 128
      },
      component: mendainSuperviseSchoolStatistics
    },
    {
      path: '/schoolEduReport',
      name: 'schoolEduReport',
      meta: {
        title: '校区督学统计',
        ModulePowerKey: ''
      },
      component: schoolEduReport
    },
    {
      path: '/superintendentSet',
      name: 'superintendentSet',
      meta: {
        title: '督学设置',
        ModulePowerKey: '129'
      },
      component: superintendentSet
    },
    {
      path: '/miniProgramTemplateVersionList',
      name: 'miniProgramTemplateVersionList',
      meta: {
        title: '代码模版列表'
      },
      component: miniProgramTemplateVersionList
    },
    {
      path: '/FlowSetMealTemplateVersionList',
      name: 'FlowSetMealTemplateVersionList',
      meta: {
        title: '流量套餐管理',
        ModulePowerKey: '145'
      },
      component: FlowSetMealTemplateVersionList
    },
    {
      path: '/worksUploadManagement',
      name: 'worksUploadManagement',
      meta: {
        title: '学生作品管理',
        ModulePowerKey: ''
      },
      component: worksUploadManagement
    },
    {
      path: '/coursePackageSort',
      name: 'coursePackageSort',
      meta: {
        title: '课包排序',
        ModulePowerKey: [118, 123]
      },
      component: coursePackageSort
    },
    {
      path: '/hqworksUploadManagement',
      name: 'hqworksUploadManagement',
      meta: {
        title: '推荐作品',
        ModulePowerKey: ''
      },
      component: hqworksUploadManagement
    },
    {
      path: '/miniProgramPublish',
      name: 'miniProgramPublish',
      component: miniProgramPublish,
      meta: {
        title: '小程序现行发布'
      }
    },
    {
      path: '/recommendManage',
      name: 'recommendManage',
      component: recommendManage,
      meta: {
        title: '云课推荐管理',
        ModulePowerKey: 137
      }
    },
    {
      path: '/crmJoinSignManage',
      name: 'crmJoinSignManage',
      meta: {
        title: '加盟签单管理',
        ModulePowerKey: 132
      },
      component: crmJoinSignManage
    },
    {
      path: '/attractInvestmentManage',
      name: 'attractInvestmentManage',
      component: attractInvestmentManage,
      meta: {
        title: '招商管理',
        ModulePowerKey: 135
      }
    },
    {
      path: '/investorRecycleBin',
      name: 'investorRecycleBin',
      component: investorRecycleBin,
      meta: {
        title: '投资人回收站',
        ModulePowerKey: 135
      }
    },
    {
      path: '/crmContactRecycleBin',
      name: 'crmContactRecycleBin',
      meta: {
        title: '加盟签单回收站',
        ModulePowerKey: 132
      },
      component: crmContactRecycleBin
    },
    {
      path: '/merchantsCounselorWorkbench',
      name: 'merchantsCounselorWorkbench',
      component: merchantsCounselorWorkbench,
      meta: {
        title: '招商顾问工作台',
        ModulePowerKey: 136
      }
    },
    {
      path: '/cloudClassAttestation',
      name: 'cloudClassAttestation',
      component: cloudClassAttestation,
      meta: {
        title: '云课认证',
        ModulePowerKey: 138
      }
    },
    {
      path: '/cloudClassDetails',
      name: 'cloudClassDetails',
      component: cloudClassDetails,
      meta: {
        title: '云课认证',
        ModulePowerKey: 0
      }
    },
    {
      path: '/cloudClassAuthenticationDetails',
      name: 'cloudClassAuthenticationDetails',
      component: cloudClassAuthenticationDetails,
      meta: {
        title: '云课认证详情（无需登录）',
        ModulePowerKey: 0
      }
    },
    {
      path: '/coursewareWorkBench',
      name: 'coursewareWorkBench',
      component: coursewareWorkBench,
      meta: {
        title: '校长工作台',
        ModulePowerKey: 0
      }
    },
    {
      path: '/coursewareBagAutoreleasePool',
      name: 'coursewareBagAutoreleasePool',
      component: coursewareBagAutoreleasePool,
      meta: {
        title: '课件释放池',
        ModulePowerKey: 0
      }
    },
    {
      path: '/coursewareAuthorizationManagement',
      name: 'coursewareAuthorizationManagement',
      component: coursewareAuthorizationManagement,
      meta: {
        title: '课包授权管理',
        ModulePowerKey: 108
      }
    },
    {
      path: '/campusCoursewareManagement',
      name: 'campusCoursewareManagement',
      component: campusCoursewareManagement,
      meta: {
        title: '校区课件管理',
        ModulePowerKey: 108
      }
    },

    {
      path: '/courseCooperationManagement',
      name: 'courseCooperationManagement',
      component: courseCooperationManagement,
      meta: {
        title: '课程合作管理',
        ModulePowerKey: 0
      }
    },
    // {
    //   path: '/courseAttendClassList',
    //   name: 'courseAttendClassList',
    //   component: courseAttendClassList,
    //   meta: {
    //     title: '课件使用查询',
    //     ModulePowerKey: 0
    //   }
    // },
    {
      path: '/coursewareClassList',
      name: 'coursewareClassList',
      component: coursewareClassList,
      meta: {
        title: '课件使用账单',
        ModulePowerKey: 0
      }
    },
    {
      path: '/pushMessageReport',
      name: 'pushMessageReport',
      component: pushMessageReport,
      meta: {
        title: '消息推送查询',
        ModulePowerKey: 152
      }
    },
    {
      path: '/teachingMode',
      name: 'teachingMode',
      component: teachingMode,
      meta: {
        title: '教学模式',
        ModulePowerKey: 0,
        keepAlive: true
      }
    },
    {
      path: '/teachingModeView',
      name: 'teachingModeView',
      component: teachingModeView,
      meta: {
        title: '教学模式',
        ModulePowerKey: 0,
        keepAlive: true
      }
    },
    {
      path: '/teachingModeCoursewareDetail',
      name: 'teachingModeCoursewareDetail',
      component: teachingModeCoursewareDetail,
      meta: {
        title: '课件详情',
        ModulePowerKey: 0,
        keepAlive: true
      }
    },
    {
      path: '/newTeachingMode',
      name: 'newTeachingMode',
      component: newTeachingMode,
      meta: {
        title: '教学模式',
        ModulePowerKey: 0,
        keepAlive: true
      }
    },
    {
      path: '/teachingModeCousewarePackgeDetail',
      name: 'teachingModeCousewarePackgeDetail',
      component: teachingModeCousewarePackgeDetail,
      meta: {
        title: '课包详情',
        ModulePowerKey: 0,
        keepAlive: true
      }
    },
    {
      path: '/operationLogReport',
      name: 'operationLogReport',
      component: operationLogReport,
      meta: {
        title: '操作日志',
        ModulePowerKey: 0 // store.getters.SaaSClientInfo&&store.getters.SaaSClientInfo.TypeKey == 6?154:153
      }
    },
    {
      path: '/campusPositioningReport',
      name: 'campusPositioningReport',
      component: campusPositioningReport,
      meta: {
        title: '校区定位记录',
        ModulePowerKey: 155 // store.getters.SaaSClientInfo&&store.getters.SaaSClientInfo.TypeKey == 6?154:153
      }
    },
    {
      path: '/tuitionTransferReport',
      name: 'tuitionTransferReport',
      component: tuitionTransferReport,
      meta: {
        title: '学费结转汇总',
        ModulePowerKey: 0 // store.getters.SaaSClientInfo&&store.getters.SaaSClientInfo.TypeKey == 6?122:157
      }
    },
    {
      path: '/tuitionBalanceReport',
      name: 'tuitionBalanceReport',
      component: tuitionBalanceReport,
      meta: {
        title: '学费结余汇总',
        ModulePowerKey: 0 // store.getters.SaaSClientInfo&&store.getters.SaaSClientInfo.TypeKey == 6?121:156
      }
    },
    {
      path: '/tuitionIncomeReport',
      name: 'tuitionIncomeReport',
      component: tuitionIncomeReport,
      meta: {
        title: '学费收入汇总',
        ModulePowerKey: 0  // store.getters.SaaSClientInfo&&store.getters.SaaSClientInfo.TypeKey == 6?123:158
      }
    },
    {
      path: '/schedulePotentialReport',
      name: 'schedulePotentialReport',
      component: schedulePotentialReport,
      meta: {
        title: '排课创收解读',
        ModulePowerKey: 159
      }
    },
    {
      path: '/repairCourseManagement',
      name: 'repairCourseManagement',
      component: repairCourseManagement,
      meta: {
        title: '补课管理',
        ModulePowerKey: 51
      }
    },
    {
      path: '/activityReport',
      name: 'activityReport',
      component: activityReport,
      meta: {
        title: '活动',
        ModulePowerKey: 55
      }
    },
    {
      path: '/couponEnrollment',
      name: 'couponEnrollment',
      component: couponEnrollment,
      meta: {
        title: '优惠券招生',
        ModulePowerKey: 55
      }
    },
    {
      path: '/diversionPackageEnrollment',
      name: 'diversionPackageEnrollment',
      component: diversionPackageEnrollment,
      meta: {
        title: '引流套餐招生',
        ModulePowerKey: 55
      }
    },
    {
      path: '/employeeDistributionEnrollment',
      name: 'employeeDistributionEnrollment',
      component: employeeDistributionEnrollment,
      meta: {
        title: '员工分销招生',
        ModulePowerKey: 55
      }
    },
    {
      path: '/groupRecruitment',
      name: 'groupRecruitment',
      component: groupRecruitment,
      meta: {
        title: '拼团招生',
        ModulePowerKey: 55
      }
    },
    {
      path: '/ladderGroupEnrollment',
      name: 'ladderGroupEnrollment',
      component: ladderGroupEnrollment,
      meta: {
        title: '阶梯团招生',
        ModulePowerKey: 55
      }
    },
    {
      path: '/parentsDistributionEnrollment',
      name: 'parentsDistributionEnrollment',
      component: parentsDistributionEnrollment,
      meta: {
        title: '家长分销招生',
        ModulePowerKey: 55
      }
    },
    {
      path: '/customerChannelManagement',
      name: 'customerChannelManagement',
      component: customerChannelManagement,
      meta: {
        title: '异业合作一码通',
        ModulePowerKey: 163
      }
    },
    {
      path: '/readShareReport',
      name: 'readShareReport',
      component: readShareReport,
      meta: {
        title: '课效数据解读',
        ModulePowerKey: 164
      }
    }
  ]
});
// 按系统返回键 已关掉的路由重新打开
router.beforeEach((to, from, next) => {
  console.log('beforeEach', to, from, next);
  var tokenstr = fun.loginInfo.getCookie('token');
  if ((!tokenstr || tokenstr == 'null') &&
    to.name != 'login' &&
    to.name != 'cloudClassAuthenticationDetails' &&
    to.name != 'coursewareTvTeaching' &&
    to.name != 'prestrainPage') {
    localStorage.removeItem('apiList');
    localStorage.removeItem('newAPIURL');
    store.commit('selectNewAPIURL');
    next({
      path: '/login'
    });
    return;
  } else {
    if (!store.getters.token) {
      store.commit('SetToken', JSON.parse(tokenstr));
    }
  }
  // 防止不同版本的门店端首页路由能相互切换
  if (tokenstr) {
    var TypeKey = JSON.parse(tokenstr).SaaSClientEditionKey;
    if (
      (TypeKey == 5 && (to.name == 'homePage' || to.name == 'coursewareWorkBench')) ||
      (TypeKey == 6 && (to.name == 'homePage' || to.name == 'chainWorkBench')) ||
      ((TypeKey != 5 && TypeKey != 6) && (to.name == 'chainWorkBench' || to.name == 'coursewareWorkBench'))) {
      outLogin();
    }
  }
  // 判断是否有权限
  if (store.getters.token) {
    if (to.path == '/') {
      next({
        path: utils.getIndexPath()
      });
      return;
    } else {
      let flag = utils.CheckModulePower(
        store.getters.RolePowerList,
        to.meta.ModulePowerKey
      );

      if (to.meta.ModulePowerKey == 146 || to.meta.ModulePowerKey == 47) {
        flag = true;
      }
      console.log(flag, 'flag', to);
      if (!flag) {
        utils.CheckModulePowerToTips(to.meta.ModulePowerKey || 0);
        let canback =
          store.getters.headerBarList.findIndex(obj => {
            return obj.routerName == from.routerName;
          }) > -1;
        if (canback) {
          next({
            path: from.path
          });
        } else {
          next({
            path: utils.getIndexPath()
          });
        }
        return;
      }
    }
  }
  // console.log('to.meta', to.meta);
  // console.log('to.meta.keepAlive', to.meta.keepAlive);
  // console.log(' from.meta', from.meta);
  // console.log(' from.meta.keepAlive', from.meta.keepAlive);
  // to.meta.keepAlive = true;
  // from.meta.keepAlive = true;
  let headerBarList = store.getters.headerBarList;
  let findIndex = headerBarList.findIndex(obj => {
    return obj.routerName == to.name;
  });
  if (findIndex == -1) {
    // 未打开过
    // 判断是否还可继续打开（窗口大小问题 不可换行）
    if (
      document.getElementsByClassName('header_fixed_ul')[0] &&
      document.getElementsByClassName('header_fixed_ul')[0].children[0]
    ) {
      let headerWidth = document.getElementsByClassName('head_bar')[0]
        .clientWidth;
      let oneHeaderModuleBoxtwidth = document.getElementsByClassName(
        'header_fixed_ul'
      )[0].children[0].clientWidth;
      let headerModuleBoxtNum = document.getElementsByClassName(
        'header_fixed_ul'
      )[0].children.length;
      console.log("oneHeaderModuleBoxtwidth",oneHeaderModuleBoxtwidth)
      console.log("headerWidth",headerWidth)
      console.log("headerModuleBoxtNum",headerModuleBoxtNum)
      let headerModuleBoxtWidth =
        oneHeaderModuleBoxtwidth * headerModuleBoxtNum;
      let oneHeaderModuleBoxtRatio = oneHeaderModuleBoxtwidth / headerWidth;
      let canOpenBoxratio = headerModuleBoxtWidth / headerWidth;
      console.log('headerWidth', headerWidth);
      if (headerBarList && headerBarList.length<=100) {
        // headerWidth=headerWidth>=1920?1920:headerWidth
        // headerWidth-(90+406)/headerModuleBoxtWidth
        console.log("headerWidth-(90+406)",headerWidth-(90+406))
        console.log("(oneHeaderModuleBoxtwidth*headerModuleBoxtWidth-100)",(oneHeaderModuleBoxtwidth*headerModuleBoxtNum-100))
        if(headerWidth-(90+406)-(oneHeaderModuleBoxtwidth*headerModuleBoxtNum-100)<=100){
            layer.alert(
            '当前打开窗口太多' +
            '最多只能同时打开' +
            headerBarList.length +
            '个窗口。请先关闭一些窗口。'
          );
          return false;
        }


        // if (
        //   (canOpenBoxratio + oneHeaderModuleBoxtRatio >= 0.63 &&
        //     headerWidth >= 1900) ||
        //   (canOpenBoxratio + oneHeaderModuleBoxtRatio >= 0.57 &&
        //     headerWidth >= 1600 &&
        //     headerWidth < 1900) ||
        //   (canOpenBoxratio + oneHeaderModuleBoxtRatio >= 0.51 &&
        //     headerWidth >= 1200 &&
        //     headerWidth < 1600)
        // ) {
        //   layer.alert(
        //     '当前打开窗口太多' +
        //     '最多只能同时打开' +
        //     headerBarList.length +
        //     '个窗口。请先关闭一些窗口。'
        //   );
        //   return false;
        // }
      }
    }
  }
  store.commit('setCurRoute', to);
  console.log('totototo', to);
  if (to.matched.length === 0) {
    // 如果未匹配到路由
    from.name ? next({ name: from.name }) : next('/'); // 如果上级也未匹配到路由则跳转登录页面，如果上级能匹配到则转上级路由
  } else {
    utils.refreshHeaderBarList(to);
    next(); // 如果匹配到正确跳转
  }
});

export default router;
