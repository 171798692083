<template>
  <div class="head_bar">
    <div class="header_fixed">
      <!-- <div class="header_fixed_top"
           :class="this.$store.getters.SaaSClientEditionKey == 6?'courseware_module_top':''"> -->

      <div class="header_fixed_top"
           :style="{ width: screenWidth + 'px' }">
        <div class="header_fixed_logo fl">
          <img :src="vBrandInfo.LogoUrl"
               alt />
        </div>
        <div class="header_fixed_ul fl">
          <header-bar-item v-for="(item, index) in vHeadBarList"
                           :key="index"
                           :item="item"></header-bar-item>
        </div>
        <div class="fr">
          <div class="header_fixed_btn all_menu header_fixed_menu fl"
               :class="isShowAllFunction ? 'opt' : ''"
               @mouseenter.stop="showAllFunction"
               @mouseleave.stop="hideAllFunction"
               v-if="vSaaSClientEditionKey">
            全部功能
          </div>
        </div>
        <div class="header_fixed_mendian fr button"
             @mouseenter="showAllMendian"
             @mouseleave="hideAllMendian">
          <div class="fr">
            <div>
              <div class="mendian_btn fr"
                   v-if="allSaasClientList.length > 0"></div>
              <div v-else
                   class="fr"
                   style="width: 20px; height: 68px"></div>
              <div class="mendian_text fr">{{ vSaaSClientKeyValue }}</div>
            </div>
            <!-- 门店弹出层 -->
            <mendian-info v-if="isShowAllMendian"
                          @switchSaasClient="switchSaasClient"></mendian-info>
            <!-- <div class="header_fixed_mendian_ul"
                 v-if="isShowAllMendian && allSaasClientList.length > 0">
              <div>
                <mendianList v-for="(item, index) in allSaasClientList"
                             :key="index"
                             :item="item"
                             @switchSaasClient="switchSaasClient">
                </mendianList>
              </div>
            </div> -->
          </div>
          <div class="mendian_logo fr">
            <img :src="vMendianLogo"
                 alt />
          </div>
        </div>
        <div class="header_fixed_btn homepage_remine fr"
             v-if="isShowExportDataBtn"
             @click="showExportDataList">
          <span>提醒</span>
        </div>
      </div>
    </div>
    <!-- 全部功能弹出层  v-show="isShowAllFunction" -->
    <div class="header_fixed_menu_box"
        v-show="isShowAllFunction"
         @mouseenter.stop="showAllFunction"
         @mouseleave.stop="hideAllFunction">
      <div class="shade"
           @mouseenter.stop="hideAllFunction"
          ></div>
      <div class="header_fixed_menu_ul" style="z-index:1000;background:white"
           @scroll="scrollEvent">
        <div class="pr">
          <div class=""
               v-for="(btnInfo, index) in vallFunctionBtnTypeChange"
               :key="index">
            <div v-show="!btnInfo.hiddenType || isShowAllMenu"
                 class="menu_list">
              <div class="menu_list_title">
                <div class="title_box">
                  <div class="title_name">{{ btnInfo.title }}</div>
                  <!-- <div class="title_en">{{ btnInfo.english }}</div> -->
                </div>
              </div>
              <div class="menu_list_content">
                <!-- 需加宽单个按钮宽度，一行3个的分类，这里增加class：bigbtn -->
                <function-btn v-for="(childItem, index) in btnInfo.childList"
                              :key="index"
                              :item="childItem"
                              class="menu_list_btn"
                              :title="childItem.name"
                              @clickItem="doAfterClickFunctionBtn"
                              @mouseenter.native="
                    mouseOverNewCourseTable(childItem, $event)
                  "
                              @mouseleave.native="mouseOutNewCourseTable(childItem, $event)"
                              :className="childItem.className"></function-btn>
              </div>
            </div>
          </div>
          <div v-if="isShowAllMenu"
               class="no_menuBtn">已显示全部</div>
          <div v-else
               @click="showAllMenu"
               class="header_fixed_menu_extend">
            显示更多
          </div>
        </div>
      </div>
    </div>
    <div class="header_placeholder"></div>
    <pop-up-drop-down-box :style="{ zIndex: 3000 }"
                          :isShowDialog="isShowAlertDialog"
                          :dropDownList="popUpList"
                          :positionLeft="positionLeft"
                          :positionTop="positionTop"
                          @onClick="doAfterSelectedNewCourseTableType"
                          @mouseenter.native="mouseOverNewCourseTable({ isShowDropDownBox: true })"
                          @mouseleave.native="mouseOutNewCourseTable({ isShowDropDownBox: true })"></pop-up-drop-down-box>
    <!-- 异步导出列表 -->

    <custom-dialog title="导出列表"
                   width="900px"
                   class="popup_dialog_withe"
                   :visible.sync="isShowExportDataList"
                   :before-close="closeShowExportDataList">
      <export-data-list></export-data-list>
    </custom-dialog>
    <custom-dialog :title="'切换门店账号'"
                   :visible.sync="isShowSwitchSaasClientDialog"
                   :before-close="closeSwitchSaasClientDialog">
      <mendian-yzm :mendianID="targetSassClientKey"
                   :codeTitle="'切换门店账号'"
                   @closeDialog="closeSwitchSaasClientDialog"></mendian-yzm>
    </custom-dialog>
  </div>
</template>

<script>
import headerBarItem from "./header-bar-item";
import mendianYzm from "../common/mendian-yzm";
import popUpDropDownBox from "../common/pop-up-drop-down-box";
import mendianInfo from "./mendian-info";
import exportDataList from "../report/export-data-list";
import {
  GetSwitchableSaaSClient,
  GetEmployeeInfo,
  SwitchSaaSClient,
  SpecialLongLink,
  GetIndexIsRemindWorkInfo,
} from "../../API/workbench";
import { outLogin, doReLogin } from "../../assets/login";

export default {
  data () {
    return {
      isShowAllFunction: false,
      isShowAllMendian: false,
      isShowAllMenu: false,
      timer: "",
      allSaasClientList: [],
      token: "",
      timeData: "",
      isShowSwitchSaasClientDialog: false,
      targetSassClientKey: 0,
      specialData: {
        notifyType: 3, // 通知类型:1-全系统;2-全门店;3-指定用户;
        eventName: "开启刷脸签到", // 事件名称
        eventContent: "开启刷脸签到", // 事件内容
        targetAgentKey: "", //	目标员工key
      },
      popUpList: [],
      coursePopUpList: [
        {
          id: "3",
          name: "日历建课",
          ModulePowerKey: 47,
          isShow: true,
        },
        {
          id: "2",
          name: "新建循环课",
          ModulePowerKey: 47,
          isShow: true,
        },
      ],
      studentPopUpList: [
        {
          id: "1",
          name: "学生请假",
          moduleName: "studentLeave",
          ModulePowerKey: 51,
          isShow: true,
        },
        {
          id: "1",
          name: "改手机号",
          moduleName: "changephonenum",
          ModulePowerKey: 48,
          isShow: true,
        },
        {
          id: "1",
          name: "修改课单",
          moduleName: "changeCourseBill",
          ModulePowerKey: 52,
          isShow: true,
        },
        // {
        //   id: '1',
        //   name: '修改考勤',
        //   moduleName: 'changeAttendance',
        //   ModulePowerKey: 51,
        //   isShow: true
        // },
        {
          id: "1",
          name: "学生换班",
          moduleName: "studentchangeclass",
          ModulePowerKey: 51,
          isShow: true,
        },
        {
          id: "1",
          name: "转让课单",
          moduleName: "transfercoursebill",
          ModulePowerKey: 52,
          isShow: true,
        },
        {
          id: "1",
          name: "课时退款",
          moduleName: "refundmoneybackcourse",
          ModulePowerKey: 52,
          isShow: true,
        },
        {
          id: "1",
          name: "合并学生",
          moduleName: "mergeStudent",
          ModulePowerKey: 48,
          isShow: true,
        },
      ],
      schedulePopUpList: [
        {
          id: "4",
          name: "课程排课",
          routertitle: "课程排课",
          routerName: "courseNameApplyCourseReport",
          ModulePowerKey: 53,
          isShow: true,
        },
        {
          id: "4",
          name: "班级排课",
          routertitle: "班级排课",
          routerName: "classGradeApplycourse",
          ModulePowerKey: 53,
          isShow: true,
        },
        {
          id: "4",
          name: "套餐排课",
          routertitle: "套餐排课",
          routerName: "packageApplyCourseReport",
          ModulePowerKey: 53,
          isShow: true,
        },
      ],
      isShowAlertDialog: false,
      positionLeft: 0,
      positionTop: 0,
      isShowPeopleFaceDiscern: false, // 面容识别签到
      isShowSavePeopleFaceDiscern: false, // 面容识别管理
      faceStatus: false, // 显示面容识别功能模块.
      isOpenFaceStatus: true, // 是否显示开启面容识别按钮,
      openFace: {
        name: "",
        type: "",
        ModulePowerKey: 81,
      },
      saveFace: {
        name: "",
        type: "",
        ModulePowerKey: 48,
      },
      imgUrl: "",
      isShowExportDataList: false,
      isShowExportDataBtn: false,
    };
  },
  props: {
    screenWidth: Number,
  },
  created () {
    this.token = this.$store.getters.token;
    // this.token.UserID  员工ID
    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      console.log("beforeunload");
      sessionStorage.setItem(
        "headerBarList",
        JSON.stringify(this.$store.getters.headerBarList)
      );
    });
    console.log("screenWidth", this.screenWidth);
    console.log("当前页面刷新和全局刷新");
    // 当前页面刷新和全局刷新
    // 当前页面刷新 则要获取之前所有打开的路由
    let headerBarList = JSON.parse(sessionStorage.getItem("headerBarList"));
    if (headerBarList) {
      if (
        headerBarList.findIndex((obj) => {
          return obj.routerName == this.$route.name && obj.isSelected;
        }) > -1
      ) {
        this.$store.commit("setHeaderBarList", headerBarList);
      }
    }
    // this.getAllSaaSClient();
    this.getRemindWorkInfo();
  },
  mounted () {
    window.addEventListener("scroll", this.handleScroll);
    this.registerBusEvent();
  },
  destroyed () {
    document.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    vBrandInfo () {
      let obj = {};
      if (window.brandCustomSettingStr && window.brandCustomSettingStr != "") {
        obj = JSON.parse(window.brandCustomSettingStr);
        obj.isOpenBrand = true;
        obj.LoginBackground = "url(" + obj.LoginBackground + ")";
        obj.LogoUrl = this.$store.getters.CDNURL + obj.LogoUrl;
      } else {
        obj = {
          isOpenBrand: false,
          BrandSlogan:
            "url(" + require("../../../public/image/login_text.png") + ")",
          LoginBackground:
            "url(" + require("../../../public/image/login_banner.png") + ")",
          LogoUrl: require("../../../public/image/header_logo.png"),
          MainBodyColor: "#f85a59",
          MainDemoName: "治学培训机构",
          QRCode: "",
        };
        // if (this.$store.getters.SaaSClientEditionKey == 6) {
        //   obj.LogoUrl = require('../../../public/image/zhixue_logo.png');
        // } else {
        //   obj.LogoUrl = require('../../../public/image/header_logo.png');
        // }
      }
      return obj;
    },

    vSaaSClientEditionKey () {
      if (
        this.$store.getters.SaaSClientEditionKey == 5 ||
        this.$store.getters.SaaSClientEditionKey == 6
      ) {
        return false;
      } else {
        return true;
      }
    },
    vOpenScanFaceSetting () {
      return this.$utils.CheckOpenSettingPower(
        this.$store.getters.SaaSClientInfo.OpenModule,
        5
      );
    },
    vMendianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vHeadBarList () {
      console.log(this.$store.getters.headerBarList, "vHeadBarList");
      // 5314 老板提出 通用版PC班级排课：标题建议改为 班级管理
      let toRouterItem = this.$store.getters.headerBarList.find((obj) => {
        return obj.routerName == "classGradeApplycourse";
      });
      if (toRouterItem) {
        // if (this.$store.getters.isSysVersionTY) {
        //   toRouterItem.titleName = '班级管理';
        // } else {
        //   toRouterItem.titleName = '班级排课';
        // }
        toRouterItem.titleName = "班级排课";
      }
      return this.$store.getters.headerBarList.filter((o) => {
        return o.routerName.indexOf("teachingMode") == -1;
      });
    },
    vMendianLogo () {
      let img = this.$store.getters.SaaSClientInfo.LogoPath;
      if (!img || img == "/public/image/no_logo@2x.png") {
        return require("../../../public/image/no_logo@2x.png");
      }
      if (img.indexOf("http") > -1) {
        return img;
      } else {
        return (
          this.$store.getters.CDNURL +
          this.$store.getters.SaaSClientInfo.LogoPath
        );
      }
    },
    isShowWebsocket () {
      return this.$route.name != "login";
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    },
    vSaaSClientKeyValue () {
      return this.$store.getters.SaaSClientInfo.MainDemoName;
    },
    vFaceSignTips () {
      return this.$store.getters.faceSignTips;
    },
    vCurrentStatus () {
      let status = "";
      // 紫色：type_purple 蓝色：type_blue 绿色：type_green 黄色：type_yellow 红色：type_red
      switch (this.vFaceSignTips.statusKey) {
        case 1:
          status = "type_purple";
          break;
        case 2:
          status = "type_blue";
          break;
        case 3:
          status = "type_green";
          break;
        case 4:
          status = "type_yellow";
          break;
        case 5:
          status = "type_red";
          break;
        default:
          break;
      }
      return status;
    },
    isSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    },
    vallFunctionBtnList () {
      let allFunctionBtn = {};
      if (this.isSysVersionTY) {
        allFunctionBtn = this.$utils.parseJson(
          this.$initJson.newallFunctionBtn
        );
      } else {
        allFunctionBtn = this.$utils.parseJson(
          this.$initJson.newallFunctionBtn
        );
      }
      // 根据是否开启了门店设置里的追销决定是否加入.
      if (
        this.vMendianInfo.SaaSClientSetting &&
        this.vMendianInfo.SaaSClientSetting.length > 0
      ) {
        let openAppointCourseSetting = this.vMendianInfo.SaaSClientSetting.find(
          (o) => {
            return o.SetTypeKey == 73;
          }
        );
        console.log("moreMendianReport", this.vMendianInfo.TypeKey);
        console.log("openAppointCourseSetting", openAppointCourseSetting);
        if (
          !(openAppointCourseSetting && openAppointCourseSetting.SetKey == 1)
        ) {
          allFunctionBtn.forEach((e) => {
            if (e.title == "教务中心") {
              e.childList = e.childList.filter((obj) => {
                return obj.routerName != "appointmentCourse"; // 约课表
              });
              // e.childList.push({
              //   name: '约课表',
              //   routertitle: '约课表',
              //   routerName: 'appointmentCourse',
              //   auth: true,
              //   roleName: '',
              //   ModulePowerKey: [149, 47]
              // });
            }
          });
        }
        let locaItem = this.vMendianInfo.SaaSClientSetting.find((o) => {
          return o.SetTypeKey == 17;
        });
        if (
          !this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 6)
        ) {
          console.log("upSellupSell", allFunctionBtn);
          //  allFunctionBtn.splice(4, 0);
        } else if (locaItem && locaItem.SetKey == 1) {
          let upSell = this.$utils.parseJson(this.$initJson.upSell);

          if (locaItem && locaItem.SetContent == "2.0") {
            // 追销2.0
            if (Number(this.$store.getters.token.IsAdviserKey) == 0) {
              upSell.childList.splice(0, 2);
            } else {
              upSell.childList.splice(0, 1);
            }
          } else {
            upSell.childList.splice(1, upSell.childList.length - 1);
          }
          console.log(
            "upSell 2.0",
            upSell,
            locaItem,
            this.$store.getters.token.IsAdviserKey
          );
          allFunctionBtn.splice(6, 0, upSell);
        }
      }
      if (this.vMendianInfo.SaaSClientSetting) {
        console.log(
          "this.vMendianInfo.TypeKey == 4 && this.vHasModulePower(87)",
          this.vMendianInfo.TypeKey == 4,
          this.vHasModulePower(87)
        );
        if (this.token.SaaSClientKey == 1) {
          // 1号门店
          if (!(this.vMendianInfo.TypeKey == 4)) {
            console.log("moreMendianReport", this.vMendianInfo.TypeKey);
            allFunctionBtn.forEach((e) => {
              if (e.title == "统计中心") {
                e.childList = e.childList.filter((obj) => {
                  return obj.routerName != "moreMendianReport"; // 连锁门店统计
                });
                // e.childList.push({
                //   name: '连锁门店统计',
                //   routertitle: '连锁门店统计',
                //   routerName: 'moreMendianReport',
                //   auth: true,
                //   roleName: '',
                //   ModulePowerKey: 87
                // });
              }
            });
          }
          allFunctionBtn.forEach((e) => {
            if (e.title == "基础") {
              // e.childList
              // .push
              // {
              //   'name': '客户跟单管理',
              //   'routerName': 'mendianOperationManagement',
              //   'routertitle': '客户跟单管理',
              //   'auth': true,
              //   'roleName': 'router',
              //   'ModulePowerKey': 88
              // },
              // {
              //   'name': '超级管理',
              //   'routerName': 'superManagement',
              //   'routertitle': '超级管理',
              //   'auth': true,
              //   'roleName': 'router',
              //   'ModulePowerKey': '97'
              // },
              // {
              //   'name': '管理与统计',
              //   'routerName': 'managementAndStatistics',
              //   'routertitle': '管理与统计',
              //   'auth': true,
              //   'roleName': 'router',
              //   'ModulePowerKey': '100'
              // },
              // {
              //   'name': '客户管理',
              //   'routertitle': '客户管理',
              //   'routerName': 'customerManagement',
              //   'auth': true,
              //   'roleName': '',
              //   'ModulePowerKey': '99'
              // },
              //    {
              //   'name': '代码模板列表',
              //   'routertitle': '代码模板列表',
              //   'routerName': 'miniProgramTemplateVersionList',
              //   'auth': true,
              //   'roleName': '',
              //   'ModulePowerKey': '101'
              // }, {
              //   'name': '流量套餐管理',
              //   'routertitle': '流量套餐管理',
              //   'routerName': 'FlowSetMealTemplateVersionList',
              //   'auth': true,
              //   'roleName': '',
              //   'ModulePowerKey': '145'
              // }
              // ();
            }
          });
        } else if (!(this.vMendianInfo.TypeKey == 4)) {
          console.log("moreMendianReport", this.vMendianInfo.TypeKey);
          allFunctionBtn.forEach((e) => {
            if (e.title == "统计中心") {
              e.childList = e.childList.filter((obj) => {
                return obj.routerName != "moreMendianReport"; // 连锁门店统计
              });
              // e.childList.push({
              //   name: '连锁门店统计',
              //   routertitle: '连锁门店统计',
              //   routerName: 'moreMendianReport',
              //   auth: true,
              //   roleName: '',
              //   ModulePowerKey: 87
              // });
            }
          });
        } else if (
          this.vMendianInfo.IsOpenMicroClassKey &&
          this.vMendianInfo.IsOpenMicroClassKey != 1
        ) {
          // 1.全部功能-》营销：需开通微课堂 才显示
          allFunctionBtn = allFunctionBtn.filter((obj) => {
            return obj.title != "营销"; // 微网校设置
          });
        }
      }
      if (this.vMendianInfo.IsOpenSelfCoursewareKey == 0) {
        allFunctionBtn.forEach((e) => {
          if (e.title == "课件加密系统") {
            console.log("课件管理权限", e.childList);
            e.childList = e.childList.filter((obj) => {
              return obj.routerName != "generalCoursewareManagement"; // 微网校设置
            });
            console.log("e.childList", e.childList);
          }
          if (e.title == "课件加密系统") {
            console.log("课件管理权限", e.childList);
            e.childList = e.childList.filter((obj) => {
              return obj.routerName != "generalCoursewareManagement"; // 微网校设置
            });
            console.log("e.childList", e.childList);
          }
        });
      }
      // 开通了销售中心
      if (
        this.vMendianInfo.OpenModule &&
        this.vMendianInfo.OpenModule.length > 0
      ) {
        if (
          !this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 2)
        ) {
          allFunctionBtn.forEach((e) => {
            if (e.title == "家校中心") {
              e.childList = e.childList.filter((obj) => {
                return obj.routerName != "creditManagement"; // 微网校设置
              });
              // e.childList.push({
              //   name: '学分管理',
              //   routertitle: '学分管理',
              //   routerName: 'creditManagement',
              //   auth: true,
              //   roleName: '',
              //   ModulePowerKey: 53
              // });
            }
          });
        }
        if (
          !this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 4)
        ) {
          allFunctionBtn.forEach((e) => {
            if (e.title == "教务中心") {
              e.childList = e.childList.filter((obj) => {
                return obj.routerName != "inventoryManagementFile"; // 库存管理
              });
              // e.childList.push({
              //   name: '库存管理',
              //   routertitle: '库存管理',
              //   routerName: 'inventoryManagementFile',
              //   auth: true,
              //   roleName: '',
              //   ModulePowerKey: 95,
              // });
            }
          });
        }
        if (
          !this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 15)
        ) {
          allFunctionBtn.forEach((e) => {
            if (e.title == "师教中心") {
              e.childList = e.childList.filter((obj) => {
                return obj.routerName != "studentManagementEngineer"; // 学管师管理
              });
            }
          });
        }
        if (
          !this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 6)
        ) {
          allFunctionBtn.forEach((e) => {
            if (e.title == "教务中心") {
              e.childList = e.childList.filter((obj) => {
                return obj.routerName != "inventoryManagementFile"; // 库存管理
              });
              e.childList = e.childList.filter((obj) => {
                return obj.routerName != "tryArrangeCourse"; // 库存管理
              });
              // e.childList.push({
              //   name: '试课追销',
              //   routerName: 'tryArrangeCourse',
              //   routertitle: '试课追销',
              //   auth: true,
              //   roleName: '',
              //   ModulePowerKey: 64,
              //      "img_type": "09"
              // });
            }
          });
        }
        if (
          !this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 8)
        ) {
          allFunctionBtn.forEach((e) => {
            if (e.title == "家校中心") {
              e.childList = e.childList.filter((obj) => {
                return obj.routerName != "mendainSuperviseSchoolStatistics"; // 校区督学综合
              });
              e.childList = e.childList.filter((obj) => {
                return obj.routerName != "readShareReport"; // 课效督学统计
              });
              // e.childList.push({
              //   name: '校区督学综合',
              //   routertitle: '校区督学综合',
              //   routerName: 'mendainSuperviseSchoolStatistics',
              //   auth: true,
              //   roleName: '',
              //   ModulePowerKey: 128
              // });
              // e.childList.push({
              //   name: '课效督学统计',
              //   routerName: 'readShareReport',
              //   routertitle: '课效督学统计',
              //   auth: true,
              //   roleName: '',
              //   ModulePowerKey: 164
              // });
            }
          });
        }
        if (
          !this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 10)
        ) {
          allFunctionBtn.forEach((e) => {
            if (e.title == "家校中心") {
              e.childList = e.childList.filter((obj) => {
                return obj.routerName != "recruitStudentShow"; // O2O分销招生
              });
              e.childList = e.childList.filter((obj) => {
                return obj.routerName != "wktBack"; // 微网校
              });
              // e.childList.push({
              //   name: 'O2O分销招生',
              //   routertitle: 'O2O分销招生',
              //   routerName: 'recruitStudentShow',
              //   auth: true,
              //   roleName: '',
              //   ModulePowerKey: 55
              // });
              // e.childList.push({
              //   name: '异业合作一码通',
              //   routerName: 'customerChannelManagement',
              //   routertitle: '异业合作一码通',
              //   auth: true,
              //   roleName: '',
              //   ModulePowerKey: 163
              // });
            }
          });
          if (
            this.$utils.CheckOpenSettingPower(this.vMendianInfo.OpenModule, 1)
          ) {
            allFunctionBtn.forEach((e) => {
              if (e.title == "营销") {
                // e.childList.push({
                //   name: '优惠券招生',
                //   routertitle: '优惠券招生',
                //   routerName: 'couponEnrollment',
                //   auth: true,
                //   roleName: '',
                //   ModulePowerKey: 55
                // });
                // e.childList.push({
                //   name: '员工分销招生',
                //   routertitle: '员工分销招生',
                //   routerName: 'employeeDistributionEnrollment',
                //   auth: true,
                //   roleName: '',
                //   ModulePowerKey: 55
                // });
                // e.childList.push({
                //   name: '引流套餐招生',
                //   routertitle: '引流套餐招生',
                //   routerName: 'diversionPackageEnrollment',
                //   auth: true,
                //   roleName: '',
                //   ModulePowerKey: 55
                // });
                // e.childList.push({
                //   name: '拼团招生',
                //   routertitle: '拼团招生',
                //   routerName: 'groupRecruitment',
                //   auth: true,
                //   roleName: '',
                //   ModulePowerKey: 55
                // });
                // e.childList.push({
                //   name: '阶梯团招生',
                //   routertitle: '阶梯团招生',
                //   routerName: 'ladderGroupEnrollment',
                //   auth: true,
                //   roleName: '',
                //   ModulePowerKey: 55
                // });
                // e.childList.push({
                //   name: '家长分销招生',
                //   routertitle: '家长分销招生',
                //   routerName: 'parentsDistributionEnrollment',
                //   auth: true,
                //   roleName: '',
                //   ModulePowerKey: 55
                // });
              }
            });
          }
        }
        allFunctionBtn.forEach((e) => {
          if (e.title == "课件加密系统") {
            // 隐藏自研课件
            if (
              this.vMendianInfo.IsAuthorizeCoursewareKey &&
              Number(this.vMendianInfo.IsOpenSelfCoursewareKey == 0)
            ) {
              let locaIndex = e.childList.findIndex((item) => {
                return item.routerName == "generalCoursewareManagement";
              });
              console.log("locaIndexlocaIndex", locaIndex);
              if (locaIndex != -1) {
                e.childList.splice(locaIndex, 1);
              }
            }
            // 隐藏授权课件
            if (
              Number(this.vMendianInfo.IsAuthorizeCoursewareKey == 0) &&
              Number(this.vMendianInfo.IsOpenSelfCoursewareKey == 0)
            ) {
              let locaIndex = e.childList.findIndex((item) => {
                return item.moduleName == "authorizationCoursewareManage";
              });
              console.log("locaIndexlocaIndex", locaIndex);
              if (locaIndex != -1) {
                e.childList.splice(locaIndex, 1);
              }
            }
            console.log("e.childList", e.childList);
            if (
              this.$store.getters.SaaSClientEditionKey != 5 &&
              this.$store.getters.SaaSClientEditionKey != 6
            ) {
            } else {
              let locaIndex = e.childList.findIndex((item) => {
                return item.routerName == "teachingModeView";
              });
              console.log("locaIndexlocaIndex", locaIndex);
              if (locaIndex != -1) {
                e.childList.splice(locaIndex, 1);
              }
            }
          }
          if (e.title == "更多") {
            if (this.vMendianInfo.EditionKey != 2) {
              let locaIndex = e.childList.findIndex(item => {
                return item.moduleName == 'applyCourse'
              })
              if (locaIndex != -1) {
                e.childList.splice(locaIndex, 1);
              }
              let towlocaIndex = e.childList.findIndex(item => {
                return item.moduleName == 'courseIDApplyList'
              })
              if (towlocaIndex != -1) {
                e.childList.splice(towlocaIndex, 1);
              }
            }
          }

        });
      }

      return allFunctionBtn;
    },
    vallFunctionBtnTypeChange () {
      this.vallFunctionBtnList.forEach((o) => {
        if (o.english == "More...") {
          o.hiddenType = true;
        }
        o.childList.forEach((c) => {
          if (c.routerName == "classGradeApplycourse") {
            // 5314 通用版PC班级排课：标题建议改为 班级管理
            // if (this.$store.getters.isSysVersionTY) {
            //   c.name = '班级管理';
            // } else {
            //   c.name = '班级排课';
            // }
            c.name = "班级排课";
          }
          if (c.moduleName == "studentAbnormalMenu") {
            let flag = false;
            this.studentPopUpList.forEach((e) => {
              if (this.vHasModulePower(e.ModulePowerKey)) {
                flag = true;
              }
            });
            if (!flag) {
              c.className = "norole";
            }
          }
          if (c.moduleName == "schedulePopupList") {
            let flag = false;
            this.schedulePopUpList.forEach((e) => {
              if (this.vHasModulePower(e.ModulePowerKey)) {
                flag = true;
              }
            });
            if (!flag) {
              c.className = "norole";
            }
          }
        });
        // classGradeApplycourse
      });

      return this.vallFunctionBtnList;
    },
  },
  watch: {
    $route (to, from) {
      // // 所有的路由都为keepAlive
      // if (!to.meta.keepAlive) {
      //   to.meta.keepAlive = true;
      // }
      // let headerBarList = this.$store.getters.headerBarList;
      // // 路由不存在headerBarList 则关闭了 所以把keepAlive设置false
      // if (
      //   headerBarList.findIndex(obj => {
      //     return obj.routerName == from.name;
      //   }) == -1
      // ) {
      //   from.meta.keepAlive = false;
      // }

      // 激活下个路由（选中效果）
      // let toRouterIndex = headerBarList.findIndex(obj => {
      //   return obj.routerName == to.name;
      // });
      // if (toRouterIndex > -1) {
      //   headerBarList[toRouterIndex].isSelected = true;
      //   this.$store.commit('setHeaderBarList', headerBarList);
      // }
      console.log("弹窗");
      this.$nextTick(() => {
        let ele = document.getElementById(to.name);
        if (ele) {
          // 检查目标路由是否有弹出框
          // 有 重新计算遮罩层的zIndex
          // 没有 把遮罩层隐藏
          if (ele.querySelectorAll(".popup_dialog").length > 0) {
            let divs = ele.querySelectorAll(".popup_dialog_box");
            let max = 0;
            for (let i = 0; i < divs.length; i++) {
              let divEL = divs[i];
              max = Math.max(max, divEL.style.zIndex || 0);
            }
            if (max <= 0) {
              max = 10;
            }
            this.$store.commit("SetPopupMaxZIndex", max - 1);
            this.$store.commit("SetPopupShade", true);
          } else {
            if (
              this.$route.name == "recruitStudentShow" &&
              document.querySelectorAll(".open").length > 0
            ) {
              console.log("触发recruitStudentShow");
              this.$store.commit("SetPopupShade", true);
            } else {
              this.$store.commit("SetPopupShade", false);
            }
          }
        }
      });
    },
  },
  components: {
    headerBarItem,
    popUpDropDownBox,
    exportDataList,
    mendianInfo,
    mendianYzm,
  },
  methods: {
    showExportDataList () {
      console.log("showExportDataList");
      this.isShowExportDataList = true;
    },
    // 面容识别录入关闭
    closeShowExportDataList () {
      this.isShowExportDataList = false;
    },
    // 面容识别录入关闭
    closeSavePeopleFaceDiscern () {
      this.$refs.faceSave.closeCanmara();
      this.$store.commit("setIsOpenFaceSave", true);
      this.isShowSavePeopleFaceDiscern = false;
    },
    showAllMenu () {
      this.isShowAllMenu = true;
    },
    openFaceSign (data) {
      SpecialLongLink(data)
        .then((result) => {
          console.log(result, "SpecialLongLink");
        })
        .catch((error) => {
          console.log(error, "SpecialLongLink");
        });
    },
    showFaceStatus (type) {
      this.faceStatus = type;
    },
    // 点击 功能按钮
    doAfterClickFunctionBtn (item) {
      console.log(item, "全部功能");
      if (!item.isShowDropDownBox) {
        // this.$bus.emit('needOpenRouter', item);
        if (item.moduleName == "cwscoursewareManager") {
          this.$dialog.open(this, {
            routerName: "homePage",
            routertitle: "工作台",
            ModulePowerKey: item.ModulePowerKey,
            name: item.name,
            dialogWidth: "1440px",
            moduleName: "cwscoursewareManager",
            data: {},
            callBack: {},
          });
        } else if (item.moduleName == "coursewareUseReport") {
          this.$dialog.open(this, {
            routerName: "homePage",
            routertitle: "工作台",
            ModulePowerKey: item.ModulePowerKey,
            name: item.name,
            dialogWidth: "1440px",
            moduleName: "coursewareUseReport",
            data: {},
            callBack: {},
          });
        } else {
          this.$dialog.openRouter(item, this.$route.name);
          if (item.routerName == "mendiansettingform") {
            let findIndex = this.vHeadBarList.findIndex((obj) => {
              return obj.routerName == "mendiansettingform";
            });
            if (findIndex > -1) {
              this.$bus.emit("switchHeaderBarToMendiansettingform");
            }
          }
        }
        this.hideAllFunction();
      }
    },
    // 显示所有功能
    showAllFunction () {
      clearTimeout(this.timeData);
      this.isShowAllFunction = true;
      if (document.getElementsByClassName("el-select-dropdown")[0]) {
        // 课程表中右侧的el-select插件弹出无法消失问题.
        document.getElementsByClassName("el-select-dropdown")[0].style.display =
          "none";
      }
    },
    // 隐藏所有功能
    hideAllFunction () {
      this.timeData = setTimeout(() => {
        if (!this.isShowAlertDialog) {
          this.isShowAllFunction = false;
          // if(document.getElementsByClassName('header_fixed_menu_ul')){
          //    document.getElementsByClassName(
          //   'header_fixed_menu_ul'
          // )[0].scrollTop = 0;
          // }
        }
      }, 100);
    },
    // 显示所有门店
    showAllMendian () {
      this.isShowAllMendian = true;
    },
    // 隐藏所有门店
    hideAllMendian () {
      this.isShowAllMendian = false;
    },

    // 打开门店设置
    openMendianSetting () {
      if (this.$route.name == "mendiansettingform") {
        return false;
      }
      let item = {
        routertitle: "门店设置",
        routerName: "mendiansettingform",
        ModulePowerKey: 38,
      };
      this.doAfterClickFunctionBtn(item);
    },
    // 退出门店
    outLoginClick () {
      if (this.isShowPeopleFaceDiscern) {
        console.log("退出");
        this.stopMediaStreamTrack();
      }
      this.$nextTick(() => {
        outLogin();
      });
    },
    // 获取所有门店
    getAllSaaSClient () {
      this.allSaasClientList = [];
      GetSwitchableSaaSClient().then((result) => {
        result.data.forEach((obj) => {
          if (obj.SaaSClientKey != this.token.SaaSClientKey) {
            this.allSaasClientList.push(obj);
          }
        });
      });
    },
    // 获取员工信息
    loadEmployeeDetailInfo () {
      return GetEmployeeInfo(this.token.UserID);
    },
    // 切换门店
    async switchSaasClient (item) {
      this.targetSassClientKey = item.SaaSClientKey;
      try {
        let resultMsg = await this.loadEmployeeDetailInfo();
        if (resultMsg.data.IsLeaveKey == 0) {
          layer.alert("该门店下不存在该员工!!");
          this.outLoginClick();
          return;
        }
        if (resultMsg.data.IsVerifyKey == 3) {
          // 3：是 直接登录
          SwitchSaaSClient(this.targetSassClientKey).then(
            (result) => {
              this.$store.commit("setClientId", "");
              doReLogin(result.data);
            },
            (error) => {
              layer.alert(error.msg);
              if (error.code == 2000) {
                this.outLoginClick();
              }
            }
          );
        } else {
          this.isShowAllMendian = false;
          this.isShowSwitchSaasClientDialog = true;
        }
      } catch (e) {
        layer.alert(e.msg);
        if (e.code == 10000) {
          this.outLoginClick();
        }
      }
    },
    closeSwitchSaasClientDialog () {
      this.isShowSwitchSaasClientDialog = false;
    },
    goToteaching () {
      // this.$store.commit('SetPopupShade', false);
      let moduleInfo = {
        routerName: "teachingModeView",
        routertitle: "教学模式",
        ModulePowerKey: 0,
      };
      if (this.vHasModulePower(moduleInfo.ModulePowerKey)) {
        this.$bus.emit("needOpenRouter", moduleInfo);
      } else {
        this.$utils.CheckModulePowerToTips(moduleInfo.ModulePowerKey);
      }
    },
    goToWKT () {
      let moduleInfo = {
        routerName: "wktBack",
        routertitle: "微网校",
        ModulePowerKey: 96,
      };
      if (this.vHasModulePower(moduleInfo.ModulePowerKey)) {
        this.$bus.emit("needOpenRouter", moduleInfo);
      } else {
        this.$utils.CheckModulePowerToTips(moduleInfo.ModulePowerKey);
      }
    },
    // 新建课表 选中课类型（多课 单课）
    doAfterSelectedNewCourseTableType (item) {
      if (item.id == "1") {
        document.body.style.overflow = "auto";
        let moduleInfo = {
          routerName: "indexView",
          routertitle: "工作台",
          moduleName: item.moduleName,
        };
        this.$bus.emit("needOpenRouter", moduleInfo);
      } else if (item.id == "4") {
        let dataInfo = this.$utils.parseJson(item);
        dataInfo.isShowDropDownBox = false;
        this.doAfterClickFunctionBtn(dataInfo);
      } else {
        document.body.style.overflow = "auto";
        let moduleInfo = {
          routerName: "CourseTable",
          routertitle: "课程表",
          moduleName:
            item.id == 2
              ? this.$initJson.allCustomerDialogNames.newCircleCourseTable
                .moduleName
              : this.$initJson.allCustomerDialogNames.newCourseByCalendar
                .moduleName,
        };
        this.$bus.emit("needOpenRouter", moduleInfo);
      }

      this.isShowAlertDialog = false;
      this.isShowAllFunction = false;
    },
    // 鼠标移到新建课
    mouseOverNewCourseTable (childItem, e) {
      if (childItem.isShowDropDownBox) {
        if (childItem.moduleName == "newCircleCourseTable") {
          if (!this.vHasModulePower(47)) {
            return false;
          }
          this.popUpList = this.coursePopUpList;
        }
        if (childItem.moduleName == "studentAbnormalMenu") {
          this.popUpList = this.studentPopUpList;
        }
        if (childItem.moduleName == "schedulePopupList") {
          this.popUpList = this.schedulePopUpList;
        }
        clearTimeout(this.timer);
        if (e) {
          let Rect = e.target.getBoundingClientRect();
          let scrollTop = document.documentElement.scrollTop;
          this.positionTop = Rect.top + Rect.height + scrollTop;
          this.positionLeft = Rect.left - 5;
        }
        this.isShowAlertDialog = true;
      }
    },
    // 鼠标离开新建课
    mouseOutNewCourseTable (childItem) {
      if (childItem.isShowDropDownBox) {
        this.timer = setTimeout(() => {
          this.isShowAlertDialog = false;
        }, 100);
      }
    },
    scrollEvent () {
      this.isShowAlertDialog = false;
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(
        this.vModulePowerList,
        ModulePowerKey
      );
    },
    // 监听页面滚动
    handleScroll () {
      this.isShowAlertDialog = false;
    },
    // 面容识别
    openPeopleFaceDiscem () {
      this.faceStatus = false;
      this.isOpenFaceStatus = false;
      this.isShowSavePeopleFaceDiscern = false;
      this.isShowPeopleFaceDiscern = true;
      this.$store.commit("setIsOpenFaceRecognition", true);
      this.$store.commit("setIsOpenFaceSave", true);
      this.specialData.targetAgentKey = this.token.UserID;
      this.specialData.eventContent = this.token.UserID;
      this.openFaceSign(this.specialData);
    },
    // 关闭面容识别
    closePeopleFaceDiscern () {
      this.isShowPeopleFaceDiscern = false;
    },
    // 面容识别录入
    openSavePeopleFaceDiscem () {
      this.faceStatus = false;
      this.$store.commit("setIsOpenFaceSave", false);

      this.isShowSavePeopleFaceDiscern = true;
    },
    // 面容识别录入关闭
    closeSavePeopleFaceDiscern () {
      this.$refs.faceSave.closeCanmara();
      this.$store.commit("setIsOpenFaceSave", true);
      this.isShowSavePeopleFaceDiscern = false;
    },
    // 关闭面容识别  //检测关闭摄像头
    stopMediaStreamTrack () {
      this.faceStatus = false;
      this.isShowPeopleFaceDiscern = false;
      this.isOpenFaceStatus = true;
      this.$store.commit("setIsOpenFaceRecognition", false);
      console.log("关闭刷脸");
    },
    getRemindWorkInfo () {
      GetIndexIsRemindWorkInfo()
        .then((result) => {
          console.log("getRemindWorkInfo", result.data);
          this.isShowExportDataBtn = result.data > 0;
        })
        .catch((error) => { });
    },
    registerBusEvent () {
      this.$bus.on(this, "wsAfterOpenFace", (data) => {
        if (data.SaaSClientKey == this.token.SaaSClientKey) {
          if (data.targetAgentKey != this.token.UserID) {
            console.log("其他员工开启刷脸签到");
            this.stopMediaStreamTrack();
          }
        }
        // this.getWarmingStatisical();
      });
      this.$bus.on(this, "showExportDataList", (data) => {
        console.log("showExportDataList", data);
        this.getRemindWorkInfo();
        // this.getWarmingStatisical();
      });
    },
  },
};
</script>
<style>
/* .courseware_module_top {
  background: rgba(228, 62, 9, 1) !important;
  padding-left: 15px;
} */
</style>
