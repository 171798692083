<!-- 日期 -->
<template>
  <el-table-column :fixed="columnData.fixed||false"
                   :label="columnData.label"
                   :prop="columnData.prop"
                   :width="columnData.width||110"
                   :sortable="columnData.sortable"
                   :align="columnData.align||'left'"
                   :sort-orders="['ascending', 'descending']"
                   :label-class-name="columnData.columnClass">
    <template slot-scope="scope">
      <div>{{convertDateformat(scope.row)}}</div>
      <div v-if="columnData.extend&&columnData.extend.showTimeAgo"
           class="font_gray">{{setAgoTime(scope.row)}}</div>
    </template>
  </el-table-column>
</template>

<script>
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          type: String,
          extend: {
            dateType: 'YYMMRR' // 日期类型
          }
        };
      }
    }
  },
  created () {
  },
  methods: {
    // 日期转换
    convertDateformat (rowData) {
      // 默认按YYMMRR转换日期格式
      if (rowData[this.columnData.prop] && (!this.columnData.extend || !this.columnData.extend.dateType || this.columnData.extend.dateType == 'YYMMRR')) {
        return this.$utils.formatDateToLine(rowData[this.columnData.prop]);
      } else {
        return rowData[this.columnData.prop];
      }
    },

    setAgoTime (rowData) {
      if (rowData[this.columnData.prop]) {
        let date = this.$utils.monthDayDiff(rowData[this.columnData.prop], this.$utils.formatDateToLine(Date.new()));
        let year = date.year > 0 ? date.year + '年' : '';
        let month = date.month > 0 ? date.month + '个月' : '';
        let day = ''; // this.studentRegisterTime
        if (date.month > 0 || date.year > 0) {
          day = date.day > 0 ? date.day + '天' : '';
        } else {
          day = date.day >= 0 ? date.day + '天' : '';
        }
        return year + month + day;
      } else {
        return '';
      }
    }
  }
};
</script>

<style>
</style>