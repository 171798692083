<!-- 日期 -->
<template>
  <el-table-column :fixed="columnData.fixed||false"
                   :label="columnData.label"
                   :prop="columnData.prop"
                   :width="columnData.width||110"
                   :sortable="columnData.sortable"
                   :align="columnData.align||'left'"
                   :sort-orders="['ascending', 'descending']"
                   :label-class-name="columnData.columnClass">
    <template slot-scope="scope">
      <div class="device_control_list" v-if="scope.row&&scope.row.OLAPKey>0">
        <icon-authorization v-for="(item,key) in setArray(scope.row)" :key="key" :courseAuth="item" :dataInfo="scope.row" @doDeviceCount="clickEvent"></icon-authorization>
        <!-- <icon-authorization :viewtype="'APP'" :courseAuth="scope.row" @doDeviceCount="clickEvent"></icon-authorization>
        <icon-authorization :viewtype="'TV'" :courseAuth="scope.row" @doDeviceCount="clickEvent"></icon-authorization> -->
       </div>
    </template>
  </el-table-column>
</template>

<script>
import iconAuthorization from './icon-authorization.vue'
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          type: String,
          extend: {
            dateType: 'YYMMRR' // 日期类型
          }
        };
      }
    },
  },
  components:{
    iconAuthorization
  },
  created () {
  },
  methods: {
    setArray(data){
  
      let newArr=[]
        let itemPC = {
        IsAuthKey: data.IsPCAuthKey,
        IsRecordKey: data.IsPCRecordKey,
        AuthorizeDeviceNum: data.AuthorizeDeviceNum,
        AuthCount: data.PCDeviceAuthCount,
        LocationLockKey: data.PCIsLocationLockKey,
        type: 'PC'
      };
      let itemTV = {
        IsAuthKey: data.IsTVAuthKey,
        IsRecordKey: data.IsTVRecordKey,
        AuthorizeDeviceNum: data.TVAuthorizeDeviceNum,
        AuthCount: data.TVDeviceAuthCount,
        LocationLockKey: data.TVIsLocationLockKey,
        type: 'TV'
      };
      let itemAPP = {
        IsAuthKey: data.IsAPPAuthKey,
        IsRecordKey: data.IsAPPRecordKey,
        AuthorizeDeviceNum: data.APPAuthorizeDeviceNum,
        AuthCount: data.APPDeviceAuthCount,
        LocationLockKey: data.IsLocationLockKey,
        type: 'APP'
      };
      newArr.push(itemPC);
      newArr.push(itemAPP);
      newArr.push(itemTV);

      return newArr
    },
    clickEvent (rowData,type) {
      console.log('clickEvent',rowData,type)
      // 表示是否支持点击
      if (this.columnData.extend && typeof this.columnData.extend.click == 'function') {
        // 普通点击事件 直接执行回调
        if (this.hasModulePower(Number(this.columnData.extend.ModulePowerKey))) {
          this.columnData.extend.click(rowData,type);
        } else {
          this.$utils.CheckModulePowerToTips(this.columnData.extend.ModulePowerKey);
        }
      }
    },
    // 判断权限,
    hasModulePower (ModulePowerKey) {
      ModulePowerKey = ModulePowerKey || '';
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    }

   
  }
};
</script>

<style>
.device_control_list{
  display: flex;
  flex-direction: row;
}
</style>