<template>
  <div class="">
    <div class="calendar_tiem_range input_time_range">
      <time-picker :timeRange="timeRangeForClassTime"
                   :placeholder="'开始时间'"
                   :readonly="true"
                   :dateNumber="startTime"
                   @upDateNumber="setStartTime"></time-picker>
      <div class="date_radio">
      </div>
      <time-picker :timeRange="timeRangeForClassTime"
                   :placeholder="'结束时间'"
                   :readonly="true"
                   :dateNumber="endTime"
                   @upDateNumber="setEndTime"></time-picker>
    </div>
    <!-- 按钮组 -->
    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             :btnText="'确定'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import timePicker from './time-picker';
export default {
  data () {
    return {
      timeRangeForClassTime: '',
      startTime: '',
      endTime: ''
    };
  },
  components: {
    timePicker
  },
  props: {
    start: {
      type: String,
      default: ''
    },
    end: {
      type: String,
      default: ''
    }
  },
  created () {
    this.timeRangeForClassTime = this.$utils.getSaaSClientSetInfo(64).SetContent + ':00-' + this.$utils.getSaaSClientSetInfo(65).SetContent + ':00';
    this.startTime = this.start;
    this.endTime = this.end;
  },
  watch: {
  },
  computed: {
    vSaasClientInfo () {
      return this.$store.getters.SaaSClientInfo;
    }
  },
  methods: {
    setStartTime (date) {
      this.startTime = date;
    },
    setEndTime (date) {
      this.endTime = date;
    },
    cancelClick () {
      this.$emit('cancelClick');
    },
    confirmClick (callBack) {
      let flag = false;
      if (!this.startTime) {
        flag = true;
        layer.alert('开始时间不能为空');
      } else if (!this.endTime) {
        flag = true;
        layer.alert('结束时间不能为空');
      } else {
        let startCount = Number(this.startTime.split(':')[0]) * 60 + Number(this.startTime.split(':')[1]);
        let endCount = Number(this.endTime.split(':')[0]) * 60 + Number(this.endTime.split(':')[1]);
        if (startCount >= endCount) {
          flag = true;
          layer.alert('开始时间不能大于等于结束时间');
        }
      }
      if (flag) {
        if (typeof callBack == 'function') { callBack(); }
        return;
      }

      this.$emit('onConfirmClick', this.startTime, this.endTime, callBack);
    }
  }
};
</script>
