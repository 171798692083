<template>
  <div :class="{opt:isFocus,'import_list':rowType=='add','table_td':rowType!='add' }"
       style="width:108px">
    <input :class="{text_error:vStudentNameWarning}"
           v-model="studentName"
           ref="studentName"
           v-if="vIsEdit"
           :placeholder="rowType=='add'?'学生名(必填)':''"
           @keydown="keydownEvent($event)"
           @blur="blurEvent"
           @focus="focusEvent($event)" />
  </div>
</template>

<script>
import { VerifyOldStudent } from '../../../../../API/workbench';
export default {
  data () {
    return {
      isFocus: false,
      studentName: ''
    };
  },
  props: {
    rowType: {
      type: String,
      default: ''
    }, // 输出类型
    rowData: Object,
    isShowPart: Boolean,
    tableData: Array
  },
  computed: {
    vStudentNameWarning () {
      let flag = false;
      let warningIndex = this.rowData.WarningList.find(obj => { return obj.TargetField == 1; }); // 重名
      let errorIndex = this.rowData.ExceptionList.find(obj => { return obj.TargetField == 1; });// 空格 和 为空
      if (warningIndex || errorIndex) {
        flag = true;
      }
      return flag;
    },
    vIsEdit () {
      let flag = false;
      if (this.rowData.guid || this.rowType == 'add') {
        flag = true;
      }
      return flag;
    }
  },
  watch: {
    'rowData.StudentKeyValue': {
      handler (n, o) {
        this.studentName = n;
      }
    },
    'rowData.focusField': {
      handler (n, o) {
        if (n == 'StudentKeyValue' && this.rowData.optioningRow && this.$refs.studentName) {
          this.rowData.focusField = 'StudentKeyValue';
          this.$refs.studentName.focus();
          // 光标定位到文本框字符串末尾
          var tObj = this.$refs.studentName;
          if (tObj.value) {
            var sPos = tObj.value.length;
            if (tObj && sPos) {
              this.$utils.setCaretPosition(tObj, sPos);
            }
          }
        }
      },
      deep: true // 表示开启深度监听
    }
  },
  created () {
    this.studentName = this.rowData.StudentKeyValue;
  },
  mounted () {
    if (this.rowData.optioningRow && this.rowData.focusField == 'StudentKeyValue' && this.$refs.studentName) {
      this.$refs.studentName.focus();
    }
  },
  methods: {
    // 鼠标聚焦
    focusEvent (e) {
      this.isFocus = true;
      this.rowData.optioningRow = true;
      this.rowData.focusField = 'StudentKeyValue';
      this.$emit('onFocusEvent', 'StudentKeyValue');
    },
    // 键盘事件
    keydownEvent (e) {
      switch (e.keyCode) {
        case 9: // Tab
          this.rowData.focusField = 'SexKeyValue';
          e.preventDefault();// 防止跳两格
          break;
        case 13: // 回车
          this.rowData.focusField = 'SexKeyValue';
          break;
        case 37: // 左键（回到上一行的最后表格）
          if (Number(this.rowData.Number) > 1) {
            this.rowData.focusField = 'CurrentCount';
            this.$emit('doUpwardMove', this.rowData, 'CurrentCount');
          }
          break;
        case 38: // 向上键
          if (Number(this.rowData.Number) > 1) {
            this.$emit('doUpwardMove', this.rowData, 'StudentKeyValue');
          }
          break;
        case 39: // 右键
          setTimeout(() => {
            this.rowData.focusField = 'SexKeyValue';
          }, 50);
          break;
        case 40: // 向下键
          this.$emit('doDownwardMove', this.rowData, 'StudentKeyValue');
          break;
        default: ;
          break;
      }
    },
    // 失去焦点事件
    // 异常：学生名未填写、学生名中间有空格
    // 警告：学生已存在（多种情况，之前已提供）
    blurEvent () {
      this.rowData.focusField = '';
      this.isFocus = false;
      if (this.studentName) {
        this.studentName = this.studentName.trim();
      }
      this.verificationStudentName(this.studentName);
      if (this.rowData.StudentKeyValue != this.studentName) {
        this.rowData.isValifiStudent = true;
        this.rowData.updateTime = Date.new();
        this.rowData.StudentKeyValue = this.studentName;
        // TypeKey:1 学生名重复 2 学生名重复且手机号不同
        let hasExistsIndex = this.rowData.WarningList.findIndex(obj => { return obj.TargetField == 1; });
        let hasExistsIndex2 = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 1; });
        VerifyOldStudent(this.rowData.StudentKeyValue, this.rowData.CustomerPhoneName).then(result => {
          result.data = result.data || {};
          if (hasExistsIndex >= 0) {
            this.rowData.WarningList.splice(hasExistsIndex, 1);
          }
          if (hasExistsIndex2 >= 0) {
            this.rowData.ExceptionList.splice(hasExistsIndex2, 1);
          }
          // 异常
          if (result.data.excption) {
            this.rowData.ExceptionList.push(result.data.excption);
          }
          // 警告
          if (result.data.warning) {
            this.rowData.WarningList.push(result.data.warning);
          }
          this.rowData.StudentKey = result.data.StudentKey;
          this.rowData.isValifiStudent = false;
        }, error => {
          this.rowData.isValifiStudent = false;
          layer.alert(error.msg);
        });
      }
      this.$emit('onBlurEvent', this.rowData, 'StudentKeyValue');
    },
    // 验证学生名
    verificationStudentName (studentName) {
      let errorEmptyIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 1 && obj.TypeKey == 1; });
      // 1 -未填写学生名
      if (!studentName && errorEmptyIndex < 0) {
        this.rowData.ExceptionList.push({ TargetField: 1, TypeKey: 1, TypeKeyValue: '未填写学生名' });
      } else if (studentName && errorEmptyIndex >= 0) {
        this.rowData.ExceptionList.splice(errorEmptyIndex, 1);
      }

      let errorSpaceIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 1 && obj.TypeKey == 2; });
      // 2 学生名不能存在空格等特殊符号"
      var reg = /\s/;
      if (reg.test(studentName) && errorSpaceIndex < 0) {
        this.rowData.ExceptionList.push({ TargetField: 1, TypeKey: 2, TypeKeyValue: '学生名不能存在空格等特殊符号' });
      } else if (!reg.test(studentName) && errorSpaceIndex >= 0) {
        this.rowData.ExceptionList.splice(errorSpaceIndex, 1);
      }

      let errorMaxLengthIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 1 && obj.TypeKey == 3; });
      // 3-学生名长度不能超过10个字符"
      if (studentName) {
        if (studentName.length > 10 && errorMaxLengthIndex < 0) {
          this.rowData.ExceptionList.push({ TargetField: 1, TypeKey: 3, TypeKeyValue: '学生名长度不能超过10个字符' });
        } else if (studentName.length <= 10 && errorMaxLengthIndex >= 0) {
          this.rowData.ExceptionList.splice(errorMaxLengthIndex, 1);
        }
      }
      // 4-禁止学生名重名
      let errorMaxLengthIndex4 = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 1 && obj.TypeKey == 4; });
      if (studentName) {
        if (studentName.length > 10 && errorMaxLengthIndex4 < 0) {
          this.rowData.ExceptionList.push({ TargetField: 1, TypeKey: 4, TypeKeyValue: '禁止学生名重名' });
        } else if (studentName.length <= 10 && errorMaxLengthIndex4 >= 0) {
          this.rowData.ExceptionList.splice(errorMaxLengthIndex4, 1);
        }
      }
    }
  }
};
</script>

<style>
</style>