<template>
  <!-- 高德地图API  https://lbs.amap.com/api/javascript-api/guide/services/autocomplete 
  实例1：https://blog.csdn.net/weixin_42065713/article/details/116238475
  实例2: https://blog.csdn.net/weixin_44743565/article/details/108669391-->
  <div>
    <div class="popup_dialog_box_content clearfix"
         style="background: #f6f6f6;">
      <!-- 地图容器 -->
      <div id="map_container">
        <!-- 地图 -->
        <el-amap :center="center"
                 :zoom="zoom"
                 class="amap_demo"
                 :events="events"
                 :plugin="plugin">
          <!-- 地图标记 -->
          <el-amap-marker v-for="(marker,index) in markers"
                          :position="marker.position"
                          :key="index"></el-amap-marker>
        </el-amap>
      </div>

      <!-- <div class="map_fixed_bottom">
        <div class="map_fixed_input">
          <input id="input_box"
                 type="text"
                 placeholder="请输入您的地址"
                 @input="inputText">
        </div>
        <div class="map_fixed_ul">
          <div class="map_fixed_list">
            <div class="list_name"
                 v-if="address.name">
              <span class="font_blue">[当前]</span>
              <span>{{address.name}}</span>
            </div>
            <div class="list_text">
              {{address.formattedAddress}}
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { UploadFiles } from '../../../API/workbench';
import html2canvas from '../../../../public/js/html2canvas.js';
window._AMapSecurityConfig = {
  securityJsCode: '9c5c4b212490be5167546fa24fff6890'
};
export default {
  props: {
    locationInfo: Array
  },
  data () {
    let _this = this;
    return {
      loaded: false,
      // 地址
      address: { name: '', formattedAddress: '' },
      zoom: 17,
      // zooms: [10, 18],
      // 默认中心点
      center: [116.40, 39.90],
      // 标记点
      markers: [
        // 标记点位置
        { position: [116.40, 39.90] }
      ],
      // 当前地图的插件
      plugin: [
        {
          preserveDrawingBuffer: true,
          enableHighAccuracy: true, // 是否使用高精度定位，默认:true
          timeout: 10000,          // 超过10秒后停止定位，默认：无穷大
          maximumAge: 0,           // 定位结果缓存0毫秒，默认：0
          convert: true,           // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: true,        // 显示定位按钮，默认：true
          buttonPosition: 'RB',    // 定位按钮停靠位置，默认：'LB'，左下角
          showMarker: true,        // 定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true,        // 定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: true,     // 定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: true, // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
          extensions: 'all',
          pName: 'Geolocation',
          events: {
            init: ''
          }
        }
      ],
      events: {
        // 点击获取地址的数据
        click (e) {
          console.log(e, ' 点击获取地址的数据');
          _this.center = [e.lnglat.lng, e.lnglat.lat];
          // 将当前经纬度给标记点
          _this.markers[0].position = _this.center;
          _this.setPosition(_this.center);
        }
      }
    };
  },
  created () {
    console.log(this.plugin, ' this.plugin111');
    this.plugin[0].events.init = (o) => {
      console.log(this.locationInfo, 'this.locationInfo');
      if (this.locationInfo[1].SetContent.length > 0) {
        if (this.locationInfo[1].SetContent instanceof Array) {
          this.center = this.locationInfo[1].SetContent;
        } else {
          this.center = JSON.parse(this.locationInfo[1].SetContent);
        }
        this.markers[0].position = this.center;
        this.setPosition(this.center);
      } else {
        // o 是高德地图定位插件实例
        o.getCurrentPosition((status, result) => {
          console.log(status, result);
          if (result && result.position) {
            // 当前地址信息
            this.address.name = result.aois.length > 0 ? result.aois[0].name : '';
            this.address.formattedAddress = result.formattedAddress;
            // 将当前经纬度给中心点
            this.center = [result.position.lng, result.position.lat];
            // 将当前经纬度给标记点
            this.markers[0].position = this.center;
          }
        });
      }
    };
  },
  computed: {

  },
  mounted () {
    this.inputText();
  },
  destroyed () {
    var AmapClassList = document.getElementsByClassName('amap-sug-result');

    // AmapClassList.forEach(o => {
    //   o.style.display = 'none';
    // });
    for (var i = 0; i < AmapClassList.length; i++) {
      AmapClassList[i].style.display = 'none';
    }
    this.hideFun(AmapClassList);
  },
  methods: {
    hideFun (type) {
      setTimeout(function () {
        // type.forEach(o => {
        //   o.style.display = 'none';
        // });
        for (var i = 0; i < type.length; i++) {
          type[i].style.display = 'none';
        }
      }, 2000);
    },
    setPosition (center) {
      console.log(center, '获取定位');
      AMap.plugin(['AMap.Geocoder'], () => {
        let geocoder = new AMap.Geocoder({
          radius: 1000,
          extensions: 'all'
        });
        geocoder.getAddress(center, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            if (result && result.regeocode) {
              // 当前地址信息
              this.address.name = result.regeocode.aois.length > 0 ? result.regeocode.aois[0].name : '';
              this.address.formattedAddress = result.regeocode.formattedAddress;
              console.log(result, this.address, '当前地址信息');
            }
          }
        });
      });
    },
    inputText () {
      AMap.plugin('AMap.Autocomplete', () => {
        // 实例化Autocomplete
        var autoOptions = {
          // input 为绑定输入提示功能的input的DOM ID
          input: 'input_box'
        };
        // 无需再手动执行search方法，autoComplete会根据传入input对应的DOM动态触发search
        var autoComplete = new AMap.Autocomplete(autoOptions);
        AMap.event.addListener(autoComplete, 'select', select); // 注册监听，当选中某条记录时会触发
        AMap.event.addListener(autoComplete, 'error', onError); // 注册监听，当选中某条记录时会触发
        console.log(autoComplete, 'autoComplete', autoOptions);
        // 解析定位正确信息
        let _this = this;
        function select (data) {
          // 当前坐标信息
          _this.center = [data.poi.location.lng, data.poi.location.lat];
          _this.markers[0].position = _this.center;
          // 当前地址信息
          _this.address.name = data.poi.name;
          _this.address.formattedAddress = data.poi.district + data.poi.address;
          console.log(data);
        }
        // 解析定位错误信息
        function onError (data) {
          console.log(data, '定位失败');
          // mui.alert(data.info);
        }
      });
    },
    confirmClick () {
      let dragDom = document.getElementsByClassName('popup_dialog_box')[2];
      console.log('dragDom.offsetTop', dragDom.offsetTop, 'dragDomTop.offsetLeft', dragDom.offsetLeft);
      console.log('dragDom', dragDom);
      console.log(this.address, 'this.address');
      const { that } = this;
      if (this.address.name) {
        layer.confirm('你确定选择地址为：' + this.address.name + '?', {
          btn: [{
            name: '确认',
            callBack: () => {
              var element = document.getElementById('map_container');
              console.log(element, '截图地址1');
              html2canvas(element, {
                // 转换为图片
                useCORS: true, // 解决资源跨域问
                // x: dragDom.offsetLeft,
                y: dragDom.offsetTop + 140,
                width: 430,
                height: 240
              }).then(canvas => {
                let imgStr = canvas.toDataURL('image/png', 1.0);
                console.log(imgStr, 'html2canvas-截图地址');
                var fd = new FormData();
                var blob = this.dataURItoBlob(imgStr);
                fd.append('source_from', 'webpage_upload');// 在formdata加入需要的参数
                fd.append('file', blob, 'image.png');
                UploadFiles(1, '', fd).then(result => {
                  console.log(this.center, '截图地址');
                  let locationInfo = [
                    { 'SetKey': '', 'SetKeyValue': '', 'SetContent': this.address.formattedAddress, 'SetTypeKey': '14' },
                    { 'SetKey': '', 'SetKeyValue': '', 'SetContent': JSON.stringify(this.center), 'SetTypeKey': '15' },
                    { 'SetKey': '', 'SetKeyValue': '', 'SetContent': result.data.url, 'SetTypeKey': '16' }
                  ];
                  this.$emit('setLocationInfo', locationInfo);
                }, err => {
                  layer.alert(err.msg);
                });
              });
              layer.close();
            }
          }]
        });
      } else {
        layer.alert('请选择您的地址');
      }
    },
    cancelClick () {
      var AmapClassList = document.getElementsByClassName('amap-sug-result');
      AmapClassList.forEach(o => {
        o.style.display = 'none';
      });
      this.hideFun(AmapClassList);
      this.$emit('close');
    },
    // 图片转成Buffer
    dataURItoBlob (dataURI) {
      var byteString = atob(dataURI.split(',')[1]);
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    }
  }
};
</script>
<style>
#map_container {
  width: 100%;
  height: 410px;
}
.amap_demo {
  width: 100%;
  height: 100%;
}
.amap-logo {
  display: none !important;
}
.amap-copyright {
  display: none !important;
}
</style>
