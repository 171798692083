<template>
  <div class="form_info_line"
       :class="{form_info_required:required}">
    <div class="form_info_field">
      <span>{{formTitle}}</span>
      <span v-if="helpDescription">
        <input-form-tip :tips='helpDescription'></input-form-tip>
      </span>
    </div>
    <div class="form_info_value"
         :class="readonly?'':'form_info_select'"
         id="cascader">
      <el-cascader v-model="selectedOptions"
                   ref="cascaderAddr"
                   :disabled="readonly"
                   :class="readonly?'input_readonly':className"
                   :placeholder="readonly?'':'请选择课件包'"
                   :options="options"
                   @active-item-change="getData"
                   @change="seletdItem"
                   :props="{ value:'OLAPKey', label:'MainDemoName', children:'childTree',checkStrictly: true }">
      </el-cascader>
    </div>

  </div>
</template>

<script>
import { HQSearchCoursewarePackage } from '../../../../../API/workbench.js';
export default {
  data () {
    return {
      selectedOptions: [],
      options: [],
      adgadg: false
    };
  },
  props: {
    formTitle: String,
    required: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: 'input_blue'
    },
    selectedInfo: Array, // [1,2,3]
    selectedId: [Number, String], // [1,2,3]
    readonly: {
      type: Boolean,
      default: false
    },
    helpDescription: String // 表单说明提示
  },
  created () {
    console.log('selectedId-', Number(this.selectedId));
    // if (Number(this.selectedId) > 0) {
    //   this.getLevelPathName(this.selectedId);
    // }
  },
  mounted () {
    this.getData();
  },
  watch: {
    // selectedInfo (c, o) {
    //   console.log(c, 'selectedInfo');
    //   if (c.length > 0) {
    //     setTimeout(() => {
    //       this.getNodes([c[0]], (data) => {
    //         this.getNodes([c[0], c[1]], (data) => {
    //           this.selectedOptions = c;
    //           console.log(data, 'selectedInfo-data');
    //         });
    //       });
    //     }, 150);
    //   }
    // }
  },
  methods: {
    getData (val) {
      this.getNodes(val);
    },
    getNodes (val, callBack) {
      console.log(val, callBack);
      if (!val) {
        HQSearchCoursewarePackage(0).then(result => {
          this.options = result.data.map((item, i) => {
            return {
              OLAPKey: item.OLAPKey,
              MainDemoName: item.MainDemoName,
              childTree: []// item.IsSubclass && Number(item.IsSubclass) > 0 ? [] : undefined
            };
          });
        });
      } else if (val.length === 1) {
        this.options.map((value, i) => {
          if (value.OLAPKey === val[0]) {
            HQSearchCoursewarePackage(val[0]).then(result => {
              value.childTree = result.data.map((value, i) => {
                return {
                  OLAPKey: value.OLAPKey,
                  MainDemoName: value.MainDemoName,
                  childTree: value.TypeKey > 0 ? [] : undefined
                };
              });
              if (callBack) {
                callBack();
              }
            });
          }
        });
      } else if (val.length === 2) {
        this.options.map((value, i) => {
          if (value.OLAPKey === val[0]) {
            value.childTree.map((itec, i) => {
              if (itec.OLAPKey === val[1]) {
                HQSearchCoursewarePackage(val[1]).then(result => {
                  this.adgadg = true;
                  if (result.data.length > 0) {
                    itec.childTree = result.data.map((every, i) => {
                      return {
                        OLAPKey: every.OLAPKey,
                        MainDemoName: every.MainDemoName
                      };
                    });
                  }
                  if (callBack) {
                    callBack();
                  }
                });
              }
            });
          }
        });
      }
    },
    seletdItem (item) {
      let data = this.$refs.cascaderAddr.getCheckedNodes()[0].pathLabels;
      // console.log('seletdItem', item, data);
      if (this.$refs.cascaderAddr) {
        this.$emit('selectedItem', this.selectedOptions, data);
        this.$refs.cascaderAddr.dropDownVisible = false;
      }
    }
  }
};
</script>
<style>
#cascader {
  height: 30px;
}
#cascader .el-cascader {
  display: inline-block;
  position: relative;
  font-size: 14px;
  line-height: 26px;
}
#cascader .el-cascader .el-input {
  width: 300px;
}
#cascader .el-cascader .el-cascader__label {
  text-align: right;
}
.el-icon-arrow-right.el-cascader-node__postfix {
  right: 20px;
}
#cascader .el-cascader .el-input__suffix-inner {
  position: absolute;
  right: -4px;
  bottom: 2px;
  display: none;
}
/* #cascader .el-cascader .el-cascader .el-icon-arrow-down {
  font-size: 16px;
} */
/* 滚动条的宽度 */
::-webkit-scrollbar {
  width: 3px;
  height: 10px;
}
/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: #a1a3a9;
  border-radius: 3px;
}
.input_readonly input {
  color: #999 !important;
}
.input_readonly .el-input.is-disabled .el-input__inner {
  background: none;
}
</style>
