<template>
  <div class="pdf courseware_add_img_box">
    <div class="add_img">
      <!-- <img class="img_box" :src="vUrl" alt="" /> -->
      <!-- <pdf class="img_box" :src="vUrl" > </pdf> -->
    </div>
    <!-- <div class="add_progress_banner" v-if="vProgress!='0%'">
          <div class="add_progress" :style="{left: vProgress}"></div>
      </div> -->
    <!-- <div v-if="dataInfo.percent && dataInfo.percent < 100" class="add_progress_text">上传中 {{vProgress}}</div> -->
    <!-- <p class="arrow"> -->
    <!-- // 上一页 -->
    <!-- <span @click="changePdfPage(0)" class="turn" :class="{grey: currentPage==1}">Preview</span>
      {{currentPage}} / {{pageCount}} -->
    <!-- // 下一页 -->
    <!-- <span @click="changePdfPage(1)" class="turn" :class="{grey: currentPage==pageCount}">Next</span>
      </p> -->
    <!-- // 自己引入就可以使用,这里我的需求是做了分页功能,如果不需要分页功能,只要src就可以了 -->
    <!-- src需要展示的PDF地址
    currentPage"  当前展示的PDF页码
    pageCount=$event"  PDF文件总页码
    currentPage=$event" // 一开始加载的页面
    loadPdfHandler"> // 加载事件 -->
    <!-- <pdf
      :src="vUrl" 
      :page="currentPage"
      @num-pages="pageCount=$event"
      @page-loaded="currentPage=$event" 
      @loaded="loadPdfHandler">
    </pdf> -->
  </div>
</template>
<script>
//import pdf from 'vue-pdf';
export default {
  data () {
    return {
      currentNum: 1,
      isShowPPT: true,
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      fileType: 'pdf' // 文件类型
    };
  },
  props: {
    dataInfo: Object
  },
  // components: {pdf},
  computed: {
    vUrl () {
      if (this.dataInfo.AttachViewUrl) {
        return this.dataInfo.AttachViewUrl;
      } else {
        return require('../../../../../../public/image/courseware_pdf_cover.png');
      }
    },
    vProgress () {
      return Number(this.dataInfo.percent) + '%';
    },
  },
  watch: {
    'dataInfo.AttachViewUrl' (n, o) {
      if (o && n != o) {
        this.currentNum = 1;
      }
    }
  },
  created () {
    console.log(this.dataInfo);
  },
  methods: {
    backClick () {
      if (this.currentNum > 1) {
        this.currentNum = this.currentNum - 1;
      }
    },
    nextClick () {
      if (this.currentNum < this.dataInfo.DurationNum) {
        this.currentNum = this.currentNum + 1;
      }
    },
    // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
    changePdfPage (val) {
      // console.log(val)
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--;
        // console.log(this.currentPage)
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++;
        // console.log(this.currentPage)
      }
    },

    // pdf加载时
    loadPdfHandler (e) {
      this.currentPage = 1; // 加载的时候先加载第一页
    }
  }
};
</script>

<style scoped>
.courseware_add_img_box .btn_no_type {
  opacity: 0.5;
  cursor: default;
}

.courseware_add_img_box .left_btn.btn_no_type:hover {
  background: url(../../../../../../public/image/left_btn.png);
}

.courseware_add_img_box .right_btn.btn_no_type:hover {
  background: url(../../../../../../public/image/right_btn.png);
}
.courseware_add_img_box {
  min-height: 492px !important;
  height: 100%;
}
</style>