<template>
  <!-- <div> -->
  <!-- 操作菜单 courseTimetableSum=》隐藏课表明细 -->
  <div class="content_block" @mouseout.stop="mouseOutNewCourseTable(itemInit)" v-if="!item.courseTimetableSum">
      <div class="content_block_text" 
        @mouseover.stop="mouseOverNewCourseTable(itemInit)"
        @click="doItemClick(itemInit)"
        :class="{noData:!isActive}"
        v-if="itemName">
      <el-tooltip popper-class="mendian_block_tooltip" effect="light" visible-arrow="false" :content="itemName" :disabled="disabled" placement="top">
        <div><span ref="nameBox" >{{itemName}}</span></div>
      </el-tooltip>
      </div>
    <div v-show="itemInit.isMenu" @mouseover.stop="mouseOverNewCourseTable(itemInit)">
      <div
        class="course_student_attendance_operation module_content_menuList"
        style="top: 41px;left: 50%;margin-left: -74px;" >
        <div class="top_bg"></div>
        <drop-down-box :optionJsonList="menuItemList" @clickOptionForAlertDialog="doMenuClick"></drop-down-box>
      </div>
    </div>
  </div>
</template> 

<script>
import dropDownBox from '../../../common/drop-down-box';
export default {
  props: {
    item: {
      // 数据对象，完整的对象，这里不再描述
      type: Object,
      require: true,
      default: null
    },
    itemName: {
      // 名称
      type: String,
      require: true,
      default: ''
    },
    isActive: {
      // 是否启用
      type: Boolean,
      require: true,
      default: true
    },
    menuItemList: {
      // 是否启用
      type: Array,
      require: true,
      default: []
    },
    menuData: {
      // 是否启用
      type: Object,
      require: true,
      default: {}
    }
  },
  data () {
    return {
      itemInit: {},
      disabled: false
    };
  },
  created () {
    this.itemInit = this.item;
  },
  mounted () {
    if (this.$refs.nameBox) {
      this.disabled = this.$refs.nameBox.offsetWidth < (this.$refs.nameBox.parentElement.parentElement.offsetWidth - 16);
    }
  },
  watch: {
    item: {
      // 深度监听该对象
      handler: function (newVal, oldVal) {
        this.itemInit = this.item;
        this.$nextTick(() => {
          if (this.$refs.nameBox) {
            this.disabled = this.$refs.nameBox.offsetWidth < (this.$refs.nameBox.parentElement.parentElement.offsetWidth - 16);
          }
        });
      }
    },
    deep: true
  },
  itemName (n, o) {

  },
  components: {
    dropDownBox
  },
  methods: {
    // 鼠标移入
    mouseOverNewCourseTable (item) {
      if (item.isMenuShow) {
        clearTimeout(this.timer);
        this.doItemClick(item);
        this.itemInit.isMenu = true;
      }
    },
    // 鼠标离开
    mouseOutNewCourseTable (item) {
      if (item.isMenuShow) {
        this.timer = setTimeout(() => {
          this.itemInit.isMenu = false;
        }, 20);
      }
    },
    // 点击
    doItemClick (item) {
      // console.log(item, '点击操作项');
      this.$emit('onItemClick', item);
    },
    doMenuClick (item) {
      // console.log(item, '点击操作项1');
      this.$emit('doMenuClick', item.eventName, this.menuData);
    }
  }
};
</script>

