<template>
  <div class="courseware_right_info"
       :class="this.$store.getters.token.RoleKey != 2?'roleKey_right_info':''">
    <div style="background-color: #fff;min-height: 362px;    border-radius: 8px 0 0 8px;">
      <courseware-teacher-list v-for="(item,index) in teacherList"
                               :key="index"
                               :dataInfo="item"
                               @doOptionClick="doOptionClick"></courseware-teacher-list>
    </div>

    <div class="courseware_content"
         :class="{'teacher_view_width':!vRoleKey}"
         :style="this.$store.getters.token.RoleKey != 2? 'width:auto;':''"
         v-if="teacherList">
      <courseware-details v-if="teacherList[0].isOpt==true&&vRoleKey"></courseware-details>
      <home-page-details v-else
                         :teacherKey="teacherInfo.OLAPKey"
                         @afterSuccess="reflashData"></home-page-details>
    </div>
    <custom-dialog :title="'新增老师'"
                   :visible.sync="showAddForm"
                   :before-close="closeDialog">
      <add-teacher @afterSuccess="updateData"
                   @cancelClick="closeDialog"></add-teacher>
    </custom-dialog>
  </div>
</template> 
<script>
import HomePageDetails from './home-page-details/index';
import coursewareDetails from './courseware-details/index';
import coursewareTeacherList from './courseware-teacher-list/index';
import addTeacher from '../components/form/add-teacher';
import { GetTeacherDataList } from '../../../API/workbench';
export default {
  data () {
    return {
      tcParam: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号
        TeacherType: 1//	整型	必须		数据源：GET	老师状态是否在职 -1-全部；1-是; 0-否
      },
      teacherList: null,
      teacherInfo: { OLAPKey: 0 },
      showAddForm: false
    };
  },
  components: {
    coursewareTeacherList,
    HomePageDetails,
    coursewareDetails,
    addTeacher
  },
  computed: {
    vRoleKey () {
      return this.$store.getters.token ? Number(this.$store.getters.token.RoleKey) == 2 : false; // 2为校长；非则为老师
    }
  },
  created () {
    console.log(this.$store.getters, 'this.$store.getters');
    this.getSearchPageTeacherList();
  },
  mounted () {

  },
  methods: {
    getSearchPageTeacherList () {
      let IPAName = GetTeacherDataList();
      IPAName.then(result => {
        this.teacherList = result.data;
        this.teacherList.forEach(o => { this.$set(o, 'isOpt', false); });
        console.log(result.data, '老师列表1');
        if (result.data.length >= 8) {
          this.teacherList = this.teacherList.slice(0, 8);
          this.teacherList.push({ MainDemoName: '更多', OLAPKey: -2 });
        } else {
          this.teacherList.push({ MainDemoName: '添加老师', OLAPKey: -1 });
        }
        this.teacherList.unshift({ MainDemoName: '全部课件', OLAPKey: 0, isOpt: true });
      }, error => {
        layer.alert(error.msg);
      });
    },
    doOptionClick (data) {
      if (data.OLAPKey == -1) {
        console.log(data, '新增');
        this.showAddForm = true;
        return false;
      }
      if (data.OLAPKey == -2) {
        let obj = {
          name: '全部老师',
          routerName: this.$route.name,
          routertitle: '工作台',
          ModulePowerKey: '',
          moduleName: 'cwsteacherManager',
          callBack: { close: () => { this.updateData(); } }
        };
        this.$bus.emit('openCustomDialog', obj);
        return false;
      }
      this.teacherList.forEach(o => {
        if (o.OLAPKey == data.OLAPKey) {
          o.isOpt = true;
        } else {
          o.isOpt = false;
        }
      });
      this.teacherInfo = data;
      console.log(data, '选择');
    },
    updateData () {
      this.getSearchPageTeacherList();
    },
    closeDialog () {
      this.showAddForm = false;
    },
    reflashData () {
      this.$emit('afterSuccess');
    }
  }
};
</script>

<style>
.courseware_right_info {
  display: flex;
}

.courseware_content {
  width: 100%;
  min-height: 362px;
  border-radius: 0px 8px 8px 8px;
  background: rgb(245, 247, 249);
}
.courseware_content.teacher_view_width {
  border-radius: 8px 8px 8px 8px;
}
.roleKey_right_info {
  background: rgb(245, 247, 249);
  border-radius: 8px;
  box-shadow: 0px 1px 5px rgba(222, 225, 230, 0.6);
  display: flex;
  justify-content: center;
}
</style>
