<template>
  <div class="summarizing_list">
    <table-list
      :tableData="vTableInfoList"
      :tableColumns="tableColumns"
      :totalNum="totalNum"
      :queryParams="params"
      :isShowCheckBox="false"
      @loadTableData="getDataInfo"
    ></table-list>
    <custom-dialog
      :title="'拒绝原因'"
      :visible.sync="isShowMessagePopup"
      :before-close="hideMessagePopup"
    >
      <remerk-popup
        :placeholder="'请输入拒绝原因'"
        @onClickClose="hideMessagePopup"
        @onClickConfirm="rejectRecommend"
      ></remerk-popup>
    </custom-dialog>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
import remerkPopup from '../remerk-popup';
import { HQSearchPageTheBillHasBeenSignedInFull, YKRecommendManageReceive, HQCancellation } from '../../../../API/workbench.js';
export default {
  data () {
    return {
      isShowMessagePopup: false,
      selectInfo: {},
      dataInfoList: [],
      totalNum: 0,
      tableColumns: [

        {
          label: '签单日期',
          prop: 'ApplicantTime',
          width: 100,
          type: 'date-item',
          sortable: 'custom'
        },
        {
          label: '来源方式',
          prop: 'SourceTypeKeyValue',
          width: 88,
          type: 'text-item'
        },
        {
          label: '投资人',
          prop: 'AttractInvestKeyValue',
          type: 'text-item',
          width: 80
        },
        {
          label: '顾问',
          prop: 'CounselorKeyValue',
          type: 'text-item',
          width: 80
        },
        {
          label: '合同金额',
          prop: 'ContractAmount',
          type: 'amount-item',
          width: 80
        },
        {
          label: '加盟方式',
          prop: 'FranchiseTypeKeyValue',
          type: 'text-item',
          width: 80
        },
        {
          label: '合同变更条款',
          prop: 'AmendingClause',
          type: 'text-item',
          width: 280
        },
        {
          label: '定金',
          prop: 'EarnestAmount',
          type: 'amount-item',
          width: 80
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          align: 'center',
          className: 'btn_dual',
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  click: (rowData) => {
                    console.log('rowData', rowData);
                    this.selectInfo = rowData;
                    this.goDetail();
                  }
                }
              }
            ]
          }
        }
      ]
    };
  },
  props: {
    params: Object
  },
  components: {
    tableList, remerkPopup
  },
  computed: {
    vTableInfoList () {
      let arr = [];
      this.dataInfoList.forEach((e) => {
        e.BudgetAmount = Number(e.StratBudgetAmount) + '-' + Number(e.EndBudgetAmount) + '万';
        e.Other = e.SexKeyValue + ' ' + e.Age + '岁';
        arr.push(e);
      });
      return arr;
    }
  },
  created () {
    this.getDataInfo();
  },
  methods: {
     // 导出
    doExportInfoClick (callBack) {
      if (this.dataInfoList.length > 0) {
        var newData = this.$utils.parseJson(this.params);
        this.params.IsExportKey = 1;
        this.params.pageIndex = 0;
        this.params.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '签单已全款',
          clist: [
            { title: '签单日期', cName: 'ApplicantTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '来源方式', cName: 'SourceTypeKeyValue', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '投资人', cName: 'AttractInvestKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '顾问', cName: 'CounselorKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '合同金额', cName: 'ContractAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '加盟方式', cName: 'FranchiseTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '合同变更条款', cName: 'AmendingClause', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '定金', cName: 'EarnestAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        HQSearchPageTheBillHasBeenSignedInFull(this.params, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.params.IsExportKey = 0;
          this.params.pageIndex = newData.pageIndex;
          this.params.pageSize = newData.pageSize;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    openMessagePopup (item) {
      this.selectInfo = item;
      this.isShowMessagePopup = true;
    },
    goDetail () {
      // 排课 按钮
      let dataitem = {
        OLAPKey: this.selectInfo.AttractInvestSignContractKey
        // MainDemoName:this.selectInfo.AttractInvestKeyValue
      };
      this.$dialog.open(this, {
        name: '签单详情',
        routerName: this.$route.name,
        moduleName: 'crmSignContractForm',
        data: { dataInfo: dataitem, isReadonly: true, showBtn: false},
        callBack: { afterSuccess: () => {
          this.getDataInfo();
        } }
      });
    },
    resultRecommend (item) {
      YKRecommendManageReceive(item.OLAPKey).then(res => {
        this.$emit('refreshListInfo');
        this.getDataInfo();
      }, err => {
        layer.alert(err.msg);
      });
    },
    rejectRecommend (text) {
      this.isShowMessagePopup = false;
      HQCancellation(this.selectInfo.OLAPKey, text).then(res => {
        this.$emit('refreshListInfo');
        this.getDataInfo();
      }, err => {
        layer.alert(err.msg);
      });
    },
    hideMessagePopup () {
      this.isShowMessagePopup = false;
    },
    getDataInfo () {
      HQSearchPageTheBillHasBeenSignedInFull(this.params).then(
        (res) => {
          this.totalNum = res.data.Total;
          this.dataInfoList = res.data.PageDataList;
          this.$emit('upDataInfo', res.data);
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    }
  }
};
</script>