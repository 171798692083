
  <template>
  <div>
    <div class="change_phone_number single_result_detail ">
      <!-- 学生个人信息 -->
      <student-lable class="content_marginRL single_shadow"
                     :isExternalAlter="true"
                     :studentID="studentItem.OLAPKey"
                     :mobileSupport="studentItem.MobileSupport"
                     @changeStudent="changeStudent">

      </student-lable>
      <!--  不可编辑界面 form_info_readonly
            可编辑界面 form_info_edit-->
      <div class="form_info_edit content_marginRL form_info_bottom">
        <!-- 内容 -->
        <div class="form_info_edit mendian_setting_content single_shadow">
          <phone-below-student-List :userStudentList="userStudentList"
                                    :phoneNumber="studentItem.MobileSupport"></phone-below-student-List>
          <input-phone :formTitle="'新手机号'"
                       :required="true"
                       :formPlaceholder="'必填'"
                       :readonly='false'
                       v-model="mobile"></input-phone>

          <div>
            <input-text :formTitle="'处理原因'"
                        :readonly="false"
                        :formPlaceholder="'选填'"
                        :required="false"
                        v-model="Remarks"></input-text>

          </div>
        </div>
      </div>
      <!-- 按钮组 -->
      <div>
        <save-cancel-btn-group :isSingleSubmit="true"
                               :singleSubmitType="'dynamic'"
                               :btnText="'提交'"
                               @confirmClick="buttonClickEvent"
                               @cancelClick="cancelChgMobile"></save-cancel-btn-group>
      </div>
    </div>
  </div>
</template>

  <script>
// 选择框 作为公共组件
import { UpdateCustomerTel, GetStudentByMobile } from '../../../../API/workbench';
import phoneBelowStudentList from './phone-below-student-List';
import studentLable from '../../../common/student-lable';
export default {
  name: 'searchBox',
  props: ['studentItem'], // 需绑定到上面
  data () {
    return {
      isShow: true,
      mobile: '', // 手机号码
      Remarks: '', // 处理原因
      userStudentList: [] // 用户信息
    };
  },
  components: {
    studentLable,
    phoneBelowStudentList
  },
  created () {
    this.getStudentByMobileSupportList();
    this.mobile = this.studentItem.MobileSupport;
  },
  watch: {
    'studentItem.MobileSupport': {
      handler (newName, oldName) {
        if (newName) {
          this.mobile = newName;
          this.studentItem.MobileSupport = this.mobile;
        }
        console.log(newName, '号码ID111');
      },
      deep: true
    }
  },
  methods: {
    // 获取该号码下的所有学生
    getStudentByMobileSupportList () {
      if (this.studentItem.MobileSupport) {
        GetStudentByMobile(this.studentItem.MobileSupport).then(result => {
          console.log(result.data, '获取该号码下的所有学生');
          this.userStudentList = [];
          result.data.forEach(o => {
            if (o.OLAPKey != this.studentItem.OLAPKey) {
              this.userStudentList.push(o);
            }
          });
        }, errer => {
          layer.alert(errer.msg);
        });
      }
    },
    // 确定按钮
    buttonClickEvent (callBack) {
      let flag = false;
      if (this.mobile && this.mobile == this.studentItem.MobileSupport) {
        layer.alert('该手机号已存在');
        flag = true;
      } else if (this.mobile.length != 11) {             // 匹配手机号.!(/^1\d{10}$/.test(MobileSupport)
        layer.alert('请输入11位的手机号码');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      GetStudentByMobile(this.mobile).then(result => {
        console.log(result.data, '获取该号码下的所有学生2');
        if (result.data.length > 0) {
          this.$emit('studentPopups', this.mobile, result.data, this.Remarks);
          if (callBack) {
            callBack();
          }
        } else {
          UpdateCustomerTel(this.studentItem.CustKey, this.mobile, this.studentItem.OLAPKey, this.Remarks).then(result => {
            this.$emit('buttonClickEvent', this.mobile);
            layer.alert('修改成功');
          }, errer => {
            layer.alert(errer.msg);
          }).finally(() => {
            if (callBack) {
              callBack();
            }
          });
        }
      }, errer => {
        layer.alert(errer.msg);
      });
    },
    // 取消按钮
    cancelChgMobile () {
      this.Remarks = '';
      this.mobile = this.studentItem.MobileSupport;
      this.$emit('cancelChgMobile');
    },
    changeStudent (item) {
      this.$emit('changeStudent');
    },
    showStudentFile () {
      this.$emit('showStudentFile');
    }
  }
};
</script>


