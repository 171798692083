<template>
  <div class="sign_popup_list" @click.stop="clickCourseItem" :class="courseItem.isActive?'opt':''">
    <div class="list_course_info" style="padding-left: 40px;">
      <div class="list_course_name">{{courseItem.CourseNameKeyValue}}</div>
      <div class="list_course_date">
        {{setClassTime(courseItem)}}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: ['courseItem'],
  computed: {
  },
  methods: {
     // 设置开始结束时间.
    setClassTime (item) {
      return (
      item.BeginClassTime.substring(10).substring(0, 6) +
      ' -' +
      item.EndClassTime.substring(10).substring(0, 6)
      );
    },
    clickCourseItem () {
      this.$emit('clickCourseItem', this.courseItem);
    }
  }
};
</script>

