<template>
  <div class="growth_time_list_swiper"
       v-if="headImg">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img :src="headImg"
               alt="">
        </div>
        <!-- <swiper-img-item v-for="(item,key) in dataInfo.Comment.attachList"
                         :key="key"
                         :item="item"></swiper-img-item> -->
      </div>
      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev"
           v-if="dataInfo.Comment.attachList.length>1"
           @click.stop="preClick"></div>
      <div class="swiper-button-next"
           v-if="dataInfo.Comment.attachList.length>1"
           @click.stop="afterClick"></div>
    </div>
  </div>
</template>
<script>
import { SetDefalutFirst } from '../../../../API/workbench';
import swiperImgItem from './swiper-img-item';
export default {
  data () {
    return {
      seletedImg: ''
    };
  },
  props: {
    dataInfo: Object,
    studentInfo: Object
  },
  created () {
    console.log(this.dataInfo, 'created');
    if (this.dataInfo && this.dataInfo.Comment && this.dataInfo.Comment.attachList.length > 0) {
      this.seletedImg = this.dataInfo.Comment.attachList[0].AttachUrl;
    }
  },
  components: {
    swiperImgItem
  },
  computed: {
    headImg () {
      if (!this.seletedImg) {
        this.seletedImg = '';
      }
      if (this.seletedImg.indexOf('http') > -1) {
        return this.seletedImg;
      } else {
        return this.$store.getters.CDNURL + this.seletedImg;// this.item.AttachUrl;
      }
    }
  },
  mounted () {

  },
  methods: {
    preClick () {
      this.findItem((locaIndex) => {
        console.log(locaIndex);
        let index = locaIndex;
        if (locaIndex == 0) {
          index = 0;
          this.seletedImg = this.dataInfo.Comment.attachList[this.dataInfo.Comment.attachList.length - 1].AttachUrl;
        } else {
          index = locaIndex + 1;
          this.seletedImg = this.dataInfo.Comment.attachList[locaIndex - 1].AttachUrl;
        }
        this.setPicFirst(index);
      });
    },
    afterClick () {
      this.findItem((locaIndex) => {
        console.log(locaIndex);
        let index = locaIndex;
        if (locaIndex == this.dataInfo.Comment.attachList.length - 1) {
          index = 0;
          this.seletedImg = this.dataInfo.Comment.attachList[0].AttachUrl;
        } else {
          index = locaIndex + 1;
          this.seletedImg = this.dataInfo.Comment.attachList[locaIndex + 1].AttachUrl;
        }
        this.setPicFirst(index);
      });
    },
    findItem (callback) {
      let locaIndex = this.dataInfo.Comment.attachList.findIndex(o => {
        return o.AttachUrl == this.seletedImg;
      });
      if (locaIndex != -1) {
        callback(locaIndex);
      }
    },
    setPicFirst (index) {
      SetDefalutFirst(this.dataInfo.Comment.attachList[index].ID, this.dataInfo.Comment.ID, this.studentInfo.OLAPKey).then(result => {
        console.log('SetDefalutFirst', result.data);
      }).catch(error => {
        console.log('errSetDefalutFirst', error);
      });
    }

  }
};
</script>