<template>
  <!-- 授权课件表单 -->
  <div style="height: 100%"
       class="sqkj_from">
    <div class="form_title_info">设备控制</div>
    <div class="form_info_edit form_info_list">
      <input-dialog :formTitle="'PC端'"
                    :formPlaceholder="''"
                    :required="true"
                    :readonly="isReadonly"
                    v-model="vPCLocation"
                    @showEldilog="setLocation('PC')"></input-dialog>
    </div>
    <div class="form_info_edit form_info_list">
      <input-dialog :formTitle="'APP端'"
                    :formPlaceholder="''"
                    :required="true"
                    :readonly="isReadonly"
                    v-model="vAPPLocation"
                    @showEldilog="setLocation('APP')"></input-dialog>
    </div>
    <div class="form_info_edit form_info_list">
      <input-dialog :formTitle="'TV端'"
                    :formPlaceholder="''"
                    :required="true"
                    :readonly="isReadonly"
                    v-model="vTVLocation"
                    @showEldilog="setLocation('TV')"></input-dialog>
    </div>
    <div class="form_title_info">校区定位</div>
    <div class="form_info_edit form_info_list">
      <input-select :formTitle="'校区变更定位后'"
                    :id="'id'"
                    :name="'name'"
                    :required="false"
                    :readonly="isReadonly"
                    :dataList="schoollLocktionList"
                    :selectedInfo="schoollMSGInfo"
                    @selectedItem="chgschoollMSG"></input-select>
    </div>
    <div class="form_title_info">加密授权</div>
    <div class="form_info_edit form_info_list">
      <!-- <div>
        <input-switch v-model="isComputed"
                      :formTitle="'电脑设备'"
                      :showInputValue="false"
                      :className="'no_border'"
                      :required="false"></input-switch>
        <div class="form_dual_text">非授权电脑设备，无法打开</div>
      </div>
      <div>
        <input-switch v-model="isLocation"
                      :formTitle="'定位锁定'"
                      :showInputValue="false"
                      :className="'no_border'"
                      :required="false"></input-switch>
        <div class="form_dual_text">非授权位置坐标，移动设备无法打开</div>
      </div> -->
      <div>
        <div class="no_border form_info_line el_switch_form_info_field">
          <div class=" form_info_field">
            <span>老师授权</span>
          </div>

          <div class="form_info_field"
               style="width: 100%;text-align: right;">
            <span>前后 <input type="number"
                     style="width:24px;color:#3498db"
                     v-model="classDay" /> <span>天</span></span>
          </div>

        </div>
        <!-- <input-switch v-model="isTeacherAuth"
                      :formTitle="'老师授权'"
                      :showInputValue="false"
                      :className="'no_border'"
                      :required="false"></input-switch> -->
        <div class="flex"
             style="border-bottom: 1px solid #ececec;
    padding-bottom: 15px;color:#999">
          <div class="">非授权人员和时间范围，无法打开</div>

        </div>
      </div>
    </div>
    <div class="form_title_info">防复制下载</div>
    <div class="form_info_edit form_info_list">
      <!-- <form-input v-model="enableValue"
                  :formTitle="'禁止复制课件的文本内容'"
                  :showInputValue="false"
                  :className="'no_border'"
                  :readonly="true"
                  :required="false"></form-input>
      <form-input v-model="enableValue"
                  :formTitle="'禁止复制课件的文本内容'"
                  :showInputValue="false"
                  :className="'no_border'"
                  :readonly="true"
                  :required="false"></form-input> -->
      <div class="form_item form-input form_info_line"
           classname="no_border">
        <div class="form_info_field">
          <span>禁止复制课件的文本内容</span>
        </div>
        <div class="form_info_value">
          <span class="font_blue">已开启</span>
        </div>
      </div>
      <div class="form_item form-input form_info_line"
           classname="no_border">
        <div class="form_info_field">
          <span>禁止下载视频|图片|音频|附件</span>
        </div>
        <div class="form_info_value">
          <span class="font_blue">已开启</span>
        </div>
      </div>
    </div>
    <div class="form_title_info">ios录屏截屏监控</div>
    <div class="form_info_edit form_info_list">
      <input-select :formTitle="'达到3次，自动暂停校区课件授权'"
                    :id="'id'"
                    :name="'name'"
                    :required="false"
                    :readonly="isReadonly"
                    :dataList="iosScreenList"
                    :selectedInfo="iosScreenInfo"
                    @selectedItem="chgIosScreen"></input-select>
    </div>
    <div class="form_title_info">防盗版水印</div>
    <div class="form_info_edit form_info_list">
      <div>
        <input-switch v-model="isBrandWatermark"
                      :formTitle="'品牌水印'"
                      :showInputValue="false"
                      :className="''"
                      :required="false"></input-switch>
        <div class="form_info_line el_switch_form_info_field"
             v-if="isBrandWatermark == 1">
          <div class=" form_info_field">
            <span>品牌名称</span>
          </div>

          <div class="form_info_field"
               style="width: 100%;text-align: right;">
            <input type="text"
                   style="width: 100%;
    color: rgb(52, 152, 219);
    text-align: right;"
                   v-model="brandWatermarkContent" />
          </div>

        </div>
        <!-- <input v-if="isBrandWatermark == 1"
               type="text"
               class="courseware_input"
               v-model="brandWatermarkContent" /> -->
      </div>
      <div>
        <input-switch v-model="isMendianWatermark"
                      :formTitle="'门店水印'"
                      :showInputValue="false"
                      :className="'no_border'"
                      :required="false"></input-switch>
        <div class="form_dual_text">拍照、录像可追溯门店</div>
      </div>
      <div>
        <input-switch v-model="isTeacherWatermark"
                      :formTitle="'老师水印'"
                      :showInputValue="false"
                      :className="'no_border'"
                      :required="false"></input-switch>
        <div class="form_dual_text"
             style="border-bottom: 1px solid #ececec; padding-bottom: 15px">
          拍照、录像可追溯责任人
        </div>
      </div>
      <div>
        <input-switch v-model="isActiveWatermark"
                      :formTitle="'幽灵水印'"
                      :showInputValue="false"
                      :className="'no_border'"
                      :required="false"></input-switch>
        <div class="form_dual_text"
             style="border-bottom: 1px solid #ececec; padding-bottom: 15px">
          随机动态显示老师水印，加强防盗性能
        </div>
      </div>
    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import {
  GetSaaSClientSet,
  ModifySaaSClientSetting
} from '../../../API/workbench';
export default {
  data () {
    return {
      // 直营类型
      sourceType: [
        { type: 1, name: '课次授权' },
        { type: 2, name: '按年授权( 天 )' },
        { type: 3, name: '永久授权' }
      ],
      locationInfo: [], // 地址
      menDianSetInfo: [],
      isActiveWatermark:0,//幽灵水印 7
      isTeacherWatermark: 0, // 老师水印 8
      isMendianWatermark: 0, // 门店水印 9
      isBrandWatermark: 0, // 品牌水印10
      isTeacherAuth: 0, // 老师授权 13
      isLocation: 0, // 定位锁定 11
      isComputed: 0, // 电脑设备 12
      enableValue: '已开启',
      brandWatermarkContent: '',
      classDay: '',
      dataInfo: {
        IsAPPAuthKey: 1, // 是否开启APP授权
        IsPCAuthKey: 1,    // 是否开启PC授权
        PCIsLocationLockKey: 0,  // 是否开启PC位置
        AuthorizeDeviceNum: 0,   // PC设备数量
        APPAuthorizeDeviceNum: 0, // APP设备数量
        IsLocationLockKey: 1,   // APP开启PC位置
        TVIsLocationLockKey: 1,    // TV开启PC位置
        IsTVAuthKey: 1,     // 是否开启TV授权
        TVAuthorizeDeviceNum: 0,  // TV设备数量
        schoolMsg: 0,  // TV设备数量
        IsAPPRecordKey:1,
        IsPCRecordKey:1,
        IsTVRecordKey:1,
        PCPositionalAccuracyKey:1,
        APPPositionalAccuracyKey:1,
        TVPositionalAccuracyKey:1,
        iosScreen:1,
         IsPCGatewayLogin:0,
        IsTVGatewayLogin:0

      },
      schoollLocktionList: [
        {
          id: 0,
          name: '仅通知'
        },
        {
          id: 1,
          name: '需审核'
        }
      ],
      iosScreenList: [
        {
          id: 0,
          name: '关闭'
        },
        {
          id: 1,
          name: '每月'
        },
         {
          id: 2,
          name: '每周'
        },
        {
          id: 3,
          name: '每天'
        }
      ]
    };
  },
  components: {},
  props: {
    coursewareId: Number,
    isReadonly: Boolean
  },
  created () {
    this.classDay = Number(this.vMendianInfo.TimeScopeCount);
    this.getMenDianSet();
  },
  mounted () { },
  computed: {
    vMendianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vPCLocation () {
      let str = '';
      if (Number(this.dataInfo.IsPCAuthKey) == 0) {
        str = '禁用';
        return str;
      } else {
        if (this.dataInfo.AuthorizeDeviceNum == '') {
          str = '设备：未设置   ';
        } else if (this.dataInfo.AuthorizeDeviceNum == '0') {
          str = '设备：不限   ';
        } else {
          str = '设备：' + this.dataInfo.AuthorizeDeviceNum + '   ';
        }
      }
      str += this.dataInfo.PCIsLocationLockKey == 0 ? '定位：不限' : '定位：开启';
      if(this.dataInfo.PCIsLocationLockKey == 1){
        str+=this.dataInfo.PCPositionalAccuracyKey == 1 ? ' 高精度':' 超高精度'
      }
      str += this.dataInfo.IsPCRecordKey == 1 ? '  防录屏':'  不防录屏'
      return str;
    },
    vAPPLocation () {
      let str = '';
      if (Number(this.dataInfo.IsAPPAuthKey) == 0) {
        str = '禁用';
        return str;
      } else {
        if (this.dataInfo.APPAuthorizeDeviceNum == '') {
          str = '设备：未设置   ';
        } else if (Number(this.dataInfo.APPAuthorizeDeviceNum) == 0) {
          str = '设备：不限   ';
        } else {
          str = '设备：' + this.dataInfo.APPAuthorizeDeviceNum + '   ';
        }
      }
      str += this.dataInfo.IsLocationLockKey == 0 ? '定位：不限' : '定位：开启';
      if(this.dataInfo.IsLocationLockKey == 1){
        str+=this.dataInfo.APPPositionalAccuracyKey == 1 ? ' 高精度':' 超高精度'
      }
      str += this.dataInfo.IsAPPRecordKey == 1 ? '  防录屏':'  不防录屏'
      return str;
    },
    vTVLocation () {
      let str = '';
      if (Number(this.dataInfo.IsTVAuthKey) == 0) {
        str = '禁用';
        return str;
      } else {
        if (this.dataInfo.TVAuthorizeDeviceNum == '') {
          str = '设备：未设置   ';
        } else if (Number(this.dataInfo.TVAuthorizeDeviceNum) == 0) {
          str = '设备：不限   ';
        } else {
          str = '设备：' + this.dataInfo.TVAuthorizeDeviceNum + '   ';
        }
      }
      str += this.dataInfo.TVIsLocationLockKey == 0 ? '定位：不限' : '定位：开启';
      if(this.dataInfo.TVIsLocationLockKey == 1){
        str+=this.dataInfo.TVPositionalAccuracyKey == 1 ? ' 高精度':' 超高精度'
      }
      str += this.dataInfo.IsTVRecordKey == 1 ? '  防录屏':'  不防录屏'
      return str;
    },
     // 教室
    // 教室
    schoollMSGInfo () {
      let value = this.dataInfo.schoolMsg == 1 ? '需审核' : '仅通知';
      return {
        id: this.dataInfo.schoolMsg,
        name: value
      };
    },
    iosScreenInfo () {
      let value = this.dataInfo.iosScreen == 0 ? '关闭' : this.dataInfo.iosScreen == 1?'每月':this.dataInfo.iosScreen == 2?'每周':'每天';
      return {
        id: this.dataInfo.iosScreen,
        name: value
      };
    }
  },
  methods: {
    chgschoollMSG (item) {
      console.log('chgschoollMSG', item);
      this.dataInfo.schoolMsg = item.id;
      this.dataInfo.schoolMsgValue = item.name;
    },
    chgIosScreen (item) {
      console.log('chgschoollMSG', item);
      this.dataInfo.iosScreen = item.id;
      this.dataInfo.iosScreenValue = item.name;
    },
    // 获取门店设置
    getMenDianSet () {
      GetSaaSClientSet().then((result) => {
        console.log('GetSaaSClientSet', result.data);
        this.menDianSetInfo = result.data;
        // 幽灵水印 97
        this.isActiveWatermark = this.initData(97, this.isActiveWatermark);
        // 老师水印 8
        this.isTeacherWatermark = this.initData(8, this.isTeacherWatermark);
        // 门店水印 9
        this.isMendianWatermark = this.initData(9, this.isMendianWatermark);
        // 品牌水印 10
        this.isBrandWatermark = this.initData(10, this.isBrandWatermark);
        // 老师授权
        this.isTeacherAuth = this.initData(13, this.isTeacherAuth);
        // 定位锁定
        this.isLocation = this.initData(11, this.isLocation);
        // 电脑设备
        this.isComputed = this.$utils.getSaaSClientSetInfo(18).SetContent;

        this.dataInfo.IsPCAuthKey = this.$utils.getSaaSClientSetInfo(76).SetContent;   // 是否开启PC授权
        this.dataInfo.IsAPPAuthKey = this.$utils.getSaaSClientSetInfo(77).SetContent; // 是否开启APP授权
        this.dataInfo.IsTVAuthKey = this.$utils.getSaaSClientSetInfo(78).SetContent;     // 是否开启TV授权
        this.dataInfo.AuthorizeDeviceNum = this.$utils.getSaaSClientSetInfo(79).SetContent;  // PC设备数量
        this.dataInfo.APPAuthorizeDeviceNum = this.$utils.getSaaSClientSetInfo(80).SetContent; // APP设备数量
        this.dataInfo.TVAuthorizeDeviceNum = this.$utils.getSaaSClientSetInfo(81).SetContent; // TV设备数量
        this.dataInfo.PCIsLocationLockKey = this.$utils.getSaaSClientSetInfo(82).SetContent;// PC开启位置
        this.dataInfo.IsLocationLockKey = this.$utils.getSaaSClientSetInfo(83).SetContent;  // APP开启位置
        this.dataInfo.TVIsLocationLockKey = this.$utils.getSaaSClientSetInfo(84).SetContent;   // TV开启PC位置
        this.dataInfo.schoolMsg = this.$utils.getSaaSClientSetInfo(87).SetKey;   // TV开启PC位置
        this.dataInfo.IsPCRecordKey = this.$utils.getSaaSClientSetInfo(88).SetKey;
        this.dataInfo.IsAPPRecordKey = this.$utils.getSaaSClientSetInfo(89).SetKey;
        this.dataInfo.IsTVRecordKey = this.$utils.getSaaSClientSetInfo(90).SetKey;
        this.dataInfo.PCPositionalAccuracyKey=this.$utils.getSaaSClientSetInfo(91).SetKey;
        this.dataInfo.APPPositionalAccuracyKey=this.$utils.getSaaSClientSetInfo(92).SetKey;
        this.dataInfo.TVPositionalAccuracyKey=this.$utils.getSaaSClientSetInfo(93).SetKey;
        this.dataInfo.iosScreen = this.$utils.getSaaSClientSetInfo(95).SetKey;  
         this.dataInfo.IsPCGatewayLogin = this.$utils.getSaaSClientSetInfo(98).SetKey;
      this.dataInfo.IsTVGatewayLogin = this.$utils.getSaaSClientSetInfo(99).SetKey;
      });
    },
    initData (type, value) {
      let locaIndex = this.menDianSetInfo.findIndex((o) => {
        return o.SetTypeKey == type;
      });
      if (locaIndex >= 0) {
        if (type == 10) {
          this.brandWatermarkContent = this.menDianSetInfo[
            locaIndex
          ].SetContent;
        } else if (type == 13) {
          this.classDay = this.menDianSetInfo[
            locaIndex
          ].SetContent || Number(this.vMendianInfo.TimeScopeCount);
        }
        return Number(this.menDianSetInfo[locaIndex].SetKey) || 0;
      }
    },
    setData (type, value) {
      let locaIndex = this.menDianSetInfo.findIndex((o) => {
        return o.SetTypeKey == type;
      });
      if (locaIndex) {
        this.menDianSetInfo[locaIndex].SetKeyValue = value == 1 ? '是' : '否';
        this.menDianSetInfo[locaIndex].SetKey = value;
        if (type == 10) {
          this.menDianSetInfo[
            locaIndex
          ].SetContent = this.brandWatermarkContent;
        }
        if (type == 13) {
          this.menDianSetInfo[
            locaIndex
          ].SetContent = this.classDay;
        }
        if (type == 95) {
          this.menDianSetInfo[
            locaIndex
          ].SetContent = this.iosScreenInfo.name;
        }
      }
      this.$store.getters.SaaSClientInfo.SaaSClientSetting = this.menDianSetInfo;
    },
    setDeviceData (type, value) {
      let locaIndex = this.menDianSetInfo.findIndex((o) => {
        return o.SetTypeKey == type;
      });
      if (locaIndex) {
        this.menDianSetInfo[locaIndex].SetContent = value;
      }
      this.$store.getters.SaaSClientInfo.SaaSClientSetting = this.menDianSetInfo;
    },
    // 找到匹配项加到数组中.
    findItemPushArray (type, newArr) {
      let locaIndex = this.menDianSetInfo.findIndex((o) => {
        return o.SetTypeKey == type;
      });
      if (locaIndex >= 0) {
        newArr.push(this.menDianSetInfo[locaIndex]);
      }
    },
    // 提交
    submit (callback) {
      console.log('submit', this.menDianSetInfo);
      // 老师水印 8
      this.setData(8, this.isTeacherWatermark);
      // 门店水印 9
      this.setData(9, this.isMendianWatermark);
      // 品牌水印 10
      this.setData(10, this.isBrandWatermark);
      // 老师授权
      this.setData(13, this.isTeacherAuth);
      // 定位锁定
      this.setData(11, this.isLocation);
      // 电脑设备
      this.setData(12, this.isComputed);
      // 幽灵水印
      this.setData(97, this.isActiveWatermark);
      // 设备信息
      this.setData(87, this.dataInfo.schoolMsg);
      this.setDeviceData(76, this.dataInfo.IsPCAuthKey);
      this.setDeviceData(77, this.dataInfo.IsAPPAuthKey);
      this.setDeviceData(78, this.dataInfo.IsTVAuthKey);
      this.setDeviceData(79, this.dataInfo.AuthorizeDeviceNum);
      this.setDeviceData(80, this.dataInfo.APPAuthorizeDeviceNum);
      this.setDeviceData(81, this.dataInfo.TVAuthorizeDeviceNum);
      this.setDeviceData(82, this.dataInfo.PCIsLocationLockKey);
      this.setDeviceData(83, this.dataInfo.IsLocationLockKey);
      this.setDeviceData(84, this.dataInfo.TVIsLocationLockKey);
      this.setData(88, this.dataInfo.IsPCRecordKey);
      this.setData(89, this.dataInfo.IsAPPRecordKey);
      this.setData(90, this.dataInfo.IsTVRecordKey);
       this.setData(91, this.dataInfo.PCPositionalAccuracyKey);
        this.setData(92, this.dataInfo.APPPositionalAccuracyKey);
         this.setData(93, this.dataInfo.TVPositionalAccuracyKey);
          this.setData(95, this.dataInfo.iosScreen);
          this.setData(98, this.dataInfo.IsPCGatewayLogin);
          this.setData(99, this.dataInfo.IsTVGatewayLogin);
      console.log('submit', this.menDianSetInfo);
      let newObj = [];
      this.findItemPushArray(8, newObj);
      this.findItemPushArray(9, newObj);
      this.findItemPushArray(10, newObj);
      this.findItemPushArray(13, newObj);
      this.findItemPushArray(11, newObj);
      this.findItemPushArray(12, newObj);
      this.findItemPushArray(87, newObj);
      //   // 设备信息
      this.findItemPushArray(76, newObj);
      this.findItemPushArray(77, newObj);
      this.findItemPushArray(78, newObj);
      this.findItemPushArray(79, newObj);
      this.findItemPushArray(80, newObj);
      this.findItemPushArray(81, newObj);
      this.findItemPushArray(82, newObj);
      this.findItemPushArray(83, newObj);
      this.findItemPushArray(84, newObj);
      this.findItemPushArray(88, newObj);
      this.findItemPushArray(89, newObj);
      this.findItemPushArray(90, newObj);
      this.findItemPushArray(91, newObj);
      this.findItemPushArray(92, newObj);
      this.findItemPushArray(93, newObj);
      this.findItemPushArray(95, newObj);
      this.findItemPushArray(97, newObj);
	  this.findItemPushArray(98, newObj);
      this.findItemPushArray(99, newObj);
      console.log(newObj, 'newObj');
      // 续费
      ModifySaaSClientSetting(newObj)
        .then(
          (result) => {
            layer.alert('修改成功');
            this.$emit('afterSuccess');
            this.cancel();
          },
          (error) => {
            layer.alert(error.msg);
          }
        )
        .finally(() => {
          if (callback) {
            callback();
          }
        });
    },
    cancel () {
      this.$emit('closeDialog');
    },
    // 设置定位
    setLocation (type) {
      let title = 'PC端';
      let obj = {};
      if (type == 'PC') {
        title = 'PC端';
        obj = {
          IsAuthKey: this.dataInfo.IsPCAuthKey,
          IsLocationLockKey: this.dataInfo.PCIsLocationLockKey,
          IsLocationLockKeyValue: this.dataInfo.PCIsLocationLockKey ? '开启' : '不限',
          IsLocationAccuracyKey: this.dataInfo.PCPositionalAccuracyKey,
          AuthorizeDeviceNum: this.dataInfo.AuthorizeDeviceNum,
          IsRecordKeyValue: this.dataInfo.IsPCRecordKey == 1 ? '是' : '否',
          IsRecordKey: this.dataInfo.IsPCRecordKey,
          AuthorizeDeviceType: 1,
           IsGatewayLogin:this.dataInfo.IsPCGatewayLogin||0,
          IsGatewayLoginValue:this.dataInfo.IsPCGatewayLogin== 1 ? '是' : '否',
        };
      } else if (type == 'APP') {
        title = 'APP端';
        obj = {
          IsAuthKey: this.dataInfo.IsAPPAuthKey,
          IsLocationLockKey: this.dataInfo.IsLocationLockKey,
          IsLocationLockKeyValue: this.dataInfo.IsLocationLockKey ? '开启' : '不限',
          IsLocationAccuracyKey: this.dataInfo.APPPositionalAccuracyKey,
          AuthorizeDeviceNum: this.dataInfo.APPAuthorizeDeviceNum,
          IsRecordKeyValue: this.dataInfo.IsAPPRecordKey == 1 ? '是' : '否',
          IsRecordKey: this.dataInfo.IsAPPRecordKey,
           AuthorizeDeviceType: 2
        };
      } else {
        title = 'TV端';
        obj = {
          IsAuthKey: this.dataInfo.IsTVAuthKey,
          IsLocationLockKey: this.dataInfo.TVIsLocationLockKey,
          IsLocationLockKeyValue: this.dataInfo.TVIsLocationLockKey ? '开启' : '不限',
          IsLocationAccuracyKey: this.dataInfo.TVPositionalAccuracyKey,
          AuthorizeDeviceNum: this.dataInfo.TVAuthorizeDeviceNum,
          IsRecordKeyValue: '是',
          IsRecordKey: 1,
          AuthorizeDeviceType: 3,
           IsGatewayLogin:this.dataInfo.IsTVGatewayLogin||0,
           IsGatewayLoginValue:this.dataInfo.IsTVGatewayLogin== 1 ? '是' : '否',
        };
      }
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: title,
        moduleName: 'locationForm',
        data: { isReadonly: false, dataList: obj },
        callBack: {
          afterSuccess: (data) => {
            console.log(data, type, '选择确定定位');
            if (type == 'PC') {
              this.dataInfo.IsPCAuthKey = data.IsAuthKey;
              this.dataInfo.PCIsLocationLockKey = data.IsLocationLockKey;
              this.dataInfo.AuthorizeDeviceNum = data.AuthorizeDeviceNum;
              this.dataInfo.IsPCRecordKey = data.IsRecordKey;
              this.dataInfo.PCPositionalAccuracyKey=data.IsLocationAccuracyKey,
               this.dataInfo.IsPCGatewayLogin=data.IsGatewayLogin
            } else if (type == 'APP') {
              this.dataInfo.IsAPPAuthKey = data.IsAuthKey;
              this.dataInfo.IsLocationLockKey = data.IsLocationLockKey;
              this.dataInfo.APPAuthorizeDeviceNum = data.AuthorizeDeviceNum;
              this.dataInfo.IsAPPRecordKey = data.IsRecordKey;
                this.dataInfo.APPPositionalAccuracyKey=data.IsLocationAccuracyKey
            } else {
              this.dataInfo.IsTVAuthKey = data.IsAuthKey;
              this.dataInfo.TVIsLocationLockKey = data.IsLocationLockKey;
              this.dataInfo.TVAuthorizeDeviceNum = data.AuthorizeDeviceNum;
              this.dataInfo.IsTVRecordKey = data.IsRecordKey;
              this.dataInfo.TVPositionalAccuracyKey=data.IsLocationAccuracyKey,
               this.dataInfo.IsTVGatewayLogin=data.IsGatewayLogin
            }
          }
        }
      });
    }
  }
};
</script>

