<template>
  <div class="margin_left_right_div">
    <div class="course-report template_minOrmax_width_div"
         style="margin:20px auto;">
      <div class="payment_statistics_bottom clearfix">
        <div class="course_statistics_table_search flex">
          <date-range ref="saveDate"
                      :searchObj="searchObj"
                      :fromTitle="''"
                      @changeDate="changeSaveDate"
                      style="margin-right:10px"></date-range>
          <div class="table_select_box"
               style="margin-right:10px">
            <div class="table_select_title">操作类型</div>
            <el-select :value="vOperatorTypekeyValue"
                       style="width:175px"
                       placeholder="请选择"
                       @change="typeEvent">
              <el-option v-for="item in vOptionList"
                         :key="item.value"
                         :label="item.label"
                         :value="item">
              </el-option>
            </el-select>
          </div>

          <div class="table_select_box"
               style="margin-right:10px">
            <div class="table_select_title">操作设备</div>
            <el-select :value="vOperatorEquipmentKey"
                       style="width:175px"
                       placeholder="请选择"
                       @change="EquipmentEvent">
              <el-option v-for="item in EquipmentOption"
                         :key="item.value"
                         :label="item.label"
                         :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="width_small course_statistics_search">
            <input-search-contain-btn v-model="searchObj.searchText"
                                      :placeholder="'请输入操作人姓名'"
                                      :clearAll="true"
                                      @clearSearch="clearSearchCondition"
                                      @onSearchEvent="search"></input-search-contain-btn>

          </div>

        </div>
      </div>

      <div class="course_statistics_table_box table_list_content">
        <table-list ref='multipleTable'
                    class="summarizing_list"
                    :key="tableKey"
                    :tableData="vdataReportList"
                    :totalNum="totalNum"
                    :PageCount="PageCount"
                    :tableColumns="tableCourseColumns"
                    :isSearchText="false"
                    :isNeedInnerScroll="false"
                    :defaultSort="defaultSort"
                    :queryParams="searchObj"
                    :footerContent="vFooterContent"
                    @loadTableData="getDataList"></table-list>
      </div>
      <div>
        <dialog-factory ref="operationLogReportDialogFactory"
                        :key="'operationLogReport'"
                        :routerName="'operationLogReport'"></dialog-factory>

        <dialog-factory-common ref="operationLogReportCommonDialogFactory"></dialog-factory-common>
      </div>

    </div>
  </div>
</template>
<script>
import dialogFactory from '../../common/dialog-factory';
import tableList from '../../common/table-list/index';
// import seletedOption from './seleted-option';
import inputSearchContainBtn from '../common/input-search-contain-btn';
import { MessageSearchPageClientActionLog, GetAgentForSaaSClient } from '../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 15,
        searchText: '',  // 搜索条件：模糊匹配单号、学生姓名、手机号
        orderBy: '',
        OperatorTypekey: '', // 操作类型Key 1-用户登录; 2-学生离校-手动; 3-删除学生; 4-修改学生名; 5-新建循环课/日历建课; 6-学生退课; 7-修改课表; 8-停单节课; 9-结束循环课; 10-修改门店设置; 11-未考勤-清除排课; 12-一键清除未考勤; 13-导出记录; 14-变更线索归属; 15-角色权限管理-操作日志; 16-修改员工手机号/名字/权限; 17-停用老师; 18-结束班级; 19-停用课名;
        OperatorTypekeyValue: '',
        OperatorEquipmentKey: '', // 操作设备  1-PC,2-WXB,3-APP,4-MP,5-桌面端,
        OperatorEquipmentKeyValue: '',
        sequence: 'DESC',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0))
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      staffList: [],
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 66,
          sortable: false,
          align: 'left',
          type: 'number-item',
          className: 'table_index_gray'
        },

        {
          label: '操作日期',
          prop: 'GenerateTime',
          minWidth: 120,
          sortable: false,
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'text-item'
        },
        {
          label: '操作人',
          prop: 'OperateKeyValue',
          minWidth: 120,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },

        {
          label: '操作类型',
          prop: 'OperateTypeKeyValue',
          width: 240,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '操作内容',
          prop: 'Content',
          minWidth: 220,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '操作设备',
          prop: 'DeviceKeyValue',
          sortable: false,
          minWidth: 120,
          align: 'left',
          isShow: true,
          type: 'text-item'

        }

      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false,
      allNum: {},
      WEBVOptionList: [{
        value: '0',
        label: '全部'
      }, {
        value: '1',
        label: '用户登录'
      }, {
        value: '2',
        label: '学生离校-手动'
      }, {
        value: '3',
        label: '删除学生'
      }, {
        value: '4',
        label: '修改学生名'
      }, {
        value: '5',
        label: '新建循环课/日历建课'
      }, {
        value: '6',
        label: '学生退课'
      }, {
        value: '7',
        label: '修改课表'
      }, {
        value: '8',
        label: '停单节课'
      }, {
        value: '9',
        label: '结束循环课'
      }, {
        value: '10',
        label: '修改门店设置'
      }, {
        value: '11',
        label: '未考勤-清除排课'
      }, {
        value: '12',
        label: '一键清除未考勤'
      }, {
        value: '13',
        label: '导出记录'
      }, {
        value: '14',
        label: '变更线索归属'
      }, {
        value: '15',
        label: '角色权限管理-操作日志'
      }, {
        value: '16',
        label: '修改员工手机号/名字/权限'
      }, {
        value: '17',
        label: '停用老师'
      }, {
        value: '18',
        label: '结束班级'
      }, {
        value: '19',
        label: '停用课名'
      },
      {
        value: '20',
        label: '老生录入'
      }, {
        value: '22',
        label: '批量老生录入'
      }, {
        value: '23',
        label: '收支作废'
      }, {
        value: '24',
        label: '开单作废'
      }, {
        value: '25',
        label: '课时不足合并课单'
      }],
      // 操作类型Key 1-用户登录; 15-角色权限管理-操作日志; 16-修改员工手机号/名字/权限; 17-停用老师; 10-直属店设置;
      HQOptionList: [
        {
          value: '0',
          label: '全部'
        }, {
          value: '1',
          label: '用户登录'
        },
        {
          value: '10',
          label: '直属店设置'
        }, {
          value: '15',
          label: '角色权限管理-操作日志'
        }, {
          value: '16',
          label: '修改员工手机号/名字/权限'
        }, {
          value: '17',
          label: '停用老师'
        }, {
          value: '23',
          label: '收支作废'
        }, {
          value: '24',
          label: '开单作废'
        },

      ],
      // CWS操作类型Key 1-用户登录; 18-停用班级; 10-修改门店设置; 16-修改员工手机号/名字; 17-停用老师; 2-学生离校-手动
      CWSOptionList: [
        {
          value: '0',
          label: '全部'
        },
        {
          value: '1',
          label: '用户登录'
        },
        {
          value: '2',
          label: '学生离校-手动'
        },
        {
          value: '10',
          label: '修改门店设置'
        }, {
          value: '16',
          label: '修改员工手机号/名字'
        }, {
          value: '17',
          label: '停用老师'
        }, {
          value: '18',
          label: '停用班级'
        }
      ],
      // 操作设备  1-PC,2-WXB,3-APP,4-MP,5-桌面端,
      EquipmentOption: [
        {
          value: '0',
          label: '全部'
        }, {
          value: '1',
          label: '浏览器'
        }, {
          value: '2',
          label: '微信H5'
        }, {
          value: '3',
          label: 'APP'
        }, {
          value: '4',
          label: '平板'
        }, {
          value: '5',
          label: '桌面端'
        }
      ]
    };
  },
  components: {
    tableList,
    // seletedOption,
    inputSearchContainBtn,
    dialogFactory
  },
  props: {
    seletedDate: {
      type: String,
      default: ''
    }

  },
  created () {

  },
  mounted () {
    this.$bus.on(this, 'wsAttendanceOrArrange', (data) => {
      console.log('触发wsAttendanceOrArrange');
      this.getDataList();
    });
    // this.searchObj.Type = this.IsMTeaKey;
    // console.log( this.IsMTeaKey,"1111111111111111");
    if (this.seletedDate) {
      this.searchObj.startTime = this.seletedDate;
      this.searchObj.endTime = this.seletedDate;
    }
    this.getDataList();
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        if (o.OperateTypeKey == 18 && this.$store.getters.SaaSClientEditionKey == 6) {
          o.OperateTypeKeyValue = '停用班级';
        }
        if (o.OperateTypeKey == 16 && (this.$store.getters.SaaSClientEditionKey != 6)) {
          o.OperateTypeKeyValue = '修改员工手机号/名字/权限';
        }
        o.GenerateTime = o.GenerateTime || '';
        o.GenerateTime = o.GenerateTime.substring(0, 16);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterContent () {
      return '共' + this.totalNum + '条数据';
    },
    vOptionList () {
      if (this.$store.getters.SaaSClientInfo.TypeKey == 6) { // 连锁总部
        return this.HQOptionList;
      } else if (this.$store.getters.SaaSClientEditionKey == 6) {
        return this.CWSOptionList;
      } else {
        return this.WEBVOptionList;
      }
    },
    vOperatorTypekeyValue () {
      return this.searchObj.OperatorTypekey == 0
        ? '全部'
        : this.searchObj.OperatorTypekeyValue;
    },
    vOperatorEquipmentKey () {
      return this.searchObj.OperatorEquipmentKey == 0
        ? '全部'
        : this.searchObj.OperatorEquipmentKeyValue;
    }
  },
  methods: {
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    // 状态切换
    typeEvent (obj) {
      this.searchObj.OperatorTypekeyValue = obj.label;
      this.searchObj.OperatorTypekey = obj.value;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    EquipmentEvent (obj) {
      this.searchObj.OperatorEquipmentKeyValue = obj.label;
      this.searchObj.OperatorEquipmentKey = obj.value;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.OperatorTypekey = 0;
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.OperatorEquipmentKey = 0; // 状态
      this.$refs.saveDate.resetTime();
      this.getDataList();
    },
    // 适用课程切换
    updateData (item) {
      console.log(this.searchObj, 'updateData', item);

      this.searchObj.pageIndex = 0;
      this.getDataList();
    },

    // 外围调用. 更新日期查询
    searchDataForDate (item, date) {
      console.log('searchDataForDate', date);
      // this.searchObj.TeacherKey = item.TeacherKey;
      // this.searchObj.TeacherKeyValue = item.TeacherKeyValue;
      // this.searchObj.GradeKey = 0;
      // this.searchObj.GradeKeyValue = '全部';
      this.searchObj.CourseKey = 0;
      this.searchObj.CourseKeyValue = '全部';
      // this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(date).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      let seletedYearMonth = Date.new(date).getFullYear() + ' ' + (Date.new(date).getMonth() + 1);
      let nowYearMonth = Date.new().getFullYear() + ' ' + (Date.new().getMonth() + 1);
      if (seletedYearMonth != nowYearMonth) {
        this.searchObj.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new(date)), 'yyyy-MM-dd');
      } else {
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0));
      }
      this.getDataList();
    },
    search () {
      // this.searchObj.TeacherKey = 0;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },

    getDataList (queryParams) {
      // if (queryParams) {
      //   if (queryParams.orderBy == 'classTimeObj') {
      //     queryParams.orderBy = 'ClassTime';
      //   }
      //   Object.assign(this.searchObj, queryParams);
      // }
      MessageSearchPageClientActionLog(this.searchObj).then(result => {
        console.log(this.searchObj, 'this.searchObj');
        console.log(result, 'result');
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.totalAbnormalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        this.allNum = result.data.Stat;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData) {
      console.log(rowData, 'doAfterTableBtnClick');

      this.seletedCourseInfo = rowData;
      let moduleInfo = {
        name: '安排考勤',
        moduleName: 'attendanceArrange',
        routerName: this.$route.name,
        data: { scheduleCourseKey: this.seletedCourseInfo.ScheduleCourseKey }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    }

  }
};
</script>
<style scoped>
.class_btn_delay {
  height: 36px;
  width: 100px !important;
  align-items: center;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  background: #3498db url(../../../../public/image/batch_distribution_icon.png)
    no-repeat left 10px center;
  background-size: 16px;
  padding-left: 20px;
}
.class_btn_delays {
  height: 36px;
  width: 90px;
  align-items: center;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
}
.remove_db_btn {
  background-image: url(../../../../public/image/dialog_del_icon.png);
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 8px;
  margin-top: 3px;
}
.tsjj {
  margin-left: 20px;
}
.course_statistics_table_search .mendian_backstage_date {
  margin-right: 0px;
  min-width: 250px;
}
.mendian_backstage_search >>> .search_box {
  width: 240px !important;
  margin-right: 30px !important;
  min-width: 240px !important;
}
/* 
.payment_statistics_bottom .course_statistics_search.width_small .search_box{
  width: 164px!important;
  min-width: 164px!important;
} */
</style>