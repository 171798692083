<template>
  <div>
    <input type="file"
            :id="vAcceptName"
         
           class="upload_input"
           name="file"
           :accept="vAcceptType" />
  </div>
</template>

<script>
import {
  UploadMaterialForAudio,
  UploadMaterialForImg,
  UploadMaterialForVideo,
  UploadMaterialForPPT,
  UploadMaterialForPDF
} from '../../../../API/workbench';
import { uploadMultipartHQ } from '../../../../utils/chunkedUpload';
export default {
  data () {
    return {
      acceptType: ''
    };
  },
  props: {
    VisitAuthKey: [String, Number],
    attachType: String,
    dataInfo: Array,
    coursewareId: [String, Number],
    authorizedCode: String,
    coursewareType: [String, Number], // 附件所属类型:1-课件2-教学步骤;
    IsUpdateFile: {
      type: [String, Number],
      default: () => {
        return 0;
      }
    }
  },
  computed: {
    vAcceptType () {
      if (this.attachType == '1') {
        return '.jpg,.jpeg,.png';
      } else if (this.attachType == '2') {
        // 视频
        return '.mp4';
      } else if (this.attachType == '3') {
        // 音频
        return '.mp3,.aac,.wav';
      } else if (this.attachType == '4') {
        return '.ppt,.pptx,.pps,.ppsx';
      } else if (this.attachType == '5') {
        // PDF
        return '.pdf';
      } else if (this.coursewareType == 3) {
        // 其他附件
        return '.mp4,.pdf,.zip,.jpg,.jpeg,.png,.mp3,.docx,.ppt,.xls,.doc,.txt,.xlsx,.rar,.pptx,.pps,.ppsx';
      }
      return '.mp4,.pdf,.zip,.jpg,.jpeg,.png,.mp3,.docx,.ppt,.xls,.doc,.txt,.xlsx,.rar,.pptx,.pps,.ppsx';
    },
    vAcceptName () {
      if (this.attachType == '1') {
        return 'tupian';
      } else if (this.attachType == '2') {
        // 视频
        return 'shipin';
      } else if (this.attachType == '3') {
        // 音频
        return 'yinpin';
      } else if (this.attachType == '4') {
        return 'worde';
      } else if (this.attachType == '5') {
        // PDF
        return 'pdfwend';
      } else if (this.coursewareType == 3) {
        // 其他附件
        return 'qita';
      }
      return 'other';
    }
  },
  created () { },
  mounted(){
    // window.addEventListener('scroll', this.updateScrollTop);
      document.getElementById(this.vAcceptName).addEventListener('change', (event) =>{
      // 处理文件上传的逻辑
       this.chooseFiles(event)
      });
    //  let a= document.getElementById('fileInput')
    //   console.log('a',a)
  },
  methods: {
    chooseFiles (e) {
      console.log('123456')
      let form = e.target.files[0];
      let fileName = e.target.files[0].name;
      // console.log('e.target.files[0]', fileName, this.vAcceptType);
      // console.log('VisitAuthKey', this.VisitAuthKey);
      console.log('this.coursewareType == 3', this.coursewareType);
      let typeText = this.vAcceptType;
      if (
        typeText.indexOf(
          fileName.toLowerCase().substring(
            fileName.toLowerCase().lastIndexOf('.'),
            fileName.length
          )
        ) == -1
      ) {
        layer.alert('上传文件类型不符合，只能上传' + typeText + '类型文件');
        return false;
      } else {
        let mbSize = 1048576;
        // console.log('this.attachType', this.attachType);
        if (this.attachType == '1') {
          if (form.size > mbSize * 8) {
            layer.alert('上传图片大于8MB，无法上传');
            return false;
          }
        } else if (this.attachType == '2') {
          if (form.size > mbSize * 500) {
            layer.alert('上传视频大于500MB，无法上传');
            return false;
          }
        } else if (this.attachType == '3') {
          if (form.size > mbSize * 50) {
            layer.alert('上传音频大于50MB，无法上传');
            return false;
          }
        } else if (this.attachType == '4') {
          if (form.size > mbSize * 200) {
            layer.alert('上传PPT大于200MB，无法上传');
            return false;
          }
        } else if (this.attachType == '5') {
          if (form.size > mbSize * 20) {
            layer.alert('上传PDF大于20MB，无法上传');
            return false;
          }
        } else if (this.coursewareType == 3) {
          if (form.size > mbSize * 100) {
            layer.alert('上传附件大于100MB，无法上传');
            return false;
          }
        } else {
          if (form.size > mbSize * 8) {
            layer.alert('上传附件大于8MB，无法上传');
            return false;
          }
        }

        // if (this.attachType == '4' && form.size > mbSize * 100) {
        //   if (form.size > mbSize * 100) {
        //     //console.log('100M了')
        //     layer.confirm('文件超过100M，将启用高级转码方式', {
        //     btn: [
        //       {
        //         name: '确认',
        //         callBack: () => {
        //           let DurationNum = 0;
        //           let formData = new FormData();
        //           formData.append("file", form, form.name);
        //           //console.log(formData, "chooseFiles111");
        //           let url = "";
        //           this.upload(formData, url, DurationNum, form);
        //           layer.close();
        //         }
        //       }
        //     ]
        //   });
        //   }
        // } else {
        // console.log('没有100M', form.size);
        let DurationNum = 0;
        let formData = new FormData();
        formData.append('file', form, form.name);
        // console.log(formData, 'chooseFiles111');
        let url = '';
        if (this.attachType == '1') {
          url = URL.createObjectURL(form);
        }
        if (this.attachType == '3') {
          // 获取音频时长
          url = URL.createObjectURL(form);
          let audio = new Audio(url);
          audio.load();
          audio.oncanplay = () => {
            // console.log('audio.oncanplay ', audio);
            DurationNum = parseInt(audio.duration);
            // console.log(DurationNum, 'DurationNumDurationNum');
            this.upload(formData, url, DurationNum, form);
          };
        } else {
          this.upload(formData, url, DurationNum, form);
        }
        // }
      }
    },
    upload (formData, url, DurationNum, form) {
      console.log('form.size', form);
      // let MainDemoName=form.name?form.name.substring(0,form.name.lastIndexOf('.')):''
      let obj = {
        percent: 0,
        AttachViewUrl: url,
        CoverUrl: '',
        SizeNum: form.size,
        DurationNum: DurationNum || '',
        ShowOrder: this.dataInfo.length ? this.dataInfo.length + 1 : 1,
        MainDemoName: form.name ? form.name.substring(0, form.name.lastIndexOf('.')) : '',
        AttachTypeKey: this.attachType,
        ID: '',
        isSelect: false,
        Remark: '',
        TypeKey: this.coursewareType || 1,
        IsUpdateFile: this.IsUpdateFile || 0,
        previewList: null
      };
      if (this.coursewareType == 2) {
        // 教学步骤
        let PDFIndex = this.dataInfo.findIndex(e => {
          return e.TypeKey == 2 && e.AttachTypeKey == 5 && e.ID != obj.ID; // 教学步骤 且附件为PDF
        });
        let videoIndex = this.dataInfo.findIndex(e => {
          return e.TypeKey == 2 && e.AttachTypeKey == 2 && e.ID > 0; // 教学步骤 且附件为 视频
        });
        let name = PDFIndex > -1 && this.attachType == '5' ? 'PDF' : '视频';

        if (
          (PDFIndex > -1 && this.attachType == '5') ||
          (videoIndex > -1 && this.attachType == '2')
        ) {
          layer.confirm(name + '只能上传一个,是否需要替换?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.dataInfo.push(obj);
                  this.$parent.selectAccessory(obj);
                  if (form.size < 1024 * 1024 * 10) {
                    this.smallUpload(formData, obj);
                  } else {
                    this.multipartUpload(formData, obj);
                  }
                  layer.close();
                }
              }
            ]
          });
          this.setInputValue();
          return false;
        }
      } else if (this.coursewareType == 3) {
        this.otherAttachTypeUpload(formData, obj, form);
        return false;
      }
      if (this.IsUpdateFile == 0) {
        this.dataInfo.push(obj);
      }
      this.$parent.selectAccessory(obj);
      if (form.size < 1024 * 1024 * 10) {
        this.smallUpload(formData, obj);
      } else {
        this.multipartUpload(formData, obj);
      }
      this.setInputValue();
    },
    // 选中图片渲染后将input里面的值清除, 防止下次选中同一个文件无效.
    setInputValue () {
      // if (document.getElementsByID('upload_input')[0]) {
      //   var loadInput = document.getElementsByClassName('upload_input')[0];
      //   loadInput.value = '';
      // }
      if (document.getElementById(this.vAcceptName)) {
        var loadInput2 = document.getElementById(this.vAcceptName);
        loadInput2.value = '';
      }
    },
    revokeObjectURL (url) {
      if (url) {
        try {
          URL.revokeObjectURL(url);
          return true;
        } catch (e) {
          // console.log('URL.revokeObjectURL error', url, e);
        }
      }
      return false;
    },
    multipartUpload (formData, obj) {
       console.log(
        this.$websocket.ws.readyState,
        'this.$websocket.ws.readyState'
      );
       if (this.$websocket.ws.readyState != 1) {
        layer.alert('网络不稳定，请稍后再试。');
        return false;
      }
      // let type = 0;
      // if (this.attachType != 4) {
      //   type = this.attachType;
      // } else {
      //   type = 8;
      // }
      console.log('multipartUpload', this.attachType);
      let parameter = {
        type: this.attachType,
        CoursewareID: this.coursewareId,
        AuthorizeCode: this.authorizedCode,
        coursewareType: this.coursewareType || 1,
        VisitAuthKey: this.VisitAuthKey,
        MainDemoName: obj.MainDemoName,
        IsUpdateFile: this.IsUpdateFile || 0
      };
      if (this.attachType == '2' || this.attachType == '4') {
        // obj.Remark = '封面生成中';
      }

      let uploadTypeKey = this.setAcceptType(this.attachType);
      uploadMultipartHQ(formData, uploadTypeKey, obj, parameter).then(
        res => {
          console.log('uploadMultipartHQ', res);
          this.$bus.emit('addAttachLog', res.data.OLAPKey);
          if (this.attachType == '1') {
            this.revokeObjectURL(obj.AttachViewUrl);
            obj.AttachViewUrl = res.data.AttachViewUrl;
            obj.CoverUrl = res.data.CoverUrl;
            obj.DurationNum = res.data.DurationNum;
            obj.ID = res.data.OLAPKey;
            obj.SizeNum = res.data.SizeNum;
            this.$parent.saveAccessory(res.data);
          } else if (this.attachType == '2') {
            if (this.coursewareType == 2) {
              // 教学步骤
              let n = this.dataInfo.findIndex(e => {
                return e.TypeKey == 2 && e.AttachTypeKey == 2 && e.ID > 0; // 教学步骤 且附件为视频
              });
              if (n > -1) {
                this.dataInfo.splice(n, 1);
              }
            }
            obj.Remark = '开始转化';
            obj.ID = res.data.OLAPKey;
            obj.CoverUrl = res.data.CoverUrl;
            this.$nextTick(() => {
              obj.Remark = '转码队列中';
            });
            this.$asyncTask.register(res.data.AsynTaskKey, sendData => {
              console.log(' res.data.SizeNum', res.data.SizeNum);

              this.revokeObjectURL(obj.AttachViewUrl);
              obj.AttachViewUrl = sendData.Data.AttachViewUrl;
              obj.Remark = sendData.remark;
              obj.SizeNum = Number(obj.SizeNum) || res.data.SizeNum;
              console.log(' res. sendData', sendData);
              this.$bus.emit('wsCoursewareEditor', sendData);
              if (sendData.nodeType == 2 && sendData.status == 1) {
                // 结束节点
                this.$asyncTask.delete(sendData.AsynTaskKey);
                obj.AttachViewUrl = sendData.Data.AttachViewUrl;
                obj.CoverUrl = sendData.Data.CoverUrl;
                obj.DurationNum = sendData.Data.DurationNum;
                obj.ID = res.data.OLAPKey;
                // obj.SizeNum = res.data.SizeNum;
                this.$parent.saveAccessory(res.data);
              } else if (sendData.status == 0) {
              }
            });
          } else if (this.attachType == '3') {
            console.log('这是音频上传');
            this.revokeObjectURL(obj.AttachViewUrl);
            obj.AttachViewUrl = res.data.AttachViewUrl;
            // obj.CoverUrl = res.data.CoverUrl;
            obj.DurationNum = res.data.DurationNum;
            obj.ID = res.data.OLAPKey;
            obj.SizeNum = res.data.SizeNum;
            this.$parent.saveAccessory(res.data);
            if (this.coursewareType == 3) {
              // 教学步骤
              let n = this.dataInfo.findIndex(e => {
                return e.TypeKey == 3 && e.AttachTypeKey == 2 && e.ID > 0; // 教学步骤 且附件为视频
              });
              if (n > -1) {
                this.dataInfo.splice(n, 1);
              }
            }
            obj.Remark = '开始转化';
            obj.ID = res.data.OLAPKey;
            this.$nextTick(() => {
              obj.Remark = '转码中';
            });
            this.$asyncTask.register(res.data.AsynTaskKey, sendData => {
              console.log(' res.data.SizeNum', res.data.SizeNum);
              this.revokeObjectURL(obj.AttachViewUrl);
              obj.AttachViewUrl = sendData.Data.AttachViewUrl;
              obj.Remark = sendData.remark;
              obj.SizeNum = res.data.SizeNum;
              this.$bus.emit('wsCoursewareEditor', sendData);
              if (sendData.nodeType == 2 && sendData.status == 1) {
                // 结束节点
                this.$asyncTask.delete(sendData.AsynTaskKey);
                obj.AttachViewUrl = sendData.Data.AttachViewUrl;
                obj.DurationNum = sendData.Data.DurationNum;
                obj.ID = res.data.OLAPKey;
                // obj.SizeNum = res.data.SizeNum;
                this.$parent.saveAccessory(res.data);
              } else if (sendData.status == 0) {
              }
            });
          } else if (this.attachType == '4') {
            obj.CoverUrl = res.data.CoverUrl;
            obj.ID = res.data.OLAPKey;
            this.$nextTick(() => {
              obj.Remark = '转码队列中';
            });
            console.log('this.$asyncTask', res.data);
            if (this.IsUpdateFile == 1) {
              this.$parent.saveAccessory(res.data);
            } else {
              this.$asyncTask.register(res.data.AsynTaskKey, sendData => {
                console.log('sendData', sendData);
                this.revokeObjectURL(obj.AttachViewUrl);
                obj.AttachViewUrl = sendData.Data.AttachViewUrl;
                obj.DurationNum = sendData.Data.DurationNum;
                obj.Remark = sendData.remark;
                obj.SizeNum = res.data.SizeNum; // sendData.Data.SizeNum
                this.$bus.emit('wsCoursewareEditor', sendData);
                if (sendData.nodeType == 2 && sendData.status == 1) {
                  // 结束节点
                  console.log('sendData.nodeType == 2 && sendData.status == 1');
                  this.$asyncTask.delete(sendData.AsynTaskKey);
                  obj.AttachViewUrl = sendData.Data.AttachViewUrl;
                  obj.CoverUrl = sendData.Data.CoverUrl;
                  obj.DurationNum = sendData.Data.DurationNum;
                  obj.ID = res.data.OLAPKey;
                  obj.previewList = sendData.Data.previewList;
                  // obj.SizeNum = sendData.Data.SizeNum||;
                  this.$parent.saveAccessory(res.data);
                }
              });
            }
          } else if (this.attachType == '5') {
            this.revokeObjectURL(obj.AttachViewUrl);
            obj.AttachViewUrl = res.data.AttachViewUrl;
            obj.CoverUrl = res.data.CoverUrl;
            obj.DurationNum = res.data.DurationNum;
            obj.ID = res.data.OLAPKey;
            obj.SizeNum = res.data.SizeNum;
            this.$parent.saveAccessory(res.data);
            console.log('this.dataInfo', this.dataInfo);
            if (this.coursewareType == 2) {
              // 教学步骤
              let n = this.dataInfo.findIndex(e => {
                return e.TypeKey == 2 && e.AttachTypeKey == 5 && e.ID != obj.ID; // 教学步骤 且附件为PDF
              });
              if (n >= 0) {
                this.dataInfo.splice(n, 1);
              }
            }
          }
        },
        err => {
          let n = this.dataInfo.findIndex(e => {
            return e.ShowOrder == obj.ShowOrder;
          });
          if (n >= 0) {
            this.dataInfo.splice(n, 1);
          }
          layer.alert(err.msg);
        }
      );
    },
    smallUpload (formData, obj) {
       console.log(
        this.$websocket.ws.readyState,
        'this.$websocket.ws.readyState'
      );
       if (this.$websocket.ws.readyState != 1) {
        layer.alert('网络不稳定，请稍后再试。');
        return false;
      }
      // console.log('smallUpload', this.attachType);
      let parameter = {
        type: this.attachType,
        CoursewareID: this.coursewareId,
        AuthorizeCode: this.authorizedCode,
        coursewareType: this.coursewareType || 1,
        VisitAuthKey: this.VisitAuthKey,
        MainDemoName: obj.MainDemoName,
        IsUpdateFile: this.IsUpdateFile || 0
      };
      let uploadTypeKey = this.setAcceptType(this.attachType);
      console.log('uploadTypeKey', uploadTypeKey);
      if (this.attachType == '1') {
        // UploadMaterialForImg(
        //   formData,
        //   this.coursewareId,
        //   obj,
        //   this.authorizedCode
        // )
        uploadMultipartHQ(formData, uploadTypeKey, obj, parameter).then(
          res => {
            this.$bus.emit('addAttachLog', res.data.OLAPKey);
            console.log(res, 'uploadMultipartHQ222');
            this.revokeObjectURL(obj.AttachViewUrl);
            obj.AttachViewUrl = res.data.AttachViewUrl;
            obj.CoverUrl = res.data.CoverUrl;
            obj.DurationNum = res.data.DurationNum;
            obj.SizeNum = res.data.SizeNum;
            obj.ID = res.data.OLAPKey;
            this.$parent.saveAccessory(res.data);
          },
          err => {
            let n = this.dataInfo.findIndex(e => {
              return e.ShowOrder == obj.ShowOrder;
            });
            if (n >= 0) {
              this.dataInfo.splice(n, 1);
            }
            layer.alert(err.msg);
          }
        );
      } else if (this.attachType == '2') {
        // obj.Remark = '封面生成中';
        // UploadMaterialForVideo(
        //   formData,
        //   this.coursewareId,
        //   this.authorizedCode,
        //   this.coursewareType || 1,
        //   obj
        // )
        console.log('sssssssaudio');
        uploadMultipartHQ(formData, uploadTypeKey, obj, parameter).then(
          res => {
            this.$bus.emit('addAttachLog', res.data.OLAPKey);
            console.log('uploadMultipartHQqqqqq', res);
            if (this.coursewareType == 2) {
              // 教学步骤
              let n = this.dataInfo.findIndex(e => {
                return e.TypeKey == 2 && e.AttachTypeKey == 2 && e.ID > 0; // 教学步骤 且附件为视频
              });
              if (n > -1) {
                this.dataInfo.splice(n, 1);
              }
            }
            obj.Remark = '开始转化';
            obj.ID = res.data.OLAPKey;
            obj.CoverUrl = res.data.CoverUrl;
            this.$nextTick(() => {
              obj.Remark = '转码队列中';
            });
            this.$asyncTask.register(res.data.AsynTaskKey, sendData => {
              console.log('sendData', sendData);
              this.revokeObjectURL(obj.AttachViewUrl);
              obj.AttachViewUrl = sendData.Data.AttachViewUrl;
              obj.Remark = sendData.remark;
              this.$bus.emit('wsCoursewareEditor', sendData);
              if (sendData.nodeType == 2 && sendData.status == 1) {
                // 结束节点
                this.$asyncTask.delete(sendData.AsynTaskKey);
                obj.AttachViewUrl = sendData.Data.AttachViewUrl;
                obj.CoverUrl = sendData.Data.CoverUrl;
                obj.DurationNum = sendData.Data.DurationNum;
                obj.ID = res.data.OLAPKey;
                console.log('res.data', res.data);
                obj.SizeNum = Number(obj.SizeNum) || res.data.SizeNum;
                this.$parent.saveAccessory(res.data);
              } else if (sendData.status == 0) {
                // this.$emit("deleteAccessory");
                // let n = this.dataInfo.findIndex((e) => {
                //   return e.ShowOrder == obj.ShowOrder;
                // });
                // this.dataInfo.splice(n,1);
                // layer.alert(sendData.remark);

              }
            });
          },
          err => {
            let n = this.dataInfo.findIndex(e => {
              return e.ShowOrder == obj.ShowOrder;
            });
            if (n >= 0) {
              this.dataInfo.splice(n, 1);
            }
            layer.alert(err.msg);
          }
        );
      } else if (this.attachType == '3') {
        // UploadMaterialForAudio(
        //   formData,
        //   this.coursewareId,
        //   obj,
        //   this.authorizedCode
        // )
        console.log('挖出');
        uploadMultipartHQ(formData, uploadTypeKey, obj, parameter).then(
          res => {
            this.$bus.emit('addAttachLog', res.data.OLAPKey);
            if (this.coursewareType == 3) {
              // 教学步骤
              let n = this.dataInfo.findIndex(e => {
                return e.TypeKey == 3 && e.AttachTypeKey == 3 && e.ID > 0; //
              });
              if (n > -1) {
                this.dataInfo.splice(n, 1);
              }
            }
            obj.Remark = '开始转化';
            obj.ID = res.data.OLAPKey;
            obj.CoverUrl = res.data.CoverUrl;
            this.$nextTick(() => {
              obj.Remark = '转码中';
            });
            this.revokeObjectURL(obj.AttachViewUrl);
            obj.AttachViewUrl = res.data.AttachViewUrl;
            obj.CoverUrl = res.data.CoverUrl;
            obj.DurationNum = res.data.DurationNum;
            obj.ID = res.data.OLAPKey;
            obj.SizeNum = res.data.SizeNum;
            this.$parent.saveAccessory(res.data);

            this.$asyncTask.register(res.data.AsynTaskKey, sendData => {
              console.log('sendData', sendData);
              this.revokeObjectURL(obj.AttachViewUrl);
              obj.AttachViewUrl = sendData.Data.AttachViewUrl;
              obj.Remark = sendData.remark;
              this.$bus.emit('wsCoursewareEditor', sendData);
              if (sendData.nodeType == 2 && sendData.status == 1) {
                // 结束节点
                this.$asyncTask.delete(sendData.AsynTaskKey);
                obj.AttachViewUrl = sendData.Data.AttachViewUrl;
                // obj.CoverUrl = sendData.Data.CoverUrl;
                obj.DurationNum = sendData.Data.DurationNum;
                obj.ID = res.data.OLAPKey;
                obj.SizeNum = res.data.SizeNum;
                this.$parent.saveAccessory(res.data);
              } else if (sendData.status == 0) {
                console.log('wwwwcccc');
              }
            });
          },
          err => {
            let n = this.dataInfo.findIndex(e => {
              return e.ShowOrder == obj.ShowOrder;
            });
            if (n >= 0) {
              this.dataInfo.splice(n, 1);
            }

            layer.alert(err.msg);
          }
        );
      } else if (this.attachType == '4') {
        // obj.Remark = '封面生成中';
        // UploadMaterialForPPT(
        //   formData,
        //   this.coursewareId,
        //   this.authorizedCode,
        //   obj
        // )
        // console.log('ppt上传attachType ==4', obj);
        uploadMultipartHQ(formData, uploadTypeKey, obj, parameter).then(
          res => {
            this.$bus.emit('addAttachLog', res.data.OLAPKey);
            obj.CoverUrl = res.data.CoverUrl;
            obj.ID = res.data.OLAPKey;
            this.$nextTick(() => {
              obj.Remark = '转码队列中';
            });
            if (this.IsUpdateFile == 1) {
              this.$parent.saveAccessory(res.data);
            } else {
              this.$asyncTask.register(res.data.AsynTaskKey, sendData => {
                this.revokeObjectURL(obj.AttachViewUrl);
                obj.AttachViewUrl = sendData.Data.AttachViewUrl;
                obj.DurationNum = sendData.Data.DurationNum;
                obj.Remark = sendData.remark;
                this.$bus.emit('wsCoursewareEditor', sendData);
                if (sendData.nodeType == 2 && sendData.status == 1) {
                  // 结束节点
                  this.$asyncTask.delete(sendData.AsynTaskKey);
                  obj.AttachViewUrl = sendData.Data.AttachViewUrl;
                  obj.CoverUrl = sendData.Data.CoverUrl;
                  obj.DurationNum = sendData.Data.DurationNum;
                  obj.ID = res.data.OLAPKey;
                  obj.SizeNum = obj.SizeNum;// sendData.Data.SizeNum
                  obj.previewList = sendData.Data.previewList;
                  this.$parent.saveAccessory(res.data);
                } else if (sendData.status == 0) {
                  // let n = this.dataInfo.findIndex((e) => {
                  //   return e.ShowOrder == obj.ShowOrder
                  // });
                  // this.dataInfo.splice(n,1);
                  // layer.alert(sendData.remark);
                }
              });
            }
          },
          err => {
            let n = this.dataInfo.findIndex(e => {
              return e.ShowOrder == obj.ShowOrder;
            });
            if (n >= 0) {
              this.dataInfo.splice(n, 1);
            }
            layer.alert(err.msg);
          }
        );
      } else if (this.attachType == '5') {
        // UploadMaterialForPDF(
        //   formData,
        //   this.coursewareId,
        //   this.authorizedCode,
        //   this.coursewareType || 1,
        //   obj
        // )
        uploadMultipartHQ(formData, uploadTypeKey, obj, parameter).then(
          res => {
            this.$bus.emit('addAttachLog', res.data.OLAPKey);
            this.revokeObjectURL(obj.AttachViewUrl);
            obj.AttachViewUrl = res.data.AttachViewUrl;
            obj.CoverUrl = res.data.CoverUrl;
            obj.DurationNum = res.data.DurationNum;
            obj.ID = res.data.OLAPKey;
            obj.SizeNum = res.data.SizeNum;
            this.$parent.saveAccessory(res.data);
            if (this.coursewareType == 2) {
              // 教学步骤
              let n = this.dataInfo.findIndex(e => {
                return e.TypeKey == 2 && e.AttachTypeKey == 5 && e.ID != obj.ID; // 教学步骤 且附件为PDF
              });
              if (n > -1) {
                this.dataInfo.splice(n, 1);
              }
            }
          },
          err => {
            let n = this.dataInfo.findIndex(e => {
              return e.ShowOrder == obj.ShowOrder;
            });
            if (n >= 0) {
              this.dataInfo.splice(n, 1);
            }
            layer.alert(err.msg);
          }
        );
      }
    },
    // 上传文件类型 1-图;2-视频; 3-音频; 4-文本图; 5-字体文件;6-文档;7-证书;8-ppt文件;9-pdf文件;10-Zip文件;11-Docx文档文件;12-xls表格文件;
    setAcceptType (attachType) {
      if (attachType == '1') {
        return '1';
      } else if (attachType == '2') {
        // 视频
        return '2';
      } else if (attachType == '3') {
        return '3';
      } else if (attachType == '4') {
        return '8';
      } else if (attachType == '5') {
        // PDF
        return '9';
      } else if (attachType == '6') {
        // Zip
        return '10';
      } else if (attachType == '7') {
        // xls
        return '12';
      } else if (attachType == '8') {
        // Docx
        return '11';
      } else {
        return '1';
      }
    },
    otherAttachTypeUpload (formData, obj, file) {
      obj.MainDemoName = file.name;
      obj.DurationNum = file.size;
      obj.SizeNum = file.size;
      obj.AttachTypeKey = 0;
      let fileType = file.name.split('.')[file.name.split('.').length - 1];
      // console.log(fileType.toLowerCase(), '文件类型');
      //  1-图片;2-视频;3-音频;4-PPT;5-PDF;6-ZIP;7-excel;8-word
      switch (fileType.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'png':
          obj.AttachTypeKey = 1;
          break;
        case 'mp4':
          obj.AttachTypeKey = 2;
          break;
        case 'mp3':
          obj.AttachTypeKey = 3;
          break;
        case 'ppt':
        case 'pptx':
        case 'pps':
        case 'ppsx':
          obj.AttachTypeKey = 4;
          break;
        case 'pdf':
          obj.AttachTypeKey = 5;
          break;
        case 'zip':
        case 'rar':
          obj.AttachTypeKey = 6;
          break;
        case 'xls':
        case 'xlsx':
          obj.AttachTypeKey = 7;
          break;
        case 'docx':
        case 'doc':
        case 'txt':
          obj.AttachTypeKey = 8;
          break;
        default:
          break;
      }
      let parameter = {
        type: this.attachType,
        CoursewareID: this.coursewareId,
        AuthorizeCode: this.authorizedCode,
        coursewareType: this.coursewareType || 1,
        MainDemoName: obj.MainDemoName,
        VisitAuthKey: this.VisitAuthKey,
        IsUpdateFile: this.IsUpdateFile || 0
      };
      // console.log(parameter, 'parameter', obj.AttachTypeKey);
      let uploadTypeKey = this.setAcceptType(obj.AttachTypeKey);
      uploadMultipartHQ(formData, uploadTypeKey, obj, parameter).then(
        res => {
          this.$bus.emit('addAttachLog', res.data.OLAPKey);
          // console.log(res, 'obj');
          this.dataInfo.push(obj);
          this.$parent.selectAccessory(obj);
          this.setInputValue();
          console.log(formData, obj, file, 'otherAttachTypeUpload');
          this.revokeObjectURL(obj.AttachViewUrl);
          obj.AttachViewUrl = res.data.AttachViewUrl;
          obj.CoverUrl = res.data.CoverUrl;
          obj.DurationNum = res.data.DurationNum;
          obj.ID = res.data.OLAPKey;
          console.log('res.data333', res.data);
          obj.SizeNum = Number(obj.SizeNum) || res.data.SizeNum;
          this.$parent.saveAccessory(res.data);
        },
        err => {
          layer.alert(err.msg);
        }
      );
    }
  }
};
</script>
