<template>

  <div class="list_score" :class="scorekey>=vScore?'no_select':''" @click.stop="clickScore">
    <!-- no_select -->
  </div>

</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    scorekey: Number,
    stuItem: Object
  },
  computed: {
    vScore () {
      return Number(this.stuItem.Currency);
    }
  },
  methods: {
    clickScore () {
      this.$set(this.stuItem, 'Currency', this.scorekey + 1);
      this.$emit('clickScore');
    }
  }
};
</script>
