<template>
  <div class="using_package">
    <div v-if="dataList.length>0">
      <courseware-package
        v-for="(item,key) in dataList"
       
                     :key="key"
                     :item="item"
                     :items="item"
                     :numTitle="key"
      ></courseware-package>
    </div>
    <div v-else>
      <div class="monitoring_content_nodata">
          <div class="monitoring_content_nodata_txt2">
            亲，当前没有任何数据哦~
          </div>
        </div>
    </div>
    
  </div>
</template>
<script>
import {
 GetInUseBigCoursewarePackageList
} from '../../../../API/workbench';
import CoursewarePackage from '../courseware-package/index.vue'
export default {
  name : 'UsingPackage',
  data () {
    return {
      dataList:[]
    };
  },
  components: {
   CoursewarePackage
  },
  created () {
    this.getDataLsit () 
  },
  mounted () {
    
  },
  methods: {
    getDataLsit () {
      GetInUseBigCoursewarePackageList().then(result => {
         this.dataList = result.data;
         console.log(this.dataList,'课件包信息')
       }).catch(error => {
        this.loading = false;
        console.log(error);
      });
    }

    
  },
  computed: {}
};
</script>
<style scoped>
.using_package{
    margin-top: 88px;
    margin-bottom: 30px;
}
</style>