<template>
  <!-- 全部线索 -->
  <div class="clue_list"
       style="position: relative;">

    <div class="search_clue_div"
         v-if="!isBatchDistribute">
      <!-- 1 -->
      <div class="clue_content_class_one">
        <div class="option_title">
          <div class="general_summarizing_student_navUl">
            <div class="title_list"
                 @click="tableSwitch(0)"
                 :class="{ opt: tableType == 0 }">
              全部
            </div>
            <div class="title_list"
                 @click="tableSwitch(1)"
                 :class="{ opt: tableType == 1 }">
              未联系 {{ contactCountList.NoContactCount }}
            </div>
            <div class="title_list"
                 @click="tableSwitch(2)"
                 :class="{ opt: tableType == 2 }">
              已联系 {{ contactCountList.ContactedCount }}
            </div>
            <div class="title_list"
                 @click="tableSwitch(3)"
                 :class="{ opt: tableType == 3 }">
              已诺访 {{ contactCountList.PromiseVisitCount }}
            </div>
            <div class="title_list"
                 @click="tableSwitch(4)"
                 :class="{ opt: tableType == 4 }">
              约试课 {{ contactCountList.InvitedClassCount }}
            </div>
            <div class="title_list"
                 @click="tableSwitch(5)"
                 :class="{ opt: tableType == 5 }">
              已试课 {{ contactCountList.TrialLessonCount }}
            </div>
          </div>
        </div>
      </div>
      <!-- 2 -->
      <div class="clue_content_class_two">

        <!-- <div class="header_bottom">
        <function-btn :item="{ name: '添加线索', ModulePowerKey: '' }"
                      :className="'el-button btn_light_blue btn_hover_bg_blue buttom_sq'"
                      @clickItem="doAddClueClick">
        </function-btn>
      </div> -->
        <!-- 销售  -->
        <div class="table_select_box ascription">
          <el-select :value="vStaffValue"
                     filterable
                     placeholder="必选"
                     value-key="OLAPKey"
                     style="width: 120px;margin-left: 0px;"
                     @change="doSelectedStaff">
            <el-option v-for="item in staffList"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item">
              <span style="float: left">{{item.MainDemoName}}</span>
              <span style="position: absolute;text-align: right;color: #8492a6; font-size: 13px">{{item.StudentThreadCount}}</span>
            </el-option>
          </el-select>
        </div>

        <!-- <div class="table_select_box ascription"
           style="margin-right: 15px;">
        <el-select :value="vLabelValue"
                   filterable
                   placeholder="必选"
                   value-key="OLAPKey"
                   style="width: 120px;"
                   @change="chgLabelValue">
          <el-option v-for="item in labelList"
                     :key="item.OLAPKey "
                     :label="item.MainDemoName"
                     :value="item"></el-option>
        </el-select>
      </div> -->
        <!-- 标签 -->
        <div class="table_select_box"
             style="width: 280px;margin-left: 20px;"
             :title="labelText">
          <input v-model="labelText"
                 class="label_input"
                 @click="labelClick"
                 placeholder="全部标签" />
        </div>

        <div class="table_select_box"
             style="margin-right: 15px;">
          <div class="table_select_title">最近跟单</div>
          <el-select v-model="selectedRecentlyDocumentary"
                     filterable
                     style="width: 120px;"
                     placeholder="必选"
                     @change="chgSearchRecentlyDocumentaryValue"
                     value-key="OLAPKey">
            <el-option v-for="item in recentlyDocumentaryList"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item"></el-option>
          </el-select>
        </div>
        <!-- 已选条件 -->
        <input-search-contain-btn v-model="queryParams.searchText"
                                  :width="'120px'"
                                  style="margin-right: 20px;"
                                  :placeholder="'搜索学生'"
                                  @clearSearch="clearExtendSearchParams"
                                  @onSearchEvent="search"></input-search-contain-btn>

        <div class="mendian_backstage_search"
             style="width: 410px;">
          <div class="btn_hover_bg_blue search_btn "
               style="margin-left: 0;"
               @click.stop="doExportInfoClick">导出</div>
          <a class="btn_hover_bg_blue search_btn"
             style="width: 80px;"
             :href="encodeURI($store.getters.PCWebURL + '/ImportTemplate/追销2.0学生线索导入模板.xls')">下载模板</a>
          <el-upload class="btn_hover_bg_blue search_btn"
                     style="width: 80px;"
                     accept=".xls, .xlsx"
                     action="string"
                     ref="upload"
                     :show-file-list="false"
                     :http-request="importEvent">线索导入</el-upload>

          <div>
            <div @click.stop="doStudnetsToClueClick"
                 class="btn_hover_bg_blue search_btn"
                 style="width: 100px;">学生转线索</div>
          </div>
          <div>
            <div @click.stop="showisBatchDistribute"
                 class="batch_allocation_class"
                 style="right: 25px;">批量分配销售</div>
          </div>
        </div>

      </div>

    </div>

    <div v-if="isBatchDistribute"
         style="margin-bottom: 20px;display: flex;">
      <div class="mendian_allocation_search">
        <div class="pitch_count pr"
             style="line-height: 36px;margin-left:10px">已选中 {{courseList.length}} 项
          <div class="remove_db_btn"
               @click="toggleSelection"></div>
        </div>
      </div>
      <div>
        <div @click.stop="allocationSaleBtn"
             class="batch_allocation_class batch_btn_delay">批量分配销售</div>
      </div>
    </div>

    <div class="table_list_content introduce_list campusManagement_list">
      <table-list ref="tableListRef"
                  class="summarizing_list schedule_table_class"
                  :tableData="recruitStudentsList"
                  :queryParams="queryParams"
                  :tableColumns="tableColumns"
                  :rowKey="'OLAPKey'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  :isShowCheckBox="true"
                  @changeCheckBoxFun="clickCheckBox"
                  @loadTableData="getManageMessageSearchPageStudentThreadList"></table-list>
    </div>

    <!--  跟进表单  登记诺访 登记约课 报名 放弃 列表 :formTitle="'可用课单'"-->
    <custom-dialog :title="scheduleTitle"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowFollowUp"
                   :before-close="doShowFollowUpHide">
      <follow-up-form :dataInfo="dataInfo"
                      :scheduleTitle="scheduleTitle"
                      @afterSuccess="afterSuccess">
      </follow-up-form>
    </custom-dialog>

    <custom-dialog :title="'分配员工'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowSalesList"
                   :before-close="closeForm">
      <get-sale-list :selectedKey="selectedKey"
                     :OLAPKey="OLAPKey"
                     :courseList="courseList"
                     :isBatchAllocation="true"
                     @closeForm="closeForm"
                     @onSelectedItem="onSelectedItem">
      </get-sale-list>
    </custom-dialog>
    <!-- 图1 -->
    <custom-dialog :title="'修改线索'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowAssociationStudentOne"
                   :before-close="doShowApplicationHide">
      <clew-add-form :threadInfo="threadInfo"
                     :isUpStudentsData="true"
                     @afterSuccess="afterSuccess">
      </clew-add-form>
    </custom-dialog>
    <custom-dialog title="线索跟进记录"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowClewForm"
                   :before-close="doShowFollowUpHide">
      <clew-follow-form :studentItem="studentItem"
                        @close="doShowFollowUpHide"
                        @afterSuccess="getManageMessageSearchPageStudentThreadList"></clew-follow-form>
    </custom-dialog>
    <custom-dialog title="跟单记录流水"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowRecordList"
                   :before-close="doShowFollowUpHide">
      <clew-follow-record-list :studentItem="studentItem"
                               :StudentThreadKey="StudentThreadKey"
                               @close="doShowFollowUpHide"></clew-follow-record-list>
    </custom-dialog>
    <custom-dialog title="筛选标签"
                   :visible.sync="isShowLabelPopup"
                   :before-close="closeLabelPopup">
      <input-label :labelInfo="queryParams"
                   :allLabelList="allLabelList"
                   @close="closeLabelPopup"
                   @checkedLabelSubmit="checkedLabelSubmit"
                   @afterSuccess="closeLabelPopup"></input-label>
    </custom-dialog>
    <custom-dialog title="学生转线索"
                   width="1090px"
                   :visible.sync="isShowStudnetToCluePopup"
                   :before-close="closeStudnetToCluePopup">
      <studnets-to-clue-form @close="doShowFollowUpHide"
                             @afterSuccess="closeStudnetToCluePopup"></studnets-to-clue-form>
    </custom-dialog>
  </div>
</template>
<script>
import { ManageMessageSearchPageStudentThreadList, GetLabelList, SaleRecycle, GetSaleList, RecruitImportStudentThread } from '../../../API/workbench.js';
import inputSearchContainBtn from '../../report/common/input-search-contain-btn';
import tableList from '../../common/table-list/index';
import followUpForm from '../follow-up-form.vue';
import getSaleList from '../form/getSale-list.vue';// 销售
import clewAddForm from '../../student/clew-add-form';
import studnetsToClueForm from '../form/studnets-to-clue-form.vue';// 学生转线索
import clewFollowForm from '../../recruit-students-workbenches/clue-list/clew-follow-form';
import clewFollowRecordList from '../../recruit-students-workbenches/clue-list/clew-follow-record-list';
import inputLabel from '../form/input-label';
export default {
  data () {
    return {
      isShowStudnetToCluePopup: false,
      isBatchDistribute: false,
      courseList: [],
      isShowLabelPopup: false, // 标签
      labelText: '', // 标签
      allLabelList: [],
      StudentThreadKey: 0,
      isShowRecordList: false,
      isShowClewForm: false,
      studentItem: null,
      threadInfo: {},
      isShowAssociationStudentOne: false, // 关联学生档案 1
      isShowSalesList: false, // 销售
      selectedKey: '', // 销售ID
      OLAPKey: '', // 线索ID
      tableType: 0,
      isShowFollowUp: false,
      selectedRecentlyDocumentary: '全部', // 最近跟单
      staffList: [], // 销售
      DocumentaryKeyValue: '',
      recentlyDocumentaryList: [
        {
          OLAPKey: 0,
          MainDemoName: '全部'
        },
        {
          OLAPKey: 1,
          MainDemoName: '0-30天'
        },
        {
          OLAPKey: 2,
          MainDemoName: '31-60天'
        },
        {
          OLAPKey: 3,
          MainDemoName: '61-90天'
        },
        {
          OLAPKey: 4,
          MainDemoName: '超过90天'
        }
      ],
      // 全部标签
      labelList: [],
      recruitStudentsList: [],
      queryParams: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        StartTime: '',
        EndTime: '',
        LabelKeyList: [], // 标签
        SalesKey: '',
        CurrentNodeKey: '', // 1-未联系;2-已联系;3-已诺访;4-已约课;5-已试课;6-已报名
        IsExportKey: 0
      },
      dataInfo: {},
      scheduleTitle: '登记诺访',
      LabelKeyValue: '',
      contactCountList: {
        NoContactCount: '0', // 未联系
        ContactedCount: '0', // 已联系
        PromiseVisitCount: '0', // 已诺访
        InvitedClassCount: '0', // 约试课
        TrialLessonCount: '0' // 已试课
      },
      tableColumns: [
        {
          label: '序号',
          width: 65,
          prop: 'Number',
          align: 'center',
          type: 'tableIndex',
          sortable: false
        },
        {
          label: '添加日期',
          width: 100,
          sortable: 'custom',
          prop: 'GenerateTime',
          type: 'text-item'
        },
        {
          label: '归属销售',
          prop: 'SalesKeyValue',
          width: 90,
          align: 'center',
          type: 'text-item',
          extend: {
            isClick: true,
            click: (rowData) => {
              this.doFollowClick(rowData);
            }
          }
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 110,
          align: 'left',
          type: 'text-item',
          sortable: false,
          extend: {
            type: 'expand',
            click: (rowData) => { this.doStudentsClick(rowData); }
          }
        },
        {
          label: '手机号',
          prop: 'MobilePhone',
          width: 110,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '节点',
          prop: 'CurrentNodeKeyValue',
          align: 'center',
          width: 90,
          type: 'text-item'
        },
        {
          label: '标签',
          prop: 'LabelNames',
          width: 150,
          align: 'left',
          type: 'text-item'
        },
        {
          label: '最近跟单',
          prop: 'FollowTime',
          width: 100,
          align: 'left',
          type: 'text-item'
        },
        {
          label: '最近跟单',
          prop: 'FollowContentValue',
          align: 'left',
          type: 'text-item',
          extend: {
            type: 'expand',
            click: (rowData) => { this.doFollowContentClick(rowData); }
          }
        },
        {
          label: '操作',
          prop: 'option',
          width: 110,
          type: 'table-btn',
          extend: {
            tableBtnGroup: [
              {
                name: '放入回收站',
                extend: {
                  isHide: (rowData) => {
                    return Number(rowData.FollowUpKey) != 0 && Number(rowData.IsAbandonKey) != 1;
                  },
                  click: (rowData) => {
                    this.recycleBinBtn(rowData);
                  }
                }
              }
            ]
          }
        }
      ],
      totalNum: 0
    };
  },
  components: {
    inputSearchContainBtn,
    tableList,
    followUpForm,
    getSaleList,
    clewAddForm,
    clewFollowForm,
    clewFollowRecordList,
    inputLabel,
    studnetsToClueForm
  },
  computed: {
    // 销售
    vStaffValue () {
      return this.DocumentaryKeyValue == '' ? '全部销售' : this.DocumentaryKeyValue;
    },
    vLabelValue () {
      return this.LabelKeyValue == '' ? '全部标签' : this.LabelKeyValue;
    }
  },
  created () {
    this.getManageMessageSearchPageStudentThreadList();
    this.loadData();
  },
  watch: {
  },
  activated () {
  },
  mounted () {
    this.$dialog.register(this, ['clewAddForm']);
  },
  methods: {
    // 导入
    importEvent (param) {
      const formData = new FormData();
      formData.append('file', param.file);
      // this.saveOldStudentEnterTemp(() => {
      RecruitImportStudentThread(formData).then(result => {
        console.log('导入成功', result.data);
        this.search();
        layer.alert('导入成功');
      }, error => {
        layer.alert(error.msg + ': ' + JSON.stringify(error.data));
      }).finally(() => {
        // this.showProgress = false;
        this.$refs.upload.clearFiles(); // 上传成功之后清除历史记录
      });
    },
    // 批量分配销售
    showisBatchDistribute () {
      this.isBatchDistribute = true;
    },
    // 批量分配销售 选中一行
    clickCheckBox (lists, rowData) {
      console.log('clickCheckBox', lists, rowData);
      this.courseList = lists;
    },
    // 情况批量选择
    emptySelection (rows) {
      this.$refs.tableListRef.$refs.multipleTable.clearSelection();
      this.$refs.tableListRef.checkBoxList = [];
      this.courseList = [];
      this.isBatchDistribute = false;
    },
    // 批量分配销售
    allocationSaleBtn () {
      if (this.courseList.length == 0) {
        layer.alert('请选择分配销售');
      } else {
        this.selectedKey = '';
        this.OLAPKey = '';
        this.isShowSalesList = true;
      }
      console.log('allocationSaleBtn66', this.courseList);
    },
    toggleSelection (rows) {
      this.emptySelection();
      console.log(rows, 'toggleSelection');
      this.isBatchDistribute = false;
    },
    labelClick () {
      this.queryParams.LabelKeyList = [];
      this.isShowLabelPopup = true;
    },
    // 关闭 标签 弹框
    closeLabelPopup () {
      this.isShowLabelPopup = false;
    },
    checkedLabelSubmit (a, list) {
      console.log(a, 'a');
      let text = '';
      a.forEach((item, index) => {
        if (index == 0) {
          text = item;
        } else {
          text = text + '|' + item;
        }
        console.log('item', item, index);
      });
      this.labelText = text;
      this.allLabelList = a;
      list.forEach(item => {
        item.forEach(child => {
          a.forEach(p => {
            if (child.LabelKeyValue == p) {
              this.queryParams.LabelKeyList.push(child.LabelKey);
              console.log(child, 'child.LabelKeyValue', p);
            }
          });
        });
      });
      this.closeLabelPopup();
      console.log(this.labelText, this.queryParams, 'this.allLabelList', this.allLabelList);
      this.getManageMessageSearchPageStudentThreadList();
    },
    doStudentsClick (rowData) {
      console.log(rowData, 'rowData');
      this.threadInfo = rowData;
      this.isShowAssociationStudentOne = true;
    },
    doShowApplicationHide () {
      this.isShowAssociationStudentOne = false;
    },
    // 根据标签筛选
    doSelectedStaff (obj) {
      console.log(obj, '标签');
      this.DocumentaryKeyValue = obj.MainDemoName;
      this.queryParams.SalesKey = obj.OLAPKey;
      this.queryParams.pageIndex = 0;
      this.getManageMessageSearchPageStudentThreadList();
    },
    tableSwitch (type) {
      this.tableType = type;
      this.queryParams.CurrentNodeKey = type;
      this.queryParams.pageIndex = 0;
      this.getManageMessageSearchPageStudentThreadList();
    },
    loadData () {
      let obj = {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号、首字母
        orderBy: 'IsLeaveKey', //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为姓名
        sequence: 'DESC', //	字符串	可选	ASC	数据源：GET	为空则默认顺序; ASC正序;DESC倒序
        IsLeaveKey: '' //	字符串	可选		数据源：GET	是否在职;空表示该条件不生效;0-离职;1-在职
      };
      GetSaleList(obj).then(
        (result) => {
          if (result.data && result.data.length > 0) {
            this.staffList = result.data;
          } else {
            this.staffList = [];
          }
          this.staffList.splice(0, 0, {
            OLAPKey: '',
            MainDemoName: '全部销售'
          });
          this.staffList.splice(1, 0, {
            OLAPKey: -1,
            MainDemoName: '未分配'
          });
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    closeForm () {
      this.isShowSalesList = false;// 销售
    },
    // 打开员工列表
    doFollowClick (rowData) {
      this.selectedKey = rowData.SalesKey;
      this.OLAPKey = rowData.OLAPKey;
      this.isShowSalesList = true;
    },
    onSelectedItem (type) {
      console.log('item0,', type);
      this.isShowSalesList = false;// 销售
      this.isBatchDistribute = false;
      if (type) {
        setTimeout(() => {
          this.getManageMessageSearchPageStudentThreadList();
        }, 1000);
      } else {
        this.getManageMessageSearchPageStudentThreadList();
      }
    },
    doFollowContentClick (item) {
      this.studentItem = item;
      this.StudentThreadKey = item.OLAPKey;
      this.isShowRecordList = true;
    },
    doShowFollowUpHide () {
      this.isShowRecordList = false;
      this.isShowFollowUp = false;
      this.isShowClewForm = false;
      this.isShowStudnetToCluePopup = false;
    },
    closeStudnetToCluePopup () {
      this.isShowStudnetToCluePopup = false;
      this.getManageMessageSearchPageStudentThreadList();
    },
    doStudnetsToClueClick () {
      this.isShowStudnetToCluePopup = true;
    },
    // 线索 数据查询
    getManageMessageSearchPageStudentThreadList () {
      ManageMessageSearchPageStudentThreadList(this.queryParams).then(result => {
        this.queryParams.IsExportKey = 0;
        this.totalNum = result.data.Total;
        this.recruitStudentsList = result.data.PageDataList;
        this.contactCountList.NoContactCount = Number(result.data.NoContactCount) ? Number(result.data.NoContactCount) : '-';// 未联系
        this.contactCountList.ContactedCount = Number(result.data.ContactedCount) ? Number(result.data.ContactedCount) : '-';// 已联系
        this.contactCountList.PromiseVisitCount = Number(result.data.PromiseVisitCount) ? Number(result.data.PromiseVisitCount) : '-';// 已诺访
        this.contactCountList.InvitedClassCount = Number(result.data.InvitedClassCount) ? Number(result.data.InvitedClassCount) : '-';// 约试课
        this.contactCountList.TrialLessonCount = Number(result.data.TrialLessonCount) ? Number(result.data.TrialLessonCount) : '-';// 已试课
        this.recruitStudentsList.forEach(o => {
          o.StudentKeyValue = o.StudentKeyValue ? o.StudentKeyValue : '-';
          o.MobilePhone = o.MobilePhone ? o.MobilePhone : '-';
          o.CurrentNodeKeyValue = o.CurrentNodeKeyValue ? o.CurrentNodeKeyValue : '-';
          o.LabelNames = o.LabelNames ? o.LabelNames : '-';
          o.FollowTime = o.FollowTime ? o.FollowTime : '-';
          // 销售IsAbandonKey
          o.SalesKeyValue = Number(o.IsAbandonKey) == 1 ? '<div>' + o.SalesKeyValue + '<span style="color:red;">弃</span></div>' : !o.SalesKeyValue ? '未分配' : o.SalesKeyValue;
          o.FollowContentValue = o.FollowContent ? '<div class="recently_documentary_class">' + o.FollowContent + '</div>' : '';
        });
        console.log(result, '线索 数据查询');
      }, error => {
        layer.alert(error.msg);
      });
    },

    // 导出
    doExportInfoClick (callBack) {
      if (this.recruitStudentsList.length > 0) {
        var newData = this.$utils.parseJson(this.queryParams);
        this.queryParams.IsExportKey = 1;
        this.queryParams.pageIndex = 0;
        this.queryParams.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '招生管理-全部线索',
          clist: [
            { title: '添加日期', cName: 'GenerateTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '归属销售', cName: 'SalesKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生名', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '手机号', cName: 'MobilePhone', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '节点', cName: 'CurrentNodeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '标签', cName: 'LabelNames', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '最近跟单', cName: 'FollowTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '最近跟单', cName: 'FollowContent', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        ManageMessageSearchPageStudentThreadList(this.queryParams, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.queryParams.IsExportKey = 0;
          this.queryParams.pageIndex = newData.pageIndex;
          this.queryParams.pageSize = newData.pageSize;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    doAddClueClick () {
      let obj = {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '添加学生线索',
        moduleName: 'clewAddForm',
        callBack: {
          afterSuccess: () => {
            this.getManageMessageSearchPageStudentThreadList();
          }
        }
      };
      this.$emit('openDialog', obj);
    },
    formatDate (date) {
      date = Date.new(date.replace(/-/g, '/'));
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
    },
    goTimerFun (num) {
      let timer = new Date();
      var date2 = new Date(timer);
      date2.setDate(timer.getDate() - num);
      var time2 = date2.getFullYear() + '-' + (date2.getMonth() + 1) + '-' + date2.getDate();
      return time2;
    },
    // 最近跟单
    chgSearchRecentlyDocumentaryValue (item) {
      console.log(item, 'item');
      let timer = new Date();
      let gotimer = new Date(timer.getFullYear() + '-' + (Number(timer.getMonth())));
      let goStartTimer = '';
      let goEndTimer = '';
      this.selectedRecentlyDocumentary = item.MainDemoName;
      switch (item.OLAPKey) {
        case 0:   // 全部
          goStartTimer = '';
          goEndTimer = '';
          break;
        case 1:  // 0-30天
          goStartTimer = this.formatDate(this.goTimerFun(30));
          goEndTimer = this.formatDate(timer.getFullYear() + '-' + (Number(timer.getMonth()) + 1) + '-' + (Number(timer.getDate())));
          break;
        case 2:    // 31-60天
          goStartTimer = this.formatDate(this.goTimerFun(60));
          goEndTimer = this.formatDate(this.goTimerFun(30));
          break;
        case 3:    // 61-90天
          goStartTimer = this.formatDate(this.goTimerFun(90));
          goEndTimer = this.formatDate(this.goTimerFun(60));
          break;
        case 4:   // 超过90天
          goStartTimer = '';
          goEndTimer = this.formatDate(this.goTimerFun(90));
          break;
        default:
          break;
      }
      this.queryParams.StartTime = goStartTimer;
      this.queryParams.EndTime = goEndTimer;
      this.queryParams.pageIndex = 0;
      console.log(goStartTimer, 'goStartTimer goEndTimer', goEndTimer, gotimer, 'this.queryParams', this.queryParams);
      this.getManageMessageSearchPageStudentThreadList();
    },
    // 放弃
    recycleBinBtn (rowData) {
      layer.confirm('确定放入回收站吗？', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              SaleRecycle(rowData.OLAPKey).then(result => {
                layer.close();
                layer.alert('放入成功');
                this.getManageMessageSearchPageStudentThreadList();
              }, error => {
                layer.alert(error.msg);
              });
            }
          }
        ]
      });
    },
    editBtn (rowData, title) {
      this.dataInfo = rowData;
      this.scheduleTitle = title;
      console.log(this.scheduleTitle, 'this.scheduleTitle this.dataInfo', this.dataInfo);
      this.isShowFollowUp = true;
    },
    afterSuccess () {
      this.isShowFollowUp = false;
      this.isShowAssociationStudentOne = false;
      this.$emit('afterSuccess');
      this.getManageMessageSearchPageStudentThreadList();
    },
    search () {
      this.queryParams.pageIndex = 0;
      this.getManageMessageSearchPageStudentThreadList();
    },
    clearExtendSearchParams () {
      this.tableType = 0;
      this.selectedRecentlyDocumentary = '全部';
      this.DocumentaryKeyValue = '全部销售';
      this.LabelKeyValue = '全部标签';
      this.labelText = '';
      this.allLabelList = [];
      this.queryParams = {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: '',
        StartTime: '',
        EndTime: '',
        LabelKeyList: [], // 标签
        SalesKey: '',
        CurrentNodeKey: '', // 1-未联系;2-已联系;3-已诺访;4-已约课;5-已试课;6-已报名
        IsExportKey: 0
      };
      this.getManageMessageSearchPageStudentThreadList();
    }
  }
};
</script>

<style scoped>
.search_clue_div {
  margin-bottom: 10px;
  line-height: 70px;
  align-items: center;
  margin-top: -10px;
  min-height: 100px;
}
.clue_content_class_two {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.general_summarizing_student_navUl {
  height: 37px !important;
  border-bottom: none !important;
  margin: 0px !important;
}
.general_summarizing_student_navUl .title_list {
  padding: 0 !important;
  line-height: 37px !important;
  height: 37px !important;
  width: 120px;
  font-size: 14px !important;
  cursor: pointer;
  border: 1px solid #ececec !important;
}
.general_summarizing_student_navUl .opt {
  color: #3498db !important;
  border: 1px solid #3498db !important;
  background-color: rgba(52, 152, 219, 0.15);
  z-index: 2;
}
.schedule_table_class >>> .el-table .recently_documentary_class {
  width: 99%;
  max-height: 84px;
  line-height: 54px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  right: 1px;
  z-index: 13;
  border: 1px solid transparent !important;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 40px;
  color: #333 !important;
}
.schedule_table_class >>> .el-table .recently_documentary_class::after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -11.5px;
  right: 20px;
  width: 21px;
  height: 21px;
  padding-right: 17px;
  z-index: 2;
  background: url(../../../../public/image/course_header.png) no-repeat 50%;
  background-size: 18px 12px;
}
.schedule_table_class >>> .el-table .btn_red {
  color: red !important;
}
.label_input {
  border: 1px solid #ececec;
  width: 260px;
  height: 36px;
  border-radius: 3px;
  cursor: pointer;
  padding-left: 10px;
  background: url(../../../../public/image/more.png) no-repeat;
  background-size: 24px;
  background-position-x: 225px;
  background-position-y: 5px;
  z-index: 2;
  padding-right: 25px;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.label_input::after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -7.5px;
  right: 5px;
  width: 15px;
  height: 15px;
  padding-right: 17px;
  background: url(../../../../public/image/more.png) no-repeat center center;
  background-size: 15px;
  z-index: 2;
}
.batch_allocation_class {
  border: 1px solid #2e86c1;
  background-color: #3498db;
  color: #fff !important;
  cursor: pointer;
  line-height: 36px;
  text-align: center;
  height: 36px;
  border-radius: 4px;
  width: 110px;
  position: absolute;
  margin-top: 1px;
}
.batch_btn_delay {
  height: 36px;
  width: 110px !important;
  align-items: center;
  line-height: 35px;
  text-align: center;
  border-radius: 4px;
  background: #3498db url(../../../../public/image/batch_distribution_icon.png)
    no-repeat left 5px center;
  background-size: 16px;
  padding-left: 20px;
  position: static !important;
  margin-left: 20px;
}
.mendian_allocation_search :deep(.search_box) {
  width: 290px !important;
  margin-right: 30px !important;
  min-width: 290px !important;
}
.remove_db_btn {
  background-image: url(../../../../public/image/dialog_del_icon.png);
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 8px;
  margin-top: 3px;
}
.download_template_class {
  position: absolute;
  right: 380px;
  border: 1px solid #2e86c1;
  background-color: #3498db;
  color: #fff !important;
  cursor: pointer;
  line-height: 36px;
  text-align: center;
  height: 36px;
  border-radius: 4px;
  width: 85px;
}
.import_upload_class {
  position: absolute;
  right: 290px;
  border: 1px solid #2e86c1;
  background-color: #3498db;
  color: #fff !important;
  cursor: pointer;
  line-height: 36px;
  text-align: center;
  height: 36px;
  border-radius: 4px;
  width: 60px;
}
</style>

