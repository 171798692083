<template>
  <div class="icon_authorization_box"
       :class="minWidth?'magrinR':''">
    <el-tooltip effect="dark"
                :content="vTitleTip"
                placement="top-start">
      <div style="display: flex;">
        <div class="img_box">
          <img :src="vImgRequireIcon"
               class="icon_img" />
        </div>
        <div v-if="courseAuth.IsAuthKey==1"
             style="display: flex;">
          <div v-if="courseAuth.AuthorizeDeviceNum==0">
            <span v-if="modelType == 'A'"
                  class="text_style">不限</span>
            <span v-else
                  class="text_style">允许</span>
          </div>
          <div v-else>
            <span v-if="modelType == 'A'"
                  class="text_style">{{courseAuth.AuthCount+'/'+courseAuth.AuthorizeDeviceNum}}</span>
            <span v-else
                  class="text_style">允许</span>
          </div>
          <img :src="vIconLocation"
               class="location_icon"
               :class="minWidth?'iconMinr':''" />
        </div>
        <div v-else>
          <span class="text_style">禁用</span>
        </div>
      </div>
    </el-tooltip>
    <span v-if="minWidth&&courseAuth.type!='TV'"
          class="line_after"></span>
  </div>
</template>
<script>

export default {
  data () {
    return {
      typaName: ''
    };
  },
  props: {
    courseAuth: {
      type: Object,
      default: () => {
        return {
          IsAuthKey: 1, // 是否允许访问
          AuthorizeDeviceNum: 0, // 允许访问设备数
          AuthCount: 0, // 以授权访问数
          LocationLockKey: 1, // 是否定位锁定
          type: 'PC', // 类型
          LocationLockKeyValue: '定位开启'
        };
      }
    },
    modelType: {
      type: String,
      default: 'A'// A为校长模式B为老师模式
    },
    minWidth: {
      type: Boolean,
      default: false
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
    vImgRequireIcon () {
      let url = '';
      if (this.courseAuth.type == 'TV') {
        this.typaName = '电视';
        url = require('../../../../public/image/TV-icon.png');
      } else if (this.courseAuth.type == 'PC') {
        this.typaName = '电脑';
        url = require('../../../../public/image/PC-icon.png');
      } else if (this.courseAuth.type == 'APP') {
        this.typaName = '手机';
        url = require('../../../../public/image/APP-icon.png');
      }
      return url;
    },
    vIconLocation () {
      let url = '';
      if (this.courseAuth.LocationLockKey == '1') {
        url = require('../../../../public/image/lock.png');
      } else {
        url = require('../../../../public/image/all-way.png');
      }
      return url;
    },
    vTitleTip () {
      let textTip = '';
      if (this.courseAuth.IsAuthKey != '1') {
        textTip = this.typaName + '禁用';
        return textTip;
      } else {
        if (this.modelType != 'A') {
          if (this.courseAuth.LocationLockKey == '1') {
            textTip = this.typaName + '允许' + '，定位开启';
            return textTip;
          } else {
            textTip = this.typaName + '允许' + '，定位不限';
            return textTip;
          }
        } else {
          if (this.courseAuth.LocationLockKey == '1') {
            textTip = this.typaName + (this.courseAuth.AuthorizeDeviceNum == '0' ? '不限' : this.courseAuth.AuthCount + '/' + this.courseAuth.AuthorizeDeviceNum) + '，定位开启';
            return textTip;
          } else {
            textTip = this.typaName + (this.courseAuth.AuthorizeDeviceNum == '0' ? '不限' : this.courseAuth.AuthCount + '/' + this.courseAuth.AuthorizeDeviceNum) + '，定位不限';
            return textTip;
          }
        }
      }

      // return url;
    }
  },
  methods: {
  }
};
</script>
<style scoped>
.icon_authorization_box {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-right: 25px;
  margin-left: 5px;
  position: relative;
}
.magrinR {
  margin-right: 10px !important;
  height: 35px;
  align-items: center;
  line-height: 35px;
}
.iconMinr {
  margin-top: 12px !important;
}
.img_box {
  width: 20px;
  height: 20px;
  align-items: center;
  text-align: center;

  margin-right: 2px;
}
.icon_img {
  width: 14px;
  height: 14px;
}
.location_icon {
  width: 10px;
  height: 13px;
  margin-top: 1px;
  margin-left: 5px;
  margin-right: 10px;
}
.text_style {
  color: #3498db;
  font-size: 14px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.line_after {
  position: absolute;
  width: 1.01px;
  height: 14px;
  background: linear-gradient(0deg, #ececec, #ececec), #ffffff;
  position: absolute;
  right: 0;
  top: 10px;
}
</style>