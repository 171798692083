<template>
  <!-- 校区督学综合头部标题 -->
  <div :class="className">
    <div class="table_fixed_nav">
      <div v-for="item in titleList"
           :key="item.key"
           @click="chgTitleList(item)"
           class="table_fixed_nav_list"
           :class="{opt:item.isSelected}">{{item.name}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    className: {
      type: String,
      default: 'table_fixed_nav_box'
    },
    titleList: Array
  },
  created () {
  },
  methods: {
    // 切换门店列表
    chgTitleList (item) {
      this.titleList.forEach(o => {
        if (item.key == o.key) {
          o.isSelected = true;
        } else {
          o.isSelected = false;
        }
      });
      this.$emit('chgTitleList', item);
    }
  },
  computed: {

  }
};
</script>
