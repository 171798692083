<template>
    <div>
      <div v-show="!isLoading"
         class="list_drop_noData"
         :class="isShowlistDropBox?'list_drop_box':''">
      <div class="">拼命加载中</div>
     </div>
      <div v-show="isLoading" :class="isShowlistDropBox?'list_drop_box':''"
           :style="'padding-left:' + tablePaddingLeft">
        <div class="list_drop_banner"
             :style="'width:' + tableWidth">
                <table-list-common
                    :SchoolKey='Number(dataInfo.OLAPKey)'
                    :showPagePagination='false'
                    :plan="plan"
                    :retailInfo="dataInfo"
                    :monitorA="monitorA"
                    @flashData='flashData'
                    @loadShow='loadShow'
                    >
                </table-list-common>
        </div>
        <div class="list_drop_box_icon"
             :style="'left:' + iconPositionLeft">
        </div>
      </div>
    </div>
</template>
<script>

import textItem from '../column/text-item';
import student from '../column/student';
import arrangeSupport from '../column/arrange-support';
import dateItem from '../column/date-item';
import readingCourses from '../column/reading-courses';
import classHour from '../column/class-hour';
import amountItem from '../column/amount-item';
import numberItem from '../column/number-item';
import mdName from '../column/md-name';
import tableBtn from '../column/table-btn';
import hiddenBtn from '../column/hidden-btn';
import wechatSubscribe from '../column/wechat-subscribe';
import faceRecognition from '../column/face-recognition';
import multiLevelHead from '../column/multi-level-head';
import tableListCommon from '../../../head-quarters/courseware-authorization-management/courseware-package/table-list-common.vue'
export default {

  props: {
    monitorA: {
      type: Number,
      default: 0
    },
    tableData: Array, // 列表数据
    tableColumns: Array, // table column
    tablePaddingLeft: [String, Number], // 展开行距离左边的
    iconPositionLeft: [String, Number],
    tableWidth: [String, Number],
    downBoxText: String,
    isShowlistDropBox: {
      type: Boolean,
      default: true
    },
    dataInfo : {
      type: Object,
      default: []
    },
    plan : {
      type : String,
      default : 'C'
    }
  },
  data () {
    return {
      isLoading : false
    }
  },
  components: {
    textItem,
    student,
    arrangeSupport,
    dateItem,
    readingCourses,
    classHour,
    amountItem,
    numberItem,
    wechatSubscribe,
    faceRecognition,
    multiLevelHead,
    tableBtn,
    hiddenBtn,
    mdName,
    tableListCommon
  },
  computed: {
  },
  watch: {

  },
  methods: {
    downBoxClick () {
      this.$emit('downBoxClick');
    },
    flashData () {
      this.$emit('flashData');
    },
    loadShow () {
      this.isLoading = true
    }
  }
};
</script>

<style>
</style>