<template>
  <div class="header_fixed_list"
       :class="{opt:item.isSelected }"
       @click.stop="clickItem">
    <div class="list_icon"
         :class="item.iconClass"></div>
    <div class="list_name"
         :style="item.isCanClose?'':''">{{item.titleName}}</div>
    <div class="list_remove"
         v-if="item.isCanClose"
         @click.stop="closeEvent"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      headerBarList: [],
      isDelete: false
    };
  },
  props: {
    item: Object
  },
  computed: {
    vHeaderBarList () {
      return this.$store.getters.headerBarList;
    }
  },
  watch: {
    vHeaderBarList (n, o) {
      this.headerBarList = n;
    }
  },
  created () {
    this.headerBarList = this.$store.getters.headerBarList;
  },
  methods: {
    // 判断权限
    vHasModulePower (key) {
      return this.$utils.CheckModulePower(this.$store.getters.RolePowerList, key);
    },
    clickItem () {
      console.log(this.$store.getters.token, this.item, 'this.$store.getters.token');
      if (this.item.routerName == 'presidentKanban' && !this.vHasModulePower(63)) {
        this.$utils.CheckModulePowerToTips(63);
        return false;
      }
      if ((this.item.routerName == 'CourseTable' && !this.vHasModulePower(146)) && !this.vHasModulePower(47)) {
        var tips =
          '您暂无此权限，请联系系统管理员。此功能对应权限为：教务-> 课程表，或教务->课表维护';
        layer.alert(tips);
        return false;
      }
      if (this.item.routerName == 'teacherAttenClass' && this.$store.getters.token.IsTeacherKey != 1) {
        layer.alert('当前登录人不是老师');
        return false;
      }
      document.documentElement.scrollTop = 0;
      // 当前路由 置为选中状态
      this.item.isSelected = true;
      this.item.index = this.headerBarList.length;
      // 其他路由置灰
      this.headerBarList.forEach(obj => {
        if (obj.routerName != this.item.routerName) {
          obj.isSelected = false;
          if (obj.index >= this.item.index) {
            obj.index -= 1;
          }
        }
      });
      // 跳转到指定路由
      if (this.item.routerName != this.$route.name) {
        this.$router.push({ name: this.item.routerName });
        this.monitorSwitchHeaderBar();
      }
    },
    closeEvent () {
      if (this.isDelete) {
        return false;
      }
      this.isDelete = true;
      let findIndex = this.headerBarList.findIndex(obj => {
        return obj.routerName == this.item.routerName && obj.isCanClose;
      });
      if (findIndex >= -1) {
        this.headerBarList.splice(findIndex, 1);
        // this.$store.commit('decreaseHasOpenRouterList', this.item.routerName);
      }

      // 如果关的窗口 正在打开 则 返回上个路由
      if (this.item.routerName == this.$route.name) {
        let headerBarArr = this.$utils.parseJson(this.headerBarList);
        // headerBarArr.sort((a, b) => {
        //   return (b.index || 0) - (a.index || 0);
        // });
        console.log('findIndex', findIndex, this.headerBarList, headerBarArr);
        // var index= this.headerBarList.length-1
        // this.$router.push(this.headerBarList[index].routerName);
        this.$router.push(headerBarArr[0].routerName);
      }
      this.isDelete = false;
    },
    monitorSwitchHeaderBar () { // 监控切换headerBar
      this.$bus.emit('switchHeaderBar');
      switch (this.item.routerName) {
        case 'indexView':// 工作台
          this.$bus.emit('switchHeaderBarToIndexView');
          break;
        case 'CourseTable':// 课程表
          this.$bus.emit('switchHeaderBarToCourseTable');
          break;
        case 'presidentKanban':// 校长看板
          this.$bus.emit('switchHeaderBarToPresidentKanban');
          break;
        case 'mendiansettingform':// 门店设置
          this.$bus.emit('switchHeaderBarToMendiansettingform');
          break;
        case 'courseArrangement':// 排課管理
          this.$bus.emit('switchHeaderBarToCourseArrangement');
          break;
        case 'newCourseArrangement':// 排課管理
          this.$bus.emit('switchHeaderBarToNewCourseArrangement');
          break;
        case 'moreMendianReport':// 连锁门店统计
          this.$bus.emit('switchHeaderBarToMoreMendianReport');
          break;
        case 'managementAndStatistics':// 连锁门店统计
          this.$bus.emit('switchHeaderBarToManagementAndStatistics');
          break;
        case 'teacherAttenClass':// 工作台
          this.$bus.emit('switchHeaderBarToTeacherAttenClass');
          break;
        default:
          break;
      }
    }

  }
};
</script>

<style>
</style>