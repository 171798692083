<template>
  <div>
    <div class="course_statistics_table_search flex course_statistics_title"
         style="padding: 13px 0px;">
      <div class="head_options">
        <div class="option_auth"
             :class="searchObj.StatusKey==1?'opt':''"
             @click="changeType(1)">授权中</div>
        <div class="option_lose_efficacy"
             :class="searchObj.StatusKey==0?'opt':''"
             @click="changeType(0)">已失效</div>
      </div>
      <div class="course_statistics_search">

        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="vplaceholderTitle"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <!-- <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div> -->
      </div>
      <div class="heardItem">
        <!-- :class="ClientKey > 0?'PortWarnings':'PortWarning'" -->
        <div style="margin-top: 10px;"
             v-if="searchObj.StatusKey==1">授权过期预警 <span class="PortWarningText">{{PortWarningCount}}</span></div>
        <div v-else> </div>
        <function-btn :item="{ name: '批量续授权', ModulePowerKey: '' }"
                      :className="'el-button btn_light_blue btn_hover_bg_blue iconPlanC'"
                      style="width: 96px;"
                      @clickItem="showBanthAuthForm">
        </function-btn>
      </div>
    </div>
    <div class="table_list_content">
      <tableList class="summarizing_list"
                 :key="tableKey"
                 :tableData="vdataReportList"
                 :totalNum="totalNum"
                 :PageCount="PageCount"
                 :tableColumns="vtableCourseColumns"
                 :isSearchText="false"
                 :isNeedInnerScroll="false"
                 :defaultSort="defaultSort"
                 :queryParams="searchObj"
                 :footerContent="vFooterTotal"
                 @loadTableData="getDataList"></tableList>
    </div>
    <custom-dialog :title="'课件续费'"
                   :visible.sync="isShowCoursewareFormPopup"
                   :before-close="closeCoursewareFormPopup">
      <authorization-courseware-form :coursewareId="Number(coursewareInfo.OLAPKey)"
                                     :campusID="Number(coursewareInfo.ClientKey)"
                                     :isReadonly="false"
                                     @close="closeCoursewareFormPopup"
                                     @afterSuccess="afterSuccess"></authorization-courseware-form>
    </custom-dialog>
    <custom-dialog :title="titleName"
                   width="400px"
                   :visible.sync="isAuthDevicePop"
                   :before-close="closeCoursewareFormPopup">
      <authDevicePop :dataList="AuthDevicePopData"
                     @close="closeCoursewareFormPopup"
                     @afterSuccess="getAuthDevicePopSf">
      </authDevicePop>

    </custom-dialog>
    <custom-dialog :title="'授权明细'"
                   :visible.sync="showDetails"
                   :before-close="closeDetailsPopup">
      <courseware-record-details :AuthLotKey="AuthLotKey"
                                 @close="closeDetailsPopup"
                                 @afterSuccess="getDataList"></courseware-record-details>
    </custom-dialog>
    <custom-dialog :title="'批量续授权'"
                   :visible.sync="isShowbantchAuthFormPopup"
                   :before-close="closeCoursewareFormPopup">
      <bantch-rewal-auth-form @close="closeCoursewareFormPopup"
                              @afterSuccess="getDataList"></bantch-rewal-auth-form>
    </custom-dialog>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../../report/common/input-search-contain-btn';
import { MessageSearchPageAuthorizeRecord, AuthorizationCoursewareCloseAuth, UpdateAuthorizeDeviceNum } from '../../../../API/workbench';
import authorizationCoursewareForm from '../../headquarters-management/authorization-courseware/form/authorization-courseware-form';
import authDevicePop from '../../headquarters-management/authorization-courseware/form/location-form.vue';
import coursewareRecordDetails from '../../campus-courseware-management/report/courseware-record-details.vue';
import bantchRewalAuthForm from '../../headquarters-management/authorization-courseware/form/bantch-rewal-auth-form/index';

export default {
  data () {
    return {
      showDetails: false,
      AuthLotKey: 0,
      isAuthDevicePop: false,
      AuthDevicePopData: {},
      rowDataItem: null,
      titleName: '',
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        StatusKey: 1, // 1-授权中 0-已失效
        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          className: 'table_index_gray'
        },
        {
          label: '授权日期',
          prop: 'AuthorizeTime',
          width: ' ',
          minWidth: 120,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '校区名',
          prop: 'SchoolKeyValue',
          minWidth: 180,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '大课包名',
          prop: 'CoursewarePackageKeyValue',
          minWidth: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '子课包',
          prop: 'CoursewarePackgeCount',
          width: ' ',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            setClassName: (rowData) => {
              return rowData.AuthTypeKey != 1 ? 'font_blue' : 'font_black';
            },
            type: 'expand',
            click: (rowData) => {
              if (rowData.AuthTypeKey != 1) {
                console.log('111111111', rowData);
                this.openDetails(rowData);
              } else {
                console.log('asdadsadasd', rowData);
              }
              // console.log('asdadsadasd', rowData);
            }
          }

        },
        {
          label: '结算方式',
          prop: 'ClearingFormKeyValue',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '已用/总共',
          prop: 'UsageNumReleasedNum',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '剩余',
          prop: 'SurplusDayCount',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '设备防盗版管控',
          prop: 'InUseAuthorizeCount',
          width: 500,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'icon-authorization-colum',
          extend: {
            courseAuth: 'StudentNameKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: (rowData, type) => {
              console.log('设备防盗版管控', rowData, type);
              this.AuthClick(rowData, type);
            }
          }

        },

      
        {
          label: '端口状态',
          prop: 'StatusTypeKeyValue',
          sortable: false,
          minWidth: 100,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
          {
          label: '授权状态',
          prop: 'AuthorizeStatus',
          sortable: false,
          minWidth: 100,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },

      ],
      dataReportList: [],
      isShowCoursewareFormPopup: false,
      coursewareInfo: {},
      isShowbantchAuthFormPopup: false,
      PortWarningCount: 0
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
    authorizationCoursewareForm,
    authDevicePop,
    coursewareRecordDetails,
    bantchRewalAuthForm
  },
  props: {
    CoursewarePackageId: [Number, String],
    SchoolKey: [Number, String],
    monitorA: {
      // 由A模块引用 用于监听是否在模块外面操作了表格数据 如果操作将监听调用当前表格刷新时间
      type: Number,
      default: 0
    }
  },
  created () {
    console.log('SchoolKey', this.SchoolKey);
    console.log('CoursewarePackageId', this.CoursewarePackageId);
  },
  watch: {
    monitorA: {
      handler (c, o) {
        console.log('监听到数据变化 自动刷新页面');
        this.getDataList();
      }
    }

  },
  computed: {
    vplaceholderTitle(){
      return this.SchoolKey>0?'请输入大课包名':'请输入校区名'
    },
    vtableCourseColumns () {
      let newArr = this.tableCourseColumns;
      if (this.searchObj.StatusKey == 1) {
        let locaIndex = newArr.findIndex(o => {
          return o.label == '操作';
        });
        if (locaIndex >= 0) {
          newArr.splice(locaIndex, 1);
        }
        newArr.push({
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          width: 130,
          align: 'center',
          fixed: 'right',
          // headerAlign: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '续授权',
                extend: {

                  click: (rowData) => {
                    console.log('续期', rowData);
                    this.renewClick(rowData);
                    // this.showStudentFile(rowData);
                  },
                  ModulePowerKey: ''
                }
              },
              {
                name: '终止',
                extend: {
                  click: (rowData) => {
                    console.log('中止', rowData);
                    this.closeAuth(rowData);
                    // this.showStudentFile(rowData);
                  },
                  ModulePowerKey: ''
                }
              }

            ]
          }
        });
      } else {
        let locaIndex = newArr.findIndex(o => {
          return o.label == '操作';
        });
        if (locaIndex >= 0) {
          newArr.splice(locaIndex, 1);
        }
        newArr.push({
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          width: 100,
          align: 'center',
          fixed: 'right',
          // headerAlign: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '续授权',
                extend: {
                  isHide: (rowData) => {
                    return rowData.AuthorizeStatus =='终止授权';
                  },
                  click: (rowData) => {
                    console.log('续期', rowData);
                    this.renewClick(rowData);
                    // this.showStudentFile(rowData);
                  },
                  ModulePowerKey: ''
                }
              }
            ]
          }
        });
      }
      if (this.SchoolKey > 0) {
        let locaIndex = newArr.findIndex(o => {
          return o.label == '校区名';
        });
        if (locaIndex >= 0) {
          newArr.splice(locaIndex, 1);
        }
      }else{
        let locaIndex = newArr.findIndex(o => {
          return o.label == '大课包名';
        });
        if (locaIndex >= 0) {
          newArr.splice(locaIndex, 1);
        }
      }
      return newArr;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.CoursewarePackgeCount = o.AuthTypeKey == 1 ? '全部' : Number(o.CoursewarePackgeCount);
        if(o.ClearingFormKey==1){
          o.UsageNumReleasedNum = Number(o.UsageNum) + '/' + Number(o.ReleasedNum) + '次';
        }else if(o.ClearingFormKey==3){
          o.UsageNumReleasedNum = '-'
        }else{
          o.UsageNumReleasedNum = Number(o.UsageNum) + '/' + Number(o.ReleasedNum) + '天';
        }
       
        // o.SurplusDayCount = Number(o.SurplusDayCount) ;
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    }
  },
  mounted () {
    this.getDataList();
  },
  methods: {
    // 关闭
    closeFormPopup () {
      this.isShowCoursewareFormPopup = false;
      this.isShowbantchAuthFormPopup = false;
    },
    showBanthAuthForm () {
      console.log('showBanthAuthForm');
      this.isShowbantchAuthFormPopup = true;
    },
    // 关闭设备授权
    closeCoursewareFormPopup () {
      this.isShowCoursewareFormPopupDetail = false;
      this.isShowCoursewareFormPopup = false;
      this.isShowbantchAuthFormPopup = false;
      this.isShowBatchCoursewareFormPopup = false;
      this.isShowAuthorizationAeviceFormPopup = false;
      this.isAuthDevicePop = false;
      this.recordStatusPop = false;
    },
    getAuthDevicePopSf (data) {
      console.log('授权设备弹窗返回', data);
      // let param = {};
      data.PositionalAccuracyKey = data.IsLocationAccuracyKey;
      if (data.AuthorizeDeviceType == 3) {
        data.IsTVGatewayLogin = data.IsGatewayLogin;
        data.IsPCGatewayLogin = this.rowDataItem.IsPCGatewayLogin;
      } else {
        data.IsTVGatewayLogin = this.rowDataItem.IsTVGatewayLogin;
        data.IsPCGatewayLogin = data.IsGatewayLogin;
      }
      UpdateAuthorizeDeviceNum(data).then(res => {
        console.log('修改成功', res);
        this.afterSuccess();
      }, error => {
        layer.alert(error.msg);
      });
    },
    AuthClick (rowData, type) {
      this.rowDataItem = rowData;
      let title = 'PC端';
      let obj = {};
      if (type == 'PC') {
        title = 'PC端';
        obj = {
          IsAuthKey: Number(rowData.IsPCAuthKey),
          IsLocationLockKey: rowData.PCIsLocationLockKey,
          IsLocationLockKeyValue: Number(rowData.PCIsLocationLockKey) == 1 ? '开启' : '不限',
          IsLocationAccuracyKey: rowData.PCPositionalAccuracyKey,
          AuthorizeDeviceNum: rowData.AuthorizeDeviceNum,
          AuthorizeDeviceType: 1,
          AuthorizationCoursewareKey: rowData.OLAPKey,
          IsRecordKeyValue: Number(rowData.IsPCRecordKey) == 1 ? '是' : '否',
          IsRecordKey: rowData.IsPCRecordKey,
          IsGatewayLogin: rowData.IsPCGatewayLogin || 0,
          IsGatewayLoginValue: rowData.IsPCGatewayLogin == 1 ? '是' : '否'

        };
      } else if (type == 'APP') {
        title = 'APP端';
        obj = {
          IsAuthKey: rowData.IsAPPAuthKey,
          IsLocationLockKey: rowData.IsLocationLockKey,
          IsLocationLockKeyValue: Number(rowData.IsLocationLockKey) == 1 ? '开启' : '不限',
          IsLocationAccuracyKey: rowData.APPPositionalAccuracyKey,
          AuthorizeDeviceNum: rowData.APPAuthorizeDeviceNum,
          AuthorizeDeviceType: 2,
          AuthorizationCoursewareKey: rowData.OLAPKey,
          IsRecordKeyValue: Number(rowData.IsAPPRecordKey) == 1 ? '是' : '否',
          IsRecordKey: rowData.IsAPPRecordKey
        };
      } else {
        title = 'TV端';
        obj = {
          IsAuthKey: rowData.IsTVAuthKey,
          IsLocationLockKey: rowData.TVIsLocationLockKey,
          IsLocationLockKeyValue: Number(rowData.TVIsLocationLockKey) == 1 ? '开启' : '不限',
          IsLocationAccuracyKey: rowData.TVPositionalAccuracyKey,
          AuthorizeDeviceNum: rowData.TVAuthorizeDeviceNum,
          AuthorizeDeviceType: 3,
          AuthorizationCoursewareKey: rowData.OLAPKey,
          IsRecordKeyValue: '是',
          IsRecordKey: 1,
          IsGatewayLogin: rowData.IsTVGatewayLogin || 0,
          IsGatewayLoginValue: rowData.IsTVGatewayLogin == 1 ? '是' : '否'

        };
      }

      this.titleName = title;
      this.AuthDevicePopData = obj;
      this.isAuthDevicePop = true;
    },

    // 展开详情-续费按钮
    renewClick (rowData) {
      this.titleName = '课件续费';
      this.coursewareInfo = rowData;
      this.isShowCoursewareFormPopup = true;
    },
    closeAuth (rowData) {
      layer.confirm('是否终止授权', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              AuthorizationCoursewareCloseAuth(rowData.AuthKey).then(result => {
                this.afterSuccess();
              });
              layer.close();
            }
          }
        ]
      });
    },
    changeType (type) {
      console.log('changeType', type);
      this.searchObj.StatusKey = type;
      this.clearSearchCondition();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 触发按钮事件回调
    afterSuccess () {
      this.$emit('afterSuccess');
      this.getDataList();
    },

    getDataList (queryParams) {
      let fn = MessageSearchPageAuthorizeRecord;
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      fn(this.searchObj, this.CoursewarePackageId, this.SchoolKey).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        this.PortWarningCount = result.data.AuthorizationWarningCount;
        this.$emit('loadShow');
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    closeDetailsPopup () {
      this.showDetails = false;
    },
    openDetails (data) {
      this.AuthLotKey = data.OLAPKey;
      this.showDetails = true;
    }

  }
};
</script>
<style scoped>
.hover {
  cursor: pointer;
}
.head_options {
  width: 200px;
  margin-right: 20px;
  display: flex;
}
.option_auth {
  border: 1px solid #ccc;
  width: 80px;
  line-height: 36px;
  text-align: center;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  cursor: pointer;
}
.option_lose_efficacy {
  border: 1px solid #ccc;
  width: 80px;
  line-height: 36px;
  text-align: center;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
}
.option_auth.opt,
.option_lose_efficacy.opt {
  color: #3498db;
  border: 1px solid #3498db;
}
.heardItem {
  display: flex;
  width: 400px;
  justify-content: space-between;
}
.PortWarningText {
  padding: 4px 25px;
  margin-left: 8px;
  border: 1px solid #ececec;
  color: #ef8604;
  border-radius: 4px;
}
</style>