<template>
  <div class="signUpPayment clearfix" :class="{bg_gray:studentData.OLAPKey>0,signuppayment_readonly_type:isReadonly}">
    <student-info :studentKey="studentData.OLAPKey" :isReadonly="isReadonly" :isShowItem="1" @onSelectionStudent="doAfterSelectedStudent" @setCouponListForCustomer="setCouponListForCustomer"></student-info>
    <div v-if="studentData.OLAPKey>0">
      <project-content :studentData="studentData" :tableID="dataInfo.TableID" :guid="dataInfo.guid" :couponID="dataInfo.couponID" :couponList="couponList" @sigunupSuccess="doAfterSignupSuccess" ></project-content>
    </div>
  </div>
</template>

<script>
import studentInfo from './common/student-info/index';
import projectContent from './project-content/index';
import { getStudentHavingClass } from '../../../API/workbench.js';
export default {
  data () {
    return {
      studentData: {}, // 学生数据
      isReadonly: false, // 是否只读
      couponList: []
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: function () {
        return {
          StudentKey: 0,
          isReadOnly: false,
          TableID: 0,
          guid: '', // 记录ID
          couponID: 0 // 优惠券ID
        };
      }
    }
  },
  components: {
    studentInfo,
    projectContent
  },
  created () {
    this.studentData.OLAPKey = this.dataInfo.StudentKey;
    this.isReadonly = this.dataInfo.TableID > 0;
  },
  methods: {
    // 选中学生
    doAfterSelectedStudent (item) {
      this.studentData = item;
      this.loadReadingClassGradeByStudentKey();
    },

    // 加载指定学生在读班级
    loadReadingClassGradeByStudentKey () {
      getStudentHavingClass(this.studentData.OLAPKey).then(result => {
        if (result.data.length > 0) {
          this.studentData.isAddInClass = true;
        } else {
          this.studentData.isAddInClass = false;
        }
      });
    },
    // 客户下的券包
    setCouponListForCustomer (data) {
      this.couponList = data;
    },
    // 报名成功后
    doAfterSignupSuccess (formData, data) {
      this.$bus.emit('AfterSignUpPaymentSuccess', data);
      this.$emit('afterSucces');
      this.$emit('closesignUpPaymentDialog', 'signuppayment');
    },
    // 返回
    backClick () {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/' });
        return false;
      } else {
        this.$router.back(-1);
        setTimeout(() => {
          if (this.$route.name == 'applyRecord' || this.$route.name == 'renewfeewarning' || this.$route.name == 'oweclasshourwarning') {
            return false;
          } else {
            this.$router.push({ path: '/' });
          }
        }, 500);
      }
    }
  }
};
</script>

<style>

</style>