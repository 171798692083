<template>
  <div class="general_summarizing_student massive_bg">
    <!-- <div class="course_statistics_left_title"><span>课次明细</span></div> -->
    <div>
      <div class="course_statistics_table_search flex">
        <date-range ref="saveDate"
                    :searchObj="searchObj"
                    :fromTitle="'选择日期'"
                    :minDate="vMinDate"
                    :maxDate="this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0))"
                    @changeDate="changeSaveDate"></date-range>
        <div class="course_statistics_search">
          <!-- <div class="mendian_backstage_search">
            <div class="search_box">
              <div class="search_box_content">
                <div class="">
                  <div class="el-input el-input--small el-input--suffix is-focus">
                    <input type="text"
                           v-model="searchObj.searchText"
                           autocomplete="off"
                           placeholder="请输入"
                           class="el-input__inner">
                  </div>
                </div>
              </div>
            </div>
            <div class="btn_hover_bg_blue search_btn "
                 @click.stop="searchData">搜索</div>
            <div class="btn_hover_white search_clear"
                 @click.stop="clearSearchCondition">清除条件</div>
          </div> -->

          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入'"
                                    :clearAll="true"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="searchData"></input-search-contain-btn>
          <!-- <div class="mendian_backstage_search">
            <div class="btn_hover_bg_blue search_btn "
                 style="margin: 0;"
                 @click.stop="doExportInfoClick">导出</div>
          </div> -->
        </div>
      </div>
      <div class="course_statistics_table_box table_list_content">
        <!-- <list class="summarizing_list"
              :key="tableKey"
              :tableData="vdataReportList"
              :totalNum="totalNum"
              :PageCount="PageCount"
              :tableColumns="tableCourseColumns"
              :isSearchText="false"
              :isNeedInnerScroll="false"
              :defaultSort="defaultSort"
              :queryParams="searchObj"
              :showPagination="false"
              @tableBtnClick="doAfterTableBtnClick"
              @loadTableData="getDataList"></list> -->
        <table-list class="summarizing_list"
                    :tableData="vdataReportList"
                    :tableColumns="tableCourseColumns"
                    :totalNum="totalNum"
                    :PageCount="PageCount"
                    :queryParams="searchObj"
                    @loadTableData="getDataList"></table-list>
      </div>
    </div>
  </div>
</template>
<script>
import list from '../class-spend-statistics-analyze/components/table';
import dialogFactory from '../../common/dialog-factory';
import tableList from '../../common/table-list/index';
import inputSearchContainBtn from '../common/input-search-contain-btn';
import { MessageSearchChainSaaSClientReport } from '../../../API/workbench';
export default {
  data () {
    return {
      seletedCourseInfo: {},
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 0,
        searchText: '',
        orderBy: '',
        sequence: '',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0)) // this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd'),
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '门店ID',
          prop: 'Support20',
          minWidth: 120,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '门店名称',
          prop: 'MainDemoName',
          minWidth: 200,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '应到人次',
          prop: 'ArrangedNum',
          width: ' ',
          minWidth: 150,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '实到人次',
          prop: 'ArrivedNum',
          width: ' ',
          minWidth: 150,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '课消金额',
          prop: 'DeductAmount',
          width: ' ',
          minWidth: 200,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '报名人次',
          prop: 'ApplyNum',
          width: ' ',
          minWidth: 150,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        },
        {
          label: '报名金额',
          prop: 'ApplyAmount',
          width: ' ',
          minWidth: 200,
          sortable: 'custom',
          align: 'left',
          type: 'textItem'
        }

      ],
      dataReportList: []
    };
  },
  components: {
    list, dialogFactory, inputSearchContainBtn, tableList
  },
  props: {

  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.DeductAmount = Math.round(Number(o.DeductAmount || 0));
        o.ApplyAmount = Math.round(Number(o.ApplyAmount || 0));
        o.ApplyNum = Number(o.ApplyNum || 0);
        o.ArrivedNum = Number(o.ArrivedNum || 0);
        o.ArrangedNum = Number(o.ArrangedNum || 0);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      return '报名金额' + this.totalAmount + '元';
    }
  },
  created () {

  },
  mounted () {
    this.getDataList();
  },
  methods: {
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.classspendStatisticsAnalyze) {
        this.$refs.classspendStatisticsAnalyze.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    changeSaveDate (dataInfo) {
      console.log('changeSaveDate', dataInfo);
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      // this.searchObj.orderBy = '';
      this.searchObj.type = '';
      // this.searchObj.sequence = 'DESC';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.QueryID = 0;
      this.searchObj.IsExportKey = 0;
      this.$refs.saveDate.resetTime();
      this.searchData();
    },
    searchData () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    getDataList (queryParams) {
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      MessageSearchChainSaaSClientReport(this.searchObj).then(result => {
        this.PageCount = result.data.Total;
        this.totalAmount = Number(Number(result.data.totalDeductAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      this.seletedMDInfo = rowData;
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.popDialog:
          this.$refs.textareaPop.isAlterMessagePopup = true;
          break;
        case this.$initJson.baseFunctionBtnName.read:
          this.seletedCourseInfo = rowData;
          this.$refs.newAttendanceArrangeDialog.isAttendanceArrangeShow = true;
          this.$refs.newAttendanceArrangeDialog.getCourseInfo(this.seletedCourseInfo.OLAPKey);
          break;
        case this.$initJson.baseFunctionBtnName.edit:
          this.isShowChangeStatuDialog = true;
          break;
        default:
          break;
      }
    }
  }
};
</script>

