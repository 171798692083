<template>
  <!-- 门店列表 -->
  <div class="table_list_content summarizing_list">
    <div class="single_result_detail">

      <div class="after_sale_list">
        <div class="customer_table_follow">
          <div class="customer_table_box">
            <div class="table_title">跟进</div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('FollowStartTime',vtoday)">
              <span class="font_gray">今天</span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.FollowTodayCount,0)||0}}</span>
            </div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('FollowStartTime',vtomorrow)">
              <span class="font_gray">明天</span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.FollowTomorrowCount,0)||0}}</span>
            </div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('FollowEndTime',vyesterday)">
              <span class="font_gray">过期</span>
              <span class="table_number font_red">{{$utils.mAmountType(reportStatistics.FollowExpiredCount,0)||0}}</span>
            </div>
          </div>

          <div class="customer_table_box">
            <div class="table_name cp"
                 @click="doReportStatisticsClick('IsGradeStarKey',1)">
              <span class="table_icon01"></span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.GradeStarCount,0)||0}}</span>
            </div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('IsGradeVKey',1)">
              <span class="table_icon02"></span>
              <span class="table_number font_red">{{$utils.mAmountType(reportStatistics.GradeVCount,0)||0}}</span>
            </div>
          </div>

          <div class="customer_table_box">
            <div class="table_title">介绍</div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('StartCount',1)">
              <span class="font_gray">0-5</span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.IntroduceCount1,0)||0}}</span>
            </div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('StartCount',2)">
              <span class="font_gray">6-10</span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.IntroduceCount2,0)||0}}</span>
            </div>
            <div class="table_name cp"
                 @click="doReportStatisticsClick('StartCount',3)">
              <span class="font_gray">>11</span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.IntroduceCount3,0)||0}}</span>
            </div>
          </div>

          <div class="customer_table_box">
            <div class="table_title">新单</div>
            <div class="table_name">
              <span class="font_gray">今天</span>
              <span class="table_number">{{$utils.mAmountType(reportStatistics.TodayCount,0)||0}}</span>
            </div>
            <div class="table_name">
              <span class="font_gray">昨天</span>
              <span class="table_number">{{$utils.mAmountType(reportStatistics.TomorrowCount,0)||0}}</span>
            </div>
            <div class="table_name">
              <span class="font_gray">近30天</span>
              <span class="table_number">{{$utils.mAmountType(reportStatistics.Day30Count,0)||0}}</span>
            </div>
          </div>

          <div class="customer_table_box">
            <div class="table_name cp"
                 @click="doReportStatisticsClick('StayBack',1)">
              <span>介绍费</span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.Introduce,0)||0}}</span>
            </div>
          </div>

          <div class="customer_table_box"
               style="padding-left: 10px;">
            <div class="table_name cp"
                 @click="doReportStatisticsClick('AttenRate',1)"
                 style="width: 120px;">
              <span>考勤≤50%</span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.AttendRate50Count,0)||0}}</span>
            </div>
          </div>

          <div class="customer_table_box">
            <div class="table_name cp"
                 @click="doReportStatisticsClick('Renew',1)">
              <span>{{'续费<30天'}}</span>
              <span class="table_number font_blue">{{$utils.mAmountType(reportStatistics.RenewCount,0)||0}}</span>
            </div>
          </div>
        </div>
        <div class="flex flex_space_between">
          <div class="customer_table_record">
            <div class="customer_table_box table_title_lists"
                 style="padding-right: 30px;">
              <div class="table_title"
                   style="width: 80px;">新签校区</div>
              <div class="table_name">
                <span class="font_gray">本周</span>
                <span class="table_number">{{$utils.mAmountType(reportStatistics.WeekCount,0)||0}}</span>
              </div>
              <div class="table_name">
                <span class="font_gray">本月</span>
                <span class="table_number">{{$utils.mAmountType(reportStatistics.MonthCount,0)||0}}</span>
              </div>
            </div>

            <div class="customer_table_box table_title_lists"
                 style="padding: 0;">
              <div class="table_title"
                   style="width: 80px;margin-left: 10px;">销售金额</div>
              <div class="table_name"
                   style="width: 140px">
                <span class="font_gray">今天</span>
                <span class="table_number">{{$utils.mAmountType(reportStatistics.TodayAmountCount,0)||0}}</span><span class="font_gray">元</span>
              </div>
              <div class="table_name"
                   style="width: 140px">
                <span class="font_gray">本周</span>
                <span class="table_number">{{$utils.mAmountType(reportStatistics.WeekAmountCount,0)||0}}</span><span class="font_gray">元</span>
              </div>
              <div class="table_name"
                   style="width: 140px">
                <span class="font_gray">本月</span>
                <span class="table_number">{{$utils.mAmountType(reportStatistics.MonthAmountCount,0)||0}}</span><span class="font_gray">元</span>
              </div>
            </div>

            <div class="customer_table_box tc"
                 style="padding-left: 0;">
              <div class="table_title cp"
                   style="width: 180px"
                   @click="doReportStatisticsClick('IsBalance',1)">
                <span style="padding-right:5px;">尾款</span>
                <span class="table_number font_red">{{$utils.mAmountType(reportStatistics.BalanceAmount,2)||0}}</span><span class="font_gray">元</span>
              </div>
            </div>

          </div>
          <div class="pr">
            <input-search-contain-btn v-model="searchText"
                                      style="margin-top: 7px"
                                      :width="'280px'"
                                      :placeholder="'请输入'"
                                      @clearSearch="clearExtendSearchParams"
                                      @onSearchEvent="search"></input-search-contain-btn>
          </div>
        </div>
      </div>
      <table-list ref="tableListRef"
                  class="customer_mendian_list"
                  :tableData="tableData"
                  :tableColumns="tableColumns"
                  :rowKey="rowKey"
                  :totalNum="pageTotal"
                  :footerContent="'共' + pageTotal + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  @loadTableData="loadTableData"></table-list>
    </div>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
export default {
  data () {
    return {
      isTemporaryStu: false,  // 是否开启临时学生
      searchText: ''
    };
  },
  props: {
    reportStatistics: Object, // 统计
    tableData: Array, // 列表数据
    rowKey: String, // 显示详情对应绑定的ID
    pageTotal: Number, // 分页总共条数
    tableColumns: Array, // table column
    queryParams: Object,
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: '', order: 'ascending' };
      }
    }
  },
  components: {
    tableList,
    inputSearchContainBtn
  },
  computed: {
    modulePowerList () {
      return this.$store.getters.RolePowerList;
    },
    // 昨天的时间
    vyesterday () {
      var day1 = Date.new();
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000);
      let month = day1.getMonth() + 1;
      let date = day1.getDate();
      var s1 = day1.getFullYear() + '-' + (Number(month) < 10 ? '0' + month : month) + '-' + (Number(date) < 10 ? '0' + date : date);
      return s1;
    },
    // 今天的时间
    vtoday () {
      var day2 = Date.new();
      day2.setTime(day2.getTime());
      let month = day2.getMonth() + 1;
      let date = day2.getDate();
      var s2 = day2.getFullYear() + '-' + (Number(month) < 10 ? '0' + month : month) + '-' + (Number(date) < 10 ? '0' + date : date);
      return s2;
    },
    // 明天的时间
    vtomorrow () {
      var day3 = Date.new();
      day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000);
      let month = day3.getMonth() + 1;
      let date = day3.getDate();
      var s3 = day3.getFullYear() + '-' + (Number(month) < 10 ? '0' + month : month) + '-' + (Number(date) < 10 ? '0' + date : date);
      return s3;
    }
  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    // 统计搜索
    doReportStatisticsClick (type, value) {
      console.log(type, value, '统计搜索');
      this.searchText = '';
      this.$emit('searchStatistics', type, value);
      this.$refs.tableListRef.$refs.multipleTable.clearSort();
    },
    // 搜索
    search () {
      this.$emit('searchValue', this.searchText);
      this.$refs.tableListRef.$refs.multipleTable.clearSort();
    },
    // 清除条件
    clearExtendSearchParams () {
      this.$emit('clearSearchParams');
      this.$refs.tableListRef.$refs.multipleTable.clearSort();
    },
    // 排序
    loadTableData (queryParams) {
      this.$emit('loadTableData', queryParams);
    }
  }
};
</script>
<style scoped>
.search_top .el-select .el-input .el-select__caret {
  color: #333;
  font-size: 8px;
  position: relative;
  top: -1px;
  left: 13px;
  /* border-left: 1px solid #e6e6e6; */
}
.table_title_lists .table_title {
  width: 80px !important;
}
</style>

