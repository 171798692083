<template>
  <!-- 云课认证 -->
  <div>
    <div class="YK_added_view bg_white"
         v-if="isShowCloudClass=='form'">
      <!-- 新增表单 -->
      <div class="YK_added_box">
        <div class="YK_added_title">
          <div class="YK_added_step"
               :class="{opt:obj.isShow,complete:index<(formOpenItem.id-1)}"
               v-for="(obj,index) in formTitlePlan"
               :key="obj.id">
            <span class="title_number">{{obj.id}}</span>
            <span class="title_name">{{obj.name}}</span>
          </div>
        </div>
        <div class="YK_added_content">
          <div class="YK_added_content_name">
            {{formOpenItem.name}}
          </div>
          <!-- 公司信息 -->
          <company-info v-if="formTitlePlan[0].isShow"
                        :dataInfo="ykPutAwayMsg"
                        @saveSuccess="nextStep"></company-info>
          <!-- 品牌信息 -->
          <brand-info v-if="formTitlePlan[1].isShow"
                      :dataInfo="ykPutAwayMsg"
                      @saveSuccess="nextStep"
                      @preStep="preStep"></brand-info>
          <!-- 加盟信息 -->
          <league-info v-if="formTitlePlan[2].isShow"
                       :dataInfo="ykPutAwayMsg"
                       @saveSuccess="nextStep"
                       @preStep="preStep"></league-info>
          <!-- 课研与督学信息 -->
          <class-research-info v-if="formTitlePlan[3].isShow"
                               :dataInfo="ykPutAwayMsg"
                               @saveSuccess="saveSuccess"
                               @preStep="preStep"></class-research-info>

        </div>

      </div>
    </div>
    <div v-else>
      <cloud-class-details @changeType="changeType"></cloud-class-details>
    </div>
    <dialog-factory ref="cloudClassAttestation"
                    :routerName="'cloudClassAttestation'"></dialog-factory>
    <dialog-factory-common ref="cloudClassAttestationCommon"></dialog-factory-common>
  </div>
</template> 
<script>
import companyInfo from './company-info/index';
import brandInfo from './brand-info/index';
import leagueInfo from './league-info/index';
import classResearchInfo from './class-research-info/index';
import cloudClassDetails from './cloud-class-details/index';
import dialogFactory from '../../common/dialog-factory';
import { GetYKPutAwayMsg } from '../../../API/workbench';
export default {
  name: 'cloudClassAttestation',
  data () {
    return {
      formTitlePlan: [
        { id: 1, name: '公司信息', isShow: true },
        { id: 2, name: '品牌信息', isShow: false },
        { id: 3, name: '加盟信息', isShow: false },
        { id: 4, name: '课研与督学信息', isShow: false }
      ], // 表单标题 Details form
      isShowCloudClass: 'details',
      formOpenItem: {},
      ykPutAwayMsg: {} // 云课上架信息
    };
  },
  components: {
    companyInfo,
    brandInfo,
    leagueInfo,
    classResearchInfo,
    cloudClassDetails,
    dialogFactory
  },
  created () {
    if (this.$route.query && this.$route.query.showType) {
      this.isShowCloudClass = this.$route.query.showType;
    }
    if (this.isShowCloudClass == 'form') {
      this.loadYKPutAwayMsg();
      this.showCurrentForm();
    }
    this.registerBusEvent();
  },
  computed: {},
  methods: {
    loadYKPutAwayMsg () {
      GetYKPutAwayMsg().then((result) => {
        console.log(result.data, '云课上架信息');
        this.ykPutAwayMsg = result.data || {};
      });
    },
    // 下一步
    nextStep (data, nextStepData) {
      console.log(this.ykPutAwayMsg, '下一步-云课上架信息');
      this.ykPutAwayMsg = Object.assign(this.ykPutAwayMsg, data, nextStepData);
      let index = this.formTitlePlan.findIndex((obj) => {
        return obj.isShow;
      });
      this.formTitlePlan[index].isShow = false;
      this.formTitlePlan[index + 1].isShow = true;
      this.formOpenItem = this.formTitlePlan[index + 1];
    },
    // 上一步
    preStep () {
      let index = this.formTitlePlan.findIndex((obj) => {
        return obj.isShow;
      });
      this.formTitlePlan[index].isShow = false;
      this.formTitlePlan[index - 1].isShow = true;
      this.formOpenItem = this.formTitlePlan[index - 1];
    },
    changeType () {
      this.loadYKPutAwayMsg();
      this.formTitlePlan.forEach((o, index) => {
        if (index == 0) {
          o.isShow = true;
        } else {
          o.isShow = false;
        }
      });
      this.showCurrentForm();
      this.isShowCloudClass = 'form';
    },
    // 提交
    saveSuccess () {
      this.isShowCloudClass = 'details';
      // let item = {
      //   name: '云客上架',
      //   routerName: 'cloudClassDetails',
      //   auth: true,
      //   roleName: '',
      //   type: 6,
      //   isShow: true,
      //   moduleName: '',
      //   ModulePowerKey: 138,
      // }
      // this.$bus.emit('needOpenRouter', item)
    },
    // 显示当前表单
    showCurrentForm () {
      this.formOpenItem = this.formTitlePlan.find((o) => {
        return o.isShow;
      });
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'cloudClassAttestation') {
          if (this.$refs.cloudClassAttestation) {
            this.$refs.cloudClassAttestation.openDialog(
              dialogInfo.moduleName,
              dialogInfo.dataKey,
              dialogInfo.data,
              dialogInfo.callBack,
              dialogInfo.name
            );
          }
        }
      });

      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'cloudClassAttestation') {
          if (this.$refs.cloudClassAttestationCommon) {
            this.$refs.cloudClassAttestationCommon.openDialogPlus(
              dialogInfo.moduleName,
              dialogInfo.dataKey,
              dialogInfo.data,
              dialogInfo.callBack,
              dialogInfo.name || dialogInfo.title
            );
          }
        }
      });
    }
  }
};
</script>

<style>
.YK_added_view {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.YK_added_box {
  width: 1366px;
  min-height: 100%;
  padding: 0 113px;
  margin: 0 auto;
  position: relative;
}

.YK_added_title {
  display: flex;
  justify-content: space-between;
  padding: 40px 20px 0;
  margin-top: 60px;
  height: 96px;
  border-bottom: 1px solid #ececec;
}

.YK_added_step {
  font-size: 16px;
  line-height: 24px;
  color: #999999;
  display: flex;
  position: relative;
}

.YK_added_step .title_number {
  width: 24px;
  height: 24px;
  line-height: 22px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #999999;
  margin-right: 14px;
}

.YK_added_step.opt,
.YK_added_step.opt .title_number {
  color: #3498db;
  border-color: #3498db;
}

.YK_added_step::after {
  content: '';
  position: absolute;
  width: 158px;
  height: 1px;
  background-color: #dcdcdc;
  left: 117px;
  top: 11px;
}

.YK_added_step:last-child::after {
  display: none;
}

.YK_added_content {
  padding-left: 270px;
}

.YK_added_content_name {
  padding: 32px 0 24px;
  font-size: 24px;
  line-height: 1;
  font-weight: bold;
}

.YK_added_content_form .form_list {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  position: relative;
}

.YK_added_content_form .form_list.border_bottom {
  padding-bottom: 24px;
}

.YK_added_content_form .form_list_name {
  min-width: 133px;
  font-size: 14px;
  line-height: 35px;
  padding-right: 8px;
}
.YK_added_content_form .form_list_checkbox .checkbox_list .form_list_name {
  min-width: 50px;
}
.YK_added_content_form .form_list_name.required .text_box {
  position: relative;
}

.YK_added_content_form .form_list_name.required .text_box::after {
  content: '*';
  position: absolute;
  color: #ff6c67;
  right: -10px;
  line-height: 14px;
  top: 6px;
}

.YK_added_content_form .form_list_content {
  min-height: 35px;
  position: relative;
  padding-bottom: 2px;
}

.YK_added_content_form .form_list_content > .flex {
  margin-bottom: 24px;
}

.YK_added_content_form .form_list_content > .flex:last-child {
  margin-bottom: 0;
}

.YK_added_content_form .form_list_input {
  width: 400px;
  height: 35px;
  border: 1px solid #ececec;
  border-radius: 4px;
  padding-left: 10px;
}

.YK_added_content_form .form_list_input_box {
  position: relative;
  margin-right: 24px;
}

.YK_added_content_form .form_list_upload {
  display: flex;
}

.YK_added_content_form .form_list_upload .upload_btn {
  width: 120px;
  height: 120px;
  background: #eeeeee;
  border: 1px dashed #dcdcdc;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  padding-top: 60px;
  color: #999;
  margin-right: 40px;
  position: relative;
}

.YK_added_content_form .form_list_upload .upload_btn::before {
  content: '';
  position: absolute;
  width: 14px;
  height: 2px;
  background-color: #333;
  left: 50%;
  margin-left: -7px;
  top: 44px;
}

.YK_added_content_form .form_list_upload .upload_btn::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 14px;
  background-color: #333;
  left: 50%;
  margin-left: -1px;
  top: 38px;
}

.YK_added_content_form .form_list_upload .upload_text {
  margin-top: 8px;
  font-size: 12px;
  line-height: 20px;
  color: #999999;
}

.YK_added_bottom_placeholder {
  height: 72px;
}

.YK_added_bottom_btn_box {
  position: absolute;
  bottom: 60px;
  left: 113px;
  right: 113px;
  height: 72px;
  border-top: 1px solid #ececec;
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.YK_added_bottom_btn_box .btn_hover_bg_blue {
  width: 175px;
  height: 40px;
  font-size: 14px;
  line-height: 38px;
  margin-left: 20px;
  text-align: center;
  border-radius: 4px;
}

.YK_added_content_form .form_list_checkbox {
}

.YK_added_content_form .form_list_checkbox .checkbox_list {
  line-height: 35px;
  height: 35px;
  padding-left: 28px;
  min-width: 80px;
  position: relative;
  margin-bottom: 17px;
  cursor: pointer;
}

.YK_added_content_form .form_list_checkbox.flex .checkbox_list {
  margin-bottom: 0;
  padding-right: 20px;
}

.YK_added_content_form .form_list_checkbox .checkbox_list:last-child {
  margin-bottom: 0;
}

.YK_added_content_form .form_list_checkbox .checkbox_list::after {
  content: '';
  position: absolute;
  left: 0;
  top: 7px;
  width: 20px;
  height: 20px;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 2px;
}

.YK_added_content_form .form_list_checkbox .checkbox_list.opt::after {
  border-color: #3498db;
  background: #3498db url(../../../../public/image/tick_white@2x.png) center
    center no-repeat;
  background-size: 12px;
}
.YK_added_content_form .form_list_children_box {
  line-height: 35px;
  display: flex;
  margin-right: 24px;
  margin-bottom: 10px;
}

.YK_added_content_form .form_list_children_box:last-child {
  margin-bottom: 0;
}

.YK_added_content_form .form_list_children_box .children_name {
  min-width: 64px;
  padding-right: 10px;
}
.YK_added_content_form .form_list_input_box .children_name {
  max-width: 100px;
  padding-right: 10px;
  line-height: 35px;
}
.YK_added_content_form .form_list_children_box .children_input {
  width: 120px;
  height: 35px;
  border: 1px solid #ececec;
  border-radius: 4px;
  padding-left: 10px;
}

.YK_added_content_form .form_list_textarea textarea {
  width: 400px;
  height: 56px;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #ececec;
  border-radius: 4px;
  padding: 8px 10px;
}

.YK_added_content_form .form_list_switch {
  height: 35px;
  padding-top: 2px;
}

.YK_added_content_form .form_list_input_unit {
  position: absolute;
  right: 1px;
  top: 1px;
  bottom: 1px;
  color: #999;
  background-color: #f7f8f9;
  text-align: center;
  min-width: 58px;
  line-height: 33px;
}

.YK_added_content_form .el-input__inner {
  width: 400px;
  line-height: 33px;
  height: 35px;
  border: 1px solid #ececec;
  border-radius: 4px;
  padding-left: 10px;
  color: #3498db;
  position: relative;
}

.YK_added_content_form .form_course_block {
  width: 730px !important;
  margin-bottom: 24px;
  border: 1px solid #ececec;
  border-radius: 4px;
}

.YK_added_content_form .form_course_block .course_title {
  line-height: 48px;
  background: #fafafa;
  border-bottom: 1px solid #ececec;
  padding-left: 24px;
}

.YK_added_content_form .form_course_block .course_content {
  display: flex;
  padding: 24px 0;
  padding-left: 24px;
  position: relative;
}

.YK_added_content_form .form_course_block .course_content_img {
  width: 120px;
  height: 120px;
  border-radius: 4px;
  flex-shrink: 0;
  margin-right: 25px;
}

.YK_added_content_form .form_course_block .course_content_name {
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
  margin-bottom: 8px;
}

.YK_added_content_form .form_course_block .course_content_info {
  font-size: 14px;
  line-height: 16px;
  color: #666666;
  margin-bottom: 22px;
}

.YK_added_content_form .form_course_block .course_content_text {
  font-size: 14px;
  line-height: 19px;
  color: #999999;
  width: 400px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: normal;
}

.YK_added_content_form .form_course_block .course_content_amount {
  position: absolute;
  right: 30px;
  top: 53px;
  font-size: 30px;
  line-height: 34px;
  color: #ff8a01;
}

.YK_added_content_form .form_course_block .course_content_amount .font_unit {
  font-size: 18px;
}

.YK_added_content_form .form_course_block .course_bottom {
  height: 82px;
  padding-top: 24px;
  border-top: 1px solid #ececec;
}

.YK_added_content_form .form_course_block .course_bottom_btn {
  width: 113px;
  height: 35px;
  line-height: 29px;
  padding-left: 16px;
  color: #666;
  border: 1px dashed #dcdcdc;
  border-radius: 3px;
  margin: 0 auto;
}

.YK_added_content_form .form_course_block .course_bottom_btn .btn_icon {
  font-size: 20px;
  margin-right: 8px;
  color: #999;
}

.YK_added_content_form .form_list_subtitle {
  line-height: 14px;
  padding-left: 10px;
  position: relative;
  margin-bottom: 24px;
  font-weight: 600;
}

.YK_added_content_form .form_list_subtitle::after {
  content: '';
  position: absolute;
  left: 0;
  top: 1px;
  bottom: 1px;
  width: 2px;
  background-color: #3498db;
}

.YK_added_content_form .form_list_radio {
  display: flex;
}

.YK_added_content_form .form_list_radio .radio_list {
  position: relative;
  padding-left: 26px;
  line-height: 35px;
  min-width: 80px;
  max-width: 348px;
  cursor: pointer;
}

.YK_added_content_form .form_list_radio .radio_list::after {
  content: '';
  position: absolute;
  left: 0;
  top: 9px;
  width: 16px;
  height: 16px;
  border: 1px solid #dcdcdc;
  border-radius: 50%;
  box-sizing: border-box;
}

.YK_added_content_form .form_list_radio .radio_list.opt::after {
  border-color: #3498db;
}

.YK_added_content_form .form_list_radio .radio_list.opt::before {
  position: absolute;
  content: '';
  left: 4px;
  top: 13px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: #3498db;
}

.YK_added_content_form .form_list_add {
  width: 35px;
  height: 35px;
  position: absolute;
  border: 1px dashed #dcdcdc;
  border-radius: 3px;
  margin-left: -16px;
  font-size: 20px;
  color: #999999;
  line-height: 1;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
}
</style>

<style>
.YK_added_view .footer_btn_group.form_footer_btn_group {
  padding-right: 0;
  padding-left: 510px;
}

.YK_added_step.complete {
  color: #3498db;
}

.YK_added_step.complete::after {
  background-color: #3498db;
}

.YK_added_step.complete .title_number {
  color: transparent;
  background: url(../../../../public/image/complete_tick_icon.png);
  border: none;
}
.YK_added_view .YK_added_save_cancel_bottom_btn_box {
  position: absolute;
  left: 86px;
  right: 113px;
  height: 72px;
  border-top: 1px solid #ececec;
  padding-top: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.YK_added_view .YK_added_save_cancel_bottom_btn_box .form_footer_btn_group {
  padding-left: 785px !important;
}
</style>
