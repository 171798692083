<template>
  <div class="serach_ul flex">
    <date-range v-if="vShowDate"
      style="margin-right: 20px;"
      :searchObj="vSearchDataRange"
      :fromTitle="'付款日期'"
      @changeDate="changeDate"
    ></date-range>
    <el-select-option v-if="showSourseInfoList"
      style="margin-right: 20px; margin-left: 0px"
      class="serach_list"
      :selectTitle="'来源方式'"
      :dataSourceList="sourseInfoList"
      :selectionKey="params.sourceKey"
      @changeEvent="selectSourseList"
    ></el-select-option>
     <el-select-option v-if="vshowPaymentScheduleType"
      style="margin-right: 20px; margin-left: 0px"
      class="serach_list"
      :selectTitle="'付款进度'"
      :dataSourceList="paymentScheduleTypeList"
      :selectionKey="params.paymentScheduleKey"
      @changeEvent="selectPaymentList"
    ></el-select-option>
     
      <el-select-option  v-if="showCounselorList"
      style="margin-right: 20px; margin-left: 0px"
      :selectTitle="'招商顾问'" 
      :dataSourceList="counselorList" 
      :selectionKey="params.counselorKey"
      @changeEvent="chooseCounselor">
      </el-select-option>
    <el-select-option v-if="vshowAddType"
      style="margin-right: 20px; margin-left: 0px"
      class="serach_list"
      :selectTitle="'加盟方式'"
      :dataSourceList="addInfoList"
      :selectionKey="params.joinTheWay"
      @changeEvent="selectAddList"
    ></el-select-option>
     <el-select-option v-if="vshowPaymentType"
      style="margin-right: 20px; margin-left: 0px"
      class="serach_list"
      :selectTitle="'付款类型'"
      :dataSourceList="paymentTypeList"
      :selectionKey="params.PaymentTypeKey"
      @changeEvent="selectPaymentTypeList"
    ></el-select-option>
  
    <el-select-option  v-if="showEmployeesList"
      style="margin-right: 20px; margin-left: 0px"
      :selectTitle="'申请人'" 
      :dataSourceList="EmployeesList" 
      :selectionKey="params.counselorKey"
      @changeEvent="chooseCounselor">
    </el-select-option>
    <div class="pr" :style="vWidth">
      <input-search-contain-btn
        class="serach_input"
        v-model="params.searchText"
        :placeholder="'请输入'"
        @onSearchEvent="searchClick"
        @clearSearch="clearSearchParams"
      ></input-search-contain-btn>
      <div class="mendian_backstage_search" style="position:absolute;top:0;right:0">
        <div class="btn_hover_bg_blue search_btn "
                  style="margin: 0;"
                  @click.stop="doExportInfoClick">导出</div>
      </div>
    </div>
  </div>
</template>

<script>
import {hqAttractInvestmentSourceSet,GetCounselorList,GetOnJobEmployees} from '../../../API/workbench.js'
import elSelectOption from "../../report/common/el-select-option"; // 下拉选择框
import inputSearchContainBtn from "../../report/common/input-search-contain-btn";
export default {
  data() {
    return {
      counselorList:[],
      sourseInfoList: [],
      EmployeesList:[],
      addInfoList: [
        {
          OLAPKey: 0,
          MainDemoName: "全部",
        },
        {
          OLAPKey: 1,
          MainDemoName: "课程加盟",
        },
        {
          OLAPKey: 2,
          MainDemoName: "整店加盟",
        },
      ],
      paymentScheduleTypeList: [  //付款进度
        {
          OLAPKey: 0,
          MainDemoName: "全部",
        },
        {
          OLAPKey: 1,
          MainDemoName: "已签单",
        },
        {
          OLAPKey: 2,
          MainDemoName: "付定中",
        },
        {
          OLAPKey: 3,
          MainDemoName: "已付定",
        },
        {
          OLAPKey: 4,
          MainDemoName: "尾款中",
        },
        {
          OLAPKey: 5,
          MainDemoName: "全款中",
        },
        //  {
        //   OLAPKey: 6,
        //   MainDemoName: "已全款",
        // },
      ],
      paymentTypeList: [    //付款类型 付款类型1-定金;2-尾款;3-全款
        {
          OLAPKey: 0,
          MainDemoName: "全部",
        },
        {
          OLAPKey: 1,
          MainDemoName: "定金",
        },
        {
          OLAPKey: 2,
          MainDemoName: "尾款",
        },
        {
          OLAPKey: 3,
          MainDemoName: "全款",
        },
      ],
      selectSourseInfo: {
        OLAPKey: 0,
        MainDemoName: "全部",
      },
      addInfo: {
        OLAPKey: 0,
        MainDemoName: "全部",
      },
      paymentScheduleTypeInfo: {
        OLAPKey: 0,
        MainDemoName: "全部",
      },
      sourceTypeList:[], //来源列表
    };
  },
  props: {
    params: Object,
    tableType: Number,
    showAddType:{
      type:Boolean,
      default:false
    }
  },
  components: {
    elSelectOption,
    inputSearchContainBtn,
  },
  computed: {
    vWidth(){
      if(this.tableType==1||this.tableType==3){
        return {'width':'550px'}
      }else{
        return {'width':'100%'}
      }
    },
    vSearchDataRange() {
      return { startTime: this.params.StartTime, endTime: this.params.EndTime };
    },
    vShowDate(){
      if(this.tableType>=4){
        return true
      }else{
        return false
      }
    },
    vshowAddType(){
       if(this.tableType<=4){
        return true
      }else{
        return false
      }
    },
    paymentTitle(){
       if(this.tableType==2){
        return '付款类型'
      }else{
        return false
      }
      
    },
    // 显示付款进度
    vshowPaymentScheduleType(){
      if(this.tableType==2){
        return true
      }else{
        return false
      }
    },
    //显示付款类型
    vshowPaymentType(){
      if(this.tableType>3){
        return true
      }else{
        return false
      }
    },
    // 显示来源
    showSourseInfoList(){
      if(this.tableType!=4){
        return true
      }else{
        return false
      }
    },
    // 显示顾问
    showCounselorList(){
       if(this.tableType!=4){
        return true
      }else{
        return false
      }
    },
    // 显示申请人
    showEmployeesList(){
       if(this.tableType==4){
        return true
      }else{
        return false
      }
    }
  },
  created() {
    this.getCorseInfoList();
    this.loadSourceType()
    this.loadAdvisorList()
    this.loadEmployeesList()
  },
  methods: {
    //获取投资顾问列表
    loadAdvisorList(){
      GetCounselorList().then(result=>{
        this.counselorList = result.data;
        this.counselorList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
      });
    },
     //获取投资顾问列表
    loadEmployeesList(){
      GetOnJobEmployees().then(result=>{
        this.EmployeesList = result.data;
        this.EmployeesList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
      });
    },
    loadSourceType(){
      hqAttractInvestmentSourceSet().then(result => {
        this.sourseInfoList = result.data||[];
        this.sourseInfoList.unshift({
          MainDemoName: '全部',
          OLAPKey: 0
        });
        console.log(this.sourseInfoList, '查询所有招商来源设置');
      }, error => {
        layer.alert(error.msg);
      });
    },
    changeDate(data) {
      this.params.StartTime = data.startTime;
      this.params.EndTime = data.endTime;
      this.$emit("searchClick");

    },
    selectCorseList(data) {
      this.selectCorseInfo = data;
      this.params.IndustryKey = data.OLAPKey;
      this.$emit("searchClick");
    },
    // 选择来源
    selectSourseList(data) {

      this.params.sourceKey = data.OLAPKey;
      this.$emit("searchClick");

    },
    // 选择付款类型
    selectPaymentTypeList(data) {

      this.params.PaymentTypeKey = data.OLAPKey;
      this.$emit("searchClick");
    },
    // 选择加盟类型
    selectAddList(data) {
      this.params.joinTheWay = data.OLAPKey;
      this.$emit("searchClick");
    },
    // 选择付款进度
    selectPaymentList(data) {
      this.params.paymentScheduleKey = data.OLAPKey;
      this.$emit("searchClick");
    },
      //选择投资顾问
    chooseCounselor(item){
      this.params.counselorKey = item.OLAPKey;
      this.$emit("searchClick");
    },
    searchClick() {
      this.$emit("searchClick");
    },
    clearSearchParams() {
      this.$emit("clearSearchParams");
    },
    getCorseInfoList() {
      // this.$api.GetNotDelIndustry().then(
      //   (res) => {
      //     res.data.forEach((e) => {
      //       this.corseInfoList.push(e);
      //     });
      //   },
      //   (err) => {
      //     console.log(err);
      //   }
      // );
    },
    doExportInfoClick(){
      this.$emit("doExportInfoClick")
    }
  },
};
</script>

<style>
.serach_ul {
  padding: 15px 0 20px;
  flex-wrap: wrap;
}

.serach_list {
  width: 222px;
  margin-bottom: 20px;
}

.serach_list:first-child {
  /* margin-right: 20px; */
}

.serach_input {
  /* margin-left: 20px; */
}
</style>