<template>
  <div class="margin_left_right_div">
    <div class="growth_time_view scroll_type massive_bg template_minOrmax_width_div"
         style="margin: 0 auto;width: auto;">
      <leftContent @seletedItem="seletedItem"
                   @searchData="searchDataAfter"></leftContent>
      <rightContent :studentInfo="studentInfo"
                    v-if="studentInfo"></rightContent>
      <dialog-factory ref="growthTime"
                      :routerName="'growthTime'"></dialog-factory>
      <dialog-factory-common ref="growthTimeDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import leftContent from './left-content';
import rightContent from './right-content';
import dialogFactory from '../common/dialog-factory';
export default {
  name: 'growthTime',
  data () {
    return {
      studentInfo: null
    };
  },
  components: {
    leftContent, rightContent, dialogFactory
  },
  mounted () {
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'growthTime') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'growthTime') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });
  },
  methods: {
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.growthTime) {
        this.$refs.growthTime.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.growthTimeDialogFactoryCommon) {
        this.$refs.growthTimeDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    },
    seletedItem (item) {
      this.studentInfo = item;
      // this.$refs.rightContent.getData(item);
    },
    searchDataAfter () {
      this.studentInfo = null;
    }
  }

};
</script>
