<template>
  <!-- 排课创收潜力挖掘 - 校区饱和排课设置 指定的排课设置-->
  <div class="saturationCourse_st">
    <div class="content_marginRL content_marginTop_30">
      <div class="saturation_course_top">
        <div class="saturation_course_name">{{ dateInfo.MainDemoName }}</div>
      </div>
      <div class="saturation_course_content">
        <div class="index_title pr">
          <span>周饱和排课</span>
          <div class="right_surFlow"
               @click="UpdateClick('周饱和排课',dateInfo.ArrangeCourseNum)"><span>{{Number(dateInfo.ArrangeCourseNum)}}课次/周</span>
            <span class="second_content_list"></span>
          </div>
        </div>
        <div class="index_title pr">
          <span>每节课饱和人次</span>
          <div class="right_surFlow"
               @click="UpdateClick('每节课饱和人次',dateInfo.CourseStudentNum)"><span>{{Number(dateInfo.CourseStudentNum)}}人/课次</span>
            <span class="second_content_list"></span>
          </div>
        </div>
        <div class="index_title pr">
          <span>周饱和排课</span>
          <div class="right_surFlow"
               style="cursor: revert; color: rgba(51, 51, 51, 1);"><span>{{Number(dateInfo.ArrangeCourseStudentNum)}}人/周</span>
          </div>
        </div>
      </div>
    </div>
    <!--  修改数据 -->
    <custom-dialog title="修改数据"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowUpdateNum"
                   :before-close="closeUpdateNumPopup">
      <update-setting :formTitle="'修改数据'"
                      :updateText="updateText"
                      :updateTitle="updateTitle"
                      :dateInfo="dateInfo"
                      :isAttendance="false"
                      @cancelClick="cancelClick"
                      @afterSuccess="afterSuccess">
      </update-setting>
    </custom-dialog>
  </div>

</template> 

<script>
import updateSetting from './update-setting';
import { GetSchoolRoomSettingInfo } from '../../../../API/workbench';
export default {
  props: {
    OLAPKey: [Number, String]
  },
  data () {
    return {
      isShowUpdateNum: false,
      updateText: '',
      updateTitle: '',
      dateInfo: {
        ArrangeCourseNum: '', // 课次/周
        CourseStudentNum: '', // 人/课次
        ArrangeCourseStudentNum: '', // 人/周
        MainDemoName: '',
        OLAPKey: ''
      }
    };
  },

  components: {
    updateSetting
  },
  computed: {
  },
  watch: {
  },
  created () {
    console.log(this.OLAPKey, 'OLAPKey222');
    this.getGetSchoolRoomSettingInfo();
  },
  mounted () {
    this.$dialog.register(this, ['']);
  },
  methods: {
    getGetSchoolRoomSettingInfo () {
      GetSchoolRoomSettingInfo(this.OLAPKey).then(result => {
        if (result.data) {
          this.dateInfo = result.data;
        }
        console.log(result.data, '获取教室设置信息22 GetSchoolRoomSettingInfo');
      });
    },
    closeUpdateNumPopup () {
      this.isShowUpdateNum = false;
    },
    UpdateClick (title, text) {
      this.updateTitle = title;
      this.updateText = text;
      this.isShowUpdateNum = true;
    },
    cancelClick () {
      this.isShowUpdateNum = false;
    },

    afterSuccess () {
      this.isShowUpdateNum = false;
      this.getGetSchoolRoomSettingInfo();
    }
  },
  destroyed () {
    this.$emit('afterSuccess');
  }
};
</script>

<style scoped>
.saturationCourse_st {
  height: 327px;
}
.saturation_course_name {
  font-family: Alibaba PuHuiTi;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  color: rgba(51, 51, 51, 1);
  margin: 10px;
}
.saturation_course_content {
  width: 387px;
  margin: 0 auto;
  height: 315px;
}
.index_title {
  font-size: 14px;
  line-height: 50px;
  display: flex;
  width: 380px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(236, 236, 236, 1) !important;
  padding-left: 0px !important;
}
.right_surFlow {
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 26px;
  color: #3498db;
}
.second_content_list::after {
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  right: 6px;
  top: 17px;
  background: url(../../../../../public/image/next_icon@2x.png) no-repeat right
    center;
  background-size: 7px;
}
</style>