<template>
  <!-- 已上架的优惠券 -->
  <div class="">
    <div class="list_status"
         v-if="Number(dataInfo.online)==1">
      <div class="list_status_text">已上架</div>
    </div>
    <coupon-content-box :dataInfo="dataInfo"></coupon-content-box>
    <div class="list_bottom">
      <coupon-number-box :dataInfo="dataInfo"
                         @doAlreadyReceivedClick="doAlreadyReceivedClick"
                         @doHaveBeenUsedClick="doHaveBeenUsedClick"></coupon-number-box>
      <coupon-operate-box :dataInfo="dataInfo"
                          @sendClick="sendClick"
                          @editClick="editClick"
                          @moreClick="moreClick"></coupon-operate-box>
    </div>
  </div>
</template>
<script>

import couponContentBox from '../common/coupon-content-box';
import couponNumberBox from '../common/coupon-number-box';
import couponOperateBox from '../common/coupon-operate-box';
export default {
  components: { couponContentBox, couponNumberBox, couponOperateBox },
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object, // 券信息
    dataIndex: Number
  },
  created () {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    doAlreadyReceivedClick (data) {
      this.$emit('doAlreadyReceivedClick', data);
    },
    doHaveBeenUsedClick (data) {
      this.$emit('doHaveBeenUsedClick', data);
    },
    sendClick (data) {
      this.$emit('sendClick', data);
    },
    editClick (data) {
      this.$emit('editClick', data);
    },
    moreClick (data) {
      this.$emit('moreClick', data);
    }
  }
};
</script>

