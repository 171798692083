<template>
  <div>
    <div class="form_info_edit form_info_list single_shadow content_marginRL content_marginTop_30">
      <div class="form_info_edit">
        <input-number :min="0"
                      :formTitle="'剩余数量'"
                      v-model="CourseCount"
                      :placeholder="'必填'"
                      :decimalPlaces="1"
                      :required="true"></input-number>
      </div>
      <div class="form_info_edit">
        <input-text :formTitle="'处理原因'"
                    :readonly="false"
                    :formPlaceholder="'选填'"
                    :required="false"
                    v-model="Remarks"></input-text>
      </div>
    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="buttonClickEvent"
                           @cancelClick="closeDialog"></save-cancel-btn-group>
  </div>
</template>

<script>
// 选择框 作为公共组件
import { UpdateClassCount } from '../../../../API/workbench.js';
export default {
  props: ['residueCourseCount', 'formItem', 'StudentKey'],
  data () {
    return {
      CourseCount: 0,
      Remarks: '' // 处理原因
    };
  },
  components: {},
  created () {
    this.CourseCount = this.residueCourseCount.toFixed(1);
  },
  methods: {
    // 确定按钮
    buttonClickEvent (callBack) {
      if (!this.StudentKey) {
        return;
      }
      // 更改课时
      UpdateClassCount(
        this.formItem.TableID,
        this.CourseCount,
        this.Remarks,
        this.StudentKey
      ).then(result => {
        this.$emit('buttonClickEvent', this.formItem.TableID);
        this.$bus.emit('AfterEditCourseSingle', this.formItem.TableID);
        layer.alert('修改成功');
      })
        .catch(error => {
          layer.alert(error.msg);
        })
        .finally(() => {
          if (callBack) {
            callBack();
          }
        });
    },
    // 取消按钮
    closeDialog () {
      this.$emit('closeDialog', this.formItem.TableID);
    }
  }
};
</script>

  <style>
</style> 