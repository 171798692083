<template>
  <div>
    <div class="form_info_edit form_info_list">
      <input-text :formTitle="'校区名'"
                  :required="true"
                  :readonly="isReadonly"
                  v-model="dataJson.MainDemoName"></input-text>

      <form-select :formTitle="'校区类型'"
                   :required="true"
                   :readonly="isReadonly"
                   :dataItem="dataJson.TypeKeyValue"
                   :dataList="TypeList"
                   v-model="dataJson.TypeKeyValue"
                   @choseItem="changeTypeList"></form-select>
      <div id="screanContent"
           style="z-index:1;position: absolute !important;right: 45px; opacity: 0"></div>
      <input-text :formTitle="'管理员'"
                  :required="true"
                  :readonly="isReadonly"
                  v-model="dataJson.PrincipalName"></input-text>
      <input-text :formTitle="'管理员手机号'"
                  :required="true"
                  :readonly="isReadonly"
                  v-model="dataJson.MobilePhone"></input-text>
      <input-text :formTitle="'地址'"
                  :required="false"
                  :readonly="isReadonly"
                  v-model="dataJson.Address"></input-text>
    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
  </div>
</template>
<script>
import { AddCampusResource, EditCampusResource } from '../../../../API/workbench';
import formSelect from '../../../form-item/form-select';
export default {
  data () {
    return {
      TypeList: [
        { key: 1, value: '直营店' },
        { key: 2, value: '加盟店' },
        { key: 3, value: '课程合作' }
      ],
      // 提交数据对象
      dataJson: {
        MobilePhone: '', // "管理员手机号",
        PrincipalName: '', // 管理员
        TypeKey: '1', // 1-直营店;2-加盟店;3-课程合作
        TypeKeyValue: '直营店', // 1-直营店;2-加盟店;3-课程合作
        MainDemoName: '', // 校区名
        Address: '' // 地址
      }

    };
  },
  components: {
    formSelect
  },
  props: {
    dataInfo: Object,
    isReadonly: Boolean
  },
  created () {
    if (this.dataInfo) {
      this.dataJson.OLAPKey = this.dataInfo.OLAPKey;
      this.dataJson.MainDemoName = this.dataInfo.MainDemoName;
      this.dataJson.TypeKey = Number(this.dataInfo.TypeKey) || '1';
      this.dataJson.TypeKeyValue = this.dataInfo.TypeKeyValue || '直营店';
      this.dataJson.MobilePhone = this.dataInfo.MobilePhone;
      this.dataJson.PrincipalName = this.dataInfo.PrincipalName;
      this.dataJson.Address = this.dataInfo.Address;
    }
  },
  computed: {
  },
  methods: {
    // 类型显示
    changeTypeList (val) {
      this.dataJson.TypeKey = val.key;
      this.dataJson.TypeKeyValue = val.value;
    },
    verifyData () {
      let isFail = false;
      if (!this.dataJson.MainDemoName) {
        isFail = true;
        layer.alert('请输入校区名');
      }
      if (!this.dataJson.PrincipalName) {
        isFail = true;
        layer.alert('请输入管理员名字');
      }
      if (!this.dataJson.MobilePhone) {
        isFail = true;
        layer.alert('请输入管理员手机号');
      }
      return isFail;
    },
    confirmClick (callBack) {
      if (this.verifyData()) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (this.dataInfo) {       // 修改
        EditCampusResource(this.dataJson).then(result => {
          console.log('CWSUpdateStudent', result);
          this.$emit('afterSuccess', result.data);
          this.$emit('close');
        }).catch(error => {
          layer.alert(error.msg);
          if (callBack) {
            callBack();
          }
          console.log('errorCWSUpdateTeacher', error.msg);
        });
      } else {                 // 新增
        AddCampusResource(this.dataJson).then(result => {
          console.log('CWSStudentLeave', result);
          layer.alert('新增成功');
          this.$emit('afterSuccess', result.data);
          this.$emit('close');
        }).catch(error => {
          layer.alert(error.msg);
          if (callBack) {
            callBack();
          }
          console.log('errorCWSAddTeacher', error.msg);
        });
      }
    },
    cancelClick () {
      this.$emit('close');
    }
  }
};
</script>