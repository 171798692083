<template>
  <!--库存明细列表 -->
  <div class=" table_list_content">
    <div class="single_result_detail">
      <div class="inventory_details_head pr flex">
        <!-- 出库、入库、设置按钮点击操作 -->
        <div v-for="(item,index) in btnList"
             :key="index">
          <function-btn :item="item"
                        :className="'inventory_details_left_btn btn_hover_bg_blue'"
                        @clickItem="doBtnClick">
          </function-btn>
        </div>
        <div class="inventory_details_right_switch">
          <el-switch v-model="isStockSwitch"
                     @change="doInStockSwitch(paramsInfo.InStock)"></el-switch>
          <span>仅显示有库存的物品</span>
        </div>
      </div>
      <!-- 已选条件 -->
      <div class="course_statistics_table_search flex course_statistics_title">
        <div class="course_statistics_search">
          <div class="table_select_box"
               style="width: 300px;">
            <div class="table_select_title">物品类型</div>
            <el-select :value="vgoodsTypeValue"
                       filterable
                       placeholder="必选"
                       @change="chgSearchValue"
                       value-key="OLAPKey">
              <el-option v-for="item in goodsTypeList"
                         :key="item.OLAPKey"
                         :label="item.MainDemoName"
                         :value="item"></el-option>
            </el-select>
          </div>
          <div class="inventory_details_search_btn">
            <input-search-contain-btn v-model="searchText"
                                      :placeholder="'请输入'"
                                      @clearSearch="clearExtendSearchParams"
                                      @onSearchEvent="searchGoodsText"></input-search-contain-btn>
          </div>
          <div class="mendian_backstage_search">
            <function-btn :item="{ name: '导出', nameValue: 'export', ModulePowerKey: '' }"
                          :className="'search_btn btn_hover_bg_blue'"
                          style="margin:0"
                          @clickItem="doExportInfoClick">
            </function-btn>
          </div>
        </div>
      </div>
      <table-list :tableData="tableData"
                  class="summarizing_list table_list_content"
                  :tableColumns="tableColumns"
                  :rowKey="rowKey"
                  :totalNum="totalNum"
                  :defaultSort="defaultSort"
                  :footerContent="'共'+totalNum+'条数据'"
                  :queryParams="paramsInfo"
                  @loadTableData="loadTableData"></table-list>
    </div>
  </div>
</template>
<script>
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import tableList from '../../../common/table-list/index';
import goodsLeaveOrEnterForm from './goods-leave-or-enter-form';
import goodsSetting from './goods-setting/index';
import { GetGoodsTypeList } from '../../../../API/workbench.js';
export default {
  data () {
    return {
      searchText: '',
      isStockSwitch: true, // 是否显示有库存的物品
      goodsTypeList: [], // 物品类型
      btnList: [
        { name: '进货入库', nameValue: 'JHRK', TypeKey: 1, ModulePowerKey: '' },
        { name: '物品出库', nameValue: 'WPCK', TypeKey: 2, ModulePowerKey: '' },
        { name: '物品设置', nameValue: 'WPSZ', TypeKey: null, ModulePowerKey: '' }
      ]
    };
  },
  props: {
    tableData: Array, // 列表数据
    tableColumns: Array, // table column
    rowKey: [String, Number], // 显示详情对应绑定的ID
    totalNum: Number, // 分页总共条数
    paramsInfo: Object,
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: '', order: 'ascending' };
      }
    }
  },
  components: {
    inputSearchContainBtn,
    tableList,
    goodsLeaveOrEnterForm,
    goodsSetting
  },
  computed: {
    vgoodsTypeValue () {
      return this.paramsInfo.GoodsTypeKey > 0 ? this.paramsInfo.GoodsTypeKeyValue : '全部';
    }
  },
  created () {
  },
  mounted () {
    this.getGoodsTypeList();
  },
  methods: {
    // 导出
    doExportInfoClick () {
      this.$emit('doExportInfoClick');
    },
    // 出库、入库、设置（按钮）
    doBtnClick (item) {
      this.$emit('doBtnClick', item);
    },
    // 库存显示开关- 1-是;0-否
    doInStockSwitch (onOff) {
      console.log(onOff, '库存显示开关');
      this.paramsInfo.pageIndex = 0;
      this.paramsInfo.InStock = onOff > 0 ? 0 : 1;
      this.$emit('chgSearchValue', this.paramsInfo);
    },
    // 搜索
    searchGoodsText () {
      this.paramsInfo.searchText = this.searchText;
      this.paramsInfo.pageIndex = 0;
      this.$emit('chgSearchValue', this.paramsInfo);
    },
    // 清除
    clearExtendSearchParams () {
      this.searchText = '';
      this.paramsInfo.searchText = this.searchText;
      this.paramsInfo.GoodsTypeKey = '';
      this.paramsInfo.pageIndex = 0;
      this.$emit('chgSearchValue', this.paramsInfo);
    },
    // 选择物品类型
    chgSearchValue (obj) {
      console.log(obj, '选择搜索类型');
      this.paramsInfo.pageIndex = 0;
      this.paramsInfo.GoodsTypeKey = obj.OLAPKey;
      this.paramsInfo.GoodsTypeKeyValue = obj.MainDemoName;
      this.$emit('chgSearchValue', this.paramsInfo);
    },
    // 获取物品类型列表
    getGoodsTypeList () {
      GetGoodsTypeList().then(result => {
        this.goodsTypeList = result.data;
        this.goodsTypeList.unshift({ MainDemoName: '全部', OLAPKey: 0 });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 排序
    loadTableData (queryParams) {
      console.log(queryParams, '排序');
      this.paramsInfo.sequence = queryParams.sequence;
      this.paramsInfo.orderBy = queryParams.orderBy;
      this.$emit('chgSearchValue', this.paramsInfo);
    }
  }
};
</script>


