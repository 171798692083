<template>
  <div style="position:relative;width:351px">
    <div class="aside_ul_box">
      <div ref="asideBox"
           class="aside_ul">
        <!--    @mousedown.stop="startList"
        @mouseup.stop="endList"
        @mousemove.stop="moveList"
        @mouseleave="endList"
        :style="{ top: vSrcollY }" -->
        <list-item v-for="(item, index) in dataInfo.newCoursewareAttach"
                   :key="index"
                   :dataInfo="item"
                   @clickItem="selectAttach"></list-item>

        <div v-if="dataInfo.HomeWork && dataInfo.HomeWork.Content && dataInfo.HomeWork.Content.length > 0"
             class="aside_item_no button"
             @click.stop="showHomeWork">
          <div class="aside_item_pic">
            <img src="../../../../public/image/zyfm.png" />
          </div>
          <div class="aside_item_info">
            <div class="aside_item_info_content">{{dataInfo.HomeWork.Title}}</div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
import listItem from './list-item';
export default {
  data () {
    return {
      isStart: false,
      offsetTop: 0,
      scrollY: 0,
      currentScrollY: 0
    };
  },
  props: {
    dataInfo: Object
  },
  components: {
    listItem
  },
  computed: {
    vSrcollY () {
      if (this.scrollY > 0) {
        return Math.abs(this.scrollY) + 'px';
      } else {
        return '-' + Math.abs(this.scrollY) + 'px';
      }
    }
  },
  created () {
    console.log(this.dataInfo.newCoursewareAttach, 'dataInfo-created');
  },
  methods: {
    selectAttach (data) {
      this.$emit('selectAttach', data);
    },
    moveList (e) {
      if (this.isStart) {
        this.scrollY = this.currentScrollY + (e.clientY - this.offsetTop);
        if (this.scrollY > 0) {
          this.scrollY = 0;
        }
        if (this.scrollY < (856 - this.$refs.asideBox.clientHeight)) {
          this.scrollY = 856 - this.$refs.asideBox.clientHeight;
        }
      }
    },
    startList (e) {
      this.offsetTop = e.clientY;
      this.currentScrollY = JSON.parse(JSON.stringify(this.scrollY));
      this.isStart = true;
    },
    endList () {
      this.isStart = false;
    },
    showHomeWork () {
      this.$emit('showHomeWork');
    }
  }
};
</script>