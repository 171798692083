<template>
  <!-- 员工设置列表 -->
  <div>
    <!-- 模块重点说明 -->
    <module-description :textTips="'添加需要使用系统的员工。'"
                        :btnTips="'查看教程'"
                        :btnUrl="'https://mp.weixin.qq.com/s/HCSIf3FtU0hXslp7_DLqQw'"></module-description>
    <div class="content_marginTop_30">
      <span class="defult_dialog_header_btn fr"
            @click="doyaunGongAddClick()"
            :class="vHasModulePower(44) ? '' : 'norole'">
        <span class="btn_add">+</span>添加员工
      </span>
      <div id="screanContent"
           style="
          z-index: 1;
          position: absolute !important;
          right: 45px;
          opacity: 0;
        "></div>
      <div class="staff_manage_box clearfix"
           style="padding: 4px 4px 4px 8px"
           @scroll="scrollTOP"
           v-if="staffList.length > 0">
        <staff-List v-for="(item, index) in staffList"
                    :item="item"
                    :index="index"
                    :key="item.OLAPKey"
                    :isShowDimissionStaff="isShowDimissionStaff"
                    @selectImg="selectImg"
                    @doYaunGongClick="doYaunGongClick"></staff-List>

        <div class="course_student_attendance_operation module_content_menuList"
             :style="{ top: topValue + 'px', left: leftValue + 'px' }"
             v-if="isShowItem">
          <div class="top_bg"></div>
          <drop-down-box :optionJsonList="staffMenu"
                         @clickOptionForAlertDialog="onItemClick"></drop-down-box>
        </div>
      </div>
      <div v-else-if="staffList.length == 0">
        <div class="monitoring_content_nodata">
          <div class="monitoring_content_nodata_txt2">
            亲，当前没有任何数据哦~
          </div>
        </div>
      </div>
      <div class="staff_dimission_btn"
           v-if="isShowDimissionStaff"
           @click="isShowDimissionStaff = false">
        <span>显示离职员工</span>
      </div>
    </div>
    <custom-dialog :title="'裁切图片'"
                   class=""
                   :visible.sync="isChgPic"
                   :before-close="closeChgPic">
      <vue-crop-img :selectPicUrl="selectPicUrl"
                    @onConfirmClick="setPicCorp"
                    @onCancelClick="clearPicData"></vue-crop-img>
    </custom-dialog>
  </div>
</template>

<script>
import staffList from './staff-List';
import dropDownBox from '../../../common/drop-down-box';
import {
  MessageSearchPageForEmployee,
  EmployeeRecover,
  EmployeeDimission,
  editEmployeeHeadImg
} from '../../../../API/workbench';
export default {
  name: 'staffManagementList',
  data () {
    return {
      isReadonly: false, // 是否只读
      isShowDimissionStaff: false, // 是否显示离职员工
      staffList: [], // 员工列表
      editItem: {}, // 员工项
      staffMenu: [], // 操作菜单项
      // 查询员工
      parameter: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 0, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、手机号、首字母
        orderBy: 'IsLeaveKey', //	字符串	可选	MainDemoName	数据源：GET	排序字段 为空则默认为姓名
        sequence: 'DESC', //	字符串	可选	ASC	数据源：GET	为空则默认顺序; ASC正序;DESC倒序
        IsLeaveKey: '' //	字符串	可选		数据源：GET	是否在职;空表示该条件不生效;0-离职;1-在职
      },
      selectPicUrl: '', // 选中图片url
      crood2: {}, // 截图坐标之类
      isChgPic: false,
      preWHRatio: 1,
      nextElWidth: 0,
      nextElHeight: 0,
      nextWHRatio: 0,
      selectedCurrentTarget: null,
      isShowItem: false,
      newIndex: -1,
      topValue: 0, // 默认第一个top值
      leftValue: 0,
      toScrollTop: 0 // 滚动条的高度
    };
  },
  components: {
    dropDownBox,
    staffList
  },

  created () {
    this.loadData(true);
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener(
      'click',
      (e) => {
        this.isShowItem = false;
      },
      false
    );
    this.$dialog.register(this, ['yuanGongSetting']);
  },
  watch: {
    $route (to, from) {
      if (to.path != from.path) {
        this.toScrollTop = 0;
      }
      console.log(to.path, from.path, '路由');
    }
  },
  computed: {
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    }
  },
  methods: {
    closeChgPic () {
      this.isChgPic = false;
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(
        this.vModulePowerList,
        ModulePowerKey
      );
    },
    // 搜索
    doSearchTextClick () {
      this.loadData();
    },
    loadData (upFirst) {
      MessageSearchPageForEmployee(this.parameter).then(
        (result) => {
          this.isShowItem = false;
          if (upFirst) {
            result.data.PageDataList.forEach((o) => {
              if (Number(o.IsLeaveKey) == 0) {
                this.isShowDimissionStaff = true;
              }
            });
          }
          this.staffList = result.data.PageDataList;
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    // 去添加员工
    doyaunGongAddClick () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: 44,
        name: '添加员工',
        moduleName: 'yuanGongSetting',
        data: { staffID: 0, isReadonly: false },
        callBack: {
          afterSuccess: (type, data) => {
            this.loadData();
          }
        }
      });
      this.$store.commit('setShowTimetableId', 0);
    },
    // 选择员工项
    doYaunGongClick (item, index, e) {
      this.onSelectPopupItem(item);
      this.staffList.forEach((o) => {
        if (o.OLAPKey == item.OLAPKey) {
          this.isShowItem = true;
          this.doOneTopValueCount(index);
        }
      });
    },
    scrollTOP (e) {
      this.toScrollTop = e.target.scrollTop;
      if (e.target.scrollTop > 0) {
        this.isShowItem = false;
      }
    },
    // 计算TOP值
    doOneTopValueCount (index) {
      // console.log(index, 'index');
      this.newIndex = index;
      this.topValue = 175;
      let oneTopValue = 86;
      if (index == 0 || index % 2 == 0) {
        // 双数
        this.leftValue = 80;
        this.newIndex = index / 2;
      } else {
        this.leftValue = 285;
        this.newIndex = (index - 1) / 2;
      }
      if (this.newIndex < 0) {
        this.newIndex = 0;
      }
      if (this.toScrollTop > 0) {
        this.topValue =
          oneTopValue * this.newIndex -
          this.toScrollTop +
          this.topValue +
          this.newIndex * 8;
      } else {
        this.topValue =
          this.topValue + oneTopValue * this.newIndex + this.newIndex * 8;
      }
    },
    // 选择弹出项
    onSelectPopupItem (item) {
      if (Number(item.IsLeaveKey) == 0) {
        // 离职员工
        this.staffMenu = [
          {
            type: 1,
            name: '修改员工',
            eventName: 'edit',
            isShow: true,
            data: item
          },
          {
            type: 2,
            name: '员工复职',
            eventName: 'reinstated',
            isShow: true,
            data: item
          }
        ];
      } else if (Number(item.IsLeaveKey) == 1) {
        // 在职员工
        this.staffMenu = [
          {
            type: 1,
            name: '修改员工',
            eventName: 'edit',
            isShow: true,
            data: item
          },
          {
            type: 2,
            name: '员工离职',
            eventName: 'dimission',
            isShow: true,
            data: item
          }
        ];
      }
    },
    // 菜单操作项 this.$store.getters.getShowTimetableId
    onItemClick (menu) {
      this.isShowItem = false;
      var _this = this;
      if (this.vHasModulePower(44)) {
        switch (menu.eventName) {
          case 'edit':
            this.$dialog.open(this, {
              routerName: this.$route.name,
              ModulePowerKey: 44,
              name: '修改员工',
              moduleName: 'yuanGongSetting',
              data: { staffID: menu.data.OLAPKey, isReadonly: false },
              callBack: {
                afterSuccess: (type, data) => {
                  this.loadData();
                }
              }
            });
            this.$store.commit(
              'setShowTimetableId',
              menu.data.Chose2Key ? menu.data.Chose2Key : 0
            );
            break;
          case 'reinstated':
            layer.confirm('请确定是否复职该员工?', {
              btn: [
                {
                  name: '确认',
                  callBack: function () {
                    layer.close();
                    EmployeeRecover(menu.data.OLAPKey).then(
                      (result) => {
                        console.log(result, '复职成功');
                        _this.loadData(); // 渲染列表数据
                        _this.$emit('cancelClick', 'reinstated', result.data);
                      },
                      (error) => {
                        layer.alert(error.msg);
                      }
                    );
                  }
                }
              ]
            });
            break;
          case 'dimission':
            if (menu.data.Chose2Key == 2 || menu.data.Chose2Key == 10049) {
              // 当前员工权限为（默认管理员：2）或者（总部管理员：10049）时，不能离职
              layer.alert(
                '当前员工权限为' + menu.data.Chose2KeyValue + '，不能离职'
              );
            } else {
              let title = '';
              console.log('this.$store.getters.SaaSClientInfo.SaaSClientSetting', this.$store.getters.SaaSClientInfo.SaaSClientSetting[3].SetKey, menu.data.IsAdviserKey, 'IsAdviserKey');
              if (this.$store.getters.SaaSClientInfo.SaaSClientSetting[3].SetKey == 1 && menu.data.IsAdviserKey == 1) {
                title = '请确定是否离职该员工? 该员工的线索会回到待分配状态。';
              } else {
                title = '请确定是否离职该员工? ';
              }
              layer.confirm(title, {
                btn: [
                  {
                    name: '确认',
                    callBack: function () {
                      layer.close();
                      EmployeeDimission(menu.data.OLAPKey).then(
                        (result) => {
                          // 离职的是当前用户 就不在重新加载数据了 长连接会通知 直接退出系统
                          if (
                            menu.data.OLAPKey !=
                            _this.$store.getters.token.UserID
                          ) {
                            console.log(
                              result,
                              '离职成功2',
                              menu.data.OLAPKey,
                              _this.$store.getters.token.UserID
                            );
                            _this.loadData(); // 渲染列表数据
                            _this.$emit(
                              'cancelClick',
                              'dimission',
                              result.data
                            );
                          }
                        },
                        (error) => {
                          layer.alert(error.msg);
                        }
                      );
                    }
                  }
                ]
              });
            }

            break;
          default:
            break;
        }
      } else {
        this.$utils.CheckModulePowerToTips(44);
      }
    },
    toBack () {
      this.$store.commit('setShowTimetableId', 0);
    },
    selectImg (e, item, callBack) {
      this.editItem = item;
      let fileName = e.target.files[0].name;
      let typeText = '.jpg,.jpeg,.png';
      if (
        typeText.indexOf(
          fileName.substring(
            fileName.toLowerCase().lastIndexOf('.'),
            fileName.length
          )
        ) == -1
      ) {
        this.clearPicData();
        layer.alert('上传文件类型不符合，只能上传.jpg,.jpeg,.png类型文件');
        return false;
      } else {
        if (e.target.files.length > 0) {
          this.isShowItem = false;
          // 上传头像
          var reader = new FileReader(); // 实例化一个FileReader对象，用于读取文件
          // 读取File对象的数据
          var that = this;
          reader.onload = function (evt) {
            that.selectPicUrl = evt.target.result;
            that.$nextTick(() => {
              that.isChgPic = true;
              if (callBack) {
                callBack();
              }
            });
          };
          reader.readAsDataURL(e.target.files[0]);
        }
      }
    },
    // 确认裁切
    setPicCorp (imgUrl) {
      this.clearPicData();
      editEmployeeHeadImg(this.editItem.OLAPKey, imgUrl).then((result) => {
        this.loadData();
      });
    },
    clearPicData () {
      this.isChgPic = false;
      this.selectPicUrl = '';
    }
  }
};
</script>

<style>
.dialog_header_add {
  position: relative;
  left: 1rem;
  font-size: 15px;
  color: #3996d8;
}
</style>
