<template>
  <!-- 基础设置项 -->
  <div>
    <div class="form_info_edit content_marginRL content_marginTop_30">
      <!-- 内容 -->
      <div class="form_info_edit mendian_setting_content single_shadow"
           :class="{btn_marginBottom_30:isReadonly}">
        <div>
          <input-text :formTitle="'名称'"
                      :readonly="isReadonly"
                      :formPlaceholder="'必填'"
                      :required="true"
                      :maxLength="14"
                      v-model="dataJson.MainDemoName"></input-text>
        </div>
        <div v-if="coursList.length>0">
          <input-dialog :formTitle="'所属课类'"
                        :formPlaceholder="'必选'"
                        :readonly="isReadonly"
                        :required="true"
                        @showEldilog="showSingleSelectionDialog()"
                        v-model="FatherKeyValue"></input-dialog>
        </div>
        <input-number :min="0"
                      :value="dataJson.UnitPrice"
                      :decimalPlaces="2"
                      :formTitle="'单价'"
                      :placeholder="'必填'"
                      :readonly="isReadonly"
                      :required="true"
                      @inputChange="amountText"></input-number>

        <input-text :formTitle="'颜色'"
                    :readonly="true"
                    :required="true"
                    class="no_border_bottom"></input-text>
        <div class="form_color_box flex">
          <course-color v-for="item in colorArr"
                        :key="item.type"
                        :item="item"
                        @clickEvent="clickColor"></course-color>
        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <div v-if="!isReadonly"
         class="">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
    <div v-else
         class="on_form_footer_btn_group"></div>
    <custom-dialog :title="'选择所属课类'"
                   class="new_class defult_dialog"
                   :visible.sync="isChildForm"
                   :before-close="doCloseChildForm">

      <single-select-tree :sourceDate="coursList"
                          :selectionDate="vDataJsonInfo"
                          :disabledType="2"
                          @confirmClcik="doAfterSelectedItem"
                          @cancleClcik="closeSingleSelectionList"></single-select-tree>

    </custom-dialog>
  </div>
</template> 

<script>
import singleSelectTree from '../../../common/single-select-tree';
import courseColor from './course-color';
import {
  AddTheClassType,
  hqAddTheClassType,
  EditTheClassType,
  hqEditTheClassType,
  getCourseTypeNameList,
  hqGetNotDelCourse,
  GetNextStyle,
  hqGetNextStyle
} from '../../../../API/workbench';
export default {
  // 提供外部输出显示，不给就输出内部默认显示
  props: {
    isReadonly: {
      // 是否只读
      type: Boolean,
      default: false
    },
    editItem: {
      // 修改项
      type: [Object, String],
      default: function () {
        return {};
      }
    }
  },
  data () {
    return {
      newInputText: '', // 默认值
      coursList: [], // 课类课名列表
      IsSameContinue: '0', // 是否同名后继续：0-否;1-是;
      // 提交数据对象
      dataJson: {
        MainDemoName: '', // -- 名称
        FatherKey: 2, // ---所属课类Key 0为全部
        TypeKey: '2', // --  类型key
        UnitPrice: 0, // --单价
        StyleName: ''
      },
      FatherKeyValue: '全部课类', // --所属课类
      isChildForm: false,
      curSelectionColorItem: null,
      colorArr: [
        { colorName: '铅白', className: 'style1_color', StyleName: 'style1', isSelected: false },
        { colorName: '象牙白', className: 'style2_color', StyleName: 'style2', isSelected: false },
        { colorName: '白粉', className: 'style3_color', StyleName: 'style3', isSelected: false },
        { colorName: '薄樱', className: 'style4_color', StyleName: 'style4', isSelected: false },
        { colorName: '淡粉色', className: 'style5_color', StyleName: 'style5', isSelected: false },
        { colorName: '淡天蓝', className: 'style6_color', StyleName: 'style6', isSelected: false },
        { colorName: '莹白', className: 'style7_color', StyleName: 'style7', isSelected: false },
        { colorName: '若芽色', className: 'style8_color', StyleName: 'style8', isSelected: false },
        { colorName: '白绿', className: 'style9_color', StyleName: 'style9', isSelected: false },
        { colorName: '白藤色', className: 'style10_color', StyleName: 'style10', isSelected: false }
      ]
    };
  },

  components: {
    singleSelectTree,
    courseColor
  },
  created () {
    if (this.editItem.OLAPKey) {
      this.dataJson.OLAPKey = this.editItem.OLAPKey;
      this.dataJson.MainDemoName = this.editItem.MainDemoName;
      this.dataJson.FatherKey = this.editItem.FatherKey;
      this.dataJson.UnitPrice = Number(this.editItem.UnitPrice) > 0 ? Number(this.editItem.UnitPrice).toFixed(2) : 0;
      if (this.editItem.FatherKey == 2 || this.editItem.FatherKey == 0) {
        this.FatherKeyValue = '全部课类';
        this.dataJson.FatherKey = 2;
      } else {
        this.FatherKeyValue = this.editItem.FatherKeyValue;
        this.dataJson.FatherKey = this.editItem.FatherKey;
      }
      this.curSelectionColorItem = this.colorArr.find(obj => { return obj.StyleName == this.editItem.StyleName; });
      if (this.curSelectionColorItem) {
        this.curSelectionColorItem.isSelected = true;
      } else {
        this.curSelectionColorItem = {};
      }
    } else {
      if (this.editItem.FatherKey) {
        if (this.editItem.FatherKey == 2 || this.editItem.FatherKey == 0) {
          this.FatherKeyValue = '全部课类';
          this.dataJson.FatherKey = 2;
        } else {
          this.FatherKeyValue = this.editItem.FatherKeyValue;
          this.dataJson.FatherKey = this.editItem.FatherKey;
        }
      }
      this.getNextStyle(); // 新增时获取
    }
    let apiFN = (this.vTypeKey ? hqGetNotDelCourse : getCourseTypeNameList);
    apiFN().then(result => {
      console.log(result, '课类查询');
      this.coursList = [];

      result.data.forEach(o => {
        if (o.TypeKey == 1 && o.StateKey == 2) {
          this.coursList.push(o);
          o.typeKey = o.TypeKey;
        }
      });
      if (this.coursList.length > 0) {
        this.coursList.splice(0, 0, {
          OLAPKey: '2',
          MainDemoName: '全部课类',
          FatherKey: '0',
          FatherKeyValue: '',
          Number: '0',
          StatusKey: '2',
          typeKey: 1,
          StatusKeyValue: '启用',
          RemarksSupport: ''
        });
      }
    });
  },

  methods: {
    // 子组件弹出层显示
    showSingleSelectionDialog () {
      this.isChildForm = true;
    },
    // 子组件弹出层取消
    doCloseChildForm () {
      this.isChildForm = false;
    },
    amountText (val) {
      if (val) {
        this.dataJson.UnitPrice = val;
      }
    },
    // 确认按钮
    confirmClick (callBack) {
      let flag = false;
      if (this.dataJson.MainDemoName) {
        this.dataJson.MainDemoName = this.dataJson.MainDemoName.replace(/(^\s*)|(\s*$)/g, '');
      }
      if (!this.dataJson.MainDemoName) {
        layer.alert('请输入名称');
        flag = true;
      } else if (isNaN(this.dataJson.FatherKey)) {
        layer.alert('请选择所属课类');
        flag = true;
      } else if (this.FatherKeyValue == this.dataJson.MainDemoName) {
        layer.alert('名称不能与上级课类重名');
        flag = true;
      } else if (isNaN(this.dataJson.UnitPrice)) {
        layer.alert('请输入单价');
        flag = true;
      } else if (this.dataJson.MainDemoName && this.dataJson.MainDemoName.substr(this.dataJson.MainDemoName.length - 1, 1) == '班') {
        layer.alert('名称最后一个字不能为“班”,请修改后再提交。');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.dataJson.StyleName = this.curSelectionColorItem ? this.curSelectionColorItem.StyleName : '';
      if (this.editItem.OLAPKey) {
        delete this.dataJson.TypeKey;
        let apiFN = (this.vTypeKey ? hqEditTheClassType : EditTheClassType);
        apiFN(this.IsSameContinue, this.dataJson).then(
          result => {
            this.$emit('afterSuccess', 'Edit', result.data);
            this.cancelClick();
            layer.alert('修改成功');
          }, error => {
          if (error.code == 10000) {
            if (error.data && error.data[0].StateKey == 2) {
              layer.alert(error.msg);
            } else if (error.data && error.data[0].StateKey == 3) {
              let name = error.data[0].TypeKey == 1 ? '课类' : '课程';
              layer.confirm('已有相同名称且停用的' + name + ',是否继续?', {
                btn: [{
                  name: '确认',
                  callBack: () => {
                    apiFN(1, this.dataJson).then(result => {
                      this.$emit('afterSuccess', 'Edit', result.data);
                      this.cancelClick();
                      layer.alert('修改成功');
                    });
                    layer.close();
                  }
                }
                ]
              });
            }
          } else {
            layer.alert(error.msg);
          }
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      } else {
        let apiFN = (this.vTypeKey ? hqAddTheClassType : AddTheClassType);
        apiFN(this.IsSameContinue, this.dataJson).then(result => {
          this.$emit('afterSuccess', 'Add', result.data);
          this.cancelClick();
          layer.alert('新增成功');
        }, error => {
          if (error.code == 10000) {
            if (error.data && error.data[0].StateKey == 2) {
              layer.alert(error.msg);
            } else if (error.data && error.data[0].StateKey == 3) {
              let name = error.data[0].TypeKey == 1 ? '课类' : '课程';
              layer.confirm('已有相同名称且停用的' + name + ',是否继续?', {
                btn: [{
                  name: '确认',
                  callBack: () => {
                    apiFN(1, this.dataJson).then(result => {
                      this.$emit('afterSuccess', 'Add', result.data);
                      this.cancelClick();
                      layer.alert('新增成功');
                    });
                    layer.close();
                  }
                }
                ]
              });
            }
          } else {
            layer.alert(error.msg);
          }
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      }
    },
    // 取消按钮
    cancelClick () {
      this.$emit('cancelClick');
    },
    // 课类确定
    doAfterSelectedItem (item) {
      this.dataJson.FatherKey = item.id;
      this.FatherKeyValue = item.label;
      this.isChildForm = false;
    },
    // 课类取消
    closeSingleSelectionList () {
      this.isChildForm = false;
    },
    getNextStyle () {
      let apiFN = (this.vTypeKey ? hqGetNextStyle : GetNextStyle);
      apiFN().then(result => {
        this.curSelectionColorItem = this.colorArr.find(obj => { return obj.StyleName == result.data; });
        if (this.curSelectionColorItem) {
          this.curSelectionColorItem.isSelected = true;
        } else {
          this.curSelectionColorItem = {};
        }
      });
    },
    clickColor (item) {
      if (this.isReadonly) {
        return;
      }
      this.curSelectionColorItem.isSelected = false;
      item.isSelected = true;
      this.curSelectionColorItem = item;
    }
  },
  computed: {
    vTypeKey () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) { // 总部key为6
        return true;
      } else {
        return false;
      }
    },
    vDataJsonInfo: {
      get () {
        return {
          id: this.dataJson.FatherKey,
          label: this.FatherKeyValue,
          typeKey: 1
        };
      },
      set (newValue) {
        this.FatherKeyValue = newValue.label;
        this.dataJson.FatherKey = newValue.id;
      }
    }
  }
};
</script>

  <style>
</style>