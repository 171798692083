<template>
  <div @click="selectItem" class="commodity_list">
    <div class="commodity_list_img">
      <img class="img_box" :src="CDNURL + dataInfo.PicSupport" />
    </div>
    <div class="commodity_list_info">
      <div class="commodity_list_name">{{ dataInfo.MainDemoName }}</div>
      <div class="commodity_list_text">
        <div class="commodity_list_credit">
          {{ Number(dataInfo.CreditCount) }}学分
        </div>
        <div class="commodity_list_amount text_center_line">
          {{ Number(dataInfo.PriceAmount) }}元
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    dataInfo: Object,
  },
  computed: {
    CDNURL() {
      return this.$store.getters.CDNURL;
    },
  },
  methods: {
    selectItem(){
      this.$emit('selectItem',this.dataInfo)
    }
  },
};
</script>