<template>
	<div :class="imageClass" @click="clickImg" v-if="imageUrl">
		<div class="img_list">
			<img class="img_box" :src="vImageUrl" mode="aspectFill">
		</div>
     <custom-dialog :title="'预览图片'"
                   :visible.sync="isImageViewItemShow"
                   :before-close="toClosePreviewImg">
        <div class="home_work_img">
          <preview-img :imgList="vImgList"
                   :showIndex="imgShowIndex"
                   :isShowEditBtn="false"
                   @onClosePreviewImg="toClosePreviewImg"></preview-img>
        </div>
      </custom-dialog>
		<!-- <div class="" v-if="isImageViewItemShow">
         <preview-img :imgList="ImgList" :showIndex="imgShowIndex"   :previewText="content" @onClosePreviewImg="toClosePreviewImg"></preview-img>
		</div> -->

	</div>
</template>

<script>
import previewImg from '../../common-component/preview-img/index.vue';
export default {
  components: {
    previewImg
  },
  data () {
    return {
      isImageViewItemShow: false,
	   imgShowIndex: 0
    };
  },
  props: {
    imageClass: {
      type: String,
      default: ''// article_details_img_ul
    },
    imageUrl: String,
    ImgList: Array,
    content: String,
    isViewImage: { // 是否支持预览
      type: Boolean,
      default: true
    }
  },
  computed: {
    vImgList () {
      let newArr = [];
      if (this.ImgList && this.ImgList.length > 0) {
        this.ImgList.forEach(o => {
          this.$set(o, 'imgUrl', o.AttachUrl);
          newArr.push(o);
        });
      }
      return newArr;
    },
    vImageUrl () {
      return this.$utils.ossLitimgSet(this.imageUrl);
    }
  },
  created () {
    console.log(this.imageUrl, '图片预览', this.ImgList);
  },
  methods: {
    // 点击图片
    clickImg () {
      console.log('clickImg', this.ImgList);
      if (this.isViewImage) {
        this.isImageViewItemShow = true;
        console.log(this.imageUrl, '图片预览');
        this.ImgList.forEach((o, index) => {
          if (o.AttachUrl == this.imageUrl) {
            this.imgShowIndex = index;
          }
        });
      }
    },
    toClosePreviewImg () {
      console.log(this.imageUrl, '图片预览');
	    this.isImageViewItemShow = false;
	    this.imgShowIndex = 0;
    }
  }
};
</script>

<style>

</style>
