<template>
  <div class="margin_left_right_div">
    <div class="recruit_student_activity template_minOrmax_width_div"
         style="width: auto;">
      <div class="recruit_student_activity_title">
        <div class="title_list"
             :class="indexType==0?'opt':''"
             @click="changeIndexType(0)">
          活动管理
        </div>
        <div class="title_list"
             :class="indexType==1?'opt':''"
             @click="changeIndexType(1)">
          我的活动
        </div>
      </div>
      <!-- 活动管理 -->
      <div v-if="indexType==0">
        <activities-management @changeType="changeIndexType(1)"
                               @openFactoryDialog="openCustomDialogByFactoryDialog"></activities-management>
      </div>
      <!-- 我的活动 -->
      <div v-if="indexType==1">
        <user-activities></user-activities>
      </div>
      <div>
        <dialog-factory ref="recruitStudentShowDialogFactory"
                        :key="'recruitStudentShow'"
                        :routerName="'recruitStudentShow'"></dialog-factory>

        <dialog-factory-common ref="recruitStudentShowDialogFactoryCommon"></dialog-factory-common>
      </div>
    </div>
  </div>
</template>
<script>
import dialogFactory from '../common/dialog-factory';
import bus from '../../js/bus.js';
import activitiesManagement from './activities-management';
import userActivities from './user-activities';
export default {
  name: 'recruitStudentShow',
  data () {
    return {
      indexType: 0
    };
  },
  components: {
    activitiesManagement, userActivities, dialogFactory
  },
  created () {
    console.log('recruitStudentShow created', this.indexType);
  },
  mounted () {
    this.resgisterBusEvent();
  },
  beforeRouteLeave (to, from, next) {
    console.log('触发beforeRouteLeave');
    this.$bus.emit('closeEditorDialog');
    next();
  },
  activated () {
    console.log('recruitStudentShow activated', this.indexType);
  },
  methods: {
    changeIndexType (type) {
      this.indexType = type;
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.recruitStudentShowDialogFactory) {
        this.$refs.recruitStudentShowDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      };
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.recruitStudentShowDialogFactoryCommon) {
        this.$refs.recruitStudentShowDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      };
    },
    resgisterBusEvent () {
      bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'recruitStudentShow') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'recruitStudentShow') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    }
  }
};
</script>
