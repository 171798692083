<template>
  <div class="margin_left_right_div">
    <div class="template_minOrmax_width_div">
      <div class="table_fixed_nav_box"
           style="padding-left: 40px;position: static;    border-bottom: 2px solid #f2f2f2;">
        <div class="table_fixed_nav"
             style="width:100%">
          <div class="table_fixed_nav_list"
               :class="isMainTeacher?'opt':''"
               @click.stop="changeIsMainTearcher(true)">
            主讲业绩
          </div>
          <div class="table_fixed_nav_list"
               :class="!isMainTeacher?'opt':''"
               @click.stop="changeIsMainTearcher(false)">助教业绩</div>
        </div>
      </div>
      <main-teacher v-if="isMainTeacher"
                    @setNewData="setNewData"
                    :generationTime="generationTime"></main-teacher>
      <sub-teacher v-else
                   @setNewData="setNewData"
                   :generationTime="generationTime"></sub-teacher>
      <div>
        <dialog-factory ref="teacherPerformance"
                        :routerName="'teacherPerformance'"></dialog-factory>
        <dialog-factory-common ref="teacherPerformanceFactoryCommon"></dialog-factory-common>
      </div>
    </div>
  </div>
</template>
<script>
import dialogFactory from '../../common/dialog-factory';
import subTeacher from './sub-teacher';
import mainTeacher from './main-teacher';
import { GetTeacherDataGenerationTime, RegenTeacherPerformance } from '../../../API/workbench';
export default {
  name: 'teacherPerformance',
  data () {
    return {
      generationTime: '',
      isMainTeacher: true
    };
  },
  components: {
    dialogFactory,
    subTeacher,
    mainTeacher
  },
  created () {
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'teacherPerformance') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'teacherPerformance') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.getTeacherDataGenerationTime();
    this.loadDataSourceTeacher();
    this.getValidClassList();
    this.getCourseNameList();
  },
  methods: {
    clickItemAfter (item, seletedDate) {
      console.log('clickItemAfter', item);
      this.$refs.rightContent.openCourseReportGetDataByDate(item, seletedDate);
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.teacherPerformance) {
        this.$refs.teacherPerformance.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.teacherPerformanceFactoryCommon) {
        this.$refs.teacherPerformanceFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    },
    getTeacherDataGenerationTime () {
      GetTeacherDataGenerationTime().then(result => {
        this.generationTime = result.data;
        console.log(result.data, 'GetTeacherDataGenerationTime');
      });
    },
    setNewData (callBack) {
      RegenTeacherPerformance().then(result => {
        console.log('RegenTeacherPerformance');
        layer.alert('重新生成数据成功');
        this.getTeacherDataGenerationTime();
        if (callBack) {
          callBack();
        }
        // this.$refs.monthReport.getReportList();
        // this.$refs.yearReport.getReportList();
        // this.$refs.rightContent.updateAllData();
      }).catch(error => {
        console.log(error, 'ErrorRegenTeacherPerformance');
      });
    },
    changeIsMainTearcher (type) {
      this.isMainTeacher = type;
    },
    // 调用在职老师接口
    loadDataSourceTeacher () {
      this.$basicData.loadTeacherList();
    },
    // 获取有效班级列表
    getValidClassList () {
      this.$basicData.loadGradeClassList();
    },
    // 课名列表
    getCourseNameList () {
      this.$basicData.loadCourseNameList();
    }
  }
};
</script>
<style scoped>
.template_minOrmax_width_div >>> .teacher_performance_statistics {
  min-height: 980px;
}
</style>