<template>
  <div class="courseware_manage_right new_courseware_manage_right">
    <!-- type	1：自研课件 0：授权课件 -->
    <div v-if="coursewareInfo">
      <headAuth :coursewareInfo="coursewareInfo"
                @afterSuccesAuth="afterSuccesAuth"
                v-if="type=='SQ' || coursewareInfo.type==0"></headAuth>

      <headNormal :coursewareInfo="coursewareInfo"
                  :logInfo="publishLogInfo"
                  :coursewareLength="newCoursewareContentList.length"
                  @afterSuccess="afterSuccess"
                  v-else-if="type=='ZZ'|| coursewareInfo.type==1"></headNormal>
    </div>
    <div class="right_nodata_type02"
         v-if="vExceptionText">
      {{vExceptionText}}，是否{{type=='SQ'?'移入回收站':'移出回收站'}}
      <div class="nodata_btn"
           @click="doImmigrationRecycle">{{type=='SQ'?'移入回收站':'移出回收站'}}</div>
    </div>
    <div class="right_nodata_type03"
         v-else-if="!$zxClient.IsClient">
      请用桌面端打开程序
    </div>
    <div class="right_nodata_type01"
         v-else-if="isAuthorize&&type=='SQ'">
      设备未绑定
      <div class="nodata_btn"
           @click="applyBinding">申请绑定</div>
    </div>
    <div v-else-if="isLoadData &&coursewareInfo.ID"
         class="right_nodata">
      加载中...
    </div>
    <div v-else-if="newCoursewareContentList.length==0 &&!coursewareInfo.ID"
         class="right_nodata">
      还没有课件哦~
    </div>
    <div v-else-if="newCoursewareContentList.length==0 &&type=='ZZ'&&coursewareInfo.ID"
         class="right_nodata">
      还没有课件哦~
      <div class="nodata_btn"
           @click="coursewareOperate('Add',null)">创建课件</div>
    </div>

    <div v-else
         style="height: 715px;overflow-y: auto;">
      <draggable v-model="newCoursewareContentList"
                  animation="100"
                 dragClass="dragClass"
                 ghostClass="ghostClass"
                 chosenClass="chosenClass"
                 filter=".forbid"
                 handle=".mover"
                 :sort="true"
                 @start="onStart"
                 @end="onEnd">
        <transition-group class="right_content">
          <new-content-item v-for="item in newCoursewareContentList"
                        :key="item.OLAPKey"
                        :type="type"
                        :dataItem="item"
                        :logInfo="publishLogInfo"
                        :coursewareInfo="coursewareInfo"
                        :coursewareInfoType="type"
                        @showOperateMenu="showOperateMenu"
                        @coursewareOperate="coursewareOperate"></new-content-item>
          <div class="forbid"
               :key="'新增'">
            <div class="courseware_manage_list_add"
                 @click="coursewareOperate('Add',null)"
                 v-if="type=='ZZ'&&coursewareInfo.ID&&publishLogInfo.PublishStatus !=2 &&coursewareInfo.PublishStatusKey==0">
              <div>新建课件</div>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
    <custom-dialog :title="'申请设备授权'"
                   :visible.sync="showAuth"
                   :before-close="closeAuthDialog">
      <apply-auth :dataInfo="authDataInfo"
                  @closeDialog="closeAuthDialog"
                  @afterSucces="verifyAuthorize"></apply-auth>
    </custom-dialog>
  </div>
</template>
<script>
import {
  VerifyAuthorizedDevice,
  AuthCoursewarePackageDisable,
  AuthCoursewarePackageEnable,
  hqCoursewareManageSort,
  CoursewareManageSort,
  hqCoursewarePackageManageGetPublishLog, // 发布记录
  CoursewarePackageManageGetPublishLog, // 发布记录
  CWSCoursewarePackageManageGetPublishLog
} from '../../../API/workbench';
import headAuth from './head-auth';
import headNormal from './head-normal';
import contentItem from './content-item';
import newContentItem from './new-content-item';
import applyAuth from '../../pop-up-menu/auth-components/apply-auth';
import { md5 } from '../../../API/md5';
import draggable from 'vuedraggable';
export default {
  components: {
    headAuth,
    headNormal,
    contentItem,
    applyAuth,
    draggable,
    newContentItem
  },
  data () {
    return {
      isAuthorize: false, // 设备是否绑定
      authDataInfo: { DeviceCode: '', CoursewareKey: '' },
      showAuth: false, // 申请设备授权
      coursewareList: [], // 课件包列表
      operateMenu: [], // 操作菜单
      publishLogInfo: {}, // 发布记录
      newCoursewareContentList: [],
      // 排序
      sort: {
        startIndex: 0,
        endIndex: 0,
        sortCoursewareID: '',
        showOrder: null
      }
    };
  },
  props: {
    type: String,
    isLoadData: Boolean,
    coursewareInfo: Object,
    maxCoursewareInfo: Object,
    coursewareContentList: Array
  },
  created () {
    this.newCoursewareContentList = this.coursewareContentList;
    console.log(this.coursewareInfo, this.type, 'coursewareInfo-选中');
    console.log(this.maxCoursewareInfo, 'maxCoursewareInfo');
    if (this.type == 'SQ') {
      this.verifyAuthorize();
    }
    if (this.type == 'ZZ') {
      this.getPublishLog();
    }
    this.$store.commit('setAcademicMdCwType', 2);
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      if (this.newCoursewareContentList.length > 0) {
        this.newCoursewareContentList.forEach(o => {
          this.$set(o, 'isOperate', false);
        });
      }
    }, true);
  },
  watch: {
    coursewareContentList (n, c) {
      console.log(n, 'coursewareInfo-2');
      this.newCoursewareContentList = n;
    },
    coursewareInfo (n, c) {
      console.log(n, 'coursewareInfo-2');
      this.coursewareInfo = n;
      if (this.type == 'SQ') {
        this.verifyAuthorize();
      }
      if (this.type == 'ZZ') {
        this.getPublishLog();
      }
    }
  },
  computed: {
    vExceptionText () {
      console.log(this.type, this.coursewareInfo, 'coursewareInfo-3');
      if (this.type != 'ZZ' && this.coursewareInfo.type != 1 && this.coursewareInfo.ID) {
        if (this.coursewareInfo.isUseUp == 1) {
          return '已用完';
        } else if (this.coursewareInfo.isExpire == 1) {
          return '已过期';
        } else if (this.coursewareInfo.AuthorizeStatusKey == 0) {
          return '暂停授权';
        }
      } else {
        return '';
      }
    },
    vTypeKey () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) { // 总部key为6
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    // 获取发布记录
    getPublishLog () {
      if (!this.coursewareInfo.ID) {
        return;
      }
      let apiFN = '';
      let type = this.$store.getters.SaaSClientInfo.EditionKey;
      if (type == 6) {
        apiFN = CWSCoursewarePackageManageGetPublishLog;
      } else if (type == 5) {
        apiFN = hqCoursewarePackageManageGetPublishLog;
      } else {
        apiFN = CoursewarePackageManageGetPublishLog;
      }
      apiFN(this.coursewareInfo.ID).then(result => {
        this.publishLogInfo = result.data;
        this.publishLogInfo.PublishTime = this.publishLogInfo.PublishTime ? this.publishLogInfo.PublishTime.slice(0, 10) : '';
        console.log(result.data, '获取发布记录');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 设备是否绑定
    verifyAuthorize () {
      console.log(this.maxCoursewareInfo, this.$zxClient.IsClient, '是否客户端打开');
      this.authDataInfo.CoursewareKey = '';
      if (this.maxCoursewareInfo.Authorize) {
        this.authDataInfo.CoursewareKey = this.maxCoursewareInfo.Authorize.CoursewarePackageKey;
      } else if (this.coursewareInfo.Authorize) {
        this.authDataInfo.CoursewareKey = this.coursewareInfo.Authorize.CoursewarePackageKey;
      }
      if (this.$zxClient.IsClient && this.authDataInfo.CoursewareKey) {
        this.showAuth = false;
        this.authDataInfo.DeviceCode = this.$zxClient.GetMCode();// '5D84F41F7969A42D';
        VerifyAuthorizedDevice(this.authDataInfo.DeviceCode, this.authDataInfo.CoursewareKey).then(result => {
          this.isAuthorize = false;
            let geoInfo = result.data;
            this.$store.commit("setGeoInfo",geoInfo)
        }, error => {
          if (error.code == 10000) {
            this.isAuthorize = true;
          }
          // layer.alert(error.msg);
        });
      } else {
        this.isAuthorize = false;
      }
    },
    // 设备授权
    applyBinding () {
      console.log(this.authDataInfo.CoursewareKey, '设备授权');
      this.showAuth = true;
    },
    // 关闭设备授权
    closeAuthDialog () {
      this.showAuth = false;
    },
    // 授权列表操作成功回调
    afterSuccesAuth () {
      this.$emit('afterSucceed', { key: this.type }, this.coursewareInfo);
    },
    // 回收站
    doImmigrationRecycle () {
      if (!this.coursewareInfo.ID) {
        console.log(this.coursewareInfo, '回收站');
        return;
      }
      if (this.type == 'SQ') { // 移入回收站
        AuthCoursewarePackageDisable(this.coursewareInfo.ID).then(result => {
          layer.alert('移入回收站成功');
          this.$emit('afterSucceed', { key: this.type });
        }, error => {
          layer.alert(error.msg);
        });
      } else if (this.type == 'HSZ') { // 移出回收站
        AuthCoursewarePackageEnable(this.coursewareInfo.ID).then(result => {
          layer.alert('移出回收站成功');
          this.$emit('afterSucceed', { key: this.type });
        }, error => {
          layer.alert(error.msg);
        });
      }
    },
    // 发布,撤回,更新之后回调
    afterSuccess () {
      this.getPublishLog();
      this.$emit('afterSuccess')
      // this.$emit('afterSucceed', { key: this.type }, this.coursewareInfo);
    },
    // 显示操作菜单
    showOperateMenu (dataInfo) {
      this.newCoursewareContentList.forEach(o => {
        this.$set(o, 'isOperate', false);
        if (dataInfo.OLAPKey == o.OLAPKey) {
          this.$set(o, 'isOperate', true);
        }
      });
    },
    // 课件
    coursewareOperate (type, data) {
      console.log(this.coursewareInfo, '课件-编辑');
      let deviceInfo = this.getDeviceInfo();
      this.$emit('coursewareOperate', type, data, deviceInfo);
    },
    // 获取设备信息
    getDeviceInfo () {
      let data;
      if (this.$zxClient.IsClient) {
        data = {
          DeviceTypeKey: 2,
          DeviceTypeKeyValue: '桌面端',
          DeviceCode: this.$zxClient.GetMCode(),
          Longitude: '',
          Latitude: '',
          Sign: '',
          IsSettle:1
        };
      } else {
        data = {
          DeviceTypeKey: 1,
          DeviceTypeKeyValue: '浏览器',
          DeviceCode: '',
          Longitude: '',
          Latitude: '',
          Sign: '',
          IsSettle:1
        };
      }
      // data = {
      //   DeviceTypeKey: 2,
      //   DeviceTypeKeyValue: '桌面端',
      //   DeviceCode: '340BC40DC769411B',
      //   Longitude: '',
      //   Latitude: '',
      //   Sign: ''
      // };
      data.Sign = md5(this.$utils.setStringmd5(data.DeviceTypeKey, data.DeviceCode, 'KJ'));
      return data;
    },
    // 开始拖拽事件
    onStart (e) {
      console.log(e, 'onStart');
      this.sort.startIndex = e.oldIndex;
      this.sort.sortCoursewareID = this.coursewareContentList[this.sort.startIndex].OLAPKey;
    },
    // 拖拽结束事件
    onEnd (e) {
      this.sort.endIndex = e.newIndex;
      // 拖拽到新增后面时取最后一个课件
      if (this.coursewareContentList.length == this.sort.endIndex) {
        this.sort.endIndex = this.coursewareContentList.length - 1;
      }
      if (this.coursewareContentList[this.sort.endIndex] && this.coursewareContentList[this.sort.endIndex].ShowOrder) {
        this.sort.showOrder = this.coursewareContentList[this.sort.endIndex].ShowOrder;
      }
      console.log(e, 'onEnd');
      if (this.sort.startIndex == this.sort.endIndex || !this.sort.sortCoursewareID) {
        return;
      }
      console.log(this.sort, 'sort');
      console.log(this.newCoursewareContentList, this.coursewareContentList, 'list');
      let apiFN = (this.vTypeKey ? hqCoursewareManageSort : CoursewareManageSort);
      apiFN(this.sort.sortCoursewareID, this.sort.showOrder).then(result => {
        // let newStartOrder = this.$utils.parseJson(this.coursewareContentList[this.sort.startIndex].ShowOrder);
        // this.coursewareContentList[this.sort.startIndex].ShowOrder = this.coursewareContentList[this.sort.endIndex].ShowOrder;
        // this.coursewareContentList[this.sort.endIndex].ShowOrder = newStartOrder;
        this.$emit('afterSucceedSort', this.coursewareInfo);
      }, error => {
        layer.alert(error.msg);
      });
    }
  }
};
</script>
<style>
/* 拖动的样式 */
.chosen {
  border: solid 0.5px #3089dc !important;
}
.new_courseware_manage_right .courseware_manage_list_add{
  /* margin: 0 15px 40px 20px; */
    width: 268px;
    height: 151px;
    padding-top: 81px;
}
.courseware_manage_list_add::before{
    top: 60px !important;
    left: 51% !important;
}
.courseware_manage_list_add::after{
    top: 34% !important;
}
</style>