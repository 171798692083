<template>
    <div>
      <el-form label-position="left"
             inline
             class="table_expand_item demo-table-expand">
      <el-form-item :label="item.label"
                    v-for="(item,index) in tableColumns"
                    :key="index">
        <span :title="rowData[item.prop]">{{ rowData[item.prop] }}</span>
      </el-form-item>
    </el-form>
    </div>
</template>
<script>
export default {
  props: {
    rowData: Object,
    tableColumns: Array
  }
};
</script>

<style>
</style>