<template>
  <!-- 后台管理 -->
  <div class="mendian_backstage_box">
    <header-page></header-page>
    <contentPage></contentPage>
    <div>
      <dialog-factory ref="mendianOperationManagement"
                      :key="'mendianOperationManagement'"
                      :routerName="'mendianOperationManagement'"></dialog-factory>
    </div>
  </div>
</template>
<script>
import headerPage from './header-page';
import contentPage from './content-page';
import dialogFactory from '../../common/dialog-factory';
export default {
  name: 'mendianOperationManagement',
  data () {
    return {

    };
  },
  components: {
    headerPage, contentPage, dialogFactory
  },
  created () {

  },
  methods: {

  }
};
</script>
