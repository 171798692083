<template>
  <div class="form_item form_info_line"
       :class="{ form_info_required: required }">
    <div :class="formPlaceholder==' '?'form_info_field_init':'form_info_field'">

      <span @click.stop="showEldilog"
            v-html="formTitle"
            v-if="isTitleClick&&!readonly"></span>
      <span v-html="formTitle"
            v-else></span>
      <span v-if="helpDescription">
        <input-form-tip :tips='helpDescription'></input-form-tip>
      </span>
    </div>
    <div class="form_info_value form_info_select pr"
         @click.stop="showEldilog"
         v-if="!readonly">
      <span class="form_hint"
            v-if="formInputHint">{{formInputHint}}</span>
      <input v-if="!hiddenInput"
             type="text"
             readonly
             :disabled="disabled"
             :value="value"
             :placeholder="vPlaceholder"
             :class="className"
             @input="updateVal($event.target.value)"
             @change="changeVal($event.target.value)" />
    </div>
    <div v-else
         class="input_readonly form_info_value"
         :class="className">
      {{hiddenInput?'':value?value:''}}</div>
    <slot></slot>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: 'formDialog',
  data () {
    return {};
  },
  props: {
    formTitle: {
      type: String,
      required: true
    }, // 标题，必填
    value: {
      type: [String, Number, Boolean],
      default: ''
    }, // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    disabled: Boolean, // 是否禁止修改，可选，如不提供，则可修改
    formPlaceholder: String, // 默认文本，可选，如不提供，则默认显示title
    isNumber: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    },
    customPlaceholder: {
      type: Boolean,
      default: false
    },
    helpDescription: String, // 表单说明提示
    formInputHint: String, // 输入框说明提示
    hiddenInput: {
      type: Boolean,
      default: false
    },
    isTitleClick: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    vPlaceholder () {
      let str = '';
      if (!this.customPlaceholder) {
        if (this.required) {
          if (this.isNumber) {
            str = '必填';
          } else {
            str = '必选';
          }
        } else {
          if (this.isNumber) {
            str = '选填';
          } else {
            str = '可选';
          }
        }
      } else {
        str = this.formPlaceholder;
      }
      return str;
    }
  },
  created () { },
  watch: {},
  methods: {
    updateVal (val) {
      this.$emit('input', val);
    },
    changeVal (val) {
      this.$emit('change', val);
    },
    showEldilog () {
      this.$emit('showEldilog');
    }
  }
};
</script>
<style>
.systemsetting .form_info_field {
  font-size: 16px !important;
}
</style>
