<template>
  <div :style="dataInfo.TypeKey==2?'border-bottom: 1px solid #ececec;':''"
       @mouseenter="showOption"
       @mouseleave="hiddenOption">
    <div class="courseware_list"
         :class="[{'opt':dataInfo.isSelected},{'type2':isChild&&Number(dataInfo.TypeKey)==0},{'type3':Number(dataInfo.TypeKey)==0}]"
         @click="selectEvent(dataInfo)">
      <!-- {'type2':Number(dataInfo.TypeKey)==0} -->
      <div class="courseware_box_name">
        <div class="courseware_packge_list_item_bg"></div>
        <div class="cover_img">
          <img class="img_box"
               :style="dataInfo.CoverUrl?'':'height: 63px;width: auto;'"
               :src="vCoverImg"
               :onerror="defaultImg"
               alt="">
        </div>

        <div class="courseware_box_name_right_content">
          <div class="flex_row">
            <div class="courseware_name_text"
                 :title="dataInfo.MainDemoName">
              {{dataInfo.MainDemoName}}
            </div>
            <!-- 操作按钮 -->
            <div class="header_btn_conent"
                 v-if="showOptionList">

              <div class="list_img_btn_top"
                   :class="keyIndex==0?'disable':''"
                   v-if="type=='ZZ'"
                   @click.stop="doListSort('Top',dataInfo,keyIndex==0)">
                <div class="list_img_btn_sort"></div>
              </div>
              <div class="list_img_btn_down"
                   :class="keyIndex==listLength-1?'disable':''"
                   v-if="type=='ZZ'"
                   @click.stop="doListSort('Bottom',dataInfo,keyIndex==listLength-1)">
                <div class="list_img_btn_sort"></div>
              </div>
              <div class="list_img_btn_more"
                   v-if="type=='ZZ'"
                   @mouseleave="mouseOutEvent"
                   @mouseenter.stop="mouseOverEvent">
                <div class="list_img_ul"
                     :class="isFiexd?'fiexdTo':''"
                     v-if="isShowOperateMenu">
                  <div v-for="item in newOperateMenu"
                       :key="item.key">
                    <div class="list_img_menu"
                         :class="item.className"
                         v-if="item.isShow"
                         @click.stop="doOperateItemClick(item,dataInfo)">
                      {{item.name}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <!-- <div class="flex_row">
            <span class="header_option_box_item" style="letter-spacing: 0.5px;">
              发布课件
              <span class="font_black">{{Number(dataInfo.CoursewareNum)||0}}课次</span>
            </span>
          </div> -->
          <div class="flex_row"
               style="align-items: flex-end;">
            <span class="header_option_box_item"
                  style="letter-spacing: 0.5px;">
              课件
              <span class="font_black">{{Number(dataInfo.CoursewareNum)||0}}课次</span>
            </span>
            <!-- <span class="header_option_box_item" style="letter-spacing: 0.5px;">
              占用空间
              <span class="font_black">{{this.$utils.capacityFormate(Number(dataInfo.OccupiedCapacity)||0)}}</span>
            </span> -->
            <div>
              <span class="status_type_orange"
                    v-if="dataInfo.isPublishInKey==1">待审批</span>
              <span class="status_type_yellow"
                    v-else-if="dataInfo.isPublishInKey ==0&&dataInfo.PublishStatusKey ==1&&dataInfo.IterationPublishStatusKey==0">待发布</span>
              <span class="status_type_red"
                    v-else-if="dataInfo.isPublishInKey==0 && dataInfo.PublishStatusKey==0&&dataInfo.TypeKey==0">未发布</span>
              <!-- <span class="status_type_red"
                    v-if="Number(dataInfo.NotReleaseNum)>0">有更新</span> -->
              <!-- <span :class="dataInfo.isOpt?'top_opt':'down_opt'"
                    v-if="dataInfo.TypeKey==2"></span> -->
            </div>
            <div class="flex_row"
                 v-if="dataInfo.TypeKey==2"
                 style="position: relative;top: 5px;">
              <function-btn v-if="type=='ZZ'&&dataInfo.TypeKey==2"
                            :item="{ name: '三级课包', ModulePowerKey: '' }"
                            :className="'el-button buttom_sq font_blue courseware_icon_03'"
                            style="width: 100px;padding-left: 40px;margin-right: -10px;"
                            @clickItem="doAddChildCourseBag">
              </function-btn>
              <span class="font_blue pr"
                    :class="dataInfo.isOpt?'top_opt':'down_opt'"
                    style="width: 75px;text-align: center;line-height: 34px;font-size:14px">{{dataInfo.isOpt?'收起':'展开'}}</span>

            </div>
          </div>
        </div>
        <!-- <div class="courseware_name_text"
             :title="dataInfo.MainDemoName">
          {{dataInfo.MainDemoName}}
        </div>
        <div class="courseware_name_capacity"
             :class="dataInfo.TypeKey==2?'courseware_name_capacity_title':''">
          <img :src="require('../../../../public/image/capacity_icon.png')"
               class="courseware_name_capacity_icon" />
          <span class="courseware_name_capacity_text">{{!dataInfo.OccupiedCapacity?'0B':this.$utils.capacityFormate(dataInfo.OccupiedCapacity)}}</span>
        </div> -->
        <!-- PublishStatusKey	整型	发布状态 0-未发布;1-已发布
            isPublishInKey	整型	是否发布中(发布中即是待审核1);
            IterationPublishStatusKey	整型	迭代发布状态 0-未发布;1-已发布 -->
        <!-- <span class="status_type_yellow"
              v-if="dataInfo.isPublishInKey==1&&dataInfo.TypeKey==0">待审核</span>
        <span class="status_type_yellow"
              v-else-if="dataInfo.isPublishInKey ==0&&dataInfo.PublishStatusKey ==1&&dataInfo.IterationPublishStatusKey==0&&dataInfo.TypeKey==0">待发布</span>
        <span class="status_type_red"
              v-else-if="dataInfo.isPublishInKey==0 && dataInfo.PublishStatusKey==0&&dataInfo.TypeKey==0">未发布</span>
        <span :class="dataInfo.isOpt?'top_opt':'down_opt'"
              v-if="dataInfo.TypeKey==2"></span> -->
      </div>

      <!-- <div class="list_btn"
           v-if="type=='ZZ'&&Number(dataInfo.TypeKey)==0">
        <div class="list_top"
             @click.stop="doListSort('Top',dataInfo)"
             v-if="Number(dataInfo.ShowOrder)>1"></div>
        <div class="list_bottom"
             v-if="Number(dataInfo.ShowOrder)<listLength"
             @click.stop="doListSort('Bottom',dataInfo)"></div>
      </div> -->
    </div>
    <!-- <div class="cw_head_menu" style="padding-left:137px;border:none" v-if="dataInfo.TypeKey==2" @click.stop="selectEvent(dataInfo)">
          <div class="font_black" style="line-height:32px;font-weight: bold;letter-spacing: 0.5px;">
            三级课包
          </div>
          <div class="flex_row">
            <function-btn v-if="type=='ZZ'&&dataInfo.TypeKey==2" 
                          :item="{ name: '新建三级课包', ModulePowerKey: '' }"
                          :className="'el-button buttom_sq font_blue courseware_icon_03'"
                          @clickItem="doAddChildCourseBag">
            </function-btn>
            <span class="font_blue pr" :class="dataInfo.isOpt?'top_opt':'down_opt'" style="width: 75px;text-align: center;line-height: 34px;">{{dataInfo.isOpt?'收起':'展开'}}</span>
            
          </div>
     </div> -->
    <transition>
      <ul v-show="dataInfo.isOpt"
          style="padding-left:50px">

        <list-item v-for="(item,index) in vdataInfochildTree"
                   :dataInfo="item"
                   :keyIndex="index"
                   :listLength="dataInfo.childTree.length"
                   :type="type"
                   :key="index"
                   :isChild="true"
                   @selectEvent="selectEvent"
                   @doListSort="doListSort"
                   @doOperateItemClick="doOperateItemClick"></list-item>
      </ul>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'list-item',
  data () {
    return {
      isShowOperateMenu: false, // 显示菜单
      isShowMenuBeing: true, // 是否已是移出状态
      newOperateMenu: [],
      operateMenu: [
        { name: '复制', className: 'type_01', key: 'copy', isShow: false },
        { name: '编辑', className: 'type_02', key: 'edit', isShow: false },
        { name: '删除', className: 'type_03', key: 'delete', isShow: false },
        { name: '停用', className: 'type_06', key: 'disable', isShow: false },
        { name: '启用', className: 'type_07', key: 'enable', isShow: false }
      ],
      lastClickTime: null,
      isFiexd: false,
      showOptionList: false
    };
  },
  props: {
    listLength: Number,
    dataInfo: Object,
    type: String,
    listHeight: Number,
    isChild: {
      type: Boolean,
      default: false
    },
    keyIndex: Number
  },
  computed: {
    vdataInfochildTree () {
      console.log("this.dataInfo.childTree", this.dataInfo.childTree)
      return this.dataInfo.childTree.sort((x, y) => {
        return Number(x.ShowOrder) - Number(y.ShowOrder)
      })
    },
    // 非自研课件时不显示添加按钮
    vCoverImg () {
      if (this.dataInfo.CoverUrl) {
        return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
      } else {
        return '';
      }
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../public/image/mulu.png') + '"';
    }
  },
  created () { console.log(this.dataInfo, 'this.dataInfo'); },
  mounted () {

  },
  methods: {
    showOption () {
      this.showOptionList = true
    },
    hiddenOption () {
      this.showOptionList = false
    },
    // 鼠标移入,显示操作菜单
    mouseOverEvent (e) {

      this.isFiexd = Number(e.clientY) > 670
      console.log('测试', this.isFiexd, Number(e.clientY), this.listHeight)
      console.log(this.dataInfo, this.type, '鼠标移入', e.clientY);
      switch (this.type) {
        case 'SQ': // 授权
          this.newOperateMenu = [];
          break;
        case 'ZZ': // 自研
          this.newOperateMenu = this.$utils.parseJson(this.operateMenu);
          if (this.dataInfo.TypeKey != 2) {
            this.newOperateMenu[0].isShow = true;
          }
          this.newOperateMenu[1].isShow = true;
          // 是否发布中(0-否;1-是)
          if (this.dataInfo.isPublishInKey == 0) {
            // 发布状态(0-未发布;1-已发布)
            if (this.dataInfo.PublishStatusKey == 0) {
              if (this.dataInfo.TypeKey == 2 && this.dataInfo.childTree.length > 0) {
                this.newOperateMenu[2].isShow = false;
              } else {
                this.newOperateMenu[2].isShow = true;
              }
            } else if (this.dataInfo.PublishStatusKey == 1) {
              this.newOperateMenu[3].isShow = true;
            }
          }
          break;
        case 'HSZ': // 回收站
          if (this.dataInfo.type && this.dataInfo.type == 0) {
            // type	整型	1：自研课件 0：授权课件
            this.newOperateMenu = [];
          } else {
            this.newOperateMenu = this.$utils.parseJson(this.operateMenu);
            this.newOperateMenu[4].isShow = true;
          }
          break;
        default:
          break;
      }
      this.isShowOperateMenu = true;
    },
    // 鼠标离开
    mouseOutEvent () {
      console.log('鼠标离开');
      this.isShowOperateMenu = false;
    },
    // 点击事件
    selectEvent (data) {
      console.log(this.dataInfo, '点击事件');
      if (data.TypeKey == 0) {
        this.$emit('selectEvent', data);
      } else {
        if (data.isOpt) {
          data.isOpt = !data.isOpt;
        } else {
          this.$emit('selectEventSecond', data, () => {
            console.log("data.isOpt")
            data.isOpt = !data.isOpt;
          });
        }

      }
    },
    // 操作项
    doOperateItemClick (item, data) {
      console.log(data, item, '操作项-item');
      // if (this.checkCanClick()) {
      this.$emit('doOperateItemClick', item, data);
      this.mouseOutEvent();
      // }
    },
    // 排序
    doListSort (sort, data, type) {
      // if (this.checkCanClick()) {

      if (type) {
        return false
      }
      this.$emit('doListSort', sort, data);

      console.log(sort, '排序');
      // }
    },
    checkCanClick () {
      let now = Date.new();
      let r = !this.lastClickTime || now - this.lastClickTime > 800;
      if (r) {
        this.lastClickTime = now;
      }
      return r;
    },
    doAddChildCourseBag () {
      this.$emit('doOperateItemClick', { key: 'add' }, this.dataInfo);
    }
  }
};
</script>

<style scoped>
.courseware_list {
  line-height: 20px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  position: relative;
  cursor: pointer;
  margin-bottom: 0px !important;
  /* margin: 10px 10px; */

  margin-right: 0px;
  /* width: 640px; */
  height: 103px;
  border-radius: 8px;
  padding: 20px 0px;
  /* border: 2px; */
}

.list_box_add {
  background: url(../../../../public/cw-img/courseware_btn@2X.png) no-repeat 50%;
  background-size: 22px;
  width: 46px;
  height: 33px;
  position: absolute;
  right: 0;
}
.courseware_box_name {
  font-size: 16px;
  /* padding-right: 10px; */
  /* width: 83%; */
  /* line-height: 39px !important; */
  height: 63px !important;
  position: relative;
  /* padding-left: 34px; */
  display: flex;
  flex: 1;
}
.courseware_name_text {
  max-width: 255px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 18px;
  /* line-height: 30px; */
}
.type2 .courseware_name_text {
  max-width: 188px;
  /* line-height: 30px; */
}
.courseware_box_name .cover_img {
  width: 112.6px;
  height: 63px;
  position: relative;
  left: 0;
  margin-right: 10px;
  border: 0.94px solid rgba(255, 255, 255, 0.4);
  border-radius: 6px;
  text-align: center;
}
.courseware_packge_list_item_bg {
  /* background: linear-gradient(
    225.87deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 90.8%
  ); */
  /* opacity: 0.35; */
  position: absolute;
  width: 112px;
  height: 63px;
  background: url('../../../../public/image/coureware_bg_highlight.png') center
    no-repeat;
  background-size: 100%;
  z-index: 1;
}
/* .courseware_box_name::after {
  content: '';
  background: url(../../../../public/cw-img/file_icon@2x.png) no-repeat 50%;
  background-size: 30px;
  width: 26px;
  height: 22px;
  position: absolute;
  top: 11px;
  left: 0;
} */
.flex_row .top_opt::before {
  content: '';
  background: url(../../../../public/image/arrows_up_icon_blue.png) no-repeat
    50%;
  background-size: 18px;
  width: 32px;
  height: 44px;
  position: absolute;
  right: -2px;
  top: -5px;
}
.flex_row .down_opt::before {
  content: '';
  background: url(../../../../public/image/arrows_bottom_icon_blue.png)
    no-repeat 50%;
  background-size: 18px;
  width: 32px;
  height: 42px;
  position: absolute;
  right: -2px;
  top: -3px;
}
.courseware_list.type3 {
  border: 2px solid rgba(236, 236, 236, 1);
  border-radius: 8px;
  height: 88px;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  padding: 10px;
}
.courseware_list.type2 {
  border: 2px solid rgba(236, 236, 236, 1);
  border-radius: 8px;
  height: 88px;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  padding: 10px;
}
.type2 .courseware_box_name {
  height: 63px !important;
}
.type2 .courseware_box_name .cover_img {
  width: 112px;
  height: 63px;
  position: relative;
  left: 0;
  margin-right: 10px;
  border: 0.94px solid rgba(255, 255, 255, 0.4);
  border-radius: 6px;
  text-align: center;
}

.type2 .courseware_packge_list_item_bg {
  /* background: linear-gradient(
    225.87deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 90.8%
  ); */
  /* opacity: 0.35; */
  position: absolute;
  width: 112px;
  height: 63px;
  background: url('../../../../public/image/coureware_bg_highlight.png') center
    no-repeat;
  background-size: 100%;
  z-index: 1;
}
.courseware_list.type2.opt {
  background-color: rgba(217, 223, 228, 0.4);
  /* color: #3498db; */
  border: 2px solid rgba(45, 152, 217, 1);
}
.courseware_list.type3.opt {
  background-color: rgba(217, 223, 228, 0.4);
  /* color: #3498db; */
  border: 2px solid rgba(45, 152, 217, 1);
}
.courseware_list.type3.opt .header_option_box_item {
  background: rgba(255, 255, 255, 1);
}
/* .courseware_list.type2.opt:before {
  content: '';
  background-color: #3498db;
  width: 3px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
} */
.courseware_list.type2 .courseware_box_name {
  /* margin-left: 28px; */
  /* width: 80%; */
}
.courseware_list.type2 .courseware_name_text {
  /* max-width: 55%; */
}
.courseware_box_name .status_type_yellow {
  width: 66px;
  height: 24px;
  border-radius: 5px;
  background: #ffeed0;
  color: black;
  font-family: Alibaba;
  font-size: 14px;
  margin-left: 10px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
}
.courseware_box_name .status_type_orange {
  width: 66px;
  height: 24px;
  border-radius: 5px;
  background: rgba(255, 122, 0, 0.2);
  color: black;
  font-family: Alibaba;
  font-size: 14px;
  margin-left: 10px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
}
.courseware_box_name .status_type_red {
  width: 66px;
  height: 24px;
  border-radius: 5px;
  background: rgba(248, 213, 213, 1);
  color: rgba(217, 45, 45, 1);
  font-family: Alibaba;
  font-size: 14px;
  margin-left: 10px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
}
.courseware_name_capacity_title {
  top: -4px !important;
  left: 203px !important;
}
.courseware_name_capacity {
  position: absolute;
  top: 17px;
  left: 35px;
}
.courseware_name_capacity_icon {
  width: 10px;
  height: 10px;
}
.courseware_name_capacity_text {
  font-size: 12px;
  color: #999999;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
.list_img_btn {
  right: 37px !important;
}
.courseware_list >>> .list_img_btn {
  top: 6px !important;
}
.fiexdTo {
  top: -55px !important;
}
.courseware_box_name_right_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header_btn_conent {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}

.list_img_btn_top .list_img_btn_sort {
  width: 30px;
  height: 30px;
  background: url(../../../../public/image/Vector_up.png) center no-repeat;
}

.list_img_btn_down .list_img_btn_sort {
  width: 30px;
  height: 30px;
  background: url(../../../../public/image/Vector_down.png) center no-repeat;
}
.list_img_btn_top.disable .list_img_btn_sort {
  background: url(../../../../public/image/Vector_up_g.png) center no-repeat;
}
.list_img_btn_down.disable .list_img_btn_sort {
  background: url(../../../../public/image/Vector_down_g.png) center no-repeat;
}
.list_img_btn_top,
.list_img_btn_down {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: rgba(241, 243, 247, 1);
  margin-right: 5px;
  cursor: pointer;
}
.type3.opt .list_img_btn_top,
.type3.opt .list_img_btn_down {
  background: white;
}
.type3.opt .list_img_btn_more {
  width: 32px;
  height: 32px;
  /* margin-right: 10px; */
  cursor: pointer;
  background: url(../../../../public/image/courseware_icon_04.png) no-repeat;
}
.list_img_btn_more {
  width: 32px;
  height: 32px;
  /* margin-right: 10px; */
  cursor: pointer;
  background: url(../../../../public/image/courseware_icon_more.png) no-repeat;
}

/* .list_img_btn_more:hover {
  background: url(../../../../public/image/courseware_icon_more.png) no-repeat;
} */
.courseware_list .header_option_box_item {
  display: inline-block;
  border-radius: 3px;
  background: rgba(217, 223, 228, 0.4);
  color: #999;
  font-size: 14px;
  height: 24px;
  text-align: center;
  margin-right: 0px;
  padding: 0 5px;
  line-height: 24px;
}

.cw_head_menu {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(236, 236, 236, 1);
}
.courseware_icon_03 {
  margin-right: -10px;
}
.courseware_icon_02,
.courseware_icon_03 {
  padding-left: 45px;
  border: none !important;
}
.courseware_icon_02:hover,
.courseware_icon_03:hover {
  /* color: #b9ddf5 !important; */
  background: none !important;
}
.courseware_icon_02::before {
  content: '';
  position: absolute;
  width: 28px;
  height: 28px;
  left: 10px;
  top: 3px;
  background: url(../../../../public/image/courseware_icon_02.png) center
    no-repeat;
  background-size: 23px;
}
.courseware_icon_03::before {
  content: '';
  position: absolute;
  width: 28px;
  height: 28px;
  left: 10px;
  top: 3px;
  background: url(../../../../public/image/courseware_icon_03.png) center
    no-repeat;
  background-size: 24px;
}
</style>
