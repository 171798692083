<template>
  <div class="serach_ul flex">
    <date-range
      style="margin-right: 0"
      v-if="tableType != 5"
      :searchObj="vSearchDataRange"
      :fromTitle="'推荐日期'"
      @changeDate="changeDate"
    ></date-range>
    <date-range
      style="margin-right: 0"
      v-if="tableType == 5"
      :searchObj="vSearchDataRange"
      :fromTitle="'签单日期'"
      @changeDate="changeDate"
    ></date-range>
    <el-select-option
      v-if="tableType == 2"
      style="margin-right: 0; margin-left: 20px"
      class="serach_list"
      :selectTitle="'签单进度'"
      :dataSourceList="statusInfoList"
      :selectionKey="selectStatusInfo.OLAPKey"
      @changeEvent="selectStatusList"
    ></el-select-option>
    <el-select-option
      v-if="tableType == 5"
      style="margin-right: 0; margin-left: 20px"
      class="serach_list"
      :selectTitle="'付款进度'"
      :dataSourceList="paymentScheduleList"
      :selectionKey="selectPaymentScheduleInfo.OLAPKey"
      @changeEvent="selectPaymentScheduleList"
    ></el-select-option>
    <input-search-contain-btn
      class="serach_input"
      v-model="params.searchText"
      :placeholder="'请输入'"
      @onSearchEvent="searchClick"
      @clearSearch="clearSearchParams"
    ></input-search-contain-btn>
  </div>
</template>

<script>
import elSelectOption from "../../report/common/el-select-option"; // 下拉选择框
import inputSearchContainBtn from "../../report/common/input-search-contain-btn";
export default {
  data() {
    return {
      statusInfoList: [
        {
          OLAPKey: 0,
          MainDemoName: "全部",
        },
        {
          OLAPKey: 1,
          MainDemoName: "新线索",
        },
        {
          OLAPKey: 2,
          MainDemoName: "跟进中",
        },
        {
          OLAPKey: 3,
          MainDemoName: "已预约",
        },
        {
          OLAPKey: 4,
          MainDemoName: "已考察",
        }
      ],
      selectStatusInfo: {
        OLAPKey: 0,
        MainDemoName: "全部",
      },
      paymentScheduleList: [
        {
          OLAPKey: 0,
          MainDemoName: "全部",
        },
        {
          OLAPKey: 1,
          MainDemoName: "已签单",
        },
        {
          OLAPKey: 2,
          MainDemoName: "付定中",
        },
        {
          OLAPKey: 3,
          MainDemoName: "已付定",
        },
        {
          OLAPKey: 4,
          MainDemoName: "尾款中",
        },
        {
          OLAPKey: 5,
          MainDemoName: "全款中",
        },
        {
          OLAPKey: 6,
          MainDemoName: "已全款",
        }
      ],
      selectPaymentScheduleInfo: {
        OLAPKey: 0,
        MainDemoName: "全部",
      },
    };
  },
  props: {
    params: Object,
    tableType: Number,
  },
  components: {
    elSelectOption,
    inputSearchContainBtn,
  },
  computed: {
    vSearchDataRange() {
      return { startTime: this.params.StartTime, endTime: this.params.EndTime };
    },
  },
  created() {
    this.getCorseInfoList();
  },
  methods: {
    changeDate(data) {
      this.params.StartTime = data.startTime;
      this.params.EndTime = data.endTime;
      this.$emit("searchClick");
    },
    selectStatusList(data) {
      this.selectStatusInfo = data;
      this.params.StatusKey = data.OLAPKey;
      this.$emit("searchClick");
    },
    selectPaymentScheduleList(data) {
      this.selectPaymentScheduleInfo = data;
      this.params.PaymentScheduleKey = data.OLAPKey;
      this.$emit("searchClick");
    },
    searchClick() {
      this.$emit("searchClick");
    },
    clearSearchParams() {
      this.selectStatusInfo = {
        OLAPKey: 0,
        MainDemoName: "全部"
      };
      this.selectPaymentScheduleInfo = {
        OLAPKey: 0,
        MainDemoName: "全部"
      };
      this.$emit("clearSearchParams");
    },
    getCorseInfoList() {},
  },
};
</script>

<style scoped>
.serach_ul {
  padding: 15px 0 20px;
}

.serach_list {
  width: 222px;
}

.serach_list:first-child {
  margin-right: 20px;
}

.serach_input {
  margin-left: 20px;
}
</style>