<template>
  <div>
      <div class="form_info_detail micro_message">
        <div class="content_marginRL single_shadow">
          <div class="message_edit_input"
               style="color: #fafafa">
            <textarea cols="30"
                      rows="10"
                      :maxlength="100"
                      :placeholder="'请输入排课备注'"
                      v-model="arrangeSupport"
                      @input="updateVal($event)"
                      @change="changeVal($event)"></textarea>
          </div>
        </div>
        <save-cancel-btn-group :isSingleSubmit="true"
                               :singleSubmitType="'dynamic'"
                               @confirmClick="comfirmEvent"
                               @cancelClick="closeEvent"></save-cancel-btn-group>
      </div>
  </div>
</template>

<script>
import { EditArrangeSupport } from '../../API/workbench.js';
export default {
  data () {
    return {
      arrangeSupport: ''
    };
  },
  props: {
    studentKey: { // 学生ID
      type: [Number, String],
      require: true
    },
    content: { // 正文
      type: String,
      require: true
    },
    placeholderText: {
      type: String,
      default: '请输入排课备注'
    }
  },
  created () {
    this.arrangeSupport = this.content;
  },
  methods: {
    updateVal (e) {
      this.onkeyupEvent(e);
    },
    changeVal (e) {
      this.onkeyupEvent(e);
    },
    // 学生排课备注 提交
    comfirmEvent (callBack) {
      if (this.arrangeSupport == '') {
        layer.alert('请输入内容');
        if (typeof callBack == 'function') {
          callBack();
        }
      } else {
        EditArrangeSupport(this.studentKey, this.arrangeSupport).then(
        res => {
          layer.alert('修改成功');
          if (typeof callBack == 'function') {
            callBack();
          }
          this.closeEvent();
          this.$emit('updateSuccess', this.arrangeSupport);
        },
        err => {
          if (typeof callBack == 'function') {
            callBack();
          }
          layer.alert(err.msg);
        }
      );
      }
    },
    closeEvent () {
      this.$emit('closeDialog');
    },
    limitMaxLength (str, maxLen) {
      var result = [];
      var re = /^[a-zA-Z]+$/;
      for (var i = 0; i < maxLen; i++) {
        var char = str[i];
        if (!re.test(char)) { maxLen--; }
        result.push(char);
      }
      return result.join('');
    },
    onkeyupEvent (e) {
      // let val = e.target.value;
      // if (this.$utils.strLength(val) > this.maxLength * 2) {
      //   e.target.value = this.limitMaxLength(val, this.maxLength * 2);
      // }    if (this.$utils.strLength(val) > this.maxLength * 2) {
      //   e.target.value = this.limitMaxLength(val, this.maxLength * 2);
      // }
    }
  }
};
</script>
<style>
</style>