<!-- 课时 -->
<template>
  <el-table-column :fixed="columnData.fixed||false"
                   :label="columnData.label"
                   :prop="columnData.prop"
                   :width="columnData.width||100"
                   :sortable="columnData.sortable"
                   :align="columnData.align||'center'"
                   :sort-orders="['ascending', 'descending']"
                   :label-class-name="columnData.columnClass"
                   :render-header="columnData.renderHeader"
                   :show-overflow-tooltip="true">
    <template slot-scope="scope">
      <div @click="clickEvent(scope.row,$event)">
        <span :class="scope.row.ISClassHourDebtKey == 1?'':'class_hour_can_click'" v-if="scope.row.ClassCountCash&&scope.row.ClassCountCash>0&&scope.row[columnData.prop]=='补课'" :style="scope.row.ISClassHourDebtKey == 1?'':'cursor: pointer'">
          {{Number(scope.row.ClassCountCash)}}
        </span>
        <span :class="[ (Number(scope.row[columnData.prop])>0||scope.row.SemesterCalShow)&&columnData.extend && typeof columnData.extend.click == 'function'?vClassName:'',columnData.extend&&typeof(columnData.extend.setClassName)=='function'&&columnData.extend.setClassName(scope.row)]"
          :style="vStringClick?'cursor: pointer':''"
          >
          {{formatData(scope.row)}}
        </span>
        <span v-if="columnData.extend &&typeof(columnData.extend.setShowOwe)=='function'&&columnData.extend.setShowOwe(scope.row)"
              class="table_font_red">
          欠
        </span>
      </div>
      <span class="new_bottom_warn"
            v-if="scope.row[columnData.prop+'_Warn']">
        <el-popover v-if="scope.row[columnData.prop+'_Pop_Tips']"
                    trigger="hover"
                    placement="top">
          <p v-html="scope.row[columnData.prop+'_Pop_Tips']"></p>
          <div slot="reference"
               class="name-wrapper-NO">
            <div size="">{{scope.row[columnData.prop+'_Warn']}}</div>
          </div>
        </el-popover>
        <span v-else>{{scope.row[columnData.prop+'_Warn']}}</span>
      </span>
      <!-- 角标 -->
      <div v-if="columnData.extend &&columnData.extend.isShowOweMark&&Number(scope.row.DebtClassCount)>0">
        <el-popover placement="top"
                    class="top"
                    width="100"
                    trigger="hover"
                    popper-class="new_popper_class"
                    :content="'欠'+$utils.mAmountType(Number(scope.row.DebtClassCount),1)+'课时'">
          <div class="warning_mark"
               slot="reference"></div>
        </el-popover>
      </div>
    </template>
  </el-table-column>
</template>
<script>
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          extend: {
            symbol: '', // 是否需要带符号 'plus-minus':加减,
            setShowOwe: () => { },  // funciton,  判断是否显示欠课时.
            isShowOweMark: false, // 是否显示欠课时角标
            click: () => { },  // 表示该列可点击，点击需要的其它操作直接写在回调函数里
            isStringClick: false // -是否支持点击
          }
        };
      }
    }
  },
  created () {
  },
  computed: {
    vClassName () {
      let className = 'class_hour_can_click';
      if (this.columnData.extend && this.columnData.extend.className) {
        className = this.columnData.extend.className;
      }
      return className;
    },
    vStringClick () {
      let StringClick = false
      if (this.columnData.extend && this.columnData.extend.isStringClick) {
        StringClick = this.columnData.extend.isStringClick;
      }
      return StringClick
    }

  },
  methods: {
    clickEvent (rowData, e) {
      console.log(rowData, e);
      // 表示是否支持点击
      if (this.columnData.extend && typeof this.columnData.extend.click == 'function') {
        // 展开列
        if (this.columnData.extend.type == 'expand') {
          if (!this.columnData.extend.isStringClick){
            if (this.formatData(rowData) == '-') {
              return false;
            }
          }
          let cellClientWidth = e.currentTarget.parentNode.offsetParent.clientWidth;
          let positionLeft = e.currentTarget.parentNode.offsetParent.offsetLeft + cellClientWidth / 2 - 10;
          this.$emit('onClickExpandRow', rowData, this.columnData, positionLeft);
        } else {
          // 普通点击事件 直接执行回调
          this.columnData.extend.click(rowData);
        }
      }
    },
    // 保留小数位数
    formatData (rowData) {
      if (!rowData.isEmpertyRow) {
        let numData = rowData[this.columnData.prop];
        if (numData === '') {
          numData = '';
        } else if (numData == 0 && rowData.SemesterCalShow) {
          // 兼容学期卡显示
          numData = rowData.SemesterCalShow;
        } else {
            if (Number(numData) > 0) {
            numData = this.$utils.mAmountType(numData, 1);
          } else if(Number(numData) == 0){
            numData = '-';
          }else{
            numData=numData
          }
          
          // 需要正负号
          if (this.columnData.extend && this.columnData.extend.symbol == 'plus-minus') {
            if (numData > 0) {
              numData = '+' + numData;
            } else if (numData < 0) {
            } else {
              numData = 0;
            }
          }
        }
        return numData;
      } else {
        return '';
      }
    }
  }
};
</script>

<style>
</style>