<template>
  <div>
    <!-- 总部版本 -->
    <div v-if="isShowMaxCoursewareList">
      <!-- 大课包 -->
      <max-courseware-list ref="maxCoursewareList"
                           :type="coursewareTypeKey"
                           :maxCoursewareList="maxCoursewareList"
                           :coursewareInfo="vMaxCoursewareInfo"
                           :statInfo="statInfo"
                           @setSortList="setSortList"
                           @afterSuccess="getCoursewareStat"
                           @selectEvent="selectEvent"
                           @doListSort="doListSort"
                           @doOperateItemClick="doOperateItemClick"
                           @selectCoursewareType="selectCoursewareType"
                           @editCoursewarePackageAfterSuccess="getParentCourseWarePackageList"></max-courseware-list>
    </div>

    <div v-else>
      <!-- 小课包 -->
      <single-courseware-list :type="coursewareTypeKey"
                              :maxCoursewareInfo="vMaxCoursewareInfo"
                              @editCoursewarePackageAfterSuccess="getParentCourseWarePackageList"></single-courseware-list>
    </div>
    <div>
      <dialog-factory ref="hqCoursewareManagementDialogFactory"
                      :key="'hqCoursewareManagement'"
                      :routerName="'hqCoursewareManagement'"></dialog-factory>
      <dialog-factory-common ref="hqCoursewareManagementCommonDialogFactory"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import {
  hqGetParentCourseWarePackageList,
  hqParentCourseWarePackageSort,
  // hqDeleteParentCourseWarePackage,
  hqCoursewarePackageManageDisable,
  CWSCoursewarePackageManageDisable,
  CoursewarePackageManageDisable,
  HQgetCoursewareStat
} from '../../../../API/workbench';
import maxCoursewareList from '../../../courseware-management/max-courseware-list/index';
import singleCoursewareList from './single-courseware-list';
// import dialogFactory from '../../../common/dialog-factory';
export default {
  name: 'hqCoursewareManagement',
  // 传递给课件回收站
  provide () {
    return {
      generalOrHq: this.generalOrHq,
      coursewarePackage: this.coursewarePackage,
      getParentCourseWarePackageList: this.getParentCourseWarePackageList,
      getCoursewareStat:this.getCoursewareStat
    };
  },
  data () {
    return {
      isShowMaxCoursewareList: true,
      maxCoursewareList: [], // 查询大课包列表-正常
      coursewareTypeKey: 'ZZ', // 课件类型
      maxCoursewareInfo: {}, // 大课包信息
      coursewarePackageID: '', // 支持外部进入选中查看(课件包ID)
      coursewareEditInfo: {},
      coursewareId: '',
      isLoadData: true,
      generalOrHq: 'hq', // 门店点击 general  总部点击 hq
      coursewarePackage: 'bigCourseware', // 传递给回收站 是从大课包传入的信息
      statInfo: {},
      chooiceKey:''
    };
  },
  computed : {
    vMaxCoursewareInfo (){
      let obj = {}
      if (this.maxCoursewareList.length>0&&this.chooiceKey!=''){
          this.maxCoursewareList.forEach((item,index) => {
            if(item.ID == this.chooiceKey){
              obj= item
            }
          });
      }
      return obj
    }
  },
  components: {
    maxCoursewareList,
    singleCoursewareList,
    dialogFactory: () => import('../../../common/dialog-factory')
  },
  created () {
    console.log('this.$route.params11111',this.$route.params)
    if(!this.$route.params.data||!this.$route.params.data.levelPath){
      this.getParentCourseWarePackageList(()=>{
        // this.enterRouterInit()
      });
      this.getCoursewareStat()
    }else if(this.$route.params.data.isNew){
      if(this.$route.params.data&&this.$route.params.data.levelPath){
         this.isShowMaxCoursewareList=true
                      this.getParentCourseWarePackageList(()=>{
                        this.enterRouterInit()
                      });
                      this.getCoursewareStat()
      }
    }
    
    console.log("this.$route",this.$route.params)
  },
  activated () {
    // this.isShowMaxCoursewareList = true;
    // if ((this.$route.params.dataKey && this.$route.params.dataKey > 0) || this.coursewarePackageID) { // 外部进入定位
    //   this.coursewarePackageID = this.$route.params.dataKey || this.coursewarePackageID;
    //   if (this.coursewareTypeKey == 'ZZ') {
    //     this.getParentCourseWarePackageList();
    //   }
    // }
     console.log('外部进入定位', this.$route.params);
    
    if(this.$route.params.data&&this.$route.params.data.levelPath&&!this.$route.params.data.isNew){
       layer.confirm(
              "是否跳转到子课包"+this.$route.params.data.Title+'?',
              {
                btn: [
                  {
                    name: '确认',
                    callBack: ()=> {
                      this.isShowMaxCoursewareList=true
                      this.getParentCourseWarePackageList(()=>{
                        this.enterRouterInit()
                      });
                      this.getCoursewareStat()
                      layer.close();
                    }
                  }
                ]
              }
            );
      
    }
    
  },
  methods: {
    enterRouterInit(){
     
       if(this.$route.params.data&&this.$route.params.data.levelPath){
         
            let dataArr= this.$route.params.data.levelPath.split('.')
            if(dataArr[0]==''){
              dataArr.splice(0,1)
            }
            console.log("dataArr",dataArr)
            if(this.maxCoursewareList.length>0){
              let item = this.maxCoursewareList.find(p=>{
                return p.ID==dataArr[0]
              })
              console.log("item",item)
              this.selectEvent(item)
            }
          }
    },
    // 查询大课包列表-总部(自研)
    getParentCourseWarePackageList (callback) {
      hqGetParentCourseWarePackageList().then(
        result => {
          // this.coursewareTypeKey = 'ZZ';
          this.maxCoursewareList = result.data;
          if(callback){
            callback()
          }
          console.log('getParentCourseWarePackageList', this.$route.params);
         
          console.log('查询大课包列表-总部', result.data);
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 查询课件包列表-回收站
    getRecycleCoursewarePackage () {
      // hqSearchCourseWarePackageListForRecycle().then(result => {
      //   this.coursewareTypeKey = 'HSZ';
      //   this.maxCoursewareList = result.data;
      //   if (this.maxCoursewareList.length > 0) {
      //     this.selectEvent(result.data[0]);// 默认第一项
      //   } else {
      //     this.coursewareContentList = [];
      //     this.maxCoursewareInfo = {};
      //   }
      //   console.log('查询课件包列表-回收站(总部)', result.data);
      // }, error => {
      //   layer.alert(error.msg);
      // });
    },
     setSortList(data,index){
       hqParentCourseWarePackageSort(data.ID, index).then(result => {
        this.getParentCourseWarePackageList();
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 课件包排序
    doListSort (sortType, data) {
      let changeToSort =
        sortType == 'Top'
          ? Number(data.ShowOrder) - 1
          : Number(data.ShowOrder) + 1;
      hqParentCourseWarePackageSort(data.ID, changeToSort).then(
        result => {
          this.getParentCourseWarePackageList();
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },

    // 根据指定课件包ID获取课件列表
    selectEvent (dataInfo) {
      this.chooiceKey=dataInfo.ID
      this.maxCoursewareInfo = dataInfo;
      this.isShowMaxCoursewareList = false;
    },
    // 重新加载数据
    selectCoursewareType (item, data) {
      // data,用于选中当前项
      console.log('重新加载数据', item);
      this.coursewarePackageID = data && data.ID ? data.ID : '';
      if (item.key == 'ZZ') {
        this.getParentCourseWarePackageList();
      } else if (item.key == 'HSZ') {
        this.getRecycleCoursewarePackage();
      }
    }, 
    getCoursewareStat () {
      HQgetCoursewareStat().then(result => {
        console.log('统计数据', result.data);
        this.statInfo = result.data;
      });
      
    },
    // 课件包操作
    doOperateItemClick (item, data) {
      console.log(item, data, '操作项');
      switch (item.key) {
        // case 'copy':
        //   hqCoursewarePackageManageCopy(data.ID).then(result => {
        //     layer.alert('复制成功');
        //     this.coursewarePackageID = result.data.CoursewarePackageID;
        //     this.getParentCourseWarePackageList();
        //   }, error => {
        //     layer.alert(error.msg);
        //   });
        //   break;
        // case 'delete':
        //   layer.confirm('确定删除该课件包?', {
        //     btn: [
        //       {
        //         name: '确认',
        //         callBack: () => {
        //           hqDeleteParentCourseWarePackage(data.ID).then(
        //             result => {
        //               this.getParentCourseWarePackageList();
        //             },
        //             error => {
        //               layer.alert(error.msg);
        //             }
        //           );
        //           layer.close();
        //         }
        //       }
        //     ]
        //   });
        //   break;
        case 'disable':
          layer.confirm('停用该课件包,所有该课件包的授权将会被暂停,是否继续停用?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  let apiFN = '';
                  let type = this.$store.getters.SaaSClientInfo.EditionKey;
                  if (type == 6) {
                    apiFN = CWSCoursewarePackageManageDisable;
                  } else if (type == 5) {
                    apiFN = hqCoursewarePackageManageDisable;
                  } else {
                    apiFN = CoursewarePackageManageDisable;
                  }
                  apiFN(data.ID).then(
                    result => {
                      this.getParentCourseWarePackageList();
                      this.getCoursewareStat();
                    },
                    error => {
                      layer.alert(error.msg);
                    }
                  );
                  layer.close();
                }
              }
            ]
          });
          break;
        // case 'enable':
        //   hqCoursewarePackageManageEnable(data.ID).then(result => {
        //     layer.alert('启用成功');
        //     this.getRecycleCoursewarePackage();
        //   }, error => {
        //     layer.alert(error.msg);
        //   });
        //   break;
        default:
          break;
      }
    }
  }
};
</script>