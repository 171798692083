<template>
  <div>
    <div class="form_info_edit">
      <!-- <div v-if="formData.PackageKey > 0" class="pr label_radio_bg_white">
      <input-dialog :formTitle="'有效期'"
                    :required="true"
                    :readonly="isReadonly"
                    @showEldilog="openPeriodOfValidityDialog"
                    v-model="vPeriodOfValidity"></input-dialog>
    </div>
    <div v-else> -->
      <div v-show="vCourseCard||(vCourseCard&&vYearCard)"
           class="pr label_radio_bg_white">
        <input-dialog :formTitle="vShowTitle"
                      :isTitleClick="true"
                      :required="true"
                      :readonly="isReadonly"
                      @showEldilog="openCourseCardEndDate"
                      v-model="vCourseCardEndDate"></input-dialog>
      </div>
      <div v-show="vYearCard&&!vCourseCard"
           class="pr label_radio_bg_white">
        <input-dialog :formTitle="'生效日期'"
                      :required="true"
                      :readonly="isReadonly"
                      @showEldilog="openYearCardStartDate"
                      v-model="vYearCardStartDate"></input-dialog>
      </div>

      <!-- </div> -->
      <div class="label_radio_bg_white">
        <div class="form_info_edit form_item form-input form_info_line ">
          <div class="form_info_field">
            <div class="form_info_icon_coupon"></div><span v-if="formData.DepositAmount>0"
                  class="form_info_icon_coupon_text">含预付款{{Number(formData.DepositAmount)}}</span>
          </div>
          <div class="form_info_value"
               :class="{form_info_select:!isReadonly}"
               @click="openMyCouponList">
            <input type="text"
                   readonly
                   :placeholder="vCouponPlaceholder"
                   :class="{input_readonly:isReadonly}"
                   :value="vCouponAmount">
          </div>
        </div>
      </div>
      <div class="pr label_radio_bg_white">
        <span class="input_center_text"
              v-if="formData.ReduceAmount>0">优惠{{$utils.mMoneyType(formData.ReduceAmount,2)}}</span>
        <input-number :min="0"
                      :max="Number(formData.OrderAmount)-Number(formData.CouponAmount)"
                      :placeholder="'0.00元'"
                      :value="vRecableAmount"
                      :decimalPlaces="2"
                      :formTitle="'应收金额'"
                      :readonly="isReadonly"
                      @inputChange="doAfterChangeRecableAmount"></input-number>
      </div>
      <div class="pr label_radio_bg_white">
        <input-text :formTitle="'备注'"
                    :readonly="isReadonly"
                    :maxLength="100"
                    v-model="formData.CommentScript"></input-text>
      </div>
    </div>
    <custom-dialog :title="vEditDateTitle"
                   width="410px"
                   show-position="visibleMiddle"
                   ref="validityPeriod"
                   :visible.sync="isShowPeriodOfValidity"
                   :append-to-body="false"
                   :close-on-click-modal="false"
                   :before-close="colsePeriodOfValidity">
      <period-of-validity :validityPeriod="vPeriodOfValidity"
                          :periodCount="formData.PeriodCount"
                          :unitKey="formData.UnitKey"
                          :editType="editType"
                          :isRequrid="vCourseCard&&vYearCard"
                          @closeValidityPeriod="colsePeriodOfValidity"
                          @confirmPeriodOfValidity="confirmPeriodOfValidity"></period-of-validity>

    </custom-dialog>
    <custom-dialog :title="'选择优惠券'"
                   :visible.sync="isCouponListDialogShow"
                   :before-close="closeCouponListDialog">
      <div class="WKT_discount_coupon_popup_ul">
        <coupon-list :isShowbtn="false"
                     :isBrowse="false"
                     :couponList="vCouponList"
                     :selectionKey="couponInfo.you_hui_id"
                     @doSelectCouponAdded="doAfterSelectionCoupon"></coupon-list>
      </div>
    </custom-dialog>

  </div>
</template>

<script>
import periodOfValidity from './period-of-validity';
import couponList from '../../../../wkt-back/coupon/coupon-list/index';
export default {
  props: {
    formData: Object,
    isReadonly: Boolean,
    couponList: Array,
    curSelectionCoupon: Object
  },
  data () {
    return {
      isShowPeriodOfValidity: false,
      isCouponListDialogShow: false,
      couponInfo: {},
      editType: '',
      newStartDate: null
    };
  },
  components: {
    periodOfValidity,
    couponList
  },
  computed: {
    vEditDateTitle () {
      if (this.editType == 'year') {
        return '年卡生效日';
      } else if (this.editType == 'course') {
        return '课时失效日';
      } else {
        return '有效期';
      }
    },
    vYearCard () {
      let flag = false;
      this.formData.KDNR.forEach(e => {
        if (e.UnitKey != 5) {
          flag = true;
        }
      });
      return flag;
    },
    vCourseCard () {
      let flag = false;
      this.formData.KDNR.forEach(e => {
        if (e.UnitKey == 5) {
          flag = true;
        }
      });
      return flag;
    },
    vRecableAmount () {
      if (this.$utils.mMoneyType(this.formData.RecableAmount - Number(this.formData.DepositAmount), 2) > 0) {
        return this.$utils.mMoneyType(this.formData.RecableAmount - Number(this.formData.DepositAmount), 2);
      } else {
        return 0;
      }
    },
    vPeriodOfValidity () {
      return this.formData.StartTime + '~' + this.formData.EndTime + '\t' + Number(this.formData.PeriodCount).toFixed(0) + (this.formData.UnitKey == 3 ? '个' : '') + this.formData.UnitKeyValue;
    },
    vCourseCardEndDate () {
      return this.formData.EndTime?this.formData.EndTime.replace(/\./g, '-'):'';
    },
    vYearCardStartDate () {
      if (this.formData.StartTime) {
        return this.formData.StartTime.replace(/\./g, '-');
      } else {
        return this.$utils.formatDateToLine(Date.new()).replace(/\./g, '-');
      }
    },
    vCouponAmount () {
      let amount = '';
      if (this.formData.CouponAmount > 0) {
        amount = -this.formData.CouponAmount;
      } else if (this.isReadonly && this.formData.CouponAmount == 0) {
        amount = '未使用优惠券';
      }
      return amount;
    },
    vShowTitle () {
      console.log('this.formData', this.formData);
      if (this.vCourseCard) {
        if (this.newStartDate) {
          return `<span>生效日期&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:` + (this.isReadonly ? '#999999' : '#3498db') + `;cursor: pointer;">` + this.newStartDate + `</span>&nbsp;&nbsp;&nbsp;&nbsp;失效日期</span>`;
        } else {
          if (!this.vYearCard) {
            let text = this.formData.StartTime && this.formData.StartTime != '1900-01-01' ? this.formData.StartTime : `&nbsp;&nbsp;&nbsp;&nbsp;不限&nbsp;&nbsp;&nbsp;&nbsp;`;
            return `<span>生效日期&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:` + (this.isReadonly ? '#999999' : '#3498db') + `;cursor: pointer;">` + text + `</span>&nbsp;&nbsp;&nbsp;&nbsp;失效日期</span>`;
          } else {
            this.formData.StartTime =this.formData.StartTime?this.formData.StartTime: this.$utils.formatDateToLine(Date.new());
            return `<span>生效日期&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:` + (this.isReadonly ? '#999999' : '#3498db') + `;cursor: pointer;">` + this.formData.StartTime + `</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;失效日期</span>`;
          }
        }
      } else {
        if (this.formData.StartTime && this.formData.StartTime != '1900-01-01') {
          return `<span>生效日期&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:` + (this.isReadonly ? '#999999' : '#3498db') + `;cursor: pointer;">` + this.formData.StartTime + `</span>&nbsp;&nbsp;&nbsp;&nbsp;失效日期</span>`;
        } else {
          return `<span>生效日期&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:` + (this.isReadonly ? '#999999' : '#3498db') + `;cursor: pointer;">不限</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;失效日期</span>`;
        }
      }
    },
    vCouponPlaceholder () {
      let str = '无可用优惠券';
      if (this.vCouponList.length > 0) {
        str = '选择优惠券';
      }
      return str;
    },
    vCouponList () {
      let canUserableCoupons = [];
      let recAmount = this.formData.OrderAmount - this.formData.ComboDiscountAmount;
      // status 状态1：正常 2 已使用 3.已过期
      this.couponList.forEach(obj => {
        if (obj.status == 1) {
          let data = this.$utils.parseJson(obj);
          // type：2满减, 3.特价 4.代金券
          if (obj.type == 2 && recAmount < obj.xiao_fei_tiao_jian) { // 未达到满减条件 不可用
            this.$set(data, 'unSelection', true);
          } else if (Date.new(obj.time_start) > Date.new(this.$utils.formatDateToLine(Date.new())) || Date.new(obj.time_end) < Date.new(this.$utils.formatDateToLine(Date.new()))) { // 过期或者未开始 不可用
            this.$set(data, 'unSelection', true);
          } else if (obj.taocanid > 0 && obj.taocanid != this.formData.PackageKey) { // 适用某个套餐的券 当前未选择该套餐 不可用
            this.$set(data, 'unSelection', true);
          }
          canUserableCoupons.push(data);
        }
      });
      return canUserableCoupons;
    }
  },
  created () {
    this.couponInfo = this.curSelectionCoupon || {};
  },
  methods: {
    openYearCardStartDate () {
      this.editType = 'year';
      this.isShowPeriodOfValidity = true;
    },
    openCourseCardEndDate () {
      if (this.vCourseCard) {
        this.editType = 'course';
      } else {
        this.editType = 'year';
      }

      this.isShowPeriodOfValidity = true;
    },
    // 打开有效期设置
    openPeriodOfValidityDialog () {
      this.editType = '';
      this.isShowPeriodOfValidity = true;
    },
    // 有效期确认
    confirmPeriodOfValidity (startDate, endDate, numberCount, unitInfo) {
      this.formData.StartTime = startDate;
      this.formData.EndTime = endDate;
      this.formData.PeriodCount = numberCount;
      this.formData.UnitKey = unitInfo.key;
      this.formData.UnitKeyValue = unitInfo.value;
      if (this.vCourseCard) {
        this.newStartDate = startDate;
        this.newStartDate.replace(/\./g, '-');
      }
      this.colsePeriodOfValidity();
    },
    // 关闭有效期
    colsePeriodOfValidity () {
      this.isShowPeriodOfValidity = false;
    },
    doAfterChangeRecableAmount (value) {
      let recAmount = this.formData.OrderAmount - this.formData.CouponAmount - this.formData.ComboDiscountAmount;
      // value > 应收金额
      if (value > recAmount) {
        value = recAmount;
      }
      this.formData.RecableAmount = value;
      this.formData.ReduceAmount = this.$utils.mMoneyType(recAmount - value, 2);

      this.formData.ActualColAmount = this.formData.RecableAmount;
    },
    // 选择优惠券 优惠券使用规则 满1000减200, 1000=OrderAmount - ComboDiscountAmount
    openMyCouponList () {
      console.log(this.formData.PackageKey, this.couponList);
      if (!this.isReadonly) {
        this.isCouponListDialogShow = true;
      }
    },
    // 关闭优惠券
    closeCouponListDialog () {
      this.isCouponListDialogShow = false;
    },
    // 选中优惠券
    doAfterSelectionCoupon (item) {
      console.log(item, '选中优惠券');
      this.closeCouponListDialog();
      this.couponInfo = item;
      this.formData.DepositAmount = this.$utils.mMoneyType(Number(item.buy_amount), 2);
      // let recAmount = this.$utils.mMoneyType(this.formData.OrderAmount - this.formData.ComboDiscountAmount, 2);
      // item.di_xian_jin = this.$utils.mMoneyType(Number(item.di_xian_jin), 2);
      // // type 1:满折 2：满减 3:特价 4:代金
      // if (item.type == 2) {
      //   this.formData.CouponAmount = item.di_xian_jin;
      // } else if (item.type == 3) {
      //   if (Number(this.formData.OrderAmount) - Number(this.formData.ComboDiscountAmount) <= Number(item.you_hu_money)) {
      //     this.formData.CouponAmount = recAmount;
      //   } else {
      //     this.formData.CouponAmount = this.$utils.mMoneyType(this.formData.OrderAmount - this.formData.ComboDiscountAmount - Number(item.you_hu_money), 2);
      //   }
      // } else if (item.type == 4) {
      //   if (recAmount <= item.di_xian_jin) { // 应收金额 < 代金券金额 则优惠券金额 = 应收金额
      //     this.formData.CouponAmount = recAmount;
      //   } else {
      //     this.formData.CouponAmount = item.di_xian_jin;
      //   }
      // }
      this.formData.CouponKey = item.you_hui_id;// 优惠券ID 0表示不用优惠券
      this.formData.CouponKeyValue = item.scopeExplain; // 券名
      this.formData.CouponType = item.type; // 券类型 2满减券 3特价券 4代金券
      this.$emit('afterChooseCouponEvent', item);
    }
  }
};
</script>

<style>
</style>