<template>
  <div class="bg_white">
    <div class="debt_course_detail_info border_bottom_bold_gray">
      <div class="item_info">
        <div class="item_info_top">
          <div class="item_info_date " style="width: 100%;">
            {{courseInfo.CourseNameKeyValue}}
          </div>
          <div  class="index_course_right_btn" @click="goDebtClassCount">还课时</div>
        </div>
        <div class="item_info_bottom">
          <div class="item_info_date">
            欠
            <span class="font_red font_margin">{{courseInfo.DebtCount}}</span>课时
          </div>
          <div class="item_info_name">

          </div>

        </div>
      </div>
    </div>
    <div class="attendance_course_info_ul bg_white">
      <atten-item v-for="(item,key) in dataList"
                  :key="key"
                  :item="item"
                  :isDebtComponent="true"></atten-item>
      <basics-nodata-tips v-if="dataList.length==0"></basics-nodata-tips>
    </div>
  </div>
</template>

<script>
import { DebtClassHourArrangeListByStu } from '../../../../../../API/workbench';
import attenItem from '../../attention-list/atten-item.vue';
export default {
  data () {
    return {
      dataList: [],
      courseInfo: {}
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: {}
    }
  },
  components: {
    attenItem
  },
  created () {
    this.courseInfo = {
      studentKey: this.dataInfo.studentKey,
      CourseNameKey: this.dataInfo.CourseNameKey,
      CourseNameKeyValue: this.dataInfo.CourseNameKeyValue,
      DebtCount: Number(this.dataInfo.DebtCount) || 0

    };
    this.getDataList(this.courseInfo.studentKey, this.courseInfo.CourseNameKey);
  },

  mounted () {
    this.$dialog.register(this, ['attendanceArrange','debtCourseHourseDetails']);
    // 学有关课程考勤的变更通知
    this.$bus.on(this, 'AfterChangeAttendanceStatus', (scheduleCourseKey, studentKey) => {
      if (studentKey == this.courseInfo.studentKey) {
        this.getDataList(this.courseInfo.studentKey, this.courseInfo.CourseNameKey);
      }
    });
  },
  computed: {

  },
  methods: {
    getDataList (studentKey, CourseNameKey) {
      DebtClassHourArrangeListByStu(studentKey, CourseNameKey).then(result => {
        result.data.forEach(o => {
          o.ISClassHourDebtKey = 1;
        });
        result.data.sort((x, y) => {
          return Date.new(y.ClassTime.substring(0, 10) + ' ' + y.ClassTime.substring(0, 5)) - Date.new(x.ClassTime.substring(0, 10) + ' ' + x.ClassTime.substring(0, 5));
        });
        this.dataList = result.data;
        console.log('DebtClassHourArrangeListByStu', result.data);
      }, error => {
        layer.alert(error.msg);
      });
    },
    goDebtClassCount(){
      let studentInfo={
        OLAPKey:this.courseInfo.studentKey,
        CourseNameKey:this.courseInfo.CourseNameKey
      }
       this.$dialog.open(this, {
          routerName: this.$route.name,
          moduleName: 'debtCourseHourseDetails',
          data: { studentItem: studentInfo },
          callBack: {
            backClassHourSuccess: (studentID) => {  this.$emit("afterSucces");this.$emit("closeDialog") }
          }
        });
	}
  }
};
</script>


<style scope>
.debt_course_detail_info {
  position: relative;
  border-radius: 4px;
  padding-left: 5px;
  width: 100%;
  margin: 0px;
}
.debt_course_detail_info .item_info {
  padding-left: 10px;
}
.debt_course_detail_info .item_info_top {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  display: flex;
  padding-top: 13px;
}
.debt_course_detail_info .item_info_bottom {
  color: #999;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  padding-top: 5px;
  padding-bottom: 10px;
}
.border_bottom_bold_gray {
  border-bottom: 8px solid #f8f8f8 !important;
}
.index_course_right_btn{
	width: 56px;
	height: 22px;
	border-radius: 11px;
	border: 1px solid #E5E5E5;
	font-size: 12px;
	line-height: 20px;
	text-align: center;
	margin-right: 15px;
}

</style>
