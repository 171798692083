<template>
  <div class="course_statistics_echarts">
    <div class="course_statistics_title">
      <div class="title_date"
           style="min-width:200px">
        <span>选择年份</span>
        <div class="title_date_select">
          <div class="el-select">
            <div class="el-input el-input--small el-input--suffix is-focus">
              <single-year-picker :minDate="vMinDate"
                                  :maxDate="vMaxDate"
                                  :isShowHistory="isShowHistory"
                                  ref="monthPicker"
                                  @chgDate="chgDate"></single-year-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="change_history_data"
           @click.stop="historyBtn(historyName)">{{ historyName }}</div>
      <div class="chain_mendian_center_left title_number flex"
           style="flex: 1;justify-content: space-between;">
        <div class="left_type_text type_blue">
          签到
        </div>
        <div class="left_type_text type_red">
          请假
        </div>
        <div class="left_type_text type_green"
             v-if="this.$utils.getSaaSClientSet(59) > 0">
          旷课
        </div>
      </div>
      <!-- <div class="title_number">
        <span>共计</span>
        <span class="font_number">{{yearTotal}}</span>
        <span>元</span>
      </div> -->
    </div>
    <div class="course_statistics_echarts_box">
      <threeLineEchart :echartID="'dual_histogram3'"
                       :reqList="monthList"
                       :nowDataList="nowList"
                       :preDataList="oldList"
                       :nextDataList="nextList"
                       :yearDate="seletedDate"
                       :echartType="'line'"
                       ref="echart"></threeLineEchart>
    </div>
  </div>
</template>
<script>
import singleYearPicker from '../../base-module/date-picker-common/single-year-picker';
import threeLineEchart from '../../base-module/echart-common/three-line-echart';
import { MessageSearchClassAwayByYear, getKQStatisticsForMonth, getKQStatisticsForMonthH } from '../../../API/workbench';
export default {
  data () {
    return {
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      seletedDate: Date.new(Date.new().setDate(1)),
      searchObj: {
        startTime: '',
        endTime: '',
        CourseKey: 0,
        GradeKey: 0
      },
      nowList: [],
      nowDataList: [],
      oldList: [],
      nextList: [],
      yearTotal: 0,
      monthList: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    };
  },
  components: {
    threeLineEchart, singleYearPicker
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    }
  },
  mounted () {
    this.searchObj.startTime = Date.new().getFullYear() + '/01/01';
    this.searchObj.endTime = Date.new().getFullYear() + '/12/31';
    this.getReportList();
  },
  methods: {
    historyBtn (name) {
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.searchObj.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-01-01';
        this.searchObj.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isShowHistory = true;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.searchObj.startTime = new Date(new Date().setFullYear(new Date().getFullYear())).getFullYear() + '-01-01';
        this.searchObj.endTime = new Date(new Date().setFullYear(new Date().getFullYear())).getFullYear() + '-12-31';
        this.isShowHistory = false;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.getReportList();
      this.$refs.monthPicker.changeHistoryTime(this.searchObj.endTime);
    },
    chgDate (val, startTime, endTime) {
      this.seletedDate = val;
      this.searchObj.startTime = startTime;
      this.searchObj.endTime = endTime;
      this.getReportList();

      // this.getNowKQDataByDay(this.startTime, this.endTime, this.getLastKQDataByDay);
    },
    getReportList (callback) {
      let fn = '';
      if (this.isShowHistory) {
        fn = getKQStatisticsForMonthH;
      } else {
        fn = getKQStatisticsForMonth;
      }
      fn(this.searchObj).then(result => {
        console.log(result.data, 'MessageSearchClassAwayByYear');
        this.nowDataList = result.data.DataList;
        this.nowList = this.getNowList();
        this.oldList = this.getOldList();
        if (this.$utils.getSaaSClientSet(59) > 0) {
          this.nextList = this.getNextList();
        }
        // this.yearTotal = Number(result.data.totalDeductAmount);
        if (callback) {
          callback();
        }
        this.$nextTick(() => {
          if (this.$refs.echart) {
            this.$refs.echart.echartInit();
          }
        });
      }).catch(error => {
        if (callback) {
          callback();
        }
        console.log(error, 'errMessageSearchClassAwayByYear');
      });
    },
    // 获取选中年数据 因为数据呈现为12个月. 所以如果返回数据不符合该月份的话直接push '0'
    // 考勤
    getNowList () {
      let nowList = [];
      // let monthList = this.getDaysOfMonth(this.seletedDate);
      this.monthList.forEach(p => {
        let item = this.nowDataList.find(o => {
          return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
        });
        if (item) {
          if (Date.new(Date.new().setDate((Date.new().getDate()))) >= Date.new(item.SetUpTime)) {
            nowList.push(Number(item.Attence3).toFixed(0));
          }
          // nowList.push(Number(item.Attence3).toFixed(0));
        } else {
          // nowList.push('0');
        }
      });
      return nowList;
    },
    // 请假
    getOldList () {
      let nowList = [];
      this.monthList.forEach(p => {
        let item = this.nowDataList.find(o => {
          return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
        });
        if (item) {
          if (Date.new(Date.new().setDate((Date.new().getDate()))) >= Date.new(item.SetUpTime)) {
            nowList.push(Number(item.Attence4).toFixed(0));
          }
          // nowList.push(Number(item.Attence4).toFixed(0));
        } else {
          // nowList.push('0');
        }
      });
      return nowList;
    },
    // 旷课
    getNextList () {
      let nowList = [];
      this.monthList.forEach(p => {
        let item = this.nowDataList.find(o => {
          return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
        });
        if (item) {
          if (Date.new(Date.new().setDate((Date.new().getDate()))) >= Date.new(item.SetUpTime)) {
            nowList.push(Number(item.Attence5).toFixed(0));
          }
          // nowList.push(Number(item.Attence5).toFixed(0));
        } else {
          // nowList.push('0');
        }
      });
      return nowList;
    }
  }
};
</script>
<style scoped>
.change_history_data {
  position: absolute;
  margin-top: -43px !important;
  margin-left: 258px !important;
  line-height: 34px !important;
  width: 120px !important;
  height: 36px !important;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
</style>