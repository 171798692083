<template>
  <div class="search_popup"
       v-if="showBox">
    <div @click="hideBox"
         class="shade"></div>
    <div class="course_popup">
      <div class="course_popup_label">
        <div class="search_title">已选</div>
        <div class="search_text">搜索{{typeInfo(type)}}：{{condition}}</div>
        <div @click="toRefresh"
             class="btn_hover_bg_blue search_btn ">清空</div>
      </div>
      <div class="course_popup_ul">
        <div @click="toSearch(1)"
             class="course_popup_list border_botom">
          按老师搜索
        </div>
        <div @click="toSearch(2)"
             class="course_popup_list border_botom">
          按学生搜索
        </div>
        <div @click="toSearch(3)"
             class="course_popup_list border_botom">
          按课程搜索
        </div>
        <div @click="toSearch(4)"
             class="course_popup_list border_botom">
          按教室搜索
        </div>
        <div @click="toSearch(5)"
             class="course_popup_list">
          按班级搜索
        </div>
        <div @click="hideBox"
             class="course_popup_list">
          取消
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      showBox: false
    };
  },
  props: ['condition', 'type'],
  methods: {
    hideBox () {
      this.showBox = false;
    },
    toSearch (key) {
      this.$router.push({ path: 'coursetableSearch', query: { type: key } });
    },
    typeInfo (type) {
      let text = '';
      switch (String(type)) {
        case '1':
          text = '老师';
          break;
        case '2':
          text = '学生';
          break;
        case '3':
          text = '课名';
          break;
        case '4':
          text = '教室';
          break;
        case '5':
          text = '班级';
          break;
        default:
          break;
      }
      return text;
    },
    toRefresh () {
      this.hideBox();
      this.$emit('restoreSearch');
    }
  }
};
</script>