<template>
  <div>
    <div class="item_img">
      <img :src="headImg"
           class="item_img_content"
           :class="!item.AuthorizeMsg?'opsss':''" />
    </div>
    <div class="item_title"
         :title="item.MainDemoName">{{item.MainDemoName}}</div>
    <div class="device_control_list"
         v-if="item.IsAuthorize == '1'">
      <iconAuthorization v-for="(item,index) in vCourseAuthList"
                         :key="index"
                         :courseAuth="item"
                         :isShowBeforLine="true"
                         :minWidth="true"></iconAuthorization>
    </div>
    <div v-if="item.AuthorizeMsg && item.AuthorizeMsg.AuthTypeKey=='1'"
         class="allAuth"
         @click="open">
      <div>全部授权：{{ item.AuthorizeMsg.CoursewarePackgeCount+'/'+item.AuthorizeMsg.CoursewarePackgeCount }}</div>
      <div>剩余：{{ item.AuthorizeMsg.ClearingFormKey!='3'? (Number(item.AuthorizeMsg.ReleasedNum)-Number(item.AuthorizeMsg.UsageNum))+'/'+item.AuthorizeMsg.ReleasedNum +(item.AuthorizeMsg.ClearingFormKey==1?'次':'天'):'永久授权'}}</div>
      <div class="xuduankou">详情 > </div>
    </div>
    <div v-else-if="item.AuthorizeMsg && item.AuthorizeMsg.AuthTypeKey=='2'"
         class="littleAuth"
         @click="open">
      <div>已授权：{{ item.AuthorizeMsg.AuthCoursewarePackgeCount+'/'+item.AuthorizeMsg.CoursewarePackgeCount }}</div>
      <div class="xuduankou">详情 > </div>
    </div>
    <div v-else
         class="noAuth">
      未授权
    </div>
  </div>

</template>
<script>
import iconAuthorization from '../../../courseware-work-bench/right-box-work-bench/courseware-details/icon-authorization.vue';
export default {
  data () {
    return {

    };
  },
  components: {
    iconAuthorization
  },
  props: {
    ClientKey: {
      type: [Number, String],
      default: 0
    },
    item: []
  },
  computed: {
    headImg () {
      if (this.item && this.item.CoverUrl) {
        if (this.item.CoverUrl.indexOf('http') > -1) {
          return this.item.CoverUrl;
        } else {
          return this.$store.getters.CDNURL + this.item.CoverUrl;
        }
      } else {
        return '';
      }
    },
    defaultImg () {
      return (
        'this.src="' +
        require('../../../../../public/image/deflut_teacher_photo@2x.png') +
        '"'
      );
    },
    vCourseAuthList () {
      let data = this.item.AuthorizeMsg;
      console.log('1111', this.item);
      let arr = [];
      if (!data) {
        return arr;
      }
      let itemPC = {
        IsAuthKey: data.IsPCAuthKey,
        IsRecordKey: data.IsPCRecordKey,
        AuthorizeDeviceNum: data.AuthorizeDeviceNum,
        AuthCount: data.PCDeviceAuthCount,
        LocationLockKey: data.PCIsLocationLockKey,
        type: 'PC'
      };
      let itemTV = {
        IsAuthKey: data.IsTVAuthKey,
        IsRecordKey: data.IsTVRecordKey,
        AuthorizeDeviceNum: data.TVAuthorizeDeviceNum,
        AuthCount: data.TVDeviceAuthCount,
        LocationLockKey: data.TVIsLocationLockKey,
        type: 'TV'
      };
      let itemAPP = {
        IsAuthKey: data.IsAPPAuthKey,
        IsRecordKey: data.IsAPPRecordKey,
        AuthorizeDeviceNum: data.APPAuthorizeDeviceNum,
        AuthCount: data.APPDeviceAuthCount,
        LocationLockKey: data.IsLocationLockKey,
        type: 'APP'
      };
      arr.push(itemPC);
      arr.push(itemAPP);
      arr.push(itemTV);
      return arr;
    }
  },
  created () {
  },

  mounted () {

  },

  methods: {
    // getDataDetails () {
    //   GetAuthorizeBigCoursePackage(this.ClientKey).then(res => {
    //     console.log('校区授权课包res', res.data);
    //     this.data = res.data;
    //   });
    // }
    open () {
      this.$emit('openDetails', this.item.OLAPKey);
    }
  }
};
</script>
<style scoped>
.contentBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.contentItem {
  width: 320px;
  height: 350px;
  background: #ccc;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 10px;
  /* margin-left: 10px; */
  /* margin-right: 5px; */
  margin: 0px 33px;
}
.item_img {
  width: 100%;
  height: 220px;
}
.item_title {
  font-weight: 600;
  font-size: 18px;
  line-height: 35px;
  height: 35px;
  overflow: hidden;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 2px 0px;
}
.item_img_content {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.device_control_list {
  display: flex;
  background: #ffffffb5;
}
.noAuth {
  margin-top: 36px;
  width: 100%;
  text-align: center;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
  line-height: 29px;
  color: #00000080;
  font-size: 13px;
}
.allAuth {
  margin-top: 14px;
  text-align: center;
  height: 30px;
  border: 1px solid #31e33f;
  border-radius: 4px;
  line-height: 29px;
  color: #0b6212;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 13px;
  cursor: pointer;
}
.xuduankou {
  cursor: pointer;
  color: #3498db;
  font-size: 12px;
}
.littleAuth {
  margin-top: 14px;
  text-align: center;
  height: 30px;
  border: 1px solid #ed9510;
  border-radius: 4px;
  line-height: 29px;
  color: #895a05;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 13px;
  cursor: pointer;
}
.opsss {
  opacity: 0.5;
}
.tree-icon {
  position: absolute;
  left: -35px;
  top: 2px;
  width: 30px;
}
</style>