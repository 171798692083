<template>
  <!-- 欠款销账模块 -->
  <div>
    <div class="content_marginRL border_bottom">
      <div class="form_info_edit mendian_setting_content single_shadow"
           :class="{btn_marginBottom_30:true}">

        <input-number :formTitle="'销账金额'"
                      min="0"
                      :readonly="false"
                      v-model="dataJson.Amount"
                      :placeholder="'必填'"
                      :required="true"></input-number>

        <input-text :formTitle="'原因说明'"
                    class="no_border_bottom"
                    :readonly="false"
                    :formPlaceholder="'选填'"
                    :required="true"
                    v-model="dataJson.Reason"></input-text>
      </div>
    </div>
    <!-- 按钮组 -->
    <div v-if="true"
         style="margin-top: 80px;">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="closePaymentDialog"></save-cancel-btn-group>
    </div>
  </div>

</template> 

<script>
import { ApplyWriteOff } from '../../../API/workbench';
export default {
  data () {
    return {
      // 数据源
      dataJson: {
        StudentKey: '', // id
        Amount: '', //
        DebtAmount: '',
        Reason: ''// 备注
      }
    };
  },

  computed: {

  },
  props: {
    dataInfo: Object//
  },
  watch: {

  },
  created () {
    if (JSON.stringify(this.dataInfo) != '{}') { // 信息
      this.dataJson.StudentKey = Number(this.dataInfo.OLAPKey); // 信息Key
      this.dataJson.DebtAmount = Number(this.dataInfo.DebtAmount); // 欠款金额
      console.log(this.dataInfo, '修改物品信息');
    } else {
      console.log(this.dataInfo, '暂无数据');
    }
  },
  mounted () {

  },
  methods: {
    // 确认按钮
    confirmClick (callBack) {
      console.log(this.dataInfo, '修改物品信息');
      if (Number(this.dataInfo.DebtAmount) >= Number(this.dataJson.Amount)) {
        let isWrong = this.checkSubmitData();
        if (isWrong) {
          if (callBack) {
            callBack();
          }
          return false;
        }
        ApplyWriteOff(this.dataJson.StudentKey, this.dataJson.Amount, this.dataJson.Reason).then(result => {
          layer.alert('申请成功');
          this.$emit('afterSuccessCallBack');
          this.closePaymentDialog();
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      } else {
        layer.alert('销账金额不能大于欠款金额');
        callBack();
      }
    },
    // 验证要提交的数据
    checkSubmitData () {
      let flag = false;
      if (!this.dataJson.Amount) {
        layer.alert('欠款销账不能为空');
        flag = true;
      } else if (!this.dataJson.Reason) {
        layer.alert('原因说明不能为空');
        flag = true;
      }
      return flag;
    },
    // 取消按钮
    closePaymentDialog () {
      this.$emit('closePaymentDialog');
    }
  }
};
</script>
