<template>
  <div :class="className">
    <!-- 置顶功能 -->
    <div class="WKT_btn_istop WKT_action_btn"
         @click.stop="setTop"
         v-if="isShowSetTop&&vShowUp">

    </div>
    <!-- 向上位移 -->
    <div class="WKT_btn_up WKT_action_btn"
         @click.stop="promote"
         v-if="vShowUp">

    </div>
    <!-- 向下位移 -->
    <div class="WKT_btn_down WKT_action_btn"
         @click.stop="decline"
         v-if="vShowDown">

    </div>
    <!-- 修改 -->
    <div class="WKT_btn_edit WKT_action_btn"
         @click.stop="edit"
         v-if="isShowEditBtn">

    </div>
    <!-- 删除 -->
    <div class="WKT_btn_remove WKT_action_btn"
         @click.stop="remove"
         v-if="isShowRemoveBtn">

    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    dataList: {
      type: Array,
      default: []
    },
    item: Object,
    isShowSetTop: {     // 是否显示置顶按钮.
      type: Boolean,
      default: false
    },
    isShowEditBtn: {     // 是否显示修改按钮.
      type: Boolean,
      default: true
    },
    isShowRemoveBtn: {     // 是否显示删除按钮.
      type: Boolean,
      default: true
    },
    className: {
      type: String,
      default: 'list_btn_box'
    }
  },
  computed: {
    vShowUp () {
      if (this.dataList.length > 0) {
        return this.dataList[0] != this.item;
      }
    },
    vShowDown () {
      if (this.dataList.length > 0) {
        return this.dataList[this.dataList.length - 1] != this.item;
      }
    }
  },
  methods: {
    // 上升
    promote () {
      let index = this.dataList.findIndex(o => {
        return o == this.item;
      });
      this.upGo(this.dataList, index);
      this.$emit('promote', this.item, index);
    },
    // 下降
    decline () {
      let index = this.dataList.findIndex(o => {
        return o == this.item;
      });
      this.downGo(this.dataList, index);
      this.$emit('decline', this.item, index);
    },
    // 修改
    edit () {
      let index = this.dataList.findIndex(o => {
        return o == this.item;
      });
      this.$emit('edit', this.item, index);
    },
    // 删除
    remove () {
      let index = this.dataList.findIndex(o => {
        return o == this.item;
      });
      this.$emit('remove', this.item, index);
    },
    setTop () {
      let index = this.dataList.findIndex(o => {
        return o == this.item;
      });
      this.toFirst(this.dataList, index);
      this.$emit('setTop', this.item, 1);
    },
    // 指定移动
    toFirst (fieldData, index) {
      if (index != 0) {
        // fieldData[index] = fieldData.splice(0, 1, fieldData[index])[0]; 这种方法是与另一个元素交换了位子，
        fieldData.unshift(fieldData.splice(index, 1)[0]);
      }
    },
    // 移上一格
    upGo (fieldData, index) {
      if (index != 0) {
        fieldData[index] = fieldData.splice(index - 1, 1, fieldData[index])[0];
      } else {
        fieldData.push(fieldData.shift());
      }
    },
    // 移下一格
    downGo (fieldData, index) {
      if (index != fieldData.length - 1) {
        fieldData[index] = fieldData.splice(index + 1, 1, fieldData[index])[0];
      } else {
        fieldData.unshift(fieldData.splice(index, 1)[0]);
      }
    }
  }

};
</script>

