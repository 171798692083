<template>
  <!-- 校区备课检查 -->
  <div class="title_package">
    <div class="prepare_lesson_module all_view">
      <!-- 头部 -->
      <prepare-lessons-head :titleList="prepareLessonsTitleList"
                            @chgTitleList="chgTitleList"></prepare-lessons-head>

      <!-- 在用校区 -->
      <div v-if="prepareLessonsTitleList[0].isSelected">
        <use-school-list :CoursewarePackageId="CoursewarePackageId"
                         :monitorA="monitorA"></use-school-list>

      </div>

      <!-- 过期校区 -->
      <div v-else-if="prepareLessonsTitleList[1].isSelected">
        <auth-school-list :CoursewarePackageId="CoursewarePackageId"
                          :monitorA="monitorA"></auth-school-list>
        <!-- <tableListCommon :CoursewarePackageId="CoursewarePackageId"
                         :plan="'A'"
                         :StatusKey="params.StatusKey"
                         :SchoolTypeKey="params.SchoolTypeKey"
                         :monitorA="monitorA"></tableListCommon> -->
      </div>

      <!-- 已授权校区 -->
      <div v-else-if="prepareLessonsTitleList[2].isSelected">
        <stop-school-list :CoursewarePackageId="CoursewarePackageId"
                          :monitorA="monitorA"></stop-school-list>

      </div>
    </div>
    <!-- <div @click="showPage" class="top_buttom"></div> -->
    <div class="buttom_sq">
      <function-btn :item="{ name: '授权', ModulePowerKey: '' }"
                    :className="'el-button btn_light_blue btn_hover_bg_blue buttom_sq'"
                    @clickItem="addAuthorizationCourseware('ZY')">
      </function-btn>
    </div>

    <custom-dialog :title="titleName"
                   :visible.sync="isShowCoursewareFormPopup"
                   :before-close="closeCoursewareFormPopup">
      <authorization-courseware-form :coursewareBagInfo="dataInfo"
                                     :isReadonly="false"
                                     @close="closeCoursewareFormPopup"
                                     @afterSuccess="getSearchPageList"></authorization-courseware-form>
    </custom-dialog>

  </div>
</template>
<script>
import {
  SearchPageAuthLogList, AuthorizationCoursewareEnable, AuthorizationCoursewareDisable
} from '../../../../API/workbench';
import prepareLessonsHead from './prepare-lessons-head';
import coursewareTablist from '../common/courseware-tablist.vue';
import authorizationCoursewareForm from '../../headquarters-management/authorization-courseware/form/authorization-courseware-form';
import editAuthorizationDeviceForm from '../../headquarters-management/authorization-courseware/form/edit-authorization-device-form';
import dialogFactory from '../../../common/dialog-factory';
import tableListCommon from './table-list-common.vue';
import useSchoolList from '../common/use-school-list'
import stopSchoolList from '../common/stop-school-list'
import authSchoolList from '../common/auth-school-list'
export default {
  name: 'campusPrepareLessonsExamine',
  props: {
    CoursewarePackageId: {
      type: String || Number,
      default: 0
    },
    CoursewareValue: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      monitorA: 0,
      showPagePagination: false,
      isShowCoursewareFormPopup: false,
      isShowAuthorizationAeviceFormPopup: false,
      prepareLessonsTitleList: [
        { name: '在用校区', key: 1, isSelected: true },
        { name: '授权记录', key: 2, isSelected: false },
        { name: '停用校区', key: 3, isSelected: false }
      ],
      dataInfo: {

      },
      params: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	可选	0	数据源：GET	每页记录数，0：表示查询所有
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: '', //	字符串	可选		数据源：GET	排序顺序
        SchoolTypeKey: 0,
        BigCoursewarePackageKey: this.CoursewarePackageId,
        StatusKey: 1
      },


      //   defaultSort: { prop: 'studentInfo', order: 'ascending' },
      coursewareIndo: {},
      titleName: '授权课件',

    };
  },
  components: {
    prepareLessonsHead,
    coursewareTablist,
    editAuthorizationDeviceForm,
    authorizationCoursewareForm,
    dialogFactory,
    tableListCommon,
    useSchoolList,
    stopSchoolList,
    authSchoolList
  },
  created () {
    // this.registerBusEvent()

  },
  mounted () {
    // this.getSearchPageList()
  },
  methods: {
    showPage () {
      this.$emit('showTableList');
    },
    chgTitleList (item) {
      console.log(item, 'item');
      this.params.StatusKey = item.key;

    },

    addAuthorizationCourseware (type) {
      this.dataInfo.key = this.CoursewarePackageId,
        this.dataInfo.value = this.CoursewareValue;
      console.log(this.dataInfo, 'this.dataInfo');
      this.titleName = '授权课件';
      this.coursewareIndo = {};
      this.isShowCoursewareFormPopup = true;
    },

    //  授权课件列表-分页查询
    getSearchPageList () {
      console.log('111111');

      this.monitorA++;
    },
    // 设备授权
    editAuthorizationEquipment (rowData) {
      this.coursewareIndo = rowData;
      this.isShowAuthorizationAeviceFormPopup = true;
    },
    // 关闭设备授权
    closeCoursewareFormPopup () {
      this.isShowCoursewareFormPopup = false;
      this.isShowAuthorizationAeviceFormPopup = false;
    },

  },
  computed: {}
};
</script>

<style scoped>
.title_package {
  position: relative;
  background-color: #fafafa;
}
.prepare_lesson_module {
  padding: 26px 0px 54px;
  position: relative;
  width: 1208px;
  margin: 0 auto;
  /* margin: 0 auto; */
  /* margin: 20px 50px 50px 50px; */
}
.prepare_lesson_module .tltle_margin {
  border-bottom: 1px solid #ebeef5;
  width: 100%;
}
.prepare_lesson_module .table_nav_box {
  background-color: #fff;
}
.prepare_lesson_module .table_select_box {
  margin-right: 10px;
}
.prepare_lesson_module .course_statistics_title {
  padding: 0;
}
.prepare_lesson_module .course_statistics_title .title_date_select {
  width: 200px;
  border: none;
  margin-right: 10px;
  margin-left: 0;
}
.prepare_lesson_module .table_select_box .el-select {
  width: 200px;
}
.prepare_lesson_module
  .course_statistics_title
  .title_date_select
  .el-input
  input {
  width: 190px;
}
.buttom_sq {
  display: block;
  width: 76px;
  height: 35px;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  right: 20px;
  top: 10px;
}
.showPagePagination {
  width: 1208px;
  height: 40px;
  background-color: #fff;
  position: relative;
  margin: 0 auto;
  margin-top: -55px;
}
.down_buttom {
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 12px;
  right: 5px;
  /* background: #F0F0F0; */
  border-radius: 8px;
  background: #f0f0f0 url(../../../../../public/image/arror_down.png) no-repeat
    50%;
}
.top_buttom {
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 51px;
  top: 856px;
  /* background: #F0F0F0; */
  border-radius: 8px;
  background: #f0f0f0 url(../../../../../public/image/arror_up.png) no-repeat
    50%;
  z-index: 20;
}
.title_package
  .prepare_lesson_module
  .table_nav_box
  .table_fixed_nav
  .table_fixed_nav_list {
  padding: 0px 0px !important;
  margin-right: 20px !important;
}
</style>

