<template>
	<div>
		<div class="article_details_interaction">
			<div class="flex">
				<div class="interaction_btn" @click.stop="doReplyBtnClick">
					回复
				</div>
				<div class="interaction_transpond" @click="doTranspondBtnClick">
					{{dataInfo.ShareNum}}
				</div>
				<div class="interaction_like" @click="doLikeBtnClick">
					{{dataInfo.PraiseNum}}
				</div>
			</div>
			<div class="interaction_date">
				{{$utils.replyTime(dataInfo.CreateTime)}}
			</div>
		</div>
		<!-- 点赞列表 -->
    <custom-dialog :title="'详情'"
                   :visible.sync="isShowListPopup"
                   :before-close="closeDialog">
        	<transpond-like-list-popup :type="serachTypeVal" :id="dataInfo.ID"></transpond-like-list-popup>
    </custom-dialog>
		<!-- <basics-bottom-popup v-if="isShowListPopup" :isShowCancelBtn="true" @cancelClick="isShowListPopup=false">
			<template #content>
        <transpond-like-list-popup :type="serachTypeVal" :id="dataInfo.ID"></transpond-like-list-popup>
			</template>
		</basics-bottom-popup> -->
	</div>
</template>

<script>
import transpondLikeListPopup from '../../details-page/common/transpond-like-list-popup.vue';
import sendMessageBox from './send-message-box.vue';
export default {
  components: {
    transpondLikeListPopup,
    sendMessageBox
  },
  data () {
    return {
      isShowListPopup: false,
      serachTypeVal: 1,
    };
  },
  props: {
    dataInfo: Object,
    // 是否支持点赞转发按钮点击
    isTranspondLike: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    // 回复按钮
    doReplyBtnClick () {
      console.log('回复');
	 this.$emit('doMineReplyClick', 1, this.dataInfo);
    },
    // 转发按钮
    doTranspondBtnClick () {
      if (this.isTranspondLike) {
        this.serachTypeVal = 1;
        this.isShowListPopup = true;
      }
    },
    // 点赞按钮
    doLikeBtnClick () {
      if (this.isTranspondLike) {
        this.serachTypeVal = 2;
        this.isShowListPopup = true;
      }
    },
    closeDialog(){
       this.isShowListPopup = false;
    }
  }
};
</script>

<style>

</style>
