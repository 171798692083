<template>
  <div class="student_file_course_block ">
    <bill-item :item="item"
               :showTaoCan="true"
               :showFather="true" @doAttenCourseSpendDetails="doAttenCourseSpendDetails"></bill-item>
    <div class="student_file_course_list_subset"
         v-if="item.childList.length>0">
      <bill-item v-for="(billItem,key) in item.childList"
                 :key="key"
                 :item="billItem"
                 :showTaoCan="false"
                 :showFather="false"
                 :showChild="true"
                 @doAttenCourseSpendDetails="doAttenCourseSpendDetails"></bill-item>
    </div>
  </div>
</template>

<script>
import billItem from './bill-item.vue';
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object

  },
  components: {
    billItem
  },
  created () {
    console.log(this.item, 'itemitem');
  },
  computed: {
    // vStatusKeyClass(){  //2正常3锁定4退课中5已退6已转让',
    // 	if(this.item.StatusKey==5){   //退课
    // 		return 'course_type02'
    // 	}else if(this.item.StatusKey==6){	//转让
    // 		return 'course_type04'
    // 	}else if(this.item.CurrentCount==0){		//已用完
    // 		return 'course_type03'
    // 	}else if(this.item.PeriodCount==0){				//到期
    // 		return 'course_type01'
    // 	}
    // }
  },
  methods: {
    doAttenCourseSpendDetails (item) {
      this.$emit('doAttenCourseSpendDetails', item);
    }
  }
};
</script>

<style scoped>
.student_file_course_block {
  border-bottom: 1px solid #ececec;
}
.student_file_course_block:last-child {
  border-bottom: none;
}
</style>
