<template>
  <div class="setting_view_left_list"
       :class="vActive"
       @click.stop="doItemClick">
    {{Item.MainDemoName}}
  </div>
</template>

<script>
export default {
  props: {
    Item: Object, // 左侧视图项
    index: Number
  },
  data () {
    return {

    };
  },
  computed: {
    vActive () {
      if (this.Item.isActive) {
        return 'active';
      } else {
        return '';
      }
    }
  },
  methods: {
    doItemClick () {
      this.$emit('doItemClick', this.Item, this.index);
    }
  }
};
</script>

<style>
</style>
