<template>
  <div>
    <custom-dialog title="选择班级"
                   class="refund_dialog defult_dialog"
                   :visible.sync="showClassFile"
                   :before-close="closeClassFile">
      <div v-if="searchBoxShow">
        <search-box :butMessage="'新建班级'"
                    :searchInitJson="searchDialogInitJson"
                    v-on:searchClick="searchGradeClass"
                    v-on:addClick="addNewClass"></search-box>
      </div>
      <div v-if="showClassList">
        <grade-list :isShowSearchInput="isShowSearchInput"
                    v-on:selectedItem="selectedClass"
                    ref="classGrade"></grade-list>
      </div>
    </custom-dialog>
    <custom-dialog title="班级管理"
                   width="958px"
                   class="class_file defult_dialog"
                   :visible.sync="selectedClassFile"
                   :before-close="closeSelectedClassFile">
      <selectedClassFile :selectedClassItem="selectedClassItem"
                         @changeClass="changeClass"
                         @showCourseDetail="showCourseDetail"
                         @showBranchAdd="showBranchAdd"
                         @chgShowAddStudent="chgShowAddStudent"
                         @showStudentFile="showStudentFile"
                         @showChgClass="showChgClass"
                         @chgPlanCount="chgPlanPeoCount"
                         @updateClass="updateSelectedClass"
                         ref="selectedClassFile"></selectedClassFile>
    </custom-dialog>
    <!-- 课考勤明细弹出层 -->
    <!-- <classDetail ref="classDetail"
                 :courseDetail="courseDetail"></classDetail> -->
    <!-- 批量加入弹出层. -->
    <branthAddStudent ref="branchAdd"
                      @updateData="updateData"></branthAddStudent>
    <custom-dialog title="添加学生"
                   :visible.sync="showAddStudent"
                   :before-close="closeShowAddStudent">
      <!-- 学生列表弹框 -->
      <student-list :isShowAddBtn="false"
                    @selectedItem="addSingelStudent"></student-list>
    </custom-dialog>
    <!-- 修改计划招生弹出层. -->
    <chgPlanCount :dialogTitle="'修改计划招生人数'"
                  ref="chgPlanCount"
                  @update="updateData"></chgPlanCount>
    <div>
      <new-class :isNewClass="isNewClass"
                 @gradeClassAddSuccess="addClassAfter"></new-class>
    </div>
  </div>
</template>
<script>
import selectedClassFile from './class-file';
import chgPlanCount from './chg-plan-count';
import searchBox from '../../common/search-box';
import branthAddStudent from './branth-add-student';
import studentList from '../../common/student-list';
import gradeList from '../../common/grade-list';
import { ArrangeStudentToClass } from '../../../API/workbench';
import newClass from '../new-class';
export default {
  name: 'classFile',
  data () {
    return {
      showClassFile: false, // 显示关闭学员班级.
      searchBoxShow: true,
      selectedClassFile: false, // 班级管理显示
      searchBoxShowStudentFile: false, // 显示学生档案.
      isAhangeStudentClass: false, /// 显示班级档案.
      searchBoxShowClassShow: false,
      showClassList: false,
      isShowSearchInput: false,
      isNewClass: false, // 新建班级
      selectedClassItem: {},
      searchDialogInitJson: {
        name: '目标班级',
        isShowAddBtn: true,
        roleName: ''
      },
      courseDetail: {}, // 这是显示课程明细接收的. 不能随意使用.
      classInfo: {}, // 这是班级修改传进去
      showAddStudent: false, // 添加单个学生显示/
      studentJson: {
        OLAPKey: ''
      }
    };
  },
  components: {
    selectedClassFile,
    searchBox,
    gradeList,
    branthAddStudent,
    studentList,
    chgPlanCount,
    newClass
  },
  created () { },
  watch: {},
  methods: {
    // 新增班级之后渲染.
    addClassAfter (item) {
      item.OLAPKey = item.GradeClassKey;
      this.selectedClassFile = true;
      this.selectedClassItem = item;
      this.closeClassFile();
    },
    // 修改计划招生弹出.
    chgPlanPeoCount (val) {
      this.$emit('editClass', this.classInfo, () => {
        this.updateData();
      });
    },
    // 新增班级.
    addNewClass () {
      this.isNewClass = true;
    },
    // 关闭班级管理
    closeClassFile () {
      this.showClassFile = false;
      this.searchBoxShow = true;
      this.showClassList = false;
    },
    closeClassList () {
      this.closeClassList = false;
    },
    closeSelectedClassFile () {
      this.selectedClassFile = false;
    },
    // 弹出班级列表
    changeClass () {
      this.showClassFile = true;
      this.searchBoxShow = false;
      this.showClassList = true;
    },
    // 获取到数据后更新班级信息.
    updateSelectedClass (val) {
      this.classInfo = this.$utils.parseJson(val);
    },
    // 选择班级时触发.
    selectedClass (item) {
      this.selectedClassFile = true;
      this.selectedClassItem = item;
      console.log('selectedClass', this.selectedClassItem);
      this.closeClassFile();
    },
    searchGradeClass () {
      // 调用接口查询班级列表 待完成
      // this.$parent.searchBoxShow = false //搜索框
      this.searchBoxShow = false;
      this.showClassList = true;
      setTimeout(() => {
        this.$refs.classGrade.studenSearchText = '';
        this.$refs.classGrade.validClassList();
      }, 10);
    },
    addGradeClass () {
      console.log('addGradeClass');
    },
    // 显示课程明细
    showCourseDetail (val) {
      this.courseDetail = val;
      let moduleInfo = {
        name: '',
        routerName: this.$route.name,
        moduleName: 'classDetail',
        data: { courseInfo: this.courseDetail }
      };
      this.$bus.emit('openCommonCustomDialog', moduleInfo);
    },
    // 显示批量加入学生
    showBranchAdd () {
      this.$refs.branchAdd.showBatchJoinLesson = true;
      this.$refs.branchAdd.courseDetail = this.selectedClassItem;
      this.$refs.branchAdd.initGetData();
    },
    // 加入学生后更新数据
    updateData (classItem) {
      // 判断有没classItem,并且ID是否跟主界面的门店ID一致, 不一致时不更新数据.
      if (classItem && classItem.OLAPKey != this.selectedClassItem.OLAPKey) {
      } else {
        let Year = Date.new().getFullYear();
        let Month = Date.new().getMonth() + 1;
        this.$refs.selectedClassFile.initGetAllData(this.selectedClassItem.OLAPKey, Year, Month);
      }
    },
    // 显示加入单个学生
    closeShowAddStudent () {
      this.showAddStudent = false;
    },
    chgShowAddStudent (type) {
      this.showAddStudent = type;
    },
    // 加入单个学生
    addSingelStudent (studentItem) {
      ArrangeStudentToClass(studentItem.OLAPKey, this.selectedClassItem.OLAPKey)
        .then(result => {
          layer.alert('加入班级成功');
          this.updateData();
          this.closeShowAddStudent();
        }).catch(error => {
          layer.alert(error.msg);
        });
      console.log(studentItem.OLAPKey);
    },
    // 显示学员档案.
    showStudentFile (val) {
      let moduleInfo = {
        name: '学生档案',
        routerName: this.$route.name,
        moduleName: 'studentFile',
        data: { studentID: val.StuKey }
      };
      this.$bus.emit('needOpenRouter', moduleInfo);
    },
    // 显示换班
    showChgClass (classItem, studentInfo) {
      let newJson = this.$utils.parseJson(studentInfo);
      newJson.OLAPKey = studentInfo.StuKey;
      this.isAhangeStudentClass = true;

      let obj = {
        ClassItem: classItem,
        StudentItem: newJson
      };
      let moduleInfo = {
        name: '学生换班',
        routerName: this.$route.name,
        moduleName: 'studentchangeclass',
        data: { dataInfo: obj },
        callBack: { afterSucces: () => { this.upStudentFileInfoClick(); } }
      };
      this.$bus.emit('needOpenRouter', moduleInfo);
    }
  }
};
</script>

