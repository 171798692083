<template>
  <div class="report_item"
       :class="className"
       @click.stop="clickItem">
    <div class="report_item_header button">
      {{ dataInfo.title }}
      <div class="report_item_header_btn button"
           @click.stop="clickbtn">
        {{ dataInfo.btnName }}
      </div>
    </div>
    <div class="report_item_content">
      <div class="content_item"
           style="text-align: left"
           @click.stop="goRouter(1)"
           :class="!isApply ? 'isnocursor' : ''">
        <div class="content_item_title">
          {{ isApply ? "今日报名" : "家长申请" }}
        </div>
        <div style="font-size: 16px; margin-bottom: 2px"
             v-if="isApply">
          <span style="font-size: 12px">￥</span>
          {{ Number(dataInfo.data.TodayActualColAmount) }}
        </div>
        <div class="margin_t_5"
             :style="{ fontSize: !isApply ? '16px' : '12px' }">
          {{
            isApply
              ? Number(dataInfo.data.TodayCount) + "单"
              : Number(dataInfo.data.ParentsApplyCount)
          }}
        </div>
      </div>
      <div class="content_item"
           @click.stop="goRouter(2)"
           :class="!isApply ? 'isnocursor' : ''">
        <div class="content_item_title">
          {{ isApply ? "本周报名" : "销售申请" }}
        </div>
        <div style="font-size: 16px; margin-bottom: 2px"
             v-if="isApply">
          <span style="font-size: 12px">￥</span>
          {{ Number(dataInfo.data.ThisWeekActualColAmount) }}
        </div>
        <div class="margin_t_5"
             :style="{ fontSize: !isApply ? '16px' : '12px' }">
          {{
            isApply
              ? Number(dataInfo.data.ThisWeekCount) + "单"
              : Number(dataInfo.data.SaleApplyCount)
          }}
        </div>
      </div>
      <div class="content_item"
           @click.stop="goRouter(3)">
        <div class="content_item_title">
          {{ isApply ? "本月报名" : "未安排" }}
        </div>
        <div style="font-size: 16px; margin-bottom: 2px"
             v-if="isApply">
          <span style="font-size: 12px">￥</span>
          {{ Number(dataInfo.data.ThisMonthActualColAmount) }}
        </div>
        <div class="margin_t_5"
             :style="{ fontSize: !isApply ? '16px' : '12px' }"
             :class="!isApply ? 'font_red' : ''">
          {{
            isApply
              ? Number(dataInfo.data.ThisMonthCount) + "单"
              : Number(dataInfo.data.NoArrangeCount)
          }}
        </div>
      </div>
      <div class="content_item"
           @click.stop="goRouter(4)">
        <div class="content_item_title">
          {{ isApply ? "待补充" : "今日试课" }}
        </div>

        <div class="margin_t_5"
             style="font-size: 16px"
             :class="isApply ? 'font_red' : ''">
          {{
            isApply
              ? Number(dataInfo.data.ToBeAddedCount)
              : Number(dataInfo.data.TodayTrialLessonCount)
          }}
        </div>
      </div>
    </div>
    <!-- 线上申请 -->
    <custom-dialog :title="'线上申请'"
                   class=" "
                   :width="'1213px'"
                   :visible.sync="isShowOnline"
                   :before-close="changeShowOnlineDialog">
      <online-table @updateData="updateData"
                    @arrangeClick="arrangeClickAfter"></online-table>
    </custom-dialog>
  </div>
</template>
<script>
import onlineTable from "../..//report/try-arrange-course/components/online-table";
export default {
  data () {
    return {
      seletedItem: null,
      isShowOnline: false,
      applyrecordInfo: {
        name: "报名记录",
        routerName: this.$route.name,
        routertitle: this.$route.title,
        auth: true,
        roleName: "",
        ModulePowerKey: 58,
        moduleName: "applyrecord",
      },
      arrangetrycourseInfo: {
        name: "",
        className: "menu_left_list icon_14",
        routerName: "tryArrangeCourse",
        routertitle: "试课追销",
        auth: true,
        roleName: "",
        moduleName: "",
        ModulePowerKey: 64,
        fatherActive: false,
        childActive: false,
      },
      applybtnInfo: {
        name: "报名收款",
        routerName: this.$route.name,
        routertitle: this.$route.title,
        auth: true,
        roleName: "",
        ModulePowerKey: 50,
        moduleName: "signuppayment",
      },
      trycoursebtnInfo: {
        name: "试课管理",
        routertitle: "试课管理",
        routerName: "tryArrangeCourse",
        auth: true,
        roleName: "",
        ModulePowerKey: 64,
        img_type: "09",
      },
    };
  },
  components: {
    onlineTable,
  },
  props: {
    dataInfo: Object,
    isApply: Boolean, //是否报名记录
    className: {
      type: String,
      default: () => {
        return "type_blue";
      },
    },
  },
  created () { },
  computed: {
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    },
  },
  methods: {
    // 显示线上申请弹出层
    changeShowOnlineDialog (type) {
      this.isShowOnline = type || false;
      console.log(this.isShowOnline);
    },
    // 线上申请模块点击安排
    arrangeClickAfter (item, callBack) {
      console.log("arrangeClickAfter", item);
      this.seletedItem = item;
      this.toArrangeCourse(callBack);
    },
    // 工作流-试课
    toArrangeCourse (callback) {
      this.$dialog.open(this, {
        name: "学生试课",
        routerName: this.$route.name,
        moduleName: "arrangeCourse",
        data: { dataInfo: this.seletedItem },
        callBack: {
          afterSuccess: () => {
            this.updateData();
            if (callback) {
              callback();
            }
          },
        },
      });
    },
    updateData () {
      this.$emit("updateData");
    },
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.vRolePowerList, ModulePowerKey);
    },
    clickItem () {
      let btnInfo = this.isApply
        ? this.applyrecordInfo
        : this.arrangetrycourseInfo;
      if (this.vHasModulePower(btnInfo.ModulePowerKey)) {
        this.$emit("doAfterClickFunctionBtn", btnInfo);
        // 无权限
      } else {
        this.$utils.CheckModulePowerToTips(btnInfo.ModulePowerKey);
      }
    },
    clickbtn () {
      let btnInfo = this.isApply ? this.applybtnInfo : this.trycoursebtnInfo;
      if (this.vHasModulePower(btnInfo.ModulePowerKey)) {
        this.$emit("doAfterClickFunctionBtn", btnInfo);
        // 无权限
      } else {
        this.$utils.CheckModulePowerToTips(btnInfo.ModulePowerKey);
      }
    },
    goRouter (type) {
      let btnInfo = null;
      if (this.isApply) {
        btnInfo = {
          name: "报名记录",
          routerName: this.$route.name,
          routertitle: this.$route.title,
          auth: true,
          roleName: "",
          ModulePowerKey: 58,
          moduleName: "applyrecord",
          data: {
            dataInfo: {
              startTime: this.$utils.formatDateStr(
                new Date().toLocaleDateString(),
                "yyyy-MM-dd"
              ),
              endTime: this.$utils.formatDateStr(
                new Date().toLocaleDateString(),
                "yyyy-MM-dd"
              ),
            },
          },
        };
        switch (type) {
          case 1:
            btnInfo.data.dataInfo.startTime = this.$utils.formatDateStr(
              new Date().toLocaleDateString(),
              "yyyy-MM-dd"
            );
            btnInfo.data.dataInfo.endTime = this.$utils.formatDateStr(
              new Date().toLocaleDateString(),
              "yyyy-MM-dd"
            );
            break;
          case 2:
            btnInfo.data.dataInfo.startTime =
              this.$utils.getThisWeekDates().start;
            btnInfo.data.dataInfo.endTime = this.$utils.getThisWeekDates().end;
            break;
          case 3:
            btnInfo.data.dataInfo.startTime = this.$utils.formatDateStr(
              Date.new(Date.new().setDate(1)).toLocaleDateString(),
              "yyyy-MM-dd"
            );
            btnInfo.data.dataInfo.endTime = this.$utils.formatDateStr(
              this.$utils.setChoseEndTime(Date.new()),
              "yyyy-MM-dd"
            );
            break;
          case 4:
            btnInfo = {
              name: "报名补充校正",
              routertitle: "报名补充校正",
              routerName: "registrationSupplementaryCorrection",
              auth: true,
              roleName: "payment",
              ModulePowerKey: 50,
            };
            break;
          default:
            break;
        }
      } else {
        switch (type) {
          case 1:
            break;
          case 2:
            break;
          case 3:
            this.isShowOnline = true;
            break;
          case 4:
            btnInfo = {
              name: "试课管理",
              routertitle: "试课管理",
              routerName: "tryArrangeCourse",
              auth: true,
              roleName: "",
              ModulePowerKey: 64,
              img_type: "09",
              data: {
                dataInfo: {
                  startTime: this.$utils.formatDateStr(
                    new Date().toLocaleDateString(),
                    "yyyy-MM-dd"
                  ),
                  endTime: this.$utils.formatDateStr(
                    new Date().toLocaleDateString(),
                    "yyyy-MM-dd"
                  ),
                },
              },
            };
            break;
          default:
            break;
        }
      }
      if (btnInfo) {
        if (this.vHasModulePower(btnInfo.ModulePowerKey)) {
          if (btnInfo.isComment) {
            this.$emit("openCommonCustomDialogByFactoryDialog", btnInfo);
          } else {
            this.$emit("doAfterClickFunctionBtn", btnInfo);
          }
          // 无权限
        } else {
          this.$utils.CheckModulePowerToTips(btnInfo.ModulePowerKey);
        }
      }
    },
  },
};
</script>
<style scoped>
.report_item {
  width: 100%;
  height: 106px;
  padding: 10px 20px 15px 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.report_item.type_blue {
  background: linear-gradient(180deg, #ddecff 0%, rgba(221, 236, 255, 0) 100%),
    linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 2px 2px 2px 0px #d7d7d733;
}
.report_item.type_yellow {
  background: linear-gradient(180deg, #fff8e5 0%, rgba(255, 248, 229, 0) 100%),
    linear-gradient(0deg, #ffffff, #ffffff);

  box-shadow: 2px 2px 2px 0px #d7d7d733;
}
.report_item.type_pink {
  background: linear-gradient(180deg, #ffeeee 0%, rgba(255, 238, 238, 0) 100%),
    linear-gradient(0deg, #ffffff, #ffffff);

  box-shadow: 2px 2px 2px 0px #d7d7d733;
}
.report_item.type_purple {
  background: linear-gradient(
      180deg,
      #e9e5ff 0%,
      #f4f2ff 0.01%,
      rgba(244, 242, 255, 0) 100%
    ),
    linear-gradient(0deg, #ffffff, #ffffff);

  box-shadow: 2px 2px 2px 0px #d7d7d733;
}
.report_item_header {
  position: relative;
  color: #333;
  font-size: 16px;
  margin-bottom: 10px;
  height: 24px;
  line-height: 24px;
}
.report_item_header::after {
  position: absolute;
  display: block;
  content: '';
  width: 18px;
  height: 18px;
  left: 70px;
  top: 3px;
  background: url('../../../../public/image/next.png') no-repeat center;
  background-size: 9px;
}
.report_item_content {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
}
.report_item_header_btn {
  width: 64px;
  height: 24px;
  border-radius: 4px;
  background: #ffffff;
  border-image-source: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #ececec, #ececec);
  color: #333;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  line-height: 24px;
}
.report_item_header_btn:hover {
  color: #3498db !important;
  border: #3498db !important;
}
.report_item_content .content_item {
  flex: 1;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  cursor: pointer;
}
.content_item.isnocursor {
  cursor: default;
}
.report_item_content .content_item::after {
  position: absolute;
  display: block;
  content: '';
  width: 1px;
  height: 45px;
  right: 0px;
  top: 3px;
  border: 1px solid #e7e9ea;
}
.report_item_content .content_item:last-child::after {
  display: none;
}
.report_item_content .content_item .content_item_title {
  margin-bottom: 5px;
  color: #666;
}
.margin_t_5 {
  margin-top: 3px;
}
</style>
