<template>
  <!-- <div> -->
  <div class="contract_item">
    <img :src="vImgUrl"
         @click.stop="showPreview"
         class="img_url"
         vspace="0" />
    <div class="contract_item_text">
      <div @click.stop="clickEdit"
           v-if="!isEdit">
        <div v-if="$utils.getTextWidth(item.Title, 14)>175">
          <el-tooltip :content="item.Title"
                      placement="top"
                      :disabled="item.Title.length<10">
            <div class="contract_item_edit">
              {{item.Title}}
            </div>
          </el-tooltip>
        </div>
        <div class="contract_item_edit"
             v-else>
          {{item.Title}}
        </div>
      </div>
      <input type="text"
             v-else
             v-model="item.Title"
             focus
             @blur="editItem" />
    </div>
    <div class="delete_the_photo"
         @click.stop="deleteImg">
      <img src="../../../../../../../public/image/delete_the_photo.png" />
    </div>

  </div>
  <!-- </div> -->
</template>

<script>
export default {
  data () {
    return {
      inputText: '',
      isEdit: false
    };
  },
  props: {
    item: Object,
    vHasModulePower: Boolean
  },
  created () {

  },
  computed: {
    vImgUrl () {
      var imageUrl = '';
      if (this.item.AttachUrl.indexOf('http') == -1) {
        imageUrl = this.$store.getters.CDNURL + this.item.AttachUrl;
      } else {
        imageUrl = this.item.AttachUrl;
      }
      return imageUrl;
    }
  },
  methods: {
    showPreview () {
      this.$emit('showPreview', this.item);
    },
    deleteImg () {
      if (this.vHasModulePower) {
        this.$emit('deleteImg', this.item);
      } else {
        this.$utils.CheckModulePowerToTips(48);
      }
    },
    clickEdit () {
      if (this.vHasModulePower) {
        this.isEdit = true;
      } else {
        this.$utils.CheckModulePowerToTips(48);
      }
    },
    editItem () {
      console.log('editItem', this.item);
      this.$emit('editItem', this.item);
      this.isEdit = false;
    }
  }
};
</script>

<style scoped>
.font_underline_blue {
  border-bottom: 1px solid #3498db;
}
.contract_item {
  position: relative;
  display: inline-block;
  width: 165px;
  height: 240px;
  margin-bottom: 30px;
  border: 1px solid #e1e4e7;
  vertical-align: middle;
  margin-left: 22px;
  margin-right: 10px;
  cursor: pointer;
}
.contract_item .img_url {
  width: 165px;
  height: 100%;
  object-fit: contain;
}
.contract_item .delete_the_photo img {
  position: absolute;
  width: 30px;
  height: 30px;
  float: right;
  top: 0;
  right: 0;
}
.contract_item_edit {
  width: 165px;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  position: absolute;
  bottom: -40px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 15px;
  cursor: pointer;
}
.contract_item_edit::after {
  content: '';
  position: absolute;
  top: 16px;
  right: 0px;
  width: 12px;
  height: 12px;
  background: #f5f5f5
    url(../../../../../../../public/image/student_file_nav01.png) no-repeat
    center;
  background-size: 10px;
  border-bottom: 1px solid #ececec;
}
.contract_item_text input {
  width: 165px;
  height: 30px;
  font-size: 12px;
  border: 1px solid #e1e4e7;
}
</style>
