<template>
  <div class="cell_course">
    <!-- 课程 -->
    <div class="course_loop_box">
      <el-popover v-if="$utils.getTextWidth(courseItem.CourseNameKeyValue, 16)>236"
                  popper-class="new_popper_class"
                  placement="top"
                  width="auto"
                  trigger="hover"
                  :content="courseItem.CourseNameKeyValue">
        <div slot="reference"
             class="reference"
             style="cursor: pointer;">
          <span>{{courseItem.CourseNameKeyValue}}</span>
        </div>
      </el-popover>
      <div v-else
           class="">
        <span>{{courseItem.CourseNameKeyValue}}</span>
      </div>
    </div>
    <!-- 循环安排内容-->
    <div class="loop_course_dual_box">
      <div class="dual_box_list">
        <arranged-content v-for="(item,index2) in courseItem.arrangedList"
                          :key="index2"
                          :arrangedItem="item"></arranged-content>
      </div>
    </div>
  </div>
</template>
<script>
import arrangedContent from './arranged-content';
// import classContent from './class-content';
export default {
  data () {
    return {

    };
  },
  props: {
    courseItem: [Object, String] // 列表数据

  },
  components: {
    arrangedContent
    // classContent
  },
  created () {
    console.log(this.courseItem, '列表数据-1232131');
  },
  watch: {

  },
  mounted () {

  },
  methods: {

  },

  computed: {
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    }
  }
};
</script>
