<template>
  <!-- <div class="send_imgItem fl photos"
       :preview="essayID"
       :style="{backgroundImage:'url(' + vImgUrl + ')'}"
       alt=""> -->
  <div class="bulk_assessment_students_image_view pr">
    <div class="img_box">
      <img class=""
           :src="vImgUrl"
           :onerror="defaultImg"
           alt="">
    </div>
    <div class="delete_the_photo"
         v-if="isCanEdit">
      <img src="../../../../../../public/image/delete_the_photo.png"
           mode=""
           @click="deleteItem">
    </div>
    <div v-if="videoInfo.percent == 100 || vImgUrl"
         @click="showVideoPlay"
         class="video_btn">

    </div>
    <div v-else
         class="video_progress_banner">
      <div class="video_progress"
           :style="{width:videoInfo.percent + '%'}">
        <!-- 				{{videoInfo.percent + '%'}} -->
      </div>
    </div>
  </div>
  <!--  <div class="img_list">
    <div class="img_list_close"
         @click="deleteImg"></div>
    <img class="img_box"
         :preview="essayID"
         :src="vImgUrl"
         @click.stop="clickImg"
         alt="">
    <div class="upload_img_progress" :style="{display:vIsProgress }">
		<span class="percentage" :style="{width:vUploadProgress}"></span>
	</div>
  </div> -->
  <!-- </div> -->
</template>

<script>
export default {
  props: {
    videoInfo: {
      type: Object
    },
    isCanEdit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    vImgUrl () {
      let url = this.videoInfo.VideoCoverUrl;
      if (url && url.indexOf('data:image/') == -1 && url.indexOf('http://') == -1) {
        url = this.$store.getters.CDNURL + url;
      }
      console.log('videoInfo', this.videoInfo);
      return url;
    },
    // 默认图片
    defaultImg () {
      return 'this.src="' + require('../../../../../../public/image/vedio_default.png') + '"';
    },
    vUploadProgress () {
      return this.videoInfo.percent + '%';
    },
    vIsProgress () {
      return (this.videoInfo.percent == 100 || this.videoInfo.EssayID) ? 'none' : 'block';
    }
  },
  methods: {
    showVideoPlay () {
      this.$emit('showPlayVideo', this.videoInfo);
    },
    previewItem () {
      this.$emit('onClick', this.videoInfo);
    },
    deleteItem () {
      this.$emit('onDelete', this.videoInfo);
    }
  }
};
</script>

<style>
</style>
