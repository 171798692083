<template>
  <div class="chain_workbench_warning pr scroll_type">
    <div class="warning_title">
      待办事项
      <span class="renew_btn" @click.stop="renew">刷新</span>
      <div class="warning_btn"
           v-if="dataList.length>0"
           @click="changesearchObj"></div>
    </div>
    <div class="backlog_ul "
         @scroll="scrollTOP"
         v-if="dataList.length>0">
      <item v-for="(item,key) in dataList"
            :key="key"
            :keyIndex="key"
            :item="item"
            @seletedItem="seletedItemAfter"></item>

    </div>
    <div v-if="dataList.length==0"
         class="course_block_nodata">
      <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
    </div>
    <div class="module_content_menuList"
         :style="{'top':topValue+'px','left':'68.5%'}"
         v-show="isShowdropDownBox"
         @mouseover.stop="isShowdropDownBox=true">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="menuList"
                     @clickOptionForAlertDialog="clickAfter"></drop-down-box>
    </div>
    <custom-dialog :title="'待审核'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowToAuditedSelectList"
                   :before-close="hiddenToAuditedSelectList">
      <home-incomeAndexpenses-form :item="selecttoAuditedInfo"
                                   @passHide="doAfterPassHide"></home-incomeAndexpenses-form>
    </custom-dialog>
  </div>
</template>
<script>
import {
  SearchPageForToDo,
  CoursewareAuditPass,
  hqAuditSZGL,
  CoursewareRefuse,
  GetSZGLDetails
} from '../../../API/workbench';
import homeIncomeAndexpensesForm from '../../head-quarters/basis-setting-module/hq-income-pay-manage/home-incomeAndexpenses-form';
import dropDownBox from '../../common/drop-down-box';
import item from './item';
export default {
  data () {
    return {
      searchObj: {
        PageSize: 10,
        PageIndex: 0
      },
      dataList: [],
      seletdItem: null,
      showAll: true,
      menuList: [
        {
          name: '查看详情',
          type: 'detail',
          isShow: true,
          ModulePowerKey: 0
        },
        {
          name: '审核通过',
          type: 'pass',
          isShow: true,
          ModulePowerKey: 0
        },
        {
          name: '退回',
          type: 'reback',
          isShow: true,
          ModulePowerKey: 0
        }
      ],
      isShowdropDownBox: false,
      newIndex: -1,
      topValue: 0, // 默认第一个top值
      toScrollTop: 0, // 滚动条的高度
      isShowToAuditedSelectList: false,
      selecttoAuditedInfo: null
    };
  },
  components: {
    item, dropDownBox, homeIncomeAndexpensesForm
  },
  props: {

  },
  created () {
    this.getDataList();
  },
  mounted () {
    document.querySelector('body').addEventListener('click', e => {
      this.isShowdropDownBox = false;
    }, false);
  },
  methods: {
     renew(){
      this.getDataList();
    },
    hiddenToAuditedSelectList () {
      this.isShowToAuditedSelectList = false;
    },
    doAfterPassHide () {
      console.log('待审个');
      this.isShowToAuditedSelectList = false;
      this.getDataList();
    },
    changesearchObj () {
      this.searchObj.PageSize = 99;
      this.getDataList();
    },
    scrollTOP (e) {
      this.toScrollTop = e.target.scrollTop;
      if (e.target.scrollTop > 0) {
        this.isShowdropDownBox = false;
      }
    },
    getDataList () {
      SearchPageForToDo(this.searchObj).then(result => {
        this.dataList = result.data.PageDataList;
        console.log('SearchPageForToDo', result.data);
      }).catch(error => {
        console.log(error, 'errorSearchPageForToDo');
      });
    },
    seletedItemAfter (item, key) {
      this.dataList.forEach(o => {
        if (o.OLAPKey == item.OLAPKey) {
          o.isShow = true;
        } else {
          o.isShow = false;
        }
      });
      this.seletdItem = item;
      this.isShowdropDownBox = true;
      this.doOneTopValueCount(key);
      console.log('seletedItemAfter', this.seletdItem);
    },
    clickAfter (item) {
      this.dataList.forEach(o => {
        o.isShow = false;
      });
      this.isShowdropDownBox = false;
      switch (item.type) {
        case 'detail':
          this.showDetail(this.seletdItem);
          break;
        case 'pass':
          this.passItem(this.seletdItem);
          break;
        case 'reback':
          this.refuseItem(this.seletdItem);
          break;
        default:
          break;
      }
    },
    // 查看详情
    showDetail () {
      console.log('showDetail');
      if (this.seletdItem.TypeKey == 2) {     // 收支审核
        GetSZGLDetails(this.seletdItem.ID).then(result => {
          this.selecttoAuditedInfo = result.data[0];
          this.$nextTick(o => {
            this.isShowToAuditedSelectList = true;
          });
        });
      } else {     // 课件
        this.goRouter(this.$initJson.chainWorkBenchBtn.hqCoursewareManagement);
      }
    },
    goRouter (item) {
      let isNew=this.$store.getters.headerBarList.findIndex(o=>{
        return o.routerName==item.routerName
      })
      // dataKey
       item.data={
        levelPath:this.seletdItem.levelPath,
         Title:this.seletdItem.Title,
           isNew:isNew==-1
      }
      item.dataKey = this.seletdItem.ID;
      console.log(item, 'goRouter');
      this.$bus.emit('needOpenRouter', item);
    },
    passItem () {
      if (this.seletdItem.TypeKey == 2) {     // 收支审核
        layer.confirm('请确定是否审核通过?', {
          btn: [
            {
              name: '确认',
              callBack: () => {
                layer.close();
                hqAuditSZGL(this.seletdItem.ID, 1).then(
                  result => {
                    this.$emit('updateData');
                    this.getDataList();
                  },
                  error => {
                    layer.alert(error.msg);
                  });
              }
            }
          ]
        });
      } else {     // 课件
        CoursewareAuditPass(this.seletdItem.ID).then(result => {
          console.log('AuditPass', this.seletdItem);
          this.$emit('updateData');
          this.getDataList();
        }).catch(error => {
          console.log('errorAuditPass', error);
        });
      }
    },
    refuseItem () {
      if (this.seletdItem.TypeKey == 2) {     // 收支审核
        layer.confirm('请确定是否审核拒绝?', {
          btn: [
            {
              name: '确认',
              callBack: () => {
                layer.close();
                hqAuditSZGL(this.seletdItem.ID, 0).then(
                  result => {
                    this.$emit('updateData');
                    this.getDataList();
                  },
                  error => {
                    layer.alert(error.msg);
                  });
              }
            }
          ]
        });
      } else {     // 课件
        CoursewareRefuse(this.seletdItem.ID).then(result => {
          console.log('AuditPass', this.seletdItem);
          this.$emit('updateData');
          this.getDataList();
        }).catch(error => {
          console.log('errorAuditPass', error);
        });
      }
    },
    // 计算TOP值
    doOneTopValueCount (index) {
      this.newIndex = index;
      this.topValue = 0;
      let oneTopValue = 54;
      console.log(this.toScrollTop, 'doOneTopValueCount');
      if (this.toScrollTop > 0) {
        this.topValue = oneTopValue * this.newIndex - this.toScrollTop + this.topValue + 64 + 20;
      } else {
        this.topValue = this.topValue + (oneTopValue * this.newIndex) + 64 + 20;
      }
    }
  }
};
</script>

