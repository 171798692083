<template>
  <div class="select_xu_checkbox_group_xuan list_credit_type"
       :class="{opt:dataInfo.isActive}"
       @click.stop="clickItem">
    <div class="checkbox_view">
      <span>{{dataInfo.StudentNameKeyValue}}</span>
    </div>
    <!-- <div v-if="dataInfo.IsComment == 1" class="reviewed_box">
		</div> -->
    <div class="reviewed_box">
      <div v-if="dataInfo.IsComment == 1"
           class="reviewed">

      </div>
    </div>
    <div class="list_type"
         :class="vType">
      {{dataInfo.AttenceStatusKeyValue}}
    </div>
    <div class="list_credit" @click.stop="clickCurrency">
      <div class="list_credit_img"
           :class="{opt:dataInfo.Currency >= 1}"
           @click.stop="clickCurrency(1)">

      </div>
      <div class="list_credit_img"
           :class="{opt:dataInfo.Currency >= 2}"
           @click.stop="clickCurrency(2)">

      </div>
      <div class="list_credit_img"
           :class="{opt:dataInfo.Currency >= 3}"
           @click.stop="clickCurrency(3)">

      </div>
      <div class="list_credit_img"
           :class="{opt:dataInfo.Currency >= 4}"
           @click.stop="clickCurrency(4)">

      </div>
      <div class="list_credit_img"
           :class="{opt:dataInfo.Currency >= 5}"
           @click.stop="clickCurrency(5)">

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  computed: {
    vType () {
      if (this.dataInfo.AttenceStatusKey == 2) {
        return 'type_gray';
      } else if (this.dataInfo.AttenceStatusKey == 3) {
        return 'type_green';
      } else if (this.dataInfo.AttenceStatusKey == 4) {
        return 'type_yellow';
      } else if (this.dataInfo.AttenceStatusKey == 5) {
        return 'type_orange';
      }
    }
  },
  props: {
    dataInfo: Object
  },
  methods: {
    clickBtn(){

    },
    clickItem (val) {
       if (!this.dataInfo.isActive) {
          this.dataInfo.Currency = 0
          this.dataInfo.isActive = true;
        } else {
          this.dataInfo.Currency = 0;
          this.dataInfo.isActive = false;
          this.dataInfo.EssayAssessTempSetList=[]
        }
    },
    clickCurrency (val) {
      this.dataInfo.isActive = true;
      this.$emit('showStarList',this.dataInfo)
      // if (this.dataInfo.isActive) {
      //   if(val == this.dataInfo.Currency){
      //     this.dataInfo.Currency = 0;
      //   }else {
      //     this.dataInfo.Currency = val;
      //   }
      // }
    }
  }
};
</script>

<style>
</style>
