<template>
  <!-- 杂费内容 -->
  <div class="package_list label_radio_bg_white"
       :class="{delete_type:item.isOpt}"
       @click.stop="showMenu()">
    <div class="package_list_name">
      {{item.ProjectKeyValue}}
    </div>
    <div class="package_list_text">
      {{$utils.mMoneyType(item.UnitPrice,2)}}元 X{{$utils.mAmountType(Number(item.NumCount),1)}}{{item.UnitName}}
    </div>

    <div class="package_list_amount">
      <div>
        <span v-if="Number(item.AfterDisAmount)==0">赠送</span>
        <span v-else>￥{{$utils.mMoneyType(item.AfterDisAmount,2)}}</span>
      </div>
      <div class="font_gray"
           v-if="$utils.mMoneyType(item.AfterDisAmount,2)< $utils.mMoneyType(item.ValueAmount,2)">
        <span class="text_center_line">￥{{$utils.mMoneyType(item.ValueAmount,2)}}</span>
        <span>{{vDiscount}}</span>
      </div>
    </div>
    <!-- <div class="delete_btn"
         v-if="isShowDeleteBtn"
         @click.stop="doDeleteBtnClick">
      删除
    </div> -->
    
    <!--操作菜单 -->
    <!-- <div class="module_content_menuList"
         style="width: 120px; left: 260px; top: 44px;"
         v-if="item.isOpt">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="menuList"
                     @clickOptionForAlertDialog="onItemClick"></drop-down-box>
    </div> -->
  </div>
</template> 

<script>
// import dropDownBox from '../../../../../common/drop-down-box';
export default {
  data () {
    return {
      // isShowMenu: false, // 显示操作菜单
      menuList: [
        { key: 1, name: '修改', eventName: 'edit', isShow: true },
        { key: -1, name: '删除', eventName: 'delete', isShow: true }

      ]
    };
  },
  props: {
    item: Object, // 杂费信息 （必填）
    index: Number, // 下标
    taocanType: String
  },
  components: {
    // dropDownBox
  },
  created () {
    console.log(this.item, '杂费信息-123');
  },
  mounted () {

  },
  watch: {},
  computed: {
    vDiscount () {
      let str = '';
      if (Number(this.item.AfterDisAmount) == 0) {
        str = '';
      } else if (Number(this.item.AfterDisAmount) < Number(this.item.ValueAmount)) {
        str = this.$utils.mAmountType(this.item.DiscountCount, 1) + ' 折';
      } else {
        str = '';
      }
      return str;
    }
  },
  methods: {
    // 显示菜单
    showMenu () {
      if (this.taocanType == 'view') {
        return false;
      }
      this.$emit('showMenu', this.index, this.item, 'incidentals');
    }
    // 操作项
    // onItemClick (item, index) {
    //   console.log(item, index, '编辑');
    //   if (item.eventName == 'edit') {
    //     this.doEditClick();
    //   } else {
    //     this.doDeleteBtnClick();
    //   }
    // }
    // // 编辑
    // doEditClick () {
    //   this.$emit('edit', 'incidentals', this.index, this.item);
    // },
    // // 删除
    // doDeleteBtnClick () {
    //   this.$emit('delete', this.index, this.item, 'incidentals');
    // }
  }
};
</script>

  <style>
/*  */
</style>