<template>
    <div class="thirdparty_tencent_title">
        <div class="thirdparty_tencent_title_line">
            <div class="line_color" :style="'width:'+ vCompleteNumber"></div>
            <div v-for="item in vProgressInfo" :key="item.type"  :class="item.class" :style="'left:'+item.left"></div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
        }
    },
    props:{
        completeNumber:{
            type:Number,
            default:1
        }
    },
    computed:{
        vProgressInfo(){
            let arr = [
                {
                    left:'0',
                    type:1,
                    class:'title_radio'
                },
                {
                    left:'33.3%',
                    type:2,
                    class:'title_radio'
                },
                {
                    left:'66.6%',
                    type:3,
                    class:'title_radio'
                },{
                    left:'100%',
                    type:4,
                    class:'title_radio_last'
                }
            ]
            arr.forEach((e)=>{
                if(this.completeNumber > e.type){
                    e.class += ' radio_pass';
                }else if(this.completeNumber == e.type){
                    e.class += ' radio_current';

                }
            })
            return arr;
        },
        vCompleteNumber(){
            console.log(this.vProgressInfo[this.completeNumber - 1]);
            return this.vProgressInfo[this.completeNumber - 1].left;
        }
    },
    mounted(){
    }
}
</script>