<template>
  <!-- 批量续授权 -->
  <div class="authorization_courseware_form">
    
    <div class="form_title_info">授权课包</div>
    <div class="form_info_edit form_info_list">
      <input-select v-if="!isReadonly&&!vIsEdit&&!coursewareBagInfo"
                    :id="'OLAPKey'"
                    :name="'MainDemoName'"
                    :formTitle="'授权课包'"
                    :required="true"
                    :readonly="isReadonly||vIsEdit"
                    :dataList="bigCoursewareDataInfo"
                    :selectedInfo="vCoursewareInfo"
                    @selectedItem="selectCourseware"></input-select>

      <!-- 修改或者只读模式下 -->
      <input-dialog v-else
                    :formTitle="'授权课包'"
                    :formPlaceholder="'请输入'"
                    :required="true"
                    :readonly="true"
                    v-model="dataInfo.CoursewarePackageKeyValue"
                    @showEldilog="selectCourseware"></input-dialog>
     
      <input-select :formTitle="'结算方式'"
                    :id="'type'"
                    :name="'name'"
                    :required="true"
                    :readonly="isReadonly||vIsEdit"
                    :dataList="sourceType"
                    :selectedInfo="vSourceTypeInfo"
                    @selectedItem="changeSourceType"></input-select>

      <input-number v-if="dataInfo.ClearingFormKey==1"
                    :min="0"
                    :decimalPlaces="0"
                    :formTitle="'授权课次'"
                    :required="true"
                    :readonly="isReadonly"
                    :formPlaceholder="'请输入'"
                    v-model="dataInfo.ReleasedNum"></input-number>
      <!-- <data-range-tt v-else-if="dataInfo.ClearingFormKey==4"
                     :minDate="$utils.formatDateToLine(Date.new())"
                     :searchObj="vDataTime"
                     :fromTitle="'有效期'"
                     @changeDate="changeDate">
      </data-range-tt> -->
      <div v-else-if="dataInfo.ClearingFormKey==4"
           class="pr">
        <input-dialog :formTitle="'有效期'"
                      :formPlaceholder="'请选择时间'"
                      :required="true"
                      :readonly="isReadonly"
                      v-model="vDataTimeInput"
                      @showEldilog="selectDataTime">
        </input-dialog>
        <span class="help_title">{{vNumberData}}</span>
      </div>

      <input-number v-else-if="dataInfo.ClearingFormKey==2"
                    :min="0"
                    :decimalPlaces="0"
                    :formTitle="'授权时长（年）'"
                    :required="true"
                    :readonly="isReadonly"
                    :formPlaceholder="'请输入'"
                    v-model="dataInfo.Duration"></input-number>
    </div>
    <div class="form_title_info">校区信息</div>
    <div class="form_info_edit form_info_list">
      <input-dialog :formTitle="'校区授权记录'"
                    :formPlaceholder="'请选择'"
                    :required="true"
                    :readonly="isReadonly||vIsEdit"
                     :value="vCampusKeyValue"
                    @showEldilog="selectBantchList"></input-dialog>
    </div>
 

    <div v-if="!isReadonly">
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
     <custom-dialog :title="'批量选择校区'"
                   :visible.sync="showbatchRenewalForm"
                   :width="'595px'"
                   :before-close="closeBantchFrom">
      <batchListForm :seletedList="dataInfo.AuthList"

                    :dataInfo="dataInfo"
                    @close="closeBantchFrom"
                    @afterSuccess="setCampusList"></batchListForm>
    </custom-dialog>
  </div>
</template>
<script>
import {
  AuthorizationCoursewareBatchRenew,
  HQSearchCoursewarePackage
} from '../../../../../../API/workbench';
import batchListForm from './batch-list-form'
export default {
  data () {
    return {
      // 直营类型
      sourceType: [
        // { type: 1, name: '按次授权' },
        { type: 4, name: '按天授权' },
        { type: 2, name: '按年授权' },
        { type: 3, name: '永久授权' }

      ],
      bigCoursewareDataInfo: [],
      dataInfo: {
        AuthList:[],
        Duration:0,
        ReleasedNum:0,
        startTime: '',
        endTime: '',
        CoursewarePackageKey:'',
        CoursewarePackageKeyValue:'',
        ClearingFormKey: 2, //	整型	必须		数据源：GET	结算方式
        ClearingFormKeyValue: '按年授权',
      },
      reportInfo:null,
      showbatchRenewalForm:false
    };
  },
  components: {
    batchListForm
  },
  props: {
    coursewareId: Number,
    isReadonly: Boolean,
    coursewareBagInfo: {
      type: Object,
      default: null
    }
  },
  created () {
  
  },
  mounted () {
    this.getBigCourseware();
    this.$dialog.register(this, ['singleSelectionList', 'chooseAuthDataTime']);
  },
  computed: {
    vCampusKeyValue(){
      if(this.dataInfo.AuthList.length==0){
        return ''
      }else if(this.dataInfo.AuthList.length==1){
         return this.dataInfo.AuthList[0].MainDemoName
      }else{
        return '共'+this.dataInfo.AuthList.length+'家'
      }
    },
    vIsEdit () {
      if (this.coursewareId > 0) {
        return true;
      } else {
        return false;
      }
    },
    // 结算方式
    vSourceTypeInfo () {
      return {
        type: this.dataInfo.ClearingFormKey,
        name: this.dataInfo.ClearingFormKeyValue
      };
    },
    vDataTime () {
      return {
        startTime: this.dataInfo.startTime,
        endTime: this.dataInfo.endTime
      };
    },
    vDataTimeInput () {
      let text = '';
      if (this.dataInfo.startTime == '' && this.dataInfo.endTime == '') {
        text = '';
      } else {
        text = this.dataInfo.startTime + '  至  ' + this.dataInfo.endTime;
      }
      return text;
    },
    vCoursewareInfo () {
      return {
        id: this.dataInfo.CoursewarePackageKey,
        name: this.dataInfo.CoursewarePackageKeyValue
      };
    },
    vNumberData () {
      if (this.dataInfo.endTime == '') {
        return '';
      }
      var beginDate = new Date(this.dataInfo.startTime);
      var endDate = new Date(this.dataInfo.endTime);
      var day = (endDate - beginDate) / (1000 * 60 * 60 * 24);
      return (Number(day) + 1).toFixed(0) + '天';
    },
    // 收支账户
    vIncomePayAccount () {
      return {
        id: this.dataInfo.IncomePayAccountKey,
        name: this.dataInfo.IncomePayAccountKeyValue
      };
    },
  
  

  },
  methods: {
    selectBantchList(){
      this.showbatchRenewalForm=true
    },
    closeBantchFrom(){
      this.showbatchRenewalForm=false
    },
    setCampusList(dataList){
      console.log("setCampusList",dataList)
      this.dataInfo.AuthList=dataList
      this.closeBantchFrom()
    },
    // 选择结算方式
    changeSourceType (obj) {
      console.log(obj, '选择结算方式');
      this.dataInfo.ClearingFormKey = obj.type;
      this.dataInfo.ClearingFormKeyValue = obj.name;
    },
    
    // 选择起始时间和结束时间
    selectDataTime () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '有效期',
        moduleName: 'chooseAuthDataTime',
        data: {
          dataInfo: {
            startTime: this.dataInfo.startTime,
            endTime: this.dataInfo.endTime
          }
        },
        callBack: {
          afterSuccess: (data) => {
            console.log(data, '选择时间后返回');
            this.dataInfo.startTime = data.startTime;
            this.dataInfo.endTime = data.endTime;
          }
        }
      });
    },
    
    // 选择课件包
    selectCourseware (obj) {
      console.log(obj, '选择课件包');
      this.dataInfo.CoursewarePackageKey = obj.OLAPKey;
      this.dataInfo.CoursewarePackageKeyValue = obj.MainDemoName;
      this.dataInfo.CoursewarePackageList = [{ CoursewarePackageKey: obj.OLAPKey, CoursewarePackageKeyValue: obj.MainDemoName }];
      this.dataInfo.isAllCourseware = true;
      console.log(this.dataInfo.CoursewarePackageList, 'this.dataInfo.CoursewarePackageListsss');
    },

    // 提交
    submit (callback) {
      console.log('submit', this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      this.dataInfo.StartTime=this.dataInfo.startTime
      this.dataInfo.EndTime=this.dataInfo.endTime
        AuthorizationCoursewareBatchRenew(this.dataInfo).then(result => {
          layer.alert('授权成功');
          this.$emit('afterSuccess');
          this.cancel();
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callback) {
            callback();
          }
        });
      
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
      if (this.dataInfo.AuthList.length == 0 ) {
        layer.alert('请选择校区');
        flag = false;
      } else
        if (!this.dataInfo.CoursewarePackageKeyValue) {
          layer.alert('请选择课件包名称');
          flag = false;
        } else if (this.dataInfo.ClearingFormKey == 1 && !this.dataInfo.ReleasedNum) {
          layer.alert('授权课次不能为空，请输入大于0的数');
          flag = false;
        } else if (this.dataInfo.ClearingFormKey == 2 && !this.dataInfo.Duration) {
          layer.alert('授权时长（年）不能为空，请输入大于0的数');
          flag = false;
        } else if (this.dataInfo.ClearingFormKey == 4 && !this.dataInfo.startTime ) {
          layer.alert('请选择开始时间');
          flag = false;
        } else if (this.dataInfo.ClearingFormKey == 4 && !this.dataInfo.endTime ) {
          layer.alert('请选择结束时间');
          flag = false;
        }
      return flag;
    },
    getBigCourseware () {
      HQSearchCoursewarePackage(0).then(result => {
        this.bigCoursewareDataInfo = result.data.map((item, i) => {
          return {
            OLAPKey: item.OLAPKey,
            MainDemoName: item.MainDemoName
          };
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    cancel () {
      this.$emit('close');
    },
    changeDate (obj) {
      this.dataInfo.startTime = obj.startTime;
      this.dataInfo.endTime = obj.endTime;
    }

  }
};
</script>

<style>
.authorization_courseware_form {
  height: 595px;
}
.authorization_courseware_form .form_info_list .form_info_value {
  width: 300px;
}
</style>
<style scoped>
.help_title {
  width: 64px;
  color: #999;
  position: absolute;
  top: 16px;
  left: 65px;
}
</style>


