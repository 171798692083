<template>
  <div>
    <div class="calendar_header">
      <span class="left_side">日历排课</span>
      <span class="fr right_side">
        已选 {{courseTableData.DateList.length}} 天
        <span class="font_blue"
              @click.stop="reset">重置</span>
      </span>
    </div>
    <select-class-time-rang :formInfoLine="false"
                            :required="false"
                            class="from_box_margin"
                            @onSelectTimeRange="selectTimeRange"
                            @onDelTimeRange="delTimeRange"></select-class-time-rang>
    <month-chart ref="monthChart"
                 :isBut="true"
                 :monthInfo="monthInfo"
                 :courseDateList="courseTableData.DateList"
                 @getMonthInfo="getMonthInfo"
                 @continuePress="continuePress"
                 @continueClick="continueClick"
                 @headerMenu="serachPopup"></month-chart>
    <div class="popup_dialog_tips_box"
         v-if="monthInfo.length>0">
      <div class="tips_text"><span class="month_chart_tips_icon"></span>表示已建课日期，请勿重复</div>
    </div>

  </div>
</template>

<script>
import monthChart from './month-chart';
// import setClassTimeRang from '../../../common/set-class-time-rang';
// import selectClassTimeRang from '../../../common/select-class-time-rang';
import { GetCourseListForAddCourse } from '../../../../API/workbench.js';
export default {
  data () {
    return {
      monthInfo: [], // 月视图数据
      // courseDateList: [],     // 选中日期.
      timeRangeList: [],      // 时间段列表
      seletedTimeInfo: null,    // 选中时间段信息.
      isShowAddTimeRange: false,   // 显示新增时间.
      seletedDate: this.$utils.getCurrentMonthFirst()
    };
  },
  props: {
    courseTableData: {
      type: Object
    }
  },
  components: {
    monthChart
    // setClassTimeRang,
    // selectClassTimeRang
  },
  created () {
  },
  methods: {
    getMonthInfo (dateStr) {
      this.seletedDate = dateStr;
      if (this.seletedTimeInfo) {
        this.selectTimeRange(this.seletedTimeInfo);
      }
    },
    // 外部调用
    getTimeRangeCourseData () {
      console.log('getTimeRangeCourseData', this.seletedTimeInfo);
      if (this.seletedTimeInfo) {
        this.selectTimeRange(this.seletedTimeInfo);
      }
    },
    // 选中时间段查询
    selectTimeRange (item) {
      this.seletedTimeInfo = item;
      let newData = {
        CourseKey: this.courseTableData.ClassSetKey,
        GradeKey: this.courseTableData.BJPKIDKey,
        ClassTimeName: this.seletedTimeInfo.SClassTimeName + '-' + this.seletedTimeInfo.EClassTimeName,
        StartTime: this.$utils.formatDateStr(Date.new(Date.new(Date.new(this.seletedDate).setMonth((Date.new(this.seletedDate).getMonth() - 1))).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        EndTime: this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new(Date.new(this.seletedDate).setMonth((Date.new(this.seletedDate).getMonth() + 1)))), 'yyyy-MM-dd')
      };
      let seletedDateList = this.$utils.parseJson(this.courseTableData.DateList);
      seletedDateList.sort((x, y) => {
        return Date.new(x) - Date.new(y);
      });
      if (seletedDateList.length > 0) {
        let lastDate = Date.new(seletedDateList[seletedDateList.length - 1]) > Date.new(newData.EndTime);
        let startDate = Date.new(seletedDateList[0]) < Date.new(newData.StartTime);
        newData.EndTime = lastDate ? seletedDateList[seletedDateList.length - 1] : newData.EndTime;
        newData.StartTime = startDate ? seletedDateList[0] : newData.StartTime;
      }
      this.$emit('selectTimeRange', this.seletedTimeInfo);
      this.getCourseListForAddCourse(newData);
    },
    // 删除时间段
    delTimeRange (item) {
      this.seletedTimeInfo = item;
      this.$emit('selectTimeRange', this.seletedTimeInfo);
      // 清除当前选中时间段获取的课信息
    },
    // 获取课列表信息
    getCourseListForAddCourse (data) {
      GetCourseListForAddCourse(data).then(result => {
        this.monthInfo = result.data;
        this.monthInfo.forEach(o => {       // 获取到数据时判断是否已经排课的日期被选中, 如果被选中则去除.
          let locaIndex = this.courseTableData.DateList.findIndex(p => {
            return p == o.ClassTime;
          });
          if (locaIndex >= 0) {
            this.courseTableData.DateList.splice(locaIndex, 1);
          }
        });
        console.log('getCourseListForAddCourse', result.data, this.courseTableData.DateList);
      }).catch(error => {
        console.log(error, 'errorGetCourseListForAddCourse');
      });
    },
    continuePress () {

    },
    continueClick () {

    },
    serachPopup () {

    },
    reset () {
      this.courseTableData.DateList = [];
    }
  }

};
</script>

<style>
</style>