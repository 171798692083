<template>
	<!-- 批改作业详情 -->
	<div class="ov_x" style="height: 750px;overflow-y: scroll;" v-if="workCorrectDetailsInfo.ID">
		<!-- <basics-page-header :titleName="'批改作业'"></basics-page-header> -->
		<!-- 头部信息 -->
		<work-correct-head :dataInfo="workCorrectDetailsInfo"></work-correct-head>
		<div class="complete_the_job no_border_bottom">
			<!-- 提交内容 -->
			<work-submit-content :dataInfo="workCorrectDetailsInfo"></work-submit-content>
			<!-- 批改内容 -->
			<div v-if="workCorrectDetailsInfo.Correct">
				<work-correct-content :dataInfo="workCorrectDetailsInfo.Correct" :Currency="workCorrectDetailsInfo.Currency" @remarkEdit="remarkEdit"></work-correct-content>
			</div>
		</div>
	</div>
</template>

<script>
import {GetStudentSubmitHomeWorkDetails} from '../../../../API/workbench';
import workCorrectHead from './common/work-correct-head.vue';
import workSubmitContent from './common/work-submit-content.vue';
import workCorrectContent from './common/work-correct-content.vue';
export default {
  components: {
    workCorrectHead,
    workSubmitContent,
    workCorrectContent
  },
  data () {
    return {
      workId: '', // 批改作业id
      workCorrectDetailsInfo: {},
      isShowWorkInfo: false
    };
  },
  props: {
    dataInfo: {
       type: Object,
       default: () => {
        return {
          ID: 0
        };
      }
     }
  },
  created () {
    this.workId = this.dataInfo.ID;
    if (this.workId) {
      this.getStudentSubmitHomeWorkDetails(this.workId);
    }
  },
  methods: {
    // 获取批改作业的详情
    getStudentSubmitHomeWorkDetails (workId) {
      GetStudentSubmitHomeWorkDetails(workId).then(res => {
        console.log(res.data, 'res.data');
        this.workCorrectDetailsInfo = res.data;
      }, err => {
        this.$store.dispatch('popup/open', {
          content: err.msg
        });
      });
    },
    // 修改批改作业
    remarkEdit () {
      console.log(this.workCorrectDetailsInfo, '去批改作业111');
      let moduleInfo = {
        name: '批改作业',
        moduleName: 'taskCorrectDetails',
        routerName: this.$route.name,
        data: { taskData: {
          essayID: this.workCorrectDetailsInfo.HomeWork.ID,
          SubHomeWorkKey: this.workCorrectDetailsInfo.ID,
          isEdit: true

        } }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
      // const url = '/pages/home-school-communication/correct-home-task/index';
      // this.$zxrouter.navigateTo({
      //   url: url +
      //     '?essayID=' + this.workCorrectDetailsInfo.HomeWork.ID +
      //     '&SubHomeWorkKey=' + this.workCorrectDetailsInfo.ID +
      //     '&isEdit=true'
      // });
    }
  }
};
</script>

<style>

</style>
