<template>
  <div class="massive_bg apply_payment_details_box">
    <div class="apply_payment_box_title">
      {{formTitle}}
      <span>{{SetUpTime}}</span>
    </div>
    <div class="apply_payment_box_singular box_dot dot_green"> 
      签到 <span class="font_big">{{dataInfo.Attence3 ||0}}</span> 人
    </div>
    <div class="apply_payment_box_singular box_dot dot_blue" v-if="this.$utils.getSaaSClientSet(59)>0">
      请假 <span>{{dataInfo.Attence4||0}}</span> 人 ;旷课 <span>{{dataInfo.Attence5||0}}</span> 人
    </div>
        <div class="apply_payment_box_singular box_dot dot_blue" v-else>
      请假 <span>{{dataInfo.Attence4||0}}</span> 人 ;
    </div>
    <div class="apply_payment_box_singular box_dot dot_yellow">
      已上 <span>{{dataInfo.HavedClass||0}}</span> 节课；未考勤 <span>{{dataInfo.Attence2||0}}</span> 人
    </div>
    <a @click.stop="showDetail"
       class="class_statistics_details_btn">
      查看详情
    </a>
  </div>
</template>
<script>
export default {
  name: 'dayAtten',
  data () {
    return {

    };
  },
  props: {
    formTitle: String,
    dataInfo: Object           // 数据源
  },
  computed: {
    SetUpTime () {
      if (this.dataInfo) {
        if (this.dataInfo.SetUpTime) {
          return this.dataInfo.SetUpTime;
        } else {
          return '';
        }
      }
    }
  },
  methods: {
    showDetail () {
      this.$emit('showDetail');
    }
  }
};
</script>
