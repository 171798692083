<template>
  <div class="index_JXT_content">
    <div class="index_JXT_box"
         v-if="vIsHasData&&isShowWorkerAndCommentData">
      <!-- 作业  -->
      <div v-if="workerAndCommentData.workerList.length>0">
        <div class="index_JXT_title">
          <div class="title_name type_homework">作业</div>
          <div class="title_number">
            已布置
            (<span class="font_black">{{mCommentDataNumber(workerAndCommentData.WorkPublishedNum)}}</span>)｜ 未布置
            (<span class="font_red">{{mCommentDataNumber(workerAndCommentData.noWorkPublishedNum)}}</span>)
          </div>
          <!-- 作业学分 -->
          <div class="title_award close_audio_playing"
               @click="doTaskStudentMoneyDetails">奖励 {{mCommentDataNumber(workerAndCommentData.WorkCurrency)}}</div>
        </div>
        <div>
        </div>
        <div class="index_JXT_ul">
          <!-- 作业内容 -->
          <home-task-browse-item v-for="item in workerAndCommentData.workerList"
                                 :key="item.ID"
                                 :dataInfo="item"
                                 :IsAllSchool="vIsAllSchool"
                                 :contentTextButton="vTaskTextBtn"
                                 :headBtnType="vHeadBtnType"
                                 @edit="doEditHomeWorkAfter"></home-task-browse-item>
        </div>
      </div>

      <!-- 点评  -->
      <div v-if="this.workerAndCommentData.commentList.length > 0">
        <div class="index_JXT_title">
          <div class="title_name type_remark">点评</div>
          <div class="title_number">
            已点评(<span class="font_black">{{mCommentDataNumber(workerAndCommentData.ReviewNum)}}</span>) |
            未点评(<span class="font_red">{{mCommentDataNumber(workerAndCommentData.noReviewNum)}}</span>) | 
            未回复(<span class="font_red">{{mCommentDataNumber(workerAndCommentData.NoReplyNum)}}</span>)
          </div>
          <!-- 作业学分 -->
          <div class="title_award close_audio_playing"
               @click="doRemarkStudentMoneyDetails">奖励 {{mCommentDataNumber(workerAndCommentData.ReviewCurrency)}}</div>
        </div>
        <div>
          <!-- 点评内容 -->
          <home-comment-browse-item v-for="item in workerAndCommentData.commentList"
                                    :key="item.ID"
                                    :dataInfo="item"
                                    :contentTextButton="''"
                                    :IsAllSchool="vIsAllSchool"
                                    :headBtnType="vHeadBtnType"
                                    @upData="getDynamicListByCourseID"
                                    @edit="doEditCommentAfter"></home-comment-browse-item>
        </div>
      </div>
      <!-- <div>
      <select-menu-popup ref="isShowMenu"
                         :menuData="replyOperateMenu"
                         @onItemClick="onItemClick"></select-menu-popup>
    </div>
    <div>
      <send-message v-show="replyStutas"
                    :byReply="byReplyContent"
                    :essayDetail="byReplyDataInfo"
                    :replyStutas="replyStutas"
                    @onBlurChg="onBlurChg"
                    @onFocusChg="onFocusChg"
                    @replyCompleted="replyCompleted"></send-message>
    </div> -->
    </div>
    <!-- 无数据 -->
    <div class="index_JXT_noData"
         v-else>
      <span class="index_JXT_noData_text">暂无作业/点评</span>
    </div>
    <!-- v-if="studentArrangeList&&studentArrangeList.length>0" -->
    <div v-if="!teacherDetailsShow && vShowFxiedBtn &&isShowWorkerAndCommentData"
         class="index_JXT_fixed_btn">
      <div class="btn_box"
           @click.stop="releaseComment">点评学生</div>
      <div class="btn_box"
           @click.stop="releaseWorker">布置作业</div>

    </div>
  </div>
</template>

<script>

import { GetDynamicByCourse, GetCourseCurrency, EditCourseCurrency, getScheduleCourseOnStudentList } from '../../../API/workbench.js';
import homeTaskBrowseItem from '../home-task-page/home-task-browse-item';
import homeCommentBrowseItem from '../dynamic-page/home-comment-browse-item';
import studentCoinDetails from '../common-component/student-coin-details/details-list';

// import correctHomeTask from '../release-essay/correct-home-task/index';
export default {
  components: {
    homeTaskBrowseItem,
    homeCommentBrowseItem,
    studentCoinDetails
    // correctHomeTask
    // sendMessage
  },
  props: {
    scheduleCourseInfo: Object, // 课信息
    scheduleCourseList: Array, // 课程列表
    teacherDetailsShow: Boolean
  },
  data () {
    return {
      isShowWorkerAndCommentData: true,
      workerAndCommentData: {// 作业点评数据列表
        OLAPKey: 0,
        CourseNameKey: 0,
        CourseNameKeyValue: '',
        WeekKey: 0,
        WeekKeyValue: '',
        ClassTime: '',
        SClassTimeName: '',
        EClassTimeName: '',
        LecturerKey: '',
        LecturerKeyValue: '',
        ArrangedNumCount: '0',
        HeadImg: '',
        WorkPublishedNum: 0,
        ReviewNum: 0,
        noWorkPublishedNum: 0,
        noReviewNum: 0,
        WorkCurrency: 0,
        ReviewCurrency: 0,
        NoReplyNum: 0,
        workerList: [],
        commentList: []
      },
      studentArrangeList: [], // 安排学生考勤人数
      coinType: null
    };
  },
  computed: {
    vIsHasData () {
      return this.workerAndCommentData.workerList.length > 0 || this.workerAndCommentData.commentList.length > 0;
    },
    vShowFxiedBtn () {
      if (Date.new(this.$utils.formatDateToLine(Date.new())) < Date.new(this.scheduleCourseInfo.ClassTime)) {
        return false;
      } else {
        return true;
      }
    },
    vTaskTextBtn () {
      if (this.teacherDetailsShow) {
        return '';
      } else {
        return '批改作业';
      }
    },
    vHeadBtnType () {
      if (this.teacherDetailsShow) {
        return '';
      } else {
        return 'btn';
      }
    },
    vIsAllSchool () {
      if (this.teacherDetailsShow) {
        return 1;
      } else {
        return 2;
      }
    }
  },
  watch: {
    'scheduleCourseInfo.ScheduleCourseKey': {
      handler (n, o) {
        if (n && n != o) {
          this.getDynamicListByCourseID();
          this.getStudentArrangeList(n);
        }
      },
      immediate: true // 监听
    }
  },
  created () {
    // this.scheduleCourseInfo.ScheduleCourseKey = 287252;
    if (this.scheduleCourseInfo.ScheduleCourseKey) {
      this.getDynamicListByCourseID();
    }
    this.registerBusEvent();
  },
  mounted () {
    this.$dialog.register(this, ['releaseHomeTask', 'releaseComment', 'studentCoindetails']);
  },
  methods: {
    // 布置作业
    releaseWorker () {
      if (this.scheduleCourseInfo.IsClassStopKey == 1) {
        layer.alert('这节课已停课');
      } else if (this.studentArrangeList.length == 0) {
        layer.alert('这节课没有安排学生');
      } else {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '布置作业',
          moduleName: 'releaseHomeTask',
          data: { taskData: { scheduleCourseID: this.scheduleCourseInfo.ScheduleCourseKey, courseName: this.scheduleCourseInfo.CourseNameKeyValue } },
          callBack: {
            afterSuccess: () => { this.getDynamicListByCourseID(); }
          }
        });
      }
    },
    // 发布点评
    releaseComment () {
      if (this.scheduleCourseInfo.IsClassStopKey == 1) {
        layer.alert('这节课已停课');
      } else if (this.studentArrangeList.length == 0) {
        layer.alert('这节课没有安排学生');
      } else {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '发布点评',
          moduleName: 'releaseComment',
          data: { taskData: { scheduleCourseID: this.scheduleCourseInfo.ScheduleCourseKey, courseName: this.scheduleCourseInfo.CourseNameKeyValue } },
          callBack: {
            afterSuccess: () => { this.getDynamicListByCourseID(); }
          }
        });
      }
    },
    // 修改作业
    doEditHomeWorkAfter (item, submitBtnName) {
      console.log(item, submitBtnName, '修改作业');
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '修改作业',
        moduleName: 'releaseHomeTask',
        data: { taskData: { scheduleCourseID: this.scheduleCourseInfo.ScheduleCourseKey, courseName: this.scheduleCourseInfo.CourseNameKeyValue, essayID: item.ID, submitBtnName: submitBtnName } },
        callBack: {
          afterSuccess: () => { this.getDynamicListByCourseID(); }
        }
      });
    },
    // 修改点评
    doEditCommentAfter (item) {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '修改点评',
        moduleName: 'releaseComment',
        data: { taskData: { scheduleCourseID: this.scheduleCourseInfo.ScheduleCourseKey, courseName: this.scheduleCourseInfo.CourseNameKeyValue, essayID: item.ID } },
        callBack: {
          afterSuccess: () => { this.getDynamicListByCourseID(); }
        }
      });
    },
    // 作业学分详情
    doTaskStudentMoneyDetails () {
      // if (Number(this.workerAndCommentData.WorkCurrency) > 0) {
      GetCourseCurrency(this.scheduleCourseInfo.ScheduleCourseKey, 1).then(result => {
        this.coinType = 1;
        this.$dialog.open(this, {
          routerName: this.$route.name,
          ModulePowerKey: '',
          name: '奖励学分详情',
          moduleName: 'studentCoindetails',
          data: { studentMoneyList: result.data || [] },
          callBack: {
            confirmClick: (studentList) => { this.currencyConfirmClick(studentList); }
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
      // }
    },
    // 点评学分详情
    doRemarkStudentMoneyDetails () {
      if (Number(this.workerAndCommentData.ReviewCurrency) > 0) {
        GetCourseCurrency(this.scheduleCourseInfo.ScheduleCourseKey, 2).then(result => {
          this.coinType = 2;
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '奖励学分详情',
            moduleName: 'studentCoindetails',
            data: { studentMoneyList: result.data || [] },
            callBack: {
              confirmClick: (studentList) => { this.currencyConfirmClick(studentList); }
            }
          });
        }, error => {
          layer.alert(error.msg);
        });
      }
    },
    // 修改学分
    currencyConfirmClick (studentList) {
      EditCourseCurrency(this.scheduleCourseInfo.ScheduleCourseKey, this.coinType, studentList).then(result => {
        this.getDynamicListByCourseID();
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取课内安排考勤人数
    getStudentArrangeList (scheduleCourseKey) {
      getScheduleCourseOnStudentList(scheduleCourseKey).then(result => {
        this.studentArrangeList = result.data || [];
        console.log(this.studentArrangeList, '获取课内安排考勤人数');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 返回指定课的所有点评和作业
    getDynamicListByCourseID () {
      console.log(this.scheduleCourseInfo.ScheduleCourseKey, '刷新- 返回指定课的所有点评和作业');
      if (this.scheduleCourseInfo.ScheduleCourseKey > 0) {
        GetDynamicByCourse(this.scheduleCourseInfo.ScheduleCourseKey).then(result => {
          if (result.data.commentList.length > 0) {
            result.data.commentList.forEach(c => {
              c.ClassTime = result.data.ClassTime;
              c.ReplyList.forEach((i) => {
                this.$set(i, 'activeName', false); // 强制监听此属性.
              });
            });
          }
          if (result.data.workerList.length > 0) {
            result.data.workerList.forEach(w => {
              w.ClassTime = result.data.ClassTime;
            });
          }
          this.workerAndCommentData = result.data;
          this.$emit('getCourseDynamicInfo', result.data);
          // 切换到明天及明天后的日期还会显示1秒左右，改为默认隐藏
          if (Date.new(this.$utils.formatDateToLine(Date.new())) >= Date.new(this.scheduleCourseInfo.ClassTime)) {
            console.log(this.scheduleCourseInfo.ClassTime);
            this.isShowWorkerAndCommentData = true;
          }
        }, error => {
          layer.alert(error.msg);
        });
      }
    },
    mCommentDataNumber (num) {
      if (num && Number(num) > 0) {
        return parseInt(num);
      } else {
        return 0;
      }
    },
    registerBusEvent () {
      // 发布作业后回调
      this.$bus.on(this, 'AfterUpdateSubmitHomeTask', (data) => {
        this.getDynamicListByCourseID();
      });
    }
  }
};
</script>

<style>
</style>