<template>
  <div class="flex"
       :class="vExpireKey?'vExpireKey':''">
  
    <div class="coursetips"
         :class="$store.getters.SaaSClientEditionKey!=6?'jwTipsTop':''"
         v-if="bigCoursewareItem.ISAuthExpireKey==1">课件端口过期，无法加载课件，请联系课件授权方</div>
    <div class="coursetips"
         :class="$store.getters.SaaSClientEditionKey!=6?'jwTipsTop':''"
         v-else-if="bigCoursewareItem.IsSuspendAuthKey==1">课件授权暂停，无法加载课件，请联系课件授权方</div>
    <div class="coursetips"
         :class="$store.getters.SaaSClientEditionKey!=6?'jwTipsTop':''"
         v-else-if="vshowChannelDate>0">课件端口{{vshowChannelDate}}天后过期，过期后无法加载课件，请联系课件授权方续期</div>
    <div class="coursetips"
         :class="$store.getters.SaaSClientEditionKey!=6?'jwTipsTop':''"
         v-else-if="vshowExpireTime>0">课包授权{{vshowExpireTime}}天后过期，过期后无法加载课件，请联系课件授权方续期</div>
    <div class="cw_details_img pr"
         @click.stop="doBigCwDetails">
      <img :src="headImg"
           :onerror="defaultImg" />
      <!-- <span class="cw_authorization"
            v-if="vIsAcademicMd">授权方：{{bigCoursewareItem.AuthorizedParty}}</span> -->
    </div>
    <div class="head_div_right">
      <span class="left_box_list_span pr"
            :class="isMoveCourse?'move_course_div':''"
            :title="bigCoursewareItem.MainDemoName"
            @mouseover="moveCourseFun"
            @mouseout="outCourseFun"
            @click.stop="doBigCwDetails"><span class="data_span">{{bigCoursewareItem.MainDemoName}}</span></span>
      <div class="list_btn">
        <div class="btn_hover_bg_blue"
             v-if="!vIsAuthor"
             @click.stop="doAuthorizeTeacher">授权老师</div>
      </div>
      <div class="flexA">
        <!-- 1 -->
        <div class="left_both_list"
             :style="vIsAcademicMd?'margin-top: 20px;':'margin-top: 25px;'">
          <div v-if="vIsAcademicMd"
               class="list_content"
               :title="bigCoursewareItem.AuthorizedParty"
               style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 240px;"><span class="list_span">授权方：</span>
            <span class="font_span"
                  style="margin-left:12px;">{{bigCoursewareItem.AuthorizedParty}}</span>
          </div>
          <div v-else
               class="list_content"
               style="    color: #999;">
            授权老师：
            <span class="font_span">{{vIsAuthor?'全部':vTeacher}}</span>
          </div>
          <!--  -->
          <div class="list_content"
               style="position: absolute;left: 249px;border-radius: 100px;padding: 3px 8px;text-align: center;"
               :style="bigCoursewareItem.IsPCAuthKey!='0' && Number(this.bigCoursewareItem.DeviceIsAuth) > 0?'background: #d1f8e6;':'background: rgba(228, 62, 9, 0.1);'">
            <span class="list_span"
                  :style="bigCoursewareItem.IsPCAuthKey!='0' && Number(this.bigCoursewareItem.DeviceIsAuth) > 0?'color: #1cce75;font-size: 14px;':'color: rgba(228, 62, 9, 1);'">当前设备：</span>
            <span v-if="bigCoursewareItem.IsPCAuthKey=='0'"
                  class="device_auth_no">
              {{vAuthorizeType}}
            </span>
            <span v-else
                  :class="Number(bigCoursewareItem.DeviceIsAuth)>0?'device_auth_ok':'device_auth_no'">
              {{vAuthorizeType}}
            </span>
          </div>
        </div>
        <!-- 2 -->
        <div class="left_both_list"
             v-if="vIsAcademicMd">
          <div class="list_content"
               v-if="vIsAcademicMd"><span class="list_span">协议时长：</span>
            <span class="font_black"
                  v-if="bigCoursewareItem.ClearingFormKeyValue == '永久授权'">{{bigCoursewareItem.AuthorizeTime}} ~ 不限</span>
            <span class="font_black"
                  v-else-if="bigCoursewareItem.ExpireTime">{{bigCoursewareItem.AuthorizeTime}} ~ {{ bigCoursewareItem.ExpireTime }}</span>
            <span class="font_black"
                  v-else>{{bigCoursewareItem.AuthorizeTime}}</span>
          </div>
          <div class="list_content"
               style="position: absolute;left: 252px;margin-left: 0;"><span class="list_span">剩余天数：</span>
            <span class="font_black">{{vAuthorizeTips}}</span>
          </div>
        </div>
        <!-- 3 -->
        <div class="left_both_list"
             :style="vIsAcademicMd?'':'margin-top: 20px;'">
          <div class="list_content"><span class="list_span">设备支持：</span>
            <div class="support_bj"
                 style="left: 63px;width: 30px;"></div>
            <div class="support_bj"
                 style="left: 98px;width: 30px;"></div>
            <div class="support_bj"
                 style="right: 0px;width: 30px;"></div>
            <span class="support_span">电脑</span>
            <span class="support_span">手机</span>
            <span class="support_span">电视</span>
          </div>
          <div class="list_content"
               style="margin-left: 0px;position: absolute;left: 252px;"><span class="list_span">安全支持：</span>
            <div class="support_bj"
                 style="left: 62px;width: 67px;"></div>
            <div class="support_bj"
                 style="left: 132px;width: 67px;"></div>
            <div class="support_bj"
                 style="right: 1px;width: 53px;"></div>
            <span class="support_span">防录屏截屏</span>
            <span class="support_span">防复制下载</span>
            <span class="support_span">位置锁定</span>
          </div>
        </div>
        <!--  -->

      </div>

    </div>
    <div class="device_div">
      <div class="device_control">设备授权：</div>
      <div class="device_control_list">
        <iconAuthorization v-for="(item,index) in vCourseAuthList"
                           :key="index"
                           :isShowBeforLine="true"
                           :courseAuth="item"
                           @doDeviceCount="doDeviceCount"></iconAuthorization>
      </div>
    </div>
  </div>
</template>
<script>
import iconAuthorization from './icon-authorization.vue';
export default {
  data () {
    return {
      isAuthor: '0',
      isMoveCourse: false
    };
  },
  props: {
    bigCoursewareItem: Object
  },
  created () {

  },
  mounted () {
    console.log('bigCoursewareItem.AuthorizedParty', this.bigCoursewareItem, this.vSaaSClientEditionKey, this.$store.getters.SaaSClientEditionKey);
  },
  components: {
    iconAuthorization
  },
  computed: {
    vshowChannelDate(){
      let date=this.bigCoursewareItem.CoursewareChannelExpiredTime||''
      let today=Date.new().toLocaleDateString()
      console.log("vshowChannelDate",date,Date.new(date).getTime()-Date.new(today).getTime(),86400000*7)
       // this.bigCoursewareItem.ExpireTime=date
      if(date&&(Date.new(date).getTime()-Date.new(today).getTime()+86400000)<=86400000*7){
        return parseInt((Date.new(date).getTime()-Date.new(today).getTime()+86400000)/86400000)
      }else{
        return 0
      }
    },
    vshowExpireTime(){
      let date=this.bigCoursewareItem.ExpireTime||''
      let today=Date.new().toLocaleDateString()
      console.log("vshowExpireTime",date,Date.new(date).getTime()-Date.new(today).getTime(),86400000*7)
        // this.bigCoursewareItem.ExpireTime=date
      if(date&&(Date.new(date).getTime()-Date.new(today).getTime()+86400000)<=86400000*7){
        return parseInt((Date.new(date).getTime()-Date.new(today).getTime()+86400000)/86400000)
      }else{
        return 0
      }
    },
    vExpireKey () {
      return this.bigCoursewareItem.ISAuthExpireKey == 1 || this.bigCoursewareItem.IsSuspendAuthKey == 1;
    },
    vIsAuthor () {
      return this.$utils.getSaaSClientSetInfo(71).SetKey == '1';
    },
    vSaaSClientEditionKey () {
      if ((this.$store.getters.SaaSClientEditionKey == 2 || this.$store.getters.SaaSClientEditionKey == 3 || this.$store.getters.SaaSClientEditionKey == 6)) { // 教务门店
        return true;
      } else {
        return false;
      }
    },
    vDeviceCountClass () {
      if (this.vSaaSClientEditionKey || this.$store.getters.SaaSClientEditionKey == 6) { // 教务门店
        return true;
      } else {
        return false;
      }
    },
    vIsAcademicMd () {
      if (this.vSaaSClientEditionKey && this.$store.getters.AcademicMdCwType == 2) { // 自研
        return false;
      } else {
        return true;
      }
    },
    vDeviceCount () {
      if (this.vSaaSClientEditionKey && this.$store.getters.AcademicMdCwType == 2) { // 自研
        return Number(this.bigCoursewareItem.DeviceAuthCount) + '台';
      } else {
        return Number(this.bigCoursewareItem.DeviceAuthCount) + '/' + Number(this.bigCoursewareItem.AuthorizeDeviceNum) + '台';
      }
    },
    // 授权方式
    vAuthorizeTips () {
      let residueNum = '';
      // let residueNum = (Number(this.bigCoursewareItem.ReleasedNum) - Number(this.bigCoursewareItem.UsageNum)) +
      //   '/' + Number(this.bigCoursewareItem.ReleasedNum);
      // if (this.bigCoursewareItem.ClearingFormKey == 1 && Number(this.bigCoursewareItem.ReleasedNum) > 0) {
      //   return '按次授权' + '(剩余可用' + residueNum + '课次)';
      // } else if (this.bigCoursewareItem.ClearingFormKey == 2) {
      //   return '按年授权' + '(剩余可用' + residueNum + '天)';
      // } else if (this.bigCoursewareItem.ClearingFormKey == 4) {
      //   return '按天授权' + '(剩余可用' + residueNum + '天)';
      // } else {
      //   return this.bigCoursewareItem.ClearingFormKeyValue;
      // }
      if ((Number(this.bigCoursewareItem.ReleasedNum) - Number(this.bigCoursewareItem.UsageNum)) == 0) {
        residueNum = '不限';
      } else {
        residueNum = (Number(this.bigCoursewareItem.ReleasedNum) - Number(this.bigCoursewareItem.UsageNum)) + '天';
      }
      if (Number(this.bigCoursewareItem.ClearingFormKey) == 3) {
        residueNum = '-';
      }
      return residueNum;
    },
    vTeacher () {
      if (this.bigCoursewareItem.TeacherAuthCount) {
        return Number(this.bigCoursewareItem.TeacherAuthCount) + '人';
      } else if (this.bigCoursewareItem.AuthTeacherList && this.bigCoursewareItem.AuthTeacherList.length > 0) {
        let teacherNames = '';
        this.bigCoursewareItem.AuthTeacherList.map(o => {
          teacherNames = (teacherNames ? teacherNames + '、' + o.TeacherKeyValue : o.TeacherKeyValue);
        });
        return Number(this.bigCoursewareItem.AuthTeacherList.length) + '人';
      } else {
        return '无';
      }
    },
    vAuthorizeType () {
      if (!this.$zxClient.IsClient) {
        return '非桌面端';
      } else {
        if (this.bigCoursewareItem.IsPCAuthKey == '0') {
          return '禁用';
        } else {
          if (Number(this.bigCoursewareItem.DeviceIsAuth) > 0) {
            return '已授权';
          } else {
            return '未授权';
          }
        }
      }
    },
    vCourseAuthList () {
      let data = this.bigCoursewareItem;
      let arr = [];
      if (!this.bigCoursewareItem) {
        return arr;
      }
      let itemPC = {
        IsAuthKey: data.IsPCAuthKey,
        IsRecordKey: data.IsPCRecordKey,
        AuthorizeDeviceNum: data.AuthorizeDeviceNum,
        AuthCount: data.PCDeviceAuthCount,
        LocationLockKey: data.PCIsLocationLockKey,
        type: 'PC'
      };
      let itemTV = {
        IsAuthKey: data.IsTVAuthKey,
        IsRecordKey: data.IsTVRecordKey,
        AuthorizeDeviceNum: data.TVAuthorizeDeviceNum,
        AuthCount: data.TVDeviceAuthCount,
        LocationLockKey: data.TVIsLocationLockKey,
        type: 'TV'
      };
      let itemAPP = {
        IsAuthKey: data.IsAPPAuthKey,
        IsRecordKey: data.IsAPPRecordKey,
        AuthorizeDeviceNum: data.APPAuthorizeDeviceNum,
        AuthCount: data.APPDeviceAuthCount,
        LocationLockKey: data.IsLocationLockKey,
        type: 'APP'
      };
      arr.push(itemPC);
      arr.push(itemAPP);
      arr.push(itemTV);
      return arr;
    },
    headImg () {
      if (!this.bigCoursewareItem.CoverUrl) {
        return '';
      } else if (this.bigCoursewareItem.CoverUrl.indexOf('http') > -1) {
        return this.bigCoursewareItem.CoverUrl;
      } else {
        return this.$store.getters.CDNURL + this.bigCoursewareItem.CoverUrl;
      }
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' + require('../../../../../public/image/no_logo@2x.png') + '"'
      );
    }
  },
  methods: {
    moveCourseFun () {
      this.isMoveCourse = true;
    },
    outCourseFun () {
      this.isMoveCourse = false;
    },
    doBigCwDetails () {
      if (this.bigCoursewareItem.ISAuthExpireKey == 1) {
        layer.alert('课件端口过期，无法加载课件，请联系课件授权方!');
      } else if (this.bigCoursewareItem.IsSuspendAuthKey == 1) {
        layer.alert('课件授权暂停，无法加载课件，请联系课件授权方!');
      } else {
        this.$emit('doBigCwDetails', this.bigCoursewareItem);
      }
    },
    // 授权设备
    doAuthorizeDevice () {
      var DeviceCode = ''; // 浏览器
      if (this.$zxClient.IsClient) {
        DeviceCode = this.$zxClient.GetMCode();
      }
      this.$dialog.open(this, {
        name: '申请设备授权',
        routerName: this.$route.name,
        routertitle: '工作台',
        ModulePowerKey: '',
        moduleName: 'cwsApplyAuth',
        data: {
          dataInfo: {
            CoursewareKey: this.bigCoursewareItem.OLAPKey,
            DeviceCode: DeviceCode
          }
        },
        callBack: {
          afterSuccess: (data) => { this.$emit('success'); }
        }
      });
    },
    // 授权老师
    doAuthorizeTeacher () {
      console.log('bigCoursewareItem', this.bigCoursewareItem);
      this.$dialog.open(this, {
        name: '授权老师',
        routerName: this.$route.name,
        routertitle: '工作台',
        ModulePowerKey: '',
        moduleName: 'cwsTeacherAuthCw',
        data: {
          dataInfo: {
            BigCoursewarePackageKey: this.bigCoursewareItem.OLAPKey,
            BigCoursewarePackageKeyValue: this.bigCoursewareItem.MainDemoName,
            TeacherKey: this.$store.getters.token.UserID,
            TeacherKeyValue: this.$store.getters.token.UserName
          }
        }
      });
    },
    doDeviceCount (type) {
      console.log(type, 'tttype');
      if (this.vDeviceCountClass) {
        let opneModuleName = this.vSaaSClientEditionKey ? 'authManagerList' : 'cwsAuthManagerList';
        if (this.bigCoursewareItem.CoursewarePackageKey) {
          this.$dialog.open(this, {
            name: '授权设备',
            routerName: this.$route.name,
            routertitle: '工作台',
            ModulePowerKey: '',
            moduleName: opneModuleName,
            data: {
              dataInfo: {
                CoursewarePackageKey: this.bigCoursewareItem.CoursewarePackageKey,
                CoursewarePackageKeyValue: this.bigCoursewareItem.CoursewarePackageKeyValue,
                type: type
              }
            }
          });
        } else {
          layer.alert('未获取信息，请刷新页面重进');
        }

      }
    }
  }
};
</script>
<style scoped>
.cw_details_img img {
  height: 112px;
  width: 172px;
  cursor: pointer;
  border: 2px solid rgb(187, 187, 187);
}
.cw_details_img img:hover {
  border: 2px solid rgba(255, 96, 0, 1);
}
.cw_authorization {
  width: 300px;
  height: 29px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  line-height: 29px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0px 0px 8px 8px;
}
.head_div_right {
  width: 590px;
  height: 168px;
  margin-left: 15px;
  position: relative;
}
.after_span {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  position: absolute;
  right: 27px;
  top: 1px;
  color: #999999;
  cursor: pointer;
}

.left_box_list {
  display: flex;
}
.right_box_list {
  width: 250px;
  height: 84px;
  float: right;
}
.left_box_list_span {
  display: block;
  height: 22px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  color: #000000;
  cursor: pointer;
}
.data_span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 439px;
  display: inline-block;
}
.left_box_list_span::after {
  content: '';
  background: url(../../../../../public/image/book_next@2x.png) no-repeat 50%;
  background-size: 12px;
  width: 30px;
  height: 12px;
  position: absolute;
  top: 5px;
}
.move_course_div::after {
  content: '';
  background: url(../../../../../public/image/book_next@2x_two.png) no-repeat
    50% !important;
  background-size: 14px !important;
  width: 30px !important;
  height: 14px !important;
}
.left_box_list {
  width: 40%;
  display: block;
}
.right_box_list {
  width: 70%;
  display: block;
}
.list_content {
  position: relative;
  font-size: 12px;
}
.list_btn {
  position: absolute;
  right: 0;
  top: 0;
}
.cw_details_list .btn_hover_bg_blue {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
  margin-top: 0px;
  border-radius: 4px;
  background: linear-gradient(225.5deg, #ff6000 50%, #ff8900), #000;
  border: 1px solid rgba(255, 96, 0, 1);
}
.cw_details_list .btn_hover_bg_blue:hover {
  background: linear-gradient(225.5deg, #ff6000, #ff8900), #000;
}
.device_auth_ok {
  width: 60px;
  height: 20px;
  /* background: rgba(209, 248, 230); */
  box-sizing: border-box;
  border-radius: 10px 10px 10px 1px;
  color: #1cce75;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 22px; */
  padding: 3px;
  font-weight: 400;
}
.device_auth_no {
  width: 60px;
  height: 20px;
  /* background: rgba(228, 62, 9, 0.1); */
  box-sizing: border-box;
  border-radius: 10px 10px 10px 1px;
  color: #ff6c67;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* line-height: 10px; */
  padding: 3px;
  font-weight: 400;
}
.head_div_right .font_black_click {
  color: #3498db !important;
  position: relative;
}
.head_div_right .font_black.font_blue_click:after {
  content: '';
  background: url(../../../../../public/image/book_next@2x.png) no-repeat 50%;
  background-size: 8px;
  width: 20px;
  height: 9px;
  position: absolute;
  top: 6px;
}
.teather_text {
  width: 100px;
  display: inline-block;
}
.device_control {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 35px;
  margin-left: 10px;
  align-items: center;
  width: 150px;
  color: #333333;
}
.device_control_list {
  box-sizing: border-box;
  width: 615px;
  height: 35px;
  background: #f9f9fb;
  border-radius: 4px;
  align-items: center;
  display: flex;
  flex-direction: row;
  /* text-align: center; */
  cursor: pointer;
  font-size: 12px;
}
.coursetips {
  position: absolute;
  left: 0;
  right: 0;
  top: -15px;
  height: 35px;
  background: #fffbe6;
  border: 1px solid #ffe694;
  border-radius: 2px;
  line-height: 35px;
  padding-left: 20px;
  color: #fea409;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
/* .vExpireKey {
  position: relative;
  padding-top: 12px;
} */
.jwTipsTop {
  top: -30px;
}
.left_both_list {
  display: flex;
  margin-top: 14px;
}

.support_span {
  border-radius: 2px;
  padding: 0 4px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}
.support_bj {
  width: 40px;
  height: 20px;
  position: absolute;
  background: rgba(217, 223, 228, 1);
  top: -4px;
  opacity: 0.4;
  border-radius: 2px;
}
.list_span {
  color: rgba(153, 153, 153, 1);
}
.device_div {
  position: absolute;
  bottom: 22px;
  display: flex;
  background: #f9f9fb;
  text-align: center;
  border-radius: 4px;
}
.device_auth {
  color: rgba(228, 62, 9, 1);
  font-weight: 400;
}
</style>