<template>
  <!--   -->
  <div class="appoint_popup_content"
       :style="{'top':vTop,'left':vLeft}"
       v-if="showBox"
       @mouseover.stop="keepShow"
       @mouseleave="closeShow"
       @click.stop="hideBox">
    <div v-for="(item,key) in optionList"
         :key="key"
         @click.stop="seletedItem(item)">{{item.name}}</div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isLoad: false,
      showBox: false,
      isShowMenu: false,
      // vTop: 300
      upTop: 300,
      vtop: 0,
      courseData: {},
      optionList: [
        { name: '查看课程', type: 1 },
        { name: '修改课程', type: 2 },
        { name: '删除', type: 3 },
      ],
      // 权限控制

      isShowNewCourseType: false
    };
  },
  props: {
    courseTableData: Object,
    vTop: String,
    vLeft: String,
    noBtn: Boolean,
    showContent: Boolean
  },
  components: {

  },
  created () {
    if (this.showBox) {
      this.initPopupDialogBoxStyle();
    }
    if (this.noBtn) {
      this.showBox = true;
    }
  },
  computed: {
    // 计算是否超过30天

  },
  watch: {
    showBox (n, o) {
      if (n) {
        this.initPopupDialogBoxStyle();
      } else {
        this.isShowMenu = false;
      }
    }
  },
  methods: {

    showGetData (OLAPKey) {
      this.showBox = true;

    },
    seletedItem (item) {
      console.log("seletedItem", item)
      this.$emit('seletedItem', item)
      // this.hideBox()
    },
    // 计算滚动条高度
    initPopupDialogBoxStyle () {
      let top = 0;
      if (this.showBox) {
        if (process.env.NODE_ENV !== 'development') {
          // console.log('this.width', this.width);
          // console.log('dialogHeight', dialogHeight);
          // console.log(
          //   "clientHeight",
          //   window.parent.document.getElementById("Form").clientHeight
          // );
          // console.log(
          //   "scrollTop",
          //   window.parent.document.getElementById("Form").scrollTop
          // );
          if (window.parent.document.getElementById('Form')) {
            top =
              window.parent.document.getElementById('Form').scrollTop - 100 <= 0
                ? Number(this.upTop)
                : window.parent.document.getElementById('Form').scrollTop -
                100 +
                Number(this.upTop);
          }
        } else {
          top = 300;
        }
        this.vtop = top + 'px';
      }
    },
    hideBox () {
      this.showBox = false;
      this.$emit('hideBox');
    },



    keepShow () {
      console.log('keepShow');
      this.$emit('keepShow', true);
    },
    closeShow () {
      this.$emit('closeShow');
    },
    boxShow () {
      this.$emit('keepShow', true);
    }
  }
};
</script>
<style>
.appoint_popup_content {
  position: absolute;
  z-index: 999;
  width: 130px;
  height: 112.78px;
  background: #474545;
  border-radius: 2px;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  color: white;
  line-height: 27px;
}
.appoint_popup_content > div:hover {
  cursor: pointer;
  opacity: 0.8;
}
</style>