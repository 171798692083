<template>
  <div @click.stop="hidePop">
    <student-lable ref="studentLableBox"
                   class="student_lable"
                   :studentID="selectedStudentInfo.OLAPKey"
                   @alterStudent="alterStudent"
                   :showMenu="true"></student-lable>
    <div class="single_shadow"
         style="overflow:hidden">
      <div class="student_courses_name">
        <div class="form-input form_info_required form_info_line form_info_edit no_border_bottom">
          <div class="student_courses_name_title">
            <span>报读课程</span>
          </div>
          <div @click="toCourseList"
               class="form_info_value form_info_select">
            <div class="form_info_value_input">
              <input type="text"
                     v-model="selectCourseInfo.MainDemoName"
                     placeholder="必选"
                     class="font_black"
                     disabled />
            </div>
          </div>
        </div>
      </div>
      <div class="student_courses_project">
        <div class="project_lable">
          <!-- <div class="project_lable_title">
            报课计划
            <div @click.stop="showCoursesProject" class="project_lable_title_menu"></div>
          </div> -->
          <div class="project_lable_content clearfix">
            <div v-show="courseBillsInfo.Sum_CurrentCount > 0 || courseBillsInfo.Sum_ArrangedCount > 0 || courseBillsInfo.Sum_ToBeArranged > 0"
                 @click="showCoursesProject"
                 class="project_lable_title_menu">
            </div>
            <div class="project_lable_list">
              <div class="font_gray"
                   style="margin-bottom:3px">当前报课</div>
              <div>
                <span class="font_number"
                      :class="courseBillsInfo.Sum_CurrentCount  < 0?'font_red':''">{{Number(courseBillsInfo.Sum_CurrentCount) || 0}}</span>
                <span class="font_gray">课时</span>
              </div>
            </div>
            <div class="project_lable_list">
              <div class="font_gray"
                   style="margin-bottom:3px">当前安排</div>
              <div>
                <span class="font_number"
                      :class="courseBillsInfo.Sum_ArrangedCount  < 0?'font_red':''">{{Number(courseBillsInfo.Sum_ArrangedCount) || 0}}</span>
                <span class="font_gray">课时</span>
              </div>
            </div>
            <div class="project_lable_list">
              <div class="font_gray"
                   style="margin-bottom:3px">等待安排</div>
              <div>
                <span class="font_number"
                      :class="courseBillsInfo.Sum_ToBeArranged  < 0?'font_red':''">{{Number(courseBillsInfo.Sum_ToBeArranged) || 0}}</span>
                <span class="font_gray">课时</span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <month-chart ref="monthChart"
                 class="single_shadow"
                 style="margin-top: 10px;"
                 :monthInfo="monthInfo"
                 @getMonthInfo="getMonthInfo"
                 @continueClick="continueClick"
                 @headerMenu="serachPopup"></month-chart>
    <div class="course_student_attendance_operation module_content_menuList"
         style="top:352px;left:-41px;"
         v-show="isShowAlerDialog">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="optionStatus"
                     @clickOptionForAlertDialog="doAfterClick"></drop-down-box>
    </div>
  </div>
</template>
<script>
import {
  getStudentRelatedMsg,
  GetTheStudentTicketPlanListByClassType,
  GetScheduleCourseListByClassType,
  courseAddStudent,
  SearchStudentHasArrangeSupport,
  kqDelete,
  GetTheClassType,
  IntegratedQueryCourse
} from '../../../API/workbench';
import studentLable from '../../common/student-lable';
import monthChart from '../../common/month-chart';
import dropDownBox from '../../common/drop-down-box';
export default {
  data () {
    return {
      isShowAlerDialog: false,
      AgentKey: '', // 当前登录人ID
      selectCourseInfo: {},
      courseBillsInfo: [], // 报课计划数据
      selectcourseBillsInfo: {}, // 选中报课计划
      monthInfo: [], // 月视图数据
      type: 0, // 搜索类型
      serachInfo: {}, // 搜索条件数据
      optionStatus: [
        {
          type: 0,
          name: '搜索全部',
          eventName: 'clear',
          isShow: true
        },
        {
          type: 1,
          name: '按老师搜索',
          eventName: 'teacher',
          isShow: true
        },
        {
          type: 2,
          name: '按班级搜索',
          eventName: 'class',
          isShow: true
        }
      ]
    };
  },
  props: ['selectedStudentInfo'],
  components: {
    monthChart,
    studentLable,
    dropDownBox
  },
  created () {
    let token = this.$store.getters.token;
    this.AgentKey = token.UserID;
  },
  mounted () { },
  watch: {
    selectCourseInfo (n, o) {
      this.getStudentCoursesProject();
    },
    serachInfo (n, o) {
      if (n && this.selectCourseInfo.OLAPKey) {
        this.getMonthInfo();
      }
    }
  },
  methods: {
    hidePop () {
      // this.$refs.studentCoursesArranging.isShowAlerDialog = false;
      if (this.isShowAlerDialog) {
        this.isShowAlerDialog = false;
      }
      // console.log(document.documentElement.getElementsByClassName('module_content_menuList_student ')[0],'000000000000000000')
      if (this.$refs.studentLableBox.showAlerDialogForStu) {
        this.$refs.studentLableBox.showAlerDialogForStu = false;
      }
    },
    close () {
      this.$parent.isStudentCoursesArranging = false;
    },
    hideTips () {
      this.$refs.monthChart.isTips = false;
    },
    toCourseList () {
      this.$emit('showCourseList');
    },
    doAfterClick (optionItem) {
      switch (optionItem.type) {
        case 0:
          this.restoreSearch();
          this.isShowAlerDialog = false;
          break;
        case 1:
          this.$emit('showTeacherList');
          this.isShowAlerDialog = false;
          break;
        case 2:
          this.$emit('showClassList');
          this.isShowAlerDialog = false;
          break;
        case 3:
          this.isShowAlerDialog = false;
          break;
        default:
          break;
      }
    },
    alterStudent (item) {
      this.$emit('alterStudent', item);
      this.$nextTick(() => {
        if (this.selectCourseInfo.OLAPKey) {
          this.getStudentCoursesProject();
        }
      });
    },
    serachPopup () {
      this.isShowAlerDialog = true;
      if (this.$refs.studentLableBox.showAlerDialogForStu) {
        this.$refs.studentLableBox.showAlerDialogForStu = false;
      }
      let that = this;

      document.onclick = function () {
        that.isShowAlerDialog = false;
      };
    },
    restoreSearch () {
      this.type = 0;
      this.serachInfo = {};
      this.getMonthInfo();
    },
    continueClick (item) {
      if (this.selectedStudentInfo.IsConfirmKey == 0) {
        layer.alert('该学生待验证确认，请先去学生档案中进行确认操作。');
        return;
      }
      if (item.StatusKey == 2) {
        if (item.alreadyJoin && this.selectedStudentInfo.OLAPKey > 0 && item.OLAPKey > 0) {
          kqDelete(this,this.selectedStudentInfo.OLAPKey, item.OLAPKey).then(
            res => {
              if (res.code == 0) {
                console.log(res, '取消课成功');
                // layer.alert("取消课成功");
                this.getStudentCoursesProject();
                this.$emit('cancelArrangeSuccess');
                this.$bus.emit('AfterArrangeForSingleStudent', 'cancel', item.OLAPKey, this.selectedStudentInfo.OLAPKey);
              }
            },
            err => {
              layer.alert(err.msg);
            }
          );
        } else {
          courseAddStudent(
            item.ClassTime,
            item.OLAPKey,
            this.selectedStudentInfo.OLAPKey,
            this.AgentKey,
            1,
            0
          ).then(
            res => {
              if (res.code == 0) {
                console.log(res, '安排课成功');
                // layer.alert("安排课成功");
                this.getStudentCoursesProject();
                this.$emit('arrangeStudentSuccess');
                this.$bus.emit('AfterArrangeForSingleStudent', 'arrange', item.OLAPKey, this.selectedStudentInfo.OLAPKey);
              }
            },
            err => {
              layer.alert(err.msg);
            }
          );
        }
      }
    },
    showCoursesProject () {
      this.$emit('showCoursesProject');
    },
    // 获取课信息
    getCourseInfo (id) {
      GetTheClassType(id).then(
        res => {
          if (res.code == 0) {
            res.data.id = res.data.OLAPKey;
            res.data.menuName = res.data.MainDemoName;
            this.selectCourseInfo = res.data;
          }
        },
        err => {
          layer.alert(err.msg);
        }
      );
    },
    // 获取学生信息
    getStudentInfo () {
      getStudentRelatedMsg(this.selectedStudentInfo.OLAPKey).then(
        res => {
          if (res.code == 0) {
            this.$parent.selectedStudentInfo = res.data;
          }
        },
        err => {
          layer.alert(err.msg);
        }
      );
    },
    // 获取报课计划
    getStudentCoursesProject () {
      GetTheStudentTicketPlanListByClassType(
        this.selectedStudentInfo.OLAPKey,
        this.selectCourseInfo.OLAPKey
      ).then(
        res => {
          if (res.code == 0) {
            this.courseBillsInfo = res.data;
          }
        },
        err => {
          layer.alert(err.msg);
        }
      );
      this.getMonthInfo();
    },
    // 获取指名课名的当月课程
    getMonthInfo () {
      if (!this.selectCourseInfo.OLAPKey) {
        return;
      }
      let firstDate = this.$refs.monthChart.vAllWeek[0][0].date;
      let lastDate = this.$refs.monthChart.vAllWeek[
        this.$refs.monthChart.vAllWeek.length - 1
      ][
        this.$refs.monthChart.vAllWeek[
          this.$refs.monthChart.vAllWeek.length - 1
        ].length - 1
      ].date;
      if (this.type == 1) {
        IntegratedQueryCourse(
          firstDate,
          lastDate,
          this.selectCourseInfo.OLAPKey,
          this.serachInfo.OLAPKey,
          ''
        ).then(
          res => {
            if (res.code == 0) {
              SearchStudentHasArrangeSupport(
                this.selectedStudentInfo.OLAPKey,
                this.selectCourseInfo.OLAPKey,
                firstDate,
                lastDate
              ).then(
                res2 => {
                  this.monthInfo = [];
                  console.log(res.data, res2.data);
                  res.data.forEach(e => {
                    res2.data.forEach(o => {
                      if (e.OLAPKey == o.OLAPKey) {
                        e.alreadyJoin = true;
                      }
                    });
                    this.monthInfo.push(e);
                  });
                },
                err2 => {
                  layer.alert(err2.msg);
                }
              );
            }
          },
          err => {
            layer.alert(err.msg);
          }
        );
      } else if (this.type == 2) {
        IntegratedQueryCourse(
          firstDate,
          lastDate,
          this.selectCourseInfo.OLAPKey,
          '',
          this.serachInfo.OLAPKey
        ).then(
          res => {
            if (res.code == 0) {
              SearchStudentHasArrangeSupport(
                this.selectedStudentInfo.OLAPKey,
                this.selectCourseInfo.OLAPKey,
                firstDate,
                lastDate
              ).then(
                res2 => {
                  this.monthInfo = [];
                  console.log(res.data, res2.data);
                  res.data.forEach(e => {
                    res2.data.forEach(o => {
                      if (e.OLAPKey == o.CourseId) {
                        e.alreadyJoin = true;
                      }
                    });
                    this.monthInfo.push(e);
                  });
                },
                err2 => {
                  layer.alert(err2.msg);
                }
              );
            }
          },
          err => {
            layer.alert(err.msg);
          }
        );
      } else {
        let obj = {
          startTime: firstDate,
          endTime: lastDate,
          classTypeKey: this.selectCourseInfo.OLAPKey
        };
        GetScheduleCourseListByClassType(
          obj
        ).then(
          res => {
            if (res.code == 0) {
              SearchStudentHasArrangeSupport(
                this.selectedStudentInfo.OLAPKey,
                this.selectCourseInfo.OLAPKey,
                firstDate,
                lastDate
              ).then(
                res2 => {
                  this.monthInfo = [];
                  console.log(res.data, res2.data);
                  res.data.forEach(e => {
                    res2.data.forEach(o => {
                      if (e.OLAPKey == o.CourseId) {
                        e.alreadyJoin = true;
                      }
                    });
                    this.monthInfo.push(e);
                  });
                },
                err2 => {
                  layer.alert(err2.msg);
                }
              );
            }
          },
          err => {
            layer.alert(err.msg);
          }
        );
      }
    }
  }
};
</script>