<template>
  <div class="student_coupon">
    <div class=" WKT_discount_coupon_ul"
         v-if="coupontPackagesList.length>0">
      <coupon-item v-for="(item,index) in coupontPackagesList"
                   :key="index"
                   :couponIndex="index"
                   :selectionKey="0"
                   :Item="item"
                   :dataList="coupontPackagesList"
                   :isBrowse="false"></coupon-item>
      <div class="no_list_text">已显示全部</div>
    </div>
    <basics-nodata-tips v-else></basics-nodata-tips>
  </div>
</template>

<script>
import couponItem from '../../../../wkt-back/coupon/coupon-list/coupon-item';
import { getCouponPackage } from '../../../../../API/workbench';
export default {
  components: {
    couponItem
  },
  data () {
    return {
      isShowOpt: true,
      coupontPackagesList: []
    };
  },
  props: {
    studentInfo: Object
  },
  created () {
    this.getMyCoupontPackages(this.studentInfo.CustKey);
  },
   watch:{
     'studentInfo.OLAPKey':{
      handler(c,o){
        if(c>0){
          console.log('watchstudentInfo.OLAPKey')
             this.getMyCoupontPackages(this.studentInfo.CustKey);
        }
      },
      deep:true
    }
  },
  computed: {

  },
  methods: {
    // 获取我的券包
    getMyCoupontPackages (id, mdID) {
      getCouponPackage(id, mdID).then(result => {
        console.log(result.data, '获取我的券包');
        this.coupontPackagesList = [];
        result.data.forEach(obj => {
          if (obj.status == 1) {
            if (Date.new(obj.time_start) > Date.new(this.$utils.formatDateToLine(Date.new())) || Date.new(obj.time_end) < Date.new(this.$utils.formatDateToLine(Date.new()))) { // 过期或者未开始 不可用
              this.$set(obj, 'unSelection', true);
            }
          } else {
            this.$set(obj, 'unSelection', true);
          }
        });
        this.coupontPackagesList = result.data;
      }, error => {
        layer.alert(error.msg);
      });
    },
    comeToBack () {
      const url = '/pages/student-center/index-div/index';
      this.$zxrouter.redirectTo({
        url: url
      });
    }
  }
};
</script>

<style>
.student_coupon .WKT_discount_coupon_ul {
  padding-top: 15px;
  max-height: 600px;
  overflow-y: auto;
}
.student_coupon .WKT_discount_coupon_ul .WKT_discount_coupon_list {
  margin: 15px;
}
.student_coupon
  .WKT_discount_coupon_ul
  .WKT_discount_coupon_list
  .WKT_discount_coupon_box {
  width: 380px;
}
</style>
