<template>
  <div class="paly_view">
    <video-editor v-if="dataInfo.AttachTypeKey == '2'"
                  :dataInfo="dataInfo"></video-editor>
    <audio-editor v-else-if="dataInfo.AttachTypeKey == '3'"
                  :dataInfo="dataInfo"></audio-editor>
  </div>
</template>
<script>
import audioEditor from '../courseware-edit/accessory-editor/audio-editor';
import videoEditor from '../courseware-edit/accessory-editor/video-editor';
export default {
  data () {
    return {
    };
  },
  components: {
    audioEditor,
    videoEditor
  },
  props: {
    dataInfo: Object
  },
  created () {
  },
  mounted () {

  },
  computed: {

  },
  methods: {
  }
};
</script>
<style scoped>
.paly_view {
  width: 100%;
  margin: auto;
  height: 562.5px;
}
.paly_view >>> .courseware_voice_progress {
  justify-content: center;
}
.paly_view >>> .courseware_voice_content_box {
  width: 100%;
}
.paly_view >>> .play_video {
  width: 100%;
  height: 492px;
  margin: 70px 60px;
}
</style>
