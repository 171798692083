<template>
  <div :id="echartID"
       class="echartbox"></div>
</template>

<script>
export default {
  data () {
    return {
      echart: null
    };
  },
  props: {
    echartID: String,
    dataList: Array
  },
  mounted () {
  },
  methods: {
    echartInit () {
      this.echart = echarts.init(document.getElementById(this.echartID));
      window.chartList.push(this.echart);
      // var option = {
      //   series: [
      //     {
      //       type: 'pie',
      //       radius: ['0%', '60%'],
      //       center: ['20%', '60%'],
      //       hoverAnimation: false,
      //       label: {
      //         show: false,
      //         position: 'inside',
      //         formatter: '{d}%',
      //         fontSize: 14
      //       },
      //       labelLine: {
      //         show: false
      //       },
      //       data: this.dataList
      //     }
      //   ],
      //   legend: {
      //     orient: 'horizontal',
      //     right: '35%',
      //     top: 100,
      //     width: 100,
      //     height: '100%',
      //     itemGap: 20,
      //     itemWidth: 20,
      //     itemHeight: 20,
      //     textStyle: {
      //       fontSize: 14,
      //       lineHeight: 20
      //     },
      //     selectedMode: false
      //   }
      // };
      var option = {
            // tooltip: {
            //   trigger: 'item'
            // },
            legend: {
              orient: 'horizontal',
              right: '35%',
              top: 100,
              width: 100,
              height: '100%',
              itemGap: 20,
              itemWidth: 20,
              itemHeight: 20,
              textStyle: {
                fontSize: 14,
                lineHeight: 20
              },
              selectedMode: false
            },
            series: [
              {
                name: '',
                type: 'pie',
                radius: ['40%', '70%'],
                center: ['20%', '60%'],
                avoidLabelOverlap: true,
                itemStyle: {
                  borderRadius: 10,
                  borderColor: '#fff',
                  borderWidth: 2
                },
                label: {
                  show: false,
                },
                emphasis: {
                  label: {
                    show: false,
                    fontSize: 40,
                    fontWeight: 'bold'
                  }
                },
                labelLine: {
                  show: false
                },
                data: this.dataList
              }
            ]
        };
      this.echart.setOption(option);
    }

  }
};
</script>