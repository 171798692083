<template>
  <!-- 销售 信息 -->
  <div class="form_info_edit ">
    <div class="">
      <div>
        <box-content-list :dataInfoList="vCommunityListForQuick"
                          :powerName="'abc'"
                          :hasActive="portInitSettings.activeInfo.canActive"
                          :formTitle='"周边的住宅区"'
                          :ModulePowerKey="45"
                          :showItemCount="showItemCount"
                          @onOpreate="doOpreateForCommunity"></box-content-list>
      </div>

      <div>
        <box-content-list :dataInfoList="vSellSourceListForQuick"
                          :powerName="'abc'"
                          :hasActive="portInitSettings.activeInfo.canActive"
                          :formTitle='"客户来源方式"'
                          :ModulePowerKey="45"
                          :showItemCount="showItemCount"
                          @onOpreate="doOpreateForSellSource"></box-content-list>
      </div>
    </div>
  </div>
</template> 

<script>
import boxContentList from './box-content-list';
import {
  MessageSearchPageForArea,
  MessageSearchPageForSourceType,
  StartArea, // 启用小区
  StopArea, // 停用小区
  StartSourceType, // 启用销售来源
  StopSourceType // 停用销售来源
} from '../../../../API/workbench';
export default {
  data () {
    return {
      showItemCount: (document.body.clientWidth >= 1890 && document.body.clientWidth <= 1930) ? 12 : (document.body.clientWidth >= 1570 && document.body.clientWidth <= 1890) ? 10 : 8,
      newInputText: '', // 默认值
      shwoPushItem: { push: '', isShowMainForm: false, formTitle: '', isReadonly: false, editItem: {} }, // 显示弹出层
      toSelectItem: '', // 选择项
      toSelectItemValue: '', // 选择项名称
      isShowMainForm: true, // 是否显示表单
      formTitle: '新增供应商设置', // 标题，
      readonlyInfo: false, // 是否只读

      communityList: [], // 小区列表
      sellSourceList: [], // 客户来源列表
      incidentalsList: [], // 学杂费列表

      // 查询小区
      xiaoqu: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称
        StateKey: 0 // 字符串	可选	0	数据源：GET	状态 2 启用 3停用
      },
      // 查询客户来源
      kefulaiyuan: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称
        StateKey: 0 // 字符串	可选	0	数据源：GET	状态 2 启用 3停用
      },
      // 查询学杂费
      xuezafei: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称
        StateKey: 0, // 字符串	可选	0	数据源：GET	状态 2 启用 3停用
        IncomePayTypeKey: '' // 	收支类型 1-收;2-支
      },
      portInitSettings: {
        lastRefreshTime: '',
        routerPath: '', // 通用操作表单路由
        activeInfo: {
          canActive: true,
          apiActive: '', // 启用接口
          apiUnActive: '', // 停用接口
          apiFinish: '', // 结束接口
          activeCallBack: (data) => {
            data.statusKey = 1; // 字段名不确定，需要确认 ToDo
            data.statusKeyValue = '启用';
          },
          unActiveCallBack: (data) => {
            data.statusKey = 0;// 字段名不确定，需要确认 ToDo
            data.statusKeyValue = '停用';
          },
          finishCallBack: (data) => {
            data.statusKey = 0;// 字段名不确定，需要确认 ToDo
            data.statusKeyValue = '停用';
          },
          otherOpreateHandler: null // 其它非通用操作处理方法
        }
      }
    };
  },

  components: {
    boxContentList
  },

  created () {
    this.$bus.on(this, 'switchHeaderBarToMendiansettingform', () => {
      this.loadData();
    });
    this.loadData();// 渲染列表数据
  },
  mounted () {
    // this.$dialog.register(this, ['communitySetting', 'sellSourceSetting']);
  },
  methods: {
    // 加载刷新列表数据
    loadData () {
      this.portInitSettings.lastRefreshTime = Date.new();
      this.upCommunityData();
      this.upSellSourceData();
    },
    // 外部刷新数据
    doCloseShowFormUpdata (boo, upDataName) {
      this.upLoadSettingData(upDataName);
    },
    upLoadSettingData (upDataName) {
      this.portInitSettings.lastRefreshTime = Date.new();
      switch (upDataName) {
        case 'communitySetting':// 查询小区
          this.upCommunityData();
          break;
        case 'sellSourceSetting': // 查询客户来源
          this.upSellSourceData();
          break;

        default:
          break;
      }
    },
    showModulesName (value) {
      switch (value) {
        case 'communitySetting':
          return '小区';
        case 'sellSourceSetting':
          return '客户来源';
        default:
          break;
      }
    },
    // 更新小区数据
    upCommunityData () {
      MessageSearchPageForArea(this.xiaoqu).then(result => {
        this.communityList = [];
        this.communityList = result.data.PageDataList;
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    // 更新客户来源数据
    upSellSourceData () {
      MessageSearchPageForSourceType(this.kefulaiyuan).then(result => {
        this.sellSourceList = [];
        this.sellSourceList = result.data.PageDataList;
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    // 执行小区相关操作
    doOpreateForCommunity (opreateType, dataKey, data, callBack) {
      this.portInitSettings.routerPath = 'communitySetting';
      this.portInitSettings.activeInfo.apiActive = StartArea;
      this.portInitSettings.activeInfo.apiUnActive = StopArea;

      let options = this.getOptions(this.portInitSettings, opreateType, dataKey, data, callBack);
      this.doOpreate(options);
    },
    // 执行客户来源相关操作
    doOpreateForSellSource (opreateType, dataKey, data, callBack) {
      this.portInitSettings.routerPath = 'sellSourceSetting';
      this.portInitSettings.activeInfo.apiActive = StartSourceType;
      this.portInitSettings.activeInfo.apiUnActive = StopSourceType;

      let options = this.getOptions(this.portInitSettings, opreateType, dataKey, data, callBack);
      this.doOpreate(options);
    },
    execAPIForOpreate (apiFN, dataKey, upDataName, callBack) {
      apiFN(dataKey).then(result => {
        this.upLoadSettingData(upDataName);
        callBack(result);
      }, error => {
        // console.log(error.msg, '接口');
        layer.alert(error.msg);
      });
    },
    // 通用操作执行
    doOpreate (options) {
      options = options || {
        lastRefreshTime: '',
        routerPath: '', // 通用操作表单路由
        activeInfo: {
          canActive: true,
          apiActive: null,
          activeCallBack: null, // 启用回调
          apiUnActive: null,
          unActiveCallBack: null, // 停用回调
          apiFinish: null,
          finishCallBack: null // 结束回调
        },
        opreateType: '', // add edit view active unActive
        dataKey: 0, // 数据Key，新增为0或null
        data: null, // 操作数据对象，新增为null
        callBack: null, // 回调，必有
        otherOpreateHandler: null // 其它非通用操作处理方法
      };

      options.dataKey = options.dataKey || 0;
      console.log(options, '操作执行');
      this.shwoPushItem.push = options.routerPath;
      switch (options.opreateType) {
        case 'add':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '新增' + this.showModulesName(options.routerPath),
            moduleName: options.routerPath,
            data: { editItem: {}, isReadonly: false },
            callBack: {
              afterSuccess: () => { this.upLoadSettingData(options.routerPath); }
            }
          });
          break;
        case 'edit':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '修改' + this.showModulesName(options.routerPath),
            moduleName: options.routerPath,
            data: { editItem: options.data, isReadonly: false },
            callBack: {
              afterSuccess: () => { this.upLoadSettingData(options.routerPath); }
            }
          });
          break;
        case 'view':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '查看' + this.showModulesName(options.routerPath),
            moduleName: options.routerPath,
            data: { editItem: options.data, isReadonly: true }
          });
          break;
        case 'active':
          if (options.activeInfo.canActive) {
            this.execAPIForOpreate(options.activeInfo.apiActive, options.dataKey, options.routerPath, resultData => {
              options.activeInfo.activeCallBack(options.data);
              options.callBack(options.data);
              options.lastRefreshTime = Date.new();
            });
          }
          break;
        case 'unActive':
          if (options.activeInfo.canActive) {
            this.execAPIForOpreate(options.activeInfo.apiUnActive, options.dataKey, options.routerPath, resultData => {
              options.activeInfo.unActiveCallBack(options.data);
              options.callBack(options.data);
              options.lastRefreshTime = Date.new();
            });
          }
          break;
        case 'finish':
          if (options.activeInfo.canActive) {
            this.execAPIForOpreate(options.activeInfo.apiFinish, options.dataKey, options.routerPath, resultData => {
              options.activeInfo.finishCallBack(options.data);
              options.callBack(options.data);
              options.lastRefreshTime = Date.new();
            });
          }
          break;
        default:// 超出标准操作，自行处理
          if (options.activeInfo.otherOpreateHandler) {
            options.otherOpreateHandler(options.opreateType, options.dataKey, options.data, options.callBack);
          }
          break;
      }
    },

    getOptions (settings, opreateType, dataKey, data, callBack) {
      let options = {
        opreateType: opreateType, // add edit view active unActive
        dataKey: dataKey, // 数据Key，新增为0或null
        data: data, // 操作数据对象，新增为null
        callBack: callBack // 回调，必有
      };
      // 合并参数设定
      return Object.assign(settings, options);
    }
  },
  computed: {
    vCommunityListForQuick () {
      // ToDo: 需要转换成通用组件需要的数据结构
      let list = [];
      if (this.portInitSettings.lastRefreshTime != '') {
        this.communityList.forEach(o => {
          let item = { dataKey: o.OLAPKey, itemName: o.MainDemoName, isActive: o.StateKey == 2, data: o, isMenu: false };
          list.push(item);
        });
      }
      return list;
    },
    vSellSourceListForQuick () {
      // ToDo: 需要转换成通用组件需要的数据结构
      let list = [];
      if (this.portInitSettings.lastRefreshTime != '') {
        this.sellSourceList.forEach(o => {
          let item = { dataKey: o.OLAPKey, itemName: o.MainDemoName, isActive: o.StateKey == 2, data: o, isMenu: false };
          list.push(item);
        });
      }
      return list;
    }
  }
};
</script>

  <style>
</style>