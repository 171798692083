<template>
  <div class=" table_list_content summarizing_list introduce_list"
       v-if="threadInfo.OLAPKey">
    <div class="single_result_detail">
      <div class="introduce_summarizing_left">
        <span class="font_gray">机构名称：</span>
        <span class="font_number">{{threadInfo.SaaSClientNameKeyValue}}</span>
        <div class="summarizing_number_right_btn">
          <div class=" el-button btn_light_blue btn_hover_bg_blue el-button--default"
               @click="addThreadClick">
            添加
          </div>
        </div>
      </div>
      <table-list ref="tableListRef"
                  :tableData="mendianList"
                  :totalNum="totalNum"
                  :tableColumns="tableColumns"
                  :isSearchText="false"
                  :rowKey="'Number'"
                  :footerContent="'共'+ totalNum + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  @loadTableData="getMessageSearchPageForSaaSClientReferral"></table-list>
    </div>
    <custom-dialog :title="'录入线索'"
                   width="640px"
                   :visible.sync="isShowAddThreadFormPopup"
                   :before-close="closeAddThreadFormPopup">
      <customer-thread :OLAPKey="''"
                       :source="{ type: 3, name: '转介绍', organizationName:threadInfo.SaaSClientNameKeyValue,organizationId:threadInfo.SaaSClientNameKey }"
                       @closeFrom="addCustomerUpData"></customer-thread>
    </custom-dialog>

    <custom-dialog :title="introduceInfo.CashAuditKey > 0 ? '返款中' : '返介绍费'"
                   :visible.sync="isShowReturnExpensesPopup"
                   :before-close="closeReturnExpensesPopup">
      <return-introduce-fee :threadId="introduceInfo.OLAPKey"
                            :CashAuditKey="introduceInfo.CashAuditKey"
                            @afterSuccess="afterSuccessIntroduce"
                            @close="afterSuccessIntroduce"></return-introduce-fee>
    </custom-dialog>

  </div>
</template>
<script>
import { MessageSearchPageForSaaSClientReferral } from '../../../../API/workbench.js';
import tableList from '../../../common/table-list/index';
import returnIntroduceFee from '../components/form-components/return-introduce-fee';
import customerThread from '../components/form-components/customer-thread';
export default {
  components: {
    tableList,
    customerThread,
    returnIntroduceFee
  },
  props: {
    threadInfo: Object // 线索信息
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: 'desc', // 排序字段
        SaaSClientNameKey: '' //	数据源：GET	 门店id
      },
      mendianList: [], // 门店列表
      introduceInfo: {}, // 介绍门店信息
      isShowAddThreadFormPopup: false, // 线索表单
      isShowReturnExpensesPopup: false, // 返介绍表单
      isShowbeBeingReturnExpensesPopup: false,
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          type: 'text-item',
          className: 'font_gray',
          decimalPlace: 0
        },
        {
          label: '意向',
          prop: 'IntentionKeyValue',
          width: 70,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: 'custom'
        },
        {
          label: '机构名称',
          prop: 'SaaSClientNameKeyValue',
          width: 200,
          type: 'sell-documentary-table-row',
          sortable: false
        },
        {
          label: '联系人',
          prop: 'ContactsName',
          width: 200,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '介绍人',
          prop: 'IntroduceKeyValue',
          width: 200,
          align: 'left',
          type: 'text-item',
          sortable: false
        },

        {
          label: '状态',
          prop: 'StatusKeyValue',
          minWidth: 120,
          align: 'left',
          type: 'text-item',
          sortable: false,
          extend: {
            setClassName: (rowData) => { // 状态 1-未成交;2-已成交;3-已返款
              if (rowData.StatusKey == 1) {
                return 'status_spot_red';
              } else if (rowData.StatusKey == 2 || rowData.StatusKey == 3) {
                return 'status_spot_green';
              } else {
                return '';
              }
            }
          }
        },
        {
          label: '操作',
          prop: 'CashAuditKeyValue',
          width: 100,
          type: 'text-item',
          extend: {
            click: (rowData) => {
              this.doReturnExpensesOperate(rowData);
            }
          }
        }
      ],
      totalNum: 0,
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  created () {
  },
  mounted () {
    this.getMessageSearchPageForSaaSClientReferral();
  },
  computed: {

  },
  methods: {
    // 转介绍列表
    getMessageSearchPageForSaaSClientReferral (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.queryParams.SaaSClientNameKey = this.threadInfo.SaaSClientNameKey;
      MessageSearchPageForSaaSClientReferral(this.queryParams).then(result => {
        console.log('转介绍列表', result.data);
        this.isShowAddThreadFormPopup = false;
        this.totalNum = result.data.Total;
        // 分页数
        if (result.data && result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach(o => {
            o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
            o.ContactsName = o.ContactsName + this.$utils.phoneModulePower(o.MobileSupport);
            o.IntroduceKeyValue = o.IntroduceKeyValue + o.IntroduceMobileSupport;
            if (o.StatusKey == 2) {
              o.CashAuditKeyValue = o.CashAuditKey > 0 ? '返款中' : '返介绍费';
            }
          });
          this.mendianList = result.data.PageDataList;
        } else {
          this.mendianList = [];
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    addThreadClick () {
      this.isShowAddThreadFormPopup = true;
    },
    closeAddThreadFormPopup () {
      this.isShowAddThreadFormPopup = false;
    },
    // 操作按钮-返介绍费
    doReturnExpensesOperate (item) {
      console.log(item, '操作按钮');
      this.introduceInfo = item;
      this.isShowReturnExpensesPopup = true;
    },
    // 录入线索后刷新
    addCustomerUpData () {
      this.getMessageSearchPageForSaaSClientReferral();
      // this.$emit('loadTableData');
    },
    // 返款/返介绍
    afterSuccessIntroduce (date) {
      if (date) {
        this.getMessageSearchPageForSaaSClientReferral();
        // this.$emit('loadTableData');
      }
      this.closeReturnExpensesPopup();
    },
    closeReturnExpensesPopup () {
      this.isShowReturnExpensesPopup = false;
    },
    // 操作按钮-返款中
    beBeingReturnExpensesOperate (item) {
      console.log(item, '操作按钮');
      this.isShowbeBeingReturnExpensesPopup = true;
    },
    closeBeingReturnExpensesPopup () {
      this.isShowbeBeingReturnExpensesPopup = false;
    }
  }
};
</script>
