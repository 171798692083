<template>
  <!-- 连锁总部工作台 -->
  <div class="index_view all_width small_width">
    <!-- <button @click.stop="goRouter">签约表单</button> -->
    <div class="index_view_left box_withe_radius"
         style="margin-left: 25px;">
      <div class="chain_workbench_teacher_info pr">

        <div class="teacher_info_img">
          <staff-heads-img-upload :ImgURl="staffInfo.XMLIcon"
                                  :staffInfo="staffInfo"
                                  :formTitle="'员工头像:'"
                                  :imgHeight="300"
                                  :imgWidth="300"
                                  @doSetPicCorp="doSetPicCorpHeadImg"></staff-heads-img-upload>
          <!-- <img class="img_box"
               src="../../../public/image/face_screenage_noImg.png"
               alt=""> -->
        </div>
        <div class="head_shadow_img"></div>
        <div class="teacher_info_name">
          {{staffInfo.MainDemoName}}
        </div>
        <div class="teacher_info_text">
          {{staffInfo.DepartmentKeyValue||''}} {{staffInfo.PostKeyValue?'|':''}} {{staffInfo.PostKeyValue}}

        </div>
        <div class="list_btn"
             @click.stop="toeditPassWord"></div>
      </div>
      <warning-list @updateData="updateData"></warning-list>
      <todo-list @updateData="updateData"></todo-list>
    </div>
    <right-content ref="rightContent"
                   :addClewAfter="updateData"
                   :menDianSetInfo="menDianSetInfo"
                   @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"
                   @openCommonCustomDialogByFactoryDialog="openCommonCustomDialogByFactoryDialog"></right-content>
    <div>
      <dialog-factory ref="chainWorkBenchDialogFactory"
                      :key="'chainWorkBench'"
                      :routerName="'chainWorkBench'"></dialog-factory>
      <dialog-factory-common ref="chainWorkBenchDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import dialogFactory from '../common/dialog-factory';
import todoList from './todo-list/index';
import warningList from './warning-list/index';
import rightContent from './right-content';
import { GetTheEmployeeByID, GetSaaSClientSet } from '../../API/workbench';
export default {
  data () {
    return {
      token: {},
      menDianSetInfo: [],
      staffInfo: {},
      seletedDate: Date.new().toLocaleDateString()
    };
  },
  components: {
    todoList,
    warningList,
    dialogFactory,
    rightContent
  },
  computed: {
    // 头像
    headImg () {
      if (!this.staffInfo.XMLIcon) {
        this.staffInfo.XMLIcon = '';
      }
      if (this.staffInfo.XMLIcon.indexOf('http') > -1) {
        return this.staffInfo.XMLIcon;
      } else {
        return this.$store.getters.CDNURL + this.staffInfo.XMLIcon;
      }
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' +
        require('../../../public/image/deflut_teacher_photo@2x.png') +
        '"'
      );
    }
  },
  created () {
  },
  mounted () {
    this.token = this.$store.getters.token;
    this.loadData();
    this.getMenDianSet();
    this.registerBusEvent();
    this.$bus.on(this, 'updateMendianSet', (data) => {
      console.log('updateMendianSet');
      this.getMenDianSet();
    });
  },
  methods: {
    goRouter (item) {
      // 排课 按钮
      this.$dialog.open(this, {
        name: '付款申请', // 新增传付款申请， 审核传付款审核
        routerName: this.$route.name,
        moduleName: 'crmPaymentForm',
        data: { OLAPKey: 9, isReadonly: false, paymentType: 'add' }, // OLAPKey 投资线索ID， isReadonly是否只读，paymentType为add时为新增，other时为审核，审核会显示收支账户收支科目
        callBack: { afterSucces: () => { } }
      });
    },
    // 完善信息
    toeditPassWord () {
      let item = {
        routerName: this.$route.name,
        moduleName: 'editPassWord',
        name: '修改密码',
        callBack: {
          afterSuccess: (data) => {
            // this.$emit('afterSuccess', data);
          }
        }
      };
      this.$dialog.open(this, item);
    },
    updateData () {
      if (this.$refs.rightContent) {

        this.$refs.rightContent.loadData();
      }
    },
    loadData () {
      this.getStaffInfo(this.token.UserID);
    },
    // 获取门店设置
    getMenDianSet () {
      GetSaaSClientSet().then((result) => {
        console.log('GetSaaSClientSet', result.data);
        this.menDianSetInfo = result.data;
        this.$nextTick((o) => {
          if (this.$refs.rightContent) {

            this.$refs.rightContent.loadData();
          }
        });
      });
    },
    // 获取员工信息.
    getStaffInfo (OLAPKey, callback) {
      GetTheEmployeeByID(OLAPKey)
        .then((result) => {
          this.staffInfo = result.data;
          if (callback) {
            callback();
          }
          console.log('getStaffInfo', result.data);
        })
        .catch((error) => {
          console.log('getStaffInfo', error);
        });
    },
    // 更新头像.
    doSetPicCorpHeadImg (ImgURl) {
      this.staffInfo.XMLIcon = ImgURl;
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCustomDialog', dialogInfo);
      if (this.$refs.chainWorkBenchDialogFactory) {
        this.$refs.chainWorkBenchDialogFactory.openDialog(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title,
          'HQ'
        );
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.chainWorkBenchDialogFactoryCommon) {
        this.$refs.chainWorkBenchDialogFactoryCommon.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callBack,
          dialogInfo.name || dialogInfo.title,
          'HQ'
        );
      }
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'chainWorkBench') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'chainWorkBench') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openOther () {
      let moduleInfo = {
        name: '连锁类型',
        moduleName: 'chainType',
        routerName: this.$route.name,
        data: {
          afterSuccess: () => {
            this.getMenDianSet();
          }
        }
      };
      this.openCustomDialogByFactoryDialog(moduleInfo);
    }
  }
};
</script>
<style>
.index_view {
  display: flex;
}

.index_view_left {
  height: 952px;
  width: 356px;
  margin-right: 20px;
}
.box_withe_radius {
  background-color: #fff;
  border-radius: 8px;
}
.all_width {
  width: 1290px;
  margin: 20px auto 40px;
}
.small_width {
  width: 1290px;
  margin: 20px auto 40px;
}
.index_view_menu {
  height: 159px;
  width: 914px;
  margin-bottom: 20px;
}

.index_view_mytype {
  min-height: 273px;
  width: 914px;
  margin-bottom: 20px;
}
.index_view_menu {
  padding: 0 24px;
}

.index_view_mytype {
  padding: 0 24px;
}

.index_view .index_title {
  font-size: 18px;
  line-height: 60px;
  border: none !important;
}

.index_view_menu .menu_content {
  display: flex;
  /* margin-top: 10px; */
}

.index_view_menu .menu_list {
  width: 160px;
  height: 58px;
  padding-left: 13px;
  display: flex;
  align-items: center;
  align-content: center;
  /* border: 1px solid #EDEFF2; */
  margin-right: 20px;
  cursor: pointer;
}
.index_view_menu .menu_list:hover {
  border: 1px solid red;
}

.index_view_menu .menu_list .list_img {
  width: 34px;
  height: 34px;
  margin-right: 14px;
  border-radius: 50%;
}

.index_view_menu .menu_list.type_gray {
  background-color: #fafafa;
  color: #c2c6cd;
  cursor: default;
}

.index_view_mytype .mytype_content {
  display: flex;
  flex-wrap: wrap;
  /* padding-right: 242px; */
  margin-top: -22px;
}

.index_view_mytype .mytype_content_list {
  width: 293px;
  height: 114px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  align-content: center;
  /* cursor: pointer; */
}
.index_view_mytype .mytype_content_list.button {
  cursor: pointer;
}
.index_view_mytype .mytype_content_list.button:hover {
  border: 1px solid red;
}
.index_view_mytype .mytype_content_list .list_img {
  width: 34px;
  height: 34px;
  margin-right: 20px;
}

.index_view_mytype .mytype_content_list .list_number {
  font-size: 20px;
  line-height: 23px;
  color: #4a5f8a;
}

.index_view_mytype .mytype_content_list .list_number.font_red {
  color: #ff6c67;
}

.index_view_mytype .mytype_content_list .list_name {
  font-size: 14px;
  line-height: 19px;
  color: #666666;
}
.index_view_mytype .mytype_content_list.button:hover .list_name {
  color: #3498db !important;
}
.menu_content .menu_content_list {
  width: 185px;
  height: 80px;
  padding: 10px;
}
.menu_content .menu_content_list:hover {
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 16px 24px rgba(255, 108, 103, 0.1),
    0px 2px 6px rgba(255, 108, 103, 0.3), 0px 0px 1px rgba(255, 108, 103, 0.2);
  border-radius: 4px;
  cursor: pointer;
}
.menu_content .menu_content_list .menu_content_list_title {
  color: #666666;
  font-size: 14px;
  /* font-weight: 400; */
  text-align: center;
  margin-top: 8px;
}
.menu_content .menu_content_list .menu_content_list_num {
  /* color: #3498db; */
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  text-align: center;
  /* margin-top: 8px; */
}
.menu_content .menu_content_list::after {
  /* content: "";
  width: 1px;
  height: 43px;
  background: #ccc;
  position: absolute;
  top: 21px;
  right: 0; */
}
.menu_content .menu_content_list:last-child::after {
  display: none;
}
.index_view_menu .index_menu_button {
  width: 112px;
  height: 23px;
  position: absolute;
  right: -1px;
  top: 25px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 4px;
}
.index_view_menu .index_menu_button .index_menu_button_num {
  position: absolute;
  color: #ececec;
  background: #ff6c67;
  font-size: 12px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  line-height: 19px;
  right: -7px;
  top: -5px;
  margin: auto;
}
.index_view_menu_three {
  width: 291px !important;
  height: 154px !important;
  margin-bottom: 20px;
  margin-right: 20px;
}
.index_view_menu_three .menu_content .menu_content_list::after {
  display: none;
}
.index_view_menu_three_content {
}
.menu_content_list_num.font_gray {
  color: #666 !important;
}

.head_shadow_img {
  width: 356px;
  height: 192px;
  position: absolute;
  z-index: 1;
  top: 10px;
  opacity: 0.5;
  background: url(../../../public/image/photo_shadow.png) no-repeat center 100px;
}
.chain_workbench_teacher_info .list_btn {
  z-index: 2;
}
</style>
