<template>
  <!-- 物品项  -->
  <div @mouseleave.stop="mouseOutNewCourseTable"
       class="manage_block">
    <div class="manage_content_block"
         :class="[nameItem.StyleName?nameItem.StyleName+'_color':'']"
         @mousedown.stop="onCourseNameMousedown"
         @mouseenter.stop="mouseOverNewCourseTable"
         @onmouseup.stop="onCourseNameMouseup"
         v-if="nameItem.MainDemoName">
      <el-tooltip popper-class="mendian_block_tooltip"
                  effect="light"
                  visible-arrow="false"
                  :content="nameItem.MainDemoName"
                  :disabled="disabled"
                  placement="top">
        <div :style="vIsEmptiness?'opacity: 0.2;':'opacity: 1;'">
          <span ref="nameBox">{{nameItem.MainDemoName}}</span>
        </div>
      </el-tooltip>
    </div>
  </div>
</template> 

<script>

import dropDownBox from '../../../../common/drop-down-box';
export default {
  data () {
    return {
      disabled: false,
      isMousedown: false
    };
  },
  props: {
    nameItem: Object,
    isDrag: Boolean,
    index: Number
  },
  components: {
    dropDownBox
  },

  created () {
    console.log(this.nameItem, 'this.nameItem');
  },
  watch: {

  },
  mounted () {
    if (this.$refs.nameBox) {
      this.disabled = this.$refs.nameBox.offsetWidth < (this.$refs.nameBox.parentElement.parentElement.offsetWidth - 16);
    }
  },
  activated () {

  },
  computed: {
    // 拖拽时选择该项,降低透明度
    vIsEmptiness () {
      console.log(this.isDrag, 'this.isDrag');
      if (this.nameItem.isEmptiness && this.isDrag) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    // 鼠标移入
    mouseOverNewCourseTable () {
      if (!this.isDrag) {
        if (this.isMousedown == false) {
          // clearTimeout(this.timer);
          this.nameItem.isMenu = true;
          this.$emit('doMouseEvent', 1, this.index, this.nameItem);
        }
      }
    },
    // 鼠标离开
    mouseOutNewCourseTable () {
      if (!this.isDrag) {
        // this.timer = setTimeout(() => {
        this.isMousedown = false;
        this.nameItem.isMenu = false;
        this.$emit('doMouseEvent', 0, this.index, this.nameItem);
        // }, 100);
      }
    },
    // 鼠标按下
    onCourseNameMousedown () {
      console.log('鼠标按下');
      this.isMousedown = true;
      this.nameItem.isMenu = false;
      this.nameItem.isEmptiness = true;
    },
    // 鼠标松开
    onCourseNameMouseup () {
      console.log('鼠标松开');
      this.isMousedown = false;
      this.nameItem.isMenu = false;
    }
  }

};
</script>

  <style>
/*  */
</style>