<!-- 排课备注 -->
<template>
  <el-table-column :fixed="columnData.fixed||false"
                   :label="columnData.label"
                   :prop="columnData.prop"
                   :width="columnData.width||352"
                   :sortable="columnData.sortable"
                   :align="columnData.align||'left'"
                   :label-class-name="columnData.columnClass"
                   :key="columnData.prop">
    <template slot-scope="scope">
      <div v-if="scope.row[columnData.prop]">
        <div :class="[vClassName,columnData.extend&&columnData.extend.isEdit?'edit_icon':'']"
             class="sell_support_info"
             v-if="!scope.row.isEmpertyRow"
             @click="clickEvent(scope.row)">
          <div class="sell_support_info_rank pa"
               :class="vPurposeName(scope.row)">
            <span>{{scope.row.PurposeKeyName}}</span>
            <!-- <span :class="vPurposeName(scope.row)">{{scope.row.PurposeName}}</span> -->
          </div>
          <div class="flex">
            <div class="sell_support_info_name">
              {{scope.row.AgentKeyValue}}
              <span style="margin:0 4px">|</span>
              {{vStartTimeFrom(scope.row)}}
            </div>
            <div class="sell_support_info_date">
              {{vAgoTime(scope.row)}}
            </div>
            <!-- <div class="sell_support_info_date">
              {{scope.row.FollowRecordsSupport}}
            </div> -->
          </div>
          <div class="sell_support_info_text">
            {{scope.row[columnData.prop]}}
          </div>
        </div>
      </div>
      <div v-else>
        <span :class="[vClassName,columnData.extend&&columnData.extend.isEdit?'edit_icon':'']"
              style="color:#999"
              v-if="!scope.row.isEmpertyRow"
              @click="clickEvent(scope.row)"></span>
      </div>
    </template>
  </el-table-column>
</template>

<script>
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          type: String,
          extend: {
            isEdit: Boolean,
            click: () => { } // 表示该列可点击
          }
        };
      }
    }
  },
  computed: {

    vClassName () {
      let className = '';
      if (this.columnData.extend && this.columnData.extend.className) {
        className = this.columnData.extend.className;
      }
      return className;
    }
  },
  created () {
  },
  methods: {
    vPurposeName (rowData) {
      let className = '';
      // 客户意愿 S-极高 A-较高 B-一般 C-较低
      if (rowData.PurposeKeyName) {
        if (rowData.PurposeKeyName == 'S') {
          className = 'purpose_black';
        } else if (rowData.PurposeKeyName == 'A') {
          className = 'purpose_brown';
        } else if (rowData.PurposeKeyName == 'B') {
          className = 'purpose_yellow';
        } else if (rowData.PurposeKeyName == 'C') {
          className = 'purpose_gray';
        }
      }
      return className;
    },
    clickEvent (rowData) {
      if (this.columnData.extend && typeof this.columnData.extend.click == 'function') {
        // 普通点击事件 直接执行回调
        this.columnData.extend.click(rowData);
      }
    },
    vAgoTime (item) {
      if (item.StartTimeFrom) {
        return this.$utils.StrForTodoTime(item.StartTimeFrom);
      }
    },
    vStartTimeFrom (item) {
      if (item.StartTimeFrom) {
        return item.StartTimeFrom.substring(0, 10);
      }
    }
  }
};
</script>

<style>
</style>