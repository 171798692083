<template>
	<div>
		<!-- 音频块 -->
		<div v-if="vAudioList">
			<audio-view v-for="(item,key) in vAudioList" :key="key" :item="item"></audio-view>
		</div>
		<div class="article_details_img_ul">
			<!-- 视频块 -->
			<video-view v-for="(item,index) in vVideoList" :key="index" :videoClass="'img_list'" :videoInfo="item"></video-view>
			<!-- 图片块 -->
			<image-view v-for="(item,index1)  in vImgList" :key="index1+1" :imageUrl="item.AttachUrl" :ImgList="vImgList" :content="content"></image-view>
		</div>
	</div>
</template>

<script>
import audioView from '../../components/attach-box/audio-view.vue';
import videoView from '../../components/attach-box/video-view.vue';
import imageView from '../../components/attach-box/image-view.vue';
export default {
  components: {
    audioView,
    videoView,
    imageView
  },
  data () {
    return {

    };
  },
  props: {
    AttachList: Array, // 附件列表
    content: String // 内容
  },
  computed: {
    // 音频列表
    vAudioList () {
      let list = [];
      if (this.AttachList && this.AttachList.length > 0) {
        list = this.AttachList.filter(obj => {
          return obj.AttachTypeKey == 3;
        });
      }
      return list;
    },
    // 图片列表
    vImgList () {
      console.log(this.AttachList, '图片');
      let list = [];
      list = this.AttachList.filter(obj => {
        return obj.AttachTypeKey == 1;
      });
      return list;
    },
    // 视频
    vVideoList () {
      console.log(this.AttachList, '视频');
      let list = [];
      list = this.AttachList.filter(obj => {
        return obj.AttachTypeKey == 2;
      });
      return list;
    }
  },
  methods: {

  }
};
</script>

<style>

</style>
