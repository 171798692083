<template>
  <!-- 课件分发 -->
  <div class="course_dispense_view all_view">
    <!-- 循环课周视图（左侧）-->
    <div class="course_dispense_left">
      <!-- 头部 -->
      <table-head ref="tableHead"
                  :selectCourseList="selectCourseList"
                  @doSelectedClassOrCourseItem="doSelectedClassOrCourseItem"
                  @doSelectedDistributeType="doSelectedDistributeType"
                  @doSelectedDateType="doSelectedDateType"></table-head>
      <!-- 内容 -->
      <div v-if="loading"
           style="margin-top: 200px;"
           class="students_loading">
        <span class="el-icon-loading"></span>
        <span class="loading_style">加载中...</span>
      </div>
      <div v-else-if="!loading">
        <table-content :dataList="vCoursewareDistributeRuleList"
                       :list="coursewareDistributeRuleList"
                       @clickAfterItem="clickAfterItem"></table-content>
      </div>
    </div>
    <!-- 分发列表（右侧） -->
    <div>
      <table-selected-list :selectCourseList="selectCourseList"
                           :coursewareDistributeRuleList="coursewareDistributeRuleList"
                           @cancelSelect="cancelSelect"
                           @showAllocationCourseware="showAllocationCourseware"></table-selected-list>
    </div>
    <!-- 分发课件 -->
    <div>
      <allocation-courseware v-if="isShowAllocationCourseware"
                             :loopCourseList="selectCourseList"
                             @complete="completeDistribute"
                             @cancelPopup="cancelAllocationCourseware"></allocation-courseware>
    </div>
    <div>
      <dialog-factory ref="studentApplyCourseReportDialogFactory"
                      :routerName="'studentManagementReport'"></dialog-factory>
    </div>
  </div>
</template>

<script>
import { CoursewareDistributeSearchStructureRule } from '../../../API/workbench.js';
import tableHead from './table-head/index';
import tableContent from './table-content/index';
import tableSelectedList from './table-selected-list/index';
import dialogFactory from '../../common/dialog-factory';
import allocationCourseware from './allocation-courseware/index';
export default {
  name: 'distributeCoursewareTable',
  components: {
    tableHead,
    tableContent,
    tableSelectedList,
    dialogFactory,
    allocationCourseware
  },
  data () {
    return {
      parameter: {
        isDistribute: 0, //	整型	可选	是否分发 1：已分发 2：未分发 0：全部
        EndDate: '', //	字符串 可选 截止日期 0：全部，1：下周，2：本月，3：下个月
        GradeClassKey: 0, //	整型	可选	班级ID
        CourseNameKey: 0, //	整型	可选	课ID
        isChg: false
      },
      dataList: [],
      loading: true,
      coursewareDistributeRuleList: [], // 课件分发列表
      selectCourseList: [], // 选中的分发列表
      parseSelectCourseList: [],
      nowSelectData: null, // 当前选择的课程项
      isShowAllocationCourseware: false
    };
  },
  props: {

  },
  created () {
    this.registerBusEvent();
  },
  activated () {
    this.getCoursewareDistributeSearchStructureRule();
    this.$refs.tableHead.loadData();
    console.log(this.selectCourseList, this.searchType, '负责收集选中的分发结果');
  },
  computed: {
    vMendianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vlits () {
      let list = [];
      list = this.coursewareDistributeRuleList;
      return list;
    },
    // 课件分发分组列表
    vCoursewareDistributeRuleList () {
      if (!this.$utils.getSaaSClientSetInfo(64).SetContent) {
        return [{
          timeRange: '', // 时间
          lengthwaysList: [], // 纵向 时间段
          crosswiseList: [] // 横向 周
        }];
      }
      // 时间段数组
      let timeSlotArr = [];
      for (let i = 0; i < 24; i++) {
        let time = i < 10 ? '0' + i : i;
        timeSlotArr.push(time);
      }
      // 返回小时时间段重组成新的数组
      let resDataTimeSlot = [];
      let startTime = this.$utils.getSaaSClientSetInfo(64).SetContent.split(':')[0];// 门店开始时间
      let endTime = this.$utils.getSaaSClientSetInfo(65).SetContent.split(':')[0];// 门店结束时间
      for (let x = 0; x < timeSlotArr.length; x++) {
        if (Number(timeSlotArr[x]) >= Number(startTime) && Number(timeSlotArr[x]) <= Number(endTime)) {
          resDataTimeSlot.push({
            timeRange: timeSlotArr[x],
            lengthwaysList: [], // 纵向 时间段
            crosswiseList: [// 横向 周
              { titleName: '周一', key: 1, data: [] },
              { titleName: '周二', key: 2, data: [] },
              { titleName: '周三', key: 3, data: [] },
              { titleName: '周四', key: 4, data: [] },
              { titleName: '周五', key: 5, data: [] },
              { titleName: '周六', key: 6, data: [] },
              { titleName: '周日', key: 7, data: [] }
            ]
          });
        }
      }
      // 循环时间段
      resDataTimeSlot.forEach(o => {
        this.vlits.forEach(item => {
          if (Number(o.timeRange) === Number(item.StartTime.split(':')[0])) {
            // 添加时间段数据
            o.lengthwaysList.push(item);
            // // 根据时间段 添加周的数据
            o.crosswiseList.forEach(v => {
              if (v.titleName === item.WeekKeyValue) {
                v.data.push(item);
              }
            });
          }
        });
      });
      console.log('最后的数组resDataTimeSlot', resDataTimeSlot);
      return resDataTimeSlot;
    }

  },
  methods: {
    cancelAllocationCourseware () {
      this.isShowAllocationCourseware = false;
    },
    // 分发课件
    showAllocationCourseware () {
      console.log('分发课件');
      this.isShowAllocationCourseware = true;
    },
    // 完成分发后刷新页面
    completeDistribute () {
      this.selectCourseList = [];// 选中后的分发列表
      this.nowSelectData = null;// 当前选择的课程项
      this.getCoursewareDistributeSearchStructureRule();
    },
    // 课件分发-规律课列表
    getCoursewareDistributeSearchStructureRule (list) {
      CoursewareDistributeSearchStructureRule().then(result => {
        console.log(result.data, this.nowSelectData, '课件分发-规律课列表');
        this.dataList = result.data;
        this.dataList.forEach(obj => {
          this.$set(obj, 'isOpt', false);// 高亮
          this.$set(obj, 'isSelected', false); // 选中
          this.$set(obj, 'isGray', false); // 置灰
          this.$set(obj, 'isLink', false);  // 链条
          if (this.selectCourseList.length > 0) {
            this.selectCourseList.forEach(o => {
              if (obj.ID == o.ID) {
                this.$set(obj, 'isOpt', o.isOpt);// 选中
                obj.dateTime = Date.new().getTime();
              }
            });
            this.showItemTable(obj);
          }
        });
        this.coursewareDistributeRuleList = this.$utils.parseJson(this.dataList);
        this.showLinkListSet();
        if (this.selectCourseList.length > 0) {
          this.search(true);
        } else {
          this.search();
        }
        this.loading = false;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 根据分发类型过滤
    doSelectedDistributeType (item) {
      console.log(item, '根据分发类型过滤');
      this.parameter.isDistribute = item.key;
      this.cancelSelect(); ;
    },
    // 根据已分发类型-日期过滤（0：全部，1：下周，2：本月，3：下个月）
    doSelectedDateType (item) {
      this.parameter.EndDate = item.key;
      this.cancelSelect();
    },
    // 根据班级或者课程过滤
    doSelectedClassOrCourseItem (item, type) {
      if (type == 0) { // 全部
        this.parameter.GradeClassKey = 0;//	班级ID
        this.parameter.CourseNameKey = 0;//	课ID
      } else if (type == 1) { // 班级
        this.parameter.GradeClassKey = item.OLAPKey;//	班级ID
      } else if (type == 2) { // 课程
        this.parameter.CourseNameKey = item.OLAPKey;//	课程ID
      }
      this.cancelSelect();
    },
    // 取消选中
    cancelSelect () {
      this.selectCourseList = [];// 选中后的分发列表
      this.nowSelectData = null;// 当前选择的课程项
      this.search();
    },
    search (type) {
      if (this.parameter.isDistribute == 0) { // 全部
        this.coursewareDistributeRuleList = this.dataList;
      } else if (this.parameter.isDistribute == 1) { // 已分发
        if (this.parameter.EndDate == 1) { // 下周
          this.getNextWeekData();
        } else if (this.parameter.EndDate == 2) { // 本月
          this.getMonthData();
        } else if (this.parameter.EndDate == 3) { // 下个月
          this.getNextMonthData();
        } else { // 全部
          this.coursewareDistributeRuleList = this.dataList.filter(obj => { return obj.LastClassTime; });
        }
      } else if (this.parameter.isDistribute == 2) { // 2：未分发
        this.coursewareDistributeRuleList = this.dataList.filter(obj => { return !obj.LastClassTime; });
      }
      if (Number(this.parameter.GradeClassKey) > 0) { // 班级
        this.coursewareDistributeRuleList = this.coursewareDistributeRuleList.filter(obj => { return obj.GradeClassKey == this.parameter.GradeClassKey; });
      } else if (Number(this.parameter.CourseNameKey) > 0) { // 课程
        this.coursewareDistributeRuleList = this.coursewareDistributeRuleList.filter(obj => { return obj.CourseNameKey == this.parameter.CourseNameKey; });
      }
      if (!type) {
        this.initializeData();
      }
      console.log(this.coursewareDistributeRuleList, '筛选后的数据');
    },
    // 下周的数据
    getNextWeekData () {
      let seletedDate = Date.new();
      var day = seletedDate.getDay() == 0 ? 7 - 1 : seletedDate.getDay() - 1;
      let startTime = this.$utils.getNextDate(seletedDate, -day + 7);
      let endTime = this.$utils.getNextDate(Date.new(startTime), 6);

      this.coursewareDistributeRuleList = this.dataList.filter(obj => {
        return obj.LastClassTime &&
          Date.new(obj.LastClassTime).getTime() >= Date.new(startTime).getTime() &&
          Date.new(obj.LastClassTime).getTime() <= Date.new(endTime).getTime();
      });
      console.log(startTime, endTime, '下周');
    },
    // 本月
    getMonthData () {
      let startTime = this.$utils.getCurrentMonthFirst();
      let endTime = this.$utils.getCurrentMonthLast();
      this.coursewareDistributeRuleList = this.dataList.filter(obj => {
        return obj.LastClassTime &&
          Date.new(obj.LastClassTime).getTime() >= Date.new(startTime).getTime() &&
          Date.new(obj.LastClassTime).getTime() <= Date.new(endTime).getTime();
      });
      console.log(startTime, endTime, '本月');
    },
    // 下个月
    getNextMonthData () {
      let startTime = this.$utils.getNextMonth(this.$utils.getCurrentMonthFirst());
      let endTime = this.$utils.getNextMonthFinallyDay(this.$utils.getCurrentMonthLast());
      this.coursewareDistributeRuleList = this.dataList.filter(obj => {
        return obj.LastClassTime &&
          Date.new(obj.LastClassTime).getTime() >= Date.new(startTime).getTime() &&
          Date.new(obj.LastClassTime).getTime() <= Date.new(endTime).getTime();
      });
      console.log(startTime, endTime, '下个月');
    },
    // 点击表格里的某一项触发
    clickAfterItem (item) {
      item.dateTime = Date.new().getTime();
      // // 切换
      if (this.nowSelectData &&
        (this.nowSelectData.GradeClassKey != item.GradeClassKey ||
          this.nowSelectData.CourseNameKey != item.CourseNameKey) &&
        !this.parameter.isChg) {
        layer.confirm('与当前已选择的循环课的班级与课程不同，请问是否切换?', {
          btn: [{
            name: '确认',
            callBack: () => {
              this.parameter.isChg = true;
              this.selectCourseList = [];// 选中后的分发列表
              this.nowSelectData = null;// 当前选择的课程项
              this.initializeData();// 重新初始化一遍，防止状态未消除
              this.$set(item, 'isOpt', !item.isOpt);
              this.showTable(item);
              layer.close();
            }
          }
          ]
        });
        return;
      }
      this.$set(item, 'isOpt', !item.isOpt);  // 选中
      console.log(this.nowSelectData, this.coursewareDistributeRuleList, '选中后');
      if (!this.nowSelectData || (
        this.nowSelectData.GradeClassKey != item.GradeClassKey &&
        this.nowSelectData.CourseNameKey != item.CourseNameKey)) {
        this.showTable(item);
      } else {
        this.gatherCourseInfo(this.coursewareDistributeRuleList); // 选中后的分发列表
      }
    },
    // 负责数据状态显示
    showTable (item) {
      console.log(item, '负责状态显示');
      this.nowSelectData = item;
      this.parameter.isChg = false;
      this.coursewareDistributeRuleList.forEach(o => {
        this.$set(o, 'isSelected', false); // 高亮
        this.$set(o, 'isGray', false); // 置灰
        this.showItemTable(o);
      });
      this.showLinkListSet();
      this.gatherCourseInfo(this.coursewareDistributeRuleList);
    },
    showItemTable (item) {
      if (this.nowSelectData.isOpt) {
        if (this.nowSelectData.GradeClassKey == item.GradeClassKey && this.nowSelectData.CourseNameKey == item.CourseNameKey) {
          this.$set(item, 'isSelected', true); // 高亮
          // 链条
          if (this.nowSelectData.CDID && item.CDID == this.nowSelectData.CDID) {
            this.$set(item, 'isLink', true);
          }
        } else {
          this.$set(item, 'isGray', true); // 置灰 （选中后，不在同一批次的全部置灰显示）
        }
      }
    },
    // 链条只有一个时，不显示
    showLinkListSet () {
      let showLinkList = this.dataList.filter(obj => { return obj.isLink; });
      if (showLinkList.length == 1) {
        this.coursewareDistributeRuleList.forEach(obj => {
          this.$set(obj, 'isLink', false);  // 链条
        });
      }
    },
    // 负责初始化数据
    initializeData () {
      this.coursewareDistributeRuleList.forEach(item => {
        this.$set(item, 'isOpt', false);// 高亮
        this.$set(item, 'isSelected', false); // 选中
        this.$set(item, 'isGray', false); // 置灰
        this.$set(item, 'isLink', false);  // 链条
      });
      console.log(this.coursewareDistributeRuleList, '负责初始化数据');
    },
    // 负责收集选中的分发结果
    gatherCourseInfo (coursewareDistributeRuleList) {
      this.selectCourseList = coursewareDistributeRuleList.filter(obj => { return obj.isOpt && obj.dateTime > 0; });
      this.selectCourseList.sort((x, y) => { return x.dateTime - y.dateTime; });
      console.log(this.selectCourseList, coursewareDistributeRuleList, '选中后的分发列表');
      // 无数据时，重新初始化一次
      if (this.selectCourseList.length == 0) {
        this.nowSelectData = null;// 当前选择的课程项
        this.search();
      }
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'studentManagementReport') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.studentApplyCourseReportDialogFactory) {
        this.$refs.studentApplyCourseReportDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    }
  }
};
</script>

<style>
</style>