<template>
  <div class="big_courseware_details pr"
       style="flex-direction: column;">
    <div style="display: flex;flex-direction: row;width: 100%;padding: 20px;">
      <div class="campusimg">
        <img :src="headImg"
             :onerror="defaultImg"
             width="100%">
      </div>
      <div style="display: flex;flex-direction: column;margin-left: 15px;margin-right: 15px;margin-top: 3px;">
        <div class="campusName">{{data.MainDemoName}} <img style="cursor: pointer;margin-bottom: 8px"
               src="../../../../../public/image/edit_icon.png"
               @click="openForm"
               v-if="this.data.TypeKey 
               != '3'" /></div>
        <div class="typeName">{{data.TypeKeyValue}}</div>
      </div>
      <div style="display: flex;flex-direction: column;">
        <div style="display: flex;flex-direction: row;margin-top: 5px;">
          <div class="SaaSClientNO"><span class="noText">ID</span> <span>{{ data.SaaSClientNO }}</span></div>
          <div class="SaaSClientNO"><span><img style="    width: 20px;
    height: 20px;
    margin-bottom: 2px;"
                   src="../../../../../public/image/stu_dh.png" /></span> <span>{{ data.MobilePhone }}</span></div>
          <div class="SaaSClientNO"><span class="noText">端口</span> <span>{{ data.UsedDay+'/'+data.TotalDay+'天' }}</span> <span class="xuduankou"
                  @click="renew">续端口</span></div>
        </div>
        <div class="SaaSClientNO">地址：{{ data.ProvinceKeyValue+data.CityKeyValue+data.AreaKeyValue+data.Address }} <span :class="data.IsLocation==1?'location':'nolocation'">{{ data.IsLocation== 1?'已定位':'未定位'}}</span></div>
      </div>
    </div>
    <div style="display: flex;flex-direction: row;padding-left: 30px;">
      <div class="choosecourse"
           :class="choose?'optionItem':''"
           @click="()=>{this.choose=!this.choose}">授权课件</div>
      <div class="choosecourse"
           :class="choose?'':'optionItem'"
           @click="()=>{this.choose=!this.choose}"
           style="margin-left: 18px;">授权记录</div>
    </div>
    <div class="content">
      <courserwareContent :ClientKey="ClientKey"
                          v-if="choose"></courserwareContent>
      <div v-else
           style="margin: 20px">
        <authorization-record :ClientKey="Number(ClientKey)"></authorization-record>
      </div>

    </div>
    <custom-dialog title="修改直营校区"
                   :visible.sync="isShowParticipateCampusFormPopup"
                   :before-close="closeParticipateCampusFormPopup">
      <retail-campus-edit-form :CampusKey="Number(data.OLAPKey)"
                               :isReadonly="false"
                               :titleName="'修改'"
                               @close="closeParticipateCampusFormPopup"
                               @afterSuccess="getDataDetails"></retail-campus-edit-form>
    </custom-dialog>
    <custom-dialog :title="'修改加盟校区'"
                   :visible.sync="isShowFranchiseeParticipateCampusFormPopup"
                   :before-close="closeParticipateCampusFormPopup">
      <franchisee-campus-edit-form :CampusKey="Number(data.OLAPKey)"
                                   :isShowInput="true"
                                   :titleName="'修改'"
                                   :isReadonly="false"
                                   @close="closeParticipateCampusFormPopup"
                                   @afterSuccess="getDataDetails"></franchisee-campus-edit-form>
    </custom-dialog>
    <custom-dialog :title="'修改课程合作校区'"
                   :visible.sync="isShowCampusFormPopup"
                   :before-close="closeParticipateCampusFormPopup">
      <retailCampusEditForms :CampusKey="Number(data.OLAPKey)"
                             :isShowInput="false"
                             :isReadonly="false"
                             :noShowJW="true"
                             @close="closeParticipateCampusFormPopup"
                             @afterSuccess="getDataDetails"></retailCampusEditForms>
    </custom-dialog>
  </div>
</template>

<script>
import { GethqCampusDetails } from '../../../../API/workbench.js';
import courserwareContent from './courseware-content.vue';
import authorizationRecord from './authorization-record.vue';
import retailCampusEditForm from '../../headquarters-management/retail-management/form/retail-campus-edit-form';
import franchiseeCampusEditForm from '../../headquarters-management/franchisee-management/form/franchisee-campus-edit-form';
import retailCampusEditForms from '../../headquarters-management/campus-management/form/retail-campus-edit-form.vue';
export default {
  data () {
    return {
      data: {},
      choose: true,
      isShowParticipateCampusFormPopup: false,
      isShowCampusFormPopup: false,
      isShowFranchiseeParticipateCampusFormPopup: false

    };
  },
  components: {
    courserwareContent,
    authorizationRecord,
    retailCampusEditForm,
    franchiseeCampusEditForm,
    retailCampusEditForms
  },
  props: {
    ClientKey: {
      type: [Number, String],
      default: 0
    },
    rowData: Object
  },
  computed: {
    headImg () {
      if (this.data && this.data.LogoPath) {
        if (this.data.LogoPath.indexOf('http') > -1) {
          return this.data.LogoPath;
        } else {
          return this.$store.getters.CDNURL + this.data.LogoPath;
        }
      } else {
        return '';
      }
    },
    defaultImg () {
      return (
        'this.src="' +
        require('../../../../../public/image/no_logo@2x.png') +
        '"'
      );
    }
  },
  created () {
  },

  mounted () {
    this.getDataDetails();
  },

  methods: {
    getDataDetails () {
      GethqCampusDetails(this.ClientKey).then(res => {
        console.log('校区详情res', res.data);
        this.data = res.data;
      });
    },
    renew () {
      this.$emit('renewClick', this.rowData);
    },
    closeParticipateCampusFormPopup () {
      this.isShowParticipateCampusFormPopup = false;
      this.isShowCampusFormPopup = false;
      this.isShowFranchiseeParticipateCampusFormPopup = false;
    },
    openForm () {
      if (this.data.TypeKey == '1') {
        this.isShowParticipateCampusFormPopup = true;
      } else if (this.data.TypeKey == '2') {
        this.isShowFranchiseeParticipateCampusFormPopup = true;
      } else if (this.data.TypeKey == '3') {
        this.isShowCampusFormPopup = true;
      }
    }

  }
};
</script>

<style scoped>
.big_courseware_details {
  height: 850px;
  display: flex;
  background: #ececec;
}
.campusimg {
  width: 50px;
  background: #fff;
  height: 50px;
  margin-top: 5px;
  margin-left: 10px;
}
.campusName {
  font-size: 20px;
  font-weight: 700;
}
.typeName {
  width: 60px;
  text-align: center;
  background: #ccc;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  margin-top: 5px;
}
.SaaSClientNO {
  line-height: 25px;
  margin-right: 10px;
}
.noText {
  background: #888888;
  color: #fff;
  padding: 0px 3px;
  font-size: 12px;
}
.xuduankou {
  cursor: pointer;
  color: #3498db;
  font-size: 12px;
}
.nolocation {
  background: #facd91;
  color: #895a05;
  padding: 1px 8px;
  border-radius: 6px;
  font-size: 12px;
}
.location {
  background: #a3f51d;
  color: #666;
  padding: 1px 8px;
  border-radius: 6px;
  font-size: 12px;
}
.choosecourse {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0px;
  cursor: pointer;
}
.optionItem {
  border-bottom: 5px solid #17bba4;
  font-weight: 600;
}
.content {
  flex: 1;
  background: #fff;
}
</style>
