<template>
  <div class="swiper-slide">
    <div class="view_photo_img">
      <div class="view_photo_img_flex"
           :id="'view_photo_img_flex_'+index">
        <img :id="'img_box_'+index"
             class="preview_img_box"
             :src="vImgUrl"
             alt=""
             crossOrigin="anonymous">
      </div>
    </div>
    <!-- <div class="view_photo_text"
         v-if="imgInfo.text">
      <div class="text_box">
        {{imgInfo.text}}
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    imgInfo: {
      type: Object,
      default: function () {
        return {
          imgUrl: '',
          text: ''
        };
      }
    },
    index: Number
  },
  computed: {
    vImgUrl () {
      console.log('预览item,', this.imgInfo);
      let showImgUrl = this.$store.getters.CDNURL + this.imgInfo.imgUrl;// img.php
      if (this.imgInfo.imgUrl.indexOf('http') != -1) {
        showImgUrl = this.imgInfo.imgUrl;
      } else if (this.imgInfo.imgUrl.indexOf('data:image/') > -1) {
        showImgUrl = this.imgInfo.imgUrl;
      }
      return showImgUrl;
    }
  }
};
</script>

<style>
</style>