<template>
  <div class="bg_white">
    <div class="signuppayment_stundent_lable_banner lable_big">
      <div class="banner_info flex">
        <div class="banner_info_left">
          <div class="banner_info_phone">
            {{dataInfo.SaaSClientNameKeyValue}}
          </div>
          <div v-if="dataInfo.IntentionKeyValue"
               class="client_support_rank">
            <div class="sell_support_info_rank"
                 :class="vPurposeClass">
              <!-- 客户意愿 -->
              <span>
                {{dataInfo.IntentionKeyValue}}
              </span>
            </div>
            <div class="client_support_rank_text"
                 :class="vPurposeClass">
              {{vPurposeName}}
            </div>
          </div>
        </div>

      </div>

      <div class="banner_lable">
        <div class="banner_lable_top flex">
          <span class="banner_lable_name">
            <span v-if="dataInfo.MarketFollowRecode">{{this.$utils.formatDate(dataInfo.MarketFollowRecode.FollowTime)}}</span>
          </span>
          <span class="banner_lable_top_btn"
                @click.stop="showRecord">
            查看全部{{recordListLength}}条
          </span>
        </div>
        <div class="banner_lable_text"
             v-if="dataInfo.MarketFollowRecode">
          {{dataInfo.MarketFollowRecode.ContentScript}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  EditArrangeSupport

} from '../../../../../../API/workbench.js';
// import studentList from '../../../../../../common/student-list';
export default {
  data () {
    return {
      showHeadsImg: true,         // 是否显示头像
      isShowStudentList: false,   // 切换学生显示
      studentInfo: {}
    };
  },
  components: {
    // studentList
  },
  props: {
    dataInfo: Object,
    readonly: {
      type: Boolean,
      default: false
    },
    styleInfo: {
      type: String,
      default: ''
    },
    CustStatusKey: { // 学生块的学生列表是否只显示在校学生1为是，0为否（可选）
      type: [Number, String],
      default: ''
    },
    dropDownBoxLeft: {
      type: [Number, String],
      default: ''
    },
    // 该学生是否回收1-是;0-否
    recordListLength: {
      type: Number,
      default: 0
    }
  },
  computed: {
    vPurposeClass () {
      let className = '';
      // 客户意愿 S-极高 A-较高 B-一般 C-较低
      console.log('vPurposeClass', this.dataInfo);
      if (this.dataInfo.IntentionKeyValue) {
        if (this.dataInfo.IntentionKeyValue == 'S') {
          className = 'purpose_black';
        } else if (this.dataInfo.IntentionKeyValue == 'A') {
          className = 'purpose_brown';
        } else if (this.dataInfo.IntentionKeyValue == 'B') {
          className = 'purpose_yellow';
        } else if (this.dataInfo.IntentionKeyValue == 'C') {
          className = 'purpose_gray';
        }
      }
      return className;
    },
    vPurposeName () {
      let className = '';
      // 客户意愿 S-极高 A-较高 B-一般 C-较低
      console.log('vPurposeClass', this.dataInfo);
      if (this.dataInfo.IntentionKeyValue) {
        if (this.dataInfo.IntentionKeyValue == 'S') {
          className = '极高';
        } else if (this.dataInfo.IntentionKeyValue == 'A') {
          className = '较高';
        } else if (this.dataInfo.IntentionKeyValue == 'B') {
          className = '一般';
        } else if (this.dataInfo.IntentionKeyValue == 'C') {
          className = '较低';
        }
      }
      return className;
    },
    vStartTimeFrom () {
      return this.dataInfo.StartTimeFrom.substring(0, 10);
    }
  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {

    showPatriarchCourse () {
      this.isPatriarchCourse = true;
    },
    arrangeShowText () {
      this.isShowArrangeText = true;
      this.isArrangeTextPa = false;
    },
    submitStudentArrangeSupport () {
      EditArrangeSupport(this.studentInfo.OLAPKey, this.alterMessageText).then(
        res => {
          this.studentInfo.ArrangeSupport = this.alterMessageText;
          this.$nextTick(() => {
            if (this.$refs.patriarchCourseUl && this.$refs.patriarchCourseUl.offsetWidth >= (this.$refs.studentLabelPatriarch.offsetWidth * 0.5)) {
              this.isPatriarchCourseText = true;
            } else {
              this.isPatriarchCourseText = false;
            }
          });
        },
        err => {
          layer.alert(err.msg);
        }
      );
    },
    closeStudentList () {
      this.isShowStudentList = false;
    },
    // 切换学生
    doAfterSelectedStudent (item) {
      console.log(item, '切换学生');
      // this.refreshArrangeText();
      this.loadStudentDetailInfo(item.OLAPKey);
      this.$emit('alterStudent', item);
      this.isShowStudentList = false;
    },

    showRecord () {
      this.$emit('showRecord');
    }
  }
};
</script>