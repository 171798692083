<template>
  <div  class="credit_management_popup">
    <div class="popup_title_padding view_title_flex">
      <div class="popup_title_data_box">
        <div class="data_list">
          <span>兑换学生(人)</span>
          <span class="font_number">{{tableData.StudentCount}}</span>
        </div>
        <div class="data_list">
          <span>兑换物品(个)</span>
          <span class="font_number">{{Number(tableData.ExchangeCount)}}</span>
        </div>
        <div class="data_list">
          <span>抵用学分</span>
          <span class="font_number">{{Number(tableData.TotalUseCreditCount)}}</span>
        </div>
      </div>
      <input-search-contain-btn
        v-model="searchObj.searchText"
        :placeholder="'请输入姓名、姓名首字母、手机号'"
        @clearSearch="clearSearchCondition"
        @onSearchEvent="search"
      ></input-search-contain-btn>
    </div>
    <div class="credit_management_table">
      <table-list
        class="summarizing_list table_list_content"
        :tableData="vdataReportList"
        :tableColumns="tableCourseColumns"
        :totalNum="totalNum"
        :queryParams="searchObj"
        :footerContent="vFooterTotal"
        @loadTableData="getDataList"
      ></table-list>
    </div>
  </div>
</template>
<script>
import { MessageSearchPageForExchangeRecord } from "../../../../API/workbench";
import inputSearchContainBtn from "../../common/input-search-contain-btn";
import tableList from "../../../common/table-list/index";
export default {
  data() {
    return {
      dataReportList: [],
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: "",
        orderBy: "",
        sequence: "DESC",
        startTime: "",
        endTime: "",
      },
      tableData:{
        ExchangeCount:0,
        TotalUseCreditCount:0,
        StudentCount:0
      },
      totalNum: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: "序号",
          prop: "Number",
          width: 68,
          sortable: false,
          align: "left",
          type: "tableIndex",
          className: "table_index_gray",
        },
        {
          label: "办理日期",
          prop: "EditTime",
          width: 156,
          sortable: false,
          align: "left",
          className: "",
          type: "textItem",
        },
        {
          label: "学生名",
          prop: "StudentKeyValue",
          width: 156,
          sortable: false,
          align: "left",
          type: "textItem",
        },
        {
          label: "手机号",
          prop: "CustomerPhoneName",
          width: 156,
          sortable: false,
          align: "left",
          type: "textItem",
        },
        {
          label: "兑换物品",
          prop: "GoodsKeyValue",
          width: 156,
          sortable: false,
          type: "textItem",
        },
        {
          label: "数量",
          prop: "QuantityCount",
          width: 156,
          sortable: false,
          align: "left",
          type: "numberItem",
        },
        {
          label: "抵用学分",
          prop: "UseCreditCount",
          width: 156,
          sortable: false,
          align: "left",
          type: "numberItem",
        },
        {
          label: "经办人",
          prop: "OperatorKeyValue",
          width: 156,
          sortable: false,
          align: "left",
          type: "textItem",
        },
      ],
    };
  },
  components: {
    inputSearchContainBtn,
    tableList,
  },
  computed: {
    vdataReportList() {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        o.CustomerPhoneName = this.$utils.phoneModulePower(o.CustomerPhoneName) || '--';
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal() {
      let text = "共" + this.totalNum + "条数据";
      return text;
    },
  },
  created() {
    this.getDataList();
  },
  methods: {
    clearSearchCondition() {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = "";
      this.getDataList();
    },
    search() {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    getDataList() {
      MessageSearchPageForExchangeRecord(this.searchObj).then(
        (res) => {
          this.totalNum = res.data.Total;
          this.dataReportList = res.data.PageDataList;
          this.tableData.StudentCount = res.data.StudentCount || 0;
          this.tableData.ExchangeCount = res.data.ExchangeCount || 0;
          this.tableData.TotalUseCreditCount = res.data.TotalUseCreditCount || 0;
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
  },
};
</script>