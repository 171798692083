<template>
  <div class="">
    <div v-if="dataList.length!=0"
         class="attendance_course_date_ul">
      <div class="attendance_course_date_list">
        <div class="attendance_course_info_ul">
          <abnormal-item v-for="(item,key) in dataList"
                         :key="key"
                         :item="item"></abnormal-item>
        </div>
      </div>

    </div>
    <basics-nodata-tips v-if="dataList.length==0"></basics-nodata-tips>
  </div>

</template>

<script>
import abnormalItem from './abnormal-item.vue';
import { GetXYKDClassHourseForUnusual } from '../../../../../../API/workbench';
export default {
  data () {
    return {
      dataList: []
    };
  },
  components: {
    abnormalItem
  },
  props: {
    searchObj: Object
  },
  created () {
    if (this.searchObj.TableID > 0) {
      this.getDataList();
    }
  },
  computed: {},
  methods: {
    getDataList () {
      GetXYKDClassHourseForUnusual(this.searchObj.TableID).then(result => {
        console.log('GetXYKDClassHourseForUnusual', result.data);
        this.dataList = result.data;
      });
    }
  }
};
</script>

<style>
</style>
