<template>
  <div>
    <div class="pr">
      <input-shortcutStart-setting-dialog :formTitle="formTitle"
                                          :formName="formName"
                                          :formPlaceholder="' '"
                                          :customPlaceholder="true"
                                          :readonly="false"
                                          :dataInfoList="dataInfoList"
                                          :ModulePowerKey="ModulePowerKey"
                                          :isAddBut="addButName?true:false"
                                          :isSwitchShowBox="isSwitchShowBox"
                                          @showEldilog="newAdd"
                                          @formTitleNameClick="doFormTitleNameClickShow"
                                          v-model="newInputText"
                                          class="padding_LR_10 shortcut_margin_Left"
                                          :style="vHasData ?'border-bottom:0px':''"></input-shortcutStart-setting-dialog>
      <div class="mendian_input_data_right pa"
           :class="vHasModulePower(ModulePowerKey)?'':'norole'"
           style="right: 38px"
           v-if="addButName">{{addButName}}</div>
    </div>
  </div>
</template> 

<script>
import inputShortcutStartSettingDialog from './input-shortcutStart-setting-dialog';
import detailedFunctionDialog from '../mendian-setting-from/detailed-function-dialog';
import boxContentItem from './box-content-item';
export default {
  data () {
    return {
      newInputText: '',
      newDataInfoList: [],
      menuItemList: [], // Ex. [{menuName:'查看',opreateType:'add'}]
      isShowOpreateMenu: false, // 操作菜单是否显示
      dataItemForOpreate: null, // 正在操作的数据项
      isSwitchShowBox: true, // 用于切换  默认为true
      isShowAllStart: false, // 是否显示全部（启用项）
      isShowAllStop: false // 是否显示全部（停用项）
    };
  },
  props: [
    'formTitle',
    'formName',
    'dataInfoList',
    'powerName',
    'hasActive',
    'isComplement',
    'ModulePowerKey',
    'showItemCount',
    'addButName'
  ],
  computed: {
    vHasData () {
      if (this.formName != 'DRXS' && this.formName == 'JZZC' && this.formName != 'JKPK' && this.dataInfoList.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    vDataInfoList () {
      let arr = this.dataInfoList;

      return arr;
    },
    vStartDataInfoList () {
      let arr = [];
      this.dataInfoList.forEach(o => {
        if ((this.isSwitchShowBox || o.dataKey) && o.isActive) {
          arr.push(o);
        }
      });
      if (!this.isComplement) {
        let len = this.dataInfoList.length - 1;
        let num = 0;
        num = 2;
        if (len > 0) {
          let lack = len % num;
          for (let i = 1; i < num - lack; i++) {
            arr.push({});
          }
        }
      }
      return arr;
    },
    // 二次包装，用于启用项切换显示全部或隐藏
    vShowStartDataInfoList () {
      if (this.isShowAllStart || this.vStartDataInfoList.length <= this.showItemCount) {
        return this.vStartDataInfoList;
      } else {
        return this.vStartDataInfoList.slice(0, this.showItemCount);
      }
    },
    vStopDataInfoList () {
      let arr = [];
      this.dataInfoList.forEach(o => {
        if (o.dataKey && !o.isActive) {
          arr.push(o);
        }
      });
      if (!this.isComplement) {
        let len = this.dataInfoList.length - 1;
        let num = 0;
        num = 3;
        if (len > 0) {
          let lack = len % num;
          for (let i = 1; i < num - lack; i++) {
            arr.push({});
          }
        }
      }
      return arr;
    },
    // 二次包装，用于启用项切换显示全部或隐藏
    vShowStopDataInfoList () {
      if (this.isShowAllStop || this.vStopDataInfoList.length <= this.showItemCount) {
        return this.vStopDataInfoList;
      } else {
        return this.vStopDataInfoList.slice(0, this.showItemCount);
      }
    }
  },
  components: {
    boxContentItem,
    detailedFunctionDialog,
    inputShortcutStartSettingDialog
  },
  created () { },
  methods: {
    // 是否显示全部启用项
    doShowStartDataAllClick () {
      this.isShowAllStart = !this.isShowAllStart;
    },
    //  是否显示全部停用项
    doShowStopDataAllClick () {
      this.isShowAllStop = !this.isShowAllStop;
    },
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(this.$store.getters.RolePowerList, ModulePowerKey);
    },
    // 右侧设置点击
    newAdd () {
      console.log('新增');
      if (this.vHasModulePower(this.ModulePowerKey)) {
        this.doOpreate('add');
      } else {
        this.$utils.CheckModulePowerToTips(this.ModulePowerKey);
      }
    },
    // 左侧点击显示
    doFormTitleNameClickShow (item) {
      console.log(item, '左侧');
      this.$emit('onOpreate', 'view', item);
    },
    // 显示操作菜单
    showOpreateMenu (item) {
      this.showMenuItem(item);
      // 如果没有权限，则 弹出无权限提示。 ToDo        { type: 0, name: '新增', eventName: 'add', isShow: true, ModulePowerKey: this.ModulePowerKey},
      if (item.isMenuShow) {
        this.menuItemList = [
          { type: 1, name: '查看', eventName: 'view', isShow: true, ModulePowerKey: '' },
          { type: 2, name: '修改', eventName: 'edit', isShow: true, ModulePowerKey: this.ModulePowerKey }
        ];
        if (this.hasActive) {
          if (item.isActive) {
            this.menuItemList.push({
              type: 3,
              name: item.unActive, // 停用
              eventName: 'unActive',
              isShow: true,
              ModulePowerKey: this.ModulePowerKey
            });
          } else {
            this.menuItemList.push({
              type: 4,
              name: item.active,
              eventName: 'active', // 启用
              isShow: true,
              ModulePowerKey: this.ModulePowerKey
            });
          }
        } else {
          this.menuItemList.push({
            type: -1,
            name: item.finish,
            eventName: 'finish', // 结束
            isShow: true,
            ModulePowerKey: this.ModulePowerKey

          });
        }
        this.dataItemForOpreate = item;
      } else {
        this.$emit('onOpreate', item);
      }
      // 有权限，Show menu
      // this.isShowOpreateMenu = true;
    },
    doOpreate (opreateType, item) {
      if (this.vStopDataInfoList.length == 0) {
        this.isSwitchShowBox = true;
      } else if (this.vStartDataInfoList.length == 0) {
        this.isSwitchShowBox = false;
      }
      item = item || { dataKey: 0, itemName: '', isActive: true, data: {} };
      // 取消菜单显示
      this.showMenuItem(item);
      this.$emit(
        'onOpreate',
        opreateType,
        item.dataKey,
        item.data,
        returnData => { }
      );
    },
    showMenuItem (item) {
      if (this.dataInfoList.length > 0) {
        this.dataInfoList.forEach(o => {
          if (item.dataKey == o.dataKey) {
            o.isMenu = !o.isMenu;
            // console.log(item.dataKey, 'item.dataKey');
          } else {
            o.isMenu = false;
          }
        });
      }
    }
  }
};
</script>

  <style>
.shortcut_margin_Left {
  margin-left: 20px;
}
</style>
  