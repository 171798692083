<template>
  <div class="all_workbench_left_list button"
       :class="{opt:item.isSelection}"
       @click.stop="clcikEvent">
    <div class="list_title"
         :class="item.titleClassName">{{item.title}}</div>
    <div class="list_number"
         :class="item.numberClassName">{{item.value}}</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      defaule: () => {
        return {
          title: '标题名2',
          titleClassName: 'title样式',
          numberClassName: '值样式',
          value: 20,
          type: '',
          isSelection: false,
        }
      },
    },
  },
  created() {
    console.log(this.item, 'this.item')
  },
  methods: {
    clcikEvent() {
      this.$emit('click', this.item)
    },
  },
}
</script>

<style scoped>
.all_workbench_left_list {
  flex: 1;
  text-align: center;
  position: relative;
}
.all_workbench_left_list .list_title {
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  margin-top: 5px;
}
.all_workbench_left_list .list_number {
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  font-weight: bold;
}
.all_workbench_left_list.opt {
  border-bottom: 3px solid #3498db;
}

.all_workbench_left_list.opt .list_title,
.all_workbench_left_list.opt .list_number {
  color: #3498db !important;
}
.all_workbench_left_list::after {
  content: '';
  position: absolute;
  right: -1px;
  top: 10px;
  width: 1px;
  height: 30px;
  background: #e7e9e8;
}
</style>