<template>
  <!-- 成果统计 -->
  <div class="achievement_statistics_box"
       style="display: flex;padding-bottom: 20px;">
    <!-- 表格统计 -->
    <div class="achievement_left"
         style="width: 59%;">
      <statistics-left-table @chgStatisticsDate="chgStatisticsDate"></statistics-left-table>
    </div>
    <!-- 柱状图统计 -->
    <div class="achievement_right"
         style="width: 41%;">
      <statistics-right-table ref="statisticsRight"></statistics-right-table>
    </div>
  </div>
</template>
<script>
import statisticsLeftTable from './statistics-left-table';
import statisticsRightTable from './statistics-right-table';
export default {
  data () {
    return {
    };
  },
  props: {
  },
  components: {
    statisticsLeftTable,
    statisticsRightTable
  },
  methods: {
    chgStatisticsDate (item) {
      this.$refs.statisticsRight.chgStatisticsRDate(item);
    }
  }
};
</script>


