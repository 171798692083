<template>
  <div class="chain_mendian_center_right massive_bg">
    <div class="right_title">
      <div class="right_title_text">
        <div :class="searchType==0?'font_blue':''"
             @click.stop="changeSearchType(0)">课消金额</div>
        <div class="text_icon">|</div>
        <div :class="searchType==1?'font_blue':''"
             @click.stop="changeSearchType(1)">报名金额</div>
      </div>
      <div class="right_title_date">
        <div class="el-select">
          <div class="el-input el-input--small el-input--suffix">
            <single-year-picker @chgDate="chgDate"
                                :isDisableNow="isDisableNow"
                                :defaultDate="defaultDate"
                                :type="'month'"
                                :minDate="vMinDate"></single-year-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="right_title_echarts">
      <div class="echarts_box"
           v-show="totalAmount>0">
        <circleEchart :echartID="'circle_echart'"
                      :reqList="monthList"
                      :nowDataList="nowList"
                      :tipstitle="vtipstitle"
                      ref="echart"></circleEchart>
        <div class="center_tips"
             :style="setSizeByLength(totalAmount)"
             v-if="searchType==0&&totalAmount>0">
          <div class="font_gray">课消总金额</div>
          <div>{{totalAmount}}</div>
        </div>
        <div class="center_tips"
             :style="setSizeByLength(totalAmount)"
             v-if="searchType==1&&totalAmount>0">
          <div class="font_gray">报名总金额</div>
          <div>{{totalAmount}}</div>
        </div>

      </div>
      <div v-show="totalAmount==0"
           style="width:100%;height:100%">
        <div class="monitoring_content_nodata"
             style="margin-top: 20px;">
          <div class="monitoring_content_nodata_txt2">亲，当前没有任何数据哦~</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import singleYearPicker from '../../../base-module/date-picker-common/single-year-picker';
import circleEchart from '../../../base-module/echart-common/circle-echart';
import { GetClassAwayAmountForMonth, GetApplyAmountForMonth } from '../../../../API/workbench';
export default {
  data () {
    return {
      searchData: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd')
      },
      searchType: 0,
      monthList: [],
      nowList: [],
      totalAmount: 0,
      nowDataList: [],
      defaultDate: Date.new(Date.new().setDate((Date.new().getDate() - -1))),
      isDisableNow: true,          // 是否禁用今天以后的日期
      echartColor: ['#5A8DF6', '#62DAAB', '#F56E53', '#657798', '#EEB91D']

    };
  },
  components: {
    circleEchart, singleYearPicker
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vtipstitle () {
      let month = this.$utils.formatDateStr(this.searchData.startTime, 'MM');
      if (this.searchType == 0) {
        return month + '月课消金额';
      } else {
        console.log('this.searchType ');
        return month + '月报名金额';
      }
    }
  },
  created () {
  },
  mounted () {
    // this.setTime();
    this.getReport();
    this.$bus.on(this, 'switchHeaderBarToMoreMendianReport', () => {
      this.getReport();
    });
  },
  methods: {
    // 根据字数设定字体大小
    setSizeByLength (num) {
      num = num || 0;
      let size = parseInt(num).toString().length <= 6 ? 'font-size:30px' : 'font-size:18px';
      return size;
    },
    chgDate (val, startTime, endTime) {
      this.searchData.startTime = startTime.replace(/\//g, '-');
      this.searchData.endTime = endTime.replace(/\//g, '-');;
      this.getReport();
    },
    changeSearchType (val) {
      this.searchType = val;
      this.getReport();
    },
    setMonthList () {
      if (this.searchData.DateType == 1) {    // 选中周
        this.monthList = this.setEchartXForWeek();
      } else if (this.searchData.DateType == 2) { // 选中月
        this.monthList = this.setEchartXForMonth();
        console.log(this.monthList, '选中月');
      } else {      // //选中年
        this.monthList = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
      }
    },
    // 设置X轴
    setEchartXForWeek () {
      let newArr = [];
      this.nowDataList.forEach((o, index) => {
        newArr.push(o.SetUpTime.substring(5) + ' ' + this.$utils.getWeekByTwo(o.SetUpTime));
      });
      return newArr;
    },
    getReport (callback) {
      if (this.searchType == 0) {
        this.getClassAmountReport();
      } else {
        this.getApplyAmountReport();
      }
    },
    getClassAmountReport () {
      GetClassAwayAmountForMonth(this.searchData).then(result => {
        console.log(result.data, 'getAmountReport');
        result.data.SaaSClientList.sort((x, y) => {
          y.DeductAmount = Math.round(Number(y.DeductAmount || 0));
          x.DeductAmount = Math.round(Number(x.DeductAmount || 0));
          return Number(y.DeductAmount) - Number(x.DeductAmount);
        });
        this.nowDataList = result.data.SaaSClientList;
        this.totalAmount = Math.round(Number(result.data.ClassAwayMonthAmount));
        this.nowList = this.setClassAmountList();
        this.$nextTick(() => {
          if (this.totalAmount > 0) {
            if (this.$refs.echart) {
              this.$refs.echart.echartInit();
            }
          }
        });
      }).catch(error => {
        console.log(error, 'error');
      });
    },
    getApplyAmountReport () {
      GetApplyAmountForMonth(this.searchData).then(result => {
        console.log(result.data, 'getApplyAmountReport');
        result.data.SaaSClientList.sort((x, y) => {
          y.ActualColAmount = Math.round(Number(y.ActualColAmount || 0));
          x.ActualColAmount = Math.round(Number(x.ActualColAmount || 0));
          return Number(y.ActualColAmount) - Number(x.ActualColAmount);
        });
        this.nowDataList = result.data.SaaSClientList;
        this.totalAmount = Math.round(Number(result.data.ApplyMonthAmount));
        this.nowList = this.setApplyAmountList();
        this.$nextTick(() => {
          if (this.totalAmount > 0) {
            this.$refs.echart.echartInit();
          }
        });
      }).catch(error => {
        console.log(error, 'error');
      });
    },
    // 设置报名金额
    setApplyAmountList () {
      let nowList = [];
      let count = 0;
      let num = 0;
      this.nowDataList.forEach((o, index) => {
        let MainDemoName = o.MainDemoName.length > 12 ? o.MainDemoName.substring(0, 12) + '...' : o.MainDemoName;
        if (Number(o.ActualColAmount) > 0 && index < 5) {
          let obj = {
            value: Number(o.ActualColAmount),
            name: MainDemoName,
            itemStyle: {
              color: this.echartColor[index]
              // normal: {
              //   label: {
              //     show: true
              //   },
              //   labelLine: {
              //     show: true
              //   }
              // }

            }
          };
          nowList.push(obj);
        } else {
          count++;
          num += o.ActualColAmount || 0;
        }
      });
      if (count > 0) {
        console.log(num, 'count>0');
        let obj = {
          value: num > 0 ? num : null,
          name: '其他',
          itemStyle: {
            color: '#6DC8EC'
            // normal: {
            //   label: {
            //     show: true
            //   },
            //   labelLine: {
            //     show: true
            //   }
            // }
          }
        };
        nowList.push(obj);
      }
      console.log(nowList, 'nowList1');
      return nowList;
    },
    setClassAmountList () {
      let nowList = [];
      let count = 0;
      let num = 0;
      this.nowDataList.forEach((o, index) => {
        let MainDemoName = o.MainDemoName.length > 12 ? o.MainDemoName.substring(0, 12) + '...' : o.MainDemoName;
        if (Number(o.DeductAmount) > 0 && index < 5) {
          let obj = {
            value: Math.round(Number(o.DeductAmount)),
            name: MainDemoName,
            itemStyle: {
              color: this.echartColor[index]
              // normal: {
              //   label: {
              //     show: true
              //   },
              //   labelLine: {
              //     show: true
              //   }
              // }
            }
          };
          nowList.push(obj);
        } else {
          count++;
          num += Math.round(Number(o.DeductAmount));
        }
      });
      if (count > 0) {
        let obj = {
          value: num > 0 ? num : null,
          name: '其他',
          itemStyle: {
            color: '#6DC8EC'
            // normal: {
            //   label: {
            //     show: true
            //   },
            //   labelLine: {
            //     show: true
            //   }
            // }
          }
        };
        nowList.push(obj);
      }
      console.log(nowList, 'nowList1');
      return nowList;
    }

  }
};
</script>
