<template>
  <!-- 学分详情列表 -->
  <div>
    <!-- 学生学分项-->
    <div class="homework_student_ul">
      <details-item v-for="(item,index) in studentList"
                    :key="index"
                    :stuItem="item"
                    @coinStateClick="afterCoinStateClick"></details-item>
      <div></div>
    </div>
    <!-- 无数据 -->
    <div class="index_JXT_noData"
         v-if="studentList&&studentList.length==0">
      <span class="index_JXT_noData_text">暂无数据</span>
    </div>

    <div v-if="isShowBtn&&studentList.length>0">
      <release-btn :btnText="'确认'"
                   :isFloatBtn="true"
                   @click="confirmClick"></release-btn>
    </div>
  </div>
</template>

<script>
import detailsItem from './details-item';
import releaseBtn from '../../common-component/release-btn';
export default {
  data () {
    return {
      studentList: []
    };
  },
  props: {
    studentMoneyList: Array,
    isShowBtn: {
      type: Boolean,
      default: function () {
        return true;
      }
    }
  },
  components: {
    detailsItem,
    releaseBtn
  },
  created () {
    this.studentList = this.$utils.parseJson(this.studentMoneyList);
    // console.log(this.studentList, '学分');
  },
  watch: {
  },
  computed: {
  },
  methods: {
    afterCoinStateClick (item, index) {
      // this.$emit('afterCoinStateClick', item, index);
    },
    // 确认按钮
    confirmClick () {
      // console.log(this.studentList, '学分');
      this.$emit('confirmClick', this.studentList);
      this.cancel();
    },
    // 关闭
    cancel () {
      this.$emit('closeDialog');
    }
  }
};
</script>

<style>
</style>