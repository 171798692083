<template>
  <!-- 连锁总部工作台 -->
  <div class="chain_workbench_view all_view">
    <!-- <button @click.stop="goRouter">签约表单</button> -->
    <div class="chain_workbench_left">
      <div class="chain_workbench_teacher_info">

        <div class="teacher_info_img">
          <staff-heads-img-upload :ImgURl="staffInfo.XMLIcon"
                                  :staffInfo="staffInfo"
                                  :formTitle="'员工头像:'"
                                  :imgHeight="300"
                                  :imgWidth="300"
                                  @doSetPicCorp="doSetPicCorpHeadImg"></staff-heads-img-upload>
          <!-- <img class="img_box"
               src="../../../public/image/face_screenage_noImg.png"
               alt=""> -->
        </div>
        <div class="teacher_info_name">
          {{staffInfo.MainDemoName}}
        </div>
        <div class="teacher_info_text">
          {{staffInfo.DepartmentKeyValue||''}} {{staffInfo.PostKeyValue?'|':''}} {{staffInfo.PostKeyValue}}

        </div>
      </div>
      <warning-list @updateData="updateData"></warning-list>
      <todo-list @updateData="updateData"></todo-list>
    </div>
    <real-time ref="realTime"
               :menDianSetInfo="menDianSetInfo"
               @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"></real-time>
    <div>
      <dialog-factory ref="chainWorkBenchDialogFactory"
                      :key="'chainWorkBench'"
                      :routerName="'chainWorkBench'"></dialog-factory>
      <dialog-factory-common ref="chainWorkBenchDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import dialogFactory from '../common/dialog-factory';
import todoList from './todo-list/index';
import warningList from './warning-list/index';
import realTime from './real-time';
import {
  GetTheEmployeeByID,
  GetSaaSClientSet
} from '../../API/workbench';
export default {
  data () {
    return {
      token: {},
      menDianSetInfo: [],
      staffInfo: {},
      seletedDate: Date.new().toLocaleDateString()
    };
  },
  components: {
    realTime, todoList, warningList, dialogFactory
  },
  computed: {
    // 头像
    headImg () {
      if (!this.staffInfo.XMLIcon) {
        this.staffInfo.XMLIcon = '';
      }
      if (this.staffInfo.XMLIcon.indexOf('http') > -1) {
        return this.staffInfo.XMLIcon;
      } else {
        return this.$store.getters.CDNURL + this.staffInfo.XMLIcon;
      }
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' +
        require('../../../public/image/deflut_teacher_photo@2x.png') +
        '"'
      );
    }
  },
  created () {

  },
  mounted () {
    this.token = this.$store.getters.token;
    this.loadData();
    this.getMenDianSet();
    this.registerBusEvent();
    this.$bus.on(this, 'updateMendianSet', (data) => {
      console.log('updateMendianSet');
      this.getMenDianSet();
    });
  },
  methods: {
    goRouter (item) {
      // 排课 按钮
      this.$dialog.open(this, {
        name: '付款申请',    // 新增传付款申请， 审核传付款审核
        routerName: this.$route.name,
        moduleName: 'crmPaymentForm',
        data: { OLAPKey: 9, isReadonly: false, paymentType: 'add' },  // OLAPKey 投资线索ID， isReadonly是否只读，paymentType为add时为新增，other时为审核，审核会显示收支账户收支科目
        callBack: {
          afterSucces: () => {

          }
        }
      });
    },
    updateData () {
      this.$refs.realTime.loadData();
    },
    loadData () {
      this.getStaffInfo(this.token.UserID);
    },
    // 获取门店设置
    getMenDianSet () {
      GetSaaSClientSet().then(result => {
        console.log('GetSaaSClientSet', result.data);
        this.menDianSetInfo = result.data;
        this.$nextTick(o => {
          this.$refs.realTime.loadData();
        });
      });
    },
    // 获取员工信息.
    getStaffInfo (OLAPKey, callback) {
      GetTheEmployeeByID(OLAPKey)
        .then(result => {
          this.staffInfo = result.data;
          if (callback) {
            callback();
          }
          console.log('getStaffInfo', result.data);
        })
        .catch(error => {
          console.log('getStaffInfo', error);
        });
    },
    // 更新头像.
    doSetPicCorpHeadImg (ImgURl) {
      this.staffInfo.XMLIcon = ImgURl;
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log('openCustomDialog', dialogInfo);
      if (this.$refs.chainWorkBenchDialogFactory) {
        this.$refs.chainWorkBenchDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title, 'HQ');
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.chainWorkBenchDialogFactoryCommon) {
        this.$refs.chainWorkBenchDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title, 'HQ');
      }
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'chainWorkBench') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'chainWorkBench') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openOther () {
      let moduleInfo = {
        name: '连锁类型',
        moduleName: 'chainType',
        routerName: this.$route.name,
        data: {
          afterSuccess: () => {
            this.getMenDianSet();
          }
        }
      };
      this.openCustomDialogByFactoryDialog(moduleInfo);
    }
  }
};
</script>
