<template>
  <!-- 优惠券管理 -->
  <div class="popup_dialog_box_content clearfix">
    <div class="WKT_discount_coupon_manage"
         @scroll="scrollTOP">
      <div>
        <online-simple-coupon :couponList="vonlineCouponList"
                                @doAlreadyReceivedClick="doAlreadyReceivedClick"
                                @doHaveBeenUsedClick="doHaveBeenUsedClick"
                                @sendClick="sendClick"
                                @editClick="editClick"
                                @moreClick="moreClick"></online-simple-coupon>

        <offline-simple-coupon :couponList="vofflineCouponList"
                                @doAlreadyReceivedClick="doAlreadyReceivedClick"
                                @doHaveBeenUsedClick="doHaveBeenUsedClick"></offline-simple-coupon>
      </div>
          <div v-if="couponList.length==0">
      <div class="monitoring_content_nodata"
           style="margin-top: 150px;">
        <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
      </div>
    </div>
    </div>
    <custom-dialog :title="'领取记录'"
                   width="1096px"
                   :top="30"
                   :visible.sync="isShowGetCouponRecordList"
                   :before-close="closeCouponRecordListDialog">
      <div class="defult_dialog_header_text fr">
        <span>有效期: {{couponInfo.time_start}} 至 {{couponInfo.time_end}}</span>
        <span class="date_text">仅剩{{this.$utils.newDateDiff( $utils.formatDateToLine(Date.new()),couponInfo.time_end) + 1}}天</span>
      </div>
      <getcoupon-record-list :couponInfo="couponInfo"></getcoupon-record-list>
    </custom-dialog>

    <custom-dialog :title="'使用记录'"
                   width="1196px"
                   :top="30"
                   :visible.sync="isShowUseCouponRecordList"
                   :before-close="closeCouponRecordListDialog">
      <usecoupon-record-List :couponInfo="couponInfo"></usecoupon-record-List>
    </custom-dialog>

    <!-- 券的更多操作 -->
    <div v-if="isShowMenu">
      <div class="course_student_attendance_operation module_content_menuList"
           :style="'top:'+newOffsetHeight+'px'+';'+'left:'+newOffsetLeft+'px'">
        <div class="top_bg"></div>
        <drop-down-box :optionJsonList="menuList"
                       @clickOptionForAlertDialog="doMenuClick"></drop-down-box>
      </div>
    </div>

    <custom-dialog :title="'发送优惠券'"
                   :top="30"
                   :visible.sync="isShowSendCoupon"
                   :before-close="closeSendCouponDialog">
      <give-student-send-coupon :couponInfo="couponInfo"
                                @afterSuccess="afterSendSuccess"
                                @cancel="closeSendCouponDialog"></give-student-send-coupon>
    </custom-dialog>

  </div>
</template> 
<script>
import onlineSimpleCoupon from './online-simple-coupon/coupon-list';
import offlineSimpleCoupon from './offline-simple-coupon/coupon-list';

import getcouponRecordList from './getcoupon-record-list';
import usecouponRecordList from './usecoupon-record-list';
import dropDownBox from '../../../common/drop-down-box';
import giveStudentSendCoupon from './give-student-send-coupon/index';
import { MessageSearchSimpleCoupon, PutAwaySimpleCoupon, SoldOutSimpleCoupon, DeleteSimpleCoupon, ChangeToIsInvalid } from '../../../../API/workbench';
export default {
  components: {
    onlineSimpleCoupon,
    offlineSimpleCoupon,
    getcouponRecordList,
    usecouponRecordList,
    dropDownBox,
    giveStudentSendCoupon
  },
  data () {
    return {
      couponList: [], // 券列表
      couponInfo: {}, // 券信息
      parameter: {
        pageIndex: 0, //	整型	可选	0	数据源：GET	页码，0为第一页
        pageSize: 20, //	整型	可选	0	数据源：GET	 每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选		数据源：GET	排序字段 为空默认为save---目前仅支持save
        sequence: '', //	字符串	可选		数据源：GET	 排序方向
        searchText: ''//	字符串	可选		数据源：GET	 搜索条件：模糊匹配标题
      },
      noMore: false,
      isShowMenu: false,
      menuList: [],
      newOffsetHeight: 0,
      newOffsetLeft: 0,
      scrollETarget: { scrollTop: 0 }, // 滚动元素
      isShowGetCouponRecordList: false,
      isShowUseCouponRecordList: false,
      isShowSendCoupon: false
    };
  },
  props: {
    dataInfo: Object // 券信息
  },
  created () {
    this.getMessageSearchSimpleCoupon();
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isShowMenu = false;
    }, false);
  },
  watch: {

  },
  computed: {
    vonlineCouponList () { // 已上线优惠券
      let list = [];
      this.couponList.forEach(o => {
        if (o.time_end >= this.$utils.formatDateToLine(Date.new()) && Number(o.isInvalid) == 0) {
          list.push(o);
        }
      });
      console.log(list, '已上线优惠券');
      return list;
    },
    vofflineCouponList () { // 已下线优惠券
      let list = [];
      this.couponList.forEach(o => {
        if (o.time_end < this.$utils.formatDateToLine(Date.new()) || Number(o.isInvalid) == 1) {
          list.push(o);
        }
      });
      console.log(list, '已下线优惠券');
      return list;
    }
  },
  methods: {
    // 滚动加载事件.
    scrollTOP (e) {
      this.isShowMenu = false;
      this.scrollETarget = e.target;
      let HeightView = this.scrollETarget.scrollHeight - this.scrollETarget.clientHeight;
      if (parseInt(this.scrollETarget.scrollTop) == parseInt(HeightView) && !this.noMore) {
        this.noMore = false;
        this.getMessageSearchSimpleCoupon();
      }
    },
    // 查询所有优惠券
    getMessageSearchSimpleCoupon () {
      MessageSearchSimpleCoupon(this.parameter).then(result => {
        if (this.parameter.pageIndex == 0) {
          this.couponList = [];
        }
        result.data.PageDataList.forEach(o => {
          let hasFind = false;
          if (this.couponList.length > 0) {
            hasFind = this.couponList.findIndex(p => p.id == o.id) >= 0;
          }
          if (!hasFind) {
            this.couponList.push(o);
          }
        });
        if (result.data.PageDataList.length < this.parameter.pageSize) {
          this.noMore = true;
        }
        this.parameter.pageIndex++;
        console.log(this.couponList, '优惠券列表');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 已领取列表
    doAlreadyReceivedClick (data) {
      this.couponInfo = data;
      this.isShowGetCouponRecordList = true;
    },
    // 已使用列表
    doHaveBeenUsedClick (data) {
      this.couponInfo = data;
      this.isShowUseCouponRecordList = true;
    },
    // 发送优惠券
    sendClick (data) {
      this.couponInfo = data;
      this.isShowSendCoupon = true;
    },
    // 发送成功
    afterSendSuccess () {
      this.parameter.pageIndex = 0;
      this.getMessageSearchSimpleCoupon();
      this.closeSendCouponDialog();
    },
    // 修改优惠券
    editClick (data) {
      this.couponInfo = data;
      this.$emit('editClick', this.couponInfo);
    },
    // 优惠券-更多操作
    moreClick (data) {
      this.couponInfo = data;
      let dataIndex = this.vonlineCouponList.findIndex(p => p.id == data.id);
      if (dataIndex >= 0) {
        if (dataIndex % 3 == 0) {
          this.newOffsetHeight = dataIndex / 3 * 180 + 220 - this.scrollETarget.scrollTop; ; // 220：默认纵向偏移量 ； 180：单个的偏移量
        } else {
          this.newOffsetHeight = (Math.ceil(dataIndex / 3) - 1) * 180 + 220 - this.scrollETarget.scrollTop; ; // 220：默认纵向偏移量 ； 180：单个的偏移量
        }
        this.newOffsetLeft = dataIndex % 3 * 340 + 220; // 默认横向偏移量 ；  340：单个的偏移量
      }
      if (Number(this.couponInfo.online) == 1) { // 已上架的券
        this.menuList = [
          { type: 2, name: '下架', eventName: 'unShelve', isShow: true },
          { type: 3, name: '删除', eventName: 'delete', isShow: true }
        ];
      } else {
        this.menuList = [
          { type: 1, name: '上架', eventName: 'added', isShow: true },
          { type: 4, name: '转为失效', eventName: 'failure', isShow: true }
        ];
      }
      this.isShowMenu = true;
    },
    doMenuClick (options) {
      this.parameter.pageIndex = 0;
      switch (options.eventName) {
        case 'added':// 上架
          PutAwaySimpleCoupon(this.couponInfo.id).then(result => {
            this.couponInfo.online = 1;
            this.getMessageSearchSimpleCoupon();
            layer.alert('上架成功');
            this.$emit('afterSuccess', this.couponInfo);
          }, error => {
            layer.alert(error.msg);
          });
          break;
        case 'unShelve':// 下架
          SoldOutSimpleCoupon(this.couponInfo.id).then(result => {
            this.couponInfo.online = 0;
            this.getMessageSearchSimpleCoupon();
            layer.alert('下架成功');
            this.$emit('afterSuccess', this.couponInfo);
          }, error => {
            layer.alert(error.msg);
          });
          break;
        case 'delete':// 删除
          DeleteSimpleCoupon(this.couponInfo.id).then(result => {
            this.getMessageSearchSimpleCoupon();
            layer.alert('删除成功');
            this.$emit('afterSuccess', this.couponInfo);
          }, error => {
            layer.alert(error.msg);
          });
          break;
        case 'failure':// 转为失效
          ChangeToIsInvalid(this.couponInfo.id).then(result => {
            this.getMessageSearchSimpleCoupon();
            layer.alert('转为失效成功');
            this.$emit('afterSuccess', this.couponInfo);
          }, error => {
            layer.alert(error.msg);
          });
          break;
        default:// 超出标准操作，自行处理
          break;
      }
      this.isShowMenu = false;
    },
    closeCouponRecordListDialog () {
      this.isShowGetCouponRecordList = false;
      this.isShowUseCouponRecordList = false;
    },
    // 取消发送
    closeSendCouponDialog () {
      this.isShowSendCoupon = false;
    }
  }
};
</script>

