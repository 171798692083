<template>
  <div class="credit_form_view">
    <div class="credit_form_btn">
      <div @click="closeForm" class="button btn_hover_white">取消</div>
      <div @click="submitForm" class="button btn_hover_bg_blue">确认</div>
    </div>
    <div class="credit_form_list">
      <div class="credit_form_title">报名收款</div>
      <div class="credit_form_content">
        <div class="credit_form_name">按收款金额奖励学分</div>
        <div class="credit_form_center">
          <div class="credit_form_box">
            <div class="from_list_input">
              <input type="number" v-model="formDataInfo.PayMentAmount" placeholder="" oninput="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" />
              <div class="from_list_unit">元</div>
            </div>
          </div>
          <div class="credit_form_box">
            <span class="font_gray">等于</span>
            <div class="from_list_input">
              <input type="number" v-model="formDataInfo.PayMentGreditCount" placeholder="" oninput="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')"  />
              <div class="from_list_unit">学分</div>
            </div>
          </div>
        </div>
        <div class="credit_form_switch">
          <el-switch
            v-model="formDataInfo.IsPayMentGreditKey"
            active-value="1"
            inactive-value="0"
          ></el-switch>
        </div>
      </div>
    </div>
    <div class="credit_form_list">
      <div class="credit_form_title">学生签到</div>
      <div class="credit_form_content">
        <div class="credit_form_name">按签到奖励学分</div>
        <div class="credit_form_center">
          <div class="credit_form_box">
            <span class="font_gray" style="margin-right: 20px"
              >签到 <span class="font_black">1</span>次，等于</span
            >
            <div class="from_list_input">
              <input type="number" v-model="formDataInfo.AttendGreditCount" placeholder="" oninput="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" />
              <div class="from_list_unit">学分</div>
            </div>
          </div>
        </div>
        <div class="credit_form_switch">
          <el-switch
            v-model="formDataInfo.IsAttendGreditKey"
            active-value="1"
            inactive-value="0"
          ></el-switch>
        </div>
      </div>
    </div>
    <div class="credit_form_list">
      <div class="credit_form_title">点评上课表现</div>
      <div class="credit_form_content">
        <div class="credit_form_name">按学生上课表现奖励学分</div>
        <div class="credit_form_center">
          <div class="credit_form_box">
            <span class="font_black">1-{{Number(formDataInfo.MaxCommentCreditCount)}}</span
            ><span class="font_gray">学分</span>
          </div>
        </div>
        <div class="credit_form_switch"></div>
      </div>
    </div>
    <div class="credit_form_list">
      <div class="credit_form_title">家长回复点评</div>
      <div class="credit_form_content">
        <div class="credit_form_name">按家长首次回复奖励学分</div>
        <div class="credit_form_center">
          <div class="credit_form_box">
            <div class="from_list_input">
              <input type="number" v-model="formDataInfo.ReplyGreditCount" placeholder="" oninput="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" />
              <div class="from_list_unit">学分</div>
            </div>
          </div>
        </div>
        <div class="credit_form_switch">
          <el-switch
            v-model="formDataInfo.IsReplyGreditKey"
            active-value="1"
            inactive-value="0"
          ></el-switch>
        </div>
      </div>
      <div class="WX_message_tips">
        <div class="WX_message_tips_title">
          点评首次回复奖励学分，后继的互动不奖励学分。
        </div>
      </div>
    </div>
    <div class="credit_form_list">
      <div class="credit_form_title">学生提交作业</div>
      <div class="credit_form_content">
        <div class="credit_form_name">按提交作业次数奖励学分</div>
        <div class="credit_form_center">
          <div class="credit_form_box">
            <span class="font_gray" style="margin-right: 20px"
              >提交 <span class="font_black">1</span>次，等于</span
            >
            <div class="from_list_input">
              <input type="number" v-model="formDataInfo.HomeworkGreditCount" placeholder="" oninput="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" />
              <div class="from_list_unit">学分</div>
            </div>
          </div>
        </div>
        <div class="credit_form_switch">
          <el-switch
            v-model="formDataInfo.IsHomeworkGreditKey"
            active-value="1"
            inactive-value="0"
          ></el-switch>
        </div>
      </div>
      <div class="credit_form_content" style="margin-top: 20px">
        <div class="credit_form_name">批改作业的质量评分</div>
        <div class="credit_form_center">
          <div class="credit_form_box">
            <span class="font_black">1-{{Number(formDataInfo.MaxCorrectCreditCount)}}</span
            ><span class="font_gray">学分</span>
          </div>
        </div>
        <div class="credit_form_switch"></div>
      </div>
    </div>
    <div class="credit_form_list">
      <div class="credit_form_title">转发分享点评和作业</div>
      <div class="credit_form_content">
        <div class="credit_form_name">按分享次数奖励学分</div>
        <div class="credit_form_center">
          <div class="credit_form_box">
            <span class="font_gray" style="margin-right: 20px"
              >转发 <span class="font_black">1</span>次，等于</span
            >
            <div class="from_list_input">
              <input type="number" v-model="formDataInfo.TranspondCreditCount" placeholder="" oninput="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')"/>
              <div class="from_list_unit">学分</div>
            </div>
          </div>
        </div>
        <div class="credit_form_switch">
          <el-switch
            v-model="formDataInfo.IsTranspondKey"
            active-value="1"
            inactive-value="0"
          ></el-switch>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetSaaSClientCreditRules, EditSaaSClientCreditRules } from "../../../API/workbench";
export default {
  data() {
    return {
      formDataInfo: {
        IsPayMentGreditKey: 0,
        IsPayMentGreditKeyValue: "否",
        PayMentAmount: "",
        PayMentGreditCount: "",
        IsAttendGreditKey: 0,
        IsAttendGreditKeyValue: "否",
        AttendGreditCount: "",
        IsReplyGreditKey: 0,
        IsReplyGreditKeyValue: "否",
        ReplyGreditCount: "",
        IsHomeworkGreditKey: 0,
        IsHomeworkGreditKeyValue: "否",
        HomeworkGreditCount: "",
        IsTranspondKey: 0,
        IsTranspondKeyValue: "否",
        TranspondCreditCount: "",
        IsCommentKey: 0,
        IsCommentKeyValue: "否",
        MaxCommentCreditCount: "",
        IsCorrectKey: 0,
        IsCorrectKeyValue: "否",
        MaxCorrectCreditCount: "",
      },
    };
  },
  computed:{
      vMendianInfo(){
          return this.$store.getters.SaaSClientInfo;
      }
  },
  created() {
      this.getFormData();
  },
  watch:{
  },
  methods:{
      closeForm(){
          this.$emit('closePopup')
      },
      submitForm(){
        if(this.formDataInfo.PayMentAmount.length == 0){
          layer.alert('报名收款金额不可为空');
          return false;
        }else if(this.formDataInfo.PayMentGreditCount.length == 0){
          layer.alert('报名收款学分不可为空');
          return false;
        }else if(this.formDataInfo.AttendGreditCount.length == 0){
          layer.alert('学生签到学分不可为空');
          return false;
        }else if(this.formDataInfo.ReplyGreditCount.length == 0){
          layer.alert('家长回复点评学分不可为空');
          return false;
        }else if(this.formDataInfo.HomeworkGreditCount.length == 0){
          layer.alert('学生提交作业学分不可为空');
          return false;
        }else if(this.formDataInfo.TranspondCreditCount.length == 0){
          layer.alert('转发分享点评和作业学分不可为空');
          return false;
        }
        if(this.formDataInfo.IsPayMentGreditKey == 1 && this.formDataInfo.PayMentGreditCount == 0){
          layer.alert('报名收款开启时，学分不可设置为0');
          return false;
        }
        if(this.formDataInfo.IsPayMentGreditKey == 1 && this.formDataInfo.PayMentAmount == 0){
          layer.alert('报名收款开启时，金额不可设置为0');
          return false;
        }
        if(this.formDataInfo.IsAttendGreditKey == 1 && this.formDataInfo.AttendGreditCount == 0){
          layer.alert('学生签到开启时，学分不可设置为0');
          return false;
        }
        if(this.formDataInfo.IsReplyGreditKey == 1 && this.formDataInfo.ReplyGreditCount == 0){
          layer.alert('家长回复点评开启时，学分不可设置为0');
          return false;
        }
        if(this.formDataInfo.IsHomeworkGreditKey == 1 && this.formDataInfo.HomeworkGreditCount == 0){
          layer.alert('学生提交作业开启时，学分不可设置为0');
          return false;
        }
        if(this.formDataInfo.IsTranspondKey == 1 && this.formDataInfo.TranspondCreditCount == 0){
          layer.alert('转发分享点评和作业开启时，学分不可设置为0');
          return false;
        }
          EditSaaSClientCreditRules(this.formDataInfo.OLAPKey, this.formDataInfo).then(res=>{
              if(res.code == 0){
                layer.alert('修改学分规则成功');
                this.closeForm();
              }else {
                layer.alert(res.msg);
              }
          },err=>{
              layer.alert(err.msg);
          })
      },
      getFormData(){
          GetSaaSClientCreditRules().then(res=>{
              res.data.PayMentAmount = Number(res.data.PayMentAmount);
              res.data.PayMentGreditCount = Number(res.data.PayMentGreditCount);
              res.data.AttendGreditCount = Number(res.data.AttendGreditCount);
              res.data.ReplyGreditCount = Number(res.data.ReplyGreditCount);
              res.data.HomeworkGreditCount = Number(res.data.HomeworkGreditCount);
              res.data.TranspondCreditCount = Number(res.data.TranspondCreditCount);
              res.data.MaxCommentCreditCount = Number(res.data.MaxCommentCreditCount);
              res.data.MaxCommentCreditCount = Number(res.data.MaxCorrectCreditCount)
              this.formDataInfo = res.data;
          },err=>{
              layer.alert(err.msg);
          })
      }
  }
};
</script>