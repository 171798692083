<template>
  <div class="homerwork_details_student_list student_info_list">
    <div class="list_img"
          :class="vClass">
      <span v-if="!vStudentPhoto"
            class="text_box">{{vStudentInitial}}</span>
      <img  v-else
            class="img_box"
             :src="vStudentPhoto">
    </div>
    <div class="list_name">
      {{item.StudentKeyValue}}
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  computed: {
    vClass () {
      let n = Math.floor(1 + Math.random() * (9 - 1))
      return 'bg_' + n;
    },
    vStudentInitial () {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(this.item.StudentKeyValue)) {
        return this.item.StudentKeyValue.substr(this.item.StudentKeyValue.length - 1, 1)
      } else {
        return this.item.StudentKeyValue.substr(0, 1)
      }
    },
    vStudentPhoto () {
      console.log("this.item.HeadImg",this.item.HeadImg)
      let isDefaultImg = (this.item.HeadImg != '/PUBLIC/images/deflut_studentPhoto_boy.png' && this.item.HeadImg != '/PUBLIC/images/deflut_studentPhoto_gril.png')
      if (this.item.HeadImg && isDefaultImg) {
        if (this.item.HeadImg.indexOf('http') > -1) {
          return this.item.HeadImg;
        } else {
          return this.$store.getters.CDNURL + this.item.HeadImg;
        }
      } else {
        return ''
      }
    },
  },
  methods: {

  }
};
</script>

<style>
</style>
