<template>
  <!-- 杂费列表 -->
  <div class="class_bill_list class_bill_list_min single_shadow marginTop_s"
       :class="{opt:item.isActive}"
       @click="clickItem(item)">
    <div>
      <span class="class_bill_list_w">
        购买日期 :
        <span class="font_black">{{vbuyDate}}</span>
      </span>
      <span class="class_bill_list_w">
        购买金额 :
        <span class="font_black">
          <!-- {{Number(item.ValueAmount).toFixed(2)}} -->
          {{$utils.mMoneyType(Number(item.ActualPayAmount),2)}}
        </span>
      </span>
    </div>
    <div>
      <span class="class_bill_list_w">
        <span class="class_bill_list_left">杂费</span>
        <span>:</span>
        <span class="font_black">{{item.ProjectKeyValue}}</span>
      </span>
      <span class="class_bill_list_w">
        <span class="class_bill_list_left">数量</span>
        <span>:</span>
        <span class="font_black">{{$utils.mMoneyType(Number(item.CurrentCount),1)}}{{item.UnitName}}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['incidentalsList', 'item'],
  data () {
    return {
    };
  },
  components: {
  },
  computed: {
    // 负责显示购买日期
    vbuyDate () {
      if (this.item.PaidTime) {
        return this.item.PaidTime.slice(0, 10).replace(/-/g, '.');
      } else if(this.item.GenerateTime){
        return this.item.GenerateTime.slice(0, 10).replace(/-/g, '.');
      } else{
        return '';
      }
    }
  },
  created () {
  },
  methods: {
    // 点击获取选择项和选择高亮
    clickItem (item) {
      this.$store.commit('showAlerDialog', false);
      item.isActive = !item.isActive;// (多选高亮)
      this.$emit('incidentalsItemAmountAdd');
    }
  }
};
</script>

  <style>
</style>