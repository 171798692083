<template>
  <div class="modulebox clearfix">
    <div class="chartbox">
      <div class="tit clearfix">
        <div class="text">课效数据解读</div>
        <single-year-picker :type="'month'"
                            @chgDate="chgDate"></single-year-picker>
      </div>
      <echart-column :echartID="'echart1'"
                     ref="echart1"
                     :dataList="echartList"></echart-column>
    </div>
    <div class="listbox">
      <div class="course_statistics_table_box table_list_content">
        <table-list class="summarizing_list"
                    :tableData="tableData"
                    :totalNum="totalNum"
                    :PageCount="PageCount"
                    :tableColumns="tableColumns"
                    :queryParams="search"
                    :footerContent="vFooterContent"
                    @loadTableData="getList"></table-list>
      </div>
    </div>
  </div>
</template>

<script>
import { GetParentCountData, GetParentMTeacherCountList } from '../../../API/workbench';
import echartColumn from './echart-column';
import tableList from '../../common/table-list/index';
import singleYearPicker from '../../base-module/date-picker-common/single-year-picker';
export default {
  data () {
    return {
      dateStart: '',
      dateEnd: '',
      lastMonth: '',
      echartList: [
        {
          type: 'bar',
          itemStyle: { color: '#FFC15E' },
          name: '',
          data: [0, 0, 0]
        },
        {
          type: 'bar',
          itemStyle: { color: '#0CB155' },
          name: '',
          data: [0, 0, 0]
        }
      ],
      search: {
        Month: '',
        pageIndex: 0,
        pageSize: 5
      },
      tableData: [],
      totalNum: 0,
      PageCount: 0,
      tableColumns: [
        {
          label: '老师',
          prop: 'MTeacherKeyValue',
          width: 150,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '应发课评',
          prop: 'StudentCount',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '实发课评',
          prop: 'SendStidentCount',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '家长已读',
          prop: 'ReadStudentCountSum',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '家长分享',
          prop: 'ChangeStudentCountSum',
          width: 100,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '发送率',
          prop: 'SendStidentCountLv',
          width: 80,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '已读率',
          prop: 'ReadStidentCountLv',
          width: 80,
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        },
        {
          label: '转发率',
          prop: 'ChangeStudentCountLv',
          align: 'left',
          isShow: true,
          sortable: false,
          type: 'text-item'
        }
      ]
    };
  },
  components: {
    echartColumn,
    tableList,
    singleYearPicker
  },
  created () {
  },
  mounted () {
    this.dateStart = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
    this.dateEnd = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd');
    this.search.Month = this.dateStart.replace('-01', '');
    this.lastMonth = this.getLastMonth(this.search.Month);
    this.getData();
    this.getList();
  },
  computed: {
    vFooterContent () {
      return '共 ' + this.totalNum + ' 条数据';
    }
  },
  methods: {
    getLastMonth (monthNow) {
      let year = parseInt(monthNow.split('-')[0]);
      let month = parseInt(monthNow.split('-')[1]);
      if (month == 1) {
        month = 12;
        year = year - 1;
      } else {
        month = month - 1;
      }
      month = month + '';
      if (month.length == 1) {
        month = '0' + month;
      }
      return year + '-' + month;
    },
    chgDate (val, startTime, endTime) {
      this.dateStart = startTime.split('/').join('-');
      this.dateEnd = endTime.split('/').join('-');
      this.search.Month = this.dateStart.replace('-01', '');
      this.lastMonth = this.getLastMonth(this.search.Month);
      this.getData();
      this.getList();
    },
    draw () {
      this.$refs.echart1.echartInit();
    },
    getData () {
      let that = this;
      GetParentCountData(that.search.Month, that.lastMonth).then(result => {
        if (result.code == 0) {
          that.echartList[0].name = that.lastMonth;
          that.echartList[0].data = [result.data.ChangeLastMonthLv, result.data.ReadLastMonthLv, result.data.SendLastMonthLv];
          that.echartList[1].name = that.search.Month;
          that.echartList[1].data = [result.data.ChangeMonthLv, result.data.ReadMonthLv, result.data.SendMonthLv];
          that.draw();
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    getList () {
      let that = this;
      GetParentMTeacherCountList(that.search).then(result => {
        if (result.code == 0) {
          let list = result.data.PageDataList;
          for (let i = 0; i < list.length; i++) {
            list[i].SendStidentCountLv = list[i].SendStidentCountLv + '%';
            list[i].ReadStidentCountLv = list[i].ReadStidentCountLv + '%';
            list[i].ChangeStudentCountLv = list[i].ChangeStudentCountLv + '%';
          }
          that.totalNum = result.data.Total;
          that.PageCount = result.data.PageCount;
          that.tableData = list;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
};
</script>

<style scoped>
</style>