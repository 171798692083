<template>
		<div class="" @click.stop="mineReplyClick">
			<div class="comment_list" v-if="ReplyItem.MainForReplyID==0">
				<span class="font_blue">{{vReplyItemFromName}}：</span>
				<span v-html="vContent"></span>
			</div>
			<div class="comment_list" v-else>
				<span class="font_blue">{{vReplyItemFromName}}</span>
				<span>回复</span>
				<span class="font_blue">{{vReplyItemToName}}：</span>
				<span v-html="vContent"></span>
			</div>
		</div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object,
    ReplyItem: Object,
  },
  computed: {
	   // 是否切换全校模式，模式 1-全校;2-普通
    vReplyItemFromName () {
		// console.log(this.$store.getters,"普通模式")
      if (this.$store.getters.browseMessageRole == 2) { // -普通模式
        if (Number(this.ReplyItem.FromID) == Number(this.$store.getters.signData.UserID)) {
          return '我';
        } else {
          return this.ReplyItem.From;
        }
      } else {
        return this.ReplyItem.From;
      }
    },
    vReplyItemToName () {
      if (this.$store.getters.browseMessageRole == 2) { // -普通模式
        if (Number(this.ReplyItem.ToID) == Number(this.$store.getters.signData.UserID)) {
          return '我';
        } else {
          return this.ReplyItem.To;
        }
      } else {
        return this.ReplyItem.To;
      }
    },
	 // 文本转译
    vContent () {
		return this.$utils.translationSchoolText(this.ReplyItem.Content)
    }
  },
  created () {

  },
  methods: {
    // 留言区
    mineReplyClick () {
      console.log(this.$store.getters,'留言区');
	  if (this.ReplyItem.FromID == this.$store.getters.signData.UserID) { // 不可回复当前用户父，否则弹出回复操作菜单
	    this.$emit('doMineReplyClick', 0, this.ReplyItem); // 自己
	  } else {
	    this.$emit('doMineReplyClick', 1, this.ReplyItem); // 其他
	  }
    }
  }
};
</script>

<style>

</style>
