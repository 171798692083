import { render, staticRenderFns } from "./number-range.vue?vue&type=template&id=22eda753&scoped=true"
import script from "./number-range.vue?vue&type=script&lang=js"
export * from "./number-range.vue?vue&type=script&lang=js"
import style0 from "./number-range.vue?vue&type=style&index=0&id=22eda753&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22eda753",
  null
  
)

export default component.exports