<template>
  <div class="general_summarizing_student payment_statistics_view height_type all_view">
    <div class="course_statistics_left_title flex">
      <span>线索回收站</span>
    </div>
    <div class="course_statistics_table_search course_statistics_title"
         v-if="batchAllotList.length>0">
      <batch-distribution-box :batchAllotList="batchAllotList"
                              @doCancelAllot="doCancelAllot"></batch-distribution-box>
    </div>
    <div class="course_statistics_table_search course_statistics_title"
         v-else>
      <div class="">
        <div class="">
          <date-range ref="monthPicker"
                      style="margin-right:36px"
                      :fromTitle="'登记日期'"
                      :searchObj="searchObj"
                      @changeDate="changeSaveDate"></date-range>
        </div>
      </div>

      <div class="course_statistics_search">
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入姓名、姓名首字母、手机号'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
      </div>

    </div>
    <div class="course_statistics_table_box table_list_content follow_clue_list">
      <table-list class="summarizing_list"
                  ref="tableListRef"
                  :tableData="vdataReportList"
                  :tableColumns="tableCourseColumns"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :queryParams="searchObj"
                  :footerContent="vFooterTotal"
                  :isShowCheckBox="true"
                  @changeCheckBoxFun="changeCheckBoxFun"
                  @loadTableData="getDataList"></table-list>
    </div>
    <custom-dialog :title="'分配线索'"
                   class=" "
                   width="410px"
                   :visible.sync="showAllot"
                   :before-close="closeshowAllot">
      <allot-dialog :dataInfo="seletedInfo"
                    :studentArray="studentArray"
                    :isRecycle="1"
                    @updateData="updateData"
                    @closeDialog="closeshowAllot"></allot-dialog>
    </custom-dialog>
    <div>
      <dialog-factory ref="clueRecycleBinDialogFactory"
                      :key="'clueRecycleBin'"
                      :routerName="'clueRecycleBin'"></dialog-factory>

      <dialog-factory-common ref="clueRecycleBinDialogFactoryCommon"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import allotDialog from './allot-dialog';
import dialogFactory from '../../../common/dialog-factory';
import titleSummaryData from './title-summary-data';
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import batchDistributionBox from './batch-distribution-box';
import { WaitAllocationThreadRecycleBin, SaleStatRecycleStudent, DoAllocationThread } from '../../../../API/workbench';
export default {
  name: 'clueRecycleBin',
  data () {
    return {
      seletedInfo: {},    // 选中的单项
      studentDetail: {},
      batchAllotList: [], // 批量分配列表
      headSummaryData: [
        { title: '全部', className: '', value: 0, StatusKey: 0, decimalPlace: 0, isSelected: true },
        { title: '新生', className: '', value: 0, StatusKey: 1, decimalPlace: 0, isSelected: false },
        { title: '已放弃', className: 'font_red', value: 0, StatusKey: 2, decimalPlace: 0, isSelected: false }
      ],
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: '',
        endTime: '',
        type: 0

      },
      salesList: [],
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      studentArray: [],     // 选中的分配学生.
      showAllot: false, // 是否显示分配弹出层
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '登记日期',
          prop: 'RegisterTime',
          minWidth: 160,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },

        {
          label: '学生',
          prop: 'StudentKeyValue',
          width: ' ',
          minWidth: 100,
          sortable: false,
          type: 'student',
          extend: {
            studentKey: 'StudentKey',
            phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '意向级别',
          prop: 'WillingnessRateKeyValue',
          width: 60,
          sortable: false,
          type: 'textItem',
          align: 'center',
          columnClass: 'font_dual'
        },
        {
          label: '兴趣课',
          prop: 'FineSupport',
          minWidth: 140,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_dual'
          }
        },
        {
          label: '到店参观',
          prop: 'ToStoreAppointTime',
          minWidth: 100,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_dual',
            setClassName: (rowData) => {
              return this.setStoreClassType(rowData, 'ToStoreResultKey');
            }
          }
        },
        {
          label: '试课记录',
          prop: 'TrialClassValue',
          minWidth: 200,
          sortable: false,
          align: 'left',
          type: 'textItem',
          extend: {
            className: 'font_dual',
            setClassName: (rowData) => {
              return this.setTrialClassType(rowData, 'TrialClassResultKey');
            }
          }
        },
        {
          label: '是否报名',
          prop: 'EnlistTimeApply',
          minWidth: 84,
          sortable: false,
          align: 'left',
          type: 'textItem',
          decimalPlace: 0
        },
        {
          label: '跟进情况描述',
          prop: 'followContent',
          minWidth: 200,
          sortable: false,
          align: 'left',
          type: 'textItem',
          decimalPlace: 0
        },

        {
          label: '来源方式',
          prop: 'SourceTypeKeyValue',
          sortable: false,
          minWidth: 112,
          align: 'left',
          type: 'textItem',
          noPlusminus: true,
          decimalPlace: 0
        },
        {
          label: '操作人',
          prop: 'RecycleOperatorKeyValue',
          sortable: false,
          minWidth: 112,
          align: 'left',
          type: 'textItem',
          noPlusminus: true,
          decimalPlace: 0
        },
        {
          label: '操作时间',
          prop: 'RecycleTime',
          sortable: false,
          minWidth: 112,
          align: 'left',
          type: 'textItem',
          noPlusminus: true,
          decimalPlace: 0
        },
        {
          label: '操作',
          prop: '',
          width: '80',
          sortable: false,
          align: 'center',
          type: 'tableBtn',
          extend: {
            tableBtnGroup: [
              {
                name: '分配',
                extend: {
                  isHide: false,
                  click: (rowData) => {
                    this.allotData(rowData);
                  }
                }

              }

            ]
          }
        }

      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    inputSearchContainBtn,
    titleSummaryData,
    dialogFactory,
    allotDialog,
    batchDistributionBox
  },
  props: {
  },
  created () {
    console.log('this.toSearchObj');
    if (this.$route.query.startTime) {
      this.searchObj.startTime = this.$route.query.startTime;
      this.searchObj.endTime = this.$route.query.endTime;
    } else {
      // this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      // this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
    }
  },
  mounted () {
    this.getDataList();
    this.resgisterBusEvent();
  },
  computed: {
    vsalesList () {
      return this.salesList;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // o.NextFollowUpTime = '2021-10-11 16:30:25';
        // o.Content = '测试133113';
        // o.FollowUpTime = '2021-10-11 16:30:25';
        o.ToStoreResult = '';
        o.TrialClassResult = '';
        o.RecycleTime = o.RecycleTime || '';
        o.ToStoreAppointTime = o.ToStoreAppointTime || '';
        o.TrialClassAppointTime = o.TrialClassAppointTime || '';
        o.WillingnessRateKeyValue = o.WillingnessRateKeyValue.substring(0, 1);
        o.RecycleTime = o.RecycleTime.substring(5, 16);
        o.EnlistTimeApply = o.EnlistTime ? '是' : '否';
        o.followContent = o.Content ? `<span class="font_gray">` + this.$utils.StrForTodoTime(o.FollowUpTime) + `:</span>` + o.Content : '';
        o.ToStoreAppointTime = o.ToStoreAppointTime || '';
        o.TrialClassAppointTime = o.TrialClassAppointTime || '';
        o.ToStoreAppointTime = o.ToStoreAppointTime.substring(5, 10);
        if (o.TrialClassAppointTime) {
          o.TrialClassValue = `<div class="flex try_course_apoint">` + o.TrialClassAppointTime.substring(5, 10) + `</div><div>` + o.OrderClassKeyValue + `</div>`;
        }
        if (o.IsAbandonKey == 1) {
          o.NextFollowUpTime = '已放弃';
        } else {
          o.NextFollowUpTime = (o.NextFollowUpTime ? this.$utils.leadTime(o.NextFollowUpTime.substring(0, 10)) + ' ' + `<div>` + o.NextFollowUpTime.substring(5, 10) + `</div>` : '');
        }
        // if (o.ToStoreResultKey >= 0) {
        //   o.ToStoreResult = o.ToStoreAppointTime.substring(5, 10);
        // }
        // if (o.TrialClassResultKey >= 0 && o.OrderClassKeyValue) {
        //   o.TrialClassResult = `<div class="result_` + o.TrialClassResultKey + `">` + o.TrialClassAppointTime.substring(5, 10) + `</div>` + `<div class="font_gray">` + o.OrderClassKeyValue + `</div>`;
        // }
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      // if (this.totalAmount > 0) {
      //   text = text + ',合计+' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      // } else {
      //   text = text + ',合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      // }
      return text;
    }
  },

  methods: {
    setStoreClassType (item, type) {
      if (item[type] == 0) {
        return '';
      } else if (item[type] == 1) {
        return 'list_type_green';
      } else if (item[type] == 2) {
        return 'list_type_red';
      } else if (item[type] == 3) {
        return 'list_type_red';
      } else {
        return '';
      }
    },
    setTrialClassType (item, type) {
      if (item[type] == 0) {
        return 'list_Trial_type_gray';
      } else if (item[type] == 1) {
        return 'list_Trial_type_green';
      } else if (item[type] == 2) {
        return 'list_Trial_type_red';
      } else if (item[type] == 3) {
        return 'list_Trial_type_red';
      } else {
        return '';
      }
    },
    doTitleBoxClick (item) {
      console.log(item, 'doAfterTitleBoxClick');
      // this.accraditationList = [];
      this.headSummaryData.forEach(o => { // 高亮显示
        if (item.title == o.title) {
          o.isSelected = true;
        } else {
          o.isSelected = false;
        }
      });
      this.searchObj.type = item.StatusKey;
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.saleMan = 0;
      this.searchObj.saleManValue = '全部';
      this.getDataList();
    },

    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
      this.getDataList();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.IsExportKey = 0;
      this.searchObj.PastDueType = 0;
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      // this.searchObj.type = 0;
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (item, date) {
      console.log('searchData', date);
      this.searchObj.TeacherKey = item.TeacherKey;
      this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(date).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      let seletedYearMonth = Date.new(date).getFullYear() + ' ' + (Date.new(date).getMonth() + 1);
      let nowYearMonth = Date.new().getFullYear() + ' ' + (Date.new().getMonth() + 1);
      if (seletedYearMonth != nowYearMonth) {
        this.searchObj.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new(date)), 'yyyy-MM-dd');
      } else {
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
      }
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    getDataList (queryParams) {
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj);
      WaitAllocationThreadRecycleBin(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        this.headSummaryData[0].value = result.data.CountStat.AllNum;
        this.headSummaryData[1].value = result.data.CountStat.newStudengtNum;
        this.headSummaryData[2].value = result.data.CountStat.AbandonNum;
        if (result.data.PageDataList.length == 0 && this.searchObj.pageIndex > 0) {
          this.searchObj.pageIndex = this.searchObj.pageIndex - 1;
          this.getDataList();
        }
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    closeshowAllot () {
      this.showAllot = false;
    },
    // 分配
    allotData (item) {
      this.seletedInfo = item;
      this.studentArray = [];
      this.studentArray.push(this.seletedInfo.StudentKey);
      this.showAllot = true;
    },
    // 批量分配
    changeCheckBoxFun (list) {
      this.batchAllotList = list;
      console.log(this.batchAllotList, '批量分配');
    },
    // 取消分配
    doCancelAllot (succeed) {
      this.batchAllotList = [];
      this.$refs.tableListRef.checkBoxList = [];
      this.$refs.tableListRef.$refs.multipleTable.clearSelection();
      if (succeed) {
        this.getDataList();
      }
    },
    // 单个分配
    updateData () {
      console.log(this.searchObj, 'updateData');
      this.doCancelAllot();
      this.getDataList();
    },
    // 删除
    deletedData (item) {
      SaleStatRecycleStudent(item.OLAPKey).then(result => {
        console.log('RecycleStudent');
        layer.alert('删除成功');
        this.getDataList();
      }).catch(error => {
        layer.alert(error.msg);
      });
    },

    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.red:
          this.$dialog.open(this, {
            name: '学生档案',
            routerName: this.$route.name,
            moduleName: 'studentFile',
            data: { studentID: rowData.StudentNumKey }
          });

          break;
        default:
          break;
      }
    },
    selectOverdueType (item) {
      console.log('selectOverdueType');
      this.searchObj.saleMan = item.value;
      this.searchObj.saleManValue = item.label;
      this.searchObj.pageIndex = 0;
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.clueRecycleBinDialogFactory) {
        this.$refs.clueRecycleBinDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      };
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.clueRecycleBinDialogFactoryCommon) {
        this.$refs.clueRecycleBinDialogFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      };
    },
    resgisterBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'clueRecycleBin') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
      this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'clueRecycleBin') {
          this.openCommonCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    }

  }
};
</script>