<template>
  <div class="source_sale_div">
    <div class="mendian_backstage_search"
         style="margin: 20px 10px;">
      <div class="btn_hover_bg_blue search_btn "
           @click.stop="chooseEdit('','','add')">添加</div>
    </div>
    <div class="source_sale_table"
         style="margin: 10px 10px 10px 20px;">
      <el-table :data="tableData"
                style="width: 100%;"
                border>
        <el-table-column label="名称"
                         prop="MainDemoName"
                         :align="'center'">
        </el-table-column>
        <el-table-column label="操作"
                         :align="'center'">
          <template slot-scope="scope">
            <el-button v-if="tableData[scope.$index].StateKey == 2"
                       @click.native.prevent="chooseEdit(scope.$index, tableData,'edit')"
                       type="text"
                       size="small">
              修改
            </el-button>
            <el-button @click.native.prevent="chooseEdit(scope.$index, tableData,'enable')"
                       type="text"
                       size="small">
              {{ tableData[scope.$index].StateKey== 2?'停用':'启用' }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { MessageSearchPageForSourceType, StopSourceType, StartSourceType } from '../../../API/workbench.js';
export default {
  data () {
    return {
      sellSourceList: [],
      // 查询客户来源
      kefulaiyuan: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称
        StateKey: 0 // 字符串	可选	0	数据源：GET	状态 2 启用 3停用
      },
      tableData: []
    };
  },
  created () {
    this.upSellSourceData();
  },
  methods: {
    // 更新客户来源数据
    upSellSourceData () {
      MessageSearchPageForSourceType(this.kefulaiyuan).then(result => {
        this.tableData = [];
        this.tableData = result.data.PageDataList;
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    chooseEdit (index, row, eventName) {
      console.log(index, row, eventName, '来源');

      switch (eventName) {
        case 'add':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '新增客户来源',
            moduleName: 'sellSourceSetting',
            data: { editItem: {}, isReadonly: false },
            callBack: {
              afterSuccess: () => { this.upSellSourceData(); }
            }
          });
          break;
        case 'view':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '查看客户来源',
            moduleName: 'sellSourceSetting',
            data: { editItem: row[index], isReadonly: true }
          });
          break;
        case 'edit':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '修改客户来源',
            moduleName: 'sellSourceSetting',
            data: { editItem: row[index], isReadonly: false },
            callBack: {
              afterSuccess: () => { this.upSellSourceData(); }
            }
          });
          break;
        case 'enable':
          let fn = '';
          let title = row[index].StateKey == 2 ? '停用' : '启用';
          if (row[index].StateKey == 2) {
            fn = StopSourceType;
          } else {
            fn = StartSourceType;
          }
          layer.confirm('是否' + title + '该客户来源?', {
            btn: [{
              name: '确认',
              callBack: () => {
                layer.close();
                fn(row[index].OLAPKey).then(result => {
                  layer.alert('更新成功');
                  this.upSellSourceData();
                }, error => {
                  layer.alert(error.msg);
                });
              }
            }
            ]
          });
          break;
        default:
          break;
      };
    }
  }
};
</script>
<style scoped>
.source_sale_table >>> .el-table .el-table__cell {
  padding: 0 !important;
}
</style>