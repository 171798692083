<template>
  <!-- 通用列表 -->
  <custom-dialog :title="listTitle"
                 width="1213px"
                 :top="30"
                 class="class_staistics_list defult_dialog"
                 :class="vClassName"
                 :visible.sync="isShowListDialog"
                 :before-close="closeDialog">

    <span class="defult_dialog_header_btn fr"
          v-if="isShowExportBtn">
      <zx-Import-button @click="doExportInfoClick()"
                        :isSingleSubmit="true"
                        :btnClass="'btn_fixed_Import'"
                        :singleSubmitType="'static'"
                        style="margin-left: 22px;"
                        :btnText="'导出'"></zx-Import-button>
    </span>

    <div class="table_list_content">
      <div class="single_result_detail">
        <!-- 搜索栏 -->
        <div class="table_searchBar detail_info_searchBar single_shadow"
             v-if="vIsSearchText">
          <!-- 搜索条件(单行、多行) -->
          <div class="table_searchBar_inputList">
            <!-- 普通搜索 -->
            <div class="detail_info_searchInput">
              <input type="text"
                     :placeholder="vPlaceholder"
                     class
                     v-model="searchText">
            </div>
          </div>
          <!-- 展开、收起 -->
          <div class="expend_btn"
               v-if="extendSearchCondition.length>0">
            <span class="expend_down"
                  v-if="!isShowMoreSearchCondition"
                  @click.stop="showMoreSearchCondition">展开</span>
            <span class="expend_up"
                  v-if="isShowMoreSearchCondition"
                  @click.stop="hideMoreSearchCondition">收起</span>
          </div>
          <!-- 搜索按钮 -->
          <div class=" search_btn ">
            <el-button class="btn_light_blue btn_hover_bg_blue"
                       @click.stop="searchBtn">搜索</el-button>
            <el-button class="btn_light_blue btn_hover_bg_blue el-icon-delete"
                       v-if="btnDelete"
                       @click.stop="btnDeleteList(1)">回收站 : {{hiddenNumber}}</el-button>
          </div>
          <!-- 按钮组 （自定义多个 eg:新增 导入 导出 批量 ？？以对象接受 还是 多个值控制）-->
          <div class="search_btn_group  search_btn  fr"
               v-if="functionBtnGroup">
            <el-button class="btn_light_blue btn_hover_bg_blue"
                       v-for="(item,index) in functionBtnGroup"
                       :style="item.icon?'':'padding-left:0px'"
                       :key="index"
                       :class="[vHasModulePower(item.ModulePowerKey)?'':'norole',item.icon]"
                       @click.stop="actionBtnClick(item)">{{item.name}}</el-button>
          </div>
          <!-- 展开、收起内容 里面有多个查询条件 日期 下拉列表 数字范围 -->
          <div v-if="isShowMoreSearchCondition"
               class="add_screening_condition clearfix">
            <extend-search-condition-group ref="searchConditionGroup"
                                           :extendSearchCondition="extendSearchCondition"
                                           @getSelectedParamsByClickSearchBtn="getSelectedParamsByClickSearchBtn"></extend-search-condition-group>
          </div>
        </div>
        <!-- 表格 -->
        <div class=" single_shadow"
             :class="{table_content:vIsSearchText}">
          <!-- 筛选条件 -->
          <div class="screening_condition clearfix">
            <div>
              <div class="screening_condition_number fl"
                   v-if="isLoading">
                <img src="../../../public/image/screening_condition_number_icon02.gif"
                     alt> 正在获取数据 , 请稍等...
              </div>
              <div class="screening_condition_number fl"
                   v-if="!isLoading">
                <img src="../../../public/image/screening_condition_number_icon01.png"
                     alt> 符合条件 : 共
                <span>{{totalNum}}</span> 条。
              </div>
            </div>
            <!-- 已选条件 -->
            <div v-if="selectedParams.length>0">
              <selected-conditions-group ref="selectedParamsGroup"
                                         v-model="selectedParams"
                                         :extendSearchCondition="extendSearchCondition"
                                         @changeExtendSearchParams="doAfterChangeExtendSearchParams"></selected-conditions-group>
              <el-button class="btn_light_white btn_hover_white fr"
                         @click.stop="clearExtendSearchParams">清除条件</el-button>
            </div>
          </div>
          <!-- 
            选中行高亮  highlight-current-row 
            隔行换色  stripe
            数据为空展示的文字  empty-text
            选中行 复选框联动  toggleRowSelection
          -->
          <div class="course_statistics_table_box table_list_content">
            <table-list ref="multipleTable"
                        :key="tableKey"
                        :tableData="tableData"
                        :tableColumns="tableColumns"
                        :PageCount="PageCount"
                        :totalNum="totalNum"
                        :defaultSort="defaultSort"
                        :queryParams="queryParams"
                        :isSearchText="false"
                        :isNeedInnerScroll="false"
                        :rowKey="rowKey"
                        :expandRowData="expandRowData"
                        @loadTableData="loadTableData"></table-list>
          </div>
          <!-- <el-table class="table_list"
                    ref="multipleTable"
                    :data="vtableData"
                    highlight-current-row
                    stripe
                    empty-text
                    toggleRowSelection
                    @sort-change="sortChange"
                    @row-click="clickRow"
                    :row-key="rowKey||'none'"
                    :expand-row-keys="expandRowKeys"
                    style="width: 100%"
                    :show-summary="summary.isShowSummary"
                    :summary-method="getSummaries">
            <el-table-column fixed
                             width="5"
                             v-if="true"></el-table-column>
            <el-table-column v-for="(item,index) in vTableColumns"
                             :fixed="item.fixed||false"
                             :width="item.width"
                             :sortable="item.sortable"
                             :align="item.align"
                             :key="index"
                             :type="item.type"
                             :label="item.label"
                             :prop="item.prop"
                             :render-header="item.renderHeader"
                             :show-overflow-tooltip="true&&(!item.type||item.type!='popUp')"
                             :label-class-name="item.columnClass">
              <el-table-column v-for="(childItem,childIndex) in item.childColumn"
                               :key="childIndex"
                               :fixed="childItem.fixed"
                               :width="childItem.width"
                               :sortable="childItem.sortable"
                               :align="childItem.align"
                               :type="childItem.type"
                               :label="childItem.label"
                               :prop="childItem.prop"
                               :show-overflow-tooltip="true"
                               :label-class-name="item.columnClass">
                <template slot-scope="scope">
                  <span v-if="childItem.type=='number'">{{rounding(scope.row,childItem.prop,childItem.decimalPlace)}}</span>
                  <span v-else-if="item.type=='date'">{{convertDateformat(scope.row,item.prop,item.dateType)}}</span>
                  <el-button v-else-if="childItem.type=='tableBtn'"
                             v-for="(info,indexs) in childItem.tableBtnGroup"
                             :class="[info.className(scope.row[info.Stutas]),vHasModulePower(info.ModulePowerKey)?'':'norole']"
                             class="tableBtn"
                             :key="indexs"
                             size="mini"
                             @click="tableBtnClick(scope.row,info)">{{info.eventName == 9?ifBtnName(scope.row):info.name}}</el-button>
                  <span v-else>{{scope.row[childItem.prop]}}</span>
                </template>
              </el-table-column>
              <template slot-scope="scope"
                        v-if="!item.childColumn">
                <span v-if="item.type=='number'">{{rounding(scope.row,item.prop,item.decimalPlace)}}</span>
                <span v-else-if="item.type=='date'">{{convertDateformat(scope.row,item.prop,item.dateType)}}</span>
                <el-button v-else-if="item.type=='tableBtn'"
                           v-for="(info,indexs) in item.tableBtnGroup"
                           :class="[info.className(scope.row[info.Stutas]),vHasModulePower(info.ModulePowerKey)?'':'norole']"
                           :style="!scope.row.StudentKey&&!scope.row.OLAPKey?'opacity: 0;cursor: default':''"
                           class="tableBtn"
                           :key="indexs"
                           size="mini"
                           @click="tableBtnClick(scope.row,info)">{{info.eventName == 9?ifBtnName(scope.row):info.name}}</el-button>
                <span v-else-if="item.type=='popDialog'"
                      :class="item.className"
                      @click="editContentBtnClick(scope.row)">{{scope.row[item.prop]}}</span>
                <span v-else-if="item.type=='lineFeed'"
                      :class="item.className"
                      v-html="scope.row[item.prop]"></span>
                <span v-else-if="item.type=='popUp'">
                  <el-popover v-if="scope.row.sourceData.length>2"
                              trigger="hover"
                              placement="top">
                    <p v-for="(O,index) in scope.row.sourceData"
                       :key="index">{{ O }}</p>
                    <div slot="reference"
                         class="name-wrapper">
                      <div size="">{{ scope.row.sourceData[0] }}</div>
                      <div class="list_down">{{ scope.row.sourceData[1] }}</div>
                    </div>
                  </el-popover>
                  <div v-else
                       slot="reference"
                       class="name-wrapper">
                    <div>{{ scope.row.sourceData[0]}}</div>
                    <div>{{ scope.row.sourceData[1]}}</div>
                  </div>
                </span>
                <span v-else
                      :class="item.className">{{scope.row[item.prop]}}</span>
              </template>
            </el-table-column>
            <el-table-column fixed="center"
                             type="expand">
              <template slot-scope="props">
                <el-form label-position="left"
                         inline
                         class="table_expand_item demo-table-expand">
                  <el-form-item :label="item.label"
                                v-for="(item,index) in detailColumns"
                                :key="index">
                    <span :title="props.row[item.prop]">{{ props.row[item.prop] }}</span>
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column>
            <div slot="empty"
                 style="min-height:500px;line-height:500px;">
              <i class="el-icon-warning-outline">
                <div class="monitoring_content_nodata"></div>
                亲，当前没有任何数据哦~
              </i>
            </div>
          </el-table>
          <div class="float-scroll"
               v-if="isNeedInnerScroll&&isShowInnerScroll"
               @scroll="scrollEvent">
            <div class="scroll_content"></div>
          </div>
          <div class="table_pagination">
            <el-pagination @current-change="handleCurrentChange"
                           :page-size="10"
                           :pager-count="5"
                           :current-page.sync="vPageIndex"
                           background
                           layout="prev, pager, next, jumper"
                           :total="totalNum>0?totalNum:10"></el-pagination>
          </div> -->
        </div>
      </div>
    </div>
  </custom-dialog>
</template>
<script>
import zxImportButton from '../common/zx-Import-button';
import extendSearchConditionGroup from './extend-search-condition-group/index';
import selectedConditionsGroup from './selected-conditions-group/index';
import tableList from '../common/table-list/index';
let initQueryData = {
  pageIndex: 0,
  pageSize: 10,
  searchText: '',
  orderBy: '',
  sequence: ''
};
export default {
  data () {
    return {
      searchText: '',
      queryParams: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: ''
      },
      tableKey: '0',
      defaultSort: { prop: '', order: 'ascending' },
      isShowListDialog: false, // 是否显示主界面
      isShowMoreSearchCondition: false, // 是否显示更多查询条件
      selectedRow: {}, // 选中行
      isLoading: true, // 正在加载
      selectedParams: [], // 扩展查询参数 [{name:'参数名',value:'值'}]
      searchConditionType: {}, // 扩展查询条件类型
      expandRowKeys: [], // 展开的行,

      isShowInnerScroll: true
    };
  },
  props: {
    listTitle: {
      // 列表头
      type: String,
      required: true
    },
    // queryParamsForList: Object,
    extendSearchCondition: {
      type: Array,
      default: function () {
        return [
          {
            type: 0, // 控件类型（js->initJson->extendSearchConditionType{ "dateRange": 1 //日期范围,"selectOption": 2//下拉选择,"numberRange": 3//数字范围}）
            formTitle: '', // 标题名
            fieldList: [
              {
                name: '', // 参数名
                defaultValue: '', // 默认值
                value: '', // 绑定值
                validateHandle: '', // 自定义验证函数
                sourceData: [] // 数据源（下拉选择需要 默认为空数组）
              }
            ]
          }
        ];
      }
    }, // 扩展查询条件
    functionBtnGroup: Array, // 功能按钮组
    tableData: Array, // 列表数据
    detailColumns: Array, // 显示详情内容
    rowKey: String, // 显示详情对应绑定的ID
    totalNum: Number, // 列表总共条数
    PageCount: Number, // 共几页
    tableColumns: Array, // table column
    btnDelete: false, // 回收站显示隐藏
    hiddenNumber: 0, // 回收站总数据
    isSearchText: Number, // 是否显示所有查询条件，包括搜索框
    top: 0,
    summary: {
      type: Object,
      default: function () {
        return {
          isShowSummary: false,
          needSummariesColumns: [],
          summaryName: '合计',
          summaryData: []
        };
      }
    },
    isNeedInnerScroll: {
      type: Boolean,
      default: false
    },
    isShowExportBtn: {
      type: Boolean,
      default: false
    },
    sarchPlaceholder: {
      type: String
    },
    expandRowData: {
      type: Array,
      default: () => {
        return [];
      }
    }
    // defaultSort: Object

  },
  components: {
    extendSearchConditionGroup,
    selectedConditionsGroup,
    zxImportButton,
    tableList
  },
  computed: {
    vClassName () {
      if (this.$zxClient.IsClient) {
        return 'exception_client'
      } else {
        return 'exceptionGradedialog'
      }
    },
    vPlaceholder () {
      if (this.sarchPlaceholder) {
        return this.sarchPlaceholder;
      } else {
        return '请输入姓名、姓名首字母、手机号';
      }
    },
    // vtableData () {
    //   let items = {
    //     StudentKey: '',
    //     OLAPKey: '',
    //     isEmpertyRow: true
    //   };
    //   let arr = [];
    //   let oldTableDataLength = this.$utils.parseJson(this.tableData.length);
    //   if (this.tableData.length < this.queryParams.pageSize && this.tableData.length > 0) {
    //     arr = this.tableData;
    //     for (let index = 1; index <= (this.queryParams.pageSize - oldTableDataLength); index++) {
    //       arr.push(items);
    //     }
    //     console.log(this.tableData, 'tableData');
    //     return arr;
    //     // return this.tableData;
    //   } else {
    //     return this.tableData;
    //   }
    // },
    modulePowerList () {
      return this.$store.getters.RolePowerList;
    },
    vIsSearchText () {
      if (this.isSearchText && this.isSearchText == -1) {
        return false;
      } else {
        return true;
      }
    },
    vPageIndex: {
      get () {
        return this.queryParams.pageIndex + 1;
      },
      set (n, o) {
      }
    },
    vTableColumns () {
      let newTableColumns = [];
      this.tableColumns.forEach(element => {
        if (element.isShow) {
          newTableColumns.push(element);
        }
      });
      return newTableColumns;
    }
  },
  created () {
    console.log('created:tableData', this.tableData);
    this.searchConditionType = this.$initJson.extendSearchConditionType;
    this.initQueryParams();
  },
  mounted () {
    if (this.isNeedInnerScroll) {
      setTimeout(() => {
        this.caculInnerScroll();
      }, 600);
    }
  },
  watch: {
    tableData () {
      // console.log('tableData', this.tableData);
    }
  },
  methods: {
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      ModulePowerKey = ModulePowerKey || '';
      return this.$utils.CheckModulePower(this.modulePowerList, ModulePowerKey);
    },
    // 首次打开 初始化查询参数（对外开放）
    initSelectedParams (selectedParams) {
      this.getSelectedParamsByClickSearchBtn(selectedParams);
    },
    // 构建查询参数
    initQueryParams () {
      console.log(this.queryParams.pageSize, 'initQueryParams');
      initQueryData.pageSize = this.queryParams.pageSize || 10;
      this.queryParams = this.$utils.parseJson(initQueryData);
      this.extendSearchCondition.forEach(condition => {
        condition.fieldList.forEach((param, index) => {
          this.queryParams[param.name] = param.defaultValue;
        });
      });
    },
    // 点击查询按钮
    searchBtn () {
      this.searchText = this.searchText.replace(/ /g, '');
      // 若更多查询条件 显示 重新收集已选参数
      if (this.isShowMoreSearchCondition) {
        this.$refs.searchConditionGroup.getSelectedParams(this.selectedParams);
        // 反之 直接查询
      } else {
        this.queryParams.pageIndex = 0;
        this.queryParams.searchText = this.searchText;
        this.$emit('loadTableData', this.queryParams);
      }
      this.$refs.multipleTable.setCurrentRow();
    },
    // 点击查询后 获取已选参数条件 并且拼接查询参数 获取列表
    getSelectedParamsByClickSearchBtn (selectedParams) {
      this.initQueryParams();
      this.selectedParams = selectedParams;
      for (var index in selectedParams) {
        this.queryParams[selectedParams[index].name] =
          selectedParams[index].value;
      }
      this.isShowMoreSearchCondition = false;
      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.setCurrentRow();
      }
      this.queryParams.searchText = this.searchText;
      this.$emit('loadTableData', this.queryParams);
    },
    // 显示更多查询条件
    showMoreSearchCondition () {
      this.isShowMoreSearchCondition = true;
      this.$nextTick(() => {
        this.$refs.searchConditionGroup.showExtendSearchCondition(
          this.selectedParams
        );
      });
    },
    // 跳转到第几页
    handleCurrentChange (val) {
      this.selectedRow = {};
      this.$refs.multipleTable.setCurrentRow();
      this.queryParams.pageIndex = val - 1;
      this.$emit('loadTableData', this.queryParams);
    },
    // 保留小数位数
    rounding (row, value, decimalPlace) {
      if (!row[value]) {
        return '';
      } else {
        return Number(row[value]).toFixed(decimalPlace);
      }
    },
    // 排序
    sortChange (column, prop) {
      if (column.prop) {
        this.queryParams.orderBy = column.prop;
        this.queryParams.sequence = column.order == 'ascending' ? 'asc' : 'desc';
      }
      this.$emit('loadTableData', this.queryParams);
    },
    // 隐藏更多查询条件
    hideMoreSearchCondition () {
      this.isShowMoreSearchCondition = false;
    },
    // 删除某个已选条件 重构queryParams重新刷新
    doAfterChangeExtendSearchParams () {
      this.tableColumns.forEach(o => {
        if (o.label == '操作') {
          if (o.extend.tableBtnGroup[0].name == '恢复') {
            o.extend.tableBtnGroup[0].name = '隐藏';
          }
        }
      });
      this.getSelectedParamsByClickSearchBtn(this.selectedParams);
    },
    // 清除已选条件栏  需重新刷新
    clearExtendSearchParams () {
      this.selectedParams = [];
      this.tableColumns.forEach(o => {
        if (o.label == '操作') {
          if (o.extend.tableBtnGroup[0].name == '恢复') {
            o.extend.tableBtnGroup[0].name = '隐藏';
          }
        }
      });
      this.getSelectedParamsByClickSearchBtn(this.selectedParams);
    },
    // 关闭窗口
    closeDialog () {
      this.isShowListDialog = false;
      this.searchText = '';
      this.isShowMoreSearchCondition = false;
      this.expandRowKeys = [];
      this.$emit('closeTableList');
      this.initQueryParams();
    },
    // 点击功能按钮
    actionBtnClick (item) {
      if (this.vHasModulePower(item.ModulePowerKey)) {
        this.$emit('actionBtnClick', item.eventName);
      } else {
        this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
      }
    },
    // 点击表单按钮
    tableBtnClick (rowData, item) {
      console.log(item, 'tableBtnClick');
      if (!rowData.StudentKey && !rowData.OLAPKey) {
        return false;
      }
      if (this.vHasModulePower(item.ModulePowerKey)) {
        if (item.eventName == this.$initJson.baseFunctionBtnName.detail) {
          if (rowData[this.rowKey] == this.expandRowKeys[0]) {
            this.expandRowKeys = [];
          } else {
            this.expandRowKeys = [];
            this.expandRowKeys.push(rowData[this.rowKey]);
          }
        } else {
          if (this.vHasModulePower(item.ModulePowerKey)) {
            this.$emit('tableBtnClick', rowData, item.eventName);
          } else {
            this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
          }
        }
      } else {
        this.$utils.CheckModulePowerToTips(item.ModulePowerKey);
      }
    },
    // 选中行
    clickRow (row, column, event) {
      this.selectedRow = row;
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row, true);
    },
    // 回收站数据切换
    btnDeleteList (IsHiddenKey) {
      let HiddenKey = true;
      this.selectedParams.forEach(e => {
        if (e.name == 'IsHiddenKey' && e.value == 1) {
          HiddenKey = false;
        }
      });
      if (HiddenKey) {
        this.selectedParams = [];
        this.selectedParams.push({ name: 'IsHiddenKey', value: 1 });
        this.tableColumns.forEach(o => {
          if (o.label == '操作') {
            if (o.extend.tableBtnGroup[0].name == '隐藏') {
              o.extend.tableBtnGroup[0].name = '恢复';
            }
          }
        });
        this.getSelectedParamsByClickSearchBtn(this.selectedParams);
      }
    },
    // 判定显示按钮名
    ifBtnName (data) {
      if (data.IsHiddenKey == 1) {
        return '恢复';
      } else {
        return '隐藏';
      }
    },
    getSummaries (param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.summary.summaryName;
          return;
        }
        let findItem = this.summary.needSummariesColumns.find(o => {
          return o.prop == column.property;
        });
        if (findItem) {
          // 非数字类型 一般统计个数
          if (findItem.SumType == 'string') {
            sums[index] = this.summary.summaryData.length + findItem.unit;
          } else {
            const values = this.summary.summaryData.map(item => Number(item[column.property]));
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += findItem.unit;
            }
          }
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
    caculInnerScroll () {
      // table的宽度
      let tableWidth = document.getElementsByClassName('el-table table_list')[0] ? document.getElementsByClassName('el-table table_list')[0].clientWidth : 0;
      // table内容的宽度
      let contentWidth = document.getElementsByClassName('el-table__body')[0] ? document.getElementsByClassName('el-table__body')[0].clientWidth : 0;
      // table 左边固定宽
      let leftFix = document.getElementsByClassName('el-table__fixed')[0] ? document.getElementsByClassName('el-table__fixed')[0].clientWidth : 0;
      // table 右边固定宽
      let rightFix = document.getElementsByClassName('el-table__fixed-right')[0] ? document.getElementsByClassName('el-table__fixed-right')[0].clientWidth : 0;
      // 合计的高度
      let countColumnHeight = document.getElementsByClassName('el-table__fixed-footer-wrapper')[0] ? document.getElementsByClassName('el-table__fixed-footer-wrapper')[0].clientHeight : 0;

      if (tableWidth >= contentWidth) {
        this.isShowInnerScroll = false;
      } else {
        this.isShowInnerScroll = true;
        if (document.getElementsByClassName('el-table__body-wrapper')[0]) {
          document.getElementsByClassName('el-table__body-wrapper')[0].style.overflowX = 'hidden';
        }
        this.$nextTick(() => {
          document.getElementsByClassName('scroll_content')[0].style.width = contentWidth + 'px';

          document.getElementsByClassName('float-scroll')[0].style.width = (tableWidth - leftFix - rightFix) + 'px';
          document.getElementsByClassName('float-scroll')[0].style.marginLeft = leftFix + 'px';
          document.getElementsByClassName('float-scroll')[0].style.marginTop = -countColumnHeight + 'px';
        });
      }
    },
    scrollEvent (e) {
      let ETarget = e.target;
      // table的宽度
      let tableWidth = document.getElementsByClassName('el-table table_list')[0].clientWidth;
      // table内容的宽度
      let contentWidth = document.getElementsByClassName('el-table__body')[0].clientWidth;
      // 左边固定宽
      let leftFix = document.getElementsByClassName('el-table__fixed')[0].clientWidth;
      // 右边固定宽
      let rightFix = document.getElementsByClassName('el-table__fixed-right')[0].clientWidth;
      // 滚动条外框的宽度
      document.getElementsByClassName('float-scroll')[0].style.width = (tableWidth - leftFix - rightFix) + 'px';

      document.getElementsByClassName('el-table__body-wrapper')[0].scrollLeft = (ETarget.scrollLeft / (contentWidth / (contentWidth - leftFix - rightFix))) - 120;
    },
    // 日期转换
    convertDateformat (row, value, type) {
      if (type == 'YYMMDD') {
        return this.$utils.formatDateToLine(Date.new(row[value]));
      } else {
        return row[value];
      }
    },
    // 导出
    doExportInfoClick () {
      this.$emit('doExportInfoClick');
    },
    loadTableData () {
      this.$emit('loadTableData', this.queryParams);
    }
  }
};
</script>
<style  scoped>
.exception_client >>> .el-table__body-wrapper .el-table__body {
  width: 1173px !important;
}
.exceptionGradedialog >>> .el-table__body-wrapper .el-table__body {
  width: 104.5% !important;
}

.exceptionGradedialog >>> .el-table__header-wrapper .el-table__header,
.exception_client >>> .el-table__header-wrapper .el-table__header {
  width: 104.5% !important;
}
.exceptionGradedialog >>> .el-table__body-wrapper,
.exception_client >>> .el-table__body-wrapper {
  width: 104% !important;
}
</style>

