<template>
  <!-- 设置表单 -->
  <div class="general_class_type_list"
       :class="{opt:item.isOpt}">
    <span @click.stop="onLetterClick(item)">{{item.value}}</span>
  </div>
</template> 

<script>

export default {
  data () {
    return {
    };
  },
  props: {
    item: Object
  },
  components: {

  },

  created () {

  },
  mounted () {

  },
  activated () {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    onLetterClick (item) {
      if (item.isOpt) {
        this.$emit('onLetterClick', item);
      }
    }
  }

};
</script>

  <style>
/*  */
</style>