<template>
  <!-- 待处理列表 -->
  <div class=" table_list_content pr summarizing_list pending_processed">
    <div class="single_result_detail">
      <!-- 搜索 -->
      <div class="table_select_box"
           style="padding: 20px 0px;">
        <div class="table_select_box"
             style="margin-right: 20px;width: 240px;">
          <div class="table_select_title">状态筛选</div>
          <el-select v-model="selectedStateScreenAccount"
                     filterable
                     placeholder="必选"
                     @change="chgSearchValue"
                     value-key="OLAPKey">
            <el-option v-for="item in stateScreenList"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item"></el-option>
          </el-select>
        </div>
        <inputSearchContainBtn v-model="queryParams.searchText"
                               :width="'280px'"
                               :placeholder="'请输入校区名、账户ID'"
                               @onSearchEvent="search"
                               @clearSearch="clearExtendSearchParams"></inputSearchContainBtn>

      </div>
      <!-- 通用列表 -->
      <table-list ref="tableListRef"
                  :tableData="mendianList"
                  :tableColumns="tableColumns"
                  :rowKey="'Number'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  @loadTableData="getAddressLogListData"></table-list>
    </div>
    <div class="course_student_attendance_operation module_content_menuList"
         :style="{'top':topValue+'px','left':'auto','right': 4+'px'}"
         v-if="isShowOperateBtn">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="operateBtnList"
                     @clickOptionForAlertDialog="onOperateClick"></drop-down-box>
    </div>

    <custom-dialog :title="'地理位置'"
                   :visible.sync="isShowLocationForm"
                   :before-close="closeLocationFormPopup">
      <location-pop :locationInfo="locationInfo"
                    @close="closeLocationFormPopup"></location-pop>
    </custom-dialog>
  </div>
</template>
  <script>
import { getAddressLogList, AuditSaaSClientAddressLog, RefuseSaaSClientAddressLog, CheckSaaSClientAddressLog } from '../../../API/workbench.js';
import tableList from '../../common/table-list/index';
import dropDownBox from '../../common/drop-down-box';
import inputSearchContainBtn from '../../report/common/input-search-contain-btn.vue';
import locationPop from './location-map-pop';
export default {
  data () {
    return {
      selectedStateScreenAccount: '全部',
      stateScreenList: [
        {
          OLAPKey: 0,
          MainDemoName: '全部'
        },
        {
          OLAPKey: 2,
          MainDemoName: '已失效'
        },
        {
          OLAPKey: 3,
          MainDemoName: '已拒绝'
        },
        {
          OLAPKey: 4,
          MainDemoName: '已通过'
        },
        {
          OLAPKey: 5,
          MainDemoName: '已处理'
        }
      ],
      // 查询参数
      queryParams: {
        pageIndex: 0, //	整型	可选		数据源：GET	页码
        pageSize: 10, //	整型	可选		数据源：GET	分页大小
        searchText: '', //	字符串	可选		数据源：GET	关键字
        orderBy: '',
        sequence: '',
        IsConfirmKey: '',
        TypeKey: ''
      },
      locationInfo: [
        { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '14' }, // 定位地址信息
        { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '15' }, // 定位经纬度信息
        { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '16' }// 定位截图信息
      ],
      nowRowData: {},
      mendianList: [], // 门店列表
      operateBtnList: [],
      topValue: 0,
      tableColumnsData: [],
      tableColumns: [
        {
          label: '发生时间',
          prop: 'GenerateTime',
          width: 150,
          align: 'left',
          type: 'text-item',
          sortable: false,
          extend: {
            className: 'sta_time'
          }
        },
        {
          label: '校区ID',
          prop: 'SaaSClientNO',
          width: 100,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '校区名',
          prop: 'SaaSClientKeyValue',
          align: 'left',
          width: 200,
          type: 'text-item',
          sortable: false,
          extend: {
            className: 'sta_saa'
          }
        },
        {
          label: '总部定位地址',
          prop: 'HQAddress',
          align: 'left',
          width: 300,
          type: 'text-item',
          sortable: false,
          extend: {
            className: 'sta'
          }
        },
        {
          label: '新定位地址',
          prop: 'LocationText',
          align: 'left',
          type: 'text-item',
          sortable: false,
          extend: {
            className: 'font_blue sta',
            type: 'expand',
            // mendianID: 'SaaSClientNO',
            click: (rowData) => {
              this.showLocationFormPopup(rowData, 'new');
            }
          }
        },
        {
          label: '状态',
          prop: 'StatusKeyValue',
          align: 'left',
          width: 100,
          type: 'text-item',
          sortable: false
        },
        {
          label: '操作',
          prop: 'option',
          width: 100,
          type: 'table-btn',
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: () => {
                  return '重新审核';
                },
                extend: {
                  isHide: (rowData) => {
                    return !(rowData.IsHQAuditKey == 1 && this.IsConfirmKey == 1 && rowData.IsAudit == 1);
                  },
                  click: (rowData) => {
                    this.operateBtnEvent(rowData);
                  },
                  ModulePowerKey: ''
                }
              }
            ]
          }
        }
      ],
      pageTotal: 0,
      totalNum: 0,
      defaultSort: { prop: 'studentInfo', order: 'ascending' },
      isShowOperateBtn: false,
      isShowLocationForm: false
    };
  },
  components: {
    tableList,
    dropDownBox,
    inputSearchContainBtn,
    locationPop
  },
  props: {
    IsConfirmKey: {
      type: Number,
      default: 0
    }
  },
  computed: {
    vAutoProcessed () {
      return this.$utils.getSaaSClientSetInfo(87).SetKey == 1;
      // return true;
    }
  },
  watch: {
    //   IsConfirmKey: {
    //     handler (c, o) {
    //       if (c) {
    //         console.log('监听到切换');
    //         this.queryParams.IsConfirmKey = this.IsConfirmKey == 2 ? 0 : 1;
    //         this.queryParams.pageIndex = 0;
    //         this.queryParams.searchText = '';
    //         this.queryParams.pageSize = 10;
    //         this.getAddressLogListData(this.queryParams);
    //       }
    //     },
    //     deep: true
    //   }
  },
  mounted () {
    this.queryParams.IsConfirmKey = this.IsConfirmKey == 2 ? 0 : 1;

    this.getAddressLogListData(this.queryParams);
    document.querySelector('body').addEventListener(
      'click',
      (e) => {
        this.isShowOperateBtn = false;
      },
      false
    );
  },
  created () {
  },
  methods: {
    // 状态筛选
    chgSearchValue (item) {
      console.log(item, 'item');
      this.queryParams.TypeKey = item.OLAPKey;
      this.selectedStateScreenAccount = item.MainDemoName;
      this.getAddressLogListData(this.queryParams);
    },
    // 处理列表
    getAddressLogListData (queryParams) {
      queryParams.searchText = queryParams.searchText.trim();
      getAddressLogList(queryParams).then(result => {
        this.isShowOperateBtn = false;
        console.log('处理列表', result.data, queryParams.IsConfirmKey);
        this.totalNum = result.data.Total; // 分页数
        this.mendianList = result.data.PageDataList || [];
        if (this.mendianList.length > 0) {
          this.mendianList.forEach(o => {
            o.PrimaryLocationText = (o.PrimaryLocation != '' ? `<img src='` + require('../../../../public/image/class_icon02_b.png') + `' style='margin-right:5px;width: 15px;
      height: 21px;' />` : `<img src='` + require('../../../../public/image/contact_site_icon.png') + `'  style='margin-right:5px;width: 15px;
      height: 21px;' />`) + o.PrimaryLocation;
            o.LocationText = (o.Location != '' ? `<img src='` + require('../../../../public/image/class_icon02_b.png') + `'  style='margin-right:5px;width: 15px;
      height: 21px;' />` : `<img src='` + require('../../../../public/image/contact_site_icon.png') + `'  style='margin-right:5px;width: 15px;
      height: 21px;' />`) + o.Location;
          });
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 搜索
    search (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.queryParams.pageIndex = 0;
      this.getAddressLogListData(this.queryParams);
    },
    // 清除条件
    clearExtendSearchParams () {
      this.queryParams.TypeKey = 0;
      this.selectedStateScreenAccount = '全部';
      this.queryParams.IsConfirmKey = this.IsConfirmKey == 2 ? 0 : 1;
      this.queryParams.pageIndex = 0;
      this.queryParams.searchText = '';
      this.queryParams.pageSize = 10;
      this.getAddressLogListData(this.queryParams);
    },
    // 排序
    loadTableData (queryParams) {
      console.log(queryParams, '排序');
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.getAddressLogListData(this.queryParams);
    },
    // 操作菜单
    operateBtnEvent (rowData) {
      let findIndex = this.mendianList.findIndex((o) => {
        // 找到该项菜单点击下标
        return Number(o.OLAPKey) == Number(rowData.OLAPKey);
      });
      this.nowRowData = rowData;
      let index = findIndex + 1;
      this.topValue = 112 + index * 63;
      if (this.IsConfirmKey == 1) {
        this.operateBtnList = [
          { type: 'pass', name: '审核通过', isShow: true },
          { type: 'refuse', name: '审核拒绝', isShow: true }
        ];
      } else {
        this.operateBtnList = this.vAutoProcessed ? [
          { type: 'pass', name: '审核通过', isShow: true },
          { type: 'refuse', name: '审核拒绝', isShow: true }
        ] : [{ type: 'check', name: '查看', isShow: true }];
      }

      this.isShowOperateBtn = true;
    },
    // 操作菜单
    onOperateClick (item) {
      // this.threadInfo = this.$utils.parseJson(rowData);
      // this.threadInfo.SaaSClientTypeKey = 2;
      this.isShowOperateBtn = false;
      console.log(item, this.nowRowData, '操作菜单');
      switch (item.type) {
        case 'pass':// 审核通过
          console.log('审核通过');
          this.pass();
          break;
        case 'refuse':// 审核拒绝
          console.log('审核拒绝');
          this.refuse();
          break;
        case 'check':// 审核拒绝
          console.log('查看');
          this.check();
          // this.$emit('goCheck');
          break;
        default:
          break;
      }
    },
    pass () {
      AuditSaaSClientAddressLog(
        this.nowRowData.OLAPKey
      ).then(
        (res) => {
          layer.alert('已通过');
          this.getAddressLogListData(this.queryParams);
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    refuse () {
      RefuseSaaSClientAddressLog(
        this.nowRowData.OLAPKey
      ).then(
        (res) => {
          layer.alert('已拒绝');
          this.getAddressLogListData(this.queryParams);
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    check () {
      CheckSaaSClientAddressLog(
        this.nowRowData.OLAPKey
      ).then(
        (res) => {
          layer.alert('已查看');
          this.getAddressLogListData(this.queryParams);
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    closeLocationFormPopup () {
      this.isShowLocationForm = false;
    },
    showLocationFormPopup (rowData, type) {
      if (type == 'new') {
        if (rowData.Location == null || rowData.Location == '') {
          return;
        }
        this.locationInfo[1].SetContent = [rowData.Longitude, rowData.Latitude];
      } else {
        if (rowData.PrimaryLocation == null || rowData.PrimaryLocation == '') {
          return;
        }
        this.locationInfo[1].SetContent = [rowData.PrimaryLongitude, rowData.PrimaryLatitude];
      }
      this.isShowLocationForm = true;
    }
  }
};
  </script>
  <style scoped>
.super_management {
  margin-top: 90px;
}
.pending_processed .location_icon {
  width: 13px;
  height: 18px;
  margin-right: 10px;
  margin-top: -1px;
}

.pending_processed .location_icon1 {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  /* top: 10px; */
  margin-top: -1px;
}
.pending_processed >>> .sta {
  white-space: break-spaces !important;
  display: flex;
  align-items: center;
}

.pending_processed >>> .sta_time {
  white-space: break-spaces !important;
  margin-right: 46px;
  display: block;
  line-height: 18px;
}
.pending_processed >>> .sta_saa {
  white-space: break-spaces !important;
}
</style>
  
  