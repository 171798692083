<template>
	<div class="">
		<div class="form_info_fiexd_placeholder" v-if="isShowBtnTopFiexdBox">
			
		</div>
		<div class="form_info_fiexd_btn" @click.stop="doClick">
			<div class="popup_confirm_btn" :class="vBtnClass">
				{{vBtnText}}
			</div>
		</div>
	</div>
</template>

<script>

export default {
  data () {
    return {
      defaultBtnText: '确认',
      defaultBtnClass: '',
      lastClickTime: null,
      isSubmiting: false,
      isClick:true
    };
  },
  props: {
    btnText: String,
    btnClass: String, // 自定义搜索名
    isSingleSubmit: {
      type: Boolean,
      default: true
    }, // 是否启用单次点击
    singleSubmitType: {
      type: String,
      default: 'static'
    }, // static（根据两次点击的时间间隔）, dynamic（提交完成后 手动更改参数）
    isShowBtnTopFiexdBox: {
      type: Boolean,
      default: true
    }
  },
  components: {

  },
  computed: {
    vBtnClass () {
      return this.btnClass || this.defaultBtnClass;
    },
    vBtnText () {
      return this.btnText || this.defaultBtnText;
    }
  },
  created () {
  },
  methods: {
    doClick () {
        if(this.isClick){
          this.isClick=false
          setTimeout(o=>{
            this.isClick=true
          },2000)
          if (!this.isSingleSubmit || this.checkCanClick()) {
            this.$emit('click', () => {
              this.isSubmiting = false;
            });
          }
        
        }else{
          layer.alert('请勿重复点击')
          // this.$showModal({
          //   content: '请勿重复点击'
          // });
        }
      // if (!this.isSingleSubmit || this.checkCanClick()) {
      //   this.$emit('click', () => { this.isSubmiting = false; });
      // }
    },
    completeSubmit () {
      this.isSubmiting = false;
    },
    checkCanClick () {
      if (this.singleSubmitType == 'static') {
        const now = Date.new();
        const r = !this.lastClickTime || now - this.lastClickTime > 800;
        if (r) {
          this.lastClickTime = now;
        }
        return r;
      } else {
        const r = !this.isSubmiting;
        this.isSubmiting = true;
        return r;
      }
    }
  }
};
</script>

  <style>
</style>
