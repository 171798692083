<template>
  <div>
    <div class="add_growth_time_from form_info_list popup_height_from">
      <input-text :formTitle="'录入人'"
                  :readonly="true"
                  :formPlaceholder="'请输入申请人'"
                  class=""
                  :value="vTeacher"></input-text>
      <div class="new_course_time_from pr">
        <input-date v-model="sumitData.PublishTime"
                    :elementId="'classDate'"
                    :formTitle="'添加日期'"
                    :maxDate="$utils.formatDateToLine(Date.new())"
                    :formPlaceholder="'请选择'"
                    @changeDate="changeDate">
        </input-date>
      </div>
      <div class="from_list">
        <div class="from_list_title">
          <!-- {{formTitle}} -->
          <!-- <span>{{tipsTitle}}</span> -->
        </div>
        <div class="from_list_content">
          <div class="from_list_textarea">
            <textarea v-model="sumitData.Content"
                      class="font_blue"
                      :placeholder="'请输入成长报告内容'"
                      :contenteditable="true"></textarea>
          </div>
        </div>
      </div>
      <mien :imgList="sumitData.AttachList"
            @changeImg="changeMien"></mien>
      <div class="from_input_list"
           v-if="studentInfo.SubscribeKey==1">
        <div class="from_list_select flex"
             style="align-items: center;">
          <el-checkbox v-model="business"
                       :true-label="1"
                       :false-label="0"
                       @change="seletedbusiness">同时发送微信通知。{{vLastPushTime}}</el-checkbox>
        </div>
      </div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submitAdd"
                             @cancelClick="doAfterClickCancelBtn"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { AddStudentGrowthTime } from '../../../API/workbench';
import mien from './mien';
export default {
  data () {
    return {
      sumitData: {
        Content: '',
        PublishTime: '',
        AttachList: []
        // [{ // --附件列表
        //   'AttachTypeKey': '1', // - 图片; 2 - 视频; 3 - 音频; ”,
        //   'AttachUrl': '',
        //   'AudioSize': ''
        // }]
      },
      business: 0
    };
  },
  props: {
    courseInfo: Object,     // 选中的那条课程信息.
    studentInfo: Object    // 选中学生
  },
  created () {

  },
  computed: {
    vToken () {
      return this.$store.getters.token;
    },
    vTeacher () {
      if (this.vToken.UserName.indexOf('老师') >= 0) {
        return this.vToken.UserName;
      } else {
        return this.vToken.UserName + '老师';
      }
    },
    vLastPushTime () {
      if (this.studentInfo.LastPushTime) {
        return '(上一次通知时间' + this.studentInfo.LastPushTime + ')';
      } else {
        return '';
      }
    }
  },
  components: {
    mien
  },
  methods: {
    seletedbusiness () {

    },
    changeMien () {
      console.log(this.sumitData, 'changeMien');
    },
    changeDate (date) {
      console.log('changeDate', date);
    },
    // 提交新增
    submitAdd (callback) {
      console.log('submit', this.sumitData);
      let isPass = true;
      if (this.sumitData.PublishTime == '') {
        layer.alert('请选择添加日期');
        isPass = false;
      } else if (this.sumitData.Content == '') {
        layer.alert('请输入内容');
        isPass = false;
      }
      let uploadingImgList = this.sumitData.AttachList.filter(obj => { return obj.AttachTypeKey == 1 && obj.AttachUrl.indexOf('data:image/') >= 0; });
      if (uploadingImgList.length > 0) {
        layer.alert('图片正在上传中，请稍后提交');
        if (callback) {
          callback();
        }
        return false;
      }
      if (!isPass) {
        if (callback) {
          callback();
        }
        return false;
      }
      AddStudentGrowthTime(this.studentInfo.OLAPKey, this.courseInfo.CourseNameKey, this.business, this.sumitData).then(result => {
        layer.alert('新增成功');
        this.studentInfo.GrowCount = Number(this.studentInfo.GrowCount) + 1;
        if (this.business == 1) {
          this.studentInfo.LastPushTime = this.$utils.formatDateToLine(Date.new().toLocaleDateString());
        } else {
          this.studentInfo.LastPushTime = '';
        }
        this.$emit('afterSuccess');
        this.doAfterClickCancelBtn();
        if (callback) {
          callback();
        }
      }).catch(error => {
        console.log('errEditDefaultReply', error);
      });
    },
    doAfterClickCancelBtn () {
      this.$emit('close');
    }
  }
};
</script>