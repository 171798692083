<template>
  <!-- 课程课类管理 -->
  <div class="course_class_manage">
    <module-description :textTips="'课类的功能是给课程分类，如果不需要分类可以不设置课类！注意课程课类不能重名！'"
                        :btnTips="'查看教程'"
                        :btnUrl="'https://mp.weixin.qq.com/s/5Ir8Z8Bswq-Dfcw_BMDmNQ'"></module-description>
    <div class="course_class_manage_title">
      <div class="manage_title_left">
        <span>课程分类</span>
        <span class="font_blue"
              style="cursor: pointer;"
              @click="onAddClassClassItemClick">新增课类</span>
      </div>
      <div class="manage_title_right">
        <span>所有课程</span>
        <span class="font_blue"
              style="cursor: pointer;"
              @click="onAddClassNameItemClick">新增课程</span>
      </div>
    </div>
    <div class="course_class_manage_content"
         ref="manageContent"
         style="position: relative;"
         @scroll="scrollTOP">
      <setting-content v-for="(item,index) in vcourseClassCourseNameList"
                       :key="index"
                       :courseClasBox="item"
                       :courseClasIndex="index"
                       :courseClassList="vcourseClassCourseNameList"
                       @onCourseClassOperateClick="doCourseClassOperateClick"
                       @oncourseNameItemClick="doOperate"
                       @doMouseEvent="doMouseEvent"></setting-content>
    </div>
    <!-- 课类操作菜单 -->
    <div v-if="isShowCourseTypeMenu">
      <div class="course_student_attendance_operation module_content_menuList"
           :style="'top:'+newOffsetHeight+'px'+';'+'left:120px'">
        <div class="top_bg"></div>
        <drop-down-box :optionJsonList="menuItemList"
                       @clickOptionForAlertDialog="doCourseClassMenuClick"></drop-down-box>
      </div>
    </div>
    <!-- 课名操作菜单 -->
    <div v-if="isShowCourseNameMenu"
         @mouseenter.stop="mouseOverNewCourseTable"
         @mouseleave.stop="mouseOutNewCourseTable">
      <div class="course_student_attendance_operation module_content_menuList"
           :style="'top:'+newOffsetHeight+'px'+';'+'left:' +newOffsetLeft+'px'">
        <div class="top_bg"></div>
        <drop-down-box :optionJsonList="menuItemList"
                       @clickOptionForAlertDialog="doCourseNameItemClick"></drop-down-box>
      </div>
    </div>

  </div>
</template> 

<script>

import {
  hqGetNotDelCourse,
  hqStopTheClassType // 停用 课名课类
} from '../../../../API/workbench';
import settingContent from './setting-content';
import dropDownBox from '../../../common/drop-down-box';
export default {
  data () {
    return {
      courseClassCourseNameList: [], // 课类列表
      options: {
        type: 'add',
        moduleName: '',
        data: {}
      }, // 操作类型
      menuItemList: [
        { type: 1, name: '查看', eventName: 'view', isShow: true, ModulePowerKey: '' },
        { type: 2, name: '修改', eventName: 'edit', isShow: true, ModulePowerKey: '' },
        { type: 3, name: '停用', eventName: 'unActive', isShow: true, ModulePowerKey: '' }
      ],
      newOffsetHeight: 0, // 元素纵向位置
      newOffsetLeft: 0, // 元素横向位置
      scrollETarget: { scrollTop: 0 }, // 滚动元素
      courseClassItem: {}, // 选择操作的课类
      isShowCourseTypeMenu: false,
      isShowCourseNameMenu: false, // 课程名称操作菜单
      courseInfo: {} // 课程信息

    };
  },
  props: [

  ],
  components: {
    settingContent,
    dropDownBox
    // courseClassSetting,
    // courseNameSetting
  },

  created () {
    this.getCourseTypeNameInfoList();
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isShowCourseTypeMenu = false;
    }, false);
    this.$dialog.register(this, ['communitySetting', 'sellSourceSetting']);
  },
  activated () {

  },
  watch: {

  },
  computed: {
    vcourseClassCourseNameList () {
      let courseClassList = [];// 课类列表
      let courseNameList = [];// 课名列表
      let notClassifiedList = {// 未分类课程列表
        OLAPKey: 0,
        MainDemoName: '未分类课程',
        buildCourseList: []
      };
      // StateKey:3为停用
      this.courseClassCourseNameList.forEach(o => {
        if (o.StateKey == 2) { // 只显示为启用的项
          this.$set(o, 'isMenu', false);
          if (o.TypeKey == 1) { // 课类列表
            courseClassList.push(o);
          } else { // 课名列表
            courseNameList.push(o);
          }
        }
      });
      // 未分类课程 构建
      courseNameList.forEach(p => {
        if (p.FatherKey == 2 || p.FatherKey == 0) {
          notClassifiedList.buildCourseList.push(p);
        }
      });
      // 课类下的课名构建
      courseClassList.forEach(o => {
        // o.buildCourseList = [];
        this.$set(o, 'buildCourseList', []);
        courseNameList.forEach(i => {
          if (o.OLAPKey == i.FatherKey) {
            o.buildCourseList.push(i);
          }
        });
      });
      courseClassList.unshift(notClassifiedList);
      console.log(courseClassList, '课类列表');
      return courseClassList;
    }
  },
  methods: {
    // 弹出框异常
    doCourseClassOperateClick (item, index, locationHeight) {
      let dialogHeight = 134;// 弹出层头部的高度
      this.newOffsetHeight = this.getElementHeight(index) + dialogHeight - locationHeight - this.scrollETarget.scrollTop;
      this.courseClassItem = item;
      this.isShowCourseNameMenu = false;
      this.isShowCourseTypeMenu = true;
    },
    //  课类=>显示操作类型
    doCourseClassMenuClick (options) {
      // if (this.courseClassItem.buildCourseList.length > 0 && options.eventName == 'unActive') {
      //   layer.alert('该课类下有未停用的课程时不能停用!');
      // } else {
      this.courseClassItem.isMenu = false;
      let obj = {
        type: options.eventName,
        moduleName: 'courseClassSetting',
        data: this.courseClassItem
      };
      this.doOperate(obj);
      // }
    },
    // 物品名称=>操作
    doCourseNameItemClick (options) {
      let obj = {
        type: options.eventName,
        moduleName: 'courseNameSetting',
        data: this.courseInfo
      };
      this.doOperate(obj);
    },
    // 课程移出移入-弹出框
    doMouseEvent (type, index, locationHeight, courseIndex, courseItem) {
      this.courseInfo = courseItem;
      let dialogHeight = 174; // 弹窗头部到第一个课程的高度
      let courseHeight = parseInt(courseIndex / 5) * 36;// 计算课程纵向所在的高度 每列高为38px
      this.newOffsetHeight = this.getElementHeight(index) + dialogHeight - locationHeight - this.scrollETarget.scrollTop + courseHeight;// 纵向位置
      this.newOffsetLeft = (courseIndex % 5) * 144 + 228;// 计算课程横向所在的偏移量 第一个默认是228，每个课程相距为144px 横向位置
      this.isShowCourseTypeMenu = false;
      // console.log(this.getElementHeight(index), '偏移量');
      if (type == 1) { // 移入
        this.mouseOverNewCourseTable();
      } else { // 离开
        this.mouseOutNewCourseTable();
      }
    },
    // 移入时课程继续高亮
    mouseOverNewCourseTable () {
      this.isShowCourseNameMenu = true;
    },
    // 移出时课程继续高亮
    mouseOutNewCourseTable () {
      this.isShowCourseNameMenu = false;
    },
    //  获取课类所在的高度
    getElementHeight (index) {
      let elementHeight = 0; // 元素的高度
      this.vcourseClassCourseNameList.forEach((o, i) => {
        if (i == 0) { // 第一条不包括新增操作的高度计算
          if (o.buildCourseList.length > 10) {
            elementHeight = Math.ceil((o.buildCourseList.length - 10) / 5) * 36 + 88;
          } else {
            elementHeight = 88;
          }
        }
        if (index >= i && i != 0) { // 其余的+新增操作项的高度计算
          if (o.buildCourseList.length + 1 > 10) {
            elementHeight += Math.ceil((o.buildCourseList.length + 1 - 10) / 5) * 36 + 86;
          } else {
            elementHeight += 86;
          }
        }
      });
      return elementHeight;
    },
    getCourseTypeNameInfoList () {
      hqGetNotDelCourse('OLAPKey', 'desc').then(result => {
        this.courseClassCourseNameList = result.data;
        console.log(this.courseClassCourseNameList, '获取课类课名列表');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 刷新课类/课名
    upCourseAndClassListData (item) {
      console.log(item, '刷新课类/课名');
      if (item.OLAPKey) {
        let findItem = this.courseClassCourseNameList.find(o => { // 找到该项
          return Number(o.OLAPKey) == Number(item.OLAPKey);
        });
        let findIndex = this.courseClassCourseNameList.findIndex(o => { // 找到该下标
          return Number(o.OLAPKey) == Number(item.OLAPKey);
        });
        console.log(findIndex, findItem, '是否找到');
        if (findItem && findItem.OLAPKey) {
          if (Number(findItem.OLAPKey) == Number(item.OLAPKey)) {
            if (item.StateKey == 3) {
              this.courseClassCourseNameList.splice(findIndex, 1);// 停用
            } else {
              Object.assign(findItem, item);// 修改
            }
          }
        } else {
          this.courseClassCourseNameList.unshift(item);// 添加
        }
      } else {
        this.getCourseTypeNameInfoList();
      }
      console.log(this.courseClassCourseNameList, '获取课类课名列表');
      // this.$bus.emit('CourseClassNameAddOrEdit', item);
    },
    // 新增课类项
    onAddClassClassItemClick () {
      this.options.type = 'add';
      this.options.data = {};
      this.options.moduleName = 'courseClassSetting';
      this.doOperate(this.options);
    },
    // 新增课程项
    onAddClassNameItemClick () {
      this.options.type = 'add';
      this.options.data = {};
      this.options.moduleName = 'courseNameSetting';
      this.doOperate(this.options);
    },
    doOperate (options) {
      this.options.moduleName = options.moduleName;
      console.log(options, '更新');
      switch (options.type) {
        case 'add':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '新增' + this.showModulesName(this.options.moduleName),
            moduleName: this.options.moduleName,
            data: { editItem: options.data || {}, isReadonly: false, type: 'HQ' },
            callBack: {
              afterSuccess: (type, data) => { this.upCourseAndClassListData(data); }
            }
          });
          console.log(options, 'add');
          break;
        case 'edit':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '修改' + this.showModulesName(this.options.moduleName),
            moduleName: this.options.moduleName,
            data: { editItem: options.data, isReadonly: false, type: 'HQ' },
            callBack: {
              afterSuccess: (type, data) => { this.upCourseAndClassListData(data); }
            }
          });
          console.log(options, 'edit');
          break;
        case 'view':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '查看' + this.showModulesName(this.options.moduleName),
            moduleName: this.options.moduleName,
            data: { editItem: options.data, isReadonly: true, type: 'HQ' }
          });
          console.log(options, 'view');
          break;
        case 'unActive':
          console.log(options, 'unActive');
          layer.confirm('确定是否停用?', {
            btn: [{
              name: '确认',
              callBack: () => {
                hqStopTheClassType(options.data.OLAPKey).then(result => {
                  options.data.StateKey = 3;
                  this.getCourseTypeNameInfoList();
                  // this.upCourseAndClassListData(options.data);
                }, error => {
                  layer.alert(error.msg);
                });
                layer.close();
              }
            }
            ]
          });
          break;
        default:// 超出标准操作，自行处理

          break;
      }
      this.isShowCourseTypeMenu = false;
    },
    showModulesName (value) {
      switch (value) {
        case 'courseNameSetting':
          return '课程';
        case 'courseClassSetting':
          return '课类';
        default:
          return '';
      }
    },
    // 滚动加载事件.
    scrollTOP (e) {
      this.scrollETarget = e.target;
      if (this.scrollETarget.scrollTop) {
        this.vcourseClassCourseNameList.forEach(o => {
          o.isMenu = false;
        });
      }
      this.isShowCourseTypeMenu = false;
      this.isShowCourseNameMenu = false;
    }
  }
};
</script>
