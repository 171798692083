<template>
  <div class="star_list">
    <div class="list_credit"
         v-for="(item,key) in stuInfo.EssayAssessTempSetList"
         :key="key">
      <div class="list_credit_title"
           :style="readonly?'margin-left:0px':''">{{item.SetName||item.ScTempSetKeyValue}}</div>
      <div class="list_credit_img"
           v-for="(obj,key) in 5"
           :key="key"
           :class="{'opt':key<Number(item.TempSetStarGrade)}"
           @click.stop="changeCurrency(key,item)"></div>
      <div class="list_credit_text">{{setRemark(item)}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    }
  },
  props: {
    stuInfo: Object,
    readonly: Boolean
  },
  created () {

  },
  methods: {
    setRemark (item) {
      if (item.TempSetStarGrade == 1) {
        item.Remark = item.OneStar || item.Remark
      } else if (item.TempSetStarGrade == 2) {
        item.Remark = item.TwoStar || item.Remark
      } else if (item.TempSetStarGrade == 3) {
        item.Remark = item.ThreeStar || item.Remark
      } else if (item.TempSetStarGrade == 4) {
        item.Remark = item.FourStar || item.Remark
      } else if (item.TempSetStarGrade == 5) {
        item.Remark = item.FiveStar || item.Remark
      } else {
        item.Remark = ''
      }
      return item.Remark
    },
    changeCurrency (key, item) {
      if (this.readonly) {
        return false
      } else {
        item.TempSetStarGrade = key + 1;
        if (item.TempSetStarGrade == 1) {
          item.Remark = item.OneStar
        } else if (item.TempSetStarGrade == 2) {
          item.Remark = item.TwoStar
        } else if (item.TempSetStarGrade == 3) {
          item.Remark = item.ThreeStar
        } else if (item.TempSetStarGrade == 4) {
          item.Remark = item.FourStar
        } else if (item.TempSetStarGrade == 5) {
          item.Remark = item.FiveStar
        } else {
          item.Remark = ''
        }
      }
    },
    setTips (item) {
      if (item.TempSetStarGrade == 1) {
        return item.OneStar
      } else if (item.TempSetStarGrade == 2) {
        return item.TwoStar
      } else if (item.TempSetStarGrade == 3) {
        return item.ThreeStar
      } else if (item.TempSetStarGrade == 4) {
        return item.FourStar
      } else if (item.TempSetStarGrade == 5) {
        return item.FiveStar
      } else {
        return ''
      }
    },

  }
}
</script>

<style scoped>
.star_list {
  margin-top: 10px;
}
.list_credit {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  margin-right: 16px;
  margin-bottom: 14px;
  color: #333333;
}
/* 
 .list_credit_img {
    width: 18px;
    height: 18px;
    background: url(/public/img/default_star.png) no-repeat center center;
    background-size: 18px;
        margin-right:13px;
  }

  .list_credit_img.opt {
    background-image: url(/public/img/light_star.png);
  } */
.list_credit_title {
  margin-right: 10px;
  margin-left: 10px;
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list_credit_text {
  color: #fe6523;
  margin-left: 15px;
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>