<template>
  <div class="course_statistics_right general_summarizing_student box_shadow_border_div">
    <!-- <div class="course_statistics_left_title"><span>课次明细</span></div> -->
    <div class="general_summarizing_student_title">
      <div class="general_summarizing_student_nav">
        <menu-item v-for="item in menuList"
                   :key="item.type"
                   :item="item"
                   @seletedItem="seletedMenuAfter"></menu-item>
      </div>
    </div>
    <div class="">
      <div v-show="searchType==0">
        <teacher-report ref="teacherReport"
                        :IsMTeaKey="IsMTeaKey"
                        @searchAppointData="searchAppointData"
                        :TeacherKey="TeacherKey"></teacher-report>
      </div>
      <div v-show="searchType==1">
        <course-report ref="courseReport"
                       :IsMTeaKey="IsMTeaKey"
                       :TeacherKey="TeacherKey"></course-report>
      </div>
      <div v-show="searchType==2">
        <student-report ref="studentReport"
                        :IsMTeaKey="IsMTeaKey"
                        :TeacherKey="TeacherKey"></student-report>
      </div>
    </div>
  </div>
</template>
<script>
import menuItem from '../class-spend-statistics-analyze/components/menu-item';
import courseReport from './components/course-report';
import teacherReport from './components/teacher-report';
import studentReport from './components/student-report';
export default {
  data () {
    return {
      searchType: 0,
      menuList: [
        {
          type: 0,
          name: '老师统计',
          isActive: true
        },
        {
          type: 1,
          name: '课次明细',
          isActive: false
        },
        {
          type: 2,
          name: '学生统计',
          isActive: false
        }
      ],
      TeacherKey: 0
    };
  },
  props: {
    IsMTeaKey: {
      type: Number,
      default: 1
    }
  },
  components: {
    courseReport, menuItem, teacherReport, studentReport
  },
  created () {
    this.menuList[0].name = this.IsMTeaKey != 1 ? '助教统计' : '主讲统计';
  },
  methods: {
    // 点击月统计单项的日期指定打开课程列表的指定日期
    openCourseReportGetDataByDate (item, date) {
      console.log(item, 'openCourseReportGetDataByDate');
      this.seletedMenuAfter(1, () => {
        this.$nextTick(() => {
          this.$refs.courseReport.searchDataForDate(item, date);
        });
      });
    },
    seletedMenuAfter (type, callback) {     // 防止seletedDate未清空.
      // console.log(callback, 'callback');
      this.seletedDate = null;
      this.menuList.forEach(o => {
        if (o.type == type) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      if (callback) {
        callback();
      }
      this.searchType = type;
    },
    // 点击老师统计详情触发.
    searchAppointData (data, searchObj) {
      this.seletedMenuAfter(1, () => {
        this.$nextTick(() => {
          this.$refs.courseReport.searchDataForKey(data, searchObj);
        });
      });
    },
    updateAllData () {
      this.$refs.courseReport.getDataList();
      this.$refs.teacherReport.getDataList();
      this.$refs.studentReport.getDataList();
    }

  }

};
</script>

