<template>
  <div>
    <!-- 总部版本 -->
    <courseware-manage-head ref="coursewareManageHead"
                            :bigcoursewareType="type"
                            :maxCoursewareInfo="maxCoursewareInfo"
                            :coursewareNum="vCoursewareNum"
                            :showEdit="true"
                             @editbigCoursewareInfo="editbigCoursewareInfo"
                            @doReturn="doOperateItemClick"
                            @doRecycle=" openCommonCustomDialogByFactoryDialog"></courseware-manage-head>
    <div v-if="isShowBody"
         class="courseware_manage_view scroll_type">

      <courseware-list ref="refCourseware"
                       :type="type"
                       :maxCoursewareInfo="maxCoursewareInfo"
                       :coursewareTypeList="coursewareTypeList"
                       :coursewareList="coursewareList"
                       :coursewareInfo="coursewarePackageInfo"
                       :vCoursewareTreeList="vCoursewareTreeList"
                        @updateElscrollTop="updateElscrollTop"
                       @selectEvent="selectEvent"
                       @selectEventSecond="selectEventSecond"
                       @doListSort="doListSort"
                       @openCommonCustomDialogByFactoryDialog=" openCommonCustomDialogByFactoryDialog"
                       @doOperateItemClick="doOperateItemClick"
                       @selectCoursewareType="selectCoursewareType"
                       @editCoursewarePackageAfterSuccess="getNormalCoursewarePackage"></courseware-list>

      <courseware-content :type="type"
                          :isLoadData="isLoadData"
                          :coursewareInfo="coursewarePackageInfo"
                          :coursewareContentList="coursewareContentList"
                          @coursewareOperate="coursewareOperate"
                          @afterSucceed="selectCoursewareType"
                          @afterSucceedSort="selectEvent"
                          @afterSuccess="afterSuccess"></courseware-content>
    </div>
    <div v-if="!isShowBody">
      <courseware-editor :coursewareId="coursewareEditInfo.CoursewareID"
                         @cancel="showBody"
                         @saveCourseware="showBody"></courseware-editor>
    </div>
    <custom-dialog :title="'编辑大课包'"
                   :visible.sync="isShowCoursewareFormPopup"
                   :before-close="closeCoursewareFormPopup">
      <max-courseware-form :coursewareId="maxCoursewareInfo.ID"
                           :isReadonly="false"
                           @close="closeCoursewareFormPopup"
                           @afterSuccess="editCoursewarePackageAfterSuccess"></max-courseware-form>
    </custom-dialog>
    <div>
      <dialog-factory ref="hqCoursewareManagementDialogFactory"
                      :key="'hqCoursewareManagement'"
                      :routerName="'hqCoursewareManagement'"></dialog-factory>
      <dialog-factory-common ref="hqCoursewareManagementCommonDialogFactory"></dialog-factory-common>
    </div>
  </div>
</template>
<script>
import {
  hqSearchCourseWarePackageListForNormal,
  hqSearchCourseWarePackageListForRecycle,
  hqCoursewarePackageManageSort,
  hqSearchCoursewareListByCoursewarePackageID,
  hqCoursewarePackageManageCopy,
  hqCoursewarePackageManageDelete,
  hqCoursewarePackageManageDisable,
  hqCoursewarePackageManageEnable,
  hqCoursewareManageAdd,
  hqCoursewareManageDelete,
  VersionRestoreCourseware
} from '../../../../API/workbench';
import coursewareList from '../../../courseware-management/courseware-list/index';
import coursewareContent from '../../../courseware-management/courseware-content/index';
import coursewareEditor from '../../../courseware-management/courseware-editor/index';
import coursewareManageHead from './courseware-manage-head';
import maxCoursewareForm from '../../../courseware-management/max-courseware-list/max-courseware-form';
import dialogFactory from '../../../common/dialog-factory';
export default {
  provide () {
    return {
      coursewarePackage: this.coursewarePackage,
      getNormalCoursewarePackage: this.getNormalCoursewarePackage,
      setCoursewareList:this.setCoursewareList,
      coursewarePackageID: this.maxCoursewareInfo.ID // 大课包ID
    };
  },
  data () {
    return {
      isShowBody: true,
      coursewareTypeList: [
        { name: '自研课件', key: 'ZZ', isOpt: true, isShow: true },
        { name: '回收站', key: 'HSZ', isOpt: false, isShow: true }
      ],
      coursewareList: [], // 课件包列表
      coursewarePackageInfo: {}, // 课件包信息
      coursewareContentList: [], // 课件包内容列表
      coursewarePackageID: '', // 支持外部进入选中查看(课件包ID)
      coursewareEditInfo: {},
      coursewareId: '',
      isLoadData: true,
      isShowCoursewareFormPopup:false,
      elscrollTop:0,
      coursewarePackage: 'smallCourseware' // 传递给回收站 是从大课包传入的信息
    };
  },
  components: {
    coursewareList,
    coursewareContent,
    coursewareEditor,
    coursewareManageHead,
    dialogFactory,
    maxCoursewareForm
  },
  props: {
    maxCoursewareInfo: Object, // 大课包信息
    type: String // 课件类型
  },
  computed: {
    vCoursewareNum () {
      let list = this.coursewareList.filter(o => { return Number(o.TypeKey) == 0; });
      return list.length;
    },
    vCoursewareTreeList(){
       let arr = [];
      // console.log(this.newCoursewareList, this.maxCoursewareInfo, 'newCoursewareList');
      console.log("this.vCoursewareTreeList",this.coursewareList)
      let treeData = this.coursewareList.filter(item => {
        return item.ID;
      });
        // 父节点数据
      const ParentList = treeData.filter(item => Number(item.ParentKey) == Number(this.maxCoursewareInfo.ID));
        // 子节点数据
      const childList = treeData.filter(item => Number(item.ParentKey) != Number(this.maxCoursewareInfo.ID));
      // console.log(ParentList, childList, '父节点数据-子节点数据');
      arr = this.$utils.loopData(ParentList, childList, treeData, 'ID', 'ParentKey');
      console.log("vCoursewareTreeList222",arr)
      return arr;
    }
  },
  created () {
    if (Number(this.maxCoursewareInfo.ID) > 0) {
      this.getNormalCoursewarePackage(()=>{
        this.enterRouterInit()
      });
    }
  },
  // activated () {
  //   if ((this.$route.params.dataKey && this.$route.params.dataKey > 0) || this.coursewarePackageID) { // 外部进入定位
  //     this.coursewarePackageID = this.$route.params.dataKey || this.coursewarePackageID;
  //     if (this.type == 'ZZ') {
  //       this.coursewareTypeList[1].isOpt = false;
  //       this.getNormalCoursewarePackage();
  //     }
  //   }
  //   console.log('外部进入定位', this.$route.params);
  // },
  methods: {
    // 判断是否有路由传参过来。
    enterRouterInit(){
        if(this.$route.params.data&&this.$route.params.data.levelPath){
            let dataArr= this.$route.params.data.levelPath.split('.')
            if(dataArr[0]==''){
              dataArr.splice(0,1)
            }
            console.log("dataArr",dataArr)
          
              let item = this.coursewareList.find(p=>{
                return p.ID==dataArr[1]
              })
              // 判断是否目录，如果不是直接选择课件
              if(item.TypeKey==0){
                this.selectEvent(item)
                this.$route.params.data={}
              }else{
                // 是目录，先调选择课件接口。
                item.isOpt=true
                this.selectEventSecond(item,()=>{
                  let secondItem = this.coursewareList.find(p=>{
                    return p.ID==dataArr[2]
                  })
                  console.log("secondItem",secondItem)
                  if(secondItem){
                    secondItem.isSelected=true
                    this.selectEvent(secondItem)
                  }
                })
                
                this.$route.params.data={}
              }
              console.log("item",item)

          }
    },
    updateElscrollTop(scrollTop){
      console.log("this.updateElscrollTop",this.elscrollTop)
      this.elscrollTop=scrollTop
    },
    editCoursewarePackageAfterSuccess(data){
      this.$emit("getParentCourseWarePackageList",data)
    },
    closeCoursewareFormPopup(){
      this.isShowCoursewareFormPopup=false
    },
    editbigCoursewareInfo(coursewareInfo){
      console.log('editbigCoursewareInfo',coursewareInfo)
      console.log("22222",this.maxCoursewareInfo)
      this.isShowCoursewareFormPopup=true
    },
    afterSuccess () {
      this.$emit('editCoursewarePackageAfterSuccess');
      this.getNormalCoursewarePackage();
    },
    showBody () {
      this.isShowBody = true;
      let locaItem = this.coursewareList.find(o => {
          return o.ID == this.coursewarePackageInfo.ParentKey;
      });
      if (locaItem) {
          locaItem.childTree = [];
              this.coursewareList = this.coursewareList.filter(o => {
              return o.TypeKey == 2 && o.ParentKey != locaItem.ID;
          });
          this.selectEventSecond(locaItem);
      }else{
        this.coursewareList = [];
        // this.getNormalCoursewarePackage();

      }
      this.$emit('editCoursewarePackageAfterSuccess');
      this.getNormalCoursewarePackage();
    },
    // 选中课件包
    selectedCoursewarePackag () {
      if (this.coursewarePackageID) {
        let locaIndex = this.coursewareList.findIndex(o => {
          return this.coursewarePackageID == o.ID;
        });
        if (locaIndex >= 0) {
          this.selectEvent(this.coursewareList[locaIndex]);
           console.log("this.elscrollTop",this.elscrollTop)
             document.querySelector('.left_ul').scrollTop = this.elscrollTop
       
          // document.querySelector('.left_ul').scrollTop = locaIndex * 205;
        } else {
           this.selectEvent(this.coursewarePackageInfo);
        }
      } else {
        // this.selectEvent(this.coursewareList[0]); // 默认第一项
      }
    },
    // 查询课件包列表-自研
    getNormalCoursewarePackage (callback) {
      hqSearchCourseWarePackageListForNormal(this.maxCoursewareInfo.ID).then(result => {
        result.data.forEach(o=>{
          this.$set(o, 'isOpt', false);
          this.$set(o, 'isSelected', false);
          this.$set(o, 'childTree', []);
          let isrepeat=false;
          this.coursewareList.forEach(item=>{
            if(o.ID==item.ID){
              isrepeat=true
              item=this.$utils.parseJson(o)
            }
          })
          if(!isrepeat){
            this.coursewareList.push(o)
          }else{
          
             let locaIndex=this.coursewareList.findIndex(item => {
                return o.ID==item.ID
              });
                console.log("this.getNormalCoursewarePackage",this.coursewareList)
              console.log("this.22222",o,locaIndex)
              if(locaIndex>=0){
                this.coursewareList[locaIndex].CoursewareNum=o.CoursewareNum
              }

            }
        })
        if (this.coursewareList.length > 0) {
          if(callback){
            callback()
          }else{
            this.selectedCoursewarePackag();
          }
          
        } else {
          this.coursewareContentList = [];
          this.coursewarePackageInfo = {};
        }
        
        console.log('查询课件包列表-自研(总部)', result.data);
      },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 查询课件包列表-回收站
    // getRecycleCoursewarePackage () {
    //   hqSearchCourseWarePackageListForRecycle().then(result => {
    //     this.type = 'HSZ';
    //     this.coursewareList = result.data;
    //     if (this.coursewareList.length > 0) {
    //       this.selectEvent(result.data[0]);// 默认第一项
    //     } else {
    //       this.coursewareContentList = [];
    //       this.coursewarePackageInfo = {};
    //     }
    //     console.log('查询课件包列表-回收站(总部)', result.data);
    //   }, error => {
    //     layer.alert(error.msg);
    //   });
    // },
    // 课件包排序
    doListSort (sortType, data) {
      let changeToSort =
        sortType == 'Top'
          ? Number(data.ShowOrder) - 1
          : Number(data.ShowOrder) + 1;
      console.log("doListSort",data)
      hqCoursewarePackageManageSort(data.ID, changeToSort).then(
        result => {
          let locaItem=this.coursewareList.find(o=>{
            return o.ID == data.ParentKey
          })
          console.log(locaItem)
          if(locaItem){
            locaItem.childTree=[]
             this.coursewareList=this.coursewareList.filter(o=>{
              return o.TypeKey==2&&o.ParentKey!=locaItem.ID
            })
            this.selectEventSecond(locaItem)
          }else{
             this.coursewareList=[]
            this.getNormalCoursewarePackage()
          }
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 点击科目时加载数据
    selectEventSecond(dataInfo,callBack){
      console.log("点击科目时加载数据",dataInfo)

      hqSearchCourseWarePackageListForNormal(dataInfo.ID).then(result => {
        console.log("result.data",result.data)
        result.data.forEach(o=>{
          this.$set(o, 'isOpt', false);
          this.$set(o, 'isSelected', false);
          this.$set(o, 'childTree', []);
          let isrepeat=false;
          this.coursewareList.forEach((item,index)=>{
            if(o.ID==item.ID){
              isrepeat=true
                  item=this.$utils.parseJson(o)
            }
          })
          if(!isrepeat){
            this.coursewareList.push(o)
          }
        })
        if(callBack){
          this.$nextTick(o=>{
            callBack()
          })
        }
        console.log('111111', result.data);
      },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 根据指定课件包ID获取课件列表
    selectEvent (dataInfo) {
      this.coursewarePackageInfo = dataInfo;
      this.coursewarePackageID = this.coursewarePackageInfo.ID;
      console.log("dataInfo",dataInfo)
      hqSearchCoursewareListByCoursewarePackageID(this.coursewarePackageInfo.ID).then(result => {
        this.coursewareContentList = result.data.filter(obj => {
          this.$set(obj, 'isOperate', false);
          return obj.OLAPKey;
        });
        this.isLoadData = false;
        console.log('根据指定课件包ID获取课件列表(总部)', result.data);
      },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 重新加载数据
    selectCoursewareType (item, data) {
      // data,用于选中当前项
      console.log('重新加载数据', item);
      this.coursewarePackageID = data && data.ID ? data.ID : '';
      if (item.key == 'ZZ') {
        this.getNormalCoursewarePackage();
      } else if (item.key == 'HSZ') {
        this.getRecycleCoursewarePackage();
      }
    },
    // 课件包操作
    doOperateItemClick (item, data) {
      console.log(item, data, '操作项');
      switch (item.key) {
        case 'return': // 返回大课件包列表
          this.$parent.isShowMaxCoursewareList = true;
          this.$parent.getParentCourseWarePackageList();
          this.$parent.getCoursewareStat();
          // this.$set(o, 'isSelected', true);
          // this.$emit('searchValue', this.queryParams);
          break;
        case 'copy':
          layer.confirm('确定复制该课件包?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  hqCoursewarePackageManageCopy(data.ID).then(result => {
                    this.coursewarePackageID = result.data.CoursewarePackageID;
                    // this.getNormalCoursewarePackage();
                    if (this.$refs.refCourseware) {
                      let locaIndex= this.coursewareList.findIndex(o=>{
                        return o.ID==data.ID
                      })
                      if(locaIndex>=0){
                        this.coursewareList[locaIndex].childTree=[]
                      }
                      this.$refs.refCourseware.editCoursewarePackageAfterSuccess('add', result.data);

                      this.selectEvent(result.data);
                    }
                  }, error => {
                    layer.alert(error.msg);
                  });
                  layer.close();
                }
              }
            ]
          });
          break;
        case 'delete':
          layer.confirm('确定删除该课件包?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  hqCoursewarePackageManageDelete(data.ID).then(result => {
                    console.log(result.data, this.coursewareList, '确定删除该课件包');
                    let locaIndex= this.coursewareList.findIndex(o=>{
                        return o.ID==data.ParentKey
                      })
                      if(locaIndex>=0){
                        this.coursewareList[locaIndex].childTree=[]
                        let n = this.coursewareList.findIndex(o => {
                          return o.ID == data.ID;
                        });
                        if (n > -1) {
                          this.coursewareList.splice(n, 1);
                        }
                        
                        let findItem = this.coursewareList.find(o => {
                          return o.isSelected;
                        });
                        console.log(findItem, 'findItem');
                        if (findItem) {
                          this.$refs.refCourseware.editCoursewareInfo = findItem;
                        } else {
                          this.selectEvent({ ID: 0, MainDemoName: '' });
                        }
                      }else{
                        let n= this.coursewareList.findIndex(o=>{
                          return o.ID==data.ID
                        })
                         if (n > -1) {
                          this.coursewareList.splice(n, 1);
                        }
                      }
                  }, error => {
                    layer.alert(error.msg);
                  }
                  );
                  this.$emit('editCoursewarePackageAfterSuccess');
                  layer.close();
                }
              }
            ]
          });
          break;
        case 'disable':
          layer.confirm(
            '停用该课件包,所有该课件包的授权将会被暂停(不影响大课包),是否继续停用?',
            {
              btn: [
                {
                  name: '确认',
                  callBack: () => {
                    hqCoursewarePackageManageDisable(data.ID).then(
                      result => {
                        // this.getNormalCoursewarePackage();
                        // this.selectEvent(this.coursewareList[0]);
                        if (this.$refs.refCourseware) {
                          let locaIndex = this.coursewareList.findIndex(o => {
                            return o.ID == data.ParentKey;
                          });
                          console.log("locaIndex",locaIndex)
                          if (locaIndex >= 0) {
                            this.coursewareList[locaIndex].childTree = [];
                            let n = this.coursewareList.findIndex(o => {
                              return o.ID == data.ID;
                            });
                            if (n > -1) {
                              this.coursewareList.splice(n, 1);
                            }

                            let findItem = this.coursewareList.find(o => {
                              return o.isSelected;
                            });
                            console.log(findItem, "findItem");
                            if (findItem) {
                              this.$refs.refCourseware.editCoursewareInfo = findItem;
                            } else {
                              this.selectEvent({ ID: 0, MainDemoName: "" });
                            }
                          }else{
                            let n = this.coursewareList.findIndex(o => {
                              return o.ID == data.ID;
                            });
                            if(n>=0){
                              this.coursewareList.splice(n, 1);
                            }
                            let findItem = this.coursewareList.find(o => {
                              return o.isSelected;
                            });
                            console.log(findItem, "findItem");
                            if (findItem) {
                              this.$refs.refCourseware.editCoursewareInfo = findItem;
                            } else {
                              this.selectEvent({ ID: 0, MainDemoName: "" });
                            }
                          }
                        }
                      },
                      error => {
                        layer.alert(error.msg);
                      }
                    );
                    this.$emit('editCoursewarePackageAfterSuccess');
                    layer.close();
                  }
                }
              ]
            }
          );
          break;
        case 'enable':
          hqCoursewarePackageManageEnable(data.ID).then(
            result => {
              layer.alert('启用成功');
              this.getRecycleCoursewarePackage();
              this.getNormalCoursewarePackage();
              this.$emit('editCoursewarePackageAfterSuccess');
              console.log(123456);
            },
            error => {
              layer.alert(error.msg);
            }
          );
          break;
        default:
          break;
      }
    },
    getCmhS () {
      // this.$refs.coursewareManageHead.
    },
    setCoursewareList(data) {
      let locaIndex=this.coursewareList.findIndex(o=>{
        return o.ID==data.ID
      })
      if(locaIndex==-1){
        this.$set(data, "isSelected", false);
        this.$set(data, "isOpt", false);
        this.$set(data, "childTree", []);
        this.coursewareList.push(data)
      }
      console.log("setCoursewareList",this.coursewareList)
    },
    // 新增课件
    coursewareOperate (type, data, deviceInfo) {
      console.log(type, data, '课件内容操作');
      switch (type) {
        case 'Add':
          this.coursewareId = '';
          this.getCoursewareDeviceInfo(
            this.coursewarePackageInfo.ID,
            deviceInfo
          );
          break;
        case 'Edit':
          this.coursewareEditInfo.CoursewareID = data.OLAPKey;
          this.isShowBody = false;
          break;
        case 'Restore':
          VersionRestoreCourseware(data.OLAPKey).then(
            res => {
              layer.alert(data.MainDemoName + '还原成功');
              this.getNormalCoursewarePackage();
              this.$emit('editCoursewarePackageAfterSuccess');
            },
            err => {
              layer.alert(err.msg);
            }
          );
          break;
        case 'Delete':
          layer.confirm('确定删除该课件?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.coursewareId = data.OLAPKey;
                  this.deleteCourseware();
                  layer.close();
                  this.$emit('editCoursewarePackageAfterSuccess');
                }
              }
            ]
          });
          break;
        default:
          break;
      }
    },
    // 获取课件新增信息
    getCoursewareDeviceInfo (coursewarePackageID, deviceInfo) {
      hqCoursewareManageAdd(coursewarePackageID, deviceInfo).then(
        result => {
          this.coursewareEditInfo = result.data;
          this.isShowBody = false;
          localStorage.setItem('isAddCourse', 1);
          console.log('设置---------------')
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 删除
    deleteCourseware () {
      hqCoursewareManageDelete(this.coursewareId).then(
        result => {
          this.coursewarePackageID = this.coursewarePackageInfo.ID;
          let locaItem = this.coursewareList.find(o => {
              return o.ID == this.coursewarePackageInfo.ParentKey;
          });
          if (locaItem) {
              locaItem.childTree = [];
                  this.coursewareList = this.coursewareList.filter(o => {
                  return o.TypeKey == 2 && o.ParentKey != locaItem.ID;
              });
              this.selectEventSecond(locaItem);
          }else{
            this.coursewareList = [];
            // this.getNormalCoursewarePackage();

          }
          this.getNormalCoursewarePackage();
          this.$emit('editCoursewarePackageAfterSuccess');
          // this.selectEvent(this.coursewarePackageInfo);
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 打开回收站
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      console.log(dialogInfo, 'dialogInfo');
      if (this.$refs.hqCoursewareManagementCommonDialogFactory) {
        this.$refs.hqCoursewareManagementCommonDialogFactory.openDialogPlus(
          dialogInfo.moduleName,
          dialogInfo.dataKey,
          dialogInfo.data,
          dialogInfo.callback,
          dialogInfo.name || dialogInfo.title
        );
      }
    }
  }
};
</script>

<style>
.courseware_manage_head_view {
  width: 100%;
  /* height: 54px; */
  background: #ffffff;
  /* line-height: 54px; */
  padding: 0 300px;
  display: flex;
}
.courseware_manage_head_btn {
  width: 56px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  text-align: center;
  line-height: 35px;
  margin: 10px 10px 0;
  cursor: pointer;
}
.coursewar_head_box {
  width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.coursewar_head_tltle {
  font-weight: 700;
  font-size: 18px;
  margin-right: 10px;
}
.coursewar_head_num {
  color: #666666;
}
</style>

