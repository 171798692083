<template>
  <div class="html_view_personages list_credit_type pr"
       style="margin:10px;
        background: white;
        border-radius: 10px;
        padding: 10px;">
    <div class="release_student_info">
      <img :src="vImgUrl"
           :data-src="vImgUrl"
           :onerror="vDefaultImgUrl"
           class="html_view_personages_img" />
      <!-- </image> -->
      <div class="name_box new_student_info_list student_info_course">
        <div class="text_box3">
          <span>{{ stuInfo.StudentKeyValue }}</span>
        </div>

        <div class="list_type"
             :class="vAttenClass">
          {{ vAttenceStatusKeyValue }}
        </div>
      </div>
      <div class="star_data_list"
           @click.stop="showStarDataList">
        {{seletedTemplateInfo.TemplateName}}
      </div>
    </div>
    <star-list :stuInfo="stuInfo"
               :seletedTemplateList="seletedTemplateList">
    </star-list>
    <custom-dialog :title="'课评评分'"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowStarDataList"
                   :before-close="closeShowList">

      <div class=""
           style="height:100%">
        <!-- 选择学生 -->
        <template-list v-for="(item,key) in templatePageList"
                       :key="item.ID"
                       :dataInfo="item"
                       :seletedTemplateInfo="seletedTemplateInfo"
                       @clickItem="seletedTemplateItem">
        </template-list>

      </div>
    </custom-dialog>
  </div>
</template>
<script>
import starList from './star-list'
import templateList from './template-list'
import { GetScAssessTemplatePageList, GetScAssessTemplateDetail } from '../../../../../API/workbench';

export default {
  data () {
    return {
      isShowStarDataList: false,
      templatePageList: [],
      seletedTemplateInfo: {
        ID: 0,
        TemplateName: ''
      },
      seletedTemplateList: []

    };
  },
  props: {
    stuInfo: Object,
    dataList: Array,
    readonly: Boolean

  },
  created () {
    console.log("stuInfo", this.stuInfo)
    setTimeout(o => {

      this.getScAssessTemplatePageList()
    }, 300)
  },
  components: {
    starList, templateList
  },
  computed: {
    vDefaultImgUrl () {
      // 默认头像. 峰
      if (this.stuInfo.SexKey == 2) {
        // 2:男 3：女
        return 'this.src="' + require('../../../../../../public/image/deflut_studentPhoto_boy.png') + '"';
      } else {
        return 'this.src="' + require('../../../../../../public/image/deflut_studentPhoto_gril.png') + '"';

      }
    },
    vImgUrl () {
      let imageUrl = "";
      if (this.stuInfo.HeadImgSupport) {
        if (this.stuInfo.HeadImgSupport.indexOf("http") == -1) {
          imageUrl = this.$store.getters.CDNURL + this.stuInfo.HeadImgSupport;
        } else {
          imageUrl = this.stuInfo.HeadImgSupport;
        }
      } else {
        if (this.stuInfo.SexKey == 2) {
          // 2:男 3：女
          return (require('../../../../../../public/image/deflut_studentPhoto_boy.png'));
        } else if (this.stuInfo.SexKey == 3) {
          return (require('../../../../../../public/image/deflut_studentPhoto_gril.png'));
        } else {
          return (require('../../../../../../public/image/deflut_studentPhoto_boy.png'));
        }
      }
      return imageUrl;
    },
    vAttenClass () {
      if (this.stuInfo.AttenceStatusKey == 3) {
        return "type_green";
      } else if (this.stuInfo.AttenceStatusKey == 4) {
        return "type_yellow";
      } else if (this.stuInfo.AttenceStatusKey == 5) {
        return "type_red";
      } else {
        return "type_gray";
      }
    },
    vAttenceStatusKeyValue () {
      if (this.stuInfo.AttenceStatusKey == 3) {
        return "签到";
      } else if (this.stuInfo.AttenceStatusKey == 4) {
        return "请假";
      } else if (this.stuInfo.AttenceStatusKey == 5) {
        return "旷课";
      } else {
        return "未考勤";
      }
    },
  },
  methods: {
    seletedTemplateItem (item) {
      this.seletedTemplateInfo = item
      this.stuInfo.ScTempKey = this.seletedTemplateInfo.ID
      this.stuInfo.ScTempKeyValue = this.seletedTemplateInfo.TemplateName
      this.getScAssessTemplateInfo()
      this.isShowStarDataList = false
    },
    closeShowList () {
      this.isShowStarDataList = false
    },
    getScAssessTemplatePageList () {
      let sourceParam = {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称

      };
      GetScAssessTemplatePageList(sourceParam).then(result => {
        console.log('getScAssessTemplatePageList', result.data);
        this.templatePageList = result.data.PageDataList

        console.log("this.stuInfo.ScTempKey", this.stuInfo.ScTempKey)
        if (this.stuInfo.ScTempKey) {
          this.seletedTemplateInfo.ID = this.stuInfo.ScTempKey
          this.seletedTemplateInfo.TemplateName = this.stuInfo.ScTempKeyValue
        } else {
          let locaIndex = this.templatePageList.findIndex(o => {
            return o.IsDefault == 1
          })
          if (locaIndex != -1) {
            this.seletedTemplateInfo = this.templatePageList[locaIndex]
            this.stuInfo.ScTempKey = this.seletedTemplateInfo.ID
            this.stuInfo.ScTempKeyValue = this.seletedTemplateInfo.TemplateName
          }

        }
        this.getScAssessTemplateInfo()

      });
    },
    getScAssessTemplateInfo () {
      GetScAssessTemplateDetail(this.seletedTemplateInfo.ID).then(result => {
        console.log("getScAssessTemplateInfo11", result.data, this.stuInfo)
        result.data.TemplateSetting.forEach(o => {
          o.TempSetStarGrade = 0;
          o.ScTempSetKey = o.ID;
          o.ScTempSetKeyValue = o.SetName
          o.Remark = ''
          if (this.stuInfo.EssayAssessTempSetList && this.stuInfo.EssayAssessTempSetList.length > 0) {
            let locaItem = this.stuInfo.EssayAssessTempSetList.find(obj => {
              return obj.ScTempSetKey == o.ScTempSetKey
            })
            console.log("locaItem", locaItem)
            if (locaItem) {
              this.$set(locaItem, 'OneStar', o.OneStar)
              this.$set(locaItem, 'TwoStar', o.TwoStar)
              this.$set(locaItem, 'ThreeStar', o.ThreeStar)
              this.$set(locaItem, 'FourStar', o.FourStar)
              this.$set(locaItem, 'FiveStar', o.FiveStar)
              this.$set(o, 'TempSetStarGrade', Number(locaItem.TempSetStarGrade))


            }
          }
        });
        console.log("ScTempKey", this.stuInfo.ScTempKey, this.stuInfo.EssayAssessTempSetList)
        //  if(!this.stuInfo.EssayAssessTempSetList){
        //     this.$set(this.stuInfo,"EssayAssessTempSetList",result.data.TemplateSetting||[])
        //  }else if(this.stuInfo.EssayAssessTempSetList.length==0){
        this.$set(this.stuInfo, "EssayAssessTempSetList", result.data.TemplateSetting || [])
        //  }
        //    this.stuInfo.EssayAssessTempSetList=result.data
      })
    },

    showStarDataList () {
      this.isShowStarDataList = true
    }
  },
};
</script>
<style scoped>
.release_student_info {
  display: flex;
  align-items: center;
}

.list_credit_type .list_credit,
.homerwork_details_student_list ::v-deep .list_credit {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-right: 16px;
}

.list_credit_type .list_credit_img,
.homerwork_details_student_list ::v-deep .list_credit_img {
  width: 18px;
  height: 18px;
  background: url(./../../../../../../public/image/learn_coins.png) no-repeat
    center center;
  background-size: 18px;
  margin-right: 10px;
}

.list_credit_type .list_credit_img.opt,
.homerwork_details_student_list ::v-deep .list_credit_img.opt {
  background-image: url(./../../../../../../public/image/learn_coins_down.png);
}

.html_view_personages {
  border-top: 1px solid #f7f7f7;
  /* width: 750rpx; */
}

.html_view_personages_img {
  display: inline-block;
  left: 10px;
  top: 7px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #bababa;
}

.html_view_personages .name_box {
  word-wrap: break-word;
  /* display: inline-block; */
  vertical-align: middle;
  margin-left: 24px;
  flex: 1;
  justify-content: flex-start;
}

.text_box3 {
  margin-bottom: 3px;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18rpx;

  display: flex;
  align-items: center;
  /* letter-spacing: 0.12rpx; */

  color: #333333;
}

.student_info_course .list_type {
  width: 38px;
  height: 19px;
  line-height: 19px;
  text-align: center;
  border-radius: 10px;
  font-size: 12px;
  margin-right: 5%;
}

.student_info_course .list_type.type_green {
  background-color: #ddf8eb;
  color: #1ed07a;
}

.student_info_course .list_type.type_yellow {
  background-color: #fff2c7;
  color: #ff8c39;
}

.student_info_course .list_type.type_red {
  color: #ff7527;
  border-color: #ffdfc7;
  background-color: #ffdfc7;
}

.student_info_course .list_type.type_gray {
  background-color: #f2f2f2;
  color: #999999;
}

.student_info_course .owe_text {
  margin: 0 3px;
}

.new_student_info_list {
  display: flex !important;
  /*   align-items: center;
  align-content: center; */
  padding: 16px 0;
  /* min-height: 140rpx; */
  justify-content: space-between;
  /* border-bottom: 1px solid #ececec; */
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.new_student_info_list:last-child {
  /* border-bottom: none; */
}

.new_student_info_list .list_date {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 215px;
}

.new_student_info_list .list_time {
  font-size: 12px;
  line-height: 15px;
  color: #999;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 215px;
}

.new_student_info_list .list_time.no_hidden {
  white-space: pre-wrap;
  overflow: visible;
}

.new_student_info_list .list_right {
  display: flex;
  padding-right: 18px;
  background: url(./../../../../../../public/image/next_icon@2x.png) no-repeat
    right center;
  background-size: 8px;
  align-items: center;
  align-content: center;
}

.new_student_info_list .list_type {
  width: 38px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 10px;
  font-size: 12px;
  margin-right: 5px;
}

.new_student_info_list .list_type.type_green {
  background-color: #ddf8eb;
  color: #1ed07a;
}

.new_student_info_list .list_type.type_yellow {
  background-color: #fff2c7;
  color: #ff8c39;
}

.new_student_info_list .list_type.type_red {
  color: #ff7527;
  border-color: #ffdfc7;
  background-color: #ffdfc7;
}

.new_student_info_list .list_type.type_pink {
  color: var(--skin-formInfoColor);
  background-color: #fcedf0;
}

.new_student_info_list .list_qian {
  width: 18px;
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
  color: #f56c6c;
  background-color: #fde9e9;
}

.new_student_info_list .list_number {
  font-size: 16px;
  line-height: 18px;
  height: 18px;
  font-weight: bold;
  font-family: Arial;
  min-width: 35px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new_student_info_list .list_number .list_number {
  min-width: 60px;
  display: inline-block;
  vertical-align: top;
  text-align: right;
  padding-left: 15px;
}

.new_student_info_list .list_number.next_icon {
  padding-right: 18px;
  background: url(./../../../../../../public/image/next_icon@2x.png) no-repeat
    right center;
  background-size: 8px;
}

.new_student_info_list .list_number .text_box {
  display: inline-block;
  vertical-align: top;
  min-width: 38px;
  text-align: right;
  padding-left: 5px;
}

.new_student_info_list .list_number .list_text {
  font-weight: 400;
}
.star_data_list {
  line-height: 60px;
  position: relative;
  padding-right: 16px;
  /* margin-right: 34rpx; */
}

.star_data_list::after {
  content: '';
  position: absolute;
  top: 28px;
  right: 0;
  width: 0;
  height: 0;
  border-width: 6px;
  border-style: solid;
  border-color: #999999 transparent transparent transparent;
}

/* .star_data_list::after {
    content: '';
    position: absolute;
    top: 28rpx;
    right: 0;
    width: 0;
    height: 0;
    border-width: 8rpx;
    border-style: solid;
    border-color: transparent transparent black transparent
  } */
</style>
