<template>
  <!-- 通用列表 -->
  <div>
    <!-- 列表名称 -->
    <div class="list_mane"
         v-if="vlistTitleName"
         style="">
      <img src="../../../../public/image/screening_condition_number_icon01.png"
           alt>{{vlistTitleName}}
    </div>
    <!-- 弹框显示模式 -->
    <div v-if="visDialogShow">
      <custom-dialog :title="listTitle"
                     width="1213px"
                     class="class_staistics_list defult_dialog"
                     :visible.sync="isShowListDialog"
                     :before-close="closeDialog">
        <div class="table_list_content">
          <div class="single_result_detail">
            <!-- 搜索栏 -->
            <div class="table_searchBar detail_info_searchBar single_shadow">
              <!-- 搜索条件(单行、多行) -->
              <div class="table_searchBar_inputList">
                <!-- 普通搜索 -->
                <div class="detail_info_searchInput">
                  <input type="text"
                         placeholder="搜索"
                         class
                         v-model="searchText"
                         @keyup.enter="searchBtn">
                </div>
              </div>
              <!-- 展开、收起 -->
              <div class="expend_btn"
                   v-if="extendSearchCondition.length>0 && !isSearch">
                <span class="expend_down"
                      v-if="!isShowMoreSearchCondition"
                      @click.stop="showMoreSearchCondition">展开</span>
                <span class="expend_up"
                      v-if="isShowMoreSearchCondition"
                      @click.stop="hideMoreSearchCondition">收起</span>
              </div>
              <!-- 搜索按钮 -->
              <div class="search_btn">
                <el-button class="btn_light_blue"
                           @click.stop="searchBtn">搜索</el-button>
                <el-button class="btn_light_blue el-icon-delete"
                           v-if="btnDelete"
                           @click.stop="btnDeleteList(1)">回收站 : {{hiddenNumber}}</el-button>
              </div>
              <!-- 按钮组 （自定义多个 eg:新增 导入 导出 批量 ？？以对象接受 还是 多个值控制）-->
              <div class="search_btn_group search_btn fr"
                   v-if="functionBtnGroup">
                <el-button class="btn_light_blue add_icon"
                           v-for="(item,index) in functionBtnGroup"
                           :key="index"
                           :class="hasRole(item.roleName)"
                           @click.stop="actionBtnClick(item)">{{item.name}}</el-button>
              </div>
              <!-- 展开、收起内容 里面有多个查询条件 日期 下拉列表 数字范围 -->
              <div v-if="isShowMoreSearchCondition"
                   class="add_screening_condition clearfix">
                <extend-search-condition-group ref="searchConditionGroup"
                                               :extendSearchCondition="extendSearchCondition"
                                               @getSelectedParamsByClickSearchBtn="getSelectedParamsByClickSearchBtn"></extend-search-condition-group>
              </div>
            </div>
            <!-- 表格 -->
            <div class="table_content single_shadow">
              <!-- 筛选条件 -->
              <div class="screening_condition clearfix">
                <div>
                  <div class="screening_condition_number fl"
                       v-if="isLoading">
                    <img src="../../../../public/image/screening_condition_number_icon02.gif"
                         alt> 正在获取数据 , 请稍等...
                  </div>
                  <div class="screening_condition_number fl"
                       v-if="!isLoading">
                    <img src="../../../../public/image/screening_condition_number_icon01.png"
                         alt> 符合条件 : 共
                    <span>{{totalNum}}</span> 条。
                  </div>
                </div>
                <!-- 已选条件 -->
                <div v-if="(selectedParams.length>0 || unClearParams.length>0)&&isToSearch">
                  <selected-conditions-group ref="selectedParamsGroup"
                                             v-model="selectedParams"
                                             :extendSearchCondition="extendSearchCondition"
                                             :unClearParams="unClearParams"
                                             @changeExtendSearchParams="doAfterChangeExtendSearchParams"></selected-conditions-group>
                  <el-button class="btn_light_blue fr"
                             v-if="isToSearch"
                             @click.stop="clearExtendSearchParams">清除条件</el-button>
                </div>
              </div>
              <!-- 列表 -->
              <!-- 
            选中行高亮  highlight-current-row 
            隔行换色  stripe
            数据为空展示的文字  empty-text
            选中行 复选框联动  toggleRowSelection
          -->
              <el-table class="table_list"
                        ref="multipleTable"
                        :data="tableData"
                        highlight-current-row
                        stripe
                        empty-text
                        toggleRowSelection
                        @sort-change="sortChange"
                        @row-click="clickRow"
                        :row-key="rowKey||'none'"
                        :expand-row-keys="expandRowKeys"
                        style="width: 100%">
                <!-- 表头 -->
                <el-table-column type="selection"
                                 width="55"></el-table-column>
                <el-table-column v-for="(item,index) in vTableColumns"
                                 :width="item.width"
                                 :sortable="item.sortable"
                                 :align="item.align"
                                 :key="index"
                                 :type="item.type"
                                 :label="item.label"
                                 :prop="item.prop"
                                 :show-overflow-tooltip="true">
                  <template slot-scope="scope">
                    <span v-if="item.type=='number'">{{rounding(scope.row,item.prop,item.decimalPlace)}}</span>
                    <el-button v-else-if="item.type=='tableBtn'"
                               v-for="(info,indexs) in item.tableBtnGroup"
                               :class="info.className(scope.row[info.Stutas.split(',')[0]],scope.row[info.Stutas.split(',')[1]])"
                               class="tableBtn"
                               :key="indexs"
                               size="mini"
                               @click.stop="tableBtnClick(scope.row,info)">{{info.eventName == 9?ifBtnName(scope.row):info.name}}</el-button>
                    <span v-else-if="item.type=='tolink'"
                          @click.stop="openLink(scope.row[item.prop])">{{scope.row[item.prop]}}</span>
                    <span v-else-if="item.type=='html'"
                          v-html="scope.row[item.prop]">{{scope.row[item.prop]}}</span>
                    <span v-else>{{scope.row[item.prop]}}</span>
                  </template>
                </el-table-column>
                <!-- 收起部分内容 -->
                <el-table-column fixed="center"
                                 type="expand">
                  <template slot-scope="props">
                    <el-form label-position="left"
                             inline
                             class="table_expand_item demo-table-expand">
                      <el-form-item :label="item.label"
                                    v-for="(item,index) in detailColumns"
                                    :key="index">
                        <span :title="props.row[item.prop]">{{ props.row[item.prop] }}</span>
                      </el-form-item>
                    </el-form>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <div class="table_pagination">
                <el-pagination @current-change="handleCurrentChange"
                               :page-size="10"
                               :current-page.sync="vPageIndex"
                               background
                               layout="prev, pager, next, jumper"
                               :total="totalNum"></el-pagination>
              </div>
            </div>
          </div>
        </div>
      </custom-dialog>
    </div>
    <!-- 全屏显示模式 -->
    <div v-else>
      <div class="table_list_content">
        <div class="single_result_detail">
          <!-- 搜索栏 -->
          <div class="table_searchBar detail_info_searchBar single_shadow">
            <!-- 搜索条件(单行、多行) -->
            <div class="table_searchBar_inputList">
              <!-- 普通搜索 -->
              <div class="detail_info_searchInput">
                <input type="text"
                       placeholder="搜索"
                       class
                       v-model="searchText"
                       @keyup.enter="searchBtn">
              </div>
            </div>
            <!-- 展开、收起 -->
            <div class="expend_btn"
                 v-if="extendSearchCondition.length>0">
              <span class="expend_down"
                    v-if="!isShowMoreSearchCondition"
                    @click.stop="showMoreSearchCondition">展开</span>
              <span class="expend_up"
                    v-if="isShowMoreSearchCondition"
                    @click.stop="hideMoreSearchCondition">收起</span>
            </div>
            <!-- 搜索按钮 -->
            <div class="search_btn">
              <el-button class="btn_light_blue"
                         @click.stop="searchBtn">搜索</el-button>
              <el-button class="btn_light_blue el-icon-delete"
                         v-if="btnDelete"
                         @click.stop="btnDeleteList(1)">回收站 : {{hiddenNumber}}</el-button>
            </div>
            <!-- 按钮组 （自定义多个 eg:新增 导入 导出 批量 ？？以对象接受 还是 多个值控制）-->
            <div class="search_btn_group search_btn fr"
                 v-if="functionBtnGroup">
              <el-button class="btn_light_blue add_icon"
                         v-for="(item,index) in functionBtnGroup"
                         :key="index"
                         :class="hasRole(item.roleName)"
                         @click.stop="actionBtnClick(item)">{{item.name}}</el-button>
            </div>
            <!-- 展开、收起内容 里面有多个查询条件 日期 下拉列表 数字范围 -->
            <div v-if="isShowMoreSearchCondition"
                 class="add_screening_condition clearfix">
              <extend-search-condition-group ref="searchConditionGroup"
                                             :extendSearchCondition="extendSearchCondition"
                                             @getSelectedParamsByClickSearchBtn="getSelectedParamsByClickSearchBtn"></extend-search-condition-group>
            </div>
          </div>
          <!-- 表格 -->
          <div class="table_content single_shadow">
            <!-- 筛选条件 -->
            <div class="screening_condition clearfix">
              <div>
                <div class="screening_condition_number fl"
                     v-if="isLoading">
                  <img src="../../../../public/image/screening_condition_number_icon02.gif"
                       alt> 正在获取数据 , 请稍等...
                </div>
                <div class="screening_condition_number fl"
                     v-if="!isLoading">
                  <img src="../../../../public/image/screening_condition_number_icon01.png"
                       alt> 符合条件 : 共
                  <span>{{totalNum}}</span> 条。
                </div>
              </div>
              <!-- 已选条件 -->
              <div v-if="selectedParams.length>0">
                <selected-conditions-group ref="selectedParamsGroup"
                                           v-model="selectedParams"
                                           :extendSearchCondition="extendSearchCondition"
                                           @changeExtendSearchParams="doAfterChangeExtendSearchParams"></selected-conditions-group>
                <el-button class="btn_light_blue fr"
                           @click.stop="clearExtendSearchParams">清除条件</el-button>
              </div>
            </div>
            <!-- 列表 -->
            <!-- 
            选中行高亮  highlight-current-row 
            隔行换色  stripe
            数据为空展示的文字  empty-text
            选中行 复选框联动  toggleRowSelection
          -->
            <el-table class="table_list"
                      ref="multipleTable"
                      :data="tableData"
                      highlight-current-row
                      stripe
                      empty-text
                      toggleRowSelection
                      @sort-change="sortChange"
                      @row-click="clickRow"
                      :row-key="rowKey||'none'"
                      :expand-row-keys="expandRowKeys"
                      style="width: 100%">
              <!-- 表头 -->
              <el-table-column type="selection"
                               width="30"></el-table-column>
              <el-table-column v-for="(item,index) in vTableColumns"
                               :width="item.width"
                               :sortable="item.sortable"
                               :align="item.align"
                               :key="index"
                               :type="item.type"
                               :label="item.label"
                               :prop="item.prop"
                               :show-overflow-tooltip="true">
                <template slot-scope="scope"
                          class="table_btn">
                  <span v-if="item.type=='number'">{{rounding(scope.row,item.prop,item.decimalPlace)}}</span>
                  <!-- && scope.row[item.prop] -->
                  <el-button v-else-if="item.type=='tableBtn'"
                             v-for="(info,indexs) in item.tableBtnGroup"
                             :class="info.className(scope.row[info.Stutas])"
                             style=" margin-right: 2px;"
                             :key="indexs"
                             size="mini"
                             @click.stop="tableBtnClick(scope.row,info)">{{info.eventName == 9?ifBtnName(scope.row):info.name}}</el-button>
                  <span v-else-if="item.type=='tableBtn'&&!scope.row[item.QRcode]">无</span>

                  <span v-else-if="item.type=='qrcode'">
                    <span v-if="scope.row[item.prop]">
                      <el-button v-for="(info,indexs) in item.tableBtnGroup"
                                 :class="info.className(scope.row[info.Stutas])"
                                 style=" margin-right: 2px;"
                                 :key="indexs"
                                 size="mini"
                                 @click.stop="tableBtnClick(scope.row,info)">
                        {{info.eventName == 9?ifBtnName(scope.row):info.name}}
                      </el-button>
                    </span>
                    <span v-else>无</span>
                  </span>

                  <!-- 图片.logo -->
                  <span v-else-if="item.type=='img'">
                    <img v-if="scope.row[item.prop]"
                         :src="scope.row[item.prop]"
                         style="width: 21px;height: 21px;"
                         @click="imgClick(scope.row[item.prop])"
                         alt="">
                    <span v-else>无</span>
                  </span>
                  <span v-else-if="item.type=='tolink'"
                        @click.stop="openLink(scope.row[item.prop])">{{scope.row[item.prop]}}</span>
                  <span v-else>{{scope.row[item.prop]}}</span>
                </template>
              </el-table-column>
              <!-- 收起部分内容 -->
              <el-table-column fixed="center"
                               type="expand">
                <template slot-scope="props">
                  <el-form label-position="left"
                           inline
                           class="table_expand_item demo-table-expand">
                    <el-form-item :label="getLabel(item,props)"
                                  :style="item.style?item.style:''"
                                  v-for="(item,index) in detailColumns"
                                  :key="index">
                      <span :title="props.row[item.prop]">{{ props.row[item.prop] }}</span>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="table_pagination">
              <el-pagination @current-change="handleCurrentChange"
                             :page-size="10"
                             :current-page.sync="vPageIndex"
                             background
                             layout="prev, pager, next, jumper"
                             :total="totalNum"></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import extendSearchConditionGroup from './extend-search-condition-group/index';
import selectedConditionsGroup from './selected-conditions-group/index';
import initJson from '../../../js/initJson.json';
import { parseJson } from '../../../utils';
// import layer from '../../../public/js/layer';
let initQueryData = {
  pageIndex: 0,
  pageSize: 10,
  searchText: '',
  orderBy: '',
  sequence: ''
};
export default {
  data () {
    return {
      searchText: '',
      queryParams: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: '',
        sequence: '',
        searchText: ''
      },
      isToSearch: true,
      isSearch: false,      // 决定是否显示展开按钮
      isShowListDialog: false, // 是否显示主界面
      isShowMoreSearchCondition: false, // 是否显示更多查询条件
      selectedRow: {}, // 选中行
      isLoading: true, // 正在加载
      selectedParams: [], // 扩展查询参数 [{name:'参数名',value:'值'}]
      unClearParams: [], // 不可清除只展示的显示参数 [{name:'参数名',value:'值'}]
      searchConditionType: {}, // 扩展查询条件类型
      expandRowKeys: [] // 展开的行,
    };
  },
  props: {
    listTitle: {
      // 列表头
      type: String,
      required: true
    },
    // queryParamsForList: Object,
    extendSearchCondition: {
      type: Array,
      default: function () {
        return [
          {
            type: 0, // 控件类型（js->initJson->extendSearchConditionType{ "dateRange": 1 //日期范围,"selectOption": 2//下拉选择,"numberRange": 3//数字范围}）
            formTitle: '', // 标题名
            fieldList: [
              {
                name: '', // 参数名
                defaultValue: '', // 默认值
                value: '', // 绑定值
                validateHandle: '', // 自定义验证函数
                sourceData: [] // 数据源（下拉选择需要 默认为空数组）
              }
            ]
          }
        ];
      }
    }, // 扩展查询条件
    functionBtnGroup: Array, // 功能按钮组
    tableData: Array, // 列表数据
    detailColumns: Array, // 显示详情内容
    rowKey: String, // 显示详情对应绑定的ID
    totalNum: Number, // 列表总共条数
    PageCount: Number, // 共几页
    tableColumns: Array, // table column
    btnDelete: false, // 回收站显示隐藏
    hiddenNumber: 0, // 回收站总数据
    isDialogShow: String, // 是否需要显示弹框，默认开启
    listTitleName: String // 是否显示自定义列表名称

  },
  components: {
    extendSearchConditionGroup,
    selectedConditionsGroup
  },
  computed: {
    visDialogShow () {
      if (this.isDialogShow == '关闭') {
        return false;
      } else {
        return true;
      }
    },
    vlistTitleName () {
      if (this.listTitleName) {
        return this.listTitleName;
      } else {
        return false;
      }
    },
    vPageIndex: {
      get () {
        return this.queryParams.pageIndex + 1;
      },
      set (n, o) {
      }
    },
    // 标题头部
    vTableColumns () {
      let newTableColumns = [];
      this.tableColumns.forEach(element => {
        if (element.isShow) {
          newTableColumns.push(element);
        }
      });
      return newTableColumns;
    }

  },
  created () {
    this.searchConditionType = initJson.extendSearchConditionType;
    this.initQueryParams();
  },
  methods: {
    vItem (imgurl) {
      var ImgObj = new Image(); // 判断图片是否存在
      ImgObj.src = imgurl;
      // 存在图片
      if (ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)) {
        return true;
      } else {
        return false;
      }
    },
    logoImgShow (logo) {
      console.log(logo, 'data');
      if (logo) {
        if (logo.indexOf('/Upload/') != -1) {
          return this.$store.getters.CDNURL + logo;
        } else {
          return this.$store.getters.CDNURL + '/PUBLIC/images/base64img/' + logo;
        }
      } else {
        return 'this.src="' + '"';
      }
    },
    getLabel (item, props) {
      // console.log(props.row[item.dynamicLabel], '1111111');
      // console.log(item, '22222222');
      if (props.row[item.dynamicLabel]) {
        return props.row[item.dynamicLabel];
      } else {
        if (item.label) {
          return item.label + ' : ';
        } else {
          return '';
        }
      }
    },
    hasRole (roleName) {
      return true;
    },
    // 域名打开新窗口
    openLink (rowData) {
      console.log(rowData, 'rowData');
      if (rowData) {
        window.open(rowData);
      } else {
        layer.alert('无法打开网页');
      }
    },
    // 首次打开 初始化查询参数（对外开放）
    initSelectedParams (selectedParams, unClearParams) {
      this.getSelectedParamsByClickSearchBtn(selectedParams);

      this.unClearParams = unClearParams;
    },
    // 构建查询参数
    initQueryParams () {
      this.queryParams = parseJson(initQueryData);
      this.extendSearchCondition.forEach(condition => {
        condition.fieldList.forEach((param, index) => {
          this.queryParams[param.name] = param.defaultValue;
        });
      });
    },
    // 点击查询按钮
    searchBtn () {
      // 若更多查询条件 显示 重新收集已选参数

      if (this.isShowMoreSearchCondition) {
        this.$refs.searchConditionGroup.getSelectedParams(this.selectedParams);
        // 反之 直接查询
      } else {
        this.queryParams.pageIndex = 0;
        this.queryParams.searchText = this.searchText;
        this.$emit('loadTableData', this.queryParams);
      }
      this.$refs.multipleTable.setCurrentRow();
      this.selectedParams.forEach(o => {
        if (o.name == 'isshare' && o.value !== 0) {
          this.isToSearch = true;
        }
        if (o.name == 'isshare' && o.value == 0) {
          this.isToSearch = false;
        }
      });
    },
    // 点击查询后 获取已选参数条件 并且拼接查询参数 获取列表
    getSelectedParamsByClickSearchBtn (selectedParams) {
      this.initQueryParams();
      console.log(this.selectedParams, 'this.selectedParams');
      this.selectedParams = selectedParams;
      for (var index in selectedParams) {
        this.queryParams[selectedParams[index].name] = selectedParams[index].value;
      }
      this.isShowMoreSearchCondition = false;
      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.setCurrentRow();
      }
      this.queryParams.searchText = this.searchText;
      this.$emit('loadTableData', this.queryParams);
    },
    // 显示更多查询条件
    showMoreSearchCondition () {
      this.isShowMoreSearchCondition = true;
      this.$nextTick(() => {
        this.$refs.searchConditionGroup.showExtendSearchCondition(this.selectedParams);
      });
    },
    // 跳转到第几页
    handleCurrentChange (val) {
      this.selectedRow = {};
      this.$refs.multipleTable.setCurrentRow();
      this.queryParams.pageIndex = val - 1;
      this.$emit('loadTableData', this.queryParams);
    },
    // 保留小数位数
    rounding (row, value, decimalPlace) {
      return Number(row[value]).toFixed(decimalPlace);
    },
    // 排序
    sortChange (column, prop) {
      if (column.prop) {
        this.queryParams.orderBy = column.prop;
        this.queryParams.sequence =
          column.order == 'ascending' ? 'asc' : 'desc';
      }
      this.$emit('loadTableData', this.queryParams);
    },
    // 隐藏更多查询条件
    hideMoreSearchCondition () {
      this.isShowMoreSearchCondition = false;
    },
    // 删除某个已选条件 重构queryParams重新刷新
    doAfterChangeExtendSearchParams () {
      this.getSelectedParamsByClickSearchBtn(this.selectedParams);
    },
    // 清除已选条件栏  需重新刷新
    clearExtendSearchParams () {
      let unClearSelectedParams = [];
      this.selectedParams.forEach(obj => {
        if (obj.unClear) {
          unClearSelectedParams.push(obj);
        }
      });
      this.selectedParams = unClearSelectedParams;
      console.log(this.selectedParams, 'selectedParams');
      this.getSelectedParamsByClickSearchBtn(this.selectedParams);
      this.isToSearch = false;
    },
    // 关闭窗口
    closeDialog () {
      this.isShowListDialog = false;
      this.searchText = '';
      this.isShowMoreSearchCondition = false;
      this.isToSearch = true;
      this.initQueryParams();
    },
    // 点击功能按钮
    actionBtnClick (item) {
      this.$emit('actionBtnClick', item.eventName);
    },
    // 点击图片
    imgClick (rowData) {
      console.log(rowData, '点击图片');
      this.$emit('imgClick', rowData);
    },
    // 操作点击表单按钮(第一个参数为券对象，第二个参数为按钮对象)
    tableBtnClick (rowData, item) {
      console.log(item.name, '功能按钮');
      // console.log(rowData);
      switch (item.name) {
        case '停用':
          this.doAffirmHint(rowData, item, item.name);
          break;
        case '启用':
          this.doAffirmHint(rowData, item, item.name);
          break;
        case '删除':
          this.doAffirmHint(rowData, item, item.name);
          break;
        default:
          if (item.eventName == initJson.baseFunctionBtnName.detail) {
            if (rowData[this.rowKey] == this.expandRowKeys[0]) {
              this.expandRowKeys = [];
            } else {
              this.expandRowKeys = [];
              this.expandRowKeys.push(rowData[this.rowKey]);
              this.$emit('tableBtnClick', rowData, item.eventName);
            }
          } else {
            this.$emit('tableBtnClick', rowData, item.eventName);
          }
          break;
      }
    },
    doAffirmHint (rowData, item, hint) {
      var _this = this;
      layer.confirm('请确定是否' + hint + '?', {
        btn: [{
          name: '确认',
          callBack: function () {
            layer.close();
            // layer.alert(hint + '成功', {
            // btn: [ {
            // name: '确认',
            // callBack: function () {
            if (item.eventName == initJson.baseFunctionBtnName.detail) {
              if (rowData[_this.rowKey] == _this.expandRowKeys[0]) {
                _this.expandRowKeys = [];
              } else {
                _this.expandRowKeys = [];
                _this.expandRowKeys.push(rowData[_this.rowKey]);
                _this.$emit('tableBtnClick', rowData, item.eventName);
              }
            } else {
              _this.$emit('tableBtnClick', rowData, item.eventName);
            }
            layer.close();
            // }
            // }
            // ]
            // });
          }
        }
        ]
      });
    },
    // 选中行
    clickRow (row, column, event) {
      this.selectedRow = row;
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row, true);
    },
    // 回收站数据切换
    btnDeleteList (IsHiddenKey) {
      let HiddenKey = true;
      this.selectedParams.forEach(e => {
        if (e.name == 'IsHiddenKey' && e.value == 1) {
          HiddenKey = false;
        }
      });
      if (HiddenKey) {
        this.selectedParams = [];
        this.selectedParams.push({ name: 'IsHiddenKey', value: 1 });

        this.getSelectedParamsByClickSearchBtn(this.selectedParams);
      }
    },
    // 判定显示按钮名
    ifBtnName (data) {
      if (data.IsHiddenKey == 1) {
        return '恢复';
      } else {
        return '隐藏';
      }
    }
  }
};
</script>
<style>
</style>


