<template>
  <div>
    <div class="my_view_info_teacher box_label_shadow">
      <div class="teacher_img fl">
        <img :src="headImg"
             :onerror="defaultImg"
             alt="" />
        <input type="file"
               name="file"
               title=""
               accept="image/*"
               ref="uploading"
               v-on:change="selectImg"
               class="staff_upload"
               data-type="userIconImg" />
      </div>
      <div class="teacher_info fl">
        <div class="teacher_info_name"
             :class="mSexKey(staffInfo.SexKey)">
          <span class="pr">{{ staffInfo.MainDemoName }}</span>
          <span class="teacher_info_post">{{ staffInfo.PostKeyValue }}</span>
        </div>
        <div class="teacher_info_list">
          <span class="teacher_info_title">门店ID</span>
          <span class="font_gray">:</span>
          <span>{{ staffInfo.Support20 }}</span>
        </div>
        <div class="teacher_info_list">
          <span class="teacher_info_title">手机</span>
          <span class="font_gray">:</span>
          <span>{{ staffInfo.Mobile }}</span>
        </div>
        <div class="teacher_info_list pr">
          <span class="teacher_info_title">密码</span>
          <span class="font_gray">:</span>
          <span>******</span>
          <div class="teacher_info_btn"
               @click="chgPassWord">
            修改密码
          </div>
        </div>
      </div>
    </div>
    <!-- 密码修改层 -->
    <custom-dialog title="修改密码"
                   :visible.sync="isAlterMessagePopup"
                   :before-close="hideAlterMessagePopup">
      <div class="content_marginTop_30 content_marginRL">
        <div class="form_info_edit mendian_setting_content single_shadow">
          <div class="form_info_line">
            <div class=" form_info_field">
              <span>原密码</span>
            </div>
            <div class="form_info_value font_cyan">
              <input type="password"
                     autocomplete="new-password"
                     placeholder="请输入原密码"
                     v-model="oldPwd"
                     v-show="!showloginPwd" />
            </div>
          </div>
          <div class="form_info_line">
            <div class=" form_info_field">
              <span>新密码</span>
            </div>
            <div class="form_info_value font_cyan">
              <input type="password"
                     placeholder="密码长度6-18位,非纯数字"
                     autocomplete="off"
                     v-model="newPwd1" />
            </div>
          </div>
          <div class="form_info_line">
            <div class=" form_info_field">
              <span>确认密码</span>
            </div>
            <div class="form_info_value font_cyan">
              <input type="password"
                     placeholder="请确认密码"
                     autocomplete="off"
                     v-model="newPwd2" />
            </div>
          </div>

        </div>
      </div>
      <div class="message_box_btn clearfix">
        <div @click="hideAlterMessagePopup"
             class="btn_close btn_hover_white fl">
          取消
        </div>
        <div @click="userSubmit"
             class="btn_confirm btn_hover_bg_blue fr">
          确认
        </div>
      </div>
    </custom-dialog>
  </div>
</template>
<script>
import { EditPassword } from '@/API/workbench.js';
export default {
  data () {
    return {
      isAlterMessagePopup: false,
      oldPwd: '',
      newPwd1: '',
      newPwd2: '',
      showloginPwd: false
    };
  },
  props: ['staffInfo'],
  computed: {
    // 头像
    headImg () {
      if (!this.staffInfo.XMLIcon) {
        this.staffInfo.XMLIcon = '';
      }
      if (this.staffInfo.XMLIcon.indexOf('http') > -1) {
        return this.staffInfo.XMLIcon;
      } else {
        console.log(this.staffInfo.XMLIcon, 'headImgheadImg');
        return this.$store.getters.CDNURL + this.staffInfo.XMLIcon;
      }
    },
    // 默认显示头像
    defaultImg () {
      return (
        'this.src="' +
        require('../../../../public/image/deflut_teacher_photo@2x.png') +
        '"'
      );
    }
  },
  methods: {
    mSexKey (SexKey) {
      if (SexKey == 3) {
        return 'text_red';
      } else if (SexKey == 2) {
        return 'text_blue';
      } else {
        return '';
      }
    },
    chgPassWord () {
      this.isAlterMessagePopup = true;
    },
    hideAlterMessagePopup () {
      this.oldPwd = '';
      this.newPwd1 = '';
      this.newPwd2 = '';
      this.isAlterMessagePopup = false;
    },
    submitStudentArrangeSupport () { },
    // 确认修改密码确认判断
    userSubmit () {
      // 旧密码
      if (this.oldPwd == '') {
        layer.alert('原密码不能为空');
      } else {
        if (this.newPwd1 == '') {
          // 新密码
          layer.alert('新密码不能为空');
        } else if ((this.newPwd1.length < 6 || this.newPwd1.length > 18) || (!isNaN(this.newPwd1) && this.newPwd1.indexOf('.') == -1)) {
          layer.alert('密码长度为6-18位,非纯数字');
        } else {
          if (this.newPwd2 == '') {
            // 确认密码
            layer.alert('确认密码不能为空');
          } else if (this.newPwd2 != this.newPwd1) {
            layer.alert('两次输入密码不一致');
          } else {
            // 全部输入正确
            EditPassword(this.oldPwd, this.newPwd1).then(result => {
              this.hideAlterMessagePopup();
              // layer.alert('修改密码成功');
            }, errer => {
              layer.alert(errer.msg);
            });
          }
        }
      }
    },
    selectImg (e) {
      this.$emit('selectImg', e, 'staff', () => {
        this.$refs.uploading.value = '';
      });
    }
  }
};
</script>
