<template>
  <div class="md_info_box"
       id="mendain_set_form">
    <div class="form_info_edit form_info_list"
         style="min-height: 283px"
         id="mendain_set_form">
      <div>
        <div id="screanContent"
             style="z-index:1;position: absolute !important;right: 45px; opacity: 0"></div>
        <input-upload-img :ImgURl="dataInfo.LogoPath"
                          :formTitle="'门店logo'"
                          :imgHeight="101"
                          :imgWidth="101"
                          :readonly="false"
                          :required="false"
                          @doSetPicCorp="doSetPicCorpLogoImg"></input-upload-img>
      </div>
      <input-phone :formTitle="'机构电话'"
                   :formPlaceholder="'必填'"
                   :numberType="'text'"
                   :required="true"
                   :readonly="false"
                   v-model="dataInfo.Telephone"></input-phone>
      <input-search-cities :formTitle="'所在地区'"
                           :required="false"
                           :readonly="false"
                           :selectedInfo="locationAreaInfo"
                           @selectedItem="changeRegion"></input-search-cities>
      <input-text :formTitle="'详细地址'"
                  :readonly="false"
                  :required="false"
                  :formPlaceholder="'详细地址'"
                  v-model="dataInfo.DetailAddress"></input-text>
      <!-- <input-text :formTitle="'门店地址'"
                  :required="true"
                  :readonly="isCampStore"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.Address"></input-text> -->
      <div>
        <input-dialog :formTitle="'地图定位'"
                      :customPlaceholder="true"
                      :formPlaceholder="'设置'"
                      :formInputHint="locationInfo[0].SetContent?'':'未设置定位'"
                      :required="false"
                      :readonly="isCampStore"
                      :hiddenInput="true"
                      :class="locationInfo[0].SetContent?'content':''"
                      :value="locationInfo[0].SetContent ||'设置'"
                      @showEldilog="showLocationSet()"></input-dialog>

        <div class="mendian_location pr"
             v-if="vLocationImg != ''">
          <img :src="vLocationImg"
               alt="">
        </div>
        <div class="bottom_text_content"
             v-if="isCampStore">
          <div class="bottom_text_content_text"
               style="padding-bottom:10px">
            <div class="bottom_text_content_text_content">
              直营店 请从总部修改地址
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
    <custom-dialog :title="'位置'"
                   :visible.sync="isShowLocationForm"
                   :before-close="closeLocationFormPopup">
      <form-location :locationInfo="locationInfo"
                     @close="closeLocationFormPopup"
                     @setLocationInfo="setLocationInfo"></form-location>
    </custom-dialog>
  </div>
</template>
<script>
import { CWSEditSaaSClient, CWSGetSaaSClientMsgForUpdate, CWSModifySaaSClientSetting } from '../../../../API/workbench';
export default {
  data () {
    return {
      locationInfo: [
        { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '14' }, // 定位地址信息
        { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '15' }, // 定位经纬度信息
        { 'SetKey': '', 'SetKeyValue': '', 'SetContent': '', 'SetTypeKey': '16' }// 定位截图信息
      ],
      dataInfo: {
        MainDemoName: '', //	整型	门店名称
        Telephone: '', //	整型	门店手机号
        Address: '', //	整型	地址
        BrandPath: '', //	整型	品牌图片
        LogoPath: '', //	整型	logo
        DetailAddress: '',
        ProvinceKey: '',
        ProvinceKeyValue: '',
        CityKey: '',
        CityKeyValue: '',
        AreaKey: '',
        AreaKeyValue: ''

      },
      locationAreaInfo: [], // 地址
      isShowLocationForm: false
    };
  },
  components: {

  },
  props: {

  },
  created () {
    console.log(this.$store.getters, 'initAddData');
    this.getSaaSClientMsgForUpdate();
  },
  computed: {

    vmendianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    // 定位截图
    vLocationImg () {
      if (this.vmendianInfo && this.locationInfo[2].SetContent) {
        return this.$store.getters.CDNURL + this.locationInfo[2].SetContent;
      } else {
        return '';
      }
    },
    // 是否连锁直营门店。
    isCampStore () {
      return this.vmendianInfo.TypeKey == 8;
    }
  },
  methods: {
    // 省市区
    changeRegion (keys, values) {
      this.dataInfo.ProvinceKey = keys[0] || '';// 省
      this.dataInfo.ProvinceKeyValue = values[0] || ''; // 省
      this.dataInfo.CityKey = keys[1] || '';// 市
      this.dataInfo.CityKeyValue = values[1] || ''; // 市
      this.dataInfo.AreaKey = keys[2] || ''; // 区/县
      this.dataInfo.AreaKeyValue = values[2] || ''; // 区/县
      console.log(keys, values, '省市区');
    },
    doSetPicCorpBrandImg (data) {
      console.log(data, '品牌图片');
      this.dataInfo.BrandPath = data;
    },
    doSetPicCorpLogoImg (data) {
      console.log(data, '门店LOGO');
      this.dataInfo.LogoPath = data;
    },
    getSaaSClientMsgForUpdate () {
      CWSGetSaaSClientMsgForUpdate().then(result => {
        console.log(result.data, '门店信息');
        Object.assign(this.dataInfo, result.data);
        this.$nextTick(o => {
          this.locationAreaInfo = [this.dataInfo.ProvinceKey, this.dataInfo.CityKey, this.dataInfo.AreaKey];
        });
        this.getLocation();
      }, error => {
        layer.alert(error.msg);
      });
    },
    showLocationSet () {
      this.isShowLocationForm = true;
    },
    closeLocationFormPopup () {
      this.isShowLocationForm = false;
    },
    setLocationInfo (data) {
      this.locationInfo = data;
      this.closeLocationFormPopup();
    },
    // 提交
    submit (callback) {
      console.log('submit', this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      CWSEditSaaSClient(this.dataInfo).then(result => {
        console.log('修改成功！', result.data);
        let SaaSClientInfo = this.$store.getters.SaaSClientInfo;
        SaaSClientInfo.MainDemoName = this.dataInfo.MainDemoName;//	整型	门店名称
        SaaSClientInfo.Telephone = this.dataInfo.Telephone; //	整型	门店手机号
        SaaSClientInfo.Address = this.dataInfo.Address;//	整型	地址
        SaaSClientInfo.BrandPath = this.dataInfo.BrandPath;//	整型	品牌图片
        SaaSClientInfo.LogoPath = this.dataInfo.LogoPath;//	整型	logo
        this.$store.commit('setSaaSClientInfo', SaaSClientInfo);
        layer.alert('修改成功！');
        if (this.locationInfo[0].SetContent) {
          this.modifySaaSClientSetting();
        }
        this.cancel();
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callback) {
          callback();
        }
      });
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
      // if (!this.dataInfo.MainDemoName) {
      //   layer.alert('门店名称不能为空');
      //   flag = false;
      // } else
      if (!this.dataInfo.Telephone) {
        layer.alert('机构电话不能为空');
        flag = false;
      }
      //  else if (!this.dataInfo.BrandPath) {
      //   layer.alert('品牌图片不能为空');
      //   flag = false;
      // } else if (!this.dataInfo.LogoPath) {
      //   layer.alert('门店LOGO不能为空');
      //   flag = false;
      // }
      return flag;
    },
    // 获取门店定位信息
    getLocation () {
      if (this.vmendianInfo.SaaSClientSetting.length > 0) {
        let findType14Item = this.vmendianInfo.SaaSClientSetting.find(o => {
          return Number(o.SetTypeKey) == 14;
        });
        let findType15Item = this.vmendianInfo.SaaSClientSetting.find(o => {
          return Number(o.SetTypeKey) == 15;
        });
        let findType16Item = this.vmendianInfo.SaaSClientSetting.find(o => {
          return Number(o.SetTypeKey) == 16;
        });
        this.locationInfo = [
          { 'SetKey': '', 'SetKeyValue': '', 'SetContent': findType14Item.SetContent ? findType14Item.SetContent : '', 'SetTypeKey': '14' },
          { 'SetKey': '', 'SetKeyValue': '', 'SetContent': findType15Item.SetContent ? JSON.parse(findType15Item.SetContent) : '', 'SetTypeKey': '15' },
          { 'SetKey': '', 'SetKeyValue': '', 'SetContent': findType16Item.SetContent ? findType16Item.SetContent : '', 'SetTypeKey': '16' }
        ];
      }
      console.log(this.locationInfo, '获取门店定位信息');
    },
    // 更新门店定位
    modifySaaSClientSetting () {
      let isStr = (typeof this.locationInfo[1].SetContent == 'string');
      if (!isStr) {
        this.locationInfo[1].SetContent = JSON.stringify(this.locationInfo[1].SetContent);
      }
      const locationArr = JSON.parse(this.locationInfo[1].SetContent);
      console.log(locationArr[0], 'locationArr');
      if (locationArr[0]) {
        CWSModifySaaSClientSetting(this.locationInfo).then(result => {
          // 地图定位设置
          if (this.vmendianInfo.SaaSClientSetting.length > 0) {
            this.vmendianInfo.SaaSClientSetting.forEach(o => {
              if (Number(o.SetTypeKey) == 14) { // 定位地址信息
                o.SetContent = this.locationInfo[0].SetContent;
              }
              if (Number(o.SetTypeKey) == 15) { // 定位经纬度信息
                o.SetContent = this.locationInfo[1].SetContent;
              }
              if (Number(o.SetTypeKey) == 16) { // 定位截图信息
                o.SetContent = this.locationInfo[2].SetContent;
              }
            });
          }
          console.log(this.vmendianInfo.SaaSClientSetting, '门店定位成功！');
        }, err => {
          layer.alert(err.msg);
        });
      }
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
<style>
.md_info_box .my_datum_picture_text {
  overflow: initial;
}
.md_info_box .my_datum_picture_portrait img {
  border-radius: 4px !important;
}
.bottom_text_content {
  display: flex;
  flex-direction: column;

  margin: 20px 0px;
  background: linear-gradient(0deg, #f9fbfd, #f9fbfd), #f5f5f5;
  border-radius: 8px;
}
.bottom_text_content_text {
  display: flex;
  flex-direction: row;
  color: #999;
  padding: 20px;
  font-size: 12px;
  line-height: 16px;
}
.bottom_text_content_text_title {
  width: 68px;
}
.bottom_text_content_text_content {
  flex: 1;
}
</style>

