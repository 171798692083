<template>
  <!-- 自定义展示项 （通用） -->
  <div class="">
    <div class="single_result single_class_info"
         :class="{active:wItemInfo.isActive}"
         @click.stop="selectedItem()"
         v-if="!multi">
      <div class="single_result_info_detail">
        <div class="student_info_name">
          <span>{{wItemInfo.dataItem.MainDemoName}}</span>
          <span v-if="wItemInfo.dataItem.CurrentPeoCount">（{{Number(wItemInfo.dataItem.CurrentPeoCount)}}人）</span>
        </div>
      </div>
    </div>
    <!-- 目前作用于客户列表 client-->
    <div class="single_client_result single_class_info"
         @click.stop="selectedItem()"
         v-else>
      <div class="single_result_info_detail">
        <div class="single_class_name">
          <!-- 主要参数-->
          <span>{{wItemInfo.CustKeyValue}}</span>
          <!-- <span>({{wItemInfo.RelationKeyValue}})</span> -->
          <span>{{$utils.phoneModulePower(wItemInfo.MobileSupport)||'无手机号'}}</span>
          
        </div>
        <div class="info_detail_item_client">
          <span class="info_detail_item_client_data">{{vMainDemoName}}</span>
          <span class="info_detail_item_client_data">{{vSexKeyValue}}</span>
          <span class="info_detail_item_client_data info_detail_item_client_data_right">{{vAgeName}}
            <!-- <div class="info_detail_item_right">{{vAgeName}}</div> -->
          </span>
          <!-- 辅助参数-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'singleSelectionItem',
  data () {
    return {
      titleValue: '',
      titleName: '',
      wItemInfo: ''
    };
  },
  props: ['itemInfo', 'multi', 'formTitle', 'dataSource'], // 单选数据项 需绑定到界面
  created () {
    if (this.itemInfo) {
      this.wItemInfo = this.itemInfo;
    }
  },
  watch: {
    itemInfo () {
      this.wItemInfo = this.itemInfo;
    }
  },
  computed: {
    vCustKeyValue () {
      return this.wItemInfo.CustKeyValue ? '客户 :' + this.wItemInfo.CustKeyValue + ' / ' : '';
    },
    vMainDemoName () {
      return this.wItemInfo.MainDemoName ? '学生 :' + this.wItemInfo.MainDemoName + ' / ' : '';
    },
    vSexKeyValue () {
      return this.itemInfo.SexKeyValue ? '性别 : ' + this.wItemInfo.SexKeyValue + ' / ' : '';
    },
    vAgeName () {
      return this.wItemInfo.AgeName ? this.wItemInfo.AgeName + '岁' : '';
    }
  },
  methods: {
    selectedItem () {
      if (this.formTitle == '选择客户') {
        this.$emit('selectedItem', this.itemInfo);
      } else {
        this.dataSource.forEach(item => {
          if (item.dataItem.OLAPKey != this.itemInfo.dataItem.OLAPKey) {
            item.isActive = false;
          } else {
            item.isActive = !item.isActive;
          }
        });
        this.$emit('selectedItem', this.itemInfo.dataItem, this.itemInfo.isActive);
      }
    }
  }
};
</script>

