<template>
  <div class="credit_management_popup">
    <div class="popup_title_padding view_title_flex">
      <div class="popup_title_data_box">
        <div class="data_list">
          <span>奖励学生(人)</span>
          <span class="font_number">{{tableData.StudentCount}}</span>
        </div>
        <div class="data_list">
          <span>奖励学分</span>
          <span class="font_number">{{tableData.TotalCredit}}</span>
        </div>
        <div class="data_list">
          <span>兑换物品(个)</span>
          <span class="font_number">{{tableData.ExchangeCount}}</span>
        </div>
        <div class="data_list">
          <span>抵用学分</span>
          <span class="font_number">{{tableData.UseCredit}}</span>
        </div>
      </div>
      <input-search-contain-btn v-model="searchObj.searchText"
                                :placeholder="'请输入姓名'"
                                @clearSearch="clearSearchCondition"
                                @onSearchEvent="search"></input-search-contain-btn>
    </div>
    <div class="credit_management_table">
      <table-list class="summarizing_list table_list_content"
                  :tableData="vdataReportList"
                  :tableColumns="tableCourseColumns"
                  :totalNum="totalNum"
                  :queryParams="searchObj"
                  :footerContent="vFooterTotal"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import { MessageSearchPageForCreditDetails } from '../../../../API/workbench';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import tableList from '../../../common/table-list/index';
export default {
  data () {
    return {
      dataReportList: [],
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        startTime: '',
        endTime: '',
        StudentKey: ''
      },
      tableData: {},
      totalNum: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '奖励日期',
          prop: 'GenerateTime',
          width: 158,
          sortable: false,
          align: 'left',
          className: '',
          type: 'textItem'
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          width: 150,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '手机号',
          prop: 'CustomerPhoneName',
          width: 150,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '学分类型',
          prop: 'typeValue',
          width: 210,
          sortable: false,
          type: 'textItem'
        },
        {
          label: '详情',
          prop: 'Remark',
          minWidth: 270,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '学分',
          prop: 'Currency',
          width: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        }
      ]
    };
  },
  props: {
    selectStudent: Object
  },
  components: {
    inputSearchContainBtn,
    tableList
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        switch (Number(o.Type)) {
          case 1:
            o.typeValue = '老师批改作业';
            break;
          case 2:
            o.typeValue = '点评上课表现';
            break;
          case 3:
            o.typeValue = '报名收款';
            break;
          case 4:
            o.typeValue = '学生签到';
            break;
          case 5:
            o.typeValue = '家长回复点评';
            break;
          case 6:
            o.typeValue = '学生提交作业';
            break;
          case 7:
            o.typeValue = '转发分享点评和作业';
            break;
          case 8:
            o.typeValue = '积分兑好礼';
            break;
          case 9:
            o.typeValue = '调整学分';
            break;
          default:
            o.typeValue = '';
            break;
        }
        if (!o.Remark) {
          o.Remark = '-';
        }
        o.CustomerPhoneName = this.$utils.phoneModulePower(o.CustomerPhoneName) || '--';
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    }
  },
  watch: {
    'searchObj.searchText' (n, o) {
      if (this.selectStudent.OLAPKey && n != this.selectStudent.MainDemoName) {
        this.searchObj.StudentKey = '';
      }
    }
  },
  created () {
    if (this.selectStudent.OLAPKey) {
      this.searchObj.StudentKey = this.selectStudent.OLAPKey;
      this.searchObj.searchText = this.selectStudent.MainDemoName;
    }
    this.getDataList();
  },
  methods: {
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.StudentKey = '';
      this.searchObj.searchText = '';
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      if (this.searchObj.searchText == '') {
        this.searchObj.StudentKey = '';
      }
      this.getDataList();
    },
    getDataList () {
      MessageSearchPageForCreditDetails(this.searchObj).then(
        (res) => {
          this.totalNum = res.data.Total;
          this.dataReportList = res.data.PageDataList;
          this.tableData.TotalCredit = res.data.TotalCredit || 0;
          this.tableData.UseCredit = res.data.UseCredit || 0;
          this.tableData.StudentCount = res.data.StudentCount || 0;
          this.tableData.ExchangeCount = res.data.ExchangeCount || 0;
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    }
  }
};
</script>