import Vue from 'vue';
import Vuex from 'vuex';
import common from './modules/common';
import workBench from './modules/workBench';
import popUpMenu from './modules/popUpMenu';
import basicDataList from './modules/basic-data-list';
import ishowEditor from './modules/ishow-editor';
import homeSchoolCommunication from './modules/home-school-communication';
Vue.use(Vuex);

const store = new Vuex.Store({
  mutations: {
    resetStore (state) {
      let storeStateStr = window.SITE_CONFIG['storeState'];
      if (storeStateStr) {
        let sData = JSON.parse(window.SITE_CONFIG['storeState']);
        Object.keys(state).forEach(key => {
          state[key] = sData[key];
        });
      } else {
        // 无数据可还原，直接中止，重进入系统。
        console.log('无数据可还原，直接中止，重进入系统');
        location.href = location.origin + location.pathname;
      }
    },
    saveStore (state) {
      window.SITE_CONFIG = {};
      window.SITE_CONFIG['storeState'] = JSON.stringify(state);
    }
  },
  modules: {
    common,
    workBench,
    popUpMenu,
    basicDataList,
    homeSchoolCommunication,
    ishowEditor
  }
});

export default store;
