<template>
  <div class="loop_box">
    <div class="text_week"
         :class="arrangedItem.WeekKey>5?'type_red':'type_green'">{{arrangedItem.WeekKeyValue}}</div>
    <div class="text_date">{{arrangedItem.ClassTimeName}}</div>
    <div class="text_loop"
         v-if="arrangedItem.IsSyncArrangedKey==1"></div>
    <div class="text_temporary"
         v-else>{{arrangedItem.ClassCount>99?99:arrangedItem.ClassCount}}</div>
  </div>

</template>
<script>

export default {
  data () {
    return {
    };
  },
  props: {
    arrangedItem: Object // 列表数据

  },
  components: {

  },
  created () {

  },
  watch: {

  },
  mounted () {

  },
  methods: {

  },

  computed: {
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    }
  }
};
</script>
