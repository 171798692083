<template>
  <div class="">
    <search-box :isBatchDistribute="isBatchDistribute"
                :paramsForQuery="paramsForQuery"
                :batchAllotList="batchAllotList"
                @searchClick="searchClick"
                @clearSearchParams="clearSearchParams"
                @enableBatchAllot="enableBatchAllot"
                @doBatchAllot="distributeAdvisor"
                @cancleBatchDistribute="cancleBatchDistribute"
                @importSuccess="importSuccess"
                @openCommonCustomDialogByFactoryDialog="openCommonCustomDialogByFactoryDialog"></search-box>
    <div class="table_list_content">
      <table-list ref="tableListRef"
                  :tableData="investorList"
                  :tableColumns="tableColumns"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="paramsForQuery"
                  :isShowCheckBox="true"
                  @changeCheckBoxFun="clickCheckBox"
                  @loadTableData="loadInvestorList"></table-list>
      <!-- :selectable="false" -->
    </div>
  </div>
</template>

<script>
import tableList from "../../../../common/table-list"; // 下拉选择框
import searchBox from "./search-box";
import {
  SearchPageNewInvestment,
  DeleteInvestor,
} from "../../../../../API/workbench";
export default {
  data () {
    return {
      tableColumns: [
        {
          label: "录入日期",
          prop: "EntryTime",
          width: 150,
          type: "date-item",
          sortable: "custom",
        },
        {
          label: "录入人",
          prop: "EntryKeyValue",
          width: 120,
          type: "text-item",
        },
        {
          label: "来源方式",
          prop: "SourceTypeKeyValue",
          type: "text-item",
          width: 120,
        },
        {
          label: "投资人",
          prop: "MainDemoName",
          type: "text-item",
          width: 120,
        },
        {
          label: "手机号",
          prop: "MobilePhone",
          type: "text-item",
          width: 150,
        },
        {
          label: "招商顾问",
          prop: "CounselorKeyValue",
          type: "text-item",
          width: 120,
          extend: {
            investorID: "OLAPKey",
            className: "font_blue",
            click: (rowData) => {
              this.distributeAdvisor(rowData);
            },
          },
        },
        {
          label: "分配时间",
          prop: "AllotTime",
          type: "date-item",
          width: 120,
        },
        {
          label: "有效性",
          prop: "IsValidKeyValue",
          type: "text-item",
          width: 100,
        },
        {
          label: "操作",
          prop: "option",
          type: "table-btn",
          align: "center",
          extend: {
            tableBtnGroup: [
              {
                name: "分配",
                extend: {
                  click: (rowData) => {
                    this.distributeAdvisor(rowData);
                  },
                  isHide: (data) => {
                    ////有效性-1-无效;0-待确认;1-有效
                    console.log(
                      "data.IsValidKey",
                      data.IsValidKey != -1,
                      data.IsValidKey
                    );
                    return data.CounselorKey > 0;
                  },
                },
              },
              {
                name: "删除",
                extend: {
                  click: (rowData) => {
                    this.deleteInvestor(rowData);
                  },
                  isHide: (data) => {
                    ////有效性-1-无效;0-待确认;1-有效
                    return data.CounselorKey == 0;
                  },
                },
              },
            ],
          },
        },
      ],
      paramsForQuery: {
        pageIndex: 0, //	页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: "", //搜索条件：模糊匹配姓名，姓名首字母
        orderBy: "", //排序字段
        sequence: "", //排序顺序
        entryTimeStart: "", //开始录入时间
        entryTimeEnd: "", //结束录入时间
        CounselorKey: 0, //投资顾问
        IsValidKey: 0, //有效性 0全部;1-有效;2-待确认;3-无效
        SourceTypeKey: 0, //来源
        StatusKey: 0, //分配状态 0-全部;1-已分配;2-未分配
      },
      noMore: false, //是否还可加载更多数据
      investorList: [], //投资人列表
      totalNum: 0,
      defaultSort: {},
      batchAllotList: [], //选择的投资人列表
      isBatchDistribute: false,
    };
  },
  components: {
    tableList,
    searchBox,
  },
  props: {
    curSelectedPrams: Object,
  },
  computed: {},
  created () {
    if (
      this.curSelectedPrams &&
      Object.keys(this.curSelectedPrams).length > 0
    ) {
      this.paramsForQuery.StatusKey =
        this.curSelectedPrams.StatusKey;
    }
    this.loadInvestorList();
  },
  methods: {
    //获取新投资人列表
    loadInvestorList () {
      SearchPageNewInvestment(this.paramsForQuery).then((result) => {
        this.investorList = result.data.PageDataList;
        this.totalNum = result.data.Total;
      });
    },
    //点击搜索
    searchClick (queryParams) {
      this.paramsForQuery = queryParams;
      this.loadInvestorList();
    },
    //清除搜索条件
    clearSearchParams () {
      this.paramsForQuery.pageIndex = 0;
      this.paramsForQuery.searchText = "";
      this.paramsForQuery.entryTimeStart = "";
      this.paramsForQuery.entryTimeEnd = "";
      this.paramsForQuery.CounselorKey = 0;
      this.paramsForQuery.IsValidKey = 0;
      this.paramsForQuery.SourceTypeKey = 0;
      this.paramsForQuery.StatusKey = 0;
      this.loadInvestorList();
    },
    //导入成功后
    importSuccess (queryParams) {
      this.paramsForQuery = queryParams;
      this.loadInvestorList();
      this.$emit("updataTableData");
    },
    //删除投资人
    deleteInvestor (rowData) {
      layer.confirm("是否确认删除当前项?", {
        btn: [
          {
            name: "确认",
            callBack: () => {
              DeleteInvestor(rowData.OLAPKey).then((obj) => {
                let index = this.investorList.findIndex((obj) => {
                  return obj.OLAPKey == rowData.OLAPKey;
                });
                if (index > -1) {
                  this.investorList.splice(index, 1);
                }
                this.loadInvestorList();
                this.$emit("updataTableData");
                layer.alert("删除成功");
              });
              layer.close();
            },
          },
        ],
      });
    },
    //启用批量分配
    enableBatchAllot () {
      this.isBatchDistribute = true;
    },
    //批量分配 选中一行
    clickCheckBox (lists, rowData) {
      console.log("clickCheckBox", lists, rowData);
      if (!this.isBatchDistribute && lists.length > 0) {
        this.isBatchDistribute = true;
      } else if (lists.length == 0) {
        this.isBatchDistribute = false;
      }
      let arr = lists.filter((item) => {
        return item.OLAPKey > 0;
      });
      this.batchAllotList = arr;
    },
    //取消批量分配
    cancleBatchDistribute () {
      this.isBatchDistribute = false;
      this.$refs.tableListRef.$refs.multipleTable.clearSelection();
      this.batchAllotList = [];
    },
    //分配顾问
    distributeAdvisor (rowData, type) {
      let item = {
        routerName: this.$route.name,
        moduleName: "distributeInvestmentAdvisor",
        name: "分配顾问",
        data: { investorIDs: rowData.OLAPKey },
        callBack: {
          afterSuccess: (data) => {
            this.loadInvestorList();
            if (type == "batch") {
              this.cancleBatchDistribute();
            }
          },
        },
      };
      this.$dialog.open(this, item);
    },
    //批量分配后
    doAfterBatchDistribute (data) {
      this.batchAllotList.forEach((obj) => {
        obj.CounselorKey = data.OLAPKey;
        obj.CounselorKeyValue = data.name;
      });
      this.cancleBatchDistribute();
    },
    openCommonCustomDialogByFactoryDialog (item) {
      this.$emit("openCommonCustomDialogByFactoryDialog", item);
    },
  },
};
</script>

<style >
.investor_manage_view {
  justify-content: space-between;
}
.recycle_bin {
  position: absolute;
  right: 20px;
  top: 30px;
  height: 40px;
  width: 80px;
  line-height: 38px;
  text-align: center;
  border-radius: 4px;
  padding-left: 27px;
  padding-right: 7px;
  background: #3498db url(../../../../../../public/image/recycled_icon.png)
    no-repeat 8px;
}
</style>