<template>
  <div>
    <div>
      <search-student-box @selectedStudent="doAfterSelectedStudent"
                          :searchBoxName="'请选择学生'"
                          :selectedStudentInfo="studentInfo"
                          :isShowAddBtn="false"></search-student-box>
      <div class="">
        <div v-if="scheduleCourseList.length>0"
             class="content_marginRL single_shadow btn_marginBottom_30">
          <div class="course_block_gray"
               style="padding-bottom: 10px;">
            <div class="fl">
              <span class="font_red">*</span> 选中课程后确认请假。
            </div>
            <div class="fr">
              共选中
              <span class="font_blue">{{totalSelectedNum}}</span>节课
            </div>
          </div>
          <!-- 课 -->
          <div class="course_number_ul ">
            <div v-for="(monthItem,index) in groupScheduleCourseList"
                 :key="index">
              <div class="course_number_details clearfix"
                   @click.stop="clickUpOrDown(monthItem)">
                <div class="fl">
                  <span>{{monthItem.yearAndMonthName}}</span>
                  <span>共 {{monthItem.dayItem.length}} 节课</span>
                </div>
                <div class="details_btn fr"
                     :class="{icon_top:monthItem.isOpen,icon_bottom:!monthItem.isOpen}"></div>
                <div class="fr"
                     v-if="monthItem.hasSelectedNum>0">
                  已选中
                  <span class="font_blue">{{monthItem.hasSelectedNum}}</span> 节课
                </div>
              </div>
              <div class="course_leave_ul"
                   v-show="monthItem.isOpen">
                <course-item v-for="(dayItem,i) in monthItem.dayItem"
                             :key="i"
                             :courseItem="dayItem"
                             :monthItem="monthItem"
                             @onClickItem="doAfterClickCourse"
                             @changeCourse="doChangeCourse"></course-item>
              </div>
            </div>
          </div>
        </div>
        <div v-if="scheduleCourseList.length==0&&!isLodding&&!vIsNullStudentInfo"
             class="course_block_nodata btn_marginBottom_30 content_marginRL single_shadow">

          <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>

        </div>
      </div>
      <!-- 按钮 -->
      <div class="btn_fixed_bottom "
           v-if="scheduleCourseList.length>0">
        <zx-button :btnClass="'btn_fixed_red'"
                   :btnText="'取消排课'"
                   :isSingleSubmit="true"
                   :singleSubmitType="'dynamic'"
                   @click="cancelArrange"></zx-button>
        <zx-button :btnClass="'btn_fixed_blue btn_hover_bg_blue'"
                   :btnText="'确认请假'"
                   :isSingleSubmit="true"
                   :singleSubmitType="'dynamic'"
                   @click="confirmLeave"></zx-button>
      </div>
    </div>
    <!-- 学生课名下已排课列表 -->
    <custom-dialog title="调课选择"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isShowUnArrangeCourseDialog"
                   :before-close="closeUnArrangeCourseDialog">
      <un-arrange-course-dialog ref="courseDialog"
                                :studentID="studentInfo.OLAPKey"
                                :changeCourseInfo="changeCourseInfo"
                                @closeDialog="closeUnArrangeCourseDialog"
                                @confirmClick="doAfterChangeCourseSuccess"></un-arrange-course-dialog>
    </custom-dialog>
  </div>
</template>
<script>
import searchStudentBox from '../../common/search-student-box';
import zxButton from '../../common/zx-button';

import {
  LeaveForBatch,
  CancelStudent2MutiCourse,
  SearchStudentHasArrangeSupport
} from '../../../API/workbench';

import courseItem from './course-item';
import unArrangeCourseDialog from './un-arrange-course-dialog';
export default {
  data () {
    return {
      changeCourseInfo: 0, // 调课信息
      studentInfo: {}, // 学生信息
      scheduleCourseList: [], // 课列表
      groupScheduleCourseList: [], // 课分组后列表
      selectedScheduleCourseForLeaveList: [], // 已选中的课ID集合
      selectedScheduleCourseForCancleList: [], // 已选中的课ID集合
      totalSelectedNum: 0, // 总共选中几条
      isClassHourLackKey: 0,
      isShowUnArrangeCourseDialog: false, // 未排课列表 弹窗
      isLodding: false
    };
  },
  computed: {
    vIsNullStudentInfo () {
      return JSON.stringify(this.studentInfo) == '{}';
    }
  },
  components: {
    searchStudentBox,
    courseItem,
    unArrangeCourseDialog,
    zxButton
  },
  methods: {
    // 获取学生下的所有进行中和未开始的课
    getScheduleCourseList () {
      this.isLodding = true;
      this.scheduleCourseList = [];
      SearchStudentHasArrangeSupport(
        this.studentInfo.OLAPKey,
        0,
        this.$utils.formatDateToLine(Date.new()),
        ''
      ).then(result => {
        if (result.code == 0 && result.data.length > 0) {
          result.data.forEach(obj => {
            if (
              (obj.StatusKey == 2 || obj.StatusKey == 3) &&
              obj.AttenceStatusKey == 2
            ) {
              this.scheduleCourseList.push(obj);
            }
          });
          this.groupByClassTimeForScheduleCourseList();
        }
        this.isLodding = false;
      });
    },
    // 根据上课时间分组
    groupByClassTimeForScheduleCourseList () {
      this.groupScheduleCourseList = [];
      let monthItem = {};
      this.scheduleCourseList.forEach(obj => {
        let classDate = Date.new(obj.ClassTime);
        let year = classDate.getFullYear();
        let month = classDate.getMonth() + 1;
        let yearAndMonth = year.toString() + (month < 10 ? '0' + month : month);
        let yearAndMonthName =
          year.toString() + '年' + (month < 10 ? '0' + month : month) + '月';
        let findIndex = this.groupScheduleCourseList.findIndex(o => {
          return o.yearAndMonth == yearAndMonth;
        });
        // 若存在年月
        if (findIndex > -1) {
          monthItem.dayItem.push({ courseInfo: obj, isSelected: false });
          // 不存在年
        } else {
          let isOpen = false;
          if (
            year == Date.new().getFullYear() &&
            month == Date.new().getMonth() + 1
          ) {
            isOpen = true;
          }
          monthItem = {
            yearAndMonth: yearAndMonth,
            yearAndMonthName: yearAndMonthName,
            hasSelectedNum: 0,
            isOpen: isOpen,
            dayItem: [{ courseInfo: obj, isSelected: false }]
          };
          this.groupScheduleCourseList.push(monthItem);
        }
      });
    },
    // 展开或收起
    clickUpOrDown (monthItem) {
      monthItem.isOpen = !monthItem.isOpen;
    },
    // 点击课
    doAfterClickCourse (month, courseItem) {
      if (courseItem.isSelected) {
        this.totalSelectedNum += 1;
        month.hasSelectedNum += 1;
        this.selectedScheduleCourseForLeaveList.push(courseItem.courseInfo.CourseId);
        this.selectedScheduleCourseForCancleList.push({
          OLAPKey: courseItem.courseInfo.CourseId,
          CourseNameKey: courseItem.courseInfo.CourseNameKey
        });
      } else {
        let findIndex = this.selectedScheduleCourseForLeaveList.findIndex(
          value => {
            return value == courseItem.courseInfo.CourseId;
          }
        );
        if (findIndex > -1) {
          this.selectedScheduleCourseForLeaveList.splice(findIndex, 1);
          this.selectedScheduleCourseForCancleList.splice(findIndex, 1);
        }
        this.totalSelectedNum -= 1;
        month.hasSelectedNum -= 1;
      }
    },
    // 取消排课
    cancelArrange (callBack) {
      if (this.selectedScheduleCourseForCancleList.length == 0) {
        layer.alert('请先至少选择一节课');
        if (typeof callBack == 'function') {
          callBack();
        }
        return false;
      }
      let parameter = {
        ScheduleCourseList: this.selectedScheduleCourseForCancleList,
        StudentKey: this.studentInfo.OLAPKey
      };
      CancelStudent2MutiCourse(parameter)
        .then(result => {
          if (result.code == 0) {
            layer.alert('取消成功');
            this.initSelectedData();
          }
        })
        .catch(error => {
          layer.alert(error.msg);
        })
        .finally(() => {
          if (typeof callBack == 'function') {
            callBack();
          }
        });
    },
    // 确认请假
    confirmLeave (callBack) {
      if (this.selectedScheduleCourseForLeaveList.length == 0) {
        layer.alert('请先至少选择一节课');
        if (typeof callBack == 'function') {
          callBack();
        }
        return false;
      }
      LeaveForBatch(
        this.studentInfo.OLAPKey,
        this.isClassHourLackKey,
        this.selectedScheduleCourseForLeaveList
      )
        .then(result => {
          if (result.code == 0) {
            this.isClassHourLackKey = 0;
            layer.alert('请假成功');
            this.initSelectedData();
          }
        })
        .catch(error => {
          if (error.code == 60001) {
            let _this = this;
            layer.confirm('是否允许欠课时请假?', {
              btn: [
                {
                  name: '确认',
                  callBack: function () {
                    _this.isClassHourLackKey = 1;
                    _this.confirmLeave();
                    layer.close();
                  }
                }
              ]
            });
          } else {
            layer.alert(error.msg);
          }
        })
        .finally(() => {
          if (typeof callBack == 'function') {
            callBack();
          }
        });
    },
    doChangeCourse (item) {
      this.isShowUnArrangeCourseDialog = true;
      this.changeCourseInfo = item.courseInfo;
    },
    // 调课成功后
    doAfterChangeCourseSuccess (beforeCourseInfo, newCourseInfo) {
      let findSelectedCourseIndex = this.selectedScheduleCourseForCancleList.findIndex(obj => { return obj.OLAPKey == beforeCourseInfo.CourseId; });
      if (findSelectedCourseIndex > -1) {
        this.selectedScheduleCourseForCancleList.splice(findSelectedCourseIndex, 1);
      }
      let classDate = Date.new(newCourseInfo.ClassTime);
      let year = classDate.getFullYear();
      let month = classDate.getMonth() + 1;
      let yearAndMonth = year.toString() + (month < 10 ? '0' + month : month);
      newCourseInfo.BeginClassTime = newCourseInfo.SClassTimeName;
      newCourseInfo.CourseId = newCourseInfo.OLAPKey;
      let hasAdd = false;
      this.groupScheduleCourseList.forEach(obj => {
        let findIndex = obj.dayItem.findIndex(item => {
          return item.courseInfo.CourseId == beforeCourseInfo.CourseId;
        });
        if (findIndex > -1) {
          // 调的那节课 若是选中的 选中数要减1
          if (obj.dayItem[findIndex].isSelected) {
            obj.hasSelectedNum -= 1;
            this.totalSelectedNum -= 1;
          }
          // 调的课为同月份的 则直接替换
          if (yearAndMonth == obj.yearAndMonth) {
            hasAdd = true;
            obj.dayItem.splice(findIndex, 1, {
              courseInfo: newCourseInfo,
              isSelected: false
            });
            obj.dayItem.sort((a, b) => {
              return (
                Date.new(
                  a.courseInfo.ClassTime + ' ' + a.courseInfo.BeginClassTime
                ).getTime() -
                Date.new(
                  b.courseInfo.ClassTime + ' ' + b.courseInfo.BeginClassTime
                ).getTime()
              );
            });
          } else {
            obj.dayItem.splice(findIndex, 1);
          }
          // 调的课为不一样月份的
        } else if (yearAndMonth == obj.yearAndMonth) {
          obj.dayItem.push({ courseInfo: newCourseInfo, isSelected: false });
          obj.dayItem.sort((a, b) => {
            return (
              Date.new(
                a.courseInfo.ClassTime + ' ' + a.courseInfo.BeginClassTime
              ).getTime() -
              Date.new(
                b.courseInfo.ClassTime + ' ' + b.courseInfo.BeginClassTime
              ).getTime()
            );
          });
          hasAdd = true;
        }
        // 调的课年月 不在分组里的
        if (!hasAdd) {
          let yearAndMonthName =
            year.toString() + '年' + (month < 10 ? '0' + month : month) + '月';
          let monthItem = {
            yearAndMonth: yearAndMonth,
            yearAndMonthName: yearAndMonthName,
            hasSelectedNum: 0,
            isOpen: true,
            dayItem: [{ courseInfo: newCourseInfo, isSelected: false }]
          };
          this.groupScheduleCourseList.push(monthItem);
        }
      });
      this.closeUnArrangeCourseDialog();
    },
    // 关闭调课界面
    closeUnArrangeCourseDialog () {
      this.isShowUnArrangeCourseDialog = false;
    },
    // 请假或取消排课成功后
    initSelectedData () {
      this.groupScheduleCourseList.forEach(obj => {
        let newArr = obj.dayItem.filter(item => item.isSelected == true);
        if (newArr.length > 0) {
          obj.hasSelectedNum = 0;
        }
        newArr.forEach(item => {
          let findIndex = obj.dayItem.findIndex(o => {
            return o.courseInfo.CourseId == item.courseInfo.CourseId;
          });
          if (findIndex > -1) {
            obj.dayItem.splice(findIndex, 1);
          }
        });
      });
      this.totalSelectedNum = 0;
      this.selectedScheduleCourseForLeaveList = [];
      this.selectedScheduleCourseForCancleList = [];
    },
    // 选中学生后
    doAfterSelectedStudent (item) {
      this.studentInfo = item;
      this.totalSelectedNum = 0;
      this.selectedScheduleCourseForLeaveList = [];
      this.selectedScheduleCourseForCancleList = [];
      this.getScheduleCourseList();
    }
  },
  beforeRouteLeave (to, from, next) {
    if (from.meta.keepAlive != undefined) {
      if (
        JSON.stringify(to.params) != '{}' ||
        JSON.stringify(to.query) != '{}'
      ) {
        from.meta.keepAlive = true;
      } else {
        from.meta.keepAlive = false;
      }
    }
    next();
  }
};
</script>
<style>
</style>