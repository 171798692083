<template>
  <div class="apply_payment_echarts_hous massive_bg echarts_hous_mb">
    <div class="statistics_echarts_left fl">
      <div class="apply_payment_echarts_left_title clearfix">
        <div class="title_font fl">考勤统计月报</div>
        <single-year-picker :minDate="vMinDate"
                            ref="monthPicker"
                            :maxDate="vMaxDate"
                            :isShowHistory="isShowHistory"
                            @chgDate="chgYear"></single-year-picker>
        <div class="change_history_data"
             @click.stop="historyBtn(historyName)">{{ historyName }}</div>
        <div class="title_month fr">
          <span class="this_month">本年</span>
          <span class="past_month">上一年</span>
        </div>
      </div>
      <div>
        <doubleTypeEchart :echartID="'dual_histogram2'"
                          :reqList="monthList"
                          :nowDataList="nowList"
                          :preDataList="oldList"
                          :echartType="'category'"
                          ref="echart"></doubleTypeEchart>
      </div>
      <div>
        <a @click.stop="showDetail"
           class="class_statistics_details_btn">
          查看详情
        </a>
      </div>
    </div>
    <nss :nowDataList="nowDataList"
         :oldDataList="oldDataList"
         :type="'year'"
         :seletedDate="seletedYear"
         :formTitle="'本年合计'"></nss>
  </div>
</template>
<script>
import doubleTypeEchart from '../echart-common/double-type-echart';
import singleYearPicker from '../date-picker-common/single-year-picker';
import nss from './now-statistics-summarize';
import { getKQStatisticsByMonth, getKQStatisticsByMonthH } from '../../../API/workbench';
export default {
  name: 'monthStatisticsReport',
  data () {
    return {
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      seletedYear: Date.new(),
      startTime: 0,
      endTime: 0,
      monthList: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      nowList: [],    // 选中月数据显示的内容
      oldList: [],    // 上个月数据显示的内容
      nowDataList: [],     // 选中当月数据源
      oldDataList: []      // 选中的上个月数据源
    };
  },
  props: {
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    }
  },
  components: {
    nss, singleYearPicker, doubleTypeEchart
  },
  mounted () {
    this.initGetMonthData(this.getLastKDSYDataByMonth);
  },
  methods: {
    historyBtn (name) {
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isShowHistory = true;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.endTime = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0));
        this.isShowHistory = false;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      console.log(this.startTime, this.endTime, this.isShowHistory, ' this.isShowHistory ');
      this.getKDSYDataByMonth(this.startTime, this.endTime, this.getLastKDSYDataByMonth);
      this.$refs.monthPicker.changeHistoryTime(this.endTime);
    },
    showDetail () {
      this.$emit('showDetail', '考勤统计月报', this.startTime, this.endTime);
    },

    chgYear (val, startTime, endTime) {
      this.seletedYear = val;       // 更改值选中年的值.
      this.startTime = val.getFullYear() + '/01/01';
      this.endTime = val.getFullYear() + '/12/31';
      this.getKDSYDataByMonth(this.startTime, this.endTime, this.getLastKDSYDataByMonth);
    },
    initGetMonthData (callback) {
      this.startTime = this.seletedYear.getFullYear() + '/01/01';
      this.endTime = this.seletedYear.getFullYear() + '/12/31';
      this.getKDSYDataByMonth(this.startTime, this.endTime, callback);
    },
    // 按月获取报名缴费报表数据
    getKDSYDataByMonth (startTime, endTime, callback) {
      let fn = '';
      if (this.isShowHistory) {
        fn = getKQStatisticsByMonthH;
      } else {
        fn = getKQStatisticsByMonth;
      }
      this.nowDataList.length = 0;
      fn(startTime, endTime).then(result => {
        this.nowDataList = result.data.DataList || [];
        this.nowList = this.getYearNowList();
        if (callback) {
          callback();
        }
      }, r => {
        console.log(r);
      });
    },
    // 获取上一个年的信息.
    getLastKDSYDataByMonth () {
      let ISHistory = 0; // 是否查询历史数据,1是0否
      let year = Date.new(this.seletedYear).getFullYear();    // 纯年
      let MinDate = Date.new(this.vMinDate).getFullYear();    // 纯年
      console.log(year - 1, 'year', MinDate - 1);
      let startTime = (year - 1) + '/01/01';
      let endTime = (year - 1) + '/12/31';
      this.oldDataList.length = 0;
      if ((year - 1) == (MinDate - 1)) {
        ISHistory = 1;
      } else {
        ISHistory = 0;
      }
      getKQStatisticsByMonth(startTime, endTime, ISHistory).then(result => {
        this.oldDataList = result.data.DataList || [];
        this.oldList = this.getYearOldList();
        this.$nextTick(() => {
          if (this.$refs.echart) {
            this.$refs.echart.echartInit();
          }
        });
      }, r => {
        console.log(r);
      });
    },
    getYearNowList () {
      let nowList = [];
      this.monthList.forEach(p => {
        let item = this.nowDataList.find(o => {
          return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
        });
        if (item) {
          nowList.push(Number(item.Attence3).toFixed(0));
        } else {
          nowList.push('0');
        }
      });
      return nowList;
    },
    // 获取上一年数据   因为数据呈现为12个月. 所以如果返回数据不符合该月份的话直接push '0'
    getYearOldList () {
      let oldList = [];
      this.monthList.forEach(p => {
        let item = this.oldDataList.find(o => {
          return Date.new(o.SetUpTime).getMonth() + 1 == parseInt(p);
        });
        if (item) {
          oldList.push(Number(item.Attence3).toFixed(0));
        } else {
          oldList.push('0');
        }
      });
      return oldList;
    }
  }
};
</script>
<style scoped>
.change_history_data {
  position: absolute;
  right: 180px;
  top: 20px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
</style>