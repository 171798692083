<template>
  <div>
    <search-student-box v-if="!this.studentInfo.OLAPKey"
                        :selectedStudentInfo="studentInfo"
                        :switchItem="1"
                        :showExamine="false"
                        :showMenu="false"
                        :showSwitchBut="true"
                        :isShowItem="0"
                        @selectedStudent="alterStudent"
                        class="form_info_top "></search-student-box>
    <div v-if="this.studentInfo.OLAPKey>0">
      <student-label :studentID="studentInfo.OLAPKey"
                     :dataInfo="studentInfo"
                     :isShowBox="false"
                     :isShowEdit="showEdit"
                     @alterStudent="alterStudent"
                     :showMenu="true"></student-label>
      <div class="form_info_edit">
        <input-dialog :formTitle="'预约课名'"
                      :formPlaceholder="''"
                      style="margin:0 20px"
                      :readonly="false"
                      :required="false"
                      @showEldilog="showCourseNameListDialog"
                      v-model="courseTableData.ClassSetKeyValue"></input-dialog>
        <calendar-choose ref="calendar"
                         :studentInfo="studentInfo"
                         :courseTableData="courseTableData"
                         @summit="summit"
                         @close="closeAfter"
                         @selectTimeRange="selectTimeRange"></calendar-choose>
      </div>

    </div>
  </div>
</template>
<script>
import studentLabel from './arrange-student-label';
import calendarChoose from './calendar-choose';
import searchStudentBox from '../../../common/search-student-box';
import { getCourseTypeNameListX, ArrangeTrialClass, ApplyTrialClassToArrange, ResetArrangeTrialClass, ArrangeTrialLesson } from '../../../../API/workbench';
export default {
  data () {
    return {
      studentInfo: {},
      courseTableData: {
        DateList: [],    // 选中日期列表
        ClassSetKey: 0,
        ClassSetKeyValue: ''
      },
      courseNameList: []

    };
  },
  props: {
    dataInfo: Object
  },
  components: {
    searchStudentBox, studentLabel, calendarChoose
  },
  computed: {
    showEdit () {
      return !!(this.dataInfo && this.dataInfo.StudentKey > 0);
    }
  },
  mounted () {
    console.log(this.dataInfo, 'dataInfo00');
    this.$dialog.register(this, ['singleSelectionList']);
    if (this.dataInfo.StudentKey > 0) {
      this.studentInfo = { OLAPKey: this.dataInfo.StudentKey };
      this.courseTableData.ClassSetKey = this.dataInfo.OrderClassKey || 0;
      this.courseTableData.ClassSetKeyValue = this.dataInfo.OrderClassKeyValue || '';
      console.log(this.courseTableData, 'mounted');
      this.$nextTick(() => {
        this.$refs.calendar.getTimeRangeCourseData(this.courseTableData);
      });
    }
    this.getCourseNameList();
  },
  methods: {
    // 选择学生，查看档案
    alterStudent (item) {
      console.log(item, 'alterStudent');
      this.studentInfo = item;
    },
    selectTimeRange (item) {
      this.selectTimeInfo = item;
    },
    seletedCourseAfter (data) {
      // this.courseTableData = data;
      if (this.courseTableData.ClassSetKey != data.OLAPKey) {
        this.courseTableData.DateList = [];
      }
      this.courseTableData.ClassSetKey = data.OLAPKey;
      this.courseTableData.ClassSetKeyValue = data.MainDemoName;
      console.log(data, 'seletedCourseAfter', this.courseTableData);
      this.$refs.calendar.getTimeRangeCourseData(this.courseTableData);
    },
    getCourseNameList () {
      getCourseTypeNameListX('OLAPKey', 'desc').then(result => {
        this.courseNameList = result.data.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; });
      });
    },
    // 打开课程
    showCourseNameListDialog () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '选择课程',
        moduleName: 'singleSelectionList',
        data: { selectedKey: this.courseTableData.ClassSetKey, dataSourceList: this.courseNameList, required: false, addModuleName: 'course-name-setting' },
        callBack: {
          onSelectedItem: (data) => { this.seletedCourseAfter(data); }
        }
      });
    },
    // 取消
    closeAfter () {
      this.$emit('closeDialog');
    },
    // 提交
    summit (courseItem, callBack) {
      console.log(courseItem, 'courseItem', this.dataInfo);
      let obj = {};
      if (this.dataInfo && this.dataInfo.StudentThreadKey) {
        obj = {
          StudentThreadKey: this.dataInfo.StudentThreadKey,
          ScheduleCourseKey: courseItem.OLAPKey,
          StudentKey: this.dataInfo.StudentKey,
          TrialLessonKey: this.dataInfo.TrialLessonKey
        };
        // 从招生管理 和 招生工作台 进来
        console.log(this.showEdit, 'this.showEdit1');
        ArrangeTrialLesson(obj).then(result => {
          layer.alert('安排成功');
          this.$emit('afterSuccess');
          this.$emit('closeDialog');
          if (callBack) {
            callBack();
          }
        }).catch(err => {
          layer.alert(err.msg);
        });
      } else {
        // 安排试课
        console.log(this.showEdit, 'this.showEdit2');
        if (this.showEdit) {
          obj = {
            TableID: this.dataInfo.TableID,
            StudentKey: this.dataInfo.StudentKey,
            SchelduleCourseKey: courseItem.OLAPKey,
            IsClassHourLackKey: 1
          };
          ResetArrangeTrialClass(obj).then(result => {
            layer.alert('安排成功');
            this.$emit('afterSuccess');
            this.$emit('closeDialog');
            if (callBack) {
              callBack();
            }
          }).catch(err => {
            layer.alert(err.msg);
          });
        } else {
          ApplyTrialClassToArrange(this.studentInfo.OLAPKey, courseItem.OLAPKey).then(result => {
            layer.alert('安排成功');
            if (callBack) {
              callBack();
            }
            this.$emit('afterSuccess');
            this.$emit('closeDialog');
          }).catch(error => {
            layer.alert(error.msg);
          });
        }
      }
    }
  }
};
</script>

