<template>
  <div class="courseware_bag_info authcoursewarebage">
    <div class="cj_details_img pr">
      <img :src="headImg"
           alt="" />
    </div>
    <div class="head_div_right">
      <span class="left_box_list_span">{{bigCoursewareBox.name}}</span>
      <div class="left_box_list font_gray9">
        <div class="list_content"
             v-for="(leftItem,index) in bigCoursewareBox.leftBoxList"
             :key="index"
             :style="leftItem.width?'width:'+leftItem.width+'px':''">{{leftItem.tltle}}
          <span class="font_black">{{leftItem.value}}</span>
        </div>
      </div>
      <div class="left_box_list_div "><span class="left_box_list_two_span">设备控制：</span>
        <div class="device_control_list">
          <iconAuthorization v-for="(item,index) in vCourseAuthList"
                             :key="index"
                             :courseAuth="item"
                             :isShowBeforLine="true">
          </iconAuthorization>
        </div>
      </div>
      <!-- <div class="right_box_list font_gray9">
          <div class="list_content"
               v-for="(rightItem,index) in bigCoursewareBox.rightBoxList"
               :key="index"
               :style=" rightItem.width?'width:'+rightItem.width+'px':''">{{rightItem.tltle}}
            <span class="font_black">{{rightItem.value}}</span>
          </div>
        </div> -->
    </div>
  </div>
</template>
<script>
import iconAuthorization from '../../../courseware-work-bench/right-box-work-bench/courseware-details/icon-authorization.vue';
export default {
  data () {
    return {
    };
  },
  props: {
    bigCoursewareBox: Object,
    authDetailsInfo: {
      type: Object,
      default: {}
    }
  },
  created () {

  },
  components: {
    iconAuthorization
  },
  computed: {
    headImg () {
      if (this.bigCoursewareBox && this.bigCoursewareBox.CoverUrl) {
        if (this.bigCoursewareBox.CoverUrl.indexOf('http') > -1) {
          return this.bigCoursewareBox.CoverUrl;
        } else {
          return this.$store.getters.CDNURL + this.bigCoursewareBox.CoverUrl;
        }
      } else {
        return '';
      }
    },
    vCourseAuthList () {
      let data = this.authDetailsInfo;
      let arr = [];
      if (!this.authDetailsInfo) {
        return arr;
      }
      let itemPC = {
        IsAuthKey: data.IsPCAuthKey,
        IsRecordKey: data.IsPCRecordKey,
        AuthorizeDeviceNum: data.AuthorizeDeviceNum,
        AuthCount: data.AuthDeviceCount,
        LocationLockKey: data.PCIsLocationLockKey,
        type: 'PC'
      };
      let itemTV = {
        IsAuthKey: data.IsTVAuthKey,
        IsRecordKey: data.IsTVRecordKey,
        AuthorizeDeviceNum: data.TVAuthorizeDeviceNum,
        AuthCount: data.TVAuthDeviceCount,
        LocationLockKey: data.TVIsLocationLockKey,
        type: 'TV'
      };
      let itemAPP = {
        IsAuthKey: data.IsAPPAuthKey,
        IsRecordKey: data.IsAPPRecordKey,
        AuthorizeDeviceNum: data.APPAuthorizeDeviceNum,
        AuthCount: data.APPAuthDeviceCount,
        LocationLockKey: data.IsLocationLockKey,
        type: 'APP'
      };
      arr.push(itemPC);

      arr.push(itemAPP);
      arr.push(itemTV);
      return arr;
    }
  },
  methods: {

  }
};
</script>
<style scoped>
.courseware_bag_info {
  display: flex;
  padding: 20px;
  height: auto;
}
.cj_details_img img {
  height: 112px;
  width: 200px;
  border-radius: 8px;
}
.cw_authorization {
  width: 300px;
  height: 29px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  line-height: 29px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0px 0px 8px 8px;
}
.head_div_right {
  width: 466px;
  margin-left: 22px;
}

.left_box_list {
  display: flex;
}
.right_box_list {
  width: 250px;
  height: 84px;
  float: right;
}
.left_box_list_span {
  display: block;
  height: 22px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  color: #000000;
}
.left_box_list {
  width: 100%;
  display: block;
}
.right_box_list {
  width: 70%;
  display: block;
}
.list_content {
  margin-top: 19px;
  display: block;
  float: left;
  width: 33%;
}
.left_box_list_div {
  display: flex;
  width: 480px;
  flex-direction: column;
  padding-top: 10px;
}
.left_box_list_div_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 480px;
}
.device_control_list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 340px;
  line-height: 25px;
  background: #f9f9fb;
  border-radius: 4px;
  padding: 10px 0;
}
.device_control_list >>> .location_icon {
  /* margin-top:8px; */
}
.left_box_list_two_span {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  color: #999999;
}
.authcoursewarebage >>> .device_control_list {
  width: 400px;
}
</style>