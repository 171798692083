<template>
  <div class="chain_workbench_right">
    <div class="chain_workbench_top">
      <div class="chain_workbench_data">
        <div class="data_title">
          <span>实时数据</span>
          <span class="data_title_date el-icon-caret-bottom pr">
            {{seletedDate}}
            <single-year-picker style="opacity: 0;position: absolute;top: -6px;"
                                @chgDate="changeSaveDate"
                                ref="monthPicker"
                                :type="'month'"></single-year-picker>
          </span>
        </div>
        <div class="data_ul">
          <div class="data_list">
            <div class="data_number">{{vLeagueBrandAmount}}</div>
            <div class="data_name">整店加盟费</div>
          </div>
          <div class="data_list">
            <div class="data_number">{{vLeagueCoursewareAmount}}</div>
            <div class="data_name">课件加盟费</div>
          </div>
          <div class="data_list">
            <div class="data_number">{{vRetailStoresApplyAmount}}</div>
            <div class="data_name">直营报名金额</div>
          </div>
          <div class="data_list">
            <div class="data_number">{{vRetailStoresClassAmount}}</div>
            <div class="data_name">直营课消金额</div>
          </div>
          <div class="data_list">
            <div class="data_number">{{setAttendRate}}</div>
            <div class="data_name">直营校区出勤率</div>
          </div>
        </div>
      </div>
      <div class="chain_workbench_menu">
        <div class="menu_list type_01"
             :class="vRetailStoresCount>=0?'':'no_bg'"
             @click.stop="verifyAuthGoRouter($initJson.chainWorkBenchBtn.retailManagement,vRetailStoresCount,vFindDirectly, vFindParticipation, 105)">
          <div class="menu_list_name">
            直营店管理
          </div>
          <div class="menu_list_info">
            直营校区
            <span class="font_black">{{vRetailStoresCount}}</span>家
          </div>
        </div>
        <div class="menu_list type_02"
             :class="vLeagueBrandCount>=0?'':'no_bg'"
             @click.stop="verifyAuthGoRouter($initJson.chainWorkBenchBtn.franchiseeManagement,vLeagueBrandCount, vFindBrand, vFindCourseware, 106)">
          <div class="menu_list_name">
            加盟店管理
          </div>
          <div class="menu_list_info">
            整店加盟
            <span class="font_black">{{vLeagueBrandCount}}</span>家
          </div>
        </div>
        <div class="menu_list type_03"
             :class="vCoursewarePackageCount>=0?'':'no_bg'"
             @click.stop="verifyAuthGoRouter($initJson.chainWorkBenchBtn.hqCoursewareManagement,vCoursewarePackageCount,'', '', 107)">
          <div class="menu_list_name">
            课件管理
          </div>
          <div class="menu_list_info">
            课件包
            <span class="font_black">{{vCoursewarePackageCount}}</span>套
          </div>
        </div>

        <div class="menu_list type_04"
             :class="vAuthorizeCoursewareCount>=0?'':'no_bg'"
             @click.stop="verifyAuthGoRouter($initJson.chainWorkBenchBtn.authorizationCourseware,vAuthorizeCoursewareCount,'', '', 108)">
          <div class="menu_list_name">
            课件授权
          </div>
          <div class="menu_list_info">
            授权在用
            <span class="font_black">{{vAuthorizeCoursewareCount}}</span>套
          </div>
        </div>
        <div class="menu_list type_04"
             @click.stop="verifyAuthGoRouter($initJson.chainWorkBenchBtn.lessonPreparation,chainInfo.PrepareLessonsToCheckNum)">
          <div class="menu_list_name">
            备课检查
          </div>
          <div class="menu_list_info">
            待检查
            <span class="font_black">{{Number(chainInfo.PrepareLessonsToCheckNum)}}</span>条
          </div>
        </div>
        <div class="menu_list type_04"
             @click.stop="verifyAuthGoRouter($initJson.chainWorkBenchBtn.schoolEduReport,chainInfo.PrincipalSchoolNum)">
          <div class="menu_list_name">
            校区督学统计
          </div>
          <div class="menu_list_info">
            统计校区
            <span class="font_black">{{Number(chainInfo.PrincipalSchoolNum)}}</span>家
          </div>
        </div>

        <div class="menu_list type_04"
             @click.stop="verifyAuthGoRouter($initJson.chainWorkBenchBtn.merchantsCounselorWorkbench,chainInfo.PrincipalSchoolNum)"> 
          <div class="menu_list_name">
            招商顾问工作台
          </div>
          <div class="menu_list_info">
            招商顾问
            <span class="font_black">{{Number(chainInfo.PrincipalSchoolNum)}}</span>个
          </div>
        </div>

        <div class="menu_list type_04"
             @click.stop="verifyAuthGoRouter($initJson.chainWorkBenchBtn.recommendManage,chainInfo.PrincipalSchoolNum)"> 
          <div class="menu_list_name">
            推荐管理
          </div>
          <!-- <div class="menu_list_info">
            招商顾问
            <span class="font_black">{{Number(chainInfo.PrincipalSchoolNum)}}</span>个
          </div> -->
        </div>
      </div>
      <menu-list @openCustomDialogByFactoryDialog="openCustomDialogByFactoryDialog"></menu-list>
    </div>
    <div class="chain_workbench_bottom">
      <div class="bottom_title">
        门店增长趋势
      </div>
      <div class="echart_box">
        <doubleColumnarEchart v-if="dataList.length > 0"
                              :echartID="'mendianCharts'"
                              :reqList="reqList"
                              :dataList="RetailStoresList"
                              :dataList2="FranchiseStoresList"
                              :totalDataList="totalList"
                              :rotate="'-45'"
                              :echartClass="'echarts_l'"
                              :maxType="''"
                              :rateType="''"
                              :barOneTitle="'直营店'"
                              :barTwoTitle="'加盟店'"
                              ref="echart"></doubleColumnarEchart>
        <div v-if="dataList.length == 0"
             class="course_block_nodata">
          <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  GetRealTimeStatisticByDate,
  GetChainaaSClientScaleStatistic,
  GetGrowthTrendForLately12Month
} from '../../API/workbench';
import doubleColumnarEchart from '../base-module/echart-common/double-columnar-echart';
import singleYearPicker from '../base-module/date-picker-common/single-year-picker';
import menuList from './menu-list/index';
export default {
  data () {
    return {
      seletedDate: '',
      realTimesInfo: {
        LeagueCoursewareAmount: 0,
        LeagueBrandAmount: 0,
        RetailStoresApplyAmount: 0,
        RetailStoresClassAmount: 0,
        RetailStoresAttendRate: 0
      },  // 连锁门店实时统计
      chainInfo: {
        RetailStoresCount: 0,
        LeagueBrandCount: 0,
        CoursewarePackageCount: 0,
        AuthorizeCoursewareCount: 0,
        PrincipalSchoolNum: 0,
        PrepareLessonsToCheckNum: 0,
      },  // 连锁门店规模统计
      reqList: [],
      ehartDataList: [],
      dataList: [],    // 未污染额数据
      FranchiseStoresList: [],  // 加盟店数
      RetailStoresList: [],  // 直营店数
      totalList: []   // 加盟店数+直营店数 的总数

    };
  },
  props: {
    menDianSetInfo: Array
  },
  created () {
    this.seletedDate = this.$utils.formatDatet(Date.new(), 'yyyy-MM');
    // this.loadData();
  },
  computed: {
    // 找到直属加盟设置
    vFindDirectly () {
      return this.findSetInfoOpenType(6);
    },
    // 找到参股加盟设置
    vFindParticipation () {
      return this.findSetInfoOpenType(7);
    },
    // 找到课件加盟设置
    vFindCourseware () {
      return this.findSetInfoOpenType(4);
    },
    // 找到整店加盟设置
    vFindBrand () {
      return this.findSetInfoOpenType(5);
    },
    // 整店加盟费
    vLeagueBrandAmount () {
      return this.setMoney(this.realTimesInfo.LeagueBrandAmount, this.vFindBrand, '', 106);
    },
    // 课件加盟费
    vLeagueCoursewareAmount () {
      return this.setMoney(this.realTimesInfo.LeagueCoursewareAmount, this.vFindCourseware, '', 106);
    },
    // 直营报名
    vRetailStoresApplyAmount () {
      return this.setMoney(this.realTimesInfo.RetailStoresApplyAmount, this.vFindDirectly, this.vFindParticipation, 105);
    },
    // 直营课消
    vRetailStoresClassAmount () {
      return this.setMoney(this.realTimesInfo.RetailStoresClassAmount, this.vFindDirectly, this.vFindParticipation, 105);
    },
    // 直营出勤
    setAttendRate () {
      if (this.isHasModulePower(105)) {
        if ((this.vFindDirectly && this.vFindDirectly.SetKey == 1) || (this.vFindParticipation && this.vFindParticipation.SetKey == 1)) {
          return Math.round(Number(this.realTimesInfo.RetailStoresAttendRate) * 100) + '%';
        } else {
          return '**';
        }
      } else {
        return '**';
      }
    },
    // 直营店管理套数
    vRetailStoresCount () {
      return this.setCount(this.chainInfo.RetailStoresCount, this.vFindDirectly, this.vFindParticipation, 105);
    },
    // 加盟店管理套数
    vLeagueBrandCount () {
      return this.setCount(this.chainInfo.LeagueBrandCount, this.vFindBrand, this.vFindCourseware, 106);
    },
    // 课件加盟套数
    vCoursewarePackageCount () {
      return this.setCount(this.chainInfo.CoursewarePackageCount, '', '', 107);
    },
    // 课件授权套数
    vAuthorizeCoursewareCount () {
      return this.setCount(this.chainInfo.AuthorizeCoursewareCount, '', '', 108);
    },
    vRolePowerList () {
      if (this.$store.getters.token) {
        return this.$store.getters.RolePowerList;
      } else {
        return [];
      }
    }
  },
  components: {
    menuList, singleYearPicker, doubleColumnarEchart
  },
  methods: {
    isHasModulePower (ModulePowerKey) {
      return this.$utils.CheckModulePower(
        this.vRolePowerList,
        ModulePowerKey
      );
    },
    // 找到门店设置中的指定项内容
    findSetInfoOpenType (type) {
      console.log(this.menDianSetInfo, 'findSetInfoOpenType');
      let locaItem = this.menDianSetInfo.find(o => {
        return o.SetTypeKey == type;
      });
      return locaItem;
    },
    changeSaveDate (val, startTime, endTime) {
      this.seletedDate = startTime.replace(/\//g, '-').substring(0, 7);
      console.log('changeSaveDate', val, startTime, endTime);
      this.getRealTimeStatistic();
    },
    // 设置金额
    setMoney (num, dataSet, dataSet2, ModulePowerKey) {
      num = Number(num) || 0;
      console.log('dataSet', dataSet);
      if (dataSet || dataSet2) {
        if (this.isHasModulePower(ModulePowerKey) && (dataSet.SetKey == 1 || dataSet2.SetKey == 1)) {
          return this.$utils.setSixMoneyToWan(num);
        } else {
          if (num > 99999.99) {
            return '**万';
          } else {
            return '**';
          }
        }
      } else {
        if (this.isHasModulePower(ModulePowerKey)) {
          return this.$utils.setSixMoneyToWan(num);
        } else {
          if (num > 99999.99) {
            return '**万';
          } else {
            return '**';
          }
        }
      }
    },
    // 设置次数
    setCount (num, dataSet, dataSet2, ModulePowerKey) {
      num = Number(num) || 0;
      console.log('dataSet', dataSet, dataSet2);
      if (dataSet || dataSet2) {
        if (this.isHasModulePower(ModulePowerKey) && (dataSet.SetKey == 1 || dataSet2.SetKey == 1)) {
          return num;
        } else {
          return '**';
        }
      } else {
        if (this.isHasModulePower(ModulePowerKey)) {
          return num;
        } else {
          return '**';
        }
      }
    },
    loadData () {
      this.getRealTimeStatistic();
      this.getChainaaStatistic();
      this.getEchartData();
    },
    // 获取指定年月连锁门店实时统计业绩
    getRealTimeStatistic () {
      GetRealTimeStatisticByDate(this.seletedDate).then(result => {
        this.realTimesInfo = result.data;
        console.log('GetRealTimeStatisticByDate', result.data);
      }).catch(error => {
        console.log('errorGetRealTimeStatisticByDate', error);
      });
    },
    // 获取连锁门店规模统计
    getChainaaStatistic () {
      GetChainaaSClientScaleStatistic().then(result => {
        this.chainInfo = result.data;
        console.log('getChainaaStatistic', result.data);
      }).catch(error => {
        console.log('errorgetChainaaStatistic', error);
      });
    },
    getEchartData () {
      GetGrowthTrendForLately12Month().then(result => {
        console.log('getEchartData', result.data);
        this.ehartDataList = result.data;
        this.dataList = this.$utils.parseJson(result.data);
        this.initEchartDataList();
        this.$nextTick(() => {
          if (this.dataList.length > 0) {
            this.$refs.echart.echartInit();
          }
        });
      });
    },
    initEchartDataList () {
      this.reqList = [];
      this.RetailStoresList = [];
      this.FranchiseStoresList = [];
      this.totalList = [];
      var length = 12 - this.ehartDataList.length;
      if (length > 0) {
        for (var i = 0; i < length - 1; i++) {
          let obj = {
            GenerateTime: '',
            RetailStoresNum: '',
            FranchiseFeesNum: ''
          };
          this.ehartDataList.push(obj);
        }
      }
      this.ehartDataList.forEach(o => {
        if (o.GenerateTime) {
          this.reqList.push(Number(o.GenerateTime.substring(5)) + '月');
        } else {
          this.reqList.push(o.GenerateTime);
        }
        this.RetailStoresList.push(o.RetailStoresNum);
        this.FranchiseStoresList.push(o.FranchiseFeesNum);
        this.totalList.push(Number(o.FranchiseFeesNum) + Number(o.RetailStoresNum));
      });
    },
    //验证是否有权限。 
    verifyAuthGoRouter (item, num, dataSet1, dataSet2, ModulePowerKey) {
      console.log('verifyAuthGoRouter', dataSet1, dataSet2)
      if (dataSet1 || dataSet2 || ModulePowerKey > 0) {
        if (dataSet1 && dataSet1.SetKey != 1) {
          layer.alert("请开启相应开关设置")
        } else if (dataSet2 && dataSet2.SetKey != 1) {
          layer.alert("请开启相应开关设置")
        } else if (!this.isHasModulePower(ModulePowerKey)) {
          console.log('!!!isHasModulePower')
          this.$utils.CheckModulePowerToTips(ModulePowerKey);
        } else {
          console.log('!!!isHasModulePower')
          this.goRouter(item, num)
        }
      } else {
        this.goRouter(item, num)
      }
    },
    goRouter (item, num) {
      console.log(item, 'goRouter');
      if (item.routerName == 'hqCoursewareManagement') {
        item.dataKey = 0;
      }
      // if (num >= 0) {
      this.$bus.emit('needOpenRouter', item);
      // }
    },
    // 打开弹出层
    openCustomDialogByFactoryDialog (item) {
      this.$emit('openCustomDialogByFactoryDialog', item);
    }

  }
};
</script>
