<template>
  <div>
    <div v-if="isShowCodeData">
      <div class="pay_money">
        <span class="pay_money_span">实付</span>&nbsp;{{decemolNumber}}<span style="font-size: 14px;">&nbsp;元</span>
      </div>

      <div>
        <div class="sign_QRcode_img">
          <img :src="this.isLoading?this.$store.getters.APIURL + '//public/Util/qrcode.php?Content='+encodeURI(this.dataInfo.code_url)+'&size=10':require('../../../../../../public/image/weixinjiaz.png')">
        </div>

        <div class="sign_QRcode_text">
          请使用微信支付扫描二维码付款
        </div>
      </div>

      <div class="wechat_pay">
        <img :src="require('../../../../../../public/image/wechat-pay-M.png')" />
        <span>&nbsp;微信支付</span>
      </div>
    </div>
    <div v-else>
      <div class="success_item">
        <img class="success_item_img"
             :src="require('../../../../../../public/image/BMCG_icon.png')" />
      </div>
      <div class="success_item"
           v-if="type == '1' || type == '3'">
        <span class="success_item_span">&nbsp;&nbsp;付款成功！</span>
      </div>

      <div class="success_item"
           v-if="type == '2'">
        <div class="success_item_span">&nbsp;&nbsp;提现申请成功！</div>
        <div class="success_item_span1">&nbsp;&nbsp;提现金额将在2-3个工作日到账，请注意查收。</div>
      </div>

      <div class="success_item">
        <div class="success_item_content">
          <div class="success_item_content_list"
               v-if="type=='1' || type=='2' ">
            <span class="success_item_content_list_left">{{showTipName}}</span>
            <span class="success_item_content_list_right">￥{{decemolNumber}}</span>
          </div>
          <div class="success_item_content_list"
               v-if="type=='1'">
            <span class="success_item_content_list_left">付款方式</span>
            <span class="success_item_content_list_right_text">微信支付</span>
          </div>
          <div class="success_item_content_list"
               v-if="type=='2'">
            <span class="success_item_content_list_left">提现原因</span>
            <span class="success_item_content_list_right_text">{{withdrawalText}}</span>
          </div>
          <div class="success_item_content_list"
               v-if="type=='3'">
            <span class="success_item_content_list_left">购买通用流量</span>
            <span class="success_item_content_list_right">{{successFlowData.dataInfo.item.SizeNum}}{{successFlowData.dataInfo.item.UnitKey==1?'G':'T'}}</span>
          </div>

          <div class="success_item_content_list"
               v-if="type=='3'">
            <span class="success_item_content_list_left">支付金额</span>
            <span class="success_item_content_list_right_text">{{successFlowData.RecableAmount||successFlowData.ActualColAmount}}元</span>
          </div>
          <div class="success_item_content_list"
               v-if=" type=='3' && successFlowData.dataInfo.mode == '1'">
            <span class="success_item_content_list_left">付款方式</span>
            <span class="success_item_content_list_right_text">微信支付</span>
          </div>
          <div class="success_item_content_list"
               v-if=" type=='3' && successFlowData.dataInfo.mode == '2'">
            <span class="success_item_content_list_left">付款方式</span>
            <span class="success_item_content_list_right_text">余额支付</span>
          </div>

          <div class="success_item_content_list pr"
               v-if=" type=='3' && successFlowData.dataInfo.mode == '3'">
            <span class="success_item_content_list_left">付款方式</span>
            <span class="success_item_content_list_right_text">{{successFlowData.PayType[0]}} </span>
          </div>
          <div class="success_item_content_list pr"
               v-if=" type=='3' && successFlowData.dataInfo.mode == '3'">
            <span class="success_item_content_list_left"></span>
            <span class="success_item_content_list_right_text">{{successFlowData.PayType[1]}} </span>
          </div>
        </div>
      </div>
      <div class="success_item">
        <div class="success_item_buttom"
             @click="compents">完成</div>
      </div>
    </div>
  </div>
</template> 

<script>
import {

} from '../../../../../API/workbench';
export default {
  props: {
    decemolNumber: {
      type: String,
      default: '0.00'
    },
    dataInfo: {
      type: Object,
      default: {}
    },
    isShowCode: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: '1'
    },
    withdrawalText: {
      type: String,
      default: ''
    },
    successFlowData: {
      type: Object,
      default: {}
    },
    isLoading: {
      type: Boolean,
      default: false
    }

  },
  data () {
    return {
      signQRcodeUrl: '',
      isShowCodeData: true,
      showTipName: '充值金额'
    };
  },
  computed: {

  },
  created () {
    this.getSendMessage();
    this.isShowCodeData = this.isShowCode;
    if (this.type == '2') {
      this.showTipName = '提现金额';
    }
    console.log(this.successFlowData, 'successFlowData');
  },
  mounted () {
  },
  watch: {
  },
  components: {
  },
  methods: {
    compents () {
      this.$emit('closeCompent', this.type);
    },
    getSendMessage () {
      this.$bus.on(this, 'wsrechargeTheBalance', (data) => {
        if (data == '1') {
          this.isShowCodeData = false;
        }
      });
    }
  }
};
</script>
<style scoped>
.pay_money {
  margin: 20px 0 20px 132px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #fe4244;
  /* text-align: center; */
}
.pay_money_span {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
}
.wechat_pay {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.wechat_pay span {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.1em;

  color: #333333;
}
.success_item {
  width: 100%;
  margin: 0 auto 10px;
  text-align: center;
}
.success_item_img {
  width: 64px;
  height: 64px;
  margin-top: 20px;
}
.success_item_span {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
}
.success_item_content {
  width: 372px;
  background: linear-gradient(0deg, #ffffff, #ffffff), #f5f5f5;
  border: 1px solid #e8ebef;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  padding-bottom: 20px;
}
.success_item_content_list {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 20px 20px 0 20px;
}
.success_item_content_list_left {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #666666;

  text-align: left;
  min-width: 60px;
}
.success_item_content_list_right {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: right;
  flex: 1;
  color: #fe4244;
}
.success_item_content_list_right_text {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  flex: 1;
  text-align: right;

  color: #333333;
}
.success_item_buttom {
  display: inline-block;
  width: 175px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dadce0;
  border-radius: 4px;
  line-height: 40px;
  cursor: pointer;
  margin-top: 40px;
}
.success_item_span1 {
  width: 100%;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 29px;
  color: #999999;
  text-align: center;
}
.sign_QRcode_img {
  margin: 0px auto 22px;
  width: 145px;
  height: 145px;
}
.sign_QRcode_img img {
  width: 138.58px;
  height: 138.58px;
}
</style>

