<template>
  <div class=""
       style="margin-top:10px">
    <div class="course_statistics_table_search flex">
      <div></div>
      <div class="mendian_backstage_search">
        <div class="btn_hover_bg_blue search_btn "
             style="margin: 0;"
             @click.stop="addClick">新增</div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list ref="multipleTable"
                  class="summarizing_list"
                  :key="tableKey"
                  :tableData="vdataReportList"
                  :tableColumns="tableCourseColumns"
                  :defaultSort="defaultSort"
                  :queryParams="searchObj"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :footerContent="vFooterContent"
                  :showPagePagination="true"
                  @loadTableData="getDataList"></table-list>
    </div>
    <custom-dialog :title="vTitle"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowForm"
                   :before-close="closeFormDialog">
      <addForm :seletedItem="seletedItem"
               @close="closeFormDialog"
               @afterSuccess="search"></addForm>
    </custom-dialog>
  </div>
</template>
<script>
import tableList from "../../common/table-list/index";
import addForm from './add-form'
// import seletedOption from './seleted-option';
import inputSearchContainBtn from "../common/input-search-contain-btn";
import { GetTemplatePageList, DelTemplateDimension, StartUsingTemplate, DisableTemplate } from "../../../API/workbench";
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0, // 门店id
        pageSize: 0,
        searchText: "", // 搜索条件：模糊匹配单号、学生姓名、手机号
        orderBy: "",
        sequence: "DESC"
      },
      isShowForm: false,
      seletedItem: null,
      totalNum: 0,
      totalAmount: 0,
      tableKey: "0",
      PageCount: 0,
      defaultSort: { prop: "", order: "ascending" },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: "序号",
          prop: "Number",
          width: 55,
          sortable: false,
          align: "left",
          type: "number-item",
          className: "table_index_gray"
        },
        // {
        //   label: "Guid",
        //   prop: "Guid",
        //   width: 240,
        //   sortable: false,
        //   align: "left",
        //   isShow: true,
        //   type: "text-item"
        // },
        {
          label: "模板名称",
          prop: "TemplateName",
          minWidth: 140,
          sortable: false,
          align: "left",
          isShow: true,
          className: "",
          type: "text-item"
        },
        {
          label: "包含维度",
          prop: "SetNames",
          minWidth: 240,
          sortable: false,
          align: "left",
          isShow: true,
          className: "",
          type: "text-item"
        },

        {
          label: "操作",
          prop: "operation",
          width: "160",
          align: "center",
          type: "table-btn",
          extend: {
            tableBtnGroup: [
              {
                name: (rowData) => {

                  // 1-进行中 2-已完成 3-取消 4-失效
                  if (rowData.StatusKey == 1) {
                    return '停用'
                  } else {
                    return '启用'
                  }
                },
                extend: {
                  click: (rowData) => {

                    this.enbleItem(rowData);
                  }
                }
              },
              {
                name: '修改',
                extend: {
                  click: (rowData) => {

                    this.editInfo(rowData);
                  }
                }
              },

            ]
          }
        }
      ],
      dataReportList: []
    };
  },
  components: {
    tableList,
    addForm,
    // seletedOption,
    inputSearchContainBtn
  },
  props: {},
  created () { },
  mounted () {
    this.getDataList(this.searchObj);
  },
  computed: {
    vTitle () {
      if (this.seletedItem) {
        return '修改'
      } else {
        return '新增'
      }

    },
    vFooterContent () {
      return "共" + this.totalNum + "条数据";
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {

        newArr.push(o);
      });
      return newArr;
    },
    vFooterContent () {
      return "共" + this.totalNum + "条数据";
    }
  },
  methods: {
    addClick () {
      this.seletedItem = null
      this.isShowForm = true
    },
    closeFormDialog () {
      this.isShowForm = false
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = "";
      this.searchObj.DataTypeKey = 0
      this.getDataList(this.searchObj);
    },

    search () {
      // this.searchObj.TeacherKey = 0;
      this.searchObj.pageIndex = 0;
      this.getDataList(this.searchObj);
    },

    getDataList (queryParams) {
      GetTemplatePageList(queryParams)
        .then(result => {
          this.totalNum = result.data.Total;
          this.dataReportList = result.data.PageDataList;
          console.log(result.data, "GetTemplatePageList");
        })
        .catch(error => {
          console.log("errorGetTemplatePageList", error.msg);
        });
    },
    editInfo (item) {
      this.seletedItem = item
      this.isShowForm = true
    },
    enbleItem (item) {
      if (item.StatusKey != 1) {
        StartUsingTemplate(item.ID).then(result => {
          this.getDataList(this.searchObj)
          console.log(result.data, "GetTemplatePageList");
        })
          .catch(error => {
            console.log("errorGetTemplatePageList", error.msg);
          });
      } else {
        layer.confirm('是否停用当前选中项', {
          btn: [
            {
              name: '确认',
              callBack: () => {
                DisableTemplate(item.ID).then(result => {
                  this.getDataList(this.searchObj)
                  console.log(result.data, "GetTemplatePageList");
                })
                  .catch(error => {
                    console.log("errorGetTemplatePageList", error.msg);
                  });
                layer.close();
              }
            }
          ]
        });
      }
    },

  }
};
</script>
<style scoped>
.class_btn_delay {
  height: 36px;
  width: 100px !important;
  align-items: center;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  background: #3498db url(../../../../public/image/batch_distribution_icon.png)
    no-repeat left 10px center;
  background-size: 16px;
  padding-left: 20px;
}
.class_btn_delays {
  height: 36px;
  width: 90px;
  align-items: center;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
}
.remove_db_btn {
  background-image: url(../../../../public/image/dialog_del_icon.png);
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 8px;
  margin-top: 3px;
}
.tsjj {
  margin-left: 20px;
}
.course_statistics_table_search .mendian_backstage_date {
  margin-right: 0px;
  min-width: 250px;
}
.mendian_backstage_search >>> .search_box {
  width: 240px !important;
  margin-right: 30px !important;
  min-width: 240px !important;
}
.course_statistics_table_box >>> .red_type {
  display: inline-block;
  color: red;
  border: 1px solid #ccc;
  padding: 2px;
  margin-left: 5px;
}
/* 
.payment_statistics_bottom .course_statistics_search.width_small .search_box{
  width: 164px!important;
  min-width: 164px!important;
} */
</style>
