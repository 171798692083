

<template>
  <!-- 当前选中课块的学生 -->

  <!-- 线条类名 border_bottom -->
  <div class="course_student_single_box">

    <div class="course_student_single course_student_sign_defult"
         :class="{course_student_sign_focus:studentItem.isSelected,course_student_sign_disable:studentItem.unSelected}"
         @click.stop="!studentItem.unSelected&&selectedStudent(studentItem)">
      <div>
        <div class="course_student_photo pr">
          <heads-img :imgUrl="headImg"
                     :dataInfo="studentItem"></heads-img>
          <div class="icon_bottom bg_green pa"
               v-if="vAttendanceStatusKey==3">
          </div>
          <div class="icon_bottom bg_red pa"
               v-else-if="vAttendanceStatusKey==5">
            旷
          </div>
          <div class="icon_bottom bg_yellow pa"
               v-else-if="vAttendanceStatusKey==4">
            假
          </div>
          <div class="icon_bottom bg_warning pa"
               v-else-if="isCourseEnd">

          </div>
        </div>
      </div>
      <div class="course_student_info">
        <div class="course_student_name">{{studentItem.StudentKeyValue}}</div>
        <div class="font_small font_yellow"
             v-if="studentItem.IsTrialKey==1?true:false">
          试课学员
        </div>
        <div class="font_small font_yellow"
             v-else-if="studentItem.IsMakeupKey==1?true:false">
          补课
        </div>
        <div class="font_small font_red"
             v-else-if="vNoClassHourse">
          无课时
        </div>
        <div class="font_small font_red"
             v-else-if="studentItem.ISClassHourDebtKey>0?true:false">
          欠课时
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
/// 有四种 状态 请假，旷课，签到，未签到
// 点击事件 根据状态有两种弹框且要 通知父组件 我被选中（已考勤 未考勤）
// 学生排课表状态 2：占位 3:排队中 4：已安排 5：已上课 6：已旷课 7：已请假 8：已取消
export default {
  name: 'studentItem',
  data () {
    return {
    };
  },
  props:{
    studentItem:Object,
    isCourseEnd:Boolean,
    isBatchAttendance:Boolean,
    isAlter:{
      type:Boolean,
      default:()=>{
        return true
      }
    }
  },
 
  computed: {
    vAttendanceStatusKey () {
      return this.studentItem.AttenceStatusKey;
    },
    vNoClassHourse () { // 无课时 未考勤且欠课时
      if (this.studentItem.AttenceStatusKey == 2 && this.studentItem.IsClassHourLackKey == 1) {
        return true;
      } else {
        return false;
      }
    },
    // 头像
    headImg () {
      if (this.studentItem && this.studentItem.HeadImgSupport && this.studentItem.HeadImgSupport !== 'null') {
        if (this.studentItem.HeadImgSupport.indexOf('http') > -1) {
          return this.studentItem.HeadImgSupport;
        } else {
          let PCAPIURL = this.$store.getters.CDNURL;
          return (
            PCAPIURL + this.studentItem.HeadImgSupport
          );
        }
      } else {
        return '';
      }
    }
  },
  methods: {
    // 选中学生事件 告诉 <student-list> 并传递选中项信息
    selectedStudent () {
      if(this.isAlter){
        if (!this.isBatchAttendance) {
          let Rect = this.$el.getBoundingClientRect();
          let parentRect = this.$parent.$el.getBoundingClientRect();
          let that = this;
          document.onclick = function () {
            that.$store.commit('showAlerDialog', false);
            that.studentItem.isSelected = false;
          };
          if (typeof this.$parent.isShowDialog == 'boolean') {
            this.$parent.isShowDialog = true;
          }
          this.$emit('selectedStudent', this.studentItem);
          this.$store.commit('showAlerDialog', true);
          this.$emit('setPosition', Rect.left - parentRect.left, Rect.bottom - parentRect.top, parentRect.top); // event.clientX  event.clientY   //显示修改考勤
        } else {
          this.studentItem.isSelected = !this.studentItem.isSelected;
        }
      }
    }
  }
};
</script>