<template>
  <!-- 逾期未备列表 -->
  <div class=" table_list_content summarizing_list">
    <div class="single_result_detail">
      <!-- 搜索 -->
      <div class="table_select_box">
        <input-search-contain-btn v-model="queryParams.searchText"
                                  style="margin: 10px;"
                                  :width="'280px'"
                                  :placeholder="'请输入'"
                                  @clearSearch="clearSearch"
                                  @onSearchEvent="search"></input-search-contain-btn>
      </div>
      <!-- 通用列表 -->
      <table-list ref="tableListRef"
                  :tableData="tableDateList"
                  :tableColumns="tableColumns"
                  :rowKey="'Number'"
                  :totalNum="totalNum"
                  :footerContent="'共' + totalNum + '条数据'"
                  :defaultSort="defaultSort"
                  :queryParams="queryParams"
                  @loadTableData="getSearchPageOverdueNotPrepareLessons"></table-list>
    </div>
  </div>
</template>
<script>
import { hqInspectStatSearchPageOverdueNotPrepareLessons, SearchPageOverdueNotPrepareLessons } from '../../../../../../API/workbench.js';
import inputSearchContainBtn from '../../../../../report/common/input-search-contain-btn';
import tableList from '../../../../../common/table-list/index';
export default {
  data () {
    return {
      // 查询参数
      queryParams: {
        pageIndex: 0, //	整型	可选		数据源：GET	页码
        pageSize: 10, //	整型	可选		数据源：GET	分页大小
        searchText: '', //	字符串	可选		数据源：GET	关键字
        StartDate: this.$utils.getCurrentMonthFirst(), //	字符串	可选		数据源：GET	开始日期
        EndDate: this.$utils.getCurrentMonthLast(), //	字符串	可选		数据源：GET	结束日期
        TeacherKey: '', //	整型	可选		数据源：GET	老师id
        SchoolKey: '', //	整型	可选		数据源：GET	校区id
        SchoolKeyValue: ''
      },
      tableDateList: [], // 门店列表
      tableColumns: [
        {
          label: '上课时间',
          prop: 'newClassTimeText',
          align: 'left',
          width: 300,
          sortable: false,
          type: 'date-item',
          extend: {
            dateType: 'YYMMRR HH:DD' // 日期类型
          }
        },
        {
          label: '班名',
          prop: 'GradeClassKeyValue',
          width: 260,
          align: 'left',
          type: 'text-item',
          sortable: false
        },
        {
          label: '课名',
          prop: 'CourseNameKeyValue',
          align: 'left',
          type: 'text-item',
          sortable: false
        }
      ],
      pageTotal: 0,
      totalNum: 0,
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  components: {
    inputSearchContainBtn,
    tableList
  },
  props: {
    paramsObj: Object
  },
  computed: {
    vTypeKey () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) { // 总部key为6
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    paramsObj: {
      // 深度监听该对象
      handler: function (c, o) {
        console.log(' paramsObj', c);
        this.queryParams.pageIndex = 0;
        this.queryParams.pageSize = 10;
        this.queryParams.StartDate = c.StartDate;
        this.queryParams.EndDate = c.EndDate;
        this.queryParams.TeacherKey = c.TeacherKey;
        this.queryParams.SchoolKey = c.SchoolKey;
        this.getSearchPageOverdueNotPrepareLessons(this.queryParams);
      },
      deep: true
    }
  },
  created () {
    this.queryParams.StartDate = this.paramsObj.StartDate;
    this.queryParams.EndDate = this.paramsObj.EndDate;
    this.queryParams.TeacherKey = this.paramsObj.TeacherKey;
    this.queryParams.SchoolKey = this.paramsObj.SchoolKey;
    this.getSearchPageOverdueNotPrepareLessons(this.queryParams);
  },
  methods: {
    // 门店记录列表
    getSearchPageOverdueNotPrepareLessons (queryParams) {
      queryParams.searchText = queryParams.searchText.trim();
      let apiFN = (this.vTypeKey ? hqInspectStatSearchPageOverdueNotPrepareLessons : SearchPageOverdueNotPrepareLessons);
      apiFN(queryParams).then(result => {
        console.log('门店记录列表', result.data);
        this.totalNum = result.data.Total; // 分页数
        this.tableDateList = result.data.PageDataList || [];
        if (this.tableDateList.length > 0) {
          this.tableDateList.forEach(o => {
            o.newClassTimeText = o.ClassTime.slice(5, 10) + ' ' + o.WeekKeyValue + o.ClassTimeName;
          });
        }
        // 获取动态老师排名列表滚动高度
        this.$nextTick(() => {
          if (this.$refs.tableListRef && this.$refs.tableListRef.$el.clientHeight > 0) {
            console.log(' 老师课评排名高度', this.$refs.tableListRef.$el.clientHeight);
            this.$emit('teacherListScrollHeight', this.$refs.tableListRef.$el.clientHeight);
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 搜索
    search (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.queryParams.pageIndex = 0;
      this.getSearchPageOverdueNotPrepareLessons(this.queryParams);
    },
    // 清除条件
    clearSearch () {
      this.queryParams.pageIndex = 0;
      this.queryParams.searchText = '';
      this.getSearchPageOverdueNotPrepareLessons(this.queryParams);
    },
    // 排序
    loadTableData (queryParams) {
      console.log(queryParams, '排序');
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.getSearchPageOverdueNotPrepareLessons(this.queryParams);
    }
  }
};
</script>
<style>
</style>

