<template>
  <div class="max_courseware">
    <div class="courseware_list"
         :class="{opt:dataInfo.isSelected,type_01: vIsUseUp,type_02:vAuthorizeStatus}"
         @mouseleave.stop="mouseOutEvent">
      <div class="max_list_box"
           @click.stop="selectEvent">
        <div class="list_number">{{dataInfo.ShowOrder}}</div>
        <div class="list_img"
             @mouseenter="mouseOverEvent">

          <!-- 大课包 显示... -->
          <div class="list_img_btn_tomove mover"
               :title="'按住可拖动排序'"
               @click.stop="stopClick"
               v-if="isShowBtn && type != 'SQ'"
               @mousedown.stop="moveStart"
               @mouseup="stopMove">

          </div>

          <div class="list_img_btn"
               @click.stop="stopClick"
               v-if="isShowBtn&&newOperateMenu.length>0 && type != 'SQ'"
               @mouseenter="showOperateMenu"
               @mouseleave="isShowOperateMenu=false">
            <div class="list_img_ul"
                 v-if="isShowOperateMenu">
              <!-- 大课包按钮显示 -->
              <div v-for="item in newOperateMenu"
                   :key="item.key">
                <div class="list_img_menu"
                     :class="item.className"
                     v-if="item.isShow"
                     @click.stop="doOperateItemClick(item)">
                  {{item.name}}
                </div>
              </div>

            </div>
          </div>
          <div class="courseware_packge_list_item_bg"></div>
          <img class="img_box"
               :src="vCoverImg"
               alt="">
          <div class="auto_release_text"
               v-if="type == 'ZZ'&&dataInfo.IsAutoReleaseKey==0">手动释放</div>
        </div>
        <div class="max_list_info ">
          <div class="list_name">
            {{dataInfo.MainDemoName}}
          </div>
          <div class="list_text flex_row">
            <div>
              <span>课件 <span class="font_black">{{Number(dataInfo.CoursewareSum)||0}}</span></span>
              <span class="capacity">空间 <span class="font_black">{{this.$utils.capacityFormate(Number(dataInfo.OccupiedCapacity))}}</span></span>
            </div>
            <div>
              <span class="status_type_orange"
                    v-if="type == 'ZZ'&&dataInfo.ToBeReviewedNum>0">待审批</span>
              <!--  {{dataInfo.ToBeReviewedNum}}  {{Number(dataInfo.ToBeReleasedNum)+Number(dataInfo.NotReleaseNum)}} -->
              <span class="status_type_yellow"
                    v-if="type == 'ZZ'&&(dataInfo.ToBeReleasedNum>0||dataInfo.NotReleaseNum>0)">待发布</span>
              <span v-if="(type=='SQ'||dataInfo.type==0)&&dataInfo.SaaSClientKeyValue"
                    class="list_details">授权方：{{dataInfo.SaaSClientKeyValue}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="list_btn"
           v-if="type=='ZZ'">
        <div class="list_left"
             @click="doListSort('Top')"
             v-if="Number(dataInfo.ShowOrder)>1"></div>
        <div class="list_right"
             @click="doListSort('Bottom')"
             v-if="Number(dataInfo.ShowOrder)<listLength"></div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isShowBtn: false, // 显示按钮
      isShowOperateMenu: false, // 显示菜单
      isShowMenuBeing: true, // 是否已是移出状态
      newOperateMenu: [],
      operateMenu: [
        // { name: '复制', className: 'type_01', key: 'copy', isShow: false },
        { name: '编辑', className: 'type_02', key: 'edit', isShow: true },
        // { name: '删除', className: 'type_03', key: 'delete', isShow: true },
        { name: '停用', className: 'type_06', key: 'disable', isShow: true }
        // { name: '启用', className: 'type_07', key: 'enable', isShow: false }
      ],
      lastClickTime: null
    };
  },
  props: {
    listLength: Number,
    dataInfo: Object,
    // operateMenu: Array,
    type: String
  },
  computed: {
    // 是否已用完
    vIsUseUp () {
      if (this.type == 'SQ' || this.dataInfo.type == 0) {
        // type类型为回收站
        if (this.dataInfo.Authorize) {
          return Number(this.dataInfo.Authorize.isUseUp) == 1;
        } else {
          return Number(this.dataInfo.isUseUp) == 1;
        }
      } else {
        return false;
      }
    },
    // 是否暂停授权
    vAuthorizeStatus () {
      if (this.type == 'SQ' || this.dataInfo.type == 0) {
        // type类型为回收站
        if (this.dataInfo.Authorize) {
          return Number(this.dataInfo.Authorize.AuthorizeStatusKey) == 0;
        } else {
          return Number(this.dataInfo.AuthorizeStatusKey) == 0;
        }
      } else {
        return false;
      }
    },
    // 非自研课件时不显示添加按钮
    vCoverImg () {
      if (this.dataInfo.CoverUrl) {
        return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
      } else {
        return '';
      }
    }
  },
  created () { },
  methods: {
    stopClick () {

    },
    // 鼠标移入,显示按钮
    mouseOverEvent () {
      // console.log(this.dataInfo, '鼠标移入');
      switch (this.type) {
        case 'SQ':// 授权
          this.isShowBtn = false;
          this.newOperateMenu = [];
          break;
        case 'ZZ':// 自研
          this.isShowBtn = true;
          this.newOperateMenu = this.$utils.parseJson(this.operateMenu);
          // 是否发布中(0-否;1-是)
          break;
        case 'HSZ':// 回收站
          break;
        default:
          break;
      }
    },
    moveStart () {
      this.$emit("moveStart", this.dataInfo)
    },
    stopMove () {
      this.$emit("stopMove", this.dataInfo)
    },
    // 鼠标移入,显示操作菜单
    showOperateMenu () {
      this.isShowOperateMenu = true;
    },
    // 鼠标离开
    mouseOutEvent () {
      this.isShowBtn = false;
      this.isShowOperateMenu = false;
    },
    // 点击事件
    selectEvent () {
      this.$emit('selectEvent', this.dataInfo);
    },
    // 操作项
    doOperateItemClick (item) {
      if (this.checkCanClick()) {
        this.$emit('doOperateItemClick', item, this.dataInfo);
        this.mouseOutEvent();
      }
    },
    // 排序
    doListSort (sort) {
      if (this.checkCanClick()) {
        this.$emit('doListSort', sort, this.dataInfo);
        console.log(sort, '排序');
      }
    },
    checkCanClick () {
      let now = Date.new();
      let r = !this.lastClickTime || now - this.lastClickTime > 800;
      if (r) {
        this.lastClickTime = now;
      }
      return r;
    }
  }
};
</script>

<style scoped>
.max_courseware_manage .courseware_list .list_img_btn_tomove {
  position: absolute;
  right: 50px;
  width: 42px;
  height: 32px;
  cursor: pointer;
  background: url(../../../../public/image/courseware_icon_01.png) no-repeat;
  z-index: 1;
}
.max_courseware_manage .courseware_list .list_img_btn {
  position: absolute;
  right: 0px;
  width: 42px;
  height: 32px;
  cursor: pointer;
  background: url(../../../../public/image/courseware_icon_04.png) no-repeat;
  background-size: 30px;
  z-index: 1;
}
.courseware_list .list_img_ul {
  position: absolute;
  right: 6px;
  top: 30px;
  width: 86px;
  background-color: #fff;
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 6px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.02), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  z-index: 99;
}
.max_courseware .courseware_list .list_img_btn,
.max_courseware_manage .courseware_list .list_img_btn_tomove {
  top: 8px;
  background-size: 30px;
}

.courseware_list {
  /* position: relative; */
  display: flex;
  width: 416px;
  height: 315px;
  float: left;
  margin: 0 14px 40px 14px;
}

.courseware_list .max_list_box {
  width: 416px;
  height: 315px;
  background: #fff;
  border-radius: 8px;
  padding: 8px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-bottom: 20px;
}
.courseware_list .list_btn {
  position: relative;
  width: 0px;
}
.courseware_list .list_left {
  position: absolute;
  left: -310px;
  right: 0px;
  height: 18px;
  cursor: pointer;
  bottom: 0px;
  top: 250px;
  width: 10px;
  background: url(../../../../public/image/arrows_top.png) no-repeat center
    center;
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg); /* IE 9 */
  -moz-transform: rotate(-90deg); /* Firefox */
  -webkit-transform: rotate(-90deg); /* Safari 和 Chrome */
  -o-transform: rotate(-90deg); /* Opera */
}

.courseware_list .list_left:hover {
  background-image: url(../../../../public/image/arrows_top_blue.png);
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg); /* IE 9 */
  -moz-transform: rotate(-90deg); /* Firefox */
  -webkit-transform: rotate(-90deg); /* Safari 和 Chrome */
  -o-transform: rotate(-90deg); /* Opera */
}

.courseware_list .list_right {
  position: absolute;
  height: 18px;
  bottom: 0px;
  top: 250px;
  left: -30px;
  right: 0;
  cursor: pointer;
  width: 10px;
  background: url(../../../../public/image/arrows_bottom.png) no-repeat center
    center;
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg); /* IE 9 */
  -moz-transform: rotate(-90deg); /* Firefox */
  -webkit-transform: rotate(-90deg); /* Safari 和 Chrome */
  -o-transform: rotate(-90deg); /* Opera */
}

.courseware_list .list_right:hover {
  background-image: url(../../../../public/image/arrows_bottom_blue.png);
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg); /* IE 9 */
  -moz-transform: rotate(-90deg); /* Firefox */
  -webkit-transform: rotate(-90deg); /* Safari 和 Chrome */
  -o-transform: rotate(-90deg); /* Opera */
}

.courseware_list .max_list_info {
  /* height: 50px; */
  cursor: pointer;
  margin: 5px;
}
.max_courseware .auto_release_text {
  position: absolute;
  width: 76px;
  height: 36px;
  right: 5px;
  background: #ff6c67;
  border-radius: 5px;
  bottom: 10px;
  text-align: center;
  line-height: 34px;
  color: #fff;
  font-family: Alibaba;
  font-size: 14px;
}
.max_courseware .status_type_01 {
  background: #ffde2e !important;
}
.max_courseware .status_type {
  width: 76px;
  height: 36px;
  line-height: 36px;
  background: #ffeed0;
  border-radius: 5px;
  color: black;
  font-family: Alibaba;
  font-size: 14px;
  margin-left: 4px;
  float: right;
  text-align: center;
  margin-top: -8px;
}
.capacity {
  padding-left: 18px;
}
.courseware_list .list_number {
  position: absolute;
  left: 0px;
  top: 8px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  width: 44px;
  height: 28px;
  line-height: 28px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0 12px 12px 0;
  z-index: 1;
}

.status_type_yellow {
  width: 66px;
  height: 24px;
  border-radius: 5px;
  background: #ffeed0;
  color: black;
  font-family: Alibaba;
  font-size: 14px;
  margin-left: 10px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
}
.status_type_orange {
  width: 66px;
  height: 24px;
  border-radius: 5px;
  background: rgba(255, 122, 0, 0.2);
  color: black;
  font-family: Alibaba;
  font-size: 14px;
  margin-left: 10px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
}
.status_type_red {
  width: 66px;
  height: 24px;
  border-radius: 5px;
  background: rgba(248, 213, 213, 1);
  color: rgba(217, 45, 45, 1);
  font-family: Alibaba;
  font-size: 14px;
  margin-left: 10px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
}
.flex_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.courseware_packge_list_item_bg {
  /* background: linear-gradient(
    225.87deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 90.8%
  ); */
  /* opacity: 0.35; */
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('../../../../public/image/coureware_bg_highlight.png') center
    no-repeat;
  background-size: 100%;
}
</style>
