<template>
   <div class="mendian_backstage_summarizing_statistics">
        <header-report-item :dataInfo="reportInfo.thisMonth" :type="'0'"></header-report-item>
        <header-report-item :dataInfo="reportInfo.last1Month" :type="'1'"></header-report-item>
        <header-report-item :dataInfo="reportInfo.last2Month" :type="'2'"></header-report-item>
        <header-report-item :dataInfo="reportInfo.last3Month" :type="'3'"></header-report-item>
      </div>
</template>
<script>
import { SummarizeTutorialData } from '../../../../API/workbench';
import headerReportItem from './header-report-item';
export default {
  data () {
    return {
      reportInfo: {
        thisMonth: {},
        last1Month: {},
        last2Month: {},
        last3Month: {}
      }
    };
  },
  components: {
    headerReportItem
  },
  created () {
    this.getSummarizeTutorialData();
  },
  mounted () {
    this.$bus.on(this, 'updateMendianOperationManagement', (classID, studentID) => {
      console.log('updateMendianOperationManagement');
      this.getSummarizeTutorialData();
    });
  },
  methods: {
    getSummarizeTutorialData () {
      SummarizeTutorialData().then(result => {
        this.reportInfo = result.data;
        console.log(result.data, 'SummarizeTutorialData');
      }).catch(error => {
        console.log(error, 'SummarizeTutorialDataError');
      });
    }
  }
};
</script>
