<template>
  <div class="JXT_dynamic_comment_list" :class="replyData.activeName?'bg_color':''"
       @click.stop="replyMineClick">
    <div class="list_img">
      <img class="img_box"
           :src="vFromHeadImg"
           alt="">
    </div>
    <div class="list_content">
      <div class="list_date">
        {{vReplyTime}}
      </div>
      <div class="list_name">
        <span>{{vReplyDataFromName}}</span>
        <span v-if="replyData.MainForReplyID>0">
          <span>回复</span>{{vReplyDataToName}}
          </span>
      </div>
      <div class="list_text">
        {{replyData.Content}}
      </div>
    </div>
        <div v-if="replyData.activeName&&isShowMenu">
      <div class="course_student_attendance_operation module_content_menuList"
           style="left: 10%; top:22px;width: 80px">
        <div class="top_bg"></div>
        <drop-down-box :optionJsonList="replyOperateMenu"
                       @clickOptionForAlertDialog="onItemClick"></drop-down-box>
      </div>
    </div>
  </div>
</template>

<script>

import dropDownBox from '../../../common/drop-down-box';
export default {
  components: {
    dropDownBox
  },
  data () {
    return {
      isShowMenu: false,
      replyOperateMenu: [
        { type: 1, name: '删除', eventName: 'delete', isShow: true, ModulePowerKey: '' }
      ]
    };
  },
  props: {
    replyData: {
      type: Object,
      default: function () {
        return {
          ReplyTypeKey: '', // 回复类型',
          FromTypeKey: '', // 发送人类型',
          From: '', // 发送人',
          FromID: '', // 发送人ID',
          FromHeadImg: '', // 发送人头像',
          ToTypeKey: '', // 1老师2老师3家长',
          To: '', // 接收人',
          ToID: '', // 接收人ID',
          ToHeadImg: '', // 接收人头像',
          ForReplyID: '', // 当前目标回复ID',
          MainForReplyID: '', // 主回复ID0表示为回复短文',
          Content: '', // 回复内容',
          ReplyTime: '' // 回复时间',
        };
      }
    },
    replyList: Array,
    IsAllSchool: {
      type: Number,
      default: 2
    } // 是否切换全校模式，模式 1-全校;2-普通
  },
  computed: {
    vReplyDataFromName () {
      if (this.IsAllSchool == 2) { // -普通模式
        if (Number(this.replyData.FromID) == Number(this.$store.getters.signData.UserID)) {
          return '我';
        } else {
          return this.replyData.From;
        }
      } else {
        return this.replyData.From;
      }
    },
    vReplyDataToName () {
      if (this.IsAllSchool == 2) { // -普通模式
        if (Number(this.replyData.ToID) == Number(this.$store.getters.signData.UserID)) {
          return '我';
        } else {
          return this.replyData.To;
        }
      } else {
        return this.replyData.To;
      }
    },
    vReplyTime () {
      let curTime = Math.round(Date.new().getTime() / 1000); // 当前时间的秒数
      let ReplyTimeIOS = this.replyData.ReplyTime.replace(/\-/g, '/');// 兼容苹机果显示
      let replyTime = curTime - Date.new(ReplyTimeIOS).getTime() / 1000; // 当前时间与要转换的时间差（ s ）
      let time = '';
      if (replyTime < 60) {
        time = '刚刚';
      } else if (replyTime > 60 && replyTime < 3600) {
        time = Math.floor(replyTime / 60) + '分钟前';
      } else if (replyTime > 3600 && replyTime < 86400) {
        time = this.replyData.ReplyTime.slice(11, 16); // 时间
      } else if (replyTime > 86400 && replyTime < 604800) {
        time = Math.floor(replyTime / 86400) + '天前';
      } else if (replyTime > 604800 && replyTime < 2678400) {
        time = Math.floor(replyTime / 604800) + '周前';
      } else if (replyTime > 2678400 && replyTime < 31536000) {
        time = Math.floor(replyTime / 2678400) + '月前';
      } else if (replyTime > 31536000) {
        time = Math.floor(replyTime / 31536000) + '年前';
      }
      return time;
    },
    vFromHeadImg (url) {
      if (this.replyData.FromHeadImg) {
        if (this.replyData.FromHeadImg.indexOf('http') > -1) {
          return this.replyData.FromHeadImg;
        } else {
          return this.$store.getters.CDNURL + this.replyData.FromHeadImg;
        }
      } else {
        return '';
      }
    },
    mainReplyList () {
      // 遍历主回复及子回复.  然后再进行重组.
      var mainReply = [];
      this.replyList.forEach(o => {
        if (o.ReplyTypeKey != 1) {
          if (o.MainForReplyID == 0) {
            if (!o.childList) {
              this.$set(o, 'childList', []); // 强制监听此属性.
            }
            mainReply.push(o);
          }
        }
      });
      mainReply.forEach(c => {
        if (c.childList.length > 0) { c.childList.splice(0, c.childList.length); }
        this.replyList.forEach(p => {
          if (c.ID == p.MainForReplyID) {
            c.childList.push(p);
          }
        });
      });
      return mainReply;
    }
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isShowMenu = false;
    }, false);
  },
  methods: {
    replyMineClick () {
      this.replyList.forEach(o => {
        if (o.ID == this.replyData.ID) {
          o.activeName = true;
        } else {
          o.activeName = false;
        }
      });
      if (this.replyData.FromID == this.$store.getters.signData.UserID) {
        this.isShowMenu = true;
      } else {
        this.$emit('toggleReply', this.replyData); // 调用方法传值
      }
    },
    onItemClick (Menu) {
      console.log(Menu.eventName, '回复项');
      this.$emit('toggleReply', this.replyData, Menu.eventName); // 调用方法传值
    }
  }
};
</script>

<style>
</style>