<template>
  <div class="data_content"
       :class="className"
       @click.stop="clickItem">
    <div class="report_item_header">
      本月业绩
    </div>
    <div class="report_item_content">
      <div class="content_item">
        <div class="content_item_title">
          上课课次
        </div>

        <div class="content_item_text">
          {{Number(dataInfo.CourseCount)||0}}
        </div>
      </div>
      <div class="content_item">
        <div class="content_item_title">
          老师课时
        </div>

        <div class="content_item_text">
          {{Number(dataInfo.CourseHourCount)||0}}
        </div>
      </div>
      <div class="content_item">
        <div class="content_item_title">
          学生课时
        </div>

        <div class="content_item_text">
          {{Number(dataInfo.StudentHourCount)||0}}
        </div>
      </div>
      <div class="content_item"
           style="margin-right:0px">
        <div class="content_item_title">
          签到/请假
        </div>

        <div class="content_item_text">
          {{Number(dataInfo.SignCount)||0}}/{{Number(dataInfo.LeaveCount)||0}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      trycoursebtnInfo: {
        "name": "老师业绩统计",
        "routertitle": "",
        "routerName": "",
        "auth": true,
        "roleName": "",
        "ModulePowerKey": 0,//71
        "img_type": "09"
      },

    }
  },
  props: {
    dataInfo: Object,
    className: {
      type: String,
      default: () => {
        return 'type_blue'
      }
    }
  },
  created () {

  },
  methods: {
    clickItem () {
      this.$emit("showTeacherList", this.trycoursebtnInfo)
    },

  }
}
</script>
<style scoped>
.data_content {
  width: 100%;
  height: 159px;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  box-shadow: 2px 2px 2px 0px #d7d7d733;
  margin-bottom: 20px;
  padding: 15px 20px;
}
.report_item_header {
  position: relative;
  color: #333;
  font-size: 20px;
  margin-bottom: 10px;
}
.report_item_header::after {
  position: absolute;
  display: block;
  content: '';
  width: 18px;
  height: 18px;
  left: 79px;
  top: 0px;
  background: url('../../../../public/image/next.png') no-repeat center;
  background-size: 13px;
}
.report_item_content {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 15px;
}
.report_item_header_btn {
  width: 64px;
  height: 24px;
  border-radius: 4px;
  background: #ffffff;
  border-image-source: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #ececec, #ececec);
  color: #333;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  line-height: 24px;
}
.content_item_title {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 16px;
}
.report_item_header_btn:hover {
  color: #3498db !important;
  border: #3498db !important;
}
.report_item_content .content_item {
  flex: 1;
  text-align: center;
  position: relative;
  border: 1px solid #ececec;
  border-radius: 10px;
  margin-right: 20px;
  padding-bottom: 20px;
}
/* .report_item_content .content_item::after{
        position: absolute;
        display: block;
        content: '';
        width: 1px;
        height: 55px;
        right: 0px;
        top: 3px;
        border: 1px solid #E7E9EA
    } */
.report_item_content .content_item:last-child::after {
  display: none;
}
.content_item_text {
  font-size: 16px;
}
</style>