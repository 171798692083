<template>
  <div class="payment_statistics_bottom clearfix">
    <div class="course_statistics_table_search flex course_statistics_title">
      <div class="course_statistics_search">
        <div class="table_select_box">
          <div class="table_select_title">关注</div>
          <el-select style="width: 160px"
                     :value="vStatusValue"
                     filterable
                     placeholder="必选"
                     @change="selectedStatus"
                     value-key="key">
            <el-option v-for="item in statusList"
                       :key="item.key"
                       :label="item.name"
                       :value="item"></el-option>
          </el-select>
        </div>
        <input-search-contain-btn v-model="searchValue"
                                  :placeholder="'请输入姓名、姓名首字母、手机号'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
      </div>
    </div>
    <div>
      <div class="table_list_content summarizing_list"> 
        <div class="single_result_detail">
          <table-list ref="tableListRef"
                      :tableData="dataReportList"
                      :checkBoxList="studentList"
                      :tableColumns="tableCourseColumns"    
                      :totalNum="totalNum"
                      :footerContent="'共'+totalNum+'条数据'"
                      :defaultSort="defaultSort"
                      :queryParams="searchObj"
                      @loadTableData="getDataList"
                      @changeCheckBoxFun="changeCheckBoxFun"></table-list>
        </div>
      </div>
      <!-- 按钮组 -->
      <div class="pr">
        <div style="position: absolute; top: 20px;left: 20px;">
          已选择
          <span class="font_blue"> {{studentList.length}}</span>人
        </div>
        <save-cancel-btn-group :isSingleSubmit="true"
                               style="width: 53%;float: right;"
                               :singleSubmitType="'dynamic'"
                               @confirmClick="submit"
                               @cancelClick="cancel"></save-cancel-btn-group>
      </div>

    </div>
    <!-- <div v-else
         class="loading_fixed_box"
         style="height: 600px;">
      <div class="loading_fixed_gray">
        <span class="loading_style">加载中...</span>
      </div>
    </div> -->
  </div>
</template>
<script>
import inputSearchContainBtn from '../../../../report/common/input-search-contain-btn';
import tableList from './table-list';
import { getAllStudentList } from '../../../../../API/workbench';
export default {
  data () {
    return {
      searchValue: '', // 搜索内容，防止双向绑定联动
      dataReportList: [], // 记录数据列表
      searchObj: {
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        status: 0, // 学生状态 1-在校; 2-潜客;3-离校
        QueryID: '',
        SubscribeKey: ''
      },
      studentList: [], // 学生列表
      // 使用状态状态1：未使用 2 已使用 3.已过期
      statusList: [
        { name: '全部', key: '' },
        { name: '已关注', key: 1 },
        { name: '未关注', key: 0 }
      ],
      isLoading: false,
      totalNum: 0,
      tableKey: '0',
      defaultSort: { prop: '', order: 'ascending' },
      // 明细总览 table 列
      tableCourseColumns: [
        {
          label: '学生',
          prop: 'MainDemoName',
          type: 'text-item',
          width: 200
        },
        {
          label: '关注',
          prop: 'SubscribeKey',
          type: 'wechat-subscribe',
          width: 'auto'
        },
        {
          label: '手机号',
          prop: 'CustomerPhoneName',
          type: 'text-item',
          width: 200
        },
        {
          label: '上次发送时间',
          prop: 'LastSendCouponTime',
          type: 'date-item',
          width: 200
        }
      ]
    };
  },
  components: {
    inputSearchContainBtn,
    tableList
  },
  props: {
    type: [Number, String],
    checkBoxList: Array
  },
  created () {
    this.getDataList();
    this.studentList = this.$utils.parseJson(this.checkBoxList);
    console.log(this.studentList, '选中1');
  },
  mounted () {
    // this.showCheckBox();
  },
  computed: {
    vStatusValue () {
      let findItem = this.statusList.find(o => { return o.key === this.searchObj.SubscribeKey; });
      console.log(findItem);
      if (findItem) {
        return findItem.name;
      } else {
        return '全部';
      }
    }
  },
  methods: {
        // 选中效果
    showCheckBox () {
      this.$refs.tableListRef.$refs.multipleTable.selection.length = 0;
      this.studentList.forEach(o => {
        // console.log(o.MainDemoName, '选中学生111');
        this.dataReportList.forEach(i => {
          if (o.OLAPKey == i.OLAPKey) {
            // console.log(this.$refs.tableListRef.$refs.multipleTable, '选中效果事件');
            this.$refs.tableListRef.$refs.multipleTable.toggleRowSelection(i, true);
          }
        });
      });
    },
    // 复选框
    changeCheckBoxFun (list, row) {
      if (row) { // 单选
        this.$set(row, 'isOpt', false);

        list.forEach(o => {
          if (o.OLAPKey == row.OLAPKey) {
            this.$set(row, 'isOpt', true);
          }
        });
        var findIndex = this.studentList.findIndex(o => { // 找到该下标
          return Number(o.OLAPKey) == Number(row.OLAPKey) && row.isOpt == false;
        });
        if (findIndex >= 0 && !row.isOpt) {
          this.studentList.splice(findIndex, 1);// 删除
        } else if (row.isOpt) {
          this.studentList.push(row);// 添加
        }
      } else { // 全部
        if (list.length > 0) { // 批量添加
          list.forEach(o => {
            this.studentList.push(o);
          });
        } else {
          this.dataReportList.forEach(p => {
            this.studentList.forEach((o, index) => {
              if (o.OLAPKey == p.OLAPKey) {
                this.studentList.splice(index, 1);// 批量删除
              }
            });
          });
        }
      }
      this.studentList = this.unique(this.studentList);
      console.log(this.studentList, '复选框3');
    },
    // 去重
    unique (arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.OLAPKey) && res.set(arr.OLAPKey, 1));
    },
    // 根据状态搜索
    selectedStatus (obj) {
      console.log(obj, '选中');
      this.searchObj.SubscribeKey = obj.key;
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 搜索文本
    search () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = this.searchValue;
      this.getDataList();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchValue = '';
      this.searchObj.searchText = this.searchValue;
      this.searchObj.pageIndex = 0;
      this.searchObj.status = this.type;
      this.getDataList();
    },
    // 返回符合条件的, 当前页的指定条数学生数据
    getDataList (queryParams) {
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      this.searchObj.status = this.type;
      getAllStudentList(this.searchObj).then(result => {
        this.isLoading = true;
        this.totalNum = result.data.Total;
        this.dataReportList = result.data.PageDataList;
        this.showCheckBox();
        console.log(result.data.PageDataList, '指定条数学生数据');
      }, error => {
        layer.alert(error.msg);
      });
    },
    submit (callBack) {
      let flag = false;
      if (this.studentList.length == 0) {
        layer.alert('请选择至少一个学生作为接收对象');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.$emit('submit', this.studentList, this.type);
    },
    cancel (data) {
      this.$emit('cancel', data);
    }
  }
};
</script>