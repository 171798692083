<template>
  <div class="margin_left_right_div">
    <div class="template_minOrmax_width_div">
      <div class="payment_statistics_view height_type all_view">
        <header-content :title="'学杂费收入'"
                        :dataInfo="dataInfo"
                        @changeType="changeType"
                        :indexType="indexType"
                        @chgDate="chgDate"
                        :searchObj="searchObj"
                        :unShowDate="false"
                        :schoolList="schoolList"
                        :showTableList="showTableList"
                        :schoolItem="schoolItem"
                        @chooseSchoolItem="chooseSchoolItem"></header-content>

        <table-content v-if="showTableList"
                       ref="tableContent"
                       :tableType="indexType"
                       :schoolItem="schoolItem"></table-content>
        <div>
          <dialog-factory ref="tuitionIncomeReportFactory"
                          :key="'tuitionIncomeReport'"
                          :routerName="'tuitionIncomeReport'"></dialog-factory>
          <dialog-factory-common ref="tuitionIncomeReportFactoryCommon"></dialog-factory-common>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import topReport from './top-report';
import tableContent from './table-content';
import headerContent from './header-content';
import { GetIncomeCountDate, GetHQClinetAcademicList } from '../../../../API/workbench';
import dialogFactory from '../../../common/dialog-factory';
export default {
  name: 'tuitionIncomeReport',
  data () {
    return {
      searchObj: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0))
      },
      schoolItem: {
        MainDemoName: '',
        OLAPKey: 0
      },
      schoolList: [],
      indexType: 1,
      schoolKey: 0,
      showTableList: false,
      dataInfo: {
        ActualColAmount: 0,	// 整型	结转金额
        ClassHourNum: 0, //	整型	结转课时金额
        ClassHourAmount: 0, //	整型	结转天数金额
        DayNum: 0, //	整型	结转课时数
        DayAmount: 0, //	整型	结转天数
        TuitionNum: 0, //	整型	结转异常金额
        TuitionAmount: 0, //
        ActualBackAmount: 0, //	整型	结转异常金额
        ActualBackNum: 0
      }
    };
  },
  components: {
    // topReport,
    tableContent,
    dialogFactory,
    headerContent
  },
  created () {
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'tuitionIncomeReport') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    this.$bus.on(this, 'openCommonCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'tuitionIncomeReport') {
        this.openCommonCustomDialogByFactoryDialog(dialogInfo);
      }
    });
    if (this.$store.getters.SaaSClientEditionKey == 5) {
      this.getSchoolList();
    } else {
      this.showTableList = true;
      this.getReportInfo();
    }
  },
  methods: {
    getSchoolList () {
      GetHQClinetAcademicList().then(result => {
        console.log('getReportInfo', result.data);
        this.schoolList = result.data;
        if (this.schoolList.length > 0) {
          this.schoolItem = this.schoolList[0];
          this.showTableList = true;
          this.getReportInfo();
        } else {
          layer.alert('没有校区可选择');
        }
      }).catch(error => {
        console.log('errorGetReportInfo', error);
      });
    },
    chooseSchoolItem (item) {
      this.schoolItem = item;
      this.getReportInfo();
      this.changeType(this.indexType);
    },
    chgDate (val, startTime, endTime) {
      this.seletedDate = val;
      this.searchObj.startTime = startTime;
      this.searchObj.endTime = endTime;
      this.getReportInfo();
      this.changeType(this.indexType);
      // this.getReportList();
    },
    // 切换列表
    changeType (type) {
      this.indexType = type;
      this.$refs.tableContent.initData(type, this.searchObj);
    },
    getReportInfo () {
      GetIncomeCountDate(this.searchObj, this.schoolItem.OLAPKey).then(result => {
        console.log('getReportInfo', result.data);
        this.dataInfo = result.data;
      }).catch(error => {
        console.log('errorGetReportInfo', error);
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      console.log(dialogInfo, 'tuitionIncomeReport');
      if (this.$refs.tuitionIncomeReportFactory) {
        this.$refs.tuitionIncomeReportFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    },
    openCommonCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.tuitionIncomeReportFactoryCommon) {
        this.$refs.tuitionIncomeReportFactoryCommon.openDialogPlus(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name || dialogInfo.title);
      }
    }
  }
};
</script>