<template>
  <div class="teacher_statistics_list">
    <div class="student_courses_img fl">
      <heads-img :imgUrl="item.XMLIcon"
                 :dataInfo="item"></heads-img>
    </div>
    <div class="teacher_statistics_list_text fl">
      <div class="human_text clearfix">
        <div class="name_text fl">
          {{item.MainDemoName}}
        </div>
        <div class="man fl"
             v-if="item.SexKey==2">
          男
        </div>
        <div class="woman fl"
             v-else-if="item.SexKey==3">
          女
        </div>
        <div class=""
             v-else>
        </div>
      </div>
      <div class="human_date clearfix">
        <span class="fl"
              v-if="item.IsPTJobKey==1">兼职</span>
        <span class="fl"
              v-else>全职</span>
        <span class="fr">入职于{{item.StartTime}}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: ['item'],
  computed: {
    // headImg () {  // 头像
    //   let pcDomainURLOld = 'http://' + this.$store.getters.CDNURL;
    //   if (!this.item.XMLIcon) {
    //     if (parseInt(this.item.SexKey) == 2) {
    //       return require('../../../../public/image/defult_photo_boy.png');
    //     } else {
    //       return require('../../../../public/image/defult_photo_gril.png');
    //     }
    //   }
    //   if (this.item && this.item.XMLIcon) {
    //     if (this.item.XMLIcon.indexOf('http') > -1) {
    //       return this.item.XMLIcon;
    //     } else if (this.item.XMLIcon && this.item.XMLIcon.indexOf('/') == -1) {
    //       return pcDomainURLOld + '/' + this.item.XMLIcon;
    //     } else {
    //       return pcDomainURLOld + this.item.XMLIcon;
    //     }
    //   }
    // }
  },
  methods: {
  }
};
</script>
