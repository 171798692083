import { render, staticRenderFns } from "./saas-client-details.vue?vue&type=template&id=5decbc55&scoped=true"
import script from "./saas-client-details.vue?vue&type=script&lang=js"
export * from "./saas-client-details.vue?vue&type=script&lang=js"
import style0 from "./saas-client-details.vue?vue&type=style&index=0&id=5decbc55&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5decbc55",
  null
  
)

export default component.exports