<template>
  <div class="mendian_backstage_summarizing_month">
    <div class="month_switch">
      <div class="month_switch_list"
           v-for="(item,key) in switchOptions"
           :key="key"
           :class="item.isActive?'opt':''"
           @click.stop="changeType(item)">
        {{item.name}}
      </div>

    </div>
    <div class="month_content">
      <div class="month_list">
        <span class="font_gray">开户</span>
        <span class="font_number">{{Number(reportInfo.CreateDateCount)}}</span>
      </div>
      <div class="month_list">
        <span class="font_gray">辅导</span>
        <span class="font_number">{{Number(reportInfo.TutorialDateCount)}}</span>
      </div>
      <div class="month_list">
        <span class="font_gray">留存</span>
        <span class="font_number">{{Number(reportInfo.KeepAliveCount)}}</span>
      </div>
      <div class="month_list">
        <span class="font_gray">留存率</span>
        <span class="font_number">{{Math.round(Number(reportInfo.KeepAliveRate)||0)}}</span>
        <span class="font_unit">%</span>
      </div>
    </div>
  </div>
</template>
<script>
import { SummarizeKeepAliveData } from '../../../../API/workbench';
export default {
  data () {
    return {
      searchType: 0,
      reportInfo: {
        CreateDateCount: 0,
        TutorialDateCount: 0,
        KeepAliveCount: 0,
        KeepAliveRate: 0
      },
      switchOptions: [
        {
          name: '本月',
          type: 0,
          isActive: true
        },
        {
          name: '上月',
          type: 1,
          isActive: false
        },
        {
          name: '本年',
          type: 2,
          isActive: false
        }
      ]
    };
  },
  created () {
    this.getSummarizeKeepAliveData();
  },
  mounted () {
    this.$bus.on(this, 'updateMendianOperationManagement', (classID, studentID) => {
      console.log('updateMendianOperationManagement');
      this.getSummarizeKeepAliveData();
    });
  },
  methods: {
    changeType (item) {
      this.switchOptions.forEach(o => {
        if (o.type == item.type) {
          o.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      this.searchType = item.type;
      this.getSummarizeKeepAliveData();
    },
    getSummarizeKeepAliveData () {
      let param = this.setSearchTime();
      SummarizeKeepAliveData(param).then(result => {
        console.log(result.data, 'SummarizeKeepAliveDatafroMonth');
        this.reportInfo = result.data;
      }).catch(error => {
        console.log(error, 'error');
      });
    },
    setSearchTime () {
      let param = {
        StartTime: '',
        EndTime: ''
      };
      if (this.searchType == 0) {
        param.StartTime = this.$utils.formatDatet(Date.new(), 'yyyy-MM') + '-01';
        param.EndTime = this.$utils.formatDateToLine(Date.new());
      } else if (this.searchType == 1) {
        let lastMonthTime = Date.new(Date.new().setMonth(Date.new().getMonth() - 1));
        let year = Date.new(lastMonthTime).getFullYear();
        let lastMonth = Date.new().getMonth();
        let lastDay = Date.new(year, lastMonth, 0).getDate();
    
        param.StartTime = this.$utils.formatDatet(lastMonthTime, 'yyyy-MM') + '-01';
        param.EndTime = this.$utils.formatDatet(lastMonthTime, 'yyyy-MM') + '-' + lastDay;
      } else {
        param.StartTime = Date.new().getFullYear() + '-01-01';
        param.EndTime = this.$utils.formatDateToLine(Date.new());
      }
      console.log(param, 'setSearchTime');
      return param;
    }
  }
};
</script>

