<template>
  <div class="mendian_info_box clearfix">
    <div class="mendian_info_logo fl"
         @click="doEditMdInfo">
      <img :src="headImg"
           :onerror="defaultImg"
           alt="../../../../../public/image/no_logo@2x.png" />
    </div>
    <div class="cw_mendian_info_text fl"
         @click="doEditMdInfo">
      <div class="mendian_info_name"
           :title="vMendianInfo.MainDemoName">{{vMendianInfo.MainDemoName}}</div>
      <div class="cw_mendian_info_ID">门店ID:
        <span class="font_black">{{vMendianInfo.SaaSClientNO}}</span>
      </div>
      <div class="mendian_edit_info_btn"
           v-if="vRoleKey"></div>
    </div>
    <div class="cw_mendian_info phone fl"
         @click="doEditMdInfo">{{vMendianInfo.Telephone}}</div>
    <div class="cw_mendian_info address fl"
         @click="doEditMdInfo">{{vMendianInfo.Address}}</div>
    <div @click.stop="">
      <flow-balance :dataInfo="vMendianInfo"></flow-balance>
    </div>
    <div class="mendian_info_general"
         @click="doEditMdInfo">课件版</div>

    <custom-dialog :title="'修改门店'"
                   class="new_class defult_dialog"
                   :visible.sync="isShowMDform"
                   :before-close="closeForm">
      <edit-mdInfo-form @close="closeForm"></edit-mdInfo-form>
    </custom-dialog>
  </div>
</template>
<script>
import editMdInfoForm from './edit-mdInfo-form';
import flowBalance from '../../../base-module/basis-setting-module/mendian-setting-from/flow-balance/index.vue';
export default {
  data () {
    return {
      isShowMDform: false
    };
  },
  props: {
    // mendianInfo: Object
  },
  components: {
    editMdInfoForm,
    flowBalance
  },
  created () {
    console.log(this.vMendianInfo, '门店信息');
  },
  computed: {
    vRoleKey () {
      return this.$store.getters.token ? Number(this.$store.getters.token.RoleKey) == 2 : false; // 2为校长；非则为老师
    },
    vMendianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    headImg () {
      if (!this.vMendianInfo.LogoPath) {
        return '';
      } else if (this.vMendianInfo.LogoPath.indexOf('http') > -1) {
        return this.vMendianInfo.LogoPath;
      } else {
        return this.$store.getters.CDNURL + this.vMendianInfo.LogoPath;
      }
    },
    // 默认显示logo
    defaultImg () {
      return (
        'this.src="' + require('../../../../../public/image/no_logo@2x.png') + '"'
      );
    }
  },
  methods: {
    doEditMdInfo (item) {
      if (this.vRoleKey) {
        this.isShowMDform = true;
      }
    },
    closeForm () {
      this.isShowMDform = false;
    }
  }
};
</script>
<style>
.mendian_info_box {
  padding-left: 6%;
}
.mendian_edit_info_btn {
  width: 20px;
  height: 20px;
  background: url(../../../../../public/cw-img/setting_icon.png) no-repeat right
    center;
  background-size: 18px;
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: 0px;
}
.cw_mendian_info_text {
  margin-left: 20px;
  width: 60%;
  position: relative;
  padding-bottom: 20px;
  min-height: 90px;
}
.cw_mendian_info_ID {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 6px;
  color: #999;
}
.cw_mendian_info {
  font-size: 14px;
  line-height: 16px;
  padding-left: 25px;
  color: #000;
  min-height: 25px;
  word-break: break-all;
  width: 100%;
}
.cw_mendian_info.phone {
  background: url(../../../../../public/cw-img/phone_icon.png) no-repeat left
    1px;
  background-size: 16px;
}
.cw_mendian_info.address {
  background: url(../../../../../public/cw-img/address_icon.png) no-repeat left
    1px;
  background-size: 16px;
  margin-bottom: 10px;
}
</style>

