<template>
  <div class="swiper-slide">
    <div class="swiper_combo">套餐</div>
    <div class="swiper_title">
      <div class="swiper_title_name">{{vTaoCanKeyValue}}</div>
      <div class="swiper_title_introduce">适用丨{{tickItem.ApplyCorName}}</div>
      <div class="swiper_title_course">
        {{vGenerateTime}}购买
        <span class="font_black">{{Number(tickItem.NumCount)}}</span>课时,剩余
        <span class="font_black">{{Number(tickItem.PeriodCount)}}</span>天
      </div>
    </div>
    <div class="swiper_conetnt">
      <div class="swiper_conetnt_list">
        <div class="swiper_conetnt_list_title">当前剩余</div>
        <div class="swiper_conetnt_list_number">{{Number(tickItem.CurrentCount)}}</div>
      </div>
      <div class="swiper_conetnt_list">
        <div class="swiper_conetnt_list_title">已报课</div>
        <div class="swiper_conetnt_list_number">{{Number(tickItem.HasBingCount)}}</div>
      </div>
      <div class="swiper_conetnt_list">
        <div class="swiper_conetnt_list_title">待报课</div>
        <div class="swiper_conetnt_list_number font_red">{{Number(tickItem.WaitForPlanCount)}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {};
  },
  props: ['tickItem'],
  computed: {
    vGenerateTime () {
      if (this.tickItem && this.tickItem.GenerateTime) {
        // this.tickItem.GenerateTime = this.tickItem.GenerateTime.replace(
        //   /-/g,
        //   '/'
        // );
        // return (
        //   Date.new(this.tickItem.GenerateTime).getMonth() +
        //   1 +
        //   '月' +
        //   Date.new(this.tickItem.GenerateTime).getDate() +
        //   '日'
        // );
        return this.tickItem.GenerateTime.substring(0, 10);
      }
    },
    vTaoCanKeyValue () {
      if (this.tickItem && Number(this.tickItem.TaoCanKey) > 0) {
        return this.tickItem.TaoCanKeyValue;
      } else {
        return '不使用';
      }
    }
  }
};
</script>