<template>
  <div>
    <!-- <div v-if="isShowAddBtn">
      <btn-add-select :addTitleName="addTitleName"
                      :addmoduleName="'register-customer'"
                      :addmoduleTitleName="'add'"
                      :addmoduleKey='48'
                      @doAddSuccessEvent="doAddSuccessEvent"></btn-add-select>
    </div> -->

    <div class="form_info_detail form_info_serach_box">
      <!-- 搜索选择层 -->
      <input-search-contain-btn v-model="SearchText"
                                :placeholder="'请输入字体名称'"
                                @onSearchEvent="searchTextSearch"></input-search-contain-btn>

      <div class="title_pre">
        <div class="title_pre_box"
             :class="titleChooice==-1?'title_pre_box_option':''"
             @click.stop="titleChooiceEvent(-1)">免费商用</div>
        <div class="title_pre_box"
             :class="titleChooice==1?'title_pre_box_option':''"
             @click.stop="titleChooiceEvent(1)">默认字体</div>
      </div>
      <!-- 搜索结果 -->
      <div class="search_result">
        <div class="search_result_content search_result_content_for_student"
             @scroll="scrollTOP">
          <div class="search_result_list"
               @scroll="scrollTOP">
            <!-- active 选中当前 -->
            <font-item v-for="item in vFontsList"
                       :key="item.id"
                       :fontItem="item"
                       :type="titleChooice"
                       @selectedItem="selectedItem"></font-item>
            <!-- <div>name</div> -->
            <div v-if="loading"
                 class="students_loading all_list_bottom">
              <span class="el-icon-loading"></span>
              <span class="loading_style">加载中...</span>

            </div>
            <div v-else-if="vFontsList.length == 0">
              <div class="has_arrange_course">
                <div class="has_arrange_course_content"
                     style="padding:0">
                  <div class="has_arrange_course_list ">
                    <div class=" has_arrange_course_nodata single_shadow ">
                      <span>亲，当前没有任何数据哦~</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="noMore"
                 class="all_list_bottom">
              <span class="complete">已显示全部</span>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>

</template>
<script>

import fontItem from './font-item.vue';
import { GetFontTemplateList } from '../../../../API/workbench';
export default {
  name: 'singleSelectionList',
  data () {
    return {
      // 测试学生列表 数据
      fontsList: [],
      SearchText: '',
      pageSize: 20,     // 每次搜索人数.
      pageIndex: 0,     // 每次查询页数
      noMore: false,
      loading: false,
      titleChooice: -1,
      StatusKey: 1,
      isSpec: -1,
      isRequest: false
    };
  },
  components: {
    fontItem
  },
  computed: {
    vFontsList () {
      let newArr = [];
      this.fontsList.forEach(o => {
        // if (this.fontInfo && this.fontInfo.OLAPKey == o.OLAPKey) {
        //   o.isActive = true;
        // } else {
        //   o.isActive = false;
        // }
        newArr.push(o);
      });
      return newArr;
    }
  },
  watch: {
    SearchText () {
      // console.log(this.SearchText, 'SearchText');
      if (this.SearchText == '') {
        this.fontsList.length = 0;
        this.pageSize = 20;
        this.pageIndex = 0;
        this.noMore = false;
        this.getFontsList();
      }
    }
  },
  created () {
    this.getFontsList();
  },
  methods: {
    // 新增回调
    doAddSuccessEvent (result) {
      console.log(result, '新增回调');
      this.$emit('selectedItem', result);
    },
    // 滚动加载事件.
    scrollTOP (e) {
      let ETarget = e.target;
      let HeightView = ETarget.scrollHeight - ETarget.clientHeight;
      if (parseInt(ETarget.scrollTop) == parseInt(HeightView) && !this.noMore) {
        this.noMore = false;
        this.getFontsList();
      }
    },
    searchTextSearch () {
      this.SearchText = this.SearchText.replace(/ /g, '');
      this.pageSize = 20;
      this.pageIndex = 0;
      this.noMore = false;
      this.fontsList = [];
      this.getFontsList();
    },
    getFontsList () {
      if (!this.isRequest) {
        this.isRequest = true;
        if (!this.noMore) {
          this.loading = true;
        }
        GetFontTemplateList(this.pageIndex, this.pageSize, this.SearchText, '', '', this.StatusKey, this.titleChooice).then(result => {
          if (this.pageIndex == 0) {
            this.fontsList = [];
          }
          result.data.PageDataList.forEach(o => {
            this.fontsList.push(o);
          });
          if (result.data.PageDataList.length < this.pageSize) {
            this.noMore = true;
            this.loading = false;
          }
          this.pageIndex++;
          this.isRequest = false;
        }).catch(error => {
          this.loading = false;
          this.isRequest = false;
          console.log(error);
        });
      }
    },
    selectedItem (item) {
      this.fontsList.forEach(obj => {
        obj.isActive = false;
        if (obj.OLAPKey == item.OLAPKey) {
          obj.isActive = true;
        }
      });
      this.verifyStudent(item);
      this.scrollBackTop();
    },
    // 验证学生
    verifyStudent (item) {
      console.log(item, 'item');
      if (item.IsConfirmKey == 0 && this.switchItem !== 1) { // switchItem 这里可做开关，1则关闭验证提示
        layer.alert('该学生待验证确认，请在学生档案中进行确认操作。');
      } else {
        this.SearchText = '';
        this.$emit('selectedItem', item);
      }
    },
    scrollBackTop () {
      // document.getElementsByClassName('search_result_content_for_student')[0].scrollTop = 0;
      // if (document.getElementsByClassName('search_result_content_for_student')[1]) {
      //   document.getElementsByClassName('search_result_content_for_student')[1].scrollTop = 0;
      // }
      document.getElementsByClassName('search_result_list')[0].scrollTop = 0;
      if (document.getElementsByClassName('search_result_list')[1]) {
        document.getElementsByClassName('search_result_list')[1].scrollTop = 0;
      }
    },
    clearSearText () {
      this.SearchText = '';
    },
    titleChooiceEvent (type) {
      this.titleChooice = type;

      this.pageSize = 20;
      this.pageIndex = 0;
      this.noMore = false;
      this.fontsList = [];
      this.getFontsList();
    }
  }

};
</script>
<style scoped>
.title_pre {
  cursor: pointer;
  width: 100%;
  height: 60px;
}
.title_pre_box {
  width: 50%;
  float: left;
  align-items: center;
  text-align: center;
  height: 60px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 60px;
  color: #666666;
  border-bottom: 1px solid #ececec;
}
.title_pre_box_option {
  font-style: normal;
  font-weight: 700;
  color: #333333;
  border-bottom: 4px solid #3498db;
}
</style>

