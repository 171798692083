var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course_list_item"},[_c('rulesClassItem',{attrs:{"rulesClassItem":_vm.item},on:{"showPupUp":_vm.showGradeClassPupUp}}),_c('div',{staticClass:"course_list_right"},[_c('draggable',_vm._b({attrs:{"options":{group:{name: 'site',pull:'clone'},sort: false},"animation":"100","dragClass":"dragClass","ghostClass":"ghostClass","chosenClass":"chosenClass","filter":".forbid","handle":".mover","move":_vm.onMove},on:{"start":_vm.onStart,"end":_vm.onEnd,"add":_vm.onAdd},model:{value:(_vm.vCombineStudentList),callback:function ($$v) {_vm.vCombineStudentList=$$v},expression:"vCombineStudentList"}},'draggable',{ pull:'clone',sort: false, store: false },false),[_c('transition-group',{staticClass:"draggable",attrs:{"id":_vm.item.StructuredRuleKey+'_StructuredRuleKey'}},[_vm._l((_vm.vCombineStudentList),function(item){return _c('studentItem',{key:item.StudentKey,class:'mover',attrs:{"stuItem":item,"isTemporaryStu":_vm.isTemporaryStu,"isDrag":_vm.isDrag},on:{"setPopUpPosition":_vm.setPopUpPositionAfter,"mouseOverEl":_vm.clearTimeOut,"selectedStudent":_vm.selectedStudentAfter}})}),_c('function-btn',{key:'新增',attrs:{"item":_vm.addbtn,"className":'list_add forbid'},on:{"clickItem":_vm.showAddStudentDialog}})],2)],1),(
        _vm.copyData.StructuredRuleKey != _vm.item.StructuredRuleKey &&
        _vm.copyData.copyStudentList.length > 0 &&
        !this.vIsSyncArranged
      )?_c('div',{staticClass:"list_add no_bg",on:{"click":function($event){$event.stopPropagation();return _vm.copyStudentInClass.apply(null, arguments)}}},[_vm._v(" 粘贴学生 ")]):_vm._e(),_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowDialog),expression:"isShowDialog"}],staticClass:"course_student_attendance_operation module_content_menuList active stuOptionPupUp",style:({
          left: _vm.pupupPositionLeft + 'px',
          top: _vm.pupupPositionTop + 'px',
        })},[_c('div',{staticClass:"top_bg"}),_c('drop-down-box',{attrs:{"optionJsonList":_vm.studentPopupMenu},on:{"clickOptionForAlertDialog":_vm.doAfterClickStudentPopup}})],1)]),_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowCourseOptionDialog),expression:"isShowCourseOptionDialog"}],staticClass:"course_student_attendance_operation module_content_menuList active courseOptionPupUp",style:({
          left: _vm.pupupPositionLeft + 'px',
          top: _vm.pupupPositionTop + 'px',
        })},[_c('div',{staticClass:"top_bg"}),_c('drop-down-box',{attrs:{"optionJsonList":_vm.rolePopupMenu},on:{"clickOptionForAlertDialog":_vm.doAfterClickRolePopup}})],1)]),_c('custom-dialog',{staticClass:"student_info_dialog defult_dialog",attrs:{"title":"调整上课时间","visible":_vm.isShowEditClassTimeDialog,"before-close":_vm.closeEditClassTimeDialog},on:{"update:visible":function($event){_vm.isShowEditClassTimeDialog=$event}}},[_c('edit-class-time',{attrs:{"circleCourseInfo":_vm.seletedRuleItem,"isShowCircleCourseItem":true},on:{"selectedItem":_vm.closeEditClassTimeDialog,"closeDialog":_vm.closeEditClassTimeDialog}})],1),_c('custom-dialog',{staticClass:"student_info_dialog defult_dialog",attrs:{"title":"调整老师","visible":_vm.isShowEditTeacherDialog,"before-close":_vm.closeEditTeacherDialog},on:{"update:visible":function($event){_vm.isShowEditTeacherDialog=$event}}},[_c('edit-teacher',{attrs:{"circleCourseInfo":_vm.seletedRuleItem,"isShowCircleCourseItem":true},on:{"closeDilog":_vm.closeEditTeacherDialog,"selectedItem":_vm.closeEditTeacherDialog}})],1),_c('custom-dialog',{staticClass:"progress_max progress",attrs:{"width":"300px","visible":_vm.showProgress},on:{"update:visible":function($event){_vm.showProgress=$event}}},[_c('div',{staticClass:"progress_title"},[_vm._v(" 正在粘贴学生 ，"+_vm._s(_vm.runCount)+"/"+_vm._s(_vm.totolPeoCount)+"。 ")]),_c('div',{staticClass:"progress_box"},[_c('el-progress',{attrs:{"type":"circle","percentage":_vm.progressStatus}})],1)]),_c('div',[_c('student-for',{ref:"studentsList",attrs:{"formTitle":_vm.studentTitle,"classGradeID":_vm.item.GradeClassKey,"selectCourseID":_vm.item.CourseNameKey},on:{"onAddStudent":_vm.addToCircleCourseForStudent,"onDeleteStudent":_vm.deleteClick}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }