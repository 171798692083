<template>
  <div class="">
    <div>
      <div class="from_title">
        <span>家长推广 </span>
        <span class="font_gray">线上报名后计算提成</span>
      </div>
      <!-- 家长分销用券显示 -->
      <ticket-award v-if="settingInfo.parentsPromotionSetting.useCouponMoney.isOpen&&parentUseTicket"
                    :title="'兑现奖励'"
                    :settingInfo="settingInfo"
                    :dataInfo="parentUseTicket"
                    :courseTreeList="courseTreeList"
                    @changebackStatus="changeUseCouponbackStatus"></ticket-award>
      <!-- 家长分销售券显示 -->
      <ticket-award v-if="settingInfo.parentsPromotionSetting.saleCouponMoney.isOpen&&parentSellTicket"
                    :title="'兑现奖励'"
                    :settingInfo="settingInfo"
                    :dataInfo="parentSellTicket"
                    :courseTreeList="courseTreeList"
                    @changebackStatus="changeSaleCouponbackStatus"></ticket-award>
      <!-- 家长浏览推广 -->
      <div class="from_list"
           v-if="settingInfo.parentsPromotionSetting.browsePromotion.isOpen&&parentBrowse">
        <div class="from_list_title">
          浏览推广
        </div>
        <div class="from_list_content">
          <div class="from_list_content">
            <form-content-input :formTitle="'目标人数'"
                                v-model="parentBrowse.targetnum"
                                :tipsTitle="''"
                                :required="false"
                                :unintTitle="'人'"
                                :inputClass="'from_list_input_three'"
                                :titleClass="'font_black'"></form-content-input>
            <form-content-input :formTitle="'奖励'"
                                v-model="parentBrowse.fangift_name"
                                :tipsTitle="''"
                                :required="false"
                                :unintTitle="'个/人'"
                                :inputClass="'from_list_input_three'"
                                :titleClass="'font_black'"></form-content-input>
          </div>
        </div>
      </div>
    </div>
    <step-btn :showPre="true"
              @preStep="preStep"
              @nextStep="nextStep">
    </step-btn>

  </div>
</template>
<script>
import stepBtn from './step-btn';
import formContentInput from './common/form-content-input';
import formInput from './common/form-input';
import ticketAward from './sales-setting-component/ticket-award';
var newObj = {
  'pid': 0, // '优惠券id 新增时为0',
  'strategy_type': '', // '策略类型 1 家长推广 2家长分销 3员工提成',
  'strategy_sub_type': '', // '策略维度1用券 2售券 3浏览',
  'fanxian_amount': '', // '返现金额',
  'fanxian_type': '', // '返现方式1线下返现（默认） 2微信红包-自动 3微信红包-手动',
  'fanhour_num': '', // '返课时数',
  'fanhour_keys': '', // '返课时适用范围keys 0不限 其他1，2，3类似表示，课名课类ID ',
  'fanhour_keyvalues': '', // '返课时适用范围keyvalues 0 不限 其他 A,B,C 类似表示，课名课类名称',
  'fangift_name': '', // '返礼品名称 空表示，不送',
  'IsLink': '', // '是否复链0否 1是',
  'targetnum': '', // '达到目标才送 浏览时 指浏览人数，用券，售券暂不生效'
  'description': ''   // 补充说明
};
export default {
  data () {
    return {
      radio: false,
      parentUseTicket: null,      // 家长用券
      parentSellTicket: null,      // 家长售券
      parentBrowse: null,       // 家长浏览
      useCouponbackStatus: null,      // 用券返现状态   0 返现, 1返课时, 2返礼品
      saleCouponbackStatus: null      // 售券返现状态   0 返现, 1返课时, 2返礼品
    };
  },
  components: {
    stepBtn, formInput, formContentInput, ticketAward
  },
  props: {
    dataInfo: Object,
    CouponInfo: Object,
    menuItem: Object,
    settingInfo: Object,
    courseTreeList: Array

  },
  created () {
    this.initData();
  },
  mounted () {
  },
  watch: {
    'CouponInfo.strategyList': {
      handler (c, o) {
        if (c && c.length > 0) {
          this.initData();
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    vMenDianInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    inShowOnLine () {
      return this.vMenDianInfo.WktInfo.is_open_redpaper && !this.vMenDianInfo.IsCommonTrusteeshipKey;
      // {{mendianInfo.WktInfo && mendianInfo.WktInfo.is_open_redpaper ?'开启':'关闭'}}
    }
  },
  methods: {
    initData () {
      // 开启家长推广
      console.log(this.CouponInfo.strategyList, '家长推广设置', this.settingInfo.parentsPromotionSetting);
      if (this.settingInfo.parentsPromotionSetting.isOpen) {
        this.CouponInfo.promotion_parent_open = 1;
        // 开启售券提成
        if (this.settingInfo.parentsPromotionSetting.saleCouponMoney.isOpen) {
          let locaItem = this.CouponInfo.strategyList.find(o => {
            return o.strategy_type == 1 && o.strategy_sub_type == 2;
          });
          if (locaItem) {
            this.parentSellTicket = locaItem;
          } else {
            this.parentSellTicket = this.$utils.parseJson(newObj);
            this.parentSellTicket.strategy_type = 1;
            this.parentSellTicket.strategy_sub_type = 2;
          }
        }
        // 开启用券提成
        if (this.settingInfo.parentsPromotionSetting.useCouponMoney.isOpen) {
          let locaItem = this.CouponInfo.strategyList.find(o => {
            return o.strategy_type == 1 && o.strategy_sub_type == 1;
          });
          if (locaItem) {
            this.parentUseTicket = locaItem;
          } else {
            this.parentUseTicket = this.$utils.parseJson(newObj);
            this.parentUseTicket.strategy_type = 1;
            this.parentUseTicket.strategy_sub_type = 1;
          }
        }
        // 开启浏览奖励
        if (this.settingInfo.parentsPromotionSetting.browsePromotion.isOpen) {
          console.log('开启浏览奖励', this.CouponInfo.strategyList);
          let locaItem = this.CouponInfo.strategyList.find(o => {
            return o.strategy_type == 1 && o.strategy_sub_type == 3;
          });
          if (locaItem) {
            this.parentBrowse = locaItem;
          } else {
            this.parentBrowse = this.$utils.parseJson(newObj);
            this.parentBrowse.strategy_type = 1;
            this.parentBrowse.strategy_sub_type = 3;
          }
        }
      } else {
        this.CouponInfo.promotion_parent_open = 0;
      }
    },
    disposalArray (locaIndex, data) {
      if (locaIndex >= 0) {
        this.CouponInfo.strategyList.splice(locaIndex, 1, data);
      } else {
        this.CouponInfo.strategyList.push(data);
      }
    },
    // 整理CouponInfo.strategyList
    disposalData () {
      // parentUseTicket: null,      // 家长用券
      // parentSellTicket: null,      // 家长售券
      // parentBrowse: null,       // 家长浏览
      if (this.parentUseTicket) {
        let locaIndex = this.CouponInfo.strategyList.findIndex(o => {
          return o.strategy_type == 1 && o.strategy_sub_type == 1;
        });
        this.disposalArray(locaIndex, this.parentUseTicket);
      }
      if (this.parentSellTicket) {
        let locaIndex = this.CouponInfo.strategyList.findIndex(o => {
          return o.strategy_type == 1 && o.strategy_sub_type == 2;
        });
        this.disposalArray(locaIndex, this.parentSellTicket);
      }
      if (this.parentBrowse) {
        let locaIndex = this.CouponInfo.strategyList.findIndex(o => {
          return o.strategy_type == 1 && o.strategy_sub_type == 3;
        });
        this.disposalArray(locaIndex, this.parentBrowse);
      }
      if (!this.inShowOnLine && this.parentUseTicket) {
        this.parentUseTicket.fanxian_type = 1;
      }
      if (!this.inShowOnLine && this.parentSellTicket) {
        this.parentSellTicket.fanxian_type = 1;
      }
    },
    preStep () {
      this.disposalData();
      this.$emit('preStep', this.menuItem);
    },
    nextStep () {
      if (!this.verifyData()) {
        return false;
      }
      this.disposalData();
      this.$emit('nextStep', this.menuItem);
    },
    changeUseCouponbackStatus (val) {
      this.useCouponbackStatus = val;
    },
    changeSaleCouponbackStatus (val) {
      this.saleCouponbackStatus = val;
    },
    verifyData () {
      let isComfire = true;
      if (this.settingInfo.parentsPromotionSetting.useCouponMoney.isOpen) {
        if (this.useCouponbackStatus == 0) {
          if (Number(this.parentUseTicket.fanxian_amount) <= 0) {
            isComfire = false;
            layer.alert('请输入返现金额');
            return isComfire;
          }
        } else if (this.useCouponbackStatus == 1) {
          if (Number(this.parentUseTicket.fanhour_num) <= 0) {
            isComfire = false;
            layer.alert('请输入赠送课时');
            return isComfire;
          }
          if (!this.parentUseTicket.fanhour_keyvalues) {
            isComfire = false;
            layer.alert('请选择适用课程');
            return isComfire;
          }
        } else if (this.useCouponbackStatus == 2) {
          if (!this.parentUseTicket.fangift_name) {
            isComfire = false;
            layer.alert('请输入赠送奖品');
            return isComfire;
          }
        } else {
          isComfire = false;
          layer.alert('请选择奖励类型');
          return isComfire;
        }
      }
      if (this.settingInfo.parentsPromotionSetting.saleCouponMoney.isOpen) {
        if (this.saleCouponbackStatus == 0) {
          if (Number(this.parentSellTicket.fanxian_amount) <= 0) {
            isComfire = false;
            layer.alert('请输入返现金额');
            return isComfire;
          }
        } else if (this.saleCouponbackStatus == 1) {
          if (Number(this.parentSellTicket.fanhour_num) <= 0) {
            isComfire = false;
            layer.alert('请输入赠送课时');
            return isComfire;
          }
          if (!this.parentSellTicket.fanhour_keyvalues) {
            isComfire = false;
            layer.alert('请选择适用课程');
            return isComfire;
          }
        } else if (this.saleCouponbackStatus == 2) {
          if (!this.parentSellTicket.fangift_name) {
            isComfire = false;
            layer.alert('请输入赠送奖品');
            return isComfire;
          }
        } else {
          isComfire = false;
          layer.alert('请选择奖励类型');
          return isComfire;
        }
      }
      return isComfire;
    }
  }
};
</script>
