<template>
  <!--ShowTypeKey-3:复选 -->
  <div class="form_list_content"
       :style="vFieldsWidth">
    <div class="form_list_checkbox">
      <div class="checkbox_list" :class="{opt:item.isOpt}" @click="checkClick">
        <div class="flex">
          <div class="form_list_name">
            <span class="text_box">{{item.FieldsThat}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    categoryItem: Object, // 类目项
    item: Object, // 字段项
    rowWidth: Number // 总行宽
  },
  created () {
    // console.log('复选:', this.categoryItem, this.FieldsItem);
  },
  computed: {
    vFieldsWidth () {
      let FWh = 0;
      if (Number(this.item.Length) > FWh) {
        FWh = Number(this.rowWidth) / Number(this.item.Length);
        return 'width:' + FWh + 'px';
      } else {
        return '';
      }
    }
  },
  methods: {
    checkClick () {
      this.categoryItem.FormFields.forEach(o => {
        if (o.OLAPKey == this.item.OLAPKey) {
          o.isOpt = !o.isOpt;
          o.DefaultValue = (o.isOpt ? 1 : 0);
        }
      });
    }
  }
};
</script>

<style>
</style>