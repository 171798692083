<template>
  <div>
    <div class="student_courses_name"
         style="padding:0 20px">
      <div class="form-input  form_info_line form_info_edit no_border_bottom">
        <div class="student_courses_name_title">
          <span>{{formTitle}}:</span>
        </div>
        <div class="form_info_value form_info_select">
          <div class="form_info_value_input">
            <input type="text"
                   readonly
                   v-model="dataInfo.selectTypeName"
                   placeholder="必选"
                   @click="doSelectedDataListClick">
          </div>
        </div>
      </div>
    </div>
    <div class="student_courses_project"
         v-if="vIsSysVersionTY">
      <div class="project_lable">
        <div class="project_lable_content clearfix">
          <div class="project_lable_list"
               :style="formTitleKey=='BanJi'?'width:22%':'width:25%'">
            <div class="font_gray">当前报课</div>
            <div>
              <span class="font_number">{{$utils.mAmountType(collectSummaryInfo.Sum_CurrentCount||0,1)}}</span>
            </div>
          </div>
          <div class="project_lable_list"
               :style="formTitleKey=='BanJi'?'width:22%':'width:25%'">
            <div class="font_gray">当前排课</div>
            <!-- 超排 -->
            <div>
              <span class="font_number">{{$utils.mAmountType(collectSummaryInfo.Sum_ArrangedCount||0,1)}}</span>
              <span class="chao_pai"
                    v-if="Number(collectSummaryInfo.Sum_ArrangedCount)>Number(collectSummaryInfo.Sum_CurrentCount)">超排</span>
            </div>
            <div class="attendant_course_arrangin"
                 v-if="formTitleKey=='BanJi'&&Number(collectSummaryInfo.ArrangeCount)>0">跟班排课{{$utils.mAmountType(collectSummaryInfo.ArrangeCount,1)}}</div>
          </div>
          <div class="project_lable_list"
               :style="formTitleKey=='BanJi'?'width:22%':'width:25%'">
            <div class="font_gray">待排课时</div>
            <div>
              <span class="font_blue font_number">{{$utils.mAmountType(collectSummaryInfo.Sum_ToBeArranged||0,1)}}</span>
            </div>
          </div>
          <div class="project_lable_list"
               :style="formTitleKey=='BanJi'?'border-right:0;width:15%':'width:25%'">
            <div class="font_gray">待报课时</div>
            <div>
              <span class="font_red font_number">{{$utils.mAmountType(collectSummaryInfo.Sum_ToBePlaned||0,1)}}</span>
            </div>
          </div>
          <div v-if="formTitleKey=='BanJi'"
               class="circulation_Addclass_But btn_hover_bg_blue"
               @click.stop="classOperateButton">{{vWhetherStudentInTheClass}}</div>
        </div>
      </div>
    </div>
    <!-- 选择课名/班级列表 -->
    <div>
      <custom-dialog :title="formTitle"
                     class="student_arrange_dialog defult_dialog no_hidden"
                     :visible.sync="isPopUpShow"
                     :before-close="close">
        <single-selection-list :formTitle="formTitle"
                               :isAddData="{}"
                               :selectedKey="dataInfo.selectTypeKey"
                               :dataSourceList="singleArrangeList"
                               :required="true"
                               @onSelectedItem="doSelectedCourseOrClassName"></single-selection-list>
      </custom-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 操作项
      dataInfo: {
        selectTypeKey: 0, // 显示的高亮项ID
        selectTypeName: ''// 显示的高亮项ID
      },
      isPopUpShow: false
    };
  },
  props: {
    formTitle: String,
    formTitleKey: String,
    selectedData: Object, // 班级或者课程项
    singleArrangeList: Array, // 列表
    collectSummaryInfo: Object// 汇总信息
  },
  created () {
    console.log(this.selectedData, '选择数据1231231');

    this.dataInfo.selectTypeKey = this.selectedData ? this.selectedData.OLAPKey : 0;
    if (this.formTitleKey == 'BanJi') {
      this.dataInfo.selectTypeName = this.selectedData ? this.selectedData.MainDemoName + ' (' + Number(this.selectedData.CurrentPeoCount) + '人)' : '';
    } else {
      this.dataInfo.selectTypeName = this.selectedData ? this.selectedData.MainDemoName : '';
    }
  },
  components: {

  },
  watch: {
    'selectedData.CurrentPeoCount': {
      handler (n, o) {
        console.log(n, o, '班内人数');
        if (n != o && this.formTitleKey == 'BanJi') {
          this.dataInfo.selectTypeName = this.selectedData ? this.selectedData.MainDemoName + ' (' + Number(n) + '人)' : '';
        }
      },
      deep: true
    }
  },
  computed: {
    vIsSysVersionTY () {
      return !this.$store.getters.isSysVersionTY;
    },
    // 判断该学生是否进班
    vWhetherStudentInTheClass () {
      if (this.collectSummaryInfo.IsInGrade == 1) {
        return '退班';
      } else {
        return '进班';
      }
    }
  },
  methods: {
    close () {
      this.isPopUpShow = false;
    },
    // 课名/班级列表显示
    doSelectedDataListClick () {
      this.isPopUpShow = true;
    },
    closeDialog () {
      this.isPopUpShow = false;
    },
    // 课名/班级列表显示 或者
    doSelectedCourseOrClassName (item) {
      console.log(item, '选择课名/班级列表');
      this.$emit('selectedCourseOrClassName', item);
      this.dataInfo.selectTypeKey = item.OLAPKey;
      if (this.formTitleKey == 'BanJi') {
        this.dataInfo.selectTypeName = item.MainDemoName + ' (' + Number(item.CurrentPeoCount) + '人)';
      } else {
        this.dataInfo.selectTypeName = item.MainDemoName;
      }

      this.isPopUpShow = false;
    },
    // 是否进班
    classOperateButton () {
      console.log(this.collectSummaryInfo, 'this.collectSummaryInfo');
      layer.confirm('请确认是否' + this.vWhetherStudentInTheClass + ' ？', {
        btn: [
          {
            name: '取消',
            callBack: () => {
              layer.close();
              return false;
            }
          },
          {
            name: '确认',
            callBack: () => {
              layer.close();
              this.$emit('classOperateButton', this.dataInfo, this.collectSummaryInfo.IsInGrade == 1);
            }
          }
        ]
      });
    }
  }
};
</script>

<style>
.chao_pai {
  color: #ff6c67;
  font-size: 0.2rem;
  width: 38px;
  height: 19px;
  border: 1px solid #ff6c67;
  border-radius: 4px;
}
</style>