 <template>
  <div class="bg_gray">
    <div class="quick_sign_up_term_validity">
      <div class="form_info_edit form_info_list box_shadow">

        <input-date v-model="startPeriod"
                    v-if="editType != 'course' && editType == 'year' || !editType"
                    :elementId="'startPeriodTime'"
                    :required="true"
                    :formTitle="'生效日期'"
                    :placeholder="'请选择'"
                    :isShowClickTips="true"
                    :cliclTipsContent="'只能抵扣生效日期范围内的课消！'"
                    @changeDate="doAfterChangeStartPeriodTime($event)"></input-date>

        <input-number v-if="!editType"
                      class="no_padding"
                      :btnList="signDataTypes"
                      :min="0"
                      :formTitle="'数 量'"
                      :value="numberCount"
                      :decimalPlaces="0"
                      :required="true"
                      @inputChange="changeNumberCount"></input-number>
        <input-select v-if="!editType"
                      :dataList="unitSourceData"
                      :formTitle="'单 位'"
                      :id="'key'"
                      :name="'value'"
                      v-model="vSelectedUnitType"
                      :selectedInfo="vSelectedUnitType"
                      :required="true"
                      @selectedItem="doAfterUnitType"></input-select>

        <input-date v-model="startPeriod"
                    v-if="(editType == 'course' && editType != 'year') || !editType"
                    :elementId="'startPeriod'"
                    class="no_border_bottom"
                    :required="isRequrid"
                    :formTitle="'生效日期'"
                    :placeholder="'请选择'"
                    :isShowClickTips="true"
                    :cliclTipsContent="'只能抵扣生效日期范围内的课消！'"
                    @changeDate="doAfterChangeStartPeriodTime($event)"></input-date>

        <input-date v-if="(editType == 'course' && editType != 'year') || !editType"
                    v-model="endPeriod"
                    :elementId="'endPeriodTime'"
                    class="no_border_bottom"
                    :required="true"
                    :formTitle="'失效日期'"
                    :placeholder="'请选择'"
                    @changeDate="doAfterChangeEndPeriodTime($event)"></input-date>
      </div>
      <div>
        <button-info v-on:buttonClickEvent="confirValidityPeriod"
                     v-on:closeDialog="closeDialog"></button-info>
      </div>
    </div>
    <div class="home home_panle"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      startPeriod: null, // 绑定开始周期
      endPeriod: this.$utils.formatDateToLine(
        Date.new(
          Date.new().getFullYear() + 1,
          Date.new().getMonth(),
          Date.new().getDate() - 1
        )
      ), // 绑定结束周期
      signDataTypes: [{
        key: 1,
        value: '1个月',
        unitName: '月',
        unitKey: 3,
        count: 1,
        isSelection: true
      },
      {
        key: 2,
        value: '3个月',
        unitName: '月',
        unitKey: 3,
        count: 3,
        isSelection: false
      },
      {
        key: 3,
        value: '6个月',
        unitName: '月',
        unitKey: 3,
        count: 6,
        isSelection: false
      },
      {
        key: 4,
        value: '1年',
        unitName: '年',
        unitKey: 2,
        count: 1,
        isSelection: false
      },
      {
        key: 5,
        value: '3年',
        unitName: '年',
        unitKey: 2,
        count: 3,
        isSelection: false
      }
      ],
      unitSourceData: [
        {
          key: 2,
          value: '年'
        },
        {
          key: 3,
          value: '月'
        },
        {
          key: 4,
          value: '日'
        }
      ],
      selectedUnitType: {
        key: 2,
        value: '年'
      },
      numberCount: 1
    };
  },
  props: ['validityPeriod', 'periodCount', 'unitKey', 'editType', 'isRequrid'],
  created () {
    this.initValidityPeriod();
    console.log(this.validityPeriod, '1');
    console.log(this.periodCount, '2');
    console.log(this.unitKey, '3');

    if (this.editType != 'course' && (this.editType == 'year' || !this.editType)) {
      this.startPeriod = this.$utils.formatDateToLine(Date.new());
    }
  },
  methods: {
    // 传递按钮事件
    confirValidityPeriod () {
      let curDate = Date.new();
      if ((this.startPeriod == '' && this.isRequrid) || (this.editType != 'year' && this.endPeriod == '') || (this
        .editType == 'year' && this
          .startPeriod == '')) {
        layer.alert('请检查是否完善日期');
      } else {
        this.$emit(
          'confirmPeriodOfValidity',
          this.startPeriod,
          this.endPeriod,
          this.numberCount,
          this.selectedUnitType
        );
      }
    },
    // 点击取消
    closeDialog () {
      this.$emit('closeValidityPeriod');
      if (this.validityPeriod != '') {
        this.initValidityPeriod();
      } else {
        this.startPeriod = this.$utils.formatDateToLine(Date.new()); // 绑定开始周期
        this.endPeriod = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear() + 1, Date.new().getMonth(), Date.new().getDate() - 1));
        this.selectedUnitType.key = this.unitKey;
        if (this.unitKey == 2) {
          this.selectedUnitType.value = '年';
        } else if (this.unitKey == 3) {
          this.selectedUnitType.value = '月';
        } else if (this.unitKey == 4) {
          this.selectedUnitType.value = '日';
        }
        this.numberCount = 1;
      }
    },
    initValidityPeriod () {
      this.numberCount = Number(this.periodCount);
      if (this.validityPeriod) {
        let qstarttime = this.validityPeriod
          .split('~')[0]
          .replace(/\./g, '-');
        if (qstarttime == 'null') {
          this.startPeriod = '';
        } else {
          this.startPeriod = qstarttime;
        }
        console.log('qstarttime', qstarttime);
        this.endPeriod = this.validityPeriod
          .split('~')[1]
          .replace(/\./g, '-')
          .split('\t')[0];
      }
      this.selectedUnitType.key = this.unitKey;
      if (this.unitKey == 2) {
        this.selectedUnitType.value = '年';
      } else if (this.unitKey == 3) {
        this.selectedUnitType.value = '月';
      } else if (this.unitKey == 4) {
        this.selectedUnitType.value = '日';
      }
      this.signDataTypes.forEach(o => {
        if (this.periodCount == o.count && this.unitKey == o.unitKey) {
          o.isSelection = true;
        } else {
          o.isSelection = false;
        }
      });
    },
    back () {
      this.closeDialog();
    },
    // 改变数量
    changeNumberCount (item) {
      console.log('改变数量', item);
      this.numberCount = item.count;
      this.selectedUnitType.key = item.unitKey;
      this.selectedUnitType.value = item.unitName;
      this.caculateEndDate();
    },
    // 改变单位
    doAfterUnitType (item) {
      this.selectedUnitType.key = item.key;
      this.selectedUnitType.value = item.value;
      this.caculateEndDate();
    },
    // 改变起始日期
    doAfterChangeStartPeriodTime (data) {
      this.staticTipsShow = true
      console.log('改变起始日期');
      this.signDataTypes.forEach(o => { o.isSelection = false; });
      this.startPeriod = data;
      this.selectedUnitType.key = 4;
      this.selectedUnitType.value = '日';
      this.numberCount = this.$utils.DateDiffDay(this.startPeriod, this.endPeriod) + 1;
    },
    // 重新计算结束日期
    caculateEndDate () {
      let startDate = '';
      if (this.startPeriod && this.startPeriod != '') {
        startDate = Date.new(this.startPeriod);
      } else {
        startDate = Date.new();
      }
      console.log('startDate', startDate);
      if (this.selectedUnitType.key == 2) {
        this.endPeriod = Date.new(
          startDate.getFullYear() + this.numberCount,
          startDate.getMonth(),
          startDate.getDate() - 1
        );
        console.log(this.endPeriod);
      } else if (this.selectedUnitType.key == 3) {
        this.endPeriod = Date.new(
          startDate.getFullYear(),
          startDate.getMonth() + this.numberCount,
          startDate.getDate() - 1
        );
      } else if (this.selectedUnitType.key == 4) {
        this.endPeriod = Date.new(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + this.numberCount - 1
        );
      }
      this.endPeriod = this.$utils.formatDateToLine(this.endPeriod);
    },
    // 改变终止日期 有效期按日计算
    doAfterChangeEndPeriodTime (date) {
      // this.selectedUnitType.key = 4;
      // this.selectedUnitType.value = '日';
      // this.numberCount = this.$utils.DateDiffDay(this.startPeriod, e) + 1;
      this.signDataTypes.forEach(o => { o.isSelection = false; });
      this.endPeriod = date;
      this.selectedUnitType.key = 4;
      this.selectedUnitType.value = '日';
      this.numberCount = this.$utils.DateDiffDay(this.startPeriod, this.endPeriod) + 1;
    }
  },
  computed: {
    vSelectedUnitType: {
      get () {
        return {
          id: this.selectedUnitType.key,
          name: this.selectedUnitType.value
        };
      },
      set (newValue) {
        this.selectedUnitType.key = newValue.id;
        this.selectedUnitType.value = newValue.name;
      }
    }
  },
  watch: {
    validityPeriod (n, o) {
      this.initValidityPeriod();
    }
  }
};
</script>

  <style>
</style>