<template>
  <div>
    <list ref="detailList"
          :listTitle="listTitle"
          :extendSearchCondition="vPopupTableParams.extendSearchCondition"
          :functionBtnGroup="functionBtnGroup"
          :tableData="vPopupTableParams.tableData"
          :totalNum="totalNum"
          :PageCount="PageCount"
          :tableColumns="vPopupTableParams.tableColumns"
          :awardText="awardText"
          :titleInfoList="titleInfoList"
          @tableBtnClick="doAfterTableBtnClick"
          @loadTableData="loadTableData"></list>
  </div>
</template>
<script>
import list from '../activity-list';
import { MessageSearchDistributionForAgent, ParentsPromoteDistributionIncentives, BrowseGiftDistribution } from '../../../API/workbench.js';
let tableFunctionType = { fanxian: 'fanxian', fanhour: 'fanhour', fangift: 'fangift' };// 几个弹出层列表tabFunction 类型的初始化
export default {
  name: 'salesStatisticsList',
  data () {
    return {
      listTitle: '',
      itemInfo:{},
      awardText:'',
      agentid:0,
      totalNum: 0, // 返回的总记录数
      PageCount: 0, // 页数
      pageSize: 0, // 查询记录数
      FXJLTableColumn: [// 列
        {
          label: "序号",
          prop: "Number",
          width: 68,
          sortable: false,
          align: "left",
          isShow: true,
          type: "table-index",
          className: "table_index_gray",
        },
        { label: '分销人', prop: 'username', width: 226, sortable: 'custom', align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '手机号', prop: 'telephone', width: 207, sortable: false, align: 'left', isShow: true, type: '', decimalPlace: 0, icon: 'fa fa-qrcode' },
        { label: '转发', prop: 'share_num', width: 207, sortable: false, align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '招生', prop: 'get_num', width: 207, sortable: false, align: 'left', isShow: true, type: '', decimalPlace: 0 },
        { label: '到店人数', prop: 'UseNum', width: '', sortable: false, align: 'left', isShow: true, type: '', decimalPlace: 0 },
        // { label: '报名金额', prop: 'Stutas', width: 150, sortable: false, align: 'left', isShow: true, type: '', decimalPlace: 0 },
      ],
      titleInfoList:[],
      FXJLTableData: [], // 数据
      functionBtnGroup: [], // 功能按钮
      queryParamsRecord: []  // 查询时的参数
    };
  },
  props: ['selectedCouponInfo'],
  components: {
    list
  },
  computed: {
    // 弹出层列表参数
    vPopupTableParams () {
      let tableParams = {};
      tableParams.tableColumns = this.FXJLTableColumn;
      tableParams.tableData = this.FXJLTableData;
      return tableParams;
    },
  },
  mounted(){
  },
  methods: {
    // 加载分销奖励明细
    loadDistributionRewardDetailList (queryParams, callback) {
      queryParams.agentid = this.agentid;
      queryParams.id = this.selectedCouponInfo.id;
      this.selectedCouponInfo.CouponStrategy.forEach(e=>{
        if(e.strategy_type == 2){
          if(e.fanxian_amount > 0) {
            this.awardText = e.fanxian_amount + '元';
          }else if(e.fanhour_num > 0) {
            this.awardText =  e.fanhour_num + '课时';
          }else if(e.fangift_name) {
            this.awardText =  e.fangift_name;
          }
        }
      })
      console.log(this.awardText, this.vAwardText,'11111111111111')
      MessageSearchDistributionForAgent(queryParams).then(result => {
        this.FXJLTableData = [];
        if (result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach((obj, index) => {
            this.FXJLTableData.push(obj)
          });
          this.totalNum = result.data.Total;
          this.PageCount = result.data.PageCount;
          this.currentPage = queryParams.PageIndex;
        } else {
          this.totalNum = 0;
        }
        
        this.$refs.detailList.isLoading = false;
        if (callback) {
          callback();
        }
      }, error => {
        layer.alert(error.data.msg);
        this.$refs.detailList.isLoading = false;
      });
    },
    // 加载弹出层列表数据
    loadTableData (queryParams, callback) {
      this.$refs.detailList.isSearch = true;
      this.$refs.detailList.isLoading = true;
      console.log('TeacherCommissionDetails', queryParams);
      this.queryParamsRecord = queryParams;
      this.loadDistributionRewardDetailList(queryParams, callback);
    },
    // 点击table 的详情
    doAfterClickDetail (item, eventName) {
      this.itemInfo = item;
      this.titleInfoList = [
        {name:'员工',text:this.itemInfo.name},
        {name:'分销人',text:this.itemInfo.ResaleNum},
        {name:'转发',text:this.itemInfo.share_num},
        {name:'招生',text:this.itemInfo.get_num},
      ]
      this.agentid = item.id;
      let initSelectedParams = [];
      let unClearParams = [];
      this.listTitle = '分销统计';
      this.$refs.detailList.isShowListDialog = true;
      this.$refs.detailList.initSelectedParams(initSelectedParams, unClearParams);
    },
    // 点击 弹出层列表的按钮
    doAfterTableBtnClick (rowData, eventName) {
      let type = 0;
      let canDo = true;
      switch (eventName) {
        case tableFunctionType.fanxian:
          canDo = !rowData.is_fafang_fanxian == 1;
          type = 1;
          break;
        case tableFunctionType.fanhour:
          canDo = !rowData.is_fafang_fanhour == 1;
          type = 2;
          break;
        case tableFunctionType.fangift:
          canDo = !rowData.is_fafang_fangift == 1;
          type = 3;
          break;
        default:
          break;
      }
      console.log(rowData, 'rowData');
    // 问题/
      if (canDo) {
        // if (rowData.strategy_type == 2 && rowData.strategy_sub_type == 3 && type == 3) {
        //   this.browseGiftDistribution(rowData);
        // } else {
        this.parentsPromoteDistributionIncentives(rowData, type);
        // }
      }
    },
    parentsPromoteDistributionIncentives (rowData, type) {
      ParentsPromoteDistributionIncentives(rowData.id, type).then(result => {
        this.$refs.detailList.isShowListDialog = true;
        this.loadTableData(this.queryParamsRecord, () => {
          this.$nextTick(() => {
            layer.alert(result.msg);
          });
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 浏览礼品发放   用户券id id UserCouponKey
    browseGiftDistribution (rowData) {
      console.log('browseGiftDistribution', rowData);
      BrowseGiftDistribution(0, rowData.id).then(result => {
        this.$refs.detailList.isShowListDialog = true;
        this.loadTableData(this.queryParamsRecord, () => {
          this.$nextTick(() => {
            layer.alert(result.msg);
          });
        });
      }, error => {
        layer.alert(error.msg);
      });
    }
  }

};
</script>
