<template>
  <div class="new_course_box bg_white">
    <!-- 第一步：课表基本信息 -->
    <div v-if="isShowCourseDetail"
         class="class_table_form form_info_edit content_marginTop_30">
      <course-detail class="form_info_bottom"
                     :courseTableData="courseTableData"
                     @nextStep="nextStepEvent"
                     @cancelClick="cancelEditCourseClick"></course-detail>
    </div>
    <!-- 第二步：设置上课时间 -->
    <div v-show="!isShowCourseDetail">
      <!--课 基本信息 -->
      <course-Info :courseTableData="courseTableData"
                   :isClassGradeWarning="isClassGradeConflictTips"
                   :generatedClassTimeData="generatedClassTimeData"
                   @editCourse="editBasicCourseData"></course-Info>
      <div>
        <div class="new_course_title"
             style="margin-bottom:12px">
          循环规律
        </div>
        <!-- 已生成的时间 -->
        <div class="new_course_weekUl">
          <generated-class-time v-for="item in generatedClassTimeData"
                                :key="item.number"
                                :classTimeData="item"
                                @onDeleteClick="toDeleteCircleTable"></generated-class-time>
        </div>
        <!-- 添加上课时段 -->
        <div v-if="isAdding">
          <set-class-time ref="setClassTime"
                          :structuredPlanList="structuredPlanList"
                          :courseTableData="courseTableData">
          </set-class-time>
        </div>
        <!-- 添加更多时段按钮 -->
        <div v-if="!isAdding"
             @click.stop="isAdding = true"
             class="new_course_addList">添加更多时段</div>
      </div>
      <!-- 按钮组 -->
      <div v-if="isAdding">
        <save-cancel-btn-group :isSingleSubmit="true"
                               :singleSubmitType="'dynamic'"
                               :btnText="'确定'"
                               @confirmClick="confirmClick"
                               @cancelClick="cancelClick"></save-cancel-btn-group>
      </div>
      <div v-else
           class="confirm_btn btn_fixed_bottom pf">
        <div class="btn_fixed_blue btn_hover_bg_blue"
             @click.stop="completedAndBack">完成并返回</div>
      </div>
      <div v-if="!isAdding"
           class="btn_fixed_bottom_placeholder">
      </div>
    </div>
  <!-- 显示加载的进度条 -->
            <custom-dialog class="progress_max progress"
                           width="300px"

                           :visible.sync="isShowProgress">
              <div class="progress_title">
                正在新建循环课
              </div>
              <div class="progress_box">
                <el-progress type="circle"
                             :percentage="batchAttencePercentage"></el-progress>
              </div>
              <div class="progress_exit" @click="isShowProgress = false"></div>
            </custom-dialog>
    <custom-dialog class="progress_max progress"
                           width="300px"
                           :visible.sync="isShowLoad">
             <submit-progress-tip :tips="vTips"></submit-progress-tip>
    </custom-dialog>
    <!-- 冲突提示 -->
    <div v-if="isShowCoursesConflictTips">
      <courses-conflict-tips :generatedClassTimeData="generatedClassTimeData"
                             @changeClassGrade="toChangeClassGrade"
                             @newClassGrade="toNewClassGrade"
                             @backToEdit="tobackToEdit"></courses-conflict-tips>
    </div>
    <!-- 修改排课计划 -->
    <custom-dialog :title="'修改排课计划'"
                   :visible.sync="isEditPlan"
                   :before-close="cancelEditPlanClick"
                   style="padding-top:22px">
      <course-detail class="content_marginTop_30"
                     :courseTableData="courseTableData"
                     :isEditPlan="isEditPlan"
                     @confirmClick="editSchedulePlan"
                     @cancelClick="cancelEditPlanClick"></course-detail>
    </custom-dialog>
  </div>
</template>

<script>
import setClassTime from './set-class-time';
import courseDetail from '../common/course-detail';
import courseInfo from '../common/course-info';
import operationProcess from '../../common/operation-process';
import coursesConflictTips from '../common/courses-conflict-tips';
import generatedClassTime from './generated-class-time/index';
import {
  AddCirculatedScheduleCourse,
  getUsingClass,
  EditPlan,
  BatchSyncSubmit,
  TerminatedPlan,
  AsyncBatchCirculatedScheduleCourse
} from '../../../API/workbench.js';
export default {
  data () {
    return {
      isShowCourseDetail: true, // 新建循环课的第一步
      isAdding: true, // 是否正在新增规律课块
      courseTableData: {
        BJPKIDKey: 0,
        BJPKIDKeyValue: '默认班级',
        ClassSetKey: 0,
        ClassSetKeyValue: '',
        FullPeopleCount: 0,
        WarnLossCount: 0,
        CircleStartTime: '',
        CircleEndTime: this.$utils.formatDateToLine(this.$utils.getNextYear(Date.new())),
        WeekList: [],
        TimeQuantum: '',
        DurationCount: 0,
        ClassRoomIDSName: '',
        ClassRoomName: '',
        LecturerKeyValue: '',
        LecturerKey: '',
        SynchronizeKey: 0,
        PeriodCount: 1.0,
        LeaveClassKey: 0,
        LeaveClassKeyValue: '否',
        IsleaveKey: 0,
        IsleaveKeyValue: '否',
        ClassCount: 0, // 节数
        EndTypeKey: this.$store.getters.isSysVersionTY ? 1 : 2,
        EndTypeKeyValue: this.$store.getters.isSysVersionTY ? '不结束' : '限日期'
      },
      generatedClassTimeData: [], // 已经生成的上课时间列表
      isShowProgress: false, // 是否显示进度
      structuredPlanList: [], // 新增生成的排课计划列表
      isShowCoursesConflictTips: false, // 班级冲突弹出提示
      isClassGradeConflictTips: false, // 班级冲突警告
      gradeClassList: [], // 班级列表
      isEditPlan: false, // 是否修改排课计划
      isNext: false, // 是否进入了第二步
      isDeleting: false, // 是否正在删除
      isShowLoad: false,
      runCount: 0, // 进度条.
      totolPeoCount: 0,
      batchAttencePercentage: 0
    };
  },
  props: {
    dataInfo: Object
  },
  components: {
    setClassTime,
    courseDetail,
    operationProcess,
    courseInfo,
    coursesConflictTips,
    generatedClassTime
  },
  created () {
    if (this.dataInfo && JSON.stringify(this.dataInfo) != '{}') {
      console.log('created dataInfo', this.dataInfo);
      Object.assign(this.courseTableData, this.dataInfo);
      if (this.courseTableData.EndTypeKey == 1) {
        this.courseTableData.CircleEndTime = this.$utils.formatDateToLine(this.$utils.getNextYear(Date.new()));
      } else {
        this.courseTableData.CircleEndTime = this.$utils.formatDateToLine(this.$utils.getNextYear(Date.new(this.dataInfo.CircleStartTime)));
      }
    }
  },
  mounted () {
    this.$bus.on(this, 'AfterClassGradeAddOrEdit', (type, result) => {
      this.courseTableData.BJPKIDKey = result.OLAPKey;
      this.courseTableData.BJPKIDKeyValue = result.MainDemoName;
      this.isClassGradeConflictTips = false;
    });
    this.$dialog.register(this, ['squadClassSetting', 'singleSelectionList']);
  },
  computed: {
    vTips () {
      let str = '';
      if (this.isEditPlan) {
        str = '正在批量修改课';
      } else if (this.isDeleting) {
        str = '正在批量删除课';
      } else {
        str = '';
      }
      return str;
    }
  },
  watch: {
    'courseTableData.BJPKIDKey': {
      handler (n, o) {
        if (n != o) {
          this.isClassGradeConflictTips = false;
        }
      }
    }
  },
  methods: {
    // 新增上课时间区间 比较是否有完全相同的时间
    contrastClassTimeDiffer () {
      let existsClassTimeData = [];
      let curAddStartDate = Date.new(this.courseTableData.CircleStartTime).getTime();
      let curAddEndDate = Date.new(this.courseTableData.CircleEndTime).getTime();
      this.generatedClassTimeData.forEach(obj => {
        // 相同时间段
        if (obj.TimeQuantum == this.courseTableData.TimeQuantum) {
          let startDate = Date.new(obj.CircleStartTime).getTime();
          let endDate = Date.new(obj.CircleEndTime).getTime();
          // 相同日期
          if ((curAddStartDate <= startDate && curAddEndDate >= startDate) ||
            (curAddStartDate >= startDate && curAddStartDate <= endDate) ||
            (curAddEndDate >= startDate && curAddEndDate <= endDate)) {
            obj.weekListData.forEach(weekData => {
              let sameClassTime = this.courseTableData.WeekList.find(value => { return value == weekData.WeekKey; });
              if (sameClassTime) {
                existsClassTimeData.push({ CircleStartTime: curAddStartDate, CircleEndTime: curAddEndDate, WeekKey: weekData.WeekKey });
              }
            });
          }
        }
      });
      return existsClassTimeData;
    },
    // 确定
    confirmClick (callBack) {
      // 是否拒绝提交
      let isRejectSubmit = false;
      if (this.verifySubmitData()) {
        isRejectSubmit = true;
      } else if (this.checkNewCoursesConutIsZero()) { // 是否建立了0节的循环课块
        isRejectSubmit = true;
      } else {
        let hasExistsClassTimeData = this.contrastClassTimeDiffer();
        if (hasExistsClassTimeData.length > 0) {
          layer.alert('上课时间有冲突，请注意');
          isRejectSubmit = true;
        }
      }
      if (isRejectSubmit) {
        if (typeof callBack == 'function') {
          callBack();
        }
        return false;
      }
      if (!this.courseTableData.BJPKIDKey) {
        let that = this;
        let className = this.newClassGradeName();
        layer.confirm('未选择班级，将为您建一个' + className + '，是否继续？',
          {
            shadeClose: false,
            btn: [
              {
                name: '取消',
                callBack: () => {
                  layer.close();
                  callBack();
                  return false;
                }
              },
              {
                name: '确认',
                callBack: () => {
                  layer.close();
                  that.courseTableData.BJPKIDKeyValue = className;
                  that.toSubmit(callBack);
                }
              }
            ]
          }
        );
      } else {
        this.toSubmit(callBack);
      }
    },

    // 提交
    toSubmit (callBack) {

      if (this.$websocket.ws.readyState != 1) {
        layer.alert('网络不稳定，请稍后再试。');
        if(callBack){
          callBack()
        }
        return false;
      }
      console.log(this.$websocket.ws.readyState, 'this.$websocket.ws.readyState');
      console.log('AddCirculatedScheduleCourse', this.courseTableData);
      this.isShowProgress = true;
      this.batchAttencePercentage = 0;
      AddCirculatedScheduleCourse(this.courseTableData).then(result => {
        console.log('AddCirculatedScheduleCourse', result.data);
        this.$asyncTask.register(result.data.AsynTaskID, (sendData) => {
          console.log('新建课长连接通知:', sendData);
          if (sendData.nodeType != 0 && sendData.status == 1) {
            console.log(sendData.progress, 'sendData.progress');
            this.batchAttencePercentage = Math.round(sendData.progress); // 批量考勤的百分比
          } else if (sendData.nodeType == 0 && sendData.status == 1) {
              // 结束节点
            this.batchAttencePercentage = Math.round(sendData.progress); // 批量考勤的百分比
            this.isShowProgress = false;
            this.$asyncTask.delete(sendData.AsynTaskID);
            this.isAdding = false;
            this.$bus.emit('AfterNewCourseTable', 'mutil', { CourseNameKey: this.courseTableData.ClassSetKey, classGradeId: this.courseTableData.BJPKIDKey });
            this.afterAddCircleCourseSuccess(sendData.remark);
            if (typeof callBack == 'function') {
              callBack();
            }
            console.log('结束了');
          } else if (sendData.status == 0) {
              // status：1成功 0失败
            console.log('批量建课某个节点失败-》sendData:', sendData);
          }
        });
      }, error => {
        this.isShowProgress = false;
        if (error.code == 1) {
          layer.alert(error.msg);
        } else if (error.code == 10000) {
          layer.alert(error.msg);
        } else if (error.code == 60101) {
          this.isShowCoursesConflictTips = true;
          this.isClassGradeConflictTips = true;
          this.getValidClassList();
        } else {
          layer.alert(error.msg);
        }
        if (typeof callBack == 'function') {
          callBack();
        }
      });
    },
    // 取消新建课
    cancelClick (type) {
      if (this.isAdding && this.generatedClassTimeData.length > 0) {
        this.isAdding = false;
        this.isClassGradeConflictTips = false;
        this.courseTableData.WeekList = [];
        this.courseTableData.TimeQuantum = '';
        this.courseTableData.ClassCount = 0;
      } else {
        this.$emit('closeDialog');
      }
    },
    // 新增循环课成功后
    afterAddCircleCourseSuccess (resultData) {
      this.courseTableData.BJPKIDKey = this.courseTableData.BJPKIDKey || resultData[0].BJPKIDKey;
      this.isClassGradeConflictTips = false;
      let weekListData = [];
      resultData.forEach(obj => {
        let weekData = { WeekKey: obj.WeekKey, WeekKeyValue: obj.WeekKeyValue, StructuredPlanKey: obj.OLAPKey, AlreadyBeenCount: obj.AlreadyBeenCount };
        weekListData.push(weekData);
        this.structuredPlanList.push({
          OLAPKey: obj.OLAPKey,
          CourseNameKey: this.courseTableData.ClassSetKey
        });
      });
      let classTimeInfo = {
        number: Math.random().toString(36).substring(3, 7),
        EndTypeKey: this.courseTableData.EndTypeKey,
        CircleStartTime: this.courseTableData.CircleStartTime,
        CircleEndTime: this.courseTableData.CircleEndTime,
        weekListData: weekListData,
        TimeQuantum: this.courseTableData.TimeQuantum,
        ClassCount: this.courseTableData.ClassCount
      };
      this.generatedClassTimeData.push(classTimeInfo);
      this.courseTableData.WeekList = [];
      this.courseTableData.TimeQuantum = '';
      if (this.courseTableData.EndTypeKey == 3) {
        this.courseTableData.CircleEndTime = '';
      } else {
        this.courseTableData.ClassCount = 0;
      }
    },
    // 更换班级
    toChangeClassGrade (id, name) {
      this.closeConflictTip();
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '选择班级',
        moduleName: 'singleSelectionList',
        data: { selectedKey: this.courseTableData.BJPKIDKey, dataSourceList: this.gradeClassList, required: true, addModuleName: '' },
        callBack: {
          onSelectedItem: (data) => { this.selectClassGradeForConflict(data); }
        }
      });
    },
    // 新建班级
    toNewClassGrade () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '新增班级',
        moduleName: 'squadClassSetting',
        data: { editItem: {}, isReadonly: false }
      });
      this.closeConflictTip();
    },
    // 返回修改
    tobackToEdit () {
      this.closeConflictTip();
    },
    // 关闭冲突提示
    closeConflictTip () {
      this.isShowCoursesConflictTips = false;
    },
    // 获取有效班级列表
    getValidClassList () {
      getUsingClass().then(result => {
        this.gradeClassList = [];
        result.data.forEach(obj => {
          if (Date.new() <= Date.new(obj.EndTime) || !obj.EndTime) {
            this.gradeClassList.push(obj);
          }
        });
      });
    },
    // 班级冲突选择班级
    selectClassGradeForConflict (item) {
      this.courseTableData.BJPKIDKey = item.OLAPKey;
      this.courseTableData.BJPKIDKeyValue = item.MainDemoName;
    },
    // 选择班级
    doAfterSelectClassGrade (item) {
      this.courseTableData.BJPKIDKey = item.OLAPKey;
      this.courseTableData.BJPKIDKeyValue = item.MainDemoName;
      this.presetCourseNameAndTeacher(item);
    },
    // 根据班级预置课程和老师
    presetCourseNameAndTeacher (item) {
      // 班级预设课程
      if (item.ClassSetKey && (!item.ApplyCorIDsName || item.ApplyCorIDsName.split(',').length == 1)) {
        this.courseTableData.ClassSetKey = item.ClassSetKey;
        this.courseTableData.ClassSetKeyValue = item.ClassSetKeyValue;
      }
      if (item.LecturerKey) {
        this.courseTableData.LecturerKeyValue = item.LecturerKeyValue;
        this.courseTableData.LecturerKey = item.LecturerKey;
      }
    },
    // 修改课的基础信息
    editBasicCourseData () {
      // 修改排课计划
      if (this.generatedClassTimeData.length > 0) {
        this.isEditPlan = true;
      } else {
        this.isShowCourseDetail = true;
      }
    },
    // 下一步
    nextStepEvent (courseData) {
      this.isNext = true;
      Object.assign(this.courseTableData, courseData);
      this.cancelEditCourseClick();
    },
    // 关闭修改课弹窗
    cancelEditCourseClick () {
      if (!this.isNext) {
        this.cancelClick();
      }
      this.isShowCourseDetail = false;
    },
    // 修改排课计划
    editSchedulePlan (courseData, callBack) {
      let courseBillItemData = this.$utils.parseJson(courseData);
      delete courseBillItemData.classTimeArr;
      let courseBillArr = [];
      this.structuredPlanList.forEach((obj, index) => {
        courseBillItemData.OLAPKey = obj.OLAPKey;
        courseBillItemData.LeaveClassKeyValue = courseBillItemData.LeaveClassKey == 0 ? '否' : '是';
        courseBillItemData.IsleaveKeyValue = courseBillItemData.IsleaveKey == 0 ? '否' : '是';
        courseBillArr.push(this.$utils.parseJson(courseBillItemData));
      });
      this.isShowLoad = true;
      let APIPromiseCreator = obj => {
        return EditPlan(obj);
      };

      let resolve = (r, obj) => {
        this.runCount++;
      };
      let reject = (r, obj) => {
        obj.errCode = r.code;
        if (obj.errCode == 1) {
          layer.alert(r.msg);
        } else if (obj.errCode == 10000) {
          layer.alert(r.msg.split('|')[2]);
        } else {
          layer.alert(r.msg);
        }
        this.isShowLoad = false;
      };
      let resultCallback = resultList => {
        if (resultList[0].r.code == 0) {
          layer.alert('修改成功');
          this.isEditPlan = false;
          this.isShowLoad = false;
          this.$bus.emit('AfterBatchEditStructuredPlanList', this.courseTableData.ClassSetKey, this.courseTableData.BJPKIDKey, courseBillArr);
          Object.assign(this.courseTableData, courseData);
          this.$nextTick(() => {
            this.cancelEditPlanClick();
          });
        }
      };
      BatchSyncSubmit(
        courseBillArr,
        APIPromiseCreator,
        resultCallback,
        resolve,
        reject,
        callBack
      );
    },
    // 取消排课计划的修改
    cancelEditPlanClick () {
      this.isEditPlan = false;
    },
    // 询问是否删除计划
    toDeleteCircleTable (classTimeObj) {
      if (this.isDeleting) {
        return false;
      }
      this.isDeleting = true;
      layer.confirm('是否确认删除？', {
        shadeClose: false,
        btn: [
          {
            name: '取消',
            callBack: () => {
              this.isDeleting = false;
              layer.close();
              return false;
            }
          },
          {
            name: '确认',
            callBack: () => {
              this.confirmDeletePlan(classTimeObj);
              layer.close();
            }
          }
        ]
      });
    },
    // 批量删除计划
    confirmDeletePlan (classTimeObj) {
      this.isShowLoad = true;
      let StructuredPlanList = [];
      let APIPromiseCreator = obj => {
        return TerminatedPlan(obj.StructuredPlanKey);
      };

      let resolve = (r, obj) => {
        StructuredPlanList.push(obj.StructuredPlanKey);
        this.runCount++;
      };
      let reject = (r, obj) => {
        obj.errCode = r.code;
        if (obj.errCode == 1) {
          layer.alert(r.msg);
        } else if (obj.errCode == 10000) {
          layer.alert(r.msg.split('|')[2]);
        } else {
          layer.alert(r.msg);
        }
        this.isShowLoad = false;
      };
      let resultCallback = resultList => {
        this.isShowLoad = false;
        if (resultList[0].r.code == 0) {
          classTimeObj.weekListData.forEach(obj => {
            let deleteIndex = this.structuredPlanList.findIndex(item => { return item.OLAPKey == obj.StructuredPlanKey; });
            if (deleteIndex >= 0) {
              this.structuredPlanList.splice(deleteIndex, 1);
            }
          });
          this.isDeleting = false;
          let deleteIndex = this.generatedClassTimeData.findIndex(obj => { return obj.number == classTimeObj.number; });
          if (deleteIndex >= 0) {
            this.generatedClassTimeData.splice(deleteIndex, 1);
            if (this.generatedClassTimeData.length == 0) {
              this.isAdding = true;
            }
          }
          this.$bus.emit('AfterBatchDeleteStructuredPlanList',
            this.courseTableData.ClassSetKey,
            this.courseTableData.BJPKIDKey,
            StructuredPlanList
          );
        }
      };
      BatchSyncSubmit(
        classTimeObj.weekListData,
        APIPromiseCreator,
        resultCallback,
        resolve,
        reject
      );
    },
    newClassGradeName () {
      let lastStr = this.courseTableData.ClassSetKeyValue.substring(this.courseTableData.ClassSetKeyValue.length - 1);
      let courseName = '';
      if (lastStr == '课') {
        courseName = this.courseTableData.ClassSetKeyValue.substring(0, this.courseTableData.ClassSetKeyValue.length - 1);
      } else {
        courseName = this.courseTableData.ClassSetKeyValue;
      }
      return courseName + '班';
    },
    completedAndBack () {
      this.cancelClick();
    },
    // 验证要提交的数据
    verifySubmitData () {
      let flag = false;
      if (!this.courseTableData.CircleStartTime) {
        flag = true;
        layer.alert('开始日期不可为空');
      } else if (Date.new(this.courseTableData.CircleStartTime).getTime() > this.$utils.getNextYear(Date.new()).getTime()) {
        flag = true;
        layer.alert('最长只能建立一年内的课');
      } else if (!this.courseTableData.CircleEndTime && this.courseTableData.EndTypeKey == 2) {
        flag = true;
        layer.alert('结束日期不可为空');
      } else if (Date.new(this.courseTableData.CircleStartTime).getTime() > Date.new(this.courseTableData.CircleEndTime).getTime() && this.courseTableData.EndTypeKey == 2) {
        flag = true;
        layer.alert('开始日期不可晚于结束日期');
      } else if (this.courseTableData.WeekList.length == 0) {
        flag = true;
        layer.alert('至少选择一个周几');
      } else if (!this.courseTableData.TimeQuantum) {
        flag = true;
        layer.alert('上课时间不能为空');
      }
      return flag;
    },
    // 检查新建节数是否为0
    checkNewCoursesConutIsZero () {
      this.courseTableData.WeekList.sort((a, b) => { return a - b; });
      let zeroCourses = '';
      let flag = false;
      let curMinutes = Date.new().getHours() * 60 + Date.new().getMinutes();
      let classMinutes = Number(this.courseTableData.TimeQuantum.split('-')[1].split(':')[0]) * 60 + Number(this.courseTableData.TimeQuantum.split('-')[1].split(':')[1]);
      if (this.courseTableData.EndTypeKey == 1) { // 不结束
        let maxClassNum = 0;
        let curWeek = Date.new(this.courseTableData.CircleStartTime).getDay(); curWeek = curWeek == 0 ? 7 : curWeek;// 开始日期是周几
        let isTodayPass = false; // 是否选了今天的日期且为过去时段
        let classAllDates = [];
        if (Date.new(this.courseTableData.CircleStartTime).getTime() == Date.new(this.$utils.formatDateToLine(Date.new())).getTime() && classMinutes <= curMinutes) {
          isTodayPass = true;
        }
        let nonClassNumWeekList = []; // 没有课的周几列表
        let weekStr = '';
        this.courseTableData.WeekList.forEach(week => {
          let datas = this.$utils.getDateArrForOneDate(this.courseTableData.CircleStartTime, this.courseTableData.CircleEndTime, week);
          maxClassNum = maxClassNum + datas.length;
          // 开始日期为今天 且上课时段为过去时间
          if (isTodayPass && week == curWeek) {
            --maxClassNum;
            datas.shift();
          }
          if (datas.length > 0) {
            classAllDates = classAllDates.concat(datas);
          } else {
            nonClassNumWeekList.push(week);
          }
        });
        if (maxClassNum <= 0 || nonClassNumWeekList.length > 0) {
          nonClassNumWeekList.forEach(week => {
            let weekItem = this.$refs.setClassTime.weekList.find(obj => { return week == obj.key; });
            weekStr = weekStr ? weekStr + ('、周' + weekItem.value) : ('周' + weekItem.value);
          });
          layer.alert('一年内，至少建立一节课。以当前选择，' + weekStr + '没有一节课可以建立。请重新选择');
          flag = true;
        }
      } else if (this.courseTableData.EndTypeKey == 2) { // 限日期
        let nextYearDate = this.$utils.getNextYear(Date.new()); // 一年后的日期
        if (Date.new(this.courseTableData.CircleEndTime).getTime() > nextYearDate.getTime()) {
          layer.alert('限日期模式最远只能建到' + nextYearDate.getFullYear() + '年' + (nextYearDate.getMonth() + 1) + '月' + nextYearDate.getDate() + '日，如果需要建立更远的课，请选择不结束。');
          flag = true;
        } else {
          for (let index = 0; index < this.courseTableData.WeekList.length; index++) {
            let weekdays = this.$utils.getWeekNum(Date.new(this.courseTableData.CircleStartTime), Date.new(this.courseTableData.CircleEndTime));
            let weekKey = this.courseTableData.WeekList[index];
            let num = weekdays[weekKey];
            let dateArr = this.$utils.getDateArrForOneDate(this.courseTableData.CircleStartTime, this.courseTableData.CircleStartTime, weekKey);
            // 开始日期为今天的且只有一节
            if (Date.new(dateArr[0]).getTime() == Date.new(this.$utils.formatDateToLine(Date.new())).getTime() && num == 1) {
              if (classMinutes <= curMinutes) {
                zeroCourses = zeroCourses ? zeroCourses + '、周' + this.$utils.getWeekByNum(weekKey) : '周' + this.$utils.getWeekByNum(weekKey);
              }
            } else if (num == 0) {
              zeroCourses = zeroCourses ? zeroCourses + '、周' + this.$utils.getWeekByNum(weekKey) : '周' + this.$utils.getWeekByNum(weekKey);
            }
          }
          if (zeroCourses) {
            layer.alert(zeroCourses + '在一年内没有课，请重新调整');
            flag = true;
          }
        }
      } else if (this.courseTableData.EndTypeKey == 3) { // 限节数
        flag = this.caculateCircleEndDateByClassCount();
      }
      return flag;
    },
    // 通过节数计算结束日期
    caculateCircleEndDateByClassCount () {
      let flag = false;
      let circleStartDate = Date.new(this.courseTableData.CircleStartTime);
      let nextYearDate = this.$utils.getNextYear(Date.new()); // 最大日期
      let curMinutes = Date.new().getHours() * 60 + Date.new().getMinutes(); // 当前时段
      let classMinutes = Number(this.courseTableData.TimeQuantum.split('-')[1].split(':')[0]) * 60 + Number(this.courseTableData.TimeQuantum.split('-')[1].split(':')[1]); // 上课时段

      let curWeek = circleStartDate.getDay(); curWeek = curWeek == 0 ? 7 : curWeek;// 开始日期是周几
      let isTodayPass = false; // 是否选了今天的日期且为过去时段
      if (circleStartDate.getTime() == Date.new(this.$utils.formatDateToLine(Date.new())).getTime() && classMinutes <= curMinutes) {
        isTodayPass = true;
      }
      if (this.courseTableData.ClassCount == 0) {
        layer.alert('节数不可为0节');
        flag = true;
      }
      // 节数>=WeekList.length
      if (this.courseTableData.ClassCount >= this.courseTableData.WeekList.length) {
        let maxClassNum = 0; // 最长能上的节数
        let classAllDates = []; // 一年内所有符合上课的日期(weekList内)
        // 到明年今天 一共能排几节课
        this.courseTableData.WeekList.forEach(week => {
          let datas = this.$utils.getDateArrForOneDate(this.courseTableData.CircleStartTime, nextYearDate, week);
          maxClassNum = maxClassNum + datas.length;
          // 开始日期为今天 且上课时段为过去时间
          if (isTodayPass && week == curWeek) {
            --maxClassNum;
            datas.shift();
          }
          classAllDates = classAllDates.concat(datas);
        });
        if (this.courseTableData.ClassCount > maxClassNum) {
          layer.alert('限节数模式最远只能建到' + nextYearDate.getFullYear() + '年' + (nextYearDate.getMonth() + 1) + '月' + nextYearDate.getDate() + '日，以当前选择，只能建立' + maxClassNum + '节课。如果需要建立更远的课，请选择不结束。');
          flag = true;
        } else {
          classAllDates.sort((a, b) => {
            return Date.new(a).getTime() - Date.new(b).getTime();
          });
          this.courseTableData.CircleEndTime = classAllDates[this.courseTableData.ClassCount - 1];
        }
      } else {
        let nonClassNumWeekList = null;
        let weekStr = '';
        // 大于等于今天的星期几
        let greaterThanWeekList = this.courseTableData.WeekList.filter(week => { return week > curWeek || (!isTodayPass && week == curWeek); });
        // 小于今天的星期几
        let lessThanWeekList = this.courseTableData.WeekList.filter(week => { return week < curWeek || (isTodayPass && week == curWeek); });
        // 节数 《= 大于等于今天的星期几
        if (this.courseTableData.ClassCount <= greaterThanWeekList.length) {
          greaterThanWeekList.splice(0, this.courseTableData.ClassCount);
          nonClassNumWeekList = lessThanWeekList.concat(greaterThanWeekList);
        } else {
          let classCount = this.courseTableData.ClassCount;
          classCount = classCount - greaterThanWeekList.length;
          lessThanWeekList.splice(0, classCount);
          nonClassNumWeekList = lessThanWeekList;
        }
        nonClassNumWeekList.forEach(week => {
          let weekItem = this.$refs.setClassTime.weekList.find(obj => { return week == obj.key; });
          weekStr = weekStr ? weekStr + ('、周' + weekItem.value) : ('周' + weekItem.value);
        });
        layer.alert(weekStr + '在一年内没有课，请重新调整');
        flag = true;
      }
      return flag;
    }
  }
};
</script>
<style>
</style>