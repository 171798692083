<template>
  <!--1-TypeKey; 首次跟单 -->
  <div class="">
    <!-- 联系状态 -->
    <radio-button-set :formTitle="'联系状态'"
                      :required="true"
                      :radioList="relationTypeSet"
                      @doClick="chgRelationType">
    </radio-button-set>
    <div v-if="dataInfo.IsAnswerKey==1">
      <!-- 意向级别 -->
      <option-box-set :formTitle="'意向级别'"
                      :required="true"
                      :optionList="intentionList"
                      @doClick="chgIntentionType">
      </option-box-set>
      <!-- 联系内容 -->
      <div class="form_text_box">
        <content-set :dataInfo="dataInfo"
                     :contentSet="brandContentSet"
                     @change="chgContent"></content-set>
      </div>
      <!-- 微信好友 -->
      <radio-button-set :formTitle="'微信好友'"
                        :required="true"
                        :radioList="wechatTypeSet"
                        @doClick="chgWechatType">
      </radio-button-set>
    </div>
    <div class="date_icon">
      <input-date v-model="dataInfo.NextFollowTime"
                  :formTitle="'下次跟进'"
                  :readonly="isReadonly"
                  :minDate="$utils.formatDatet(Date.new(), 'yyyy-MM-dd')"
                  :elementId="'NextFollowTime'"
                  :required="false"
                  :formPlaceholder="'可选'"
                  @changeDate="chgNextFollowTime"></input-date>
    </div>
  </div>
</template>

<script>
import contentSet from './form/content-set';
import radioButtonSet from './form/radio-button-set';
import optionBoxSet from './form/option-box-set';

const relation = [{ id: 1, name: '已接通', isOpt: true }, { id: 0, name: '未接通', isOpt: false }];
const wechat = [{ id: 1, name: '已申请未通过', isOpt: true }, { id: 0, name: '已通过好友申请', isOpt: false }];
const ontent = { name: '联系详情', iconType: '', value: '', isReadonly: false };
const intentionList = [
  { id: 1, name: 'S 高意向', value: 'S', isOpt: true },
  { id: 2, name: 'A 中意向', value: 'A', isOpt: false },
  { id: 3, name: 'B 低意向', value: 'B', isOpt: false },
  { id: 4, name: 'C 无意向', value: 'C', isOpt: false }
];
export default {
  components: {
    contentSet,
    radioButtonSet,
    optionBoxSet
  },
  props: {
    dataInfo: Object // 招商顾问信息
  },
  data () {
    return {
      isReadonly: false, // 是否只读
      relationTypeSet: [],
      wechatTypeSet: [],
      brandContentSet: {},
      intentionList: []
    };
  },
  computed: {

  },
  watch: {
    'dataInfo.IsAnswerKey': {
      handler (n, o) {
        this.initData();
      },
      deep: true
    }
  },
  created () {
    this.initData();
  },
  methods: {
    initData () {
      console.log(this.dataInfo.IsAnswerKey, 'dataInfo.IsAnswerKey');
      if (this.dataInfo.IsAnswerKey < 0) {
        this.relationTypeSet = this.$utils.parseJson(relation);
      }
      this.wechatTypeSet = this.$utils.parseJson(wechat);
      this.intentionList = this.$utils.parseJson(intentionList);
      this.brandContentSet = this.$utils.parseJson(ontent);

      if (Number(this.dataInfo.IsAnswerKey) == 0) {
        this.dataInfo.Content = '未接通';
        this.dataInfo.WeChatStatusKey = 0;// 是否通过微信好友申请
        this.dataInfo.IntentionKey = 0;// 意向级别
        this.dataInfo.IntentionKeyValue = '';
        this.dataInfo.WeChatStatusKey = -1;// 是否通过微信好友申请
        this.dataInfo.WeChatStatusKeyValue = '';
      } else {
        this.dataInfo.IsAnswerKey = 1;// 联系状态
        this.dataInfo.IsAnswerKeyValue = '是';
        this.dataInfo.IntentionKey = 1;// 意向级别
        this.dataInfo.IntentionKeyValue = 'S';
        this.dataInfo.WeChatStatusKey = 1;// 是否通过微信好友申请
        this.dataInfo.WeChatStatusKeyValue = '是';
        this.dataInfo.Content = '';
      }
    },
    // 联系状态
    chgRelationType (item) {
      this.dataInfo.IsAnswerKey = item.id;
      this.dataInfo.IsAnswerKeyValue = item.id > 0 ? '是' : '否';
    },
    chgIntentionType (item) {
      this.dataInfo.IntentionKey = item.id;
      this.dataInfo.IntentionKeyValue = item.value;
    },
    chgWechatType (item) {
      this.dataInfo.WeChatStatusKey = item.id;
      this.dataInfo.WeChatStatusKeyValue = item.id > 0 ? '是' : '否';
    },
    // 下次跟进日期
    chgNextFollowTime (date) {
      this.dataInfo.NextFollowTime = date;
    },
    // 联系内容
    chgContent (value) {
      this.dataInfo.Content = value;
    }
  }
};
</script>

<style>
</style>