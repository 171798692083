<template>
  <div class="principal_echart">
    <div class="principal_echart_option">
      <div class="fl font_big ">报名业绩分析</div>
      <function-btn :item="applyRecordbtn"
                    @clickItem="doAfterClickFunctionBtn('applyrecord')"
                    :className="''">
        <div class="fr">
          <span v-if="setNumber(dataInfo.Summary_KDSY_Today.Number)>0">今日报名：<span>{{setNumber(dataInfo.Summary_KDSY_Today.Number)}}<span class="font_small font_gray">人</span> {{setNumber(dataInfo.Summary_KDSY_Today.ActualColAmount)}}<span class="font_small font_gray">元</span></span></span>
          <span class="font_blue"
                v-else>明细</span>
        </div>
      </function-btn>
    </div>
    <div class="principal_echart_content">

      <div class="principal_echart_data no_img button clearfix"
           :style="vDebtNum>0?'':'background:none'">
        <div class="fl"
             style="width: 25%;">
          <div class="font_top bg_gray_title">本月金额</div>
          <div class="font_bottom">
            {{setNumber(dataInfo.Summary_KDSY_ThisMonth.ActualColAmount)}}<span class="font_gray">元</span>
          </div>
        </div>
        <div class="fl"
             style="width: 25%;">
          <div class="font_top bg_gray_title">报名人数</div>
          <div class="font_bottom">
            {{setNumber(dataInfo.Summary_KDSY_ThisMonth.Number)}}<span class="font_gray">人</span>
          </div>
        </div>
        <div class="fr"
             style="width: 50%;padding-left:26px">
          <div class="font_top bg_gray_title">当前欠款</div>
          <div class="font_bottom"
               :class="vHasModulePower?'font_red':'font_gray'">
            {{setNumber(dataInfo.Summary_DebtAmount.DebtAmount)}}<span class="font_gray">元</span>
            {{setNumber(dataInfo.Summary_DebtAmount.CurrentDebtNum)}}<span class="font_gray">人</span>
          </div>
        </div>
        <function-btn :item="applyDebtWarningbtn"
                      @clickItem="doAfterClickFunctionBtn('debtMoneyStatisticsInfo')"
                      :className="''">
          <div class="principal_echart_apply_detail font_blue"
               v-show="setNumber(dataInfo.Summary_DebtAmount.DebtAmount)>0">详情</div>
        </function-btn>
        <!-- <div class="principal_echart_data_next pa font_blue">详情</div> -->
      </div>

      <div class="principal_echart_box"
           style="position:relative">
        <div class="six_month">近6个月:</div>
        <div class="echart_btn">
          <div :class="{'opt':!isMonth}"
               @click="chgIsMonth(false)">金额</div>
          <div :class="{'opt':isMonth}"
               @click="chgIsMonth(true)">人数</div>
        </div>
        <div class="echart_box">
          <single-line-echart :echartID="'echart_03'"
                              :nowList="nowList"
                              :reqList="reqList"
                              ref="echart"></single-line-echart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import singleLineEchart from './single-line-echart';
import { getKDSYStatistics, getKDSYForMonth } from '../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        startTime: '',
        endTime: ''
      },
      dataInfo: {
        Summary_KDSY_Today: {},
        Summary_KDSY_Yesterday: {},
        Summary_KDSY_ThisMonth: {},
        Summary_DebtAmount: {}
      },
      isMonth: false,        // 判断是否查询月接口或者周接口.
      nowList: [],     //
      reqList: [],    // x轴显示数组.
      applyRecordbtn: {     // 报名记录权限
        name: '',
        eventName: 'abnormalHandlingbtn',
        ModulePowerKey: 58
      },
      applyDebtWarningbtn: {      // 报名欠款权限
        name: '',
        eventName: 'debtMoneyStatisticsInfo',
        ModulePowerKey: 79
      }
    };
  },
  props: {
    screenWidth: [Number, String]
  },
  components: {
    singleLineEchart
  },
  watch: {
    screenWidth (c, o) {
      // console.log('this.$refs.echart报名.', this.$refs.echart.myChart);
      if (this.$refs.echart.myChart) {
        this.$refs.echart.myChart.resize();
      }
    },
    $route (to, from) {
      // 监听路由是否变化
      if (to.name == 'presidentKanban') {
        this.$nextTick(() => {
          this.$refs.echart.echartInit();
        });
      }
    }
  },
  mounted () {
    this.loadData();
  },
  computed: {
    vDebtNum () {
      if (this.dataInfo) {
        // console.log(this.setNumber(this.dataInfo.Summary_KDSY_ThisMonth.DebtNum), 'this.setNumber(this.dataInfo.Summary_KDSY_ThisMonth.DebtNum)');
        return this.setNumber(this.dataInfo.Summary_DebtAmount.DebtAmount);
      }
    },
    vHasModulePower () {
      return this.$utils.CheckModulePower(
        this.$store.getters.RolePowerList,
        54
      );
    }
  },
  methods: {
    loadData () {
      this.setMonthTime();
      this.getClassAmountChartForMonth(this.searchObj);
      this.getKDSYData();
    },
    // 获取所有统计数据
    getKDSYData () {
      getKDSYStatistics().then(result => {
        this.dataInfo = result.data;
        console.log(this.dataInfo, 'getKDSYData.dataInfo ');
      });
    },
    setNumber (num) {
      num = num || 0;
      return Math.round(num);
    },
    // 选中周或月
    chgIsMonth (type) {
      this.isMonth = type;
      this.toLoadData();
    },
    toLoadData () {
      this.setMonthTime();
      this.getClassAmountChartForMonth(this.searchObj);
    },
    // 设置月查询时间.
    setMonthTime () {
      let month = Date.new().getMonth();
      let startTime = this.$utils.formatDatet(Date.new(Date.new().setMonth(month - 5)), 'yyyy-MM') + '-01';
      let lastDate = Date.new(Date.new(this.$utils.formatDatet(Date.new(Date.new().setMonth(month + 1)), 'yyyy-MM') + '-01').setDate(-1));      // 获取这个月最后一天的日期.
      this.searchObj.endTime = this.$utils.formatDatet(lastDate, 'yyyy-MM-dd');
      this.searchObj.startTime = startTime;
      console.log(this.searchObj);
    },
    // 获取月份报表.
    getClassAmountChartForMonth (searchObj) {
      getKDSYForMonth(searchObj).then(result => {
        this.reportList = result.data.DataList;
        this.reportList.sort((x, y) => {
          return Date.new(x.SetUpTime.replace(/-/g, '/')) - Date.new(y.SetUpTime.replace(/-/g, '/'));
        });
        this.reqList = this.setMonthReqList();
        if (!this.isMonth) {
          this.nowList = this.setAmountList();
        } else {
          this.nowList = this.setCountList();
        }
        if (this.$refs.echart) {
          this.$nextTick(() => {
            this.$refs.echart.echartInit();
          });
        }
      }).catch(error => {
        console.log('getKQChartForMonth  error', error);
      });
    },
    // 设置月份X轴.
    setMonthReqList () {
      var newArr = [];
      if (this.reportList.length > 0) {
        this.reportList.forEach(o => {
          newArr.push(this.$utils.formatDateStr(o.SetUpTime, 'yyyy.M'));
          // totalDeductAmount
        });
      }
      return newArr;
    },
    // 设置金额数组
    setAmountList () {
      var newArr = [];
      if (this.reportList.length > 0) {
        this.reportList.forEach(o => {
          newArr.push(this.setNumber(o.ActualColAmount));
          // totalDeductAmount
        });
      }
      return newArr;
    },
    // 设置人数数组
    setCountList () {
      var newArr = [];
      if (this.reportList.length > 0) {
        this.reportList.forEach(o => {
          newArr.push(this.setNumber(o.Number));
          // totalDeductAmount
        });
      }
      return newArr;
    },
    goRouter (url) {
      this.$router.push({ name: url });
    },
    doAfterClickFunctionBtn (moduleName) {
      if (moduleName == 'debtMoneyStatisticsInfo' && this.vDebtNum == 0) {
        return false;
      }

      let moduleInfo = {
        routerName: this.$route.name,
        moduleName: moduleName
      };
      this.$bus.emit('needOpenRouter', moduleInfo);
    }

  }
};
</script>
