<template>
  <div>
    <div class="WKT_slideshow_title"
         v-if="titleName">
      <span>{{titleName}}</span>
    </div>
    <div class="WKT_introduction_name"
         @click="doEditBtnClick">
      <input type="text"
             class="WKT_introduction_name_input"
             :readonly="isReadonly"
             :value="value">
      <div class="WKT_introduction_btn"></div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    titleName: {//  标题
      type: String,
      default: ''
    },
    value: String,
    isReadonly: {
      type: Boolean,
      default: true
    }

  },
  methods: {
    doEditBtnClick () {
      this.$emit('doEditBtnClick', this.comboItem);
    }
  }

};
</script>

<style>
</style>