<template>
  <div>
    <!-- 搜索学生弹框 -->
    <div v-if="JSON.stringify(selectedStudentItem) == '{}'">
      <searchBox :searchInitJson="newScheduleCourseInitJson"
                 @searchClick="chooseClick"></searchBox>
    </div>
    <!--学生选择考勤列表  -->
    <div v-else>
      <select-attendance-item :studentItem="selectedStudentItem"
                              :studentAttendanceList="studentAttendanceList"
                              @amendItemShow="amendItemShow"
                              @updateAttenHistory="updateAttenHistory"
                              @changeStudent="chooseClick"></select-attendance-item>
    </div>
    <!-- 调整考勤和课消课时 -->
    <custom-dialog title="修改考勤"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowAmendAttendanceItem"
                   :before-close="closeDialog">
      <amend-attendance-item :studentItem="selectedStudentItem"
                             :item="attendanceCourseItem"
                             @updateAttenHistory="buttonClickEvent"
                             @closeDialog="closeDialog"></amend-attendance-item>
    </custom-dialog>
  </div>
</template>
<script>
import searchBox from '../../../common/search-box';
import selectAttendanceItem from './select-attendance-item';
import amendAttendanceItem from './amend-attendance-item';
import { timeGetStudentAttendanceHistroy } from '../../../../API/workbench';
// 修改 考勤
export default {
  name: 'changeAttendance',
  data () {
    return {
      // 修改考勤弹框
      isShowAmendAttendanceItem: false,
      //  搜索目标
      newScheduleCourseInitJson: {
        name: '目标学生'
      },
      studentAttendanceList: [], // 考勤数据
      searchInfo: {
        year: '',
        month: ''
      },
      isShowSearchInput: false,
      selectedStudentItem: {}, // 学生选中项
      attendanceCourseItem: {} // 考勤课项
    };
  },
  components: {
    searchBox, // 搜索学生
    selectAttendanceItem, // 学生选择考勤列表
    amendAttendanceItem // 调整考勤和课消课时
  },
  props: {
    dataInfo: {
      type: Object
    },
    courseInfo: {
      type: Object
    },
    ShowSearchInput: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.isShowSearchInput = this.ShowSearchInput;
    if (this.dataInfo) {
      this.doAfterSelectedStudent(this.dataInfo);
    }
    if (this.courseInfo && this.courseInfo.constructor === Object) {
      this.amendItemShow(this.courseInfo);
    }
  },
  mounted () {
    this.$dialog.register(this, ['studentList']);
  },
  watch: {
  },
  methods: {
    // 关闭弹窗
    isAmendSingleCourseHide () {
      this.$parent.isAmendAttendance = false;
      this.$emit('closeViewDialog');
    },
    // 点击搜索 切换学生
    chooseClick () {
      console.log('点击搜索 搜索框可见 并查询数据');
      this.$dialog.open(this, {
        routerName: this.$route.name,
        moduleName: 'studentList',
        data: {},
        callBack: {
          selectedItem: (item) => {
            this.doAfterSelectedStudent(item);
          }
        }
      });
    },
    // 点击确定
    buttonClickEvent (scheduleCourseKey, studentKey, attendanceType, successBackdata) {
      console.log('修改考勤成功回调：', scheduleCourseKey, studentKey, attendanceType, successBackdata);
      if (this.isShowSearchInput == true) {
        this.$emit('afterSucces', successBackdata);
        this.$emit('closeViewDialog');
      }
      this.updateAttenHistory();
      this.closeDialog();
    },
    // 点击取消
    closeDialog () {
      this.isShowAmendAttendanceItem = false;
    },
    // 学生选择项
    doAfterSelectedStudent (item) {
      console.log(item, '学生选择项');
      this.selectedStudentItem = item;
      this.updateAttenHistory();
    },
    // 更新考勤数据
    updateAttenHistory () {
      this.studentAttendanceList = [];
      this.intiSearchTime();
      this.GetOneMonthPage(this.searchInfo.year, this.searchInfo.month);// 获取当前这个月的考勤历史
      this.GetOneMonthAgoPage();
    },
    // 考勤列表
    amendItemShow (item) {
      this.attendanceCourseItem = item;
      this.isShowAmendAttendanceItem = true;
    },
    // 学生考勤数据
    GetOneMonthPage (year, month) {                       // 获取某个月的考勤数据
      timeGetStudentAttendanceHistroy(this.selectedStudentItem.OLAPKey, year, month).then(result => {
        if (result.data.length > 0) {
          result.data.forEach(o => {
            this.studentAttendanceList.push(o);
          });
          this.studentAttendanceList.sort(function (x, y) {
            return Date.new(y.ClassTime) - Date.new(x.ClassTime);
          });
          console.log(this.studentAttendanceList, '学生考勤');
        }
      });
    },
    intiSearchTime () {  // 初始化当前时间.
      var date = Date.new();
      this.searchInfo.year = date.getFullYear();
      this.searchInfo.month = date.getMonth() + 1;
    },
    GetOneMonthAgoPage () {   // 获取上个月.
      var date = Date.new(this.searchInfo.year, this.searchInfo.month - 1, -1);
      var yearAndMonth = [date.getFullYear(), date.getMonth() + 1];
      this.GetOneMonthPage(yearAndMonth[0], yearAndMonth[1]);
    }
  }
};
</script>

