<template>
  <!-- 出勤预警设置 -->
  <div>
    <div class="form_info_edit form_info_list single_shadow content_marginRL">
      <input-number :formTitle="'预警-出勤天数'"
                    :min="0"
                    v-model="menDianInfo.Set119Content"
                    :decimalPlaces="1"
                    :required="true"></input-number>
      <input-number :formTitle="'预警-出勤比例%'"
                    :min="0"
                    v-model="menDianInfo.Set120Content"
                    :decimalPlaces="0"
                    :required="true"></input-number>
      <input-number :formTitle="'预警-长期未到校天数'"
                    :min="0"
                    v-model="menDianInfo.Set121Content"
                    :decimalPlaces="0"
                    :required="true"></input-number>
    </div>
    <!-- 按钮组 -->
    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             :btnText="'确定'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { GetSaaSClientMsg, ModifySetting } from '../../../API/workbench.js';
export default {
  data () {
    return {
      menDianInfo: {
        Set119Content: Number(this.$utils.getSaaSClientSetInfo(119).SetContent), // 出勤天数预警
        Set120Content: Number(this.$utils.getSaaSClientSetInfo(120).SetContent), // 出勤比例预警
        Set121Content: Number(this.$utils.getSaaSClientSetInfo(121).SetContent) // 长期未到校预警
      }
    };
  },
  created () {

  },
  computed: {
    vSaasClientInfo () {
      return this.$store.getters.SaaSClientInfo;
    }
  },
  methods: {
    updateMendianInfo (data, callBack) {
      ModifySetting(data).then(res => {
        console.log(res, '更新门店设置成功');
        layer.alert('更新成功');
        this.$store.getters.SaaSClientInfo.SaaSClientSetting = this.$utils.setSaaSClientSet(data.SetTypeKey, data);
        this.$emit('onConfirmClick');
        this.$emit('cancelClick');
      }, err => {
        layer.alert(err.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    doAfterChangeFullPeopleCount (val) {
      console.log(val, 'val');
    },
    cancelClick () {
      this.$emit('cancelClick');
    },
    confirmClick (callBack) {
      let flag = false;
      if (!this.menDianInfo.Set119Content) {
        flag = true;
        layer.alert('出勤天数预警不能小于0');
      } else if (!this.menDianInfo.Set120Content) {
        flag = true;
        layer.alert('出勤比例预警不能小于0');
      } else if (!this.menDianInfo.Set121Content) {
        flag = true;
        layer.alert('长期未到校预警不能小于0');
      }
      if (flag) {
        if (typeof callBack == 'function') { callBack(); }
        return;
      }
      let item119 = {
        SetKey: '',
        SetKeyValue: '',
        SetContent: this.menDianInfo.Set119Content,
        SetTypeKey: 119
      };
      this.updateMendianInfo(item119, callBack);
      let item120 = {
        SetKey: '',
        SetKeyValue: '',
        SetContent: this.menDianInfo.Set120Content,
        SetTypeKey: 120
      };
      this.updateMendianInfo(item120, callBack);
      let item121 = {
        SetKey: '',
        SetKeyValue: '',
        SetContent: this.menDianInfo.Set121Content,
        SetTypeKey: 121
      };
      this.updateMendianInfo(item121, callBack);
    }
  }
};
</script>
