<template>
  <div>
    <div class="general_class_top"
         style="justify-content: flex-start;">
      <div class="attend_class_title">{{ this.classInfo.MainDemoName }}</div>

      <div class="general_class_top_text"
           style="margin-left: 5px;padding-bottom: 20px;line-height: 17px;">
        <!-- 修改 -->
        <div class="general_class_alter"
             @click="onEditClassInfo">&nbsp;</div>
      </div>
    </div>

    <div class="general_right_top_box flex">
      <div class="right_top_box box_right fr"
           style="width: 555px; border:none;height: 24px;    background: rgb(217, 223, 228,0.4);border-radius: 3px;line-height: 20px;">
        <div class="weekly_class_scheduling general_info">周排课次 <span :title="gradeClassInfo.WeekClassArrangeCount">{{ gradeClassInfo.WeekClassArrangeCount }}</span></div>
        <div class="classroom_courses general_info">报课学生 <span :title="gradeClassInfo.StudentCount">{{ gradeClassInfo.StudentCount }}</span></div>
        <div class="Lecturer_class general_info"
             style="width: 150px;">报正课 <span :title="gradeClassInfo.FormalClassStudentCount">{{ gradeClassInfo.FormalClassStudentCount }}</span></div>
        <div class="full_class_number general_info"
             style="border-right: none;width: 165px;">报引流课(含老生) <span :title="gradeClassInfo.DiversionStudentCount">{{ gradeClassInfo.DiversionStudentCount }}</span></div>
      </div>
    </div>

    <div class="studen_attendance_box">
      <div class="studen_content_box">
        <!-- 结转课消 -->
        <div class="forward_course_cancellation f_c_flex"
             style="background: #E7E1FB;box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04);margin-left: 20px;">
          <div class="course_cancellation_text c_text">结转课消</div>
          <div class="course_cancellation_num c_num"
               :title="classDeductAmountInfo.DeductAmount">
            {{ Number(classDeductAmountInfo.DeductAmount) }}
            <span>元</span>
          </div>
          <div class="consumption_class_hours a_num"
               :title="classDeductAmountInfo.DeductCount">消耗课时：

            {{ Number(classDeductAmountInfo.DeductCount) }}
          </div>
        </div>
        <!-- 正课课消单价 -->
        <div class="forward_course_cancellation f_c_flex"
             style="background: #E7E1FB;box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04);">
          <div class="course_cancellation_text c_text">正课课消单价</div>
          <div class="course_cancellation_num c_num"
               :title="classDeductAmountInfo.UnitPrice">
            {{ Number(classDeductAmountInfo.UnitPrice) }}
            <span>元/课时</span>
          </div>
        </div>
        <!-- 本月排课 -->
        <div class="month_course_scheduling">
          <!-- 1 -->
          <div class="scheduling_box month_flex">
            <div class="course_scheduling_text s_text_flex">本月排课</div>
            <div class="scheduling_schedulel schedulel_flex"
                 :style="{'width':Number(monthAttendanceInfo.ThisMonthArrangedNumCount)?'150px':'0px'}"></div>
            <div class="scheduling_num people_flex">{{Number(monthAttendanceInfo.ThisMonthArrangedNumCount)||0}}人次</div>
          </div>
          <!-- 2 -->
          <div class="current_arrive_box month_flex">
            <div class="current_arrive_text s_text_flex">当前应到</div>
            <div class="arrive_schedulel schedulel_flex"
                 :style="{'width':Number(monthAttendanceInfo.ArrangedNumCount)?(monthAttendanceInfo.ArrangedNumCount/monthAttendanceInfo.ThisMonthArrangedNumCount)*150+'px':'0px'}"></div>
            <div class="arrive_num people_flex">{{Number(monthAttendanceInfo.ArrangedNumCount)||0}}人次</div>
          </div>
          <!-- 3 -->
          <div class="current_actual_box month_flex">
            <div class="current_actual_text s_text_flex">当前实到</div>
            <div class="actual_schedulel schedulel_flex"
                 :style="{'width':Number(monthAttendanceInfo.ArrangedNumCount)?(monthAttendanceInfo.ArrivedNumCount/monthAttendanceInfo.ThisMonthArrangedNumCount)*150+'px':'0px'}"></div>
            <div class="actual_num people_flex">{{Number(monthAttendanceInfo.ArrivedNumCount)||0}}人次</div>
          </div>
        </div>
        <!-- 正课课时结余 -->
        <div class="all_students_info">
          <div class="balance_box">
            <div class="only_class_hour_balance c_h_b_flex">
              <div class="class_hour_balance_title">单课课时结余</div>
              <div class="balance_num"
                   :title='Number(scheduleInfo.ClassHourBalance.YesClassHourBalance)+"元"'><span class="b_num">{{Number(scheduleInfo.ClassHourBalance.YesClassHourBalance)}}</span><span>元</span> </div>
            </div>
            <div class="combination_class_hour_balance c_h_b_flex"
                 style="margin-top: 10px;">
              <div class="class_hour_balance_title">组合课时结余</div>
              <div class="balance_num"
                   :title='Number(scheduleInfo.ClassHourBalance.NoClassHourBalance)+"元"'><span class="b_num">{{Number(scheduleInfo.ClassHourBalance.NoClassHourBalance)}}</span><span>元</span> </div>
            </div>
          </div>
          <div style="position: absolute;left: 170px;top: 0;">
            <!-- 1 -->
            <div class="scheduling_box month_flex"
                 style="width: 225px;">
              <div class="course_scheduling_text s_text_flex">正课课时结余</div>
              <div class="scheduling_schedulel schedulel_flex m_c_s"
                   :style="{'width':Number(scheduleInfo.AllTypeClassHour.formalClassCount)?'150px':'0px'}"></div>
              <div class="scheduling_num people_flex"
                   style="margin-left: 80px;">{{Number(scheduleInfo.AllTypeClassHour.formalClassCount)}}课时</div>
            </div>
            <!-- 2 -->
            <div class="current_arrive_box month_flex"
                 style="width: 225px;">
              <div class="current_arrive_text s_text_flex">赠送课时结余</div>
              <div class="arrive_schedulel schedulel_flex m_c_s"
                   :style="{'width':Number(scheduleInfo.AllTypeClassHour.formalClassCount)?(Number(scheduleInfo.AllTypeClassHour.PresentedCount)/Number(scheduleInfo.AllTypeClassHour.formalClassCount))*150+'px':Number(scheduleInfo.AllTypeClassHour.PresentedCount)?'150px':'0px'}"></div>
              <div class="arrive_num people_flex"
                   style="margin-left: 80px;">{{Number(scheduleInfo.AllTypeClassHour.PresentedCount)}}课时</div>
            </div>
            <!-- 3 -->
            <div class="current_actual_box month_flex"
                 style="width: 225px;">
              <div class="current_actual_text s_text_flex">引流课时结余</div>
              <div class="actual_schedulel schedulel_flex m_c_s"
                   :style="{'width':Number(scheduleInfo.AllTypeClassHour.formalClassCount)?(Number(scheduleInfo.AllTypeClassHour.DiversionCount)/Number(scheduleInfo.AllTypeClassHour.formalClassCount))*150+'px':Number(scheduleInfo.AllTypeClassHour.DiversionCount)?'150px':'0px'}"></div>
              <div class="actual_num people_flex"
                   style="margin-left: 80px;">{{Number(scheduleInfo.AllTypeClassHour.DiversionCount)}}课时</div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="right_table">
      <div class="general_course_summarizing_title right_table_box clearfix"
           style="display:block;">
        <!-- 类型 -->
        <div class="right_table_nav fl">
          <div class="nav_list"
               v-for="(item,index) in classHeadBox"
               :key="index"
               :class="{'opt':item.isShow}"
               @click="switchClassHead(item)">{{item.title}}
          </div>
        </div>
      </div>
      <div class="general_course_summarizing_title right_table_box no_border_bottom pr"
           style="justify-content:left"
           :class="arrangebtn.nameValue=='DC' || arrangebtn.nameValue=='TJFX'?'class_record_module':''">
        <div class="flex"
             v-if="arrangebtn.nameValue=='XSJB'">
          <div class="table_select_box"
               style="margin-right:20px">
            <div class="table_select_title">关注</div>
            <el-select v-model="defaultAttentionText"
                       placeholder="请选择"
                       ref="resetText"
                       @change="selectAttention">
              <el-option v-for="item in attention"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
          <!-- <div class="table_select_box"
               style="margin-right:0px">
            <div class="table_select_title">刷脸</div>
            <el-select v-model="defaultFaceRecognitionText"
                       placeholder="请选择"
                       ref="resetText"
                       @change="selectFaceRecognition">
              <el-option v-for="item in faceSwiping"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div> -->
        </div>
        <!-- 上课记录日期查询 -->
        <div v-if="arrangebtn.nameValue=='DC'"
             style="padding-top:13px">
          <div class="record_course_cancellation">
            <div class="course_record course_flex"
                 :class="isCourseInfo?'courseOpt':''"
                 @click.stop="courseInfoFun(true)">课次记录</div>
            <div class="course_cancellations course_flex"
                 :class="!isCourseInfo?'courseOpt':''"
                 @click.stop="courseInfoFun(false)">结转课消</div>
          </div>
          <date-range ref="saveDate"
                      :searchObj="attendClassDate"
                      :fromTitle="'选择日期'"
                      :minDate="vMinDate"
                      :maxDate="vMaxDate"
                      :isShowHistory="isShowHistory"
                      @changeDate="changeAttendClassDate"></date-range>
          <div class="change_history_data"
               @click.stop="historyBtn(historyName)">{{ historyName }}</div>
        </div>

        <div :style="arrangebtn.nameValue=='XJKB'?'width:100%':''">

          <!-- 循环班级 -->
          <div class="record_course_class_cancellation"
               v-if="arrangebtn.nameValue=='XJKB' && GradeClasslist.length > 0">
            <div v-for="(item,index) in GradeClasslist"
                 :key="index">
              <div class="course_record course_flex"
                   :title="item.GradeClassKeyValue"
                   :class="GradeClassIndex == index?'courseOpt':''"
                   style="border-radius: 3px;margin-right: 20px;"
                   @click.stop="GradeClassClick(item,index)">{{ item.GradeClassKeyValue }}</div>
            </div>
          </div>

          <div class="course_search_form"
               v-if="arrangebtn.nameValue=='XJKB'">

            <div class="course_search_input">
              <input type="text"
                     v-model="paramsForStudent.searchText"
                     @keydown="keydownEvent($event)"
                     placeholder="请输入姓名、姓名首字母、手机号">
              <span v-show="paramsForStudent.searchText"
                    class="home_remove_btn"
                    @click="paramsForStudent.searchText=''"></span>
            </div>
          </div>

          <div v-if="arrangebtn.nameValue!='XJKB' && arrangebtn.nameValue!='TJFX'">
            <div style="margin-left: 30px;margin-top: 12px;">
              <input-search-contain-btn v-model="paramsForStudent.searchText"
                                        :placeholder="vplaceholder"
                                        :width="'286px'"
                                        @onSearchEvent="searchStudent"
                                        @clearSearch="clearSearchCondition"></input-search-contain-btn>
            </div>
          </div>
          <div v-if="arrangebtn.nameValue =='XJKB'">
            <function-btn :item="arrangebtn"
                          @clickItem="addToStudent"
                          style="position: absolute;right: 0px;top: 0;"
                          :className="'right_top_btn btn_hover_bg_blue fr'">
            </function-btn>
          </div>
          <div v-if="arrangebtn.nameValue =='DC'">
            <function-btn :item="arrangebtn"
                          @clickItem="addToStudent"
                          style="position: absolute;right: 0px;top: 0;width: 55px"
                          :className="'right_top_btn btn_hover_bg_blue fr'">
            </function-btn>
          </div>
          <div v-if="arrangebtn.nameValue =='XSJB'"
               class="">
            <function-btn v-if="arrangebtn.nameValue =='XJKB'"
                          :item="arrangebtn"
                          @clickItem="addToClass"
                          @mouseover.native="addToClass"
                          @mouseleave.native="closeStudentAddClass"
                          style="position: absolute;top: 0;right: 95px;width: 60px"
                          :className="'right_top_btn btn_hover_bg_blue fr'">
            </function-btn>

            <function-btn v-if="arrangebtn.nameValue =='XSJB'"
                          :item="{name:'学生导出',ModulePowerKey:121}"
                          @clickItem="studentExport"
                          style="position: absolute;right: 0;top: 0;width: 90px"
                          :className="'right_top_btn btn_hover_bg_blue fr'">
            </function-btn>
            <!-- <pop-up-drop-down-box :style="{ zIndex: 14 }"
                                  :isShowDialog="isShowStudentAddClass"
                                  :dropDownList="studentAddClassSelect"
                                  :positionLeft="836"
                                  :positionTop="47"
                                  @onClick="selectStudentAddClass"
                                  @mouseover.native="isShowStudentAddClass = true"
                                  @mouseleave.native="closeStudentAddClass"></pop-up-drop-down-box> -->
          </div>
        </div>
        <!-- 结余统计 -->
        <div v-if="arrangebtn.nameValue=='TJFX'"
             style="padding-top:13px">
          <div class="record_course_cancellation">
            <div class="course_record course_flex"
                 :class="isCourseBalance?'courseOpt':''"
                 @click.stop="courseBalanceFun(true)">课时结余</div>
            <div class="course_cancellations course_flex"
                 :class="!isCourseBalance?'courseOpt':''"
                 @click.stop="courseBalanceFun(false)">天数结余</div>
          </div>
        </div>
        <div v-if="arrangebtn.nameValue=='TJFX'">
          <div style="margin-top: 12px;">
            <input-search-contain-btn v-model="courseBalanceInfo.searchText"
                                      placeholder="请搜索学生名"
                                      :width="'286px'"
                                      @onSearchEvent="searchCourseStatistics"
                                      @clearSearch="clearSearchCondition"></input-search-contain-btn>
          </div>

          <div class="mendian_backstage_search">
            <div class="btn_hover_bg_blue search_btn "
                 style="margin: 0;position: absolute;right: 0;top: 12px;"
                 @click.stop="doExportInfoClick">导出</div>
          </div>
        </div>
      </div>
    </div>
    <students-list ref="studentsList"
                   :formTitle="'批量进班'"
                   @onAddStudent="addStudentClass"
                   @onDeleteStudent="quitStudentClass"></students-list>

  </div>
</template>

<script>
import {
  GetClassStatisticsForGrade,
  GetClassHistoryStatisticsForGrade,
  studentAddClass,
  QuitStudentFromClass,
  MessageSearchArrangedPageForGradeStudent,
  MessageSearchClassHourBalanceReportThisMonth, // 课时结余统计
  MessageSearchThisMonthAttendanceReportForCourse, // 本月应到实到统计
  MessageSearchThisMonthClassHourDeductCostReport, // 本月结转课消和正课课消单价统计
  MessageSearchWeekCourseArrangeReport // 课程信息
} from '../../../../API/workbench.js';
import summaryDataItem from '../../summary-data/item';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import popUpDropDownBox from '../../../common/pop-up-drop-down-box';
import studentsList from './students-list';
import classStudentsForm from './class-students-form';

export default {
  data () {
    return {
      GradeClassIndex: 0,
      isCourseInfo: true, // true 课次记录 false 结转课消
      isCourseBalance: true, // true课时结余/false天数结余
      classType: 1,
      gradeClassInfo: {},
      scheduleInfo: {
        AllTypeClassHour: {
          DiversionCount: '0',
          PresentedCount: '0',
          formalClassCount: '0'
        },
        ClassHourBalance: {
          NoClassHourBalance: '0',
          YesClassHourBalance: '0'
        }
      },
      monthAttendanceInfo: {},
      classDeductAmountInfo: {},
      historyBigName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isBigShowHistory: false, // true 2022前/false 2022后
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      defaultAttentionText: '全部',
      defaultFaceRecognitionText: '全部',
      attention: [
        { value: '全部', id: '' },
        { value: '已关注', id: 1 },
        { value: '未关注', id: 0 }
      ],
      faceSwiping: [
        { value: '全部', id: '' },
        { value: '已录入', id: 1 },
        { value: '未录入', id: 0 }
      ],
      // 班级课统计日期查询
      classStatisticsForGrade: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd'),
        GradeID: ''
      },
      // 上课记录日期查询
      attendClassDate: {
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd'),
        GradeID: ''
      },
      isTemporaryStu: false,  // 是否开启临时学生
      headSummaryData: [
        { title: '课次', value: '', className: '', SpecialFilter: '0', dataType: 'number', decimalPlace: 0 },
        { title: '学生课时', value: '', type: 'Num_NotEnought', className: '', SpecialFilter: '3', dataType: 'number', decimalPlace: 0 },
        { title: '课消金额(元)', value: '', type: 'Num_UsedUpClassCount', className: '', SpecialFilter: '2', dataType: 'number', decimalPlace: 0 },
        { title: '实到/应到', value: '', className: '', SubscribeKey: '1', dataType: 'number' },
        { title: '出勤率', value: '', type: 'Num_UnSubscribe', className: '', SubscribeKey: '0', dataType: 'number' }
      ],
      isShowStudentAddClass: false,
      studentAddClassSelect: [
        {
          id: 'add',
          name: '学生进班',
          ModulePowerKey: 51,
          isShow: true
        },
        {
          id: 'batch',
          name: '学生批量进班',
          ModulePowerKey: 51,
          isShow: true
        }
      ],
      isShowBatchStudentAddClass: false
    };
  },
  props: {
    classInfo: Object,
    classHeadBox: Array,
    paramsForStudent: Object,
    courseBalanceInfo: Object,
    arrangebtn: Object,
    GradeClasslist: Array
  },
  components: {
    summaryDataItem,
    inputSearchContainBtn,
    popUpDropDownBox,
    studentsList,
    classStudentsForm
  },
  computed: {
    vtipstitle () {
      return '月报名金额';
    },
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vMaxDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 2));
      let year = nowDate.getFullYear();
      let month = '12';
      let day = '31';
      let hour = '00';
      let minute = '00';
      let second = '00';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMaxDate', dateStr);
      return dateStr;
    },
    vplaceholder () {
      if (this.arrangebtn.nameValue == 'XSJB') { // 学生进班
        return '请输入姓名、姓名首字母、手机号';
      } else if (this.arrangebtn.nameValue == 'DC') { // 导入
        return '请输入课程、老师';
      }
    }
  },
  watch: {
    'classInfo.OLAPKey': {
      handler (n, o) {
        console.log(n, '班级id-123');
        if (Number(n) > 0) {
          this.getClassStatisticsForGrade(n);
        }
      },
      // deep: true,
      immediate: true
    }
  },
  created () {
    console.log(this.GradeClasslist, 'GradeClasslist66');
    this.$dialog.register(this, ['squadClassSetting']);
    // this.getClassStatisticsForGrade();
  },
  mounted () {
    this.getMessageSearchGradeCourseArrangeRepost();
  },
  methods: {
    // 班级 按钮
    GradeClassClick (item, index) {
      this.GradeClassIndex = index;
      console.log(item, 'item');
      this.$emit('GradeClassClick', item);
    },
    courseInfoFun (bool) {
      this.paramsForStudent.searchText = '';
      this.paramsForStudent.pageIndex = 0;
      this.isCourseInfo = bool;
      this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      this.attendClassDate.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      this.attendClassDate.endTime = this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd');
      this.isShowHistory = false;
      console.log(this.paramsForStudent, 'courseInfoFun', this.isCourseInfo);
      this.$emit('courseInfoFun', this.attendClassDate.startTime, this.attendClassDate.endTime, this.isShowHistory, this.isCourseInfo);
    },
    courseBalanceFun (bool) {
      this.courseBalanceInfo.searchText = '';
      this.courseBalanceInfo.pageIndex = 0;
      this.isCourseBalance = bool;
      this.$emit('courseBalanceFun', this.isCourseBalance);
    },
    doExportInfoClick () {
      this.$emit('doExportInfoClick', this.isCourseBalance);
    },
    getMessageSearchGradeCourseArrangeRepost () {
      console.log(this.classInfo, 'this.classInfo.OLAPKey123');
      MessageSearchWeekCourseArrangeReport(this.classInfo.OLAPKey).then(res => {
        this.gradeClassInfo.WeekClassArrangeCount = Number(res.data.WeekClassArrangeCount) ? Number(res.data.WeekClassArrangeCount) : '-';
        this.gradeClassInfo.StudentCount = Number(res.data.StudentCount) ? Number(res.data.StudentCount) : '-';
        this.gradeClassInfo.FormalClassStudentCount = Number(res.data.FormalClassStudentCount) ? Number(res.data.FormalClassStudentCount) : '-';
        this.gradeClassInfo.DiversionStudentCount = Number(res.data.DiversionStudentCount) ? Number(res.data.DiversionStudentCount) : '-';
        console.log(res.data, 'getMessageSearchGradeCourseArrangeRepost11');
      }, err => {
        layer.alert(err.msg);
      });
      MessageSearchClassHourBalanceReportThisMonth(this.classInfo.OLAPKey).then(res => {
        this.scheduleInfo = res.data;
        console.log(res.data, 'MessageSearchClassHourBalanceReportThisMonth');
      }, err => {
        layer.alert(err.msg);
      });
      MessageSearchThisMonthAttendanceReportForCourse(this.classInfo.OLAPKey).then(res => {
        this.monthAttendanceInfo = res.data[0];
        console.log(res.data, 'MessageSearchThisMonthAttendanceReportForCourse');
      }, err => {
        layer.alert(err.msg);
      });
      MessageSearchThisMonthClassHourDeductCostReport(this.classInfo.OLAPKey).then(res => {
        this.classDeductAmountInfo = res.data;

        console.log(this.classDeductAmountInfo, 'this.classDeductAmountInfo');
        console.log(res.data, 'MessageSearchThisMonthClassHourDeductCostReport');
      }, err => {
        layer.alert(err.msg);
      });
      console.log('this.scheduleInfo ', this.scheduleInfo);
    },
    historyBtn (name) {
      console.log(this.isShowHistory, 'isShowHistory');
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.attendClassDate.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.attendClassDate.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isShowHistory = true;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.attendClassDate.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.attendClassDate.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
        this.isShowHistory = false;
        this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.$emit('getDataList', this.attendClassDate.startTime, this.attendClassDate.endTime, this.isShowHistory);
      this.$refs.saveDate.changeHistoryTime(this.attendClassDate.startTime, this.attendClassDate.endTime);
    },
    historyBigBtn (name) {
      console.log(this.isBigShowHistory, 'isBigShowHistory', name);
      if (name == new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前') {
        this.classStatisticsForGrade.startTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-01';
        this.classStatisticsForGrade.endTime = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).getFullYear() + '-12-31';
        this.isBigShowHistory = true;
        this.historyBigName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号后';
      } else {
        this.classStatisticsForGrade.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
        this.classStatisticsForGrade.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
        this.isBigShowHistory = false;
        this.historyBigName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      }
      this.getClassStatisticsForGrade();
      this.$refs.saveDate.changeHistoryTime(this.classStatisticsForGrade.startTime, this.classStatisticsForGrade.endTime);
    },
    addStudentClass (item, completeCallback, isAllowClassHourLackKey, errorCallback) {
      console.log(item, 'QuitStudentFromClass');
      studentAddClass(item.OLAPKey, this.classInfo.OLAPKey).then(res => {
        this.$emit('upForStudentList');
      }, err => {
        errorCallback();
        layer.alert(err.msg);
      });
    },
    quitStudentClass (item, index, completeCallback) {
      QuitStudentFromClass(item.StudentKey, this.classInfo.OLAPKey).then(res => {
        completeCallback();
        this.$emit('upForStudentList');
      }, err => {
        layer.alert(err.msg);
      });
    },
    selectStudentAddClass (e) {
      if (e.id == 'add') {
        this.$emit('addToClass', this.arrangebtn);
      } else if (e.id == 'batch') {
        let params = this.$utils.parseJson(this.paramsForStudent);
        params.pageIndex = 0;
        params.pageSize = 0;

        MessageSearchArrangedPageForGradeStudent(params).then(result => {
          console.log(result.data, '班内学生');
          let studentList = result.data.PageDataList;
          this.isShowBatchStudentAddClass = true;
          this.$refs.studentsList.isShowAddStudent = true;
          this.$refs.studentsList.pushDataStudentList(studentList);
        }, error => {
          layer.alert(error.msg);
        });
      }
    },
    closeStudentAddClass () {
      this.isShowStudentAddClass = false;
    },
    getClassStatisticsForGrade (classkey) {
      let fn = '';
      if (this.isBigShowHistory) {
        fn = GetClassHistoryStatisticsForGrade;
      } else {
        fn = GetClassStatisticsForGrade;
      }
      console.log(this.classInfo, '课程课统计');
      this.classStatisticsForGrade.GradeID = this.classInfo.OLAPKey || classkey;
      if (this.classStatisticsForGrade.GradeID) {
        fn(this.classStatisticsForGrade).then(result => {
          console.log(result.data, 'GetClassStatisticsForGrade');
          this.headSummaryData[0].value = result.data.CourseNum;// 课次
          this.headSummaryData[1].value = result.data.ClassCount;// 学生课时
          this.headSummaryData[2].value = result.data.Amount;// 课消金额(元)
          if (Number(result.data.ArrivedNumCount) > 0 || Number(result.data.ArrangedNumCount) > 0) {
            this.headSummaryData[3].dataType = 'text';
            this.headSummaryData[3].value = Number(result.data.ArrivedNumCount) + '/' + Number(result.data.ArrangedNumCount);// 实到/应到
          } else {
            this.headSummaryData[3].dataType = 'number';
            this.headSummaryData[3].value = '';
          }
          if (Number(result.data.AttendanceRate) > 0) {
            this.headSummaryData[4].dataType = 'text';
            this.headSummaryData[4].value = this.$utils.mAmountType(Number(result.data.AttendanceRate) * 100, 2) + '%';// 出勤率
          } else {
            this.headSummaryData[4].dataType = 'number';
            this.headSummaryData[4].value = '';
          }
          console.log(result.data, '课程课统计');
        });
      }
    },
    // 上课记录日期查询
    changeAttendClassDate (dataInfo) {
      console.log(dataInfo, '上课记录日期查询');
      this.$emit('changeAttendClassDate', dataInfo);
    },
    // 查询 关注
    selectAttention (val) {
      let findItem = this.attention.find(o => {
        return o.value == val;
      });
      this.paramsForStudent.SubscribeKey = findItem ? findItem.id : '';
      this.$emit('searchStudent');
    },
    // 查询 刷脸
    selectFaceRecognition (val) {
      let findItem = this.faceSwiping.find(o => {
        return o.value == val;
      });
      this.paramsForStudent.FaceRecognition = findItem ? findItem.id : '';
      this.$emit('searchStudent');
    },
    // 修改课程
    onEditClassInfo () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '修改课程',
        moduleName: 'courseNameSetting',
        data: { editItem: this.classInfo, isReadonly: false },
        callBack: {
          afterSuccess: (type, data) => {
            this.getMessageSearchGradeCourseArrangeRepost();
            this.addSuccessEvent('Edit');
          }
        }
      });
    },
    // 修改课程成功后回调
    addSuccessEvent (type) {
      this.$emit('addSuccessEvent', type, this.classInfo);
    },
    // 切换班级排课类型
    switchClassHead (item) {
      this.$emit('doSwitchClassHead', item);
    },
    // 搜索
    searchStudent () {
      this.$emit('searchStudent', this.paramsForStudent);
    },
    // 课程结余统计搜索
    searchCourseStatistics () {
      this.$emit('searchCourseStatistics', this.courseBalanceInfo);
    },
    // 清除
    clearSearchCondition () {
      this.$emit('clearSearchCondition', this.isCourseInfo, this.isCourseBalance);
      this.externalClearSearchCondition();
    },
    // 外部清除
    externalClearSearchCondition () {
      this.GradeClassIndex = 0;
      this.defaultAttentionText = '全部';
      this.defaultFaceRecognitionText = '全部';
      // this.isCourseInfo = true;// 上课记录
      // this.isCourseBalance = true;// 结余统计
      this.historyName = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前';
      this.attendClassDate.startTime = '';
      this.attendClassDate.endTime = '';
      this.isShowHistory = false;
      if (this.$refs.resetText) {
        this.$refs.resetText.blur();
      }
    },
    addToStudent () {
      this.$emit('addToClass', this.arrangebtn);
    },
    // 按钮
    addToClass () {
      if (this.arrangebtn.nameValue == 'XSJB' && this.classInfo.IsSyncGradeClassKey == 1) {
        this.studentAddClassSelect[1].ModulePowerKey = 51;
      } else {
        this.studentAddClassSelect[1].ModulePowerKey = -1;
      }
      this.isShowStudentAddClass = true;
      console.log(this.arrangebtn.nameValue, '按钮');
    },
    // 班级学生导出
    studentExport () {
      let arrangebtn = {
        name: '班级学生导出', nameValue: 'XSDC', ModulePowerKey: ''
      };
      this.$emit('addToClass', arrangebtn);
    },
    // 更改是否离校学生状态.
    changeStatus (val) {
      this.$emit('changeStatus', val);
      // console.log(this.isTemporaryStu, 'changeStatus', val);
    },
    // 回车搜索
    keydownEvent (e) {
      switch (e.keyCode) {
        case 13: // 回车
          this.searchStudent();
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style scoped>
.attend_class_title {
  color: #333;
  font-family: Alibaba PuHuiTi;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.change_history_data {
  position: absolute;
  right: 80px;
  top: 13px;
  line-height: 34px;
  width: 120px;
  height: 36px;
  text-align: center;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #2e86c1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
.general_info {
  width: 150px;
  text-align: center;
  border-right: 1px solid #dce3eb;
  height: 20px;
  margin-top: 3px;
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #999;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.general_info span {
  color: #333;
}
.studen_attend_class span {
  border-radius: 3px;
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  width: 28px;
  line-height: normal;
  display: inline-block;
}
.open_attend_class {
  background: rgb(30, 208, 122, 0.2);
  color: #1ed07a !important;
}
.close_attend_class {
  background: rgba(225, 108, 103, 0.2);
  color: #ff6c67 !important;
}
.studen_content_box {
  display: flex;
  justify-content: space-between;
}
.f_c_flex {
  width: 145px;
  height: 124px;
  flex-shrink: 0;
  border-radius: 8px;
  text-align: center;
  line-height: 40px;
}
.month_course_scheduling {
  width: 240px;
  height: 124px;
}
.all_students_info {
  width: 400px;
  height: 124px;
  position: relative;
}
.all_students_info >>> .echarts_m div canvas {
  width: 240px !important;
  height: 140px !important;
}
.c_text {
  color: #666;
  text-align: center;
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}
.c_num {
  color: #333;
  font-family: Arial;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  height: 47px;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.c_num span {
  font-size: 14px;
}
.a_num {
  width: 111px;
  height: 26px;
  margin: 0 auto;
  border-radius: 100px;
  color: #fff;
  text-align: center;
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 26px;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.consumption_class_hours {
  background: rgb(180, 152, 253, 0.5);
}
.attendance_num {
  background: rgb(250, 185, 117, 0.5);
}
.month_flex {
  position: relative;
  height: 46px;
}
.s_text_flex {
  color: #999;
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}
.schedulel_flex {
  width: 150px;
  height: 8px;
  position: absolute;
  top: 0px;
  left: 60px;
  max-width: 180px;
}
.scheduling_schedulel {
  background: linear-gradient(90deg, #a9d0ff 0%, #0075ff 80.24%);
}
.arrive_schedulel {
  background: linear-gradient(90deg, #ffe49b 0%, #fb0 80.24%);
}
.actual_schedulel {
  background: linear-gradient(90deg, #c0f8e3 0%, #12cd88 80.24%);
}
.people_flex {
  color: #333;
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: 60px;
}
.studen_attendance_box {
  margin-top: 10px;
}
.m_c_s {
  left: 80px;
  max-width: 150px;
}
.schedulel_bj {
  width: 150px;
  height: 8px;
  position: absolute;
  top: 5px;
  left: 80px;
  background: #e2eefe;
}
.details_text {
  position: absolute;
  top: 0px;
  right: -5px;
  cursor: pointer;
  color: #3498db;
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}
.full_shift_rate {
  position: absolute;
  top: 18px;
  right: -5px;
  color: #333;
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}
.class_record_module {
  margin-top: 50px;
}
.record_course_cancellation {
  position: absolute;
  top: -54px;
  display: flex;
  width: 230px;
  justify-content: space-between;
  margin-top: 18px;
}
.record_course_class_cancellation {
  width: 930px;
  overflow-x: auto;
  display: -webkit-box;
  margin-top: 18px;
  padding-bottom: 10px;
}
.record_course_class_cancellation::-webkit-scrollbar {
  height: 4px;
}
.record_course_class_cancellation::-webkit-scrollbar-thumb {
  border-radius: 2.5px;
  background-color: #cdd1d8;
}
.record_course_class_cancellation::-webkit-scrollbar-track {
  background-color: #f6f6f6;
}
.course_flex {
  width: 115px;
  line-height: 30px;
  border: 1px solid #ececec;
  text-align: center;
  position: relative;
  cursor: pointer;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.course_record {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.course_cancellations {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.courseOpt {
  color: #3498db;
  border-color: #3498db;
  background-color: rgba(52, 152, 219, 0.15);
  z-index: 2;
}
.general_class_alter {
  background: url(../../../../../public/image/edit_icon.png) no-repeat 0 !important;
  background-size: 15px !important;
}
.balance_box {
  width: 145px;
  height: 124px;
}
.c_h_b_flex {
  background: rgba(253, 243, 233, 1);
  width: 145px;
  height: 60px;
  border-radius: 8px;
  position: relative;
}
.class_hour_balance_title {
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #666;
  left: 37px;
  top: 7px;
  position: absolute;
}
.balance_num {
  font-family: Arial;
  font-weight: 400;
  text-align: center;
  line-height: 95px;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.balance_num .b_num {
  font-size: 30px;
}
.balance_num span {
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
</style>