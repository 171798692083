<template>
  <div class="defect_warning content_marginTop_30 content_marginRL single_shadow overflow_hidden">
    <div class="school_warning_content scroll_y">
      <div
        class="school_warning_content_list content_list_line_height"
        v-for="(item,key) in functionList"
        :key="key"
        @click="clickEvent(item)"
      >
        <div
          class="school_warning_content_list_left content_list_line_height"
          style="text-align: center;font-size: 20px;"
        >{{item.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    functionList: Array
  },
  methods: {
    clickEvent(item) {
      this.$emit("clickEvent", item);
    }
  }
};
</script>

<style>
</style>