<template>
  <div class="content_item pr">
    <div class="courseware_list"
         @mouseleave.stop="mouseOutEvent">
      <div class="max_list_box">
        <div class="list_img"
             style="text-align: center"
             @mouseenter="mouseOverEvent">

          <div class="list_number">{{dataItem.ShowOrder}}</div>
          <!-- 大课包 显示... -->
          <div class="pa_status_type">
            <div class="status_type_red"
                 v-if="publishLogInfo.PublishStatus!=2&&coursewareInfo.PublishStatusKey == 1 && dataItem.PublishStatusKey == 0">
              有更新
            </div>
            <div class="pa_status_type_02 "
                 :title="'文件字体缺失'"
                 v-if="Number(dataItem.IsInsetFontKey)==0&&dataItem.SettingTemplate.pptFontCheck==1">
              <!-- <input-form-tip :tips="'文件未嵌入字体'"></input-form-tip> -->
              <img :src="require('../../../../public/image/ppt_font_d.png')"
                   class="ppt-font-d">
              <span style="margin-right: 5px;">字体</span>
            </div>
          </div>

          <div class="list_img_btn_tomove mover"
               v-if="isShowBtn&&coursewareInfo.PublishStatusKey == 0"
               @mousedown.stop="moveStart"
               @mouseup="stopMove">

          </div>

          <div class="list_img_btn"
               v-if="isShowBtn&&publishLogInfo.PublishStatus!=2"
               @mouseenter="showOperateMenu"
               @mouseleave="isShowOperateMenu=false">
            <div class="list_img_ul"
                 v-if="isShowOperateMenu">
              <!-- 大课包按钮显示 -->
              <div>
                <div class="list_img_menu type_02"
                     style="padding-left:12px"
                     @click.stop="coursewareEdit">
                  编辑
                </div>
                <div class="list_img_menu type_04"
                     style="padding-left:12px"
                     v-if="coursewareInfo.PublishStatusKey == 1 && this.dataItem.PublishStatusKey == 0"
                     @click.stop="coursewareRestore">
                  还原
                </div>
                <div class="list_img_menu type_03"
                     style="padding-left:12px"
                     v-if="coursewareInfo.PublishStatusKey ==0"
                     @click.stop="coursewareDelete">
                  删除
                </div>
              </div>

            </div>
          </div>
          <div class="courseware_packge_list_item_bg"></div>
          <img class="img_box"
               style="height: 150px;width: auto;"
               :src="vCoverImg"
               :onerror="defaultImg"
               alt="">
          <div class="begin_class_img_content"
               v-if="isShowBtn">
            <img class="begin_class_img"
                 src="../../../../public/image/teaching_mode_beginclass.png"
                 alt=""
                 @click.stop="showCourseware">
            <span style="z-index:1;cursor: pointer;"
                  @click.stop="showCourseware">预览</span>
          </div>
        </div>
        <div class="max_list_info">
          <div class="list_name"
               style="letter-spacing: 0.5px;font-size:16px">
            {{dataItem.MainDemoName}}
          </div>
          
        </div>
         <div class="max_list_info">
          <div class="list_name list_text" style="font-size:14px">
            {{vStepText}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isShowBtn: false, // 显示按钮
      isShowOperateMenu: false, // 显示菜单
      isShowMenuBeing: true, // 是否已是移出状态
      publishLogInfo: { PublishStatus: '-1' },
      lastClickTime: null
    };
  },
  props: {
    type: String,
    coursewareInfo: Object,
    dataItem: Object,
    logInfo: Object,
    coursewareInfoType: [String, Number]
  },
  computed: {
    vStepText(){
      let text=''
      let SettingTemplate =this.dataItem.SettingTemplate||{}
      // homeworkAfter: 0    //作业
      // lessonCommentary: 0   //话术
      // procedures: 1   //教案
      text=this.dataItem.briefDesc
      // if(SettingTemplate.filesData==1){
      //   text='文件'
      // } if(SettingTemplate.procedures==1){
      //   text=text+(text?'·'+'教案':'教案')
      // } if(SettingTemplate.lessonCommentary==1){
      //   text=text+(text?'·'+'话术':'话术')
      // } if(SettingTemplate.homeworkAfter==1){
      //   text=text+(text?'·'+'作业':'作业')
      // }
      return text
    },
    vClassName () {
      // 课包级发布状态(0-未发布;1-已发布)
      console.log(this.coursewareInfo, '课包信息');
      if (this.coursewareInfo.PublishStatusKey == 0) {
        return 'button mover';
      } else if (this.coursewareInfo.PublishStatusKey == 1 && this.dataItem.PublishStatusKey == 0) { //	课件级发布状态0：草稿状态 1：发布状态
        return 'type_blue';
      } else if (this.coursewareInfo.PublishStatusKey == 1 && this.dataItem.PublishStatusKey == 1) {
        return 'type_tick';
      }
    },
    // 非自研课件时不显示添加按钮
    vCoverImg () {
      if (this.dataItem.CoverUrl) {
        return this.$store.getters.CDNURL + this.dataItem.CoverUrl;
      } else {
        return '';
      }
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../public/image/couseware_default.png') + '"';
    }
  },
  created () {
    if (this.logInfo && this.logInfo.PublishStatus) {
      this.publishLogInfo = this.logInfo;
    }
  },
  watch: {
    // 发布状态（2：发布中 1：可以发布 0：不能发布（按钮灰色））
    'logInfo': {
      handler (n, o) {
        console.log(n, '发布按钮22');
        this.publishLogInfo = n;
      },
      immediate: true
    }
  },
  methods: {
    // 鼠标移入,显示按钮
    mouseOverEvent () {
      // console.log(this.dataItem, '鼠标移入');
      this.isShowBtn = true;

    },
    moveStart () {
      this.$emit("moveStart", this.dataItem)
    },
    stopMove () {
      this.$emit("stopMove", this.dataItem)
    },
    // 鼠标离开
    mouseOutEvent () {
      this.isShowBtn = false;
      this.isShowOperateMenu = false;
      console.log("mouseOutEvent", this.isShowOperateMenu)
    },
    // 点击事件
    selectEvent () {
      this.$emit('selectEvent', this.dataItem);
    },
    // 显示操作项
    showOperateMenu () {
      this.isShowOperateMenu = true;
      console.log("showOperateMenu", this.isShowOperateMenu)
      this.$emit('showOperateMenu', this.dataItem);
    },
    // 编辑
    coursewareEdit () {
      this.$emit('coursewareOperate', 'Edit', this.dataItem);
    },
    coursewareRestore () {
      this.$emit('coursewareOperate', 'Restore', this.dataItem);
    },
    // 删除
    coursewareDelete () {
      this.$emit('coursewareOperate', 'Delete', this.dataItem);
    },
    // 进入课件预览模式
    showCourseware () {
      // authCoursewareID 授权课件ID
      this.$router.push({ name: 'coursewareTeachingView', query: { type: 2, coursewareId: this.dataItem.OLAPKey, coursewareInfoType: this.coursewareInfoType, IsPCRecordKey: 1 } });
    }
  }
};
</script>

<style scoped>
.content_item .courseware_list .list_img_btn_tomove {
  position: absolute;
  right: 42px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: url(../../../../public/image/courseware_icon_01.png) no-repeat;
  z-index: 1;
}

.content_item .courseware_list .list_img_btn {
  position: absolute;
  right: 0px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: url(../../../../public/image/courseware_icon_04.png) no-repeat;
  background-size: 30px;
  z-index: 1;
}
.content_item .courseware_list .list_img_ul {
  position: absolute;
  right: 6px;
  top: 32px;
  width: 86px;
  background-color: #fff;
  padding-left: 0px;
  text-align: center;
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 6px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.02), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  z-index: 99;
}
.content_item .courseware_list .list_img_btn,
.content_item .courseware_list .list_img_btn_tomove {
  top: 8px;
  background-size: 30px;
}

.content_item .courseware_list {
  /* position: relative; */
  display: flex;
  width: 268px;
  height: 171px;
  float: left;
  margin: 0 15px 48px 0px;
}
.content_item .courseware_list .max_list_box {
  width: 268px;
  height: 151px;
  background: #fff;
  border-radius: 8px;
  /* padding: 8px; */
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-bottom: 20px;
}
.content_item .courseware_list .list_name {
  font-size: 14px;
  line-height: 46px;
}
.content_item .courseware_list .list_name.list_text {
  font-size: 14px;
  line-height: 14px;
  margin-top: -10px;
}

.content_item .courseware_list .list_img {
  width: 268px;
  height: 151px;
}
.content_item .courseware_list .list_btn {
  position: relative;
  width: 0px;
}
.content_item .courseware_list .list_left {
  position: absolute;
  left: -310px;
  right: 0px;
  height: 18px;
  /* cursor: pointer; */
  bottom: 0px;
  top: 250px;
  width: 10px;
  background: url(../../../../public/image/arrows_top.png) no-repeat center
    center;
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg); /* IE 9 */
  -moz-transform: rotate(-90deg); /* Firefox */
  -webkit-transform: rotate(-90deg); /* Safari 和 Chrome */
  -o-transform: rotate(-90deg); /* Opera */
}

.content_item .courseware_list .list_left:hover {
  background-image: url(../../../../public/image/arrows_top_blue.png);
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg); /* IE 9 */
  -moz-transform: rotate(-90deg); /* Firefox */
  -webkit-transform: rotate(-90deg); /* Safari 和 Chrome */
  -o-transform: rotate(-90deg); /* Opera */
}

.content_item .courseware_list .list_right {
  position: absolute;
  height: 18px;
  bottom: 0px;
  top: 250px;
  left: -30px;
  right: 0;
  /* cursor: pointer; */
  width: 10px;
  background: url(../../../../public/image/arrows_bottom.png) no-repeat center
    center;
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg); /* IE 9 */
  -moz-transform: rotate(-90deg); /* Firefox */
  -webkit-transform: rotate(-90deg); /* Safari 和 Chrome */
  -o-transform: rotate(-90deg); /* Opera */
}

.content_item .courseware_list .list_right:hover {
  background-image: url(../../../../public/image/arrows_bottom_blue.png);
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg); /* IE 9 */
  -moz-transform: rotate(-90deg); /* Firefox */
  -webkit-transform: rotate(-90deg); /* Safari 和 Chrome */
  -o-transform: rotate(-90deg); /* Opera */
}

.content_item .courseware_list .max_list_info {
  width: 100%;
  height: 50px;
  /* cursor: pointer; */
  font-size: 14px;
}
.content_item .auto_release_text {
  position: absolute;
  width: 60px;
  height: 24px;
  left: 225px;
  background: #ff6c67;
  border-radius: 5px;
  bottom: 10px;
  text-align: center;
  line-height: 24px;
  color: #ffffff;
  font-family: 'Alibaba';
  font-size: 12px;
}
.content_item .status_type_01 {
  background: #ffde2e !important;
}
.content_item .status_type {
  width: 76px;
  height: 36px;
  line-height: 36px;
  background: #ffeed0;
  border-radius: 5px;
  color: black;
  font-family: Alibaba;
  font-size: 14px;
  margin-left: 4px;
  float: right;
  text-align: center;
  margin-top: -8px;
}
.capacity {
  padding-left: 2px;
}
.pa_status_type {
  position: absolute;
  left: 40px;
  top: 12px;
  z-index: 1;
}
.pa_status_type_02 {
  width: 66px;
  height: 24px;
  border-radius: 5px;
  background: #f8d5d5;
  color: #d92d2d;
  font-family: Alibaba;
  font-size: 14px;
  margin-left: 10px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
  z-index: 1;
}
.status_type_red {
  width: 66px;
  height: 24px;
  border-radius: 5px;
  background: rgba(248, 213, 213, 1);
  color: rgba(217, 45, 45, 1);
  font-family: Alibaba;
  font-size: 14px;
  margin-left: 10px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
}
.content_item .courseware_list .list_number {
  width: 44px;
  height: 28px;
  line-height: 28px;
}
.courseware_packge_list_item_bg {
  /* background: linear-gradient(
    225.87deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 90.8%
  ); */
  /* opacity: 0.35; */
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('../../../../public/image/coureware_bg_highlight.png') center
    no-repeat;
  background-size: 100%;
}
.begin_class_img_content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;

  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
}
.begin_class_img {
  position: absolute;
  width: 66px;
  height: 66px;
  cursor: pointer;
}
.ppt-font-d {
  width: 22px;
  height: 22px;
  margin-left: 3px;
  margin-bottom: 2px;
}
.courseware_list .list_img:hover {
  cursor: default !important;
}
</style>
