<template>
  <!-- 公司信息 -->
  <div class="company_info_form">
    <div class="YK_added_content_form">
      <input-text-form :formTitle="'公司名称'"
                       :formPlaceholder="'请输入内容'"
                       :required="true"
                       v-model="submitData.CompanyName"></input-text-form>
      <input-text-form :formTitle="'公司地址'"
                       :formPlaceholder="'请输入内容'"
                       :required="true"
                       v-model="submitData.CompanyAddres"></input-text-form>
      <div class="form_list">
        <div class="form_list_name required">
          <span class="text_box">营业执照</span>
        </div>
        <div class="form_list_content">
          <div class="form_list_upload">
            <div class="upload_btn">
              <input @change="selectImg($event)"
                     class="upload_img"
                     type="file"
                     accept='image/*'>
              点击上传图片
              <img class="img_box"
                   v-if="submitData.BusinessLicense"
                   alt=""
                   :src="vBusinessLicense" />
            </div>
            <div class="upload_text">
              <div>1、请上传清晰、完整、无遮挡物的营业执照图片</div>
              <div>2、要求原件红章清晰，如为复印件需加盖公司公章</div>
              <div>3、图片大小不能超过4M，图片格式为jpg、jpeg、png</div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="YK_added_save_cancel_bottom_btn_box">
        <zx-button :btnClass="'btn_hover_bg_blue'"
                   :btnStype="' '"
                   :btnText="'下一步'"
                   :isSingleSubmit="false"
                   :singleSubmitType="'Static'"
                   @click="doSubmitClick"></zx-button>
      </div>
  </div>
</template> 
<script>
import inputTextForm from '../common/input-text-form';
import zxButton from '../../../common/zx-button';
import {
  AmendCompanyMsg,
  UploadFiles,
  GetCompanyMsg
} from '../../../../API/workbench';
export default {
  data () {
    return {
      submitData: {
        OLAPKey: 0, // 上架申请key
        CompanyName: '', // 公司名称,
        CompanyAddres: '', // 公司地址,
        BusinessLicense: '' // 营业执照,
      }
    };
  },
  props: {
    dataInfo: Object
  },
  components: {
    inputTextForm,
    zxButton
  },
  created () {
    this.initData();
  },
  computed: {
    vBusinessLicense () {
      if (this.submitData.BusinessLicense) {
        return this.$store.getters.CDNURL + this.submitData.BusinessLicense;
      } else {
        return '';
      }
    }
  },
  methods: {
    initData () {
      GetCompanyMsg().then(
        (result) => {
          this.submitData = result.data;
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
      if (Object.keys(this.dataInfo).length > 0) {
        for (let key in this.submitData) {
          this.submitData[key] = this.dataInfo[key];
        }
        console.log('this.submitData', this.submitData, this.dataInfo);
      }
    },
    selectImg (e) {
      console.log(e, 'e');
      let form = e.target.files[0];
      let mbSize = 1048576;
      if (form.size > mbSize * 4) {
        layer.alert('上传图片大于4MB，无法上传');
        return false;
      }
      var formData = new FormData();
      formData.append('file', form);
      UploadFiles(1, '', formData).then(
        (result) => {
          console.log(result.data.url, '截图地址');
          this.submitData.BusinessLicense = result.data.url;
        },
        (err) => {
          layer.alert(err.msg);
        }
      );
    },
    // 保存公司信息
    doSubmitClick () {
      let flag = true;
      if (
        !this.submitData.CompanyName ||
        !this.submitData.CompanyName.replace(/(^\s*)|(\s*$)/g, '')
      ) {
        layer.alert('请输入公司名称');
        flag = false;
      } else if (!this.submitData.CompanyAddres) {
        layer.alert('请输入公司地址');
        flag = false;
      } else if (!this.submitData.BusinessLicense) {
        layer.alert('请上传营业执照');
        flag = false;
      }
      if (!flag) {
        return false;
      }
      console.log('doSubmitClick', this.submitData);
      AmendCompanyMsg(this.submitData).then((result) => {
        this.$emit('saveSuccess', this.submitData, result.data);
      });
    }
  }
};
</script>

<style scoped>
.upload_img {
  height: 120px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 11;
}
.img_box {
  height: 120px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* opacity: 0; */
  z-index: 10;
}
.company_info_form .YK_added_save_cancel_bottom_btn_box{
  padding-top: 16px;
}
</style>