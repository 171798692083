<!-- 文本 -->
<template>
  <el-table-column :label="columnData.label"
                   :prop="columnData.prop"
                   :width="columnData.width"
                   :min-width="columnData.minWidth">
    <template slot="header"
              slot-scope="scope">
      <div class="all_workbench_left_serach">
        <div class="serach_input">
          <input class="input_box"
                 v-model="paramsForQuery.searchText"
                 placeholder="请输入"
                 type="text">
        </div>
        <div class="serach_btn btn_hover_bg_blue"
             @click="searchClick">
          搜索
        </div>
        <div class="serach_btn serach_btn_clear"
             :class="vIsChoiseOptions?'opt':''"
             @click="clearAllOptions">
          <!-- 搜索 -->
        </div>
      </div>
    </template>
    <template slot-scope="scope">
      <div>
        <div class="rank_label_box">
          <div class="investor_intentionKey rank_label"
               :class="setClassName(scope.row)">{{scope.row[columnData.extend.IntentionKeyValue]}}</div>
          <span class="intention_type">{{intentionValue(scope.row[columnData.extend.IntentionKeyValue])}}</span>
        </div>

        <div class="investor_name">
          <span>{{scope.row[columnData.prop].length>4?scope.row[columnData.prop].slice(0, 4)+'..':scope.row[columnData.prop]}}</span>
          <span class="type_status_color"
                v-if="scope.row[columnData.extend.StatusKeyValue]"
                :class="setStatusColor(scope.row[columnData.extend.StatusKey])">{{scope.row[columnData.extend.StatusKeyValue]}}</span>
          <span class="follow_time"
                v-if="scope.row[columnData.extend.NextFollowTime]">
            <span class="follow_value_time">计划跟进</span> {{followTime(scope.row[columnData.extend.NextFollowTime])}}</span>
        </div>
        <div class="investor_text">
          <span class="investor_phone">{{scope.row[columnData.extend.MobilePhone]}}</span>
          <span class="investor_address"
                v-if="scope.row[columnData.extend.ProvinceKeyValue]">{{' | '+scope.row[columnData.extend.ProvinceKeyValue]+'·'+scope.row[columnData.extend.CityKeyValue]}}</span>
        </div>
      </div>
    </template>
  </el-table-column>
</template>

<script>
// import tableBtn from '../../../../../../';// 操作
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String
        };
      }
    },
    paramsForQuery: Object
  },
  data () {
    return {

    };
  },
  created () {
  },
  computed: {
    vIsChoiseOptions () {
      if (this.paramsForQuery && (
        this.paramsForQuery.searchText ||
        this.paramsForQuery.IntentionKey > 0 ||
        this.paramsForQuery.StatusKey > 0 ||
        this.paramsForQuery.AddToDateStart ||
        this.paramsForQuery.AddToDateEnd)) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    setClassName (rowData) {
      let className = '';
      let type = rowData[this.columnData.extend.IntentionKeyValue];
      if (type == 'S') {
        className = 'rank_S';
      } else if (type == 'A') {
        className = 'rank_A';
      } else if (type == 'B') {
        className = 'rank_B';
      } else if (type == 'C') {
        className = 'rank_C';
      } else if (type == 'N') {
        className = 'rank_new';
      }
      return className;
    },
    intentionValue (type) {
      if (type == 'S') {
        return '高意向';
      } else if (type == 'A') {
        return '中意向';
      } else if (type == 'B') {
        return '有意向';
      } else if (type == 'C') {
        return '无意向';
      } else if (type == 'N') {
        return '新线索';
      }
    },
    followTime (date) {
      if (date) {
        let dateTime = date.replace(/-/g, '/').substring(0, 10);
        if (dateTime == this.$utils.formatDatet(Date.new(), 'yyyy/MM/dd')) {
          return '今天';
        } else {
          return date.slice(5, 10);
        }
      } else {
        return '';
      }
    },
    // 签单进度  0-待接收;1-新线索;2-跟进中;3-已预约;4-已考察;5-已签单;6-已付订;7-已全款
    setStatusColor (StatusKey) {
      console.log(StatusKey, 'StatusKey');
      if (Number(StatusKey) < 5) {
        return 'type_yellow';
      } else if (Number(StatusKey) == 5 || Number(StatusKey) == 6) {
        return 'type_aqua';
      } else if (Number(StatusKey) == 7) {
        return 'type_green';
      }
      // let className = '';
      // if (StatusKey == 0) {
      //   className = 'cambridge_blue';
      // } else if (
      //   StatusKey == 1 ||
      //   StatusKey == 2 ||
      //   StatusKey == 3 ||
      //   StatusKey == 4 ||
      //   StatusKey == 5
      // ) {
      //   className = 'cambridge_yellow';
      // } else {
      //   className = 'cambridge_green';
      // }
      // return className;
    },
    searchClick () {
      this.$emit('searchEvent', this.paramsForQuery.searchText);
    },
    clearAllOptions () {
      if (this.vIsChoiseOptions) {
        this.paramsForQuery.searchText = '';
        this.$emit('clearAllOptions');
      }
    }
  }
};
</script>

<style scoped>
.all_workbench_left_serach {
  position: absolute;
  left: 0;
  top: -1px;
  bottom: 0;
  width: 322px;
  display: flex;
  padding: 0 20px;
  z-index: 12;
  background-color: #fff;
}

.all_workbench_left_serach .input_box {
  width: 180px;
  height: 35px;
  font-size: 12px;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 10px;
  padding-left: 29px;
  background: url(../../../../../../../public/image/search_icon_01.png)
    no-repeat 11px center;
  background-size: 14px;
}

.all_workbench_left_serach .serach_btn {
  width: 56px;
  height: 35px;
  line-height: 33px;
  text-align: center;
  border-radius: 4px;
  margin-top: 8px;
}

.black {
  background-color: rgb(15, 1, 1);
  color: white;
}
.red {
  background-color: #ec0c0c;
  color: white;
}

.investor_name {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 8px;
  padding-top: 11px;
  padding-left: 45px;
}

.investor_text {
  font-size: 12px;
  line-height: 14px;
  padding-left: 15px;
  margin-left: 45px;
  color: #666;
  background: url(../../../../../../../public/image/workbench_vector_icon.png)
    no-repeat left center;
}
.rank_label_box {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(225, 231, 235, 0.65);
  border-radius: 4px;
  width: 38px;
  height: 38px;
  top: 12px;
}
.investor_intentionKey {
  position: absolute;
  top: 2px;
  left: 5px;
}
.type_status_color {
  width: 55px;
  height: 20px;
  border-radius: 2px;
  padding: 2px 5px;
  font-size: 12px;
  margin-left: 5px;
  color: #333 !important;
}
/* .type_status_color.cambridge_blue {
  background: #d2e1ff;
}
.type_status_color.cambridge_yellow {
  background: #fcefe4;
}
.type_status_color.cambridge_green {
  background: #dcf5ed;
} */

.type_status_color.type_aqua {
  background: #bcebdc;
}
.type_status_color.type_yellow {
  background: #f9e0c7;
}
.type_status_color.type_green {
  background: #78d1b5;
}

.follow_time {
  position: absolute;
  right: 15px;
  font-size: 12px;
  font-weight: 800;
}
.follow_value_time {
  color: #999;
  font-weight: 400;
}
.intention_type {
  position: relative;
  width: 20px;
  height: 5px;
  font-size: 12px;
  top: 18px;
  left: 1px;
  color: #333 !important;
}
.all_workbench_left_serach .serach_btn_clear {
  background: url(../../../../../../../public/image/un_clear.png) no-repeat 11px
    center;
  background-size: 28px;
  cursor: pointer;
}
.all_workbench_left_serach .serach_btn_clear.opt {
  background: url(../../../../../../../public/image/do_clear.png) no-repeat 11px
    center;
  background-size: 28px;
  cursor: pointer;
}
</style>