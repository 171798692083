<template>
  <div class="form_info_detail form_info_serach_box">
    <!-- 自定义展示列表 （通用） -->
    <!-- <span class="defult_dialog_header_btn fr"  @click="doAddClick()" ><span class="btn_add"><span v-if="addTitle!==' '">+</span></span>{{addTitle || '新增'}}</span> -->
    <!-- 搜索选择层 -->
    <div class="defult_dialog_searchform">
      <div class="search_form">
        <input type="text"
               class="search_input"
               placeholder="请输入"
               v-model="searchText">
        <span class="remove_btn"
              v-show="searchText.length > 0"
              @click.stop="clearSearText"></span>
      </div>
    </div>
    <!-- 搜索结果 -->
    <div class="search_result_content"
         :class="{nonBtnForList:!vIsShowBtnGroup,hasBtnForList:vIsShowBtnGroup}">
      <div class="search_result_list">
        <!-- active 选中当前 -->
        <single-selection-item :dataSource="vDataSourceLsit"
                               @selectedItem="doAfterSelectionItem"></single-selection-item>
        <div v-if="vDataSourceLsit.length==0">
        <div class="monitoring_content_nodata" style="margin-top:160px">
            <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
          </div>
        </div>
      </div>
      
    </div>
    <!-- 按钮组 -->
    <div v-if="vIsShowBtnGroup">
      <common-button-group @buttonClickEvent="doAfterClickConfirmBtn"
                           @closeDialog="doAfterClickCancelBtn"></common-button-group>
    </div>
  </div>
</template>
<script>
import singleSelectionItem from './single-selection-item';
export default {
  name: 'singleSelectionList',
  data () {
    return {
      searchText: '',
      selectedItem: {} // 选中项
    };
  },
  props: {
    dataSourceLsit: Array, // 数据源（必填）
    selectionKey: [String, Number], // 可根据key找到当前项
    formTitle: String, // 标题（必填）
    isShowBtnGroup: Boolean, // 是否显示下面的确认 取消按钮
    switchItem: [String, Number, Boolean], // 自定义跳转，不跳转路由的返回.switchItem为任意值
    addTitle: String
  },
  components: {
    singleSelectionItem
  },
  watch: {
    dataSourceLsit (n, o) {
      this.selectedItem = n.find(o => { return o.OLAPKey == this.selectionKey; });
    }
  },
  created () {
  },
  methods: {
    doAddClick () {
      this.$emit('addClick');
    },
    // 选中项
    doAfterSelectionItem (item) {
      console.log(item, 'itemitem');
      if (item) {
        this.selectedItem = item;
      }
      if (!this.vIsShowBtnGroup) {
        this.doAfterClickConfirmBtn();
      }
    },
    // 确定按钮
    doAfterClickConfirmBtn () {
      this.searchText = '';
      this.$emit('selectedItem', this.selectedItem);
    },
    // 取消按钮
    doAfterClickCancelBtn () {
      this.selectedItem = this.dataSourceLsit.find(o => { return o.OLAPKey == this.selectionKey; });
      this.doAfterClickConfirmBtn();
    },
    // 返回页面
    back () {
      if (this.switchItem) {      // 不跳转路由的返回.switchItem为任意值.
        this.$emit('return');
      } else {
        this.$router.go(-1);
      }
    },
    // 清除按钮
    clearSearText () {
      this.searchText = '';
    }
    // 接口流（欢迎扩展）
    // getConnectorDataList () {

    // },
  },
  computed: {
    vDataSourceLsit () {
      let newList = [];
      // const PinyinMatch = require('pinyin-match');// 根据字母搜索
      let trueSearchText = this.searchText.toLowerCase();
      this.dataSourceLsit.forEach(o => {
        // var m = PinyinMatch.match(o.MainDemoName, trueSearchText);
        if (o.MainDemoName && (o.MainDemoName.toLowerCase().indexOf(trueSearchText) >= 0 ||
        (o.FirstLetterName && o.FirstLetterName.toUpperCase().indexOf(trueSearchText.toUpperCase()) >= 0))) {
          if (o.OLAPKey == this.selectionKey) {
            newList.push(o);
          } else {
            newList.push(o);
          }
        }
      });
      return newList;
    },
    vIsShowBtnGroup () {
      return this.isShowBtnGroup == false ? this.isShowBtnGroup : true;
    }
  }
};
</script>


