<template>
  <div>
    <div class="form_info_detail form_info_serach_box">
      <div class="search_result">
        <div class="search_result_content search_result_content_for_student">
          <div class="search_result_list">
            <!-- active 选中当前 -->
            <select-course-bill-item v-for="item in dataSourceList"
                                   :key="item.OLAPKey"
                                   :item="item"
                                   @onSelectedItem="doSelectedItem"></select-course-bill-item>
            <div v-if="!required&&dataSourceList.length>0"
                 class="btn_fixed_bottom_placeholder"></div>
            <div v-if="dataSourceList.length == 0">
              <div class="has_arrange_course">
                <div class="has_arrange_course_content"
                     style="padding:0">
                  <div class="has_arrange_course_list">
                    <div class="has_arrange_course_nodata single_shadow">
                      <span>亲，当前没有任何数据哦~</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import selectCourseBillItem from './select-course-bill-item';
export default {
  components: {
    selectCourseBillItem
  },
  data () {
    return {

    };
  },
  props: {
    dataSourceList: Array, // 数据源
    selectedKey: [String, Number], // 选中项key
    required: { // 是否必选项
      type: Boolean,
      default: false
    },
    footerButtonTitle: {
      type: String,
      default: ''
    }
  },
  created () {
    this.initSelectedItem();
  },
  watch: {
    selectedKey (n, o) {
      this.initSelectedItem();
    }
  },
  methods: {
    initSelectedItem () {
      this.dataSourceList.forEach(obj => {
        if (obj.TableID == this.selectedKey) {
          this.$set(obj, 'isSelected', true);
        } else {
          this.$set(obj, 'isSelected', false);
        }
      });
    },
    // 选中一项
    doSelectedItem (item) {
      this.$emit('onSelectedItem', item);
    },
    cancelData () {
      this.doSelectedItem({ OLAPKey: 0, MainDemoName: '' });
    },
    seletedFooterBtn () {
      this.doSelectedItem({ OLAPKey: 0, MainDemoName: this.footerButtonTitle });
    }
  }
};
</script>
