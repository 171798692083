<template>
  <!-- 管理信息 -->
  <div class="form_info_edit ">
    <div class="">
      <div>
        <box-content-list :dataInfoList="vDepartmentListForQuick"
                          :powerName="'abc'"
                          :hasActive="portInitSettings.activeInfo.canActive"
                          :formTitle='"设立的部门"'
                          :ModulePowerKey="44"
                          :showItemCount="showItemCount"
                          @onOpreate="doOpreateForDepartment"></box-content-list>
      </div>

      <div>
        <box-content-list :dataInfoList="vPostListForQuick"
                          :powerName="'abc'"
                          :hasActive="portInitSettings.activeInfo.canActive"
                          :formTitle='"员工的职务"'
                          :ModulePowerKey="44"
                          :showItemCount="showItemCount"
                          @onOpreate="doOpreateForPost"></box-content-list>
      </div>

      <div>
        <box-content-list :dataInfoList="vIncomeAndExpensesSubjectListForQuick"
                          :powerName="'abc'"
                          :hasActive="portInitSettings.activeInfo.canActive"
                          :formTitle='"财务科目"'
                          :ModulePowerKey="41"
                          :showItemCount="showItemCount"
                          @onOpreate="doOpreateForIncomeAndExpensesSubject"></box-content-list>
      </div>

      <div>
        <box-content-list :dataInfoList="vProceedsAccountListForQuick"
                          :powerName="'abc'"
                          :hasActive="portInitSettings.activeInfo.canActive"
                          :formTitle='"收款方式"'
                          :ModulePowerKey="41"
                          :showItemCount="showItemCount"
                          @onOpreate="doOpreateForProceedsAccount"></box-content-list>
      </div>

      <div>
        <box-content-list :dataInfoList="vIncidentalsListForQuick"
                          :powerName="'abc'"
                          :hasActive="portInitSettings.activeInfo.canActive"
                          :formTitle='"学杂费"'
                          class="no_border_bottom"
                          :ModulePowerKey="45"
                          :showItemCount="showItemCount"
                          @onOpreate="doOpreateForIncidentals"></box-content-list>
      </div>

      <div>
        <box-content-list :dataInfoList="vSupplierListForQuick"
                          :powerName="'abc'"
                          :hasActive="portInitSettings.activeInfo.canActive"
                          :formTitle='"供应商名单"'
                          class="no_border_bottom"
                          :ModulePowerKey="41"
                          :showItemCount="showItemCount"
                          @onOpreate="doOpreateForSupplier"></box-content-list>
      </div>
    </div>
  </div>

</template> 

<script>
import {
  MessageSearchPageForDepartment,
  MessageSearchPageForStation,
  MessageSearchPageForIncomePaySubject,
  MessageSearchPageForIncomePayAccount,
  MessageSearchPageForSupplier,
  MessageSearchPageForIncidentals,
  StartDepartment, // 启用部门
  StopDepartment, // 停用部门
  StartStation, // 启用岗位
  StopStation, // 停用岗位
  StartIncomePaySubject, // 启用收支科目
  StopIncomePaySubject, // 停用收支科目
  StartIncomePayAccount, // 启用收支账户
  StopIncomePayAccount, // 停用收支账户
  StartIncidentals, // 启用杂费
  StopIncidentals, // 停用杂费
  StartSupplier, // 启用供应商
  StopSupplier // 停用供应商
} from '../../../../API/workbench';
import boxContentList from './box-content-list';
export default {
  data () {
    return {
      showItemCount: (document.body.clientWidth >= 1890 && document.body.clientWidth <= 1930) ? 12 : (document.body.clientWidth >= 1570 && document.body.clientWidth <= 1890) ? 10 : 8,
      newInputText: '', // 默认值
      shwoPushItem: { push: '', isShowMainForm: false, formTitle: '', isReadonly: false, editItem: {} }, // 显示弹出层
      toSelectItem: '', // 选择项
      toSelectItemValue: '', // 选择项名称

      isShowMainForm: true, // 是否显示表单
      formTitle: '新增供应商设置', // 标题，
      readonlyInfo: false, // 是否只读
      departmentList: [], // 部门列表
      postList: [], // 岗位列表
      incomeAndExpensesSubjectList: [], // 财务科目列表
      proceedsAccountList: [], // 收款方式列表
      incidentalsList: [], // 学杂费列表
      supplierList: [], // 供应商列表
      // 查询部门
      bumen: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称
        StateKey: 0 // 字符串	可选	0	数据源：GET	状态 2 启用 3停用
      },
      // 查询岗位
      gangwei: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称
        StateKey: 0 // 字符串	可选	0	数据源：GET	状态 2 启用 3停用
      },
      // 查询财务科目
      shouzhikemu: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称
        StateKey: 0, // 字符串	可选	0	数据源：GET	状态 2 启用 3停用
        IncomePayTypeKey: '' // 	收支类型 1-收;2-支
      },
      // 查询收款方式
      shouzhizhanghu: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称
        StateKey: 0, // 字符串	可选	0	数据源：GET	状态 2 启用 3停用
        AccountTypeKey: '' // 字符串	可选	0	数据源：GET	账户类型 1-储蓄卡; 2-信用卡;3-支付宝;4-微信;5-现金;6-其它
      },
      // 查询学杂费
      xuezafei: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称
        StateKey: 0, // 字符串	可选	0	数据源：GET	状态 2 启用 3停用
        IncomePayTypeKey: '' // 	收支类型 1-收;2-支
      },
      // 查询供应商
      gongyinshang: {
        pageIndex: 0, //	整型	必须	0	数据源：GET	页码，0为第一页
        pageSize: 0, // 整型	必须	0	数据源：GET	每页记录数，0：表示查询所有
        orderBy: 'OLAPKey', //	字符串	可选	OLAPKey	数据源：GET	排序字段
        sequence: 'desc', // 字符串	可选	desc	数据源：GET	排序字段
        searchText: '', //		字符串	可选		数据源：GET	搜索条件：模糊匹配名称
        StateKey: 0 // 字符串	可选	0	数据源：GET	状态 2 启用 3停用
      },
      portInitSettings: {
        lastRefreshTime: '',
        routerPath: '', // 通用操作表单路由
        activeInfo: {
          canActive: true,
          apiActive: '', // 启用接口
          apiUnActive: '', // 停用接口
          apiFinish: '', // 结束接口
          activeCallBack: (data) => {
            data.statusKey = 1; // 字段名不确定，需要确认 ToDo
            data.statusKeyValue = '启用';
          },
          unActiveCallBack: (data) => {
            data.statusKey = 0;// 字段名不确定，需要确认 ToDo
            data.statusKeyValue = '停用';
          },
          finishCallBack: (data) => {
            data.statusKey = 0;// 字段名不确定，需要确认 ToDo
            data.statusKeyValue = '停用';
          },
          otherOpreateHandler: null // 其它非通用操作处理方法
        }
      }
    };
  },

  components: {
    boxContentList
  },

  created () {
    this.loadData();// 渲染列表数据
    this.$bus.on(this, 'switchHeaderBarToMendiansettingform', () => {
      this.loadData();
    });
  },
  mounted () {
    // this.$dialog.register(this, ['departmentSetting', 'postSetting', 'incomeAndExpensesSubjectSetting', 'proceedsAccountSetting', 'incidentalsSetting', 'supplierSetting']);
  },
  methods: {
    // 加载刷新列表数据
    loadData () {
      this.portInitSettings.lastRefreshTime = Date.new();
      this.upDepartmentData();
      this.upPostData();
      this.upIncomeAndExpensesSubjectData();
      this.upProceedsAccountData();
      this.upIncidentalsData();
      this.upSupplierData();
    },
    // 刷新列表数据
    upLoadSettingData (upDataName) {
      this.portInitSettings.lastRefreshTime = Date.new();
      switch (upDataName) {
        case 'departmentSetting':// 查询部门
          this.upDepartmentData();
          break;
        case 'postSetting':// 查询岗位
          this.upPostData();
          break;
        case 'incomeAndExpensesSubjectSetting':// 查询财务科目
          this.upIncomeAndExpensesSubjectData();
          break;
        case 'proceedsAccountSetting':// 查询收款方式
          this.upProceedsAccountData();
          break;
        case 'incidentalsSetting':// 查询学杂费
          this.upIncidentalsData();
          break;
        case 'supplierSetting':// 查询供应商
          this.upSupplierData();
          break;
        default:
          break;
      }
    },
    showModulesName (value) {
      switch (value) {
        case 'departmentSetting':
          return '部门';
        case 'postSetting':
          return '职务';
        case 'incomeAndExpensesSubjectSetting':
          return '财务科目';
        case 'proceedsAccountSetting':
          return '收款方式';
        case 'incidentalsSetting':
          return '学杂费';
        case 'supplierSetting':
          return '供应商';
        default:
          break;
      }
    },
    // 更新部门数据
    upDepartmentData () {
      MessageSearchPageForDepartment(this.bumen).then(result => {
        this.departmentList = [];
        this.departmentList = result.data.PageDataList;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 更新岗位数据
    upPostData () {
      MessageSearchPageForStation(this.gangwei).then(result => {
        this.postList = [];
        this.postList = result.data.PageDataList;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 更新财务科目数据
    upIncomeAndExpensesSubjectData () {
      MessageSearchPageForIncomePaySubject(this.shouzhikemu).then(result => {
        this.incomeAndExpensesSubjectList = [];
        this.incomeAndExpensesSubjectList = result.data.PageDataList;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 更新收款方式数据
    upProceedsAccountData () {
      MessageSearchPageForIncomePayAccount(this.shouzhizhanghu).then(result => {
        this.proceedsAccountList = [];
        this.proceedsAccountList = result.data.PageDataList;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 更新学杂费数据
    upIncidentalsData () {
      MessageSearchPageForIncidentals(this.xuezafei).then(result => {
        this.incidentalsList = [];
        this.incidentalsList = result.data.PageDataList;
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    // 更新供应商数据
    upSupplierData () {
      MessageSearchPageForSupplier(this.gongyinshang).then(result => {
        this.supplierList = [];
        this.supplierList = result.data.PageDataList;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 执行部门相关操作
    doOpreateForDepartment (opreateType, dataKey, data, callBack) {
      this.portInitSettings.routerPath = 'departmentSetting';
      this.portInitSettings.activeInfo.apiActive = StartDepartment;
      this.portInitSettings.activeInfo.apiUnActive = StopDepartment;

      let options = this.getOptions(this.portInitSettings, opreateType, dataKey, data, callBack);
      this.doOpreate(options);
    },
    // 执行岗位相关操作
    doOpreateForPost (opreateType, dataKey, data, callBack) {
      this.portInitSettings.routerPath = 'postSetting';
      this.portInitSettings.activeInfo.apiActive = StartStation;
      this.portInitSettings.activeInfo.apiUnActive = StopStation;

      let options = this.getOptions(this.portInitSettings, opreateType, dataKey, data, callBack);
      this.doOpreate(options);
    },
    // 执行财务科目相关操作
    doOpreateForIncomeAndExpensesSubject (opreateType, dataKey, data, callBack) {
      if (opreateType == 'unActive' && Number(data.SysPaySubjectKey) > 0) {
        layer.alert('该科目为预设收支科目，不允许停用');
        return false;
      }
      this.portInitSettings.routerPath = 'incomeAndExpensesSubjectSetting';
      this.portInitSettings.activeInfo.apiActive = StartIncomePaySubject;
      this.portInitSettings.activeInfo.apiUnActive = StopIncomePaySubject;

      let options = this.getOptions(this.portInitSettings, opreateType, dataKey, data, callBack);
      this.doOpreate(options);
    },
    // 执行收款方式相关操作
    doOpreateForProceedsAccount (opreateType, dataKey, data, callBack) {
      this.portInitSettings.routerPath = 'proceedsAccountSetting';
      this.portInitSettings.activeInfo.apiActive = StartIncomePayAccount;
      this.portInitSettings.activeInfo.apiUnActive = StopIncomePayAccount;

      let options = this.getOptions(this.portInitSettings, opreateType, dataKey, data, callBack);
      this.doOpreate(options);
    },
    // 执行学杂费相关操作
    doOpreateForIncidentals (opreateType, dataKey, data, callBack) {
      this.portInitSettings.routerPath = 'incidentalsSetting';
      this.portInitSettings.activeInfo.apiActive = StartIncidentals;
      this.portInitSettings.activeInfo.apiUnActive = StopIncidentals;

      let options = this.getOptions(this.portInitSettings, opreateType, dataKey, data, callBack);
      this.doOpreate(options);
    },
    // 执行供应商相关操作
    doOpreateForSupplier (opreateType, dataKey, data, callBack) {
      this.portInitSettings.routerPath = 'supplierSetting';
      this.portInitSettings.activeInfo.apiActive = StartSupplier;
      this.portInitSettings.activeInfo.apiUnActive = StopSupplier;
      let options = this.getOptions(this.portInitSettings, opreateType, dataKey, data, callBack);
      this.doOpreate(options);
    },
    execAPIForOpreate (apiFN, dataKey, upDataName, callBack) {
      apiFN(dataKey).then(result => {
        this.upLoadSettingData(upDataName);
        callBack(result);
      }, error => {
        // console.log(error.msg, '接口');
        layer.alert(error.msg);
      });
    },
    // 通用操作执行
    doOpreate (options) {
      options = options || {
        lastRefreshTime: '',
        routerPath: '', // 通用操作表单路由
        activeInfo: {
          canActive: true,
          apiActive: null,
          activeCallBack: null, // 启用回调
          apiUnActive: null,
          unActiveCallBack: null, // 停用回调
          apiFinish: null,
          finishCallBack: null // 结束回调
        },
        opreateType: '', // add edit view active unActive
        dataKey: 0, // 数据Key，新增为0或null
        data: null, // 操作数据对象，新增为null
        callBack: null, // 回调，必有
        otherOpreateHandler: null // 其它非通用操作处理方法
      };
      options.dataKey = options.dataKey || 0;
      // console.log(options, '操作执行');
      this.shwoPushItem.push = options.routerPath;
      switch (options.opreateType) {
        case 'add':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '新增' + this.showModulesName(options.routerPath),
            moduleName: options.routerPath,
            data: { editItem: {}, isReadonly: false },
            callBack: {
              afterSuccess: () => { this.upLoadSettingData(options.routerPath); }
            }
          });
          break;
        case 'edit':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '修改' + this.showModulesName(options.routerPath),
            moduleName: options.routerPath,
            data: { editItem: options.data, isReadonly: false },
            callBack: {
              afterSuccess: () => { this.upLoadSettingData(options.routerPath); }
            }
          });
          break;
        case 'view':
          this.$dialog.open(this, {
            routerName: this.$route.name,
            ModulePowerKey: '',
            name: '查看' + this.showModulesName(options.routerPath),
            moduleName: options.routerPath,
            data: { editItem: options.data, isReadonly: true }
          });
          break;
        case 'active':
          if (options.activeInfo.canActive) {
            this.execAPIForOpreate(options.activeInfo.apiActive, options.dataKey, options.routerPath, resultData => {
              options.activeInfo.activeCallBack(options.data);
              options.callBack(options.data);
              options.lastRefreshTime = Date.new();
            });
          }
          break;
        case 'unActive':
          if (options.activeInfo.canActive) {
            this.execAPIForOpreate(options.activeInfo.apiUnActive, options.dataKey, options.routerPath, resultData => {
              options.activeInfo.unActiveCallBack(options.data);
              options.callBack(options.data);
              options.lastRefreshTime = Date.new();
            });
          }
          break;
        case 'finish':
          if (options.activeInfo.canActive) {
            this.execAPIForOpreate(options.activeInfo.apiFinish, options.dataKey, options.routerPath, resultData => {
              options.activeInfo.finishCallBack(options.data);
              options.callBack(options.data);
              options.lastRefreshTime = Date.new();
            });
          }
          break;
        default:// 超出标准操作，自行处理
          if (options.activeInfo.otherOpreateHandler) {
            options.otherOpreateHandler(options.opreateType, options.dataKey, options.data, options.callBack);
          }
          break;
      }
    },

    getOptions (settings, opreateType, dataKey, data, callBack) {
      let options = {
        opreateType: opreateType, // add edit view active unActive
        dataKey: dataKey, // 数据Key，新增为0或null
        data: data, // 操作数据对象，新增为null
        callBack: callBack // 回调，必有
      };
      // 合并参数设定
      return Object.assign(settings, options);
    }
  },
  computed: {
    vDepartmentListForQuick () {
      // ToDo: 需要转换成通用组件需要的数据结构
      let list = [];
      if (this.portInitSettings.lastRefreshTime != '') {
        this.departmentList.forEach(o => {
          let item = { dataKey: o.OLAPKey, itemName: o.MainDemoName, isActive: o.StatusKey == 1, data: o, isMenu: false };
          list.push(item);
        });
      }
      return list;
    },
    vPostListForQuick () {
      // ToDo: 需要转换成通用组件需要的数据结构
      let list = [];
      if (this.portInitSettings.lastRefreshTime != '') {
        this.postList.forEach(o => {
          let item = { dataKey: o.OLAPKey, itemName: o.MainDemoName, isActive: o.StatusKey == 1, data: o, isMenu: false };
          list.push(item);
        });
      }
      return list;
    },
    vIncomeAndExpensesSubjectListForQuick () {
      // ToDo: 需要转换成通用组件需要的数据结构
      let list = [];
      if (this.portInitSettings.lastRefreshTime != '') {
        this.incomeAndExpensesSubjectList.forEach(o => {
          let item = { dataKey: o.OLAPKey, itemName: o.MainDemoName, isActive: o.StatusKey == 1, data: o, isMenu: false };
          list.push(item);
        });
      }
      return list;
    },
    vProceedsAccountListForQuick () {
      // ToDo: 需要转换成通用组件需要的数据结构
      let list = [];
      if (this.portInitSettings.lastRefreshTime != '') {
        this.proceedsAccountList.forEach(o => {
          let item = { dataKey: o.OLAPKey, itemName: o.MainDemoName, isActive: o.StatusKey == 1, data: o, isMenu: false };
          list.push(item);
        });
      }
      return list;
    },
    vIncidentalsListForQuick () {
      // ToDo: 需要转换成通用组件需要的数据结构
      let list = [];
      if (this.portInitSettings.lastRefreshTime != '') {
        this.incidentalsList.forEach(o => {
          let item = { dataKey: o.OLAPKey, itemName: o.MainDemoName, isActive: o.StatusKey == 2, data: o, isMenu: false };
          list.push(item);
        });
      }
      return list;
    },
    vSupplierListForQuick () {
      // ToDo: 需要转换成通用组件需要的数据结构
      let list = [];
      if (this.portInitSettings.lastRefreshTime != '') {
        this.supplierList.forEach(o => {
          let item = { dataKey: o.OLAPKey, itemName: o.MainDemoName, isActive: o.StatusKey == 1, data: o, isMenu: false };
          list.push(item);
        });
      }
      return list;
    }
  }
};
</script>

  <style>
</style>