<template>
  <div>
    <div class>
      <!-- 在校学生 -->
      <div class="">
        <list ref="studentApplyCourseTable"
              :tableData="studentList"
              :totalNum="totalNum"
              :pageTotal="pageTotal"
              :PageCount="PageCount"
              :tableColumns="tableColumns"
              :isSearchText="false"
              :isNeedInnerScroll="false"
              :isShowSearch="true"
              :rowKey="'StudentKey'"
              :defaultSort="defaultSort"
              :paramsForStudent="paramsForStudent"
              @btnFnClick="btnClickAfter"
              @doExportInfoClick="doExportInfoClick"
              @searchStudent="searchStudent"
              @tableBtnClick="doAfterTableBtnClick"
              @loadTableData="getMessageSearcForStudent"
              @clearSearchParams="clearSearchCondition"
              @doAfterCilckSummaryData="afterCilckSummaryData"></list>
      </div>
    </div>

  </div>
</template>
<script>
import {
  getAllStudentList,
  getStudentRelatedMsg,
  WeChatUnbind,
  RecoveryStudent
} from '../../../API/workbench.js';

import list from './table';
// import dialogFactory from '../../common/dialog-factory';
export default {
  data () {
    return {
      isShowCourseNameList: false,
      courseNameList: [], // 课程列表
      paramsForStudent: {
        // 学生报课统计
        pageIndex: 0, // 页码，0为第一页
        pageSize: 10, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: 'RegisterTime', // 排序字段
        sequence: 'desc', // 排序字段
        status: 0,        //	0全部  学生状态 1-在校; 2-潜客;3-离校
        QueryID: '',
        BirthdayMonth: '',
        IsExportKey: 0
      },
      searchText: '',
      pageTotal: 0, // 分页器总数
      totalStudentCount: 0, // 导出的全部学生数
      studentKey: 0,
      studentInSchoolTotalNum: 0,
      studentList: [], // 按学生 的 学生列表
      curSelectedStudentKey: 0, // 当前选中学生Key
      tableColumns: [
        {
          label: '学生ID',
          prop: 'OLAPKey',
          width: 20,
          sortable: false,
          align: 'left',
          isShow: false,
          type: 'number-item',
          decimalPlace: 0
        },
        {
          label: '登记日期',
          prop: 'RegisterTime',
          width: 120,
          align: 'left',
          isShow: true,
          sortable: 'custom',
          type: 'date-item',
          decimalPlace: 0,
          className: '',
          eventName: this.$initJson.baseFunctionBtnName.read
        },
        {
          label: '学生名',
          prop: 'MainDemoName',
          width: 120,
          sortable: 'custom',
          type: 'student',
          extend: {
            studentKey: 'OLAPKey', // 学生ID
            click: () => { }
          }
        },
        {
          label: '性别',
          prop: 'SexKeyValue',
          width: 50,
          type: 'text-item'
        },
        {
          label: '排课备注',
          prop: 'ArrangeSupport',
          type: 'arrange-support',
          width: 200,
          extend: {
            studentKey: 'OLAPKey', // 学生ID
            studentKeyValue: 'MainDemoName', // 学生名
            click: () => { }
          }
        },
        {
          label: '手机号',
          prop: 'CustomerPhoneName',
          width: '',
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          className: ''
        },
        {
          label: '出生日期',
          prop: 'BirthdayTime',
          width: '',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          className: ''
        },
        {
          label: '状态',
          prop: 'StudentStatusKeyValue',
          width: 100,
          sortable: 'custom',
          align: 'center',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          className: ''
        },
        {
          label: '登记类型',
          prop: 'RegisterTypeKeyValue',
          width: '',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          className: ''
        },
        {
          label: '关注',
          prop: 'SubscribeKey',
          type: 'wechat-subscribe',
          width: 60,
          extend: {
            click: (rowData) => { this.wechatSubscribeUnbind(rowData); }
          }
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          width: 180,
          extend: {
            tableBtnGroup: [
              {
                name: '查看',
                extend: {
                  click: (rowData) => { this.doAfterTableBtnClick(rowData); },
                  ModulePowerKey: ''
                }
              },
              {
                name: '报名',
                extend: {
                  click: (rowData) => { this.studentPaymentEvent(rowData); },
                  ModulePowerKey: '50'
                }
              },
              {
                name: '恢复',
                extend: {
                  isHide: (rowData) => {
                    return Number(rowData.StudentStatusKey) != 3;
                  },
                  IsInquiryHint: 'inquiryBtn',
                  setClassName: (rowData) => {
                    if (rowData.inquiryBtn) { // 开启询问提示，显示询问弹窗时，界面按钮置灰，不可操作
                      return rowData.inquiryBtn;
                    }
                  },
                  click: (rowData, callBack) => {
                    if (callBack) {
                      callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: 'table_btn_gray' });
                    }
                    this.studentRegainBtnClick(rowData, callBack);
                  },
                  ModulePowerKey: ''
                }
              }
            ]
          }
        }
      ],
      PageCount: 0,
      totalNum: 0,
      summary: {
        needSummariesColumns: [
          { prop: '', SumType: 'string', unit: '人' },
          { prop: 'Total_NumCount', SumType: 'number', unit: '' },
          { prop: 'Sum_SpendCount', SumType: 'number', unit: '' },
          { prop: 'Total_CurrentCount', SumType: 'number', unit: '' },
          { prop: 'Sum_CurrentCount', SumType: 'number', unit: '' },
          { prop: 'Total_ToBePlaned', SumType: 'number', unit: '' },
          { prop: 'Sum_ArrangedCount', SumType: 'number', unit: '' },
          { prop: 'Sum_ToBeArranged', SumType: 'number', unit: '' }
        ],
        isShowSummary: false,
        summaryName: '合计',
        summaryData: []
      },
      isAlterMessagePopup: false,
      arrangeType: '', // 安排类型（因为有两个入口可安排学生 以区分是哪个入口）
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  components: {
    list
    // dialogFactory
  },
  created () {
  },
  mounted () {
    this.getMessageSearcForStudent();
    this.registerBusEvent();
  },
  methods: {
    // 关闭 课名列表
    closeDialogForCourseNameList () {
      this.isShowCourseNameList = false;
    },

    // 获取学生列表
    getMessageSearcForStudent (queryParams, callBack) {
      console.log(queryParams, 'getMessageSearcForStudent');
      if (queryParams) {
        queryParams.orderBy = (queryParams.orderBy == 'studentInfo' ? '' : queryParams.orderBy);
        Object.assign(this.paramsForStudent, queryParams);
      }
      console.log(this.paramsForStudent, 'this.paramsForStudent');
      this.paramsForStudent.searchText = this.paramsForStudent.searchText.trim();
      // MessageSearchTicketPlanPageForStudent
      getAllStudentList(this.paramsForStudent).then(result => {
        this.pageTotal = result.data.Total;
        this.totalStudentCount = Number(result.data.TotalStudentCount);
        this.$nextTick(() => {
          if (result.data.PageDataList.length == 0) {
            this.$refs.studentApplyCourseTable.isLoading = true;
          }
        });
        if (this.paramsForStudent.status == 0 && this.paramsForStudent.searchText == '' && this.paramsForStudent.BirthdayMonth == '') {
          this.totalNum = result.data.Total;
        }
        // 分页数
        if (result.data && result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach(o => {
            // o.sourceData = o.ApplyClassKeyValue.split(',');
            o.RegisterTime = o.RegisterTime || '';
            o.RegisterTime = o.RegisterTime.substring(0, 10);
            o.BirthdayTime = o.BirthdayTime || '--';
            o.CustomerPhoneName = this.$utils.phoneModulePower(o.CustomerPhoneName) || '--';
            o.SubscribeValue = o.SubscribeKey == 1 ? '已关注' : '';
            let info = o.StudentKeyValue + ',' + o.CustomerPhoneName;
            o.studentInfo = info.split(',');
            o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
          });
          if (this.$refs.studentApplyCourseTable) {
            this.$refs.studentApplyCourseTable.options[0].sum = result.data.TotalStudentCount;// 全部学生数
            this.$refs.studentApplyCourseTable.options[1].sum = result.data.InSchoolCount;// 在校学生数
            this.$refs.studentApplyCourseTable.options[2].sum = result.data.BrokerCount;// 潜客学生数
            this.$refs.studentApplyCourseTable.options[3].sum = result.data.LeavSchoolCount;// 离校学生数
          }
          this.studentList = result.data.PageDataList;
          console.log('this.studentList = result.data.PageDataList', this.studentList);
        } else {
          this.studentList = [];
        }
        if (callBack) {
          callBack();
        }
      }).finally(() => {
        this.$refs.studentApplyCourseTable.isLoading = false;
      });
    },
    // 汇总数据排序
    afterCilckSummaryData (item) {
      console.log(item, '汇总数据排序');
      if (item.title !== '当前报课') {
        if (!item.value || item.value == 0 || item.title == '当前报课') {
          return false;
        }
      }
    },
    // 搜索
    searchStudent (searchText) {
      this.paramsForStudent.searchText = searchText;
      this.paramsForStudent.pageIndex = 0;
      this.$refs.studentApplyCourseTable.queryParams.pageIndex = 0;
      console.log('searchStudent', searchText);
      // this.getAllMessageSearchTicketPlanPageForStudent();
      this.getMessageSearcForStudent();
    },
    // 点击学生
    clickStudentItem (item) {
      this.curSelectedWholeStudent = item;
      this.curSelectedStudentKey = item.StudentKey;
      // 获取当前选中学生信息
      this.loadStudentInfo();
      this.isShowMoreOptionForStudent = true;
    },
    // 获取当前选中学生信息
    loadStudentInfo () {
      getStudentRelatedMsg(this.curSelectedStudentKey).then(
        result => {
          this.curSelectedStudentInfo = result.data;
        },
        error => {
          layer.alert(error.msg);
        }
      );
    },
    // 导出
    doExportInfoClick () {
      console.log('导出', this.totalStudentCount);
      let StudentsInfo = {
        // 学生报课统计
        pageIndex: 0, // 页码，0为第一页
        pageSize: this.totalStudentCount, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: 'RegisterTime', // 排序字段
        sequence: 'desc', // 排序字段
        status: 0,        //	0全部  学生状态 1-在校; 2-潜客;3-离校
        QueryID: '',
        BirthdayMonth: '',
        IsExportKey: 1
      };
      let jsonFormate = {
        SheetTitle: '全部学生统计',
        clist: [
          { title: '学生名', cName: 'MainDemoName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '性别', cName: 'SexKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '手机号', cName: 'CustomerPhoneName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '出生日期', cName: 'BirthdayTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '兴趣课程', cName: 'FineSupport', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '跟销顾问', cName: 'AdviserKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '地址', cName: 'VillageKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '学校', cName: 'SchoolName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
          { title: '状态', cName: 'StudentStatusKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
        ]
      };
      console.log(jsonFormate, 'jsonFormate');
      getAllStudentList(StudentsInfo, jsonFormate).then(result => {
        console.log(result.data, '导出');
        window.location.href = this.$store.getters.APIURL + result.data.url;
        this.paramsForStudent.IsExportKey = 0;
      }, error => {
        layer.alert(error.msg);
      });
    },
    btnClickAfter (eventName) {
      console.log(eventName, 'btnClickAfter');
      let moduleInfo = {
        moduleName: eventName,
        routerName: this.$route.name,
        name: '登记新生'
      };
      this.$emit('openCustomDialog', moduleInfo);
      // this.openCustomDialogByFactoryDialog(moduleInfo);
    },
    // table按钮点击
    doAfterTableBtnClick (rowData) {
      console.log(rowData, '按钮点击');
      this.studentKey = rowData.OLAPKey;
      let moduleInfo = {};
      console.log(this.studentKey, 'studentKey');
      moduleInfo = {
        moduleName: 'studentFile',
        name: '学生档案',
        data: { studentID: this.studentKey }
      };
      this.$emit('openCommonCustomDialog', moduleInfo);
      // this.openCustomDialogByFactoryDialog(moduleInfo);
    },
    doAfterApplyCourseClick (item) {
      this.$refs.courseApplyCourse.openDialogByOtherModule(
        this.studentKey,
        item.ApplyClassKey
      );
    },
    doAfterArrangeClick (item) {
      console.log('doAfterArrangeClick', item);
      this.doAfterTableBtnClick(item, this.$initJson.baseFunctionBtnName.arrange);
    },
    // // 报名按钮
    studentPaymentEvent (rowData) {
      this.studentKey = rowData.OLAPKey;
      let obj = {
        StudentKey: this.studentKey,
        isReadOnly: false,
        TableID: ''
      };
      let moduleInfo = {
        moduleName: 'signuppayment',
        routerName: this.$route.name,
        name: '报名收款',
        data: { dataInfo: obj }
      };
      this.$emit('openCommonCustomDialog', moduleInfo);
      // this.openCustomDialogByFactoryDialog(moduleInfo);
    },

    // 停用/启用
    studentRegainBtnClick (rowData, callBack) {
      layer.confirm('恢复后即为在校，恢复后没有在30天内购课将会再次离校，是否确认?', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              layer.close();
              if (callBack) {
                callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
              }
            }
          },
          {
            name: '确认',
            callBack: () => {
              RecoveryStudent(rowData.OLAPKey).then(result => {
                this.getMessageSearcForStudent();
                if (callBack) {
                  callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });;
                }
              }, error => {
                layer.alert(error.msg);
              });

              layer.close();
            }
          }
        ]
      });
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.paramsForStudent.searchText = '';
      this.paramsForStudent.pageIndex = 0;
      this.paramsForStudent.status = 0;
      this.paramsForStudent.BirthdayMonth = '';
      this.$refs.studentApplyCourseTable.queryParams.pageIndex = 0;
      // this.getAllMessageSearchTicketPlanPageForStudent();
      this.getMessageSearcForStudent();
    },
    // 微信关注解绑
    wechatSubscribeUnbind (rowData) {
      console.log(rowData, '微信关注解绑');
      layer.confirm('请问是否解绑?', {
        btn: [{
          name: '确认',
          callBack: () => {
            WeChatUnbind(rowData.CustKey).then(
              result => {
                layer.alert('解绑成功');
                this.getMessageSearcForStudent();
              }, error => {
                layer.alert(error.msg);
              });
            layer.close();
          }
        }
        ]
      });
    },
    registerBusEvent () {
      // 修改学生档案的学生信息成功后触发回调
      this.$bus.on(this, 'AfterStudentUpdate', studentKey => {
        console.log('AfterStudentUpdate', 'allStudent');
        this.getMessageSearcForStudent(this.paramsForStudent, () => {
          if (this.paramsForStudent.searchText != '' || this.paramsForStudent.status != 0) {
            let newSearchData = this.$utils.parseJson(this.paramsForStudent);
            newSearchData.searchText = '';
            newSearchData.status = 0;
            getAllStudentList(newSearchData).then(result => {
              this.totalNum = result.data.Total;
            });
          }
        });
      });
      this.$bus.on(this, 'AfterStudentDelete', studentKey => {
        if (this.paramsForStudent.status != 0) {
          this.totalNum--;
        }
        this.getMessageSearcForStudent();
      });
      this.$bus.on(this, 'AfterStudentAdd', studentKey => {
        if (this.paramsForStudent.searchText != '' || this.paramsForStudent.status != 0) {        // 因为totalNum是全部学生总数. 但接口会因为searchText跟status变更导致总数不一致.
          this.totalNum++;                                                                        // 只有选择全部学生及没有searchText为空是才会更新totalNum
        }
        this.getMessageSearcForStudent();
      });
      // 报名收款新增成功后的回调
      this.$bus.on(this, 'AfterSignUpPaymentSuccess', () => {
        this.getMessageSearcForStudent(this.paramsForStudent, () => {
          if (this.paramsForStudent.searchText != '' || this.paramsForStudent.status != 0) {
            getAllStudentList(this.paramsForStudent).then(result => {
              this.pageTotal = result.data.Total;
            });
          }
        });
      });
      // this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      //   if (dialogInfo.routerName == 'allStudent') {
      //     this.openCustomDialogByFactoryDialog(dialogInfo);
      //   }
      // });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.allStudent) {
        this.$refs.allStudent.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    }
  },
  computed: {}
};
</script>
