<template>
  <div class="cpitem">
    <div class="title_div">
      <div class="Num_div">NO.{{numTitle+1}}</div>
      <div class="title_div_img">
        <img :src="headImg"
             :onerror="defaultImg"
             width="100%"
             height="100%"
             style="border-radius: 8px;"
             alt="../../../../../public/image/no_logo@2x.png" />
      </div>
      <span class="title_div_h1">{{items.MainDemoName}}</span>
      <!-- <span class="title_div_span">子课包：<span class="title_div_span_color">{{items.CoursewarePackageCount?items.CoursewarePackageCount:0}}</span></span> -->
      <ul class="title_div_ul">
        <li class="line_after1">
          <span class="title_div_ul_span"><span class="up_span font_blue">{{items.InUseSchoolCount?items.InUseSchoolCount:0}}</span></span>
          <span class="title_div_ul_span"><span class="down_span">在用校区</span></span>
        </li>
        <li class="line_after">
          <span class="title_div_ul_span"><span class="up_span "
                  style="color: #feaa35;">{{items.ExpireWarningCount?items.ExpireWarningCount:0}}</span></span>
          <span class="title_div_ul_span"><span class="down_span">授权过期预警</span></span>
        </li>
        <li>
          <span class="title_div_ul_span"><span style="color: #FF6C67;"
                  class="up_span font_red">{{items.ExpireSchoolCount?items.ExpireSchoolCount:0}}</span></span>
          <span class="title_div_ul_span"><span class="down_span">停用校区</span></span>
        </li>
      </ul>
      <div class="isCur"
           v-if="isButtom"
           @click="isButtomclick">
        <div class="buttom_blur">展开详情</div>
        <div class="down_buttom"></div>
      </div>
      <div class="isCur"
           v-else
           @click="isButtomclick">
        <div class="buttom_blur">收起详情</div>
        <div class="top_buttom"></div>
      </div>
    </div>
    <div v-if="isShowTag"
         class="prepare">
      <title-package :CoursewarePackageId="items.OLAPKey"
                     :CoursewareValue="items.MainDemoName"
                     @showTableList='isButtomclick'></title-package>
    </div>
  </div>
</template>
<script>
import titlePackage from './title-package.vue'
export default {
  props: ['items', 'numTitle'],
  data () {
    return {
      isButtom: true,
      isShowTag: false
    };
  },
  components: {
    titlePackage
  },
  created () {
  },
  mounted () {

  },
  methods: {
    isButtomclick () {
      this.isButtom = !this.isButtom
      this.isShowTag = !this.isShowTag
    }
  },
  computed: {
    headImg () {
      if (!this.items.CoverUrl) {
        this.items.CoverUrl = '';
      }
      if (this.items.CoverUrl.indexOf('http') > -1) {
        return this.items.CoverUrl;
      } else {
        return this.$store.getters.CDNURL + this.items.CoverUrl
      }
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../../public/image/no_logo@2x.png') + '"';
    }
  }
};
</script>
<style scoped>
.cpitem {
  margin-top: 20px;

  /* background-color: #fafafa; */
}
.prepare {
  margin-top: -2px;
  width: 1290px;
  border: 1px solid #ececec;
  border-top: none;
}
.title_div {
  width: 1290px;
  height: 180px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  position: relative;
}
.Num_div {
  position: absolute;
  width: 48px;
  height: 26px;
  left: 0px;
  top: 0px;
  background: #feaa35;
  border-radius: 8px 0px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.98);
  text-align: center;
  line-height: 26px;
}
.title_div_img {
  box-sizing: border-box;
  position: absolute;
  width: 200px;
  height: 112.32px;
  left: 32px;
  top: 36px;
  border-radius: 4px;
}
.title_div_h1 {
  position: absolute;
  height: 25px;
  left: 260px;
  top: 36px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
}
.title_div_span {
  position: absolute;
  height: 19px;
  left: 260px;
  top: 65px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #999999;
}
.title_div_span_color {
  color: #333333;
}
.title_div_ul {
  /* Rectangle 3704 */
  list-style: none;
  box-sizing: border-box;
  position: absolute;
  width: 369px;
  height: 52px;
  left: 260px;
  top: 96px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #f1f7ff, #f1f7ff),
    linear-gradient(0deg, #f5f8ff, #f5f8ff), #f9f9fb;
  border: 1px solid #ececec;
  border-radius: 4px;
}
.title_div_ul li {
  width: 120px;
  height: 50px;
  float: left;
}
.line_after::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 34px;
  left: 119px;
  top: 10px;
  background: linear-gradient(0deg, #ececec, #ececec), #ffffff;
}
.line_after1::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 34px;
  left: 239px;
  top: 10px;
  background: linear-gradient(0deg, #ececec, #ececec), #ffffff;
}
.title_div_ul_span {
  width: 120px;
  height: 25px;
  text-align: center;
  display: block;
}
.up_span {
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  color: #333333;
}
.down_span {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  color: #999999;
}
.buttom_blur {
  position: absolute;
  width: 77px;
  height: 18px;
  left: 1160px;
  top: 113px;

  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 128.91%;
  /* identical to box height, or 18px */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #3498db;
}
.down_buttom {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 1225px;
  top: 112px;
  /* background: #F0F0F0; */
  border-radius: 8px;
  background: #f0f0f0 url(../../../../../public/image/arror_down.png) no-repeat
    50%;
}
.top_buttom {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 1225px;
  top: 112px;
  /* background: #F0F0F0; */
  border-radius: 8px;
  background: #f0f0f0 url(../../../../../public/image/arror_up.png) no-repeat
    50%;
}
.top_buttom_icon {
  width: 7.07px;
  height: 7.07px;
  background: #c4c4c4;
}
.isCur {
  cursor: pointer;
}
</style>