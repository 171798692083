<template>
  <!-- 班级学生排课分类统计 -->
  <div>
    <div class="content_marginRL content_marginTop_30"
         style="height: 500px;">
      <!-- 内容 -->
      <div class="form_info_edit mendian_setting_content single_shadow">
        <div>
          <div class="attend_class_title">{{ classInfo.MainDemoName }}</div>
          <div class="weeks_class_box">
            <div class="weeks_one_order w_order"
                 :class="CourseArrangeNumberType == 1?'orderTrue':'orderFalse'"
                 @click.stop="weeksOrderFun(1)">周排课0-1次 ({{Number(scheduleInfo.CourseArrangeNumberType1)}}人)</div>
            <div class="weeks_two_order w_order"
                 :class="CourseArrangeNumberType == 2?'orderTrue':'orderFalse'"
                 @click.stop="weeksOrderFun(2)">周排课2次以上 ({{Number(scheduleInfo.CourseArrangeNumberType2)}}人)</div>
          </div>
          <div class=""
               style="height:500px;width:390px;overflow-y: auto;"
               v-if="studentsInfo.length>0">
            <div class="students_content_data"
                 v-for="(item,index) in studentsInfo"
                 :key="index"
                 :class="(studentsInfo.length - 1) == index?'no_border_bottom':''">
              <div class="students_name"
                   :title="item[0].StudentNameKeyValue">{{ item[0].StudentNameKeyValue}}</div>

              <div class="students_data">
                <div v-for="(child,i) in item"
                     :key="i"
                     style="margin-bottom: 10px;"><span>{{child.WeekKeyValue}}</span> <span>{{child.ClassTimeName}}</span> <span>{{child.CourseNameKeyValue}}</span></div>
              </div>
            </div>
          </div>
          <div v-if="studentsInfo.length == 0"
               class="course_block_nodata">
            <div class="monitoring_content_nodata">
              <div class="monitoring_content_nodata_txt">亲，当前没有任何数据哦~</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import { MessageSearchGradeCourseArrangeDetail } from '../../../../API/workbench';
export default {
  // 提供外部输出显示，不给就输出内部默认显示
  props: {
    classInfo: Object,
    scheduleInfo: Object,
    classType: Number
  },
  data () {
    return {
      CourseArrangeNumberType: 1,
      studentsInfo: []
    };
  },

  components: {
  },
  computed: {
  },
  watch: {
  },
  created () {
    this.weeksOrderFun(this.classType);
    console.log(this.classInfo, 'classInfo666', this.scheduleInfo, this.classType);
  },
  mounted () {
  },
  methods: {
    weeksOrderFun (type) {
      this.CourseArrangeNumberType = type;
      console.log(this.classInfo, 'this.classInfo this.CourseArrangeNumberType', this.CourseArrangeNumberType);
      MessageSearchGradeCourseArrangeDetail(this.classInfo.OLAPKey, this.CourseArrangeNumberType).then(res => {
        if (res.data.length > 0) {
          this.studentsInfo = res.data;
        } else {
          this.studentsInfo = [];
        }
        console.log(res.data, 'MessageSearchGradeCourseArrangeDetail', this.studentsInfo);
      }, err => {
        layer.alert(err.msg);
      });
    }
  }
};
</script>

  <style scoped>
.attend_class_title {
  margin: 15px 0;
  font-size: 20px;
}
.weeks_class_box {
  display: flex;
  margin-bottom: 30px;
}
.w_order {
  width: 50%;
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-weight: 600;
  border: 1px solid #ccc;
}
.weeks_two_order {
  border-left: none;
}
.orderTrue {
  color: #3498db;
  background: rgb(204, 204, 204, 0.2);
}
.orderFalse {
  color: #ccc;
  background: #fff;
}
.students_content_data {
  position: relative;
  border-bottom: 1px solid #ccc;
  margin-top: 15px;
  width: 370px;
  min-height: 30px;
}
.students_name {
  width: 70px;
  float: left;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.students_data {
  width: 287px;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.no_border_bottom {
  border-bottom: none !important;
}
</style>