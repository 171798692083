<template>
  <!-- 加盟店表单 -->
  <div class="courseware_bag_form">
    <div class="cwb_form_box_info"
         :class="bigCoursewarePackageDetails.Catalogue?'maxheight':'minheight'">
      <div class="tltle_info">{{bigCoursewarePackageDetails.MainDemoName}}</div>
      <div class="">
        <div class="file_info"
             v-if="bigCoursewarePackageDetails.Catalogue">{{bigCoursewarePackageDetails.Catalogue}}</div>
        <div class="cwb_num_info"
             :style="bigCoursewarePackageDetails.Catalogue?'padding-left:35px;':'padding-left: 5px;'">课件数 :
          <span class="font_black">{{bigCoursewarePackageDetails.CoursewareSum}}</span>
        </div>
      </div>
    </div>
    <div class="form_info_edit form_info_list">
      <input-dialog :formTitle="'子课包'"
                    :formPlaceholder="'请选择'"
                    :required="true"
                    :readonly="bigCoursewareInfo.CoursewarePackageKey > 0?true:isReadonly"
                    v-model="dataInfo.CoursewarePackageKeyValue"
                    @showEldilog="selectCoursewareBag"></input-dialog>

      <input-select :formTitle="'适用校区'"
                    :required="true"
                    :id="'id'"
                    :name="'name'"
                    :readonly="isReadonly"
                    :dataList="[{ id: 1, name: '全部' },{ id: 2, name: '部分' }]"
                    :selectedInfo="vType"
                    @selectedItem="selectCampus"></input-select>

      <div v-if="dataInfo.TypeKey==2">
        <div class="campus_list_ul">
          <div class="campus_list_box pr"
               v-for="(item,index) in campusList"
               :key="index">
            <span :title="item.MainDemoName"
                  class="campus_item">{{item.MainDemoName}}</span>
            <span class="campus_delete_box"
                  @click="deleteAmpus(item)">×</span>
          </div>
        </div>
        <div class="pr">
          <div class="campus_list_box add_btn pr"
               @click="getCampusList"></div>
        </div>
      </div>
    </div>
    <div v-if="!isReadonly">
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import mutiSelectList from '../../../common/muti-select-list';
import {
  HQGetBigCoursewarePackageDetails,
  HQGetCoursewarePackageList,
  HQAddCoursewarePackageForManualRelease,
  HQEditMRCoursewarePackage,
  HQGetMRCoursewarePackageDetails
} from '../../../../API/workbench';
export default {
  data () {
    return {
      dataInfo: {
        OLAPKey: '', //	整型	必须		数据源：GET	子课包释放数据Key
        BigCoursewarePackageKey: '', //	整型	必须		数据源：GET	 大课包key
        CoursewarePackageKey: '', //	整型	必须		数据源：GET	小课包key
        CoursewarePackageKeyValue: '',
        TypeKey: 1, //	整型	必须		数据源：GET	 释放类型  1-全部校区;2-部分校区
        ClientKeylist: '' //	字符串	可选		数据源：GET	 Json数据，授权校
      },
      campusList: [],
      bigCoursewarePackageDetails: {}
    };
  },
  components: {
    mutiSelectList
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: false
    },
    bigCoursewareInfo: {
      type: Object,
      default: () => {
        return {
          BigCoursewarePackageKey: '', // 大课件包ID (必填)
          CoursewarePackageKey: '' // 子课件ID (编辑时必填)
        };
      }
    }
  },
  created () {
    if (this.bigCoursewareInfo.BigCoursewarePackageKey > 0) {
      this.getBigCoursewarePackageDetails();
    }
    if (this.bigCoursewareInfo.CoursewarePackageKey > 0) {
      this.getCoursewarePackageDetailInfo();
    }
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList', 'campusForList']);
  },
  computed: {
    // 结算方式
    vType () {
      return {
        type: this.dataInfo.TypeKey,
        name: this.dataInfo.TypeKey == 1 ? '全部' : this.campusList.length + '个'
      };
    }
  },
  methods: {
    // 获取大课件包详情信息
    getBigCoursewarePackageDetails () {
      HQGetBigCoursewarePackageDetails(this.bigCoursewareInfo.BigCoursewarePackageKey, this.bigCoursewareInfo.sourceKey).then(result => {
        console.log('获取大课件包详情信息', result.data);
        this.bigCoursewarePackageDetails = result.data;
        this.dataInfo.BigCoursewarePackageKey = this.bigCoursewarePackageDetails.OLAPKey;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 获取释放池子课包详情
    getCoursewarePackageDetailInfo () {
      HQGetMRCoursewarePackageDetails(this.bigCoursewareInfo.CoursewarePackageKey).then(result => {
        console.log('获取释放池子课包详情', result.data);
        Object.assign(this.dataInfo, result.data);
        this.campusList = result.data.ClientList;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 选择子课包
    selectCoursewareBag () {
      HQGetCoursewarePackageList(this.bigCoursewareInfo.BigCoursewarePackageKey).then(result => {
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择子课包',
          moduleName: 'singleSelectionList',
          data: { selectedKey: this.dataInfo.CoursewarePackageKey, dataSourceList: result.data, required: true },
          callBack: {
            onSelectedItem: (data) => {
              console.log(data, '子课包');
              this.dataInfo.CoursewarePackageKey = data.OLAPKey;
              this.dataInfo.CoursewarePackageKeyValue = data.MainDemoName;
            }
          }
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 选择适用校区类型
    selectCampus (obj) {
      this.dataInfo.TypeKey = obj.id;
      this.dataInfo.ClientKeylist = '';
    },
    // 获取校区列表添加
    getCampusList () {
      console.log('获取校区列表添加');
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '选择适用校区',
        moduleName: 'campusForList',
        data: { BigCoursewarePackageKey: this.bigCoursewarePackageDetails.OLAPKey, campusListTypeKey: 1 },
        callBack: {
          onSelectedItem: (data) => {
            console.log(data, '子课包');
            this.doCampusSelected(data);
          }
        }
      });
    },
    // 选中的校区队列
    doCampusSelected (obj) {
      var findIndex = -1;
      if (this.campusList.length > 0) {
        findIndex = this.campusList.findIndex(o => { return o.OLAPKey == obj.OLAPKey; });
      }
      if (findIndex < 0) {
        this.campusList.push(obj);
      }
    },
    // 删除该校区
    deleteAmpus (item) {
      this.campusList = this.campusList.filter((obj) => { return item.OLAPKey != obj.OLAPKey; });
    },
    initDate () {
      let keys = [];
      this.campusList.forEach(o => { keys.push(o.OLAPKey); });
      this.dataInfo.ClientKeylist = JSON.stringify(keys);
      console.log(this.dataInfo.ClientKeylist, '初始化');
    },
    // 提交
    submit (callback) {
      console.log('submit', this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      this.initDate();
      if (this.bigCoursewareInfo.CoursewarePackageKey > 0) {
        HQEditMRCoursewarePackage(this.dataInfo).then(result => {
          layer.alert('修改成功');
          this.$emit('afterSuccess');
          this.cancel();
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callback) {
            callback();
          }
        });
      } else {
        console.log(this.dataInfo, '新增成功');
        HQAddCoursewarePackageForManualRelease(this.dataInfo).then(result => {
          layer.alert('新增成功');
          this.$emit('afterSuccess');
          this.cancel();
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (callback) {
            callback();
          }
        });
      }
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
      if (!this.dataInfo.CoursewarePackageKey) {
        layer.alert('请选择子课包');
        flag = false;
      } else if (this.dataInfo.TypeKey == 2 && this.campusList.length == 0) {
        layer.alert('适用校区的类型为部分时，请至少添加一个校区');
        flag = false;
      }
      return flag;
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
<style>
.courseware_bag_form {
  min-height: 550px;
}
.courseware_bag_form .form_item .input_readonly {
  overflow: hidden !important;
}
.cwb_form_box_info {
  box-sizing: border-box;
  width: 370px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 20px;
}
.cwb_form_box_info.minheight {
  height: 73px;
}
.cwb_form_box_info.maxheight {
  height: 103px;
}
.cwb_form_box_info .tltle_info {
  padding: 10px 5px 10px 20px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cwb_form_box_info .file_info {
  font-size: 14px;
  border-top: 1px dashed #ececec;
  margin: 0px 16px;
  position: relative;
  padding: 10px 0 10px 35px;
}
.cwb_form_box_info .file_info::after {
  content: '';
  background: url(../../../../../public/cw-img/file_icon@2x.png) no-repeat 50%;
  background-size: 22px;
  width: 28px;
  height: 22px;
  position: absolute;
  top: 6px;
  left: 0;
}
.cwb_form_box_info .cwb_num_info {
  font-size: 14px;
  margin: 0 16px;
  color: #999999;
  font-family: 'Alibaba';
}
.campus_list_ul {
  overflow-y: auto;
  max-height: 300px;
}
.campus_list_box {
  height: 46px;
  width: 360px;
  border: 1px solid #e8ebef;
  border-radius: 3px;
  line-height: 46px;
  background: rgb(249, 251, 253);
  margin: 10px 0;
  padding-left: 25px;
}
.campus_delete_box {
  right: 0px;
  position: absolute;
  color: #c4c4c4;
  cursor: pointer;
  width: 30px;
  top: 0;
  text-align: right;
  padding-right: 10px;
}
.campus_list_box.add_btn {
  cursor: pointer;
}
.campus_list_box.add_btn:after {
  content: '';
  position: absolute;
  width: 2px;
  height: 18px;
  left: 180px;
  top: 12px;
  background-color: #3498dc;
}
.campus_list_box.add_btn:before {
  content: '';
  position: absolute;
  width: 18px;
  height: 2px;
  left: 172px;
  top: 20px;
  background-color: #3498dc;
}
.campus_list_box .campus_item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
  display: block;
}
.campus_select_input {
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
}
.campus_select_input .form_info_line .form_info_field {
  display: none;
}
</style>

