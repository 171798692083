<template>
  <div>
    <div class="payment_statistics_bottom schoolEduReport"
         v-if="isShowSchoolEduReport">
      <div class="course_statistics_table_search flex course_statistics_title">
        <div class="flex">
          <span>选择时间</span>
          <div class="title_date_select">
            <div class="el-select">
              <div class="el-input el-input--small el-input--suffix is-focus">
                <single-year-picker @chgDate="changeSaveDate"
                                    ref="monthPicker"
                                    :type="'month'"></single-year-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="course_statistics_search">
          <!-- <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入姓名、姓名首字母、手机号'"
                                  :clearAll="true"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div> -->
          <seleted-option :searchObj="searchObj"
                          :IsMTeaKey="IsMTeaKey"
                          @changeSearchObj="updateData"></seleted-option>
        </div>
      </div>
      <div class="course_statistics_table_box table_list_content">
        <table-list class="summarizing_list"
                    :key="tableKey"
                    :tableData="vdataReportList"
                    :totalNum="totalNum"
                    :PageCount="PageCount"
                    :tableColumns="tableCourseColumns"
                    :isSearchText="false"
                    :isNeedInnerScroll="false"
                    :defaultSort="defaultSort"
                    :queryParams="searchObj"
                    :footerContent="vFooterContent"
                    @loadTableData="getDataList"></table-list>
      </div>
    </div>
    <div v-else>
      <campus-supervise-school-statistics :searchObj="detailsObj"></campus-supervise-school-statistics>
    </div>
    <div>
      <dialog-factory ref="schoolEduReport"
                      :routerName="'schoolEduReport'"></dialog-factory>
    </div>
  </div>

</template>
<script>
// import dateOption from '../../class-spend-statistics-analyze/components/date-option';
import debtCourseHourseDetails from '../../../pop-up-menu/sign-up-payment/debt-course-hourse-details';
// import list from '../../class-spend-statistics-analyze/components/table';
import tableList from '../../../common/table-list/index';
import seletedOption from './/seleted-option';
import singleYearPicker from '../../../base-module/date-picker-common/single-year-picker';
// import inputSearchContainBtn from '../../../report/common/input-search-contain-btn';
import { schoolDistrictSuperintendentStatistics, getStudentRelatedMsg } from '../../../../API/workbench';

import campusSuperviseSchoolStatistics from '../../campus-supervise-school-statistics/index';
import dialogFactory from '../../../common/dialog-factory';
export default {
  data () {
    return {
      isShowSchoolEduReport: true,
      seletedCourseInfo: {},
      studentDetail: {},
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 15,
        date: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM'),
        // searchText: '',
        // orderBy: '',
        // type: '',
        // TeacherKey: 0,
        // TeacherKeyValue: '',
        // GradeKey: 0,
        // GradeKeyValue: '',
        // CourseKey: 0,
        // CourseKeyValue: '',
        // sequence: 'DESC',
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0)),
        // IsExportKey: 0,
        // IsMTeaKey: 1,
        SchoolKey: ''
      },
      // 详情参数
      detailsObj: {
        SchoolKey: '',
        StartDate: '',
        EndDate: ''
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '校区',
          prop: 'MainDemoName',
          width: 150,
          sortable: false,
          align: 'left',
          type: 'text-item',
          className: 'table_index_gray'
        },
        {
          label: '备课率',
          prop: 'PrepareLessonsRate',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '应备课次',
          prop: 'OughtToPrepareLessonsNum',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'number-item'
        },
        {
          label: '逾期课次',
          prop: 'PrepareLessonsOverdueNum',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'number-item'
        },
        {
          label: '备课次数',
          prop: 'PrepareLessonsNum',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'number-item'
        },
        {
          label: '课评率',
          prop: 'AssessmentRate',
          minWidth: 90,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '已课评',
          prop: 'AssessmentNum',
          minWidth: 90,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'number-item',
          decimalPlace: 0,
          eventName: this.$initJson.baseFunctionBtnName.read
        },
        {
          label: '未课评',
          prop: 'NotAssessmentNum',
          minWidth: 90,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'number-item'
        },
        {
          label: '批改率',
          prop: 'HomeWorkNotCheckedRate',
          minWidth: 90,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '已批改',
          prop: 'HomeWorkChecked',
          sortable: 'custom',
          minWidth: 90,
          align: 'left',
          isShow: true,
          type: 'number-item'

        },
        {
          label: '未批改',
          prop: 'HomeWorkNotChecked',
          sortable: 'custom',
          minWidth: 100,
          align: 'left',
          isShow: true,
          type: 'number-item'

        },
        // {
        //   label: '操作',
        //   prop: 'ArrivedNumCount',
        //   minWidth: 100,
        //   sortable: 'custom',
        //   align: 'left',
        //   isShow: true,
        //   type: 'number-item'
        // },
        // {
        //   label: '出勤率',
        //   prop: 'AttendanceRate',
        //   minWidth: 100,
        //   sortable: 'custom',
        //   align: 'left',
        //   isShow: true,
        //   type: 'text-item'
        // },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          // fixed: 'right',
          width: 80,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '详情',
                extend: {
                  click: (rowData) => {
                    console.log(rowData, '详情');
                    this.searchAppointData(rowData);
                  }
                }
              }

            ]
          }
        }

      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    debtCourseHourseDetails,
    singleYearPicker,
    seletedOption,
    campusSuperviseSchoolStatistics,
    dialogFactory
    // inputSearchContainBtn
  },
  props: {
    seletedDate: {
      type: String,
      default: ''
    },
    IsMTeaKey: {
      type: Number,
      default: 1
    }
  },
  // watch: {
  //   IsMTeaKey: {
  //     handler (c, o) {
  //       this.searchObj.IsMTeaKey = c;
  //     },
  //     deep: true
  //   }
  // },
  created () {
    this.registerBusEvent();
  },
  computed: {

    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // let info = o.ClassTime.substring(0, 10) + ',' + o.ClassTime.substring(11);
        // o.classTimeObj = info.split(',');
        // o.ClassTime = o.ClassTime.substring(5);
        o.ClassAwayAmountCount = Number(o.ClassAwayAmountCount || 0);
        o.ClassHourDebtCount = Number(o.ClassHourDebtCount) || 0;
        o.PrepareLessonsRate = o.PrepareLessonsRate == 0 ? '-' : parseInt(o.PrepareLessonsRate) + '%';
        o.AssessmentRate = o.AssessmentRate == 0 ? '-' : parseInt(o.AssessmentRate) + '%';
        o.HomeWorkNotCheckedRate = o.HomeWorkNotCheckedRate == 0 ? '-' : parseInt(o.HomeWorkNotCheckedRate) + '%';
        // o.AttenceTime = o.AttenceTime.substring(5, 16);
        // this.$set(o, 'OLAPKey', o.CourseNameKey);
        newArr.push(o);
      });
      return newArr;
    },
    vFooterContent () {
      return '共' + this.totalNum + '条数据';
    }
  },
  mounted () {
    this.$bus.on(this, 'wsAttendanceOrArrange', (data) => {
      console.log('触发wsAttendanceOrArrange');
      this.getDataList();
    });
    this.searchObj.IsMTeaKey = this.IsMTeaKey;
    if (this.seletedDate) {
      this.searchObj.startTime = this.seletedDate;
      this.searchObj.endTime = this.seletedDate;
    }
    this.getDataList();
  },
  methods: {
    changeSaveDate (val, startTime, endTime) {
      console.log(val, startTime, endTime, 'val, startTime, endTime');
      this.searchObj.startTime = startTime.replace(/\//g, '-');
      this.searchObj.endTime = endTime.replace(/\//g, '-');
      // this.searchObj.date = startTime.split('/')[0] + '-' + startTime.split('/')[1];
      // console.log(this.searchObj.date, 'this.searchObj.date');
      this.getDataList();
    },
    // 清除搜索条件
    clearSearchCondition () {
      // this.searchObj.pageIndex = 0;
      // this.searchObj.searchText = '';
      // // this.searchObj.orderBy = '';
      // this.searchObj.type = '';
      // this.searchObj.TeacherKey = 0;
      // this.searchObj.GradeKey = 0;
      // this.searchObj.CourseKey = 0;
      // // this.searchObj.sequence = 'DESC';
      // this.searchObj.startTime = '';
      // this.searchObj.endTime = '';
      // this.searchObj.IsExportKey = 0;
      // this.searchObj = {
      //   pageIndex: 0,   // 门店id
      //   pageSize: 15,
      //   searchText: '',
      //   orderBy: '',
      //   type: '',
      //   sequence: 'DESC',
      //   QueryID: 0,
      //   TeacherKey: 0,
      //   startTime: '',
      //   endTime: '',
      //   IsExportKey: 0
      // };
      // this.$refs.monthPicker.cleanTime();
      // this.getDataList();
    },
    updateData (v) {
      console.log(this.searchObj, v, 'updateData');

      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 外围调用. 更新日期查询
    searchData (item, date) {
      console.log('searchData', date);
      this.searchObj.TeacherKey = item.TeacherKey;
      this.searchObj.searchText = item.TeacherKeyValue;
      this.searchObj.pageIndex = 0;
      this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new(date).setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
      let seletedYearMonth = Date.new(date).getFullYear() + ' ' + (Date.new(date).getMonth() + 1);
      let nowYearMonth = Date.new().getFullYear() + ' ' + (Date.new().getMonth() + 1);
      if (seletedYearMonth != nowYearMonth) {
        this.searchObj.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new(date)), 'yyyy-MM-dd');
      } else {
        this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().getFullYear(), (Date.new().getMonth() + 1), 0));
      }
      this.getDataList();
    },
    search () {
      // this.searchObj.TeacherKey = 0;
      // this.searchObj.pageIndex = 0;
      // this.getDataList();
    },

    getDataList (queryParams) {
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }

      schoolDistrictSuperintendentStatistics(this.searchObj).then(result => {
        console.log(this.searchObj, '传参。。。。。。。。');
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.totalAbnormalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, '列表数据');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);

      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.popDialog:
          break;
        case this.$initJson.baseFunctionBtnName.read:
          break;
        case this.$initJson.baseFunctionBtnName.detail:
          // this.seletedCourseInfo = rowData;
          // this.$refs.newAttendanceArrangeDialog.isAttendanceArrangeShow = true;
          // this.$refs.newAttendanceArrangeDialog.getCourseInfo(this.seletedCourseInfo.ScheduleCourseKey);
          this.$emit('searchAppointData', rowData, this.searchObj);
          // this.isShowChangeTutorialDialog = true;
          break;
        default:
          break;
      }
    },
    searchAppointData (rowData) {
      // this.$emit('searchAppointData', rowData, this.searchObj);
      this.detailsObj.SchoolKey = rowData.SchoolKey;
      this.detailsObj.StartDate = this.searchObj.startTime;
      this.detailsObj.EndDate = this.searchObj.endTime;
      this.isShowSchoolEduReport = false;
      // this.$router.push({
      //   name: 'campusSuperviseSchoolStatistics',
      //   query: { SchoolKey: rowData.SchoolKey, dateMonth: this.searchObj.date }
      // }); // 新增
    },
    // 去还款
    doDebtDetailsClick (item) {
      getStudentRelatedMsg(item.StudentNameKey).then(res => {
        if (res.code == 0) {
          this.studentDetail = res.data;
          this.$nextTick(o => {
            this.isDebtCourseHoursDetailsShow = true;
          });
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 关闭弹窗页面
    closeDetails () {
      this.isDebtCourseHoursDetailsShow = false;
    },
    // 确认欠课时详情
    confirmDebtCourseHoursDetails (error) {
      if (error.code == 0) {
        this.getDataList();
        this.closeDetails();
      }
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        // console.log('openCustomDialog', dialogInfo);
        if (dialogInfo.routerName == 'schoolEduReport') {
          if (this.$refs.schoolEduReport) {
            this.$refs.schoolEduReport.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
          }
        }
      });
    }
  }
};
</script>
<style>
.schoolEduReport .summarizing_list .el-table__cell:nth-child(2) {
  color: #3498dc;
}
.schoolEduReport .summarizing_list .el-table__cell:nth-child(5) {
  color: #3498dc;
}
.schoolEduReport .summarizing_list .el-table__cell:nth-child(8) {
  color: #3498dc;
}
.schoolEduReport .summarizing_list .el-table__cell:nth-child(4) {
  color: #ff6c67;
}
.schoolEduReport .summarizing_list .el-table__cell:nth-child(7) {
  color: #ff6c67;
}
.schoolEduReport .summarizing_list .el-table__cell:nth-child(10) {
  color: #ff6c67;
}
.schoolEduReport{
  margin: 0!important;
}
</style>