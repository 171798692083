<template>
  <div class="recycledStudent_view">
    <!-- 在校学生 -->
    <div class="margin_left_right_div">
      <div class="general_summarizing_student template_minOrmax_width_div"
           style="width: auto;">
        <list ref="studentApplyCourseTable"
              class="summarizing_list"
              :tableData="studentList"
              :totalNum="totalNum"
              :pageTotal="pageTotal"
              :PageCount="PageCount"
              :tableColumns="tableColumns"
              :isSearchText="false"
              :isNeedInnerScroll="false"
              :isShowSearch="true"
              :rowKey="'StudentKey'"
              :defaultSort="defaultSort"
              :paramsForStudent="paramsForStudent"
              @btnFnClick="btnClickAfter"
              @searchStudent="searchStudent"
              @tableBtnClick="doAfterTableBtnClick"
              @loadTableData="getMessageSearcForStudent"
              @clearSearchParams="clearSearchCondition"></list>
      </div>
    </div>
    <!-- 恢复原因 -->
    <textarea-pop :title="textareaTitle"
                  :message="textareaText"
                  :placeholderText="'请输入'+textareaTitle+'...'"
                  ref="textareaPop"
                  @submitStudentArrangeSupport="regainStudentClick"></textarea-pop>
    <div>
      <dialog-factory ref="recycledStudent"
                      :key="'recycledStudent'"
                      :routerName="'recycledStudent'"></dialog-factory>
    </div>
  </div>
</template>
<script>
import {
  MessageSearchPageForRecycleStudent,
  EditRecycleReason,
  RecoverStudent
} from '../../../API/workbench.js';

import list from './table';
import textareaPop from '../../common/textarea-pop';
import dialogFactory from '../../common/dialog-factory';
export default {
  name: 'recycledStudent',
  data () {
    return {
      cdnUrl: '',
      textareaTitle: '恢复原因',
      textareaText: '',
      curSelectedStudent: {},
      studentList: [], // 按学生 的 学生列表
      paramsForStudent: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: 'RecycleTime',
        sequence: 'desc',
        searchText: ''
      },
      PageCount: 0,
      totalNum: 0,
      pageTotal: 0, // 分页器总数
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          align: 'center',
          width: 65,
          isShow: true,
          className: 'table_index_gray'
        },
        {
          label: '删除日期',
          prop: 'RecycleTime',
          width: 156,
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0,
          className: ''
        },
        {
          label: '头像',
          prop: 'HeadImgSupport',
          width: 82,
          align: 'center',
          isShow: true,
          type: 'studentPhoto',
          decimalPlace: 0,
          className: ''
        },
        {
          label: '学生名',
          prop: 'MainDemoName',
          width: 154,
          align: 'left',
          isShow: true,
          type: 'studentName',
          decimalPlace: 0,
          className: ''
        },
        {
          label: '来源',
          prop: 'RegisterTypeKeyValue',
          width: 135,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0,
          className: ''
        },
        {
          label: '登记日期',
          prop: 'RegisterTime',
          width: 160,
          sortable: false,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },

        {
          label: '删除人',
          prop: 'RecycleOperateKeyValue',
          width: 125,
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0,
          className: ''
        },
        {
          label: '删除原因',
          prop: 'RecycleReasonScript',
          width: '',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0,
          className: ''
        },
        {
          label: '操作',
          prop: '',
          sortable: false,
          width: 88,
          align: 'center',
          tableBtnGroup: [
            {
              name: '恢复',
              eventName: 'regainStudent',
              Stutas: '',
              className: () => {
                return 'btn_light_blue';
              },
              roleName: ''
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        }
      ],
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  components: {
    list,
    textareaPop,
    dialogFactory
  },
  created () {
    this.cdnUrl = this.$store.getters.CDNURL;
  },
  mounted () {
    this.getMessageSearcForStudent();
    this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
      if (dialogInfo.routerName == 'recycledStudent') {
        this.openCustomDialogByFactoryDialog(dialogInfo);
      }
    });
  },
  methods: {
    // 获取学生列表
    getMessageSearcForStudent () {
      if (this.paramsForStudent.pageIndex == 0) {
        this.studentList = [];
      }
      MessageSearchPageForRecycleStudent(this.paramsForStudent).then(result => {
        // this.initSummaryData();
        this.PageCount = result.data.PageCount;
        this.totalNum = result.data.Total;
        this.pageTotal = result.data.Total;   // 分页数
        if (result.data && result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach(o => {
            o.HeadImgSupport = this.changeStudentPhoto(o);
            if (o.Number < 10) {
              o.Number = '0' + o.Number;
            }
          });
          this.studentList = result.data.PageDataList.sort((a, b) => { return a.Number - b.Number; });
        } else {
          this.studentList = [];
        }
      }).finally(() => {

      });
    },
    // 搜索
    searchStudent (searchText) {
      if (searchText) {
        this.paramsForStudent.searchText = searchText;
      }
      this.paramsForStudent.pageIndex = 0;
      console.log('searchStudent', searchText);
      this.getMessageSearcForStudent();
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.paramsForStudent.searchText = '';
      this.paramsForStudent.pageIndex = 0;
      this.paramsForStudent.status = 0;
      this.$refs.studentApplyCourseTable.queryParams.pageIndex = 0;
      this.getMessageSearcForStudent();
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName) {
      console.log(rowData, eventName, '按钮点击');
      this.curSelectedStudent = rowData;
      switch (eventName) {
        case 'regainStudent':
          this.textareaText = '';
          this.$refs.textareaPop.isAlterMessagePopup = true;
          // this.regainStudentClick();
          break;
        case this.$initJson.baseFunctionBtnName.popDialog:
          // this.textareaTitle = '删除原因';
          // this.textareaText = this.curSelectedStudent.RecycleReasonScript;
          // this.$refs.textareaPop.isAlterMessagePopup = true;
          break;
        default:
          break;
      }
    },
    // 恢复学生
    regainStudentClick (message) {
      if (message.length == 0) {
        layer.alert('请填写恢复原因');
        return false;
      }
      RecoverStudent(this.curSelectedStudent.OLAPKey, message).then(res => {
        this.$refs.textareaPop.isAlterMessagePopup = false;
        layer.alert('恢复成功');
        this.searchStudent();
      }, err => {
        layer.alert(err.msg);
      });
    },
    // 删除原因 提交
    submitStudentArrangeSupport (message, callBack) {
      EditRecycleReason(this.curSelectedStudent.OLAPKey, message).then(res => {
        this.curSelectedStudent.RecycleReasonScript = message;
        layer.alert('修改成功');
        if (typeof callBack == 'function') {
          callBack();
        }
      });
    },
    changeStudentPhoto (data) {
      let imgUrl = data.HeadImgSupport || '';
      if (imgUrl && imgUrl.indexOf('deflut_studentPhoto') == -1 && imgUrl.indexOf('data:image/png;base64') == -1) {
        if (imgUrl.indexOf('http') == -1) {
          return this.cdnUrl + imgUrl;
        } else {
          return imgUrl;
        }
      } else {
        if (data.SexKeyValue == '男' || Number(data.SexKey) == 2) {
          return (
            require('../../../../public/image/deflut_studentPhoto_boy.png')
          );
        } else {
          return (
            require('../../../../public/image/deflut_studentPhoto_gril.png')
          );
        }
      }
    },

    btnClickAfter (eventName) {
      console.log(eventName, 'btnClickAfter');
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.studentApplyCourseReportDialogFactory) {
        this.$refs.studentApplyCourseReportDialogFactory.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    }

  },
  computed: {}
};
</script>
