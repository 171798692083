<template>
  <div class="course_box_list open"
       :class="vBoxSatus"
       @click.stop="showDetail">
    <!-- close 代表展开 , open有数据未展开,  为空代码没有排课数据 -->
    <div class="course_box_list_name">
      <!-- <el-tooltip popper-class="mendian_block_tooltip" effect="light" visible-arrow="false" :content="courseNameItem.MainDemoName" :disabled="disabled" placement="top">
        <div><span ref="nameBox">{{courseNameItem.MainDemoName}}</span></div>
      </el-tooltip> -->
      <span class="name_box"
            :title="!disabled1?item.GradeClassKeyValue:''">
        <span ref="tooltipBox1">{{item.GradeClassKeyValue}}</span>
      </span>
      <!-- <el-tooltip :content="item.GradeClassKeyValue"
                          :disabled="disabled1"
                          popper-class="mendian_introduce_tooltip"
                          placement="bottom"
                          effect="light">
                <div class="fl"
                     style="max-width:100%"><span ref="tooltipBox1"><span class="name_box">{{item.GradeClassKeyValue}}</span></span></div>
      </el-tooltip> -->
      <span class="font_gray">每周 <span class="font_black">{{vRulesList.length}}</span>节课丨<span class="font_black">{{vTotalSyncArranged}}</span>学生</span>
      <div class="course_box_list_name_add"
           @click.stop="toAddLoopCourse"
           v-if="false">+</div>
    </div>
    <div class="course_box_list_content"
         v-if="isshowDetail"
         @click.stop="keep">
      <courseRulesList ref="courseRulesList"
                       v-for="rulesItem in vRulesList"
                       :key="rulesItem.StructuredRuleKey"
                       :item="rulesItem"
                       :studentList="studentList"
                       :searchText="searchText"
                       :isTemporaryStu="isTemporaryStu"
                       :copyData="copyData"
                       :seletedStructuredRuleKey="seletedStructuredRuleKey"
                       @updateClassStudentList="getStudentArrangedListByGradeAndCourse"
                       @quitOraddStudentToClass="quitOraddStudentToClassAfter"></courseRulesList>
    </div>
  </div>
</template>
<script>
import courseRulesList from './course-rules-list';
import { gradeCourseStudentArranged } from '../../../API/workbench';
export default {
  data () {
    return {
      isshowDetail: false,
      studentList: [],
      disabled1: false
      // copyData: {
      //   StructuredRuleKey: 0,
      //   copyStudentList: []
      // }
    };
  },
  props: {
    item: {
      type: Object
    },
    searchText: String,
    isTemporaryStu: {
      type: Boolean,
      default: false
    },
    seletedStructuredRuleKey: Number,
    copyData: {
      type: Object,
      default: {
        StructuredRuleKey: 0,
        copyStudentList: []
      }
    }
  },
  components: {
    courseRulesList
  },
  created () {

  },
  watch: {
    'item.studentList': {
      handler (c, o) {
        if (c.length == 0) {
          if (this.isshowDetail) {
            this.getStudentArrangedListByGradeAndCourse();
            if (this.$refs.courseRulesList.length > 0) {
              this.$refs.courseRulesList.forEach(o => {
                o.updateRulesStuList();
              });
            }
          }
        }
      }
    }
  },
  mounted () {
    if (this.$refs.tooltipBox1) {
      this.disabled1 = this.$refs.tooltipBox1.offsetWidth < this.$refs.tooltipBox1.parentElement.offsetWidth - 40;
    }
  },
  computed: {
    vRulesList () {
      this.item.rulesList.sort((x, y) => {
        return Number(x.WeekKey) - Number(y.WeekKey);
      });
      return this.item.rulesList;
    },
    vTotalSyncArranged () {
      let num = 0;
      this.item.rulesList.forEach(o => {
        num += Number(o.Num_SyncArranged);
      });
      return num;
    },
    // 列表点击状态,
    vBoxSatus () {
      if (this.vRulesList.length == 0) {
        return '';
      } else {
        if (this.isshowDetail) {
          return 'close';
        } else {
          return 'open';
        }
      }
    }
  },
  methods: {
    // 显示更多按钮.
    showDetail () {
      if (this.vRulesList.length == 0) {
        return false;
      } else {
        this.isshowDetail = !this.isshowDetail;
        if (this.isshowDetail) {
          this.getStudentArrangedListByGradeAndCourse();
        }
      }
    },
    // 获取该班级下所有排课规律学生.
    getStudentArrangedListByGradeAndCourse () {
      gradeCourseStudentArranged(this.item.GradeClassKey, this.item.CourseNameKey).then(result => {
        console.log('getStudentArrangedListByGradeAndCourse', result.data);
        // result.data.forEach(o => {
        //   this.item.studentList.push(o);
        // });
        this.studentList = this.$utils.parseJson(result.data);
        this.item.studentList = this.$utils.parseJson(result.data);
      });
    },
    // 加学生入班或者退班
    quitOraddStudentToClassAfter (StudentKey, type) {
      let locaIndex = this.studentList.findIndex(o => {
        return o.StudentKey == StudentKey;
      });
      if (locaIndex >= 0) {
        let item = this.$utils.parseJson(this.studentList[locaIndex]);
        if (type == 'add') {
          item.IsInGradeClassKey = 1;
          item.IsInGradeClassKeyValue = '有';
        } else {
          item.IsInGradeClassKey = 0;
          item.IsInGradeClassKeyValue = '无';
        }
        this.studentList.splice(locaIndex, 1, item);
      }
    },
    toAddLoopCourse () {
      // 需要传的参数
      // let WeekKey = item.WeekKey == 7 ? '0' : item.WeekKey;
      let paramsData = {
        SClassTimeName: '', // 上课开始时间
        EClassTimeName: '', // 上课结束时间
        CircleStartTime: '', // 上课开始日期
        CircleEndTime: '', // 上课结束日期
        FrequencyKey: 2, // 多课
        frequenceInfo: [],
        BJPKIDKey: this.item.GradeClassKey, // 班级ID
        BJPKIDKeyValue: this.item.GradeClassKeyValue, // 班级名
        ClassSetKey: this.item.CourseNameKey, // 课名ID
        ClassSetKeyValue: this.item.CourseNameKeyValue, // 课名
        ClassRoomIDSName: '', // 教室ID
        ClassRoomNames: '', // 教室名
        LecturerKeyValue: '', // 主讲ID
        LecturerKey: '', // 主讲名
        PeriodCount: 1, // 课消
        LeaveClassKey: 0, // 请假是否扣课时
        IsleaveKey: 0, // 旷课是否扣课时
        WeekKey: 0 // 周几
      };
      let moduleInfo = {
        name: '新建课表',
        moduleName: 'newCircleCourseTable',
        routerName: this.$route.name,
        data: { dataInfo: paramsData },
        callBack: {
          afterSuccess: () => {
            this.$emit('addRulesCourse');
          }
        }
      };
      this.$bus.emit('needOpenRouter', moduleInfo);
    },
    // 防止点击时触发了showDetail事件
    keep () {

    }
  }

};
</script>
