<template>
  <div style="padding:20px;width: 540px;">
    <div class="table_list_content summarizing_list">
      <table-list ref="tableListRef"
                  :tableData="dataList"
                  :tableColumns="tableColumns"
                  :showPagePagination="false"
                  @loadTableData="getDataList"></table-list>
    </div>

  </div>
</template>
<script>
import { GetSaaSClientAddressLogList } from '../../../../../API/workbench';
import tableList from '../../../../common/table-list/index';
export default {
  data () {
    return {
      tableColumns: [
        {
          label: '变更时间',
          prop: 'GenerateTimeText',
          align: 'left',
          width: 155,
          type: 'text-item',
          sortable: false
        },
        {
          label: '变更地址',
          prop: 'Location',
          align: 'left',
          // width: 255,
          type: 'text-item'
        }
      ],
      totalNum: 0,
      dataList: []
    };
  },
  components: {
    tableList
  },
  props: {
    CampusKey: {
      type: Number || String,
      default: 0
    }
  },
  created () {
    this.getDataList();
    let preDate = '2022-01-26 02:03:04';
    let result = this.handlerDateDurationCurrent(preDate);
    console.log('时间', result);
  },
  computed: {
  },
  methods: {
    // 分页查询校区变更地址历史记录
    getDataList () {
      GetSaaSClientAddressLogList(this.CampusKey).then(result => {
        let newDate = [''];
        result.data.forEach(o => {
          if (o.GenerateTime) {
            newDate = /\d{4}-\d{1,2}-\d{1,2}/g.exec(o.GenerateTime);
          }
          o.GenerateTimeText = newDate[0];
          newDate = [''];
        });
        this.dataList = result.data;
      }, error => {
        layer.alert(error.msg);
      });
    },
    handlerDateDurationCurrent (time) {
      let d1 = new Date(time);
      let d2 = new Date();

      let cha = Math.abs(d2.getTime() - d1.getTime());
      let days = parseInt(cha / (24 * 60 * 60 * 1000));
      let hours = parseInt(cha % (24 * 60 * 60 * 1000) / (60 * 60 * 1000));
      let mins = parseInt(cha % (60 * 60 * 1000) / (60 * 1000));
      if (days) {
        return `+ ${days}d ${hours}h ${mins}m`;
      } else if (hours) {
        return `+ ${hours}h ${mins}m`;
      } else {
        return `+ ${mins}m`;
      }
    }
  }
};
</script>