<template>
  <!-- 有效期 -->
  <div class="form_info_edit content_marginRL ">

    <div class="mendian_setting_content">
      <input-option :formTitle="'结束方式'"
                    :readonly="false"
                    :required="false"
                    class="no_border_bottom"
                    :selectList="[{name:'限时长',key:0},{name:'限日期',key:1}]"
                    :radioKey="showFormDataType"
                    @change="chgParentsPromotionSetting">
      </input-option>
    </div>
    <div class="taoCanSetting clearfix">
      <div v-if="showFormDataType==0"
           class="label_radio_bg_white">
        <input-number class="no_padding"
                      :min="0"
                      :formTitle="'数 量'"
                      :placeholder="'必填'"
                      v-model="formData.PeriodCount"
                      :decimalPlaces="0"
                      :required="true"></input-number>

        <input-select class="popTop_3"
                      :formTitle="'单 位'"
                      :required="true"
                      :readonly="false"
                      :id="'id'"
                      :name="'name'"
                      :selectedInfo="vSelectedUnitType"
                      :dataList="unitSourceData"
                      @selectedItem="doAfterUnitTypeChange"></input-select>
      </div>

      <div v-if="showFormDataType==1"
           class="label_radio_bg_white">
        <input-date v-model="formData.StartTime"
                    :formTitle="'生效日期'"
                    :readonly="false"
                    :elementId="'startPeriod'"
                    :required="false"
                    :minDate="$utils.formatDateToLine(Date.new())"
                    :formPlaceholder="'可选'"></input-date>

        <input-date v-model="formData.ExpireTime"
                    :formTitle="'失效日期'"
                    :readonly="false"
                    :elementId="'endPeriod'"
                    :required="true"
                    :minDate="$utils.formatDateToLine(Date.new())"
                    :formPlaceholder="'必填'"></input-date>
      </div>
    </div>

    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="submitDate"
                             @cancelClick="closeFrom"></save-cancel-btn-group>

    </div>
  </div>
</template>
<script>

export default {
  data () {
    return {
      formData: {
        UnitKeyValue: '年',
        UnitKey: 2,
        PeriodCount: 1,
        StartTime: '',
        ExpireTime: ''
      },
      showFormDataType: 0
    };
  },

  props: {
    taocanInfo: Object, // 套餐信息
    unitSourceData: {
      type: Array,
      default: function () {
        return [
          { id: 2, name: '年' },
          { id: 3, name: '月' },
          { id: 4, name: '日' }
        ];
      }
    }   // 显示周期
  },
  computed: {
    vSelectedUnitType: {
      get () {
        return {
          id: this.formData.UnitKey,
          name: this.formData.UnitKeyValue
        };
      },
      set (newValue) {
        this.formData.UnitKey = newValue.id;
        this.formData.UnitKeyValue = newValue.name;
      }
    }
  },
  created () {
    console.log(this.taocanInfo, '默认有效期');
    if (this.taocanInfo.PeriodCount) {
      this.formData.UnitKeyValue = this.taocanInfo.UnitKeyValue;
      this.formData.UnitKey = this.taocanInfo.UnitKey;
      this.formData.PeriodCount = this.taocanInfo.PeriodCount;
    }
    this.formData.StartTime = this.taocanInfo.StartTime;
    this.formData.ExpireTime = this.taocanInfo.ExpireTime;
    if (this.taocanInfo.ExpireTime) {
      this.showFormDataType = 1;
    } else {
      this.showFormDataType = 0;
    }
  },
  watch: {

  },
  methods: {
    chgParentsPromotionSetting (name, key) {
      console.log(name, key, '选项');
      this.showFormDataType = Number(key);
      if (this.showFormDataType == 1) {
        this.formData.StartTime = this.taocanInfo.StartTime || '';
        this.formData.ExpireTime = this.taocanInfo.ExpireTime || '';
        this.formData.PeriodCount = '';
        this.formData.UnitKeyValue = '';
        this.formData.UnitKey = '';
      } else {
        this.formData.PeriodCount = this.taocanInfo.PeriodCount || 1;
        this.formData.UnitKeyValue = this.taocanInfo.UnitKeyValue || '年';
        this.formData.UnitKey = this.taocanInfo.UnitKey || 2;
        this.formData.StartTime = '';
        this.formData.ExpireTime = '';
      }
    },
    // 选择有效期
    doSelectDateClick (date) {
      console.log(date, '选择默认有效期');
      this.selectedDate(date);
      this.dateInfo = date;
    },
    // 设置有效期单位
    doAfterUnitTypeChange (date) {
      this.formData.UnitKeyValue = date.name;
      this.formData.UnitKey = date.id;
    },
    //  关闭
    closeFrom () {
      this.hideBox = false;
      this.$emit('closeFrom');
    },
    // 提交
    submitDate (callBack) {
      if (callBack) {
        callBack();
      }
      if (this.showFormDataType == 0 && (!this.formData.PeriodCount || Number(this.formData.PeriodCount) == 0)) {
        layer.alert('有效期数量必须大于0');
      } else if (this.showFormDataType == 1 && !this.formData.ExpireTime) {
        layer.alert('课时失效日不能为空');
      } else {
        this.taocanInfo.PeriodCount = this.formData.PeriodCount;
        this.taocanInfo.UnitKey = this.formData.UnitKey;
        this.taocanInfo.UnitKeyValue = this.formData.UnitKeyValue;
        this.taocanInfo.StartTime = this.formData.StartTime;
        this.taocanInfo.ExpireTime = this.formData.ExpireTime;
        // this.$emit('submitDate', this.taocanInfo);
        this.closeFrom();
      }
    }

  }
};
</script>


