<template>
  <!-- 课件授权管理 -->
  <div class="super_management ">
    <div class="table_fixed_nav_box">
      <div class="table_fixed_nav tltle_margin">
        <div @click="showPendingProcessingClick"
             class="table_fixed_nav_list"
             :class="{opt:showManagement=='pendingProcessing'}">待处理</div>
        <div @click="showProcessedLocationClick"
             class="table_fixed_nav_list"
             :class="{opt:showManagement=='processedLocation'}">已处理</div>
      </div>
    </div>
    <div>
      <!-- 待处理列表 -->
      <div class="showManagement"
           v-if="showManagement=='pendingProcessing'">
        <pending-processing :IsConfirmKey="confirmKey"
                            @goCheck="showProcessedLocationClick"></pending-processing>
      </div>
      <!-- 已处理列表 -->
      <div class="showManagement"
           v-else-if="showManagement=='processedLocation'">
        <processed-location :IsConfirmKey="confirmKey"></processed-location>
      </div>

    </div>
    <div>
      <dialog-factory ref="coursewareAuthorizationManagementDialog"
                      :routerName="'coursewareAuthorizationManagement'"></dialog-factory>
    </div>
  </div>
</template>
<script>
import DialogFactory from '../../common/dialog-factory';
import pendingProcessing from './pending-processing';
import processedLocation from './pending-processed';
export default {
  name: 'campusPositioningReport',
  data () {
    return {
      showManagement: 'pendingProcessing',
      confirmKey: 2
    };
  },
  components: {
    pendingProcessing,
    DialogFactory,
    processedLocation
  },
  created () {
    this.registerBusEvent();
  },
  mounted () {

  },
  methods: {
    // 切换到待处理
    showPendingProcessingClick () {
      this.confirmKey = 2;
      this.showManagement = 'pendingProcessing';
      console.log('切换到待处理');
    },
    // 切换已处理
    showProcessedLocationClick () {
      this.confirmKey = 1;
      this.showManagement = 'processedLocation';
      console.log('切换已处理');
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        console.log('openCustomDialog', dialogInfo);
        if (dialogInfo.routerName == 'campusPositioningReport') {
          this.openCustomDialogByFactoryDialog(dialogInfo);
        }
      });
    },
    openCustomDialogByFactoryDialog (dialogInfo) {
      if (this.$refs.coursewareAuthorizationManagementDialog) {
        this.$refs.coursewareAuthorizationManagementDialog.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
      }
    }

  },
  computed: {}
};
</script>
<style scoped>
.super_management .tltle_margin {
  margin: 0 auto;
}
.showManagement {
  margin: 0 auto;
  width: 1290px;
}
.super_management {
  margin-bottom: 0px;
  margin-top: 90px;
}
.table_fixed_nav_list {
  padding: 0;
  width: 155px;
}
</style>