<template>
  <div class="content_search right_table_box"
       style="padding-right: 0;">
    <div class="course_search_form">
      <div class="course_search_input">
        <input type="text"
               :value="value"
               @input="updateVal($event.target.value)"
               @change="changeVal($event.target.value)"
               :placeholder="placeholder">
        <span v-show="value"
              class="home_remove_btn"
              @click="clearSearch"></span>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    value: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    placeholder: {
      type: String
    }
  },
  methods: {
    updateVal (val) {
      this.$emit('input', val);
    },
    changeVal (val) {
      this.$emit('change', val);
    },
    search () {
      this.$emit('search');
    },
    clearSearch () {
      this.$emit('clearSearch');
    }
  }

};
</script>
