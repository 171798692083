<template>
  <div class="index_view_menu box_withe_radius">
    <div class="index_title">常用功能</div>
    <div class="chain_workbench_file_new pr menu_content" style="margin-top:-10px">
      <function-btn
        v-for="(item, key) in optionList"
        :key="key"
        @clickItem="clickAfter"
        :item="item"
      >
        <menu-item class="file_list pr" @clickAfter="clickAfter" :item="item"></menu-item>
      </function-btn>
     
    </div>
  </div>
</template>
<script>
import menuItem from './menu-item';
export default {
  data () {
    return {
      optionList: [
          {
          name: '',
          routerName: 'hqCoursewareManagement',
          routertitle: '课件管理',
          value: '上传课件',
          auth: true,
          roleName: '',
          keyvalue: '08',
          ModulePowerKey: 107,
          moduleName: '',
          urlPath: '../../../../assets/img/index_menu_icon03.png',
          verifyIsSignYKKey: 1
        },
        {
          name: '',
          value: '授权课件',
          keyvalue: '01',
          ModulePowerKey: 0,
          urlPath: '../../../../assets/img/index_menu_icon01.png'
        },
        {
          name: '',
          value: '端口管理',
          routerName: 'campusManagement',
          routertitle: '端口管理',
          keyvalue: '13',
          ModulePowerKey: 109,
          moduleName: '',
          urlPath: '../../../../assets/img/index_menu_icon02.png'
        },
        {
          name: '',
          value: '课件安全',
          keyvalue: '12',
          ModulePowerKey: 0,
          moduleName: '',
          urlPath: '../../../../assets/img/index_menu_icon02.png'
        },
         {
          name: '',
          routerName: 'chainWorkBench',
          routertitle: '工作台',
          auth: true,
          roleName: '',
          ModulePowerKey: 115,
          moduleName: 'staffManagementList',
          value: '员工管理',
          keyvalue: '13'
        },
        {
          name: '',
          auth: true,
          roleName: '',
          value: '招商加盟',
          keyvalue: '02',
          ModulePowerKey: 0,
          moduleName: '',
          urlPath: '../../../../assets/img/index_menu_icon02.png'
        },
      
        {
          name: '',   
          value: '系统设置',
          keyvalue: '09',
          ModulePowerKey: 0,
          urlPath: '../../../../assets/img/index_menu_icon04.png'
        },
        {
          name: '',
          value: '更多功能',
          keyvalue: '07',
          ModulePowerKey: 0,
          urlPath: '../../../../assets/img/index_menu_icon06.png'
        }
      ]
      // optionList: [
      //   {
      //     name: '',
      //     value: '添加投资人',
      //     routerName: 'chainWorkBench',
      //     routertitle: '首页',
      //     keyvalue: '01',
      //     ModulePowerKey: 135,
      //     moduleName: 'addClewForm',
      //     urlPath: '../../../../assets/img/index_menu_icon01.png'
      //   },
      //   {
      //     name: '',
      //     value: '招商管理',
      //     routerName: 'attractInvestmentManage',
      //     routertitle: '招商管理',
      //     keyvalue: '13',
      //     ModulePowerKey: 135,
      //     moduleName: '',
      //     urlPath: '../../../../assets/img/index_menu_icon02.png'
      //   },
      //   {
      //     name: '',
      //     value: '招商工作台',
      //     routerName: 'merchantsCounselorWorkbench',
      //     routertitle: '招商顾问工作台',
      //     keyvalue: '12',
      //     ModulePowerKey: 136,
      //     moduleName: '',
      //     urlPath: '../../../../assets/img/index_menu_icon02.png'
      //   },
      //   {
      //     name: '',
      //     routerName: 'crmJoinSignManage',
      //     routertitle: '加盟签单管理',
      //     auth: true,
      //     roleName: '',
      //     value: '签约收款',
      //     keyvalue: '02',
      //     ModulePowerKey: 132,
      //     moduleName: '',
      //     urlPath: '../../../../assets/img/index_menu_icon02.png'
      //   },
      //   {
      //     name: '',
      //     routerName: 'hqCoursewareManagement',
      //     routertitle: '课件管理',
      //     value: '课件加密上传',
      //     auth: true,
      //     roleName: '',
      //     keyvalue: '08',
      //     ModulePowerKey: 107,
      //     moduleName: '',
      //     urlPath: '../../../../assets/img/index_menu_icon03.png',
      //     verifyIsSignYKKey: 1
      //   },
      //   {
      //     name: '',
      //     routerName: 'chainWorkBench',
      //     routertitle: '工作台',
      //     value: '授权课件',
      //     auth: true,
      //     roleName: '',
      //     keyvalue: '09',
      //     ModulePowerKey: 108,
      //     moduleName: 'authorizationCoursewareForm',
      //     urlPath: '../../../../assets/img/index_menu_icon04.png',
      //     verifyIsSignYKKey: 1
      //   },
      //   // {
      //   //   name: '',
      //   //   routerName: 'authorizationCourseware',
      //   //   routertitle: '授权课件',
      //   //   value: '课件授权管理',
      //   //   auth: true,
      //   //   roleName: '',
      //   //   keyvalue: '09',
      //   //   ModulePowerKey: 108,
      //   //   moduleName: '',
      //   //   urlPath: '../../../../assets/img/index_menu_icon04.png',
      //   //   verifyIsSignYKKey: 1
      //   // },
      //   {
      //     name: '',
      //     routerName: 'chainWorkBench',
      //     routertitle: '工作台',
      //     auth: true,
      //     roleName: '',
      //     ModulePowerKey: 115,
      //     moduleName: 'staffManagementList',
      //     value: '员工管理',
      //     keyvalue: '13'
      //   },
      //   {
      //     name: '',
      //     value: '更多功能',
      //     keyvalue: '07',
      //     ModulePowerKey: 0,
      //     urlPath: '../../../../assets/img/index_menu_icon06.png'
      //   }
      // ]
    };
  },
  components: {
    menuItem
  },
  created () {

  },
  props: {},
  computed: {

    vMendianInfo () {
      return this.$store.getters.SaaSClientInfo;
    }
  },
  methods: {
    onItemClick (optionItem) {
      this.isShowOption = false;
      this.$bus.emit('needOpenRouter', item);
    },
    clickAfter (item) {
      console.log('clickAfter', item);
      this.isShowOption = false;
      if (item.name == '直属设置') {
        item.name = '直属店设置';
      }
      if (item.verifyIsSignYKKey == 1) {
        if (this.vMendianInfo.IsOpenChainKey != 1) {
          layer.alert('未开通连锁功能，请联系您的专属客服');
          return false;
        }
        this.openCustomDialogByFactoryDialog(item);
        return false;
      } else if (item.routerName == 'cloudClassAttestation') {
        if (this.vMendianInfo.IsSignYKKey != 1) {
          layer.alert('请先与云课网签约');
          return false;
        }
        this.$router.push({
          name: 'cloudClassAttestation',
          query: {
            showType: 'details'
          }
        });
        return false;
      } else if (item.routerName == 'coursewareBagAutoreleasePool') {
        this.$router.push('/coursewareBagAutoreleasePool');
        return false;
      } else if(!item.moduleName){
        this.$router.push('/'+item.routerName);
        return false;
      }
      this.openCustomDialogByFactoryDialog(item);
    },
    openCustomDialogByFactoryDialog (item) {
      this.isShowdropDownBox = false;
      this.isShowCheckInBox = false;
      if (item.moduleName == '') {
        this.$bus.emit('needOpenRouter', item);
      } else {
        if (item.moduleName == 'addClewForm'||item.moduleName=='screenRecordList'||item.moduleName=='coursewareTeachingmodeSetting') {
          if(item.moduleName == 'addClewForm'){
            item.callBack = { afterSuccess: () => { this.$emit('addClewAfter'); }};
          }
          this.$emit('openCommonCustomDialogByFactoryDialog', item);
        } else {
          this.$emit('openCustomDialogByFactoryDialog', item);
        }
      }
    }
  }
};
</script>
<style>
.chain_workbench_file_new {
  display: flex;
  justify-content: space-between;
  /* padding: 28px 24px 0; */
}

.chain_workbench_file_new .file_list {
  flex: 1;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  padding: 0 1.5%;
}

.chain_workbench_file_new .file_list:hover {
  border: 1px solid #f85a59;
  border-radius: 5px;
  cursor: pointer;
}
.chain_workbench_file_new .file_list {
  height: 100px;
  width: 100px;
  padding-top: 15px;
}
.chain_workbench_file_new .file_list:hover .file_name {
  color: #3498db;
}
.chain_workbench_file_new .file_img {
  width: 34px;
  height: 34px;
  margin: 0 auto 14px;
}
</style>