<template>
  <div>
    <div class="form_info_edit form_info_list"
         style="height: 275px">
      <input-text :formTitle="'校区名'"
                  :required="false"
                  :readonly="true"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.CampusKeyValue"></input-text>

      <input-number :min="0"
                    :formTitle="'收款金额'"
                    :formPlaceholder="'请输入'"
                    :decimalPlaces="0"
                    :required="true"
                    :readonly="isReadonly"
                    v-model="dataInfo.CollectAmount"></input-number>

      <input-select :formTitle="'收款账户'"
                    :id="'OLAPKey'"
                    :name="'MainDemoName'"
                    :required="true"
                    :readonly="isReadonly"
                    :dataList="dataSourceList"
                    :selectedInfo="vSourceTypeInfo"
                    @selectedItem="selectedAccount"></input-select>

      <input-text :formTitle="'备注'"
                  :required="false"
                  :readonly="isReadonly"
                  :formPlaceholder="'请输入'"
                  v-model="dataInfo.Remark"></input-text>

    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { FranchiseeManagementCollect, hqMessageSearchPageForIncomePayAccount } from '../../../../../API/workbench';
export default {
  data () {
    return {
      dataInfo: {},
      queryParams: {
        pageIndex: 0, // 页码，0为第一页
        pageSize: 0, // 每页记录数，0：表示查询所有
        searchText: '', // 搜索条件：模糊匹配姓名，姓名首字母
        orderBy: '', // 排序字段
        sequence: 'asc', // 排序字段
        StatusKey: 1,        // 收款账户状态 1-启用;2-停用
        AccountTypeKey: '', // 	账户类型 1-储蓄卡; 2-信用卡;3-支付宝;4-微信;5-现金;6-其它;
        QueryID: ''
      },
      dataSourceList: []
    };
  },
  components: {

  },
  props: {
    campusInfo: Object,
    isReadonly: Boolean
  },
  created () {
    // console.log(this.$store.getters, 'initAddData');
    this.initData();
  
  },
  computed: {
    // 收支账户
    vSourceTypeInfo () {
      return {
        id: this.dataInfo.IncomePayAccountKey,
        name: this.dataInfo.IncomePayAccountKeyValue
      };
    }
  },
  methods: {
    initData () {
      this.dataInfo = {
        CampusKey: this.campusInfo.OLAPKey, // 校区
        CampusKeyValue: this.campusInfo.MainDemoName, // 校区
        CollectAmount: 0, // 收款金额
        IncomePayAccountKey: "",
        IncomePayAccountKeyValue: "",//收支账户
        Remark: '' // 备注
      };
      this.getIncomePayAccount()
    },
    // 返回总部门店所有收支账户
    getIncomePayAccount () {
      hqMessageSearchPageForIncomePayAccount(this.queryParams).then(result => {
        this.dataSourceList = result.data.PageDataList.filter(obj => {
          return obj.OLAPKey > 0 && obj.StatusKey != 2;
        });
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 选择收支账户
    selectedAccount (obj) {
      console.log('选择收支账户', obj);
      this.dataInfo.IncomePayAccountKey = obj.OLAPKey;
      this.dataInfo.IncomePayAccountKeyValue = obj.MainDemoName;
    },
    // 提交
    submit (callback) {
      console.log('submit', this.dataInfo);
      let res = this.verificationInfo();
      if (!res) {
        if (callback) {
          callback();
        }
        return false;
      }
      FranchiseeManagementCollect(this.dataInfo).then(result => {
        layer.alert('收款成功');
        this.$emit('afterSuccess');
        this.cancel();
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callback) {
          callback();
        }
      });
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
      if (!this.dataInfo.CollectAmount) {
        layer.alert('请输入收款金额');
        flag = false;
      }
      return flag;
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
