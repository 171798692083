<template>
  <div class="particular_list">
    <div class="particular_list_front">
      <div class="particular_list_front_title">
        <!-- 列表第一个图标 -->
        <div class="particular_list_front_title_img">
          <div class="particular_list_front_title_img_text">
            {{item.ShowOrder >= 10 ? item.ShowOrder : '0' + item.ShowOrder}}</div>
        </div>
        <!-- 列表第一个文本 -->
        <el-tooltip effect="light"
                    :content="item.MainDemoName"
                    placement="top-end">
          <div class="particular_list_front_title_text">{{item.MainDemoName}}</div>
        </el-tooltip>

      </div>
      <!-- 最近备课部分 -->

    </div>
    <!-- 最近上课部分 -->
    <div class="particular_list_back">
      <img class="particular_list_img"
           style="margin-left: 40px;"
           src="../../../../../../public/cw-img/attend_class.png"
           alt="" />
      <div style="display: flex; flex: 1">
        <div v-if="Number(item.CourseCount)>0"
             class="particular_list_back_text">
          <div style="display: flex; color: #999;height: 50px;line-height: 70px;">
            最近选班上课 ：
            <div style="color: #000; margin-top: 1px;">
              {{item.RecentlyClassTime.substring(0,16)}}
            </div>
          </div>
          <div style="margin-left: 100px">已上{{Number(item.CourseCount)}}课次，共{{Number(item.DurationCount)}}分钟</div>
        </div>
        <div v-else
             class="prepareType">
          <div style="color: #ff6c67">未选班上课</div>
        </div>
      </div>
      <div v-if="item.ScheduleCourseKey == 0&&maxCoursewarePackage.IsPCAuthKey==1"
           class="particularBtn"
           style="margin-right:20px;width: 60px;"
           @click="attendClass">
        选班上课
      </div>
      <div v-else-if="item.ScheduleCourseKey != 0&&maxCoursewarePackage.IsPCAuthKey==1"
           class="beClassType pr"
           @click.stop="isShowdropDownBox=true">
        <div style="color: #ed9908">正在上课</div>
        <div class="module_content_menuList"
             :style="{ top: '30px', left: '-20px' }"
             v-show="isShowdropDownBox"
             @mouseover.stop="isShowdropDownBox = true">
          <div class="top_bg"></div>
          <drop-down-box :optionJsonList="menuList"
                         @clickOptionForAlertDialog="clickAttendanceOption"></drop-down-box>
        </div>
        <!-- <el-dropdown trigger="click">
          <div style="color: #ed9908">
            正在上课
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="atPresentTeacher" @click.native="goOnAttendClass">继续上课</el-dropdown-item>
            <el-dropdown-item @click.native="stopGoOnAttendClass">停止上课</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  CWSTeacherGiveLectureFinishScheduleCourse, CWSTeacherGiveLectureSetUpScheduleCourse
} from '../../../../../API/workbench';
import dropDownBox from '../../../../common/drop-down-box';
export default {
  props: {
    item: Object, // 传入的子课包数据
    CoursewarePackage: Object, // 子课包
    dataInfo: Object,  // 班级
    maxCoursewarePackage: Object // 全部课包详情
  },
  data () {
    return {
      atPresentTeacher: '', // 当前老师ID
      isEditPlan: false,
      menuList: [
        {
          name: '继续上课',
          type: 1,
          isShow: true
        },
        {
          name: '停止上课',
          type: 2,
          isShow: true
        }
      ],
      isShowdropDownBox: false,
      showAuth: false, // 显示授权弹出层
      authDataInfo: null,
      chooseScheduleCourseKey: '',
      chooseSaaSClientKey: '',
      chooseType: '',
      chooseCoursewareInfoType: '',
      openClassData: {

      },
      IsContinueKey: 0,
      IsAuthKey: 0,
      busResult: null
    };
  },
  components: {
    dropDownBox
  },
  computed: {},
  created () {
    this.getAtPresent();
  },
  mounted () {
    document.querySelector('body').addEventListener(
      'click',
      (e) => {
        this.isShowdropDownBox = false;
      },
      false
    );
  },
  methods: {
    // 进入课件预览模式
    prepare () {
      console.log('去备课');
      // if (!this.$zxClient.IsClient) {
      //   layer.alert('请用桌面端打开程序')
      // } else {
      this.$router.push({
        name: 'coursewareTeachingView',
        query: {
          type: 4,
          coursewareId: this.item.OLAPKey,
          Accuracy: this.maxCoursewarePackage.Accuracy,
          IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
        }
      });
      // }
    },
    // 开始上课
    attendClass () {
      console.log('item', this.item);
      console.log('dataInfo', this.dataInfo);
      console.log('CoursewarePackage', this.CoursewarePackage);
      console.log('maxCoursewarePackage', this.maxCoursewarePackage);
      // if(this.maxCoursewarePackage.HQAuthKey!=0){
      //   let text=this.maxCoursewarePackage.HQAuthKey==1?'  当前门店定位，课件授权方禁止访问课件':' 当前门店定位，课件授权方正在审核中，暂时无法访问课件'
      //   layer.alert(text)
      //   return false
      // }
      this.doViewCoursewareDetails();
    },
    // 打开上课弹窗
    doViewCoursewareDetails () {
      if (this.$zxClient && this.$zxClient.IsClient) {
        this.item.ParentKey = this.CoursewarePackage.BigCoursewarePackageKey;
        this.$dialog.open(this, {
          name: '开课',
          routerName: this.$route.name,
          ModulePowerKey: '',
          moduleName: 'cwsGoToClass',
          data: {
            dataInfo: {
              item: this.item,
              GradeKey: this.dataInfo.OLAPKey, // 班级key
              GradeKeyValue: this.dataInfo.MainDemoName,
              PCIsLocationLockKey: this.maxCoursewarePackage.PCIsLocationLockKey,
              Longitude: this.$store.getters.geoInfo.Longitude,
              Latitude: this.$store.getters.geoInfo.Latitude,
              HQAuthKey: this.maxCoursewarePackage.HQAuthKey
            }
          },
          callBack: {
            afterSuccess: (data) => { this.$emit('getCoursewarePackageDetails'); }
          }
        });
      } else {
        layer.alert('请用桌面端打开程序');
      }
    },
    openAuthDialog (OLAPKey, type, model, introType) {
      console.log('openAuthDialog maxCoursewarePackage', this.maxCoursewarePackage);
      this.chooseScheduleCourseKey = '';
      this.coursewareIdforOpen = OLAPKey;
      this.chooseType = model;
      this.chooseCoursewareInfoType = type;
      let skey = '';
      if (model == 1) {
        this.chooseScheduleCourseKey = OLAPKey;
        skey = OLAPKey;
      }

      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '安全验证',
        moduleName: 'courseAuthQrcode',
        data: {
          dataInfo: {
            ScheduleCourseKey: skey,
            CourseWarePackageKey: this.CoursewarePackage.BigCoursewarePackageKey,
            client_id: this.$store.getters.clientId,
            SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
            DeviceTypeKey: '2',  //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
            introType: 11,
            DeviceCode: this.$zxClient.GetMCode() || ''  // 桌面端设备码
          }
        },
        callBack: {
          afterSuccess: () => {
            this.$emit('updateParentData');
            this.$router.push({
              name: 'coursewareTeachingView',
              query: {
                type: 1,
                scheduleCourseID: this.item.ScheduleCourseKey, // 正在上课的Key
                Accuracy: this.maxCoursewarePackage.Accuracy,
                IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
              }
            });
          }
        }
      });
    },
    // 继续上课
    goOnAttendClass () {
      console.log('继续上课', this.item);
      if (this.item.MTeacherKey != this.$store.getters.token.UserID) {
        layer.alert('您不是当前的主讲老师，无法上课');
        return false;
      }
      if (this.$zxClient && this.$zxClient.IsClient) {
        if (this.maxCoursewarePackage.DeviceIsAuth != 1) {
          layer.alert('设备未授权');
        } else {
          if (this.maxCoursewarePackage.PCIsLocationLockKey == 1 && this.maxCoursewarePackage.HQAuthKey != 0) {
            let text = this.maxCoursewarePackage.HQAuthKey == 1 ? '  当前门店定位，课件授权方禁止访问课件' : ' 当前门店定位，课件授权方正在审核中，暂时无法访问课件';
            layer.alert(text);
            return false;
          } else {
            layer.confirm('是否继续上课', {
              btn: [{
                name: '确认',
                callBack: () => {
                  // 点击确定调用接口
                  if (this.maxCoursewarePackage.PCIsLocationLockKey == 1 && !this.$store.getters.geoInfo.Longitude) {
                    this.openAuthDialog(this.item.ScheduleCourseKey, 'ZZ', 1, 11);
                  } else {
                    let newdataInfo = {
                      ScheduleCourseKey: this.item.ScheduleCourseKey,
                      CourseWarePackageKey: this.CoursewarePackage.BigCoursewarePackageKey,
                      client_id: this.$store.getters.clientId,
                      SaaSClientKey: this.$store.getters.SaaSClientInfo.OLAPKey,
                      DeviceTypeKey: '2',  //	设备类型1-浏览器;2-桌面端;3-APP;4-电视
                      introType: 11,
                      DeviceCode: this.$zxClient.GetMCode() || ''  // 桌面端设备码
                    };
                    this.$utils.verifySystemLocation(newdataInfo, (success) => {
                      this.$router.push({
                        name: 'coursewareTeachingView',
                        query: {
                          type: 1,
                          scheduleCourseID: this.item.ScheduleCourseKey,
                          coursewareInfoType: 'ZZ',
                          Accuracy: this.maxCoursewarePackage.Accuracy,
                          IsPCRecordKey: this.maxCoursewarePackage.IsPCRecordKey || 0
                        }
                      });
                    }, (error) => {
                      this.openAuthDialog(this.item.ScheduleCourseKey, 'ZZ', 1, 11);
                    });
                  }

                  // }
                  // 取消事件
                  layer.close();
                }
              }]
            });
          }
        }
      } else {
        layer.alert('请用桌面端打开程序');
      }
    },
    // 停止上课
    stopGoOnAttendClass () {
      console.log('停止上课');
      layer.confirm('是否停止上课', {
        btn: [{
          name: '确认',
          callBack: () => {
            // 点击确定调用接口
            CWSTeacherGiveLectureFinishScheduleCourse(this.item.ScheduleCourseKey).then(result => {
              this.$emit('getCoursewarePackageDetails');
            });
            // 取消事件
            layer.close();
          }
        }]
      });
    },
    // 获取当前老师ID
    getAtPresent () {
      this.atPresentTeacher = this.$store.getters.token.UserID;
      console.log(this.atPresentTeacher, '当前老师');
      console.log(this.item, '传入的子课包数据');
      console.log(this.CoursewarePackage, '全部课包xinxi');
    },
    clickAttendanceOption (item) {
      if (item.type == 1) {
        this.isShowdropDownBox = false;
        if (this.maxCoursewarePackage.HQAuthKey != 0) {
          let text = this.maxCoursewarePackage.HQAuthKey == 1 ? '  当前门店定位，课件授权方禁止访问课件' : ' 当前门店定位，课件授权方正在审核中，暂时无法访问课件';
          layer.alert(text);
          return false;
        }
        if (this.CoursewarePackage.DeviceIsAuth == 0) {
          layer.alert('设备未授权');
        } else {
          this.goOnAttendClass();
        }
      } else {
        this.stopGoOnAttendClass();
        this.isShowdropDownBox = false;
      }
    }
  }
};
</script>

<style scoped>
.particular_list {
  display: flex;
  align-items: center;
  border: 1px solid #ececec;
  border-radius: 5px;
  width: 884px;
  height: 70px;
  margin-bottom: 10px;
}

.particular_list_front {
  display: flex;
  flex: 1;
  align-items: center;
  /* justify-content: space-between; */
  position: relative;
}

.particular_list_img {
  width: 15px;
  height: 15px;
}

.particular_list_front_title {
  display: flex;
  margin-left: 25px;
  align-items: center;
}

.particular_list_front_title_img {
  background: url('../../../../../../public/cw-img/Subtract.png') no-repeat;
  background-size: 18px;
  width: 18px;
  height: 18px;
  line-height: 18px;
}

.particular_list_front_title_img_text {
  font-family: 'Arial';
  font-style: normal;
  font-size: 12px;
  color: #6dc4bf;
  padding-left: 3px;
}

.particular_list_front_title_text {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-size: 16px;
  margin: 0px 20px 0 10px;
  font-weight: 700;
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
  width: 450px;
  /* flex: 1; */
}

.particular_list_front_content {
  display: flex;
  align-items: center;
  color: #999999;
  padding-right: 100px;
  /* position: absolute;
  left: 320px; */
}

.particularBtn {
  color: #3498db;
  cursor: pointer;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  width: 30px;
}

.particular_list_front_content_text {
  display: flex;
  font-family: 'Arial';
  font-style: normal;
  margin-left: 10px;
}

.particular_list_back {
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: space-between;
}

.particular_list_back_text {
  display: flex;
  flex: 1;
  margin-left: 10px;
  height: 70px;
  flex-direction: column;
}

.prepareType {
  border: 1px solid #f1d6d5;
  height: 20px;
  width: 77px;
  line-height: 20px;
  text-align: center;
  margin: 0 70px 0 10px;
  background-color: #f1d6d5;
  border-radius: 4px;
  font-family: 'Arial';
  font-style: normal;
  font-size: 12px;
}

.beClassType {
  border: 1px solid #ffedce;
  height: 20px;
  width: 80px;
  line-height: 20px;
  text-align: center;
  margin: 0 5px 0;
  background-color: #ffedce;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Arial';
  font-style: normal;
  font-size: 12px;
}
</style>
