<template>
	<div class="a_cycle_lesson_has_been_lesson_plan_time_view" @click.stop="clickItem">
		<span class="text_number">{{vIndex}}</span>
		<el-tooltip placement="top" :content="dataInfo.MainDemoName" :disabled="disabled">
			<span ref="textBox">{{dataInfo.MainDemoName}}</span>
		</el-tooltip>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				disabled:true
			}
		},
		props:{
			dataInfo:Object
		},
		computed:{
			vIndex(){
				let i = this.dataInfo.index + 1;
				if(this.dataInfo.MainDemoName){
					if(i < 10){
						return '0' + i;
					}else {
						return i;
					}
				}else {
					return ''
				}
			}
		},
		mounted () {
  		  this.disabled = this.$refs.textBox.offsetWidth < 108;
		},
		methods:{
			clickItem(e){
				this.$emit('clickItem',e);
			}
		}
		
	}
</script>

<style>
</style>
