<template>
  <!-- 购买课时 -->
  <div :class="{opt:isFocus,'import_list':rowType=='add','table_td':rowType!='add' }"
       style="width:74px">
    <input :class="{text_error:vCurrentCountWarning}"
           :value="rowData.NumCount"
           v-if="vIsEdit"
           type="text"
           ref="NumCount"
           :placeholder="rowType=='add'?'购买课时':''"
           @keydown="keydownEvent($event)"
           @blur="blurEvent"
           @focus="focusEvent($event)"
           @input="inputEvent($event.target.value,$event)"
           @change="changeEvent($event.target.value,$event)"
           onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/\.{2,}/g,'.')">

  </div>
</template>

<script>
export default {
  data () {
    return {
      NumCount: '', // 购买课时
      isFocus: false
    };
  },
  props: {
    rowType: {
      type: String,
      default: ''
    }, // 输出类型
    rowData: Object
  },
  computed: {
    vCurrentCountWarning () {
      let flag = false;
      let findItem = this.rowData.ExceptionList.find(obj => { return obj.TargetField == 8; });
      if (findItem) {
        flag = true;
      }
      return flag;
    },
    vIsFocus () {
      return this.rowData.focusField == 'NumCount' && this.rowData.optioningRow;
    },
    vIsEdit () {
      let flag = false;
      if (this.rowData.guid || this.rowType == 'add') {
        flag = true;
      }
      return flag;
    }
  },
  watch: {
    // 'rowData.NumCount': {
    //   handler (n, o) {
    //     console.log(n, 'NumCount1');
    //     if (Number(n) && typeof n === 'number') {
    //       this.rowData.NumCount = this.$utils.mAmountType(n, 1);
    //     }
    //   }
    // },
     'rowData.focusField': {
      handler (n, o) {
        if (n.focusField == 'NumCount' && n.optioningRow) {
          this.rowData.focusField = 'NumCount';
          this.$refs.NumCount.focus();
          // 光标定位到文本框字符串末尾
          var tObj = this.$refs.NumCount;
          if (tObj.value) {
            var sPos = tObj.value.length;
            this.$utils.setCaretPosition(tObj, sPos);
          }
        }
      },
      // immediate: true
      deep: true // 表示开启深度监听
    }
  },
  created () {
    // if (Number(this.rowData.NumCount) && typeof this.rowData.NumCount === 'number') {
    //   this.rowData.NumCount = Number(this.rowData.NumCount);
    // }
  },
  methods: {
    // 鼠标聚焦
    focusEvent (e) {
      this.isFocus = true;
      this.rowData.optioningRow = true;
      this.rowData.focusField = 'NumCount';
      this.$emit('onFocusEvent', 'NumCount');
    },
    // 键盘事件
    keydownEvent (e) {
      switch (e.keyCode) {
        case 9: // Tab
          this.rowData.focusField = 'CurrentCount';
          break;
        case 13: // 回车
          this.rowData.focusField = 'CurrentCount';
          break;
        case 37: // 左键
          this.rowData.focusField = 'ActualPayAmount';
          break;
        case 38: // 向上键
          if (Number(this.rowData.Number) > 1) {
            this.$emit('doUpwardMove', this.rowData, 'NumCount');
          }
          break;
        case 39: // 右键
          this.rowData.focusField = 'CurrentCount';
          break;
        case 40: // 向下键
          this.$emit('doDownwardMove', this.rowData, 'NumCount');
          break;
        default:
          break;
      }
    },
    inputEvent (val, e) {
      if (val.toString().length > 10) {
        e.target.value = (val === '' ? '' : Number(val.toString().substr(0, 10)));
        this.rowData.NumCount = e.target.value;
      }
    },
    changeEvent (val, e) {
      this.rowData.NumCount = (val === '' ? '' : this.$utils.mAmountType(val, 1));
    },
    // 失去焦点事件
    blurEvent () {
      this.rowData.focusField = '';
      this.isFocus = false;
      if (this.rowData.NumCount && isNaN(this.rowData.NumCount)) {
        this.rowData.NumCount = this.rowData.NumCount.replace(/[^\d.]/g, '');// 校验数字类型（字符串提取数字和小数点）
      }
      this.verificationNumCount();
      this.rowData.updateTime = Date.new();
      this.$emit('onBlurEvent', this.rowData, 'NumCount');
    },
    // 验证 购买课时
    // 异常TargetField：8-》 1：购买课时[非数字]填写错误，2：购买课时[负数]必须大于0，3：购买课时超出99999
    verificationNumCount () {
      if (typeof this.rowData.NumCount === 'number') {
        this.rowData.NumCount = Number(this.rowData.NumCount);
      }
      if (typeof this.rowData.CurrentCount === 'number') {
        this.rowData.CurrentCount = Number(this.rowData.CurrentCount);
      }

      // 1：购买课时[非数字]填写错误
      let NANErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 8 && obj.TypeKey == 1; });
      if ((!this.rowData.NumCount || this.rowData.NumCount > 0) && NANErrorIndex >= 0) {
        this.rowData.ExceptionList.splice(NANErrorIndex, 1);
      }
      // 2：购买课时[负数]必须大于0
      let negativeErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 8 && obj.TypeKey == 2; });
      if ((this.rowData.NumCount === 0 || this.rowData.NumCount < 0) && negativeErrorIndex < 0) {
        this.rowData.ExceptionList.push({ TargetField: 8, TypeKey: 2, TypeKeyValue: '购买课时必须大于0' });
      } else if ((this.rowData.NumCount > 0 || this.rowData.NumCount === '') && negativeErrorIndex >= 0) {
        this.rowData.ExceptionList.splice(negativeErrorIndex, 1);
      }

      // 3：购买课时超出99999
      let maxErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 8 && obj.TypeKey == 3; });
      if (this.rowData.NumCount > 99999 && maxErrorIndex < 0) {
        let maxItem = { TargetField: 8, TypeKey: 3, TypeKeyValue: '购买课时超出99999' };
        this.rowData.ExceptionList.push(maxItem);
      } else if (this.rowData.NumCount <= 99999 && maxErrorIndex >= 0) {
        this.rowData.ExceptionList.splice(maxErrorIndex, 1);
      }
      // 4：剩余课时不能大于购买课时
      let overErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 9 && obj.TypeKey == 4; }); // 剩余课时不能大于购买课时
      if (typeof this.rowData.NumCount === 'number' && typeof this.rowData.CurrentCount === 'number') {
        if (this.rowData.NumCount >= 0 && this.rowData.CurrentCount > this.rowData.NumCount && overErrorIndex < 0) {
          let overItem = { TargetField: 9, TypeKey: 4, TypeKeyValue: '剩余课时不能大于购买课时' };
          this.rowData.ExceptionList.push(overItem);
        } else if (((this.rowData.NumCount > 0 && this.rowData.CurrentCount <= this.rowData.NumCount) || this.rowData.NumCount == 0) && overErrorIndex >= 0) {
          this.rowData.ExceptionList.splice(overErrorIndex, 1);
        }
      } else if ((this.rowData.NumCount === '' || this.rowData.NumCount === null) && overErrorIndex >= 0) {
        this.rowData.ExceptionList.splice(overErrorIndex, 1);
      }
      // 在读课程未选择
      if (!this.rowData.ApplyClassKeyValue) {
        let unSelectedErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 4 && obj.TypeKey == 3; });
        if ((this.rowData.NumCount > 0 || this.rowData.NumCount === 0) && unSelectedErrorIndex < 0) {
          this.rowData.ExceptionList.push({ TargetField: 4, TypeKey: 3, TypeKeyValue: '在读课程未选择' });
          this.rowData.isShowCorrect = false;
        } else if ((this.rowData.NumCount === '' || this.rowData.NumCount === null) && unSelectedErrorIndex >= 0) {
          if (!this.rowData.PayTime && !this.rowData.ExpireTime && (this.rowData.ActualPayAmount === '' || this.rowData.ActualPayAmount === null) && (this.rowData.CurrentCount === '' || this.rowData.ActualPayAmount === null) && unSelectedErrorIndex >= 0) {
            this.rowData.ExceptionList.splice(unSelectedErrorIndex, 1);
          }
        }
      }
    }
  }
};
</script>

<style>
</style>