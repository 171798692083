<template>
  <div>
    <div class="form_info_edit form_info_list single_shadow content_marginRL content_marginTop_30">
      <div class=" form_info_line no_border_bottom"
           :class="{ form_info_required: required }">
        <div class="form_info_field">
          <span>{{vformTitle}}</span>
          <span v-if="helpDescription">
            <input-form-tip :tips='helpDescription'></input-form-tip>
          </span>
        </div>
        <div class="form_info_value ">
          <input type="number"
                 :placeholder="required?'必填':'选填'"
                 v-model="inputValue"
                 onclick="this.select();"
                 @input="inputEvent($event.target.value,$event)"
                 @change="changeEvent($event.target.value,$event)" />
        </div>
      </div>
    </div>
    <button-info @buttonClickEvent="confirmEdit"
                 @closeDialog="cancelEdit"></button-info>
  </div>
</template>
<script>
import buttonInfo from '../common/button-info';
export default {
  name: 'inputNumberDialog',
  props: {
    value: {
      type: [Number, String],
      default: 0
    }, // 值，双向绑定，如不想双向绑定，则自行实现input事件
    placeholder: String, // 提示文本，可选，如不提供，则默认显示title
    decimalPlaces: {
      type: [Number, String],
      default: 1
    }, // 保留的小数位数
    readonly: Boolean, // 是否只读，可选，如不提供，则可修改
    formTitle: {
      // 标题，必填
      type: String,
      required: true
    },
    dialogTitleName: String,
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    isShowButtonInfo: Boolean, // 是否显示保存按钮
    isRemarksShow: Boolean,
    max: [Number, String], // 最大值
    min: [Number, String], // 最小值
    maxLength: {
      type: Number,
      default: 12
    },
    helpDescription: String // 表单说明提示
  },
  data () {
    return {
      inputValue: '',
      isWrithing: false,
      Remarks: '' // 处理原因
    };
  },
  computed: {
    vformTitle () {
      // console.log(this.formTitle, 'title');
      if (this.formTitle === '修改剩余数量') {
        return '剩余数量';
      } else if (this.formTitle === '修改剩余天数') {
        return '剩余天数';
      } else {
        return this.formTitle;
      }
    },
    vIsShowButtonInfo () {
      return this.isShowButtonInfo != 'undefined'
        ? this.isShowButtonInfo
        : true;
    },
    vDialogTitleName () {
      return this.dialogTitleName || this.formTitle;
    },
    vDecimalPlaces () {
      if (this.decimalPlaces == 0) {
        return 0;
      } else {
        return this.decimalPlaces;
      }
    }
  },
  components: {
    buttonInfo
  },
  created () {
    this.setInputValue(this.value);
  },
  watch: {
    value (c, o) {
      this.setInputValue(c);
    }
  },
  methods: {
    setInputValue (v) {
      this.inputValue = Number(v);
      this.inputValue = this.inputValue || '';
    },
    inputEvent (val, e) {
      if (val.length > this.maxLength) {
        e.target.value = e.target.value.slice(0, this.maxLength);
        this.inputValue = e.target.value;
        return false;
      }
      this.inputValue = val || '';
      this.$emit('input', this.inputValue);
    },
    changeEvent (val, e) {
      if (val.length > this.maxLength) {
        e.target.value = e.target.value.slice(0, this.maxLength);
        this.inputValue = e.target.value;
        return false;
      }
      this.inputValue = this.$utils.numberToDecimalPlaces(
        val,
        this.decimalPlaces
      );
      this.inputValue = this.inputValue > this.max ? this.max : this.inputValue;
      this.inputValue = this.inputValue < this.min ? this.min : this.inputValue;
    },
    confirmEdit () {
      this.$emit('inputChange', this.inputValue, this.Remarks);
    },
    // 取消+返回
    cancelEdit () {
      this.$emit('cancelEvent');
      this.inputValue = Number(this.value);
    }
  }
};
</script>

