<template>
  <div>
    <div class="progress_title">
      {{tips}}...
    </div>
    <div class="progress_box">
      <div>
        <img src="../../../public/image/submit-progress.gif" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tips: String,
    runCount: [Number, String],
    totolPeoCount: [Number, String]
  },
  computed: {
    // 进度条百分比.
    progressStatus () {
      return Math.floor((this.runCount / this.totolPeoCount).toFixed(2) * 100);
    }
  }
};
</script>

<style>
</style>