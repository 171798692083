<template>
  <!-- 已上架的套餐列表 -->
  <div class="WKT_discount_package_list">
    <div class="WKT_discount_package_blcok"
         :class="comboItem.comboColor">
      <div class="package_blcok_top">
        <div class="package_blcok_name">
          {{comboItem.PackageName}}
        </div>
        <div class="package_blcok_text pr"
             v-if="vIdoneityItem">
          <span class="package_blcok_text_name">适用：</span>{{vIdoneityItem.ApplyCorName||vIdoneityItem.ProjectKeyValue}}
        </div>
        <div class="package_blcok_text pr"
             v-if="vIdoneityItem">
          <div v-if="vIdoneityItem.UnitKey==5||vIdoneityItem.UnitName">
            <span class="package_blcok_text_name">数量：</span>{{$utils.mAmountType(vIdoneityItem.UnitPrice,2)}}元x{{$utils.mAmountType(vIdoneityItem.NumCount,1)}}{{vIdoneityItem.UnitKeyValue||vIdoneityItem.UnitName}}
          </div>
          <div v-else>
            <span class="package_blcok_text_name">数量：</span> 
             <span v-if="vIdoneityItem.UnitKey!=5">{{vIdoneityItem.UnitKeyValue}}卡 |</span>
            {{$utils.mAmountType(vIdoneityItem.NumCount,1)}}{{vUnitName}}
          </div>
        </div>
        <div v-if="visMoreShow">
          <div class="package_blcok_text">......</div>
          <div class="package_blcok_text">更多</div>
        </div>
      </div>
      <div class="package_blcok_bottom">
        <div class="package_blcok_date"
             v-if="vShowCreateTime">
          课时有效期
          <span class="font_bold">{{vShowCreateTime}}</span>{{vUnitKeyValue}}
        </div>
        <div class="package_blcok_details"
             @click="doLookComboDetailsClick">
          查看详情
        </div>
      </div>
      <div class="package_blcok_cost">
        <div class="package_blcok_amount">
          <span class="package_blcok_unit">￥</span><span class="package_blcok_number">{{$utils.mAmountType(comboItem.SalePrice,2)}}</span>
        </div>
        
        <div class="package_blcok_btn" v-if="Number(this.$store.getters.SaaSClientInfo.WktInfo.IsOpenPay) == 1&&Number(comboItem.SalePrice)>0">购买</div>
      </div>
      <div class="package_blcok_recommend"
           v-if="comboIndex<=1">
        推荐
      </div>
    </div>
    <div class="list_btn_box"
         v-if="showOperation">
      <operation-btn :isShowSetTop="true"
                     :item="comboItem"
                     :dataList="dataList"
                     @promote="promote"
                     @decline="decline"
                     @edit="edit"
                     @remove="remove"
                     @setTop="setTop"></operation-btn>
    </div>
    <!-- 修改套餐卡片颜色 -->
    <custom-dialog title="修改套餐卡片颜色"
                   :visible.sync="isComboCardShow"
                   :before-close="doComboCardCloseClick">
      <div class="WKT_discount_package_color_box">
        <div v-for="(item,index) in colorList"
             :key="index">
          <div class="color_list"
               :class="[item.comboColor, item.isOpt?'opt':'']"
               @click="doChangeComboCardClick(item)"></div>
        </div>
      </div>
      <div>
        <common-button-group @buttonClickEvent="doAfterConfirmBtn"
                             @closeDialog="doComboCardCloseClick"></common-button-group>
      </div>
    </custom-dialog>
  </div>

</template>

<script>
import operationBtn from '../common/operation-btn';
import { MoveCombo, EditCombo, StopCombo } from '../../../API/workbench';

export default {
  components: { operationBtn },
  data () {
    return {
      isComboCardShow: false, // 修改套餐卡片颜色弹窗
      colorList: [
        { comboColor: 'type_brown', isOpt: false },
        { comboColor: 'type_blue', isOpt: false },
        { comboColor: 'type_gray', isOpt: false },
        { comboColor: 'type_red', isOpt: false },
        { comboColor: 'type_black', isOpt: false },
        { comboColor: 'type_green', isOpt: false }
      ],
      colorItem: {}// 选中变更的卡片颜色
    };
  },
  props: {
    comboItem: Object,
    dataList: Array,
    comboIndex: Number,
    showOperation: {
      type: Boolean,
      default: true
    }
  },
  created () {

  },
  watch: {

  },
  computed: {
  	 // 有效期
    vShowCreateTime () {
      const findItem = this.comboItem.xykdList.find(o => {
        return o.UnitKey == 5;
      });
      if (findItem) {
        if (this.comboItem.ExpireTime) {
          // let newStartTime = this.comboItem.StartTime.replace(/\-/g, '.');
          // let newExpireTime = this.comboItem.ExpireTime.replace(/\-/g, '.');
          if (this.comboItem.StartTime && this.comboItem.StartTime != '1900-01-01') {
            return this.comboItem.StartTime + ' - ' + this.comboItem.ExpireTime;
          } else {
            return this.comboItem.ExpireTime;
          }
        } else {
          return this.$utils.mAmountType(this.comboItem.PeriodCount, 0);
        }
      } else {
        return false;
      }
    },
			// 单位显示
    vUnitKeyValue () {
      if (this.comboItem.ExpireTime || !this.comboItem.UnitKeyValue) {
        return '';
      } else {
        if (this.comboItem.UnitKeyValue == '月') {
          return '个月';
        } else {
          return this.comboItem.UnitKeyValue;
        }
      }
    },
    vIdoneityItem () {
      if (this.comboItem.xykdList.length > 0) {
        return this.comboItem.xykdList[0]; // 适用课单 显示第一个
      } else if (this.comboItem.incidentalsList.length > 0) {
        return this.comboItem.incidentalsList[0];// 适用杂费 显示第一个
      } else {
        return '';
      }
    },
    vUnitName () {
      if (this.vIdoneityItem.UnitKeyValue == '月' || this.vIdoneityItem.UnitName == '月') {
        return '个月';
      } else {
        return this.vIdoneityItem.UnitKeyValue || this.vIdoneityItem.UnitName;
      }
    },
    // 是否显示更多...提示
    visMoreShow () {
      if (this.comboItem.incidentalsList.length > 0 && this.comboItem.xykdList.length > 0) {
        return true;
      } else if (this.comboItem.incidentalsList.length > 1) {
        return true;
      } else if (this.comboItem.xykdList.length > 1) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    // 套餐详情
    doLookComboDetailsClick () {
      this.$emit('selectedItemDetails', this.comboItem);
    },
    setMoveCombo (id, indedx) {
      MoveCombo(id, indedx).then(result => {
        // this.$emit('afterMoveCombo');
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 上升
    promote () {
      console.log('上升');
      let Indedx = this.comboIndex;
      Indedx = (Indedx == 0 ? 1 : Indedx);
      this.setMoveCombo(this.comboItem.id, Indedx);
      document.getElementsByClassName('WKT_discount_package')[0].scrollTop = this.comboIndex * 221 - 221;
    },
    // 下降
    decline () {
      console.log('下降');
      let Indedx = this.comboIndex + 3;
      this.setMoveCombo(this.comboItem.id, Indedx);
      document.getElementsByClassName('WKT_discount_package')[0].scrollTop = this.comboIndex * 221 + 221;
    },
    // 置顶
    setTop () {
      console.log('置顶');
      this.setMoveCombo(this.comboItem.id, 1);
      document.getElementsByClassName('WKT_discount_package')[0].scrollTop = 0;
    },
    // 显示对应的卡片颜色
    showColor (item) {
      this.colorList.forEach(o => {
        if (o.comboColor == item.comboColor) {
          o.isOpt = true;
        } else {
          o.isOpt = false;
        }
      });
    },
    // 修改套餐卡片颜色
    edit () {
      console.log(this.comboItem, '修改套餐卡片颜色');
      this.colorItem = this.comboItem;
      this.showColor(this.comboItem);
      this.isComboCardShow = true;
    },
    // 选择套餐卡片颜色
    doChangeComboCardClick (item) {
      console.log(item, '修改');
      this.showColor(item);
      this.colorItem = item;
    },
    // 确定修改
    doAfterConfirmBtn () {
      if (this.colorItem.comboColor == this.comboItem.comboColor) {
        layer.alert('该颜色已应用，请重新选择你喜欢的颜色进行修改');
        return false;
      }
      EditCombo(this.comboItem.id, this.colorItem.comboColor).then(result => {
        this.comboItem.comboColor = this.colorItem.comboColor;
        layer.alert('卡片颜色修改成功');
        this.doComboCardCloseClick();
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 删除
    remove () {
      layer.confirm('是否需要删除该套餐?', {
        btn: [{
          name: '确认',
          callBack: () => {
            StopCombo(this.comboItem.id).then(result => {
              this.dataList.splice(this.comboIndex, 1);
            }, error => {
              layer.alert(error.msg);
            });
            layer.close();
          }
        }
        ]
      });
      console.log('删除');
    },
    // 关闭弹窗卡片颜色弹窗层
    doComboCardCloseClick () {
      this.isComboCardShow = false;
    }
  }
};
</script>

<style>
</style>