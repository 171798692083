
  <template>
  <!-- 已审核详情页面 -->
  <div class="income_expenditure_manage_audited_list box_shadow">
    <div class="form_info_line ">
      <div class=" form_info_field">
        <span>审 核 人</span>
      </div>
      <div class=" form_info_value">
        <input type="text"
               placeholder=""
               readonly
               :value="item.AuditAgentKeyValue">
      </div>
    </div>
    <div class="form_info_line ">
      <div class=" form_info_field">
        <span>审核时间</span>
      </div>
      <div class=" form_info_value">
        <input type="text"
               placeholder=""
               readonly
               :value="item.AuditTime">
      </div>
    </div>
    <div class="form_info_line ">
      <div class="form_info_field">
        <span>审核状态</span>
      </div>
      <div class="form_info_value font_red">
        <input type="text"
               placeholder=""
               class="font_red"
               readonly
               :value="item.StatusKeyValue">
      </div>
    </div>
    <div class="form_info_line ">
      <div class=" form_info_field">
        <span>收支流水</span>
      </div>
      <div class=" form_info_value">
        <input type="text"
               placeholder=""
               readonly
               :value="item.SourceKeyName">
      </div>
    </div>
    <div class="form_info_line "
         v-if="item.IncomePayTypeKey ==1">
      <div class=" form_info_field">
        <span>收入金额</span>
      </div>
      <div class=" form_info_value">
        <input type="text"
               placeholder=""
               readonly
               :value="Number(item.InAmount).toFixed(2)">
      </div>
    </div>
    <div class="form_info_line "
         v-if="item.IncomePayTypeKey ==2">
      <div class=" form_info_field">
        <span>支出金额</span>
      </div>
      <div class=" form_info_value">
        <input type="text"
               placeholder=""
               readonly
               :value="Number (item.PayAmount).toFixed(2)">
      </div>
    </div>
    <div class="form_info_line ">
      <div class=" form_info_field">
        <span>收支账户</span>
      </div>
      <div class=" form_info_value">
        <input type="text"
               readonly
               :value="item.IncomePayAccountKeyValue">
      </div>
    </div>
    <div class="form_info_line ">
      <div class=" form_info_field">
        <span>收支科目</span>
      </div>
      <div class="form_info_value">
        <input type="text"
               readonly
               :value="item.IncomePaySubKeyValue">
      </div>
    </div>
    <div class="form_info_line ">
      <div class=" form_info_field">
        <span>发生人类型</span>
      </div>
      <div class=" form_info_value ">
        <input type="text"
               placeholder=""
               readonly
               :value="item.HapObjTypeKeyValue">
      </div>
    </div>
    <div class="form_info_line "
         v-if="item.HapObjTypeKeyValue">
      <div class=" form_info_field">
        <span>{{item.HapObjTypeKeyValue}}</span>
      </div>
      <div class=" form_info_value">
        <input type="text"
               placeholder=""
               readonly
               :value="item.HapPeolKeyValue.replace('(客户)', '').replace('(供应商)', '').replace('(员工)', '')">
      </div>
    </div>
    <div class="form_info_line ">
      <div class=" form_info_field">
        <span>备 注</span>
      </div>
      <div class=" form_info_value">
        <input type="text"
               placeholder=" "
               readonly
               onfocus="this.blur()"
               :value="item.CommentSupport">
      </div>
    </div>
    <div style="width: 100%; margin: 0 auto;text-align: center;">
      <el-button @click="deleteBillClick"
                 type="danger"
                 :class="!vCheckModulePower || !(item.HapSourceKeyKey==5)?'no_power_btn':''"
                 style="width: 50%;margin: 20px 0;">收支作废</el-button>
    </div>

  </div>
</template>
<script>
import { DeleteSearchPageForSZGL } from '../../../API/workbench.js';
export default {
  data () {
    return {
      searchTimeViewText: '',
      monthView: '',
      yearView: ''
    };
  },
  props: {
    item: Object   // 已待审核对象.
  },
  computed: {
    vCheckModulePower () {
      if (this.$utils.CheckModulePower(this.$store.getters.RolePowerList, 166)) {
        return true;
      } else {
        return false;
      }
    }
  },
  created () {
    console.log(this.$utils.CheckModulePower(this.$store.getters.RolePowerList, 166), 'this.$utils.CheckModulePower this.item', this.item);
    // this.$utils.CheckModulePowerToTips(166);
  },
  watch: {

  },
  methods: {
    deleteBillClick () {
      console.log(this.item.TableID, 'this.item.TableID');
      if (this.vCheckModulePower) {
        if (this.item.HapSourceKeyKey != 5) {
          layer.alert('该数据不能作废');
        } else {
          // 其他数据
          if (this.item.StatusKey == 6) {
            layer.alert('该数据已作废，请勿重复作废');
          } else {
            layer.confirm('确定是否作废这条数据?', {
              btn: [{
                name: '确认',
                callBack: () => {
                  DeleteSearchPageForSZGL(this.item.TableID).then(result => {
                    this.$emit('deleteBillClick');
                    layer.alert('作废成功');
                  }, error => {
                    layer.alert(error.msg);
                  });
                  layer.close();
                }
              }
              ]
            });
          }
        }
      } else {
        this.$utils.CheckModulePowerToTips(166);
      }
    }
  }
};
</script>

<style>
.no_power_btn {
  background-color: #f7f7f7 !important;
  color: #aaa !important;
  border-color: #e7e9e8 !important;
}
</style>

