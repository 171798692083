<template>
  <div class="margin_left_right_div">
    <div class="template_minOrmax_width_div">
      <el-container class="page exceptionAtten">
        <el-main class="page_content">
          <div class="exception_handling clearfix">
            <div class="apply_payment_details fl">
              <leftSide :formTitle="'今天'"
                        :dataInfo="exceptionData.exceptionToday"
                        @showDetail="showDetail(exceptionData.exceptionToday)"></leftSide>
              <leftSide :formTitle="'昨天'"
                        :dataInfo="exceptionData.exceptionYesterday"
                        @showDetail="showDetail(exceptionData.exceptionYesterday)"></leftSide>
            </div>
            <!-- 报表 -->
            <exceptionReport @showDetail="showDetail"></exceptionReport>
            <!-- 底部信息区 -->
            <div class="details_bottom_box">
              <bottomSide :formTitle="'改手机号'"
                          :count="exceptionData.DataList.DataType1"
                          :dotColor="'dot_cyan'"
                          @showDetail="showDetail('改手机号')"></bottomSide>
              <bottomSide :formTitle="'修改课单'"
                          :count="chgCourseBillCount"
                          :dotColor="'dot_purple'"
                          @showDetail="showDetail('修改课单')"></bottomSide>
              <bottomSide :formTitle="'校正考勤'"
                          :count="exceptionData.DataList.DataType3"
                          :dotColor="'dot_yellow'"
                          @showDetail="showDetail('校正考勤')"></bottomSide>
              <bottomSide :formTitle="'转让课单'"
                          :count="exceptionData.DataList.DataType5"
                          :dotColor="'dot_blue'"
                          @showDetail="showDetail('转让课单')"></bottomSide>
              <bottomSide :formTitle="'课时退款'"
                          :count="exceptionData.DataList.DataType6"
                          :dotColor="'dot_cyan'"
                          @showDetail="showDetail('课时退款')"></bottomSide>
              <bottomSide :formTitle="'超时考勤'"
                          :count="exceptionData.DataList.DataType25"
                          :dotColor="'dot_blue'"
                          @showDetail="showDetail('超时考勤')"></bottomSide>
              <bottomSide :formTitle="'长假停课'"
                          :count="exceptionData.DataList.DataType7"
                          :dotColor="'dot_yellow'"
                          @showDetail="showDetail('长假停课')"></bottomSide>

              <bottomSide :formTitle="'录入老生'"
                          :count="exceptionData.DataList.DataType8"
                          :dotColor="'dot_purple'"
                          @showDetail="showDetail('录入老生')"></bottomSide>
            </div>
          </div>
        </el-main>
        <!-- 列表区域 -->
        <list ref="exceptionGrade"
              :listTitle="'异常处理明细'"
              :extendSearchCondition="extendSearchCondition"
              :tableData="vexceptionGradeList"
              :totalNum="totalNum"
              :rowKey="'OLAPKey'"
              :PageCount="PageCount"
              :tableColumns="tableColumns"
              @loadTableData="loadTableData"></list>
      </el-container>
    </div>
  </div>
</template>
<script>
import leftSide from './left-side';
import bottomSide from './bottom-side';
import exceptionReport from './exception-report';
import { getExceptionStatistics, MessageSearchPageForException } from '../../../API/workbench';
import list from '../../list/index';
export default {
  name: 'exceptionGrade',
  data () {
    return {
      exceptionData: {
        exceptionToday: {},
        exceptionYesterday: {},
        DataList: []
      },
      exceptionGradeList: [], // 列表数据
      totalNum: 0,
      PageCount: 0,
      extendSearchCondition: [// 扩展查询条件
        {
          type: this.$initJson.extendSearchConditionType.dateRange, // 日期范围
          formTitle: '处理时间',
          fieldList: [
            {
              name: 'startTime', // 参数名
              defaultValue: '', // 默认值
              value: '', // 绑定值
              validateHandle: '', // 自定义验证函数
              sourceData: []// 数据源
            },
            {
              name: 'endTime',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: []
            }
          ]
        },
        {
          type: this.$initJson.extendSearchConditionType.selectOption,
          formTitle: '处理类型',
          fieldList: [
            {
              name: 'typeKey',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: [
                { key: 1, value: '改手机号' },
                { key: 3, value: '校正考勤' },
                { key: 5, value: '转让课单' },
                { key: 6, value: '课时退款' },
                { key: 7, value: '长假停课' },
                { key: 8, value: '录入老生' },
                { key: 13, value: '学生还课时' },
                { key: 25, value: '超时考勤' },
                { key: 21, value: '修改课单 - 剩余课时' },
                { key: 22, value: '修改课单 - 剩余天数' },
                { key: 23, value: '修改课单 - 课程范围' },
                { key: 26, value: '复制课表' },
                { key: 28, value: '收支作废' },
                { key: 29, value: '开单作废' },
                { key: 34, value: '课时不足合并课单' }
              ]
            }
          ]
        }
      ],
      tableColumns: [ // table 列
        {
          label: '序号',
          prop: 'Number',
          type: 'text-item',
          sortable: false,
          width: 68
        },
        {
          label: '处理时间',
          prop: 'GenerateTime',
          width: 150,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '处理人',
          prop: 'OperationAgentKeyValue',
          width: 100,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '处理类型',
          prop: 'OperationTypeKeyValue',
          width: 160,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '对象',
          prop: 'DataKeyValue',
          width: 120,
          sortable: 'custom',
          align: 'left',
          type: 'text-item',
          showOverflowTooltip: true

        },
        {
          label: '处理内容',
          prop: 'OperationSupport',
          width: 300,
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        },
        {
          label: '处理原因',
          prop: 'OperationReasonSupport',
          sortable: 'custom',
          align: 'left',
          type: 'text-item'
        }
      ]
    };
  },
  components: {
    leftSide, bottomSide, exceptionReport, list
  },
  computed: {
    chgCourseBillCount () {
      return this.exceptionData.DataList.DataType21 + this.exceptionData.DataList.DataType22 + this.exceptionData.DataList.DataType23;
    },
    vexceptionGradeList () {
      let newArr = [];
      this.exceptionGradeList.forEach(o => {
        newArr.push(o);
      });
      return newArr;
    }
  },
  mounted () {
    this.getExceptionData();
  },
  methods: {
    getExceptionData () {
      getExceptionStatistics().then(result => {
        console.log('getExceptionData', result.data);
        this.exceptionData = result.data;
      });
    },
    showDetail (type, startTime, endTime) {
      let initSelectedParams = [];
      if (startTime && endTime) {
        startTime = startTime.split('/')[0] + '-' + startTime.split('/')[1] + '-' + startTime.split('/')[2];
        endTime = endTime.split('/')[0] + '-' + endTime.split('/')[1] + '-' + endTime.split('/')[2];
      }
      switch (type) {
        case this.exceptionData.exceptionToday:
          initSelectedParams.push({ name: 'startTime', value: this.exceptionData.exceptionToday.SetUpTime }, { name: 'endTime', value: this.exceptionData.exceptionToday.SetUpTime });
          break;
        case this.exceptionData.exceptionYesterday:
          initSelectedParams.push({ name: 'startTime', value: this.exceptionData.exceptionYesterday.SetUpTime }, { name: 'endTime', value: this.exceptionData.exceptionYesterday.SetUpTime });
          break;
        case '改手机号':
          initSelectedParams.push({ name: 'startTime', value: this.$utils.getCurrentMonthFirst() }, { name: 'endTime', value: this.$utils.getCurrentMonthLast() }, { name: 'typeKey', value: 1 });
          break;
        case '修改课单':
          initSelectedParams.push({ name: 'startTime', value: this.$utils.getCurrentMonthFirst() }, { name: 'endTime', value: this.$utils.getCurrentMonthLast() });
          break;
        case '校正考勤':
          initSelectedParams.push({ name: 'startTime', value: this.$utils.getCurrentMonthFirst() }, { name: 'endTime', value: this.$utils.getCurrentMonthLast() }, { name: 'typeKey', value: 3 });
          break;
        case '转让课单':
          initSelectedParams.push({ name: 'startTime', value: this.$utils.getCurrentMonthFirst() }, { name: 'endTime', value: this.$utils.getCurrentMonthLast() }, { name: 'typeKey', value: 5 });
          break;
        case '课时退款':
          initSelectedParams.push({ name: 'startTime', value: this.$utils.getCurrentMonthFirst() }, { name: 'endTime', value: this.$utils.getCurrentMonthLast() }, { name: 'typeKey', value: 6 });
          break;
        case '长假停课':
          initSelectedParams.push({ name: 'startTime', value: this.$utils.getCurrentMonthFirst() }, { name: 'endTime', value: this.$utils.getCurrentMonthLast() }, { name: 'typeKey', value: 7 });
          break;
        case '录入老生':
          initSelectedParams.push({ name: 'startTime', value: this.$utils.getCurrentMonthFirst() }, { name: 'endTime', value: this.$utils.getCurrentMonthLast() }, { name: 'typeKey', value: 8 });
          break;
        case '异常统计日报':
          initSelectedParams.push({ name: 'startTime', value: startTime }, { name: 'endTime', value: endTime });
          break;
        case '超时考勤':
          initSelectedParams.push({ name: 'startTime', value: this.$utils.getCurrentMonthFirst() }, { name: 'endTime', value: this.$utils.getCurrentMonthLast() }, { name: 'typeKey', value: 25 });
          break;
        default:
          break;
      }
      if (type == '修改课单') {
        this.$refs.exceptionGrade.searchText = '修改课单';
      }
      this.$refs.exceptionGrade.isShowListDialog = true;
      this.$refs.exceptionGrade.initSelectedParams(initSelectedParams);
    },
    loadTableData (queryParams) {
      if (queryParams.orderBy == '' && queryParams.sequence == '') {
        queryParams.orderBy = 'GenerateTime';
        queryParams.sequence = 'DESC';
      }
      queryParams.stutasKey = 0;
      this.exceptionGradeList = [];
      MessageSearchPageForException(queryParams).then(result => {
        if (result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach((obj, index) => {
            this.exceptionGradeList.push(obj);
          });
        }
        this.totalNum = result.data.Total;
        this.PageCount = result.data.PageCount;
        this.$refs.exceptionGrade.isLoading = false;
        console.log('this.exceptionGradeList', this.exceptionGradeList);
      });
    }

  }
};
</script>
<style scoped>
.exceptionAtten >>> .details_bottom_box .except_details_box {
  width: 11.1% !important;
}
.exceptionAtten >>> .el-table__header-wrapper {
  width: 104%;
}
</style>
