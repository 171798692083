<!-- 设置 -->
<template>
  <div>
    <div class="form_info_edit form_info_list"
         style="height: 220px">

      <input-dialog :formTitle="'课前截止预约'"
                    :formPlaceholder="'请选择'"
                    :required="true"
                    v-model="CourseList"
                    @showEldilog="changeCourseType"></input-dialog>
    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
    <custom-dialog :title="'课前截止预约设置'"
                   :visible.sync="isShowCourseSet"
                   :before-close="closeFlowSetCoursePopuP">
      <appointment-setting @close="closeFlowSetCoursePopuP"
                           @UpdataCourse="UpdataCourse"
                           :courseSetInfo='dataInfo'></appointment-setting>
    </custom-dialog>
  </div>
</template>
<script>
import { GetSaaSClientSettingSubscribeCourse, UpdataSaaSClientSettingSubscribeCourse } from '../../../../API/workbench';
import appointmentSetting from './appointmentSetting';
export default {
  name: 'CourseSetting',
  data () {
    return {
      isShowCourseSet: false,
      CourseList: 0,
      dataInfo: {
        SetKey: '', // "天数状态 1小时 2天" ,
        SetContent: 0, // 内容
        SetValue: ''
      },
      courseInfo: {
        SetKey: '', // "天数状态 1小时 2天" ,
        SetContent: 0 // 内容
      }
    };
  },
  components: {
    appointmentSetting
  },
  props: {
  },
  created () {

    console.log(this.$store.getters.token, 'this.$store.getters.token');
  },
  mounted () {
    if (this.$store.getters.token.SaaSClientKey && Number(this.$store.getters.token.SaaSClientKey) > 0) {
      this.getSubscribeCourse();
    }
  },
  computed: {
  },
  methods: {

    // 选择课程类型
    changeCourseType (obj) {
      this.isShowCourseSet = true;
    },
    getSubscribeCourse () {
      GetSaaSClientSettingSubscribeCourse(this.$store.getters.token.SaaSClientKey).then(result => {
        this.dataInfo = result.data;
        if (result.data.SetKey == 1) {
          this.dataInfo.SetValue = '小时';
        } else if (result.data.SetKey == 2) {
          this.dataInfo.SetValue = '天';
        }

        this.CourseList = result.data.SetContent + this.dataInfo.SetValue;
        this.courseInfo.SetKey = this.dataInfo.SetKey;
        this.courseInfo.SetContent = this.dataInfo.SetContent;
        console.log(result.data, 'GetSaaSClientSettingSubscribeCourse', this.dataInfo);
      }, error => {
        layer.alert(error.msg);
      });
    },
    closeFlowSetCoursePopuP () {
      this.isShowCourseSet = false;
    },
    // 验证客户信息
    verificationInfo () {
      let flag = true;
      if (!this.dataInfo.SetKey) {
        layer.alert('请选择预约');
        flag = false;
      }
      return flag;
    },
    UpdataCourse (type) {
      this.courseInfo = type;
      if (type.SetKey == 1) {
        this.dataInfo.SetValue = '小时';
      } else if (type.SetKey == 2) {
        this.dataInfo.SetValue = '天';
      }
      this.dataInfo.SetContent = type.SetContent;
      this.CourseList = type.SetContent + this.dataInfo.SetValue;
      console.log(type, 'type');
    },
    // 提交
    submit () {
      console.log(this.courseInfo, 'this.courseInfo');
      UpdataSaaSClientSettingSubscribeCourse(this.dataInfo.OLAPKey, this.courseInfo).then(result => {
        layer.alert('修改成功');
        this.cancel();
      }, error => {
        layer.alert(error.msg);
      });
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
