<template>
  <!--ShowTypeKey-3:复选 -->
  <div class="form_list_content" :class="keyIndex<dataLength-1&&item.Length!=1&&!item.ParentName&&nextItem&&nextItem.ShowTypeKey!=2?'show_left_Line':''"
       :style="vFieldsWidth" v-if="item.isOpt">
    <div class="form_list_name" >
      <span class="text_box font_black">{{item.FieldsThat}}</span>
    </div>
  </div>
</template> 
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    categoryItem: Object, // 类目项
    item: Object, // 字段项
    rowWidth: Number, // 总行宽
    keyIndex: Number, // 当前下标
    dataLength: Number, // 数组长度
    nextItem: Object, // 字段项
  },
  created () {
    // console.log('复选:', this.categoryItem, this.FieldsItem);
  },
  computed: {
    vFieldsWidth () {
      let FWh = 0;
      if (Number(this.item.Length) > FWh) {
        FWh = Number(this.rowWidth) / Number(this.item.Length);
        return 'width:' + FWh + 'px';
      } else {
        return '';
      }
    }
  },
  methods: {
    checkClick () {
      // this.item.isOpt = true;
      // this.item.DefaultValue = 1;
      // this.item.isOpt = !this.item.isOpt;
      // this.item.DefaultValue = 1;

      this.categoryItem.FormFields.forEach(o => {
        if (o.OLAPKey == this.item.OLAPKey) {
          o.isOpt = !o.isOpt;
          o.DefaultValue = (o.isOpt ? 1 : 0);
        }
      });
    }
  }
};
</script>

<style>
</style>