<template>
  <div class="form_item form-input form_info_line" :class="{ form_info_required: required }">
    <div class="form_info_field">{{formTitle}}</div>
    <div class="form_info_value"
         v-if=" !readonly">
      <el-radio v-for="(item,index) in selectList"
                :key="index"
                v-model="radio"
                :label="item.key"
                @change="changeVal(radio)">{{item.name}}</el-radio>
    </div>
    <div class="form_info_value"
         v-if=" readonly">
      <el-radio v-for="(item,index) in selectList"
                :key="index"
                v-model="radio"
                :label="item.key"
                disabled>{{item.name}}</el-radio>
    </div>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: 'formInput',
  data () {
    return {
      radio: 0 // 选项Key
    };
  },
  props: {
    formTitle: {// 标题，（必填）
      type: String,
      default: ''
    },
    required: Boolean, // 是否必填，可选，如不提供，则为非必填
    readonly: Boolean, // 是否只读，（可选）
    selectList: {// 选择项列表
      type: Array,
      default: function () {
        return [
          { name: '是', key: 1 },
          { name: '否', key: 0 }
        ];
      }
    },

    radioKey: [String, Number] // 选项Key
  },
  computed: {

  },
  created () {
    this.radio = Number(this.radioKey);
  },
  watch: {
    radioKey () {
      this.radio = Number(this.radioKey);
    }
  },
  methods: {
    changeVal (val) {
      this.$emit('change', this.formTitle, val);
    }
  }
};
</script>
<style scoped>
.form_info_value > span {
  display: inline !important;
}
</style>
