<template>
  <div class="code">
    <!-- <vue-qr :text="qrcodeurl" :size="400" :margin="0"></vue-qr> -->
    <div class="ymt_qr_code">
      <div v-if="vqrcodeurl==''"
           class="qrtext">二维码生成中</div>
      <img v-else
           :src="vqrcodeurl" />
    </div>
  </div>
</template>

<script>
// import vueQr from './vue-qr.js';
export default {
  data () {
    return {
    };
  },
  components: {
  },
  props: {
    qrcodeurl: String
  },
  created () {
  },
  mounted () {
  },
  computed: {
    vqrcodeurl () {
      console.log(this.qrcodeurl, 'qrcodeurl');
      return this.qrcodeurl;
    }
  },
  methods: {
  }
};
</script>

<style scoped>
.code {
  text-align: center;
}
.ymt_qr_code {
  flex: 1;
  display: flex;
  align-items: center;
  text-align: center;
}

.ymt_qr_code img {
  border-radius: 6px;
  width: 450px;
  height: 450px;
  margin: 0 auto;
  margin-top: 5px;
}
</style>