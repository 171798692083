<template>
  <!-- 校区督学综合汇总-列表 -->
  <div class="school_inspector_statistics all_view ">
    <!-- 头部 -->
    <div class="school_inspector_title">
      <statistics-tltle-head :titleList="statisticsTltleList"></statistics-tltle-head>
    </div>

    <div v-if="schoolInfo.SchoolKey">
      <!-- 课评统计 -->
      <div v-if="statisticsTltleList[0].isSelected">
        <lesson-remark-statistics :campusList="campusList"
                                  :schoolInfo="schoolInfo"
                                  @search="search"></lesson-remark-statistics>
      </div>

      <!-- 作业统计 -->
      <div v-else-if="statisticsTltleList[1].isSelected">
        <work-statistics :campusList="campusList"
                         :schoolInfo="schoolInfo"
                         @search="search"></work-statistics>
      </div>
      <!-- 备课统计 -->
      <div v-else-if="statisticsTltleList[2].isSelected">
        <prepare-lessons-statistics :campusList="campusList"
                                    :schoolInfo="schoolInfo"
                                    @search="search"></prepare-lessons-statistics>
      </div>
    </div>
    <div v-else
         class="monitoring_content_nodata">
      <div class="monitoring_content_nodata_txt">暂无数据</div>
    </div>
    <div>
      <dialog-factory ref="campusSuperviseSchoolStatistics"
                      :routerName="'campusSuperviseSchoolStatistics'"></dialog-factory>
    </div>
  </div>
</template>
<script>
import statisticsTltleHead from './common/statistics-tltle-head';
import prepareLessonsStatistics from './statistics-list/prepare-lessons-statistics/index';
import lessonRemarkStatistics from './statistics-list/lesson-remark-statistics/index';
import workStatistics from './statistics-list/work-statistics/index';
import dialogFactory from '../../common/dialog-factory';
import { hqSelectSchoolForInspector } from '../../../API/workbench.js';
export default {
  // name: 'campusSuperviseSchoolStatistics',
  data () {
    return {
      campusList: [],
      schoolInfo: { SchoolKey: '', StartDate: this.$utils.getCurrentMonthFirst(), EndDate: this.$utils.getCurrentMonthLast() },
      statisticsTltleList: [
        { name: '课评统计', key: 1, isSelected: true },
        { name: '作业统计', key: 2, isSelected: false },
        { name: '备课统计', key: 3, isSelected: false }
      ]
    };
  },
  components: {
    statisticsTltleHead,
    prepareLessonsStatistics,
    lessonRemarkStatistics,
    workStatistics,
    dialogFactory
  },
  props: {
    searchObj: {
      type: Object,
      default: null
    }
  },
  created () {
    console.log(this.vTypeKey, this.$route.params, 'this.$store.getters');
    if (this.vTypeKey && this.searchObj) { // 总部
      this.getSelectSchoolForInspector();
    } else { // 门店端
      this.campusList = null;
      this.schoolInfo.SchoolKey = this.$store.getters.token.SaaSClientKey;
    }
    this.registerBusEvent();
    console.log('校区督学综合汇总', this.schoolInfo);
  },
  methods: {
    // 获取有效校区列表
    getSelectSchoolForInspector () {
      hqSelectSchoolForInspector().then(result => {
        console.log('获取有效校区列表', this.schoolInfo.SchoolKey, result.data);
        this.campusList = result.data || [];
        this.schoolInfo.SchoolKey = this.searchObj.SchoolKey;
        this.schoolInfo.StartDate = this.searchObj.StartDate;  //	字符串	可选		数据源：GET	开始日期
        this.schoolInfo.EndDate = this.searchObj.EndDate; //	字符串	可选		数据源：GET	结束日期
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 搜索条件变更校区和时间时，同时更新到其他模块
    search (searchObj) {
      this.schoolInfo.SchoolKey = searchObj.SchoolKey;
      this.schoolInfo.StartDate = searchObj.StartDate;  //	字符串	可选		数据源：GET	开始日期
      this.schoolInfo.EndDate = searchObj.EndDate; //	字符串	可选		数据源：GET	结束日期
    },
    registerBusEvent () {
      this.$bus.on(this, 'openCustomDialog', (dialogInfo) => {
        if (dialogInfo.routerName == 'campusSuperviseSchoolStatistics') {
          if (this.$refs.campusSuperviseSchoolStatistics) {
            this.$refs.campusSuperviseSchoolStatistics.openDialog(dialogInfo.moduleName, dialogInfo.dataKey, dialogInfo.data, dialogInfo.callBack, dialogInfo.name);
          }
        }
      });
    }
  },
  computed: {
    vTypeKey () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) { // 总部key为6
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style>
.school_inspector_statistics {
  width: 1366px;
  margin: 0 auto;
}
.school_inspector_title {
  margin-bottom: 60px;
}
.school_inspector_title .table_fixed_nav_list {
  padding: 0 30px;
}
.school_inspector_statistics_top {
  height: 198px;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
}

.school_inspector_statistics_bottom {
  min-height: 400px;
  background: #fff;
  border-radius: 4px;
  display: flex;
  overflow: hidden;
}

.school_inspector_statistics_bottom .bottom_left {
  width: 356px;
  border-radius: 4px 0 0 4px;
  box-shadow: 4px 0px 15px rgba(0, 0, 0, 0.06);
}

.school_inspector_statistics_bottom .bottom_right {
  width: 934px;
  padding: 0 20px;
}

.school_inspector_statistics_top {
  padding: 0 20px;
}

.school_inspector_statistics_top .top_select {
  height: 70px;
  padding-top: 20px;
}

.school_inspector_statistics_top .top_content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 110px;
  padding: 20px 0;
  border: 1px solid #ececec;
  border-radius: 4px;
}

.school_inspector_statistics_top .top_content_list {
  flex: 1;
  text-align: center;
  border-right: 1px solid #ececec;
}

.school_inspector_statistics_top .top_content_list:last-child {
  border-right: none;
}

.school_inspector_statistics_top .top_content_list .list_title {
  font-size: 14px;
  line-height: 1;
}

.school_inspector_statistics_top .top_content_list .list_content {
  font-size: 30px;
  line-height: 34px;
  margin-top: 21px;
}

.school_inspector_statistics .table_select_box {
  margin-right: 10px;
}
.school_inspector_statistics .course_statistics_title {
  padding: 0;
}
.school_inspector_statistics .course_statistics_title .title_date_select {
  width: 200px;
  border: none;
  margin-right: 10px;
  margin-left: 0;
}
.school_inspector_statistics .table_select_box .el-select {
  width: 240px;
}
.school_inspector_statistics
  .course_statistics_title
  .title_date_select
  .el-input
  input {
  width: 190px;
}

.school_inspector_statistics .bottom_left .left_list_ul {
  height: 568px;
  overflow-y: auto;
}
.school_inspector_statistics .bottom_left .left_list_title {
  font-size: 16px;
  font-weight: 700;
  line-height: 68px;
  padding-left: 16px;
  position: relative;
  border-bottom: 1px solid #ececec;
}
.school_inspector_statistics .bottom_left .left_list {
  padding: 15px 20px 15px;
  line-height: 20px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  position: relative;
  cursor: pointer;
  border: 1px solid transparent;
  height: 80px;
}
.school_inspector_statistics .bottom_left .left_list.opt {
  background-color: #eaf4fb;
  color: #3498db;
}
.school_inspector_statistics .bottom_left .left_list.opt:before {
  content: '';
  background-color: #3498db;
  width: 3px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.school_inspector_statistics .bottom_left .left_list_teacher {
  font-size: 16px;
  line-height: 28px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding-right: 10px;
  word-break: break-all;
  width: 100%;
  height: 28px;
}
.school_inspector_statistics .bottom_left .left_list_content {
  color: #999;
}
.school_inspector_statistics .bottom_right .right_list_title {
  font-size: 16px;
  font-weight: 700;
  line-height: 68px;
  position: relative;
  border-bottom: 1px solid #ececec;
}
.school_inspector_statistics .bottom_right .table_fixed_nav_list {
  font-size: 16px;
  padding: 0 30px;
}
.school_inspector_statistics
  .bottom_right
  .table_list_content.summarizing_list {
  padding: 0px;
}
.school_inspector_statistics .bottom_right .mendian_backstage_search {
  margin-left: 0 !important;
  margin-top: 20px !important;
}
</style>

