<template>
  <div>
    <div class="form_info_edit content_marginTop_30 form_info_list single_shadow content_marginRL pr">
      <input-option-text :formTitle="'考勤状态'"
                         :readonly="false"
                         :required="false"
                         :selectList="vattenList"
                         :selectedInfo="vTargetAttence"
                         @change="chgAtten">
      </input-option-text>
      <!-- <form-select :formTitle="'考勤状态'"
                     :required="false"
                     :dataItem="submitData.TargetAttenceStatusKeyValue"
                     :dataList="vattenList"
                     v-model="submitData.TargetAttenceStatusKeyValue"
                     @choseItem="chgAtten"></form-select> -->

      <form-select :formTitle="'是否补课'"
                   :required="false"
                   :dataItem="submitData.IsMakeupKeyValue"
                   :dataList="bukeList"
                   v-model="submitData.IsMakeupKeyValue"
                   @choseItem="chgBuke"
                   :readonly="true"></form-select>
      <input-number :min="0"
                    :max="60"
                    v-model="submitData.ClassHourNum"
                    :decimalPlaces="1"
                    :required="true"
                    :readonly="ClassCountReadonly"
                    :formTitle="'扣减课时数'"></input-number>
      <input-text v-if="!vNoAttence"
                  :formTitle="'处理原因'"
                  style="width: 100%"
                  :readonly="false"
                  :formPlaceholder="'选填'"
                  :required="inputRequired"
                  v-model="submitData.Reason"></input-text>

    </div>
    <!-- 按钮组 -->
    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'static'"
                             @confirmClick="confirmSave"
                             @cancelClick="closeDialog"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { obj2xml } from 'ali-oss/lib/common/utils/obj2xml';
import formSelect from '../../../form-item/form-select';
export default {
  data () {
    return {
      remarks: '',
      attenList: [
        {
          id: 2,
          key: 2,
          value: '未考勤',
          name: '未考勤'
        },
        {
          id: 3,
          key: 3,
          value: '签到',
          name: '签到'
        },
        {
          id: 4,
          key: 4,
          value: '请假',
          name: '请假'
        }

      ],
      bukeList: [
        {
          key: 0,
          value: '否'
        },
        {
          key: 1,
          value: '是'
        }
      ],
      submitData: {
        TargetAttenceStatusKey: 3, // 考勤状态
        TargetAttenceStatusKeyValue: '签到',
        ClassHourNum: 0,
        IsMakeupKey: 0,
        IsMakeupKeyValue: '否',
        Reason: ''
      },
      ClassCountReadonly: false
    };
  },
  components: {
    formSelect
  },
  props: {
    curSelectedStudentInfo: Object,
    courseInfo: Object
  },
  computed: {
    inputRequired(){
      return this.$utils.getSaaSClientSet(124)>0
    },
    vattenList () {
      let newArr = [];
      this.attenList.forEach(o => {
        newArr.push(o);
      });
      if (this.$utils.getSaaSClientSet(59) == 1) {
        newArr.push({
          id: 5,
          key: 5,
          value: '旷课',
          name: '旷课'
        });
      }
      return newArr;
    },
    // 意向级别
    vTargetAttence () {
      return {
        id: Number(this.submitData.TargetAttenceStatusKey),
        name: this.submitData.TargetAttenceStatusKeyValue,
        value: this.submitData.TargetAttenceStatusKeyValue
      };
    },
    vNoAttence () {
      return this.curSelectedStudentInfo.AttenceStatusKey == 2 || !this.curSelectedStudentInfo.AttenceStatusKey;
    }
  },
  created () {
    this.submitData.TargetAttenceStatusKey = this.curSelectedStudentInfo.AttenceStatusKey || 2;
    this.submitData.TargetAttenceStatusKeyValue = this.curSelectedStudentInfo.AttenceStatusKeyValue || '未考勤';
    this.submitData.IsMakeupKey = this.curSelectedStudentInfo.IsMakeupKey || 0;
    this.submitData.IsMakeupKeyValue = this.curSelectedStudentInfo.IsMakeupKey == 0 ? '否' : '是';
    if (this.submitData.TargetAttenceStatusKey == 2) {
      this.ClassCountReadonly = true;
    }

    // else if(this.submitData.TargetAttenceStatusKey==4){
    //    this.ClassCountReadonly=this.courseInfo.IsleaveKey!=1
    // }else if(this.submitData.TargetAttenceStatusKey==5){
    //    this.ClassCountReadonly=this.courseInfo.IsChargesKey!=1
    // }
    if (!(this.curSelectedStudentInfo.AttenceStatusKey == 2 || !this.curSelectedStudentInfo.AttenceStatusKey)) {
      this.submitData.ClassHourNum = Number(this.curSelectedStudentInfo.ClassCount);
    }
  },
  methods: {
    chgAtten (item) {
      console.log('chgAtten', item);
      this.submitData.TargetAttenceStatusKey = item.key;
      this.submitData.TargetAttenceStatusKeyValue = item.value;
      this.submitData.ClassHourNum = 0;
      if (item.key == 2) {
        this.ClassCountReadonly = true;
      } else if (item.key == 4) {
        // this.ClassCountReadonly=this.courseInfo.IsleaveKey!=1
        // if(!this.ClassCountReadonly){
        this.ClassCountReadonly = false;
        this.submitData.ClassHourNum = this.courseInfo.IsleaveKey != 1 ? 0 : Number(this.courseInfo.PeriodCount);
        // }
      } else if (item.key == 5) {
        // this.ClassCountReadonly=this.courseInfo.IsChargesKey!=1
        // if(!this.ClassCountReadonly){
        this.ClassCountReadonly = false;
        this.submitData.ClassHourNum = this.courseInfo.IsChargesKey != 1 ? 0 : Number(this.courseInfo.PeriodCount);
        // }
      } else {
        this.ClassCountReadonly = false;
        this.submitData.ClassHourNum = Number(this.courseInfo.PeriodCount);
      }
    },
    chgBuke (item) {
      this.submitData.IsMakeupKey = item.key;
      this.submitData.IsMakeupKeyValue = item.value;
      if (this.submitData.IsMakeupKey == 1) {
        this.submitData.ClassHourNum = 0;
      } else {
        this.submitData.ClassHourNum = Number(this.courseInfo.PeriodCount);
      }
    },
    confirmSave (callback) {
       if(this.inputRequired&&!this.submitData.Reason){
          layer.alert("请输入处理原因")
          if(callback){
            callback()
          }
        }else{
      this.$emit('changeAttendance', this.submitData, 0, 'other', callback);
        }
    },
    closeDialog () {
      this.$emit('cancel');
    }
  }
};
</script>