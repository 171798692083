
  <template>
  <!-- 待审核页面循环组件 -->

  <div class="income_expenditure_manage_list">
    <!-- 待审核页面不显示箭头角标 -->
    <div class="income_expenditure_manage_audit"
         @click.stop="selectItem(sourceItem,$event)">
      <div class="income_expenditure_manage_list_title"
           v-if="isBtnGroup">
        <span class="title_name">收支流水</span>
        <span>{{sourceItem.SourceKeyName}}</span>
      </div>
      <div class="income_expenditure_manage_list_title"
           v-else>
        <span class="title_name">收支流水</span>
        <span>{{sourceItem.SourceKeyName}}</span>
      </div>
      <div :class="sourceItem.StatusKey == 5?'income_expenditure_manage_list_text2':sourceItem.StatusKey == 6?'income_expenditure_manage_list_text3':''"
           class="income_expenditure_manage_list_text clearfix">
        <div class="income_expenditure_manage_list_number fl">
          <div v-if="sourceItem.IncomePayTypeKey ==1">
            <div class="font_show text_yellow">{{ Number(sourceItem.InAmount)}}</div>
            <div class="font_gray">
              <span>收入金额</span>
            </div>
          </div>
          <div v-if="sourceItem.IncomePayTypeKey ==2">
            <div class="font_show font_red">{{Number(sourceItem.PayAmount)}}</div>
            <div class="font_gray">
              <span>支出金额</span>
            </div>
          </div>
        </div>
        <div class="income_expenditure_manage_list_right fl">
          <div>
            <span class="font_gray">收支科目:</span>
            <span>{{sourceItem.IncomePaySubKeyValue}}</span>
          </div>
          <div>
            <span class="font_gray">收支账户:</span>
            <span>{{sourceItem.IncomePayAccountKeyValue}}</span>
          </div>
          <div>
            <span class="font_gray">发生人:</span>
            <span>{{sourceItem.HapPeolKeyValue}}</span>
          </div>
          <div>
            <span class="font_gray">发生来源:</span>
            <span>{{sourceItem.HapSourceKeyKeyValue}}</span>
          </div>
          <div>
            <span class="font_gray">发生时间:</span>
            <span>{{vCreateTime}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { AuditSZGL } from '../../../API/workbench.js';
export default {
  data () {
    return {
      isShow: '',
      iconText: false
    };
  },
  props: {
    sourceItem: Object,  // 为待审核对象.
    isBtnGroup: Boolean
  },
  computed: {
    vCreateTime () {
      if (this.sourceItem.StatusKey == 5) {  //  " 5-已拒绝"
        this.iconText = true;
      }
      if (this.sourceItem.StartTimeFrom) {
        return this.sourceItem.StartTimeFrom.replace(/-/g, '.');
      } else {
        return '';
      }
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    }
  },

  created () {
  },
  watch: {
  },
  methods: {
    // 判断权限,
    vHasModulePower (ModulePowerKey) {
      console.log(this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey), '判断权限');
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    },
    // 选择-待处理项
    selectItem (item, $e) {
      this.$emit('selectItem', item, $e);
    },
    // 审核通过
    auditPass (item) {
      if (this.vHasModulePower(43)) {
        var _this = this;
        layer.confirm('请确定是否审核通过?', {
          btn: [{
            name: '确认',
            callBack: function () {
              layer.close();
              AuditSZGL(item.TableID, 1).then(result => {
                _this.$emit('auditPass');
              }, error => {
                layer.alert(error.msg);
              });
            }
          }
          ]
        });
      } else {
        this.$utils.CheckModulePowerToTips(43);
      }
    },
    // 审核拒绝
    auditReject (item) {
      if (this.vHasModulePower(43)) {
        var _this = this;
        layer.confirm('请确定是否审核拒绝?', {
          btn: [{
            name: '确认',
            callBack: function () {
              layer.close();
              AuditSZGL(item.TableID, 0).then(result => {
                _this.$emit('auditPass');
              }, error => {
                layer.alert(error.msg);
              });
            }
          }
          ]
        });
      } else {
        this.$utils.CheckModulePowerToTips(43);
      }
    }
  }
};
</script>

<style>
.font_show {
  color: #333;
  min-height: 16px;
}
.income_expenditure_manage_list_text3 {
  background: url(../../../../public/image/income_type03.png) no-repeat 302px
    70px !important;
}
</style>

