<template>
  <div class="apply_payment_echarts_hous massive_bg echarts_hous_mb">
    <div class="statistics_echarts_left fl">
      <div class="apply_payment_echarts_left_title clearfix">
        <div class="title_font fl">课消金额统计日报</div>
        <single-year-picker @chgDate="chgDate"
                            :type="'month'"></single-year-picker>
        <div class="title_month fr">
          <span class="this_month">本月</span>
          <span class="past_month">上个月</span>
        </div>
      </div>
      <div>
        <doubleTypeEchart :echartID="'graph5'"
                          :reqList="monthList"
                          :nowDataList="nowList"
                          :preDataList="oldList"
                          :echartType="'line'"
                          ref="echart"></doubleTypeEchart>
      </div>
      <div>
        <a @click.stop="showDetail"
           class="class_statistics_details_btn">
          查看详情
        </a>
      </div>
    </div>
    <rateItem :formTitle="'课消金额'"
              :nowDataList="nowDataList"
              :oldDataList="oldDataList"
              :seletedDate="seletedDate"></rateItem>
  </div>
</template>
<script>
import singleYearPicker from '../date-picker-common/single-year-picker';
import doubleTypeEchart from '../echart-common/double-type-echart';
import rateItem from './rate-item';
import { getClassCountUsedAmountReportByDay } from '../../../API/workbench';
export default {
  name: 'classAmountDayReport',
  data () {
    return {
      seletedDate: Date.new(Date.new().setDate(1)),
      startTime: null,
      endTime: null,
      monthList: [],
      nowList: [],    // 选中月数据显示的内容
      oldList: [],    // 上个月数据显示的内容
      nowDataList: [],     // 选中当月数据源
      oldDataList: []      // 选中的上个月数据源
    };
  },
  components: {
    singleYearPicker, doubleTypeEchart, rateItem
  },
  mounted () {
    this.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy/MM/dd');
    this.endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy/MM/dd');
    this.monthList = this.getLongDaysOfMonth();
    this.getNowKQDataByDay(this.startTime, this.endTime, this.getLastKQDataByDay);
    // this.initGetMonthData(this.getLastKDSYDataByMonth);
  },
  methods: {
    showDetail () {
      this.$emit('showDetail', '日报', this.startTime, this.endTime);
    },
    chgDate (val, startTime, endTime) {
      this.seletedDate = val;
      this.startTime = startTime;
      this.endTime = endTime;
      this.monthList = this.getLongDaysOfMonth();
      console.log(this.monthList, '最长', startTime, endTime);
      this.getNowKQDataByDay(this.startTime, this.endTime, this.getLastKQDataByDay);
    },
    // 获取最长的月份.
    getLongDaysOfMonth () {
      let testTime = this.seletedDate || Date.new();
      let nowDayOFMonth = this.getDaysOfMonth(this.seletedDate);
      let preMonthDate = Date.new(testTime.getFullYear(), testTime.getMonth() - 1, 1);
      let preDayOfMonth = this.getDaysOfMonth(preMonthDate);
      let xAxisData;
      if (nowDayOFMonth.length > preDayOfMonth.length) {
        xAxisData = nowDayOFMonth;
      } else {
        xAxisData = preDayOfMonth;
      }
      return xAxisData;
    },
    // 获取天数数组
    getDaysOfMonth (monthDate) {
      monthDate = monthDate || Date.new(Date.new().setDate(1));
      let dayList = [];
      let selectedMonth = Date.new(monthDate.getFullYear(), monthDate.getMonth() + 1, 0);
      let dayMany = selectedMonth.getDate();
      for (let i = 1; i <= dayMany; i++) {
        dayList.push(i);
      }
      return dayList;
    },
    // 获取选中月数据.
    getNowKQDataByDay (startTime, endTime, callback) {
      getClassCountUsedAmountReportByDay(startTime, endTime).then(result => {
        this.nowDataList = result.data.DataList;
        console.log('getClassCountSpendReportByDay', this.nowDataList);
        this.nowList = this.getNowList();
        if (callback) {
          callback();
        }
      }, r => {
        console.log(r);
      });
    },
    // 获取选中月的上个月数据.
    getLastKQDataByDay () {
      let year = Date.new(this.startTime).getFullYear();    // 纯年
      let month = Date.new(this.startTime).getMonth() + 1;   // 纯月
      let testTime = Date.new(year, month - 2); // 上个月
      let startTime = this.$utils.formatDateStr(testTime.toLocaleDateString(), 'yyyy/MM/dd');
      let endTime = this.$utils.formatDateStr(this.$utils.setChoseEndTime(testTime), 'yyyy/MM/dd');
      getClassCountUsedAmountReportByDay(startTime, endTime).then(result => {
        this.oldDataList = result.data.DataList;
        this.oldList = this.getOldList(Date.new(this.seletedDate));
        this.$nextTick(() => {
          this.$refs.echart.echartInit();
        });
      }, r => {
        console.log(r);
      });
    },
    // 获取选中年数据 因为数据呈现为12个月. 所以如果返回数据不符合该月份的话直接push '0'
    getNowList () {
      let nowList = [];
      let monthList = this.getDaysOfMonth(this.seletedDate);
      monthList.forEach(p => {
        let item = this.nowDataList.find(o => {
          return Date.new(o.SetUpTime).getDate() == parseInt(p);
        });
        if (item) {
          nowList.push(Number(item.totalDeductAmount).toFixed(2));
        } else {
          nowList.push('0');
        }
      });
      return nowList;
    },
    // 获取上一年数据   因为数据呈现为12个月. 所以如果返回数据不符合该月份的话直接push '0'
    getOldList (timeDate) {
      let oldList = [];
      timeDate = timeDate || Date.new();
      let preMoth = Date.new(timeDate.getFullYear(), timeDate.getMonth() - 1, 1);
      let monthList = this.getDaysOfMonth(preMoth);
      monthList.forEach(p => {
        let item = this.oldDataList.find(o => {
          return Date.new(o.SetUpTime).getDate() == parseInt(p);
        });
        if (item) {
          oldList.push(Number(item.totalDeductAmount).toFixed(2));
        } else {
          oldList.push('0');
        }
      });
      return oldList;
    }

  }
};
</script>

