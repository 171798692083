import { render, staticRenderFns } from "./record-item.vue?vue&type=template&id=4c0975de&scoped=true"
import script from "./record-item.vue?vue&type=script&lang=js"
export * from "./record-item.vue?vue&type=script&lang=js"
import style0 from "./record-item.vue?vue&type=style&index=0&id=4c0975de&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c0975de",
  null
  
)

export default component.exports