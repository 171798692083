<template>
  <!-- 通用列表 -->
  <div :class="position">
    <el-table element-loading-text="拼命加载中"
              class="table_list"
              ref="multipleTable"
              :data="vTableData"
              highlight-current-row
              stripe
              :border="false"
              empty-text
              toggleRowSelection
              :default-sort="defaultSort"
              :expand-row-keys="expandRowKeys"
              style="width: 100%;"
              :height="heightValue"
              :show-summary="isShowSummary"
              :summary-method="getSummaries"
              :cell-class-name="cellcb"
              :row-key="getRowKeys"
              @sort-change="sortChange"
              @row-click="clickRow"
              @select-all="changeCheckBoxFun"
              @select="changeCheckBoxFun">

      <el-table-column type="selection"
                       width="55"
                       :reserve-selection="true"
                       v-if="isShowCheckBox"></el-table-column>

      <component v-for="columnInfo in vTableColumns"
                 :key="columnInfo.prop"
                 v-bind:is="columnInfo.type"
                 :columnData="columnInfo"
                 @onClickExpandRow="expandRowEvent"
                 @onclickShowMore="expandRowshowMore"></component>
      <!-- fixed="center" -->
      <el-table-column type="expand"
                       v-if="expandRowData && expandRowData.length>0">
        <template slot-scope="props">
          <div v-if="curExpandRowInfo.expandType=='form'">
            <expand-row-form :rowData="props.row"
                             :tableColumns="curExpandRowInfo.tableColumns"></expand-row-form>
          </div>
          <div v-else>
            <expand-row-table :tableData="expandRowData"
                              :tableColumns="curExpandRowInfo.tableColumns"
                              :isLoading="curExpandRowInfo.isLoading"
                              :tablePaddingLeft="curExpandRowInfo.left"
                              :iconPositionLeft="curExpandRowInfo.positionLeft"
                              :downBoxText="curExpandRowInfo.downBoxText"
                              :tableWidth="curExpandRowInfo.width"
                              @downBoxClick="downBoxClick"></expand-row-table>
          </div>
        </template>
      </el-table-column>
      <!-- </div> -->
      <div slot="empty"
           style="height:440px;line-height:440px;">
        <i class="el-icon-warning-outline">
          <div class="monitoring_content_nodata"></div>
          亲，当前没有任何数据哦~
        </i>
      </div>
    </el-table>
    <div class="module_content_menuList"
         style="width: 80px"
         :style="{'left':positionLeft+'px','top':positionTop+'px'}"
         v-if="isShowdropDownBox">
      <div class="top_bg"
           style=""></div>
      <drop-down-box :optionJsonList="btnInfo.btnList"
                     @clickOptionForAlertDialog="doAfterExceptBtn"></drop-down-box>
    </div>
    <!-- 分页 -->
    <div v-if="queryParams.pageSize > 0&&showPagePagination"
         class="table_pagination table_pagination_right">
      <div class="table_left_text">
        {{footerContent}}
      </div>

      <el-pagination v-if="showPagePagination"
                     @current-change="handleCurrentChange"
                     :page-size="queryParams.pageSize"
                     :current-page.sync="vPageIndex"
                     background
                     :pager-count="5"
                     layout="prev, pager, next, jumper"
                     :total="totalNum>0?totalNum:queryParams.pageSize"></el-pagination>
      <table-pagination-number v-if="showPagePagination"
                               :pageSize="queryParams.pageSize"
                               :queryParams="queryParams"
                               @loadTableData="loadTableData"></table-pagination-number>
    </div>
    <div v-if="footerContent&&queryParams.pageSize ==0"
         class="table_pagination table_pagination_right">
      <div class="table_left_text">
        {{footerContent}}
      </div>
    </div>
    <div v-if="isShowHeaderFilter"
         class="pa setting_icon"
         @click.stop="openColumnsSetting"></div>
    <custom-dialog title="列表设置"
                   width="410px"
                   :visible.sync="isShowHeaderFilterDialog"
                   :before-close="closeColumnsSetting">
      <column-header-filtering :tableKey="tableKey"
                               :allColumnsList="tableColumns"
                               @close="closeColumnsSetting"
                               @afterSuccess="headerFilterAfterSuccess"></column-header-filtering>
    </custom-dialog>
  </div>
</template>
<script>
import columnHeaderFiltering from './column-header-filtering/index.vue'; // 自定义列
import tableIndex from './column/table-index';// 序号
import textItem from './column/text-item';// 文本
import htmlItem from './column/html-item';
import toolTipsItem from './column/tool-tips-item';
import student from './column/student';// 学生
import mdName from './column/md-name';// 学生
import arrangeSupport from './column/arrange-support';// 排课备注
import remarksItem from './column/remarks-item';// 备注
import dateItem from './column/date-item'; // 日期
import classHour from './column/class-hour';// 课时
import amountItem from './column/amount-item';// 金额
import numberItem from './column/number-item';// 数字
import readingCourses from './column/reading-courses';// 通用在读课程
import wechatSubscribe from './column/wechat-subscribe';// 微信关注icon
import faceRecognition from './column/face-recognition';// 面容识别icon
import multiLevelHead from './column/multi-level-head';// 多级表头
import tableBtn from './column/table-btn';// 操作
import hiddenBtn from './column/hidden-btn';// 操作
import expandRowTable from './expand-row-table/index';// 展开行 table
import expandRowForm from './expand-row-form/index';// 展开行 表单
import tablePaginationNumber from '../../common/table-pagination-number'; // table 分页
import lineNumber from './column/line-number';
import iconItem from './column/icon-item';
import readingCourseDetail from '../../report/student-management/reading-course-detail';
import readingGradeDetail from '../../report/class-grade-applycourse/general-class-grade-apply-course-list/reading-grade-detail';
import dropDownBox from '../drop-down-box';
import upSellSupport from '../../report/try-arrange-course/components/up-sell-support';
import sellDocumentaryTableRow from '../../report/customer-management/components/sell-documentary-table-row';
import sellDocumentaryTableBtn from '../../report/customer-management/components/sell-documentary-table-btn';
import followUpRecordColumn from '../../head-quarters/attract-investment-manage/table-column/follow-up-record-column';// 跟单记录
import nextFollowTimeColumn from '../../head-quarters/attract-investment-manage/table-column/next-follow-time-column';// 下次跟单时间
import textDualItem from './column/text-dual-item';
import headimgItem from './column/headimg-item';
import popoverItem from './column/popover-item';// 文本
import iconAuthorizationColum from '../../head-quarters/courseware-authorization-management/common/icon-authorization-colum';// 下次跟单时间
export default {
  data () {
    return {
      curExpandRowInfo: { tableData: [], tableColumns: [], expandType: 'table', prop: '', isLoading: false, width: 0, left: 0, positionLeft: 0, downBoxText: '' }, // 当前查看详情的列信息
      expandRowKeys: [], // 展开行对应的Key 类型于序列号,
      selectedRow: {}, // 选中行
      isShowInnerScroll: true,
      isShowSummary: false,
      isShowdropDownBox: false,
      seletedData: null,
      btnInfo: null,
      positionLeft: 0,
      positionTop: 0,
      checkBoxList: [],
      isShowHeaderFilterDialog: false, // 是否显示自定义列表弹出层
      fieldList: []
    };
  },
  props: {
    tableData: Array, // 列表数据
    detailColumns: Array, // 显示详情内容
    expandRowData: {
      type: Array,
      default: null
    },
    rowKey: String, // 显示详情对应绑定的ID
    totalNum: Number, // 列表总共条数
    queryParams: {
      type: Object,
      default: () => {
        return {
          pageIndex: 0,
          pageSize: 10,
          orderBy: '',
          sequence: '',
          searchText: ''
        };
      }
    },
    position: {
      type: String,
      default: 'pr'
    },
    tableKey: {
      type: String,
      default: ''
    },
    tableColumns: {
      type: Array,
      default: () => {
        return [
          {
            label: {// 列名
              type: String,
              required: true
            },
            prop: {// 绑定的字段名
              type: String,
              required: true
            },
            type: {// 类型
              type: String,
              required: true
            },
            extend: {

            },
            width: 120, // 默认宽
            sortable: true, // 是否可排序
            align: 'left', // 对戏方式
            isShow: false// 是否显示
          }
        ];
      }
    }, // table column
    summary: {
      type: Object,
      default: function () {
        return {
          isShowSummary: false,
          needSummariesColumns: [],
          summaryName: '合计',
          summaryData: []
        };
      }
    },
    defaultSort: {
      type: Object,
      default: () => {
        return { prop: '', order: 'ascending' };
      }
    },
    isShowHeaderFilter: { // 是否显示自定义列表设置
      type: Boolean,
      default: false
    },
    isNeedInnerScroll: {
      type: Boolean,
      default: false
    },
    footerContent: {    // 显示底部左下角统计文字
      type: String,
      default: ''
    },
    isShowCheckBox: {    // 显示复选框 （默认隐藏）
      type: Boolean,
      default: false
    },
    showPagePagination: { // 是否显示分页
      type: Boolean,
      default: true
    },
    heightValue: String
  },
  components: {
    textItem,
    tableIndex,
    student,
    mdName,
    arrangeSupport,
    dateItem,
    readingCourses,
    classHour,
    amountItem,
    numberItem,
    wechatSubscribe,
    faceRecognition,
    multiLevelHead,
    readingCourseDetail,
    readingGradeDetail,
    tableBtn,
    hiddenBtn,
    lineNumber,
    iconItem,
    expandRowTable,
    expandRowForm,
    tablePaginationNumber,
    upSellSupport,
    remarksItem,
    dropDownBox,
    sellDocumentaryTableRow,
    sellDocumentaryTableBtn,
    followUpRecordColumn,
    nextFollowTimeColumn,
    textDualItem,
    headimgItem,
    toolTipsItem,
    iconAuthorizationColum,
    popoverItem,
    htmlItem,
    columnHeaderFiltering

  },
  computed: {
    vPageIndex: {
      get () {
        return this.queryParams.pageIndex + 1;
      },
      set (n, o) {
      }
    },
    vTableColumns () {
      let columns = this.fieldList.filter(obj => { return obj.isShow == undefined || obj.isShow; });
      return columns;
    },
    vTableData () {
      let items = {
        isEmpertyRow: true
      };
      let arr = [];
      let oldTableDataLength = this.$utils.parseJson(this.tableData.length);
      if (this.tableData.length < this.queryParams.pageSize && this.tableData.length > 0) {
        arr = this.tableData;
        for (let index = 1; index <= (this.queryParams.pageSize - oldTableDataLength); index++) {
          arr.push(items);
        }
      } else if (this.tableData.length >= this.queryParams.pageSize) {
        arr = this.tableData;
      }
      console.log('this.tableData', arr);
      return arr;
    }
  },
  created () {
    console.log('this.tableData', this.expandRowData, this.vTableColumns);
    // this.queryParams = this.$utils.parseJson(this.queryParamInfo);
    // 用于编辑显示数据
    this.fieldList = this.isShowHeaderFilter ? this.fieldList = Object.assign([], this.getTableAgentSetting()) : Object.assign([], this.tableColumns);
    console.log('this.fieldList', this.fieldList);
  },
  mounted () {
    if (this.isNeedInnerScroll) {
      setTimeout(() => {
        this.caculInnerScroll();
      }, 600);
    }
    document.querySelector('body').addEventListener('click', e => {
      this.isShowdropDownBox = false;
    }, false);
  },
  watch: {
    // tableData () {
    //   this.expandRowKeys = [];
    // },
    // queryParamInfo () {
    //   this.queryParams = this.$utils.parseJson(this.queryParamInfo);
    // }
  },
  methods: {
    getTableAgentSetting () {
      let defualtColumnsList = this.$initJson.tableColumnsList;
      let tableColumnsList = [];
      defualtColumnsList.forEach(item => {
        if (item.tableKey == this.tableKey) {
          tableColumnsList = item;
        }
      });
      let arr = [];
      if (tableColumnsList.tableColumns && tableColumnsList.tableColumns.length > 0) {
        arr = Object.assign([], tableColumnsList.tableColumns);  // 用于编辑显示数据
      }
      let allarr = JSON.parse(localStorage.getItem('AgentSettingInfor'));
      let tempArr = [];
      console.log('defualtColumnsList', defualtColumnsList, tableColumnsList, arr);
      if (allarr && allarr.length > 0) {
        allarr.forEach(item => {
          if (item.SetKey == this.tableKey) {
            tempArr = item.SetValue;
          }
        });
      }

      if (tempArr.length == 0) {
        tempArr = Object.assign([], arr);
      }
      let columnArr = this.getLocalDataFunc(tempArr, this.tableColumns, 1);
      return columnArr.length > 0 ? columnArr : this.tableColumns;
    },
    getLocalDataFunc (tempArr, tableColumns, type) {
      if (typeof tempArr == 'string') {
        tempArr = JSON.parse(tempArr);
      }
      let columnArr = [];
      console.log('tempArr', tempArr);
      if (tempArr.length > 0) {
        let btempArr = [];
        tempArr.forEach(item => {
          btempArr.push(item.label);
        });
        tableColumns.forEach(item => {
          let aindex = tempArr.findIndex(i => {
            return type == 1 ? i.label == item.label : i == item.title;
          });
          if (aindex > -1) {
            item.width = tempArr[aindex].width;
            item.isShow = tempArr[aindex].isShow;
            columnArr.push(item);
          }
        });

        // 更新表格展示列
        columnArr.sort((a, b) => {
          const aKey = type == 1 ? a.label : a.title;
          const bKey = type == 1 ? b.label : b.title;
          console.log('aKey', aKey, bKey, Object.keys(a));
          if (btempArr.indexOf(aKey) == -1) {
            return false;
          }
          if (btempArr.indexOf(bKey) == -1) {
            return true;
          }
          return btempArr.indexOf(aKey) - btempArr.indexOf(bKey);
        });
      }
      console.log('columnArr', columnArr);
      return columnArr;
    },
    // 跳转到第几页
    handleCurrentChange (val) {
      this.selectedRow = {};
      this.$refs.multipleTable.setCurrentRow();
      this.queryParams.pageIndex = val - 1;
      this.loadTableData(this.queryParams);
    },
    // 排序
    sortChange (column, prop) {
      if (column.prop) {
        this.queryParams.orderBy = column.prop;
        this.queryParams.sequence = column.order == 'ascending' ? 'asc' : 'desc';
      }
      this.loadTableData(this.queryParams);
    },
    // 展开行
    expandRowEvent (rowData, columnInfo, positionLeft) {
      console.log(this.rowKey, 'expandRowEvent', this.expandRowKeys[0], rowData);
      if (rowData[this.rowKey] == this.expandRowKeys[0] && this.curExpandRowInfo.prop == columnInfo.prop) {
        this.expandRowKeys = [];
        this.curExpandRowInfo.isLoading = false;
        this.curExpandRowInfo.tableData = [];
      } else {
        this.expandRowKeys.splice(0, 1, rowData[this.rowKey]);
        this.curExpandRowInfo.tableColumns = columnInfo.extend.tableColumnsDetail;
        this.curExpandRowInfo.prop = columnInfo.prop;
        this.curExpandRowInfo.expandType = columnInfo.extend.expandType;
        if (columnInfo.extend.expandType != 'form') {
          this.curExpandRowInfo.isLoading = true;
          this.curExpandRowInfo.positionLeft = positionLeft + 'px';
          this.curExpandRowInfo.width = columnInfo.extend.tableWidth;
          this.curExpandRowInfo.left = columnInfo.extend.tableleft;
          this.curExpandRowInfo.downBoxText = columnInfo.extend.downBoxText;
          columnInfo.extend.loadtableData(rowData, (data) => {
            this.expandRowKeys.splice(0, 1, rowData[this.rowKey]);
            this.curExpandRowInfo.isLoading = false;
            this.curExpandRowInfo.tableData = data;
          });
        }
      }
    },
    // 展开行底部按钮
    downBoxClick () {
      this.$emit('downBoxClick', this.expandRowKeys);
    },
    // 选中行
    clickRow (row, column, event) {
      this.selectedRow = row;
      console.log(row, '选中行');
      if (!this.isShowCheckBox) { // 复选框和选中行两者互斥，防止选择行时，复选框被选中
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(row, true);
      }
      this.$emit('clickRow', row);
    },
    // 显示选中项
    getRowKeys (row) {
      if (this.isStudentKey) {
        if (row && row.StudentKey && this.isShowCheckBox) {
          return row.StudentKey;
        } else {
          if (row[this.rowKey]) {
            return row[this.rowKey] || 'none';
          }
        }
      } else {
        if (row && row.XYKDKey && this.isShowCheckBox) {
          return row.XYKDKey;
        } else {
          if (row[this.rowKey]) {
            return row[this.rowKey] || 'none';
          }
        }
      }
    },
    // 没有数据时，去掉多余复选框
    cellcb (row) {
      if (!row.row.Number && this.isShowCheckBox) {
        return 'myCell';
      }
    },
    // 选中框-复选框内容
    changeCheckBoxFun (list, row) {
      console.log('list, row', list, row);
      let newList = this.$utils.parseJson(list);
      if (row) { // 单选
        let newRow = this.$utils.parseJson(row);
        this.$set(newRow, 'isOpt', false);
        newList.forEach(o => {
          if (o.Number == newRow.Number) {
            this.$set(newRow, 'isOpt', true);
          }
        });
        var findIndex = this.checkBoxList.findIndex(o => { // 找到该下标
          return Number(o.Number) == Number(newRow.Number) && newRow.isOpt == false;
        });
        if (findIndex >= 0 && !newRow.isOpt) {
          this.checkBoxList.splice(findIndex, 1);// 删除
        } else if (newRow.isOpt) {
          this.checkBoxList.push(newRow);// 添加
        }
      } else { // 全部
        this.checkBoxList = newList;
      }
      // this.checkBoxList = this.unique(this.checkBoxList);
      let newarr = this.checkBoxList.filter(obj => { return obj.Number && obj.Number > 0; });// 防止空行被选中
      this.$emit('changeCheckBoxFun', newarr);
      console.log('复选框内容', newarr);
    },
    // 去重
    unique (arr) {
      let res = new Map();
      if (this.isStudentKey) {
        return arr.filter((arr) => !res.has(arr.StudentKey) && res.set(arr.StudentKey, 1));
      } else {
        return arr.filter((arr) => !res.has(arr.XYKDKey) && res.set(arr.XYKDKey, 1));
      }
    },
    loadTableData (queryParams) {
      console.log('翻页', queryParams);
      this.expandRowKeys = [];
      this.$emit('loadTableData', queryParams);
    },
    getSummaries (param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.summary.summaryName;
          return;
        }
        let findItem = this.summary.needSummariesColumns.find(o => {
          return o.prop == column.property;
        });
        if (findItem) {
          // 非数字类型 一般统计个数
          if (findItem.SumType == 'string') {
            sums[index] = this.summary.summaryData.length + findItem.unit;
          } else {
            const values = this.summary.summaryData.map(item => Number(item[column.property]));
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += findItem.unit;
            }
          }
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
    caculInnerScroll () {
      // table的宽度
      let tableWidth = document.getElementsByClassName('el-table table_list')[0] ? document.getElementsByClassName('el-table table_list')[0].clientWidth : 0;
      // table内容的宽度
      let contentWidth = document.getElementsByClassName('el-table__body')[0] ? document.getElementsByClassName('el-table__body')[0].clientWidth : 0;
      // table 左边固定宽
      let leftFix = document.getElementsByClassName('el-table__fixed')[0] ? document.getElementsByClassName('el-table__fixed')[0].clientWidth : 0;
      // table 右边固定宽
      let rightFix = document.getElementsByClassName('el-table__fixed-right')[0] ? document.getElementsByClassName('el-table__fixed-right')[0].clientWidth : 0;
      // 合计的高度
      let countColumnHeight = document.getElementsByClassName('el-table__fixed-footer-wrapper')[0] ? document.getElementsByClassName('el-table__fixed-footer-wrapper')[0].clientHeight : 0;

      if (tableWidth >= contentWidth) {
        this.isShowInnerScroll = false;
      } else {
        this.isShowInnerScroll = true;
        if (document.getElementsByClassName('el-table__body-wrapper')[0]) {
          document.getElementsByClassName('el-table__body-wrapper')[0].style.overflowX = 'hidden';
        }
        this.$nextTick(() => {
          document.getElementsByClassName('scroll_content')[0].style.width = contentWidth + 'px';

          document.getElementsByClassName('float-scroll')[0].style.width = (tableWidth - leftFix - rightFix) + 'px';
          document.getElementsByClassName('float-scroll')[0].style.marginLeft = leftFix + 'px';
          document.getElementsByClassName('float-scroll')[0].style.marginTop = -countColumnHeight + 'px';
        });
      }
    },
    scrollEvent (e) {
      let ETarget = e.target;
      // table的宽度
      let tableWidth = document.getElementsByClassName('el-table table_list')[0].clientWidth;
      // table内容的宽度
      let contentWidth = document.getElementsByClassName('el-table__body')[0].clientWidth;
      // 左边固定宽
      let leftFix = document.getElementsByClassName('el-table__fixed')[0].clientWidth;
      // 右边固定宽
      let rightFix = document.getElementsByClassName('el-table__fixed-right')[0].clientWidth;
      // 滚动条外框的宽度
      document.getElementsByClassName('float-scroll')[0].style.width = (tableWidth - leftFix - rightFix) + 'px';

      document.getElementsByClassName('el-table__body-wrapper')[0].scrollLeft = (ETarget.scrollLeft / (contentWidth / (contentWidth - leftFix - rightFix))) - 120;
    },
    setCurrentRow () {
      this.$refs.multipleTable.setCurrentRow();
    },
    doAfterExceptBtn (item) {
      this.isShowdropDownBox = false;
      this.$emit('doAfterExceptBtn', item, this.seletedData);
    },
    expandRowshowMore (rowData, btnInfo, e) {
      this.btnInfo = btnInfo;
      this.seletedData = rowData;
      this.isShowdropDownBox = true;
      // this.$emit('setPosition', Rect.left - parentRect.left, Rect.bottom - parentRect.top + 14, parentRect.top); // event.clientX  event.clientY   //显示修改考勤
      let Rect = this.$el.getBoundingClientRect();
      this.positionLeft = e.clientX - e.offsetX - Rect.left - 17;
      this.positionTop = e.clientY - e.offsetY - Rect.top + 25;
      console.log(e, Rect);
    },
    openColumnsSetting () {
      this.isShowHeaderFilterDialog = true;
    },
    closeColumnsSetting () {
      this.isShowHeaderFilterDialog = false;
    },
    headerFilterAfterSuccess (data) {
      this.fieldList = [];
      setTimeout(() => {
        this.fieldList = Object.assign([], this.getLocalDataFunc(data, this.tableColumns, 1));
        this.$forceUpdate();
      }, 200);
    }
  }
};
</script>
<style>
.el-table__expand-column {
  display: none;
}
</style>

<style  scoped>
.setting_icon {
  position: absolute;
  background-image: url(../../../../public/image/courseware_set.png);
  background-position: center;
  background-size: 15px;
  width: 30px;
  height: 30px;
  right: 5px;
  top: 15px;
  z-index: 1;
  background-repeat: no-repeat;
  cursor: pointer;
  /* background-color: #f0f0f9; */
  border: 1px solid #ececec;
  border-radius: 6px;
}
</style>

