<template>
  <!-- 优化组件 -->
  <div class="stundent_list_box single_result single_student single_student_item"
       :class="isTestStudentItem.isActive?'active':''"
       @click.stop="selectedItem(isTestStudentItem)">
    <!-- <div class="single_result_left student_photo"
         v-if="showImg">
      <heads-img :imgUrl="headImg"
                 :dataInfo="isTestStudentItem"></heads-img>
    </div> -->
    <div class="single_result_right student_info">
      <div class="student_info_name fl"
           :title="isTestStudentItem.MainDemoName">
        <span>{{isTestStudentItem.MainDemoName}}</span>
        <!-- 学生 -->
      </div>
      <div class="student_info_sex fl"
           :class="isTestStudentItem.SexKey==2?'boy':'gril'"
           v-if="isTestStudentItem.SexKey">

      </div>

      <div class="student_info_otherinfo fl"
           v-if="!isTestStudentItem.CurrentPeoCount">
        <span class="student_info_age">{{isTestStudentItem.AgeName||'--'}}岁</span>
        <!-- 家长关系 -->
        <span class="student_info_relation">{{isTestStudentItem.RelationKeyValue}}</span> <span class="student_info_phone">{{$utils.phoneModulePower(isTestStudentItem.MobileSupport)}}</span>
        <!-- 电话 -->
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'singleSelectionItem',
  data () {
    return {
      vTestStudentItem: ''
    };
  },
  props: ['isTestStudentItem'], // 单选数据项 需绑定到界面
  created () {
    this.vTestStudentItem = this.isTestStudentItem;
  },
  watch: {
    isTestStudentItem () {
      this.vTestStudentItem = this.isTestStudentItem;
    }
  },
  computed: {
    showImg () {
      if (!this.isTestStudentItem.SexKey && this.isTestStudentItem.SexKey == 'null') {
        return false;
      } else {
        return true;
      }
    },
    // 头像
    headImg () {
      if (this.isTestStudentItem.HeadImgSupport && this.isTestStudentItem.HeadImgSupport != 'null') {
        if (this.isTestStudentItem.HeadImgSupport.indexOf('http') > -1 && this.isTestStudentItem.HeadImgSupport.indexOf('KPIupload') > -1) {
          return this.isTestStudentItem.HeadImgSupport;
        } else {
          return this.$store.getters.PCWebURL + this.isTestStudentItem.HeadImgSupport; // 服务器的图片地址有时有问题，会返回404报错
        }
        // 默认显示头像
      } else {
        if (parseInt(this.isTestStudentItem.SexKey) === 2) {
          return require('../../../../../public/image/deflut_studentPhoto_boy.png');
        } else {
          return require('../../../../../public/image/deflut_studentPhoto_gril.png');
        }
      }
    }
  },
  methods: {
    selectedItem (item) {
      item.isActive = true;
      this.$emit('selectedItem', item);
    }
  }
};
</script>
<style scoped>
.single_student_item .student_info_name {
  width: 150px !important;
}
</style>

