<template>
  <div class="defult_dialog_searchform clearfix"
       :class="showSearchBtn?'':'defult_dialog_searchform_padding'">
    <div class="student_search_form">
      <input type="text"
             class="search_input"
             :placeholder="placeholder"
             @input="inputEvent($event)"
             @keydown="keydownEvent($event)"
             v-model="searchText">
      <span class="remove_btn"
            :style="showSearchBtn?'right:70px':'right:10px'"
            v-if="searchText != ''"
            @click.stop="clearSearText"></span>
    </div>
    <div @click.stop="searchEvent"
         v-if="showSearchBtn">
      <div class="btn_light_blue_search">搜索</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String, // 搜索文本
    placeholder: {// 水印提示
      type: String,
      default: ''
    },
    showSearchBtn: {   // 是否显示点击按钮. 若为True,  对外开放事件this.$emit('onSearchEvent');，若为false 类似清除文本
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      searchText: this.value, // 搜索文本
      lastClickTime: null
    };
  },
  created () {
  },
  methods: {
    // 回车
    keydownEvent (e) {
      if (e.keyCode == 13) {
        this.searchEvent();// 搜索函数
      }
    },
    inputEvent (e) {
      this.$emit('input', e.target.value);
    },
    // 搜索
    searchEvent () {
      if (this.checkCanClick()) {
        this.searchText = this.searchText.replace(/(^\s*)|(\s*$)/g, '');
        console.log('searchEvent', this.searchText);
        this.$emit('input', this.searchText);
        this.$emit('onSearchEvent', this.searchText);
      }
    },
    // 防止快速点击
    checkCanClick () {
      let now = Date.new();
      let r = !this.lastClickTime || now - this.lastClickTime > 500;
      if (r) {
        this.lastClickTime = now;
      }
      return r;
    },
    // 清除搜索文本
    clearSearText () {
      this.searchText = '';
      this.$emit('input', this.searchText);
      this.$emit('onSearchEvent',this.searchText,'isClear');
    }
  }
};
</script>

<style lang="sass" scoped>
.defult_dialog_searchform_padding
  padding: 16px 10px 10px
</style>
