<template>
  <div>
    <div class="general_summarizing_student">
      <div class="payment_statistics_bottom_select flex">
        <div class="table_select_box">
          <div class="table_select_title">报名类型</div>
          <el-select :value="vApplyTypeKeyValue"
                     filterable
                     style="width:240px"
                     placeholder="必选"
                     @change="changeApplyType"
                     value-key="OLAPKey">
            <el-option v-for="item in typeList"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item"></el-option>
          </el-select>
        </div>
        <div class="table_select_box">
          <div class="table_select_title">课程</div>
          <el-select :value="vCourseKeyValue"
                     filterable
                     placeholder="必选"
                     @change="subjectEvent"
                     value-key="OLAPKey">
            <el-option v-for="item in vCourseNameList"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item"></el-option>
          </el-select>
        </div>
        <div class="table_select_box">
          <div class="table_select_title">状态</div>
          <el-select :value="vStatusValue"
                     filterable
                     style="width:240px"
                     placeholder="必选"
                     @change="changeStatusType"
                     value-key="OLAPKey">
            <el-option v-for="item in statusList"
                       :key="item.OLAPKey"
                       :label="item.MainDemoName"
                       :value="item"></el-option>
          </el-select>
        </div>

      </div>
      <div class="course_statistics_table_search flex">
        <date-range ref="saveDate"
                    :searchObj="searchObj"
                    :fromTitle="'选择日期'"
                    :maxDate="$utils.formatDateToLine(Date.new())"
                    @changeDate="changeSaveDate"></date-range>
        <div class="course_statistics_search">
          <input-search-contain-btn v-model="searchObj.searchText"
                                    :placeholder="'请输入学生名、备注'"
                                    :clearAll="true"
                                    @clearSearch="clearSearchCondition"
                                    @onSearchEvent="searchData"></input-search-contain-btn>
          <div class="mendian_backstage_search">
            <div class="btn_hover_bg_blue search_btn "
                 style="margin: 0;"
                 @click.stop="doExportInfoClick">导出</div>
          </div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_box ">
      <list class="summarizing_list table_list_content"
            :key="tableKey"
            :tableData="vdataReportList"
            :totalNum="totalNum"
            :PageCount="PageCount"
            :tableColumns="tableCourseColumns"
            :isSearchText="false"
            :isNeedInnerScroll="false"
            :defaultSort="defaultSort"
            :queryParams="searchObj"
            :footerContent="vFooterTotal"
            @loadTableData="getDataList"></list>
    </div>
  </div>
</template>
<script>
import list from '../../../../common/table-list/index';
import applyAcountItem from './../apply-acount-item';
import inputSearchContainBtn from '../../../common/input-search-contain-btn';
import { MessageSearchInComePayReportForCourse } from '../../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        Status: 0,    // 状态0-全部;1-已用完;2-已过期
        StatusValue: '全部',
        ApplyTypeKey: 0,   // 报名类型1新生2续费3扩课
        ApplyTypeKeyValue: '全部',
        CourseKey: 0,
        CourseKeyValue: '全部',
        startTime: '',
        endTime: '',
        IsExportKey: 0
      },
      statusList: [
        {
          MainDemoName: '全部',
          OLAPKey: 0
        },
        {
          MainDemoName: '已用完',
          OLAPKey: 1
        },
        {
          MainDemoName: '已过期',
          OLAPKey: 2
        },
        {
          MainDemoName: '进行中',
          OLAPKey: 3
        },
        {
          MainDemoName: '已退',
          OLAPKey: 4
        }, {
          MainDemoName: '已转让',
          OLAPKey: 5
        }
      ],
      typeList: [
        {
          MainDemoName: '全部',
          OLAPKey: 0
        },
        {
          MainDemoName: '新报',
          OLAPKey: 1
        },
        {
          MainDemoName: '续费',
          OLAPKey: 2
        },
        {
          MainDemoName: '扩科',
          OLAPKey: 3
        }
      ],
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          decimalPlace: 0,
          className: 'table_index_gray'
        },
        {
          label: '发生日期',
          prop: 'GenerateTime',
          minWidth: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0

        },
        {
          label: '经办人',
          prop: 'OperatorKeyValue',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'text-item',
          decimalPlace: 0
        },
        {
          label: '学生',
          prop: 'StudentKeyValue',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: '',
          type: 'student',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '报名类型',
          prop: 'ApplyTypeKeyValue',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '购买课程',
          prop: 'ApplyCorName',
          minWidth: 126,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          eventName: '4'
        },
        {
          label: '购买课时',
          prop: 'NumCount',
          type: 'class-hour',
          width: 60,
          sortable: 'custom',
          columnClass: 'font_dual'
        },
        {
          label: '有效期',
          prop: 'ExpiryDate',
          minWidth: 126,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          eventName: '4'
        },
        {
          label: '购买金额',
          prop: 'ActualPayAmount',
          type: 'amount-item',
          decimalPlace: 2,
          width: 80,
          sortable: 'custom',
          columnClass: 'font_dual padding_right_30'
        },
        {
          label: '课时单价',
          prop: 'UnitPrice',
          type: 'amount-item',
          decimalPlace: 2,
          width: 80,
          sortable: 'custom',
          columnClass: 'font_dual padding_right_30'
        },
        {
          label: '消耗课时',
          prop: 'SpendCount',
          type: 'class-hour',
          width: 80,
          sortable: 'custom',
          columnClass: 'font_dual padding_right_30'
        },
        {
          label: '剩余课时',
          prop: 'CurrentCount',
          type: 'class-hour',
          width: 80,
          sortable: 'custom',
          columnClass: 'font_dual padding_right_30'
        },
        {
          label: '剩余天数',
          prop: 'PeriodCount',
          type: 'text-item',
          width: 80,
          sortable: 'custom',
          columnClass: 'font_dual padding_right_30'
        },
        {
          label: '剩余价值',
          prop: 'ValueAmount',
          type: 'amount-item',
          decimalPlace: 2,
          width: 80,
          sortable: 'custom',
          columnClass: 'font_dual padding_right_30'
        },
        {
          label: '状态',
          prop: 'StatusKeyValue',
          minWidth: 80,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item',
          decimalPlace: 0,
          eventName: '4',
          extend: {
            className: 'radio_type',
            setClassName: (rowData) => {
              let className = '';
              switch (Number(rowData.StatusKey)) {
                case 1:
                  className = 'type_red';
                  break;
                case 2:
                  className = 'type_red';
                  break;
                case 3:
                  className = 'type_blue';
                  break;
                default:
                  break;
              }
              return className;
            }
          }
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          fixed: 'right',
          width: 120,
          align: 'center',
          extend: {
            tableBtnGroup: [
              {
                name: '修改',
                extend: {
                  click: (rowData) => {
                    this.toChangeClassCount(rowData);
                  }
                },
                ModulePowerKey: 50
              }

            ]
          }
        }

      ],
      dataReportList: []
    };
  },
  components: {
    list,
    applyAcountItem,
    inputSearchContainBtn
  },
  props: {

  },
  created () {
    this.searchObj.startTime = this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd');
    this.searchObj.endTime = this.$utils.formatDateToLine(Date.new(Date.new().setDate((Date.new().getDate()))));
  },
  computed: {
    vCourseNameList () {
      let newArr = (this.$store.getters.courseNameList && this.$store.getters.courseNameList.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; })) || [];
      newArr.unshift({
        MainDemoName: '全部',
        OLAPKey: 0
      });
      return newArr;
    },
    vCourseKeyValue () {
      return this.searchObj.CourseKey == 0
        ? '全部'
        : this.searchObj.CourseKeyValue;
    },
    vApplyTypeKeyValue () {
      return this.searchObj.ApplyTypeKey == 0
        ? '全部'
        : this.searchObj.ApplyTypeKeyValue;
    },
    vStatusValue () {
      return this.searchObj.Status == 0
        ? '全部'
        : this.searchObj.StatusValue;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        // o.GenerateTime = o.GenerateTime.substring(5);
        if (o.UnitKey != 5) {
          o.CurrentCount = '-';
          o.SpendCount = '-';
          o.UnitPrice = '-';
          o.NumCount = '-';
        }
        o.ExpiryDate = o.ExpiryDate.replace('1900.01.01','不限');
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      return '共' + this.totalNum + '笔，合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
    }

  },
  mounted () {
    this.getDataList();
  },
  methods: {
    // 选中账户
    changeStatusType (obj) {
      console.log(obj, '选中班级');
      this.searchObj.StatusValue = obj.MainDemoName;
      this.searchObj.Status = obj.OLAPKey;
      this.searchData();
    },
    // 选中账户
    changeApplyType (obj) {
      console.log(obj, '选中班级');
      this.searchObj.ApplyTypeKeyValue = obj.MainDemoName;
      this.searchObj.ApplyTypeKey = obj.OLAPKey;
      this.searchData();
    },
    // 选中课程
    subjectEvent (obj) {
      console.log(obj, '选中课程');
      this.searchObj.CourseKeyValue = obj.MainDemoName;
      this.searchObj.CourseKey = obj.OLAPKey;
      this.searchData();
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.AccountTypeKey = '';
      this.searchObj.searchText = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.Status = 0;
      this.searchObj.ApplyTypeKey = 0;
      this.searchObj.CourseKey = 0;
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    searchData () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '课程销售明细',
          clist: [
            { title: '发生日期', cName: 'GenerateTime', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '经办人', cName: 'OperatorKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '报名类型', cName: 'ApplyTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '购买课程', cName: 'ApplyCorName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '购买课时', cName: 'NumCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '有效期', cName: 'ExpiryDate', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '购买金额', cName: 'ActualPayAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '课时单价', cName: 'UnitPrice', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '消耗课时', cName: 'SpendCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '剩余课时', cName: 'CurrentCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '剩余天数', cName: 'PeriodCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '剩余价值', cName: 'ValueAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '状态', cName: 'StatusKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchInComePayReportForCourse(searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      console.log(this.searchObj);
      MessageSearchInComePayReportForCourse(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchInComePayReportForKDSY');
      }).catch(error => {
        console.log('MessageSearchInComePayReportForKDSY', error.msg);
      });
    },
    toApplyReport (item) {
      this.$emit('toApplyReport', item, this.searchObj);
    },
    toOtherIncomeReport (item) {
      this.$emit('toOtherIncomeReport', item, this.searchObj);
    },
    toExpenditureReport (item) {
      this.$emit('toExpenditureReport', item, this.searchObj);
    },
    selectAccountType (item) {
      console.log(item, 'selectAccountType');
      this.searchObj.AccountTypeKey = item.OLAPKey;
      this.searchObj.AccountTypeKeyValue = item.MainDemoName;
      this.searchData();
    },
    // 到修改课单
    toChangeClassCount (item) {
      console.log('toApplyRecord', item);
      let studentInfo = {
        OLAPKey: item.StudentKey,
        MainDemoName: item.StudentKeyValue
      };
      // let courseitem = {

      // };
      this.$dialog.open(this, {
        name: '修改课单',
        routerName: this.$route.name,
        moduleName: 'changeCourseBill',
        data: { dataInfo: studentInfo, courseInfo: item },
        callBack: { updateCourseEvent: () => { this.searchData(); } }
      });
    }

  }
};
</script>