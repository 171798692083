<template>
  <div class="nav_list" :class="item.isActive?'opt':''" @click.stop="seletedItem">
    {{item.name}}
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  methods: {
    seletedItem () {
      this.$emit('seletedItem', this.item.type);
    }
  }
};
</script>
