<template>
  <!-- 音频 -->
  <div class="courseware_voice_content_box">
    <div class="courseware_voice_content"
         :class="{ open_type: audio.isPlaying }">
      <div class="voice_banner"></div>
      <div class="voice_player"></div>
      <div class="voice_btn"
           @click="playVoice"></div>
    </div>
    <div class="courseware_voice_progress">
      <div class="voice_left">{{ vCurrentTime }}</div>
      <div class="progress_banner button"
           @mousedown="touchstartProgress">
        <div class="progress_color button"
             :style="{ width: vProgress }"></div>
      </div>
      <div class="voice_right">{{ vRecorderDuration }}</div>
    </div>
    <div class="watermark">
      {{ watermark }}
    </div>
    <audio v-if="dataInfo.AttachViewUrl"
           ref="audio"
           controls
           preload="auto"
           name="media"
           class="audio audio-file"
           @error="onError"
           @ended="onEnded"
           @loadeddata="onLoadeddata"
           @timeupdate="handleAudioTimeUpdated"
           @canplay="onCanplay"
           id="audio-file">
      <source :src="vAudioUrl"
              type="audio/wav" />
    </audio>
  </div>
</template>
<script>
export default {
  data () {
    return {
      audioObj: null, // 音频对象
      playDurationTimeout: null, // 播放进度自增1
      audio: {
        playOpen: false,
        isPlaying: false, // 该字段是音频是否处于播放状态的属性
        currentTime: 0, // 音频当前播放时长
        recorderDuration: 0, // 音频最大播放时长
        minTime: 0,
        step: 0.1
      },
      isProgress: false
    };
  },
  props: {
    dataInfo: Object,
    watermark: String
  },
  computed: {
    vAudioUrl () {
      if (this.dataInfo.AttachViewUrl) {
        return this.dataInfo.AttachViewUrl;
      } else {
        return this.dataInfo.CoverUrl;
      }
    },
    vProgress () {
      if ((this.audio.currentTime / this.audio.recorderDuration * 100).toFixed(2) < 100) {
        return (this.audio.currentTime / this.audio.recorderDuration * 100).toFixed(2) + '%';
      } else {
        return 100;
      }
    },
    vRecorderDuration () {
      let min = '00';
      let sec = '00';
      if (this.audio.recorderDuration > 0) {
        min = Math.floor(this.audio.recorderDuration / 60);
        min = min < 10 ? '0' + min : min;
        sec = Math.floor(this.audio.recorderDuration % 60);
        sec = sec < 10 ? '0' + sec : sec;
      }
      let duration = min + ':' + sec;
      return duration;
    },
    vCurrentTime () {
      let min = '00';
      let sec = '00';
      min = Math.floor(this.audio.currentTime / 60);
      min = min < 10 ? '0' + min : min;
      sec = Math.floor(this.audio.currentTime % 60);
      sec = sec < 10 ? '0' + sec : sec;
      let duration = min + ':' + sec;
      return duration;
    },
    vCurPlayingAudio () {
      return this.$store.getters.curPlayingAudio;
    },
    vIsCloseAudioPlay () {
      return this.$store.getters.isCloseAudioPlay;
    }
  },
  watch: {
    vAudioUrl: {
      handler (newVal, oldVal) {
        this.isProgress = false;
        this.audio.isPlaying = false;
        if (this.audioObj) {
          this.audio.recorderDuration = this.dataInfo.DurationNum;
          this.audioPause();
          this.audioObj.load();
          this.audio.currentTime = 0;
          this.audioObj.currentTime = 0;
          if (this.dataInfo.currentTime && newVal == oldVal) {
            if (this.dataInfo.currentTime <= this.dataInfo.DurationNum) {
              this.audio.currentTime = this.dataInfo.currentTime;
              this.audioObj.currentTime = this.dataInfo.currentTime;
              this.audioPlay();
            }
          }
          this.audioObj.oncanplay = () => {
            if (this.audioObj.duration > 0) {
              this.recorderDuration = Math.floor(this.audioObj.duration);
            }
          };
        } else {
          this.audio.recorderDuration = this.dataInfo.DurationNum;
          this.audioObj = this.$refs.audio;
        }
      },
      immediate: true
    },
    vCurPlayingAudio (n, o) {
      // 同时打开多个音频 把前面打开的音频关闭
      if (n != this.vAudioUrl) {
        this.isProgress = false;
        this.audio.isPlaying = false;
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        this.audio.currentTime = 0;
      }
    },
    vIsCloseAudioPlay (n, o) {
      // 点击其他地方 关闭音频
      if (n) {
        this.isProgress = false;
        this.audio.isPlaying = false;
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        this.audio.currentTime = 0;
      }
    },
    $route (to, from) {
      // 路由变更 关闭音频
      this.isProgress = false;
      this.audio.isPlaying = false;
      this.audioObj.pause();
      this.audioObj.currentTime = 0;
      this.audio.currentTime = 0;
    }
  },
  mounted () {
    console.log(this.dataInfo, 'this.dataInfo');
    this.audio.recorderDuration = this.dataInfo.DurationNum;
    this.audioObj = this.$refs.audio;
    if (this.dataInfo.currentTime && this.dataInfo.currentTime <= this.dataInfo.DurationNum) {
      this.audio.currentTime = this.dataInfo.currentTime;
      this.audioObj.currentTime = this.dataInfo.currentTime;
      this.audioPlay();
    }
  },
  methods: {
    touchstartProgress (e) {
      if (this.audio.recorderDuration != 0 && !this.isError && this.audio.playOpen) {
        this.isProgress = true;
        let n = this.audio.recorderDuration * (e.offsetX / 882).toFixed(4);
        this.audio.currentTime = n;
        this.audioObj.currentTime = n;
      }
    },
    onLoadeddata (res) {
      this.audio.playOpen = true;
    },
    onError () {
      this.isError = true;
      layer.alert('音频下载出错');
    },
    onEnded () {
      this.isProgress = false;
      this.audioPause();
    },
    onCanplay () {
      if (this.audioObj && this.audioObj.recorderDuration > 0) {
        this.audio.recorderDuration = this.audioObj.recorderDuration;
      }
    },
    playVoice () {
      if (this.isError) {
        layer.alert('音频下载出错');
        return false;
      }
      if (!this.audio.playOpen) {
        layer.alert('请等待音频下载完成');
        return false;
      }
      if (!this.audio.isPlaying) {
        // 播放
        this.$store.commit('setCurPlayingAudio', this.vAudioUrl);
        this.audioPlay();
      } else {
        // 暂停
        this.audioPause();
      }
    },
    // 播放
    audioPlay () {
      this.audio.isPlaying = true;
      this.$refs.audio.play();
    },
    // 暂停
    audioPause () {
      this.audio.isPlaying = false;
      this.$refs.audio.pause();
    },
    handleAudioTimeUpdated (res) {
      // if (res.target.currentTime == 0 && this.isProgress) {
      //   layer.alert('音频未下载完成，无法指定播放时间');
      // }
      this.audio.currentTime = res.target.currentTime;
      this.dataInfo.currentTime = this.audio.currentTime;
    }
  }
};
</script>
<style scoped>
</style>