<template>
  <div class="student_list_popup"
       :style="{'left': vleft+'px','top': vBottom+'px'}"
       @mouseout="keepShow"
       @mouseleave="closeShow">
    <!--top -164 -->
    <div  style="min-height:90px">

    <student-lable v-if="showPopUp"
                   ref="studentLable"
                   :studentID="studentInfo.StudentKey"
                   :showExamine="false"
                   :showMenu="false"
                   @showAlterMessagePopup="showAlterMessagePopup"
                   @getDetailInfo="updateStudentComfireKey"
                   @closed="closeAlterMessagePopup"></student-lable>
    </div>
    <div class="student_list_popup_course">
      <span>适用课时</span>
      <span class="course_number">{{vCurrentCount}}</span>
    </div>
    <div class="student_list_popup_warning"
         v-if="abnormalStatus">
      {{vIsInGradeClass}}{{vIsInGradeClass&&vDebtClassCount?'、':''}}{{vDebtClassCount}}{{(vDebtClassCount&&vClassCount)?'、':''}}{{(vIsInGradeClass&&!vDebtClassCount&&vClassCount)?'、':''}}{{vClassCount}}
    </div>
  </div>
</template>
<script>
import studentLable from '../../../common/student-lable';
export default {
  data () {
    return {
      showPop: false
    };
  },
  props: {
    vleft: Number,
    vBottom: Number,
    studentInfo: Object,
    showPopUp: Boolean
  },
  components: {
    studentLable
  },
  created () {
    console.log(this.studentInfo, 'studentInfo');
  },
  computed: {
    // 适用课时显示
    vCurrentCount () {
      if (this.studentInfo.YearCardMaxDayCount > 0) {
        return '有';
      } else {
        return Number(this.studentInfo.CurrentCount);
      }
    },
    abnormalStatus () {
      if (this.studentInfo.IsInGradeClassKey == 0 || Number(this.studentInfo.DebtClassCount) > 0 || (this.studentInfo.CurrentCount <= 0 && this.studentInfo.YearCardMaxDayCount <= 0)) {
        return true;
      } else {
        return false;
      }
    },
    vIsInGradeClass () {
      return this.studentInfo.IsInGradeClassKey == 0 ? '未进班' : '';
    },
    vDebtClassCount () {
      return Number(this.studentInfo.DebtClassCount) > 0 ? '欠' + Number(this.studentInfo.DebtClassCount) + '课时' : '';
    },
    vClassCount () {
      return (Number(this.studentInfo.CurrentCount) <= 0 && Number(this.studentInfo.YearCardMaxDayCount) <= 0) ? '课时不足' : '';
    }

  },
  methods: {
    keepShow () {
      this.$emit('mouseOutEl');
    },
    closeShow () {
      console.log('出去');
      if (!this.showPop) {
        this.$emit('mouseOverEl');
      }
    },
    showAlterMessagePopup () {
      this.showPop = true;
      this.$emit('mouseOutEl');
    },
    closeAlterMessagePopup () {
      this.showPop = false;
      this.$nextTick(() => {
        this.$emit('mouseOverEl');
      });
    },
    updateStudentComfireKey (item) {
      console.log('updateStudentComfireKey', item.IsConfirmKey);
      this.$set(this.studentInfo, 'IsConfirmKey', item.IsConfirmKey);
    }
  }
};
</script>

