
  <template>
  <div
    class="form_item form-input"
    :class="{ form_info_required: required,form_info_line:formTitle }">
    <div :class="formPlaceholder==' '?'form_info_field_init':'form_info_field'" v-if="formTitle">
      <span>{{formTitle}}</span>
    </div>
    <div class="form_info_value form_info_select" v-if="!readonly">
      <el-select :value="dataItem" :placeholder="required?'必选':'可选'" @change="changeVal" 
               @click.native="clickEvent()"> 
        <el-option v-for="(item) in dataList" :key="item.key" :label="item.value" :value="item"></el-option>
      </el-select>
    </div>
    <!-- 只读模式 -->
    <div v-else class="form_info_value  input_readonly">{{dataItem}}</div>
  </div>
</template>
<script>
// 普通输入控件
// 支持双向绑定
// 支持change事件
export default {
  name: 'formSelect',
  data () {
    return {};
  },
  props: {
    formTitle: {
      type: String,
      required: true
    }, // 标题，必填
    dataItem: [String, Number, Boolean], // 值，双向绑定，如不想双向绑定，则自行实现input事件
    required: Boolean, // 是否必填，可选，如不提供，则为非必填，暂时没有加验证，后续可扩展验证
    dataList: Array, // 为数组.
    formPlaceholder: String, // 默认文本，可选，如不提供，则默认显示title
    readonly: Boolean
  },
  computed: {},
  created () {},
  watch: {},
  methods: {
    updateVal (val) {
      this.$emit('input', val);
    },
    changeVal (val) {
      this.$emit('choseItem', val);
    },
    clickEvent () {
      this.$store.commit('showAlerDialog', false);
    }
  }
};
</script>


