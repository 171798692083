<template>
  <div class="form_info_detail micro_message">
    <div class="micro_message_edit content_marginRL single_shadow">
      <div class="message_edit_input">
        <textarea
          cols="30"
          rows="10"
          maxlength="300"
          :placeholder="placeholder"
          v-model="text"
          @input="updateVal($event)"
          @change="changeVal($event)"
        ></textarea>
      </div>
    </div>
    <!-- 按钮组 -->
    <div class="footer_btn_group form_footer_btn_group">
      <el-button
        round
        class="btn_light_red btn_hover_white"
        @click.native="closeClick"
        >取消</el-button
      >
      <el-button
        round
        class="btn_light_blue btn_hover_bg_blue"
        @click.native="confirmClick"
        >确认</el-button
      >
    </div>
  </div>
</template>
<script>
import { EditIsLeaveByRuleId } from '../../../API/workbench';
export default {
  data() {
    return {
      text: "",
    };
  },
  props: {
    placeholder: String,
  },
  methods: {
    updateVal(e) {
      this.onkeyupEvent(e);
    },
    changeVal(e) {
      this.onkeyupEvent(e);
    },
    onkeyupEvent(e) {
        this.text = e.target.value;
    },
    confirmClick() {
      if(!this.text){
        layer.alert('内容不能为空')
        return false
      }
      this.$emit("onClickConfirm", this.text);
    },
    closeClick() {
      this.$emit("onClickClose");
    },
  },
};
</script>
<style scoped>
.micro_message_consignee {
  padding: 10px 36px;
}

.micro_message_field,
.micro_message_value {
  display: inline-block;
  vertical-align: top;
}

.message_edit_input > textarea {
  width: 390px !important;
  margin: 15px 10px;
  height: 184px;
  border: 1px solid #ececec;
  overflow-y: auto;
  font-family: 阿里巴巴普惠体, "Microsoft YaHei";
  /* background-color: #fafafa; */
  border-radius: 8px;
  word-break: break-word;
}

.message_edit_input ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/

.message_edit_input ::-webkit-scrollbar-track {
  background-color: #ebebeb;
}

/*定义滑块 内阴影+圆角*/

.message_edit_input ::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #cdd1d8;
}

.message_edit_input ::-webkit-scrollbar-thumb:hover {
  border-radius: 3px;
  opacity: 0.7;
}
</style>