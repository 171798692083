<template>
  <div>
    <!-- 搜索弹框 -->
    <div v-if="searchBoxShow">
      <searchBox :searchInitJson="newScheduleCourseInitJson"
                 @searchClick="chooseClick"
                 :classButton="classButtonShow"
                 @CloseDialog="cancleBtnClick"
                 @onSelectedStudent="selectedItemA"></searchBox>
    </div>
    <!-- 学生选择列表 -->
    <div v-else-if="studentListShow">
      <student-list :singleSelectionDatasource="studentList" 
                     :CustStatusKey="1"
                    @selectedItem="selectedItemA"></student-list>
    </div>
    <!-- 学生信息 -->
    <div v-else-if="isStudentChangeClassShow">
      <change-class-item @changeClass="showClassList"
                         @returnClass="loadClassList"
                         @changeStudent="changeStudent"
                         @alterStudent="selectedItemA"
                         :isSelectedStudentItem="isSelectedStudentItem"
                         :getStudentClassList="getStudentClassList"></change-class-item>
    </div>
    <custom-dialog title="选中班级"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isSelectedClassShow"
                   :before-close="cancleBtnClick">
      <classSelectBox class="content_marginTop_30 content_marginRL"
                      :classItem="selectedClassitem"
                      @isChangeClass="isChangeClass"></classSelectBox>

      <!-- 按钮组 提交-->
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="ClickSubmitEvent"
                             @cancelClick="cancleBtnClick"></save-cancel-btn-group>
    </custom-dialog>
  </div>
</template>
<script>
import searchBox from '../../../../components/common/search-box';
import studentList from '../../../../components/common/student-list';
import changeClassItem from './change-class-item';
import classSelectBox from './class-select-box';
import {
  getStudentHavingClass,
  studentAddClass,
  QuitStudentFromClass
} from '../../../../API/workbench.js';
// 学生换班
export default {
  name: 'studentChangeClass',
  data () {
    return {
      // 学生选择
      studentListShow: false,
      // 选中班级项
      isSelectedClassShow: false,
      // 选择学生信息
      isStudentChangeClassShow: false,
      //  搜索目标
      newScheduleCourseInitJson: { name: '目标学生' },
      searchBoxShow: true, // 搜索框
      // 学生列表弹框
      studentList: [],
      isSelectedStudentItem: {}, // 选中学生
      studentChangeClass: {}, // 学生换班
      selectedClassitem: {}, // 选中班级
      classButtonShow: false, // 按钮显示
      getStudentClassList: [] // 获取学生的班级信息
    };
  },
  props: ['dataInfo'],
  components: {
    searchBox, // 搜索学生
    studentList, // 学生选择列表
    changeClassItem, // 学生个人信息
    classSelectBox
  },
  created () {
    console.log(this.dataInfo, '换班');
    if (this.dataInfo && Number(this.dataInfo.ClassItem.OLAPKey) > 0 && Number(this.dataInfo.StudentItem.OLAPKey) > 0) {
      this.searchBoxShow = false;
      this.selectedClassitem = this.dataInfo.ClassItem;
      this.selectedItemA(this.dataInfo.StudentItem);
    }
  },
  mounted () {
    this.$dialog.register(this, ['gradeList']);
  },
  methods: {
    // 点击搜索 学生选择
    chooseClick () {
      this.searchBoxShow = false;
      this.studentListShow = true;
    },
    // 班级列表
    showClassList (oldClass) {
      this.$dialog.open(this, {
        moduleName: 'gradeList',
        name: '班级列表',
        routerName: this.$route.name,
        routertitle: '',
        callBack: { selectedItem: (newClass) => {
          this.selectedClass(oldClass, newClass);
        } }
      });
    },
    // 开始换班
    selectedClass (oldClass, newClass) {
      this.studentChangeClass = oldClass;
      this.selectedClassitem = newClass;
      this.isSelectedClassShow = true;
    },
    // 学生选择
    selectedItemA (item) {
      console.log(item, '选择学生');
      this.isSelectedStudentItem = item;
      this.studentListShow = false;
      this.searchBoxShow = false;
      this.isStudentChangeClassShow = true;
      this.loadClassList();
    },
    // 切换学生
    changeStudent () {
      this.studentListShow = true;
      this.isStudentChangeClassShow = false;
    },
    // 切换班级
    isChangeClass () {
      this.showClassList(this.selectedClassitem);
      // this.isSelectedClassShow = false;
    },
    // 刷新班级列表
    loadClassList () {
      getStudentHavingClass(this.isSelectedStudentItem.OLAPKey).then(result => {
        this.getStudentClassList = result.data;
      }).catch(error => {
        console.log(error);
      });
    },
    // 确定换班按钮
    ClickSubmitEvent (callBack) {
      if (this.selectedClassitem.OLAPKey !== this.studentChangeClass.OLAPKey) {
        QuitStudentFromClass(
          this.isSelectedStudentItem.OLAPKey,
          this.studentChangeClass.OLAPKey).then(result => {
            // 移除班级
            studentAddClass(
              this.isSelectedStudentItem.OLAPKey,
              this.selectedClassitem.OLAPKey).then(result => {
                // 添加班级
                getStudentHavingClass(this.isSelectedStudentItem.OLAPKey).then(result => {
                  this.getStudentClassList = result.data;
                  this.isStudentChangeClassShow = true;
                  this.isSelectedClassShow = false;
                  if (result.code == 0) {
                    this.$bus.emit('updateCourse', this.studentChangeClass);
                    layer.alert('换班成功');
                  }
                }, r => {
                  layer.alert(r.msg);
                }).finally(() => {
                  if (callBack) {
                    callBack();
                  }
                });
              }, r => {
                layer.alert(r.msg);
                if (callBack) {
                  callBack();
                }
              });
          }, r => {
            layer.alert(r.msg);
            if (callBack) {
              callBack();
            }
          });
      } else {
        if (callBack) {
          callBack();
        }
        layer.alert('该班级已有该学生');
      }
    },
    // 取消换班按钮
    cancleBtnClick () {
      this.isSelectedClassShow = false;
      this.searchBoxShow = false;
      this.isStudentChangeClassShow = true;
    }
  }
};
</script>

