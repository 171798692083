<template>
  <!-- 循环课详情 -->
  <div>
    <course-info :circleCourseInfo="circleCourseDetail"
                 :isShowCouserInfoLableBtn="true"
                 class="button"
                 @clickCircleCourse="clickCircleCourse"></course-info>
    <div class="loop_couser_info_ul">
      <detail-item v-for="(item,index) in circleCourseDetail.SubRule"
                   :key="index"
                   class="button"
                   :courseInfo="item"
                   :IsNotEndKeyAndLast="circleCourseDetail.IsNotEndKey==1&&(index+1)==circleCourseDetail.SubRule.length"
                   @clickOneRuleItem="afterClickSubRuleItem"></detail-item>
    </div>
    <!-- 点击学生 展开更多操作 -->
    <div class="course_student_attendance_operation module_content_menuList"
         :style="{'top':topValue+'px','left':'315px','position': 'absolute'}"
         v-show="isShowPopUpForSubRule||this.isShowPopUpForCircleCourse">
      <div class="top_bg"></div>
      <drop-down-box :optionJsonList="vPopUpMenu"
                     @clickOptionForAlertDialog="doAfterSelectItemClick"></drop-down-box>
    </div> <!-- 调整上课时间 -->
    <custom-dialog title="调整上课时间"
                   class="popup_dialog_height_from defult_dialog"
                   :visible.sync="isShowEditClassTimeDialog"
                   :before-close="closeEditClassTimeDialog">
      <edit-class-time :circleCourseInfo="circleCourseData"
                       @closeDialog="closeEditClassTimeDialog">

      </edit-class-time>
    </custom-dialog>
    <!-- 调整上课时间 -->
    <custom-dialog title="调整老师"
                   class="popup_dialog_height_from defult_dialog"
                   :visible.sync="isShowEditTeacherDialog"
                   :before-close="closeEditTeacherDialog">
      <edit-teacher :circleCourseInfo="circleCourseData"
                    @closeDilog="closeEditTeacherDialog">

      </edit-teacher>
    </custom-dialog>
    <custom-dialog title="修改教室"
                   class="popup_dialog_height_from defult_dialog"
                   :visible.sync="isShowClassRoomDialog"
                   :before-close="closeClassRoomDialog">
      <edit-class-room :circleCourseInfo="circleCourseData"
                       @closeDilog="closeClassRoomDialog"></edit-class-room>
    </custom-dialog>
    <custom-dialog title="修改课消"
                   class="popup_dialog_height_from defult_dialog"
                   :visible.sync="isShowPeriodCountDialog"
                   :before-close="closePeriodCountDilog">
      <edit-period-count :circleCourseInfo="circleCourseData"
                         @closeDilog="closePeriodCountDilog"></edit-period-count>
    </custom-dialog>
    <custom-dialog title="修改计划满员数"
                   class="popup_dialog_height_from defult_dialog"
                   :visible.sync="isShowFullPeopleCountDialog"
                   :before-close="closeFullPeopleCountDilog">
      <edit-full-people-count :circleCourseInfo="circleCourseData"
                              @closeDilog="closeFullPeopleCountDilog"></edit-full-people-count>
    </custom-dialog>
    <custom-dialog title="修改旷课扣课时"
                   class="defult_dialog popup_dialog_height_from"
                   :visible.sync="isShowIsChargesKeyDialog"
                   :before-close="closeIsChargesKeyDilog">
      <edit-charge-truant :circleCourseInfo="circleCourseData"
                          @closeDilog="closeIsChargesKeyDilog"></edit-charge-truant>
    </custom-dialog>
    <custom-dialog title="修改请假扣课时"
                   class="defult_dialog popup_dialog_height_from"
                   :visible.sync="isShowIsleaveKeyDialog"
                   :before-close="closeIsleaveKeyDilog">
      <edit-isleave-key :circleCourseInfo="circleCourseData"
                        @closeDilog="closeIsleaveKeyDilog"></edit-isleave-key>
    </custom-dialog>
     <custom-dialog title="修改亏损预警人数"
                   class="popup_dialog_height_from defult_dialog"
                   :visible.sync="isShowWarnLossCountDialog"
                   :before-close="closeWarnLossCountDilog">
      <edit-warnLoss-count :circleCourseInfo="circleCourseData"
                         @closeDilog="closeWarnLossCountDilog"></edit-warnLoss-count>
    </custom-dialog>
  </div>
</template>

<script>
import { GetStructedRuleDetail, AbortStructuredRule, StopNotEndStructuredRule } from '../../../API/workbench.js';
import courseInfo from '../course-info';
import dropDownBox from '../../common/drop-down-box';
import editClassTime from '../edit-circle-course-table/edit-class-time';
import editTeacher from '../edit-circle-course-table/edit-teacher';
import detailItem from './detail-item';
import editClassRoom from '../edit-circle-course-table/edit-class-room';
import editPeriodCount from '../edit-circle-course-table/edit-period-count';
import editFullPeopleCount from '../edit-circle-course-table/edit-full-people-count';
import editChargeTruant from '../edit-circle-course-table/edit-charge-truant';
import editIsleaveKey from '../edit-circle-course-table/edit-isleave-key';
import editWarnLossCount from '../edit-circle-course-table/edit-warnLoss-count';
export default {
  data () {
    return {
      circleCourseDetail: {},
      topValue: 0,
      popUpType: 0,
      isShowPopUpForSubRule: false,
      childPopupsMenu: [
        { name: '调整上课时间', eventName: 'changeClassTime', isShow: true, ModulePowerKey: 47 },
        { name: '调整老师', eventName: 'changeTeacher', isShow: true, ModulePowerKey: 47 },
        { name: '修改教室', eventName: 'changeClassRoom', isShow: true, ModulePowerKey: 47 },
        { name: ' 修改课消', eventName: 'changeSpendHourse', isShow: true, ModulePowerKey: 47 },
        { name: ' 修改计划满员数', eventName: 'changeFullPeopleNum', isShow: true, ModulePowerKey: 47 },
        { name: ' 修改请假扣课时', eventName: 'changeChargeLeave', isShow: true, ModulePowerKey: 47 },
        { name: ' 修改旷课扣课时', eventName: 'changeChargeTruant', isShow: true, ModulePowerKey: 47 },
        { name: ' 修改亏损预警人数', eventName: 'changeWarnLossCount', isShow: true, ModulePowerKey: 47 },
        { name: '修改课表', eventName: 'editCircleCourseTable', isShow: true, ModulePowerKey: 47 },
        { name: '续建课表', eventName: 'continueNewCircleCourse', isShow: true, ModulePowerKey: 47 },
        { name: '结束循环课', eventName: 'endCircleCourse', isShow: true, ModulePowerKey: 47 }
      ],
      popupsMenu: [
        { name: '修改教室', eventName: 'changeClassRoom', isShow: true, ModulePowerKey: 47 },
        { name: ' 修改课消', eventName: 'changeSpendHourse', isShow: true, ModulePowerKey: 47 },
        { name: ' 修改计划满员数', eventName: 'changeFullPeopleNum', isShow: true, ModulePowerKey: 47 },
        { name: ' 修改请假扣课时', eventName: 'changeChargeLeave', isShow: true, ModulePowerKey: 47 },
        { name: ' 修改旷课扣课时', eventName: 'changeChargeTruant', isShow: true, ModulePowerKey: 47 },
        { name: '修改课表', eventName: 'editCircleCourseTable', isShow: true, ModulePowerKey: 47 },
        { name: '续建课表', eventName: 'continueNewCircleCourse', isShow: true, ModulePowerKey: 47 },
        { name: '结束循环课', eventName: 'endCircleCourse', isShow: true, ModulePowerKey: 47 }
      ],
      circleCourseData: {}, // 循环课数据
      isShowPopUpForCircleCourse: false,
      isShowEditClassTimeDialog: false, // 调整上课时间
      isShowEditTeacherDialog: false, // 调整老师
      isShowClassRoomDialog: false, // 修改教室
      isShowPeriodCountDialog: false, // 修改课消
      isShowFullPeopleCountDialog: false, // 修改计划满员数
      isShowIsChargesKeyDialog: false, // 修改旷课扣课时
      isShowIsleaveKeyDialog: false, // 修改请假扣课时
      isShowWarnLossCountDialog: false, // 修改亏损预警人数
      curSelectedSubRuleInfo: null, // 当前选中子循环项
      curRuleID: 0
    };
  },
  props: {
    ruleID: {
      type: [String, Number]
    }
  },
  components: {
    detailItem,
    courseInfo,
    dropDownBox,
    editClassTime,
    editTeacher,
    editClassRoom,
    editPeriodCount,
    editFullPeopleCount,
    editChargeTruant,
    editIsleaveKey,
    editWarnLossCount
  },
  computed: {
    vBeginTime () {
      let beginTime = '';
      if (this.circleCourseDetail.ClassTimeName) {
        beginTime = this.circleCourseDetail.ClassTimeName.split('-')[0];
      }
      return beginTime;
    },
    vPopUpMenu () {
      let popUpList;
      if (this.popUpType == 0) {
        popUpList = this.$utils.parseJson(this.popupsMenu);

        if (this.circleCourseDetail.IsNotEndKey == 1) { // 不结束
          let continueItem = popUpList.find(obj => { return obj.eventName == 'continueNewCircleCourse'; });
          if (continueItem) {
            continueItem.name = '停止续课';
            continueItem.eventName = 'stopContinueNewCircleCourse';
          }
        } else {
          let stopItem = popUpList.find(obj => { return obj.eventName == 'stopContinueNewCircleCourse'; });
          if (stopItem) {
            stopItem.name = '续建课表';
            stopItem.eventName = 'continueNewCircleCourse';
          }
        }
      } else {
        popUpList = this.$utils.parseJson(this.childPopupsMenu);
      }

      for (var key in popUpList) {
        const obj = popUpList[key];
        if (this.isShowPopUpForCircleCourse && !this.isShowPopUpForSubRule) {
          if (obj.eventName == 'changeSpendHourse' || obj.eventName == 'changeClassRoom' ||
            obj.eventName == 'changeFullPeopleNum' || obj.eventName == 'changeChargeLeave' || obj.eventName == 'changeChargeTruant') {
            obj.isShow = false;
          } else {
            obj.isShow = true;
          }
        } else {
          if ((obj.eventName == 'changeChargeLeave' && !this.vIsShowChargeLeave) || (obj.eventName == 'changeChargeTruant' && !this.vIsShowChargeTruant) ||
            obj.eventName == 'editCircleCourseTable' || obj.eventName == 'continueNewCircleCourse') {
            obj.isShow = false;
          } else {
            obj.isShow = true;
          }
        }
      }
      return popUpList;
    },
    // 2不扣课时 3扣课时 4建课设置
    vIsShowChargeTruant () {
      return this.$utils.getSaaSClientSet(33) == 4 && this.$utils.getSaaSClientSet(59) > 0;
    },
    //  2不扣课时 3扣课时 4建课设置
    vIsShowChargeLeave () {
      return this.$utils.getSaaSClientSet(32) == 4;
    }
  },
  watch: {
    ruleID: {
      handler (n, o) {
        if (n) {
          this.curRuleID = n;
          this.getStructedRuleDetail();
        }
      },
      deep: true
    }
  },
  created () {
    this.curRuleID = this.ruleID;
    this.getStructedRuleDetail();
  },
  mounted () {
    // 点击别处时弹框自动收回
    document.querySelector('body').addEventListener('click', e => {
      this.isShowPopUpForCircleCourse = false;
      this.isShowPopUpForSubRule = false;
      if (this.curSelectedSubRuleInfo) {
        this.$set(this.curSelectedSubRuleInfo, 'isActive', false);
      }
    }, false);
    this.$bus.on(this, 'AfterNewCourseTable', (type, params) => {
      this.getStructedRuleDetail();
    });
    this.$bus.on(this, 'AfterEditCircleCourse', (ruleID) => {
      if (ruleID) {
        this.curRuleID = ruleID;
      }
      this.getStructedRuleDetail();
    });
  },
  methods: {
    // 排课规律->排课规律详情
    getStructedRuleDetail () {
      if (this.curRuleID) {
        GetStructedRuleDetail(this.curRuleID).then(result => {
          this.circleCourseDetail = result.data;
          this.circleCourseDetail.isShowCouserInfoLableBtn = true;
        });
      }
    },
    // 点击循环课块
    clickCircleCourse (e) {
      this.popUpType = 0;
      if (this.curSelectedSubRuleInfo) {
        this.curSelectedSubRuleInfo.isActive = false;
      }
      this.curSelectedSubRuleInfo = null;
      let offsetTop = e.currentTarget.offsetTop;
      this.topValue = offsetTop + 30;
      this.isShowPopUpForCircleCourse = true;
      this.isShowPopUpForSubRule = false;
      console.log('点击循环课块');
    },
    // 点击 子循环课块
    afterClickSubRuleItem (courseInfo, e) {
      this.popUpType = 1;
      if (this.curSelectedSubRuleInfo) {
        this.$set(this.curSelectedSubRuleInfo, 'isActive', false);
      }
      this.$set(courseInfo, 'isActive', true);
      this.curSelectedSubRuleInfo = courseInfo;
      let offsetTop = e.currentTarget.offsetTop;
      this.topValue = offsetTop + 40;
      this.isShowPopUpForSubRule = true;
      this.isShowPopUpForCircleCourse = false;
    },
    // 滚动后触发获取弹窗位置
    doAfterEventScrollTOP (e) {
      this.isShowPopUpForSubRule = false;
    },
    // 弹出 菜单 操作项
    doAfterSelectItemClick (menu) {
      if (this.curSelectedSubRuleInfo) {
        this.curSelectedSubRuleInfo.isActive = false;
        this.isShowPopUpForSubRule = false;
      } else {
        this.isShowPopUpForCircleCourse = false;
      }
      this.circleCourseData = this.$utils.parseJson(this.circleCourseDetail);
      this.circleCourseData.SubRule = this.curSelectedSubRuleInfo;
      switch (menu.eventName) {
        case 'continueNewCircleCourse': // 续建课表
          let item = {
            routerName: this.$route.name,
            routertitle: this.$route.meta.title,
            moduleName: 'continueNewCircleCourseTable',
            data: { ruleID: this.curRuleID }
          };
          this.$bus.emit('needOpenRouter', item);
          break;
        case 'stopContinueNewCircleCourse': // 停止续课
          layer.confirm('请确认是否停止续课?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  this.stopNotEndStructuredRule();
                  layer.close();
                }
              }
            ]
          });
          break;
        case 'editCircleCourseTable': // 修改课表
          this.$bus.emit('needOpenRouter', { routerName: this.$route.name, routertitle: this.$route.meta.title, moduleName: 'editCircleCourseTable', data: { circleCourseInfo: this.circleCourseData } });
          break;
        case 'changeClassTime': // 调整上课时间
          this.isShowEditClassTimeDialog = true;
          break;
        case 'changeTeacher': // 调整老师
          this.isShowEditTeacherDialog = true;
          break;
        case 'endCircleCourse':// 结束循环课
          layer.confirm('请确认是否结束循环课?', {
            btn: [
              {
                name: '确认',
                callBack: () => {
                  let IsProgressCourse = 0;// 是否终止进行中的课 0-否;1-是
                  this.abortStructuredRule(IsProgressCourse, 1);
                  layer.close();
                }
              }
            ]
          });
          break;
        case 'changeClassRoom': // 修改教室
          this.isShowClassRoomDialog = true;
          break;
        case 'changeFullPeopleNum': // 修改计划满员数
          this.isShowFullPeopleCountDialog = true;
          break;
        case 'changeChargeLeave': // 修改请假扣课时
          this.isShowIsleaveKeyDialog = true;
          break;
        case 'changeChargeTruant': // 修改旷课扣课时
          this.isShowIsChargesKeyDialog = true;
          break;
        case 'changeSpendHourse': // 修改课消
          this.isShowPeriodCountDialog = true;
          break;
        case 'changeWarnLossCount': // 修改亏损预警人数
          this.isShowWarnLossCountDialog = true;
          break;
        default:
          break;
      }
    },
    // 停止续课
    stopNotEndStructuredRule () {
      StopNotEndStructuredRule(this.circleCourseDetail.StructuredRuleKey).then(result => {
        layer.alert('停止续课成功');
        this.circleCourseDetail.IsNotEndKey = 0;
        this.circleCourseDetail.IsNotEndKeyValue = '否';
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 结束循环课
    abortStructuredRule (IsProgressCourse, IsNotEndKey) {
      if(this.circleCourseDetail.StructuredRuleKey>0){

        AbortStructuredRule(this.circleCourseDetail.StructuredRuleKey, IsProgressCourse, this.circleCourseData.SubRule ? this.curSelectedSubRuleInfo.StartTime : this.circleCourseData.CurrentRuleTime,
          this.circleCourseData.SubRule ? this.curSelectedSubRuleInfo.EndTime : this.circleCourseData.LastClassTime, IsNotEndKey).then(result => {
            if (this.circleCourseData.SubRule && this.circleCourseDetail.SubRule.length > 1) {
              this.reLoadData(this.circleCourseDetail.StructuredRuleKey);
            } else {
              this.$emit('closeDialog');
              this.$bus.emit('AfterAbortCircleCorse', this.circleCourseDetail.StructuredRuleKey, this.circleCourseDetail);
            }
          }, error => {
            if (error.code == 10000) {
              layer.alert(error.msg);
            } else if (error.code == 60104) {
              layer.confirm('有进行中的课，是否继续结束循环课?', {
                btn: [
                  {
                    name: '确认',
                    callBack: () => {
                      let IsProgressCourse = 1;// 是否终止进行中的课 0-否;1-是
                      this.abortStructuredRule(IsProgressCourse, IsNotEndKey);
                      layer.close();
                    }
                  }
                ]
              });
            } else if (error.code == 70000) {
              layer.confirm(error.msg, {
                btn: [
                  {
                    name: '确认',
                    callBack: () => {
                      this.abortStructuredRule(IsProgressCourse, 0);
                      layer.close();
                    }
                  }
                ]
              });
            } else {
              layer.alert(error.msg);
            }
          });
      }
    },
    // 关闭 调整上课时间
    closeEditClassTimeDialog (ruleID) {
      if (ruleID) {
        this.curRuleID = this.ruleID;
        this.$emit('afterEditClassTime', ruleID);
        this.$bus.emit('AfterEditCircleCourse', ruleID, this.circleCourseDetail);
      }
      this.isShowEditClassTimeDialog = false;
    },
    // 关闭 调整老师
    closeEditTeacherDialog (ruleID) {
      this.reLoadData(ruleID);
      this.isShowEditTeacherDialog = false;
    },
    // 关闭 修改教室
    closeClassRoomDialog (ruleID) {
      this.reLoadData(ruleID);
      this.isShowClassRoomDialog = false;
    },
    // 关闭 修改课消
    closePeriodCountDilog (ruleID) {
      this.reLoadData(ruleID);
      this.isShowPeriodCountDialog = false;
    },
    // 关闭 修改计划满员数
    closeFullPeopleCountDilog (ruleID) {
      this.reLoadData(ruleID);
      this.isShowFullPeopleCountDialog = false;
    },
    // 关闭 修改旷课扣课时
    closeIsChargesKeyDilog (ruleID) {
      this.reLoadData(ruleID);
      this.isShowIsChargesKeyDialog = false;
    },
    // 关闭 修改请假扣课时
    closeIsleaveKeyDilog (ruleID) {
      this.reLoadData(ruleID);
      this.isShowIsleaveKeyDialog = false;
    },
    // 关闭 修改亏损预警人数
    closeWarnLossCountDilog (ruleID) {
      this.reLoadData(ruleID);
      this.isShowWarnLossCountDialog = false;
    },
    // 重新刷新数据
    reLoadData (ruleID) {
      if (ruleID) {
        this.getStructedRuleDetail();
        this.$bus.emit('AfterEditCircleCourse', ruleID, this.circleCourseDetail);
      }
    }
  }
};
</script>

<style>
</style>