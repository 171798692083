<template>
  <div :class="{opt:isFocus,'import_list':rowType=='add','table_td':rowType!='add' }"
       style="width:126px">
    <input :class="{text_error:vCustomerPhoneNameException}"
           v-model="phoneName"
           v-if="vIsEdit"
           type="text"
           ref="customerPhoneName"
           :placeholder="rowType=='add'?'手机号':''"
           @keydown="keydownEvent($event)"
           @blur="blurEvent"
           @focus="focusEvent($event)"
           @input="inputEvent($event.target.value,$event)"
           onkeyup="this.value=this.value.replace(/[^0-9-]/g,'');">
  </div>
</template>

<script>
import { VerifyOldStudent } from '../../../../../API/workbench';
export default {
  data () {
    return {
      phoneName: '',
      isFocus: false
    };
  },
  props: {
    rowType: {
      type: String,
      default: ''
    }, // 输出类型
    rowData: Object,
    tableData: Array
  },
  computed: {
    vCustomerPhoneNameException () {
      let flag = false;
      let errorItem = this.rowData.ExceptionList.find(obj => { return obj.TargetField == 3; });
      if (errorItem) {
        flag = true;
      }
      return flag;
    },
    vIsEdit () {
      let flag = false;
      if (this.rowData.guid || this.rowType == 'add') {
        flag = true;
      }
      return flag;
    }
  },
  watch: {
    'rowData.CustomerPhoneName': {
      handler (n, o) {
        this.phoneName = n;
      }
    },
    rowData: {
      handler (n, o) {
        if (n.focusField == 'CustomerPhoneName' && n.optioningRow && this.$refs.customerPhoneName) {
          this.rowData.focusField = 'CustomerPhoneName';
          this.$refs.customerPhoneName.focus();
          // 光标定位到文本框字符串末尾
          var tObj = this.$refs.customerPhoneName;
          if (tObj.value) {
            var sPos = tObj.value.length;
            this.$utils.setCaretPosition(tObj, sPos);
          }
        }
      },
      deep: true // 表示开启深度监听
    }
  },
  created () {
    this.phoneName = this.rowData.CustomerPhoneName;
  },
  methods: {
    // 鼠标聚焦
    focusEvent (e) {
      this.isFocus = true;
      this.rowData.optioningRow = true;
      this.rowData.focusField = 'CustomerPhoneName';
      this.$emit('onFocusEvent', 'CustomerPhoneName');
    },
    // 键盘事件
    keydownEvent (e) {
      switch (e.keyCode) {
        case 9: // Tab
          this.rowData.focusField = 'ApplyClassKeyValue';
          e.preventDefault();// 防止跳两格
          break;
        case 13: // 回车
          this.rowData.focusField = 'ApplyClassKeyValue';
          break;
        case 37: // 左键
          setTimeout(() => {
            this.rowData.focusField = 'SexKeyValue';
          }, 50);
          break;
        case 38: // 向上键
          if (Number(this.rowData.Number) > 1) {
            this.$emit('doUpwardMove', this.rowData, 'CustomerPhoneName');
          }
          break;
        case 39: // 右键
          setTimeout(() => {
            this.rowData.focusField = 'ApplyClassKeyValue';
          }, 50);
          break;
        case 40: // 向下键
          this.$emit('doDownwardMove', this.rowData, 'CustomerPhoneName');
          break;
        default:

          break;
      }
    },
    inputEvent (val, e) {
      if (val.toString().length > 11) {
        e.target.value = val.toString().substr(0, 11);
        this.phoneName = e.target.value;
      }
    },
    // 失去焦点事件
    blurEvent () {
      this.isFocus = false;
      this.rowData.focusField = '';
      this.phoneName = this.phoneName ? this.phoneName.trim() : this.phoneName;
      if (this.phoneName && isNaN(this.phoneName)) {
        this.phoneName = this.phoneName.replace(/[^0-9]/ig, '');// 校验数字类型（字符串提取数字）
      }
      if (this.rowData.CustomerPhoneName != this.phoneName) {
        this.rowData.updateTime = Date.new();
        this.rowData.CustomerPhoneName = this.phoneName;
        let flag = this.verificationCustomerPhoneName();
        // 手机号码错误就无须验证了
        if (flag) {
          return flag;
        }
        this.rowData.isValifiStudent = true;
        console.log(this.rowData, '手机号码');
        // TargetField： 对应列表中的九个字段，1代表验证学生，手机号这里也需验证学生 ;TypeKey:验证该字段下存在的异常类型
        let hasExistsIndex = this.rowData.WarningList.findIndex(obj => { return obj.TargetField == 1; });
        let hasExistsIndex2 = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 1; });
        VerifyOldStudent(this.rowData.StudentKeyValue, this.rowData.CustomerPhoneName).then(result => {
          result.data = result.data || {};

          if (hasExistsIndex >= 0) {
            this.rowData.WarningList.splice(hasExistsIndex, 1);
          }
          if (hasExistsIndex2 >= 0) {
            this.rowData.ExceptionList.splice(hasExistsIndex2, 1);
          }
          // 异常
          if (result.data.excption) {
            this.rowData.ExceptionList.push(result.data.excption);
          }
          // 警告
          if (result.data.warning) {
            this.rowData.WarningList.push(result.data.warning);
          }
          this.rowData.StudentKey = result.data.StudentKey;
          this.rowData.isValifiStudent = false;
        },
          error => {
            this.rowData.isValifiStudent = false;
            layer.alert(error.msg);
          });
      }
      this.$emit('onBlurEvent', this.rowData, 'CustomerPhoneName');
    },
    // 验证手机号码
    verificationCustomerPhoneName () {
      let flag = false;
      let phoneErrorIndex = this.rowData.ExceptionList.findIndex(obj => { return obj.TargetField == 3 && obj.TypeKey == 1; });
      if (this.rowData.CustomerPhoneName && this.rowData.CustomerPhoneName.length != 11) {
        if (phoneErrorIndex < 0) {
          this.rowData.ExceptionList.push({ TargetField: 3, TypeKey: 1, TypeKeyValue: '手机号格式错误' });
          this.rowData.isShowCorrect = false;
        }
        flag = true;
      } else {
        if (phoneErrorIndex >= 0) {
          this.rowData.ExceptionList.splice(phoneErrorIndex, 1);
        }
      }
      return flag;
    }
  }
};
</script>

<style>
</style>