<!-- 学生 -->
<template>
  <el-table-column :fixed="columnData.fixed||false"
                   :label="columnData.label"
                   :prop="columnData.prop"
                   :width="columnData.width||100"
                   :min-width="columnData.minWidth"
                   :sortable="columnData.sortable"
                   :align="columnData.align||'left'"
                   :sort-orders="['ascending', 'descending']"
                   :label-class-name="columnData.columnClass"
                   :render-header="columnData.renderHeader">
    <template slot-scope="scope">
      <div class="button pr"
           @click="clickEvent(scope.row)">
        <div class="student_view_title_next"
             :class="{padding_r:columnData.extend.showWechat&&!scope.row.isEmpertyRow}">
          {{scope.row[columnData.prop]}}
          <span v-if="columnData.extend.showWechat&&!scope.row.isEmpertyRow"
                class=""
                :class="[typeof(columnData.extend.setClassName)=='function'&&columnData.extend.setClassName(scope.row)]"
                style="display: inline-block;">
            <!-- class  subscribe_icon||subscribe_icon no_type-->
          </span>
        </div>
        <div class="table_student_column_show_phone"
             v-if="scope.row[columnData.extend.phone]">{{$utils.phoneModulePower(scope.row[columnData.extend.phone])}}</div>
        <div v-if="scope.row[columnData.extend.gradeClassKey]==0"
             class="Not_in_classbottom_warn">{{'未进班'}}</div>
      </div>
    </template>
  </el-table-column>
</template>

<script>
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String,
          extend: {
            studentKey: 'StudentKey', //  由本组件完成查看学生档案  所以需要辅助信息 学生ID
            phone: 'CustomerPhoneName', // 手机号 如果学生名下面要显示手机号 则必填 否则不填
            gradeClassKey: 'IsInGradeClassKey', // 未进班显示，是否显示学生名下的未进班标识 非必填
            showWechat: false
          }
        };
      }
    }
  },
  created () {
  },
  methods: {
    clickEvent (rowData) {
      if (typeof this.columnData.extend.click == 'function' && rowData[this.columnData.extend.studentKey]) {
        let item = {
          routerName: this.$route.name,
          moduleName: 'studentFile',
          name: '学生档案',
          data: { studentID: rowData[this.columnData.extend.studentKey] }
        };
        this.$dialog.open(this, item);
      }
    }
  }
};
</script>

<style>
</style>