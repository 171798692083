<!-- 文本 -->
<template>
  <el-table-column :fixed="columnData.fixed||false"
                   :label="columnData.label"
                   :prop="columnData.prop"
                   :width="columnData.width"
                   :min-width="columnData.minWidth"
                   :sortable="columnData.sortable"
                   :align="columnData.align||'left'"
                   :show-overflow-tooltip="vShowOverflowTooltip"
                   :sort-orders="['ascending', 'descending']"
                   :label-class-name="columnData.columnClass"
                   :render-header="columnData.renderHeader">
    <template slot-scope="scope">
      <div :class="[columnData.extend && typeof columnData.extend.click == 'function'?'font_blue button':'',vClassName,columnData.extend&&typeof(columnData.extend.setClassName)=='function'&&columnData.extend.setClassName(scope.row)]"
           @click="clickEvent(scope.row)">
          <span :class="columnData.extend.classNameOne">{{scope.row[columnData.extend.textOne]}}</span>
          <span :class="columnData.extend.classNameTwo">{{scope.row[columnData.extend.textTwo]}}</span>
      </div>
    </template>
  </el-table-column>
</template>

<script>
export default {
  props: {
    columnData: {// 列名配置
      type: Object,
      default: () => {
        return {
          label: String,
          prop: String
        };
      }
    }
  },
  created () {
  },
  computed: {
    vClassName () {
      let className = '';
      if (this.columnData.extend && this.columnData.extend.className) {
        className = this.columnData.extend.className;
      }
      return className;
    },
    vShowOverflowTooltip () {
      if (typeof this.columnData.showOverflowTooltip == 'boolean') {
        return this.columnData.showOverflowTooltip;
      } else {
        return true;
      }
    },
    vModulePowerList () {
      return this.$store.getters.RolePowerList;
    }
  },
  methods: {
    clickEvent (rowData) {
      // 表示是否支持点击
      if (this.columnData.extend && typeof this.columnData.extend.click == 'function') {
        // 普通点击事件 直接执行回调
        if (this.hasModulePower(Number(this.columnData.extend.ModulePowerKey))) {
          this.columnData.extend.click(rowData);
        } else {
          this.$utils.CheckModulePowerToTips(this.columnData.extend.ModulePowerKey);
        }
      }
    },
    // 判断权限,
    hasModulePower (ModulePowerKey) {
      ModulePowerKey = ModulePowerKey || '';
      return this.$utils.CheckModulePower(this.vModulePowerList, ModulePowerKey);
    }
  }
};
</script>

<style>
</style>