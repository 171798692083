<template>
  <div>
    <div class="form_info_student_box">
      <span style="font-size:20px;"
            class="font_black">{{studentItem.MainDemoName}}</span>
      <span style="font-size:12px;"
            class="font_gray">{{studentItem.MobileSupport}}</span>
    </div>
    <div class="register_student form_info_edit">
      <div class="bg_gray minHeight">
        <!-- 学生信息 -->

        <div class="form_info_box bg_white">

          <div class="from_textarea_box">
            <div class="up_sell_record_from_title from_textarea_title"
                 @click="isShowRecordList=true">
              内容
              <span style="color: #666;float: right;cursor: pointer;">
                共<span class="font_blue">{{this.recordList.length}}</span>条>
              </span>
            </div>
            <div class="from_textarea_content">
              <textarea v-model="dataInfo.FollowConent"
                        placeholder="请输入跟单内容"
                        maxlength="600"></textarea>
            </div>
          </div>
          <div>
            <div class="upload_img_content">
              <div class=""
                   style="width:90px">
                <span class="upload_btn font_blue">
                  上传
                  <!-- 上传 -->
                  <choose-img-mutil class="upload_box"
                                    :maxNum="1"
                                    @upLoadImgReject="doAfterUpLoadImgSuccess"></choose-img-mutil>
                </span>
                <div class="font_gray"
                     style="margin:5px">
                  限3张图片
                </div>
              </div>
              <div class="form_info_value flex_row">
                <div class="upload_img_content_imgitem"
                     v-for="(item,key) in dataInfo.FollowImgList"
                     :key="key"
                     @click="showPreview(item)">
                  <img :src="$store.getters.CDNURL+item.FollowImg"
                       :onerror="defaultImg"
                       alt="">
                  <div class="delete_the_photo"
                       @click.stop="deleteImg(key)">
                    <img src="../../../../../public/image/delete_the_photo.png" />
                  </div>
                  <div>{{key+1}}/3</div>
                </div>

              </div>
            </div>

          </div>
        </div>

        <!-- 提交 -->
        <div>
          <save-cancel-btn-group :isSingleSubmit="true"
                                 :singleSubmitType="'dynamic'"
                                 @confirmClick="confirmClick"
                                 @cancelClick="cancelClick"></save-cancel-btn-group>
        </div>
      </div>
    </div>
    <custom-dialog title="跟单记录流水"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowRecordList"
                   :before-close="closeFrom">
      <clew-follow-record-list :studentItem="studentItem"
                               :StudentThreadKey="dataInfo.StudentThreadKey"
                               @close="closeFrom"></clew-follow-record-list>
    </custom-dialog>
    <custom-dialog :title="'预览图片'"
                   :visible.sync="isPreviewImg"
                   :before-close="closePreviewImg">
      <div class="home_work_img">
        <preview-img :imgList="vimgList"
                     :showIndex="dataInfo.index"
                     :previewText="dataInfo.Content"
                     @onClosePreviewImg="closePreviewImg"></preview-img>
      </div>
    </custom-dialog>
  </div>

</template>
<script>
import previewImg from '../../../home-school-communication/common-component/preview-img/index';

import { GetStudentFollowUpList, AddAdiserStudentFollow } from "../../../../API/workbench";
import clewFollowRecordList from './clew-follow-record-list'
// 登记客户
export default {
  data () {
    return {
      dataInfo: {
        "FollowConent": '',//"跟单内容",
        "FollowImgList": [],// [{"FollowImg":"图片链接"},{"FollowImg":"图片链接"}],
      },
      isSubmit: true,
      isShowRecordList: false,
      recordList: [],
      isPreviewImg: false
    };
  },
  props: {
    studentItem: Object
  },
  components: {
    clewFollowRecordList, previewImg
  },
  computed: {
    vimgList () {
      let list = [];
      this.dataInfo.FollowImgList.forEach(o => {
        list.push({ imgUrl: o.FollowImg, id: o.OLAPKey });
      });
      return list;
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../../public/image/no_logo@2x.png') + '"';
    }
  },
  created () {
    this.getRecordList(this.studentItem.OLAPKey)
    // this.dataInfo = this.$utils.parseJson(newCustomerInfo);
  },
  mounted () { },
  watch: {},
  methods: {
    showPreview (item) {
      this.PhotoUrl = this.$store.getters.CDNURL + item.AttachUrl;
      console.log(item, 'aaa');
      this.isPreviewImg = true;
      let locaIndex = this.dataInfo.FollowImgList.findIndex(o => {
        return o.OLAPKey == item.OLAPKey;
      });
      if (locaIndex >= 0) {
        this.dataInfo.Content = item.Title;
        this.dataInfo.index = locaIndex;
      } else {
        this.dataInfo.index = 0;
      }
    },
    // 关闭预览图片
    closePreviewImg () {
      this.isPreviewImg = false;
      this.dataInfo.index = 0;
    },
    getRecordList () {
      GetStudentFollowUpList(this.studentItem.OLAPKey, this.studentItem.AdviserStudentKey).then(result => {
        this.recordList = result.data
        console.log("GetFollowUpList", result.data)
      })
    },
    closeFrom () {
      this.isShowRecordList = false
    },
    deleteImg (key) {
      layer.confirm('是否删除该图片', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              this.dataInfo.FollowImgList.splice(key, 1)
              layer.close();
            }
          }
        ]
      });
      console.log("deleteImg", key)
    },
    // 新增
    doAfterUpLoadImgSuccess (imgUrl, callback) {
      console.log('doAfterUpLoadImgSuccess', imgUrl);
      if (this.dataInfo.FollowImgList.length == 3) {
        layer.alert("最多只能上传三张图片")
        return false
      } else {
        let obj = {
          FollowImg: imgUrl
        }
        this.dataInfo.FollowImgList.push(obj)
      }

    },
    // 确定执行
    confirmClick (callBack) {
      const res = this.validata(callBack);
      if (!res) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (this.isSubmit) {
        // 防止接口请求太慢导致重复提交
        this.confirmData(callBack);
      }
    },
    // 验证客户信息
    validata (callBack) {
      let flag = true;

      if (!this.dataInfo.FollowConent) {
        layer.alert("请输入跟单内容");
        flag = false;
      }
      return flag;
    },
    // 提交
    confirmData (callBack) {
      this.addConfirm(this.studentItem.AdviserStudentKey, this.studentItem.OLAPKey, this.dataInfo, callBack);
    },

    // 新增操作
    addConfirm (TeacherKey, StudentKey, dataInfo, callBack) {
      AddAdiserStudentFollow(TeacherKey, StudentKey, dataInfo)
        .then(
          result => {
            layer.alert("新增成功");
            this.$emit("afterSuccess", result.data);
            this.cancelClick(result.data);
          },
          err => {
            layer.alert(err.msg);
          }
        )
        .finally(() => {
          this.isSubmit = true;
          if (callBack) {
            callBack();
          }
        });
    },
    // 取消
    cancelClick (data) {
      this.$emit("close");
    }
  }
};
</script>
<style scoped>
.form_info_student_box {
  height: 60px;
  line-height: 50px;
  border-bottom: 1px solid #ececec;
  padding: 5px 20px;
}

.upload_btn {
  display: inline-block;
  line-height: 36px;
  width: 76px;
  height: 36px;
  border-radius: 4.6769232749938965px;
  text-align: center;
  padding-left: 20px;
  font-size: 14px;
  background: #f1f3f9;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.upload_btn::before {
  content: '';
  display: inline-block;
  position: absolute;
  right: 44px;
  top: 8px;
  width: 20px;
  height: 20px;
  background: url('../../../../../public/image/Vector_upload.png') center
    no-repeat;
  background-size: 16px;
}
.upload_input {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.upload_img_content {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  height: 109px;
  margin-bottom: 15px;
}
.flex_row {
  display: flex;
  flex-direction: row;
}
.upload_img_content_imgitem {
  width: 85px;
  height: 109px;
  margin-right: 10px;
  text-align: center;
  position: relative;
}
.upload_img_content_imgitem img {
  width: 85px;
  height: 85px;
}
.delete_the_photo img {
  position: absolute;
  width: 30px;
  height: 30px;
  float: right;
  top: 0;
  right: 0;
}
.up_sell_record_from_title {
  font-size: 16px !important;
}
</style>