import { render, staticRenderFns } from "./teacher-course-performance-statistics-Info.vue?vue&type=template&id=26305473"
import script from "./teacher-course-performance-statistics-Info.vue?vue&type=script&lang=js"
export * from "./teacher-course-performance-statistics-Info.vue?vue&type=script&lang=js"
import style0 from "./teacher-course-performance-statistics-Info.vue?vue&type=style&index=0&id=26305473&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports