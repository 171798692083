<template>
<div>
	<div class="setting_view_left_ul">
			<setting-view-left-item v-for="(item,index) in dataList" :key="index" :Item="item" @doItemClick="doTypeSearchClick" @doCourseItemClick="doCourseViewClick"></setting-view-left-item>
	</div>
	<div class="setting_view_left_btn" v-if="addDataName &&dataList.length>=0" @click="doAddTypeClick">
		<span class="font_icon">+</span> {{addDataName}}
	</div>
</div>
</template>

<script>
import settingViewLeftItem from './setting-view-left-item';
export default {
  components: {
    settingViewLeftItem
  },
  props: {
    dataList: Array, // 左侧视图-列表
    addDataName: String // 下方添加按钮-标题
  },
  data () {
    return {

    };
  },
  methods: {
	  // 选择类型筛选-操作
    doTypeSearchClick (item) {
      this.dataList.forEach(o => {
        if (o.OLAPKey === item.OLAPKey) {
          o.isOpt = true;
        } else {
          o.isOpt = false;
        }
      });
      this.$emit('doTypeSearchClick', item);
    },
	  // 添加类型-操作
    doAddTypeClick () {
	   this.$emit('doAddTypeClick');
    },
    // 查看
    doCourseViewClick (item) {
      this.dataList.forEach(o => {
		  if (o.OLAPKey === item.OLAPKey) {
		    o.isOpt = true;
		  } else {
		    o.isOpt = false;
		  }
      });
      this.$emit('doCourseViewClick', item);
    }
  }
};
</script>

<style>

</style>
