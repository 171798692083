<template>
  <!-- 微信通知设置 -->
  <div class="mendian_setting_content form_info_edit">
    <input-dialog :formTitle="'课前提醒'"
                  class="padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('课前提醒',12,53)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(53))"></input-dialog>

    <input-dialog :formTitle="'第一次'"
                  class="padding_LR_10 no_border_bottom"
                  :className="'font_black'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="true"
                  :value="showHourMinutestr(62)"></input-dialog>

    <input-dialog :formTitle="'第二次'"
                  class="padding_LR_10"
                  :className="'font_black'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="true"
                  :value="showHourMinutestr(63)"></input-dialog>

    <input-dialog :formTitle="'签到通知'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('签到通知',13,55)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(55))"></input-dialog>

    <input-dialog :formTitle="'请假通知'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('请假通知',15,48)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(48))"></input-dialog>

    <input-dialog v-if="this.$utils.getSaaSClientSet(59) > 0"
                  :formTitle="'旷课通知'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('旷课通知',16,56)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(56))"></input-dialog>

    <input-dialog :formTitle="'家校通知'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('家校通知',33,45)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(45))"></input-dialog>

    <input-dialog :formTitle="'家校回复'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('家校回复',32,46)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(46))"></input-dialog>

    <input-dialog :formTitle="'家校点评'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('家校点评',31,44)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(44))"></input-dialog>

    <input-dialog :formTitle="'家校批改'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('家校批改',30,43)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(43))"></input-dialog>

    <input-dialog :formTitle="'家校作业'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('家校作业',29,42)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(42))"></input-dialog>

    <input-dialog :formTitle="'校正考勤通知'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('校正考勤通知',34,37)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(37))"></input-dialog>

    <input-dialog :formTitle="'缴费通知'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('缴费通知',39,51)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(51))"></input-dialog>

    <input-dialog :formTitle="'还款通知'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('还款通知',40,50)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(50))"></input-dialog>

    <input-dialog :formTitle="'还课时通知'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('还课时通知',41,54)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(54))"></input-dialog>

    <input-dialog :formTitle="'课单过期提醒'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('课单过期提醒',38,38)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(38))"></input-dialog>

    <input-dialog :formTitle="'课时不足提醒'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="mendianInfo.WktInfo.PatternKey == 2&&mendianInfo.IsCommonTrusteeshipKey == 1?'norole':''"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  :formInputHint="'在工作台-智能预警-课时不足学生发送'"
                  @showEldilog="doTemplateSettingClick('课时不足提醒',37,47)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(47))"></input-dialog>

    <input-dialog :formTitle="'欠费提醒'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="mendianInfo.WktInfo.PatternKey == 2&&mendianInfo.IsCommonTrusteeshipKey == 1?'norole':''"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  :formInputHint="'在工作台-智能预警-报名欠款学生发送'"
                  @showEldilog="doTemplateSettingClick('欠费提醒',35,36)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(36))"></input-dialog>

    <input-dialog :formTitle="'欠课提醒'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="mendianInfo.WktInfo.PatternKey == 2&&mendianInfo.IsCommonTrusteeshipKey == 1?'norole':''"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  :formInputHint="'在工作台-智能预警-欠课时学生发送'"
                  @showEldilog="doTemplateSettingClick('欠课提醒',36,40)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(40))"></input-dialog>

    <input-dialog  v-if="vOpenWKT"
                  :formTitle="'优惠券过期提醒'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('优惠券过期提醒',4,39)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(39))"></input-dialog>

    <input-dialog  v-if="vOpenWKT"
                  :formTitle="'赠送优惠券通知'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('赠送优惠券通知',1,52)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(52))"></input-dialog>

    <input-dialog v-if="mendianInfo.IsOpenMicroClassKey == 1 && mendianInfo.WktInfo"
                  :formTitle="'成长时光通知'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('成长时光通知',45,41)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(41))"></input-dialog>

    <!-- 官方门店的托管门店，不开放（入口隐藏） -->
    <input-dialog v-if="mendianInfo.IsCommonTrusteeshipKey != 1"
                  :formTitle="'微消息通知'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('微消息通知',21,49)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(49))"></input-dialog>
    <input-dialog  v-if="vOpenApontCourseSetting"
                  :formTitle="'约课通知'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('约课通知',48,74)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(74))"></input-dialog>
    <input-dialog  v-if="vOpenApontCourseSetting"
                  :formTitle="'学分变动提醒'"
                  class=" padding_LR_10 no_border_bottom"
                  :className="!isReadonly?'':'norole'"
                  :formPlaceholder="' '"
                  :required="false"
                  :readonly="isReadonly"
                  @showEldilog="doTemplateSettingClick('学分变动提醒',49,96)"
                  :value="showInformSwitchBtn($utils.getSaaSClientSet(96))"></input-dialog>
  </div>
</template> 

<script>
export default {
  props: {
    mendianInfo: {
      type: Object,
      default: {}
    },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {

    };
  },
  computed:{
    //开启督学设置
    vOpenWKT(){
        return this.$utils.CheckOpenSettingPower(this.$store.getters.SaaSClientInfo.OpenModule,10)
    },
    // 是否开通约课设置
    vOpenApontCourseSetting(){
        return this.$utils.CheckOpenSettingPower(this.$store.getters.SaaSClientInfo.OpenModule,3)
    },
  },
  created () {

  },
  methods: {
    // 显示消息模板类型
    doTemplateSettingClick (titleName, eventId, setTypeKey) {
      console.log(!this.readonlyInfo, 'this.readonlyInfo');
      if (!this.readonlyInfo) {
        if ((eventId == 35 || eventId == 36 || eventId == 37) && this.mendianInfo.WktInfo && this.mendianInfo.WktInfo.PatternKey == 2 && this.mendianInfo.IsCommonTrusteeshipKey == 1) {
          layer.alert('非自有公众号，不能设置。');
        } else {
          let templateKey = this.$utils.getSaaSClientSet(setTypeKey, this.mendianInfo.SaaSClientSetting);
          this.$emit('doTemplateSettingClick', titleName, eventId, templateKey, setTypeKey);
        }
      } else {
        this.$utils.CheckModulePowerToTips(38);
      }
    },
    showInformSwitchBtn (key) {
      if (key == 1) {
        return '开启';
      } else {
        return '关闭';
      }
    },
    showHourMinutestr (power) {
      let list = this.mendianInfo.SaaSClientSetting || [];
      let str = '';
      if (list.length > 0) {
        let findItem = list.find(o => {
          return (o.SetTypeKey == power);
        });
        if (findItem && Number(findItem.SetContent) > 0) {
          str = Number(findItem.SetContent);
        }
      }
      if (str != 0 && str != '' && str !== null) {
        return '上课前 ' + ((Math.floor(str / 60)).toString() > 0 ? (Math.floor(str / 60)).toString() + '小时 ' + ((str % 60).toString() > 0 ? (str % 60).toString() + '分钟' : '') : (str % 60).toString() + '分钟');
      } else {
        return '未开启';
      }
    }
  }
};
</script>

