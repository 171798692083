<template>
  <div>
    <custom-dialog :title="title"
                   :visible.sync="isAlterMessagePopup"
                   :before-close="hideAlterMessagePopup">

      <div class="form_info_detail micro_message">
        <div class="content_marginRL single_shadow">
          <div class="message_edit_input"
               style="color: #fafafa">
            <textarea cols="30"
                      rows="10"
                      :maxlength="maxLength || 100"
                      :placeholder="placeholderText"
                      v-model="textSupport"
                      @input="updateVal($event)"
                      @change="changeVal($event)"></textarea>
          </div>
        </div>
        <save-cancel-btn-group :isSingleSubmit="true"
                               :singleSubmitType="'dynamic'"
                               @confirmClick="submitStudentArrangeSupport"
                               @cancelClick="hideAlterMessagePopup"></save-cancel-btn-group>
      </div>

    </custom-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isAlterMessagePopup: false,
      textSupport: ''
    };
  },
  props: {
    title: {
      type: String,
      default: '备注'
    },
    message: String,
    placeholderText: {
      type: String,
      default: '请输入排课备注'
    },
    maxLength: {
      type: Number,
      default: 100
    }
  },
  created () {
    this.textSupport = this.message;
  },
  watch: {
    message (n, o) {
      this.textSupport = n;
    }
  },
  methods: {
    updateVal (e) {
      this.onkeyupEvent(e);
    },
    changeVal (e) {
      this.onkeyupEvent(e);
    },
    // 学生排课备注 提交
    submitStudentArrangeSupport (callBack) {
      if (this.textSupport == '') {
        layer.alert('请输入内容');
        callBack();
      } else {
        this.$emit('submitStudentArrangeSupport', this.textSupport, callBack);
        this.hideAlterMessagePopup();
      }
    },
    hideAlterMessagePopup () {
      this.isAlterMessagePopup = false;
      this.textSupport = this.message;
    },
    limitMaxLength (str, maxLen) {
      var result = [];
      var re = /^[a-zA-Z]+$/;
      for (var i = 0; i < maxLen; i++) {
        var char = str[i];
        if (!re.test(char)) { maxLen--; }
        result.push(char);
      }
      return result.join('');
    },
    onkeyupEvent (e) {
      // let val = e.target.value;
      // if (this.$utils.strLength(val) > this.maxLength * 2) {
      //   e.target.value = this.limitMaxLength(val, this.maxLength * 2);
      // }    if (this.$utils.strLength(val) > this.maxLength * 2) {
      //   e.target.value = this.limitMaxLength(val, this.maxLength * 2);
      // }
    }
  }
};
</script>
<style>
</style>