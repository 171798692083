<template>
  <div class="detail">
    <div class="headbox">
      <div class="name clearfix">
        <img :src="headImg"
             :onerror="defaultImg"
             alt="../../../../../public/image/deflut_teacher_photo@2x.png"
             crossOrigin="anonymous" />
        <div>{{studentobj.name}}</div>
        <span>{{studentobj.tel}}</span>
      </div>
      <div class="nav clearfix">
        <div v-for="i in courseList"
             :key="i.CourseNameKey"
             class="block"
             :class="i.CourseNameKey==search.courseNow ? 'cur' : ''"
             @click="courseClick(i)">{{i.CourseNameKeyValue}}</div>
      </div>
    </div>
    <div class="formbox clearfix">
      <div class="text">缺勤日期</div>
      <date-range ref="selectDate"
                  @changeDate="selectDateChange"
                  :minDate="minDate"
                  style="float:left;display:block;"></date-range>
      <div class="block"
           :class="search.type==1 ? 'cur' : ''"
           @click="typeClick(1)">待处理: {{pending}}</div>
      <div class="block"
           :class="search.type==2 ? 'cur' : ''"
           @click="typeClick(2)">已处理: {{processed}}</div>
      <div class="block"
           :class="search.type==0 ? 'cur' : ''"
           @click="typeClick(0)">共缺勤: {{AbsentCount}}</div>
    </div>
    <div class="listbox">
      <div v-for="(i,index) in tableData"
           :key="index"
           class="block clearfix">
        <div class="date">
          <div class="day"><span>{{i.WeekKeyValue}}</span>{{i.absenteeismTimeDay}}</div>
          <div class="time">{{i.ClassTimeName}}</div>
        </div>
        <div class="name">
          <div>{{i.CourseNameKeyValue}}</div>
          <span>{{i.GradeClassKeyValue}} - {{i.MTeacherKeyValue}}</span>
        </div>
        <div class="mark"
             :class="i.AttenceStatusKey==5 ? 'red' : ''">
          <div v-if="i.AttenceStatusKey==4">请假</div>
          <div v-if="i.AttenceStatusKey==5">旷课</div>
          <span v-if="i.PeriodCount==0">{{i.PeriodCount}} 课时</span>
          <span v-else>-{{i.PeriodCount}} 课时</span>
        </div>
        <div class="state"
             @click="goRepairProgramme(i)"
             :style="i.DealTypeKey==2 ? 'cursor:pointer;' : ''">
          <div class="pending"
               v-if="i.DealTypeKey==0">待处理</div>
          <div class="processed"
               v-if="i.DealTypeKey==4">补扣课时: -{{i.MakeupClassHourCount}} 课时</div>
          <div class="processed"
               v-if="i.DealTypeKey==3">退还课时: +{{i.MakeupClassHourCount}} 课时</div>
          <div class="processed"
               v-if="i.DealTypeKey==1">不补课</div>
          <div class="status"
               v-if="i.DealTypeKey==2 && i.MakeupAttenceStatusKey==2"><span class="circle"
                  v-if="i.MakeUpCount>1">{{i.MakeUpCount}}</span> <span>已排课</span></div>
          <div class="status green"
               v-if="i.DealTypeKey==2 && i.MakeupAttenceStatusKey==3"><span class="circle"
                  v-if="i.MakeUpCount>1">{{i.MakeUpCount}}</span> <span>已补课</span> | 补课出勤 {{i.MakeupClassHourCount}}课时</div>
          <div class="status yellow"
               v-if="i.DealTypeKey==2 && i.MakeupAttenceStatusKey==4"><span class="circle"
                  v-if="i.MakeUpCount>1">{{i.MakeUpCount}}</span> <span>请假</span></div>
          <div class="status red"
               v-if="i.DealTypeKey==2 && i.MakeupAttenceStatusKey==5"><span class="circle"
                  v-if="i.MakeUpCount>1">{{i.MakeUpCount}}</span> <span>旷课</span></div>
          <div class="target"
               v-if="i.DealTypeKey==2">{{i.MakeUpClassTime}} {{i.MakeUpWeekKeyValue}} {{i.MakeUpClassTimeName}} {{i.MakeupGradeClassKeyValue}} {{i.MakeupTeacherKeyValue}}</div>
        </div>
        <div class="btns clearfix">
          <a @click="toPlan(i)"
             v-if="(i.DealTypeKey==0 || i.DealTypeKey==2) && i.MakeupAttenceStatusKey!=3">安排补课</a>
          <a @click="refuse(i)"
             v-if="(i.DealTypeKey==0 || i.DealTypeKey==2) && i.MakeupAttenceStatusKey!=3">不补课</a>
          <a @click="refund(i)"
             v-if="(i.DealTypeKey==0 || i.DealTypeKey==2) && i.MakeupAttenceStatusKey!=3 && i.PeriodCount!=0">退还课时</a>
          <a @click="remake(i)"
             v-if="(i.DealTypeKey==0 || i.DealTypeKey==2) && i.MakeupAttenceStatusKey!=3 && i.PeriodCount==0">补扣课时</a>
          <a @click="revoke(i)"
             v-if="(i.DealTypeKey==1 || i.DealTypeKey==3 || i.DealTypeKey==4) || (i.DealTypeKey==2 && i.MakeupAttenceStatusKey==3)">撤销处理</a>
        </div>
      </div>
    </div>
    <el-pagination @size-change="sizeChange"
                   @current-change="currentChange"
                   :current-page="search.pageIndex"
                   :page-sizes="[5,10,20,30,40,50]"
                   :page-size="search.pageSize"
                   layout="total,prev,pager,next,jumper,sizes"
                   :total="totalNum"
                   background></el-pagination>
    <custom-dialog title="安排补课"
                   width="500px"
                   :visible.sync="isShowPlan"
                   :before-close="closePlan">
      <repair-student-plan :planobj="dataPlan"
                           @afterSuccess="successPlan"
                           @cancelClick="closePlan"></repair-student-plan>
    </custom-dialog>
    <custom-dialog title="补课处理方案"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowRepairCourseProgramme"
                   :before-close="closeRepairCourseProgrammePopup">
      <repair-programme-setting :formTitle="'补课处理方案'"
                                :repairProgrammObj="repairProgrammObj"></repair-programme-setting>
    </custom-dialog>
  </div>
</template>

<script>
import { GetMakeUpCourseByStudent, MessageSearchPageMakeUpCourseStudensProcessingRecord, DealMakeUpForDoNot, DealMakeUpForRefundClassHour, DealMakeUpForDeductClassHour, RevokeDispose } from '../../../../API/workbench';
import repairStudentPlan from './plan.vue';
import repairProgrammeSetting from '../repair-programme-setting';
export default {
  data () {
    return {
      courseList: [],
      search: {
        StartTime: '',
        EndTime: '',
        courseNow: '',
        type: 0,
        pageIndex: 1,
        pageSize: 10
      },
      tableData: [],
      totalNum: 0,
      PageCount: 0,
      pending: 0,
      processed: 0,
      AbsentCount: 0,
      isShowPlan: false,
      dataPlan: {
        mode: 1, // 1为已有学生和缺课记录，要选补课课程，2为已有补课课程，要选学生和缺课记录
        studentKey: '', // 学生id
        studentVal: '', // 学生名
        absenceKey: '', // 缺课记录id
        absenceCourseDay: '', // 缺课日期，只传日
        absenceCourseWeek: '', // 缺课日期，周几
        absenceCourseTime: '', // 缺课上课时段
        absenceCourseKey: '', // 缺课课程id
        absenceCourseName: '', // 缺课课程名
        absenceCourseClass: '', // 缺课班级名
        absenceCourseTeacher: '', // 缺课老师名
        absenceCourseType: '', // 缺课原因
        absenceCourseHour: ''// 消耗课时数
      },
      isShowRepairCourseProgramme: false,
      repairProgrammObj: {}
    };
  },
  components: {
    repairStudentPlan,
    repairProgrammeSetting
  },
  props: {
    studentobj: Object
  },
  created () {
  },
  mounted () {
    this.search.courseNow = this.studentobj.course;
    this.search.StartTime = this.studentobj.start;
    this.search.EndTime = this.studentobj.end;
    this.$refs.selectDate.changeHistoryTime(this.studentobj.start, this.studentobj.end);
    GetMakeUpCourseByStudent(this.studentobj.key).then(result => {
      if (result.code == 0) {
        this.courseList = result.data;
      } else {
        console.log(result.msg);
      }
    }).catch(error => {
      console.log(error);
    });
    this.getList();
  },
  computed: {
    headImg () {
      if (!this.studentobj.img) {
        return '';
      } else if (this.studentobj.img.indexOf('http') > -1) {
        return this.studentobj.img;
      } else {
        return this.$store.getters.CDNURL + this.studentobj.img;
      }
    },
    defaultImg () {
      return ('this.src="' + require('../../../../../public/image/deflut_teacher_photo@2x.png') + '"');
    },
    minDate () {
      let now = new Date();
      let year = parseInt(now.getFullYear());
      let month = parseInt(now.getMonth()) + 1;
      let day = 1;
      if (month == 2) {
        month = 12;
        year = year - 1;
      } else if (month == 1) {
        month = 11;
        year = year - 1;
      } else {
        month = month - 2;
      }
      return year + '-' + month + '-' + day;
    }
  },
  methods: {
    courseClick (row) {
      this.search.courseNow = row.CourseNameKey;
      this.search.pageIndex = 1;
      this.getList();
    },
    typeClick (key) {
      this.search.type = key;
      this.search.pageIndex = 1;
      this.getList();
    },
    selectDateChange (obj) {
      this.search.StartTime = obj.startTime;
      this.search.EndTime = obj.endTime;
      this.search.pageIndex = 1;
      this.getList();
    },
    sizeChange (val) {
      this.search.pageSize = val;
      this.search.pageIndex = 1;
      this.getList();
    },
    currentChange (val) {
      this.search.pageIndex = val;
      this.getList();
    },
    getList () {
      let that = this;
      let param = {
        pageIndex: that.search.pageIndex - 1,
        pageSize: that.search.pageSize,
        searchText: '',
        orderBy: '',
        sequence: '',
        StartTime: that.search.StartTime,
        EndTime: that.search.EndTime,
        ClassTypeKey: that.search.courseNow,
        MakeupResultKey: that.search.type,
        StudentKey: that.studentobj.key
      };
      MessageSearchPageMakeUpCourseStudensProcessingRecord(param).then(result => {
        if (result.code == 0) {
          let list = result.data.PageDataList;
          for (let i = 0; i < list.length; i++) {
            list[i].absenteeismTimeDay = list[i].absenteeismTime ? list[i].absenteeismTime.split('-')[1] + '月' + list[i].absenteeismTime.split('-')[2] + '日' : '-';
            list[i].PeriodCount = Number(list[i].PeriodCount);
            list[i].MakeupClassHourCount = Number(list[i].MakeupClassHourCount);
          }
          that.pending = result.data.WaitingCount;
          that.processed = result.data.ResolvedCount;
          that.AbsentCount = result.data.AbsentCount;
          that.totalNum = result.data.Total;
          that.PageCount = result.data.PageCount;
          that.tableData = list;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    refuse (obj) {
      let that = this;
      layer.confirm('确认设置为不补课？', {
        btn: [{
          name: '取消',
          callBack: function () {
            layer.close();
          }
        }, {
          name: '确认',
          callBack: function () {
            layer.close();
            DealMakeUpForDoNot(obj.StudentNameKey, obj.ScheduleCourseKey).then(result => {
              if (result.code == 0) {
                that.getList();
              } else {
                console.log(result.msg);
              }
            }).catch(error => {
              console.log(error);
            });
          }
        }]
      });
    },
    refund (obj) {
      let that = this;
      layer.confirm('确认退还课时？', {
        btn: [{
          name: '取消',
          callBack: function () {
            layer.close();
          }
        }, {
          name: '确认',
          callBack: function () {
            layer.close();
            DealMakeUpForRefundClassHour(obj.StudentNameKey, obj.ScheduleCourseKey).then(result => {
              if (result.code == 0) {
                that.getList();
              } else {
                console.log(result.msg);
              }
            }).catch(error => {
              console.log(error);
            });
          }
        }]
      });
    },
    remake (obj) {
      let that = this;
      layer.confirm('确认补扣课时？', {
        btn: [{
          name: '取消',
          callBack: function () {
            layer.close();
          }
        }, {
          name: '确认',
          callBack: function () {
            layer.close();
            DealMakeUpForDeductClassHour(obj.StudentNameKey, obj.ScheduleCourseKey).then(result => {
              if (result.code == 0) {
                that.getList();
              } else {
                console.log(result.msg);
              }
            }).catch(error => {
              console.log(error);
              layer.alert(error.msg);
            });
          }
        }]
      });
    },
    revoke (obj) {
      let that = this;
      layer.confirm('确认撤销处理？', {
        btn: [{
          name: '取消',
          callBack: function () {
            layer.close();
          }
        }, {
          name: '确认',
          callBack: function () {
            layer.close();
            RevokeDispose(obj.StudentNameKey, obj.ScheduleCourseKey, '', '').then(result => {
              if (result.code == 0) {
                that.getList();
              } else {
                console.log(result.msg);
              }
            }).catch(error => {
              console.log(error);
              layer.alert(error.msg);
            });
          }
        }]
      });
    },
    toPlan (obj) {
      this.dataPlan.studentKey = obj.StudentNameKey;
      this.dataPlan.studentVal = obj.StudentNameKeyValue;
      this.dataPlan.absenceKey = obj.ScheduleCourseKey;
      this.dataPlan.absenceCourseDay = obj.absenteeismTimeDay;
      this.dataPlan.absenceCourseWeek = obj.WeekKeyValue;
      this.dataPlan.absenceCourseTime = obj.ClassTimeName;
      this.dataPlan.absenceCourseKey = obj.CourseNameKey;
      this.dataPlan.absenceCourseName = obj.CourseNameKeyValue;
      this.dataPlan.absenceCourseClass = obj.GradeClassKeyValue;
      this.dataPlan.absenceCourseTeacher = obj.MTeacherKeyValue;
      this.dataPlan.absenceCourseType = obj.AttenceStatusKey;
      this.dataPlan.absenceCourseHour = obj.PeriodCount;
      this.isShowPlan = true;
    },
    closePlan () {
      this.isShowPlan = false;
    },
    successPlan () {
      this.isShowPlan = false;
      this.getList();
    },
    closeRepairCourseProgrammePopup () {
      this.isShowRepairCourseProgramme = false;
    },
    goRepairProgramme (rowData) {
      if (rowData.DealTypeKey == 2) {
        this.repairProgrammObj = rowData;
        this.isShowRepairCourseProgramme = true;
      }
    }
  }
};
</script>

<style scoped>
.detail .headbox {
  background-color: #f2f2f2;
  padding: 20px 20px 0;
}
.detail .headbox .name {
  line-height: 50px;
}
.detail .headbox .name img {
  display: block;
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
.detail .headbox .name div {
  display: block;
  float: left;
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
}
.detail .headbox .name span {
  display: block;
  float: left;
  color: #666666;
  margin-right: 10px;
}
.detail .headbox .nav {
  margin-top: 10px;
}
.detail .headbox .nav .block {
  float: left;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  box-sizing: border-box;
  cursor: pointer;
  margin-right: 20px;
}
.detail .headbox .nav .block.cur {
  font-weight: bold;
  border-bottom: 3px solid #3498db;
}
.detail .formbox {
  border-bottom: 1px solid #cccccc;
  padding: 10px 20px;
}
.detail .formbox .text {
  float: left;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  color: #666666;
  margin-right: 10px;
}
.detail .formbox .block {
  float: right;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0 10px;
  margin-left: 10px;
}
.detail .formbox .block.cur {
  font-weight: bold;
  color: #3498db;
  border: 2px solid #3498db;
}
.detail .listbox {
  padding: 0 20px;
}
.detail .listbox .block {
  padding: 10px 0;
  border-bottom: 1px solid #cccccc;
}
.detail .listbox .block .date {
  float: left;
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 2px solid #333333;
  margin-right: 10px;
}
.detail .listbox .block .date .day {
  height: 26px;
  line-height: 26px;
  font-size: 14px;
  border-bottom: 2px solid #333333;
}
.detail .listbox .block .date .day span {
  display: inline-block;
  color: #ffffff;
  background-color: #333333;
  padding: 0 5px;
  margin-right: 5px;
}
.detail .listbox .block .date .time {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  padding: 0 5px;
  text-align: center;
}
.detail .listbox .block .name {
  float: left;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}
.detail .listbox .block .name div {
  height: 26px;
  line-height: 26px;
  font-size: 16px;
  font-weight: bold;
}
.detail .listbox .block .name span {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
}
.detail .listbox .block .mark {
  float: left;
  width: 100px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #f59a23;
  margin-right: 10px;
}
.detail .listbox .block .mark div {
  height: 26px;
  line-height: 26px;
  font-size: 14px;
  color: #ffffff;
  background-color: #f59a23;
}
.detail .listbox .block .mark span {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  color: #f59a23;
}
.detail .listbox .block .mark.red {
  border: 2px solid #d9001b;
}
.detail .listbox .block .mark.red div {
  background-color: #d9001b;
}
.detail .listbox .block .mark.red span {
  color: #d9001b;
}
.detail .listbox .block .state {
  float: left;
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 2px solid #cccccc;
  box-sizing: border-box;
  padding: 10px;
  margin-right: 20px;
}
.detail .listbox .block .state .pending {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  color: #999999;
  text-align: center;
}
.detail .listbox .block .state .processed {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
}
.detail .listbox .block .state .status {
  height: 20px;
  line-height: 20px;
  font-size: 16px;
  margin-bottom: 10px;
}
.detail .listbox .block .state .status .circle {
  display: inline-block;
  border: 1px solid #333333;
  border-radius: 11px;
  padding: 0 6px;
}
.detail .listbox .block .state .status.red span {
  color: #d9001b;
}
.detail .listbox .block .state .status.red .circle {
  border: 1px solid #d9001b;
}
.detail .listbox .block .state .status.yellow span {
  color: #f59a23;
}
.detail .listbox .block .state .status.yellow .circle {
  border: 1px solid #f59a23;
}
.detail .listbox .block .state .status.green span {
  color: #52c3a1;
}
.detail .listbox .block .state .status.green .circle {
  border: 1px solid #52c3a1;
}
.detail .listbox .block .state .target {
  height: 20px;
  line-height: 20px;
  font-size: 14px;
}
.detail .listbox .block .btns {
  float: left;
  width: 200px;
  margin-right: 10px;
}
.detail .listbox .block .btns a {
  float: left;
  display: block;
  width: 50%;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  color: #3498db;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  cursor: pointer;
}

.detail >>> .el-pagination {
  text-align: right;
  background-color: transparent;
}
</style>