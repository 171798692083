<template>
	<div class="course_estimate_list">
		<!-- 点评学生 -->
		<remark-student-view :dataInfo="dataInfo" :dataIndex="dataIndex" :dataLength="dataLength"></remark-student-view>
		<div class="pr">
          <!-- 作业内容 -->
          <new-home-task-browse-item :dataInfo="dataInfo"></new-home-task-browse-item>
         
    </div>
    <!-- <div class="article_details_content"> -->
			<!-- 点评文字内容 -->
			<!-- <remark-content-view :dataInfo="dataInfo"></remark-content-view> -->
			<!-- 附件块（音频+视频+图片） -->
			<!-- <attach-view :AttachList="dataInfo.AttachList" :content="dataInfo.Content"></attach-view> -->
		<!-- </div> -->
		<!-- 回复头部（点赞数+评论数+时间） -->
		<reply-head-view :dataInfo="dataInfo" @doMineReplyClick="doMineReplyClick"></reply-head-view>
		<!-- 回复内容 -->
		<div class="article_details_comment" v-if="dataInfo.Replylist.length>0">
			<reply-content-view v-for="(item,index) in dataInfo.Replylist" :key="index" :ReplyItem="item" :dataInfo="dataInfo" @doMineReplyClick="doMineReplyClick"></reply-content-view>
		</div>
	</div>
</template>

<script>
import newHomeTaskBrowseItem from '../../../home-task-page/new-home-task-browse-item';

import remarkStudentView from '../../../components/comment-box/remark-student-view.vue';
import remarkContentView from '../../../components/comment-box/remark-content-view.vue';
import replyHeadView from '../../../components/comment-box/reply-head-view.vue';
import replyContentView from '../../../components/comment-box/reply-content-view.vue';
import attachView from '../../common/attach-view.vue';
export default {
  components: {
    remarkStudentView,
    remarkContentView,
    replyHeadView,
    replyContentView,
    attachView,
    newHomeTaskBrowseItem
  },
  data () {
    return {

    };
  },
  props: {
    dataInfo: Object,
    dataIndex: Number,
    dataLength: Number
  },
  created () {
    console.log(this.dataInfo, 'dataInfo');
  },
  methods: {
	doMineReplyClick(type, itme){
     this.$emit('doMineReplyClick',type, itme);
	}
  }
};
</script>

<style>

</style>
