<template>
  <div>
    <custom-dialog title="单学生排课"
                   class="student_arrange_dialog defult_dialog no_hidden"
                   :visible.sync="showBox"
                   :before-close="close">
      <!-- 搜索学生弹框 -->
      <div v-if="searchBoxShow">
        <searchBox :searchInitJson="newScheduleCourseInitJson"
                   @searchClick="chooseClick"></searchBox>
      </div>
      <!-- 学生列表弹框 -->
      <div v-else-if="singleSelectionListShow">
        <student-list :isShowSearchInput="isShowSearchInput"
                      :studentList="studentList"
                      :switchItem="1"
                      @selectedItem="selectedItem"></student-list>
      </div>
      <!-- 单学生排课 -->
      <student-courses-arranging v-show="isContent"
                                 ref="studentCoursesArranging"
                                 :selectedStudentInfo="selectedStudentInfo"
                                 @alterStudent="alterStudent"
                                 @showCourseList="showCourseList"
                                 @showTeacherList="showTeacherList"
                                 @showClassList="showClassList"
                                 @showCoursesProject="showCoursesProject"
                                 @cancelArrangeSuccess="cancelArrangeSuccess"
                                 @arrangeStudentSuccess="arrangeStudentSuccess"></student-courses-arranging>
    </custom-dialog>
    <!-- 搜索选择层 -->
    <custom-dialog :title="selectionTitle"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isShowCourseNameList"
                   :before-close="closeDialogForCourseNameList">
      <div>
        <single-selection-list ref="studentListDialog"
                               isShowSearchInput="false"
                               :dataSourceList="courseNameList"
                               :isShowUnChoose="false"
                               @onSelectedItem="doAfterSelectedCourseName"></single-selection-list>
      </div>
    </custom-dialog>
    <!-- 报课计划详情 -->
    <custom-dialog :title="'报课计划详情'"
                   class="refund_dialog defult_dialog no_hidden"
                   :visible.sync="isCoursesPopup"
                   :before-close="closeCoursesPopup">
      <div class="student_courses_popup">
        <div class="popup_box single_shadow">
          <!-- <div class="popup_box_title">
            <span>报课计划详情</span>
            <span class="popup_box_text">
              当前
              <span class="font"
                    :class="courseBillsInfo.Sum_CurrentCount  < 0?'font_red':''">{{Number(courseBillsInfo.Sum_CurrentCount) || 0}}</span>
            </span>
            <span class="popup_box_text">
              已排
              <span class="font"
                    :class="courseBillsInfo.Sum_ArrangedCount  < 0?'font_red':''">{{Number(courseBillsInfo.Sum_ArrangedCount) || 0}}</span>
            </span>
            <span class="popup_box_text">
              可排
              <span class="font"
                    :class="courseBillsInfo.Sum_ToBeArranged  < 0?'font_red':''">{{Number(courseBillsInfo.Sum_ToBeArranged) || 0}}</span>
            </span>
          </div> -->
          <div style="overflow-y:auto;max-height:400px;">
            <!-- <div v-for="(item,index) in courseBillsInfo.DataList"
                 :key="index"
                 class="popup_box_content single_shadow pr">
              <div class="popup_box_content_top clearfix">
                <div class="content_list">
                  <div class="font_gray tl">购课日期:</div>
                  <div class="tl">{{item.GenerateTime}}</div>
                </div>
                <div class="content_list">
                  <div class="font_gray">购买</div>
                  <div class>{{Number(item.NumCount)}}</div>
                </div>
                <div class="content_list">
                  <div class="font_gray">剩余</div>
                  <div class>{{Number(item.CurrentCount)}}</div>
                </div>
                <div class="content_list">
                  <div class="font_gray">待报课</div>
                  <div class="font_red">{{Number(item.ToBePlaned)}}</div>
                </div>
                <div v-if="false"
                     @click.stop="showCoursesProjectDetails(item)"
                     class="student_courses_popup_menu"></div>
              </div>
              <div class="popup_box_content_bottom">
                <div class="content_list"
                     style="flex: none;">
                  <div class="font_gray">报课</div>
                  <div class>{{Number(item.PlanCount)}}</div>
                </div>
                <div class="content_list"
                     style="flex: 1.6;position: relative;right: -0.2rem;">
                  <div class="font_gray">消耗</div>
                  <div class>{{Number(item.SpendCount)}}</div>
                </div>
                <div class="content_list">
                  <div class="font_gray">当前</div>
                  <div class>{{Number(item.Sum_CurrentCount)}}</div>
                </div>
                <div class="content_list">
                  <div class="font_gray">已排</div>
                  <div class>{{Number(item.Sum_ArrangedCount)}}</div>
                </div>
                <div class="content_list">
                  <div class="font_gray">可排</div>
                  <div class>{{Number(item.Sum_ToBeArranged)}}</div>
                </div>
              </div>
              <div class="course_student_attendance_operation module_content_menuList"
                   style="top:32px;right:-54px;left:auto;"
                   v-if="item.showPop">
                <div class="top_bg"></div>
                <drop-down-box :optionJsonList="optionStatus"
                               @clickOptionForAlertDialog="doAfterClick"></drop-down-box>
              </div>
            </div> -->
            <div v-for="(item,index) in courseBillsInfo.DataList"
                 :key="index"
                 class="general_statement_course_box">
              <div class="list_top no_border_bottom clearfix">
                <div class="list_text fl">
                  <div class="text_overflow_hidden">{{selectCourseInfo.MainDemoName}}</div>
                  <div class="text_overflow_hidden font_gray">
                    购买日期
                    <span class="font_black">{{item.GenerateTime}}</span>
                    丨购买
                    <span class="font_black">{{Number(item.NumCount)}}</span>
                    课时丨可用
                    <span class="font_black">{{Number(item.CurrentCount)}}</span>
                    课时
                  </div>
                </div>
                <div @click.stop="showCoursesProject(item)"
                     class="list_btn fl"></div>
              </div>
              <div class="general_statement_course_info">
                <div class="info_list">
                  <div class="font_gray">上课消耗</div>
                  <div>
                    {{Number(item.SpendCount)}}
                    <span class="font_gray">课时</span>
                  </div>
                </div>
                <div class="info_list">
                  <div class="font_gray">当前报课</div>
                  <div>
                    {{Number(item.Sum_CurrentCount)}}
                    <span class="font_gray">课时</span>
                  </div>
                </div>
                <div class="info_list">
                  <div class="font_gray">当前安排</div>
                  <div>
                    {{Number(item.Sum_ArrangedCount)}}
                    <span class="font_gray">课时</span>
                  </div>
                </div>
                <div class="info_list">
                  <div class="font_gray">等待安排</div>
                  <div>
                    {{Number(item.Sum_ToBeArranged)}}
                    <span class="font_gray">课时</span>
                  </div>
                </div>
                <div class="info_list">
                  <div class="font_gray">等待报课</div>
                  <div>
                    {{Number(item.ToBePlaned)}}
                    <span class="font_gray">课时</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!courseBillsInfo.DataList">
            <div class="has_arrange_course">
              <div class="has_arrange_course_content"
                   style="padding:0">
                <div class="has_arrange_course_list">
                  <div class="has_arrange_course_nodata single_shadow">
                    <span>未选择课程</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="courseBillsInfo.DataList && courseBillsInfo.DataList.length == 0">
            <div class="has_arrange_course">
              <div class="has_arrange_course_content"
                   style="padding:0">
                <div class="has_arrange_course_list">
                  <div class="has_arrange_course_nodata single_shadow">
                    <span>亲，当前没有任何数据哦~</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </custom-dialog>
  </div>
</template>
<script>
import {
  getStudentRelatedMsg,
  SelectTheClassType,
  GetTheTicketPlanByApplyClassKey,
  GetTheCourseDetails,
  SelectTeach,
  SearchClass,
  GetTheClassType
} from '../../../API/workbench';
import studentCoursesArranging from './student-courses-arranging.vue';
import searchBox from '../../common/search-box';
import studentList from '../../common/student-list';
import dropDownBox from '../../common/drop-down-box';
export default {
  data () {
    return {
      showBox: false,
      selectedStudentInfo: {}, // 选中学生
      selectProject: {}, // 选中报课计划
      courseNameList: [], // 搜索数据列表
      studentList: [], // 学生列表
      selectCourseInfo: {}, // 选中的课程
      courseBillObj: [],
      otherApplyCourseList: [],
      courseBillsInfo: [], // 报课计划列表
      selectionTitle: '', // 搜索列表标头
      curStudentID: '', //
      curApplyClassKey: '',
      isContent: false,
      isShowSearchInput: false,
      singleSelectionListShow: false,
      searchBoxShow: true,
      isShowCourseNameList: false,
      isLoadSuccess: false,
      isCoursesPopup: false,
      isCoursesPopupSecond: false,
      newScheduleCourseInitJson: {
        name: '目标学生'
      },
      optionStatus: [
        {
          type: 0,
          name: '查看课单',
          eventName: 'clear',
          isShow: true
        },
        {
          type: 1,
          name: '学生报课',
          eventName: 'teacher',
          isShow: true
        },
        {
          type: 2,
          name: '取消',
          eventName: 'cancel',
          isShow: true
        }
      ]
    };
  },
  components: {
    studentCoursesArranging,
    searchBox,
    studentList,
    dropDownBox
  },
  props: {
    callBackFunction: Function
  },
  created () { },
  mounted () { },
  methods: {
    selectStudent (studentID) {
      this.searchBoxShow = false;
      this.selectedStudentInfo.OLAPKey = studentID;
      getStudentRelatedMsg(studentID).then(
        res => {
          if (res.code == 0) {
            this.selectedItem(res.data);
          }
        },
        err => {
          layer.alert(err.msg);
        }
      );
    },
    selectClassName (classID) {
      // this.$nextTick(e => {
      GetTheClassType(classID).then(
        res => {
          if (res.code == 0) {
            this.$refs.studentCoursesArranging.selectCourseInfo = res.data;
          }
        },
        err => {
          layer.alert(err.msg);
        }
      );
      // });
    },
    close () {
      this.showBox = false;
      this.isContent = false;
      this.searchBoxShow = true;
    },
    // 点击搜索 搜索框可见 并查询数据
    chooseClick () {
      this.searchBoxShow = false;
      this.singleSelectionListShow = true;
    },
    alterStudent (item) {
      this.selectedStudentInfo = item;
    },
    closeCoursesPopup () {
      this.isCoursesPopup = false;
    },
    selectedItem (item) {
      this.searchBoxShow = false;
      this.selectedStudentInfo = item;
      this.singleSelectionListShow = false;
      this.isContent = true;
    },
    showCourseList () {
      this.selectionTitle = '选择课程';
      this.loadCourseNameList();
    },
    showTeacherList () {
      this.selectionTitle = '选择教师';
      this.loadTeacherNameList();
    },
    showClassList () {
      this.selectionTitle = '选择班级';
      this.loadClassNameList();
    },
    closeDialogForCourseNameList () {
      this.isShowCourseNameList = false;
    },
    showCoursesProject () {
      this.courseBillsInfo = this.$refs.studentCoursesArranging.courseBillsInfo;
      this.isCoursesPopup = true;
    },
    showCoursesProjectDetails (item) {
      this.$set(item, 'showPop', true);
      this.selectProject = item;
    },
    doAfterClick (option) {
      switch (option.type) {
        case 0:
          this.selectProject.showPop = false;
          break;
        case 1:
          this.selectProject.showPop = false;
          break;
        case 2:
          this.selectProject.showPop = false;
          break;
        default:
          break;
      }
    },
    doAfterSelectedCourseName (item) {
      switch (this.selectionTitle) {
        case '选择课程':
          this.selectCourseInfo = item;
          this.$refs.studentCoursesArranging.selectCourseInfo = item;
          break;
        case '选择教师':
          this.$refs.studentCoursesArranging.type = 1;
          this.$refs.studentCoursesArranging.serachInfo = item;
          break;
        case '选择班级':
          this.$refs.studentCoursesArranging.type = 2;
          this.$refs.studentCoursesArranging.serachInfo = item;
          break;
        default:
          break;
      }
      this.closeDialogForCourseNameList();
    },
    // 获取课名列表
    loadCourseNameList () {
      SelectTheClassType().then(result => {
        this.courseNameList = [];
        result.data.forEach(obj => {
          if (obj.StateKey != 3 && obj.TypeKey == 2) {
            // StateKey:3为停用 TypeKey:0-全部；1-课类；2-课名
            this.courseNameList.push(obj);
          }
        });
        this.$nextTick(() => {
          console.log(this.courseNameList, 'this.courseNameList');
          this.isShowCourseNameList = true;
        });
        // 要根据课名列表来判断课单对应的报课计划是否为单课
        // this.getTheTicketPlanByApplyClassKey();
      });
    },
    // 获取教师列表
    loadTeacherNameList () {
      SelectTeach().then(result => {
        this.courseNameList = [];
        result.data.forEach(obj => {
          this.courseNameList.push(obj);
        });
        this.$nextTick(() => {
          this.isShowCourseNameList = true;
        });
      });
    },
    // 获取班级列表
    loadClassNameList () {
      SearchClass().then(result => {
        this.courseNameList = [];
        result.data.forEach(obj => {
          this.courseNameList.push(obj);
        });
        this.$nextTick(() => {
          this.isShowCourseNameList = true;
        });
      });
    },
    // 返回该学生指定课名的所有可用课单，及其下的报课计划
    getTheTicketPlanByApplyClassKey () {
      GetTheTicketPlanByApplyClassKey(this.curStudentID, this.curApplyClassKey)
        .then(result => {
          result.data.dataList.forEach(obj => {
            obj.changeNum = 0;
            obj.activeCount =
              Number(obj.Sum_PlanCount) - Number(obj.Sum_SpendCount);
            let applyCorIDs = obj.ApplyCorIDsName.split(',');
            if (applyCorIDs.length > 1) {
              obj.isEdit = true;
            } else {
              // 全部
              if (applyCorIDs[0] == 2) {
                obj.isEdit = true;
              } else {
                // 是否是课名
                console.log('applyCorIDs[0]', this.courseNameList);
                let isCourseName = this.courseNameList.find(o => {
                  return applyCorIDs[0] == o.OLAPKey;
                });
                // 找到就是课名
                if (isCourseName) {
                  obj.isEdit = false;
                } else {
                  obj.isEdit = true;
                }
              }
            }
          });
          this.courseBillObj = result.data;
          this.isLoadSuccess = true;
          this.getTheCourseDetails();
        })
        .catch(error => {
          layer.alert(error.msg);
        });
    },
    // 获取指定课单的所有报课计划
    getTheCourseDetails () {
      this.otherApplyCourseList = [];
      this.courseBillObj.dataList.forEach(obj => {
        GetTheCourseDetails(obj.TableID)
          .then(result => {
            let newArr = [];
            let numPlanCount = 0;
            result.data.forEach(obj => {
              let item = {
                PlanCount: obj.PlanCount,
                ApplyClassKey: obj.ApplyClassTypeKey,
                ApplyClassKeyValue: obj.ApplyClassTypeKeyValue
              };
              numPlanCount = numPlanCount + Number(obj.PlanCount);
              newArr.push(item);
            });
            this.otherApplyCourseList.push({
              id: obj.TableID,
              list: newArr,
              numPlanCount: numPlanCount
            });
          })
          .catch(error => {
            layer.alert(error.msg);
          });
      });
    },
    cancelArrangeSuccess () {
      this.$emit('cancelArrangeSuccess');
    },
    arrangeStudentSuccess () {
      if (typeof this.callBackFunction == 'function') {
        this.callBackFunction();
      }
      this.$emit('arrangeStudentSuccess');
    }
  }
};
</script>