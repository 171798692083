<template>
  <div class="form_info_list"
       :class="{form_info_required:required}">
    <div class="form_info_title">
      {{formTitle}}
    </div>
    <div class="form_info_content">
      <seleted-option :dataList="dataList"
                      :dataKey="dataKey"
                      @seletedItem="seletedItem"></seleted-option>
    </div>
  </div>
</template>
<script>
import seletedOption from '../common/seleted-option/index';
export default {
  data () {
    return {

    };
  },
  props: {
    formTitle: String,
    dataKey: [Number, String], // 选中项ID
    readonly: Boolean,  // 是否只读
    required: Boolean, // 是否必填
    dataList: Array  // 列表
  },
  components: {
    seletedOption
  },
  methods: {
    seletedItem (item) {
      if (!this.readonly) {
        this.$emit('seletedItem', item);
      }
    }
  }
};
</script>

<style>
</style>
