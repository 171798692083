<template>
  <!-- 还款欠款详细 -->
  <div class="arrears_repayment_list">
    <div class="arrears_repayment_item"
         v-if="item.TypeKey==2">
      <div class="arrears_amount">
        <div class="arrears_amount_field">
          <!-- TypeKey,2为欠款, 3位还款.  TypeKeyValue文字返回.    -->
          <span>欠款金额</span>
        </div>
        <div class="arrears_amount_value">
          <span class="arrears">－{{DebtAmount}}</span>
        </div>
      </div>
      <div class="arrears_amount_detail">
        <div class="arrears_amount_number">
          <span>{{item.SourceKey}}</span>
        </div>
        <div class="arrears_amount_data">
          <span class="arrears_amount_field">开单日期:</span>
          <span class="arrears_amount_value">{{BillTime}}</span>
        </div>
        <div class="arrears_amount_collection">
          <span class="arrears_amount_field">应收金额:</span>
          <span class="arrears_amount_value arrears_amount_number">{{RecableAmount}}</span>
          <span class="arrears_amount_field  arrears_amount_receipts">实收金额:</span>
          <span class="arrears_amount_value">{{ActualColAmount}}</span>
        </div>
      </div>
    </div>
    <div class="arrears_repayment_item"
         v-else>
      <div class="arrears_amount">
        <div class="arrears_amount_field" v-if="item.TypeKey==3">
          <span>还款金额</span>
        </div>
         <div class="arrears_amount_field" v-else>
          <span>销账金额</span>
        </div>
        <div class="arrears_amount_value">
          <span class="repayment" v-if="item.TypeKey==3">＋{{RefundAmount}}</span>
          <span class="repayment" v-else>＋{{Number(item.DebtDestroy).toFixed(2) || 0.00}}</span>
        </div>
      </div>
      <div class="arrears_amount_detail">
        <div class="arrears_amount_number">
          <span>{{item.SourceKey}}</span>
        </div>
        <div class="arrears_amount_data">
          <span class="arrears_amount_field" v-if="item.TypeKey==3">还款日期:</span>
               <span class="arrears_amount_field"  v-else>销账日期:</span>
          <span class="arrears_amount_value">{{BillTime}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { formatDateToDot } from '../../utils/index.js';
export default {
  name: 'paymentDetailItem',
  data () {
    return {

    };
  },
  props: ['item'],
  computed: {
    DebtAmount () {                  // 实收金额
      return Number(this.item.DebtAmount).toFixed(2) || 0.00;
    },
    RecableAmount () {                  // 实收金额
      return Number(this.item.RecableAmount).toFixed(2) || 0.00;
    },
    RefundAmount () {                  // 实收金额
      return Number(this.item.RefundAmount).toFixed(2) || 0.00;
    },
    ActualColAmount () {                  // 实收金额
      return Number(this.item.ActualColAmount).toFixed(2) || 0.00;
    },
    BillTime () {                       // 开单日期.
      // return Date.new(this.item.BillTime.date).format("yyyy.MM.dd");
      return formatDateToDot(Date.new(this.item.BillTime));
    }
  }
};
</script>

