<template>
  <div>
    <div class="course_statistics_table_search flex">
      <date-range ref="saveDate"
                  :searchObj="searchObj"
                  :fromTitle="'选择日期'"
                  :minDate="vMinDate"
                  @changeDate="changeSaveDate"></date-range>
      <div class="course_statistics_search">
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入姓名、姓名首字母、手机号'"
                                  :clearAll="true"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="searchData"></input-search-contain-btn>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <table-list class="summarizing_list"
                  :key="tableKey"
                  :tableData="vdataReportList"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :tableColumns="tableCourseColumns"
                  :isSearchText="false"
                  :isNeedInnerScroll="false"
                  :defaultSort="defaultSort"
                  :queryParams="searchObj"
                  :footerContent="vFooterContent"
                  @loadTableData="getDataList"></table-list>
    </div>

  </div>
</template>
<script>

import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import { MessageSearchClassAwayForAbnormal } from '../../../../API/workbench';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 15,
        searchText: '',
        orderBy: '',
        type: '',
        sequence: 'DESC',
        QueryID: 0,
        startTime: this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd'), // this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd'),
        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'number-item',
          className: 'table_index_gray'
        },
        {
          label: '发生时间',
          prop: 'SetUpTime',
          width: 120,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'

        },
        {
          label: '录入人',
          prop: 'CreatorKeyValue',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '发生类型',
          prop: 'DeductTypeKeyValue',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '学生',
          prop: 'StudentKeyValue',
          width: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'student',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            click: () => { }
          }
        },
        {
          label: '异常内容',
          prop: 'AbnormalCount',
          width: 120,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '价值金额',
          prop: 'DeductAmount',
          sortable: 'custom',
          width: 100,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '说明',
          prop: 'OccurReasonName',
          sortable: false,
          minWidth: 150,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '异常操作备注',
          prop: 'RemarkSupport',
          sortable: false,
          minWidth: 200,
          width: 'auto',
          align: 'left',
          isShow: true,
          type: 'text-item'
        }

      ],
      dataReportList: []
    };
  },
  components: {
    tableList, inputSearchContainBtn
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.ParentPhoneName = o.ParentPhoneName || '--';
        // let info1 = o.SetUpTime.substring(0, 10) + ',' + o.SetUpTime.substring(11);
        // o.classTimeObj = info1.split(',');
        o.SetUpTime = o.SetUpTime.substring(5);
        let info = o.StudentKeyValue + ',' + o.ParentPhoneName;
        o.studentInfo = info.split(',');
        o.AbnormalCount = o.AbnormalCount.replace('增加', '+');
        o.AbnormalCount = o.AbnormalCount.replace('减少', '-');
        o.DeductAmount = o.DeductAmount ? o.DeductAmount + '元' : '';
        newArr.push(o);
      });
      return newArr;
    },
    vFooterContent () {
      return '共' + this.totalNum + '条数据';
    }
    // vFooterTotal () {
    //   return '异常课消金额' + this.totalAmount + '元';
    // }
  },
  props: {
    teacherList: Array,
    gradeClassList: Array,
    courseNameList: Array
  },
  created () {

  },
  mounted () {
    this.getDataList();
  },
  methods: {
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      // this.searchObj.orderBy = '';
      this.searchObj.type = '';
      // this.searchObj.sequence = 'DESC';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.QueryID = 0;
      this.searchObj.IsExportKey = 0;
      // this.searchObj = {
      //   pageIndex: 0,   // 门店id
      //   pageSize: 15,
      //   searchText: '',
      //   orderBy: '',
      //   type: '',
      //   sequence: 'DESC',
      //   QueryID: 0,
      //   startTime: '',
      //   endTime: '',
      //   IsExportKey: 0
      // };
      this.$refs.saveDate.resetTime();
      this.searchData();
    },
    searchData () {
      this.searchObj.pageIndex = 0;
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        var newData = this.$utils.parseJson(this.searchObj);
        this.searchObj.IsExportKey = 1;
        this.searchObj.pageIndex = 0;
        this.searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '异常明细',
          clist: [
            { title: '发生时间', cName: 'SetUpTime', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '录入人', cName: 'CreatorKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '发生类型', cName: 'DeductTypeKeyValue', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '学生', cName: 'StudentKeyValue', backgroundColor: '', fontColor: '', fomate: 'Date', numberzero: '' },
            { title: '异常内容', cName: 'AbnormalCount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '价值金额', cName: 'DeductAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '说明', cName: 'OccurReasonName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            { title: '异常操作备注', cName: 'RemarkSupport', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' }
          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        MessageSearchClassAwayForAbnormal(this.searchObj, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
          this.searchObj.IsExportKey = 0;
          this.searchObj.pageIndex = newData.pageIndex;
          this.searchObj.pageSize = newData.pageSize;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      if (queryParams) {
        if (queryParams.orderBy == 'studentInfo') {
          queryParams.orderBy = 'StudentKeyValue';
        } else if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'SetUpTime';
        }
        // classTimeObj

        Object.assign(this.searchObj, queryParams);
      }
      MessageSearchClassAwayForAbnormal(this.searchObj).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.totalAbnormalAmount || 0).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      this.seletedMDInfo = rowData;
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.popDialog:
          this.$refs.textareaPop.isAlterMessagePopup = true;
          break;
        case this.$initJson.baseFunctionBtnName.read:
          this.$dialog.open(this, {
            name: '学生档案',
            routerName: this.$route.name,
            moduleName: 'studentFile',
            data: { studentID: rowData.StudentKey }
          });
          // let item = {
          //   moduleName: 'studentFile',
          //   name: '学生档案',
          //   data: { studentID: rowData.StudentKey }
          // };
          // this.$emit('openCustomDialogByFactoryDialog', item);
          break;
        case this.$initJson.baseFunctionBtnName.edit:
          this.isShowChangeStatuDialog = true;
          break;
        default:
          break;
      }
    }
  }
};
</script>