<template>
  <div class="left_list "
       @click.stop="clickCourse"
       :class="{opt:courseItem.isActive}">
    <div class="left_list_title"
         :class="$utils.getTextWidth(courseItem.MainDemoName, 16)>168?'font_dual':''">{{courseItem.MainDemoName||'-'}}</div>
    <!-- <div class="left_list_content clearfix">
      <div class="left_list_content_data">
        <span v-if="courseItem.MTeacherKeyValue">{{courseItem.MTeacherKeyValue}}</span>
        <span class="font_gray"
              v-if="courseItem.MTeacherKeyValue">|</span>
        {{$utils.mAmountType(courseItem.CurrentPeoCount,0)}}<span class="font_gray">学生</span>
        <span class="font_gray">|</span>
        {{$utils.mAmountType(courseItem.RuleCount,1)}}<span class="font_gray">课次/周</span>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: { courseItem: Object },
  computed: {},
  created () { },
  methods: {
    clickCourse () {
      this.$set(this.courseItem, 'isActive', true);
      this.$emit('clickCourse', this.courseItem);
    }
  }
};
</script>