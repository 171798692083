<template>
  <div class="text_box list_text_box"
       :class="{opt:dataInfo.isSelect}"
       @click="clickItem">
    <div v-html="$utils.translationSchoolText(dataInfo.Content)"
         class="text_html"
         :class="dataInfo.className?dataInfo.className:''"></div>
  </div>
</template>
<script>
export default {
  props: {
    dataInfo: Object
  },
  methods: {
    clickItem () {
      this.$emit('selectItem', this.dataInfo);
    }
  }
};
</script>