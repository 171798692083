<template>
  <div class="recording_data_tr">
    <div class="recording_data_td"
         style="width: 125px">
      <div>{{vDate}}</div>
      <div>{{vHour}}</div>
    </div>
    <div class="recording_data_td"
         style="width: 130px">
      <div :title="item.CourseNameKeyValue">{{item.CourseNameKeyValue}}</div>
      <div :title="item.MTeacherKeyValue">{{item.MTeacherKeyValue}}</div>
    </div>
    <div class="recording_data_td"
         style="width:150px">
      <div>{{item.StudentNameKeyValue}}</div>
    </div>
    <div class="recording_data_td tr"
         style="width: 110px">
      <div>{{item.AttenceStatusKeyValue}}</div>
      <div class="pr">
        <div class="icon_blue icon_text"
             v-if="item.UnitKey&&item.UnitKey!=5">
          {{item.UnitKeyValue}}
        </div>
        <div class="icon_yellow icon_text"
             v-else-if="item.IsTrialKey==1">
          试
        </div>
        <div class="icon_red icon_text"
             v-else-if="item.ISClassHourDebtKey==1">
          欠
        </div>
        {{Number(item.ClassCount)}}课时
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  },
  props: ['item'],
  computed: {
    vDate () {
      if (this.item && this.item.BeginClassTime) {
        return this.item.BeginClassTime.substring(0, 10);
      }
    },
    vHour () {
      if (this.item && this.item.BeginClassTime) {
        return this.item.BeginClassTime.substring(10, 16);
      }
    }
  }
};
</script>

