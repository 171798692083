<template>
  <div style="height:100%">
    <div>
      <div class="content_marginTop_30 pr">
        <!-- 内容 -->
        <div class="pr single_shadow"
             style="overflow:hidden">
          <div class="mendian_setting_content form_info_edit">
            <input-text :formTitle="'学生名'"
                        :formPlaceholder="'必填'"
                        :required="true"
                        v-model="studentInfo.MainDemoName"
                        @change="verificationStudentNameAndPhone"></input-text>
            <input-phone :formTitle="'手机号'"
                         :formPlaceholder="'选填'"
                         @change="verificationStudentNameAndPhone"
                         v-model="studentInfo.MobileSupport"></input-phone>
            <form-select :formTitle="'性别'"
                         :required="false"
                         :readonly="false"
                         :dataItem="studentInfo.SexKeyValue"
                         :dataList="sexData"
                         v-model="studentInfo.SexKeyValue"
                         @choseItem="chgSexKey"></form-select>
            <single-select-course-dialog :formTitle="'在读课程'"
                                         :required="false"
                                         v-model="vCourseNameSelectInfo"
                                         :dataSource="dataSourceCourseNameList"
                                         @doAddCoursSuccessEvent="getCourseNameList"></single-select-course-dialog>
            <input-number :min="0"
                          :value="studentInfo.UnitPrice"
                          :decimalPlaces="2"
                          :formTitle="'课程单价'"
                          :placeholder="'选填'"
                          :readonly="false"
                          :required="false"
                          @inputChange="afterChangeUnitPrice"></input-number>
            <input-number :min="0"
                          :value="studentInfo.CurrentCount"
                          :decimalPlaces="1"
                          :formTitle="'剩余课时'"
                          :placeholder="'选填'"
                          :readonly="false"
                          :required="false"
                          @inputChange="afterChangeCurrentCount"></input-number>
            <input-number :min="0"
                          :value="studentInfo.ValueAmount"
                          :decimalPlaces="2"
                          :formTitle="'剩余价值'"
                          :placeholder="'选填'"
                          :readonly="false"
                          :required="false"
                          @inputChange="afterChangeCurrentAmount"></input-number>
            <div class="form_data form_info_line">
              <div class="form_info_field">
                <span>到期日期</span>
              </div>
              <div class="form_info_value form_info_select">
                <el-date-picker type="date"
                                v-model="studentInfo.ExpireTime"
                                :picker-options="expireTimeOption"
                                placeholder="可选"></el-date-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 按钮组 -->
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
  </div>
</template>

<script>
import formSelect from '../../../form-item/form-select';
import {
  SingleMakeUp,
  VerifySingleStudent
} from '../../../../API/workbench.js';
export default {
  data () {
    return {
      studentInfo: {
        MainDemoName: '',
        MobileSupport: '',
        ApplyClassKey: 0,
        ApplyClassKeyValue: '',
        CurrentCount: 0,
        ValueAmount: 0,
        UnitPrice: 0,
        ExpireTime: '',
        SexKey: 3,
        SexKeyValue: '女',
        UnitKey: 5
      },
      sexData: [
        { key: 2, value: '男' },
        { key: 3, value: '女' }
      ],
      dataSourceCourseNameList: [], // 课名列表
      expireTimeOption: {
        disabledDate (date) {
          return date.getTime() + 86400000 <= Date.now();
        }
      }
    };
  },
  props: {
    isChildComponent: {
      type: Boolean,
      default: false
    }
  },
  components: {
    formSelect
  },
  created () {
    this.getCourseNameList();
  },
  methods: {
    // 性别
    chgSexKey (val) {
      this.studentInfo.SexKey = val.key;
      this.studentInfo.SexKeyValue = val.value;
    },
    // 验证姓名号码 是否存在
    verificationStudentNameAndPhone () {
      VerifySingleStudent(this.studentInfo.MainDemoName, this.studentInfo.MobileSupport)
        .then(result => {
          if (result.data.StudentKey > 0) {
            layer.alert('该客户名下此学生已存在');
          }
        }, error => {
          layer.alert(error.msg);
        });
    },
    // 提交前验证
    verificationBeforeSubmit () {
      let flag = true;
      if (this.studentInfo.MainDemoName == '') {
        layer.alert('学生名不能为空');
        flag = false;
      } else if (new RegExp('^[ ]+$').test(this.studentInfo.MainDemoName)) {
        layer.alert('学生名不能为空格');
        flag = false;
      } else if (
        this.studentInfo.MobileSupport.length != 11 &&
        this.studentInfo.MobileSupport
      ) {
        layer.alert('请输入11位的手机号码');
        flag = false;
      } else if (
        this.studentInfo.ApplyClassKey > 0 &&
        !this.studentInfo.CurrentCount
      ) {
        layer.alert('剩余课时不可为0');
        flag = false;
      } else if (
        this.studentInfo.ApplyClassKey > 0 &&
        !this.studentInfo.ExpireTime
      ) {
        layer.alert('到期日期不可为空');
        flag = false;
      } else if (this.studentInfo.ValueAmount >= 100000000) {
        layer.alert('价值须小于一亿');
        flag = false;
      }
      return flag;
    },
    confirmClick (callBack) {
      let result = this.verificationBeforeSubmit();
      if (!result) {
        if (typeof callBack == 'function') {
          callBack();
        }
        return false;
      }
      this.studentInfo.ExpireTime = this.studentInfo.ExpireTime ? this.$utils.formatDateToLine(Date.new(this.studentInfo.ExpireTime)) : '';
      SingleMakeUp(this.studentInfo).then(result => {
        if (result.code == 0) {
          if (!this.isChildComponent) {
            layer.alert('新增成功');
            this.$emit('addSuccess', result.data);
          }
          this.cancelClick(result.data);
        } else {
          layer.alert(result.msg);
        }
      })
        .catch(result => {
          layer.alert(result.msg);
        })
        .finally(() => {
          if (typeof callBack == 'function') {
            callBack();
          }
        });
    },
    cancelClick (data) {
      if (this.isChildComponent) {
        this.$emit('addSuccess', data);
      } else {
        this.$emit('closeDialog', data);
      }
    },
    // 单价
    afterChangeUnitPrice (val) {
      if (!val) {
        val = 0;
      }
      this.studentInfo.UnitPrice = val;
      this.studentInfo.ValueAmount = this.$utils.mMoneyType(this.studentInfo.UnitPrice * this.studentInfo.CurrentCount, 2);
    },
    // 课时
    afterChangeCurrentCount (val) {
      if (!val) {
        val = 0;
      }
      this.studentInfo.CurrentCount = val;
      this.studentInfo.ValueAmount = this.$utils.mMoneyType(this.studentInfo.UnitPrice * this.studentInfo.CurrentCount, 2);
    },
    // 价值
    afterChangeCurrentAmount (val) {
      if (!val) {
        val = 0;
      }
      this.studentInfo.ValueAmount = val;
      if (this.studentInfo.CurrentCount > 0) {
        this.studentInfo.UnitPrice = this.$utils.mMoneyType(this.studentInfo.ValueAmount / this.studentInfo.CurrentCount, 2) || 0;
      }
    },
    // 调用课类课名列表接口
    async getCourseNameList () {
      let resultData = await this.$basicData.loadCourseNameList();
      this.dataSourceCourseNameList = [];
      resultData.forEach(obj => {
        if (obj.StateKey != 3 && obj.TypeKey == 2) {
          this.dataSourceCourseNameList.push(obj);
        }
      });
    }
  },
  computed: {
    vSexKey: {
      get () {
        return {
          key: this.studentInfo.SexKey,
          value: this.studentInfo.SexKeyValue
        };
      },
      set (newValue) {
        this.studentInfo.SexKey = newValue.key;
        this.studentInfo.SexKeyValue = newValue.value;
      }
    },
    vCourseNameSelectInfo: {
      get () {
        return {
          id: this.studentInfo.ApplyClassKey,
          label: this.studentInfo.ApplyClassKeyValue,
          UnitPrice: 0
        };
      },
      set (newValue) {
        this.studentInfo.ApplyClassKeyValue = newValue.label;
        this.studentInfo.ApplyClassKey = newValue.id;
        this.studentInfo.UnitPrice = this.$utils.mMoneyType(newValue.UnitPrice, 2);
      }
    },
    vCurCount: {
      get () {
        return {
          number: this.studentInfo.CurrentCount,
          UnitName: '课时'
        };
      },
      set (newValue) {
        this.studentInfo.CurrentCount = newValue.number;
      }
    },
    vCurValue: {
      get () {
        return {
          number: this.studentInfo.ValueAmount,
          UnitName: '元'
        };
      },
      set (newValue) {
        this.studentInfo.ValueAmount = newValue.number;
      }
    }
  }
};
</script>