<template>
  <div>
    <!-- 课件管理头部 -->
    <div class="courseware_manage_head_view"
         style="max-width: 1920px !important;margin: auto;"
         :class="$store.getters.SaaSClientInfo.EditionKey==6?'courseware_manage_head_views':''">
      <div class="courseware_manage_head_btn btn_hover_bg_blue"
           @click="doReturn">返回</div>
      <div class="coursewar_head_box">
        <div class="coursewar_head_tltle dp"
             style="letter-spacing: 0.5px;">
          <span>{{maxCoursewareInfo.MainDemoName}}</span>
          <div class="list_img_btn"
               @mouseenter="isShowOperateMenu=true"
               @mouseleave="isShowOperateMenu=false">
            <div class="list_img_ul"
                 v-if="isShowOperateMenu">
              <!-- 大课包按钮显示 -->
              <div class="list_img_menu type_02"
                   @click="editbigCoursewareInfo">
                编辑
              </div>

            </div>
          </div>
        </div>
        <div class="">
          <span class="header_option_box_item"
                v-if="bigcoursewareType=='ZZ'"
                style="letter-spacing: 0.5px;">
            发布课件
            <span class="font_black">{{maxCoursewareInfo.CoursewareSum||0}}</span>
          </span>
          <span class="header_option_box_item"
                style="letter-spacing: 0.5px;">
            占用容量
            <span class="font_black">{{this.$utils.capacityFormate(Number(maxCoursewareInfo.OccupiedCapacity))}}</span>
          </span>
          <!-- <span class="coursewar_head_num dp"
                v-if="bigcoursewareType=='ZZ'">课件：{{maxCoursewareInfo.CoursewareSum||0}}</span>
          <span class="capacity dp">&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;容量：{{this.$utils.capacityFormate(Number(maxCoursewareInfo.OccupiedCapacity))}}</span> -->

          <!-- <span class="status_type dp"
                v-if="bigcoursewareType == 'ZZ'&&maxCoursewareInfo.ToBeReviewedNum>0">待审核&nbsp; {{maxCoursewareInfo.ToBeReviewedNum}}</span>
          <span class="status_type dp"
                v-if="bigcoursewareType == 'ZZ'&&maxCoursewareInfo.ToBeReleasedNum>0">待发布&nbsp;{{maxCoursewareInfo.ToBeReleasedNum}}</span>
          <span class="status_type_red dp"
                v-if="bigcoursewareType == 'ZZ'&&maxCoursewareInfo.NotReleaseNum>0">未发布&nbsp;{{maxCoursewareInfo.NotReleaseNum}}</span>
          <div class="courseware_manage_right max_courseware"
              v-if="bigcoursewareType=='SQ'"> -->
        </div>
        <authorization-info :authorizationInfo="maxCoursewareInfo.Authorize"
                            :maxCoursewareID="maxCoursewareInfo.ID"
                            @afterSuccesAuth="afterSuccesAuth"></authorization-info>
      </div>
      <div class="el-button  buttom_sq courseware_icon_02"
           @click="doRecycle"
           v-if="bigcoursewareType=='ZZ'">回收站</div>
    </div>

  </div>
</template>
<script>
import authorizationInfo from '../../../courseware-management/courseware-list/authorization-info';
export default {
  data () {
    return {
      isShowOperateMenu: false
    };
  },
  components: {
    authorizationInfo
  },
  props: {
    maxCoursewareInfo: Object, // 大课包信息
    coursewareNum: Number,
    bigcoursewareType: String,
    showEdit: Boolean
  },
  watch: {

  },
  created () {
    console.log(this.maxCoursewareInfo, 'dataInfo');
  },
  methods: {
    doReturn () {
      this.$emit('doReturn', { key: 'return' });
    },
    doRecycle () {
      this.$emit('doRecycle', { name: '', moduleName: 'recycleBin' });
    },
    // 授权列表操作成功回调
    afterSuccesAuth () {
      this.$emit('selectCoursewareType', { key: this.type }, this.coursewareInfo);
    },
    editbigCoursewareInfo () {
      this.$emit('editbigCoursewareInfo', this.coursewareInfo);
    }
  }
};
</script>

<style scoped>
.courseware_manage_head_view {
  width: 100%;
  height: 92px;
  background: #ffffff;
  /* line-height: 92px; */
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
}
.courseware_manage_head_views {
  padding: 0 88px;
}
.courseware_manage_head_btn {
  width: 68px;
  height: 37px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  text-align: center;
  line-height: 37px;
  /* margin: 10px 34px 0px 0px; */
  cursor: pointer;
}
.coursewar_head_box {
  width: 90%;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.coursewar_head_tltle {
  font-weight: 700;
  font-size: 18px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-top: -5px;
}
.coursewar_head_num {
  color: #666666;
}
.status_type {
  width: 64px;
  height: 20px;
  background: rgb(255, 238, 208);
  border-radius: 3px;
  color: #fea409;
  line-height: 17px;
  font-family: 'Alibaba';
  font-size: 12px;
  padding: 2px 3px;
  /* margin-right: 10px; */
  margin-top: 16px;
  margin-left: 20px;
  text-align: center;
}
.status_type_red {
  width: 58px;
  height: 20px;
  background: rgba(248, 213, 213, 1);
  color: rgba(217, 45, 45, 1);
  border-radius: 3px;
  line-height: 17px;
  font-family: 'Alibaba';
  font-size: 12px;
  padding: 2px 3px;
  /* margin-right: 10px; */
  margin-top: 16px;
  margin-left: 20px;
  text-align: center;
}
.capacity {
  padding-right: 10px;
  padding-left: 5px;
  color: #666666;
}
.dp {
  display: block;
  float: left;
}
.courseware_icon_02,
.courseware_icon_03 {
  padding-left: 45px;
  border: none !important;
}
.courseware_icon_02:hover,
.courseware_icon_03:hover {
  /* color: #b9ddf5 !important; */
  background: none !important;
}
.courseware_icon_02::before {
  content: '';
  position: absolute;
  width: 28px;
  height: 28px;
  left: 10px;
  top: 3px;
  background: url(../../../../../public/image/courseware_icon_02.png) center
    no-repeat;
  background-size: 23px;
}

.header_option_box_item {
  display: inline-block;
  border-radius: 3px;
  background: rgba(217, 223, 228, 0.4);
  color: #999;
  font-size: 14px;
  height: 24px;
  text-align: center;
  margin-right: 16px;
  padding: 0 10px;
  line-height: 24px;
}

.coursewar_head_box .list_img_btn {
  position: relative;
  right: 0px;
  top: 10px;
  margin-left: 10px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: url(../../../../../public/image/courseware_icon_more_g.png)
    no-repeat;
  background-size: 30px;
  z-index: 1;
  display: inline-block;
}
.coursewar_head_box .list_img_btn .list_img_ul {
  position: absolute;
  right: 8px;
  top: 24px;
  width: 86px;
  background-color: #fff;
  padding-left: 0px;
  text-align: center;
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 6px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.02), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  z-index: 99;
}
.coursewar_head_box .list_img_ul .list_img_menu {
  padding-left: 13px;
  text-align: center;
}

.coursewar_head_box .list_img_ul {
  position: absolute;
  right: 6px;
  top: 24px;
  width: 86px;
  background-color: #fff;
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 6px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.02), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  z-index: 99;
}

.coursewar_head_box .list_img_menu {
  font-size: 14px;
  color: #666;
  padding-left: 36px;
  line-height: 34px;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 13px center;
}
.coursewar_head_box .list_img_menu:hover {
  color: #3498db;
}

.coursewar_head_box .list_img_menu.type_02 {
  background-image: url(../../../../../public/image/courseware_menu_list02.png);
}

.coursewar_head_box .list_img_menu.type_02:hover {
  background-image: url(../../../../../public/image/courseware_menu_list02_blue.png);
}
</style>

