<template>
  <div class="clew_follow_form">
    <div class="form_info_student_box">
      <span style="font-size:20px;"
            class="font_black">{{studentItem.StudentKeyValue}}</span>
      <span style="font-size:12px;"
            class="font_gray">{{studentItem.MobilePhone}}</span>
    </div>
    <div class="register_student form_info_edit">
      <div class="bg_gray minHeight">
        <!-- 学生信息 -->

        <div class="form_info_box bg_white">
          <div class="up_sell_record_from_title from_textarea_title">
            节点
          </div>
          <div class="flex_row"
               style="width:100%;height:45px;line-height:30px;">
            <div class="flex_row_item"
                 :class="dataInfo.CurrentNodeKey&&dataInfo.CurrentNodeKey>=2?'isActive':''"
                 @click.stop="changeCurrentNodeKey(2)">
              <div>已联系</div>
            </div>
            <div class="right_lll"
                 :class="dataInfo.CurrentNodeKey&&dataInfo.CurrentNodeKey>=3?'isActive':''"></div>
            <div class="flex_row_item"
                 :class="dataInfo.CurrentNodeKey&&dataInfo.CurrentNodeKey>=3?'isActive':''"
                 @click.stop="changeCurrentNodeKey(3)">
              <div>已诺访</div>
              <div class="flex_row_item_date font_gray"
                   v-if="dataInfo.CurrentNodeKey==3"
                   @click.stop="showDateForm(3)">{{seletedDate||'登记日期'}}</div>
            </div>
            <div class="right_lll"
                 :class="dataInfo.CurrentNodeKey&&dataInfo.CurrentNodeKey>=4?'isActive':''"></div>
            <div class="flex_row_item"
                 :class="dataInfo.CurrentNodeKey&&dataInfo.CurrentNodeKey>=4?'isActive':''"
                 @click.stop="changeCurrentNodeKey(4)">
              <div>约试课</div>
              <div class="flex_row_item_date font_gray"
                   v-if="dataInfo.CurrentNodeKey==4"
                   @click.stop="showDateForm(4)">{{seletedDate||'登记日期'}}</div>
            </div>
            <div class="right_lll"
                 :class="dataInfo.CurrentNodeKey&&dataInfo.CurrentNodeKey>=5?'isActive':''"></div>
            <div class="flex_row_item"
                 :class="dataInfo.CurrentNodeKey&&dataInfo.CurrentNodeKey>=5?'isActive':''"
                 @click.stop="changeCurrentNodeKey(5)">
              <div>已试课</div>
            </div>
            <div class="right_lll"
                 :class="dataInfo.CurrentNodeKey&&dataInfo.CurrentNodeKey==6?'isActive':''"></div>
            <div class="flex_row_item"
                 :class="dataInfo.CurrentNodeKey==6?'isActive':''"
                 @click.stop="changeCurrentNodeKey(6)">
              <div>已报名</div>
            </div>
          </div>
          <div class="up_sell_record_from_title from_textarea_title">
            标签
          </div>
          <input-option-btn-text v-for="(item,key) in opionList"
                                 :key="key"
                                 :formTitle="item[0].MainDemoName"
                                 :readonly="false"
                                 :required="false"
                                 :selectList="item"
                                 :indexKey="key+1"
                                 :selectedInfo="vIntentionInfo"
                                 @change="chgIntentionType">
          </input-option-btn-text>
          <!-- <input-option-text :formTitle="'成交时间:'"
                        :readonly="false"
                        :required="false"
                        :selectList="franchiseList"
                        :selectedInfo="vFranchiseInfo"
                        @change="chgIntentionType">
                </input-option-text> -->
          <div class="from_textarea_box">
            <div class="up_sell_record_from_title from_textarea_title">
              内容
              <span style="color: #666;float: right;cursor: pointer;font-size:12px"
                    @click="isShowRecordList=true">
                共<span class="font_blue">{{this.recordList.length}}</span>条>
              </span>
            </div>
            <div class="from_textarea_content">
              <textarea v-model="dataInfo.FollowContent"
                        placeholder="请输入跟单内容"
                        maxlength="600"></textarea>
            </div>
          </div>

          <div class="upload_img_content">
            <div class=""
                 style="width:90px">
              <span class="upload_btn font_blue">
                上传
                <!-- 上传 -->
                <choose-img-mutil class="upload_box"
                                  :maxNum="1"
                                  @upLoadImgReject="doAfterUpLoadImgSuccess"></choose-img-mutil>
              </span>
              <div class="font_gray"
                   style="margin:5px">
                限3张图片
              </div>
            </div>
            <div class="form_info_value flex_row">
              <div class="upload_img_content_imgitem"
                   v-for="(item,key) in dataInfo.FollowUpUrlList"
                   :key="key"
                   @click="showPreview(item)">
                <img :src="$store.getters.CDNURL+item.FollowUpUrl"
                     :onerror="defaultImg"
                     alt="">
                <div class="delete_the_photo"
                     @click.stop="deleteImg(key)">
                  <img src="../../../../public/image/delete_the_photo.png" />
                </div>
                <div>{{key+1}}/3</div>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>
    <!-- 提交 -->
    <div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
    <custom-dialog title="选择登记日期"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowDate"
                   :before-close="closeFrom">
      <change-date :seletedDate="seletedDate"
                   @close="closeFrom"
                   @afterSuccess="changeDateAfter"></change-date>
    </custom-dialog>
    <custom-dialog title="跟单记录流水"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowRecordList"
                   :before-close="closeFrom">
      <clew-follow-record-list :studentItem="studentItem"
                               :StudentThreadKey="dataInfo.StudentThreadKey"
                               @close="closeFrom"></clew-follow-record-list>
    </custom-dialog>
    <custom-dialog :title="'预览图片'"
                   :visible.sync="isPreviewImg"
                   :before-close="closePreviewImg">
      <div class="home_work_img">
        <preview-img :imgList="vimgList"
                     :showIndex="dataInfo.index"
                     :previewText="dataInfo.Content"
                     @onClosePreviewImg="closePreviewImg"></preview-img>
      </div>
    </custom-dialog>
  </div>

</template>
<script>
import changeDate from './change-date'
import clewFollowRecordList from './clew-follow-record-list'
import previewImg from '../../home-school-communication/common-component/preview-img/index';

import { AddStudentThreadFollowUp, GetFollowUpDetail, GetLabelGroupList, GetFollowUpList } from "../../../API/workbench";

// 登记客户
export default {
  data () {
    return {
      isPreviewImg: false,
      isShowDate: false,
      seletedDate: '',
      dataInfo: {
        "StudentThreadKey": "",//"线索ID",								
        "CurrentNodeKey": "1",//"节点 1-未联系;2-已联系;3-已诺访;4-已约课;5-已试课;6-已报名",						
        "CurrentNodeKeyValue": "未联系",//"节点",					
        "CurrentLabelIDs": "",//"标签IDs 1,2,3",						
        "CurrentLabelNames": "",//"标签名称集合 高意向,低意向",		
        "FollowContent": "",//"标签内容",		
        "PromiseVisitTime": "",//"诺访登记日期",		
        "TrialLessonTime": "",//"约试课登记日期",		
        "SignUpTime": "",//"已报名登记日期",		
        "FollowUpUrlList": [
          // {"FollowUpUrl":"跟进图片地址"},
        ],
      },
      opionList: [],
      isSubmit: true,
      seletdlist: [],
      recordList: [],
      isShowRecordList: false,
    };
  },
  props: {
    studentItem: Object
  },
  components: {
    changeDate, clewFollowRecordList, previewImg
  },
  computed: {
    vimgList () {
      let list = [];
      this.dataInfo.FollowUpUrlList.forEach(o => {
        list.push({ imgUrl: o.FollowUpUrl, id: o.OLAPKey });
      });
      return list;
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../public/image/no_logo@2x.png') + '"';
    },
    // 意向级别
    vIntentionInfo () {
      return {
        id: Number(this.dataInfo.CurrentLabelIDs),
        name: this.dataInfo.CurrentLabelNames,
      }
    },
    vFranchiseInfo () {
      return {
        id: Number(this.dataInfo.CurrentLabelIDs),
        name: this.dataInfo.CurrentLabelNames,
      }
    },
  },
  created () {
    // this.dataInfo = this.$utils.parseJson(newCustomerInfo);

    if (this.studentItem.FollowUpKey > 0) {
      this.getDataInfo(this.studentItem.FollowUpKey)
    } else {
      this.getList()
      this.dataInfo.StudentThreadKey = this.studentItem.OLAPKey
    }
    this.getRecordList(this.studentItem.OLAPKey)
  },
  mounted () { },
  watch: {},
  methods: {
    showPreview (item) {
      this.PhotoUrl = this.$store.getters.CDNURL + item.AttachUrl;
      console.log(item, 'aaa');
      this.isPreviewImg = true;
      let locaIndex = this.dataInfo.FollowUpUrlList.findIndex(o => {
        return o.OLAPKey == item.OLAPKey;
      });
      if (locaIndex >= 0) {
        this.dataInfo.Content = item.Title;
        this.dataInfo.index = locaIndex;
      } else {
        this.dataInfo.index = 0;
      }
    },
    // 关闭预览图片
    closePreviewImg () {
      this.isPreviewImg = false;
      this.dataInfo.index = 0;
    },
    getRecordList () {
      GetFollowUpList(this.studentItem.OLAPKey).then(result => {
        this.recordList = result.data
        console.log("GetFollowUpList", result.data)
      })
    },
    changeDateAfter (date) {
      console.log("changeDateAfter", date)
      if (this.dataInfo.CurrentNodeKey == 3) {
        this.seletedDate = date
      } else {
        this.seletedDate = date
      }
      this.closeFrom()
    },
    showDateForm (type) {
      // if(type==3){
      //   this.dataInfo.PromiseVisitTime=type
      // }else{
      //   this.dataInfo.TrialLessonTime=type
      // }
      this.isShowDate = true
    },
    closeFrom () {
      this.isShowDate = false
      this.isShowRecordList = false
    },
    changeCurrentNodeKey (val) {
      this.dataInfo.CurrentNodeKey = val
      //"节点 1-未联系;2-已联系;3-已诺访;4-约试课;5-已试课;6-已报名",
      if (val == 2) {
        this.dataInfo.CurrentNodeKeyValue = '已联系'
      } else if (val == 3) {
        this.dataInfo.CurrentNodeKeyValue = '已诺访'
      } else if (val == 4) {
        this.dataInfo.CurrentNodeKeyValue = '约试课'
      } else if (val == 5) {
        this.dataInfo.CurrentNodeKeyValue = '已试课'
      } else if (val == 6) {
        this.dataInfo.CurrentNodeKeyValue = '已报名'
      } else {
        this.dataInfo.CurrentNodeKeyValue = '未联系'
      }
      console.log('changeCurrentNodeKey', this.dataInfo)
    },
    chgIntentionType (item) {

      console.log(this.seletdlist, 'chgIntentionType', item)
    },
    getDataInfo (FollowUpKey) {
      GetFollowUpDetail(FollowUpKey).then(result => {
        console.log('GetFollowUpDetail', result.data)
        this.dataInfo = result.data
        this.dataInfo.PromiseVisitTime = this.dataInfo.PromiseVisitTime || ''
        this.dataInfo.TrialLessonTime = this.dataInfo.TrialLessonTime || ''
        // if(this.dataInfo.CurrentNodeKey==3){
        //   this.seletedDate=this.dataInfo.PromiseVisitTime.toString()
        // }else{
        //   this.seletedDate=this.dataInfo.TrialLessonTime.toString()
        // }
        this.getList(() => {
          if (this.dataInfo.CurrentLabelIDs) {
            let newArr = this.dataInfo.CurrentLabelIDs.split('、')
            newArr.forEach(o => {
              this.opionList.forEach(p => {
                let locaItem = p.find(item => {
                  return o == item.LabelKey
                })
                if (locaItem) {
                  locaItem.isActive = true
                }

              })
            })
          }
        })

      })
    },
    getList (callBack) {
      GetLabelGroupList().then(result => {
        result.data.forEach(element => {
          element.forEach(o => {
            o.isActive = false
          })
        });
        this.opionList = result.data
        console.log('this.opionList=', this.opionList)
        if (callBack) {
          callBack()
        }
        console.log("GetLabelGroupList", result.data)
      })
    },
    deleteImg (key) {
      layer.confirm('是否删除该图片', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              this.dataInfo.FollowUpUrlList.splice(key, 1)
              layer.close();
            }
          }
        ]
      });

      console.log("deleteImg", key)
    },
    // 新增
    doAfterUpLoadImgSuccess (imgUrl, callback) {
      console.log('doAfterUpLoadImgSuccess', imgUrl);
      if (this.dataInfo.FollowUpUrlList.length == 3) {
        layer.alert("最多只能上传三张图片")
        return false
      } else {
        let obj = {
          FollowUpUrl: imgUrl
        }
        this.dataInfo.FollowUpUrlList.push(obj)
      }

    },
    // 确定执行
    confirmClick (callBack) {
      this.dataInfo.CurrentLabelIDs = ''
      this.dataInfo.CurrentLabelNames = ''
      this.opionList.forEach(o => {
        o.forEach(p => {
          if (p.isActive) {
            this.dataInfo.CurrentLabelIDs = this.dataInfo.CurrentLabelIDs + p.LabelKey + '、'
            this.dataInfo.CurrentLabelNames = this.dataInfo.CurrentLabelNames + p.LabelKeyValue + '、'
          }
        })
      })
      if (this.dataInfo.CurrentLabelIDs.length > 0) {
        this.dataInfo.CurrentLabelIDs = this.dataInfo.CurrentLabelIDs.substring(0, this.dataInfo.CurrentLabelIDs.length - 1)
        this.dataInfo.CurrentLabelNames = this.dataInfo.CurrentLabelNames.substring(0, this.dataInfo.CurrentLabelNames.length - 1)
      }
      console.log("确定执行", this.dataInfo)
      const res = this.validata(callBack);
      if (!res) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (this.isSubmit) {
        // 防止接口请求太慢导致重复提交
        this.confirmData(callBack);
      }
    },
    // 验证客户信息
    validata (callBack) {
      let flag = true;

      if (!this.dataInfo.FollowContent) {
        layer.alert("请输入跟单内容");
        flag = false;
      }
      return flag;
    },
    // 提交
    confirmData (callBack) {
      this.addConfirm(this.dataInfo, callBack);
    },

    // 新增操作
    addConfirm (dataInfo, callBack) {
      console.log(this.dataInfo.CurrentNodeKey, "this.seletedDate", this.seletedDate, this.dataInfo.PromiseVisitTime)
      if (this.dataInfo.CurrentNodeKey == 3) {
        // this.dataInfo.TrialLessonTime=''
        //  if(this.seletedDate==this.dataInfo.PromiseVisitTime){
        //   this.dataInfo.PromiseVisitTime=''
        //  }else{
        this.dataInfo.PromiseVisitTime = this.seletedDate
        //  }

      } else {
        // this.dataInfo.TrialLessonTime=''
        // if(this.seletedDate==this.dataInfo.TrialLessonTime){
        //   this.dataInfo.TrialLessonTime=''
        // }else{
        this.dataInfo.TrialLessonTime = this.seletedDate
        // }
      }
      AddStudentThreadFollowUp(dataInfo)
        .then(
          result => {
            layer.alert("新增成功");
            this.$emit("afterSuccess", result.data);
            this.cancelClick(result.data);
          },
          err => {
            layer.alert(err.msg);
          }
        )
        .finally(() => {
          this.isSubmit = true;
          if (callBack) {
            callBack();
          }
        });
    },
    // 取消
    cancelClick (data) {
      this.$emit("close");
    }
  }
};
</script>
<style scoped>
.form_info_student_box {
  height: 60px;
  line-height: 50px;
  border-bottom: 1px solid #ececec;
  padding: 5px 20px;
}

.upload_btn {
  display: inline-block;
  line-height: 36px;
  width: 76px;
  height: 36px;
  border-radius: 4.6769232749938965px;
  text-align: center;
  padding-left: 20px;
  font-size: 14px;
  background: #f1f3f9;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.upload_btn::before {
  content: '';
  display: inline-block;
  position: absolute;
  right: 44px;
  top: 8px;
  width: 20px;
  height: 20px;
  background: url('../../../../public/image/Vector_upload.png') center no-repeat;
  background-size: 16px;
}
.upload_input {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.upload_img_content {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  height: 109px;
  margin-bottom: 15px;
}
.flex_row {
  display: flex;
  flex-direction: row;
}
.upload_img_content_imgitem {
  width: 85px;
  height: 109px;
  margin-right: 10px;
  text-align: center;
  position: relative;
}
.upload_img_content_imgitem img {
  width: 85px;
  height: 85px;
  object-fit: contain;
}
.delete_the_photo img {
  position: absolute;
  width: 30px;
  height: 30px;
  float: right;
  top: 0;
  right: 0;
}
.flex_row_item {
  width: 66px;
  height: 30px;
  border-radius: 4px;
  text-align: center;
  line-height: 30px;
  border: 1.25px solid #ececec;
  position: relative;
  cursor: pointer;
}
.flex_row_item.isActive {
  background: #3498db33;
  color: #3498db;
}
.right_lll {
  margin: 0 2px;
  width: 10px;
  background: url('../../../../public/image/page_right.png') center no-repeat;
  background-size: 7px;
  background-position-y: 10px;
}
.right_lll.isActive {
  margin: 0 2px;
  width: 10px;
  background: url('../../../../public/image/blue_right.png') center no-repeat;
  background-size: 7px;
  background-position-y: 10px;
}
.flex_row_item_date {
  position: absolute;
  min-width: 54px;
  height: 20px;
  line-height: 20px;
  border-radius: 4px;
  font-size: 12px;
  border: 1.25px solid #ececec;
  margin-top: 3px;
  cursor: pointer;
  white-space: nowrap;
  padding: 0px 2px;
}
.up_sell_record_from_title {
  font-size: 16px !important;
}
.clew_follow_form >>> .form_info_field {
  text-overflow: ellipsis;
  width: 58px;
  overflow: hidden;
  text-wrap: nowrap;
}
</style>