<template>
  <div class="particulars">
    <!-- 头部 -->
    <particularHeader ref="particularHeader"
                      :TeacherID="TeacherID"
                      :particularData="CoursewarePackage.particularData"
                      :CoursewarePackageData="CoursewarePackageData"
                      :Source="CoursewarePackage.Source"
                      :role="role"
                      :TMainDemoName="TMainDemoName"
                      @getCoursewarePackageDetails="getCoursewarePackageDetails"
                      :maxCoursewarePackage="maxCoursewarePackage"
                      @getPrincipalWorkbenchGetCoursewareList="getPrincipalWorkbenchGetCoursewareList"
                      @setElSelectID="setElSelectID"
                      :CoursewarePackage="CoursewarePackage"
                      @cutCourseware="cutCourseware"
                      :TypeKey='TypeKey'>
    </particularHeader>
    <!-- 底部 -->
    <div style="margin-bottom: 55px;">
      <particularList @getCoursewarePackageDetails="getCoursewarePackageDetails"
                      @getPrincipalWorkbenchGetCoursewareList="getPrincipalWorkbenchGetCoursewareList"
                      :TeacherID="TeacherID"
                      :maxCoursewarePackage="maxCoursewarePackage"
                      :CoursewarePackage="CoursewarePackage"
                      :role="role"
                      :Source="CoursewarePackage.Source"
                      v-for="(item, index) in particulars"
                      :key="index"
                      :item="item"></particularList>
    </div>
  </div>
</template>

<script>
import particularHeader from './particularHeader.vue';
import particularList from './particularList.vue';
import {
  CWSGetCoursewarePackageDetails,
  CWSTeacherWorkbenchGetCoursewarePackageDetails,
  CWSPrincipalWorkbenchGetCoursewareList,
  CWSGetCoursewareForGrade
} from '../../../../API/workbench';
export default {
  name: 'particulars',
  components: {
    particularHeader,
    particularList
  },
  props: {
    // CoursewarePackageKey: [Number, String]
    CoursewarePackage: {
      type: Object,
      default: () => {
        return {
          CourseCount: [Number, String],
          CourseTimeCount: [Number, String],
          CoursewareNum: [Number, String],
          OLAPKey: [Number, String],
          MainDemoName: String,
          particularData: Object,
          TeacherKey: [Number, String],
          ParentKeyValue: String,
          Source: [String, Number]
        };
      }
    }
  },
  data () {
    return {
      TypeKey: '',
      particulars: [],
      CoursewarePackageData: {
        OLAPKey: this.CoursewarePackage.OLAPKey || '', // 子课包ID
        MainDemoName: this.CoursewarePackage.ParentKeyValue || this.CoursewarePackage.MainDemoName || '' // 课件详情标题
      },
      TMainDemoName: '',
      tipContont: '',
      role: this.vRoleKey(),
      maxCoursewarePackage: {}, // 子课包详情
      deviceCode: '', // this.$zxClient.GetMCode() // 设备码
      TeacherID: this.CoursewarePackage.TeacherKey || '' // this.$store.getters.token.UserID || '' ,// 老师id
    };
  },

  created () {
    console.log('调用create');
    if (this.$zxClient && this.$zxClient.IsClient) {
      this.deviceCode = this.$zxClient.GetMCode();
    } else {
      // this.tipContont = '非桌面端';
      // this.dataInfo.DeviceCode = '340BC40DC769411B'
    }
    if (this.CoursewarePackage.OLAPKey && Number(this.CoursewarePackage.OLAPKey) > 0) {
      this.getCoursewarePackageDetails(this.CoursewarePackage.OLAPKey, this.deviceCode, this.TeacherID);
    }
    console.log(this.CoursewarePackage, '传入的CoursewarePackage');
    this.vRoleKey();
    // console.log(this.$zxClient.GetMCode(),'设备码');
  },
  mounted () {
    console.log('调用mounted');
    this.$refs.particularHeader.getDateRangeCoursewareStat(this.$utils.getCurrentMonthFirst(), this.$utils
      .getCurrentMonthLast(), this.CoursewarePackage.OLAPKey);
    this.getPrincipalWorkbenchGetCoursewareList();
    this.$dialog.register(this,
      [
        'cwsTeacherAuthCw', 'cwsGoToClass', 'classStatistics', 'classStatisticsTeacher', 'coursewareUseStatlist', 'singleSelectionList', 'cwsApplyAuth', 'WebvClassStatistics', 'courseAuthQrcode'
      ]);
  },
  activated () {
    console.log('调用activated');
    this.$refs.particularHeader.getDateRangeCoursewareStat(this.$utils.getCurrentMonthFirst(), this.$utils
      .getCurrentMonthLast(), this.CoursewarePackage.OLAPKey);
    this.getPrincipalWorkbenchGetCoursewareList();
  },
  methods: {
    // 获取子课件包详情
    getCoursewarePackageDetails (OLAPKey, deviceCode, TeacherID) {
      console.log(this.TeacherID, '老师ID');
      // if (!this.$zxClient.IsClient) {
      //   layer.alert('请用桌面端打开程序')
      // } else {
      if (this.role) {
        CWSGetCoursewarePackageDetails(OLAPKey, deviceCode, TeacherID).then(
          result => {
            this.maxCoursewarePackage = result.data;
            let geoInfo = {
              Longitude: result.data.Longitude || '',
              Latitude: result.data.Latitude || '',
              Accuracy: result.data.Accuracy || '',
              PositioningOffset: result.data.PositioningOffset || ''
            };
            console.log('geoInfo', geoInfo);
            console.log('result.data', result.data);
            this.$store.commit('setGeoInfo', geoInfo);
            if (result.data.CoursewarePackageList.length > 0) {
              // 有目录
              if (result.data.BigCoursewarePackageKey != result.data.CoursewarePackageList[0].ParentKey) {
                this.TypeKey = 1;
              } else {
                // 没有目录
                this.TypeKey = 0;
              }
            }

            console.log('校长-获取子课件包详情index页面', result.data);
          });
      } else {
        CWSTeacherWorkbenchGetCoursewarePackageDetails(OLAPKey, deviceCode, TeacherID).then(
          result => {
            this.maxCoursewarePackage = result.data;
            let geoInfo = {
              Longitude: result.data.Longitude || '',
              Latitude: result.data.Latitude || '',
              Accuracy: result.data.Accuracy || '',
              PositioningOffset: result.data.PositioningOffset || ''
            };
            this.$store.commit('setGeoInfo', geoInfo);
            if (result.data.BigCoursewarePackageKey != result.data.CoursewarePackageList[0].ParentKey) {
              this.TypeKey = 1;
            } else {
              this.TypeKey = 0;
            }
            console.log('老师-获取子课件包详情index页面', result.data);
          });
      }
      console.log(6545665165);
      this.$emit('afterSuccess');
      // }
    },
    cutCourseware (data, startDate, endDate) {
      console.log(data, '切换后的子课件');
      this.CoursewarePackageData.MainDemoName = data.MainDemoName;
      this.CoursewarePackageData.OLAPKey = data.OLAPKey;
      this.$refs.particularHeader.getDateRangeCoursewareStat(startDate, endDate, data.OLAPKey);
      this.getCoursewarePackageDetails(data.OLAPKey, this.deviceCode, this.TeacherID);
      this.getPrincipalWorkbenchGetCoursewareList();
    },
    setElSelectID (item, startDate, endDate) {
      console.log(item, '切换后的子课件');
      this.CoursewarePackageData.OLAPKey = item.OLAPKey;
      this.$refs.particularHeader.getDateRangeCoursewareStat(startDate, endDate, item.OLAPKey);
      this.getCoursewarePackageDetails(item.OLAPKey, this.deviceCode, this.TeacherID);
      this.getPrincipalWorkbenchGetCoursewareList();
    },
    //   获取子课包课件list
    getPrincipalWorkbenchGetCoursewareList () {
      // if (!this.$zxClient.IsClient) {
      //   layer.alert('请用桌面端打开程序')
      // } else {
      if (this.role) {
        CWSPrincipalWorkbenchGetCoursewareList(this.CoursewarePackageData.OLAPKey, this.TeacherID).then(
          result => {
            this.particulars = result.data;
            this.particulars.forEach(o => {
              o.RecentlyClassTime = o.RecentlyClassTime.split('.')[0].split(':')[0] + ':' + o.RecentlyClassTime.split('.')[0].split(':')[1];
              o.PrepareLessonsTime = o.PrepareLessonsTime.split(' ')[0];
              console.log(o.RecentlyClassTime, '上课时间');
            });
            console.log('获取子课包课件list', this.particulars);
          });
      } else {
        CWSGetCoursewareForGrade(this.CoursewarePackageData.OLAPKey, this.TeacherID).then(result => {
          this.particulars = result.data;
          this.particulars.forEach(o => {
            o.RecentlyClassTime = o.RecentlyClassTime.split('.')[0].split(':')[0] + ':' + o.RecentlyClassTime.split('.')[0].split(':')[1];
            o.PrepareLessonsTime = o.PrepareLessonsTime.split(' ')[0];
          });
          console.log('老师-获取子课包课件list', this.particulars);
        });
      }
      // }
    },
    vRoleKey () {
      if (this.$store.getters.SaaSClientEditionKey == 6) {
        return this.$store.getters.token ? Number(this.$store.getters.token.RoleKey) == 2 : false; // 2为校长；非则为老师
      } else if (this.$store.getters.SaaSClientEditionKey <= 3) {
        return true;
      }
    }
  }
};
</script>

<style scoped>
.particulars {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 800px;
  overflow: auto;
  /* padding: 20px; */
}
</style>
