<template>
  <div style="width:100%;height:100%"
       @click="isShowAlertDialog=false">
    <function-btn :item="settingbtn"
                  :className="''"
                  @clickItem="openDialog">
      <div class="defult_dialog_header_btn"><span>预警设置</span></div>
    </function-btn>
    <div class="renew_warning content_marginTop_30 content_marginRL single_shadow btn_marginBottom_30">
      <div class="population">
        <div class="population_left">
          <!-- <span class="">可用课时≤5,可用天数≤15:</span> -->
          <span class="">可用课时不足</span>
          <span class="">{{Number(renewFeeWarning.Total)}}</span>{{Number($utils.getSaaSClientSetInfo(128).SetKey)==0?"人":'课单'}}
        </div>
      </div>
      <input-search-contain-btn v-model="searchText"
                                :placeholder="'请输入姓名、姓名首字母、手机号'"
                                @onSearchEvent="inputSearch"></input-search-contain-btn>
      <div class="new_table_list_box"
           v-if="renewFeeWarning.PageDataList.length>0">
        <div class="new_table_list_thead">
          <div style="width: 89px;"
               class="new_table_list_th style_gray tl">学生</div>
          <div style="width: 116px;"
               class="new_table_list_th style_yellow tl">手机号</div>
          <div style="width: 110px;"
               class="new_table_list_th style_red tl">剩余课时/天数</div>
          <div style="width: 75px;padding-left:20px"
               class="new_table_list_th style_green tl">微信</div>
        </div>
        <div class="school_warning_content school_warning_content_scroll_y scroll_y"
             @scroll="scrollTOP">
          <div class="new_table_list_tr"
               v-for="(item,index) in renewFeeWarning.PageDataList "
               :key="index"
               @click.stop="selectAmendEvent(item,$event)">
            <div class="new_table_list_td font_blue tl"
                 style="width: 89px;"
                 @click.stop="goStudentFile(item)">{{item.StudentKeyValue}}</div>
            <div class="new_table_list_td tl"
                 style="width: 116px;">
              <div class="school_warning_content_center">{{$utils.phoneModulePower(item.CustomerPhoneName)||'--'}}</div>
            </div>
            <div class="new_table_list_td tl"
                 style="width: 110px;">
              <span :class="Number(item.CurrentClassHourCount)<=Number($utils.getSaaSClientSetInfo(60).SetContent)?'font_red':''">{{CurrentClassHourCount(item.CurrentClassHourCount)||'-'}}</span>
              <span>{{Number(item.CurrentClassHourCount)>0?'课时/':'/'}}</span>
              <span :class="Number(item.CurrentMaxDayCount)<=Number($utils.getSaaSClientSetInfo(61).SetContent)?'font_red':''">{{CurrentMaxDayCount(item.CurrentMaxDayCount)}}</span>天
            </div>
            <!-- 微信 -->
            <div class="new_table_list_td tl"
                 style="width: 75px;padding-left:20px"
                 @click.stop="doWeChatTemplateShow(item)">
              <!-- td_WX_close -->
              <div class=""
                   :class="Number(item.SubscribeKey)>0?'td_WX_open':'td_WX_close'"></div>
            </div>
            <div class="new_table_list_arrow"></div>
          </div>
          <div class="new_table_list_bottom"
               v-if="isLoadEnd">
            <!-- <span class="font_gray">已显示全部</span> -->
            <span class="loading_btn">加载中...</span>
          </div>
          <div class="new_table_list_bottom"
               v-if="noMore">
            <span class="font_gray">已显示全部</span>
          </div>
        </div>
      </div>
      <div class="course_block_nodata content_marginTop_30 content_marginRL single_shadow btn_marginBottom_30"
           v-else>
        <div class="course_block_nodata_txt">亲，当前没有任何数据哦~</div>
      </div>
      <pop-up-drop-down-box :isShowDialog="isShowAlertDialog"
                            :dropDownList="popUpList"
                            :positionLeft="positionLeft"
                            :positionTop="positionTop"
                            @onClick="doAfterClick"></pop-up-drop-down-box>

    </div>
    <custom-dialog title="课时不足预警设置"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowSetting"
                   :before-close="closeSettingDialog">
      <setting-warning-form @onConfirmClick="doAfterConfirm"
                            @cancelClick="closeSettingDialog"></setting-warning-form>
    </custom-dialog>
  </div>
  <!-- 续费预警数据列表 -->
</template>
<script>
import { getRenewFeeWarning, getWarningIgnore, getStudentRelatedMsg } from '../../../API/workbench.js';
import popUpDropDownBox from '../../common/pop-up-drop-down-box';
import settingWarningForm from './setting-warning-form';
export default {

  data () {
    return {
      renewFeeWarning: {
        PageDataList: [],
        Total: 0,
        Total_RestDay: 0
      },
      PageMax: 0,
      PopUpShow: false, // 弹出层选择菜单
      studentItem: {}, // 操作按钮选择对象
      searchObj: {
        pageIndex: 0,
        pageSize: 20,
        searchText: '',
        orderBy: '',
        sequence: ''
      },
      searchText: '',
      isLoadEnd: false, // 判断是否加载完,
      noMore: false,
      moreJiaowuBaseMenuData: {
        // 权限控制.
        payment: {
          name: '报名收款',
          eventName: 'payment',
          ModulePowerKey: 50
        },
        delay: {
          name: '调整课单',
          eventName: 'delay',
          ModulePowerKey: 52
        }
      },
      popUpList: [
        { name: '报名收款', type: 'payment', isShow: true, ModulePowerKey: 50 },
        { name: '调整课单', type: 'delay', ModulePowerKey: 52, isShow: true },
        { name: '忽略', type: 'ignore', isShow: true }
      ],
      positionLeft: 0,
      positionTop: 0,
      isShowAlertDialog: false,
      isShowMainForm: false,
      settingbtn: {
        name: '',
        type: 'mdsj',
        ModulePowerKey: 38
      },
      isShowSetting: false // 设置预警线.

    };
  },
  props: ['GOwarningDetailsList', 'callBackFunction'],
  components: {
    popUpDropDownBox, settingWarningForm
  },
  created () {
    document.addEventListener('click', e => {
      const ele = document.getElementsByClassName('alert_dialog')[0];
      if (e.target && !e.target.contains(ele)) {
        this.isShowAlertDialog = false;
      }
    });
    this.doShowPopupShowClick();
  },
  mounted () {
    this.$dialog.register(this, ['signuppayment', 'studentFile', 'changeCourseBill', 'weChatTemplate']);
  },
  watch: {},
  methods: {
    // 打开预警设置
    openDialog () {
      this.isShowSetting = true;
    },
    // 关闭预警设置
    closeSettingDialog () {
      this.isShowSetting = false;
    },
    // 预警设置确定触发.
    doAfterConfirm () {
      this.inputSearch();
    },
    // 微信模板通知
    doWeChatTemplateShow (item) {
      if (this.vWeChatPattern) { // 托管模式
        layer.alert('非自有公众号, 不能发送通知');
      } else if (Number(this.$utils.getSaaSClientSet(47)) == 0) {
        layer.alert('该通知开关未开启');
      } else if (item.SubscribeKey == 0) {
        // layer.alert('非自有公众号, 不能发送通知');
      } else {
        this.$dialog.open(this, {
          name: '课时不足提醒',
          routerName: this.$route.name,
          moduleName: 'weChatTemplate',
          data: { StudentKey: item.StudentKey, eventId: 37 }
        });
      }
      console.log(this.$store.getters.SaaSClientInfo, '微信模板显示');
    },
    // 打开学生档案.
    goStudentFile (item) {
      this.$dialog.open(this, {
        name: '学生档案',
        routerName: this.$route.name,
        moduleName: 'studentFile',
        data: { studentID: item.StudentKey }
      });
    },
    // 主表单开关
    doShowPopupHideClick () {
      this.renewFeeWarning.PageDataList = [];
      this.renewFeeWarning.Total = 0;
      this.renewFeeWarning.Total_RestDay = 0;
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchText = '';
      this.noMore = false;
      this.$emit('chgCallBackFunction');
      this.isShowMainForm = false;
      this.isShowAlertDialog = false;
    },
    doShowPopupShowClick () {
      this.isShowMainForm = true;
      document.body.scrollTop = 0;
      this.updatewarningList();
    },
    // 滚动加载事件.
    scrollTOP (e) {
      this.isShowAlertDialog = false;
      let ETarget = e.target;
      if (
        (ETarget.scrollTop == ETarget.scrollHeight - ETarget.clientHeight ||
          ETarget.scrollTop + 1 ==
          ETarget.scrollHeight - ETarget.clientHeight) &&
        !this.noMore &&
        !this.isLoadEnd
      ) {
        this.updatewarningList();
      }
    },
    // 点击搜索
    inputSearch () {
      this.noMore = false;
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = this.searchText;
      this.renewFeeWarning.PageDataList = [];
      this.renewFeeWarning.Total = 0;
      this.renewFeeWarning.Total_RestDay = 0;
      this.updatewarningList();
    },
    rewardData () {
      this.renewFeeWarning.PageDataList = [];
      // this.searchObj.searchText = '';
      this.searchObj.pageIndex = 0;
      this.noMore = false;
      setTimeout(() => {
        this.$emit('afterSucces');
      }, 500);
      this.updatewarningList();
    },
    // 剩余天数
    CurrentMaxDayCount (item) {
      return Number(item);
    },
    // 剩余课时.
    CurrentClassHourCount (item) {
      // 排课时间精确到1位小数点.
      return Number(Number(item).toFixed(1));
    },
    // 返回页面
    returnEvent () {
      this.$router.back(-1);
    },
    //  显示弹出层
    selectAmendEvent (item, e) {
      this.studentItem = item;
      let scrollTop = document.getElementsByClassName('school_warning_content_scroll_y')[0].scrollTop;
      this.positionLeft = 322;
      this.positionTop = e.currentTarget.offsetTop - scrollTop + 32;
      this.isShowAlertDialog = true;
      document.body.scrollTop = 0;
    },
    // 刷新续费预警
    updatewarningList () {
      this.isLoadEnd = true;
      getRenewFeeWarning(this.searchObj).then(result => {
        result.data.PageDataList.forEach(o => {
          this.renewFeeWarning.PageDataList.push(o);
        });
        if (result.data.PageDataList.length == this.searchObj.pageSize) {
          this.searchObj.pageIndex++;
        } else {
          this.noMore = true;
        }
        this.renewFeeWarning.Total = result.data.Total;
        this.isLoadEnd = false;
      });
    },
    reload () {
      this.renewFeeWarning.PageDataList = [];
      this.renewFeeWarning.Total = 0;
      this.renewFeeWarning.Total_RestDay = 0;
      this.searchObj.pageIndex = 0;
      // this.searchObj.searchText = '';
      this.noMore = false;
      this.isLoadEnd = false;
      this.updatewarningList();
      setTimeout(() => {
        this.$emit('afterSucces');
      }, 500);
    },
    // 忽略按钮
    overlookEvent () {
      let title = this.studentItem.StudentKeyValue + '可用课时不足';
      let warningType=Number(this.$utils.getSaaSClientSetInfo(128).SetKey)==0?5:13
      let OLAPKey=warningType==5?parseInt(this.studentItem.StudentKey):this.studentItem.DataKey
      getWarningIgnore(warningType, OLAPKey, title).then(
        result => {
          var length = this.renewFeeWarning.length;
          for (var i = 0; i < length; i++) {
            if (this.renewFeeWarning[i] == this.studentItem) {
              if (i == 0) {
                this.renewFeeWarning.shift(); // 删除并返回数组的第一个元素
              } else if (i == length - 1) {
                this.renewFeeWarning.pop(); // 删除并返回数组的最后一个元素
              } else if (i > 0) {
                this.renewFeeWarning.splice(i, 1); // 删除下标为i的元素
              }
            }
          }
          this.rewardData();
        }
      );
    },
    getStudentInfo (id, callback) {
      getStudentRelatedMsg(id).then(result => {
        if (callback) {
          callback(result.data);
        }
      }, err => {
        layer.alert(err.msg);
      });
    },
    // // 续费按钮
    studentPaymentEvent () {
      this.PopUpShow = false;
      let obj = {
        StudentKey: this.studentItem.StudentKey,
        isReadOnly: false,
        TableID: ''
      };
      this.$dialog.open(this, {
        name: '报名收款',
        routerName: this.$route.name,
        moduleName: 'signuppayment',
        moduleDescription: '学员报名购买课程，如未登记的学生可点界面“+”新增学生信息后再进行报名。',
        data: { dataInfo: obj },
        callBack: { afterSucces: () => { this.reload(); } }
      });
    },
    // 延期按钮
    studentPostponeEvent () {
      this.PopUpShow = false;
      this.getStudentInfo(this.studentItem.StudentKey, (data) => {
        this.$dialog.open(this, {
          name: '修改课单',
          routerName: this.$route.name,
          moduleName: 'changeCourseBill',
          data: { dataInfo: data },
          callBack: { updateCourseEvent: () => { this.reload(); } }
        });
      });
    },
    doAfterClick (item) {
      switch (item.type) {
        case 'payment':
          this.studentPaymentEvent();
          break;
        case 'delay':
          this.studentPostponeEvent();
          break;
        case 'ignore':
          this.overlookEvent();
          break;
        case 'cancel':
          break;
        default:
          break;
      }
      this.isShowAlertDialog = false;
    }
  },
  computed: {
    // 门店是否托管
    vWeChatPattern () {
      if (this.$store.getters.SaaSClientInfo.WktInfo && this.$store.getters.SaaSClientInfo.WktInfo.PatternKey == 2 && this.$store.getters.SaaSClientInfo.IsCommonTrusteeshipKey == 1) { // 托管模式
        return true;
      } else {
        return false;
      }
    },
    // 剩余课时
    surplusHourCount () {
      if (this.vWarmingStatisical) {
        return this.vWarmingStatisical;
      } else {
        return 0;
      }
    },
    vWarmingStatisical () {
      return this.$store.getters.getWarmingStatisical.CountRenewalNum;
    },
    // 剩余天数
    surplusDayCount () {
      if (this.renewFeeWarning) {
        return this.renewFeeWarning.Total_RestDay;
      } else {
        return 0;
      }
    },
    vSaasClientInfo () {
      console.log(this.$store.getters.SaaSClientInfo, 'vSaasClientInfo');
      return this.$store.getters.SaaSClientInfo;
    },
    vBMSKNorole () {
      return !this.$fun.CheckPower(this.$store.getters.getRolestr, 'BMSK');
    },
    vEKDNorole () {
      return !this.$fun.CheckPower(this.$store.getters.getRolestr, 'EKD');
    }
  }
};
</script>
<style scoped>
</style>