<template>
  <div>
    <!-- <div class="border_bottom"
         v-if="CouponInfo.if_fenxiang!=2">
      <div class="from_title">
        <span>玩法说明</span>
      </div>
      <div class="from_introduce_text">
        <div>
          · 拼多多的玩法。可设置系统在活动的前几天，每天代开2人团。
        </div>
        <div>
          · 能保证用户参团立即成团，降低成团门槛，提高报名率。并允许用户自己开团。
        </div>
        <div>
          · 优惠券在学生微信账户“我的-我的优惠券”，报名时使用优惠券，系统可自动减免。
        </div>
      </div>
      <div>
        <form-input :formTitle="'转介绍名额'"
                    :unintTitle="'人'"
                    v-model="CouponInfo.share_num"
                    :tipsTitle="''"
                    :decimalPlaces="0"
                    :required="false"
                    :inputClass="'from_list_input_three'"></form-input>
      </div>
    </div> -->
    <div v-if="settingInfo.groupSetting.isOpen">
      <group-setting ref="groupSetting"
                     :fxType="fanxianType"
                     @changeType="changeFanXianType"
                     :CouponInfo="CouponInfo"
                     :dataInfo="dataInfo"
                     :settingInfo="settingInfo"
                     @updateCourseList="updateCourseList"
                     :courseTreeList="courseTreeList">
      </group-setting>
    </div>
    <parent-promote-setting v-if="settingInfo.parentsPromotionSetting.isOpen||this.CouponInfo.if_fenxiang != 2"
                            :dataInfo="dataInfo"
                            :CouponInfo="CouponInfo"
                            :menuItem="menuItem"
                            :settingInfo="settingInfo"
                            :courseTreeList="courseTreeList"
                            @updateCourseList="updateCourseList"
                            ref="parentPromote"></parent-promote-setting>
    <virtual-setting v-if="settingInfo.virtualSoftHitsSetting.isOpen||settingInfo.groupSetting.virtualGroup.isOpen"
                     :class="{'border_top_line':CouponInfo.if_fenxiang!=2||settingInfo.groupSetting.isOpen}"
                     :CouponInfo="CouponInfo"
                     :settingInfo="settingInfo"></virtual-setting>
    <step-btn :showPre="true"
              @preStep="preStep"
              @nextStep="nextStep">
    </step-btn>

  </div>
</template>
<script>
import groupSetting from './play-setting-componenet/group-setting';
import virtualSetting from './play-setting-componenet/virtual-setting';
import parentPromoteSetting from './play-setting-componenet/parent-promote';
import stepBtn from './step-btn';
import formInput from './common/form-input';
export default {
  data () {
    return {
      fanxianType: 1
    };
  },
  components: {
    stepBtn, virtualSetting, groupSetting, parentPromoteSetting, formInput
  },
  props: {
    dataInfo: Object,
    CouponInfo: Object,
    menuItem: Object,
    settingInfo: Object,
    courseTreeList: Array
  },
  computed: {
    groupStepNum () {
      return this.settingInfo.groupSetting.degreeRestriction.defaultValue;
    },
    vTypetitle () {
      if (this.CouponInfo.if_fenxiang == 1) {
        return '转介绍';
      } else {
        return '裂变';
      }
    },
    vcommanderAward () {
      return this.settingInfo.groupSetting.targetAward.commanderAward;
    }
  },
  watch: {
    CouponInfo (c, o) {
      this.initData();
    }
  },
  mounted () {
    this.initData();
  },
  methods: {
    updateCourseList () {
      this.$emit('updateCourseList');
    },
    initData () {
      if (this.settingInfo.groupSetting.isOpen) {
        this.fanxianType = this.CouponInfo.groupSetting.target_1_A.fanxian_type;
        console.log(this.fanxianType, 'this.initData');
        if (this.settingInfo.groupSetting.targetAward.oneGroupNumberLimit.isOpen) {
          this.CouponInfo.groupSetting.target_1 = this.CouponInfo.groupSetting.target_1 || this.settingInfo.groupSetting.targetAward.oneGroupNumberLimit.defaultValue;
        }
      }
    },
    changeFanXianType (val) {
      this.fanxianType = val;
    },
    preStep () {
      this.$emit('preStep', this.menuItem);
    },
    nextStep () {
      if (!this.verifyData()) {
        return false;
      }
      // if (this.settingInfo.parentsPromotionSetting.isOpen) {
      //   if (!this.$refs.parentPromote.verifyData()) {
      //     return false;
      //   } else {
      //     this.$refs.parentPromote.disposalData();
      //   }
      // }

      this.$emit('nextStep', this.menuItem);
    },
    verifyData () {
      let isComfire = true;
      let warningTitle = '';
      // 团阶段开启
      if (this.settingInfo.groupSetting.isOpen) {
        if (!this.$refs.groupSetting.verifyData()) {
          isComfire = false;
          return isComfire;
        }
        console.log(this.CouponInfo.groupSetting, 'CouponInfo.groupSetting');

        if (this.groupStepNum == 1) {
          this.CouponInfo.groupSetting.target_1_A.fanxian_type = this.fanxianType;
          this.CouponInfo.groupSetting.target_1_B.fanxian_type = this.fanxianType;
          this.CouponInfo.groupSetting.target_2_A = null;
          this.CouponInfo.groupSetting.target_2_B = null;
          this.CouponInfo.groupSetting.target_3_A = null;
          this.CouponInfo.groupSetting.target_3_B = null;
        } else if (this.groupStepNum == 2) {
          this.CouponInfo.groupSetting.target_1_A.fanxian_type = this.fanxianType;
          this.CouponInfo.groupSetting.target_1_B.fanxian_type = this.fanxianType;
          this.CouponInfo.groupSetting.target_2_A.fanxian_type = this.fanxianType;
          this.CouponInfo.groupSetting.target_2_B.fanxian_type = this.fanxianType;
          this.CouponInfo.groupSetting.target_3_A = null;
          this.CouponInfo.groupSetting.target_3_B = null;
        } else {
          this.CouponInfo.groupSetting.target_1_A.fanxian_type = this.fanxianType;
          this.CouponInfo.groupSetting.target_1_B.fanxian_type = this.fanxianType;
          this.CouponInfo.groupSetting.target_2_A.fanxian_type = this.fanxianType;
          this.CouponInfo.groupSetting.target_2_B.fanxian_type = this.fanxianType;
          this.CouponInfo.groupSetting.target_3_A.fanxian_type = this.fanxianType;
          this.CouponInfo.groupSetting.target_3_B.fanxian_type = this.fanxianType;
        }
      }
      if (this.settingInfo.groupSetting.virtualGroup.isOpen) {
        if (Number(this.CouponInfo.groupSetting.virtual_group_person_num) > 0) {
          if (Number(this.CouponInfo.groupSetting.virtual_group_count) <= 0) {
            isComfire = false;
            layer.alert('请输入虚拟代开团个数');
            return isComfire;
          }
          if (this.groupStepNum == 3) {
            if (Number(this.CouponInfo.groupSetting.virtual_group_person_num) >= Number(this.CouponInfo.groupSetting.target_3)) {
              isComfire = false;
              layer.alert('虚拟代开团人数不能大于等于阶梯团最大目标人数');
              return isComfire;
            }
          }
          if (this.groupStepNum == 2) {
            if (Number(this.CouponInfo.groupSetting.virtual_group_person_num) >= Number(this.CouponInfo.groupSetting.target_2)) {
              isComfire = false;
              layer.alert('虚拟代开团人数不能大于等于阶梯团最大目标人数');
              return isComfire;
            }
          }
          if (this.groupStepNum == 1) {
            if (Number(this.CouponInfo.groupSetting.virtual_group_person_num) >= Number(this.CouponInfo.groupSetting.target_1)) {
              isComfire = false;
              layer.alert('虚拟代开团人数不能大于等于阶梯团最大目标人数');
              return isComfire;
            }
          }
          console.log('虚拟团总人数', (Number(this.CouponInfo.groupSetting.virtual_group_person_num) * Number(this.CouponInfo.groupSetting.virtual_group_count)), Number(this.CouponInfo.maxGetNum));
          // if (Number(this.CouponInfo.maxGetNum) > 0) {
          if ((Number(this.CouponInfo.groupSetting.virtual_group_person_num) * Number(this.CouponInfo.groupSetting.virtual_group_count)) > Number(this.CouponInfo.maxGetNum)) {
            isComfire = false;
            layer.alert('虚拟代开团总人数不能大于最大招生数');
            return isComfire;
          }
          // }
        } else {
          if (Number(this.CouponInfo.groupSetting.virtual_group_count) > 0) {
            isComfire = false;
            layer.alert('请输入虚拟代开团人数');
            return isComfire;
          } else {
            // this.CouponInfo.groupSetting = null;
          }
        }
        // this.CouponInfo.groupSetting.virtual_group_person_num
      }
      // 虚拟人数开启   //临时注释.
      if (this.settingInfo.virtualSoftHitsSetting.isOpen) {
        let dataObj = this.CouponInfo.virtual_get_person_setting;
        if (Number(dataObj.perDayNum) > 0) {
          dataObj.bgeinDate = this.$utils.formatDateToLine(Date.new().toLocaleDateString());
          dataObj.endDate = this.CouponInfo.activity_time_end || '';
          dataObj.bgeinTime = '08:00';
          dataObj.endTime = '23:00';
          console.log(dataObj, '虚拟设置.');
          if (dataObj.bgeinDate && dataObj.endDate && dataObj.bgeinTime && dataObj.endTime) {
            if (Date.new(dataObj.bgeinDate) > Date.new(dataObj.endDate)) {
              isComfire = false;
              layer.alert('请选择开始日期不能大于结束日期');
              return isComfire;
            }
            if (parseInt(dataObj.endTime) <= parseInt(dataObj.bgeinTime)) {
              isComfire = false;
              layer.alert('开始时间不能大于或等于结束时间');
              return isComfire;
            }
          }
          if (Number(this.CouponInfo.maxVirNum) == 0) {
            let totalPerNum = (((Date.new(dataObj.endDate).getTime() - Date.new(dataObj.bgeinDate).getTime()) / 86400000) + 1) * Number(dataObj.perDayNum);
            // debugger;
            console.log(totalPerNum, 'totalPerNum');
            if (totalPerNum >= Number(this.CouponInfo.maxGetNum)) {
              isComfire = false;
              layer.alert('每日虚拟人数总数不能大于等于最大招生数');
              return isComfire;
            }
          }
        }
        if (Number(this.CouponInfo.maxVirNum) > 0) {
          if (Number(this.CouponInfo.maxVirNum) < Number(this.CouponInfo.VirtualNumber)) {
            isComfire = false;
            layer.alert('最多虚拟报名数不能小于当前已虚拟报名数');
            return isComfire;
          }
          if (Number(this.CouponInfo.maxVirNum) > Number(this.CouponInfo.maxGetNum)) {
            isComfire = false;
            layer.alert('最多虚拟报名数不能大于最大招生数');
            return isComfire;
          }
        }
      }
      if (this.settingInfo.parentsPromotionSetting.isOpen || this.CouponInfo.if_fenxiang != 2) {
        if (!this.$refs.parentPromote.verifyData()) {
          isComfire = false;
        } else {
          this.$refs.parentPromote.disposalData();
        }
      }

      return isComfire;
    }
  }
};
</script>