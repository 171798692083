<template>
  <div class="debt_course_item"
       @click.stop="seletedItem">
    <div>
      {{item.MainDemoName}}欠
      <span class="font_red">{{Number(item.DebtCount)||0}}</span>
      课时
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: {
    item: Object
  },
  methods: {
    seletedItem () {
      this.$emit('seletedItem', this.item);
    }
  }
};
</script>

<style scoped>
.debt_course_item:first-child {
  margin-top: 0;
}
.debt_course_item {
  margin: 0 7px;
  height: 63px;
  font-size: 14px;
  line-height: 47px;
  color: #fff;
  padding: 8px 0;
  padding-left: 23px;
  padding-right: 48px;
  border-radius: 4px;
  background: url(../../../../../../public/image/student_file_list_banner.png)
    no-repeat;
  background-size: 100%;
  /* box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.5); */
  margin-top: -7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  cursor: pointer;
}
.debt_course_item::after {
  content: '';
  position: absolute;
  width: 23px;
  height: 23px;
  top: 50%;
  margin-top: -11px;
  right: 26px;
  background: url(../../../../../../public/image/school_home_inform_btn.png)
    no-repeat;
  background-size: 23px;
}
</style>
