<template>
  <!-- 授权课件表单 -->
  <div style="height:400px">
    <div class="form_info_edit form_info_list">
      <input-select :formTitle="'课单类型'"
                    :id="'id'"
                    :name="'name'"
                    :required="true"
                    :dataList="applyTypeList"
                    :selectedInfo="applyInfo"
                    @selectedItem="changeApplyInfo"></input-select>
    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>

  </div>
</template>
<script>

import {
  AlterKDApplyTypeKey
} from '../../../API/workbench.js';
export default {
  data () {
    return {
      applyTypeList: [
        { id: '1', name: '新生报名' },
        { id: '2', name: '老生续费' },
        { id: '3', name: '老生扩科' }
      ],
      applyInfo: {
        id: '1',
        name: '新生报名'
      },
      dataJson: {}
    };
  },
  components: {

  },
  props: {
    dataInfo: Object,
    isReadonly: Boolean,
    TableID: [String, Number]
  },
  created () {
    this.dataJson.ApplyTypeKey = this.dataInfo.ApplyTypeKey;
    this.dataJson.ApplyTypeKeyValue = this.dataInfo.ApplyTypeKeyValue;

    this.applyInfo.id = this.dataInfo.ApplyTypeKey;
    this.applyInfo.name = this.dataInfo.ApplyTypeKeyValue;

    console.log('dataInfo', this.dataInfo);
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    // 选择所属类型
    changeApplyInfo (item) {
      console.log(11111);
      this.dataJson.ApplyTypeKey = item.id;
      this.dataJson.ApplyTypeKeyValue = item.name;
    },
    submit () {
      let data = {
        TableID: this.TableID,
        ApplyTypeKey: this.dataJson.ApplyTypeKey,
        ApplyTypeKeyValue: this.dataJson.ApplyTypeKeyValue
      };
      AlterKDApplyTypeKey(data).then((res) => {
        console.log('WEBVModifySaaSClientSetting', res.data);
        this.$emit('afterSuccess', data);
        this.$emit('cancelClick');
      }, error => {
        console.error('WEBVModifySaaSClientSetting', error.msg);
        // this.$emit('afterSuccess', data);
        this.$emit('cancelClick');
      });
    },
    cancel () {
      this.$emit('cancelClick');
    }
  }
};
</script>
<style scoped>
.tipSpan {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */
  position: absolute;
  top: 14px;
  left: 94px;

  color: #999999;
}
</style>
