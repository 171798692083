<template>
  <div>
    <div class="studentname">{{studentName}}</div>
    <div class="box">
      <div class="item"
           v-for="item in dataInfo">
        <div class="item_type">
          <el-radio v-model="radio"
                    :label="item.TableID"
                    class="ridioinput"> {{ item.name }} </el-radio>
          <div class="zhengke">
            <div class="zhengkeitem">
              <div class="zhengkeitemTitle">
                <div class="titletype">正课</div>
                <div class="courseno">课单号:{{`&nbsp;`+ item.NumberName }}</div>
              </div>
              <div class="titleStatus"
                   :class="getStatus(item)=='使用中'?'':'shixiao'"> {{ getStatus(item) }}</div>
            </div>
            <div class="zhengkecontent">
              <div class="fontItem">
                <span class="leftclass">报名日期:</span>
                <span class="rightclass">{{ item.GenerateTime }}</span>
              </div>
              <div class="fontItem">
                <span class="leftclass">报名课程:</span>
                <span class="rightclass">{{ item.ApplyCorName }}</span>
              </div>
              <div class="fontItem">
                <span class="leftclass">剩余课时:</span>
                <span class="rightclass"> {{ Number(item.CurrentCount)+'课时' }}</span>
              </div>
              <div class="fontItem">
                <span class="leftclass">实付金额:</span>
                <span class="rightclass">{{ '￥'+ $utils.setMoneyformatToParseInt(Number(item.ActualPayAmount))}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <save-cancel-btn-group :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
  </div>
</template>
<script>
import { GetClassHourXYKDListByStudentKey, MergeClassHourXYKD } from '../../../API/workbench';

export default {
  data () {
    return {
      // 提交数据对象
      dataInfo: [],
      radio: ''
    };
  },
  components: {

  },
  props: {
    XYKDKey: [String, Number],
    StudentKey: [String, Number],
    TableID: [String, Number],
    isReadonly: Boolean,
    studentName: String
  },
  created () {
    // if (this.dataInfo) {
    //   this.dataJson.OLAPKey = this.dataInfo.OLAPKey;
    //   this.dataJson.MainDemoName = this.dataInfo.MainDemoName;
    // }
    this.getDataInfo();
  },
  computed: {
    // vMoney () {
    //   return this.;
    // }
  },
  methods: {
    getDataInfo () {
      console.log('this.IsRenewalKey, this.StudentKey', this.XYKDKey, this.StudentKey);
      GetClassHourXYKDListByStudentKey(this.StudentKey, this.XYKDKey).then((res) => {
        this.dataInfo = res.data;
        // this.dataInfo.unshift({
        //   TypeKey: 4,
        //   title: '不关联'
        // });
      });
    },
    verifyData () {
      let isFail = false;
      if (!this.dataJson.MainDemoName) {
        isFail = true;
        layer.alert('请输入班级名');
      }
      return isFail;
    },
    confirmClick () {
      let data = {

      };
      if (this.radio == '') {
        layer.alert('请选择需要合并的课单');
        return
      } else {
        let index = this.dataInfo.findIndex((item) => {
          return item.TableID == this.radio;
        });
        console.log('index', index, this.radio);
        let obj = this.dataInfo[index];
        data.StudentKey = this.StudentKey;
        data.XYKDKey = this.XYKDKey;
        data.mergeXYKDKey = obj.TableID;
      }
      MergeClassHourXYKD(data).then((res) => {
        layer.alert('操作成功');
        this.$emit('afterSuccess');
        this.$emit('cancelClick');
      }, (error) => {
        layer.alert(error.msg);
      });
      console.log('cancelClick', this.radio);
    },
    cancelClick () {
      this.$emit('cancelClick');
    },
    getStatus (data) {
      let strdd = '';
      console.log('new Date(data.ExpireTime).getTime()', new Date(data.ExpireTime).getTime(), new Date().getTime());
      if (data.CurrentCount > 0) {
        strdd = '使用中';
      } else if (new Date(data.ExpireTime).getTime() < new Date().getTime() && data.ExpireCount == 0) {
        strdd = '已过期';
      } else {
        strdd = '已耗尽';
      }
      return strdd;
    }
  }
};
</script>
<style scoped>
.box {
  width: 390px;
  height: 300px;
  overflow: auto;
  margin: 10px 10px;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}
.item {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ccc;
}
.item_type {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.title_name {
  line-height: 50px;
  font-size: 16px;
  margin-left: -30px;
}
.box >>> .el-radio__inner {
  width: 18px;
  height: 18px;
}
.ridioinput {
  line-height: 50px;
}
.zhengke {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  margin-left: -30px;
}
.zhengkeitem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f2f2f2;
}

.studentname {
  margin: 10px;
  line-height: 50px;
  font-size: 18px;
  font-weight: 600;
  padding: 0 10px;
}
.zhengkeitemTitle {
  display: flex;
  flex-direction: row;
}
.titletype {
  font-size: 12px;
  background: #9af20b;
  margin: 5px;
  padding: 5px;
  width: 50px;
  text-align: center;
  border-radius: 4px;
  color: #666;
}
.courseno {
  font-size: 12px;
  margin: 3px;
  color: #666;
  line-height: 28px;
}
.titleStatus {
  font-size: 12px;
  background: #9af20b;
  margin: 5px;
  padding: 5px;
  width: 50px;
  text-align: center;
  border-radius: 4px;
  color: #333;
}
.zhengkecontent {
  margin-top: 1px;
  background: #f2f2f2;
  padding: 5px 0;
}
.zhengkecontentdas {
  background: #f2f2f2;
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: column;
}
.leftclass {
  color: #666;
  font-size: 14px;
  line-height: 22px;
}
.rightclass {
  color: black;
  font-size: 14px;
}
.fontItem {
  margin-left: 7px;
  line-height: 20px;
  overflow: hidden;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.yinliuke {
  background: #facd91 !important;
}
.shixiao {
  background: #c0c4cc !important;
}
</style>