import { render, staticRenderFns } from "./remerk-popup.vue?vue&type=template&id=0aafe52d&scoped=true"
import script from "./remerk-popup.vue?vue&type=script&lang=js"
export * from "./remerk-popup.vue?vue&type=script&lang=js"
import style0 from "./remerk-popup.vue?vue&type=style&index=0&id=0aafe52d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0aafe52d",
  null
  
)

export default component.exports