<template>
  <div class="swiper-slide swiper-list-text" :class="{'swiper-list-text-choose':item.isActive}" @click="seletedItem">
                <div class="swiper-list-text_one" :title="item.BigCoursewarePackageKeyValue">{{item.BigCoursewarePackageKeyValue}} </div>
                <div class="swiper-list-text_two" :title="item.CoursewarePackageKeyValue">{{item.CoursewarePackageKeyValue}} </div>
   </div>
</template>
<script>
export default {
  components: {
  },
  props: ['item'],
  data () {
    return {
    };
  },

  created () {
    
  },
  mounted () {

  },
  methods: {
    seletedItem(){
        this.$emit('seletedItem', this.item);
    }
  }
};
</script>
<style scoped>
.swiper-slide{
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  width: 160px !important;
  height: 60px !important;
}
.swiper-list-text{
    display:table-cell;
    font-family: 'Alibaba PuHuiTi';
    font-style: normal;
    color: #333333;
    align-items:center;
    vertical-align:middle;
    text-align: center;
    width: 160px;
    height: 60px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    padding-left: 15px;
    padding-right: 15px;
}
.swiper-list-text-choose{
    background: linear-gradient(270deg, #1A9AF6 0%, #2CBFF2 100%);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  color: #FFFFFF !important;
}
.swiper-list-text-choose::after{
  content: '';
  position: relative;
  right: 0px;
  top: 13px;
  border: 6px solid transparent;
  border-top-color: #2CBFF2;
  border-radius: 4px;
}
.swiper-list-text_one{
  margin-top: 8px;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.swiper-list-text_two{
  font-size: 12px;
  color: #999;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.swiper-list-text-choose .swiper-list-text_two{
  color: #FFFFFF !important;
}

</style>