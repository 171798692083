<template>
  <div class="screening_condition_list fl"
       @click.stop="del">
    <span>{{dataModel.formTitle}}：</span>
    <span>{{dataModel.fieldList[0].value}}</span>
    <span>-</span>
    <span>{{dataModel.fieldList[1].value}}</span>
    <div>x</div>
  </div>

</template>
<script>
export default {
  model: {
    prop: 'extendSearchParams'
  },
  props: {
    dataModel: Object,
    extendSearchParams: Array
  },
  methods: {
    del () {
      this.dataModel.fieldList.forEach(obj => {
        let findIndex = this.extendSearchParams.findIndex(o => { return o.name == obj.name; });
        if (findIndex >= 0) {
          this.extendSearchParams.splice(findIndex, 1);
        }
      });
      // 选中参数
      this.$emit('input', this.extendSearchParams);
      this.$emit('change');
    }
  }
};
</script>
