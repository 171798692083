<template>
  <div class="bantch_renewal_content">
    <div class="bantch_renewal_head">
      <div class="bantch_renewal_head_left">
        <div class="head_title">
          <span class="head_title_first">未授权课包</span>
          
        </div>
        <div class="head_chose">
          <!-- <input type="text"> -->
        </div>
      </div>
      <div  class="bantch_renewal_head_center">
        
      </div>
      <div  class="bantch_renewal_head_right">
        <div class="head_title">
          <span class="head_title_first">选中课包</span>
          
        </div>
        <div class="head_chose chose_text font_gray">
          已选中<span class="font_black">{{seletedListArray.length}}个课包</span>
          <!--  -->
        </div>
      </div>
    </div>
    <div class="form_info_edit form_info_list"
         style="height: 475px">
       
       <el-transfer
          @mouseover.native="addTitle"
          v-model="seletedListArray"
          :props="{
            key: 'value',
            label: 'desc'
          }"
          :data="vDataList"
          :left-default-checked="vnoSeletedListArray"
          :right-default-checked="seletedListArray"
          @left-check-change="leftChange"
          @right-check-change="rightChange">
        </el-transfer>
    </div>
    <div>
      <save-cancel-btn-group :cancelBtnText="'取消'"
                             :isSingleSubmit="true"
                             :fixedBtn="true"
                             @confirmClick="submit"
                             @cancelClick="cancel"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import { GetClientAuthBigCoursewarePackageList } from '../../../../../../API/workbench';
export default {
  data () {
    return {
      dataList:[],
      searchText:'',
      seletedListArray:[]
    };
  },
  props: {
    seletedList:Array,
    isReadonly: Boolean,
    dataInfo:Object
  },
  computed:{
    vnoSeletedListArray(){
      let newArr=[]
      this.dataList.forEach(o=>{
        let locaIndex=this.seletedListArray.findIndex(p=>{
          console.log('pppp',p)
          return o.CoursewarePackageKey==p
        })
        if(locaIndex>=0){
          
        }else{
          newArr.push(o.CoursewarePackageKey)
        }
      })
      console.log("vnoSeletedListArray",newArr)
      return newArr
    },
    vDataList(){
      let data = [];
      this.dataList.forEach(o=>{
        data.push({
            value: o.CoursewarePackageKey,
            desc: o.CoursewarePackageKeyValue,
            disabled:false
            // disabled: i % 4 === 0
          });
      })
      return data;
    }
  },
  components: {

  },
 
  created () {
    this.seletedListArray=[]
       console.log("created created",this.seletedList)
    if(this.seletedList.length>0){
   
      this.seletedList.forEach(o=>{
          this.seletedListArray.push(o.CoursewarePackageKey)
      })
    }
    this.getDataList()
  },
  methods: {
    addTitle(e) {
      // 手动给鼠标滑过的元素加一个title
      let target_el = e.target;
      console.log("target_el",target_el)
      if (target_el.title) return;
      target_el.title = target_el.innerText;
    },
    leftChange(dataList,item){
      console.log("leftChange",dataList,item)
      this.seletedListArray=this.seletedListArray.concat(item)
    },
    rightChange(dataList,item){
      console.log("rightChange",dataList,item)
      item.forEach(o=>{
        
        let locaIndex=this.seletedListArray.findIndex(p=>{
          return o==p
        })
        console.log("rightChange",o,locaIndex)
        if(locaIndex>=0){
          this.seletedListArray.splice(locaIndex,1)
        }
      })
    },
      // 选择加盟方式
    search(item) {
      console.log("chgRenewTypeInfo",item)
      this.seletedListArray=[]
      this.getDataList()
    },
    getDataList(){
      console.log("this.dataInfo",this.dataInfo)
      GetClientAuthBigCoursewarePackageList(this.dataInfo.AuthorizationSaaSClientKey).then(result=>{
        console.log("GetClientSystemPortConsumeOptionList",result.data)
        this.dataList=result.data
      }).catch(error=>{
        console.log("error",error)
      })
    },
   
    // 提交
    submit (callback) {
      // console.log('submit', this.dataInfo);
      if(this.seletedListArray.length==0){
        layer.alert("请选择至少一个课包")
        if(callback){
          callback()
        }
      }else{
        let newArr=[]
        this.seletedListArray.forEach(obj=>{
          let locaItem=this.dataList.find(p=>{
            return p.CoursewarePackageKey==obj
          })
          if(locaItem){
            newArr.push(locaItem)
          }
        })
        console.log('newArr',newArr)
        this.$emit("afterSuccess",newArr)
      }
 
       
     
    },
    cancel () {
      this.$emit('close');
    }
  }
};
</script>
<style>
.bantch_renewal_content .el-transfer{
  height: 450px;
}
.bantch_renewal_content .el-transfer-panel .el-transfer-panel__header{
  display: none;
}
.bantch_renewal_content .custonStyle .el-dialog__body {
        border-top: 1px solid #ccc;
    }

    .bantch_renewal_content .el-transfer {
        display: flex;
    }
    .bantch_renewal_content .el-button+.el-button{margin-left: 0px;}
    .bantch_renewal_content .el-button [class*=el-icon-]+span{margin-left: 0px;}
    .bantch_renewal_content .el-transfer__buttons {

        margin-top: 100px;
        display: block;
    }

    .bantch_renewal_content .el-transfer__buttons .is-with-texts {
        height: 40px;
        display: block;
        line-height: 40px;
        padding: 0 20px;

    }
    .bantch_renewal_content .el-transfer__buttons {
      display: inline-block;
      vertical-align: middle;
      padding: 0px 17px;
      width: 80px;
      margin: auto;
    }
    .bantch_renewal_content .el-transfer-panel{
      width: 250px;
      border: 1px solid #ccc;
    }
    .bantch_renewal_head{
      display: flex;
    margin: auto;
    padding: 10px 21px;
    height: 80px;
    }
    .bantch_renewal_head_left,.bantch_renewal_head_right{
      width: 250px;
      /* height: 50px; */
      /* background: yellowgreen; */
      display: flex;
      flex-direction: column;
    }
    .bantch_renewal_content .head_title{
      color: black;
    }
    .head_title_first{
       font-size: 18px;
      color: black;
    }
    .bantch_renewal_content .head_chose{
      margin-top: 5px;
    } 
     .bantch_renewal_head_center{
      width: 80px;
      /* background: red; */
    }
    .bantch_renewal_content .el-select .el-input--suffix .el-input__inner{
      border: 1px solid #ccc;
      padding:0 5px !important;
    }
    .bantch_renewal_content .form_footer_btn_group, .on_form_footer_btn_group{
      justify-content: center;
    }
    .bantch_renewal_content .el-button.is-round{
      margin-right: 20px;
    }
    .bantch_renewal_content .chose_text{
    background: #ececec;
    height: 30px;
    width: 100%;
    /* margin: auto; */
    text-align: center;
    line-height: 30px;
    }
    .bantch_renewal_content .el-transfer-panel__item .el-checkbox__input{
      display: none;
    }
    .bantch_renewal_content .el-transfer-panel__item.el-checkbox .el-checkbox__label{
      padding-left: 0px;
    }
    .bantch_renewal_content .el-transfer-panel__list{
      height: 450px;
     }
</style>
