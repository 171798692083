<template>
  <div class="mendian_backstage_date week_range">
    <div class="date_title">{{fromTitle}}</div>
    <div class="date_box"
         :class="{border_blue:vIsBorder}">
      <div class="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date no_bg">
        <input type="text"
               autocomplete="off"
               name=""
               placeholder="开始日期"
               v-model="vStartTime"
               readonly
               class="el-input__inner">

      </div>
      <div class="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date ">
        <input type="text"
               autocomplete="off"
               name=""
               placeholder="结束日期"
               v-model="vEndTime"
               readonly
               class="el-input__inner">

      </div>
      <input-date class="mendian_backstage_date_input_date"
                  :formTitle="''"
                  ref="inputDate"
                  :minDate="minDate"
                  :maxDate="maxDate"
                  @blurEvent="blurEvent('2')"
                  @focusEvent="focusEvent('2')"
                  @changeDate="changeDay"></input-date>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isDate01: false,
      isDate02: false,
      dataInfo: {
        startTime: '',
        endTime: ''
      }
    };
  },
  props: {
    fromTitle: String,
    searchObj: {
      type: Object,
      default: () => {
        return {
          startTime: '',
          endTime: ''
        };
      }
    },
    minDate: {
      type: String,
      default: ''
    },
    maxDate: {
      type: String,
      default: ''
    }
  },
  computed: {
    vStartTime () {
      if (this.dataInfo.startTime) {
        return this.$utils.formatDateToLine(Date.new(this.dataInfo.startTime));
      } else {
        return '';
      }
    },
    vEndTime () {
      if (this.dataInfo.endTime) {
        return this.$utils.formatDateToLine(Date.new(this.dataInfo.endTime));
      } else {
        return '';
      }
    },
    vIsBorder () {
      if (this.isDate01 || this.isDate02) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    // 结束时间
    'searchObj.endTime': {
      handler (newValue, oldValue) {
        if (!newValue && !this.searchObj.startTime) {
          this.resetTime();
        }
      },
      deep: true
    },
    // 开始时间
    'searchObj.startTime': {
      handler (newValue, oldValue) {
        if (!newValue && !this.searchObj.endTime) {
          this.resetTime();
        }
      },
      deep: true
    }
  },
  mounted () {
    this.dataInfo = this.searchObj;
  },
  methods: {
    resetTime () {
      this.dataInfo.startTime = '';
      this.dataInfo.endTime = '';
      this.$refs.inputDate.resetDate();
    },
    changeStartDay (date) {
      if (this.dataInfo.endTime && Date.new(this.dataInfo.endTime) < Date.new(date)) {
        this.$refs.inputDate.resetDate();
        layer.alert('开始时间不能大于结束时间');
      } else {
        this.dataInfo.startTime = date;
        this.$emit('changeDate', this.dataInfo);
      }
    },
    changeDay (date) {
      this.dataInfo.endTime = date;
      this.dataInfo.startTime = date;
      this.$emit('changeDate', this.dataInfo);
    },
    focusEvent (type) {
      if (type == '1') {
        this.isDate01 = true;
      } else {
        this.isDate02 = true;
      }
    },
    blurEvent (type) {
      if (type == '1') {
        this.isDate01 = false;
      } else {
        this.isDate02 = false;
      }
    }

  }
};
</script>