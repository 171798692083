import store from '../store';
import axios from 'axios';
import * as utils from '../utils';
import initJson from '../js/initJson.json';
import { md5 } from '../API/md5';
import { publicCheckFileExit, privateCheckFileExit } from '../API/workbench';
// import { type } from 'os';
const OSS = require('ali-oss');
function InitOssSetting () {
  let securityTokenPublic = null;
  let securityTokenPrivate = null;
  // let isprivate, type, md5Text, file, fileData
  let getSecurityToken = isprivate => {
    isprivate = isprivate || false;
    if (securityTokenPublic) {
      if (Date.new(securityTokenPublic.Expiration) < Date.new()) {
        // 过时
        return loadSecurityToken(isprivate);
      } else {
        return new Promise((resolve, reject) => {
          // securityTokenPublic
          let callbackSecurityToken = isprivate
            ? securityTokenPrivate
            : securityTokenPublic;
          resolve(callbackSecurityToken);
        });
      }
    } else {
      return loadSecurityToken(isprivate);
    }
  };
  // 加载token封装
  let loadSecurityToken = isprivate => {
    return new Promise((resolve, reject) => {
      var path =
        store.getters.APIURL +
        '/public/?service=WEB.Files.GetSTSSecurityToken&sign=' +
        store.getters.signData.Sign;
      const timeoutNum = 60000;
      const instance = axios.create({
        method: 'GET',
        params: {
          //
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        baseURL: path,
        timeout: timeoutNum // 超时
      });
      instance()
        .then(result => {
          // if (!isprivate) {    //公共

          let r = result.data.data.data;
          securityTokenPublic = {};
          securityTokenPublic = r.publicSTSSecurityToken;
          securityTokenPublic.isprivate = false;
          securityTokenPublic.RegionID = r.RegionID;
          securityTokenPublic.bucket = r.bucket;
          // resolve(result.data.data)
          // } else {     //私有
          if (r.privateSTSSecurityToken) {
            securityTokenPrivate = {};
            securityTokenPrivate = r.privateSTSSecurityToken;
            securityTokenPrivate.isprivate = true;
            securityTokenPrivate.RegionID = r.RegionID;
            securityTokenPrivate.bucket = r.bucket;
          } else {
            if (isprivate) {
              // 当没有课件上传权限的时候直接返回false
              reject(new Error('没有课件上传权限'));
            }
          }
          // console.log('loadSecurityToken', isprivate);
          // console.log('securityTokenPrivate', securityTokenPrivate);
          // console.log('securityTokenPublic', securityTokenPublic);
          if (isprivate) {
            resolve(securityTokenPrivate);
          } else {
            resolve(securityTokenPublic);
          }
          // resolve(result.data.data)
          // }
        })
        .catch(error => {
          reject(error);
          // console.log(error, 'errloadMoudlePowerData');
        });
    });
  };
  let put = (securityToken, fileType, md5Text, file, fileData) => {
    // fileData.callPamras = {
    //   type: "1",
    //   CoursewareID: "2",
    //   AuthorizeCode: "3",
    //   coursewareType: "4"
    // };
    // newOSS对象及参数.
    const client = setOSSClient(securityToken);

    let UploadTypeKey = !securityToken.isprivate ? '1' : '2'; // 上传类型 1-公共上传2-课件上传；3-分片上传
    // console.log('store', store.getters.token);
    let bodyText =
      'bucket=${bucket}&object=${object}&etag=${etag}&size=${size}&mimeType=${mimeType}';
    // bodyText += '&UploadTypeKey=${x:var1}&SaaSClientKey=${x:var2}&identifier=${x:var3}&FileTypeKey=${x:var4}&CoursewareID=${x:var5}&UserID=${x:var6}&UserName=${x:var7}'

    let defaultCustomValue = {
      UploadTypeKey: UploadTypeKey,
      SaaSClientKey: store.getters.token.SaaSClientKey.toString(),
      identifier: md5Text || '',
      FileTypeKey: fileType.toString(),
      UserID: store.getters.token.UserID.toString(),
      UserName: store.getters.token.UserName,
      CoursewareID:
        fileData && fileData.callPamras && fileData.callPamras.CoursewareID
          ? fileData.callPamras.CoursewareID.toString()
          : '',
      DurationNum:
        fileData && fileData.callPamras && fileData.callPamras.DurationNum
          ? fileData.callPamras.DurationNum.toString()
          : '',
      AuthorizeCode:
        fileData && fileData.callPamras && fileData.callPamras.AuthorizeCode
          ? fileData.callPamras.AuthorizeCode.toString()
          : '',
      VisitAuthKey:
        fileData && fileData.callPamras && fileData.callPamras.VisitAuthKey
          ? fileData.callPamras.VisitAuthKey.toString()
          : '',
      MainDemoName:
        fileData && fileData.callPamras && fileData.callPamras.MainDemoName
          ? fileData.callPamras.MainDemoName.toString()
          : '',
      client_id: store.getters.clientId.toString(),
      TypeKey:
        fileData && fileData.callPamras && fileData.callPamras.TypeKey
          ? fileData.callPamras.TypeKey.toString()
          : '',
      // SizeNum:
      // fileData && fileData.callPamras
      //   ? fileData.callPamras.SizeNum || fileData.callPamras.size
      //   : '',
      IsUpdateFile:
      fileData && fileData.callPamras
      ? fileData.callPamras.IsUpdateFile.toString()
      : '0'
    };
    console.log('fileData111111111111',file)
    let myCustomValue = {};
    bodyText = setBodyText(
      bodyText,
      myCustomValue,
      defaultCustomValue,
      fileType
    );
    // console.log('myCustomValue', myCustomValue);
    // console.log('bodyText', bodyText);
    // console.log('filedata', fileData);
    // 设置ossheaders
    const options = setOSSOptions(
      securityToken,
      bodyText,
      myCustomValue,
      fileData
    );
    return new Promise((resolve, reject) => {
      try {
        // 填写OSS文件完整路径和本地文件的完整路径。OSS文件完整路径中不能包含Bucket名称。
        // 如果本地文件的完整路径中未指定本地路径，则默认从示例程序所属项目对应本地路径中上传文件。
        // const buffer =  fileToBuffer(file.get('file'))
        // let fileData = fileToOssFileData(file);
        fileToOssFileData(file).then(ossFileData => {
          // console.log('ossFileData', ossFileData);
          client.put(getUploadFolder(fileType, securityToken.AuthPath, ossFileData.name), ossFileData.data, options).then(result => {
            // console.log(result, 'result');
            if (fileData) {
              fileData.percent = 100;
            }
            resolve(setResultData(result));
          });
        });
        // fileToBuffer(file.get('file')).then(buffer => {
        //   //console.log('buffer', buffer);

        // });
        // console.log('options', options);
      } catch (error) {
        // console.log('error', error);
        reject(error);
      }
    });
  };
  
  let miltipartPut = (securityToken, fileType, md5Text, file, fileData) => {
    // isprivate
    console.log('fileData',fileData,file)
    // newOSS对象及参数.
    const client = setOSSClient(securityToken);

    let UploadTypeKey = !securityToken.isprivate ? '1' : '2'; // 上传类型 1-公共上传2-课件上传；3-分片上传
    // console.log('store', store.getters.token);
    let bodyText =
      'bucket=${bucket}&object=${object}&etag=${etag}&size=${size}&mimeType=${mimeType}';
    // bodyText += '&UploadTypeKey=${x:var1}&SaaSClientKey=${x:var2}&identifier=${x:var3}&FileTypeKey=${x:var4}&CoursewareID=${x:var5}&UserID=${x:var6}&UserName=${x:var7}'
  
    let defaultCustomValue = {
      UploadTypeKey: UploadTypeKey,
      SaaSClientKey: store.getters.token.SaaSClientKey.toString(),
      identifier: md5Text || '',
      FileTypeKey: fileType.toString(),
      UserID: store.getters.token.UserID.toString(),
      UserName: store.getters.token.UserName,
      CoursewareID:
        fileData && fileData.callPamras && fileData.callPamras.CoursewareID
          ? fileData.callPamras.CoursewareID.toString()
          : '',
      DurationNum:
        fileData && fileData.callPamras && fileData.callPamras.DurationNum
          ? fileData.callPamras.DurationNum.toString()
          : '',
      AuthorizeCode:
        fileData && fileData.callPamras && fileData.callPamras.AuthorizeCode
          ? fileData.callPamras.AuthorizeCode.toString()
          : '',
      VisitAuthKey:
        fileData && fileData.callPamras && fileData.callPamras.VisitAuthKey
          ? fileData.callPamras.VisitAuthKey.toString()
          : '',
      MainDemoName:
        fileData && fileData.callPamras && fileData.callPamras.MainDemoName
          ? fileData.callPamras.MainDemoName.toString()
          : '',
      client_id: store.getters.clientId.toString(),
      TypeKey:
        fileData && fileData.callPamras && fileData.callPamras.TypeKey
          ? fileData.callPamras.TypeKey.toString()
          : '',
      // SizeNum:
      //   fileData && fileData.callPamras
      //     ? fileData.callPamras.SizeNum || fileData.callPamras.size
      //     : '',
      IsUpdateFile:
          fileData && fileData.callPamras
          ? fileData.callPamras.IsUpdateFile.toString()
          : '0'
    };
    let myCustomValue = {};
    bodyText = setBodyText(
      bodyText,
      myCustomValue,
      defaultCustomValue,
      fileType
    );
    // console.log('myCustomValue', myCustomValue);
    // console.log('bodyText', bodyText);

    const options = setOSSOptions(
      securityToken,
      bodyText,
      myCustomValue,
      fileData
    );
    // console.log('options', options);
    return new Promise((resolve, reject) => {
      try {
        // 填写OSS文件完整路径和本地文件的完整路径。OSS文件完整路径中不能包含Bucket名称。
        // 如果本地文件的完整路径中未指定本地路径，则默认从示例程序所属项目对应本地路径中上传文件。
        // const buffer =  fileToBuffer(file.get('file'))
        fileToOssFileData(file).then(ossFileData => {
          // console.log('ossFileData', ossFileData);
          client.multipartUpload(getUploadFolder(fileType, securityToken.AuthPath, ossFileData.name), ossFileData.data, options).then(result => {
            // console.log(result, 'multipartUploadResult');
            resolve(setResultData(result));
          });
        });
        // fileToBuffer(file.get('file')).then(buffer => {
        //   //console.log('buffer', buffer);
        //   client.multipartUpload(
        //       getUploadFolder(fileType, securityToken.AuthPath, file.get('file').name),
        //       new OSS.Buffer(buffer),
        //       options
        //     )
        //     .then(result => {
        //       //console.log(result, 'multipartUploadResult');
        //       resolve(setResultData(result));
        //     });
        // }, error => {
        //   reject(error);
        // });
        // console.log('options', options);
      } catch (error) {
        // console.log('error', error);
        if (error.code === 'ConnectionTimeoutError') {
          // console.log('TimeoutError');
          // do ConnectionTimeoutError operation
        }
        reject(error);
      }
    });
  };
  this.upload = (type, md5Text, file, fileData, isprivate) => {
    // console.log('this.upload', type, md5Text, file, fileData, isprivate);
    return new Promise((resolve, reject) => {
      // isprivate, type, md5Text, file, fileData
      getSecurityToken(isprivate).then(securityToken => {
        // put or miltipartPut
        let fileObj = getFile(file);
        let isMuti = fileObj.size >= 1024 * 1024 * 10;
        // 判断是否分片上传 -决定调用哪个方法
        let uploadPromiseBuilder = isMuti ? miltipartPut : put;
        let checkFileExitPromise = null;
        if (isMuti) {
          checkFileExitPromise =
            fileData && fileData.checkFileExitPromise
              ? fileData.checkFileExitPromise
              : publicCheckFileExit(md5Text);
        } else {
          checkFileExitPromise = publicCheckFileExit(); // fileData && fileData.checkFileExitPromise ? fileData.checkFileExitPromise : publicCheckFileExit()
        }
        checkFileExitPromise.then(
          r => {
            // console.log('checkFileExitPromise', r);
            uploadPromiseBuilder(securityToken, type, md5Text, file, fileData)
              .then(result => {
                resolve(result);
              })
              .catch(error => {
                reject(error);
              });
          },
          error => {
            if (error.code > 0) {
              // r cover
              // console.log('已上传过。', error);
              let resultData = {
                code: 0,
                data: error.data
              };
              if (fileData) {
                fileData.percent = 100;
              }
              if (typeof error.data != 'string') {
                resultData.data = error.data;
              } else {
                resultData.data = {
                  url: '/' + error.data
                };
              }
              resolve(resultData);
            } else {
              reject(error);
            }
          }
        );
      });
    });
  };
  // this.delete = (isprivate, type, md5Text, file, fileData)=>{}

  return this;
}

let OSSUploader = new InitOssSetting();

function OSSUploadFiles (type, md5Text, file, fileData, isp) {
  return new Promise((resolve, reject) => {
    // Check File
    if (CheckFile(type, file, isp, resolve, reject)) {
      // console.log('CheckFile true');
      OSSUploader.upload(type, md5Text, file, fileData, isp).then(
        res => {
          // console.log('OSSUploader.upload', res);
          resolve(res);
        },
        err => {
          // console.log('OSSUploader.uploadError', err);
          reject(err);
        }
      );
    } else {
      // console.log('CheckFile false');
    }
  });
}

//
export function OSSUploadFilesPublic (
  type,
  md5Text,
  file,
  fileData,
  fileParameter
) {
  if (fileParameter) {
    Object.assign(fileData.callPamras, fileParameter);
  }
  return OSSUploadFiles(type, md5Text, file, fileData, false);
}

//
export function OSSUploadFilesPrivate (type, md5Text, file, fileData) {
  return OSSUploadFiles(type, md5Text, file, fileData, true);
}

export function OSSUploadFilesPrivateKJ (
  file,
  type,
  fileData,
  coursewareParameter
) {
  let fileObj = getFile(file);
  let totalSize = fileObj.size;
 
  console.log("fileObj.name",fileObj.name)
  console.log("fileObj.lastModified",fileObj.lastModified)
  let ext = fileObj.name.split('.')[fileObj.name.split('.').length - 1];
  console.log('extextextext',ext)
  let stringmd5 =
    store.getters.SaaSClientInfo.OLAPKey +
    totalSize +
    ext + fileObj.name +
    (fileObj.lastModified + 18);
  let md5Text = md5(stringmd5);
  console.log('md5Text',md5Text)
  // console.log("coursewareParameter",coursewareParameter)
  coursewareParameter = coursewareParameter || {};
  fileData.callPamras = {
    identifier: md5Text,
    // type: type,
    // totalSize: totalSize,
    // ext: ext,
    DurationNum: fileData ? fileData.DurationNum : '',
    CoursewareID: coursewareParameter.CoursewareID || '',
    AuthorizeCode: coursewareParameter.AuthorizeCode || '',
    TypeKey: coursewareParameter.coursewareType || '',
    MainDemoName: coursewareParameter.MainDemoName || '',
    VisitAuthKey: coursewareParameter.VisitAuthKey || '',
    // SizeNum: coursewareParameter.SizeNum || coursewareParameter.size || '',
    IsUpdateFile: coursewareParameter.IsUpdateFile || ''
  };
  fileData.checkFileExitPromise = privateCheckFileExit(
    md5Text,
    coursewareParameter.CoursewareID,
    type,
    coursewareParameter.coursewareType,
    store.getters.clientId,
    totalSize,
    coursewareParameter.AuthorizeCode,
    coursewareParameter.MainDemoName,
    coursewareParameter.VisitAuthKey
  );
  return OSSUploadFilesPrivate(type, md5Text, file, fileData);
}

function setOSSClient (securityToken) {
  return new OSS({
    // yourregion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
    region: securityToken.RegionID, // obj.CallBackUrl,
    // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
    accessKeyId: securityToken.AccessKeyId,
    accessKeySecret: securityToken.AccessKeySecret,
    stsToken: securityToken.SecurityToken,
    // 填写Bucket名称，例如examplebucket。
    // 填写Bucket名称。关于Bucket名称命名规范的更多信息，请参见Bucket。
    bucket: securityToken.bucket
  });
}
function setOSSHeaders (securityToken) {
  return {
    // 指定该Object被下载时网页的缓存行为。
    // 'Cache-Control': 'no-cache',
    // 指定该Object被下载时的名称。
    // 'Content-Disposition': 'oss_download.txt',
    // 指定该Object被下载时的内容编码格式。
    // 'Content-Encoding': 'UTF-8',
    // 指定过期时间。
    // 'Expires': 'Wed, 08 Jul 2022 16:57:01 GMT',
    // 指定Object的存储类型。
    // 'x-oss-storage-class': 'Standard',
    // 指定Object的访问权限。
    'x-oss-object-acl': !securityToken.isprivate
      ? 'public-read-write'
      : 'private'
    // 设置Object的标签，可同时设置多个标签。
    // 'x-oss-tagging': 'Tag1=1&Tag2=2',
    // 指定CopyObject操作时是否覆盖同名目标Object。此处设置为true，表示禁止覆盖同名Object。
    // 'x-oss-forbid-overwrite': 'true',
  };
}
function setOSSOptions (securityToken, bodyText, myCustomValue, fileData) {
  let headers = setOSSHeaders(securityToken);
  // 设置ossheaders
  const progress = (p, _checkpoint) => {
    // Object的上传进度。
    if (fileData) {
      fileData.percent = Math.ceil(Number(p) * 100);
      if (fileData.progressBack) {
        fileData.progressBack(Number(p));
      }
    }
    // console.log(p, 'progress', fileData);
    // 分片上传的断点信息。
    // console.log(_checkpoint);
  };
  const options = {
    headers: headers,
    callback: {
      // 设置回调请求的服务器地址，例如http://oss-demo.aliyuncs.com:23450。
      url: securityToken.CallBackUrl,
      // （可选）设置回调请求消息头中Host的值，即您的服务器配置Host的值。
      // host: 'yourCallbackHost',
      // 设置发起回调时请求body的值。
      body: bodyText,
      // 设置发起回调请求的Content-Type。
      contentType: 'application/x-www-form-urlencoded',
      // 设置发起回调请求的自定义参数。
      customValue: myCustomValue
    }
  };
  if (progress) {
    options.progress = progress;
  }
  return options;
}
function setBodyText (bodyText, myCustomValue, defaultCustomValue, fileType) {
  let keyIndex = 0;
  let newValue = bodyText;
  if (fileType == 1) {
    newValue += '&imageInfo.height=${imageInfo.height}&imageInfo.width=${imageInfo.width}&imageInfo.format=${imageInfo.format}';
  }
  // 处理字段参数拼接问题.
  for (let key in defaultCustomValue) {
    keyIndex++;
    newValue += '&' + key + '=${x:var' + keyIndex + '}';
    myCustomValue['var' + keyIndex] = defaultCustomValue[key];
  }
  // console.log('setBodyText', newValue); // foo, bar

  return newValue;
}
//
function CheckFile (type, file, isprivate, resolve, reject) {
  let fileObj = getFile(file);
  let text = '';
  if (utils.isTest() && !fileObj) {
    text = '非法请求：缺少上传文件：file';
    // layer.alert('非法请求：缺少上传文件：file');
    // return false;
  }
  if (isprivate) {    // 私有
    if (!privateCheckFileExt(type, getFileLastName(fileObj.name))) {      // 验证文件类型
      text = '上传文件类型不符合，只能上传' + commonGetFileType(type, '2');
      // layer.alert(text)
      // return false
    } else if (!privateCheckFileSize(type, fileObj.size)) {    // 文件超过大小 -提示
      text = '上传文件不能超过' + commonGetFileSize(type, '2');
      // layer.alert(text);
      // return false
    }
  } else {
    // 公有
    if (!publicCheckFileExt(type, getFileLastName(fileObj.name))) {
      text = '上传文件类型不符合，只能上传' + commonGetFileType(type, '1');
      // layer.alert(text)
      // return false
    } else if (!publicCheckFileSize(type, fileObj.size)) {  // 文件超过大小 -提示
      text = '上传文件不能超过' + commonGetFileSize(type, '1');
      // layer.alert(text);
      // return false
    }
  }
  if (text) {
    let errorData = {
      code: 10000,
      data: {
        // url: '/' + result.name
      },
      msg: text
    };
    reject(errorData);
    return false;
  } else {
    return true;
  }
  // return true;
}
// 公共模式验证文件大小。
function commonGetFileType (fileType, type) {
  var text = '';
  let fileExtsList = type == 1 ? initJson.uploadType.publicTypeList[fileType].fileExtsList : initJson.uploadType.privateTypeList[fileType].fileExtsList;
  fileExtsList.forEach((o, index) => {
    if (index == fileExtsList.length - 1) {
      text += o;
    } else {
      text += o + ',';
    }
  });
  return text + '类型';
}
// 公共模式验证文件大小。
function commonGetFileSize (fileType, type) {
  var maxSize = 4 * 1024 * 1024;
  let obj = type == 1 ? initJson.uploadType.publicTypeList[fileType] : initJson.uploadType.privateTypeList[fileType];
  maxSize = obj.size;
  return Number(maxSize) / 1024 / 1024 + 'MB';
}
// 公共验证文件后缀。
function publicCheckFileExt (fileType, fileExt) {
  // console.log('publicCheckFileExt', fileExt, fileType, initJson)
  let fileExtsList = [];
  if (fileType > 0) {
    fileExtsList = initJson.uploadType.publicTypeList[fileType].fileExtsList;
  }
  if (fileExtsList.length > 0) {
    let findItem = fileExtsList.find(o => {
      return '.' + o == fileExt.toLowerCase();
    });
    if (!findItem) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}
// 私有验证文件后缀。
function privateCheckFileExt (fileType, fileExt) {
  let fileExtsList = [];
  if (fileType > 0) {
    fileExtsList = initJson.uploadType.privateTypeList[fileType].fileExtsList;
  }
  if (fileExtsList.length > 0) {
    let findItem = fileExtsList.find(o => {
      return '.' + o == fileExt.toLowerCase();
    });
    // console.log(fileExt,"fileExt",fileExtsList,findItem)
    if (!findItem) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}
// 公共模式验证文件大小。
function publicCheckFileSize (fileType, FileSize) {
  var maxSize = 4 * 1024 * 1024;
  if (fileType > 0) {
    maxSize = initJson.uploadType.publicTypeList[fileType].size;
  }
  if (FileSize > maxSize) {
    return false;
  } else {
    return true;
  }
}
// 私有模式验证文件大小
function privateCheckFileSize (fileType, FileSize) {
  var maxSize = 8 * 1024 * 1024;
  if (fileType > 0) {
    maxSize = initJson.uploadType.privateTypeList[fileType].size;
  }
  if (FileSize > maxSize) {
    return false;
  } else {
    return true;
  }
}
// file转buffer
let fileToOssFileData = file => {
  return new Promise((resolve, reject) => {
    try {
      let fileData = getFileData(file);
      let fileObj = fileData.fileObj;
      if (fileData.isFake) {
        const reader = new FileReader();
        // console.log(reader, 'fileToBuffer');
        reader.onload = () => {
          // console.log('文件读取成功', fileObj);
          resolve({name: fileObj.name,
            data: new OSS.Buffer(reader.result)
          });
        };
        reader.onerror = (error) => {
          // console.log('文件读取异常', fileObj, error);
          reject(new Error('文件读取异常'));
        };
        reader.readAsArrayBuffer(fileObj);
      } else {
        resolve({name: fileObj.name,
          data: fileObj
        });
      }
    } catch (error) {
      // console.log('文件读取异常', file, error);
      reject(new Error('文件读取异常'));
    }
  });
};
// 请求成功的时候设置返回对象跟以前一样的结构。
function setResultData (result) {
  let resultData = {
    code: 0,
    data: {
      url: '/' + result.name
    },
    msg: '正常'
  };

  if (result.data.callBackReturn && result.data.callBackReturn.data) {
    resultData.data = result.data.callBackReturn.data;
    resultData.data.url = '/' + result.name;
  }
  if (result.data.callBackReturn && result.data.callBackReturn.SizeNum) {
    resultData.data.SizeNum = result.data.callBackReturn.SizeNum || 0;
  }
  console.log('setResultData', resultData, result);
  return resultData;
}
// 获取文件后缀名
function getFileLastName (value) {
  let type = value.substr(value.lastIndexOf('.')) || '';
  // console.log(type, 'getFileLastName1111');
  return type;
}
// 公共拼接路径.
function getUploadFolder (fileType, AuthPath, fileName) {
  var path = '';
  var picName =
    utils.formatDatet(Date.new(), 'yyyy') +
    utils.formatDatet(Date.new(), 'MM') +
    utils.formatDatet(Date.new(), 'dd') +
    Math.ceil(Math.random() * 1000000);
  // console.log('picName', picName, fileName);
  fileName = fileName || '.png';
  if (fileType > 0) {
    path =
      AuthPath +
      initJson.uploadType.publicTypeList[fileType].path +
      picName +
      getFileLastName(fileName);
  }
  return path;
}

// 获取文件对象
function getFile (file) {
  let fileObj = null;
  if (file instanceof FormData) {
    fileObj = file.get('file');
  } else if (file instanceof File) {
    fileObj = file;
  } else {
    let objname = Object.prototype.toString.call(file);
    if (Object.prototype.toString.call(file) === '[object File]') {
      // console.log('file is s file', file);
      fileObj = file;
    } else if (objname === '[object FormData]') {
      // console.log('file is s FormData', file);
      fileObj = file.get('file');
    } else {
      console.error('file is unknow', file);
      fileObj = file;
    }
  }
  return fileObj;
}

function getFileData (file) {
  if (file instanceof FormData) {
    let f = file.get('file');
    return getFileData(f);
  } else if (file instanceof File) {
    return {
      fileObj: file,
      isFile: true,
      isFake: false
    };
  } else {
    let objname = Object.prototype.toString.call(file);
    if (objname === '[object File]') {
      // console.log('file is s file', file);
      return {
        fileObj: file,
        isFile: false,
        isFake: true
      };
    } else if (objname === '[object FormData]') {
      // console.log('file is s FormData', file);
      let f = file.get('file');
      return getFileData(f);
    } else {
      // console.log('file is unknow', file);
      return {
        fileObj: file,
        isFile: false,
        isFake: false
      };
    }
  }
}
