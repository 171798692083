<template>
  <div class="cell_course">
    <!-- 课程 -->
    <div class="course_loop_box" 
           v-for="(item,key) in courseItem"
           :key="key">
       <div><span>{{item}}</span></div>
    </div>
  </div>
</template>
<script>

// import classContent from './class-content';
export default {
  data () {
    return {

    };
  },
  props: {
    courseItem: [Array] // 列表数据

  },
  components: {

  },
  created () {

  },
  watch: {

  },
  mounted () {

  },
  methods: {

  },

  computed: {
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    }
  }
};
</script>
