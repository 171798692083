<template>
  <div class="attendance_course_date_list">
    <div class="form_header_gray">
      <div class="form_header_text">
        {{$utils.formatDateStr(dateItem.date,'yyyy')+'年'}}{{$utils.formatDateStr(dateItem.date,'M')+'月'}}
      </div>
      <div class="form_header_text">
        签到 {{vSignCount}}次
        <span v-if="vLeaveCount>0">, 请假 {{vLeaveCount}}次</span>
        <span v-if="vKQCount>0">, 旷课 {{vKQCount}}次</span>
        <span v-if="vHourCount>0">, 课消 {{vHourCount}}课时</span>
      </div>
    </div>
    <div class="attendance_course_info_ul">
      <atten-item v-for="(item,key) in dateItem.childList"
                  :bantchChgCourse="bantchChgCourse"
                  :key="key"
                  :item="item"></atten-item>
    </div>
  </div>
</template>

<script>
import attenItem from './atten-item.vue';
export default {
  data () {
    return {

    };
  },
  props: {
    dateItem: Object,
    bantchChgCourse: { // 是否选择批量换课单扣
      type: Boolean,
      default: false
    }
  },
  components: {
    attenItem
  },
  created () {

  },
  computed: {
    vSignCount () {
      var num = 0;
      this.dateItem.childList.forEach(o => {
        if (o.DeductTypeKey == 2) {
          num++;
        }
      });
      return num;
    },
    vLeaveCount () {
      var num = 0;
      this.dateItem.childList.forEach(o => {
        if (o.DeductTypeKey == 10) {
          num++;
        }
      });
      return num;
    },
    vKQCount () {
      var num = 0;
      this.dateItem.childList.forEach(o => {
        if (o.DeductTypeKey == 3) {
          num++;
        }
      });
      return num;
    },
    vHourCount () {
      var num = 0;
      this.dateItem.childList.forEach(o => {
        num += Number(o.DeductCount);
      });
      return Number(num.toFixed(1));
    }
  },
  methods: {}
};
</script>

<style>

</style>
