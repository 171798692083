<template>
  <div class="max_courseware">
    <div class="courseware_list"
         @mouseleave.stop="mouseOutEvent"
         :class="{opt:dataInfo.isSelected,type_01: vIsUseUp,type_02:vAuthorizeStatus}">
      <div class="max_list_box">
        <div class="list_img"
             @mouseenter="mouseOverEvent">
          <div class="list_img_btn_tomove mover"
               :title="'按住可拖动排序'"
               @click.stop="stopClick"
               v-if="isShowBtn && type != 'SQ'"
               @mousedown.stop="moveStart"
               @mouseup="stopMove">

          </div>
          <div class="list_number">{{ShowOrder}}</div>
          <img class="img_box"
               :src="vCoverImg"
               alt="">
        </div>
        <div class="max_list_info">
          <div class="list_name">
            {{dataInfo.MainDemoName}}
          </div>
          <div class="list_text">
            <span>课件：{{Number(dataInfo.CoursewareCount)||0}}</span>
            <span class="capacity">容量：{{this.$utils.capacityFormate(Number(dataInfo.OccupiedCapacity))}}</span>
            <span class="status_type"
                  v-if="dataInfo.Source == 1">授权</span>
            <span class="status_type"
                  style="background-color: rgb(219 253 220);;color: #3BCD41;"
                  v-if="dataInfo.Source == 2">自研</span>
          </div>
        </div>
      </div>
      <!-- <div class="list_btn">
        <div class="list_left"
             @click="doListSort('Top')"
             v-if="Number(ShowOrder+1)>2"></div>
        <div class="list_right"
             @click="doListSort('Bottom')"
             v-if="Number(ShowOrder+1)<listLength"></div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isShowMenuBeing: true, // 是否已是移出状态
      newOperateMenu: [],
      lastClickTime: null,
      isShowBtn: false
    };
  },
  props: {
    listLength: Number,
    dataInfo: Object,
    type: String,
    ShowOrder: Number
  },
  computed: {
    // 是否已用完
    vIsUseUp () {
      if (this.type == 'SQ' || this.dataInfo.type == 0) {
        // type类型为回收站
        if (this.dataInfo.Authorize) {
          return Number(this.dataInfo.Authorize.isUseUp) == 1;
        } else {
          return Number(this.dataInfo.isUseUp) == 1;
        }
      } else {
        return false;
      }
    },
    // 是否暂停授权
    vAuthorizeStatus () {
      if (this.type == 'SQ' || this.dataInfo.type == 0) {
        // type类型为回收站
        if (this.dataInfo.Authorize) {
          return Number(this.dataInfo.Authorize.AuthorizeStatusKey) == 0;
        } else {
          return Number(this.dataInfo.AuthorizeStatusKey) == 0;
        }
      } else {
        return false;
      }
    },
    // 非自研课件时不显示添加按钮
    vCoverImg () {
      if (this.dataInfo.CoverUrl) {
        return this.$store.getters.CDNURL + this.dataInfo.CoverUrl;
      } else {
        return '';
      }
    }
  },
  created () { },
  methods: {
    // 鼠标移入,显示按钮
    mouseOverEvent () {
      // console.log(this.dataInfo, '鼠标移入');

      this.isShowBtn = true;

    },
    moveStart () {
      this.$emit("moveStart", this.dataInfo)
    },
    stopMove () {
      this.$emit("stopMove", this.dataInfo)
    },
    // 鼠标移入,显示操作菜单
    showOperateMenu () {
      this.isShowOperateMenu = true;
    },
    // 鼠标离开
    mouseOutEvent () {
      this.isShowBtn = false;
      this.isShowOperateMenu = false;
    },
    // 排序
    doListSort (sort) {
      if (this.checkCanClick()) {
        this.$emit('doListSort', sort, this.dataInfo, (Number(this.ShowOrder)));
        console.log(sort, '排序');
      }
    },
    checkCanClick () {
      let now = Date.new();
      let r = !this.lastClickTime || now - this.lastClickTime > 800;
      if (r) {
        this.lastClickTime = now;
      }
      return r;
    }
  }
};
</script>

<style scoped>
.max_courseware .courseware_list .list_img_btn {
  top: 8px;
  right: 0px;
  background-size: 40px;
}
.courseware_list {
  /* position: relative; */
  display: flex;
  width: 300px;
  float: left;
  margin: 0 15px 20px 20px;
}
.courseware_list .max_list_box {
  height: 230px;
  background: #fff;
  border-radius: 8px;
  padding: 6px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-bottom: 20px;
}
.courseware_list .list_btn {
  position: relative;
  width: 0px;
}
.courseware_list .list_left {
  position: absolute;
  left: -310px;
  right: 0px;
  height: 18px;
  cursor: pointer;
  bottom: 0px;
  top: 250px;
  width: 10px;
  background: url(../../../public/image/arrows_top.png) no-repeat center center;
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg); /* IE 9 */
  -moz-transform: rotate(-90deg); /* Firefox */
  -webkit-transform: rotate(-90deg); /* Safari 和 Chrome */
  -o-transform: rotate(-90deg); /* Opera */
}

.courseware_list .list_left:hover {
  background-image: url(../../../public/image/arrows_top_blue.png);
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg); /* IE 9 */
  -moz-transform: rotate(-90deg); /* Firefox */
  -webkit-transform: rotate(-90deg); /* Safari 和 Chrome */
  -o-transform: rotate(-90deg); /* Opera */
}

.courseware_list .list_right {
  position: absolute;
  height: 18px;
  bottom: 0px;
  top: 250px;
  left: -30px;
  right: 0;
  cursor: pointer;
  width: 10px;
  background: url(../../../public/image/arrows_bottom.png) no-repeat center
    center;
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg); /* IE 9 */
  -moz-transform: rotate(-90deg); /* Firefox */
  -webkit-transform: rotate(-90deg); /* Safari 和 Chrome */
  -o-transform: rotate(-90deg); /* Opera */
}

.courseware_list .list_right:hover {
  background-image: url(../../../public/image/arrows_bottom_blue.png);
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg); /* IE 9 */
  -moz-transform: rotate(-90deg); /* Firefox */
  -webkit-transform: rotate(-90deg); /* Safari 和 Chrome */
  -o-transform: rotate(-90deg); /* Opera */
}

.courseware_list .max_list_info {
  width: 280px;
  height: 50px;
  cursor: pointer;
  margin: 10px 10px 10px 15px;
}
.max_courseware .auto_release_text {
  position: absolute;
  width: 60px;
  height: 24px;
  left: 225px;
  background: #ff6c67;
  border-radius: 5px;
  bottom: 10px;
  text-align: center;
  line-height: 24px;
  color: #ffffff;
  font-family: 'Alibaba';
  font-size: 12px;
}
.max_courseware .status_type {
  width: 50px;
  height: 20px;
  background: #ffeed0;
  border-radius: 3px;
  color: #fea409;
  font-family: Alibaba;
  font-size: 12px;
  margin-left: 4px;
  float: right;
  text-align: center;
  margin-top: -2px;
}
.capacity {
  padding-left: 2px;
}

.max_courseware_manage .courseware_list .list_img_btn_tomove {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 42px;
  height: 32px;
  cursor: pointer;
  background: url(../../../public/image/courseware_icon_01.png) no-repeat;
  z-index: 1;
}
</style>
