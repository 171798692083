<template>
  <div>
    <el-container class="page">
      <el-main class="page_content">
        <div style="display: block;margin-bottom: 100px;"
             class="client_clew clearfix">
          <div class="apply_payment_details fl">
            <dayClew :formTitle="'今天'"
                     :dataInfo="customerClewData.Total_Today"
                     :dotColor="'dot_green'"
                     @showDetail="showDetail('今天')"></dayClew>
            <dayClew :formTitle="'昨天'"
                     :dataInfo="customerClewData.Total_Yesterday"
                     :dotColor="'dot_blue'"
                     @showDetail="showDetail('昨天')"></dayClew>
            <dayClew :formTitle="'本月'"
                     :dataInfo="customerClewData.Total_ThisMonth"
                     :dotColor="'dot_cyan'"
                     @showDetail="showDetail('本月')"></dayClew>
            <dayClew :formTitle="'历史总计'"
                     :dataInfo="customerClewData.Total"
                     :dotColor="'dot_purple'"
                     @showDetail="showDetail('历史总计')"></dayClew>
          </div>
          <div class="apply_payment_echarts fl">
            <systemReport @showDetail="showDetail"></systemReport>
            <wktMobileReport @showDetail="showDetail"></wktMobileReport>
          </div>
        </div>
      </el-main>
      <!-- 弹窗区域 -->
      <!-- 客户线索列表 -->
      <list ref="customerGrade"
            :listTitle="'客户线索列表'"
            :extendSearchCondition="extendSearchCondition"
            :functionBtnGroup="functionBtnGroup"
            :tableData="customerGradeList"
            :totalNum="totalNum"
            :PageCount="PageCount"
            :tableColumns="tableColumns"
            @actionBtnClick="doAfterFunctionBtnClick"
            @tableBtnClick="doAfterTableBtnClick"
            @loadTableData="loadTableData"></list>
      <!-- 登记客户弹窗 -->
      <register-customer ref="registerCustomerShow"
                         :elTitle="elTitle"
                         :chgMobile="chgMobile"
                         :registerCustomerVisible="registerCustomerVisible"
                         :studentInfo="studentItem"
                         @closeRegisterCustomer="closeRegisterCustomer"></register-customer>

    </el-container>

    <div>
      <dialog-factory ref="dialogFactory"></dialog-factory>
    </div>
  </div>
</template>
<script>
import { getClewStatistics, MessageSearchPageForStudent } from '../../../API/workbench';
import systemReport from './system-report';
import wktMobileReport from './wkt-mobile-report';
import dayClew from './day-for-clew';
import list from '../../list/index';
import registerCustomer from '../../pop-up-menu/register-customer-details';
import dialogFactory from '../../common/dialog-factory';
export default {
  name: 'customerClew',
  data () {
    return {
      customerClewData: {},
      value1: '',
      registerCustomerVisible: false, // 登记客户弹窗
      studentItem: {}, // 目标学生信息
      elTitle: '', // 登记客户弹窗的标题
      chgMobile: false, // 登记客户——联系人号码禁用与否
      PageCount: 0,
      totalNum: 0,
      functionBtnGroup: [{ name: '新增', eventName: this.$initJson.baseFunctionBtnName.add, icon: '', roleName: '', ModulePowerKey: 48 }], // 功能按钮组
      customerGradeList: [],
      extendSearchCondition: [// 扩展查询条件
        {
          type: this.$initJson.extendSearchConditionType.dateRange, // 日期范围
          formTitle: '登记日期',
          fieldList: [
            {
              name: 'sRegisterTime', // 参数名
              defaultValue: '', // 默认值
              value: '', // 绑定值
              validateHandle: '', // 自定义验证函数
              sourceData: []// 数据源
            },
            {
              name: 'eRegisterTime',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: []
            }
          ]
        },
        {
          type: this.$initJson.extendSearchConditionType.selectOption,
          formTitle: '登记类型',
          fieldList: [
            {
              name: 'registerTypeKey',
              defaultValue: '',
              value: 0,
              validateHandle: '',
              sourceData: [{ key: 2, value: '员工登记' }, { key: 3, value: '微网校拓客' }, { key: 5, value: '录入老生' }, { key: 4, value: '系统导入' }, { key: 24, value: '系统登记' }]
            }
          ]
        },
        {
          type: this.$initJson.extendSearchConditionType.selectOption,
          formTitle: '是否在校',
          fieldList: [
            {
              name: 'CustStatusKey',
              defaultValue: '',
              value: '',
              validateHandle: '',
              sourceData: [{ key: 1, value: '是' }, { key: 0, value: '否' }]
            }
          ]
        }
      ],
      tableColumns: [ // table 列
        {
          label: 'OLAPKey',
          prop: 'OLAPKey',
          width: 0,
          sortable: false,
          align: 'left',
          isShow: false,
          type: '',
          decimalPlace: 0
        },
        {
          label: '登记日期',
          prop: 'RegisterTime',
          width: '',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '学生名',
          prop: 'MainDemoName',
          width: '',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '客户关系/手机号',
          prop: 'RelationKeyValue',
          width: '180',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '性别/年龄',
          prop: 'SexKeyValue',
          width: '',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '是否在校',
          prop: 'IsActiveKeyValue',
          width: '',
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '来源方式',
          prop: 'RegisterTypeKeyValue',
          width: '',
          sortable: '',
          align: 'left',
          isShow: true,
          type: '',
          decimalPlace: 0
        },
        {
          label: '操作',
          prop: '',
          width: '',
          sortable: false,
          align: 'center',
          tableBtnGroup: [
            {
              name: '查看',
              eventName: this.$initJson.baseFunctionBtnName.read,
              Stutas: 'StutasKey',
              className: () => {
                return 'btn_light_blue';
              },
              roleName: ''
            },
            {
              name: '修改',
              eventName: this.$initJson.baseFunctionBtnName.edit,
              Stutas: 'StutasKey',
              className: () => {
                return 'btn_light_blue';
              },

              roleName: '',
              ModulePowerKey: 48
            }
          ],
          isShow: true,
          type: 'tableBtn',
          decimalPlace: 0
        }
      ]
    };
  },
  components: {
    dayClew, systemReport, wktMobileReport, list, registerCustomer, dialogFactory
  },
  mounted () {
    this.initGetClewStatistics();
  },
  methods: {
    initGetClewStatistics () {
      getClewStatistics().then(result => {
        console.log(result.data);
        this.customerClewData = result.data;
      });
    },
    // 关闭登记客户页面
    closeRegisterCustomer () {
      this.registerCustomerVisible = false;
      this.loadTableData(this.$refs.customerGrade.queryParams);
      this.initGetClewStatistics();
    },
    showDetail (type, startTime, endTime) {
      if (startTime && endTime) {
        startTime = startTime.split('/')[0] + '-' + startTime.split('/')[1] + '-' + startTime.split('/')[2];
        endTime = endTime.split('/')[0] + '-' + endTime.split('/')[1] + '-' + endTime.split('/')[2];
      }
      let initSelectedParams = [];
      switch (type) {
        case '今天':
          initSelectedParams.push({ name: 'sRegisterTime', value: this.$utils.formatDatet(Date.new(), 'yyyy-MM-dd') }, { name: 'eRegisterTime', value: this.$utils.formatDatet(Date.new(), 'yyyy-MM-dd') });
          break;
        case '昨天':
          initSelectedParams.push({ name: 'sRegisterTime', value: this.$utils.formatDatet(Date.new(Date.new().getTime() - 24 * 60 * 60 * 1000), 'yyyy-MM-dd') }, { name: 'eRegisterTime', value: this.$utils.formatDatet(Date.new(Date.new().getTime() - 24 * 60 * 60 * 1000), 'yyyy-MM-dd') });
          break;
        case '本月':
          initSelectedParams.push({ name: 'sRegisterTime', value: this.$utils.getCurrentMonthFirst() }, { name: 'eRegisterTime', value: this.$utils.getCurrentMonthLast() });
          break;
        case '历史总计':
          break;
        case '系统登记':
          initSelectedParams.push({ name: 'registerTypeKey', value: 24 }, { name: 'sRegisterTime', value: startTime }, { name: 'eRegisterTime', value: endTime });
          break;
        case '拓客':
          initSelectedParams.push({ name: 'registerTypeKey', value: 3 }, { name: 'sRegisterTime', value: startTime }, { name: 'eRegisterTime', value: endTime });
          break;
        default:
          break;
      }
      this.$refs.customerGrade.isShowListDialog = true;
      this.$refs.customerGrade.initSelectedParams(initSelectedParams);
    },
    // 加载列表数据
    loadTableData (queryParams) {
      if (queryParams.orderBy == '' && queryParams.sequence == '') {
        queryParams.orderBy = 'RegisterTime';
        queryParams.sequence = 'DESC';
      }
      MessageSearchPageForStudent(queryParams).then(result => {
        if (queryParams.IsHiddenKey == 1) {
          this.hiddenNumber = result.data.Total;
        }
        this.customerGradeList = [];
        if (result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach((obj, index) => {
            obj.MobileSupport = this.$utils.phoneModulePower(obj.MobileSupport) || '无手机号';
            obj.RelationKeyValue = obj.RelationKeyValue + '/' + (obj.MobileSupport || '无手机号');
            obj.SexKeyValue = obj.SexKeyValue + '/' + (Number(obj.AgeName) || '无年龄');
            obj.CustStatusKey == 1 ? (obj.IsActiveKeyValue = '是') : (obj.IsActiveKeyValue = '否');
            this.customerGradeList.push(obj);
          });
        }
        this.totalNum = result.data.Total;
        this.PageCount = result.data.PageCount;
        this.$refs.customerGrade.isLoading = false;
      });
    },
    // table 功能按钮点击
    doAfterFunctionBtnClick (eventName) {
      this.elTitle = '登记新生';
      this.chgMobile = false;
      this.studentItem = {};
      this.registerCustomerVisible = true;
    },
    doAfterTableBtnClick (rowData, eventName) {
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.read:
          this.showStudentFile(rowData);
          break;
        case this.$initJson.baseFunctionBtnName.edit:
          this.alterStudentMessage(rowData);
          break;
        default:
          break;
      }
    },
    // 显示学生信息
    showStudentFile (item) {
      let moduleInfo = {
        name: '学生档案',
        routerName: this.$route.name,
        moduleName: 'studentFile',
        data: { studentID: item.OLAPKey }
      };
      this.$bus.emit('needOpenRouter', moduleInfo);
    },
    alterStudentMessage (item) {
      this.elTitle = '修改学生档案';
      this.chgMobile = true;
      this.studentItem = item;
      this.registerCustomerVisible = true;
      this.$refs.registerCustomerShow.getStudentDetailInfo(item.OLAPKey);
      this.$refs.registerCustomerShow.showAllInfo();
    }
  }

};
</script>
