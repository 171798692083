<template>
  <div class="list">
    <div class="formbox">
      <div class="text">渠道</div>
      <el-select :value="search.channel"
                 @change="selectChannelChange">
        <el-option :label="'全部'"
                   :value="''"></el-option>
        <el-option v-for="i in channelList"
                   :key="i.OLAPKey"
                   :label="i.MainDemoName"
                   :value="i.OLAPKey"></el-option>
      </el-select>
      <div class="text">活动</div>
      <el-select :value="search.activity"
                 @change="selectActivityChange">
        <el-option :label="'全部'"
                   :value="''"></el-option>
        <el-option v-for="i in activityList"
                   :key="i.OLAPKey"
                   :label="i.MainDemoName"
                   :value="i.OLAPKey"></el-option>
      </el-select>
      <div class="text">返佣状态</div>
      <el-select :value="search.state"
                 @change="selectStateChange">
        <el-option :label="'全部'"
                   :value="''"></el-option>
        <el-option :label="'待返佣'"
                   :value="'1'"></el-option>
        <el-option :label="'已返佣'"
                   :value="'2'"></el-option>
      </el-select>
      <div class="text">搜索学生</div>
      <el-input v-model="search.text"
                class="input_text"></el-input>
      <a class="btn btn_1"
         @click="searchClick">搜索</a>
      <a class="btn btn_2"
         @click="resetClick">清除条件</a>
    </div>
    <el-table :data="tableData">
      <el-table-column prop="GenerateTime"
                       label="报名日期"
                       width="100"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="MainDemoName"
                       label="来源渠道"
                       width="150"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="StudentKeyValue"
                       label="学生名"
                       width="100"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="MobileNumber"
                       label="手机号"
                       width="150"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="ActivityName"
                       label="方案名"
                       width="150"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="RelevanceActivityKeyValue"
                       label="活动名"
                       width="150"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="ApplyAmount"
                       label="报名金额"
                       width="100"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="RebateAmount"
                       label="返佣金额"
                       width="100"
                       show-overflow-tooltip></el-table-column>
      <el-table-column label="返佣状态"
                       width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.RebateStatusKey==0">待返佣</span>
          <span v-if="scope.row.RebateStatusKey==1">已返佣</span>
        </template>
      </el-table-column>
      <el-table-column label="返佣方式"
                       width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.RebateTypeKey==1">自动返佣</span>
          <span v-if="scope.row.RebateTypeKey==2">手动返佣</span>
        </template>
      </el-table-column>
      <el-table-column prop="RebateTime"
                       label="返佣日期"
                       show-overflow-tooltip></el-table-column>
      <el-table-column label="操作"
                       width="70">
        <template slot-scope="scope">
          <a class="row_btn"
             v-if="scope.row.RebateStatusKey==0"
             @click="rebate(scope.row)">返佣</a>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChange"
                   @current-change="currentChange"
                   :current-page="search.pageIndex"
                   :page-sizes="[5,10,20,30,40,50]"
                   :page-size="search.pageSize"
                   layout="total,prev,pager,next,jumper,sizes"
                   :total="totalNum"
                   background></el-pagination>
  </div>
</template>

<script>
import { GetBelowCustomerChannellist, GetBelowCustomerChannelActivitylist, GetRebateLogList, Rebate } from '../../../API/workbench';
export default {
  data () {
    return {
      channelList: [],
      activityList: [],
      search: {
        channel: '',
        activity: '',
        state: '',
        text: '',
        pageIndex: 1,
        pageSize: 10
      },
      tableData: [],
      totalNum: 0,
      PageCount: 0
    };
  },
  components: {
  },
  props: {
    searchobj: Object
  },
  created () {
    GetBelowCustomerChannellist().then(result => {
      if (result.code == 0) {
        this.channelList = result.data;
      } else {
        console.log(result.msg);
      }
    }).catch(error => {
      console.log(error);
    });
    GetBelowCustomerChannelActivitylist(1).then(result => {
      if (result.code == 0) {
        this.activityList = result.data;
      } else {
        console.log(result.msg);
      }
    }).catch(error => {
      console.log(error);
    });
  },
  mounted () {
    if (this.searchobj.OLAPKey) {
      this.search.channel = this.searchobj.OLAPKey;
      this.search.state = '1';
    }
    this.getList();
  },
  computed: {
  },
  methods: {
    sizeChange (val) {
      this.search.pageSize = val;
      this.search.pageIndex = 1;
      this.getList();
    },
    currentChange (val) {
      this.search.pageIndex = val;
      this.getList();
    },
    selectChannelChange (val) {
      this.search.channel = val;
      this.search.pageIndex = 1;
      this.getList();
    },
    selectActivityChange (val) {
      this.search.activity = val;
      this.search.pageIndex = 1;
      this.getList();
    },
    selectStateChange (val) {
      this.search.state = val;
      this.search.pageIndex = 1;
      this.getList();
    },
    searchClick () {
      this.search.pageIndex = 1;
      this.getList();
    },
    resetClick () {
      this.search.channel = '';
      this.search.activity = '';
      this.search.state = '';
      this.search.pageIndex = 1;
      this.search.text = '';
      this.getList();
    },
    rebate (row) {
      let that = this;
      layer.confirm('确认返佣？', {
        btn: [{
          name: '取消',
          callBack: function () {
            layer.close();
          }
        }, {
          name: '确认',
          callBack: function () {
            layer.close();
            Rebate(row.OLAPKey).then(result => {
              if (result.code == 0) {
                that.getList();
              } else {
                console.log(result.msg);
              }
            }).catch(error => {
              console.log(error);
            });
          }
        }]
      });
    },
    getList () {
      let that = this;
      let params = {
        channel: that.search.channel,
        activity: that.search.activity,
        state: that.search.state,
        text: that.search.text,
        pageIndex: that.search.pageIndex - 1,
        pageSize: that.search.pageSize
      };
      GetRebateLogList(params).then(result => {
        if (result.code == 0) {
          let list = result.data.PageDataList;
          for (let i = 0; i < list.length; i++) {
            list[i].ApplyAmount = '￥' + Number(list[i].ApplyAmount);
            list[i].RebateAmount = '￥' + Number(list[i].RebateAmount);
          }
          that.totalNum = result.data.Total;
          that.PageCount = result.data.PageCount;
          that.tableData = list;
        } else {
          console.log(result.msg);
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
};
</script>

<style scoped>
</style>