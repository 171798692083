<template>
  <div class=" table_list_content">
    <div class="single_result_detail">
      <!-- 筛选条件 -->
      <div class="course_statistics_table_search flex flex_aling_center">
        <div class="summarizing_student_top_box content_top_data_box course_class_summarizing_right ">
          <div class="right_top_box fr"
               style="margin-bottom:0;width:366px">
            <summary-data-item v-for="(item,key,index) in headSummaryData"
                               :sumData="item"
                               :key="index"
                               :canClick="true"
                               @onCilckEvent="doAfterCilckSummaryData"></summary-data-item>
          </div>
        </div>
        <date-range ref="saveDate"
                    style="margin:0 10px 0 92px"
                    :searchObj="searchObj"
                    :fromTitle="'申请日期'"
                    :minDate="vMinDate"
                    @changeDate="changeSaveDate"></date-range>
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入姓名、姓名首字母、手机号'"
                                  :clearAll="true"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="searchData"></input-search-contain-btn>
      </div>
      <div class="course_statistics_table_box table_list_content">
        <table-list class="summarizing_list"
                    :key="tableKey"
                    :tableData="vdataReportList"
                    :totalNum="totalNum"
                    :PageCount="PageCount"
                    :tableColumns="tableCourseColumns"
                    :isSearchText="false"
                    :isNeedInnerScroll="false"
                    :defaultSort="defaultSort"
                    :queryParams="searchObj"
                    :footerContent="vFooterContent"
                    @loadTableData="getDataList"></table-list>

      </div>
    </div>
  </div>
</template>
<script>
import { MessageSearchPageTrialClassForOnlineApplication, CancelTrialClass } from '../../../../API/workbench';
import tableList from '../../../common/table-list/index';
import summaryDataItem from '../../summary-data/item';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
export default {
  data () {
    return {
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        type: '',
        sequence: 'DESC',
        stutasKey: 2,
        CourseNameKey: 0,
        CourseNameKeyValue: '',
        sourceKey: 0,
        sourceKeyValue: '',
        startTime: '', // this.$utils.formatDateStr(Date.new(Date.new().setDate(1)).toLocaleDateString(), 'yyyy-MM-dd'),
        endTime: '', // this.$utils.formatDateStr(Date.new().toLocaleDateString(), 'yyyy-MM-dd'), // this.$utils.formatDateStr(this.$utils.setChoseEndTime(Date.new()), 'yyyy-MM-dd'),
        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      seletedCourseInfo: {},
      dataReportList: [],
      defaultSort: { prop: '', order: 'ascending' },
      headSummaryData: [
        { title: '待安排', stutasKey: 2, className: 'font_blue', value: 50, type: 'Total_Student', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: true },
        { title: '已安排', stutasKey: 4, className: 'font_blue', value: 10, type: 'Num_NotEnought', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false },
        { title: '已取消', stutasKey: 5, className: 'font_red', value: 5, type: 'Num_PlanCount', dataType: 'number', decimalPlace: 0, filterName: true, isSelected: false }
      ],
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'number-item',
          className: 'table_index_gray'
        },
        {
          label: '申清日期',
          prop: 'ApplicantDateTime',
          minWidth: 80,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '学生名',
          prop: 'StudentKeyValue',
          minWidth: 80,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'student',
          extend: {
            studentKey: 'StudentKey', // 学生ID
            // phone: 'CustomerPhoneName',
            click: () => { }
          }
        },
        {
          label: '手机号',
          prop: 'CustomerPhoneName',
          minWidth: 100,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          className: 'font_dual',
          type: 'text-item'
        },
        {
          label: '客户名',
          prop: 'CustKeyValue',
          minWidth: 80,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'

        },
        {
          label: '试课课名',
          prop: 'OrderClassKeyValue',
          minWidth: 80,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '预约要求',
          prop: 'OrderAskForSupport',
          minWidth: 160,
          sortable: 'custom',
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '状态',
          prop: 'StutasKeyValue',
          sortable: 'custom',
          minWidth: 80,
          align: 'left',
          isShow: true,
          type: 'text-item',
          extend: {
            className: 'radio_type',
            setClassName: (rowData) => {
              let className = '';
              console.log(rowData, 'setClassName');
              if (Number(rowData.StutasKey) > 0) {
                switch (Number(this.searchObj.stutasKey)) {
                  case 2:
                    className = '';
                    break;
                  case 4:
                    className = 'type_green';
                    break;
                  case 5:
                    className = 'type_red';
                    break;
                  default:
                    break;
                }
              }
              return className;
            }
          }
        },
        {
          label: '操作',
          prop: 'option',
          type: 'table-btn',
          extend: {
            tableBtnGroup: [
              {
                name: '安排',
                extend: {
                  isHide: (rowData) => {
                    return this.searchObj.stutasKey != 2;
                  },
                  click: (rowData) => {
                    if (this.searchObj.stutasKey == 2) {
                      this.arrangeClick(rowData);
                    }
                  },
                  ModulePowerKey: 0
                }
              },
              {
                name: '取消',
                extend: {
                  isHide: (rowData) => {
                    return this.searchObj.stutasKey != 2;
                  },
                  IsInquiryHint: 'inquiryBtn',
                  setClassName: (rowData) => {
                    if (rowData.inquiryBtn) { // 开启询问提示，显示询问弹窗时，界面按钮置灰，不可操作
                      return rowData.inquiryBtn;
                    }
                  },
                  click: (rowData, callBack) => {
                    if (callBack) {
                      callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: 'table_btn_gray' });
                    }
                    this.cancelClick(rowData, callBack);
                  },
                  ModulePowerKey: 0
                }
              }
            ]
          }
        }
      ]
    };
  },
  components: {
    summaryDataItem, inputSearchContainBtn, tableList
  },
  created () {
    this.getDataList();
  },
  computed: {
    vMinDate () {
      // 获取向前推27年的日期  不用可不减27
      let myDate = new Date(); // 获取今天日期
      let nowDate = new Date(myDate.setFullYear(myDate.getFullYear() - 1));
      let year = nowDate.getFullYear();
      let month = '01';
      let day = '01';
      let hour = '00';
      let minute = '00';
      let second = '01';
      let dateStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      console.log('vMinDate', dateStr);
      return dateStr;
    },
    visHide () {
      return this.searchObj.stutasKey != 2;
    },
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        if (o.StutasKey == 2) {
          o.StutasKeyValue = '待安排';
          o.CustomerPhoneName = this.$utils.phoneModulePower(o.CustomerPhoneName);
        }
        newArr.push(o);
      });
      return newArr;
    },
    vFooterContent () {
      return '共' + this.totalNum + '条数据 ';
    }
  },
  methods: {
    // 汇总数据排序
    doAfterCilckSummaryData (item) {
      console.log('点击汇总数据排序', item);
      this.headSummaryData.forEach(o => { // 高亮显示
        if (o.type == item.type) {
          o.isSelected = true;
        } else {
          o.isSelected = false;
        }
      });
      this.searchObj.stutasKey = item.stutasKey;
      this.searchObj.pageIndex = 0;
      this.dataReportList = [];
      this.getDataList();
    },
    // 汇总数据
    initSummaryData (data) {
      if (data) {
        this.headSummaryData[0].value = Number(data.ToBeArrangeCount);// 待安排
        this.headSummaryData[1].value = Number(data.HasArrangeCount);// 已安排
        this.headSummaryData[2].value = Number(data.CancelCount);// 取消
      }
    },
    // 选择日期
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      // this.searchObj.orderBy = '';
      this.searchObj.type = '';
      // this.searchObj.sequence = 'DESC';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.QueryID = 0;
      this.searchObj.IsExportKey = 0;
      this.searchObj.CourseNameKey = 0;
      this.searchObj.sourceKeyValue = '';
      this.searchObj.sourceKey = 0;
      this.searchObj.AttenceStatusKey = '';
      this.$refs.saveDate.resetTime();
      this.searchData();
    },
    searchData () {
      // this.searchObj.StudentKey = '';
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    getDataList (queryParams) {
      if (queryParams) {
        Object.assign(this.searchObj, queryParams);
      }
      MessageSearchPageTrialClassForOnlineApplication(this.searchObj).then(result => {
        this.initSummaryData(result.data);
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.totalDeductAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'getDataList');
      }).catch(error => {
        console.log(error, 'error');
      });
    },
    // 点击安排
    arrangeClick (item) {
      this.$emit('arrangeClick', item, this.getDataList);
    },
    // 点击取消
    cancelClick (item, callBack) {
      layer.confirm('请问确定取消该预约', {
        btn: [
          {
            name: '取消',
            callBack: function () {
              layer.close();
              if (callBack) {
                callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
              }
            }
          }, {
            name: '确认',
            callBack: () => {
              CancelTrialClass(item.TableID).then(result => {
                console.log(result.data, 'CancelTrialClass');
                this.getDataList();
                this.$emit('updateData');
                if (callBack) {
                  callBack({ IsInquiryHint: 'inquiryBtn', InquiryClassName: '' });
                }
              }).catch(error => {
                layer.alert(error.msg);
              });
              layer.close();
            }
          }
        ]
      });
    }
  }
};
</script>