<template>
  <!-- 学员ID查询欠课时的课. -->
  <div class="courses_purchased_single  single_shadow"
       :class="{ checked_right : item.isChoose }"
       @click.stop="choseItem(item)">
    <div class="courses_purchased_name">
      <span class="courses_name_field point_light_blue">使用课名</span>
      <span class="courses_name_value"
            :title="item.CourseNameKeyValue">{{item.CourseNameKeyValue}}</span>
    </div>
    <div class="courses_purchased_time">
      <span class="courses_name_field point_light_green">欠课时</span>
      <span class="courses_name_value">
        <span>{{$utils.mAmountType(item.totalClassCount,1)}}</span>课时</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'studentOweClassCountItem',
  data () {
    return {
    };
  },
  props: ['item'],
  methods: {
    choseItem (item) {
      this.$emit('sendItem', item);
    }
  }
};
</script>

