<template>
  <div>
    <div class="course_statistics_table_search flex course_statistics_title">

      <div class="course_statistics_search">

        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入课程名'"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>
        <div class="mendian_backstage_search">
          <div class="btn_hover_bg_blue search_btn "
               style="margin: 0;"
               @click.stop="doExportInfoClick">导出</div>
        </div>
      </div>
    </div>
    <div class=" table_list_content">
      <tableList class="summarizing_list"
                 :key="tableKey"
                 :tableData="vdataReportList"
                 :totalNum="totalNum"
                 :PageCount="PageCount"
                 :tableColumns="tableCourseColumns"
                 :isSearchText="false"
                 :isNeedInnerScroll="false"
                 :defaultSort="defaultSort"
                 :queryParams="searchObj"
                 :vFooterTotal="vFooterTotal"
                 @loadTableData="getDataList"></tableList>
    </div>
  </div>
</template>
<script>
import tableList from '../../../../common/table-list/index';
import inputSearchContainBtn from '../../../common/input-search-contain-btn';
import { GetTuitionBalanceCourseList } from '../../../../../API/workbench';
export default {
  data () {
    return {
      historyName: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear() + '年1月1号前',
      isShowHistory: false, // true 2022前/false 2022后
      seletedCourseInfo: {},
      studentDetail: {},
      searchObj: {
        pageIndex: 0,   // 门店id
        pageSize: 10,
        searchText: '',
        orderBy: '',
        sequence: 'DESC',
        GradeKey: '',
        GradeKeyValue: '',
        CourseKey: '',
        CourseKeyValue: '',
        TeacherKey: '',
        TeacherKeyValue: '',
        startTime: '',
        endTime: '',
        IsExportKey: 0
      },
      totalNum: 0,
      totalAmount: 0,
      tableKey: '0',
      PageCount: 0,
      defaultSort: { prop: '', order: 'ascending' },
      tableCourseColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 50,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'table-index',
          className: 'table_index_gray'
        },
        {
          label: '课程名',
          prop: 'MainDemoName',
          width: ' ',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '结余总额',
          prop: 'ValueAmount',
          minWidth: 120,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },

        {
          label: '课时结余',
          prop: 'ClassHourAmount',
          width: ' ',
          minWidth: 100,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'

        },
        {
          label: '单课课时&结余',
          prop: 'OneClassHourAmountValue',
          width: ' ',
          minWidth: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'

        },
        {
          label: '组合课课时&结余',
          prop: 'TwoClassHourAmountValue',
          width: ' ',
          minWidth: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'

        },
        {
          label: '天数结余',
          prop: 'DayAmount',
          minWidth: 120,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'
        },
        {
          label: '单课天数&结余',
          prop: 'OneDayAmountValue',
          width: ' ',
          minWidth: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'

        },
        {
          label: '组合课天数&结余',
          prop: 'TwoDayAmountValue',
          width: ' ',
          minWidth: 140,
          sortable: false,
          align: 'left',
          isShow: true,
          type: 'text-item'

        }

      ],
      dataReportList: [],
      isDebtCourseHoursDetailsShow: false
    };
  },
  components: {
    tableList,
    inputSearchContainBtn
  },
  props: {
    toSearchObj: {
      type: Object
    },
    searchTime: Object,
    searchText: String,
    schoolItem: Object
  },
  created () {
    console.log(this.toSearchObj, 'this.toSearchObj');
    this.schoolKey = this.$route.query.schoolKey || 0;
    this.searchObj.searchText = this.searchText;
  },
  watch: {
    searchTime: {
      handler (c, o) {
        if (c) {
          //  this.getDateRangeCoursewareStat( this.$utils.getCurrentMonthFirst(),this.$utils.getCurrentMonthLast())
          console.log("searchTime")
          setTimeout(o => {
            console.log("searchTime", this.searchObj.searchText)
            this.searchObj.startTime = c.startTime
            this.searchObj.endTime = c.endTime
            this.getDataList();
          }, 100)
        }
      },
      immediate: true,
      deep: true
    },
    'schoolItem.OLAPKey': {
      handler (c, o) {
        if (c) {
          console.log('schoolItem.OLAPKey', this.schoolItem.OLAPKey)
          //  this.getDateRangeCoursewareStat( this.$utils.getCurrentMonthFirst(),this.$utils.getCurrentMonthLast())
          setTimeout(o => {
            this.getDataList();
          }, 100)
        }
      },
    }

  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach(o => {
        o.ValueAmount = Number(o.ValueAmount) == 0 ? '-' : '￥' + Number(Number(o.ValueAmount).toFixed(2));
        o.ClassHourAmount = Number(o.ClassHourAmount) == 0 ? '-' : '￥' + Number(Number(o.ClassHourAmount).toFixed(2));
        o.DayAmount = Number(o.DayAmount) == 0 ? '-' : '￥' + Number(Number(o.DayAmount).toFixed(2));
        o.OneClassHourAmountValue = Number(o.OneClassHourNum) == 0 ? '-' : `<span class="">` + Number(Number(o.OneClassHourNum).toFixed(2)) + `课时/￥` + Number(Number(o.OneClassHourAmount).toFixed(2)) + `</span>`;
        o.TwoClassHourAmountValue = Number(o.TwoClassHourNum) == 0 ? '-' : `<span class="">` + Number(Number(o.TwoClassHourNum).toFixed(2)) + `课时/￥` + Number(Number(o.TwoClassHourAmount).toFixed(2)) + `</span>`;
        o.OneDayAmountValue = Number(o.OneDayNum) == 0 ? '-' : `<span class="">` + Number(Number(o.OneDayNum).toFixed(2)) + `天/￥` + Number(Number(o.OneDayAmount).toFixed(2)) + `</span>`;
        o.TwoDayAmountValue = Number(o.TwoDayNum) == 0 ? '-' : `<span class="">` + Number(Number(o.TwoDayNum).toFixed(2)) + `天/￥` + Number(Number(o.TwoDayAmount).toFixed(2)) + `</span>`;
        newArr.push(o);
      });
      return newArr;
    },
    vFooterTotal () {
      let text;
      if (this.totalAmount > 0) {
        text = '+' + this.$utils.setMoneyformatToParseInt(this.totalAmount);
        text = ',合计' + text + '元';
      } else {
        text = ',合计' + this.$utils.setMoneyformatToParseInt(this.totalAmount) + '元';
      }
      return text;
    }
  },
  mounted () {
    // this.getDataList();
  },
  methods: {
    changeType (item, type) {
      console.log('changeType', item, type);
      this.$emit('changeType', item, type);
    },
    // 清除搜索条件
    clearSearchCondition () {
      this.searchObj.pageIndex = 0;
      this.searchObj.searchText = '';
      this.searchObj.GradeKey = '';
      this.searchObj.CourseKey = '';
      this.searchObj.TeacherKey = '';
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.IsExportKey = 0;
      this.getDataList();
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    // 导出
    doExportInfoClick (callBack) {
      if (this.dataReportList.length > 0) {
        let fn = GetTuitionBalanceCourseList;
        let searchObj = this.$utils.parseJson(this.searchObj);
        searchObj.IsExportKey = 1;
        searchObj.pageIndex = 0;
        searchObj.pageSize = 0;
        let jsonFormate = {
          SheetTitle: '课程结余统计',
          clist: [
            { title: '课程名', cName: 'MainDemoName', backgroundColor: '', fontColor: '', fomate: '', numberzero: '' },
            {
              title: '结余总额', cName: 'ValueAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::ValueAmount::2::0}}"
            },
            {
              title: '课时结余', cName: 'ClassHourAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::ClassHourAmount::2::0}}"
            },
            {
              title: '单课课时', cName: 'OneClassHourNum', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::OneClassHourNum::2::0}}"
            },
            {
              title: '单课结余', cName: 'OneClassHourAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::OneClassHourAmount::2::0}}"
            },
            {
              title: '组合课课时', cName: 'TwoClassHourNum', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::TwoClassHourNum::2::0}}"
            },
            {
              title: '组合课结余', cName: 'TwoClassHourAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::TwoClassHourAmount::2::0}}"
            },
            {
              title: '天数结余', cName: 'DayAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::DayAmount::2::0}}"
            },
            {
              title: '单课天数', cName: 'OneDayNum', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::OneDayNum::2::0}}"
            },
            {
              title: '单课结余', cName: 'OneDayAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::OneDayAmount::2::0}}"
            },
            {
              title: '组合课天数', cName: 'TwoDayNum', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::TwoDayNum::2::0}}"
            },
            {
              title: '组合课结余', cName: 'TwoDayAmount', backgroundColor: '', fontColor: '', fomate: '', numberzero: '', "type": "complex",
              "template": "{{float::TwoDayAmount::2::0}}"
            },

          ]
        };
        console.log(jsonFormate, 'jsonFormate');
        fn(searchObj, this.schoolItem.OLAPKey, jsonFormate).then(result => {
          console.log(result.data, '导出');
          window.location.href = this.$store.getters.APIURL + result.data.url;
        }, error => {
          layer.alert(error.msg);
        });
      } else {
        layer.alert('暂无数据导出');
      }
      console.log('导出');
    },
    getDataList (queryParams) {
      let fn = GetTuitionBalanceCourseList;
      if (queryParams) {
        if (queryParams.orderBy == 'classTimeObj') {
          queryParams.orderBy = 'ClassTime';
        }
        Object.assign(this.searchObj, queryParams);
      }
      console.log(this.searchObj, '22222');
      fn(this.searchObj, this.schoolItem.OLAPKey).then(result => {
        this.totalNum = result.data.Total;
        this.totalAmount = Number(Number(result.data.TotalAmount).toFixed(2));
        this.dataReportList = result.data.PageDataList;
        console.log(result.data, 'MessageSearchClassAwayForCourse');
      }).catch(error => {
        console.log('errorMessageSearchClassAwayForCourse', error.msg);
      });
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(rowData, 'doAfterTableBtnClick', eventName, this.$initJson.baseFunctionBtnName.popDialog);
      switch (eventName) {
        case this.$initJson.baseFunctionBtnName.red:
          this.doAttendanceDetailsClick(rowData);
          break;
        case this.$initJson.baseFunctionBtnName.detail:
          this.seletedItemToAttenDetail(rowData);
          break;
        // seletedItemToAttenDetail
        default:
          break;
      }
    },
    seletedItemToAttenDetail (item) {
      console.log(item, 'seletedItemToAttenDetail');
      let dataInfo = {
        CourseKey: item.CourseNameKey,
        CourseKeyValue: item.CourseNameKeyValue,
        GradeKey: item.GradeClassKey,
        GradeKeyValue: item.GradeClassKeyValue,
        TeacherKey: item.MTeacherKey,
        TeacherKeyValue: item.MTeacherKeyValue,
        ScheduleCourseKey: item.OLAPKey || 0,
        startTime: item.showClassTime.substring(0, 10),
        endTime: item.showClassTime.substring(0, 10)
      };
      let moduleInfo = {
        name: '课消明细',
        moduleName: 'attenDetailReport',
        routerName: this.$route.name,
        data: { dataInfo: dataInfo }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    },
    // 打开课消明细
    openAttenDetail () {
      let dataInfo = {
        startTime: this.searchObj.startTime,
        endTime: this.searchObj.endTime
      };
      let moduleInfo = {
        name: '课消明细',
        moduleName: 'attenDetailReport',
        routerName: this.$route.name,
        data: { dataInfo: dataInfo }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    },
    doAttendanceDetailsClick (item) {
      // if (this.mIsShowArrangeDialog(item)) {
      // this.$refs.arrangeDialog.isAttendanceArrangeShow = true;
      // this.$refs.arrangeDialog.getCourseInfo(item.OLAPKey);
      // }
      let moduleInfo = {
        name: '课程详情',
        moduleName: 'attendanceArrange',
        routerName: this.$route.name,
        data: { scheduleCourseKey: item.OLAPKey, isAlter: false }
      };
      this.$bus.emit('openCustomDialog', moduleInfo);
    }

  }
};
</script>
<style>
.hover {
  cursor: pointer;
}
</style>