<template>
  <div class="edit-period-count edit_class_from">
    <div class="content_marginRL content_marginTop_30">
      <course-info :circleCourseInfo="circleCourseInfo"
                   v-if="vIsShowCircleCourseItem"></course-info>
      <choose-class-time-range :circleCourseInfo="circleCourseInfo" @confirmClassRangeTime="confirmClassRangeTime"></choose-class-time-range>
      <div class="new_course_title border_top_line"
             style="margin-bottom:12px">
          修改内容
        </div>
      <div class="mendian_setting_content form_info_edit">
        <div class="pr">
          <span class="pa input_date_week"
                style="top:0px;line-height: 36px;right:192px"
                v-if="periodCount>0">学生上课消耗</span>
          <input-number-dialog :formTitle="'课消'"
                               :dialogTitleName="'输入课时'"
                               v-model="vPeriodCount"
                               class="no_border_bottom form_info_border_type max_width"
                               :decimalPlaces="1"
                               :isShowButtonInfo="true"
                               :required="true"
                               :min="0"
                               :maxLength="4"
                               :formPlaceholder="'学生上课消耗'"></input-number-dialog>
        </div>

      </div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="closeDilog"></save-cancel-btn-group>
    </div>
  </div>
</template>
<script>
import courseInfo from '../course-info';
import chooseClassTimeRange from './common/choose-class-time-range';
import { EditPeriodCountByRuleId, GetStructuredRuleRecentlyCourseInfo } from '../../../API/workbench';
export default {
  data () {
    return {
      classRangeTimeType: '全部',
      periodCount: '',
      sClassDate: '', // 开始上课时间
      eClassDate: '' // 结束上课时间
    };
  },
  components: {
    courseInfo,
    chooseClassTimeRange
  },
  props: {
    circleCourseInfo: {
      type: Object
    }
  },
  computed: {
    vIsShowCircleCourseItem () {
      let bool = false;
      if (!this.circleCourseInfo.SubRule) { // SubRule为NULL 就显示循环课块
        bool = true;
      }
      return bool;
    },
    vPeriodCount: {
      get () {
        return {
          number: this.periodCount,
          UnitName: this.periodCount > 0 ? '课时 / 节课' : ''
        };
      },
      set (newValue) {
        this.periodCount = newValue.number;
      }
    }
  },
  created () {
    this.sClassDate = this.circleCourseInfo.SubRule ? this.circleCourseInfo.SubRule.StartTime : this.circleCourseInfo.CurrentRuleTime;
    this.eClassDate = this.circleCourseInfo.SubRule ? this.circleCourseInfo.SubRule.EndTime : this.circleCourseInfo.LastClassTime;

    this.getStructuredRuleRecentlyCourseInfo();
  },
  methods: {
    // 排课规律-》获取规律最近一节课课信息
    getStructuredRuleRecentlyCourseInfo () {
      if (this.circleCourseInfo.SubRule) {
        this.periodCount = Number(this.circleCourseInfo.SubRule.PeriodCount);
        return;
      }
      GetStructuredRuleRecentlyCourseInfo(this.circleCourseInfo.StructuredRuleKey).then(result => {
        this.periodCount = Number(result.data.PeriodCount);
      }, error => {
        layer.alert(error.msg);
      });
    },
    confirmClick (callback) {
      if (!this.periodCount) {
        layer.alert('课消必须大于0'); if (typeof callback == 'function') { callback(); }
        return false;
      }
      EditPeriodCountByRuleId(this.circleCourseInfo.StructuredRuleKey,
        this.sClassDate, this.eClassDate, this.periodCount).then(resule => {
          this.$emit('AfterEditCircleCoursePeriodCount', this.circleCourseInfo.StructuredRuleKey);
          layer.alert('修改循环课课消成功');
          this.closeDilog(this.circleCourseInfo.StructuredRuleKey);
        }, error => {
          layer.alert(error.msg);
        }).finally(() => {
          if (typeof callback == 'function') { callback(); }
        });
    },
    closeDilog (ruleID) {
      this.$emit('closeDilog', ruleID);
    },
    // 确认 修改课表范围
    confirmClassRangeTime (startDate, endDate, classNum) {
      this.sClassDate = startDate;
      this.eClassDate = endDate;
    }
  }
};
</script>

<style>
</style>