<template>
  <div class="bg_gray form_info_detail clearfix">
    <div class="purchase_order">
      <div class="signuppayment_course_select_ul bg_white">
        <div class="select_list"
             :class="{opt:signupType ==1 }"
             @click="formData.ApplyTypeKey=1,formData.ApplyTypeKeyValue='新报',signupType=1">
          新生报名
        </div>
        <div class="select_list"
             :class="{opt:signupType ==2 }"
             @click="formData.ApplyTypeKey=2,formData.ApplyTypeKeyValue='续费',signupType=2">
          老生续费
        </div>
        <div class="select_list"
             :class="{opt:signupType ==3 }"
             @click="formData.ApplyTypeKey=3,formData.ApplyTypeKeyValue='扩科',signupType=3">
          老生扩科
        </div>
      </div>
      <!-- 课单详情 -->
      <div class="purchase_order_form form_info_edit label_radio_bg_white">
        <input-dialog :formTitle="'适用课程'"
                      :formPlaceholder="'适用课程'"
                      :readonly="false"
                      :required="true"
                      @showEldilog="openCourseNameList"
                      v-model="formData.ApplyCorName"></input-dialog>
        <input-number-unit :min="0"
                           v-model="formData.NumCount"
                           :decimalPlaces="1"
                           :required="true"
                           :unit="'课时'"
                           :formTitle="'课时数量'"
                           @inputChange="changeNumCount"></input-number-unit>
        <!-- <div class="full_class_box form_info_required"
             style="border-bottom: 1px solid #ececec;">
          <div class="full_class_text">课时数量</div>
          <div class="full_input_box">
            <input type="number"
                   v-model="formData.NumCount"
                   @change="changeNumCount($event.target.value,$event)"
                   class="full_class_input">
            <span class="people_class">课时</span>
          </div>
        </div>
        <div class="full_class_box form_info_required"
             style="border-bottom: 1px solid #ececec;">
          <div class="full_class_text">课时单价</div>
          <div class="full_input_box">
            <input type="number"
                   :value="formData.UnitPrice"
                   @change="changeUnitPrice($event.target.value,$event)"
                   class="full_class_input">
            <span class="people_class">元/课时</span>
          </div>
        </div> -->
        <input-number-unit :min="0"
                           :value="formData.UnitPrice"
                           :decimalPlaces="2"
                           :formTitle="'课时单价'"
                           :unit="'元/课时'"
                           :required="true"
                           @inputChange="changeUnitPrice"></input-number-unit>
        <input-dialog :formTitle="'优惠折扣'"
                      :formPlaceholder="'必填'"
                      :readonly="false"
                      :required="true"
                      v-model="vDiscountCount"
                      @showEldilog="openDisCountDialog"></input-dialog>

        <!-- <div class="full_class_box form_info_required">
          <div class="full_class_text">收费价格</div>
          <div class="full_input_box">
            <input type="number"
                   :max="formData.ValueAmount"
                   :decimalPlaces="2"
                   :value="formData.AfterDisAmount"
                   @change="doAfterChangeAfterDisAmount($event.target.value,$event)"
                   class="full_class_input">
            <span class="people_class">元</span>
          </div>
        </div> -->

        <input-number-unit :min="0"
                           :max="formData.ValueAmount"
                           :readonly="false"
                           :value="formData.AfterDisAmount"
                           :decimalPlaces="2"
                           :required="true"
                           :unit="'元'"
                           :formTitle="'收费价格'"
                           class="no_border_bottom"
                           @inputChange="doAfterChangeAfterDisAmount"></input-number-unit>
      </div>
      <!-- 按钮组 -->
      <div>
        <save-cancel-btn-group :btnText="'下一步'"
                               @confirmClick="confirmClick"
                               @cancelClick="backClick"></save-cancel-btn-group>
      </div>
    </div>
    <custom-dialog :title="'折扣'"
                   width="410px"
                   show-position="top"
                   :visible.sync="isShowDiscountCountDialog"
                   :append-to-body="false"
                   :close-on-click-modal="false"
                   :before-close="closeDiscountDialog">
      <form-discount v-model="formData.DiscountCount"
                     @onConfirm="doAfterChangeDiscountCount"
                     @onClose="closeDiscountDialog"></form-discount>
    </custom-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      signupType: 1, // 报名类型
      formData: {}, // 表单数据
      isShowDiscountCountDialog: false
    };
  },
  props: {
    KDNR: Object
  },
  components: {
  },
  computed: {
    vCardUnit () {
      let unit = '课时';
      switch (Number(this.cardItem.cardKey)) {
        case 5:// 课时卡
          unit = '课时';
          break;
        case 4:// 日卡
          unit = '日';
          break;
        case 3:// 月卡
          unit = '月';
          break;
        case 2:// 年卡
          unit = '年';
          break;
        default:
          break;
      }

      return unit;
    },
    vFormTitle () {
      let formTitle = '';
      if (!this.formData.ApplyCorName) {
        formTitle = '购买课单';
      } else {
        formTitle = '编辑课单';
      }
      return formTitle;
    },
    vCourseNameList () {
      return (this.$store.getters.courseNameList && this.$store.getters.courseNameList.filter(obj => { return obj.StateKey == 2 && obj.TypeKey == 2; })) || [];
    },
    vDiscountCount: {
      get () {
        let val = '';
        if (this.formData.DiscountCount == 10) {
          val = '原价';
        } else if (this.formData.DiscountCount == 0) {
          val = '赠送';
        } else {
          val = this.$utils.mMoneyType(this.formData.DiscountCount, 1) + '折';
        }
        return val;
      },
      set (newValue) {
        this.formData.DiscountCount = Number(newValue.number);
      }
    }
  },
  watch: {
    vCourseNameList (n, o) {
      this.getCourseNameList();
    }
  },
  created () {
    this.getCourseNameList();
    this.formData = this.$utils.parseJson(this.KDNR);
    this.signupType = this.formData.ApplyTypeKey;
  },
  mounted () {
    this.$dialog.register(this, ['singleSelectionList']);
  },
  methods: {
    // 打开适用课程列表
    openCourseNameList () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        name: '选择课程',
        moduleName: 'singleSelectionList',
        data: { selectedKey: this.formData.ApplyCorIDName, dataSourceList: this.vCourseNameList, required: false, addModuleName: 'course-name-setting', ModulePowerKey: 45 },
        callBack: {
          onSelectedItem: (data) => { this.doAfterChooseCourseName(data); }
        }
      });
    },
    // 适用课程变更后
    doAfterChooseCourseName (item) {
      this.formData.ApplyCorIDName = item.OLAPKey;
      this.formData.ApplyCorName = item.MainDemoName;
      this.formData.UnitPrice = Number(item.UnitPrice);
      this.RecalculationAmount();
    },
    // 收费价格变更后
    doAfterChangeAfterDisAmount (value) {
      this.formData.AfterDisAmount = value;
      if (value == 0) {
        this.formData.DiscountCount = 0;
      } else {
        let disCount = (this.formData.AfterDisAmount / this.formData.ValueAmount) * 10;
        let DateArr = disCount.toString().split('.');
        let firstDecimal = DateArr[1] ? DateArr[1].toString().substring(0, 1) : 0;
        if (firstDecimal == 9) {
          this.formData.DiscountCount = Number(disCount.toString().substring(0, 3));
        } else if (disCount < 0.1) {
          this.formData.DiscountCount = 0.1;
        } else {
          this.formData.DiscountCount = Number(disCount.toFixed(1));
        }
      }
      this.formData.DiscountAmount = this.$utils.mMoneyType(this.formData.ValueAmount - this.formData.AfterDisAmount, 2);
    },
    // 折扣变更后
    doAfterChangeDiscountCount (val) {
      this.formData.DiscountCount = Number(val);
      this.RecalculationAmount();
      this.closeDiscountDialog();
    },
    // 变更单价
    changeUnitPrice (value) {
      console.log('value', value);
      this.formData.UnitPrice = value;
      this.RecalculationAmount();
    },
    // 重算价值跟金额
    RecalculationAmount (value) {
      this.formData.ValueAmount = this.$utils.mMoneyType(this.formData.UnitPrice * this.formData.NumCount, 2); // 单价*数量
      this.formData.AfterDisAmount = this.$utils.mMoneyType((this.formData.ValueAmount * this.formData.DiscountCount) / 10, 2);
      this.formData.DiscountAmount = this.$utils.mMoneyType(this.formData.ValueAmount - this.formData.AfterDisAmount, 2);
    },
    // 课时变更
    changeNumCount (value) {
      this.formData.NumCount = value;
      this.RecalculationAmount();
    },
    // 打开折扣界面
    openDisCountDialog () {
      this.isShowDiscountCountDialog = true;
    },
    // 折扣 取消
    closeDiscountDialog () {
      this.isShowDiscountCountDialog = false;
    },
    // 确定
    confirmClick () {
      const result = this.checkFomDataBeforeSumbit();
      if (result) {
        return false;
      }
      this.$emit('onConfirmClick', this.formData);
    },
    // 验证课单项
    checkFomDataBeforeSumbit () {
      let flag = false;
      if (!this.formData.ApplyCorIDName) {
        layer.alert('适用课程不能为空!');
        flag = true;
      } else if (this.formData.NumCount <= 0) {
        layer.alert('数量不能小于等于0!');
        flag = true;
      } else if (this.formData.UnitPrice <= 0 || !this.formData.UnitPrice) {
        layer.alert('单价不能小于等于0!');
        flag = true;
      } else if (this.formData.DiscountCount < 0) {
        layer.alert('折扣不能小于0!');
        flag = true;
      } else if (this.formData.DiscountCount > 10) {
        layer.alert('折扣不能大于10!');
        flag = true;
      }
      return flag;
    },
    // 课名列表
    getCourseNameList () {
      this.$basicData.loadCourseNameList();
    },
    // 退出
    backClick () {
      this.$emit('onCancelClick');
    }
  }
};
</script>

<style scoped>
.full_class_box {
  display: flex;
  justify-content: space-between;
  height: 47px;
  margin-top: 0px;
}

.full_class_input {
  border: 1px solid #ccc;
  width: 60px;
  text-align: center;
  border-radius: 3px;
  color: #3498db;
}

.full_class_text {
  font-size: 14px;
  color: #666;
  padding-left: 19px;
  line-height: 45px;
}
.full_class_text span {
  font-size: 10px !important;
  color: #ccc !important;
}
.full_input_box {
  margin-top: 12px;
}
</style>