<template>
  <div>
    <div class="select_mode_content">
      <div class="select_mode_content_box">
        <div class="select_mode_list"
             v-for="(item,index) in accountList"
             :key="index"
             :class="{opt:item.isActive}"
             @click="clickActiveEvent(item)">
          {{item.MainDemoName}}
        </div>
      </div>

    </div>
    <!-- 按钮组 -->
    <div>
      <common-button-group @buttonClickEvent="buttonClickEvent"
                           @closeDialog="closeDialog"></common-button-group>
    </div>
  </div>
</template>

<script>
export default {
  props: ['accountList', 'item'],
  data () {
    return {
      incomePayAccount: '' // 收支账户
    };
  },
  methods: {
    clickActiveEvent (item) {
      this.accountList.forEach(o => {
        if (item.OLAPKey == o.OLAPKey) {
          item.isActive = true;
        } else {
          o.isActive = false;
        }
      });
      this.incomePayAccount = item;
    },
    // 确定按钮
    buttonClickEvent () {
      if (!this.incomePayAccount) {
        layer.alert('请选择支付账户');
      } else {
        this.$emit('selectcloseDialog', this.incomePayAccount);
      }
    },
    // 取消按钮
    closeDialog () {
      this.$emit('selectcloseDialog');
    }
  }
};
</script>

  <style>
</style>