<template>
  <!-- 分配投资顾问 -->
  <div>
    <div class="form_info_edit form_info_list">
      <input-select :formTitle="'分配招商顾问'"
                    :id="'OLAPKey'"
                    :name="'MainDemoName'"
                    :required="true"
                    :readonly="isReadonly"
                    :dataList="advisorList"
                    :selectedInfo="curSelectionAdvisor"
                    @selectedItem="chooseAdvisor"></input-select>
    </div>
    <!-- 按钮组 -->
    <save-cancel-btn-group v-if="!isReadonly"
                           :isSingleSubmit="true"
                           :singleSubmitType="'dynamic'"
                           @confirmClick="confirmClick"
                           @cancelClick="cancelClick"></save-cancel-btn-group>
  </div>
</template>

<script>
import { GetCounselorList,AllotInvestor } from '../../../../API/workbench';
export default {
  data () {
    return {
      advisorList: [], // 所属部门信息
      // 提交数据对象
      curSelectionAdvisor: {					
					OLAPKey:0,
          MainDemoName:''
			}
    };
  },
  props: {
    investorIDs: {
      type:[String,Number,Array]
    }, // 投资人ID
    isReadonly:{
      type:Boolean
    }
  },
  created () {
    this.loadEmployee();
  },
  computed: {

  },
  methods: {
    //加载员工
    loadEmployee(){
      GetCounselorList().then(result=>{
        this.advisorList = result.data;
      });
    },
    //选择顾问
    chooseAdvisor(data){
      console.log('选择顾问',data);
      this.curSelectionAdvisor.OLAPKey=data.OLAPKey;
      this.curSelectionAdvisor.MainDemoName=data.MainDemoName;
    },
    // 确认按钮
    confirmClick (callBack) {
      let flag = false;
      if (!this.curSelectionAdvisor.OLAPKey) {
        layer.alert('请选择投资顾问');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      
      this.distributeAdvisor(callBack);
      
    },
    // 分配顾问
    distributeAdvisor (callBack) {
      AllotInvestor(this.curSelectionAdvisor.OLAPKey,this.curSelectionAdvisor.MainDemoName,JSON.stringify(this.investorIDs)).then(result => {
        this.$emit('afterSuccess',  this.curSelectionAdvisor);
        this.cancelClick()
        layer.alert('分配成功');
      }, error => {
        layer.alert(error.msg);
      }).finally(() => {
        if (callBack) {
          callBack();
        }
      });
    },
    cancelClick () {
      this.$emit('cancelClick');
    }
  }
};
</script>

<style>
</style>
