<template>
  <div>
    <!-- 售后跟单 -->
    <div class="after_sale_documentary_list">
      <list ref="studentApplyCourseTable"
            :tableData="customerMendianList"
            :pageTotal="pageTotal"
            :PageCount="PageCount"
            :tableColumns="tableColumns"
            :isSearchText="false"
            :rowKey="'Number'"
            :defaultSort="defaultSort"
            :queryParams="queryParams"
            :reportStatistics="reportStatistics"
            @searchValue="searchValue"
            @loadTableData="getMessageSearchPageForAfterSale"
            @searchStatistics="searchStatistics"
            @clearSearchParams="clearSearchCondition"></list>
    </div>

    <custom-dialog :title="'转介绍'"
                   width="986px"
                   :visible.sync="isShowIntroduceCountPopup"
                   :before-close="closeIntroduceCountPopup">
      <introduce-list :threadInfo="threadInfo"></introduce-list>
    </custom-dialog>

    <custom-dialog :title="'跟进情况'"
                   :visible.sync="isShowUpSellRecordFormPopup"
                   :before-close="closeUpSellRecordFormPopup">
      <up-sell-record :dataInfo="threadInfo"
                      @close="closeUpSellRecordFormPopup"
                      @afterSuccess="getMessageSearchPageForAfterSale"></up-sell-record>
    </custom-dialog>

    <custom-dialog title="全部记录"
                   :visible.sync="isShowRecord"
                   :before-close="closeisShowRecord">
      <recordList :recordList="recordDataList"></recordList>
    </custom-dialog>

    <custom-dialog :title="'付款申请'"
                   :visible.sync="isShowPaymentRequestFormPopup"
                   :before-close="closePaymentRequestFormPopup">
      <payment-request :seletedItem="threadInfo"
                       @close="closePaymentRequestFormPopup"
                       @afterSuccess="getMessageSearchPageForAfterSale"></payment-request>
    </custom-dialog>

    <custom-dialog :title="'清数据'"
                   :visible.sync="isShowCleanDataFormPopup"
                   :before-close="closeCleanDataFormPopup">
      <clean-data :seletedItem="threadInfo"
                  @close="closeCleanDataFormPopup"
                  @afterSuccess="getMessageSearchPageForAfterSale"></clean-data>
    </custom-dialog>

    <custom-dialog title="查看员工"
                   class="refund_dialog defult_dialog"
                   :visible.sync="isShowStaffListDialog"
                   :before-close="doCloStaffList">
      <staff-list :threadInfo="threadInfo"></staff-list>
    </custom-dialog>

    <custom-dialog title="导出"
                   :visible.sync="isShowExportPopup"
                   :before-close="closeExportPopup">
      <export-form :seletedItem="threadInfo"
                   @close="closeExportPopup"
                   @afterSuccess="getMessageSearchPageForAfterSale"></export-form>
    </custom-dialog>

    <custom-dialog :title="'修改客户信息'"
                   width="640px"
                   :visible.sync="isShowAddCustomerFormPopup"
                   :before-close="closeAddCustomerFormPopup">
      <customer-thread :OLAPKey="threadInfo.OLAPKey"
                       :source="sourceInfo"
                       @closeFrom="getMessageSearchPageForAfterSale"></customer-thread>
    </custom-dialog>

    <custom-dialog :title="'详情'"
                   width="1600px"
                   :visible.sync="isShowSaasClientDetails"
                   :before-close="closeSaasClientDetails">
      <saas-client-details :searchParams="queryParams"
                           :mendianId="threadInfo.support20"></saas-client-details>
    </custom-dialog>

    <custom-dialog title="延期"
                   :visible.sync="isShowPostponePopup"
                   :before-close="closePostponePopup">
      <postpone-form :seletedItem="threadInfo"
                     @close="closePostponePopup"
                     @afterSuccess="getMessageSearchPageForAfterSale"></postpone-form>
    </custom-dialog>
    <custom-dialog title="流量赠送"
                   :visible.sync="isShowFlowGivePopup"
                   :before-close="closeFlowGivePopup">
      <flowgive-form :seletedItem="threadInfo"
                     @close="closeFlowGivePopup"
                     @afterSuccess="getMessageSearchPageForAfterSale"></flowgive-form>
    </custom-dialog>
  </div>
</template>
<script>
import {
  MessageSearchPageForAfterSale, GetAfterSalesReport, UpdateGradeStar, UpdateGradeV, SelectFollowRecode, ExportXYKD
} from '../../../../API/workbench.js';
import list from './table';
import staffList from '../components/form-components/staff-list';
import introduceList from './introduce-list';
import upSellRecord from '../components/form-components/up-sell-record';
import recordList from '../components/form-components/up-sell-record-component/record-list';
import cleanData from '../components/form-components/clean-data';
import paymentRequest from '../components/form-components/payment-request';
import openAccountList from './after-sale-detail';
import exportForm from '../components/form-components/export-form';
import customerThread from '../components/form-components/customer-thread';
import saasClientDetails from './saas-client-details';
import postponeForm from '../components/form-components/postpone-form';
import flowgiveForm from '../components/form-components/flowgive-form';
export default {
  components: {
    list,
    staffList,
    introduceList,
    upSellRecord,
    recordList,
    openAccountList,
    cleanData,
    paymentRequest,
    exportForm,
    customerThread,
    saasClientDetails,
    postponeForm,
    flowgiveForm
  },
  props: {
    listType: String
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageIndex: 0, //	整型	必须		数据源：GET	页码，0为第一页
        pageSize: 10, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: 'desc', //	字符串	可选		数据源：GET	排序字段
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
        IntentionKey: '', //	整型	必须		数据源：GET	意向1-S;2-A;3-B;4-C;
        FollowStartTime: '', //	字符串	可选		数据源：GET	跟进日期开始
        FollowEndTime: '', //	字符串	可选		数据源：GET	跟进日期结束
        DemoStartTime: '', //	字符串	可选		数据源：GET	演示日期开始
        DemoEndTime: '', //	字符串	可选		数据源：GET	演示日期结束
        IsUseKey: '', //	字符串	可选		数据源：GET	是否在用 空-不生效;0-否;1-是
        IsGradeStarKey: '', //	字符串	可选		数据源：GET	是否为星 空-不生效;0-否;1-是
        IsGradeVKey: '', //	字符串	可选		数据源：GET	是否为V 空-不生效;0-否;1-是
        StartCount: '', //	整型	可选		数据源：GET	介绍起始数
        EndCount: '', //	整型	可选		数据源：GET	介绍终止数
        StayBack: '', //	整型	可选		数据源：GET	待返是否大于0 0-否;1-是
        Renew: '', //	整型	可选		数据源：GET	续费是否小于30天 0-否;1-是
        IsBalance: '', //	整型	可选		数据源：GET	是否有尾款 0-否 1-是
        AttenRate: '', //	整型	可选		数据源：GET	考勤率是否小于50% 0-否;1-是
        IsParent: '' //	字符串	可选		数据源：GET	不显示总部
      },
      pageTotal: 0, // 分页器总数
      customerMendianList: [], // 门店列表
      reportStatistics: {}, // 统计信息
      threadInfo: {}, // 线索信息
      isShowIntroduceCountPopup: false, // 转介绍列表
      isShowStaffListDialog: false, // 员工列表
      isShowUpSellRecordFormPopup: false, // 跟进情况表单
      isShowRecord: false, // 记录表单
      isShowCleanDataFormPopup: false, // 清数据
      isShowPaymentRequestFormPopup: false, // 付款申请
      isShowExportPopup: false, // 导出
      isShowAddCustomerFormPopup: false,
      isShowSaasClientDetails: false, // 总部详情
      isShowPostponePopup: false, // 延期
      isShowFlowGivePopup: false, // 流量赠送
      recordDataList: [],
      sourceInfo: {// 类型
        type: '',
        name: '',
        organizationName: '',
        organizationId: ''
      },
      tableColumns: [
        {
          label: '序号',
          prop: 'Number',
          width: 60,
          sortable: false,
          align: 'left',
          fixed: 'left',
          type: 'sell-documentary-table-row',
          className: 'font_gray',
          decimalPlace: 0,
          extend: {
            isClick: false
          }
        },
        {
          label: '要紧',
          prop: 'IsGradeStarKey',
          width: 80,
          sortable: false,
          align: 'left',
          fixed: 'left',
          isShow: true,
          type: 'sell-documentary-table-row',
          extend: {
            isClick: true,
            click: (rowData, val) => {
              this.doImportantClick(rowData, val);
            }
          }
        },
        {
          label: '机构名',
          prop: 'SaaSClientNameKeyValue',
          width: 120,
          align: 'left',
          fixed: 'left',
          sortable: false,
          type: 'sell-documentary-table-row',
          extend: {
            isClick: true,
            click: (rowData) => {
              this.doOrganizationClick(rowData);
            }
          }
        },
        {
          label: '门店功能',
          prop: 'storeFunction',
          width: 80,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '门店类型',
          prop: 'TypeKeyValue',
          width: 80,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '门店版本',
          prop: 'EditionKeyValueText',
          width: 80,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '联系人/手机号',
          prop: 'ContactsName',
          width: 120,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '介绍',
          prop: 'IntroduceCount',
          width: 70,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: true,
            className: 'font_blue',
            click: (rowData) => {
              this.doIntroduceCountClick(rowData);
            }
          }
        },
        {
          label: '待返',
          prop: 'WaitReturnCount',
          width: 70,
          align: 'left',
          type: 'sell-documentary-table-row',
          decimalPlace: 0,
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '跟进情况描述',
          prop: 'FTime',
          align: 'left',
          width: 250,
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: true,
            // isEdit: true,
            click: (rowData) => {
              this.doMarketFollowRecodeClick(rowData);
            }
          }
        },
        {
          label: '下次跟进',
          prop: 'FollowTime',
          width: 110,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: 'custom',
          extend: {
            isClick: true,
            isEdit: true,
            click: (rowData) => {
              this.doFollowTimeClick(rowData);
            }
          }
        },
        {
          label: '员工',
          prop: 'AgentCount',
          width: 70,
          align: 'left',
          type: 'sell-documentary-table-row',
          decimalPlace: 0,
          sortable: false,
          extend: {
            isClick: true,
            className: 'font_blue',
            click: (rowData) => {
              this.doAgentClick(rowData);
            }
          }
        },
        {
          label: '运行情况',
          prop: 'SaaSClientPort',
          width: 180,
          align: 'left',
          type: 'sell-documentary-table-row',
          decimalPlace: 0,
          sortable: false,
          extend: {
            isClick: false
          }
        },
        // {
        //   label: '学生',
        //   prop: 'StudentCount',
        //   width: 70,
        //   align: 'left',
        //   type: 'sell-documentary-table-row',
        //   decimalPlace: 0,
        //   sortable: false,
        //   extend: {
        //     isClick: false
        //   }
        // },
        // {
        //   label: '7天考勤',
        //   prop: 'AttendText',
        //   width: 90,
        //   align: 'left',
        //   type: 'sell-documentary-table-row',
        //   sortable: false,
        //   extend: {
        //     isClick: false
        //   }
        // },
        {
          label: '来源方式',
          prop: 'SourceTypeKeyValue',
          width: 110,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '开户',
          prop: 'DredgeTime',
          width: 180,
          align: 'left',
          sortable: 'custom',
          type: 'sell-documentary-table-row',
          extend: {
            isClick: false
          }
        },
        {
          label: '合同付款',
          prop: 'TotalAmountText',
          width: 100,
          align: 'left',
          sortable: false,
          decimalPlace: 2,
          type: 'sell-documentary-table-row',
          extend: {
            isClick: false
          }
        },
        {
          label: '尾款',
          prop: 'BalanceAmount',
          width: 80,
          align: 'left',
          sortable: false,
          decimalPlace: 2,
          type: 'sell-documentary-table-row',
          extend: {
            isClick: false,
            className: 'font_red'
          }
        },
        {
          label: '流量空间',
          prop: 'FluxCount',
          width: 120,
          align: 'left',
          type: 'sell-documentary-table-row',
          sortable: false,
          extend: {
            isClick: false
          }
        },
        {
          label: '操作',
          prop: 'option',
          width: 240,
          type: 'sell-documentary-table-btn',
          align: 'left',
          fixed: 'right',
          extend: {
            tableBtnGroup: [
              {
                extend: {
                  name: (rowData) => { // 付款中
                    return rowData.PayAuditKey > 0 ? '付款中' : '付款';
                  },
                  setClassName: (rowData) => {
                    return Number(rowData.PayAuditKey) > 0 ? 'font_green' : '';
                  },
                  click: (rowData) => {
                    this.operateBtnEvent('FK', rowData);
                  }
                }
              },
              {
                extend: {
                  name: (rowData) => { // 详情
                    return Number(rowData.TypeKey) == 6 ? '详情' : '';
                  },
                  click: (rowData) => { this.saaSClientDetails(rowData); }

                }
              },
              {
                extend: {
                  name: (rowData) => { // 开户中 总部不支持操作
                    if (rowData.StatusKey != 0 && Number(rowData.TypeKey) != 6) {
                      return rowData.ClearAuditKey > 0 ? '清数据中' : '清数据';
                    } else {
                      return '';
                    }
                  },
                  setClassName: (rowData) => {
                    return Number(rowData.ClearAuditKey) > 0 ? 'font_green' : '';
                  },
                  click: (rowData) => {
                    this.operateBtnEvent('QSJ', rowData);
                  }
                }
              },
              {
                extend: {
                  name: (rowData) => { // 导出 总部不支持操作
                    if (rowData.StatusKey != 0 && Number(rowData.TypeKey) != 6) {
                      if (rowData.ExportExpirTime && rowData.ExportExpirTime >= this.$utils.formatDateToLine(Date.new()) && rowData.ExportAuditKey == 0) {
                        return '可导出';
                      } else if (!rowData.ExportExpirTime && rowData.ExportAuditKey > 0) {
                        return '导出中';
                      } else {
                        return '导出';
                      }
                    } else {
                      return '';
                    }
                  },
                  setClassName: (rowData) => {
                    return !rowData.ExportExpirTime && rowData.ExportAuditKey > 0 ? 'font_green' : '';
                  },
                  click: (rowData) => {
                    this.operateBtnEvent('DC', rowData);
                  }
                }
              },
              {
                extend: {
                  name: (rowData) => { // 延期
                    if (rowData.StatusKey > 0 && rowData.EditionKey != 6) {
                      return rowData.RenewalAuditKey > 0 ? '延期中' : '延期';
                    }
                  },
                  setClassName: (rowData) => {
                    return rowData.RenewalAuditKey > 0 ? 'font_green' : '';
                  },
                  click: (rowData) => {
                    this.operateBtnEvent('YQ', rowData);
                  }
                }
              },
              {
                extend: {
                  name: (rowData) => { // 流量赠送
                    return Number(rowData.GiveFluxApplyKey) > 0 ? '流量赠送中' : '流量赠送';
                  },
                  setClassName: (rowData) => {
                    return Number(rowData.GiveFluxApplyKey) > 0 ? 'font_green' : '';
                  },
                  click: (rowData) => {
                    this.operateBtnEvent('LLZS', rowData);
                  }
                }
              }
            ]
          }
        }
      ],
      // 门店操作按钮
      PageCount: 0,
      defaultSort: { prop: 'studentInfo', order: 'ascending' }
    };
  },
  created () {
  },
  mounted () {
    this.getMessageSearchPageForAfterSale();
  },
  methods: {
    // 获取售前跟单统计
    getAfterSalesReport () {
      GetAfterSalesReport().then(result => {
        console.log(result.data, '售后-获取售前跟单统计');
        this.reportStatistics = result.data;
      });
    },
    // 自用追销查询列表
    getMessageSearchPageForAfterSale (queryParams) {
      if (queryParams) {
        Object.assign(this.queryParams, queryParams);
      }
      this.getAfterSalesReport();// 统计
      MessageSearchPageForAfterSale(this.queryParams).then(result => {
        console.log('售后-自用追销查询列表', result.data);
        this.isShowIntroduceCountPopup = false;
        this.isShowAddCustomerFormPopup = false;
        this.pageTotal = result.data.Total;
        // 分页数
        if (result.data && result.data.PageDataList.length > 0) {
          result.data.PageDataList.forEach(o => {
            o.dredgeType = 1;// 售后
            o.Number = o.Number > 9 ? o.Number : '0' + o.Number;
            o.CompleteCount = Number(o.CompleteCount);
            o.IntroduceCount = o.CompleteCount + '/' + Number(o.IntroduceCount);
            // 根据学生数显示考勤率
            if (Number(o.StudentCount) > 0) {
              // 考勤人数+考勤率
              o.AttendText = Number(o.AttendCount) + ' | ' + (Number(o.AttendRate) > 50 ? Number(o.AttendRate) + '%' : `<span class="font_red">${Number(o.AttendRate) + '%'}</span>`);
            } else {
              o.AttendText = '';
            }
            if (o.TotalAmount && o.StrokeCount) {
              o.TotalAmountText = this.$utils.mAmountType(o.TotalAmount, 2) + ' ' + o.StrokeCount + '笔';
            }
            o.BalanceAmount = Number(o.BalanceAmount) > 0 ? o.BalanceAmount : '-';
            switch (Number(o.EditionKey)) { // 2标准 3旗舰 4高级 5总部 6课件
              case 2:
                o.EditionKeyValueText = '精准版';
                break;
              case 3:
                o.EditionKeyValueText = '旗舰版';
                break;
              case 4:
                o.EditionKeyValueText = '高级版';
                break;
              case 5:
                o.EditionKeyValueText = '连锁版';
                break;
              case 6:
                o.EditionKeyValueText = '课件版';
                break;
              default:
                break;
            }
            // 运行情况
            //  o.SaaSClientPortSet =
          });
          this.customerMendianList = result.data.PageDataList;
        } else {
          this.customerMendianList = [];
        }
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 要紧
    doImportantClick (rowData, val) {
      console.log(rowData, val, '要紧');
      if (val == 'IsGradeStarKey') {
        let IsGradeStarKey = Number(rowData.IsGradeStarKey) == 1 ? 0 : 1;
        let IsGradeStarKeyValue = Number(IsGradeStarKey) == 1 ? '是' : '否';
        UpdateGradeStar(rowData.OLAPKey, IsGradeStarKey, IsGradeStarKeyValue).then(result => {
          console.log(result.data, '星级');
          this.getMessageSearchPageForAfterSale();
        });
      } else if (val == 'IsGradeVKey') {
        let IsGradeVKey = Number(rowData.IsGradeVKey) == 1 ? 0 : 1;
        let IsGradeVKeyValue = Number(IsGradeVKey) == 1 ? '是' : '否';
        UpdateGradeV(rowData.OLAPKey, IsGradeVKey, IsGradeVKeyValue).then(result => {
          console.log(result.data, 'V级');
          this.getMessageSearchPageForAfterSale();
        });
      }
    },
    // 点击机构名操作 SourceTypeKey
    doOrganizationClick (rowData) {
      this.threadInfo = this.$utils.parseJson(rowData);
      console.log(rowData, '操作菜单');
      this.sourceInfo = {
        type: rowData.SourceTypeKey,
        name: rowData.SourceTypeKeyValue,
        organizationName: rowData.SaaSClientNameKeyValue,
        organizationId: rowData.SaaSClientNameKey
      };
      this.isShowAddCustomerFormPopup = true;
    },
    // 关闭线索弹窗
    closeAddCustomerFormPopup () {
      this.isShowAddCustomerFormPopup = false;
    },
    // 转介绍表单
    doIntroduceCountClick (rowData) {
      console.log(rowData, '转介绍表单');
      this.threadInfo = rowData;
      this.isShowIntroduceCountPopup = true;
    },
    // 关闭转介绍表单
    closeIntroduceCountPopup () {
      this.isShowIntroduceCountPopup = false;
      this.getMessageSearchPageForAfterSale();
    },
    // 员工表单
    doAgentClick (rowData) {
      console.log(rowData, '员工');
      this.threadInfo = rowData;
      this.isShowStaffListDialog = true;
    },
    // 关闭员工表单
    doCloStaffList () {
      this.isShowStaffListDialog = false;
    },
    // 点击跟进情况描述操作
    doMarketFollowRecodeClick (rowData) {
      this.threadInfo = rowData;
      SelectFollowRecode(this.threadInfo.OLAPKey).then(result => {
        this.recordDataList = result.data;
        this.isShowRecord = true;
      }, error => {
        layer.alert(error.msg);
      });
    },
    // 关闭跟进情况描述表单
    closeisShowRecord () {
      this.isShowRecord = false;
    },
    // 点击下次跟进操作
    doFollowTimeClick (rowData) {
      this.threadInfo = rowData;
      this.isShowUpSellRecordFormPopup = true;
    },
    // 关闭下次跟进表单
    closeUpSellRecordFormPopup () {
      this.isShowUpSellRecordFormPopup = false;
    },
    // 操作菜单
    operateBtnEvent (type, rowData) {
      console.log(rowData, '操作菜单');
      this.threadInfo = rowData;
      switch (type) {
        case 'FK':// 付款
          this.isShowPaymentRequestFormPopup = true;
          break;
        case 'QSJ':// 清数据
          this.isShowCleanDataFormPopup = true;
          break;
        case 'DC':// 导出
          if (rowData.ExportExpirTime && rowData.ExportExpirTime >= this.$utils.formatDateToLine(Date.new()) && rowData.ExportAuditKey == 0) { // 可导出
            this.doExportCustomerClick(this.threadInfo.OLAPKey);
          } else {
            this.isShowExportPopup = true;
          }
          break;
        case 'YQ':// 延期
          this.isShowPostponePopup = true;
          break;
        case 'LLZS':// 流量赠送
          this.isShowFlowGivePopup = true;
          break;
        default:
          break;
      }
    },
    // 关闭导出弹窗
    closeExportPopup () {
      this.isShowExportPopup = false;
    },
    closePostponePopup () {
      this.isShowPostponePopup = false;
    },
    closeFlowGivePopup () {
      this.isShowFlowGivePopup = false;
      this.getMessageSearchPageForAfterSale();
    },
    // 导出
    doExportCustomerClick (OLAPKey) {
      ExportXYKD(OLAPKey).then(result => {
        console.log(result.data, '导出');
        window.location.href = this.$store.getters.APIURL + result.data.url;
        this.queryParams.pageIndex = 0;
        this.getMessageSearchPageForAfterSale();
      }, error => {
        layer.alert(error.msg);
      });
      console.log('导出');
    },
    // 关闭付款申请表单
    closePaymentRequestFormPopup () {
      this.isShowPaymentRequestFormPopup = false;
    },
    // 关闭清数据
    closeCleanDataFormPopup () {
      this.isShowCleanDataFormPopup = false;
    },
    // 搜索统计
    searchStatistics (type, value) {
      this.clearSearchCondition(true);
      switch (type) {
        case 'IsGradeStarKey':
          this.queryParams.IsGradeStarKey = value;
          break;
        case 'IsGradeVKey':
          this.queryParams.IsGradeVKey = value;
          break;
        case 'FollowStartTime':
          this.queryParams.FollowStartTime = value;
          this.queryParams.FollowEndTime = value;
          break;
        case 'FollowEndTime':
          this.queryParams.FollowStartTime = '';
          this.queryParams.FollowEndTime = value;
          break;
        case 'StartCount':
          if (value == 1) {
            this.queryParams.StartCount = 0;
            this.queryParams.EndCount = 5;
          } else if (value == 2) {
            this.queryParams.StartCount = 6;
            this.queryParams.EndCount = 10;
          } else if (value == 3) {
            this.queryParams.StartCount = 11;
            this.queryParams.EndCount = 1000;
          }
          break;
        case 'StayBack':
          this.queryParams.StayBack = value;
          break;
        case 'AttenRate':
          this.queryParams.AttenRate = value;
          this.queryParams.IsParent = 1;
          break;
        case 'Renew':
          this.queryParams.Renew = value;
          break;
        case 'IsBalance':
          this.queryParams.IsBalance = value;
          break;
        default:
          break;
      }
      this.queryParams.pageIndex = 0;
      this.queryParams.searchText = '';
      this.getMessageSearchPageForAfterSale();
    },

    // 搜索
    searchValue (searchText) {
      this.clearSearchCondition(true);
      this.queryParams.searchText = searchText;
      this.queryParams.pageIndex = 0;
      this.getMessageSearchPageForAfterSale();
    },
    // 清除搜索条件
    clearSearchCondition (isUpdata) {
      this.queryParams = {
        pageIndex: 0, //	整型	必须		数据源：GET	页码，0为第一页
        pageSize: this.queryParams.pageSize, //	整型	必须		数据源：GET	每页记录数，0：表示查询所有
        orderBy: '', //	字符串	可选		数据源：GET	排序字段
        sequence: 'desc', //	字符串	可选		数据源：GET	排序字段
        searchText: '', //	字符串	可选		数据源：GET	搜索条件：模糊匹配姓名、昵称、学号、客户手机号
        IntentionKey: '', //	整型	必须		数据源：GET	意向1-S;2-A;3-B;4-C;
        FollowStartTime: '', //	字符串	可选		数据源：GET	跟进日期开始
        FollowEndTime: '', //	字符串	可选		数据源：GET	跟进日期结束
        DemoStartTime: '', //	字符串	可选		数据源：GET	演示日期开始
        DemoEndTime: '', //	字符串	可选		数据源：GET	演示日期结束
        IsUseKey: '', //	字符串	可选		数据源：GET	是否在用 空-不生效;0-否;1-是
        IsGradeStarKey: '', //	字符串	可选		数据源：GET	是否为星 空-不生效;0-否;1-是
        IsGradeVKey: '', //	字符串	可选		数据源：GET	是否为V 空-不生效;0-否;1-是
        StartCount: '', //	整型	可选		数据源：GET	介绍起始数
        EndCount: '', //	整型	可选		数据源：GET	介绍终止数
        StayBack: '', //	整型	可选		数据源：GET	待返是否大于0 0-否;1-是
        Renew: '', //	整型	可选		数据源：GET	续费是否小于30天 0-否;1-是
        AttenRate: '', //	整型	可选		数据源：GET	考勤率是否小于50% 0-否;1-是
        IsParent: '' //	字符串	可选		数据源：GET	不显示总部
      };
      if (!isUpdata) {
        this.getMessageSearchPageForAfterSale();
      }
    },
    saaSClientDetails (rowData) {
      this.threadInfo = rowData;
      this.isShowSaasClientDetails = true;
    },
    closeSaasClientDetails () {
      this.isShowSaasClientDetails = false;
    }
  },
  computed: {}
};
</script>
<style scoped>
.after_sale_documentary_list >>> .el-table .el-table__cell {
  padding: 0px;
}
</style>
