<template>
  <div class="content_box_list"
       :class="dataObj.isOpen==true?'opt':''"
       @click.stop="clickEvent($event)">   
    <div class="content_box_list_week">
      <div v-if="dataObj.isWeekShow">{{dataObj.WeekKeyValue}}</div>
      <div class="list_icon"
           v-if="vIsSysVersionTY"></div>
    </div>
    <div class="content_box_list_info">
      <div class="list_info_left"
           :style="dataObj.alreadyAttenCount>0 &&!vIsSysVersionTY?'max-width:226px':''">
        <div ref="CourseNameKeyValue"
             class="text_overflow_hidden">
          <span>{{dataObj.ClassTimeName}}</span>
          <span class="text_overflow_hidden">{{dataObj.CourseNameKeyValue}}</span>
        </div>

        <div class="font_gray">
          <span>{{dataObj.MTeacherKeyValue}}</span>
          <span v-if="dataObj.IsNotEndKey==1">不结束</span>
          <span v-else>
            截止{{vLastClassTime}}
            <span class="font_black">{{$utils.mAmountType(dataObj.ClassCount,0)}}</span>节
          </span>
          丨
          <span class="font_black"
                v-if="vIsSysVersionTY">{{$utils.mAmountType(dataObj.Num_SyncArranged,0)}}<span class="font_gray">人</span></span>
          <span v-else>
            <span class="font_black">{{$utils.mAmountType(dataObj.Num_InGrade,0)}}</span>
            <span class="font_gray">人</span>
          </span>
        </div>
      </div>
      <!-- 精准版才显示已排节数 -->
      <div class="list_info_right fr"
           v-if="dataObj.alreadyAttenCount>0 &&!vIsSysVersionTY">
        <div>已排节数</div>
        <div class="font_number font_blue">{{dataObj.alreadyAttenCount}}</div>
      </div>
      <!-- 通用版才显示角标 -->
      <!-- $utils.mAmountType(dataObj.alreadyAttenCount,0)==0&& -->
      <div class="list_info_loop"
           :class="dataObj.isOpen==0?'no_course_arranging':''"
           v-if="vIsSysVersionTY">
        <!-- 排课节数 -->
        <div class="list_info_loop_text "
             v-if="dataObj.IsSyncArrangedKey==0&&Number(dataObj.alreadyAttenCount)>0">{{Number(dataObj.alreadyAttenCount) >99?99:$utils.mAmountType(dataObj.alreadyAttenCount,0)}}</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      isClick: true // 防止重复点击或者点击过快方法
    };
  },
  props: {
    dataObj: {
      type: Object,
      required: true,
      default: () => {
        return {
          OALPKey: 0,
          MainDemoName: '', // 班级名称/课程名
          dataList: '', // 内循环列表
          isShow: false // 班级要显示加入班级按钮
        };
      }
    }
  },
  components: {},
  computed: {
    vIsSysVersionTY () {
      return this.$store.getters.isSysVersionTY;
    },
    // visMaxWidth () {
    //   let MaxWidth = this.dataObj.alreadyAttenCount > 0 ? 210 : 240;
    //   if ((this.$refs.CourseNameKeyValue && Number(this.$refs.CourseNameKeyValue.scrollWidth) >= MaxWidth) || this.dataObj.CourseNameKeyValue.length > 12) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    vLastClassTime () {
      return this.dataObj.LastClassTime.slice(0, 10);
    },
    vLastClassTimeDay () { // 截止日期小于14天
      return this.$utils.DateDiffDay(Date.new(), this.dataObj.LastClassTime) <= 14;
    }
  },
  methods: {
    clickEvent (e) {
      if (this.isClick) {
        this.isClick = false;
        setTimeout(() => { // 定时器
          this.isClick = true;  // 一秒内不能重复点击
          console.log(this.isClick, 'this.isClick');
        }, 500);
        this.dataObj.isOpen = !this.dataObj.isOpen;
        this.$emit('onClickEvent', this.dataObj, e);
      }
    }
  }
};
</script>

<style>
</style>