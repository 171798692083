<template>
  <div class="theacher_adiser">
    <div class="head_date_static_box ">
      <div class="head_date_static_list_box "
           @click.stop="changeType(1)"
           :class="{optionItem:seachObj.StatusType==1}">
        <div class="head_date_static_list_text">续费预警</div>
        <div class="head_date_static_list_data">{{titleData.RenewalWarningCount}}</div>
      </div>
      <div class="head_date_static_list_box  listleft "
           @click.stop="changeType(2)"
           :class="{optionItem:seachObj.StatusType==2}">
        <div class="head_date_static_list_text">失效未续</div>
        <div class="head_date_static_list_data colorf75">{{titleData.NotRenewalCount}}</div>
      </div>
      <div class="head_date_static_list_box  listleft"
           @click.stop="changeType(3)"
           :class="{optionItem:seachObj.StatusType==3}">
        <div class="head_date_static_list_text">出勤率预警</div>
        <div class="head_date_static_list_data">{{titleData.AttendanceWarningCount}}<span class="unit">人</span></div>
      </div>
      <div class="head_date_static_list_box  listleft"
           @click.stop="changeType(4)"
           :class="{optionItem:seachObj.StatusType==4}">
        <div class="head_date_static_list_text">排课不足</div>
        <div class="head_date_static_list_data">{{titleData.StuentNotOneCount}}<span class="unit">人</span></div>
      </div>
      <div class="head_date_static_list_box  listright"
           @click="changeType(5)"
           :class="{optionItem:seachObj.StatusType==5}">
        <div class="head_date_static_list_text">长期未到校预警</div>
        <div class="head_date_static_list_data">{{titleData.CampusWarningCount}}<span class="unit">人</span></div>
      </div>
    </div>

    <div class="head_seach_box">
      <div class="head_seach_box_list"
           style="margin-top:10px;margin-bottom:10px">
        <div class="table_select_box_flex">
          <div class="table_select_box"
               style="width:180px;margin-right:20px">
            <div class="table_select_title">最近跟单</div>
            <el-select v-model="gendanText"
                       filterable
                       placeholder="必选"
                       @change="selectGenDanItem"
                       value-key="OLAPKey">
              <el-option v-for="item in gendanDataList"
                         :key="item.OLAPKey"
                         :label="item.MainDemoName"
                         :value="item.OLAPKey"></el-option>
            </el-select>
          </div>
          <div class="course_statistics_search">
            <input-search-contain-btn v-model="seachObj.searchText"
                                      :placeholder="'学生名'"
                                      :clearAll="false"
                                      @clearSearch="clearSearchCondition"
                                      @onSearchEvent="search"></input-search-contain-btn>
          </div>
        </div>

        <!-- <div class="head_seach_item">
          <div class="head_seach_item_text">最近跟单</div>
          <div class="head_seach_item_input" @tap.stop="showGenDanPopup">{{vGendanText}}</div>
        </div>
        <div class="seach_item"> -->
        <!-- <basics-search-box v-model="seachObj.searchText" :placeholderText="'学生名'" :isFocus="true"
            :isShowSearchBtn="false" @input="getDataList"></basics-search-box> -->
        <!-- </div> -->
      </div>
    </div>
    <div class="titletotal"> 分配正课生 {{studentcount}}人 </div>
    <div class="content_box"
         v-if="vDataList.length>0">
      <!-- <scroll-view class="scroll_content" scroll-y="true"> -->
      <list-item v-for="item in vDataList"
                 :item="item"
                 @clickBottom="clickBottom"
                 :key="item.OLAPKey"
                 @goStudentFile="goStudentFile"></list-item>

      <!-- </scroll-view> -->
    </div>
    <div v-else>
      <div class="monitoring_content_nodata"
           style="margin-top: 120px;">
        <div class="monitoring_content_nodata_txt2">暂无数据</div>
      </div>
    </div>
    <custom-dialog title="线索跟进记录"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowForm"
                   :before-close="closeFormDialog">
      <clew-follow-form :studentItem="studentItem"
                        @close="closeFormDialog"
                        @afterSuccess="getDataList"></clew-follow-form>
    </custom-dialog>
  </div>
</template>

<script>
import { GetCourseStudentList, GetRenewalWarningCount } from '../../../../API/workbench';
import clewFollowForm from '../../../report/student-management-engineer/list-components/clew-follow-form'
import inputSearchContainBtn from '../../../report/common/input-search-contain-btn'
import listItem from './list-item'
export default {
  data () {
    return {

      gendanDataList: [{
        MainDemoName: '全部',
        OLAPKey: 0
      }, {
        MainDemoName: '0-30天',
        OLAPKey: 1
      },
      {
        MainDemoName: '31-60天',
        OLAPKey: 2
      },
      {
        MainDemoName: '61-90天',
        OLAPKey: 3
      },
      {
        MainDemoName: '超过90天',
        OLAPKey: 4
      }
      ],
      isShowGenDanPopup: false,
      titleItem: {
        MainDemoName: '今日',
        OLAPKey: 1
      },
      titleData: {
        RenewalWarningCount: 0,
        NotRenewalCount: 0,
        AttendanceWarningCount: 0,
        CampusWarningCount: 0,
        StuentNotOneCount: 0,
        InvitedClassCount: 0,
        TrialLessonCount: 0,
        SignUpCount: 0
      },
      seachObj: {
        TeacherKey: this.$store.getters.token.UserID,
        searchText: '',
        pageIndex: 0,
        pageSize: 0,
        sequence: '',
        orderBy: '',
        startTime: '',
        endTime: '',
        StatusType: 0
      },
      gendanText: 0,
      studentcount: 0,
      dataList: [],
      isShowForm: false,
      studentItem: null
    }
  },
  props: {

  },
  computed: {
    vGendanText () {
      return this.gendanText
    },
    vDataList () {
      return this.dataList
    }
  },
  components: {
    listItem,
    inputSearchContainBtn,
    clewFollowForm
  },
  onShow () {
  },
  created () {
    this.getTitleStaticData()
    this.getDataList()
  },
  methods: {
    closeFormDialog () {
      this.isShowForm = false
    },
    search () {
      this.getTitleStaticData()
      this.getDataList()
    },
    getDataList () {
      this.$nextTick(() => {
        console.log('GetCourseStudentList', this.seachObj)
        GetCourseStudentList(this.seachObj).then((res) => {
          console.log('GetCourseStudentList', res)
          this.dataList = res.data.PageDataList
          this.studentcount = res.data.StudentCount
        })
      })

    },
    cancelGenDanPopup () {
      this.isShowGenDanPopup = false
    },
    showGenDanPopup () {
      this.isShowGenDanPopup = true
    },
    selectGenDanItem () {
      console.log('this.gendanText', this.gendanText)
      this.getSeachDate(this.gendanText)
      this.cancelGenDanPopup()
      this.getDataList()

    },
    formatDate (date) {
      date = Date.new(date.replace(/-/g, '/'));
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
    },
    clearSearchCondition () {
      this.seachObj = {
        TeacherKey: this.$store.getters.token.UserID,
        searchText: '',
        pageIndex: 0,
        pageSize: 0,
        sequence: '',
        orderBy: '',
        startTime: '',
        endTime: '',
        StatusType: this.seachObj.StatusType
      }
      this.gendanText = 0
      this.getDataList()
    },
    getSeachDate (key) {
      if (key == 0) {
        this.seachObj.startTime = '';
        this.seachObj.endTime = '';
        return
      }
      let monday = new Date();
      let sunday = new Date();
      let goStartTimer = '';
      let goEndTimer = '';
      const firstDay = key == 1 ? 0 : key == 2 ? 31 : key == 3 ? 61 : 91;
      const LastDay = key == 1 ? 30 : key == 2 ? 60 : key == 3 ? 90 : 0;
      monday.setDate(monday.getDate() - LastDay);


      sunday.setDate(sunday.getDate() - firstDay);

      this.seachObj.startTime = this.formatDate(monday.toLocaleDateString());
      if (LastDay == 0) {
        this.seachObj.endTime = '';
      } else {
        this.seachObj.endTime = this.formatDate(sunday.toLocaleDateString());
      }
      console.log('this.seachObj', this.seachObj)
    },
    getTitleStaticData () {
      GetRenewalWarningCount(this.$store.getters.token.UserID).then((res) => {
        console.log('PersonalAchievement', res)
        this.titleData = res.data
      })
    },
    selectContentType (i) {
      this.showContentType = i
    },
    clickBottom (item) {
      console.log('去详情', item)
      this.studentItem = item
      this.isShowForm = true
      // this.$zxrouter.navigateTo({
      //   url: '/pages/student/clew-follow-form/index?OLAPKey=' + data
      //     .OLAPKey + '&MobileSupport=' + data.MobileSupport + '&AdviserStudentKey=' + data.AdviserStudentKey +
      //     "&MainDemoName=" + data.MainDemoName
      // });
    },
    goStudentFile (item) {
      console.log('去学生档案', item)
      this.$dialog.open(this, {
        name: '学生档案',
        routerName: this.$route.name,
        moduleName: 'studentFile',
        data: { studentID: item.OLAPKey }
      });
    },
    changeType (type) {
      if (this.seachObj.StatusType == type) {
        this.seachObj.StatusType = 0
      } else {
        this.seachObj.StatusType = type
      }
      this.getDataList()
    }


  }
}
</script>

<style scoped>
.theacher_adiser {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* position: relative;
    z-index: 1; */
}

.head_box {
  height: 70px;
  padding-left: 22px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.select_date {
  font-family: Alibaba PuHuiTi;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #333333;
  width: 120px;
  background-color: #f6f6f6;
  border-radius: 18px;
  position: relative;
  height: 37px;
  padding-left: 12px;
  padding-top: 2px;
}

.select_date::after {
  content: '';
  position: absolute;
  /* top: var(--status-bar-height); */
  top: 12px;
  right: 12px;
  width: 0;
  height: 0;
  border-width: 8px;
  border-style: solid;
  border-color: #919191 transparent transparent transparent;
}

.content_box {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  /* position: absolute;
    top: 250px;
    bottom: 0;
    left: 0px;
    right: 0px; */
}

.scroll_content {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* #ifdef APP-PLUS */
  padding: 15px 0;
  /* #endif */
  /* #ifdef H5 */
  padding-top: 15px;
  padding-bottom: 50px;
  /* #endif */
}

.fixed_popup_title {
  font-size: 14px;
  /* 	min-height: 102rpx; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 20px;
  font-weight: bold;
  padding: 18px 0;
  padding-left: 15px;
  position: relative;
  border-bottom: 1px solid #ececec;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333;
}

.fixed_popup_title .font_gray {
  width: 100%;
  font-size: 12px;
  line-height: 15px;
  color: #999;
  font-weight: normal;
}

.fixed_popup_title .font_black {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 80px;
}

.fixed_popup_title .title_btn {
  position: absolute;
  width: 62px;
  height: 22px;
  line-height: 20px;
  border-radius: 11px;
  border: 1px solid #e5e5e5;
  text-align: center;
  top: 50%;
  margin-top: -11px;
  right: 15px;
  font-size: 12px;
  font-weight: normal;
}

.head_date_static_box {
  background: linear-gradient(180deg, #ffc6a0 0%, #ffe8d8 100%);
  height: 108px;
  /* margin: 10px 5px; */
  padding: 5px;
  display: flex;
  flex-direction: row;
  /* border-radius: 6px; */
  text-align: center;
  justify-items: center;
  align-items: center;
}

.head_date_static_list_box {
  display: flex;
  flex-direction: column;
  height: 72px;
  flex: 1;
  background: #ffffff;
  border-radius: 10px;
  margin-left: 5px;

  position: relative;
  box-shadow: 0px 1px 5px 0px #ffc6a0;
  justify-content: space-around;
}

.listright {
  margin-right: 5px;
}

.head_date_static_list_text {
  font-family: Alibaba PuHuiTi;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 10px;
  padding: 0 5px;
  color: #666666;
}

.head_date_static_list_data {
  font-family: DIN;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #ff9800;
  max-width: 100%;
  overflow: hidden;
  word-break: break-all;
  /* break-all(允许在单词内换行。) */
  text-overflow: ellipsis;
  /* 超出部分省略号 */
  display: -webkit-box;
  /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical;
  /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 1;
}

.head_date_static_list_box.bp::after {
  content: '';
  /* background: linear-gradient(270deg, #FF5400 0%, rgba(255, 84, 0, 0) 92.5%); */
  width: 12px;
  height: 8px;
  position: absolute;
  top: 35px;
  right: -11px;
  opacity: 1;
  background-image: url('../../../../../public/image/jiantou.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px;
}

.actualColAmountText {
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: #ff9800;
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
}

.body_select_box {
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #dadfe8;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-items: center;
  align-items: center;
}

.body_select_list_box {
  height: 100%;
  width: 70px;
  margin-left: 2px;
  margin-right: 2px;
  font-family: Alibaba PuHuiTi;
  font-size: 15px;
  font-weight: 400;
  line-height: 50px;
  text-align: center;
  color: #666666;
}

.unit {
  color: #666666;
  font-size: 11px;
}

.body_select_list_opt {
  font-weight: 600;
  color: #333333;
  border-bottom: 1px solid #e64024;
}

.recruit_student_content {
  flex: 1;
}

.colorf75 {
  color: #f75040;
}

.head_seach_box {
  height: 60px;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.head_seach_box_list {
  display: flex;
  flex-direction: row;
  padding: 0px 10px 0 10px;
}

.head_seach_item {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.seach_item {
  /* padding-top: 30rpx; */
  /* margin-right: 120rpx; */
  /* width: 200rpx; */
  width: 172px;
  /*    padding-left: 20rpx; */
}

.head_seach_item_input {
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-weight: 400;
  line-height: 23px;
  color: #333333;
  width: 100px;
  background-color: #f6f6f6;
  border-radius: 18px;
  position: relative;
  height: 30px;
  padding-left: 12px;
  padding-top: 2px;
}

.head_seach_item_input::after {
  content: '';
  position: absolute;
  /* top: var(--status-bar-height); */
  top: 12px;
  right: 12px;
  width: 0;
  height: 0;
  border-width: 8px;
  border-style: solid;
  border-color: #919191 transparent transparent transparent;
}

.head_seach_item_text {
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
  margin-right: 12px;
  margin-left: 10px;
}

.titletotal {
  font-family: Alibaba PuHuiTi;
  font-size: 18px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  height: 40px;
  color: #333333;
  background: #f7f7f7;
  padding: 0 15px;
}

.optionItem {
  border: 1.25px solid #3498db;
}

.optionItem .head_date_static_list_text {
  color: #3498db !important;
}
.head_seach_box_list ::v-deep .mendian_backstage_search .search_box {
  min-width: 150px !important;
  width: 170px;
}
</style>