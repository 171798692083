<template>
  <!-- 待批改-->
  <div class="JXT_dynamic_list"
       v-if="studenttaskItem.TasksStatus==0">
    <div class="JXT_dynamic_content">
      <div class="dynamic_content_photo">
        <img class="img_box"
             :src="vStudentImg"
             :onerror="defaultImg"
             alt="">
      </div>
      <div class="dynamic_content_body">
        <div class="body_title_box flex">
          <div class="body_name">
            {{studenttaskItem.Author}}的作业
          </div>
        </div>
        <div class="body_arrange_date">
          <span class="body_arrange_name">{{studenttaskItem.CustKeyValue}}</span>
          <span>提交于 {{vPublishTime}}</span>
        </div>
        <!-- 作业浏览内容 -->
        <browse-item-content :dataInfo="studenttaskItem"
                             :isUnfoldShow="false"></browse-item-content>
      </div>
    </div>
    <!-- 按钮 -->
    <div v-if="!isStudentTask">
      <release-btn :btnText="'批改作业'"
                   @click="correctTaskButClick"></release-btn>
    </div>
  </div>
</template>

<script>
// import browseItemHead from '../common-component/browse-box/browse-item-head';
import browseItemContent from '../../common-component/browse-box/browse-item-content';
import releaseBtn from '../../common-component/release-btn';
export default {
  components: {
    browseItemContent,
    releaseBtn
  },
  data () {
    return {
      isNOTimeShow: Date.new().getTime()
    };
  },
  props: {
    studenttaskItem: {
      type: Object,
      default: function () {
        return {};
      }
    },
    taskCorrectInfo: {
      type: Object,
      default: function () {
        return {};
      }
    },
    isStudentTask: {
      type: Boolean,
      default: false
    }
  },
  created () {

  },
  mounted () {
    this.$dialog.register(this, ['taskCorrectDetails']);
  },
  computed: {
    // 头像
    vStudentImg () {
      if (this.studenttaskItem.HeadImg) {
        if (this.studenttaskItem.HeadImg.indexOf('http') > -1) {
          return this.studenttaskItem.HeadImg;
        } else {
          return this.$store.getters.CDNURL + this.studenttaskItem.HeadImg;
        }
      } else {
        return '';
      }
    },
    // 默认显示logo
    defaultImg () {
      return 'this.src="' + require('../../../../../public/image/face_screenage_noImg.png') + '"';
    },
    // 时间
    vPublishTime () {
      if (this.studenttaskItem.PublishTime) {
        const month = this.studenttaskItem.PublishTime.slice(5, 7);
        const day = this.studenttaskItem.PublishTime.slice(8, 10);
        const time = this.studenttaskItem.PublishTime.slice(11, 16);
        return month + '-' + day + ' ' + time;
      } else {
        return '';
      }
    }
  },
  methods: {
    // 去批改作业
    correctTaskButClick () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '批改作业',
        moduleName: 'taskCorrectDetails',
        data: { taskData: { courseName: this.taskCorrectInfo.Title, essayID: this.studenttaskItem.ID } },
        callBack: {
          afterSuccess: () => { this.$emit('doEditTaskClick'); }
        }
      });
    }
  }
};
</script>

<style>
</style>