<template>
  <div class="class_detail_header">
    <div class="principal_header">
      <div class="class_detail_header_title pr">
        <div class="class_detail_header_name">{{dataInfo.MainDemoName}}</div>
        <!-- <div class="class_detail_header_btn font_blue" @click.stop="quitClassForTeacher">退出班级</div> -->
      </div>
      <div class="class_detail_header_content">
        <teacher-list :title="'老师'"
                      :dataList="dataInfo.BJTeacherList"
                      :showDel="true"
                      :showAdd="true"
                      @addItem="showAddTeacherList"
                      @delItem="delClassGradeForTeacher"></teacher-list>
        <teacher-list :title="'学生'"
                      :dataList="dataInfo.BJStudentList"
                      :showDel="true"
                      :showAdd="true"
                      @addItem="showAddStudentList"
                      @delItem="delClassGradeForStudent"></teacher-list>

      </div>
    </div>
    <!-- <div style="clear: both;"></div> -->
    <div class="principal_header_date">
      <classBiginData @registerBusEvent='doViewCoursewareDetails'
                      @getDateRangeCoursewareStat="getDateRangeCoursewareStat"
                      :par="'par'"
                      :dataInfo="statistics"></classBiginData>

    </div>
  </div>
</template>
<script>
import classBiginData from '../../right-box-work-bench/home-page-details/class-bigin-data.vue';
import { PrincipalquitClassGradeForTeacher, PrincipalAddClassGradeForTeacher, PrincipalAddClassGradeForStudent, PrincipalQuitClassGradeForStudent, CWSSearchPageStudentList, CWSGetDateRangeCoursewareStat } from '../../../../API/workbench';
import teacherList from './components/teacher-list';
export default {
  data () {
    return {
      statistics: {},
      CoursewarePackage: {},
      params: {
        StartTime: this.$utils.getCurrentMonthFirst(), //	字符串	必须		数据源：GET	起始时间
        EndTime: this.$utils.getCurrentMonthLast(),
        TeacherKey: '',
        GradeKey: '',
        BigCoursewarePackageKey: ''
      }
    };
  },
  components: {
    teacherList, classBiginData
  },
  props: {
    isTeacher: Boolean,
    dataInfo: {
      type: Object,
      default: () => {
        return {
          BJTeacherList: [],
          BJStudentList: []
        };
      }
    }
  },
  created () {

  },

  computed: {
    vToken () {
      return this.$store.getters.token;
    },
    vRoleKey () {
      return Number(this.vToken.RoleKey) == 2; // 2为校长；非则为老师
    }
  },
  methods: {
    // 上课统计
    getDateRangeCoursewareStat (startDate, endDate, GradeKey) {
      console.log('getDateRangeCoursewareStat', startDate, endDate, GradeKey);
      this.params.StartTime = startDate || this.params.StartTime;
      this.params.EndTime = endDate || this.params.EndTime;
      this.params.GradeKey = GradeKey || this.params.GradeKey;
      CWSGetDateRangeCoursewareStat(this.params).then(result => {
        console.log(result.data, '获取课件详情的上课统计');
        console.log(this.params, '提交课件详情的上课统计数据');
        this.statistics = result.data;
      });
      // this.cwsSummList[2].value = Number(result.data.ArrivedNumCount);// 签到人次
      // if (this.params.StartTime == this.params.EndTime) {
      //   this.cwsSummList[3].value = Number(result.data.CourseCount);// 今日已上课次
      //   this.cwsSummList[4].value = Number(result.data.CourseTimeCount);// 今日上课时长
      // } else {
      //   this.cwsSummList[0].value = Number(result.data.CourseCount) || 0;// 已上课次
      //   this.cwsSummList[1].value = Number(result.data.CourseTimeCount) || 0;// 上课时长
      // }
    },
    doViewCoursewareDetails () {
      console.log('this.dataInfo', this.dataInfo);
      if (this.vRoleKey) {
        this.$dialog.open(this, {
          name: '课件选班上课',
          routerName: this.$route.name,
          moduleName: 'classStatistics',
          data: {
            dataInfo: {
              TeacherKey: 0, //
              StartTime: this.params.StartTime,
              EndTime: this.params.EndTime,
              BigCoursewarePackageKey: 0,
              ClassKey: this.dataInfo.OLAPKey,
              ClassKeyValue: this.dataInfo.MainDemoName

            }
          }
        });
      } else {
        this.$dialog.open(this, {
          name: '老师课件选班上课',
          routerName: this.$route.name,
          moduleName: 'classStatisticsTeacher',
          data: {
            dataInfo: {
              StartTime: this.params.StartTime,
              EndTime: this.params.EndTime,
              BigCoursewarePackageKey: 0,
              ClassKey: this.dataInfo.OLAPKey,
              ClassKeyValue: this.dataInfo.MainDemoName
            }
          }
        });
      }
    },
    // 老师自己退出班级
    quitClassForTeacher () {
      layer.confirm('请问是否退出该班级', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              PrincipalquitClassGradeForTeacher(this.dataInfo.OLAPKey, this.vToken.UserID).then(result => {
                this.$emit('updataData');
              }).catch(error => {
                layer.alert(error.msg);
              });
              layer.close();
            }
          }
        ]
      });
    },
    showAddTeacherList () {
      this.$dialog.open(this, {
        routerName: this.$route.name,
        ModulePowerKey: '',
        name: '选择老师',
        moduleName: 'selectTeacher',
        data: { selectedKey: 0 },
        callBack: {
          afterSuccess: (data) => { this.addClassGradeForTeacher(data); }
        }
      });
    },
    // 加老师进班
    addClassGradeForTeacher (item) {
      console.log('addClassGradeForTeacher', item);
      PrincipalAddClassGradeForTeacher(this.dataInfo.OLAPKey, item.OLAPKey).then(result => {
        layer.alert('添加成功');
        this.$emit('updataData');
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    showAddStudentList () {
      let searchObj = {
        pageIndex: 0,
        pageSize: 0,
        searchText: '',
        StudentType: 1
      };
      CWSSearchPageStudentList(searchObj).then((result) => {
        this.studentList = result.data.PageDataList;
        this.$dialog.open(this, {
          routerName: this.$route.name,
          name: '选择学生',
          moduleName: 'singleSelectionList',
          data: {
            selectedKey: 0,
            dataSourceList: this.studentList,
            required: true,
            addModuleName: 'cws-add-student',
            addModuleTitleName: '添加学生',
            ModulePowerKey: 0
          },
          callBack: {
            onSelectedItem: (data) => {
              this.addClassGradeForStudent(data);
            }
          }
        });
      });
    },
    // 加学生进班
    addClassGradeForStudent (item) {
      console.log('addClassGradeForStudent', item);
      PrincipalAddClassGradeForStudent(this.dataInfo.OLAPKey, item.OLAPKey).then(result => {
        layer.alert('添加成功');
        this.$emit('updataData');
      }).catch(error => {
        layer.alert(error.msg);
      });
    },
    // 老师退班
    delClassGradeForTeacher (item) {
      console.log('delClassGradeForTeacher', item);
      layer.confirm('是否将该老师退出班级', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              PrincipalquitClassGradeForTeacher(this.dataInfo.OLAPKey, item.OLAPKey).then(result => {
                this.$emit('updataData');
              }).catch(error => {
                layer.alert(error.msg);
              });
              layer.close();
            }
          }
        ]
      });
    },
    // 学生退班
    delClassGradeForStudent (item) {
      console.log('delClassGradeForStudent', item);
      layer.confirm('是否将该学生退出班级', {
        btn: [
          {
            name: '确认',
            callBack: () => {
              PrincipalQuitClassGradeForStudent(this.dataInfo.OLAPKey, item.OLAPKey).then(result => {
                this.$emit('updataData');
              }).catch(error => {
                layer.alert(error.msg);
              });
              layer.close();
            }
          }
        ]
      });
    }
  }
};
</script>
<style>
.class_detail_header {
  width: 100%;
  padding: 20px 40px;
  border-bottom: 1px solid #ececec;
  display: flex;
}
.principal_header {
  width: 45%;
  display: inline-block;
}
.principal_header_date {
  width: 54%;
  display: inline-block;
}
.principal_header_date .class_bigin_box_par {
  margin: 0px !important;
  height: 120px !important;
}
.principal_header_date .data-list-div {
  display: block;
  float: left;
  width: 102px !important;
  /* height: 40px; */
  text-align: center;
  margin: 10px;
  height: 60px !important;
  background: white;
  padding-top: 10px;
}
.class_detail_header_title {
  /* padding: 20px; */
  width: 100%;
}
.class_detail_header_title .class_detail_header_name {
  font-size: 18px;
}
.class_detail_header_btn {
  position: absolute;
  right: 10px;
  top: 5px;
}
.class_detail_header_btn::before {
  content: '';
  width: 15px;
  height: 16px;
  background-image: url('../../../../../public/image/out_class.png');
  background-size: 15px;
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: 3px;
}

.class_detail_header_content {
  width: 100%;
  margin-top: 20px;
}
.class_detail_header_content .class_detail_teacher_list {
  width: 100%;
  /* margin-top: 10px; */
  display: flex;
}

.class_detail_teacher_list_title {
  color: #999999;
  width: 40px;
  display: inline-block;
  /* margin-right: 10px; */
  font-size: 14px;
  line-height: 20px;
}
.class_detail_teacher_list_content {
  /* display: inline-block; */
  display: flex;
  flex-wrap: wrap;
  width: 350px;
}
.class_detail_teacher_list_item {
  position: relative;
  border: 1px solid #e8ebef;
  border-radius: 3px;
  margin-left: 10px;
  margin-bottom: 10px;
  height: 24px;
  line-height: 12px;
  font-size: 12px;
  min-width: 60px;
  display: inline-block;
  text-align: center;
  line-height: 21px;
}
.class_detail_teacher_list_item_btn {
  color: #999999;
}
</style>