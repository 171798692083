<template>
  <div class="active_box">
    <div class="active_title">
      <div class="active_title_text">{{dataInfo.ActivityKeyValue || '-'}}</div>
      <div v-if="dataInfo.IsHasActivity"
           @click='showList'
           :class="isShowChildList?'redBottom':'upBottom'">
        {{vBottomText}}
        <div :class="isShowChildList?'down':'up'"></div>
      </div>
    </div>
    <div class="active_detail">
      <div class="active_detail_text">推荐报名：<span class="active_detail_span">{{dataInfo.ChannelCount || '0'}}人</span></div>
      <div class="active_detail_text">已返佣金：<span class="active_detail_span"><span class="active_list_mtext">{{Number(dataInfo.Rebate1Amount) || '0'}}</span>元</span></div>
      <div class="active_detail_text">待返佣金：<span class="active_detail_span"><span class="active_list_ptext">{{Number(dataInfo.Rebate0Amount) || '0'}}</span>元</span>
      </div>
    </div>
    <template v-if="isShowChildList">
      <div class="active_list_box"
           v-for="(item,index) in dataInfo.ActivityList"
           :key="index">
        <div class="active_list_li">
          <div class="active_list_la">
            <div class="active_date_icon"></div> 报名日期：<span class="active_list_text">{{item.GenerateTime}}</span>
          </div>
          <div class="active_list_la">
            <div class="active_stu_icon"></div> 报名学生：<span class="active_list_text">{{item.StudentKeyValue}}</span>
          </div>
        </div>
        <div class="active_list_li">
          <div class="active_list_la">
            <div class="active_money_icon"></div> 报名金额：<span class="active_list_text">{{Number(item.ApplyAmount)}}元</span>
          </div>
          <div class="active_list_la">
            <div class="active_ymtmoney_icon"></div>一码通金额：<span class="active_list_text"><span class="active_list_mtext">{{Number(item.RebateAmount)}}</span>元</span>
            <div :class="item.RebateStatusKey=='1'?'green':'red'">{{item.RebateStatusKey=='1'?'已返':'待返'}}</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isShowChildList: false,
      loadData: {}
    };
  },
  props: {
    dataInfo: Object,
    isCurrActive: Boolean
  },
  computed: {
    vBottomText () {
      return this.isShowChildList ? '收起' : '展开';
    }
  },
  mounted () {
    console.log(this.dataInfo, 'dataInfo');
    this.isShowChildList = this.isCurrActive;
  },
  methods: {
    showList () {
      this.isShowChildList = !this.isShowChildList;
      console.log(this.isShowChildList, this.isCurrActive, this.dataInfo, 'this.dataInfo');
      if (this.isShowChildList && !this.isCurrActive) {
        // 调用接口
        this.$emit('getHistoryDetail', this.dataInfo);
      }
    }
  }
};
</script>

<style scoped>
.active_box {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 18px;
  padding-left: 22px;
  border-radius: 6px;
}

.active_title {
  display: flex;
  flex-direction: row;
}

.active_detail {
  display: flex;
  flex-direction: row;
}

.active_title_text {
  flex: 1;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
}

.redBottom {
  width: 60px;
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #f75040;
  justify-content: center;
  border-radius: 15px;
  border: 1px solid #f75040;
  margin-right: 15px;
  cursor: pointer;
}

.upBottom {
  width: 60px;
  height: 30px;
  border: 1px solid #cfcfcf;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #999999;
  justify-content: center;
  border-radius: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.up {
  width: 12px;
  height: 8px;
  background-image: url(../../../../public/image/ymtup.png);
  background-size: 100%;
  margin-left: 2px;
}

.down {
  width: 12px;
  height: 8px;
  background-image: url(../../../../public/image/ymtdown.png);
  background-size: 100%;
  margin-left: 2px;
}

.active_detail_text {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 125px;
  height: 30px;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #999999;
}

.active_detail_span {
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
  color: #333333;
}

.active_list_box {
  display: flex;
  flex-direction: column;
  width: 95%;
  background: linear-gradient(0deg, #f8f8fa, #f8f8fa),
    linear-gradient(90deg, #fbfdff 0%, #fbfdff 100%),
    linear-gradient(0deg, #ffffff, #ffffff);
  padding: 3px 10px;
  margin-bottom: 10px;
}

.active_list_li {
  display: flex;
  flex-direction: row;
}

.active_list_la {
  font-size: 14px;
  line-height: 30px;
  text-align: left;
  display: flex;
  flex: 1;
  align-items: center;
  color: #999999;
}

.active_date_icon {
  width: 15px;
  height: 15px;
  background-image: url(../../../../public/image/abouttdate.png);
  background-size: 100%;
  margin-right: 7px;
}

.active_stu_icon {
  width: 15px;
  height: 15px;
  background-image: url(../../../../public/image/aboutstu.png);
  background-size: 100%;
  margin-right: 7px;
}

.active_money_icon {
  width: 15px;
  height: 15px;
  background-image: url(../../../../public/image/aboutmoney.png);
  background-size: 100%;
  margin-right: 7px;
}

.active_ymtmoney_icon {
  width: 15px;
  height: 15px;
  background-image: url(../../../../public/image/ymtrebat.png);
  background-size: 100%;
  margin-right: 7px;
}

.active_list_text {
  color: #333333;
}

.active_list_mtext {
  color: #ff9800;
}

.active_list_ptext {
  color: #f75040;
}

.green {
  width: 40px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  background: #c6ecdb;
  color: #3ba66f;
  border-radius: 3px;
  margin-left: 7px;
}

.red {
  width: 40px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  background: #f7cbc7;
  color: #f75040;
  border-radius: 3px;
  margin-left: 7px;
}
</style>