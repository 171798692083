<template>
  <!-- 基础设置项 -->
  <div>
    <div class="content_marginRL content_marginTop_30">
      <!-- 内容 -->
      <div class="form_info_edit mendian_setting_content single_shadow"
           :class="{btn_marginBottom_30:isReadonly}">
        <div>
          <input-text :formTitle="'名称'"
                      :readonly="isReadonly"
                      :formPlaceholder="'名称'"
                      :required="true"
                      :maxLength="14"
                      v-model="dataJson.MainDemoName"></input-text>
        </div>

        <div v-if="coursList.length>0 &&Number(dataJson.FatherKey)>2">
          <input-dialog :formTitle="'上级课类'"
                        :formPlaceholder="'上级课类'"
                        class="no_border_bottom"
                        :readonly="isReadonly"
                        :required="true"
                        @showEldilog="showSingleSelectionDialog()"
                        v-model="FatherKeyValue"></input-dialog>
        </div>
      </div>
    </div>
    <!-- 按钮组 -->
    <div v-if="!isReadonly"
         class="">
      <save-cancel-btn-group :isSingleSubmit="true"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="cancelClick"></save-cancel-btn-group>
    </div>
    <div v-else
         class="on_form_footer_btn_group"></div>
    <custom-dialog :title="'选择上级课类'"
                   class="new_class defult_dialog"
                   :visible.sync="isChildForm"
                   :before-close="doCloseChildForm">

      <single-select-tree :sourceDate="coursList"
                          :selectionDate="vDataJsonInfo"
                          :disabledType="2"
                          @confirmClcik="doAfterSelectedItem"
                          @cancleClcik="closeSingleSelectionList"></single-select-tree>
    </custom-dialog>

  </div>

</template> 

<script>
import singleSelectTree from '../../../common/single-select-tree';
import { AddTheClassType, hqAddTheClassType, EditTheClassType, hqEditTheClassType, getCourseTypeNameList, hqGetNotDelCourse } from '../../../../API/workbench';

export default {
  props: {// 是否显示表单
    isReadonly: {// 是否只读
      type: Boolean,
      default: false
    },
    editItem: {// 修改项
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data () {
    return {
      coursList: '', // 课类课名列表
      IsSameContinue: '0', // 是否同名后继续：0-否;1-是;
      // 提交数据对象
      dataJson: {
        MainDemoName: '', // -- 名称
        FatherKey: 2, // ---上级课类Key 0为全部
        TypeKey: '1', // --  类型key
        UnitPrice: 0// 单价
      },
      FatherKeyValue: '全部课程', // --上级课类
      isChildForm: false
    };
  },

  components: {
    singleSelectTree
  },
  watch: {
  },
  created () {
    this.getInquirePort();
    console.log(this.editItem, 'editItem');
    if (JSON.stringify(this.editItem) != '{}') {
      this.dataJson.OLAPKey = this.editItem.OLAPKey;
      this.dataJson.MainDemoName = this.editItem.MainDemoName;
      this.dataJson.FatherKey = this.editItem.FatherKey;
      this.dataJson.UnitPrice = this.editItem.UnitPrice;
      this.FatherKeyValue = this.editItem.FatherKeyValue;
    }
  },
  computed: {
    vDataJsonInfo: {
      get () {
        return {
          id: this.dataJson.FatherKey,
          label: this.FatherKeyValue
        };
      },
      set (newValue) {
        this.FatherKeyValue = newValue.label;
        this.dataJson.FatherKey = newValue.id;
      }
    },
    vTypeKey () {
      if (Number(this.$store.getters.SaaSClientInfo.TypeKey) == 6) { // 总部key为6
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    // 子组件弹出层显示
    showSingleSelectionDialog () {
      this.isChildForm = true;
    },
    // 子组件弹出层取消
    doCloseChildForm () {
      this.isChildForm = false;
    },
    // 课类确定
    doAfterSelectedItem (item) {
      this.dataJson.FatherKey = item.id;
      this.FatherKeyValue = item.label;
      this.isChildForm = false;
      console.log(item, 'doAfterSelectedItem');
    },
    // 课类取消
    closeSingleSelectionList () {
      this.isChildForm = false;
    },
    getInquirePort () {
      let apiFN = (this.vTypeKey ? hqGetNotDelCourse : getCourseTypeNameList);
      apiFN().then(result => {
        console.log(result, '课类查询');
        this.coursList = [];
        if (JSON.stringify(this.editItem) != '{}') { // 修改时过滤掉该课类
          result.data.forEach(o => {
            if (o.TypeKey == 1 && o.StateKey == 2 && this.editItem.OLAPKey !== o.OLAPKey) {
              this.coursList.push(o);
              o.typeKey = o.TypeKey;
            }
          });
        } else {
          result.data.forEach(o => {
            if (o.TypeKey == 1 && o.StateKey == 2) {
              this.coursList.push(o);
              o.typeKey = o.TypeKey;
            }
          });
        }
        if (this.coursList.length > 0) {
          this.coursList.splice(0, 0, {
            OLAPKey: '2',
            MainDemoName: '全部课程',
            FatherKey: '0',
            FatherKeyValue: '',
            Number: '0',
            StatusKey: '2',
            typeKey: 2,
            StatusKeyValue: '启用',
            RemarksSupport: ''
          });
        }
      });
      console.log(this.coursList, '课类查询');
    },
    // 确认按钮
    confirmClick (callBack) {
      let flag = false;
      if (this.dataJson.MainDemoName) {
        this.dataJson.MainDemoName = this.dataJson.MainDemoName.replace(/(^\s*)|(\s*$)/g, '');
      }
      if (!this.dataJson.MainDemoName) {
        layer.alert('请输入名称');
        flag = true;
      } else if (isNaN(this.dataJson.FatherKey)) {
        layer.alert('请选择上级课类');
        flag = true;
      } else if (this.FatherKeyValue == this.dataJson.MainDemoName) {
        layer.alert('名称不能与上级课类重名');
        flag = true;
      } else if (this.dataJson.MainDemoName && this.dataJson.MainDemoName.substr(this.dataJson.MainDemoName.length - 1, 1) == '班') {
        layer.alert('名称最后一个字不能为“班”,请修改后再提交。');
        flag = true;
      }
      if (flag) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      if (JSON.stringify(this.editItem) != '{}') {
        delete this.dataJson.TypeKey;
        let apiFN = (this.vTypeKey ? hqEditTheClassType : EditTheClassType);
        apiFN(this.IsSameContinue, this.dataJson).then(result => {
          console.log(result, '课类修改');
          this.$emit('afterSuccess', 'Edit', result.data);
          this.cancelClick();
          layer.alert('修改成功');
        }, error => {
          if (error.code == 10000) {
            if (error.data && error.data[0].StateKey == 2) {
              layer.alert(error.msg);
            } else if (error.data && error.data[0].StateKey == 3) {
              let name = error.data[0].TypeKey == 1 ? '课类' : '课程';
              layer.confirm('已有相同名称且停用的' + name + ',是否继续?', {
                btn: [{
                  name: '确认',
                  callBack: () => {
                    apiFN(1, this.dataJson).then(result => {
                      this.$emit('afterSuccess', 'Edit', result.data);
                      this.cancelClick();
                      layer.alert('修改成功');
                    });
                    layer.close();
                  }
                }
                ]
              });
            }
          } else {
            layer.alert(error.msg);
          }
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      } else {
        let apiFN = (this.vTypeKey ? hqAddTheClassType : AddTheClassType);
        apiFN(this.IsSameContinue, this.dataJson).then(result => {
          console.log(result, '课类新增');
          this.$emit('afterSuccess', 'Add', result.data);
          this.cancelClick();
          layer.alert('新增成功');
        }, error => {
          if (error.code == 10000) {
            if (error.data && error.data[0].StateKey == 2) {
              layer.alert(error.msg);
            } else if (error.data && error.data[0].StateKey == 3) {
              let name = error.data[0].TypeKey == 1 ? '课类' : '课程';
              layer.confirm('已有相同名称且停用的' + name + ',是否继续?', {
                btn: [{
                  name: '确认',
                  callBack: () => {
                    apiFN(1, this.dataJson).then(result => {
                      this.$emit('afterSuccess', 'Add', result.data);
                      this.cancelClick();
                      layer.alert('新增成功');
                    });
                    layer.close();
                  }
                }
                ]
              });
            }
          } else {
            layer.alert(error.msg);
          }
        }).finally(() => {
          if (callBack) {
            callBack();
          }
        });
      }
    },
    // 取消按钮
    cancelClick () {
      this.$emit('cancelClick');
    }
  }
};
</script>

  <style>
</style>