<template>
  <div>
    <div class="content_marginRL content_marginTop_30">
      <course-info :circleCourseInfo="circleCourseInfo"></course-info>

      <!-- 内容 -->
      <!-- <div class="form_info_edit mendian_setting_content single_shadow border_bottom">
        <input-date v-model="submitData.CircleStartTime"
                    :elementId="'beginDate'"
                    :required="true"
                    :formTitle="'开始日期'"
                    :minDate="$utils.formatDateToLine(Date.new())"
                    @changeDate="doAfterChangeBeginDate"></input-date>
        <input-date v-model="submitData.CircleEndTime"
                    :elementId="'endDate'"
                    :required="true"
                    :formTitle="'结束日期'"
                    :minDate="$utils.formatDateToLine(Date.new(submitData.CircleStartTime))"
                    @changeDate="doAfterChangeEndDate"></input-date>
        <div class="form_info_line">
          <div class="form_week_content flex">
            <div class="form_week_list form_week_list_big"
                 :class="{opt:item.isActive}"
                 v-for="(item,index) in fastBtnList"
                 :key="index"
                 @click="clickDataBtnType(item)">{{item.value}}</div>
          </div>
        </div>
        <input-number :min="0"
                      :required="false"
                      :placeholder="'0.00元'"
                      v-model="vClassCount"
                      :decimalPlaces="0"
                      :readonly="true"
                      :formTitle="'节数'"></input-number>
        <input-dialog :formTitle="'上课教室'"
                      :formPlaceholder="'教室'"
                      v-model="submitData.ClassRoomName"
                      @showEldilog="showClassRoomDialog"></input-dialog>

        <div class="pr">
          <span class="pa input_date_week"
                style="top:8px">学生上课消耗</span>
          <input-number-dialog :formTitle="'消耗课时'"
                               :dialogTitleName="'输入课时'"
                               v-model="vPeriodCount"
                               :decimalPlaces="1"
                               :isShowButtonInfo="true"
                               :required="true"
                               :min="0"
                               :formPlaceholder="'学生上课消耗'"></input-number-dialog>
        </div>

        <input-number :min="0"
                      v-model="submitData.FullPeopleCount"
                      :decimalPlaces="0"
                      :formTitle="'满员人数'"
                      :required="true"></input-number>
        <div v-if="vIsShowChargeLeave">
           <input-switch v-model="submitData.IsleaveKey"
                      :formTitle="'请假扣课时'"
                      style="padding-right: 23px;"
                      :required="true"></input-switch>
        </div>
        <div v-if="vIsShowChargeTruant">
          
           <input-switch v-model="submitData.LeaveClassKey"
                      :formTitle="'旷课扣课时'"
                      style="padding-right: 23px;"
                      :required="true"></input-switch>
        </div>
      </div> -->
     <div> 
      <div class="new_course_title"
          style="margin-bottom:0.22rem">
        循环规律
      </div>
      <div style="min-height:226px;margin: 12px 0 120px">
        <div style="padding: 0 0.3rem;">
          <div class="new_course_time_from">
            <div class="form_info_list">
              <input-date v-model="submitData.CircleStartTime"
                          :elementId="'circleStartTime'"
                          :formTitle="'开始日期'"
                          :placeholder="'开始日期'"
                          :minDate="minDate"
                          @changeDate="changeCircleStartTime"></input-date>
            </div>
            <div class="form_info_list pr">
              <input-select class="popTop_3"
                    :formTitle="'结束方式'"
                    :id="'key'"
                    :name="'value'"
                    :selectedInfo="vSelectionEndType"
                    :readonly="false"
                    :dataList="vEndTypes"
                    :helpDescription="vHelpDescription"
                    @selectedItem="changeEndType"></input-select>
              <div class="new_course_btn_box" v-if="submitData.EndTypeKey==2">
                <div class="new_course_btn"
                    v-for="item in fastBtnList"
                    :key="item.key"
                    :class="{opt:item.isActive}"
                    @click.stop="clickDataBtnType(item)">
                  {{ item.value}}
                </div>
              </div>
              <div class="new_course_btn_box" v-if="submitData.EndTypeKey==3">
                <input-number 
                      :min="0"
                      v-model="submitData.ClassCount"
                      :decimalPlaces="0"
                      :formTitle="''"
                      :placeholder="'必填'"
                      :required="false"></input-number>
                <span class="text_pa">节</span>
              </div>
            </div>
            <div class="form_info_list pr" v-if="submitData.EndTypeKey==2">
              <input-date v-model="submitData.CircleEndTime" 
                          :elementId="'circleEndTime'"
                          :formTitle="'结束日期'"
                          :placeholder="'结束日期'"
                          :minDate="$utils.formatDateToLine(Date.new())"
                          @changeDate="changeCircleEndTime"></input-date>
              <div class="new_course_btn_box">
                <span>{{submitData.ClassCount}}节</span>
              </div>
            </div>
          </div>
        </div>
      </div>
     </div>
      <save-cancel-btn-group :isSingleSubmit="true"
                             style="margin-top:-1px"
                             :singleSubmitType="'dynamic'"
                             @confirmClick="confirmClick"
                             @cancelClick="closeDilog"></save-cancel-btn-group>
    </div>

    <custom-dialog title="选择教室"
                   :visible.sync="isShowClassRoomDialog"
                   :before-close="closeClassRoomDialog">

      <btn-add-select :addTitleName="'新增'"
                      :addmoduleName="'classroom-setting'"
                      :addmoduleTitleName="'add'"
                      :addmoduleKey='45'
                      @doAddSuccessEvent="doAfterSelectClassRoom"></btn-add-select>

      <single-selection-list :selectedKey="submitData.ClassRoomIDSName"
                             :dataSourceList="classRoomList"
                             :required="false"
                             @onSelectedItem="doAfterSelectClassRoom"></single-selection-list>
      <!-- 显示加载的进度条 -->
            
    </custom-dialog> 
    <custom-dialog class="progress_max progress"
                           width="300px"

                           :visible.sync="isShowProgress">
              <div class="progress_title">
                正在续建循环课
              </div>
              <div class="progress_box">
                <el-progress type="circle"
                             :percentage="batchAttencePercentage"></el-progress>
              </div>
              <div class="progress_exit" @click="isShowProgress = false"></div>
            </custom-dialog>
  </div>
</template>
<script>
import courseInfo from '../course-info';
import { AddCirculatedScheduleCourse, getValidClassroomList, GetStructedRuleDetail } from '../../../API/workbench';
export default {
  data () {
    return {
      circleCourseInfo: {},
      submitData: {
        BJPKIDKey: 0,
        BJPKIDKeyValue: '',
        ClassSetKey: 0,
        ClassSetKeyValue: '',
        FullPeopleCount: 0,
        CircleStartTime: '',
        CircleEndTime: '',
        WeekList: [],
        TimeQuantum: '',
        DurationCount: 0,
        ClassRoomIDSName: '',
        ClassRoomName: '',
        LecturerKeyValue: '',
        LecturerKey: '',
        SynchronizeKey: 0,
        PeriodCount: 1.0,
        LeaveClassKey: 0,
        LeaveClassKeyValue: '否',
        IsleaveKey: 0,
        IsleaveKeyValue: '否',
        ClassCount: 0, // 节数
        EndTypeKey: 2,
        EndTypeKeyValue: '限日期'
      },
      dataBtnInfo: {},
      fastBtnList: [
        { value: '1个月', key: 1, isActive: false },
        { value: '2个月', key: 2, isActive: false },
        // { value: '3个月', key: 3, isActive: false },
        // { value: '半年', key: 4, isActive: false },
        { value: '1年', key: 3, isActive: false }
      ],
      classRoomList: [],
      isShowClassRoomDialog: false,
      weekList: [
        { value: '一', key: 1, isActive: false },
        { value: '二', key: 2, isActive: false },
        { value: '三', key: 3, isActive: false },
        { value: '四', key: 4, isActive: false },
        { value: '五', key: 5, isActive: false },
        { value: '六', key: 6, isActive: false },
        { value: '日', key: 7, isActive: false }
      ],
      endTypes: [
        { value: '不结束', key: 1, isActive: false },
        { value: '限日期', key: 2, isActive: false },
        { value: '限节数', key: 3, isActive: false }
      ],
      isShowProgress: false,
      batchAttencePercentage: 0,
      minDate: ''
    };
  },
  components: {
    courseInfo
  },
  props: {
    ruleID: [String, Number]
  },
  computed: {
    vEndTypes () {
      if (this.$store.getters.isSysVersionTY) { // true：通用版
        return this.endTypes;
      } else { // false精准版
        return this.endTypes.slice(1, 3);
      }
    },
    vHelpDescription () {
      if (this.$store.getters.isSysVersionTY) { // true：通用版
        return '不结束：一直延续，不结束循环课<br/>限日期：指定日期结束循环课<br/>限节数：指定开课节数，自动计算结束日期';
      } else { // false精准版
        return '限日期：指定日期结束循环课<br/>限节数：指定开课节数，自动计算结束日期';
      }
    },
    vClassCount () {
      let weekNum = 0;
      if (this.submitData.CircleStartTime && this.submitData.CircleEndTime) {
        let weekday = this.$utils.getWeekNum(Date.new(this.submitData.CircleStartTime), Date.new(this.submitData.CircleEndTime));
        weekNum = weekday[this.submitData.WeekList[0]];
      }
      return weekNum;
    },
    vPeriodCount: {
      get () {
        return {
          number: this.submitData.PeriodCount,
          UnitName: '课时 / 节课'
        };
      },
      set (newValue) {
        this.submitData.PeriodCount = newValue.number;
      }
    },
    // 2不扣课时 3扣课时 4建课设置
    vIsShowChargeTruant () {
      return this.$utils.getSaaSClientSet(33) == 4 && this.$utils.getSaaSClientSet(59) > 0;
    },
    //  2不扣课时 3扣课时 4建课设置
    vIsShowChargeLeave () {
      return this.$utils.getSaaSClientSet(32) == 4;
    },
    vSelectionEndType: {
      get () {
        return {
          id: this.submitData.EndTypeKey,
          name: this.submitData.EndTypeKeyValue
        };
      }
    }
  },
  watch: {
    'submitData.IsleaveKey': {
      handler (newVal, oldVal) {
        if (newVal == 1) {
          this.submitData.IsleaveKeyValue = '是';
        } else {
          this.submitData.IsleaveKeyValue = '否';
        }
      },
      deep: true
    },
    'submitData.LeaveClassKey': {
      handler (newVal, oldVal) {
        if (newVal == 1) {
          this.submitData.LeaveClassKeyValue = '是';
        } else {
          this.submitData.LeaveClassKeyValue = '否';
        }
      },
      deep: true
    }
  },
  created () {
    this.loadStructedRuleDetail();
    this.loadDataSourceClassRoom();
  },
  methods: {
    // 变更结束类型
    changeEndType (item) {
      switch (item.key) {
        case 1:
          this.submitData.CircleEndTime = this.$utils.formatDateToLine(this.$utils.getNextYear(Date.new()));
          this.calculateClassCount();
          break;
        case 2:
          this.submitData.CircleEndTime = '';
          this.fastBtnList.forEach(obj => {
            obj.isActive = false;
          });
          this.submitData.ClassCount = 0;
          break;
        case 3:
          this.submitData.CircleEndTime = '';
          this.submitData.ClassCount = 0;
          break;
        default:
          break;
      }
      console.log('changeEndType', item);
      this.submitData.EndTypeKey = item.key;
      this.submitData.EndTypeKeyValue = item.value;
    },
    // 排课规律->排课规律详情
    loadStructedRuleDetail () {
      GetStructedRuleDetail(this.ruleID).then(result => {
        this.circleCourseInfo = result.data;
        this.initCircleCourseData(result.data);
      });
    },
    initCircleCourseData (courseInfo) {
      let lastClassTime = Date.new(courseInfo.LastClassTime);
      // 判断如果班级结束时间大于现在时间, 将续课开始时间设置为结束时间的下一天
      if (Date.new(courseInfo.LastClassTime) < Date.new(this.$utils.formatDateToLine(Date.new()))) {
        this.submitData.CircleStartTime = this.$utils.formatDatet(Date.new(), 'yyyy-MM-dd');// Date.new().toLocaleDateString();
      } else {
        this.submitData.CircleStartTime = this.$utils.formatDatet(Date.new(lastClassTime.setDate(lastClassTime.getDate() + 1)), 'yyyy-MM-dd');
      }
      this.minDate = this.submitData.CircleStartTime;
      this.submitData.TimeQuantum = courseInfo.ClassTimeName;
      this.submitData.BJPKIDKey = courseInfo.GradeClassKey;
      this.submitData.BJPKIDKeyValue = courseInfo.GradeClassKeyValue;
      this.submitData.ClassSetKey = courseInfo.CourseNameKey;
      this.submitData.ClassSetKeyValue = courseInfo.CourseNameKeyValue;
      this.submitData.LecturerKeyValue = courseInfo.MTeacherKeyValue;
      this.submitData.LecturerKey = courseInfo.MTeacherKey;
      this.submitData.FullPeopleCount = courseInfo.FullPeopleCount;
      this.submitData.PeriodCount = courseInfo.SchedulePeriodCount;
      this.submitData.LeaveClassKey = courseInfo.IsChargesKey;
      this.submitData.LeaveClassKeyValue = courseInfo.IsChargesKeyValue;
      this.submitData.IsleaveKey = courseInfo.IsleaveKey;
      this.submitData.IsleaveKeyValue = courseInfo.IsleaveKeyValue;
      this.submitData.ClassRoomIDSName = courseInfo.classRoomKeyName;
      this.submitData.ClassRoomName = courseInfo.classRoomName;
      this.submitData.WeekList.push(Number(courseInfo.WeekKey));
    },
    // 获取教室列表
    loadDataSourceClassRoom () {
      getValidClassroomList().then(result => {
        this.classRoomList = result.data;
      });
    },
    // 打开教室列表
    showClassRoomDialog () {
      this.isShowClassRoomDialog = true;
    },
    // 选择教室
    doAfterSelectClassRoom (item) {
      this.submitData.ClassRoomIDSName = item.OLAPKey || 0;
      this.submitData.ClassRoomName = item.MainDemoName || '';
      this.closeClassRoomDialog();
    },
    // 关闭教室列表
    closeClassRoomDialog () {
      this.loadDataSourceClassRoom();
      this.isShowClassRoomDialog = false;
    },
    // 选择开始日期
    doAfterChangeBeginDate (date) {
      this.dataBtnInfo.isActive = false;
      this.submitData.CircleStartTime = date;
    },
    // 选择结束日期
    doAfterChangeEndDate (date) {
      this.dataBtnInfo.isActive = false;
      this.submitData.CircleEndTime = date;
      this.calculateClassCount();
    },
    clickDataBtnType (item) {
      this.dataBtnInfo.isActive = false;
      item.isActive = true;
      this.dataBtnInfo = item;
      let curDate = Date.new(this.submitData.CircleStartTime);
      let year = curDate.getFullYear();
      let month = curDate.getMonth();
      let date = curDate.getDate() - 1;
      switch (item.key) {
        case this.fastBtnList[0].key:
          this.submitData.CircleEndTime = this.$utils.formatDateToLine(Date.new(year, month + 1, date));
          break;
        case this.fastBtnList[1].key:
          this.submitData.CircleEndTime = this.$utils.formatDateToLine(Date.new(year, month + 2, date));
          break;
        // case this.fastBtnList[2].key:
        //   this.submitData.CircleEndTime = this.$utils.formatDateToLine(Date.new(year, month + 3, date));
        //   break;
        // case this.fastBtnList[3].key:
        //   this.submitData.CircleEndTime = this.$utils.formatDateToLine(Date.new(year, month + 6, date));
        //   break;
        case this.fastBtnList[2].key:
          this.submitData.CircleEndTime = this.$utils.formatDateToLine(Date.new(year + 1, month, date));
          break;
        default:
          break;
      }
      this.calculateClassCount();
    },
    // 部分日期 排课提交
    confirmPartClick (callBack) {
       console.log(
        this.$websocket.ws.readyState,
        'this.$websocket.ws.readyState'
      );
       if (this.$websocket.ws.readyState != 1) {
        layer.alert('网络不稳定，请稍后再试。');
        if(callBack){
          callBack()
        }
        return false;
      }
      let flag = false;
      if (!this.submitData.CircleStartTime) {
        layer.alert('开始日期不可为空 。');
        flag = true;
      } else if (!this.submitData.CircleEndTime) {
        layer.alert('结束日期不可为空 。');
        flag = true;
      } else if (Date.new(this.submitData.CircleEndTime).getTime() > Date.new(this.submitData.CircleEndTime).getTime()) {
        layer.alert('开始日期不可大于结束日期 。');
        flag = true;
      } else if (!this.vClassCount) {
        layer.alert('至少建立一节课 。');
        flag = true;
      }
      if (flag) {
        if (typeof callBack == 'function') {
          callBack();
        }
        return;
      }
      console.log('this.submitData', this.$utils.parseJson(this.submitData));
      this.isShowProgress = true;
      this.batchAttencePercentage = 0;
      AddCirculatedScheduleCourse(this.submitData).then(result => {
        console.log('AddCirculatedScheduleCourse', result.data);

        this.$asyncTask.register(result.data.AsynTaskID, (sendData) => {
          console.log('新建课长连接通知:', sendData);
          if (sendData.nodeType != 0 && sendData.status == 1) {
            console.log(sendData.progress, 'sendData.progress');
            this.batchAttencePercentage = Math.round(sendData.progress); // 批量考勤的百分比
          } else if (sendData.nodeType == 0 && sendData.status == 1) {
              // 结束节点
            this.batchAttencePercentage = Math.round(sendData.progress); // 批量考勤的百分比
            this.isShowProgress = false;
            this.$asyncTask.delete(sendData.AsynTaskID);
            layer.alert('续建循环课成功');
            this.$emit('afterSucces', result.data);
            this.$bus.emit('AfterNewCourseTable', 'mutil', { CourseNameKey: this.submitData.ClassSetKey, classGradeId: this.submitData.BJPKIDKey });
            this.$emit('closeDialog');
          } else if (sendData.status == 0) {
              // status：1成功 0失败
            console.log('批量建课某个节点失败-》sendData:', sendData);
          }
        });
      }, error => {
        this.isShowProgress = false;
        if (typeof callBack == 'function') {
          callBack();
        }
        if (error.code == 10001) {
          layer.alert('课程表冲突，请检查后再修改');
        } else {
          layer.alert(error.msg);
        }
      });
    },
    closeDilog () {
      this.$emit('closeDialog');
    },
    // 确定
    confirmClick (callBack) {
       console.log(
        this.$websocket.ws.readyState,
        'this.$websocket.ws.readyState'
      );
      if (this.$websocket.ws.readyState != 1) {
        layer.alert('网络不稳定，请稍后再试。');
        if(callBack){
          callBack()
        }
        return false;
      }
      // 是否拒绝提交
      let isRejectSubmit = false;
      if (this.verifySubmitData()) {
        isRejectSubmit = true;
      } else if (this.checkNewCoursesConutIsZero()) { // 是否建立了0节的循环课块
        isRejectSubmit = true;
      }
      if (isRejectSubmit) {
        if (typeof callBack == 'function') {
          callBack();
        }
        return false;
      }
      this.isShowProgress = true;
      this.batchAttencePercentage = 0;
      AddCirculatedScheduleCourse(this.submitData).then(result => {
        this.$asyncTask.register(result.data.AsynTaskID, (sendData) => {
          console.log('新建课长连接通知:', sendData);

          if (sendData.nodeType != 0 && sendData.status == 1) {
            console.log(sendData.progress, 'sendData.progress');
            this.batchAttencePercentage = Math.round(sendData.progress); // 批量考勤的百分比
          } else if (sendData.nodeType == 0 && sendData.status == 1) {
              // 结束节点
            this.batchAttencePercentage = Math.round(sendData.progress); // 批量考勤的百分比
            this.isShowProgress = false;
            this.$asyncTask.delete(sendData.AsynTaskID);
            layer.alert('续建循环课成功');
            this.$emit('afterSucces', result.data);
            this.$bus.emit('AfterNewCourseTable', 'mutil', { CourseNameKey: this.submitData.ClassSetKey, classGradeId: this.submitData.BJPKIDKey });
            this.$emit('closeDialog');
          } else if (sendData.status == 0) {
              // status：1成功 0失败
            console.log('批量建课某个节点失败-》sendData:', sendData);
          }
        });
      }, error => {
        this.isShowProgress = false;
        if (typeof callBack == 'function') {
          callBack();
        }
        if (error.code == 10001) {
          layer.alert('课程表冲突，请检查后再修改');
        } else {
          layer.alert(error.msg);
        }
      });
    },
    // 提交
    toSubmit (callBack) {
      this.isShowProgress = true;
      AddCirculatedScheduleCourse(this.submitData).then(result => {
        console.log('AddCirculatedScheduleCourse', result.data);
        this.isShowProgress = false;
        this.isAdding = false;
        this.afterAddCircleCourseSuccess(result.data);
        if (typeof callBack == 'function') {
          callBack();
        }
      }, error => {
        this.isShowProgress = false;
        if (error.code == 1) {
          layer.alert(error.msg);
        } else if (error.code == 10000) {
          layer.alert(error.msg);
        } else if (error.code == 60101) {
          this.isShowCoursesConflictTips = true;
          this.isClassGradeConflictTips = true;
          this.getValidClassList();
        } else {
          layer.alert(error.msg);
        }
        if (typeof callBack == 'function') {
          callBack();
        }
      });
    },
    // 计算上课节数
    calculateClassCount () {
      let maxClassNum = 0;
      if (this.submitData.CircleStartTime && this.submitData.CircleEndTime && this.submitData.WeekList.length > 0 && this.submitData.TimeQuantum) {
        let curMinutes = Date.new().getHours() * 60 + Date.new().getMinutes(); // 当前时段
        let classMinutes = Number(this.submitData.TimeQuantum.split('-')[1].split(':')[0]) * 60 + Number(this.submitData.TimeQuantum.split('-')[1].split(':')[1]); // 上课时段

        let curWeek = Date.new(this.submitData.CircleStartTime).getDay(); curWeek = curWeek == 0 ? 7 : curWeek;// 开始日期是周几
        let isTodayPass = false; // 是否选了今天的日期且为过去时段
        if (Date.new(this.submitData.CircleStartTime).getTime() == Date.new(this.$utils.formatDateToLine(Date.new())).getTime() && classMinutes <= curMinutes) {
          isTodayPass = true;
        }
        this.submitData.WeekList.forEach(week => {
          let datas = this.$utils.getDateArrForOneDate(this.submitData.CircleStartTime, this.submitData.CircleEndTime, week);
          console.log('测试：', this.submitData.CircleStartTime, this.submitData.CircleEndTime, week, datas);
          maxClassNum = maxClassNum + datas.length;
            // 开始日期为今天 且上课时段为过去时间
          if (isTodayPass && week == curWeek) {
            --maxClassNum;
            datas.shift();
          }
        });
      } else if (this.submitData.EndTypeKey == 3 && !this.submitData.CircleEndTime) {
        maxClassNum = this.submitData.ClassCount;
      }
      this.submitData.ClassCount = maxClassNum;
    },
    // 验证要提交的数据
    verifySubmitData () {
      let flag = false;
      if (!this.submitData.CircleStartTime) {
        flag = true;
        layer.alert('开始日期不可为空');
      } else if (Date.new(this.submitData.CircleStartTime).getTime() > this.$utils.getNextYear(Date.new()).getTime()) {
        flag = true;
        layer.alert('最长只能建立一年内的课');
      } else if (!this.submitData.CircleEndTime && this.submitData.EndTypeKey == 2) {
        flag = true;
        layer.alert('结束日期不可为空');
      } else if (Date.new(this.submitData.CircleStartTime).getTime() > Date.new(this.submitData.CircleEndTime).getTime() && this.submitData.EndTypeKey == 2) {
        flag = true;
        layer.alert('开始日期不可晚于结束日期');
      } else if (this.submitData.WeekList.length == 0) {
        flag = true;
        layer.alert('至少选择一个周几');
      } else if (!this.submitData.TimeQuantum) {
        flag = true;
        layer.alert('上课时间不能为空');
      }
      return flag;
    },
    // 检查新建节数是否为0
    checkNewCoursesConutIsZero () {
      this.submitData.WeekList.sort((a, b) => { return a - b; });
      let zeroCourses = '';
      let flag = false;
      let curMinutes = Date.new().getHours() * 60 + Date.new().getMinutes();
      let classMinutes = Number(this.submitData.TimeQuantum.split('-')[1].split(':')[0]) * 60 + Number(this.submitData.TimeQuantum.split('-')[1].split(':')[1]);
      if (this.submitData.EndTypeKey == 1) { // 不结束
        let maxClassNum = 0;
        let curWeek = Date.new(this.submitData.CircleStartTime).getDay(); curWeek = curWeek == 0 ? 7 : curWeek;// 开始日期是周几
        let isTodayPass = false; // 是否选了今天的日期且为过去时段
        let classAllDates = [];
        if (Date.new(this.submitData.CircleStartTime).getTime() == Date.new(this.$utils.formatDateToLine(Date.new())).getTime() && classMinutes <= curMinutes) {
          isTodayPass = true;
        }
        let nonClassNumWeekList = []; // 没有课的周几列表
        let weekStr = '';
        this.submitData.WeekList.forEach(week => {
          let datas = this.$utils.getDateArrForOneDate(this.submitData.CircleStartTime, this.submitData.CircleEndTime, week);
          maxClassNum = maxClassNum + datas.length;
          // 开始日期为今天 且上课时段为过去时间
          if (isTodayPass && week == curWeek) {
            --maxClassNum;
            datas.shift();
          }
          if (datas.length > 0) {
            classAllDates = classAllDates.concat(datas);
          } else {
            nonClassNumWeekList.push(week);
          }
        });
        if (maxClassNum <= 0 || nonClassNumWeekList.length > 0) {
          nonClassNumWeekList.forEach(week => {
            let weekItem = this.weekList.find(obj => { return week == obj.key; });
            weekStr = weekStr ? weekStr + ('、周' + weekItem.value) : ('周' + weekItem.value);
          });
          layer.alert('一年内，至少建立一节课。以当前选择，' + weekStr + '没有一节课可以建立。请重新选择');
          flag = true;
        }
      } else if (this.submitData.EndTypeKey == 2) { // 限日期
        let nextYearDate = this.$utils.getNextYear(Date.new()); // 一年后的日期
        if (Date.new(this.submitData.CircleEndTime).getTime() > nextYearDate.getTime()) {
          layer.alert('限日期模式最远只能建到' + nextYearDate.getFullYear() + '年' + (nextYearDate.getMonth() + 1) + '月' + nextYearDate.getDate() + '日，如果需要建立更远的课，请选择不结束。');
          flag = true;
        } else {
          for (let index = 0; index < this.submitData.WeekList.length; index++) {
            let weekdays = this.$utils.getWeekNum(Date.new(this.submitData.CircleStartTime), Date.new(this.submitData.CircleEndTime));
            let weekKey = this.submitData.WeekList[index];
            let num = weekdays[weekKey];
            let dateArr = this.$utils.getDateArrForOneDate(this.submitData.CircleStartTime, this.submitData.CircleStartTime, weekKey);
            // 开始日期为今天的且只有一节
            if (Date.new(dateArr[0]).getTime() == Date.new(this.$utils.formatDateToLine(Date.new())).getTime() && num == 1) {
              if (classMinutes <= curMinutes) {
                zeroCourses = zeroCourses ? zeroCourses + '、周' + this.$utils.getWeekByNum(weekKey) : '周' + this.$utils.getWeekByNum(weekKey);
              }
            } else if (num == 0) {
              zeroCourses = zeroCourses ? zeroCourses + '、周' + this.$utils.getWeekByNum(weekKey) : '周' + this.$utils.getWeekByNum(weekKey);
            }
          }
          if (zeroCourses) {
            layer.alert(zeroCourses + '在一年内没有课，请重新调整');
            flag = true;
          }
        }
      } else if (this.submitData.EndTypeKey == 3) { // 限节数
        flag = this.caculateCircleEndDateByClassCount();
      }
      return flag;
    },
    // 通过节数计算结束日期
    caculateCircleEndDateByClassCount () {
      let flag = false;
      let circleStartDate = Date.new(this.submitData.CircleStartTime);
      let nextYearDate = this.$utils.getNextYear(Date.new()); // 最大日期
      let curMinutes = Date.new().getHours() * 60 + Date.new().getMinutes(); // 当前时段
      let classMinutes = Number(this.submitData.TimeQuantum.split('-')[1].split(':')[0]) * 60 + Number(this.submitData.TimeQuantum.split('-')[1].split(':')[1]); // 上课时段

      let curWeek = circleStartDate.getDay(); curWeek = curWeek == 0 ? 7 : curWeek;// 开始日期是周几
      let isTodayPass = false; // 是否选了今天的日期且为过去时段
      if (circleStartDate.getTime() == Date.new(this.$utils.formatDateToLine(Date.new())).getTime() && classMinutes <= curMinutes) {
        isTodayPass = true;
      }
      if (this.submitData.ClassCount == 0) {
        layer.alert('节数不可为0节');
        flag = true;
      }
      // 节数《WeekList.length 找出一节课都没有的节数
      if (this.submitData.ClassCount >= this.submitData.WeekList.length) {
        let maxClassNum = 0; // 最长能上的节数
        let classAllDates = []; // 一年内所有符合上课的日期(weekList内)
        // 到明年今天 一共能排几节课
        this.submitData.WeekList.forEach(week => {
          let datas = this.$utils.getDateArrForOneDate(this.submitData.CircleStartTime, nextYearDate, week);
          maxClassNum = maxClassNum + datas.length;
          // 开始日期为今天 且上课时段为过去时间
          if (isTodayPass && week == curWeek) {
            --maxClassNum;
            datas.shift();
          }
          classAllDates = classAllDates.concat(datas);
        });
        if (this.submitData.ClassCount > maxClassNum) {
          layer.alert('限节数模式最远只能建到' + nextYearDate.getFullYear() + '年' + (nextYearDate.getMonth() + 1) + '月' + nextYearDate.getDate() + '日，以当前选择，只能建立' + maxClassNum + '节课。如果需要建立更远的课，请选择不结束。');
          flag = true;
        } else {
          classAllDates.sort((a, b) => {
            return Date.new(a).getTime() - Date.new(b).getTime();
          });
          this.submitData.CircleEndTime = classAllDates[this.submitData.ClassCount - 1];
        }
      } else {
        let nonClassNumWeekList = null;
        let weekStr = '';
        // 大于等于今天的星期几
        let greaterThanWeekList = this.submitData.WeekList.filter(week => { return week > curWeek || (!isTodayPass && week == curWeek); });
        // 小于今天的星期几
        let lessThanWeekList = this.submitData.WeekList.filter(week => { return week < curWeek || (isTodayPass && week == curWeek); });
        // 节数 《= 大于等于今天的星期几
        if (this.submitData.ClassCount <= greaterThanWeekList.length) {
          greaterThanWeekList.splice(0, this.submitData.ClassCount);
          nonClassNumWeekList = lessThanWeekList.concat(greaterThanWeekList);
        } else {
          let classCount = this.submitData.ClassCount;
          classCount = classCount - greaterThanWeekList.length;
          lessThanWeekList.splice(0, classCount);
          nonClassNumWeekList = lessThanWeekList;
        }
        nonClassNumWeekList.forEach(week => {
          let weekItem = this.weekList.find(obj => { return week == obj.key; });
          weekStr = weekStr ? weekStr + ('、周' + weekItem.value) : ('周' + weekItem.value);
        });
        layer.alert(weekStr + '在一年内没有课，请重新调整');
        flag = true;
      }
      return flag;
    },
    // 选择开始日期
    changeCircleStartTime (date) {
      this.dataBtnInfo.isActive = false;
      this.submitData.CircleStartTime = date;
      this.calculateClassCount();
    },
    // 选择结束日期
    changeCircleEndTime (date) {
      this.dataBtnInfo.isActive = false;
      this.submitData.CircleEndTime = date;
      this.calculateClassCount();
    }
  }
};
</script>

<style>
</style>