<template>
  <!-- 授权管理 -->
  <div class="authorization_courseware_manage"
       style="min-height: 703px;">
    <div class="course_statistics_table_search flex">
      <date-range ref="saveDate"
                  style="align-items: center;margin-right: 20px;"
                  :searchObj="searchObj"
                  :fromTitle="'时间范围'"
                  @changeDate="changeSaveDate"></date-range>
      <div class="table_select_box"
           style="margin-right: 20px; min-width: 192px">
        <div class="table_select_title">流量包类型</div>
        <el-select :value="vFlowPackagesTypeKeyValue"
                   placeholder="请选择"
                   style="width: 115px"
                   ref="resetText"
                   @change="selectFlowPackagesTypeKey">
          <el-option v-for="item in TypeList"
                     :key="item.value"
                     :label="item.label"
                     :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="table_select_box"
           style="margin-right: 20px; min-width: 192px">
        <div class="table_select_title">流量包</div>
        <el-select :value="vFlowPackagesKeyValue"
                   placeholder="请选择"
                   style="width: 170px"
                   ref="resetText"
                   @change="selectFlowPackagesKey">
          <el-option v-for="item in fluxPackageList"
                     :key="item.value"
                     :label="item.label"
                     :value="item">
          </el-option>
        </el-select>
      </div>

    </div>
    <div class="course_statistics_table_search flex">
      <div class="table_select_box"
           style="margin-right: 20px; min-width: 192px">
        <div class="table_select_title">发生类型</div>
        <el-select :value="vHappenKeyValue"
                   placeholder="请选择"
                   style="width: 115px"
                   ref="resetText"
                   @change="selectHappenType">
          <el-option v-for="item in HappenTypeList"
                     :key="item.value"
                     :label="item.label"
                     :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="width_small course_statistics_search">
        <input-search-contain-btn v-model="searchObj.searchText"
                                  :placeholder="'请输入'"
                                  :clearAll="true"
                                  @clearSearch="clearSearchCondition"
                                  @onSearchEvent="search"></input-search-contain-btn>

      </div>
    </div>
    <div class="course_statistics_table_box table_list_content">
      <!-- <list class="summarizing_list"
            :key="tableKey"
            :tableData="vdataReportList"
            :totalNum="totalNum"
            :PageCount="PageCount"
            :tableColumns="tableCourseColumns"
            :isSearchText="false"
            :isNeedInnerScroll="false"
            :defaultSort="defaultSort"
            :queryParams="searchObj"
            :vFooterTotal="vFooterTotal"
            @tableBtnClick="doAfterTableBtnClick"
            @loadTableData="getDataList"></list> -->
      <table-list class="summarizing_list"
                  :tableData="vdataReportList"
                  :tableColumns="vTableCourseColumns"
                  :key="tableKey"
                  :totalNum="totalNum"
                  :PageCount="PageCount"
                  :queryParams="searchObj"
                  :footerContent="vFooterTotal"
                  @loadTableData="getDataList"></table-list>
    </div>
  </div>
</template>
<script>
import tableList from '../../../common/table-list/index';
import inputSearchContainBtn from '../../common/input-search-contain-btn';
import prepareLessonsHead from '../../../head-quarters/campus-prepare-lessons-examine/common/prepare-lessons-head';
import {
  WEBVSearchPageFlowDetailedList, CWSSearchPageFlowDetailedList, HQSearchPageFlowDetailedList, AdminSearchPageFlowDetailedList,
  WEBVGetFluxPackageForSaaSClient, CWSGetFluxPackageForSaaSClient, HQGetFluxPackageForSaaSClient, AdminGetFluxPackageForSaaSClient

} from '../../../../API/workbench';
// import dialogFactory from '../../common/dialog-factory/index';
export default {
  components: {
    prepareLessonsHead,
    tableList,
    inputSearchContainBtn
    // coursewareDetails
  },
  data () {
    return {
      authorizationHead: [
        { name: '有效流量包', key: 1, isSelected: true },
        { name: '无效流量包', key: 2, isSelected: false }
      ],
      TypeList: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '通用',
          value: 1
        },
        {
          label: '定向',
          value: 2
        }
      ],
      HappenTypeList: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '购买充值',
          value: 1
        },
        {
          label: '课件消耗',
          value: 2
        }
      ],
      // 查询参数
      searchObj: {
        startTime: this.$utils.getCurrentMonthFirst(), //	字符串	必须		数据源：GET	起始时间
        endTime: this.$utils.getCurrentMonthLast(), //	字符串	必须		数据源：GET	结束时间
        pageIndex: 0,
        pageSize: 10,
        searchText: '',
        sequence: 'DESC',
        orderBy: '',
        FlowPackagesTypeKey: 0, // 流量包类型:1-通用流量;2-定向流量
        FlowPackagesKey: 0,   // 流量包Key
        HappenKey: 0,  // 发生类型:1-购买充值;2-课件消耗
        FlowPackagesTypeKeyValue: '全部', // 流量包类型:1-通用流量;2-定向流量
        FlowPackagesKeyValue: '全部',   // 流量包Key
        HappenKeyValue: '全部',  // 发生类型:1-购买充值;2-课件消耗
        ExpendClientKey: 0,
        SaaSClientKey: 0// 总部门店Key
      },
      fluxPackageList: [],  // 流量包列表。
      dataReportList: [],
      PageCount: 0,
      totalNum: 0,
      tableKey: '0',
      GeneralFluxCount: 0,
      tableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '时间',
          prop: 'GenerateTime',
          minWidth: 140,
          align: 'left',
          sortable: 'custom',
          type: 'textItem'
        },
        {
          label: '流量包',
          prop: 'FluxPackageKeyValue',
          minWidth: 160,
          align: 'left',
          sortable: 'custom',
          type: 'textItem'
        },
        {
          label: '流量包类型',
          prop: 'TypeKeyValue',
          minWidth: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '发生类型',
          prop: 'HappenTypeKeyValue',
          minWidth: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '数量',
          prop: 'NumberCount',
          sortable: false,
          minWidth: 88,
          width: ' ',
          type: 'textItem'

        },
        {
          label: '发生对象',
          prop: 'vContentText',
          minWidth: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '发生人',
          prop: 'OperatorKeyValue',
          minWidth: 88,
          sortable: false,
          align: 'left',
          type: 'textItem'
        }
      ],
      HQtableCourseColumns: [
        // 明细总览 table 列
        {
          label: '序号',
          prop: 'Number',
          width: 68,
          align: 'left',
          type: 'tableIndex',
          className: 'table_index_gray'
        },
        {
          label: '时间',
          prop: 'GenerateTime',
          minWidth: 140,
          align: 'left',
          sortable: 'custom',
          type: 'textItem'
        },
        {
          label: '流量包',
          prop: 'FluxPackageKeyValue',
          minWidth: 160,
          align: 'left',
          sortable: 'custom',
          type: 'textItem'
        },
        {
          label: '流量包类型',
          prop: 'TypeKeyValue',
          minWidth: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '发生类型',
          prop: 'HappenTypeKeyValue',
          minWidth: 120,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '数量',
          prop: 'NumberCount',
          sortable: false,
          minWidth: 88,
          width: ' ',
          type: 'textItem'

        },
        {
          label: '发生对象',
          prop: 'vContentText',
          width: 140,
          sortable: false,
          align: 'left',
          type: 'textItem'
        },
        {
          label: '发生人',
          prop: 'OperatorKeyValue',
          minWidth: 88,
          sortable: false,
          align: 'left',
          type: 'textItem'
        }
      ]
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {
          OLAPKey: 0  // 门店ID。 超级管理中需要传递。
        };
      }
    },
    searchType:{
      type:[Boolean,Number,String],
      default:0
    }
  },
  created () {
    if (this.dataInfo) {
      console.log('this.dataInfo', this.dataInfo);
      this.searchObj.startTime = this.dataInfo.StartTime;
      this.searchObj.endTime = this.dataInfo.EndTime;
      this.searchObj.HappenKey = this.dataInfo.HappenKey || 0;
      this.searchObj.HappenKeyValue = this.dataInfo.HappenKeyValue || '全部';
      this.searchObj.FlowPackagesTypeKey = this.dataInfo.FlowPackagesTypeKey || 0;
      this.searchObj.FlowPackagesTypeKeyValue = this.dataInfo.FlowPackagesTypeKeyValue || '全部';
      this.searchObj.FlowPackagesKey = this.dataInfo.FlowPackagesKey || 0;
      this.searchObj.FlowPackagesKeyValue = this.dataInfo.FlowPackagesKeyValue || '全部';
      this.searchObj.ExpendClientKey = this.dataInfo.OLAPKey || 0;
      this.searchObj.SaaSClientKey = this.dataInfo.SaaSClientKey || 0;
    }
  },
  mounted () {
    this.getDataList();
    this.getFluxPackageList();
  },
  computed: {
    vdataReportList () {
      let newArr = [];
      this.dataReportList.forEach((o) => {
        //  1-购买充值;2-课件消耗
        o.HappenTypeKeyValue = o.HappenTypeKey == 1 ? '购买充值' : '课件消耗';
        o.TypeKeyValue = o.TypeKey == 2 ? o.SaaSClientKeyValue : '通用流量';
        o.NumberCount = Number(o.NumberCount) < 0 ? this.$utils.flowFormate(o.NumberCount) : Number(o.NumberCount) > 0 ? '+' + this.$utils.flowFormate(o.NumberCount) : '--';
        o.vContentText = '';
        o.EndTime = o.EndTime || '';
        o.StartTime = o.StartTime || '';
        o.OperatorKeyValue = o.OperatorKeyValue || '-';
        if (o.HappenTypeKey == 2) {
          o.vContentText = `<span class="div_block" :title="`+o.CoursewareSeries+`">` + o.ExpenseTypeKeyValue + ' ' + o.StartTime.substring(11, 16) + '-' + o.EndTime.substring(11, 16) + `</span><span class="div_block">` + o.CoursewareSeries + `</span>`;
        } else {
          o.vContentText = '';
        }
        newArr.push(o);
      });
      return newArr;
    },
    vTableCourseColumns () {
      if (this.vmdInfo.EditionKey == 5) {
        return this.HQtableCourseColumns;
      } else {
        return this.tableCourseColumns;
      }
    },
    vFooterTotal () {
      let text = '共' + this.totalNum + '条数据';
      return text;
    },
    vmdInfo () {
      return this.$store.getters.SaaSClientInfo;
    },
    vFlowPackagesTypeKeyValue () {
      return this.searchObj.FlowPackagesTypeKey == 0
        ? '全部'
        : this.searchObj.FlowPackagesTypeKeyValue;
    },
    vFlowPackagesKeyValue () {
      return this.searchObj.FlowPackagesKey == 0
        ? '全部'
        : this.searchObj.FlowPackagesKeyValue;
    },
    vHappenKeyValue () {
      return this.searchObj.HappenKey == 0
        ? '全部'
        : this.searchObj.HappenKeyValue;
    }
  },
  methods: {

    getDataList () {
      let fn = '';
      let OLAPKey = this.dataInfo ? Number(this.dataInfo.OLAPKey) : 0;
      // // 2标准 3旗舰 4高级 5总部 6课件
      // // 2标准 3旗舰 4高级 5总部 6课件
      if (this.vmdInfo.OLAPKey == 1) {
        fn = AdminSearchPageFlowDetailedList;
      } else if (this.vmdInfo.EditionKey == 6) {
        fn = CWSSearchPageFlowDetailedList;
      } else if (this.vmdInfo.EditionKey == 5) {
        fn = HQSearchPageFlowDetailedList;
        OLAPKey = this.vmdInfo.OLAPKey;
      } else {
        fn = WEBVSearchPageFlowDetailedList;
      }
      fn(this.searchObj, OLAPKey,this.searchType).then(result => {
        console.log(result.data, 'WEBVGetFluxPackageForList');
        this.dataReportList = result.data.PageDataList;
        this.totalNum = result.data.Total;
        this.GeneralFluxCount = Number(result.data.GeneralFluxCount);
      }).catch(error => {
        console.log('errorWEBVGetFluxPackageForList', error.msg);
      });
    },
    getFluxPackageList () {
      let fn = null;
      let OLAPKey = this.dataInfo ? Number(this.dataInfo.OLAPKey) : 0;
      console.log(this.vmdInfo.OLAPKey, 'this.vmdInfo.OLAPKey');
      if (this.vmdInfo.OLAPKey == 1) {
        fn = AdminGetFluxPackageForSaaSClient;
      } else if (this.vmdInfo.EditionKey == 6) {
        fn = CWSGetFluxPackageForSaaSClient;
      } else if (this.vmdInfo.EditionKey == 5) {
        fn = HQGetFluxPackageForSaaSClient;
        OLAPKey = this.vmdInfo.OLAPKey;
      } else {
        fn = WEBVGetFluxPackageForSaaSClient;
      }
      fn(OLAPKey).then(result => {
        console.log(result.data, 'getFluxPackageList');
        result.data.forEach(o => {
          o.label = o.Number;
          o.value = o.OLAPKey;
        });
        result.data.unshift({
          label: '全部',
          value: 0
        });
        this.fluxPackageList = result.data;
      }).catch(error => {
        console.log(error, 'getFluxPackageList');
      });
    },
    clearSearchCondition () {
      this.searchObj.startTime = '';
      this.searchObj.endTime = '';
      this.searchObj.FlowPackagesTypeKey = '0'; // 流量包类型:1-通用流量;2-定向流量
      this.searchObj.FlowPackagesKey = '0';  // 流量包Key
      this.searchObj.HappenKey = '0'; // 发生类型:1-购买充值;2-课件消耗
      this.searchObj.FlowPackagesTypeKeyValue = '全部'; // 流量包类型:1-通用流量;2-定向流量
      this.searchObj.FlowPackagesKeyValue = '全部';  // 流量包Key
      this.searchObj.HappenKeyValue = '全部'; // 发生类型:1-购买充值;2-课件消耗
      this.searchObj.pageIndex = 0;
      this.search();
    },
    changeSaveDate (dataInfo) {
      this.searchObj.startTime = dataInfo.startTime;
      this.searchObj.endTime = dataInfo.endTime;
    },
    search () {
      this.searchObj.pageIndex = 0;
      this.getDataList();
    },
    selectFlowPackagesTypeKey (item) {
      this.searchObj.FlowPackagesTypeKey = item.value;
      this.searchObj.FlowPackagesTypeKeyValue = item.label;
      this.search();
      console.log(item, 'FlowPackagesTypeKey', this.searchObj.FlowPackagesTypeKey);
    },
    selectFlowPackagesKey (item) {
      this.searchObj.FlowPackagesKey = item.value;
      this.searchObj.FlowPackagesKeyValue = item.label;
      this.search();
      console.log(item, 'FlowPackagesKey', this.searchObj.FlowPackagesKey);
    },
    selectHappenType (item) {
      this.searchObj.HappenKey = item.value;
      this.searchObj.HappenKeyValue = item.label;
      this.search();
      console.log(item, 'selectHappenType', this.searchObj.HappenKey);
    },
    // table 按钮点击
    doAfterTableBtnClick (rowData, eventName, rowAtrribute) {
      console.log(
        rowData
      );
      this.$dialog.open(this, {
        name: '学生档案',
        routerName: this.$route.name,
        moduleName: 'studentFile',
        data: { studentID: rowData.StudentKey }
      });
    },
    toBuy () {

    }
  }
};
</script>

<style>
.authorization_courseware_manage .tltle_margin {
  border-bottom: 1px solid #ebeef5;
  width: 100%;
}
.authorization_courseware_manage .table_fixed_nav_list {
  margin: 0 20px;
  padding: 0px 2px;
}
.authorization_courseware_manage .course_statistics_title {
  padding: 0;
}
.authorization_courseware_manage .course_statistics_title .title_date_select {
  width: 200px;
  border: none;
  margin-right: 10px;
  margin-left: 0;
}
.authorization_courseware_manage .table_select_box .el-select {
  width: 200px;
}
.authorization_courseware_manage
  .course_statistics_title
  .title_date_select
  .el-input
  input {
  width: 190px;
}

.authorization_courseware_manage
  .cw_summarizing
  .top_content_list
  .list_content
  .list_num {
  text-overflow: clip;
}
.flow_packet_total {
  position: absolute;
  right: 20px;
  top: 65px;
  line-height: 35px;
}
.div_block{
  display: block;
   overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>

