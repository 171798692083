<template>
  <div>
    <div class="from_title">
      <span>风采展示</span>
      <span class="font_gray">请上传小于5M的JPG/PNG图片，建议尺寸640px*495px。最多上传6张</span>
    </div>
    <div class="from_list">
      <div class="from_list_title">
      </div>
      <div class="from_list_content">
        <div class="WKT_slideshow_ul">

          <div class="WKT_slideshow_list "
               v-for="(item,key) in imgList"
               :key="key">
            <crop-upload-img :size="''"
                             :imgWidth="640"
                             :imgHeight="495"
                             :imgClass="'WKT_slideshow_img hover_border_blue'"
                             :remarks="''"
                             :isShowImg="true"
                             :ImgURl="item.imgUrl"
                             :indexKey="key"
                             @seletedItem="seletedItemAfter"
                             @uploadImgSuccess="doAfterUploadImgSuccess"></crop-upload-img>
            <div class="WKT_slideshow_input pr"
                 style="z-index:1">
              <input type="text"
                     placeholder="请输入"
                     v-model="item.title"
                     @click="seletedItemAfter"
                     @change="changeMien">
            </div>

            <operation-btn :className="'WKT_slideshow_btn_box pr'"
                           :item="item"
                           :dataList="imgList"
                           :isShowSetTop="true"
                           :isShowEditBtn="false"
                           @promote="promote"
                           @decline="decline"
                           @edit="edit"
                           @remove="remove"
                           @setTop="setTop"></operation-btn>
          </div>
          <div v-if="imgList.length<=5">
            <div class="">
              <crop-upload-img ref="notImg"
                               :imgWidth="640"
                               :imgHeight="495"
                               :imgClass="'upload_list WKT_slideshow_upload_box  hover_border_blue pr'"
                               :remarks="''"
                               :isShowImg="true"
                               :ImgURl="''"
                               @seletedItem="seletedItemAfter"
                               @uploadImgSuccess="addImg"></crop-upload-img>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import operationBtn from '../common/operation-btn';
import formInput from '../common/form-input';
import cropUploadImg from '../common/crop-upload-img';
export default {
  data () {
    return {
      value: ''
    };
  },
  props: {
    imgList: {
      type: Array,
      default: []
    }
  },
  components: {
    formInput, cropUploadImg, operationBtn
  },
  created () {

  },
  methods: {
    seletedItemAfter () {
      this.$emit('seletedMien');
    },
    doAfterUploadImgSuccess (imgUrl, key) {
      console.log('doAfterUploadImgSuccess', imgUrl, key);
      if (key >= 0) {
        let obj = {
          title: this.imgList[key].title,
          imgUrl: imgUrl
        };
        this.$refs.notImg.imgURlString = '';
        this.$nextTick(o => {
          this.imgList.splice(key, 1, obj);
        });
      }
      this.$emit('changeImg');
    },
    // 添加图片
    addImg (imgUrl) {
      let obj = {
        title: '',
        imgUrl: imgUrl
      };
      this.$refs.notImg.imgURlString = '';
      this.$nextTick(o => {
        this.imgList.push(obj);
      });
      console.log(imgUrl);
      this.$emit('changeImg');
    },
    // 上升
    promote (item, index) {
      this.$emit('moveIndex', item, index);
    },
    // 下降
    decline (item, index) {
      this.$emit('moveIndex', item, index + 3);
    },
    // 修改
    edit (item) {
      this.$emit('edit', item);
    },
    // 删除
    remove (item, index) {
      this.imgList.splice(index, 1);
      this.$emit('remove', item);
    },
    setTop (item, index) {
      this.$emit('moveIndex', item, index);
    },
    changeMien (val) {
      this.$emit('changeMien');
    }
  }
};
</script>
