<template>
  <!-- 排课创收潜力挖掘 - 校区饱和排课设置 -->
  <div class="saturationCourse_cs">
    <div class="content_marginRL content_marginTop_30">
      <div class="saturation_course_top">
        <div class="saturation_course_name">{{ MainDemoName }}</div>
        <div class="saturation_course_total">饱和排课：{{weekArrangeCourseCount}}人次/周</div>
      </div>
      <div class="saturation_course_content">
        <!-- 列表名 -->
        <div class="tabulation_title">
          <div class="title_name">教室名</div>
          <div class="title_name">周饱和排课</div>
          <div class="title_name">每节课饱和人数</div>
          <div class="title_name">周饱和排课</div>
        </div>
        <!-- 列表内容 -->
        <div class="tabulation_content">
          <div class="table_row"
               v-for="(item,index) in saturationList"
               :key="index"
               @click="saturationClick(item.OLAPKey)">
            <div class="row_name"
                 :title="item.MainDemoName">{{ item.MainDemoName }}</div>
            <div class="row_name"
                 :title="item.ArrangeCourseNum+'课次/周'">{{Number(item.ArrangeCourseNum)}}课次/周</div>
            <div class="row_name"
                 :title="item.CourseStudentNum+'人/课次'">{{Number(item.CourseStudentNum)}}人/课次</div>
            <div class="row_name"
                 :title="item.ArrangeCourseStudentNum+'人/周'">{{Number(item.ArrangeCourseStudentNum)}}人/周</div>
            <div class="row_name">く</div>
          </div>
        </div>
      </div>
    </div>
    <!--  指定的排课设置 -->
    <custom-dialog title="设置校区排课饱和课次"
                   class="student_info_dialog defult_dialog"
                   :visible.sync="isShowCourseSetting"
                   :before-close="closeCourseSettingPopup">
      <appoint-course-setting :formTitle="'设置校区排课饱和课次'"
                              :OLAPKey="OLAPKey"
                              @afterSuccess="afterSuccess">
      </appoint-course-setting>
    </custom-dialog>
  </div>

</template> 

<script>
import appointCourseSetting from './appoint-course-setting';
import { GetSchoolRoomSettingList } from '../../../../API/workbench';
export default {
  props: {
  },
  data () {
    return {
      OLAPKey: '',
      isShowCourseSetting: false,
      MainDemoName: '',
      weekArrangeCourseCount: 0,
      saturationList: []
    };
  },

  components: {
    appointCourseSetting
  },
  computed: {
  },
  watch: {
  },
  created () {
    this.getGetSchoolRoomSettingList();
  },
  mounted () {
    this.$dialog.register(this, ['']);
  },
  methods: {
    closeCourseSettingPopup () {
      this.isShowCourseSetting = false;
      this.getGetSchoolRoomSettingList();
    },
    saturationClick (key) {
      this.OLAPKey = key;
      console.log(this.OLAPKey, 'OLAPKey');
      this.isShowCourseSetting = true;
    },
    afterSuccess () {
      this.closeCourseSettingPopup();
    },
    // 获取教室设置信息
    getGetSchoolRoomSettingList () {
      GetSchoolRoomSettingList().then(result => {
        this.MainDemoName = result.data.MainDemoName;
        this.weekArrangeCourseCount = Number(result.data.weekArrangeCourseCount);
        this.saturationList = result.data.SchoolRoomSettingList;
        console.log(result.data, '获取教室设置信息 GetSchoolRoomSettingList');
      });
    }
  },
  destroyed () {
    this.$emit('afterSuccess');
  }
};
</script>

<style scoped>
.saturationCourse_cs {
  height: 527px;
}
.saturation_course_name {
  font-family: Alibaba PuHuiTi;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  color: rgba(51, 51, 51, 1);
  margin: 10px;
}
.saturation_course_total {
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(153, 153, 153, 1);
  margin: 10px;
}
.saturation_course_content {
  width: 387px;
  margin: 0 auto;
  height: 415px;
  border: 1px solid rgba(236, 236, 236, 1);
}
.tabulation_title {
  display: flex;
  background: #ececec;
  height: 31px;
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  line-height: 31px;
}
.title_name {
  margin-left: 20px;
}
.table_row {
  display: flex;
  height: 40px;
  line-height: 40px;
}
.table_row:hover {
  cursor: pointer;
  color: rgba(52, 152, 219, 1) !important;
}
.row_name {
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  font-weight: 400;
}
.row_name:nth-of-type(1) {
  margin-left: 18px;
  width: 60px;
}
.row_name:nth-of-type(2) {
  width: 75px;
  margin-left: 4px;
}
.row_name:nth-of-type(3) {
  margin-left: 15px;
  width: 105px;
}
.row_name:nth-of-type(4) {
  margin-left: 12px;
  width: 75px;
}
.row_name:nth-of-type(5) {
  margin-left: 5px;
  transform: rotate(180deg);
}
.tabulation_content {
  overflow-y: auto;
  height: 382px;
}
</style>