<template>
  <div>
    <div class="content_marginRL content_marginTop_30 customer_registration">
      <div
        class="customer_registration_content form_info_edit"
        id="customer_registration"
      >
        <div class="form_info_edit mendian_setting_content single_shadow">
          <!-- 基础信息 -->
          <div
            class="customer_registration_info customer_registration_info_more"
          >
            <div
              id="screanContent"
              style="
                z-index: 1;
                position: absolute !important;
                right: 45px;
                opacity: 0;
              "
            ></div>

            <input-upload-img-file
              :ImgURl="mdInfo.LogoUrl ? mdInfo.LogoUrl : ''"
              :formTitle="'门店LOGO'"
              :saaSClientNameKey="mendianInfo.OLAPKey"
              :readonly="isReadonly"
              :required="true"
              :imgstyle="'width: 20px!important;height: 20px;z-index:10;'"
              @doAfterUpLoadImgSuccess="doSetLogoPath"
            ></input-upload-img-file>
             <input-text
              :formTitle="'品牌名称'"
              :readonly="isReadonly"
              :formPlaceholder="'请输入'"
              :required="true"
              v-model="mdInfo.MainDemoName"
            ></input-text>
            <input-text
              :formTitle="'主题颜色'"
              :readonly="isReadonly"
              :formPlaceholder="'请输入'"
              :required="true"
              v-model="mdInfo.MainBodyColor"
            ></input-text>
            <input-upload-img-file
              :ImgURl="mdInfo.LoginBackground ? mdInfo.LoginBackground : ''"
              :formTitle="'登录背景图'"
              :saaSClientNameKey="mendianInfo.OLAPKey"
              :readonly="isReadonly"
              :required="true"
              :imgMaxWidth="1920"
              :imgstyle="'width: 20px!important;height: 20px;z-index:10;'"
              @doAfterUpLoadImgSuccess="doSetBrandPath"
            ></input-upload-img-file>
            <input-upload-img-file
              :ImgURl="mdInfo.QRCode ? mdInfo.QRCode : ''"
              :formTitle="'公众号二维码'"
              :saaSClientNameKey="mendianInfo.OLAPKey"
              :readonly="isReadonly"
                :required="true"
              :imgstyle="'width: 20px!important;height: 20px;z-index:10;'"
              @doAfterUpLoadImgSuccess="doSetQRCodePath"
            ></input-upload-img-file>
            <input-text
              :formTitle="'品牌宣传语'"
              :readonly="isReadonly"
              :formPlaceholder="'请输入'"
              :required="true"
              v-model="mdInfo.BrandSlogan"
            ></input-text>

          </div>
        </div>
        <!-- 按钮组 -->
        <div v-if="!isReadonly">
          <save-cancel-btn-group
            :isSingleSubmit="true"
            :singleSubmitType="'dynamic'"
            :cancelBtnText="vCancelBtnTitle"
            @confirmClick="confirmClick"
            @cancelClick="closeBrandCustomize"
          ></save-cancel-btn-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  UpdateBrandCustom,
  GetBrandCustom,
  OpenBrandCustom,
  CloseBrandCustom
} from "../../../../API/workbench.js";
export default {
  data() {
    return {
      mdInfo: {
        MainDemoName: '',//"品牌名称",
        LogoUrl: '',//"logo",
        MainBodyColor: '',//"主体颜色",
        LoginBackground: '',//"登录背景图",
        QRCode:'',// "公众号二维码",
        BrandSlogan: '',//"品牌宣传语",
      },
      isReadonly: false,
    };
  },
  components: {},
  props: {
    mendianInfo: {
      type: Object,
      default: null,
    },
  },
  created() {
    if (this.mendianInfo && this.mendianInfo.IsBrandCustomKey==1) {
      // 修改员工
      this.getBrandInfo();
    }
  },
  mounted() {
 
  },
  computed: {
    vCancelBtnTitle(){
      if(this.mendianInfo.IsBrandCustomKey==1){
        return '关闭设置'
      }else{
        return '取消'
      }
    }
  },
  methods: {
    // 获取门店信息
    getBrandInfo() {
      GetBrandCustom(this.mendianInfo.OLAPKey).then(
        (result) => {
          this.mdInfo = result.data;
          console.log(this.mdInfo, "获取门店信息");
        },
        (error) => {
          layer.alert(error.msg);
        }
      );
    },
    //关闭品牌定制
    closeBrandCustomize(){
      if(this.mendianInfo.IsBrandCustomKey==1){
         layer.confirm('是否关闭品牌定制', {
        btn: [{
          name: '确认',
          callBack: () => {
             CloseBrandCustom(this.mendianInfo.OLAPKey).then(result => {
              // this.getMessageSearchSaaSClient();
              this.$emit("afterSuccess");
              layer.alert('关闭品牌定制成功!');
            }, error => {
              layer.alert(error.msg);
            });
            layer.close();
          }
        }
        ]
      });
      }else{
       this.cancelClick()
      }
     
      
    },
    changemdVersions(obj) {
      this.mdInfo.EditionKey = obj.type;
      this.mdInfo.EditionKeyValue = obj.name;
    },
    changeMdTypee(obj) {
      this.mdInfo.TypeKey = obj.type;
      this.mdInfo.TypeKeyValue = obj.name;
    },
    doSetLogoPath(Url) {
      this.mdInfo.LogoUrl = Url;
    },
    doSetBrandPath(Url) {
      console.log(Url, "ZangIcon");
      this.mdInfo.LoginBackground = Url;
    },
    doSetQRCodePath(Url){
       this.mdInfo.QRCode = Url;
    },
    // 保存
    confirmClick(callBack) {
      let res = this.validatestaffItemInfo();
      if (!res) {
        if (callBack) {
          callBack();
        }
        return false;
      }
      this.confirmData(callBack);
    },
    confirmData(callBack) {
      console.log(this.mdInfo, "this.mdInfo");
      if(this.mendianInfo.IsBrandCustomKey==1){
         UpdateBrandCustom(this.mendianInfo.OLAPKey, this.mdInfo)
        .then(
          (result) => {
            this.$emit("afterSuccess", "Edit", result.data);
            this.cancelClick();
            layer.alert("修改成功");
          },
          (error) => {
            layer.alert(error.msg);
          }
        )
        .finally(() => {
          if (callBack) {
            callBack();
          }
        });
      }else
      {
         OpenBrandCustom(this.mendianInfo.OLAPKey, this.mdInfo)
        .then(
          (result) => {
            this.$emit("afterSuccess", "Edit", result.data);
            this.cancelClick();
            layer.alert("修改成功");
          },
          (error) => {
            layer.alert(error.msg);
          }
        )
        .finally(() => {
          if (callBack) {
            callBack();
          }
        });
      }
     
    },
    // 验证客户信息
    validatestaffItemInfo() {
      if (this.mdInfo.MainDemoName) {
        this.mdInfo.MainDemoName = this.mdInfo.MainDemoName.replace(
          /(^\s*)|(\s*$)/g,
          ""
        );
      }
      let flag = true;
      if (!this.mdInfo.LogoUrl) {
        layer.alert("请上传品牌logo");
        flag = false;
      }else if (!this.mdInfo.MainDemoName) {
        layer.alert("请输入品牌名称");
        flag = false;
      } else if (!this.mdInfo.MainBodyColor) {
        layer.alert("请输入主题颜色");
        flag = false;
      } else if (!this.mdInfo.LoginBackground) {
        layer.alert("请上传登录背景图");
        flag = false;
      }else if (!this.mdInfo.QRCode) {
        layer.alert("请上传公众号二维码");
        flag = false;
      }else if (!this.mdInfo.BrandSlogan) {
        layer.alert("请输入品牌宣传语");
        flag = false;
      }
      return flag;
    },
    // 取消
    cancelClick() {
      this.$emit("closeFrom");
    },
  },
};
</script>
